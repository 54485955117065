import { Component, OnInit, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../../settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PackageClass } from '../../../shared/business/view-settings.modals';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-package-class',
  templateUrl: './package-class.component.html',
  styleUrls: ['./package-class.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PackageClassComponent implements OnInit {
  @Input() dataInput;
  packageClass: UntypedFormGroup;
  blnReadOnly: boolean;
  clickButton: any;
  isUserAuthorized = true;
  isViewOnly = true;
  captions: any;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  constructor(public formBuilder: UntypedFormBuilder, public localization: SpaLocalization, private BP: BreakPointAccess, private utils: SpaUtilities, private http: HttpServiceCall, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<PackageClassComponent>, public _settingsService: SettingsService, private vsBusiness: ViewSettingClientBusiness) {
    this.captions = this.localization.captions.setting;
    this.floatLabel = this.localization.setFloatLabel;
    this.packageClass = this.formBuilder.group({
      packageCode: ['', Validators.required],
      packageName: ['', [Validators.required, EmptyValueValidator]]
    });

    if (data.popupConfig.operation == "edit") {
      this.packageClass.controls.packageCode.setValue(data.datarecord.code);
      this.packageClass.controls.packageName.setValue(data.datarecord.className);
      this.clickButton = this.captions.UPDATE;
    }
    this.vsBusiness.activeFormGroup = this.packageClass;
  }

  //Save the package data
  save() {
    setTimeout(() => {
      this.saveButton.disabledproperty = true;
    },1);
    if (this.data.popupConfig.operation == "create") {
      this.createPackageClass();
    } else if (this.data.popupConfig.operation == "edit") {
      this.updatePackageClass();
    }
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingPackageClasses]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingPackageClasses);
    if (this.isViewOnly) {
      this.utils.disableControls(this.packageClass);
    }
  }
  createPackageClass() {
    const packageClassObj: PackageClass = {
      Code: this.packageClass.controls.packageCode.value,
      ClassName: this.packageClass.controls.packageName.value,
    };
    try{
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "CreatePackageClass",
        method: HttpMethod.Post,
        body: packageClassObj,
        showError: true,
        extraParams: []
      });
    }
    finally{
      this.saveButton.disabledproperty = false;
    }
  }

  updatePackageClass() {
    let packageClassObj: PackageClass = {
      Code: this.packageClass.controls.packageCode.value,
      ClassName: this.packageClass.controls.packageName.value,
    };
    try {
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "UpdatePackageClass",
      method: HttpMethod.Put,
      uriParams: { id: this.data.datarecord.id },
      body: packageClassObj,
      showError: true,
      extraParams: []
    });
    }
  finally{
    this.saveButton.disabledproperty = false;
  }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {

    if (callDesc == "CreatePackageClass" || callDesc == "UpdatePackageClass") {
      this.dialogRef.close(result);
    }
  }


  //Cancel the package data
  cancel() {
    this._settingsService.packageClassArray = [];
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  ngOnInit() {
    this.ValidateBreakPoint();
    this.saveButton = {
      type: 'primary',
      label: this.data.popupConfig.operation == "create" ? this.captions.SAVE : this.captions.UPDATE,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.CANCEL,
    }
    if (this.data.popupConfig.operation == "create") {

      this.blnReadOnly = false;
    } else if (this.data.popupConfig.operation == "edit") {
      this.blnReadOnly = true;
    }
    if (this.data.popupConfig.operation == "create") {
      this.clickButton = this.captions.SAVE;
      this.packageClass.setValue({
        packageCode: this.dataInput ? this.dataInput : '',
        packageName: this.dataInput ? this.dataInput : ''
      });
    } else if (this.data.popupConfig.operation == "edit") {
      this._settingsService.packageClassArray = [];
      this.clickButton = this.captions.UPDATE;
      const packageData = this.data.datarecord;
      this.packageClass.setValue({
        packageCode: packageData.packageClassCode,
        packageName: packageData.packageName
      });
    }
    this.packageClass.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.packageClass.valid && this.packageClass.dirty);
      this.saveButton = { ...this.saveButton };
    });
  }

}
