<div class="multi-select-auto-complete-container">
    <div class="search-input">
       <input autocomplete="off" [(ngModel)]="textValue" class="input-text" (input)="textChanged()"/>
       <i *ngIf="enabledCancel" (click)="cancelautocomplete()" class="search-cancel icon-requisition-denied-eatec"></i>
    </div>
    <div class="search-autocomplete" *ngIf="autoCompleteParams.selectData.length > 0">
       <ul class="search-list">
           <li class="search-list-detail" *ngFor="let list of autoCompleteParams.selectData" (click)="preventDefault($event)" title="{{list[displayKeyName]}}">
               <mat-checkbox [(ngModel)]="list.Checked" (ngModelChange)="addData(list)" (click)="preventDefault($event)" >{{list[displayKeyName]}}</mat-checkbox>
            </li>
       </ul>
    </div>
</div>