
import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const CustomUnitDropdownChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: FormType[], CustomData: Array<any>,
    systemPreferenceData?: SysytemPreferenceService
): any => {
    try {
        if (FieldGrouping.CustomFormAction) {
            // const fromUnit = CustomData.filter(x => x.UnitTypeId === 1)[0].UnitId;
            const customUnitCollection: Array<any> = CustomData.filter(x => x.UnitTypeId === 1);
            let fromUnit = null;
            if ( customUnitCollection && customUnitCollection.length > 0 ) {
                fromUnit = customUnitCollection.filter(x => x.UnitTypeId === 1)[0].UnitId;
            }
            if (MasterDataCollection && MasterDataCollection.hasOwnProperty('ParentFormGroup') &&
                MasterDataCollection.ParentFormGroup.value.hasOwnProperty('BaseUnit') && 
                MasterDataCollection.ParentFormGroup.value.BaseUnit !== null) {
                fromUnit = MasterDataCollection.ParentFormGroup.value.BaseUnit;
            }
            const copyFromUnit = fromUnit;
            const UnitObject = CustomData.filter(x => x.Id === FormGroupData.value.UnitId);
            if (UnitObject && UnitObject.length > 0) {
                let toUnit = UnitObject[0].UnitId;
                if (UnitObject[0].UnitTypeId !== null) {
                    FormGroupData.controls['Conversion'].setValue(UnitObject[0].Conversion);                    
                    CommonService.triggerBlurEvent('Conversion');
                } else {
                    if (FieldGrouping.CustomFormAction.CustomFromKey === 'Barcode') {
                        if (FormGroupData.value.UnitTypeId === 4) {
                            fromUnit = toUnit;
                            toUnit = copyFromUnit;
                        }
                    }
                    const UnitMeasurementSystemId = systemPreferenceData.globalSystemPreference.
                            filter(x => x.LookupName === 'UnitMeasurementSystem')[0].LookupValue;
                    const filter = `FromUnit eq ${fromUnit} and ToUnit eq ${toUnit} and UnitMeasurementSystemId eq ${UnitMeasurementSystemId}`;
                    const GetDataFilter = encodeURIComponent(`${filter}`).replace(/[!'()*]/g, function (c) {
                        return '%' + c.charCodeAt(0).toString(16);
                    });
                    const UnitConversionparams: QueryParams = {
                        count: true,
                        top: InfiniteScrollItemCount,
                        skip: 0,
                        expand: '',
                        select: '',
                        filter: '',
                        orderby: '',
                        isactive: true,
                        search: '',
                        filterQuery: GetDataFilter,
                        searchtext: ''
                    };
                    if (fromUnit !== undefined && toUnit !== undefined) {
                        const GetDataByIdUrl = `/inventory/api/UnitConversions${formQueryParams(UnitConversionparams)}`;
                        httpService.GethttpMethod(GetDataByIdUrl).subscribe((x: any) => {
                            console.log('Unit Conversion', x);
                            const responseValue = x.value[0];
                            if (responseValue.hasOwnProperty('Conversion')) {
                                FormGroupData.controls['Conversion'].setValue(responseValue.Conversion);
                            } else {
                                FormGroupData.controls['Conversion'].setValue(1);
                            }                  
                            CommonService.triggerBlurEvent('Conversion');
                        });
                    }
                }
            }
        }
    } catch (error) {
        console.log('error occured CustomDefaultSelect', error);
    }
};
function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}