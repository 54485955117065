import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { SpaLocalization } from './spa-localization';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';

@Pipe({
    name: 'PackageSummaryDate',
    pure: false
})
@Injectable()
export class PackageSummaryDatePipe implements PipeTransform {

    constructor(private utils: SpaUtilities, private localization: SpaLocalization) {

    }

    transform(service: any): string {
        let startTime: Date = this.localization.AddTimeToDate(service.date, this.utils.TimeToPropertyDateTime(this.localization.DeLocalizeTime(service.fromTime)));
        let endTime: Date = this.localization.AddTimeToDate(service.date, this.utils.TimeToPropertyDateTime(this.localization.DeLocalizeTime(service.toTime)));
        return this.utils.FormatAppointmentDateTime(startTime, endTime);
    }
}