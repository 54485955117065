import { Injectable, EventEmitter } from '@angular/core';
import { AuthenticationParameters, UserAgentApplication, CacheLocation } from 'msal';
import { BehaviorSubject } from 'rxjs';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { MsalConfiguration } from './MsalConfig';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';


@Injectable({
    providedIn: 'root'
})
export class MsalIntegrationService {
    app: UserAgentApplication;
    isIframe = false;
    loggedIn = false;
    private loggedInUser = new BehaviorSubject('');
    currentUser = this.loggedInUser.asObservable();
    userDetails: any;
    loginEvent: EventEmitter<any> = new EventEmitter();
    logoutEvent: EventEmitter<any> = new EventEmitter();
    config: any;
    constructor(private _localization: SpaLocalization, private propertyInfo: SpaPropertyInformation) {
    }

    Init() {
        try {
            this.isIframe = window !== window.parent && !window.opener;

            this.config = (this.propertyInfo.GetPropertyConfiguration() || {});

            if (this.config.hasOwnProperty('clientId')) {
                this.app = new UserAgentApplication({
                    auth: {
                        clientId: this.config.clientId,
                        redirectUri: (MsalConfiguration.redirectUri || window.location.origin),
                    },
                    cache: {
                        cacheLocation: (MsalConfiguration.cacheLocation || 'sessionStorage') as CacheLocation
                    },
                    framework: {
                        unprotectedResources: MsalConfiguration.unprotectedResources,
                    }
                });

                this.checkAccount();

                this.app.handleRedirectCallback((authError, response) => {
                    if (authError) {
                        console.error('Redirect Error: ', authError.errorMessage);
                        return;
                    }

                    console.log('Redirect Success: ', response.accessToken);
                });
                this.updateStatus();
            }
            else {
                console.log("ClientId not registered for this property");
            }
        }
        catch (err) {
            console.dir(err);
        }
    }

    private loginHandler(result) {
        this.loggedIn = true;
        this.userDetails = result;
        this.checkAccount();
        this.updateStatus();
        result && this.loginEvent.emit(result);
    }

    updateStatus() {
        if (this.loggedIn)
            this.loggedInUser.next(this._localization.captions.header.SignInAs + " : " + this.getAccount().userName);
        else
            this.loggedInUser.next(this._localization.captions.header.SignInToMicrosoft);
    }

    checkAccount() {
        let logginStatus;
        try {
            logginStatus = (this.loggedIn = !!this.getAccount(), this.loggedIn);
        }
        catch (err) {
            console.log(err);
        }
        return logginStatus;
    }

    login() {
        if (this.config.hasOwnProperty('clientId')) {
            return this.app.loginPopup().then(
                idToken => {
                    this.loginHandler(this.getAccount())
                },
                error => {
                    console.log(error);
                }
            );
        }
        else {
            console.log("ClientId not registered for this property. Please register to login outlook");
        }
    }

    logout() {
        this.app.logout();
    }

    async getAccessToken() {
        return this.app.acquireTokenSilent(({ scopes: MsalConfiguration.Scopes } as AuthenticationParameters))
            .catch(error => {
                console.log(error);
                console.log("silent token acquisition fails. acquiring token using popup");

                // fallback to interaction when silent call fails
                return this.app.acquireTokenPopup(({ scopes: MsalConfiguration.Scopes } as AuthenticationParameters))
                    .then(tokenResponse => {
                        return tokenResponse;
                    }).catch(err => {
                        console.log(err);
                    });
            });
    }

    getAccount() {
        return this.app.getAccount();
    }

    async checkAndLogin() {
        try {
            if (!this.checkAccount())
                await this.login();
        }
        catch (ex) {
            console.log(ex);
        }
    }

}
