import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { StateService } from 'src/app/eatecui/source/shared/services/state.service';
// import videojs from 'video.js';
@Component({
  selector: 'app-vjs-player',
  template: `
    <video #target id="videojs" class="video-js" controls muted playsinline preload="none"></video>
  `,
  styleUrls: [
    './vjs-player.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target') target: ElementRef;
  @Input() options: {
      fluid: boolean,
      aspectRatio: string,
      autoplay: boolean,
      sources: {
          src: string,
          type: string,
          emeHeaders: any
      }[],
  };
  // player: videojs.Player;
  constructor(
    private elementRef: ElementRef,  private stateService: StateService
  ) { }
  ngOnChanges(): void {
    console.log('Video');
  }
  ngOnInit() {
    // instantiate Video.js
    console.log('Video');
  }
  ngOnDestroy() {
    // destroy player
    console.log('Video');
  }
}