import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from './system-preference-configuration';


export const LoadSystemPreference: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService
): Array<GlobalSystemPreference> => {
    try {
        const EndPointUrl = CONSTANT.ENDPOINTURL['InvertoryManagement']['GetSystemPreference'];
        const globalSystemPreferenceArray: Array<GlobalSystemPreference> = [];
        httpService.GethttpMethod(EndPointUrl + '/0').subscribe(x => {
            const parsedObject = JSON.parse(x['value']);
            const systemPreference = parsedObject['SystemPreferences'];
            if (systemPreference.length > 0) {
                systemPreference.forEach((element: any) => {
                    const preference = element.SystemPreferences;
                    if ( preference && preference.length > 0 ) {
                        preference.forEach(( preferenceobj: any) => {
                           const globalSystemPreference = {} as GlobalSystemPreference;
                           globalSystemPreference.Id = preferenceobj.Id;
                           globalSystemPreference.GroupName = preferenceobj.GroupName;
                           globalSystemPreference.InputType = preferenceobj.InputType;
                           globalSystemPreference.IsActive = preferenceobj.IsActive;
                           globalSystemPreference.LookupName = preferenceobj.LookupName;
                           globalSystemPreference.LookupValue = preferenceobj.LookupValue;
                           globalSystemPreferenceArray.push(globalSystemPreference);
                        });
                    }  
                 });
            }
            sysytemPreferenceService.globalSystemPreference = [...globalSystemPreferenceArray];
            const UnitMeasurementSystemId = sysytemPreferenceService.globalSystemPreference.
                            filter(y => y.LookupName === 'UnitMeasurementSystem')[0].LookupValue;
            sessionStorage.setItem('UnitMeasurementSystemId', UnitMeasurementSystemId.toString());
        });
        return globalSystemPreferenceArray;
       
    } catch (error) {
        console.error(error);
    }
};