import { Component, Input, AfterViewChecked, ViewChild, Output, EventEmitter, OnDestroy, OnChanges, ViewEncapsulation, SecurityContext } from '@angular/core';
import { HttpServiceCall } from '../../shared/service/http-call.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HTMLDomElement, ReportAPIOptions, ReportAPIModel, ReportDownloadFormat, AllReports, ReportSelector } from '../business/report.modals';
import { Host } from '../../shared/globalsContant';
import { SPAConfig } from '../../common/config/SPA-config';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { ScrollbarComponent } from 'ngx-scrollbar';
import { Subscription } from 'rxjs';
import { FastReportBusinessService } from './fastreport.business.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { JasperReportBusiness } from 'src/app/common/JaspersoftReport/jasperreport.business';
import { JasperReportDetails } from 'src/app/common/JaspersoftReport/jasperreport.model';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { environment } from 'src/environments/environment';
import { ReportRoutes } from 'src/app/common/communication/common-route';
import { ReportControlBuilder } from '../business/reportControlBuilder';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { cloneDeep } from 'lodash';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';


@Component({
  selector: 'app-retail-report',
  templateUrl: './fastreport.component.html',
  styleUrls: ['./fastreport.component.scss'],
  providers:[JasperReportBusiness],
  encapsulation: ViewEncapsulation.None
})
export class RetailFastReportComponent implements AfterViewChecked, OnChanges, OnDestroy {
  pageNumber: number = 1;
  zoomLevel: number = 1;
  reportDomStringData: string;
  reportQuerySubscription: Subscription;
  formats: DropdownOptions[];
  downlodformat: ReportDownloadFormat;
  reportresourcepath: string ;
  blobSubscription: Subscription;
  constructor(private http: HttpServiceCall, private _sanitizer: DomSanitizer, private spaConfig: SPAConfig,
    private localization: RetailLocalization, private utils: RetailUtilities, 
    private business: FastReportBusinessService, private jasperbusiness:JasperReportBusiness,
    private commonutils: CommonUtilities, private retailFeatureFlagService: RetailFeatureFlagInformationService,
  private retailPropertyInfo: RetailPropertyInformation) {
      this.floatLabel = this.localization.setFloatLabel;
     }
  activeReportURL: string;
  activeReportAPIModel: ReportAPIModel;
  activeReportCode: string;
  reportHTML: SafeHtml = "";
  reportDOM: Document;
  fileTypeDropDown: string = '';
  options: ReportAPIOptions;
  @Input('options')
  set setValue(value) {
    this.options = value;
  }
  @ViewChild('scrollable') scrollRef: ScrollbarComponent;
  @Output() disableGenerateEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Input() reportViewDisable: boolean = false;
  public pages: string[] = [];
  private currentPageNo: number = 0;
  FileTypes: any[] = [{ id: 1, value: "PDF" }, { id: 2, value: "WORD" }, { id: 3, value: "EXCEL" }, { id: 4, value: "RAWDATA" }];
  findText: string = "";
  captions: any = this.localization.captions.reports;
  ifInputShow: boolean = false;
  ifCancelBool: boolean = false;
  ispageReference: string;
  isZoomReference: string;
  ifPrintable: boolean = false;
  ifSelectPrintable: boolean = false;
  isSearchAvailable:boolean = false;
  formatArray : any = { 'PDF':0, 'HTML':1,'EXCEL':2,'WORD':3,'IMAGE':4,'CSV' :5};
  floatLabel: string;
  JasperReportDetails : JasperReportDetails; 
  jreportURL : string;
  AllowJasperReport : boolean = true;
  jreportseletor : ReportSelector[];
  reportselector : any;
  isJasperEnabled : boolean =false;
  selectformat:string = "Select Format";

  ngOnChanges() {
    this.ifPrintable = false;
    this.ispageReference="";
    this.isZoomReference="";
    this.findText = '';
    this.ifCancelBool = false;
    this.ifInputShow =false;
    if (this.options != undefined) {
      if (!this.options.format || this.options.format == "HTML") {
        this.reportViewDisable = false;
        this.reportDomStringData = null;
        this.JasperReportDetails = this.GetJasperReportDetails(this.options.code);
        this.generateReportHTML();
      } else {
        if(this.options.code == AllReports.TransactionExtract ){
          this.resetOldReport();
        }
        this.generateAndDownloadReport();
      }
    }
  }

  private async generateReportHTML() {
    //before report html load
    this.utils.ToggleLoader(true, this.captions.lbl_processing);
    this.resetScrollBar();
    this.resetOldReport();
    this.disableGenerateEvtEmitter.emit(true);
    let body = this.createAPIOptions(this.options, "HTML");
    this.activeReportAPIModel = body;
    let parser = new DOMParser();

    //to check isjasperreport
    if (!this.AllowJasperReport) {
      this.reportDomStringData = await this.sendReportRequest();
      this.commonutils.ToggleLoader(false);
      this.reportDOM = parser.parseFromString(this.reportDomStringData, "text/html");
      //once html is loaded
      this.pages = [];
      this.separatePages();
      this.setPage(1);
      this.disableGenerateEvtEmitter.emit(false);
    }
    else {
      if (document.getElementById('jasperreportdata'))
        this.localization.decodeHTMLEntityByID('', 'jasperreportdata')
      //document.getElementById('jasperreportdata').innerHTML = '';
        await this.jasperbusiness.sendJasperReportRequest(body, this.JasperReportDetails, environment["commonGateway"], true, false).then(x => {
        this.reportDomStringData = x;
        this.utils.ToggleLoader(false);
        let data = cloneDeep(this.reportDomStringData);
        this.reportDOM = parser.parseFromString(this.reportDomStringData, "text/html");

        if (document.readyState === "complete" && document.getElementById('jasperreportdata')) {
          // document.getElementById('jasperreportdata').innerHTML = data;     
          this.localization.decodeHTMLEntityByID(data, 'jasperreportdata')
          this.pages = [];
          this.seperatePagesForJasper();
          this.setPage(1);
          this.disableGenerateEvtEmitter.emit(false);
          this.utils.ToggleLoader(false);
        }
        else {
          setTimeout(() => {
            // document.getElementById('jasperreportdata').innerHTML = data;
            this.localization.decodeHTMLEntityByID(data, 'jasperreportdata');
            this.pages = [];
            this.seperatePagesForJasper();
            this.setPage(1);
            this.disableGenerateEvtEmitter.emit(false);
            this.utils.ToggleLoader(false);
          }, 1000);
        }
      })
    }
    this.utils.ToggleLoader(false);
  }

  reportDownloadandPrint() {
    if (this.AllowJasperReport) 
      this.downloadFile('PDF', true)
    else
      this.business.downloadReport('PDF', this.options, true);
  }

  private generateAndDownloadReport(): void {
    this.business.downloadReport(this.options.format, this.options);
  }

  downloadReportData(_eve, showPrintDialog: boolean = false) {
    this.downlodformat = this.formats.filter(x => x.id == _eve.value)[0].value;
    //let downloadURL = this.GetReportAPIURL(this.downlodformat);
    this.downloadFile(this.downlodformat, showPrintDialog);
  }
  
  private downloadFile(type: ReportDownloadFormat, showPrintDialog: boolean): void {
    this.utils.ToggleLoader(true);
    let fileName: string;
    fileName = this.jasperbusiness.GetFileNameWithExtensionSUffixDate(type, this.activeReportAPIModel.code);
    this.activeReportAPIModel.format = type;
    try {
      this.jasperbusiness.sendJasperReportRequest(this.activeReportAPIModel, this.JasperReportDetails, environment["commonGateway"]).then(res => {
        this.business.ShowOrSaveFile(res, fileName, showPrintDialog)
      })
    }
    catch (er) {
      console.error('Jasper report call failed: ' + er);
      this.utils.ToggleLoader(false);
    }
  }

  private async sendReportRequest(): Promise<string> {
    try {
      let body = this.createAPIOptions(this.options, "HTML");
      let url = this.spaConfig.getHost(Host.reporting) + '/' + this.spaConfig.getUrl("GetReport");
      let response$: Promise<string> = this.http.putHTTPData(url, body).toPromise();
      response$.catch(err => {
        let errorTxt: string = "";
        let errorObjStr: string = err.error;
        let errorCode = JSON.parse(errorObjStr).errorCode;
        this.disableGenerateEvtEmitter.emit(false);
        errorTxt = this.localization.getError(errorCode);

        //TO DO - need to remove common translog err
        if (errorCode.toString() == "-118" && this.options.code == "TransLog") {
          errorTxt = this.localization.getError(10723);
        }
        this.utils.ShowErrorMessage(this.localization.captions.common.Information, errorTxt);
      });
      this.activeReportAPIModel = body;
      return await response$;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  searchContent() {
    this.ifInputShow = !this.ifInputShow;
  }
  reportDownload() {
    this.formats = this.jasperbusiness.getDownloadoptions(this.AllowJasperReport);
    this.ifPrintable = !this.ifPrintable;
  }

  private createAPIOptions(options: ReportAPIOptions, _format: ReportDownloadFormat): ReportAPIModel {

    return {
      code: options.code,
      format: _format,
      downloadFileName: "Download",
      parameters: this.arrayToObject(options.params,_format,true),
      uRIParams: this.arrayToObject(options.URIParams,_format,false),
      filterBody: options.filters,
      dateFormat: this.localization.dateFormat
    };
  }

  private arrayToObject(objectArr: any[], format:any, includeFormat:boolean): { [key: string]: string } {
    var result = {};
    objectArr.forEach(o => {
      result[Object.keys(o)[0]] = Object.values(o)[0];
    });
    if(includeFormat){
      result['pFormat'] = this.formatArray[format];
    }

    return result;   //Dictionary<string,string>
  }

  ngOnDestroy() {
    if (this.reportQuerySubscription) {
      this.reportQuerySubscription.unsubscribe();
    }
    if (this.blobSubscription) {
      this.blobSubscription.unsubscribe();
    }
  }

  private resetOldReport(): void {
    this.reportHTML = "";
    this.pages = [];
  }

  private resetScrollBar(): void {
    if (this.scrollRef) {
      this.scrollRef.scrollToTop();
      this.scrollRef.scrollToLeft();
    }
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.calculateWidthHeight();
    }, 1);
  }

  private separatePages(): void {
    let finalHtml: string = "";
    let bodyArr: HTMLCollectionOf<Element> = this.reportDOM.getElementsByTagName('body')[0].children;
    for (let i = 0; i < bodyArr.length; i++) {
      const element: HTMLDomElement = bodyArr[i];
      let currentHTML: string = "";
      if (element.name && element.name.includes('PageN')) {
        this.pages.push(finalHtml);
        finalHtml = "";
      } else {
        currentHTML = element.outerHTML;
        finalHtml = finalHtml + currentHTML;
      }
    }
    this.pages.push(finalHtml);
  }

  getPageNo(operation: "NEXT" | "PREVIOUS" | "FIRST" | "LAST"): number {
    this.ispageReference = operation;
    switch (operation) {
      case "NEXT":
        if (this.currentPageNo < (this.pages.length - 1)) {
          this.currentPageNo++;
        }
        break;
      case "PREVIOUS":
        if (this.currentPageNo > 1) {
          this.currentPageNo--;
        }
        break;
      case "FIRST":
        this.currentPageNo == 0;
        break;
      default:
        break;
    }
    return this.currentPageNo;
  }


  public setPage(pageNo: number): void {
    pageNo = parseInt(pageNo.toString().replace(/^0+/,''));
    this.resetScrollBar();
    this.pageNumber = pageNo;
    if (pageNo != 0 && pageNo < this.pages.length) {
      // this.reportHTML = this._sanitizer.sanitize(SecurityContext.HTML,this._sanitizer.bypassSecurityTrustHtml(this.getPageContent(pageNo)));
      this.reportHTML = this.getPageContent(pageNo);
    } else {
      this.setPage(this.pages.length - 1);
    }
  }
  validatePage(pageNo){
    if(pageNo != ''){
      this.setPage(pageNo);
    }
  }
  defaultFirstSelection(pageNo){
    if(pageNo == ''){
      this.pageNumber = 1;
      this.reportHTML = this.getPageContent(this.pageNumber);
    }
  }

  moveToNextPage(e: any): void {
    if ((this.scrollRef['_trackTopMax'] && this.scrollRef['_currYPos']) >= 0) {
      if (Math.ceil(this.scrollRef['_trackTopMax']) <= Math.ceil(this.scrollRef['_currYPos']) && (e.wheelDeltaY < 0)) {
        if (this.pageNumber < this.pages.length - 1) {
          this.setPage(this.getPageNo('NEXT'));
          this.scrollRef.scrollToTop();
        }
      } else if (this.scrollRef['_currYPos'] == 0 && (e.wheelDeltaY > 0)) {
        if (this.pageNumber != 1) {
          this.setPage(this.getPageNo('PREVIOUS'));
          this.scrollRef.scrollToBottom();
        }
      }
    }
  }

  downloadReport(format: ReportDownloadFormat): void {
    this.options.format=format;
    this.generateAndDownloadReport();
  }


  zoom(action: "IN" | "OUT"): void {
    this.isZoomReference = action;
    if (action == 'IN') {
      if (this.zoomLevel >= 1 && this.zoomLevel <= 4) {
        this.zoomLevel += 0.5;
      }
      document.getElementById("reportData").style['zoom'] = this.zoomLevel + "";
    }
    else if (action == 'OUT') {
      if (this.zoomLevel >= 1.5) {
        this.zoomLevel -= 0.5
      }
      document.getElementById("reportData").style['zoom'] = this.zoomLevel + "";
    }
  }

  print(): boolean {
    let mywindow = window.open('', 'PRINT');
    mywindow.document.write(this.reportDomStringData);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
  }

  GetReportAPIURL(format: ReportDownloadFormat) {
    if (!this.AllowJasperReport) {
      return environment["Report"] + ReportRoutes.GetReport;
    }
  }

  searchtext(searchdata: string): void {
    let content = document.getElementById('reportData').innerHTML;
    let regex = new RegExp("<span style=\"background-color:yellow\">", "gi");
    content = content.replace(regex, "");
    regex = new RegExp("</span>", "gi");
    content = content.replace(regex, "");
    if (searchdata) {
      this.ifCancelBool = true;
      regex = new RegExp(searchdata, "gi");
      content = content.replace(regex, "<span style='background-color:yellow'>" + searchdata + "</span>");
      // document.getElementById('reportData').innerHTML = content;
      this.localization.decodeHTMLEntityByID(content,'reportData') 
    }
    else {
      this.findText = '';
      this.ifCancelBool = false;
      // document.getElementById('reportData').innerHTML = content;
      this.localization.decodeHTMLEntityByID(content,'reportData') 
    }
  }

  private getPageContent(pageNo: number): string {
    let _allStylesContent: string = this.extractAllStyles();
    let _headerHTMLContent: string = '<html><body>' + (this.AllowJasperReport? '' : this.pages[0]) + _allStylesContent;
    let _currentContent: string = "";
    _currentContent = this.AllowJasperReport ? this.pages[pageNo - 1] : this.pages[pageNo];
    this.currentPageNo = pageNo;
    return _headerHTMLContent + _currentContent + '</body></html>';
  }

  private extractAllStyles(): string {
    let _allStylesFromDom: any = this.reportDOM.getElementsByTagName('style');
    let _concatedstyles: string = "";
    for (let i = 0; i < _allStylesFromDom.length; i++) {
      const element = _allStylesFromDom[i].outerHTML;
      _concatedstyles = _concatedstyles + element;
    }
    return _concatedstyles;
  }

  calculateWidthHeight(): void {
    if(this.AllowJasperReport){
      if(document.getElementById('JR_PAGE_ANCHOR_0_1')){
        let reportWidth = document.getElementById('JR_PAGE_ANCHOR_0_1');
        if (reportWidth) {
          let dataWidth = reportWidth.style.cssText.split(";")[1].split(":")[1];
          let reportwidth = Number(dataWidth.replace(" ","").replace('px',''))
          // if(reportWidth.closest("#textcenter-Web"))
          //   reportWidth.closest("#textcenter-Web")['style']['width'] = reportwidth<600? reportwidth+ 203 + 'px':reportwidth+ 300 +'px';
        }
      }
    }
    else
    {
      let reportWidth = document.getElementsByClassName('frpage')[0];
      if (reportWidth) {
        let dataWidth = reportWidth['offsetWidth'];
        reportWidth.closest("#textcenter-Web")['style']['width'] = dataWidth + 77 + 'px';
      }
    }
  }
  //#region Jaspersoft Changes
  getReportsSelectorData() {
    this.reportselector = new ReportControlBuilder(this.localization, this.utils, this.retailFeatureFlagService, this.retailPropertyInfo);
    this.jreportseletor = this.reportselector.getAllReportSelections().filter(x => x.enableJasperReports);
  }

  GetJasperReportDetails(reportcode: string): JasperReportDetails {
    this.AllowJasperReport = false;
    if (this.jreportseletor == undefined || this.jreportseletor.length == 0) {
      this.getReportsSelectorData();
    }
    let data = this.jreportseletor.find((x) => x.code.toString() == reportcode);
    this.isJasperEnabled = sessionStorage.propConfig && JSON.parse(sessionStorage.propConfig).EnableJasperReports?.toString().trim().toLowerCase() === "true" ? true : false;
    if (data != undefined || data != null) {
      this.zoomLevel = 1.3;
      this.AllowJasperReport = true && this.isJasperEnabled;
      var selectedreports = {
        reportCode: data.code.toString(),
        isJaspersoftReport: data.enableJasperReports,
        reportUrlPath: data.reportUrlpath
      } as JasperReportDetails
      this.jreportURL = selectedreports.reportUrlPath
      return selectedreports;
    }
    else {
      this.zoomLevel = 1;
      return;
    }

  }

  seperatePagesForJasper(): void {
    let finalHtml: string = "";
    let i = 1;
    while (1) {
      const element: HTMLDomElement = document.getElementById('JR_PAGE_ANCHOR_0_' + i);
      let currentHTML: string = "";
      currentHTML = element.outerHTML;
      finalHtml = finalHtml + currentHTML;
      this.pages.push(finalHtml);
      finalHtml = '';
      i++;
      if (!document.getElementById('JR_PAGE_ANCHOR_0_' + i)) {
        break;
      }
    }
    this.pages.push(finalHtml);
  }


  //#endregion
}


