import { FormQueryConfig } from './form-query.interface';
import { MasterQueryListLookupTabClick } from './master-query-tab-click.interface';
import { QueryListLookup } from './query-lookup.interface';
import { TransMasterQueryListLookup } from './query-master-transaction.interface';
import { TransQueryListLookup } from './tranasction-query-lookup.interface';
import { TransQueryListLookupTabClick } from './transaction-query-tab-click.interface';

export const QueryListInterface = {
     QueryEngine: FormQueryConfig,
     QueryListData: QueryListLookup,
     QueryTransData: TransQueryListLookup,
     QueryTransMaster: TransMasterQueryListLookup,
     QueryTransDataTabClick: TransQueryListLookupTabClick,
     QueryMasterDataTabClick: MasterQueryListLookupTabClick
};