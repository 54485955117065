import { Injectable } from "@angular/core";
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from "../shared/shared/utilities/common-utilities";
import { GuestDataPolicyDataService } from "../dataservices/guest-datapolicy.data.service";
import { DataGroupUIModel } from "./data-retention.model";

@Injectable()
export class DataRetentionBusiness {
  captions: any;
  constructor(
    private localization: Localization, private utilities: CommonUtilities, private guestDataPolicyDataService: GuestDataPolicyDataService) {
    this.captions = this.localization.captions;
  }

  async GetDataGroups(policyId: number, categoryId: number): Promise<any> {
    let tenantId = Number(this.utilities.GetPropertyInfo("TenantId"));
    const dataGroups: DataGroupUIModel[] = await this.guestDataPolicyDataService.GetDataGroups(tenantId, policyId, categoryId);
    return this.MapDataGroup(dataGroups);
  }
  MapDataGroup(value: DataGroupUIModel[]) {
    return [{
      id: 1,
      value: 0,
      viewValue: "Guest Details",
      isSelected: true,
      checked: false,
      selectedList: value.map(v => {
        return {
          checked: false,
          disabled: false,
          id: v.dataGroupId,
          outletId: 0,
          value: v.dataGroupId,
          viewValue: v.dataGroupName,
        }
      })
    }]
  }
}