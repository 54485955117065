import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagefiletype'
})
export class ImageTypePipe implements PipeTransform {
  transform(FileType: any, args: any): string {
    let imageName;
    const type = (FileType === 'workbook' || FileType === 'json' || FileType === 'ppt' || 
     FileType === 'zip' || FileType === 'text' || FileType === 'Image') ? 
     FileType : FileType.split('.')[1];
     if (type) {
      switch (type.toString().toLowerCase()) {
        case 'pdf':
          imageName = 'app/eatecui/assets/images/20px_PDF-ii.svg';
          break;
        case 'doc':
          imageName = 'app/eatecui/assets/images/20px_DOC-ii.svg';
          break;
        case 'docx':
        imageName = 'app/eatecui/assets/images/20px_DOCX-ii.svg';
        break;
        case 'workbook':
          imageName = 'app/eatecui/assets/images/excel.jfif';
          break;
        case 'json':
          imageName = 'app/eatecui/assets/images/json.png';
          break;
        case 'ppt':
          imageName = 'app/eatecui/assets/images/ppt.png';
          break;
        case 'zip':
          imageName = 'app/eatecui/assets/images/zip.jfif';
          break;
        case 'text':
          imageName = 'app/eatecui/assets/images/text.png';
          break;
        case 'image':
            imageName = 'app/eatecui/assets/images/image.png';
            break;
        default:
          imageName = 'app/eatecui/assets/images/pdf.png';
      } 
     } else {
      imageName = 'app/eatecui/assets/images/image.png';
     }
   return imageName;
  }

}
