import { Component, OnInit, Inject, ViewChild, ViewEncapsulation, OnDestroy, NgZone, AfterViewChecked, HostListener } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { SettingsService } from '../../settings.service';
import { AlertAction, AlertType, ButtonType, GridType, Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { PackageDetail, PackageComponent, PackageAvailableDays, PackageInfo, PackageYield, PackageAppointmentDate, ItemSearchRequest, ItemSearchBaseResponse, RetailItemUIMapper, ServiceUIMapper, IncDec, PackageWeeklyPrices, PackageYieldGroupDetails } from '../../../shared/business/view-settings.modals';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { cloneDeep } from 'lodash';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { DaysLocaleSorted_app_multiple, BaseResponse } from '../../../shared/business/shared.modals';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { MultipleSelectComponent } from '../../../shared/multiple-select/multiple-select.component';
import { Subscription } from 'rxjs';
import { MoreSectionServiceService } from '../../../shared/more-section/more-section-service.service';
import _ from 'lodash';
import { Days } from 'src/app/appointment/spa-wizard/spa-wizard.modal';


@Component({
  selector: 'app-spa-package',
  templateUrl: './spa-package.component.html',
  styleUrls: ['./spa-package.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class SpaPackageComponent implements OnInit, AfterViewChecked, OnDestroy {


  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('availableDays') availableDays: MultipleSelectComponent;
  captions: any = this.localization.captions.setting;
  commonCaptions: any = this.localization.captions.common;
  minValue = 1;
  minDate = this.PropertyInfo.CurrentDate;
  minEndDate = this.PropertyInfo.CurrentDate;
  pakageStartDate: Date;
  packageEndDate: Date;
  maxStartDate: any;
  packageYieldStartDate: Date = this.PropertyInfo.CurrentDate;
  packageYieldMinEndDate = this.PropertyInfo.CurrentDate;
  type1: any = 'type1';
  type2: any = 'type2';
  type3: any = 'type3';
  IsComponentSelectable = false;
  totalPrice = 0;
  totalServicePrice = 0;
  totalRetailPrice = 0;
  totalServiceAmount = 0;
  totalServicePercent = 0;
  totalRetailAmount = 0;
  totalRetailPercent = 0;
  totalPackageAmount = 0;
  totalPackagePercent = 0;
  minimumYeildingAmount = 0;
  totalServiceQuantityAmount = 0;
  totalRetailQuantityAmount = 0;
  totalPakageQuantityAmount = 0;
  fixedService = 0;
  variableService = 0;
  minimumVariableServiceCount = 1;
  minimumVariableRetailCount = 1;
  totalServiceComponent = 0;
  fixedRetail = 0;
  variableRetail = 0;
  totalRetailComponent = 0;
  totalComponent = 0;
  selected: any;
  clickbutton: any = this.captions.SAVE;
  toggleAction = false;
  selectedIndex = 0;
  cancellationPolicy: string;
  dayValue: string;
  IsValid = true;
  sysDate = this.PropertyInfo.CurrentDate;
  packageAppointmentDate: PackageAppointmentDate;
  startDateError: string = this.captions.InvalidStartDate;
  endDateError: string = this.captions.InvalidEndDate;
  editStartDate: Date;
  editEndDate: Date;
  selectedPackageComponent: any[] = [];
  disableCurrencyFormat = false;
  selectedRetailItemId: number;
  retailItemMiss = true;
  retailItemDirty = false;
  selectedYieldDaysArr: number[] = [];
  isReadOnly = false;
  packageStepValid = false;
  startDateSubscription: Subscription;
  endDateSubscription: Subscription;
  packageyieldStartDateSubscription: Subscription;
  packageyieldEndDateSubscription: Subscription;
  multiselect: any;
  Availabledays: any;
  Yield_Availabledays: any;
  selectedItem: any = [];
  categoryIdx: boolean;
  serviceGrpCount: number;
  viewChecked: boolean = true;
  packageRetailItemType: number = 2;
  spaServices = [];
  spaRetailItems = [];
  selectedSerivce = [];
  ServiceRetail=[];
  ItemType:number=2;
  public numberArray: any = [
    { 'name': '1', 'setSelected': true },
    { 'name': '2', 'setSelected': false },
    { 'name': '3', 'setSelected': false },
    { 'name': '4', 'setSelected': false },
    { 'name': '5', 'setSelected': false }
  ];
  public autoPickArray: any = [
    { 'name': this.captions.never, 'setSelected': true },
    { 'name': this.captions.always, 'setSelected': false },
    { 'name': this.captions.ask, 'setSelected': false }
  ];

  public arrayValues: DaysLocaleSorted_app_multiple[] = [];
  public daysArrForPackageYield: DaysLocaleSorted_app_multiple[] = [];

  public commnArray: any = [
    { 'name': 'email 1', 'setSelected': false },
    { 'name': 'email 2', 'setSelected': false },
    { 'name': 'email 3', 'setSelected': false },
    { 'name': 'email 4', 'setSelected': false },
    { 'name': 'email 5', 'setSelected': false }
  ];
  public packageClass: any = [];
  public categoriesTemp: any[] = [];
  packageYieldJson = [];
  copyPackageInformation: PackageInfo;
  oldPackageYield: PackageYield[] = [];
  serviceArray = [];
  RetailItemsArray = [];
  retailItemCount = 0;
  serviceItemCount = 0;
  tableoptions = [
    {
      TableHdrData: [{ 'title': this.captions.ServiceRetailCode, 'jsonkey': 'service' },
      { 'title': this.captions.Description, 'jsonkey': 'desc' },
      { 'title': `${this.captions.Price} (${this.localization.currencySymbol})`, 'jsonkey': 'price', 'datatype': 'money', 'alignType': 'right' }],
      TablebodyData: [],
      pagination: false,
      sortable: false,
      CustomColumn: true,
      PlaceHoldertext: this.captions.ServiceRetailCodeDescription,
      EnableActions: false,
      SelectRows: true,
      IsCommission: true,
      SelectedSettingId: GridType.spaPackage,
      SelectedData: this.selectedPackageComponent,
      Searchable: false,
      EditMoreOption: true,
      toggleDisplayText: this.captions.ServicesOnly,
      isEditOptionRemove: true
    }
  ];
  spaPackageForm: UntypedFormGroup;
  spaPackage: UntypedFormGroup;
  spaPackageService: UntypedFormGroup;
  spaPackageRetailItems: UntypedFormGroup;
  spaPackagePolicy: UntypedFormGroup;
  spaPackageYield: UntypedFormGroup;
  defaultCancelPolicy: string ;
  availableValue = 0;
  soldValue = 0;
  AllServices: any;
  retailItemsOutlet:any;
  AllretailItems: any;
  id: number;
  isUserAuthorized = true;
  spaPackageDetailServices: [];
  isViewOnly = true;
  dialogSubscription: Subscription;
  Active = false;
  Component = false;
  Recap = false;
  Lunch = false;
  Web = false;
  defaultPolicy = false;
  serviceOnly = false;
  PplArr = [];
  DayArr = [];
  autoPickArr = [];
  yeildDayArr = [];
  commnArr = [];
  SelectedServiceObjects = [];
  checkedItems: any[] = [];
  checkedComponentItems: any[] = [];
  categories: any = [];
  selectedCategories: any = [];
  deviceName: string;
  floatLabel: string;
  retailItemType = 4;
  searchFilter: ItemSearchBaseResponse[] = [];
  guid: string;
  placeholderFormat: string;
  retailItemId: number;
  quantity=1;
  retailItemPrice:any;
  divideServicePrice:any;
  calculatedRetailPrice:number;
  yeildingTableHeader = [];
  yeildingTableData = [];
  minFromDate: Date;
  activeSeasonalSetupFormGrp: number;
  yeildArray= [];
  yeildTableData = [];
  servicePkgData = [];
  slecetedServicesList = [];
  checkedYeildItems =[];
  isExpanded = false;
  searchText = '';
  noPriceChange : boolean;
  days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  unfilteredTableData = [];
  isServiceIncreased = 0;
  isRetailPriceIncreased = 0;
  isPackagePriceIncreased = 0;
  enableNext: boolean = true;
  variableLength = 3;
  DefaultCancelationPolicy: string;
  isServiceNegative:boolean=false;
  isRetailNegative:boolean=false;
  throttleTime: number = 1000;
  
  constructor(public fb: UntypedFormBuilder,
    private BP: BreakPointAccess,
    public localization: SpaLocalization,
    @Inject(MAT_DIALOG_DATA) public data, public _su: SettingsService,
    public dialogRef: MatDialogRef<SpaPackageComponent>,
    public dialog: MatDialog,
    private http: HttpServiceCall,
    public business: ViewSettingClientBusiness,
    private utils: SpaUtilities,
    private PropertyInfo: SpaPropertyInformation,
    public _MoreSectionServiceService: MoreSectionServiceService,
    private ngZone: NgZone) {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
    this.arrayValues = this.utils.getShortDaysOfWeekModel(true);

    this.daysArrForPackageYield = this.utils.getShortDaysOfWeekModel(false);
    // Auto pick default
    this.autoPickArr = [this.captions.never];
    // Active default
    this.Active = true;
    // default number of days
    this.DayArr = ['1'];
    // default number of people
    this.PplArr = ['1'];
    this.commnArr = [''];
    // default end date = startdate + 1 year
    const todayDate = this.PropertyInfo.CurrentDate;
    const year = todayDate.getFullYear();
    const month = todayDate.getMonth();
    const day = todayDate.getDate();
    const defaultPackageEndDate = new Date(year + 1, month, day);
    this.isReadOnly = this.data.popupConfig.operation === 'edit';

    window.onresize = (e) => {
      this.ngZone.run(() => {
        this.countCalc();

      });
    };

    this.spaPackage = this.fb.group({
      packageCode: ['', Validators.required],
      packageDescription: ['', [Validators.required, EmptyValueValidator]],
      servicePrice:[''],
      retailPrice:[''],
      packagePrice: [''],
      startDate: [todayDate, Validators.required],
      endDate: [defaultPackageEndDate, Validators.required],
      availableDays: [''],
      numberOfPeople: ['1'],
      quantity:['1'],
      autoPick: String['yes'],
      maximumDays: ['1'],
      activeStatus: [''],
      isPackageYield: [''],
      bookingComponentStatus: [''],
      bookingComponentCount: [{ value: '', disabled: (!this.IsComponentSelectable || this.isReadOnly) }],
      recap: [''],
      bookLunch: [''],
      serviceChecked: [''],
      activeToggle: this.Active,
      autoRecap: this.Recap,
      bookLunchToggle: this.Lunch,
      isAvailableOnWeb: this.Web,
      linkedItem: ['', Validators.required],
    });

    this.spaPackageService = this.fb.group({
      priceType: 0,
      isComponentSelected:this.Component,
      serviceVariable: 0,
      retailVariable: 0,
      topBottom: true,
      serviceTableView: 0,
      selectedServices: this.fb.array([]),
      selectedRetailServices: this.fb.array([

      ])
    });
    
    this.spaPackagePolicy = this.fb.group({
      packagePolicy: [''],
      defaultPolicy: false,
      cancellationPolicy: ['']
    });

    this.spaPackageYield = this.fb.group({
      packageClassData: [''],
      availableValue: [''],
      startDate: '',
      endDate: '',
      yeildDataArray : this.fb.array([])
    });
    this.spaPackageForm = this.fb.group({
      package: this.spaPackage,
      service: this.spaPackageService,
      policy: this.spaPackagePolicy,
      yield: this.spaPackageYield
    });

    this.yeildingTableHeader = [
      { id:4,displayName: this.captions.allDays},
      { id:5,displayName: this.captions.lbl_mon},
      { id:6,displayName: this.captions.lbl_tue},
      { id:7,displayName: this.captions.lbl_wed},
      { id:8,displayName: this.captions.lbl_thurs},
      { id:9,displayName: this.captions.lbl_fri},
      { id:10,displayName: this.captions.lbl_sat},
      { id:11,displayName: this.captions.lbl_sun},
    ]
    
    this.minFromDate = this.PropertyInfo.CurrentDate;
    this.createYeildingFormArray();
    if (data.popupConfig.operation === 'edit') {
      this.clickbutton = this.captions.UPDATE;
      this.makeDirty();
      const valueArray = data.datarecord as PackageInfo;
      this.selectedRetailItemId = valueArray.packageDetail.retailItemId;
      this.retailItemMiss = this.selectedRetailItemId > 0 ? false : true;
      if (this.utils.getDate(valueArray.packageDetail.startDate) > this.PropertyInfo.CurrentDate) {
        this.minDate = this.PropertyInfo.CurrentDate;
      }
      else {
        this.minDate = this.utils.getDate(valueArray.packageDetail.startDate);
      }

      this.IsComponentSelectable = valueArray.packageDetail.isSelectCompAtBooking;
      this.onDisableComponentField();
      this.cancellationPolicy = valueArray.packageDetail.cancellationPolicy;
      this.oldPackageYield = valueArray.packageYields;
      this.id = valueArray.id;
      this.spaPackage.controls.packageCode.setValue(valueArray.packageDetail.code);
      this.spaPackage.controls.packageDescription.setValue(valueArray.packageDetail.description);
      this.disableCurrencyFormat = true;
      this.spaPackage.controls.packagePrice.setValue(this.localization.localizeCurrency(valueArray.packageDetail.packagePrice.toString(), false));
      this.spaPackage.controls.startDate.setValue(this.utils.getDate(valueArray.packageDetail.startDate));
      this.spaPackage.controls.endDate.setValue(this.utils.getDate(valueArray.packageDetail.endDate));
      this.DayArr[0] = valueArray.packageDetail.maxDays.toString();
      this.PplArr[0] = valueArray.packageDetail.maxPeople.toString();
      this.spaPackage.controls.maximumDays.setValue(Number(valueArray.packageDetail.maxDays));
      this.spaPackage.controls.numberOfPeople.setValue(Number(valueArray.packageDetail.maxPeople));
      this.spaPackage.controls.isAvailableOnWeb.setValue(valueArray.packageDetail.isAvailableOnWeb);

      if (valueArray.packageDetail.autopick === 1) {
        this.autoPickArr[0] = this.captions.never;
      } else if (valueArray.packageDetail.autopick === 2) {
        this.autoPickArr[0] = this.captions.always;
      } else if (valueArray.packageDetail.autopick === 3) {
        this.autoPickArr[0] = this.captions.ask;
      }

      this.Active = valueArray.packageDetail.isActive;
      this.Component = valueArray.packageDetail.isSelectCompAtBooking;
      this.spaPackageService.controls.serviceVariable.setValue(valueArray.packageDetail.numberOfItems);
      this.spaPackageService.controls.retailVariable.setValue(valueArray.packageDetail.numberOfRetailItems);
      this.spaPackageService.controls.topBottom.setValue(valueArray.packageDetail.isTopCompAtBooking);
      this.Lunch = valueArray.packageDetail.isBookLunch;
      this.Recap = valueArray.packageDetail.isAutomaticRecap;
      this.Web = valueArray.packageDetail.isAvailableOnWeb;
      this.spaPackageService.controls.isComponentSelected.setValue(this.Component);
      this.spaPackage.controls.activeToggle.setValue(this.Active);
      this.spaPackage.controls.autoRecap.setValue(this.Recap);
      this.spaPackage.controls.bookLunchToggle.setValue(this.Lunch);
      this.spaPackage.controls.isAvailableOnWeb.setValue(this.Web);
      this.spaPackagePolicy.controls.packagePolicy.setValue(valueArray.packageDetail.packagePolicy);
      this.spaPackagePolicy.controls.defaultPolicy.setValue(valueArray.packageDetail.isDefaultCancelPolicy);
      this.defaultPolicy = valueArray.packageDetail.isDefaultCancelPolicy;
      this.commnArr[0] = valueArray.packageDetail.emailCommunicationType;


      /* Set Saved available days */

      this.setPackageAVailableDaysControl(valueArray.availableDays);

      this.checkedItems = [];
      const componentLength = valueArray.packageComponents.length;

      let retailCount=0,spaCount=0;
      for (let i = 0; i < componentLength; i++) {
        let item:any=valueArray.packageComponents[i];
        // these lines are added for in this code before save we are using basePrice as price and total price as amount
        item.amount=this.localization.localizeCurrency(item.price,false);
        item.price=this.localization.localizeCurrency(item.basePrice,false);
        item.fixedVariable=item.isFixedComponent;
        item.percent=this.localization.localizeCurrency(item.percentage,false);
        this.checkedItems.push(item);
        if (item.serviceId == 0) {
          this.spaRetailItems.push(item);
          this.totalRetailPrice = this.totalRetailPrice + Number(this.spaRetailItems[retailCount].price);
          retailCount++;
        }
        else {
          this.spaServices.push(item);
          this.totalServicePrice = this.totalServicePrice + Number(this.spaServices[spaCount].price);
          spaCount++;
        }
        this.totalPrice = this.totalPrice + Number(this.checkedItems[i].price);
      }
      this.setPackageYieldGroupDetails(valueArray.packageYields,valueArray.packageYieldGroupDetails);
      if (!this.defaultPolicy) {
        this.spaPackagePolicy.controls.cancellationPolicy.setValue(valueArray.packageDetail.cancellationPolicy);
      } else {
        this.spaPackagePolicy.controls.cancellationPolicy.setValue(valueArray.packageDetail.cancellationPolicy);
      }

      if (valueArray.packageYields == null) {
        this.spaPackageYield.controls.packageClassData.setValue('');
        this.spaPackageYield.controls.startDate.setValue('');
        this.spaPackageYield.controls.availableValue.setValue('');
        this._su.type2Array = [''];
        this.spaPackageYield.controls.endDate.setValue('');
      } else {
        const yieldCount = valueArray.packageYields.length;

        if (yieldCount === 0) {
          this.spaPackageYield.controls.packageClassData.setValue('');
          this.spaPackageYield.controls.startDate.setValue('');
          this.spaPackageYield.controls.availableValue.setValue('');
          this._su.type2Array = [''];
          this.spaPackageYield.controls.endDate.setValue("");
        }

        else {
          if (yieldCount == 1) {
            this.spaPackageYield.controls.endDate.setValue(this.utils.getDate(valueArray.packageYields[0].yieldDate));
          }
          else if (yieldCount > 1) {
            this.spaPackageYield.controls.endDate.setValue(this.utils.getDate(valueArray.packageYields[yieldCount - 1].yieldDate));
          }

          this.spaPackageYield.controls.packageClassData.setValue(valueArray.packageYields[0].packageClass);
          this.spaPackageYield.controls.startDate.setValue(this.utils.getDate(valueArray.packageYields[0].yieldDate));
          this.spaPackageYield.controls.availableValue.setValue(valueArray.packageYields[0].available);
          this.setYieldingAvailableDays();
        }
      }
    }
    else {
      this._su.type1Array = this.arrayValues.map(x => x.name);
    }
    this.business.activeFormGroup = this.spaPackage;
    this.createSpaService();
    this.calculateTotalPrice();
  } // end of constructor

  setPackageYieldGroupDetails(packageYields,packageYieldGroupDetails){
    this.yeildArray=[];
    this.yeildTableData=[];
    for(let packageYield of packageYieldGroupDetails)
      {
        let isMonAvailable=false;
        let isTueAvailable=false;
        let isWedAvailable=false;
        let isThuAvailable=false;
        let isFriAvailable=false;
        let isSatAvailable=false;
        let isSunAvailable=false;
        let isAllDaysAvailable= true;
        for (let currentDate = new Date(this.utils.getDate(packageYield.yieldStartDate)); currentDate <= this.utils.getDate(packageYield.yieldEndDate); currentDate.setDate(currentDate.getDate() + 1)) {
          
          const currentDateDateString = this.utils.getDate(currentDate);

          // Filter the valueArray.packageYields array
          const matchingYields = packageYields.filter(yieldItem => {
            // Assuming utils.getDate() extracts the date part of a yieldDate property
            const yieldDateString = this.utils.getDate(yieldItem.yieldDate);
            return this.utils.ValidateDatesAreEqual(yieldDateString,currentDate);
          });
          switch (currentDate.getDay()) {
              case 0: // Sunday
                  isSunAvailable = matchingYields.length>0;
                  break;
              case 1: // Monday
                  isMonAvailable = matchingYields.length>0;
                  break;
              case 2: // Tuesday
                  isTueAvailable = matchingYields.length>0;
                  break;
              case 3: // Wednesday
                  isWedAvailable = matchingYields.length>0;
                  break;
              case 4: // Thursday
                  isThuAvailable = matchingYields.length>0;
                  break;
              case 5: // Friday
                  isFriAvailable = matchingYields.length>0;
                  break;
              case 6: // Saturday
                  isSatAvailable = matchingYields.length>0;
                  break;
          }
          if(matchingYields.length==0)
          isAllDaysAvailable = false;
      }
        let Monday = packageYield.packageWeeklyPrices.reduce((total, item) => {
          let weeklyPriceObject = JSON.parse(item.weeklyPrice);
          let mondayPrice = weeklyPriceObject["Monday"];
          return total + mondayPrice;
        }, 0);
        let Tuesday = packageYield.packageWeeklyPrices.reduce((total, item) => {
          let weeklyPriceObject = JSON.parse(item.weeklyPrice);
          let tuesdayPrice = weeklyPriceObject["Tuesday"];
          return total + tuesdayPrice;
        }, 0);
        let Wednesday = packageYield.packageWeeklyPrices.reduce((total, item) => {
          let weeklyPriceObject = JSON.parse(item.weeklyPrice);
          let wednesdayPrice = weeklyPriceObject["Wednesday"];
          return total + wednesdayPrice;
        }, 0);
        let Thursday = packageYield.packageWeeklyPrices.reduce((total, item) => {
          let weeklyPriceObject = JSON.parse(item.weeklyPrice);
          let thursdayPrice = weeklyPriceObject["Thursday"];
          return total + thursdayPrice;
        }, 0);
        let Friday = packageYield.packageWeeklyPrices.reduce((total, item) => {
          let weeklyPriceObject = JSON.parse(item.weeklyPrice);
          let fridayPrice = weeklyPriceObject["Friday"];
          return total + fridayPrice;
        }, 0);
        let Saturday = packageYield.packageWeeklyPrices.reduce((total, item) => {
          let weeklyPriceObject = JSON.parse(item.weeklyPrice);
          let saturdayPrice = weeklyPriceObject["Saturday"];
          return total + saturdayPrice;
        }, 0);
        let Sunday = packageYield.packageWeeklyPrices.reduce((total, item) => {
          let weeklyPriceObject = JSON.parse(item.weeklyPrice);
          let sundayPrice = weeklyPriceObject["Sunday"];
          return total + sundayPrice;
        }, 0);
        let allDays=0;
        if (Monday === Tuesday && Monday === Wednesday && Monday === Thursday && Monday === Friday && Monday === Saturday && Monday === Sunday) {
          // All values are the same, store the value of Monday
          allDays = Monday;
      }
        let tempYield={
          id: 0,
          startDate: this.utils.getDate(packageYield.yieldStartDate),
          endDate: this.utils.getDate(packageYield.yieldEndDate),
          minStartDate: this.utils.getDate(packageYield.yieldStartDate),
          minEndDate: this.utils.getDate(packageYield.yieldStartDate),
          availableValue: packageYield.available,
          packageClass: packageYield.packageClass,
          allDays: allDays,
          checked: false,
          mon: Monday,
          tue: Tuesday,
          wed: Wednesday,
          thu: Thursday,
          fri: Friday,
          sat: Saturday,
          sun: Sunday,
          isAllDaysAvailable:isAllDaysAvailable,
          isMonAvailable: isMonAvailable,
          isTueAvailable: isTueAvailable,
          isWedAvailable: isWedAvailable,
          isThuAvailable: isThuAvailable,
          isFriAvailable: isFriAvailable,
          isSatAvailable:  isSatAvailable,
          isSunAvailable:  isSunAvailable,
          yeildServicesArray : []
        }
        this.yeildArray.push(tempYield);
      }
      this.patchYeildFormData();
  }

  //disable component
  onDisableComponentField() {
    if (!this.IsComponentSelectable || this.isReadOnly) {
      this.spaPackage.get('bookingComponentCount').disable();
    } else {
      this.spaPackage.get('bookingComponentCount').enable();
    }
  }

  createYeildingFormArray(){
    this.servicePkgData = [];
    if(this.IsComponentSelectable)
    {
      for(let service of this.checkedComponentItems){
        this.servicePkgData.push(this.fb.group(
          {
            serviceId: service.serviceId ? service.serviceId : 0,
            retailItemId: service.retailItemId,
            serviceName: service.description,
            quantity: service.quantity,
            amount: service.amount,
            allowPriceChange : service.allowPriceChange,
            isServiceComponent:service?.isServiceComponent?service.isServiceComponent:false,
            componentIndex:service?.componentIndex?service.componentIndex:0,
            mon: "",
            tue: "",
            wed: "",
            thu: "",
            fri: "",
            sat: "",
            sun: "",
          }
        ))
      }
    }
    else
    {
    for(let service of this.checkedItems){
      this.servicePkgData.push(this.fb.group(
        {
          serviceId: service.serviceId ? service.serviceId : 0,
          retailItemId: service.retailItemId,
          serviceName: service.description,
          quantity: service.quantity,
          amount: service.amount,
          allowPriceChange : service.allowPriceChange,
          isServiceComponent:service?.isServiceComponent?service.isServiceComponent:false,
          componentIndex:service?.componentIndex?service.componentIndex:0,
          mon: "",
          tue: "",
          wed: "",
          thu: "",
          fri: "",
          sat: "",
          sun: "",
        }
      ))
    }
  }
      
    return this.fb.group({
      id: 0,
      startDate: ['',Validators.required],
      endDate: ['',Validators.required],
      minStartDate: this.minFromDate,
      minEndDate: '',
      availableValue: '',
      packageClass: '',
      allDays: 0,
      checked: false,
      isAllDaysAvailable: false,
      isMonAvailable: false,
      isTueAvailable: false,
      isWedAvailable: false,
      isThuAvailable: false,
      isFriAvailable: false,
      isSatAvailable: false,
      isSunAvailable: false,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
      yeildServicesArray : this.fb.array(this.servicePkgData)
    });
  }

  patchYeildFormData(){
   

    for(let item of this.yeildArray){
       this.yeildTableData.push(this.fb.group({
        id: item.id,
        startDate: item.startDate,
        endDate: item.endDate,
        minStartDate: item.minStartDate,
        minEndDate: item.minEndDate,
        availableValue: item.availableValue,
        packageClass: item.packageClass,
        allDays: item.allDays?item.allDays: 0,
        checked: item.checked,
        mon: item.mon ? item.mon: 0,
        tue: item.tue ? item.tue: 0,
        wed: item.wed ? item.wed: 0,
        thu: item.thu ? item.thu: 0,
        fri: item.fri ? item.fri: 0,
        sat: item.sat ? item.sat: 0,
        sun: item.sun ? item.sun: 0,
        isAllDaysAvailable: item.isAllDaysAvailable ? item.isAllDaysAvailable : false,
        isMonAvailable: item.isMonAvailable ? item.isMonAvailable: false,
        isTueAvailable: item.isTueAvailable ? item.isTueAvailable: false,
        isWedAvailable: item.isWedAvailable ? item.isWedAvailable: false,
        isThuAvailable: item.isThuAvailable ? item.isThuAvailable: false,
        isFriAvailable: item.isFriAvailable ? item.isFriAvailable: false,
        isSatAvailable: item.isSatAvailable ? item.isSatAvailable: false,
        isSunAvailable: item.isSunAvailable ? item.isSunAvailable: false,
        yeildServicesArray : this.fb.array(this.servicePkgData)
       }))
      
    }
    this.spaPackageYield = this.fb.group({
      packageClassData: [''],
      availableValue: [''],
      startDate: '',
      endDate: '',
      yeildDataArray : this.fb.array(this.yeildTableData)
    });
    this.yeildArray.forEach((value, index) => {
      this.enableOrDisableDays(index);
    });
  }

  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingSpaPackage]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingSpaPackage);
    if (this.isViewOnly) {
      this.utils.disableControls(this.spaPackage);
    }
  }

  private setYieldingAvailableDays(): void {
    // take top 8 days to find out unique days of the week to be selected
    let selectedDays: string[] = this.oldPackageYield.slice(0, 8).map(py => this.localization.getDayForDate(this.utils.getDate(py.yieldDate)));
    selectedDays = this.utils.removeDuplicates(selectedDays);
    const toBeSelectedArr: DaysLocaleSorted_app_multiple[] = [];
    this.daysArrForPackageYield.forEach(av => {
      if (selectedDays.includes(av.name)) {
        av.setSelected = true;
      } else {
        av.setSelected = false;
      }
    });
    if (selectedDays.length === 7) {
      this.daysArrForPackageYield = this.utils.getShortDaysOfWeekModel(true);
    }
    this.daysArrForPackageYield = cloneDeep(this.daysArrForPackageYield);
  }
  // deleting the selected service
  deleteRow(item, index) {
    this.spaPackage.markAsDirty();        
      if(item!=null){
        if(item.value.serviceId==0){
          if(item.value.id!=null){
        this.checkedItems=this.checkedItems.filter(c=>c.retailItemId !=item.value.id);
          }
          else{
          this.checkedItems=this.checkedItems.filter(c=>c.code!=item.value.code);
          }
        }
        else{
          this.checkedItems=this.checkedItems.filter(c=>c.id!=item.value.id);
        }
    }
 
 
  let tableData;
  if (this.selectedCategories.length === 0) {
    tableData = this.ServiceRetail;
  } else {
    tableData = this.ServiceRetail.filter(res => this.selectedCategories.map(a => (a.id ? a.id : a)).indexOf(res.serviceGroupId) > -1);
  }
  //this.UpdateComponentPrices();
  this.createSpaService();
  this.createYeilding();
  this.checkQuantity();
  //this.addValue(this.checkedItems);
  this.setTableData(tableData);
  this.calculateTotalPrice();
  }

  onComponentValueChange(e) {
    if (e.currentTarget.value !== '') {
      this.IsValid = false;
    } else {
      this.IsValid = true;
    }
  }

  validateNext(type) {
    if (this.data.popupConfig.operation == "edit") {
      if (type == "packageDetail") {
        if (!this.spaPackage.valid) {
          return false;
        } else if (this.retailItemMiss) {
          return false;
        } else if (this.spaPackage.controls.startDate.value == "" || this.spaPackage.controls.endDate.value == null) {
          return false;
        } else if (this.spaPackage.controls.packageDescription.value == "" || this.spaPackage.controls.packageDescription.value == null) {
          return false;
        }

        // if (this.Component && Number(this.spaPackage.controls.bookingComponentCount.value) == 0) {
        //   this.spaPackage.controls.bookingComponentCount.setErrors({ incorrect: true });
        //   return false;
        // }
        return true;
      } else if (type == "services") {
        return true;
      } else if (type == "policies") {
        return true;
      } else if (type == "yielding") {
        return true;
      }
    } else {
      if (type == "packageDetail") {
        if (this.retailItemMiss) {
          return false;
        }

        // if (this.Component && Number(this.spaPackage.controls.bookingComponentCount.value) == 0) {
        //   this.spaPackage.controls.bookingComponentCount.setErrors({ incorrect: true });
        //   return false;
        // }

        if (this.spaPackage.valid) {
          return true;
        } else {
          return false
        }
      } else if (type == "services") {
        return true;
      } else if (type == "policies") {
        return true;
      } else if (type == "yielding") {
        if (this.spaPackageYield.valid) {
          return true;
        } else {
          return false;
        }
      }
    }
  }



  componentCountKeyDown(e) {
    const input = e.key;
    if (parseInt(input) === 0 && e.currentTarget.value === '') {
      e.preventDefault();
    }
  }



  selectedService(item) {
    if ((item.id === 0)) {
      this.selectedCategories = [...this.utils.getToggleAllFilter(this.categoriesTemp, this.selectedCategories)];
    } else {
      this.selectedCategories = [...this.utils.getToggleFilter(this.categoriesTemp, this.selectedCategories, item)];
    }
    var value = this.AllServices.filter(res => this.selectedCategories.length === 0 || this.selectedCategories.indexOf(res.serviceGroupId) > -1);
        if(this.selectedCategories.length==0)
        {
          value=this.ServiceRetail;
        }
    this.setTableData(value);
  }

  expandCollapse() {
    this.toggleAction = !this.toggleAction;
    if (this.toggleAction) {
      this.countCalc();
    }
  }

  populateData(data) {
    this.tableoptions = [{
      TableHdrData: data[0].TableHdrData,
      TablebodyData: data[0].TablebodyData,
      pagination: false,
      sortable: false,
      CustomColumn: true,
      PlaceHoldertext: this.captions.ServiceRetailCodeDescription,
      EnableActions: false,
      SelectRows: true,
      IsCommission: true,
      SelectedSettingId: GridType.spaPackage,
      SelectedData: this.selectedPackageComponent,
      Searchable: false,
      EditMoreOption: true,

      toggleDisplayText: this.captions.ServicesOnly,
      isEditOptionRemove: true
    }];
    this.unfilteredTableData = data[0].TablebodyData;
  }

  toggleBtnClick = ($event, frm, Driven) => {
    this.spaPackage.markAsDirty();
    if (frm === 'People') {
      this.spaPackage.controls.numberOfPeople.setValue(Driven);
      this.PplArr = [];
      this.PplArr.push(Driven);
    }
    if (frm === 'Days') {
      this.spaPackage.controls.maximumDays.setValue(Driven);
      if (this.checkMaxDaysWithSelectedDate(Driven)) {
        this.DayArr = [];
        this.DayArr.push(Driven);
      }
    }
    if (frm === 'autoPick') {

      this.autoPickArr = [];
      this.autoPickArr.push(Driven);
    }
    if (frm === 'Comm') {

      this.commnArr = [];
      this.commnArr.push(Driven);
    }
    if (frm === 'availableDays') {
      this.arrayValues.forEach(element => {
        if (element.name === Driven) {
          if (element.setSelected) {
            element.setSelected = false;
          } else {
            element.setSelected = true;
          }
        }
      });
      if (this.availableDays) {
        this.availableDays.initValues();
      }
    }
  }

  sliderChange($event, item) {
    switch (item) {
      case 'active':
        this.Active = !this.Active;
        break;
      case 'recap':
        this.Recap = !this.Recap;
        break;
      case 'lunch':
        this.Lunch = !this.Lunch;
        break;
      case 'web':
        this.Web = !this.Web;
        break;
      case 'defaultPolicy':
        this.defaultPolicy = $event[0];
        if (!this.defaultPolicy) {
          if (this.selectedItem) {
            this.spaPackagePolicy.controls.cancellationPolicy.setValue(this.selectedItem.cancellationPolicy);
          } else {
            this.spaPackagePolicy.controls.cancellationPolicy.setValue("");
          }
        } else {
          this.spaPackagePolicy.controls.cancellationPolicy.setValue(this.DefaultCancelationPolicy);
        }
        break;
      case 'service':
        this.serviceOnly = !this.serviceOnly;
        var value = this.AllServices.filter(res => { return (this.selectedCategories && this.selectedCategories.length == 0) || this.selectedCategories.map(a => (a.id ? a.id : a)).indexOf(res.serviceGroupId) > -1 });
        value = value ? value : [];
        this.setTableData(value);
        break;
      case 'component':
        if (this.data.popupConfig.operation === 'edit') {
          return;
        } else {
          this.IsComponentSelectable = $event[0];
          this.onDisableComponentField();
          if ($event[0]) {
            this.spaPackage.controls.packagePrice.markAsTouched();
            //this.spaPackage.controls.bookingComponentCount.markAsTouched();
          } 
          this.Component = !this.Component;
          if (this.IsComponentSelectable) {
            //this.spaPackage.controls.packagePrice.setValue('');
            this.autoPickArr[0] = this.captions.never;
          }
          if (!this.IsComponentSelectable && this.data.popupConfig.operation !== 'edit') {
            //this.spaPackage.controls.packagePrice.setValue('');
            //this.spaPackage.controls.bookingComponentCount.setValue('');
          }
        }
        break;
    }
  }

  peopleCount($event, count) {
    const value: number = $event.target.value == "" ? 0 : parseInt($event.target.value);
    if (count === 'pplCnt') {
      this.PplArr = [];
      this.PplArr.push(value.toString());
    }
    if (count === 'maxDays') {
      this.DayArr = [];
      if (this.checkMaxDaysWithSelectedDate(value)) {
        this.DayArr.push(value.toString());
      }
    }
  }

  ValidateIsEmpty(type) {
    if (type == "numberOfPeople") {
      if (this.spaPackage.controls.numberOfPeople.value == "" || this.spaPackage.controls.numberOfPeople.value == null) {

        this.spaPackage.controls.numberOfPeople.setValue(0);
      }
    }
    else {
      if (this.spaPackage.controls.maximumDays.value == "" || this.spaPackage.controls.maximumDays.value == null) {

        this.spaPackage.controls.maximumDays.setValue(0);
      }

    }
  }

  serviceSelected(event,SelectedRow){
      this.CurrentRowSelected({ "event": event, "SelectedRow": SelectedRow, "From": "Single" })
  }

  CurrentRowSelected(e) {
    e.SelectedRow.checked = e.event.checked
    if (!e.SelectedRow.quantity) {
      e.SelectedRow.quantity=1;
     }
    this.spaPackage.markAsDirty();
    if (e.event.checked === true) {
      if (e.From === 'All') {
        this.checkedItems = [];
        e.SelectedRow.forEach(element => {
          element.checked = e.event.checked
          element.quantity=1;
          this.addToCheckedItems(element);
        });
      } else {

        this.addToCheckedItems(e.SelectedRow);
      }
    } else {
      if (e.From === 'All') {
        this.checkedItems = [];
        e.SelectedRow.forEach(element => {
          element.checked = e.event.checked
        });
      } else {
      if(e.SelectedRow.serviceId==0)
      {
          this.checkedItems = this.checkedItems.filter(res => (res.retailItemId?res.retailItemId:res.id) !== e.SelectedRow.id);
      }
      else{
        this.checkedItems = this.checkedItems.filter(res => res.serviceId!== e.SelectedRow.id);
      }
      }
    }
    //this.UpdateComponentPrices();
    this.createSpaService();
    this.createYeilding();
    this.calculateTotalPrice();
    //this.addValue(this.checkedItems);
  }

  checkQuantity(){
    for (let i = 0; i < this.spaServices.length; i++) {
      if(this.localization.currencyToSQLFormat(this.spaServices[i].controls.amount.value) == 0){
        this.spaServices[i].controls.allowPriceChange.setValue(false);
        this.spaServices[i].controls.allowPriceChange.disable();
     }
     else{
      this.spaServices[i].controls.allowPriceChange.enable();
     }
    }
    for (let i = 0; i < this.spaRetailItems.length; i++) {
      if(this.localization.currencyToSQLFormat(this.spaRetailItems[i].controls.amount.value) == 0){
        this.spaRetailItems[i].controls.allowPriceChange.setValue(false);
        this.spaRetailItems[i].controls.allowPriceChange.disable();
     }
     else{
      this.spaRetailItems[i].controls.allowPriceChange.enable();
     }
    }
  }

  createYeilding(){
    this.servicePkgData = [];
    const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    if(this.IsComponentSelectable)
      {
        this.createYieldingComponentArray();
        this.noPriceChange = this.checkedComponentItems.some(x=> x.allowPriceChange == true);
      }
      else
      {
        this.noPriceChange = this.checkedItems.some(x=> x.allowPriceChange == true);
      }
    for(let array of arr.controls){
      while (array['controls']['yeildServicesArray'].controls.length !== 0) {
        array['controls']['yeildServicesArray'].removeAt(0)
      }
      if(this.IsComponentSelectable)
      {
        this.checkedComponentItems.forEach((service,index) => {
          array['controls']['yeildServicesArray'].push(this.fb.group({
                  serviceId: service.serviceId ? service.serviceId : 0,
                  retailItemId: service.retailItemId,
                  componentIndex:service?.componentIndex?service.componentIndex:0,
                  isServiceComponent:service?.isServiceComponent?service.isServiceComponent:false,
                  serviceName: service.description,
                  quantity: service.quantity,
                  amount: service.amount,
                  allowPriceChange : service.allowPriceChange,
                  mon: ["",new UntypedFormControl],
                  tue: ["",new UntypedFormControl],
                  wed: ["",new UntypedFormControl],
                  thu: ["",new UntypedFormControl],
                  fri: ["",new UntypedFormControl],
                  sat: ["",new UntypedFormControl],
                  sun: ["",new UntypedFormControl],
          }
          ))
        });
      }
      else{
      this.checkedItems.forEach((service,index) => {
        array['controls']['yeildServicesArray'].push(this.fb.group({
                serviceId: service.serviceId ? service.serviceId : 0,
                retailItemId: service.retailItemId,
                componentIndex:service?.componentIndex?service.componentIndex:0,
                isServiceComponent:service?.isServiceComponent?service.isServiceComponent:false,
                serviceName: service.description,
                quantity: service.quantity,
                amount: service.amount,
                allowPriceChange : service.allowPriceChange,
                mon: ["",new UntypedFormControl],
                tue: ["",new UntypedFormControl],
                wed: ["",new UntypedFormControl],
                thu: ["",new UntypedFormControl],
                fri: ["",new UntypedFormControl],
                sat: ["",new UntypedFormControl],
                sun: ["",new UntypedFormControl],
        }
        ))
      });
    }
     }
    
    console.log(this.spaPackageYield);
  }
  createSpaService() {
    this.createServiceFormArray();
  }

  createServiceFormArray() {

    this.selectedSerivce = cloneDeep(this.checkedItems);
    this.checkedItems = [];
    this.spaRetailItems = [];
    this.spaServices = [];
    // if(spaServices.controls.length == 0 ) {
    for (let item of this.selectedSerivce) {
      if (item.serviceId == 0) {
        if (this.data.popupConfig.operation == 'edit')
        {
          this.spaRetailItems.push(this.fb.group({
            code:item.code,
            id: item.retailItemId,
            description: item.description,
            itemType:this.ItemType,
            retailPrice: item.price,
            quantity: item.quantity?item.quantity:1,
            serviceId:0,
            retailQuantity : 1,
            incrementDecrement: item.incrementDecrement!=undefined?item.incrementDecrement:IncDec.increment,
            fixedVariable: item.fixedVariable ? item.fixedVariable : false,
            percent:item.percent,
            amount:item.amount,
            allowPriceChange: item.allowPriceChange!=undefined?item.allowPriceChange:false
          }));
        }
        else{
        this.spaRetailItems.push(this.fb.group({
          code:item.code,
          id: item.retailItemId,
          description: item.description,
          retailPrice: item.price,
          itemType:this.ItemType,
          quantity: item.quantity?item.quantity:1,
          serviceId:0,
          retailQuantity : 1,
          incrementDecrement: item.incrementDecrement!=undefined?item.incrementDecrement:IncDec.increment,
          fixedVariable: item.fixedVariable ? item.fixedVariable : false,
          percent:item.percent,
          amount: item.amount,
          allowPriceChange: item.allowPriceChange!=undefined?item.allowPriceChange:false
        }));
      }
        this.spaPackageService = this.fb.group({
          priceType: this.spaPackageService.controls.priceType.value,
          isComponentSelected: this.spaPackageService.controls.isComponentSelected.value,
          serviceVariable: this.spaPackageService.controls.serviceVariable.value,
          retailVariable: this.spaPackageService.controls.retailVariable.value,
          topBottom: this.spaPackageService.controls.topBottom.value,
          serviceTableView: this.spaPackageService.controls.serviceTableView.value,
          selectedRetailServices: this.fb.array(this.spaRetailItems),
          selectedServices: this.fb.array(this.spaServices)
        });
      }
      else {
        if (this.data.popupConfig.operation == 'edit')
        {
          this.spaServices.push(this.fb.group({
            id: item.id,
            description: item.description,
            servicePrice:item.price,
            quantity: item.quantity?item.quantity:1,
            serviceId:item.serviceId,
            itemType:item.itemType,
            serviceQuantity : 1,
            incrementDecrement: item.incrementDecrement!=undefined?item.incrementDecrement:IncDec.increment,
            fixedVariable: item.fixedVariable ? item.fixedVariable : false,
            percent:item.percent,
            amount:item.amount,
            allowPriceChange: item.allowPriceChange!=undefined?item.allowPriceChange:false
          }));
        }
        else{
        this.spaServices.push(this.fb.group({
          id: item.id,
          description: item.description,
          servicePrice: item.price,
          quantity: item.quantity?item.quantity:1,
          serviceId:item.serviceId,
          itemType:item.itemType,
          serviceQuantity : 1,
          incrementDecrement: item.incrementDecrement!=undefined?item.incrementDecrement:IncDec.increment,
          fixedVariable: item.fixedVariable ? item.fixedVariable : false,
          percent:item.percent,
          amount:item.amount,
          allowPriceChange: item.allowPriceChange!=undefined?item.allowPriceChange:false
        }));
      }
        this.spaPackageService = this.fb.group({
          priceType: this.spaPackageService.controls.priceType.value,
          isComponentSelected: this.spaPackageService.controls.isComponentSelected.value,
          serviceVariable: this.spaPackageService.controls.serviceVariable.value,
          retailVariable: this.spaPackageService.controls.retailVariable.value,
          topBottom: this.spaPackageService.controls.topBottom.value,
          serviceTableView: this.spaPackageService.controls.serviceTableView.value,
          selectedRetailServices: this.fb.array(this.spaRetailItems),
          selectedServices: this.fb.array(this.spaServices)
        });
      }  
    }
    this.checkedItems = this.selectedSerivce;
    
    this.checkQuantity();
  }

createYieldingComponentArray()
{
  let serviceArray=this.calculateServiceComponentPrice(this.spaServices).map(x=>x.value);
  let retailArray=this.calculateRetailComponentPrice(this.spaRetailItems).map(x=>x.value);
  this.checkedComponentItems=[];
  this.checkedComponentItems=[...serviceArray.map(x => ({ ...x })), ...retailArray.map(x => ({ ...x }))];
  let serviceIndex=0;
  let retailIndex=0;
  this.minimumYeildingAmount=0;
  for(let item of this.checkedComponentItems)
  {
    item.retailItemId=item.serviceId==0?item.id:this.selectedRetailItemId;
    if(!item.allowPriceChange)
      {
        this.minimumYeildingAmount=this.minimumYeildingAmount+this.localization.currencyToSQLFormat(item.amount);
      }
    if(!item.fixedVariable)
    {
      if(item.serviceId==0)
      {
        item.isServiceComponent=false;
        item.componentIndex=retailIndex;
        retailIndex++;
        item.description= this.captions.lbl_retailVariable+retailIndex
        item.retailItemId=0;
      }
      else{
        item.isServiceComponent=true;
        item.componentIndex=serviceIndex;
        serviceIndex++;
        item.description=this.captions.lbl_serviceVariable+serviceIndex
        item.serviceId=0;
        item.retailItemId=0;
      }
    }
  }
  
}

  // UpdateComponentPrices() {
  //  this.totalServicePrice=0;
  //  this.totalRetailPrice=0;
  //     this.spaPackage.controls.packagePrice.setValue(this.localization.localizeCurrency(this.totalPrice.toString(), false));
  //     this.divideServicePrice=this.localization.currencyToSQLFormat(this.spaPackage.controls.packagePrice.value);
  //     this.totalRetailPrice= this.addPriceDetails(this.checkedItems.filter(a=>a.serviceId==0).map(a=>a.price));
 
  //   if (this.Component === true) {
  //     this.divideServicePrice=this.divideServicePrice-this.totalRetailPrice;
  //     const dividedPrice = Number((this.divideServicePrice) / this.spaPackage.controls.bookingComponentCount.value);
  //     this.checkedItems.filter(s=>s.serviceId!=0).forEach(element =>{
  //       element.price = dividedPrice.toString();
  //     });      
  //   }
  // }

  addPriceDetails(prices:any):number{
    let total:number=0;
    prices.forEach(p=>{total+=(this.localization.currencyToSQLFormat(p))});
    return total;
  }

  addToCheckedItems(e: any) {
    this.selectedPackageComponent.push(e);
    const selectedItem = cloneDeep(e);
    //selectedItem.price.customToFixed();
    selectedItem.price=this.localization.localizeCurrency(selectedItem.price,false);
    selectedItem.amount=selectedItem.price;
    selectedItem.percent=this.localization.localizeCurrency('0',false);
    selectedItem.incrementDecrement=IncDec.increment;
    selectedItem.retailItemId=selectedItem.itemType==2?selectedItem.id:this.selectedRetailItemId;
    selectedItem.fixedVariable = false;
    selectedItem.allowPriceChange = false;
    selectedItem.quantity=1;
    this.checkedItems.push(selectedItem);
  }

  // addValue(e) {
  //   if (this.Component === true) {
  //     this.totalRetailPrice=0;
  //     this.totalServicePrice=0;
  //     this.totalPrice = 0;
  //     for (let eItem of e) {
  //       if (eItem != null) {
  //     if( eItem.serviceId==0)
  //     {
  //       this.totalRetailPrice=this.totalRetailPrice + this.localization.currencyToSQLFormat(eItem.price);
  //     }
  //     else
  //     {
  //     this.totalPrice = this.localization.currencyToSQLFormat(this.spaPackage.controls.packagePrice.value);
  //   }
  //  }  
  // }  
  // this.totalPrice = this.localization.currencyToSQLFormat(this.spaPackage.controls.packagePrice.value);
  // this.totalServicePrice=this.divideServicePrice;
  // //this.totalServicePrice=this.totalServicePrice - this.totalRetailPrice;
  //  } else {
  //     this.totalRetailPrice=0;
  //     this.totalServicePrice=0;
  //     this.totalPrice = 0;
  //     for (let eItem of e) {
  //       if (eItem != null) {
  //         if (eItem.serviceId == 0) {
  //           this.totalRetailPrice = this.totalRetailPrice + this.localization.currencyToSQLFormat(eItem.price);
  //         }
  //         else {
  //           this.totalServicePrice = this.totalServicePrice + this.localization.currencyToSQLFormat(eItem.price);
  //         }
  //         this.totalPrice = this.totalPrice + this.localization.currencyToSQLFormat(eItem.price);
  //       }
  //     }
  //     this.totalPrice = Number(this.totalPrice.customToFixed());
  //   }
  // }

  changeMaxPeople(items: any) {
    let maximumPeopleOfselectedService: number;
    const lstOfMaxPeople = items.map(x => x.maxPeople ? x.maxPeople : 0);
    maximumPeopleOfselectedService = Math.max(...lstOfMaxPeople);
    this.spaPackage.controls.numberOfPeople.setValue(maximumPeopleOfselectedService);
    this.PplArr = [];
    this.PplArr.push(maximumPeopleOfselectedService ? maximumPeopleOfselectedService.toString() : maximumPeopleOfselectedService);
  }

  changeServiceValue(item, $event) {
    for (let checkedItemsItem of this.spaServices) {
      if (item.value.id === checkedItemsItem.value.id) {
        const value = $event.target.value ? $event.target.value : '0';
        checkedItemsItem.servicePrice = this.localization.currencyToSQLFormat(value);
      }
    }
    for (let checkedItemsItem of this.checkedItems) {
      if (item.value.id === checkedItemsItem.id) {
        const value = $event.target.value ? $event.target.value : '0';
        checkedItemsItem.price = this.localization.currencyToSQLFormat(value);
      }
    }
    //this.UpdateComponentPrices();
    this.createSpaService();
    //this.addValue(this.checkedItems);
  }

  changeRetailValue(item, $event) {
    for (let checkedItemsItem of this.spaRetailItems) {
      if (item.value.id === checkedItemsItem.value.id) {
        const value = $event.target.value ? $event.target.value : '0';
        checkedItemsItem.retailPrice = this.localization.currencyToSQLFormat(value);
      }
    }

    for (let checkedItemsItem of this.checkedItems) {
      const checkedRetailItemId=checkedItemsItem.retailItemId?checkedItemsItem.retailItemId:checkedItemsItem.id;
      if (item.value.id === checkedRetailItemId) {
        const value = $event.target.value ? $event.target.value : '0';
        checkedItemsItem.price = this.localization.currencyToSQLFormat(value);
      }
    }
    //this.UpdateComponentPrices();
    this.createSpaService();
    //this.addValue(this.checkedItems);
  }

  makeDirty() {
    this.spaPackageForm.markAsDirty();
    this.spaPackageService.markAsDirty();
  }

  Done(selectedRetailItemId) {
    if (selectedRetailItemId > 0) {
      this.selectedRetailItemId = selectedRetailItemId;
      this.retailItemMiss = false;
      this.retailItemDirty = true;
      for(let item of this.checkedItems)
        {
          if(item.serviceId!=0)
            {
              item.retailItemId=selectedRetailItemId;
            }
        }
        this.createSpaService();
      this.GetRetailItemByItemId(this.selectedRetailItemId);

    } else {
      this.retailItemMiss = true;
      this.retailItemDirty = false;
    }
  }

  // copy from package
  packageCodeSelection(item) {
    let id: number;
    for (let packageYieldJsonItem of this.packageYieldJson) {
      if (packageYieldJsonItem.code === item) {
        id = packageYieldJsonItem.id;
        break;
      }
    }
    this.CopyPackage(id);
  }

  BindCopyInfo() {
    this.IsComponentSelectable = this.copyPackageInformation.packageDetail.isSelectCompAtBooking;
    this.onDisableComponentField();
    this.SetPackageDetailControlsValue(this.copyPackageInformation.packageDetail);
    this.setPackageAVailableDaysControl(this.copyPackageInformation.availableDays);
    this.SetServiceComponents(this.copyPackageInformation.packageComponents);
    this.SetPackageYieldComponent(this.copyPackageInformation.packageYields);
    this.setYieldingAvailableDays();
    this.setPackageYieldGroupDetails(this.copyPackageInformation.packageYields,this.copyPackageInformation.packageYieldGroupDetails);
    this.pakageStartDate = this.spaPackage.controls.startDate.value;
    this.packageEndDate =  this.spaPackage.controls.endDate.value;
  }

  SetPackageDetailControlsValue(pacakgeDtl: PackageDetail) {
    this.spaPackage.controls.packageCode.setValue('');
    this.spaPackage.controls.packageDescription.setValue(pacakgeDtl.description);
    this.spaPackage.controls.packagePrice.setValue(this.localization.localizeCurrency(pacakgeDtl.packagePrice.toString(), false));
    this.spaPackage.controls.startDate.setValue(this.utils.getDate(pacakgeDtl.startDate));
    this.spaPackage.controls.startDate.markAsDirty();
    this.spaPackage.controls.startDate.markAsTouched();
    this.spaPackage.controls.endDate.setValue(this.utils.getDate(pacakgeDtl.endDate));
    this.spaPackage.controls.endDate.markAsDirty();
    this.spaPackage.controls.endDate.markAsTouched();
    this.spaPackage.controls.activeToggle.setValue(pacakgeDtl.isActive);
    this.spaPackageService.controls.isComponentSelected.setValue(pacakgeDtl.isSelectCompAtBooking);
    this.spaPackage.controls.autoRecap.setValue(pacakgeDtl.isAutomaticRecap);
    this.spaPackage.controls.bookLunchToggle.setValue(pacakgeDtl.isBookLunch);
    this.spaPackage.controls.isAvailableOnWeb.setValue(pacakgeDtl.isAvailableOnWeb)

    this.DayArr[0] = pacakgeDtl.maxDays.toString();
    this.PplArr[0] = pacakgeDtl.maxPeople.toString();
    this.commnArr[0] = pacakgeDtl.emailCommunicationType;
    this.spaPackage.controls.maximumDays.setValue(Number(pacakgeDtl.maxDays));
    this.spaPackage.controls.numberOfPeople.setValue(Number(pacakgeDtl.maxPeople));
    if (pacakgeDtl.autopick === 1) {
      this.autoPickArr[0] = this.captions.never;
    } else if (pacakgeDtl.autopick === 2) {
      this.autoPickArr[0] = this.captions.always;
    } else if (pacakgeDtl.autopick === 3) {
      this.autoPickArr[0] = this.captions.ask;
    }

    this.Active = pacakgeDtl.isActive;
    this.Component = pacakgeDtl.isSelectCompAtBooking;
    this.spaPackageService.controls.serviceVariable.setValue(pacakgeDtl.numberOfItems);
    this.spaPackageService.controls.retailVariable.setValue(pacakgeDtl.numberOfRetailItems);
    this.spaPackageService.controls.topBottom.setValue(pacakgeDtl.isTopCompAtBooking);
    this.Lunch = pacakgeDtl.isBookLunch;
    this.Recap = pacakgeDtl.isAutomaticRecap;
    this.Web = pacakgeDtl.isAvailableOnWeb;
    this.spaPackagePolicy.controls.packagePolicy.setValue(pacakgeDtl.packagePolicy);
    this.defaultPolicy = pacakgeDtl.isDefaultCancelPolicy;
    this._su.type1Array = [];
    if (!this.defaultPolicy) {
      this.spaPackagePolicy.controls.cancellationPolicy.setValue(pacakgeDtl.cancellationPolicy);
    } else {
      this.spaPackagePolicy.controls.cancellationPolicy.setValue(pacakgeDtl.cancellationPolicy);
    }
  }

  // setting up the controls with the values
  setPackageAVailableDaysControl(availableDays: PackageAvailableDays) {
    this._su.type1Array = [];
    if (availableDays.isAvailableOnMonday) {
      this._su.type1Array.push(this.localization.captions.calendar.Mon);
    }
    if (availableDays.isAvailableOnTuesday) {
      this._su.type1Array.push(this.localization.captions.calendar.Tue);
    }
    if (availableDays.isAvailableOnWednesday) {
      this._su.type1Array.push(this.localization.captions.calendar.Wed);
    }
    if (availableDays.isAvailableOnThursday) {
      this._su.type1Array.push(this.localization.captions.calendar.Thu);
    }
    if (availableDays.isAvailableOnFriday) {
      this._su.type1Array.push(this.localization.captions.calendar.Fri);
    }
    if (availableDays.isAvailableOnSaturday) {
      this._su.type1Array.push(this.localization.captions.calendar.Sat);
    }
    if (availableDays.isAvailableOnSunday) {
      this._su.type1Array.push(this.localization.captions.calendar.Sun);
    }

    /* Set Saved available days */
    this.arrayValues.forEach(element => {
      element.setSelected = this._su.type1Array.includes(element.name);
    });

    if (this._su.type1Array.length == 7) {
      this._su.type1Array.push(this.localization.captions.common.all);
      /* Select All if all days are selected */
      let allButton: DaysLocaleSorted_app_multiple = this.arrayValues.find(x => x.name == this.localization.captions.common.all);
      if (allButton) {
        allButton.setSelected = true;
      }
    }

    if (this.availableDays) {
      this.availableDays.initValues();
    }

  }

  SetServiceComponents(packageComponents: any) {
    this.checkedItems = [];
    const componentLength = packageComponents.length;
    for (let i = 0; i < componentLength; i++) {
      packageComponents[i].amount=this.localization.localizeCurrency(packageComponents[i].price,false);
      packageComponents[i].price=this.localization.localizeCurrency(packageComponents[i].basePrice,false);
      packageComponents[i].percent=this.localization.localizeCurrency(packageComponents[i].percentage,false);
      packageComponents[i].fixedVariable=packageComponents[i].isFixedComponent;
      this.checkedItems.push(packageComponents[i]);
      if (packageComponents[i].serviceId == 0) {
        this.spaRetailItems.push(packageComponents[i]);
      }
      else {
        this.spaServices.push(packageComponents[i]);
      }
    }
    for (let i = 0; i < componentLength; i++) {
      if (packageComponents[i].serviceId == 0) {
        this.totalRetailPrice=this.totalRetailPrice+Number((this.localization.currencyToSQLFormat(this.checkedItems[i].price)).customToFixed());
      }
      else
      {
        this.totalServicePrice=this.totalServicePrice+Number((this.localization.currencyToSQLFormat(this.checkedItems[i].price)).customToFixed());
      }
      this.totalPrice = this.totalPrice + Number((this.localization.currencyToSQLFormat(this.checkedItems[i].price)).customToFixed());
    }
    this.createSpaService()
  }

  SetPackageYieldComponent(packageYields: any) {
    if (packageYields == null) {
      this.spaPackageYield.controls.packageClassData.setValue('');
      this.spaPackageYield.controls.startDate.setValue('');
      this.spaPackageYield.controls.availableValue.setValue('');
      this._su.type2Array = [''];
      this.spaPackageYield.controls.endDate.setValue('');
      this.spaPackageYield.controls.packageClassData.setValue('');
    } else {
      const yieldDaysCount = packageYields.length;
      if (yieldDaysCount === 0) {
        this.spaPackageYield.controls.packageClassData.setValue('');
        this.spaPackageYield.controls.startDate.setValue('');
        this.spaPackageYield.controls.availableValue.setValue('');
        this._su.type2Array = [''];
        this.spaPackageYield.controls.endDate.setValue('');
        this.spaPackageYield.controls.packageClassData.setValue('');
      } else {
        this.oldPackageYield = packageYields;
        const yieldCount = packageYields.length;
        if (yieldCount === 1) {
          this.spaPackageYield.controls.endDate.setValue(this.utils.getDate(packageYields[0].yieldDate));
        } else {
          this.spaPackageYield.controls.endDate.setValue(this.utils.getDate(packageYields[yieldCount - 1].yieldDate));
        }
        this.spaPackageYield.controls.startDate.setValue(this.utils.getDate(packageYields[0].yieldDate));
        this.soldValue = Number(packageYields[0].sold);
        this.spaPackageYield.controls.availableValue.setValue(packageYields[0].available);
        this._su.type2Array = this._su.type1Array;
        this.spaPackageYield.controls.packageClassData.setValue(packageYields[0].packageClass);
      }
    }
  }

  saveInfo() {
    if (this.checkServicesSelected() !== true) {
      return;
    }
    if ((this.data.popupConfig.operation !== 'edit')) {
      // setting up the pacakage detail
      const pkCode = this.spaPackage.controls.packageCode.value;
      const pkDescription = this.spaPackage.controls.packageDescription.value;
      const startDate = this.spaPackage.controls.startDate.value;
      const endDate = this.spaPackage.controls.endDate.value;
      let price = this.spaPackage.controls.packagePrice.value;
      const daysCount: number = (this.DayArr[0]);
      const people: number = (this.PplArr[0]);
      let autopickenum: number;
      if (this.autoPickArr[0] === this.captions.never) {
        autopickenum = 1;
      } else if (this.autoPickArr[0] === this.captions.always) {
        autopickenum = 2;
      } else if (this.autoPickArr[0] === this.captions.ask) {
        autopickenum = 3;
      } else {
        autopickenum = 4;
      }
      if (price === '') {
        price = this.totalPrice.customToFixed();
      } else {
        price = this.localization.currencyToSQLFormat(price);
      }


      const pacakgeDetails: PackageDetail = {
        id: 0,
        code: pkCode,
        description: pkDescription,
        startDate: this.localization.ConvertDateToISODate(startDate),
        endDate: this.localization.ConvertDateToISODate(endDate),

        maxPeople: people,
        packagePrice: price,
        autopick: autopickenum,

        isActive: this.spaPackage.controls.activeToggle.value,
        isAutomaticRecap: this.spaPackage.controls.autoRecap.value,
        isBookLunch: this.spaPackage.controls.bookLunchToggle.value,

        packagePolicy: this.spaPackagePolicy.controls.packagePolicy.value,
        cancellationPolicy: this.spaPackagePolicy.controls.cancellationPolicy.value,
        isDefaultCancelPolicy: this.defaultPolicy,
        isSelectCompAtBooking: this.Component,
        numberOfItems: Number(this.spaPackageService.controls.serviceVariable.value),
        numberOfRetailItems:Number(this.spaPackageService.controls.retailVariable.value),
        isTopCompAtBooking:this.spaPackageService.controls.topBottom.value,
        maxDays: daysCount,
        emailCommunicationType: this.commnArr[0],
        retailItemId: this.selectedRetailItemId,
        isAvailableOnWeb: this.spaPackage.controls.isAvailableOnWeb.value
      };
      // setting up the package component
      const packageComponents = [];
      for (let checkedItemsItem of this.checkedItems) {
        if (this.data.popupConfig.operation === 'edit'){
            let component: PackageComponent = {
              description: checkedItemsItem.description,
              price: this.localization.currencyToSQLFormat(checkedItemsItem.amount),
              quantity: parseInt(checkedItemsItem.quantity),
              // Temporary fix for service Id issue
              serviceId: checkedItemsItem.serviceId ? checkedItemsItem.serviceId : 0,
              retailItemId: checkedItemsItem.retailItemId ,
              packageId: 0,
              id: 0,
              percentage:this.localization.currencyToSQLFormat(checkedItemsItem.percent),
              incrementDecrement:checkedItemsItem.incrementDecrement,
              allowPriceChange:checkedItemsItem.allowPriceChange,
              basePrice:this.localization.currencyToSQLFormat(checkedItemsItem.price),
              isFixedComponent:checkedItemsItem.fixedVariable
            };
            packageComponents.push(component);
          }else{
          let component: PackageComponent = {
          description: checkedItemsItem.description,
          price: this.localization.currencyToSQLFormat(checkedItemsItem.amount),
          quantity: parseInt(checkedItemsItem.quantity),
          // Temporary fix for service Id issue
          serviceId: checkedItemsItem.serviceId ? checkedItemsItem.serviceId : 0,
          retailItemId:  checkedItemsItem.retailItemId ,
          packageId: 0,
          id: 0,
          percentage:this.localization.currencyToSQLFormat(checkedItemsItem.percent),
          incrementDecrement:checkedItemsItem.incrementDecrement,
          allowPriceChange:checkedItemsItem.allowPriceChange,
          basePrice:this.localization.currencyToSQLFormat(checkedItemsItem.price),
          isFixedComponent:checkedItemsItem.fixedVariable
        };
        packageComponents.push(component);
      }

      }
      // setting up  the available days
      let monday = false;
      let tuesday = false;
      let wednesday = false;
      let thursday = false;
      let friday = false;
      let saturday = false;
      let sunday = false;


      for (let type1ArrayItem of this._su.type1Array) {
        if (type1ArrayItem === this.localization.captions.common.all) {
          monday = true;
          tuesday = true;
          wednesday = true;
          thursday = true;
          friday = true;
          saturday = true;
          sunday = true;
        } else if (type1ArrayItem === this.localization.captions.calendar.Mon && this._su.type1Array.length !== 8) {
          monday = true;
        } else if (type1ArrayItem === this.localization.captions.calendar.Tue && this._su.type1Array.length !== 8) {
          tuesday = true;
        } else if (type1ArrayItem === this.localization.captions.calendar.Wed && this._su.type1Array.length !== 8) {
          wednesday = true;
        } else if (type1ArrayItem === this.localization.captions.calendar.Thu && this._su.type1Array.length !== 8) {
          thursday = true;
        } else if (type1ArrayItem === this.localization.captions.calendar.Fri && this._su.type1Array.length !== 8) {
          friday = true;
        } else if (type1ArrayItem === this.localization.captions.calendar.Sat && this._su.type1Array.length !== 8) {
          saturday = true;
        } else if (type1ArrayItem === this.localization.captions.calendar.Sun && this._su.type1Array.length !== 8) {
          sunday = true;
        }
      }
      const available: PackageAvailableDays = {
        isAvailableOnMonday: monday,
        isAvailableOnTuesday: tuesday,
        isAvailableOnWednesday: wednesday,
        isAvailableOnThursday: thursday,
        isAvailableOnFriday: friday,
        isAvailableOnSaturday: saturday,
        isAvailableOnSunday: sunday,
        packageId: 0,
        typeName: 'PACKAGE'
      };

      let header: any;
      let PackageYieldGroupDetails=[];
      PackageYieldGroupDetails =this.GetPackageYeildGroupDetails(PackageYieldGroupDetails);
      const bodyObj: PackageInfo = {
        id: 0,
        availableDays: available,
        packageComponents: packageComponents,
        packageDetail: pacakgeDetails,
        packageYields: this.generatePackageYield(),
        packageYieldGroupDetails:PackageYieldGroupDetails
      };

      this.addPackage(header, bodyObj);
    } else {

      // update package
      let packageDetails: PackageDetail;
      let packageComponents = [];
      let available: PackageAvailableDays;
      let PackageYieldGroupDetails=[];
      packageDetails = this.GetPackageDetails(packageDetails);
      available = this.GetVailableDays(available);
      packageComponents = this.GetPackageComponents(packageComponents);
      PackageYieldGroupDetails =this.GetPackageYeildGroupDetails(PackageYieldGroupDetails);
      let header: any;
      const bodyObj: PackageInfo = {
        id: this.id,
        availableDays: available,
        packageComponents: packageComponents,
        packageDetail: packageDetails,
        packageYields: this.generatePackageYield(),
        packageYieldGroupDetails:PackageYieldGroupDetails
      };

      this.updatePackage(header, bodyObj);
    }
  }

  SetYieldPageVale() {
    const date = this.spaPackageYield.controls.startDate.value;
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const defaultPackageYieldEndDate = new Date(year, month, day + 1);
    this.spaPackageYield = this.fb.group({
      endDate: defaultPackageYieldEndDate
    });
  }

  fillAllAvailableDays(e?: any) {
    this.daysArrForPackageYield.forEach(av => av.setSelected === true);
    let defaultArr = this.utils.getShortDaysOfWeekModel(true);
    this.daysArrForPackageYield = defaultArr;
  }

 

  validateYield(): boolean {
    return this.spaPackageYield.get('startDate').valid &&
      this.spaPackageYield.get('endDate').valid &&
      this.daysArrForPackageYield.some(x => x.setSelected) && this.spaPackageYield.controls.packageClassData.valid;
  }

  addPackage(header: any, bodyobj: PackageInfo) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'AddPackage',
      method: HttpMethod.Post,
      header: header,
      body: bodyobj,
      showError: true,
      extraParams: [bodyobj]
    });
  }

  updatePackage(header: any, bodyobj: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'UpdatePackage',
      method: HttpMethod.Put,
      uriParams: { id: this.id },
      header: header,
      body: bodyobj,
      showError: true,
      extraParams: []
    });
  }

  GetPackageDetails(packageDetails: PackageDetail) {

    const pkCode = this.spaPackage.controls.packageCode.value;
    const pkDescription = this.spaPackage.controls.packageDescription.value;
    const packageStartDate = this.spaPackage.controls.startDate.value;
    const pacakgeEndDate = this.spaPackage.controls.endDate.value;
    let price = this.localization.currencyToSQLFormat(this.spaPackage.controls.packagePrice.value);
    const people: number = (this.DayArr[0]);

    const startDate = this.utils.GetFormattedDate(packageStartDate);
    const endDate = this.utils.GetFormattedDate(pacakgeEndDate);

    // GetAutoPickValue
    let autopickenum: number;
    if (this.autoPickArr[0] === this.captions.never) {
      autopickenum = 1;
    } else if (this.autoPickArr[0] === this.captions.always) {
      autopickenum = 2;
    } else if (this.autoPickArr[0] === this.captions.ask) {
      autopickenum = 3;
    } else {
      autopickenum = 4;
    }

    // Get Price Value
    if (price.toString() === '') {
      price = this.totalPrice;
    }

    packageDetails = {
      id: this.id,
      code: pkCode,
      description: pkDescription,
      startDate: startDate,
      endDate: endDate,

      maxPeople: this.PplArr[0],
      packagePrice: price,
      autopick: autopickenum,

      isActive: this.spaPackage.controls.activeToggle.value,
      isAutomaticRecap: this.spaPackage.controls.autoRecap.value,
      isBookLunch: this.spaPackage.controls.bookLunchToggle.value,

      packagePolicy: this.spaPackagePolicy.controls.packagePolicy.value,
      cancellationPolicy: this.spaPackagePolicy.controls.cancellationPolicy.value,
      isDefaultCancelPolicy: this.defaultPolicy,
      isSelectCompAtBooking: this.Component,
      numberOfItems: Number(this.spaPackageService.controls.serviceVariable.value),
      numberOfRetailItems:Number(this.spaPackageService.controls.retailVariable.value),
      isTopCompAtBooking:this.spaPackageService.controls.topBottom.value,
      maxDays: people,
      emailCommunicationType: this.commnArr[0],
      retailItemId: this.selectedRetailItemId,
      isAvailableOnWeb: this.spaPackage.controls.isAvailableOnWeb.value
    };

    return packageDetails;
  }
  GetPackageYeildGroupDetails(PackageYieldGroupDetails:any)
  {
    const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    for(let YieldDataArray of arr.controls)
    {
      let packageWeeklyPrice=[];
      for(let YieldData of YieldDataArray['controls'].yeildServicesArray.controls)
      {
        const price={Monday:YieldData.controls.mon.value,Tuesday:YieldData.controls.tue.value,Wednesday:YieldData.controls.wed.value,Thursday:YieldData.controls.thu.value,
          Friday:YieldData.controls.fri.value,Saturday:YieldData.controls.sat.value,Sunday:YieldData.controls.sun.value};
        const yieldWeeklyPrice:PackageWeeklyPrices ={
          serviceId:YieldData.controls.serviceId.value,
          retailItemId:YieldData.controls.retailItemId.value?YieldData.controls.retailItemId.value:0,
          packageYieldGroupDetailsId:0,
          weeklyPrice:JSON.stringify(price),
          isServiceComponent:YieldData.controls.isServiceComponent.value,
          componentIndex:YieldData.controls.componentIndex.value
        };
        packageWeeklyPrice.push(yieldWeeklyPrice);
      }
      const startDate = this.utils.GetFormattedDate(YieldDataArray['controls'].startDate.value);
      const endDate = this.utils.GetFormattedDate(YieldDataArray['controls'].endDate.value);  
      const yieldGroupDetail:PackageYieldGroupDetails ={
        packageId:this.id,
        yieldStartDate:startDate,
        yieldEndDate:endDate,
        available:Number(YieldDataArray['controls'].availableValue.value),
        packageClass:Number(YieldDataArray['controls'].packageClass.value),
        packageWeeklyPrices:packageWeeklyPrice
      };
      PackageYieldGroupDetails.push(yieldGroupDetail);
    }
    return PackageYieldGroupDetails;
  }
  generatePackageYield(): PackageYield[] {
    const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    const packageYieldArr: PackageYield[] = [];
    
      for(let YieldDataArray of arr.controls)
      {
      const startDate: Date = YieldDataArray['controls'].startDate.value;
      const endDate: Date = YieldDataArray['controls'].endDate.value;
      const availablecount: number= Number(YieldDataArray['controls'].availableValue.value)
      // if (this.selectedYieldDaysArr.length === 0) {
        //   this.daysArrForPackageYield.forEach(days => {
          //     if (days.setSelected) {
            //       this.selectedYieldDaysArr.push(days.id);
          //     }
      //   });
      // }
      //const selectedDays: string[] = this.selectedYieldDaysArr.map((day: number) => this.daysArrForPackageYield.find(av => av.id === day).name);
      
      const daysDiff: number = this._su.DateDiff.inDays(startDate, endDate);
      for (let i = 0; i <= daysDiff; i++) {
        var isDayTicked=this.GetDayOfWeek(this.localization.AddDays(startDate, i));
        const _tempDay: Date = this.localization.AddDays(startDate, i);
        const _tempDayName: string = this.localization.getDayForDate(_tempDay);
       if (YieldDataArray['controls'][isDayTicked]?.value) {
          const yieldComponent: PackageYield = {
            id: 0,
            code: this.spaPackage.controls.packageCode.value,
            description: this.spaPackage.controls.packageDescription.value,
            available: isNaN(availablecount) ? 0 : availablecount,
            sold: 0,
            remaining: isNaN(availablecount) ? 0 : availablecount,
            packageClass: Number(this.spaPackageYield.controls.packageClassData.value),
            yieldDate: this.localization.ConvertDateToISODate(this.localization.AddDays(startDate, i))
          };
          packageYieldArr.push(yieldComponent);
        }
    }
    }
    
    return packageYieldArr;
  }

  GetDayOfWeek(date: Date): string {
    const daysOfWeek = ['isSunAvailable', 'isMonAvailable', 'isTueAvailable', 'isWedAvailable', 'isThuAvailable', 'isFriAvailable', 'isSatAvailable'];
    return daysOfWeek[date.getDay()];
  }
  GetPackageComponents(packageComponents: any) {
    for (let checkedItemsitemTemp of this.checkedItems) {

      const component: PackageComponent = {
        description: checkedItemsitemTemp.description,
        price: this.localization.currencyToSQLFormat(checkedItemsitemTemp.amount),
        quantity: parseInt(checkedItemsitemTemp.quantity),
        // Temporary fix for service Id issue
        serviceId: checkedItemsitemTemp.serviceId ? checkedItemsitemTemp.serviceId : 0,
        retailItemId: checkedItemsitemTemp.retailItemId,
        packageId: this.id,
        id: 0,
        percentage:this.localization.currencyToSQLFormat(checkedItemsitemTemp.percent),
        incrementDecrement:checkedItemsitemTemp.incrementDecrement,
        allowPriceChange:checkedItemsitemTemp.allowPriceChange,
        basePrice:this.localization.currencyToSQLFormat(checkedItemsitemTemp.price),
        isFixedComponent:checkedItemsitemTemp.fixedVariable
      };

      packageComponents.push(component);
    }

    return packageComponents;
  }

  GetPackageYield(packageYieldComponents: any) {

    if (this.spaPackageYield.controls.startDate.value === '' || this.spaPackageYield.controls.startDate.value == null) {
      return null;
    }
    const pkCode = this.spaPackage.controls.packageCode.value;
    const pkDescription = this.spaPackage.controls.packageDescription.value;
    let availablecount: number;
    let yieldStartdate = this.spaPackageYield.controls.startDate.value;
    const YieldendDate = this.spaPackageYield.controls.endDate.value;
    availablecount = this.spaPackageYield.controls.availableValue.value;


    const day1 = yieldStartdate.getDate();
    const endDateYield = YieldendDate.getDate();


    let index = 0;
    let incrementDate;
    let i = 0;

    for (let day = day1; day <= endDateYield; day++) {
      incrementDate = this.utils.getDate(yieldStartdate);
      incrementDate.setDate(incrementDate.getDate() + index);
      const yieldComponent: PackageYield = {
        id: 0,
        code: pkCode,
        description: pkDescription,
        available: availablecount,
        sold: 0,
        remaining: availablecount,
        yieldDate: this.utils.GetFormattedDate(incrementDate),

        packageClass: this.spaPackageYield.controls.packageClassData.value
      };
      packageYieldComponents.push(yieldComponent);
      index = 1;
      yieldStartdate = incrementDate;
      i = i + 1;
    }

    return packageYieldComponents;
  }

  GetVailableDays(available: PackageAvailableDays) {
    let monday = false;
    let tuesday = false;
    let wednesday = false;
    let thursday = false;
    let friday = false;
    let saturday = false;
    let sunday = false;

    for (let suItem of this._su.type1Array) {
      if (suItem === this.localization.captions.common.all) {
        monday = true;
        tuesday = true;
        wednesday = true;
        thursday = true;
        friday = true;
        saturday = true;
        sunday = true;
      } else if (suItem === this.localization.captions.calendar.Mon && this._su.type1Array.length !== 8) {
        monday = true;
      } else if (suItem === this.localization.captions.calendar.Tue && this._su.type1Array.length !== 8) {
        tuesday = true;
      } else if (suItem === this.localization.captions.calendar.Wed && this._su.type1Array.length !== 8) {
        wednesday = true;
      } else if (suItem === this.localization.captions.calendar.Thu && this._su.type1Array.length !== 8) {
        thursday = true;
      } else if (suItem === this.localization.captions.calendar.Fri && this._su.type1Array.length !== 8) {
        friday = true;
      } else if (suItem === this.localization.captions.calendar.Sat && this._su.type1Array.length !== 8) {
        saturday = true;
      } else if (suItem === this.localization.captions.calendar.Sun && this._su.type1Array.length !== 8) {
        sunday = true;
      }
    }
    available = {
      isAvailableOnMonday: monday,
      isAvailableOnTuesday: tuesday,
      isAvailableOnWednesday: wednesday,
      isAvailableOnThursday: thursday,
      isAvailableOnFriday: friday,
      isAvailableOnSaturday: saturday,
      isAvailableOnSunday: sunday,
      packageId: this.id,
      typeName: 'PACAKAGE'
    };

    return available;
  }


  expand(name) {
    const d = document.getElementById(name);
    const topPos = d.offsetTop - 180; //subtracted the value for the top calculation
    const leftPos = d.offsetLeft;
    this.utils.setFilterPopUp(true, this.categoriesTemp, cloneDeep(this.selectedCategories), topPos, leftPos, name, 'SP');
  }

  filteredDataResponse(e) {
    let result = e[0];
    this.selectedCategories = e[0];
    const value = this.AllServices.filter(res => (result && result.length === 0) || result.indexOf(res.serviceGroupId) > -1);
    this.setTableData(value);
  }

  ngAfterViewChecked() {

    if (!this.viewChecked) {
      this.viewChecked = true;
      this.countCalc();

    }
  }

  @HostListener('document:keydown.control.z', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }


  ngOnInit() {
    this.ValidateBreakPoint();
    this.GetAllSpaPackageClass();
    this.populateData(this.tableoptions);
    for (let j = 0; j < 4; j++) {
      this.categories.push(this.categoriesTemp[j]);
    }
    if (this.data.popupConfig.operation === 'edit') {

      const obj = this.data.datarecord as PackageInfo;
      this.GetPackageAppointmentDate(obj.packageDetail.id);
      this.getLinkedItemDesc(obj.packageDetail.retailItemId);
      this.GetRetailItemByType(this.packageRetailItemType);
      this.GetRetailItemByItemId(obj.packageDetail.retailItemId);

    }
    this.GetServiceGroup();
    this.GetServices();   
    if (this.packageRetailItemType) {
      this.GetRetailItemByType(this.packageRetailItemType);
    }
    this.GetAllSpaPackagesActiveAndInactive();
    this.onChanges();
    if (this.utils.isIpad()) {
      this.floatLabel = "always";
      console.log(this.deviceName);
    }
    this.utils.getConfiguration(this.successCallback.bind(this), this.utils.errorCallback.bind(this), "Appointment", "APPOINMENT_SYSTEMDEFAULTCANCELLATIONPOLICY");
  }
  ngOnDestroy() {
    this.startDateSubscription.unsubscribe();
    this.endDateSubscription.unsubscribe();
    this.packageyieldStartDateSubscription.unsubscribe();
    this.packageyieldEndDateSubscription.unsubscribe();
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  close() {
    this.business.promptUserDialog(this.dialogRef);
  }

  GetServiceGroup() {

    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetAllServiceGrp',
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetServices() {

    const sDate = this.utils.formatDate(this.spaPackage.controls.startDate.value);
    const eDate = this.utils.formatDate(this.spaPackage.controls.endDate.value);
    this.dayValue = '';
    const dayArr = [];
    for (let ItemTemp of this._su.type1Array) {
      if (ItemTemp === this.localization.captions.calendar.Mon) {
        dayArr.push(1);
      } else if (ItemTemp === this.localization.captions.calendar.Tue) {
        dayArr.push(2);
      } else if (ItemTemp === this.localization.captions.calendar.Wed) {
        dayArr.push(3);
      } else if (ItemTemp === this.localization.captions.calendar.Thu) {
        dayArr.push(4);
      } else if (ItemTemp === this.localization.captions.calendar.Fri) {
        dayArr.push(5);
      } else if (ItemTemp === this.localization.captions.calendar.Sat) {
        dayArr.push(6);
      } else if (ItemTemp === this.localization.captions.calendar.Sun) {
        dayArr.push(7);
      }
    }

    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetValidServices',
      method: HttpMethod.Post,
      body: dayArr,
      uriParams: { startDate: sDate, endDate: eDate },
      showError: true,
      extraParams: []
    });
  }


  GetRetailItemByType(itemType: number) {
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetRetailItemByItemType",
      method: HttpMethod.Get,
      uriParams: {
        type: itemType
      },
      showError: true,
      extraParams: []
    });
  }


  GetAllSpaPackagesActiveAndInactive() {

    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetSpaPackages',
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetAllSpaPackageClass() {
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetSpaPackageClass',
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  CopyPackage(id: number): any {
    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'CopyPackage',
      method: HttpMethod.Get,
      uriParams: { id: id },
      showError: true,
      extraParams: []
    });
  }

  GetPackageAppointmentDate(id: number): any {

    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'GetPackageAppointmentDate',
      method: HttpMethod.Get,
      uriParams: { id: this.id },
      showError: true,
      extraParams: []
    });
  }

  enableCurrencyFormat() {
    this.disableCurrencyFormat = false;
  }

  successCallback(result: BaseResponse<any>, callDesc: string, extraParams: any[]) {
    if (callDesc === 'GetAllServiceGrp') {
      this.categoriesTemp = [];
      const res = <any>result.result;
      this.categoriesTemp = [{ id: 0, description: this.captions.AllServiceGroups }];
      this.categoriesTemp = this.categoriesTemp.concat(res ? res : []);
    } else if (callDesc === 'GetValidServices') {
      this.AllServices = [];
      this.AllServices = result.result;
      this.AllServices.sort((a, b) => a.id - b.id);
      this.AllServices.forEach(element => {
        element.serviceId = element.id
      });
      this.serviceArray = cloneDeep(this.AllServices);
    } 
    else if(callDesc === 'GetRetailItemByItemId'){
      this.retailItemsOutlet=[];
      this.retailItemsOutlet=result.result;
    }
    else if (callDesc === 'GetRetailItemByItemType') {
      this.AllretailItems = [];
      this.AllretailItems = result.result;
      this.RetailItemsArray = cloneDeep(this.AllretailItems);
    } else if (callDesc === 'AddPackage') {
      this.tableoptions[0].TablebodyData = result.result;
      this.unfilteredTableData = result.result
      this.dialogRef.close();
    } else if (callDesc === 'GetSpaPackages') {
      this.packageYieldJson = result.result;
    } else if (callDesc === 'GetSpaPackageClass') {
      this.packageClass = result.result;
    } else if (callDesc === 'CopyPackage') {

      this.copyPackageInformation = result.result;
      this.BindCopyInfo();
    } else if (callDesc === 'UpdatePackage') {
      this.dialogRef.close();
    } else if (callDesc === 'GetPackageAppointmentDate') {

      this.packageAppointmentDate = result.result ? result.result : null;
      if (this.packageAppointmentDate != null) {
        this.editEndDate = this.packageAppointmentDate.endDate;
        this.editStartDate = this.packageAppointmentDate.startDate;
      } else {
        this.minEndDate = this.spaPackage.controls.startDate.value;
      }
    }
    else if (callDesc === 'GetConfiguration')  {
      if (result.result) {
        const res = <any>result.result;
        this.DefaultCancelationPolicy = res && res.value ? res.value : '';
      }
    }
  }

  setTableData(body) {

    const totalIds = this.checkedItems.map(a => a.serviceId);
    let serviceIds=this.checkedItems.filter(s=>s.serviceId!=0).map(s=>s.serviceId);
    var itemIds = [];
    if (totalIds.includes(0)) {
        itemIds = this.checkedItems.filter(a=>a.serviceId==0).map(r => r.retailItemId? r.retailItemId:r.id);
    }
    itemIds = serviceIds.concat(itemIds);
    if (body != null) {
      body.forEach(element => {
        if (itemIds.indexOf(element.id) > -1) {
          element['checked'] = true;
        } else {
          element['checked'] = false;
        }
      });
    }

    if (this.checkedItems && this.checkedItems.length > 0) {
      if (body != undefined && body.length > 0) {

        this.selectedPackageComponent = body.filter(x => itemIds.includes(x.id));

      }
    }

    const ActiveServices = (body != null && body.length > 0) ? body.filter(res => res.isActive === true) : '';
    const header = [{ 'title': this.captions.ServiceRetailCode, 'jsonkey': 'code' },
    { 'title': this.captions.Description, 'jsonkey': 'description' },
    { 'title': `${this.captions.Price} (${this.localization.currencySymbol})`, 'jsonkey': 'price', 'datatype': 'money', 'alignType': 'right' }];
    this.tableoptions = [{
      TableHdrData: header,
      TablebodyData: ActiveServices,
      pagination: false,
      sortable: false,
      CustomColumn: true,
      PlaceHoldertext: this.captions.ServiceRetailCodeDescription,
      EnableActions: false,
      SelectRows: true,
      IsCommission: true,
      SelectedSettingId: GridType.spaPackage,
      SelectedData: this.selectedPackageComponent,
      Searchable: false,
      EditMoreOption: true,
      toggleDisplayText: this.captions.ServicesOnly,
      isEditOptionRemove: true
    }];
    this.unfilteredTableData = ActiveServices
  }
  errorCallback() {
    console.error('error');
  }

  formBodyData(data: any) {
    const retailItemsUiModel: RetailItemUIMapper[] = [];
    data.forEach(d => {
      retailItemsUiModel.push({
        id: d.id,
        itemType: d.itemType,
        code: d.itemNumber,
        description: d.itemDescription,
        price: d.salesPrice,
        quantity: d.quantity,
        isActive: d.isActive,
        serviceId: 0
      });
    });
    return retailItemsUiModel;
  }

  onTabChange(e) {
    
    if (e && e.selectedIndex === 1) {

      if ((this.DayArr && this.DayArr.length === 0) && !this.spaPackage.controls.maximumDays.value) {
        this.DayArr = ['1'];
      }
      this.GetRetailItemByType(this.packageRetailItemType);
      this.GetRetailItemByItemId(this.selectedRetailItemId);
      let OutletIds=this.retailItemsOutlet?.outletItem.map(a=>a.outletId);
      //this.AllretailItems= this.AllretailItems.filter(r=>r.outletItem.some(a=>OutletIds.includes(a.outletId)));
      var filteredRetailItems=[];
      this.AllretailItems.forEach(element => {
       var data= element.outletItem.filter(y=>OutletIds.includes(y.outletId));
       if(data&&data.length>0){
        filteredRetailItems.push(element);
       }
      });
      this.AllretailItems=filteredRetailItems;
      //var items=cloneDeep(this.AllServices).concat(cloneDeep(this.AllretailItems.map(a=>a.retailItemDetail)));
      var retailItems = cloneDeep(this.AllretailItems.map(a => a.retailItemDetail));
      //let serviceBody:ServiceUIMapper[]=this.formServiceBodyData(this.AllServices);
      let body: RetailItemUIMapper[] = this.formBodyData(retailItems);
      var bodyData = cloneDeep(this.AllServices).concat(body);
      this.ServiceRetail=bodyData;
      this.setTableData(bodyData);
      this.viewChecked = false;
      this.countCalc();
      this.calculateTotalPrice();
    }
    this.minEndDate = this.utils.getDate(this.spaPackage.controls.startDate.value);
    this.pakageStartDate = this.utils.getDate(this.spaPackage.controls.startDate.value);
    this.packageEndDate = this.utils.getDate(this.spaPackage.controls.endDate.value);
    this.packageYieldMinEndDate = this.utils.getDate(this.spaPackage.controls.startDate.value);

    
    //this.UpdateComponentPrices();
    //this.createSpaService();
    //this.addValue(this.checkedItems);
    this.createYeilding();
    if(!this.IsComponentSelectable)
    {
    this.minimumYeildingAmount = 0;
    let allowPriceChangeArray = this.checkedItems.filter(x=> x.allowPriceChange == false);
    for (let i = 0; i < allowPriceChangeArray.length; i++) {
      this.minimumYeildingAmount = this.minimumYeildingAmount + this.localization.currencyToSQLFormat(allowPriceChangeArray[i].amount);
      console.log("Minimum",this.minimumYeildingAmount)
    }
    }
  }

  // Handles onchange event of the form
  onChanges(): void {
    this.startDateSubscription = this.spaPackage.get('startDate').valueChanges.subscribe(val => {
      this.minEndDate = val;
      const d = this.utils.GetFormattedDate(val);
      const d1 = this.utils.GetFormattedDate(this.packageAppointmentDate ? this.packageAppointmentDate.startDate : val);
      if (this.spaPackage.get('startDate').value > this.spaPackage.get('endDate')) {
        this.spaPackage.get('endDate').setErrors({ incorrect: true });
      }

      if (this.utils.getDate(d) > this.utils.getDate(d1)) {
        const showDate = this.utils.GetFormattedDate(this.packageAppointmentDate ? this.packageAppointmentDate.startDate : val);
        this.startDateError = this.captions.Minimumstartdatemustbe + showDate;
        this.spaPackage.get('startDate').setErrors({ incorrect: true });
      }

      let dayval = this.spaPackage.controls.maximumDays.value;
      const tempDay = (this.DayArr ? this.DayArr[0] : null);
      dayval = dayval ? dayval : tempDay;
      if (dayval && !this.checkMaxDaysWithSelectedDate(dayval)) {
        this.spaPackage.controls.maximumDays.setValue('');
        this.DayArr = [];
      }

    });

    this.endDateSubscription = this.spaPackage.get('endDate').valueChanges.subscribe(val => {
      const e = this.utils.GetFormattedDate(val);
      const e1 = this.utils.GetFormattedDate(this.packageAppointmentDate ? this.packageAppointmentDate.endDate : val);

      if (this.spaPackage.get('startDate').value > this.spaPackage.get('endDate')) {
        this.spaPackage.get('endDate').setErrors({ incorrect: true });
      }

      if (this.utils.getDate(e) < this.utils.getDate(e1)) {
        const showDate = this.utils.GetFormattedDate(this.packageAppointmentDate ? this.packageAppointmentDate.endDate : val);
        this.endDateError = 'Minimum end date must be ' + showDate;
        this.spaPackage.get('endDate').setErrors({ incorrect: true });
      }

      let dayval = this.spaPackage.controls.maximumDays.value;
      const tempDayVal = (this.DayArr ? this.DayArr[0] : null);
      dayval = dayval ? dayval : tempDayVal;
      if (dayval && !this.checkMaxDaysWithSelectedDate(dayval)) {
        this.spaPackage.controls.maximumDays.setValue('');
        this.DayArr = [];
      }
    });

    this.packageyieldStartDateSubscription = this.spaPackageYield.get('startDate').valueChanges.subscribe(val => {
      this.packageYieldMinEndDate = val;
      if (this.spaPackageYield.get('startDate').value > this.spaPackageYield.get('endDate')) {
        this.spaPackageYield.get('endDate').setErrors({ incorrect: true });
      }
    });

    this.packageyieldEndDateSubscription = this.spaPackageYield.get('endDate').valueChanges.subscribe(val => {
      if (this.spaPackageYield.get('startDate').value > this.spaPackageYield.get('endDate')) {
        this.spaPackageYield.get('endDate').setErrors({ incorrect: true });
      }
    });
  }

  checkServicesSelected(): any {
    let numberOfServicesDefined: number=1;
    if (this.spaPackageService.controls.isComponentSelected.value) {
      let serviceArray = this.calculateServiceComponentPrice(this.spaServices);
      if(serviceArray.length <= 0){
        this.stepper.selectedIndex = 1;
        const errorMsg = this.captions.PackageErrNoOfServices;
        this.business.openErrorDialog(errorMsg, this.localization.captions.common.Error, this.captions.ServiceSelection);
        return false;
      }
      // if ((this.totalServicePrice<=0)||(this.spaServices.length < numberOfServicesDefined)) {        
      //   this.stepper.selectedIndex = 1;
      //   const errorMsg = this.localization.replacePlaceholders(this.captions.spaPackageErrNoOfServices, ['NoOfServices'], [numberOfServicesDefined]);
      //   this.business.openErrorDialog(errorMsg, this.localization.captions.common.Error, this.captions.ServiceSelection);
      //   return false;
      //   }
      }
      else
      {
        if(this.spaServices.length < numberOfServicesDefined){
          this.stepper.selectedIndex = 1;
          const errorMsg = this.captions.PackageErrNoOfServices;
          this.business.openErrorDialog(errorMsg, this.localization.captions.common.Error, this.captions.ServiceSelection);
          return false;
        }
      }
      if(this.isServiceNegative && this.isRetailNegative){
        this.stepper.selectedIndex = 1;
        const errorMsg = this.captions.spaPackageErrNegativePrice;
        this.business.openErrorDialog(errorMsg, this.localization.captions.common.Error, this.localization.captions.common.Error);
        return false;
      }
      if (this.isServiceNegative) {
        this.stepper.selectedIndex = 1;
        const errorMsg = this.captions.spaPackageErrServiceNegativePrice;
        this.business.openErrorDialog(errorMsg, this.localization.captions.common.Error, this.localization.captions.common.Error);
        return false;
      }
      if(this.isRetailNegative){
        this.stepper.selectedIndex = 1;
        const errorMsg = this.captions.spaPackageErrRetailNegativePrice;
        this.business.openErrorDialog(errorMsg, this.localization.captions.common.Error, this.localization.captions.common.Error);
        return false;
      }
    return true;
  }


  trackByFn(index, item) {
    return index;
  }
  MaxPeopleBlur($event) {
    const val = this.spaPackage.controls.maximumDays.value;
    if (val && !this.checkMaxDaysWithSelectedDate(val)) {
      this.spaPackage.controls.maximumDays.setValue('');
    }
  }

  checkMaxDaysWithSelectedDate(val: number) {
    let startDate = this.data.popupConfig.operation === 'edit' ? this.PropertyInfo.CurrentDate : this.spaPackage.controls.startDate.value;
    let endDate = this.spaPackage.controls.endDate.value;
    if (startDate && endDate) {
      // Available till end of the day - 11.59pm
      startDate = this.utils.getDate(this.utils.formatDate(startDate) + "T" + "00:00")
      endDate = this.utils.getDate(this.utils.formatDate(endDate) + "T" + "23:59")
      const diff = this.utils.DateDiff.inDays(startDate, endDate);
      if (diff < val) {
        return false;
      }
    }
    return true;
  }
  selectedCollection(event, type) { // this._su.type1Array its named as serviceVaraiable
    this.spaPackage.markAsDirty();
    const serviceVaraiable = [];
    const selectedArray = event[0];
    this.arrayValues.forEach(x => {
      if (selectedArray.indexOf(x.id) !== -1) {
        serviceVaraiable.push(x.name);
        x.setSelected = true;
      } else {
        x.setSelected = false;
      }
    });
    if (type === 'type1') {
      this._su.type1Array = serviceVaraiable;
    }
    if (type === 'type2') {
      this._su.type2Array = serviceVaraiable;
    }
    if (this.availableDays)
      this.availableDays.initValues();
  }

  selectYieldDays(days: any[]) {
    this.spaPackage.markAsDirty();
    this.selectedYieldDaysArr = days[0];
  }

  getTitle() {
    return `${this.captions.SelectedServices} / ${this.captions.RetailItems}`;
  }
  validateSearchControl(isSearchControlinValid: boolean) {
    this.retailItemMiss = !isSearchControlinValid;
  }

  countCalc() {
    let value;
    let parentHeight;
    let parentElement;

    parentElement = document.getElementById("servicestepper");
    if (parentElement != null) {
      parentHeight = parentElement.clientHeight;
      value = (parentHeight - 75) / 37;
      value = Math.round(value);
    }
    this.serviceGrpCount = (value - 1);

  }

  startDateChange($event: MatDatepickerInputEvent<Date>): void {
    let endDate: Date = this.localization.dateAdd.AddDays($event.value, 1);
    if (!this.spaPackageYield.controls.endDate.value)
      this.spaPackageYield.controls.endDate.setValue(endDate);
    this.fillAllAvailableDays();
  }

  async getLinkedItemDesc(retailItemId: number) {
    var itemText = await this.business.GetLinkedRetailItemTextByItemId(retailItemId);
    this.spaPackage.controls['linkedItem'].setValue(itemText);
  }

  async searchTextEmit(searchText) {
    this.retailItemMiss = true;
    this.retailItemDirty = false;
    this.searchFilter = [];
    this.guid = this.utils.generateGUID();
    let body: ItemSearchRequest = {
      itemType: this.retailItemType,
      searchKey: searchText,
      requestId: this.guid
    };
    var response = await this.business.SearchRetailItem(body);
    if (response && response.requestId === this.guid) {
      this.searchFilter = response.retailItems;
    }
  }
  // changeQuantity(item, $, idx) {
   
  //   this.quantity=0;
  //   this.retailItemPrice=0;
  //   this.calculatedRetailPrice=0;
  //   for(let selectedItemsItem of this.spaRetailItems)
  //   {
  //     if(item.value.id != null){
  //       if(item.value.id === selectedItemsItem.value.id)
  //       { 
  //         if(item.value.quantity==0)
  //         {
  //         this.quantity=1;
  //         }
  //         else{
  //         this.quantity=Number(item.controls.quantity.value);
  //         }
  //        this.retailItemPrice=(this.ServiceRetail.filter(a=>a.serviceId==0 && a.id==item.value.id))[0].price;
  //        this.calculatedRetailPrice=this.quantity*this.retailItemPrice;
  //        selectedItemsItem.retailPrice = this.calculatedRetailPrice;
  //        selectedItemsItem.value.retailPrice=this.calculatedRetailPrice;    
  //        if (this.data.popupConfig.operation === 'edit'){
  //         this.checkedItems.forEach(element => {
  //           if(((element.retailItemId?element.retailItemId:element.id)==item.value.id) && element.serviceId==0)
  //           {
  //            element.quantity=this.quantity;
  //            element.price=this.calculatedRetailPrice;
  //           }
  //         });
  //        }
  //        else{
  //        this.checkedItems.forEach(element => {
  //         if(element.id==item.value.id && element.serviceId==0)
  //         {
  //         element.quantity=this.quantity;
  //         element.price=this.calculatedRetailPrice;
  //         }
  //        });
  //        }
  //       }
  //     }
  //     else{
  //       if(item.value.code === selectedItemsItem.value.code)
  //       { 
  //         if(item.value.quantity==0)
  //         {
  //         this.quantity=1;
  //         }
  //         else{
  //         this.quantity=Number(item.controls.quantity.value);
  //         }
  //        this.retailItemPrice=(this.ServiceRetail.filter(a=>a.serviceId==0 && a.code==item.value.code))[0].price;
  //        this.calculatedRetailPrice=this.quantity*this.retailItemPrice;
  //        selectedItemsItem.retailPrice = this.calculatedRetailPrice;
  //        selectedItemsItem.value.retailPrice=this.calculatedRetailPrice;    
  //        if (this.data.popupConfig.operation === 'edit'){
  //         this.checkedItems.forEach(element => {
  //           if(element.code==item.value.code && element.serviceId==0)
  //           {
  //            element.quantity=this.quantity;
  //            element.price=this.calculatedRetailPrice;
  //           }
  //         });
  //        }
  //        else{
  //        this.checkedItems.forEach(element => {
  //         if(element.id==item.value.id && element.serviceId==0)
  //         {
  //         element.quantity=this.quantity;
  //         element.price=this.calculatedRetailPrice;
  //         }
  //        });
  //       }
        
  //     }
  //   }
  //   //this.UpdateComponentPrices();
  //   this.createSpaService();
  //   //this.addValue(this.checkedItems);
  //   }
  //  } 

   GetRetailItemByItemId(itemId: number) {
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetRetailItemByItemId",
      method: HttpMethod.Get,
      uriParams: {
        itemId: itemId
      },
      showError: true,
      extraParams: []
    });
  }

  priceTypeChange(e){

  }
  allowPriceChangeToggle(item,e){
    let value = e[0];
    if(Number(item.controls.amount.value) == 0){
      item.controls.allowPriceChange.setValue(false);
      item.controls.allowPriceChange.disable();
      value = false;
    }
    else{
     item.controls.allowPriceChange.enable();
    }
    for (let checkedItemsItem of this.checkedItems) {
      const checkedRetailItemId=checkedItemsItem.retailItemId?checkedItemsItem.retailItemId:checkedItemsItem.id;
      if (item.value.id === checkedItemsItem.id ||item.value.id === checkedRetailItemId) {
        checkedItemsItem.allowPriceChange=value;
      }
    }
    this.createYeilding();
  }
  serviceTableViewChange(e){
   
  }
  changePercent(item,price){
    if(Number(item.value.quantity==0) ||item.value.quantity=="")
    {
      item.value.quantity=1;
      item.controls.quantity.setValue(1);
    }
    let amount = this.localization.currencyToSQLFormat(item.controls[price].value) * this.localization.currencyToSQLFormat(item.value.quantity);
    let serviceamount = (this.localization.currencyToSQLFormat(item.value.percent) / 100) * amount;

    if(item.value.incrementDecrement == IncDec.increment)
    item.controls.amount.setValue(this.localization.localizeCurrency(amount + serviceamount,false));
    if(item.value.incrementDecrement == IncDec.decrement)
    item.controls.amount.setValue(this.localization.localizeCurrency(amount - serviceamount,false));

    if(this.localization.currencyToSQLFormat(item.controls.amount.value) == 0){
       item.controls.allowPriceChange.setValue(false);
       item.controls.allowPriceChange.disable();
    }
    else{
      item.controls.allowPriceChange.enable();
    }

    for (let checkedItemsItem of this.checkedItems) {
      const checkedRetailItemId=checkedItemsItem.retailItemId?checkedItemsItem.retailItemId:checkedItemsItem.id;
      if (item.value.id === checkedItemsItem.id ||item.value.id === checkedRetailItemId) {
        checkedItemsItem.incrementDecrement=item.value.incrementDecrement;
        checkedItemsItem.percent=this.localization.localizeCurrency(item.value.percent,false)
        checkedItemsItem.amount=item.value.amount
        checkedItemsItem.allowPriceChange=item.value.allowPriceChange
        checkedItemsItem.quantity=item.value.quantity
        checkedItemsItem.fixedVariable = item.value.fixedVariable
      }
    }
    this.createYeilding();
    this.calculateTotalPrice();
  }

  changeAmount(item,percentcontrol,price){
    if(this.localization.currencyToSQLFormat(item.controls[price].value) == 0){
        item.controls.amount.setValue(this.localization.localizeCurrency('0',false));
        item.controls[percentcontrol].setValue(this.localization.currencyToSQLFormat('0'));
    }
    else{
      let totalamount = this.localization.currencyToSQLFormat(item.value.quantity) * this.localization.currencyToSQLFormat(item.controls[price].value);
      let percentageAmount =  this.localization.currencyToSQLFormat(item.value.amount) - totalamount;
      let percent = ((percentageAmount/totalamount) * 100)
      if(percent < 0)
        item.controls.incrementDecrement.setValue(IncDec.decrement);
      else{
        item.controls.incrementDecrement.setValue(IncDec.increment);
      }
    item.controls[percentcontrol].setValue(this.localization.localizeCurrency(Math.abs(percent),false));
    }

    if(this.localization.currencyToSQLFormat(item.controls.amount.value) == 0){
      item.controls.allowPriceChange.setValue(false);
      item.controls.allowPriceChange.disable();
   }
   else{
     item.controls.allowPriceChange.enable();
   }
   
    for (let checkedItemsItem of this.checkedItems) {
      const checkedRetailItemId=checkedItemsItem.retailItemId?checkedItemsItem.retailItemId:checkedItemsItem.id;
      if (item.value.id === checkedItemsItem.id ||item.value.id === checkedRetailItemId) {
        checkedItemsItem.incrementDecrement=item.value.incrementDecrement;
        checkedItemsItem.percent=this.localization.localizeCurrency(item.value.percent,false)
        checkedItemsItem.amount=this.localization.localizeCurrency(item.value.amount,false)
        checkedItemsItem.allowPriceChange=item.value.allowPriceChange
        checkedItemsItem.quantity=item.value.quantity
        checkedItemsItem.fixedVariable = item.value.fixedVariable
      }
    }
    this.createYeilding();
    this.calculateTotalPrice();
  }
  incDecChange(item,price){
   this.changePercent(item,price);
  }

  calculateTotalPrice(){
      this.totalServicePrice = 0;
      this.totalServiceAmount = 0;
      this.totalRetailPrice = 0;
      this.totalRetailAmount = 0;
      this.totalPrice = 0;
      this.totalPackageAmount = 0;
      this.totalServiceQuantityAmount = 0;
      this.totalRetailQuantityAmount = 0
      this.totalServicePercent = 0;
      this.totalRetailPercent =0;
      this.totalPackagePercent = 0;

      this.fixedService = 0;
      this.variableService = 0;
      this.totalServiceComponent = 0;
      this.fixedRetail = 0;
      this.variableRetail = 0;
      this.totalRetailComponent = 0;
      this.totalComponent = 0;
      let serviceNegative=false;
      let retailNegative=false;
      if(!this.IsComponentSelectable){
      for (let i = 0; i < this.spaServices.length; i++) {
        if(this.localization.currencyToSQLFormat(this.spaServices[i].value.amount)<0)
          {
            serviceNegative=true;
          }
        this.totalServicePrice = this.totalServicePrice + this.localization.currencyToSQLFormat(this.spaServices[i].value.servicePrice);
        this.totalServiceAmount = this.totalServiceAmount + this.localization.currencyToSQLFormat(this.spaServices[i].value.amount);
        this.totalServiceQuantityAmount = this.totalServiceQuantityAmount + (this.localization.currencyToSQLFormat(this.spaServices[i].value.servicePrice) * this.localization.currencyToSQLFormat(this.spaServices[i].value.quantity));
      }
     
      for (let i = 0; i < this.spaRetailItems.length; i++) {
        if(this.localization.currencyToSQLFormat(this.spaRetailItems[i].value.amount)<0)
          {
            retailNegative=true;
          }
        this.totalRetailPrice = this.totalRetailPrice + this.localization.currencyToSQLFormat(this.spaRetailItems[i].value.retailPrice);
        this.totalRetailAmount = this.totalRetailAmount + this.localization.currencyToSQLFormat(this.spaRetailItems[i].value.amount);
        this.totalRetailQuantityAmount = this.totalRetailQuantityAmount + (this.localization.currencyToSQLFormat(this.spaRetailItems[i].value.retailPrice) * this.localization.currencyToSQLFormat(this.spaRetailItems[i].value.quantity));
      }

    }
    else{
     let serviceArray = this.calculateServiceComponentPrice(this.spaServices);
     let retailArray = this.calculateRetailComponentPrice(this.spaRetailItems);
     for (let i = 0; i < serviceArray.length; i++) {
        if(this.localization.currencyToSQLFormat(serviceArray[i].value.amount)<0)
          {
            serviceNegative=true;
          }
        this.totalServicePrice = this.totalServicePrice + this.localization.currencyToSQLFormat(serviceArray[i].value.servicePrice);
        this.totalServiceAmount = this.totalServiceAmount + this.localization.currencyToSQLFormat(serviceArray[i].value.amount);
        this.totalServiceQuantityAmount = this.totalServiceQuantityAmount + (this.localization.currencyToSQLFormat(serviceArray[i].value.servicePrice) * Number(serviceArray[i].value.quantity));
      }
     for (let i = 0; i < retailArray.length; i++) {
      if(this.localization.currencyToSQLFormat(retailArray[i].value.amount)<0)
        {
          retailNegative=true;
        }
      this.totalRetailPrice = this.totalRetailPrice + this.localization.currencyToSQLFormat(retailArray[i].value.retailPrice);
      this.totalRetailAmount = this.totalRetailAmount + this.localization.currencyToSQLFormat(retailArray[i].value.amount);
      this.totalRetailQuantityAmount = this.totalRetailQuantityAmount + (this.localization.currencyToSQLFormat(retailArray[i].value.retailPrice) * this.localization.currencyToSQLFormat(retailArray[i].value.quantity));
    }
    for (let i = 0; i < this.spaServices.length; i++) {
        if(this.spaServices[i].value.fixedVariable){
          this.fixedService = ++this.fixedService
        }
        else{
          this.variableService = ++this.variableService
        }
      }
    for (let i = 0; i < this.spaRetailItems.length; i++) {
        if(this.spaRetailItems[i].value.fixedVariable){
          this.fixedRetail = ++this.fixedRetail
        }
        else{
            this.variableRetail = ++this.variableRetail
        }
    }

  }
  
    this.isServiceNegative=serviceNegative;
    this.isRetailNegative=retailNegative;
    this.totalServicePercent = ((this.totalServiceAmount - this.totalServiceQuantityAmount)/this.totalServiceQuantityAmount)  * 100;
    this.isServiceIncreased = this.totalServicePercent;
    this.totalServicePercent = this.totalServicePercent ? Math.abs(this.totalServicePercent) : 0

      this.totalRetailPercent = ((this.totalRetailAmount - this.totalRetailQuantityAmount)/this.totalRetailQuantityAmount)  * 100
      this.isRetailPriceIncreased = this.totalRetailPercent;
      this.totalRetailPercent = this.totalRetailPercent ? Math.abs(this.totalRetailPercent) : 0;
  
      this.totalPrice = this.totalServicePrice + this.totalRetailPrice;
      this.totalPackageAmount = this.totalServiceAmount + this.totalRetailAmount;
      this.totalPakageQuantityAmount = this.totalRetailQuantityAmount + this.totalServiceQuantityAmount;
  
      this.totalPackagePercent = ((this.totalPackageAmount - this.totalPakageQuantityAmount)/this.totalPakageQuantityAmount)  * 100
      this.isPackagePriceIncreased = this.totalPackagePercent;
      this.totalPackagePercent =this.totalPackagePercent ? Math.abs(this.totalPackagePercent) : 0;
      this.spaPackage.controls.packagePrice.setValue(this.localization.localizeCurrency(this.totalPackageAmount.toString()));
      if(this.IsComponentSelectable){
      this.totalServiceComponent = this.fixedService + Number(this.spaPackageService.controls.serviceVariable.value);
      this.totalRetailComponent = this.fixedRetail + Number(this.spaPackageService.controls.retailVariable.value);
      this.totalComponent = this.totalServiceComponent + this.totalRetailComponent;
      this.checkServiceVariable();
      this.checkRetailVariable();
      }
  }

  calculateServiceComponentPrice(array){
    let variableArray = array.filter(x=>x.controls.fixedVariable.value == false);
    let fixedArray = array.filter(x=>x.controls.fixedVariable.value == true);
    let n = Number(this.spaPackageService.controls.serviceVariable.value)
    if(this.spaPackageService.controls.topBottom.value){
     variableArray = variableArray.sort((a , b) => this.localization.currencyToSQLFormat(b.controls.amount.value) - this.localization.currencyToSQLFormat(a.controls.amount.value)).slice(0,n);
    }
    else{
      variableArray =variableArray.sort((a , b) =>this.localization.currencyToSQLFormat( a.controls.amount.value) - this.localization.currencyToSQLFormat(b.controls.amount.value)).slice(0,n);
    }
    return [...fixedArray,...variableArray ];
  }
  calculateRetailComponentPrice(array){
    let variableArray = array.filter(x=>x.controls.fixedVariable.value == false);
    let fixedArray = array.filter(x=>x.controls.fixedVariable.value == true);
    let n = Number(this.spaPackageService.controls.retailVariable.value)
    if(this.spaPackageService.controls.topBottom.value){
     variableArray = variableArray.sort((a , b) => this.localization.currencyToSQLFormat(b.controls.amount.value) - this.localization.currencyToSQLFormat(a.controls.amount.value)).slice(0,n);
    }
    else{
      variableArray =variableArray.sort((a , b) => this.localization.currencyToSQLFormat(a.controls.amount.value) - this.localization.currencyToSQLFormat(b.controls.amount.value)).slice(0,n);
    }
    return [...fixedArray, ...variableArray ];
  }
  startDateValueChange(index: number) {
    const yeildArray = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    yeildArray['controls'][index]['controls'].minEndDate.setValue(yeildArray['controls'][index]['controls'].startDate.value);
    const yeildDateValues = yeildArray.value.map(res => {
      return {
        startDate: res.startDate,
        endDate: res.endDate
      }
    });
    const startDate = yeildDateValues[index].startDate;
    const endDate = yeildDateValues[index].endDate;
    yeildDateValues.forEach((value, idx) => {
      if (index != idx
        && (((value.startDate && startDate >= value.startDate) && (value.endDate && startDate <= value.endDate))
          || ((value.startDate && endDate >= value.startDate) && (value.endDate && endDate <= value.endDate))
          || (startDate && endDate && startDate <= value.startDate && endDate >= value.endDate))) {
            yeildArray['controls'][index]['controls'].startDate.setValue('');
            yeildArray['controls'][index]['controls'].endDate.setValue('');
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.common.DateOverlap, ButtonType.Ok);
      }
    });
    if (endDate && endDate < startDate) {
      yeildArray['controls'][index]['controls'].endDate.setValue('');
    }
    this.enableOrDisableDays(index);
  }

  endDateValueChange(index) {
    const yeildArray = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    const yeildDateValues = yeildArray.value.map(res => {
      return {
        startDate: res.startDate,
        endDate: res.endDate
      }
    });
    const startDate = yeildDateValues[index].startDate;
    const endDate = yeildDateValues[index].endDate;
    yeildDateValues.forEach((value, idx) => {
      if (index != idx
        && (((value.startDate && startDate >= value.startDate) && (value.endDate && startDate <= value.endDate))
          || ((value.startDate && endDate >= value.startDate) && (value.endDate && endDate <= value.endDate))
          || (startDate && endDate && startDate <= value.startDate && endDate >= value.endDate))) {
            yeildArray['controls'][index]['controls'].startDate.setValue('');
            yeildArray['controls'][index]['controls'].endDate.setValue('');
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.common.DateOverlap, ButtonType.Ok);
      }
    });
    this.enableOrDisableDays(index);
  }

  enableOrDisableDays(index) {
    const yeildArray = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    const yeildData = yeildArray['controls'][index]['controls'];
    yeildArray['controls'][index]['controls'].startDate.markAsTouched();
    yeildArray['controls'][index]['controls'].endDate.markAsTouched();
    this.disableYeildingDays(yeildData);
    const startDate = _.cloneDeep(yeildData.startDate.value);
    const endDate = _.cloneDeep(yeildData.endDate.value);
    if (startDate && endDate) {
      yeildData.allDays.enable({ emitEvent: false });
    }
    const diffTime = endDate - startDate;
    let daysDiff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    daysDiff = daysDiff + 1;
    if (daysDiff < 7) {
      for (let i = 0; i < daysDiff; i++) {
        this.enableYeildingDay(startDate.getDay(), yeildData);
        startDate.setDate(startDate.getDate() + (i == 0 ? (i + 1) : 1));
      }
    } else {
      yeildArray['controls'][index].enable({ emitEvent: false });
    }
    this.emptyDisabledYeildingDays(yeildData);
  }

  emptyDisabledYeildingDays(yeildData) {
    const startDate = _.cloneDeep(yeildData.startDate.value);
    const endDate = _.cloneDeep(yeildData.endDate.value);
    if (startDate && endDate) {
      yeildData.allDays.enable({ emitEvent: false });
      this.days.forEach(val => {
        let value = this.localization.currencyToSQLFormat(yeildData[val].value)
        if(value < this.minimumYeildingAmount){
          if(value!=0)
          yeildData[val].setErrors({required :true});
        }
        if (yeildData[val].disabled) {
          yeildData[val].setValue('0.00', { emitEvent: false });
        }
      });
    }
    
  }

  enableYeildingDay(day: Days, seasonSetup) {
    switch (day) {
      case Days.Sunday:
        seasonSetup.sun.enable({ emitEvent: false });
        break;
      case Days.Monday:
        seasonSetup.mon.enable({ emitEvent: false });
        break;
      case Days.Tuesday:
        seasonSetup.tue.enable({ emitEvent: false });
        break;
      case Days.Wednesday:
        seasonSetup.wed.enable({ emitEvent: false });
        break;
      case Days.Thursday:
        seasonSetup.thu.enable({ emitEvent: false });
        break;
      case Days.Friday:
        seasonSetup.fri.enable({ emitEvent: false });
        break;
      case Days.Saturday:
        seasonSetup.sat.enable({ emitEvent: false });
        break;
    }
  }

  disableYeildingDays(yeildData) {
    yeildData.allDays.disable({ emitEvent: false });
    yeildData.sun.disable({ emitEvent: false });
    yeildData.mon.disable({ emitEvent: false });
    yeildData.tue.disable({ emitEvent: false });
    yeildData.wed.disable({ emitEvent: false });
    yeildData.thu.disable({ emitEvent: false });
    yeildData.fri.disable({ emitEvent: false });
    yeildData.sat.disable({ emitEvent: false });
  }

  setActiveIndex(index: any,event): void {
    this.activeSeasonalSetupFormGrp = index;
    this.setDaysValue(event.target.value);
  }
   setDaysValue(value: any): void {
    const yeildArrayControls = this.spaPackageYield.controls.yeildDataArray as UntypedFormArray;
    const daysFormArr = yeildArrayControls['controls'][this.activeSeasonalSetupFormGrp]['controls'];
    this.days.forEach(val => {
      if (daysFormArr[val].disabled) {
        daysFormArr[val].setValue('0.00', { emitEvent: false });
      } else if (daysFormArr[val].enabled) {
        const delocalizedNumber = this.localization.currencyToSQLFormat(value);
        const currencyValue = this.localization.localizeCurrency(delocalizedNumber, false)
        daysFormArr[val].setValue(currencyValue, { emitEvent: false });
      }
    });
  }

  setAllDaysValue(index,event: any): void {
    const val = event.target.value;
    const yeildArrayControls = this.spaPackageYield.controls.yeildDataArray as UntypedFormArray;
    const yeild = yeildArrayControls.at(index) as UntypedFormGroup;
      let daysCount = 0;
      Object.keys(yeild.controls).forEach((key) => {
        if (key != "endDate" && key != 'startDate') {
          if (yeild.get(key).value === val) {
            daysCount++;
          }
        }
      });
      if (daysCount == 7) {
        yeild.patchValue({
          allDays: val
        }, { emitEvent: false });
      } else {
        yeild.patchValue({
          allDays: '0.00'
        }, { emitEvent: false });
      }
    
  }

  selectAllDaysAvailable(index,formcontrol){
    const yeildArrayControls = this.spaPackageYield.controls.yeildDataArray as UntypedFormArray;
    const yeild = yeildArrayControls.at(index) as UntypedFormGroup;
    const val = !yeild.controls[formcontrol].value;
    yeild.controls.isAllDaysAvailable.setValue(val);
    yeild.controls.isMonAvailable.setValue(val);
    yeild.controls.isTueAvailable.setValue(val);
    yeild.controls.isWedAvailable.setValue(val);
    yeild.controls.isThuAvailable.setValue(val);
    yeild.controls.isFriAvailable.setValue(val);
    yeild.controls.isSatAvailable.setValue(val);
    yeild.controls.isSunAvailable.setValue(val);
  }

  selectAvailable(index,formcontrol){
    const yeildArrayControls = this.spaPackageYield.controls.yeildDataArray as UntypedFormArray;
    const yeild = yeildArrayControls.at(index) as UntypedFormGroup;
    const val = !yeild.controls[formcontrol].value;
    let daysCount = 0;
     yeild.controls[formcontrol].setValue(val);
    Object.keys(yeild.controls).forEach((key) => {
      if (key != "endDate" && key != 'startDate' && key != 'isAllDaysAvailable' && key != 'checked' ) {
        if (yeild.get(key).value === true) {
          daysCount++;
        }
      }
    });
    if (daysCount == 7) {
      yeild.patchValue({
        isAllDaysAvailable: true
      }, { emitEvent: false });
    } else {
      yeild.patchValue({
        isAllDaysAvailable: false
      }, { emitEvent: false });
    }
  }
  
  checkValid(index,event,formcontrol){
    const val = this.localization.currencyToSQLFormat(event.target.value);
    const yeildArrayControls = this.spaPackageYield.controls.yeildDataArray as UntypedFormArray;
    const yeild = yeildArrayControls.at(index) as UntypedFormGroup;
    if(val < this.minimumYeildingAmount){
      if(val!=0)
      yeild.controls[formcontrol].setErrors({required :true});
    }
  }

  selectRow(item,event){
    item.value.checked = event.checked;
   let arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    if(event.checked){
      const selectedItem = cloneDeep(item);
      this.checkedYeildItems.push(selectedItem);
    }
    else{
      let checkedArray = arr.controls.filter(x=> x.value.checked == true)
      this.checkedYeildItems = checkedArray
    }
  }

  checkAll(event){
    this.spaPackageYield.get('yeildDataArray')['controls'].forEach(control => {
        control.controls.checked.patchValue(event.checked);
    });
    if(event.checked){
      this.checkedYeildItems = this.spaPackageYield.get('yeildDataArray')['controls'];
    }
    else{
      this.checkedYeildItems = [];
    }
  }

  deleteYeild(){
    let indexToRemove: number[] = [];
    this.utils.showAlert(this.captions.lbl_deleteMsg, AlertType.Warning, ButtonType.YesNo,
      async (res) => {
        if (res == AlertAction.YES) {
          const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
          arr.controls.forEach((control,index) => {
           if(control['controls'].checked.value){
            indexToRemove.push(index);
           }
        });
        indexToRemove.reverse().forEach((index) => {
          arr.removeAt(index);
        });
        this.checkedYeildItems = [];
        }
    });
  }
  clearYeild(){
    this.utils.showAlert(this.captions.lbl_clearMsg, AlertType.Warning, ButtonType.YesNo,
      async (res) => {
        if (res == AlertAction.YES) {
          const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
          let checkedArray = arr.controls.filter(x=> x.value.checked == true)
          checkedArray.forEach(data=>{
            let index = arr.controls.findIndex(x=> x.value.checked == true);
            arr['controls'][index]['controls'].allDays.setValue('0.00');
            arr['controls'][index]['controls'].mon.setValue('0.00');
            arr['controls'][index]['controls'].tue.setValue('0.00');
            arr['controls'][index]['controls'].wed.setValue('0.00');
            arr['controls'][index]['controls'].thu.setValue('0.00');
            arr['controls'][index]['controls'].fri.setValue('0.00');
            arr['controls'][index]['controls'].sat.setValue('0.00');
            arr['controls'][index]['controls'].sun.setValue('0.00');
            arr['controls'][index]['controls'].checked.setValue(false);
          })
          this.checkedYeildItems = [];
        }
      });
  }
  addYeild(){
    const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    arr.push(this.createYeildingFormArray());
  }
  copyYeild(){
    const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    let data = this.createYeildingFormArray();
    let index = arr.controls.findIndex(x=> x.value.checked == true);
    data.patchValue(arr['controls'][index].value);
    data.controls.startDate.setValue('');
    data.controls.endDate.setValue('');
    data.controls.checked.setValue(false);
    arr.push(cloneDeep(data));
    arr['controls'][index]['controls'].checked.setValue(false);
    this.checkedYeildItems = [];
  }

  toggleAccordian() {
    this.isExpanded = !this.isExpanded;
  }
  GetLocalized(data) {
    return this.localization.localizeCurrency(data,false);
  }
  GetLocalizedSql(data) {
    return this.localization.currencyToSQLFormat(data);
  }
  searchValueChange(searchTerm: string) {
    let searchText = searchTerm ? searchTerm.trim().toLowerCase():''
    if(searchText && searchText.length > 0)
    {
     this.tableoptions[0].TablebodyData = this.unfilteredTableData.filter(x=>x.code.toLowerCase().includes(searchText)|| x.description.toLowerCase().includes(searchText))
    }else{
      this.tableoptions[0].TablebodyData = this.unfilteredTableData;
    }
    this.tableoptions = [...this.tableoptions]
  }

  enableSave(){
    const arr = this.spaPackageYield.get('yeildDataArray') as UntypedFormArray;
    return arr.length==0
  }

  selectCompChangeToggle(event){
    if (this.data.popupConfig.operation === 'edit') {
      return;
    } else {
      this.spaPackageService.controls.isComponentSelected.setValue(event[0]);
      this.IsComponentSelectable = event[0];
      this.Component = event[0];
      if (this.IsComponentSelectable) {
        this.autoPickArr[0] = this.captions.never;
      }
    if(event[0]){
      for (let i = 0; i < this.spaServices.length; i++) {
        if(!this.spaServices[i].controls.fixedVariable.value){
          this.spaServices[i].controls.quantity.setValue(1);
          this.changePercent(this.spaServices[i],'servicePrice');
        }
      }
      for (let i = 0; i < this.spaRetailItems.length; i++) {
        if(!this.spaRetailItems[i].controls.fixedVariable.value){
          this.spaRetailItems[i].controls.quantity.setValue(1);
          this.changePercent(this.spaRetailItems[i],'retailPrice');
        }
      }
      this.calculateTotalPrice();
    }
    else{
      this.calculateTotalPrice();
      this.spaPackageService.controls.serviceVariable.setErrors(null);
      this.spaPackageService.controls.retailVariable.setErrors(null);
      this.enableNext = this.spaPackageService.valid;
    }
  }
  }
  topBottomChange(e){
    this.calculateTotalPrice();
  }
  
  checkRetailVariable(){
    if((this.spaPackageService.controls.retailVariable.value > this.variableRetail) || (this.variableRetail > 0 && this.spaPackageService.controls.retailVariable.value == 0)){
      this.minimumVariableRetailCount = 1;
      this.spaPackageService.controls.retailVariable.setErrors({incorrect: true});
      this.spaPackageService.controls.retailVariable.markAsDirty();
      this.spaPackageService.controls.retailVariable.markAsTouched();
      this.spaPackageService.updateValueAndValidity();
    }
    else
    {
      this.spaPackageService.controls.retailVariable.setErrors(null);
    }
    if(this.variableRetail == 0){
      this.minimumVariableRetailCount = 0;
    }
    this.enableNext = this.spaPackageService.valid;
  }

  checkServiceVariable(){
    if((this.spaPackageService.controls.serviceVariable.value > this.variableService) || (this.variableService > 0 && this.spaPackageService.controls.serviceVariable.value == 0)){
      this.minimumVariableServiceCount = 1;
      this.spaPackageService.controls.serviceVariable.setErrors({incorrect: true});
      this.spaPackageService.controls.serviceVariable.markAsTouched();
      this.spaPackageService.controls.serviceVariable.markAsDirty();
      console.log(this.spaPackageService)
    }
     else
    {
      this.spaPackageService.controls.serviceVariable.setErrors(null);
    }
    if(this.variableService == 0){
      this.minimumVariableServiceCount = 0;
    }
    this.enableNext = this.spaPackageService.valid;
  }

  changeRetailVariableCount(event){
    if(this.spaPackageService.controls.retailVariable.value==null)
    {
      this.spaPackageService.controls.retailVariable.setValue(0);
    }
    this.calculateTotalPrice();
  }
  changeServiceVariableCount(event){
    if(this.spaPackageService.controls.serviceVariable.value==null){
      this.spaPackageService.controls.serviceVariable.setValue(0);
    }
    this.calculateTotalPrice();
  }
  fVChange(e,item,isService){
    if(!e.value){
      item.controls.quantity.setValue(1);
      if(isService)
      {
      this.changePercent(item,'servicePrice');
      }
      else
      {
      this.changePercent(item,'retailPrice');
      }
   }
   for (let checkedItemsItem of this.checkedItems) {
    const checkedRetailItemId=checkedItemsItem.retailItemId?checkedItemsItem.retailItemId:checkedItemsItem.id;
    if (item.value.id === checkedItemsItem.id ||item.value.id === checkedRetailItemId) {
      checkedItemsItem.incrementDecrement=item.value.incrementDecrement;
      checkedItemsItem.percent=this.localization.localizeCurrency(item.value.percent,false)
      checkedItemsItem.amount=this.localization.localizeCurrency(item.value.amount,false)
      checkedItemsItem.allowPriceChange=item.value.allowPriceChange
      checkedItemsItem.quantity=item.value.quantity
      checkedItemsItem.fixedVariable = item.value.fixedVariable
    }
  }
  this.createYeilding();
  this.calculateTotalPrice();
  }
  checkYeildFormValid(){
    let isvalid = false;
    let controls = this.spaPackageYield.controls.yeildDataArray['controls']
    if(this.spaPackageYield.controls.yeildDataArray.invalid){
     controls?.forEach(yeild => {
      Object.keys(yeild.controls).forEach((key) => {
        if (key == 'mon' || key == 'tue' || key == 'wed' || key == 'thu' || key == 'fri' || key == 'sat' || key == 'sun' ) {
          if (yeild.get(key).invalid) {
            isvalid = true;
          }
        }
      });
     });
    }
    return isvalid
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { I } from '@angular/cdk/keycodes';
@Pipe({
  name: 'yeildingPrice'
})
export class YeildingPrice implements PipeTransform {
  constructor(public localization: SpaLocalization,){}
  transform(value: any,serviceAmount,noPriceChange,minimumYeildingAmount,serviceArray,allowPriceChange,index,formcontrol,form,yeildRowIndex,totalPackageAmount,serviceComponentArray,isComponentEnabled): any {
    serviceArray=isComponentEnabled?serviceComponentArray:serviceArray;
    const delocalizedServiceAmount = this.localization.currencyToSQLFormat(serviceAmount);
    const delocalizedValue = this.localization.currencyToSQLFormat(value);
    let yeildServicesArray = form.get('yeildDataArray').controls[index].get('yeildServicesArray') as UntypedFormArray;
    const yeildArrayControls = form.controls.yeildDataArray as UntypedFormArray;
    const yeild = yeildArrayControls.at(index) as UntypedFormGroup;
    var needIndex=-1;
    if(!noPriceChange){
      yeild.controls[formcontrol].setErrors(null);
      yeildServicesArray.at(yeildRowIndex).get(formcontrol).setValue(0);
      return this.localization.localizeCurrency('0',false);
        
    }
     else if(delocalizedValue < minimumYeildingAmount){
        yeild.controls[formcontrol].markAsTouched();
        if(delocalizedValue!=0){
          yeild.controls[formcontrol].setErrors({required :true});
        }
        yeildServicesArray.at(yeildRowIndex).get(formcontrol).setValue(0);
        return this.localization.localizeCurrency('0',false);
      }
      else{
        yeild.controls[formcontrol].setErrors(null);
          if(allowPriceChange){
            let diff = delocalizedValue-totalPackageAmount;
            let amount = 0;
            let allowPriceChangeArr = serviceArray.filter(x=> x.allowPriceChange==true);
            for (let i = 0; i < allowPriceChangeArr.length; i++) {
               amount = amount + this.localization.currencyToSQLFormat(allowPriceChangeArr[i].amount);
            }
            for(let i=0;i<serviceArray.length;i++)
              {
                if(serviceArray[i].allowPriceChange)
                {
                  needIndex=i;
                }
              }
           let total = delocalizedServiceAmount + ((delocalizedServiceAmount/amount)* diff);
           let totalResult=this.localization.localizeCurrency(total,false);
           yeildServicesArray.at(yeildRowIndex).get(formcontrol).setValue(this.localization.currencyToSQLFormat(totalResult));
           if(yeildRowIndex==needIndex)
            {
              let TotalYieldingAmount=0;
              let difference=0;
              for(let i=0;i<serviceArray.length;i++)
              {
                if(serviceArray[i].allowPriceChange)
                {
                var localizeAmount=this.localization.localizeCurrency(yeildServicesArray.at(i).get(formcontrol).value,false)
                TotalYieldingAmount=TotalYieldingAmount+this.localization.currencyToSQLFormat(localizeAmount);
                }
                else
                {
                  TotalYieldingAmount=TotalYieldingAmount+this.localization.currencyToSQLFormat(serviceArray[i].amount);
                }
              }
              //var localizeYieldingAmount=this.localization.localizeCurrencyNumber(TotalYieldingAmount,false)
              difference=this.localization.currencyToSQLFormat(value)-TotalYieldingAmount;
              if(difference!=0)
              {
                let servicePrice=this.localization.localizeCurrency(yeildServicesArray.at(needIndex).get(formcontrol).value,false);
                let price=this.localization.currencyToSQLFormat(servicePrice)+difference;
                let result=this.localization.localizeCurrency(price,false);
                yeildServicesArray.at(needIndex).get(formcontrol).setValue(this.localization.currencyToSQLFormat(result));
                return result;
              }
           
            }
           return totalResult;
          }
          else{
            let result =this.localization.localizeCurrency(delocalizedServiceAmount,false)
            yeildServicesArray.at(yeildRowIndex).get(formcontrol).setValue(this.localization.currencyToSQLFormat(result));
            return result;
          }
        }
  }
}