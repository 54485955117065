import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IcartDataService } from 'src/app/common/dataservices/icart.data.service';
import { CartDetail, CartDetailUI, CartFolioAuthorization, CartFolioAuthorizationsUI, CartItinerary, CartItineraryType, CartItineraryUI, CartPayload, CartStatus, CartWindowMessage, CartWindowMessageType, CartItineraryMenuAction, TransferNotification, AuthorizeCompleteNotification, CartErrorCodes, CartDetailStatus } from './vcart.modal';
import { cloneDeep, isEqual, omit, uniqWith } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CartTransferComponent } from '../cart-transfer/cart-transfer.component';
import { SetCartTimerComponent } from '../set-cart-timer/set-cart-timer.component';
import { from, Observable, of, ReplaySubject, timer } from 'rxjs';
import { scan, takeUntil, takeWhile } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { VCartBusiness } from './vcart.business';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/Models/common.models';
import { CartAuthorizeComponent } from './cart-authorize/cart-authorize.component';
import { CartUtilities } from './cart.utilities';
import { Pipe, PipeTransform } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ButtonType, Product } from 'src/app/common/enums/shared-enums';
import { CartCommentsComponent } from '../cart-comments/cart-comments.component';
import { SignalrService } from 'src/app/common/communication/signalR/signalr.service';
import { NotificationModel, SignalRMessage, SignalRNotificationType } from 'src/app/common/communication/signalR/signalR.model';
import { SnackBarType } from 'src/app/common/shared/shared/enums/enums';
import { HubConnectionState } from '@microsoft/signalr';
import { CartGuestSearchComponent } from 'src/app/common/components/menu/cart-guest-search/cart-guest-search.component';
import { CommonGatewayCommunication } from 'src/app/common/communication/services/commongateway-communication.service';
import { CardTypeComponent } from '../card-type/card-type.component';
import { VCartConfiguration } from 'src/app/common/Models/property-information.model';
import { ViewAllItinerariesPopupComponent } from './view-all-itineraries-popup/view-all-itineraries-popup.component';
@Component({
  selector: 'app-vcart',
  templateUrl: './vcart.component.html',
  styleUrls: ['./vcart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VcartComponent implements OnInit {
  itineraryList: CartItineraryUI[] = [];
  moreOptions: { label: string; id: number; action: number }[];
  bookButton: { type: string; label: any; disabledproperty: boolean; };
  captions: any;
  searchText: any;
  userId: number;
  showSpinner: boolean = false;
  confrmId: string = '';
  hasAccess = false;
  subject = new ReplaySubject<number>(2);
  cartwarningList: string[];
  itineraryListUnfilteredData: CartItineraryUI[];
  lastActiveCartItineraryId: number;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() CartCountModified = new EventEmitter();
  activeConfirmationId: string = '';
  activeItineraryId = 0;
  usersList: { userId: number; firstname: string; lastname: string; userName: string; }[];
  dragObject: { cartDescription: string, sourceCartItinerary: CartItineraryUI, cartDetailToBeMoved: CartDetailUI };
  primaryguestName: string;
  dataLoadError: boolean = false;
  vCartTenantconfiguration: VCartConfiguration<any>;
  constructor(private dialog: MatDialog, private localization: Localization,
    private IcartDataService: IcartDataService, private utils: CommonUtilities,
    private cartBusiness: VCartBusiness, private cartUtilities: CartUtilities,
    private signalR: SignalrService, private commonGatewayCommunication: CommonGatewayCommunication
  ) {
    this.captions = this.localization.captions;
    this.userId = Number(this.utils.GetUserInfo('userId'));
  }
  ngAfterViewInit() {
    // setInterval(() => {
    //   this.expirysecondsCalculator()
    // }, 1000);
    this.webWorker()

  }

  webWorker() {
    var blob = new Blob([document.querySelector('#worker-code').textContent]);
    var worker = new Worker(window.URL.createObjectURL(blob));
    worker.onmessage = () => {
      this.expirysecondsCalculator()
    }
  }


  expirysecondsCalculator() {
    this.itineraryList?.forEach(element => {
      if (element.expirySeconds > 0) {
        element.expirySeconds = --element.expirySeconds
      }

      if (element.expirySeconds == 0) {
        this.remove(element.id)
      }
    });
  }

  async ngOnInit() {
    this.bookButton = {
      type: 'primary',
      label: this.captions.btn_book,
      disabledproperty: true
    }
    this.cartUtilities.getUserAccessForVCartViewAllItineraries().then(o => {
      if (o) {
        this.hasAccess = true;
      } else
        this.hasAccess = false;
    });

    this.cartwarningList = [
    ];

    this.IcartDataService.CartItemUpdated.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      if (x) {
        const itinerary = this.itineraryList.find(o => o.id == x.cartItineraryId);
        if (itinerary) {
          let detail = itinerary.cartDetails?.find(o => o.id == x.cartDetailId);
          if (detail) {
            let cartDetailAPI = this.mapCartDetailUItoAPI(detail);
            let clonedCartDetailAPI = cloneDeep(cartDetailAPI);
            clonedCartDetailAPI.cartPayload = JSON.stringify(x.cartPayload);
            let cartItemUpdated: boolean = false;
            this.cartBusiness.updateCartDetail(clonedCartDetailAPI)
              .then(result => {
                cartItemUpdated = true;
                detail.cartPayload = x.cartPayload;
                this.refreshGuestNames();
                this.postCartDataBroadcastMessage();
              })
              .catch(o => {
                this.cartUtilities.showErrorAlert(this.captions.lbl_errorUnabletoUpdate);
              }).finally(() => {
                this.postCartResultMessage({ cartDetail: cartDetailAPI, cartItinerary: itinerary, result: true, cartEvent: CartWindowMessageType.UpdateCart });
              });
          } else {
            // Edited item does not existing in the Cart. Could be expired/removed
            this.postCartResultMessage({ cartDetail: null, cartItinerary: itinerary, result: false, cartEvent: CartWindowMessageType.UpdateCart });
          }
        }
        else {
          // throw Cart Itinerary not found. Could be expired/removed
          this.postCartResultMessage({ cartDetail: null, cartItinerary: null, result: false, cartEvent: CartWindowMessageType.UpdateCart });
        }
      }
    });

    this.IcartDataService.CartItemAdded.pipe(takeUntil(this.destroyed$)).subscribe(async cartPayload => {
      // Find the selected / newly created Cart Itinerary
      this.cartUtilities.ToggleLoader(true, this.captions.msg_CartItineraryItemAdded);
      try {
        var selectedItinerary = this.getActiveItinerary();
        var cartDetail: CartDetail = {
          id: 0,
          cartPayload: JSON.stringify(cartPayload),
          createdBy: 0,
          createdDTM: this.localization.getUTCDateTimeNow().toDateString(),
          modifiedBy: 0,
          modifiedDTM: this.localization.getUTCDateTimeNow().toDateString(),
          primaryGuestId: (cartPayload.guestInfos && cartPayload.guestInfos.length) ? cartPayload.guestInfos[0].guestId : '00000000-0000-0000-0000-000000000000',
          productId: cartPayload.productId,
          statusId: CartStatus.Created,
          version: '00000000-0000-0000-0000-000000000000',
          cartItineraryId: selectedItinerary ? selectedItinerary.id : 0,
          propertyId: cartPayload.propertyId
        };
        let cartItemAdded: boolean = false;
        try {
          var result = await this.cartBusiness.AddCartDetail(cartDetail);
          if (result) {
            cartItemAdded = true;
          }
        }
        finally {
          this.postCartResultMessage({ cartDetail: cartDetail, cartItinerary: result, result: true, cartEvent: CartWindowMessageType.AddToCart });
        }
        this.postCartDataBroadcastMessage();
        await this.loadCartItinerariesForTheUser();
        if (cartDetail.cartItineraryId = 0 && this.itineraryList && this.itineraryList.length) {
          this.itineraryList[0].iscartExpand = true;
        }
        this.validateCart();
      }
      catch {
        this.cartUtilities.showErrorAlert(this.captions.lbl_errorUnabletoAddCartItem);
      } finally {
        this.cartUtilities.ToggleLoader(false, this.captions.msg_CartItineraryItemAdded);
      }
    });

    this.IcartDataService.CartItinerarySelected.pipe(takeUntil(this.destroyed$)).subscribe(s => {
      if (s) {
        this.activeConfirmationId = s.confirmationId;
      }
    });


    this.IcartDataService.NewGuestCreated.pipe(takeUntil(this.destroyed$)).subscribe(data => {
      if (data) {
        this.newItineraryClicked(data);
      }
    });

    this.addSignalREventListeners();


    this.cartUtilities.getVCartConfigurations().then(async res => {
      if (res) {
        this.vCartTenantconfiguration = res;
      }
      await this.loadCartItinerariesForTheUser();
    });
  }

  refreshGuestNames() {
    this.itineraryList.forEach(itinerary => {
      if (itinerary && itinerary.cartDetails && itinerary.cartDetails.length) {
        itinerary.cartDetails.forEach(cartDetail => {
          cartDetail.allGuestsNames = cartDetail.cartPayload ? this.getGuestNames(cartDetail.cartPayload) : '';
        });
      }
    });
  }
  /**
       * @function MaskCreditCardNumber
       * @returns string
       * @param cardNo string
       * @description Formats the card number for display purpose
       */
   MaskCreditCardNumber(cardNo: string): string {
    cardNo = 'XXXX-XXXX-XXXX-' + cardNo.substring(cardNo.length - 4, cardNo.length);
    return cardNo;
  }
  opencardType(obj: CartItineraryUI) {
    this.dialog.open(CardTypeComponent, {
      height: '250px',
      width: '500px',
      data: {
        obj: obj
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async data => {
      this.cartUtilities.hideExpandVcartoverlay();
      if (data != null) {
        try {
          this.cartUtilities.ToggleLoader(true);
          let res = await this.cartBusiness.SetActiveCardOnFile(data.cartItineraryId, data.id);
          if (res) {
            for (let i = 0; i < obj.cartFolioAuthorizations.length; i++) {
              let auth = obj.cartFolioAuthorizations[i];
              if (auth.id == data.id) {
                auth.isActive = true;
              } else
                auth.isActive = false;
            }
          }
          // Set Active Card
          const activeCard = this.mapCartFolioAuthorizationsUIToAPI(obj.cartFolioAuthorizations.find(o => o.isActive))
          obj.activeCardOnFile = this.getActiveCardOnFile([activeCard]);
        } catch (e) {
          this.cartUtilities.showErrorAlert(this.captions.err_UnableToSetActiveCardOnFile);
        }
        finally {
          this.cartUtilities.ToggleLoader(false);
        }
      }
    });
  }

  private getActiveItinerary() {
    return this.itineraryList.find(o => o.expanded);
  }

  async loadCartItinerariesForTheUser() {
    this.cartUtilities.ToggleLoader(true, this.captions.msg_loadCartItinerariesForTheUser);
    try {
      const data = await this.cartBusiness.GetItineraries(this.userId);
      this.dataLoadError = false;
      if (data && data.length > 0) {
        this.itineraryList = data.map(o => {
          return this.mapItineraryAPItoUI(o);
        });
        this.setLastActivatedCartItinerary(this.itineraryList);
        this.itineraryListUnfilteredData = this.itineraryList ? cloneDeep(this.itineraryList) : [];

      } else {
        this.itineraryList = [];
        this.itineraryListUnfilteredData = [];
      }
      this.validateCart();
    } catch (ex) {
      this.dataLoadError = true;
      console.error(ex.stack);
    } finally {
      this.cartUtilities.ToggleLoader(false, this.captions.msg_loadCartItinerariesForTheUser);
    }
  }

  setLastActivatedCartItinerary(itineraryList: CartItineraryUI[]) {
    if (itineraryList && itineraryList.length && this.lastActiveCartItineraryId > 0) {
      let lastActiveItinerary = itineraryList.find(o => o.id == this.lastActiveCartItineraryId)
      if (lastActiveItinerary) {
        itineraryList.forEach(element => {
          element.expanded = false;
        });
        lastActiveItinerary.expanded = true;
      }
    }
  }
  async newItineraryClicked(data: { guestId: String, packageCode: String, arrivalDate: String, nights: String } = null) {
    this.dialog.open(CartGuestSearchComponent, {
      height: '500px',
      width: '50%',
      maxWidth: '70%',
      data: {
        title: this.captions.lbl_CreateCartItinerary,
        config: this.vCartTenantconfiguration,
        inputs: data
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async data => {
      this.cartUtilities.hideExpandVcartoverlay();
      if (data) {
        this.cartUtilities.ToggleLoader(true);
        try {
          await this.createEmptyItinerary(data);
          this.postCartSelectedMessage();
        } catch {
          this.cartUtilities.showErrorAlert(this.captions.err_unabletoCreateItinerary);
        }
        finally {
          this.cartUtilities.ToggleLoader(false);
        }
      }
    });
  }

  async createEmptyItinerary(data) {
    const guest = data.guest;
    let memberIds = guest.membershipDetails.map(o => o.memberId);
    let loyaltyIds = guest.loyaltyDetails.filter(o => o.playerId != null).map(o => o.playerId);
    var itinerary = await this.cartBusiness.createEmptyItinerary(
      {
        guestId: guest.id,
        firstName: guest.firstName,
        lastName: guest.lastName,
        platformGuestUuid: guest.platformGuestUuid,
        memberIDs: memberIds,
        loyaltyIDs: loyaltyIds ? loyaltyIds : [],
        travelStartDate: data.startDate,
        numberOfNights: data.nights,
        requestedPackageCode: data.packageCode
      });
    this.collapseAllCart();
    var newItinerary = this.mapItineraryAPItoUI(itinerary);
    newItinerary.expanded = true;
    this.lastActiveCartItineraryId = newItinerary.id;
    newItinerary.iscartExpand = true;
    this.itineraryList?.push(newItinerary);
    this.validateCart();
  }

  validateCart() {
    this.assignCartItineraryName();
    this.checkIfOneCartIsActive();
    this.postCartDataBroadcastMessage();
    var activeItinerary = this.getActiveItinerary() ? this.getActiveItinerary() : null;
    this.activeConfirmationId = activeItinerary ? activeItinerary.confirmationId : '';
    this.primaryguestName = activeItinerary ? activeItinerary.name : ''
    this.setCartOptions();
    this.emitCartCount();
  }

  setCartOptions() {
    if (this.itineraryList && this.itineraryList.length > 0)
      this.itineraryList.forEach(cartItinerary => {
        if (cartItinerary.cartDetails && cartItinerary.cartDetails.length > 0) {
          cartItinerary.menuOptions = this.getCartOptions(cartItinerary);
        }
      });
  }

  postCartEditedMessage(cartItinerary: CartItineraryUI, cartDetail: CartDetailUI) {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.CartItemEdited, { cartItinerary, cartDetail });
  }

  postCartRemovedMessage(cartItinerary: CartItineraryUI, cartDetail: CartDetailUI) {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.CartItemRemoved, { cartItinerary, cartDetail });
  }

  postCartResultMessage(data: any) {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.CartResult, data);
  }

  postCartSelectedMessage() {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.CartItinerarySelected, this.getActiveItinerary());
  }

  postCartDataBroadcastMessage() {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.CartDataBroadcast, this.itineraryList);
  }

  postDataReloadMessage() {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.DataReloadNeeded);
  }

  postItineraryTransferredMessage(cartItinerary: CartItineraryUI) {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.CartItineraryTransfered, cartItinerary);
  }

  postCartItineraryRemovedMessage(cartItinerary: CartItineraryUI) {
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.CartItineraryRemoved, cartItinerary);
  }

  emitCartCount() {
    let count = this.itineraryList.length;
    this.CartCountModified.emit(
      {
        count,
        activeConfirmationId: this.activeConfirmationId,
        primaryguestName: this.primaryguestName

      }
    );
  }

  checkIfOneCartIsActive() {
    if (this.itineraryList && this.itineraryList.length) {
      var activeCart = this.getActiveItinerary();
      if (!activeCart) {
        this.itineraryList[0].expanded = true;
        this.lastActiveCartItineraryId = this.itineraryList[0].id;
      }
    }
  }

  async book(obj) {
    await this.completeBooking(obj);
  }

  async completeBooking(obj) {
    try {
      var selectedCart = this.getActiveItinerary();
      this.cartBusiness.getCartItineraryById(selectedCart.id)
        .then(itenarary => {
          let cartDepositAmount = 0;
          itenarary.cartDetails.forEach(o => {
            if (o.statusId == CartDetailStatus.Active) {
              let payload: CartPayload = JSON.parse(o.cartPayload)
              cartDepositAmount += payload.depositAmount;
            }
          });

          if (itenarary.cartFolioAuthorizations == null || itenarary.cartFolioAuthorizations.length == 0) {
            if (cartDepositAmount > 0) {
              if (itenarary.cartAuthorizeLink == null || itenarary.cartAuthorizeLink.trim() == "")
                this.utils.showAlert(this.captions.msg_PaymentLinkNotSentYet, AlertType.Info);
              else
                this.utils.showAlert(this.captions.msg_CardInfoNotYetReceived, AlertType.Info);

              return;
            }
          }
          if (itenarary) {
            if (selectedCart && selectedCart.cartDetails.length) {
              this.cartUtilities.ToggleLoader(true, this.captions.msg_completeBooking);
              this.cartBusiness.completeBooking(selectedCart.id)
                .then(async res => {

                  if (res && res.length) {
                    // Historical Item Detected
                    const historicalDateCartItem = res.find(o => o.errorCode == 200);
                    // Is the Cart Itinerary Expired
                    const ExpiredCartItinerary = res.find(o => o.errorCode == 300);
                    if (historicalDateCartItem) {
                      let errorCartItem = selectedCart.cartDetails.find(o => o.id == historicalDateCartItem.cartDetailId);
                      const errorMsg = this.localization.replacePlaceholders(this.captions.msg_historicalCartDetailCantBeBookedAlert, ['cartItem'], [errorCartItem.cartPayload.description]);
                      this.utils.showCommonAlert(errorMsg, AlertType.Info);
                      return;
                    }
                    if (ExpiredCartItinerary) {
                      const errorMsg = this.localization.replacePlaceholders(this.captions.msg_ExpiredCartItineraryCantBeBookedAlert, ['cartItineraryConfirmationId'], [itenarary.confirmationId]);
                      this.utils.showCommonAlert(errorMsg, AlertType.Error);
                      return;
                    }
                    if (res.filter(o => !o.isSuccess).length == 0) {
                      this.postDataReloadMessage();
                      this.utils.showCommonAlert(this.captions.msg_allCartItemsAreReserved, AlertType.Success);
                      this.itineraryList = this.itineraryList.filter(o => o.id != selectedCart.id);
                    }
                    else if (res.filter(o => !o.isSuccess).length == res.length) {
                      // all items in the cart failed
                      this.utils.showCommonAlert(this.captions.msg_allCartItemsAreFailed, AlertType.Error);

                    } else {
                      // partial failure
                      this.postDataReloadMessage();
                      this.utils.showCommonAlert(this.captions.msg_partialCartItemsFailed, AlertType.Error);
                    }
                  }
                  this.validateCart();
                  await this.loadCartItinerariesForTheUser();
                })
                .catch(_ => {
                  // TODO: Handle Partial Booking success, show respective error message
                  this.cartUtilities.showErrorAlert();
                })
                .finally(() => {
                  this.cartUtilities.ToggleLoader(false, this.captions.msg_completeBooking);
                });
            }
          }
        });
    }
    catch (ex) {
      console.log(ex, ex.stack);
      this.cartUtilities.showErrorAlert(this.captions.msg_bookingFailed);
    }
    finally {
      this.cartUtilities.ToggleLoader(false);
    }
  }
  assignCartItineraryName() {
    if (this.itineraryList && this.itineraryList.length > 0) {
      this.itineraryList.forEach(itinerary => {
        // if (itinerary.cartDetails.length == 0) {
        //   itinerary.name = '';
        // } else {
        //   let cartPayload: CartPayload = itinerary.cartDetails[0].cartPayload;
        //   if (cartPayload && cartPayload.guestInfos && cartPayload.guestInfos.length) {
        //     itinerary.name = this.formatName(cartPayload.guestInfos[0].firstName, cartPayload.guestInfos[0].lastName);
        //   }
        // }
        itinerary.name = this.cartUtilities.formatName(itinerary.primaryGuestFirstName, itinerary.primaryGuestLastName);
      });
    }
  }

  collapseAllCart() {
    this.itineraryList?.forEach(i => {
      i.expanded = false;
    });
  }

  getCartOptions(cartItinerary: CartItineraryUI | CartItinerary): { id: number; value: string; disabled: boolean }[] {
    const cartDetailsCount = cartItinerary.cartDetails.length ?? 0;
    var options = [{
      id: 1,
      value: this.captions.lbl_Reomve,
      disabled: false
    },
    {
      id: 2,
      value: this.captions.lbl_Transfer,
      disabled: cartDetailsCount ? false : true
    },
    {
      id: 4,
      value: this.captions.lbl_SharewithGuest,
      disabled: true
    },
    {
      id: 5,
      value: cartItinerary.cartComments && cartItinerary.cartComments.trim().length > 0 ? this.captions.lbl_EditComments : this.captions.lbl_AddComments,
      disabled: false
    },
    {
      id: 6,
      value: this.captions.lbl_payment_credit_card,
      disabled: false
    }
    ];
    if (this.vCartTenantconfiguration && this.vCartTenantconfiguration.configValue && this.vCartTenantconfiguration.configValue.AuthorizeConfigured
      && this.vCartTenantconfiguration.configValue.AuthorizeConfigured.toString().trim() == "true") {
      options.push(
        {
          id: 3,
          value: cartItinerary.cartNotifications && cartItinerary.cartNotifications.find(o => o.isSuccess) ? this.captions.lbl_ResendPaymentLink : this.captions.lbl_SendPaymentLink,
          disabled: cartDetailsCount > 0 ? false : true
        }
      );
    }
    return options;
  }

  mapCartDetailUItoAPI(uiObject: CartDetailUI): CartDetail {
    let apiObject: CartDetail = {
      version: uiObject.version,
      cartPayload: JSON.stringify(uiObject.cartPayload),
      primaryGuestId: uiObject.primaryGuestId,
      modifiedBy: uiObject.modifiedBy,
      modifiedDTM: uiObject.modifiedDTM,
      createdDTM: uiObject.createdDTM,
      createdBy: uiObject.createdBy,
      cartItineraryId: uiObject.cartItineraryId,
      id: uiObject.id,
      statusId: uiObject.statusId,
      productId: uiObject.productId,
      propertyId: uiObject.propertyId
    };
    return apiObject;
  }
  async refreshCart() {
    await this.loadCartItinerariesForTheUser();
  }

  mapCartDetailAPItoUI(aPIObject: CartDetail): CartDetailUI {
    let uiObject: CartDetailUI = {
      version: aPIObject.version,
      cartPayload: JSON.parse(aPIObject.cartPayload),
      primaryGuestId: aPIObject.primaryGuestId,
      modifiedBy: aPIObject.modifiedBy,
      modifiedDTM: aPIObject.modifiedDTM,
      createdDTM: aPIObject.createdDTM,
      createdBy: aPIObject.createdBy,
      cartItineraryId: aPIObject.cartItineraryId,
      id: aPIObject.id,
      statusId: aPIObject.statusId,
      productId: aPIObject.productId,
      propertyId: aPIObject.propertyId,
      allGuestsNames: aPIObject.cartPayload ? this.getGuestNames(JSON.parse(aPIObject.cartPayload)) : ''
    };
    return uiObject;
  }
  getGuestNames(cartPayload: CartPayload): string {
    if (cartPayload && cartPayload.guestInfos && cartPayload.guestInfos.length > 0) {
      let guestFNameLNames = '';
      let guestInfoDetails = cartPayload.guestInfos;
      if (cartPayload.guestInfos.length > 1) {
        for (var idx = 0; idx < guestInfoDetails.length; idx++) {
          guestFNameLNames += guestInfoDetails[idx].firstName + ',' + guestInfoDetails[idx].lastName + ' ';
        }
        if (guestFNameLNames.length > 0) {
          return guestFNameLNames;
        }
      }
      else if (cartPayload.guestInfos.length == 1)
        return cartPayload.guestInfos[0].firstName + ' ' + cartPayload.guestInfos[0].lastName;
    }
    return '';
  }
  getExiprySeconds(lastoldTime: Date, holdSeconds: number) {
    const secondsPassed = (lastoldTime.getSeconds() - this.localization.getUTCDateTimeNow().getSeconds());
    let secondsLeft = secondsPassed - holdSeconds;
    return secondsLeft;
  }
  localizeDateTime(date: string) {
    if (date) {
      var value = this.localization.getDate(date);
      return value ? this.localization.LocalizeDate(value) + " - " + this.localization.LocalizeTime(value) : "";
    }
  }
  localizeDate(date: string) {
    return this.localization.LocalizeDate(this.localization.getDate(date));
  }


  mapItineraryAPItoUI(aPIObject: CartItinerary): CartItineraryUI {
    const cartDetailCount = aPIObject.cartDetails ? aPIObject.cartDetails.length : 0;
    let name = this.cartUtilities.formatName(aPIObject.primaryGuestFirstName, aPIObject.primaryGuestLastName);
    // if (cartDetailCount > 0) {
    //   let cartPayload: CartPayload = JSON.parse(aPIObject.cartDetails[0].cartPayload);
    //   if (cartPayload && cartPayload.guestInfos && cartPayload.guestInfos.length) {
    //     name = this.formatName(cartPayload.guestInfos[0].firstName, cartPayload.guestInfos[0].lastName);
    //   }
    // }
    let memberDetails = '';
    if (aPIObject.isMember) {
      memberDetails = aPIObject.memberType;
      if (aPIObject.memberLevel && aPIObject.memberLevel.trim().length > 0) {
        memberDetails = memberDetails + ' | ' + aPIObject.memberLevel
      }
    }
    let uiObject: CartItineraryUI = {
      isSearched: true,
      isStatusred: false,
      expirySeconds: this.calculateExpirySeconds(aPIObject.lastHoldDateTime, aPIObject.holdSeconds),
      expanded: false,
      iscartExpand: false,
      totalItems: cartDetailCount,
      name: name,
      menuOptions: this.getCartOptions(aPIObject),
      id: aPIObject.id,
      confirmationId: aPIObject.confirmationId,
      holdSeconds: aPIObject.holdSeconds,
      lastHoldDateTime: aPIObject.lastHoldDateTime,
      statusId: aPIObject.statusId,
      userId: aPIObject.userId,
      platformItineraryUuid: aPIObject.platformItineraryUuid,
      cartDetails: aPIObject.cartDetails.map(o => this.mapCartDetailAPItoUI(o)),
      cartComments: aPIObject.cartComments,
      primaryGuestId: aPIObject.primaryGuestId,
      guestPhoneNumber: aPIObject.guestPhoneNumber,
      version: aPIObject.version,
      cartAuthorizeLink: aPIObject.cartAuthorizeLink ?? '',
      createdBy: aPIObject.createdBy,
      transferredUserId: aPIObject.transferredUserId,
      transferredUserName: aPIObject.transferredUserName,
      primaryGuestFirstName: aPIObject.primaryGuestFirstName,
      primaryGuestLastName: aPIObject.primaryGuestLastName,
      primaryGuestUuId: aPIObject.primaryGuestUuId,
      cartFolioAuthorizations: this.mapCartFolioAuthorizationsAPIToUI(aPIObject.cartFolioAuthorizations),
      cartNotifications: aPIObject.cartNotifications.filter(o => o.isSuccess),
      isMember: aPIObject.isMember,
      itineraryPackageCode: aPIObject.itineraryPackageCode,
      memberLevel: aPIObject.memberLevel,
      membershipStatus: aPIObject.membershipStatus,
      memberType: aPIObject.memberType,
      numberOfNights: aPIObject.numberOfNights,
      travelEndDate: aPIObject.travelEndDate,
      travelStartDate: aPIObject.travelStartDate,
      memberDetails: memberDetails,
      memberLevelColorCode: aPIObject.memberLevelColorCode,
      memberTypeColorCode: aPIObject.memberTypeColorCode,
      activeCardOnFile: this.getActiveCardOnFile(aPIObject.cartFolioAuthorizations)
    };
    return uiObject;
  }

  getActiveCardOnFile(cartFolioAuthorizations: CartFolioAuthorization[]): CartFolioAuthorizationsUI {
    let activeCard = null;
    if (cartFolioAuthorizations && cartFolioAuthorizations.length) {
      activeCard = cartFolioAuthorizations.find(o => o.isActive);
      if (!activeCard) {
        activeCard = cartFolioAuthorizations[0];
      }
    }
    return activeCard ? this.mapCartFolioAuthorizationAPIToUI(activeCard) : null;
  }
  mapCartFolioAuthorizationsAPIToUI(cartFolioAuthorizations: CartFolioAuthorization[]): CartFolioAuthorizationsUI[] {
    let cartFolioAuthorizationsUI: CartFolioAuthorizationsUI[] = [];
    if (cartFolioAuthorizations && cartFolioAuthorizations.length > 0) {
      cartFolioAuthorizations.forEach(folio => {
        cartFolioAuthorizationsUI.push(this.mapCartFolioAuthorizationAPIToUI(folio));
      })
    }
    return cartFolioAuthorizationsUI;
  }

  mapCartFolioAuthorizationAPIToUI(cartFolioAuthorization: CartFolioAuthorization): CartFolioAuthorizationsUI {
    let auth = JSON.parse(cartFolioAuthorization?.authorization);
    if (auth) {
      return {
        id: cartFolioAuthorization.id,
        authorizationSeq: cartFolioAuthorization.authorizationSeq,
        cartItineraryId: cartFolioAuthorization.cartItineraryId,
        foloioId: cartFolioAuthorization.foloioId,
        maxAuthorizedAmount: cartFolioAuthorization.maxAuthorizedAmount,
        cardCode: auth.cardCode.toLowerCase(),
        cardExpireDate: auth.cardExpireDate,
        cardMasked: this.MaskCreditCardNumber(auth.cardMasked),
        cardToken: auth.cardToken,
        authorizationType: auth.authorizationType,
        isActive: cartFolioAuthorization.isActive
      }
    }
  }
  mapItineraryUIToAPI(uiObject: CartItineraryUI): CartItinerary {
    const uiObj: CartItineraryUI = cloneDeep(uiObject);
    let cartDetailsAPI: CartDetail[] = [];
    let cartAuthFolio = [];
    if (uiObj.cartDetails && uiObj.cartDetails.length > 0) {
      uiObj.cartDetails.forEach(
        cartDetail => {
          cartDetailsAPI.push(this.mapCartDetailUItoAPI(cartDetail));
        }
      );
    }
    if (uiObj.cartFolioAuthorizations && uiObj.cartFolioAuthorizations.length > 0) {
      uiObj.cartFolioAuthorizations.forEach(cartFolioAuthorization => {
        cartAuthFolio.push(this.mapCartFolioAuthorizationsUIToAPI(cartFolioAuthorization));
      });
    }
    let cartItinerary: CartItinerary;
    cartItinerary = {
      id: uiObj.id,
      cartAuthorizeLink: uiObj?.cartAuthorizeLink,
      cartComments: uiObj?.cartComments,
      primaryGuestId: uiObj?.primaryGuestId,
      cartDetails: cartDetailsAPI,
      confirmationId: uiObj.confirmationId,
      guestPhoneNumber: uiObj.guestPhoneNumber,
      holdSeconds: uiObj.holdSeconds,
      lastHoldDateTime: uiObj.lastHoldDateTime,
      platformItineraryUuid: uiObj.platformItineraryUuid,
      statusId: uiObj.statusId,
      userId: uiObj.userId,
      version: uiObj.version,
      cartFolioAuthorizations: cartAuthFolio,
      transferredUserId: uiObj.transferredUserId,
      createdBy: uiObj.createdBy,
      transferredUserName: uiObj.transferredUserName,
      primaryGuestFirstName: uiObj.primaryGuestFirstName,
      primaryGuestLastName: uiObj.primaryGuestLastName,
      primaryGuestUuId: uiObj.primaryGuestUuId,
      isMember: uiObj.isMember,
      itineraryPackageCode: uiObj.itineraryPackageCode,
      memberLevel: uiObj.memberLevel,
      membershipStatus: uiObj.membershipStatus,
      memberType: uiObj.memberType,
      numberOfNights: uiObj.numberOfNights,
      travelEndDate: uiObj.travelEndDate,
      travelStartDate: uiObj.travelStartDate,
      memberLevelColorCode: uiObj.memberLevelColorCode,
      memberTypeColorCode: uiObj.memberTypeColorCode
    };
    return cartItinerary;
  }

  mapCartFolioAuthorizationsUIToAPI(cartFolioAuthorization: CartFolioAuthorizationsUI): CartFolioAuthorization {
    let APIObject: CartFolioAuthorization;
    let authorization = JSON.stringify({
      cardCode: cartFolioAuthorization.cardCode,
      cardMasked: cartFolioAuthorization.cardMasked,
      cardExpireDate: cartFolioAuthorization.cardExpireDate,
      cardToken: cartFolioAuthorization.cardToken
    });
    APIObject = {
      id: cartFolioAuthorization.id,
      authorization: authorization,
      authorizationSeq: cartFolioAuthorization.authorizationSeq,
      authorizationType: cartFolioAuthorization.authorizationType,
      cartItineraryId: cartFolioAuthorization.cartItineraryId,
      foloioId: cartFolioAuthorization.foloioId,
      maxAuthorizedAmount: cartFolioAuthorization.maxAuthorizedAmount,
      isActive: cartFolioAuthorization.isActive
    };
    return APIObject;
  }

  private addSignalREventListeners() {
    if (this.signalR.hubConnection == null || this.signalR.hubConnection.state == HubConnectionState.Disconnected) {
      this.signalR.startConnection();
    }
    this.signalR.startedConnection.then(res => {
      this.signalR.addListener(this, "VcartAuthorizeComplete", this.authCompleteNotificationListener)
        .catch((err) => console.log(err));

      this.signalR.hubConnection.onreconnected((reconnect) => {
        const list = this.signalR.GetSignalREvents();
        list.forEach(e => { this.signalR.subscribeToEvent(e); });
      });

      this.signalR.addListener(this, "VCartItineraryTransfer", this.transferNotificationListener)
        .catch((err) => console.log(err));

      this.signalR.hubConnection.onreconnected((reconnect) => {
        const list = this.signalR.GetSignalREvents();
        list.forEach(e => { this.signalR.subscribeToEvent(e); });
      });
    });
  }

  async transferNotificationListener(message: SignalRMessage<NotificationModel>): Promise<void> {
    if (message && message.content) {
      const notification: TransferNotification = JSON.parse(message.content.notificationObjectString)
      if (notification && notification.cartItinerary.userId == this.userId) {
        const itinerary = notification.cartItinerary;
        const toastMsg = this.localization.replacePlaceholders(this.captions.toast_msg_cart_Transfer, ['confirmationId', 'userName'], [itinerary.confirmationId, notification.fromUserName]);
        this.utils.showToastMessage(toastMsg, SnackBarType.Info);
        let itineraryUI = this.mapItineraryAPItoUI(itinerary);
        this.itineraryList.push(itineraryUI);
        this.validateCart();
      } else if (this.itineraryList && this.itineraryList.find(o => o.id == notification.cartItinerary.id)) {
        // some other user transferred this itinerary to different user
        // hence remove from the current user's cart 
        try{
        let idx = this.itineraryList.findIndex(x => x.id == notification.cartItinerary.id);
        this.itineraryList.splice(idx, 1);
        this.validateCart();
        const toastMsg = this.localization.replacePlaceholders(this.captions.toast_msg_cart_Transfer, ['confirmationId', 'userName'], [notification.cartItinerary.confirmationId, notification.fromUserName]);
        this.utils.showToastMessage(toastMsg, SnackBarType.Info);
        this.postItineraryTransferredMessage(this.mapItineraryAPItoUI(notification.cartItinerary));
        }catch(e)
        {

        }
      }
    }
  }
  async authCompleteNotificationListener(message: SignalRMessage<NotificationModel>): Promise<void> {
    if (message && message.content) {
      const notification: AuthorizeCompleteNotification = JSON.parse(message.content.notificationObjectString)
      if (notification) {
        var itinerary = this.itineraryList.find(o => o.id == notification.cartItineraryId);
        if (itinerary) {
          itinerary.cartFolioAuthorizations = this.mapCartFolioAuthorizationsAPIToUI(notification.cartFolioAuthorizations);
          if (notification.cartFolioAuthorizations && notification.cartFolioAuthorizations.length) {
            const activeCard = notification.cartFolioAuthorizations.find(o => o.isActive);
            if (activeCard)
              itinerary.activeCardOnFile = this.mapCartFolioAuthorizationAPIToUI(activeCard);
          }
          const toastMsg = this.localization.replacePlaceholders(this.captions.toast_msg_cart_AuthComplete, ['confirmationId'], [itinerary.confirmationId]);
          this.utils.showToastMessage(toastMsg, SnackBarType.Info);
        }
      }
    }
  }

  calculateExpirySeconds(lastHoldDateTime: string, holdSeconds: number): number {
    let expirySeconds = 0;
    let utcDateNow = this.localization.getUTCDateTimeNow();
    let lastHoldDtm = this.localization.getDate(lastHoldDateTime);
    var ellapsedSeconds = (utcDateNow.getTime() - lastHoldDtm.getTime()) / 1000;
    ellapsedSeconds = Math.floor(ellapsedSeconds);
    if (ellapsedSeconds < 0 || ellapsedSeconds >= holdSeconds)
      expirySeconds = 0;
    else {
      expirySeconds = holdSeconds - ellapsedSeconds;
    }
    return expirySeconds;
  }

  searchValueChange(e) {
    this.searchText = e ? e.trim().toLowerCase() : '';
    if (this.searchText) {
      this.itineraryList = [];
      this.itineraryListUnfilteredData.filter(x => {
        if ((x.confirmationId.toLowerCase().indexOf(this.searchText) > -1) ||
          (x.name.toLowerCase().indexOf(this.searchText) > -1)) {
          this.itineraryList.push(x);
        }
      }
      );
    }
    else {
      this.itineraryList = this.itineraryListUnfilteredData;
    }
    this.collapseAllCart();
    this.validateCart();
  }

  hold(obj: CartItineraryUI, i) {
    try {

      this.dialog.open(SetCartTimerComponent, {
        height: '300px',
        width: '630px',
        data: {
          config: this.vCartTenantconfiguration
        }
      }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {
        this.cartUtilities.hideExpandVcartoverlay();
        if (res) {
          this.cartUtilities.ToggleLoader(true, this.captions.msg_holdingCartItinerary);
          this.cartBusiness.HoldCartItinerary(res, obj.id)
            .then(
              resFromAPI => {
                if (resFromAPI) {
                  if (obj.cartDetails.length == 0 || resFromAPI.length > 0) {
                    obj.holdSeconds = res * 60;  
                    obj.expirySeconds = res * 60;
                  }
                }
              })
            .catch(_ => {
              this.cartUtilities.showErrorAlert(this.captions.lbl_errorUnabletoHold);
            })
            .finally(() => {
              this.cartUtilities.ToggleLoader(false, this.captions.msg_holdingCartItinerary);
            }
            );
        }
      });
    }
    catch (ex) {
      console.error(ex, ex.stack);
      this.cartUtilities.showErrorAlert(this.captions.msg_holdItineraryFailed);
    }
    finally { this.cartUtilities.ToggleLoader(false, this.captions.msg_holdingCartItinerary); }
  }

  activateCart(event, x) {
    event.stopPropagation();
    this.itineraryList.forEach(obj => {
      if (x.id !== obj.id) {
        obj.expanded = false;
      } else {
        obj.expanded = true;
        this.lastActiveCartItineraryId = obj.id;
      }
    })
    this.postCartDataBroadcastMessage();
    this.postCartSelectedMessage();
    this.validateCart();

  }

  opnExpand(e) {
    e.iscartExpand = !e.iscartExpand;
  }



  moreOptionsemiter(e, obj) {
    switch (e.id) {
      case CartItineraryMenuAction.Remove: {
        this.remove(obj.id, true);
        break;
      }
      case CartItineraryMenuAction.Transfer: {
        this.openTransfer(obj)
        break;
      }
      case CartItineraryMenuAction.SendPaymentLink: {
        this.openSendPaymentLinkPopup(obj)
        break;
      }
      case CartItineraryMenuAction.AddCartCommnets: {
        this.openAddComments(obj);
        break;
      }
      case CartItineraryMenuAction.PaymentCreditCard: {
        this.processCreditCard(obj);
        break;
      }
    }
  }

  processCreditCard(obj){
    this.cartUtilities.pushMessageToChildWindows(CartWindowMessageType.RequestNewCard, obj.id);
  }

  openAddComments(cartItinerary: CartItineraryUI) {
    let confirmationId = cartItinerary?.confirmationId;
    this.dialog.open(CartCommentsComponent, {
      height: '320px',
      width: '700px',
      data: {
        title: this.captions.lbl_CartComments + ' - ' + confirmationId,
        existingComment: cartItinerary?.cartComments
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {
      this.cartUtilities.hideExpandVcartoverlay();
      if (res && res.length > 0) {
        try {
          this.cartUtilities.ToggleLoader(true, this.captions.msg_UpdatingCartComments);
          const result = await this.cartBusiness.UpdateCartComments(cartItinerary.id, res);
          if (result) {
            cartItinerary.cartComments = res;
            cartItinerary.menuOptions = this.getCartOptions(cartItinerary);
          }
        }
        catch (ex) {
          this.cartUtilities.showErrorAlert(this.captions.msg_CartCommentsUpdateFailed);
          console.error(ex.stack);
        }
        finally {
          this.cartUtilities.ToggleLoader(false, this.captions.msg_UpdatingCartComments);
        }
      }
    });
  }
  openSendPaymentLinkPopup(selectedItinerary: CartItineraryUI) {
    let guestDetails = selectedItinerary.cartDetails?.flatMap(c => c.cartPayload.guestInfos);
    let tblData: { id: string, name: string, email: string, selected: boolean }[] = [];
    guestDetails.forEach(gd => {
      if (gd.email && gd.email.length > 0) {
        gd.email.forEach(e => {
          if (e && e.value.length > 0 && !tblData.find(o => o.id == gd.guestId && o.email == e.value)) {
            tblData.push({
              email: e.value,
              id: gd.guestId,
              name: gd.firstName + ' ' + gd.lastName,
              selected: false
            });
          }
        })
      }
    });
    const customComparator = (obj1, obj2) => {
      return isEqual(omit(obj1, 'id'), omit(obj2, 'id'));
    };
    tblData = uniqWith(tblData, customComparator);

    this.dialog.open(CartAuthorizeComponent, {
      height: '400px',
      width: '500px',
      data: {
        tableData: tblData,
        title: this.captions.lbl_SendPaymentLink + " - " + selectedItinerary?.confirmationId
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.cartUtilities.hideExpandVcartoverlay();
      if (res) {
        this.cartUtilities.ToggleLoader(true, this.captions.msg_SendingPaymentLink);
        this.cartBusiness.AuthorizeItinerary(selectedItinerary?.id, res.email)
          .then(async () => {
            this.utils.showCommonAlert(this.captions.msg_paymentLinkSentSuccess, AlertType.Success);
          })
          .catch(_ => {
            this.cartUtilities.showErrorAlert(this.captions.lbl_errorSendingPaymentLink);
          })
          .finally(async () => {
            this.cartUtilities.ToggleLoader(false, this.captions.msg_SendingPaymentLink);
            await this.loadCartItinerariesForTheUser();
          });
      }
    });
  }


  removeCartItem(obj, data, cartItinerary) {
    try {
      let warnMsg = this.localization.replacePlaceholders(this.captions.warn_deleteWithName, ['name'], [obj.cartPayload.description]);
      this.utils.showCommonAlert(warnMsg, AlertType.Warning, ButtonTypes.YesNo, res => {
        if (res === AlertAction.YES) {
          this.cartUtilities.ToggleLoader(true, this.captions.msg_removeCartItem);
          this.cartBusiness.deleteCartDetailById(obj.id)
            .then(response => {
              if (response) {
                let idx = data.findIndex(x => x.id == obj.id);
                data.splice(idx, 1);
                this.validateCart();
                this.postCartRemovedMessage(cartItinerary, obj);
              }
            })
            .catch(o => {
              this.cartUtilities.showErrorAlert(this.captions.lbl_errorRemoveCartDetail);
            })
            .finally(() => {
              this.cartUtilities.ToggleLoader(false, this.captions.msg_removeCartItem);
            });
        }
      });
      this.validateCart();
    } catch (ex) {
      console.error(ex, ex.stack);
      this.cartUtilities.showErrorAlert(this.captions.lbl_CartItemRemoveFailed);
    }
  }
  async openTransfer(obj) {
    // if (obj.userId != obj.createdBy) {
    //   const errMsg = this.localization.replacePlaceholders(this.captions.msg_CartAlreadyTransferred, ['confirmationId'], [obj.confirmationId]);
    //   this.utils.showAlert(errMsg, AlertType.Info);
    // }

    let userData = await this.cartBusiness.getUsers();
    userData = userData.filter(o => o.userId != this.userId);
    this.usersList = userData.map(x => ({ userId: x.userId, userName: x.userName, firstname: x.firstName, lastname: x.lastName }));
    this.dialog.open(CartTransferComponent, {
      height: '600px',
      width: '900px',
      data: {
        cartItineraryId: obj.id,
        tableData: this.usersList,
        title: this.captions.lbl_Transfer + ' - ' + obj.confirmationId
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.cartUtilities.hideExpandVcartoverlay();
      if (res.errorCode == 0) {
        let idx = this.itineraryList.findIndex(x => x.id == obj.id);
        this.itineraryList.splice(idx, 1);
        this.validateCart();
        let successMsg = this.localization.replacePlaceholders(this.captions.msg_cartItineraryTransferred, ['confirmationId'], [obj.confirmationId]);;
        this.utils.showAlert(successMsg, AlertType.Success);
        this.postItineraryTransferredMessage(obj);
      }
      // else if (res.errorCode == CartErrorCodes.AlreadyTransferred) {
      //   const errMsg = this.localization.replacePlaceholders(this.captions.msg_CartAlreadyTransferred, ['confirmationId'], [obj.confirmationId]);
      //   this.utils.showAlert(errMsg, AlertType.Info);
      //   return;
      // }
    });

  }
  async removeItinerary(id: number, releaseItinerary: boolean = false) {
    try {
      this.cartUtilities.ToggleLoader(true, this.captions.msg_removeItinerary);
      let isRemoved = false;
      // This condition comes true when user removed the itinerary manually
      if (releaseItinerary) {
        isRemoved = await this.cartBusiness.RemoveItinerary(id, releaseItinerary);
      }
      // this condition comes when the Cart Expires and this method would have been called from the Timer Observable
      // when the cart expires, we will just remove it from the UI object and let the server expires the Itinerary when we fetch the Cart Itinerary
      // when the user removed the itinerary, we will call the server to release the itineraries and then remove the UI object only if the release success from Server end
      if (!releaseItinerary || isRemoved) {
        // let idx = this.itineraryList.findIndex(x => x.id == id);
        // this.itineraryList.splice(idx, 1);
        // this.validateCart();
        let cartItinerary = this.getCartItineraryById(id);
        this.postCartItineraryRemovedMessage(cartItinerary);
        await this.refreshCart();
      }
    } catch (e) {
      console.log(e.stack);
      if (releaseItinerary)
        this.cartUtilities.showErrorAlert(this.captions.msg_errorWhileRemoving);
    } finally {
      this.cartUtilities.ToggleLoader(false, this.captions.msg_removeItinerary);
    };
  }

  async remove(id, releaseItinerary: boolean = false) {
    try {
      if (releaseItinerary) {
        let confirmationId = this.itineraryList.find(x => x.id == id)?.confirmationId;
        let warnMsg = this.localization.replacePlaceholders(this.captions.warn_deleteWithName, ['name'], [confirmationId]);
        this.utils.showCommonAlert(warnMsg, AlertType.Warning, ButtonTypes.YesNo, async res => {
          if (res === AlertAction.YES) {
            await this.removeItinerary(id, releaseItinerary);
          }
        });
      } else {
        await this.removeItinerary(id, releaseItinerary);
      }
    } catch (ex) {
      console.error(ex, ex.stack);
      if (releaseItinerary)
        this.cartUtilities.showErrorAlert(this.captions.msg_errorWhileRemoving);
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.cartUtilities.vCartEnabled = null;
    this.signalR.destroyListener("VCartItineraryTransfer");
    this.signalR.destroyListener("VcartAuthorizeComplete");
  }
  showErrorAlert(message: string = null) {
    if (!message) {
      this.utils.showAlert(this.captions.PMUnexpectedError, AlertType.Error);
    }
    else {
      this.utils.showAlert(message, AlertType.Warning);
    }
  }

  cartItemEdited(itinerary: CartItineraryUI, cartDetail: CartDetailUI) {
    if (cartDetail && cartDetail.cartPayload) {
      let cartDetailOriginPropertyId = cartDetail.cartPayload.propertyId.toString().trim();
      let editAllowed = true;
      if (cartDetail.productId == Product.PMS) {
        if (cartDetailOriginPropertyId != this.utils.GetPropertyInfo('PropertyId').trim()) {
          editAllowed = false;
        }
      } else if (cartDetail.productId == Product.SPA) {
        if (cartDetailOriginPropertyId != sessionStorage.getItem('spaEmbedPropertyId').trim()) {
          editAllowed = false;
        }
      } else if (cartDetail.productId == Product.GOLF) {
        if (cartDetailOriginPropertyId != sessionStorage.getItem('golfEmbedPropertyId').trim()) {
          editAllowed = false;
        }
      }
      if (editAllowed)
        this.postCartEditedMessage(itinerary, cartDetail);
      else
        this.utils.showAlert(this.captions.msg_cartDetailCrossPropertyEditAlert, AlertType.Info);

    }
  }

  expandCart() {
    document.getElementById("cartPopOverID").classList.toggle("newCartstyle");
    const bodyTag = document.getElementsByTagName("body")[0];
    bodyTag.classList.toggle("vcart-overlay");
  }
  dragStart(event, sourceCartItinerary: CartItineraryUI, cartDetailToBeMoved: CartDetailUI) {
    this.dragObject = {
      cartDescription: sourceCartItinerary && sourceCartItinerary.confirmationId,
      sourceCartItinerary: sourceCartItinerary,
      cartDetailToBeMoved: cartDetailToBeMoved,
    };
  }
  async drop(event: CdkDragDrop<string[]>, destinationCartItinerary: CartItineraryUI) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.dragObject = null;
    }
    else {

      let ellapsedSeconds = this.calculateExpirySeconds(destinationCartItinerary.lastHoldDateTime
        , destinationCartItinerary.holdSeconds);
      if (ellapsedSeconds <= 180) {
        const data = this.localization.replacePlaceholders(this.captions.msg_MoveCartDetailToCartItineraryWhichIsAboutToExpire,
          ['destinationConfirmationID'],
          [destinationCartItinerary.confirmationId]);
        this.utils.showAlert(data, AlertType.Info, ButtonType.Ok, async (result) => {
          return;
        });
      }
      else {
        const data = this.localization.replacePlaceholders(this.captions.msg_MoveCartDetailFromSourceToDestination,
          ['cartDescription', 'fromconfirmationId', 'destinationConfirmationId'],
          [this.dragObject.cartDetailToBeMoved.cartPayload.description,
          this.dragObject.sourceCartItinerary.confirmationId, destinationCartItinerary.confirmationId]);
        this.utils.showAlert(data, AlertType.Warning, ButtonType.YesNo, async (res) => {
          if (res === AlertAction.YES) {
            try {
              this.cartUtilities.ToggleLoader(true, this.captions.msg_CartDetailBeingMoved);
              await this.cartBusiness.MoveCartDetail(this.dragObject.cartDetailToBeMoved.id,
                this.dragObject.sourceCartItinerary.id, destinationCartItinerary.id)
                .then(async res => {
                  transferArrayItem(event.previousContainer.data, event.container.data,
                    event.previousIndex, event.currentIndex);
                  this.utils.showAlert(this.captions.msg_MovedCartDetail, AlertType.Success);
                  await this.loadCartItinerariesForTheUser();
                  this.validateCart();
                })
                .catch(_ => {
                  this.cartUtilities.showErrorAlert(this.captions.lbl_MoveFailed);
                })
                .finally(() => {
                  this.dragObject = null;
                });
            }
            catch (ex) {
              this.cartUtilities.showErrorAlert(this.captions.msg_MoveFailed);
              console.error(ex, ex.stack);
            }
            finally {
              this.cartUtilities.ToggleLoader(false, this.captions.msg_CartDetailBeingMoved);
            }
          }
        }
        );
      }
    }
  }
  private getCartItineraryById(id: number): CartItineraryUI {
    return this.itineraryList?.find(o => o.id == id);
  }

  viewAllItineraries() {
    this.dialog.open(ViewAllItinerariesPopupComponent, {
      width: '80%',
      height: '80%',
      data: {
        title: this.captions.lbl_view_all_itineraries
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
      }
    });
  }
}

@Pipe({
  name: 'totalestimate',
  pure: false
})
export class totalestimatePipe implements PipeTransform {
  constructor(private localization: Localization) {

  }
  transform(obj: CartItineraryUI[], key: string): any {
    if (obj && obj['cartDetails']) {
      let allValue = obj['cartDetails'].map(data => data.cartPayload[key]);
      const total = allValue && allValue.reduce((a, b) => a + b, 0)
      return this.localization.localizeCurrency(total, true)
    }
  }
}
