import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { NomathMenuService } from 'src/app/eatecui/source/transaction/nomath-menu/nomath-menu.service';
import { CreateTransaction } from 'src/app/eatecui/source/transaction/shared/interface/create-transcation';
import { NoMathPopUpConfig, NomathDetailConfig, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { HeaderOverlayRef } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';

@Component({
  selector: 'app-nomathmenu-form',
  templateUrl: './nomathmenu-form.component.html',
  styleUrls: ['./nomathmenu-form.component.scss']
})
export class NomathmenuFormComponent implements OnInit, AfterViewInit {

  homeGroup: FormGroup;
  FormFieldArray: FormType[];
  agilysysCofirmationButton: AgilysysButtonModel[];
  noMathPopUpConfig = {} as NoMathPopUpConfig;
  qtyvalue: any;
  params: any;
  enterIndex: any = 1;
  DivisionId: number;
  constructor(
    private transactionService: TransactionService,
    private httpService: HttpService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private nomathmenuService: NomathMenuService,
    private formBuilder: FormBuilder,
    public dialogRef: HeaderOverlayRef
  ) {
    this.DivisionId = this.nomathmenuService.DivisionId;
    this.params = this.nomathmenuService.params;
    this.qtyvalue = this.nomathmenuService.params.data.Qty;
    this.homeGroup = this.formBuilder.group({});
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.constratctMathEngineCalculation(this.noMathPopUpConfig, this.transactionService.transactionConfigurationData,
            this.params.data, callBackData, this.dialog, this.params);
          this.dialogRef.close();
          this.homeGroup.reset();
          this.nomathmenuService.params = null;
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.constratctMathEngineCalculation(this.noMathPopUpConfig, this.transactionService.transactionConfigurationData,
            this.params.data, callBackData, this.dialog, this.params);            
          const inputBox = document.getElementById('input-number-touch');
          if (inputBox) {
            inputBox.focus();
          }
          this.dialogRef.close();
          this.homeGroup.reset();
          this.nomathmenuService.params = null;
        }
      }
    ];
  }

  reset() {
    this.enterIndex = 1;
    const Qty = this.homeGroup.value.Qty;
    this.homeGroup.reset();
    this.homeGroup.controls['Qty'].setValue(Qty);
    this.FormFieldArray.forEach(formDatas => {
      if (formDatas.fieldFormaters) {
        CommonService.triggerBlurEvent(formDatas.fieldType.SingleData.name);
      }
    });
  }

  closeMenu() {
    this.dialogRef.close();
    this.homeGroup.reset();
  }

  focusInputBox() {
    const focusInput = document.getElementById(this.FormFieldArray[this.enterIndex]?.fieldType.SingleData.name);
    if (focusInput) {
      focusInput.focus();
    }
  }

  enterKeyNavigation(event) {
    console.log('Enter Key', event, this.enterIndex);
    this.enterIndex = this.enterIndex + 1;
    if (this.enterIndex <= 4) {
      this.focusInputBox();
    } else if (this.enterIndex === 5) {
      const NoMathSection = document.querySelector('.nomath-menu-section');
      const confirmButton = NoMathSection.getElementsByClassName('agilysys-button-primary')[0];
      confirmButton.setAttribute('id', 'nomath-primary');
      const noMathButton = document.getElementById('nomath-primary');
      setTimeout(() => {
        if (noMathButton) {
          noMathButton.focus();
        }
      }, 10);
    }
  }

  constratctMathEngineCalculation(
    noMathPopUpConfig: NoMathPopUpConfig,
    TransactionCollection: TransactionConfiguration,
    NoMathData: any, value: CallBackData, dialog: MatDialog, GridApi): void {
    try {
      const rowNode = this.params.api.getRowNode(this.params.rowIndex);
      switch (value.buttonValue.buttonKey) {
        case 'confirm':
          if (this.homeGroup.valid) {
            dialog.closeAll();
            const mathFieldValue = this.homeGroup.value;
            const mathCalculateQuantity = this.calculateMathNomathData(mathFieldValue, NoMathData);
            const noMathUniqueId = (NoMathData.hasOwnProperty('Id') && NoMathData.Id !== 0)
              ? NoMathData.Id : NoMathData['RowId'];
            // Duplicate Ingredient Id will be available so used RowId
            NoMathData['Qty'] = mathCalculateQuantity ? parseFloat(mathCalculateQuantity).toFixed(6)
              : parseFloat('0').toFixed(6);
            this.qtyvalue = NoMathData['Qty'];
            const detailConfig = {} as NomathDetailConfig;
            detailConfig.NoMathRowId = noMathUniqueId;
            const QtyValue = parseFloat(mathFieldValue['Qty']).toFixed(this.commonService.GetVisibleNumberLength());
            detailConfig.NoMathDetailHeader = `${QtyValue} ` + `${NoMathData['UnitCode']}`;
            detailConfig.NoMathDetailData = [];
            for (const property in mathFieldValue) {
              if (mathFieldValue[property]) {
                const UnitData = NoMathData['Units'];
                if (UnitData.length > 0) {
                  const mathUnits = UnitData.filter(x => x['UnitTypeName'] === property)[0];
                  if (mathUnits) {
                    const unitsObject = { MathType: '', MathText: '' };
                    unitsObject['MathType'] = (mathFieldValue[property] >= 0) ? 'Added' : 'Subtracted';
                    const symbol = (unitsObject['MathType'] === 'Added') ? '+' : '';
                    const MathValue = parseFloat(mathFieldValue[property]).toFixed(this.commonService.GetVisibleNumberLength());
                    unitsObject['MathText'] = `<span class='no-math-type'>` + `${unitsObject['MathType']}</span>` + `<span class='math-${unitsObject['MathType']}'> ` + `${symbol} ` + `${MathValue}` + ` (${mathUnits['UnitCode']})</span> ` + `<span class='math-unit-name'> in ${mathUnits['UnitTypeName']}</span>`;
                    detailConfig.NoMathDetailData.push(unitsObject);
                  }
                }

              }

            }
            const Qtydata: GetCellEditorInstancesParams = {
              rowNodes: [rowNode],
              columns: ['Qty'],
            };
            const Qtyinstances = this.params.api.getCellEditorInstances(Qtydata);
            if (Qtyinstances.length > 0) {
              Qtyinstances[0]._params.data.Qty = this.qtyvalue;
              Qtyinstances[0].value = this.qtyvalue;
              Qtyinstances[0]._params.value = this.qtyvalue;
              if (Qtyinstances[0].input) {
                Qtyinstances[0].input.value = this.qtyvalue;
              }
              Qtyinstances[0]._frameworkComponentInstance.qtyvalue = this.qtyvalue;
            }
            if (GridApi) {
              GridApi.api.refreshCells();
            }
            TransactionCollection.ActionConfig.filter(
              x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['NomathDetails'].push(detailConfig);
          } else {
            TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].
              ActionPopupConfig['PopupInputData']['FormGroupData'].markAllAsTouched();
          }
          break;
        case 'reset':
          const FormData = this.homeGroup;
          for (const property in FormData.controls) {
            if (property !== 'Qty' && FormData['controls'][property].value !== '') {
              FormData['controls'][property].setValue('');
            }
          }
          break;
        case 'cancel':
          dialog.closeAll();
          TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupButtonConfig'].forEach((buttonModel: AgilysysButtonModel) => {
            if (buttonModel.buttonKey === 'confirm') {
              buttonModel.displayProperity = true;
            }
          });
          break;
        default:
        // code block
      }
    } catch (error) {
      console.error(error);
    }
  }
  calculateMathNomathData(MathFieldValue: any, NomathData: any): any {
    try {
      const actualQuantity = parseFloat(MathFieldValue['Qty'].toString());
      let calulateValue = 0;
      let unitConversion = 0;
      for (const property in MathFieldValue) {
        if (property !== 'Qty' && MathFieldValue[property] !== '') {
          // Converting to Pack size
          NomathData['Units'].forEach(element => {
            // && element.UnitTypeName !== 'Recipe Unit'
            if (element.UnitTypeName === property) {
              unitConversion = element.Conversion;
            }
            // Commented this becoz recipe conversion is coming as 1/ recipe conversion
            // so commented in UI
            // else if (property === 'Recipe Unit') {
            //     unitConversion = BaseUnitConversion / element.Conversion;
            // }
          });
          // Convert All BaseUnit Qty To Corresponding Unit Qty
          calulateValue += parseFloat(MathFieldValue[property].toString())
            * parseFloat(unitConversion !== 0 ? unitConversion.toString() : NomathData['Conversion'].toString());
        }
      }
      const CurrentConversion = NomathData.Units ? NomathData.Units.
        filter(x => x.UnitTypeId === NomathData.UnitTypeId)[0].Conversion : 1;
      const formattedcalulateValue = calulateValue !== 0 ? calulateValue / CurrentConversion : 0;
      return (formattedcalulateValue + actualQuantity);
    } catch (error) {
      console.error(error);
    }
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    setTimeout(() => {
      this.focusInputBox();
      this.FormClick();
    }, 10);
  }

  ngOnInit(): void {
    const FormFields = CreateTransaction.NomathCalculation(this.transactionService.transactionConfigurationData, this.httpService,
      this.dialog, this.nomathmenuService.params.data, this.nomathmenuService.params, this.transactionService,
      this.transactionService.transactionFormGroup, 'nomath-editor', this.commonService);
    this.noMathPopUpConfig = FormFields;
    this.FormFieldArray = FormFields.PopupFormConfig.FormFields;
  }

  FormClick() {
    this.nomathmenuService.params.api.startEditingCell({
      rowIndex: this.nomathmenuService.params.rowIndex,
      colKey: this.nomathmenuService.params.colDef.field
    });
  }

}
