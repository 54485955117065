import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AutomailBusiness } from './dm-automail.business';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { automailTemplate } from './dm-automail.model';
import { MatDialogRef } from '@angular/material/dialog';
import { FromTypeEnum } from '../../components/cdkvirtual/cdkvirtual.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Localization } from '../../localization/localization';


@Component({
  selector: 'app-dm-automail',
  templateUrl: './dm-automail.component.html',
  styleUrls: ['./dm-automail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AutomailBusiness]
})
export class DmAutomailComponent implements OnInit {
  captions: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  filteredItems = [];
  unitItems = [];
  selectedtemplate : any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  options: TableOptions;
  emailHeaderOptions: TableHeaderOptions[];
  emailTableContent: automailTemplate[];
  count: number;
  searchText1: any;
  searchText2: any;
  selectedIndex: number=0;

  constructor(private localization: Localization,
    private business:AutomailBusiness,
    private dialogRef: MatDialogRef<DmAutomailComponent>) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.initialize();
    this.generateTable();
  }
  handleSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedIndex = event.index;
    this.selectedtemplate = this.filteredItems[event.index].content[event.index];
    this.filteredItems = [...this.filteredItems];
  }
  async initialize(){
    this.filteredItems = await this.business.getData();
    this.unitItems = this.filteredItems;
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: false
    }

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    }
    this.selectedtemplate = this.filteredItems[0].content[0];
  }
  drop(event: CdkDragDrop<string[]>,i) {
    moveItemInArray(this.filteredItems[i].data, event.previousIndex, event.currentIndex);
  }
  generateTable(){
    this.options = this.business.getTableOptions();
  }

  emailTableAction(event,i) {
    switch (event.fromType) {
      case FromTypeEnum.radioButton:
           this.filteredItems[i].data.forEach(x => { x.checked = false });
           this.count = 0;
           this.selectedtemplate = event.Obj;
      break;
    }
    this.filteredItems = [...this.filteredItems];
  }
  onSave(eve){

  }
  onCancel(eve){

  }
  onDrop(event) {
    if (event.previousContainer === event.container) {   
      moveItemInArray(event.container.data,
        event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
   }
  toggle(item, eve,i) {
    this.count = 0;
    this.filteredItems[i].data.forEach(x => {
      if (x.id == item.id) {
        if (eve) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      }
      if (x.checked) {
        this.count++;
      }
    });
    this.filteredItems = [...this.filteredItems];
  }
  close(eve) {
    this.dialogRef.close();
  }
  searchValueChange(e,i) {
    this.searchText1 = e;
    if (e) {
      const filterValue = e.toLowerCase();
      this.filteredItems[i].data = this.unitItems[i].filter(x => x.code.toLowerCase().indexOf(filterValue) === 0)
    } else {
      this.filteredItems[i].data = this.unitItems[i];
    }
    this.filteredItems = [...this.filteredItems];
  }
}
