<section class="golfdatepicker" *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'">
    <div [formGroup]="form" title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
      form.controls[this.formFieldName]?.value : formFieldToolTip}}">
      <mat-form-field appearance="fill" [ngClass]="field.fieldType[fieldType]?.disbaledProperty ? 'datepicker-disabled':  ''">
        <mat-label>{{formFieldlabel}}</mat-label>
        <input [min]="minDate" [max]="maxDate" [required]="isRequired" (dateChange)="eventCallback($event)" matInput [matDatepicker]="picker" [value]=""  
          [readonly]="field.fieldType[fieldType]?.disbaledProperty"
          [disabled]="field.fieldType[fieldType]?.disbaledProperty" [formControlName]="formFieldName"
          [id]="formFieldName" (change)="valueChange($event)" [placeholder]="IniDateFieldFormat ? IniDateFieldFormat : ''">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
      </mat-form-field>
    </div>
  </section>
  <!-- readonly Remove readonly as per CM suggestion - Purpose to clear supplier bids expire date for open state -->