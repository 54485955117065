import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailSetupService } from '../retail-setup.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { HttpMethod } from '../../shared/service/http-call.service';
import { Host,RetailBreakPoint } from '../../shared/globalsContant';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { Subscription } from 'rxjs';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';

@Component({
  selector: 'app-seasonal-price',
  templateUrl: './seasonal-price.component.html',
  styleUrls: ['./seasonal-price.component.scss']
})
export class SeasonalPriceComponent implements OnInit, OnDestroy {

  seasonalPriceFormGrp: UntypedFormGroup;
  captions: any;
  minFromDate; minToDate;
  seasonalFormChanges: any;
  validateBtn: boolean = false;
  popTemplate: any;
  validateBtnSubscription: Subscription;
  public selectedOutletMgrArr: any = [];
  outletSubscription: Subscription;
  @Input() isEdit;
  @Input() selectedItemInfo: any;
  IsViewOnly: boolean = false;
  placeHolderFormat: string;
  captionsCommon: any;
  floatLabelNever: string;
  floatLabel: string;
  constructor(public fb: UntypedFormBuilder, private dialogRef: MatDialogRef<RetailPopupComponent>, public _retailService: RetailSetupService,
    public localization: RetailLocalization,  private PropertyInfo: RetailPropertyInformation, private utils: RetailUtilities) {
      this.captionsCommon = this.localization.captions.common;
      this.floatLabel = this.localization.setFloatLabel;
      this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.placeHolderFormat = this.localization.inputDateFormat;
    this.captions = this.localization.captions.retailsetup;
    this.minFromDate = this.minToDate = this.PropertyInfo.CurrentDate; 
    this.seasonalPriceFormGrp = this._retailService.seasonalPriceFormGrp;
    this.outletSubscription = this._retailService.selectedOutletMgrArr.subscribe(outlet =>
      this.selectedOutletMgrArr = outlet);
    this.validateBtnSubscription = this._retailService.validateBtn.subscribe(isValid => this.validateBtn = isValid);
    this.IsViewOnly = this._retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.ItemSetup).view;
  }

  ngOnDestroy() {
    if (this.seasonalFormChanges) {
      this.seasonalFormChanges.unsubscribe();
    }
    this.outletSubscription.unsubscribe();
    this.validateBtnSubscription.unsubscribe();
  }

  async save() {

    this._retailService.ValidateUpdate();
    if (!this.validateBtn) return;
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    let updateBody = this._retailService.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;

    let itemId = 0;
    if (this.isEdit) {
      response = await this._retailService.InvokeServiceCallAsync("UpdateRetailItem", Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id })
      if (response) {
        this._retailService.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    }
    else {
      response = await this._retailService.InvokeServiceCallAsync("CreateRetailItem", Host.retailManagement, HttpMethod.Post, updateBody)
      if (response) {
        this._retailService.saveImage(response.result)
        itemId = response.result;
      }      
    }
    if (this._retailService.showRetailSetupRoomTypesAssignmentTab){
      RetailDataAwaiters.createOrUpdateRoomTypeAssignment(updateBody.retailItem.roomTypeAssignmentRetailItemMapping , itemId)
    }
    if (response && response.result) {
      this._retailService.retailItemUpdateEvent.next(itemId);
      this.dialogRef.close(updateBody);
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "UpdateRetailItem") {
      if (result.result) {
        this.dialogRef.close();
      }
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  close() {
    this.dialogRef.close();
  }

  async saveOnAsync() {
    await this.save();
  }




  GetOutletName(outlet): string {
    if (outlet && outlet.name) { 
      return outlet.name;
    }
    return ''
  }

  fromDateChange($event) {
    this.minToDate = this.utils.getDate($event)
    let endDate = this.seasonalPriceFormGrp.controls.endDate.value;
    if (endDate && this.utils.getDate(endDate) < this.utils.getDate($event)) {
      this.seasonalPriceFormGrp.controls.endDate.setValue(this.utils.getDate($event));
    }
  }

  ngAfterViewInit() {
    this._retailService.ValidateUpdate();
    this.formValueChanges();
  }

  formValueChanges() {
    this.seasonalFormChanges = this.seasonalPriceFormGrp.valueChanges.subscribe(data => {
      this._retailService.ValidateUpdate();
    }

    )
  }
  stopdefaultClick(e) {
    if (this.isEdit && this.IsViewOnly) {
      e.preventDefault();
    }
  }
}


