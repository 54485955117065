import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridActionsConfig, GridHeaderConfig, GridRowActionRenderer, GridSearchAction, GridSortAction, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridActionsConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): GridActionsConfig => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                const gridActionsConfig = {} as GridActionsConfig;
                return gridActionsConfig;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };