import { Pipe, PipeTransform } from '@angular/core';
import { ClientDetail } from '../business/shared.modals';

@Pipe({
  name: 'getClientName'
})
export class GetClientNamePipe implements PipeTransform {

  transform(client: ClientDetail, ...args: any[]): any {
    return (client.firstName || client.FirstName) + ' ' + (client.lastName || client.LastName || '');
  }

}
