import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef, OnChanges, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, map, distinctUntilChanged ,takeUntil} from 'rxjs/operators';
import { fromEvent, ReplaySubject, Subject, Subscription } from 'rxjs';
import { ItemSearchBaseResponse } from '../shared.modal';


@Component({
  selector: 'app-search-linked-retail-items',
  templateUrl: './search-linked-retail-items.component.html',
  styleUrls: ['./search-linked-retail-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchLinkedRetailItemsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() searchTextEmit: EventEmitter<any> = new EventEmitter();
  @Output() searchControlValidator: EventEmitter<any> = new EventEmitter();
  @Input() retailItemType: number;
  @Input() displayTexts;
  @Input() autocomplete: UntypedFormControl;
  @Input() filteredData: any = [];
  @Input() searchedIndex;
  @Input() arrayIndex;
  @Input() isMultipleLinkedItem;
  @Input() automationId : string = '';
  ORG_searchFilter: any;
  @Input() Disabled = false;
  isRetailItemSelected = false;
  searchText: string;
  subscription: Subscription;
  @ViewChild('autoCompleteInput') autoCompleteSelect: MatAutocompleteTrigger;
  @ViewChild('searchInput', { static: true }) input: ElementRef;
  linkedItemsClicked: boolean;
  searchFilter: ItemSearchBaseResponse[] = [];

  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);



  ngOnChanges() {
    this.searchFilter = this.filteredData ? this.filteredData : [];
  }

  ngAfterViewInit(): void {
    // const terms$ = fromEvent<any>(this.input.nativeElement, 'keyup')
    //   .pipe(
    //     map(event => event.target.value),
    //     debounceTime(500),
    //     distinctUntilChanged()
    //   );
    // this.subscription = terms$
    //   .subscribe(
    //     criterion => {
    //       if (this.autocomplete.value) this.searchTextEmit.emit(this.autocomplete.value);
    //     }
    //   );

    this.autocomplete.valueChanges.pipe(
			debounceTime(500),
			distinctUntilChanged(),
			takeUntil(this.$destroyed)).subscribe(val => {
        this.searchTextEmit.emit(this.autocomplete.value);
			});

    
  }

  ngOnInit() {
    if (this.Disabled) {
      this.autocomplete.disable();
    }   
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.$destroyed.next(true);
		this.$destroyed.complete();
  }

  filterFucntion($event) {
    if (!this.Disabled && this.autocomplete.value) {
      this.searchText = null;
      this.autocomplete.setValue(undefined);
      this.searchTextEmit.emit(this.autocomplete.value);
    }
  }
  Done(eventData) {
    this.doneEvtEmitter.emit(eventData);
  }

  SelectedRecord(data) { 
    if(this.arrayIndex>=0)
    {
      const eventData={data:data.id,index:this.arrayIndex}
      this.Done(eventData);
    }
    else
    {
      const eventData=data.id;
      this.Done(eventData);
    }
    this.autocomplete.setValue(data.itemNumber + ' - ' + data.itemDescription);
    this.searchFilter = [];
    this.linkedItemsClicked = false;
  }

  autocompleteShow(e) {
    this.linkedItemsClicked = true;
    e.stopPropagation();
  }

  clickedOutside() {
    if (this.linkedItemsClicked) {
      this.linkedItemsClicked = false;
    }
  }

  ToMatch(from: string, to: string): boolean {
    let fromValue: string = from.toString().toUpperCase();
    fromValue = fromValue.replace(/[^a-zA-Z0-9]/g, "")
    to = to.toUpperCase();
    to = to.replace(/[^a-zA-Z0-9]/g, "")
    if (fromValue.match(to)) {
      return true;
    } else {
      return false;
    }
  }
  displayFn(searchVal: any) {
    if (searchVal) {
      const dispValue = searchVal.itemNumber + ' - ' + searchVal.itemDescription;
      this.isRetailItemSelected = true;
      if (this.isRetailItemSelected) {
        this.searchText = dispValue;
      }
      return dispValue;
    }
  }
  formFieldValidator() {
    this.searchControlValidator.emit((this.autocomplete.value) ? true : false);
  }
}
