import { Injectable } from '@angular/core';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { ActionTypeEnum, SorTypeEnum, aligment } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Localization } from 'src/app/common/localization/localization';
import { ComponentsLinkingConfigAPI, ComponentsLinkingConfigUI } from '../../Models/components-linking-config.model';
import { ComponentsLinkingConfigService } from '../../dataservices/componentsLinking/components-linking-config.service';

@Injectable()
export class MasterComponentLinkingBusiness {
  captions: any;
  constructor(
    private localization: Localization,
    private componentsLinkingConfigservice: ComponentsLinkingConfigService) {
    this.captions = this.localization.captions;
  }

  getTableOptions(isViewOnly: boolean): TableOptions {
    return {
      actions: [
        {
          type: TableActions.edit,
          disabled: isViewOnly
        },
        {
          type: TableActions.delete,
          disabled: isViewOnly
        }
      ],
      columnFreeze: {
        lastColumn: true,
        firstColumn: false
      },
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'key',
      deleteMsgKey: 'key',
      showTotalRecords: false
    }
  }
  getTableHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'key',
        displayNameId: 'lbl_key',
        displayName: this.captions.lbl_key,
        sorting: true,
        sortingKey: 'key',
        searchable: true
      },
      {
        key: 'value',
        displayNameId: 'lbl_value',
        displayName: this.captions.lbl_value,
        sorting: true,
        sortingKey: 'value',
        searchable: true
      },
      {
        key: 'typeId',
        displayNameId: 'lbl_typeId',
        displayName: this.captions.lbl_typeId,
        sorting: true,
        sortingKey: 'typeId',
        searchable: true,
        alignment: aligment.right
      },
      {
        key: ActionTypeEnum.action,
        displayNameId: 'tbl_hdr_actions',
        displayName: this.captions.tbl_hdr_actions,
        templateName: ActionTypeEnum.action
      },
    ];
  }
  async createComponentsLinkingConfig(componentsLinkingConfig: ComponentsLinkingConfigUI): Promise<boolean> {

    return this.componentsLinkingConfigservice.createComponentsLinkingConfig(this.mapComponentsLinkingConfigUItoAPI(componentsLinkingConfig));


  }
  private mapComponentsLinkingConfigUItoAPI(uiModel: ComponentsLinkingConfigUI): ComponentsLinkingConfigAPI {
    let ApiModel: ComponentsLinkingConfigAPI = {

      id: 0,
      typeId: uiModel.typeId,
      description: uiModel.key,
      entityType: uiModel.value
    }
    return ApiModel;

  }
  updateComponentsLinkingConfig(componentsLinkingConfig: ComponentsLinkingConfigUI, id: number): Promise<boolean> {
    return this.componentsLinkingConfigservice.updateComponentsLinkingConfig(this.mapComponentsLinkingConfigUItoAPI(componentsLinkingConfig), id);

  }
  deleteComponentsLinkingConfig(id: number): Promise<boolean> {
    return this.componentsLinkingConfigservice.deleteComponentsLinkingConfig(id);

  }
  async getComponentsLinkingConfigByTypeId(typeId: number, performTypeIdValidation: boolean = true): Promise<boolean> {
    if(performTypeIdValidation){
      let existingModel: ComponentsLinkingConfigAPI = await this.componentsLinkingConfigservice.getComponentsLinkingConfigByTypeId(typeId);   
    return Promise.resolve((existingModel? existingModel.id: 0) > 0);

    }
    else{
      return Promise.resolve(false);
    }
    
 
  }
  async getTableValues(): Promise<ComponentsLinkingConfigUI[]> {
   
    let uiModel : ComponentsLinkingConfigUI[] = [];
    uiModel = this.mapComponentsLinkingConfigAPItoUI(await  this.componentsLinkingConfigservice.getComponentsLinkingConfig());
    return Promise.resolve(uiModel);
  }
  private mapComponentsLinkingConfigAPItoUI(apiModel: ComponentsLinkingConfigAPI[]) :ComponentsLinkingConfigUI[]{
    let uiModel:ComponentsLinkingConfigUI[] = [];
    apiModel.map(x => uiModel.push({id: x.id,  key: x.description, value: x.entityType, typeId: x.typeId}));
    return uiModel;

  }


}
