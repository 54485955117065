import { Pipe, PipeTransform } from '@angular/core';
import { SpaLocalization } from '../../core/localization/spa-localization';

@Pipe({
  name: 'loaddecimalvalue'
})
export class LoadDecimalValuePipe implements PipeTransform {
  constructor(public localization : SpaLocalization){}
  transform(val: string): any {
    let StrVal: string = val ? val.toString() : "";
        if (!StrVal.includes(this.localization.decimalSeparator)) {
          StrVal = StrVal + this.localization.decimalSeparator + "00"
        }
        return StrVal;
  }

}
