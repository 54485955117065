import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'lib-link-actions',
  templateUrl: './link-actions.component.html',
  styleUrls: ['./link-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkActionsComponent implements OnInit {
  params: any;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    this.params = params;
  }

  downloadClick(event: any) {
      this.params.context.parentComponent.rowActionButtonClick(this.params, this.params.data, this.params.EntityName.toLowerCase());
    }
  showErrorClick(event: any) {
    this.params.context.parentComponent.rowActionButtonClick(this.params, this.params.data, 'showError');
  }
}

