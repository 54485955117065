import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';

export const EditPOSupplierConfiguration: PostPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    Result: PostPOSupplierConfig,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId?: any,
    selectedSeqNo?: any): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig) {
            const POPostSuppliers = [];
            const DataForm = homeGroup;
            const postModelData = transactionService.constractTransactionHeaderPostModel(
                postModelHederEntity, postModelDetailEntity, TransactionCollection, DataForm, HttpMethods.Put, GlobalOdataModel);
            const copyPostModelData = JSON.parse(JSON.stringify((postModelData)));
            const PostHeadObject = JSON.parse(JSON.stringify(copyPostModelData));
            PostHeadObject['Id'] = parseInt(selectedId, 10);
            const SupplierItemList = PostHeadObject['PurchaseOrderDetails'];
            const SupplierPostObject = {
                'SupplierId': SupplierItemList[0].SupplierId,
                'PurchaseOrderHead': PostHeadObject,
                'IsValidEntry': true,
                'ClientURL': homeGroup.value.ClientURL
            };
            POPostSuppliers.push(SupplierPostObject);
            console.log('POPostSuppliers', POPostSuppliers);
            const postData = {
                POPostSuppliers: POPostSuppliers
            };
            const EndPointUrl = `/inventory/api/TransactionPurchaseOrder/${PostHeadObject['Id']}`;
            httpService[HttpMethods.Put](EndPointUrl, postData).subscribe(x => {
                console.log('After http call', x);
                if (TransactionCollection.AttachedFilesConfig) {
                    const fileAttachedData: Array<UploadMetaData> = TransactionCollection.AttachedFilesConfig.
                        AttachMentConfig.FileAttachedData;
                    if (fileAttachedData && fileAttachedData.length > 0) {
                        fileAttachedData.forEach(req => {
                            req.IsTransaction = true;
                            req.ItemId = selectedId;
                        });
                        FileData.FileDataPost(fileAttachedData, httpService, toastrService);
                    }
                }
                const ToastMsg = x && x.hasOwnProperty('Message') && x.Message ? x.Message :
                `${TransactionCollection.Name}(s) ${selectedId} Updated Successfully`;
                const message = {
                    // Message: `${TransactionCollection.Name}(s) ${selectedSeqNo} Updated Successfully`,
                    Message: ToastMsg,
                    Show: true,
                    AutoClose: true,
                    TimerCount: 20000
                };
                toastrService.success(message.Message, '', {
                    timeOut: 3000,
                    closeButton: true,
                });
                const transActionFrom = { queryParams: { transaction: selectedTransaction } };
                const transActionrouterUrl = `/settings/enhancedInventory/transaction/view-${selectedTransaction.toLowerCase()}`;
                routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred EditPOSupplierConfiguration', Error);
    }
};