import { TranslateService } from '@ngx-translate/core';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { BreadCrumbConfiguration, HeaderConfiguration, TransactionConfiguration } from '../../transaction.interface';
import { ViewTransactionConfiguration } from '../view-configuration.interface';

export const ViewHeaderActionButtonConfiguration: ViewTransactionConfiguration = (
ViewHeaderEntity: any, 
ViewDetailsEntity: any, 
TransactionCollection: TransactionConfiguration, 
httpService: HttpService,
translateService?: TranslateService): BreadCrumbConfiguration[] => {
    try {
        if (TransactionCollection) {
            const entityName = (ViewHeaderEntity && ViewHeaderEntity.length > 0 && ViewHeaderEntity[0]['$']['Name']) ? 
            ViewHeaderEntity[0]['$']['Name'].toString() : TransactionCollection.OdataKey;
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            const actionButton = [];
            if (
                TransactionCollection.ViewTranscationConfig && 
                TransactionCollection.ViewTranscationConfig.hasOwnProperty('HeaderConfiguration') && 
                TransactionCollection.ViewTranscationConfig.HeaderConfiguration.ActionButtonConfig.length > 0) {
                TransactionCollection.ViewTranscationConfig.HeaderConfiguration.ActionButtonConfig.forEach((x: AgilysysButtonModel) => {
                  const actionButtonConfiguration = {} as AgilysysButtonModel;
                  actionButtonConfiguration.buttonKey = x.buttonKey;
                  actionButtonConfiguration.buttonType = x.buttonType;
                  ProductDataRemap.FormDisplayNameRemapEngine(
                    productremapJson, entityName, x.displayName, translateService).then((data: any) =>
                     actionButtonConfiguration.displayName = data);
                  actionButtonConfiguration.displayProperity = x.displayProperity;
                  if (x.hasOwnProperty('icon')) {
                    actionButtonConfiguration.icon = x.hasOwnProperty('icon') ? x.icon : null;
                  }
                  actionButton.push(actionButtonConfiguration);
                });  
            }
            return actionButton; 
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

