import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { EformsBusiness } from 'src/app/common/data-magine/dm-eforms/dm-eforms.business';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { AgDateConfig, ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { DmConfig, DmDocument, Product } from 'src/app/common/Models/common.models';
import { Localization } from '../../localization/Localization';
import { CommonPropertyInformation } from '../../services/common-property-information.service';
import { ButtonType } from '../../shared.modal';
import { AlertType } from '../enums/enums';
import { CommonUtilities } from '../utilities/common-utilities';
import { SelectEFormsBusiness } from './select-e-forms.business';
import { GuestItineraryRequest } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
@Component({
  selector: 'app-select-e-forms',
  templateUrl: './select-e-forms.component.html',
  styleUrls: ['./select-e-forms.component.scss'],
  providers: [SelectEFormsBusiness, EformsBusiness], 
  encapsulation: ViewEncapsulation.None
})
export class SelectEFormsComponent implements OnInit {
  captions: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  fromDateInput: AgDateConfig;
  endDateInput: AgDateConfig;
  eFormGroup: UntypedFormGroup;
  headerOptions: TableHeaderOptions[];
  options: TableOptions;
  tableContent: any[];
  guestId: string; //guid
  selectedFromDate: string;
  selectedEndDate: string;
  dmConfig: DmConfig;
	dmResponse: any;
	dmDoc : DmDocument;
	token: string = '';
  appConfNo: string;
  golfConfNo: string;
  unitItems : any[];
  productId: Product;
  guestRequest : GuestItineraryRequest = { guestIdList : [],startDate: '',endDate: ''  };
  searchText: string='';
  DataMagineLogin : any;
  DMSubmittedEform: any;
  DMFetchEform: any;

  constructor(private localization: Localization, private fb: UntypedFormBuilder,
     private business: SelectEFormsBusiness,  private utils: CommonUtilities,
     private PropertyInfo: CommonPropertyInformation, private eformBusiness: EformsBusiness,
     private dmConfigDataService: DMConfigDataService,
    @Inject(MAT_DIALOG_DATA) data: any, private dialogRef: MatDialogRef<SelectEFormsComponent>) { 
       this.guestId = data.guestId;
       this.headerOptions = data.headerOptions;
       this.tableContent = data.tableData;
    }

  ngOnInit(): void {
    let _propertyDate = this.utils.convertDateFormat(this.PropertyInfo.CurrentDate);
    this.captions = this.localization.captions;
    this.buildForm(_propertyDate);
    this.selectedFromDate =  this.localization.convertDateObjToAPIdate(_propertyDate);
    this.selectedEndDate =  this.localization.convertDateObjToAPIdate(_propertyDate);
    this.productId =this.localization.GetPropertyInfo('ProductId');
    this.initialize();
    this.getTableData(); 
    this.getDmconfig();  
  }

  async getDmconfig()
	{
	  this.dmConfig = await this.dmConfigDataService.getDataMagineConfigSession();
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig")); 
    this.DataMagineLogin = propConfig?.DMServerLogin;
    this.DMSubmittedEform = propConfig?.DMSubmittedEform;
    this.DMFetchEform = propConfig?.DMFetchEform;
	}


  buildForm(_propertyDate) {
    
    this.eFormGroup = this.fb.group({
      fromDate: _propertyDate,
      endDate: _propertyDate
    })
  }

  initialize() {
    this.fromDateInput = {
      className: 'ag_form--control--33',
      form: this.eFormGroup,
      formControlName: 'fromDate',
      placeHolderId: 'lbl_fromDate',
      placeHolder: this.captions.lbl_fromDate,
      isDateRequired: false
    };
    this.endDateInput = {
      className: 'ag_form--control--33',
      form: this.eFormGroup,
      formControlName: 'endDate',
      placeHolderId: 'lbl_toDate',
      placeHolder: this.captions.lbl_toDate,
      isDisabled: false,
      isDateRequired: false
    };
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_view,
      disabledproperty: true
    }

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
  }

  getTableData() {
      this.options = this.business.getTableOptions();
      this.headerOptions = this.getHeaderOptions();
      //let guestRequest : GuestItineraryRequest = { guestIds : [],startDate: '',endDate: ''  };
      if (this.productId == Product.SPA) {
        this.business.getData(this.guestId, this.selectedFromDate, this.selectedEndDate).then(x=>{
          this.tableContent = x;
          this.unitItems = cloneDeep(x);
        }); 
      }
       else if(this.productId == Product.GOLF) {
        this.guestRequest.guestIdList.push(this.guestId);
        this.guestRequest.startDate = this.selectedFromDate;
        this.guestRequest.endDate = this.selectedEndDate;
        this.business.getGolfData(this.guestRequest).then(x=>{
        this.tableContent = x;
        this.unitItems = cloneDeep(x);
        });         
      }
     
    }

    getHeaderOptions() {
      let headerOptionsValue;
      switch(Number(this.productId)) {
        case Product.SPA:
          headerOptionsValue = this.business.headerGenerator();
          break;
        case Product.GOLF:
          headerOptionsValue = this.business.GetGolfHeaderOptions();
          break;
      }
      return headerOptionsValue;
    }
 
  searchValueChange(e) {
    if (e) {
      const filterValue = e.trim().toLowerCase();
      this.searchText = filterValue;
      if(this.productId == Product.SPA)
      {
        this.tableContent  = this.unitItems.filter(x => ((x.serviceName.toLowerCase()).includes(filterValue))
        || ((x.apptConfNo.toLowerCase()).includes(filterValue)));
      }
      else if(this.productId == Product.GOLF)
      {
        this.tableContent  = this.unitItems.filter(x => ((x.courseName.toLowerCase()).includes(filterValue))
        || ((x.confNo.toLowerCase()).includes(filterValue)));
      }
      
    } else {
      this.searchText ='';
      this.tableContent = this.unitItems;
    } 

  }

  tableAction(e) {
    if(e.selectedData)
    {
      this.saveButton.disabledproperty = false;
      this.appConfNo = e.selectedData[0].apptConfNo ?? "" ;
      this.golfConfNo =  e.selectedData[0].id ?? "" ;
    }
    

  }
  async onSave(e) {

    if(this.productId == Product.SPA)
    {
      let appId = this.appConfNo;
      this.dmDoc = {
        PCname: "V1PC",
        Viewname: "SPA INTAKE",
        Indexvaluelist: [{
          Name: "Appointment Number",
          Operator: "eq",
          Value: appId,
          Connector: "And"
        }],
        Pagesize: 500,
        Pagenum: 1,
        DateDelimiter: ""
        };
    }
    else if(this.productId == Product.GOLF)
    {
      let playerId =  this.golfConfNo;
      this.dmDoc = {
        PCname: "V1PC",
        Viewname: "GOLF EFORMS",
        Indexvaluelist: [
          {
          Name: "Player Id",
          Operator: "eq",
          Value: playerId,
          Connector: "And"
        }
      ],
        Pagesize: 500,
        Pagenum: 1,
        DateDelimiter: ""
        };
    }
    let tkn:any;
    if(this.DataMagineLogin)
    {
      tkn = await this.eformBusiness.getDataMagineLoginToken(this.dmConfig.serverURL, this.dmConfig.userName, this.dmConfig.password);
    }
    else{
      tkn = await this.eformBusiness.getDMLoginToken(this.dmConfig.serverURL, this.dmConfig.userName, this.dmConfig.password);
    }
        if (tkn && tkn?.Result?.access_token) {
          this.token = tkn?.Result?.access_token;
          let rs: any;
          if(this.DMSubmittedEform)
          {
            rs = await this.eformBusiness.getDMSubmittedEforms(this.DMSubmittedEform, this.token, this.dmDoc);
          }
          else{
            rs = await this.eformBusiness.getSubmittedEforms(this.dmConfig.serverURL, this.token, this.dmDoc);
          }
		  if(rs.Result.length > 0)
		  {		
      rs.Result.forEach(async element => {
      let folderId = element.FolderNumber;
      let docId = element.DocumentNumber;
      let result: any;
      if(this.DMFetchEform)
      {
        result = await this.eformBusiness.getDMSubmittedEformsById(this.DMFetchEform, this.token, folderId, docId);
      }
      else
      {
         result = await this.eformBusiness.getSubmittedEformsById(this.dmConfig.serverURL, this.token, folderId, docId);
      }
      });
		  }
      else{
        const message = this.captions.NoEformSUbmitted;
        this.utils.showAlert(message, AlertType.Info, ButtonType.Ok);
      }

		}

  }

  fromDateChange(e) {
    if(e)
    {
      this.selectedFromDate = this.localization.convertDateObjToAPIdate(this.utils.convertDateFormat(e[0].value.fromDate));
      let startDate = this.utils.getDate(this.selectedFromDate);
      let endDate =  this.utils.getDate(this.selectedEndDate);
      if(this.selectedEndDate && endDate < startDate)
      {
        this.eFormGroup.controls['endDate'].setValue(this.utils.convertDateFormat(e[0].value.fromDate));
        this.selectedEndDate = this.selectedFromDate;
        if (this.productId == Product.SPA)
        {
          this.business.getData(this.guestId, this.selectedFromDate, this.selectedEndDate).then(x=>{
            this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.serviceName.toLowerCase()).includes(this.searchText))
          || ((x.apptConfNo.toLowerCase()).includes(this.searchText)));
          });
        }
        else if (this.productId == Product.GOLF)
        {
          this.guestRequest.guestIdList.push(this.guestId);
          this.guestRequest.startDate = this.selectedFromDate;
          this.guestRequest.endDate = this.selectedEndDate;
          this.business.getGolfData(this.guestRequest).then(x=>{
          this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.courseName.toLowerCase()).includes(this.searchText))
        || ((x.confNo.toLowerCase()).includes(this.searchText)));
          });
        }     
      }
      else
      {
        if (this.productId == Product.SPA)
        {
          this.business.getData(this.guestId, this.selectedFromDate, this.selectedEndDate).then(x=>{
            this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.serviceName.toLowerCase()).includes(this.searchText))
          || ((x.apptConfNo.toLowerCase()).includes(this.searchText)));
          });
        }
        else if (this.productId == Product.GOLF)
        {
          this.guestRequest.guestIdList.push(this.guestId);
          this.guestRequest.startDate = this.selectedFromDate;
          this.guestRequest.endDate = this.selectedEndDate;
          this.business.getGolfData(this.guestRequest).then(x=>{
          this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.courseName.toLowerCase()).includes(this.searchText))
        || ((x.confNo.toLowerCase()).includes(this.searchText)));
          });
        }     
      }

    }

  }

  endDateChange(e) {
    if(e)
    {
      this.selectedEndDate =  this.localization.convertDateObjToAPIdate(this.utils.convertDateFormat(e[0].value.endDate));
      let startDate = this.utils.getDate(this.selectedFromDate);
      let endDate =  this.utils.getDate(this.selectedEndDate);
      if(this.selectedFromDate && endDate < startDate)
      {
        this.eFormGroup.controls['fromDate'].setValue(this.utils.convertDateFormat(e[0].value.endDate));
        this.selectedFromDate = this.selectedEndDate;
        if (this.productId == Product.SPA)
        {
          this.business.getData(this.guestId, this.selectedFromDate, this.selectedEndDate).then(x=>{
            this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.serviceName.toLowerCase()).includes(this.searchText))
          || ((x.apptConfNo.toLowerCase()).includes(this.searchText)));
          });
        }
        else if (this.productId == Product.GOLF)
        {
          this.guestRequest.guestIdList.push(this.guestId);
          this.guestRequest.startDate = this.selectedFromDate;
          this.guestRequest.endDate = this.selectedEndDate;
          this.business.getGolfData(this.guestRequest).then(x=>{
          this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.courseName.toLowerCase()).includes(this.searchText))
        || ((x.confNo.toLowerCase()).includes(this.searchText)));
          });
        }     
      }
      else
      {
        if (this.productId == Product.SPA)
        {
          this.business.getData(this.guestId, this.selectedFromDate, this.selectedEndDate).then(x=>{
            this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.serviceName.toLowerCase()).includes(this.searchText))
          || ((x.apptConfNo.toLowerCase()).includes(this.searchText)));
          });
        }
        else if (this.productId == Product.GOLF)
        {
          this.guestRequest.guestIdList.push(this.guestId);
          this.guestRequest.startDate = this.selectedFromDate;
          this.guestRequest.endDate = this.selectedEndDate;
          this.business.getGolfData(this.guestRequest).then(x=>{
          this.tableContent = x;
          this.unitItems = cloneDeep(x);
          this.tableContent  = this.unitItems.filter(x => ((x.courseName.toLowerCase()).includes(this.searchText))
          || ((x.confNo.toLowerCase()).includes(this.searchText)));
          });
        }     
      }
    }
  }

  onCancel(e) {
    this.dialogRef.close();
  }

  close(e) {
    this.dialogRef.close();
  }

}
