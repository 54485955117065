import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ActionConfiguaration, CustomFilterQuery, GridCustomColExpand, TransactionConfiguration } from './transaction.interface';
import { ViewEndPointConfiguration } from './grid-transaction/grid-transaction-config.interface';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FilterTypes } from '../enum/transaction-enum';
import { FieldEdmType } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import moment from 'moment';
import * as constants from '@shared/constant/GlobalConstants';

export const GetListEndPointUrl: ViewEndPointConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    parms: QueryParams,
    TypeId?: number,
    translateService?: TranslateService, TilesHeaderTrascationEntity?: any): any => {
    try {
        if (ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
            if (TransactionCollection.hasOwnProperty('ActionConfig')
                && TransactionCollection.ActionConfig.length > 0) {
                    return getEndPointUrl(
                        ViewHeaderEntity, ViewDetailsEntity, TransactionCollection,
                        httpService, parms, translateService, TilesHeaderTrascationEntity);            }
          
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function getEndPointUrl(
    ViewHeaderEntity: any, ViewDetailsEntity: any, TransactionCollection: TransactionConfiguration,
    httpService: HttpService, Params: QueryParams, translateService: TranslateService, TilesHeaderTrascationEntity: any) {
    try {
        if (ViewHeaderEntity.length > 0 && TransactionCollection) {
            let endPointUrl = ViewHeaderEntity[0]['$']['Name'];
            const navigationProperity = NavigationProperty(ViewHeaderEntity, TransactionCollection);
            if (navigationProperity && navigationProperity.length > 0) {
                Params.expand = (Params.expand ? Params.expand + ',' : '')  + navigationProperity.join();
                const getQueryParams = formQueryParams(Params);
                endPointUrl = `/inventory/api/${endPointUrl}` + getQueryParams;
            } else {
                const getQueryParams = formQueryParams(Params);
                endPointUrl = endPointUrl + getQueryParams;
            }
            return endPointUrl;
        }
    } catch (error) {

    }
}
function NavigationProperty(ViewModelEntity: any, TransactionCollection: TransactionConfiguration) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                const NavigationArray = JSON.parse(JSON.stringify(ViewModelEntity[0]['NavigationProperty']));
                if (TransactionCollection && TransactionCollection.hasOwnProperty('ActionConfig')) {
                    TransactionCollection.ActionConfig.forEach((actionConfig: ActionConfiguaration) => {
                      if (actionConfig.hasOwnProperty('CustomNavigation')) {
                        NavigationArray.forEach((properityNav: any, i: number) => {
                           const getIndex = actionConfig.CustomNavigation.findIndex(customNav => customNav === properityNav['$'].Name);
                           if (getIndex > -1) {
                            NavigationArray.splice(i, 1);
                           }
                        });                 
                      }
                    });
                }
                NavigationArray.forEach(element => {
                    const navigationName = element['$'].Name;
                    const referentialRelationId = element['ReferentialConstraint'] && element['ReferentialConstraint'][0]['$'].Property
                        ? element['ReferentialConstraint'][0]['$'].Property : '';
                    if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                        const properityIndex = ViewModelEntity[0]['Property']
                            .findIndex(x => x['$'].Name === referentialRelationId);
                        if (properityIndex > -1) {
                            let expandQuery = '($select=id,name)';
                            if (TransactionCollection &&
                                TransactionCollection.hasOwnProperty('ActionConfig') &&
                                TransactionCollection.ActionConfig.hasOwnProperty('GridConfiguration') &&
                                TransactionCollection.ViewTranscationConfig.GridConfiguration.GridCustomExpand.length > 0) {
                                TransactionCollection.ViewTranscationConfig.GridConfiguration.GridCustomExpand.
                                    forEach((GridCustomExpandKey: GridCustomColExpand) => {
                                        if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand && GridCustomExpandKey.IsCustomExpand
                                            && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                            const expandCode = GridCustomExpandKey.SelectQuery;
                                            if (expandCode !== null) {
                                                expandQuery = `($select=id,${expandCode})`;
                                            } else {
                                                expandQuery = '';
                                            }
                                        }
                                    });
                            }
                            // if (expandQuery) {
                                const properityString = `${navigationName}` + `${expandQuery}`;
                                navigationPrperity.push(properityString);
                            // }
                        } else {
                            if (navigationName !== 'TransactionPictures') {
                                const properityString = `${navigationName}`;
                                navigationPrperity.push(properityString);
                            }
                        }
                    }
                });
                return navigationPrperity;
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }
                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function getFilterQuery(CustomFilterQuerys: Array<CustomFilterQuery>) {
    if (CustomFilterQuerys.length > 0) {
        const queryList = [];
        CustomFilterQuerys.forEach(querys => {
            let querystring = '';
            switch (querys.Type) {
                case FilterTypes.Multiple: {
                    let value = '';
                    if (querys.CustomOdataUrlName) {
                        value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                        querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                    } else if (querys.Name === querys.OdataKey) {
                        value = querys.DefaultValue.join();
                        querystring = querys.OdataKey + ' in (' + value + ')';
                    } else {
                        value = querys.DefaultValue.join();
                        querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                    }                    
                    break;
                }
                case FilterTypes.GreaterThanEqual: {
                    if (querys.FieldType === FieldEdmType.EDMDATEOFFSET) {
                        // const date = new Date();
                        // Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
                        //   date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                        // const utcdate = moment.utc().format();
                        // querystring = querys.OdataKey + ' ge ' + utcdate;
                        querystring = querys.OdataKey + ' ge ' + moment().startOf('day').format(constants.PostDateTimeFormat);
                    } else {
                        querystring = querys.OdataKey + ' ge ' + querys.DefaultValue;
                    }
                    break;
                }
                case FilterTypes.Equal: {
                    querystring = querys.OdataKey + ' eq ' + querys.DefaultValue;
                    break;
                }
                case FilterTypes.NotEqual: {
                    // querystring = 'not(' + query.OdataKey + ' eq ' + query.DefaultValue + ')';
                    querystring = querys.OdataKey + ' eq ' + querys.DefaultValue;
                    break;
                }
            }
            queryList.push(querystring);
        });
        const returnData = queryList.join(' and ');
        return returnData;
    }
}