import { Injectable } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ConfigData, QuickIdConfigSetting } from './quickid-config.model';
import { QuickIdConfigService } from './quickid-config.service';

@Injectable()

export class QuickIdConfigBusiness 
{
    captions: any;
    testMode: boolean = false;
    deviceNames: Array<any>;
    enablePaymentDeviceSelect: boolean = false;
    showPaymentDevice: boolean;
    paymentDevices: Array<any>;
    userSessionConfiguration: any;
    quickIdConfig = [
        QuickIdConfigSetting.appointmentBook,
        QuickIdConfigSetting.appointmentEdit,
        QuickIdConfigSetting.appointmentMove,
        QuickIdConfigSetting.appointmentCancel,
        QuickIdConfigSetting.appointmentCopy,
        QuickIdConfigSetting.appointmentReinstate,
        QuickIdConfigSetting.therapistBreakCreate,
        QuickIdConfigSetting.therapistBreakEdit,
        QuickIdConfigSetting.therapistBreakDelete,
        QuickIdConfigSetting.therapistBreakMove,
        QuickIdConfigSetting.retailtransactions
    ] as string[];

    constructor(
        private quickIdConfigService: QuickIdConfigService,
        private localization: SpaLocalization
    ) 
    {
        this.captions =  this.localization.captions.settings.utilities;         
    }


    public async GetQuickIdConfiguration(): Promise<ConfigData[]> {    
        let result = await this.quickIdConfigService.GetQuickIdConfigSettings();
        return this.mapToUI(result);
    }

    public async GetSettingByModule(): Promise<any> {    
        let result = await this.quickIdConfigService.GetAllSettingbyModule("QuickIdConfig");
        console.log(result);        
        return result;
    }

    public async UpdateQuickIdConfigSettings(data): Promise<ConfigData[]> {    
        let result = await this.quickIdConfigService.UpdateQuickIdConfigSettings(data);
        return this.mapToUI(result);
    }

    private mapToUI(configData: ConfigData[]): ConfigData[]{
        let result = [];
        this.quickIdConfig.forEach(val => {
            const configValue = configData.find(x=> x.switch === val);
            if(configValue) {
                result = result.concat({
                    id: configValue.id,
                    isActive: configValue.isActive,
                    moduleId: configValue.moduleId,
                    switch: configValue.switch,
                    switchType: configValue.switchType,
                    value: (configValue.value.toLowerCase() === 'true')
                } as ConfigData)
            }
        });
        return result;
    }

}