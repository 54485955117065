import { PlanHeadConstruction } from './planhead-data.interface';
import { PlanHeadButcheryConstruction } from './planheadButchery-data.interface';
import { SalesForecastingConstruction } from './salesForecasting-data.interface';
import { CyclePlanningConstruction } from './cyclePlanning-data.interface';
import { ButcheryConstruction } from './butchery-data.interface';
import { DataImportSummaryConstruction } from './data-import-summary-data.interface';
import { DataImportUploadConstruction } from './data-import-upload-data.interface';
import { DataImportWexFlowsModuleConstruction } from './data-import-wexflows-module-data.interface';
import { ValidationSummaryDownload } from './validation-summary-download.interface';
import { LoadSalesHistoryDataConstruction } from './load-sales-history-data.interface';
import { ScheduleReportDataConstruction } from './schedule-report-data.interface';
import { EditScheduleReportDataConstruction } from './edit-schedule-report-data.interface';
import { SaveScheduleReportDataConstruction } from './save-schedule-report-data.interface';
import { LoadVendorPunchOutConstruction } from './load-vendor-punchout-contruct.interface';
import { RequisitionTemplateDataCondtruction } from './requisitionTemplate-data-construction.interface';
import { ProductionPlanConstruction } from './productionPlan-data.interface';
import { GetAllAccountActivity } from './account-activity-getall.interface';
import { GetPayloadConstract } from './get-payload-contract.interface';
import { MRPConstruction } from './mrp-data.interface';
import { CreateSetUpData } from './create-setup-data.interface';

export const DefineModuleBasedObject = {
    PlanHead: PlanHeadConstruction,
    PlanHeadBudchery: PlanHeadButcheryConstruction,
    SalesForecasting: SalesForecastingConstruction,
    CyclePlanning: CyclePlanningConstruction,
    ProductionPlan: ProductionPlanConstruction,
    Butchery: ButcheryConstruction,
    DataImportSummary: DataImportSummaryConstruction,
    DataImportUpload: DataImportUploadConstruction,
    DataImportWexFlowsModule: DataImportWexFlowsModuleConstruction,
    ValidationSummaryDownload: ValidationSummaryDownload,
    LoadSalesHistory: LoadSalesHistoryDataConstruction,
    VendorPunchOutGet: LoadVendorPunchOutConstruction,
    ScheduleReport: ScheduleReportDataConstruction,
    EditScheduleReport: EditScheduleReportDataConstruction,
    SaveScheduleReport: SaveScheduleReportDataConstruction,
    ReqTemplate: RequisitionTemplateDataCondtruction,
    AccountActivityGet: GetAllAccountActivity,
    MRP: MRPConstruction,
    GetPayload: GetPayloadConstract,
    CreateSetUp: CreateSetUpData
};