import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {cloneDeep} from 'lodash'
import { Localization } from '../../localization/localization';

@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class CommonFilterComponent implements OnInit {

  getFilterInputs: any= [];
  filterData: any= [];
  filteredValues : any = {}
  defaultData: any;
  captions: any;
  @Output() emitData = new EventEmitter();
  @Input() inputData : any =[];
  @Output() searchEmit = new EventEmitter();
  from : string;

  @Input('filterInput')
  set configValues(value) {
    if(value){
      this.getFilterInputs = value;
      this.setForm();
    }
  }
  @Input('from')
  set fromValue(value: string) {
    this.from = value;
  }
  constructor(private fb: UntypedFormBuilder, private localization : Localization) { 
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.defaultData = {
      id: 0,
      value: 0,
      viewValue: this.captions.lbl_all
    };
  }
  setForm(){
    this.getFilterInputs.forEach(name => {
      this.filteredValues[name.formControlName] = '';
    });
    this.filterData = cloneDeep(this.getFilterInputs);
  }
  applyFilter(eve){
    let filteredData = [];
    filteredData = this.inputData.filter(item =>{
     return Object.keys(eve).every(col => {
        if (eve[col].length === 0) return true; // No filter applied
        if (Array.isArray(eve[col])) {
          const selectedValues = eve[col] as string[];
          return selectedValues.some(selectedValue => {
              return item[col]?.toString()  == (selectedValue.toString())
          });
        } 
      });
    });
    this.emitData.emit(filteredData);
  }
  checkAllForsearch(eve, options, key) {
    if(eve.checked){
      let obj=[];
      options.forEach(k => {
        obj.push(k.viewValue);
      });
      this.filteredValues[key] = obj;
    } else {
      this.filteredValues[key] = [];
    }
    this.applyFilter(this.filteredValues);
  }
  searchValueChange(e){
    this.searchEmit.emit(e)
  }
}
