import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import { ReportBusinessService } from '../report-business.service';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailFunctionalities } from 'src/app/retail/shared/globalsContant';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';

export class SalesMix extends BaseReport {	
	functionalities: { [key: string]: boolean} = {};
	constructor(
		public reportFormGrp: UntypedFormGroup,
		public data: ReportDataService,
		public business: ReportBusinessService,
		public utils: RetailUtilities,
		private func: RetailFunctionalityBusiness,
		private propertyInfo: RetailPropertyInformation,
        public retailLocalization : RetailLocalization
	) {
		super(reportFormGrp);
	}

	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: AllReports.SalesMix,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		}; 
	}

	getReportUIConfig(): ReportUIConfig {
		return {
			startDatePicker: true,
			endDatePicker: true,
			timePicker: false,
			dropDownFilters: this.data.getAllOutletsByUser(),
			dropDownFilterName: this.localization.captions.reports.Outlets,
			inActiveToggle: false,
			pageBreakToggle: false,
			layout: 'PORTRAIT',
			allowFutureDate: false
		}; 
	}

	protected formReportParams(): ReportParams[] {
		this.functionalities = this.func.getFunctionality();
		let toApi = this.localization.convertDateObjToAPIdate;

		let salesMixReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
		let userCaption: string = this.business.getFilterCaption(
			this.business.FilterDataSource['allUsers'],
			this.fillFilter(salesMixReportFilterData.users.filter((u) => u.showInDropDown))
		);
		return [
			{ pStartDate: toApi(this.startDate) },
			{ pEndDate: toApi(this.endDate) },			
			{ pPropertyName: this.propertyName },
			{ pUsers: userCaption },
			{ pIncludeInactiveUsers: salesMixReportFilterData.includeInactiveusers},
			{ pIncludeReturns: salesMixReportFilterData.toggleIncludeReturnWithoutTicket},
			{ pOutlets: this.functionalities[RetailFunctionalities.ShowOutletSelectionFieldInSalesMixReport] ? this.getFilterCaption : this.getDefaultOutletCaption },
			{ pDate: this.printedDate },			
			{pPaymentMethod:this.localization.captions.reports.RetailInterfacePayment}
		];
	}

	protected formURIParams(): ReportParams[] {
		return [{ StartDate: this.localization.convertDateObjToAPIdate(this.startDate) },
			{ EndDate: this.localization.convertDateObjToAPIdate(this.endDate) }];
	}

	protected formFilters() {
		this.functionalities = this.func.getFunctionality();
		let toApi = this.localization.convertDateObjToAPIdate;
		let salesMixReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
		const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
		const tempOutletId = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
		if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
				StartDate: this.localization.convertDateObjToAPIdate(this.startDate),
			EndDate: this.localization.convertDateObjToAPIdate(this.endDate),
			OutletIds: this.functionalities[RetailFunctionalities.ShowOutletSelectionFieldInSalesMixReport] ? 
			 this.fillFilter(this.selectedFilterOptions): tempOutletId,
			UserIds: this.fillFilter(salesMixReportFilterData.users.filter((u) => u.showInDropDown)),
			IncludeReturnWithoutTicket:salesMixReportFilterData.toggleIncludeReturnWithoutTicket,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            };
        }
		return {
			StartDate: this.localization.convertDateObjToAPIdate(this.startDate),
			EndDate: this.localization.convertDateObjToAPIdate(this.endDate),
			OutletIds: this.functionalities[RetailFunctionalities.ShowOutletSelectionFieldInSalesMixReport] ? 
			 this.fillFilter(this.selectedFilterOptions): tempOutletId,
			UserIds: this.fillFilter(salesMixReportFilterData.users.filter((u) => u.showInDropDown)),
			IncludeReturnWithoutTicket:salesMixReportFilterData.toggleIncludeReturnWithoutTicket
		};
	}
	fillFilter(customFilterData: any): number[] {
		return customFilterData.length === 0 ? [] : customFilterData.map((x) => x.id);
	}
}
