import { Injectable } from "@angular/core";
import { SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import * as GlobalConst from '../../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import { BaseResponse, RentalItemOutOfOrderDetails } from 'src/app/retail/shared/shared.modal';
@Injectable()
export class RentalItemControlBusiness {

    captions;
  outOfOrderTableContent : RentalItemOutOfOrderDetails[];

    constructor(
        public localization: RetailLocalization, private http: HttpServiceCall) {
          this.captions = this.localization.captions
    }

    getInuseHeaderOptions(){
        return[
          {
            key: 'itemNumber',
            displayName: this.captions.lbl_item_number,
            displayNameId: 'itemNumber',
            sorting: true,
          },
          {
            key: 'itemName',
            displayName: this.captions.lbl_itemName,
            displayNameId: 'lbl_itemName',
            sorting: true,
          },
          {
            key: 'categoryName',
            displayName: this.localization.captions.retailsetup.CategoryName,
            displayNameId: 'CategoryName',
            sorting: true,
          },
          {
            key: 'inUseQuantity',
            displayName: this.captions.retailsetup.quantity,
            displayNameId: 'quantity',
            sorting: true,
          },
          {
            key: 'scheduledTeeTimePlayerName',
            displayName: this.captions.lbl_player,
            displayNameId: 'scheduledTeeTimePlayerName',
            sorting: true,
          },
          {
            key: 'teeTime',
            displayName: this.captions.lbl_teetime,
            displayNameId: 'teeTime',
            sorting: true,
          },{
            key: 'courseName',
            displayName: this.captions.lbl_courses,
            displayNameId: 'courseName',
            sorting: true,
          },
          {
            key: 'duration',
            displayName: this.captions.retailsetup.duration,
            displayNameId: 'duration',
            sorting: true,
          },
          {
            key: 'comments',
            displayName: this.captions.lbl_comments,
            displayNameId: 'comments',
            sorting: true,
          },
        ]
      }
      
      getOutOfOrderHeaderOptions(){
        return[
          {
            key: 'itemNumber',
            displayName: this.captions.lbl_item_number,
            displayNameId: 'itemNumber',
            sorting: true,
          },
            {
              key: 'itemName',
              displayName: this.captions.lbl_itemName,
              displayNameId: 'rentalItemName',
              sorting: true,
            },
            {
              key: 'categoryName',
              displayName: this.localization.captions.retailsetup.CategoryName,
              displayNameId: 'CategoryName',
              sorting: true,
            },
            {
              key: 'totalQuantity',
              displayName: this.captions.retailsetup.quantity,
              displayNameId: 'quantity',
              sorting: true,
            },
            {
              key: 'outofOrderQuantity',
              displayName: this.captions.lbl_outOfOrder,
              displayNameId: 'outoforder',
              sorting: true,
            },
            {
              key: 'remainingQuantity',
              displayName: this.captions.remaining,
              displayNameId: 'remaining',
              sorting: true,
            },
          ]
      }

      getTableOptions() {
        return {
          defaultSortOrder: SorTypeEnum.asc,
          defaultsortingColoumnKey: 'itemNumber',
          uniqueKey: 'itemNumber',
        }
      }
      
}