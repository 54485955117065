import { GridColumnModels } from '../grid-header-models/index';
import { GridHeaderConfig } from '../grid-data.interface';
import { GridHeaderConfigs } from '../grid.interface';
import { ColumnDetails, GridAppConfig } from '../app-grid.interface';
import { SubGroupListing } from 'src/app/eatecui/source/setup/shared/interface/setup-links.interface';
import { ReplaceTextWithIcon } from '../../constant/GlobalConstants';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

export const GridHeaderColumn: GridHeaderConfigs = async (ResponseData: Array<any>, DeviceName: string,
  GridActionConfig: GridAppConfig, MenuObject: SubGroupListing, translateService ?: TranslateService,
  httpClient?: HttpClient): Promise<GridHeaderConfig[]> => {
  const GridColumns: Array<GridHeaderConfig> = [];
  if (ResponseData && ResponseData.length > 0) {
    const ColumnHide: ColumnDetails[] = GridActionConfig.GridColumnHide[0][DeviceName];
    const ResponseKeys = Object.keys(ResponseData[0]);
    ResponseKeys.forEach(headerConfig => {
      const show: ColumnDetails[] = ColumnHide.filter(x => x.ColumnName === headerConfig);
      if (show.length === 0) {
        const rowHeaderColumn = {} as GridHeaderConfig;
        rowHeaderColumn.headerName = GridColumnModels.HeaderName(headerConfig, MenuObject.OdataKey, translateService);
        rowHeaderColumn.field = GridColumnModels.Field(headerConfig);
        rowHeaderColumn.hide = GridColumnModels.Hide(headerConfig);
        rowHeaderColumn.sortOrder = GridColumnModels.SortOder(headerConfig);
        rowHeaderColumn.sortable = GridColumnModels.Sortable(headerConfig);
        rowHeaderColumn.customSort = GridColumnModels.CustomSort(headerConfig, 'SetUp');
        rowHeaderColumn.suppressMovable = GridColumnModels.SuppressMovable(headerConfig);
        rowHeaderColumn.sortDirection = GridColumnModels.SortDirection(headerConfig, GridActionConfig);
        rowHeaderColumn.type = GridColumnModels.Type(headerConfig, GridActionConfig);
        rowHeaderColumn.valueFormatter = GridColumnModels.ValueFormatter(rowHeaderColumn.type);
        rowHeaderColumn.cellRenderer = GridColumnModels.CellRenderer(rowHeaderColumn, ReplaceTextWithIcon);
        rowHeaderColumn.resizable = GridColumnModels.ColumnResizable(rowHeaderColumn);
        rowHeaderColumn.minWidth = '75';
        rowHeaderColumn.tooltipField = rowHeaderColumn.field;
        const ReplacableObject = ReplaceTextWithIcon.filter(x => x.OdataKey.toLowerCase() ===
          rowHeaderColumn.field.toLowerCase())[0];
        if (ReplacableObject) {
          rowHeaderColumn.width = ReplacableObject.Width;
        }
        GridColumns.push(rowHeaderColumn);
      }
    });
  }
  const RearrangedHeaderDefination = await rearrangeHeaderColumn(GridColumns, httpClient, GridActionConfig.GridKey);
  return RearrangedHeaderDefination;
};

async function rearrangeHeaderColumn(HeaderDefination, httpClient: HttpClient, odataKey: any) {
  const IsActiveIndex = HeaderDefination.findIndex(x => x.field.toLowerCase() === 'isactive');
  const IsActiveObject = HeaderDefination.filter(x => x.field.toLowerCase() === 'isactive')[0];
  if (IsActiveIndex > -1) {
    HeaderDefination.splice(IsActiveIndex, 1);
    HeaderDefination.splice(0, 0, IsActiveObject);
  }
  const RetailProductJson = await httpClient.get<any>('app/eatecui/assets/json/ui-control/pre-order-gridcolumn.json').toPromise();
    let reorderHeaderDefination = HeaderDefination;    
    const columnOrder = RetailProductJson;
    columnOrder.forEach((columnorder: any, orderIndex: number) => {
        const headerINdex = HeaderDefination.findIndex(x => x.field === columnorder.Key && columnorder.Odatakey === odataKey);
        if ( headerINdex > -1 ) {
            reorderHeaderDefination = array_move(HeaderDefination, headerINdex, columnorder.Order);
        }
    });
  return HeaderDefination;
}

/**
 * @method array_move
 * @params headerDefinition, old_index, new_index
 * @returns sorted header fields
 */
function array_move(arr: any, old_index: number, new_index: number) {
  try {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
  } catch (error) {
    console.log(error);
  }
  
}
