import { Action } from '@ngrx/store';

export enum EGlobalState {
    GetMenuExpand = '[GetMenuExpand] Get GetMenuExpand',
    GetIsLoggedIn = '[GetIsLoggedIn] Get GetIsLoggedIn',
    GetAccessToken = '[GetAccessToken] Get GetAccessToken',
    GetTenant = '[GetTenant] Get GetTenant',
    GetOnPrem = '[GetOnPrem] Get GetOnPrem',
    IsOutSideClick = '[IsOutSideClick] IsOutSideClick'
}

export class GetMenuExpand implements Action {
    readonly type = EGlobalState.GetMenuExpand;
    constructor(public payload: any) { }
}

export class GetIsLoggedIn implements Action {
    readonly type = EGlobalState.GetIsLoggedIn;
    constructor(public payload: boolean) { }
}

export class GetAccessToken implements Action {
    readonly type = EGlobalState.GetAccessToken;
    constructor(public payload: string) { }
}

export class GetTenant implements Action {
    readonly type = EGlobalState.GetTenant;
    constructor(public payload: string) { }
}

export class GetOnPrem implements Action {
    readonly type = EGlobalState.GetOnPrem;
    constructor(public payload: boolean) { }
}
export class OutSideClickAction implements Action {
    readonly type = EGlobalState.IsOutSideClick;
    constructor(public payload: boolean) { }
}

export type GlobalStateAction = GetMenuExpand | GetIsLoggedIn | GetAccessToken | GetTenant | GetOnPrem | OutSideClickAction;