import { Injectable } from '@angular/core';
import { HttpServiceCall, HttpMethod } from 'src/app/retail/shared/service/http-call.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { BaseResponse } from 'src/app/retail/shared/business/shared.modals';
import { Host } from 'src/app/retail/shared/globalsContant';
import * as _ from 'lodash';
import { FailedTransactions } from '../../shop/view-categories/retail-transactions-log/retail-transactions-log';


@Injectable({ providedIn: 'root' })
export class TransactionDataService {

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization) { }

    private showError(errorCode: number) {
        const errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage('Error', errMsg);
    }

    private async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any): Promise<T> {
        const response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: host,
            method: callType,
            body: body,
            uriParams: uRIParams,
            showError: true
        });
        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    public async BatchProcessFailedTransactions(isProcessAll: boolean, body: number[]): Promise<boolean> {
        try {
            return await this.InvokeServiceCall('BatchProcessFailedTransactions', HttpMethod.Post, Host.payment, body, { processAll: isProcessAll });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async getRevenuePostingCount(): Promise<number> {
        return await this.InvokeServiceCall<number>('GetFailureDetails', HttpMethod.Get, Host.payment);
    }

    // public async getFailedTransaction(serachText :string,startDate: Date,endDate: Date,
    //     pageStart:number): Promise<FailedTransactions[]> {
    //     return await this.InvokeServiceCall<FailedTransactions[]>('GetFailedTransactions',
    //      HttpMethod.Get, Host.payment, { searchText: serachText?serachText:'',startDate: this.localization.ConvertDateToISODateTime(startDate),
    //      endDate: this.localization.ConvertDateToISODateTime(endDate),pageStart:pageStart});
    // }
}
