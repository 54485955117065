import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfferRankData, CheckboxList, RadioList } from './offer-rank-dialog.model';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { cloneDeep, difference } from 'lodash';
import { RetailUtilities } from '../utilities/retail-utilities';
import { AlertType } from '../shared.modal';
import { ButtonType } from '../globalsContant';
import { UserAccessBreakPoints } from '../constants/useraccess.constants';

@Component({
  selector: 'app-offer-rank-dialog',
  templateUrl: './offer-rank-dialog.component.html',
  styleUrls: ['./offer-rank-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferRankDialogComponent implements OnInit {
  captions: any;
  dataList: CheckboxList[] = [];
  leftList: CheckboxList[] = [];
  rightList: CheckboxList[] = [];
  selectedList: CheckboxList[] = [];
  initialRightList: CheckboxList[] = [];
  removedList: CheckboxList[] = [];
  selectedRadio: RadioList;
  isDisabled: boolean = true;
  lbl_leftCheckBoxListTitle: string;
  lbl_rightCheckBoxListTitle: string;

  constructor(
    public localization: RetailLocalization,
    private utils: RetailUtilities,
    public dialogRef: MatDialogRef<OfferRankDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OfferRankData) {
    this.captions = this.localization.captions;
    this.dataList = cloneDeep(this.data.dataList);
    this.selectedRadio = this.data.radioList.find(x => x.checked);
  }

  ngOnInit(): void {
    this.dataListBinding();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(from) {
    this.removedList = this.initialRightList.filter(x => { return (this.rightList.filter(y => y.viewValue == x.viewValue).length <= 0) });

    this.dialogRef.close({
      from,
      data: {
        radioType: this.selectedRadio,
        selectedList: this.rightList,
        removedList: this.removedList
      }
    });
  }

  radioChange(list) {
    this.selectedRadio = list;
    this.dataListBinding();
  }

  dataListBinding() {
    this.lbl_leftCheckBoxListTitle = this.localization.replacePlaceholders(this.captions.lbl_leftCheckBoxListTitle, ['filterType'], [this.selectedRadio.viewValue]);
    this.lbl_rightCheckBoxListTitle = this.localization.replacePlaceholders(this.captions.lbl_rightCheckBoxListTitle, ['filterType'], [this.selectedRadio.viewValue]);
    this.selectedList = this.data.selectedList;

    this.leftList = this.dataList.filter(x => x.filterType == this.selectedRadio.value && (this.selectedList.filter(y => y.viewValue == x.viewValue).length <= 0));
    this.rightList = this.dataList.filter(x => x.filterType == this.selectedRadio.value && (this.selectedList.filter(y => y.viewValue == x.viewValue).length > 0));
    this.rightList.forEach(x => { x.disabled = (this.selectedList.filter(y => y.viewValue == x.viewValue && y.disabled).length > 0) });

    this.initialRightList = cloneDeep(this.rightList);

    this.enableDisableActionButton();
  }

  leftListChange(e, list, lists) {
    list.checked = !list.checked;
  }

  rightListChange(e, list, lists) {
    list.checked = !list.checked;
  }

  moveRight(e) {
    if (this.data.userAccess.isViewOnly) {
      const message = `${this.captions.common.BreakPointAccessDeniedMsg}
     <br><br>${this.captions.common.Breakpoint}: ${this.captions[UserAccessBreakPoints.PLAYEROFFERS]}`;
      this.utils.showAlert(message, AlertType.AccessDenied, ButtonType.Ok);
      return;
    }
    const checkedData = this.leftList.filter(x => x.checked);
    if (checkedData.length > 0) {
      for (let i = 0; i < checkedData.length; i++) {
        this.leftList.splice(this.leftList.findIndex(x => x.viewValue == checkedData[i].viewValue), 1);
        checkedData[i].checked = false;
        this.rightList.push(checkedData[i]);
      }
      this.rightList = [...this.rightList]
      this.enableDisableActionButton();
    }
  }

  moveAllRight(e) {
    if (this.data.userAccess.isViewOnly) {
      const message = `${this.captions.common.BreakPointAccessDeniedMsg}
     <br><br>${this.captions.common.Breakpoint}: ${this.captions[UserAccessBreakPoints.PLAYEROFFERS]}`;
      this.utils.showAlert(message, AlertType.AccessDenied, ButtonType.Ok);
      return;
    }
    const disableData = this.leftList.filter(x => x.disabled);
    const effectiveData = (disableData && disableData.length > 0) ? difference(this.leftList, disableData) : this.leftList;
    if (effectiveData.length > 0) {
      for (let i = 0; i < effectiveData.length; i++) {
        let availData = cloneDeep(effectiveData);
        availData[i].checked = false;
        this.rightList.push(availData[i]);
        availData.splice(i, 1);
      }
      this.leftList = (disableData && disableData.length > 0) ? [...disableData] : [];
      this.leftList = [...this.leftList];
      this.rightList = [...this.rightList]
      this.enableDisableActionButton();
    }
  }

  moveLeft(e) {
    const checkedData = this.rightList.filter(x => x.checked);
    if (checkedData && checkedData.length > 0) {
      for (let i = 0; i < checkedData.length; i++) {
        this.rightList.splice(this.rightList.findIndex(x => x.viewValue == checkedData[i].viewValue), 1);
        checkedData[i].checked = false;
        this.leftList.push(checkedData[i]);
      }
      this.leftList = [...this.leftList];
      this.enableDisableActionButton();
    }
  }

  moveAllLeft(e) {
    if (this.rightList.filter(x => !x.disabled).length > 0) {
      for (let i = 0; i < this.rightList.filter(x => !x.disabled).length; i++) {
        let selectedData = cloneDeep(this.rightList.filter(x => !x.disabled));
        selectedData[i].checked = false;
        this.leftList.push(selectedData[i]);
        selectedData.splice(i, 1);
      }
      this.rightList = this.dataList.filter(x => x.filterType == this.selectedRadio.value && x.disabled);
      this.rightList = [...this.rightList]
      this.leftList = [...this.leftList];
      this.enableDisableActionButton();
    }
  }

  enableDisableActionButton() {
    this.isDisabled = (this.rightList.length <= 0) || this.data.userAccess.isViewOnly;
   
  }
}
