import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';

export const Replaceitemreplaceinchange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: FormType[], CustomData: MatDialog
    ): any => {
        try {
            if (FieldGrouping.CustomFormAction) {
                let endpointUrl = '';
                const ReplaceIn = FormGroupData.value[FieldGrouping.OdataKey];
                const EnableFieldIndex =
                FormField.findIndex(x => x.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo);
                if (ReplaceIn === 1) {
                    endpointUrl = `/inventory/api/Packages?$filter=TypeId eq 1 and IsActive eq true and PackLists/any(PackLists: PackLists/Item eq ${FormGroupData.value['IngredientId']} and PackLists/IsActive eq true)`;
                }
                httpService.GethttpMethod(endpointUrl).subscribe(res => {
                    const radioOptionlist = [];
                    res['value'].forEach(data => {
                        const valueAssign = {} as RadioOptions;
                        valueAssign.label = data.hasOwnProperty('Name') ? data['Name'] : data['Code'];
                        valueAssign.key = data['Id'];
                        radioOptionlist.push(valueAssign);
                    });
                    FormField[EnableFieldIndex].fieldType.SingleData.options = radioOptionlist;
                });
            }
        } catch (error) {
            console.log('error occured Replaceitemreplaceinchange', error);
        }
    };