import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AlertAction, AlertType } from 'src/app/common/Models/common.models';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { Observable } from 'rxjs';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Localization } from 'src/app/common/localization/localization';
import { AlertMessagePopupComponent } from 'src/app/retail/shared/alert-message-popup/alert-message-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Product } from '../../shared/globalsContant';
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateShopGuard implements CanDeactivate<CanComponentDeactivate>{
    excludePaths= [];
    shopPath;
    addToResvPath;

    constructor(private localization: Localization, private _Utilities: CommonUtilities,public dialog: MatDialog) {
      this.excludePaths= ["/shop/viewshop/order",""];
      this.shopPath= "/shop/viewshop/retailitems";
      this.addToResvPath = "/reservations/reservationslist/";
    }

    canDeactivate(component: CanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (component.canDeactivate) {
            return component.canDeactivate();
        }
        else {
            for (const val in component) {
                let allowPath = (currentState.url.includes(this.shopPath) && (this.excludePaths.includes(nextState.url) || nextState.url.includes(this.addToResvPath)))
                if ( (component[val]) && typeof (component[val]) === 'object' && !allowPath) {
                    if(component['_shopservice'].correctTransaction && component['_shopservice'].voidedTransactionId > 0){
                        return new Promise((resolve, reject) => {
                        this.dialog.open(AlertMessagePopupComponent, {
                            width: '550px',
                            height: '300px',
                            hasBackdrop: true,
                            panelClass: 'small-popup',
                            data: {
                              headername: this.localization.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.localization.captions.shop.CorrectionTransactionMsg, buttonName: this.localization.captions.common.Yes, noButton: true, noButtonName: this.localization.captions.common.No, type: 'message'
                            },
                            disableClose: true,
                          }).afterClosed().subscribe(result => {
                            if(result.toLowerCase() == this.localization.captions.common.Yes.toLowerCase()){
                                resolve(true);
                                component['_shopservice'].correctTransaction = false;
                                component['_shopservice'].voidedTransactionId = 0;
                            }
                            else{
                                resolve(false);
                            }
                          })
                        });
                }
               else{
                    const isValidateForAddRetailitems = component['_shopservice']['ProductId'] == Product.GOLF && component['_shopservice']['addRetailItemToSource']  && component['_shopservice']['isdisableUpdate'] 
                    if ( !isValidateForAddRetailitems && component['_shopservice']['selectedProducts'].length>0 ) {
                        return new Promise((resolve, reject) => {
                            this._Utilities.showAlert(this.localization.captions.settings.utilities.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
                                if (res === AlertAction.YES) {
                                    component['_shopservice'].selectedProducts = [];
                                    component['_shopservice'].ticketDiscount = null;
                                    component['_shopservice'].LinkedRetailItemDetails = [];
                                    component['_retailService'].manualEmailId = [];
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            });
                        });
                    }
                    }
                }
            }
            return true;
        }
    }
}
