import { ImportPIConfig } from './create-transaction-config.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AgilysysImportPiPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-import-pi-popup/agilysys-import-pi-popup.component';
import { TranslateService } from '@ngx-translate/core';

export const ImportPI: ImportPIConfig = (translateService: TranslateService, dialog: MatDialog): void => {
    try {
      const dialogRef = dialog.open(AgilysysImportPiPopupComponent, {
        // width: '87%',
        // height: '80vh',
        width: '87%',
        height: '87vh',
        maxWidth: '100vw',
        data: translateService.instant('PhysicalHead.ImportPI'),
        position: {
            right: '0'
        },
        panelClass: 'attached-files-popup',
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
    });
    } catch (Error) {
        console.error('Error occurred', Error);
    }
};

