import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { DetailsBuilderData, GridFetchFields, ProcessPoConfiguration, ProcessPoSupplier, TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety, PatchValueReassign, ProcessPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { FormType } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';

export const PostPoDialogConfig: ProcessPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig) {
            console.log('TransactionCollection', TransactionCollection);
            TransactionCollection.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.AllSupplierCount =
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList.length;
            TransactionCollection.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.SelectedSupplierCount = 0;
            const FormObject: FormType = {
                type: 'checkbox',
                disbaledproperity: true,
                class: 'supplierlist',
                fieldType: {
                    SingleData: {
                        name: 'SupplierList',
                        label: 'SupplierList',
                        value: false,
                        disbaledProperty: true,
                        onCellClick: (e: any, field: any, form: any) => {
                            console.log('onCellClick', e, field, form);
                        }
                    }
                }
            };
            const options = [];
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList.forEach(supplier => {
                options.push({
                    key: supplier.SuplierId,
                    label: supplier.SuplierName
                });
            });
            FormObject.fieldType.SingleData.options = options;
            if (TransactionCollection.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.
                PoFormConfig.findIndex(x => x.type === 'checkbox') === -1) {
                // TransactionCollection.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.PoFormConfig.push(FormObject);
            } else if (FormObject.fieldType.SingleData.options.length !== TransactionCollection.CreateTranscationConfig.
                ItemsConfig.PostPoFormConfig.PoFormConfig[1].fieldType.SingleData.options.length) {
                TransactionCollection.CreateTranscationConfig.ItemsConfig.PostPoFormConfig.
                    PoFormConfig[1].fieldType.SingleData.options = options;
            }
            console.log('TransactionCollection', TransactionCollection.CreateTranscationConfig);
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred PostPoDialogConfig', Error);
    }
};