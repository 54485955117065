import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { SPAConfig } from '../../../core/config/SPA-config';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertType, ButtonType, Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { Relationship } from '../../../shared/business/view-settings.modals';
import { EmptyValueValidator } from 'src/app/shared/Validators/EmptyValueValidator';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-relationship',
  templateUrl: './relationship.component.html',
  styleUrls: ['./relationship.component.scss']
})
export class RelationshipComponent implements OnInit {
  clickbutton: any;
  @Input() dataInput:any;
  @Input() popupConfigs: popupConfig;

  sgDataInput: any = [];
  FormGrp: UntypedFormGroup;
  blnReadOnly: boolean;
  maxListOrder: number;
  captions: any = this.localization.captions.setting;
  allcaptions: any = this.localization.captions;

  //validation error variables
  rCodeErr: string = this.captions.MissingRelationshipCode;
  rDescErr: string = this.captions.MissingRelationshipName;
  isUserAuthorized = true;
  isViewOnly=true;
  floatLabel:string;
  isEdit: boolean = false;
  existingListOrder: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private spaConfig: SPAConfig,public dialogRef: MatDialogRef<RelationshipComponent>,
    public localization:SpaLocalization,private http: HttpServiceCall, private vsBusiness: ViewSettingClientBusiness,
    private Form: UntypedFormBuilder, public dialog: MatDialog,
    private utils: SpaUtilities,private BP: BreakPointAccess) { 
      this.floatLabel = this.localization.setFloatLabel;
    }
    ValidateBreakPoint(): void {
      this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.Relationship]);
      this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.Relationship);
      if (this.isViewOnly) {
        this.utils.disableControls(this.FormGrp);
      }
    }
    onNoClick(): void {
      this.vsBusiness.promptUserDialog(this.dialogRef);
    }
    validateSave() {
      return this.FormGrp.valid && this.FormGrp.dirty;
    }
  
    saveRelationship() {
      this.saveButton.disabledproperty = true;
      if (this.popupConfigs.operation == "create") {
        this.createRelationship();
      } else if (this.popupConfigs.operation == "edit") {
        this.updateRelationship();
      }
    }
  
    updateRelationship() {
      const _id =this.dataInput.id;
      const _code = this.FormGrp.controls['code'].value;
      const _name = this.FormGrp.controls['name'].value;
      let _listOrder = this.FormGrp.controls['listOrder'].value;
      let _isActive =  this.FormGrp.controls['isActive'].value;
      if(_listOrder == '') {
        _listOrder=this.existingListOrder;
      }
      const bodyObj: Relationship = {
        id: this.dataInput.id ,code: _code.toUpperCase(), name: _name, listOrder: _listOrder,isActive: _isActive
      }
      this.http.CallApiWithCallback<Relationship[]>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "UpdateRelationship",
        method: HttpMethod.Put,
        uriParams: { id: this.dataInput.id },
        body: bodyObj,
        showError: true,
        extraParams: []
      });
  
    }
  
    createRelationship() {
      const _code = this.FormGrp.controls['code'].value;
      const _name = this.FormGrp.controls['name'].value;
      let _listOrder = this.FormGrp.controls['listOrder'].value;
      let _isActive = this.FormGrp.controls['isActive'].value;
      if(_listOrder == '') {
        _listOrder = this.maxListOrder;
      }
      const bodyObj: Relationship = {
        code: _code.toUpperCase(), name: _name, listOrder: _listOrder,isActive: _isActive
      }
       this.http.CallApiWithCallback<Relationship[]>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "CreateRelationship",
        method: HttpMethod.Post,
        body: bodyObj,
        showError: true,
        extraParams: [bodyObj]
      });
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
      if (callDesc == "CreateRelationship" || callDesc == "UpdateRelationship") {
        result["NewData"] = extraParams[0];
        this.dialogRef.close(result);
      }
    }
  
    errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
      if (callDesc == "CreateRelationship" || callDesc == "UpdateRelationship") {
        this.saveButton.disabledproperty = false;
        if (error) {error["NewData"] = extraParams[0];}
      }
    }

  ngOnInit(): void {
    this.FormGrp = this.Form.group({
      code: ['', [Validators.required, EmptyValueValidator]],
      name: ['', [Validators.required, EmptyValueValidator]],
      listOrder: [''],
      isActive: ['']
    });
    this.saveButton = {
      type: 'primary',
      label: this.popupConfigs.operation == "create" ? this.captions.save : this.captions.update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.CANCEL,
    }
    this.vsBusiness.activeFormGroup = this.FormGrp;
    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.captions.save;
      this.blnReadOnly = false;
      this.maxListOrder = this.popupConfigs.maxListOrder;
      this.FormGrp.setValue({
        code: this.dataInput ? this.dataInput.code : '',
        name: this.dataInput ? this.dataInput.name : '',
        listOrder: this.maxListOrder,
        isActive: true
      });
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.captions.update;
      this.blnReadOnly = true;
      this.existingListOrder = this.dataInput.listOrder;    
      this.FormGrp.patchValue(this.dataInput);  
      if(this.dataInput.isActive)
      {
        this.FormGrp.controls.isActive.setValue(true);       
      }
      else
      {
        this.FormGrp.controls.isActive.setValue(false);      
      }  
    }
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.FormGrp.valid && this.FormGrp.dirty);
      this.saveButton = { ...this.saveButton };
    });
    this.ValidateBreakPoint();
  }
  ValidateInactive()
  {
    const isActive = this.FormGrp.controls['isActive'].value;
    if (!isActive) {
      return;
    }
    if(this.dataInput.isContactRelationshipInUse){
      this.utils.ShowErrorMessage(this.localization.captions.common.Error,this.captions.UpdateNotallowed, ButtonType.Ok,async (res) => {
        this.FormGrp.controls.isActive.setValue(true);  
      });

    }
  }
}
