import { Component, Input, ViewChild } from '@angular/core';
@Component({
  selector: 'app-spa-more-legend',
  templateUrl: './more-legend.component.html',
  styleUrls: ['./more-legend.component.scss']
})
export class MoreLegendComponent  {
  @Input() label: string;
  @Input() legends;
  @Input() modalType = false;
  @ViewChild('myPopover', { static: false }) myPopover;
  @ViewChild('popOverTarget', { static: false }) popOverTarget;

  onShown(e) {
    const popOverTargetPosition = this.popOverTarget.nativeElement.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const popOverWidth = this.myPopover.element.nativeElement.childNodes[0].offsetWidth;
    let cdkOverlayPaneWidth = 0;
    let cdkOverlayPaneOffsetLeft = 0;
    let offsetRight = 0;
    const actualLeft = e.content.left;
    const targetLeft = popOverTargetPosition.left;
    const cdkOverlayPane = document.getElementsByClassName('cdk-overlay-pane')[0] as HTMLElement;
    if (this.modalType) {
      cdkOverlayPaneWidth = cdkOverlayPane.offsetWidth;
      cdkOverlayPaneOffsetLeft = cdkOverlayPane.offsetLeft;
      offsetRight = cdkOverlayPaneWidth + cdkOverlayPaneOffsetLeft;
    } else {
      offsetRight = windowWidth;
    }
    const checkRightPosition = offsetRight - popOverWidth;

    if (this.modalType) {
      if (actualLeft < 0) {
        e.content.left = 0;
        const calcArrow = 20;
        this.checkArrowTimeout(calcArrow, 0);
      } else if ((actualLeft + popOverWidth + cdkOverlayPaneOffsetLeft + 5) > offsetRight) {
        e.content.left = offsetRight - (popOverWidth + cdkOverlayPaneOffsetLeft);
        const calcArrow = (((windowWidth - popOverTargetPosition.left) / popOverWidth) - ((popOverTargetPosition.width / 2) / popOverWidth));
        if ((calcArrow * 100) < 10) {
          this.checkArrowTimeout((1 - ((popOverTargetPosition.width / 2) / popOverWidth)) * 100, 0);
        } else {
          this.checkArrowTimeout((calcArrow) * 100, 0);
        }
      }
    } else {
      if (targetLeft < (popOverWidth / 2)) {
        e.content.left = 0;
        const calcArrow = 20;
        this.checkArrowTimeout(calcArrow, 0);
      } else if (targetLeft > checkRightPosition) {
        e.content.left = checkRightPosition;
        const calcArrow = (((windowWidth - popOverTargetPosition.left) / popOverWidth) - ((popOverTargetPosition.width / 2) / popOverWidth));
        if ((calcArrow * 100) < 10) {
          this.checkArrowTimeout((1 - ((popOverTargetPosition.width / 2) / popOverWidth)) * 100, 0);
        } else {
          this.checkArrowTimeout((1 - calcArrow) * 100, 0);
        }
      }
    }
  }
  checkArrowTimeout(arrowPosition, arrIndex) {
    const checkArrow = setTimeout(() => {
      const className = 'more-legend-popover';
      const popOverArrow = document.getElementsByClassName(className)[arrIndex].querySelectorAll('.arrow')[0] as HTMLElement;
      if (popOverArrow) {
        popOverArrow.style.left = arrowPosition + '%';
        clearTimeout(checkArrow);
      }
    }, 0);
  }
}
