import { Injectable } from '@angular/core';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { NotificationTemplate, NotificationKeyword, TemplateDomainData, NotificationAPITemplate, Language } from './crud-email-template.model';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';
import { Product } from 'src/app/common/enums/shared-enums';

@Injectable()
export class CrudEmailTemplatesBusiness {

  constructor(private _templateDataServices: TemplateDataServices, private dmConfigDataService: DMConfigDataService, private _propertyInformationDataService: PropertyInformationDataService) {

  }
  exceptionHandle(ex: any) {
    console.error(ex);
    throw ex;
  }
  async getDmconfig() {
    return await this.dmConfigDataService.getDataMagineConfig();
  }
  async getDmconfigSession() {
    return await this.dmConfigDataService.getDataMagineConfigSession();
  }

  async getEmailTemplateData(typeId: number, showInActive: boolean = false): Promise<NotificationTemplate[]> {
    try {
      return await this._templateDataServices.GetAllTemplates(typeId, showInActive);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async getMaxListOrder(typeId: number): Promise<number> {
    try {
      return await this._templateDataServices.GetMaxListOrder(typeId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async getNotificationKeywords(eventId: number[], productId: number): Promise<NotificationKeyword[]> {
    try {
      let result = await this._templateDataServices.GetTemplateKeywords(eventId, productId);
      return result.map(x => this.NotificationKeywordUIMapper(x))
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  private NotificationKeywordUIMapper(notificationKeyword): NotificationKeyword {
    return {
      id: notificationKeyword.id,
      description: notificationKeyword.description,
      name: notificationKeyword.name,
      groupId: notificationKeyword.groupId,
      groupNumber: notificationKeyword.groupName,
      isExpanded: false
    } as NotificationKeyword;
  }

  async getTemplateDetailsById(notificationTemplateId: number,languageid:number=1): Promise<TemplateDomainData> {
    try {
      return await this._templateDataServices.GetTemplatByNotificationTemplateId(notificationTemplateId,languageid);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }


  async CreateorUpdateTemplate(templatedomaindata: TemplateDomainData): Promise<number> {
    try {
      return await this._templateDataServices.CreateorUpdateTemplate(templatedomaindata);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }


  async DeleteTemplate(notificationTemplateId: number): Promise<NotificationTemplate[]> {
    try {
      return await this._templateDataServices.DeleteTemplate(notificationTemplateId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async DeleteTemplateNotificationTemplateContent(notificationTemplateId: number,languageid:number=1): Promise<NotificationTemplate> {
    try {
      return await this._templateDataServices.DeleteNotificationTemplateContent(notificationTemplateId,languageid);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }


  async UpdateTemplate(id: number, notificationTemplate: NotificationAPITemplate): Promise<boolean> {
    try {
      return await this._templateDataServices.UpdateTemplate(id, notificationTemplate);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async UpdateListOrder(fromorder: number, toOrder: number, typeId: number): Promise<boolean> {
    try {
      return await this._templateDataServices.UpdateListOrder(fromorder, toOrder, typeId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async DefaultTemplateExists(eventId: number[], id: number, typeId: number): Promise<boolean> {
    try {
      return await this._templateDataServices.DefaultTemplateExists(eventId, id, typeId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  public async getLanguages() {
    let languageAPIData = await this._propertyInformationDataService.getAllLanguages();
    return languageAPIData.map(x => this.MapToUILanguage(x));
  }

  private MapToUILanguage(languageData): Language {
    return {
      id: languageData.id,
      value: languageData.languageCode,
      viewValue: languageData.languageName,
      isDisabled:false
    } as Language;
  }

  public async GetProductTemplateIds(productId){
    if(productId == Product.SALESANDCATERING){
      return await this._templateDataServices.GetSNCTemplateIds();
    }
  else{
  return [];
  }
  }

}