import { PaymentMethods } from './shared.modals';
import { Injectable } from '@angular/core';
import { RetailUtilities } from '../utilities/retail-utilities';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CommonVariablesService } from '../service/common-variables.service';
import { RetailFeatureFlagInformationService, ConfigKeys } from '../service/retail.feature.flag.information.service';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import { ArAcctSearchType, ARFinancialBin, ARPostPaymentRequest, ARPostPaymentResponse, EnableZeroPosting, POSFinancialBin } from '../service/payment/payment-business.model';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { FinancialBinType } from './FinancialBin-business';
import { RetailRoutes } from '../../retail-route';

@Injectable()
export class ARPostingBusinessService {

    constructor(
        private http: HttpServiceCall,
        public utils: RetailUtilities,
        public localization: RetailLocalization,
        public _ss: CommonVariablesService,
        private featureFlagInfo: RetailFeatureFlagInformationService,
        private propInfo: RetailPropertyInformation
    ) { }

    async AccountLookUp(tenderId, searchKey, type: ArAcctSearchType, profitCenter = 0) {
        return this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: RetailRoutes.ARAccountLookupWithTenderId,
            method: HttpMethod.Post,
            uriParams: { byname: (type === ArAcctSearchType.SearchByName), tenderId :tenderId, profitCenter : profitCenter },
            body: searchKey,
            showError: true
        });
    }

    async PerformARPosting(compScope: any, currentPostAmt: number, finBinSplitUp: number, isRWT: boolean = false): Promise<ARPostPaymentResponse> {
        let postRes: ARPostPaymentResponse;
        let profitCenter = "";
        let paymentConfigProfitCenter = "";        
        const selectedoutlet = this._ss.GetSelectedOutletDetail();
        if(compScope?.selectedpayment?.additionalConfigurations){
                const paymentAdditionalConfig = JSON.parse(compScope.selectedpayment.additionalConfigurations);
                if (paymentAdditionalConfig && paymentAdditionalConfig.length > 0) {
                    const config = paymentAdditionalConfig.find(x => x.Key === ConfigKeys.ARAccountPosting.ProfitCenter);
                    paymentConfigProfitCenter = config ? config.Value : "";                    
                }
        }        
        profitCenter = (selectedoutlet && selectedoutlet.profitCenter && (selectedoutlet.profitCenter != "")) ? selectedoutlet.profitCenter
                : (paymentConfigProfitCenter != "" ? paymentConfigProfitCenter : this.featureFlagInfo.ARAcctProfitCenter);

        if (compScope && compScope.selectedARAccount && currentPostAmt !== 0 && profitCenter !=""
            && (compScope.CurrentTryPayResponse != null || (isRWT && compScope.IsRefundFlow)
                || compScope.IsMakePaymentScreen || compScope.IsCheckOutScreen)) {
            let financialBins = [];
            let checkNumber = '';
            let ticketNumber = '';
            let parentTenderId = compScope?.selectedpayment?.parentTypeId??0;
            if (isRWT && compScope.IsRefundFlow) {
                const transactionInput: TransactionInput = {
                   id : compScope['ReturnWithTicketResponse'].id,
                   tenderId : compScope?.selectedpayment && compScope?.selectedpayment?.paymentTypeId ? compScope?.selectedpayment?.paymentTypeId : PaymentMethods.ARAcctPost ,
                   parentTenderId : parentTenderId,
                   amount : currentPostAmt,
                   binLevelSplitUp: finBinSplitUp,
                   isAllowZeroPosting: compScope?.selectedpayment?.allowZeroPosting
                };
                financialBins = await this.getFinancialBin(transactionInput);
                checkNumber = compScope['CreateRetailItemResponse']?.transactionData?.retailTicketNumber;
                ticketNumber = this.propInfo.UseRetailInterface ?
                    compScope['ReturnWithTicketResponse'].transactionData.ticketNumber :
                    compScope['ReturnWithTicketResponse'].transactionData.retailTicketNumber;
            } else if (compScope.IsMakePaymentScreen || compScope.IsCheckOutScreen) {
                financialBins = [
                    {
                        Amount: currentPostAmt * 100,
                        Type: 1,
                        Id: 1
                    }
                ];
                checkNumber = String(this.utils.GetRandomNumber(5));
                ticketNumber = String(this.utils.GetRandomNumber(5));
            }
            else {
                checkNumber = compScope['CreateRetailItemResponse']?.transactionData?.retailTicketNumber;
                financialBins = compScope.CurrentTryPayResponse.financialBins;
                ticketNumber = compScope['CreateRetailItemResponse']?.transactionData?.retailTicketNumber;
                if (isRWT && !ticketNumber) {
                    ticketNumber = this.propInfo.UseRetailInterface ?
                        compScope['ReturnWithTicketResponse'].transactionData.ticketNumber :
                        compScope['ReturnWithTicketResponse'].transactionData.retailTicketNumber;
                }
            }
            if (!this._ss.AllOutlets || this._ss.AllOutlets.length <= 0) {
                await this._ss.SetAllOutletsData();
                this._ss.AllOutlets = this._ss.outlets;
            }

            const selectedOutlet = this._ss.GetSelectedOutletDetail();
            const tenderId = compScope?.selectedpayment && compScope?.selectedpayment?.paymentTypeId ? compScope?.selectedpayment?.paymentTypeId : PaymentMethods.ARAcctPost;
            
            const postRequest: ARPostPaymentRequest = {
                accountName: compScope.selectedARAccount.accountName,
                accountNumber: compScope.selectedARAccount.accountNumber,
                amount: currentPostAmt,
                checkNumber: ticketNumber,
                profitCenter: profitCenter,
                financialBins: this.ConstructFinancialBinForPost(financialBins),
                outletName:  (compScope.IsMakePaymentScreen ||  compScope.IsCheckOutScreen) ? '' : selectedOutlet.subPropertyName,
                outletId: (compScope.IsMakePaymentScreen ||  compScope.IsCheckOutScreen) ? this._ss.SelectedOutletId
                :  selectedOutlet.subPropertyID,
                ticketNumber: Number.parseInt(ticketNumber),
                tenderId: tenderId,
                parentTenderId: parentTenderId
            };
            const postUrl = currentPostAmt < 0 ? 'ARPaymentCredit' : 'ARPaymentPost';
            const response: any = await this.http.CallApiAsync<any>({
                host: GlobalConst.Host.payment,
                callDesc: postUrl,
                method: HttpMethod.Post,
                body: postRequest
            });
            if (response.successStatus) {
                postRes = response.result;
            }
        }
        return postRes;
    }

    async ConstructARPostingRecord(compScope: any, currentPostAmt: number, finBinSplitUp: number, isRWT: boolean = false): Promise<ARPostPaymentResponse> {
        let postRes: ARPostPaymentResponse;
        let profitCenter = "";
        let paymentConfigProfitCenter = "";        
        const selectedoutlet = this._ss.GetSelectedOutletDetail();
        if(compScope?.selectedpayment?.additionalConfigurations){
                const paymentAdditionalConfig = JSON.parse(compScope.selectedpayment.additionalConfigurations);
                if (paymentAdditionalConfig && paymentAdditionalConfig.length > 0) {
                    const config = paymentAdditionalConfig.find(x => x.Key === ConfigKeys.ARAccountPosting.ProfitCenter);
                    paymentConfigProfitCenter = config ? config.Value : "";                   
                }
        }        
        profitCenter = (selectedoutlet && selectedoutlet.profitCenter && (selectedoutlet.profitCenter != "")) ? selectedoutlet.profitCenter
                : (paymentConfigProfitCenter != "" ? paymentConfigProfitCenter : this.featureFlagInfo.ARAcctProfitCenter);

        if (compScope && compScope.selectedARAccount && currentPostAmt !== 0 && profitCenter !=""
            && (compScope.CurrentTryPayResponse != null || (isRWT && compScope.IsRefundFlow)
                || compScope.IsMakePaymentScreen || compScope.IsCheckOutScreen)) {
            let financialBins = [];
            let checkNumber = '';
            let ticketNumber = '';
            let parentTenderId = compScope?.selectedpayment?.parentTypeId??0;
            if (isRWT && compScope.IsRefundFlow) {
                const transactionInput: TransactionInput = {
                   id : compScope['ReturnWithTicketResponse'].id,
                   tenderId : compScope?.selectedpayment && compScope?.selectedpayment?.paymentTypeId ? compScope?.selectedpayment?.paymentTypeId : PaymentMethods.ARAcctPost ,
                   parentTenderId : parentTenderId,
                   amount : currentPostAmt,
                   binLevelSplitUp: finBinSplitUp,
                   isAllowZeroPosting: compScope?.selectedpayment.allowZeroPosting
                };
                financialBins = await this.getFinancialBin(transactionInput);
                checkNumber = compScope['CreateRetailItemResponse']?.transactionData?.retailTicketNumber;
                ticketNumber = this.propInfo.UseRetailInterface ?
                    compScope['ReturnWithTicketResponse'].transactionData.ticketNumber :
                    compScope['ReturnWithTicketResponse'].transactionData.retailTicketNumber;
            } else if (compScope.IsMakePaymentScreen || compScope.IsCheckOutScreen) {
                financialBins = [
                    {
                        Amount: currentPostAmt * 100,
                        Type: 1,
                        Id: 1
                    }
                ];
                checkNumber = String(this.utils.GetRandomNumber(5));
                ticketNumber = String(this.utils.GetRandomNumber(5));
            }
            else {
                checkNumber = compScope['CreateRetailItemResponse']?.transactionData?.retailTicketNumber;
                financialBins = compScope.CurrentTryPayResponse.financialBins;
                ticketNumber = compScope['CreateRetailItemResponse']?.transactionData?.retailTicketNumber;
                if (isRWT && !ticketNumber) {
                    ticketNumber = this.propInfo.UseRetailInterface ?
                        compScope['ReturnWithTicketResponse'].transactionData.ticketNumber :
                        compScope['ReturnWithTicketResponse'].transactionData.retailTicketNumber;
                }
            }
            if (!this._ss.AllOutlets || this._ss.AllOutlets.length <= 0) {
                await this._ss.SetAllOutletsData();
                this._ss.AllOutlets = this._ss.outlets;
            }

            const selectedOutlet = this._ss.GetSelectedOutletDetail();
         
            postRes = {
                postingStatus: 'Success',
                postingMessage: 'Success',
                amount: currentPostAmt,
                profitCenter: profitCenter,
                checkNumber: ticketNumber,
                financialBins: this.ConstructFinancialBinForPost(financialBins),
                accountNumber: compScope.selectedARAccount.accountNumber,
                accountName: compScope.selectedARAccount.accountName,
                outletName: (compScope.IsMakePaymentScreen ||  compScope.IsCheckOutScreen) ? '' : selectedOutlet.subPropertyName,
                outletId: (compScope.IsMakePaymentScreen ||  compScope.IsCheckOutScreen) ? this._ss.SelectedOutletId : selectedOutlet.subPropertyID,
                ticketNumber: Number.parseInt(ticketNumber),
            }
        }
        return postRes;
    }

    async getFinancialBin(request: TransactionInput): Promise<[]> {
        const result = await this.http.CallApiAsync({
            callDesc: RetailRoutes.GetFinancialBins,
            method: HttpMethod.Put,
            host: GlobalConst.Host.retailPOS,
            body: request
        });
        return result && result.result ? result.result as [] : [];
    }

    ConstructFinancialBinForPost(financialBin: any[]) {
        const financialBins: ARFinancialBin[] = [];
        financialBin.forEach(x => {
            const bin: ARFinancialBin = {
                amount: x.amount,
                id: x.id,
                type: String(x.type)
            };
            financialBins.push(bin);
        });
        return financialBins;
    }

    HandleARPostError(errorResponse) {
        const PaymentMSErrorCodes = [
            75001, // Feature Configurations are not available
            75002, // Web Proxy URI is not available
            75003, // IG WebPosting Service URI is not available
            75004, // Sender not available
            75005, // Unable to deserialize the PaymentRefund API Response
            75006  // Payment Refund API returned an empty Response
        ];
        const errorMsg = PaymentMSErrorCodes.includes(errorResponse.errorCode) ? this.localization.getError(errorResponse.errorCode)
            : errorResponse.errorDescription;
        this.utils.showError(errorMsg);
    }


}
export interface TransactionInput {
    id: number;
    tenderId: number;
    parentTenderId: number;
    amount: number;
    binLevelSplitUp: number;
    isAllowZeroPosting: boolean;
}
