import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as myGlobals from '../../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { BaseResponse, Role, RoleSetup } from '../../../shared/business/shared.modals';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-role-setup',
  templateUrl: './role-setup.component.html',
  styleUrls: ['./role-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleSetupComponent implements OnInit {
  tableoptions: any[];
  captions: any;
  private result: any = [];
  type: any;
  currIndex: any;
  tableData: any = [];

  IsReadOnly: boolean = false;
  hasAccess: boolean = true;
  checked: boolean = false;

  private PropertyId: number;
  private TeantId: number;
  private indexToBeDeleted: number;
  private ProductId: number;
  private ProductIdlst: number[] = [];

  Roles: Role[] = [];
  private roleToBeCreated: RoleSetup;
  initialLoads = true;
  callCounter = 0;

  constructor(private http: HttpServiceCall, public localization: SpaLocalization,
    private BPoint: BreakPointAccess, private utils: SpaUtilities, private ss: SettingsService) {
    

  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    if (!this.BPoint.CheckForAccess([myGlobals.SPAScheduleBreakPoint.UserRoleSetUp])) {
      this.hasAccess = false;
      return;
    }
    this.PropertyId = +this.utils.GetPropertyInfo('PropertyId');
    this.TeantId = +this.utils.GetPropertyInfo('TenantId');
    this.ProductId = +this.utils.GetPropertyInfo('ProductId');
    this.ProductIdlst.push(this.ProductId ? this.ProductId : 1);
    this.ProductIdlst.push(myGlobals.Product.RETAIL);
    this.IsReadOnly = this.BPoint.GetBreakPoint([myGlobals.SPAScheduleBreakPoint.UserRoleSetUp]).result[0].view;
    this.LoadUserRoles();
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
  }


  Done(event: any) {
    this.UpdateUserRole(event);
  }

  CheckUserRoleExist(code, name) {
    //New api CheckDupicateUserRoleExist has been added with input as property id instead of tenant id and to validate role code and name for Tenant, Golf and Spa
    this.http.CallApiWithCallback<any>({
      host: myGlobals.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CheckDupicateUserRoleExist",
      method: HttpMethod.Get,
      uriParams: { propertyId: this.PropertyId, roleCode: encodeURIComponent(code), roleName: encodeURIComponent(name) },
      showError: true,
      extraParams: [false]
    });
  }

  addOutlet(data?: any, event?: any) {
    if(data.value.controls.roleName.value.trim() == ''){
      this.utils.ShowErrorMessage("Error", this.localization.captions.setting.RoleNameErr);
      return;
    }
    if (data.type.toLowerCase() == this.localization.captions.setting.Add.toLowerCase()) {

      this.roleToBeCreated = {
        active: true,
        description: data.value.controls.roleName.value.trim(),
        propertyId: this.PropertyId,
        TenantId: this.TeantId,
        productId : this.ProductIdlst,
        code: data.value.controls.roleCode.value.trim(),
      }
      this.CheckUserRoleExist(data.value.controls.roleCode.value.trim(), data.value.controls.roleName.value.trim());

    }
    else if (data.type.toLowerCase() == this.localization.captions.setting.update.toLowerCase()) {
      let role: RoleSetup = {
        id: this.tableoptions[0].TablebodyData[this.currIndex].id,
        description: data.value.controls.roleName.value,
        active: this.tableoptions[0].TablebodyData[this.currIndex].isActive,
        TenantId: this.TeantId,
        productId : this.tableoptions[0].TablebodyData[this.currIndex].productId,
        code: data.value.controls.roleCode.value
      };
      console.log(this.tableoptions[0].TablebodyData[this.currIndex]);
      let UpdatedDataIndex = this.result.findIndex(result => result.id == role.id);
      role.active = data.value.controls.activetoggle.value;
      this.result[UpdatedDataIndex] = role;
      role.propertyId = this.PropertyId;
      this.UpdateUserRole(role);
    }
    return true;
  }

  EditRecords(data: any) {
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
  }

  DeleteRecords(event: any) {
    let currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event[0].id);
    //call
    this.AnyAsscociatedUser(event[0].id);
    this.indexToBeDeleted = currIndex;
  }
  AnyAsscociatedUser(id: number) {
    this.http.CallApiWithCallback<any>({
      host: myGlobals.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetUserCountsByRoleId",
      method: HttpMethod.Get,
      uriParams: { RoleId: id },
      showError: true,
      extraParams: [false]
    });
  }

  sliderChange(event: any) {
    if (event.data) {
      let body: Role = event.data;
      body.active = !event.value;
    }
    this.checked = event.value;
    this.PopulateData();
  }

  PopulateData() {
    if(this.result && this.result.length > 0){
      this.result = this.result.filter(role => role.productId.find(x=> x === this.ProductId));
    } 
    if (this.checked) {
      this.tableData = this.result;
    }
    else if (!this.checked) {
      this.tableData = this.result.filter(res => res.active);
    }
    this.BindToGrid();
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  LoadUserRoles() {
    this.http.CallApiWithCallback<any>({
      host: myGlobals.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetUserRoleByPropertyId",
      method: HttpMethod.Get,
      uriParams: { propertyId: this.PropertyId },
      showError: true,
      extraParams: [false]
    });
  }

  CreateUserRole(role: RoleSetup) {
    let header: any;
    this.http.CallApiWithCallback<any>({
      host: myGlobals.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CreateUserRole",
      method: HttpMethod.Post,
      header: header,
      body: role,
      showError: true,
      extraParams: []
    });
  }

  UpdateUserRole(role: RoleSetup) {
    let header: any;
    this.http.CallApiWithCallback<any>({
      host: myGlobals.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateUserRole",
      method: HttpMethod.Put,
      header: header,
      body: [role],
      showError: true,
      extraParams: []
    });
  }

  DeleteUserRole(role: RoleSetup) {
    let header: any;
    this.http.CallApiWithCallback<any>({
      host: myGlobals.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "DeleteUserRole",
      method: HttpMethod.Delete,
      header: header,
      body: role,
      showError: true,
      extraParams: []
    });
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "Role" || callDesc == "RoleWithId") {
      this.Roles = <any>result.result;
      this.PopulateData();
    }
    else if (["GetUserRoleByPropertyId"].includes(callDesc)) {
      [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
      this.tableData = result.result ? result.result : [];
      this.result = this.tableData;
      this.PopulateData();
    }
    else if (["UpdateUserRole"].includes(callDesc)) {

      if (result.result) {
        this.LoadUserRoles();
        this.PopulateData();
      }
      else {
        this.utils.ShowErrorMessage(this.localization.captions.common.ErrorOnDeActivate, `${this.localization.captions.common.RoleNameInUseDeActivateNotAllowed}`, myGlobals.ButtonType.Ok);
        this.LoadUserRoles();
        this.PopulateData();
      }

    }
    else if (["CreateUserRole"].includes(callDesc)) {
      this.LoadUserRoles();
      this.PopulateData();
    }
    else if (["DeleteUserRole"].includes(callDesc)) {
      this.LoadUserRoles();
      this.PopulateData();
    }
    else if (["GetUserCountsByRoleId"].includes(callDesc)) {
      if (result && !result.result) {
        this.DeleteUserRole(this.tableData[this.indexToBeDeleted]);
      }
      else {
        this.utils.ShowErrorMessage(this.localization.captions.common.ErrorOnDelete, `${this.localization.captions.common.RoleNameInUseDeleteNotAllowed}`, myGlobals.ButtonType.Ok);
      }
    }
    else if (["CheckDupicateUserRoleExist"].includes(callDesc)) {
      if (result && !result.result) {
        this.CreateUserRole(this.roleToBeCreated);
      }
      else {
        this.utils.ShowErrorMessage(this.localization.captions.common.ErrorOnAddingNewRole, `${this.localization.captions.common.RoleAlreadyExists}`, myGlobals.ButtonType.Ok);
      }
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
   if(callDesc == "GetAllUserRole"){
      [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
    }
  }

  private BindToGrid() {
    this.tableoptions = [
      {
        TableHdrData: [{ "title": this.captions.RoleName, "jsonkey": "description", "sortable": true },
        { "title": this.captions.Active, "jsonkey": "active", "type": "toggle", "sortable": false }],
        TablebodyData: this.tableData,
        pagination: false,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: false,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.roleSetup,
        TableId: myGlobals.GridType.roleSetup,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'roleSetup',
        ServiceId: 'roleSetup',
        InactiveRoles: true,
        DoneCancel: true,
        Sortable: "description",
        TableDraggable: false
      }
    ];
  }
}
