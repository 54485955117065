import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import moment from 'moment';
import { FormBuilderGroupKey, MasterInventoryManagement } from '../master.interface';
import { CustomFormBuilder, FormModelFieldBuilder } from './form-config.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const FormAutoFillDataForm: FormModelFieldBuilder = (ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey): any => {
    try {
        if (FieldGrouping.hasOwnProperty('AutoFillData') && FieldGrouping.AutoFillData.EnableAutoFill) {
            if (FieldGrouping.AutoFillData.FetchFromStorage && FieldGrouping.AutoFillData.StorageName) {
                let data;
                if (FieldGrouping.AutoFillData.StorageName === 'Session') {
                    data = sessionStorage.getItem(FieldGrouping.AutoFillData.KeyToFetch);
                } else if (FieldGrouping.AutoFillData.StorageName === 'Local') {
                    data = localStorage.getItem(FieldGrouping.AutoFillData.KeyToFetch);
                }
                if (FieldGrouping.AutoFillData.hasOwnProperty('IsParsedValue')) {
                    if (FieldGrouping.AutoFillData.IsParsedValue) {
                        data = JSON.parse(data);
                    }
                }
                return data;
            }
        } else if (FieldGrouping.hasOwnProperty('DefaultDateConfig') && FieldGrouping.DefaultDateConfig) {
            if (MasterDataCollection['CreateTranscationConfig']['flowFrom'] !== 'edit' &&
            MasterDataCollection['CreateTranscationConfig']['flowFrom'] !== 'copy') {
                if (FieldGrouping.DefaultDateConfig.SetDefaultDate) {
                    const returnValue = moment().add(FieldGrouping.DefaultDateConfig.DaysToAdd, 'days').format();
                    return new Date(returnValue);
                }
            }
        }  else if (FieldGrouping.hasOwnProperty('DefaultTimeConfig') && FieldGrouping.DefaultTimeConfig) {
            if (MasterDataCollection['CreateTranscationConfig']['flowFrom'] !== 'edit' &&
            MasterDataCollection['CreateTranscationConfig']['flowFrom'] !== 'copy') {
                let defaultTime = null;
                if (FieldGrouping.DefaultTimeConfig.SetSystemTime) {
                    if (FieldGrouping.DefaultTimeConfig.AddMinutes) {
                        defaultTime = moment().add(FieldGrouping.DefaultTimeConfig.AddMinutes, 'minutes').format('hh:mm a');
                    } else {
                        defaultTime = moment().format('hh:mm a');                    
                    }
                } else if (FieldGrouping.DefaultTimeConfig.SetDefaultTime) {
                    defaultTime = moment(FieldGrouping.DefaultTimeConfig.SetTime).format('hh:mm a');
                }
                return defaultTime;
            }
        } else if (FieldGrouping.hasOwnProperty('DefaultCurrencyConfig') && FieldGrouping.DefaultCurrencyConfig) {
            if ((MasterDataCollection['FormConfig'] && MasterDataCollection['FormConfig']['FlowMode'] !== 'edit' &&
                MasterDataCollection['FormConfig']['FlowMode'] !== 'copy') || (MasterDataCollection['CreateTranscationConfig'] &&
                    MasterDataCollection['CreateTranscationConfig']['flowFrom'] !== 'edit' &&
                    MasterDataCollection['CreateTranscationConfig']['flowFrom'] !== 'copy')) {
                if (FieldGrouping.DefaultCurrencyConfig.SetDefaultCurrency) {
                    const currencyDefaultId = sessionStorage.getItem('BaseCurrency') ?
                        JSON.parse(sessionStorage.getItem('BaseCurrency')) : constants.DefaultCurrencyId;
                    return currencyDefaultId;
                }
            }
        } else {
            const retunValue = FieldGrouping.Value !== null && FieldGrouping.Value !== undefined ? FieldGrouping.Value : '';
            return retunValue;
        }
    } catch (error) {
        console.error('Error occurred in FormAutoFillDataForm', error);
    }
};