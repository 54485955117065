import { CommonSharedPopupComponent } from "../common-popup/common-popup.component";
import { QuickLoginComponent } from "../quick-login/quick-login.component";
import { Localization } from 'src/app/common/localization/localization';
import { ButtonAction } from "../globalsContant";
import { MatDialog } from "@angular/material/dialog";
import { Injectable } from "@angular/core";
import { AuthenticationService } from '../../services/authentication.service';


@Injectable()
export class QuickLoginUtilities {
  constructor(public localization: Localization, public dialog: MatDialog, public authService:AuthenticationService) {
  }

  QuickLogin(popUpData?, hasMultipleBreakPoint = false, multipleBreakPoints = [], commonBpErrorMessage?) {
    const componentDetails = {
      componentName: QuickLoginComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: popUpData,
        hasMultipleBreakPoint: hasMultipleBreakPoint,
        multipleBreakPoints: multipleBreakPoints,
        commonBpErrorMessage: commonBpErrorMessage
      }
    };
    return this.dialog.open(CommonSharedPopupComponent, {
      width: '30%',
      height: '330px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        title: this.localization.captions.common.QUICKLOGIN,
        update: this.localization.captions.common.Login,
        cancel: this.localization.captions.common.Cancel,
        componentDetails: componentDetails,
        actionType: ButtonAction.login
      }
    });

  }
  
  resetQuickIdDetails() {    
    this.authService.UpdateQuickIdSession();
  }

  GetQuickLoginRoleId(): string {
    return sessionStorage.getItem('quickIdRoleId');    
  }

}


