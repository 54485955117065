import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lib-name-with-icon-renderer',
  templateUrl: './name-with-icon-renderer.component.html',
  styleUrls: ['./name-with-icon-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NameWithIconRendererComponent implements OnInit {
  textValue: any;
  params: any;
  iconName: any;
  isAlreadyPosted = false;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    this.textValue = params.value;
    this.iconName = params.IconName;
    this.params = params;
    this.isAlreadyPosted = this.params.data.hasOwnProperty('IsAlreadyPosted') ? this.params.data.IsAlreadyPosted : false;
  }

  iconClick(event) {
    if (this.params.Clickable) {
      event.stopPropagation();
      this.params.api.stopEditing();
      this.params.context.parentComponent.rowNameIconClicked(this.params);
      const allColumns = this.params.columnApi.getAllColumns();
      allColumns.forEach(element => {
        element.colDef.editable = false;
      });
      // setTimeout(() => {
      //   this.params.context.parentComponent.moreData(this.params);
      // }, 1000);
    }
  }
}
