import { Injectable } from '@angular/core';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { InventoryPopup, ImportInventorySyncStatusColumn} from './use-inventory-popup.model'
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { SyncDetailInfo,InventorySyncDetails,UserInfo, CancelInventorySync} from '../../retail.modals';
import { SubPropertyDataService } from '../../retail-code-setup/retail-outlets/subproperty-data.service';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { Tenant } from 'src/app/common/Models/property-information.model';
@Injectable()
export class UseInventoryPopupBusiness {
    captions : any;
    InventorySyncDetailInfo: SyncDetailInfo;
    InventorySyncDetails:InventorySyncDetails[]=[];
    Outlets: any;
    tenantInfo:Tenant;
    userInfo:UserInfo[];
    constructor(private localization: Localization,public Retlocalization: RetailLocalization, private retailService: RetailSetupService, private subpropService: SubPropertyDataService, public propertyInfoService: PropertyInformationDataService) {
        this.captions = this.localization.captions;

    }

    getTableOptions(): any {
        return {
            actions: [
               
            ],
            columnFreeze: {
                firstColumn: false,
                lastColumn: true
            },
            defaultSortOrder: SorTypeEnum.asc,
            defaultsortingColoumnKey: '',
            ignoreSort: true,
            showTotalRecords: false,
            enablePagination: false
        }
    }

getHeaderOptions(): TableHeaderOptions[] {
        return [
            {
                key: 'type',
                displayName: this.captions.lbl_type,
                displayNameId: this.captions.lbl_type,
                sorting: false,
                searchable: false
            },
            {
                key: 'name',
                displayName: this.captions.lbl_name,
                displayNameId: this.captions.lbl_name,
                sorting: false,
                searchable: true
            },
            {
                key: 'lastSyncDate',
                displayName: this.captions.lbl_lastSyncDate,
                displayNameId: this.captions.lbl_lastSyncDate,
                sorting: false,
                searchable: true
            },
            {
                key: 'syncStatus',
                displayName: this.captions.lbl_syncStatus,
                displayNameId: this.captions.lbl_syncStatus ,
                sorting: false,
                searchable: true,
                templateName: ActionTypeEnum.custom
            },
            {
                key: 'errorMessage',
                displayName: this.captions.lbl_errorMessageResponse,
                displayNameId: this.captions.lbl_errorMessageResponse,
                sorting: false,
                searchable: true,
            },
            {
                key: 'lastSyncedBy',
                displayName: this.captions.lbl_lastSyncedBy,
                displayNameId: this.captions.lbl_lastSyncedBy,
                sorting: false,
                searchable: true,
            },
            {
                key: 'actions',
                displayNameId: this.captions.shop.tbl_hdr_Action,
                displayName: this.captions.shop.tbl_hdr_Action,
                sorting: false,
                searchable: false,
                templateName: ActionTypeEnum.custom
            }
        ]
    }

    async cancelSync(model: CancelInventorySync){
        return await this.retailService.CancelInventorySync(model);
    }

    async getTableContent() : Promise<InventoryPopup[]> {
        let tabledatas : InventoryPopup[] = [];
        this.InventorySyncDetailInfo = await this.retailService.GetSyncStatus(); 
        let userName:string;
        let lastSyncDate: string;
        let userId: Number;
        let propertyName: string;
        this.tenantInfo = await this.propertyInfoService.GetTenantByTenantId(Number(this.localization.GetPropertyInfo('TenantId')));
        userId = this.InventorySyncDetailInfo.userId;
        this.userInfo = await this.retailService.getClerkInfo();
        userName = this.userInfo && this.userInfo.some(u => u.id === userId) ? this.userInfo. find(u => u.id === userId).userName : '';
        lastSyncDate = this.localization.localizeDisplayDate(this.InventorySyncDetailInfo.createdDate);
        this.Outlets = await this.subpropService.getOutletsByProperty();
        propertyName = this.localization.GetPropertyInfo('PropertyName');
       this.InventorySyncDetailInfo.inventorySyncDetails.forEach(x=> {
        let TypeName = '';
            if(x.type=='TENANT'){
                TypeName = this.tenantInfo.tenantName;
            }
            else if(x.type=='PROPERTY'){
                TypeName = propertyName;
            }
            else if(x.type=='OUTLET'){
                TypeName = this.Outlets.find(p => p.id == x.typeId).outletName;
            }
            else if(x.type=='RETAILITEMS'){
                TypeName =  this.Outlets.find(p => p.id == x.typeId).outletName;
            }
            else if(x.type=='VENDOR'){
                TypeName =  propertyName;
            }
            let tableData : InventoryPopup = {
            id : x.id,    
            type : x.type,
            syncStatus : x.status,
            errorMessage :x.errorMessage? x.errorMessage : x.response,
            name : TypeName,
            lastSyncedBy :userName,
            lastSyncDate :lastSyncDate
            };
            
            tabledatas.push(tableData);            
        })
        if(tabledatas.length > 0)
        return tabledatas;     
    }
    
}