import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    AddNewTypeConfig, GridConfiguration, ItemQueryListConfiguaration,
    LookupConfiguration, TransactionConfiguration
} from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { TransQueryLookupConfig } from './query-list-config.interface';
import _ from 'lodash';
import { QueryTransaction } from 'src/app/eatecui/source/transaction/shared/interface/query-details';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { CategoryIcons } from '../category-icons';

export const TransQueryListLookup: TransQueryLookupConfig = async (
    addNewTypeConfig: AddNewTypeConfig,
    transactionConfigurationData: TransactionConfiguration, httpService: HttpService, translateService: TranslateService,
    headerEntity: any, detailEntity: any, transactionService: TransactionService, selectedTransaction: string,
    OdataGlobalEntityModel: OdataGlobalEntity, masterManagementService: MasterManagementService): Promise<any> => {
    try {
        const ScrollItemCount = sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting')).LoadMoreItems : InfiniteScrollItemCount;
        const transactionJsonData = transactionConfigurationData;
        // const getHeaderOdataKey = transactionService.getOdataKey(transactionJsonData, 'ViewHeaderOdataKey');
        const getHeaderOdataKey = 'Ingredients';
        const queryTrasacationHeaderEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity,
            getHeaderOdataKey);
        const getDetailOdataKey = transactionService.getOdataKey(transactionJsonData, 'ViewDetailOdataKey');
        const queryTransactionDetailEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity,
            getDetailOdataKey);

        const jsondata = transactionService.transactionConfigurationData;
        const searchByKey = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds;
        const searchListArray = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList;
        let endPointUrl = '';
        endPointUrl = `/inventory/api/${addNewTypeConfig.EndPointUrl}`;
        let LocId = 0;
        const ModuleName = transactionJsonData.Name;
        switch (ModuleName) {
            case 'Transfer':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'spoilage':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Requisition':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'Purchase Request':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['RequestLocId'];
                break;
            case 'Physical Inventory':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Receiving':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Purchase Order':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Item Pars':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'sales':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SalesLocationId'];
                break;
            case 'Production':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Butchery':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'productionplan':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'PlanHead':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'cycleplanning':
                const locId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                LocId = locId > 0 ? locId : 0;
                break;
            case 'quickorder':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
        }
        const lookUpData = {} as LookupConfiguration;
        lookUpData.Id = 0;
        lookUpData.TransType = transactionJsonData.TransactionType;
        lookUpData.FromLocationId = LocId;
        lookUpData.ToLocationId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'];
        lookUpData.flgSearch = true;
        lookUpData.FetchAll = false;
        lookUpData.Skip = 0;
        lookUpData.Take = ScrollItemCount;
        if (ModuleName === 'Batch Item Maintenance') {
            lookUpData.DivisionId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['Division'];
            lookUpData.IsBatchItem = true;
        }
        if (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup') {
            lookUpData.itemType = addNewTypeConfig.DefaultTypeId ? addNewTypeConfig.DefaultTypeId : addNewTypeConfig.TypeId;
          }
        if ( addNewTypeConfig.EndPointUrl === 'IngredientLookup') {
            lookUpData.SupplierId = ( jsondata && jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SupplierId']) ? 
            jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SupplierId'] : 0;
            lookUpData.PostDate = ( jsondata && jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate']) ? 
            jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate'] : null;

          }
        lookUpData.IsQuery = true;
        if (addNewTypeConfig.Name === 'Class') {
            lookUpData.ClassIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Type') {
            lookUpData.TypeIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Packages') {
            lookUpData.PackageIds = searchByKey;
        }
        if ( ModuleName === 'Butchery') {
            lookUpData.IsButchery = true;
        }
        const x = await httpService.PosthttpMethod(endPointUrl, lookUpData).toPromise();
        let resultSet = x['Data'] !== undefined && addNewTypeConfig.Name === 'Packages' ?
        x['Data']['ent']['PackageResults'] : x['Data'] !== undefined ? x['Data']['ent']['ResultData'] : [];
        if (addNewTypeConfig.EndPointUrl === 'ProductLookup' && (addNewTypeConfig.Name === 'Class' || addNewTypeConfig.Name === 'Type')) {
           resultSet = x['PackageResults'];
        }
        // if (resultSet.length > 0) {
        const responseData = [];
        const itemHeaderList = [];
        let resultData = [];
        let values;
        if (addNewTypeConfig.Name === 'Inventory' || (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup' &&
            addNewTypeConfig.Name !== 'Packages')) {
            resultSet.forEach((element: any) => {
                const dataObject = _.mapKeys(element, function (v, k) {
                    return k.charAt(0).toUpperCase() + k.slice(1);
                });
                responseData.push(dataObject);
            });
            values = _(responseData)
                .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                .map((ItemList: any, Id: any) => ({
                    Id: parseInt(Id, 10),
                    ItemList,
                    Name: ItemList[0][addNewTypeConfig.Url]['Name']
                }))
                .value();
        } else {
            let QueryName: string;
            switch (addNewTypeConfig.Name) {
                case 'Class': 
                    QueryName = 'ClassId';
                    break;
                case 'Type': 
                    QueryName = 'TypeId';
                    break;
                case 'Packages':
                    QueryName = 'PackageId';
                    break;
            }
            resultData = x['PackageResults'] ?
            x['PackageResults'] : x['Data'] !== undefined ?
            x['Data']['ent']['PackageResults'] : (x['Data'] && x['Data']['ent']['ResultData'] ? 
            x['Data']['ent']['ResultData'] : x['ResultData']);
            let loopvalues: any;
            if (resultData !== null && resultData !== undefined) {
                loopvalues = Object.keys(resultData);
            } else {
                resultData = [];
                loopvalues = Object.keys(resultData);
            }
            values = [];
            loopvalues.forEach(pack => {
                const data = {} as QueryDatas;
                data.Id = resultData[pack] && resultData[pack].length > 0 ? resultData[pack][0][QueryName]
                : searchListArray.filter(res => res.Name === pack)[0].Id;
                data.ItemList = resultData[pack];
                data.Name = pack;
                values.push(data);
            });
        }
        if (values.length < searchByKey.length) {
            searchListArray.forEach(key => {
                if (values.findIndex(z => z.Id === key.Id) === -1) {
                    values.push({
                        Id: key.Id,
                        ItemList: [],
                        Name: key.Name
                    });
                }
            });
        }
        console.log('Grouped Data', values);
        values.forEach((data: any, Index: number) => {
            const itemQueryListConfiguaration = {} as ItemQueryListConfiguaration;
            itemQueryListConfiguaration.DisplayName = data.Name;
            itemQueryListConfiguaration.Enable = true;
            itemQueryListConfiguaration.IconName = 'Na';
            itemQueryListConfiguaration.Id = data['id'] !== undefined ? data['id'] : data['Id'];
            itemQueryListConfiguaration.ItemSelected = [];
            itemQueryListConfiguaration.Name = data.Name;
            itemQueryListConfiguaration.RoutingLinkUrl = addNewTypeConfig.Url;
            itemQueryListConfiguaration.ShowInEllipsis = false;
            itemQueryListConfiguaration.Type = 'grid';
            itemQueryListConfiguaration.listAuthorized = true;
            const ItemList = data.ItemList;
            const GridDataRows = QueryTransaction.QueryIngredientsGridData(queryTrasacationHeaderEntity,
                queryTransactionDetailEntity, transactionJsonData, httpService, translateService, '', ItemList);
            GridDataRows.forEach(element => {
                element['GridId'] = Index + '_' + (element.Id ? element.Id :
                     element.IngredientId !== null ? element.IngredientId :
                     element.ProductId !== null ? element.ProductId : '');
                if (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup' && addNewTypeConfig.DefaultTypeId === 2) {
                    element['TypeName'] = element.RecipeTypes.Name;
                    element['ClassName'] = element.Classes;
                }
                const IconObject = CategoryIcons.categoryIconName(element);
                element.CategoryIconName = IconObject.IconName;
                element.CategoryIconToolTip = IconObject.IconToolTip;
            });
            itemQueryListConfiguaration.listGridData = {} as GridConfiguration;
            itemQueryListConfiguaration.listGridData = {
                ParentId: data.Name,
                GridName: 'Item List',
                GridOdataKey: 'Requisition',
                ShowActiveToggle: false,
                ItemsPerPage: ScrollItemCount,
                ShowAddNew: false,
                GridTitleType: {
                    Type: 'text',
                    TypeConfig: [
                        {
                            Id: 1,
                            Name: 'Item List',
                            DisplayName: 'Item List'
                        }
                    ]
                },
                GridFilter: [],
                GridSort: [
                    {
                        DefaultSorting: true,
                        SortingOdataKey: 'Name',
                        SortingType: 'asc',
                        SortingDisplayName: ''
                    }
                ],
                GridSearch: [],
                GridColumnHide: [],
                GridData: GridDataRows,
                GridHeaderDefination: QueryTransaction.QueryGridHeaders(queryTransactionDetailEntity,
                    queryTransactionDetailEntity, transactionJsonData, httpService, translateService, data
                ),
                GridActions: {
                    GridTitle: 'Inventory List',
                    TotalItems: x['Data'] !== undefined && addNewTypeConfig.EndPointUrl !== 'GetRecipeIngredientLookup' ?
                     x['Data']['ent']['TotalCount'] : x['TotalCount'] ? x['TotalCount'] : ItemList.length,
                    InfiniteScrollConfig: {
                        EnableInfiniteScroll: true,
                        TotalRecords: x['Data'] !== undefined && addNewTypeConfig.EndPointUrl !== 'GetRecipeIngredientLookup' ?
                        x['Data']['ent']['TotalCount'] : x['TotalCount'] ? x['TotalCount'] : ItemList.length
                    },
                    ResizeRowsConfig: {
                        EnableResizeRows: false
                    },
                    ShuffleColumnConfig: {
                        EnableColumnShuffle: false
                    },
                    FilterConfig: {
                        EnableFilter: false
                    }
                },
            };
            if (addNewTypeConfig.Name === 'Packages') {
                itemQueryListConfiguaration.listGridData.GridHeaderDefination.forEach(header => {
                    header.sortDirection = '';
                });
                itemQueryListConfiguaration.listGridData.GridSort = [];
            }
            itemHeaderList.push(itemQueryListConfiguaration);
        });
        transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig = [...itemHeaderList];
        return transactionJsonData;
    } catch (error) {
        console.error(error);
    }
};

export interface QueryDatas {
    Id: string;
    Name: string;
    ItemList: Array<any>;
}