import { Injectable } from '@angular/core';
import { ValidatorFn, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ValidationResult } from './form-type.entity';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})

export class FormValidation {
  errorMessage: string;
  validationErrorMessage: string;
  createValidation(validators: any): ValidatorFn[] {
    const validate = [];
    validators.map(x => { validate.push(x.validation); });
    return validate;
  }

  createValidationMessage(e, field) {
    field.forEach(element => {
      if (element.key.toLowerCase() === e.toLowerCase()) {
        this.errorMessage = element.validationMessage;
      }
    });
    return this.errorMessage;
  }
  // composedValidation(form: any, Field: any) {
  //   return  Object.keys(Field.fieldType).toString();
  // }

}

export class CustomValidator {
  static startsWithNumber(control: any): ValidationResult {
    if (control.value !== '' && !isNaN(control.value.charAt(0))) {
      return { 'startsWithNumber': true };
    }
    return null;
  }

  /**
   * @method onlyNumber
   * @params control
   * @description accepts only number values with decimal values
   */
  static onlyNumberWithDecimal(control: any): ValidationResult {
    let isValid = false;
    if (control.value) {
      isValid = !isNaN(Number(control.value)) && isFinite(control.value);
    }
    if (control.value === '' || control.value == null) {
      return null;
    }
    if (isValid) {
      return null;
    } else {
      return {
        onlyNumberWithDecimal: true
      };
    }
  }

  /**
   * @method onlyCheckNumber
   * @params control @method
   * @description accepts only numbers no float values
   */
  static checkMinusPlusFormat(control: any): ValidationResult {
    if (control && control.value !== '' && control.value !== null && control.value !== undefined && !Array.isArray(control.value)) {
      const getValueCount = control.value.toString().split('');
      if (getValueCount.length > 0) {
        const getMinusPlusCount = getValueCount.filter(x => x === '-' || x === '+');
        const getDotCount = getValueCount.filter(x => x === '.');
        const getMinusPlusIndex = getValueCount.findIndex(x => x === '-' || x === '+');
        const checkDotPostionIndex = getValueCount.findIndex(x => x === '.');
        const format = /[ `!@#$%^&*()_\=\[\]{};':"\\|,<>\/?~]/;
        const regerPatternValid = format.test(control.value.toString());
        if ( getMinusPlusCount.length > 1  || getMinusPlusIndex > 0  || regerPatternValid || checkDotPostionIndex === 0 ||
            getDotCount > 1) {
          return {
            checkMinusPlusFormat: true
            };
        }
      }
    } else {
      return null;

    }
  }

  /**
   * @method validateCurrency
   * @param control 
   * @description Validate Currency Value 
   */

  static validateCurrency(control: any): ValidationResult {
    if (control.value) {
      const currencyRegex = /^[-]?([\d]{0,12}([\.][\d]{0,4})?)$/m;
      const validCurrency = currencyRegex.test(control.value);
      const isValid = validCurrency;
      return isValid ? null : { invalidCurrency: true };
    } else {
      return null;
    }
  }

  /**
   * @method validateDecimal
   * @param control 
   * @description Validate Decimal Value 
   */

  static validateDecimal(control: any): ValidationResult {
    if (control.value) {
      const decimalRegex = /^[-]?([\d]{0,10}([\.][\d]{0,6})?)$/m;
      const validDecimal = decimalRegex.test(control.value);
      const isValid = validDecimal;
      return isValid ? null : { invalidDecimal: true };
    } else {
      return null;
    }
  }

  /**
   * @params control
   * @method validateEmail
   * @description Validate Email Values
   */
  static validateEmail(control: any): ValidationResult {
    if (control.value) {
      // const validEmails = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
      const emailRegex = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i;
      const validEmail = emailRegex.test(control.value);
      const isValid = validEmail;
      return isValid ? null : { invalidEmail: true };
    } else {
      return null;
    }
  }
  static specialCharacterValidation(control: any): ValidationResult {
    if (control.value) {
      const regex = /^[A-Za-z0-9 ]+$/;
      const isValid = regex.test(control.value);
      return ( isValid ) ? null : { invalidcharacter: true };
    } else {
      return null;
    }
  }

    /**
   * @params control
   * @method validateEmail
   * @description Validate Email Values
   */
     static validateEffectiveDate(control: any): ValidationResult {
      if (control.value) {
        const effectiveDate =  moment(control.value).format('MM/DD/YYYY');
        const expirationDate = control._parent.value.ExpirationDate ? 
          moment(control._parent.value.ExpirationDate).format('MM/DD/YYYY') : '';
        let isValid = true;
        if (effectiveDate && expirationDate) {
          isValid = moment(effectiveDate).isSameOrBefore(expirationDate);
          if (control.root.controls.ExpirationDate.errors && isValid) {
            control.root.controls.ExpirationDate.setErrors(null);
          }
        }
        // this.validateExpirationDate(control.root.contols.ExpirationDate);
        return isValid ? null : { invalidEffectiveData: true };
      } else {
        return null;
      }
    }

     /**
   * @params control
   * @method validateEmail
   * @description Validate Email Values
   */
      static validateExpirationDate(control: any): ValidationResult {
        if (control.value) {
          const expirationDate =  moment(control.value).format('MM/DD/YYYY');
          const effectiveDate = control._parent.value.EffectiveDate ?
            moment(control._parent.value.EffectiveDate).format('MM/DD/YYYY') : '';
          let isValid = true;
          if (effectiveDate && expirationDate) {
            isValid = moment(expirationDate).isSameOrAfter(effectiveDate);
            if (control.root.controls.EffectiveDate.errors && isValid) {
              control.root.controls.EffectiveDate.setErrors(null);
            }
          }
          // this.validateEffectiveDate(control.root.contols.EffectiveDate);
          return isValid ? null : { invalidExpirationData: true };
        } else {
          return null;
        }
      }

  /**
   * @method OnlyPositiveNumbers
   * @params control
   * @description accepts only positive numbers
   */
  static OnlyPositiveNumbers(control: any): ValidationResult {
    const regex = new RegExp('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$');
    const controlValue = control.value ? parseFloat(control.value.toString()) : 0; 
    if ((control.value !== '' && control.value != null && !regex.test(control.value)) || controlValue < 0) {
      return {
        onlyPositiveNumber: true
      };
    }
    return null;
  }

  /**
   * @method onlyCheckNumber
   * @params control @method
   * @description accepts only numbers no float values
   */
  static onlyCheckNumber(control: any): ValidationResult {
    let isValid = false;
    if (control.value) {
      isValid = !isNaN(Number(control.value));
    }
    if (control.value === '' || control.value == null || control.value === 0) {
      return null;
    }
    if (isValid) {
      return null;
    } else {
      return {
        onlyCheckNumber: true
      };
    }
  }
   /**
   * @method OnlyPositiveNumbers
   * @params control
   * @description accepts only positive numbers
   */
    static invaliddaterange(control: any): ValidationResult {
      if (control.value !== '' && control.value != null ) {
        return {
          invaliddaterange: true
        };
      }
      return null;
    }

  static maxDateValidator(maxDateValue: Date): ValidatorFn {
    return (control: AbstractControl) => {
      let isValid = true;
      if (control.value) {
        const controlDate = moment(control.value).format('MM/DD/YYYY');
        const maxDate = moment(maxDateValue).format('MM/DD/YYYY');
        isValid = moment(controlDate).isSameOrBefore(maxDate);
        if (isValid) {
          return null;
        } else {
          return {
            maxDateValidator: true
          };
        }
      } else {
        return null;
      }
    };
  }

  /**
   *
   * @ param control
   * @method whiteSpaceValidation
   * @returns  Validates White Space
   */
   static whiteSpaceValidation(control: any): ValidationResult {
    try {
      if (control.value) {
        const regex = /^\S*$/;
        const isValid = regex.test(control.value);
        return ( isValid ) ? null : { whitespace: true };
      } else {
        return null;
      }
    } catch (error: any) {
      console.error('White Space Validation Error ', error);
    }
  }

  static phoneNumberValidation(control: any):  ValidationResult {
    try {
      if (control.value) {
        const regex = /^[0-9- +]*$/;
        const isValid = regex.test(control.value);
        return ( isValid ) ? null : { onlyPhoneNumber: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error('phoneNumber Validation Error ', error);
    }
  }
  /**
   *
   * @ param control
   * @method passwordValidation
   * @description Password Validation
   * @ returns
   */
  static passwordValidation(control: any): ValidationResult {
    try {
      if (control.value) {
        const regex = /^(?=.{10,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
        const isValid = regex.test(control.value);
        return ( isValid ) ? null : { invalidpassword: true };
      } else {
        return null;
      }
    } catch (error: any) {
      console.error('Password Validation Error ', error);
    }
  }
  static validateDataTypeRangeEdm16(control: any): ValidationResult {
    try {
      if (control.value) {
        if (Number(control.value) > 32767 || Number(control.value) < -32768) {
          return { invalidValue: true };
      } } else {
        return null;
      }
    } catch (error) {
      console.log('EdmType: Int16 Range Validation Error Occured ', error);
    }
  }
  static nonDecimalValidators(control: any): ValidationResult {
    try {
      if (control.value) {
        if ( Number(control.value) ) {
          const numberValue = Number(control.value);
          if ( !!(numberValue % 1) ) {
            return { InvalidNonDecimalValue: true };
          }
          if ( control.value.toString().indexOf('.') > -1 ) {
            return { InvalidNonDecimalValue: true };
          }
      } } else {
        return null;
      }
    } catch (error) {
      console.log('EdmType: Int16 Range Validation Error Occured ', error);
    }
  }
  static validateDataTypeRangeEdm32(control: any): ValidationResult {
    try {
      if (control.value) {
        if (Number(control.value) > 2147483647 || Number(control.value) < -2147483648) {
          return { invalidValue: true };
        } 
      } else {
          return null;
      }
    } catch (error) {
        console.error('EdmType: Int32 Range Validation Error Occured ', error);
    }
  }
  static validateDataTypeRangeEdm64(control: any): ValidationResult {
    try {
      if (control.value) {
        if (Number(control.value) > 9223372036854775807 || Number(control.value) <  -9223372036854775808) {
          return { invalidValue: true };
      } } else {
        return null;
      }
    } catch (error) {
      console.error('EdmType: Int64 Range Validation Error Occured ', error);
    }
  }
  static validateDataTypeRangeDecimal (control: any): ValidationResult {
    try {
      if (control.value) {
        if ( Math.floor(control.value) !== 0 ) {
          const number = Number(control.value);
          const maxNumber = Number(exponentConvertToNumber('7.9e10'));
          const minNumber = Number(exponentConvertToNumber('-1.0e10'));
          if (number > maxNumber || number < minNumber) {
            return { invalidValue: true };
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Validate Data Type Range Decimal Error Occured ', error);
    }
  }

  static checkdecimalCount (control: any): ValidationResult {
    try {
      if (control.value) {
        if ( !Number.isInteger(control.value) ) {
        const splitedValue: Array<any> = control.value.toString().split('.');
        if ( splitedValue.length > 0 ) {
          const getAfterdecimal: string = splitedValue[1];
          if ( getAfterdecimal && getAfterdecimal.length > 6 ) {
            return { invalidDecimalcount: true };
          } else {
            return null;
          }
          }
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Validate Data Type Range Decimal Error Occured ', error);
    }
  }
  static validateDataTypeRangeDouble (control: any): ValidationResult {
    try {
      if (control.value) {
        const number = Number(control.value);
        // const maxNumber = Number(exponentConvertToNumber('1.79769313486232e308'));
        // const minNumber = Number(exponentConvertToNumber('-1.79769313486232e308'));
        const maxNumber = Number(exponentConvertToNumber('1.79769313486232e10'));
        const minNumber = Number(exponentConvertToNumber('-1.79769313486232e10'));
        if (number > maxNumber || number < minNumber) {
          return { invalidValue: true };
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Validate Data Type Range Double Error Occured ', error);
    }
  }
  static validateDataTypeRangeFloat (control: any): ValidationResult {
    try {
      if (control.value) {
        const number = Number(control.value);
        // const maxNumber = Number(exponentConvertToNumber('3.402823e38'));
        // const minNumber = Number(exponentConvertToNumber('-3.402823e38'));
        const maxNumber = Number(exponentConvertToNumber('3.402823e10'));
        const minNumber = Number(exponentConvertToNumber('-3.402823e10'));
        if (number > maxNumber || number < minNumber) {
          return { invalidValue: true };
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Validate Data Type Range Float Error Occured ', error);
    }
  }
  static digitLimitValidator(control: any): any {
    try {
      if (control.value !== '' && control.value != null && control.value !== undefined) {
        if (control.value.includes('e')) {
          return {
            digitlimit: true
          };
        } else {
          if (Number(control.value).toString().includes('e')) {
            return {
              digitlimit: true
            };
          } else if (control.value.includes('.')) {
            const s = control.value.split('.');
            let checkMinus: number;
            if (s[0].includes('-')) {
              checkMinus = s[0].replace('-', '').length;
            } else {
              checkMinus = s[0].length;
            }
            if (checkMinus > 15) {
              return {
                digitlimit: true
              };
            } else if (s[1].length > 4) {
              return {
                digitlimit: true
              };
            }     
          } else {
            let digitLength: number;
            if (control.value.includes('-') ) {
              digitLength = control.value.replace('-', '').length;
            } else if (control.value.includes('+')) {
              digitLength = control.value.replace('+', '').length;
            } else {
              digitLength = control.value.length;
            }            
            if (digitLength > 15) {
              return {
                digitlimit: true
              };
            }
          }
        }
      } 
      return null;
    } catch (error: any) {
      console.error('Digit Limit Validator Error Occured - ', error);
    }
  }
  static validateStartDate(control: any): ValidationResult {
    if (control.value) {
      const startDate =  moment(control.value).format('MM/DD/YYYY');
      const endDate = control._parent.value.HistoryEndDate ? 
        moment(control._parent.value.HistoryEndDate).format('MM/DD/YYYY') : '';
      let isValid = true;
      if (startDate && endDate) {
        isValid = moment(startDate).isSameOrBefore(endDate);
        if (control.root.controls.HistoryEndDate.errors && isValid) {
          control.root.controls.HistoryEndDate.setErrors(null);
        }
      }
      return isValid ? null : { invalidStartDate: true };
    } else {
      return null;
    }
  }
  static validateEndDate(control: any): ValidationResult {
    if (control.value) {
      const startDate =  moment(control._parent.value.HistoryStartDate).format('MM/DD/YYYY');
      const endDate = control.value ? 
        moment(control.value).format('MM/DD/YYYY') : '';
      let isValid = true;
      if (startDate && endDate) {
        isValid = moment(startDate).isSameOrBefore(endDate);
        if (control.root.controls.HistoryStartDate.errors && isValid) {
          control.root.controls.HistoryStartDate.setErrors(null);
        }
      }
      return isValid ? null : { invalidEndDate: true };
    } else {
      return null;
    }
  }
  static validateScheduleStartDate(control: any): ValidationResult {
    try {
      if (control && control.value) {
        const startDate =  moment(control.value).format('MM/DD/YYYY');
        const endDate = control._parent.value.EndDate ? 
          moment(control._parent.value.EndDate).format('MM/DD/YYYY') : '';
        let isValid = true;
        if (startDate && endDate) {
          isValid = moment(startDate).isSameOrBefore(endDate);
          if (control.root.controls.EndDate.errors && isValid) {
            control.root.controls.EndDate.setErrors(null);
          }
        }
        return isValid ? null : { invalidScheduleStartDate: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  static validateScheduleEndDate(control: any): ValidationResult {
    try {
      if (control && control.value) {
        const startDate =  moment(control._parent.value.StartDate).format('MM/DD/YYYY');
        const endDate = control.value ? 
          moment(control.value).format('MM/DD/YYYY') : '';
        let isValid = true;
        if (startDate && endDate) {
          isValid = moment(startDate).isSameOrBefore(endDate);
          if (control.root.controls.StartDate.errors && isValid) {
            control.root.controls.StartDate.setErrors(null);
          }
        }
        return isValid ? null : { invalidScheduleEndDate: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  static validateScheduleReportStartDate(control: any): ValidationResult {
    try {
      if (control && control.value) {
        const startDate =  moment(control.value).format('MM/DD/YYYY');
        const endDate = control._parent.value.EndDate ? 
          moment(control._parent.value.EndDate).format('MM/DD/YYYY') : '';
        let isValid = true;
        if (startDate && endDate) {
          isValid = moment(startDate).isSameOrBefore(endDate);
          if (control.root.controls.EndDate.errors && isValid) {
            control.root.controls.EndDate.setErrors(null);
          }
        }
        return isValid ? null : { invalidScheduleStartDate: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  static validateScheduleReportEndDate(control: any): ValidationResult {
    try {
      if (control && control.value) {
        const startDate =  moment(control._parent.value.StartDate).format('MM/DD/YYYY');
        const endDate = control.value ? 
          moment(control.value).format('MM/DD/YYYY') : '';
        let isValid = true;
        if (startDate && endDate) {
          isValid = moment(startDate).isSameOrBefore(endDate);
          if (control.root.controls.StartDate.errors && isValid) {
            control.root.controls.StartDate.setErrors(null);
          }
        }
        return isValid ? null : { invalidScheduleEndDate: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  static validateEventStartDate(control: any): ValidationResult {
    try {
      if (control && control.value) {
        const startDate =  moment(control.value).format('MM/DD/YYYY');
        const endDate = control._parent ? control._parent.value.EndDate ? 
          moment(control._parent.value.EndDate).format('MM/DD/YYYY') : '' : '';
        let isValid = true;
        if (startDate && endDate) {
          isValid = moment(startDate).isSameOrBefore(endDate);
          if (control.root.controls.EndDate.errors && isValid) {
            control.root.controls.EndDate.setErrors(null);
          }
        }
        return isValid ? null : { invalidEventStartDate: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  static validateEventEndDate(control: any): ValidationResult {
    try {
      if (control && control.value && control._parent) {
        const startDate =  control._parent ? control._parent.value.StartDate ? 
        moment(control._parent.value.StartDate).format('MM/DD/YYYY') : '' : '';
        const endDate = control.value ? 
          moment(control.value).format('MM/DD/YYYY') : '';
        let isValid = true;
        if (startDate && endDate) {
          isValid = moment(startDate).isSameOrBefore(endDate);
          if (control.root.controls.StartDate.errors && isValid) {
            control.root.controls.StartDate.setErrors(null);
          }
        }
        return isValid ? null : { invalidEventEndDate: true };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }
  static validateEventStartTime(control: any): ValidationResult {
    if (control && control.value) {
      const startTime =  moment( control.value, 'hh:mm a').format('HH:mm:ss');
      const endTime = control._parent ? control._parent.value.EventEndTime ? 
        moment(control._parent.value.EventEndTime, 'hh:mm a').format('HH:mm:ss') : '' : '';
      let isValid = true;
      if (endTime && startTime) {
        isValid = startTime < endTime;
        if (control.root.controls.EventEndTime.errors && isValid) {
          control.root.controls.EventEndTime.setErrors(null);
        }
      }
      return isValid ? null : { invalidEventStartTime: true };
    } else {
      return null;
    }
  }
  static validateEventEndTime(control: any): ValidationResult {
    if (control && control.value) {
      const startTime = control._parent ? control._parent.value['EventStartTime'] ? 
      moment(control._parent.value['EventStartTime'], 'hh:mm a').format('HH:mm:ss') : '' : '';
      const endTime = control.value ? 
        moment(control.value, 'hh:mm a').format('HH:mm:ss') : '';
      let isValid = true;
      if (endTime && startTime) {
        isValid = endTime > startTime;
        if (control.root.controls.EventStartTime.errors && isValid) {
          control.root.controls.EventStartTime.setErrors(null);
        }
      }
      return isValid ? null : { invalidEventEndTime: true };
    } else {
      return null;
    }
  }
  static validateMRPStartDate(control: any): ValidationResult {
    if (control.value) {
      const endDateName = Object.keys(control._parent.controls).find(name => 
        control === control._parent.controls[name])?.replace('StartDate', 'EndDate') ?? null;
      const startDate =  moment(control.value).format('MM/DD/YYYY');
      const endDate = control._parent.value[endDateName] ? 
        moment(control._parent.value[endDateName]).format('MM/DD/YYYY') : '';
      let isValid = true;
      if (startDate && endDate) {
        isValid = moment(startDate).isSameOrBefore(endDate);
        if (control.root.controls[endDateName].errors && isValid) {
          control.root.controls[endDateName].setErrors(null);
        }
      }
      return isValid ? null : { invalidMRPStartDate: true };
    } else {
      return null;
    }
  }
  static validateMRPEndDate(control: any): ValidationResult {
    if (control.value) {
      const startDateName = Object.keys(control._parent.controls).find(name => 
        control === control._parent.controls[name])?.replace('EndDate', 'StartDate') ?? null;
      const startDate =  control._parent.value[startDateName] ? 
        moment(control._parent.value[startDateName]).format('MM/DD/YYYY') : '';
      const endDate = moment(control.value).format('MM/DD/YYYY');
      let isValid = true;
      if (startDate && endDate) {
        isValid = moment(startDate).isSameOrBefore(endDate);
        if (control.root.controls[startDateName].errors && isValid) {
          control.root.controls[startDateName].setErrors(null);
        }
      }
      return isValid ? null : { invalidMRPEndDate: true };
    } else {
      return null;
    }
  }
}


export class CustomErrorMesssage {
  static createError(fieldType: string, form: FormGroup, field: any): string {
    let errorMessage: string;
    switch (fieldType) {
      case 'SingleData': {
        errorMessage = this.singleControl(form, field);
        break;
      }
      case 'MultipleData': {
        errorMessage = this.multipleControl(form, field);
        break;
      }
      case 'groupingDataSingle': {
        errorMessage = this.groupingControl(form, field);
        break;
      }
      case 'groupingDataMultiple': {
        errorMessage = this.groupingControl(form, field);
        break;
      }
      default: {
        console.log('Error to another way');
        break;
      }
    }
    return errorMessage;
  }
  static singleControl(form: any, field: any): any {
    const fieldType = Object.keys(field.fieldType).toString();
    const fieldName = field.fieldType.SingleData.name;
    if (form.controls[fieldName].errors) {
      const allErrorType = Object.keys(form.controls[fieldName].errors).toString();
      const errorType = allErrorType.split(',')[0];
      return this.createValidationMessage(errorType, field.fieldType[fieldType].FieldValidation);
    }
    return null;
  }

  static multipleControl(form: any, field: any): any {
    const fieldType = Object.keys(field.fieldType).toString();
    const formFieldName = field.fieldType.MultipleData.formName;
    let errorMsg: string;
    form.controls[formFieldName].controls.forEach((element, index) => {
      if (element.errors) {
        const allErrorType = Object.keys(element.errors).toString();
        const errorType = allErrorType.split(',')[0];
        return errorMsg = this.createValidationMessage(errorType, field.fieldType[fieldType].data[index].FieldValidation);
      }
    });
    return errorMsg;
  }

  static groupingControl(form: any, field: any): any {
    const fieldType = Object.keys(field.fieldType).toString();
    const formFieldName = field.fieldType[fieldType].name;
    if (form.controls[formFieldName].errors) {
      const allErrorType = Object.keys(form.controls[formFieldName].errors).toString();
      const errorType = allErrorType.split(',')[0];
      return this.createValidationMessage(errorType, field.fieldType[fieldType].FieldValidation);
    }
    return null;
  }

  static createValidationMessage(errorType, field) {
    let errorMessage: string;
    field.forEach(element => {
      if (element.key.toLowerCase() === errorType.toLowerCase()) {
        errorMessage = element.validationMessage;
      }
    });
    return errorMessage;
  }
}

function exponentConvertToNumber(numIn: any) {
  numIn += '';                                            
  let sign = '';                                           
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  numIn.charAt(0) === '-' && (numIn = numIn.substring(1), sign = '-');
  let str = numIn.split(/[eE]/g);                        
  if (str.length < 2) { return sign + numIn; }                   
  const power = str[1];                                    
  if (power === 0 || power === -0) { return sign + str[0]; }       
 
  const deciSp = 1.1.toLocaleString().substring(1, 2);  
  str = str[0].split(deciSp);                        
  let baseRH = str[1] || '',                         
      baseLH = str[0];                               
 
   if (power > 0) {   
      if (power > baseRH.length) { baseRH += '0'.repeat(power - baseRH.length); } 
      baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power);      
      if (baseRH.charAt(baseRH.length - 1) === deciSp) { baseRH = baseRH.slice(0, -1); } 
   } else {        
      const num = Math.abs(power) - baseLH.length;                              
      if (num > 0) { baseLH = '0'.repeat(num) + baseLH; }                       
      baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power);     
      if (baseLH.charAt(0) === deciSp) { baseLH = '0' + baseLH; }                
   }
  return sign + baseLH + baseRH;                                         
}
