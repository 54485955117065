
import { UntypedFormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ReportDataService } from '../../data/report-data.services';
import { BaseReport } from '../common/base-report';
import { ReportBusinessService } from '../report-business.service';
import { AllReports, ReportAPIOptions, ReportParams, ReportUIConfig } from '../report.modals';

export class LockerUtilizationreport extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public code: string,public business: ReportBusinessService) {
		super(reportFormGrp);
	}

	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: AllReports.LockerUtilization,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		};
	}

	getReportUIConfig(): ReportUIConfig {
		
		return {
			startDatePicker: true,
			endDatePicker: true,
			inActiveToggle: false,
			inActiveToggleName: '',
			pageBreakToggle: false,
			allowFutureDate:true,
			layout: 'PORTRAIT'
		};
	}
	protected formReportParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		let selected = this.reportFormGrp.controls['customFilterFormGrp'].value.clientTobeSelected? this.business.FilterDataSource['selectedClient'] : "";
        let selectedClient = selected ? selected.name : "";
		const servicesCaption: string = this.business.getFilterCaption(this.business.FilterDataSource['services'], this.reportFormGrp.controls['customFilterFormGrp'].value.services.map(s=>s.id));
        const lockerTypesCaption: string = this.business.getFilterCaption(this.business.FilterDataSource['lockerTypes'], this.reportFormGrp.controls['customFilterFormGrp'].value.lockerTypes.map(s=>s.id));
		return [{ pGuestName : selectedClient },
			{pServices: servicesCaption},
			{pLockerType: lockerTypesCaption},
			{ pStartDate: toApi(this.startDate) },
			{ pEndDate: toApi(this.endDate) },
			{ pPropertyName: this.propertyName },
			{ pDate: this.printedDate }
		];
	}
	protected formURIParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
        let selected = this.reportFormGrp.controls['customFilterFormGrp'].value.clientTobeSelected ? this.business.FilterDataSource['selectedClient']:"";
        let guestId = selected ? selected.guestId : Guid.EMPTY;
		return [ { "guestId": guestId },{ startDate: toApi(this.startDate) }, { endDate: toApi(this.endDate) } ];
	}
	protected formFilters(): any {
        let toApi = this.localization.convertDateObjToAPIdate;
		let serviceIds: number[] = this.reportFormGrp.controls['customFilterFormGrp'].value.services.map(s=>s.id);
		let selected =this.reportFormGrp.controls['customFilterFormGrp'].value.clientTobeSelected? this.business.FilterDataSource['selectedClient']: "";
        let guestId = selected ? selected.guestId : Guid.EMPTY;
		let lockerIds: any = this.reportFormGrp.controls['customFilterFormGrp'].value.lockerTypes.map(s=>s.id);
		return {
			guestId: guestId,
			serviceIds : serviceIds,
			lockerIds : lockerIds,
			StartDate: toApi(this.startDate),
			EndDate: toApi(this.endDate)
		};
	}

    
	
}
