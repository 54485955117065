import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';

@Injectable()
export class SlideActionBusiness {
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private localization: Localization) {
    this.captions = this.localization.captions;
  }
}
