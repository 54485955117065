import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ValidationMessage } from '../../basereport/base-report.component';
import { ReportBusinessService } from '../../business/report-business.service';
import { DropDownData } from '../../business/report.modals';
import { ReportDataService } from '../../data/report-data.services';

@Component({
  selector: 'app-staff-schedule',
  templateUrl: './staff-schedule.component.html',
  styleUrls: ['./staff-schedule.component.scss']
})
export class StaffScheduleComponent implements OnInit {
  staffScheduleFrmGrp: UntypedFormGroup;
  captions: any;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  @Output() validateUser = new EventEmitter<boolean>();
  radioFilter = [] ;
  therapists: DropDownData[] = [];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  isClass: boolean = false;
  therapistData: DropDownData[] = [];
  validTherapistSelection: boolean;
  validTherapistErrMsg: string;
  IncludeInactive: any;
  StaffDropDown: any;
  reportTypes: { id: number; name: any; }[];
  constructor(private fb: UntypedFormBuilder,private cdr: ChangeDetectorRef,private dataService: ReportDataService,public business: ReportBusinessService, private controlContainer: ControlContainer,public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
    this.reportTypes =[
      { id: 0, name: this.captions.Therapist },
      { id: 1, name: this.captions.Instructor}
     ];
    this.radioFilter =[
    { id: 0, name: this.captions.Both },
    { id: 1, name: this.captions.Actual},
    { id: 2, name: this.captions.Oncall}
   ];
   }

  ngOnInit() {
    this.staffScheduleFrmGrp = this.fb.group({
      includeInactiveTherapists: false,
      reportTypes:0,
      radioFilter: 0,
      therapists: new UntypedFormControl([this.defaultData]),
    });
    this.onLoad();
    this.isValidText(true);
    this.formReady.emit(this.staffScheduleFrmGrp);
  }

  private async onLoad() {
    this.getCaptions();
    if(!this.isClass){
    this.therapistData = await this.dataService.getAllTherapist("ProjectedRevenue");
    this.therapists = this.therapistData.filter(t=>t.isActive);
    this.business.FilterDataSource["therapists"] = this.therapists;
  }
  else{
    this.therapistData = await this.dataService.getAllInstructors("ProjectedRevenue");
    this.therapists = this.therapistData.filter(t=>t.isActive);
    this.business.FilterDataSource["therapists"] = this.therapists;
  }
  }

  getCaptions()
  {
    this.IncludeInactive = this.isClass?this.captions.IncludeInactiveInstructors:this.captions.IncludeInactiveTherapist;
    this.StaffDropDown = this.isClass? this.captions.Instructors : this.captions.Therapists;
  }

  reportTypeChange(id){
    this.toggleIncludeInactive(false);
    this.staffScheduleFrmGrp.controls['includeInactiveTherapists'].setValue(false);
    this.staffScheduleFrmGrp.controls.includeInactiveTherapists.updateValueAndValidity();
    if(id == 1)
    {
      this.isClass = true;
    }
    else
    {
      this.isClass = false;
    }
    this.onLoad();
    this.isValidText(true);
    this.formReady.emit(this.staffScheduleFrmGrp);
  }

  getFormControlValue(event) 
  {
     if (event[0] === 'therapists') {
        this.staffScheduleFrmGrp.setControl('therapists', event[1]);
        } 
  }

  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

  toggleIncludeInactive(e){
    this.therapists = [];
    if(e){
     this.therapists = this.therapistData.map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    } else {
     this.therapists = this.therapistData.filter(t=>t.isActive).map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    }
    this.cdr.detectChanges(); 
    this.formReady.emit(this.staffScheduleFrmGrp); 
  }

  IsTherapistValid(validity: boolean) {
    this.validTherapistSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption:any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validTherapistErrMsg = this.localization.replacePlaceholders(caption,["option"],[this.captions.Therapists]);
    }
  }

}