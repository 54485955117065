import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { DataImportWorkFlow, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const DataImportSummaryConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, value?: any): any => {
    try {   
        const dataImportModel = {} as DataImportWorkFlow;
        dataImportModel.fileName = null;
        dataImportModel.workFlowName = value.workFlowName ? value.workFlowName : '';
        dataImportModel.isMultipleUpload = false;
        dataImportModel.uploadCategory = 0;
        dataImportModel.pageNumber = value.pageNumber;
        dataImportModel.pageSize = 100;
        dataImportModel.wexflowAuthToken = sessionStorage.getItem('WexFlowLogin') ?
        sessionStorage.getItem('WexFlowLogin') :  value.wexFlowAuthToken;
        dataImportModel.tenantIdentifier = sessionStorage.getItem('TenantIdentifier');
        dataImportModel.userId = parseInt(sessionStorage.getItem('UserId'), 10);
        dataImportModel.userName = sessionStorage.getItem('UserName');
        dataImportModel.wexflowBatchSize = 0;
        dataImportModel.OrderBy = value.order;
        const PostData = Object.assign(dataImportModel);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Data Import Summary Construction', error);
    }
};
