import { GridSearchAction, MasterInventoryManagement } from '../master.interface';
import { GridModelConfiguration } from './grid-config.interface';

export const GridSearchPlaceholder: GridModelConfiguration = 
(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement): any => {
    try {
        // let searchText = 'Search by ';
        // if ( MasterDataCollection && MasterDataCollection.GridConfig &&
        //     MasterDataCollection.GridConfig.hasOwnProperty('GridSearch') &&  MasterDataCollection.GridConfig.GridSearch.length > 0 ) {
        //     const searchKeys = MasterDataCollection.GridConfig.GridSearch.map( (GridSearch: GridSearchAction) => 
        //     GridSearch.SearchPlaceholderName.toLowerCase());
        //     searchText = searchText + searchKeys.join(',');
        //     return searchText;
        // } else {
        //     return 'Search';
        // }
        return MasterDataCollection.GridConfig.GridSearch;
    } catch (error) {
        console.log('error occured GetSearchPlaceholderText', error);
    }
};
