import { PayloadMappingModel } from "../models/payloadMapping.interface";
import { RequestEngine } from "../request-engine/requestengine";

export class PayloadMappingEngine implements  PayloadMappingModel {
    TransactionType: number;
    FromLocation: boolean;
    constructor( FetchAll: boolean, LocationIds: Array<number> ) {
    }
    /**
     * @method MappingPostPayload
     * @input IntialArgument
     * @output MappingPayloadEngine
     * @description Mapping Payload
     */
    MappingPostPayload(requestUrl: any): any {
        try {
            return requestUrl;
        } catch (error) {
            console.error(error);
        }
    }
    /**
     * @method MappingGetPayload
     * @input IntialArgument
     * @output MappingGetPayload
     * @description Mapping Payload
     */
    MappingGetPayload(requestUrl: any): string {
        try {
            let endPointUrl = requestUrl;
            if (this.TransactionType) {
                endPointUrl = `${endPointUrl}/` + this.TransactionType
            } if (this.FromLocation !== undefined && this.FromLocation !== null) {
                endPointUrl = `${endPointUrl}/` + `${this.FromLocation}`
            }
            return endPointUrl
        } catch (error) {
            console.error(error);
        }
    }

}