import { Component, AfterViewInit, ViewChild, ViewEncapsulation, ElementRef, OnInit, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';

export interface AutoCompleteData {
    Id: number;
    Value: string;
    Checked?: boolean;
}

@Component({
    selector: 'lib-auto-complete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AutoCompleteComponent implements OnInit, ICellEditorAngularComp, OnChanges, AfterViewInit {
    textValue: string;
    autoCompleteParams: any;
    value: any;
    defaultValue: any;
    enabledCancel: boolean;
    autoCompleteData: Array<AutoCompleteData> = [];
    displayValue: string;
    constructor() {
        this.enabledCancel = false;
    }
    ngOnInit(): void {
        console.log('autoCompleteParams');
    }
    cancelautocomplete(): void {
       try {
        this.autoCompleteParams.selectData = [];
        this.autoCompleteParams.stopEditing();
       } catch (error) {
           console.error(error);
       }
    }
    agInit(params: any) {
        this.autoCompleteParams = params;
        this.autoCompleteParams.selectData = [];
        this.displayValue = this.autoCompleteParams.value;
        this.textValue = this.displayValue;
    }
    init(params: any) {
      this.autoCompleteParams = params;
    }
    getValue(): any {
      return this.displayValue;
    }
    isCancelAfterEnd(): boolean {
      return false;
    }
    addSupplier(SupplierList: AutoCompleteData): void {
      try {
        const rowNode = this.autoCompleteParams.api.getRowNode(this.autoCompleteParams.rowIndex);
        rowNode.setDataValue('AccountRangeFrom', SupplierList.Id);
        this.displayValue = SupplierList.Value;
        this.autoCompleteParams.value = SupplierList.Id;
        this.autoCompleteParams.data[this.autoCompleteParams.UpdatedProperty] = SupplierList.Id;
        this.autoCompleteParams.stopEditing();
      } catch (error) {
        console.error(error);
      }
    }
    textChanged() {
      console.log('Text Changed', this.autoCompleteParams);
      if (this.textValue && this.textValue !== '' && this.textValue.length > 0) {
          this.enabledCancel = true;
          this.autoCompleteParams['searchParams'] = this.textValue;
          if (this.autoCompleteParams.hasOwnProperty('colDef')) {
            this.autoCompleteParams.colDef.valueFormatter(this.autoCompleteParams, true);
          }
          this.serchTotop();
      } else {
        this.displayValue = '';
        this.autoCompleteParams.value = null;
        this.autoCompleteParams.data[this.autoCompleteParams.UpdatedProperty] = null;
      }
    }

    serchTotop(): void {
      try {
        this.autoCompleteData = this.autoCompleteParams.colDef.cellEditorParams.selectData;
        const GetBodyBottomBoundary = document.getElementsByClassName('layout-container')[0].getBoundingClientRect();
        const GetBodyAutoCompleteBoundary = document.getElementsByClassName('search-input')[0].getBoundingClientRect();
        const GetDifference = ( GetBodyBottomBoundary.bottom - GetBodyAutoCompleteBoundary.bottom);
        if ( GetDifference < 150 ) {
            document.getElementsByClassName('expense-invoice-grid-container')[0].classList.add('search-element-top');
        } else {
          document.getElementsByClassName('expense-invoice-grid-container')[0].classList.remove('search-element-top');
        }
      } catch (error) {
          console.error( error );
      }
    }

    ngAfterViewInit() {
      console.log('to-top');
    }
    ngOnChanges(): void {
     console.log('top');
    }
}
