import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DetailsHeaderData, ItemTableCardConfig, TransactionTableData } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import * as GlobalState from 'src/app/eatecui/source/store/actions/global-state.action';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
@Component({
    selector: 'app-item-card',
    templateUrl: './item-card.component.html',
    styleUrls: ['./item-card.component.scss']
})

  export class TransactionItemCardComponent implements OnInit, OnChanges {
      tableCardHeader: Array<DetailsHeaderData>;
      tableCardBody: Array<any>;
      tableLayout: string;
      itemTableBuilderhead: TransactionTableData[];
      @Input() itemTableCardConfig: ItemTableCardConfig;
      @Input() formValid: boolean;
    constructor(  private store: Store<AppState> ) {
     
    }
    openFormDetails(): void {
      this.store.dispatch(new GlobalState.OutSideClickAction(true));
      if (this.itemTableCardConfig.hasOwnProperty('ItemHeaderExpand')) {
        this.itemTableCardConfig.ItemHeaderExpand();
      }
    }

    ngOnChanges(): void {
        console.log('Transaction Module loaded', this.itemTableCardConfig);
        this.tableCardHeader = this.itemTableCardConfig.ItemTableCardHeader;
        this.tableCardBody = this.itemTableCardConfig.ItemTableBuilder.ItemTableBuilderBody;
        this.tableLayout = this.itemTableCardConfig.ItemTableBuilder.ItemTableLayout;
        this.itemTableBuilderhead = this.itemTableCardConfig.ItemTableBuilder.ItemTableBuilderhead;
    }
    ngOnInit(): void {
       console.log('Transaction Module loaded');
    }
  }