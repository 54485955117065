import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';

@Component({
  selector: 'lib-label-icon',
  templateUrl: './label-icon.component.html',
  styleUrls: ['./label-icon.component.scss']
})
export class LabelIconComponent implements OnInit {

  @Input() field: any = {};
  @Input() form: FormGroup;
  formFieldLabelName: string;
  
  constructor(
    public agyDynamicFormServices: AgyDynamicFormServices
  ) { }

  ngOnInit(): void {
    this.formFieldLabelName = this.field.fieldType.SingleData.label;
  }

  defaultIconClick(): void {
    this.agyDynamicFormServices.formEventCallback(this.field, 'edit', this.form);
  }

}
