import { IntegrationOperationsTransactionType, Voucher } from "src/app/common/Models/common.models";
import { MemberPayeeInfo } from "../../business/Member-business.service";
import { HandleData, CardInfo, PayAgentCardInfo, PropertyInfo } from "../../business/shared.modals";
import { GatewayConfigurationType, GiftCardTransactionType } from "../../globalsContant";
import { ACESPaymentRecord, RainCheckIssue } from '../../shared.modal';
import { PostingRecords } from "src/app/retail/Folio/Model/folioDetails-model";
import { GatewayConfiguration, GiftcardSearchResult, TransactionState, TransactionUpdate } from "./payment-model";
import { Guid } from "guid-typescript";
import { SearchTypes } from "../retail.feature.flag.information.service";
import { Ticket } from "../common-variables.service";

//-----------------------------------------------------------------------------------------
//Request Model for Auth/Sale:

export interface SaleRequest {
    originalTenderId?: number;
    handle: string; //(Required)(Encrypted data)
    amount: Amount; //(Required)
    inquirerInfo: InquirerInformation; //(Required)
    requestId?: string;
    transactionType?: IntegrationOperationsTransactionType;
    transactionAmount?: Number;
    transactionId?: number;
    ticketNumber?: number;
    paymentMethodId?: number;
    paymentMethodTableId?: number;
    paymentTypeId?: number;
    parentTypeId?: number;
    roomNumber?: string;
    roomReference?: string;
    guestName?: string;
    paymentReferenceComments?: string;
    transactionPaymentId?: number;  
    multiPMSPropertyIndex?: string;
    multiPropertyPropCode?: string;
    pMSIntegrationHostId?: string;
    postingRecords?: any[]
    multiPropertyFeatureConfigurations?: any[];
    paymentMethodAdditionalConfigurations?: any[];
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    paymentDetails?: PaymentDetails;
    searchValue?: string;
    searchType: SearchTypes;
    bookingId?: string;
    allowTip?: boolean;
}

export interface Amount {
    requestAmount: number; // (Required)
    authorisedAmount?: number;
    tipAmount?: number;
    taxAmount?: number;
    saleAmount?: number;
}

export interface InquirerInformation {
    terminalId: string; // (Required)
    store?: string;
    division?: string;
    enterprise?: string;
    customer?: string;
    mealPeriod?: string;
    coverCount?: string;
    orderType?: string;
    orderNumber: string; // (Required)
    employeeId?: string;
    profitCenter: string; //(Required)
    profitCenterName?: string; //(Required)
    tenderId: string; //(Required)
    zipcode?: string;
    isPartialTenderAllowed:boolean;
    financialBins?: Array<FinancialBin>;
    id?: string;
    zoneOffset?: string;
    manualCardEntry?: boolean;
    ReceiptTextImage?: string;
    pin?: string;
    clientId: string;
    postingId: string;
    posTransactionId?: number;
    propertyInfo?: PropertyInfo;
    isFolioPosting?: boolean;
    sourceTypeId?: string;
    ticketNumber?: string;
    stayId?: string;
    postId?: string;

    //Tip Implementation

    /** The first tip preset value  */
    tipPreset1?: number;
    /** The second tip preset value  */
    tipPreset2?: number;
    /** The third tip preset value  */
    tipPreset3?: number;
    /** The fourth tip preset value  */
    tipPreset4?: number;
    /** Specifies the tip preset type to be used, if available, on the device = ['none', 'amount', 'percent'] */
    tipPresetValueType?: string;
    /**If the value is set, tip amount calculation will be based on this value */
    tipBasisAmount?: number;
    /** If specified, this amount is "seeded" as the suggested tip amount on the device */
    tipSeedAmount?: number;
}

/** Tip Preset Value Type used for Credit card Transactions */
export const TipPresetValueType = {
    None: "none",
    Amount: "amount",
    Percent: "percent"
}


export interface FinancialBin{
    Id: string,
    type: string,
    amt: string
}

//-----------------------------------------------------------------------------------------
//Response Model for Auth/Sale:

export interface SaleResponse {
    payAgentId: string;
    status: string;
    account: DisplayInformation;
    amount: Amount;
    transactionDetails: string; //(Encrypted)
    transactionKey: string;
    errorMessage: string;
    cardInfo: PayAgentCardInfo;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    requestId?: string;

    //Exclusively For Rewamped Flow
    pMSCommunicationInterfaceRequestResponse?: PMSCommunicationInterfaceRequestResponse;
    paymentBaseResponse?: any;
}

export interface PMSCommunicationInterfaceRequestResponse{
    endPoint?: string;
    request?: string;
    response?: string;
}

export interface DisplayInformation {
    id: string;
    name: string;
    type: string;
}

export interface TransactionDetails {
    handle: HandleData;
    configurationId: number;
    allowVoidSale: boolean;
    status: number;
    Amount: Amount;
    transactionTime: string
}

//-----------------------------------------------------------------------------------------



export interface PaymentMethod {
    id: number;
    isActive?: boolean;
    isDefault?: boolean;
    listOrder?: number;
    paymentMethod?: string;
    paymentMethodId?: number;
    paymentTypeId: number;
    paymentTypeEqualant?: number;
    type?: string;
    isDisabled?: boolean;
    allowEarn?: boolean;
    postTypeId?: number;
    requireComments?: boolean;
    noOfReceipts?:number;
    requireReceipts?:boolean;
    financialBinLevel?: number;
    isSurcharge?: boolean;
    isPercent?: boolean;
    surchargeValue?: number;
    parentMethodId?: number;
    parentTypeId?: number;
    isCloned?: boolean;
    additionalConfigurations?: string;
    requireSignatureCapture?: boolean;
    bucketCode?:string;
    bucketName?:string;
    isAutoRemoveTax?: boolean;
    tenderReducesDiscount?: boolean;
    corpId?: number;
    allowZeroPosting?: boolean;
}
export const PaymentMethodIconConst = {
    "1": "pay-cash",
    "2": "pay-card",
    "22": "pay-card", //For Card-OnFile
    "3": "pay-room-charge",
    "4": "pay-pending-settlement",
    "44": "pay-others",
    "6": "rain-check",
    "7": "AR_Post", //AR Post
    "8": "Round_Redeem", //Redeem Round
    "9": "Credit_book", //Credit Book,
    "10": "Comp-Redemption",
    "11": "offer-redemption",
    "12": "v1-gift-card",
    "13": "external-gift-card",
    "17": "external-gift-card",
    "16": "pay-others",
    "19": "comp-slip-redemption",
    "20": "pay-room-charge",
    "21": "post-to-folio",
    "24": "everi-wallet", //Wallet
    "25": "discount",
    "26":"pay-card",
    "999999999": "pay-others"
}
export const PaymentMethodDisplayLabel = {
    1: "Cash",
    2: "Credit Card",
    3: "Room Charge",
    5: "Credit Card",
    6: "Rain Check",
    22: "CardOnFile",
    7: "AR Post",
    8: "Round Redeem",
    9: "Credit Book",
    10: "Comp Redemption",
    11: "Offer Redemption",
    12: "Gift Card"
}

export const ARSenderCode = {
  STAY:"STAY",
  LMS:"LMS",
  V1:"V1",
  COMTROL: "COMTROL",
  RESORT_SUITE: "RESORTSUITE",
  OPERA: "OPERA",
  INFOR: "INFOR",
  LIGHT_SPEED: "LIGHTSPEED",
  FOSSE: "FOSSE",
  FSPMS: "FSPMS"
}

export interface ValidateSaleRequest {
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    tryPayResponse: TryPayResponse;
    cardInfo: CardInfo;
}

export interface PayAgentResponse{
    payAgentID: string,
    status: string,
    transactionDetails: string,
    transactionKey: string,
    errorMessage?: string
}

export const HttpResponseStatus = {
    Success: "success",
    Failure: "failure"
}

export const PMSIntegrationHostId = 'PMSIntegrationHostId';
export const PMSXMLDBInterface = 'PMSXMLDBInterface';
export const PMSHTTPInterface = 'PMSHTTPInterface';
export const ValidateCreditLimit = 'VALIDATECREDITLIMIT';
export const EnableZeroPosting = 'ENABLEZEROPOSTING';
export const IFrameURL ="IFRAMEURL"

export interface TryPayRequest {
    transactionId: number;
    paymentMethodId: number;
    tenderId: number;
    parentTenderId: number;
    amount: number;
    allowEarn: boolean;
    surcharge: number;
    financialBinLevel: number;
    multiPMSPropCode: string;
    pMSIntegrationHostId: string;
    isAutoRemoveTax: boolean;
    tenderReducesDiscount?: boolean;
    isAllowZeroPosting: boolean;
    discountExemptRatio?:number;
    taxExemptRatio?:number;
}

export interface TryPayResponse {
    ticketNumber: string;
    checkHandleGuid: string;
    tenderId: number;
    amount: number;
    transactionId: number;
    transactionPaymentId: number;
    paymentReferenceId: number;
    checkJSON:string;
    financialBins: POSFinancialBin[];
    postingRecords: POSPostingRecord[];
    paymentMethodId: number;
    surcharge:number;
    clientId: string;
    postingId: string;
    postingCheckNumber: string;
    checkZoomString: string;
}

export interface POSFinancialBin {
    id: number;
    amount: number;
    quantity: number;
    type: number;
}

export interface POSPostingRecord {
    postTypeNumber: string;
    amount: number;
    taxableAmount: number;
    comments: string;
    reference: string;
    recordType: number;
    groupingId: number;
    paymentMethodId: number;
    doNotPost: boolean;
    sourceSystem: string;
    otherData: any;
}

export interface PostingRecordsAndCheckZoomDetails{
    financialBins: POSFinancialBin[];
    postingRecords: POSPostingRecord[];
    checkZoomString: string;
    postingCheckNumber: string;
    outletName: string;
    outletId: number;
    clientId: string;
    postingId: string;
    transactionAdditionalReference?: string;
    transactionAdditionalComment?: string;
}

export interface GuestStayDetail {
    guestId: string;
    roomNo: string;
    confirmationNumber: string;
    status: ActivityStatus;
}


export enum ActivityStatus {
    Unknown = -1,
    Reserved,
    CheckedIn,
    CheckedOut,
    NoShow,
    Cancelled
}

export interface PaymentHistory {
    cardType?: string;
    paymentMethodId: number;
    paymentMethod: string;
    parentTenderId: number;
    paymentReferenceComment?: string;
    issuerType?: string;
    cardNumber?: string;
    entryMode?: string;
    amount: number;
    isReversed?: boolean;
    paymentReferenceId?: number;
    isPrefilled?: boolean;
    additionalDetails?: GiftcardSearchResult | ArAccount | GuestRoomSearchResult | RainCheckIssue | Voucher | MemberPayeeInfo | string;
    cashPaymenHandle?: any;
    disableDelete?: boolean;
    giftcardTransactionType?: GiftCardTransactionType;
    surcharge?: number;
    tipAmount?: number;
    memberInfo?: MemberPaymentHistory;
    paymentMethodInfo?: PaymentMethod;
    paymentMethodMasterId?: number
    folioSourceType?: string;
    folioSourceTypeId?: string;
    folioInvoiceNumber?: string;
    folioGuestName?: string;
    interfaceGuestId?: string;
    taxExemptRatio?: number;
    discountExemptRatio?: number;
    tenderReducesDiscount?: boolean;
    tenderReducesTax?: boolean;
}

export interface MemberPaymentHistory {
    memberName: string;
    arAccountNumber: string;
    golfPoints: string;
    memberCardNumber: string;
    paymentReferenceId: number;
}

export enum CardInputMethod {
    None,
    All = 1,
    KeyIn,
    SwipeInsertTap
}

export interface CardEntryModeDialogResult {
    action: CardEntryModeDialogAction;
    cardInputMethod?: CardInputMethod;
}

export enum CardEntryModeDialogAction {
    Proceed = 1,
    Cancel
}

export interface DomainGatewayValue {
    id: number;
    configurationId: number;
    configKey: string;
    configValue: string;
    gatewayConfiguration: GatewayConfiguration;
}

export interface SaleByTokenRequest
{
    inquirerInfo: InquirerInformation;
    transactionId: number;
    amount: number;
    surcharge: number;
    sourceType? : string;
    sourceTypeId? :string;
    roomNumber? :string;
    folioNumber?: string;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    postId?:number;
    guestGuid: string;
}

export interface SaleByTokenResponse
{
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    errorCode?: string;
    paymentManagerResponse : PMPayResponse;
    transactionIdGeneratedFromToken?: number; 
}

export interface AuthByTokenRequest {
    inquirerInfo: InquirerInformation;
    transactionId: number;
    amount: number;
    sourceType : string;
    sourceTypeId :string;
    roomNumber :string;
    folioNumber:string;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    postId?:number;
    guestGuid?: string;
}

export interface AuthByTokenResponse {
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    errorCode?: string;
    paymentManagerResponse: PMPayResponse;
}

export interface ValidateAuthResponse {
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    paymentManagerResponse: PMPayResponse;
}

export interface PaymentRecalcInfo{
    selectedPayment: PaymentMethod ,
    amounttendered: number,
    settledAmount: number,
    amountChanged: boolean
}

export interface ValidatePayRequest
{
    paymentMethodId: number;
    tenderId: number;
    parentTenderId: number;
    retailTicketNumber?: string;
    payAgentResponse: PayAgentResponse;
    cardInfo: CardInfo;
    amount: number;
    surcharge: number;
    aCESPaymentRecord:ACESPaymentRecord[];
    cMSPaymentRecord: CMSPaymentDetails;
    giftcardPaymentRecord: GiftCardPaymentRecord;
    aRPostingPaymentRecord: ARPostPaymentResponse;
    resortFinanceFolioPostingPaymentRecord? : ResortFinanceFolioPostingPaymentRecord;
    roomNumber?: string;
    reservationName?: string;
    roomReference?: string;
    userId?: string;
    referenceComment?: string;
    sourceType?: string;
    sourceTypeId?: string;
    folioNumber?: string;
    machineId?: number;
    hostName?: string;
    agentVersion?: string;
    cashPaymentDetails?: CashPaymentDetails;
    ipAddress?: string;
    postId?: number;
    requestId?: string;
    guestGuid: string;
    gatewayResponse?: string;
    transactionState?: TransactionState;
    propertyId?: number;
    lodgingData?: string;
    searchValue?: string;
    searchType : SearchTypes
    tokenRefId?:number
}

export interface ResortFinanceFolioPostingPaymentRecord {
    id?: number;
    paymentTransactionId?: number;
    amount: number;
    isVoided: boolean;
    guestProfileId: string;
    clientId: number;
    folioNumber: string;
    folioId: number;
    product: number;
    folioInvoiceNumber : string;
    logId?: number;
}

export interface TransactionInfoforCheckZoom
{
    id: number;
    outletId: number;
    isVoided: boolean;
    retailTicketNumber: string;
    transactionDate: string
}
export interface ValidateAuthRequest {
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    cardInfo: CardInfo;
    amount: number;
    sourceType : string;
    sourceTypeId :string;
    roomNumber :string;
    folioNumber:string;
    userId?: string;
    requestId?: string;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    guestGuid?: string;
    gatewayResponse?: string;
    transactionState?: TransactionState;
    propertyId?: number;
    lodgingData?: string;
}

export interface ValidatePayResponse
{
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    paymentManagerResponse : PMPayResponse;

}

export interface ValidateAuthResponse extends ValidatePayResponse{}

export interface PMPayResponse
{
    status?: string;
    transactionId?: string;
    transactionTime: Date | string;
    amount: Amount;
    inquirerInfo: InquirerInformation;
    clientInfo?: ClientInfo;
    errorMessage?: string;
    totalAuthorizedAmount?: number;
}

export interface ClientInfo
{
    accountName : string;
    accountNumber : string;
    authCode : string;
    roomNumber : string;
}

export interface PayRequest
{
    payResponse: PMPayResponse;
    tryPayResponse: TryPayResponse;  
    transactionUpdate?: TransactionUpdate
}

export interface UserSessionConfiguration{
    defaultCourseId: number;
    defaultDeviceName: string;
    defaultOutletId: number;
    defaultPaymentDevice: string;
    hangingTicketsPrinter: string;
    id: number;
    isIdtechSred: boolean;
    propertyId: number;
    smallStickersPrinter: string;
    subPropertyId: number;
    userId: number;
}

export interface CMSPaymentDetails{
    id?: number;
    paymentTransactionId?: number;
    patronId: string;
    patronName: string;
    amount: number;
    redemptionType: CMSRedemptionType;
    name: string;
    voucherId: string;
    voucherType: string;
    voucherExpirationDate: Date | string;
    voucherRedeemTransactionId: string;
    isVoided?: boolean;
    vendorType: string;
    playerPin?: string;
    compRedeemTransactionId: string;
    retailTicketNumber: number;
    offerPaymentMethodId: number; //Id from CMS
}

export interface GiftCardPaymentRecord {
    id?: number;
    paymentTransactionId?: number;
    amount: number;
    type: number;
    isVoided: boolean;
    serviceManagerReferenceId?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    cardNumber: string;
    expiryDate: string;
    isLoaded?: boolean;
    loadedDate?: string;
    isRedeemed?: boolean;
    redemptionDate?: string;
    isIssued?: boolean;
    issuedDate?: string;
    productId?: number;
    referenceId: string;
    propertyId?: number;
  }

  export interface CashPaymentDetails {
    id?: number,
    paymentTransactionId?: number,
    amountDue: number,
    cashReceived: number,
    changeDue: number,
    returnTransactionId?: number,
    isReturn?: boolean,
    transactionDateTime?: string
  }

export enum CMSRedemptionType {
    Points = 1,
    Comps,
    Offers,
    CompSlip
}

export enum SourceTypeConstant{
    RetailTransaction = 7
}

export enum HandleType {
    Points = "Points",
    Comps = "Comps"
}

export const RedemptionType = {
    [HandleType.Points]: CMSRedemptionType.Points,
    [HandleType.Comps]: CMSRedemptionType.Comps
}

export const RedemptionTypeToHandle = {
    [CMSRedemptionType.Points]: HandleType.Points,
    [CMSRedemptionType.Comps]: HandleType.Comps,
    [CMSRedemptionType.Offers]: ""
}

export interface TransactionPaymentAggregate{
    transactionPayment : TransactionPayment;
    additionalInformation: AdditionalPatronInformation;
}

export interface TransactionPayment {
    id: number;
    transactionId: number;
    paymentAmount: number;
    paymentMethod: string;
    paymentDate: Date | string;
    authorizationCode: string;
    outletId: number;
    propertyId: number;
    subPropertyId: number;
    paymentReferenceId: number;
    paymentStatus: number;
    linkedPaymentReferenceId: number;
    isRefunded?: boolean;
    parentTenderId?: number;
    doNotDisplay?:boolean;
    errorMessage:string;
    discountExemptRatio?:number;
    taxExemptRatio?:number
}

export interface AdditionalPatronInformation {
    PatronId: string;
    PatronName: string;
    Amount: number;
    RedemptionType: number;
    VoucherId: string;
    VoucherType: string;
    Name: string;
    VendorType: string;
    WalletNumber: string;
}

export interface CMSSettlementData
{
    patronId: string;
    redemptionType: number;
    refundAmount: number;
    saleAmount: number;
    refunded: boolean;
    pmAgentCallReq: boolean;
    voucherId: string;
    voucherType: string;
    name: string;
    playerPin: string;
}

export interface VoucherRedemptionResponse {
    transactionId: string;
    voucherId: string;
    voucherType: string;
    name: string;
    voucherAmount: number;
    offerPaymentMethodId: number; //Id from CMS
    debugMessage: string;
    message: string;
    returnCode: number
    vendorErrorCode: string;
}

export interface RedeemPointsResponse{
    transactionId: string;
    debugMessage: string;
    message: string;
    returnCode: number
    vendorErrorCode: string;
}

export interface PersonalDetails {
    firstName: string;
    lastName: string;
    pronounced: string;
    playerRank: string;
    dateOfBirth: string;
    gender: string;
    address: Address;
    email: Email[];
    phoneNumber: Phone[];
}

export interface Address{
    addressLine1: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
}

export interface Email{
    emailTypeId: number;
    emailAddress: string;
}

export interface Phone {
    phoneNumber: string;
    extension: string;
    countryCode: string;
    phoneTypeId: number;
    isPrimary: boolean
}

export interface ArAccount {
    accountName: string;
    accountNumber: string;
    isEmittedForPrefill?: boolean;
}

export enum ArAcctSearchType {
    SearchByName,
    SearchByNumber
}

export enum GuestRoomSearchType{
    SearchByGuestName,
    SearchByRoomNumber,
    SearchByRoomkey,
    SearchByGroupName,
    SearchByBookingID
}

export interface ARPostPaymentRequest extends ArAccount {
    amount: number;
    profitCenter: string;
    checkNumber: string;
    financialBins: ARFinancialBin[];
    outletName:string;
    outletId:number;
    ticketNumber:number;
    tenderId:number;
    parentTenderId:number;
}
export interface ARFinancialBin {
    type: string;
    id: number;
    amount: number;
}

export interface ARPostPaymentResponse {
    postingStatus: string;
    postingMessage: string;
    amount: number;
    profitCenter: string;
    checkNumber: string;
    financialBins: ARFinancialBin[];
    accountNumber: string;
    accountName: string;
    outletName:string;
    outletId:number;
    ticketNumber:number;
}

export interface LookUpInput {
    arAccountName: string;
    arAccountNumber: string;
    isCustomUIRequired: boolean;
    isCustomCaptureSection?: boolean;
    selectedPaymentMethod?: any;
    profitCenter?: number;
}

export interface GuestRoomSearchResult {
    GuestName: string;
    RoomNumber: string;
    handle: string;
    RoomReference: string;
    isChargingAllowed: boolean;
    groupBookingId?: string;
    propertyInfo?: PropertyInfo;
    phoneNumber?: string;
    balance: number;
    creditLimit?: number;
    SearchValue?: string;
    SearchType: SearchTypes;
    HotelId?: string
}

export enum DepositTransactionStatus {
    Pending = 1,
    Closed = 2
}

export interface PaymentDetails {
    paymentMethodId: number;
    paymentMethod: string;
    parentTenderId: number;
    issuerType?: string;
    cardNumber?: string;
    entryMode?: string;
    amount: number;
    patronId?: string;
    cMSType?: number;
    offerName?: string;
    paymentReferenceId?: number;
    vendorName?: string;
    aRAccountNumber?: string;
    aRAccountName?: string;
    redemptionType?: number;
    roomNumber?: string;
    screenRoute?: string;
    referenceComment?: string;
    disableDelete?: boolean;
    giftcardTransactionType?: GiftCardTransactionType;
    cardType?: string;
    reservationName?: string;
    paymentMethodMasterId?: number;
    folioInvoiceNumber?: string;
    walletNumber?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    sourceType?: string;
    sourceTypeId?: string;
    folioNumber?: string;
    postId?: string;
}
export interface PaymentHistoryDetails {
    paymentHistory: PaymentDetails[];
    isHavingPaymentHistory: boolean;
}

export interface SurchargeConfiguration extends SurchargeConfigurationAPI {
    issuerName: string;
    placeHolder?: string;
    maxLength?: number;
    decimalMaxLength?: number;
    lastIndex?: boolean;
    uniqueId?: Guid;
    isValid?: boolean;
    isDefault? :boolean;
}

export interface SurchargeConfigurationAPI {
    id: number;
    paymentMethodId: number;
    paymentTypeId: number;
    CreditCardIssuerTypeId?: number;
    typeId?: number;
    isActive: boolean;
    isPercentage: boolean;
    value: number;
    creditCardIssuerType?: CreditCardIssuerType;
}

export interface TipConfig {
    id: number;
    allowTipConfig: boolean;
    isPercentage: boolean;
    presetValue: number[]
}

export interface TipConfigAPI {
    id: number;
    enableTip: boolean;
    tipInPercentage: boolean;
    presetValues: string;
}

export interface CreditCardIssuerType{
    id: number;
    issuerType: string;
    displayName: string;
}
export interface TransactionSummary {
    id: number;
    transactionDate: string;
    clerkId: number;
    totalAmount: number;
    isVoided: boolean;
    outletId: number;
    propertyId: number;
    subPropertyId: number;
    transactionLinkId: number;
    retailTicketNumber: number;
    retailTransactionType: string;
    transactionPayments: TransactionPayment[];
  }

export interface ResortFinanceFolioInvoicePrintRequest{
    printFolioIds: string;
    resortFinanceFolioInvoicePrintOption: number;
    isStatementBySplit: boolean;
    folioInvoiceNumber: string;
    folioInvoiceGuestName: string;
    invoicePrintedAtProperty: string;
    invoicePrintedByClerkId: string;
    invoicePrintedByClerkName: string;
    invoiceColourTheme: string;
    sourceType: string;
    sourceTypeId: string;
}