import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
@Component({
  selector: 'app-correct-void',
  templateUrl: './correct-void.component.html',
  styleUrls: ['./correct-void.component.scss']
})
export class CorrectVoidComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  CorrectVoidFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  @Input() outlets: any;
  selectedDropdownType = '';
  valuesSelected: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  users: DropDownData[];
  inActiveText = '';
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, public business: ReportBusinessService, private dataService: ReportDataService) {
    this.inActiveText = this.captions.IncludeInactiveUsers;
   }

  ngOnInit() {
    this.CorrectVoidFormGrp = this.fb.group({
      users: new UntypedFormControl([]),
      includeInactiveusers: false
    });
    this.formReady.emit(this.CorrectVoidFormGrp);
    this.onLoad();
  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsers();
    this.users = this.business.toggleIncludeInactive(false,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.users = this.business.toggleIncludeInactive(IncludeInactiveToo,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
    this.CorrectVoidFormGrp.controls['users'].setValue([this.users]);
  }


  getFormControlValue(event) {
    if (event[0] === 'users') {
    this.CorrectVoidFormGrp.setControl('users', event[1]);
    }
  }
}
