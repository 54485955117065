import { Component, Input, OnInit, ViewChild, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Localization } from '../../localization/localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { dashBoardJasperConfigBusiness } from './dashboard-jasper-config.business';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subscription } from 'rxjs';
import { CustomizableDashboardDataService } from '../../dataservices/authentication/customizable-dashbard.data.service';
import { CustomizableDashboardDetails } from './dashboard-jasper-config.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { DashboardJasperCommonPopupComponent } from '../dashboard-jasper-common-popup/dashboard-jasper-common-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ResetDashboardConfiguration } from '../dashboard-jasper-common-popup/dashboard-jasper-common-popup.model';
import { JasperServerCommonDataService } from 'src/app/common/dataservices/jasperServerCommon.data.service';
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { AlertType, AlertAction, ButtonType } from 'src/app/common/shared/shared.modal';
import { ButtonValue } from '../../Models/ag-models';

@Component({
  selector: 'app-dashboard-jasper-config',
  templateUrl: './dashboard-jasper-config.component.html',
  styleUrls: ['./dashboard-jasper-config.component.scss'],
  providers:[dashBoardJasperConfigBusiness, CustomizableDashboardDataService],
  encapsulation: ViewEncapsulation.None
})
export class DashboardJasperConfigComponent implements OnInit {

  captions: any;
  dashboardViewList: any = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  customizableDashboardEnabled: boolean = false;
  productId: number;
  propertyId: number;
  userId: number;
  dashboardConfigurationForm: UntypedFormGroup;
  customizableDashboardDetails: CustomizableDashboardDetails[] = [];
  clearAllOptions: any[];
  customDashboardAllow: boolean;
  isCustomViewOnly: boolean = false;
  isUserAccessAllowed: boolean = false;
  @ViewChild('dashBoardConfig') dashBoardConfigTrigger: MatMenuTrigger;
  @Input() isCopyEnabled
  showSlide: boolean = false;
  header: string = '';
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  formsubcriber: Subscription;
  constructor(private localization: Localization,private utilities: CommonUtilities,private business: dashBoardJasperConfigBusiness,private fb: UntypedFormBuilder,public dialog: MatDialog,
    private jasperServerDataService : JasperServerCommonDataService,private PropertySettingService: PropertySettingDataService) { 
    this.captions = this.localization.captions;
    this.header = this.captions.lbl_Customizable_Dashboard;
  }

  ngOnInit(): void {
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
    this.dashboardConfigurationForm = this.fb.group({
    
    });
    this.business.isCustomizableDashboardEnabled().then(x => {
      this.customizableDashboardEnabled = x;
      if(this.customizableDashboardEnabled){
        this.initializeDashboard();
        this.validateBreakPoints();
      }
      else{
        this.dashboardViewList = []
      }
    });
    this.subscribeForm();
  }

  subscribeForm(){
    this.formsubcriber = this.dashboardConfigurationForm.statusChanges.subscribe(() => {
      let isActionButtonsDisable = this.business.shouldActionButtonsDisable(this.dashboardConfigurationForm);
      this.disableActionButtons(isActionButtonsDisable.isSaveDisable, isActionButtonsDisable.isCancelDisable);
    });
  }

  private disableActionButtons(isSaveBtnDisable: boolean = true, isCancelBtnDisable: boolean = true) {
    this.saveButton.disabledproperty = isSaveBtnDisable;
    this.cancelButton.disabledproperty = isCancelBtnDisable;
  }

  private async validateBreakPoints()
  {
    let result = await this.business.ValidateBreakPoints();
    this.customDashboardAllow = result.isAllow;
    this.isCustomViewOnly = result.isViewOnly;
    this.isUserAccessAllowed = this.customizableDashboardEnabled && (this.customDashboardAllow || this.isCustomViewOnly)
  }

  async initializeDashboard(){
    this.customizableDashboardDetails = await this.business.getDashBoardViews();
    this.refreshDashboards();
  }

  refreshDashboards(){
    this.dashboardViewList = this.business.dashboardConfigDetails;
  }


  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  editDashboards(event){
    this.showSlide = true;
  }

  clearAllDashboardConfig(){
    this.dialog.open(DashboardJasperCommonPopupComponent, {
      width: '500px',
      height: '232px',     
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if(result){
        this.setDashboardConfig(result);
      }
    });
  }

  async setDashboardConfig(result) {
    switch (result) {
      case ResetDashboardConfiguration.ClearDashboardConfiguration:
        this.customizableDashboardDetails.map(x => {
          x.dashboardName = '';
          x.dashboardURL = '';
        });
        this.saveAndRefreshDashboard(null);
        break;
      case ResetDashboardConfiguration.SetPropertyDefaultConfiguration:
        this.customizableDashboardDetails = await this.business.getCustomDashboardConfigByPropertyId();
        this.customizableDashboardDetails.map(x => x.userId = this.business.userId);
        this.saveAndRefreshDashboard(null);
        break;
    }
  }

  async saveAndRefreshDashboard(value){
    await this.save(value);
    this.refreshDashboards();
    this.dashboardConfigurationForm.markAsPristine();
    this.showSlide = false;
  }

  async save(value){
    this.customizableDashboardDetails = await this.business.saveCustomizableDashboard(value, this.customizableDashboardDetails);
  }

  async routeDashboard(dashBoard){
    if(dashBoard && this.customizableDashboardDetails.length > 0 && this.customizableDashboardDetails.find(x => x.customType == dashBoard.id && x.dashboardName != '' && x.dashboardURL != '')){
      let jasperServerURL: string = await this.jasperServerDataService.GetJasperServerBaseURL();   
      await this.PropertySettingService.UpdateRoleAndAttributeToUser()
      let baseUrl: string = this.customizableDashboardDetails.find(x => x.customType == dashBoard.id).dashboardURL;
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          window.open(baseUrl, '_blank');
        }
      });
      var _authtokenprovider = sessionStorage.getItem('authtokenProvider');
      var data = "";
      xhr.open("GET", jasperServerURL + "/rest_v2/serverInfo");
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("pp", sessionStorage.getItem("_jwt"));
      xhr.setRequestHeader("tokenProvider",_authtokenprovider);
      xhr.send(data);
    }  
  }

  closeSlide(){
    if(this.dashboardConfigurationForm.dirty){
      this.utilities.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.dashboardConfigurationForm.markAsPristine();
          this.disableActionButtons();
          this.showSlide = false;
        }
      });
    }
    else{
      this.showSlide = false;
    }
  }

  dashboardConfigEmit(eve){
    this.dashboardConfigurationForm.setControl('dashboardConfigArray', eve);
  }

  onSave(eve){
    console.log(eve);
    this.saveAndRefreshDashboard(this.dashboardConfigurationForm.value);
  }

  onCancel(eve){
    this.closeSlide()
  }

}
