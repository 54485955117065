import { Component,  Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { AgToggleConfig } from '../../models/ag-models';

@Component({
  selector: 'app-spa-ag-toggle',
  templateUrl: './ag-toggle.component.html',
  styleUrls: ['./ag-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgToggleComponent {
  field: AgToggleConfig;
  checkedLabel: string;
  unCheckedLabel: string;
  @Output() valueChange = new EventEmitter();
  @Input('toggleInputs')
  set toggleData(value: AgToggleConfig) {
    this.field = value;
  }
 
  toggleChange(arg) {
    this.valueChange.emit(arg);
  }

}



