import { Injectable } from '@angular/core';
import { API } from 'src/app/common/Models/datautilitydetails-model';
import { DataMigrationServiceApiRoutes } from '../common-route';
import { DataMigrationCommunication } from '../communication/services/data-migration-communication-service';




@Injectable({
    providedIn: 'root'
})
export class DataUtilityDataService {

    constructor(private dataMigrationCommonGateway: DataMigrationCommunication) {
    }

    public getAllModules(): Promise<string[]> {
        let result = this.dataMigrationCommonGateway.getPromise<string[]>(
            { route: DataMigrationServiceApiRoutes.getAllDataUtilityModules }, false);
        return result;
    }
    public getImportModules(): Promise<string[]> {
        let result = this.dataMigrationCommonGateway.getPromise<string[]>(
            { route: DataMigrationServiceApiRoutes.getImportDataUtilitytModules }, false);
        return result;
    }

    public getAllTables(serviceName: string): Promise<string[]> {
        let result = this.dataMigrationCommonGateway.getPromise<string[]>(
            { route: DataMigrationServiceApiRoutes.getAllDataUtilityTables, uriParams: { serviceName: serviceName } }, true);
        return result;
    }

    public exportExcel(screenNames: string[], serviceName : string,isCreate: boolean): Promise<API.DataUtilityDetails[]> { 
        let result = this.dataMigrationCommonGateway.putPromise<API.DataUtilityDetails[]>(
            { route: DataMigrationServiceApiRoutes.exportExcel, body: screenNames , uriParams: {serviceName : serviceName,isCreate: isCreate} });   
        return result;
    }

    public downloadExcel(dataUtilityId: number): Promise<any> {
        let result = this.dataMigrationCommonGateway.putDownloadPromise(
            { route: DataMigrationServiceApiRoutes.downloadExcel, uriParams: { dataUtilityId: dataUtilityId } });
        return result;
    }
    public getAllDataUtilityExportDetails(serviceName: string): Promise<API.DataUtilityDetails[]> {
        let result = this.dataMigrationCommonGateway.getPromise<API.DataUtilityDetails[]>(
            { route: DataMigrationServiceApiRoutes.getAllExportDataUtilityDetails, uriParams: { serviceName: serviceName } }, true);
        return result;
    }
    public getAllDataUtilityImportDetails(serviceName: string): Promise<API.DataUtilityDetails[]> {
        let result = this.dataMigrationCommonGateway.getPromise<API.DataUtilityDetails[]>(
            { route: DataMigrationServiceApiRoutes.getAllImportDataUtilityDetails, uriParams: { serviceName: serviceName } }, true);
        return result;
    }
    public getDataUtilityDetailsByScreen(serviceName: string, screenName: string, fromScreen: boolean): Promise<API.DataImportDetails[]> {
        let result = this.dataMigrationCommonGateway.getPromise<API.DataImportDetails[]>(
            { route: DataMigrationServiceApiRoutes.getDataUtilityDetailsByScreen, uriParams: { serviceName, screenName, fromScreen } });
        return result;
    }
    public postDataUtilityDetails(datautilitydetails: API.DataUtilityDetails): Promise<API.DataUtilityDetails[]> {
        let result = this.dataMigrationCommonGateway.postPromise<API.DataUtilityDetails[]>(
            { route: DataMigrationServiceApiRoutes.postDataUtilityDetails, body: datautilitydetails }, false);
        return result;
    }
    public downloadErrorRowsExcelData(dataUtilityId: number): Promise<any> {
        let result = this.dataMigrationCommonGateway.putDownloadPromise(
            { route: DataMigrationServiceApiRoutes.downloadErrorRowsExcelData, uriParams: { dataUtilityId: dataUtilityId } });
        return result;
    }

    public getBulkImportDataUtilityEntityNames(isFastTrack: boolean): Promise<API.ModuleFile[]> {
        let result = this.dataMigrationCommonGateway.getPromise<API.ModuleFile[]>(
            { route: DataMigrationServiceApiRoutes.getBulkImportDataUtilityEntityNames, uriParams: { isFastTrack: isFastTrack } }, true);
        return result;
    }
    public getAllDataUtilityErrors(dataUtilityId: number): Promise<API.DataUtilityErrors[]> {
        let result = this.dataMigrationCommonGateway.getPromise<API.DataUtilityErrors[]>(
            { route: DataMigrationServiceApiRoutes.getAllDataUtilityErrors, uriParams: { dataUtilityId: dataUtilityId } }, true);
        return result;
    }

    public PostDataFilesForBulkImport(moduleFiles: API.ModuleFile[], isFastTrack: boolean, isOverWrite: boolean = false): Promise<API.BulkImport[]> {
        let result = this.dataMigrationCommonGateway.postPromise<API.BulkImport[]>(
            { route: DataMigrationServiceApiRoutes.queueDataFiles, body: moduleFiles, uriParams: { isFastTrack: isFastTrack, isOverWrite: isOverWrite } }, false);
        return result;
    }

    public GetActiveBulkImportFiles(includeInactive: boolean): Promise<API.BulkImportDetails[]> {
        let result = this.dataMigrationCommonGateway.getPromise<API.BulkImportDetails[]>(
            { route: DataMigrationServiceApiRoutes.getAllBulkImportFiles, uriParams: { includeInactive: includeInactive } }, true);
        return result;
    }

    public reQueueBulkImportDetails(id): Promise<boolean> {
        let result = this.dataMigrationCommonGateway.postPromise<boolean>(
            { route: DataMigrationServiceApiRoutes.reQueueBulkImportDetails, uriParams: { id: id } }, true);
        return result;
    }

    public deleteQueueBulkImportItem(id): Promise<boolean> {
        let result = this.dataMigrationCommonGateway.postPromise<boolean>(
            { route: DataMigrationServiceApiRoutes.deleteQueueBulkImportItem, uriParams: { id: id } }, true);
        return result;
    }

    public getLoggedInPropertyQueueProgressCount(): Promise<API.BulkImportStatusCount> {
        let result = this.dataMigrationCommonGateway.getPromise<API.BulkImportStatusCount>(
            { route: DataMigrationServiceApiRoutes.getLoggedInPropertyQueueProgressCount }, false);
        return result;
    }
    public getAllConfiguration(settingType: number): Promise<API.Setting[]>{
        let result = this.dataMigrationCommonGateway.getPromise<API.Setting[]>(
            { route: DataMigrationServiceApiRoutes.getAllDataMigrationConfiguration, uriParams: {settingType : settingType} }, true);
            return result;        
    }
   
    public createConfiguration(dMDefaults:API.Setting[]): Promise<boolean>{
        let result = this.dataMigrationCommonGateway.postPromise<boolean>(
            { route: DataMigrationServiceApiRoutes.createDataMigrationConfiguration, body:dMDefaults }, true,true);
            return result;        
    }
}

