import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { PrintListDataModel, PrintPopModel, PrintPopUpCallBack } from '../model/pop-up.interface';
// import * as PrintReport from '../../../assets/json/report/report-print.json';
import { ReportPrint } from 'src/app/eatecui/source/reports/interface/reports.interface';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-agilysys-print-popup',
  templateUrl: './aagilysys-print-popup.component.html',
  styleUrls: ['./agilysys-print-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysPrintPopupComponent {
  agilysysCofirmationButton: AgilysysButtonModel[];
  PrintDropDownData: Array<PrintListDataModel> = [];
  printValue = new FormControl(null, [Validators.required]);
  printCountControl = new FormControl(1, Validators.min(1));
  constructor(public dialogRef: MatDialogRef<AgilysysPrintPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PrintPopModel,
    private http: HttpClient) {
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          const printPopUpCallBack = {} as PrintPopUpCallBack;
          printPopUpCallBack.ButtonKey = 'Cancel';
          printPopUpCallBack.SelectedId = this.printValue.value;
          this.dialogRef.close(printPopUpCallBack);
        }
      },
      {
        displayProperity: this.printValue.invalid,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          const printPopUpCallBack = {} as PrintPopUpCallBack;
          printPopUpCallBack.ButtonKey = 'Confirm';
          if (data.EnableCount) {
            printPopUpCallBack.printCount = this.printCountControl.value;
          }
          printPopUpCallBack.SelectedId = this.printValue.value;
          this.dialogRef.close(printPopUpCallBack);
        }
      }
    ];
    if (data?.PrintId) {
      this.printValue.addValidators(Validators.required);
    }
    this.loadPrintData();
    this.printValue.valueChanges.subscribe((x: any) => {
      console.log(x)
    });
  }
  printlistChange($vent: any): void {
    try {
      this.agilysysCofirmationButton[1].displayProperity = this.printValue.invalid;
    } catch (error) {
      console.error(error);
    }
  }
  /**
 * @method loadPrintData
 */
  async loadPrintData() {
    try {
      if (this.data?.PrintList?.length > 0) {
        const getReportListData: Array<PrintListDataModel> = this.data.PrintList.filter(x => x.Id === this.data.PrintId)[0]['Reports'];
        if (getReportListData && getReportListData.length > 0) {
          this.PrintDropDownData = [...getReportListData];
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

}
