import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-spa-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogHeaderComponent {

  @Input() title: string;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  close() {
    this.closeDialog.emit();
  }
}
