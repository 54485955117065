import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons, FieldArray, FormType, RadioOptions } from '../../form-type.entity';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
@Component({
  selector: 'lib-autocomplete-box',
  templateUrl: './autocomplete-box.component.html',
  styleUrls: ['./autocomplete-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteBoxComponent implements OnInit, AfterViewInit {

  @Input() field: FormType;
  @Input() form: FormGroup;
  formBuilderIcons: FormIcons;
  fieldType: string;
  formFieldName: string;
  formFieldlabel: string;
  formArrayName: string;
  formFieldheader: any;
  searchtext: string;
  className: string;
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplete: MatAutocompleteTrigger;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  showRequired: boolean;
  constructor(public AgyDynamicFormService: AgyDynamicFormServices) { }

  fieldArrayCallback(e: FieldArray): void {
    this.field.fieldArray.push(e);
  }
  iconCallback(formArray: FormArray, formObject: FormGroup): void {
    this.AgyDynamicFormService.addnewRow(formArray, formObject);
  }
  get isValid(): any { return this.form.controls[this.field.name].valid; }
  get isDirty(): any { return this.form.controls[this.field.name].dirty; }

  ngOnInit(): void {
    this.fieldType = Object.keys(this.field.fieldType).toString();
    this.showRequired = this.field.fieldType.SingleData?.FieldValidation?.filter(x => x.key === 'required').length > 0;
    const formField = this.AgyDynamicFormService.generateFormFieldName(this.field);
    this.formFieldName = formField.fieldName;
    this.formFieldlabel = formField.fieldLabel;
    this.formArrayName = formField.fieldName;
    this.formFieldheader = formField.fieldHeader;
    if(!this.field.fieldType.SingleData.enableSingleSelect && this.field.fieldType.SingleData.value &&
      this.field.fieldType.SingleData.value.length > 0) {
        this.setValueForAutoComplete(this.field.fieldType.SingleData.value);
    }
    // if (this.field.fieldType.SingleData.options.length <= 4) {
    //   const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
    //   matAutoCompleteBox.style.height = 46 + 
    //     ((this.field.fieldType.SingleData.options.length === 0 ? 1 : this.field.fieldType.SingleData.options.length) * 48) + 'px';
    // }
  }

  selectedOption() {
    this.AgyDynamicFormService.formEventCallback(this.field, 0, this.form);
  }

  searchChange(event): void {
    if (event.length >= 1) {
      const searchResult = this.field.fieldType.SingleData.autocompleteoptions.filter(x => x.label.toLowerCase().includes(event.toLowerCase()));
      this.field.fieldType.SingleData.options = searchResult;
      if (searchResult.length <= 4) {
        const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
        matAutoCompleteBox.style.height = 46 + ((searchResult.length === 0 ? 1 : searchResult.length) * 48) + 'px';
      } else {
        const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
        matAutoCompleteBox.style.height = '256px';
      }
    } else {      
      this.field.fieldType.SingleData.options = this.field.fieldType.SingleData.autocompleteoptions;
      if (this.field.fieldType.SingleData.options.length <= 4) {
        const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
        matAutoCompleteBox.style.height = 46 + ((this.field.fieldType.SingleData.options.length === 0 ? 1 : this.field.fieldType.SingleData.options.length) * 48) + 'px';
      } else {
        const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
        matAutoCompleteBox.style.height = '256px';
      }
    }
  }

  panelClosed():void {
    this.searchtext = null;    
    this.field.fieldType.SingleData.options = this.field.fieldType.SingleData.autocompleteoptions;
  }

  getUniqueId(index : number, currentObj: any) {
    return currentObj.key;
  }

  displayFn(id) {
    if (!id || id.length === 0) return '';
    if (this.field.fieldType.SingleData.enableSingleSelect) {
      let index = this.field.fieldType.SingleData.autocompleteoptions.findIndex(state => state.key === id);
      return this.field.fieldType.SingleData.autocompleteoptions[index].label;
    } else {
      const LabelList: Array<any> = [];
      id.forEach(element => {
        let index = this.field.fieldType.SingleData.autocompleteoptions.findIndex(state => state.key === element);
        LabelList.push(this.field.fieldType.SingleData.autocompleteoptions[index].label);
      });
      return LabelList.join(', ');
    }
  }

  selectionChange(event, option: RadioOptions) {
    const selectedList = this.form.controls[this.formFieldName].value ? this.form.controls[this.formFieldName].value : [];
    if (event.checked && selectedList.findIndex(x => x === option.key) === -1) {
      selectedList.push(option.key);
    } else if (!event.checked && selectedList.findIndex(x => x === option.key) !== -1) {
      const removeIndex = selectedList.findIndex(x => x === option.key);
      selectedList.splice(removeIndex, 1);
      const copyIndex = this.field.fieldType.SingleData.options.findIndex(x => x.key === option.key);
      if (copyIndex !== -1) {
        this.field.fieldType.SingleData.options[copyIndex].ischecked = false;
      }
      const mainIndex = this.field.fieldType.SingleData.autocompleteoptions.findIndex(x => x.key === option.key);
      if (mainIndex !== -1) {
        this.field.fieldType.SingleData.autocompleteoptions[mainIndex].ischecked = false;
      }
    }
    this.form.controls[this.formFieldName].setValue(selectedList);
    this.AgyDynamicFormService.formEventCallback(this.field, 0, this.form);
  }

  ngAfterViewInit() {
    this.form.controls[this.formFieldName].valueChanges.subscribe(res => {
      this.setValueForAutoComplete(res);
    });    
    if (this.field.fieldType.SingleData.options.length <= 4) {
      const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
      if (matAutoCompleteBox) {
        matAutoCompleteBox.style.height = 46 + 
        ((this.field.fieldType.SingleData.options.length === 0 ? 1 : this.field.fieldType.SingleData.options.length) * 48) + 'px';
      }
    }
  }

  setValueForAutoComplete(res: Array<any>) {    
    if (!this.field.fieldType.SingleData.enableSingleSelect && res) {
      res.forEach(element => {
          const copyIndex = this.field.fieldType.SingleData.options.findIndex(x => x.key === element);
          if (copyIndex !== -1) {
            this.field.fieldType.SingleData.options[copyIndex].ischecked = true;
          }
          const mainIndex = this.field.fieldType.SingleData.autocompleteoptions.findIndex(x => x.key === element);
          if (mainIndex !== -1) {
            this.field.fieldType.SingleData.autocompleteoptions[mainIndex].ischecked = true;
          }
      });
    } else if (!this.field.fieldType.SingleData.enableSingleSelect && !res) {
      this.field.fieldType.SingleData.options.forEach(optionss => {
        optionss.ischecked = false;
      });
      this.field.fieldType.SingleData.autocompleteoptions.forEach(optionss => {
        optionss.ischecked = false;
      });
    }
  }

  openPanel() {
    this.inputAutoComplete.openPanel();
    setTimeout((x) => {
      this.viewPort?.checkViewportSize();
      if (this.field.fieldType.SingleData.options.length <= 4) {
        const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
        if (matAutoCompleteBox) {
          matAutoCompleteBox.style.height = 46 + 
          ((this.field.fieldType.SingleData.options.length === 0 ? 1 : this.field.fieldType.SingleData.options.length) * 48) + 'px';
        }
      } else {
        const matAutoCompleteBox = document.getElementsByClassName('auto-complete-box')[0] as HTMLElement;
        if (matAutoCompleteBox) {
          matAutoCompleteBox.style.height = '256px';
        }
      }
    }, 10);
  }
}
