<div [formGroup]="form" class="golffile" [ngClass]="field.disbaledproperity ? 'disable-file' : ''">
    <div *ngIf="files.length === 0" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (OnFileDropped)="uploadFile($event)">
      <!-- <span class="d-block text-center display-label">Display Picture</span> -->
      <span class="d-block text-center"><span class="upload-text">UPLOAD FILE</span><span> or drop file here</span></span>
      <span class="d-block text-center accept-text">[Only png, jpg, jpeg and bmp formats allowed.Maximum upload per file size is 2 MB]</span>
      <span class="d-block text-center accept-text">[Only mp4 formats allowed.Maximum upload per file size is 20 MB]</span>
      <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple="multiple" accept=".png, .jpg, .jpeg, .bmp, .mp4">
    </div>
    <div *ngIf="files.length > 0" class="image-container" appDragDrop (OnFileDropped)="uploadFile($event)">
        <div class="image-content">
           <img class="image-file" [src]="files[0].Picture | basefourimage:files[0].Picture">
        </div>
        <div class="image-count" (click)="viewMoreImage(files)">
           <label>{{files.length}}</label>
        </div>
        <!-- <div class="add-image" (click)="fileInput.click()">
          <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple="multiple" accept="image/*">
           Add Picture
        </div> -->
        <div class="add-image" (click)="viewMoreImage(files)">
          <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple="multiple" accept=".png, .jpg, .jpeg, .bmp, .mp4">
           Add Picture
        </div>
    </div>
    <!-- <div>
      <div class="files-list" *ngFor="let file of files;let i=index">
        <p>{{file}}</p>
        <button class="delete-file" (click)="deleteAttachment(i)">
          <img src="assets/images/Recycle_Bin_Full.png">
        </button>
      </div>
    </div> -->
  </div>
