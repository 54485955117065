import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { VendorPunchOutConfig, TransactionConfiguration, Butchery } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import moment from 'moment';
import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const LoadVendorPunchOutConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, Value?: any): any => {
    try {   
        const ScrollItemCount = sessionStorage.getItem('appsetting') ?
          JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid : constants.InfiniteScrollItemCount;
        const orderByColumn = transactionService.
          setSortColumn(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortDirection = transactionService.
          setSortDirection(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortingData = new RequestPagination(orderByColumn, sortDirection, requestEngine.FetchAll,
                requestEngine.LocationIds);
        const requestPayload = sortingData.getPaginationPayload(0, ScrollItemCount);
        const vendorPunchOutGet = {} as VendorPunchOutConfig;
        vendorPunchOutGet.SupplierIds = [];
        vendorPunchOutGet.IsActiveOnly = true;
        vendorPunchOutGet.FromDate = null;
        vendorPunchOutGet.ToDate = null;
        vendorPunchOutGet.isPurchaseOrder = JsonData.PostEntityName === 'PurchaseReqHead' ? false : true;
        const PostData = Object.assign(requestPayload, vendorPunchOutGet);
        return PostData;        
    } catch (error) {
        console.error('Error occurred in Load Sales History Data Construction', error);
    }
};

function formatDateFilter(DateValue, DateType: string) {
    try {
      let currentDate;
      if (DateType === 'ToDate') {
        currentDate = moment(DateValue).endOf('day').format(constants.PostDateTimeFormat);
      } else {
        currentDate =  moment(DateValue).startOf('day').format(constants.PostDateTimeFormat);
      }
      return currentDate;
    } catch (error) {
      console.error('Error occurred in formatDateFilter', error);

    }
  }