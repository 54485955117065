import { ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';
import { FormaterConfig } from './formater-config';

export const ConversionFormater: FormaterConfig = ( FieldKey: string, FieldValue: string, HeaderEntity: any, DetailEntity: any,
    Editable?: boolean): string => {
    try {
        const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
        let decimalValue = INIDecimalValue ? parseInt(INIDecimalValue, 10) : sessionStorage.getItem('appsetting') ? 
        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength : ConversionValue.ConversionTwo;
        if (Editable) {
            decimalValue = ConversionValue.ConversionSix;
        }
        if (FieldValue !== null && FieldValue !== undefined && FieldValue !== '' 
        && !isNaN(parseFloat(FieldValue)) && isFinite(parseFloat(FieldValue))) {
            return parseFloat(FieldValue).toFixed(decimalValue);
        } else {
            return parseFloat('0').toFixed(decimalValue);
        }
    } catch (error) {
        console.error(error);
    }
};