import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import * as moment from "moment";

import { SpaLocalization } from '../../../core/localization/spa-localization';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { ServiceYieldingService } from '../service-yielding-service';
import { BaseResponse, MoreFilterOptions } from '../../../shared/business/shared.modals';
import { SettingsService } from '../../settings.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';

@Component({
  selector: 'app-service-yielding-grid',
  templateUrl: './service-yielding-grid.component.html',
  styleUrls: ['./service-yielding-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceYieldingGridComponent implements OnInit {
  serviceArr: any = [];
  aWeekArr: any[];
  selectedserviceArr: any = [];
  weekDays: any = [];
  selectedMonth: string;
  selectedYear: string;
  YielldingData: any[] = [];
  serviceArray = false;
  CurrentData: any;
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  Yield1Desc: any = this.captions.YieldLevel1;
  Yield2Desc: any = this.captions.YieldLevel2;
  Yield3Desc: any = this.captions.YieldLevel3;
  isUserAuthorized = true;
  isViewOnly = true;
  filterOptions: MoreFilterOptions;
  fullMonth: string;
  initialLoads = true;
  callCounter = 0;
  currentDate: Date = this.propertyInfo.CurrentDate;

  constructor(public _utilities: SpaUtilities,
    private _yieldService: ServiceYieldingService,
    public localization: SpaLocalization,
    private BP: BreakPointAccess, private ss: SettingsService, private propertyInfo: SpaPropertyInformation) {
    this.ss.tabLoaderEnable.next(true);
  }

  toggleButtonClick = ($event, frm, Driven: any) => {
    this.selectedserviceArr = [];
    this.selectedserviceArr.push(Driven);
    this._yieldService.BindGridData(this.selectedMonth, this.selectedYear, this.selectedserviceArr, this.successCallback.bind(this), this.errorCallback);
  }

  ngOnInit() {
    this.ValidateBreakPoint();
    this.weekDays = this.localization.getLongDaysOfWeek();
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this._yieldService.GetAllServices(this.successCallback.bind(this), this.errorCallback);
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingServiceYielding]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingServiceYielding);
  }

  openDialog(event): void {
    const windowHeight: number = window.innerHeight;
    const scrollposition = 0;
    const d = event.srcElement;
    const idPosition: number = (d.offsetTop + 324) - scrollposition;
    const approxPopWidow = 300;
    let topPos: number;
    let leftPos: number;
    if ((windowHeight - idPosition) > approxPopWidow) {
      topPos = idPosition - 101;
      leftPos = d.offsetLeft;
    } else {
      topPos = idPosition - 101 - (windowHeight - idPosition);
      leftPos = d.offsetLeft;
    }
    let selecedArr: any;
    const DataArray = this.serviceArr;
    selecedArr = this.selectedserviceArr.map(x => x.id);
    this.filterOptions = this._utilities.getFilterPopUpOption(true, DataArray, selecedArr, topPos, leftPos, '', 'SY');
  }

  filteredDataResponse(event) {
    let result = event[0];
    if (!result) { return; }
    result = result ? result : [];

    this.selectedserviceArr = this.serviceArr.filter((element) => result.includes(element.id));
    this.serviceArr = _.union(this.selectedserviceArr, this.serviceArr);
    this._yieldService.BindGridData(this.selectedMonth, this.selectedYear, this.selectedserviceArr, this.successCallback.bind(this), this.errorCallback);
  }

  getPrevMonth(mnth, yr) {
    const givenDate = yr + mnth + '01';
    const previousMonth = moment(givenDate, "YYYYMMDD").subtract(1, 'M');
    this.selectedMonth = previousMonth.format('MM');
    this.selectedYear = previousMonth.format('YYYY');
    this.fullMonth = previousMonth.format('MMMM');
    this.aWeekArr = this._yieldService.formCalendarGrid(previousMonth, this.selectedMonth,givenDate,'subtract');
    this._yieldService.BindGridData(this.selectedMonth, this.selectedYear, this.selectedserviceArr, this.successCallback.bind(this), this.errorCallback);
  }
  getNxtvMonth(mnth, yr) {
    const givenDate = yr + mnth + '01';
    const nextMonth = moment(givenDate, "YYYYMMDD").add(1, 'M');
    this.selectedMonth = nextMonth.format('MM');
    this.selectedYear = nextMonth.format('YYYY');
    this.fullMonth = nextMonth.format('MMMM');
    this.aWeekArr = this._yieldService.formCalendarGrid(nextMonth, this.selectedMonth,givenDate,'add');
    this._yieldService.BindGridData(this.selectedMonth, this.selectedYear, this.selectedserviceArr, this.successCallback.bind(this), this.errorCallback);
  }
  getCurrMonth($evt, bindgrid: any = true) {
    const currentMonth = moment(this.currentDate, "YYYYMMDD");
    this.selectedMonth = currentMonth.format('MM');
    this.selectedYear = currentMonth.format('YYYY');
    this.fullMonth = currentMonth.format('MMMM');
    this.aWeekArr = this._yieldService.formCalendarGrid(currentMonth, this.selectedMonth,this.currentDate,'');
    if (bindgrid) {
      this._yieldService.BindGridData(this.selectedMonth, this.selectedYear, this.selectedserviceArr, this.successCallback.bind(this), this.errorCallback);
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === "GetMonthlyYield") {
      this.YielldingData = <any>result.result;
      if (this.YielldingData) {
        this.aWeekArr.forEach((week) => {
          week.map(x => {
            const arr = x.row1.split(' ');
            const thisdate = arr[0];
            const thisMonth = arr[1];
            const resultdata = this.YielldingData.filter(k => {
              const yieldDate = this._utilities.getDate(k.yieldDate);
              return yieldDate.getDate() === Number(thisdate) && yieldDate.getMonth() + 1 == Number(thisMonth);
            });
            if (resultdata.length > 0) {
              x.filteredYieldingData = resultdata;
            } else {
              x.filteredYieldingData = null;
            }
            return x;
          });
        });
      }
    }
    if (callDesc === "GetAllSpaService") {
      [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
      const allServices = <any>result.result;
      if (allServices) {
        this.serviceArr = allServices.filter(x => x.isActive);
        if (this._yieldService.selectedServiceId && this._yieldService.selectedServiceId > 0) {
          const selectedService = this.serviceArr.find(x => x.id === this._yieldService.selectedServiceId);
          if (selectedService) {
            this.selectedserviceArr.push(selectedService);
          }

        } else if (this._yieldService.FirstServiceId && this._yieldService.FirstServiceId > 0) {
          const selectedService = this.serviceArr.find(x => x.id === this._yieldService.FirstServiceId);
          if (selectedService) {
            this.selectedserviceArr.push(selectedService);
          }
        }
        this.getCurrMonth(event);
      } else {
        this.getCurrMonth(event, false);
      }
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetAllSpaService" && !this.initialLoads) {
      [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
    }
  }

  openYield(operation: any) {
    this._yieldService.ChangeNewYield(true);
    this._yieldService.isTabsViewable = false;
    this._yieldService.currentYield = this.CurrentData;

    document.getElementsByClassName('menuSecondary-container')[0].classList.add("d-none");
    if (operation === "EDIT") {
      this._yieldService.editYield = true;
      this._yieldService.copyYield = false;
      this._yieldService.CurrentData = _.cloneDeep(this.CurrentData);
    }else if (operation === "COPY") {
      this._yieldService.copyYield = true;
      this._yieldService.editYield = false;
      this._yieldService.CurrentData = _.cloneDeep(this.CurrentData);
    } else {
      this._yieldService.editYield = false;
      this._yieldService.CurrentData = null;
    }
    this._yieldService.selectedServiceId = this.selectedserviceArr && this.selectedserviceArr.length > 0 ? this.selectedserviceArr[0].id : 0;
    this._yieldService.FirstServiceId = 0;
  }

  popOverContentBind(i, j, data) {
    this.CurrentData = data.filteredYieldingData ? data.filteredYieldingData[0] : null;
  }

  ResetSelections() {
    this.selectedserviceArr = [];
    this._yieldService.BindGridData(this.selectedMonth, this.selectedYear, this.selectedserviceArr, this.successCallback.bind(this), this.errorCallback);
  }
}



