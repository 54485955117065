import * as _ from 'lodash';
import { PrinterConfig, PrinterInterface } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { AgilysysPrinterPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-printer-popup/agilysys-printer-popup.component';

export const PrinterAlgorithm: PrinterConfig = ( PrinterConfig: PrinterInterface ): void => {
  try {
      try {
          PrinterConfig.DialogConfig.open(AgilysysPrinterPopupComponent, {
            width: '700px',
            height: '525px',
            data: PrinterConfig,
            disableClose: true
          }); 
                
            
        } catch (Error) {
        console.error('Error occurred in Miscellaneous Charges Calculation ', Error);
      }
  } catch (error) {
    console.log('Error occured Conversion Algorithm ', error);
  }
};