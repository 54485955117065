import { Component,  Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AgFieldConfig } from '../../models/ag-models';

@Component({
  selector: 'app-spa-ag-currency',
  templateUrl: './ag-currency.component.html',
  styleUrls: ['./ag-currency.component.scss']
})
export class AgCurrencyComponent {
  customClass: string;
  errorMessage: string;
  groupName: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  maxDecimalLength: string | number;
  maxValue: number | string;
  showRequired: boolean;
  maxValueErrorMessage: string;
  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.groupName = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.maxDecimalLength = value.maxlength;
    this.showRequired = value.isRequired ? value.isRequired : false;
    this.maxValue = value.maxValue ? value.maxValue: '';
    this.maxValueErrorMessage=value.maximumValueErrorMsg?value.maximumValueErrorMsg:''
  }

}
