import { Intergration } from './integration';
import { SculptureValidationModel } from './integration-interface';

export class SculptureIntegeration extends Intergration {
    /**     * @method SculptureValidation()
     * @Input responseData     
     * @output permission resource     
     * @description check scupture validation based on data     
    */
   SculptureValidation(responseData: Array<any>, from: string = null): SculptureValidationModel { 
    try {
        const validationModel = {} as SculptureValidationModel;
        validationModel.EnableValidateButton = false;
        validationModel.EnablePostButton = false;
        validationModel.ValidationMessage = 'Validate your data';
        validationModel.SculptureData = [];
        validationModel.isValidate = null;
        if ( responseData && responseData.length === 0 ) {
            validationModel.ValidationMessage = 'Data not found';
        } else {
            const emptyValidateMessage = responseData.every((data: any) => data.ValidationMessage === '');
            const isValidateData = responseData.some((data: any) => data.StatusId === 1 );
            if ( emptyValidateMessage || from === 'Validate') {
                validationModel.EnableValidateButton = true;
                validationModel.EnablePostButton = false;
                validationModel.ValidationMessage = null;
                validationModel.isValidate = true;
                validationModel.SculptureData = this.selectedResponseData(responseData, from);
            } else if ( !emptyValidateMessage && !isValidateData ) {
                validationModel.EnableValidateButton = false;
                validationModel.EnablePostButton = true;
                validationModel.ValidationMessage = 'one or more data will must true';
                validationModel.SculptureData = responseData;
                validationModel.isValidate = false;
            } else if ( !emptyValidateMessage && isValidateData ) {
                validationModel.EnableValidateButton = false;
                validationModel.EnablePostButton = true;
                validationModel.ValidationMessage = null;
                validationModel.isValidate = false;
                validationModel.SculptureData = this.selectedResponseData(responseData);
            }
        }
        return validationModel;
    } catch (error) {
        console.error(error);
    }
    }
    /**     
     * @method selectedResponseData     
     * @Input responseData     
     * @output checked Data     
     * @descrption check     
     */
    selectedResponseData(responseData: Array<any>, from: string = null): Array<any> {
        try {
            let returnResponseData = responseData;
            if (from === 'Validate') {
                responseData.forEach(y => {
                    y.StatusId = 0;
                    y.ValidationMessage = '';
                });
            } else {
                const isCheckedData = responseData.some(x => x.Checked);
                if ( isCheckedData ) {
                    returnResponseData = responseData.filter(x => x.Checked);
                } else {
                    returnResponseData = responseData.filter(data => data.StatusId === 1);
                    returnResponseData.forEach(d => {
                      delete d.Checked;
                    //   d.ValidationMessage = '';
                    });
                }
            }
            return returnResponseData;
        } catch ( error ) {
            console.error(error);
        }
    }}