import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare let $: any;
@Injectable({
    providedIn: 'root'
})
export class JSONReaderService {
    private readonly _getContent = new Map<string, any>();
    constructor(private http: HttpClient) { }
    public getJSON(filePath): any {
        const cacheKey = filePath;
        if (!this._getContent.get(cacheKey)) {
            let jsonResult = [];
            $.ajax({
                url: filePath,
                async: false,
                success(result) {
                    jsonResult = result;
                },
                error(result) {
                    jsonResult = [];
                }
            });
            this._getContent.set(cacheKey, jsonResult);           
        }
        return this._getContent.get(cacheKey);

    }
}
