import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RGuestPayRequestBuildParams, RGuestPayRequestDetails, GetDeviceResponse, RGuestSaleResponse, TokenResponse, TransactionType, rGuestPayDevice, DeviceStatus } from "src/app/common/shared/shared/business/rGuestPayRequestModel";
import { HttpResponseStatus, SaleResponse, HandleRequest, HandleResponse, TokentransactionInfo, StoreTokenRequest, PaymentBaseResponse, CardInfo, PaymentMethods } from "src/app/common/shared/shared/business/shared.modals";
import { SaleRequest, ValidateAuthRequest } from "../../../Models/payment.model";
import { PMAgentToRGuestPayRequestAdapter } from "./PMAgentToRGuestPayRequestAdapter";
import { RetailPaymentCommunication } from "src/app/common/communication/services/retail-payment-communication.service";
import { CommonUtilities } from '../../../shared/shared/utilities/common-utilities';
import { take, timeout } from "rxjs/operators";
import { PayAgentService } from "src/app/common/services/payagent.service";
import { RetailConstants } from 'src/app/common/constants';
import { CommonApiRoutes} from 'src/app/common/common-route';

@Injectable({ providedIn: 'root' })
export class NonPMAgentRequestHandler {

    private rGuestRequestCache_Store = new Map<string, string>();

    constructor(
        private http: HttpClient
        , private _retailComm: RetailPaymentCommunication
        , private _reqAdapter: PMAgentToRGuestPayRequestAdapter
        , private _utils: CommonUtilities
        , private _payAgent: PayAgentService
    ) {
    }

    private async GetOutletId(outletId: number) {
        if (outletId > 0) return outletId;
        else {
            //TO DO 
            //const ceds = await this._payAgent.TryGetCEDSValueAsync(outletId)
            //return ceds ? Number(ceds.split("_")[3]) : outletId;
        }
    }


    async GetHandles(handleRequestBody: HandleRequest, terminalId: string | number = 0, outletId: string | number = 0, handleErr: boolean = false): Promise<HandleResponse> {
        const handleResponse: HandleResponse = {
            errorMessage: "",
            status: HttpResponseStatus.Failure,
            paymentHandle: []
        }
        try {
            if (handleRequestBody.tenderId == PaymentMethods.IDTECH.toString()) {
                handleResponse.status = HttpResponseStatus.Success;
                const idTechHandleWithData = {
                    inquiryInfo: handleRequestBody.inquiryInfo
                }
                handleResponse.paymentHandle.push({ handle: JSON.stringify(idTechHandleWithData) } as any);
            } else {
                outletId = await this.GetOutletId(+outletId);
                let deviceList = await this.GetDevices(this._reqAdapter.MapHandleRequest(handleRequestBody, outletId));
                if (deviceList.length > 0) {
                    deviceList.map(d => {
                        const handle: any = {
                            handle: d.deviceGuid,
                            name: d.description,
                            deviceGuid: d.deviceGuid,
                            deviceId: d.deviceGuid,
                        }
                        handleResponse.paymentHandle.push(handle);
                    });
                    handleResponse.status = HttpResponseStatus.Success;
                } else {
                    handleResponse.errorMessage = "No devices/handles found";
                    handleResponse.status = HttpResponseStatus.Failure;
                }
            }
        } catch (error) {
            let errorMessage = error?.error?.result?.errorMessage;
            handleResponse.errorMessage = (!errorMessage || errorMessage.length === 0)? error.message : errorMessage;
            handleResponse.status = HttpResponseStatus.Failure;
        }
        return handleResponse;
    }

    async CreateToken(handle: string, tenderID: number, terminalId = '0', isPartialTenderAllowed = false, ismanualCardEntry= false,outletId = '0'): Promise<TokentransactionInfo> {
        let tokenResponse: TokentransactionInfo;
        let rGPResponse: any;
        let _outletId = 0;
        try {
            _outletId = await this.GetOutletId(+outletId);
            const response = await this._retailComm.postPromise<RGuestPayRequestDetails>({
                route: CommonApiRoutes.BuildRGuestPayRequest,
                body: this._reqAdapter.MapTokenRequest(handle, tenderID, _outletId, ismanualCardEntry)
            }, false);
            if (response) {
                let requestPayload = JSON.parse(response.requestPayload);
                const isProxyRequired = response?.headers?.[RetailConstants.InvokeAgilysysPayViaWebProxy]?.toLowerCase() === 'true' ? true : false;
                if (isProxyRequired) {
                    rGPResponse = await this._retailComm.postPromiseDirect<TokenResponse>({
                        route: CommonApiRoutes.ProxyCallToRGuestPay,
                        body: response,
                        uriParams: { proxyPaymentMethod: tenderID }
                    }, false)
                }
                else {
                    rGPResponse = await this.http.post<TokenResponse>(response.url, requestPayload).pipe(timeout(this.getCancellationTimeout(response))).toPromise();
                }

                tokenResponse = this._reqAdapter.MapTokenResponse(rGPResponse);
                tokenResponse.requestId = requestPayload.RequestId;
                tokenResponse.gatewayResponse = rGPResponse;
            }
        } catch (error) {
            console.error("Error while Create Token, err:" + error.message);
            const errorRes: any = {
                status: HttpResponseStatus.Failure,
                errorMessage: error?.error?.reason
            }
            tokenResponse = errorRes;
            throw error;
        }
        return tokenResponse;
    }

    private getCancellationTimeout(response: RGuestPayRequestDetails): number {
        return response?.headers?.[RetailConstants.CancellationTimeInSeconds] ? Number(response?.headers?.[RetailConstants.CancellationTimeInSeconds]) * 1000 : RetailConstants.RGUEST_DEVICE_SWIPE_TIMEOUT;
    }

    async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
        storeTokenRequest.outletId = await this.GetOutletId(storeTokenRequest.outletId);
        const response: any = await this._retailComm.postPromise<PaymentBaseResponse>({
            route: CommonApiRoutes.RetailStoreTokenV2,
            body: storeTokenRequest
        }, false);
        return response ?? null;
    }

    async FolioStoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
        storeTokenRequest.outletId = await this.GetOutletId(storeTokenRequest.outletId);
        const response: any = await this._retailComm.postPromise<PaymentBaseResponse>({
            route: CommonApiRoutes.FolioStoreTokenV2,
            body: storeTokenRequest
        }, false);
        return response ?? null;
    }

    async GetDevices(request: RGuestPayRequestBuildParams) {
        let deviceList: rGuestPayDevice[] = [];
        let GetDeviceResponse: GetDeviceResponse
        try {
            let rGuestReq: RGuestPayRequestDetails;
            const outletKey = `${request.outletId}.${request.tenderId}`;
            if (this.rGuestRequestCache_Store.has(outletKey)) {
                rGuestReq = JSON.parse(this.rGuestRequestCache_Store.get(outletKey))
            } else {
                rGuestReq = await this._retailComm.postPromise<RGuestPayRequestDetails>({
                    route: CommonApiRoutes.BuildRGuestPayRequest,
                    body: request
                }, false);
                this.rGuestRequestCache_Store.set(outletKey, JSON.stringify(rGuestReq));
            }
            if (rGuestReq) {
                const isProxyRequired = rGuestReq?.headers?.[RetailConstants.InvokeAgilysysPayViaWebProxy]?.toLowerCase() === 'true' ? true : false;
                if (isProxyRequired) {
                    GetDeviceResponse = await this._retailComm.postPromiseDirect<GetDeviceResponse>({
                        route: CommonApiRoutes.ProxyCallToRGuestPay,
                        body: rGuestReq,
                        uriParams: { proxyPaymentMethod: request.tenderId }
                    }, false)

                }
                else {
                    GetDeviceResponse = await this.http.get<GetDeviceResponse>(rGuestReq.url + "?skipLog=true").toPromise();
                }
                console.log(GetDeviceResponse);
                if (GetDeviceResponse) {
                    deviceList = GetDeviceResponse.devices?.filter(x => x.deviceStatus == DeviceStatus.Available);
                    console.log(deviceList);
                }
            }
        }
        catch (error) {
            console.error("Error while fetching device list, err:" + error);
            // this._utils.showError(error?.error?.errorDescription);
            throw error;
        }
        return deviceList;
    }
}