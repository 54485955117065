import { FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { OrderSummary, PostPOSupplierConfig, SalesOthersSummary, TransactionConfiguration } from '../transaction.interface';
import { PostPo, SalesMediaAssMent } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopModel, SuggestQuantityPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { FormType, SingleField } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';
import { ConversionAlgorithm } from 'src/app/eatecui/source/shared/algorithms';
import { FormFieldType } from 'src/app/eatecui/source/setup-master/shared/interface/form-interface/fieldType.enum';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const SalesMediaAssesMent: SalesMediaAssMent = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    homeGroup: FormGroup,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    salesMediaDetails: Array<SalesOthersSummary | any>,
    salesService?: any,
    commonService?: any
): TransactionConfiguration => {
    try {
        const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
        const FocusDigit = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
        JSON.parse(sessionStorage.getItem('appsetting')).FocusNumberCurrencyLength;
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ItemMediaAssesment')) {
            const EntityName = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment.EntityName;
            const Url = `/inventory/api/${EntityName}?$filter=IsActive eq true`;
            httpService.GethttpMethod(Url).subscribe(res => {
                const Response = res['value'];
                const FormFields = [];
                let FormSingleFields = ''; 
                
                Response.forEach((element: any) => {
                    const tranasctionName = TransactionCollection.Name;
                    const elementId = element.Id;
                    const salesOtherSummary = homeGroup.value['SalesOtherSummary'];
                    const QuickOrderSummery: Array<any> = homeGroup.value['QuickOrderSummary'];
                    let AssignedValue = null;
                     if( tranasctionName === 'sales'){
                          if ( salesOtherSummary ) {
                            AssignedValue = salesOtherSummary.filter(x => x.ItemId === element.Id)[0];
                          } else if( salesMediaDetails ) {
                            AssignedValue = salesMediaDetails.filter(media => media.ItemId === element.Id)[0]
                          }
                     } 
                     else if( tranasctionName === 'quickorder' && QuickOrderSummery && QuickOrderSummery.length > 0 ){
                        AssignedValue = QuickOrderSummery.filter(media => media.QOSummaryLinkTypeId === 5 && media.LinkItemId === element.Id)[0];
                     } else if( tranasctionName === 'quickorder' && salesMediaDetails && salesMediaDetails.length > 0){
                        AssignedValue = salesMediaDetails.filter(media => media.QOSummaryLinkTypeId === 5 && media.LinkItemId === element.Id)[0];
                     } 
                  
                    const Value = AssignedValue ? AssignedValue.Valued && AssignedValue.TypeId === 1
                     ? AssignedValue.Valued :
                     (AssignedValue.Valued && AssignedValue.QOSummaryLinkTypeId === 5) ?
                       AssignedValue.Valued : (AssignedValue.valued ? AssignedValue.valued :'') : '';
                    const MediaFormType = {} as FormType;
                    MediaFormType.type = FormFieldType.TEXT;
                    MediaFormType.class = '';
                    MediaFormType.fieldFormaters = {
                        EnableDecimalFormatter: true,
                        WholeNumberLength: commonService.GetWholeNumberLength(true),
                        VisibleDecimal: CommonService.GetCurrencyVisible(),
                        FocusDecimal: commonService.GetFocusNumberLength(true)
                    };
                    const FormSingleField = {} as SingleField;
                    FormSingleField.name = element.Id;
                    FormSingleField.label = element.Name;
                    FormSingleField.value = Value !== '' ? Value : null;
                    if (transactionService.FlowMode === 'Copy') {
                        FormSingleField.disbaledProperty = true;
                    }
                    // || transactionService.FlowMode === 'CopyAsNew'
                    if (transactionService.FlowMode === 'Create' || transactionService.FlowMode === 'CopyAsNew') {
                        let MediaTotal = 0;
                        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment.hasOwnProperty('PostData') && 
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment.PostData.length > 0) {
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment.PostData.forEach((x: any) => {
                                if (x.QOSummaryLinkTypeId && x.LinkItemId !== 10 && x.QOSummaryLinkTypeId === 5) {
                                    MediaTotal += parseFloat(x.Valued?.toString() || 0);
                                } else if  (x.ItemId !== 10 && !x.QOSummaryLinkTypeId && x.LinkItemId !== 10) {
                                    MediaTotal += parseFloat(x.Valued?.toString() || 0);
                                }
                            });
                        } else if (QuickOrderSummery && QuickOrderSummery.length) {
                            QuickOrderSummery.forEach((x: any) => {
                                if (x.QOSummaryLinkTypeId && x.LinkItemId !== 10 && x.QOSummaryLinkTypeId === 5) {
                                    MediaTotal += parseFloat(x.Valued?.toString() || 0);
                                } else if  (x.ItemId !== 10 && !x.QOSummaryLinkTypeId && x.LinkItemId !== 10) {
                                    MediaTotal += parseFloat(x.Valued?.toString() || 0);
                                }
                            });
                        }
                        if (element.Name === 'Over/Short') {
                            FormSingleField.disbaledProperty = true;
                            const grandTotal =  transactionService.calculateValues([],
                                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData);
                            if (grandTotal === MediaTotal) {
                                FormSingleField.value = parseFloat('0').toFixed(FocusDigit);
                            } else if (MediaTotal > grandTotal) {
                                FormSingleField.value  = (grandTotal - MediaTotal).toFixed(FocusDigit);
                            } else if (MediaTotal < grandTotal) {
                                FormSingleField.value = (grandTotal - MediaTotal).toFixed(FocusDigit);
                            } else {
                                FormSingleField.value = (grandTotal).toFixed(FocusDigit);
                            }
                        }
                    }
                    FormSingleField.onCellClick = ( e: any, field: any, form: any ) => {
                        let count: any;
                        // const MediaTotal = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment.PostData;
                        let fieldName = '';
                        const formValues = form['value'];
                        delete formValues[10]; // ItemId of Over/Short is 10
                        const MediaValues = Object.values(formValues);
                        count = MediaValues.reduce((prev: any, next: any) => {
                            const nextValue = next === null || next === 'null' || next === '' ? 0 : next;
                            const prevValue = prev === null || prev === 'null' || prev === '' ? 0 : prev;
                            return parseFloat(prevValue) + parseFloat(nextValue);
                        }, 0);
                        field.forEach((x: any) => {
                            if (x.fieldType.SingleData.label === 'Over/Short') {
                                fieldName = x.fieldType.SingleData.name;
                            }
                        });
                        const grandTotal = transactionService.calculateValues([],
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData);
                        FormSingleFields = calculateMedia(grandTotal, fieldName, count).toString();  
                        form['controls'][fieldName].setValue(FormSingleFields);
                        CommonService.triggerBlurEvent(fieldName);
                        form.updateValueAndValidity();
                    };
                    if (FormSingleField.label !== 'Over/Short') {       
                        FormSingleField.FieldValidation = [
                            { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'}
                        ];
                    } else {
                        FormSingleField.FieldValidation = [
                            { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'}
                        ];
                    }
                    MediaFormType.fieldType = {
                        SingleData : FormSingleField
                    };
                    FormFields.push(MediaFormType);                    
                });
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment.FormFields = FormFields;
                const DataInfo = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment;
                const SuggestQtyDialog: SuggestQuantityPopUpModel = {
                    PopupHeaderContent: DataInfo.PopupHeaderName,
                    FormFields: DataInfo.FormFields,
                    FormGroupData: DataInfo.FormGroupData,
                    Classname: 'media-assesment',
                    Hide: transactionService.FlowMode === 'Copy' ? true : false
                };
                const dialogRef = dialog.open(AgilysysSuggestqtyPopupComponent, {
                    width: '87%',
                    height: '80vh',
                    data: SuggestQtyDialog,
                    maxWidth: '100vw',
                    panelClass: 'media-assignment-manual-sales',
                    hasBackdrop: true,
                    backdropClass: 'detail-expand-backdrop-class',
                    position: {
                        right: '0'
                    },
                    autoFocus: false
                    // disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result[0] === 'Confirm') {
                        const FormData = result[1] as FormGroup;
                        const summary = TransactionCollection.Name === 'quickorder' ? homeGroup.value.QuickOtherSummary : homeGroup.value.SalesOtherSummary;
                        let ReturnData = aasignPostObject(FormData, 1, summary, TransactionCollection, homeGroup.value['Id']);
                        if (TransactionCollection.Name === 'quickorder') {
                            const FilterArray = homeGroup.controls[DataInfo.Name].value.filter(x => x.QOSummaryLinkTypeId !== 5);
                            ReturnData = ReturnData.concat(FilterArray);
                        }
                        homeGroup.controls[DataInfo.Name].setValue(ReturnData);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMediaAssesment.PostData = ReturnData;
                        receivingEstimationTotal(ReturnData,
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData,
                        transactionService, TransactionCollection, selectedTransaction);
                      }
                });
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred Media Assignment', Error);
    }
};

function receivingEstimationTotal(
_rowData: any, GridRowData,  transactionServices: TransactionService,
transactionConfigurationData: TransactionConfiguration, selectedTransaction) {
    const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
        const FocusDigit = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
        JSON.parse(sessionStorage.getItem('appsetting')).FocusNumberCurrencyLength;
  const total = ConversionAlgorithm.allocateTotal( _rowData, GridRowData, selectedTransaction);
  const grandTotal = transactionServices.calculateValues([],
    transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData);
transactionConfigurationData.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailFormHeader[1].Value =
grandTotal.toFixed(FocusDigit);
}
function aasignPostObject(FormData: FormGroup, selectedId: any, MiscCharges: Array<SalesOthersSummary>, TransactionCollection: TransactionConfiguration, Id?) {
    try {
        const SalesMediaDetails = [];
        for (const key in FormData.value) {
            if (FormData.value.hasOwnProperty(key) && FormData.value[key] !== null &&  FormData.value[key] !== undefined) {
                if (selectedId > 0 && MiscCharges && TransactionCollection.Name !== 'quickorder') {
                    const index = MiscCharges.findIndex(x => x.ItemId.toString() === key.toString());
                    if (index !== -1) {
                        MiscCharges[index].Valued = parseFloat(FormData.value[key]);
                        MiscCharges[index].TypeId = 1;
                        SalesMediaDetails.push(MiscCharges[index]);
                    } else {
                        const MiscCharge = {
                            Id: 0,
                            ItemId: parseInt(key, 10),
                            TypeId: 1,
                            Valued: parseFloat(FormData.value[key] ? FormData.value[key] : '0')
                        };
                        SalesMediaDetails.push(MiscCharge);
                    }
                } else if (selectedId > 0 && MiscCharges && TransactionCollection.Name === 'quickorder') {
                    const obj = {} as OrderSummary
                    obj.Id = 0;
                    obj.QuickOrderHeadId = Id;
                    obj.TrnType = 1;
                    obj.LinkItemId = parseFloat(key);
                    obj.QOSummaryLinkTypeId =  5;
                    obj.AccId = null;
                    obj.Valued = String(parseFloat(FormData.value[key]));
                    SalesMediaDetails.push(obj);
                } else {
                    const MiscCharge = {
                        Id: 0,
                        ItemId: parseInt(key, 10),
                        TypeId: 1,
                        Valued: parseFloat(FormData.value[key] ? FormData.value[key] : '0')
                    };
                    SalesMediaDetails.push(MiscCharge);
                }
            }
        }
        return SalesMediaDetails;
    } catch (error) {
        console.error('Error occurred in aasignPostObject', error);
    }
}
function calculateMedia(GrandTotal: any, FormSingleField: any, MediaTotal: any) {
    const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
        const FocusDigit = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength;
    let value: any;
    if (MediaTotal < GrandTotal) {
        const Value = GrandTotal - MediaTotal;
        value  = Value ? Value : 0;
    } else if (MediaTotal > GrandTotal) {
        value  = GrandTotal - MediaTotal;
    } else if (MediaTotal === GrandTotal) {
        value = 0;
    } else {
        value = GrandTotal;
    }
    return value.toFixed(FocusDigit);
}