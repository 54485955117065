import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "./http-call.service";
import { Host } from "../globalsContant";

@Injectable()
export class ImageProcessorService {
    constructor(private http: HttpServiceCall) { }

     /**
     * @function GetAllImageByIds()
     * @Inputs referenceids, referencetype, successCallback, errorCallback, extraParams
     * @description get all image by Referencetype and ids
     */
    GetAllImagesByIds(referenceids: any, referencetype: string, isthumbnailonly: boolean, successCallback, errorCallback, extraParams, isV2Service = false) {
        if(!referenceids || referenceids.length == 0 ){
            return;
        }
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: isV2Service ? "v2GetAllImagesByReferenceId" : "GetAllImagesByIds",
            uriParams: isV2Service ? { isThumbnailOnly: isthumbnailonly } : { referencetype: referencetype, isthumbnailonly: isthumbnailonly },
            body: referenceids,
            method: HttpMethod.Put,
            showError: false,
            extraParams: extraParams
        });
    }

    GetImagesByGuid(guid: string,successCallback,errorCallback, isthumbnailonly: boolean,extraParams){
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "GetImagesByGuid",
            uriParams: { imageReferenceId:guid  ,isThumbnailOnly: isthumbnailonly },
            method: HttpMethod.Get,
            showError: false,
            extraParams: extraParams
        });
    }


    /**
     * @function GetAllImageByReference()
     * @Inputs referenceids, referencetype, successCallback, errorCallback, extraParams
     * @description get all image by Referencetype and ids
     */
    GetAllImagesByReference(referenceids: any, referencetype: string, isthumbnailonly: boolean, successCallback, errorCallback, extraParams) {
        if(!referenceids || referenceids.length == 0 ){
            return;
        }
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "getAllImagesByReference",
            uriParams: { referencetype: referencetype, referenceids: referenceids, isthumbnailonly: isthumbnailonly },
            method: HttpMethod.Get,
            showError: false,
            extraParams: extraParams
        });
    }



    /**
     * @function GetAllImageByReference()
     * @Inputs referenceids, referencetype, successCallback, errorCallback, extraParams
     * @description get all image by Referencetype and ids
     */
    V2GetAllImagesByReference(referenceids: any, isthumbnailonly: boolean, successCallback, errorCallback, extraParams) {
        if(!referenceids || referenceids.length == 0 ){
            return;
        }
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: "v2GetAllImagesByReferenceId",
            uriParams: { isThumbnailOnly: isthumbnailonly },
            method: HttpMethod.Put,
            body: referenceids,
            showError: false,
            extraParams: extraParams
        });
    }

    /**
     * @function GetImageByReference()
     * @Inputs referenceid, referencetype, successCallback, errorCallback, extraParams
     * @description get image by Referencetype and id
     */
    GetImagesByReference(referenceid: any, referencetype: string, successCallback, errorCallback, extraParams, isV2Service = false) {
        return this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: isV2Service ? "v2GetImagesByReferenceId" : "getImagesByReference",
            uriParams: isV2Service ? {imageReferenceId: referenceid, isThumbnailOnly: false} : { referencetype: referencetype, referenceid: referenceid },
            method: HttpMethod.Get,
            showError: false,
            extraParams: extraParams
        });
    }

    DeleteImageByReference(referenceid: any, referencetype: string, successCallback, errorCallback, extraParams, isV2Service = false) {
        this.http.CallApiWithCallback({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: isV2Service ? "v2DeleteImageByReference" : "deleteImagesByReference",
            uriParams: isV2Service ? { guid: referenceid } : { referencetype: referencetype, referenceid: referenceid },
            method: isV2Service ? HttpMethod.Delete : HttpMethod.Post,
            showError: true,
            extraParams: extraParams
        })
    }

    /**
     * @function updateImage
     * @param imageId
     * @param imageData
     * @param successCallback
     * @param errorCallback
     * @param extraParams
     * @description Saves image of client/therapist
     */
    updateImage(imageId, imageData, successCallback, errorCallback, extraParams, isV2Service = false) {
        this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: isV2Service ? "v2updateImage" : "updateImageById",
            method: HttpMethod.Put,
            uriParams: isV2Service ? {} : { id: imageId },
            body: imageData,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function SaveImage
     * @param imageData
     * @param successCallback
     * @param errorCallback
     * @param extraParams
     * @description Saves image of client/therapist
     */
    saveImage(imageData, successCallback, errorCallback, extraParams, isV2Service = false) {
        this.http.CallApiWithCallback<any>({
            host: Host.image,
            success: successCallback,
            error: errorCallback,
            callDesc: isV2Service ? "v2saveImage" : "saveImage",
            method: HttpMethod.Post,
            body: imageData,
            showError: true,
            extraParams: extraParams
        });
    }

}