import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { CustomFormBuild, FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration, CustomFormValidation } from './custom-form-configuration.interface';

export const cascadingDropdown: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, MasterDataCollection: any,
    httpService: HttpService, FieldGrouping: FormBuilderGroupKey, FormGroupData: FormGroup, FormField: any, CustomData?: string, 
    customBuilderData ?: any
): any => {
    try {
        const RelativeOdatakey = FieldGrouping.CustomFormAction.CascadingProperity.RelativeOdatakey;
        const RelativeOdataUrl = FieldGrouping.CustomFormAction.CascadingProperity.RelativeUrl;
        if ( customBuilderData && customBuilderData.length > 0 ) {
            customBuilderData.forEach((formFields: any) => {
                formFields.FormFields.forEach((formElements: any) => {
                    const fieldName = formElements.fieldType.SingleData.name;
                    if ( fieldName === RelativeOdatakey) { 
                        formElements.fieldType.SingleData.options = [];
                        httpService.GethttpMethod(RelativeOdataUrl).subscribe((response: any) => {
                            const optionListResponse = ( response.hasOwnProperty('value') ) ?  response.value : response;
                            const mappedlist = optionListResponse.map((data: any) => {
                                const checkActiveBool  = ( data.hasOwnProperty('IsActive') ) ? ( data.IsActive ) ? true : false : true;
                                if (checkActiveBool) {
                                    const valueAssign = {} as RadioOptions;
                                    valueAssign.label = data.hasOwnProperty('Name') ? data['Name'] : data['Code'];
                                    valueAssign.key = data['Id'];
                                    formElements.fieldType.SingleData.options.push(valueAssign);                    
                                }
                            });
                        },
                          (responseError) => {
                            console.error('error caught in  component', responseError);
                          });
                    }
                }); 
            });
        }
      return null;
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};