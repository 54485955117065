import { GridFilterAction } from '../master.interface';
import { FieldKeyMapping } from './grid-config.interface';

export const GridFilterLabelMapping: FieldKeyMapping = (GridFilterDataObj: GridFilterAction, ResponseData: any): any => {
    try {
        let formLabel = '';
        if (ResponseData.hasOwnProperty('Name')) {
            formLabel = ResponseData['Name'];
        } else if (ResponseData.hasOwnProperty('Code')) {
            formLabel = ResponseData['Code'];
        } else if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('CustomMapping') &&
        GridFilterDataObj.CustomMapping.CustomLabel) {
            formLabel = ResponseData[ GridFilterDataObj.CustomMapping.CustomLabel];
        }else if (ResponseData.hasOwnProperty('name')){
            formLabel = ResponseData['name'];
        }
         else {
            formLabel = 'No Value Assign';
        }
        return formLabel;
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  };