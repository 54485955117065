import {  Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { EmailTemplatesService } from '../email-templates/email-templates.service';
import { NotificationActions, TemplateData, TemplateDomainData, DefaultTemplateUpdate, TemplateForm, TemplateDetailUI, TemplateDetail } from './sms-templates.model';

@Injectable()
export class SmsTemplatesBusiness  {
  captions: any;
  smsTypes: any = [  ];
  notificationActions: NotificationActions[] =[];
  constructor(private localization: Localization, private _EmailTemplatesService : EmailTemplatesService) {
    this.captions = this.localization.captions.settings.utilities;
  }
 
  getSMSTypes() {
    return [
      {
        id: 1,
        name: this.captions.SMS
      }
    ];
  }

  async getTemplateDetails(topicId: number): Promise<TemplateDetailUI> {
    const templateDetail: TemplateDetail = await this._EmailTemplatesService.GetTemplateDetailsByTopicId(topicId);
    return this.mapToUI(topicId, templateDetail);
  }

  async getActions(type: number): Promise<NotificationActions[]>{
    this.notificationActions = await this._EmailTemplatesService.getActionsByType(type);
    return this.notificationActions;
  }

  async CreateorUpdateTemplate(topicId: number, templateData: TemplateData[]): Promise<boolean> {
    try {
      const templateDomainData: TemplateDomainData = { topicId: topicId, templateData: templateData };
      return await this._EmailTemplatesService.CreateorUpdateTemplate(templateDomainData);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async UpdateDefaultTemplate(topicId: number, templateId: string, templateType: number): Promise<boolean> {
    try {
      const defaultTemplateUpdate: DefaultTemplateUpdate = {
        notificationTopicId: topicId,
        templateId: templateId,
        templateType: templateType
      }
      return await this._EmailTemplatesService.UpdateDefaultTemplate(defaultTemplateUpdate);
     } catch (e) {
      console.error(e);
      throw e;
    }
  }

  mapToUI(requestedTopicId: number, templateDetail: TemplateDetail): TemplateDetailUI {
    let smstemplateData: any; 
    const topicId = templateDetail.templateDomainData.topicId ? templateDetail.templateDomainData.topicId : requestedTopicId;
    const topicName = this.notificationActions.find(x => x.id == topicId).name;

    if (templateDetail.templateDomainData.templateData) {
      smstemplateData = templateDetail.templateDomainData.templateData &&templateDetail.templateDomainData.templateData.length ===1
       && templateDetail.templateDomainData.templateData[0];
    }

    const formData: TemplateForm = {
      action: topicId,
      name: topicName,
      smsType1: smstemplateData ? smstemplateData.htmlContent : '',
      templateId1: smstemplateData ? smstemplateData.templateId : '',
      templateName1: smstemplateData ? smstemplateData.templateName : '',
    };
    const keywords = templateDetail.keywords;

    return {
      keywords: keywords,
      templateForm: formData
    };   
  }


}
