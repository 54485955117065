import { Component, OnInit,ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import * as myGlobals from '../shared/globalsContant';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailLocalization } from '../common/localization/retail-localization';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarriersComponent implements OnInit {
  tableoptions:any[];
  currIndex:any;
  IsViewOnly:boolean;
  captions: any = this.localization.captions.retailsetup;
  type:any;
  onEditDisableFunction:boolean = true;
  constructor(private retailSetup:RetailSetupService,private localization:RetailLocalization) { }

  ngOnInit() {
    this.tableoptions= [
      {
        TableHdrData: [{ "title":"Carriers Name", "jsonkey": "carriers", "sortable":true },
        { "title": "Active", "jsonkey": "active", "sortable":false },
        { "title":"List Order", "jsonkey": "listOrder", "alignType": "left", "sortable":false }],
        TablebodyData: [
          { "id": "1", "carriers": "USPS", "active": true, "listOrder":"1" },
          { "id": "2", "carriers": "AB Logistics", "active": true, "listOrder":"2" },
          { "id": "3", "carriers": "J.B.Hunt Logistics", "active": true, "listOrder":"3" }
          ],
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: "Search",
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.outlet,
        Sortable: "itemDescription",
        TableId:myGlobals.GridType.outlet,
        disableDelete:false,
        customHeader:true,
        pageTitle:'carriers',
        ServiceId:'carriers',
        TableDraggable: true,
        IsViewOnly:this.IsViewOnly
      }
    ];
  }

  addCarrier(data?:any, e?:any){
    if(data.type == 'Add'){
      let newCarrier = [{
        "id": Number(this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1] ? this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1].id : 0) + 1,
        "carriers": data.value.controls.setupCodeName.value,
        "active": true,
        "listOrder": Number(this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1] ? this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1].listOrder : 0) + 1
      }];
      this.tableoptions[0].TablebodyData = [...this.tableoptions[0].TablebodyData,...newCarrier];
      this.tableoptions=[...this.tableoptions];
    }
    else if(data.type == 'UPDATE'){
      this.tableoptions[0].TablebodyData[this.currIndex] = {
        "id": this.tableoptions[0].TablebodyData[this.currIndex].id,
        "carriers": data.value.controls.setupCodeName.value,
        "active": data.value.controls.activetoggle.value,
        "listOrder": this.tableoptions[0].TablebodyData[this.currIndex].listOrder
      }
    }
    return true;
  }

  EditRecords(data?:any, type?:any){
    this.currIndex =  this.tableoptions[0].TablebodyData.findIndex(item => item.id==data[0].id);
  }
  DeleteRecords(event:any){
    let currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id==event[0].id);
    this.tableoptions[0].TablebodyData.splice(currIndex,1);
    this.tableoptions=[...this.tableoptions];
   }
  sliderChange(event:any){
    let currIndex =  this.tableoptions[0].TablebodyData.findIndex(item => item.id==event.data.id);
    this.tableoptions[0].TablebodyData[currIndex].active = !event.value;
  }
  
}
