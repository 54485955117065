import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SPAManagementBreakPoint } from '../../../shared/globalsContant';

@Injectable()
export class AuditRouterGuard {

	constructor(private router: Router, private bpoint: BreakPointAccess) { }

	canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
		let userTherapist = sessionStorage.getItem('UserTherapist');
		let isTherapistLogin = userTherapist ? true : false;
		if(isTherapistLogin){
			let canRedirect = true;
			let isRouteDisabledonSingleTherapist = route.data.disableRouteonSingleTherapist;
			if(isRouteDisabledonSingleTherapist && isTherapistLogin){
				this.redirectToApptURL('/appointment/newappointment');
				canRedirect = false;
			}
			return canRedirect;
		} else {
			return this.bpoint.CheckForAccess([SPAManagementBreakPoint.DayEnd], true);
		}
	}

	redirectToApptURL(redirectLocation) {
		this.router.navigate([redirectLocation]);
	}
}
