import { DateFormater } from './date-formater.interface';
import { NumberFormater } from './number-formatter.interface';
import { DecimalFormater } from 'src/app/eatecui/source/shared/formaters/decimal-formater.interface';
import { CurrencyFormater } from 'src/app/eatecui/source/shared/formaters/currency-formaters.interface';
export const Formaters = {
  dateformater: DateFormater,
  numberFormatter: NumberFormater,
  decimalFormatter: DecimalFormater,
  currencyFormater: CurrencyFormater
};

