import {
  AppointmentDetails,
  AppointmentInformation,
  AppointmentServiceClientData,
  AppointmentServiceInformation,
  AppointmentServiceInformationModal,
  CostDetails,
  CustomFieldData,
  GroupAppointment,
  MedicalWarning,
  MultiServiceInformation,
  OtherDetailsModel,
  ServiceInformation,
  SpaWizardActionRequestType,
  SpaWizardAppointmentRequest,
  Warning
} from '../spa-wizard.modal';
import { AppointmentServiceInformationService } from 'src/app/shared/service/appointmentserviceinformation.service';
import { AppointmentStatus } from 'src/app/retail/shared/globalsContant';
import { Injectable } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaWizardService } from '../spa-wizard.service';
import { uniq as _unique } from 'lodash';

@Injectable()
export class AppointmentServiceInformationBusiness {

  model: AppointmentServiceInformationModal;
  constructor(
    private appointmentApi: AppointmentServiceInformationService,
    private localization: SpaLocalization,
    private spaWizardService: SpaWizardService) {
  }
  exceptionHandle(error: any) {
    console.error(error);
    throw error;
  }

  async build(selectedAppointment: GroupAppointment, multiClientAppointments: GroupAppointment[], clientIds: number[]): Promise<AppointmentServiceInformation> {
    this.model = null;
    const appointmentClientInfo = await this.getSpaWizardClientInfo(selectedAppointment, multiClientAppointments, clientIds);
    const medicalWarnings = await this.getServiceMedicalConditions(selectedAppointment.appointment.appointmentDetail.serviceId);
    const groupAppointmentInfo = await this.getGroupAppointmentData(selectedAppointment.appointment.appointmentDetail.groupAppointmentId);
    const locations = this.spaWizardService.locationArray;
    const services = this.spaWizardService.serviceArray;
    const therapists = this.spaWizardService.therapistArray;
    const customFields = this.spaWizardService.customFields;
    const medicalConditions = this.spaWizardService.medicalConditions;
    this.model = {
      appointments: multiClientAppointments && multiClientAppointments.length > 1 ? multiClientAppointments.map(gp => gp.appointment) : [selectedAppointment.appointment],
      customFields: customFields,
      locations: locations,
      services: services,
      therapists: therapists,
      medicalWarning: medicalConditions,
      requestedAppointment: selectedAppointment.appointment,
      requestedAppointmentId: selectedAppointment.appointment.appointmentDetail.id,
      requestedAppointmentClientInfo: appointmentClientInfo,
      requestedAppointmentGroupInfo: groupAppointmentInfo,
      serviceMedicalWarnings: medicalWarnings && medicalWarnings.medicalCondition ? medicalWarnings.medicalCondition : []
    };
    return this.buildData();
  }

  async getClientData(clientIds: number[], appointmentClientId: number) {
    const result = {
      requestedClientName: '-',
      requestedClientGender: '-',
      appointmentClientList: []
    };
    const promises = [
      this.appointmentApi.GetClientByIds(clientIds)
    ];
    const pro = await Promise.all(promises);
    const clientInfo: any[] = pro[0];
    if (clientInfo) {
      const requestedClient = clientInfo ? clientInfo.find(x => x.id === appointmentClientId) : null;
      if (requestedClient) {
        result.requestedClientName = requestedClient.firstName + ' ' + requestedClient.lastName;
        result.requestedClientGender = requestedClient.gender;
      }
      result.appointmentClientList = this.getClientList(clientInfo);
    }
    return result;
  }

  async getServiceMedicalConditions(serviceId: number) {
    try {
      return await this.appointmentApi.GetMedicalWarning(serviceId);
    } catch(e) {
      this.exceptionHandle(e);
    }
    
  }

  async getGroupAppointmentData(groupAppointmentId: number) {
    try {
      if (groupAppointmentId != null && groupAppointmentId !== 0) {
        return await this.appointmentApi.GetGroupAppointmentById(groupAppointmentId);
      }
      return null;
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  buildData(): AppointmentServiceInformation {
    return {
      appointmentInformation: this.buildAppointmentInformation(),
      customFieldData: this.buildCustomFieldData(),
      medicalWarning: this.buildMedicalWarning(),
      multiServiceInformation: this.buildMultiServiceInformation(),
      serviceInformation: this.buildServiceInformation(),
      customFields: this.buildCustomFields()
    };
  }

  buildAppointmentInformation(): AppointmentInformation {
    return {
      appointmentDetails: this.buildAppointmentDetail(),
      costDetails: this.buildCostDetails(),
      otherDetails: this.buildOtherDetails()
    };
  }

  buildAppointmentDetail(): AppointmentDetails {
    const response = this.model;
    const location = response.locations ? response.locations.find(l => l.id === response.requestedAppointment.appointmentDetail.locationId) : null;
    const service = response.services.find(s => s.id === response.requestedAppointment.appointmentDetail.serviceId);
    const staffs = response.therapists.filter(therapist => response.requestedAppointment.appointmentTherapists.some(appointmentTherapist => therapist.id === appointmentTherapist.therapistId));
    const isTempStatus = this.checkTempStatus(response); 
    const spaWizardFormValues = isTempStatus ? this.spaWizardService.GetAppointmentFilterData() : null;
    return {
      appointmentId: response.requestedAppointmentId,
      appointmentDateTime: response.requestedAppointment.appointmentDetail.startTime,
      bookingName: response.requestedAppointmentGroupInfo ? response.requestedAppointmentGroupInfo.groupName : spaWizardFormValues ? spaWizardFormValues.clientForm.bookingName :'-',
      clientId: response.requestedAppointment.appointmentDetail.clientId,
      clientName: response.requestedAppointmentClientInfo.requestedClientName,
      clientGender: response.requestedAppointmentClientInfo.requestedClientGender,
      duration: response.requestedAppointment.appointmentDetail.duration,
      location: location ? location.description : '-',
      offsiteLocation: location == null ? this.localization.captions.common.Yes : '-',
      service: service.description,
      staff: staffs.map(x => `${x.firstName} ${x.lastName}`).join(','),
      status: this.getAppointmentStatusAsEnum(response.requestedAppointment.appointmentDetail.status)
    };
  }

  checkTempStatus(response: AppointmentServiceInformationModal){
    if(response && response.appointments && response.appointments.length > 0)
      return response.appointments[0].appointmentDetail.status == AppointmentStatus.Temp;
  }
  joinTherapistNames(previousValue, currentValue, index, objects: any[]) {
    return previousValue + index < objects.length - 1 ? ',' : '' + currentValue.firstName + ' ' + currentValue.lastName;
  }

  getAppointmentStatusAsEnum(status: string): AppointmentStatus {
    let appointmentStatus: AppointmentStatus;
    switch (status) {
      case 'BREAK':
        appointmentStatus = AppointmentStatus.Break;
        break;
      case 'CANC':
        appointmentStatus = AppointmentStatus.Canceled;
        break;
      case 'CKIN':
        appointmentStatus = AppointmentStatus.CheckIn;
        break;
      case 'CKOUT':
        appointmentStatus = AppointmentStatus.CheckOut;
        break;
      case 'NOSHOW':
        appointmentStatus = AppointmentStatus.NoShow;
        break;
      case 'RESV':
        appointmentStatus = AppointmentStatus.Scheduled;
        break;
      case 'TEMP':
        appointmentStatus = AppointmentStatus.Temp;
        break;
      default:
        break;
    }

    return appointmentStatus;
  }

  buildCostDetails(): CostDetails {
    const response: any = this.model.requestedAppointment.appointmentDetail;
    const tempgratuity = response.gratuity ? response.gratuity : 0;
    const tempprice = response.price ? response.price : 0;
    const tempserviceCharge = response.serviceCharge ? response.serviceCharge : 0;
    const temptax = response.tax ? response.tax : 0;
    return {
      gratuity: response.Gratuity ? response.Gratuity : tempgratuity,
      package: this.getPackageDescription(response.packageId),
      price: response.Price ? response.Price : tempprice,
      serviceCharge: response.ServiceCharge ? response.ServiceCharge : tempserviceCharge,
      tax: response.Tax ? response.Tax : temptax
    };
  }

  getPackageDescription(packageId: number): string {
    const packages = this.spaWizardService.packages;
    const appointmentPackage = packages.find(p => p.id === packageId);
    if (appointmentPackage) {
      return appointmentPackage.description;
    }
    return '-';
  }

  buildOtherDetails(): OtherDetailsModel {
    const response = this.model;
    return {
      appointmentComments: response.requestedAppointment.appointmentDetail.comments,
      checkoutComments: response.requestedAppointment.appointmentDetail.checkOutComments,
      doNotMoveAppointment: response.requestedAppointment.appointmentDetail.doNotMove,
      genderPreference: response.requestedAppointment.appointmentDetail.genderPreference,
      isStaffMemberRequested: response.requestedAppointment.appointmentDetail.requestStaff,
      isVip: response.requestedAppointment.appointmentDetail.isVip,
      isBookingLocked: response.requestedAppointment.appointmentDetail.isBookingLocked
    };
  }

  buildCustomFieldData(): CustomFieldData {
    const response = this.model.requestedAppointment.appointmentDetail;
    return {
      CustomField1: response.customField1 === 0 ? '' : response.customField1,
      CustomField2: response.customField2 === 0 ? '' : response.customField2,
      CustomField3: response.customField3 === 0 ? '' : response.customField3,
      CustomField4: response.customField4,
      CustomField5: response.customField5,
    };
  }

  buildMedicalWarning(): MedicalWarning {
    const response = this.model;
    const warningMessages: Warning[] = response.serviceMedicalWarnings.map(mw => {
      return {
        warningMessage: mw.warning,
        warningTitle: mw.description
      } as Warning;
    });
    return {
      warnings: warningMessages
    };
  }

  buildMultiServiceInformation(): MultiServiceInformation {
    const response = this.model;
    const service = response.services.find(s => s.id === response.requestedAppointment.appointmentDetail.serviceId);
    const staffs = response.therapists
      .filter(therapist => response.requestedAppointment.appointmentTherapists
        .some(appointmentTherapist => therapist.id === appointmentTherapist.therapistId))
      .map(x => x.firstName + ' ' + x.lastName);
    return {
      clientList: response.requestedAppointmentClientInfo.appointmentClientList,
      minNumberOfClients: service.minimumGuest,
      maxNumberOfClients: service.maximumGuest,
      minNumberOfStaff: service.minimumStaff,
      maxNumberOfStaff: service.maximumStaff,
      therapistList: response.requestedAppointmentClientInfo.appointmentTherapistList
    };
  }

  getClientList(clientInfo: any[]) {
    return clientInfo.map(x => x.firstName + ' ' + x.lastName);
  }

  buildServiceInformation(): ServiceInformation {
    const response = this.model;
    const service = response.services.find(s => s.id === response.requestedAppointment.appointmentDetail.serviceId);
    return {
      serviceId: service.id,
      serviceCancelPolicy: service.cancellationPolicy,
      serviceComments: service.serviceComment,
      servicePolicy: service.servicePolicy
    };
  }

  buildCustomFields(): any[] {
    return this.spaWizardService.customFields;
  }

  async UpdateAppointmentServiceInformation(data: any): Promise<any> {
    try {
      const request = this.ApiMapper(data);
      return await this.appointmentApi.UpdateAppointmentAction(request);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  ApiMapper(data: any): SpaWizardAppointmentRequest[] {
    return [{
      spaWizardActionRequestType: SpaWizardActionRequestType.UpdateAppointmentServiceInformation,
      checkOutComments: data.checkoutComments,
      comments: data.appointmentComments,
      customField1: data.customField1,
      customField2: data.customField2,
      customField3: data.customField3,
      customField4: data.customField4,
      customField5: data.customField5,
      doNotMove: data.doNotMove,
      genderPreference: data.genderPreference,
      id: data.appointmentId,
      isVip: data.isVip,
      requestStaff: data.isStaffMemberRequested,
    }];
  }

  async deleteTempHold(tempHoldIds: any[]): Promise<any> {
    try {
      return await this.appointmentApi.deleteTempHold(tempHoldIds);
    } catch (e) {
      this.exceptionHandle(e);
    }
    
  }

  async getSpaWizardClientInfo(selectedAppointment: GroupAppointment, multiClientAppointments: GroupAppointment[], clientIds: number[]): Promise<AppointmentServiceClientData> {
    const tempInfo = this.getTempClientInfo(selectedAppointment, multiClientAppointments);
    const serverInfo = await this.getClientData(clientIds, selectedAppointment.appointment.appointmentDetail.clientId);
    const result = {
      requestedClientName: '-',
      requestedClientGender: '-',
      appointmentClientList: [],
      appointmentTherapistList: []
    };
    if (selectedAppointment.tempClientId) {
      result.requestedClientName = tempInfo.requestedClientName;
      result.requestedClientGender = tempInfo.requestedClientGender;
    } else {
      result.requestedClientName = serverInfo.requestedClientName;
      result.requestedClientGender = serverInfo.requestedClientGender;
    }
    result.appointmentClientList = result.appointmentClientList.concat(tempInfo.appointmentClientList).concat(serverInfo.appointmentClientList).sort();
    result.appointmentTherapistList = this.getAllAppointmentTherapist(selectedAppointment, multiClientAppointments).sort();
    return result;
  }

  getTempClientInfo(selectedAppointment: GroupAppointment, multiClientAppointments: GroupAppointment[]): AppointmentServiceClientData {

    let clientList: any[] = [];
    const result = {
      requestedClientName: '-',
      requestedClientGender: '-',
      appointmentClientList: [],
      appointmentTherapistList: []
    };
    const leftPanelClient = this.spaWizardService.selectedClients.find(x => x.clientDetail.tempId === selectedAppointment.tempClientId && x.clientDetail.tempId !== '');
    if (leftPanelClient) {
      result.requestedClientName = `${leftPanelClient.clientDetail.firstName} ${leftPanelClient.clientDetail.lastName}`;
      result.requestedClientGender = '-';
      clientList.push(`${leftPanelClient.clientDetail.firstName} ${leftPanelClient.clientDetail.lastName}`);
    }
    if (multiClientAppointments) {
      const multiTempClients = multiClientAppointments.map(x => x.tempClientId);
      this.spaWizardService.selectedClients.forEach(x => {
        if (multiTempClients.includes(x.clientDetail.tempId) && x.clientDetail.tempId !== selectedAppointment.tempClientId && x.clientDetail.tempId !== '') {
          clientList.push(`${x.clientDetail.firstName} ${x.clientDetail.lastName}`);
        }
      });
    }
    clientList = clientList.filter(x => x !== '');
    result.appointmentClientList = clientList;
    return result;
  }

  getAllAppointmentTherapist(selectedAppointment: GroupAppointment, multiClientAppointments: GroupAppointment[]) {
    let therapistList: string[] = [];
    let therapistIds: number[] = [];
    if (multiClientAppointments) {
      multiClientAppointments.forEach(x => {
        x.appointment.appointmentTherapists.forEach(z => {
          therapistIds.push(z.therapistId);
        });
      });
    } else {
      therapistIds = selectedAppointment.appointment.appointmentTherapists.map(x => {
        return x.therapistId;
      });
    }
    therapistIds = _unique(therapistIds);
    therapistList = this.spaWizardService.therapistArray.filter(therapist => therapistIds.includes(therapist.id))
      .map(x => {
        if (x) {
          return x.firstName + ' ' + x.lastName;
        }
        return '';
      });
    therapistList = therapistList.filter(x => x !== '');
    return therapistList;
  }
}
