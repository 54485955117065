import { Action } from '@ngrx/store';

export enum EUserList {
    GetUserList = '[GetUserList] Get GetUserList',
    GetUserListSuccess = '[GetUserListSuccess] Get GetUserListSuccess',
    GetUserListFailure = '[GetUserListFailure] Get GetUserListFailure',
    DeactivateUser = '[DeactivateUser] Get DeactivateUser',
    DeactivateUserSuccess = '[DeactivateUserSuccess] Get DeactivateUserSuccess',
    DeactivateUserFailure = '[DeactivateUserFailure] Get DeactivateUserFailure',
    ActivateUser = '[ActivateUser] Get ActivateUser',
    ActivateUserSuccess = '[ActivateUserSuccess] Get ActivateUserSuccess',
    ActivateUserFailure = '[ActivateUserFailure] Get ActivateUserFailure'
}

export class GetUserList implements Action {
    readonly type = EUserList.GetUserList;
    constructor(public payload: any) { }
}

export class GetUserListSuccess implements Action {
    readonly type = EUserList.GetUserListSuccess;
    constructor(public payload: any) { }
}

export class GetUserListFailure implements Action {
    readonly type = EUserList.GetUserListFailure;
    constructor(public payload: any) { }
}

export class DeactivateUser implements Action {
    readonly type = EUserList.DeactivateUser;
    constructor(public payload: any) { }
}

export class DeactivateUserSuccess implements Action {
    readonly type = EUserList.DeactivateUserSuccess;
    constructor(public payload: any) { }
}

export class DeactivateUserFailure implements Action {
    readonly type = EUserList.DeactivateUserFailure;
    constructor(public payload: any) { }
}

export class ActivateUser implements Action {
    readonly type = EUserList.ActivateUser;
    constructor(public payload: any) { }
}

export class ActivateUserSuccess implements Action {
    readonly type = EUserList.ActivateUserSuccess;
    constructor(public payload: any) { }
}

export class ActivateUserFailure implements Action {
    readonly type = EUserList.ActivateUserFailure;
    constructor(public payload: any) { }
}

export type UserListAction = GetUserList | GetUserListSuccess | GetUserListFailure | DeactivateUser | DeactivateUserSuccess
| DeactivateUserFailure | ActivateUser | ActivateUserSuccess | ActivateUserFailure;