import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-temperature-renderer',
  templateUrl: './temperature-renderer.component.html',
  styleUrls: ['./temperature-renderer.component.scss']
})
export class TemperatureRendererComponent implements OnInit {
  params: any;
  data: any;
  showrange: boolean;

  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    this.params = params;
    this.data = params.data;
    if (this.data.MinTemperature !== 0 && this.data.MaxTemperature !== 0) {
      this.showrange = true;
    }
  }
}
