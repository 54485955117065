import { GridSortAction } from '../app-grid.interface';
import { SetSortOrders } from '../grid.interface';

export const SetSortOrder: SetSortOrders = (GridSort: GridSortAction[]): any => {
    try {
        let sortOrder = '';
        if (GridSort.length > 0 ) {
            const sort: GridSortAction = GridSort.filter( x => x.DefaultSorting === true)[0];
            sortOrder = sort.SortingKey + ' ' + sort.SortingType.toLowerCase();
            return sortOrder;
        } else {
            return '';
        }
    } catch (error) {
        console.log('error occured SetSortOrder', error);
    }
};