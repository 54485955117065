import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {DateInput} from '../../retail.modals';
import { UntypedFormGroup } from '@angular/forms';
import { RetailLocalization } from '../../common/localization/retail-localization'; 
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  hidden: boolean = false;
  maxDate: string|Date;
  minDate: string|Date;
  placeHolder: string;
  value: string|Date;
  automationId: string;
  captions = {
    invalid: "Invalid"
  }
  @Output() datePickerChange = new EventEmitter();
  captionsLocalization: any;
  placeHolderFormat: string;
  @Input('inputs')
  set inputOptions(value: DateInput) {
    this.className = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.hidden = value.hidden ? value.hidden : false;
    this.maxDate = value.maxDate;
    this.minDate = value.minDate;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.automationId = value.automationId ? value.automationId : '';
  }
  floatLabel: string;

  constructor(private localization:RetailLocalization) { 
    this.captionsLocalization = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.placeHolderFormat = this.localization.inputDateFormat;
  }

  dateChanged(event) {
    this.datePickerChange.emit([this.form, this.formControlName]);
  }

}
