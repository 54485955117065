import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
@Component({
  selector: 'app-sales-by-discountype',
  templateUrl: './sales-by-discountype.component.html',
  styleUrls: ['./sales-by-discountype.component.scss']
})
export class SalesByDiscountypeComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  SalesByDiscountTypeFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  outlets: any;
  allCategoryGroups: any[];
  discountTypes: any[];
  categories: any[];
  allCategories: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  valuesSelected: DropDownData[] = [];
  selectedDropdownType = '';
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, public business: ReportBusinessService, private dataService: ReportDataService) { }

  ngOnInit() {
    this.SalesByDiscountTypeFormGrp = this.fb.group({
      reportcategory: new UntypedFormControl([]),
      category: new UntypedFormControl([]),
      discounttype: new UntypedFormControl([])
    });
    this.formReady.emit(this.SalesByDiscountTypeFormGrp);
    this.onLoad();
  }

  private async onLoad() {
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.categories = await this.dataService.GetAllCategories();
    this.discountTypes = await this.dataService.GetDiscountTypes();
    this.allCategories = this.categories;
    this.discountTypes.push({id: -1,description:"Other",active:true,listOrder:this.discountTypes.length+1,showInDropDown:true })
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
    this.business.FilterDataSource["categories"] = this.categories;
    this.business.FilterDataSource["discountTypes"] = this.discountTypes;

  }

 

  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
    this.SalesByDiscountTypeFormGrp.setControl('reportcategory', event[1]);
    } else if (event[0] === 'category') {
      this.SalesByDiscountTypeFormGrp.setControl('category', event[1]);
      } else if (event[0] === 'discounttype') {
        this.SalesByDiscountTypeFormGrp.setControl('discounttype', event[1]);
        }
  }

  getCategoryGroupValue(selectedCategoryGroupArray)
  {
    let filteredCategory = this.allCategories.filter(element => selectedCategoryGroupArray.map(r=>r.id).includes(element.categoryGroup));
    this.categories = filteredCategory;
  }
}
