import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewChecked, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UtilityMoveOptions, Host, SPAManagementBreakPoint, SPAScheduleBreakPoint, ButtonType, ButtonOptions } from '../../../shared/globalsContant';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import * as _ from 'lodash';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { ScrollbarComponent } from 'ngx-scrollbar';
import { BaseResponse, ClientDetail, GenderOverrideMessage, GenderOverrideTransLog } from '../../../shared/business/shared.modals';
import { ReplaySubject, SubscriptionLike as ISubscription } from 'rxjs';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { takeUntil } from 'rxjs/operators';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import * as moment from 'moment';
import { AppointmentpopupService } from 'src/app/shared/service/appointmentpopup.service';
import { AppointmentUtilities } from 'src/app/shared/utilities/appointment-utilities';
import * as GlobalConst from '../../../shared/globalsContant';

@Component({
  selector: 'app-multiple-appointments',
  templateUrl: './multiple-appointments.component.html',
  styleUrls: ['./multiple-appointments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleAppointmentsComponent implements OnInit, AfterViewChecked, OnDestroy {
  //any
  therapistOptions: any = [];
  locationOptions: any = [];
  therapistMoveOptions: any = [];
  locationMoveOptions: any = [];
  appointments: any = [];
  minFromValue: any;
  minToValue: any;
  tableHeaderArray: any = [];
  tableDataArray: any = [];
  moveResults: any;
  captions: any;
  moveMultiBreakPoints: any;

  //boolean
  enableProcess = false;
  changeWidth = false;

  //FormGroup
  utilityGroup: UntypedFormGroup;
  toggleForm: UntypedFormGroup;

  //number
  moveTypeId: number;
  locationId: number;
  moveAllTherapistId: number;
  moveAllLocationId: number;
  therapistId: number;
  showLoader = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  isGenderPreferenceOverride: boolean = false;
  genderOverrideappointmentIds: number[] = [];

  @ViewChild(ScrollbarComponent) scrollbarRef: ScrollbarComponent;
  startDateSubscription: ISubscription;
  endDateSubscription: ISubscription;
  moveOptions = [
    { id: UtilityMoveOptions.therapistAppointments, viewValue: this.localization.captions.setting.TherapistAppointments, isDisable: false },
    { id: UtilityMoveOptions.therapistLocation, viewValue: this.localization.captions.setting.TherapistLocation, isDisable: false },
    { id: UtilityMoveOptions.appointmentLocation, viewValue: this.localization.captions.setting.AppointmentLocation, isDisable: false }
  ];
  commonCaptions: any;
  placeholderFormat: string;
  floatLabel:string;
  constructor(public localization: SpaLocalization, private Form: UntypedFormBuilder, private http: HttpServiceCall,
    private utilities: SpaUtilities, private breakPoint: BreakPointAccess, private PropertyInfo: SpaPropertyInformation,
    private cdRef: ChangeDetectorRef, public quickLoginUtils: QuickLoginUtilities, private PropertyDetails: RetailPropertyInformation
    , private appointmentPopupService: AppointmentpopupService, private ApptUtilities: AppointmentUtilities
    ) {
      this.commonCaptions = this.localization.captions.common;
      this.placeholderFormat = this.localization.inputDateFormat;
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    const DateObj = moment(this.PropertyInfo.CurrentDate).add(0, "days").format();
    this.utilityGroup = this.Form.group({
      startDate: DateObj,
      endDate: DateObj,
      moveType: []
    });
    this.toggleForm = this.Form.group({
      deleteRecords: false
    });
    this.captions = this.localization.captions;
    this.moveMultiBreakPoints = this.breakPoint.GetBreakPoint([SPAManagementBreakPoint.MoveMultipleApptAppointmentLocation,
    SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments, SPAManagementBreakPoint.MoveMultipleApptTherapistLocation]).result;
    if (this.moveMultiBreakPoints && this.moveMultiBreakPoints.length > 0) {
      this.moveTypeId = this.getDefaultMoveType(this.moveMultiBreakPoints);
      this.moveMultiBreakPoints.forEach(element => {
        if (element.breakPointNumber === SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments) {
          this.moveOptions.find(x => x.id == UtilityMoveOptions.therapistAppointments).isDisable = !element.allow;
        } else if (element.breakPointNumber === SPAManagementBreakPoint.MoveMultipleApptTherapistLocation) {
          this.moveOptions.find(x => x.id == UtilityMoveOptions.therapistLocation).isDisable = !element.allow;
        } else if (element.breakPointNumber === SPAManagementBreakPoint.MoveMultipleApptAppointmentLocation) {
          this.moveOptions.find(x => x.id == UtilityMoveOptions.appointmentLocation).isDisable = !element.allow;
        }
      });
    } else {
      this.moveTypeId = this.moveOptions[0].id;
    }
    this.loadOptions('getActiveTherapist');
    this.loadOptions('GetAllLoc');
    this.minFromValue = this.PropertyInfo.CurrentDate;
    this.minToValue = this.PropertyInfo.CurrentDate;
    this.formValueChanges();
    this.moveOptionsChange();
    //Removed setTimeout
    this.changeWidth = true;
  }

  ngAfterViewChecked() {
    if (this.changeWidth) {
      this.changeWidth = false;
      setTimeout(() => {
        this.calculateWidth();
      }, 1);
    }
  }

  ngOnDestroy() {
    this.quickLoginUtils.resetQuickIdDetails();
    if (this.startDateSubscription) {
      this.startDateSubscription.unsubscribe();
    }
    if (this.endDateSubscription) {
      this.endDateSubscription.unsubscribe();
    }
  }

  checkAccess(option) {
    if (option.isDisable) {
      this.displayUserMessage(option.id);
    } else {
      this.moveTypeId = option.id;
      this.moveOptionsChange();
    }
  }

  moveOptionsChange() {
    this.tableHeaderArray = [];
    this.tableDataArray = [];
    switch (this.moveTypeId) {
      case UtilityMoveOptions.therapistAppointments:
        this.therapistId = 0;
        this.moveAllTherapistId = 0;
        this.tableHeaderArray = [{ tableHeader: this.localization.captions.setting.Service, keyValue: "service", alignType: "left" }, { tableHeader: this.localization.captions.setting.AppointmentTime, keyValue: "appointmentTime", alignType: "left" },
        { tableHeader: this.localization.captions.setting.Location, keyValue: "location", alignType: "left" }, { tableHeader: this.localization.captions.setting.MovetoTherapist, keyValue: "moveToValue", alignType: "left" },
        { tableHeader: this.localization.captions.setting.Status, keyValue: "status", alignType: "left" }];
        this.therapistMoveOptions = this.therapistOptions;
        break;
      case UtilityMoveOptions.therapistLocation:
        this.therapistId = 0;
        this.moveAllLocationId = 0;
        this.tableHeaderArray = [{ tableHeader: this.localization.captions.setting.Service, keyValue: "service", alignType: "left" }, { tableHeader: this.localization.captions.setting.AppointmentTime, keyValue: "appointmentTime", alignType: "left" },
        { tableHeader: this.localization.captions.setting.Location, keyValue: "location", alignType: "left" }, { tableHeader: this.localization.captions.setting.MovetoLocation, keyValue: "moveToValue", alignType: "left" },
        { tableHeader: this.localization.captions.setting.Status, keyValue: "status", alignType: "left" }];
        this.locationMoveOptions = this.locationOptions;
        break;
      case UtilityMoveOptions.appointmentLocation:
        this.locationId = 0;
        this.moveAllLocationId = 0;
        this.tableHeaderArray = [{ tableHeader: this.localization.captions.setting.Service, keyValue: "service", alignType: "left" }, { tableHeader: this.localization.captions.setting.AppointmentTime, keyValue: "appointmentTime", alignType: "left" },
        { tableHeader: this.localization.captions.setting.Therapist, keyValue: "therapist", alignType: "left" }, { tableHeader: this.localization.captions.setting.MovetoLocation, keyValue: "moveToValue", alignType: "left" },
        { tableHeader: this.localization.captions.setting.Status, keyValue: "status", alignType: "left" }];
        this.locationMoveOptions = this.locationOptions;
        break;
    }
    this.changeWidth = true;
    this.isEnableProcessButton();
  }

  loadOptions(callDes) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      callDesc: callDes,
      method: HttpMethod.Get,
      showError: false,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      extraParams: []
    });
  }

  makeGetCalls(callDesc: string, uriParams, extraParams) {
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      callDesc: callDesc,
      method: HttpMethod.Get,
      showError: false,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      uriParams: uriParams,
      extraParams: extraParams
    });
  }

  getAppointments(fromDate, toDate, therapistId, locationId) {
    const uriParams = { fromDate: fromDate, toDate: toDate, therapistId: therapistId, locationId: locationId };
    this.makeGetCalls('GetAppointmentsForMultiMove', uriParams, []);
  }

  formValueChanges(): void {
    this.startDateSubscription = this.utilityGroup.get("startDate").valueChanges.subscribe(val => {
      this.minToValue = val;
      const toDate = this.utilityGroup.controls['endDate'].value;
      if (this.utilities.getDate(toDate) < this.utilities.getDate(val)) {
        this.utilityGroup.controls.endDate.setValue(val);
      }
      this.getAppoinmentByChance(val, toDate);
    });
    this.endDateSubscription = this.utilityGroup.get("endDate").valueChanges.subscribe(val => {
      this.getAppoinmentByChance(this.utilityGroup.controls['startDate'].value, val);
    });
  }

  getAppoinmentByChance(startDate, endDate) {
    const fromDate = this.utilities.formatDate(startDate);
    const toDate = this.utilities.formatDate(endDate);
    if (this.moveTypeId != 3 && this.therapistId != null && this.therapistId > 0) {
      this.getAppointments(fromDate, toDate, this.therapistId, 0);
    } else if (this.moveTypeId == 3 && this.locationId != null && this.locationId > 0) {
      this.getAppointments(fromDate, toDate, 0, this.locationId);
    }
    this.moveAllLocationId = 0;
    this.moveAllTherapistId = 0;
  }

  onChange(event, type): void {
    const fromDate = this.utilities.formatDate(this.utilityGroup.controls['startDate'].value);
    const toDate = this.utilities.formatDate(this.utilityGroup.controls['endDate'].value);
    switch (type) {
      case 'therapistOption':
        this.getAppointments(fromDate, toDate, event.value, 0);
        this.therapistMoveOptions = this.therapistOptions.filter(x => x.id != event.value);
        this.moveAllTherapistId = 0;
        this.moveAllLocationId = 0;
        break;
      case 'locationOption':
        this.getAppointments(fromDate, toDate, 0, event.value);
        this.locationMoveOptions = this.locationOptions.filter(x => x.id != event.value);
        this.moveAllLocationId = 0;
        break;
      case 'moveAllToTherapist':
        for (let i = 0; i < this.tableDataArray.length; i++) {
          if (!this.tableDataArray[i].isMoved) {
            this.tableDataArray[i].moveToValue = this.therapistMoveOptions.map(t => ({ id: t.id, value: t.firstName + ' ' + t.lastName }));
            this.tableDataArray[i].moveToValue.id = event.value;
          }
        }
        break;
      case 'moveAllToLocation':
        for (let i = 0; i < this.tableDataArray.length; i++) {
          if (!this.tableDataArray[i].isMoved) {
            this.tableDataArray[i].moveToValue = this.locationMoveOptions.map(l => ({ id: l.id, value: l.description }));
            this.tableDataArray[i].moveToValue.id = event.value;
          }
        }
        break;
    }
    this.changeWidth = true;
    this.isEnableProcessButton();
  }

  getMoveToValue(data, index): void {
    this.showLoader = true;
    this.tableDataArray[index].moveToValue = [{ id: 0, value: '' }];
    const blockStartTime = this.utilities.convertDateFormat(this.utilities.AddMins(data.startDateTime, data.setupTime * -1));
    const blockEndTime = this.utilities.convertDateFormat(this.utilities.AddMins(data.endDateTime, data.breakDownTime));
    if (this.moveTypeId == 1) {
      const uriParams = { serviceId: data.serviceId, startTime: blockStartTime, endTime: blockEndTime, appointmentId: data.id };
      this.makeGetCalls('GetAvailableTherapists', uriParams, [index]);
    } else if (this.moveTypeId != 1) {
      const uriParams = { serviceId: data.serviceId, startTime: blockStartTime, endTime: blockEndTime, therapistId: this.therapistId };
      this.makeGetCalls('GetAvailableLocations', uriParams, [index]);
    }
  }

  moveToValueChange(event, index) {
    if (event.value) {
      this.tableDataArray[index].moveToValue.id = event.value;
      this.isEnableProcessButton();
    }
  }

  displayOptions(keyValue) {
    return keyValue == 'moveToValue';
  }

  isEnableProcessButton() {
    const results: any = [];
    for (let i = 0; i < this.tableDataArray.length; i++) {
      if (this.tableDataArray[i].moveToValue.id > 0 && !this.tableDataArray[i].isMoved) {
        results.push(true);
      } else {
        results.push(false);
      }
    }
    this.enableProcess = results.includes(true);
  }

  successCallback<T>(
    result: BaseResponse<T>,
    callDesc: string,
    extraParams: any[]): void {
    switch (callDesc) {
      case 'getActiveTherapist': {
        this.therapistOptions = result.result;
        this.therapistMoveOptions = this.therapistOptions;
      } break;
      case 'GetAllLoc': {
        this.locationOptions = result.result;
        this.locationMoveOptions = this.locationOptions;
      } break;
      case 'GetAppointmentsForMultiMove': {
        this.appointments = result.result;
        this.tableDataArray = this.appointments.map(a => {
          return {
            id: a.appointmentId,
            serviceId: a.serviceId,
            service: a.serviceName,
            appointmentTime: this.utilities.FormatAppointmentDateTime(a.startDateTime, a.endDateTime),
            startDateTime: a.startDateTime,
            endDateTime: a.endDateTime,
            setupTime: a.setupTime,
            breakDownTime: a.breakDownTime,
            locationId: a.locationId,
            location: !a.locationName ? this.localization.captions.setting.Offsite : a.locationName,
            therapistIds: a.therapistId,
            therapist: a.therapistName,
            status: '', isMoved: false,
            moveToValue: [{ id: 0, value: '' }],
            requestStaff:a.requestStaff
          };
        });
      } break;
      case 'GetAvailableTherapists': {
        const availableTherapist: any = result.result;
        this.showLoader = false;
        if (availableTherapist && availableTherapist.length > 0) {
          const setTherapistData = setTimeout(() => {
            this.tableDataArray[extraParams[0]].moveToValue = availableTherapist.map(x => ({ id: x.therapistId, value: x.therapistName }));
            clearTimeout(setTherapistData);
            this.cdRef.detectChanges();
          }, 300);
        }
      } break;
      case 'GetAvailableLocations': {
        const availableLocations: any = result.result;
        this.showLoader = false;
        if (availableLocations && availableLocations.length > 0) {
          const setLocationData = setTimeout(() => {
            this.tableDataArray[extraParams[0]].moveToValue = availableLocations.map(x => ({ id: x.locationId, value: x.locationDescription }));
            clearTimeout(setLocationData);
            this.cdRef.detectChanges();
          }, 300);
        }
      } break;
      case 'MoveMultipleAppointments': {
        this.moveResults = result.result;
        for (let i = 0; i < this.moveResults.length; i++) {
          const appointmentId = this.moveResults[i].appointmentID;
          const index = this.tableDataArray.map(e => e.id).indexOf(appointmentId);
          if (this.moveResults[i].status && index !== -1) {
            this.tableDataArray[index].status = this.localization.captions.setting.MovedSuccessfully;
            this.tableDataArray[index].isMoved = true;
          } 
          else if(this.moveResults[i].errorCodes.length > 0 && this.moveResults[i].errorCodes[0] == 960){
            this.tableDataArray[index].status = this.captions.bookAppointment.MoveNotAllowed;
          }
          else if (!this.moveResults[i].status && index !== -1) {
            const therapistOrLocationName = this.tableDataArray[index].moveToValue.find(x => x.id == this.tableDataArray[index].moveToValue.id).value;
            if (this.moveResults[i].errorCodes.length > 0 && this.moveTypeId == 1 && this.moveResults[i].errorCodes[0] == GlobalConst.ErrorConstants.TherapistNotCertifiedForAddOn)
              this.tableDataArray[index].status = this.localization.replacePlaceholders(this.captions.utilities.UnableToMoveAddon, ['therapistOrLocation'], [therapistOrLocationName]);
            else
            this.tableDataArray[index].status = this.localization.replacePlaceholders(this.captions.utilities.UnableToMove, ['therapistOrLocation'], [therapistOrLocationName]);
          }
        }
        this.isEnableProcessButton()
        this.quickLoginUtils.resetQuickIdDetails();
      } break;
    }
    this.changeWidth = true;
  }

  errorCallback<T>(
    error: BaseResponse<T>,
    callDesc: string,
    extraParams: any[]
  ): void {
    if (callDesc == "MoveMultipleAppointments") {
      this.quickLoginUtils.resetQuickIdDetails();
    }
    this.showLoader = false;
  }

  moveAppointment() {
    if (this.tableDataArray && this.tableDataArray.length > 0) {
      const fromDate = this.utilities.formatDate(this.utilityGroup.controls['startDate'].value);
      const toDate = this.utilities.formatDate(this.utilityGroup.controls['endDate'].value);
      let isDeleteTherapistSchedule = false;
      const moveMultipleData: any = [];
      if (this.moveTypeId == 1) {
        for (let i = 0; i < this.tableDataArray.length; i++) {
          if (!this.tableDataArray[i].isMoved && this.tableDataArray[i].moveToValue.id > 0) {
            const index = this.tableDataArray[i].therapistIds.indexOf(this.therapistId);
            const therapist = _.cloneDeep(this.tableDataArray[i].therapistIds);
            if (index !== -1) {
              therapist[index] = this.tableDataArray[i].moveToValue.id;
            }
            const singleMoveData: any = {
              appointmentId: this.tableDataArray[i].id,
              locationId: this.tableDataArray[i].locationId,
              therapistId: therapist
            };
            moveMultipleData.push(singleMoveData);
          }
        }
        isDeleteTherapistSchedule = this.toggleForm.controls.deleteRecords.value;
      } else if (this.moveTypeId != 1) {
        for (let i = 0; i < this.tableDataArray.length; i++) {
          if (!this.tableDataArray[i].isMoved && this.tableDataArray[i].moveToValue.id > 0) {
            if (this.tableDataArray[i].locationId != this.tableDataArray[i].moveToValue.id) {
              const singleMoveData: any = {
                appointmentId: this.tableDataArray[i].id,
                locationId: this.tableDataArray[i].moveToValue.id,
                therapistId: this.tableDataArray[i].therapistIds
              };
              moveMultipleData.push(singleMoveData);
            } else {
              this.tableDataArray[i].status = this.captions.utilities.NoChange;
            }
          }
        }
      }
      if (moveMultipleData.length > 0) {
        this.http.CallApiWithCallback<any>({
          host: Host.schedule,
          callDesc: "MoveMultipleAppointments",
          method: HttpMethod.Put,
          showError: false,
          success: this.successCallback.bind(this),
          error: this.errorCallback.bind(this),
          body: moveMultipleData,
          uriParams: { therapistId: this.therapistId, fromDate: fromDate, toDate: toDate, isDeleteSchedule: isDeleteTherapistSchedule },
          extraParams: []
        });
      }
    }
  }
  process() {

    let quickConfig = this.PropertyDetails.getQuickIdConfig;
    if (quickConfig && quickConfig.QUICKID_APPOINTMENTMOVE) {
      const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        // update retail communication component for quickjwt
        if (quickLoginDialogResult.isLoggedIn) {
          if (!this.validateGenderPreferenceOverride()) {
            this.moveAppointment();
          }

        }
      });
    }
    else {
      if (!this.validateGenderPreferenceOverride()) {
        this.moveAppointment();
      }
    }
  }

  validateGenderPreferenceOverride() {
    let genderOverrideMesssage: GenderOverrideMessage[] = [];
    this.isGenderPreferenceOverride = false;
    this.genderOverrideappointmentIds = [];

    if (this.moveTypeId == 1) {
      for (let i = 0; i < this.tableDataArray.length; i++) {
        const appointmentId: number = this.tableDataArray[i].id;
        let appointment = this.appointments.find(x => x.appointmentId === appointmentId);
        const appointmentgender = appointment.genderPreference;
        const clientDetail: ClientDetail = appointment?.clientDetail;
        const currentTherapist = this.therapistOptions.find(x => x.id === appointment?.therapistId[0])

        if (!this.tableDataArray[i].isMoved && this.tableDataArray[i].moveToValue.id > 0) {
          let appointmentGenderPrefernce = this.appointmentPopupService.getGenderPreference(appointmentgender,currentTherapist);
          if (appointmentGenderPrefernce && appointmentGenderPrefernce !== "" && appointmentGenderPrefernce!==GlobalConst.Gender.NoPreference) {
            const toTherapistId = this.tableDataArray[i].moveToValue.id
            const index = this.tableDataArray[i].therapistIds.indexOf(this.therapistId);
            const therapist = _.cloneDeep(this.tableDataArray[i].therapistIds);
            if (index !== -1) {
              therapist[index] = toTherapistId;
            }
           
            const therapistDetail = this.therapistOptions.find(x => x.id === toTherapistId);
            if (appointmentGenderPrefernce !== therapistDetail.gender) {
              this.genderOverrideappointmentIds.push(appointmentId);
              if(this.tableDataArray[i].requestStaff==false)
              {
              let messageInput: GenderOverrideMessage = {
                appointmentId: appointmentId,
                therapistGender: therapistDetail.gender,
                clientName: clientDetail.firstName + " " + clientDetail.lastName,
                clientPreferredGender: appointmentGenderPrefernce,
                isOverriden: false,
                therapistId: 0,
                serviceId: 0,
                clientId: 0
              };
              genderOverrideMesssage.push(messageInput);
            }

            }
          }
        }
      }
    }
    if(this.genderOverrideappointmentIds.length > 0)
    {
    if (genderOverrideMesssage.length > 0) {

      if (this.breakPoint.CheckForAccess([SPAScheduleBreakPoint.OverrideGenderPreference])) {

        let message: string = this.appointmentPopupService.formGenderOverridePreferenceMessage(genderOverrideMesssage);
        this.utilities.ShowErrorMessage(this.localization.captions.alertPopup.warning,
          message, ButtonType.YesNo, this.genderPreferenceOverrideCallback.bind(this));

      }
      return true;
    }
    else
    {
      this.genderPreferenceOverrideCallback(ButtonOptions.Yes);
      return true;
    }
  }


    return false;
  }
  genderPreferenceOverrideCallback(result) {
    if (result.toLowerCase() === ButtonOptions.Yes) {
      this.isGenderPreferenceOverride = true;
      this.moveAppointment();
      if(this.isGenderPreferenceOverride){          
            
        let genderOverrideTransLog: GenderOverrideTransLog = {appointmentIds: this.genderOverrideappointmentIds};
        this.ApptUtilities.insertTransLogforGenderPreferenceOverride(genderOverrideTransLog);
      }
    }
  }

  getDefaultMoveType(breakPoint): number {
    const accessed = breakPoint.filter(x => x.allow).map(y => y.breakPointNumber);
    if (accessed.includes(SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments)) {
      return UtilityMoveOptions.therapistAppointments;
    } else if (accessed.includes(SPAManagementBreakPoint.MoveMultipleApptAppointmentLocation)) {
      return UtilityMoveOptions.appointmentLocation;
    } else if (accessed.includes(SPAManagementBreakPoint.MoveMultipleApptTherapistLocation)) {
      return UtilityMoveOptions.therapistLocation;
    }
    return 0;
  }

  displayUserMessage(type) {
    let breakPointNumber: SPAManagementBreakPoint;
    switch (type) {
      case UtilityMoveOptions.therapistAppointments:
        breakPointNumber = SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments;
        break;
      case UtilityMoveOptions.appointmentLocation:
        breakPointNumber = SPAManagementBreakPoint.MoveMultipleApptAppointmentLocation;
        break;
      case UtilityMoveOptions.therapistLocation:
        breakPointNumber = SPAManagementBreakPoint.MoveMultipleApptTherapistLocation;
        break;
    }
    this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[breakPointNumber]);
  }

  calculateWidth() {
    const tableHeight = document.querySelectorAll('#appointmentDetails>#appointmentDetailsTable>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')[0]['offsetHeight'];
    const parentHeight = document.getElementById('appointmentDetails').offsetHeight;
    if (parentHeight > tableHeight)
      document.getElementById('appointmentDetailsTable').style.height = tableHeight + 2 + 'px';
    else if (parentHeight < tableHeight)
      document.getElementById('appointmentDetailsTable').style.height = parentHeight + 'px';
    this.scrollbarRef.update();
  }

}
