import { Component, OnInit } from '@angular/core';
import { RouteLoaderService } from '../core/services/route-loader.service';
import { menuTypes } from '../shared/shared.modal';
import { RetailPropertyInformation } from '../retail/common/services/retail-property-information.service';


@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponenet implements OnInit {

  menuList: any;
  menuType = menuTypes;
  constructor(
    private routeDataService: RouteLoaderService
    , private propertyInformation: RetailPropertyInformation
  ) {    
   }

  async ngOnInit() {
    const value = this.routeDataService.GetChildMenu('/reports');    
    value.linkedElement.map(res => {
      if (res && res.routePath === '/reports/giftcards') {
        res.visibility = (!this.propertyInformation.UseRetailInterface && this.propertyInformation.UseGiftCardInterface);
      }
      if (res && res.routePath === '/reports/inventorycontrol') {
        res.visibility = (this.propertyInformation.IsEatecEnabled);
      }
    });
    this.menuList = {
      menu: value.linkedElement,
      menuType : menuTypes.secondary
    };
  }

}
