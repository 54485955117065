import { GridSearchAction } from 'src/app/eatecui/source/shared/models/app-grid.interface';
import { GridActionsConfig, GridColumnReorder, GridFilter, GridFilterData, GridInfiniteScrollConfig, GridSearchConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { GridConfiguration, GridFilterAction, TitleType } from '../master.interface';
import { GridActions } from './grid-config.interface';

export const GridActionData: GridActions = (ViewModelEntity, MasterDataCollection, ResponseData): GridActionsConfig => {
    try {
        const GridActionObject = {} as GridActionsConfig;
        GridActionObject.GridTitle = ViewModelEntity[0]['$']['Name'];
        GridActionObject.TotalItems = GridActionObject.GridTitle === 'Ingredients' ? ResponseData['totalCount'] : ResponseData['totalCount'];
        GridActionObject.EnableToggle = MasterDataCollection.GridConfig.ShowActiveToggle;
        GridActionObject.SearchConfig = {} as GridSearchConfig;
        GridActionObject.SearchConfig.EnabledSearch = true;
        GridActionObject.SearchConfig.SearchPlaceholder = GetSearchPlacholderName(MasterDataCollection.GridConfig.GridSearch);
        GridActionObject.FilterConfig = {} as GridFilter;
        GridActionObject.FilterConfig.EnableFilter = EnableFilterIcon(MasterDataCollection.GridConfig.GridFilter, ResponseData);
        // GridActionObject.FilterConfig.FilterOptions = GridConfig.GridFilter as unknown as Array<GridFilterData>;
        GridActionObject.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
        GridActionObject.InfiniteScrollConfig.EnableInfiniteScroll = true;
        GridActionObject.InfiniteScrollConfig.TotalRecords = ResponseData['totalCount'];
        GridActionObject.ShuffleColumnConfig = {} as GridColumnReorder;
        GridActionObject.ShuffleColumnConfig.GridName = ViewModelEntity[0]['$']['Name'];
        GridActionObject.ShuffleColumnConfig.EnableColumnShuffle = true;
        GridActionObject.GridTitleType = {} as TitleType;
        if (MasterDataCollection.GridConfig.GridTitleType) {
            GridActionObject.GridTitleType.Type = MasterDataCollection.GridConfig.GridTitleType.Type;
            GridActionObject.GridTitleType.TypeConfig = MasterDataCollection.GridConfig.GridTitleType.TypeConfig;
        } else {
            GridActionObject.GridTitleType.Type = 'text';
            GridActionObject.GridTitleType.TypeConfig = [];
        }
        return GridActionObject;
    } catch (error) {

    }
};

function GetSearchPlacholderName(GridSearchArray: GridSearchAction[]) {
    try {
        let SearchPlaceholder = 'Search by ';
        const SearchKeys = [];
        GridSearchArray.forEach(search => {
            SearchKeys.push(search.SearchPlaceholderName);
        });
        SearchPlaceholder = SearchPlaceholder + SearchKeys.join(', ');
        return SearchPlaceholder;
    } catch (error) {
        console.error('Error occurred in GetSearchPlacholderName', error);
    }
}

function EnableFilterIcon(GridFilters: GridFilterAction[], ResponseData: Array<any>) {
    try {
        if (ResponseData.length > 0 && GridFilters.length > 0) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error occurred in GetSearchPlacholderName', error);
    }
}