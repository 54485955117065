import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, includes, startsWith } from 'lodash'; // STORAGE THE BACK ARRAY
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Localization } from '../../../localization/localization';
import { CommonUtilities } from '../utilities/common-utilities';

@Component({
  selector: 'app-more-section',
  templateUrl: './more-section.component.html',
  styleUrls: ['./more-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreSectionComponent {

  searchText:string = '';
  belongto: any;
  frompage: any;
  moreDataContentFirstCharArr: any = [];
  removeOpacity = false;
  getheight: any;
  selectedMoreDataContent: any;
  moreDataContent: any;
  showMoreFilterSection: any = false;
  intialDataArray: Array<any> = [];
  moreOption: any;
  @Input() filterOptions;
  @Input() selectOne;
  @Input() sectionfrom;
  @Output() resultData: EventEmitter<any> = new EventEmitter();

  ShowMoreFilters = false;
  moreListArr: string[];
  captions: any = this.localization.captions;
  alphabets: any[] = this.captions.common.alphabets;
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 23,
    keyboard: {
      enabled: true,
    },
    mousewheel: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slideToClickedSlide: true,
    loop: false
  };
 
  assingSectionfrom: any;
  categoryType: any;
  selectedAlphabets: any[] = [];
  isHeightSet: boolean;
  alphabet: string = 'all';
  floatLabelNever: string;
  constructor(public localization: Localization, private utils: CommonUtilities) {
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnChanges() {
    if (this.filterOptions) {
      this.assingSectionfrom = this.sectionfrom ? this.sectionfrom : '';
      this.moreDataContentFirstCharArr = [];
      this.showMoreFilterSection = this.filterOptions.ShowMoreFilters;
      this.moreDataContent = this.filterOptions.moreData;
      this.moreOption = this.filterOptions.moreData.filter(x => x.id == 0);
      this.belongto = this.filterOptions.belongto;
      this.frompage = this.filterOptions.fromPage;      
      this.categoryType = this.filterOptions.categoryType;
      this.moreDataContent.forEach(x => {
        let dispName = x.description ? x.description : x.name;
        if(dispName){
        this.moreDataContentFirstCharArr.push(dispName.charAt(0).toUpperCase());}
      })
      this.selectedMoreDataContent = [...this.filterOptions.selectedMoreData];      
      this.intialDataArray = cloneDeep(this.filterOptions.selectedMoreData);
      this.isHeightSet = false;
      if (!this.isHeightSet) {
        const setHeightForFilterContent = setInterval(() => {
          this.setheightCalculated();
          clearInterval(setHeightForFilterContent);
        }, 1);
      }
      this.selectedAlphabets[0] = this.captions.common.all;
    }
  }

  setheightCalculated() {
    if (document.getElementById('MS_filterContent')) {
      clearInterval(this.getheight)
      let contentheight = document.getElementById('MS_filterContent').clientHeight;
      if (contentheight <= 123) {
        if(this.utils.isIpad()){
        document.getElementById('MS_filterContent')['style'].height = contentheight + 'px';}
        else{
          document.getElementById('MS_filterContent')['style'].height = contentheight - 17 + 'px';
        }
      } else {
        document.getElementById('MS_filterContent')['style'].height = '123px';
      }
      this.setPositions();
    }
  }
  setPositions() {
    if (document.getElementById('MoreSecID' + this.assingSectionfrom)) {
      let top = this.filterOptions.top;
      if (this.filterOptions.position == 'top') {
        let containerheight = document.getElementById('MoreSecID' + this.assingSectionfrom).offsetHeight;
        if (containerheight <= 245) {
          top= top + (245 - document.getElementById('MoreSecID' + this.assingSectionfrom).offsetHeight) ;
        } 
      }
      document.getElementById("MoreSecID" + this.assingSectionfrom)['style'].top = top + "px";
      document.getElementById("MoreSecID" + this.assingSectionfrom)['style'].left = this.filterOptions.left + "px";
      this.removeOpacity = true;
    }
    this.isHeightSet = true;
  }
  toggleClickbtn(id) {
    if (this.selectOne) {
      this.selectedMoreDataContent = [];
    }
    let ga = this.selectedMoreDataContent
    let gv = id;
    if (gv == 0) { /* For all button click */
      if (ga.indexOf(gv) == -1) {
        ga = [];
        this.moreDataContent.forEach(x => {
          ga.push(x.id);
        });
      } else {
        ga = [];
      }
    } else { /* For other than all button click */
      if (ga.indexOf(gv) == -1) {
        ga.push(gv);
        if ((this.moreDataContent.length - 1) == ga.length) {
          ga.push(0);
        }
      } else {
        ga.splice(ga.indexOf(gv), 1);

        if (ga.indexOf(0) != -1) {
          ga.splice(ga.indexOf(0), 1);
        }
      }
    }
    this.selectedMoreDataContent = ga;
    return;
  }
  SearchCategories(e, type, alphabet?: string) {
    this.alphabet = alphabet ? alphabet : this.alphabet;
    let searchvalue, newarr;
    
    if (type == 'search') {
      searchvalue = e.target.value ? e.target.value : '';
      newarr = this.filterOptions.moreData.filter(x => includes((x.description ? x.description : x.name).toLowerCase(), searchvalue.toLowerCase()));
      this.selectedAlphabets[0] = this.captions.common.all;
    }
    if (type == 'click') {
      searchvalue = e.target ? e.target.textContent : '';
      newarr = this.filterOptions.moreData.filter(x => startsWith((x.description ? x.description : x.name).toLowerCase(), searchvalue.toLowerCase()));
      this.ButtonToggle(this.selectedAlphabets, searchvalue);
    }
    this.moreDataContent =  newarr;
    return;
  }

  ButtonToggle(ga, gv) {
    ga.splice(ga.indexOf(gv), 1);
    if (ga.indexOf(gv) == -1 && gv !== '') {
      ga.push(gv);
    } else if (gv === '') {
      ga.push(this.captions.common.all);
    }
  }

  senddataOut() {
    this.resultData.emit([this.selectedMoreDataContent, this.belongto]);
    this.showMoreFilterSection = this.filterOptions.ShowMoreFilters = false;
    this.removeOpacity = false;
  }

  closeMoreWidow() {
    this.showMoreFilterSection = this.filterOptions.ShowMoreFilters = false;
    this.removeOpacity = false;
    if (this.intialDataArray.length > 0) {
      this.selectedMoreDataContent = [];
      this.selectedMoreDataContent = this.intialDataArray;
      this.resultData.emit([this.selectedMoreDataContent, this.belongto]);
    }
  }
}
