import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subscription, timer } from "rxjs";
import { scan, takeWhile } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AutologoffTimerService {
  subject = new ReplaySubject<number>(2);
  _subscription: Subscription;
  buttonDisabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

  TriggerTimer(releaseTime) {
    this._subscription = timer(0, 1000)
      .pipe(
        scan((acc) => --acc, releaseTime),
        takeWhile((x) => x >= 0)
      )
      .subscribe((x) => {
        this.subject.next(x);
        if (x == 0) this.buttonDisabled.next(true);
      });
  }

  getTimer() {
    return this.subject.asObservable();
  }

  unSubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  clearTimer() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this.subject = new ReplaySubject<number>(2);
    }
  }
}
