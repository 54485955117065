import { Injectable } from "@angular/core";
import { SorTypeEnum, HdrActionTypeEnum, ActionTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { HttpMethod } from "src/app/common/Models/http.model";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailRoutes } from "src/app/retail/retail-route";
import { BaseResponse, OrphanPayments } from "src/app/retail/retail.modals";
import { Host } from "src/app/retail/shared/globalsContant";
import { HttpServiceCall } from "src/app/retail/shared/service/http-call.service";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { RefundFailureSearchRequest } from "../retail-transactions-log/retail-transactions-log";
import { TransactionSummary } from 'src/app/retail/shared/service/payment/payment-business.model';
import {UIUnsetteledRefund} from './refund-log.model'
import * as _ from 'lodash';
import { PaymentMethodValue } from './../../../shared/globalsContant';

@Injectable()
export class RefundLogBusiness {
  captions: any;
  currencySymbol: string;

  constructor( private localization: RetailLocalization, 
               private utils: RetailUtilities, private http: HttpServiceCall) {
    this.captions = this.localization.captions.shop;
    this.currencySymbol = this.localization.currencySymbol;
  }

  public getTableOptions() {
    return {
        actions: [
        ],
        defaultsortingColoumnKey: 'ticketNumber',
        showTotalRecords: false,
        defaultSortOrder: SorTypeEnum.desc,
        columnFreeze: {
            firstColumn: false,
            lastColumn: true
        },
        isDragDisabled: false,
        isHeaderCheckboxAllowed: true,
        checkboxKey: 'checked',
        ignoreSort: true
    };
}

public getHeaderOptions() {
    return [
        {
            key: 'userName',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_user,
            sortingKey: 'userName',
            sorting: true
        },
        {
            key: 'createdDTM',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_transactionDate,
            sortingKey: 'createdDTM',
            sorting: true
        },
        {
            key: 'transactionType',
            displayNameId: '',
            displayName: this.captions.lbl_transactionType,
            sortingKey: 'transactionType',
            sorting: true
        },
                  
        {
            key: 'ticketNumber',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_ticketNumber,
            sortingKey: 'ticketNumber',
            sorting: true
        },
        {
            key: 'amountFormatted',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_failedAmount + ' ' + '(' + this.currencySymbol + ')',
            sortingKey: 'amount',
            sorting: true
        },                        
        {
            key: 'paymentMethod',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_payment_type,
            sortingKey: 'paymentMethod',
            sorting: true
        },
        {
          key: 'errorMessage',
          displayNameId: '',
          displayName: this.captions.tbl_hdr_errorMessage,
          sortingKey: 'errorMessage',
          sorting: true,
          templateName: ActionTypeEnum.custom
        },             
        {
          key: 'Action',
          displayNameId: '',
          displayName: this.captions.tbl_hdr_Action,
          sortingKey: 'Action',
          templateName: ActionTypeEnum.custom
        }
    ];
}

async getTabledata(model: RefundFailureSearchRequest): Promise<UIUnsetteledRefund[]> {
   var response = await this.SearchFailedRefund(model);
   var userInfo:any = await this.getClerkDetail(response.map(x => x.clerkId))
   return this.UiMapper(response,userInfo);
}

UiMapper(response: TransactionSummary[] , userInfo:any[]) : UIUnsetteledRefund[] {
  const data = response.flatMap(x => {
    // Filter transactionPayments based on paymentStatus is try refund
    const filteredPayments = x.transactionPayments.filter(payment => payment.paymentStatus === 2);

    return filteredPayments.map(payment => {
        return {
            id: payment.id,
            userName: userInfo.find(y => y.userId === x.clerkId)?.userName || '',
            transactionType: x.retailTransactionType,
            createdDTM: x.transactionDate,
            paymentMethod:payment.parentTenderId === 0 ? PaymentMethodValue[payment.paymentMethod] : PaymentMethodValue[payment.parentTenderId] ,
            Action: '',
            transactionId: x.id,
            amountFormatted:this.localization.localizeCurrency(Math.abs(payment.paymentAmount)),
            ticketNumber: x.retailTicketNumber,
            errorMessage: (!payment.errorMessage || payment.errorMessage.trim().length === 0 || payment.errorMessage.length > 50) ? "Refund Failure" : payment.errorMessage,
            errorMessageDetail:(!payment.errorMessage || payment.errorMessage.trim().length === 0) ? "Refund Failure" : payment.errorMessage 
        };
    });
});
return data;
}

  public async RefundFailedPayment(transId: Number) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    let response: any = await this.http.CallApiAsync<any>({
      host: Host.retailPOS,
      callDesc: RetailRoutes.RefundFailedPayment,
      uriParams: { transactionId: transId },
      method: HttpMethod.Put
    });
    this.utils.ToggleLoader(false);
    if (response) {
      if (response == false || response?.result == false) {
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, response?.errorDescription ? response.errorDescription : 'Refund Failed');
      }
    }
    return response;
  }

 
 public async SearchFailedRefund(model: RefundFailureSearchRequest) {
    let response: any = await this.http.CallApiAsync<TransactionSummary[]>({
      callDesc: RetailRoutes.SearchFailedRefund,
      host: Host.retailPOS,
      method: HttpMethod.Put,
      body: model
    });
    return response.result;
  }

public async getClerkDetail(clerkIds: number[]): Promise<any> {
    let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
        callDesc: "GetAllUsersbyUserIds",
        host: Host.authentication,
        method: HttpMethod.Get,
        uriParams: { tenantId: this.utils.GetPropertyInfo('TenantId') },
        withQueryString: "ids",
        queryString: { key: "ids", value: clerkIds }
    });

    if (!response.successStatus) {
        this.utils.showError("Invalid User");
    }
    return response.result;
}
}