

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ReportService {

  searchPath: string = "/assets/transaction-log.json";
  constructor(public http: HttpClient) { }

  getTransactionLog() {
    return this.http.get(this.searchPath);
  }


}
