import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CustomFormField } from '.';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';

export const IngredientTypeIdChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            console.log('Master Data Collection', MasterDataCollection);
            const PrimaryFormFields = MasterDataCollection.FormConfig.FormFields;
            const selectedTypeId = FormGroupData.value.TypeId;
            const TypeIdIndex = PrimaryFormFields.findIndex(x => x.fieldType.SingleData.name === 'TypeId');
            if (TypeIdIndex !== -1) {
                const TypeOptions = PrimaryFormFields[TypeIdIndex].fieldType.SingleData.options;
                const selectedTypeName = TypeOptions.filter(x => x.key === FormGroupData.value.TypeId)[0]?.label;
                if (selectedTypeName)  {
                    const MasterNestedFields = MasterDataCollection.NestedFormConfig.EditFormConfig[0]?.
                    NestedForm[0]?.FormFields;
                    const ProductTypeIndex = MasterNestedFields.findIndex(x => x.fieldType.SingleData.name === 'ProductType');
                    if (ProductTypeIndex !== -1) {
                        const ProductTypeOptions = MasterNestedFields[ProductTypeIndex].fieldType.SingleData.options;
                        const ProductId = ProductTypeOptions.filter(x => x.label === selectedTypeName)[0]?.key;
                        if (ProductId) {
                            FormGroupData.controls['ProductType'].setValue(ProductId);
                            httpService.GethttpMethod(`/inventory/api/ProductTypes?$filter=Id eq ${ProductId}`).subscribe(Res => {
                                const ResultSet = Res['value'][0];
                                FormGroupData.controls['Markup'].setValue(ResultSet.MarkupPercentage);
                                if (FormGroupData.controls['BaseCurrentCost'].value) {
                                    const ResultPrice = parseFloat(FormGroupData.controls['BaseCurrentCost'].value) +
                                        (parseFloat(FormGroupData.controls['BaseCurrentCost'].value) / 100) * ResultSet.MarkupPercentage;
                                    FormGroupData.controls['ProductPrice'].setValue(ResultPrice);
                                    CommonService.triggerBlurEvent('ProductPrice');
                                }
                            });
                        }  else {
                            FormGroupData.controls['ProductType'].setValue(null);
                            FormGroupData.controls['ProductType'].markAsTouched();
                        }
                    }
                }
            }
            if (selectedTypeId) {
                const customOdataUrl = `/inventory/api/IngredientSubTypes?$filter=IsActive eq true and InventoryTypeId eq ${selectedTypeId}&$select=Id,Name&$orderby=Name ASC`;
                httpService.GethttpMethod(customOdataUrl).subscribe(res => {
                    const subTypeIndex = PrimaryFormFields.findIndex(f => f.fieldType.SingleData.name === 'SubTypeId');
                    if (subTypeIndex > -1 && res['value']?.length > 0) {
                        let mapDropDownData = [];
                        res['value'].map(e => {
                            const radioOptions = {} as RadioOptions;
                            radioOptions.key = e['Id'];
                            radioOptions.label = e['Name'];
                            mapDropDownData.push(radioOptions);
                        });
                        MasterDataCollection.FormConfig.FormFields[subTypeIndex].fieldType.SingleData.options = mapDropDownData;
                    }                    
                });
            }
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

