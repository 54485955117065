import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridInterface } from '.';
import { CustomFilterNavigationUrl, GridFilterAction, GridFilterData, GridFilterValue, MasterInventoryManagement } from '../master.interface';
import { GridModelFilterConfiguration } from './grid-config.interface';

export const GridFilter: GridModelFilterConfiguration = (ViewModelEntity: any, 
MasterDataCollection: MasterInventoryManagement, httpService: HttpService ): any => {
    const GridFilterRowData = [];
    try {
        if (ViewModelEntity.length > 0  && MasterDataCollection && 
            MasterDataCollection.GridConfig &&  MasterDataCollection.GridConfig.GridFilter) {
            MasterDataCollection.GridConfig.GridFilter.forEach((GridFilterDataObj: GridFilterAction) => {
                const navigationProp = {} as CustomFilterNavigationUrl;
                const Navigation = ViewModelEntity[0]['NavigationProperty']
                .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] ===  GridFilterDataObj.FilterOdataKey);
                if (Navigation.length > 0) {
                    ViewModelEntity[0]['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
                        const NavigationProperityName = NavigationProperty['$']['Name'];
                        if (NavigationProperityName === GridFilterDataObj.FilterOdataKey) {
                            let NavigationReferentialConstraint  = '';
                            if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                                NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                            }
                            const EntityNameList = NavigationProperty['$']['Type'].split('.');
                            let EntityName = '';
                            if (EntityNameList.length > 0) {
                                EntityName = EntityNameList[EntityNameList.length - 1];
                                if (GridFilterDataObj.CustomQuery) {
                                    navigationProp.RequestUrl = `/inventory/api/${EntityName}${GridFilterDataObj.CustomQuery}`;
                                } else {
                                    navigationProp.RequestUrl = `/inventory/api/${EntityName}`;
                                }
                                navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                                navigationProp.EnabledCustomField = false;
                            }
                        }
                    });
                } else {
                    if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('CustomFilters') &&
                        GridFilterDataObj.CustomFilters.EnableCustomFilter) {
                        navigationProp.RequestUrl = GridFilterDataObj.CustomFilters.CustomNavigationUrl;
                        navigationProp.EnabledCustomField = true;
                        navigationProp.NavigationContraintName = GridFilterDataObj.FilterOdataKey;
                    } else if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('CustomOptions')) {
                        const filterDisplay = {} as GridFilterData;
                        filterDisplay.FilterKey = GridFilterDataObj.FilterDisplayName;
                        filterDisplay.FilterValue = GridFilterDataObj.CustomOptions;
                        filterDisplay.FilterOriginalKey = GridFilterDataObj.FilterOdataKey;
                        filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                        filterDisplay.MappingKeyForNonOdataCore = GridFilterDataObj.MappingKeyForNonOdataCore;
                        filterDisplay.Type = 'multiselect';
                        navigationProp.RequestUrl = null;
                        GridFilterRowData.push(filterDisplay);
                    }
                }
                if ( navigationProp && navigationProp.RequestUrl !== null) {
                    // httpService.GethttpMethod(navigationProp.RequestUrl).subscribe((response: any) => {
                    // if (response && response.value && response.value.length > 0) {
                    // const filterKeyData = response.value.map((data: any) => {
                    // const filterKey = {} as GridFilterValue;
                    // filterKey.FilterId = GridInterface.GridFilterKeyMapping(GridFilterDataObj, data);
                    // filterKey.FilterName = GridInterface.GridFilterLabelMapping(GridFilterDataObj, data);
                    // filterKey.Checked = false;
                    // return filterKey;
                    // });
                    const filterDisplay = {} as GridFilterData;
                    filterDisplay.FilterKey = GridFilterDataObj.FilterDisplayName;
                    filterDisplay.FilterValue = [];
                    filterDisplay.FilterOriginalKey = GridFilterDataObj.FilterOdataKey;
                    filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                    filterDisplay.Type = 'multiselect';
                    filterDisplay.FilterUrl = navigationProp.RequestUrl;
                    filterDisplay.CustomMapping = GridFilterDataObj.CustomMapping;
                    filterDisplay.SortOrder = GridFilterDataObj.SortOrder;
                    filterDisplay.MappingKeyForNonOdataCore = GridFilterDataObj.MappingKeyForNonOdataCore;
                    GridFilterRowData.push(filterDisplay);
                    // }
                    // },
                    // ( responseError ) => {
                    // console.error('error caught in component', responseError);
                    // });
                }
            });
        } 
        return GridFilterRowData;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};
