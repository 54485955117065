import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { Host } from '../../shared/globalsContant';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import moment from 'moment';

@Injectable()
export class ServiceYieldingService {

    selectedServiceId: any;
    FirstServiceId: any;
    isTabsViewable = true;
    editYield = false;
    copyYield=false;
    currentYield: any = [];
    CurrentData: any;
    newYieldSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private http: HttpServiceCall, private utils:SpaUtilities) {

    }


    ChangeNewYield(value: boolean) {
        this.newYieldSubject.next(value);
    }

    // Generate Week Array starts
    WeekArray(strt, end, allDayCaption: string) {
        const aweek = [];
        let row2, nxtday;
        const noOfDayDisp = end.diff(strt, 'days');
        aweek.push(allDayCaption);
        for (let i = 0; i <= noOfDayDisp; i++) {
            if (i === 0) {
                row2 = strt.format('ddd');
                if (aweek.indexOf(row2) === -1) { aweek.push(row2); }
            } else {
                nxtday = strt.add(1, 'day');
                row2 = nxtday.format('ddd');
                if (aweek.indexOf(row2) === -1) { aweek.push(row2); }
            }
        }
        return aweek;
    }

    formCalendarGrid(givenDate, selectedMonth,date,type) {
        const startWeek = givenDate.clone().startOf('month').week();
        let endWeek = givenDate.clone().endOf('month').week();
       
        endWeek = endWeek < startWeek ? endWeek + 52 : endWeek;
        const weekArr = [];
        for (let week = startWeek; week <= endWeek; week++) {
            weekArr.push(
                Array(7).fill(0).map((n, i) => {
                    let dateObj;
                    if(type == 'add'){
                        dateObj = moment(date, "YYYYMMDD").add(1, 'M').week(week).startOf('week').clone().add(n + i, 'day');
                    } else if(type == 'subtract'){
                        dateObj = moment(date, "YYYYMMDD").subtract(1, 'M').week(week).startOf('week').clone().add(n + i, 'day');
                    } else {
                        dateObj = moment(date, "YYYYMMDD").week(week).startOf('week').clone().add(n + i, 'day');
                    }
                    return {
                        'row1': dateObj.format("D MM"), 'row2': dateObj.format("dddd"),
                        year: dateObj.format("YYYY"), dateValue: dateObj,
                        isCurrentMonth: dateObj.format("MM") === selectedMonth
                    };
                })
            );
        }
        return weekArr;
    }

    GetDates(dates: any, tmp: any, startDate: any, endDate: any): any {
        let tempDate: Date = this.utils.getDate(tmp);

        if (tempDate.getTime() >= startDate.getTime() && tempDate.getTime() <= endDate.getTime()) {
            dates.push(tempDate);
        }
        while (tempDate.getTime() <= endDate.getTime()) {
            tmp.add(7, 'days');
            tempDate = this.utils.getDate(tmp);
            if (tempDate.getTime() <= endDate.getTime()) {
                dates.push(tempDate);
            }

        }
        return dates;
    }

    LoadExistingYieldData(CurrentData, successCallback, errorCallback) {
        this.http.CallApiWithCallback({
            host: Host.spaManagement,
            success: successCallback,
            error: errorCallback,
            callDesc: 'GetDateYield',
            method: HttpMethod.Get,
            uriParams: { date: CurrentData.yieldDate, serviceId: CurrentData.serviceId },
            showError: true,
            extraParams: []
        });
    }

    LoadServices(successCallback, errorCallback) {
        this.http.CallApiWithCallback({
            host: Host.spaManagement,
            success: successCallback,
            error: errorCallback,
            callDesc: 'GetAllSpaService',
            method: HttpMethod.Get,
            showError: true,
            extraParams: []
        });
    }

    BindGridData(selectedMonth, selectedYear, serviceArr, successCallback, errorCallback) {
        this.http.CallApiWithCallback({
            host: Host.spaManagement,
            success: successCallback,
            error: errorCallback,
            callDesc: 'GetMonthlyYield',
            method: HttpMethod.Get,
            uriParams: { month: selectedMonth, serviceId: serviceArr && serviceArr.length > 0 ? serviceArr[0].id : 0, year: selectedYear},
            showError: true,
            extraParams: []
        });
    }

    GetAllServices(successCallback, errorCallback) {
        this.http.CallApiWithCallback({
            host: Host.spaManagement,
            success: successCallback,
            error: errorCallback,
            callDesc: 'GetAllSpaService',
            method: HttpMethod.Get,
            showError: true,
            extraParams: []
        });
    }

    createServiceYield(requestBody, selectedServices,isOverwiteExisting, successCallback, errorCallback) {
        this.http.CallApiWithCallback({
            host: Host.spaManagement,
            success: successCallback,
            error: errorCallback,
            callDesc: 'CreateServiceYield',
            method: HttpMethod.Post,
            body: requestBody,
            uriParams: {isOverwiteExisting:isOverwiteExisting} ,
            showError: false,
            extraParams: [{ selectedServices: selectedServices }]
        });
    }
}
