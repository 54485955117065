import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { ConfirmPopModel, ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import { PoBackOrderOptions } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import _ from 'lodash';
import { AgilysysPartialTransferComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-partial-transfer/agilysys-partial-transfer.component';


export const PoBackOrder: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType ?: any,
    ModuleKey ?: string,
    dialog ?: MatDialog,
    transactionCollection ?: TransactionConfiguration,
    homeGroup ?: FormGroup
): any => {
        return new Promise<boolean>((resolve, reject) => {
            const POBackOrderOptions = false;
            const poExistingData = _.cloneDeep(transactionCollection.CreateTranscationConfig.
                ItemsConfig.ItemList.ItemListExistingData);
            if ( GlobalPreference && GlobalPreference.length > 0 && homeGroup &&
                     homeGroup.value['PONumber'] && poExistingData && poExistingData['PurchaseOrderDetails']) {
               let shopopup = false;
    
               const backOrderGlobalPreference = GlobalPreference.filter(x => x.LookupName === PrferenceType);
               if ( backOrderGlobalPreference && backOrderGlobalPreference.length > 0 ) {
                const poBackOrder =  backOrderGlobalPreference[0]; 
    
                if (poBackOrder.LookupValue !== PoBackOrderOptions.NoBackOrderbyType) {
                    
                    const poCurrentData = transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
                    if (poExistingData) {
                        poCurrentData.forEach(element => {
                            const originalData = poExistingData['PurchaseOrderDetails']?.filter(x => x.Id === element.PODetailId)[0];
                            if (originalData) {
                                const typeBackOrderOptions = originalData.PODetailIngredientId.IngredientTypes ?
                                    originalData.PODetailIngredientId.IngredientTypes.AllowBackOrder : false;
                                const currentQty = (element.Qty > 0 && element.Conversion  > 0) ?
                                 (element.Qty * element.Conversion ) + (element.ReceivedQty ? element.ReceviedQty : 0) : 0;
                                const originalQty = originalData.Qty * originalData.Conversion;
                                if (currentQty < originalQty) {
                                    if (poBackOrder.LookupValue === PoBackOrderOptions.BackOrderbyItem ||
                                            (poBackOrder.LookupValue === PoBackOrderOptions.AllowBackOrderbyType && typeBackOrderOptions)) {
                                        shopopup = true;
                                    }
                                }
                            }
                        });
    
                        const removedItems = poExistingData['PurchaseOrderDetails']?.filter(x =>
                            poCurrentData.every(y => y.PODetailId !== x.Id));
                        if (removedItems?.length > 0) {
                            shopopup = true;
                        }
                    }
                }
    
                if (shopopup) {
                    const ConfirmPopUpDialog: ConfirmPopModel = {
                        PopupHeaderContent: 'Partial Receiving',
                        PopUpContent: 'Item(s) received partially. Do you wish to back order?',
                      };
                    const dialogRef = dialog.open(AgilysysPartialTransferComponent, {
                        width: '400px',
                        height: '222px',
                        data: ConfirmPopUpDialog,
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result === 'Confirm') {
                            homeGroup.controls['AllowBackOrder'].setValue(true);
                            resolve(true);
                        } else if (result === 'Cancel') {
                            homeGroup.controls['AllowBackOrder'].setValue(false);
                            resolve(true);
                        }
                    });                
                } else {
                    resolve(true);
                }
               }
            } else {
                resolve(true);
            }
        });
};