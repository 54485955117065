import { Injectable } from '@angular/core';
import { RetailUtilities } from './retail-utilities';

@Injectable()
export class ReadUploadedFile {

    constructor(private _utils: RetailUtilities) {

    }

    /**
     * Reads an uploaded file and returns as array of string
     * @param event Read
     */
    async ReadFile(event: Event): Promise<string[]> {
        var lines: string[] = [];
        let elem: HTMLInputElement = <HTMLInputElement>event.srcElement;
        let files: FileList = elem.files;
        var prm =  new Promise(resolve => {
            let reader = new FileReader();
            reader.onload = (res) => resolve((<any>res.target).result);
            reader.readAsText(files[0]);
        });
        var result = <string>await prm;
        lines = [].concat.apply([], result.toString().split(/\r?\n/));
        return lines;
    }

    async ReadCsvFile<T>(event: Event): Promise<T[]> {
        let elem: HTMLInputElement = <HTMLInputElement>event.srcElement;
        let file: File = elem.files[0];
        var result: T[] = []
        if (this.isValidCSVFile(file)) {
            var lines = await this.ReadFile(event);
            if (lines.length > 0) {
                var header = lines[0].split(",");
                for (let i = 1; i < lines.length; i++) {
                    const line: string = lines[i];
                    if (!line || line.trim().length == 0 || ! /\w/.test(line)) {
                        continue;
                    }
                    let data: string[] = [];
                    let concatedtring=''

                    line.split(',').forEach((val,index)=>
                    {
                        if(val.slice(-1)=='"' || val.charAt(0)=='"' || concatedtring.trim().length>0 )
                        {
                            concatedtring=concatedtring+val;
                            
                            if(isNaN(Number(val)) && val.slice(-1)!='"')
                                concatedtring=concatedtring+',';

                            if(val.slice(-1)=='"')
                            {
                                data.push(concatedtring);
                                concatedtring='';
                            }
                        }
                        else {
                        data.push(val);
                        }
                    });
                    let row = <T>{};
                    for (let j = 0; j < header.length; j++) {
                        row[header[j]] =  data[j] && data[j].trim();
                    }
                    result.push(row);
                }
            }
        }
        else {
            this._utils.showError("Not a valid CSV file");
        }
        return <T[]>result;
    }

    async ReadCsvFileUpperCase<T>(event: Event): Promise<T[]> {
        let elem: HTMLInputElement = <HTMLInputElement>event.srcElement;
        let file: File = elem.files[0];
        var result: T[] = []
        if (this.isValidCSVFile(file)) {
            var lines = await this.ReadFile(event);
            if (lines.length > 0) {
                var header = lines[0].split(",");
                for (let i = 1; i < lines.length; i++) {
                    const line: string = lines[i];
                    if (!line || line.trim().length == 0 || ! /\w/.test(line)) {
                        continue;
                    }
                    let data: string[] = [];
                    let concatedtring=''

                    line.split(',').forEach((val,index)=>
                    {
                        if(val.slice(-1)=='"' || val.charAt(0)=='"' || concatedtring.trim().length>0 )
                        {
                            concatedtring=concatedtring+val;
                            
                            if(isNaN(Number(val)) && val.slice(-1)!='"')
                                concatedtring=concatedtring+',';

                            if(val.slice(-1)=='"')
                            {
                                data.push(concatedtring);
                                concatedtring='';
                            }
                        }
                        else {
                        data.push(val);
                        }
                    });
                    let row = <T>{};
                    for (let j = 0; j < header.length; j++) {
                        row[header[j].toUpperCase()] =  data[j] && data[j].trim();
                    }
                    result.push(row);
                }
            }
        }
        else {
            this._utils.showError("Not a valid CSV file");
        }
        return <T[]>result;
    }

    isValidCSVFile(file: File): boolean {
        return file.name.endsWith(".csv");
    }

}