import { Injectable } from '@angular/core';
import { AuthPayload, IntegrationConfiguration } from 'src/app/eatecui/source/shared/integration/integration-interface';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { HttpService } from './http.service';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { DataMagineIntegeration } from 'src/app/eatecui/source/shared/integration/datamagine-integration';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { MatDialog } from '@angular/material/dialog';
import { MasterInventoryManagement, SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { VendorPunchoutIntegeration } from 'src/app/eatecui/source/shared/integration/vendor-punchout-integration';
import { StorageService } from './storage.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
@Injectable({
    providedIn: 'root'
  })
export class IntegrationService {
    moduleKey: string;
    configService: ConfigService;
    dmIntergerationInstance: any;
    IntegrationConfig: IntegrationConfiguration;
    dmIntegration: any;
    constructor(private httpService: HttpService, private toastrService: ToastrService, 
      private httpClient: HttpClient, private envService: EnvService, private dialog: MatDialog, private configureService:  ConfigService) {       
    }
    /**
       * @method getIntegrationInstance
       * @param moduleKey; 
       */
    getDMIntegrationInstance(moduleKey: string): void {
      try {
          if (this.IntegrationConfig && this.IntegrationConfig.EnabledIntegration && this.IntegrationConfig.IntegrationCollection) {
              const dmIntegCollect = this.IntegrationConfig.IntegrationCollection.filter(x => x.IntegratonName === 'DataMagine')[0];
              const authorizeData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
              const setupSubModules = authorizeData.filter(x => x.Name === 'setupmanagement')[0].SubModules;
              const setupPermissionResources = setupSubModules.filter(m => m.Name === 'setup')[0].PermissionResource;
              const setupDMMappingsEnabled = setupPermissionResources.filter(r => r.Name === 'DMMappings')[0].IsActive;
              if (dmIntegCollect.IsEnabled && setupDMMappingsEnabled) {
                  const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
                  'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
                  const IntegrationIndex: number = AllowedIntegration.findIndex(x => x === moduleKey);
                  if (IntegrationIndex > -1) {
                    /* Commenting below code block due to there is no need for taking DM Token. Its taken from API. */ 
                      // const authPayload = {} as AuthPayload;
                      // authPayload.ClientUrl = CONSTANT.ENDPOINTURL['DMIntegration']['GetLoginToken'];
                      // authPayload.Payload = { moduleKey: 'DataMagine' };

                      const authMasterPayload = {} as AuthPayload;
                      authMasterPayload.ClientUrl = CONSTANT.ENDPOINTURL['DMIntegration']['GetMasterData'];
                      authMasterPayload.Payload = null;
                      
                    /* Commenting below code block due to there is no need for taking mappings. */ 
                      // const authConfigPayload = {} as AuthPayload;
                      // authConfigPayload.ClientUrl = CONSTANT.ENDPOINTURL['DMIntegration']['GetConfigData'];
                      // authConfigPayload.Payload = { moduleKey: 'DataMagine' };

                      this.dmIntegration = new DataMagineIntegeration(this.httpClient, this.httpService, 
                        this.toastrService, this.dialog);
                      this.dmIntegration.setIntegrationConfiguration();
                      // this.dmIntegration.DataMagineAuthLogin(this.httpService, authPayload);
                      this.dmIntegration.GetDatamagineMasterData(this.httpService, authMasterPayload);
                      // this.dmIntegration.GetDataMagineConfigData(this.httpService, authConfigPayload);
                      this.dmIntergerationInstance = this.dmIntegration;
                  }
              }
          }
      } catch (error) {
        console.error(error);
      }
    }

    /**
     * @method vendorPermission
     * @Input ToastService
     * @Description enabled vendor checkbox based on checkbox
     */
    vendorPermission(MasterJsonData: MasterInventoryManagement): void {
      try{
        const vendorConfig = new VendorPunchoutIntegeration(this.toastrService, this.configureService, this.httpService);
        vendorConfig.vendorPermission(MasterJsonData)
      } catch(error){
        console.error(error);
      }
    }

    /**
     * @method vendorPermission
     * @Input ToastService
     * @Description enabled vendor checkbox based on checkbox
     */
    vendorTransPermission(transaction: TransactionConfiguration, permissionKey: string): void {
      try{
        const vendorConfig = new VendorPunchoutIntegeration(this.toastrService, this.configureService, this.httpService);
        vendorConfig.vendorTransPermission(transaction, permissionKey)
      } catch(error){
        console.error(error);
      }
    }


    vendorLandingTabPermission(masterJsonData: MasterInventoryManagement, PunchoutEnabledInTenant: boolean): any {
      try {
        const vendorConfig = new VendorPunchoutIntegeration(this.toastrService, this.configureService, this.httpService);
        return vendorConfig.LandingTabPermissionConfig(masterJsonData, PunchoutEnabledInTenant);
      } catch (error) {
        console.error(error);
      }
    }

     /**
     * @method vendorPermission
     * @Input ToastService
     * @Description enabled vendor checkbox based on checkbox
     */
     vendorSupplierConfig(MasterSubFormConfig: Array<SubFormConfigs>, ResponseData: Array<any>, PunchoutEnabledInTenant: boolean): void {
      try{
        const vendorConfig = new VendorPunchoutIntegeration(this.toastrService, this.configureService, this.httpService);
        const vendorIsPunchoutEnabled = (ResponseData[0].IsPunchoutEnabled && PunchoutEnabledInTenant);
        vendorConfig.TabPermissionConfig(MasterSubFormConfig, vendorIsPunchoutEnabled);
      } catch(error){
        console.error(error);
      }
    }
    /**
     * @method constructPunchoutFields
     */
    constructPunchoutFields(MasterSubFormConfig: Array<SubFormConfigs>, ResponseData: Array<any>, IsAgilysysEmployee ?: boolean): Array<SubFormConfigs> {
      try {
        const vendorConfig = new VendorPunchoutIntegeration(this.toastrService, this.configureService, this.httpService);
        const masterSubFormConfig = vendorConfig.punchoutFieldsConstruction(MasterSubFormConfig, ResponseData, IsAgilysysEmployee);
        return masterSubFormConfig;
      } catch (error) {
        console.error(error);
      }
    }
}