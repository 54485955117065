import { Injectable } from '@angular/core';
import { LocationAvailability, ScheduleTime, TherapistAvailability, TherapistMaxServiceDetail, Location, Therapist, ServiceViewModel, ServiceEquipmentMaintenance, EquipmentQuantity, EquipmentQuantityDetails, SlotResourceFilter } from 'src/app/shared/business/shared.modals';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { SlotSelectionValidationResponse, TimeIntervals, WizardTimeSlots } from '../spa-wizard/spa-wizard.modal';
import { SpaWizardService } from '../spa-wizard/spa-wizard.service';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { cloneDeep } from 'lodash'; // STORAGE THE BACK ARRAY
import { ServiceClientDetailsComponent } from 'src/app/shared/common-functionalities/appointment-actions/service-and-client-details/service-and-client-details.component';
import { ServiceParams } from 'src/app/common/Models/http.model';
import { durationValidator } from 'src/app/ag-common/components/ag-duration/ag-duration.validator';
@Injectable()
export class WizardSlotBusiness {
    wizardCaption: any;
    therapistAvailability: TherapistAvailability;
    constructor(private localization: SpaLocalization,
        private utils: SpaUtilities, private wizardService: SpaWizardService, private PropertyInfo: SpaPropertyInformation) {
        this.wizardCaption = this.localization.captions.bookAppointment.spawizard;
        let config = this.PropertyInfo.AppointmentConfigurations();
        if (config) {
            this.wizardService.slotInterval = config.APPOINMENT_GRIDTIMEINTERVAL;
        }
    }

    async buildSlotData(bookingDate: Date, startTime, endTime, service: ServiceViewModel, filterData: SlotResourceFilter = null) {
        const slots: TimeIntervals[] = [];
        const timeInterval = this.wizardService.slotInterval;
        const ignoreSetupBreakdownTime = filterData && filterData.ignoreSetupBreakdownTime;
        const allSlots = this.localization.generateTimeRange(this.localization.ToDate(startTime, "HH:mm"), this.localization.ToDate(endTime, "HH:mm"), timeInterval);
        if (!allSlots || allSlots.length == 0) {
            return slots;
        }
        if(service!=null)
        {
        let totalminutes = service.breakDownTime + service.setupTime + service.duration;
        for (let slot of allSlots) {
            if ((slot == allSlots[allSlots.length - 1]) && (!ignoreSetupBreakdownTime)) {
                
                if (totalminutes > timeInterval) {
                    let lastslot = this.utils.getDate(this.utils.formatDate(bookingDate) + 'T' + this.localization.DeLocalizeTime(slot));
                    lastslot.setMinutes(lastslot.getMinutes()-totalminutes);
                    let previousslot=slots[slots.length-1];
                    while (previousslot.dateTime>lastslot)
                    {
                        slots.pop();
                        previousslot=slots[slots.length-1];
                    }

                    return slots;
                }
            }
            else if (slot == allSlots[allSlots.length - 1]) {
                if (service.duration > timeInterval) {
                    let lastslot = this.utils.getDate(this.utils.formatDate(bookingDate) + 'T' + this.localization.DeLocalizeTime(slot));
                    lastslot.setMinutes(lastslot.getMinutes()-service.duration);
                    let previousslot=slots[slots.length-1];
                    while (previousslot.dateTime>lastslot)
                    {
                       slots.pop();
                       previousslot=slots[slots.length-1];
                    }
                    return slots;
                }    
            }           
            slots.push({ localizedTime: slot, dateTime: this.utils.getDate(this.utils.formatDate(bookingDate) + 'T' + this.localization.DeLocalizeTime(slot)) });
        }
    }
            else
            {
             for (let slot of allSlots) {
            slots.push({ localizedTime: slot, dateTime: this.utils.getDate(this.utils.formatDate(bookingDate) + 'T' + this.localization.DeLocalizeTime(slot)) });
            }
            }
        return slots;
    }

    async buildWizardSlots(bookingDate: Date, service: ServiceViewModel, intervals: TimeIntervals[], appointmentId = 0, tempIds: number[] = [], filterData: SlotResourceFilter = null) {

        const slots: WizardTimeSlots[] = [];
        try {
            const therapistId = filterData && filterData.therapistIds && filterData.therapistIds[0];
            const locationId = filterData && filterData.locationIds && filterData.locationIds[0];
            const ignoreSetupBreakdownTime = filterData && filterData.ignoreSetupBreakdownTime;
            let isOffSiteLocation = service.isOffsite;
            let excludeAppointmentIds: number[] = [];
            if (appointmentId > 0) {
                excludeAppointmentIds.push(appointmentId);
            }
            if (tempIds && tempIds.length > 0) {
                excludeAppointmentIds.push(...tempIds);
            }
            let apiDate = this.localization.convertDateObjToAPIdate(bookingDate);
            let [therapistAvailability, locationAvailability, therapistMaxService, equipmentMaintenance, appointmentDetails, serviceEquipments] = await Promise.all([
                this.wizardService.GetTherapistAvailability(apiDate, service.id, false, appointmentId, tempIds),
                this.wizardService.GetLocationAvailability(apiDate, service.id, excludeAppointmentIds),
                this.wizardService.GetTherapistServiceCount(apiDate, service.id),
                this.wizardService.GetServiceEquipmentMaintenance(service.id),
                this.wizardService.getAppointmentByDate(apiDate),
                this.wizardService.getEquipmentQuantity()]);
            this.therapistAvailability = cloneDeep(therapistAvailability);
            if (locationId > 0 && locationAvailability && locationAvailability.length > 0) {
                locationAvailability = locationAvailability.filter(r => r.location.id == locationId);
            }
            if (therapistId > 0 && therapistAvailability && therapistAvailability.therapistDetails.length > 0) {
                therapistAvailability.therapistDetails = therapistAvailability.therapistDetails.filter(r => r.therapist.id == therapistId);
            }
            let actualBookableSlots = 0;

            let appt = appointmentDetails.filter(f => f.appointmentDetail.status !== 'CANC'
                && f.appointmentDetail.status !== 'CKOUT'
                && f.appointmentDetail.status !== 'NOSHOW'
                && f.appointmentDetail.status !== 'BREAK');

            if (excludeAppointmentIds.length > 0) {
                appt = appt.filter(a => !excludeAppointmentIds.some(id => id == a.appointmentDetail.id));
            }

            for (let i = 0; i < intervals.length; i++) {
                let maleStaff = 0;
                let femaleStaff = 0;
                let isEquipmentAvailable = true;
                const availablityEquipments = this.GetEquipmentAvailablityForSlot(intervals[i].dateTime, appt, service, serviceEquipments);
                const availableLocations = this.GetLocationAvailablityForSlot(intervals[i].dateTime, locationAvailability, service, ignoreSetupBreakdownTime, therapistMaxService);
                const availableTherapists = this.GetTherapistAvailabilityForSlot(intervals[i].dateTime, therapistAvailability, availableLocations, therapistMaxService, service, ignoreSetupBreakdownTime);
                if (availableTherapists && availableTherapists.length > 0 && (availableLocations && availableLocations.length > 0) || isOffSiteLocation) {
                    maleStaff = availableTherapists.filter(r => r.gender == 'Male').length;
                    femaleStaff = availableTherapists.filter(r => r.gender == 'Female').length;
                }
                actualBookableSlots = maleStaff + femaleStaff;
                if (!isOffSiteLocation && availableLocations.length < actualBookableSlots) {
                    actualBookableSlots = availableLocations.length;
                }
                let equipmentSlots = availablityEquipments.map(m => m.availableSlots);
                if (equipmentSlots.length > 0) {
                    let equipmentAvailableSlots = equipmentSlots.reduce((a, b) => Math.min(a, b));
                    if (actualBookableSlots > equipmentAvailableSlots) {
                        actualBookableSlots = equipmentAvailableSlots;
                        isEquipmentAvailable = equipmentAvailableSlots > 0;
                    }
                }

                if (actualBookableSlots == 0) {
                    continue;
                }
                if ((maleStaff + femaleStaff) < service.minimumStaff) { //Service requires minimum staffs in a slot
                    continue;
                }
                // check if selected service's equipments are under maintenance for a slot
                if (this.isEquipmentUnderMaintenance(intervals[i].dateTime, equipmentMaintenance, service, ignoreSetupBreakdownTime)) {
                    continue;
                }
                slots.push({
                    id: i,
                    localizedTime: intervals[i].localizedTime,
                    dateTime: intervals[i].dateTime,
                    availableLocations: availableLocations,
                    availableStaff: availableTherapists,
                    availableSlots: actualBookableSlots,
                    maleStaffs: maleStaff,
                    femaleStaffs: femaleStaff,
                    isEquipmentAvailable: isEquipmentAvailable
                });
            }
        }
        catch (e) {
            console.log(e);
        }
        return slots;
    }


    GetAvailableEqupmentQuantity(serviceId: number, equipmentCount: EquipmentQuantityDetails, serviceIds: number[]): EquipmentQuantity[] {
        let resultArray: EquipmentQuantity[] = [];
        let selectedServiceEquipment = equipmentCount.serviceEquipments.filter(f => f.serviceId == serviceId);
        let serviceequipments = equipmentCount.serviceEquipments.filter(f => serviceIds.includes(f.serviceId));
        if (serviceequipments != null) {
            let equipmentIds: any[] = [];
            for (let item of selectedServiceEquipment) {
                equipmentIds.push(item.equipmentId);
            }
            let equipmentList = equipmentCount.equipments.filter(f => equipmentIds.includes(f.id));

            for (let equip of equipmentList) {
                let usedEquipment = serviceequipments.filter(f => f.equipmentId == equip.id);
                let usedQuantity = usedEquipment.reduce((accu, item) => {
                    return accu + item.quantity;
                }, 0);
                let availableQuantity = equip.quantity - usedQuantity;
                let Quantity = selectedServiceEquipment.find(f => f.serviceId == serviceId && f.equipmentId == equip.id).quantity;
                let availableSlot = Math.floor(availableQuantity / Quantity);
                resultArray.push({
                    equipmentId: equip.id,
                    usedQuantity: usedQuantity,
                    availableQuantity: availableQuantity,
                    availableSlots: availableSlot <= 0 ? 0 : availableSlot
                });
            }
        }
        return resultArray;
    }

    GetEquipmentAvailablityForSlot(slotTime: Date, apptDetails: any, service: ServiceViewModel, serviceEquipments: EquipmentQuantityDetails): EquipmentQuantity[] {
        let resultArray: EquipmentQuantity[] = [];
        if (apptDetails.length > 0) {
            let startTime = this.utils.getDate(this.utils.AddMinutes(slotTime, -service.setupTime));
            let endTime = this.utils.getDate(this.utils.AddMinutes(slotTime, (service.duration + service.breakDownTime)));
            let filteredAppt = apptDetails.filter(f =>
                (this.utils.AddMinutes(this.utils.getDate(f.appointmentDetail.startTime), -f.appointmentDetail.setUpTime).getTime() >= startTime.getTime()
                    && this.utils.AddMinutes(this.utils.getDate(f.appointmentDetail.startTime), -f.appointmentDetail.setUpTime).getTime() < endTime.getTime()) ||
                (this.utils.AddMinutes(this.utils.getDate(f.appointmentDetail.endTime), f.appointmentDetail.breakDownTime).getTime() > startTime.getTime()
                    && this.utils.AddMinutes(this.utils.getDate(f.appointmentDetail.endTime), f.appointmentDetail.breakDownTime).getTime() <= endTime.getTime()) ||
                (this.utils.AddMinutes(this.utils.getDate(f.appointmentDetail.startTime), -f.appointmentDetail.setUpTime).getTime() < startTime.getTime()
                    && this.utils.AddMinutes(this.utils.getDate(f.appointmentDetail.endTime), f.appointmentDetail.breakDownTime).getTime() > endTime.getTime()));
            let serviceIds: any[] = [];
            for (let item of filteredAppt) {
                if (item.appointmentDetail.multiGroupId === null) {
                    serviceIds.push(item.appointmentDetail.serviceId)
                }
            }
            if (filteredAppt.length > 0) {
                let multiGroupAppt = filteredAppt.filter(f => f.appointmentDetail.multiGroupId !== null);
                let multiGroupIds: any[] = [];
                for (let id of multiGroupAppt) {
                    multiGroupIds.push(id.appointmentDetail.multiGroupId);
                }
                multiGroupIds = [...new Set(multiGroupIds)];
                for (let mId of multiGroupIds) {
                    let serviceId = filteredAppt.find(f => f.appointmentDetail.multiGroupId == mId).appointmentDetail.serviceId;
                    serviceIds.push(serviceId);
                }
                return this.GetAvailableEqupmentQuantity(service.id, serviceEquipments, serviceIds);
            }
        }
        return resultArray;
    }

    GetLocationAvailablityForSlot(slotTime: Date, locationAvailability: LocationAvailability[], service: ServiceViewModel, ignoreSetupBreakDownTime: boolean, therapistMaxService: TherapistMaxServiceDetail[]): Location[] {
        const availableLocations: Location[] = [];
        if (!locationAvailability || locationAvailability.length == 0) {
            return availableLocations;
        }
        let startTime = slotTime;
        let endTime = this.utils.AddMinutes(slotTime, service.duration);
        if (!ignoreSetupBreakDownTime) {
            startTime = this.utils.AddMinutes(startTime, -(service.setupTime));
            endTime = this.utils.AddMinutes(endTime, service.breakDownTime);
        }
        for (let availability of locationAvailability) {
            let isSlotOccupied = false;
            if (availability.occupiedTime && availability.occupiedTime.length > 0) {
                for (let occupied of availability.occupiedTime) {
                    occupied.startTime = this.utils.getDate(occupied.startTime);
                    occupied.endTime = this.utils.getDate(occupied.endTime);
                }
                // check any of the slot is occupied
                isSlotOccupied = availability.occupiedTime.some(r => (r.startTime.getTime() >= startTime.getTime() && r.startTime.getTime() < endTime.getTime()) ||
                    (r.endTime.getTime() > startTime.getTime() && r.endTime.getTime() <= endTime.getTime()) ||
                    (r.startTime.getTime() < startTime.getTime() && r.endTime.getTime() > endTime.getTime()));
            }
            if (!isSlotOccupied) {
                availableLocations.push(availability.location);
            }
        }

        var result = [];

        availableLocations.forEach(c => {
            if (therapistMaxService.filter(b => b.therapistSchedule && b.therapistSchedule.filter(d => (d.locationId == null || d.locationId == 0 || d.locationId == c.id) && startTime.getTime() >= this.utils.getDate(d.fromTime).getTime()
                && endTime.getTime() <= this.utils.getDate(d.toTime).getTime()).length > 0).length > 0) {
                result.push(c);
            }
        });

        return result;
    }

    GetTherapistAvailabilityForSlot(slotTime: Date, scheduledTherapists: TherapistAvailability, availableLocations: Location[], therapistMaxService: TherapistMaxServiceDetail[],
        service: ServiceViewModel, ignoreSetupBreakDownTime: boolean): Therapist[] {
        const availableTherapists: Therapist[] = [];
        if (!scheduledTherapists || !scheduledTherapists.therapistDetails || scheduledTherapists.therapistDetails.length == 0) {
            return availableTherapists;
        }
        let startTime = slotTime;
        let endTime = this.utils.AddMinutes(slotTime, service.duration);
        if (!ignoreSetupBreakDownTime) {
            startTime = this.utils.AddMinutes(startTime, -(service.setupTime));
            endTime = this.utils.AddMinutes(endTime, service.breakDownTime);
        }
        for (let therapist of scheduledTherapists.therapistDetails) {
            let isScheduleAvailable = false;

            // Check if therapist has schedule for selected duration
            let availableSchedule: ScheduleTime;
            if (therapist.scheduleTime && therapist.scheduleTime.length > 0) {
                for (let timing of therapist.scheduleTime) { // Converting string to Date format
                    timing.startTime = this.utils.getDate(timing.startTime);
                    timing.endTime = this.utils.getDate(timing.endTime);
                }
                availableSchedule = therapist.scheduleTime.find(t => t.startTime.getTime() <= startTime.getTime() && t.endTime.getTime() >= endTime.getTime());
                isScheduleAvailable = availableSchedule ? true : false;
            }

            // Check if therapist has occupied for selected duration
            if (isScheduleAvailable && therapist.occupiedTime && therapist.occupiedTime.length > 0) {
                for (let timing of therapist.occupiedTime) { // Converting string to Date format
                    timing.startTime = this.utils.getDate(timing.startTime);
                    timing.endTime = this.utils.getDate(timing.endTime);
                }
                const isTherapistOccupied = therapist.occupiedTime.some(r => (r.startTime.getTime() >= startTime.getTime() && r.startTime.getTime() < endTime.getTime()) ||
                    (r.endTime.getTime() > startTime.getTime() && r.endTime.getTime() <= endTime.getTime()) ||
                    (r.startTime.getTime() < startTime.getTime() && r.endTime.getTime() > endTime.getTime()));
                isScheduleAvailable = isTherapistOccupied ? false : true;
            }

            // Check if therapist's location available
            if (isScheduleAvailable) {
                if (availableSchedule.locationId > 0) {
                    isScheduleAvailable = availableLocations && availableLocations.some(r => r.id == availableSchedule.locationId);
                }
            }

            // Check Therapist's max number of services per day
            if (isScheduleAvailable && therapistMaxService && therapistMaxService.length > 0) {
                const therapistService = therapistMaxService.find(r => r.serviceId == service.id && r.therapistId == therapist.therapist.id);
                if (therapistService) {
                    isScheduleAvailable = therapistService.maxNumberOfServices == 0 || therapistService.remainingServices > 0;
                }
            }
            if (isScheduleAvailable) {
                availableTherapists.push(therapist.therapist);
            }
        }
        return availableTherapists;
    }

    isEquipmentUnderMaintenance(slotTime: Date, maintenance: ServiceEquipmentMaintenance[], service: ServiceViewModel, ignoreSetupBreakDownTime: boolean): boolean {
        let underMaintenance = false;
        if (!maintenance || maintenance.length == 0) {
            return underMaintenance;
        }
        let startTime = slotTime;
        let endTime = this.utils.AddMinutes(slotTime, service.duration);
        if (!ignoreSetupBreakDownTime) {
            startTime = this.utils.AddMinutes(startTime, -(service.setupTime));
            endTime = this.utils.AddMinutes(endTime, service.breakDownTime);
        }
        maintenance.forEach(m => {
            m.startTime = this.utils.getDate(m.startTime);
            m.endTime = this.utils.getDate(m.endTime);
        });

        underMaintenance = maintenance.some(r => (r.startTime.getTime() >= startTime.getTime() && r.startTime.getTime() < endTime.getTime()) ||
            (r.endTime.getTime() > startTime.getTime() && r.endTime.getTime() <= endTime.getTime()) ||
            (r.startTime.getTime() < startTime.getTime() && r.endTime.getTime() > endTime.getTime()));

        return underMaintenance;
    }

    ValidateSlotSelection(service: ServiceViewModel, selectedClients: any[], selectedSlot: WizardTimeSlots, packageId?: number): SlotSelectionValidationResponse {
        let response: SlotSelectionValidationResponse = {
            isMultiGroupAppointment: false,
            isValidSelectionForBooking: true, validationMessage: '',
        };

        let minGuest = service.minimumGuest;
        let maxGuest = service.maximumGuest;
        let minTherapist = service.minimumStaff;
        let noOfClientsForAppointment = selectedClients.length;
        // All single appointment. validate available staff with no. of clients selected.
        if (minGuest == 1 && minTherapist == 1) {
            if (noOfClientsForAppointment > selectedSlot.availableSlots) {
                response.isValidSelectionForBooking = false;
                response.validationMessage = this.localization.captions.bookAppointment.spawizard.NoEnoughSlotsForBooking;
                return response;
            }
        }

        // It's a multi Group Appointment
        if (minGuest > 1 || minTherapist > 1) {
            // Min client Validation
            response.isMultiGroupAppointment = true;
            if (minGuest > 1) {
                if (noOfClientsForAppointment < minGuest) {
                    response.isValidSelectionForBooking = false;
                    response.validationMessage = this.localization.replacePlaceholders(this.localization.captions.bookAppointment.spawizard.MinMaxGuestNotSatisfied, ['minGuest', 'maxGuest'], [minGuest, maxGuest]);
                    return response;
                }
            }

            // Max client validation
            if (noOfClientsForAppointment > maxGuest) {
                response.isValidSelectionForBooking = false;
                response.validationMessage = this.localization.replacePlaceholders(this.localization.captions.bookAppointment.spawizard.MinMaxGuestNotSatisfied, ['minGuest', 'maxGuest'], [minGuest, maxGuest]);
                return response;
            }
        }

        //Check for Quick room 
        let formData = this.wizardService.GetAppointmentFilterData();
        if (formData.newApptForm.quickRoom && noOfClientsForAppointment != 2) {
            let errorText = noOfClientsForAppointment > 2 ? this.localization.captions.bookAppointment.spawizard.QuickRoomHasMoreGuests :
                this.localization.captions.bookAppointment.spawizard.QuickRoomHasLessGuest
            response.isValidSelectionForBooking = false;
            response.validationMessage = errorText;
        }

        //Check overbook
        let nonByPassClientSchedulingClients = selectedClients.filter(x => !(x.clientDetail.bypassClientScheduling || x.clientDetail.BypassClientScheduling)),
            clientOverBook = [];
        if (nonByPassClientSchedulingClients && nonByPassClientSchedulingClients.length) {
            nonByPassClientSchedulingClients.forEach(element => {
                this.wizardService.groupAppointments.forEach(val => {
                    if (element.clientDetail.tempId ? val.tempClientId == element.clientDetail.tempId : val.appointment.appointmentDetail.clientId == element.clientDetail.id) {
                        if (new Date(val.appointment.appointmentDetail.startTime).getTime() == selectedSlot.dateTime.getTime()) {
                            clientOverBook.push(`${element.clientDetail.firstName || element.clientDetail.FirstName} ${element.clientDetail.lastName || element.clientDetail.LastName}`);
                        }
                    }
                });
            });
            if (clientOverBook.length) {
                response.validationMessage = `${clientOverBook.join(", ")} ${this.wizardCaption.ClientsNotAvailableForSelectedTime}`;
                response.isValidSelectionForBooking = false;
            }
        }

        return response;
    }

}
