import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { QuickSaleCategory, QuickSaleCategoryOutlets } from '../../retail.modals';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { CategoryTable } from './retail-quick-sale-categories.model';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';

@Component({
  selector: 'app-retail-quick-sale-categories',
  templateUrl: './retail-quick-sale-categories.component.html',
  styleUrls: ['./retail-quick-sale-categories.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class RetailQuickSaleCategoriesComponent implements OnInit {
  tableoptions: any[];
  currIndex: any;
  categories: QuickSaleCategory[] = [];
  captions: any;
  IsViewOnly: boolean;
  type:any;
  outlets : any[];
  defaultOutlet: number;
  onEditDisableFunction: boolean = true;
  nonAccessableOutletIds: number[] = [];
  functionalities: { [key: string]: boolean } = {};
  constructor(private http: HttpServiceCall, 
    public _utilities: RetailUtilities,
    public localization: RetailLocalization,
     private _rs: RetailSetupService,
     public propertyInfo: RetailPropertyInformation,
     private func: RetailFunctionalityBusiness) {

  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
   
    this._utilities.ToggleLoader(true,this.captions.lbl_processing);
    
    this.func.getRetailFunctionality().then(res => {
     
			this.functionalities = res;	
      this.RefreshGrid();
      this._utilities.ToggleLoader(false);
		}); 		
    this.InvokeServiceCall("QuickSaleCategory", myGlobals.Host.retailManagement, HttpMethod.Get);
    this.InvokeServiceCall("GetSubPropertyAccessByUser", myGlobals.Host.retailManagement, HttpMethod.Get, {userId: this.localization.GetUserInfo('userId')});
    this.defaultOutlet = this.propertyInfo.GetDefaultOutlet();
    this.IsViewOnly = this._rs.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.QuickSaleCategories).view;
  }

  addCategory(data?:any, e?:any) {
    this._utilities.ToggleLoader(true,this.captions.lbl_processing)
    let quickSaleCategoryOutlets : QuickSaleCategoryOutlets [] = [];
    if(data.value.controls.selectedOptions.value && data.value.controls.selectedOptions.value[0]!== ""){
      quickSaleCategoryOutlets = data.value.controls.selectedOptions.value.map(x => ({
        id: 0,
        outletId: x.value,
        quickSaleCategoryId: 0,
        listOrder: 0
      }));
    } else {
      quickSaleCategoryOutlets = this.outlets.map(x => ({
        id: 0,
        outletId: x.subPropertyID,
        quickSaleCategoryId: 0,
        listOrder: 0
      }));
    }
    
    
    if(this.nonAccessableOutletIds && this.nonAccessableOutletIds.length > 0){
      this.nonAccessableOutletIds.forEach(outletId => {
        quickSaleCategoryOutlets.push({
          id: 0,
          outletId: outletId,
          quickSaleCategoryId: 0,
          listOrder: 0
        });
      });
    }

    if(!this.functionalities.IsShowOutletInQuickSaleCategoryGrid){
      quickSaleCategoryOutlets = [{
          id: 0,
          outletId: this.defaultOutlet > 0 ? this.defaultOutlet : this.outlets[0].subPropertyID,
          quickSaleCategoryId: 0,
          listOrder: 0
      }]
    } 
    
    if (data.type.toLowerCase() == this.captions.ADD.toLowerCase()) {
      let category: QuickSaleCategory = {
        id: 0,
        quickSaleCategory: data.value.controls.setupCodeName.value,
        isActive: true,
        listOrder: 0,
        quickSaleCategoryOutlets: quickSaleCategoryOutlets
      }
      this.InvokeServiceCall("QuickSaleCategory", myGlobals.Host.retailManagement, HttpMethod.Post, '', category);
    } else if (data.type.toLowerCase() == this.captions.UPDATE.toLowerCase()) {
      let category: QuickSaleCategory = {
        id: this.tableoptions[0].TablebodyData[this.currIndex].id,
        quickSaleCategory: data.value.controls.setupCodeName.value,
        isActive: data.value.controls.activetoggle.value,
        listOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
        quickSaleCategoryOutlets: quickSaleCategoryOutlets
      }
      this.InvokeServiceCall("QuickSaleCategoryWithId", myGlobals.Host.retailManagement, HttpMethod.Put, { id: category.id }, category);
    }
    return true;
  }
  EditRecords(data?:any, type?:any) {
    console.log(data, type);
    this._utilities.ToggleLoader(true,this.captions.lbl_processing)
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
    this.updateNonAccessableOutlet();
  }
  DeleteRecords(event:any) {
    this._utilities.ToggleLoader(true,this.captions.lbl_processing)
    this.InvokeServiceCall("QuickSaleCategoryWithId", myGlobals.Host.retailManagement, HttpMethod.Delete, { id: event[0].id });
  } 
  Done(event:any) {
    if (event) {
      let quickSaleCategoryOutlets : QuickSaleCategoryOutlets [] = [];
      quickSaleCategoryOutlets = event.outletIds.map(x => ({
        id: 0,
        outletId: x,
        quickSaleCategoryId: event.id,
        listOrder: 0

      }));
      let body: QuickSaleCategory = event;
      body.quickSaleCategoryOutlets = quickSaleCategoryOutlets;
      this.InvokeServiceCall("QuickSaleCategoryWithId", myGlobals.Host.retailManagement, HttpMethod.Put, { id: body.id }, body);
    }
  }

  dragDrop(event:any) {
    this._utilities.ToggleLoader(true,this.captions.lbl_processing)
    let params = { 'fromorder': event[0], 'toorder': event[1] }
    this.InvokeServiceCall("UpdateQuickSaleCategoryListOrder", myGlobals.Host.retailManagement, HttpMethod.Put, params);
  }

  UpdateCategory(data:any) {
    let optionIndex = this.tableoptions[0].dropdownOptions.findIndex(item => item.id == data[0].value);
    let dataIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.itemid == data[2].itemid);
    this.tableoptions[0].TablebodyData[dataIndex].categoryName = this.tableoptions[0].dropdownOptions[optionIndex].id;
    this.tableoptions[0].TablebodyData[dataIndex].checkbox = false;
    this._rs.quickSaleItemList[dataIndex].categoryName = this.tableoptions[0].dropdownOptions[optionIndex].id;

  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == 'QuickSaleCategory' || callDesc == 'QuickSaleCategoryWithId' || callDesc == 'UpdateQuickSaleCategoryListOrder') {
      this.categories = <any>result.result;
      this.RefreshGrid();
      this._utilities.ToggleLoader(false)
    } else if(callDesc == 'GetSubPropertyAccessByUser') {
      this.outlets = <any>result.result;
      this.RefreshGrid();
      this._utilities.ToggleLoader(false)
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this._utilities.ToggleLoader(false)
  }

  private RefreshGrid() {
    const TableHdrDataArray = [];
    TableHdrDataArray.push({ "title": this.captions.QuickSaleCategory, "jsonkey": "quickSaleCategory", "sortable":true });
    if(this.functionalities.IsShowOutletInQuickSaleCategoryGrid){
      TableHdrDataArray.push({ "title": this.captions.Outlet, "jsonkey": "outletNames",  "sortable": true });
    }
    TableHdrDataArray.push({ "title": this.captions.Active, "jsonkey": "isActive", "type": "toggle", "sortable": true });
    TableHdrDataArray.push({ "title": this.captions.ListOrder, "jsonkey": "listOrder", "alignType": "right", "sortable": true });
    this.tableoptions = [
      {
        TableHdrData: [...TableHdrDataArray],
        TablebodyData: this.formTableData(),
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.quickSaleCategories,
        Sortable: "category",
        TableId: myGlobals.GridType.quickSaleCategories,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'quicksale',
        ServiceId: 'quicksaleCategories',
        TableDraggable: true,
        IsViewOnly: this.IsViewOnly,
        enableMultiSelect: this.functionalities.IsShowOutletInQuickSaleCategoryGrid? true: false,
        selectOptions: this.outlets && this.functionalities.IsShowOutletInQuickSaleCategoryGrid ? this.outlets.map(x => {
          return {
            id: x.propertyID,
            value: x.subPropertyID,
            viewValue: x.subPropertyName
          }
        }) : [{
          id: 0,
          value: (this.defaultOutlet && this.defaultOutlet > 0) ? this.defaultOutlet : ((this.outlets?.[0]?.subPropertyID) ?? 0)
        }],
        selectedOptionPlacholder: this.captions.OutletName,
        automationId: "retailQuickSaleCategories"
      }
    ];
  }

  private formTableData(): CategoryTable[] {
    if(this.categories) {
      let result : CategoryTable [] = [];
      this.categories.forEach(x => {
        const outletIds = x.quickSaleCategoryOutlets.map(y => y.outletId);
        result .push( {
          id: x.id,
          quickSaleCategory: x.quickSaleCategory,
          outletIds: outletIds,
          listOrder: x.listOrder,
          isActive: x.isActive,
          outletNames: this.getOutletNames(outletIds)
        });
       
      });
      return result;
    }
    else{
      return [];
    }
    
  }

  private getOutletNames(outletIds: number[]): string {
    let outletNames = '';
    if(this.outlets) {
      outletIds = outletIds.sort((x,y) => (x-y));
      const outletPropertyNames = [];
      outletIds.forEach(x=> {
        const outletDetail = this.outlets.find(outlet => outlet.subPropertyID == x);
        if(outletDetail){
          outletPropertyNames.push(outletDetail.subPropertyName);
        }
        
      })
      outletNames = outletPropertyNames.join(',');
    }
   
    return outletNames;
  }

  private updateNonAccessableOutlet() {
    this.nonAccessableOutletIds = [];
    const editData = this.tableoptions[0].TablebodyData[this.currIndex];
    if(editData.outletIds && editData.outletIds.length > 0) {
      editData.outletIds.forEach(element => {
        const data = this.outlets.find(x=> x.subPropertyID == element);
        if(!data) {
          this.nonAccessableOutletIds.push(element);
        }
      });
    }
    this._utilities.ToggleLoader(false);
  }

}
