import { HttpService } from "src/app/eatecui/source/shared/services/http.service";
import { TransactionService } from "src/app/eatecui/source/transaction/shared/services/transaction.service";
import { PODeliveryCutOffModel } from "./create-transaction-config.interface";
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { promise } from "protractor";

export const PODeliveryCutOff: PODeliveryCutOffModel = (
    postPayload: any,
    transactionService: TransactionService,
    httpService: HttpService
    ): any => {
        return new Promise(resolve => {
            try {
                const deliveryCheckURL = CONSTANT.ENDPOINTURL['DeliveryRulesCutOffCheck']['CheckDeliveryCutOff'];
                const url = deliveryCheckURL + `/TransactionPurchaseOrder`;
                const postData = {
                    POPostSuppliers: postPayload
                };
                httpService.PosthttpMethod(url, postData).subscribe(res => {
                    if (res?.length > 0) {
                        transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig?.ProcessPoConfig?.SupplierList?.forEach(sl => {
                           const ind = res.findIndex(r => r.SupplierId === sl.SuplierId);
                           if (ind > -1) {
                            sl['DeliveryDate'] = res[ind].DeliveryDate;
                            sl['Message'] = res[ind].Message;
                            sl['Warning'] = true;
                           }
                        });
                        resolve(transactionService.transactionConfigurationData);
                    } else {
                        resolve(transactionService.transactionConfigurationData);
                    }
                    console.log(res);
                });
            } catch (error) {
                console.log(error);
            }
        });        
    }