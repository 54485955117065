import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';


@Component({
  selector: 'app-retail-unit-of-measure',
  templateUrl: './retail-unit-of-measure.component.html',
  styleUrls: ['./retail-unit-of-measure.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailUnitOfMeasureComponent implements OnInit {
  FormGrp: UntypedFormGroup;
  unitOfMeasures: any = [];
  tableoptions: any[];
  captions: any;
  currIndex: any;
  IsViewOnly: boolean;
  type: any;
  maxListOrder: number;
  onEditDisableFunction: boolean = true;
  constructor(private Form: UntypedFormBuilder, private http: HttpServiceCall, public localization: RetailLocalization, private utils: RetailUtilities,
    private retailService: RetailSetupService) {
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      TerminalID: []
    });
    this.captions = this.localization.captions.retailsetup;
    this.InvokeServiceCall("GetUnitOfMeasures", myGlobals.Host.retailManagement, HttpMethod.Get);
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.UnitofMeasure).view;
  }

  addUnitOfMeasure(data: any) {
    if (data.type.toLowerCase() == this.localization.captions.setting.Add.toLowerCase()) {
      const unitOfMeasureObj = {
        IsActive: true,
        Name: data.value.controls.setupCodeName.value.trim(),
        ListOrder: Number(this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1] ? this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1].listOrder : 0) + 1,
      };
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.InvokeServiceCall("CreateUnitOfMeasure", myGlobals.Host.retailManagement, HttpMethod.Post, '', unitOfMeasureObj);
 
    }
    else if (data.type.toLowerCase() == this.localization.captions.setting.update.toLowerCase()) {
      const unitOfMeasureObj = {
        id: this.tableoptions[0].TablebodyData[this.currIndex].id,
        IsActive: data.value.controls.activetoggle.value,
        Name: data.value.controls.setupCodeName.value.trim(),
        ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
      };
      this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.InvokeServiceCall("UpdateUnitOfMeasure", myGlobals.Host.retailManagement, HttpMethod.Put, { id: unitOfMeasureObj.id }, unitOfMeasureObj);
  
    }
    return true;
  }

  Done(event: any) {
    const unitOfMeasureObj = {
      id: event.id,
      IsActive: event.active,
      Name: event.measuringUnit,
      ListOrder: event.listOrder,
    };
    this.InvokeServiceCall("UpdateUnitOfMeasure", myGlobals.Host.retailManagement, HttpMethod.Put, { id: unitOfMeasureObj.id }, unitOfMeasureObj);
  
  }

  EditRecords(data?: any, type?: any) {
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
  }

  DeleteRecords(event: any) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("DeleteUnitOfMeasure", myGlobals.Host.retailManagement, HttpMethod.Delete, { id: event[0].id });
 
  }

  dragDrop(e: any) {
    let params = { 'fromorder': e[0], 'toorder': e[1] }
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.InvokeServiceCall("UnitOfMeasureDragDrop", myGlobals.Host.retailManagement, HttpMethod.Put, params);
 
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateUnitOfMeasure" || "GetUnitOfMeasures" || "DeleteUnitOfMeasure" || "UpdateUnitOfMeasure" || "UnitOfMeasureDragDrop") {
      let response = <any>result.result;
      this.unitOfMeasures = [];
      response.forEach(element => {
        let UnitOfMeasure: any = {
          id: element.id,
          measuringUnit: element.name,
          active: element.isActive,
          listOrder: element.listOrder
        }
        this.unitOfMeasures.push(UnitOfMeasure);
      });

      this.BindToGrid();
      this.utils.ToggleLoader(false);
    }
  }

  private BindToGrid() {
    this.tableoptions = [
      {
        TableHdrData: [{ "title": this.captions.MeasuringUnit, "jsonkey": "measuringUnit", "sortable": true },
        { "title": this.captions.Active, "jsonkey": "active", "sortable": true },
        { "title": this.captions.ListOrder, "jsonkey": "listOrder", "alignType": "right", "sortable": true, "searchable": false }],
        TablebodyData: this.unitOfMeasures,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.unitOfMeasure,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.unitOfMeasure,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'measures',
        ServiceId: 'measures',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: true,
        automationId: 'retailUnitOfMeasure'
      }
    ];
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  sliderChange(event: any) {
    let currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.data.id);
    this.tableoptions[0].TablebodyData[currIndex].active = !event.value;
  }

}
