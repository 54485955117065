import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailReportControl } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-sales-by-discountype',
  templateUrl: './sales-by-discountype.component.html',
  styleUrls: ['./sales-by-discountype.component.scss']
})
export class RetailSalesByDiscountypeComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  SalesByDiscountTypeFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  outlets: any;
  allCategoryGroups: any[];
  discountTypes: any[];
  categories: any[];
  allCategories: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  valuesSelected: DropDownData[] = [];
  selectedDropdownType = '';
  editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService
    ,private container: ViewContainerRef) { }

  ngOnInit() {
    this.SalesByDiscountTypeFormGrp = this.fb.group({
      reportcategory: new UntypedFormControl([]),
      category: new UntypedFormControl([]),
      discounttype: new UntypedFormControl([]),
      summaryView : new UntypedFormControl()
    });
    this.formReady.emit(this.SalesByDiscountTypeFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
        if(patchItem.DiscountType != undefined){
          this.dataService.GetDiscountTypes().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.DiscountType.find(x => x == item.id);
            });
            this.discountTypes = arr;     
          });
        } 
        if(patchItem.CategoryIds != undefined){
          this.dataService.GetAllCategories().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.CategoryIds.find(x => x == item.id);
            });
            this.categories = arr;
            this.dataService.GetAllCategoryGroups().then(res => {
              let arr = res.filter((item) => {
                return this.categories.find(x => x.categoryGroup == item.id);
            });
            this.allCategoryGroups  = arr; 
          });
        });
      }
        }
    } 
  }

  private async onLoad() {
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.categories = await this.dataService.GetAllCategories();
    this.discountTypes = await this.dataService.GetDiscountTypes();
    this.allCategories = this.categories;
    this.discountTypes.push({ id: -1, description: this.localization.captions.shop.lbl_costDiscount, active: true, listOrder: this.discountTypes.length + 1, showInDropDown: true },
      { id: -2, description: this.localization.captions.shop.lbl_costPlusDiscount, active: true, listOrder: this.discountTypes.length + 2, showInDropDown: true },
      { id: -3, description: "Other", active: true, listOrder: this.discountTypes.length + 3, showInDropDown: true }
    )
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
    this.business.FilterDataSource["categories"] = this.categories;
    this.business.FilterDataSource["discountTypes"] = this.discountTypes;
  }

  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
    this.SalesByDiscountTypeFormGrp.setControl('reportcategory', event[1]);
    } else if (event[0] === 'category') {
      this.SalesByDiscountTypeFormGrp.setControl('category', event[1]);
      } else if (event[0] === 'discounttype') {
        this.SalesByDiscountTypeFormGrp.setControl('discounttype', event[1]);
        }
  }

  getCategoryGroupValue(selectedCategoryGroupArray)
  {
    let filteredCategory = this.allCategories.filter(element => selectedCategoryGroupArray.map(r=>r.id).includes(element.categoryGroup));
    this.categories = filteredCategory;
  }
}
