import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
// import {write, utils} from 'xlsx';
import moment from 'moment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_TYPE = 'text/csv;charset=utf-8';
const CSV_EXTENSION = '.csv';

@Injectable()
export class ExcelService {

    public exportAsExcelFile(headingobj: any, json: any[], excelFileName: string): void {
        json.unshift(headingobj);
        import('xlsx').then(XLSX => {
            // do something with xlsx
            const worksheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
            const workbook = { Sheets: { 'Exported data': worksheet }, SheetNames: ['Exported data'] };

            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

           this.saveAsExcelFile(excelBuffer, excelFileName);
        });
        
        
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + moment().format('MM-DD-YYYY_hh:mm:ss').toString() + EXCEL_EXTENSION);
    }

    public exportAsCSVFile(headingobj: any, json: any[], excelFileName: string): void {
        json.unshift(headingobj);
        import('xlsx').then(XLSX => {
            // do something with xlsx
            const worksheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
            const csv = XLSX.utils.sheet_to_csv(worksheet)


            const data: Blob = new Blob([csv], {
                type: CSV_TYPE
            });
            FileSaver.saveAs(data, excelFileName + '_' + moment().format('MM-DD-YYYY_hh:mm:ss').toString() + CSV_EXTENSION);
        });
        
        
    }

}