import { GridTableCard, MasterInventoryManagement } from '../master.interface';
import { MasterDetailPictureConfiguration } from './master-detail-config.interface';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { DetailsPayload, FileViewData, ThumbViewModel } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { MasterName } from '../../enum/master-enum';

export const MasterDetailPicture: MasterDetailPictureConfiguration =
(MasterDataCollection: MasterInventoryManagement,
  InventoryId: number,
   httpService?: HttpService, domSanitizer?: any): any =>  {
    try {
      if (MasterDataCollection.DetailsConfig.hasOwnProperty('DetailPictures') &&
                    MasterDataCollection.DetailsConfig.DetailPictures.hasOwnProperty('PictureUrl') &&
                    (MasterDataCollection.Name === 'Inventory' || MasterDataCollection.Key === 'Products' || MasterDataCollection.Key === 'Recipes')) {
                    MasterDataCollection.DetailsConfig.DetailPictures.PictureDetail = [];
                    let InventoryGroupId: any;
                    switch (MasterDataCollection.Key) {
                        case MasterName.MasterIngredients: InventoryGroupId = 1; break;
                        case MasterName.MasterProducts: InventoryGroupId = 3; break;
                        case MasterName.MasterReceipe: InventoryGroupId = 2; break;
                    }
            const seleInventoryId = `InventoryId eq ${InventoryId} and ` + `InventoryGroupId eq ${InventoryGroupId}`;
                    const Pictureparams = {} as QueryParams;
                    Pictureparams.orderby = 'IsFavorite desc';
                    Pictureparams.filterQuery = encodeURIComponent(`${seleInventoryId}`).replace(/[!'()*]/g, function (c) {
                        return '%' + c.charCodeAt(0).toString(16);
                    });
                    const GetPictuureEnpoint = MasterDataCollection.DetailsConfig.DetailPictures.PictureUrl;
                    const filterParams = formQueryParams(Pictureparams);
                    const endPointPictureUrl = `/inventory/api/${GetPictuureEnpoint}?` + filterParams;
                    MasterDataCollection.DetailsConfig.DetailPictures.EnablePictureProgress = true;
                    const FileDetails = {} as DetailsPayload;
                    FileDetails.ItemId = InventoryId;
                    FileDetails.GroupId = InventoryGroupId;
                    FileDetails.EndpointUrl = `/inventory/api/File/Details?`;
                    FileDetails.EndPoint = `/inventory/api/File/Download?`;
                    const ViewResponse: Array<FileViewData> = [];
                    // const FileViewResponse: Array<ThumbViewModel> =
                    MasterDataCollection.DetailsConfig.DetailPictures.PictureDetail = [];
                    FileData.FileThumbView(ViewResponse, FileDetails, httpService, domSanitizer)
                        .then((FileViewResponse: Array<ThumbViewModel>) => {
                          MasterDataCollection.DetailsConfig.DetailPictures.EnablePictureProgress = false;
                            if (FileViewResponse && FileViewResponse.length > 0) {
                                const fielViewResponse = new Promise((resolve, reject) => {
                                    FileViewResponse.forEach(async (response: ThumbViewModel, index) => {
                                        MasterDataCollection.DetailsConfig.DetailPictures.TotalCount = response.TotalCount;
                                        const GetFileExtension = response.FilePath.split('.').pop();
                                        if (GetFileExtension && GetFileExtension === 'mp4') {
                                            response.Picture = await FileData.FileThumbNailGenearate('assets/images/video.png');
                                        }
                                        //   return response;
                                        if (index === FileViewResponse.length - 1) {
                                            resolve(FileViewResponse);
                                        }
                                    });
                                });
                                fielViewResponse.then((res: ThumbViewModel[]) => {
                                     MasterDataCollection.DetailsConfig.DetailPictures.PictureHeaderName =
                                     MasterDataCollection.DetailsConfig.DetailsObservation;
                                    const responseDetailPicture = detailPicture(res, domSanitizer);
                                    MasterDataCollection.DetailsConfig.DetailPictures.PictureDetail = [...responseDetailPicture];
                                    MasterDataCollection.DetailsConfig.UploadFileCollection = [...responseDetailPicture];
                                });
                            }
                        });
                      }
                      return MasterDataCollection.DetailsConfig.DetailPictures;
                } catch (error) {
                  MasterDataCollection.DetailsConfig.DetailPictures.EnablePictureProgress = false;
                  console.error(error);
              }
};


function formQueryParams(params: QueryParams) {
  params.filter = '';
  const keys = Object.keys(params);
  let query = '';
  keys.forEach((data, index) => {
      if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
          if (params[data] !== '' && data !== 'filter') {
              if (index === 0) {
                  query = query + '?$';
              } else {
                  if (query.length > 0) {
                      query = query + '&$';
                  } else {
                      query = query + '?$';
                  }
              }
              query = query + data + '=' + params[data];
          } else if (data === 'filter') {
              if (params.isactive) {
                  params[data] = 'IsActive eq true';
              }
              if (params.searchtext && params.searchtext.length > 0 && params.search) {
                  if (params[data].length > 0) {
                      params[data] = params[data] + ' and ' + params.search;
                  } else {
                      params[data] = params.search;
                  }
              }
              if (params.filterQuery && params.filterQuery.length > 0) {
                  if (params[data].length > 0) {
                      params[data] = params[data] + ' and ' + params.filterQuery;
                  } else {
                      params[data] = params.filterQuery;
                  }
              }

              if (params[data] !== '') {
                  if (index === 0) {
                      query = query + '?$';
                  } else {
                      query = query + '&$';
                  }
                  query = query + data + '=' + params[data];
              }
          }
      }
  });
  return query;
}

function detailPicture(pictureArray: Array<any>, domSanitizer: any) {
  const detailPictures = [];
  try {
      if (pictureArray && pictureArray.length > 0) {
          pictureArray.forEach(pictures => {
              const pictureObject = {};
              pictureObject['Id'] = ( pictures.hasOwnProperty('FileDetails')) ?
               pictures.FileDetails.Id : pictures['Id'];
              pictureObject['Name'] = pictures['Name'];
              pictureObject['Url'] = typeof(pictures['Picture']) === 'string' ?
               domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${pictures['Picture']}`)
                : pictures['Picture'];
              pictureObject['IsActive'] = pictures['IsActive'];
              pictureObject['InventoryId'] = ( pictures.hasOwnProperty('FileDetails')) ?
              pictures.FileDetails.InventoryId : pictures['InventoryId'];
              pictureObject['InventoryGroupId'] = ( pictures.hasOwnProperty('FileDetails')) ?
              pictures.FileDetails.InventoryGroupId : pictures['InventoryGroupId'];
              pictureObject['IsFavorite'] = ( pictures.hasOwnProperty('FileDetails')) ?
               pictures.FileDetails.IsFavorite : pictures['IsFavorite'];
              pictureObject['SortOrder'] = ( pictures.hasOwnProperty('FileDetails')) ?
               pictures.FileDetails.SortOrder : pictures['SortOrder'];
              pictureObject['FileDetails'] = pictures['FileDetails'];
              pictureObject['FileData'] = pictures['FileData'];
              pictureObject['Picture'] = pictures['Picture'];
              pictureObject['FilePath'] = pictures['FilePath'];
              detailPictures.push(pictureObject);
          });
      }
      return detailPictures;
  } catch (error) {
      console.error(error);
  }
}
