import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { SpaLocalization } from './core/localization/spa-localization';
import { OAuthService } from 'angular-oauth2-oidc';
import { IdleTimeoutService } from './shared/service/session-expired.service'; 
import { AppBusiness } from './app.business';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { RestrictPortraitComponent } from './core/restrict-portrait/restrict-portrait.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AppBusiness]
})
export class AppComponent implements OnInit {

  constructor(public localization: SpaLocalization
    , private oauthService: OAuthService
    , public _ids: IdleTimeoutService
    , public appBusiness: AppBusiness,
    public deviceService: DeviceDetectorService,
    public dialog: MatDialog) { 

  }
  title = 'app';

  /**
   * @method @HostListener
   * @description If listener watch the Entire Application mousemove and keydown Event
   */

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(_e) {
    if (this._ids.resetOnTrigger) {
      this._ids.resetTimer();
    }

  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(_event: KeyboardEvent) {
    if (this._ids.resetOnTrigger) {
      this._ids.resetTimer();
    }
  }
  
  @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
    this.portraitCheck();{
    }
  }

  ngOnInit() { 
    this.portraitCheck();
    // window.onbeforeunload = (ev) => {
    //   let dialogText = this.localization.captions.common.RefreshMessage;
    //   ev.returnValue = dialogText;
    //   return dialogText;
    // };
  }
  portraitCheck(){
    if(this.deviceService.isTablet()) {
      if(window.orientation == 0 || window.orientation == 180){
        this.dialog.open(RestrictPortraitComponent, {
        width: '100%',
        height: '100%',
        disableClose: true,
      }); 
      }  else {
        this.dialog.closeAll();
     }
    }
  }
}

