import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[durationFormatter]'
})
export class DurationFormatterDirective {
    public numberValue: any;
    @Input() minValue: number;
    @Input() maxValue: number;

    constructor(el: ElementRef, private control: NgControl) {
        this.numberValue = el.nativeElement;
    }

    @HostListener('keydown', ['$event']) keydown($event: KeyboardEvent) {
        const arrayKeys: any = [27, 13, 110, 190, 17, 86]; // removed 9 from array -  for tab fix
        const server = arrayKeys.find(x => x == $event.keyCode);
        if (server ||
            (($event.keyCode == 65 || $event.keyCode == 86 || $event.keyCode == 67) && ($event.ctrlKey === true || $event.metaKey === true)) ||
            ($event.keyCode >= 35 && $event.keyCode <= 40)) {
            $event.preventDefault();
        }
        if (($event.shiftKey || ($event.keyCode < 48 || $event.keyCode > 57)) && ($event.keyCode < 96 || $event.keyCode > 105) && ([9, 8, 46].indexOf($event.keyCode) == -1)) {
            $event.preventDefault();
        }

    }
    @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
        const valuePasted = e.clipboardData.getData('text/plain');
        if (this.ValidateNumbers(valuePasted)) {
            if (parseInt(valuePasted) > this.maxValue) {
                e.preventDefault();
            }
        }
    }

    @HostListener('input', ['$event']) oninput(event) {
        const value = event.target.value;
        if (value.length > 0) {
            if (this.minValue && value < this.minValue) {
                event.currentTarget.value = this.minValue;
            } else if (this.maxValue && value > this.maxValue) {
                event.currentTarget.value = value.slice(0, value.length - 1);
                this.control.control.setValue('');
            }
        }
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value) {
        const formattedValue = ('00' + value).slice(-2);
        this.control.control.setValue(formattedValue);
    }

    ValidateNumbers(text) {
        const reg = /^-?[\d]+$/;
        return reg.test(String(text).toLowerCase());
    }

}