import { TranslateService } from '@ngx-translate/core';
import { CustomRowData, FormType, GroupingFormData } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { CustomFormBuild, CustomFormBuildingGrouping, FormBuilderGroupKey, MasterInventoryManagement } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FieldBuilderType } from './form-builder-type.enum';
import { FormModelConfiguration, FormModelCustomBuilder, FormModelGroupBuilder } from './form-config.interface';

export const FormFieldGrouping: FormModelGroupBuilder = (
ViewModelEntity: any, 
PostModelEntity: any, 
MasterDataCollection: MasterInventoryManagement, 
httpService: HttpService, 
CustomGrouping: CustomFormBuildingGrouping, translateService: TranslateService): any => {
    try {
        const FormGroupingData = [];
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 && CustomGrouping) {
            CustomGrouping.GroupKey.forEach( (FormBuilderGroup: FormBuilderGroupKey) => {
                const GroupData = {} as GroupingFormData;
                GroupData.name = FormBuilderGroup.OdataKey;
                GroupData.label = FormBuilderGroup.DisplayName;
                GroupData.type = FormInterface.FormGroupFieldTypes(
                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService);
                GroupData.value = '';
                // GroupData.fieldHeader = FormBuilderGroup.DisplayName;
                GroupData.FieldValidation = FormInterface.FormFieldValidations(
                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService);
                GroupData.options = FormInterface.FormFieldOption(
                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService);
                FormGroupingData.push(GroupData);
            });
        }
        return FormGroupingData; 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};