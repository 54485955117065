import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { HandleResponse, PaymentMethods } from 'src/app/retail/shared/business/shared.modals';
import { GiftCardBusiness, SearchScreen } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { HttpResponseStatus, GiftcardSearchResult } from 'src/app/retail/shared/service/payment/payment-model';
import { GiftCardPaymentRecord } from 'src/app/retail/shared/service/payment/payment-business.model';
import { GiftCardType, ButtonType } from 'src/app/retail/shared/globalsContant';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { AlertType } from 'src/app/retail/shared/shared.modal';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { AgilysysGCMethods, ExternalGCMethods } from "src/app/retail/payment/PaymentFlowHelpers";
import { PaymentMethod } from "src/app/retail/retail.modals";

@Component({
  selector: "app-gift-card-payment-section",
  templateUrl: "./gift-card-payment-section.component.html",
  styleUrls: ["./gift-card-payment-section.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class GiftCardPaymentSectionComponent implements OnInit, OnChanges {

  @Input() isRefund: boolean = false;
  @Input() tender: PaymentMethod;
  @Output() selectGiftCard = new EventEmitter<any>();

  GiftCardForm: UntypedFormGroup;
  GiftCardSelectedData = "";
  giftCardsSearchedData: GiftcardSearchResult[] = [];
  captions: any;
  currencyType: string;
  giftCardSearchResult: HandleResponse;
  currentOutletId: number;
  SearchScreen = SearchScreen;
  tempTenderId: PaymentMethods;
  cardNumberReset: string = "";
  IDTechTenders: PaymentMethods[] = [
    PaymentMethods.ExternalGiftCardIdTech,
    PaymentMethods.V1GiftCardIdTech,
    PaymentMethods.AgilysysGiftCard
  ]

  get tenderId() {
    return this.tender.isCloned ? this.tender.parentTypeId : this.tender.paymentTypeId;
  }

  get isThirdPartyGiftcard() {
    return (ExternalGCMethods.concat(AgilysysGCMethods)).includes(this.tenderId);
  }

  get CardType() {
    return this.isThirdPartyGiftcard ? GiftCardType.ExternalGiftCard : GiftCardType.V1GiftCard;
  }

  get isAgilysysGiftcard() {
    return (this.isThirdPartyGiftcard && this.giftCardBusiness.IsAgilysysGiftcard)
  }

  constructor(private localization: RetailLocalization
    , private giftCardBusiness: GiftCardBusiness
    , private propertyInfo: RetailPropertyInformation
    , private utils: RetailUtilities
    , private shopService: CommonVariablesService
  ) {
    this.captions = this.localization.captions.shop.GiftCard;
    this.currencyType = this.localization.currencySymbol;
    this.currentOutletId = shopService.SelectedOutletId;
  }

  ngOnInit() { this.tempTenderId = this.tenderId; }

  ngOnChanges() {
    if (!this.IDTechTenders.includes(this.tenderId) && this.tempTenderId != this.tenderId) {
      this.GiftCardSelectedData = "";
      this.giftCardsSearchedData = [];
      this.cardNumberReset = "reset";
    }
    this.tempTenderId = this.tenderId;
  }

  SelectGiftCard(selectedGiftCard: GiftcardSearchResult) {
    this.GiftCardSelectedData = selectedGiftCard.cardNumber;
    this.selectGiftCard.emit(selectedGiftCard);
  }

  HandleGiftCardData(giftCardData: HandleResponse) {
    if (!giftCardData) {
      this.GiftCardSelectedData = "";
      this.giftCardsSearchedData = [];
      this.selectGiftCard.emit(null);
      return;
    }
    console.log(giftCardData);
    this.giftCardSearchResult = giftCardData;
    this.GetGiftCardDetails(giftCardData);
  }

  async GetGiftCardDetails(giftCardData: HandleResponse) {
    if (giftCardData && (giftCardData.paymentHandle || giftCardData.cardNumber)) {
      let currentCardHandle = giftCardData.paymentHandle ? giftCardData.paymentHandle[0] : null;
      let result: GiftCardPaymentRecord = await this.giftCardBusiness.GetLatestPaymentRecordDetailByCardNumber(currentCardHandle?.inquiryInfo?.id, giftCardData.tenderId);
      this.giftCardsSearchedData = [];
      const maskCardNumber = (cardNumber: string): string => { return cardNumber.replace(/.(?=.{4})/g, '*') }
      const GetVendorName = (isExternal: boolean): string => { return isExternal ? this.giftCardBusiness.GiftcardVendorName : this.captions.VendorName["1"] }
      if (result) { //Existing Cards Available in our system
        let latestCardRecord = result;        
        if (result && result.expiryDate &&
          this.localization.getDate(result.expiryDate) < this.propertyInfo.CurrentDate) {
          this.utils.showAlert(this.captions.CardExpired, AlertType.Warning, ButtonType.Ok);
          this.GiftCardSelectedData = "";
          this.selectGiftCard.emit(null);
          return;
        }
        this.giftCardsSearchedData.push({
          amount: currentCardHandle.balance,
          expiryDate: latestCardRecord.expiryDate,
          firstName: latestCardRecord.firstName,
          lastName: latestCardRecord.lastName,
          cardType: this.CardType,
          vendor: GetVendorName(this.CardType == GiftCardType.ExternalGiftCard),
          cardNumber: currentCardHandle.inquiryInfo.id,
          maskedCardNumber: maskCardNumber(currentCardHandle.inquiryInfo.id),
          handle: currentCardHandle.handle,
          tenderId: giftCardData.tenderId,
          locExpiryDate: latestCardRecord.expiryDate ? this.localization.localizeDisplayDate(this.localization.getDate(latestCardRecord.expiryDate)).toUpperCase() : this.captions.NA,
          isCardActive: giftCardData?.isCardActive
        });
      } else if (giftCardData.paymentHandle && currentCardHandle) { //New cards which have handle means, already issued
        this.giftCardsSearchedData.push({
          amount: currentCardHandle.balance,
          expiryDate: null,
          locExpiryDate: "-",
          firstName: "-",
          lastName: "",
          cardType: this.CardType,
          vendor: GetVendorName(giftCardData.isThirdParty),
          cardNumber: currentCardHandle.inquiryInfo.id,
          maskedCardNumber: maskCardNumber(currentCardHandle.inquiryInfo.id),
          handle: currentCardHandle.handle,
          tenderId: giftCardData.tenderId,
          isCardActive: giftCardData?.isCardActive
        });
      } else { //Fresh cards
        this.giftCardsSearchedData.push({
          amount: 0,
          expiryDate: null,
          locExpiryDate: "-",
          firstName: "-",
          lastName: "",
          cardType: this.CardType,
          vendor: GetVendorName(giftCardData.isThirdParty),
          cardNumber: giftCardData.cardNumber,
          maskedCardNumber: maskCardNumber(giftCardData.cardNumber),
          handle: currentCardHandle?.handle,
          tenderId: giftCardData.tenderId,
          isCardActive: giftCardData?.isCardActive
        });
      }
      //Auto-Select Giftcard
      this.SelectGiftCard(this.giftCardsSearchedData[0]);
    }
  }
}