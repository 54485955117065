import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { DetailsBuilderData, GridFetchFields, PoPreviousValue, ProcessPoConfiguration, ProcessPoSupplier, TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety, PatchValueReassign, ProcessPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { CreateTransaction } from '.';
import { MatDialog } from '@angular/material/dialog';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { promise } from 'selenium-webdriver';
import { resolve } from 'dns';

export const ProcessPoConfig: ProcessPo = async (
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    transactionService: TransactionService, postModelHederEntity: any, postModelDetailEntity: any, homeGroup: FormGroup, GlobalOdataModel: any, dialog: MatDialog): Promise<TransactionConfiguration> => {
        return new Promise(async resolve => {
            try {
                if (TransactionCollection.CreateTranscationConfig) {
                    const createTransaction = TransactionCollection.CreateTranscationConfig;
                    const actionButtonConfig = createTransaction.HeaderConfig.ActionButtonConfig;
                    actionButtonConfig.forEach((poButton: AgilysysButtonModel) => {
                       if (poButton.buttonKey === 'processpo') {
                           poButton.buttonKey = 'postpoconfirm';
                           poButton.displayName = 'post po';
                       }
                    });
                    // changes made by if empty and zero quantity not allowed to process po
                    // let getItemListData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData
                    // .filter(x => x.hasOwnProperty('Qty') && x.Qty !== '' && x.Qty !== null &&  x.Qty !== 0 && x.Qty !== '0.00');
                    let getItemListData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
                    if (getItemListData && getItemListData.length > 0) {
                       let processSuplierArray = [];
                       getItemListData.forEach(x => {
                        const processSupplier = {} as ProcessPoSupplier;
                        processSupplier.SuplierId = x['SupplierId'];
                        processSupplier.SuplierName = x['SupplierName'];
                        processSupplier.SendVia = x['SendVia'];
                        processSupplier.CurrencyId = x['ForeignCurrencyId'];
                        processSupplier.ExchangeRate = x['ExchangeRate'];
                        processSuplierArray.push(processSupplier);
                       });
                       
                       const processUniqueSuplierArray =  processSuplierArray.filter((v, i, a) => a.findIndex(
                        t => ( t.SuplierId.toString() === v.SuplierId.toString() )) === i );
                        processSuplierArray = processUniqueSuplierArray;
                       if (processSuplierArray.length > 0) {
                          const processPoConfiguration = {} as ProcessPoConfiguration;
                          processPoConfiguration.SupplierList = processSuplierArray;
                          processPoConfiguration.EnabledProcessPo = true;
                          processPoConfiguration.SelectedSupplierId = processPoConfiguration.SupplierList[0].SuplierId;
                          TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig = processPoConfiguration;
                          transactionService.poCloneGridData = [];
                           transactionService.poCloneGridData =  _.cloneDeep(
                           TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData);
                          getItemListData =  transactionService.getFilterSupplier(processSuplierArray[0]['SuplierId'], getItemListData);
                          TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = getItemListData;
                       }
                       transactionService.transactionConfigurationData = {...TransactionCollection};
                    }
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.PreviousValuePO = [];
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList.forEach((res, index) => {
                        const PreviousValue = {} as PoPreviousValue;
                        PreviousValue.Id = index + 1;
                        PreviousValue.ParentId = res.SuplierId;
                        PreviousValue.FormData = TransactionCollection.CreateTranscationConfig.TransactionFormConfig.FormGroupData;
                        PreviousValue.GridData = transactionService.poCloneGridData.filter(x => x.SupplierId === res.SuplierId);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.PreviousValuePO.push(PreviousValue);
                    });
                    const postSuppliers = CreateTransaction.PostPOSuppliers(null, TransactionCollection, homeGroup, 
                        transactionService, postModelHederEntity, postModelDetailEntity, HttpMethods.Post, GlobalOdataModel);
                    const returnTransactionConfigurationData = await CreateTransaction.PODeliveryCutOffCheck(postSuppliers, transactionService, httpService);
                    resolve(returnTransactionConfigurationData);
                }
            } catch (Error) {
                console.error('Error occurred FetchGridProperties', Error);
            }
        });        
    };