import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AppService } from "../common/app-service";
import { HttpCallService } from "../common/communication/common/http-call.service";
import { BaseResponse, ServiceParams } from "../common/Models/http.model";
import { CommonPropertyInformation } from "../common/shared/services/common-property-information.service";
import { RetailLocalization } from "./common/localization/retail-localization";
import { RetailUtilities } from "./shared/utilities/retail-utilities";

@Injectable({
    providedIn: 'root'
})
export class RetailCommunication extends HttpCallService {

    constructor(httpclient: HttpClient, localization: RetailLocalization, utilities: RetailUtilities, commonPropertyInfo: CommonPropertyInformation, appService: AppService) {
        super(environment["RetailGateway"], httpclient, localization, utilities, commonPropertyInfo,appService);
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        let response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false): Promise<T> {
        let response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params, isLoaderRequired);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }

    public Post<T>(params: ServiceParams) {
        return super.post<BaseResponse<T>>(params);
    }

    public Put<T>(params: ServiceParams) {
        return super.put<BaseResponse<T>>(params);
    }

    public async postPromiseDirect<T>(params: ServiceParams): Promise<T> {
        return super.postPromise(params);
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        }
        else {
            throw err;
        }
    }


}
