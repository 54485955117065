import { Injectable } from '@angular/core';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { HttpMethod } from 'src/app/shared/models/http.model';
import { BaseResponse } from '../retail.modals';
import { PaymentMethods } from '../shared/business/shared.modals';
import { Host, ResortFinancePaymentMethods } from '../shared/globalsContant';
import { HttpServiceCall } from '../shared/service/http-call.service';
import { UI, API } from './payment.model';


@Injectable()
export class PaymentMethodBusiness {
    defaultNoOfReceipt: number = 1;
    constructor(private http: HttpServiceCall) { }


    public async getPaymentMethodByProduct(fromFolio: boolean = false): Promise<DropdownOptions[]> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "PaymentMethod",
            host: Host.payment,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: undefined
        });

        const retailPayments = response.result;
        let data = retailPayments.filter(x => x.paymentTypeId != PaymentMethods.IDTECH)
        
        if(fromFolio){
            data = data.filter(x => Object.values(ResortFinancePaymentMethods).includes(x.paymentTypeId));
        }
        return data ? data.map(x => ({ id: x.id, value: x.id, viewValue: x.paymentMethod || x.method, otherData: x })) : [];
    }

    public async getPaymentMethod(id): Promise<any> {

        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetPaymentMethod",
            host: Host.payment,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { id }
        });

        return response.result;
    }

    public async getPostTypeOptions(): Promise<any[]> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "GetAllPostType",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: { includeInactive: false }
        });

        const postTypes = response.result;
        return postTypes.map(x => ({ id: x.id, postTypeNumber: x.postTypeNumber, postTypeName: x.postTypeName, checked: false }));
    }

    public async create(paymentMethod: UI.PaymentMethod, paymentTypeId, retailPayments): Promise<boolean> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "CreatePaymentMethod",
            host: Host.folio,
            method: HttpMethod.Post,
            body: this.APIMapper(paymentMethod, paymentTypeId, retailPayments),
            uriParams: undefined    
        });

        return response.result;
    }

    public async delete(id: any) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "DeletePaymentMethod",
            host: Host.folio,
            method: HttpMethod.Delete,
            body: undefined,
            uriParams: { id }
        });

        return response?.result;
    }

    public async update(paymentMethod: UI.PaymentMethod, paymentTypeId, retailPayments): Promise<boolean> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "UpdatePaymentMethod",
            host: Host.folio,
            method: HttpMethod.Put,
            body: this.APIMapper(paymentMethod, paymentTypeId, retailPayments),
            uriParams: undefined
        });

        return response.result;
    }

    public async updateListOrder(body) {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: "UpdatePaymentOnGridAction",
            host: Host.folio,
            method: HttpMethod.Put,
            body,
            uriParams: undefined
        });

        return response.result;
    }


    private APIMapper(paymentMethod: UI.PaymentMethod, paymentTypeId, retailPayments): API.PaymentMethod {
        return {
            id: paymentMethod.id,
            code: paymentMethod.code,
            name: paymentMethod.name,
            listOrder: paymentMethod.listOrder ? paymentMethod.listOrder : 0,
            postTypeId: paymentMethod.postTypeId ? paymentMethod.postTypeId : 0,
            isUseSpecialGuarantee: paymentMethod.isUseSpecialGuarantee,
            creditLimit: paymentMethod.creditLimit ? paymentMethod.creditLimit : 0,
            isActivateInterface: paymentMethod.isActivateInterface,
            isPaymentTypeAllowedOnWeb: paymentMethod.isPaymentTypeAllowedOnWeb,
            phoneRestrictions: paymentMethod.phoneRestrictions ? paymentMethod.phoneRestrictions : 0,
            isRestrictPOSChanges: paymentMethod.isRestrictPOSChanges,
            isMovieRestrictions: paymentMethod.isMovieRestrictions,
            isInterfacePostToCheckoutFolio: paymentMethod.isInterfacePostToCheckoutFolio,
            centralResvEquivalent: paymentMethod.centralResvEquivalent,
            ccTypeId: paymentMethod.ccTypeId.id ? paymentMethod.ccTypeId.id : paymentMethod.ccTypeId,
            isActive: typeof paymentMethod.isActive === 'object' ? paymentMethod.isActive.value : paymentMethod.isActive,
            requireComments: typeof paymentMethod.requireComments === 'object' ? paymentMethod.requireComments.value : paymentMethod.requireComments,
            isSurcharge: paymentMethod.isSurcharge,
            isPercent: paymentMethod.isPercent,
            surchargeValue: paymentMethod.surchargeValue,
            paymentMethodServiceReferences: this.MappaymentMethodServiceReferences(paymentMethod, paymentTypeId, retailPayments),
        };
    }

    public MappaymentMethodServiceReferences(paymentMethod: UI.PaymentMethod, paymentTypeId, retailPayments): API.PaymentMethodServiceReferences[] {
        var data = paymentTypeId === PaymentMethods.CreditCard
            ? retailPayments.filter(x => x.paymentTypeId == PaymentMethods.CreditCard || x.paymentTypeId == PaymentMethods.IDTECH)
            : paymentMethod.ccTypeId.id ? retailPayments.filter(x => x.id == paymentMethod.ccTypeId.id) : retailPayments.filter(x => x.id == paymentMethod.ccTypeId);
        
        if(data[0].otherData && data[0].otherData != null) {
            data= data.map(x => x.otherData);
        }

        return data.map(x => {
            return {
                id: 0,
                paymentMethodId: paymentMethod.id,
                serviceReferenceId: x.id,
                paymentMethod: x.paymentMethod || x.method,
                paymentTypeId: x.paymentTypeId,
            };
        });
    }

    public async getNextListOrder(): Promise<number> {
        let response: BaseResponse<number> = await this.http.CallApiAsync<number>({
            callDesc: "GetNextListOrder_PaymentMethod",
            host: Host.folio,
            method: HttpMethod.Get,
            body: undefined,
            uriParams: undefined
        });

        return response.result;
    }    

    public getOriginalTenderId(tenderId, parentTenderId):number{
        return parentTenderId > 0 ? parentTenderId : tenderId;
    }
}
