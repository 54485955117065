interface String {
    interpolate(object: any): string;
    containsSpecialChar(): boolean;
}


(function () {

    String.prototype.interpolate = function (object: any): string {
        object = object ? object : {};
        let keys: string[] = Object.keys(object);
        var _this = this;
        for (let key of keys) {
            let newkey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            var regEx = new RegExp("{" + newkey + "}", "ig");
            _this = _this.replace(regEx, object[key]);
        }
        return _this;
    }

    String.prototype.containsSpecialChar = function (): boolean {
        var regex = /^[A-Za-z0-9 ]+$/
        return !regex.test(this);
    }

})();