import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { DataImportWorkFlow, LoadSalesHistoricalData, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from '@shared/constant/GlobalConstants';

export const LoadSalesHistoryDataConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, Value?: any): any => {
    try {   
        const DetailsData = Value.detailsData;
        const PopUpData = Value.popUpData;
        const loadSalesHistoryObject = {} as LoadSalesHistoricalData;
        loadSalesHistoryObject.Id = DetailsData.Id ?
         DetailsData.Id : 0;
        loadSalesHistoryObject.Name = DetailsData.Name ?
        DetailsData.Name : '';
        loadSalesHistoryObject.Description = DetailsData.Description ?
        DetailsData.Description : '';
        loadSalesHistoryObject.Remark = DetailsData.Remark ? 
        DetailsData.Remark : '';
        loadSalesHistoryObject.LocationId = DetailsData.LocationId ?
        DetailsData.LocationId : null;
        loadSalesHistoryObject.PlanTypeId = DetailsData.PlanTypeId ?
        DetailsData.PlanTypeId : null;
        loadSalesHistoryObject.StartDate = DetailsData.StartDate ?
        getUTCTimeFormat(DetailsData.StartDate) : '';
        loadSalesHistoryObject.EndDate = DetailsData.EndDate ?
        getUTCTimeFormat(DetailsData.EndDate) : '';
        loadSalesHistoryObject.IsActive = DetailsData.IsActive ? 
        DetailsData.IsActive : false;
        loadSalesHistoryObject.PlanCreationFactorId = DetailsData.PlanCreationFactorId ?
        DetailsData.PlanCreationFactorId : null;
        loadSalesHistoryObject.HistoryStartDate = PopUpData.HistoryStartDate ? 
        getUTCTimeFormat(PopUpData.HistoryStartDate) : null;
        loadSalesHistoryObject.HistoryEndDate = PopUpData.HistoryEndDate ?
        getUTCTimeFormat(PopUpData.HistoryEndDate) : null;
        loadSalesHistoryObject.PlanPeriodId = DetailsData.PlanPeriodId ? 
        DetailsData.PlanPeriodId : null;
        loadSalesHistoryObject.PlanPeriodLengthId = DetailsData.PlanPeriodLengthId ? 
        DetailsData.PlanPeriodLengthId : null;
        loadSalesHistoryObject.StartRDAAgeGroupId = null;
        loadSalesHistoryObject.EndRDAAgeGroupId = null;
        loadSalesHistoryObject.MealPeriodId = DetailsData.MealPeriodId ?
        DetailsData.MealPeriodId : null;
        loadSalesHistoryObject.Valued = DetailsData.Valued ? 
        DetailsData.Valued : null;
        loadSalesHistoryObject.LinkPlanId = null;
        loadSalesHistoryObject.AllowAutoReq = DetailsData.AllowAutoReq ?
        DetailsData.AllowAutoReq : null;
        loadSalesHistoryObject.PlanColumnId = null;
        loadSalesHistoryObject.AllowExports = DetailsData.AllowExports ?
        DetailsData.AllowExports : false;
        loadSalesHistoryObject.ProductionLoctionId = null;
        loadSalesHistoryObject.TransactionStatusId = DetailsData.TransactionStatusId ?
        DetailsData.TransactionStatusId : null;
        loadSalesHistoryObject.PlanVisibility = false;
        loadSalesHistoryObject.LocationIds = PopUpData.LocationIds ? 
        PopUpData.LocationIds : [];
        loadSalesHistoryObject.MealPeriodsIds = PopUpData.MealPeriodsIds ? 
        PopUpData.MealPeriodsIds : [];
        loadSalesHistoryObject.PlanAverageFormulaId = PopUpData.PlanAverageFormulaId ?
        PopUpData.PlanAverageFormulaId : null;
        loadSalesHistoryObject.IsRoundUp = PopUpData.IsRoundUp ?
        PopUpData.IsRoundUp : false;
        loadSalesHistoryObject.IsAlignDay = PopUpData.IsAlignDay ?
        PopUpData.IsAlignDay : false;
        loadSalesHistoryObject.IsRestaurantSales = PopUpData.RestaurantSales.RestaurantSales ?
        PopUpData.RestaurantSales.RestaurantSales : false;
        loadSalesHistoryObject.IsCateringSales = false;
        loadSalesHistoryObject.IsConcessionSales = false;
        loadSalesHistoryObject.IsQuickOrders = false;
        const PostData = Object.assign({}, loadSalesHistoryObject);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Load Sales History Data Construction', error);
    }
};

// function getUTCTimeFormat(dateValue: any) {
//     const currentStart = new Date(dateValue);
//     const currentDate = new Date();
//     const utcStartDate = new Date(
//           currentStart.getFullYear(),
//           currentStart.getMonth(),
//           currentStart.getDate(),
//           currentDate.getHours(),
//           currentDate.getMinutes(),
//           currentDate.getSeconds()
//     );
//     const returnValue = moment
//           .utc(utcStartDate)
//           .format();
//     return returnValue;
// }

    function getUTCTimeFormat(dateValue: any) {
        const date = moment(dateValue);
        if (!date.isValid()) return dateValue;
        const returnValue = date.startOf('day').format(constants.PostDateTimeFormat);
        return returnValue;
    }