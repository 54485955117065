import { MasterManagementAuthorizedConfig } from './ui-control-config.interface';
import { AuthorizationSubModule, AuthorizedDataModel, AuthorizedGroupModel, 
MasterAuthenticationParams, PermissionResource, UiControlConfiguration, UiControls } from './ui-controls.interface';
// import * as AuthSample from '../../../../assets/json/ui-control/authoriztion-sample.json';
import { AuthorizationModule, AuthoriztionKey } from './authorization.enum';
import { FormBuilderGroupKey, MasterBreadCrumbConfig, MasterInventoryManagement, SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { SecondaryHeader } from '../secondary-header.interface';
import { AgilysysButtonModel } from 'src/app/eatecui/source/agilysys-buttons/models/agilysys-buttons.interface';
import { InventoryDefaultSelect } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { filter } from 'rxjs/operators';

export const MasterManagementAuthorization: MasterManagementAuthorizedConfig = (
  masterInventoryManagement: Array<MasterInventoryManagement>, AuthorizedData: Array<AuthorizedDataModel>,
  MasterHeader?: SecondaryHeader, masterAuthenticationParams ?: MasterAuthenticationParams, MasterAuthFrom: string = 'viewauth'): any => {
  try {
    const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
    const authSample = null;
    // eslint-disable-next-line max-len
    const MasterAuthConfig = ( MasterAuthFrom === 'viewauth' ) ? 'ViewFormConfig' : 'EditFormConfig';
    const AuthorizedDataResult: Array<AuthorizedDataModel> = (AuthorizedData && AuthorizedData !== null) ? AuthorizedData : 
    ( AuthorizationData && AuthorizationData !== null ) ? AuthorizationData : authSample;
    if (masterInventoryManagement && AuthorizedDataResult && AuthorizedDataResult.length > 0) {
      // eslint-disable-next-line max-len
      const authDataModel: Array<AuthorizedDataModel> = AuthorizedDataResult.filter((x: AuthorizedDataModel) => x.Name === AuthorizationModule.MASTERMANAGEMENT);
      if (authDataModel && authDataModel.length > 0) {
        authDataModel.forEach((authModel: AuthorizedDataModel) => {
          if (authModel.hasOwnProperty('SubModules') && authModel.SubModules.length > 0) {
            authModel.SubModules.forEach((e: AuthorizationSubModule) => {
              const subModuleUniqueName = e.Name.toString().toLowerCase();
              if (e.hasOwnProperty('PermissionResource') && e.PermissionResource.length > 0) {
                e.PermissionResource.forEach((p: PermissionResource) => {
                  const PermissionRName = p.Name;
                  if (
                    PermissionRName === AuthoriztionKey.VIEWFBINVENTORY ||
                    PermissionRName === AuthoriztionKey.VIEWRETAILINVENTORY ||
                    PermissionRName === AuthoriztionKey.VIEWCUSTOMER ||
                    PermissionRName === AuthoriztionKey.VIEWSUPPLIERS ||
                    PermissionRName === AuthoriztionKey.VIEWFBPRODUCT ||
                    PermissionRName === AuthoriztionKey.VIEWRETAILPRODUCT ||
                    PermissionRName === AuthoriztionKey.VIEWRECIPE
                  ) {
                    ViewAuthMasterManagement(p, masterInventoryManagement, 
                    e.Name, MasterHeader, masterAuthenticationParams, MasterAuthConfig);
                  }
                  if (
                    PermissionRName === AuthoriztionKey.CREATEFBINVENTORY ||
                    PermissionRName === AuthoriztionKey.CREATERETAILINVENTORY ||
                    PermissionRName === AuthoriztionKey.CREATECUSTOMER ||
                    PermissionRName === AuthoriztionKey.CREATESUPPLIER ||
                    PermissionRName === AuthoriztionKey.CREATEFBPRODCUT ||
                    PermissionRName === AuthoriztionKey.CREATERETAILPRODUCT ||
                    PermissionRName === AuthoriztionKey.CREATERECIPE
                  ) {
                    CreateAuthMasterManagement(p, masterInventoryManagement, e.Name, MasterHeader, MasterAuthConfig, authModel.SubModules);
                  }
                  if (
                    p.Name === AuthoriztionKey.REPLACEBASEUNIT || 
                    p.Name === AuthoriztionKey.REPLACEITEM ||
                    p.Name === AuthoriztionKey.DEFINEPRODUCT || 
                    p.Name === AuthoriztionKey.DEFINEFINISHEDGOODS
                    ) {
                    MasterEllipseAuthMasterManagement(p, masterInventoryManagement, e.Name, MasterHeader, authModel.SubModules);
                  }

                  if (
                    PermissionRName === AuthoriztionKey.REACTIVEDEACTIVEFB ||
                    PermissionRName === AuthoriztionKey.REACTIVEDEACTIVERETAIL ||
                    PermissionRName === AuthoriztionKey.REACTIVATEDCUSTOMER ||
                    PermissionRName === AuthoriztionKey.REACTIVATESUPPLIER ||
                    PermissionRName === AuthoriztionKey.REACTIVEPRODUCTFB ||
                    PermissionRName === AuthoriztionKey.REACTIVATERETAILPRODUCT ||
                    PermissionRName === AuthoriztionKey.ACTIVATEDEACTIVATERECIPE
                    ) {
                    ActiveDeactiveAuthMasterManagement(p, masterInventoryManagement, e.Name, MasterHeader);
                  }
                  if (subModuleUniqueName === AuthoriztionKey.NUTRITION ||
                      subModuleUniqueName === AuthoriztionKey.SUPPLIERBIDS ||
                      subModuleUniqueName === AuthoriztionKey.TRANSACTIONTAB ||
                      subModuleUniqueName === AuthoriztionKey.CROSSREFERENCETAB ||
                      subModuleUniqueName === AuthoriztionKey.HISTORYTAB ||
                      subModuleUniqueName === AuthoriztionKey.PRICINGTAB) {
                    TabAuthMasterManagement(p, masterInventoryManagement, subModuleUniqueName, MasterHeader, MasterAuthConfig);
                  }
                  // Added this block becoz wizard not coming as submodule it is one of permission resource for retail inventory
                  if (PermissionRName === AuthoriztionKey.WIZARD) {
                    WizardAuthMasterManagement(p, masterInventoryManagement, PermissionRName, MasterHeader, MasterAuthConfig);
                  }

                  if ( subModuleUniqueName === AuthoriztionKey.MANAGEPACKAGE ) {
                    ManagePackageAuthMasterManagement(p, masterInventoryManagement, subModuleUniqueName, MasterHeader);
                  } 


                });
              }
            });
          }
        });
      }

      // For Report/Print Authorization
      const authReportDataModel: Array<AuthorizedDataModel> = AuthorizedDataResult.filter((x: AuthorizedDataModel) => x.Name === AuthorizationModule.REPORTSMANGEMENT);
      if (authReportDataModel && authReportDataModel.length > 0) {
        authReportDataModel.forEach((DataModel: AuthorizedDataModel) => {
          if (DataModel && DataModel.hasOwnProperty('SubModules') && DataModel.SubModules.length > 0) {
            // eslint-disable-next-line max-len
            const PrintCheck = DataModel.SubModules.filter(x => x.Name.includes('reports')).filter(e => e.PermissionResource.filter(f => (f.Name === 'Reports') && f.IsActive).length > 0);
            // eslint-disable-next-line max-len
            for (const key in masterInventoryManagement[0].DetailsConfig.DetailActions) {
              if (key && key === 'Print') {
                masterInventoryManagement[0].DetailsConfig.DetailActions[key] = (PrintCheck.length > 0 ) ? true : false;
              }
            }
          }
        });
      }
    }
    return null;
  } catch (error) {
    console.log('error occured EnableGridFilter', error);
  }
};

function MasterEllipseAuthMasterManagement(p: PermissionResource, masterInventoryManagement: Array<MasterInventoryManagement>,
  SubModuleName: string, MasterHeader?: SecondaryHeader, subModuleCollection ?: Array<AuthorizationSubModule>): void {
  try {
    const DModuleName = masterInventoryManagement[0].Name;
    const ModuleName = (masterInventoryManagement[0].hasOwnProperty('SelectedTypeId')) ?
      ( masterInventoryManagement[0].SelectedTypeId === 2 ) ? `retail${DModuleName}` : `fb${DModuleName}` : DModuleName;
      if ( ModuleName.toString().toLowerCase() === SubModuleName.toString().toLowerCase()) {
        masterInventoryManagement[0].NestedFormConfig.ViewFormConfig.forEach((FormConfig: SubFormConfigs) => {
          const authKey = ( FormConfig.hasOwnProperty('AuthorizedKey')) ? FormConfig.AuthorizedKey :  FormConfig.Name;
          if ( authKey.toString().toLowerCase() === p.Name.toString().toLowerCase()) {
            FormConfig.Enable = p.IsActive;
            if ( FormConfig.hasOwnProperty('MultipleAuthorizedKey') && FormConfig.MultipleAuthorizedKey.length > 0 ) {
              if ( subModuleCollection && subModuleCollection.length > 0 ) {
                subModuleCollection.forEach((subModule: AuthorizationSubModule) => {
                  if ( FormConfig.Enable ) {
                  const permissionColl: Array<PermissionResource> = subModule.PermissionResource.filter((pr: PermissionResource) => 
                  (FormConfig.MultipleAuthorizedKey.findIndex(( value: string) => pr.Name === value ) > -1) );
                  if ( permissionColl && permissionColl.length > 0 ) {
                    FormConfig['Enable'] = permissionColl.every((pm: PermissionResource) => pm.IsActive);
                  }
                }
                });
              }
          }
          }
          
        });
      }
  } catch (error) {
    console.error(error);
  }
}


function ViewAuthMasterManagement(p: PermissionResource, masterInventoryManagement: Array<MasterInventoryManagement>,
  SubModuleName: string, MasterHeader?: SecondaryHeader, masterAuthenticationParams ?: MasterAuthenticationParams, 
  MasterAuthConfig ?: string): void {
  try {
    const selectedInventoryType = sessionStorage.getItem('appsetting') ?
                JSON.parse(sessionStorage.getItem('appsetting')).InventoryselectDefault : InventoryDefaultSelect;
    const DModuleName = masterInventoryManagement[0].Name;
    const ModuleName = (masterInventoryManagement[0].hasOwnProperty('SelectedTypeId')) ?
      ( masterInventoryManagement[0].SelectedTypeId === 2) ? `retail${DModuleName}` : `fb${DModuleName}` : DModuleName;
    if ( ModuleName.toString().toLowerCase() === SubModuleName.toString().toLowerCase()) {
      if ( masterAuthenticationParams && masterAuthenticationParams !== null  ) {
        if ( masterAuthenticationParams.ToggleAuthentication && masterAuthenticationParams.IsSingleInventory ) {
           if ( masterAuthenticationParams.SelectedInventoryType !== selectedInventoryType ) {
               masterAuthenticationParams.ViewAuthorization = p.IsActive;
           }
        }
      }
      masterInventoryManagement.forEach(x => {
        if (x.hasOwnProperty('NestedFormConfig') && x.NestedFormConfig) {
          if (x.NestedFormConfig.hasOwnProperty(MasterAuthConfig) &&
           x.NestedFormConfig[MasterAuthConfig].length > 0) {
            x.NestedFormConfig[MasterAuthConfig].forEach(v => {
              if (
                v.Name === AuthoriztionKey.INVNTORYDETAILTAB ||
                v.Name === AuthoriztionKey.SETTINGTAB ||
                v.Name === AuthoriztionKey.LOCATIONTAB ||
                v.Name === AuthoriztionKey.BILLIGTAB || 
                v.Name === AuthoriztionKey.CUSTOMERCONTACTS || 
                v.Name === AuthoriztionKey.SUPCOMMUNICATION ||
                // v.Name === AuthoriztionKey.PUNCHOUTCONFIG ||
                v.Name === AuthoriztionKey.ACCTIVITY  ) {
                v['Enable'] = p.IsActive;
              }
            });
          }
        }
      });
    }
    if ( masterAuthenticationParams && masterAuthenticationParams !== null && 
         masterInventoryManagement[0].hasOwnProperty('SelectedTypeId')  ) {
      if ( masterAuthenticationParams.ToggleAuthentication && !masterAuthenticationParams.IsSingleInventory ) {
         if ( masterAuthenticationParams.SelectedInventoryType === selectedInventoryType ) {
             const typePermission = checkInventoryType(DModuleName, SubModuleName);
             if ( !p.IsActive && typePermission) {
              const ViewoppInventory = ( SubModuleName.toLowerCase() === `retail${DModuleName}`.toString().toLowerCase() ) ? 1 : 2; 
              masterAuthenticationParams.ViewAuthorization = true;
              masterAuthenticationParams.IsSingleInventory = true;
              masterAuthenticationParams.SelectedInventoryType = ViewoppInventory;
              masterAuthenticationParams.SelectedPreferenceInventoryId = ViewoppInventory;
              masterAuthenticationParams.PageTitle = ( ViewoppInventory === 1 ) ? `${DModuleName} - FB` : `${DModuleName} - Retail`;
             }
         }
      }
    }
   
  } catch (error) {
    console.error(error);
  }
}
function TabAuthMasterManagement(p: PermissionResource, masterInventoryManagement: Array<MasterInventoryManagement>,
  SubModuleName: string, MasterHeader?: SecondaryHeader, MasterAuthConfig ?: string): void {
  try {
    const DModuleName = masterInventoryManagement[0].Name;
    const ModuleName = (masterInventoryManagement[0].hasOwnProperty('SelectedTypeId')) ?
      (masterInventoryManagement[0].SelectedTypeId === 2) ? `retail${DModuleName}` : `fb${DModuleName}` : DModuleName;
    if (masterInventoryManagement && ModuleName.toString().toLowerCase() === p.Name.toString().toLowerCase()) {
      masterInventoryManagement.forEach(x => {
        if (x.hasOwnProperty('NestedFormConfig') && x.NestedFormConfig) {
          if (x.NestedFormConfig.hasOwnProperty(MasterAuthConfig) &&
           x.NestedFormConfig[MasterAuthConfig].length > 0) {
            x.NestedFormConfig[MasterAuthConfig].forEach(v => {
              const uniqueModuleName = v.hasOwnProperty('AuthorizedKey') ? v.AuthorizedKey : v.Name;
              if (uniqueModuleName.toString().toLowerCase() === SubModuleName.toString().toLowerCase()) {
                v['Enable'] = p.IsActive;
              }
            });
          }
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
}
function WizardAuthMasterManagement (p: PermissionResource, masterInventoryManagement: Array<MasterInventoryManagement>,
  SubModuleName: string, MasterHeader?: SecondaryHeader, MasterAuthConfig ?: string) {
    try {
      if (masterInventoryManagement) {
        masterInventoryManagement.forEach(x => {
          if (x.hasOwnProperty('NestedFormConfig') && x.NestedFormConfig) {
            if (x.NestedFormConfig.hasOwnProperty(MasterAuthConfig) &&
             x.NestedFormConfig[MasterAuthConfig].length > 0) {
                x.NestedFormConfig[MasterAuthConfig].forEach(v => {
                  const uniqueModuleName = v.hasOwnProperty('AuthorizedKey') ? v.AuthorizedKey : v.Name;
                  if (uniqueModuleName.toString().toLowerCase() === SubModuleName.toString().toLowerCase()) {
                    v['Enable'] = p.IsActive;
                  }
                });
              }
            }
        });
      }
    } catch (error) {
      console.error(error);
    }
}
function ManagePackageAuthMasterManagement(p: PermissionResource, masterInventoryManagement: Array<MasterInventoryManagement>,
  SubModuleName: string, MasterHeader?: SecondaryHeader): void {
  try {
    const DModuleName = masterInventoryManagement[0].Name;
    const ModuleName = (masterInventoryManagement[0].hasOwnProperty('SelectedTypeId')) ?
      (masterInventoryManagement[0].SelectedTypeId === 2) ? `retail${DModuleName}` : `fb${DModuleName}` : DModuleName;
      masterInventoryManagement.forEach(x => {
        x.NestedFormConfig.ViewFormConfig.forEach(v => {
          const NestedAuthKey = ( v.hasOwnProperty('AuthorizedKey') ) ? v.AuthorizedKey : v.Name;
          if (NestedAuthKey.toString().toLowerCase() === p.Name.toString().toLowerCase()) {
            v['Enable'] = p.IsActive;
          }
        });
      });

  } catch (error) {
    console.error(error);
  }
}

function CreateAuthMasterManagement(p: PermissionResource, masterInventoryManagement: Array<MasterInventoryManagement>,
  SubModuleName: string, MasterHeader?: SecondaryHeader, MasterAuthConfig ?: string, authSubModule ?: Array<any> ): void {
  try {
    const selectedInventoryType = sessionStorage.getItem('appsetting') ?
                JSON.parse(sessionStorage.getItem('appsetting')).InventoryselectDefault : InventoryDefaultSelect;
    const DModuleName = masterInventoryManagement[0].Name;
    const ModuleName = (masterInventoryManagement[0].hasOwnProperty('SelectedTypeId')) ?
      (masterInventoryManagement[0].SelectedTypeId === 2) ? `retail${DModuleName}` : `fb${DModuleName}` : DModuleName;
    if (masterInventoryManagement && SubModuleName.toString().toLowerCase() === ModuleName.toString().toLowerCase()) {
      if (MasterHeader && MasterHeader !== null && MasterHeader.hasOwnProperty('ButtonArray') && MasterHeader.ButtonArray.length > 0) {
        MasterHeader.ButtonArray.forEach((buttonModel: AgilysysButtonModel) => {
          if (buttonModel.Name === 'Create') {
            buttonModel.Class = (p.IsActive) ? 'agilysys-button-primary' : 'd-none';
          }
        });
      }
      if (!p.IsActive) {
        for (const key in masterInventoryManagement[0].DetailsConfig.DetailActions) {
          if (key && key !== 'IsActive' && key !== 'Print') {
            masterInventoryManagement[0].DetailsConfig.DetailActions[key] = false;
          }
        }
      }
      if (p.IsActive) {
        for (const key in masterInventoryManagement[0].DetailsConfig.DetailActions) {
          if (key && key !== 'Deactivate' && key !== 'Activate' && key !== 'IsActive' && key !== 'PrintLabel') {
            masterInventoryManagement[0].DetailsConfig.DetailActions[key] =
              (masterInventoryManagement[0].DetailsConfig.DetailActions.Activate) ? false : true;
          }
        }
      }
      if ( masterInventoryManagement[0].NestedFormConfig.EditFormConfig ) {
        masterInventoryManagement[0].NestedFormConfig.EditFormConfig.forEach((subFormConfig: SubFormConfigs) => {
          if (subFormConfig.Name === 'SupplierBids') {
            const checkAganistModel = (DModuleName === 'Inventory') ? ['suppliers'] : ['fbinventory', 'retailinventory'];
            let AddNewCheck = false;
            // eslint-disable-next-line max-len
            const checkAddNew = authSubModule.filter(x => checkAganistModel.findIndex(g => x.Name === g) > -1).filter(e => e.PermissionResource.
              filter(f => (f.Name.toString().toLowerCase() === `Create${e.Name}`.toString().toLowerCase()) && f.IsActive).length > 0);
            const FBInventory = authSubModule.filter(x => x.Name === 'fbinventory')[0].PermissionResource;
            const EnableAddFBButton = FBInventory.filter(z => (z.Name === 'CreateFBInventory' || z.Name === 'ViewFBInventory')
              && z.IsActive).length === 2;
            const RetailInventory = authSubModule.filter(x => x.Name === 'retailinventory')[0].PermissionResource;
            const EnableAddRetailButton = RetailInventory.filter(z => (z.Name === 'CreateRetailInventory' || z.Name === 'ViewRetailInventory')
              && z.IsActive).length === 2;
            AddNewCheck = EnableAddFBButton || EnableAddRetailButton;
            if (subFormConfig.hasOwnProperty('AddNewListConfig')) {
              if (subFormConfig.AddNewListConfig.hasOwnProperty('AddNewInventory') &&
                subFormConfig.AddNewListConfig.AddNewInventory.hasOwnProperty('EnableAddNewInventory')) {
                subFormConfig.AddNewListConfig.AddNewInventory.EnableAddNewInventory = (checkAddNew.length > 0) ? true : false;
                if (EnableAddFBButton && EnableAddRetailButton) {
                  subFormConfig.AddNewListConfig.AddNewInventory['InventoryType'] = selectedInventoryType;
                  subFormConfig.AddNewListConfig.AddNewInventory.SystemPreference = 3;
                } else if (AddNewCheck) {
                  subFormConfig.AddNewListConfig.AddNewInventory['InventoryType'] = EnableAddRetailButton ? 2 : 1;
                  subFormConfig.AddNewListConfig.AddNewInventory.SystemPreference = EnableAddRetailButton ? 2 : 1;
                }
              }
            }
          }
        });
      }

      if (masterInventoryManagement[0].Key === 'Recipes') {
        const checkAganistModel = (DModuleName === 'Inventory') ? ['suppliers'] : ['fbinventory', 'retailinventory'];
            let AddNewCheck = false;
            // eslint-disable-next-line max-len
            const checkAddNew = authSubModule.filter(x => checkAganistModel.findIndex(g => x.Name === g) > -1).filter(e => e.PermissionResource.
              filter(f => (f.Name.toString().toLowerCase() === `Create${e.Name}`.toString().toLowerCase()) && f.IsActive).length > 0);
            const FBInventory = authSubModule.filter(x => x.Name === 'fbinventory')[0].PermissionResource;
            const EnableAddFBButton = FBInventory.filter(z => (z.Name === 'CreateFBInventory' || z.Name === 'ViewFBInventory')
              && z.IsActive).length === 2;
            const RetailInventory = authSubModule.filter(x => x.Name === 'retailinventory')[0].PermissionResource;
            const EnableAddRetailButton = RetailInventory.filter(z => (z.Name === 'CreateRetailInventory' || z.Name === 'ViewRetailInventory')
              && z.IsActive).length === 2;
            AddNewCheck = EnableAddFBButton || EnableAddRetailButton;
            if (masterInventoryManagement[0].AddItemList.hasOwnProperty('AddNewListConfig')) {
              if (masterInventoryManagement[0].AddItemList.AddNewListConfig.hasOwnProperty('AddNewInventory') &&
                masterInventoryManagement[0].AddItemList.AddNewListConfig.AddNewInventory.hasOwnProperty('EnableAddNewInventory')) {
                masterInventoryManagement[0].AddItemList.AddNewListConfig.AddNewInventory.EnableAddNewInventory = (checkAddNew.length > 0) ? true : false;
                if (EnableAddFBButton && EnableAddRetailButton) {
                  masterInventoryManagement[0].AddItemList.AddNewListConfig.AddNewInventory['InventoryType'] = selectedInventoryType;
                  masterInventoryManagement[0].AddItemList.AddNewListConfig.AddNewInventory.SystemPreference = 3;
                } else if (AddNewCheck) {
                  masterInventoryManagement[0].AddItemList.AddNewListConfig.AddNewInventory['InventoryType'] = EnableAddRetailButton ? 2 : 1;
                  masterInventoryManagement[0].AddItemList.AddNewListConfig.AddNewInventory.SystemPreference = EnableAddRetailButton ? 2 : 1;
                }
              }
            }
      }
    }
    if ( masterInventoryManagement[0].hasOwnProperty('SelectedTypeId') && 
        SubModuleName.toString().toLowerCase().includes(DModuleName.toString().toLowerCase()) ) {
      if ( authSubModule && authSubModule.length > 0 ) {
        const res = authSubModule.filter(x => x.Name.toString().toLowerCase()
        .includes(DModuleName.toString().toLowerCase())).filter(e => e.PermissionResource.
        filter(f => ( f.Name === `CreateRetail${DModuleName}` || f.Name === `CreateFB${DModuleName}`) && !f.IsActive).length > 0 );
        masterInventoryManagement[0].FormConfig.CustomFormBuilder.forEach(x => {
          if ( x.CustomBuilderName === 'intialform') {
             x.CustomBuilderKey.forEach( (Builder: FormBuilderGroupKey) => {
                 if ( Builder.OdataKey === 'InventoryType' ||  Builder.OdataKey === 'ProductsType') {
                   Builder.Hide = ( res.length > 0 ) ? true : false;
                 }
             });
          }
       });

      }
    }
  } catch (error) {
    console.error(error);
  }
}
function ActiveDeactiveAuthMasterManagement(p: PermissionResource, masterInventoryManagement: Array<MasterInventoryManagement>,
  SubModuleName: string, MasterHeader?: SecondaryHeader): void {
  try {
    if (masterInventoryManagement && p) {
      const DModuleName = masterInventoryManagement[0].Name;
      const ModuleName = (masterInventoryManagement[0].hasOwnProperty('SelectedTypeId')) ?
        (masterInventoryManagement[0].SelectedTypeId === 2) ? `retail${DModuleName}` : `fb${DModuleName}` : DModuleName;
      if (SubModuleName.toString().toLowerCase() === ModuleName.toString().toLowerCase()) {
        const getActiveState = masterInventoryManagement[0].DetailsConfig.DetailActions['IsActive'];
        for (const key in masterInventoryManagement[0].DetailsConfig.DetailActions) {
          if (key) {
            if (!p.IsActive) {
              if (key && (key === 'Deactivate' || key === 'Activate')) {
                delete masterInventoryManagement[0].DetailsConfig.DetailActions[key];
              } else if (key !== 'IsActive' && !getActiveState) {
                masterInventoryManagement[0].DetailsConfig.DetailActions[key] = p.IsActive;
              }
            }
            // (masterInventoryManagement[0].DetailsConfig.DetailActions.hasOwnProperty('Edit') &&
            //   masterInventoryManagement[0].DetailsConfig.DetailActions['Edit']) &&
            if (p.IsActive) {
              masterInventoryManagement[0].DetailsConfig.DetailActions['Deactivate'] = (getActiveState) ? true : false;
              masterInventoryManagement[0].DetailsConfig.DetailActions['Activate'] = (getActiveState) ? false : true;
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function checkInventoryType( ModuleName: string, SubModuleName: string ): boolean {
  try {
    const allowedPermission = [ `retail${ModuleName}`.toString().toLowerCase(), `fb${ModuleName}`.toString().toLowerCase()]
   .some(arrVal => SubModuleName.toString().toLowerCase() === arrVal);
    return allowedPermission;
  } catch ( error ) {
    console.error(error);
  }
}



