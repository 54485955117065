import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import { Gender, Host, SPAManagementBreakPoint } from '../../../../shared/globalsContant';
import { BaseResponse } from '../../../../shared/business/shared.modals';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-preferences',
  templateUrl: './client-preferences.component.html',
  styleUrls: ['./client-preferences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientPreferencesComponent implements OnInit {

  captions: any;
  clientCaptions: any;
  services: any = [];
  therapists: any = [];
  showAddServiceOptions: boolean = false;
  showAddTherapistOptions: boolean = false;
  tempServices: any;
  tempTherapists: any[];
  genderPreference: boolean = false;
  male: boolean;
  female: boolean;
  servicePreference: boolean;
  therapistPreference: boolean;
  preferenceFormGroup: UntypedFormGroup;
  servicedataRecord: any;
  therapistRecord: any;
  checkallBool: boolean = false;
  serviceallCheck: boolean = false;
  serviceallTherapistCheck: boolean = false;
  checkallTherapist: boolean = false;
  ClientEditData: any;
  clientPreference: any;
  servicePreferences: any = [];
  therapistPreferences: any = [];
  clientPreferences: any = [];
  messageForWarning: string;
  clientId: number;
  common: any;
  gender:any;
  clientWindowConvertion : Subscription;
  isFirstTime: boolean = true;
  showClose: boolean = true;
  isGuestRecordPurged: boolean = false;
  checked: boolean =false;

  @Input("IsTherapistServiceLoaded")
  set serviceTherapistPreference(value: boolean){
    if(value){
      this.LoadServices();
      this.LoadTherapists();
    }
  }
  @Input('changedClientData')
  set changeClientData(value){
    if(value){
      this.initializeFormData();
    }
  }
  
  constructor(public appointmentService: AppointmentpopupService, private Form: UntypedFormBuilder, public localization: SpaLocalization,
    private http: HttpServiceCall, public dialog: MatDialog, public dialogRef: MatDialogRef<ClientPreferencesComponent>,
    private utils: SpaUtilities, private breakPoint: BreakPointAccess) {
    this.captions = this.localization.captions.bookAppointment;
    this.common = this.localization.captions.common;
    this.preferenceFormGroup = this.Form.group({
      genderPreference: false,
      gender: '',
      servicePreference: false,
      therapistPreference: false,
    })
    this.appointmentService.preferenceFormGroup = this.preferenceFormGroup;
    this.messageForWarning = this.appointmentService?.allCaptions?.lbl_guestRecordPurged;
    this.isGuestRecordPurged = this.appointmentService?.clientEditData?.clientDetail?.isPurged;
    for(let index =0; index<this.servicePreferences.length;index++)
      this.appointmentService.clientPreferedService.push(this.servicePreferences[index]);
    for(let index =0; index<this.therapistPreferences.length;index++)
        this.appointmentService.clientPreferedTherapist.push(this.therapistPreferences[index]);
  }

  ngOnInit() {
    this.initializeFormData();
  }

  initializeFormData(){
    if (this.appointmentService.clientEditData && this.appointmentService.clientWidowActionType == "EDIT") {
      this.appointmentService.hasAccessForClientPreference =  !this.appointmentService.disablePreference ? this.breakPoint.CheckForAccess([SPAManagementBreakPoint.EditClientPreferences], false) : true;
      this.clientId = this.appointmentService.clientEditData.clientDetail.id;
      // this.preferenceFormGroup.controls.genderPreference.setValue(true);
      // this.preferenceFormGroup.controls.genderPreference.updateValueAndValidity();
      this.checked =this.appointmentService.clientEditData.clientDetail.genderPreference?true:false;
      this.preferenceFormGroup.controls.genderPreference.setValue(this.checked); 
      this.genderPreference=this.checked;
      this.preferenceFormGroup.controls.gender.setValue(this.appointmentService.clientEditData.clientDetail.genderPreference);
      this.clientPreference = this.appointmentService.clientEditData.clientPreferences ? this.appointmentService.clientEditData.clientPreferences : [];

      for (let i = 0; i < this.clientPreference.length; i++) {
        if (this.clientPreference[i].typeName == 'SERVICE') {
          this.servicePreferences.push(this.clientPreference[i]);
        }
        else if (this.clientPreference[i].typeName == 'THERAPIST') {
          this.therapistPreferences.push(this.clientPreference[i]);
        }
      }
      this.appointmentService.clientPreferences = this.clientPreference;
      if(this.appointmentService.isClientViewOnly || this.appointmentService.isPreferenceViewOnly)
      {
        this.utils.disableControls(this.preferenceFormGroup);
      }
    }
    else {
      this.appointmentService.clientPreferences = [];
    }
   
    this.LoadServices();
    this.LoadTherapists();
    this.clientWindowConvertion = this.appointmentService.convertToEdit.subscribe(x =>{
      if(x && x.id > 0 && this.isFirstTime){
        this.isFirstTime = false;
        this.initializeFormData();
      }
    });
    this.isGuestRecordPurged ? this.preferenceFormGroup.disable() : '' ;
  }

  LoadServices() {
    this.services = _.cloneDeep(this.appointmentService.allServices.result);
      for (let i = 0; i < this.services?.length; i++) {
        this.services[i].selected = false;
        for (let j = 0; j < this.servicePreferences.length; j++) {
          if (this.servicePreferences[j].typeId == this.services[i].id) {
            this.services[i].selected = true;
          }
        }
      }
    this.servicedataRecord = this.checkisData(this.services);
  }

  LoadTherapists() {
    this.therapists = _.cloneDeep(this.appointmentService.allTherapists.result);
      for (let i = 0; i < this.therapists?.length; i++) {
        this.therapists[i].selected = false;
        this.therapists[i].name = this.therapists[i].firstName + " " + this.therapists[i].lastName;
        for (let j = 0; j < this.therapistPreferences.length; j++) {
          if (this.therapistPreferences[j].typeId == this.therapists[i].id) {
            this.therapists[i].selected = true;
          }
        }
      }
    this.therapistRecord = this.checkisData(this.therapists);
  }

  ClosePreference() {
    this.dialogRef.close();
    this.appointmentService.disablePreference = false;

  }

  togglePreference(pref, v) {

    if (this.appointmentService.disablePreference || (!this.servicePreference && !this.therapistPreference))
      return;
    let prefferenceArray = (v == "service") ? this.services : this.therapists;
    pref.selected = !pref.selected
    if (v == "service") {
      this.showAddServiceOptions = true;
      this.checkallBool = prefferenceArray.every(x => x.selected);
      this.serviceallCheck = prefferenceArray.every(x => x.selected);
      this.servicedataRecord = this.checkisData(prefferenceArray);
    } else {
      this.showAddTherapistOptions = true;
      this.checkallTherapist = prefferenceArray.every(x => x.selected);
      this.serviceallTherapistCheck = prefferenceArray.every(x => x.selected);
      this.therapistRecord = this.checkisData(prefferenceArray);
    }

  }

  AddServiceOptions() {
    this.showAddServiceOptions = true;
    this.tempServices = _.cloneDeep(this.services);
  }

  AddTherapistOptions() {
    this.showAddTherapistOptions = true;
    this.tempTherapists = _.cloneDeep(this.therapists);
  }

  updateServicePreference() {
    this.showAddServiceOptions = false;
    this.tempServices = [];
    this.servicedataRecord = this.checkisData(this.services);
    let tempPreferences: any = [];
    this.services.forEach(s => {
      if (s.selected )
        tempPreferences.push({
          Id: 0,
          ClientId: this.clientId,
          typeName: 'SERVICE',
          typeId: s.id
        });
    });

    for (let i = 0; i < tempPreferences.length; i++) {
      for (let j = 0; j < this.servicePreferences.length; j++) {
        if (tempPreferences[i].typeId == this.servicePreferences[j].typeId) {
          tempPreferences[i].id = this.servicePreferences[j].id;
        }
      }
    }
    this.servicePreferences = [];
    this.servicePreferences = tempPreferences;
    this.ClientPreferenceData()
  }
  updateTherapistPreference() {

    this.showAddTherapistOptions = false;
    this.tempTherapists = [];
    this.therapistRecord = this.checkisData(this.therapists);
    let tempPreferences: any = [];
    this.therapists.forEach(s => {
      if (s.selected)
        tempPreferences.push({
          Id: 0,
          ClientId: this.clientId,
          typeName: 'THERAPIST',
          typeId: s.id
        });
    });

    for (let i = 0; i < tempPreferences.length; i++) {
      for (let j = 0; j < this.therapistPreferences.length; j++) {
        if (tempPreferences[i].typeId == this.therapistPreferences[j].typeId) {
          tempPreferences[i].id = this.therapistPreferences[j].id;
        }
      }
    }
    this.therapistPreferences = [];
    this.therapistPreferences = tempPreferences;
    this.ClientPreferenceData();
  }

  ClientPreferenceData() {
    this.clientPreferences = [];
    this.servicePreferences.forEach(s => {
      this.clientPreferences.push(s);
    });
    this.therapistPreferences.forEach(s => {
      this.clientPreferences.push(s);
    });
    this.appointmentService.clientPreferences = this.clientPreferences;
  }

  OnChage(eve)
  {
    if(eve.checked ==true)
    {
      this.preferenceFormGroup.controls.gender.setValue(Gender.NoPreference);
    }
    else{
      this.preferenceFormGroup.controls.gender.setValue("");
    }

  }

  cancelServicePreference() {
    this.showAddServiceOptions = false;
    this.services = this.tempServices;
    this.checkallBool = this.services.every(x => x.selected);
    this.serviceallCheck = this.services.every(x => x.selected);
    this.servicedataRecord = this.checkisData(this.services);
  }

  cancelTherapistPreference() {
    this.showAddTherapistOptions = false;
    this.therapists = this.tempTherapists;
    this.checkallTherapist = this.therapists.every(x => x.selected);
    this.serviceallTherapistCheck = this.therapists.every(x => x.selected);
    this.therapistRecord = this.checkisData(this.therapists);
  }
  /**
   * @function checkisData
   * @param array
   * @description Find the array if any checked data
   */

  checkisData(e) {
    let result = e?.find(x => x.selected);
    return (typeof result == "undefined") ? true : false;
  }

  /**
   * @function checkallData
   * @param array
   * @description Find the array if any checked data
   */
  checkallData() {
    if (this.appointmentService.disablePreference)
      return;
    if (!this.serviceallCheck) {
      this.services.map(x => x.selected = true);
      this.checkallBool = this.services.every(x => x.selected);
      this.serviceallCheck = true;
      this.servicedataRecord = this.checkisData(this.services);
    } else {
      this.services.map(x => x.selected = false);
      this.checkallBool = this.services.every(x => x.selected);
      this.serviceallCheck = false;
      this.servicedataRecord = this.checkisData(this.services);
    }
  }

  checkallTherapistData() {
    if (this.appointmentService.disablePreference)
      return;
    if (!this.serviceallTherapistCheck) {
      this.therapists.map(x => x.selected = true);
      this.checkallTherapist = this.therapists.every(x => x.selected);
      this.serviceallTherapistCheck = true;
      this.therapistRecord = this.checkisData(this.therapists);
    } else {
      this.therapists.map(x => x.selected = false);
      this.checkallTherapist = this.therapists.every(x => x.selected);
      this.serviceallTherapistCheck = false;
      this.therapistRecord = this.checkisData(this.therapists);
    }
  }

  // successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
  //   if (callDesc == "GetAllSpaService") {
  //     this.services = <any>result.result;
  //     for (let i = 0; i < this.services.length; i++) {
  //       this.services[i].selected = false;
  //       for (let j = 0; j < this.servicePreferences.length; j++) {
  //         if (this.servicePreferences[j].typeId == this.services[i].id) {
  //           this.services[i].selected = true;
  //         }
  //       }
  //     }
  //     this.servicedataRecord = this.checkisData(this.services);

  //   }
  //   else if (callDesc == "GetAllTherapist") {
  //     this.therapists = <any>result.result;
  //     for (let i = 0; i < this.therapists.length; i++) {
  //       this.therapists[i].selected = false;
  //       this.therapists[i].name = this.therapists[i].firstName + " " + this.therapists[i].lastName;
  //       for (let j = 0; j < this.therapistPreferences.length; j++) {
  //         if (this.therapistPreferences[j].typeId == this.therapists[i].id) {
  //           this.therapists[i].selected = true;
  //         }
  //       }
  //     }
  //     this.therapistRecord = this.checkisData(this.therapists);
  //   }
  // }

  ngOnDestroy() : void {
    if(this.clientWindowConvertion){
      this.clientWindowConvertion.unsubscribe()
    }
    this.isFirstTime = true;
  }

  closeWarning(){
    this.showClose = false;
  }
}


