import { Formatter } from 'src/app/eatecui/source/shared/models/formater.interface';

export const BooleanFormater: Formatter = (gridData: any): any => {
    try {
        const returnValue = gridData.value === true ? 'Yes' : 'No';
        return returnValue;
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  };
