import * as _ from 'lodash';
import { ExitModule } from '../models/module-exit-popup.interface';

export const ModuleExitPopup: ExitModule = (Old_Details: any, Old_GridData ?: Array<any>, New_Details ?: any,
  New_GridData ?: Array<any>, createForm ?: any, PostDeactivate?: any, transactionService?: any): any => {
  try {
    // if (Old_Details && Old_GridData) {
    const OldDetailsKeys = Object.keys(Old_Details);
    const NewDetailsKeys = Object.keys(New_Details);
    const DetailKeys = NewDetailsKeys.filter(value => OldDetailsKeys.includes(value));
    const DetailsMatch = [];
    const MatchKeys = ['Buyer', 'IsTransferOut'];
    if (Object.keys(Old_Details).length > 0 && Object.keys(New_Details).length > 0) {
    DetailKeys.forEach((keys: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      if (MatchKeys.indexOf(keys) > -1) {
        DetailsMatch.push(true);
      } else {
        const DetailsData = JSON.stringify(Old_Details[keys]) === JSON.stringify(New_Details[keys]);
        DetailsMatch.push(DetailsData);
      }
    });
    } else {
      DetailsMatch.push(true);
    }
    const ComparedDetails = DetailsMatch.includes(false); // If false is present in the DetailsMatch, it will return true
    const GridMatch = [];
    if (Old_GridData?.length > 0 && New_GridData?.length > 0) {
    if (Old_GridData.length === New_GridData.length) {
      const OldGridKeys = Object.keys(Old_GridData[0]);
      const NewGridKeys = Object.keys(New_GridData[0]);
      const GridKeys = NewGridKeys.filter(value => OldGridKeys.includes(value));
      for (let i = 0; i < Old_GridData.length; i++) {
      GridKeys.forEach((keys: any) => {
            const ItemGridData = JSON.stringify(Old_GridData[i][keys]) === JSON.stringify(New_GridData[i][keys]);
            GridMatch.push(ItemGridData);
      });
    }
    } else {
      GridMatch.push(false);
    } } else {
      GridMatch.push(true);
    }
    const ComparedItemList = GridMatch.includes(false); // If false is present in the GridMatch, it will return true
    if (ComparedDetails === true || ComparedItemList === true || createForm === true  ||
      (transactionService.transactionConfigurationData &&
      transactionService.transactionConfigurationData.AttachedFilesConfig &&
      transactionService.transactionConfigurationData.AttachedFilesConfig.AttachMentConfig &&
      transactionService.transactionConfigurationData.AttachedFilesConfig.AttachMentConfig.FileAttachedData &&
      transactionService.transactionConfigurationData.AttachedFilesConfig.AttachMentConfig.FileAttachedData.length > 0)) {
      if (PostDeactivate === true)  {
        return false;
      } else {
        if (ComparedDetails === true || ComparedItemList === true || createForm === true) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log('Error occured Conversion Algorithm ', error);
  }
};
