import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { SettingsService } from '../../../settings.service';
import { HttpServiceCall, HttpMethod } from '../../../../shared/service/http-call.service';
import * as GlobalConst from '../../../../shared/globalsContant';
import { SpaUtilities } from '../../../../shared/utilities/spa-utilities';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { BaseResponse, popupConfig } from '../../../../shared/business/shared.modals';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserSettingsComponent implements OnInit {

  caption: any;
  languages: any[] = [];
  accesses: any[] = [];
  selectedAccess: any[] = [];
  minDateValue: any;
  IsReadOnly: boolean;
  userSettingsFormGrp: UntypedFormGroup;
  ActionButton: string;
  userIdPattern;
  placeholderFormat: string;
  commonCaptions: any;
  @Input() popupConfigs:popupConfig;
  floatLabel:string;
  @Input() isADB2CConfigEnabled: boolean;
  isEmailEditUser : boolean = false;
  isToggleDisable : boolean = true;

  constructor(public localization: SpaLocalization, public _servicesetting: SettingsService,
    private http: HttpServiceCall, private utils: SpaUtilities, private PropertyInfo: SpaPropertyInformation) {
      this.placeholderFormat = this.localization.inputDateFormat;
      this.commonCaptions = this.localization.captions.common;
      this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() {
    this.caption = this.localization.captions;
    this.ActionButton = this.caption.setting.save;
    this.userSettingsFormGrp = this._servicesetting.userSettingsFormGrp;
    this.toggleChange(this._servicesetting.userSettingsFormGrp.controls?.newpassword?.value);
    this.IsReadOnly = this._servicesetting.breakpoints.find(bp => bp.breakPointNumber == GlobalConst.SPAScheduleBreakPoint.UserSetup).view;
    if (this.IsReadOnly) {
      this.utils.disableControls(this.userSettingsFormGrp);
    }
    this.minDateValue = this.utils.getDate(this.PropertyInfo.CurrentDate);
    if(this.popupConfigs && this.popupConfigs.operation === 'Edit'){
      this.isToggleDisable = false;
      this.userSettingsFormGrp.controls['pwdexpirationdate'].markAsTouched();
      this.userSettingsFormGrp.controls['userid'].disable();
      this.isEmailEditUser=(this.userSettingsFormGrp.controls['email'].value !=  "" && this.isADB2CConfigEnabled) ? true : false;
    } else {
      this.userSettingsFormGrp.controls['userid'].enable();
    }
    const tenantConfig = await this.GetTenantConfigurationCall('GetTenantConfiguration',{configurationName: 'TENANTCONFIGURATION'});
    this.setUserIdPattern(tenantConfig);
    this.GetServiceCall('GetAllLanguages');
    this.GetServiceCall('GetPropLanguages', { propertyId: this.utils.GetPropertyInfo("PropertyId") });
    this.GetServiceCall('GetStandAloneProducts');
    this.GetRetailServiceCall('GetSubPropertyAccessByUser', { userId: this.utils.GetPropertyInfo("UserId") });
  }

  ButtonToggle(ga, gv) {
    if (ga.indexOf(gv) == -1) {
      ga.push(gv);
    } else {
      ga.splice(ga.indexOf(gv), 1);
    }
    this.utils.setUserAccessSettings(this.accesses, this._servicesetting.selectedAccess);
    this._servicesetting.isRadioButtonsChange = true;
  }

  isExist(coll, obj) {
    let index = -1;
    if (coll && obj) {
      index = coll.findIndex(x => { return ((x.id && x.id == 0) ? x.id : x) == obj });
    }
    return index;
  }


  GetServiceCall(Route, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetRetailServiceCall(Route, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  async GetTenantConfigurationCall(Route, Uri?): Promise<any> {
    const response = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.authentication,
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
    });
    return response.result;
  }



  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == 'GetAllLanguages') {
      if (result.result) {
        let data = <any>result.result;
        this.languages = data.map(x => { return { id: x.id, name: x.languageName, code: x.languageCode } });
      }
    }
    else if (callDesc == 'GetPropLanguages') {
      if (result.result) {
        let data = <any>result.result;
        this.userSettingsFormGrp.controls.language.setValue(data);
      }
    }
    else if (callDesc == 'GetStandAloneProducts') {
      if (result.result) {
        this._servicesetting.products = <any>result.result;
        let products: number[] = [GlobalConst.Product.SPA];
        this.accesses = this._servicesetting.products.filter(r => products.includes(r.id)).map(x => { return { id: x.id, name: x.productName } });
        this.utils.setUserAccessSettings(this.accesses, this._servicesetting.selectedAccess);
      }
    }
  }


  setUserIdPattern(jsonString) {
    if(jsonString) {
      const tenantConfig = jsonString.configValue;
      if(Object.keys(tenantConfig).length > 0) {
        this.userIdPattern = '^[\\w'+tenantConfig.AllowedSpecialCharacter+']+$';
      }
    }
  }

  toggleChange(eve) {
    if(eve) {
      this.userSettingsFormGrp.controls?.nPassword?.setValidators([Validators.required]);
      this.userSettingsFormGrp.controls?.nPassword?.updateValueAndValidity();
      this.userSettingsFormGrp.controls?.cPassword?.setValidators([Validators.required]);
      this.userSettingsFormGrp.controls?.cPassword?.updateValueAndValidity();
    }
    else {
      this.userSettingsFormGrp.controls?.nPassword?.setValidators(null);
      this.userSettingsFormGrp.controls?.nPassword?.updateValueAndValidity();
      this.userSettingsFormGrp.controls?.cPassword?.setValidators(null);
      this.userSettingsFormGrp.controls?.cPassword?.updateValueAndValidity();
    }
  }

  passwordValidation(){
    if(this.userSettingsFormGrp.controls['nPassword'].value != this.userSettingsFormGrp.controls['cPassword'].value){
      this.userSettingsFormGrp.controls.cPassword.setErrors({ invalid: true });
    } else {
      this.userSettingsFormGrp.controls.cPassword.setErrors(null);
    }
    this.userSettingsFormGrp.updateValueAndValidity();
  }
}
