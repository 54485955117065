import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ViewGridConfiguration } from '../grid-transaction/grid-transaction-config.interface';
import { TransactionConfiguration } from '../transaction.interface';

export const QueryGridTransactionDataConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any, responseData ?: Array<any> ): Array<any> => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                responseData?.forEach( dataobject => {​​​​​​​​​
                    for (const prop in dataobject) {​​​​​​​​​
                        if (prop) {​​​​​​​​​
                            if (dataobject[prop] && dataobject[prop] !== null) {​​​​​​​​​
                                const isArrayprop = Array.isArray(dataobject[prop]);
                                const propType = typeof dataobject[prop];
                                if (propType === 'object' && !isArrayprop) {​​​​​​​​​
                                    // const objProperity = dataobject[prop];
                                    assignNavigationProperity(prop, dataobject[prop], dataobject, ViewHeaderEntity);
                                }​​​​​​​​​
                            }​​​​​​​​​
                        }​​​​​​​​​
                    }   ​​​​​​​​​
                }​​​​​​​​​);
                return responseData;
            } 
            
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

    function assignNavigationProperity (ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
        const entityObject = PrimaryProperity;
        if (
          ViewModelEntityColl &&
          ViewModelEntityColl[0].hasOwnProperty('NavigationProperty') &&
          ViewModelEntityColl[0]['NavigationProperty'].length > 0 ) {
           ViewModelEntityColl[0]['NavigationProperty'].forEach((element: any) => {
             const navProperiperityName = element['$']['Name'].toLowerCase().toString();
             if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
                 if (element['ReferentialConstraint']) {
                    // const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toString();
                    entityObject[ ProperityName ] = ProperirtyObject['Name'];
                 }
             }
           });
        }
        return entityObject;
     }