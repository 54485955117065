import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { Butchery, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const ButcheryConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine): any => {
    try {     
      const ScrollItemCount = sessionStorage.getItem('appsetting') ? 
      JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid : constants.InfiniteScrollItemCount;   
        const orderByColumn = transactionService.
        setSortColumn(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortDirection = transactionService.
        setSortDirection(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortingData = new RequestPagination(orderByColumn, sortDirection, requestEngine.FetchAll,
        requestEngine.LocationIds );
        const requestPayload = sortingData.getPaginationPayload(0, ScrollItemCount);
        const butcheryModel = {} as Butchery;
        if (sysytemPreferenceService && sysytemPreferenceService.globalSystemPreference) {            
            const LoadFromTransactionFilter = sysytemPreferenceService.globalSystemPreference.
              filter(x => x.LookupName === 'LoadFromTransactionsFilter')[0].LookupValue;
            const allowFilter = true;
            if (LoadFromTransactionFilter > 1 && allowFilter) {
                const selectedDays = (LoadFromTransactionFilter - 1) * 14;
                butcheryModel.FromDate = formatDateFilter(moment().subtract((selectedDays - 1), 'days'), 'FromDate');
                butcheryModel.ToDate = formatDateFilter(moment(), 'ToDate');
            }
        }
        delete(requestPayload.userId);
        delete(requestPayload.IsActiveOnly);
        const PostData = Object.assign(requestPayload, butcheryModel);
        return PostData;
    } catch (error) {
        console.error('Error occurred in PlanHeadConstruction', error);
    }
};

function formatDateFilter(DateValue, DateType: string) {
    try {
      // let currentDate;
      // if (DateType === 'FromDate') {
      //   const FromDate = new Date(DateValue);
      //   currentDate = moment.utc([FromDate.getFullYear(), FromDate.getMonth(), FromDate.getDate(), 0, 0, 0]).format();
      // } else {
      //   const ToDate = new Date(DateValue);
      //   currentDate =  moment.utc([ToDate.getFullYear(), ToDate.getMonth(), ToDate.getDate(), 23, 59, 59]).format();
      // }
      // return currentDate;
      let currentDate;
      if (DateType === 'ToDate') {
        currentDate = moment(DateValue).endOf('day').format(constants.PostDateTimeFormat);
      } else {
        currentDate =  moment(DateValue).startOf('day').format(constants.PostDateTimeFormat);
      }
      return currentDate;
    } catch (error) {
      console.error('Error occurred in formatDateFilter', error);

    }
  }