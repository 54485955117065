import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import _ from 'lodash';
import { ZeroQty } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { Validation } from 'src/app/eatecui/source/shared/utilities/data.validator';
export const NoZeroQtyCyclePlanning: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType?: any,
    ModuleKey?: string,
    dialog?: MatDialog,
    transactionCollection?: TransactionConfiguration,
    homeGroup?: FormGroup
): any => {
    try {
        return new Promise<boolean>((resolve, reject) => {
            const enablePost = transactionCollection.CreateTranscationConfig?.ItemsConfig?.CyclePlanItemConfig?.
            CyclePlanDetailMealCovers?.length > 0 ? false : true;
            if (enablePost) {
                resolve(true);
            } else {
                const receivingZeroQuantity = {} as ErrorPopModel;
                receivingZeroQuantity.PopupHeaderContent = 'Info';
                receivingZeroQuantity.PopUpContent = 'Please add atleast one meal.';
                dialog.open(AgilsysErrorPopComponent, {
                    width: '400px',
                    data: receivingZeroQuantity,
                    disableClose: true
                });
                reject(false);
            }
        });
    } catch (error) {
        console.error(error);
    }
};