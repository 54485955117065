import { Injectable } from '@angular/core';
// import { Body } from '@angular/http/src/body';
import { CommonApiRoutes } from '../../common-route';
import { CommonControllersRoutes } from '../../communication/common-route';

import { ComponentsLinkingCommunication } from '../../communication/services/components-linking-communication.service';
import { RetailCommunication } from '../../communication/services/retailcommunication.service';
import { ComponentAccessTypeLink } from '../../components/component-linking-base/component-linking-base.model';
import { PaymentMethod } from '../../shared/retail.modals';

@Injectable({
  providedIn: 'root'
})
export class ComponentsLinkingService {

  constructor(private _componentsLinkingCommunication : ComponentsLinkingCommunication,
              private _retailCommunication: RetailCommunication ) { }

  public getPaymentMethods()
  {
    return this._retailCommunication.getPromise<PaymentMethod[]>({
     route: CommonApiRoutes.PaymentMethodByProduct    
    })
  }

  public getComponentAccessTypeLink(){

    return this._componentsLinkingCommunication.getPromise<ComponentAccessTypeLink[]>({
      route: CommonControllersRoutes.GetComponentAccessTypeLink

    });
  }
  public createComponentAccessTypeLink( componentAccessTypeLink :ComponentAccessTypeLink[]){
    return this._componentsLinkingCommunication.postPromise<boolean>(
      {
        route: CommonControllersRoutes.CreateComponentAccessTypeLink,
        body: componentAccessTypeLink

      }
    );
  }
}
