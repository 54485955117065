// managehttp.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpCancelService } from './httpcancel.service';
import { RetailIntegrationLogService } from 'src/app/retail/shared/service/retail-integrationLog.service';
import { SpaUtilities } from '../utilities/spa-utilities';

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private httpCancelService: HttpCancelService, private loggerService: RetailIntegrationLogService,
    private utils: SpaUtilities) {
  }

  CheckIfItsPMAgentCall(req: HttpRequest<any>): boolean {
    const reqUrl = req.url.toLowerCase();
    const payAgentURI = JSON.parse(sessionStorage.getItem('paymentConfiguration'));
    const v1GiftcardRGuestPayURL = sessionStorage.getItem('v1GiftcardRGuestPayURL');
    const v1GiftcardRGuestPayCardCaptureRoute = 'cardcapture';
    const isRguestPayPooling = (req.urlWithParams.toString().includes(v1GiftcardRGuestPayCardCaptureRoute) && req.method == 'GET');
    const isSkipPMAgentCall = req.urlWithParams.toString().includes("skipLog");
    let payURL: string = "";
    if (payAgentURI && payAgentURI.length > 0) {
      payURL = payAgentURI[0]?.configValue;
    }
    const rGuestPayURLs = [
      v1GiftcardRGuestPayURL + "/device",
      v1GiftcardRGuestPayURL + "/ondemand/cardcapture/device"
    ]
    return (payURL != "" && reqUrl.includes(payURL.toLowerCase()) || (v1GiftcardRGuestPayURL && rGuestPayURLs.some(r => reqUrl.includes(r.toLowerCase())) && !isRguestPayPooling && !isSkipPMAgentCall))
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {

    const isPMAgentCall = this.CheckIfItsPMAgentCall(req);
    let correlationId = this.utils.generateGUID();
    let screenURL = this.router.url;
    const reqUrl = req.url.toLowerCase();
    if (isPMAgentCall) {
      this.loggerService.sendLogData(req.urlWithParams.toString(), screenURL, JSON.stringify(req.body), "Before Response", "CREATE", correlationId);
    }
    const validURLS = (reqUrl.includes('retail') && !reqUrl.includes('getoutletsale')) || reqUrl.includes('report') ||
      reqUrl.includes('bookingdeposits') || reqUrl.includes('tenantmgt') || reqUrl.includes('api/');

    if (!validURLS && !sessionStorage.getItem('_jwt')) {
      this.httpCancelService.cancelPendingRequests();
    }

    return this.loggerService.GetRequestId(isPMAgentCall, this.router.url, req).pipe(
      switchMap(integrationOpLogResponse => {
        const updatedReq = req.clone({
          body: isPMAgentCall ? this.loggerService.SetRequestIdAndTransactionType(req.body, integrationOpLogResponse?.result) : req.body,
        });
        return next.handle(updatedReq).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
          .pipe(tap((event: any) => {
            if (event.status && isPMAgentCall) {
              this.loggerService.sendLogData(req.urlWithParams.toString(), screenURL, JSON.stringify(req.body), JSON.stringify(event.body) + " Status:" + event.status, "UPDATE", correlationId);
            }
          },
          (err: any) => {
            if (isPMAgentCall) {
              this.loggerService.sendLogData(req.urlWithParams.toString(), screenURL, JSON.stringify(req.body), JSON.stringify(err.error) + " Error Message: " + err.message, "UPDATE", correlationId);
            }
          }),
          catchError(errs => {
            console.log("catchError -->", errs);
            return throwError(errs);
          }));
      }));
  }

}