import { Injectable, Injector } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserAccessService } from './user-access.service';


@Injectable()
export class FacadeService {

  private useraccessservice: UserAccessService;
  public get userAccessService(): UserAccessService {
    if (!this.useraccessservice) {
      this.useraccessservice = this.injector.get(UserAccessService);
    }
    return this.useraccessservice;
  }
  constructor(private injector: Injector) { }

  getUserAccess(breakPointNumber: number, showUserMessage: boolean = true, callBack?: any) {
    return this.userAccessService.getUserAccess(breakPointNumber, showUserMessage, callBack);
  }

  getUserAccesses(breakPointNumbers: number[], showUserMessage: boolean = true, callBack?: any) {
    return this.userAccessService.getUserAccesses(breakPointNumbers, showUserMessage, callBack);
  }

  getUserAccessDetails(breakPointNumber: number, showUserMessage: boolean = true, callBack?: any) {
    return this.userAccessService.getUserAccessDetails(breakPointNumber, showUserMessage, callBack);
  }

  disableControls(FormGroups: UntypedFormGroup, excludedControls?: string[]) {
    this.userAccessService.disableControls(FormGroups, excludedControls);
  }
  ensableControls(FormGroups: UntypedFormGroup, excludedControls?: string[]) {
    this.userAccessService.enableControls(FormGroups, excludedControls);
  }
}
