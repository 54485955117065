import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { GridType } from 'src/app/shared/globalsContant';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { result } from 'lodash';
import { BaseResponse } from 'src/app/shared/business/shared.modals';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { EmptyValueValidator } from 'src/app/shared/Validators/EmptyValueValidator';
@Component({
  selector: 'app-lost-denial-reasons-setup',
  templateUrl: './lost-denial-reasons-setup.component.html',
  styleUrls: ['./lost-denial-reasons-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LostDenialReasonsSetupComponent implements OnInit {

  lostDenialForm: UntypedFormGroup;
  captions: any;
  addButton: ButtonValue;
  cancelButton: ButtonValue;
  floatLabel: string;
  searchText: string = '';
  tableoptions: any;
  tableDataTemp;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isEdit: boolean = false;
  isAddDisable :boolean = false;
  currentId :number =0;
  searchTempData:string;
  onEditDisableFunction: boolean = true;
  constructor(private localization: SpaLocalization, private fb: UntypedFormBuilder, private http: HttpServiceCall,private utils: SpaUtilities) {
    this.captions = this.localization.captions;
    console.log(this.captions.btn_add)
    this.floatLabel = this.localization.setFloatLabel;

  }

  ngOnInit() {
    this.initializeForm();
    this.buttonInitialize();
    this.bindtoGridData();
  }

  initializeForm() {
    this.lostDenialForm = this.fb.group({
      lostdenialinput: ['', [Validators.required, EmptyValueValidator]],
      showActive: false,
      toggleActive: ''
    })
    this.lostDenialForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      // if(this.lostDenialForm.controls['lostdenialinput'].value.length >=3){
      this.addButton.disabledproperty = !(this.lostDenialForm.valid && this.lostDenialForm.dirty && this.lostDenialForm.value.lostdenialinput.length >= 3);
      this.addButton = { ...this.addButton };
      // }
      // else{
      //   this.addButton.disabledproperty = true;
      //   this.addButton = { ...this.addButton };
      // }     
    })
  }

 async bindtoGridData(formvalue:any=null,includeinactive:boolean=false,searchByLost:boolean=false){
    let arrDataHeader = [
      { 'title': this.captions.tbl_hdr_lostdenial, 'jsonkey': 'reasonCode', 'searchable': true, 'sortable': true, 'Sortable': 'reasonCode' },
      { 'title': this.captions.lbl_active, 'jsonkey': 'isActive', 'searchable': false, 'sortable': true, "type": "toggle" },
    ]
    if(formvalue==null&&searchByLost==false){
      this.tableDataTemp = await this.getLostDeniedReason(includeinactive);
    }
    else if(searchByLost==true){
        this.searchTempData =this.tableDataTemp.filter(t=> t.reasonCode.toLowerCase().includes(this.searchText.toLowerCase()));
    }
    else{
      this.tableDataTemp = formvalue;
    }
    let showInactiveTogglevalue = this.lostDenialForm.controls.showActive.value;
    if (!showInactiveTogglevalue) {
      this.tableDataTemp = this.tableDataTemp.filter(t => t.isActive);
    }
    
    if(this.searchText!=''&&searchByLost==false)
   {
    this.searchTempData=this.tableDataTemp.filter(t=> t.reasonCode.includes(this.searchText));
   }
   
    this.tableoptions = [
      {
          TableHdrData: arrDataHeader,
          TablebodyData: this.searchText!=''?this.searchTempData:this.tableDataTemp,
          pagination: false,
          sortable: true,
          editable: true,
          CustomColumn: true,
          PlaceHoldertext: this.captions.WaitListSearchPlaceHolder,
          EnableActions: true,
          SelectRows: false,
          Searchable: false,
          EditMoreOption: false,
          SelectedSettingId: GridType.lostDeniedreason,
          Sortable: 'reasonCode',
          TableId: '',
          disableDelete: false,
          SelectRow: true,
          SelectOnlyRow: true,
          dropdownOptions: '',
          waitlistIcon: false,
          TableDraggable: false,
          sticky: true,
          DoneCancel: false
      }
  ];
  }

  async getLostDeniedReason(includeinactive:boolean){
    const results = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllLostDeniedReasons",
      method: HttpMethod.Get,
      uriParams:{"IncludeInactive":includeinactive}
    });
    return results.result;
  }

  async createorUpdateLostDeniedReason() {
    const lostDeniedReason: any = {
      reasonCode: this.lostDenialForm.controls.lostdenialinput.value,
      isActive: this.isEdit ? this.lostDenialForm.controls.toggleActive.value : true
    };
    let saveorUpdateStatus;
    if (this.isEdit) {
      saveorUpdateStatus = await this.http.CallApiAsync<any[]>({
        host: GlobalConst.Host.spaManagement,
        callDesc: "UpdateLostDeniedReasons",
        method: HttpMethod.Put,
        body: lostDeniedReason,
        uriParams: { "id": this.currentId }
      });
    } else {
      saveorUpdateStatus = await this.http.CallApiAsync<any[]>({
        host: GlobalConst.Host.spaManagement,
        callDesc: "CreateLostDeniedReasons",
        method: HttpMethod.Post,
        body: lostDeniedReason
      });
    }

    return saveorUpdateStatus.result;
  }

  async UpdateLostDeniedStatus(inputdata) {
    const lostDeniedReason: any = {
      reasonCode: inputdata.reasonCode,
      isActive: inputdata.isActive
    };
     await this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "UpdateLostDeniedReasons",
      method: HttpMethod.Put,
      body: lostDeniedReason,
      uriParams: { "id": inputdata.id },
      success: this.successCallback.bind(this),
      error : this.errorCallback.bind(this),
      extraParams:[]
    });
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    this.bindtoGridData(result.result);  
    this.isAddDisable = false;
  }

  errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(result);
    this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(result.errorCode));
    this.lostDenialForm.get('showActive').setValue(false);
    this.bindtoGridData();
    this.ResetForm();
}
  buttonInitialize() {
    this.addButton = {
      type: 'primary',
      label: this.captions.btn_add,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }
  CancelEvent(event) {
    this.ResetForm();
    this.isAddDisable = false;
  }

  async onSave(event) {
    let createorupdateresult = await this.createorUpdateLostDeniedReason();
    if (createorupdateresult){
      this.bindtoGridData(createorupdateresult);  
      this.isEdit = this.isEdit?!this.isEdit : false;   
      this.ResetForm();
    }   
  }

  onCancel(){
    this.isEdit = !this.isEdit;
    this.onEditDisableFunction = true;
    this.ResetForm();
  }

  ResetForm(){

    this.addButton.label = this.captions.btn_add;
    this.addButton.disabledproperty = true;
    this.addButton = {...this.addButton};
    let showActivevalue = this.lostDenialForm.controls.showActive.value;
    this.lostDenialForm.reset();
    this.lostDenialForm.get('showActive').setValue(showActivevalue);
    this.currentId =0;
    this.isAddDisable = false;
  }

  onToggleChange(event){
    this.bindtoGridData(null,event[0]);
  }

  searchByLostDenial(event){
    this.searchText = event;
    this.bindtoGridData(null,null,true);

  }

  EditRecords(event){
    console.log(event);
    this.isEdit = true;
    this.addButton.label = this.captions.btn_edit;
    this.addButton.disabledproperty = true;
    this.addButton = {...this.addButton};
    this.lostDenialForm.get('lostdenialinput').setValue(event[0].reasonCode);
    this.lostDenialForm.get('toggleActive').setValue(event[0].isActive);
    this.currentId = event[0].id;
  }

  async DeleteConfirm(event){
    console.log(event)
    let result = await this.DeleteLostDeniedReason(event[0].id);
    this.bindtoGridData(result);  
  }

  async DeleteLostDeniedReason(id:number){
    let deletestatus = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "DeleteLostDeniedReasons",
      method: HttpMethod.Delete,
      uriParams: {"id":id}
    });
    return deletestatus.result;
  }

  sliderChange(eve){

  }

  inactiveSliderChange(eve){
    console.log(eve);
    this.isAddDisable = true;
  }

  async DoneEvent(event){
    console.log(event);
    let result = await this.UpdateLostDeniedStatus(event);
  }

}
