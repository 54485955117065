import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppointmentpopupService } from '../shared/service/appointmentpopup.service';
import { appointmentService } from '../shared/service/appointment.service';
import { CommonUtilities } from '../common/shared/shared/utilities/common-utilities';
import { PMSSessionData } from './core.model';
import { PMSRequestInfo, PMSAction } from '../common/external-request/pms-request.model';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import *as GlobalConst from '../shared/globalsContant'
import { SpaLocalization } from './localization/spa-localization';
import { CartUtilities } from '../common/components/menu/vcart/cart.utilities';


@Injectable({
  providedIn: 'root'
})
export class PMSEventService {
  routes$: Subscription;
  sessionData: PMSSessionData;
  constructor(private utilities: CommonUtilities, private router: Router,
    private appointmentpopupService: AppointmentpopupService,
    private apptService: appointmentService,
    private breakpoint: BreakPointAccess,
    private cartUtils: CartUtilities,
    private localization : SpaLocalization) {
  }

  async handlePMSEvent() {
    this.preparePMSInputData();
    if (!this.sessionData) {
      return;
    }

    switch (this.sessionData.action) {
      case PMSAction.Reservation:
        if (!this.cartUtils.isEmbed()) {
        this.prepareDataForBooking();
        }
        let hasAppointmentAccess = this.checkPMSAccess(GlobalConst.SPAScheduleBreakPoint.BookAppointment, !this.cartUtils.isEmbed());
        if(hasAppointmentAccess){
            this.routeToPage('/appointment/newappointment');
        }
        break;
      case PMSAction.CancelReservation:
        break;
      case PMSAction.ReInstateReservation:
      case PMSAction.MoveReservation:
      case PMSAction.RescheduleReservation:
        await this.prepareGuestData();
        let hasSearchAccess = this.checkPMSAccess(GlobalConst.SPAScheduleBreakPoint.AppointmentSearch);
        if (hasSearchAccess) {
          this.appointmentpopupService.moveToSearchScreen = true;
          this.apptService.selectedTabIndex = 1;
          this.routeToPage('/appointment/newappointment');
        }
        break;
      case PMSAction.DashBoard:
        this.routeToPage('home/dashboard')
        break;
      default:
        break;
    }
  }


  async checkPMSAccess(breakpoint: GlobalConst.SPAScheduleBreakPoint, redirectToHome: boolean = true) : Promise<boolean> {
    let result = await this.breakpoint.CheckForAccess([breakpoint], true);
    if(!result){        
        if(redirectToHome)
          this.router.navigateByUrl('home');
        return false;
    }
    else {
      return true;
    }
  }

  preparePMSInputData() {
    let requestInfo = this.utilities.getSessionValue<PMSRequestInfo>('PMSRequestInfo');
    if (requestInfo) {
      this.sessionData = {
        action: requestInfo.action,
        gpsId: requestInfo.gpsId,
        activityId: requestInfo.activityId,
        stayFromDate: requestInfo.stayFromDate,
        stayToDate: requestInfo.stayToDate,
        clientInfo: null,
        activityDate: requestInfo.activityDate
      };
      this.utilities.removeSession('PMSRequestInfo');
      this.setSession();
    }
  }

  async prepareDataForBooking() {
    this.sessionData.clientInfo = await this.appointmentpopupService.getPMSGuestInfoForAppointment(this.sessionData.gpsId);
    this.sessionData.clientInfo.clientDetail.pmsActivityId=this.sessionData.activityId;
    this.appointmentpopupService.setPMSGuestInfoForAppointment(this.sessionData.clientInfo);   
    this.setSession();
  }

  async prepareGuestData() {
    this.sessionData.clientInfo = await this.appointmentpopupService.getPMSGuestInfoForAppointment(this.sessionData.gpsId);
    this.setSession();
  }

  private setSession() {
    this.utilities.setSession(GlobalConst.PMSDATA, JSON.stringify(this.sessionData));
  }

  routeToPage(pageurl: string) {
    this.router.navigateByUrl(pageurl);
  }

}


