import { TranslateService } from '@ngx-translate/core';
import { CustomRowData, FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { CustomFormBuild, CustomFormBuildingGrouping, MasterInventoryManagement } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FieldBuilderType } from './form-builder-type.enum';
import { FormModelConfiguration, FormModelCustomBuilder, FormModelGroupBuilder } from './form-config.interface';

export const FormGrouping: FormModelGroupBuilder = (
ViewModelEntity: any, 
PostModelEntity: any, 
MasterDataCollection: MasterInventoryManagement, 
httpService: HttpService, 
CustomGrouping: CustomFormBuildingGrouping, translateService: TranslateService): any => {
    try {
        const customGroupingData = {} as CustomRowData;
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 && CustomGrouping) {
            customGroupingData.customClass = `master-${CustomGrouping.GroupHeader}`;
            customGroupingData.rowHeader = `${CustomGrouping.GroupHeader}`;
            customGroupingData.data = FormInterface.FormFieldGrouping(
            ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, CustomGrouping, translateService);
        }
        return customGroupingData;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};