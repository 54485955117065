import { Directive, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import { detailedDiff } from 'deep-object-diff';
@Directive({
  selector: '[DetectFormValueChange]'
})
export class DetectFormValueChangeDirective  implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  formRawValues: any;
  formCurrentValues: any;
  compareValuesWith: any;
  objectIsEqual: boolean;
  difference: any;
  @Output() formValueChange = new EventEmitter();
  @Output() formStatusChange = new EventEmitter();
  private valueSubscription: Subscription;
  private statusSubscription: Subscription;

  @Input('formGroup')
  set setForm(value: UntypedFormGroup) {
    this.form = value;
  }

  @Input('changeInputValues')
  set changeInputValues(value: any) {
    this.compareValuesWith = value;
  }


  ngOnInit() {
    this.statusSubscription = this.form.statusChanges.subscribe(x => {
      this.formRawValues = this.form.getRawValue();
      this.formStatusChange.emit({
        formValid: this.form.valid,
        objectNotEqual: !this.objectIsEqual,
        formCurrentValues: this.formCurrentValues,
        formRawValues: this.formRawValues,
        compareValuesWith : this.compareValuesWith,
        difference: this.difference
      });
    });
    this.valueSubscription = this.form.valueChanges.subscribe(value => {
        this.formCurrentValues = value;
        this.formRawValues = this.form.getRawValue();
        this.objectIsEqual = isEqual(this.compareValuesWith, this.formRawValues);
        this.difference = detailedDiff(this.compareValuesWith, this.formRawValues);
        this.formValueChange.emit({
          formValid: this.form.valid,
          objectNotEqual: !this.objectIsEqual,
          formCurrentValues: this.formCurrentValues,
          formRawValues: this.formRawValues,
          compareValuesWith : this.compareValuesWith,
          difference: this.difference
        });
    });

  }

  ngOnDestroy() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

}
