import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DecimalRoundOff } from 'src/app/eatecui/source/shared/enum/global.enum';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const CalMarkUpValue: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            const decimalPoint = sessionStorage.getItem('appsetting') ? 
            JSON.parse(sessionStorage.getItem('appsetting')).DecimalPointRoundOff : DecimalRoundOff.SIX;
            const GetCurrentCostValue = FormGroupData.controls['BaseCurrentCost'].value;
            const GetProductPrice = FormGroupData.controls['ProductPrice'].value;
            const markupValue = GetProductPrice && GetCurrentCostValue &&
            parseFloat(GetCurrentCostValue) > 0 && parseFloat(GetProductPrice) > 0 ? 
            (GetProductPrice - GetCurrentCostValue ) / (GetCurrentCostValue / 100) : 0;
            FormGroupData.controls['Markup'].setValue(markupValue ? markupValue.toFixed(decimalPoint) : 0);
            CommonService.triggerBlurEvent('Markup');
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

