import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button/public-api';
import { GridLookUpPopupModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-grid-lookup-popup',
  templateUrl: './agilysys-grid-lookup-popup.component.html',
  styleUrls: ['./agilysys-grid-lookup-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysGridLookupPopupComponent {
  agilysysButtonConfig: AgilysysButtonModel[];
  constructor(public dialogRef: MatDialogRef<AgilysysGridLookupPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GridLookUpPopupModel) { 
    this.data.PopupContent?.ActionButtonConfig?.forEach(button => {
      button.buttonCallBack = (callBackData: CallBackData) => {
        this.dialogRef.close(callBackData.buttonValue.buttonKey);
      }
    })
    this.data.PopupContent?.GridConfig?.GridHeaderDefination?.forEach(header => {
      if (header.type === 'dateColumn') {
        header.valueFormatter = Formaters.dateformater;
      } else if (header.columnType === 'decimal') {
        header.valueFormatter = Formaters.decimalFormater;
      } else if (header.columnType === 'currency') {
        header.valueFormatter = Formaters.currencyFormater;
      }
    });
  }

}
