import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { ButtonType, AlertType } from 'src/app/common/enums/shared-enums';
import { MatButton } from '@angular/material/button';
import { UserErrorMessage } from 'src/app/common/constants';


@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AlertPopupComponent   {
  continueText = this._Localization.captions.alertPopup.continue;
  cancelText = this._Localization.captions.alertPopup.cancel;
  YES = this._Localization.captions.alertPopup.yes;
  NO = this._Localization.captions.alertPopup.no;
  okText = this._Localization.captions.alertPopup.okay;
  success = this._Localization.captions.alertPopup.success;
  warning = this._Localization.captions.alertPopup.warning;
  error = this._Localization.captions.alertPopup.error;
  wellDone = this._Localization.captions.alertPopup.welldone;
  info = this._Localization.captions.alertPopup.info;
  accessDenied = this._Localization.captions.alertPopup.accessDenined;
  confirmation = this._Localization.captions.alertPopup.confirmation;
  done = this._Localization.captions.alertPopup.done;
  RESEV = this._Localization.captions.alertPopup.resev;
  RSV = this._Localization.captions.alertPopup?.rsv;
  ALL = this._Localization.captions.alertPopup.all;
  Caption_User_Notification_ErrorMessage = UserErrorMessage;
  attention = this._Localization.captions.alertPopup.lbl_attention;


  @ViewChild('btnRef') buttonRef: MatButton;
  public alertType = AlertType;
  public buttonType = ButtonType;
  constructor(public dialogRef: MatDialogRef<AlertPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Localization: Localization) {
  }

  ngAfterViewInit() {
    this.buttonRef.focus();
 }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }

  DialogCloseWithCustomAction(result?: any): void {
    this.dialogRef.close(result);
  }

   async copyToClipboard(textToCopy) {
    const textToCopyFormatted = `${textToCopy.referenceIdText} | ${textToCopy.message}`;
       await navigator.clipboard.writeText(textToCopyFormatted);
   }
  }
