import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgRangeSliderComponent } from './ag-range-slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/common/material-module';



@NgModule({
  declarations: [AgRangeSliderComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxSliderModule,
    MaterialModule
  ],
  exports: [AgRangeSliderComponent]
})
export class AgRangeSliderModule { }
