import { Observable } from "rxjs";
export interface CartItineraryUI {
    totalItems?: number;
    expanded: boolean; // Activated Cart
    iscartExpand: boolean;
    menuOptions: { id: number; value: string; }[];
    expirySeconds: number;
    isSearched: boolean;
    isStatusred: boolean;
    name: string;
    id: number;
    confirmationId: string;
    holdSeconds: number;
    lastHoldDateTime: string;
    statusId: CartStatus;
    userId: number;
    transferredUserId: number;
    transferredUserName: string;
    platformItineraryUuid: string;
    primaryGuestUuId: string;
    primaryGuestFirstName: string;
    primaryGuestLastName: string;
    cartDetails: CartDetailUI[];
    cartComments: string;
    primaryGuestId: string;
    guestPhoneNumber: string;
    createdBy: number;
    version: string;
    cartAuthorizeLink?: string;
    cartFolioAuthorizations?: CartFolioAuthorizationsUI[];
    cartNotifications?: CartNotification[];
    travelStartDate: string;
    numberOfNights: number;
    travelEndDate: string;
    itineraryPackageCode: string;
    isMember: boolean;
    memberType: string;
    membershipStatus: string;
    memberLevel: string;
    memberDetails: string;
    memberTypeColorCode: string; // hexcode
    memberLevelColorCode: string // hexcode
    activeCardOnFile: CartFolioAuthorizationsUI
}
export interface CartFolioAuthorizationsUI {
    id: number;
    cartItineraryId: number;
    foloioId: number;
    authorizationSeq: number;
    maxAuthorizedAmount: number;
    cardToken: string;
    cardCode: string;
    cardMasked: string;
    cardExpireDate: string;
    authorizationType: string;
    isActive: boolean;
}

export enum CartWindowMessageType {
    CartDataBroadcast,
    CartItemEdited,
    DataReloadNeeded,
    CartItinerarySelected,
    AddToCart,
    UpdateCart,
    CartItemRemoved,
    CartItineraryRemoved,
    CartItineraryTransfered,
    CartResult,
    IFrameLoaded,
    NewGuestCreateSelected,
    NewGuestCreated,
    RequestNewCard
}
export interface CartDetailUI {
    id: number;
    cartItineraryId: number;
    productId: number;
    cartPayload: CartPayload;
    primaryGuestId: string;
    statusId: number;
    createdBy: number;
    createdDTM: string;
    modifiedBy: number;
    modifiedDTM: string;
    version: string;
    allGuestsNames: string;
    propertyId: number;
}

export enum CartDetailStatus {
    Active = 10,
    Completed = 20,
    Failed = 30,
    Deleted = 40
}

export enum GuestSearchTypes {
    fullname = 0,
    firstName = 1,
    lastName = 2,
    email = 3,
    phoneNumber = 4,
  }
export interface CartWindowMessage {
    type: CartWindowMessageType;
    data: any;
    productId: Number;
    channel: string;
    sender: string;
}

export enum CartItineraryMenuAction {
    Remove = 1,
    Transfer,
    SendPaymentLink,
    ShareToGuest,
    AddCartCommnets,
    PaymentCreditCard
}

export enum CartItineraryType {
    Empty = "Empty",
    Loaded = "Loaded",
    Expired = "Expired"
}

export interface CartItineraryRequest {
    holdTime: number | null;
    cartComments: string;
    firstName: string;
    lastName: string;
    guestId: string;
    guestPhoneNumber: string;
}

// API Model
export interface CartItinerary {
    id: number;
    confirmationId: string;
    holdSeconds: number;
    lastHoldDateTime: string;
    statusId: CartStatus;
    userId: number;
    platformItineraryUuid: string;
    transferredUserId: number;
    transferredUserName: string;
    primaryGuestUuId: string;
    primaryGuestFirstName: string;
    primaryGuestLastName: string;
    cartDetails: CartDetail[];
    cartComments: string;
    primaryGuestId: string;
    createdBy: number;
    createdDTM?: string;
    guestPhoneNumber: string;
    version: string;
    cartAuthorizeLink?: string;
    cartFolioAuthorizations?: CartFolioAuthorization[];
    cartNotifications?: CartNotification[];
    travelStartDate: string;
    numberOfNights: number;
    travelEndDate: string;
    itineraryPackageCode: string;
    isMember: boolean;
    memberType: string;
    membershipStatus: string;
    memberLevel: string;
    memberTypeColorCode: string; // hexcode
    memberLevelColorCode: string; // hexcode
    userName?: string;
}

export interface CartFolioAuthorization {
    id: number;
    cartItineraryId: number;
    foloioId: number;
    authorizationSeq: number;
    authorizationType: string;
    maxAuthorizedAmount: number;
    authorization: string;
    isActive: boolean;
}

export interface CartDetail {
    id: number;
    cartItineraryId: number;
    productId: number;
    propertyId: number;
    cartPayload: string;
    primaryGuestId: string;
    statusId: number;
    createdBy: number;
    createdDTM: string;
    modifiedBy: number;
    modifiedDTM: string;
    version: string;
}

export enum CartStatus {
    Created = 10, //not re
    Completed = 20,
    Failed = 30, //done
    Deleted = 40, //not required
    PartiallyCompleted = 60 //done
}

// add-on models start

export interface CartItem {
    cartPayload: CartPayload;
}

export interface CartMessage {
    channel: string;
    cartPayload: CartPayload;
}

export interface CartPayload {
    productCode: string;
    productId: number;
    tenantId: number;
    propertyId: number;
    userId: number;
    startDate: string;
    endDate: string;
    description: string;
    comments: string;
    activityType: string;
    price: number;
    depositAmount: number;
    guestInfos: GuestInfo[];
    bookAPI: RequestObject;
    holdAPI: RequestObject;
    releaseAPI: RequestObject;
    cardInfos: CardInfo[];
    uiObject?: any;
}

export interface TempHold {
    appointmentId: number;
    holdMinutes: number;
}

export interface GuestInfo {
    platformGuestUuid: string;
    guestId: string;
    firstName: string;
    lastName: string;
    email: ContactInfo[];
    phone: ContactInfo[];
}

export interface ContactInfo {
    isPrivate: boolean;
    isPrimary: boolean;
    value: string;
    type: number;
}

export interface CardInfo {
    tokenId: number;
    last4Digits: string;
    cardType: string;
}

export interface RequestObject {
    verb: string;
    path: string;
    payload: string;
    headers: HeaderValue[];
}

export interface HeaderValue {
    key: string;
    value: string;
}

export interface bookingReponse {
    productId: number,
    cartDetailId: number,
    isSuccess: boolean,
    response: string,
    httpStatusCode: number,
    errorCode: number,
}
export interface CartTempHoldReservationResponse {
    holdSuccess: boolean,
    lastHoldTime: Date,
    holdSeconds: number,
    cartDetailId: number,
    CartItineraryId: number
}

export interface AuthorizeCompleteNotification {
    cartItineraryId: number;
    cartFolioAuthorizations: CartFolioAuthorization[];
}

export interface TransferNotification {
    fromUserId: number;
    fromUserName: string;
    cartItinerary: CartItinerary;
}

export interface TransferCartResult {
    cartItineraryID: number;
    errorCode: number;
}

export enum CartErrorCodes {
    AlreadyTransferred = 100,
    CartItemDateIsHistorical = 200,
    CartItineraryExpired = 300
}


export enum SearchParameters {
    FIRSTNAME = "FIRSTNAME",
    NAME = "NAME",
    LASTNAME = "LASTNAME",
    PHONE = "PHONE",
    EMAIL = "EMAIL"
}
export interface ItineraryCreateRequest {
    guestId: string;
    platformGuestUuid: string;
    firstName: string;
    lastName: string;
    memberIDs?: string[];
    loyaltyIDs?: string[];
    travelStartDate: string;
    numberOfNights: number;
    requestedPackageCode: string;
}

export interface CartNotification {
    id: number;
    notificationType: NotificationType;
    toAddress: string;
    sentOnUtc: string;
    sentBy: number;
    isSuccess: boolean;
}

export enum NotificationType {
    email,
    sms
}



