import { OrderSummaryComponenet } from "../../shop/order-summary/order-summary.component";
import { RetailShopItem } from "../../shop/shop.modals";
import { TransactionDetail } from "../business/shared.modals";
import { CustomFeeSourceType } from "../globalsContant";
import { SelectedProducts } from "../service/common-variables.service";
import { DepositTransactionStatus } from "../service/payment/payment-business.model";

export const retailEventName: string = "retailEvent";

export interface RetailEventParameters<T> {
  eventType: RetailEventType;
  data: T;
  callBack?: (data: T) => void;
}



export enum RetailEventType {
  // Common Events
  Deposit = 1,
  CancelDeposit,
  Void,
  Cancel,
  ReOpen,
  DeleteTransaction,
  ReturnWithTicket,
  Correct,
  SyncUpTransaction,
  CancelOrderSummary,
  LinkRetailItem,
  TeeTimeLinkRetailItem,
  CancellationNoShowCharge,
  WaiveOffCancellationNoShowCharge,
  DayPass,
  ClassCheckOut,
  CancelDayPass,
  UpdateCancellationNoShowCharge,
  TeeTimeCancellation,
  TeeTimeNoShow
}

export interface VoidEventModel {
  transactionId: number;
  isDeposit?: boolean;
  ticketNumber?: string;
  lstTransactionDetailId?:number[]
  sourceType?:CustomFeeSourceType | null
}

export interface TicketChangeEventModel {
  transactionId: number;
  oldTransactionId: number;
  transactionDetail: TransactionDetail[];
  ticketNumber?: string;
  oldTicketNumber?: string;
  returnData?: RetailShopItem[];
}


export interface DepositEventModel {
  id: number;
  typeId: number;
  amount: number;
  gratuity: number;
  serviceCharge: number;
  refundAmount?: number;
  depositTransactionId?: number;
  depositTransactionDetailId?: number;
  refundTransactionId?: number;
  isVoided?: boolean;
  status?: DepositTransactionStatus;
  clientId?: number;
  clientName?: string;
  clienttype?:number;
  clientLinkId?:string;
  sourceType?:CustomFeeSourceType;
  sourceTypeId?:number;
  retailItemId?: number;
  packageGroupId?: string;
}

export interface DayPassEventModel{
transactionId:number;
transactionDetailsIds:any;
sourceTypeId:number;
amount:number;
status:number;
}

export interface CancelDepositEventModel {
  id: number;
  transactionId: number;
  cancelReason: number;
  depositRefundAmount: number;
  cancelFee: any;
  cancelComments: string;
  canPerformCancel?: boolean;
  customFeeSourceType?:CustomFeeSourceType
}
export interface CancelDayPassEventModel {
  id: number;
  transactionId: number;
  cancelReason: number;
  depositRefundAmount: number;
  cancelFee: any;
  cancelComments: string;
  canPerformCancel?: boolean;
  customFeeSourceType?:CustomFeeSourceType
}
export interface CancelDayPassEventModel {
  id: number;
  transactionId: number;
  cancelReason: number;
  depositRefundAmount: number;
  cancelFee: any;
  cancelComments: string;
  canPerformCancel?: boolean;
}

export interface CancellationNoShowChargeEventModel{
  id: number;
  appointmentId: number;
  appointmentDate: string;
  transactionId: number;
  ticketNumber: string;
  cancelReason: number;
  cancelComments: string;
  isCancellation: boolean;
  policyId: number;
  actualAmount: number;
  chargedAmount: number;
  status: CancellationNoShowChargeStatus;
  outletId: number;
}

export interface UpdateCancellationNoShowChargeEventModel{
  sourceTypeId: number;
  transactionId: number;
  isSettle: boolean;
  selecetedProducts?: SelectedProducts[];
  orderSummaryComponentScope?: OrderSummaryComponenet;
}

export enum CancellationNoShowChargeStatus{
  Charged = 1,
  NotCharged,
  WaivedOff,
  PendingSettlement
}

export interface TransactionCompletedModel {
  ticketNumber: number;
  checkNumber: string;
  transactionId: string;
}

export interface IClassCheckOutEventModel{
  classClientId:number,
  transactionId:number,
  transactionDetailId:number,
  tax:number,
  retailTicketNumber:string
}
