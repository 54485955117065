import { Injectable } from '@angular/core';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { PhoneRestriction } from 'src/app/common/shared/shared/setupConstants';
import { RetailLocalization } from '../../common/localization/retail-localization';


@Injectable()
export class CreatePaymentMethodsBusiness {
  captions: any;
  taxExemptOptions: { id: number; name: string; }[];

  constructor(
    private localization: RetailLocalization) {
    this.captions = this.localization.captions.retailsetup;
  } 

  public getSurchargeType() {
    return [
      {
        id: 1,
        value: this.localization.captions.rad_txt_percentage
      },
      {
        id: 0,
        value: this.localization.captions.lbl_flatAmount
      }
    ];
  }

  async getCreditCardTypeOptions(): Promise<DropdownOptions[]> {
    return [{
      id: 1,
      value: '0',
      viewValue: '0'
    },
    {
      id: 2,
      value: '1',
      viewValue: '1'
    }]; 
  }

  getpaymentMethodsCheckbox() {
    return [
      {
        id: 'isUseSpecialGuarantee',
        value: this.captions.chk_txt_useSpecialGuarantee
      },
      {
        id: 'isRestrictPOSChanges',
        value: this.captions.chk_txt_restrictPOSCharges
      },
      {
        id: 'isInterfacePostToCheckoutFolio',
        value: this.captions.chk_txt_allowInterfacePostToCheckFolio
      } 
    ];
  }

  getPaymentMethodsRadioBox() {
    return [
      {
        id: PhoneRestriction.None,
        value: this.captions.chk_rad_none
      },
      {
        id: PhoneRestriction.AllCallsAllowed, 
        value: this.captions.chk_rad_allCallsAllowed
      },
      {
        id: PhoneRestriction.AllCallsRestricted,
        value: this.captions.chk_rad_allCallsRestricted
      },
      {
        id: PhoneRestriction.NoDirectDialCallsAllowed,
        value: this.captions.chk_rad_noDirectDialCallsAllowed
      },
      {
        id: PhoneRestriction.OnlyLocalCallsAllowed,
        value: this.captions.chk_rad_onlyLocalCallsAllowed
      },
      {
        id: PhoneRestriction.InternalCallsOnly,
        value: this.captions.chk_rad_internalCallsOnly
      }
    ]
  }



}
