import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { EmailConfigModel, SavedFiltersModel, ScheduleReportDataModel, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from '@shared/constant/GlobalConstants';

export const EditScheduleReportDataConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, Value?: any): any => {
    try {   
        const homeGroupValue = Value.homeGroup.value;
        const FilterString = Value.FilterString;
        const FormGroupData = Value.FormGroupData;
        const ScheduleReportObject = {} as ScheduleReportDataModel;
        ScheduleReportObject.Id = FormGroupData.Id;        
        ScheduleReportObject.Name = homeGroupValue.Name ? homeGroupValue.Name : FormGroupData.Name;
        ScheduleReportObject.FileName = homeGroupValue.FileName ? homeGroupValue.FileName :
         FormGroupData.FileName;
        ScheduleReportObject.Remarks = homeGroupValue.Remarks ? homeGroupValue.Remarks :
         FormGroupData.Remarks;
        ScheduleReportObject.SiteId = Value.SiteId ? Value.SiteId : FormGroupData.SiteId;
        ScheduleReportObject.Description = homeGroupValue.Description ? homeGroupValue.Description : '';
        ScheduleReportObject.SavedFilters = {} as SavedFiltersModel;
        ScheduleReportObject.SavedFilters.Id = FormGroupData.SavedFilters.Id;
        ScheduleReportObject.SavedFilters.IsActive = FormGroupData.SavedFilters.IsActive;
        ScheduleReportObject.SavedFilters.FilterString = FilterString ? JSON.stringify(FilterString) :
         FormGroupData.SavedFilters.FilterString; 
        ScheduleReportObject.SavedFilters.Period = homeGroupValue.period ? homeGroupValue.period :
         FormGroupData.SavedFilters.Period;
        ScheduleReportObject.Cron = homeGroupValue.CronExpression ? homeGroupValue.CronExpression :
         FormGroupData.Cron;
        ScheduleReportObject.StartDate = homeGroupValue.StartDate ? 
        getStartDate(homeGroupValue.StartDate) : FormGroupData.StartDate;
        ScheduleReportObject.EndDate = homeGroupValue.EndDate ? 
        getEndDate(homeGroupValue.EndDate) : FormGroupData.EndDate;
         ScheduleReportObject.Frequency = (homeGroupValue.frequency !== null &&
            homeGroupValue.frequency !== undefined) ? 
        homeGroupValue.frequency : null;
        ScheduleReportObject.RecurrenceArray = (homeGroupValue.recurrence && 
            homeGroupValue.recurrence.length > 0) ? homeGroupValue.recurrence : 
            (homeGroupValue.monthrecurence && homeGroupValue.monthrecurence.length > 0) ? 
                homeGroupValue.monthrecurence : null;
        ScheduleReportObject.ScheduledTime = homeGroupValue.shedulereporttime ? 
        getUTCTime(homeGroupValue.shedulereporttime) : '';
        ScheduleReportObject.LastRunTime = FormGroupData.LastRunTime;
        ScheduleReportObject.NextRunTime = FormGroupData.NextRunTime;
        ScheduleReportObject.UserId = FormGroupData.UserId;
        ScheduleReportObject.IsActive = FormGroupData.IsActive ? FormGroupData.IsActive : false;
        ScheduleReportObject.CreatedDateTime = FormGroupData.CreatedDateTime;
        ScheduleReportObject.LastUpdatedDateTime = FormGroupData.LastUpdatedDateTime;
        ScheduleReportObject.CreatedBy = FormGroupData.CreatedBy;
        ScheduleReportObject.LastUpdatedBy = FormGroupData.LastUpdatedBy;
        ScheduleReportObject.CurrnetProcessingInstanceId = FormGroupData.CurrnetProcessingInstanceId;
        ScheduleReportObject.ProcessingStartTime = FormGroupData.ProcessingStartTime;
        ScheduleReportObject.EmailConfig = {} as EmailConfigModel;
        ScheduleReportObject.EmailConfig.Id = FormGroupData.EmailConfig.Id;
        ScheduleReportObject.EmailConfig.Description = '';
        ScheduleReportObject.EmailConfig.ToEmail = homeGroupValue.EmailTo ? homeGroupValue.EmailTo :
         FormGroupData.EmailConfig.ToEmail;
        ScheduleReportObject.EmailConfig.BCCEmail = '';
        ScheduleReportObject.EmailConfig.CCEmail = '';
        ScheduleReportObject.EmailConfig.FromEmail = '';
        ScheduleReportObject.IanaTimeZoneId = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
        ScheduleReportObject.UserTimeZoneId = new Date()?.toString()?.split("(")[1]?.split(")")[0];
        const PostData = Object.assign({}, ScheduleReportObject);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Schedule Report Data Construction', error);
    }
};

function getUTCTime(timeValue: any): any {
    try {
        // const currentTimeDate = new Date();
        // const TimeFormats = moment(timeValue, [
        //     'hh:mm a',
        //   ]).format('HH:mm');
        //   const Hours = parseInt(TimeFormats.split(':')[0], 10);
        // const Minutes = parseInt(TimeFormats.split(':')[1], 10);
        // const datetime = new Date(
        //   currentTimeDate.getFullYear(),
        //   currentTimeDate.getMonth(),
        //   currentTimeDate.getDate(),
        //   Hours,
        //   Minutes,
        //   0
        // );
        // const ScheduleTime = moment.utc(datetime).format();
        const ScheduleTime = moment(timeValue, 'hh:mm A').format();
        return ScheduleTime;
    } catch (error) {
        console.error('Error Occured ', timeValue);
    }
}


function getStartDate(startDate: any): any {
    // let startDateValue = moment(startDate).format('MM-DD-YYYY');
    // let returnValue = moment.utc(startDateValue).startOf('day').format();
    let returnValue = moment(startDate).startOf('day').format(constants.PostDateTimeFormat);
    return returnValue;
}

function getEndDate(endDate: any): any {
    // let endDateValue = moment(endDate).format('MM-DD-YYYY');
    // let returnValue = moment.utc(endDateValue).endOf('day').format();
    let returnValue = moment(endDate).endOf('day').format(constants.PostDateTimeFormat);
    return returnValue;
}