import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RetailSetupService } from '../retail-setup.service';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { Subject, Subscription } from 'rxjs';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { Host} from '../../shared/globalsContant';
import { HttpMethod } from '../../shared/service/http-call.service';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonVariablesService } from '../../shared/service/common-variables.service';
import { RentalItemRateType, RetailItemType } from '../../retail.modals';
import { RetailRoutes } from '../../retail-route';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RentalItemErrorCode } from '../../retail-child-setup/retail-child-setup.model';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';

@Component({
  selector: 'app-rental-information',
  templateUrl: './rental-information.component.html',
  styleUrls: ['./rental-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RentalInformationComponent implements OnInit,OnDestroy {
  floatLabel: string;
  @Input() parentForm;
  @Input() isEdit;
  IsViewOnly: boolean = false;
  captions: any;
  rentalInfoForm: UntypedFormGroup;
  validateBtnSubscription: Subscription;
  validateBtn: boolean;
  useRetailInterface: boolean = false;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent= [];
  rentalTableContent = new Subject<any[]>();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  miscSettings : any;
  currencySymbol:any;
  triggerPatch: number;
  outOfOrderMaxValue:number=999;
  commonCaption:any;

  constructor(public _retailService: RetailSetupService, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<RetailPopupComponent>,
    public localization: RetailLocalization, private PropertyInfo: RetailPropertyInformation,public _commonVarService: CommonVariablesService, private utils : RetailUtilities) { 
      this.captions = this.localization.captions.retailsetup;
      this.commonCaption = this.localization.captions;
      this.floatLabel = this.localization.setFloatLabel;
      this.useRetailInterface = this.PropertyInfo.UseRetailInterface;
      this.currencySymbol = this.localization.currencySymbol;
    }

  async ngOnInit() {
    this.rentalInfoForm = this._retailService.rentalFormGrp;
    this.miscSettings = await this._commonVarService.GetMiscConfig();
    if(!this._commonVarService.isRentalAtOutletLevel) {
      this.rentalInfoForm.controls.quantity.setValidators([Validators.required])
    } else {
      this.rentalInfoForm.controls.quantity.setValidators(null);
      this.rentalInfoForm.controls.quantity.setValue(0);
    }
    if (this.rentalInfoForm.value.rateType == RentalItemRateType.Hourly) {
      this.rentalInfoForm.get('rate').disable();  
    }
    this.validateBtnSubscription = this._retailService.validateBtn.subscribe(isValid =>
      this.validateBtn = isValid
    );

    if(this.parentForm){
      this.parentForm.addControl('rentalFormGrp', this.rentalInfoForm)
    }
    this.rentalInfoForm.patchValue(this._retailService.rentalItemInformation);
    this.triggerPatch = Math.random();

    this.headerOptions = this.getTableHeaderOptions();
    this.tableOptions = this.getTableOptions();
    this.tableContent = this.getTableContent();
    if (this._retailService.rentalItemInformation?.rateType == RentalItemRateType.Hourly) {
      this.rentalInfoForm.get('rate').disable();  
    }
    if (this.isEdit && this._retailService.rentalItemInformation) {
      if (this._retailService.rentalItemInformation?.rateType == RentalItemRateType.Hourly) {
        let array = this.rentalInfoForm.get('hourlyRate') as UntypedFormArray;
        array.clear();
        this._retailService.rentalItemInformation?.rentalItemSlabRates.forEach(y => {
          const slabRates = this.fb.group({
            id: y.id,
            slabStart: y.slabStart,
            slabEnd: y.slabEnd,
            amount:this.localization.localizeCurrency(y.amount)
          })
          array.push(slabRates)
        })
        this.rentalInfoForm.get('hourlyRate').enable();
        this.rentalInfoForm.get('rate').disable(); 
      }
      else{
        this.rentalInfoForm.get('hourlyRate').disable();
        this.rentalInfoForm.get('rate').enable(); 
        this.rentalInfoForm.controls.rate.setValue(this.localization.localizeCurrency(this._retailService.rentalItemInformation?.rate))
      }
      if (this._commonVarService.isRentalAtOutletLevel) {
        this.tableContent?.map((x, i) => {
          this._retailService.rentalItemInformation?.rentalItemQuantity?.forEach(item => {
            if (item.outletId == x.outletId) {
                x.index = i,
                x.id = item.id,
                x.quantity = item.quantity,
                x.outofOrderQuantity = item.outofOrderQuantity
            }
          });
        })
        this.rentalInfoForm.controls.tableContent.patchValue(this.tableContent);
      }
      else {
        this._retailService.rentalItemInformation?.rentalItemQuantity?.forEach(element => {
          this.rentalInfoForm.controls['quantity'].patchValue(element.quantity)
          this.rentalInfoForm.controls['outofOrderQuantity'].patchValue(element.outofOrderQuantity)
        });
      }
    }
    else{
      this.rateTypeChange({value: 0})
    }
    this.tableContent = [...this.tableContent];
    this._retailService.rentalTableContent.pipe(takeUntil(this.destroyed$)).subscribe(result =>
      this.tableContent = result
    );
  }

  ngOnDestroy() {
    if (this.validateBtnSubscription) {
      this.validateBtnSubscription.unsubscribe();
    }
  }

  stopdefaultClick(e) {
    if (this.isEdit && this.IsViewOnly) {
      e.preventDefault();
    }
  }

  rateTypeChange(e){
    if(e.value == 0){
      this.rentalInfoForm.get('hourlyRate').enable();
      this.rentalInfoForm.get('rate').disable();
    }
    else{
      this.rentalInfoForm.get('hourlyRate').disable();
      this.rentalInfoForm.get('rate').enable();
    }
  }

  toggleClick(e){

  }

  close() {
    this.dialogRef.close();
  }

  async save(){
    this._retailService.ValidateUpdate();
    if (!this.validateBtn) return;
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    this.rentalInfoForm.controls.tableContent.setValue(this.tableContent);
    this.rentalInfoForm.updateValueAndValidity()
    let updateBody = this._retailService.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;
    let itemId = 0;
    if (this.isEdit) {
      if (updateBody.retailItem.retailItemDetail.itemType == Number(RetailItemType.PMSAddOnsRentalItem)) {
        let checkRentalInUseCount = await this.checkRentalInUse(updateBody.retailItem.retailItemDetail.id);
        if(checkRentalInUseCount > 0 && updateBody.retailItem.retailItemDetail.isActive == false){
          this.utils.showError(this.localization.getError(RentalItemErrorCode.RentalItemInUseErrorCodeDelete));
          return ;
        }
      }
      response = await this._retailService.InvokeServiceCallAsync("UpdateRetailItem", Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id })
      if (response) {
        this._retailService.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    }
    else {
      response = await this._retailService.InvokeServiceCallAsync("CreateRetailItem", Host.retailManagement, HttpMethod.Post, updateBody)
      if (response) {
        this._retailService.saveImage(response.result)
        itemId = response.result;
      }
    }
    if (response && response.result) {
      this._retailService.retailItemUpdateEvent.next(itemId);
      this.dialogRef.close(updateBody);
    }
  }
  getTableHeaderOptions(){
    return[
      {
        key: 'outletName',
        displayName: this.captions.lbl_outlet,
        displayNameId: 'lbl_outlet',
        sorting: true,
      },
      {
        key: 'quantity',
        displayName: this.captions.quantity,
        displayNameId: 'quantity',
        templateName: ActionTypeEnum.custom,
        isRequired: true
      },
      {
        key: 'outofOrderQuantity',
        displayName: this.captions.outofOrderQuantity,
        displayNameId: 'outofOrderQuantity',
        templateName: ActionTypeEnum.custom,
        isRequired: true
      },
    ]
  }
  getTableOptions() {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'outletName',
      uniqueKey: 'outletName',
    }
  }

  getTableContent() {
    let outlets = [];
    this._retailService.selectedOutletMgrArr.value.forEach((x, i) => {
      let outlet = {
        index: i,
        id: 0,
        outletId: x.id,
        outletName: x.name,
        quantity: 1,
        outofOrderQuantity: 0,
      }
      outlets.push(outlet);
    });
    return outlets;
  }

  quantityInput(e, key, index) {
      this.tableContent[index][key] = e.target.value;
      this.rentalInfoForm.controls.tableContent.markAsDirty();
      if(this.tableContent[index].outofOrderQuantity > this.tableContent[index].quantity){
        this.rentalInfoForm.controls.tableContent.setErrors({invalid : true})
      } else {
        this.rentalInfoForm.controls.tableContent.setErrors(null)
      }
      this._retailService.rentalItemQuantities = this.tableContent;
      this.rentalInfoForm.markAllAsTouched();

  }

  addItem(index){
    const controls=this.fb.group({
      slabStart: ['',Validators.required],
      slabEnd: ['',Validators.required],
      amount: ['',Validators.required]
    });
    let array = this.rentalInfoForm.get('hourlyRate') as UntypedFormArray;
    array.push(controls);
    this.updateRanges();
  }

  removeItem(index){
    let array = this.rentalInfoForm.get('hourlyRate') as UntypedFormArray;
    array.removeAt(index);
    this.updateRanges();
  }
  updateRanges(): void {
    const currentRanges = this.rentalInfoForm.get('hourlyRate')['controls'];
    for (let i = 0; i < currentRanges.length; i++) {
      const start = i === 0 ? 1 : +currentRanges[i - 1].get('slabEnd').value + 1;
      currentRanges[i].controls.slabStart.setValue(start);
      if(currentRanges[i].controls.slabEnd.value != null){
        if(currentRanges[i].controls.slabStart.value > currentRanges[i].controls.slabEnd.value || currentRanges[i].controls.slabStart.value == currentRanges[i].controls.slabEnd.value){
          currentRanges[i]['controls']['slabEnd'].setErrors({ invalid: true });
        }
      }
      else{
        currentRanges[i]['controls']['slabEnd'].setErrors({ required: true });
      }
    }
  }

  minutesInputChange(index){
    this.updateRanges();
  }

  rentalQuantityChange(eve) {
    if(Number(this.rentalInfoForm.controls.outofOrderQuantity.value) > Number(eve.target.value)) {
    this.rentalInfoForm.controls.outofOrderQuantity.setErrors({ invalid: true });
    } else {
    this.rentalInfoForm.controls.outofOrderQuantity.setErrors(null);

    }
    this.outOfOrderMaxValue = eve.target.value;
    
    this.rentalInfoForm.updateValueAndValidity();
  }
  outOfOrderChange(eve) {
    if(Number(eve.target.value) > Number(this.outOfOrderMaxValue)) {
      this.rentalInfoForm.controls.outofOrderQuantity.setErrors({ invalid: true });
    } else {
          this.rentalInfoForm.controls.outofOrderQuantity.setErrors(null);

    }
  }

  async checkRentalInUse(id: number): Promise<number> {
    try{
      const response = await this._retailService.InvokeServiceCallAsync( RetailRoutes.RentalItemPurchaseCount , GlobalConst.Host.retailPOS, HttpMethod.Get,'',{ Id: id });
      return response.result;
    }catch(err){
      console.error('error from Rental Item in use check ',err);
    }
  }

}


