
//import AppointmentListingParent = require('./appointmentListing.report');
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import { AppointmentListing } from './appointmentListing.report';

/**
 * No Show Report configuration
 * Same as the appointment listing report configuration
 */
export class NoShowReport extends AppointmentListing {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public code: string, public patcheableValue) {
        super(reportFormGrp, data, code, patcheableValue);
    }
}