import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CustomFormField } from '.';


export const SetUnitData: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            if (MasterDataCollection.FormConfig.FlowMode === 'Create') {
                const BaseUnit = FormGroupData.controls['BaseUnit'].value;
                const InventoryUnits: Array<any> = ['PurchaseUnit', 'TransactionUnit', 'RecipeUnit'];
                for (const property in FormGroupData.controls) {
                     if ( property ) {
                           const controlIndex = InventoryUnits.findIndex(x => x === property);
                           if ( controlIndex > - 1) {
                            FormGroupData.controls[property].setValue(BaseUnit);
                           }
                     }
                  }
                CustomFormField.customRecipeUnitChange(ViewModelEntity, PostModelEntity,
                    MasterDataCollection,
                    httpService,
                    FieldGrouping,
                    FormGroupData, FormField, CustomData);
            }
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

