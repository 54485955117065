import { PaymentMethods } from "../enums/enums";

export interface GetDeviceResponse {
    devices: rGuestPayDevice[];
}

export interface rGuestPayDevice {
    deviceGuid: string;
    handlerId: string;
    deviceNumber: number;
    handlerStatus: string;
    deviceStatus: string;
    statusMessage: string;
    handler: string;
    manufacturer: string;
    product: string;
    description: string;
    deviceType: string;
    devicePath: string;
    serialNumber: string;
    serialNumber2: string;
    extendedData: ExtendedDatum[];
}

export interface ExtendedDatum {
    key: string;
    value: string;
}

export interface GetDeviceInfoResponse {
    deviceInfo: rGuestPayDevice;
}

export interface CaptureCardRequest {
    amount:          number;
    currencyCulture: CurrencyCulture;
}

export interface CurrencyCulture {
    currencyCultureName: string;
    currencySymbol:      string;
}


export interface CapturedCardResponse {
    cardData: CardData;
}

export interface CardData {
    accountName: string;
    accountNumber: string;
    expirationDate: string;
    cvv2: string;
    postalCode: string;
    track1: string;
    track2: string;
    cardDataSource: string;
    encryptionType: string;
    p2peData: P2PEData;
}

export interface P2PEData {
    deviceType: string;
    deviceIdentifier: string;
    encryptionIdentifier: string;
    encryptedData1: string;
    encryptedData2: string;
    additionalData: AdditionalDatum[];
}

export interface AdditionalDatum {
    name: string;
    value: string;
}

export interface RGuestSaleResponse {
    transactionReferenceData: TransactionReferenceData;
    transactionResponseData:  TransactionResponseData;
    gatewayResponseData:      { [key: string]: string };
    cardInfo:                 CardInfo;
    emvInfo:                  EmvInfo;
    pinVerified:              boolean;
    signatureStatus:          string;
    signatureData:            SignatureData;
    dccResponseData:          DCCResponseData;
    isOfflineApproved:        boolean;
    receiptData:              ReceiptData;
}

export interface CardInfo {
    cardHolderName:      string;
    accountNumberMasked: string;
    cardIssuer:          string;
    cardIssuerExtension: string;
    cardType:            string;
    entryMode:           string;
    expirationYearMonth: string;
    correlationId:       string;
}

export interface DCCResponseData {
    exchangeRate:          number;
    margin:                number;
    foreignCurrencyAlpha:  string;
    foreignCurrencyCode:   string;
    foreignCurrencyAmount: number;
}

export interface EmvInfo {
    isFallback:                   boolean;
    mode:                         string;
    applicationIdentifier:        string;
    applicationLabel:             string;
    applicationPreferredName:     string;
    issuerCodeTableIndex:         string;
    issuerApplicationData:        string;
    cryptogram:                   string;
    terminalVerificationResults:  string;
    transactionStatusInformation: string;
    authorizationResponseCode:    string;
    tags:                         Tag[];
}

export interface Tag {
    id:    string;
    value: string;
}

export interface ReceiptData {
    merchantReceiptText: string[];
    customerReceiptText: string[];
}

export interface SignatureData {
    format:    string;
    height:    number;
    width:     number;
    polyLines: PolyLine[];
}

export interface PolyLine {
    points: Point[];
}

export interface Point {
    x: number;
    y: number;
}

export interface TransactionReferenceData {
    transactionId:           string;
    token:                   string;
    tokenExpirationDate:     string;
    transactionState:        string;
    transactionFollowOnData: string;
    complianceDataValue:     string;
}

export interface TransactionResponseData {
    authorizedAmount: number;
    subTotalAmount:   number;
    tipAmount:        number;
    totalAmount:      number;
    availableBalance: number;
    surchargeAmount:  number;
}

export enum TransactionType {
    Auth = 1,
    Sale,
    Credit
}

export const PMAgentActionType =
{
    GetHandles: "GETHANDLES",
    CreateToken: "CREATETOKEN",
    Sale: "SALE",
    Credit: "CREDIT",
    Auth: "AUTH",
    GetBalance: "GETBALANCE",
    Load: "LOAD",
    Issue: "ISSUE"
}

export interface TokenResponse {
    token: string;
    tokenExpirationDate: string;
    gatewayResponseData: { [key: string]: string };
    cardInfo: CardInfo;
}

export interface CardInfo {
    cardHolderName: string;
    accountNumberMasked: string;
    cardIssuer: string;
    cardIssuerExtension: string;
    cardType: string;
    entryMode: string;
    expirationYearMonth: string;
    correlationId: string;
}

export interface RGuestPayRequestBuildParams {
    deviceGuid?: string;
    tenderId: number;
    outletId: number;
    sourceTypeId?: string;
    sourceType?: number;
    ticketNumber?: string;
    amount?: number;
    zipcode?: string;
    manualCardEntry: boolean;
    encryptedCardData?: string;
    actionType: string;
    integrationOperationType: IntegrationOperationsTransactionType;
    params?: { [key: string]: string };
  }
  
  export interface RGuestPayRequestDetails {
    url: string;
    requestPayload: string;
    actionMethod: string;
    headers: { [key: string]: string };
    status: boolean;
    errorMessage: string;
    errorCode: number;
  }
  
  export enum IntegrationOperationsTransactionType {
    //Retail Transactions
    CreditCardSalePaymentForRetailTransaction = 1,
    CreditCardCreditPaymentForRetailTransaction,
    CreditCardRefundPaymentForRetailTransaction,
    CreditCardVoidPaymentForRetailTransaction,
    GiftCardTopupForRetailTransaction,
    GiftCardRedemptionForRetailTransaction,
    GiftCardIssueForRetailTransaction,
    CreditCardGetHandlesForRetailTransaction,
  
    //Folio Postings
    CreditCardSalePaymentForFolioPosting = 101,
    CreditCardCreditPaymentForFolioPosting,
    CreditCardRefundPaymentForFolioPosting,
    CreditCardVoidPaymentForFolioPosting,
    GiftCardTopupForFolioPosting,
    GiftCardRedemptionForFolioPosting,
    GiftCardIssueForFolioPosting,
    CreditCardAuthForFolioPosting
  }

  export const DeviceStatus = {
    Unknow: 'unknown',
    Available: 'available',
    NotAvailable: 'notAvailable',
    Updating: 'updating',
    Failed: 'failed'
}

export enum TransactionState {
    Authorised = 1,
    AuthorizationVoid = 2,
    Settled = 3,
    Sale = 4,
    VoidSale = 5,
    Refund = 6,
    Rejected = 7,
    Declined = 8,
    Success = 9,
    Failed = 10,
    Cancelled = 11,
    TokenGenerated = 12,
    TokenisedSale = 13,
    TokenisedCredit = 14,
    Credit = 15,
    VoidTransaction = 16,
    Load = 17,
    Issue = 18,
    Balance = 19,
    CashOut = 20,
    IssueReversal = 21,
    CreditReversal = 22,
    LoadReversal = 23,
    DeActivate = 24,
    SignatureCapture = 25,
    TipCapture = 26,
    ForceCaptureTransactionState = 27,
    AuthTransactionState = 28,
    TokenisedAuth = 29,
    ModifyAuthorization = 30,
    DecreaseAuthorization = 31,
    ForceCaptureToken = 32,
    CreateCheck = 33,
    UpdateCheck = 34,
    GetAllChecks = 35,
    InquiryCheck = 36,
    InquiryCheckPayments = 37,
    CheckPaymentsAcknowledge = 38,
    CloseCheck = 39,
    Notification = 40,
    Message = 41,
    SecurityCode = 42
}
export const GiftCardMethods: PaymentMethods[] = [
    PaymentMethods.V1GiftCard,
    PaymentMethods.V1GiftCardIdTech,
    PaymentMethods.ExternalGiftCard,
    PaymentMethods.ExternalGiftCardIdTech,
    PaymentMethods.AgilysysGiftCard,
    PaymentMethods.AgilysysGiftCardIdTech
];