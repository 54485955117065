import { Injectable } from '@angular/core';
import { ServiceParams ,BaseResponse} from '../../models/http.model';
import { HttpCallService } from '../common/http-call.service';
import { HttpErrorResponse,HttpClient } from '@angular/common/http';
import { SPAApiHosts } from 'src/app/core/extensions/spa-route';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaUtilities } from '../../utilities/spa-utilities';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';

@Injectable()
export class RetailmanagementCommunication extends HttpCallService{

  constructor(httpclient: HttpClient,localization:SpaLocalization,utilities: SpaUtilities, PropertyInfo: SpaPropertyInformation) {
    super(SPAApiHosts.RetailManagement, httpclient,localization,utilities, PropertyInfo);
}

public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response.result;
}

public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
    let response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response.result;
}

public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response.result;
}

public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response ? response.result : undefined;
}


private error(err: HttpErrorResponse, handleErr: boolean) {
    if (handleErr) {
        super.errorHandler(err);
    }
    else {
        throw err;
    }
}


}
