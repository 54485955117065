import { ENavigationMenu, NavigationMenuAction } from '../actions/navigation-menu.action';
import { intialNavigationState } from '../states/navigation-menu.state';

export function NavigationMenuReducer(state = intialNavigationState, action: NavigationMenuAction) {
    switch (action.type) {
        case ENavigationMenu.GetNavigationMenu: {
            return{
                ...state,
                GetNavigationMenu : action.payload
            };
        }
        case ENavigationMenu.GetNavigationMenuSucess: {
            return{
                ...state,
                GetNavigationMenuSucess : action.payload
            };
        }
        case ENavigationMenu.GetTretiaryTreeView: {
            return{
                ...state,
                GetTretiaryTreeView : action.payload
            };
        }
        default:
       return state;
    }
}