import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomExchangeRate: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: TransactionConfiguration
): any => {
    try {
        if (FieldGrouping.Name === 'TransactionCurrencyId') {
            const TransactionCurrencyId = FormGroupData.value['TransactionCurrencyId'];
            const BaseCurrencyId = parseInt(sessionStorage.getItem('BaseCurrency'), 10);
            if (BaseCurrencyId && TransactionCurrencyId && BaseCurrencyId !== TransactionCurrencyId) {
                const EndPointUrl = `/inventory/api/GetExchangeRate/${TransactionCurrencyId}/${BaseCurrencyId}`;
                httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                    if (res) {
                        FormGroupData.controls['ExchangeRate'].setValue(res['ExchangeRate'] ? res['ExchangeRate'] : 1);
                        setExchangeRateInGrid((res['ExchangeRate'] ? res['ExchangeRate'] : 1), MasterDataCollection);
                    } else {
                        FormGroupData.controls['ExchangeRate'].setValue(1);
                        setExchangeRateInGrid(1, MasterDataCollection);
                    }
                });
            } else {
                FormGroupData.controls['ExchangeRate'].setValue(1);
                setExchangeRateInGrid(1, MasterDataCollection);
            }
        } else if (FieldGrouping.Name === 'ExchangeRate') {
            setExchangeRateInGrid(FormGroupData.value.ExchangeRate, MasterDataCollection);
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function setExchangeRateInGrid (ExchangeRate: any, MasterDataCollection: TransactionConfiguration) {
    try {
        const GridData = MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
        GridData.forEach(res => {
            res.ExchangeRate = ExchangeRate;
            res.UnitCostInFC = parseFloat(((res.UnitCost ? parseFloat(res.UnitCost.toString()) : 0) * (ExchangeRate ? parseFloat(ExchangeRate.toString()) : 0)).toString()).toFixed(4);
            res.AmountInFC = parseFloat(((res.Valued ? parseFloat(res.Valued.toString()) : 0) * (ExchangeRate ? parseFloat(ExchangeRate.toString()) : 0)).toString()).toFixed(4);
            res.ChargesInFC = parseFloat(((res.Charges ? parseFloat(res.Charges.toString()) : 0) * (ExchangeRate ? parseFloat(ExchangeRate.toString()) : 0)).toString()).toFixed(4);
            res.TaxAmountInFC1 = parseFloat(((res.TaxAmount1 ? parseFloat(res.TaxAmount1.toString()) : 0) * (ExchangeRate ? parseFloat(ExchangeRate.toString()) : 0)).toString()).toFixed(4);
            res.TaxAmountInFC2 = parseFloat(((res.TaxAmount2 ? parseFloat(res.TaxAmount2.toString()) : 0) * (ExchangeRate ? parseFloat(ExchangeRate.toString()) : 0)).toString()).toFixed(4);
            res.UnitCost = parseFloat(((res.UnitCostInFC ? parseFloat(res.UnitCostInFC.toString()) : 0) / res.ExchangeRate).toString()).toFixed(4);
            res.Valued = parseFloat(((res.AmountInFC ? parseFloat(res.AmountInFC.toString()) : 0) / res.ExchangeRate).toString()).toFixed(4);
            res.TaxAmount1 = parseFloat(((res.TaxAmountInFC1 ? parseFloat(res.TaxAmountInFC1.toString()) : 0) / res.ExchangeRate).toString()).toFixed(4);
            res.TaxAmount2 = parseFloat(((res.TaxAmountInFC2 ? parseFloat(res.TaxAmountInFC2.toString()) : 0) / res.ExchangeRate).toString()).toFixed(4);
            res.Charges = parseFloat(((res.ChargesInFC ? parseFloat(res.ChargesInFC.toString()) : 0) / res.ExchangeRate).toString()).toFixed(4);
            if (res
                && res.ReceivingItemsOtherTotals && (res.Charges || res.ChargesInFC)) {
                const AllocCharges = res.ReceivingItemsOtherTotals.findIndex(x => x.CategoryId === 3);
                if (AllocCharges === -1) {
                    res.ReceivingItemsOtherTotals.push({
                    'Id': 0,
                    'CategoryId': 3,
                    'CategoryDetailId': 2,
                    'Amount': parseFloat(res.Charges),
                    'AmountInFC': parseFloat(res.ChargesInFC)
                  });
                } else {
                  res.ReceivingItemsOtherTotals[AllocCharges].CategoryDetailId = 2;
                  res.ReceivingItemsOtherTotals[AllocCharges].Amount = parseFloat(res.Charges);
                  res.ReceivingItemsOtherTotals[AllocCharges].AmountInFC = parseFloat(res.ChargesInFC);
                }
              } else {
                res.ReceivingItemsOtherTotals = res.ReceivingItemsOtherTotals
                  && res.ReceivingItemsOtherTotals.length > 0 ? res.ReceivingItemsOtherTotals : [];
                if (res.ReceivingItemsOtherTotals.length === 0 && res.Charges) {
                  res.ReceivingItemsOtherTotals.push({
                    'Id': 0,
                    'CategoryId': 3,
                    'CategoryDetailId': 2,
                    'Amount': parseFloat(res.Charges),
                    'AmountInFC': parseFloat(res.ChargesInFC)
                  });
                }
              }
        });
        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [...GridData];
        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = {
                ...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig};
        // MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded(GridData, true);
    } catch (error) {
        console.log('Error occurred in setExchangeRateInGrid', error);
    }
}