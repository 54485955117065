import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { Outlet, ReceiptModel,PropertyReceiptModel, PropertyConfigurationModel} from '../../../retail/retail.modals';
import { ReceiptConfigurationDataService } from './receipt-configuration-data';
import { RetailOutletsDataService } from '../../../retail/retail-code-setup/retail-outlets/retail-outlets-data.service';
import { RetailBreakPoint,  ButtonType } from '../../../shared/globalsContant';
import { RetailSetupService } from '../../../retail/retail-setup/retail-setup.service';
import {  HttpServiceCall } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CUSTOM_FEE_RECEIPTNAME } from 'src/app/common/shared/shared/custom-fee/custom-fee.model';

@Component({
  selector: 'app-receipt-configuration',
  templateUrl: './receipt-configuration.component.html',
  providers: [ReceiptConfigurationDataService, RetailOutletsDataService],
  styleUrls: ['./receipt-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ReceiptConfigurationComponent implements OnInit {

  [x: string]: any;
  printReceiptArray: any;
  FormGrp: UntypedFormGroup;
  textCaptions: any;
  printInfo: any;
  userPrintValue:any;
  userPrintControls:number[];
  Outlet: Outlet[];
  OutletInfo: ReceiptModel[];
  selectedOutletId: number;
  IsViewOnly: boolean;
  isSaveDisabled: boolean
  ServiceCharge: any;
  isSuppressClerk: boolean;
  isSuppressPrint: boolean;
  selctedClerkById: number;
  selctedPrintById: number;
  propertyForm: UntypedFormGroup;
  PropertyReceiptInfo: PropertyReceiptModel;
  DisplayAuthCode:boolean;
  AuthCodeConfiguration:PropertyReceiptModel[];
  propertyId: number;
  floatLabel:string;
  displayChangeDue: boolean;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  propertySaveButton: ButtonValue;
  propertyCancelButton: ButtonValue
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(private Form: UntypedFormBuilder, private breakPoint: BreakPointAccess, private localization: SpaLocalization, private http: HttpServiceCall, private data: ReceiptConfigurationDataService, private outletData: RetailOutletsDataService, private retailService: RetailSetupService, private utils: SpaUtilities) {
    this.textCaptions = this.localization.captions.utilities;
    this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() {
    this.initializeForm();
    this.textCaptions = this.localization.captions.utilities;
    this.DisplayAuthCode=false;
    this.displayChangeDue= false;
    this.ServiceCharge = [
      { id: 1, value: this.textCaptions.Details },
      { id: 2, value: this.textCaptions.SummarySplit },
      { id: 3, value: this.textCaptions.SummaryCombine }
    ]
    this.Outlet = await this.outletData.getOutlets();
    this.Outlet = this.Outlet.filter(x => x.isActive);
    this.OutletInfo = await this.data.getOutletInfo();
    
    this.printInfo = [
      { id: 1, Name: this.textCaptions.DisplayDisOnReceipt, controlName: 'discountOnReceipt', enableToggle: true },
      { id: 2, Name: this.textCaptions.DisplayPackageItemDesc, controlName: 'packItemDesc', enableToggle: true },
      { id: 3, Name: this.textCaptions.AddSecondLine, controlName: 'addSecondLine', enableToggle: true },
      { id: 4, Name: this.textCaptions.PackItemPrice, controlName: 'packItemPrice', enableToggle: false },
      { id: 5, Name: this.textCaptions.PackItemOnReceipt, controlName: 'packageItemOnReceipt', enableToggle: true },
      { id: 6, Name: this.textCaptions.PackAppID, controlName: 'packAppId', enableToggle: false },
      { id: 10, Name: this.textCaptions.displayCustomFeeDetails, controlName: 'displayDetailedCustomFee', enableToggle: true },
      { id: 7, Name: this.textCaptions.SurplusClerkID, controlName: 'surplusClientIdOnReceipt', enableToggle: true },  
      { id: 8, Name: this.textCaptions.PackItemStaffCode, controlName: 'packStaffCode', enableToggle: false },    
      { id: 9, Name: this.textCaptions.SurplusPrintedBy, controlName: 'surplusPrintedByOnReceipt', enableToggle: true },
      
    ];
    this.userPrintControls=[7,9];
    this.userPrintValue = [
      {id:1, Name:this.textCaptions.userId, controlName: 'userId', value: 0},
      {id:2, Name:this.textCaptions.fullName, controlName: 'fullName', value: 1},
      {id:3, Name:this.textCaptions.firstName, controlName: 'firstName', value: 2},
      {id:4, Name:this.textCaptions.lastName, controlName: 'lastName', value: 3}
    ]
    this.isSuppressClerk = true;
    this.isSuppressPrint =true;
    this.breakPoint.CheckForAccess([RetailBreakPoint.ReceiptConfiguration], false);
    if (this.breakPoint.IsViewOnly(RetailBreakPoint.ReceiptConfiguration)) {
      this.IsViewOnly = true;
      this.FormGrp.controls['noOfReceipts'].disable();
      this.FormGrp.controls['gratuityLine'].disable();
      this.FormGrp.controls['receiptNote'].disable();
      this.printInfo[0].enableToggle = false;
      this.printInfo[1].enableToggle = false;
      this.printInfo[2].enableToggle = false;
      this.printInfo[3].enableToggle = false;
      this.printInfo[4].enableToggle = false;
      this.printInfo[5].enableToggle = false;
      this.printInfo[6].enableToggle = false;
      this.printInfo[7].enableToggle = false;
      this.printInfo[9].enableToggle = false;
    }
    this.isSaveDisabled = true;
    this.getPropertyReceiptConfig();
    this.FormGrp.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(value => {
      this.enableSave();
    })
    this.propertyForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(value => {
      this.propertySaveButton.disabledproperty = !(this.propertyForm.dirty && this.propertyForm.valid)
    })
  }

  enableSave(){
    this.saveButton.disabledproperty = !(this.FormGrp.valid && !this.isSaveDisabled && !this.IsViewOnly)
  }

  initializeForm(){
    this.FormGrp = this.Form.group({
      outlet: ['', Validators.required],
      noOfReceipts: [''],
      displayServiceCharge: ['1', Validators.required],
      gratuityLine: [''],
      receiptNote: [''],
      printReceipt: this.Form.array([this.addPrintDetails()])
    });
    this.propertyForm = this.Form.group({
      printGiftReceipt:[''],
      displayAuthcode: [''],
      authcodeName: [''],
      displayChangeDue: [''],
      customFeeReceiptName:['', Validators.required],
      receiptFooterNote: [''],
      printPendingSettlementReceipt: ['']
    })
       
    this.saveButton = {
      type: 'primary',
      label: this.textCaptions.save,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.textCaptions.cancel
    }
    this.propertySaveButton = {
      type: 'primary',
      label: this.textCaptions.save,
      disabledproperty: true
    }
    this.propertyCancelButton = {
      type: 'tertiary',
      label: this.textCaptions.cancel
    }
  }
  toggleChangeDueAction(event) {
    if (event == false) {
      this.DisplayChangeDue=false;
      
    }
    else {
      this.DisplayChangeDue=true;
      
    }
  }

  changeSelection(e) {
    this.isSaveDisabled = true;
    this.selectedOutletId = e.value;
    let selectedValues = this.OutletInfo.filter(x => x.outletId == e.value);
    if (selectedValues.length == 0) {
      this.resetData();
      this.cancelClick();
      this.FormGrp.get('outlet').setValue(this.selectedOutletId);
    }
    else {
      this.bindGridData(selectedValues);
    }
    this.enableSave();
  }

  bindGridData(selectedValues) {
    let printReceiptCustom: any = {
      discountOnReceipt: selectedValues[0].displayDiscount,
      addSecondLine: selectedValues[0].addSecondLine,
      packageItemOnReceipt: selectedValues[0].displayOnlyPackageItem,
      surplusClientIdOnReceipt: selectedValues[0].suppressClerkId,
      surplusPrintedByOnReceipt: selectedValues[0].suppressPrintedBy,
      packItemDesc: selectedValues[0].displayPackageDescription,
      packItemPrice: selectedValues[0].displayPackagePrice,
      packAppId: selectedValues[0].displayPackageAppointmentID,
      packStaffCode: selectedValues[0].displayPackageStaffCode,
      clerkIdPrintValue:  selectedValues[0].clerkIdPrintValue,
      printedByPrintValue:  selectedValues[0].printedByPrintValue,
      displayDetailedCustomFee: selectedValues[0].displayDetailedCustomFee
    };

    this.FormGrp.get('outlet').setValue(selectedValues[0].outletId);
    this.FormGrp.get('noOfReceipts').setValue(selectedValues[0].numberOfReceipts);
    this.FormGrp.get('displayServiceCharge').setValue(selectedValues[0].serviceChargeGratuityDisplay);
    this.FormGrp.get('gratuityLine').setValue(selectedValues[0].gratuityLine);
    this.FormGrp.get('receiptNote').setValue(selectedValues[0].receiptNote);
    this.printReceiptArray = this.FormGrp.get('printReceipt') as UntypedFormArray;
    this.printReceiptArray.removeAt(0);
    let x = this.savePrintDetails(printReceiptCustom);
    this.printReceiptArray.push(x);
    if (selectedValues[0].displayPackageDescription && !this.IsViewOnly) {
      this.printInfo[3].enableToggle = true;
      this.printInfo[5].enableToggle = true;
      this.printInfo[7].enableToggle = true;
    }
    if (selectedValues[0].displayOnlyPackageItem) {
      this.printInfo[1].enableToggle = false;
      this.printInfo[3].enableToggle = false;
      this.printInfo[5].enableToggle = false;
      this.printInfo[7].enableToggle = false;
    }
    if (selectedValues[0].suppressClerkId == false || selectedValues[0].suppressPrintedBy == false) {
      this.isSuppressClerk = true;
      this.isSuppressPrint = true;
    }
    if (!selectedValues[0].displayOnlyPackageItem) {
      if(selectedValues[0].displayPackageDescription)
      {
        this.printInfo[3].enableToggle = true;
        this.printInfo[5].enableToggle = true;
        this.printInfo[7].enableToggle = true;
      }
      this.printInfo[1].enableToggle = true;
    }
    
    if (selectedValues[0].suppressClerkId == true) {
      this.isSuppressClerk = false;
    }
    if ( selectedValues[0].suppressPrintedBy == true) {
      this.isSuppressPrint = false;
    }

  }

  addPrintDetails(): UntypedFormGroup {
    return this.Form.group({
      discountOnReceipt: '',
      addSecondLine: '',
      packageItemOnReceipt: '',
      surplusClientIdOnReceipt: '',
      displayDetailedCustomFee: '',
      surplusPrintedByOnReceipt: '',
      packItemDesc: '',
      packItemPrice: '',
      packAppId: '',
      packStaffCode: '',
      surplusClientIdOnReceiptbyName: '',
      surplusPrintedByOnReceiptbyName: '',
    });
  }
  savePrintDetails(data): UntypedFormGroup {
    return this.Form.group({
      discountOnReceipt: data.discountOnReceipt,
      addSecondLine: data.addSecondLine,
      packageItemOnReceipt: data.packageItemOnReceipt,
      surplusClientIdOnReceipt: data.surplusClientIdOnReceipt,
      surplusPrintedByOnReceipt: data?.surplusPrintedByOnReceipt,
      packItemDesc: data.packItemDesc,
      packItemPrice: data.packItemPrice,
      packAppId: data.packAppId,
      packStaffCode: data.packStaffCode,
      surplusClientIdOnReceiptbyName: data.clerkIdPrintValue,
      surplusPrintedByOnReceiptbyName: data.printedByPrintValue,
      displayDetailedCustomFee: data.displayDetailedCustomFee
    });
  }
  radioSelect(value, controlName) {
    this.isSaveDisabled = false;
    let controlname = ""
    if(controlName == "surplusClientIdOnReceipt")
    {
      this.selctedClerkById =  value;
      controlname = controlName + 'byName';
    }
    if(controlName == "surplusPrintedByOnReceipt")
    {
      this.selctedPrintById = value;
      controlname = controlName + 'byName';
    }
    this.FormGrp.get('printReceipt')['controls'][0].get(controlname).setValue(value);
    this.enableSave();
  }
  
  toggleChange(event, controlName) {
    this.isSaveDisabled = false;
    if (controlName == 'packItemDesc' && event[0] && !this.IsViewOnly) {
      this.printInfo[3].enableToggle = true;
      this.printInfo[5].enableToggle = true;
      this.printInfo[7].enableToggle = true;
    }
    else if (controlName == 'packItemDesc' && !event[0]) {
      this.printInfo[3].enableToggle = false;
      this.printInfo[5].enableToggle = false;
      this.printInfo[7].enableToggle = false;
    }
    else if ((controlName == 'surplusClientIdOnReceipt') && event[0] == false) {
      this.isSuppressClerk = true;
    }
    else if ((controlName == 'surplusPrintedByOnReceipt') && event[0] == false) {
      this.isSuppressPrint = true;
    }
    else if ((controlName == 'surplusClientIdOnReceipt' ) && event[0] == true) {
      this.isSuppressClerk = false;
    }
    else if ((controlName == 'surplusPrintedByOnReceipt') && event[0] == true) {
      this.isSuppressPrint = false;
    }
    else if (controlName == 'packageItemOnReceipt' && event[0]) {
      this.printInfo[1].enableToggle = false; 
      this.printInfo[3].enableToggle = false;
      this.printInfo[5].enableToggle = false;
      this.printInfo[7].enableToggle = false;
    }
    else if (controlName == 'packageItemOnReceipt' && !event[0] && !this.IsViewOnly) {
      if(this.FormGrp.controls.printReceipt.value[0].packItemDesc)
      {
        this.printInfo[3].enableToggle = true;
        this.printInfo[5].enableToggle = true;
        this.printInfo[7].enableToggle = true;
      }
      this.printInfo[1].enableToggle = true;
    }
    this.FormGrp.get('printReceipt')['controls'][0].get(controlName).setValue(event[0]);
    this.enableSave();
  }

  viewChange(e) {
    this.isSaveDisabled = false;
    this.enableSave();
  }

  cancelClick() {
    this.FormGrp.reset();
    this.FormGrp.get('displayServiceCharge').setValue(1);
    this.propertyForm.reset();
    this.propertyForm.reset();
    this.getPropertyReceiptConfig();
  }

  async saveReceipt(data: any) {
    this.saveButton.disabledproperty = true;
    console.log(data);
    let receiptobj: ReceiptModel = {
      id: 0,
      outletId: this.selectedOutletId,
      numberOfReceipts: data.noOfReceipts || 0,
      displayDiscount: data.printReceipt[0].discountOnReceipt ? true : false,
      addSecondLine: data.printReceipt[0].addSecondLine ? true : false,
      displayOnlyPackageItem: data.printReceipt[0].packageItemOnReceipt ? true : false,
      suppressClerkId: data.printReceipt[0].surplusClientIdOnReceipt ? true : false,
      suppressPrintedBy: data.printReceipt[0].surplusPrintedByOnReceipt ? true : false,
      displayPackageDescription: data.printReceipt[0].packItemDesc ? true : false,
      displayPackagePrice: data.printReceipt[0].packItemPrice ? true : false,
      displayPackageAppointmentID: data.printReceipt[0].packAppId ? true : false,
      displayPackageStaffCode: data.printReceipt[0].packStaffCode ? true : false,
      serviceChargeGratuityDisplay: data.displayServiceCharge,
      gratuityLine: data.gratuityLine,
      receiptNote: data.receiptNote,
      clerkIdPrintValue: data.printReceipt[0].surplusClientIdOnReceiptbyName ? data.printReceipt[0].surplusClientIdOnReceiptbyName : 0 ,
      printedByPrintValue: data.printReceipt[0].surplusPrintedByOnReceiptbyName ? data.printReceipt[0].surplusPrintedByOnReceiptbyName : 0,
      displayDetailedCustomFee: data.printReceipt[0].displayDetailedCustomFee ? true : false,
    }
    try{
      this.OutletInfo = await this.data.createReceipt(receiptobj);
      let currOutlet = this.Outlet.filter(x => x.id == this.selectedOutletId);
      this.utils.ShowErrorMessage(this.textCaptions.Success, this.textCaptions.AfterSaveMessage + currOutlet[0].outletName, ButtonType.Ok);
      this.resetData();
    }
    catch(error){
      this.saveButton.disabledproperty = false;
    }
  }

  resetData() {
    this.isSaveDisabled = true;
    this.printInfo[3].enableToggle = false;
    this.printInfo[5].enableToggle = false;
    this.printInfo[7].enableToggle = false;
  }
  
  toggleAction(e) {
    if (e[0] == false) {
      this.DisplayAuthCode=false;
      this.propertyForm.controls["authcodeName"].setValidators(Validators.required);
      this.propertyForm.controls["authcodeName"].updateValueAndValidity();
    }
    else {
      this.DisplayAuthCode=true;
      this.propertyForm.controls["authcodeName"].clearValidators();
      this.propertyForm.controls["authcodeName"].updateValueAndValidity();
    }
    this.isSaveDisabled=false;
  }
async getPropertyReceiptConfig()
{
  this.PropertyReceiptInfo = await this.data.getPropertyReceiptConfig(); 
  if(this.PropertyReceiptInfo && this.PropertyReceiptInfo.id)
  {
    let authCode = this.PropertyReceiptInfo.configValue.authCodeReceiptName != "" ? 
     this.PropertyReceiptInfo.configValue.authCodeReceiptName : this.PropertyReceiptInfo.defaultValue.authCodeReceiptName;

     let receiptFooterNote = this.PropertyReceiptInfo.configValue.receiptFooterNote != "" ? 
     this.PropertyReceiptInfo.configValue.receiptFooterNote : "";

     let displayAuthCode = this.PropertyReceiptInfo.configValue.displayAuthCode != false ?  
     this.PropertyReceiptInfo.configValue.displayAuthCode : this.PropertyReceiptInfo.defaultValue.displayAuthCode;
     let displayChangeDue= this.PropertyReceiptInfo.configValue.displayChangeDue != false ?  
     this.PropertyReceiptInfo.configValue.displayChangeDue : this.PropertyReceiptInfo.defaultValue.displayChangeDue;
     let customFeeReceiptName= this.PropertyReceiptInfo.configValue.customFeeReceiptName ?  
     this.PropertyReceiptInfo.configValue.customFeeReceiptName : CUSTOM_FEE_RECEIPTNAME;
     let printGiftReceipt = this.PropertyReceiptInfo.configValue.printGiftReceipt != null ? this.PropertyReceiptInfo.configValue.printGiftReceipt : false;
     let printPendingSettlementReceipt = this.PropertyReceiptInfo.configValue.printPendingSettlementReceipt != null ? this.PropertyReceiptInfo.configValue.printPendingSettlementReceipt : false;
     this.propertyForm.controls["displayChangeDue"].setValue(displayChangeDue);
     this.propertyForm.controls["customFeeReceiptName"].setValue(customFeeReceiptName);
     this.propertyForm.controls["receiptFooterNote"].setValue(receiptFooterNote);
     this.propertyForm.controls["printGiftReceipt"].setValue(printGiftReceipt);
     this.propertyForm.controls["printPendingSettlementReceipt"].setValue(printPendingSettlementReceipt);
     if(displayAuthCode == true)
    {
      this.DisplayAuthCode=true;
      this.propertyForm.controls["authcodeName"].setValue(authCode);
      this.propertyForm.controls["displayAuthcode"].setValue(displayAuthCode);
      this.propertyForm.markAsPristine();
    }
    else
    {
      this.propertyForm.controls["displayAuthcode"].setValue(displayAuthCode);
      this.propertyForm.controls["authcodeName"].setValue(authCode);
      this.propertyForm.markAsPristine();
    }
  }
}

  async saveReceiptProperty(data: any) {
    console.log(data);
    this.propertySaveButton.disabledproperty = true;
    sessionStorage.removeItem("propertyReceiptConfiguration");
    if(this.PropertyReceiptInfo && this.PropertyReceiptInfo.id > 0)
    {
      let Propertyreceiptobj: PropertyReceiptModel = {
        id: this.PropertyReceiptInfo.id,
        screenName: "ReceiptConfiguration",//ScreenName.ReceiptConfiguration,
        moduleName : "Utilities",//ModuleName.Utilities,
        configValue: JSON.stringify(this.formConfigValue(data)),
        defaultValue: JSON.stringify(this.formDefaultValue(data))
      } 
      //Update call
      let propertyReceiptConfig = await this.data.updatePropertyConfig(Propertyreceiptobj);
      propertyReceiptConfig = this.parsePropertyReceiptConfig(propertyReceiptConfig);
      sessionStorage.setItem("propertyReceiptConfiguration",JSON.stringify(propertyReceiptConfig));
    }
    else{
      let Propertyreceiptobj: PropertyReceiptModel = {
        id: 0,
        screenName: "ReceiptConfiguration",//ScreenName.ReceiptConfiguration,
        moduleName : "Utilities",//ModuleName.Utilities,
        configValue: JSON.stringify(this.formConfigValue(data)),
      defaultValue: JSON.stringify(this.formDefaultValue(data))
      }
      this.PropertyReceiptInfo = await this.data.createPropertyConfig(Propertyreceiptobj);
      this.PropertyReceiptInfo  = this.parsePropertyReceiptConfig(this.PropertyReceiptInfo );
      sessionStorage.setItem("propertyReceiptConfiguration",JSON.stringify( this.PropertyReceiptInfo));

    } 
    try{
      this.utils.ShowErrorMessage(this.textCaptions.Success, this.textCaptions.AfterSaveMessage + "the Property", ButtonType.Ok);
      this.resetData();
    }
    catch(error){
      this.propertySaveButton.disabledproperty = false;
    }
  
  }
  parsePropertyReceiptConfig(propertyReceiptConfig: PropertyReceiptModel): PropertyReceiptModel {
    propertyReceiptConfig.configValue = this.isStringified(propertyReceiptConfig.configValue) ? JSON.parse(propertyReceiptConfig.configValue) : propertyReceiptConfig.configValue;
    propertyReceiptConfig.defaultValue = this.isStringified(propertyReceiptConfig.defaultValue) ? JSON.parse(propertyReceiptConfig.defaultValue) : propertyReceiptConfig.defaultValue;
    return propertyReceiptConfig;
  }
  isStringified(value: any): boolean {
    if (typeof value !== 'string') {
      return false;
    }

    try {
      JSON.parse(value);
      return true;
    } catch {
      return false;
    }
  }


  formConfigValue(data: any)
  {
    let configValue : PropertyConfigurationModel = {
      displayAuthCode: data.displayAuthcode,
      AuthCodeReceiptName: data.authcodeName,
      displayChangeDue: data.displayChangeDue,
      customFeeReceiptName: data.customFeeReceiptName,
      receiptFooterNote: data.receiptFooterNote,
      printGiftReceipt: data.printGiftReceipt,
      printPendingSettlementReceipt: data.printPendingSettlementReceipt
    }
    return configValue;
  }
  formDefaultValue(data: any)
  {
    let defaultValue : PropertyConfigurationModel = {
      displayAuthCode: false,
      AuthCodeReceiptName: "Auth Code",
      displayChangeDue: false,
      customFeeReceiptName: CUSTOM_FEE_RECEIPTNAME,
      receiptFooterNote: "",
      printGiftReceipt: false,
      printPendingSettlementReceipt: false
    }
    return defaultValue;
  }
}
