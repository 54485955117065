import { EGlobalState, GlobalStateAction } from 'src/app/eatecui/source/store/actions/global-state.action';
import { GlobalState, intialGlobalState } from 'src/app/eatecui/source/store/states/global-state';

export function GlobalStateReducer(state = intialGlobalState, action: GlobalStateAction) {
    switch (action.type) {
        case EGlobalState.GetMenuExpand: {
            return{
                ...state,
                GetMenuExpand : action.payload
            };
        }
        case EGlobalState.GetIsLoggedIn: {
            return{
                ...state,
                GetIsLoggedIn : action.payload
            };
        }
        case EGlobalState.GetAccessToken: {
            return{
                ...state,
                GetAccessToken : action.payload
            };
        }
        case EGlobalState.GetTenant: {
            return{
                ...state,
                GetTenant : action.payload
            };
        }
        case EGlobalState.GetOnPrem: {
            return{
                ...state,
                GetOnPrem : action.payload
            };
        }
        case EGlobalState.IsOutSideClick: {
            return{
                ...state,
                IsOutSideClick : action.payload
            };
        }
        default:
       return state;
    }
}