import { BaseReport } from "../common/base-report";
import { ReportUIConfig, ReportAPIOptions, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";

export class MemberServiceAvailed extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public patcheableValue) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.MemberServiceAvailed,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }
    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: null,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: "PORTRAIT"
        };
    }
    protected formReportParams(): ReportParams[] {
        let selected = this.reportFormGrp.controls["memberSearch"].value;
        let selectedClient = selected ? selected : "";
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pClientName": selectedClient }, { "pDate": this.printedDate },
        { 'pPropertyName': this.propertyName },
        { 'pPageBreak': this.pageBreakToggle }
        ];
    }
    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        let selected = this.reportFormGrp.controls["memberSearch"].value;
        return [{ "searchkey": selected }, { "startDate": toApi(this.startDate) }, { "endDate": toApi(this.endDate) }];
    }
    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        let selected = this.reportFormGrp.controls["memberSearch"].value;
        if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
            return JSON.stringify({
                fromDate: toApi(this.formGrp.get('startDate').value),
            	toDate: toApi(this.formGrp.get('endDate').value),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            });  
        }    
        return {
            searchkey:selected,
            startDate:toApi(this.startDate),
            endDate:  toApi(this.endDate)

        }
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);            

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
            
        }, 1);
    }

}