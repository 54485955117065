import { Component,  ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';



@Component({
  selector: 'custom-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CheckboxComponent   {


  @Input() customCBxDisabled = false;
  @Input() customCBxChecked: string;
  @Input() customCBxId = '';
  @Input() customCBxwrapperClass = '';
  @Input() customCBxlabelClass = '';
  @Input() customCBxInputClass = '';
  @Input() customCBxlabelText = '';
  @Input() automationId:string = '';
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();

  customCheckBox = true;
  checkedChanged = false;

  customCheckboxChange(event) {
    this.checkedChanged = true;
    event['target'] = {checked: event.checked};
    this.changeEvent.emit(event);
  }

}
