import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { TherapistComboOptions } from 'src/app/shared/business/view-settings.modals';
import { ButtonType, Host } from 'src/app/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { BaseResponse } from 'src/app/shared/shared.modal';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { SettingsService } from '../../settings.service';
import * as _ from "lodash";
import { StaffScheduleService } from '../staff-schedule.service';

@Component({
  selector: 'app-staff-schedule-bulk-delete',
  templateUrl: './staff-schedule-bulk-delete.component.html',
  styleUrls: ['./staff-schedule-bulk-delete.component.scss'],
  providers: [StaffScheduleService]
})
export class StaffScheduleBulkDeleteComponent implements OnInit {
  scheduleDeleteForm: UntypedFormGroup;
  captions: any = this.localization.captions.staffSchedule;
  minDate: Date = this.PropertyInfo.CurrentDate;
  minEndDate: Date = this.PropertyInfo.CurrentDate;
  therapistlist: TherapistComboOptions[] = [];
  commonCaptions: any;
  selectedTherapist: number;
  floatLabel: string;
  buttonClicked: boolean = false
  isInstructorSchedule:boolean=false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StaffScheduleBulkDeleteComponent>,
    public http: HttpServiceCall, 
    public fb: UntypedFormBuilder,
    private localization: SpaLocalization,
    private PropertyInfo: SpaPropertyInformation,
    private utils: SpaUtilities,
    public ss: SettingsService,
    public _su: StaffScheduleService) { 
      this.commonCaptions = this.localization.captions.common;
      this.floatLabel = this.localization.setFloatLabel;
      this._su.isInstructor = this.data?.datarecord?true:false;
      this.isInstructorSchedule = this.data?.datarecord?true:false;

    }

  ngOnInit() {
    this.therapistListGetCall();
    this.scheduleDeleteForm = this.fb.group({
      staff:['',Validators.required],
      startDate: ['',Validators.required],
      endDate: ['',Validators.required]
    });
    this.scheduleDeleteForm.controls.startDate.setValue(this.PropertyInfo.CurrentDate);
    this.scheduleDeleteForm.controls.endDate.setValue(this.utils.AddDays(this.PropertyInfo.CurrentDate,6));
  }

  SetMinimumDate():void{
    this.minEndDate =  this.scheduleDeleteForm.controls.startDate.value;
    this.buttonClicked = false;
  }

  formChange(){
    this.buttonClicked = false; 
  }

  async therapistListGetCall() {
    var keyValue = { key: "isInstructor", value:  [this.isInstructorSchedule]};
    let response: any[] = await this.ss.InvokeServiceCallAsync<any[]>('GetAllTherapist', Host.spaManagement, HttpMethod.Get,null,null,keyValue);
    if (response) {
      response = response.filter(t=> t.isActive);
      let allTherapists = response.map(x => {
        return {
          Id: x.id,
          Type: `${x.firstName} ${x.lastName}`,
          Description: "",
          Active: x.isActive
        };
      });
      this.therapistlist = _.orderBy(allTherapists, x => x.Type);
    }
  }

  async deleteSchedule() {
    this.buttonClicked = true;
    const params : any = this.isInstructorSchedule?{
      instructorId : this.scheduleDeleteForm.controls.staff.value,
      fromDate: this.localization.convertDateObjToAPIdate(this.scheduleDeleteForm.controls.startDate.value),
      toDate: this.localization.convertDateObjToAPIdate(this.scheduleDeleteForm.controls.endDate.value)
    }
    :
    {
      therapistId : this.scheduleDeleteForm.controls.staff.value,
      fromDate: this.localization.convertDateObjToAPIdate(this.scheduleDeleteForm.controls.startDate.value),
      toDate: this.localization.convertDateObjToAPIdate(this.scheduleDeleteForm.controls.endDate.value)
    };
    let apiResponse: any[] = await this.ss.InvokeServiceCallAsync<any[]>(this.isInstructorSchedule?'GetInstructorClasses':'GetTherapistAppointments', Host.schedule, HttpMethod.Get, params);
    let body = [];
            body.push({
              fromTime: params.fromDate,
              toTime: params.toDate,
            })
            let request : any = {
              schedules : body,
              isBulkDelete : true
            }
    if(apiResponse && apiResponse.length > 0){
      const errorText = this.isInstructorSchedule?this.captions.bulkDeletePopUpClass:this.captions.bulkDeletePopUp;
      this.utils.ShowErrorMessage(this.commonCaptions.Warning, errorText, ButtonType.YesNo, res => {
      if (res == "YES") {
        this._su.deleteTherapistSchedule(this.scheduleDeleteForm.controls.staff.value, request);
      }
      });
    }
    else{
      this._su.deleteTherapistSchedule(this.scheduleDeleteForm.controls.staff.value, request);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}

