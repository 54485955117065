import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';
import { GridCalculationService } from 'src/app/eatecui/source/shared/services/grid-alogrithm/grid-calculation.service';

@Component({
  selector: 'app-select-editor',
  templateUrl: './select-editor.component.html',
  styleUrls: ['./select-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownEditorComponent implements OnInit, ICellEditorAngularComp {
  params: any;
  value: any;
  defaultValue: number;
  dropdown: FormControl = new FormControl('');
  dropdownOptions: any;
  prevUnitTypeId: any;
  dropdownEditor: HTMLElement;
  constructor(
    private gridCalculationService: GridCalculationService
  ) { }

  ngOnInit(): void {
    console.log('NgOnInit');
  }

  agInit(params) {
    // console.log('Dropdown Editor', params);
    this.defaultValue = params.data['UnitTypeId'];
    const dropdownField = params['DropdownValueProperty'];
    this.params = params;
    this.dropdown.setValue(this.defaultValue);
    this.dropdownOptions = this.params.data[dropdownField];
    this.dropdownEditor = document.getElementById('dropdowm-editor');
  }

  init(params) {
    this.dropdown.valueChanges.subscribe(res => {
      this.defaultValue = parseInt(res, 10);
      const rowNode = this.params.api.getRowNode(this.params.rowIndex);
      const qty = this.defaultValue * parseInt(this.params.data.Conversion5, 10);
      rowNode.setDataValue('Qty', qty);
    });
  }

  dropdownChangeEvent = (event) => {
    // console.log('Dropdown Change Event', event);
    this.params.context.parentComponent.selectedColumn = this.params.colDef.field;
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    let prevUnit, nextUnit, nextPackSize, quantity, yieldValue;
    this.prevUnitTypeId = this.defaultValue;
    this.defaultValue = parseInt(event.target.value, 10);
    this.params.data.Qty = parseFloat( this.params.data.Qty.toString() === '' ? 0 : this.params.data.Qty.toString().split(',').join('') );
      quantity = parseFloat(this.params.data.Qty.toString() === '' ? 0 : this.params.data.Qty.toString().split(',').join(''));
      if (this.prevUnitTypeId >= 0) {
        prevUnit = this.dropdownOptions.filter(x => x.UnitTypeId === this.prevUnitTypeId);
        nextUnit = this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue);
        // If Unit Type Id > 4 means Portion and Batch Unit
        const UnitCost = nextUnit[0].UnitTypeId > 4 ? parseFloat(parseFloat(nextUnit[0].CostPerUnit.toString()).toFixed(4)) :
        parseFloat(parseFloat(nextUnit[0].UnitCost?.toString()).toFixed(4));
        const TotalAmount = this.params.data.Valued;
        // const UnitCost = nextUnit[0].UnitTypeId === 4 ? (1 / nextUnit[0].Conversion) *  this.params.data.CurrentCost :
        //  nextUnit[0].Conversion * this.params.data.CurrentCost;
        if (prevUnit != null && nextUnit != null && this.defaultValue <= 6) {
          // const prevPackSize = prevUnit[0].UnitTypeId === 4 ? (1 / prevUnit[0].Conversion) :
          //   (prevUnit[0].Conversion ? prevUnit[0].Conversion : parseFloat(this.params.data.Conversion.toString()));
          const prevPackSize = prevUnit[0].Conversion ? prevUnit[0].Conversion : parseFloat(this.params.data.Conversion.toString());
          // nextPackSize = nextUnit[0].UnitTypeId === 4 ? (1 / nextUnit[0].Conversion) : nextUnit[0].Conversion;
          if (this.params.colDef.from === 'recipe-additem') {
            nextPackSize = nextUnit[0].UnitTypeId === 4 ? (1 / nextUnit[0].Conversion) : parseFloat(nextUnit[0].Conversion);
          } else {
            nextPackSize = nextUnit[0].Conversion ? parseFloat(nextUnit[0].Conversion.toString()) : 0;
          }
          // if (this.params.colDef.from === 'recipe-additem') {
            quantity = (TotalAmount && parseFloat(TotalAmount.toString()) !== 0 &&
                        UnitCost && parseFloat(UnitCost.toString()) !== 0) ? (TotalAmount / UnitCost) : 0;
          // } else {
          //   quantity = (prevPackSize > 0 && quantity !== 0) ? ((prevPackSize * quantity) / nextPackSize) : 0;
          // }
          quantity = parseFloat(quantity).toFixed(6);
          yieldValue = parseFloat((1 / nextPackSize).toFixed(6));
          this.params.data.UnitTypeId = this.defaultValue;
          this.params.data.UnitId = nextUnit[0].UnitId;
          this.params.data.UnitCost = UnitCost ? parseFloat(UnitCost?.toString()).toFixed(4) : 0;

          const UnitCostParams: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['UnitCost'],
          };

          const UnitCostinstances = this.params.api.getCellEditorInstances(UnitCostParams);
          if (UnitCostinstances && UnitCostinstances.length > 0) {
            UnitCostinstances[0].params.data.UnitCost = parseFloat(UnitCost.toString()).toString();
            UnitCostinstances[0].value = parseFloat(UnitCost.toString());
            UnitCostinstances[0].params.value = parseFloat(UnitCost.toString());
            UnitCostinstances[0].input.value = parseFloat(UnitCost.toString());
          } else {
            const col = rowNode.columnApi.getColumn('UnitCost');
            if (col) {
              rowNode.setDataValue('UnitCost', parseFloat(UnitCost.toString()));
            }
          }
          const YieldParams: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['Yield'],
          };
          const YieldParamsinstances = this.params.api.getCellEditorInstances(YieldParams);
          if (YieldParamsinstances && YieldParamsinstances.length > 0) {
            YieldParamsinstances[0].params.data.Conversion = parseFloat(yieldValue);
            YieldParamsinstances[0].value = parseFloat(yieldValue);
            YieldParamsinstances[0].params.value = parseFloat(yieldValue);
            YieldParamsinstances[0].input.value = parseFloat(yieldValue);
          }
          else {
            rowNode.setDataValue('Yield', parseFloat(yieldValue));
          }
          const ConversionParams: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['Conversion'],
          };

          const Conversioninstances = this.params.api.getCellEditorInstances(ConversionParams);
          if (Conversioninstances && Conversioninstances.length > 0) {
            Conversioninstances[0].params.data.Conversion = parseFloat(nextPackSize).toFixed(6);
            Conversioninstances[0].value = parseFloat(nextPackSize).toFixed(6);
            Conversioninstances[0].params.value = parseFloat(nextPackSize).toFixed(6);
            Conversioninstances[0].input.value = parseFloat(nextPackSize).toFixed(6);
          } else {
            rowNode.setDataValue('Conversion', parseFloat(nextPackSize ? nextPackSize.toString() : '0').toFixed(6));
          }
          // if (this.params.colDef.from !== 'recipe-additem') {
          //   const ValuedParams: GetCellEditorInstancesParams = {
          //     rowNodes: [rowNode],
          //     columns: ['Valued'],
          //   };
  
          //   const Valuedinstances = this.params.api.getCellEditorInstances(ValuedParams);
          //   if (Valuedinstances && Valuedinstances.length > 0) {
          //     Valuedinstances[0].params.data.Valued = parseFloat((quantity * this.params.data.UnitCost).toString()).toFixed(2);
          //     Valuedinstances[0].value = parseFloat((quantity * this.params.data.UnitCost).toString()).toFixed(2);
          //     Valuedinstances[0].params.value = parseFloat((quantity * this.params.data.UnitCost).toString()).toFixed(2);
          //     Valuedinstances[0].input.value = parseFloat((quantity * this.params.data.UnitCost).toString()).toFixed(2);
          //   } else {
          //     rowNode.setDataValue('Valued', parseFloat((quantity * this.params.data.UnitCost).toString()).toFixed(2));
          //   }
          // }
          // rowNode.setDataValue('Valued', parseFloat((quantity * this.params.data.UnitCost).toString()));
          // rowNode.setDataValue('Conversion', parseFloat((nextPackSize).toString()));
          const data: GetCellEditorInstancesParams = {
            rowNodes: [rowNode], // specify rows, or all rows by default
            columns: ['Qty'], // specify columns, or all columns by default
          };
          const instances = this.params.api.getCellEditorInstances(data);
          if (instances.length > 0) {
            instances[0].params.data.Qty = parseFloat((quantity).toString());
            instances[0].value = parseFloat((quantity).toString());
            instances[0].params.value = parseFloat((quantity).toString());
            instances[0].input.value = parseFloat((quantity).toString());
          }
          const UnitCostInFCValue = (UnitCost * this.params.data.ExchangeRate).toFixed(4);
          const UnitCostInFC: GetCellEditorInstancesParams = {
            rowNodes: [rowNode], // specify rows, or all rows by default
            columns: ['UnitCostInFC'], // specify columns, or all columns by default
          };
          const UnitCostInFCinstances = this.params.api.getCellEditorInstances(UnitCostInFC);
          if (instances.length > 0) {
            UnitCostInFCinstances[0].params.data.Qty = parseFloat((UnitCostInFCValue).toString());
            UnitCostInFCinstances[0].value = parseFloat((UnitCostInFCValue).toString());
            UnitCostInFCinstances[0].params.value = parseFloat((UnitCostInFCValue).toString());
            UnitCostInFCinstances[0].input.value = parseFloat((UnitCostInFCValue).toString());
          } else {
            rowNode.setDataValue('UnitCostInFC', parseFloat(UnitCostInFCValue));
          }
          let TaxAmount = 0;
          const TaxRate1 = this.params.data.TaxRate1;
          if (TaxRate1 && this.params.data.OnRecipt) {
            TaxAmount = (TaxRate1 / 100) * parseFloat(TotalAmount);
          }
          let TaxAmountVal2 = 0;
          const TaxRate2 = this.params.data.TaxRate2;
          if (TaxRate2 && this.params.data.OnRecipt) {
            TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(TotalAmount);
          }
          rowNode.setDataValue('TaxAmount1', parseFloat(TaxAmount.toFixed(4)));
          rowNode.setDataValue('TaxAmount2', parseFloat(TaxAmountVal2.toFixed(4)));
        } else {
          this.params.data.UnitTypeId = this.defaultValue;
          this.params.data.UnitId = nextUnit[0].UnitId;
          this.params.fromUnit = this.prevUnitTypeId;
          this.params.toUnit = this.defaultValue;
          this.params.context.parentComponent.onCellValueChanged(this.params);
          // if (this.params.hasOwnProperty('colDef') && this.params.colDef.valueFormatter) {
          //   this.params.colDef.valueFormatter(this.params);
          // } else {
          //   const ColumnInfo = this.params.api.columnController.columnDefs.filter(x => x.field === this.params.colDef.field)[0];
          //   ColumnInfo.valueFormatter(this.params);
          // }
        }
        this.gridCalculationService.calculateForeignCurrency(this.params);
      }
  }

  getValue(): any {
    // console.log('Changed data', this.defaultValue);
    if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
    }
    if (this.dropdownOptions && this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue).length > 0) {
      const returnValue = this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue)[0].UnitCode;
      console.log('Return Value', returnValue, this.params.data);
      return returnValue;
    }
  }

  focusIn() {
    const selectEditor = document.getElementById('dropdowm-editor');
    if (selectEditor) {
      selectEditor.focus();
    }
  }

  afterGuiAttached() {
    try {
      if (this.dropdownEditor && (this.params.context.parentComponent.selectedColumn === this.params.colDef.field)) {
            this.dropdownEditor.focus();
      }
    } catch (error) {
      console.error('Error occurred in number cell editor component of afterGuiAttached function', error);
    }
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  keyDown(event, params: ICellEditorParams) {
    // console.log('key down', event);
    if (event.key === KEY_ESCAPE) {
      this.params.api.stopEditing();
    }
  }
}

const KEY_ESCAPE = 'Escape';
