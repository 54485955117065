export class PMSIntegrationHostConfiguration {   
    hostId: Number;   
    configurationKey: String;
    configurationValue: String;  
}

export const PMSCommunicationReceiverGateway = 'commongateway';

export const MultiPropertyDefaultToggle = {
    DefaultRoomCharge: "defaultRoomCharge",
    DefaultGroupCharge: "defaultGroupCharge",
    DefaultHotelComp: "defaultHotelComp",
    DefaultCityLedger: "defaultCityLedger",
    DefaultRevenuePosting: "defaultRevenuePosting"
}