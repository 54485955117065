import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ENDPOINTURL } from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { NutrientsIdentify, NutrientVitaminUSDA } from 'src/app/eatecui/source/setup-master/shared/enum/master-enum';
import { MasterFormConfiguration, SubFormConfigs } from '../../../setup-master/shared/interface/master.interface';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataSource } from '@angular/cdk/collections';
import { RequestCache } from 'src/app/eatecui/source/core/interceptors/services/request-cache.service';
import { CacheType } from 'src/app/eatecui/source/shared/enum/global.enum';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { PrintService } from 'src/app/eatecui/source/shared/services/print.service';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

@Component({
  selector: 'app-nutrients',
  templateUrl: './nutrients.component.html',
  styleUrls: ['./nutrients.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class NutrientsComponent implements OnInit, OnChanges, AfterViewInit {
  homeGroup: FormGroup;
  MoreOptionhomeGroup: FormGroup;
  FieldType: Array<any> = [];
  displayedColumns: Array<any> = [];
  dataSource = new MatTableDataSource;
  coloriesGroup: FormGroup;
  selectableRow: number;
  @Input() subFormConfigs: SubFormConfigs;
  @Input() ParentFormGroup: FormGroup;
  @Input() tabchangeEvent: MatTabChangeEvent;
  @Input() selectedId: number;
  lockNutritionValue: number;
  visibleDecimalValue: number;
  focus: boolean;
  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef,
    private httpService: HttpService, private dialog: MatDialog, private requestCache: RequestCache,
    private sysytemPreferenceService: SysytemPreferenceService, private printService: PrintService,
    private commonService: CommonService) {
    this.visibleDecimalValue = this.commonService.GetVisibleNumberLength();
    this.homeGroup = this.formBuilder.group({});
    this.coloriesGroup = this.formBuilder.group({});
    this.MoreOptionhomeGroup = this.formBuilder.group({});
    this.lockNutritionValue = 0;
    this.selectableRow = 1;
    // this.coloriesGroup.valueChanges.subscribe(x => console.log(x));
    if (this.subFormConfigs && this.subFormConfigs.hasOwnProperty('caloriesCalculation')) {
      this.dataSource = new MatTableDataSource(this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData);
    }
    if (this.sysytemPreferenceService && this.sysytemPreferenceService.globalSystemPreference) {
      const getGropData = this.sysytemPreferenceService.globalSystemPreference.filter(x => x.GroupName === 'Inventory Options');
      if (getGropData && getGropData.length > 0) {
        const locknutrition = getGropData.filter(x => x.LookupName === 'Locknutritionwhenitemlinked');
        if (locknutrition && locknutrition.length > 0) {
          this.lockNutritionValue = locknutrition[0].LookupValue;
        }
      }
    }
    // if ( this.subFormConfigs && this.subFormConfigs.hasOwnProperty('OdataKey') && this.subFormConfigs.OdataKey === 'RecipeNutritions' ) {
    //   this.lockNutritionValue = 1;
    // }
  }
  rowSelected(rowIndex: number, element: any): void {
    try {
         this.selectableRow = rowIndex;
         if ( this.subFormConfigs.caloriesCalculation.CaloriesGrid.hasOwnProperty('selectableRow')) {
           this.subFormConfigs.caloriesCalculation.CaloriesGrid.selectableRow = element;
           this.subFormConfigs.caloriesCalculation.CaloriesGrid.enablePopup = true;
         }

    } catch (error) {
      console.error(error);
    }
  }
  calculateCaloriesKilograms(FctValue: number, NutrientsRDValue: number, Kilograms: number,
    NutrientValue1: number, percentage: number, element: any, MdsValue: number): void {
    if (FctValue > 0) {
      element['NutrientValue1'] = Kilograms / FctValue;
    }
    if (NutrientsRDValue >= 0.1) {
      const PercentageFct = 100 * (element['NutrientValue2'] / (MdsValue * NutrientsRDValue));
      if (PercentageFct > 0 && PercentageFct < 1) {
        const belowPercentageFct = '1.00';
        element['NutrientPercentageValue'] = `< ${belowPercentageFct}`;
      } else {
        element['NutrientPercentageValue'] = PercentageFct ? parseFloat((PercentageFct).toString()).toFixed(6) :
        parseFloat('0').toFixed(6);
      }
    } else {
      element['NutrientPercentageValue'] = 'N/A';
    }
  }

  applicationprint(DivUniqueId: string): void {
   try {
    this.printService.applicationPrint(DivUniqueId);
   } catch ( error ) {
     console.error(error);
   }
  }

  calculateCaloriesgrams(FctValue: number, NutrientsRDValue: number, Kilograms: number,
    NutrientValue1: number, percentage: number, element: any, MdsValue: number): void {
    if (FctValue > 0) {
      element['NutrientValue2'] = NutrientValue1 * FctValue;
    }
    if (NutrientsRDValue >= 0.1) {
      const PercentageFct = 100 * element['NutrientValue1'] / (MdsValue * NutrientsRDValue);
      if (PercentageFct > 0 && PercentageFct < 1) {
        const belowPercentageFct = '1.00';
        element['NutrientPercentageValue'] = `< ${belowPercentageFct}`;
      } else {
        element['NutrientPercentageValue'] = PercentageFct ? parseFloat((PercentageFct).toString()).toFixed(6) :
        parseFloat('0').toFixed(6);
      }
    } else {
      element['NutrientPercentageValue'] = 'N/A';
    }
  }

  calculateCaloriesPercentage(FctValue: number, NutrientsRDValue: number, Kilograms: number,
    NutrientValue1: number, percentage: number, element: any, MdsValue: number): void {
    if (FctValue > 0 && NutrientsRDValue > 0 && percentage !== null && percentage !== undefined) {
      percentage = parseFloat(percentage.toString().replace('< ', ''));
      element['NutrientValue2'] = parseFloat((percentage * FctValue * (MdsValue * NutrientsRDValue) / 100).toString()).toFixed(6);
      element['NutrientValue1'] = parseFloat((element['NutrientValue2'] / FctValue).toString()).toFixed(6);
    }

  }

  calcNutrientColumns(FctValue: number, NutrientsRDValue: number, NutrientValue2: number,
    NutrientValue1: number, percentage: number, element: any, MdsValue: number): void {
    if (FctValue > 0) {
      element['NutrientValue1'] = NutrientValue2 / FctValue;
    }
    if (NutrientsRDValue >= 0.1) {
      const PercentageFct = 100 * (element['NutrientValue2'] / (MdsValue * NutrientsRDValue));
      if (PercentageFct > 0 && PercentageFct < 1) {
        const belowPercentageFct = '1.00';
        element['NutrientPercentageValue'] = `< ${belowPercentageFct}`;
      } else {
        element['NutrientPercentageValue'] = PercentageFct ? parseFloat(PercentageFct.toString()).toFixed(6) : parseFloat('0').toFixed(6);
      }
    } else {
      element['NutrientPercentageValue'] = 'N/A';
    }
    return element;
  }

  vitaminACalculation(element: any, USDA_NO: string, ColumnName: string, FctValue: number, NutrientRDAValue: number, Kilograms: number,
    NutrientValue1: number, percentage: number, MdsValue: number) {
    let aiu: any;
    let are: any;
    // Check whether Vitamin A(re) row is present in grid
    const cn = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.filter(x => x.USDA_No ===
      NutrientVitaminUSDA.VitaminARE).length > 0 ? true : false;
    let VitaminIURow = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.filter(x => x.USDA_No
      === NutrientVitaminUSDA.VitaminAIU)[0];
    let VitaminRERow = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.filter(x => x.USDA_No
      === NutrientVitaminUSDA.VitaminARE)[0];
    let VitaminREURow = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.filter(x => x.USDA_No
      === NutrientVitaminUSDA.VitaminAREU)[0];
    if (VitaminIURow && VitaminIURow[NutrientsIdentify.NutrientValue2] !== 'N/A') {
      aiu = VitaminIURow[NutrientsIdentify.NutrientValue2];
    } else {
      aiu = -10;
    }
    if (cn) {
      if (VitaminRERow && VitaminRERow[NutrientsIdentify.NutrientValue2] !== 'N/A') {
        are = VitaminRERow[NutrientsIdentify.NutrientValue2];
      } else {
        are = -10;
      }
    } else {
      if (VitaminREURow && VitaminREURow[NutrientsIdentify.NutrientValue2] !== 'N/A') {
        are = VitaminREURow[NutrientsIdentify.NutrientValue2];
      } else {
        are = -10;
      }
    }
    if (USDA_NO === NutrientVitaminUSDA.VitaminARE || USDA_NO === NutrientVitaminUSDA.VitaminAREU) {
      aiu = are * 5;
    } else if (USDA_NO === NutrientVitaminUSDA.VitaminAIU) {
      are = aiu / 5;
    }
    VitaminIURow[NutrientsIdentify.NutrientValue2] = aiu > -1 ? aiu : '';
    if (cn) {
      VitaminRERow[NutrientsIdentify.NutrientValue2] = are > -1 ? are : '';
    } else {
      VitaminREURow[NutrientsIdentify.NutrientValue2] = are > -1 ? are : '';
    }
    // Only of value changed in NutrientValue2 column
    if (ColumnName === NutrientsIdentify.NutrientValue2) {
      if (VitaminIURow.NutrientRDAValue >= 0.1 && aiu > -1) {
        VitaminIURow[NutrientsIdentify.NutrientPercentageValue] = (100 * aiu) / VitaminIURow.NutrientRDAValue;
      }

      if (cn) {
        if (VitaminRERow.NutrientRDAValue >= 0.1 && aiu > -1) {
          VitaminRERow[NutrientsIdentify.NutrientPercentageValue] = (100 * are) / VitaminRERow.NutrientRDAValue;
        }
      } else {
        if (VitaminREURow.NutrientRDAValue >= 0.1 && aiu > -1) {
          VitaminREURow[NutrientsIdentify.NutrientPercentageValue] = (100 * are) / VitaminREURow.NutrientRDAValue;
        }
      }
    }
    if (cn) {
      VitaminRERow[NutrientsIdentify.NutrientPercentageValue] = 'N/A';
    } else {
      VitaminREURow[NutrientsIdentify.NutrientPercentageValue] = 'N/A';
    }
    // assigning to Grid the updated data;
    const VitaminIURowIndex = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.findIndex(x => x.USDA_No
      === NutrientVitaminUSDA.VitaminAIU);
    const VitaminRERowIndex = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.findIndex(x => x.USDA_No
      === NutrientVitaminUSDA.VitaminARE);
    const VitaminREURowIndex = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.findIndex(x => x.USDA_No
      === NutrientVitaminUSDA.VitaminAREU);
    VitaminIURow = this.calcNutrientColumns(FctValue, VitaminIURow.NutrientRDAValue, VitaminIURow.NutrientValue2,
      VitaminIURow.NutrientValue1, VitaminIURow.NutrientPercentageValue, VitaminIURow, 1);
    if (cn) {
      VitaminRERow = this.calcNutrientColumns(FctValue, VitaminRERow.NutrientRDAValue, VitaminRERow.NutrientValue2,
        VitaminRERow.NutrientValue1, VitaminRERow.NutrientPercentageValue, VitaminRERow, 1);
    } else {
      VitaminREURow = this.calcNutrientColumns(FctValue, VitaminREURow.NutrientRDAValue, VitaminREURow.NutrientValue2,
        VitaminREURow.NutrientValue1, VitaminREURow.NutrientPercentageValue, VitaminREURow, 1);
    }
    this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData[VitaminIURowIndex] = VitaminIURow;
    if (cn) {
      this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData[VitaminRERowIndex] = VitaminRERow;
    } else {
      this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData[VitaminREURowIndex] = VitaminREURow;
    }
    this.dataSource = new MatTableDataSource(this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData);
  }

  caloriescalculation(element: any, calories: any, event: any, index: number = 0, idx: number = 0) {
    try {
      const granservingBool = (this.homeGroup.controls['ServingSize'].value === '' ||
        this.homeGroup.controls['ServingSize'].value === null) ? false : true;
      if (granservingBool) {
        const gramsServing = (this.homeGroup.controls['ServingSize'].value === '') ? 0 :
          this.homeGroup.controls['ServingSize'].value;
        const FctValue = gramsServing && gramsServing > 0 ? 100 / gramsServing : 0;
        const NutrientRDAValue = element['NutrientRDAValue'];
        const MdsValue = 1;
        const NutrientValue2 = (element['NutrientValue2'] !== '' || element['NutrientValue2'] !== null) ? element['NutrientValue2'] : 0;
        const NutrientValue1 = (element['NutrientValue1'] !== '' || element['NutrientValue1'] !== null) ? element['NutrientValue1'] : 0;
        const percentage = (element['NutrientPercentageValue'] !== '' || element['NutrientPercentageValue'] !== null) ? element['NutrientPercentageValue'] : 0;
        const USDA_NO = (element['USDA_No'] !== '' || element['USDA_No'] !== null) ? element['USDA_No'] : 0;
        switch (calories.FieldName) {
          case NutrientsIdentify.NutrientValue2:
            this.calculateCaloriesKilograms(FctValue, NutrientRDAValue, NutrientValue2, NutrientValue1, percentage, element, MdsValue);
            // Check whether edited row is Vitamin A(iu) - 318 or Vitamin A(re) - 392
            if (USDA_NO === NutrientVitaminUSDA.VitaminAIU || USDA_NO === NutrientVitaminUSDA.VitaminARE
              || USDA_NO === NutrientVitaminUSDA.VitaminAREU) {
              this.vitaminACalculation(element, USDA_NO, NutrientsIdentify.NutrientValue2,
                FctValue, NutrientRDAValue, NutrientValue2, NutrientValue1, percentage, MdsValue);
            }
            break;
          case NutrientsIdentify.NutrientValue1:
            this.calculateCaloriesgrams(FctValue, NutrientRDAValue, NutrientValue2, NutrientValue1, percentage, element, MdsValue);
            // Check whether edited row is Vitamin A(iu) - 318 or Vitamin A(re) - 392
            if (USDA_NO === NutrientVitaminUSDA.VitaminAIU || USDA_NO === NutrientVitaminUSDA.VitaminARE
              || USDA_NO === NutrientVitaminUSDA.VitaminAREU) {
              this.vitaminACalculation(element, USDA_NO, NutrientsIdentify.NutrientValue2,
                FctValue, NutrientRDAValue, NutrientValue2, NutrientValue1, percentage, MdsValue);
            }
            break;
          case NutrientsIdentify.NutrientPercentageValue:
            this.calculateCaloriesPercentage(FctValue, NutrientRDAValue, NutrientValue2, NutrientValue1, percentage, element, MdsValue);
            // Check whether edited row is Vitamin A(iu) - 318 or Vitamin A(re) - 392
            if (USDA_NO === NutrientVitaminUSDA.VitaminAIU || USDA_NO === NutrientVitaminUSDA.VitaminARE
              || USDA_NO === NutrientVitaminUSDA.VitaminAREU) {
              this.vitaminACalculation(element, USDA_NO, NutrientsIdentify.NutrientValue2,
                FctValue, NutrientRDAValue, NutrientValue2, NutrientValue1, percentage, MdsValue);
            }
            break;
        }
      } else {
        element[calories.FieldName] = '';
      }
      this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData = this.dataSource.filteredData;
    } catch (error) {
      console.error('error', error);
    }
  }
  lbunitCalculation(NestedForm: any): void {
    try {
      if (this.subFormConfigs.OdataKey === 'IngredientsNutritions') {
        const gramsPerServing = (NestedForm.controls.ServingSize.value === '' &&
          NestedForm.controls.ServingSize.value === null) ? 0 : NestedForm.controls.ServingSize.value;
        const servingBaseUnit = (NestedForm.controls.Serving.value === '' &&
          NestedForm.controls.Serving.value === null) ? 0 : NestedForm.controls.Serving.value;
        const calculateLbUnit = gramsPerServing * servingBaseUnit / 100;
        NestedForm.controls.Conversion.setValue(calculateLbUnit);
      }
    } catch (error) {
      console.error(error);
    }
  }
  checkUnitConversion(): void {
    try {
      const getUnitId = (this.ParentFormGroup && this.ParentFormGroup.controls.hasOwnProperty('BaseUnit') &&
        this.ParentFormGroup.controls.BaseUnit.value > 0) ? this.ParentFormGroup.controls.BaseUnit.value : null;
      if (getUnitId) {
        const endUnitPoint = ENDPOINTURL['InvertoryManagement']['GetUnits'];
        const endPointUrl = `${endUnitPoint}` + `?$filter=Id eq ${getUnitId}`;
        this.httpService.GethttpMethod(endPointUrl).subscribe(x => {
          const getUnitCategoryId = x['value'][0].UnitCategoryId;
          if (getUnitCategoryId && getUnitCategoryId === 1) {
            const endPoint = ENDPOINTURL['InvertoryManagement']['GetUnitConversion'];
            const UnitMeasurementSystemId = this.sysytemPreferenceService.globalSystemPreference.
                            filter(y => y.LookupName === 'UnitMeasurementSystem')[0].LookupValue;
            const converionEndPointUrl = 
            `${endPoint}` + `?$filter=FromUnit eq 4 and ToUnit eq ${getUnitId} and UnitMeasurementSystemId eq ${UnitMeasurementSystemId}`;
            this.httpService.GethttpMethod(converionEndPointUrl).subscribe(y => {
              const conversionValue = y['value'][0]['Conversion'];
              const lbValue = conversionValue / 100;
              if (this.homeGroup.controls.hasOwnProperty('Conversion')) {
                this.homeGroup.controls.Conversion.setValue(lbValue);
              }
              if (this.homeGroup.controls.hasOwnProperty('ServingSize')) {
                this.homeGroup.controls.ServingSize.setValue(100);
              }
              if (this.homeGroup.controls.hasOwnProperty('Serving')) {
                this.homeGroup.controls.Serving.setValue(1);
              }
              const gramsPerServing = (this.homeGroup.controls.ServingSize.value === '' &&
                this.homeGroup.controls.ServingSize.value === null) ? 0 : this.homeGroup.controls.ServingSize.value;
              this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(gridheader => {
                if (gridheader.FieldName === 'NutrientValue1') {
                  const DisplayValue = Formater('ServingSize', gramsPerServing, null, null);
                  gridheader.DisplayName = `/${DisplayValue} gms`;
                }
              });
              this.subFormConfigs.NestedForm.forEach(yx => {
                yx.FormFields.forEach(e => {
                  if (e.fieldType.SingleData.name === 'Conversion' || e.fieldType.SingleData.name === 'Serving' ||
                    e.fieldType.SingleData.name === 'ServingSize' ) {
                    e.fieldType.SingleData.disbaledProperty = true;
                  }
                });
              });
            });
          } else {
            setTimeout(() => {
              if (this.homeGroup.controls.hasOwnProperty('Conversion')) {
                this.homeGroup.controls.Conversion.setValue('');
              }
              if (this.homeGroup.controls.hasOwnProperty('ServingSize')) {
                this.homeGroup.controls.ServingSize.setValue('');
              }
              if (this.homeGroup.controls.hasOwnProperty('Serving')) {
                this.homeGroup.controls.Serving.setValue('');
              }
              this.subFormConfigs.NestedForm.forEach(y => {
                y.FormFields.forEach(e => {
                  if (e.fieldType.SingleData.name === 'Conversion' || e.fieldType.SingleData.name === 'Serving' ||
                    e.fieldType.SingleData.name === 'ServingSize' ) {
                    e.fieldType.SingleData.disbaledProperty = false;
                  }
                });
              });
            }, 100);
          }
        });
      } else {
        setTimeout(() => {
          if (this.homeGroup.controls.hasOwnProperty('Conversion')) {
            this.homeGroup.controls.Conversion.setValue('');
          }
          if (this.homeGroup.controls.hasOwnProperty('ServingSize')) {
            this.homeGroup.controls.ServingSize.setValue('');
          }
          if (this.homeGroup.controls.hasOwnProperty('Serving')) {
            this.homeGroup.controls.Serving.setValue('');
          }
          if (this.subFormConfigs.NestedForm) {
            this.subFormConfigs.NestedForm.forEach(y => {
              y.FormFields.forEach(e => {
                if (e.fieldType.SingleData.name === 'Conversion' || e.fieldType.SingleData.name === 'Serving' ||
                  e.fieldType.SingleData.name === 'ServingSize' ) {
                  e.fieldType.SingleData.disbaledProperty = false;
                }
              });
            });
          }
        }, 100);
      }
    } catch (error) {
      console.error(error);
    }
  }
  servingCalculation(Form: any): void {
    try {
      this.lbunitCalculation(Form);
      const gramsPerServing = (Form.controls.ServingSize.value === '' ||
        Form.controls.ServingSize.value === null) ? 0 : Form.controls.ServingSize.value;
      this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(gridheader => {
        if (gridheader.FieldName === 'NutrientValue1') {
          const DisplayValue = Formater('ServingSize', gramsPerServing, null, null);
          gridheader.DisplayName = `/${DisplayValue} gms`;
        }
      });
      // Calories Grid Data Calculation after serving size value change
      this.recalculateCalories();
    } catch (error) {
      console.error(error);
    }
  }

  ngAfterViewInit() {
    if (this.subFormConfigs && this.subFormConfigs.hasOwnProperty('caloriesCalculation') && this.subFormConfigs.NestedForm) {
      this.subFormConfigs.NestedForm.forEach(x => {
        x.FormFields.forEach(e => {
          if (e.fieldType.SingleData.name === 'ServingSize') {
            e.fieldType.SingleData.onCellClick = (field: any, Value: any, Form: any) => {
               this.servingCalculation(Form);
            };
          }
          if (e.fieldType.SingleData.name === 'Serving') {
            e.fieldType.SingleData.onCellClick = (field: any, Value: any, Form: any) => {
              this.lbunitCalculation(Form);
            };
          }
        });
      });
    }
    if (!this.selectedId) {
      this.checkUnitConversion();
    }
    if (this.homeGroup && this.homeGroup.controls && this.homeGroup.controls['Ndb_No'] && this.homeGroup.controls['Ndb_No'].value) {
      const USDALinkFeild = this.subFormConfigs.NestedForm.find(e => e.FormHeaderName === 'USDA Nutrition Link');
      if (USDALinkFeild) {
        USDALinkFeild.FormFields[0].fieldType.SingleData.disbaledProperty = true;
      }
    }
  }
  getCaloriesData(): void {
    try {
      if (this.subFormConfigs && this.subFormConfigs.hasOwnProperty('caloriesCalculation')) {
        const GetTransTypeId = this.getTransType( this.subFormConfigs.OdataKey );
        const transaTypeId = `transType=${GetTransTypeId}`;
        const endPointUrl = ENDPOINTURL['InvertoryManagement']['GetInventoryNutrition'] + `/'0'?` + transaTypeId;
        this.httpService.GethttpMethod(endPointUrl).subscribe(x => {
          const nutrientsData = x;
          const detailsCollection = (this.subFormConfigs.OdataKey === 'IngredientsNutritions') ? 'InventoryNutritionDetails' :
            'ProductNutritionDetails';
          this.dataSource = new MatTableDataSource(nutrientsData['InventoryNutritionDetails']);
        }, err => console.log('HTTP Error', err));

      }
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method getTransferType()
   * @Input Odatakey
   * @outpt TranasferType
   */
   getTransType(Odatakey: string): number {
     try {
      let TransTypeId = null;
      switch ( Odatakey ) {
        case 'IngredientsNutritions':
          TransTypeId = 1;
          break;
        case 'ProductNutritions':
          TransTypeId = 2;
          break;
          case 'RecipeNutritions':
          TransTypeId = 3;
          break;
        default:
          TransTypeId = 1;
      }
      return TransTypeId;
     } catch ( error ) {
       console.error(error);
     }
   }
  ngOnChanges() {
    // if ( this.subFormConfigs && this.subFormConfigs.hasOwnProperty('OdataKey') && this.subFormConfigs.OdataKey === 'RecipeNutritions' ) {
    //   this.lockNutritionValue = 1;
    // }
    this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(gridheader => {
      if (gridheader.FieldName === 'NutrientValue2') {
        const DisplayValue = Formater('ServingSize', '100', null, null);
        gridheader.DisplayName = `/${DisplayValue} gms`;
        console.log('Value', gridheader);
      }
    });
    if (this.subFormConfigs && this.subFormConfigs.hasOwnProperty('caloriesCalculation')) {
      this.dataSource = new MatTableDataSource(this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData);
      this.displayedColumns = this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(x => x.FieldName);
      if (!this.selectedId) {
        if (!this.homeGroup.value.USDAItem) { // USDA Item Linked no need to clear
          this.getCaloriesData();
          this.checkUnitConversion();
        }
      } else {
        if (this.selectedId) {
          const GetTransTypeId = this.getTransType( this.subFormConfigs.OdataKey );
          const transaTypeId = `transType=${GetTransTypeId}`;
          const endPointUrl = ENDPOINTURL['InvertoryManagement']['GetInventoryNutrition'] + `/'${this.selectedId}'?` + transaTypeId;
          this.requestCache.manageCacheEngine(CacheType.CLEARINDIVUAL, endPointUrl);
          this.httpService.GethttpMethod(endPointUrl).subscribe(x => {
            if (x) {
              const nutrientsData = x;
              if ( this.subFormConfigs.hasOwnProperty('nutrientsData') ) {
                this.subFormConfigs.nutrientsData = x;
                if ( x.hasOwnProperty('InventoryNutritionDetails') && x['InventoryNutritionDetails'].length > 0 ) {
                  this.subFormConfigs.caloriesCalculation.CaloriesGrid.selectableRow = x['InventoryNutritionDetails'][1];
                }
              }
              if (this.subFormConfigs.Type !== 'listing') {
                setTimeout(() => {
                  if (x.hasOwnProperty('Allergy') && x['Allergy'] !== '' && x['Allergy'] !== null) {
                    const allergyList = x;
                    if ( allergyList.hasOwnProperty('Allergens') && allergyList !== null) {
                      const allergyCollections = x['Allergens'].map((e: any) => e.Id);
                      x['Allergy'] = allergyCollections;
                      this.homeGroup.controls.Allergy.setValue(allergyCollections);
                    }
                  }
                  this.homeGroup.patchValue(x);
                  const gramsPerServing = (this.homeGroup.controls['ServingSize'].value !== '' &&
                    this.homeGroup.controls['ServingSize'].value !== null) ? this.homeGroup.controls['ServingSize'].value : 0;
                  this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(gridheader => {
                    if (gridheader.FieldName === 'NutrientValue1') {
                      const DisplayValue = Formater('ServingSize', gramsPerServing, null, null);
                      gridheader.DisplayName = `/${DisplayValue} gms`;
                    }
                  });
                  if (this.subFormConfigs && this.subFormConfigs.NestedForm) {
                    const labelInformation = this.subFormConfigs.NestedForm.filter(e => e.FormName === 'Product Serving Size / Nutrition Calculator');
                    if (labelInformation && labelInformation.length > 0) {
                      if (labelInformation[0].FormFields && labelInformation[0].FormFields.length > 0) {
                        const fieldInfo = labelInformation[0].FormFields.filter(f =>
                          (f.fieldType.SingleData.name === 'Serving')
                          && this.lockNutritionValue === 1);

                        if (fieldInfo && fieldInfo.length > 0 && this.lockNutritionValue === 1) {
                          fieldInfo.forEach(item => {
                            item.fieldType.SingleData.disbaledProperty = true;
                          });
                          // fieldInfo[0].fieldType.SingleData.disbaledProperty = true;
                        }
                      }
                    }

                    if (this.homeGroup && this.homeGroup.controls['Ndb_No'].value) {
                      const USDALinkFeild = this.subFormConfigs.NestedForm.find(e => e.FormHeaderName === 'USDA Nutrition Link');
                      if (USDALinkFeild) {
                        USDALinkFeild.FormFields[0].fieldType.SingleData.disbaledProperty = true;
                      }
                    }
                    this.subFormConfigs.NestedForm.forEach(f => {
                      f.FormFields.forEach(e => {
                        if (e.fieldType.SingleData.name === 'ServingSize') {
                          e.fieldType.SingleData.onCellClick = (field: any, Value: any, Form: any) => {
                             this.servingCalculation(Form);
                          };
                        }
                      });
                    });
                  }
                }, 100);
                if (x['ServingSize'] === null || x['ServingSize'] === undefined || x['ServingSize'] === '') {
                  this.checkUnitConversion();
                }
              }
              const updateDetailsCollection = (this.subFormConfigs.OdataKey === 'IngredientsNutritions') ? 'InventoryNutritionDetails' :
                'ProductNutritionDetails';
              this.dataSource = new MatTableDataSource(nutrientsData['InventoryNutritionDetails']);
              if (this.subFormConfigs.Type === 'listing') {
                  this.subFormConfigs.data.forEach(y => {
                    y.NestedData.forEach(data => {
                      data.Visible = true;
                      data.Value = (data.OdataKey === 'Allergy') ?
                        (nutrientsData.hasOwnProperty('Allergens') && nutrientsData['Allergens'] !== null &&
                        nutrientsData['Allergens'].length > 0) ? nutrientsData['Allergens'].map(a => a.Name).join() : '-'
                        : Formater(data.OdataKey, nutrientsData[data.OdataKey], null, null);
                    });
                  });
                const gramsPerServing = (nutrientsData['ServingSize'] === '' ||
                  nutrientsData['ServingSize'] === null) ? 0 : nutrientsData['ServingSize'];
                this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(gridheader => {
                  if (gridheader.FieldName === 'NutrientValue1') {
                    const DisplayValue = Formater('ServingSize', gramsPerServing, null, null);
                    gridheader.DisplayName = `/${DisplayValue} gms`;
                  }
                });
                // nutrientsData.filter(i => i.hasOwnProperty('Allergens') &&
                //     i.Allergens !== null && i.Allergens.length > 0 ).map(a => a.Name.join())
              }
              if (this.subFormConfigs && this.subFormConfigs.NestedForm) {
                const labelInformation = this.subFormConfigs.NestedForm.filter(e => e.FormName === 'Product Serving Size / Nutrition Calculator');
                if (labelInformation && labelInformation.length > 0) {
                  if (labelInformation[0].FormFields && labelInformation[0].FormFields.length > 0) {
                    const fieldInfo = labelInformation[0].FormFields.filter(f =>
                      (f.fieldType.SingleData.name === 'Serving')
                      && this.lockNutritionValue === 1);

                    if (fieldInfo && fieldInfo.length > 0 && this.lockNutritionValue === 1) {
                      fieldInfo.forEach(item => {
                        item.fieldType.SingleData.disbaledProperty = true;
                      });
                      // fieldInfo[0].fieldType.SingleData.disbaledProperty = true;
                    }
                  }
                }

                if (this.homeGroup && this.homeGroup.controls['Ndb_No'].value) {
                  const USDALinkFeild = this.subFormConfigs.NestedForm.find(e => e.FormHeaderName === 'USDA Nutrition Link');
                  if (USDALinkFeild) {
                    USDALinkFeild.FormFields[0].fieldType.SingleData.disbaledProperty = true;
                  }
                }
              }
              // If assign the CaloriesGrid.CaloriesData only clear nutrion working
              this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData = this.dataSource.filteredData;
            }
          });
        }
      }
      // Adding Grid Info based on USDA Item
      this.subFormConfigs.caloriesCalculation.CaloriesCallBack = (GridData: Array<any>) => {
        this.dataSource = new MatTableDataSource(GridData);
        this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData = GridData;
        const gramsPerServing = (this.homeGroup.controls.ServingSize.value === '' ||
          this.homeGroup.controls.ServingSize.value === null) ? 0 : this.homeGroup.controls.ServingSize.value;
        this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(gridheader => {
          if (gridheader.FieldName === 'NutrientValue1') {
            const DisplayValue = Formater('ServingSize', gramsPerServing, null, null);
            gridheader.DisplayName = `/${DisplayValue} gms`;
          }
        });
      };
      this.subFormConfigs.caloriesCalculation.CaloriesReCalcuationCallBack = (GridData: Array<any>) => {
        this.recalculateCalories();
      };
    }
    if (this.ParentFormGroup && this.ParentFormGroup !== undefined) {
      this.ParentFormGroup.addControl(this.subFormConfigs.OdataKey, this.homeGroup);
    }

  }
  ngOnInit(): void {
    if (this.subFormConfigs && this.subFormConfigs.hasOwnProperty('caloriesCalculation')) {
      this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.forEach((x, hIndex) => {
        this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesData.forEach((e, dindex) => {
          for (const property in e) {
            if (property) {
              const fc = new FormControl('');
              this.coloriesGroup.addControl(hIndex.toString() + '-' + dindex.toString(), fc);
            }
          }

        });
      });
      console.log(this.coloriesGroup, this.selectedId);
    }
  }

  recalculateCalories() {
    const gramsPerServing = (this.homeGroup.controls.ServingSize.value === '' ||
    this.homeGroup.controls.ServingSize.value === null) ? 0 : this.homeGroup.controls.ServingSize.value;
    this.subFormConfigs.caloriesCalculation.CaloriesGrid.CaloriesGridHeader.map(gridheader => {
      if (gridheader.FieldName === 'NutrientValue1') {
        const DisplayValue = Formater('ServingSize', gramsPerServing, null, null);
        gridheader.DisplayName = `/${DisplayValue} gms`;
      }
    });
    const DisplayValue = Formater('ServingSize', '100', null, null);
    const nutrientHeader = {
      'Name': 'kilograms',
      'FieldName': 'NutrientValue2',
      'DisplayName': `/${DisplayValue} gms`
    };
    this.dataSource.filteredData.forEach(x => {
      this.caloriescalculation(x, nutrientHeader, null);
    });
    const enableErrorpop = (this.homeGroup.controls['ServingSize'].value === '' ||
    this.homeGroup.controls['ServingSize'].value === null) ? false : true;
    if ( !enableErrorpop ) {
      const ErrorPopUpDialog: ErrorPopModel = {
        PopupHeaderContent: 'Error',
        PopUpContent: 'Please Enter Value for Grams Per Serving',
      };
      const dialogRef = this.dialog.open(AgilsysErrorPopComponent, {
        width: '400px',
        data: ErrorPopUpDialog,
        disableClose: true
      });
    }
  }

  columnClick(event, index, idx, from) {
    try {
      const editClass = document.getElementById(`${index}-${idx}`);
      if (editClass) {
        editClass.focus();
      }
    } catch (error) {
      console.error('Error occurred in columnClick', error);
    }
  }
}
