import { GridFilterAction } from '../app-grid.interface';
import { EnableFilter } from '../grid.interface';

export const EnableFilterOption: EnableFilter = (FilterKeys: GridFilterAction[], RowData: Array<any>): boolean => {
    try {
        if (FilterKeys.length > 0 && RowData && RowData.length > 0 ) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log('error occured EnableFilterOption', error);
    }
};