import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'groupeditem'})
export class GroupedIteampipe implements PipeTransform {
  transform(value: any, groupedset?: any): string {
    let groupedName: string;
    switch (groupedset) {
        case 1: {
            groupedName = 'group-item--1';
            break;
        }
        case 2: {
            groupedName = 'group-item--2';
            break;
        }
        case 3: {
            groupedName = 'group-item--3';
            break;
         }
         case 4: {
            groupedName = 'group-item--4';
            break;
         }
         case 5: {
            groupedName = 'group-item--5';
            break;
         }
         case 6: {
            groupedName = 'group-item--6';
            break;
         }
        default: {
           break;
        }
     }
    return groupedName;
  }
}