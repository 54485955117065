import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SpaLocalization } from "src/app/core/localization/spa-localization";
import { BreakPointAccess } from "src/app/shared/service/breakpoint.service";
import {
  HttpMethod,
  HttpServiceCall,
} from "src/app/shared/service/http-call.service";
import { SpaUtilities } from "src/app/shared/utilities/spa-utilities";
import { SystemConfigBusinessService } from "../system-config.business.service";
import {
  ACRSwitch,
  DataTypeConstants,
  OutletSubProperty,
  SystemConfig,
} from "src/app/shared/business/shared.modals";
import { ACRBusiness, ACRModel } from "./acr-setup-business";
import { Host } from "src/app/shared/globalsContant";
import { Subscription } from "rxjs";
import * as GlobalConst from "../../../shared/globalsContant";
import { SystemConfiguration } from "src/app/shared/business/view-settings.modals";
import { StoreTerminal } from "src/app/retail/shared/business/shared.modals";

@Component({
  selector: "app-acr-setup",
  templateUrl: "./acr-setup.component.html",
  styleUrls: ["./acr-setup.component.scss"],
  providers: [SystemConfigBusinessService],
})
export class AcrSetupComponent implements OnInit {
  captions: any;
  allCaptions:any;
  floatLabel: string;
  acrFrmGrp: UntypedFormGroup;
  cancelPolicy: ACRModel.IOptions[] = [];
  pricePercentage: ACRModel.IOptions[] = [];
  customValue: ACRModel.ICustomOption[] = [];
  selectedValue: ACRModel.ICustomOption = { id: 0, value: "" };
  outlets: OutletSubProperty[] = [];
  requiredFields: UntypedFormArray;
  requiredFieldsInfo: ACRModel.IRequiredField[] = [];
  acrSubscription: Subscription;
  enableSave: boolean;
  isViewOnly = false;
  isUserAuthorized = true;
  settingInfo: SystemConfiguration[] = [];
  depositMaxLength: number = 0;
  disableRequireDeposit: boolean = false;
  terminals: StoreTerminal[] = [];

  constructor(
    private localization: SpaLocalization,
    private fb: UntypedFormBuilder,
    private systemConfig: SystemConfigBusinessService,
    private utils: SpaUtilities,
    public http: HttpServiceCall,
    private BP: BreakPointAccess,
    private acrBusiness: ACRBusiness
  ) {}

  ngOnInit(): void {
    this.buildACRForm();
    this.pageLoad();
  }

  /**
   * initiate page load
   */
  async pageLoad() {
    this.captions = this.localization.captions.setting;
    this.allCaptions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.cancelPolicy = this.acrBusiness.getCancelPolicy();
    this.pricePercentage = this.acrBusiness.getPricePercentage();
    this.customValue = this.acrBusiness.getCustomValue();
    this.selectedValue = this.acrBusiness.getSelectValue();
    Promise.all([this.getOutletsByPropertyId(), this.getAllSetting()]);
    this.setRequiredFields();
    setTimeout(() => this.calculateHeight(), 1);
    this.acrSubscription = this.acrFrmGrp.valueChanges.subscribe(() => {
      if (this.acrFrmGrp.valid && this.acrFrmGrp.dirty) {
        this.enableSave = true;
      } else {
        this.enableSave = false;
      }
    });
    this.acrFrmGrp.get("maximumAddOns").disable();
    this.validateBreakPoint();
  }

  /**
   * get outlets based on property
   */
  async getOutletsByPropertyId() {
    this.outlets = [];
    let result = await this.http.CallApiAsync<OutletSubProperty[]>({
      host: Host.retailManagement,
      callDesc: "GetOutletsByPropertyAndProduct",
      method: HttpMethod.Get,
      uriParams: {
        propertyId: Number(this.localization.GetPropertyInfo("PropertyId")),
        productId: Number(this.localization.GetPropertyInfo("ProductId")),
      },
    });
    if (result) {
      this.outlets = result.result ? result.result : [];
      this.outlets = this.outlets.filter((x) => x.isActive);
    }
  }

  /**
   * get terminals based on outlet
   * @param outletId
   */
  async getDefaultTerminalIds(outletId: number) {
    this.utils.ToggleLoader(true);
    try {
      this.terminals = [];
      if (outletId) {
        let result = await this.http.CallApiAsync<StoreTerminal[]>({
          host: Host.retailManagement,
          callDesc: "GetStoreTerminal",
          method: HttpMethod.Get,
          uriParams: { outletId: outletId },
        });
        this.terminals = result?.result ? result.result : [];
        if (this.terminals.length > 0) {
          this.acrFrmGrp.controls.terminal.setValue(
            this.settingInfo
              .find((setting) => setting.switch == ACRSwitch.terminal)
              .value.toString()
          );
        }
      }
    } finally {
      this.utils.ToggleLoader(false);
    }
  }

  /**
   * get all setting for the property
   */
  async getAllSetting() {
    let result = await this.http.CallApiAsync<OutletSubProperty[]>({
      host: Host.spaManagement,
      callDesc: "GetAllSetting",
      method: HttpMethod.Get,
      uriParams: {
        Id: this.utils.GetPropertyInfo("PropertyId"),
      },
    });
    if (result) {
      this.settingInfo = <any>result.result;
      this.requiredFieldsSetting();
      this.appointmentDefaultsSetting();
      this.enableSave = false;
    }
  }

  /**
   * build ACR setup form
   */
  buildACRForm() {
    //control name is configured same as ACRSwitch in shared model//
    this.acrFrmGrp = this.fb.group({
      outlet: ["", Validators.required],
      requiredFields: this.fb.array([]),
      bookingDaysOut: ["", Validators.required],
      bookingDaysIn: ["", Validators.required],
      allowAppointmentEdit: false,
      gridInterval: "",
      requireAppointmentDeposit: "",
      pricePercentage: 1,
      includeGratuity: true,
      includeServiceCharge: false,
      depositValue: "",
      terminal: [""],
      limitAddOns: false,
      autoBookTherapist: false,
      isCustomPercentage: false,
      maximumAddOns: ["", Validators.required],
    });
  }

  /**
   * get form control value
   * @param controlName -- outlet,requiredFields,bookingDaysOut,bookingDaysIn,
   * allowAppointmentEdit,gridInterval,requireAppointmentDeposit,pricePercentage,includeGratuity,isCustomPercentage,
   * includeServiceCharge,depositValue,terminal,limitAddOns,autoBookTherapist,maximumAddOns
   */
  getFormValue(controlName: string) {
    return this.acrFrmGrp.controls[controlName]?.value;
  }

  /**
   * set form control value
   * @param controlName -- outlet,requiredFields,bookingDaysOut,bookingDaysIn,
   * allowAppointmentEdit,gridInterval,requireAppointmentDeposit,pricePercentage,includeGratuity,isCustomPercentage,
   * includeServiceCharge,depositValue,terminal,limitAddOns,autoBookTherapist,maximumAddOns
   */
  setFormValue(controlName: string, value: any) {
    this.acrFrmGrp.controls[controlName]?.setValue(value);
  }

  /**
   * set required fields form input
   */
  setRequiredFields() {
    this.requiredFieldsInfo = this.acrBusiness.getRequiredFieldInfo();
    this.requiredFields = this.acrFrmGrp.get(
      "requiredFields"
    ) as UntypedFormArray;

    for (let i = 0; i < this.requiredFieldsInfo.length; i++) {
      switch (this.requiredFieldsInfo[i].name) {
        case this.captions.personalInformation:
          this.requiredFields.push(this.addPersonalDetails());
          break;
        case this.captions.contactDetails:
          this.requiredFields.push(this.addContactDetails());
          break;
        case this.captions.paymentDetails:
          this.requiredFields.push(this.addPaymentDetails());
          break;
      }
    }
  }

  /**
   * add personal info to form group
   * @returns UntypedFormGroup
   */
  addPersonalDetails(): UntypedFormGroup {
    return this.fb.group({
      ACR_TITLE: "",
      ACR_FIRST_NAME: "",
      ACR_LAST_NAME: "",
      ACR_BIRTHDAY: "",
      ACR_GENDER: "",
    });
  }

  /**
   * add contact info to form group
   * @returns UntypedFormGroup
   */
  addContactDetails(): UntypedFormGroup {
    return this.fb.group({
      ACR_ADDRESS: "",
      ACR_CITY: "",
      ACR_STATE: "",
      ACR_POSTAL_CODE: "",
      ACR_COUNTRY: "",
      ACR_PHONE: "",
      ACR_EMAIL: "",
    });
  }

  /**
   * add payment info to form group
   * @returns UntypedFormGroup
   */
  addPaymentDetails(): UntypedFormGroup {
    return this.fb.group({
      ACR_CREDIT_CARD: "",
    });
  }

  /**
   * validate check access
   */
  validateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([
      GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings,
    ]);
    this.isViewOnly = this.BP.IsViewOnly(
      GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings
    );
    if (this.isViewOnly) {
      this.utils.disableControls(this.acrFrmGrp);
    }
  }

  /**
   * set required field setting value
   */
  requiredFieldsSetting() {
    let propJSON: ACRModel.ISwitch = {};
    this.settingInfo.forEach((sc) => {
      if (sc.switchType == DataTypeConstants.Boolean) {
        sc.value = this.convertStringToBoolean(sc.value as string);
      } else if (sc.switchType == DataTypeConstants.Int32) {
        sc.value = this.convertStringToInt(sc.value as string);
      }
      propJSON[sc.switch] = sc.value;
      propJSON[sc.id] = sc.id;
    });
    this.systemConfig.systemConfigValues = propJSON;
    this.formAndPatchRequiredFieldsData(propJSON);
    this.enableSave = false;
  }

  /**
   * set appointment default settings
   */
  async appointmentDefaultsSetting() {
    Object.keys(this.acrFrmGrp.controls).forEach((control: string) => {
      if (control !== "requiredFields") {
        const value =
          this.settingInfo.find(
            (setting) => setting.switch == ACRSwitch[control]
          )?.value ?? null;

        this.setFormValue(
          control,
          control === "depositValue"
            ? this.localization.localizePercentage(String(value ?? ""))
            : value
        );
      }
    });

    const maxLimitAddOnsValue = this.getFormValue("maximumAddOns");
    if (maxLimitAddOnsValue != "" && maxLimitAddOnsValue) {
      this.acrFrmGrp.get("maximumAddOns").enable();
    }

    const limitAddOns = this.getFormValue("limitAddOns");
    if (limitAddOns) {
      this.acrFrmGrp.get("maximumAddOns").setValidators([Validators.required]);
    } else {
      this.acrFrmGrp.get("maximumAddOns").setValidators([]);
      this.acrFrmGrp.get("maximumAddOns").disable();
    }
    this.acrFrmGrp.get("maximumAddOns").markAsTouched(); // IK -Check

    let isCustomDepositPercentage = this.getFormValue("isCustomPercentage");
    this.selectedValue = isCustomDepositPercentage
      ? this.customValue[1]
      : this.customValue[0];
    this.depositMaxLength = isCustomDepositPercentage
      ? GlobalConst.Maxlength.PERCENTAGE
      : GlobalConst.Maxlength.FLATAMOUNT;
   
    if (!this.acrFrmGrp.value?.requiredFields[2]?.ACR_CREDIT_CARD) {
      this.disableRequireDeposit = true;
      this.depositMaxLength = GlobalConst.Maxlength.PERCENTAGE;
    }
    await this.getDefaultTerminalIds(this.acrFrmGrp.controls["outlet"].value);
  }

  /**
   * toggle limit add ons
   * @param value
   */
  limitAddOnsClick(value: boolean) {
    if (value[0]) {
      this.acrFrmGrp.get("maximumAddOns").setValidators([Validators.required]);
      this.acrFrmGrp.get("maximumAddOns").markAsTouched();
      this.acrFrmGrp.get("maximumAddOns").enable();
    } else {
      this.setFormValue("maximumAddOns", "");
      this.acrFrmGrp.get("maximumAddOns").setValidators([]);
      this.acrFrmGrp.get("maximumAddOns").markAsTouched();
      this.acrFrmGrp.get("maximumAddOns").disable();
    }
  }

  /**
   * toggle allow credit card
   * @param controlName
   * @param isEnable
   */
  allowCreditCard(controlName: string, isEnable: boolean) {
    if (controlName === "ACR_CREDIT_CARD") {
      this.disableRequireDeposit = !isEnable;
      if (this.disableRequireDeposit) {
        this.setFormValue("requireAppointmentDeposit", false);
      }
    }
  }

  /**
   * set 0 if empty
   * @param $event
   */
  approximateZero($event: any) {
    if ($event?.target?.value === 0 || $event?.target?.value === "") {
      $event.target.value = "0";
      let formControlName: string =
        $event.target.getAttribute("formcontrolname");
      if (formControlName) {
        this.setFormValue(formControlName, 0);
      }
    }
  }

  /**
   * toggle appointment deposit deposit
   */
  allowAppointmentDepositEmit() {
    this.setFormValue("pricePercentage", 1);
    this.setFormValue("depositValue", 0);
    this.selectedValue = this.customValue[1];
  }

  /**
   * price percentage toggle change
   */
  radioChange() {
    if (this.getFormValue("pricePercentage") === 2) {
      this.acrFrmGrp.get("depositValue").setValidators(Validators.required);
    } else {
      this.acrFrmGrp.get("depositValue").setValidators(null);
      this.setFormValue("depositValue", 0);
    }
    this.acrFrmGrp.get("depositValue").updateValueAndValidity();
  }

  /**
   * percentage or amount toggle
   * @param value  ACRModel.ICustomOption
   */
  chooseValue(value: ACRModel.ICustomOption) {
    this.selectedValue = value;
    this.setFormValue("depositValue", 0);
    if (this.selectedValue.id == 2) {
      this.depositMaxLength = GlobalConst.Maxlength.PERCENTAGE;
    } else {
      this.depositMaxLength = GlobalConst.Maxlength.FLATAMOUNT;
    }
    this.acrFrmGrp.markAsDirty();
    this.acrFrmGrp.updateValueAndValidity();
  }

  /**
   * save ACR setup values
   */
  async save() {
    this.enableSave = false;
    let settingData: SystemConfig[] = this.formSettingBody().concat(
      this.formAppointmentBody()
    );
    this.utils.ToggleLoader(true);
    try {
      let result = await this.http.CallApiAsync<any>({
        host: Host.spaManagement,
        callDesc: "UpdateSetting",
        method: HttpMethod.Put,
        body: settingData,
      });
      if (result?.successStatus) {
        this.settingInfo = <any>result?.result;
        this.requiredFieldsSetting();
        this.appointmentDefaultsSetting();
      } else {
        this.utils.ShowErrorMessage(
          this.localization.captions.common.Error,
          this.localization.getError(result?.errorCode),
          GlobalConst.ButtonType.Ok
        );
      }
    } finally {
      this.utils.ToggleLoader(false);
    }
  }

  /**
   * form required setting
   * @returns SystemConfig[]
   */
  formSettingBody(): SystemConfig[] {
    let allControls = [this.requiredFields.controls];
    let con = allControls[0];
    let body: SystemConfig[] = [];
    for (let systemControl of con) {
      let formControl = systemControl as UntypedFormGroup;
      let controls: string[] = Object.keys(formControl.controls);
      for (let control of controls) {
        let systemConfig: SystemConfig;
        const setting = this.settingInfo.find(
          (setting) => setting.switch == control
        );
        if (setting) {
          systemConfig = {
            id: setting.id,
            moduleId: setting.moduleId,
            switch: control,
            value: formControl.controls[control]?.value,
          };
          body.push(systemConfig);
        }
      }
    }
    return body;
  }

  /**
   * add setting detail
   * @param uiSwitchName
   * @returns -- SystemConfig
   */
  addAppointmentData(uiSwitchName: string): SystemConfig {
    let con = this.acrFrmGrp.value;
    let body: SystemConfig;
    if (con) {
      const tempValue =
        ACRSwitch[uiSwitchName] == ACRSwitch.depositValue
          ? this.localization.currencyToSQLFormat(con[uiSwitchName]).toString()
          : con[uiSwitchName];
      const value = this.settingInfo.find(
        (setting) => setting.switch == ACRSwitch[uiSwitchName]
      );
      if (value) {
        body = {
          id: value.id,
          moduleId: value.moduleId,
          switch: ACRSwitch[uiSwitchName],
          value:
            ACRSwitch[uiSwitchName] == ACRSwitch.isCustomPercentage
              ? this.selectedValue.id == 2
              : tempValue,
        };
        body.value = body.value === undefined ? "" : body.value;
        return body;
      }
    }
    return null;
  }

  /**
   * form ACR setup detail for save
   * @returns SystemConfig[]
   */
  formAppointmentBody(): SystemConfig[] {
    let config: SystemConfig[] = [];
    Object.keys(this.acrFrmGrp.controls).forEach((control: string) => {
      if (control !== "requiredFields") {
        let settingData = this.addAppointmentData(control);
        if (settingData) {
          config.push(settingData);
        }
      }
    });
    return config;
  }

  /**
   * cancel event handler
   */
  cancel() {
    if (this.isViewOnly) {
      return;
    }
    this.requiredFieldsSetting();
    this.appointmentDefaultsSetting();
    this.enableSave = false;
  }

  /**
   * convert string to boolean
   * @param value
   * @returns -- true/false
   */
  convertStringToBoolean(value: string): boolean {
    return value.toString().toLowerCase() == "true" ? true : false;
  }

  /**
   * convert string to number
   * @param value
   * @returns number
   */
  convertStringToInt(value: string): any {
    return value != null ? parseInt(value) : value;
  }

  /**
   * patch form array value
   * @param appJSON
   */
  formAndPatchRequiredFieldsData(appJSON: ACRModel.ISwitch) {
    appJSON["requiredFields"] = [appJSON, appJSON, appJSON];
    this.acrFrmGrp.reset();
    this.acrFrmGrp.patchValue(appJSON);
  }

  /**
   * calculate form height
   */
  calculateHeight() {
    try {
      let col1 = document.getElementById("required-0").offsetHeight;
      let col2 = document.getElementById("required-1").offsetHeight;
      let col3 = document.getElementById("required-2").offsetHeight;
      let maxHeight = Math.max(col1, col2, col3);
      this.setHeight(maxHeight);
    } catch (e) {}
  }

  /**
   * set form height
   * @param height
   */
  setHeight(height) {
    try {
      document.getElementById("required-0").style.height = height + "px";
      document.getElementById("required-1").style.height = height + "px";
      document.getElementById("required-2").style.height = height + "px";
    } catch (e) {}
  }
}
