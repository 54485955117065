import { Component, Inject, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { AddNewPopModel, ItemFbProductModel } from '../model/pop-up.interface';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';

@Component({
  selector: 'app-agilysys-fbproduct-popup',
  templateUrl: './agilysys-fbproduct.component.html',
  styleUrls: ['./agilysys-fbproduct.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysFbProductComponent implements OnInit, OnChanges {
  agilysysAddNewButton: AgilysysButtonModule[];
  homeGroup: FormGroup;
  itemChecked: boolean;
  constructor(
    public dialogRef: MatDialogRef<AgilysysFbProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemFbProductModel,
    public formBuilder: FormBuilder
  ) {
    this.homeGroup = this.formBuilder.group({});
    const _that = this;
    this.itemChecked = true;
  }

  addButtonClick(callBackData: CallBackData) {
     const geFgItemtData = this.data.SelectedItem;
     const GetuncheckedFgData = geFgItemtData.filter(x => !x.Checked);
     this.dialogRef.close([callBackData.buttonValue.buttonKey, GetuncheckedFgData]);
  }
  updateAllComplete() {
     console.log('dd');
  }


  getReturnFormValue(value: any) {
    // console.log('FormValue', value.form);
  }

  ngOnChanges(): void {
    const _that = this;
    // if (this.data.FormGroupData) {
    //      setTimeout(function () { _that.homeGroup.patchValue(this.data.FormGroupData); }, 1000);
    // }
  }

  ngOnInit(): void {
    this.agilysysAddNewButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, []]);
        }
      },
      {
        displayProperity: false,
        displayName: 'Add',
        buttonKey: 'remove',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.addButtonClick(callBackData);
        }
      }
    ];
  }

}
