
import { Injectable } from '@angular/core'; 
import { Localization } from 'src/app/common/shared/localization/Localization';
import { ObjectType } from '../../guest-model';

@Injectable()
export class valueToSimpleBusiness {
    captions: any;

  constructor(private localization: Localization){
        this.captions = this.localization.captions;
    }

    getjsonobj(){
       return [{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.vendors.lbl_lastName,
          id:'lastname',
          isRequired:false,
          key:'lastname'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.vendors.lbl_firstName,
          id:'firstname',
          isRequired:false,
          key:'firstname'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.lbl_Title,
          id:'title',
          isRequired:false,
          key:'title'
        },
        
          {
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.lbl_address,
          id:'address',
          isRequired:false,
          key:'address'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.lbl_postcode,
          id:'postalcode',
          isRequired:false,
          key:'postalcode'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.vendors.lbl_city,
          id:'city',
          isRequired:false,
          key:'city'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.lbl_phoneno,
          id:'phoneNumberArray',
          isRequired:false,
          key:'phoneNumberArray'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.lbl_emailID,
          id:'emailArray',
          isRequired:false,
          key:'emailArray'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.lbl_state,
          id:'state',
          isRequired:false,
          key:'state'
        },{
          objectType:ObjectType.Dropdown,
          objectTemplateType:"VCT"+ObjectType.Dropdown,
          columnSplitclass:'ag_w--25',
          label:this.captions.vendors.lbl_country,
          id:'country',
          isRequired:false,
          key:'country'
        },{
            objectType:ObjectType.Dropdown,
            objectTemplateType:"VCT"+ObjectType.Dropdown,
            columnSplitclass:'ag_w--25',
            label:this.captions.lbl_patronId,
            id:'patronid',
            isRequired:false,
            key:'patronid'
          }]       
      }

}
