import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { SpaCancellationNoShowFeeService } from '../spa-cancellation-no-show-fee/spa-cancellation-no-show-fee.service';
import { CancallationNoShowSharedService} from 'src/app/common/shared/shared/cancellation-no-show-policy/cancellation-no-show-shared.service'
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MappingSubjectDataType } from 'src/app/common/shared/shared/cancellation-no-show-policy/cancellation-no-show-policy.model';
import { Guid } from 'guid-typescript';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import * as GlobalConst from '../../shared/globalsContant';
import { UserAccessBreakPoints as AccessBreakpoint}  from 'src/app/common/constants/useraccess.constants';

@Component({
  selector: 'app-cancellation-no-show-policy-mapping-wrapper',
  templateUrl: './cancellation-no-show-policy-mapping-wrapper.component.html',
  styleUrls: ['./cancellation-no-show-policy-mapping-wrapper.component.scss'],
  providers: [SpaCancellationNoShowFeeService] 
})
export class CancellationNoShowPolicyMappingWrapperComponent implements OnInit {

  policyOptions = [];
  captions: any;
  applyToOptions = [];
  serviceGroupArray = [];
  productId  = GlobalConst.Product.SPA;
  searchPlaceholder: any;
  tableOptions;
  tableContent = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isViewOnly: boolean = false;

  constructor(private localization: Localization, private spaCancellationNoShowFeeService: SpaCancellationNoShowFeeService,
    private cancellationNoShowSharedService: CancallationNoShowSharedService, private utils: SpaUtilities, private _userAccessBusiness: UserAccessBusiness)
   {
    this.captions = this.localization.captions;
   }

 async ngOnInit(): Promise<void> {
  this.getTableOptions();
  await this._userAccessBusiness.getUserAccess(AccessBreakpoint.CancellationNoShowMapping).then(
    x => this.isViewOnly = x.isViewOnly
  );
    this.cancellationNoShowSharedService.mappingData$.pipe(takeUntil(this.destroyed$)).subscribe(async payload => {
      if(payload != null){
        switch (payload.type) {
          case MappingSubjectDataType.GetPolicyMapping:
            await this.getCancellationNoShowMapping(payload.data);
            break;
          case MappingSubjectDataType.CreatePolicyMapping:
            await this.createCancellationNoShowMapping(payload.data);
            break;
          case MappingSubjectDataType.UpdatePolicyMapping:
            await this.createCancellationNoShowMapping(payload.data, true);
            break;
          case MappingSubjectDataType.DeletePolicyMapping:
            await this.deleteCancellationNoShowMapping(payload.data);
        }
      }
    })
    this.searchPlaceholder = this.captions.lbl_searchByServiceGroup;
    this.applyToOptions = this.spaCancellationNoShowFeeService.getGuestTypes();
    this.serviceGroupArray = await this.getServiceGroupsWithService();
    this.tableContent = await this.getTableContent();
    this.assignTableBodyData(this.tableContent);
    this.getAllCancellationNoShowPolicy();
  }

getTableOptions() {
  let arrDataHeader = [
    { 'title': this.captions.tbl_hdr_policyCode, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
    { 'title': this.captions.tbl_hdr_policyName, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
    { 'title': this.captions.lbl_applyTo, 'jsonkey': 'applyTo', 'searchable': false, 'sortable': true },
    { 'title': this.captions.tbl_hdr_dateRange, 'jsonkey': 'dateRange', 'searchable': false, 'sortable': true,'sortcolumn':'dateSort','sortcolumndatatype': 'number'},
    { 'title': this.captions.tog_lbl_active, 'jsonkey': 'isInActive', 'searchable': false, 'sortable': false, "type": "toggle" },
  ]
  this.tableOptions = [
    {
      TableHdrData: arrDataHeader,
      TablebodyData: [],
      pagination: false,
      sortable: true,
      editable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.lbl_searchByPolicyCodeName,
      EnableActions: true,
      SelectRows: false,
      Searchable: true,
      EditMoreOption: false,
      Sortable: 'code',
      TableId: '',
      disableDelete: false,
      SelectRow: true,
      SelectOnlyRow: true,
      dropdownOptions: '',
      waitlistIcon: false,
      TableDraggable: false,
      sticky: false,
      DoneCancel: false,
      IsViewOnly: true,
      disableEditButton: false,
      isCopyEnabled : false,
      ServiceId : 'viptype'
    }
  ];
}

async getTableContent(){
  var mappingData = await this.spaCancellationNoShowFeeService.getAllCancellationNoShowMappings(true)
  return this.spaCancellationNoShowFeeService.mapMappingData(mappingData);
}

  async getServiceGroupsWithService(){
  return await this.spaCancellationNoShowFeeService.getAllServicesGroupsWithServices(false);
  }

  async getAllCancellationNoShowPolicy(){
    var response = await this.spaCancellationNoShowFeeService.getAllCancellationNoShowPolicies(false);
    var policiesData = this.spaCancellationNoShowFeeService.policyMapper(response);
    this.cancellationNoShowSharedService.sendMappingData(MappingSubjectDataType.SearchMappingResponse, policiesData);
  }

  async createCancellationNoShowMapping(mappingData: any[], isUpdate: boolean = false) {
    let errorMessage = '';
    this.utils.ToggleLoader(true);
    let mappingDataobj = await this.spaCancellationNoShowFeeService.formObjectForPolicyMapping(mappingData);
    var response = await this.spaCancellationNoShowFeeService.createOrUpdateCancellationNoShowMapping(mappingDataobj, isUpdate);
    this.utils.ToggleLoader(false);
    if (response.errorCode == 54)
      errorMessage = this.localization.replacePlaceholders(this.localization.getError(response.errorCode), ['policyName'], [response.errorDescription]);
    this.cancellationNoShowSharedService.sendMappingData(MappingSubjectDataType.APIResponse, response.errorCode, errorMessage);
    var result = this.spaCancellationNoShowFeeService.mapMappingData(response.result);
    this.assignTableBodyData(result);
  }

  async deleteCancellationNoShowMapping(groupId: Guid){
    this.utils.ToggleLoader(true);
    var response = await this.spaCancellationNoShowFeeService.deleteCancellationNoShowMapping(groupId);
    this.utils.ToggleLoader(false);
    this.cancellationNoShowSharedService.sendMappingData(MappingSubjectDataType.DeleteAPIResponse, response.errorCode);
    var result = this.spaCancellationNoShowFeeService.mapMappingData(response.result);
    this.assignTableBodyData(result);
  }

  assignTableBodyData(data: any){
    var tableData = [...this.tableOptions]
    tableData[0].TablebodyData = data;
    tableData[0].IsViewOnly = this.isViewOnly;
    this.tableOptions = tableData;
  }

  async getCancellationNoShowMapping(groupId: Guid){
    var result = await this.spaCancellationNoShowFeeService.getCancellationNoShowMapping(groupId);
    this.applyToOptions = this.spaCancellationNoShowFeeService.updateGuestTypes(this.applyToOptions, result.guestType);
    this.spaCancellationNoShowFeeService.mapServiceAndServiceGroup(this.serviceGroupArray, result.policyMapping);
    this.cancellationNoShowSharedService.sendMappingData(MappingSubjectDataType.SendPolicyMapping, result);
  }

  ngOnDestroy(){
    this.cancellationNoShowSharedService.clearSendMappingData();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
