import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-more-section-with-tabs',
  templateUrl: './more-section-with-tabs.component.html'
})
export class MoreSectionWithTabsComponent implements OnInit {
  Categories = [];
  @Output() filterEmitter: EventEmitter<any> = new EventEmitter();
  filterOptions: { ShowMoreFilters: boolean; moreData: any[]; selectedMoreData: any[]; belongto: number; fromPage: string; categoryType: string; };

  ngOnInit() {
    const category = {
      filters: [],
      filtered: [],
      id: 1,
      title: ''
    };
    this.filterOptions = {
      'ShowMoreFilters': true,
      'moreData': category.filters,
      'selectedMoreData': category.filtered,
      'belongto': category.id,
      'fromPage': 'AF',
      'categoryType': category.title
    };
  }


  filteredDataResponse(e) {
    let result = e[0];
    let id = e[1];
    let categoryIndex = this.Categories.findIndex(r => r.id === id);
    if (categoryIndex >= 0) {
      this.Categories[categoryIndex].filtered = result;
    }
    this.filterEmitter.emit(this.Categories);
  }
}
