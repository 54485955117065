import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, GridCustomColExpand, MasterInventoryManagement } from '../master.interface';
import { GridModelData } from './grid-config.interface';
import _ from 'lodash';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';

export const GridData: GridModelData = 
(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement, 
httpService: HttpService, queryParams: QueryParams, ResponseData: Array<any> ): Array<any> => {
    try {
        ResponseData.forEach( dataobject => {​​​​​​​​​
            for (const prop in dataobject) {​​​​​​​​​
                if (prop) {​​​​​​​​​
                    if (dataobject[prop] && dataobject[prop] !== null) {​​​​​​​​​
                        // dataobject[prop] = Formater(prop, dataobject[prop], ViewModelEntity, ViewModelEntity);
                        const propType = typeof dataobject[prop];
                        const isArrayprop = Array.isArray(dataobject[prop]);
                        if (propType === 'object' && !isArrayprop) {​​​​​​​​​
                            assignNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                        }​​​​​​​​​
                    }​​​​​​​​​
                }​​​​​​​​​
            }   ​​​​​​​​​
        }​​​​​​​​​);
        return ResponseData;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function NavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                    const navigationName = element['$'].Name;
                    const referentialRelationId = element['ReferentialConstraint'] && element['ReferentialConstraint'][0]['$'].Property
                        ? element['ReferentialConstraint'][0]['$'].Property : '';
                    if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                        const properityIndex = ViewModelEntity[0]['Property']
                            .findIndex(x => x['$'].Name === referentialRelationId);
                        if (properityIndex > -1) {
                            let expandQuery = '($select=id,name)';
                            if ( MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand.length > 0 ) {
                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey: GridCustomColExpand) => {
                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand && GridCustomExpandKey.IsCustomExpand
                                        && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                        expandQuery = `($select=id,${expandCode})`;
                                    }
                                });
                            }
                            const properityString = `${navigationName}` + `${expandQuery}`;
                            navigationPrperity.push(properityString);
                        }
                    }
                });
                return navigationPrperity;
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    query = query + '&$';
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}
function assignNavigationProperity (ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
      ViewModelEntityColl &&
      ViewModelEntityColl[0].hasOwnProperty('NavigationProperty') &&
      ViewModelEntityColl[0]['NavigationProperty'].length > 0 ) {
       ViewModelEntityColl[0]['NavigationProperty'].forEach((element: any) => {
         const navProperiperityName = element['$']['Name'].toLowerCase().toString();
         if (navProperiperityName.toString().toLowerCase() === ProperityName.toString().toLowerCase()) {
             if (element['ReferentialConstraint']) {
                const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toLowerCase().toString();
                entityObject[ referelcontraintName ] = ProperirtyObject['Name'] ? ProperirtyObject['Name']: ProperirtyObject['UnitName'] ? ProperirtyObject['UnitName']
                : ProperirtyObject['Code'] ? ProperirtyObject['Code'] :  ProperirtyObject['UnitCode'] ? ProperirtyObject['UnitCode'] : null;
             }
         }
       });
    }
    return entityObject;
 }