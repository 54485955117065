import { Component,Inject, OnInit } from '@angular/core';
import { ButtonValue } from '../Models/ag-models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Localization } from '../localization/localization';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { SamsotechDataService } from 'src/app/common/dataservices/samsotech.data.service';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/Models/common.models';

@Component({
  selector: 'app-samsotech-scan-readchip',
  templateUrl: './samsotech-scan-readchip.component.html',
  styleUrls: ['./samsotech-scan-readchip.component.scss']
})
export class SamsotechScanReadchipComponent implements OnInit {

  captions: any;
  title: any;
  previewImage1: any;
  previewImage2: any;
  scanUrl: string;
  readChipUrl: string;
  flipEnabled: boolean;
  readChipButton: ButtonValue;
  readScanButton: ButtonValue;
  deviceUpdates: string;
  showLoader: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private localization: Localization,private dialogRef: MatDialogRef<SamsotechScanReadchipComponent>,
   private samsotechService: SamsotechDataService, private utils: CommonUtilities) { 
    this.captions = this.localization.captions;
    if(data){
      this.title = data.header;
      this.scanUrl = data.scannerUrl;
      this.readChipUrl = data.readChipUrl;
      this.flipEnabled = data.flipEnabled;
      this.deviceUpdates = this.captions.msg_ScanInProgress;
    }
  }

  ngOnInit(): void {
    if(this.title == this.captions.lbl_samsotechScan)
      {
        this.previewImage1 = '';
        this.previewImage2 = '';
        this.initScanDocument();
      }
      else{
        this.previewImage1 = '';
        this.previewImage2 = '';
        this.initProcessSmartCard();
      }
    this.readChipButton = {
      type: 'primary',
      label: this.captions.btn_readchip,
      disabledproperty: false
    }
    this.readScanButton = {
      type: 'tertiary',
      label: this.captions.btn_readscan,
      disabledproperty: false
    }
  }

  readChipAction(eve){
    this.title = this.captions.lbl_samsotechReadChip;
    this.previewImage1 = '';
    this.previewImage2 = '';
    this.initProcessSmartCard();
  }

  readScanAction(eve){
    this.title = this.captions.lbl_samsotechScan;
    this.previewImage1 = '';
    this.previewImage2 = '';
    this.initScanDocument();
  }

  private async initScanDocument(){
    this.showLoader = true;
    var responseString = await this.samsotechService.InitScanDocument(this.scanUrl);
    let response = JSON.parse(responseString);
    if (response && response.result)
    {
      this.previewImage1 = "data:image/jpeg;base64," + response.fullImage;
      if (this.flipEnabled && (response.idType == 'NID' || response.idType == 'OC'))
      {
        await this.utils.showCommonAlert(this.captions.info_FlipCard, AlertType.Warning, ButtonTypes.YesNo).afterClosed()
        .toPromise()
        .then(async res => {
          if (res === AlertAction.YES) {
            var otherSideResponseString = await this.samsotechService.InitScanDocument(this.scanUrl);
            let otherSideResponse = JSON.parse(otherSideResponseString);
            if (otherSideResponse && otherSideResponse.result)
            {
              this.previewImage2 = "data:image/jpeg;base64," + otherSideResponse.fullImage;
            }
          }
        });
      }
      this.showLoader = false;
    }
    else if(response?.error_message)
    {
      this.showLoader = false;
      this.utils.showAlert(response.error_message, AlertType.Error, ButtonType.Ok);
    }
    else
    {
      this.showLoader = false;
      this.utils.showAlert(this.captions.lbl_failed, AlertType.Error, ButtonType.Ok);
    }
  }

  private async initProcessSmartCard(){
    this.showLoader = true;
    var responseString = await this.samsotechService.InitProcessSmartCard(this.readChipUrl);
    this.showLoader = false;
    let response = JSON.parse(responseString);
    if (response && response.result)
    {
      this.previewImage1 = "data:image/jpeg;base64," + response.fullImage;
      this.utils.showAlert(this.captions.info_SmartCardProcessed, AlertType.Success, ButtonType.Ok);
    }
    else if(response?.error_message)
    {
      this.utils.showAlert(response.error_message, AlertType.Error, ButtonType.Ok);
    }
    else
    {
      this.utils.showAlert(this.captions.lbl_failed, AlertType.Error, ButtonType.Ok);
    }
  }

  close(eve){
    this.dialogRef.close()
  }

}
