import { Injectable } from '@angular/core';
import { ActionTypeEnum, SorTypeEnum, aligment } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableHeaderOptions, TableOptions, DropdownOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { UImodal, DataMigrationStatisticsRequest, DataMigrationStatistics, StatisticalDetails } from './data-migration-exp.modal';
import { DataMigrationStatisticsDataService } from 'src/app/common/dataservices/datamigration-statistics.data.service';
import * as FileSaver from 'file-saver';
@Injectable()
export class dataMigrationexpBussiness {
  captions: any;
  responseModel: UImodal[] = [];
  propertyDate: string;
  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */

  constructor(private localization: Localization, private _dataService: DataMigrationStatisticsDataService) 
  {
    this.captions = this.localization.captions;
    }

  public async getAPIData(requestModel: DataMigrationStatisticsRequest): Promise<UImodal[]>
  {
    let result: UImodal[] = [];
    const apiReseponseData: DataMigrationStatistics = await this._dataService.processDataMigrationStatisticsRequest(requestModel);
    this.propertyDate = this.localization.ConvertDateToISODate(this.localization.ToDate(apiReseponseData?.processedDate, ''));
    apiReseponseData?.statisticalDetails.forEach(x => {
      result.push({
        id:x?.id,
        screen: x?.screenName,
        screenType: apiReseponseData?.moduleName,
        date: this.propertyDate,
        externalPmscount: x?.externalPMSCount,
        versaCount: x?.versaCount,
        failedCount: x?.failedCount,
        totalImportTime: x?.totalDurationMins,
        failedCodes:'FAILEDCODE',
        failedDownloads:'FAILEDITEM'
      });
    });
    return result;
  }

  async download(statisticalDetailsId : number,downloadFileType: string, fileName : string) {
    let file = await this._dataService.downloadExcel(statisticalDetailsId, downloadFileType);    
    FileSaver.saveAs(file, fileName);
    return true;
  }

  public getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'screenType',
        displayNameId: 'tbl_hdr_screenType',
        displayName: this.captions.tbl_hdr_screenType,
        searchable: true,
        sorting: true,
        sortingKey: 'screenType'
      },
      {
        key: 'screen',
        displayNameId: 'tbl_hdr_screen',
        displayName: this.captions.tbl_hdr_screen,
        searchable: true,
        sorting: true,
        sortingKey: 'screen',
        
      },      
      {
        key: 'date',
        displayNameId: 'tbl_hdr_date',
        displayName: this.captions.tbl_hdr_date,
        searchable: true,
        sorting: true,
        sortingKey: 'date'
      },
      {
        key: 'externalPmscount',
        displayNameId: 'tbl_hdr_externalPmscount',
        displayName: this.captions.tbl_hdr_externalPmscount,
        searchable: true,
        sorting: true,
        sortingKey: 'externalPmscount',
        alignment:aligment.right
      },
      {
        key: 'versaCount',
        displayNameId: 'tbl_hdr_versaCount',
        displayName: this.captions.tbl_hdr_versaCount,
        searchable: true,
        sorting: true,
        sortingKey: 'versaCount',
        alignment:aligment.right
      },
      {
        key: 'failedCount',
        displayNameId: 'tbl_hdr_failedCount',
        displayName: this.captions.tbl_hdr_failedCount,
        searchable: true,
        sorting: true,
        sortingKey: 'failedCount',
        alignment:aligment.right
      },
      {
        key: 'totalImportTime',
        displayNameId: 'tbl_hdr_totalImportTime',
        displayName: this.captions.tbl_hdr_totalImportTime,
        searchable: true,
        sorting: true,
        sortingKey: 'totalImportTime',
        alignment:aligment.right
      },
      {
        key: 'failedCodes',
        displayNameId: 'tbl_hdr_failedCodes',
        displayName: this.captions.tbl_hdr_failedCodes,
        templateName: ActionTypeEnum.custom,
        searchable: false,
        sorting: false,
        sortingKey: 'failedCodes'
      },
      {
        key: 'failedDownloads',
        displayNameId: 'tbl_hdr_failedDownloads',
        displayName: this.captions.tbl_hdr_failedDownloads,
        templateName: ActionTypeEnum.custom,
        searchable: false,
        sorting: false,
        sortingKey: 'failedDownloads'
      }
    ]
  }

  public getTableOptions(): TableOptions {
    return {
      defaultsortingColoumnKey: 'clerkName',
      defaultSortOrder: SorTypeEnum.asc,
      showTotalRecords:true,
      columnFreeze: {
        firstColumn: true,
        lastColumn: true
      },

      ignoreSort: true
    };
  }
}
