import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SettingComponent } from './setting.component';
import { SetUpComponent } from './Setup/setup.component';
import { StaffScheduleComponent } from './staff-schedule/staff-schedule.component';
import { RetailCommissionSetupComponent } from '../retail/commission-setup/commission-setup.component';
import { PackageYieldingComponent } from './package-yielding/package-yielding.component';
import { ServiceYieldingComponent } from './service-yielding/service-yielding.component';
import { UtilitiesComponent } from './Utilities/utilities.component';
import { RetailInventorySettingComponent } from '../retail/inventory/inventory.component';
import { SystemConfigComponent } from './system-config/system-config.component';
import { UserConfigComponent } from './user-config/user-config.component';
import { MultipleAppointmentsComponent } from './Utilities/multiple-appointments/multiple-appointments.component';
import { ReceiptConfigurationComponent } from './Utilities/receipt-configuration/receipt-configuration.component';
import { UserMachineConfigurationComponent } from './Utilities/user-machine-configuration/user-machine-configuration.component';
import { TemplatesComponent } from '../common/templates/templates.component';
import { DistributionListComponent } from '../common/templates/distribution-list/distribution-list.component';
import { SpaUtilitiesComponent } from './Utilities/spa-utilities/spa-utilities.component';
import { InterfacesComponent } from './interfaces/interfaces.component';
import { PropertyInformationComponent } from './system-config/property-information/property-information.component';
import { AppointmentComponent } from './system-config/appointment/appointment.component';
import { MiscellaneousComponent } from '../retail/sytem-config/miscellaneous/miscellaneous.component';
import { CreditCardsComponent } from '../retail/sytem-config/credit-cards/credit-cards.component';
import { RguestBookSetupComponent } from './system-config/rguest-book-setup/rguest-book-setup.component';
import { VatComponent } from '../retail/sytem-config/vat-configuration/vat-configuration.component';
import { GiftCardsComponent } from '../retail/sytem-config/gift-cards/gift-cards.component';
import { PaymentFeaturesConfigComponent } from '../retail/sytem-config/payment-features-config/payment-features-config.component';
import { EmailConfigurationSetupComponent } from './system-config/email-configuration-setup/email-configuration-setup.component';
import { NotificationConfigurationComponent } from '../common/templates/notification-configuration/notification-configuration.component';
import { UserSetupComponent } from './user-config/user-setup/user-setup.component';
import { RoleSetupComponent } from './user-config/role-setup/role-setup.component';
import { UserRoleComponent } from './user-config/user-role/user-role.component';
import { SpaSetupComponent } from './Setup/spa-setup/spa-setup.component';
import { GridType, SPAScheduleBreakPoint, SPAManagementBreakPoint, RetailBreakPoint, InventoryBreakPoint } from '../shared/globalsContant';
import { RouteGuardService } from '../core/services/route-gaurd.service';
import { SpaTemplatesComponent } from './Utilities/spa-templates/spa-templates.component';
import { EatecComponent } from './eatec/eatec.component';
import { QuickidConfigComponent } from './Utilities/quickid-config/quickid-config.component';
import { AgCombineGuestRecordsComponent } from '../common/components/combine-guest-records/combine-guest-records.component';
import { DeactivateGuard } from '../core/services/Route-Guards/deactivate.guard.service';
import { ManagerUtilitiesComponent } from './Utilities/spa-utilities/manager-utilities/manager-utilities.component';
import { PrinterDefaultConfigurationComponent } from './Utilities/spa-utilities/manager-utilities/printer-default-configuration/printer-default-configuration.component';
import { UserAccessBreakPoints } from '../shared/enums/useraccess.constants';
import { LostDenialReasonsSetupComponent } from './Setup/lost-denial-reasons-setup/lost-denial-reasons-setup.component';
import { SftpSetupComponent } from '../common/shared/shared/sftp-setup/sftp-setup.component';
import { EventSchedulerComponent } from '../common/shared/shared/event-scheduler/event-scheduler.component';
import { ReportSchedularWrapperComponent } from './system-config/report-schedular-wrapper/report-schedular-wrapper.component';
import { EnahancedInventoryMasterSyncComponent } from '../retail/enahanced-inventory-master-sync/enahanced-inventory-master-sync.component';
import { LogTypeComponent } from '../common/log-type/log-type.component';
import { GuestTypeComponent } from './Setup/guest-type/guest-type.component';
import { RelationshipComponent } from './Setup/relationship/relationship.component';
import { UtilStaffReaderBoardComponent } from './Utilities/util-staff-reader-board/util-staff-reader-board.component';
import { DedupeGuestRecordsComponent } from '../common/components/dedupe-guest-records/dedupe-guest-records.component';
import { UserAccessBreakPoints as AccessBreakpoint}  from 'src/app/common/constants/useraccess.constants';
import { CgpsLoggingProfileSyncWrapperComponent } from './Utilities/cgps-logging-profile-sync-wrapper/cgps-logging-profile-sync-wrapper.component';
import { JobSchedulerComponent } from './Utilities/job-scheduler/job-scheduler.component';
import { EventSchedulerConfigurationComponent } from '../common/shared/shared/event-scheduler-configuration/event-scheduler-configuration.component';
import { SftpLandingComponent } from '../common/sftp/sftp-landing/sftp-landing.component';
import { InstructorScheduleComponent } from './instructor-schedule/instructor-schedule.component';
import { VipTypeComponent } from '../common/components/vip-type/vip-type.component';
import { SpaCancellationNoShowFeeComponent } from './spa-cancellation-no-show-fee/spa-cancellation-no-show-fee.component';
import { CancellationNoShowWrapperComponent } from './cancellation-no-show-wrapper/cancellation-no-show-wrapper.component';
import { CancellationNoShowPolicyMappingWrapperComponent } from './cancellation-no-show-policy-mapping-wrapper/cancellation-no-show-policy-mapping-wrapper.component';
import { DynamicYieldingComponent } from './dynamic-yielding/dynamic-yielding.component';
import { ServiceDiscountTypeComponent } from './Utilities/service-discount-type/service-discount-type.component';
import { DiscountMappingComponent } from 'src/app/retail/discount-mapping/discount-mapping.component';
import { LockersComponent } from './Utilities/lockers/lockers.component';
import { SpaOccupancyComponent } from './Utilities/spa-occupancy/spa-occupancy.component';
import { AcrSetupComponent } from './system-config/acr-setup/acr-setup.component';
import { HomeDashboardConfigurationComponent } from './system-config/home-dashboard-configuration/home-dashboard-configuration.component';

const routes: Routes = [{
  path: '', component: SettingComponent,
  children: [
    { path: '', redirectTo: 'setup', pathMatch: 'full' },
    {
      path: 'setup',
      component: SpaSetupComponent,
      // canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/staffSchedule', hasChild: true },
      children: [
        {
          path: '',
          redirectTo: 'servicegroup',
          pathMatch: 'full'
        },
        {
          path: 'servicegroup',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 0, type: GridType.group, breakPointNumber: SPAScheduleBreakPoint.SettingServiceGroup, redirectTo: '/settings/setup/servicelocation' }
        },
        {
          path: 'servicelocation',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 1, type: GridType.location, breakPointNumber: SPAScheduleBreakPoint.SettingServiceLocation, redirectTo: '/settings/setup/therapistsetup' }
        },
        {
          path: 'therapistsetup',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 2, type: GridType.therapist, breakPointNumber: SPAScheduleBreakPoint.SettingTherapistSetup, redirectTo: '/settings/setup/medicalcondition' }
        },
        {
          path: 'medicalcondition',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 3, type: GridType.medical, breakPointNumber: SPAScheduleBreakPoint.SettingMedicationCondition, redirectTo: '/settings/setup/serviceaddon' }
        },
        {
          path: 'serviceaddon',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 4, type: GridType.addons, breakPointNumber: SPAScheduleBreakPoint.SettingServiceAddOn, redirectTo: '/settings/setup/serviceequipment' }
        },
        {
          path: 'serviceequipment',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 5, type: GridType.equipment, breakPointNumber: SPAScheduleBreakPoint.SettingServiceEquipment, redirectTo: '/settings/setup/spaservice' }
        },
        {
          path: 'spaservice',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 6, type: GridType.spaServices, breakPointNumber: SPAScheduleBreakPoint.SettingSpaService, redirectTo: '/settings/setup/spapackage' }
        },
        {
          path: 'spapackage',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 7, type: GridType.spaPackage, breakPointNumber: SPAScheduleBreakPoint.SettingSpaPackage, redirectTo: '/settings/setup/cancelreason' }
        },
        {
          path: 'cancelreason',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 8, type: GridType.cancelReasons, breakPointNumber: SPAScheduleBreakPoint.SettingCancelReason, redirectTo: '/settings/setup/packageclasses' }
        },
        {
          path: 'packageclasses',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 9, type: GridType.packageClasses, breakPointNumber: SPAScheduleBreakPoint.SettingPackageClasses, redirectTo: '/settings/setup/lunchsetup' }
        },
        {
          path: 'lunchsetup',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 10, type: GridType.lunchsetup, breakPointNumber: SPAScheduleBreakPoint.SettingLunchSetup, redirectTo: '/settings/setup/colorlinking' }
        },
        {
          path: 'colorlinking',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 11, type: GridType.colorLink, breakPointNumber: SPAScheduleBreakPoint.SettingColorLinking, redirectTo: '/settings/setup/breaktype' }
        },
        {
          path: 'breaktype',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 12, type: GridType.breakType, breakPointNumber: SPAScheduleBreakPoint.SettingBreakType, redirectTo: '/settings/setup/pricetype' }
        },
        {
          path: 'pricetype',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 13, type: GridType.priceType, breakPointNumber: SPAScheduleBreakPoint.SettingPriceType, redirectTo: '/settings/setup/customfields' }
        },
        {
          path: 'customfields',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 14, type: GridType.customfield, breakPointNumber: SPAScheduleBreakPoint.SettingCustomFields, redirectTo: '/settings/setup/lostdeniedreasons' }
        },
        {
          path: 'lostdeniedreasons',
          component: LostDenialReasonsSetupComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 15, type: GridType.lostDeniedreason, breakPointNumber: SPAScheduleBreakPoint.LostDeniedReason, redirectTo: '/settings/setup/logtype' }

        },
        {
          path: 'logtype',
          component: LogTypeComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 16, type: GridType.logtype, breakPointNumber: SPAScheduleBreakPoint.LogType, redirectTo: '/settings/setup/guesttype' }

        },
        {
          path: 'guesttype',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 16, type: GridType.guestType, breakPointNumber: SPAScheduleBreakPoint.GuestType, redirectTo: '/settings/setup/relationship' }
        },
        {
          path: 'relationship',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 17, type: GridType.relationship, breakPointNumber: SPAScheduleBreakPoint.Relationship, redirectTo: '/settings/setup/viptype' }

        },
        {
          path: 'viptype',
          component: VipTypeComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 18, type: GridType.viptype, breakPointNumber: AccessBreakpoint.VipType, redirectTo: '/settings/setup/classgroup' }
        },
        {
          path: 'classgroup',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 18, type: GridType.classGroup, breakPointNumber: SPAScheduleBreakPoint.SettingClassGroup, redirectTo: '/settings/setup/classsetup' }
        },
        {
          path: 'classsetup',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 19, type: GridType.classSetup, breakPointNumber: SPAScheduleBreakPoint.SettingClassSetup, redirectTo: '/settings/setup/instructorsetup' }
        },
        {
          path: 'instructorsetup',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 20, type: GridType.instructorSetup, breakPointNumber: SPAScheduleBreakPoint.SettingInstructorSetup, redirectTo: '/settings/setup/classlocation' }
        },
        {
          path: 'classlocation',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 21, type: GridType.classLocation, breakPointNumber: SPAScheduleBreakPoint.SettingClassLocation, redirectTo: '/settings/setup/classequipment' }
        },
        {
          path: 'classequipment',
          component: SetUpComponent,
          canActivate: [RouteGuardService],
          data: { serviceList: 22, type: GridType.classEquipment, breakPointNumber: SPAScheduleBreakPoint.SettingClassEquipment, ShowPopup:true }
        },
      ]
    },
    {
      path: 'staffSchedule',
      component: StaffScheduleComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/commissionsetup', breakPointNumber: SPAManagementBreakPoint.StaffSchedule, hasChild: false }
    },
    {
      path: 'instructorSchedule',
      component: InstructorScheduleComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/commissionsetup', breakPointNumber: SPAManagementBreakPoint.StaffSchedule, hasChild: false }
    },
    {
      path: 'commissionsetup',
      component: RetailCommissionSetupComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/packageyielding', breakPointNumber: SPAManagementBreakPoint.CommissionClassSetup, hasChild: false }
    },
    {
      path: 'packageyielding',
      component: PackageYieldingComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/serviceyielding', breakPointNumber: SPAScheduleBreakPoint.SettingPackageYielding, hasChild: false }
    },
    // {
    //   path: 'serviceyielding',
    //   component: ServiceYieldingComponent,
    //   canActivate: [RouteGuardService],
    //   data: { redirectTo: '/settings/utilities', breakPointNumber: SPAScheduleBreakPoint.SettingServiceYielding, hasChild: false }
    // },
    {
      path: 'serviceyielding',
      component: DynamicYieldingComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/utilities', breakPointNumber: SPAScheduleBreakPoint.SettingServiceYielding, hasChild: false }
    },
    {
      path: 'utilities',
      component: SpaUtilitiesComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/retailsetup', hasChild: true },
      children: [
        {
          path: '',
          redirectTo: 'equipmentmaintenance',
          pathMatch: 'full'
        },
        {
          path: 'equipmentmaintenance',
          component: UtilitiesComponent,
          canActivate: [RouteGuardService],
          data: { id: GridType.equipmentMaintenance, breakPointNumber: SPAManagementBreakPoint.EquipmentMaintenance, redirectTo: '/settings/utilities/locationmaintenance' }
        },
        {
          path: 'locationmaintenance',
          component: UtilitiesComponent,
          canActivate: [RouteGuardService],
          data: { id: GridType.locationMaintenance, breakPointNumber: SPAManagementBreakPoint.LocationMaintenance, redirectTo: '/settings/utilities/therapistunavailability' }
        },
        {
          path: 'therapistunavailability',
          component: UtilitiesComponent,
          canActivate: [RouteGuardService],
          data: { id: GridType.therapistUnavailability, breakPointNumber: SPAManagementBreakPoint.TherapistUnavailability, redirectTo: '/settings/utilities/movemultipleappointments' }
        },
        {
          path: 'movemultipleappointments',
          component: MultipleAppointmentsComponent,
          canActivate: [RouteGuardService],
          data: { id: GridType.multipleAppointments, breakPointNumber: SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments, redirectTo: '/settings/utilities/receiptconfiguration' }
        },
        {
          path: 'receiptconfiguration',
          component: ReceiptConfigurationComponent,
          canActivate: [RouteGuardService],
          data: { id: GridType.recieptConfiguration, breakPointNumber: RetailBreakPoint.ReceiptConfiguration, redirectTo: '/settings/utilities/usermachineconfiguration' }
        },
        {
          path: 'managerUtilities',
          component: ManagerUtilitiesComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: UserAccessBreakPoints.PRINTERDEFAULTCONFIGURATION, redirectTo: ''},
          children: [
            { path: '', redirectTo: 'printerDefaultConfiguration', pathMatch: 'full' },
            {
              path: 'printerDefaultConfiguration',
              component: PrinterDefaultConfigurationComponent,
              canActivate: [RouteGuardService], canDeactivate: [DeactivateGuard],
              data: { breakPointNumber: UserAccessBreakPoints.PRINTERDEFAULTCONFIGURATION, redirectTo: '' }
            },
          ]
        },
        {
          path: 'usermachineconfiguration',
          component: UserMachineConfigurationComponent,
          canActivate: [RouteGuardService],
          data: { id: GridType.userMachineConfiguration, breakPointNumber: RetailBreakPoint.UserSessionConfiguration, redirectTo: '/settings/utilities/templates' }
        },
        {
          path: 'templates',
          component: SpaTemplatesComponent, 
          canActivate: [RouteGuardService],
          data: { hasChild: true, redirectTo: '/settings/utilities/distributionlist'},
          children: [
            { path: '', redirectTo: 'email', pathMatch: 'full' },
            {
              path: 'email',
              component: TemplatesComponent,
              canActivate: [RouteGuardService],
              data: { templateID: 1, breakPointNumber: AccessBreakpoint.EmailTemplate, redirectTo: '/settings/utilities/templates/sms'}
            },
            {
              path: 'sms',
              component: TemplatesComponent,
              canActivate: [RouteGuardService],
              data: { templateID: 2 ,breakPointNumber: AccessBreakpoint.SMSTemplate,redirectTo: '/settings/utilities/templates/report'}
            },
            {
              path: 'report',
              component: TemplatesComponent,
              canActivate: [RouteGuardService],
              data: { templateID: 3 ,breakPointNumber: AccessBreakpoint.ReportTemplate, redirectTo :''}
            }
          ]
        },
        {
          path: 'distributionlist',
          component: DistributionListComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: AccessBreakpoint.DistributionList,redirectTo: '/settings/utilities/quickidconfig'}
            
        },
        {
          path: 'quickidconfig',
          component: QuickidConfigComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAManagementBreakPoint.QUICKIDCONFIG, redirectTo: '/settings/utilities/combineguest' }

        },
        {
          path: 'combineguest',
          component: AgCombineGuestRecordsComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [DeactivateGuard],
          data: { id: GridType.combineGuestRecords, breakPointNumber: SPAManagementBreakPoint.CombineGuestRecords, redirectTo: '/settings/utilities/dedupeguest' }
        },
        {
          path: 'dedupeguest',
          component: DedupeGuestRecordsComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [DeactivateGuard],
          data: { id: GridType.dedupeGuestProfiles, breakPointNumber: SPAManagementBreakPoint.DeDupeGuestRecords, redirectTo: '/settings/utilities/jobScheduler' }
        },
        {
          path: 'jobScheduler',
          component: JobSchedulerComponent,
          canActivate: [RouteGuardService],
          data: { hasChild: true, redirectTo: '/settings/utilities/inventorysync' },
          children: [
            { path: '', redirectTo: 'sftp', pathMatch: 'full' },
            {
              path: 'sftp',
              component: SftpLandingComponent,
               canActivate: [RouteGuardService],
              data: {
                templateID: 1 ,
                breakPointNumber: AccessBreakpoint.SFTP,
                redirectTo: '/settings/utilities/jobScheduler/jobSchedulerConfig'
              }
            },
            {
              path: 'jobSchedulerConfig',
              component: EventSchedulerConfigurationComponent,
               canActivate: [RouteGuardService],
              data: {
                templateID: 2,
                breakPointNumber: AccessBreakpoint.JOBSCHEDULERCONFIG ,
                redirectTo: '/settings/utilities/jobScheduler/eventScheduler'
              }
            },
            {
              path: 'eventScheduler',
              component: ReportSchedularWrapperComponent,
               canActivate: [RouteGuardService],
              data: {
                templateID: 3 ,
                breakPointNumber: AccessBreakpoint.EVENTSCHEDULER,
                redirectTo: '',
                ShowPopup: true
              }
            }
          ]
        },
        {
          path: 'inventorysync',
          component: EnahancedInventoryMasterSyncComponent,
          canActivate: [RouteGuardService],
          data: { id: GridType.inventorysync, breakPointNumber: RetailBreakPoint.inventorySync, redirectTo: '/settings/utilities/staffreaderboard' }
        },
        { path: 'staffreaderboard',
        component: UtilStaffReaderBoardComponent,
        canActivate: [RouteGuardService],
        canDeactivate: [DeactivateGuard],
        data: { breakPointNumber: SPAScheduleBreakPoint.staffReaderBoard,  redirectTo: '/settings/utilities/cgpsFailedProfile' }
        },
        {
          path: 'cgpsFailedProfile', component: CgpsLoggingProfileSyncWrapperComponent,
          data: { breakPointNumber: SPAScheduleBreakPoint.CGPSFailedProfile, redirectTo: '/settings/utilities/discountMapping', showtableRecords: true },
          canActivate: [RouteGuardService],
          canDeactivate: [DeactivateGuard],
        },
        // {
        //   path: 'serviceDiscountType', component: ServiceDiscountTypeComponent,
        //   data: { redirectTo: '/settings/utilities/discountMapping' },
        // },
        {
          path: 'discountMapping', component: DiscountMappingComponent,
          data: { breakPointNumber: AccessBreakpoint.ADDEDITDISCOUNTMAPPING, redirectTo: '/settings/utilities/cancellationOrNoShowFee'},
          canActivate: [RouteGuardService],
          canDeactivate: [DeactivateGuard],
        },
        {
          path: 'cancellationOrNoShowFee',
          component: SpaCancellationNoShowFeeComponent, 
          canActivate: [RouteGuardService],
          data: { hasChild: true, redirectTo: '/settings/utilities/lockers' },
          children: [
            { path: '', redirectTo: 'policy', pathMatch: 'full' },
            {
              path: 'policy',
              component: CancellationNoShowWrapperComponent,
              canActivate: [RouteGuardService],
              data: { templateID: 1, breakPointNumber: AccessBreakpoint.CancellationNoShowPolicy, redirectTo: '/settings/utilities/cancellationOrNoShowFee/policy'}
            },
            {
              path: 'mapping',
              component: CancellationNoShowPolicyMappingWrapperComponent,
              canActivate: [RouteGuardService],
              data: { templateID: 2 ,breakPointNumber: AccessBreakpoint.CancellationNoShowMapping,redirectTo: '/settings/utilities/cancellationOrNoShowFee/mapping'}
            }
          ]
        },
        {
          path: 'lockers',
          component: LockersComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [DeactivateGuard],
          data: { id: GridType.Lockers, breakPointNumber: RetailBreakPoint.LockerType, redirectTo: '/settings/utilities/spaOccupancy' }
        },
        {
          path: 'spaOccupancy',
          component: SpaOccupancyComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [DeactivateGuard],
          data: {breakPointNumber: SPAScheduleBreakPoint.SpaOccupancy, showPopup :true}
        },
      ]
    },
    {
      path: 'retailsetup',
      loadChildren: () => import('../retail/retail.module').then(m => m.RetailModule),
      data: { redirectTo: '/settings/inventory', hasChild: true }
    },
    {
      path: 'inventory',
      component: RetailInventorySettingComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/enhancedInventory', breakPointNumber: InventoryBreakPoint.InventoryManagement, hasChild: false }
    },
    {
      path: 'enhancedInventory',
      loadChildren: () => import('./eatec/eatec-routing.module').then(m => m.EatecRoutingModule),
      data: { redirectTo: '/settings/systemconfig', hasChild: false }
    },
    {
      path: 'systemconfig',
      component: SystemConfigComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '/settings/userconfig', breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, hasChild: true },
      children: [
        { path: '', redirectTo: 'propertyinformation', pathMatch: 'full' },
        {
          path: 'propertyinformation',
          component: PropertyInformationComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'appointment',
          component: AppointmentComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'miscellaneous',
          component: MiscellaneousComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'creditcards',
          component: CreditCardsComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'rguestbooksetup',
          component: RguestBookSetupComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'vatconfigurations',
          component: VatComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'giftconfigurations',
          component: GiftCardsComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'paymentconfigurations',
          component: PaymentFeaturesConfigComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'notifyconfigurations',
          component: EmailConfigurationSetupComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'notifications',
          component: NotificationConfigurationComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'acrSetup',
          component: AcrSetupComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
        {
          path: 'dashboardConfiguration',
          component: HomeDashboardConfigurationComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.SettingSystemSettings, redirectTo: '/settings/userconfig' }
        },
      ]
    },
    {
      path: 'userconfig',
      component: UserConfigComponent,
      canActivate: [RouteGuardService],
      data: { redirectTo: '', hasChild: true },
      children: [
        { path: '', redirectTo: 'usersetup', pathMatch: 'full' },
        {
          path: 'usersetup',
          component: UserSetupComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.UserSetup, redirectTo: '/settings/userconfig/rolesetup' }
        },
        {
          path: 'rolesetup',
          component: RoleSetupComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.UserRoleSetUp, redirectTo: '/settings/userconfig/userroleconfiguration' }
        },
        {
          path: 'userroleconfiguration',
          component: UserRoleComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: SPAScheduleBreakPoint.UserRoleConfiguration, redirectTo: '' }
        }
      ]
    },
    {
      path: 'interfaces',
      //loadChildren: () => import('./interfaces/interfaces.module').then(m => m.InterfacesModule),
      component: InterfacesComponent,
      canActivate: [RouteGuardService],
      data: { hasChild: true, lastBreakPointNumber: UserAccessBreakPoints.DATAMAGINE_CONFIGURATION },
      children: [
        { path: '', redirectTo: 'admIntegration', pathMatch: 'full' },
        {
          path: 'admIntegration',
          loadChildren: () => import('./interfaces/adm-integration/adm-integration.module').then(m => m.AdmIntegrationModule),
          canActivate: [RouteGuardService],
          data: { breakPointNumber: UserAccessBreakPoints.ADMINTEGRATIONSETUP, redirectTo: '/settings/interfaces/pmsIntegration' , ShowPopup : false}
        },
        {
          path: 'pmsIntegration',
          loadChildren: () => import('../common/components/pmsintegration/pms-integration.module').then(m => m.PMSIntegrationModule),
          // canActivate: [RouteGuardService],
          // data: { breakPointNumber: UserAccessBreakPoints.ADMINTEGRATIONSETUP, redirectTo: 'authorizeIntegration' , ShowPopup : false}
        },
        {
          path: 'dataMagine',
          loadChildren: () => import('./interfaces/datamagine/data-magine.module').then(m => m.DataMagineModule),
          canActivate: [RouteGuardService],
          data: { breakPointNumber: UserAccessBreakPoints.DATAMAGINE_CONFIGURATION, redirectTo: '/settings/interfaces/authorizeIntegration' }
        },
        {
          path: 'authorizeIntegration',
          loadChildren: () => import('./interfaces/authorize-integration/authorize-integration.module').then(m => m.AuthorizeIntegrationModule),
          canActivate: [RouteGuardService],
          data: { breakPointNumber: UserAccessBreakPoints.AUTHORIZE_CONFIGURATION, redirectTo:'' }
      } 
      ]
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
