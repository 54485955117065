export enum SPAApiHosts {
  SalesCateringService='',
  TenantManagement='',
  RetailManagement='',
  SNCGateway='',
  Report='',
  PayAgent='',
  MsDeeplink='',
  V1IGPosting='',
  Documentation='',
}

export class MsalConfiguration {
  AppId: string;
  RedirectUri: string;
  Scopes: string[];
}

export enum MsGraphRoutes {
  Me = '/me',
  MeMessages = '/me/messages',
  MeEvents = '/me/events',
  MeTasks = '/me/outlook/tasks',
  MeContacts = '/me/contacts?$filter=createdDateTime ge {lastSyncTime} or lastModifiedDateTime ge {lastSyncTime} &$top=100',
  // MeContacts='/me/contacts?$top=100',
  MeDeltaContacts = '/me/contacts/delta',
  MeUpdateContacts = '/me/contacts',
  MeSendMail = '/me/sendMail',
  Batch = '/$batch'
}

export enum GoogleApiRoutes {
  MapApi = 'https://maps.google.com/maps/api/js?sensor=true&key={apiKey}&libraries=places&language={language}'
}

export enum PropertyApiRoutes {
  changePropertyNewTab = '{hostName}/setProperty?token={token}&propertyId={propertyId}&propertyName={propertyName}&routeParam={routeParam}',
  changePropertySameTab = '/setProperty'

}

export enum OutlookRoutes {
  GetAllTask = 'me/outlook/tasks',
  CreateTask = 'me/outlook/tasks',
  updateTask = 'me/outlook/tasks/{id}',
}

export enum SPARoutes{
  GetUserByTenantId = 'User/GetUserByTenantId/{UserName}/{tenantId}',
  GetTenantGroupDetailByProductId = 'TenantOrganization/GetTenantGroupDetailByProductId?productId={productId}',
  GetPropertyDetailsByProductId = 'Property/GetPropertyDetailsByProductId?productId={productId}',
  SupportUserLogin = 'Adb2CAuthentication/GetSupportUserLoginDetails',
  ValidateUserByProductTenantAndEmail = 'User/ValidateUserByProductTenantAndEmail?productId={productId}&tenantId={tenantId}&emailId={emailId}',
  AuditSupportUser = 'Adb2CAuthentication/AuditSupportUser'
}
