export namespace API {
    export interface CustomQueryStore {
        id: number,
        queryId: string,
        name: string,
        modelId: string,
        queryJson: string,
        isSelected: boolean,
        filePrefix: string,
        delimiter: string,
        dateFormat: string,
        isQuotesEnclosed: boolean,
        excludeDecimals: boolean,
        decimalLength: number,
        currency: string,
        depositLedger: string,
        isHeaderNeeded: boolean,
        extractGLAccountNumber: string,
        extractDetails: ExtractDetails[]
    }

    export interface ExtractDetails {
        id: number,
        customQueryStoreId: number,
        columnId: string,
        field: string,
        displayName: string,
        isEnabled: boolean,
        order: number,
        length: number,
    }

    export interface CustomQueryGroup {
        groupName: number,
        queryListItem: QueryListItem[],
    }

    export interface QueryListItem {
        id: string,
        name: string,
        text: string,
        description: string, 
    }

    export interface CustomQueryExtractData {
        columnDetails: string,
        reportData: string
    }
}
export namespace UI {
    export interface CustomQueryStore {
        //  index:number,
        id: number,
        queryId: string,
        name: string,
        modelId: string,
        queryJson: string,
        isSelected: boolean,
        filePrefix: string,
        delimiter: string,
        dateFormat: string,
        isQuotesEnclosed: boolean,
        excludeDecimals: boolean,
        decimalLength: number,
        currency: string,
        depositLedger: string,
        isHeaderNeeded: boolean,
        extractGLAccountNumber: string,
        extractDetails: ExtractDetails[]
    }

    export interface ExtractDetails {
        id: number,
        customQueryStoreId: number,
        columnId: string,
        field: string,
        isEnabled: boolean,
        order: number,
        length: number,
        displayName: string,
    }

    export interface customExportForm {
        format: string | number | null,
        tableDirty: string,
        encloseQuotes: boolean,
        excludeDecimal: boolean,
        filenamePrefix: string,
        dateFormat: string | number,
        enableHeader: boolean,
        customDelimiter: string
    }

    export interface extractDetailTable {
        id: number,
        checked: boolean,
        columnId: string,
        fields: string,
        length: string,
        order: string,
        visibility: boolean,
        orderNumber: number,
        displayName: string,
        dataType: string
    }

    export interface CustomQueryGroup {
        groupName: string,
        queryListItem: QueryListItem[],
    }

    export interface QueryListItem {
        id: string,
        name: string,
        text: string,
        description: string, 
    }

    export interface CustomQueryExtractData {
        columnDetails: string,
        reportData: string
    }
}

export enum EasyQueryDataType {
    Unknown,
    String,
    Byte,
    Word,
    Int,
    Int32,
    Int64,
    Bool,
    Float,
    Currency,
    BCD,
    Date,
    Time,
    DateTime,
    Autoinc,
    Memo,
    Blob,
    FixedChar,
    Guid,
    Geometry,
}

export enum ExtractList {
   Folio = 'Folio',
   GenericExtract= 'GenericExtract',
   Group= 'Group',
   Guest= 'Guest',
   Reservations= 'Reservations',
   Retail= 'Retail',
   RoomInventory= 'RoomInventory',
   Accounting= 'Accounting',
   Membership= 'Membership',
  FrontDesk = 'FrontDesk',
  Sales = 'Sales',
  Default = 'Default'
}

export enum ErrorCode
{
    EasyQueryErrorCode  = 5013
}