import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { RguestCommunication } from '../common/rguest-communication';
import { ServiceParams, BaseResponse } from '../../Models/http.model';
import { AppService } from '../../app-service';


@Injectable({ providedIn: 'root' })
export class PMSManagerCommunication extends RguestCommunication {

    constructor(httpclient: HttpClient, localization: Localization, utilities: CommonUtilities,
        propertyinfo: CommonPropertyInformation, appservice: AppService) {
        super(environment["PMSManagerService"], httpclient, localization, utilities, propertyinfo, appservice);
    }

    public getObservable<T>(params: ServiceParams, handleErr: boolean = true): Observable<T> {
        return super.get<BaseResponse<T>>(params).pipe(
            map(response => response.result),
            catchError(err => {
                
                return throwError(err);
            })
        );
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        const response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postEasyQueryPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<EasyQueryResponse<T>> = super.postPromise<EasyQueryResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: EasyQueryResponse<T> = await response$;
        return response.resultSet;
    }

    public async putPromiseCancellable<T>(params: ServiceParams, handleErr: boolean = true, notifier: Subject<void>): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.putCancellablePromise<BaseResponse<T>>(params, notifier);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }
    /*
    This is not the correct way to get return type as base response.
    Todo: IMPORTANT. NEED TO REVAMPED
    */
    public async postPromiseReturnsBaseResponse<T>(params: ServiceParams, handleErr: boolean = true): Promise<BaseResponse<T>> {

        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response;
    }

    public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }


    public async patchPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.patchPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putDownloadPromise(params: ServiceParams) {
        const response$ = super.excelPut(params).toPromise();
        return await response$;
    }

}

export interface EasyQueryResponse<T> extends BaseResponse<T> {
    resultSet: T;
}