import { AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { AddNewTypeConfig, ItemQueryListConfiguaration, LookupConfiguration, SearchShowMoreConfig, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { QueryListModel } from '../model/pop-up.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import moment from 'moment';
import * as _ from 'lodash';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { ActivatedRoute } from '@angular/router';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { QueryListInterface } from 'src/app/eatecui/source/shared/models/query-list';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { MasterInventoryManagement, SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
@Component({
  selector: 'app-agilysys-querylist',
  templateUrl: './agilysys-querylist-popup.component.html',
  styleUrls: ['./agilysys-querylist-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysQueryListComponent implements AfterViewInit {
  agilysysTempCheckButton: AgilysysButtonModule[];
  selectedIndex = 0;
  transactionJsonData: TransactionConfiguration;
  selectedItemList: Array<any> = [];
  rowSelectedList = [];
  rowSelectedData = [];
  originalQueryTransaction: TransactionConfiguration;
  selectedTransaction: string;
  addNewTypeConfig: AddNewTypeConfig;
  showMoreConfig = {
    FetchAll: false,
    Skip: 0,
    Take: constants.InfiniteScrollItemListCount
  } as SearchShowMoreConfig;
  ScrollItemCount: any;
  scrollReached: boolean;
  constructor(
    private masterManagementService: MasterManagementService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AgilysysQueryListComponent>,
    private transactionService: TransactionService,
    @Inject(MAT_DIALOG_DATA) public data: QueryListModel,
    private httpService: HttpService,
    private translateService: TranslateService) {
    this.ScrollItemCount = sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting')).LoadMoreItems : constants.InfiniteScrollItemCount;
    this.selectedTransaction =
      this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'transaction', null);
    if (this.selectedTransaction === undefined) {
      this.selectedTransaction =
        this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'master', null);
    }
    this.addNewTypeConfig = this.data.AddNewListConfigs;
    this.agilysysTempCheckButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey]);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, this.selectedItemList]);
        }
      }
    ];
    this.transactionJsonData = { ...this.data.transactionJsonData };
    this.originalQueryTransaction = _.cloneDeep(this.data.transactionJsonData);
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
      .forEach((listconfig: ItemQueryListConfiguaration) => {
        listconfig.listGridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
        listconfig.listGridData.rowChecked = (value: Array<any>, event?: any) => {
          this.selectedItemList = [...value];          
          // this.selectedItemList[this.selectedIndex] = value;
          event.forEach((element: any) => {
            if (element.Checked) {
              if (!this.rowSelectedList.includes(element.GridId)) {
                this.rowSelectedList.push(element.GridId);
                this.rowSelectedData.push(element);
              }
            } else {
              if (this.rowSelectedList.indexOf(element.GridId) !== -1) {
                const IndexValue = this.rowSelectedList.indexOf(element.GridId);
                this.rowSelectedList.splice(this.rowSelectedList.indexOf(element.GridId), 1);
                this.rowSelectedData.splice(IndexValue, 1);
              }
            }
          });
          if (this.rowSelectedData && this.rowSelectedData.length > 0) {
            this.selectedItemList = [];
            this.rowSelectedData.forEach(selectedData => {
              this.selectedItemList.push(selectedData);
            });
          }
          listconfig.listGridData.RowSelectedList = this.selectedItemList.map(res => {
             return res.GridId;
          });
        };
        listconfig.listGridData.searchTextChanged = (searchText) => {
          this.showMoreConfig.Skip = 0;
          this.showMoreConfig.Take = this.ScrollItemCount;
          this.showMoreConfig.SearchKey = searchText ? searchText : '';
          this.pageChanged();
          // this.searchTextChange(searchText);
        };
        listconfig.listGridData.sortChanged = (value) => {
          this.showMoreConfig.Skip = 0;
          this.showMoreConfig.Take = this.ScrollItemCount;
          this.sortChange(value);
        };
        listconfig.listGridData.bottomReached = (value) => {
          this.scrollReached = value;
          this.showMoreConfig.Skip = 0;
          this.showMoreConfig.Take = this.showMoreConfig.Take + this.ScrollItemCount;
          this.pageChanged();
        };
      });
  }

  pageChanged() {
    this.transactionService.transactionConfigurationData.CreateTranscationConfig.
    ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds = [this.data.SelectedIds[this.selectedIndex]];
    QueryListInterface.QueryTransDataTabClick(this.addNewTypeConfig, this.transactionJsonData,
      this.httpService, this.translateService, null, null, this.transactionService,
      this.selectedTransaction, this.data.OdataGlobalEntity, this.masterManagementService, this.data.SelectedIds,
       this.data.SelectedQueryList, this.showMoreConfig).then(res => {
        console.log('QueryListLookUP', res);
        this.scrollReached = false;
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
          ItemQueryListConfig[this.selectedIndex].listGridData.TotalRecords = res[1];
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
          ItemQueryListConfig[this.selectedIndex].listGridData.GridActions.TotalItems = res[1];
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
          ItemQueryListConfig[this.selectedIndex].listGridData.GridData = [...res[0]];
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
          ItemQueryListConfig[this.selectedIndex].listGridData = {
            ...this.transactionJsonData.CreateTranscationConfig.
              ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].listGridData
        };
        this.originalQueryTransaction.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
        ItemQueryListConfig[this.selectedIndex].listGridData.GridData = [...res[0]];
      });
  }

  sortChange(value: any) {
    const orderBy = value[0].sort;
    const tempKey = value[0].colId;
    const gridItemList = this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
      ItemQueryListConfig[this.selectedIndex].listGridData;
    const key = gridItemList.GridHeaderDefination.filter(x => x.field === value[0].colId)[0].fieldType;
    const data = gridItemList.GridData;
    const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
    const DateTimeFormat = (sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null) ?
      sessionDateFormat : constants.DefaultIniDateFormat;
    if (key.toString() === 'date') {
      data.sort((a, b) => {
        const data1 = a[tempKey] ? Number(moment(a[tempKey], DateTimeFormat).format('X')) : 0;
        const data2 = b[tempKey] ? Number(moment(b[tempKey], DateTimeFormat).format('X')) : 0;
        if (orderBy.toString() === 'desc') {
          return data2 - data1;
        } else {
          return data1 - data2;
        }

      });
    } else if (key.toString() === 'number') {
      data.sort((a, b) => {
        const data1 = Number(a[tempKey]);
        const data2 = Number(b[tempKey]);
        if (orderBy.toString() === 'desc') {
          return data2 - data1;
        } else {
          return data1 - data2;
        }
      });
    } else if (key.toString() === 'string') {
      data.sort((a, b) => {
        const data1 = a[tempKey] ? a[tempKey].replace(/\s/g, '').toLowerCase() : '';
        const data2 = b[tempKey] ? b[tempKey].replace(/\s/g, '').toLowerCase() : '';
        if (orderBy.toString() === 'desc') {
          return data2.localeCompare(data1, undefined, { numeric: true });
        } else {
          return data1.localeCompare(data2, undefined, { numeric: true });
        }

      });
    }
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData.GridData = data;
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData.GridData = [...this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig
      .ItemQueryListConfig[this.selectedIndex].listGridData.GridData];
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData.GridActions.TotalItems = data.length;
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData = {
      ...this.transactionJsonData.CreateTranscationConfig.
        ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].listGridData
    };
  }
  // searchTextChange(searchkey: any) {
  //   const rowData = this.originalQueryTransaction.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
  //     ItemQueryListConfig[this.selectedIndex].listGridData.GridData;
  //   let returnData = [];
  //   if (searchkey.length > 0) {
  //     returnData = rowData.filter(x => x.Name.toLowerCase().includes(searchkey.toLowerCase()));
  //   } else {
  //     returnData = rowData;
  //   }

  //   this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
  //   [this.selectedIndex].listGridData.GridData = [];

  //   this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
  //   [this.selectedIndex].listGridData.GridData = returnData;

  //   this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
  //   [this.selectedIndex].listGridData.GridActions.TotalItems = returnData.length;

  //   this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
  //   [this.selectedIndex].listGridData = {
  //     ...this.transactionJsonData.CreateTranscationConfig.
  //       ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].listGridData
  //   };
  //   this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig = 
  //   [...this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig];

  // }
  searchTextChange(searchkey) {
    const rowData = this.originalQueryTransaction.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
      ItemQueryListConfig[this.selectedIndex].listGridData.GridData;
    let returnData = [];
    if (searchkey.length > 0) {
      returnData = rowData.filter(x => x.Name.toLowerCase().includes(searchkey.toLowerCase()));
    } else {
      returnData = rowData;
    }
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData.RowSelectedList = this.rowSelectedList;
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData.GridData = [];
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData.GridData = returnData;
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData.GridActions.TotalItems = returnData.length;
    this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
    [this.selectedIndex].listGridData = {
      ...this.transactionJsonData.CreateTranscationConfig.
        ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].listGridData
    };
    if (this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].
      listGridData.GridSort.length > 0) {
      const sortvalues = [
        {
          colId: this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].
            listGridData.GridSort[0].SortingOdataKey,
          sort: this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].
            listGridData.GridSort[0].SortingType
        }];
      this.sortChange(sortvalues);
    }
  }

  async selectedTabValue(event) {
    try {
      console.log('Selected Tab', event);
      this.showMoreConfig.Skip = 0;
      this.showMoreConfig.Take = this.ScrollItemCount;
      this.showMoreConfig.SearchKey = '';
      if ((this.addNewTypeConfig.EndPointUrl === 'IngredientLookup' || this.addNewTypeConfig.EndPointUrl === 'ProductLookup') &&
       this.transactionService.transactionConfigurationData) {
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.
          ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds = [this.data.SelectedIds[event.index]];
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.
          ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList = [this.data.SelectedQueryList[event.index]];
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
        [this.selectedIndex].listGridData.RowSelectedList = this.rowSelectedList;
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
        [this.selectedIndex].listGridData.ClearSearchFilter = true;
        QueryListInterface.QueryTransDataTabClick(this.addNewTypeConfig, this.transactionJsonData,
          this.httpService, this.translateService, null, null, this.transactionService,
          this.selectedTransaction, this.data.OdataGlobalEntity, this.masterManagementService, this.data.SelectedIds,
          this.data.SelectedQueryList, this.showMoreConfig).then(res => {
            // console.log('QueryListLookUP', res);
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData.TotalRecords = res[1];
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData.GridActions.TotalItems = res[1];
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig[
              event.index
            ].listGridData.GridData = [...res[0]];
            this.originalQueryTransaction.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig[
              event.index
            ].listGridData.GridData = [...res[0]];
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData.ClearSearchFilter = false;
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData = {
              ...this.transactionJsonData.CreateTranscationConfig.
                ItemsConfig.ItemQueryConfig.ItemQueryListConfig[event.index].listGridData
            };
            this.setScrollEvent();
          });
      } else if (this.addNewTypeConfig.EndPointUrl === 'IngredientLookup') {
        const transactionJsonData = this.formJsonData();
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.
          ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds = [this.data.SelectedIds[event.index]];
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.
          ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList = [this.data.SelectedQueryList[event.index]];
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
        [this.selectedIndex].listGridData.RowSelectedList = this.rowSelectedList;
        this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig
        [this.selectedIndex].listGridData.ClearSearchFilter = true;
        QueryListInterface.QueryMasterDataTabClick(this.addNewTypeConfig, transactionJsonData,
          this.httpService, this.translateService, null, null, this.transactionService,
          this.selectedTransaction, this.data.OdataGlobalEntity, this.masterManagementService, this.data.SelectedIds,
          this.data.SelectedQueryList).then(res => {
            // console.log('QueryListLookUP', res);
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData.TotalRecords = res[1];
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData.GridActions.TotalItems = res[1];
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData.GridData = [...res[0]];
              this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
                ItemQueryListConfig[event.index].listGridData.ClearSearchFilter = false;
            this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
              ItemQueryListConfig[event.index].listGridData = {
              ...this.transactionJsonData.CreateTranscationConfig.
                ItemsConfig.ItemQueryConfig.ItemQueryListConfig[event.index].listGridData
            };
            this.setScrollEvent();
          });
      }
    } catch (error) {
      console.error('Error occurred in selectedTabValue', error);
    }
  }

  formJsonData() {
    const transactionJsonData: TransactionConfiguration = {} as TransactionConfiguration;    
    const MasterJsonData: Array<MasterInventoryManagement> = this.masterManagementService.
      GetMasterJsonData(this.selectedTransaction, 2);
    transactionJsonData.Name = this.selectedTransaction;
    transactionJsonData.ViewTranscationConfig = {
        ViewHeaderOdataKey: 'Suppliers',
        HeaderConfiguration: null,
        ViewDetailOdataKey: null
    };
    let AddNewConfig: SubFormConfigs = MasterJsonData[0].NestedFormConfig.EditFormConfig.
      filter(x => x.hasOwnProperty('AddNewListConfig'))[0];
    if (this.selectedTransaction === 'Products') {
        AddNewConfig = {} as SubFormConfigs;
        AddNewConfig.QueryListMasterConfig = MasterJsonData[0].NestedFormConfig.EditFormConfig[0].
            LinkedItems.QueryListMasterConfig;
        AddNewConfig.AddNewListConfig = MasterJsonData[0].NestedFormConfig.EditFormConfig[0].
            LinkedItems.AddNewListConfig;
    }
    if (this.selectedTransaction === 'Recipes') {
        AddNewConfig = {} as SubFormConfigs;
        AddNewConfig.QueryListMasterConfig = MasterJsonData[0].AddItemList.QueryListMasterConfig;
        AddNewConfig.AddNewListConfig = MasterJsonData[0].AddItemList.AddNewListConfig;
    }
    let TypeId = 1;
    switch (AddNewConfig.QueryListMasterConfig.EntityName) {
        case 'IngredientBids':
            TypeId = 6;
            break;
        case 'SupplierBids':
            TypeId = 1;
            break;
        case 'ProductItemDetails':
            TypeId = 1;
            break;
        case 'RecipeIngredients':
            TypeId = 2;
            break;
    }
    transactionJsonData.QueryListTransactionConfig = AddNewConfig.QueryListMasterConfig;
    transactionJsonData.CreateTranscationConfig = {
        HeaderConfig: null,
        DetailsConfig: null,
        ItemsConfig: {
            ItemTableCard: null,
            KeyToEnablePost: null,
            ItemList: {
                AddNewListConfig: AddNewConfig.AddNewListConfig,
                EnableAddNewItem: true,
                EnableItemList: false,
                ExpandItemList: false,
                ItemGridConfig: null,
            },
            ItemQueryConfig: {
                ItemQueryGridHeaderList: AddNewConfig.QueryListMasterConfig.ItemQueryGridHeaderList,
                ItemQueryListConfig: []
            }
        },
        TransactionFormConfig: {
            FormFields: [],
            DisplayName: '',
            Key: '',
            Name: '',
            FormGroupData: {
                'TypeId': TypeId
            }
        }
    };
    this.transactionService.transactionConfigurationData = { ...transactionJsonData };
    return transactionJsonData;
  }

  AssignBidsOptions() {
    if (this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData) {
      const ExpiredBids = this.transactionJsonData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ExpiredBids'] ? '1' : '0';
      const Priority = this.transactionJsonData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['Priority'] ? '1' : '0';
      const SkipZero = this.transactionJsonData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['SkipZero'] ? '1' : '0';
      const ClearPrices = this.transactionJsonData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ClearPrices'] ? '1' : '0';
      const ClearSupplier = this.transactionJsonData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ClearSupplier'] ? '1' : '0';
      const BidRegion = this.transactionJsonData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['BidRegion'] ? '1' : '0';
      const options = ExpiredBids + Priority + SkipZero + ClearPrices + ClearSupplier + BidRegion;
      return options;
    } else {
      return '011000';
    }
  }
  ngAfterViewInit(): void {
    this.setScrollEvent();
  }

  setScrollEvent() {
    if (this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
      ItemQueryListConfig[this.selectedIndex].listGridData.GridData) {
      const gridPopup = document.getElementsByClassName('query-transaction-content')[0];
      const viewport = gridPopup.getElementsByClassName('ag-body-viewport')[0] as HTMLElement;
      if (viewport) {
        viewport.addEventListener('scroll', this.scrollEvent);
      }
    }
  }

  scrollEvent = (event) => {
    try {
      // Math.ceil(event.target.scrollHeight) === Math.ceil(event.target.scrollTop + event.target.offsetHeight)
      //   || Math.ceil(event.target.scrollHeight) <= Math.ceil(event.target.scrollTop + event.target.offsetHeight)
      const {scrollHeight, scrollTop, clientHeight} = event.target;
      if (Math.abs(scrollHeight - clientHeight - scrollTop) <= 1) {
        if (this.transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
          ItemQueryListConfig[this.selectedIndex].listGridData.TotalRecords !== this.transactionJsonData.CreateTranscationConfig.
            ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].listGridData.GridData.length) {
          if (this.transactionJsonData.CreateTranscationConfig.ItemsConfig.
            ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].listGridData.hasOwnProperty('bottomReached')) {
              if (!this.scrollReached) {
                this.transactionJsonData.CreateTranscationConfig.
                  ItemsConfig.ItemQueryConfig.ItemQueryListConfig[this.selectedIndex].listGridData.bottomReached(true);
              } else {
                this.scrollReached = false;
              }
          }
        }
      }
    } catch (error) {
      console.error('Error occurred in scrollEvent', error);
    }
  }

}
