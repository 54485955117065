import { cloneDeep } from 'lodash';
import { Directive, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { InputTypeValidator } from '../validators/inputtypeValidator';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaUtilities, InputTypeNumbers, InputTypeText } from 'src/app/shared/utilities/spa-utilities';


@Directive({
  selector: "[spaaginputtype]"
})

export class InputtypeDirective implements AfterViewInit {

  private listOfNumberTypes: string[] = [InputTypeNumbers.NUMBERS
    , InputTypeNumbers.ONLYPOSITIVE
    , InputTypeNumbers.ONLYNEGATIVE
    , InputTypeNumbers.NODECIMAL
    , InputTypeNumbers.DECIMAL
    , InputTypeNumbers.ROUNDOFF
    , InputTypeNumbers.PERCENT
    , InputTypeNumbers.POSITIVEDECIMAL
    , InputTypeNumbers.POSITIVEDECIMALORNUMERIC
    , InputTypeNumbers.NUMBERWITHSEPARATOR
    , InputTypeNumbers.PREMAXDECIMAL];

  private listOfTextType: string[] = [InputTypeText.CAP
    , InputTypeText.TEXT
    , InputTypeText.NOSPL
    , InputTypeText.NOSPACE
    , InputTypeText.EMAIL
    , InputTypeText.FIRST_CHAR_CAP
    , InputTypeText.NOPRESPACE
    , InputTypeText.WEBSITE
    , InputTypeText.RESTRICTSCRIPT];

  constructor(private element: ElementRef, private control: NgControl, public localization: SpaLocalization, private utils: SpaUtilities) {

  }
  @Input() inputtype: string;
  @Input() skipInputValidation = false;
  @Input() showErrPopup = false;
  @Input() maxlength = 0;
  @Input() maxpredecimalValue;
  private givenListType: string[];
  private negativeSign = '-';
  private DecimalSeperator: string = this.localization.decimalSeparator;
  private CommaSeperator = ',';

  includesArrayValue(baseArray: string[], inputArray: string[]): boolean {

    return inputArray.every((value) => {
      return (baseArray.indexOf(value) >= 0);
    });

  }

  ngAfterViewInit() {
    const input = cloneDeep(this.element.nativeElement.value);
    const valueEntered: string = input;
    if (this.inputtype) {
      this.givenListType = this.inputtype.replace(/ /g, '').split(',');
      if (this.givenListType.includes(InputTypeNumbers.DECIMAL) || this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL) || this.givenListType.includes(InputTypeNumbers.PREMAXDECIMAL)) {
        this.addDecimalSeperator(valueEntered);
      }
    }
  }

  @HostListener('blur', ['$event']) onblur(event) {
    if (this.skipInputValidation) {
      return;
    }
    const e = event as KeyboardEvent;
    this.givenListType = this.inputtype.replace(/ /g, '').split(',');
    if (this.includesArrayValue(this.listOfNumberTypes, this.givenListType)) {
      this.OnBlurFunction(e);
    }
    if (this.includesArrayValue(this.listOfTextType, this.givenListType)) {
      this.capitalizeblur(e);
      this.OnBlurFunction(e);
    }

  }


  @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
    this.givenListType = this.inputtype.replace(/ /g, '').split(',');
    let valueEntered = this.control.control.value ?
      this.control.control.value + e.clipboardData.getData('text/plain') :
      e.clipboardData.getData('text/plain');
    let valueTobePasted: string = new InputTypeValidator(this.utils, this.localization, valueEntered, this.givenListType).Validate();
    if(this.givenListType.includes(InputTypeText.RESTRICTSCRIPT)){
      valueEntered = valueEntered.replace('&lt','');
      valueEntered = valueEntered.replace('&gt','');
      const forbiddenKeys = ['<', '>'];
      let containsForbiddenChars = forbiddenKeys.some(char => valueEntered.includes(char));
      if(containsForbiddenChars) {
        valueTobePasted = '';
        e.preventDefault();
      }
    }
    if (this.maxlength > 0) {
      valueTobePasted = valueTobePasted.substring(0, this.maxlength);
    }
    this.control.control.setValue(valueTobePasted);
    e.preventDefault();
  }



  @HostListener('input', ['$event']) oninput(event) {
    if (this.skipInputValidation) {
      return;
    }

    if (!this.inputtype) {
      this.inputtype = '';
    }

    if (this.givenListType.includes(InputTypeText.EMAIL)) {
      let valueEntered = event.target.value;
      valueEntered = valueEntered ? valueEntered : '';
      if (!this.validateEmail(valueEntered)) {
        if (valueEntered.trim() != '') {
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }

    if (this.givenListType.includes(InputTypeText.WEBSITE)) {
      let valueEntered = event.target.value;
      valueEntered = valueEntered ? valueEntered : '';
      if (!this.validateWebsite(valueEntered)) {
        if (valueEntered.trim() != '') {
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }
  }

  @HostListener('keyup', ['$event']) onkeyup(event) {
    if (this.skipInputValidation) {
      return;
    }

    if (!this.inputtype) {
      this.inputtype = '';
    }
    this.givenListType = this.inputtype.replace(/ /g, '').split(',');
    if (this.includesArrayValue(this.listOfTextType, this.givenListType)) {
      this.InputTextType(event);
    }
    if (this.inputtype === InputTypeNumbers.PREMAXDECIMAL) {
      const splitedValue = event.target.value.trim().split(this.DecimalSeperator);
      const afterDecimal = splitedValue[1] ? splitedValue[1] : '';
      if (splitedValue[0].length <= this.maxpredecimalValue) {
        const combinedValue =
          splitedValue.length > 1 ? `${splitedValue[0]}${this.DecimalSeperator}${afterDecimal.slice(0, 2)}` : splitedValue[0];
        this.control.control.setValue(combinedValue);
      } else {
        const combinedValue =
          splitedValue.length > 1 ?
            `${splitedValue[0].slice(0, this.maxpredecimalValue)}${this.DecimalSeperator}${afterDecimal.slice(0, 2)}` :
            splitedValue[0].slice(0, this.maxpredecimalValue);
        this.control.control.setValue(combinedValue);
      }
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.skipInputValidation) {
      return;
    }
    const e = event as KeyboardEvent;
    if (!this.inputtype) {
      this.inputtype = '';
    }


    this.givenListType = this.inputtype.replace(/ /g, '').split(',');
    if (this.includesArrayValue(this.listOfNumberTypes, this.givenListType)) {
      this.InputTypeNumberValidation(e);
    }
    if (this.includesArrayValue(this.listOfTextType, this.givenListType)) {
      this.InputTextType(e);
    }

    if (this.givenListType.includes(InputTypeNumbers.ROUNDOFF)) {
      this.RestrictRoundoff2(e);
    }
  }

  OnBlurFunction(e: KeyboardEvent) {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value ? input.value : '';


    if (this.givenListType.includes(InputTypeNumbers.DECIMAL) || this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL) || this.givenListType.includes(InputTypeNumbers.PREMAXDECIMAL)) {
      this.addDecimalSeperator(valueEntered);
    }

    if (this.givenListType.includes(InputTypeNumbers.PERCENT)) {
      if (valueEntered == '') {
        this.control.control.setValue('0.00');
      }
      if (parseFloat(valueEntered) > 100) {
        this.control.control.setValue('0.00');
      }
    }

    if (this.givenListType.includes(InputTypeText.EMAIL)) {
      if (!this.validateEmail(valueEntered)) {
        if (valueEntered.trim() != '') {
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }

    if (this.givenListType.includes(InputTypeNumbers.PREMAXDECIMAL) || this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL)) {
      if (!valueEntered.includes(this.DecimalSeperator)) {
        let preDecimalValue = valueEntered.trim();
        this.maxrestrictor(preDecimalValue, this.maxpredecimalValue)

      } else {
        let afterSplited = valueEntered.trim().split(this.DecimalSeperator);
        this.maxrestrictor(afterSplited[0], this.maxpredecimalValue, afterSplited[1])

        const splitVal = valueEntered.split(this.DecimalSeperator);
        if (splitVal[1].length > 2) {
          let value: any = this.localization.currencyToSQLFormat(valueEntered);
          value = value.bankersToFixed(2);
          this.control.control.setValue(value);
          valueEntered = this.control.control.value;
        } else if (splitVal[1].length < 2) {
          const value = splitVal[1].length == 0 ? '00' : splitVal[1] + '0';
          const roundedValue = splitVal[0] + this.DecimalSeperator + value;
          this.control.control.setValue(roundedValue);
          valueEntered = this.control.control.value;
        }
      }
    }

    this.regexValidator(valueEntered);

  }
  maxrestrictor(valueEntered, maxval, afterDecimalValue?) {
    if (valueEntered.length > maxval) {
      valueEntered = valueEntered.slice(0, maxval);
    }
    const afterValue = afterDecimalValue ? afterDecimalValue : '00';
    this.control.control.setValue(valueEntered == '' ? '' : valueEntered.toString() + this.DecimalSeperator + afterValue);
    valueEntered = this.control.control.value;
  }

  regexValidator(valueEntered) {

    if (valueEntered.trim() != '') {

      if (this.givenListType.includes(InputTypeNumbers.NUMBERS) && !this.givenListType.includes(InputTypeNumbers.DECIMAL)) {
        if (!this.ValidateNumbers(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE) && !this.givenListType.includes(InputTypeNumbers.DECIMAL)) {
        if (!this.ValidateNonNegative(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL)) {
        if (!this.ValidatePositiveDecimal(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMALORNUMERIC)) {
        valueEntered = valueEntered.indexOf(this.DecimalSeperator) !== -1 ? parseFloat(valueEntered).bankersToFixed(2) : valueEntered;
        this.control.control.setValue(valueEntered);
        if (!this.ValidatePositiveDecimalNumbers(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      } else if (this.givenListType.includes(InputTypeNumbers.NUMBERWITHSEPARATOR)) {
        const lastChar = valueEntered[valueEntered.length - 1];
        const firstChar = valueEntered[0];
        if (lastChar === this.CommaSeperator) {
          valueEntered = valueEntered.slice(0, -1);
          this.control.control.setValue(valueEntered);
        }
        if (firstChar === this.CommaSeperator) {
          valueEntered = valueEntered.substr(1);
          this.control.control.setValue(valueEntered);
        }
        if (!this.validateCommaSeparatedPattern(valueEntered)) {
          this.control.control.setValue('');
          this.control.control.setErrors({ incorrect: true });
        }
      }
    }
  }

  ValidatePositiveDecimalNumbers(text) {
    if (this.ValidatePositiveDecimal(text)) {
      return true;
    } else {
      return this.ValidateNonNegative(text);
    }
  }


  ValidatePositiveDecimal(text) {
    const regx = new RegExp('^\\d+(' + this.DecimalSeperator + '\\d{2})$');
    return regx.test(String(text).toLowerCase());
  }

  ValidateNonNegative(text) {
    const regx = /^\d+$/;
    return regx.test(String(text).toLowerCase());
  }

  ValidateNumbers(text) {
    const reg = /^-?[\d]+$/;
    return reg.test(String(text).toLowerCase());
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateWebsite(website) {
    const re = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return re.test(String(website).toLowerCase());
  }

  validateCommaSeparatedPattern(text) {
    const reg = /^[\d]+(,[\d]+)*$/;
    return reg.test(String(text).toLowerCase());
  }

  InputTypeNumberValidation(e: KeyboardEvent): void {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : '';
    const lastChar = valueEntered[valueEntered.length - 1];
    if (
      // Allow function keys
      (e.keyCode >= 112 && e.keyCode <= 123) ||
      //allow minus - onlynumber,only negative
      (input.selectionStart == 0 && (this.givenListType.includes(InputTypeNumbers.NUMBERS) || this.givenListType.includes(InputTypeNumbers.ONLYNEGATIVE)) &&
        e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length)) ||
      // Decimal Seperator - Region
      (!this.givenListType.includes(InputTypeNumbers.NODECIMAL) && valueEntered.indexOf(this.DecimalSeperator) == -1 && this.DecimalSeperator == e.key) ||
      (this.givenListType.includes(InputTypeNumbers.NUMBERWITHSEPARATOR) && this.CommaSeperator == e.key && lastChar != this.CommaSeperator) ||
      //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 40)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) ||
      //non negative , includes zero
      (this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE) && e.key == this.negativeSign)
    ) {
      e.preventDefault();
    }

  }

  omit_special_char(e) {
    // Allow function keys
    return (e.keyCode >= 112 && e.keyCode <= 123) ||
      //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      //Allow: Space
      (e.keyCode === 32) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39) ||
      !(/[^a-zA-Z0-9]/.test(e.key));
  }

  RestrictRoundoff2(e: KeyboardEvent) {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : '';
    if (valueEntered.indexOf(this.DecimalSeperator) >= 0) {
      const arr = valueEntered.split(this.DecimalSeperator);
      if (
        // Allow function keys
        (e.keyCode >= 112 && e.keyCode <= 123) ||
        //allow minus - onlynumber,only negative
        (input.selectionStart < 1 && (this.givenListType.includes(InputTypeNumbers.NUMBERS) || this.givenListType.includes(InputTypeNumbers.ONLYNEGATIVE)) &&
          e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length)) ||
        // Decimal Seperator - Region
        (!this.givenListType.includes(InputTypeNumbers.NODECIMAL) && valueEntered.indexOf(this.DecimalSeperator) == -1 && this.DecimalSeperator == e.key) ||
        //Allow default options like delete..
        [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39) || (input.selectionEnd > input.selectionStart) ||
        //allow input before decimal seperator
        (valueEntered.indexOf(this.DecimalSeperator) >= input.selectionStart)) {
        // let it happen, don't do anything
        return;
      }
      if (arr[1].length > 1) {
        e.preventDefault();
      }
    }
  }

  InputTextType(e: KeyboardEvent): void {
    if (this.givenListType.includes(InputTypeText.NOSPL)) {
      if (!this.omit_special_char(e)) {
        if (this.showErrPopup) {
          this.utils.showError(this.localization.captions.common.Specialcharactersarenotallowed);
        }
        e.preventDefault();
        return;
      }
    }

    if (this.givenListType.includes(InputTypeText.TEXT)) {
      if (Number(e.key) > 0) {
        e.preventDefault();
        return;
      }
    }

    if (this.givenListType.includes(InputTypeText.NOSPACE)) {
      if (e.which === 32) {
        e.preventDefault();
      }
    }
    if (this.givenListType.includes(InputTypeText.FIRST_CHAR_CAP)) {
      let value = this.element.nativeElement.value;
      value = value ? value : '';
      if (value.length > 0) {
        value = `${value[0].toUpperCase()}${value.slice(1, value.length)}`;
      }
      this.control.control.setValue(value);
    }
    if (this.givenListType.includes(InputTypeText.NOPRESPACE)) {
      let value = this.element.nativeElement.value;
      value = value ? value : '';
      if (e.which === 32 && value.trim().length === 0) {
        this.control.control.setValue(value.trim());
        e.preventDefault();
      }
    }
    if(this.givenListType.includes(InputTypeText.RESTRICTSCRIPT)){
      let value = this.element.nativeElement.value;
      value = value ? value : "";
      value = value.replace('&lt','');
      value = value.replace('&gt','');
      this.control.control.setValue(value);
      const forbiddenKeys = ['<', '>'];
      if (forbiddenKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
  }

  capitalizeblur(_e: KeyboardEvent) {
    if (this.givenListType.includes(InputTypeText.CAP)) {
      let value = this.element.nativeElement.value;
      value = value ? value.toUpperCase() : '';
      this.control.control.setValue(value.toUpperCase());
    }
  }

  addDecimalSeperator(valueEntered) {
    if (!valueEntered) {
      return;
    }
    if (!valueEntered.includes(this.DecimalSeperator)) {
      this.control.control.setValue(valueEntered.trim() == '' ? '' : valueEntered.toString() + this.DecimalSeperator + '00');
      valueEntered = this.control.control.value;
    } else {
      const splitVal = valueEntered.split(this.DecimalSeperator);
      if (splitVal[1].length > 2) {
        let value: any = this.localization.currencyToSQLFormat(valueEntered);
        value = value.bankersToFixed(2);
        const localizedCurrency = this.localization.localizeCurrency(value, false);
        this.control.control.setValue(localizedCurrency);
        valueEntered = this.control.control.value;
      } else if (splitVal[1].length < 2) {
        const value = splitVal[1].length == 0 ? '00' : splitVal[1] + '0';
        const roundedValue = splitVal[0] + this.DecimalSeperator + value;
        this.control.control.setValue(roundedValue);
        valueEntered = this.control.control.value;
      }
    }
  }
}
