export interface SignalRMessage<T>
 {
  content: T;
  name: string;
}
export interface NotificationModel
{
  title:string;
  discription:string;
  notificationObjectString:string;
  notificationType:SignalRNotificationType;
  productId: number; //To handle same property different Product

}

export interface SystemDateChangeMessage {
  message: string;
  isSuccess: boolean;
}  
export enum SignalRNotificationType 
{
  BackGroundActivity = 1,
  NotificationIcon,
  ToasterNotification
}
