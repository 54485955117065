import { Injectable } from "@angular/core";
import { SpaLocalization } from "../../core/localization/spa-localization";
import * as moment from "moment";
import { ScheduleData, TherapistSchedule, TherapistScheduleEditModel } from "../../shared/business/view-settings.modals";
import { SpaUtilities } from "../../shared/utilities/spa-utilities";
import { BreakPointAccess } from "src/app/shared/service/breakpoint.service";
import { Host, SPAManagementBreakPoint } from "src/app/shared/globalsContant";
import { SettingsService } from "../settings.service";
import { HttpMethod } from "src/app/shared/service/http-call.service";
import { MatDialog } from "@angular/material/dialog";
import { SettingDialogPopupComponent } from "../setting-dialog-popup/setting-dialog-popup.component";
import { Subscription, Subject } from "rxjs";
import _ from "lodash";
import { SnackBarType } from "src/app/common/shared/shared/enums/enums";

 

@Injectable()
export class StaffScheduleService {
    newSchedule = false;
    Days: string[] = [];
    showBreakTime = false;
    captions: any;
    dialogSubscription: Subscription;
    ScheduleData: any[];
    staffScheduleUpdateNotifier$ = new Subject<any>();
    isInstructor=false;
    constructor(private localization: SpaLocalization, private utils: SpaUtilities,public breakPoint: BreakPointAccess,
        public ss: SettingsService,public dialog: MatDialog) {
        this.Days = this.formDaysObjs();
        this.captions = this.localization.captions.staffSchedule;
    }


    // Generate Week Array starts
    WeekArray(strt, end) {
      strt = moment(strt);
      end = moment(end);
        const aweek = [];
        let row1, row2, nxtday;
        const noOfDayDisp = end.diff(strt, "days");
        for (let i = 0; i <= noOfDayDisp; i++) {
            if (i == 0) {
                row1 = this.localization.convertMomentDateToSQLDate(strt);
                row2 = strt.format("dddd");
                aweek.push({ row1: row1, row2: row2, year: strt.format("YYYY") });
            } else {
                nxtday = strt.add(1, "day");
                row1 = this.localization.convertMomentDateToSQLDate(nxtday);
                row2 = nxtday.format("dddd");
                aweek.push({ row1: row1, row2: row2, year: strt.format("YYYY") });
            }
        }
        return aweek;
    }

    formScheduleData(): any[] {
        const arrWeeks = moment.weekdays();
        const arrWeeksShrt = moment.weekdaysShort();
        const dataArr = [];
        let scheduleObj: ScheduleData;
        for (let i = 1; i <= arrWeeks.length; i++) {
            const day = arrWeeks[i - 1];
            const sDay = arrWeeksShrt[i - 1];
            scheduleObj = {
                id: i,
                day: day,
                startTime: "",
                endTime: "",
                breakTime: "",
                locationId: 0,
                breakTypeId:0,
                onCall: false,
                Off: false,
                paid: false,
                unPaid: false,
                shortDay: sDay,
                disabled: false,
                comments: ""
            };
            dataArr.push(scheduleObj);
        }
        return dataArr;
    }

    private formDaysObjs(): string[] {
        const arrOfDays = this.utils.getShortDaysOfWeek();
        const daysObj = [];
        for (let i = 0; i < arrOfDays.length; i++) {
            const element = arrOfDays[i];
            daysObj.push({ id: i + 1, code: element });
        }
        return daysObj;
    }

    IsValidTime(fromTime, toTime): boolean {
        if (fromTime == "" && toTime == "") { return true; }
        if ((fromTime != "" && toTime == "") || (fromTime == "" && toTime != "")) {
            return false;
        }        
        if (this.localization.TimeToDate(toTime).getTime() <= this.localization.TimeToDate(fromTime).getTime()) {
            return false;
        }
        return true;
    }

    IsValidTimeForBreak(fromTime, toTime): boolean {
        if (fromTime == "" && toTime == "") { return true; }
        if ((fromTime != "" && toTime == "") || (fromTime == "" && toTime != "")) {
            return false;
        }        
        if (this.localization.TimeToDate(toTime).getTime() < this.localization.TimeToDate(fromTime).getTime()) {
            return false;
        }
        return true;
    }

    FormatScheduleTime(therapistSchedule, locationArr) {
        if (therapistSchedule != null && therapistSchedule.length > 0) {
            therapistSchedule.forEach(s => {
                const availableTime = s.availableTime && s.availableTime.length > 0 ? s.availableTime[0] : {};
                const locationElement = locationArr.find(x => x.id === availableTime['locationId']);
                availableTime['fromTime'] = availableTime['fromTime'] ? this.localization.LocalizeTime(availableTime['fromTime']) : "";
                availableTime['toTime'] = availableTime['toTime'] ? this.localization.LocalizeTime(availableTime['toTime']) : "";
                availableTime['locationDescription'] = locationElement ? locationElement.description : '';
                s.availableTime = availableTime;
                s.scheduleDate = this.localization.LocalizeDate(s.scheduleDate);
            });
        }
    }

    CreateDateArray(strt, end) {
        strt = this.utils.getDate(this.utils.formatDate(this.utils.getDate(strt)));
        end = this.utils.getDate(this.utils.formatDate(this.utils.getDate(end)));
        const aweek: any = [];
        let row1, row2;

        while (strt <= end) {
            row1 = this.utils.formatDate(this.utils.getDate(strt));
            row2 = moment(strt).format("dddd");
            const _shortDay = moment(strt).format("ddd");

            aweek.push({ row1: row1, row2: row2, shortDay: _shortDay });
            strt = this.utils.getDate(
                moment(strt)
                    .add(1, "days")
                    .format()
            );
        }

        aweek.forEach(aw => {
            this.Days.forEach(day => {
                if (aw.shortDay === day['code']) {
                    aw['idOfDay'] = day['id'];
                }
            });
        });
        return aweek;
    }

    editStaffScheduleSelectedData(data:any){
        let inputData = [];
        if (data.availableTime.length > 1) {
          for (var i = 0; i < data.availableTime.length; i++) {
            inputData.push({
              availableOnCall: data.availableTime[i].availableOnCall,
              createdAt: data.availableTime[i].createdAt,
              id: data.availableTime[i].id,
              locationId: data.availableTime[i].locationId,
              lunchId: data.availableTime[i].lunchId,
              therapistScheduleId: data.availableTime[i].therapistScheduleId,
              fromTime: data.availableTime[i].fromTime,
              toTime: data.availableTime[i].toTime,
              time: `${this.localization.LocalizeTime(
                this.utils.getDate(data.availableTime[i].fromTime)
              )}-${this.localization.LocalizeTime(
                this.utils.getDate(data.availableTime[i].toTime)
              )}`,
              checked: i === 0 ? true : false,
            });
          }

          let dialogRef = this.dialog.open(SettingDialogPopupComponent, {
            height: "35%",
            width: "450px",
            data: {
              headername: this.captions.EditSchedule,
              closebool: true,
              templatename: "SSE",
              datarecord: { data: inputData },
            },
            panelClass: "small-popup",
            disableClose: true,
            hasBackdrop: true,
          });
    
          this.dialogSubscription = dialogRef.afterClosed().subscribe((res) => {
            if (res) {
              this.staffScheduleUpdateNotifier$.next({
                msg:'Modified',
                type: editStaffSchedule.edit,
                id: res.id,
                returnData: data,
              });
            }else{
              this.staffScheduleUpdateNotifier$.next({ msg: 'Cancel', type: editStaffSchedule.cancel,therapistId: 0 });
            }
          });
         }
       }

       async  deleteTherapistSchedule(therapistId: number, body) {
        var keyValue = { key: "isInstructorSchedule", value:  [this.isInstructor]};
        let apiResponse = await this.ss.InvokeServiceCallAsync<number>(
          "DeleteTherapistSchedule",
          Host.schedule,
          HttpMethod.Delete,
          { therapistId: therapistId },
          body,
          keyValue,
        );
        if (apiResponse > 0) {
          let errorText = this.localization.getError(apiResponse);
          this.utils.ShowErrorMessage(
            this.localization.captions.common.Information,
            errorText
          );
        }else{
          // this.utils.ShowErrorMessage(
          //   this.localization.captions.common.Information,
          //   this.captions.deletedSuccessMessage
          // );
          let notificationMsg = this.captions.deletedSuccessMessage
          this.utils.showToastMessage(notificationMsg, SnackBarType.Success, 5000, 'center', 'top', 'icon-requisition-finalized-eatec', 345);
          this.staffScheduleUpdateNotifier$.next({ type: editStaffSchedule.deleted, msg: 'Deleted', therapistId: therapistId });
        }
      }

      async DeleteStaffSchedule(data, therapistId: number)   {
        const breakpointNumber = [SPAManagementBreakPoint.DeleteStaffSchedule];
        const isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
        if (!isUserAuthorized) {
          return;
        }
        if (!data || !data.scheduleTime || data.scheduleTime.length == 0) {
          return;
        }
        let params =this.isInstructor?{
          FromDate: this.localization.convertDateObjToAPIdate(data.scheduleTime[0].fromTime),
          ToDate: this.localization.convertDateObjToAPIdate(data.scheduleTime[0].fromTime),
          instructorId: therapistId
        }
        : {
          FromDate: this.localization.convertDateObjToAPIdate(data.scheduleTime[0].fromTime),
          ToDate: this.localization.convertDateObjToAPIdate(data.scheduleTime[0].fromTime),
          therapistId: therapistId
        };
        let apiResponse: any[] = await this.ss.InvokeServiceCallAsync<any[]>(this.isInstructor?'GetInstructorClasses':'GetTherapistAppointments', Host.schedule, HttpMethod.Get, params);
        let inputData = [];
        let isAppointmentExists = false;
        for (var schedule of data.scheduleTime) {
          isAppointmentExists = this.isAppointmentExists(apiResponse, this.utils.getDate(schedule.fromTime), this.utils.getDate(schedule.toTime));
          inputData.push({
            fromTime: schedule.fromTime,
            toTime: schedule.toTime,
            time: `${this.localization.LocalizeTime(this.utils.getDate(schedule.fromTime))}-${this.localization.LocalizeTime(this.utils.getDate(schedule.toTime))}`,
            checked: !isAppointmentExists,
            disabled: isAppointmentExists
          });
        }
        // Sorting based on schedule start time.
        inputData = _.sortBy(inputData, ['fromTime']);
        let dialogRef = this.dialog.open(SettingDialogPopupComponent, {
          height: '35%',
          width: '450px',
          data: { headername: this.captions.DELETESTAFFSCHEDULE, closebool: true, templatename: 'SSD', datarecord: { data: inputData } },
          panelClass: 'small-popup',
          disableClose: true,
          hasBackdrop: true
        });
    
        this.dialogSubscription = dialogRef.afterClosed().subscribe((res) => {
          if (res && res.length > 0) {
            let body = [];
            res.forEach(s => {
              body.push({
                fromTime: s.fromTime,
                toTime: s.toTime,
              })
            });
            let request : any = {
              schedules : body,
              isBulkDelete : false
            }
            this.deleteTherapistSchedule(therapistId,request);
          }
        });
      }

      async DeleteBulkSchedule() { 
        const breakpointNumber = [SPAManagementBreakPoint.DeleteStaffSchedule];
        const isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
        if (!isUserAuthorized) {
          return;
        }

        let dialogRef = this.dialog.open(SettingDialogPopupComponent, {
          height: '50%',
          width: '530px',
          data: { headername: this.captions.DELETESTAFFSCHEDULE, closebool: true, templatename: 'SSBD',datarecord: this.isInstructor },
          panelClass: 'small-popup',
          disableClose: true,
          hasBackdrop: true
        });

        this.dialogSubscription = dialogRef.afterClosed().subscribe((res) => {
          this.staffScheduleUpdateNotifier$.next({ type: editStaffSchedule.deleted, msg: 'Deleted', therapistId: 0 });
        })
      }

       CreateTherapistSchedule(data: { therapaistId: any, startDate: any, endDate: any, startTime: string, endTime: string, locationId: number,comments?: any } ): Promise<boolean> {
        let allSchedules: TherapistSchedule[] = [];
        let therapist: TherapistSchedule;
        let createDateArray : any[] = this.CreateDateArray( _.cloneDeep(data.startDate), _.cloneDeep(data.endDate) );
          for (let i = 0; i < createDateArray.length; i++) {
            let scheduleDay = createDateArray[i].row2;
            let scheduleData: any = this.formScheduleData().find((s) => { return s.day == scheduleDay;});
            if (scheduleData != null) {
              if (!scheduleData.Off) {
                if (data.startTime && data.startTime.length > 0 && data.endTime &&  data.endTime.length > 0 ) {
                  const startTime = `${createDateArray[i].row1} ${moment( data.startTime, this.localization.timeFormat ).format("hh:mm a")}`;
                  const endTime = `${createDateArray[i].row1} ${moment( data.endTime, this.localization.timeFormat ).format("hh:mm a")}`;
                  therapist = {
                    therapistId: data.therapaistId,
                    scheduleDate: createDateArray[i].row1,
                    isOff: scheduleData.Off,
                    
                    availableTime: [
                      {
                        id: 0,  fromTime: startTime, toTime: endTime, locationId: data.locationId,
                        availableOnCall: scheduleData.onCall
                      },
                    ],
                    comments: scheduleData.comments
                  };
                  allSchedules.push(therapist);
                }
              } else {
                therapist = {
                  therapistId: data.therapaistId, scheduleDate: createDateArray[i].row1, isOff: scheduleData.Off, availableTime: null,comments: scheduleData.comments
                };
                allSchedules.push(therapist);
              }
            }
          }
          return this.editTherapistSchedule( this.MapToApiModel(allSchedules[0], data.startTime, data.endTime));
      }
  
      private async editTherapistSchedule(body: TherapistScheduleEditModel): Promise<boolean>{
        var keyValue = { key: "isInstructorSchedule", value:  [this.isInstructor]};
        let apiResponse = await this.ss.InvokeServiceCallAsync<number>(
          "EditTherapistSchedule",Host.schedule, HttpMethod.Put,
          {
            isOverwiteExisting: body.isOff ? true : false,
          },
          body,keyValue
        );
        if (apiResponse > 0) {
          let errorText = this.localization.getError(apiResponse);
          this.utils.ShowErrorMessage(
            this.localization.captions.common.Error,
            errorText
          );
          return false;
        } 
        else{
          this.staffScheduleUpdateNotifier$.next({type: editStaffSchedule.edit,msg:'Updated',therapistId: body.therapistId});
        }
        return true;
      }

      async GetTherapistSchedule(date, therapaistId){
        let therapistSchedulesResponse = await this.ss.InvokeServiceCallAsync
        ('GetAllTherapistSchedules', Host.schedule, HttpMethod.Get, { fromDate: this.localization.convertDateObjToAPIdate(date), toDate: this.localization.convertDateObjToAPIdate(date), therapistId : therapaistId,isInstructorSchedule:this.isInstructor });
        let schedules = (therapistSchedulesResponse as any)?.map(x => {
          x.availableTime = x.availableTime.map(avail => {
            avail.fromTime = this.localization.getDate(avail.fromTime)
            avail.toTime = this.localization.getDate(avail.toTime)
            return avail;
          });
          x.breakTime = x.breakTime.map(bt => {
            bt.fromTime = this.localization.getDate(bt.fromTime)
            bt.toTime = this.localization.getDate(bt.toTime)
            return bt;
          });
          return x;
        });
        return schedules;
      }
  
      private MapToApiModel(scheduleInfo: TherapistSchedule, oldStartTime: string, oldEndTime: string ): TherapistScheduleEditModel {
        return {
          therapistId: scheduleInfo.therapistId,scheduleId: scheduleInfo.id, scheduleDate: scheduleInfo.scheduleDate, isOff: scheduleInfo.isOff,comments: scheduleInfo.comments,
          oldTime: { FromTime: oldStartTime, ToTime: oldEndTime },  availableTime: scheduleInfo.availableTime,
        } as TherapistScheduleEditModel;
      }
      
  private isAppointmentExists(appointments: any[], startTime: Date, endTime: Date): boolean {
    if (appointments == null || appointments.length == 0) {
      return false;
    }
    if (this.isInstructor) {
      return appointments.some(app => this.utils.getDate(app.startTime).getTime() >= startTime.getTime() &&
        this.utils.getDate(app.endTime).getTime() <= endTime.getTime());
    }
    else {
      return appointments.some(app => this.utils.getDate(app.appointmentDetail.startTime).getTime() >= startTime.getTime() &&
        this.utils.getDate(app.appointmentDetail.endTime).getTime() <= endTime.getTime());
    }
  }

  async updateScheduleComments(therapistScheduleId: number, comment: string): Promise<boolean> {

    let apiResponse = await this.ss.InvokeServiceCallAsync<number>(
      "UpdateTherapistScheduleComment", Host.schedule, HttpMethod.Put, {scheduleId: therapistScheduleId}, comment, null);

    if (!apiResponse) {
      let errorText = this.localization.getError(apiResponse);
      this.utils.ShowErrorMessage(
        this.localization.captions.common.Error,
        errorText
      );
      return false;
    }
    return true;
  }
}

export enum RecurringType {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    Yearly = 3
}

export enum editStaffSchedule{
    deleted,
    cancel,
    save,
    edit
}
