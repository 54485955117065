import { InputTypeText, InputTypeNumbers, CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Localization } from '../../shared/localization/Localization';
import { AgTextBoxAttributes, AgTextBoxType } from '../../Models/ag-models';

export class AgInputBehaviour {

    constructor(public localization: Localization, public _commonUtilities: CommonUtilities) {
    }

    public defaultType: AgTextBoxType = AgTextBoxType.FreeText;

    public inputTypes: AgTextBoxAttributes[] = [
        {
            type: AgTextBoxType.Code,
            maxLength: 15,
            minLength: 0,
            directives: [InputTypeText.CAP, InputTypeText.NOSPL, InputTypeText.NOSPACE],
            defaultValue: '',
            toDomMapper: (text: string) => text ? text.toUpperCase().trim() : text,
            toFormMapper: (text: string) => text ? text.toUpperCase().trim() : text,
            customClass: 'ag_w--100'
        },
        {
            type: AgTextBoxType.FreeText,
            maxLength: 100000,
            minLength: 0,
            directives: [],
            defaultValue: '',
            toDomMapper: (text: string) => text,
            toFormMapper: (text: string) => text ? text.trim() : text,
            customClass: 'ag_w--100'
        },
        {
            type: AgTextBoxType.Number,
            maxLength: 100,
            minLength: 0,
            directives: [InputTypeNumbers.NUMBERS],
            defaultValue: 0,
            toDomMapper: (text: number) => text.toString(),
            toFormMapper: (text: string) => parseInt(text),
            customClass: 'ag_w--100'
        },
        {
            type: AgTextBoxType.Currency,
            maxLength: 100,
            minLength: 0,
            directives: [],
            defaultValue: 0.00,
            toDomMapper: (text: string) => this.localization.localizeCurrency(text, false),
            toFormMapper: (text: string) => this.mapToSQLFormat(text),
            customClass: 'ag_w--100'
        },
        {
            type: AgTextBoxType.ExchangeConversion,
            maxLength: 100,
            minLength: 0,
            directives: [],
            defaultValue: 0.00,
            toDomMapper: (text: string) => this.localization.localizeCurrencyExchange(text, false, 6, true),
            toFormMapper: (text: string) => this.mapToSQLFormat(text),
            customClass: 'ag_w--100'
        },
        {
            type: AgTextBoxType.Percent,
            maxLength: 100,
            minLength: 0,
            directives: [],
            defaultValue: 0,
            toDomMapper: (text: number) => text.toString(),
            toFormMapper: (text: string) => parseFloat(text),
            customClass: 'ag_w--100'
        },
        {
            type: AgTextBoxType.NoWhiteSpace,
            maxLength: 15,
            minLength: 0,
            directives: [InputTypeText.NOSPACE],
            defaultValue: '',
            toDomMapper: (text: string) => text ? text.trim() : text,
            toFormMapper: (text: string) => text ? text.trim() : text,
            customClass: 'ag_w--100'
        },
    ]



    private mapToSQLFormat(text: string | number): string | number {

        text = text === "" ? 0.00 : text;
        const isAlreadyInSQLFormat = !(text && text.toString().includes(','));
        let sqlValue: number = 0;
        if (isAlreadyInSQLFormat) {
            sqlValue = parseFloat(text as string);
        }
        else {
            sqlValue = this.localization.currencyToSQLFormat(text as string);
        }
        return sqlValue;
    }

}
