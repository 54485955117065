import { Pipe, PipeTransform } from '@angular/core';
// import { first } from 'rxjs/operators';

@Pipe({name: 'splitTransform'})
export class SplitTransform implements PipeTransform {
  transform(text, delimeter, index, Isellipsis = false, ellipsisChar = 0): any {
    if (text) {
      const stringval = text.toString();
      const split = stringval.split(delimeter);
      let returnValue = split[index];
      if (Isellipsis) {
        returnValue = (returnValue && returnValue.length > ellipsisChar) ? (returnValue.slice(0, ellipsisChar)) + '...' : returnValue;
      }
      return returnValue;
    }
  }
}
