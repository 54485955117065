import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgDropdownConfig, AgInputFieldConfig, ButtonValue, DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EformsBusiness } from './dm-eforms.business';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertType, AlertAction, EformConfig } from 'src/app/common/Models/common.models';
import { ButtonType, ContactType, MailTypes } from '../../enums/shared-enums';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { FromTypeEnum } from '../../components/cdkvirtual/cdkvirtual.model';
import { mailTemplate } from './dm-eforms.model';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DmConfig } from 'src/app/common/Models/common.models';
import { EformEmailRequest, EformLinkMapping, GuestContactInformation } from '../data-magine-integration/data-magine-models';
import { Localization } from '../../localization/localization';
import { StayGuestOccupanyInfo } from '../../Models/stayguestoccupanyinfo.model';
import { EformConfigBusiness } from 'src/app/common/eform-config/eform-config.business';

@Component({
  selector: 'app-dm-eforms',
  templateUrl: './dm-eforms.component.html',
  styleUrls: ['./dm-eforms.component.scss'],
  providers: [EformsBusiness, EformConfigBusiness],
  encapsulation: ViewEncapsulation.None
})
export class DmEformsComponent implements OnInit {

  captions: any;
  includeInactive: boolean = false;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  selectContactInputConfig: AgDropdownConfig;
  eforms: UntypedFormGroup;
  contacts: Observable<DropdownOptions[]>;
  emailConfig: AgInputFieldConfig;
  filteredItems = [];
  searchText: any;
  unitItems = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  options: TableOptions;
  emailHeaderOptions: TableHeaderOptions[];
  emailTableContent: mailTemplate[];
  selectedtemplate: mailTemplate;
  max: number = -1;
  count: number;
  dmConfig: DmConfig;
  eformConfig: EformConfig;
  token: string = '';
  stayId: number;
  senderEmail = '';
  FromName = '';
  MailBody = '';
  MailSubject = '';
  emailValue: string;
  stayContacts: StayGuestOccupanyInfo[];
  confNo: string;
  guestId: string;
  isSaaSEform: boolean;
  eformLinks: EformLinkMapping[] = [];
  preferredLanguageId :number;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private localization: Localization, private business: EformsBusiness, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<DmEformsComponent>, private utilities: CommonUtilities) {
    this.stayId = data ? Number(data.id) : 0;
    this.confNo = data ? data.confNo : '';
    this.guestId = data ? data.guestId : '';
    this.isSaaSEform = data ? data.isSaaSEform : false;
  }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    if(this.isSaaSEform){
      this.business.getEformConfig().then(eformConfig => {
        if (eformConfig && eformConfig?.enableEform) {
          this.eformConfig = eformConfig;
          this.checkDM();
        } else {
          this.utilities.showAlert(this.captions.lbl_EformConfig_empty_msg, AlertType.Error, ButtonType.Ok, async (res) => {
          });
        }
      });
    }
    else{
      this.business.getDmConfigSession().then(dm => {
        if (dm && dm?.enableDataMagine) {
          this.dmConfig = dm;
          this.checkDM();
        } else {
          this.utilities.showAlert(this.captions.lbl_DMConfig_empty_msg, AlertType.Error, ButtonType.Ok, async (res) => {
          });
        }
      });
    }
  }

  async checkDM() {
    if(this.isSaaSEform){
      this.business.getEformToken(this.eformConfig?.authenticationURL, this.eformConfig?.userName, this.eformConfig?.password).then(res => {
        if (res && res?.Result?.access_token) {
          this.token = res?.Result?.access_token;
          this.initializeEforms();
        } else {
          this.utilities.showAlert(this.captions.lbl_EformConfig_empty_msg, AlertType.Error, ButtonType.Ok, async (res) => {
          });
        }
      });
    }
    else{
      this.business.getDMToken(this.dmConfig?.dmEformsConfig?.authURL, this.dmConfig?.dmEformsConfig?.clientId, this.dmConfig?.dmEformsConfig?.clientSecret).then(res => {
        if (res && res?.access_token) {
          this.token = res?.access_token;
          this.initializeEforms();
        } else {
          this.utilities.showAlert(this.captions.lbl_DMConfig_empty_msg, AlertType.Error, ButtonType.Ok, async (res) => {
          });
        }
      });
    }
  }

  initializeEforms() {
    this.FromName = this.localization.GetPropertyInfo('PropertyName');
    this.getCurrentUser();
    this.buildForm();
    this.pageInitialization();
    this.stayContactInfo();
    this.initialize();
    this.setActionButtons();
  }

  async stayContactInfo() {
    this.stayContacts = await this.business.getStayContactInfo(this.stayId);
    const primaryguest = this.stayContacts.filter(x => x.stayId === this.stayId)[0];
    if (primaryguest != undefined && primaryguest != null) { 
      let guestdetails = await  this.business.getGuestInformationById(primaryguest.guestInformationId);    
      this.preferredLanguageId = guestdetails?.pmsGuest?.guestPreference?.preferredLanguageId ?? 0;
    }
    this.contacts = this.business.getSelectContact1(this.stayContacts);
    const primarContactDetail = this.business.getPrimaryContact(this.stayContacts, this.stayId);
    
    this.selectContactInputConfig = this.business.selectFieldGenerator('ag_form-control--lg', this.eforms, 'selectContact', this.captions.lbl_selectContact, 'lbl_selectContact', this.contacts, primarContactDetail, false);
    this.onContactChange(primarContactDetail);
    this.generateTable();
  }

  onContactChange(primarContactDetail) {
    this.getEmailofcontactChanged(primarContactDetail);
  }

  getEmailofcontactChanged(primarContactDetail) {
    this.emailValue = primarContactDetail.value.emailId ? primarContactDetail.value.emailId : '';
    this.patchvalue();
    if (!this.eforms.value.emailAddress) {
      this.eforms.controls.emailAddress.setErrors({ required: true });
    } else {
      this.validateEmail(this.eforms.controls.emailAddress.value);
    }
  }

  validateEmail(email) {
    if (email && email.trim().length > 0) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        this.eforms.controls.emailAddress.setErrors({ incorrect: true });
      } else {
        this.eforms.markAsDirty();
      }
    }
  }


  patchvalue() {
    this.eforms.patchValue({
      emailAddress: this.emailValue,
      selectContact: this.eforms.controls.selectContact.value,
      emailTemplateId: this.eforms.controls.emailTemplateId.value,
    });
  }

  async getCurrentUser() {
    const propertyEmail = this.utilities.GetLocalStorageValue('propertyInfo', 'PropertyEmail');
    const userEmail = this.utilities.GetLocalStorageValue('propertyInfo', 'UserEmail');
    this.senderEmail = propertyEmail !== 'null' && propertyEmail != null && propertyEmail !== '' ? propertyEmail : userEmail;

  }


  close(eve) {
    this.dialogRef.close();
  }
  generateTable() {
    this.options = this.business.getTableOptions();
    this.emailHeaderOptions = this.business.EmailHeaderGenerator();
    this.business.generateTableData(1, this.includeInactive,this.preferredLanguageId).then(data => {
      if (data && data.length > 0) {
        this.emailTableContent = data;
        this.emailTableContent = [...this.emailTableContent];
        this.selectedtemplate = data.filter(x => x.checked)[0];
        this.max = this.selectedtemplate.max;
        this.eforms.controls.emailTemplateId.patchValue(this.selectedtemplate.id);
        this.patchvalue();
      }
    });
    // this.emailTableContent= this.business.getTableContent();
    // this.selectedtemplate = this.emailTableContent[0];
  }
  buildForm() {
    this.eforms = this.fb.group({
      selectContact: '',
      emailAddress: [this.emailValue, Validators.required],
      emailTemplateId: ''
    });
  }

  pageInitialization() {
    this.selectContactInputConfig = {
      form: this.eforms,
      className: 'ag_form_wrapper--xs ag-pr-8',
      formControlName: 'selectContact',
      selectOptions: this.contacts,
      placeHolderId: 'lbl_selectContact',
      showRequired: true,
      placeHolder: this.captions.lbl_selectContact,
    };
  }

  setActionButtons() {
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_sendEmail,
      disabledproperty: true
    }

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
  }

  async initialize() {
    if(this.isSaaSEform){
      this.filteredItems = await this.business.getSaaSEformsDropDownList(this.token, this.eformConfig?.systemURL, this.eformConfig?.eformsTenantId, this.eformConfig?.eformsPropertyId);
    }
    else
    {
      this.filteredItems = await this.business.getEformsDropDownList(this.token, this.dmConfig?.dmEformsConfig?.eformsListURL, this.dmConfig?.dmEformsConfig?.eformsTenantId, this.dmConfig?.dmEformsConfig?.eformsPropertyId);
    }
    
    this.unitItems = this.filteredItems;

    this.emailConfig = {
      className: 'ag_form_wrapper--xs ag-pl-8',
      form: this.eforms,
      formControlName: 'emailAddress',
      placeHolderId: 'lbl_email',
      placeHolder: this.captions.lbl_guest_email,
      inputType: 'email',
      minlength: 1,
      maxlength: 50,
      showRequired: true,
      errorMessageId: 'err_missingEmail',
      errorMessage: this.captions.err_guestemail
    };

    this.eforms.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.saveButton.disabledproperty = !(this.eforms.valid && this.eforms.dirty && this.count === this.max);
      this.saveButton = { ...this.saveButton };
    });
  }
  emailTableAction(event) {
    switch (event.fromType) {
      case FromTypeEnum.radioButton:
        this.filteredItems.forEach(x => { x.checked = false });
        this.count = 0;
        this.max = event.Obj.max;
        this.selectedtemplate = event.Obj;
        this.eforms.controls.emailTemplateId.setValue(event.Obj.id);
        break;
    }
    this.filteredItems = [...this.filteredItems];
  }
  async onSave(eve) {
    this.saveButton.disabledproperty = true;
    this.saveButton = { ...this.saveButton };
    let eformsLinkURL = this.dmConfig?.dmEformsConfig?.eformsLinkURL;
    let eligible = this.filteredItems.filter(x => x.checked == true);
    if (eligible && eligible.length > 0) {
      if(this.emailValue == '') {
        let guestContactInformation: GuestContactInformation = {
          guestId: this.guestId,
          contactDetails: [{
            name: ContactType.email,
            description: this.captions.drp_txt_personal,
            value: this.eforms.controls.emailAddress.value.toString(),
            isPrimary: true,
            isPrivate: false,
            type: MailTypes.personal
          }]
        }
        await this.business.updateGuestContactDetails([guestContactInformation]);
      }
      await Promise.all(eligible.map(async x => {
        if(this.isSaaSEform){
          let tkn = await this.business.getEformToken(this.eformConfig?.authenticationURL, this.eformConfig?.userName, this.eformConfig?.password);
          if (tkn && tkn?.Result?.access_token) {
            this.token = tkn.Result.access_token;
            let rs = await this.business.getSaaSformsLinkURL(x.id, this.token, this.eformConfig.systemURL
              , { confirmationNumber: this.confNo, stayID: this.stayId.toString(), isWebcheckIn: false });
            if (rs && rs.result) {
              this.eformLinks.push({
                link: rs.result,
                linkName: x.code
              });
            }
          }
        }
        else{
          let tkn = await this.business.getDMToken(this.dmConfig?.dmEformsConfig?.authURL, this.dmConfig?.dmEformsConfig?.clientId, this.dmConfig?.dmEformsConfig?.clientSecret);
          if (tkn && tkn?.access_token) {
            this.token = tkn?.access_token;
            let rs = await this.business.getformsLinkURL(x.id, this.token, eformsLinkURL
              , { confirmationNumber: this.confNo, stayID: this.stayId.toString(), isWebcheckIn: false });
            if (rs && rs.result) {
              this.eformLinks.push({
                link: rs.result,
                linkName: x.code
              });
            }
          }
        }
      }));
      this.setEmailRequest();
    }
  }
  setEmailRequest() {
    let emailTemplateId = this.eforms.controls.emailTemplateId.value;
    if (this.eformLinks.length > 0) {
      let emailRequest: EformEmailRequest = {
        senderEmail: this.senderEmail,
        senderName: this.localization.GetPropertyInfo('PropertyName'),
        toEmail: this.eforms.controls.emailAddress.value.toString(),
        emailTemplateId: emailTemplateId,
        linkMappings: this.eformLinks,
        mailSubject: `${this.captions.lbl_guest_eforms}${this.confNo}`,
        refId: this.stayId.toString()
      };
      //api call
      this.business.sendEmailRequest(emailRequest).then(res => {
        if (res) {
          this.utilities.showAlert('Email sent successfully', AlertType.Success, ButtonType.Ok);
          this.dialogRef.close();
        } else {
          this.saveButton.disabledproperty = false;
          this.saveButton = { ...this.saveButton };
        }
      }).catch(ex => {
        this.saveButton.disabledproperty = false;
        this.saveButton = { ...this.saveButton };
      });
      this.eformLinks = [];
    }

  }
  onCancel(eve) {
    if (this.eforms && this.eforms.dirty) {
      this.utilities.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }
  toggle(item, eve) {
    this.count = 0;
    this.filteredItems.forEach(x => {
      if (x.id == item.id) {
        if (eve) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      }
      if (x.checked) {
        this.count++;
      }
    });
    this.filteredItems = [...this.filteredItems];
    this.saveButton.disabledproperty = !(this.eforms.valid && this.eforms.dirty && this.count === this.max);
    this.saveButton = { ...this.saveButton };
  }
  searchValueChange(e) {
    this.searchText = e;
    if (e) {
      const filterValue = e.toLowerCase();
      this.filteredItems = this.unitItems.filter(x => x.code.toLowerCase().indexOf(filterValue) === 0)
    } else {
      this.filteredItems = this.unitItems;
    }
  }
  onDrop(event) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
}
