import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { BreadCrumbConfiguration } from '../transaction.interface';
import { ChangeBreadcrumbConfig } from './create-transaction-config.interface';

export const ChangeBreadCrumbNameConfig: ChangeBreadcrumbConfig =
(BreadCrumbConfig: Array<BreadCrumbConfiguration>, RouterQueryParams: any, specificName?: any, ViewModelHeaderEntity?: any): any => {
    const entityName =  ViewModelHeaderEntity.toString();
    const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
    const returnData = BreadCrumbConfig;
    returnData.forEach((x: BreadCrumbConfiguration) => {
      x.DisplayName = ProductDataRemap.FieldTranslateRemapEngine(
        productremapJson, entityName, x.DisplayName);
      x.Name = ProductDataRemap.FieldTranslateRemapEngine(
        productremapJson, entityName, x.Name);
    });
    if (BreadCrumbConfig.length === 2) {
        // if (RouterQueryParams.rowId && RouterQueryParams.transSequenceNo && (
        if (RouterQueryParams.rowId && (
        RouterQueryParams.transactionFrom === 'EditPO' || RouterQueryParams.transactionFrom === 'SavedReceiving'
        || RouterQueryParams.transactionFrom === 'EditTemplate' || RouterQueryParams.transactionFrom === 'LoadAsRequisition'
        || RouterQueryParams.salesFrom === 'EditSales' || RouterQueryParams.from === 'editpackagelimitation' 
        || RouterQueryParams.transactionFrom === 'EditPackageLimitation')) {
            if (RouterQueryParams.transactionFrom === 'SavedReceiving' && specificName) {
                returnData[1].Name = specificName;
            } else if (RouterQueryParams.transactionFrom === 'EditTemplate' || RouterQueryParams.from === 'editpackagelimitation' || RouterQueryParams.transactionFrom === 'EditPackageLimitation') {
                returnData[1].Name = returnData[1].Name.replace('New', 'Edit');
            } else if (RouterQueryParams.transactionFrom === 'LoadAsRequisition') {
                returnData[1].Name = returnData[1].Name.replace('Template', 'Requisition');
            } else if (RouterQueryParams.salesFrom === 'EditSales') {
                returnData[1].Name = entityName === 'QuickOrderHead' ? `${RouterQueryParams.rowId} - ${returnData[1].Name.replace('New', 'Edit')}` : returnData[1].Name.replace('New', 'Edit');
            } else if (BreadCrumbConfig[1].Type.toLowerCase() === 'text') {
                returnData[1].Name = RouterQueryParams.rowId;
            } 
        }
    } 
    if ( 
        returnData && 
        returnData.length > 0 && 
        returnData.some(x => x.hasOwnProperty('DynamicDisplay')) &&
         RouterQueryParams.hasOwnProperty('rowId')
         ) {
            returnData.forEach((breadcrumb: BreadCrumbConfiguration) => {
             if (breadcrumb.hasOwnProperty('DynamicDisplay') && breadcrumb.DynamicDisplay.EnabledDynamicDisplay) {
                breadcrumb.DisplayName = breadcrumb.DisplayName + `${breadcrumb.DynamicDisplay.DynmaicDisplayName}` + `${RouterQueryParams[breadcrumb.DynamicDisplay.DynamicDisplayKey]}`;
                breadcrumb.Name = breadcrumb.Name + `${breadcrumb.DynamicDisplay.DynmaicDisplayName}` + `${RouterQueryParams[breadcrumb.DynamicDisplay.DynamicDisplayKey]}`;
             }
        });    
    }

    if (RouterQueryParams.rowId && (RouterQueryParams.transactionFrom === 'OpenRequisition' || RouterQueryParams.transactionFrom === 'OpenRequests' || RouterQueryParams.master === 'Packages')
    && RouterQueryParams.transaction !== 'opentransaction') {
        returnData[1].Name = returnData[1].Name.replace('New', 'Edit');
    }
    return returnData;
};