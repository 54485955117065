import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  sessionTimeoutSeconds: number;
  idleState: string;
  sessionIdleCount = 0;
  constructor() { }
}
