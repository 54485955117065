import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { HeaderConfiguration } from '../transaction.interface';
import { ViewTransactionConfiguration } from './view-configuration.interface';
import { ViewHeaderTransaction } from './view-header';

export const ViewHeaderConfiguration: ViewTransactionConfiguration = (
ViewHeaderEntity: any, 
ViewDetailsEntity: any, 
TransactionCollection: any, 
httpService: HttpService,
translateService?: TranslateService): HeaderConfiguration => {
    try {
        if (TransactionCollection) {
            const headerConfiguration = {} as HeaderConfiguration;
            headerConfiguration.BreadCrumbConfig = ViewHeaderTransaction.HeaderBreadcrumb(
            ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService);
            headerConfiguration.ActionButtonConfig = ViewHeaderTransaction.HeaderActionButton( 
            ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService);
            return headerConfiguration; 
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};