import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { UploadMetaData, DetailsPayload } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ToastrService } from 'ngx-toastr';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';

@Component({
  selector: 'app-agilysys-attchedfiles-popup',
  templateUrl: './agilysys-attchedfiles-popup.component.html',
  styleUrls: ['./agilysys-attchedfiles-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysattchedfilesPopupComponent {
  agilysysCofirmationButton: AgilysysButtonModel[];
  fileFormats = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'video/mp4', 'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/docx'];
  fileSizeLimit = 20000000000000;
  fileSizeExceeded = [];
  files: any = [];
  fileArray: any = [];
  validFormats: Array<any> = [];
  fileDataCollection: Array<UploadMetaData> = [];
  TransactionGroupId: number;
  constructor(public dialogRef: MatDialogRef<AgilysysattchedfilesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpservice: HttpService,
    private masterManagementService: MasterManagementService,
    private router: ActivatedRoute,
    private toasterService: ToastrService) {
      this.agilysysCofirmationButton = [
        {
          displayProperity: false,
          displayName: 'Cancel',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callBackData: CallBackData) => {
              this.dialogRef.close(callBackData.buttonValue.buttonKey);
          }
        },
        {
          displayProperity: true,
          displayName: 'Confirm',
          buttonKey: 'Confirm',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
              const buttonCallbackEvent = {
                'ButtonEvent': callBackData.buttonValue.buttonKey,
                'Files': this.files,
                'FileCollection': this.fileDataCollection
              };
              this.dialogRef.close(buttonCallbackEvent);
          }
        }
      ];
     }

      // eslint-disable-next-line
  async uploadFile(event: any) {
    const IngrdientId = this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'rowId', 'rowId');
    const transactionName = this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'transaction', null);
    const FileDetails: DetailsPayload = {
      ItemId: 0,
      GroupId: 0
    };
    switch (transactionName) {
      case 'openpurchaserequest':
      case 'allpurchaserequest':
        this.TransactionGroupId = 1;
        break;
      case 'purchaseorder':
      case 'openpurchaseorder':
      case 'poopenpr':
      case 'poopenrequisition':
        this.TransactionGroupId = 2;
        break;
      case 'receiving':
      case 'savedreceiving':
        this.TransactionGroupId = 3;
        break;
      default:
        this.TransactionGroupId = 1;
        break;
    }
    FileDetails.ItemId = IngrdientId;
    FileDetails.GroupId = this.TransactionGroupId;
    if (!this.data) {
      this.data = [];
    }
    const FileUploadResult: Array<any> = await FileData.FileUpload(event, this.httpservice, FileDetails, this.data,
       this.toasterService);
    this.fileDataCollection = [...FileUploadResult];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if (this.fileFormats.includes(event[index].type)) {
        if (event[index].size <= this.fileSizeLimit) {
          const filedata = await this.readFileBinary(element);
          // const pictureObj = {};
          // eslint-disable-next-line @typescript-eslint/dot-notation
          // pictureObj['Picture'] = filedata.split(';base64,')[1];
          element['Base64Url'] = filedata.split(';base64,')[1];
          if (this.fileDataCollection.findIndex(x => x.FileName === event[index].name) !== -1 ) {
            this.files.push(element);
          }
        } else {
          this.fileSizeExceeded.push(true);
        }
      } else {
        this.validFormats.push(false);
      }
    }
    if (this.files.length > 0) {
      this.agilysysCofirmationButton[1].displayProperity = false;
    }
    if (this.validFormats.filter(x => x === false).length > 0) {
      alert('Invalid File Formats Uploaded');
      this.validFormats = [];
    }
    if (this.fileSizeExceeded.filter(x => x === true).length > 0) {
      alert('One or more file(s) has exceeded allowed upload file size (2MB).');
      this.fileSizeExceeded = [];
    }
  }

  readFileBinary(UploadedFile: any): Promise<any> {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(UploadedFile);
        fileReader.onload = (fileLoaded) => {
          return resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
  }

  deleteAttachment(index: number, file: any): void {
    this.files.splice(index, 1);
    const fileDataIndex = this.fileDataCollection.findIndex(x => x.FileName === file.name);
    if (fileDataIndex > -1) {
      this.fileDataCollection.splice(fileDataIndex, 1);
    }
    if (this.files.length === 0) {
      this.agilysysCofirmationButton[1].displayProperity = true;
    }
  }
  
}
