import { TenantManagementCommunication } from '../../communication/services/tenantmanagement-communication-service';
import { Injectable } from '@angular/core';
import { CustomizableDashboardAPIRoutes } from '../../common-route';
import { CustomizableDashboardDetails } from '../../components/dashboard-jasper-config/dashboard-jasper-config.model';

@Injectable({
    providedIn: 'root'
})

export class CustomizableDashboardDataService{
    constructor(private _authenticationCommunication: TenantManagementCommunication) {
    }

    public async getCustomizableDashboardDetails(userId, propertyId, productId): Promise<CustomizableDashboardDetails[]> {
        return this._authenticationCommunication.getPromise<CustomizableDashboardDetails[]>(
            {
                route: CustomizableDashboardAPIRoutes.GetCustomizableDashboardDetailsByUserIdPropertyId,
                uriParams: { 
                    userId: userId,
                    propertyId: propertyId,
                    productId: productId }
            }, false);
    }

    public async getCustomizableDashboardDetailsByPropertyId(propertyId, productId): Promise<CustomizableDashboardDetails[]> {
        return this._authenticationCommunication.getPromise<CustomizableDashboardDetails[]>(
            {
                route: CustomizableDashboardAPIRoutes.GetCustomizableDashboardDetailsByPropertyId,
                uriParams: {
                    propertyId: propertyId,
                    productId: productId }
            }, false, true);
    }

    UpdateCustomizableDashboardDetails(customizableDashboard: CustomizableDashboardDetails[]): Promise<CustomizableDashboardDetails[]> {
        return this._authenticationCommunication.putPromise({
            route: CustomizableDashboardAPIRoutes.UpdateCustomizableDashboardDetails,
            body: customizableDashboard
        }, false, true);
    } 
}