
import { ActionTypes, EUserLogin, LoginAction } from 'src/app/eatecui/source/store/actions/login.action';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { initialLoginState } from 'src/app/eatecui/source/store/states/login-state';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import * as CryptoJS from 'crypto-js';

export function LoginReducer(state = initialLoginState, action: LoginAction) {
    switch (action.type) {
        case EUserLogin.GetUserLogin: {
        return{
            ...state,
            GetUserLogin : action.payload
          };
        }
        case EUserLogin.GetUserLoginSuccess: {
            return {
              ...state,
              GetUserLoginSuccess : action.payload
            };
          }
        case EUserLogin.GetUserLoginFailure: {
          return {
            ...state,
            GetUserLoginFailure : action.payload
          };
        }
        case EUserLogin.GetClientId: {
          return{
              ...state,
              GetClientId : action.payload
            };
          }
        case EUserLogin.GetClientIdSuccess: {
            return {
              ...state,
              GetClientIdSuccess : action.payload
            };
          }
        case EUserLogin.GetClientIdFailure: {
          return {
            ...state,
            GetClientIdFailure : action.payload
          };
        }
        case EUserLogin.GetToken: {
          return{
              ...state,
              GetToken : action.payload
            };
          }
        case EUserLogin.GetTokenSuccess: {
            return {
              ...state,
              GetTokenSuccess : action.payload
            };
          }
        case EUserLogin.GetTokenFailure: {
          return {
            ...state,
            GetTokenFailure : action.payload
          };
        }
        case EUserLogin.GetSSOClientId: {
          return{
              ...state,
              GetSSOClientId : action.payload
            };
          }
        case EUserLogin.GetSSOClientIdSuccess: {
            return {
              ...state,
              GetSSOClientIdSuccess : action.payload
            };
          }
        case EUserLogin.GetSSOClientIdFailure: {
          return {
            ...state,
            GetSSOClientIdFailure : action.payload
          };
        }
        case EUserLogin.GetSSOToken: {
          return{
              ...state,
              GetSSOToken : action.payload
            };
          }
        case EUserLogin.GetSSOTokenSuccess: {
            return {
              ...state,
              GetSSOTokenSuccess : action.payload
            };
          }
        case EUserLogin.GetSSOTokenFailure: {
          return {
            ...state,
            GetSSOTokenFailure : action.payload
          };
        }
        default:
       return state;
    }
}

/**Logout clearstate */
export function clearState(reducer: any) {
  return function (state: any, action: any) {
    if (action.type === ActionTypes.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
