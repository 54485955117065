import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[isSpaEllipsis]'
})
export class IsSpaEllipsisDirective implements AfterViewInit {
  @Input() isSpaEllipsis: any;
  @Input() isSpaEllipsisEnable: any = true;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngAfterViewInit(): void {
    let enableEllipsis = false;
    if (this.isSpaEllipsisEnable) {
      setTimeout(() => {
        const element = this.elementRef.nativeElement;
        enableEllipsis = element.offsetWidth < element.scrollWidth ? true : false;
        if (enableEllipsis) {
          this.renderer.setAttribute(this.elementRef.nativeElement, 'title', this.isSpaEllipsis);
        }
      }, 1);
    }
  }
}
