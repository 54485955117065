import { Pipe, PipeTransform } from '@angular/core';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(private _localization: SpaLocalization) {
  }

  transform(value: Date | string): string {
    return this._localization.localizeDisplayDate(value, false);
  }

}
