import { Injectable } from '@angular/core';
import { CoreFacadeService } from 'src/app/eatecui/source/core/services/core-facade.service';
import { StorageService } from './storage.service';
import * as constants from '../constant/GlobalConstants';
import { RouteNavigateService } from './route-navigate.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
    token: string;
    onPrem: boolean;
    tenant: string;
    authClientBool: boolean;
    Basic: string;
    integrationToken: string;

    constructor(private coreFacadeService: CoreFacadeService) {
      this.authClientBool = false;
      this.coreFacadeService.GetAccessToken().subscribe((x) => {
        this.token = x;
      });
      this.coreFacadeService.GetOnPrem().subscribe((x) => {
        if ( x !== null) {
          this.onPrem = x;
        }
      });
      this.coreFacadeService.GetTenant().subscribe((x) => {
        if (x !== null) {
          this.tenant = x;
        }
      });
     }

    reSetSate() {
      const getSessionLogged = StorageService.GetSessionStorage(constants.IsloggedIn);
      const getSessionTenant = StorageService.GetSessionStorage(constants.IsloggedIn);
      const getSessionOnprem = sessionStorage.getItem(constants.OnPrem);
      const getSessionAccessToken = StorageService.GetSessionStorage(constants.AccessToken);
      this.coreFacadeService.dispatchLoggedIn(JSON.parse(getSessionLogged));
      this.coreFacadeService.dispatchTenant(getSessionTenant);
      this.coreFacadeService.dispatchAccessToken(getSessionAccessToken);
      const onPremValue = ( getSessionOnprem && getSessionOnprem !== '' ) ? JSON.parse(getSessionOnprem) : null;
      this.coreFacadeService.dispatchOnPrem(onPremValue);
    }  

    InitializeStore() {
        const AccessToken = StorageService.GetSessionStorage(constants.AccessToken);
        this.coreFacadeService.dispatchAccessToken(AccessToken);
        const Tenant = StorageService.GetSessionStorage(constants.Tenant);
        this.coreFacadeService.dispatchTenant(Tenant);
    }
}