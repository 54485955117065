import { GridFieldName } from './column-field-name.interface';
import { GridCustomSort } from './column-custom-sort.interface';
import { GridHeaderName } from './column-header-name.interface';
import { GridHideProperty } from './column-hide-property.interface';
import { GridSortOrder } from './column-sort-order.interface';
import { GridSortable } from './column-sortable.interface';
import { GridSortDirection } from './column-sort-direction.interface';
import { GridSuppressMovable } from './column-suppress-movable.interface';
import { GridType } from './column-type.interface';
import { GridValueFormatter } from './column-formatter.interface';
import { GridColumnCellRenderer } from './column-cell-renderer.interface';
import { GridColumnResizable } from './column-resize.interface';

export const GridColumnModels = {
  HeaderName: GridHeaderName,
  Field: GridFieldName,
  Hide: GridHideProperty,
  SortOder: GridSortOrder,
  Sortable: GridSortable,
  SuppressMovable: GridSuppressMovable,
  CustomSort: GridCustomSort,
  SortDirection: GridSortDirection,
  Type: GridType,
  ValueFormatter: GridValueFormatter,
  CellRenderer: GridColumnCellRenderer,
  ColumnResizable: GridColumnResizable
};
