import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({name: 'transactionBaseurl'})
export class TransConvertBaseSixtyFour implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer ) {}
  transform(value: any, transForm: any): string {
    const base64 = value.split(',')[0];
    return (value.hasOwnProperty('changingThisBreaksApplicationSecurity') || base64 === 'data:image/png;base64') ?
    value : this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${value}`);
  }
}