import { Component, Inject, OnInit, Pipe, PipeTransform } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable, ReplaySubject, Subscription, timer } from "rxjs";
import { scan, takeWhile } from "rxjs/operators";
import { Localization } from "src/app/common/localization/localization";
import { AutologoffTimerService } from "../autologoff-timer-service";

@Component({
  selector: "app-alert-popup-with-timer",
  templateUrl: "./alert-popup-with-timer.component.html",
  styleUrls: ["./alert-popup-with-timer.component.scss"],
})
export class AlertPopupWithTimerComponent implements OnInit {
  captions: any;
  logOutWaitingtime: number = 2; //minutes
  timer$: Observable<number>;
  subject = new ReplaySubject<number>(this.logOutWaitingtime); //2 minutes for logout
  _subscription: Subscription;
  isButtonDisabled: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AlertPopupWithTimerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Localization: Localization,
    private autoLogOfftimer: AutologoffTimerService
  ) {
    this.captions = this._Localization.captions;
  }

  ngOnInit(): void {
    this.autoLogOfftimer.TriggerTimer(this.logOutWaitingtime * 60); //give input as seconds
    this.timer$ = this.autoLogOfftimer.getTimer();
    this.autoLogOfftimer.buttonDisabled.subscribe((x) => {
      if (x) {
        this.isButtonDisabled = true;
        this.autoLogOfftimer.buttonDisabled.next(false);
      }
    });
  }

  continue() {
    this.dialogRef.close({ from: "Continue" });
    this.autoLogOfftimer.clearTimer();
  }

  logout() {
    this.dialogRef.close({ from: "logout" });
    this.autoLogOfftimer.clearTimer();
  }
}

@Pipe({
  name: "UITime",
})
export class UITimePipe implements PipeTransform {
  constructor() {}
  transform(value: any): any {
    return value
      ? Math.floor(value / 60) + ":" + (value % 60).toString().padStart(2, "0")
      : "";
  }
}
