// For Infinite Scrolling
export const InfiniteScrollItemCount = 100;
export const InfiniteScrollItemListCount = 100;
export const ItemSelectionCount = 300;
export const TotalItemListRowCount = 1000;
export const BatchItemListRowCount = 500;
export const InfiniteScrollTableCards = 15;
export const InventoryDefaultSelect = 2;
export const BaseUnitConversion = 1;
export const ConfirmationString = {
  Edit: 'Edit',
  Delete: 'Delete',
  Copy: 'Copy',
  Deactivate: 'Deactivate',
  Activate: 'Activate'
};

// Sesssion Storage Variables
export const ActiveMenu = 'ActiveMenu';
export const EndPointUrl = 'EndPointUrl';
export const AccessToken = 'AccessToken';
export const LocationIds = 'FromLocId';
export const LoggedInSiteId = 'LoggedInSiteId';
export const LoggedInSiteName = 'LoggedInSiteName';
export const LoggedInRoleName = 'LoggedInRoleName';
export const Tenant = 'Tenant';
export const OnPrem = 'OnPrem';
export const ReloadAlertMessage = 'Changes you made may not be saved.';
export const IniDateFormat = 'IniDateFormat';
export const IniDateFieldFormat = 'IniDateFieldFormat';
export const IniEnableGridSort = 'EnableGridSort';
export const IniItemListSelectionCount = 'ItemListSelectionCount';
export const IniPostLimit = 'ItemListPostLimitation';
export const DefaultIniDateFormat =  'MM/DD/YYYY'; //change regrads prasann
export const DefaultIniEnableGridSort = false;
export const IsMFAEnabled =  'IsMFAEnabled';
export const IsAdEnabled = 'IsAdEnabled';
export const PasswordValue = 'PasswordValue';
export const IsProductAuthentication = 'isProductAuthentication';
export const Default_SessionInactivityTime = 15;
export const DefaultLanguage = 'en';
export const APGLIntegration = 'APGLIntegration';
export const StorageData = 'StorageData';
export const DefaultDateTimeFormat = 'MM/DD/YYYY, hh:mm A';
export const DocApprovals = 'DocApprovals';
export const PostDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';


export enum DeviceName {
  Desktop = 'Desktop',
  Tablet = 'Tab',
  Mobile = 'Mobile'
}

export enum SortOrder {
  ASC = 'Asc',
  DESC = 'Desc'
}

export const desktopminwidth = 1025;
export const desktopminheight = 769;
export const tablandscapewidth = 1024;
export const tablandscapeheight = 768;
export const tabpotraitmaxwidth = 768;
export const tabpotraitmaxheight = 1280;
export const tabpotraitminwidth = 720;
export const tabpotraitminheight = 1024;
export const mobilemaxwidth = 600;
export const mobilemaxheight = 850;
export const mobileminwidth = 280;
export const mobileminheight = 530;

export const DateFormatters = ['CreatedDate', 'UpdatedDate'];
export const NumberFormatters = [];
export const BooleanFormatters = ['IsAdUser', 'IsRecipeAdmin', 'isBlocked'];

export enum ColumnFieldType {
  NUMERIC = 'numericColumn',
  DATECOLUMN = 'dateColumn',
  NONEDITABLE = 'nonEditableColumn',
  BOOLEAN = 'booleanColumn',
  DECIMAL = 'decimal',
  CURRENCY = 'currency'
}

export enum ColumnType {
  number = 'number',
  string = 'string',
  boolean = 'boolean',
  date = 'date',
}
export const IsloggedIn = 'IsLoggedIn';


// For showing icons in grid
export const ReplaceTextWithIcon = [
  {
    OdataKey: 'IsActive',
    RendererName: 'iconRenderer',
    HeaderName: '',
    Width: '30'
  }
];

export const NestedFilterKey = [
  {
    Name: 'UserRole',
    FilterEntityName: 'Role',
    FilterColumnKey: 'Id'
  }];

export enum FieldDataType {
  NUMERIC = 'number',
  DATECOLUMN = 'date',
  STRING = 'string'
}

export enum ModuleType {
  REQUISTION = 'Requistion Request',
  PURCHASE = 'Purchase Request',
  PO = 'Po Request'
}

export enum TemplateAuthMessage {
  PARAMSMODIFIED = 'Params Modified',
  VALIDATIONINPROGRESS = 'Authentication In progress'
}

export enum ApprovalType {
  HOLD = 'hold',
  DENY = 'deny',
  APPROVE = 'approve'
}


export enum AzureAdType {
  CUSTOMERB2C = '1',
  PRODUCTB2C = '2',
  LDAP = '3',
  NONE = '0',
  AzureAdSingle = '4',
}

export enum AzureUserFlow {
  CUSTOMERUSERFLOW  = 'CustomerAzureB2C',
  AGIYSYSUSERFLOW = 'AgilysysLocalB2C',
  LDAPUSERFLOW = 'LDAP',
  NONEUSERFLOW = 'None'
}

export enum TamperingMessage  {
  TamperingHeaderMessage = 'Error occurred due to any of these reason(s).',
  TamperingContentMessage = 'Incorrect URL@Unauthorized access@Other exception occurred',
  TamperingFooterMessage = 'Please contact the System Administrator.'
}

export const DataImportStatus = [ 
  'Pending', 'Running', 'Done', 'Failed',
  'Warning', 'Disabled', 'Stopped', 'Rejected',
  'Internal Server Error', 'Bad Request'];

  export enum ValueFormaters {
    DateFormater = 'DateFormater',
    BooleanFormater = 'BooleanFormater',
    DateTimeFormater = 'DateTimeFormater',
    CurrencyFormater = 'CurrencyFormater'
  }
  
export const resizeRows = [{
  Id: 1,
  Name: 'Cozy',
  IconName: 'icon-default-eatec',
  DisplayName: 'Cozy'
}, {
  Id: 2,
  Name: 'Comfort',
  IconName: 'icon-comfortable-eatec',
  DisplayName: 'Comfort'
}, {
  Id: 3,
  Name: 'Compact',
  IconName: 'icon-compact-eatec',
  DisplayName: 'Compact'
}];

//DecimalFormatter
export const WholeNumberLength = 10;
export const VisibleNumberLength = 2;
export const FocusNumberLength = 6;
//CurrencyFormatter
export const WholeNumberCurrencyLength = 12;
export const VisibleNumberCurrencyLength = 3;
export const FocusNumberCurrencyLength = 4;
export const DefaultCurrencyId = 164; // Unites States of America

export const currencySymbol = {
	"Symbols": [
		{
			"Id": 1,
			"Name": "Afghan afghani",
			"Code": "AFN",
			"Symbol": "؋"
		},
		{
			"Id": 2,
			"Name": "Albanian Lek",
			"Code": "ALL",
			"Symbol": "Lek"
		},
		{
			"Id": 3,
			"Name": "Algerian Dinar",
			"Code": "DZD",
			"Symbol": "دج"
		},
		{
			"Id": 4,
			"Name": "Angolan Kwanza",
			"Code": "AOA",
			"Symbol": "Kz"
		},
		{
			"Id": 5,
			"Name": "Antillian Guilder",
			"Code": "ANG",
			"Symbol": "ƒ"
		},
		{
			"Id": 6,
			"Name": "Armenian Dram",
			"Code": "AMD",
			"Symbol": "֏"
		},
		{
			"Id": 7,
			"Name": "Aruban Guilder",
			"Code": "AWG",
			"Symbol": "ƒ"
		},
		{
			"Id": 8,
			"Name": "Australian Dollars",
			"Code": "AUD",
			"Symbol": "$"
		},
		{
			"Id": 9,
			"Name": "Azerbaijani manat",
			"Code": "AZN",
			"Symbol": "₼"
		},
		{
			"Id": 10,
			"Name": "Bahamian Dollar",
			"Code": "BSD",
			"Symbol": "$"
		},
		{
			"Id": 11,
			"Name": "Bahraini Dinar",
			"Code": "BHD",
			"Symbol": "د.ب"
		},
		{
			"Id": 12,
			"Name": "Bangladesh Taka",
			"Code": "BDT",
			"Symbol": "৳"
		},
		{
			"Id": 13,
			"Name": "Barbados Dollar",
			"Code": "BBD",
			"Symbol": "$"
		},
		{
			"Id": 14,
			"Name": "Belize Dollar",
			"Code": "BZD",
			"Symbol": "BZ$"
		},
		{
			"Id": 15,
			"Name": "Bermudian Dollar",
			"Code": "BMD",
			"Symbol": "$"
		},
		{
			"Id": 16,
			"Name": "Bhutanese Ngultrum",
			"Code": "BTN",
			"Symbol": "Nu"
		},
		{
			"Id": 17,
			"Name": "Bolivar Fuerte",
			"Code": "ESA",
			"Symbol": "Bs"
		},
		{
			"Id": 18,
			"Name": "Bolivia Boliviano",
			"Code": "BOB",
			"Symbol": "$b"
		},
		{
			"Id": 19,
			"Name": "Bolivian Mvdol",
			"Code": "BOV",
			"Symbol": "Mvdol"
		},
		{
			"Id": 20,
			"Name": "Bosnia and Herzegovina Convertible Marks",
			"Code": "BAM",
			"Symbol": "KM"
		},
		{
			"Id": 21,
			"Name": "Botswana Pula",
			"Code": "BWP",
			"Symbol": "P"
		},
		{
			"Id": 22,
			"Name": "Brazilian Real",
			"Code": "BRL",
			"Symbol": "R$"
		},
		{
			"Id": 23,
			"Name": "Brunei Dollar",
			"Code": "BND",
			"Symbol": "$"
		},
		{
			"Id": 24,
			"Name": "Burundi Franc",
			"Code": "BIF",
			"Symbol": "FBu"
		},
		{
			"Id": 25,
			"Name": "Cambodia Riel",
			"Code": "KHR",
			"Symbol": "៛"
		},
		{
			"Id": 26,
			"Name": "Canadian Dollar",
			"Code": "CAD",
			"Symbol": "$"
		},
		{
			"Id": 27,
			"Name": "Cape Verdi Escudo",
			"Code": "CVE",
			"Symbol": "Esc"
		},
		{
			"Id": 28,
			"Name": "Cayman Islands Dollar",
			"Code": "KYD",
			"Symbol": "$"
		},
		{
			"Id": 29,
			"Name": "CFA Franc BCEAO",
			"Code": "XOF",
			"Symbol": "CFA"
		},
		{
			"Id": 30,
			"Name": "CFA Franc BEAC",
			"Code": "XAF",
			"Symbol": "FCFA"
		},
		{
			"Id": 31,
			"Name": "CFP Franc",
			"Code": "XPF",
			"Symbol": "₣"
		},
		{
			"Id": 32,
			"Name": "Chilean Peso",
			"Code": "CLP",
			"Symbol": "$"
		},
		{
			"Id": 33,
			"Name": "Chinese Yuan Renminbi",
			"Code": "CNX",
			"Symbol": "¥"
		},
		{
			"Id": 34,
			"Name": "Colombian Peso",
			"Code": "COP",
			"Symbol": "$"
		},
		{
			"Id": 35,
			"Name": "Comoro Franc",
			"Code": "KMF",
			"Symbol": "CF"
		},
		{
			"Id": 36,
			"Name": "Congolese Franc",
			"Code": "CDF",
			"Symbol": "FC"
		},
		{
			"Id": 37,
			"Name": "Costa Rican Colon",
			"Code": "CRC",
			"Symbol": "₡"
		},
		{
			"Id": 38,
			"Name": "Croatian Kuna",
			"Code": "HRK",
			"Symbol": "kn"
		},
		{
			"Id": 39,
			"Name": "Cuban convertible peso",
			"Code": "CUC",
			"Symbol": "CUC$"
		},
		{
			"Id": 40,
			"Name": "Cuban Peso",
			"Code": "CUP",
			"Symbol": "₱"
		},
		{
			"Id": 41,
			"Name": "Czech Koruna",
			"Code": "CZK",
			"Symbol": "Kč"
		},
		{
			"Id": 42,
			"Name": "Danish Krone",
			"Code": "DKK",
			"Symbol": "kr"
		},
		{
			"Id": 43,
			"Name": "Djibouti Franc",
			"Code": "DJF",
			"Symbol": "Fdj"
		},
		{
			"Id": 44,
			"Name": "Dobra",
			"Code": "STD",
			"Symbol": "Db"
		},
		{
			"Id": 45,
			"Name": "Dominican Republic Peso",
			"Code": "DOP",
			"Symbol": "RD$"
		},
		{
			"Id": 46,
			"Name": "East Caribbean Dollar",
			"Code": "XCD",
			"Symbol": "$"
		},
		{
			"Id": 47,
			"Name": "Egyptian Pound",
			"Code": "EGP",
			"Symbol": "£"
		},
		{
			"Id": 48,
			"Name": "El Salvador Colón",
			"Code": "SVC",
			"Symbol": "$"
		},
		{
			"Id": 49,
			"Name": "Eritrean Nakfa",
			"Code": "ERN",
			"Symbol": "ናቕፋ"
		},
		{
			"Id": 50,
			"Name": "Ethiopian Birr",
			"Code": "ETB",
			"Symbol": "ብር"
		},
		{
			"Id": 51,
			"Name": "Euro",
			"Code": "EUR",
			"Symbol": "€"
		},
		{
			"Id": 52,
			"Name": "Falkland Islands Pound",
			"Code": "FKP",
			"Symbol": "£"
		},
		{
			"Id": 53,
			"Name": "Fiji Dollar",
			"Code": "FJD",
			"Symbol": "$"
		},
		{
			"Id": 54,
			"Name": "Gambia Delasi",
			"Code": "GMD",
			"Symbol": "D"
		},
		{
			"Id": 55,
			"Name": "Georgian Lari",
			"Code": "GEL",
			"Symbol": "ლ"
		},
		{
			"Id": 56,
			"Name": "Ghanaian Cedi (3rd)",
			"Code": "GHS",
			"Symbol": "¢"
		},
		{
			"Id": 57,
			"Name": "Gibraltar Pound",
			"Code": "GIP",
			"Symbol": "£"
		},
		{
			"Id": 58,
			"Name": "Guatemala Quetzal",
			"Code": "GTQ",
			"Symbol": "Q"
		},
		{
			"Id": 59,
			"Name": "Guinea Franc",
			"Code": "GNF",
			"Symbol": "GFr"
		},
		{
			"Id": 60,
			"Name": "Guyanese Dollar",
			"Code": "GYD",
			"Symbol": "$"
		},
		{
			"Id": 61,
			"Name": "Haitian Gourde",
			"Code": "HTG",
			"Symbol": "G"
		},
		{
			"Id": 62,
			"Name": "Honduras Lempira",
			"Code": "HNL",
			"Symbol": "L"
		},
		{
			"Id": 63,
			"Name": "Hong Kong Dollar",
			"Code": "HKD",
			"Symbol": "$"
		},
		{
			"Id": 64,
			"Name": "Hungarian Forint",
			"Code": "HUF",
			"Symbol": "Ft"
		},
		{
			"Id": 65,
			"Name": "Iceland Krona",
			"Code": "ISK",
			"Symbol": "kr"
		},
		{
			"Id": 66,
			"Name": "Indian Rupee",
			"Code": "INR",
			"Symbol": "₹"
		},
		{
			"Id": 67,
			"Name": "Indonesian Rupiah",
			"Code": "IDR",
			"Symbol": "Rp"
		},
		{
			"Id": 68,
			"Name": "Iranian Rial",
			"Code": "IRR",
			"Symbol": "﷼"
		},
		{
			"Id": 69,
			"Name": "Iraqi Dinar",
			"Code": "IQD",
			"Symbol": "ع.د"
		},
		{
			"Id": 70,
			"Name": "Jamaican Dollar",
			"Code": "JMD",
			"Symbol": "J$"
		},
		{
			"Id": 71,
			"Name": "Japanese Yen",
			"Code": "JPY",
			"Symbol": "¥"
		},
		{
			"Id": 72,
			"Name": "Jordanian Dinar",
			"Code": "JOD",
			"Symbol": "د.ا"
		},
		{
			"Id": 73,
			"Name": "Kazakhstani Tenge",
			"Code": "KZT",
			"Symbol": "лв"
		},
		{
			"Id": 74,
			"Name": "Kenyan Shilling",
			"Code": "KES",
			"Symbol": "Ksh"
		},
		{
			"Id": 75,
			"Name": "Kuwaiti Dinar",
			"Code": "KWD",
			"Symbol": "د.ك"
		},
		{
			"Id": 76,
			"Name": "Kyrgyzstan Som",
			"Code": "KGS",
			"Symbol": "лв"
		},
		{
			"Id": 77,
			"Name": "Laos Kip",
			"Code": "LAK",
			"Symbol": "₭"
		},
		{
			"Id": 78,
			"Name": "Lebanese Pound",
			"Code": "LBP",
			"Symbol": "£"
		},
		{
			"Id": 79,
			"Name": "Lesotho Loti",
			"Code": "LSL",
			"Symbol": "M"
		},
		{
			"Id": 80,
			"Name": "Liberia Dollar",
			"Code": "LRD",
			"Symbol": "$"
		},
		{
			"Id": 81,
			"Name": "Libyan Dinar",
			"Code": "LYD",
			"Symbol": "ل.د"
		},
		{
			"Id": 82,
			"Name": "Lithuania",
			"Code": "LTL",
			"Symbol": "LTL"
		},
		{
			"Id": 83,
			"Name": "Macau Pataca",
			"Code": "MOP",
			"Symbol": "MOP$"
		},
		{
			"Id": 84,
			"Name": "Macedonian Denar",
			"Code": "MKD",
			"Symbol": "ден"
		},
		{
			"Id": 85,
			"Name": "Malagasy Ariary",
			"Code": "MGA",
			"Symbol": "Ar"
		},
		{
			"Id": 86,
			"Name": "Malawi Kwacha",
			"Code": "MWK",
			"Symbol": "MK"
		},
		{
			"Id": 87,
			"Name": "Malaysian Ringgit",
			"Code": "MYR",
			"Symbol": "RM"
		},
		{
			"Id": 88,
			"Name": "Maldives Rufiyaa",
			"Code": "MVR",
			"Symbol": "Rf"
		},
		{
			"Id": 89,
			"Name": "Manat",
			"Code": "TMT",
			"Symbol": "AZN"
		},
		{
			"Id": 90,
			"Name": "Mauritanian ouguiya",
			"Code": "MRU",
			"Symbol": "UM"
		},
		{
			"Id": 91,
			"Name": "Mauritius Rupee",
			"Code": "MUR",
			"Symbol": "₨"
		},
		{
			"Id": 92,
			"Name": "Mexican Peso",
			"Code": "MXN",
			"Symbol": "$"
		},
		{
			"Id": 93,
			"Name": "Mexican Unidad de Inversion",
			"Code": "MXV",
			"Symbol": "MXV"
		},
		{
			"Id": 94,
			"Name": "Moldovia Leu",
			"Code": "MDL",
			"Symbol": "pl"
		},
		{
			"Id": 95,
			"Name": "Mongolia Tugrik",
			"Code": "MNT",
			"Symbol": "₮"
		},
		{
			"Id": 96,
			"Name": "Moroccan Dirham",
			"Code": "MAD",
			"Symbol": "MAD"
		},
		{
			"Id": 97,
			"Name": "Mozambican Metical",
			"Code": "MZN",
			"Symbol": "MT"
		},
		{
			"Id": 98,
			"Name": "Myanmar Kyat",
			"Code": "MMK",
			"Symbol": "K"
		},
		{
			"Id": 99,
			"Name": "Namibian Dollar",
			"Code": "NAD",
			"Symbol": "N$"
		},
		{
			"Id": 100,
			"Name": "Nepalese Rupee",
			"Code": "NPR",
			"Symbol": "₨"
		},
		{
			"Id": 101,
			"Name": "New Belarusian Ruble",
			"Code": "BYN",
			"Symbol": "Br"
		},
		{
			"Id": 102,
			"Name": "New Bulgarian Lev",
			"Code": "BGN",
			"Symbol": "лв"
		},
		{
			"Id": 103,
			"Name": "New Guinea Kina",
			"Code": "PGK",
			"Symbol": "K"
		},
		{
			"Id": 104,
			"Name": "New Israeli Scheqel",
			"Code": "ILS",
			"Symbol": "₪"
		},
		{
			"Id": 105,
			"Name": "New Polish Zloty",
			"Code": "PLN",
			"Symbol": "zł"
		},
		{
			"Id": 106,
			"Name": "New Romanian Lei",
			"Code": "RON",
			"Symbol": "lei"
		},
		{
			"Id": 107,
			"Name": "New Taiwan Dollar",
			"Code": "TWD",
			"Symbol": "NT$"
		},
		{
			"Id": 108,
			"Name": "New Turkish Lira",
			"Code": "TRY",
			"Symbol": "₺"
		},
		{
			"Id": 109,
			"Name": "New Zealand Dollar",
			"Code": "NZD",
			"Symbol": "$"
		},
		{
			"Id": 110,
			"Name": "Nicaragua Cordoba Oro",
			"Code": "NIO",
			"Symbol": "C$"
		},
		{
			"Id": 111,
			"Name": "Nigerian Naira",
			"Code": "NGN",
			"Symbol": "₦"
		},
		{
			"Id": 112,
			"Name": "North Korean won",
			"Code": "KPW",
			"Symbol": "₩"
		},
		{
			"Id": 113,
			"Name": "Norwegian Krone",
			"Code": "NOK",
			"Symbol": "kr"
		},
		{
			"Id": 114,
			"Name": "Nuevo Argentine Peso",
			"Code": "ARS",
			"Symbol": "$"
		},
		{
			"Id": 115,
			"Name": "Offshore Renminbi",
			"Code": "CNH",
			"Symbol": "¥‎"
		},
		{
			"Id": 116,
			"Name": "Ouguiya",
			"Code": "MRO",
			"Symbol": "MRU"
		},
		{
			"Id": 117,
			"Name": "Pakistan Rupee",
			"Code": "PKR",
			"Symbol": "₨"
		},
		{
			"Id": 118,
			"Name": "Panamanian Balboa",
			"Code": "PAB",
			"Symbol": "B/."
		},
		{
			"Id": 119,
			"Name": "Paraguay Guarani",
			"Code": "PYG",
			"Symbol": "Gs"
		},
		{
			"Id": 120,
			"Name": "Peruvian Nuevo Sol",
			"Code": "PEN",
			"Symbol": "S/."
		},
		{
			"Id": 121,
			"Name": "Peso Uruguayo",
			"Code": "UYU",
			"Symbol": "$U"
		},
		{
			"Id": 122,
			"Name": "Philippine Peso",
			"Code": "PHP",
			"Symbol": "₱"
		},
		{
			"Id": 123,
			"Name": "British Pound Sterling",
			"Code": "GBP",
			"Symbol": "£"
		},
		{
			"Id": 124,
			"Name": "Qatari Rial",
			"Code": "QAR",
			"Symbol": "﷼"
		},
		{
			"Id": 125,
			"Name": "Reval. Belarus Ruble",
			"Code": "BYR",
			"Symbol": "Rbl‎"
		},
		{
			"Id": 126,
			"Name": "Rial Omani",
			"Code": "OMR",
			"Symbol": "﷼"
		},
		{
			"Id": 127,
			"Name": "Russian Ruble",
			"Code": "RUB",
			"Symbol": "₽"
		},
		{
			"Id": 128,
			"Name": "Rwanda Franc",
			"Code": "RWF",
			"Symbol": "FRw"
		},
		{
			"Id": 129,
			"Name": "Samoan Tala",
			"Code": "WST",
			"Symbol": "WS$"
		},
		{
			"Id": 130,
			"Name": "Sao Tome and Principe Dobra",
			"Code": "STN",
			"Symbol": "Db"
		},
		{
			"Id": 131,
			"Name": "Saudi Riyal",
			"Code": "SAR",
			"Symbol": "﷼"
		},
		{
			"Id": 132,
			"Name": "Serbian Dinar",
			"Code": "RSD",
			"Symbol": "Дин."
		},
		{
			"Id": 133,
			"Name": "Seychelles Rupee",
			"Code": "SCR",
			"Symbol": "₨"
		},
		{
			"Id": 134,
			"Name": "Sierra Leone Leone",
			"Code": "SLE",
			"Symbol": "Le"
		},
		{
			"Id": 135,
			"Name": "Sierra Leonean leone",
			"Code": "SLL",
			"Symbol": "Le"
		},
		{
			"Id": 136,
			"Name": "Singapore Dollar",
			"Code": "SGD",
			"Symbol": "$"
		},
		{
			"Id": 137,
			"Name": "Solomon Island Dollar",
			"Code": "SBD",
			"Symbol": "$"
		},
		{
			"Id": 138,
			"Name": "Somalia Shilling",
			"Code": "SOS",
			"Symbol": "S"
		},
		{
			"Id": 139,
			"Name": "South African Rand",
			"Code": "ZAR",
			"Symbol": "R"
		},
		{
			"Id": 140,
			"Name": "South Sudan Pound",
			"Code": "SSP",
			"Symbol": "£"
		},
		{
			"Id": 141,
			"Name": "South-Korean Won",
			"Code": "KRW",
			"Symbol": "₩"
		},
		{
			"Id": 142,
			"Name": "Sri Lankan Rupee",
			"Code": "LKR",
			"Symbol": "₨"
		},
		{
			"Id": 143,
			"Name": "St. Helena Pound",
			"Code": "SHP",
			"Symbol": "£"
		},
		{
			"Id": 144,
			"Name": "Sudanese pound",
			"Code": "SDG",
			"Symbol": "ج.س"
		},
		{
			"Id": 145,
			"Name": "Surinamese dollar",
			"Code": "SRD",
			"Symbol": "$"
		},
		{
			"Id": 146,
			"Name": "Swaziland Lilangeni",
			"Code": "SZL",
			"Symbol": "E"
		},
		{
			"Id": 147,
			"Name": "Swedish Krone",
			"Code": "SEK",
			"Symbol": "kr"
		},
		{
			"Id": 148,
			"Name": "Swiss Franc",
			"Code": "CHF",
			"Symbol": "CHF"
		},
		{
			"Id": 149,
			"Name": "Syrian Pound",
			"Code": "SYP",
			"Symbol": "£"
		},
		{
			"Id": 150,
			"Name": "Tajikistan Somoni",
			"Code": "TJS",
			"Symbol": "ЅM"
		},
		{
			"Id": 151,
			"Name": "Tanzanian Shilling",
			"Code": "TZS",
			"Symbol": "TSh"
		},
		{
			"Id": 153,
			"Name": "Thai Baht",
			"Code": "THB",
			"Symbol": "฿"
		},
		{
			"Id": 154,
			"Name": "Tonga Paanga",
			"Code": "TOP",
			"Symbol": "T$"
		},
		{
			"Id": 155,
			"Name": "Trinidad & Tobago Dollar",
			"Code": "TTD",
			"Symbol": "TT$"
		},
		{
			"Id": 156,
			"Name": "Tunisian Dinar",
			"Code": "TND",
			"Symbol": "د.ت"
		},
		{
			"Id": 157,
			"Name": "Emirati UAE Dirham",
			"Code": "AED",
			"Symbol": " د.إ"
		},
		{
			"Id": 158,
			"Name": "Uganda Shilling",
			"Code": "UGX",
			"Symbol": "USh"
		},
		{
			"Id": 159,
			"Name": "Ukraine Hryvnia",
			"Code": "UAH",
			"Symbol": "₴"
		},
		{
			"Id": 160,
			"Name": "Unidad de Fomento",
			"Code": "CLF",
			"Symbol": "CLF"
		},
		{
			"Id": 161,
			"Name": "Unidad de Valor Real",
			"Code": "COU",
			"Symbol": "UVR"
		},
		{
			"Id": 162,
			"Name": "United States dollar (next day) ",
			"Code": "USN",
			"Symbol": "$"
		},
		{
			"Id": 163,
			"Name": "Uruguay Peso en Unidades Indexadas",
			"Code": "UYI",
			"Symbol": "$U‎"
		},
		{
			"Id": 164,
			"Name": "US Dollars",
			"Code": "USD",
			"Symbol": "$"
		},
		{
			"Id": 165,
			"Name": "Uzbekistani Som",
			"Code": "UZS",
			"Symbol": "лв"
		},
		{
			"Id": 166,
			"Name": "Vanuatu Vatu",
			"Code": "VUV",
			"Symbol": "VT"
		},
		{
			"Id": 167,
			"Name": "Venezuelan Bolívar",
			"Code": "VEF",
			"Symbol": "Bs"
		},
		{
			"Id": 168,
			"Name": "Venezuelan digital bolivar",
			"Code": "VED",
			"Symbol": "Bs."
		},
		{
			"Id": 169,
			"Name": "Venezuelan sovereign bolivar",
			"Code": "VES",
			"Symbol": "VES"
		},
		{
			"Id": 170,
			"Name": "Vietnamese New Dong",
			"Code": "VND",
			"Symbol": "₫"
		},
		{
			"Id": 171,
			"Name": "WIR Euro",
			"Code": "CHE",
			"Symbol": "€"
		},
		{
			"Id": 172,
			"Name": "WIR franc",
			"Code": "CHW",
			"Symbol": "CHW"
		},
		{
			"Id": 173,
			"Name": "Yemeni Rial",
			"Code": "YER",
			"Symbol": "﷼"
		},
		{
			"Id": 174,
			"Name": "Yuan Renminbi",
			"Code": "CNY",
			"Symbol": "¥"
		},
		{
			"Id": 175,
			"Name": "Zambian Kwacha",
			"Code": "ZMW",
			"Symbol": "ZK"
		},
		{
			"Id": 176,
			"Name": "Zimbabwean dollar",
			"Code": "ZWL",
			"Symbol": "Z$"
		}
	]
}

export const ModulePackageId = {
	"Requisition": 1
};