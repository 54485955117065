import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount, ModulePackageId } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';

export const CustomFromLocation: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: any, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData ?: TransactionConfiguration, 
    sysytemPreferenceService?: SysytemPreferenceService,
    serviceData?: any, customBuilderData ?: any, FileEvent ?: any, 
    DomSanitizer ?: DomSanitizer, toasterService?: ToastrService, 
    createTransactionService?: CreateTransactionService, 
    translateService?: TranslateService, matDialog?: MatDialog,fromEdit?: boolean
    ): any => {
        try {
            if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
                const getCurrentLocationId = FormGroupData.controls[FieldGrouping.OdataKey].value;
                // For Bug Fix - 232710
                const reqPrevId = createTransactionService?.RequisitionPrevLocationId;
                // const seleId = `Id eq ${getCurrentLocationId}`;
                // const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                //     return '%' + c.charCodeAt(0).toString(16);
                // }); 
                // const params: QueryParams = {
                //     count: true,
                //     top: InfiniteScrollItemCount,
                //     skip: 0,
                //     expand: '',
                //     select: 'SupplyLocId,LocationTypeId,Id,Name',
                //     filter: '',
                //     orderby: '',
                //     isactive: false,
                //     search: '',
                //     filterQuery: GetDataFilter,
                //     searchtext: ''
                // };
                // const getQueryParams = formQueryParams(params);   
                // const CustomUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}`;
                // const GetDataByIdUrl = `${CustomUrl + getQueryParams}`;
                const transTypeId = FieldGrouping.StoreProcedureConFig.TransactionType;
                const CustomUrl = `/inventory/api/GetLocations/${transTypeId}/true`;
                httpService.GethttpMethod(CustomUrl).subscribe(async locationdata => {
                   if (locationdata && locationdata['ResultData'].length > 0) {
                        // Only For Requisition Screen
                        if (MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0 &&
                            MasterDataCollection.TransactionType === 4) {
                            const packageId = ModulePackageId[MasterDataCollection.Name];
                            const isFromLocation = FieldGrouping.OdataKey === 'FromLocId' ? true : false;
                            const GetUrl = `/inventory/api/FetchMasterLimitItemsLocation/${packageId}/${MasterDataCollection.TransactionType}/${isFromLocation}`;
                            httpService.GethttpMethod(GetUrl).subscribe(location => {
                                if (location && location['ResultData'].length > 0) {
                                    const ItemIds = location['ResultData'].filter(x => x.Id === getCurrentLocationId)[0].ItemIds;
                                    if (ItemIds !== null) {
                                        const confirmPopModel = {} as ConfirmPopModel;
                                        confirmPopModel.PopUpContent = translateService.instant('Common.TransactionLocationPopupMessage');
                                        confirmPopModel.PopupHeaderContent = translateService.instant('Common.Confirmation');
                                        const locationDialog = matDialog.open(AgilysysConfirmationPopupComponent, {
                                            width: '525px',
                                            height: '235px',
                                            data: confirmPopModel,
                                            autoFocus: false,
                                            disableClose: true
                                        });
                                        locationDialog.afterClosed().subscribe(res => {
                                            if (res === 'Confirm') {
                                                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
                                                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = {
                                                    ...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig
                                                };
                                                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList = {
                                                    ...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList
                                                };
                                                MasterDataCollection = {...MasterDataCollection};
                                                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true);  
                                            } else if (res === 'Cancel' && reqPrevId) {
                                                FormGroupData.controls[FieldGrouping.OdataKey].setValue(reqPrevId);
                                                FormGroupData.updateValueAndValidity();
                                            }
                                        });
                                    }
                                }
                            });
                            // For Bug Fix - 232710
                            createTransactionService.RequisitionPrevLocationId = FormGroupData.controls[FieldGrouping.OdataKey].value;
                        }
                        const getSupplierId = locationdata['ResultData'][0]['SupplyLocId'];
                        FormField.forEach(element => {
                            if (element.fieldType.SingleData 
                                && element.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo
                                && element.fieldType.SingleData.options 
                                && element.fieldType.SingleData.options.length > 0) {
                                if (element.fieldType.SingleData.options.findIndex(x => x.key === getSupplierId) >= 0) {
                                    if (getSupplierId && getSupplierId !== null && !FormGroupData.value['IsTransferOut']) {
                                        FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(getSupplierId);
                                    }
                                }
                            }
                        });
                        
                        if ( FieldGrouping.CustomFormAction.hasOwnProperty('DisabledField') && 
                            FieldGrouping.CustomFormAction.DisabledField && !FormGroupData.value['IsTransferOut']) {
                            const locationTypeId = locationdata['ResultData'].filter(z => z.Id === FormGroupData.value.FromLocId)[0].LocationTypeId;
                            if (locationTypeId === 8 && locationTypeId !== null ) {
                                let authPayload = {} as any;
                                authPayload.ClientUrl = '/inventory/api/GetCustomers';
                                const customerPostPayload = {} as any;
                                customerPostPayload.transTypeId = MasterDataCollection.TransactionType;
                                customerPostPayload.locationId = FormGroupData.value.FromLocId;
                                customerPostPayload.searchText = '';
                                customerPostPayload.skip = 0;
                                customerPostPayload.take = 100;
                                customerPostPayload.getTotalRecCount = true;
                                customerPostPayload.fetchAll = false;
                                customerPostPayload.totalCount = 0;
                                customerPostPayload.lookupField = '';
                                customerPostPayload.orderByColumn = 'Name';
                                customerPostPayload.orderByDirection = 1;
                                authPayload.Payload = customerPostPayload;
                                const customerResultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
                                // FormGroupData.controls['CustomerId'].enable();
                                FormField.forEach((x: any) => {
                                    if (x.hasOwnProperty('fieldType') && x.fieldType.hasOwnProperty('SingleData') && 
                                    x.fieldType.SingleData.name === 'CustomerId') {
                                        x.fieldType.SingleData.disbaledProperty = false;
                                    }
                                });
                                const customerDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'CustomerId');
                                FormField[customerDropdownIndex].fieldType.SingleData.autoCompleteData = customerResultData.ResultData;
                                FormField[customerDropdownIndex].autoCompleteConfig.EnableLoadMore = 
                                customerResultData.ResultData?.length === customerResultData.TotalCount ? false : true;
                                FormGroupData.controls['CustomerId'].setValidators([Validators.required]);
                                FormGroupData.controls['CustomerId'].markAsTouched();
                                FormGroupData.controls['CustomerId'].updateValueAndValidity();
                            } else {
                                FormGroupData.controls['CustomerId'].clearValidators();
                                FormGroupData.controls['CustomerId'].setValue(null);
                                // FormGroupData.controls['CustomerId'].disable();
                                FormField.forEach((x: any) => {
                                    if (x.type === 'multipleautocomplete') {
                                    if (x.hasOwnProperty('fieldType') && x.fieldType.hasOwnProperty('SingleData') && 
                                    x.fieldType.SingleData.name === 'CustomerId') {
                                        x.fieldType.SingleData.disbaledProperty = true;
                                        x.fieldType.SingleData.autoCompleteData = [];
                                    }
                                }
                                });
                            }
                            FormGroupData.controls['CustomerId'].updateValueAndValidity();
                        }
                        if (!FormGroupData.value['IsTransferOut']) {
                            CustomFormField.CustomComapareLocation(
                            ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, 
                            FieldGrouping, FormGroupData, FormField, CustomData  );
                        }
                }
                });               
            }         
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

    function formQueryParams(params: QueryParams) {
        params.filter = '';
        const keys = Object.keys(params);
        let query = '';
        keys.forEach((data, index) => {
            if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
                if (params[data] !== '' && data !== 'filter') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        if (query.length > 0) {
                            query = query + '&$';
                        } else {
                            query = query + '?$';
                        }
                    }
                    query = query + data + '=' + params[data];
                } else if (data === 'filter') {
                    if (params.isactive) {
                        params[data] = 'IsActive eq true';
                    }
                    if (params.searchtext && params.searchtext.length > 0 && params.search) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.search;
                        } else {
                            params[data] = params.search;
                        }
                    }
                    if (params.filterQuery && params.filterQuery.length > 0) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.filterQuery;
                        } else {
                            params[data] = params.filterQuery;
                        }
                    }
    
                    if (params[data] !== '') {
                        if (index === 0) {
                            query = query + '?$';
                        } else {
                            query = query + '&$';
                        }
                        query = query + data + '=' + params[data];
                    }
                }
            }
        });
        return query;
    }