import { FormGroup } from '@angular/forms';
import { DecimalRoundOff } from 'src/app/eatecui/source/shared/enum/global.enum';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { QueryListInterface } from 'src/app/eatecui/source/shared/models/query-list';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    FormBuilderGroupKey, MasterInventoryManagement
} from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const CustomRecipeUnitChange: CustomFormConfiguration = (ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, 
    CustomData?: any, systemPreferenceData?: SysytemPreferenceService): any => {
    try {
        if (FieldGrouping.CustomFormAction ) {
            const converiosnParams = constractConversionQuery(FieldGrouping, FormGroupData, systemPreferenceData);
            if ( converiosnParams !== null ) {
                const GetDataByIdUrl =  `/inventory/api/UnitConversions${converiosnParams}`;
                httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                    // if (x['value'].length > 0) {
                    //     FormGroupData.controls['RecipeConversion'].setValue(x['value'][0].Conversion);
                    // } else {
                    //     FormGroupData.controls['RecipeConversion'].setValue(1);
                    // }
                    // if (x['value'].length > 0) {
                        const UnitsQueryArray = [
                            {
                                UnitsName: 'PurchaseUnit',
                                Conversion: 'PurchaseConversion',
                                CurrentCost: 'PurchaseCurrentCost'
                            },
                            {
                                UnitsName: 'TransactionUnit',
                                Conversion: 'TransactionConversion',
                                CurrentCost: 'TransactionCurrentCost'
                            },
                            {
                                UnitsName: 'RecipeUnit',
                                Conversion: 'RecipeConversion',
                                CurrentCost: 'RecipeCurrentCost'
                            }
                        ];
                        const conversionName = UnitsQueryArray.filter(e => e.UnitsName === FieldGrouping.OdataKey);
                        const conversionValue = x['value'][0] ? x['value'][0].Conversion : 1;
                        if ( conversionName && conversionName.length > 0 ) {
                            const InventoryUnits: Array<any> = [conversionName[0].Conversion];
                            const InventoryCurrentCost: Array<any> = [conversionName[0].CurrentCost];
                            setFormValue(FormGroupData, conversionValue, InventoryUnits);
                            setCurrentCost(FormGroupData, conversionValue, FormGroupData.controls['BaseCurrentCost'].value, 
                            InventoryCurrentCost);

                        }
                    // }
                    // const RecipeConversion = FormGroupData.controls['RecipeConversion'].value ?
                    //     parseFloat(FormGroupData.controls['RecipeConversion'].value) : 0;
                    // const CurrentCost = FormGroupData.controls['BaseCurrentCost'].value ? 
                    //     parseFloat(FormGroupData.controls['BaseCurrentCost'].value) : 0;
                    // const RecipeCost =  (1 / RecipeConversion) * CurrentCost;
                    // FormGroupData.controls['RecipeCurrentCost'].setValue(RecipeCost);
                });
            } else {
                const InventoryUnits: Array<any> = ['BaseConversion', 'PurchaseConversion', 'RecipeConversion', 'TransactionConversion'];
                setFormValue(FormGroupData, 1, InventoryUnits);
                const InventoryConversion: Array<any> = ['RecipeCurrentCost', 'PurchaseCurrentCost', 'RecipeCurrentCost', 'TransactionCurrentCost'];
                setCurrentCost(FormGroupData, 1, FormGroupData.controls['BaseCurrentCost'].value, InventoryConversion);
            }
        }
    } catch (error) {
        console.log('error occured openBarcodeEdit', error);
    }
};

const setCurrentCost = (FormGroupData: FormGroup, conversionValue: any, currentCoast: any, InventoryConversion: Array<any>) => {
    try {
        const decimalPoint = sessionStorage.getItem('appsetting') ? 
            JSON.parse(sessionStorage.getItem('appsetting')).DecimalPointRoundOff :
            DecimalRoundOff.SIX;
        for (const property in FormGroupData.controls) {
            if ( property ) {
                  const controlIndex = InventoryConversion.findIndex(x => x === property);
                  if ( controlIndex > - 1) {
                const UpdateCurrentCost = ( property === 'RecipeCurrentCost' ) ? 
                ( 1 / conversionValue) * currentCoast : conversionValue * currentCoast;
                FormGroupData.controls[property].setValue(UpdateCurrentCost.toFixed(4));
                CommonService.triggerBlurEvent(property);
                  }
            }
         }
    } catch ( error ) {
        console.error(error);
    }
};

const setFormValue = (FormGroupData: FormGroup, conversionValue: any, InventoryUnits: Array<any>) => {
    try {
        for (const property in FormGroupData.controls) {
            if ( property ) {
                  const controlIndex = InventoryUnits.findIndex(x => x === property);
                  if ( controlIndex > - 1) {
                   FormGroupData.controls[property].setValue(conversionValue);
                   CommonService.triggerBlurEvent(property);
                  }
            }
         }
    } catch ( error ) {
        console.error(error);
    }
};

const constractConversionQuery = ( FieldGrouping: FormBuilderGroupKey, FormGroupData: FormGroup,
         systemPreferenceData: SysytemPreferenceService ): any => {
  try {
        let conversionParams = null;
        const UnitsQueryArray = [
            {
                UnitsName: 'PurchaseUnit',
                FromUnit: 'BaseUnit',
                ToUnit: 'PurchaseUnit'
            },
            {
                UnitsName: 'TransactionUnit',
                FromUnit: 'BaseUnit',
                ToUnit: 'TransactionUnit'
            },
            {
                UnitsName: 'RecipeUnit',
                FromUnit: 'RecipeUnit',
                ToUnit: 'BaseUnit'
            }
        ];
        const GetUnitsFilter: Array<any> = UnitsQueryArray.filter((units: any) => units.UnitsName === FieldGrouping.OdataKey);
        if ( GetUnitsFilter && GetUnitsFilter.length > 0 ) {
            const UnitsObj = GetUnitsFilter[0];
            const UnitMeasurementSystemId = systemPreferenceData.globalSystemPreference.
            filter(x => x.LookupName === 'UnitMeasurementSystem')[0].LookupValue;
            const filter = `FromUnit eq ${FormGroupData.value[UnitsObj['FromUnit']]} and ToUnit eq ${FormGroupData.value[UnitsObj['ToUnit']]} and UnitMeasurementSystemId eq ${UnitMeasurementSystemId}`;
            const GetDataFilter = encodeURIComponent(`${filter}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            const params: QueryParams = {
                count: true,
                skip: 0,
                expand: '',
                select: 'Conversion,FromUnit,ToUnit',
                filter: '',
                orderby: '',
                isactive: true,
                search: '',
                filterQuery: GetDataFilter,
                searchtext: ''
            };
              conversionParams = QueryListInterface.QueryEngine(params);
        }
        return conversionParams;
    
  } catch ( error ) {
    console.error(error);
  }
};
