import { Component } from '@angular/core';
import { LiteralsUpdatesService } from 'src/app/common/shared/services/literals-updates.service';


@Component({
  selector: 'app-literals-edit',
  templateUrl: './literals-edit.component.html',
  styleUrls: ['./literals-edit.component.scss']
})
export class LiteralsEditComponent  {
  showIcon: boolean;

  constructor(public _literalUpdate: LiteralsUpdatesService) { }

  edit() {    
    if (this.showIcon) {
      this.save();
      this.showIcon = false;
      document.designMode = 'off'
      document.querySelectorAll('label').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('mat-error').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('h4').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('mat-checkbox').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('h1').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('h2').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('div').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('span').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('mat-label').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
      document.querySelectorAll('.mat-form-field-label').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'false') } });
    }
    else {
      this.showIcon = true;
      document.designMode = 'on'
      document.querySelectorAll('mat-error').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('label').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.style.color = "blue"; x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('h4').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.style.color = "blue"; x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('mat-checkbox').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('h1').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.style.color = "blue"; x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('h2').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.style.color = "blue"; x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('div').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.style.color = "blue"; x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('span').forEach(x => { if (x.attributes["LiteralID"] != undefined) { x.style.color = "blue"; x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('mat-label').forEach(x => { if (x.attributes["literalid"] != undefined) { x.setAttribute('contenteditable', 'true') } });
      document.querySelectorAll('.mat-form-field-label').forEach(x => {  x.setAttribute('contenteditable', 'true') } );
      document.querySelectorAll('.mat-form-field-label-wrapper').forEach(x => { x.setAttribute('contenteditable', 'true') });
    }
  }

  save() {
    this._literalUpdate.updateLiterals();
  }

}
