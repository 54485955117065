import { Injectable } from '@angular/core';
import { CommonControllersRoutes } from 'src/app/common/communication/common-route';
import { CommonCommunication } from 'src/app/common/communication/services/common-communication-services';
import { Localization } from 'src/app/common/localization/localization';
import { GuestItineraryRequest } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { BaseResponse } from '../business/shared.modals';
import { Host } from '../globalsContant';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';

@Injectable({
    providedIn: 'root'
  })

  export class SelectEformService {
    productId : string;
    eventCaptions: any;
    constructor(private localization: Localization, private _commonCommunication: CommonCommunication,
        private http: HttpServiceCall) {
      this.productId = this.localization.GetPropertyInfo('ProductId');
      this.eventCaptions =this.localization.captions.settings.events;   
     }

     async GetAppointmentByGuestId(guestId : string, fromDate: string, endDate: string): Promise<any[]> {
     
            const response: BaseResponse<any[]> = await this.http.CallApiAsync<any[]>(
                {
                  callDesc: 'GetAppointmentByGuestID',
                  host: Host.schedule,
                  method: HttpMethod.Get,
                  uriParams: { guestId: guestId,startDate : fromDate, endDate:endDate }
                }
              );
              return response.result;
        }

        async GetTeeTimeByGuestId(request: GuestItineraryRequest): Promise<any[]> {
     
          const response: BaseResponse<any[]> = await this.http.CallApiAsync<any[]>(
              {
                callDesc: 'GetGuestScheduledTeeTime',
                host: Host.golfSchedule,
                method: HttpMethod.Put,
                uriParams: {  },
                body: request
              }
            );
            return response.result;
      }


    } 