import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroupName, UntypedFormArray } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ServiceGroup, ThearapistDetails, ServiceDetails, TherapistCertification, ComboOptions, TherapistDetails,
  ServiceAddOns, TherapistAddOn, TherapistContact, PatchFormat, TherapistCertificationPatch
} from 'src/app/shared/business/view-settings.modals';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { ViewSettingClientBusiness } from 'src/app/shared/common-functionalities/business/view-settings.business';
import { SPAConfig } from 'src/app/core/config/SPA-config';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageProcessorService } from 'src/app/shared/service/image-processor-service';
import * as _ from 'lodash';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { EmptyValueValidator } from 'src/app/shared/Validators/EmptyValueValidator';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { BaseResponse, popupConfig, ImageData, MoreFilterOptions, NoDataModel, OutletSubProperty } from 'src/app/shared/business/shared.modals';
import { SettingDialogPopupCommonComponent } from 'src/app/shared/common-functionalities/setting-dialog-common-popup/setting-dialog-common-popup.component';
import { CommissionableRetailItem, Commission, RetailCommission } from 'src/app/retail/retail.modals';
import { SettingsService } from 'src/app/settings/settings.service';
import { ViewMoreServiceService } from 'src/app/shared/view-more/view-more-service.service';
import { CommissionSetupService } from 'src/app/retail/commission-setup/commission-setup.service';
import { CommissionDetails, CommissionOn } from 'src/app/retail/commission-setup/commission-setup.modals';
import { PhoneTypes } from 'src/app/common/shared/shared/enums/enums';

@Component({
  selector: 'app-instructor-setup',
  templateUrl: './instructor-setup.component.html',
  styleUrls: ['./instructor-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InstructorSetupComponent implements OnInit, OnDestroy {
  relativePlaceholder: string;
  // dev
  contactEmailType: any = [];
  contactPhoneType: any = [];
  hireDateSelected: Date;
  private therapistId = 0;
  private existingContactArr: any;
  private existingCertificationArr = [];
  SelectedTherapistId: number = 0;
  private selectedPhoneType = [];
  private IsServicesUpdated: boolean;
  selectedPhone: any;
  selectedEmail: any;
  private maxLstOrder: number;
  blnReadOnly: boolean;
  IsActiveModel: boolean;
  textToDisplayed: string;
  // ui
  url: any;
  @Input() dataInput: any;
  DispSec: any;
  Historydata: any;
  phoneNumArr: any;
  emailArr: any;
  TherSerGrp: ServiceGroup[];
  TherSerGrpDatas: any = [];
  TherAddOnData: any = [];
  isDisplayAddOn = false;
  existingAddOns: any = [];
  selectedSerGrp: any;
  checkedCommissionsArr: any = [];
  selectedAddon: any = [];
  SIS: any;
  genderMissing = true;
  TSCAIcon: any;
  SelectedRadioBut: any = 'CC';
  selectedCommLoc: any;
  selectedcommiLocArr: any = [];
  SelectedCommissionType: any;
  IsAllowGratuityChecked = false;
  IsAllowServiceChargeChecked = false;
  IsAllowCommissionChecked = false;
  captions: any = this.spaconfig.captions;
  CommissioncategoryArr: any;
  selectedcategoryArr: any = [];
  CommissionItemArr: RetailCommission[] = [];
  CommissionItemArr1: any[] = [];
  selectedItemArr: any = [];
  filterItemArr: any = [];
  filterCategoryArr: any = [];
  filterSerGrpArr: any = [];
  CommissionData: any = [];
  CCData: any = [];
  CPData: any = [];
  therapistYieldJson = [];
  textmaskFormat: string;
  isCommisionUserAuthorized: boolean;
  isCommisionViewOnly: boolean;
  clickbutton: any;
  ImageUploaded: any = false;
  selectedFile: File;
  emailIdArray: any = [];
  selectedArr: any;
  DataType: string;
  editImageId: any;
  sequenceNo: any;
  isImageRemoved: boolean;
  items: any[] = [];
  updateTherapistCommission: any[] = [];
  existingLisOrder: number;
  selectedIndex: any = 0;
  isphoneType: number;
  isboooo = false;
  Email: any = [];
  Phone: any = [];
  unFormatedPhone: any;
  oldServiceCount: number;
  FormGrp: UntypedFormGroup;
  PhoneNumbers: FormGroupName;
  public localize: any;
  IsGenderInValid: any = true;
  isUserAuthorized = true;
  isViewOnly = true;
  base64textString: any;
  DataArray: any;
  settingDialogSubscription: ISubscription;
  tableoptions: any;
  filterOptions: MoreFilterOptions;
  commissionableItems: CommissionableRetailItem[] = null;
  thumbnailImg: any;
  noDataOptions: NoDataModel;
  outlets: OutletSubProperty[] = [];
  selectedOutlet = 0;
  loadedOutlets: number[] = [];
  selectedOutletData: any[] = [];
  service: UntypedFormArray;
  noOfLocations: any;
  commissionMaxLength = GlobalConst.Maxlength.FLATAMOUNT;
  isDisabled: string = 'true';
  screenName = 'THERAPIST';
  commonCaptions: any;
  placeholderFormat: string;
  public get IsOutletAlreadyLoaded() {
    return this.loadedOutlets.some(r => r == this.selectedOutlet);
  }
  outletItemArray = [];
  commissionClassId = 0;
  SelectType: any = GlobalConst.SelectType;
  modifiedCommissionRequest: any;
  sortBy = 0;
  isSortByAscending = true;
  isLoading = false;
  enableSave = false;
  isReadOnly = false;
  defaultSettings: GlobalConst.DefaultSettings[] = [];
  floatLabel:string;
  isCopyTherapist:boolean =false;
  isEnableSave:boolean = true;
  constructor(public spaconfig: SPAConfig,
    private business: ViewSettingClientBusiness,
    public dialogRef: MatDialogRef<InstructorSetupComponent>,
    private Form: UntypedFormBuilder, private dialog: MatDialog, private http: HttpServiceCall,
    private utilities: SpaUtilities,
    private domSanitizer: DomSanitizer,
    private imgService: ImageProcessorService,
    public localization: SpaLocalization,
    private BP: BreakPointAccess,
    private propertyInfo: SpaPropertyInformation,
    private settingsService: SettingsService,
    public _ViewMoreServiceService: ViewMoreServiceService,
    private commissionService: CommissionSetupService) { //instead emit the commission data
    this.commonCaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.localize = this.spaconfig.captions.setting;
    this.textmaskFormat = this.localization.captions.common.PhoneFormat !== '' ? this.localization.captions.common.PhoneFormat : '999999999999999999';
    this.IsActiveModel = true;
    this.textToDisplayed = this.localization.captions.common.NoDataFound;
    this.defaultSettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
    this.FormGrp = this.Form.group({
      IsActive: true,
      StaffCode: ['', Validators.required],
      FirstName: ['', [Validators.required, EmptyValueValidator]],
      LastName: ['', [Validators.required, EmptyValueValidator]],
      Gender: ['', Validators.required],
      DateOfHire: this.propertyInfo.CurrentDate,
      Date: '',
      SeniorityLevel: '',
      AlsoKnownAs: '',
      HourlyRate:'',
      CommissionId: '',
      displayOnAppointmentGrid: true,
      PhoneNumbers: this.Form.group({
        PhoneNumberLabel: '',
        countryCode: '',
        PhoneNumber: '',
        Extension: ''

      }),
      TSpi: '',
      Email: this.Form.array([this.createEmailItem(0, '', '', null, true)]),
      Phone: this.Form.array([this.createPhoneItem(0, '', '', '', null,null, true)]),
      TSei: '',
      Allowgratuity: '',
      AllowServiceCharge: '',
      AllowCommission: '',
      ShowInactive: '',
      CommissionType: '',
      selectedCC: '',
      Threshold: '',
      listOrder: '',
      CommissionValue: '',
      services: this.Form.array([])
    });
    this.emailIdArray.push(this.FormGrp.get('items'));
    this.business.activeFormGroup = this.FormGrp;
    this.IsGenderInValid = this.FormGrp.get('Gender').value === '' ? true : false;
  }
  CommissionValidation(eve) {
    let returnflag = true;
    const data = eve.target.value + '' + eve.key;
    if (this.relativePlaceholder === 'Enter Percentage') {
      returnflag = (parseInt(data) < 1000) ? true : false;
    }
    return returnflag;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingInstructorSetup]);
    this.isViewOnly = this.BP.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.SettingInstructorSetup);
    this.isCommisionViewOnly = this.BP.IsViewOnly(GlobalConst.SPAManagementBreakPoint.StaffCommissionSetup);
    this.isCommisionUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAManagementBreakPoint.StaffCommissionSetup], false);
    if (this.isViewOnly) {
      this.utilities.disableControls(this.FormGrp);
    }
  }
  createEmailItem(arr: number, _EmailLabel?: any, _EmailId?: any, _existingId?: number, isInitial?: boolean): UntypedFormGroup {
    if (isInitial && (!_EmailLabel || _EmailLabel == '')) {
      let emailLabel = this.defaultSettings.find(x => x.switch == 'DEFAULT_EMAIL_TYPE');
      _EmailLabel = emailLabel.value ? Number(emailLabel.value) : '';
    }


    return this.Form.group({
      EmailLabel: _EmailLabel,
      EmailId: { value: _EmailId, disabled: !_EmailLabel },
      id: _existingId
    });
  }

  addEmailItem(i, _EmailLabel?: any, _EmailId?: any, _existingId?: number): void {
    this.Email = this.FormGrp.get('Email') as UntypedFormArray;
    const email: any = this.contactEmailType;
    const emailTemp = email ? email[0].id : '';
    this.Email.push(this.createEmailItem(i, _EmailLabel !== '' ? _EmailLabel : emailTemp, _EmailId, _existingId));
  }

  removeEmailItem(i: any) {
    this.Email.removeAt(i);
  }

  removePhoneItem(i: any) {
    this.Phone.removeAt(i);
  }

  createPhoneItem(arr: number, _phoneNoLabel: any, _countryCode: any, _phoneNoDetails: any, _existingId?: number, extension?: any, isInitial?: boolean): UntypedFormGroup {


    if (isInitial && _countryCode == '') {
      let countryCode = this.defaultSettings.find(x => x.switch == 'DEFAULT_COUNTRY_CODE');
      _countryCode = countryCode.value && Number(countryCode.value) > 0 ? countryCode.value : '';
    }
    if (isInitial && (!_phoneNoLabel || _phoneNoLabel == '')) {
      let phoneNoLabel = this.defaultSettings.find(x => x.switch == 'DEFAULT_PHONE_TYPE');
      _phoneNoLabel = phoneNoLabel.value ? Number(phoneNoLabel.value) : '';
    }

    return this.Form.group({
      PhoneNumberLabel: _phoneNoLabel,
      countryCode: { value: this.utilities.appendLocalizedFormat(_countryCode, ''), disabled: !_phoneNoLabel },
      PhoneNumber: { value: this.utilities.appendLocalizedFormat(_phoneNoDetails, this.localization.captions.common.PhoneFormat), disabled: !_phoneNoLabel },
      id: _existingId,
      Extension: extension
    });
  }
  addPhoneItem(i: any, _phoneNoLabel: any, _countryCode, _phoneNoDetails: any, _existingId?: any, extension: any = ''): void {
    this.Phone = this.FormGrp.get('Phone') as UntypedFormArray;
    const contact: any = this.contactPhoneType;
    const extensionNo = extension === '' ? '' : ('' + extension);
    const _countrycode: any = _countryCode;
    const contactTemp = contact ? contact[0].id : '';
    this.Phone.push(this.createPhoneItem(i, _phoneNoLabel !== '' ? _phoneNoLabel : contactTemp, _countrycode, _phoneNoDetails, _existingId, extensionNo));
  }

  // enabling the extension only if the selected value is 'Office'
  enableExtension(index: number): boolean {
    const phoneControlsArr: any = this.FormGrp.get('Phone') as UntypedFormArray;
    const phoneNoSelectedValue: any = phoneControlsArr.at(index).controls['PhoneNumberLabel'].value;
    let officeDesc = '';
    if (phoneNoSelectedValue && this.contactPhoneType) {
      const officeNo = this.contactPhoneType.find(d => d['id'] === phoneNoSelectedValue);
      officeDesc = officeNo ? officeNo['description'] : '';
    }
    return (this.localization.captions.common.Work.toLowerCase() === officeDesc.toLowerCase());
  }

  genderClick(): void {
    this.IsGenderInValid = false;
    this.genderMissing = false;
  }

  EditRecords(e: any): void {
    let popupConfiguration: popupConfig;
    const data = _.cloneDeep(e[0]);
    const ids: any = this.selectedOutletData.map(x => x.id);
    const nextId = Math.min(...ids) - 1;
    popupConfiguration = {
      operation: e[1],
      fromScreen: this.screenName
    };
    if (e[1] === 'new') {
      data.id = nextId;
      data.flatAmount = 0.00;
      data.threshold = 0.00;
      data.percentage = 0.00;
    }
    this.OpenDialog('Commission', 'CO', data, popupConfiguration, this.selectedOutletData.indexOf(e[0]));
  }

  BuildCommissionPercentageData() {
    const zero = 0;
    let outletName = this.outlets.find(x => x.subPropertyID == this.selectedOutlet).subPropertyName;
    this.selectedOutletData = this.CommissionItemArr.map(x => {
      return {
        id: x.id, therapistId: zero, categoryId: x.categoryId, itemId: x.itemId, classId: zero,
        clerkId: 0, flatAmount: x.flatAmount.customToFixed(), percentage: this.localization.localizeCurrency(x.percentage, false), threshold: x.threshold.customToFixed(),
        isAfterDiscount: x.isAfterDiscount, isTotalRevenue: x.isTotalRevenue,
        isItemOnly: x.isItemOnly,
        locationDescription: outletName,
        itemDescription: x.itemDescription,
        commissionon: x.isTotalRevenue === true ? this.captions.setting.Revenue : this.captions.setting.Items,
        staffType: x.staffType, referenceId: this.SelectedTherapistId,
        outletId: x.outletId
      };
    });
    this.CPData.push(...this.selectedOutletData);
  }

  async GetCommissionData() {
    this.selectedOutletData = this.CPData.filter(r => r.outletId == this.selectedOutlet);
    if (!this.IsOutletAlreadyLoaded || this.selectedOutletData.length == 0) {
      let param = { 'referenceType': 'THERAPIST', 'referenceId': this.SelectedTherapistId, 'productId': GlobalConst.Product.SPA, 'outletId': this.selectedOutlet };
      this.CommissionItemArr = await this.getTherapistDataAsync('GetCommissionbyType', param);
      this.BuildCommissionPercentageData();
    }
    else {
      this.items = this.outletItemArray.find(r => r.key == this.selectedOutlet).value;
    }
    this.populateTableInfo(this.selectedOutletData);
  }

  async commissionTypeChange(data) {
    if (data.value != 'CP') {
      return;
    }
    this.selectedOutletData = this.CPData.filter(r => r.outletId == this.selectedOutlet);
    if (!this.IsOutletAlreadyLoaded || this.selectedOutletData.length == 0) {
      let param = {};
      if (this.clickbutton === this.captions.setting.save) { // Create flow
        param = { 'referenceType': 'THERAPIST', 'referenceId': 0, 'productId': GlobalConst.Product.SPA, 'outletId': this.selectedOutlet };
      } else {   // Edit flow
        param = { 'referenceType': 'THERAPIST', 'referenceId': this.SelectedTherapistId, 'productId': GlobalConst.Product.SPA, 'outletId': this.selectedOutlet };
      }
      this.CommissionItemArr = await this.getTherapistDataAsync('GetCommissionbyType', param);
      this.BuildCommissionPercentageData();
    }
    else {
      this.items = this.outletItemArray.find(r => r.key == this.selectedOutlet).value;
    }
    this.populateTableInfo(this.selectedOutletData);
  }

  OpenDialog(title: any, templateName: any, e: any, popupConfiguration: any, index: any) {
    const Dialogtitle = title;
    const dialogRef = this.dialog.open(SettingDialogPopupCommonComponent, {
      height: '66%',
      width: '661px',
      data: { headername: Dialogtitle, closebool: true, templatename: templateName, datarecord: e, popupConfig: popupConfiguration },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });

    this.settingDialogSubscription = dialogRef.afterClosed().subscribe(result => {

      if (result) {
        const data = result.result;
        if (result.action === 'new') {
          var newData = {
            id: 0, therapistId: data.therapistId, categoryId: data.categoryId, itemId: data.itemId, classId: 0,
            clerkId: data.clerkId, flatAmount: data.flatAmount,
            percentage: data.percentage, threshold: data.threshold,
            isAfterDiscount: data.isAfterDiscount, isTotalRevenue: data.isTotalRevenue, isItemOnly: data.isItemOnly,
            itemDescription: data.itemDescription, locationDescription: data.locationDescription,
            commissionon: data.isTotalRevenue === true ? this.captions.setting.Revenue : this.captions.setting.Items,
            outletId: data.outletId
          };
          this.selectedOutletData.push(newData);
          this.CPData.push(newData);
        } else if (result.action === 'edit') {
          const edited = this.selectedOutletData[index];
          edited.threshold = data.threshold;
          edited.percentage = data.percentage;
          edited.flatAmount = data.flatAmount;
          edited.isItemOnly = data.isItemOnly;
          edited.isTotalRevenue = data.isTotalRevenue;
          edited.isAfterDiscount = data.isAfterDiscount;
          edited.commissionon = data.isTotalRevenue === true ? this.captions.setting.Revenue : this.captions.setting.Items;
          // Reset master data for current outlet
          this.CPData = this.CPData.filter(r => r.outletId != edited.outletId);
          this.CPData.push(...this.selectedOutletData);
        }
        this.FormGrp.markAsDirty();
        this.populateTableInfo(this.selectedOutletData);
      }

    });
  }


  openFilterDialog(ArrType): void {
    const popupLeft = document.getElementById('TherapistSetup');
    const d = document.getElementById(ArrType);
    let topPos = d.offsetTop - 34,
      leftPos = popupLeft.offsetLeft + 16;
    this.DataType = 'commissionitem';
    if (ArrType === 'Category') {
      this.DataArray = this.CommissioncategoryArr;
      this.selectedArr = this.selectedcategoryArr;
    }
    if (ArrType === 'item') {
      topPos = topPos + 211;
      leftPos = leftPos + 24;
      this.DataArray = this.items;
      this.selectedArr = this.filterItemArr;
    }
    if (ArrType === 'servicegrp') {
      this.DataArray = this.TherSerGrp;
      this.selectedArr = this.selectedSerGrp;
    }

    this.filterOptions = this.utilities.getFilterPopUpOption(true, this.DataArray, this.selectedArr, topPos, leftPos, ArrType, 'TS');
  }

  filteredDataResponse(event: any) {
    let result: any = event[0];
    const ArrType = event[1];
    if (!result) { return; }
    result = result ? result : [];
    const ids = result.map(x => x.id ? x.id : x);
    if (ArrType === 'Category') {
      this.filterCategoryArr = ids;
      this.selectedcategoryArr = ids;
    } else if (ArrType === 'item') {
      this.filterItemArr = ids;
      this.selectedItemArr = ids;
    }
    if (ArrType === 'servicegrp') {
      this.filterSerGrpArr = ids;
      this.selectedSerGrp = ids;
    }
    this.populateTableInfo(this.filterCommissions());
  }
  ngOnInit() {
    this.noDataOptions = { inputText: this.localization.captions.common.NoDataFound, noDataId: GlobalConst.noRecordsType.noRecords, noDataFontSize: 10 };
    this.contactPhoneType = this.localization.ContactTypes.Phone;
    this.contactEmailType = this.localization.ContactTypes.Email;
    this.ValidateBreakPoint();
    this.getTherapistData<ComboOptions>('GetAllTherapist',{ key: "isInstructor", value:  [true]});
    this.clickbutton = typeof this.dataInput === 'undefined' ? this.captions.setting.save : this.captions.setting.update;
    this.isReadOnly = typeof this.dataInput === 'undefined' ?false:true;
    this.dataInputChanges();
    
    
  }
  instructorCodeSelection(item) {
    let id: number;
    for (let therapistYieldJsonItem of this.therapistYieldJson) {
      if (therapistYieldJsonItem.code === item) {
        id = therapistYieldJsonItem.id;
        break;
      }
    }
    // let Phone2 = this.FormGrp.get('Phone') as UntypedFormArray;
    // let email2 = this.FormGrp.get('Email') as UntypedFormArray;
    // Phone2.clear();
    // email2.clear();
    // this.FormGrp.reset();
    // this.FormGrp.removeControl('Phone');
    // this.FormGrp.addControl('Phone',this.Form.array([this.createPhoneItem(0, '', '', '', null,null, true)]));
    // this.FormGrp.removeControl('Email');
    // this.FormGrp.addControl('Email',this.Form.array([this.createEmailItem(0, '', '', null, true)]));
    this.isCopyTherapist=true;
    this.CopyTherapist(id);
    
  }
  CopyTherapist(id: number): any {
    this.http.CallApiWithCallback<number>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: 'GetTherapistAggregate',
      method: HttpMethod.Get,
      uriParams: { id: id },
      showError: true,
      extraParams: []
    });
  }
  dataInputChanges()
  {
    if (typeof this.dataInput !== 'undefined') {
      this.blnReadOnly = true;
      this.FormGrp.patchValue(this.dataInput.therapistDetail);
      const tDetails: TherapistDetails = <TherapistDetails>(this.dataInput.therapistDetail);
      this.IsAllowCommissionChecked = tDetails.allowCommission;
      this.IsAllowGratuityChecked = tDetails.allowGratuity;
      this.IsAllowServiceChargeChecked = tDetails.allowServiceCharge;
      this.SelectedTherapistId = tDetails.id;
      this.existingLisOrder = tDetails.listOrder;

      this.genderClick();
      this.FormGrp.patchValue({
        IsActive: tDetails.isActive,
        StaffCode: tDetails.code,
        FirstName: tDetails.firstName,
        LastName: tDetails.lastName,
        Gender: tDetails.gender,
        DateOfHire:tDetails.dateOfHire? this.utilities.getDate(tDetails.dateOfHire):"",
        SeniorityLevel: tDetails.seniorityLevel,
        AlsoKnownAs: tDetails.alsoKnownAs,
        HourlyRate:this.localization.localizeCurrency( tDetails.hourlyRate.toString(),true),
        Allowgratuity: tDetails.allowGratuity,
        AllowServiceCharge: tDetails.allowServiceCharge,
        AllowCommission: tDetails.allowCommission,
        listOrder: tDetails.listOrder
      });
      this.IsActiveModel = tDetails.isActive;
      if(!this.isCopyTherapist)
      {
              this.imgService.GetImagesByReference(this.SelectedTherapistId, GlobalConst.ImgRefType.therapist, this.successCallback.bind(this), this.utilities.errorCallback.bind(this), []);
              this.BindContactInformation(this.dataInput.therapistContact);
      }
      }
    else {
      this.blnReadOnly = false;
      this.getTherapistData('getMaxListOrderTherapist',{ key: "isInstructor", value:  [true]});
      this.SelectedRadioBut = 'CC';
      this.FormGrp.controls.selectedCC.setValue('');
    }
    
    this.DispSec = 'TD';
    this.phoneNumArr = [''];
    this.emailArr = [''];
    this.TherSerGrp = [];
    this.selectedSerGrp = [];
    this.SIS = false;
    this.TSCAIcon = false;
    this.getTherapistData<ServiceDetails>('GetAllSpaService',{ key: "isClass", value:  [true]});
    this.getTherapistData<ServiceAddOns>('GetAllAddOns',{ key: "isClass", value:  [true]});
    this.getTherapistData<ServiceGroup[]>('GetAllServiceGrp',{ key: "isClassGroup", value:  [true]});
    this.GetOutlets();
    this.checkContactDetails();
    if(this.isCopyTherapist)
    {
      this.blnReadOnly=false;
    }
  }

  checkContactDetails() {
    const phone = this.FormGrp.get('Phone') as UntypedFormArray;
    phone.controls.forEach((obj: UntypedFormGroup) => {
      if (!obj.value['PhoneNumberLabel']) {
        obj.get('countryCode').disable();
        obj.get('PhoneNumber').disable();
      }
    });

    const email = this.FormGrp.get('Email') as UntypedFormArray;
    email.controls.forEach((obj: UntypedFormGroup) => {
      if (!obj.value['EmailLabel']) {
        obj.get('EmailId').disable();
      }
    });
  }
  createServicesData(): UntypedFormGroup {
    return this.Form.group({
      seniorityLevel: '',
      maximumNumberOfServices: '',
    });
  }

  addServicesData(i): void {
    this.service = this.FormGrp.get('services') as UntypedFormArray;
    this.service.push(this.createServicesData());
  }

  ngOnDestroy() {
    if (this.settingDialogSubscription) {
      this.settingDialogSubscription.unsubscribe();
    }
    this.commissionService.commissionGridData = [];
  }

  populateTableInfo(gridData) {
    this.tableoptions = [{
      TableHdrData: this.business.getDataheader(GlobalConst.GridType.commission),
      TablebodyData: gridData,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.setting.DescriptionLocationCommissionon,
      EnableActions: false,
      SelectRows: true,
      Searchable: true,
      EditMoreOption: true,
      ServiceId: GlobalConst.GridType.therapist,
      SelectedSettingId: GlobalConst.GridType.commission,
      IsCommission: true,
      Sortable: 'itemDescription',
      TableId: GlobalConst.GridType.group
    }];
  }

  nextbutton(N?: any): void {
    this.DispSec = N;
  }

  ToSpecialities(): void {

    if (this.IsServicesUpdated) {
      let level = 0;
      this.TherSerGrpDatas[0].forEach(element => {
        level = parseInt(this.FormGrp.controls['SeniorityLevel'].value);
        element.seniorityLevel = isNaN(level) ? 0 : level;
      });
    }
  }
  previousButton(N: any): void {
    this.DispSec = N;
  }

  updateSerGrpArr(ga: any, gv: any): void {
    if (gv === 'Add-ons') {
      this.isDisplayAddOn = !this.isDisplayAddOn;
      this.TSCAIcon = false;
    }
    if (ga.indexOf(gv) === -1) {
      ga.push(gv);
    } else {
      ga.splice(ga.indexOf(gv), 1);
    }
    this.toggleserDet(false, '', 'addon');
    this.filterSerGrpArr = ga;
    this.populateTableInfo(this.filterCommissions());
  }

  async togglecerAll(res: any) {
    this.TSCAIcon = !res;
    if (!this.isDisplayAddOn) {
      if (!res) {
        for (let i = 0; i < this.TherSerGrpDatas[0].length; i++) {
          if (this.TherSerGrpDatas[0][i].serStatus === 'Active') {
            this.TherSerGrpDatas[0][i].certified = !res;
          }
        }
      }
      else {
        let serviceAppointments = await this.IsTherapistServicesInUse(this.TherSerGrpDatas[0].map(y => y.id));
        for (let i = 0; i < this.TherSerGrpDatas[0].length; i++) {
          if (this.TherSerGrpDatas[0][i].serStatus === 'Active') {
            this.TherSerGrpDatas[0][i].certified = serviceAppointments.filter(x => x == this.TherSerGrpDatas[0][i].id).length > 0 ? res : !res;
          }
        }
        if (serviceAppointments.length > 0) {
          this.utilities.ShowErrorMessage(this.captions.common.Error, this.captions.setting.TherapistServicesUsedInAppointment, GlobalConst.ButtonType.Ok);
        }
      }
    } else {
      for (let i = 0; i < this.TherAddOnData[0].length; i++) {
        this.TherAddOnData[0][i].certified = this.TSCAIcon;
      }
    }
  }

  SetSeniorityLevel($event: any, k: any): void {
    this.TherSerGrpDatas[0][k].seniorityLevel = $event.target.value;
  }

  SetMaximumValue($event: any, k: any): void {
    this.oldServiceCount = this.TherSerGrpDatas[0][k].maximumNumberOfServices;
    this.TherSerGrpDatas[0][k].maximumNumberOfServices = $event.target.value;
    if (this.blnReadOnly && $event.target.value != 0 && this.oldServiceCount != $event.target.value) {
      this.http.CallApiWithCallback<any>({
        host: GlobalConst.Host.schedule,
        success: this.successCallback.bind(this),
        error: this.utilities.errorCallback.bind(this),
        callDesc: "classInstructorclassLimitation",
        method: HttpMethod.Get,
        uriParams: { instructorId: this.SelectedTherapistId, classId: this.TherSerGrpDatas[0][k] != null ? this.TherSerGrpDatas[0][k].id : 0, classcount: $event.target.value },
        showError: true,
        extraParams: []
      });
    }
  }

  async toggleserDet(isCertifyChange: boolean, k: any, certifyFor: any) {
    if (certifyFor === 'service') {
      if (isCertifyChange) {
        const isAppointmentexists = await this.IsTherapistServiceInUse(this.TherSerGrpDatas[0][k].id)
        if (isAppointmentexists) {
          this.utilities.ShowErrorMessage(this.captions.common.Error, this.captions.setting.TherapistServiceUsedInAppointment, GlobalConst.ButtonType.Ok);
          return;
        }
      }
      if (k !== '') {
        this.TherSerGrpDatas[0][k].certified = !this.TherSerGrpDatas[0][k].certified;
      }
      const certifiedServices = this.TherSerGrpDatas[0].filter(data => { if (data.certified) { return data.certified } });
      if (this.TherSerGrpDatas[0].length === certifiedServices.length) {
        this.TSCAIcon = true;
      } else {
        this.TSCAIcon = false;
      }


    } else if (certifyFor === 'addon') {
      if (isCertifyChange) {
        const isAddonexists = await this.IsTherapistAddonInUse(this.TherAddOnData[0][k].id)
        if (isAddonexists) {
          this.utilities.ShowErrorMessage(this.captions.common.Error, this.captions.setting.TherapistAddonUsedInAppointment, GlobalConst.ButtonType.Ok);
          return;
        }
      }
      if (k !== '') {
        this.TherAddOnData[0][k].certified = !this.TherAddOnData[0][k].certified;
      }
      const certifiedAddons = this.TherAddOnData[0].filter(data => { if (data.certified) { return data.certified } });
      if (this.TherAddOnData[0].length === certifiedAddons.length) {
        this.TSCAIcon = true;
      } else {
        this.TSCAIcon = false;
      }
    }
  }


  async IsTherapistServiceInUse(serviceId: number): Promise<boolean> {
    const results = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.schedule,
      callDesc: 'IsInstructorClassInUse',
      method: HttpMethod.Get,
      uriParams: { instructorId: this.SelectedTherapistId, classId: serviceId }
    });
    return results.result;
  }

  async IsTherapistServicesInUse(serviceId: number[]): Promise<number[]> {
    const results = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.schedule,
      callDesc: 'IsInstructorClassesInUse',
      method: HttpMethod.Get,
      uriParams: { instructorId: this.SelectedTherapistId }
    });
    return results.result;
  }

  async IsTherapistAddonInUse(addonId: number): Promise<boolean> {
    const results = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.schedule,
      callDesc: 'IsTherapistAddonInUse',
      method: HttpMethod.Get,
      uriParams: { therapistId: this.SelectedTherapistId, addonId: addonId }
    });
    return results.result;
  }

  OnOutletChange(outletId: number) {
    this.filterItemArr = [];
    this.selectedItemArr = [];
    this.selectedOutlet = outletId;
    this.GetCommissionData();
  }

  FormSubmit(e: any): void {
    this.isEnableSave = false;
    if (this.IsGenderInValid) {
      this.genderMissing = true;
    }
    if (e.currentTarget.innerText.toLowerCase() === this.captions.setting.save.toLowerCase()) {
      this.saveTherapistInfo();
    } else if (e.currentTarget.innerText.toLowerCase() === this.captions.setting.update.toLowerCase()) {
      this.updateTherapistInfo();
    }
  }

  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
    const file = event.target.files[0];
    // convert to MB
    const fileSize = file.size / (1024 * 1024);
    // Allow upload if size is lesser than or equal to 2 MB
    if (fileSize <= 2) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
      this.FormGrp.markAsDirty();
    } else {
      this.business.openErrorDialog(this.captions.common.FileSizeExceeded, this.captions.common.Error, this.captions.common.Error);
      this.url = './assets/images/greenish-wide.jpg';
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.bindImage(this.selectedFile.type, this.base64textString);
  }
  onFileDelete(event: any) {
    this.isImageRemoved = true;
    this.ImageUploaded = false;
    this.FormGrp.markAsDirty();
  }

  fileDeleted() {
    this.isImageRemoved = true;
    this.ImageUploaded = false;
    this.FormGrp.markAsDirty();
  }
  fileUploaded(data) {
    this.base64textString = data['orgImg'];
    this.thumbnailImg = data['tmbImg'];
    this.FormGrp.markAsDirty();
  }
  fileSizeExceeded() {
    this.business.openErrorDialog(this.captions.common.FileSizeExceeded, this.captions.common.Error, this.captions.common.Error);
  }


  getTherapistData<T>(routeURL: string, keyValue:any, dataBelongTo?: any): void {
    
    this.http.CallApiWithCallback<T[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: routeURL,
      method: HttpMethod.Get,
      showError: true,
      extraParams: [dataBelongTo],
      queryString: keyValue
    });

  }

  async LoadCommissionableRetailItems(): Promise<CommissionableRetailItem[]> {
    this.commissionableItems = [];
    const response = await this.http.CallApiAsync<CommissionableRetailItem[]>({
      host: GlobalConst.Host.retailManagement,
      callDesc: 'GetCommissionItems',
      method: HttpMethod.Get,
      uriParams: { outletId: this.selectedOutlet },
    });

    if (response && response.result) {
      for (let responseItem of response.result) {
        this.commissionableItems.push(responseItem);
      }
      return this.commissionableItems;
    }
    this.commissionableItems = [];
    return this.commissionableItems;
  }

  async getTherapistDataAsync(routeURL: any, uriParams?: any) {
    const gridData: RetailCommission[] = [];
    const results = await this.http.CallApiAsync<Commission[]>({
      host: GlobalConst.Host.commission,
      callDesc: routeURL,
      method: HttpMethod.Get,
      uriParams: uriParams
    });

    const data: Commission[] = results.result;
    if (data && routeURL === 'GetCommissionbyType') {
      const commissionItems: CommissionableRetailItem[] = await this.LoadCommissionableRetailItems();
      if (commissionItems) {
        commissionItems.forEach(item => {
          const commissionItem = data.filter(o => o.itemId === item.id);
          const commissionConfigured = (commissionItem != null && commissionItem.length > 0);
          if (commissionConfigured) {
            commissionItem.forEach(o => {
              gridData.push({
                id: (o.id),
                categoryId: item.categoryId ? item.categoryId : 0,
                itemDescription: item.description,
                locationDescription: 'Visual One Spa',
                threshold: (o.threshold),
                flatAmount: (o.flatAmount),
                percentage: (o.percentage),
                classId: (o.classId),
                isAfterDiscount: (o.isAfterDiscount),
                isItemOnly: (o.isItemOnly),
                isTotalRevenue: (o.isTotalRevenue),
                itemId: item.id,
                propertyId: (o.propertyId),
                subPropertyId: (o.subPropertyId),
                staffId: (o.staffId),
                staffType: (o.staffType),
                productId: GlobalConst.Product.SPA,
                outletId: o.outletId
              });
            });
          } else {
            gridData.push({
              id: 0,
              categoryId: 0,
              itemDescription: item.description,
              locationDescription: 'Visual One Spa',
              threshold: 0,
              flatAmount: 0,
              percentage: 0,
              classId: 0,
              isAfterDiscount: false,
              isItemOnly: false,
              isTotalRevenue: false,
              itemId: item.id,
              propertyId: 0,
              subPropertyId: 0,
              staffId: 0,
              staffType: '',
              productId: GlobalConst.Product.SPA,
              outletId: this.selectedOutlet
            });
          }
        });
      }
      this.items = [];
      this.items.push({ 'id': 0, 'description': this.spaconfig.captions.bookAppointment.All });
      for (let gridDataItem of gridData) {
        if (!this.items.some(e => e.id === gridDataItem.itemId)) {
          this.items.push(
            {
              'id': gridDataItem.itemId,
              'description': gridDataItem.itemDescription
            }
          );
        }
      }
    }
    this.outletItemArray.push({
      key: this.selectedOutlet,
      value: this.items
    });
    this.loadedOutlets.push(this.selectedOutlet);
    return gridData;

  }

  getTherapistServiceData<T>(routeURL: string, dataBelongTo?: any): void {
    let isClass=true;
    let keyValue: any = { key: "isInstructor", value:  [isClass]};
    this.http.CallApiWithCallback<T[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: routeURL,
      uriParams: { id: dataBelongTo, includeServiceDetail: false },
      method: HttpMethod.Get,
      showError: true,
      extraParams: [dataBelongTo],
      queryString: keyValue
    });
  }

  saveTherapistInfo(): void {
    let tDetailObj: ThearapistDetails;

    tDetailObj = this.GetTherapistDetail();

    let tContactObj = this.formContactObject(this.therapistId);

    tContactObj = this.validateContact(tContactObj);

    const tCertifiedObj = this.formCertificationObject(this.therapistId);

    const therapistAddOn = this.FormTherpistAddOn(this.therapistId);

    const body = { id: this.therapistId, therapistDetail: tDetailObj, serviceTherapist: tCertifiedObj, therapistContact: tContactObj, therapistAddOn: therapistAddOn };
    let isClass=true;
    let keyValue: any = { key: "isInstructor", value:  [isClass]};
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'CreateTherapistDetails',
      method: HttpMethod.Post,
      body: body,
      showError: true,
      extraParams: [],
      queryString: keyValue
    });
  }

  updateTherapistInfo(): void {

    let tDetailObj: ThearapistDetails;
    tDetailObj = this.GetTherapistDetail();

    let tContactObj = this.formContactObject(this.SelectedTherapistId);

    tContactObj = this.validateContact(tContactObj);

    const tCertifiedObj = this.formCertificationObject(this.SelectedTherapistId);
    const therapistAddOn = this.FormTherpistAddOn(this.SelectedTherapistId);

    this.updateTherapistCommission = this.FormTherapistComission(this.SelectedTherapistId);

    const body = { id: this.SelectedTherapistId, therapistDetail: tDetailObj, serviceTherapist: tCertifiedObj, therapistContact: tContactObj, therapistAddOn: therapistAddOn };

    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'updateTherapistDetails',
      method: HttpMethod.Put,
      uriParams: { id: this.SelectedTherapistId },
      body: body,
      showError: true,
      extraParams: []
    });

  }

  private GetTherapistDetail(): ThearapistDetails {
    let tDetailObj: ThearapistDetails;
    this.commissionClassId = this.SelectedRadioBut === 'CC' && this.FormGrp.controls['selectedCC'].value !== '' ? Number(this.FormGrp.controls['selectedCC'].value) : 0;
    tDetailObj = {
      Code: this.FormGrp.controls['StaffCode'].value.toUpperCase(),
      FirstName: this.utilities.capitalizeFirstLetter(this.FormGrp.controls['FirstName'].value),
      LastName: this.utilities.capitalizeFirstLetter(this.FormGrp.controls['LastName'].value),
      Gender: this.FormGrp.controls['Gender'].value,
      DateOfHire: this.FormGrp.controls['DateOfHire'].value?this.localization.ConvertDateToISODateTime(this.FormGrp.controls['DateOfHire'].value):'',
      SeniorityLevel: this.FormGrp.controls['SeniorityLevel'].value,
      AlsoKnownAs: this.FormGrp.controls['AlsoKnownAs'].value,
      HourlyRate: this.localization.currencyToSQLFormat( this.FormGrp.controls['HourlyRate'].value.toString()),
      AllowGratuity: this.IsAllowGratuityChecked,
      AllowServiceCharge: this.IsAllowServiceChargeChecked,
      AllowCommission: this.IsAllowCommissionChecked,
      listOrder: this.FormGrp.controls['listOrder'].value,
      isActive: this.FormGrp.controls['IsActive'].value,
      commissionClassId: this.commissionClassId,
      displayOnAppointmentGrid: this.FormGrp.controls['displayOnAppointmentGrid'].value
    };

    // Setting list order, when manualy removed from control
    if (!tDetailObj.listOrder) {
      if (this.blnReadOnly) { // edit
        tDetailObj.listOrder = this.existingLisOrder;
      } else { // create
        tDetailObj.listOrder = this.maxLstOrder;
      }
    }
    return tDetailObj;
  }

  updateTherapistLinks(): void {
    const patchedContactDetailsBody = this.formContactPatchObject(this.SelectedTherapistId);
    // patching therapist contact info
    if (patchedContactDetailsBody.length > 0) {
      this.makePatchCall(patchedContactDetailsBody, 'updateTherapistContacts');
    }
    // update Therapist Certification
    this.updateTherapistCertification(this.SelectedTherapistId);
    this.dialog.closeAll();
  }


  updateTherapistCertification(therapistId: number): void {
    const oldCertification = this.existingCertificationArr;
    const newCertification = this.formCertificationObject(therapistId);
    const certificationPatchObj: PatchFormat[] = [];


    let patchFormat: PatchFormat;
    // to Remove patch
    for (let oldCertificationItem of oldCertification) {
      const element = oldCertificationItem;
      const _element = this.convertToPatchObj(element);

      patchFormat = {
        op: 'remove',
        path: '/Therapist',
        value: _element
      };
      certificationPatchObj.push(patchFormat);
    }
    // to Add patch
    for (let newCertificationItem of newCertification) {
      const element = newCertificationItem;
      const _element = this.convertToPatchObj(element);
      patchFormat = {
        op: 'add',
        path: '/Therapist',
        value: _element
      };
      certificationPatchObj.push(patchFormat);
    }
    this.makePatchCall(certificationPatchObj, 'updateTherapistServiceDetails');
  }

  convertToPatchObj(certificationObj: TherapistCertification): TherapistCertificationPatch {
    let returnObj: TherapistCertificationPatch;
    returnObj = {
      Id: 0,
      TherapistId: certificationObj.therapistId,
      SeniorityLevel: certificationObj.seniorityLevel,
      ServiceId: certificationObj.serviceId,
      MaximumNumberOfServices: certificationObj.maximumNumberOfServices
    };
    return returnObj;
  }

  makePatchCall(patchedDetails: any, callDesc: string): void {
    this.http.CallApiWithCallback<ThearapistDetails[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: callDesc,
      method: HttpMethod.Patch,
      uriParams: { id: this.SelectedTherapistId },
      body: patchedDetails,
      showError: true,
      extraParams: []
    });
  }

  formCertificationObject(currentTherapistid: number): any {

    const arrOfTherCert = this.TherSerGrpDatas[0];
    let bodyObj: TherapistCertification;
    const arrBodyObj = [];
    arrOfTherCert.forEach(element => {
      if (element.certified === true) {
        bodyObj = {
          serviceId: element.id,
          seniorityLevel: element.seniorityLevel,
          therapistId: currentTherapistid,
          maximumNumberOfServices: element.maximumNumberOfServices != "" ? element.maximumNumberOfServices : 0
        };
        arrBodyObj.push(bodyObj);
      }

    });

    // Assign exiting id's

    for (let arrBodyObjItem of arrBodyObj) {
      const item = this.existingCertificationArr.filter(r => {
        return r.serviceId === arrBodyObjItem.serviceId;
      });
      if (item.length > 0) {
        arrBodyObjItem.id = item[0].id;
      }
    }

    return arrBodyObj;
  }

  FormTherpistAddOn(currentTherapistid: number): any {
    const addOnArr: any = [];
    for (let TherAddOnDataItem of this.TherAddOnData[0]) {
      if (TherAddOnDataItem.certified) {
        const addOn: TherapistAddOn = {
          id: 0,
          therapistId: currentTherapistid,
          addOnId: TherAddOnDataItem.id
        };
        addOnArr.push(addOn);
      }
    }

    // Assign existing TherapistAddOn's Id for update
    if(!this.isCopyTherapist)
    {
    if (this.existingAddOns.length > 0 && addOnArr.length > 0) {
      for (let addOnArrItem of addOnArr) {
        const matchItem = this.existingAddOns.filter(r => {
          return r.addOnId === addOnArrItem.addOnId;
        });
        if (matchItem.length > 0) {
          addOnArrItem.id = matchItem[0].id;
        }
      }
    }
  }
    return addOnArr;
  }

  FormTherapistComission(therapistId) {
    let bodyObj: CommissionDetails;
    let therpistCommission: CommissionDetails[] = [];
    this.commissionService.commissionGridData = this.commissionService.commissionGridData.filter(r => r.isModified);
    for (let commissionData of this.commissionService.commissionGridData) {
      bodyObj = {
        id: commissionData.id,
        itemId: commissionData.itemId,
        categoryId: commissionData.categoryId,
        itemDescription: commissionData.itemDescription,
        locationDescription: commissionData.locationDescription,
        classId: 0,
        flatAmount: commissionData.flatAmount,
        percentage: commissionData.percentage,
        threshold: commissionData.threshold,
        isAfterDiscount: commissionData.isAfterDiscount,
        isTotalRevenue: commissionData.commissionon == this.captions.setting.Revenue ? true : false,
        isItemOnly: commissionData.commissionon == this.captions.setting.Items ? true : false,
        productId: GlobalConst.Product.SPA,
        outletId: commissionData.outletId,
        staffType: 'THERAPIST',
        staffId: therapistId
      }
      therpistCommission.push(bodyObj);
    }
    return therpistCommission;
  }



  onPhChange($event, i, item) {
    item['controls']['PhoneNumber'].enable();
    item['controls']['countryCode'].enable();
    item['controls']['PhoneNumberLabel'].clearValidators();
    const selectedPhoneType = $event.value;
    this.isphoneType = selectedPhoneType;
    this.selectedPhoneType.push({ id: $event.source.id, comboSelected: selectedPhoneType });
    this.countryCodeRequiredCheck(i);
    //settingEmptyvalues 
    if (!$event.value) {
      item.reset();
      item['controls']['PhoneNumber'].disable();
      item['controls']['countryCode'].disable();
      item['controls']['PhoneNumberLabel'].clearValidators();
      item.markAsDirty();
    }
    item.updateValueAndValidity();
  }


  countryCodeRequiredCheck(index) {
    if (this.FormGrp.get('PhoneNumberLabel')) {
      if (this.FormGrp.get('PhoneNumberLabel').value === PhoneTypes.mobile && this.FormGrp.get('PhoneNumber').value) {
        this.FormGrp.controls['countryCode'].setValidators([Validators.required]);
      } else {
        this.FormGrp.controls['countryCode'].clearValidators();
      }
      this.FormGrp.controls['countryCode'].updateValueAndValidity();
    } else if (this.FormGrp.get('Phone')) {
      const phoneControl = this.FormGrp.controls.Phone['controls'][index]['controls'];
      if (phoneControl['PhoneNumberLabel'].value === PhoneTypes.mobile) {
        phoneControl['countryCode'].setValidators([Validators.required]);
      } else {
        phoneControl['countryCode'].clearValidators();
      }
      phoneControl['countryCode'].updateValueAndValidity();
    }
  }

  onEmailChange($event, i, item) {
    item['controls']['EmailId'].enable();
    if (!$event.value) {
      item.reset();
      item['controls']['EmailId'].disable();
      item.markAsDirty();

    }
  }

  formContactPatchObject(therapistId: number): any {
    let patchFormat: PatchFormat;
    const contactPatchObj = [];
    const UpdatedContactObj = this.formContactObject(therapistId);
    for (let existingContactArrItem of this.existingContactArr) {
      const element = existingContactArrItem;
      {
        patchFormat = {
          op: 'remove',
          path: '/' + element.Type,
          value: element
        };
        contactPatchObj.push(patchFormat);
      }
    }
    for (let i = 0; i < UpdatedContactObj.length; i++) {
      const element = UpdatedContactObj[i];
      if (element.ContactDetail !== '') {
        patchFormat = {
          op: 'add',
          path: '/' + element.Type,
          value: this.formContactObject(therapistId)[i]
        };
        contactPatchObj.push(patchFormat);
      }
    }
    return contactPatchObj;
  }

  formContactObject(currentTherapistId: number): TherapistContact[] {
    const therapistContactBodyObj: TherapistContact[] = [];
    const phoneControlFrmArr: any = this.FormGrp.get('Phone');
    const phoneArr = phoneControlFrmArr.controls;
    const emailControlFrmArr: any = this.FormGrp.get('Email');
    const emailArr = emailControlFrmArr.controls;

    for (let phoneArrItem of phoneArr) {
      if (phoneArrItem.value['PhoneNumberLabel']) {
        const phoneNumber = phoneArrItem.value['PhoneNumber'] ? (phoneArrItem.value['PhoneNumber']).replace(/\D/g, '') : '';
        const countryCode = (phoneArrItem.value['countryCode']).replace('', '');
        const contactDetail = phoneNumber ? countryCode + '|' + phoneNumber : "";
        const phoneObj: TherapistContact = {
          TherapistId: currentTherapistId,
          ContactLocationId: phoneArrItem.value['PhoneNumberLabel'],
          ContactDetail: contactDetail,
          Type: 'PHONE',
          Id: phoneArrItem.value['id']&& !this.isCopyTherapist ? phoneArrItem.value['id'] : 0,
          Extension: phoneArrItem.value['Extension'] ? phoneArrItem.value['Extension'].replace('', '') : '',
        };
        therapistContactBodyObj.push(phoneObj);
      }
    }

    for (let emailArrItem of emailArr) {
      if (emailArrItem.value['EmailLabel']) {
        const emailObj: TherapistContact = {
          TherapistId: currentTherapistId,
          ContactLocationId: emailArrItem.value['EmailLabel'],
          ContactDetail: emailArrItem.value['EmailId'],
          Id: emailArrItem.value['id'] && !this.isCopyTherapist ? emailArrItem.value['id'] : 0,
          Type: 'EMAIL'
        };
        therapistContactBodyObj.push(emailObj);
      }
    }
    return therapistContactBodyObj;
  }

  InactiveTherapist(e: any): void {
    this.getTherapistData<ComboOptions>('GetAllTherapist',{ key: "isInstructor", value:  [true]});
  }

  validateContact(Contactobj: Array<any>): Array<any> {
    const returnArr = [];

    for (let ContactobjItem of Contactobj) {
      if (ContactobjItem.ContactDetail !== '' && ContactobjItem.ContactLocationId !== '') {
        returnArr.push(ContactobjItem);
      }
    }
    return returnArr;
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    if (callDesc === 'GetAllServiceGrp') {
      this.TherSerGrp = <any>result.result;
    } else if (callDesc === 'CreateTherapistDetails') {
      this.therapistId = <any>result.result;

      if (typeof this.base64textString !== 'undefined') {
        const base64result = this.base64textString.split(',');
        const base64Thumbnail = this.thumbnailImg ? this.thumbnailImg.split(',') : '';
        const imageDataObj: ImageData = {
          referenceId: this.therapistId,
          referenceType: GlobalConst.ImgRefType.therapist,
          data: base64result[1],
          id: 0,
          thumbnailData: base64Thumbnail ? base64Thumbnail[1] : '',
          contentType: base64result[0],
          sequenceNo: 0
        };
        this.imgService.saveImage(imageDataObj, this.successCallback.bind(this), this.errorCallback.bind(this), []);
      }

      if (this.SelectedRadioBut === 'CP') {
        const allCommissionCount = this.commissionService.commissionGridData.filter(r => r.isModified).length;
        const modifiedCommissionCount = this.commissionService.commissionGridData.length;
        if (allCommissionCount == modifiedCommissionCount && this.modifiedCommissionRequest && this.commissionService?.commissionGridData) {
          this.UpdateRetailCommissionSetUpByFilter();
        }
        else {
          this.updateTherapistCommission = this.FormTherapistComission(this.therapistId);
          if (this.updateTherapistCommission.length > 0) {
            this.InvokeServiceCall('UpdateCommission', GlobalConst.Host.commission, HttpMethod.Put, { classId: 0 }, this.updateTherapistCommission);
          }
        }
      }

      this.dialog.closeAll();


    } else if (callDesc === 'GetAllSpaService') {
      this.TherSerGrpDatas=[];
      let resultObj: any = result.result;
      if (resultObj == null) {
        resultObj = [];
      }

      for (let resultObjItem of resultObj) {
        if (resultObjItem.isActive === true) {
          resultObjItem['serStatus'] = 'Active';
        } else {
          resultObjItem['serStatus'] = 'In-Active';
        }
        resultObjItem['certified'] = false;
        resultObjItem['seniorityLevel'] = 0;
        resultObjItem['maximumNumberOfServices'] = 0;
      }
      this.TherSerGrpDatas.push(resultObj);

      for (let i = 0; i < this.TherSerGrpDatas[0].length; i++) {
        this.addServicesData(i);
      }
      this.IsServicesUpdated = true;


      if (this.SelectedTherapistId > 0) {
        this.getTherapistServiceData('getTherapistCertification', this.SelectedTherapistId);
      }
    } else if (callDesc === 'getTherapistCertification') {
      this.existingCertificationArr=[];
      const tServiceObj = <any>result.result;
      let existingCertificationObj: TherapistCertification;

      let index = 0;
      tServiceObj.forEach(newTservice => {
        index = 0;
        this.TherSerGrpDatas[0].forEach((oldTService, i) => {
          if (newTservice.serviceId === oldTService.id) {
            // for patching
            existingCertificationObj = {
              therapistId: newTservice.therapistId,
              seniorityLevel: parseInt(newTservice.seniorityLevel),
              serviceId: newTservice.serviceId,
              id: this.isCopyTherapist?0:newTservice.id,
              maximumNumberOfServices: newTservice.maximumNumberOfServices != "" ? parseInt(newTservice.maximumNumberOfServices) : 0
            };
            this.existingCertificationArr.push(existingCertificationObj);
            this.TherSerGrpDatas[0][i].certified = true;
            // for display
            oldTService.seniorityLevel = newTservice.seniorityLevel;
            oldTService.maximumNumberOfServices = newTservice.maximumNumberOfServices != "" ? newTservice.maximumNumberOfServices : 0;
          }
          index++;
        });
      });
      this.toggleserDet(false, '', 'service');
      this.service = this.FormGrp.get('services') as UntypedFormArray;
      this.service.patchValue(this.TherSerGrpDatas[0]);
    } else if (callDesc === 'getMaxListOrderTherapist') {
      this.maxLstOrder = <any>result.result;
      this.FormGrp.patchValue({ listOrder: this.maxLstOrder });
    } else if (callDesc === 'updateTherapistDetails') {
      if (typeof this.base64textString !== 'undefined') {
        const base64result = this.base64textString.split(',');
        const imageDataObj: ImageData = {
          referenceId: this.SelectedTherapistId,
          referenceType: GlobalConst.ImgRefType.therapist,
          data: base64result[1],
          id: this.editImageId,
          thumbnailData: base64result[1],
          contentType: base64result[0],
          sequenceNo: this.sequenceNo
        };
        if (this.editImageId) {
          this.imgService.updateImage(this.editImageId, imageDataObj, this.successCallback.bind(this), this.errorCallback.bind(this), []);
        } else {
          this.imgService.saveImage(imageDataObj, this.successCallback.bind(this), this.errorCallback.bind(this), []);
        }
      } else {
        if (this.editImageId && this.isImageRemoved) {
          this.imgService.DeleteImageByReference(this.SelectedTherapistId, GlobalConst.ImgRefType.therapist, this.successCallback.bind(this), this.errorCallback.bind(this), []);
        }
      }
      if (this.updateTherapistCommission.length > 0) {
        if (this.SelectedRadioBut === 'CP') {
          const allCommissionCount = this.commissionService.commissionGridData.filter(r => r.isModified).length;
          const modifiedCommissionCount = this.commissionService.commissionGridData.length;
          if (allCommissionCount == modifiedCommissionCount && this.modifiedCommissionRequest) {
            this.UpdateRetailCommissionSetUpByFilter();
          }
          else {
            this.InvokeServiceCall('UpdateCommission', GlobalConst.Host.commission, HttpMethod.Put, { classId: 0 }, this.updateTherapistCommission);
          }
        }
        else {
          // remove existing item config
          const commissionToRemove = this.updateTherapistCommission.filter(r => {
            return r.id > 0;
          }).map(r => r.id);
          if (commissionToRemove.length > 0) {
            this.InvokeServiceCall('RemoveCommission', GlobalConst.Host.commission, HttpMethod.Delete, '', commissionToRemove);
          }
        }
      }
      this.dialog.closeAll();
    } else if (callDesc === 'getImagesByReference') {
      const therapistImageDetails = result.result;
      if (therapistImageDetails[0]) {
        this.url = `${therapistImageDetails[0].contentType},${therapistImageDetails[0].data}`;
        this.editImageId = therapistImageDetails[0].id;
        this.sequenceNo = therapistImageDetails[0].sequenceNo;
      } else {
        this.url = '';
      }
    } else if (callDesc === 'GetAllAddOns') {
      this.TherAddOnData=[];
      let resultObj: any = result.result;
      if (resultObj == null) {
        resultObj = [];
      }
      for (let index = 0; index < resultObj.length; index++) {
        const element = resultObj[index];

        element['certified'] = false;
      }

      this.TherAddOnData.push(resultObj);


      if (this.SelectedTherapistId > 0) {
        this.BindTherapistAddOns(this.dataInput.therapistAddOn);
      }
    }
    else if (callDesc === 'GetCommissions') {
      let data: any = result.result;

      data = data.filter(x => x.classId === extraParams[1]);
      this.CCData = data.map(x => {
        return {
          id: 0, therapistId: x.therapistId, categoryId: x.categoryId, itemId: x.itemId, classId: x.classId,
          clerkId: x.clerkId, flatAmount: x.flatAmount.customToFixed(), percentage: x.percentage.toFixed(2), threshold: x.threshold.customToFixed(),
          isAfterDiscount: x.isAfterDiscount, isTotalRevenue: x.isTotalRevenue, isItemOnly: x.isItemOnly
        };
      });
    }
    else if (callDesc ==='GetAllTherapist') {
      this.therapistYieldJson = <any>result.result
    }
    else if (callDesc ==='GetTherapistAggregate') {
      this.dataInput = <any>result.result
      this.dataInputChanges();
      this.BindTherapistAddOns(this.dataInput.therapistAddOn);
      this.FormGrp.controls.selectedCC.setValue(this.dataInput.therapistDetail.commissionClassId);
      if (this.SelectedTherapistId > 0) {
        this.getTherapistServiceData('getTherapistCertification', this.SelectedTherapistId);
      }
      this.FormGrp.controls['StaffCode'].setValue('');
      
      this.FormGrp.controls['FirstName'].setValue('');
      this.FormGrp.controls['LastName'].setValue('');
      this.FormGrp.controls['AlsoKnownAs'].setValue('');
      this.FormGrp.controls['DateOfHire'].setValue('');
      this.FormGrp.patchValue({ listOrder: this.maxLstOrder });
    }
    else if ('GetActiveRetailItems') {
      const response = <any>result.result;
      if (response && response.length > 0) {
        this.CommissionItemArr1 = response;
      }
    }
    if (callDesc === 'classInstructorclassLimitation') {
      let data: any = result.result;
      if (data != null && data.dates != null && data.dates.length > 0) {
        let dateString: string = data.dates.map(date => this.localization.getLocalizedDayMonthDateYear(date)).join("<br>");
        this.utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localize.TherapistExceededMaximumAppointments + "<br>" + dateString, GlobalConst.ButtonType.ContinueCancel, this.AppointmentExceededCallBack.bind(this), data.serviceId);
      }
    }
  }

  errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    this.isEnableSave = true;
    console.error(result.result);
  }

  AppointmentExceededCallBack(result: any, extraParams?: any[]) {
    if (result.toLowerCase() == GlobalConst.ButtonOptions.No.toLowerCase()) {
      for (let TherSerGrpDatasItem of this.TherSerGrpDatas[0]) {
        if (TherSerGrpDatasItem.id == extraParams) {
          TherSerGrpDatasItem.maximumNumberOfServices = this.oldServiceCount;
        }
      }
      this.selectedIndex = 1;
    }
  }

  BindContactInformation(tPhoneEmailObj: any) {
    this.existingContactArr = [];
    if (tPhoneEmailObj.length === 0) {
      return;
    }
    for (let i = 0; i < tPhoneEmailObj.length; i++) {
      const element = tPhoneEmailObj[i];

      if (tPhoneEmailObj[i].contactDetail.indexOf('|') !== -1) {
        const contactDetailArr = tPhoneEmailObj[i].contactDetail.split('|');
        element.countryCode = contactDetailArr[0];
        element.contactDetail = contactDetailArr[1];
      } else {
        element.countryCode = "";
      }
      const contactObj: TherapistContact = {
        Id: element.id,
        ContactDetail: element.contactDetail,
        ContactLocationId: element.contactLocationId,
        Type: element.type,
        TherapistId: this.SelectedTherapistId
      };
      this.existingContactArr.push(contactObj);
      if (element.type === 'EMAIL') {
        this.addEmailItem(i, element.contactLocationId, element.contactDetail, element.id);
      } else if (element.type === 'PHONE') {
        const _extension = element.extension ? element.extension : '';
        this.addPhoneItem(i, element.contactLocationId, element.countryCode, element.contactDetail, element.id, _extension);
      }
    }

    this.Phone = this.FormGrp.get('Phone') as UntypedFormArray;
    if (this.Phone.controls.length > 1) {
      this.Phone.removeAt(0);
    }

    this.Email = this.FormGrp.get('Email') as UntypedFormArray;
    if (this.Email.controls.length > 1) {
      this.Email.removeAt(0);
    }

  }
  BindTherapistAddOns(tAddOnObj: any) {
    this.existingAddOns = tAddOnObj.length ? tAddOnObj : [];
    if (tAddOnObj.length > 0 && this.TherAddOnData[0].length > 0) {
      for (let i = 0; i < tAddOnObj.length; i++) {
        const addOn = this.TherAddOnData[0].filter(r => {
          return r.id === tAddOnObj[i].addOnId;
        });
        if (addOn.length > 0) {
          addOn[0].certified = true;
        }
      }
    }
  }
  private bindImage(fileContentType: any, fileContent: any) {
    const _placeholderBase64 = `data:${fileContentType};base64,${fileContent}`;
    // this.url = this.domSanitizer.bypassSecurityTrustUrl(_placeholderBase64);
    this.url = _placeholderBase64;
    this.ImageUploaded = true;
  }

  async BindTherapistCommission() {
    const isCommissionClassSelected: boolean = this.dataInput.therapistDetail.commissionClassId > 0;
    if (isCommissionClassSelected) {
      this.SelectedRadioBut = 'CC';
      this.FormGrp.controls.selectedCC.setValue(this.dataInput.therapistDetail.commissionClassId);
      return;
    }
    this.selectedOutletData = this.CPData.filter(r => r.outletId == this.selectedOutlet);
    if (!this.IsOutletAlreadyLoaded || this.selectedOutletData.length == 0) {
      const param = { 'referenceType': 'THERAPIST', 'referenceId': this.SelectedTherapistId, 'productId': GlobalConst.Product.SPA, 'outletId': this.selectedOutlet };
      this.CommissionItemArr = await this.getTherapistDataAsync('GetCommissionbyType', param);
      if (this.CommissionItemArr && this.CommissionItemArr.length > 0) {
        this.SelectedRadioBut = 'CP';
        this.BuildCommissionPercentageData();
      }
    }
    else {
      this.items = this.outletItemArray.find(r => r.key == this.selectedOutlet).value;
    }
    this.populateTableInfo(this.selectedOutletData);

  }

  filterCommissions(): any[] {
    const gridData = this.selectedOutletData;
    const filteredGridData: any = [];
    const itemToDisplay: any = this.filterItemArr.length === 0 ? this.selectedOutletData.map(x => x.itemId) : this.filterItemArr.map(r => r.id ? r.id : r);

    for (let i = 0; i < gridData.length; i++) {
      const element = gridData[i];
      if (itemToDisplay.includes(element.itemId)) {
        filteredGridData.push(element);
      }
    }
    return filteredGridData;
  }

  updateCategoryArr(ga: any, gv: any) {
    if (ga.indexOf(gv) === -1) {
      ga.push(gv);
    } else {
      ga.splice(ga.indexOf(gv), 1);
    }
    this.filterCategoryArr = ga;
    this.populateTableInfo(this.filterCommissions());
  }

  updateItemArr(ga: any, gv: any) {
    if (gv.id === 0) {
      if (this.filterItemArr.length === this.items.length) {
        this.filterItemArr = [];
      } else {
        this.filterItemArr = _.cloneDeep(this.items);
      }
    } else if (this.selectedItemArr.some(r => r === gv.id)) {
      this.filterItemArr = this.filterItemArr.filter(r => r.id !== gv.id && r.id !== 0);
    } else {
      this.filterItemArr.push(gv);
      if (this.filterItemArr.length === this.items.length - 1) {
        this.filterItemArr = _.cloneDeep(this.items);
      }
    }
    this.selectedItemArr = this.filterItemArr.length > 0 ? this.filterItemArr.map(r => r.id) : [];
    this.populateTableInfo(this.filterCommissions());
  }

  ThresholdChange() {
    this.populateTableInfo(this.filterCommissions());
  }

  applyall(selectedCommissionType, SelectedCommissionValue) {

    if (this.checkedCommissionsArr.length === 0) {
      this.business.openErrorDialog(this.captions.setting.PleaseSelectAtleastOneiteminGrid, this.captions.common.Error, this.captions.setting.NoItemSelected);
      return;
    } else if (selectedCommissionType === '') {
      this.business.openErrorDialog(this.captions.setting.PleaseSelectCommissionType, this.captions.common.Error, this.captions.setting.NoItemSelected);
      return;
    } else if (SelectedCommissionValue === '') {
      this.business.openErrorDialog(this.captions.setting.PleaseSelectCommissionValue, this.captions.common.Error, this.captions.setting.NoItemSelected);
      return;
    }
    if (selectedCommissionType === 'Flat Amount') {
      for (let i = 0; i < this.checkedCommissionsArr.length; i++) {
        this.checkedCommissionsArr[i].flatAmount = this.localization.currencyToSQLFormat(SelectedCommissionValue);
        this.checkedCommissionsArr[i].percentage = this.localization.localizeCurrency('0', false);
      }
    }
    if (selectedCommissionType === 'Threshold') {
      for (let i = 0; i < this.checkedCommissionsArr.length; i++) {
        this.checkedCommissionsArr[i].threshold = this.localization.currencyToSQLFormat(SelectedCommissionValue);
      }
    }
    if (selectedCommissionType === 'Percentage') {
      for (let i = 0; i < this.checkedCommissionsArr.length; i++) {
        this.checkedCommissionsArr[i].percentage = SelectedCommissionValue;
        this.checkedCommissionsArr[i].flatAmount = '0';
      }
    }
    if (selectedCommissionType === 'CommissionOn') {
      for (let i = 0; i < this.checkedCommissionsArr.length; i++) {
        this.checkedCommissionsArr[i].commissionon = SelectedCommissionValue;
        this.checkedCommissionsArr[i].isItemOnly = SelectedCommissionValue === this.captions.setting.Items ? true : false;
        this.checkedCommissionsArr[i].isTotalRevenue = !this.checkedCommissionsArr[i].isItemOnly;
      }
    }
    if (selectedCommissionType === 'AfterDiscount') {
      for (let i = 0; i < this.checkedCommissionsArr.length; i++) {
        this.checkedCommissionsArr[i].isAfterDiscount = SelectedCommissionValue == "Yes" ? true : false;
      }
    }
    this.checkedCommissionsArr = [];
    this.populateTableInfo(this.filterCommissions());
  }

  RowSelected(e) {
    this.checkedCommissionsArr = e;
  }
  close() {
    this.business.promptUserDialog(this.dialogRef);
  }

  OnCommissionTypeChange(e) {
    this.relativePlaceholder = 'Enter ' + e.value;
    this.FormGrp.controls['CommissionValue'].setValue('');
    this.commissionMaxLength = this.SelectedCommissionType == 'Percentage' ? GlobalConst.Maxlength.PERCENTAGE : GlobalConst.Maxlength.FLATAMOUNT;
    if (this.SelectedCommissionType == 'Percentage' || this.SelectedCommissionType == 'Threshold' || this.SelectedCommissionType == 'Flat Amount') {
      this.isDisabled = 'false';
    }
  }

  InvokeServiceCall(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  async ValidateInactive() {
    const isActive = this.FormGrp.controls['IsActive'].value;
    if (!this.blnReadOnly || !isActive) {
      return;
    }
    const results = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.schedule,
      callDesc: 'CheckTherapistAssociatedInAppointment',
      method: HttpMethod.Get,
      uriParams: { id: this.SelectedTherapistId }
    });
    const response: any = results.result;
    if (response) {
      this.FormGrp.controls['IsActive'].setValue('true');
      this.utilities.ShowErrorMessage(this.captions.common.Error, this.captions.common.TherapistInActiveNotAllowed, GlobalConst.ButtonType.Ok);
    }
  }


  async selectionChange($event?: StepperSelectionEvent) {
    setTimeout(() => {
      this.isboooo = true;
    }, 500);


    this.SelectedRadioBut = 'CC';
    if ($event.selectedIndex === 2 && this.FormGrp.controls['selectedCC'].value == 0) {
      this.isCommisionViewOnly = this.BP.IsViewOnly(GlobalConst.SPAManagementBreakPoint.StaffCommissionSetup);
      this.isCommisionUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAManagementBreakPoint.StaffCommissionSetup], true);
      this.SelectedRadioBut = 'CP';

      if (this.blnReadOnly) {
        await this.BindTherapistCommission();
      }
      else { // Setting default commission setup as commission class on Creation of new therapist
        this.SelectedRadioBut = 'CC';
      }
    }
    this.selectedIndex = $event.selectedIndex;
  }

  async GetOutlets() {
    this.outlets = await this.settingsService.GetActiveOutletsByUserAccess();
    if (this.outlets.length > 0) {
      this.selectedOutlet = this.outlets[0].subPropertyID;
    }
  }

  bindLocations(event) {
    this.noOfLocations = event;
  }

  emitedValue(event) {
    this.OnOutletChange(event[2].subPropertyID);
  }
  setmandatory(eve, phoneNumber, altfield, index) {
    if (eve.target.value) {
      this.FormGrp.controls['Phone']['controls'][index].controls[altfield].setValidators([Validators.required]);
      this.FormGrp.controls['Phone']['controls'][index].controls['PhoneNumberLabel'].setValidators([Validators.required]);

    } else {
      this.FormGrp.controls['Phone']['controls'][index].controls[altfield].clearValidators();
      this.FormGrp.controls['Phone']['controls'][index].controls['PhoneNumberLabel'].clearValidators();
    }
    this.FormGrp.controls['Phone']['controls'][index].controls[altfield].markAsTouched();
    this.FormGrp.controls['Phone']['controls'][index].controls[altfield].updateValueAndValidity();
    this.FormGrp.controls['Phone']['controls'][index].controls['PhoneNumberLabel'].markAsTouched();
    this.FormGrp.controls['Phone']['controls'][index].controls['PhoneNumberLabel'].updateValueAndValidity();
  }

  sortHandler(arg) {
    this.sortBy = this.business.getDataheader(GlobalConst.GridType.commission).map(r => r.jsonkey).indexOf(arg.header);
    this.isSortByAscending = arg.direction === 'asc';
  }

  async UpdateRetailCommissionSetUpByFilter() {
    this.utilities.ToggleLoader(true);
    if (this.modifiedCommissionRequest.updateCommissionSetupValue.isThresholdModified) {
      this.modifiedCommissionRequest.updateCommissionSetupValue.threshold = this.modifiedCommissionRequest.updateCommissionSetupValue.updateAllCommissionThersholdValue;
    }
    if (this.modifiedCommissionRequest.updateCommissionSetupValue.isCommissionOnModified) {
      this.modifiedCommissionRequest.updateCommissionSetupValue.commissionOn = this.modifiedCommissionRequest.updateCommissionSetupValue.updateAllCommissionOnValue;
    }
    if (this.modifiedCommissionRequest.updateCommissionSetupValue.isCommissionAfterDiscountModified) {
      this.modifiedCommissionRequest.updateCommissionSetupValue.isCommissionAfterDiscount = this.modifiedCommissionRequest?.updateCommissionSetupValue?.updateAllCommissionAfterDiscountValue?.toUpperCase() == 'YES'? true : false;
    }
    if (this.modifiedCommissionRequest.updateCommissionSetupValue.isAllCommissionValueModified) {
      this.modifiedCommissionRequest.updateCommissionSetupValue.commissionValue = this.modifiedCommissionRequest.updateCommissionSetupValue.updateAllCommissionValue;
    }
    this.modifiedCommissionRequest.updateCommissionSetupValue.commissionType = this.modifiedCommissionRequest.updateCommissionSetupValue.applyAllCommissionType;
    this.modifiedCommissionRequest.updateCommissionSetupValue.staffId = this.modifiedCommissionRequest.commissionSetupRequest.staffId ? this.modifiedCommissionRequest.commissionSetupRequest.staffId : this.therapistId;
    this.modifiedCommissionRequest.commissionSetupRequest.recordsToFetch = -1;
    this.commissionService.UpdateRetailCommissionSetUpByFilter(this.modifiedCommissionRequest.commissionSetupRequest, this.modifiedCommissionRequest.updateCommissionSetupValue, this.FormTherapistComission(this.modifiedCommissionRequest.updateCommissionSetupValue.staffId));
    this.utilities.ToggleLoader(true);
  }

  CommissionModified(event) {
    this.enableSave = event;
  }
  modifiedCommissionValue(event) {
    this.modifiedCommissionRequest = event;
  }
}

