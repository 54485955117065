import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-no-items-found',
  templateUrl: './no-items-found.component.html',
  styleUrls: ['./no-items-found.component.scss']
})
export class NoItemsFoundComponent implements OnInit {
  params: any;
  noItemsFoundText: any;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    this.params = params;
    this.noItemsFoundText = this.params.api.gridOptionsWrapper.gridOptions.context.
    parentComponent.gridInput.AdditionalTextToShowNoItemsFound;
  }

}
