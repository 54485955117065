import { Injectable } from "@angular/core";
import { TableOptions, TableHeaderOptions } from 'src/app/common/Models/ag-models';
import { SorTypeEnum } from 'src/app/common/shared/shared/enums/enums';
import { ActionTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from "../shared/shared/utilities/common-utilities";
import { GuestDataPolicyDataService } from "../dataservices/guest-datapolicy.data.service";
import { ConsentPolicyUIModel, PolicyDetails } from "./consent-management.model";

@Injectable()
export class ConsentManagementBusiness {
  captions: any;
  constructor(
    private localization: Localization, private utilities: CommonUtilities, private guestDataPolicyDataService: GuestDataPolicyDataService) {
    this.captions = this.localization.captions;
  }
  async GetIsGdprConfiguredFlag(): Promise<boolean> {
    var tenantId = Number(this.utilities.GetPropertyInfo("TenantId"));
    var isGdprConfigured = await this.guestDataPolicyDataService.GetDataRetentionPolicyConfiguredFlag(tenantId);
    return isGdprConfigured;
  }
  
  async GetPolicyTypeUsingPolicyId(policyId: number): Promise<number>{
    var policyType = await this.guestDataPolicyDataService.GetPolicyTypeUsingPolicyId(policyId);
    return policyType;
  }

  async GetConsentPoliciesForProduct(productId: number): Promise<ConsentPolicyUIModel[]> {
    let tenantId = Number(this.utilities.GetPropertyInfo("TenantId"));
    const ConsentPolicyModel: ConsentPolicyUIModel[] = await this.guestDataPolicyDataService.GetConsentPoliciesForProduct(tenantId, productId);
    return ConsentPolicyModel
  }
  async GetConsentPoliciesUsingCategoryId(categoryId: number): Promise<ConsentPolicyUIModel[]> {
    let tenantId = Number(this.utilities.GetPropertyInfo("TenantId"));
    let productId = Number(this.utilities.GetPropertyInfo("ProductId"));
    const ConsentPolicyModel: ConsentPolicyUIModel[] = await this.guestDataPolicyDataService.GetConsentPoliciesUsingCategoryId(tenantId,productId,categoryId);
    return ConsentPolicyModel;
  }
  async GetPolicyDetailsUsingPolicyId(policyId: number) : Promise<PolicyDetails>{
    const result = await this.guestDataPolicyDataService.GetPolicyDetailsUsingPolicyId(policyId);
    return result;
  }
  getTableOptions(): TableOptions {
    return {
      defaultsortingColoumnKey: '',
      defaultSortOrder: '',
      showTotalRecords: false,
      ignoreSort: true,
      columnFreeze: {
        firstColumn: true,
        lastColumn: false
      },
      isRowClick: true,
      uniqueKey: 'id',
      imageCaption: this.captions.lbl_noDataFound
    };

  }
  getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'checked',
        displayName: '',
        templateName: ActionTypeEnum.radioButton,
        //hdrtemplateName: HdrActionTypeEnum.rad,
        radioKey: 'id'
      },
      {
        key: 'consentPolicy',
        displayName: this.captions.consentPolicy,
        sorting: false
      },
      {
        key: 'fromDate',
        displayName: this.captions.consentFromdate,
        templateName: ActionTypeEnum.custom,
        sorting: false
      },
      {
        key: 'toDate',
        displayName: this.captions.consentToDate,
        templateName: ActionTypeEnum.custom,
        sorting: false
      },
      {
        key: 'noOfDays',
        displayName: this.captions.noOfDays,
        sorting: false
      }
    ]

  }
}
