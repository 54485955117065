import { Component, OnInit, Output, EventEmitter,ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
@Component({
  selector: 'app-retail-itemsextract',
  templateUrl: './itemsextract.component.html',
  styleUrls: ['./itemsextract.component.scss']
})

export class RetailItemsExtractComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  validSelection: boolean = true;
  ItemFormGrp: UntypedFormGroup;
  ShiftFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  inActiveText = this.captions.IncludeInactiveUsers;
  paymentMethods: DropDownData[] = [];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  useRetailInterface: boolean = false;
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any[];
  clerks: DropDownData[] = [];
  machineName: DropDownData[] = [];
  registers: any;
  categoryGroupSelected : any[] = [];
  showMachineDropDown: boolean = false;  
  validSelectionErrMsg: string ;
  isFromJobScheduler: boolean = false;
  editPatchValue: any;
  isFromEdit = false;
  categoryComponentFilter: any;
  timelineOptions: DropdownOptions[];
  fromJobSchedulerEdit :  boolean = false;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, 
    private dataService: ReportDataService, private propertyInfo: RetailPropertyInformation,private container: ViewContainerRef) {
    this.useRetailInterface = this.propertyInfo.UseRetailInterface;
   }

   ngOnInit() {
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
    this.showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;    
    this.ItemFormGrp = this.fb.group({
      filterItems: false,
      includeInactiveusers: false,
      reportcategory: new UntypedFormControl([this.defaultData]),
      paymentMethods: new UntypedFormControl([this.defaultData]),
      clerks: new UntypedFormControl([this.defaultData]),
      machineName: new UntypedFormControl([this.defaultData]),
      itemFilter: 0
    });

    if (this.isFromJobScheduler) {
      this.ItemFormGrp.addControl('timelineFrom', new UntypedFormControl());
      this.ItemFormGrp.addControl('timelineCountFrom', new UntypedFormControl());
      this.ItemFormGrp.addControl('timelineTo', new UntypedFormControl());
      this.ItemFormGrp.addControl('timelineCountTo', new UntypedFormControl());
      this.setSchedulercontrols();
    }
    this.formReady.emit(this.ItemFormGrp);    
    if (this.container) 
    {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;

      if(this.editPatchValue)
      {
        this.categoryComponentFilter =
        {
          category: _parent.editableValue.CategoryIds,
          subCategory1: _parent.editableValue.SubCategory1Ids,
          subCategory2: _parent.editableValue.SubCategory2Ids,
          subCategory3: _parent.editableValue.SubCategory3Ids,
          subCategory4: _parent.editableValue.SubCategory4Ids,
          subCategory5: _parent.editableValue.SubCategory5Ids
        };
        this.PatchSchedulercontrols(_parent.edit);     
      }      
      this.fromJobSchedulerEdit = _parent.edit;
      
    }
    if(!this.fromJobSchedulerEdit)
      this.onLoad();
  }

  private PatchSchedulercontrols(isEdit) 
  {
    if (this.localization.isFromJobScheduler && isEdit) 
    {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      setTimeout(() => {      

        if(patchItem.PaymentMethodIds != undefined){
          this.dataService.GetPaymentMethodByProduct().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.PaymentMethodIds.find(x => x == item.id);
            });
            this.paymentMethods = arr;
            this.business.FilterDataSource["allPaymentMethod"] = this.paymentMethods;
          });
        }         

        if(patchItem.ClerkIds != undefined){
          this.dataService.GetAllUsersByPropertyId().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.ClerkIds.find(x => x == item.id);
            });
            this.clerks = arr;
            this.business.FilterDataSource["allClerks"] = this.clerks;
            this.clerks = this.business.toggleIncludeInactive(false, this.clerks);
          });
        } 

        
        if(patchItem.MachineNameIds != undefined){
          this.dataService.GetMachineName().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.MachineNameIds.find(x => x == item.id);
            });
            this.machineName = arr;
            this.business.FilterDataSource["allMachineNames"] = this.machineName;
          });
        } 
      }, 1);
    }else{
      this.onLoad();
    }
  }

  setSchedulercontrols() {
    this.timelineOptions = this.localization.scheduleJobWildCards;
    this.ItemFormGrp.controls['timelineFrom'].setValue(this.localization.scheduleJobWildCards[0].id);
    this.ItemFormGrp.controls['timelineTo'].setValue(this.localization.scheduleJobWildCards[0].id);
    this.ItemFormGrp.controls['timelineCountFrom'].setValue(0);
    this.ItemFormGrp.controls['timelineCountTo'].setValue(0);
  }

  ngOnDestroy() {
  }

  private async onLoad() {
    this.clerks = await this.dataService.GetAllUsersByPropertyId();
    this.machineName = await this.dataService.GetMachineName();
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.paymentMethods = await this.dataService.GetPaymentMethodByProduct();
    this.business.FilterDataSource["allClerks"] = this.clerks;
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
    this.business.FilterDataSource["allMachineNames"] = this.machineName;
    this.business.FilterDataSource["allPaymentMethod"] = this.paymentMethods.filter(u => u.isActive);
    this.clerks = this.business.toggleIncludeInactive(false, this.clerks);
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.clerks = this.business.toggleIncludeInactive(IncludeInactiveToo, this.clerks);
    this.business.FilterDataSource["allClerks"] = this.clerks;
    this.ItemFormGrp.controls['clerks'].setValue([this.clerks]);
  }

  formInitialized(form: UntypedFormGroup) {
    this.ItemFormGrp.setControl('category', form);
  }


 
  getFormControlValue(event) {
    if (event[0] === 'clerks') {
      this.ItemFormGrp.setControl('clerks', event[1]);
      } else if (event[0] === 'reportcategory') {
        this.ItemFormGrp.setControl('reportcategory', event[1]);
        }
        else if (event[0] === 'machineName') {
          this.ItemFormGrp.setControl('machineName', event[1]);
          }
        else if(event[0] === 'paymentMethods'){
          this.ItemFormGrp.setControl('paymentMethods', event[1]);
        }
  }
  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }

  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    if ((this.ItemFormGrp.controls['paymentMethods'] && this.ItemFormGrp.controls['paymentMethods'].value.length == 0 && this.ItemFormGrp.controls["category"] && this.ItemFormGrp.controls["category"].value[0] && this.ItemFormGrp.controls["category"].value[0].selectedCat.length == 0)
    || (!this.ItemFormGrp.controls['paymentMethods'] && this.ItemFormGrp.controls["category"] && this.ItemFormGrp.controls["category"].value[0] && this.ItemFormGrp.controls["category"].value[0].selectedCat.length == 0)
    ) {
      this.validSelectionErrMsg = this.localization.captions.reports.PleaseSelectCategoryOrPayment;
      this.IsValidSelection.emit({ dropDownName: this.validSelectionErrMsg, validity: false });
    }
    else {
      this.IsValidSelection.emit({ dropDownName: '', validity: true });
    }
  }

}
