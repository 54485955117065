import { HttpClient, HttpParams } from '@angular/common/http';
import { Intergration } from './integration';
import { Injectable } from '@angular/core';
import {
    AuthPayload, DataMagineDelete, DataMagineDocumentGet, DataMaginePostPayload,
    IndexValueList, IntegrationMasterModel, DataMagineDownload, DocumentValueList, GetDocPayloadModel
} from './integration-interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FormGroup } from '@angular/forms';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { ToastrService } from 'ngx-toastr';
import { TransactionTableData } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import * as _ from 'lodash';
import moment from 'moment';
import { IntegrationURLs } from 'src/app/eatecui/source/shared/enum/global.enum';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { MatDialog } from '@angular/material/dialog';
import { AgilysysSessionExpiredComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-session-expired/agilysys-session-expired.component';
import { GridConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { DefaultIniDateFormat } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
export class DataMagineIntegeration extends Intergration {
    httpClinetModel: HttpClient;
    httpService: HttpService;
    dialog: MatDialog;
    dmViaEatec: boolean;
    dateFormat: string;
    constructor(httpClinetModel: HttpClient, httpService: HttpService, toastrService: ToastrService, 
        dialogg: MatDialog) {
        super(toastrService, null);
        this.httpClinetModel = httpClinetModel;
        this.httpService = httpService;
        this.dialog = dialogg;
        this.dmViaEatec = JSON.parse(sessionStorage.getItem('appsetting')).DataMagine.APIConfiguration.ViaEatec;
        const IniDateFormat = StorageService.GetSessionStorage('IniDateFormat');
        this.dateFormat = IniDateFormat ? IniDateFormat : DefaultIniDateFormat;
    }
    /**
     * @method DataMagineAuthLogin
     * @input None
     * @description None
     */
    DataMagineAuthLogin(httpService: HttpService, ClientPayload: AuthPayload): any {
        try {
            super.IntegrationAuthLogin(httpService, ClientPayload);
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * @method GetDataMagineMasterData
    * @input None
    * @description None
    */
    GetDatamagineMasterData(httpService: HttpService, ClientPayload: AuthPayload): any {
        try {
            super.IntegerationMasterData(httpService, ClientPayload);
        } catch (error) {
            console.log(error);
        }
    }
    /**
    * @method GetDataMagineConfigData
    * @input None
    * @description None
    */
    GetDataMagineConfigData(httpService: HttpService, ClientPayload: AuthPayload): any {
        try {
            super.IntegerationConfigData(httpService, ClientPayload);
        } catch (error) {
            console.log(error);
        }
    }
    

    /**
     * @method GetDataMagineMasterDataLocal
     * @input None
     * @description None
     */
    GetDataMagineMasterDataLocal(httpClinetModel: HttpClient, ClientPayload: AuthPayload): any {
        try {
            super.IntegerationMasterDataLocal(httpClinetModel, ClientPayload);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * @method ConstractDataDocumentPayload
     * @input None
     * @description constractDocumentPayload
     */
    ConstractDataDocumentPayload(FormGriData: Array<TransactionTableData>): DataMagineDocumentGet {
        try {
            const documentPayload = {} as DataMagineDocumentGet;
            documentPayload.PCname = this.integrationModuleData[0].PCName;
            documentPayload.Viewname = this.integrationModuleData[0].ViewName;
            documentPayload.SortOrder = [];
            documentPayload.TitleTypeArr = [];
            documentPayload.Pagesize = 500;
            documentPayload.Pagenum = 1;
            documentPayload.DateDelimiter = {};
            documentPayload.DisplayDate = true;
            documentPayload.Indexvaluelist = [];
            this.integrationModuleData.forEach((x: IntegrationMasterModel) => {
                const documentList = {} as DocumentValueList;
                documentList.Name = x.IndexFieldName;
                documentList.Operator = 'eq';
                    const GetValueIndex: number = FormGriData.findIndex((G: TransactionTableData) => G.Odatakey.toLowerCase()
                         === x.SourceFieldName.toLowerCase());
                    const valueList = {} as IndexValueList;
                    if (GetValueIndex > -1) {
                        const DataValue = FormGriData[GetValueIndex].Value;
                        // valueList.Value = ( DataValue !== '' ) ? ( FormGriData[GetValueIndex].Formatter &&
                        //     FormGriData[GetValueIndex].Formatter === 'SHORTDATE') ? moment(DataValue).format('DD MMM YY') : DataValue : '';
                        valueList.Value = (DataValue && DataValue !== '' && DataValue !== null) ? ( FormGriData[GetValueIndex].Formatter &&
                          FormGriData[GetValueIndex].Formatter === 'SHORTDATE') ? moment(DataValue).format(this.dateFormat) : DataValue.toString() : '';
                        valueList.Position = x.IndexPostition;
                    } else {
                        valueList.Value = (x.SourceFieldName === 'DocumentType') ? this.integrationModuleData[0].ViewName : '';
                        valueList.Position = x.IndexPostition;
                    }
                    documentList.Value = valueList.Value;
                    documentList.Connector = 'And';
                    if ( documentList.Value && documentList.Value !== '-') {
                        documentPayload.Indexvaluelist.push(documentList);
                    }
            });
            return documentPayload;
            
        } catch (error) {
            return null;
            console.log(error);
        }
    }

    /**
    * @method GetDataMagineDocument
    * @input DataMagineDocumentGet
    * @description None
    */
    async GetDataMagineDocument(DocumentGetPayload: DataMagineDocumentGet): Promise<any> {
        try {
            const documentPayload = {} as AuthPayload;
            documentPayload.ClientUrl = this.dmViaEatec ?
            (CONSTANT.ENDPOINTURL['EatecDMIntegration']['GetDocument']) : CONSTANT.ENDPOINTURL['DMIntegration']['GetDocument'] ;
            documentPayload.Payload = DocumentGetPayload;
            if (!this.dmViaEatec) {
                const getallURL: string = this.GetDataMagineEndPointUrl(1, IntegrationURLs.RETRIEVEALL);
                if ( getallURL ) {
                    documentPayload.ClientUrl = getallURL;
                }
            }
            await super.IntegrationGet(this.httpService, documentPayload, this.dialog, this.dmViaEatec);
        } catch (error) {
            console.log(error);
        }
    }

    async GetDataMagineDocumentLocal(ClientPayload: AuthPayload): Promise<any> {
        try {
            await super.IntegerationGridDataLocal(this.httpClinetModel, ClientPayload);
        } catch (error) {
            console.log(error);
        }
    }

    /**
    * @method PostDataMagineFileData
    * @input None
    * @description None
    */
    PostDatamagineFileData(UploadData: Array<UploadMetaData>, FormGriData: Array<TransactionTableData>): void {
        try {
            if (this.integrationModuleData && this.integrationModuleData.length > 0 && FormGriData && FormGriData.length > 0) {  
                const ValueList: Array<IndexValueList> = [];
                this.integrationModuleData.forEach((k: IntegrationMasterModel) => {
                    const GetValueIndex: number = FormGriData.findIndex((G: TransactionTableData) => G.Odatakey === k.SourceFieldName);
                    const valueList = {} as IndexValueList;
                    if (GetValueIndex > -1) {
                        const DataValue = FormGriData[GetValueIndex].Value;
                        // valueList.Value = ( DataValue !== '-' ) ? ( FormGriData[GetValueIndex].Formatter &&
                        //  FormGriData[GetValueIndex].Formatter === 'SHORTDATE') ? moment(DataValue).format('DD MMM YY') : DataValue : '';
                        valueList.Value = ( DataValue !== '-' ) ? ( FormGriData[GetValueIndex].Formatter &&
                            FormGriData[GetValueIndex].Formatter === 'SHORTDATE') ? moment(DataValue).format(this.dateFormat) : DataValue : '';
                        valueList.Position = k.IndexPostition;
                    } else {
                        valueList.Value = (k.SourceFieldName === 'DocumentType') ? this.integrationModuleData[0].ViewName : '';
                        valueList.Position = k.IndexPostition;
                    }
                    ValueList.push(valueList);
                });
                const orderValueList = _.orderBy(ValueList, ['Position'], ['asc']);
                const FileValueList: Array<any> = orderValueList.map((v: IndexValueList) => v.Value);
                const formData = new FormData();
                formData.append('PCname', this.integrationModuleData[0].PCName);
                formData.append('Overwriteflag', JSON.stringify(1));
                formData.append('Viewname', this.integrationModuleData[0].ViewName);
                formData.append('SecurityLevel', '1000');
                FileValueList.forEach(v => {
                    formData.append('Indexvaluelist', v);
                });
                formData.append('PdfToTiffConvert', 'false');
                formData.append('BreakMultiplePdf', 'false');
                formData.append('BreakMultipleTiff', 'false');
                UploadData.forEach((x: UploadMetaData) => {
                    formData.append('Files', x.FileData);
                });
                UploadData.forEach((x: UploadMetaData) => {
                    formData.append('Annotation', '');
                });                
                const authPayload = {} as AuthPayload;
                authPayload.ClientUrl = this.dmViaEatec ? CONSTANT.ENDPOINTURL['EatecDMIntegration']['DocumentPost'] : 
                CONSTANT.ENDPOINTURL['DMIntegration']['DocumentPost'];
                authPayload.Payload = formData;
                if (!this.dmViaEatec) {
                    const postUrl: string = this.GetDataMagineEndPointUrl(1, IntegrationURLs.POSTURL);
                    if ( postUrl ) {
                        authPayload.ClientUrl = postUrl;
                    }
                }     
                if (this.dmViaEatec) {
                    super.IntegerationPostData(this.httpService, this.dialog, this.dmViaEatec, authPayload);
                } else {
                    const integrationToken = sessionStorage.getItem('IntegrationToken');
                    if (integrationToken) {
                        super.IntegerationPostData(this.httpService, this.dialog, this.dmViaEatec, authPayload);
                    } else {
                        const ConfirmPopUpDialog: ConfirmPopModel = {
                            PopupHeaderContent: 'Warning',
                            PopUpContent: 'There is no valid access token to Post data. Please Validate'
                          };
                          const dialogRef = this.dialog.open(AgilysysSessionExpiredComponent, {
                            width: '400px',
                            height: '222px',
                            data: ConfirmPopUpDialog,
                            disableClose: true
                          });
                          dialogRef.afterClosed().subscribe((r: any) => { 
                            console.log(r);
                          });
                    }
                }               
            }

        } catch (error) {
            console.log(error);
        }
    }

    /**
     * @method DelteDataFile
     * @input None
     * @description None
     */
    DelteDataMagineFile(dataMagineDelete: DataMagineDelete): any {
        try {
            return new Promise(resolve => {
                const deleteParams: any = {};
                deleteParams.ids = dataMagineDelete.ids;
                deleteParams.ViewName = dataMagineDelete.ViewName;
                const authPayload = {} as AuthPayload;
                authPayload.ClientUrl = this.dmViaEatec ? CONSTANT.ENDPOINTURL['EatecDMIntegration']['DocumentDelete'] :
                CONSTANT.ENDPOINTURL['DMIntegration']['DocumentDelete'];
                authPayload.Payload = deleteParams;
                if (!this.dmViaEatec) {
                    const deleteUrl: string = this.GetDataMagineEndPointUrl(1, IntegrationURLs.DELETEURL);
                    if (deleteUrl) {
                        authPayload.ClientUrl = deleteUrl;
                    }
                }                
                super.IntegerationDelete(this.httpService, this.dmViaEatec, authPayload).then(res => {
                    resolve(res);
                });
            });
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * @method DownLoadDataMagineFile
     */
    DownLoadDataMagineFile(dataMagineDownload: DataMagineDownload): any {
        try {
            let Url: string = this.dmViaEatec ? CONSTANT.ENDPOINTURL['EatecDMIntegration']['DocumentDownload'] :
               CONSTANT.ENDPOINTURL['DMIntegration']['DocumentDownload'];
            // let Url: string = CONSTANT.ENDPOINTURL['DMIntegration']['DocumentDownload'];
            if (!this.dmViaEatec) {
                const downloadURL: string = this.GetDataMagineEndPointUrl(1, IntegrationURLs.RETRIEVEURL);
                if (downloadURL) {
                    Url = downloadURL;
                }
                Url = Url.replace('folderid', dataMagineDownload.FolderId);
                Url = Url.replace('documentid', dataMagineDownload.DocumentId);
            }  
            const getDocumentPayload = {} as GetDocPayloadModel;
            getDocumentPayload.folderId = parseInt(dataMagineDownload.FolderId, 10);
            getDocumentPayload.documentId = parseInt(dataMagineDownload.DocumentId, 10);
            const fileName = dataMagineDownload.FolderName + '-' + dataMagineDownload.CreatedDate + `(${dataMagineDownload.DocumentId})`;
            // super.IntegrationDownload(this.httpService, Url, getDocumentPayload, 
            //     (dataMagineDownload.FolderId + '-' + dataMagineDownload.DocumentId), this.dmViaEatec);
            super.IntegrationDownload(this.httpService, Url, getDocumentPayload, fileName, this.dmViaEatec);
            // super.IntegrationDownload(this.httpClinetModel, Url, null, 
            // (dataMagineDownload.FolderId + '-' + dataMagineDownload.DocumentId ));
        } catch (Error) {
            console.error('Error occurred in DownLoadDataMagineFile', Error);
        }
    }

    /**
     * @method GetDataMagineDocument
     * @input DataMagineDocumentGet
     * @description None
     */
    GetMasterDataModuleList(DocumentGetPayload: DataMagineDocumentGet): any {
        try {
            const documentPayload = {} as AuthPayload;
            documentPayload.ClientUrl = this.dmViaEatec ? (CONSTANT.ENDPOINTURL['EatecDMIntegration']['GetDocument']) :
             CONSTANT.ENDPOINTURL['DMIntegration']['GetDocument'];
            documentPayload.Payload = DocumentGetPayload;
            super.IntegrationGet(this.httpService, documentPayload, this.dialog, this.dmViaEatec);
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * @method GetModuleIndexValue
     * @input DataMagineDocumentGet
     * @description None
     */
    GetModuleIndexValue(ModuleName: string, IntegrationName: string): any {
        try {
            super.IntegerationModuleFilter(ModuleName, IntegrationName);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * @method GetEndPointUrl(ModuleKey: number, UrlType)
     * @description GetEndPoint Url In Dynamic
     */
    GetDataMagineEndPointUrl( ModuleId: number, ConfigMapingKey: string ): string {
        try {
            let endPointUrl = null;
            if ( this.integrationConfigData ) {
                const ConfigId = this.integrationConfigData.IntegrationFieldMappings.filter(x => 
                    x.ConfigMappingKey === ConfigMapingKey)[0].Id;
                const integrationFieldDetails: Array<any> = this.integrationConfigData.IntegrationFieldDetails;
                if ( integrationFieldDetails && integrationFieldDetails.length > 0 ) {
                    const FilterConfigData: Array<any> = integrationFieldDetails
                    .filter((configData: any) => configData.ModuleId === ModuleId && configData.ConfigId
                    === ConfigId);
                    if ( FilterConfigData && FilterConfigData.length > 0 ) {
                     endPointUrl = FilterConfigData[0].ConfigValues;
                    }
                 }
            }
            
           return endPointUrl;
        } catch ( error ) {
            console.error(error);
        }
    }

    /**
     * @method constractGridData
     * @input None
     * @output GridConfig
     * @description contratc the grid configuration to the Grid
     */
    constractGridData(): GridConfig {
      try {
        const GridDataConfig: GridConfig = {
            GridData: [],
            TotalRecords: 0,
            GridHeaderDefination: [
              {
                'headerName': '',
                'checkboxSelection': true,
                'field': 'check',
                'headerCheckboxSelection': true,
                'sortable': false,
                'suppressMovable': true,
                'width': '50',
                'editable': false
              },
              {
                'headerName': 'Folder Number',
                'field': 'FolderNumber',
                'editable': false,
                'suppressMovable': true,
                'sortable': false,
                'tooltipField': 'FolderNumber',
                'resizable': true,
                'customSort': true
              },
              {
                'headerName': 'Folder Name',
                'field': 'FolderName',
                'editable': false,
                'suppressMovable': true,
                'sortable': false,
                'tooltipField': 'FolderName',
                'resizable': true,
                'customSort': true
              },
              {
                'headerName': 'Total Documents',
                'field': 'TotalDocuments',
                'editable': false,
                'suppressMovable': true,
                'sortable': false,
                'tooltipField': 'TotalDocuments',
                'resizable': true,
                'customSort': true
              },
              {
                'headerName': 'Document Type',
                'field': 'DocumentType',
                'editable': false,
                'suppressMovable': true,
                'sortable': false,
                'tooltipField': 'DocumentType',
                'resizable': true,
                'customSort': true
              },
              {
                'headerName': 'Document Number',
                'field': 'DocumentNumber',
                'editable': false,
                'suppressMovable': true,
                'sortable': false,
                'tooltipField': 'DocumentNumber',
                'resizable': true,
                'customSort': true
              },
              {
                'headerName': 'Created Date',
                'field': 'CreatedDate',
                'editable': false,
                'suppressMovable': true,
                'sortable': false,
                'tooltipField': 'CreatedDate',
                'resizable': true,
                'customSort': true
              },
              {
                'cellRenderer': 'linkActions',
                'cellRendererParams': { 'EntityName': 'Download' },      
                'editable': false,
                'field': '',
                'headerName': '',
                'width': '70'
              },
              {
                'cellRenderer': 'linkActions',
                'cellRendererParams': { 'EntityName': 'Delete' },      
                'editable': false,
                'field': '',
                'headerName': '',
                'width': '70'
              }
            ],
            GridActions: {},
            GridItemListAction: {
              'EnableCustomGridAction': true,
              'EnableSearch': false,
              'EnableFilter': false,
              'ShowSelectedCount': true,
              'ActionIcons': [
                {
                  'Id': 1,
                  'Name': 'Delete',
                  'IconName': 'icon-delete-eatec',
                  'Enable': false,
                  'Tooltip': 'Delete'
                }
              ]
            }
            };
        GridDataConfig.GridData = this.contractresponsedata(this.integrationGridData);
        if (GridDataConfig.GridData && GridDataConfig.GridData.length > 0) {
            const index = GridDataConfig.GridHeaderDefination.findIndex(x => x.field === 'CreatedDate');
            GridDataConfig.GridHeaderDefination[index].valueFormatter = (gridData: any): any => {
            return this.dateFormatter(gridData);
            };
        }   
        return GridDataConfig;
    } catch (error) {
        console.error(error);
      }  
    }

    /**
     * @method contractresponsedata
     * @Input Method None
     * @Description change response Method
     */
    contractresponsedata( responseData: any ): any {
        try {
            let returnResponseData = [];
            if ( responseData && responseData.Response_Code && responseData.Response_Code === 200 ) {
                returnResponseData = responseData.Data.Result;
            } else if ( responseData && responseData.StatusCode && responseData.StatusCode === 200 ) {
                returnResponseData = responseData.Result;
            }
            return returnResponseData; 
        } catch (error) {
            console.error(error);
        }
    }
    /**
     * @method dateFormatter
     */
    dateFormatter(data: any): any {
        try {
            const AlreadyFormatted = moment(data.value, this.dateFormat).format(this.dateFormat) === data.value;
            console.log('Moment Date', moment(data.value, this.dateFormat).format(this.dateFormat), data.value);
            if (!AlreadyFormatted) {
                const FormatedValue = moment(data.value, 'DD-MM-YYYY HH:mm:ss').format(this.dateFormat);
                data.data[data.colDef.field] = FormatedValue;
                return FormatedValue;
            } else {
                return data.value;
            }
        } catch (error) {
            console.log(error);
        }
    }
  }