import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import { CommonControllersRoutes } from '../../communication/common-route';
import { NotificationCommunication } from '../../communication/services/notification-communication-service';
import { DefaultUrlConfigAPI,API } from 'src/app/common/shared/shared/event-scheduler-configuration/event-scheduler-configuration.model';


@Injectable(
    { providedIn: 'root' }
)

export class EventSchedulerConfigurationDataService{
    constructor(private _notificationCommunication : NotificationCommunication)
    {
        
    }

    public async getDefaultUrlConfiguration() : Promise<DefaultUrlConfigAPI.DefaultConfig[]>{
        return this._notificationCommunication.getPromise({
            route: CommonControllersRoutes.GetDefaultUrlConfiguration
          }, false);
    }

    public async getDefaultConfiguration() : Promise<API.jobScheduleConfig>{
        return this._notificationCommunication.getPromise({
            route: CommonControllersRoutes.GetDefaultConfiguration}, true);
    }

    public CreateJobSchedulerConfiguration(data: API.jobScheduleConfig): Promise<API.jobScheduleConfig> {
        const result = this._notificationCommunication.postPromise<API.jobScheduleConfig>({
          route: CommonControllersRoutes.CreateJobSchedulerConfiguration,
          body: data
        });
        return result;
      }

      public UpdateJobSchedulerConfiguration(data: API.jobScheduleConfig): Promise<API.jobScheduleConfig[]> {
        const result = this._notificationCommunication.putPromise<API.jobScheduleConfig[]>({
          route: CommonControllersRoutes.UpdateJobSchedulerConfiguration,body: data},true);
        return result;
      }
}