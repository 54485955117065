import {
    Component,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    OnChanges,
    ViewContainerRef,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormIcons } from '../../form-type.entity';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import * as _ from 'lodash';
export interface AdComponent {
    form: any;
    field: any;
}
@Component({
    selector: 'lib-grouped-items',
    templateUrl: './grouped-items.component.html',
    styleUrls: ['./grouped-items.component.scss'],
})
export class GroupedItemsProductComponent implements OnChanges, AfterViewInit, OnInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Input() components: any;
    formAddBuilderIcons: FormIcons;
    formRemoveBuilderIcons: FormIcons;
    currentAdIndex = -1;
    fieldType: any;
    formFieldName: any;
    formHeader: any;
    showRemoveForAll: boolean;
    @ViewChild('dynamicComponent', { read: ViewContainerRef, static: false }) dynamicComponent: ViewContainerRef;
    @ViewChild('dynamicComponentGrouped', { read: ViewContainerRef, static: false }) dynamicComponentGrouped: ViewContainerRef;
    @ViewChild('dynamicComponentGroupedMultiple',
        { read: ViewContainerRef, static: false }) dynamicComponentGroupedMultiple: ViewContainerRef;
    showCount: boolean;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        public formBuilder: FormBuilder,
        public AgyDynamicFormService: AgyDynamicFormServices,
    ) { }
    iconCallback(e: any): void {
        if (e.iconType.type === 'remove') {
            this.AgyDynamicFormService.RemoveRow(e.formArray, e.formObject, e.objectIndex);
            this.field.fieldType.groupingDataMultiple.data.splice(e.objectIndex, 1);
            this.dynamicComponentGroupedMultiple.clear();
            this.createGroupDataMultipleComponent();
        } else {
            this.AgyDynamicFormService.addnewRowGrouped(e, this.field);
        }
    }

    fieldArrayCallback(e: any, formObject: any): void {
        // const resetObject: FormGroup = _.cloneDeep(formObject);
        // resetObject.reset();
        const DataIndex = this.form.controls[this.formFieldName]['controls'].length;
        this.field.fieldType.groupingDataMultiple.data[0].dataSet.forEach(groupelement => {
            const adItem = this.AgyDynamicFormService.getComponentIndex(groupelement, this.components);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
            const componentRef = this.dynamicComponentGroupedMultiple.createComponent(componentFactory);
            (componentRef.instance as AdComponent).form = this.form.controls[this.formFieldName]['controls'][DataIndex - 1];
            (componentRef.instance as AdComponent).field = this.objectRestructure(groupelement, 'groupingDataMultiple');
        });
    }
    createRowHorizontalComponent(): void {
        this.field.groupedFields.forEach((element: any, index: number) => {
            const adItem = this.AgyDynamicFormService.getComponentIndex(element, this.components);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
            const componentRef = this.dynamicComponent.createComponent(componentFactory);
            (componentRef.instance as AdComponent).form = this.form;
            (componentRef.instance as AdComponent).field = element;
        });
    }
    createGroupDataSingleComponent(): void {
        this.field.fieldType[this.fieldType].data.forEach((SingleGroupingData: any, elementIndex: number) => {
            const adItem = this.AgyDynamicFormService.getComponentIndex(SingleGroupingData, this.components);
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
            const componentRef = this.dynamicComponentGrouped.createComponent(componentFactory);
            (componentRef.instance as AdComponent).form = this.form.controls[this.formFieldName];
            (componentRef.instance as AdComponent).field = this.objectRestructure(SingleGroupingData, 'groupingDataSingle');
        });
    }
    createGroupDataMultipleComponent(): void {
        this.field.fieldType[this.fieldType].data.forEach((element, dataIndex) => {
            element.dataSet.forEach((groupelement, index) => {
                const adItem = this.AgyDynamicFormService.getComponentIndex(groupelement, this.components);
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
                const componentRef = this.dynamicComponentGroupedMultiple.createComponent(componentFactory);
                (componentRef.instance as AdComponent).form = this.form.controls[this.formFieldName]['controls'][dataIndex];
                (componentRef.instance as AdComponent).field = this.objectRestructure(groupelement, 'groupingDataMultiple');
            });
        });
    }

    objectRestructure(element: any, elementName: string): any {
        const groupingSinglevalue = {};
        groupingSinglevalue[elementName] = element;
        const reStructureObject = {
            fieldType: groupingSinglevalue,
        };
        return reStructureObject;
    }

    ngOnChanges(): void {
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
        this.formRemoveBuilderIcons = {
            type: 'remove',
            label: 'remove',
            maxcount: this.field.maxcount
        };
    }
    ngAfterViewInit(): void {
        if (this.fieldType === 'groupingDataSingle') {
            this.createGroupDataSingleComponent();
        } else if (this.fieldType === 'groupingDataMultiple') {
            this.createGroupDataMultipleComponent();
        }
        this.form.controls[this.formFieldName].valueChanges.subscribe(res => {
            console.log('Value changed', res);
        });
    }

    ngOnInit(): void {
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
        this.formRemoveBuilderIcons = {
            type: 'remove',
            label: 'remove',
            maxcount: this.field.maxcount
        };
        this.fieldType = Object.keys(this.field.fieldType).toString();
        this.formFieldName = this.field.fieldType[this.fieldType].formName;
        this.formHeader = this.field.fieldType[this.fieldType].formHeader;
        this.showCount = this.field.fieldType[this.fieldType].showCount;
        this.showRemoveForAll = this.field.fieldType[this.fieldType].showRemoveForAll;
    }
}