
import { initialUsersList } from 'src/app/eatecui/source/store/states/user-list.state';
import { EUserList, UserListAction } from 'src/app/eatecui/source/store/actions/user-list.action';

export function UserListReducer(state = initialUsersList, action: UserListAction) {
  switch (action.type) {
    case EUserList.GetUserList: {
      return {
        ...state,
        GetUserList: action.payload
      };
    }
    case EUserList.GetUserListSuccess: {
      return {
        ...state,
        GetUserListSuccess: action.payload
      };
    }
    case EUserList.GetUserListFailure: {
      return {
        ...state,
        GetUserListFailure: action.payload
      };
    }
    case EUserList.DeactivateUser: {
      return {
        ...state,
        DeactivateUser: action.payload
      };
    }
    case EUserList.DeactivateUserSuccess: {
      return {
        ...state,
        DeactivateUserSuccess: action.payload
      };
    }
    case EUserList.DeactivateUserFailure: {
      return {
        ...state,
        DeactivateUserFailure: action.payload
      };
    }
    case EUserList.ActivateUser: {
      return {
        ...state,
        ActivateUser: action.payload
      };
    }
    case EUserList.ActivateUserSuccess: {
      return {
        ...state,
        ActivateUserSuccess: action.payload
      };
    }
    case EUserList.ActivateUserFailure: {
      return {
        ...state,
        ActivateUserFailure: action.payload
      };
    }
    default:
      return state;
  }
}
