import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import _ from 'lodash';
import { GridFilterSearchConfig } from './grid-config.interface';
import { ClientSideSearchFilter, GridFilterData, MasterInventoryManagement } from '../master.interface';
export const GridSearchFilter: GridFilterSearchConfig = (
    ViewModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    clientSideSearchFilter: ClientSideSearchFilter,
    elementData: any
    ): Array<any> => {
        try {
            if ( ViewModelEntity && MasterDataCollection && elementData) {
                let responseList = [];
                const FilterList = [];
                let responseData = [];
                if ( clientSideSearchFilter.hasOwnProperty('FilterConfig') && clientSideSearchFilter.FilterConfig.length > 0) {
                    clientSideSearchFilter.FilterConfig.forEach((x: any ) => {
                        if (x) {
                           x.forEach((element: GridFilterData) => {
                                const checkListArray =  element.FilterValue.filter( f => f.Checked);
                                if (checkListArray.length > 0) {
                                    const checkeList = {columnKey: element['FilterOriginalKey'], checkedList: checkListArray};
                                    FilterList.push(checkeList);
                                }
                            });

                        }
                    });
                }

                if (FilterList.length > 0) {
                    FilterList.forEach((x, index) => {
                        const columnKey = x['columnKey'];
                        const checkListId = x['checkedList'].map( (checkList: any) => checkList.FilterId);
                        if (checkListId.length > 0 && clientSideSearchFilter.CloneData.length > 0) {
                            responseData = clientSideSearchFilter.CloneData.filter(function(e) {
                                return checkListId.includes(e[columnKey]);
                            });
                            clientSideSearchFilter.CloneData = responseData;
                        }

                    });
                    responseList.push(...responseData);
                } else {
                    responseList = [...clientSideSearchFilter.CloneData];
                }
                if (clientSideSearchFilter.hasOwnProperty('SearchList') && clientSideSearchFilter.SearchList.length > 1) {
                    const searchParams = clientSideSearchFilter.SearchConfig.SearchParams;
                    const searchKeyList = [];
                    clientSideSearchFilter.SearchList.forEach((search: any) => {
                        const searchKey = search['SearchKey'];
                        searchKeyList.push(searchKey);
                    });
                    if ( responseList.length > 0 ) {
                        const searchResult =  responseList.filter( x => 
                            searchKeyList.some(val => 
                                String(x[val]).toLowerCase().includes(searchParams.toString().toLowerCase())));
                        // x[searchKey].toString().toLowerCase().includes(searchParams.toString().toLowerCase()));
                        responseList = [...searchResult];                
                    } else {
                        const searchResult = elementData.NestedGridInfo?.GridData?.filter( x => 
                            searchKeyList.some(val => 
                                String(x[val]).toLowerCase().includes(searchParams.toString().toLowerCase())));
                        responseList = searchResult? [...searchResult]:[];
                    }
                } else if (clientSideSearchFilter.hasOwnProperty('SearchConfig') &&
                 clientSideSearchFilter.SearchConfig.SearchParams !== '' &&
                clientSideSearchFilter.SearchConfig.SearchKey.length === 1) {
                    const searchParams = clientSideSearchFilter.SearchConfig.SearchParams;
                    const searchKey = clientSideSearchFilter.SearchConfig.SearchKey[0]['SearchKey'];
                    if ( responseList.length > 0 ) {
                   const searchResult =  responseList.filter( x => x[searchKey] !== '' &&  x[searchKey] !== null &&
                   x[searchKey].toString().toLowerCase().includes(searchParams.toString().toLowerCase()));
                   responseList = [...searchResult];
                    } else {
                        const searchResult = elementData.NestedGridInfo?.GridData?.filter( x => x[searchKey] !== '' &&  x[searchKey] !== null &&
                        x[searchKey].toString().toLowerCase().includes(searchParams.toString().toLowerCase()));
                        responseList = searchResult? [...searchResult]:[];
                    }
                } 
                return responseList;
            } else {
                return clientSideSearchFilter.CloneData;
            }
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };
