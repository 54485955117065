import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name:'minuteToHourMinute'
})
export class MinuteToHourMinute implements PipeTransform
{
  transform(value : number) : string{
      let hours : number   = Math.floor((value/60));
      let minutes: number  = Math.floor((value % 60));
      return hours.toString().padStart(2,'0')  + ':' + minutes.toString().padStart(2, '0') ;
  }
}