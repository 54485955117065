import { Injectable } from '@angular/core';
import { ReportTemplateService } from './report-template.service';
import { ReportTemplate, ReportKeyword,  TemplateDomainData, ReportAPITemplate,Events, ReportKeywordAPI } from './crud-report-templates.model';


@Injectable()
export class CrudReportTemplatesBusiness {


  constructor(private _reportTemplateService: ReportTemplateService) {

  }
  exceptionHandle(ex :any) {
    console.error(ex);
    throw ex;
  }

  async getReportTemplateData(): Promise<ReportTemplate[]> {
    try {
      return await this._reportTemplateService.GetAllTemplates();
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async getMaxListOrder(): Promise<number> {
    try {
      return await this._reportTemplateService.GetMaxListOrder();
    } catch (e) {
      this.exceptionHandle(e);
    }
  } 

  async getReportKeywords(): Promise<ReportKeyword[]> {
    try {
      let result= await this._reportTemplateService.GetReportKeyWords();
      return result.map(x=>this.MapKeyWordUI(x))
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async getTemplateDetailsById(notificationTemplateId: number): Promise<TemplateDomainData> {
    try {
      return await this._reportTemplateService.GetTemplateDataById(notificationTemplateId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }


  async CreateorUpdateTemplate(templatedomaindata: TemplateDomainData): Promise<number> {
    try {
      return await this._reportTemplateService.SaveTemplateData(templatedomaindata);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }


  async DeleteTemplate(reportTemplateId: number): Promise<ReportTemplate[]> {
    try {
      return await this._reportTemplateService.DeleteTemplate(reportTemplateId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async UpdateTemplate(id: number, reportTemplate: ReportAPITemplate): Promise<boolean> {
    try {
      return await this._reportTemplateService.UpdateTemplate(id, reportTemplate);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async UpdateListOrder(fromorder: number, toOrder: number): Promise<boolean> {
    try {
      return await this._reportTemplateService.UpdateListOrder(fromorder, toOrder);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async DefaultTemplateExists(eventId: number[], id: number): Promise<boolean> {
    try {
      return await this._reportTemplateService.DefaultTemplateExists(eventId, id);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async GetAllEvents(): Promise<Events[]> {
    try {
      return await this._reportTemplateService.GetAllEvents();
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  private MapKeyWordUI(reportKeyWordAPIModel: ReportKeywordAPI): ReportKeyword
  {
    return {
      id: reportKeyWordAPIModel.id,
      name: reportKeyWordAPIModel.name,
      description: reportKeyWordAPIModel.description,
      groupId: 1,
      groupNumber: 'General',
      isExpanded: false
    } as ReportKeyword
  }
}