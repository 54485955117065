import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class SystemConfigBusinessService {

  public systemForm:UntypedFormGroup;
  public systemConfigValues:any;


}
