import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder,  UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../shop-dialog-popup/shop-dialog-popup.component';
import { GiftCardBusiness } from '../../sytem-config/gift-cards/gift-cards.business';

@Component({
  selector: 'app-gift-card-payment-popup',
  templateUrl: './gift-card-payment-popup.component.html',
  styleUrls: ['./gift-card-payment-popup.component.scss']
})
export class GiftCardPaymentPopupComponent  {
  @Input() datainput;
  giftCardsPaymentFormGroup: UntypedFormGroup;
  captions: any;
  giftCardCaptions: any;
  balance:number;
  updatedBalance:number;
  cashBackEnabled: boolean = false;
  cashbackAmt: number = 0;
  floatLabel: string;


  constructor(private fb: UntypedFormBuilder,
    private localization: RetailLocalization,
    private dialogRef: MatDialogRef<ShopDialogPopUp>,
    private giftcardBusiness: GiftCardBusiness
  ) {
    this.captions = this.localization.captions.shop;
    this.floatLabel = this.localization.setFloatLabel;
    this.giftCardCaptions = this.localization.captions.shop.GiftCard;
    this.giftCardsPaymentFormGroup = this.fb.group({
      amount: new UntypedFormControl({ value: 0, disabled: true }),
      cashback: false
    });
    this.cashBackEnabled = giftcardBusiness._giftCardConfig.allowCashback;
  }


  ngOnChanges(): void {
    this.balance = this.datainput.balance;
    const amt = this.balance > this.datainput.saleAmount ? this.datainput.saleAmount : this.balance;
    this.giftCardsPaymentFormGroup = this.fb.group({
      amount: new UntypedFormControl({ value: amt, disabled: true }),
      cashback: false
    });
    this.calculateBalance();
  }

  OnCashBackToggle(event) {
    if (event[0] && this.datainput.balance > this.datainput.saleAmount) {
      this.cashbackAmt = this.datainput.balance - this.datainput.saleAmount;
    } else {
      this.cashbackAmt = 0;
    }
    this.calculateBalance();
  }

  calculateBalance() {
    const roundOffTwo = (value) => Number(value.customToFixed())
    const balance = roundOffTwo(this.datainput.balance - this.datainput.saleAmount);
    this.updatedBalance = roundOffTwo(balance - roundOffTwo(this.cashbackAmt) > 0 ? balance - roundOffTwo(this.cashbackAmt) : 0);
  }

  SendGiftCardToPayment() {
    let formValues = this.giftCardsPaymentFormGroup.getRawValue();
    formValues.cashbackAmt = this.cashbackAmt;
    this.dialogRef.close(formValues);
  }
}
