import { Component, OnInit, ViewChild, ElementRef,ViewEncapsulation,EventEmitter,Output,Input } from '@angular/core';
import moment, { Moment } from 'moment';
import { Localization } from '../../localization/localization';
import { CalenderCount } from '../../Models/diary.model';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';


@Component({
  selector: 'app-calendar-date-selection',
  templateUrl: './calendar-date-selection.component.html',
  styleUrls: ['./calendar-date-selection.component.scss'],
  encapsulation:ViewEncapsulation.None,
})
export class CalendarDateSelectionComponent implements OnInit {
  num: any=[];
  disabledRight: boolean;
  disabledLeft: boolean;
  disabledMonthLeft: boolean;
    disabledMonthRight: boolean;
    floatLabel: string;
  monthObj;
  dateSelection: Date;
  captions: any;
  selectedDate : string;
  start: Date;
  end: Date;
  min
  @Output() dateEmit = new EventEmitter();
  @Input() maxDate: Date;
  @Input() minDate: Date;
  
  private _dateCount: CalenderCount[] = [];
  @Input()
  public get dateCount(): CalenderCount[] {
    return this._dateCount;
  }
  public set dateCount(value: CalenderCount[]) {
    if(value)
      this._dateCount = value;
    this.SetDiaryCount();
  }

  @ViewChild('buttonView') btnView: ElementRef;
  @ViewChild('monthView') monthView: ElementRef;
  @ViewChild('caroView')  caroView: ElementRef;

  constructor(private localization: Localization,private propertyInfo: CommonPropertyInformation) { 
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.end = this.maxDate;
    this.start = this.minDate;
    let date = this.localization.getDate(this.propertyInfo.CurrentDate);   
    this.selectedDate = moment(this.localization.getDate(this.propertyInfo.CurrentDate)).format('MM/DD/YYYY'); 
    this.dateSelection = this.localization.getDate(this.propertyInfo.CurrentDate);
    // this.disabledMonthLeft = true;
    // this.disabledMonthRight = false;
    this.formatDateandMonth(date);
  }
private SetDiaryCount(){
  if(this.monthObj &&  this.monthObj.days && this.dateCount ){
    this.monthObj.days.forEach(x=>x.count = this.dateCount.find(d=>this.localization.getDate(d.diaryDate).getTime() == x.obj.getTime())?.count ?? 0);
    this.monthObj ={...this.monthObj};
  }
}
  formatDateandMonth(date){
    let monthIndex = date.getMonth();
    let month = moment().month(monthIndex).format("MMMM");
    this.monthObj ={
      displayMonth : month,
      month : monthIndex+1,
      displayYear : date.getFullYear(),
      fullObject : date,
      days : this.getDays(date.getFullYear(),monthIndex)
     }
    this.dateEmit.emit(this.monthObj.fullObject);
  }
  getDays(year, month){
    let daysArr =[];
    let days = new Date(year, month+1, 0).getDate();
    for(let i=1;i<=days;i++){
      let dateObj = new Date(year,month,i);
      let dateString = moment(dateObj).format('MM/DD/YYYY');
      daysArr.push({
        day : i,
        obj : dateObj,
        name: moment(dateObj).format('dddd').substring(0,2),
        isSelected : (this.selectedDate == dateString) ? true : false,
        count:0
      });
    }
    return daysArr;
  };

  getMonthList(eve){
    let date: Date;
    this.disabledMonthRight = false;
    this.disabledMonthLeft = false;
    if(eve == 'n'){
      date = moment(this.monthObj.fullObject).add(1, 'M').toDate();
      if(this.monthObj.displayYear == this.end.getFullYear() && this.monthObj.month == this.end.getFullYear()){
        this.disabledMonthRight = true;
      }
    } else {
      date = moment(this.monthObj.fullObject).subtract(1, 'M').toDate();
      if(this.monthObj.displayYear == this.start.getFullYear() && this.monthObj.month == this.start.getFullYear()){
        this.disabledMonthLeft = true;
      }
    }
    let x = document.getElementsByClassName('sliderPart')[0].scrollLeft;
    document.getElementsByClassName('sliderPart')[0].scrollLeft = x - 400;
    this.disabledLeft = true;
    this.disabledRight = false;
    this.formatDateandMonth(date);
  }
  Prev() {
    this.disabledRight = false;
    let x = document.getElementsByClassName('sliderPart')[0].scrollLeft;
    document.getElementsByClassName('sliderPart')[0].scrollLeft = x - 200;
    if ((x -50)<= 0) {
      this.disabledLeft = true
    } else {
      this.disabledLeft = false
    }
  }

  today(){
    let date = new Date();
    this.selectedDate= moment(date).format('MM/DD/YYYY');
    this.formatDateandMonth(date);
    this.dateEmit.emit(date);
  }


  Next() {
    this.disabledLeft = false;
    let element = this.btnView.nativeElement;
    let scrollValue = element.scrollLeft + 200;
    let scrollWidth = element.scrollWidth;
    let offsetWidth = element.offsetWidth;
    element.scrollLeft = scrollValue;
    if ((scrollWidth - (scrollValue + offsetWidth)) > 0) {
      this.disabledRight = false;
    } else {
      this.disabledRight = true
    }
  }

  dateChanged(eve) {
    this.selectedDate= moment(eve.value).format('MM/DD/YYYY');
    this.formatDateandMonth(eve.value);
    this.dateEmit.emit(eve.value);
  }

  setSelectedDate(index,obj){
    this.monthObj.days.forEach(x=>{
      x.isSelected = false;
    })
    this.selectedDate= moment(obj).format('MM/DD/YYYY');
    this.monthObj.days[index].isSelected = true;
    this.dateEmit.emit(obj);
  }
}
