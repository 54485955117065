import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
// import * as FBInventoryJson from '../../../../../assets/json/master/fbproduct.json';
// import * as RetailProductJson from '../../../../../assets/json/master/retailproduct.json';
import { fetchHttp } from 'src/app/eatecui/source/shared/models/fetch-data.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const CustomProductTypeChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
    ): any => {
        try {
            const FBJson: any  = fetchHttp('assets/json/master/fbproduct.json');
            const RetailJson: any  = fetchHttp('assets/json/master/retailproduct.json');
            const ReplaceMasterObject = {} as MasterInventoryManagement;
            FormGroupData.controls['Qty'].setValue(null);
            CommonService.triggerBlurEvent('Qty');
            FormGroupData.controls['CostRU'].setValue(null);
            CommonService.triggerBlurEvent('CostRU');
            FormGroupData.controls['CostBU'].setValue(null);
            CommonService.triggerBlurEvent('CostBU');
            FormGroupData.controls['Price'].setValue(null);
            CommonService.triggerBlurEvent('Price');
            FormGroupData.controls['CostPercentage'].setValue(null);
            CommonService.triggerBlurEvent('CostPercentage');
            if (FormGroupData.value.ProductsType === 2) {
                ReplaceMasterObject.Key = FBJson[0].Key;
                ReplaceMasterObject.Name = FBJson[0].Name;
                ReplaceMasterObject.NestedFormConfig = FBJson[0].NestedFormConfig;
                ReplaceMasterObject.SelectedTypeId = 1;
                ReplaceMasterObject.packageTypeId = FBJson[0].packageTypeId;
                ReplaceMasterObject.DetailsConfig = FBJson[0].DetailsConfig;
                ReplaceMasterObject.FormConfig = FBJson[0].FormConfig;
                ReplaceMasterObject.GridConfig = FBJson[0].GridConfig;
                ReplaceMasterObject.OnCellClick = MasterDataCollection.OnCellClick;
                ReplaceMasterObject.MasterBreadCrumbConfig = FBJson[0].MasterBreadCrumbConfig;
                ReplaceMasterObject.MasterKeys = FBJson[0].MasterKeys;
                ReplaceMasterObject.DetailExpand = FBJson[0].DetailExpand;
                const Data = [ReplaceMasterObject];
                MasterDataCollection.OnCellClick(Data);
            } else {
                ReplaceMasterObject.Key = RetailJson[0].Key;
                ReplaceMasterObject.Name = RetailJson[0].Name;
                ReplaceMasterObject.NestedFormConfig = RetailJson[0].NestedFormConfig;
                ReplaceMasterObject.SelectedTypeId = 1;
                ReplaceMasterObject.packageTypeId = RetailJson[0].packageTypeId;
                ReplaceMasterObject.DetailsConfig = RetailJson[0].DetailsConfig;
                ReplaceMasterObject.FormConfig = RetailJson[0].FormConfig;
                ReplaceMasterObject.GridConfig = RetailJson[0].GridConfig;
                ReplaceMasterObject.OnCellClick = MasterDataCollection.OnCellClick;
                ReplaceMasterObject.MasterBreadCrumbConfig = RetailJson[0].MasterBreadCrumbConfig;
                ReplaceMasterObject.MasterKeys = RetailJson[0].MasterKeys;
                ReplaceMasterObject.DetailExpand = RetailJson[0].DetailExpand;
                const Data = [ReplaceMasterObject];
                MasterDataCollection.OnCellClick(Data);
            }
        } catch (error) {
            console.log('error occured CustomDefaultSelect', error);
        }
    };

  
    