import { Injectable } from "@angular/core";
import { ActionTypeEnum, aligment } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { Localization } from "src/app/common/localization/localization";
import { GuestItineraryRequest } from "src/app/common/templates/notification-configuration/notification-configuration.model";
import { SorTypeEnum } from "../enums/enums";
import { CommonUtilities } from "../utilities/common-utilities";
import { SelectEformService } from "./select-e-forms.service";

@Injectable()
export class SelectEFormsBusiness {
    captions;
    constructor(private localize: Localization, private dataService: SelectEformService, private utils: CommonUtilities) {
        this.captions = this.localize.captions;
    }

    headerGenerator() {
        return [
          {
            displayName: '',
            key: 'selected',
            searchable: false,
            sorting: false,
            templateName: ActionTypeEnum.radioButton,
            radioKey: 'id'
          },
          {
          displayNameId: 'tbl_hdr_apptConfNo',
            displayName: this.captions.tbl_hdr_apptConfNo,
            key: 'apptConfNo',
            searchable: false,
            sorting: true,
            sortingKey: 'apptConfNo',
          },
          {
          displayNameId: 'tbl_hdr_serviceName',
            displayName: this.captions.tbl_hdr_serviceName,
            key: 'serviceName',
            searchable: true,
            sorting: true,
            sortingKey: 'serviceName',
          },
          {
          displayNameId: 'tbl_hdr_appointmentID',
            displayName: this.captions.tbl_hdr_appointmentID,
            key: 'appointmnetID',
            searchable: false,
            sorting: true,
            sortingKey: 'appointmnetID',
          },         
          {
          displayNameId: 'tbl_hdr_appointmentDateTime',
            displayName: this.captions.tbl_hdr_appointmentDateTime,
            key: 'appointmentDateTime',
            searchable: false,
            sorting: true,
            sortingKey: 'appointmentDateTime',
          },
          {
            displayNameId: 'tbl_hdr_Therapist',
              displayName: this.captions.tbl_hdr_Therapist,
              key: 'therapistName',
              searchable: false,
              sorting: true,
              sortingKey: 'therapistName',
            }           
        ];
      }
      getTableOptions() {
        return {
            defaultsortingColoumnKey: 'eFormName',
            defaultSortOrder: SorTypeEnum.asc,
            showTotalRecords: false,
            columnFreeze: {
                firstColumn: true,
                lastColumn: false
            }
        };
    }

     async getData(guestId: string, fromDate: string, endDate: string) {
      let response = await this.dataService.GetAppointmentByGuestId(guestId, fromDate, endDate);
      if(response)
      {
        response = response.map(x => {
          return {
                id: x.id,
                apptConfNo: x.confirmationNumber,
                serviceName: x.serviceName,
                appointmnetID: x.id,
                appointmentDateTime:this.localize.LocalizeShortDateTime(x.startTime) ,
                therapistName: x.therapistName
              };
        });
        return response;
    }
    else{
      return response;
    }
  }

  async getGolfData(request: GuestItineraryRequest) {
    let response = await this.dataService.GetTeeTimeByGuestId(request);
    if(response)
    {
      response = response.map(x => {
        return {
              id: x.scheduledTeeTimePlayerId,
              confNo: x.confirmationNumber,
              courseName: x.courseName,
              teeTimeDateTime:this.localize.LocalizeShortDateTime(x.dateAndTime) ,
              playerType: x.playerType,
              rateType : x.rateType,
            };
      });
      return response;
  }
  else{
    return response;
  }
}

  GetGolfHeaderOptions() {
    return [
      {
        displayName: '',
        key: 'selected',
        searchable: false,
        sorting: false,
        templateName: ActionTypeEnum.radioButton,
        radioKey: 'id'
      },
      {
      displayNameId: 'tbl_hdr_confirmationNumber',
        displayName: this.captions.tbl_hdr_confirmationNumber,
        key: 'confNo',
        searchable: false,
        sorting: true,
        sortingKey: 'apptConfNo',
      },
      {
      displayNameId: 'tbl_hdr_courseName',
        displayName: this.captions.tbl_hdr_courseName,
        key: 'courseName',
        searchable: true,
        sorting: true,
        sortingKey: 'courseName',
      },
      {
      displayNameId: 'tbl_hdr_teeTimeDateTime',
        displayName: this.captions.tbl_hdr_teeTimeDateTime,
        key: 'teeTimeDateTime',
        searchable: false,
        sorting: true,
        sortingKey: 'teeTimeDateTime',
      },
      {
        displayNameId: 'tbl_hdr_playerType',
          displayName: this.captions.tbl_hdr_playerType,
          key: 'playerType',
          searchable: false,
          sorting: true,
          sortingKey: 'playerType',
        },
        {
          displayNameId: 'tbl_hdr_rateType',
            displayName: this.captions.tbl_hdr_rateType,
            key: 'rateType',
            searchable: false,
            sorting: true,
            sortingKey: 'rateType',
          }            
    ];
  }

  async getTeeTimeData(guestId: string, fromDate: string, endDate: string) {
    return null;
  }
}
      