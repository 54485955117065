import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { AppointmentpopupService } from '../../service/appointmentpopup.service';
import { appointmentService } from '../../service/appointment.service';
import { SpaLocalization } from '../../../core/localization/spa-localization';
//import { WaitListService } from '../../addwaitlist-popup/waitlist-popup.service';
import { LostDeniedBusinessPopupService } from '../../appointment-actions/add-lost-denied-business-popup/lost-denied-business-popup.service';
//import { AppointmentWaitList, BaseResponse } from '../../business/shared.modals';
import { ManagementData, BaseResponse, LostDeniedReasonBusiness, LostDeniedReasonBusinessInfo } from '../../business/shared.modals';
import { AlertType, ButtonType, Host, OperationType } from '../../globalsContant';
import { HttpMethod,HttpServiceCall } from '../../service/http-call.service';
import { SpaUtilities } from '../../utilities/spa-utilities';
import { GuaranteeMethodBusiness, GuaranteeMethodJSONModel } from '../../appointment-popup/create-appointment/guarantee-method/guarantee-method.business';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { BreakPointAccess } from 'src/app/retail/shared/service/breakpoint.service';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-add-lost-denied-business-popup',
  templateUrl: './add-lost-denied-business-popup.component.html',
  styleUrls: ['./add-lost-denied-business-popup.component.scss'],
  providers: [GuaranteeMethodBusiness]
})
export class AddLostDeniedBusinessPopupComponent implements OnInit {
  captions: any = this.localization.captions.bookAppointment;
  saveText: string = this.captions.save;
  isGuaranteeMethodValid = true;
  fromPackage: boolean = false;
  guaranteePaymentMethod: GuaranteeMethodJSONModel;
  @ViewChild('appointmentTabGroup', { static: true }) tabGroup: MatTabGroup;
  cardId: number = 0;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  saveButtonEnable: boolean = true;
  lostDeniedData: LostDeniedReasonBusiness;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<AddLostDeniedBusinessPopupComponent>, public appointmentpopservice: AppointmentpopupService,
    public appointmentservice: appointmentService, public localization: SpaLocalization, public lostdeniedbusinessservice: LostDeniedBusinessPopupService,
    private utils: SpaUtilities, private guaranteePaymentMethodBusiness: GuaranteeMethodBusiness, private _quickLoginUtil: QuickLoginUtilities
    , private breakPoint: BreakPointAccess,private http: HttpServiceCall,) {

  }

  ngOnInit() {
    this.tabGroup._handleClick = this.handleTabChange.bind(this);
    this.appointmentservice.clientScreenProperties = { maxGuest: 1 };
    // this.lostdeniedbusinessservice.action
    this.appointmentpopservice.isFromLostDeniedBusiness = true;
    if (this.lostdeniedbusinessservice.action == OperationType.Edit) {
      this.saveText = this.captions.update;
    }
    if (this.data.appointmentInfomation) {
      this.lostdeniedbusinessservice.action = OperationType.Create;
      this.lostdeniedbusinessservice.isFromAppointment = true;
      this.lostdeniedbusinessservice.dataFromAppointmentScreen = this.data;
    }
    else {
      this.lostdeniedbusinessservice.isFromAppointment = false;
    }
  
    //this.fromPackage = this.data.fromPackage;
    this.initializeLocalValues();
  }

  ngAfterViewInit()
  {
    if (this.data.appointmentInfomation) {
      this.lostdeniedbusinessservice.isFromAppointment = true;
      this.lostdeniedbusinessservice.GetAllFilters();
      this.lostdeniedbusinessservice.dataFromAppointmentScreen = this.data.appointmentInfomation;
    }
  }

  async initializeLocalValues() {
    this.lostdeniedbusinessservice.isGuaranteeMethodRequired = await this.appointmentservice.IsGuaranteePaymentRequired();
    if(this.lostdeniedbusinessservice.appointmentDetail?.guaranteePaymentMethod)
    {
    this.GuaranteeMethodChange(this.lostdeniedbusinessservice.appointmentDetail?.guaranteePaymentMethod);
    }
  }

  handleTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {

    if (this.appointmentpopservice.clientCreditCardInfo && this.appointmentpopservice.clientCreditCardInfo.length > 0) {
      const activeCard = this.appointmentpopservice.clientCreditCardInfo.filter(x => x.isActive);
      if (activeCard.length > 0) {
        this.cardId = activeCard[0].tokenTransId;
      }
    }
    return MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
  }

  DialogClose = (result) => {
    this.dialog.close(result);
  }

  ngOnDestroy(): void {
    // Do not destroy object when add client popup is opened. This component will reopened again on close of client
    if (this.appointmentpopservice.add_client) {
      return;
    }

    this._quickLoginUtil.resetQuickIdDetails();
    this.appointmentpopservice.clientBlockInfos = [];
    this.appointmentpopservice.multiClientInfo = [];
    this.appointmentpopservice.labelRecords = [];
    this.appointmentpopservice.recordsArray = [];
    this.appointmentpopservice.resultNewAppointment = [];
    this.appointmentpopservice.resultNewClient = [];
    this.appointmentpopservice.otherdetails = { linkcode: 0, vip: false, intakeForm: false, NoMove: false, gender: 0,guestType:0, customField1: null, customField2: null, customField3: null, customField4: '', customField5: '', comments: '',noShowComments:'', policy: '', checkout: '', valueChange: false, client_scheduling: false };
    this.appointmentpopservice.add_client = false;
    this.appointmentpopservice.clientId = 0;
    this.appointmentpopservice.editselectedClient = false;
    this.appointmentpopservice.addFromClientModule = false;
    this.appointmentpopservice.isEditAppointment = false;
    this.appointmentpopservice.TempHoldArr = [];

    this.appointmentpopservice.tempHoldValues = {
      service: 0,
      location: 0,
      startTime: '',
      endTime: '',
      serviceGroupId: 0,
      comments: '',
      status: '',
      breakDownTime: 0,
      duration: 0,
      setUpTime: 0,
      price: 0,
      doNotMove: false,
      requestStaff: false,
      isBookingLocked: false,
      isVip: false,
      genderPreference: 0,
      checkOutComments: '',
      noShowComments:'',
      noShow: false,
      packageId: 0,
      clientId: 0,
      therapist: [],
      appointmentId: 0,
      cancelId: '',
      appointmentDate: '',
      TempHoldId: '',
      TempHoldLinkId: 0,
      originalClientId: 0,
      multiGroupId: 0,
      guestTypeId:0
    };
    this.appointmentpopservice.therapistAssociationError = false;
    this.appointmentpopservice.resultExistingAppointment = null;
    this.appointmentpopservice.resultExistingAppActivity = null;
    this.appointmentpopservice.isOverbook = false;
    this.appointmentpopservice.activateClientSelected = null;
    this.appointmentpopservice.clientGuestMap = [];
    this.appointmentpopservice.activateClientSelected = null;
    this.appointmentpopservice.ClientActivities = [];
    this.appointmentpopservice.existingAppointmentsRef = null;
    this.appointmentpopservice.resultPkgEntitlementData = null;
    this.appointmentpopservice.ClientSelectedTab = 0;
    this.appointmentpopservice.isPreviousDayAppt = false;
    this.appointmentpopservice.addwaitlist = false;
    this.appointmentpopservice.clientScreenProperties.minGuest = 1;
    this.appointmentpopservice.clientScreenProperties.maxGuest = 1;
    this.lostdeniedbusinessservice.appointmentDetailFormGroup = this.lostdeniedbusinessservice.appointmentDetail = null;
    this.lostdeniedbusinessservice.isAppointmentDetailFormValid = false;
    this.lostdeniedbusinessservice.selectedIndex = 0;
    this.lostdeniedbusinessservice.filterData = null;
    this.lostdeniedbusinessservice.isFormDirty = false;
    this.appointmentpopservice.clientScreenProperties.selectedStaff = null;
    this.appointmentpopservice.clientScreenProperties.helperText = '';
    this.appointmentpopservice.clientScreenProperties.maxGuestLocation = 0;

    this.appointmentservice.isAppointmentFromPackage = false;
    if (this.lostdeniedbusinessservice.action == OperationType.Edit && this.lostdeniedbusinessservice.viewWaitlistRef) {
      this.lostdeniedbusinessservice.viewWaitlistRef.LoadWaitLists();
    }
    this.lostdeniedbusinessservice.action = OperationType.Delete;
    this.lostdeniedbusinessservice.guaranteeMethodConfig = null;
    this.lostdeniedbusinessservice.isGuaranteeMethodRequired = false;
    this.appointmentpopservice.isFromLostDeniedBusiness = false;
  }

  async Save() {
    this.saveButtonEnable = false;
    if (this.ValidateSave()) {
      if (this.appointmentpopservice.clientScreenProperties && this.appointmentpopservice.clientScreenProperties.maxGuestLocation && this.appointmentpopservice.clientScreenProperties.maxGuestLocation > 0 &&
        this.appointmentpopservice.recordsArray.length > this.appointmentpopservice.clientScreenProperties.maxGuestLocation) {
        let message = this.captions.LocationMaxGuestClientConflict.interpolate({ location: this.appointmentpopservice.clientScreenProperties.maxGuestLocation });
        this.saveButtonEnable = true;
        this.utils.showAlert(message, AlertType.Warning, ButtonType.Ok);
        return false;
      }
      let selectedTherapistId: number[] = [];
      if (this.appointmentpopservice.clientScreenProperties.selectedStaff && this.appointmentpopservice.clientScreenProperties.selectedStaff.length > 0) {
        this.appointmentpopservice.clientScreenProperties.selectedStaff.forEach(r => {
          selectedTherapistId.push(r.id);
        });
      }
      let billingDetail = this.guaranteePaymentMethodBusiness.GetAPIStringJSON(this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod);
      this.lostDeniedData = this.lostdeniedbusinessservice.BuildWaitListData(this.appointmentpopservice.multiClientInfo, selectedTherapistId, billingDetail);
      if (this.lostDeniedData) {
        // Invoke serviceCall
        var dateConvertedBody: any = this.lostDeniedData;
        dateConvertedBody.startTime = this.localization.ConvertDateToISODateTime(dateConvertedBody.startTime);
        dateConvertedBody.endTime = this.localization.ConvertDateToISODateTime(dateConvertedBody.endTime);
        this.http.CallApiWithCallback<LostDeniedReasonBusiness[]>({
          host: Host.schedule,
          success: this.successCallback.bind(this),
          error: this.errorCallback.bind(this),
          callDesc: this.lostdeniedbusinessservice.action == OperationType.Create ? 'LostDeniedBusiness' : 'LostDeniedBusinessUpdate',
          method: this.lostdeniedbusinessservice.action == OperationType.Create ? HttpMethod.Post : HttpMethod.Put,
          uriParams: this.lostdeniedbusinessservice.action == OperationType.Create ? '' : { id: dateConvertedBody.id },
          body: this.lostdeniedbusinessservice.action == OperationType.Create ? [dateConvertedBody] : dateConvertedBody,
          showError: true,
          extraParams: [this.lostdeniedbusinessservice.action == OperationType.Create ? [dateConvertedBody] : dateConvertedBody]
        });
        // let callDesc: string = this.lostdeniedbusinessservice.action == OperationType.Create ? 'LostDeniedBusiness' : 'LostDeniedBusinessUpdate';
        // let uriParams: any = this.lostdeniedbusinessservice.action == OperationType.Create ? '' : { id: dateConvertedBody.id };
        // let httpMethod: HttpMethod = this.lostdeniedbusinessservice.action == OperationType.Create ? HttpMethod.Post : HttpMethod.Put;
        // let body: any = this.lostdeniedbusinessservice.action == OperationType.Create ? [dateConvertedBody] : dateConvertedBody;
        // let apiResponse: BaseResponse<any> = await this.lostdeniedbusinessservice.InvokeServiceCallAsync(callDesc, Host.schedule, httpMethod, uriParams, body);
        // if (apiResponse && apiResponse.successStatus) {
        //   this.updateClientCardInfo(apiData);
        //   this.DialogClose("SAVE");
        //   this.appointmentservice.selectedTabIndex = 3;
        // }
      }

      // if (this.saveButton == 'UPDATE') {
      //   let uriParams = { id: this.lostreasonBusinessId }
      //   response = await this.InvokeServiceCallAsync("LostDeniedBusinessUpdate", Host.schedule, HttpMethod.Put, uriParams, lostdeniedreason)
      // }
      // else {
      //   response = await this.InvokeServiceCallAsync("LostDeniedBusiness", Host.schedule, HttpMethod.Post, null, lostDeniedReasons)
      // }

      // if (response.successStatus && response.result) {
      //   this.dialogRef.close('SAVE');
      // }
    }
    else
    this.saveButtonEnable = true;
  }

  successCallback(): void {
      this.saveButtonEnable = true;
      this.updateClientCardInfo(this.lostDeniedData);
      this.DialogClose("SAVE");
      this.appointmentservice.selectedTabIndex = 3;
  }

  errorCallback(): void {
        this.saveButtonEnable = true;
  }

  updateClientCardInfo(apiData: LostDeniedReasonBusiness) {
    if (this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod && this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod.guaranteePaymentMethod
      && this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod.guaranteePaymentMethod.CardDetail &&
      this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod.guaranteePaymentMethod.CardDetail.PaymentReferenceId > 0 &&
      this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod.updateCardDetailsToClientProfile) {
      let clientsToUpdate = apiData.client.map(r => {
        return {
          clientId: r.clientId,
          tokenTransId: this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod.guaranteePaymentMethod.CardDetail.PaymentReferenceId
        }
      });
      this.guaranteePaymentMethodBusiness.UpdateCardInfoToClientProfile(clientsToUpdate);
    }
  }

  ValidateSave(): boolean {
    let isValid: boolean = true;
    // check Therapist/client validations
    if (this.appointmentpopservice.recordsArray.length == 0) {
      this.appointmentpopservice.attachClient = true;

      this.lostdeniedbusinessservice.selectedIndex = 1;
      isValid = false;
    }
    // else if (this.appointmentpopservice.multiClientInfo.length > 1 && !this.appointmentpopservice.validateMultiClient()) {
    //   this.appointmentpopservice.therapistAssociationError = true;
    //   this.lostdeniedbusinessservice.selectedIndex = 1;
    //   isValid = false;
    // }
    // else if (this.appointmentpopservice.multiClientInfo.length > 1 && !this.appointmentpopservice.ValidateAllTherapistsAssociated()) {
    //   this.lostdeniedbusinessservice.selectedIndex = 1;
    // }

    // Check form valid
    if (isValid) {
      if (this.lostdeniedbusinessservice.action == OperationType.Create) {
        isValid = this.lostdeniedbusinessservice.isAppointmentDetailFormValid;
      }
      else if (this.lostdeniedbusinessservice.action == OperationType.Edit) {
        isValid = this.lostdeniedbusinessservice.isAppointmentDetailFormValid && (this.lostdeniedbusinessservice.isFormDirty || this.appointmentpopservice.clientInformationForm.dirty);
      }
    }

    // Check for Min/Max Client validation
    if (isValid) {
      if (this.appointmentpopservice.checkForMinGuestNeeded()) {
        isValid = false;
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.MinimumGuestRequiredforthisServiceis + ' ' + this.appointmentpopservice.clientScreenProperties.minGuest);
      }
      else if (this.appointmentpopservice.checkForMaxGuestExceeded()) {
        isValid = false;
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.MaximumGuestRequiredforthisServiceis + ' ' + this.appointmentpopservice.clientScreenProperties.maxGuest);
      }
    }

    if (isValid) {
      if (!this.isGuaranteeMethodValid) {
        isValid = false;
        this.lostdeniedbusinessservice.selectedIndex = 2;
      }
    }

    if (isValid) {
      if (!this.appointmentpopservice) {
        isValid = false;
        this.lostdeniedbusinessservice.selectedIndex = 2;
      }
    }


    if (isValid && this.appointmentpopservice.clientScreenProperties && this.appointmentpopservice.clientScreenProperties.maxGuestLocation && this.appointmentpopservice.clientScreenProperties.maxGuestLocation > 0 &&
      this.appointmentpopservice.recordsArray.length > this.appointmentpopservice.clientScreenProperties.maxGuestLocation) {
      let message = this.captions.LocationMaxGuestClientConflict.interpolate({ location: this.appointmentpopservice.clientScreenProperties.maxGuestLocation });
      this.utils.showAlert(message, AlertType.Warning, ButtonType.Ok);
      isValid = false;
    }


    return isValid;
  }

  GuaranteeMethodChange(event: GuaranteeMethodJSONModel) {
    this.lostdeniedbusinessservice.appointmentDetail.guaranteePaymentMethod = event;
    this.isGuaranteeMethodValid = this.guaranteePaymentMethodBusiness.IsGuaranteeMethodAvailable(event?.guaranteePaymentMethod, this.lostdeniedbusinessservice.isGuaranteeMethodRequired);
    if (!this.guaranteePaymentMethodBusiness.IsGuaranteeMethodAvailable(event?.guaranteePaymentMethod, true)) { // Guarantee method has been removed
      this.lostdeniedbusinessservice.appointmentDetail.billingDetail = null;
    }
    if (event) {
      this.lostdeniedbusinessservice.isFormDirty = event.isFormDirty;
    }

  }
}
