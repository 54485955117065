import { IAppService } from "./IAppService";
import { HttpMethod, KeyValuePair, HttpServiceCall } from "../service/http-call.service";
import * as GlobalConst from '../globalsContant';
import { BaseResponse } from "../business/shared.modals";
import { Injectable } from "@angular/core";
/**
 * Appointment Confirmation Service Class
 */
@Injectable()
export class AppService implements IAppService {

    constructor(private http: HttpServiceCall){

    }

    /**
     * Common method to make
     * @param route Route string
     * @param domain Host name
     * @param callType Httpmethod type
     * @param successCallback success callback method
     * @param errorCallback error callback method
     * @param uriParams URI params
     * @param queryString query string
     * @param body body
     * @param extraParams extra params
     */
    public InvokeServiceCall(route: string, domain: GlobalConst.Host, callType: HttpMethod,
        successCallback: <T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) => void,
        errorCallback: <T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]) => void,
        uriParams?: any, queryString?: KeyValuePair, body?: any, extraParams?: any) {
        this.http.CallApiWithCallback<any>({
          host: domain,
          success: successCallback.bind(this),
          error: errorCallback.bind(this),
          callDesc: route,
          method: callType,
          body: body,
          showError: true,
          extraParams: extraParams,
          uriParams: uriParams,
          queryString: queryString
        });
      }
}