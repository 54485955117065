<section *ngIf="field" class="grouped-checkbox {{field.class}}">
    <div *ngFor="let module of field.fieldType.SingleData.groupOptions;let i =index">
        <label class="header-class">{{module.HeaderName}}</label>
        <!-- <mat-checkbox [(ngModel)]="module.IsActive" class="headercheckbox" (change)="selectGroup(module, $event)">{{module.HeaderName}}</mat-checkbox>  -->
        <div  class="subgroup-list">
            <div *ngFor="let subGroup of module.SubHeaderGroup;let j =index" >
                <mat-checkbox [(ngModel)]="subGroup.IsActive" class="subgroup-checkbox"
                 *ngIf="subGroup.SubGroupName" (change)="selectSubGroupName(module, $event, j)">{{subGroup.SubGroupName}}</mat-checkbox>
                <div class="subgroup-option">
                    <div *ngFor="let permission of subGroup.SubGroupOptions" class="subgroup-label"  [ngClass]="permission.Class ? permission.Class : ''">
                        <mat-checkbox [(ngModel)]="permission.IsActive" (change)="selectSubGroup(module, i, j)">{{permission.DisplayName}}</mat-checkbox>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>