import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import {
  ServiceLocations, ServiceEquipments, ServiceGroup, MedicalConditions, SettingsGrid, ServiceAddOns, ThearapistDetails, PackageModel, BreakType, PriceType, CancelReason,GuestType, PackageClass, SetupMenu,Relationship,
  SpaService
} from '../../shared/business/view-settings.modals';
import { ViewSettingClientBusiness } from '../../shared/common-functionalities/business/view-settings.business';
import { SPAConfig } from '../../core/config/SPA-config';
import { ButtonType, GridOperationType, GridType, Host, SPAScheduleBreakPoint, SPAManagementBreakPoint } from '../../shared/globalsContant';
import { DialogOverviewExampleDialog } from '../../shared/dialog-popup/dialogPopup-componenet';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { SettingsService } from '../settings.service';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { UntypedFormControl } from '@angular/forms';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { SubscriptionLike as ISubscription, ReplaySubject } from 'rxjs';
import { BaseResponse, popupConfig, ServiceTherapist } from '../../shared/business/shared.modals';
import { SetupService } from './setup.service';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { TableComponent } from '../../shared/table/table.component';
import { SettingDialogPopupComponent } from '../setting-dialog-popup/setting-dialog-popup.component';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Grid } from 'ag-grid-community';

@Component({
  selector: 'app-set-up',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SetupService]
})
export class SetUpComponent implements OnInit, OnDestroy {

  @ViewChild('selectSetup') selectSetup: MatSelect;
  @ViewChild('tableComponent') tableComponent: TableComponent;

  deleteConfirm: string;
  private olddata: any;
  orderTypearr: any = [];
  orderType: any;
  currentIndex: any;
  inactiveBoolControl: UntypedFormControl;
  selectedHeader: any = 'ID';
  ArrayList: any = [];
  SearChArray: any = [];
  InnerArrayElement: any = [];
  ButtonName: string;
  SelectId: number;
  serviceId: number;
  value: any = '';
  error: any = false;
  PlaceHolder: any;
  InactiveBool = false;
  PlaceHolderLen: any;
  searchText: any;
  EnableActions = true;
  locObj: any = {};
  tableoptions: any;
  lunchSetup = false;
  therapistSetup: boolean = false;
  IsViewOnly = false;
  IsAccessAllowed = false;
  propertyDate: any;
  ServiceArray: any;
  urlSubscription: ISubscription;
  settingDialogSubscription: ISubscription;
  alertDialogSubscription: ISubscription;
  promptSubscription: ISubscription;
  askConfirmForDelete = false;
  serverError: string;
  MedicalDeleteCode = "";
  PackageDeleteCode = "";
  private maxListOrder: number;
  private ArrayGridData = [];
  private TAll: boolean;
  EditCaption: string;
  Category: any;
  serviceAddOnsResult: any;
  initialLoads = true;
  callCounter = 0;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public dialog: MatDialog,
    private spaConfig: SPAConfig,
    private setupService: SetupService,
    private http: HttpServiceCall,
    private ViewSettingBusiness: ViewSettingClientBusiness, private utils: SpaUtilities, private _ss: SettingsService,
    public localization: SpaLocalization, private BP: BreakPointAccess, private PropertyInfo: SpaPropertyInformation, private router: Router, private route: ActivatedRoute) {
    this.InactiveBool = false;
    this.propertyDate = this.utils.convertDateFormat(this.PropertyInfo.CurrentDate);
    this.inactiveBoolControl = new UntypedFormControl(false);
    this._ss.tabLoaderEnable.next(true);
  }


  service: SetupMenu[] = [
    { id: GridType.group, viewValue: this.spaConfig.captions.setting.ServiceGroup, callDesc: "GetAllServiceGrp", breakPointNumber: SPAManagementBreakPoint.ServiceGroup, IsAuthorized: true },
    { id: GridType.location, viewValue: this.spaConfig.captions.setting.ServiceLocation, callDesc: "GetAllLoc", breakPointNumber: SPAManagementBreakPoint.ServiceLocation, IsAuthorized: true },
    { id: GridType.therapist, viewValue: this.spaConfig.captions.setting.TherapistSetup, callDesc: "getActiveTherapist", breakPointNumber: SPAManagementBreakPoint.TherapistSetup, IsAuthorized: true },
    { id: GridType.medical, viewValue: this.spaConfig.captions.setting.MedicalCondition, callDesc: "GetAllMC", breakPointNumber: SPAManagementBreakPoint.MedicationCondition, IsAuthorized: true },
    { id: GridType.addons, viewValue: this.spaConfig.captions.setting.ServiceAddon, callDesc: "GetAllAddOns", breakPointNumber: SPAManagementBreakPoint.ServiceAddOn, IsAuthorized: true },
    { id: GridType.equipment, viewValue: this.spaConfig.captions.setting.CreateServiceEquipment, callDesc: "GetAllEquip", breakPointNumber: SPAManagementBreakPoint.ServiceEquipment, IsAuthorized: true },
    { id: GridType.spaServices, viewValue: this.spaConfig.captions.setting.SpaService, callDesc: "GetAllSpaService", breakPointNumber: SPAManagementBreakPoint.SpaService, IsAuthorized: true },
    { id: GridType.spaPackage, viewValue: this.spaConfig.captions.setting.SpaPackage, callDesc: "GetSpaPackages", breakPointNumber: SPAManagementBreakPoint.SpaPackage, IsAuthorized: true },
    { id: GridType.cancelReasons, viewValue: this.spaConfig.captions.setting.CancelReason, callDesc: "GetActiveCancelReason", breakPointNumber: SPAManagementBreakPoint.CancelReason, IsAuthorized: true },
    { id: GridType.packageClasses, viewValue: this.spaConfig.captions.setting.PackageClasses, callDesc: "GetAllPackageClasses", breakPointNumber: SPAManagementBreakPoint.PackageClasses, IsAuthorized: true },
    { id: GridType.lunchsetup, viewValue: this.spaConfig.captions.setting.LunchSetup, callDesc: "GetAllLunch", breakPointNumber: SPAManagementBreakPoint.LunchSetup, IsAuthorized: true },
    { id: GridType.colorLink, viewValue: this.spaConfig.captions.setting.ColorLinking, callDesc: "GetLinkCodes", breakPointNumber: SPAManagementBreakPoint.ColorLinking, IsAuthorized: true },
    { id: GridType.breakType, viewValue: this.spaConfig.captions.setting.BreakType, callDesc: "GetAllBreakTypes", breakPointNumber: SPAManagementBreakPoint.BreakType, IsAuthorized: true },
    { id: GridType.priceType, viewValue: this.spaConfig.captions.setting.PriceType, callDesc: "GetAllPriceTypes", breakPointNumber: SPAManagementBreakPoint.PriceType, IsAuthorized: true },
    { id: GridType.customfield, viewValue: this.spaConfig.captions.setting.CustomFields, callDesc: "GetCustomFields", breakPointNumber: SPAManagementBreakPoint.CustomFields, IsAuthorized: true },
    { id: GridType.lostDeniedreason, viewValue: this.spaConfig.captions.setting.CustomFields, callDesc: "GetCustomFields", breakPointNumber: SPAManagementBreakPoint.CustomFields, IsAuthorized: true },
    { id: GridType.guestType, viewValue: this.spaConfig.captions.setting.guestType, callDesc: "GetAllGuestType", breakPointNumber: SPAManagementBreakPoint.GuestType, IsAuthorized: true },
    { id: GridType.relationship, viewValue: this.spaConfig.captions.setting.Relationship, callDesc: "GetAllRelationships", breakPointNumber: SPAManagementBreakPoint.Relationship, IsAuthorized: true },
    { id: GridType.classGroup, viewValue: this.spaConfig.captions.setting.classGroup, callDesc: "GetAllServiceGrp",breakPointNumber: SPAManagementBreakPoint.ClassGroup, IsAuthorized: true },
    { id: GridType.classSetup, viewValue: this.spaConfig.captions.setting.classSetup, callDesc: "GetAllSpaService", breakPointNumber: SPAManagementBreakPoint.ClassSetup, IsAuthorized: true },
    { id: GridType.instructorSetup, viewValue: this.spaConfig.captions.setting.instructorSetup, callDesc: "getActiveTherapist", breakPointNumber: SPAManagementBreakPoint.InstructorSetup, IsAuthorized: true },
    { id: GridType.classLocation, viewValue: this.spaConfig.captions.setting.classLocation, callDesc: "GetAllLoc", breakPointNumber: SPAManagementBreakPoint.ClassLocation, IsAuthorized: true },
    { id: GridType.classEquipment, viewValue: this.spaConfig.captions.setting.classEquipment, callDesc: "GetAllEquip", breakPointNumber: SPAManagementBreakPoint.ClassEquipment, IsAuthorized: true },
  ];

  InactiveTherapist(e: any) {
    const Checkbool = e.checked;
    const tempArray: any = [];
    if (Checkbool) {
      this.ArrayList[0].datacolumn.forEach(elementValue => {
        elementValue.therapist.forEach(element => {
          if (!element.onlineStatus) {
            tempArray.push(elementValue);
          }
        });
      });
      this.ArrayList[0].datacolumn = tempArray;
    } else {
      this.ArrayList[0].datacolumn = this.SearChArray[0].datacolumn;
    }
  }

  showActive(e) {
    return (e == 12 || e == 14 || e == 18);
  }

  inactiveRecords($event?) {
    if ($event) {
      this.inactiveBoolControl.setValue($event[0]);
    }
    if (this.serviceId === GridType.colorLink) {
      this.GetLinkCodeDetail(this.propertyDate, this.inactiveBoolControl.value, [GridType.colorLink, this.inactiveBoolControl.value]);
    }
    if(this.serviceId===GridType.relationship){
        this.GetAllRelationships("GetAllRelationships", [GridType.relationship]);
    }
    if (this.serviceId === GridType.cancelReasons) {
      if (!this.inactiveBoolControl.value) {
        this.GetCancelReasons("GetActiveCancelReason", [GridType.cancelReasons]);
      } else {
        this.GetCancelReasons("GetAllCancelReasons", [GridType.cancelReasons]);
      }
    }
  }

  DeleteConfirm(e: Array<any>) {
    const popUpbelonTo: GridType = e[1];
    if (this.askConfirmForDelete) {
      this.openPromptDialog("Are you sure want to delete?", popUpbelonTo, 'Confirm', 'Confirm', e);
    } else {
      this.DeleteRecords(e);
    }
  }

  DeleteRecords(e: any) {
    const popUpbelonTo: GridType = e[1];
    let codeOfDeleteObj = e[0].code;
    const ShowOnlyActiveTherapist: any = typeof e[2] == 'undefined' ? false : e[2].checked;
    let deleteRouteStr: string;
    let showError = true;
    let deleteBody = {};
    let isClass= false;
    let keyValue: any = {};
    const extraParams = [popUpbelonTo];
    switch (popUpbelonTo) {
      case GridType.location: {
        deleteRouteStr = "deleteLoc";
        extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.equipment: {
        deleteRouteStr = "deleteEquip";
        extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.breakType: {
        deleteRouteStr = "DeleteBreakType";
        codeOfDeleteObj = { id: e[0].id };
      } break;
      case GridType.priceType: {
        deleteRouteStr = "DeletePriceType";
        codeOfDeleteObj = { id: e[0].id };
      } break;
      case GridType.packageClasses: {
        deleteRouteStr = "DeletePackageClass";
        codeOfDeleteObj = { id: e[0].id };
      } break;
      case GridType.medical: {
        deleteRouteStr = "deleteMC";
        showError = false;
        extraParams.push(codeOfDeleteObj);
        codeOfDeleteObj = codeOfDeleteObj + '/false';
      } break;
      case GridType.group: {
        deleteRouteStr = "DeleteServiceGrp";
        extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.guestType: {
        codeOfDeleteObj = { id: e[0].id };
         deleteRouteStr = "DeleteGuestType";
         extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.addons: {
        codeOfDeleteObj = { id: e[0].id };
        deleteRouteStr = "DeleteAddOn";
        extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.spaServices: {
        codeOfDeleteObj = { id: e[0].id };
        deleteRouteStr = "DeleteSpaService";
        extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.therapist: {
        deleteRouteStr = "DeleteTherapist";
        codeOfDeleteObj = e[0].id;
        deleteBody = { "showOnlyActive": ShowOnlyActiveTherapist }
      } break;
      case GridType.spaPackage: {
        deleteRouteStr = "DeletePackage";
        codeOfDeleteObj = { id: e[0].id };
        extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.cancelReasons: {
        deleteRouteStr = "DeleteCancelReason";
        codeOfDeleteObj = { id: e[0].id };
      } break;
      case GridType.colorLink: {
        deleteRouteStr = "DeleteLinkCode";
        codeOfDeleteObj = { id: e[0].id };
        extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.relationship: {
         deleteRouteStr = "DeleteRelationship";
         if(e[0].isContactRelationshipInUse)
         {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error,this.spaConfig.captions.setting.DeleteNotAllowed);
          return;
         }
         codeOfDeleteObj = { id: e[0].id };
         extraParams.push(codeOfDeleteObj);
      } break;
      case GridType.classGroup: {
        deleteRouteStr = "DeleteServiceGrp";
        extraParams.push(codeOfDeleteObj);
        isClass=true;
        keyValue = { key: "isClassGroup", value:  [true]};
      } break;
      case GridType.classSetup: {
        codeOfDeleteObj = { id: e[0].id };
        deleteRouteStr = "DeleteSpaService";
        extraParams.push(codeOfDeleteObj);
        isClass=true;
        keyValue = { key: "isClass", value:  [true]};
      } break;
      case GridType.instructorSetup: {
        deleteRouteStr = "DeleteTherapist";
        codeOfDeleteObj = e[0].id;
        deleteBody = { "showOnlyActive": ShowOnlyActiveTherapist }
        isClass=true;
        keyValue = { key: "isInstructor", value:  [true]};
      } break;
      case GridType.classLocation: {
        deleteRouteStr = "deleteLoc";
        extraParams.push(codeOfDeleteObj);
        isClass=true;
        keyValue = { key: "isClassLocation", value:  [true]};
      } break;
      case GridType.classEquipment: {
        deleteRouteStr = "deleteEquip";
        extraParams.push(codeOfDeleteObj);
        isClass=true;
        keyValue = { key: "isClassEquipment", value:  [true]};
      } break;
    }
    if(isClass)
      {
        this.DeleteCallsWithQueryString(codeOfDeleteObj, deleteRouteStr, showError, extraParams,keyValue, deleteBody);
      }
      else
      {
        this.DeleteCalls(codeOfDeleteObj, deleteRouteStr, showError, extraParams, deleteBody);
      }
  }

  openDialog(e: any, belonTo: any, preTit: any, afterClosed = true): any {
    this.utils.ToggleLoader(true);
    if (!this.service.find(setupMenu => setupMenu.id == belonTo).IsAuthorized) {
      return;
    }
    let dialogRef: any;
    let popupConfiguration: popupConfig;
    let keyValue: any = {};
    if ((belonTo == GridType.location || belonTo == GridType.classLocation || belonTo == GridType.group || belonTo == GridType.classGroup || belonTo == GridType.packageClasses || belonTo == GridType.colorLink || belonTo ==GridType.spaServices || belonTo ==GridType.classSetup || belonTo ==GridType.guestType || belonTo ==GridType.relationship)) {
      if (preTit === 'Edit') {
        popupConfiguration = {
          operation: "edit",
          GridOperationType: GridOperationType.Edit
        };
        this.EditCaption = this.localization.captions.common.Edit
        dialogRef = this.triggerPopup(e, belonTo, preTit, popupConfiguration, afterClosed);
      } else {
        let routeUrl: any;
        if (belonTo == GridType.group || belonTo == GridType.classGroup) {
          routeUrl = "maxListOrderGroup";
          keyValue = { key: "isClassGroup", value:  [true]};
        } else if (belonTo == GridType.guestType) {
          routeUrl = "maxListOrderGuestType";
        }
        else if (belonTo == GridType.spaServices || belonTo == GridType.classSetup) {
          routeUrl = "maxListOrderService";
          keyValue = { key: "isClass", value:  [true]};
        } else if (belonTo == GridType.colorLink) {
          routeUrl = "maxListOrderLinkCode";
        } else if(belonTo == GridType.relationship){
          routeUrl = "maxListOrderRelationship";
        }
        else {
          routeUrl = "maxListOrderLocation";
          keyValue = { key: "isClassLocation", value:  [true]};
        }
        if(belonTo == GridType.classGroup || belonTo == GridType.classSetup || belonTo == GridType.classLocation || belonTo == GridType.classLocation|| belonTo == GridType.instructorSetup)
          {
            this.urlSubscription = this.ViewSettingBusiness.getMaxListOrderwithQueryString(routeUrl,keyValue).subscribe(
              data => {
                this.maxListOrder = data.result;
                popupConfiguration = {
                  operation: "create",
                  maxListOrder: this.maxListOrder,
                  GridOperationType: GridOperationType.Create
                };
                dialogRef = this.triggerPopup(e, belonTo, preTit, popupConfiguration, afterClosed);
              }
            );
          }
          else{
        this.urlSubscription = this.ViewSettingBusiness.getMaxListOrder(routeUrl).subscribe(
          data => {
            this.maxListOrder = data.result;
            popupConfiguration = {
              operation: "create",
              maxListOrder: this.maxListOrder,
              GridOperationType: GridOperationType.Create
            };
            dialogRef = this.triggerPopup(e, belonTo, preTit, popupConfiguration, afterClosed);
          }
        );
      }
      }

    } else {
      if (preTit == "Edit") {
        this.EditCaption = this.localization.captions.common.Edit
        popupConfiguration = {
          operation: "edit",
          GridOperationType: GridOperationType.Edit
        };
      } else {
        popupConfiguration = {
          operation: "create",
          GridOperationType: GridOperationType.Create
        };
      }
      dialogRef = this.triggerPopup(e, belonTo, preTit, popupConfiguration, afterClosed);
    }
    return dialogRef;
  }

  triggerPopup(e: any, belonTo: any, preTit: any, popupConfiguration: any, afterClosed = true): any {
    const Dialogtitle = this.setupService.getpopData(belonTo)[0];
    const DialogTemplate = this.setupService.getpopData(belonTo)[1];
    const bigPopupWin = [this.localization.captions.setting.TherapistSetup,this.localization.captions.setting.instructorSetup, this.localization.captions.setting.SpaService, this.localization.captions.setting.SpaPackage, this.localization.captions.setting.LocationMaintenance,this.localization.captions.setting.classSetup];
    const panelClass = ['small-popup'];
    let popupWidth = '661px';
    let popupHeight = 'auto';
    let popupMinWidth = '661px';
    if (bigPopupWin.includes(Dialogtitle)) {
      popupWidth = '80%';
      popupHeight = '85%';
    }
    if (belonTo == GridType.lunchsetup) {
      popupWidth = '700px';
    }
    if (belonTo == GridType.colorLink) {
      popupWidth = '900px';
      popupHeight = '530px';
    }
    if (belonTo == GridType.customfield) {
      popupWidth = '50%';
      popupHeight = '70%';
    }
    if (belonTo == GridType.therapist || belonTo == GridType.instructorSetup) {
      popupWidth = "90%";
      popupHeight = "85%";
      popupMinWidth = "900px";
    }
    if (belonTo == GridType.lunchsetup) {
      popupWidth = "750px";
    }
    if (belonTo == GridType.spaPackage) {
      popupWidth = "100%";
      popupHeight = "100%";
      popupMinWidth = "100%";
      panelClass.push('spa-package-panel');
    }
    if (belonTo == GridType.priceType) {
      popupWidth = "500px";
      popupMinWidth = "500px";
    }
    if (belonTo == GridType.medical) {
      popupWidth = '550px';
      popupMinWidth = '550px';
    }
    if (belonTo == GridType.guestType) {
      popupWidth = '500px';
      popupMinWidth = '500px';
    }
    if (belonTo == GridType.location || belonTo == GridType.classLocation) {
      popupWidth = '900px';
      popupHeight = "85%";
    }
    if (belonTo == GridType.equipment || belonTo == GridType.classEquipment) {
      popupHeight = "75%";
      popupWidth = "65%";
    }
    if (belonTo == GridType.relationship) {
      popupWidth = '500px';
      popupMinWidth = '500px';
    }
    const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      height: popupHeight,
      minWidth: popupMinWidth,
      width: popupWidth,
      maxWidth: popupWidth,
      data: { headername: (preTit == "Edit" ? this.EditCaption : preTit) + '  ' + Dialogtitle, closebool: true, templatename: DialogTemplate, datarecord: e, popupConfig: popupConfiguration, type: belonTo },
      panelClass: panelClass,
      disableClose: true,
      hasBackdrop: true
    });
    if (afterClosed) {
      this.settingDialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.olddata = result.NewData ? result.NewData : '';
        }
        this.onClosingPopup(belonTo, result, preTit);
      });
    }
    this.utils.ToggleLoader(false);
    return dialogRef;
  }

  openPromptDialog(error: any, belongTo: any, Type: any, header: any, e: any): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '600px',
      data: {
        headername: header, closebool: true, templatename: 'In the Given Data', datarecord: error,
        Type: Type, buttonType: ButtonType.YesNo
      },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });

    this.promptSubscription = dialogRef.afterClosed().subscribe(result => {
      if (Type !== "Confirm") {
        this.openDialog(this.olddata, belongTo, Type);
      }
      if (Type === "Confirm" && result === "ok") {
        this.DeleteRecords(e);
      }
    });
  }

  afterEditPopupClose(fromTableComponent: any): void {
    const popupBelongsTo: GridType = <GridType>Object.values(fromTableComponent)[1];
    const responseObj = Object.values(fromTableComponent)[0];
    this.gridRefresh(popupBelongsTo, responseObj);
  }

  onClosingPopup(popupBelongsTo: any, responseObj: any, mode: string): void {
    if (typeof responseObj === 'undefined') {
      this.onChange();
    } else {
      this.gridRefresh(popupBelongsTo, responseObj);
    }
    if (this.ButtonName == this.localization.captions.setting.CreateCancelReason || this.ButtonName == this.localization.captions.setting.CreateRelationship) {
      this.inactiveRecords();
    }
  }

  dragDrop(e: Array<number>): void {
    this._ss.tabLoaderEnable.next(true);
    const settingBelongsTo = e[2];
    let keyValue: any = {};
    let routeURL: string;
    const dragIndex = e[0];
    const dropIndex = e[1];
    const IsInactiveTherapist: any = e[3];
    let uriParam: any = dragIndex.toString() + "/" + dropIndex.toString();

    if (settingBelongsTo == GridType.group || settingBelongsTo == GridType.classGroup) {
      routeURL = "ServiceGrpDragDrop";
      keyValue = { key: "isClassGroup", value:  [true]};
    }else if (settingBelongsTo == GridType.guestType) {
      routeURL = "GuestTypeDragDrop";
    }
    else if (settingBelongsTo == GridType.spaServices || settingBelongsTo == GridType.classSetup) {
      routeURL = "ServiceDragDrop";
      keyValue = { key: "isClass", value:  [true]};
    }
     else if (settingBelongsTo == GridType.location || settingBelongsTo == GridType.classLocation) {
      routeURL = "LocationDragDrop";
      keyValue = { key: "isClassLocation", value:  [true]};
    } else if (settingBelongsTo == GridType.colorLink) {
      routeURL = "LinkCodeDragDown";
      uriParam = { fromorder: dragIndex, toorder: dropIndex };
    } else if (settingBelongsTo == GridType.therapist || settingBelongsTo == GridType.instructorSetup) {
      if (typeof IsInactiveTherapist == "undefined") {
        routeURL = "dragAndDropActiveTherapist";
      } else {
        routeURL = IsInactiveTherapist.checked ? "dragAndDropTherapist" : "dragAndDropActiveTherapist";
      }
      uriParam = { fromorder: dragIndex, toorder: dropIndex };
      keyValue = { key: "isInstructor", value:  [true]};
    }
    else if(settingBelongsTo == GridType.relationship)
      {
        routeURL = "RelationshipDragDrop";
        uriParam = dragIndex.toString() + "/" + dropIndex.toString()+"/"+ this.inactiveBoolControl.value;
      
      }
      if(settingBelongsTo == GridType.classGroup || settingBelongsTo == GridType.classSetup || settingBelongsTo == GridType.classLocation || settingBelongsTo == GridType.instructorSetup)
        {
          this.patchCallsWithQueryString(uriParam, routeURL, [settingBelongsTo],keyValue);
        }
        else
        {
          this.patchCalls(uriParam, routeURL, [settingBelongsTo]);
        }
  }

  onChange($event?: any) {
    let ListId: GridType;
    let selectedElem: any;
    let callDesc: string;
    const isInitial = $event ? true : false;
    // To retain the toggle value
    //this.inactiveBoolControl.setValue(false);
    if (this.tableComponent) {
      this.tableComponent.clearSearchText();
    }

    if ($event) {
      ListId = this.serviceId;
      selectedElem = this.service.filter(x => x.id === this.serviceId);
      callDesc = selectedElem[0].callDesc;
    } else {
      selectedElem = this.service.filter(x => x.id === this.serviceId);
      ListId = this.serviceId;
      callDesc = selectedElem[0].callDesc;
    }
    this.ArrayList = [];
    let keyValue: any = {};
    this.lunchSetup = false;
    this.therapistSetup = false;
    switch (ListId) {
      case GridType.group:
        this.getGridDataSource<ServiceGroup[]>(callDesc, ListId, isInitial);
        break;
      case GridType.location:
        this.getGridDataSource<ServiceLocations[]>(callDesc, ListId, isInitial);
        break;
      case GridType.equipment:
        this.getGridDataSource<ServiceEquipments[]>(callDesc, ListId, isInitial);
        break;
      case GridType.medical:
        this.getGridDataSource<MedicalConditions[]>(callDesc, ListId, isInitial);
        break;
      case GridType.addons:
        this.getGridDataSource<ServiceAddOns[]>(callDesc, ListId, isInitial);
        break;
      case GridType.spaServices:
        this.getGridDataSource<any[]>(callDesc, ListId, isInitial);
        break;
      case GridType.therapist:
        this.getGridDataSource<ThearapistDetails[]>(this.TAll ? 'GetAllTherapist' : callDesc, ListId, isInitial);
        this.therapistSetup = true;
        break;
      case GridType.spaPackage:
        this.ButtonName = this.localization.captions.setting.CreateSpaPackage;
        this.PlaceHolder = this.localization.captions.setting.PackageCodeDescription;
        this.getGridDataSource<PackageModel[]>(callDesc, ListId, isInitial);
        break;
      case GridType.cancelReasons:
        this.ButtonName = this.localization.captions.setting.CreateCancelReason;
        this.PlaceHolder = this.localization.captions.setting.CancelReason;
        this.getGridDataSource<CancelReason[]>(callDesc, ListId, isInitial);
        break;
      case GridType.packageClasses:
        this.ButtonName = this.localization.captions.setting.CreatePackageClass;
        this.PlaceHolder = this.localization.captions.setting.PackageClassCodePackageClassName;
        this.getGridDataSource<PackageClass[]>(callDesc, ListId, isInitial);
        break;
      case GridType.lunchsetup:
        this.ButtonName = this.localization.captions.setting.CreateLunchSetup;
        this._ss.GetAllLunchSlots(this._ss.startDate, this._ss.endDate);
        this.lunchSetup = true;
        break;
      case GridType.colorLink:
        this.ButtonName = this.localization.captions.setting.CreateColorLink;
        this.PlaceHolder = this.localization.captions.setting.LinkCodeLinkDescription;
        this.GetLinkCodeDetail(this.propertyDate, this.inactiveBoolControl.value, [GridType.colorLink, this.inactiveBoolControl.value]);
        break;
      case GridType.priceType:
        this.ButtonName = this.localization.captions.setting.CreatePriceType;
        this.PlaceHolder = this.localization.captions.setting.PriceTypeCodePriceTypeDescription;
        this.getGridDataSource<PriceType[]>(callDesc, ListId, isInitial);
        break;
      case GridType.breakType:
        this.ButtonName = this.localization.captions.setting.CreateBreakType;
        this.PlaceHolder = this.localization.captions.setting.BreakTypeCodeBreakTypeDescription;
        this.getGridDataSource<BreakType[]>(callDesc, ListId, isInitial);
        break;
      case GridType.customfield:
        this.ButtonName = this.localization.captions.setting.CreateCustomField;
        this.PlaceHolder = this.localization.captions.setting.CustomFieldDisplayField;
        this.getGridDataSource<any[]>(callDesc, ListId, isInitial);
        break;
      case GridType.guestType:
        this.getGridDataSource<GuestType[]>(callDesc, ListId, isInitial);
        break; 
      case GridType.relationship:
        this.getGridDataSource<Relationship[]>(callDesc, ListId, isInitial);
        break; 
      case GridType.classGroup:
        keyValue = { key: "isClassGroup", value:  [true]};
        this.getGridDataSource<ServiceGroup[]>(callDesc, ListId, isInitial,true,keyValue);
        break;
      case GridType.classSetup:
        keyValue = { key: "isClass", value:  [true]};
        this.getGridDataSource<any[]>(callDesc, ListId, isInitial,true,keyValue);
        break;
      case GridType.instructorSetup:
        keyValue = { key: "isInstructor", value:  [true]};
        this.getGridDataSource<ThearapistDetails[]>(this.TAll ? 'GetAllTherapist' : callDesc, ListId, isInitial,true,keyValue);
        this.therapistSetup = true;
        break;
      case GridType.classLocation:
        keyValue = { key: "isClassLocation", value:  [true]};
        this.getGridDataSource<ServiceLocations[]>(callDesc, ListId, isInitial,true,keyValue);
        break;
      case GridType.classEquipment:
        keyValue = { key: "isClassEquipment", value:  [true]};
        this.getGridDataSource<ServiceEquipments[]>(callDesc, ListId, isInitial,true,keyValue);
        break;
      default:
        break;
    }
  }

  GetAllCategory(result, extraParams) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetCategoryAndSubCategoryLink",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      extraParams: extraParams
    });

  }

  bindGridData<T>(data: BaseResponse<T>, belongTo?: GridType, isInitial?: boolean) {
    if (!this.setupService.IsAuthorized(belongTo)) {
      return;
    }
    if (!data.successStatus) {
      this.serverError = data.errorDescription;
      return;
    }
    const gridDS: SettingsGrid<T> = {
      dataheader: this.ViewSettingBusiness.getDataheader(belongTo),
      datacolumn: data.result == null ? [] : data.result
    };
    if (belongTo === GridType.therapist || belongTo === GridType.instructorSetup) {
      const therapistGridArr = this.setupService.formTherapistGridData(<any>data.result);
      gridDS.datacolumn = therapistGridArr == null ? [] : therapistGridArr;
    }
    if (belongTo === GridType.colorLink) {
      const linkCodeGridArr = data.result;
      gridDS.datacolumn = linkCodeGridArr == null ? [] : linkCodeGridArr;

    }
    this.ArrayGridData = [];
    this.ArrayGridData.push(gridDS);
    this.ArrayList = this.ArrayGridData;
    this.SearChArray = _.cloneDeep(this.ArrayGridData);
    switch (belongTo) {
      case GridType.group:
        this.ButtonName = this.localization.captions.setting.CreateServiceGroup;
        this.PlaceHolder = this.localization.captions.setting.GroupCodeDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingServiceGroup]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingServiceGroup);
        this.populateTableInfo(true, false, belongTo, '', "listOrder", true, data, isInitial);
        break;
      case GridType.location:
        this.ButtonName = this.localization.captions.setting.CreateServiceLocation;
        this.PlaceHolder = this.localization.captions.setting.LocationDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingServiceLocation]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingServiceLocation);
        this.populateTableInfo(true, false, belongTo, '', "listOrder", true, data, isInitial);
        break;
      case GridType.equipment:
        this.ButtonName = this.localization.captions.setting.ServiceEquipment;
        this.PlaceHolder = this.localization.captions.setting.EquipmentCodeEquipmentDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingServiceEquipment]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingServiceEquipment);
        this.populateTableInfo(false, false, belongTo, '', "description", true, data, isInitial);
        break;
      case GridType.medical:
        this.ButtonName = this.localization.captions.setting.CreateMedicalCondition;
        this.PlaceHolder = this.localization.captions.setting.ConditionCodeDescriptionConditionWarning;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingMedicationCondition]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingMedicationCondition);
        this.populateTableInfo(false, false, belongTo, '', "description", true, data, isInitial);
        break;
      case GridType.addons:
        this.ButtonName = this.localization.captions.setting.createServiceAddon;
        this.PlaceHolder = this.localization.captions.setting.AddOns;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingServiceAddOn]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingServiceAddOn);
        this.mapAddonWithRetailCategory(data.result);
        this.populateTableInfo(false, false, belongTo, '', "addOnName", true, data, isInitial);
        break;
      case GridType.spaServices:
        this.ButtonName = this.localization.captions.setting.CreateSpaService;
        this.PlaceHolder = this.localization.captions.setting.SpaServiceSearch;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingSpaService]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingSpaService);
        this.populateTableInfo(true, false, belongTo, 'S', "listOrder", true, data, isInitial);
        break;
      case GridType.therapist:
        this.ButtonName = this.localization.captions.setting.CreateTherapist;
        this.PlaceHolder = this.localization.captions.setting.SearchPlaceholderTherapist;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingTherapistSetup]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingTherapistSetup);
        this.populateTableInfo(true, false, belongTo, 'T', "listOrder", true, data, isInitial);
        break;
      case GridType.spaPackage:
        this.ButtonName = this.localization.captions.setting.CreateSpaPackage;
        this.PlaceHolder = this.localization.captions.setting.SearchPlaceholderPackage;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingSpaPackage]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingSpaPackage);
        this.populateTableInfo(false, false, belongTo, 'P', "description", true, data, isInitial);
        break;
      case GridType.colorLink:
        this.ButtonName = this.localization.captions.setting.CreateColorLink;
        this.PlaceHolder = this.localization.captions.setting.LinkCodeLinkDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingColorLinking]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingColorLinking);
        this.populateTableInfo(true, false, belongTo, "", "listOrder", true, "", isInitial);
        break;
      case GridType.customfield:
        const displayFieldOptionsAvailable = [
          this.localization.captions.setting.Notdisplayed,
          this.localization.captions.setting.Onclientonly,
          this.localization.captions.setting.Onappointmentonly,
          this.localization.captions.setting.Bothonclientandonappointment
        ];
        const gridData = <any>data.result;
        if (gridData && gridData.length > 0) {
          gridData.forEach(element => {
            element.columnName = element.fieldName ? element.fieldName : element.columnName;
            element.fieldName = displayFieldOptionsAvailable[element.displayOn];
          });
        }
        this.ButtonName = this.localization.captions.setting.CreateCustomField;
        this.PlaceHolder = this.localization.captions.setting.CustomFieldDisplayField;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingCustomFields]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingCustomFields);
        this.populateTableInfo(false, false, belongTo, '', "columnName", true, data, isInitial);
        break;
      case GridType.breakType:
        this.ButtonName = this.localization.captions.setting.CreateBreakType;
        this.PlaceHolder = this.localization.captions.setting.BreakTypeCodeBreakTypeDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingBreakType]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingBreakType);
        this.populateTableInfo(false, false, belongTo, "", "description", true, "", true);
        break;
      case GridType.priceType:
        this.ButtonName = this.localization.captions.setting.CreatePriceType;
        this.PlaceHolder = this.localization.captions.setting.PriceTypeCodePriceTypeDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingPriceType]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingPriceType);
        this.populateTableInfo(false, false, belongTo, "", "description", true, "", true);
        break;
      case GridType.packageClasses:
        this.ButtonName = this.localization.captions.setting.CreatePackageClass;
        this.PlaceHolder = this.localization.captions.setting.PackageClassCodePackageClassName;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingPackageClasses]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingPackageClasses);
        this.populateTableInfo(false, false, belongTo, "", "className", true, "", true);
        break;
      case GridType.cancelReasons:
        this.ButtonName = this.localization.captions.setting.CreateCancelReason;
        this.PlaceHolder = this.localization.captions.setting.CancelReason;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingCancelReason]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingCancelReason);
        this.ArrayList[0].datacolumn.forEach(x => x['Status'] = x['isActive'] ? this.spaConfig.captions.setting.Active : this.spaConfig.captions.setting.Inactive)
        this.populateTableInfo(false, false, belongTo, "", "reasonCode", false, "", true);
        break;
      case GridType.guestType:
        this.ButtonName = this.localization.captions.setting.CreateGuestType;
        this.PlaceHolder = this.localization.captions.setting.GuestTypeCodeDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.GuestType]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.GuestType);
        this.populateTableInfo(true, false, belongTo, '', "listOrder", true, data, isInitial);
        break;
      case GridType.relationship:
        this.ButtonName = this.localization.captions.setting.CreateRelationship;
        this.PlaceHolder = this.localization.captions.setting.RelationshipCodeName;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.Relationship]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.Relationship);
        if( typeof this.ArrayList[0]?.datacolumn != 'string')
        this.ArrayList[0].datacolumn.forEach(x => x['Status'] = x['isActive'] ? this.spaConfig.captions.setting.Active : this.spaConfig.captions.setting.Inactive)
        this.populateTableInfo(true, true, belongTo, 'R', "listOrder", true, data, isInitial);
       break;
      case GridType.classGroup:
        this.ButtonName = this.localization.captions.setting.CreateClassGroup;
        this.PlaceHolder = this.localization.captions.setting.classCodeDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingClassGroup]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingClassGroup);
        this.populateTableInfo(true, false, belongTo, '', "listOrder", true, data, isInitial);
      break;
      case GridType.classSetup:
        this.ButtonName = this.localization.captions.setting.CreateClass;
        this.PlaceHolder = this.localization.captions.setting.ClassSetupSearch;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingClassSetup]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingClassSetup);
        this.populateTableInfo(true, false, belongTo, 'C', "listOrder", true, data, isInitial);
      break;
      case GridType.instructorSetup:
        this.ButtonName = this.localization.captions.setting.createInstructor;
        this.PlaceHolder = this.localization.captions.setting.SearchPlaceholderInstructor;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingInstructorSetup]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingInstructorSetup);
        this.populateTableInfo(true, false, belongTo, 'I', "listOrder", true, data, isInitial);
      break;
      case GridType.classLocation:
        this.ButtonName = this.localization.captions.setting.createClassLocation;
        this.PlaceHolder = this.localization.captions.setting.classLocationDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingClassLocation]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingClassLocation);
        this.populateTableInfo(true, false, belongTo, '', "listOrder", true, data, isInitial);
        break;
      case GridType.classEquipment:
        this.ButtonName = this.localization.captions.setting.createClassEquipment;
        this.PlaceHolder = this.localization.captions.setting.classEquipmentCodeEquipmentDescription;
        this.IsAccessAllowed = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingClassEquipment]);
        this.IsViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingClassEquipment);
        this.populateTableInfo(false, false, belongTo, '', "description", true, data, isInitial);
        break;
      default:
        break;
    }
  }

  mapAddonWithRetailCategory(serviceAddons: any) {
    if (serviceAddons) {
      serviceAddons.forEach(element => {
        if (this.Category.some(x => x.id == element.retailCategoryId)) {
          let categorydescription = this.Category.find(x => x.id == element.retailCategoryId).name;
          element.retailCategory = categorydescription;
        }
      });
    }
  }

  getGridDataSource<T>(routeURL: string, dataBelongTo: GridType, isInitial?: boolean,isClass?:boolean,keyValue?:any): void {
    if(isClass)
      {
    this.ViewSettingBusiness.getServiceDataWithQueryParam<T>(HttpMethod.Get, routeURL,
      this.successCallback.bind(this), this.errorCallback.bind(this), true, keyValue,[dataBelongTo, isInitial]);
    }
    else
    {
      this.ViewSettingBusiness.getServiceData<T>(HttpMethod.Get, routeURL,
        this.successCallback.bind(this), this.errorCallback.bind(this), true, [dataBelongTo, isInitial]);
    }
  }

  populateTableInfo(draggable: boolean, blnPagination: boolean, selectedSettingID: number, checkType: string, sortedByDefault: string,
    sortable: boolean,
    lastModifiedData: any,
    isInitial?: boolean): void {
    this.tableoptions = [{
      TableHdrData: this.ArrayList[0].dataheader,
      TablebodyData: this.ArrayList[0].datacolumn,
      ServiceId: this.serviceId,
      pagination: blnPagination,
      sortable: sortable,
      CustomColumn: true,
      TableSearchText: this.searchText,
      PlaceHoldertext: this.PlaceHolder,
      EnableActions: this.EnableActions,
      TableDraggable: draggable,
      InactiveTherapists: checkType === 'T',
      InactiveService: checkType === 'S',
      InactivePackage: checkType === 'P',
      InactiveRelationship: checkType === 'R',
      InactiveClass: checkType === 'C',
      InactiveInstructor: checkType === 'I',
      SelectedSettingId: selectedSettingID,
      Sortable: sortedByDefault,
      SelectRows: false,
      NewData: lastModifiedData ? lastModifiedData.NewData : {},
      isInitial: isInitial,
      Searchable: selectedSettingID !== GridType.customfield,
      EditMoreOption: false,
      disableDelete: selectedSettingID === GridType.customfield,
      IsViewOnly: this.IsViewOnly,
      IsAccessAllowed: this.IsAccessAllowed,
      disableEditButton: this.IsViewOnly
    }];
  }

  gridRefresh(serviceType: GridType, result: any): void {
    this.bindGridData(result, serviceType);
  }

  public EditRecords(e: Array<any>, afterClose = true): void {
    const popUpbelonTo: GridType = e[1];
    const id = e[0].id;
    switch (popUpbelonTo) {
      case GridType.therapist:
        this.GetDetailsForId(id, 'GetTherapistAggregate');
        break;
      case GridType.spaServices:
        this.GetDetailsForId(id, 'GetSpecificService');
        break;
      case GridType.spaPackage:
        this.GetDetailsForId(id, 'GetSpecificPackage');
        break;
      case GridType.equipment:
        this.GetDetailsForId(id, 'GetEquipment');
        break;
      case GridType.location:
        this.GetDetailsForId(id, 'GetLocation');
        break;  
      case GridType.customfield:
        if (id && parseInt(id) > 0) {
          this.GetDetailsForId(id, 'GetCustomSpecificField');
        }
        break;
      case GridType.classSetup:
        this.GetDetailsForId(id, 'GetSpecificService');
        break;
      case GridType.classEquipment:
        this.GetDetailsForId(id, 'GetEquipment');
        break;
      case GridType.classLocation:
        this.GetDetailsForId(id, 'GetLocation');
        break; 
      case GridType.instructorSetup:
        this.GetDetailsForId(id, 'GetTherapistAggregate');
        break; 
      default:
        this.openDialog(e[0], popUpbelonTo, 'Edit', afterClose);
        break;
    }
  }


  ngOnInit() {

    this.locObj = this.localization.captions.setting;
    let keyValue: any = {};
    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.serviceId = this.service[data.serviceList].id; //Default DropDown
      [this.initialLoads, this.callCounter] = this._ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
      switch (this.serviceId) {
        case GridType.lunchsetup:
          this.ButtonName = this.localization.captions.setting.CreateLunchSetup;
          this.lunchSetup = true;
          break;
        case GridType.colorLink:
          this.ButtonName = this.localization.captions.setting.CreateColorLink;
          this.PlaceHolder = this.localization.captions.setting.LinkCodeLinkDescription;
          this.GetLinkCodeDetail(this.propertyDate, this.inactiveBoolControl.value, [GridType.colorLink, this.inactiveBoolControl.value]);
          break;
        case GridType.classGroup:
          keyValue = { key: "isClassGroup", value:  [true]};
          this.getGridDataSource<ServiceGroup[]>(this.service[data.serviceList].callDesc, data.type, true,true,keyValue);
          break;
        case GridType.classEquipment:
          keyValue = { key: "isClassEquipment", value:  [true]};
          this.getGridDataSource<ServiceEquipments[]>(this.service[data.serviceList].callDesc, data.type, true,true,keyValue);
          break;
        case GridType.classLocation:
          keyValue = { key: "isClassLocation", value:  [true]};
          this.getGridDataSource<ServiceLocations[]>(this.service[data.serviceList].callDesc, data.type, true,true,keyValue);
          break;
        case GridType.classSetup:
          keyValue = { key: "isClass", value:  [true]};
          this.getGridDataSource<SpaService[]>(this.service[data.serviceList].callDesc, data.type, true,true,keyValue);
          break;
        case GridType.instructorSetup:
          keyValue = { key: "isInstructor", value:  [true]};
          this.getGridDataSource<ServiceTherapist[]>(this.service[data.serviceList].callDesc, data.type, true,true,keyValue);
          break;
        default:
          this.getGridDataSource<ServiceGroup[]>(this.service[data.serviceList].callDesc, data.type, true);
          break;
      }
      this.service = this.ViewSettingBusiness.getSetupBreakPoints(this.service);
    });
  }

  ngOnDestroy() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
    if (this.alertDialogSubscription) {
      this.alertDialogSubscription.unsubscribe();
    }
    if (this.settingDialogSubscription) {
      this.settingDialogSubscription.unsubscribe();
    }
    if (this.promptSubscription) {
      this.promptSubscription.unsubscribe();
    }
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  showInactiveTherapist(e: any) {
    let keyValue: any = {};
    if (e) {
      this.TAll = true;
      if(this.serviceId==GridType.instructorSetup)
        {
          keyValue = { key: "isInstructor", value:  [true]};
          this.getGridDataSource<ThearapistDetails[]>("GetAllTherapist", GridType.instructorSetup,null,true,keyValue);
        }
        else
        {
          this.getGridDataSource<ThearapistDetails[]>("GetAllTherapist", GridType.therapist);
        }
    } else {
      this.TAll = false;
      
      if(this.serviceId==GridType.instructorSetup)
        {
          keyValue = { key: "isInstructor", value:  [true]};
          this.getGridDataSource<ThearapistDetails[]>("getActiveTherapist", GridType.instructorSetup,null,true,keyValue);
        }
        else
        {
          this.getGridDataSource<ThearapistDetails[]>("getActiveTherapist", GridType.therapist);
        }
    }
  }

  GetDetailsForId(id: string, callDesc: string): any {
    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: callDesc,
      method: HttpMethod.Get,
      uriParams: { id: id },
      showError: true,
      extraParams: []
    });
  }


  GetLinkCodeDetail(propertyDate, showInActive: boolean, extraParams) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: 'GetLinkCodes',
      method: HttpMethod.Get,
      uriParams: { propertyDate: propertyDate, showInActive: showInActive },
      showError: true,
      extraParams: extraParams
    });
  }

  GetCancelReasons(callDesc, extraParams) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: callDesc,
      method: HttpMethod.Get,
      showError: true,
      extraParams: extraParams
    });
  }

  async GetAllRelationships(callDesc, extraParams){
    this.http.CallApiWithCallback<any>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback,
        callDesc: callDesc,
        method: HttpMethod.Get,
        showError: true,
        extraParams:extraParams
    });
  }



  DeleteCalls(params: any, callDesc: string, showError, extraParams, body?: any) {
    this.http.CallApiWithCallback<ServiceLocations[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      uriParams: params,
      body: body,
      method: HttpMethod.Delete,
      showError: showError,
      extraParams: extraParams
    });
  }
  DeleteCallsWithQueryString(params: any, callDesc: string, showError, extraParams,keyValue:any, body?: any) {
    
    this.http.CallApiWithCallback<ServiceLocations[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      uriParams: params,
      body: body,
      method: HttpMethod.Delete,
      showError: showError,
      extraParams: extraParams,
      queryString: keyValue
    });
  }

  patchCalls(params: any, callDesc: string, extraParams) {
    this.http.CallApiWithCallback<ServiceLocations[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: callDesc,
      uriParams: params,
      method: HttpMethod.Patch,
      showError: true,
      extraParams: extraParams
    });
  }

  patchCallsWithQueryString(params: any, callDesc: string, extraParams,keyValue:any) {
    this.http.CallApiWithCallback<ServiceLocations[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: callDesc,
      uriParams: params,
      method: HttpMethod.Patch,
      showError: true,
      extraParams: extraParams,
      queryString: keyValue
    });
  }

  AddDefaultCustomFields() {
    this.http.CallApiWithCallback<any>({
      host : Host.spaManagement,
      success : this.successCallback.bind(this),
      error : this.errorCallback,
      callDesc : 'AddDefaultCustomFields',
      method : HttpMethod.Post,
      extraParams : [GridType.customfield]
    });
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    switch (callDesc) {
      case "GetAllServiceGrp":
      case "GetAllLoc":
      case "GetAllTherapist":
      case "GetAllMC":
      case "GetAllEquip":
      case "GetAllSpaService":
      case "GetAllTherapist":
      case "GetSpaPackages":
      case "GetAllBreakTypes":
      case "GetAllPackageClasses":
      case "GetBreakType":
      case "GetAllGuestType":
      case "GetAllPriceTypes":
      case "GetAllRelationships":
      case "getActiveTherapist":
      case "GetLinkCodes":
      case 'GetActiveCancelReason':
      case "ServiceGrpDragDrop":
      case "ServiceDragDrop":
      case "LocationDragDrop":
      case "GuestTypeDragDrop":
      case "dragAndDropActiveTherapist":
      case "dragAndDropTherapist":
      case "RelationshipDragDrop":
        [this.initialLoads, this.callCounter] = this._ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
        this.bindGridData(result, extraParams[0], extraParams[1]);
        this._ss.tabLoaderEnable.next(false);
        break;

       case 'GetCustomFields':
       case 'AddDefaultCustomFields':
        let response : [] = <any>result.result;
        if (response == null || response.length === 0) {
          this.AddDefaultCustomFields();
        } else {
        [this.initialLoads, this.callCounter] = this._ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
        this.bindGridData(result, extraParams[0], extraParams[1]);
        this._ss.tabLoaderEnable.next(false);
        }
        break;

        case "GetAllAddOns":
        this.serviceAddOnsResult = result;
        this.GetAllCategory(result, extraParams);
        break;
      case "DeleteSpaService": {
        this.onChange();
      }
        break;
      case "deleteLoc":
      case "deleteEquip":
      case "deleteMC":
      case "DeleteServiceGrp":
      case "DeleteAddOn":
      case "DeleteBreakType":
      case "DeletePriceType":
      case "DeleteGuestType":
      case "DeleteRelationship":
      case "DeletePackageClass":
      case "DeleteCancelReason":
        {
          this.inactiveRecords();
        }
       // break; // do not add break for sonar fix. this flow needs to be continued till line# 912.
      case "DeleteSpaService":
      case "DeletePackage":
      case 'GetAllCancelReasons':
        this.gridRefresh(extraParams[0], result);
        break;
      case "GetSpecificService": {
        this.openDialog(result.result, this.serviceId, 'Edit');
      } break;
      case "GetTherapistAggregate": {
        this.openDialog(result.result, this.serviceId, 'Edit');
      } break;
      case "GetSpecificPackage": {
        this.openDialog(result.result, GridType.spaPackage, 'Edit');
      } break;
      case "GetEquipment": {
        this.openDialog(result.result, this.serviceId, 'Edit');
      } break;
      case "GetLocation": {
        this.openDialog(result.result, this.serviceId, 'Edit');
      } break;
      case "DeleteLinkCode":
      case "LinkCodeDragDown": {
        this.GetLinkCodeDetail(this.propertyDate, this.inactiveBoolControl.value, [GridType.colorLink, this.inactiveBoolControl.value]);
        this._ss.tabLoaderEnable.next(false);
      } break;
      case "DeleteTherapist": {
        const showAll = { checked: true };
        const showActive = { checked: false };
        if (this.TAll) {
          this.showInactiveTherapist(showAll);
        } else {
          this.showInactiveTherapist(showActive);
        }
      }
        break;
      case "GetCustomSpecificField": {
        this.openDialog(result.result, GridType.customfield, 'Edit');
      }
        break;
      case "GetCategoryAndSubCategoryLink":
        {
          let response = <any>result.result;
          this.Category = response ? this.utils.sortObj(response.category, "listOrder") : [];
          this.mapAddonWithRetailCategory(this.serviceAddOnsResult.result);
          this.bindGridData(this.serviceAddOnsResult, extraParams[0], extraParams[1]);
          this._ss.tabLoaderEnable.next(false);
        }
        break;
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === "deleteMC") {
      switch (error.errorCode) {
        case -45:
        case -46:
        case -47:
          this.MedicalDeleteCode = extraParams[1];
          this.utils.ShowErrorMessage(this.localization.captions.setting.MedicalCondition, this.localization.getError(error.errorCode) + " " + this.localization.captions.setting.Continue, ButtonType.YesNo, this.PopupCallback.bind(this));
          break;
        default:
          this.utils.ShowErrorMessage(this.localization.captions.setting.MedicalCondition, this.localization.getError(error.errorCode));
          break;
      }
    }
    else if (callDesc == "GetAllServiceGrp") {
      [this.initialLoads, this.callCounter] = this._ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
    }
  }

  accessCheckAndAlert(setupMenu: SetupMenu) {
    if (!setupMenu.IsAuthorized) {
      this.BP.showBreakPointPopup(this.localization.captions.breakpoint[setupMenu.breakPointNumber]);
    }
  }

  PopupCallback(result: any, extraParams?: any[]) {
    if (result.toLowerCase() == "YES".toLowerCase()) {
      const urlParam = this.MedicalDeleteCode + "/true";
      this.DeleteCalls(urlParam, 'deleteMC', true, [GridType.medical]);
    }
    this.MedicalDeleteCode = "";
  }
}
