import { Pipe, PipeTransform } from '@angular/core';
import { SpaUtilities } from '../utilities/spa-utilities';
import { appointmentService } from '../service/appointment.service';
 

@Pipe({
  name: 'getTextColorPipes'
})
export class GetTextColorPipesPipe implements PipeTransform {

  constructor(public utils: SpaUtilities, public _appointmentService: appointmentService) { }
  transform(status) {
    return this.utils.getTextColor(this._appointmentService, status);
  }
}