import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { EmailConfigModel, SavedFiltersModel, ScheduleReportDataModel, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';

export const SaveScheduleReportDataConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, Value?: any): any => {
    try {   
        const homeGroupValue = Value.HeaderFormValue;
        const FilterString = Value.FilterString;
        const FormGroupData = Value.FormGroupData;
        const ScheduleReportObject = {} as ScheduleReportDataModel;
        ScheduleReportObject.Id = FormGroupData.Id;        
        ScheduleReportObject.Name = homeGroupValue.description ? homeGroupValue.description : FormGroupData.Name;
        let FileName = '';
        if (homeGroupValue.description) {
            const matches = homeGroupValue.description.match(/\[(.*?)\]/);
            if (matches) {
                FileName = matches[1];
            }
        }
        ScheduleReportObject.FileName = FileName ? FileName :
         FormGroupData.FileName;
        ScheduleReportObject.Remarks = FormGroupData.Remarks;
        ScheduleReportObject.SiteId = FormGroupData.SiteId;
        ScheduleReportObject.Description = FormGroupData.Description;
        ScheduleReportObject.SavedFilters = {} as SavedFiltersModel;
        ScheduleReportObject.SavedFilters.Id = FormGroupData.SavedFilters.Id;
        ScheduleReportObject.SavedFilters.IsActive = FormGroupData.SavedFilters.IsActive;
        ScheduleReportObject.SavedFilters.FilterString = FilterString ? JSON.stringify(FilterString) :
         FormGroupData.SavedFilters.FilterString; 
        ScheduleReportObject.SavedFilters.Period = FormGroupData.SavedFilters.Period;
        ScheduleReportObject.Cron = homeGroupValue.CronExpression ? homeGroupValue.CronExpression :
         FormGroupData.Cron;
        ScheduleReportObject.StartDate = FormGroupData.StartDate;
        ScheduleReportObject.EndDate = FormGroupData.EndDate;
        ScheduleReportObject.Frequency = (FormGroupData.Frequency !== null &&
            FormGroupData.Frequency !== undefined) ? 
            FormGroupData.Frequency : '';
        ScheduleReportObject.RecurrenceArray = FormGroupData.RecurrenceArray ? 
        FormGroupData.RecurrenceArray : null;
        ScheduleReportObject.ScheduledTime = FormGroupData.ScheduledTime ? 
        FormGroupData.ScheduledTime : '';
        ScheduleReportObject.LastRunTime = FormGroupData.LastRunTime;
        ScheduleReportObject.NextRunTime = FormGroupData.NextRunTime;
        ScheduleReportObject.UserId = FormGroupData.UserId;
        ScheduleReportObject.IsActive = FormGroupData.IsActive ? FormGroupData.IsActive : false;
        ScheduleReportObject.CreatedDateTime = FormGroupData.CreatedDateTime;
        ScheduleReportObject.LastUpdatedDateTime = FormGroupData.LastUpdatedDateTime;
        ScheduleReportObject.CreatedBy = FormGroupData.CreatedBy;
        ScheduleReportObject.LastUpdatedBy = FormGroupData.LastUpdatedBy;
        ScheduleReportObject.CurrnetProcessingInstanceId = FormGroupData.CurrnetProcessingInstanceId;
        ScheduleReportObject.ProcessingStartTime = FormGroupData.ProcessingStartTime;
        ScheduleReportObject.EmailConfig = {} as EmailConfigModel;
        ScheduleReportObject.EmailConfig.Id = FormGroupData.EmailConfig.Id;
        ScheduleReportObject.EmailConfig.Description = '';
        ScheduleReportObject.EmailConfig.ToEmail = FormGroupData.EmailConfig.ToEmail;
        ScheduleReportObject.EmailConfig.BCCEmail = '';
        ScheduleReportObject.EmailConfig.CCEmail = '';
        ScheduleReportObject.EmailConfig.FromEmail = '';
        ScheduleReportObject.IanaTimeZoneId = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
        ScheduleReportObject.UserTimeZoneId = new Date()?.toString()?.split("(")[1]?.split(")")[0];
        const PostData = Object.assign({}, ScheduleReportObject);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Schedule Report Data Construction', error);
    }
};
