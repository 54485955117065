import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import {
  ValidationSummaryDownloadModel,
  TransactionConfiguration,
} from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';

export const ValidationSummaryDownload: DefineDataObjectConfig = (
  OdataKey: string,
  commonService: CommonService,
  JsonData: TransactionConfiguration,
  LocationIds: any,
  transactionService: ViewTransactionService,
  IsActive: boolean,
  sysytemPreferenceService: SysytemPreferenceService,
  requestEngine?: RequestEngine,
  value?: any
): any => {
  try {
    const dataImportModel = {} as ValidationSummaryDownloadModel;
    let Type: any;
    if (value.name.includes('Multiple')) {
      Type = 'Multiple';
    } else {
      Type = 'Single';
    }
    dataImportModel.type = Type;
    dataImportModel.TenantId = parseInt(sessionStorage.getItem('TenantId'), 10);
    dataImportModel.id = value.id;
    dataImportModel.workflowId = value.workflowId;
    dataImportModel.name = value.name;
    dataImportModel.launchType = value.launchType;
    dataImportModel.description = value.description;
    dataImportModel.status = value.status;
    dataImportModel.startedDate = value.startedDate;
    dataImportModel.statusDate = value.statusDate;
    dataImportModel.validationSummaryPath = value.validationSummaryPath;
    dataImportModel.totalCount = value.totalCount;
    dataImportModel.wexflowAuthToken = sessionStorage.getItem('WexFlowLogin');
    dataImportModel.tenantIdentifier =
      sessionStorage.getItem('TenantIdentifier');
    dataImportModel.linkedHistory = value.linkedHistory;
    const PostData = Object.assign(dataImportModel);
    return PostData;
  } catch (error) {
    console.error('Error occurred in Data Import Upload Construction', error);
  }
};
