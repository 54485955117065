import { HttpType } from "src/app/eatecui/source/shared/enum/global.enum";
import { OptionListConfiguration, OptionMappingBase, RgetMapping } from "../models/data-mapping.interface";
import { PayloadMappingEngine } from "../payload/payload-mapping";
import { BaseDataMapping } from "./base-data-mapping";
import { RequestPagination } from "../pagination/requestpagination";

export class OptionListMapping extends PayloadMappingEngine implements OptionMappingBase{
    optionListConfiguration: OptionListConfiguration;
    constructor(optionListConfiguration: OptionListConfiguration){
        super(optionListConfiguration.FetchAll, optionListConfiguration.LoggedInLocationId);
        this.optionListConfiguration = optionListConfiguration;
    }
    OptionConfig: OptionListConfiguration;
    /**
     * @method DataMapping()
     * @input None
     * @outPut None
     */
    DataMapping(): void {
        try{
          const MappingMethod: RgetMapping = this.GetMappingUrl(this.optionListConfiguration);
          if( MappingMethod.HttpClinetType === HttpType.POSTMETHOD ){
            let getPayload: any;
            // if (this.optionListConfiguration.RequestEndPointUrl.includes('GetCustomers')) {
            //     getPayload = this.constructGetCustomersPayload(this.optionListConfiguration);
            // } else {
                const requestEngine = new RequestPagination('Name', 1, true, this.optionListConfiguration.LoggedInLocationId);
                getPayload = requestEngine.getPaginationPayload(0, 100);
                if (this.optionListConfiguration.CustomPayload) {
                    getPayload = {...getPayload, ...this.optionListConfiguration.CustomPayload};
                }
                if (this.optionListConfiguration.IsDateIncluded) {
                    getPayload['postDate'] = new Date();
                }
            // }            
            this.optionListConfiguration.httpService.PosthttpMethod(MappingMethod.ReturnUrl, getPayload).subscribe((resposeData: any)=>{
             const responseData: Array<any> = (resposeData.ResultData) ? resposeData.ResultData : 
                (resposeData.value) ? resposeData.value : resposeData;
             const baseMappingData: Array<any> = BaseDataMapping.BaseDataMapping(responseData);
             this.optionListConfiguration.Responsecallback(baseMappingData)
            });
          } else if (MappingMethod.HttpClinetType === HttpType.POSTMETHODBYID) {
            const getPayload = this.optionListConfiguration.CustomPayload;
            this.optionListConfiguration.httpService.PosthttpMethod(MappingMethod.ReturnUrl, getPayload).subscribe((resposeData: any)=>{
             const responseData: Array<any> = (resposeData.ResultData) ? resposeData.ResultData : resposeData;
             const baseMappingData: Array<any> = BaseDataMapping.BaseDataMapping(responseData);
             this.optionListConfiguration.Responsecallback(baseMappingData)
            });
          } else {
            this.optionListConfiguration.httpService.GethttpMethod(MappingMethod.ReturnUrl).subscribe((resposeData: any)=>{
                const responseData: Array<any> = (resposeData.ResultData) ? resposeData.ResultData : 
                    (resposeData.value) ? resposeData.value : resposeData;
                const baseMappingData: Array<any> = BaseDataMapping.BaseDataMapping(responseData);
                this.optionListConfiguration.Responsecallback(baseMappingData)
            })
          }
         
        } catch(error){
            console.error(error);
        }
    }

    /**
     * @method GetMappingUrl
     * @input optionListConfiguration: OptionListConfiguration
     * @output urlString
     * @description mappingUrl
     */
    GetMappingUrl( optionListConfiguration: OptionListConfiguration ): RgetMapping {
        try {
            const MappingUrl = {} as RgetMapping;
            if( optionListConfiguration.HttpType === HttpType.GETMETHOD){
                super.TransactionType = optionListConfiguration.TransactionType;
                super.FromLocation = optionListConfiguration.FromLocation;
                MappingUrl.HttpClinetType = HttpType.GETMETHOD;
                MappingUrl.ReturnUrl = super.MappingGetPayload(optionListConfiguration.RequestEndPointUrl);
            } else if (optionListConfiguration.HttpType === HttpType.POSTMETHODBYID) {
                MappingUrl.HttpClinetType = HttpType.POSTMETHODBYID;
                MappingUrl.ReturnUrl = super.MappingGetPayload(optionListConfiguration.RequestEndPointUrl);
            } else {
                MappingUrl.HttpClinetType = HttpType.POSTMETHOD;
                MappingUrl.ReturnUrl = super.MappingGetPayload(optionListConfiguration.RequestEndPointUrl);
            }
            return MappingUrl;
        } catch(error){
            console.error(error);
        }
    }
    /**
     * @method constructGetCustomersPayload
     */
    constructGetCustomersPayload(optionListConfiguration: any): any {
        try {
            const customerPostPayload = {} as any;
            customerPostPayload.transTypeId = optionListConfiguration.TransactionType;
            customerPostPayload.locationId = 0;
            customerPostPayload.searchText = '';
            customerPostPayload.skip = 0;
            customerPostPayload.take = 1000;
            customerPostPayload.getTotalRecCount = true;
            customerPostPayload.fetchAll = false;
            customerPostPayload.totalCount = 0;
            customerPostPayload.lookupField = '';
            customerPostPayload.orderByColumn = 'Name';
            customerPostPayload.orderByDirection = 1;
            return customerPostPayload;
        } catch (error) {
            console.log(error);
        }
    }
}