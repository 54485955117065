import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setcolumnvalue'
})
export class SetcolumnvaluePipe implements PipeTransform {

  transform(value: boolean, bodyCnt: any, jsonkey: string): string {
    jsonkey = jsonkey ? jsonkey : "";
    if (jsonkey.includes("+")) {
      let result = "";
      let arrKey = jsonkey.split("+");
      for (let i = 0; i < arrKey.length; i++) {
        let val = bodyCnt[arrKey[i]];
        val = val ? val : "";
        result = result + (i == 0 ? "" : " ") + val;
      }
      return result;
    }
    return bodyCnt[jsonkey];
  }

}
