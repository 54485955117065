import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-member-accrual-map-crud',
  templateUrl: './member-accrual-map-crud.component.html',
  styleUrls: ['./member-accrual-map-crud.component.scss']
})
export class MemberAccrualMapCrudComponent implements OnInit {

  commonCaptions: any;
  captions: any;
  memberaccrualmapform: UntypedFormGroup;
  isValid:boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isFromResortFinance : boolean = false;
  @Input() dataInput: any;
  @Input() LinkType:number;

  constructor(private dialog:MatDialog,private fb:UntypedFormBuilder,private localization: RetailLocalization,private dialogRef: MatDialogRef<MemberAccrualMapCrudComponent>) { }

  ngOnInit(): void {
    this.initializeForm();
    this.isFromResortFinance = this.dataInput[0]?.isFromResortFinance?? false;
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.memberaccrualmapform.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.isValid = (this.memberaccrualmapform.dirty)
    });
    if(this.dataInput[0][this.dataInput[1]] !='—'){
      this.patchBucketValue(this.dataInput);
    }
  }

  initializeForm(){
    this.memberaccrualmapform = this.fb.group({
      bucketcode:[''],
      bucketname:[''],
      applyForOutletsForTheSamePaymentMethod: false,
      applyForAllPaymentMethodsForTheSameOutlet: false
    })
  }

  patchBucketValue(data){
    console.log(data);
    this.memberaccrualmapform.controls.bucketcode.setValue(data[0][data[1]]);
    this.memberaccrualmapform.controls.bucketname.setValue(data[0][data[1]+'Bucket Name']);
    this.memberaccrualmapform.controls.applyForOutletsForTheSamePaymentMethod.setValue(false);
    this.memberaccrualmapform.controls.applyForAllPaymentMethodsForTheSameOutlet.setValue(false); 
  }

  saveMappingType(){
    this.dialogRef.close(this.memberaccrualmapform)
  }

  onClick(){
    this.dialogRef.close()
  }

  applyForOutletsForTheSamePaymentMethod(event){   
    this.memberaccrualmapform.controls.applyForOutletsForTheSamePaymentMethod.setValue(event);
  }

  applyForAllPaymentMethodsForTheSameOutlet(event){   
    this.memberaccrualmapform.controls.applyForAllPaymentMethodsForTheSameOutlet.setValue(event);
  }
}
