import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { BreadCrumbConfiguration } from '../transaction.interface';
import { ChangeBreadcrumbConfig } from './create-transaction-config.interface';

export const HttpMethodSelection: ChangeBreadcrumbConfig =
    (BreadCrumbConfig: Array<BreadCrumbConfiguration>, RouterQueryParams: any): any => {
        let returnData: string = HttpMethods.Post;
        const isTransAction = RouterQueryParams['transactionFrom'] ? RouterQueryParams['transactionFrom'] :
        RouterQueryParams['salesFrom'];
        const transferType = RouterQueryParams['transaction'] ? RouterQueryParams['transaction'] : RouterQueryParams['master'];
        const salesType = RouterQueryParams['sales'];
        const rowId = RouterQueryParams['rowId'];
        if ((transferType === 'requisition' && isTransAction === 'OpenRequisition') ||
            (transferType === 'openpurchaserequest' && isTransAction === 'OpenRequests') ||
            ((transferType === 'pientercounts' || transferType === 'pientercyclecounts') && rowId > 0) ||
            (transferType === 'purchaseorder' && isTransAction === 'EditPO') ||
            (transferType === 'savedreceiving' && isTransAction === 'SavedReceiving') ||
            (transferType === 'apvoucherrni' && isTransAction === 'AllRNI') ||
            (transferType === 'template' && isTransAction === 'EditTemplate') ||
            (transferType === 'Packages' && rowId > 0) ||
            ((salesType === 'salesforecasting' || salesType === 'cycleplanning'
            || salesType === 'productionplan') && rowId > 0 && isTransAction === 'EditSales')) {
            returnData = HttpMethods.Put;
        }
        return returnData;
    };