import {Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { CancellationNoShowPolicyBusiness } from './cancellation-no-show-policy.business';
import { MatDialog } from '@angular/material/dialog';
import { ActionMode, AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { CommonUtilities } from '../utilities/common-utilities';
import { CancallationNoShowSharedService } from './cancellation-no-show-shared.service';
import { SubjectDataType } from './cancellation-no-show-policy.model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cancellation-no-show-policy',
  templateUrl: './cancellation-no-show-policy.component.html',
  styleUrls: ['./cancellation-no-show-policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CancellationNoShowPolicyComponent implements OnInit {

  captions : any;
  @Input() tableContent;
  @Input() isViewOnly = true;
  @Input() chargeOptions;
  originalcontent = [];
  tableOptions: any = [];
  searchText = '';
  IsEditModeEnabledFlag: boolean;
  showInactivetog:boolean=false;
  createToggler = false;
  createModalData: createModal;
  crudActionInput;
  onEditDisableFunction = true;
  isCopyPolicy = false;
  customEditCancelled : boolean = false;
  isSliderChanged : boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input("tableOptions")
  set tableValues(value: any){
    if(value != null && value.length > 0){
      this.tableOptions = value;
      this.loadTableData();
    }
  }

  constructor(private localization: Localization,private business: CancellationNoShowPolicyBusiness,
    private cancellationNoShowService: CancallationNoShowSharedService, public dialog: MatDialog, public utils: CommonUtilities) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.utils.ToggleLoader(true);
    this.cancellationNoShowService.data$.pipe(takeUntil(this.destroyed$)).subscribe(payload => {
      if (payload) {
        if(payload.type == SubjectDataType.SendPolicy) {
        if (this.isCopyPolicy)
          this.loadCopyData(payload.data);
        else
          this.loadEditData(payload.data);
      }
        else if (payload.type == SubjectDataType.APIResponse || payload.type == SubjectDataType.DeleteAPIResponse) {
          if (payload.data == 0) {
            let alertMessage = payload.type == SubjectDataType.APIResponse ? this.captions.alertPopup.successfullysaved : this.captions.alertPopup.successfullyDeleted;
            this.utils.showAlert(alertMessage, AlertType.WellDone, ButtonType.Ok);
            this.backAction();
          }
        else if(payload.data == -1000) {
          // in golf we are not throwing alert popup, so used this check and ignored the alert
          this.backAction();
        }
        else{
          let errorMessage = payload.errorMessage == '' ? this.localization.getError(payload.data) : payload.errorMessage;
          this.utils.showError(errorMessage);
        }
      }
    }
    })
    setTimeout(() => {
      this.utils.ToggleLoader(false);
    }, 100)
  }

  loadTableData() {
    this.originalcontent = this.tableOptions[0].TablebodyData;
    this.showInActiveFilter();
  }

  createCancellation() {
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_save,
      form: undefined
    };
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.createCancellation
    };
  }

  showInactive(eve) {
    this.showInactivetog = !this.showInactivetog;
    this.showInActiveFilter();
  }

  showInActiveFilter() {
    if (this.showInactivetog)
      this.tableContent = this.originalcontent;
    else
      this.tableContent = this.originalcontent.filter(x => x.isActive);

    var tableData = [...this.tableOptions]
    tableData[0].TablebodyData = this.tableContent;
    this.tableOptions = tableData;
  }

  inactiveSliderChange(e){
    console.log(e);
    this.isSliderChanged = true;
    let alertMsg = e && e.data.isActive ? this.captions.lbl_showMappingInactiveAlertmsg : this.captions.lbl_showMappingActiveAlertmsg;
    this.utils.showAlert(alertMsg, AlertType.Info, ButtonType.Ok);
  }

  CancelEvent(event) {
    this.customEditCancelled = true;
    this.IsEditModeEnabledFlag = false;
    this.isSliderChanged = false;
  }

  async DoneEvent(event) {
    this.cancellationNoShowService.sendData(SubjectDataType.UpdateActive, event.id);
    this.showInActiveFilter();
    this.IsEditModeEnabledFlag = false;
    this.isSliderChanged = false;
  }

  searchTextChange(eve) {
    this.searchText = eve;
  }

  handleClick(event){
    switch (event.from) {
      case ActionMode.create:
         var obj = this.cancellationNoShowService.mapPolicyToObject(event.formValues, false);
         this.cancellationNoShowService.sendData(SubjectDataType.CreatePolicy, obj);
         break;
      case ActionMode.copy:
          var obj = this.cancellationNoShowService.mapPolicyToObject(event.formValues, false);
          this.cancellationNoShowService.sendData(SubjectDataType.CreatePolicy, obj);
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
      case ActionMode.update:
          var obj = this.cancellationNoShowService.mapPolicyToObject(event.formValues, true);
          this.cancellationNoShowService.sendData(SubjectDataType.UpdatePolicy, obj);
        break;
    }
  }

  back(e) {
    this.backAction();
  }

  backAction(form?) {
    this.customEditCancelled = true;
    this.IsEditModeEnabledFlag = false;
    this.createToggler = false;
  }

  edit(rowData: any){
    this.IsEditModeEnabledFlag = true;
    this.customEditCancelled = false;
    this.cancellationNoShowService.sendData(SubjectDataType.GetPolicy, rowData[0].id);
    this.isCopyPolicy = false;
  }

  loadEditData(PolicyData: any)
  {
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.editCancellation
    };
    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: PolicyData
    };
  }

  copyRow(rowData: any){
    this.IsEditModeEnabledFlag = true;
    this.cancellationNoShowService.sendData(SubjectDataType.GetPolicy, rowData.id);
    this.isCopyPolicy = true;
  }

  loadCopyData(policyData: any)
  {
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.createCancellation
    };
    this.crudActionInput = {
      mode: ActionMode.copy,
      actionButton: this.captions.btn_update,
      form: policyData
    };
  }

  deleteRow(rowData: any){
    this.utils.showAlert(this.captions.lbl_showMappingDeleteAlertmsg, AlertType.Warning, ButtonType.YesNo, (res) => {
      if (res == AlertAction.YES) {
        this.cancellationNoShowService.sendData(SubjectDataType.DeletePolicy, rowData[0].id);
      }
    })
  }
  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
