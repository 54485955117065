import { FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { PostPOSupplierConfig, SalesOthersSummary, TransactionConfiguration } from '../transaction.interface';
import { PostPo, SalesMediaAssMent } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopModel, SuggestQuantityPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { FormType, SingleField } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';
import { ConversionAlgorithm } from 'src/app/eatecui/source/shared/algorithms';
import { FormFieldType } from 'src/app/eatecui/source/setup-master/shared/interface/form-interface/fieldType.enum';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const SpecialInstruction: SalesMediaAssMent = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    homeGroup: FormGroup,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    salesMediaDetails: Array<SalesOthersSummary>,
    salesService?: any,
    commonService?: any
): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ItemSpecialInstruction')) {
            const DataInfo = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemSpecialInstruction;
            DataInfo.FormFields[0].fieldType.SingleData.value = homeGroup.controls[DataInfo.Name].value;
            const SuggestQtyDialog: SuggestQuantityPopUpModel = {
                PopupHeaderContent: DataInfo.PopupHeaderName,
                FormFields: DataInfo.FormFields,
                FormGroupData: DataInfo.FormGroupData,
                Classname: 'Special-instruction',
                Hide: transactionService.FlowMode === 'Copy' ? true : false
            };
            const dialogRef = dialog.open(AgilysysSuggestqtyPopupComponent, {
                width: '60%',
                height: 'auto',
                data: SuggestQtyDialog,
                maxWidth: '100vw',
                panelClass: 'Special-Instruction',
                hasBackdrop: true,
                autoFocus: false,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result && result[0] === 'Confirm') {
                    const FormData = result[1] as FormGroup;
                    homeGroup.controls[DataInfo.Name].setValue(FormData.value['specialinstruction']);
                }
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred Media Assignment', Error);
    }
};


