import { Directive, HostListener, ElementRef, OnInit, Input, SimpleChanges } from "@angular/core";
import { RetailLocalization } from "./retail-localization"
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { NgControl } from "@angular/forms";
import { cloneDeep } from "lodash";

@Directive({ selector: "[RetailCurrencyFormatter]" })
export class RetailCurrencyFormatterDirective implements OnInit {
  private el: HTMLInputElement;
  private negativeSign: string = "-";
  private allowNegative: boolean = false;
  private disabled: boolean = false;

  @Input()
  cDisabled?: any = "false";
  @Input()
  cAllowNegative?: any = 'false';
  @Input() skipOnInit = false;
  @Input('preDecimalLength') preDecimalLength = 12;
  @Input('postDecimalLength') postDecimalLength = 2;
  @Input('postDecimalLengthForTaxes') postDecimalLengthForTaxes;
  @Input() allowPaste = false;
  @Input('isFromTaxPercentage') isFromTaxPercentage;

  constructor(
    private elementRef: ElementRef, public Localization: RetailLocalization, private utils: RetailUtilities,private control: NgControl) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);        
    this.formatInput()  
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formatInput()  
  }

  formatInput() {
    if (this.disabled || this.skipOnInit)
      return;
    let value = (this.el.value || "").toString().trim();
    if(value != '') { 
      this.el.value = this.el.value ? this.el.value : '';
      let delocalizedNumber: number;

      if (this.el.value.includes(this.Localization.decimalSeparator)) {
        delocalizedNumber = this.Localization.currencyToSQLFormat(this.el.value);
      }
      else {
        delocalizedNumber = Number(this.el.value);
      }

      this.el.value = isNaN(Number(delocalizedNumber)) ? '' : this.Localization.localizeCurrency(delocalizedNumber, false, (this.postDecimalLengthForTaxes >= 0 ? this.postDecimalLengthForTaxes: this.postDecimalLength), (this.postDecimalLengthForTaxes? true: false),this.isFromTaxPercentage? this.isFromTaxPercentage : false);
      this.control.control.setValue(this.el.value); 
    }
  }  

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.el.readOnly || this.disabled)
      return;
    this.el.value = this.Localization.removeThousandSeparator(value);
    this.control.control.setValue(this.el.value);
    this.el.select()
  }

  @HostListener("paste", ['$event']) blockPaste(e: ClipboardEvent) {
    if (!this.allowPaste) {
      e.preventDefault();
    }
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    value = (value || value == 0) ? value.toString() : "";
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.el.readOnly || this.disabled)
      return;

    let splittedValue = value.split(this.Localization.decimalSeparator);
    if (splittedValue[0] || splittedValue[0] == 0) {
      splittedValue[0] = splittedValue[0].replace(this.Localization.thousandSeparator, "");
    }

    value = splittedValue.join(".");
    //Return Negative Values
    if (value < 0 && !this.allowNegative) {
      value = "";
    }

    this.el.value = this.Localization.localizeCurrency(value, false, (this.postDecimalLengthForTaxes >= 0? this.postDecimalLengthForTaxes: this.postDecimalLength), (this.postDecimalLengthForTaxes? true: false),this.isFromTaxPercentage? this.isFromTaxPercentage : false);
    this.control.control.setValue(this.el.value);
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.el.readOnly || this.disabled)
      return;
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    let e = <KeyboardEvent>event;
    if (this.IsMaxLengthExceeded(e)) {
      e.preventDefault();
      return;
    }
    this.InputTypeNumberValidation(e);
  }

  IsMaxLengthExceeded(e: KeyboardEvent) {
    let isLengthExceeded = false;
    if (e.key == this.Localization.decimalSeparator || this.AllowDefaultKeys(e)) {
      return isLengthExceeded;
    }
    let input = (<HTMLInputElement>e.target);
    let value = input.value.toString().replace("-", "");
    if (value.indexOf(this.Localization.decimalSeparator) == -1) { // validate non decimal when decimal separator not entered
      isLengthExceeded = value.length == this.preDecimalLength;
    }
    else if (input.selectionStart <= value.indexOf(this.Localization.decimalSeparator)) // validate non decimal when decimal separator entered
    {
      let splitArray = value.split(this.Localization.decimalSeparator);
      isLengthExceeded = splitArray[0].length == this.preDecimalLength;
    }
    else { // validate decimal length
      let splitArray = value.split(this.Localization.decimalSeparator);
      if(this.postDecimalLengthForTaxes >= 0){
        this.postDecimalLength = cloneDeep(this.postDecimalLengthForTaxes);
      } else if(sessionStorage.getItem('noOfDecimalDigits')){
        let propDigits = parseInt(sessionStorage.getItem('noOfDecimalDigits'));
        if(!isNaN(this.postDecimalLength)){
          this.postDecimalLength = propDigits;
        }
      }
      isLengthExceeded = splitArray[1].length == this.postDecimalLength;
    }
    return isLengthExceeded;
  }

  AllowDefaultKeys(e: KeyboardEvent) {
    if (  //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return true;
    }
    return false;
  }

  InputTypeNumberValidation(e: KeyboardEvent): void {
    let input = (<HTMLInputElement>e.target);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : "";
    if (
      // Allow function keys
      (e.keyCode >= 112 && e.keyCode <= 123) ||
      //allow minus - onlynumber,only negative
      (this.allowNegative && (input.selectionStart == 0 && e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length))) ||
      // Decimal Seperator - Region
      (valueEntered.indexOf(this.Localization.decimalSeparator) == -1 && this.Localization.decimalSeparator == e.key) ||
      this.AllowDefaultKeys(e)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) ||
      //non negative , includes zero
      (e.key == this.negativeSign)
    ) {
      e.preventDefault();
    }
  }
  setDirectiveConfigurations(cAllowNegative, cDisabled) {
    this.disabled = (cDisabled || cDisabled.toLowerCase()) == "true" ? true : false;
    this.allowNegative = (cAllowNegative && cAllowNegative.toLowerCase()) == "true" ? true : false;
  }
}
