export namespace API {
    export interface SFTPConnection {
        id: number,
        connectionName?: string,
        hostName: string,
        userName: string,
        password: string,
        remotePath: string,
        port: number,
        isEnabled: boolean,
        ftpProtocol: FtpProtocols
    }

    export interface SFTPConnectionTableContent {
        id: number,
        connectionName?: string,
        hostName: string,
        userName: string,
        password: string,
        remotePath: string,
        port: number,
        ftpProtocol: FtpProtocols;
        isEnabled: {
            value: boolean;
            isDisabled: boolean;
        };
    }
}

export namespace UI {
    export interface SFTPConnection {
        id: number,
        connectionName?: string,
        hostName: string,
        userName: string,
        password: string,
        remotePath: string,
        port: number,
        isEnabled: boolean,
        ftpProtocol: FtpProtocols;
    }
}


export enum FtpProtocols {
    FTP,
    SFTP   
}
