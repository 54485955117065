
export interface CartPayload {
  refId: string; // Guid - UniqueIdentifier
  productCode: string; // SPA/GOLF/ACTIVITY
  tenantId: number;
  propertyId: number;
  addedBy: number; // User Id
  expireAt: number; // minutes - to invoke or handle temp hold
  itemInfo: ItemInfo;
  uRIInfo: uRIInfo;
  guestInfo?: GuestInfo[];
  cardInfo?: cardInfo;
}

export interface ItemInfo {
  name: string;
  startdate: Date;
  endDate: Date;
  description: string;
  comments: string;
  price: number;
}

export interface uRIInfo {
  verb: string; // POST/PUT
  path: string; // Relative URI path
  payload: string; // Request payload
  headers: HeaderValue[]; // Request headers, to pass session, bearer token
}
export interface HeaderValue {
  key: string;
  value: string;
}
export interface GuestInfo {
  gpsId?: string; // Guid
  firstName: string;
  lastName: string;
}

export interface cardInfo {
  tokenId: string;
  cardName: string;
  number: string,
  expiryDate: string;
  cvc: string;
}
export enum ExistingPlayerTypes {
  firstName = 0,
  lastName = 1,
  fullName = 2,  
  email = 3,
  phoneNumber = 4  
}

export enum GuestType{
  SpaGuest = 1,
  OperaGuest = 2
}