import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, DropDownData } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class CorrectVoid extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,public business: ReportBusinessService,
         private func: RetailFunctionalityBusiness ,  public propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.CorrectVoid,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;

        let clerkCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allUsers"],this.fillFilter(customFilterData.users.filter(u=>u.showInDropDown)));

        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) }, {'pDate': this.printedDate},
                { "pPageBreak": this.pageBreakToggle}, 
                { "pIncludeInactiveUsers": customFilterData.includeInactiveusers},
                {"pFilterOutlets":this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ? this.getFilterCaption : this.getDefaultOutletCaption },
                 {"pFilterClerks":clerkCaption},{"pPropertyName":this.propertyName},
                {"pPaymentMethod":this.localization.captions.reports.RetailInterfacePayment},
                {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ? true : false } ];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }

    protected getMultiSelectValues(values): any {
        return values.map(o => o.id+"|"+o.description);
    }
    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        let correctVoidCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutletFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.isJasperReportEnabled)
        {
            let outletids = this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ? this.getMultiSelectValuesForJasper('outletOptions')
            : this.getDefaultOutletIdForJasper;
            return{
                StartDate: toApi(this.startDate),
                EndDate: toApi(this.endDate),
                OutletIds: outletids,
                UserIds: this.getValuesForJasper(correctVoidCustomFilterData.users.filter(u => u.showInDropDown)),
                IncludeInactiveUsers: this.reportFormGrp.controls['customFilterFormGrp'].value.includeInactiveusers,
                currentUserId: this.localization.GetUserInfo('userId'),
                PageBreak: this.pageBreakToggle
            };
        }
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                StartDate: toApi(this.startDate),
                EndDate: toApi(this.endDate),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ? this.fillFilter(this.selectedFilterOptions)
                    : tempOutletFalse,
                UserIds: this.fillFilter(correctVoidCustomFilterData.users.filter(u => u.showInDropDown)),
                includeInactiveusers: correctVoidCustomFilterData.includeInactiveusers,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            };  
        }
        return {
            StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ? this.fillFilter(this.selectedFilterOptions)
                : tempOutletFalse,
            UserIds: this.fillFilter(correctVoidCustomFilterData.users.filter(u => u.showInDropDown))
        };
    }

    fillFilter(customFilterData: any): number[] {
        return customFilterData.length === 0 ? 0 : customFilterData.map(x => x.id);
    }

}


