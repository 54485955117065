import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DropDownData } from '../../../business/report.modals';
import { HttpServiceCall } from '../../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../../shared/utilities/spa-utilities';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss']
})
export class ShiftComponent implements OnInit {
  public ShiftFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  @Input() outlets: any;
  @Output() disableGenerateEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() validateUser = new EventEmitter<boolean>();
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  selectedDropdownType: string = '';
  valuesSelected: any[];
  users: DropDownData[];
  inActiveText = '';
  registers: any;
  validSelection: boolean = true;
  validSelectionErrMsg:string;
  floatLabel:string;
   constructor(private fb: UntypedFormBuilder, private http: HttpServiceCall, private utils: SpaUtilities, public business: ReportBusinessService, private dataService: ReportDataService, public localization: SpaLocalization) {
    this.inActiveText = this.captions.IncludeInactiveUsers;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.ShiftFormGrp = this.fb.group({
      users: new UntypedFormControl([]),
      includeInactiveusers: false,
      startingCash:0
    });
    this.formReady.emit(this.ShiftFormGrp);
    this.onLoad();
  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsers();
    this.users = this.business.toggleIncludeInactive(false,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown );
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.users = this.business.toggleIncludeInactive(IncludeInactiveToo,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
    this.ShiftFormGrp.controls['users'].setValue([this.users]);
  }

  // filterDropDownSelected(event: MatSelectChange, data: DropDownData, allData: DropDownData[], dropDownType) {
  //   if (dropDownType !== this.selectedDropdownType) {
  //     this.valuesSelected = [];
  //   }
  //   if (data && data.description.toLowerCase() === 'all') {
  //     this.valuesSelected = this.business.toggleClickbtn(this.defaultData, allData, this.valuesSelected, this.defaultData);
  //   } else {
  //     this.valuesSelected = this.business.toggleClickbtn(data, allData, this.valuesSelected, this.defaultData);
  //   }
  //   this.ShiftFormGrp.controls[dropDownType].setValue(this.valuesSelected);
  //   this.selectedDropdownType = dropDownType;
  // }

  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption:any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validSelectionErrMsg = this.localization.replacePlaceholders(caption,["option"],[this.captions.User]);
    }
  }

  updateCash(value:any)
  {
    this.business.startingCash = value;
  }

  getFormControlValue(event) {
    if (event[0] === 'users') {
    this.ShiftFormGrp.setControl('users', event[1]);
    }
    // else if (event[0] === 'register') {
    //   this.ShiftFormGrp.setControl('register', event[1]);
    // }
  }
}
