import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators,UntypedFormArray } from '@angular/forms'; 
import { EmptyValueValidator } from '../../shared/Validators/EmptyValueValidator';
import {  popupConfig } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-retail-create-new-supplier',
  templateUrl: './retail-create-new-supplier.component.html',
  styleUrls: ['./retail-create-new-supplier.component.scss']
})
export class RetailCreateNewSupplierComponent implements OnInit {

  FormGrp: UntypedFormGroup;
  textCaptions:any;
  contactTypeEmail: any = [];
  contactTypePhone: any = [];
  phoneRequired: boolean;
  emailRequired:boolean;
  currentIndexPhone: any = 0;
  currentIndexemail:any=0;
  Phone:any;
  @Input() dataInput:any;
  textmaskFormat:string;
  Email: any;
  @Input() popupConfigs: popupConfig;
  clickbutton:string;
  validateEmailType:string;
  validatePhoneType:string;
  floatLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<RetailCreateNewSupplierComponent>,
   private Form: UntypedFormBuilder ,
   public localization: RetailLocalization,
   private cmnutils: CommonUtilities,
   private utils: RetailUtilities) {
    this.floatLabel = this.localization.setFloatLabel;
    }

  ngOnInit() {
    this.textCaptions=this.localization.captions.retailsetup;
    this.contactTypePhone = this.localization.ContactTypes.Phone;
    this.contactTypeEmail = this.localization.ContactTypes.Email;
    this.validateEmailType = this.localization.getError(-87);
    this.validatePhoneType = this.localization.getError(-88);
    const PhoneFormat = this.cmnutils.getPhoneFormat();
    this.textmaskFormat = PhoneFormat != '' ? PhoneFormat : '999999999999999999';
    this.FormGrp = this.Form.group({
      code: ['', [Validators.required]],
      name: ['', [Validators.required]],
      accountrep: ['', [Validators.required]],
      address: [''],
      phone: this.Form.array([this.createPhoneItem(0, '', '')]),
      email:this.Form.array([this.createEmailItem(0,'','')]),
      postalcode: [''],
      account:[''],
      terms:[''],
      ap:[''],
      comment:['']
    });

    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.textCaptions.SAVE;
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.textCaptions.UPDATE;
      console.log(this.dataInput);

        this.FormGrp.controls.code.setValue(this.dataInput[0].suppliercode);
        this.FormGrp.controls.name.setValue(this.dataInput[0].suppliername);
        this.FormGrp.controls.accountrep.setValue(this.dataInput[0].accountrep);
        this.FormGrp.controls.ap.setValue(this.dataInput[0].ap);
        this.FormGrp.controls.address.setValue(this.dataInput[0].address);
        this.FormGrp.controls.postalcode.setValue(this.dataInput[0].postalcode);
        this.FormGrp.controls.account.setValue(this.dataInput[0].account);
        this.FormGrp.controls.terms.setValue(this.dataInput[0].terms);
        this.FormGrp.controls.comment.setValue(this.dataInput[0].comment);

        if (this.dataInput[0].email && this.dataInput[0].email.length > 0) {
          this.dataInput[0].email.forEach((element, i) => {
            this.addEmailItem(i, element.EmailLabel, element.EmailId);
          });
          this.Email.removeAt(0);
          this.currentIndexemail=this.dataInput[0].email.length-1;
        }

        if (this.dataInput[0].phoneDetails && this.dataInput[0].phoneDetails.length > 0) {
          this.dataInput[0].phoneDetails.forEach((element, i) => {
            const PhoneFormat = this.cmnutils.getPhoneFormat();
            this.addPhoneItem(i, element.PhoneNumberLabel, this.utils.appendFormat(element.PhoneNumber, PhoneFormat));
          });
          this.Phone.removeAt(0);

          this.currentIndexPhone=this.dataInput[0].phoneDetails.length-1;
        }
        console.log(this.currentIndexemail);
    }
  }

  onNoClick(){
    this.dialogRef.close();
  }

  createPhoneItem(arr: number, _phoneNoLabel: any, _phoneNoDetails: any): UntypedFormGroup {
    return this.Form.group({
      PhoneNumberLabel: [_phoneNoLabel,this.phoneRequired? [Validators.required,EmptyValueValidator]:''],
      PhoneNumber: [_phoneNoDetails,this.phoneRequired? [Validators.required,EmptyValueValidator]:''],

    });
  }

  removePhoneItem(i:any, e?:any, d?:any) {
    this.Phone.removeAt(i);
    this.currentIndexPhone = i - 1;
  }


  addPhoneItem(i, _phoneNoLabel: any, _phoneNoDetails: any): void {
    this.Phone = this.FormGrp.get('phone') as UntypedFormArray;

    this.Phone.push(this.createPhoneItem(i, _phoneNoLabel, _phoneNoDetails));
    this.currentIndexPhone = i + 1;
  }

  createEmailItem(arr: number, _EmailLabel?: any, _EmailId?: any): UntypedFormGroup {

    return this.Form.group({

      EmailLabel: [_EmailLabel,this.emailRequired? [Validators.required,EmptyValueValidator]:'']  ,
      EmailId:[_EmailId,this.emailRequired? [Validators.required,EmptyValueValidator]:'']  ,


    });
  }


  addEmailItem(i, _EmailLabel?: any, _EmailId?: any): void {
    this.currentIndexemail = i + 1;
    this.Email = this.FormGrp.get('email') as UntypedFormArray;
    this.Email.push(this.createEmailItem(i, _EmailLabel, _EmailId));
  }

  removeEmailItem(i:any, d?:any, f?:any) {
    this.Email.removeAt(i);
    this.currentIndexemail = i - 1;
  }

  saveServiceGroup(data){
    if (this.popupConfigs.operation == "create") {
      console.log("created successfully =",data);
    } else if (this.popupConfigs.operation == "edit") {
      console.log("edited succesfully =",data);
    }
    this.dialogRef.close();
  }

}
