import { Component, Inject, OnInit, ViewEncapsulation, ViewContainerRef, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { popupConfig } from '../retail.modals';
import {PostTypeMappingCrudComponent} from '../retail-code-setup/post-type-mapping/post-type-mapping-crud/post-type-mapping-crud.component'
import {ResortFinancePostTypeMappingCrudComponent } from '../retail-code-setup/resort-finance-post-type-mapping/resort-finance-post-type-mapping-crud/resort-finance-post-type-mapping-crud.component';
import { GeneralLedgerMappingCrudComponent } from '../retail-code-setup/general-ledger-mapping/general-ledger-mapping-crud/general-ledger-mapping-crud.component';

@Component({
  selector: 'app-retail-popup',
  templateUrl: './retail-popup.component.html',
  styleUrls: ['./retail-popup.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class RetailPopupComponent implements OnInit {
  datRec:any;
  popupConfigs: popupConfig;
  tableData:any;
  LinkType:number;
  isMultiplePmsPropertyEnabled: boolean = false;
  pmsPropCode:string = '';
  automationId: string ='';
  PostType:string;
  @ViewChild(PostTypeMappingCrudComponent) postTypeCrudComponent:PostTypeMappingCrudComponent;
  @ViewChild(ResortFinancePostTypeMappingCrudComponent) resortfinancepostTypeCrudComponent: ResortFinancePostTypeMappingCrudComponent;
  @ViewChild(GeneralLedgerMappingCrudComponent) generalLedgerMappingCrudComponent: GeneralLedgerMappingCrudComponent;
  constructor( public dialogRef: MatDialogRef<RetailPopupComponent>, @Inject( MAT_DIALOG_DATA ) public data: any,
   public viewContainerRef: ViewContainerRef){

   }

  onNoClick(): void {
    if (this.data?.templatename === 'PTM'){
      this.postTypeCrudComponent.onNoClick()
    }
    else if(this.data?.templateName === 'RPTM'){
      this.resortfinancepostTypeCrudComponent.onNoClick()
    }
    else if(this.data?.templatename === 'GLM'){
      this.generalLedgerMappingCrudComponent.onNoClick()
    }
      else {
      this.dialogRef.close();
    }
  }

  ngOnInit(){
    this.datRec = this.data.datarecord;
    this.LinkType=this.data.LinkType;
    this.popupConfigs = this.data.popupConfig;
    this.tableData = this.data.tabledata;
    this.isMultiplePmsPropertyEnabled = this.data?.isMulitPropertyFeatureEnabled? this.data.isMulitPropertyFeatureEnabled : false;
    this.pmsPropCode = this.data?.pmsPropCode? this.data.pmsPropCode : '';
    this.automationId = this.data?.automationId;
    this.PostType = this.data?.selectedPostType;
  }

}
