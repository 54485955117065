import { Directive, HostListener, ElementRef, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Directive({ selector: "[CurrencyFormatter]" })
export class CurrencyFormatterDirective implements OnInit, AfterViewInit {
  private el: HTMLInputElement;
  private negativeSign = '-';
  private allowNegative = false;
  private disabled = false;
  private decimalLocus = 3;
  private regex: RegExp = new RegExp(/^\-?\d*\.?\d{0,2}$/g);

  @Input()
  cDisabled?: any = 'false';
  @Input()
  cAllowNegative?: any = 'false';
  @Input()
  cDecimalLocus = 3;
  @Input()
  preDecimalmaxLength?;
  @Input()
  maxVal?:number|string;
  constructor(
    private elementRef: ElementRef, public Localization: SpaLocalization, private control: NgControl) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.disabled) {
      return;
    }
    this.el.value = this.el.value ? this.el.value : '';
    let delocalizedNumber: number;
    const exp = '^-?\\d*\\' + this.Localization.decimalSeparator + `?\\d{0,${this.Localization.fractionLength}}$`;
    this.regex = new RegExp(exp, 'g');

    if (this.el.value.includes(this.Localization.decimalSeparator)) {
      delocalizedNumber = this.Localization.currencyToSQLFormat(this.el.value); // to fix localization issue in 27126
    } else {
      delocalizedNumber = Number(this.el.value);
    }

    this.el.value = isNaN(Number(delocalizedNumber)) ? '' : this.Localization.localizeCurrency(delocalizedNumber, false);
    if (this.el.value) {
      if (this.el.value === this.Localization.localizeCurrency(0, false)) {
        return;
      }
      this.control.control.setValue(this.el.value);
    }
  }

  ngAfterViewInit() {
    this.formatCurrencyValue(this.el.value);
  }

  @HostListener('keyup', ['$event.target.value'])
  onKeyUp(value) {
    if (this.preDecimalmaxLength) {
      const splitedValue = value.split(this.Localization.decimalSeparator);
      const afterDecimal = splitedValue[1] ? splitedValue[1] : '';
      if (splitedValue[0].length <= this.preDecimalmaxLength) {
        const combinedValue =
          splitedValue.length > 1 ? `${splitedValue[0]}${this.Localization.decimalSeparator}${afterDecimal}` : splitedValue[0];
        this.control.control.setValue(combinedValue);
      } else {
        const combinedValue =
          splitedValue.length > 1 ?
            `${splitedValue[0].slice(0, this.preDecimalmaxLength)}${this.Localization.decimalSeparator}${afterDecimal}` :
            splitedValue[0].slice(0, this.preDecimalmaxLength);
        this.control.control.setValue(combinedValue);
      }
    }

  }
  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.el.readOnly || this.disabled) {
      return;
    }
    this.el.maxLength = this.Localization.getMaxCurrencyLength();
    this.el.value = this.Localization.removeThousandSeparator(value);
    this.control.control.setValue(this.el.value, {emitEvent : false});
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.formatCurrencyValue(value);
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.el.readOnly || this.disabled) {
      return;
    }
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    const e = event as KeyboardEvent;
    this.InputTypeNumberValidation(e);
  }

  private formatCurrencyValue(value) {
    if (value === this.Localization.localizeCurrency(0, false)) {
      return;
    }
    value = (value || value == 0) ? value.toString() : '';
    this.setDirectiveConfigurations(this.cAllowNegative, this.cDisabled);
    if (this.el.readOnly || this.disabled) {
      return;
    }

    const splittedValue = value.split(this.Localization.decimalSeparator);
    if (splittedValue[0] || splittedValue[0] == 0) {
      splittedValue[0] = splittedValue[0].replace(this.Localization.thousandSeparator, '');
    }

    value = splittedValue.join(this.Localization.decimalSeparator);
    if (value < 0 && !this.allowNegative) {
      value = '';
    }
    value = this.Localization.currencyToSQLFormat(value);
    this.el.value = this.Localization.localizeCurrency(value, false);
    this.el.maxLength = this.el.value.length;
    this.control.control.setValue(this.el.value);
    if(this.maxVal && value>this.maxVal){
      this.control.control.setErrors({max:true});
    }

  }


  InputTypeNumberValidation(e: KeyboardEvent): void {
    const input = (e.target as HTMLInputElement);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : '';
    if (
      // Allow function keys
      (e.keyCode >= 112 && e.keyCode <= 123) ||
      // allow minus - onlynumber,only negative
      (this.allowNegative && (input.selectionStart == 0 && e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length))) ||
      // Decimal Seperator - Region
      (valueEntered.indexOf(this.Localization.decimalSeparator) == -1 && this.Localization.decimalSeparator == e.key) ||
      // Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) ||
      // non negative , includes zero
      (e.key == this.negativeSign)
    ) {
      e.preventDefault();
    }
    if (valueEntered.indexOf(this.Localization.decimalSeparator) == -1 && (this.el.value.length >= (this.el.maxLength - this.decimalLocus))) {
      e.preventDefault();
    }
    const position = this.el.selectionStart;
    const next: string = [valueEntered.slice(0, position), e.key == this.Localization.decimalSeparator ? this.Localization.decimalSeparator : e.key, valueEntered.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      e.preventDefault();
    }
  }
  setDirectiveConfigurations(cAllowNegative, cDisabled) {
    this.disabled = (cDisabled || cDisabled.toLowerCase()) == 'true' ? true : false;
    this.allowNegative = (cAllowNegative && cAllowNegative.toLowerCase()) == 'true' ? true : false;
  }
}
