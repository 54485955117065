import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { CRUDDLBusiness } from './crud-distribution-list.business';
import { EventDataServices } from 'src/app/common/dataservices/notification/event.data.service';
import { EventActorUIModel, DistributionListApi, DistributionListFormGroup, CollectionType, Collection } from 'src/app/common/Models/notification.model';
import { DistributionListDataService } from 'src/app/common/dataservices/notification/distributionList.service';
import _ from 'lodash';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/Models/common.models';
import { SendToMail } from './crud-distribution-list.model';
import { Product } from 'src/app/common/enums/shared-enums';
@Component({
  selector: 'app-crud-distribution-list',
  templateUrl: './crud-distribution-list.component.html',
  styleUrls: ['./crud-distribution-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [EventDataServices, DistributionListDataService, CRUDDLBusiness]
})
export class CrudDistributionListComponent implements OnInit {
  @Input() showTableRecord: boolean;
  @Output() emittedDLobj: EventEmitter<any> = new EventEmitter();
  @Input() distributionData: DistributionListApi;
  @Input() isViewOnly;
  @Input() isEditEnabled;
  isEditMode: boolean;
  distributionListForm: UntypedFormGroup;
  crudDLtemplateCaptions: any;
  commoncaptions: any;
  saveUpdLabel: any;
  HeaderLabel: any;

  ToDD_Data: EventActorUIModel[] = [];
  ToListCollection: any = [];
  ToListCollectionFlag: boolean = false;

  ccDD_Data: EventActorUIModel[] = [];
  ccListCollection: any = [];
  ccListCollectionFlag: boolean = false;

  BccDD_Data: EventActorUIModel[] = [];
  BccListCollection: any = [];
  BccListCollectionFlag: boolean = false;

  dropDownFilterData: any = [];
  EnablesaveUpdBtn: any;
  selectedIndex = 0;
  floatLabel: string;
  sendToOptionList: { id: SendToMail; value: SendToMail; viewValue: any; isSelected: boolean; }[];
  defaultSelectedSendTo: SendToMail;
  ToExpadCollapse: boolean = true;
  ccExpandCollapse: boolean = true;
  bccExpandCollapse: boolean = true;
  selectedCategory = SendToMail
  ToTotalCount: number = 0;
  ccTotalCount: number = 0;
  bccTotalCount: number = 0;
  productId: any;
  sncId = Product.SALESANDCATERING;
  pmsId = Product.PMS;
  captions: any;
  constructor(private localization: Localization, private commonUtils: CommonUtilities,
              private fb: UntypedFormBuilder, private _CRUDDLBusiness: CRUDDLBusiness, private _cdRef: ChangeDetectorRef) {    
    this.crudDLtemplateCaptions = this.localization.captions.settings.utilities.distributionlist;
    this.commoncaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.defaultSelectedSendTo = SendToMail.To;
    this.isEditMode = this.isEditEnabled;
    this.captions = this.localization.captions;
  }
  ngOnChanges() {

    this.isEditMode = this.isEditEnabled;
  }
  ngAfterViewInit() {
    this._cdRef.detectChanges();
  }
  ngOnInit() {
    this.isEditMode = this.isEditEnabled;
    this.productId = Number(this.commonUtils.GetPropertyInfo('ProductId'));
    this.saveUpdLabel = this.commoncaptions.Save;
    this.HeaderLabel = this.isEditMode ? this.crudDLtemplateCaptions.updateDl : this.crudDLtemplateCaptions.newDL;
    this.generateDLform();
    this.sendToOptionList = this._CRUDDLBusiness.getSendToMailOptions();
    this.getDDdata().then(o => {
      if (this.isEditMode) {
        this.saveUpdLabel = this.commoncaptions.Update;    
        this.patchFormValues(this.distributionData, o);
      } else {
        this.emailEnabled(true);
        this.smsEnabled(true);
        this.whatsappEnabled(true);
      }

    });
    if(this.productId == this.sncId){
      this.distributionListForm.controls.sms.setValue(false);
      this.distributionListForm.controls.whatsapp.setValue(false);
    }
    this.distributionListForm.statusChanges.subscribe(x => {
      const email = this.distributionListForm.controls['email'].value;
      const sms = this.distributionListForm.controls['sms'].value;
      const whatsapp = this.distributionListForm.controls['whatsapp'].value;
      this.EnablesaveUpdBtn = (email || sms || whatsapp) && this.distributionListForm.valid && !this.distributionListForm.pristine;
    });

  }
  private patchFormValues(distributionData: DistributionListApi, actorsUiData: EventActorUIModel[]) {
    const distributionListUivalue: DistributionListFormGroup = this._CRUDDLBusiness.mapToUI(distributionData, actorsUiData);
    this.emailEnabled(distributionListUivalue.email);
    this.smsEnabled(distributionListUivalue.sms);
    this.whatsappEnabled(distributionListUivalue.whatsapp);
    this.ToListCollection = distributionListUivalue.ToCollection.map(t => t.collection[0]);
    this.ccListCollection = distributionListUivalue.CcCollection.map(t => t.collection[0]);
    this.BccListCollection = distributionListUivalue.BccCollection.map(t => t.collection[0]);

    this.ToListCollectionFlag = this.ToListCollection.some(s => s.addtoTabView);
    this.ccListCollectionFlag = this.ccListCollection.some(s => s.addtoTabView);
    this.BccListCollectionFlag = this.BccListCollection.some(s => s.addtoTabView);
    this.distributionListForm.controls['distributionlistname'].setValue(distributionListUivalue.distributionlistname);
    this.distributionListForm.controls['email'].setValue(distributionListUivalue.email);
    this.distributionListForm.controls['sms'].setValue(distributionListUivalue.sms);
    this.distributionListForm.controls['whatsapp'].setValue(distributionListUivalue.whatsapp);
    this.distributionListForm.controls['fromEmail'].setValue(distributionListUivalue.fromEmail);
    this.distributionListForm.controls['To'].setValue(distributionListUivalue.To);
    this.distributionListForm.controls['CC'].setValue(distributionListUivalue.CC);
    this.distributionListForm.controls['Bcc'].setValue(distributionListUivalue.Bcc);
    this.distributionListForm.controls['fromName'].setValue(distributionData.fromName);
    this.calculateTotalForList();
    this.EnablesaveUpdBtn = false;
    if(this.isViewOnly){
      this.distributionListForm.disable();
    }  
  }

  generateDLform() {
    this.distributionListForm = this.fb.group({
      distributionlistname: ['', Validators.required],
      email: true,
      sms: true,
      whatsapp: true,
      fromEmail: [{ value: '', disabled: true }],
      To: [{ value: '', disabled: true }],
      CC: [{ value: '', disabled: true }],
      Bcc: [{ value: '', disabled: true }],
      fromName : ['']
    });
  }
  async getDDdata(): Promise<EventActorUIModel[]> {
    const eventActorsUi: EventActorUIModel[] = await this._CRUDDLBusiness.getEventActors();
    this.ToDD_Data = _.cloneDeep(eventActorsUi);
    this.ccDD_Data = _.cloneDeep(eventActorsUi);
    this.BccDD_Data = _.cloneDeep(eventActorsUi);
    return _.cloneDeep(eventActorsUi);
  }
  async save() {
    this.EnablesaveUpdBtn = false;
    const udpatedCollection = [
      {
        collection: this.ToListCollection,
        type: CollectionType.To
      },
      {
        collection: this.ccListCollection,
        type: CollectionType.Cc
      },
      {
        collection: this.BccListCollection,
        type: CollectionType.Bcc
      }
    ];
    if (!this.isEditMode) {
      await this._CRUDDLBusiness.saveDistributionList(this.distributionListForm.value, udpatedCollection);
    } else {
      await this._CRUDDLBusiness.updateDistributionList(this.distributionListForm.value, udpatedCollection, this.distributionData.id);
    }
    this.emittedDLobj.emit({ CrudDLWindow: true });
  }
  promptForUnSavedChanges() {
    if (this.showTableRecord) {
      if (this.distributionListForm.dirty) {
        this.commonUtils.showCommonAlert(this.crudDLtemplateCaptions.warn_datalost, AlertType.Warning, ButtonTypes.YesNo, (res) => {
          if (res === AlertAction.YES) {
           this.emittedDLobj.emit({ CrudDLWindow: true });
          }
          else {
            return;
          }
      });
      }
      else {
     this.emittedDLobj.emit({ CrudDLWindow: true });
      }
    }
    else {
     this.emittedDLobj.emit({ CrudDLWindow: true });
    }
    
  }
  emailEnabled(eve) {
    let controls = [];
    let smstoggleStatus = this.distributionListForm.controls['sms'].value;
    let whatsapptoggleStatus = this.distributionListForm.controls['whatsapp'].value;
    if (!eve && smstoggleStatus && whatsapptoggleStatus) {
      controls = ['fromEmail', 'CC', 'Bcc'];
    } else {
      controls = ['fromEmail', 'To', 'CC', 'Bcc'];
    }
    this.enableDisablectrl(eve, controls);
  }
  smsEnabled(eve) {
    let controls = [];
    let emailtoggleStatus = this.distributionListForm.controls['email'].value;
    if (!eve && emailtoggleStatus) {
      controls = [];
    } else {
      controls = ['To'];
    }
    this.enableDisablectrl(eve, controls);
  }

  whatsappEnabled(eve){
    let controls = [];
    let emailtoggleStatus = this.distributionListForm.controls['email'].value;
    if (!eve && emailtoggleStatus) {
      controls = [];
    } else {
      controls = ['To'];
    }
    this.enableDisablectrl(eve, controls);
  }
  
  enableDisablectrl(eve, control) {
    let controls = control;
    if (eve) {
      controls.forEach(x => {
        if (x == 'fromEmail' || x == 'To') {
          this.distributionListForm.controls[x].setValidators([Validators.required]);
        }
        this.distributionListForm.controls[x].enable();
      });
    } else {
      controls.forEach(x => {
        this.distributionListForm.controls[x].setValidators([]);
        this.distributionListForm.controls[x].setValue([]);
        this.distributionListForm.controls[x].disable();
        this.setCollectionlist(x, []);
      });
    }
  }
  setCollectionlist(frm, data) {
    switch (frm) {
      case 'To':
        this.ToListCollection = data;
        break;
      case 'CC':
        this.ccListCollection = data;
        break;
      case 'Bcc':
        this.BccListCollection = data;
    }
  }

  DDclicked(eve, clickeddata, frm, tocolllst: Collection[], tocolllstFlag) {

    if (eve.isUserInput) {
      if (eve.source.selected) {
        const selectedCollection: EventActorUIModel = this.getCollection(eve.source.value, frm);
        this.PUSHvalue(selectedCollection, tocolllst);
      } else {
        const selectedCollection = this.getCollection(eve.source.value, frm);
        this.POPValue(selectedCollection, tocolllst);
      }
      let flag = (tocolllst.filter(x => x.addtoTabView).length > 0);
      this.setFlag(frm, flag);
      this.EnablesaveUpdBtn = true;
    } else {
      
      this.EnablesaveUpdBtn = this.distributionListForm.valid && !this.distributionListForm.pristine;
    }
    this.calculateTotalForList();

  }
  getCollection(value: string, frm: string): EventActorUIModel {
    let collection: EventActorUIModel;
    switch (frm) {
      case 'To':
        collection = this.ToDD_Data.find(o => o.code == value);
        break;
      case 'CC':
        collection = this.ccDD_Data.find(o => o.code == value);
        break;
      case 'Bcc':
        collection = this.BccDD_Data.find(o => o.code == value);
        break;
    }
    return collection;
  }
  setFlag(frm, flag) {

    switch (frm) {
      case 'To':
        this.ToListCollectionFlag = flag;
        break;
      case 'CC':
        this.ccListCollectionFlag = flag;
        break;
      case 'Bcc':
        this.BccListCollectionFlag = flag;
        break;
    }
  }
  PUSHvalue(clickeddata: EventActorUIModel, tocolllst: Collection[]) {
    let collectionlist = clickeddata.collection;
    if (collectionlist.length > 0) {
      collectionlist.forEach(y => {
        tocolllst.push(y);
      });
    }
  }
  POPValue(clickeddata, tocolllst_pop) {
    let collectionlist = clickeddata.collection;
    if (collectionlist.length > 0) {
      collectionlist.forEach(y => {
        let index = tocolllst_pop.findIndex(s => s.listTabName == y.listTabName);
        if (index != -1) {
          tocolllst_pop[index].selectedList = [];
          tocolllst_pop.splice(index, 1);
        }
      });
    }
  }
  totabChanged(eve) {

    this.selectedIndex = eve.index;
  }
  opencloseAccordian(eve,selectedCategory) {


    switch(selectedCategory){
      case SendToMail.To:
        this.ToExpadCollapse = !this.ToExpadCollapse
        break;
      case SendToMail.CC:
        this.ccExpandCollapse = !this.ccExpandCollapse
        break;
      case SendToMail.BCC:
        this.bccExpandCollapse = !this.bccExpandCollapse
        break;
    }

    if (eve.currentTarget.querySelector('i').classList.contains('deg180')) {
      eve.currentTarget.querySelector('i').classList.remove('deg180');
    } else {
      eve.currentTarget.querySelector('i').classList.add('deg180');
    }

  }
  filterdataSectionEmit(eve, pushpopObj,selectedCategory) {
    if (eve.pushFlag) {
      pushpopObj.selectedList.push(eve.obj);
    } else {
      const index = pushpopObj.selectedList.findIndex(s => s.id == eve.obj.id);
      if (index != -1) {
        pushpopObj.selectedList.splice(index, 1);
      }
    }
    if (this.distributionListForm.valid) {
      this.EnablesaveUpdBtn = true;
    } else {
      this.EnablesaveUpdBtn = false;
    }

    switch(selectedCategory){
      case SendToMail.To:
        this.ToTotalCount = this.calculateTotalCount(this.ToListCollection)
        break;
      case SendToMail.CC:
        this.ccTotalCount = this.calculateTotalCount(this.ccListCollection)
        break;
      case SendToMail.BCC:
        this.bccTotalCount = this.calculateTotalCount(this.BccListCollection)
        break;
    }

  }
 
  removeRecp(obj, pushpopObj,selectedCategory) {
    let eve={
      'pushFlag':false,
      'obj':obj
    };
    this.filterdataSectionEmit(eve, pushpopObj,selectedCategory);
    pushpopObj.selectedList = [...pushpopObj.selectedList];
  }
  ngOnDestroy(): void {
    if (!this.showTableRecord){
      this.promptForUnSavedChanges();
    }
  }

  /**
   * 
   * 
   * @param1 - selectedOption item from HTML,it contains id,value,viewValue,isSelected
   * 
   * @returns updated selected Send To Option
   * 
   */
    
    selectSendToOption(selectedOption){
      this.sendToOptionList.forEach(x => {
        x.isSelected = (selectedOption.id === x.id) ? !selectedOption.isSelected : false;
      });
      this.defaultSelectedSendTo = selectedOption.id 
  }

  /**
   * @param1 - List - ToListCollection,
   * 
   * @returns - Over Total selected List count for individual To,cc,Bcc
   * 
   */
    calculateTotalCount(list){
      return list.map( x => x.selectedList.length).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    }

    /**
     * 
     * @returns - Individual Total Count all the category
     * 
     */
    calculateTotalForList(){
      this.ToTotalCount = this.calculateTotalCount(this.ToListCollection);
      this.ccTotalCount = this.calculateTotalCount(this.ccListCollection);
      this.bccTotalCount = this.calculateTotalCount(this.BccListCollection);
    }

    /**
     * 
     * @returns - removing all the selectedList from the selection
     * 
     */
    clearAllSelectedList(){
     
      this.removeIndividualSelectedList(this.ToListCollection)
      this.removeIndividualSelectedList(this.ccListCollection)
      this.removeIndividualSelectedList(this.BccListCollection)

      this.calculateTotalForList();
    }


    /**
     * 
     * @returns - Remove SelectedList by indiviual Array To,cc,Bcc
     * 
     */
    removeIndividualSelectedList(list){
      list.find(x =>{
        x.selectedList = [];
      })
    }

}
