import { Injectable } from '@angular/core';
import { NestedFilterKey } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FilterOptions, GridAppConfig, GridCustomColExpand, GridFilterAction, GridSortAction } from 'src/app/eatecui/source/shared/models/app-grid.interface';
import { GridModels } from 'src/app/eatecui/source/shared/models/grid-models';
import { FilterTypeConfig, GridAction, GridTableConfig } from 'src/app/eatecui/source/shared/models/product-grid/grid.interface';
import { GridHeaderConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class GridActionsService {

    constructor(private httpService: HttpService) { }

    /**
       * @description setSort for assign the default sort order for api call
       * @params gridSorting
       */
    setSort(gridSorting: GridSortAction[]) {
        try {
            return GridModels.setSortOrder(gridSorting);
        } catch (error) {
            console.error(error);
        }
    }

    /**
       * @description assign expand
       * @params gridSorting
       */
    formExpand(gridColumnExpand: GridCustomColExpand[]) {
        try {
            return GridModels.getCustomExpand(gridColumnExpand);
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @method formQueryParams
     * @param params;
     * @description form api query params
     */
    formQueryParams(params: QueryParams) {
        params.filter = '';
        const keys = Object.keys(params);
        let query = '';
        keys.forEach((data, index) => {
            if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
                if (params[data] !== '' && data !== 'filter') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                } else if (data === 'filter') {
                    if (params.isactive) {
                        params[data] = 'IsActive eq true';
                    }
                    if (params.searchtext && params.searchtext.length > 0 && params.search) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.search;
                        } else {
                            params[data] = params.search;
                        }
                    }
                    if (params.filterQuery && params.filterQuery.length > 0) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.filterQuery;
                        } else {
                            params[data] = params.filterQuery;
                        }
                    }

                    if (params[data] !== '') {
                        if (index === 0) {
                            query = query + '?$';
                        } else {
                            query = query + '&$';
                        }
                        query = query + data + '=' + params[data];
                    }
                }
            }
        });
        return query;
    }

    /**
         * @description to form the query for api
         * @params searchBy
         * @params currentFilter
         * @params searchText
         * @params headerDefinition
         */
    formSearchQuery(searchBy: Array<any>, currentFilter: any, searchText: any, navigationProperity?: any, toLower?: Boolean ) {
        const SearchText = searchText.replaceAll('\'', '\'\'');
        searchText = encodeURIComponent(SearchText).replace(/[!'()*]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
        searchBy.forEach(element => {
            if (element) {
                if (toLower) {
                    const query = 'contains(tolower(' + element.columnName + '),\'' + searchText.toLowerCase() + '\')';
                    currentFilter = this.addFilter(currentFilter, query);
                } else {
                    let query = '';
                    // if (element.columnName === 'Id') {
                    //     query = 'contains(cast(' + element.columnName + ',Edm.String),\'' + searchText + '\')';
                    // } else {
                        const navigationNames = navigationProperity ? navigationProperity.map(x => x.split('(')[0]) : [];
                        if (navigationNames.indexOf(element.columnName) > -1) {
                            if (element.OdataKey) {
                                query = 'contains(' + element.columnName + '/' + element.OdataKey + ',\'' + searchText + '\')';
                            }
                        } else {
                            query = 'contains(cast(' + element.columnName + ',Edm.String),\'' + searchText + '\')';
                           // query = 'contains(' + element.columnName + ',\'' + searchText.toLowerCase() + '\')';
                        }
                    // }
                    currentFilter = this.addFilter(currentFilter, query);
                }
            }
        });
        return '(' + currentFilter + ')';
    }

    addFilter(currentFilter, filter) {
        if (currentFilter.length === 0) {
            currentFilter = filter;
        } else {
            currentFilter += ' or ' + filter;
        }
        return currentFilter;
    }

    /**
     * @description to form filter query for api
     * @params filterArray
     * @params currentFilter
     * @params gridFilterConfig
     */
    formFilterQuery(filterArray: FilterOptions[], currentFilter: any, gridFilterConfig: GridAppConfig, GridData: any = null) {
        let query: any = '';
        currentFilter = '';

        filterArray.forEach(element => {
            if (NestedFilterKey.filter(x => x.Name === gridFilterConfig.GridFilter[0].FilterKey).length > 0) {
                const NestedFilterObject = NestedFilterKey.filter(x => x.Name === gridFilterConfig.GridFilter[0].FilterKey)[0];
                if (element.FilterValue.filter(x => x.Checked).length > 0) {
                    const checkedObjects = [];
                    element.FilterValue.forEach((data, index) => {
                        if (data.Checked) {
                            checkedObjects.push(data.FilterId);
                        }
                    });
                    const datas = checkedObjects.join(',');
                        // eslint-disable-next-line max-len
                        query = element.FilterOriginalKey + '/any(c: c/' + NestedFilterObject.FilterEntityName + '/' + NestedFilterObject.FilterColumnKey + ' in (' + datas + ') and c/IsActive eq true)';
                }
            } else {
                if (element.FilterValue.filter(x => x.Checked).length > 0) {
                    if (element.FilterNavigationKey) {
                        query = gridFilterConfig.GridFilter[0].FilterKey + '/' + element.FilterNavigationKey + ' in (';
                    } else {
                        query = gridFilterConfig.GridFilter[0].FilterKey + ' in (';
                    }
                }
                const checkedObjects = [];
                element.FilterValue.forEach((data, index) => {
                    if (data.Checked) {
                        checkedObjects.push(data.FilterId);
                    }
                });
                if (checkedObjects && checkedObjects.length > 0) {
                    query = query + checkedObjects.join(',') + ')';
                }
            }
            currentFilter = currentFilter + query;
            query = '';
        });
        return currentFilter;
    }

    formFilterQueryInCard (filterArray: FilterOptions[], currentFilter: any, gridTableConfig: GridAction
        , FilterType: FilterTypeConfig) {
        const query: any = '';
        // currentFilter = '';
        const FilterData = gridTableConfig.GridFilter.FilterType.filter(x => x.Name === 'Packages')[0];
        const hasPackageFilter = FilterData.FilterData.filter(x => x.IsChecked === true).length > 0 ? true : false;
        return new Promise<any>(resolve => {
            if (hasPackageFilter) {
                const FilterDataIds = FilterData.FilterData.filter(x => x.IsChecked === true).
                    map(y =>  y.Key).join(',');
                this.httpService.GethttpMethod(`/inventory/api/${FilterData.Name}?$filter=Id in (${FilterDataIds}) and TypeId eq 1&$expand=PackLists($select=Item)&$select=Id,Name`).subscribe(response => {
                    const IngredientIds = [];
                    response['value'].forEach(pack => {
                        if (pack.PackLists.length > 0) {
                            pack.PackLists.map(x => {
                                if (IngredientIds.findIndex(id => id === x.Item) === -1) {
                                    IngredientIds.push(x.Item);
                                }
                            });
                        }
                    });
                    console.log('Packages Response', IngredientIds);
                    if (currentFilter.length > 0) {
                        currentFilter = currentFilter + ` and Id in (${IngredientIds.join(',')})`;
                    } else {
                        currentFilter = `Id in (${IngredientIds.join(',')})`;
                    }
                    const returnValue = this.filterQueryCallBack(gridTableConfig, currentFilter);
                    resolve(returnValue);
                });
            } else {
                const returnValue = this.filterQueryCallBack(gridTableConfig, currentFilter);
                resolve(returnValue);
            }
        });
        
    }

    filterQueryCallBack(gridTableConfig: GridAction, currentFilter: any) {
        let query = '';
        const hasPackageFilter = currentFilter === undefined ? '' : currentFilter;
        currentFilter = '';
        gridTableConfig.GridFilter.FilterType.forEach((element, index1) => {
            if (element.Name !== 'Packages') {
                if (element.FilterData.filter(x => x.IsChecked).length > 0) {
                    if (element.FilterNavigationKey) {
                        query = element.FilterKey + '/' + element.FilterNavigationKey + ' in (';
                    } else {
                        query = element.FilterKey + ' in (';
                    }
                }
                const checkedObjects = [];
                element.FilterData.forEach((data, index) => {
                    if (data.IsChecked) {
                        checkedObjects.push(data.Key);
                    }
                });
                if (index1 > 0 && currentFilter.length > 0 && checkedObjects.length > 0) {
                    query = ' and ' + query;
                }
                if (query) {
                    query = query + checkedObjects.join(',') + ')';
                    currentFilter = currentFilter + query;
                }
                query = '';
            }
        });
        if (currentFilter.length > 0 && hasPackageFilter) {
            currentFilter += ' and ' + hasPackageFilter;
        } else if (hasPackageFilter) {
            currentFilter = hasPackageFilter;
        }
        return currentFilter;
    }

    formLookUpFilterQuery(filterArray: FilterOptions[], currentFilter: any) {
        let query: any = '';
        currentFilter = '';

        filterArray.forEach((element, index1) => {
            if (element.FilterValue.filter(x => x.Checked).length > 0) {
                if (element.FilterNavigationKey) {
                    query = element.FilterOriginalKey + '/' + element.FilterNavigationKey + ' in (';
                } else {
                    query = element.FilterOriginalKey + ' in (';
                }
            }
            const checkedObjects = [];
            element.FilterValue.forEach((data, index) => {
                if (data.Checked) {
                    checkedObjects.push(data.FilterId);
                }
            });
            if (checkedObjects.length > 0) {
                query = query + checkedObjects.join(',') + ')';
            }
            if (index1 > 0 && checkedObjects.length > 0 && currentFilter.length > 0) {
                query = ' and ' + query;
            }
            currentFilter = currentFilter + query;
            query = '';
        });
        return currentFilter;
    }
}
