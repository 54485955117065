import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GlobalState } from 'src/app/eatecui/source/store/states/global-state';
import { AppState } from '../states/app-state';

const globalStateSelector =   (state: AppState) => state.GlobalState;
export const MenuExpand = createSelector(globalStateSelector, (state: GlobalState) => state.GetMenuExpand);
export const IsLoggedIn = createSelector(globalStateSelector, (state: GlobalState) => state.GetIsLoggedIn);
export const GetAccessToken = createSelector(globalStateSelector, (state: GlobalState) => state.GetAccessToken);
export const GetTenant = createSelector(globalStateSelector, (state: GlobalState) => state.GetTenant);
export const GetOnPrem = createSelector(globalStateSelector, (state: GlobalState) => state.GetOnPrem);
export const IsOutsideClicked = createSelector(globalStateSelector, (state: GlobalState) => state.IsOutSideClick);