import { Component, Input, OnChanges, ViewChild, OnInit, ViewEncapsulation, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { FormIcons, FieldArray, RadioOptions, OnEventArgs } from '../../form-type.entity';
import { MatOption } from '@angular/material/core';
import { CustomErrorMesssage } from '../../form-validation';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
    selector: 'lib-multiselect-dropdown',
    templateUrl: './multiselect-dropdown.component.html',
    styleUrls: ['./multiselect-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultiselectDropdownComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() field: any = {};
    @Input() form: FormGroup;
    formBuilderIcons: FormIcons;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    @ViewChild('multiselect', { static: false }) multiselect;
    fieldType: string;
    formFieldName: any;
    formFieldlabel: any;
    formArrayName: any;
    errorMessage: any;
    formAddBuilderIcons: FormIcons;
    formRemoveBuilderIcons: FormIcons;
    selectedData: any = [];
    options: any;
    dataOption: RadioOptions = { key: 'all', label: 'All', ischecked: false };
    checked = new FormControl(false);
    errorLength: number;
    dropDownsearchControl = new FormControl();
    deepCloneFilterArray: Array<any>;
    enableDropDownSearch: boolean;
    isRequired: boolean;
    selectedItems = [];
    selectedLabels = [];
    formFieldToolTip: string;
    enableSelectAll = true;
    searchKey = '';
    get isValid(): any { return this.form.controls[this.formFieldName].valid; }
    get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }
    @ViewChild('search') searchTextBox: ElementRef;
    constructor(public AgyDynamicFormService: AgyDynamicFormServices) {
        this.enableDropDownSearch = (this.fieldType === 'groupingDataMultiple' && this.field?.fieldType?.groupingDataMultiple?.EnableDropDownSearch === true) ? true :
         ( this.field.hasOwnProperty('EnableDropDownSearch') && this.field.EnableDropDownSearch) ? true : false
    }

    changeSelect(index?: any, argus?: OnEventArgs): void{
        try{
            this.AgyDynamicFormService.formEventCallback(this.field, index, this.form);
        }catch ( error ) {
            console.error(error);
        }
    }
    checkBoxChange($event: any): void{
        try {
            if ($event.checked) {
                const optionList = this.field.fieldType[this.fieldType]?.options?.filter(ele => !ele?.isdisabled
                    || this.selectedItems?.includes(ele?.key));
                if (optionList.length > 0) {
                    const getKey = optionList.map(x => x.key);
                    let getValues = Object.values(getKey);
                    this.selectedItems = getValues;
                    if (this.dropDownsearchControl?.value) {
                        const selectedList = optionList?.filter(opt =>
                            opt.label?.toLowerCase().includes(this.dropDownsearchControl?.value?.toLowerCase()));
                        const getKeys = selectedList.map(m => m.key);
                        getValues = Object.values(getKeys);
                        this.selectedItems = getValues;
                    }
                    this.form.controls[this.formFieldName].setValue(getValues);
                    this.checked.setValue(true);
                    this.AgyDynamicFormService.formEventCallback(this.field, 1, this.form);
                }
            } else {
                const optionList = this.field.fieldType[this.fieldType]?.options?.filter(ele => ele?.isdisabled
                    && this.selectedItems?.includes(ele?.key));
                if (optionList.length > 0) {
                    this.selectedItems = optionList.map(x => x.key);
                    this.form.controls[this.formFieldName].setValue(this.selectedItems);
                } else {
                    this.form.controls[this.formFieldName].setValue('');
                    this.selectedItems = [];
                }
                this.AgyDynamicFormService.formEventCallback(this.field, 1, this.form);
            }
        } catch (error) {
            console.error(error);
        }
    }
    selectAll(ev: MatOption): void{
        if (ev.value !== '' && ev.value !== null && ev.value !== undefined && ev.selected &&
         this.selectedItems.findIndex(x => x === ev.value) === -1) {
            this.selectedItems.push(ev.value);
        } else if ( ev.value !== '' && ev.value !== null && ev.value !== undefined &&
         !ev.selected && this.selectedItems.findIndex(x => x === ev.value) !== -1) {
            const Index = this.selectedItems.findIndex(x => x === ev.value);
            this.selectedItems.splice(Index, 1);
        }
        this.checkSekectAll();
      }

    ngOnInit(): void {
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
        this.formRemoveBuilderIcons = {
            type: 'remove',
            label: 'remove',
            maxcount: this.field.maxcount
        };

        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.AgyDynamicFormService.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.options = this.field.fieldType[this.fieldType].options;
        this.deepCloneFilterArray = this.field.fieldType[this.fieldType].options;
        this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
        ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
        this.enableDropDownSearch = (this.fieldType === 'groupingDataMultiple' && this.field?.fieldType?.groupingDataMultiple?.EnableDropDownSearch === true) ? true :
         ( this.field.hasOwnProperty('EnableDropDownSearch') && this.field.EnableDropDownSearch) ? true : false;
        this.selectedItems = this.field.fieldType[this.fieldType].value ? this.field.fieldType[this.fieldType].value : [];
    }

    openedChange(event: any): void {
        if (!event) {
            this.dropDownsearchControl.reset();
        } else {
            if (this.searchTextBox) {
                this.searchTextBox?.nativeElement.focus();
            }
            this.selectedItems = this.form.controls[this.formFieldName].value ?
            this.form.controls[this.formFieldName].value : [];
            this.deepCloneFilterArray = this.field.fieldType[this.fieldType].options;
        }
    }
    checkSekectAll(): void{
        try{
            const optionsLength = this.field.fieldType[this.fieldType].options;
            const valueLength = this.form.controls[this.formFieldName].value;
            const getKey = optionsLength.map(x => x.key);
            const getValues = Object.values(getKey);
            if (getValues?.length && valueLength !== '' && valueLength !== null && valueLength !== undefined &&
            getValues.every(x => valueLength.includes(x))) {
                this.checked.setValue(true);
            }else{
                this.checked.setValue(false);
            }
        }catch (error){
            console.error(error);
        }
    }


    ngAfterViewInit(): void {
        const that = this;
        this.isRequired = this.form.controls[this.formFieldName]?.validator ? 
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
        this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
            this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field);
        });
        if (this.form.controls[this.formFieldName].value?.length > 0) {
            this.selectedLabels = this.form.controls[this.formFieldName].value?.map(ele =>
                 this.field.fieldType[this.fieldType].options?.find(opt => opt.key === ele)?.label);
        }
        this.form.controls[this.formFieldName].valueChanges.subscribe(x => {
            this.selectedItems = (this.form.value[this.formFieldName] &&
                         this.form.value[this.formFieldName].length > 0) ? this.form.value[this.formFieldName] : [];
            this.checkSekectAll();
            this.selectedLabels = [];
            if (x?.length > 0) {
                this.selectedLabels = x?.map(ele => this.field.fieldType[this.fieldType].options?.find(opt =>
                     opt.key === ele)?.label);
            }
        });
        if (this.form.controls[this.formFieldName] && this.form.controls[this.formFieldName].errors){
            const errorsArray =  Object.keys(this.form.controls[this.formFieldName].errors);
            this.errorLength = errorsArray.length;
        }
        this.checkSekectAll();
        this.dropDownsearchControl.valueChanges.pipe(debounceTime(500)).subscribe(x => {
            this.searchKey = x ? x.toString().toLowerCase() : '';
            this.enableSelectAll = true;
            if (this.searchKey) {
                const hasSearchData = this.field.fieldType[this.fieldType]?.options?.some(opt => opt.label?.toLowerCase().includes(this.searchKey.toLowerCase()));
                if (hasSearchData) {
                   this.enableSelectAll = true;
                } else {
                    this.enableSelectAll = false;
                }
            }
            if (that.deepCloneFilterArray.length === 0) {
                that.deepCloneFilterArray = this.field.fieldType[this.fieldType].options;
            }
            if (that.deepCloneFilterArray && that.deepCloneFilterArray.length > 0) {
                // const filtered = that.deepCloneFilterArray
                //     .filter(arrayOptions => arrayOptions.label.toString().toLowerCase().includes(searchKey));
                // this.field.fieldType[this.fieldType].options = [...filtered];
                this.checkSekectAll();
            }
          });
        this.enableDropDownSearch = (this.fieldType === 'groupingDataMultiple' && this.field?.fieldType?.groupingDataMultiple?.EnableDropDownSearch === true) ? true :
        ( this.field.hasOwnProperty('EnableDropDownSearch') && this.field.EnableDropDownSearch) ? true : false
    }

    fieldArrayCallback(e: FieldArray): void {
        this.field.fieldType[this.fieldType].data.push(e);
    }
    ngOnChanges(): void {
        this.formBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
    }
    tosslePerOne(all): boolean {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
            return false;
        }
        if (this.form.controls[this.field.name].value[0][this.field.name].length === this.field.fieldArray[0].options.length) {
            this.allSelected.select();
        }
    }
    iconCallback(e: any): any {
        if (e.iconType.type === 'remove') {
            this.AgyDynamicFormService.RemoveRow(e.formArray, e.formObject, e.objectIndex);
        } else {
            this.AgyDynamicFormService.addnewRow(e.formArray, e.formObject);
        }
    }
}
