import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ButtonValue, IntegrationOperationsTransactionType, OrphanPayments } from 'src/app/retail/retail.modals';
import { RetailService } from 'src/app/retail/retail.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RefundFailureSearchRequest } from '../retail-transactions-log/retail-transactions-log';
import { RefundLogBusiness } from './refund-log-business';
import { TransactionSummary } from 'src/app/retail/shared/service/payment/payment-business.model';
import {UIUnsetteledRefund} from './refund-log.model'
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';

@Component({
  selector: 'app-refund-log',
  templateUrl: './refund-log.component.html',
  styleUrls: ['./refund-log.component.scss'],
  providers: [RefundLogBusiness]
})
export class RefundLogComponent implements OnInit {
  commonCaptions: any;
  captions: any;
  transcForm: UntypedFormGroup;
  searchText = '';
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  originalData$: Promise<UIUnsetteledRefund[]>;
  originalData: UIUnsetteledRefund[];
  tableContent: Observable<any[]>;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchButton: ButtonValue;
  linkdisabled = false;
  isAllSelected: boolean;
  userList: any[] = [];
  showLoader = false;
  floatLabel: string;
  loaderCaptions: any;

  constructor(private fb: UntypedFormBuilder
    , private localization: RetailLocalization
    , private refundLogBussiness: RefundLogBusiness
    , public propertyInfo: RetailPropertyInformation
    , private _utils: RetailUtilities
    , public _ams: RetailService) {
      this.captions = this.localization.captions.shop;
      this.commonCaptions = this.localization.captions.common;
      this.loaderCaptions = this.localization.captions;
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.formGenerator()
    this.tableOptions = this.refundLogBussiness.getTableOptions();
    this.headerOptions = this.refundLogBussiness.getHeaderOptions();    
    
    this.searchButton = {
      type: 'primary',
      label: this.captions.search,
      disabledproperty: false
    }
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.reset,
      disabledproperty: true
    }
    this.transcForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.searchButton.disabledproperty = !(this.transcForm.valid && this.transcForm.dirty);
      this.cancelButton.disabledproperty = !(this.transcForm.valid && this.transcForm.dirty);
    });
    this.AddLoaderSubscription();
    this.getTableData();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  AddLoaderSubscription() {
    this._ams.loaderEnable.pipe(takeUntil(this.destroyed$)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        }
        else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
  }

  formGenerator() {
    this.transcForm = this.fb.group({
      fromDate: this.propertyInfo.CurrentDate,
      toDate: this.propertyInfo.CurrentDate,
      tableData: '',
      searchByTicket: '',
      searchByClerkID: '',
      searchByAmount: ''
    })
  }

  fromDate(e) {
    const startDate = this.localization.getDate(this.transcForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.transcForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.transcForm.controls.toDate.setValue(startDate);
    }
  }

  toDate(e) {
    const startDate = this.localization.getDate(this.transcForm.controls.fromDate.value);
    const endDate = this.localization.getDate(this.transcForm.controls.toDate.value);
    if (startDate >= endDate) {
      this.transcForm.controls.fromDate.setValue(endDate);
    }
  }

  async getTableData() {
    this.showLoader = true;
    this.originalData = [];
    const clerkIds = this.transcForm.controls.searchByClerkID.value ?
      this.userList.filter(x => (x.userName.toLowerCase().includes(this.transcForm.controls.searchByClerkID.value.toLowerCase()))) : [];
    const request: RefundFailureSearchRequest = {
      fromDate: this.localization.ConvertDateToISODateTime(this.transcForm.controls.fromDate.value),
      toDate: this.localization.ConvertDateToISODateTime(this.transcForm.controls.toDate.value),
      ticketNumber: this.transcForm.controls.searchByTicket.value,
      amount: this.localization.currencyToSQLFormat(this.transcForm.controls.searchByAmount.value),
      clerkIdList: clerkIds ? clerkIds.map(x => x.id) : [],
      pageStart: 0
    }
    this.originalData$ = this.refundLogBussiness.getTabledata(request);
    this.originalData$.then(response => {
        this.originalData = response;
        this.tableContent = of(response);
        this.transcForm.get('tableData').setValue(response);
        this.showLoader = false;
        this.searchButton.disabledproperty = false;
    }).catch(ex => {
      console.log(ex);
      this.showLoader = false;
      this.searchButton.disabledproperty = false;
    });
  }

  tableRetry(e, key) {
    console.log(e);
    this.RefundFailedPayment(e.id);
  }
  async RefundFailedPayment(transId : Number){
    var response = await this.refundLogBussiness.RefundFailedPayment(transId);

    if (response === true) {
      this._utils.showAlert(this.localization.captions.shop.RefundSuccessMsg, AlertType.Success, ButtonType.Ok ,(res) => {
        this.getTableData();
      });
     
    }
    
  }
  
  resetSearch(event?: any): void {
    this.formGenerator();
    this.getTableData();
  }
  }

