import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { ValidationMessage } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.scss']
})
export class InventoryDetailsComponent implements OnInit {
  InventoryDetailFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, private controlContainer: ControlContainer) { }

  ngOnInit() {
    this.radioFilter = [{ id: 0, name: this.captions.AllActiveitems }, { id: 1, name: this.captions.SuppressZeroQtyItems }];
    this.InventoryDetailFormGrp = this.fb.group({
      lostCostMethod: false,
      itemFilter: 0,
      categoryView: false,
      category: new UntypedFormGroup({})
    });
    this.formReady.emit(this.InventoryDetailFormGrp);
  }

  /**
  * After a form is initialized, we link it to our main form
  */
  formInitialized(form: UntypedFormGroup) {
    this.InventoryDetailFormGrp.setControl('category', form);
  }

  IsSelectionValid(validationObject: ValidationMessage) {
    this.IsValidSelection.emit({ dropDownName: validationObject.dropDownName, validity: validationObject.validity });
  }
}
