import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailSetupService } from 'src/app/retail/retail-setup/retail-setup.service';
import { Host, RetailTransactions,Product } from 'src/app/retail/shared/globalsContant';
import { HttpMethod } from 'src/app/retail/shared/service/http-call.service';

@Component({
    selector: 'app-reprint-advanced-search',
    templateUrl: 'reprint-advanced-search.component.html',
    styleUrls: ['reprint-advanced-search.component.scss'],
    encapsulation:ViewEncapsulation.None
})
export class ReprintAvancedSearchComponent {

    @Output() search: EventEmitter<ReprintAdvancedSearch> = new EventEmitter();
    @Output() close: EventEmitter<ReprintAdvancedSearch> = new EventEmitter();
    @Input() outlet = 0;
    @Input() isLoading = false;
    fromScreen: any;
    productIdcheck: number;
    productId: number = Product.SNC;
    @Input('serviceID')
    set serviceIDValue(value){
        if(value){
        this.fromScreen = value;
        if(this.fromScreen === this.retailTransactions.opentransactions){
            this.formGroup.addControl('amountReceived', new UntypedFormControl());
        } else {
            this.formGroup.addControl('voidedByclerkId', new UntypedFormControl());
        } 
    }
    }
    retailTransactions = RetailTransactions;
    searchParams: ReprintAdvancedSearch;
    captions: any = {};
    formGroup: UntypedFormGroup;
    itemNumberCaptions: any = {};
    retailItems = [];
    searchTextChanged = new Subject<string>();
    notifier: Subject<void> = new Subject<void>();
    itemId = 0;
    validateBtn: boolean;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    constructor(private localization: RetailLocalization
        , private formBuilder: UntypedFormBuilder
        , public retailsetupservice: RetailSetupService) {

        this.initializePage();
        this.initializeFormGroup();
        this.initializeItemNumber();
        this.formGroup.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(isValid =>
            this.validateBtn = this.formGroup.valid && this.formGroup.dirty
        );
    }

    initializePage() {
        this.productIdcheck = Number(this.localization.GetPropertyInfo('ProductId'));
        this.captions = this.localization.captions;
        this.searchTextChanged.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(async (searchtxt) => this.searchbyInputAfterDebounce(searchtxt));
    }
    
    ngOnDestroy() {
        if (this.destroyed$) {
          this.destroyed$.next(true);
          this.destroyed$.complete();
        }
    }

    initializeFormGroup() {
        this.formGroup = this.formBuilder.group({
            clientName: '',
            memberName: '',
            clerkId: '',
            ticketNumber: '',
            amount: '',
            itemId: '',
            transactiontype:'',
            cardNumber: '',
            cardType:'',
            transactionAdditionalReference: ''
        });
        
    }

    initializeItemNumber() {
        this.itemNumberCaptions = {
            placeHolder: this.captions.shop.ItemNumber
        };
    }



    onSearch() {
        const value = this.formGroup.value as ReprintAdvancedSearch;
        value.itemId = this.itemId;
        this.search.emit(value);
    }

    OnReset() {
        this.formGroup.controls.clientName.setValue('');
        this.formGroup.controls.memberName.setValue('');
        this.formGroup.controls.clerkId.setValue('');
        this.formGroup.controls.ticketNumber.setValue('');
        this.formGroup.controls.amount.setValue('');        
        this.formGroup.controls.itemId.setValue('');
        this.formGroup.controls.amountReceived?.setValue('');
        this.formGroup.controls.voidedByclerkId?.setValue('');
        this.formGroup.controls.transactiontype.setValue('');
        this.formGroup.controls.transactionAdditionalReference.setValue('');
        this.formGroup.controls.cardNumber.setValue('');
        this.formGroup.controls.cardType.setValue('');
        const value = this.formGroup.value as ReprintAdvancedSearch;
        value.itemId = this.itemId;
        this.formGroup.markAsPristine();
        this.validateBtn = false;
        this.close.emit(value);
    }

    onClose() {
        this.close.emit();
    }

    onItemSearch(event) {
        this.searchTextChanged.next(event);
    }

    onItemChosen(event) {
        this.itemId = event || 0;
    }


    async searchbyInputAfterDebounce(searchtxt: string): Promise<void> {
        if (searchtxt === undefined) {
            this.retailItems = [];
            this.itemId = 0;
            return;
        }
        if (searchtxt.trim().length == 0 || this.outlet === 0) {
            this.retailItems = [];
            return;
        }
        this.cancelOngoingCall();
        this.notifier = new Subject<void>();
        this.retailItems = await this.getRetailItems(searchtxt);
    }

    async getRetailItems(searchtxt: string) {
        try {
            const response = await this.retailsetupservice.cancellableObservalble(
                this.notifier,
                'SearchRetailItemsByType',
                Host.retailManagement,
                HttpMethod.Put,
                {
                    itemType: -1,
                    searchKey: searchtxt,
                    outletId: this.outlet[0]
                },
                undefined
            ).toPromise();

            return response.result.retailItems;

        } catch {
            return [];
        }
    }


    cancelOngoingCall() {
        this.notifier.next();
        this.notifier.complete();
    }

}

export interface ReprintAdvancedSearch {
    clientName: string;
    memberName: string;
    clerkId: string;
    ticketNumber: string;
    amount: string;
    itemId: number;
    voidedByclerkId: string;
    transactiontype :string;
    cardNumber: string;
    cardType: string;
    transactionAdditionalReference?: string;
}
