import { Observable, from, of } from 'rxjs';
import { TableSearchHeader, TableOptions, TableHeaderOptions } from 'src/app/common/Models/ag-models';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { TableActions, AlertAction, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { SorTypeEnum, ActionMode } from 'src/app/common/shared/shared/enums/enums';
import { map } from 'rxjs/operators';
import { FromTypeEnum, ActionTypeEnum, aligment } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { cloneDeep } from 'lodash';
import { RetailUtilities } from './shared/utilities/retail-utilities';
import { AlertType } from './shared/shared.modal';
import { ButtonType } from './shared/globalsContant';
import * as CONSTANTS from 'src/app/common/shared/shared/setupConstants';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { ServiceLocator } from 'src/app/common/service.locator';

export class RetailSettingBaseComponent<T extends {
    isActive: {
        value: boolean;
        disabled?: boolean;
    }
}> {
    crudActionInput: any;
    searchHeaderOption: TableSearchHeader;
    createModalData: createModal;
    captions: any;
    options: TableOptions;
    headerOptions: TableHeaderOptions[];
    tableContent: Observable<T[]>;
    disableOption = true;
    searchText = '';
    originalData: Observable<T[]>;
    settingsSearchTextMaxLength: number;
    showInactiveTog = false;
    IsEditModeEnabledFlag: boolean;
    form: any;
    isViewOnly: boolean;
    copyMode: boolean;
    CreationToggler = false;
    breakPointNumber: number;
    createTitle: string;
    editTitle: string;
    defaultSortingKey = 'listOrder';
    deleteMsgKey = 'name';
    label: string;
    isShowSuccessMessage: boolean;
    private loadFreshDataOnEveryEditClick = false;
    enablePagination: boolean;

    constructor(public business,
        public utils: RetailUtilities,
        public userAccess: UserAccessBusiness
    ) {
        const injector = ServiceLocator.getInjector();
        this.enablePagination = injector.get<boolean>(<any>'EnablePagination');
        this.options = {
            defaultsortingColoumnKey: this.defaultSortingKey,
            defaultSortOrder: SorTypeEnum.asc,
            showTotalRecords: true,
            enablePagination: this.enablePagination
        };
    }

    showInactiveToggler(e) {
        this.showInactiveTog = e.checked;
        this.refreshGrid();
    }

    searchChange(e) {
        this.searchText = e;
    }

    formReady(event) {
        this.form = event;
    }

    back(e) {
        if ((this.form && this.form.dirty) ||
            (this.form && this.form.valid && this.copyMode)) {
            if (!this.isViewOnly) {
                this.utils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
                    if (res === AlertAction.YES) {
                        this.backEmit(e);
                        this.refreshGrid();
                    }
                });
            }
            else {
                this.backEmit(e);
                this.refreshGrid();
            }
        } else {
            this.backEmit(e);
            this.refreshGrid();
        }
    }

    backEmit(e) {
        if (this.form) {
            this.form.reset();
        }
        this.CreationToggler = false;
        this.IsEditModeEnabledFlag = false;
    }

    setTableOptions() {
        this.options = {
            actions: [
                {
                    type: TableActions.edit,
                    disabled: false
                }, {
                    type: TableActions.delete,
                    disabled: this.isViewOnly
                },
                {
                    type: TableActions.copy,
                    disabled: this.isViewOnly
                },
                {
                    type: TableActions.drag,
                    disabled: this.isViewOnly
                }
            ],
            defaultsortingColoumnKey: this.defaultSortingKey,
            defaultSortOrder: SorTypeEnum.asc,
            deleteMsgKey: this.deleteMsgKey,
            isDragDisabled: this.isViewOnly,
            showTotalRecords: true,
            columnFreeze: {
                firstColumn: false,
                lastColumn: true
            },
            enablePagination: this.enablePagination
        };
    }

    refreshGrid() {
        if (this.form) {
            this.form.reset();
        }
        this.utils.ToggleLoader(true,this.captions.lbl_processing);
        // this.originalData = from(this.business.getAllEntries(this.showInactiveTog)) as Observable<T[]>;
        // this.tableContent = this.originalData.pipe(map(x => x));
        this.business.getAllEntries(this.showInactiveTog).then(x => {
            this.originalData = of(x) as Observable<T[]>;
            this.tableContent = this.originalData.pipe(map(x => x));
            this.utils.ToggleLoader(false);
        })
    }

    protected intializeScreen(): void {
        this.refreshGrid();
    }

    async copyEvent(e) {
        const listorder = this.business.getNextListOrder();
        this.CreationToggler = true;
        this.crudActionInput = {
            mode: ActionMode.copy,
            actionButton: this.captions.btn_create,
            isCopy: true,
            code: e.name,
            form: e,
            listOrder: listorder
        };
        this.createModalData = {
            title: this.createTitle
        };
    }

    async createEvent(e) {
        const listorder = this.business.getNextListOrder();
        this.CreationToggler = true;
        this.crudActionInput = {
            mode: ActionMode.create,
            actionButton: this.captions.btn_create,
            form: undefined,
            listOrder: listorder
        };
        this.createModalData = {
            title: this.createTitle
        };
    }

    async editEvent(rowData) {
        if (this.loadFreshDataOnEveryEditClick) {
            const editObject = await this.business.getEntryById(rowData.id);
            rowData = editObject;
        }
        this.CreationToggler = true;
        this.crudActionInput = {
            mode: ActionMode.update,
            actionButton: this.captions.btn_update,
            form: rowData,
            isEdit: true,
            isViewOnly: this.isViewOnly
        };
        this.createModalData = {
            title: this.editTitle

        };
    }

    async tableAction(event) {
        switch (event.fromType) {
            case FromTypeEnum.edit:
                this.IsEditModeEnabledFlag = true;
                this.editEvent(event.Obj);
                break;
            case FromTypeEnum.delete:
                const confirmationMessage = this.business.localization ? this.business.localization.replacePlaceholders(this.captions.lbl_deleteMsg, ['[Description]'], [event?.Obj?.code]) : this.business._localization.replacePlaceholders(this.captions.lbl_deleteMsg, ['[Description]'], [event?.Obj?.code])
                this.utils.showCommonAlert(confirmationMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
                    if (res == 'YES') {
                        this.delete(event.Obj);
                    }
                });
                break;
            case FromTypeEnum.dragdrop:
                if (event.fromType === 'dragdrop' && event.Obj.dragdata.listOrder !== event.Obj.dropdata.listOrder) {
                    //Since Payment API use from and to data for list Order
                    event.Obj["dragdatalistorder"] = cloneDeep(event.Obj.dragdata.listOrder);
                    event.Obj["dropdatalistorder"] = cloneDeep(event.Obj.dropdata.listOrder);
                    event.Obj.dragdata.listOrder = event.Obj.dropdata.listOrder;
                    this.business.updateListOrderOnDrag(event).then(() => this.refreshGrid());
                } else {
                    this.refreshGrid();
                }
                break;
            case FromTypeEnum.switch:
                this.update(event.Obj);
                break;
            case FromTypeEnum.done:
                break;
            case FromTypeEnum.cancel:
                this.refreshGrid();
                break;
            case FromTypeEnum.copy:
                this.copyEvent(event.Obj);
                break;
        }
    }
    update(data) {
        this.utils.ToggleLoader(true,this.captions.lbl_processing);
        this.business.update(data).then(response => {
            this.backEmit(data);
            this.refreshGrid();
        }, err => {

            const errmsgstr = [];
            const errmsgstr1 = [];
            if (err.error.length === undefined) {
                const code: number = parseInt(err.error.errorCode);
                const message: string = this.business.localization.getError(code);
                this.utils.showAlert(message, AlertType.Error, ButtonType.Ok);
            } else {
                err.error.forEach((element, index) => {
                    const string = this.captions[element.errorCode];
                    index === 0 ? errmsgstr1[index] = [string] + '\n' : errmsgstr[index] = [string];
                });
                this.utils.showDependencyAlert('Update' + ' ' + this.label, errmsgstr, errmsgstr1[0] + this.captions.lbl_screen + ':', ButtonType.OkCancel, async (res) => {
                    if (res === AlertAction.CONTINUE) {
                        this.backEmit(data);
                        this.refreshGrid();
                    }
                });
            }
        });
        this.utils.ToggleLoader(false);
    }

    delete(data) {
        this.business.delete(data.id).then(response => {
            this.refreshGrid();
        }, err => {

            const errmsgstr = [];
            const errmsgstr1 = [];
            err.error.forEach((element, index) => {
                const string = this.captions[element.errorCode];
                index === 0 ? errmsgstr1[index] = [string] + '\n' : errmsgstr[index] = [string];
            }
            );
            this.utils.showDependencyAlert('Delete' + ' ' + this.label, errmsgstr, errmsgstr1[0] + this.captions.lbl_screen + ':', ButtonType.OkCancel, async (res) => {
                if (res === AlertAction.CONTINUE) {
                    this.backEmit(data);
                }
            }
            );
        }
        );
    }
    handleClick(event) {
        switch (event.from) {
            case ActionMode.create:
            case ActionMode.copy:
                this.utils.ToggleLoader(true,this.captions.lbl_processing);
                this.business.create(event.formValues).then(response => {
                    this.form.reset();
                    if (this.isShowSuccessMessage)//Here isShowSuccessMessage of the value passed from respective component for showing successfull alert message
                        this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
                    this.back(event);
                }).catch(ex => {
                    if (ex.error && ex.error.errorCode) {
                        const code: number = parseInt(ex.error.errorCode);
                        const message: string = this.business._localization.getError(code);
                    }
                });
                this.utils.ToggleLoader(false);
                break;
            case ActionMode.update:               
                this.update(event.formValues);                
                break;
            case ActionMode.cancel:
                this.back(event);
                break;
        }
    }

    async validateUserAccess() {
        const userAccess = await this.userAccess.getUserAccess(this.breakPointNumber, true);

        this.isViewOnly = userAccess.isViewOnly;
        this.setTableOptions();
        this.searchHeaderOption.createBtnDisabled = userAccess.isViewOnly;
        this.searchHeaderOption.toggleDisabled = userAccess.isViewOnly;
        this.searchHeaderOption = { ...this.searchHeaderOption };

    }

    getValidators() {
        this.settingsSearchTextMaxLength = CONSTANTS.SETTINGS_SEARCH_TEXT_MAXLENGTH;
    }

    setSearchHeaderOption(createBtnLabel, searchPlaceHolder) {
        this.searchHeaderOption = {
            createBtnLabel,
            searchPlaceHolder,
            toggleLabel: this.captions.tog_lbl_showInactive,
            showInactive: true,
            toggleDisabled: this.isViewOnly,
            createBtnDisabled: this.isViewOnly,
            hasHeaderAction: false,
            maxLength: this.settingsSearchTextMaxLength
        };
    }

    addHeaderOption(displayText, key, sortingKey?: string, searchable?: boolean, displayNameId?: string) {
        this.headerOptions.push(
            {
                displayName: displayText,
                displayNameId: displayNameId,
                key,
                searchable,
                sorting: sortingKey ? true : false,
                sortingKey
            }
        );
    }

    addDefaults() {
        this.headerOptions.push({
            displayNameId: 'grid_hdr_ListOrder',
            displayName: this.captions.grid_hdr_ListOrder,
            key: 'listOrder',
            searchable: false,
            sorting: true,
            sortingKey: 'listOrder',
            alignment: aligment.right
        },
            {
                key: 'isActive',
                displayNameId: 'grid_hdr_IsActive',
                displayName: this.captions.grid_hdr_IsActive,
                searchable: false,
                sorting: true,
                sortingKey: 'isActive.value',
                templateName: ActionTypeEnum.toggle,
            },
            {
                key: ActionTypeEnum.action,
                templateName: ActionTypeEnum.action,
                displayNameId: 'tbl_hdr_actions',
                displayName: this.captions.tbl_hdr_actions,
                searchable: false
            });
    }
}
