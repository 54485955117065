import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { TemplateModel, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { TemplateDataModel } from 'src/app/eatecui/source/shared/nonodatacore/models/requestdata.interface';

export const RequisitionTemplateDataCondtruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine): any => {
    try {   
      const ScrollItemCount = sessionStorage.getItem('appsetting') ? 
      JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid : constants.InfiniteScrollItemCount;     
        const orderByColumn = transactionService.
        setSortColumn(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortDirection = transactionService.
        setSortDirection(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortingData = new RequestPagination(orderByColumn, sortDirection, requestEngine.FetchAll,
        requestEngine.LocationIds, JsonData.Name );
        let requestPayload = sortingData.getPaginationPayload(0, ScrollItemCount);
        requestPayload.Take = 100;
        const templateRequestModel = {} as TemplateModel;
        templateRequestModel.transTypeId = 24;
        templateRequestModel.fromLocationIds = [];
        templateRequestModel.toLocationIds = [];
        templateRequestModel.customerIds = [];
        templateRequestModel.fromDate = null;
        templateRequestModel.toDate = null;
        templateRequestModel.totalCount = 0;
        const PostData = Object.assign(requestPayload, templateRequestModel);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Data construction', error);
    }
};