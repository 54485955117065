
import {throwError as observableThrowError,  Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './messageservice.service'; 


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
  export class HttpRequestService {
    constructor( private http: HttpClient, private messageService: MessageService) {}
  /**
     * GET Http Request with token
     * Common service to handle PUT Http Request
     *
     * @param {string} url - Get request end point URL.
     *
     * @return {Observable}
     */


  GetHttpRequest (RequestUrl: any): Observable<any> {
    return this.http.get<any>(RequestUrl)
    /** Incase If Change the Server Response Method Enable the Pipe method at the Same time Disable the Above map method Requesting non-JSON data */
    .pipe(
        tap(data => this.log(`fetched data`)),
        catchError(this.handleError('getHeroes', []))
      );
  }

  /**
     * GET Http Request with token
     * Common service to handle Get Http Request
     *
     * @param {string} url - Get request end point URL.
     * @param {number} id - Get request end point URL.
     *
     * @return {Observable}
     */
    GetHttpRequestId (RequestParams: Object, RequestUrl: any): Observable<any> {
      return this.http.get<any>(RequestUrl)
        .pipe(map((res:Response) => res.json()), // ...and calling .json() on the response to return data
        catchError((error:any) => observableThrowError(error.json().error || 'Server error')))
         
      }


      /**
     * POST Http Request
     * Common service to handle PUT Http Request
     *
     * @param {string} url - POST request with end point URL.
     * @param {string} data - POST request with data.
     *
     * @return {Observable}
     */



    /** POST: add a new hero to the server */
    putHttpRequest (RequestUrl: any): Observable<any> {
        return this.http.put<any>(RequestUrl, httpOptions) // ...using post request
        .pipe(map((res:Response) => res.json()), // ...and calling .json() on the response to return data
        catchError((error:any) => observableThrowError(error.json().error || 'Server error'))) //...errors if any
 
    }



  /**
     * POST Http Request
     * Common service to handle PUT Http Request
     *
     * @param {string} url - POST request with end point URL.
     * @param {string} data - POST request with data.
     *
     * @return {Observable}
     */



    /** POST: add a new hero to the server */
    postHttpRequest (RequestUrl: any, RequestParams: Object): Observable<any> {
        
        return this.http.post<any>(RequestUrl, RequestParams, httpOptions) // ...using post request
        .pipe(map((res:Response) => res.json()), // ...and calling .json() on the response to return data
        catchError((error:any) => observableThrowError(error.json().error || 'Server error'))) //...errors if any
 
    }

    /**
     * DELETE Http Request
     * Common service to handle DELETE Http Request
     *
     * @param {string} url - POST request with end point URL.
     *
     * @return {Observable}
     */



    /** POST: add a new hero to the server */
    deleteHttpRequest (RequestUrl: any): Observable<any> {
        return this.http.delete<any>(RequestUrl, httpOptions) // ...using post request
        .pipe(map((res:Response) => res.json()), // ...and calling .json() on the response to return data
        catchError((error:any) => observableThrowError(error.json().error || 'Server error')))//...errors if any
 
    }






    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any):any => {
      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return result;
    };
  }

   /** Log a HeroService message with the MessageService */
   private log(message: string) {
    this.messageService.add('HeroService: ' + message);
  }

  }
