import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports,OutletAndCategoryFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class SalesByDiscountTypes extends BaseReport {

    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness
        , private propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        return {
            code: customFilterData.summaryView === true ? AllReports.SalesByDiscountTypesSummary : AllReports.SalesByDiscountTypes,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;

        let reportcategoryCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allCategoryGroups"],customFilterData.reportcategory.map(x=>x.id));
        let categoryCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["categories"],customFilterData.category.map(x=>x.id));
        let discountCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["discountTypes"],customFilterData.discounttype.map(x=>x.id));

        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) },{ "pPageBreak": this.pageBreakToggle},
        {"pOutletCaption":this.functionalities.ShowOutletSelectionFieldInSaleByDiscountTypeReport ? this.getFilterCaption : this.getDefaultOutletCaption}
        ,{"pReportCategoryCaption":reportcategoryCaption},{"pCategoryCaption":categoryCaption},{"pDiscountCaption":discountCaption},{"pPropertyName":this.propertyName}
        ,{'pDate': this.printedDate}
        ,{ 'pSummaryView': customFilterData.summaryView === true},
        {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInSaleByDiscountTypeReport ? true : false }];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }




    protected formFilters() {
            const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
            let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
            let toApi = this.localization.convertDateObjToAPIdate;
        const tempOutFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return JSON.stringify({
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByDiscountTypeReport ? this.selectedFilterOptions.map(x=>x.id) : 
                tempOutFalse,
                CategoryIds: customFilterData.category.map(x=>x.id),
                DiscountType:customFilterData.discounttype.map(x=>x.id),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
            let filter: Partial<OutletAndCategoryFilter> = {
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByDiscountTypeReport ? this.selectedFilterOptions.map(x=>x.id) : 
                tempOutFalse,
                CategoryIds: customFilterData.category.map(x=>x.id),
                DiscountType:customFilterData.discounttype.map(x=>x.id)
    
            }; 
            return JSON.stringify(filter);
    }
}


