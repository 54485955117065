import { UntypedFormGroup } from "@angular/forms";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { ReportDataService } from "../../data/report-data.services";
import { BaseReport } from "../common/base-report";
import { ReportBusinessService } from "../report-business.service";
import { ReportUIConfig, ReportAPIOptions, ReportParams, AllReports } from "../report.modals";

export class Accrual extends BaseReport {
    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }
    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.Accrual,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }
    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate,
            accrualReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        return [
            { pStartDate: toApi(this.startDate) },
            { pEndDate: toApi(this.endDate) },
            { pPageBreak: this.pageBreakToggle },
            { pPropertyName: this.propertyName },
            { pDate: this.printedDate },
            { pOutlets: this.business.getFilterCaption(
                this.business.FilterDataSource['outletData'],
                this.fillFilter(accrualReportFilterData.Outlets.filter((u) => u.showInDropDown))
            )},
            { pUsers: this.business.getFilterCaption(
                this.business.FilterDataSource['allUsers'],
                this.fillFilter(accrualReportFilterData.Users.filter((u) => u.showInDropDown ))
            )},
            { pIncludeInactiveUsers: accrualReportFilterData.IncludeInActiveUsers ? "true" : "false"},
            { pGuestName: accrualReportFilterData.clientTobeSelected}           
        ];
    }

    fillFilter(customFilterData: any): number[] {
        return customFilterData.length === 0 ? [] : customFilterData.map((x) => x.id);
    }

    protected formURIParams(): ReportParams[] {
        return [{ StartDate: this.localization.convertDateObjToAPIdate(this.startDate) },
        { EndDate: this.localization.convertDateObjToAPIdate(this.endDate) }];
    }
    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        let accrualReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                StartDate: toApi(this.startDate),
                EndDate: toApi(this.endDate),
                OutletIds: this.fillFilter(accrualReportFilterData.Outlets.filter(x => x.showInDropDown)),
                UserIds: this.fillFilter(accrualReportFilterData.Users.filter(x => x.showInDropDown)),
                PatronId: accrualReportFilterData.clientTobeSelected ? accrualReportFilterData.clientTobeSelected.patronId : '' ,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            };
        }
        return {
            StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            OutletIds: this.fillFilter(accrualReportFilterData.Outlets.filter(x => x.showInDropDown)),
            UserIds: this.fillFilter(accrualReportFilterData.Users.filter(x => x.showInDropDown)),
            PatronId: accrualReportFilterData.clientTobeSelected ? accrualReportFilterData.clientTobeSelected.patronId : ''            
        }; 
    }
    constructor(
        public reportFormGrp: UntypedFormGroup,
        public data: ReportDataService,
        public business: ReportBusinessService,
        public utils: RetailUtilities,
        public retailLocalization : RetailLocalization
    ) {
        super(reportFormGrp);
    }
}