import { Injectable } from '@angular/core';
import { CommonControllersRoutes } from 'src/app/common/communication/common-route';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { MenuObj } from 'src/app/common/components/menu/menu.model';
import { APILanguage, EntityElement,  Languages,  MenuEditListObj, MenuEditObj, UILiteral } from './menu-edit.model';
@Injectable()
export class EditMenuPopupBusiness {

    constructor(private _tenantSetupDataService: TenantManagementCommunication) {

    }

    public MapInfoToMenuList(langID: number, customisedText: string): MenuEditListObj {
        return {
            languageID: langID,
            customisedText: customisedText
        } as MenuEditListObj;
    }

    public MapToMenu(menuList: MenuEditListObj[], id: number): MenuEditObj {
        return {
            id: id,
            literalList: menuList
        } as MenuEditObj;
    }

    public async saveLiterals(literals: UILiteral[], userLangID: number, newLiteral: string) {
        literals.forEach(x => {
            x.ID = 0;
            x.propertyId = 0;
            x.literalList.forEach(lit => {
                if (lit.languageID == userLangID)
                    lit.customisedText = newLiteral
            })
        });
        let literalId: number = await this._tenantSetupDataService.postPromise({ route: CommonControllersRoutes.CreateLiterals, body: literals });
        return literalId;       
   
         
    }

    public UpdateLiterals(UIData: any[], lang: Languages[], textID: number) {
        const literals: UILiteral[] = [];

        for (let key in UIData) {
            let value = UIData[key];
            literals.push({
                ID: textID,
                propertyId: 0,
                literalList: [
                    {
                        languageID: lang.find(x => x.sub == key).id,
                        customisedText: value
                    }
                ]
            });
        }
        return this._tenantSetupDataService.putPromise({ route: CommonControllersRoutes.UpdateLiterals, body: literals });
    }

    public async getLanguages() {
        const languageAPIData: APILanguage[] = await this._tenantSetupDataService.getPromise({ route: CommonControllersRoutes.GetAllLanguages });
        return languageAPIData.map(x => this.MapToUILanguage(x));
    }

    public getMenu(langID: any, textID: number, customText: string) {
        let menulist: MenuEditListObj[] = [];
        menulist.push(this.MapInfoToMenuList(langID['id'], customText));
        return this.MapToMenu(menulist, textID);
    }

    private MapToUILanguage(languageData: APILanguage): Languages {
        return {
            lang: languageData.languageName,
            id: languageData.id,
            sub: languageData.languageCode,
            value: ''
        } as Languages;
    }

    async getEntityElementsByLiteralId(literalId: any): Promise<any> {
        try {
            return await this._tenantSetupDataService.getPromise(
                {
                    route: CommonControllersRoutes.GetEntityElementsByLiteralId,
                    uriParams: { literalId: literalId }
                });
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
    async getAllLiteralsByLiteralId(literalId: any): Promise<any[]> {
        try {
            return await this._tenantSetupDataService.getPromise(
                {
                    route: CommonControllersRoutes.GetAllLiteralByLiteralId,
                    uriParams: { LiteralId: literalId }
                });
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateEntityElement(menu: MenuObj) {
        let MenuArr: MenuObj[] = [];
        MenuArr.push(menu);
        await this._tenantSetupDataService.putPromise(
            {
                route: CommonControllersRoutes.UpdateEntityElements,
                body: MenuArr
            });
    }

    public MapEntityElementToMenu(entityElement: EntityElement): MenuObj {
        return {
            elementId: entityElement.id,
            textID: entityElement.customisedTextID,
            text: entityElement.elementName,
            routePath: entityElement.customisedRoutePath,
            order: entityElement.customisedOrder,
            visibility: entityElement.visiblility,
            disable: entityElement.disable,
            tenantID: entityElement.tenantId,
            propertyID: entityElement.propertyID,
            productID: entityElement.productId,
            parentID: entityElement.customisedParentID,
            menuPosition: entityElement.menuPosition,
            menuAlignment: entityElement.menuAlignment,
            externalLink: entityElement.externalLink
        } as MenuObj
    }
}