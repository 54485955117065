import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpCacheService } from "./http-cache.service";
import { Localization } from "../../localization/localization";
import { UICache} from "./cache-constants";
import { cloneDeep } from "lodash";
import { Router } from "@angular/router";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    productId: number;
    propertyId: number;
    allEntityInfoList: any;
    uiCache: UICache;   

    constructor(private httpCacheService: HttpCacheService,
        private localization: Localization, private router: Router) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
        let enableUICache = propConfig?.UICacheEnabled;
        if (enableUICache && enableUICache.toLowerCase() == "true") {
            if (req.method != 'GET') {
                return next.handle(req);
            }
            this.productId = Number(this.localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
            this.propertyId =Number(this.localization.GetPropertyInfo('PropertyId'));

            let cachedResponse = this.httpCacheService.get(this.propertyId+'_'+req.url);
            this.uiCache = cloneDeep(this.localization.uiCacheData);       

            let product = this.uiCache?.products.find(product => product.productId === this.productId);
            let excluded = false;
            let containsPartialURL = false;
            if (product) {
                this.iterateEntitiesInModules(product.modules, (entityName, entityDetails) => {
                    const partialURL = req.url;
                    containsPartialURL = entityDetails.apiRouteContains ?
                        partialURL.includes(entityDetails.apiRouteContains) : partialURL.endsWith(entityDetails.apiRouteEndsWith);
                    if (containsPartialURL && entityDetails.exclusionUIRoute && entityDetails.exclusionUIRoute.length > 0) {
                        excluded = entityDetails.exclusionUIRoute?.some(route => this.router.url.includes(route));
                        if(cachedResponse && excluded)
                        {
                            return next.handle(req);
                        }
                    }
                });
            }          
            return cachedResponse && !excluded ? of(cloneDeep(cachedResponse)) : this.sendRequest(req, next);
        }
        else {
            return next.handle(req);
        }
    }

    sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap({
                next: (event) => {
                    // URL to check partially within the API strings
                     this.uiCache = cloneDeep(this.localization.uiCacheData);
                    let product = this.uiCache?.products.find(product => product.productId === this.productId);
                    let containsPartialURL = false;
                    if (product) {
                        this.iterateEntitiesInModules(product.modules, (entityName, entityDetails) => {
                            const partialURL = req.url;
                            containsPartialURL = entityDetails.apiRouteContains ?
                                partialURL.includes(entityDetails.apiRouteContains) : partialURL.endsWith(entityDetails.apiRouteEndsWith);
                            if (containsPartialURL && event instanceof HttpResponse && event.status === 200) {
                                this.httpCacheService.set(entityName, this.propertyId+'_'+req.url, cloneDeep(event), entityDetails.cacheExpiry);
                            }
                        });
                    }
                },
                error: (error: HttpResponse<any>) => {
                    throw error;
                }
            })
        )
    }

    private iterateEntitiesInModules(modules: any[], callback: (entityName: string, entityDetails: any) => void): void {
        modules.forEach(module => {
            for (let entityName in module.entitiestobeCached) {
                if (module.entitiestobeCached.hasOwnProperty(entityName)) {
                    let entityDetails = module.entitiestobeCached[entityName];
                    callback(entityName, entityDetails);
                }
            }
        });
    }


}



