import { Injectable } from '@angular/core';
import { DefaultUserConfiguration } from 'src/app/common/shared/shared.modal';
import { PropertyApiRoutes } from '../common-route';

import { Localization } from '../localization/localization';


@Injectable({
  providedIn: 'root'
})

export class UserdefaultsInformationService {

  private outlets;
  private captions;
  private defaultUserConfig: DefaultUserConfiguration;
  constructor(
     
    private _localization: Localization) {
    this.captions = this._localization.captions.settings.utilities;
  }

  public syncDefaultValues(userId) {
   //syncDefaultValues
  }

  public get DefaultUserConfig() : DefaultUserConfiguration{
    return this.defaultUserConfig;
  }


  private GetDefaultUserConfiguration(userId: string): Promise<DefaultUserConfiguration> {
  
    return null;
  }
  private defaultConfig(userId) {
    this.GetDefaultUserConfiguration(userId).then((d) => {
      this.defaultUserConfig = d;
      sessionStorage.setItem("userDefaults", JSON.stringify(d));
    });
  }
  private setOutlets() {
    this.fetchOutlets().then((o) => {
      this.outlets = o;
      sessionStorage.setItem("outlets", JSON.stringify(o));
    });
  }

  private async fetchOutlets() {
    let serviceParams = {
      route: PropertyApiRoutes.GetAllOutlets,
      uriParams: { PropertyId: Number(this._localization.GetPropertyInfo('PropertyId')) },
      header: '',
      body: undefined,
      showError: true,
      baseResponse: true
    };
   
  }

  private mapSubpropertyToOutlet(subP) {
    return subP.map(s => {
      return  {
        id: s.subPropertyId,
        isActive: s.isActive,
        outletNumber: s.subPropertyId,
        outletName: s.subPropertyName
      }
    });    
  }

  public getPaymentDevices() {
    return [
      { id: '', description: '' },
      { id: 'rguestpay', description: this.captions.RGuestPay },
      { id: 'idtech', description: this.captions.Idtech }
    ];     
  }

  sync() {
    if (this.defaultUserConfig == undefined) {
      this.defaultUserConfig = JSON.parse(sessionStorage.getItem('userDefaults'));
    }
    if (this.outlets == undefined) {
      this.outlets = JSON.parse(sessionStorage.getItem('outlets'));
    }

  }
  GetDefaultOutlet() {
    this.sync();
    const configuredOutlet = this.outlets && this.outlets.find(x => this.defaultUserConfig && x.id == this.defaultUserConfig.defaultOutletId);
    const description = configuredOutlet && configuredOutlet.outletName;
    let outletId = this.defaultUserConfig && this.defaultUserConfig.defaultOutletId;
    return { id: (outletId == 0) ? null : outletId, type: description };
  }

  GetDefaultPaymentDevice() {
    this.sync();
    return this.defaultUserConfig.defaultPaymentDevice;
  }
  GetDefaultDeviceName() {
    this.sync();
    return this.defaultUserConfig.defaultDeviceName;
  }

  SetDefaults(_defaults)
  {
    this.defaultUserConfig = _defaults;
    sessionStorage.setItem("userDefaults", JSON.stringify(_defaults));
  }
}
