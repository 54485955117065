import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'src/app/common/app-service';
import { RguestCommunication } from 'src/app/common/communication/common/rguest-communication';
import { Localization } from 'src/app/common/localization/localization';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { environment } from 'src/environments/environment';


@Injectable(
    {
        providedIn: 'root'
    }
)
export class CartCommunication extends RguestCommunication {

    constructor(httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService) {
        super(environment["CartService"] as unknown as string,
            httpclient, localization, utilities, PropertyInfo, appService);
    }
}
