export enum AppliedEnum{
    actual = 0,
    override = 1
}

export class Occupancy {
    date: string;
    startTime: string;
    endTime: string;
    noOfRooms: number;
    actualOccupancy: number;
    applied: string;
    overrideOcc: number;
    serviceMinutes: number;
    serviceTimes: number;
    cleanupTimes: number;
}

export class UpdateOccupancy {
    occupancyDate: string;
    isOverride: boolean;
    override: number;
}