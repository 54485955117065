import { Injector } from '@angular/core';
/**
 * Static injector property can be used to do setter Injection 
 * Should be initialzed globally in App Module of product
 * USAGE => ServiceLocator.injector.get(TOKEN);
 * @export
 * @class ServiceLocator
 */
export class ServiceLocator {
    static injector: Injector;

    static setInjector(injector: Injector) {

        ServiceLocator.injector = injector;

    }

    static getInjector(): Injector {

        return ServiceLocator.injector;

    }
}
