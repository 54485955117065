import { Component, ElementRef, Inject, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { DetailsPayload, FileDataPicture, UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { StateService } from 'src/app/eatecui/source/shared/services/state.service';
import { AgilysysButtonModel} from 'src/app/eatecui/dist/agilysys-button';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform/public-api';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { DetailPictureConfig, MasterInventoryManagement } from '../../setup-master/shared/interface/master.interface';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
@Component({
  selector: 'app-agilysys-picture-popup',
  templateUrl: './agilysys-picture-popup.component.html',
  styleUrls: ['./agilysys-picture-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysPicturePopupComponent implements OnInit, OnChanges {
  PictureDefaultHeaderName: string;
  defaultPictureUrl: string;
  defaultPictureName: string;
  pictureData: Array<any> = [];
  addPictureButton: AgilysysButtonModel[];
  browseFile: AgilysysButtonModel[];
  files: any = [];
  fileArray: any = [];
  validFormats: Array<any> = [];
  fileFormats = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
  fileSizeLimit = 200000000000;
  fileSizeExceeded = [];
  homeGroup: FormGroup;
  imageUrlArray: Array<FormType> = [];
  isFavorite: boolean;
  productremapJson: Array<any>;
  currentFile: any;
  fileType: string;
  fileDataCollection: Array<UploadMetaData>;
  showVideo = false;
  MasterDataCollection: MasterInventoryManagement;
  @ViewChild('fileUpload', {static: false}) fileInput: ElementRef;
  @ViewChild('fileBrowseUpload', {static: false}) fileBrowseUpload: ElementRef;
  
  constructor(
    public domSanitizer: DomSanitizer, private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AgilysysPicturePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DetailPictureConfig,
    private httpService: HttpService,
    private stateService: StateService,
    private envService: EnvService,
    public dialog: MatDialog,
    public http: HttpClient,
    private toastrService: ToastrService
    ) {
       this.PictureDefaultHeaderName = this.data.PictureHeaderName;
       this.data.PictureDetail.forEach(pic => {
        if (pic.Id === 0 && pic.FileDetails) {
          pic.FileDetails.Picture = this.domSanitizer.bypassSecurityTrustResourceUrl(pic.FileDetails.Picture);
        }
       });
       const FavouritePic = this.data.PictureDetail.filter(x => x.FileDetails.IsFavorite === true)[0];
       this.defaultPictureUrl = FavouritePic ? FavouritePic.Url : this.data.PictureDetail[0]['Url'];
       this.defaultPictureName = this.data.PictureDetail[0]['FileDetails'] ? 
       this.data.PictureDetail[0]['FileDetails']['FileName'] : FavouritePic ? FavouritePic.Name :
        this.data.PictureDetail[0]['Name'];
       this.fileType = FavouritePic ? FavouritePic.Type : this.data.PictureDetail[0]['Type'];
       this.currentFile = FavouritePic ? FavouritePic : this.data.PictureDetail[0];
       this.pictureData = this.data.PictureDetail;
       this.homeGroup = this.formBuilder.group({});
       this.productremapJson = [];
       this.fileDataCollection = [];
       this.addPictureButton = [
        {
          displayProperity: (data.action === 'edit') ? false : true,
          displayName: 'Cancel',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callbackvalue) => {
            // const result = {
            //   Data: ( this.fileDataCollection && this.fileDataCollection.length > 0 ) ? this.fileDataCollection : null,
            //   type: 'cancel'
            // };
            this.dialogRef.close(this.data.PictureDetail);
          }
        },
        {
          displayProperity: (data.action === 'edit') ? false : true,
          displayName: 'Browse Files',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-primary',
        }

      ];
      this.browseFile = [
        {
          displayProperity: false,
          displayName: 'Browse File',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
        }
      ];

      const _that = this;

      this.imageUrlArray = [
         {
           type: 'text',
           name: 'imageUrl',
           fieldType: {
             SingleData: {
               name: 'image-url',
               label: 'Add url',
               value: '',
               onCellClick: (form: any, field: any, value: any) => {
                 const imageValue = value.controls['image-url'].value;
                 if (imageValue && imageValue !== undefined && imageValue !== '') {
                  value.controls['image-url'].setValue('');
                  _that.downloadImage(imageValue);
                 }

               }
             }
           }
         }
      ];
  }

  async downloadImage(imageSrc: any) {
    try {
      this.files = [];
      const image = await fetch(imageSrc);
      const imageBlog = await image.blob();
      const imageblogUrl = await this.readFileBinary(imageBlog);
      const pictureObj = {};
      pictureObj['Picture'] = imageblogUrl.split(',')[1];
      this.files.push(pictureObj);
      this.rearrangePicture(this.files);
    } catch (error) {
      console.error('Error occurred in downloadImage', error);
    }
  }

  /**
   * @expand Picture
   * @params value
   */

  expandPicture(Picture: any) {
    this.defaultPictureName = Picture['Name'];
    this.defaultPictureUrl = Picture['Url'];
    this.fileType = Picture['Type'];
    this.currentFile = Picture;
    if (Picture['Name'] && Picture['Name'].includes('mp4') ) {
      this.showVideo = true;
      const video = document.getElementById('addPictureVideo');
      if (this.currentFile.FileDetails && this.currentFile.FileDetails.Id !== 0 ) {
        FileData.FileVideo(video, this.httpService, this.stateService, this.envService, Picture, 'Mat');
      } else {
        video.setAttribute('src', this.currentFile.FileDetails.LocalUrl);
      }
    } else {
      this.showVideo = false;
    }
  }
  /**
   * @ videoStreming
   * @ param Pictures
   * @ param index
   */

   videoStreming() {
    try {
    //  const fileDataModel = {} as StreamingModel;
    //  fileDataModel.FileData = this.currentFile;
    //  this.dialog.open(AgilysysVideoStremingComponent, {
    //    width: '80%',
    //    height: '80%',
    //    data: fileDataModel,
    //    disableClose: true
    //  });
      this.currentFile = this.data.PictureDetail.filter(x => x.Name === this.defaultPictureName 
        || x.FileName === this.defaultPictureName)[0];
    if (this.defaultPictureName && this.defaultPictureName.includes('mp4') ) {
      this.showVideo = true;
      const video = document.getElementById('addPictureVideo');
      if (this.currentFile.FileDetails && this.currentFile.FileDetails.Id !== 0 ) {
        FileData.FileVideo(video, this.httpService, this.stateService, this.envService, this.currentFile, 'Mat');
      } else {
        const localStream = this.domSanitizer.bypassSecurityTrustResourceUrl(this.currentFile.FileDetails.LocalUrl);
        video.setAttribute('src', this.currentFile.FileDetails.LocalUrl);
      }
    }
    } catch (error) {
      console.error(error);
    }
   }

  /**
   * deletePictures(Pictures)
   * @params value
   */
  deletePictures(Pictures: any, index: number, event) {
    try {
      event.stopPropagation();
      this.data.PictureDetail.splice(index, 1);
      const video = document.getElementById('addPictureVideo');
      if ( this.showVideo && video && video !== undefined ) {
        video['src'] = '';
        this.showVideo = false;
      }
      this.defaultPictureUrl =
      ( this.data.PictureDetail && this.data.PictureDetail.length > 0 && this.data.PictureDetail[0]['Url'] ) ? this.data.PictureDetail[0]['Url'] : null;
      this.defaultPictureName =
      ( this.data.PictureDetail && this.data.PictureDetail.length > 0 && this.data.PictureDetail[0]['Name'] ) ? this.data.PictureDetail[0]['Name'] : null;
      this.pictureData = [...this.data.PictureDetail];
      this.data.FormData.controls[this.data.PictureKey].value.splice(index, 1);
      this.data.IsActivePictures.push(Pictures);
      if (Pictures.hasOwnProperty('Id')) {
        this.data.IsActivePictures.map(x => {x.IsActive = false; x.Picture = null; delete x.Url; delete x.Name;  });
      }
      const DeleteData: DeletePicture = {
        fileName: Pictures.Name,
        groupId: Pictures.FileDetails.InventoryGroupId,
        itemId: Pictures.FileDetails.Id,
        filePath : Pictures.FilePath
      };
      if (Pictures.FileDetails.Id !== 0) {
        const DeleteUrl =
          `/inventory/api/File/Delete?id=${DeleteData.itemId}&fileName=${DeleteData.filePath}&IsTransaction=false`;
        this.httpService.GethttpMethod(DeleteUrl).subscribe(res => {
          if (res) {
            this.toastrService.success(res.toString(), '', {
              timeOut: 3000,
              closeButton: true,
            });
          } else {
            this.toastrService.error('Delete Failed', '', {
              timeOut: 3000,
              closeButton: true,
            });
          }
        });
      }
    } catch (error) {

    }
  }

  /**
   * Add Favourites
   */
   favouritePictures(Pictures: any, index: number, event) {
     try {
      event.stopPropagation();
      this.data.PictureDetail.map(x => {
        x.IsFavorite = false;
        x.IsFavourite = false;
        if (x.FileDetails) {
          x.FileDetails['IsFavorite'] = false;
        }
      });
      this.pictureData.map(e =>  {
        e.IsFavorite = false;
        e.IsFavourite = false;
        if (e.FileDetails) {
          e.FileDetails['IsFavorite'] = false;
        }
      });
      this.pictureData[index]['IsFavorite'] = !Pictures['IsFavorite'];
      this.pictureData[index]['IsFavourite'] = !Pictures['IsFavourite'];
      this.data.PictureDetail[index]['IsFavorite'] = !this.data.PictureDetail[index]['IsFavorite'];
      this.data.PictureDetail[index]['IsFavourite'] = !this.data.PictureDetail[index]['IsFavourite'];
      this.data.PictureDetail[index].FileDetails['IsFavorite'] = !this.data.PictureDetail[index].FileDetails['IsFavorite'];
      this.data.PictureDetail[index].FileDetails['IsFavourite'] = !this.data.PictureDetail[index].FileDetails['IsFavourite'];
      Pictures['IsFavorite'] = !Pictures['IsFavorite'];
      Pictures['IsFavourite'] = !Pictures['IsFavourite'];
      //  this.data.PictureDetail.forEach((x: any) => {
         if (Pictures.FileDetails.Id !== 0) {
          const FavoriteData: any = {
            fileName: Pictures.Name,
            groupId: Pictures.FileDetails.InventoryGroupId,
            itemId: Pictures.FileDetails.InventoryId,
            filePath : Pictures.FilePath,
            id: Pictures.FileDetails.Id
          };
          const UpdateUrl =
          `/inventory/api/File/Update?id=${FavoriteData.id}&itemId=${FavoriteData.itemId}&groupId=${FavoriteData.groupId}`;
          this.httpService.PuthttpMethod(UpdateUrl, null).subscribe(res => {
            console.log('Added Favorite', res);
          });
         }
        //   else {
        //   const pictureObj = {} as UploadMetaData;
        //   // pictureObj.BlocksCount = Pictures[index].BlocksCount;
        //   // pictureObj.CloudFileName = Pictures[index].CloudFileName;
        //   pictureObj.ContentType = Pictures[index].FileData.type;
        //   pictureObj.FileName = Pictures[index].FileData.name;
        //   pictureObj.FileSize = Pictures[index].FileData.size;
        //   pictureObj.GroupId = Pictures[index].FileDetails.InventoryGroupId;
        //   pictureObj.IsFavourite = Pictures[index].IsFavourite;
        //   pictureObj.IsFavorite = Pictures[index].IsFavourite;
        //   // pictureObj.IsMetaCreated = Pictures[index].IsMetaCreated;
        //   pictureObj.ItemId = Pictures[index].FileDetails.Id;
        //   pictureObj.FileData = Pictures[index].FileData;
        //   pictureObj.FileDetails = Pictures[index].FileDetails;
        //  }
      //  });
     } catch (error) {
       console.error(error);
     }
   }


  /**
   * uploadFile()
   * @params value
   */

  readFileBinary(UploadedFile: any): Promise<any> {
     return new Promise((resolve, reject) => {
       const fileReader = new FileReader();
       fileReader.readAsDataURL(UploadedFile);
       fileReader.onload = (fileLoaded) => {
         return resolve(fileReader.result);
       };
       fileReader.onerror = (error) => {
         reject(error);
       };
     });

 }
  // eslint-disable-next-line
  async uploadFile(event: any) {
    const FileDetails: DetailsPayload = {
      ItemId: 0,
      GroupId: 0,
      IsFromMaster: true
    };
    FileDetails.ItemId = 1;
    FileDetails.GroupId = this.data.GroupId;
    // switch (this.data.GroupId) {
    //   case MasterName.MasterIngredients: FileDetails.GroupId = 1; break;
    //   case MasterName.MasterProducts: FileDetails.GroupId = 3; break;
    //   case MasterName.MasterReceipe: FileDetails.GroupId = 2; break;
    // }
    this.fileDataCollection = [];
    const FileUploadResult: Array<any> = await FileData.FileUpload(event, this.httpService,
       FileDetails, this.fileDataCollection, this.toastrService, null, null, undefined, this.data.PictureDetail);
    // for (let index = 0; index < event.length; index++) {
    //   this.files = [];
    //   const element = event[index];
    //   if (this.fileFormats.includes(event[index].type)) {
    //     if (event[index].size <= this.fileSizeLimit) {
    //       const filedata = await this.readFileBinary(element);
    //       const imageBaseConvert = this.domSanitizer.bypassSecurityTrustUrl(filedata);
    //       const pictureObj = {} as UploadMetaData;
    //       pictureObj.BlocksCount = FileUploadResult[index].BlocksCount;
    //       pictureObj.CloudFileName = FileUploadResult[index].CloudFileName;
    //       pictureObj.ContentType = FileUploadResult[index].ContentType;
    //       pictureObj.FileName = event[index].name;
    //       pictureObj.FileSize = event[index].size;
    //       pictureObj.GroupId = FileUploadResult[index].GroupId;
    //       pictureObj.IsFavourite = FileUploadResult[index].IsFavourite;
    //       pictureObj.IsFavorite = FileUploadResult[index].IsFavourite; //
    //       pictureObj.IsMetaCreated = FileUploadResult[index].IsMetaCreated;
    //       pictureObj.ItemId = FileUploadResult[index].ItemId;
    //       pictureObj.FileData = FileUploadResult[index].FileData;
    //       pictureObj.FileDetails = FileUploadResult[index].FileDetails;
    //       pictureObj.Picture = ( !pictureObj.ContentType.includes('video')) ?
    // imageBaseConvert : await FileData.FileThumbNailGenearate('assets/images/video.png');
    //       this.files.push(pictureObj);
    //       this.rearrangePicture(this.files);
    //     } else {
    //         this.fileSizeExceeded.push(true);
    //     }
    //   } else {
    //     this.validFormats.push(false);
    //   }
    // }
    
    this.files = [];
    FileUploadResult.map(async ( picData: UploadMetaData) => {
        picData.IsFavorite = picData.IsFavourite; 
      return picData;
    });
    this.rearrangePicture(FileUploadResult);
    // if (this.fileSizeExceeded.filter( x => x === true).length > 0) {
    //   alert('One or more file(s) has exceeded allowed upload file size (2MB).');
    //   this.fileSizeExceeded = [];
    // }
    // if (this.validFormats.filter(x => x === false).length > 0) {
    //   alert('Invalid File Formats Uploaded');
    //   this.validFormats = [];
    // }
    // this.fileInput.nativeElement.value = '';
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    if (this.fileBrowseUpload) {
      this.fileBrowseUpload.nativeElement.value = '';
    }
  }



  rearrangePicture(pictureData: Array<UploadMetaData>): void {
     try {
      const localPictureData = _.cloneDeep(pictureData);
      localPictureData.forEach(async (FileObject: UploadMetaData, index) => {
       const pictureObject = {} as FileDataPicture;
       const filedata = await this.readFileBinary(FileObject.FileData);
       const imageBaseConvert = this.domSanitizer.bypassSecurityTrustUrl(filedata);
       const alreadyHasVideo = this.data.PictureDetail?.findIndex(p => p.FileDetails.ContentType === 'video/mp4');
       const locPicture = ( !FileObject.ContentType.includes('video')) ? imageBaseConvert : 
         await FileData.FileThumbNailGenearate('assets/images/video.png');
       pictureObject.Name = FileObject.FileName ? FileObject.FileName : FileObject.FileName;
       pictureObject['FileName'] = FileObject.FileName;
       pictureObject['ContentType'] = FileObject.ContentType;
       pictureObject['GroupId'] = FileObject.GroupId;
       pictureObject['BlocksCount'] = FileObject.BlocksCount;
       pictureObject.Url = locPicture;
       pictureObject.Picture = locPicture;
       pictureObject.IsFavorite = false;
       pictureObject.Type = FileObject.ContentType;
       pictureObject['FileSize'] = FileObject.FileSize;
       pictureObject.SortOrder = ( this.data.PictureDetail.length === 0 ) ? 1 : this.data.PictureDetail.length + 1;
       pictureObject.FileData = FileObject.FileData;
       pictureObject.FileDetails = FileObject.FileDetails;
       if (alreadyHasVideo > -1 && pictureObject.Type === 'video/mp4') {
        this.toastrService.error('Video atatchment already exist.', '', 
        {
          closeButton: true,
          timeOut: 3000
        });
       } else {
        this.data.PictureDetail.unshift(pictureObject);
       }
       this.data.FormData.controls[this.data.PictureKey].value.splice(this.data.PictureDetail, 0, pictureObject);
       if (localPictureData.length - 1 === index) {        
          this.defaultPictureUrl = this.data.PictureDetail[0]['Url'];
          console.log(this.data.PictureDetail[0]);
          this.defaultPictureName = this.data.PictureDetail[0]['Name'];
          this.fileType = this.data.PictureDetail[0]['Type'];
          this.pictureData = [...this.data.PictureDetail];
       }
      });
     } catch (error) {
       console.error(error);
     }
  }

  closePopup(fileData: any) {
    if (!this.addPictureButton[0].displayProperity) {
      fileData.forEach((x: any) => {
        const pictureObj = {} as UploadMetaData;
        pictureObj.BlocksCount = x.BlocksCount;
        pictureObj.CloudFileName = x.CloudFileName;
        pictureObj.ContentType = x.ContentType;
        pictureObj.FileName = x.FileName;
        pictureObj.FileSize = x.FileSize;
        pictureObj.GroupId = x.GroupId;
        pictureObj.IsFavourite = x.IsFavourite;
        pictureObj.IsFavorite = x.IsFavourite;
        pictureObj.IsMetaCreated = x.IsMetaCreated;
        pictureObj.ItemId = x.ItemId;
        pictureObj.FileData = x.FileData;
        pictureObj.FileDetails = x.FileDetails;
      });
      this.dialogRef.close(fileData);
      if (this.MasterDataCollection) {
        this.MasterDataCollection.DetailsConfig.UploadFileCollection = [...fileData];
        console.log(this.MasterDataCollection.DetailsConfig.UploadFileCollection);
      }
    } else {
      this.dialogRef.close();
    }
  }
  getReturnFormValue(value: any) {
    console.log('sss');
  }

  ngOnChanges(): void {
    console.log('sss');
  }

  ngOnInit(): void {
    console.log('sss');
  }

}

export interface DeletePicture {
  groupId: number;
  itemId: number;
  fileName: string;
  filePath: string;
}
