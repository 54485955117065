import { ViewGridRowActionsConfig } from './grid-rowactions-config.interface';
import { ViewGridColumnHideConfig } from './grid-columhide-config.interface';
import { ViewGridCustomExpandConfig } from './grid-customexpand-config.interface';
import { ViewGridDataConfig } from './grid-data-config.interface';
import { ViewGridFilterConfig } from './grid-filter-config.interface';
import { ViewGridHeaderDefinationConfig } from './grid-headerdefination-config.interface';
import { ViewGridNameConfig } from './grid-name-config.interface';
import { ViewGriOdataKeyConfig } from './grid-odatakey-config.interface';
import { ViewGridSearchConfig } from './grid-search-config.interface';
import { ViewGridShowAddNewConfig } from './grid-showaddnew-config.interface';
import { ViewGridShowToggleConfig } from './grid-showtoggle-config.interface';
import { ViewGridSortConfig } from './grid-sort-config.interface';
import { ViewGridTittleConfig } from './grid-title-config.interface';
import { ViewGridActionsConfig } from './grid-action-config.interface';
import { ViewGridTableCardsConfig } from './grid-tablecards-config.interface';
import { GridItemListConfiguration } from './grid-itemlistdata-config.interface';
import { GridClientFilter } from './grid-filter-client.interface';

export const GridTransaction = {
  GridNameConfig: ViewGridNameConfig,
  GridOdataKeyConfig:  ViewGriOdataKeyConfig,
  GridShowToggleConfig: ViewGridShowToggleConfig,
  GridShowAddnNewConfig: ViewGridShowAddNewConfig,
  GridTitleConfig: ViewGridTittleConfig,
  GridFilterConfig: ViewGridFilterConfig,
  GridSortConfig: ViewGridSortConfig,
  GridSearchConfig: ViewGridSearchConfig,
  GridColumnHide: ViewGridColumnHideConfig,
  GridData: ViewGridDataConfig,
  GridHeaderDefination: ViewGridHeaderDefinationConfig,
  GridRowActions: ViewGridRowActionsConfig,
  GridCustomExpand: ViewGridCustomExpandConfig,
  GridActionsConfig: ViewGridActionsConfig,
  GridTableCardsConfig: ViewGridTableCardsConfig,
  GridItemListConfiguration: GridItemListConfiguration,
  GridClinetFilterConfig: GridClientFilter
};