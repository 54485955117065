export interface GlobalState {
    GetMenuExpand: boolean;
    GetIsLoggedIn: boolean;
    GetAccessToken: string;
    GetTenant: string;
    GetOnPrem: boolean;
    IsOutSideClick: boolean;
}

export const intialGlobalState: GlobalState = {
    GetMenuExpand: null,
    GetIsLoggedIn: null,
    GetAccessToken: null,
    GetTenant: null,
    GetOnPrem: null,
    IsOutSideClick: null
};
