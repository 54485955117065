import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, ItemsExtractFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class ItemsExtract extends BaseReport {
    functionalities: { [key: string]: boolean } = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness, private propertyInfo: RetailPropertyInformation
        ,public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.ItemsExtract,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let reportcategoryCaption: string;
        let captions: string[] = [];
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let radioFilterId: number = this.reportFormGrp.controls['customFilterFormGrp'].value.itemFilter;
        if (!customFilterData.filterItems) {
            reportcategoryCaption = this.business.getFilterCaption(this.business.FilterDataSource["allCategoryGroups"], customFilterData.reportcategory.map(x => x.id));
            captions = this.business.getSelectedCategoryCaptions(customFilterData);
        }
        let clerkCaption: string = this.business.getFilterCaption(this.business.FilterDataSource["allClerks"], customFilterData.clerks.map(x => x.id));
        let machineCaption: string = this.business.getFilterCaption(this.business.FilterDataSource["allMachineNames"], customFilterData.machineName.map(x => x.id));
        let paymentCaption: string = this.business.getFilterCaption(this.business.FilterDataSource["allPaymentMethod"], customFilterData.paymentMethods.map(x => x.id));

        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) }, { "pPageBreak": this.pageBreakToggle }
            , { "pSummaryByOutlet": radioFilterId == 1 ? "true" : "false" }, { "pSearchByItem": customFilterData.filterItems ? "true" : "false" },
        { "pOutlet":   this.functionalities.ShowOutletSelectionFieldInItemsExtractReport ? this.getFilterCaption : this.getDefaultOutletCaption },
        { "pPropertyName": this.propertyName }, { "pUser": clerkCaption }, { "pReportCategory": reportcategoryCaption },
        { "pCategory": captions[0] },
        { "pSubCategory1": captions[1] },
        { "pSubCategory2": captions[2] },
        { "pSubCategory3": captions[3] },
        { "pSubCategory4": captions[4] },
        { "pSubCategory5": captions[5] },
        { "pIncludeInactiveUsers": customFilterData.includeInactiveusers ? "true" : "false" },
        { 'pDate': this.printedDate },
        { 'pMachineName': machineCaption },
        { 'pPaymentMethod': paymentCaption },
        { 'pPrintMachineName': this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false }];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }


    protected formFilters() {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let toApi = this.localization.convertDateObjToAPIdate;
        let ids: number[];
        if (customFilterData.filterItems && customFilterData.searchbyitem != "") {
            ids = customFilterData.searchbyitem.split(',');
        }
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        let showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
        let isAllMachineSelected: boolean = this.business.checkAllSelected(this.business.FilterDataSource["allMachineNames"], customFilterData.machineName.map(x => x.id));
        const tempOutletFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        const tempMachineNameIds = isAllMachineSelected ? [] : customFilterData.machineName.map(x => x.id);
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInItemsExtractReport ?
                    this.selectedFilterOptions.map(x => x.id) : tempOutletFalse,
                ClerkIds: customFilterData.clerks.map(x => x.id),
                MachineNameIds: showMachineDropDown ? tempMachineNameIds : [],
                CategoryIds: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[0],true),
                SubCategory1Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[1],false),
                SubCategory2Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[2],false),
                SubCategory3Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[3],false),
                SubCategory4Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[4],false),
                SubCategory5Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[5],false),
                PaymentMethodIds: customFilterData.paymentMethods.map(x => x.id),
                ReportCode: AllReports.ItemsExtract,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            };
        }
        let filter: Partial<ItemsExtractFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInItemsExtractReport ?
                this.selectedFilterOptions.map(x => x.id) : tempOutletFalse,
            ClerkIds: customFilterData.clerks.map(x => x.id),
            MachineNameIds: showMachineDropDown ? tempMachineNameIds : [],
            CategoryIds: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[0],true),
            SubCategory1Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[1],false),
            SubCategory2Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[2],false),
            SubCategory3Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[3],false),
            SubCategory4Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[4],false),
            SubCategory5Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[5],false),
            PaymentMethodIds: customFilterData.paymentMethods.map(x => x.id),
            ReportCode: AllReports.ItemsExtract
        };
        return filter;
    }

    fillFilter(customFilterData: any, returnactual): number[] {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
        if (!returnactual && customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0) {
            ids = [];
        }
        return ids;
    }
}


