import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ButtonTypes } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-delete-dependency-alert',
  templateUrl: './delete-dependency-alert.component.html',
  styleUrls: ['./delete-dependency-alert.component.scss']
})
export class DeleteDependencyAlertComponent implements OnInit {
  captions: any;
  title: string;
  subHeader: any;
  deps: string[];
  actionButton: ButtonValue;
  printButton: ButtonValue;
  public buttonType = ButtonTypes;
  cancelText: string;
  continueText: string;
  YES: string;
  NO: string;
  okText: string;
  constructor(private localization: Localization, @Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<DeleteDependencyAlertComponent>) {
    this.captions = this.localization.captions;
    this.title = this.data.lable;
    this.subHeader = this.data.subHeader;
    this.deps = this.data.message;
    this.continueText = this.localization.captions.alertPopup.continue;
    this.cancelText = this.localization.captions.alertPopup.cancel;
    this.YES = this.localization.captions.alertPopup.yes;
    this.NO = this.localization.captions.alertPopup.no;
    this.okText = this.localization.captions.alertPopup.okay;
  }

  ngOnInit(): void {
   
    this.actionButton = {
      label: this.captions.alertPopup.okay,
      type: 'primary'
    };
    this.printButton = {
      label: this.captions.btn_print,
      type: 'secondary'
    };
  }

  close(arg) {
    this.dialogRef.close();
  }

  okay(arg) {
    this.dialogRef.close(arg);
  }

  print(arg) {
    this.dialogRef.close(arg);
  }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }
}
