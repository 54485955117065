import { Component,  Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpaLocalization } from '../localization/spa-localization';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginPopupComponent  {
  captions: any;
  loginPopupFormGrp: UntypedFormGroup;

  constructor(public dialog: MatDialogRef<LoginPopupComponent>, @Inject( MAT_DIALOG_DATA ) public data: any, private localization: SpaLocalization, private Form: UntypedFormBuilder) { 
    this.captions= this.localization.captions.common;
    this.loginPopupFormGrp = this.Form.group({
    });
  }

  DialogClose = () => {
    this.dialog.close();
  }

}
