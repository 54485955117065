import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailFeatureFlagInformationService } from "src/app/retail/shared/service/retail.feature.flag.information.service";
export class InventoryOnHand extends BaseReport {
    constructor(public reportFormGrp: UntypedFormGroup,
                public data: ReportDataService,
                public business: ReportBusinessService,
                private func: RetailFunctionalityBusiness,
                public propertyInfo: RetailPropertyInformation,
                private featureFlag: RetailFeatureFlagInformationService) {
        super(reportFormGrp);
    }
    functionalities: { [key: string]: boolean} = {};

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.InventoryOnHand,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: false,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT'
        };
    }

    protected formReportParams(): ReportParams[] {
        const inventoryOnHandFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        this.functionalities = this.func.getFunctionality();
        let radioFilterId: number = this.reportFormGrp.controls['customFilterFormGrp'].value.itemFilter;
        let categoryFilter: string[] = this.business.getSelectedCategoryCaptions(inventoryOnHandFilterData);
        let toApi = this.localization.convertDateObjToAPIdate;
        return [
            { "isSuppressZeroQuantity": radioFilterId === 1 ? "true" : "false" },
        { "isLastCostMethod": inventoryOnHandFilterData.lostCostMethod },
        { "pPageBreak": this.pageBreakToggle },
        { "pSuppressZeroQty": radioFilterId === 1 ? "true" : "false" },
        { "pCategoryOnly": inventoryOnHandFilterData.categoryView},
        { "pOutletFilter": this.functionalities.ShowOutletSelectionFieldInInventorySummaryReport ?
                             this.getFilterCaption : this.getDefaultOutletCaption},
        { "pLastCostMethod": inventoryOnHandFilterData.lostCostMethod },
        { "pDisposableItems": inventoryOnHandFilterData.DisposableItems },
        { "pSubCategory1": categoryFilter[1] },
        { "pSubCategory2": categoryFilter[2] },
        { "pSubCategory3": categoryFilter[3] },
        { "pSubCategory4": categoryFilter[4] },
        { "pSubCategory5": categoryFilter[5] },
        { "pCategoryFilter": categoryFilter[0] },
        {"pPropertyName" : this.propertyName },
        { "pDate" : this.printedDate },
        {"pIsEatecEnabled":this.featureFlag.IsEatecEnabled==true ? "true" : "false"}
        ];
    }

    protected formURIParams(): ReportParams[] {
        return [];
    }

    protected formFilters() {
        const inventoryOnHandFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.isJasperReportEnabled)
            {
                let outletids = this.functionalities.ShowOutletSelectionFieldInSaleByCorrectOrVoidReport ? this.getMultiSelectValuesForJasper('outletOptions')
                : this.getDefaultOutletIdForJasper;
                return{
                    CategoryIds: inventoryOnHandFilterData.category[0].selectedCat.length > 0 && inventoryOnHandFilterData.category[0].selectedCat.length != inventoryOnHandFilterData.category[0].catArr.length? this.getCategoryMultiSelectValuesForJasper(inventoryOnHandFilterData.category[0].selectedCat): [],
                    SubCategory1Ids: inventoryOnHandFilterData.category[1].selectedCat.length > 0 && inventoryOnHandFilterData.category[1].selectedCat.length != inventoryOnHandFilterData.category[1].catArr.length? this.getCategoryMultiSelectValuesForJasper(inventoryOnHandFilterData.category[1].selectedCat): [],
                    SubCategory2Ids: inventoryOnHandFilterData.category[2].selectedCat.length > 0 && inventoryOnHandFilterData.category[2].selectedCat.length != inventoryOnHandFilterData.category[2].catArr.length? this.getCategoryMultiSelectValuesForJasper(inventoryOnHandFilterData.category[2].selectedCat): [],
                    SubCategory3Ids: inventoryOnHandFilterData.category[3].selectedCat.length > 0 && inventoryOnHandFilterData.category[3].selectedCat.length != inventoryOnHandFilterData.category[3].catArr.length? this.getCategoryMultiSelectValuesForJasper(inventoryOnHandFilterData.category[3].selectedCat): [],
                    SubCategory4Ids: inventoryOnHandFilterData.category[4].selectedCat.length > 0 && inventoryOnHandFilterData.category[4].selectedCat.length != inventoryOnHandFilterData.category[4].catArr.length? this.getCategoryMultiSelectValuesForJasper(inventoryOnHandFilterData.category[4].selectedCat): [],
                    SubCategory5Ids: inventoryOnHandFilterData.category[5].selectedCat.length > 0 && inventoryOnHandFilterData.category[5].selectedCat.length != inventoryOnHandFilterData.category[5].catArr.length? this.getCategoryMultiSelectValuesForJasper(inventoryOnHandFilterData.category[5].selectedCat): [],
                    OutletIds: outletids,
                    isLastCostMethod: inventoryOnHandFilterData.lostCostMethod,
                    disposableItems: inventoryOnHandFilterData.DisposableItems,
                    isSuppressZeroQuantity: inventoryOnHandFilterData.itemFilter,
                    currentUserId: this.localization.GetUserInfo('userId'),
                    PageBreak: this.pageBreakToggle,
                    IsEatecEnabled: this.propertyInfo.IsEatecEnabled,
                };
            }
        return {
            categoryIds: this.fillFilter(inventoryOnHandFilterData.category[0]),
            subCategory1Ids: this.fillFilter(inventoryOnHandFilterData.category[1]),
            subCategory2Ids: this.fillFilter(inventoryOnHandFilterData.category[2]),
            subCategory3Ids: this.fillFilter(inventoryOnHandFilterData.category[3]),
            subCategory4Ids: this.fillFilter(inventoryOnHandFilterData.category[4]),
            subCategory5Ids: this.fillFilter(inventoryOnHandFilterData.category[5]),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInInventorySummaryReport ? this.selectedFilterOptions.map(x => x.id) :
                tempOutFalse,
            reportCode: AllReports.InventoryOnHand,
            isLastCostMethod: inventoryOnHandFilterData.lostCostMethod,
            disposableItems: inventoryOnHandFilterData.DisposableItems,
            isSuppressZeroQuantity: inventoryOnHandFilterData.itemFilter === 1
        };
    }
    fillFilter(customFilterData: any): number[] {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
        if(customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0){
            ids =[];
        }
        return ids;
    }
}


