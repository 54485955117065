import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { SaveButtonObj } from '../../more/more-model';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';

@Component({
  selector: 'app-cgps-sync',
  templateUrl: './cgps-sync.component.html',
  styleUrls: ['./cgps-sync.component.scss']
})
export class CgpsSyncComponent implements OnInit {
  captions: any;
  pg_title: string;
  Form: UntypedFormGroup;
  buttonObj: SaveButtonObj;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  minEndDate: Date;
  currentDate: Date;
  

  
  
  constructor(private localization:Localization,private fb:UntypedFormBuilder,private utils: CommonUtilities,@Inject(MAT_DIALOG_DATA) data: any,
  private dialogRef: MatDialogRef<CgpsSyncComponent>,private propertyInfo: CommonPropertyInformation) {
    this.captions=this.localization.captions;
   }

  ngOnInit(): void {
    this.pg_title='Onboarding Sync';
    this.buttonObj = {
      customSaveText: "SYNC",
      customCancelText:  this.captions.btn_cancel,
      disabled:true,
    };
    this.currentDate = this.localization.getCurrentDate();
    this.formBuilder()
    this.Form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.buttonObj.disabled = !( this.Form.valid && this.Form.dirty);
      this.buttonObj = { ...this.buttonObj };
    });
  }

  formBuilder()
  {
    this.Form = this.fb.group({
      startDate: '',
      endDate:''
    });
  }

  closeHandler(e)
  {
    this.dialogRef.close();
  }

  close(e)
  {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  saveHandler(e)
  {
    this.dialogRef.close(this.Form.value);
  }

  startDate(e){
    this.minEndDate = e.date
    let dateValidation = this.utils.ValidateDatesGreaterThan(this.Form.controls.startDate.value, this.Form.controls.endDate.value)
    // if(this.Form.controls.startDate.value > this.Form.controls.endDate.value)
    if(dateValidation) {
      // this.Form.controls.endDate.setValue(this.Form.controls.startDate.value);
      this.Form.controls.endDate.setErrors({ incorrect: true });
      this.Form.updateValueAndValidity();
    }
    else {
      if (this.Form.controls.endDate.value) {
        this.Form.controls.endDate.setErrors(null);
        this.Form.updateValueAndValidity();
      }
    }
  }
}
