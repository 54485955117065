import { AlertType } from 'src/app/common/enums/shared-enums';
import { BehaviorSubject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Client, GraphRequest, Options } from '@microsoft/microsoft-graph-client';
import { UserAgentApplication, CacheLocation } from 'msal';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { MsalConfiguration } from '../../services/MsalConfig';
import { GraphServiceParams, GraphUser } from '../../Models/ms-graph-http.model';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { StorageService } from '../../shared/services/session-utilization.service';

export class MsGraphHttpService {
    private app: UserAgentApplication;
    private graphClient: Client;
    private loggedInUser = new BehaviorSubject('');
    currentUser = this.loggedInUser.asObservable();
    clientUserAuthenticated = new BehaviorSubject(false);
    captions: any;
    clientAuthenticated: boolean;
    signedInAs: string;
    utilities: CommonUtilities;
    config: any;
    pendingGraphRequest: GraphRequest[];
    constructor(
        private propertyInfo: CommonPropertyInformation,
        localization: Localization,
        utilities: CommonUtilities, private storageService: StorageService) {
        this.captions = localization.captions;
        this.utilities = utilities;
        if (!this.app)
            this.Init();
    }

    Init() {
        try {
            this.config = (this.propertyInfo.GetPropertyConfiguration() || {});
            if (this.config.hasOwnProperty('clientId')) {
                this.app = new UserAgentApplication({
                    auth: {
                        clientId: this.config.clientId,
                        redirectUri: (MsalConfiguration.redirectUri || window.location.origin),
                    },
                    cache: {
                        cacheLocation: (MsalConfiguration.cacheLocation || 'sessionStorage') as CacheLocation,
                        storeAuthStateInCookie: true
                    },
                    framework: {
                        unprotectedResources: MsalConfiguration.unprotectedResources,
                    }
                });

                this.app.handleRedirectCallback((authError, response) => {
                    if (authError) {
                        console.error('Redirect Error: ', authError.errorMessage);
                        return;
                    }
                    console.log('Redirect Success: ', response.accessToken);
                });

                this.graphClient = Client.init(this.getClientOptions());
            }
            else {
                console.log("ClientId not registered for this property");
            }
        }
        catch (err) {
            console.dir(err);
        }
    }

    private getClientOptions(): Options {
        return {
            authProvider: async (done) => {
                const token = await this.getAccessToken()
                    .catch((reason) => {
                        done(reason, null);
                    });
                if (token) {
                    done(null, token);
                } else {
                    done('Could not get an access token', null);
                }
            }
        }
    }

    protected getPromise<T>(params: GraphServiceParams): Promise<T> {
        const vClient = this.buildGraphClient(params);
        return vClient.get();
    }

    protected postPromise<T>(params: GraphServiceParams): Promise<T> {
        const vClient = this.buildGraphClient(params);
        return vClient.post(params.body);
    }

    protected putPromise<T>(params: GraphServiceParams): Promise<T> {
        const vClient = this.buildGraphClient(params);
        return vClient.update(params.body);
    }

    protected deletePromise<T>(params: GraphServiceParams): Promise<T> {
        const vClient = this.buildGraphClient(params);
        return vClient.delete();
    }

    protected errorHandler(err: HttpErrorResponse): void {   
        console.log(err);
    }

    private buildGraphClient(params: GraphServiceParams): GraphRequest {      
        let url: string = this.formURL(params);
        let gr: GraphRequest = this.graphClient.api(url);       
        if (params.isBeta) {
            gr = gr.version('beta');
        }
        return gr;
    }
  

    async signInToGraph(): Promise<boolean> {
        this.initializeApp();
        if (this.app) {
            try {
                const result = await this.app.loginPopup(this.utilities.getMsalAuthParams());
                if (result) {
                    try {
                        let response = await this.app.acquireTokenSilent(this.utilities.getMsalAuthParams());
                        this.storageService.setstoragekey('msal_access_token', response.accessToken);
                        this.clientAuthenticated = true;
                        this.clientUserAuthenticated.next(this.clientAuthenticated);
                        this.loggedInUser.next(this.captions.mail_signedInAs + result.account.userName);
                    } catch (silentError) {
                        try {
                            const response = await this.app.acquireTokenPopup(this.utilities.getMsalAuthParams());
                            this.storageService.setstoragekey('msal_access_token', response.accessToken);
                            this.clientAuthenticated = true;
                            this.clientUserAuthenticated.next(this.clientAuthenticated);
                            this.loggedInUser.next(this.captions.mail_signedInAs + result.account.userName);
                        } catch (popupError) {
                            console.log(JSON.stringify(popupError, null, 2));
                            this.clientUserAuthenticated.next(false);
                            this.loggedInUser.next(this.captions.mail_signInToMicrosoft);
                            this.clientAuthenticated = false;
                        }
                    }
                } else {
                    this.clientUserAuthenticated.next(false);
                    this.loggedInUser.next(this.captions.mail_signInToMicrosoft);
                    this.clientAuthenticated = false;
                }
            } catch (loginError) {
                console.log(JSON.stringify(loginError, null, 2));
                this.clientUserAuthenticated.next(false);
                this.loggedInUser.next(this.captions.mail_signInToMicrosoft);
                this.clientAuthenticated = false;
            }
            return this.clientAuthenticated;
        }
        return false;
    }
    

    async isAuthenticated() {
        if (!this.clientAuthenticated) {
            await this.getAccessToken();
        }
        return this.clientAuthenticated;
    }

    async getAccessToken(): Promise<string> {
        this.initializeApp();
        
        if (this.app) {
            if (!this.clientAuthenticated) {
                const result = await this.app.acquireTokenSilent(this.utilities.getMsalAuthParams())
                    .catch((reason) => {
                        console.log(JSON.stringify(reason, null, 2));
                    });
                if (result) {
                    this.clientAuthenticated = true;
                    this.loggedInUser.next(this.captions.mail_signedInAs + result.account.userName);
                    return result.accessToken;
                } else {
                    this.loggedInUser.next(this.captions.mail_signInToMicrosoft);
                }
            }
            else{
                let token = this.storageService.getstoragekey('msal_access_token')
                return token && token != '' ? token : null
            }
            
        }
        return null;
    }

    async getUser(): Promise<GraphUser> {
        try {
            if (!this.clientAuthenticated) { return null; }
            return await this.graphClient.api('/me').get();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async isAuthenticatedToAction(action?: string): Promise<boolean> {
        const isAuthenticated = await this.isAuthenticated();
        if (isAuthenticated === undefined || !isAuthenticated) {
            this.utilities.showCommonAlert(this.captions.mail_pleaseLoginToMicrosoft + action, AlertType.Error);
        }
        return isAuthenticated;
    }

    async signOutOfGraph(): Promise<boolean> {
        this.app.logout();
        this.clientAuthenticated = false;
        this.loggedInUser.next(this.captions.mail_signInToMicrosoft);
        return this.clientAuthenticated;
    }
     escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    private formURL(params: GraphServiceParams): string {
        this.validate(params);
        let url: string = '';
        if (params.uriParams != undefined && params.uriParams != null && typeof params.uriParams == 'object') {
            let route: string = params.route;
            let keys: string[] = Object.keys(params.uriParams);    
            for (let i = 0; i < keys.length; i++) {
                const escapedKey = this.escapeRegExp(keys[i]);
                const regEx = new RegExp('{' + escapedKey + '}', 'ig');
                route = route.replace(regEx, params.uriParams[keys[i]]);
            }  
            url += route;
        } else {
            url += params.route;
        }

        url = this.formatQueryString(url, params);
        return url;
    }

    private formatQueryString(url: string, params: GraphServiceParams): string {
       
        let queryParams: string[] = this.matchQueryStringRegex(url);
        for (var queryParam of queryParams) {
            var paramName = queryParam.split(':')[0];
            paramName = paramName ? paramName : '';
            // paramName = paramName.replace('{', '');
            paramName = paramName.replace(/\{/g, '');
            var qParamValue = params.uriParams[paramName];
            var qParamString = '';
            if (typeof qParamValue == 'object' && qParamValue && qParamValue.length > 0) {
                for (var value of qParamValue) {
                    qParamString += `${paramName}=${value}&`
                }
                // To remove last &
                qParamString = qParamString.substr(0, qParamString.length - 1);
            }
            else if (typeof qParamValue == 'object' && qParamValue && qParamValue.length == undefined) {
                let keys: string[] = Object.keys(qParamValue);
                for (let key of keys) {
                    let route = `${key}=${qParamValue[key]}&`;
                    qParamString += route;
                }

                // To remove last &
                if (qParamString.lastIndexOf('&') == qParamString.length - 1)
                    qParamString = qParamString.substr(0, qParamString.length - 1);
            }
            else {
                qParamString = `${paramName}=${qParamValue}`;
            }
            url = url.replace(queryParam, qParamString);
        }
        return url;
    }

    private validate(params: GraphServiceParams): void {
        if (!params) {
            throw new Error('Route not defined');
        }
        if (params.route.includes('{') && !params.uriParams) {
            let message: string = `Route Param '${params.route.match('\{(.*?)\}')[1]}' is not defined in route '${params.route}'`;
            alert(message);
            throw new Error(message);
        }
    }

    private matchQueryStringRegex(url: string): string[] {
        var regex = /{[A-Z]+:QueryString}/gi;
        var expMatch: RegExpExecArray;
        var result: string[] = [];
        while ((expMatch = regex.exec(url)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (expMatch.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            expMatch.forEach((match, groupIndex) => {
                result.push(match);
            });
        }
        return result;
    }

    initializeApp() {
        if (!this.app)
            this.Init();
    }

}
