import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ClientInformationComponent } from '../appointment-popup/create-appointment/client-information/client-information.component';
import { Host } from '../globalsContant';
import { appointmentService } from '../service/appointment.service';
import { AppointmentpopupService } from '../service/appointmentpopup.service';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';

@Component({
  selector: 'app-find-relationship',
  templateUrl: './find-relationship.component.html',
  styleUrls: ['./find-relationship.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class FindRelationshipComponent implements OnInit {
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  floatLabel: string;
  itemArray:any =[]
  guestIds:any=[];
  relatedClientInfo:any=[];
  okayButtonStatus: string;
  isRowDisabled: boolean = false;
  isCheckAllEnabled: boolean = true;
  removeClient: any = [];
  totalSelectedArray =[];
  checkallArr=[];
  isAllchecked:boolean=false;
  okayButtonCaption: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public localization: SpaLocalization,
  public dialog: MatDialogRef<FindRelationshipComponent>,private Form: UntypedFormBuilder,private http: HttpServiceCall,
  public appointmentservice: AppointmentpopupService,private utils: CommonUtilities,private apptService: appointmentService) {
    this.floatLabel = this.localization.setFloatLabel;
   }
  ngOnInit(): void {
    this.initialize();
    this.okayButtonStatus = 'spa-primary-disabled';
  }

  initialize(){
   this.data?.form?.forEach((a,index)=>{
     this.itemArray.push({
      id: index+1,
      contactGuestId:a.contactGuestId,
      clientName: a.contactGuestFirstName+" "+a.contactGuestLastName,
      relationship: a.contactRelationshipName,
      checked:false,
      isRowDisabled : false,
      isCreate : false
     });
    });
    this.okayButtonCaption = this.data?.swapClient ? this.captions.SWAP : this.allCaptions.btn_add;
    this.totalSelectedArray = this.appointmentservice.recordsArray;
    this.appointmentservice.recordsArray.forEach(x => {
      this.itemArray.forEach(y => {
        if(x.clientDetail.guestId == y.contactGuestId){
          y.checked = true
        }
      })
    })
    if(this.itemArray.length>0){
    this.checkallArr =this.itemArray.filter(i=>i.checked==false)
    if(this.checkallArr.length==0)
    {
      this.isAllchecked=true;
    }
    else{
      this.isAllchecked=false
    }
  }
  if((this.apptService.PackageBookingType != 'Standard' && !this.appointmentservice.isSpaWizard)||(this.apptService.PackageBookingType == 'Standard' && !this.appointmentservice.isSpaWizard && this.apptService.PackageMaxPeople!=-1)){
    if((this.itemArray.length + this.appointmentservice.recordsArray.length) > this.appointmentservice.clientScreenProperties.maxGuest) {
      this.isCheckAllEnabled = false;
    }
    else {
      this.isCheckAllEnabled = true;
    }
  }
 }


  DialogClose = (result) => {
    this.dialog.close(result);
  }
  onSave(){
  this.guestIds=this.appointmentservice.relatedClient.map(r=>r.contactGuestId);
  this.GetAllClientsbyguid(this.guestIds).then(x => {
    this.relatedClientInfo = {
      data : x,
      relatedClient : this.appointmentservice.relatedClient
    };
    this.dialog.close(this.relatedClientInfo);
  });
}
  allSelection(eve){
    this.okayButtonStatus = 'spa-primary-default-save';
    if(eve) {
      this.itemArray.forEach(x=>{
        x.checked = eve.checked;
      })
    }
    this.appointmentservice.relatedClient = this.itemArray;
  }
  onItemSelection(eve,item){
    this.appointmentservice.relatedClient = [];
    this.appointmentservice.relatedClient = this.itemArray;
    this.okayButtonStatus = 'spa-primary-default-save';
    this.itemArray.forEach(x => {
      if(x.id === item.id) {
        x.checked = eve.checked;
        if(eve.checked == false) {
          this.totalSelectedArray = this.totalSelectedArray.filter(res => res.clientDetail.guestId != item.contactGuestId) 
        }
        
      }
    })
    if(!this.appointmentservice.isSpaWizard){
    let relationArray= this.itemArray.filter(a=>a.checked==true);
    this.totalSelectedArray.forEach(x => {     
      relationArray= relationArray.filter(y=>y.contactGuestId!= x.clientDetail.guestId)           
    })
    let relationcount=relationArray.length;
  
    let maxlength = this.appointmentservice.clientScreenProperties.maxGuest;
    
    if(this.data?.swapClient)
      maxlength++;

    let totalRecordsSelected = relationcount + (this.totalSelectedArray.length);
    if(this.itemArray.length>0){
      this.checkallArr =this.itemArray.filter(i=>i.checked==false)
      if(this.checkallArr.length==0)
      {
        this.isAllchecked=true;
      }
      else{
        this.isAllchecked=false
      }
    }
    if((this.apptService.PackageBookingType != 'Standard' && !this.appointmentservice.isSpaWizard)||(this.apptService.PackageBookingType == 'Standard' && !this.appointmentservice.isSpaWizard && this.apptService.PackageMaxPeople!=-1)){
    this.itemArray.forEach(x => {
      if(x.checked == false && totalRecordsSelected >= maxlength) {
        x.isRowDisabled = true;
      }
      else {
        x.isRowDisabled = false;
      }
    })
  }
  }
  }

  
  async GetAllClientsbyguid(guestIds: any[]):Promise<number[]>
  {
    let response=await this.http.CallApiAsync<any>({
      host: Host.spaManagement,
      callDesc: "GetClientsByGuestIds",
      method:HttpMethod.Put,
      body: guestIds
    });
    return response.result;
  }

}
