import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { Outlet, SubPropertyModel, OutelTerminalHeaderDropDown, CCTerminal, OutletsCRUD, Translog, OutletUIMapper, Product } from 'src/app/retail/retail.modals';
import { AgCountryConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { GridOperationType, RetailFunctionalities } from 'src/app/retail/shared/globalsContant';
import { RetailCreditCardTerminalDataService } from 'src/app/retail/retail-code-setup/retail-credit-card-terminal/retail-credit-card-terminal-data.service';
import { RetailOutletsBusinessService } from 'src/app/retail/retail-code-setup/retail-outlets/retail-outlets-business';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { AlertAction } from 'src/app/common/Models/common.models';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { AgAddressFieldConfig, AgPhoneDetail } from 'src/app/retail/shared/shared.modal';
import { AgContactConfig } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-create-reatil-outlets',
  templateUrl: './create-reatil-outlets.component.html',
  styleUrls: ['./create-reatil-outlets.component.scss'],
  providers: [RetailCreditCardTerminalDataService, RetailOutletsBusinessService],
  encapsulation: ViewEncapsulation.None
})
export class CreateReatilOutletsComponent implements OnInit {
  captions: any;
  dataInput: any;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  form: UntypedFormGroup;
  terminals: OutelTerminalHeaderDropDown[] = []
 // terminalOptions: { id: number; value: string; viewValue: string; }[];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  minInputLength=2;
  floatLabel: string;
  functionalities: { [key: string]: boolean } = {};
  ShowDefaultToggle: boolean;
  @Input() throttletime = 3000;
  addressInput: AgAddressFieldConfig;
  propertyCaptions:any;
  phoneInputs: AgContactConfig;
  phoneInfo: AgPhoneDetail[];
  productId: number;
  mailInfo: [];
  addressValue: string[];
  showIncludeOutletToggle: boolean;
  hideInAccounting: boolean = false;
  filteredCountries: Observable<any>;
  settingCaptions: Object;
  showMorePhoneTypes: boolean;
  countryInput : AgCountryConfig;

  constructor(private fb: UntypedFormBuilder, private ccData: RetailCreditCardTerminalDataService,
    private localization: RetailLocalization,  private propertyConfig: RetailPropertyInformation,
    private business: RetailOutletsBusinessService, public func: RetailFunctionalityBusiness, private propertyInfo: PropertySettingDataService,private utils: RetailUtilities,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CreateReatilOutletsComponent>,
    public _ss: CommonVariablesService) {
    this.dataInput = data;
    this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() {

    this.productId = this._ss.ProductId;
    this.functionalities = await this.func.getRetailFunctionality();
    this.showMorePhoneTypes = this.functionalities[RetailFunctionalities.ShowMorePhoneTypes];
    this.formGenerator()
    this.ShowDefaultToggle = this.functionalities[RetailFunctionalities.ShowDefaultToggleInOutletCreation] || false;
    this.hideInAccounting = this.functionalities.HideOptionsForAccountingProduct;
    this.showIncludeOutletToggle = this.functionalities[RetailFunctionalities.ShowOutletAddressToggleInOutletCreation] || false;
    this.captions = this.localization.captions.retailsetup;
    this.settingCaptions = this.localization.captions.setting;

    await this.onPageLoad();

    this.actionButton = {
      type: 'primary',
      label: this.dataInput.datarecord.actionButton,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.Cancel,
    };
    this.countryInput = {
      className: 'golf-form-control--sm',
      form: this.form,
      formControlName: 'country',
      automationId :"Txt_createRetailOutlet_country"
    }; 

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.actionButton.disabledproperty = !(this.form.dirty && this.form.valid)
    });

    this.utils.geCountriesJSON().then(res => {
      this.filteredCountries = this.form.controls.country.valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        map((country: string) => country ? this.utils.FilterCountry(country, this.utils.countryDetails) : [])
      );
      this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (this.form.controls['city'].value) {
          this.form.controls.country.setErrors({ required: true });
        } else {
          this.form.controls.country.setErrors(null);
        }
        if (this.form.controls['country'].value &&
          !this.utils.FilterCountryValueFromData(this.form.controls['country'].value)) {
          this.form.controls.country.setErrors({ invalid: true });
        } else if ((this.form.controls['city'].value &&
          this.utils.FilterCountryValueFromData(this.form.controls['country'].value) &&
          this.form.controls['country'].value) || (!this.form.controls['city'].value &&
            !this.form.controls['country'].value)) {
          this.form.controls.country.setErrors(null);
        }
        this.form.controls['country'].markAsTouched();
      });
    });

  }

  async onPageLoad() {
    let cCTerminals: CCTerminal[] = await this.ccData.getAllCCTerminal();
    this.terminals = this.propertyConfig.UseRetailInterface ? [] : this.business.getTerminals(cCTerminals);
  }

  ngAfterViewInit()
  {
    this.patchValues()
  }


  formGenerator()
  {
    this.form=this.fb.group({
      OutletName:['',Validators.required],
      profitCenter:[''],
      terminalId:'',
      AutoPrintReceipts: false,
      active: true,
      default: false,
      id: 0,
      postalCode: '',
      city: '',
      country: '',
      state: '',
      includeOutletAddress: false,
      address: this.fb.array([
        this.fb.group({
          addressDetails: ''
        })
      ]),
      phone: this.fb.array([
        this.fb.group({
          phoneType: '',
          phoneNumber: '',
        })
      ])
    })

    this.addressInput = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'addressDetails',
      disabled: false,
      maxlength : 250
    };

    this.phoneInputs = {
      form: this.form,
      formArrayName: 'phone',
      showMoreTypes: this.showMorePhoneTypes,
      showOthers: false
    };
  }

  get address(){
    return this.form.get('address') as FormArray
  }

  async onAction(e) {
    const clickReturnValue = {
      from: this.dataInput.datarecord.mode,
      formValues: this.form.value
    };
    if (!clickReturnValue.formValues.active && clickReturnValue.formValues.default) {
      this.utils.showAlert(this.captions.alert_defaultOutletInactive, AlertType.Warning, ButtonType.Ok, res =>{
        this.form.controls['active'].markAsPristine();
        this.form.controls['active'].markAsUntouched();
        this.form.controls['active'].setValue(true);
      });
      return;
    }
    if(clickReturnValue.formValues.default) {
      let defaultOutletId = await this.propertyInfo.getDefaultOutlet();
      if (defaultOutletId > 0 && defaultOutletId != clickReturnValue.formValues.id) {
        this.utils.showAlert(this.captions.alert_DefaultAlreadyExists, AlertType.Warning, ButtonType.YesNo, res => {
          if (res === AlertAction.YES) {
            this.dialogRef.close(clickReturnValue);
          }
        });
      }
      else {
        this.dialogRef.close(clickReturnValue);
      }
    }
    else {
      this.dialogRef.close(clickReturnValue);
    }
  }

  patchValues() {

    if (this.dataInput.datarecord.mode === GridOperationType.Edit) {
      let arr = [];
      if(this.dataInput.datarecord.form.outletAddresses[0]?.address1){
          arr.push({addressDetails: this.dataInput.datarecord.form.outletAddresses[0].address1})
      }
      if(this.dataInput.datarecord.form.outletAddresses[0]?.address2){
        arr.push({addressDetails: this.dataInput.datarecord.form.outletAddresses[0].address2})
    }
    if(this.dataInput.datarecord.form.outletAddresses[0]?.address3){
      arr.push({addressDetails: this.dataInput.datarecord.form.outletAddresses[0].address3})
    }
    for(let i = 1; i < arr.length; i++){
        (this.form.get('address') as FormArray).push(this.fb.group({addressDetails:''}));
    }
      this.form.patchValue({
        OutletName:this.dataInput.datarecord.form.OutletName,
        profitCenter: this.dataInput.datarecord.form.profitCenter,
        terminalId: this.dataInput.datarecord.form.terminalId,
        AutoPrintReceipts: this.dataInput.datarecord.form.AutoPrintReceipts,
        active:this.dataInput.datarecord.form.active,
        default: this.dataInput.datarecord.form.default,
        id: this.dataInput.datarecord.form.id,
        postalCode: this.dataInput.datarecord.form.outletAddresses[0]?.zip,
        city: this.dataInput.datarecord.form.outletAddresses[0]?.city,
        state: this.dataInput.datarecord.form.outletAddresses[0]?.state,
        country: this.dataInput.datarecord.form.outletAddresses[0]?.country,
        includeOutletAddress: this.dataInput.datarecord.form.includeOutletAddress,
        address: arr,
    });

    this.phoneInfo = (this.dataInput.datarecord.form.outletContacts as any[]).map(value => {
      return {
         id: value.id,
         countryCode: this.utils.getCountryCodeFromValue(value.contactTypeId, value.number),
         phoneNumber: this.utils.getPhoneNumberFromValue(value.contactTypeId, value.number),
         phoneType: value.contactTypeId,
         extension: value.number.split(':').length > 1 ? value.number.split(':')[0] : '',
         isPrimary: false,
         isPrivate: false,
         disabled: false}
      });

    }
  }


  checkValidState(e)
  {

  }

  onCancel(e)
  {
    this.dialogRef.close();
  }

  close(e)
  {
    this.dialogRef.close();
  }

  terminalChange(e)
  {

  }

}
