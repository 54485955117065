import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, HeaderConfiguration, TilesConfiguration, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridShowToggleConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): any => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                if ( TransactionCollection.ViewTranscationConfig && 
                     TransactionCollection.ViewTranscationConfig.GridConfiguration && 
                     TransactionCollection.ViewTranscationConfig.GridConfiguration.ShowActiveToggle
                ) {
                    return TransactionCollection.ViewTranscationConfig.GridConfiguration.ShowActiveToggle;    
                }
               
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };