import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer } from '@angular/forms';
import { Product } from 'src/app/retail/shared/globalsContant';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-retail-inventory-summary',
  templateUrl: './inventory-summary.component.html',
  styleUrls: ['./inventory-summary.component.scss']
})
export class RetailInventorySummaryComponent implements OnInit {
  InventorySummaryFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  productId: number;
  hideDisposableItem: boolean;
  radioFilter: { id: number; name: string; }[];
  disposableItemsValue: boolean;
  editPatchValue: any;
  isFromEdit = false;
  isFromJobScheduler: boolean = false;
  categoryComponentFilter: any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private controlContainer: ControlContainer, private container: ViewContainerRef) {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.hideDisposableItem = this.productId == Product.PMS ? false : true;
   }

  ngOnInit() {
    this.radioFilter = [{id: 0, name: this.captions.AllActiveitems}, {id: 1, name: this.captions.SuppressZeroQtyItems}];
    this.InventorySummaryFormGrp = this.fb.group({
      lostCostMethod: false,
      categoryView: false,
      itemFilter: 0,
      category: new UntypedFormGroup({}),
      DisposableItems: this.disposableItemsValue == null ? false : this.disposableItemsValue
    });
    this.formReady.emit(this.InventorySummaryFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if (this.localization.isFromJobScheduler && _parent.edit){
        this.categoryComponentFilter =
        {
          category: _parent.editableValue.categoryIds,
          subCategory1: _parent.editableValue.subCategory1Ids,
          subCategory2: _parent.editableValue.subCategory2Ids,
          subCategory3: _parent.editableValue.subCategory3Ids,
          subCategory4: _parent.editableValue.subCategory4Ids,
          subCategory5: _parent.editableValue.subCategory5Ids
        };
        this.PatchSchedulercontrols(_parent.edit);
      }
    }
  }

  private PatchSchedulercontrols(isEdit) {
    if (this.localization.isFromJobScheduler && isEdit) {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      setTimeout(() => {
        if (patchItem.lostCostMethod != undefined)
          this.InventorySummaryFormGrp.controls.lostCostMethod.patchValue(patchItem.lostCostMethod);
        if (patchItem.lostCostMethod != undefined)
          this.InventorySummaryFormGrp.controls.lostCostMethod.patchValue(patchItem.lostCostMethod);

        if (patchItem.categoryView != undefined)
          this.InventorySummaryFormGrp.controls.categoryView.patchValue(patchItem.categoryView);

        if (patchItem.DisposableItems != undefined)
          this.InventorySummaryFormGrp.controls.DisposableItems.patchValue(patchItem.DisposableItems);

        if (patchItem.itemFilter != undefined){
          var itemFilter = patchItem.itemFilter == "true" ? 1 : 0;
          this.InventorySummaryFormGrp.controls.itemFilter.patchValue(itemFilter);
        }          
      }, 1);
    }
  }

  toggleDisposableItems(event){
    this.disposableItemsValue = event;
  }

   /**
   * After a form is initialized, we link it to our main form
   */
  formInitialized(form: UntypedFormGroup) {
    this.InventorySummaryFormGrp.setControl('category', form);
  }


  IsSelectionValid(validationObject: ValidationMessage) {
    this.IsValidSelection.emit({ dropDownName: validationObject.dropDownName, validity: validationObject.validity });
  }
}
