import { AfterViewInit, Component, Inject, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { SuggestQuantityPopUpModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-suggestqty-popup',
  templateUrl: './agilysys-suggestqty-popup.component.html',
  styleUrls: ['./agilysys-suggestqty-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysSuggestqtyPopupComponent implements OnInit, OnChanges, AfterViewInit {
  agilysysAddNewButton: AgilysysButtonModule[];
  homeGroup: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AgilysysSuggestqtyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuggestQuantityPopUpModel,
    public formBuilder: FormBuilder
  ) {
    this.homeGroup = this.formBuilder.group({});
    const _that = this;
    if (this.data.FormGroupData) {
      setTimeout(function () {
        _that.homeGroup.patchValue(_that.data.FormGroupData);
      }, 100);
    }
  }

  addButtonClick(callBackData: CallBackData) {
    if (this.homeGroup.valid) {
      if (this.data.Classname = 'Batch-Items') {
        this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup, this.data.FormFields]);
      } else {
        this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
      }
    } else {
      this.homeGroup.markAllAsTouched();
    }
  }


  getReturnFormValue(value: any) {
    // console.log('FormValue', value.form);
  }

  ngOnChanges(): void {
    const _that = this;
    // if (this.data.FormGroupData) {
    //      setTimeout(function () { _that.homeGroup.patchValue(this.data.FormGroupData); }, 1000);
    // }
  }

  ngAfterViewInit(): void {
    const _that = this;
    if (this.data.FormGroupData) {      
      setTimeout(function () {
        _that.homeGroup.patchValue(_that.data.FormGroupData);
      }, 300);
    }
    if (this.data.Classname && this.data.Classname === 'suggest-quantity') {
      this.homeGroup.controls['Add'].valueChanges.subscribe(x => {  
        const alternateParIndex = _that.data.FormFields.findIndex(y => y.fieldType.SingleData.name === 'AlternatePar');
        if (alternateParIndex > -1) {
          _that.data.FormFields[alternateParIndex].fieldType.SingleData.disbaledProperty = !(x === 'AlternatePar');
          if (_that.data.FormFields[alternateParIndex].fieldType.SingleData.disbaledProperty === false) {
            _that.homeGroup.controls['AlternatePar'].setValidators([Validators.required]);          
          }  else {
            _that.homeGroup.controls['AlternatePar'].setValidators([]);
          }  
          _that.homeGroup.controls['AlternatePar'].updateValueAndValidity(); 
        }            
      });
      if (this.homeGroup.controls['MinimumOrderQuantity'] !== undefined && this.homeGroup.controls['MinimumOrderQuantity'] !== null) {
        this.homeGroup.controls['MinimumOrderQuantity'].valueChanges.subscribe(x => {
          _that.data.FormFields.forEach(field => {
             if (field.fieldType.SingleData.name !== 'MinimumOrderQuantity') {
              field.fieldType.SingleData.disbaledProperty = x;
              if (x === false && field.fieldType.SingleData.name === 'AlternatePar') {
                field.fieldType.SingleData.disbaledProperty = !(_that.homeGroup.controls['Add'].value === 'AlternatePar');
                if (field.fieldType.SingleData.disbaledProperty === false) {
                  _that.homeGroup.controls['AlternatePar'].setValidators([Validators.required]);               
                } else {
                  _that.homeGroup.controls['AlternatePar'].setValidators([]);
                }
                _that.homeGroup.controls['AlternatePar'].updateValueAndValidity();
              }
             }
          });
        });
      }      
    }
  }

  ngOnInit(): void {
    if (this.data.Hide) {
      this.agilysysAddNewButton = [];
    } else {
      if (!this.data.UpdateButtonShow) {
      this.agilysysAddNewButton = [
        {
          displayProperity: false,
          displayName: 'Cancel',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
          }
        },
        {
          displayProperity: false,
          displayName: 'Confirm',
          buttonKey: 'Confirm',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
            this.addButtonClick(callBackData);
          }
        }
      ];
    } else if (this.data.SaveButton) {
      this.agilysysAddNewButton = [
        {
          displayProperity: false,
          displayName: 'Cancel',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
          }
        },
        {
          displayProperity: false,
          displayName: 'Save',
          buttonKey: 'Confirm',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
            this.addButtonClick(callBackData);
          }
        }
      ];
    } else {
    this.agilysysAddNewButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: 'Update',
        buttonKey: 'Update',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.addButtonClick(callBackData);
        }
      }
    ];
    }
  }
}
}
