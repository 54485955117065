import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollItemCount, InfiniteScrollItemListCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AddNewTypeConfig, HeaderConfiguration, LookupConfiguration,
SearchListHeaderConfig, SearchShowMoreConfig, TransactionConfiguration, TransactionLookupData } from '../transaction.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { ItemListSearch } from '../view-transaction/view-configuration.interface';
import { lookup } from 'dns';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { FormGroup } from '@angular/forms';
import { Filter } from '../../../../shared/nonodatacore/models/filter.interface';
import { AdvancedSearchParams } from 'src/app/eatecui/source/shared/nonodatacore/models/filterArguments.interface';
import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';

export const ItemSearch: ItemListSearch = (
httpService: HttpService,
searchByKey: any,
lookupForm: FormGroup,
searchByType: AddNewTypeConfig,
sysytemPreferenceService: SysytemPreferenceService,
searchHeader: SearchListHeaderConfig[], TransactionData: TransactionConfiguration, 
// requestPagination: RequestPagination, filter: Filter,
searchShowMoreConfigs: SearchShowMoreConfig, selectedModule: string): any => {
        return new Promise(function (resolve, reject) {
        if ( searchByKey && searchByType ) {
          // const searchParams = {} as AdvancedSearchParams;
          //   searchParams.lookupField = lookupForm.value.lookupFields;
          //   searchParams.searchText = searchByKey;
          //   searchParams.searchPatternId = lookupForm.value.searchPattern;
           // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            const endPointUrl = ( searchByType.Name === 'Inventory' || searchByType.Name === 'Recipes' || searchByType.Name === 'Product') 
            ? searchByType.EndPointUrl : searchByType.Url;
            let seleId = `contains(tolower(Name),'${searchByKey.toLowerCase()}')`;
            const selectedTypeId = searchByType.DefaultTypeId ? searchByType.DefaultTypeId : searchByType.TypeId;
            if (searchByType.Name === 'Packages') {
                let TypeId = 1;
                if (TransactionData && TransactionData.Name === 'Packages') {
                    if (TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData) {
                        TypeId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'];
                    } else {
                        TypeId = searchByType.DefaultTypeId ? searchByType.DefaultTypeId : searchByType.TypeId;
                    }

                } else if (searchByType && searchByType.Name !== 'Inventory' && searchByType.TypeId
                && searchByType.EndPointUrl !== 'IngredientLookup') {
                    TypeId = searchByType.DefaultTypeId ? searchByType.DefaultTypeId : searchByType.TypeId;
                }
                seleId = seleId + ` and TypeId eq ${TypeId}`;
            }
            // Added for Bug Fix 190196
            if (searchByType.Name === 'Type' && TransactionData && TransactionData.CreateTranscationConfig.
                TransactionFormConfig.FormGroupData && TransactionData.Name === 'Batch Item Maintenance') {
                const DivisionId = TransactionData.CreateTranscationConfig.
                    TransactionFormConfig.FormGroupData['Division'];
                seleId = seleId + ` and DivisionId eq ${DivisionId}`;
            }
            // if ( selectedModule === 'Recipes' && selectedTypeId === 1 ) {
            //     seleId = seleId + ` and DivisionId eq ${1}`;
            // }
            // Below Line commented because of Inventory Restriction De-Activate

            // if (searchByType.Name === 'Type') {
            //     if (sysytemPreferenceService) {
            //         const inventoryRestrictionValue = sysytemPreferenceService.globalSystemPreference
            //         .filter(x => x.LookupName === 'Inventory Restriction')[0];
            //         if (inventoryRestrictionValue && inventoryRestrictionValue.LookupValue !== 3) {
            //             seleId = seleId + ` and DivisionId eq ${inventoryRestrictionValue.LookupValue}`;
            //         }
            //     }
            // }

            const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            const params: QueryParams = {
                count: true,
                top: InfiniteScrollItemListCount,
                skip: 0,
                expand: searchByType.Name === 'Packages' ? 'PackLists($filter=IsActive eq true;$select=Item,IsActive)' : '',
                select: '',
                filter: '',
                orderby: '',
                isactive: true,
                search: '',
                filterQuery: GetDataFilter,
                searchtext: ''
            };
            if (searchShowMoreConfigs) {
                params.top = searchShowMoreConfigs.Take;
                params.skip = searchShowMoreConfigs.Skip;
            }
            // if ( searchByType.Name === 'Inventory' && false) {
            //    const transactionLookupData: Array<TransactionLookupData> = LookupData['default'];
            //    const selectedName = transactionLookupData.filter(x => x.LookupType === 'Inventory')
            //    .map(e => e['FieldName'] = ( e.FieldName ) ? e.FieldName : null);
            //    if ( selectedName.length > 0 ) {
            //     params.select = selectedName.toString();
            //    }
            // }
            const getQueryParams = formQueryParams(params);
            const ModuleName = ( TransactionData && TransactionData.Name ) ? TransactionData.Name : null;
            let LocId = 0;
            let options = '';
            const lookUpData = {} as LookupConfiguration;
            lookUpData.TransType = ( TransactionData && TransactionData.TransactionType ) ? TransactionData.TransactionType : 1;
            if (TransactionData && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData) {
                switch (ModuleName) {
                    case 'Transfer':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                        break;
                    case 'spoilage':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                    case 'Requisition':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                        break;
                    case 'Purchase Request':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['RequestLocId'];
                        options = AssignBidsOptions(TransactionData);
                        break;
                    case 'Physical Inventory':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                    case 'Purchase Order':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        options = AssignBidsOptions(TransactionData);
                        break;
                    case 'Receiving':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                    case 'Production':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                    case 'Item Pars':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                        break;
                    case 'Batch Item Maintenance':
                        lookUpData.DivisionId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['Division'];
                        lookUpData.IsBatchItem = true;
                        lookUpData.TransType = 0;
                        break;
                    case 'Butchery':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                    case 'sales':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SalesLocationId'];
                        break;
                    case 'productionplan':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                    case 'PlanHead':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                    case 'cycleplanning':
                        const locId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        LocId = locId > 0 ? locId : 0;
                        break;
                    case 'quickorder':
                        LocId = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                        break;
                }
            }
            lookUpData.Id = 0;
            if (ModuleName === 'Butchery') {
                lookUpData.IsButchery = true;
            }
            lookUpData.TransType = ( TransactionData && TransactionData.TransactionType ) ? TransactionData.TransactionType : 1;
            if (searchByType.EndPointUrl === 'GetRecipeIngredientLookup') {
                lookUpData.itemType = searchByType.DefaultTypeId ? searchByType.DefaultTypeId : searchByType.TypeId;
                lookUpData.IsFromMasterLimit = ModuleName === 'PackageLimitation' ? true : false;
            } else if (searchByType.EndPointUrl === 'IngredientLookup') {
              lookUpData.SupplierId = ( TransactionData && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SupplierId']) ? 
              TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SupplierId'] : 0;
              lookUpData.PostDate = ( TransactionData && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate']) ? 
              TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate'] : null;
            lookUpData.IsFromMasterLimit = ModuleName === 'PackageLimitation' ? true : false;
            } else if (searchByType.EndPointUrl === 'ProductLookup') {
                lookUpData.IsFromMasterLimit = ModuleName === 'PackageLimitation' ? true : false;
            }

            lookUpData.searchText = searchByKey;
            lookUpData.FromLocationId = LocId;
            lookUpData.ToLocationId =
            ( TransactionData && TransactionData.CreateTranscationConfig
                && TransactionData.CreateTranscationConfig.TransactionFormConfig
                && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData
                 && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'] ) ?
             TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'] : undefined;
            lookUpData.TypeIds = [];
            lookUpData.PackageIds = [];
            lookUpData.ClassIds = [];
            lookUpData.options = options;
            lookUpData.flgSearch = true;

            lookUpData.PostDate = (TransactionData && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData
                && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate'])
                ? TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate'] : null;
            if (searchShowMoreConfigs) {
                lookUpData.FetchAll = searchShowMoreConfigs.FetchAll;
                lookUpData.Take = searchShowMoreConfigs.Take;
                lookUpData.Skip = searchShowMoreConfigs.Skip;
            }
            if (selectedModule === 'Recipes' && selectedTypeId === 1) {
                lookUpData.DivisionIds = [1];
            }
            if (searchByType.Name === 'Inventory' || searchByType.Name === 'Recipes' || searchByType.Name === 'Product') {
            const GetDataByIdUrl = `/inventory/api/${endPointUrl}`;
            const responseData = httpService.PosthttpMethod(GetDataByIdUrl, lookUpData).toPromise();
            resolve(responseData);
            } else {
            const GetDataByIdUrl = `/inventory/api/${endPointUrl + getQueryParams}`;
            const responseData = httpService.GethttpMethod(GetDataByIdUrl).toPromise();
            resolve(responseData);
            }

        }
    });

};

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function AssignBidsOptions (transactionJson) {
    if (transactionJson.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData) {
      const ExpiredBids = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['ExpiredBids'] ? '1' : '0';
      const Priority = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['Priority'] ? '1' : '0';
      const SkipZero = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['SkipZero'] ? '1' : '0';
      const ClearPrices = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['ClearPrices'] ? '1' : '0';
      const ClearSupplier = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['ClearSupplier'] ? '1' : '0';
      const BidRegion = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['BidRegion'] ? '1' : '0';
      const options = ExpiredBids + Priority + SkipZero + ClearPrices + ClearSupplier + BidRegion;
      return options;
    } else {
      return '011000';
    }
  }

// function setSortDirection(GridSort) {
//   if (GridSort.length > 0) {
//     const sort: GridSortAction = GridSort.filter(x => x.DefaultSorting === true)[0];
//     return sort.SortingType.toLowerCase() === 'asc' ? 1 : 2;
//   } else {
//     return 1;
//   }
// }
// function setSortColumn(GridSort) {
//   if (GridSort.length > 0) {
//     const sort: GridSortAction = GridSort.filter(x => x.DefaultSorting === true)[0];
//     return sort.SortingOdataKey;
//   } else {
//     return '';
//   }
// }
