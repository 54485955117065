import { Pipe, PipeTransform, Injectable } from '@angular/core';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Pipe({ name: 'Currency' })
@Injectable()
export class CustomCurrencyPipe implements PipeTransform {
    constructor(private language: SpaLocalization) {
    }

    transform(value: any, currencySymbolRequired: boolean = true): string {
        return this.language.localizeCurrency(value, currencySymbolRequired);
    }
}
