import * as _ from 'lodash';
import { QuickOrderCalculationConfig } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';

export const QuickOrderAlgorithm: QuickOrderCalculationConfig = (GridData: Array<any>, Saleskey: string, Info: any, GetByIdResponse?: any): any => {
    try {
        let returnValue = 0;
        const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
        const decimalValue = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength;
        const IsTaxChecked = GetByIdResponse['value']['QuickOrderSettings']['IsTaxChecked'];
        if (GetByIdResponse && GetByIdResponse.hasOwnProperty('value')) {
            let amount = 0;
            let taxValue = 0;
            let totalTaxValue = 0;
            taxValue = GetByIdResponse.taxValue ? JSON.parse(GetByIdResponse.taxValue) : null;  
            GridData.forEach((gridData: any) => {
                amount += gridData.Valued ? parseFloat(gridData.Valued) : 0;
                totalTaxValue += gridData.TaxValue ? parseFloat(gridData.TaxValue) : 0;
                if (!gridData.TaxValue || gridData.TaxValue === 0) {
                    gridData.TaxAmount1 = gridData.TaxAmount1 !== undefined ? gridData.TaxAmount1 : 0;
                    gridData.TaxAmount2 = gridData.TaxAmount2 !== undefined ? gridData.TaxAmount2 : 0;
                    const rowtotaltax = parseFloat(gridData.TaxAmount1) + parseFloat(gridData.TaxAmount2);
                    totalTaxValue += rowtotaltax;
                    gridData.TaxValue = parseFloat((rowtotaltax !== null ? rowtotaltax : 0).toString());
                }
                // Commented and Updated Above
                // Becoz TaxValue will be coming from GetById or Will be present in RowData during add
                // if (taxValue === null || taxValue === undefined) {                            
                //     const rowtotaltax =  parseFloat(gridData.TaxAmount1) + parseFloat(gridData.TaxAmount2);
                //     totalTaxValue += rowtotaltax;
                //     gridData.TaxValue = parseFloat((rowtotaltax !== null ? rowtotaltax : 0).toString());
                // } else {
                //     gridData.TaxValue = parseFloat((taxValue).toString());
                // }
            });                
            if (GetByIdResponse['value'] && GetByIdResponse['value']['QuickOrderSummary']) {
                const summaryValue = calculateQuickOrderSummary(GetByIdResponse['value']['QuickOrderSummary'], amount);                
                if (Info.Name === 'subtotal') {
                    returnValue = amount;
                    } else if (Info.Name === 'tax') {
                        let serviceTax = 0;
                        if ( totalTaxValue === 0 && amount === 0) {
                            serviceTax = 0;
                        } else if (parseFloat(totalTaxValue.toString()) > parseFloat(summaryValue['totalTax'])) {
                            serviceTax = parseFloat(summaryValue['totalTax'])
                        } else if (parseFloat(totalTaxValue.toString()) < parseFloat(summaryValue['totalTax'])) {
                            serviceTax = parseFloat(summaryValue['totalTax']) - parseFloat(totalTaxValue.toString());
                        }
                        totalTaxValue += (!IsTaxChecked ? 0 : serviceTax);
                        returnValue = !IsTaxChecked ? 0 : totalTaxValue;
                    } else if (Info.Name === 'ServiceCharges') {
                    returnValue = summaryValue['serviceCharge'];
                    } else if (Info.Name === 'Discount%') {
                    const discountPercent = GetByIdResponse['value'].QuickOrderSettings['Discount'];
                    returnValue = discountPercent;
                    } else if (Info.Name === 'Discount$') {
                    returnValue = summaryValue['discountCost'];
                    }
                returnValue = Formater('Currency', returnValue, null, null);
            }
        } else {
            if (Info.Name === 'subtotal') {
                returnValue = GridData.reduce(function (accumulator, curValue) { return accumulator + (curValue.Valued ?  parseFloat(curValue.Valued) : 0)}, 0);
            } else if (Info.Name === 'tax') {
                returnValue = GridData.reduce(function (accumulator, curValue) { return accumulator + (curValue.TaxValue ?  parseFloat(curValue.TaxValue) : 0)}, 0);
            }
            returnValue = Formater('Currency', returnValue, null, null);
        }
        return returnValue;
    } catch (Error) {
        console.error('Error occurred in Sales Calculation', Error);
    }
};

function calculateQuickOrderSummary(quickOrderSummaryDetails: any, TotalAmount: any): any {
    let serviceCharge = 0;
    let discountCost = 0;
    let TotalTax = 0;
    if (quickOrderSummaryDetails && quickOrderSummaryDetails.length > 0) {
        quickOrderSummaryDetails.forEach((quickOrderSummary: any) => {
            if (quickOrderSummary.QOSummaryLinkTypeId === 3) {
                serviceCharge += parseFloat(quickOrderSummary.Valued.toString());
            } else if (quickOrderSummary.QOSummaryLinkTypeId === 6) {
                discountCost += parseFloat(quickOrderSummary.Valued.toString());
            } else if (quickOrderSummary.QOSummaryLinkTypeId === 2) {
                TotalTax += parseFloat(quickOrderSummary.Valued);
              }
        });
    }
    const returnValue = {
        serviceCharge: serviceCharge ? serviceCharge : null,
        discountCost: discountCost ? discountCost : null,
        totalTax: TotalTax ? TotalTax : null
    };
    return returnValue ? returnValue : 0;
}