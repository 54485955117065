import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";

@Injectable()
export class ServiceDiscountTypeBusiness {
    captions: any;

    constructor(private localization: Localization) {
        this.captions = this.localization.captions;
    }

    getServiceGroupOptions(){
        return[
            {
                id: 0,
                value: "Public",
                viewValue: "Public" 
            },
            {
                id: 1,
                value: "Member",
                viewValue: "Member" 
            },
            {
                id: 3,
                value: "Guest",
                viewValue: "Guest" 
            }
        ]
    }

    getServiceTypes(){
        return[
            {
                id: 0,
                value: "Public 18 rate",
                viewValue: "Public 18 rate" 
            },
            {
                id: 1,
                value: "Member 18 rate",
                viewValue: "Public 18 rate" 
            },
        ]
    }

    getDiscountTypes(){
        return[
            {
                id: 0,
                name: "Winter sale",
                selected: false
            },
            {
                id: 1,
                name: "Shipping discount",
                selected: false
            },
            {
                id: 3,
                name: "Flat 50%",
                selected: false
            }
        ]
    }
}