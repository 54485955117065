import { PipeTransform } from '@angular/core';
import { Localization } from './localization';


export abstract class LocalizeDateTimePipe implements PipeTransform {

    constructor(private localization: Localization) {
    }
  transform(value: any): any {
    return value? this.localization.LocalizeDate(value) + " - "  + this.localization.LocalizeTime(value): "";
  }

}
