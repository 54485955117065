import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { LockerAssignmentDetails, LockerAvailabilityRequest, LockerInformation } from './lockers.model';
import { BaseResponse } from '../shared.modal';


@Injectable({
  providedIn: 'root'
})
export class AddLockersService {

  constructor(private http: HttpServiceCall) { }

  getLockersWithAvailability(){

  }

  async SaveLocker(lockerAssignment: LockerAssignmentDetails, isUpdate: boolean = false) {

    let response = await this.http.CallApiAsync<LockerAssignmentDetails[]>({
      host: Host.retailPOS,
      callDesc: RetailRoutes.CreateLockerAssignment,
      method: isUpdate ? HttpMethod.Put : HttpMethod.Post,
      body: [lockerAssignment]
    })
    return response.result;

  }

  async GetLockerAvailability(lockerAvailabilityRequest: LockerAvailabilityRequest) {
    try {
      return await this.http.CallApiAsync({
        host: Host.retailPOS,
        callDesc: RetailRoutes.GetLockerAvailability,
        method: HttpMethod.Put,
        body: lockerAvailabilityRequest,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getRetailItemsDetailedInfoByIds(retailItemIds: any[]): Promise<any[]> {
    const result = await this.http.CallApiAsync<any>({
      host: Host.retailManagement,
      callDesc: "GetRetailItemDetailedInfoList",
      method: HttpMethod.Put,
      body: retailItemIds
    });

    return result.result;
  }

  async CheckLockerAssingedToClient(startDateTime, endDateTime, clientId) {
    const result = await this.http.CallApiAsync<any>({
      host: Host.retailPOS,
      callDesc: RetailRoutes.CheckLockerAssingedToClient,
      uriParams: { clientId: clientId, startDateTime: startDateTime, endDateTime: endDateTime },
      method: HttpMethod.Get,
    });
    return result.result;
  }

  async GetLockerInformationByLockerIds(lockerIds: number[]): Promise<BaseResponse<LockerInformation[]>> {
    return await this.http.CallApiAsync<LockerInformation[]>({
      callDesc: RetailRoutes.GetLockerInformationByIds,
      host: Host.retailManagement,
      method: HttpMethod.Put,
      body: lockerIds
    })
  }

  
}
