import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { CreateTransactionItemlist } from '../create-transcation/item-transaction';
import { LookupConfiguration, TransactionConfiguration } from '../transaction.interface';
import { CustomCreditsFormConfiguration, CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import moment from 'moment';

export const AutoCompleteSearchInventory: CustomCreditsFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, 
    CustomData?: any
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod && FieldGrouping.hasOwnProperty('autoCompleteHeader') && typeof CustomData === 'string' ) {
            const lookUpData = {} as LookupConfiguration;
            lookUpData.Id = 0;
            lookUpData.TransType = 0;
            lookUpData.searchText = CustomData;
            lookUpData.FromLocationId = 0;
            lookUpData.ToLocationId = 0;
            lookUpData.TypeIds = [];
            lookUpData.PackageIds = [];
            lookUpData.ClassIds = [];
            const EndPointUrl =  `/inventory/api/${FieldGrouping.CustomFormAction.Url}`;
            httpService.PosthttpMethod(EndPointUrl, lookUpData).subscribe(res => {
                const responseData = res['Data']['ent']['ResultData'];
                const autoCompleteData = FormField.filter((x: FormType) => x.type === 'autocomplete');
                if (autoCompleteData && autoCompleteData.length > 0) {
                    const autoFormFields = autoCompleteData[0];
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [];
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [...responseData];
                }
            });
        }

        if (FieldGrouping.CustomFormAction.EnableCustomMethod &&
            FieldGrouping.hasOwnProperty('autoCompleteHeader') &&
            FieldGrouping.autoCompleteHeader.length > 0 && typeof CustomData !== 'string') {
                FormGroupData.controls['ReplaceItem'].setValue(CustomData.Name);
                FormGroupData.controls['ReplaceItemId'].setValue(CustomData.IngredientId);
                const autoCompleteData = FormField.filter((x: FormType) => x.type === 'autocomplete');
                if (autoCompleteData && autoCompleteData.length > 0) {
                    const autoFormFields = autoCompleteData[0];
                    autoFormFields['fieldType']['SingleData'].disbaledProperty = true;
                }
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};