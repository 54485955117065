export enum AuthoriztionKey {
    CANCELREQUISITION = 'CancelRequisition',
    CANCELREQUISITIONTEMPLATE = 'CancelRequisitionTemplate',
    REACTIVATEDEACTIVATEMASTERLIMITATION = 'ReactivateDeactivateMasterLimitation',
    CREATEREQUISITIONTEMPLATE = 'CreateRequisitionTemplate',
    CREATEREQUISITION = 'CreateRequisition',
    CREATEPACKAGE = 'CreatePackage',
    CREATETRANFEROUT = 'CreateTransfersOut',
    CREATETRANFER = 'CreateTransfers',
    LOADORDERGUIDE = 'loadorderguide',
    SALESPLANNING = 'Planning',
    PACKAGESMODULENAME = 'Package',
    REVERSE = 'reverse',
    VIEWUSERPROFILE = 'ViewUserProfile',
    VIEWROLE = 'ViewRole',
    CREATEUSERPROFILE = 'CreateUserProfile',
    CREATEROLE = 'CreateRole',
    VIEWFBINVENTORY = 'ViewFBInventory',
    VIEWRETAILINVENTORY = 'ViewRetailInventory',
    VIEWCUSTOMER = 'ViewCustomer',
    VIEWSUPPLIERS = 'ViewSuppliers',
    VIEWRETAILPRODUCT = 'ViewRetailProduct',
    VIEWFBPRODUCT = 'ViewFBProduct',
    CREATEFBINVENTORY  = 'CreateFBInventory',
    CREATERETAILINVENTORY  = 'CreateRetailInventory',
    CREATECUSTOMER = 'CreateCustomer',
    CREATESUPPLIER = 'CreateSuppliers',
    CREATEFBPRODCUT = 'CreateFBProduct',
    CREATERETAILPRODUCT = 'CreateRetailProduct',
    VIEWRECIPE = 'ViewRecipe',
    CREATERECIPE = 'CreateRecipe',
    ACTIVATEDEACTIVATERECIPE = 'ActivateDeactivateRecipe',
    RDUSERPROFILE = 'ReactivateDeactivateUserProfile',
    RDROLEPROFILE = 'ReactivateDeactivateRole',
    REPLACEBASEUNIT = 'ReplaceBaseUnit',
    REPLACEITEM = 'ReplaceInventoryItem',
    DEFINEPRODUCT = 'DefineProducts',
    WIZARD = 'Wizard',
    REACTIVEDEACTIVEFB = 'ReactivateDeactivateF&BInventory',
    REACTIVEDEACTIVERETAIL = 'ReactivateDeactivateRetailInventory',
    REACTIVATEDCUSTOMER = 'ReactivateDeactivateCustomer',
    REACTIVATESUPPLIER = 'ReactivateDeactivateSupplier',
    REACTIVEPRODUCTFB = 'ReactivateDeactivateFBProduct',
    REACTIVATERETAILPRODUCT = 'ReactivateDeactivateRetailProduct',
    NUTRITION = 'nutrients',
    MANAGEPACKAGE = 'package',
    CANCELPURCHASEREQUEST = 'CancelRequest',
    CANCELPURCHASEORDER = 'CancelPO',
    SUPPLIERBIDS = 'supplierbids',
    CUSTOMERCONTACTS = 'CustomerContacts',
    SUPCOMMUNICATION = 'SupplierSitesAccConfig',
    ACCTIVITY = 'Activity',
    REVERSETRANSFEROUT = 'ReverseTransferandTransferOut',
    ACTIVEDEACTIVEPACKAGES = 'ReactivateDeactivatePackage',
    REVERSESPOILAGE = 'ReverseSpoilage',
    REVERSEINVOICE = 'ReverseInvoice',
    REVERSEAP = 'ReverseAP',
    ASSIGNBIDS = 'assignBids',
    SUGGESTQTY = 'suggestqty',
    TRANSACTIONTAB = 'transactions', // Master DetailsTab
    CROSSREFERENCETAB = 'crossreference', 
    HISTORYTAB = 'history',
    PRICINGTAB = 'pricing',
    INVNTORYDETAILTAB = 'Inventory Details',
    SETTINGTAB = 'Settings',
    LOCATIONTAB = 'Location',
    BILLIGTAB = 'Billing Address & Comms',
    OTHERDETAILSTAB = 'Other Fields', // Master DetailsTab
    CREATEWORKFLOW = 'CreateWorkflows',
    CREATEPURCHASEORDER = 'CreatePurchaseOrder',
    RUNWORKFLOW = 'RunWorkflows',
    DEFINEFINISHEDGOODS = 'DefineFinishedGoods',
    PRINTNUTRITION = 'PrintNutrition',
    REVERSESALES = 'ReverseSales',
    CREATESALES = 'CreateSalesPlanning',
    CREATESPOILAGELOSS = 'CreateSpoilageandloss' ,
    DOCUMENTAPPROVALS = 'DocumentApprovals',
    CREATEPHYSICALINVENTORY = 'CreatePhysicalInventory',
    CREATEPURCHASEREQUEST = 'CreatePurchaseRequests',
    CREATERECEIVING = 'CreateReceiving',
    CREATEAPVOUCHERS = 'CreateAPVouchers',
    CREATEAPCREDITS = 'CreateAPCredits',
    CREATEMISCINVOICEAP = 'CreateMiscInvoiceAP',
    CREATEMISCINVOICEAR = 'CreateMiscInvoiceAR',
    CREATEPRODUCTION = 'CreateProduction',
    CREATEBUTCHERY = 'CreateButchery',
    ACCOUNTACTIVITY = 'AccountActivity',
    APPROVALS = 'approvals',
    CREATESALE = 'CreateSales',
    VIEWSALESPLANNING = 'ViewSalesPlanning',
    ITEMPARS = 'ItemPars',
    BATCHITEMAINTENANCE = 'BatchItemMaintenance',
    REPORTS = 'Reports',
    REPORTSEXPORT = 'export',
    SHOWDASHBOARD = 'ShowDashboard',
    PUNCHOUTCONFIG = 'PunchoutConfiguration',
    CREATECYCLEPLANNING = 'CreateCyclePlanning',
    CREATEPRODUCTIONPLANNING = 'CreateProductionPlanning',
    CREATEQUICKORDER = 'CreateQuickOrders',
    CREATEEVENT = 'CreateEvent',
    SHOWVALUEWAREHOUSEPROCUREMENTWIDGET = 'ShowvalueinWarehouseProcurmentWidget',
    CREATEMASTERLIMITATION = 'CreateMasterLimitation',
    CREATEMRP = 'CreateMRP'
}

export enum AuthorizationModule {
    USERMANAGEMENT = 'usermanagement',
    MASTERMANAGEMENT = 'mastermanagement',
    TRANSACTION = 'transactionsmanagement',
    REPORTSMANGEMENT = 'reportsmanagement',
    INTEGERATIONMGNT = 'integrationmanagement',
    DASHBOARDMGMT = 'dashBoardmanagement',
    SETUPMANAGEMENT = 'setupmanagement',
    PRODUCTIONMANAGEMENT = 'productionmanagement',
    ACCOUNTMANAGEMENT = 'accountmanagement',
    UTILITYMANAGEMENT = 'utilitymanagement',
    REPORTSMANAGEMENT = 'reportsmanagement',
    MESSAGE = 'messagemanagement',
    EVENTMANAGEMENT = 'eventmanagement',
    DASHBOARD = 'dashBoardmanagement'
} 
export enum AuthSubModule {
    REQUISTION = 'requisitions',
    SPOILAGELOSS = 'spoilageloss',
    APPROVALS = 'approvals',
    SUPPLIERS = 'suppliers',
    CUSTOMER = 'customer',
    RECIPES = 'Recipe',
    PHYSICALINVENTORY = 'physicalinventory',
    PURCHASEREQUEST = 'purchaserequest',
    PURCHASEORDER = 'purchaseorder',
    RECEIVING = 'receiving',
    APVOUCHER = 'apvoucher',
    APCREDITS = 'apcredits',
    MISCINVOICEAP = 'miscinvoiceap',
    MISCINVOICEAR = 'miscinvoicear',
    PRODUCTION = 'Production',
    ACCOUNT = 'account',   
    SALES = 'sales',
    PLANNING = 'Planning',
    ITEMPARS = 'ItemPars',
    BATCHITEMAINTENANCE = 'BatchItemMaintenance',
    REPORTS = 'reports',
    REPORTSEXPORT = 'export',
    DASHBOARD = 'dashboard',
    BUTCHERY = 'Butchery',
    TEMPLATE = 'template',
    EVENTS = 'events',
    MASTERLIMIT = 'packagelimitation',
    CREATEMASTERLIMIT = 'masterlimit'
 }