import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { FeatureName, RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RevenuePostTypes } from 'src/app/retail/retail.modals';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { FeatureValue, GridType, ButtonType } from 'src/app/retail/shared/globalsContant';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { PostTypeBusiness } from '../post-type-mapping.business';
import { DialogOverviewExampleDialog } from 'src/app/retail/shared/dialog-popup/dialogPopup-componenet';
import { MatDialog } from '@angular/material/dialog';
import { PropertyFeature } from 'src/app/retail/shared/business/property-features.model';
import { PropertyFeaturesConfigurationService } from 'src/app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { PropertyInfo } from 'src/app/retail/shared/business/shared.modals';
import { RetailConstants } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { FolioBusiness } from 'src/app/retail/Folio/folio-business';
import { AlertAction, AlertType } from 'src/app/common/shared/shared.modal';



@Component({
  selector: 'app-post-type-mapping-crud',
  templateUrl: './post-type-mapping-crud.component.html',
  styleUrls: ['./post-type-mapping-crud.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostTypeMappingCrudComponent implements OnInit {
  captions: any;
  searchText = '';
  maxInputLength = 30;
  @Input() dataInput: any;
  @Input() LinkType:number;
  @Input() isMultiplePmsPropertyEnabled: boolean = false;
  @Input() pmsPropCode:string = '';
  searchPlaceholderValue: any;
  IsViewOnly: any;
  tableoptions: any;
  isValid = false;
  isDisabled = true;
  tableHeader = [
    { title: 'Post Code Number', jsonkey: 'postCode', sortable: true, sortcolumn: 'postCodeNumber', sortcolumndatatype: 'number', "alignType": "right"},
    { title: 'Description', jsonkey: 'description', sortable: true },
    { title: 'Department', jsonkey: 'department', sortable: true }
  ];
  commonCaptions: any;
  headerData: any;
  tableData = [];
  selectedColumnValue: any;
  selectedData: any;
  OriginalSelectedData: any;
  functionalities: { [key: string]: boolean} = {};
  showOutletDetails = false;
  defaultOutlet: number;
  applyToAllButtonCaption:any;
  floatLabel: string;
  floatLabelNever: string;
  isResortFinance: boolean = false;

  constructor(private dialog:MatDialog,private localization: RetailLocalization, private dialogRef: MatDialogRef<PostTypeMappingCrudComponent>, private postTypeBusiness: PostTypeBusiness,
    private utilities: RetailUtilities, private func: RetailFunctionalityBusiness, public propertyInfo: RetailPropertyInformation, private _folioBusiness:FolioBusiness) {
      this.floatLabel = this.localization.setFloatLabel;
      this.floatLabelNever = this.localization.setFloatLabelNever;
     }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.defaultOutlet = this.propertyInfo.GetDefaultOutlet();
    this.commonCaptions = this.localization.captions.common;
    this.searchPlaceholderValue = this.localization.captions.retailsetup.SearchPostCode;
    this.headerData = this.dataInput[0];
    this.selectedColumnValue = this.dataInput[1];
    this.getTableData();
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      this.showOutletDetails = this.functionalities.ShowOutletDetailsInPostTypeMapping;

    });
   this.applyToAllButtonCaption=this.applyToAllButtonCaptions();
  }
  applyToAllButtonCaptions()
  {
    switch(this.LinkType)
    {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.Gratuity:
      case RevenuePostTypes.ServiceCharge:
        return this.commonCaptions.ApplyToAllRetailCategories;
      case RevenuePostTypes.Tax:
        return this.commonCaptions.ApplyToAllTaxes;
      case RevenuePostTypes.PaymentMethod:
        return this.commonCaptions.ApplyToAllPaymentMethods;
      case RevenuePostTypes.Surcharge:
        return this.commonCaptions.ApplyToAllCardTypes;
    }

  }

  async getTableData() {
    if (this.isResortFinance) {
      this.tableData = await this.LoadPostTypeDetailsForResortFinance();
    }
    else
    {
      const data = await this.LoadPostTypeDetails();
      this.tableData = data.result;
    }
    this.tableData.forEach(x => {
      x.postCodeNumber = Number(x.postCode);
      if (x.postCode === this.headerData[this.selectedColumnValue]) {
        x.radioSelected = true;
      }
    });
    this.BindToGrid();
  }
  async LoadPostTypeDetailsForResortFinance()
  {
    return this._folioBusiness.getPostTypes();
  }

  async LoadPostTypeDetails() {
    if (this.isMultiplePmsPropertyEnabled) {
      return this.postTypeBusiness.GetMultiPropertyPostTypeDetails(this.pmsPropCode);
    }
    else {
      return this.postTypeBusiness.GetPostTypeDetails();
    }
  }

  searchValue(event) {
    this.searchText = event.target.value;
    this.tableData.forEach(x => {
      if ((this.selectedData && x.postCode === this.selectedData.postCode) || x.postCode === this.headerData[this.selectedColumnValue]) {
        x.radioSelected = true;
      } else {
        x.radioSelected = false;
      }
    });
  }

  resetValue() {
    this.searchText = '';
  }

  BindToGrid() {
    this.tableoptions = [
      {
        TableHdrData: this.tableHeader,
        TablebodyData: this.tableData,
        pagination: false,
        sortable: true,
        CustomColumn: false,
        PlaceHoldertext: this.captions.Search,
        EnableActions: false,
        SelectRows: false,
        IsCommission: false,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: GridType.revenuepostcode,
        Sortable: 'postCode',
        TableId: GridType.revenuepostcode,
        disableDelete: false,
        customHeader: false,
        pageTitle: 'posttypemapping',
        ServiceId: 'posttypemapping',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: false,
        RadiobuttonOption: true,
        RadioButtonColumnName: this.captions.Select,
        bufferCount: 20
      }
    ];
  }

  radiobuttonClicked(event) {
    console.log("PostTypeMappingCrudComponent -> radiobuttonClicked -> event", event);
    this.selectedData = event;
    this.OriginalSelectedData = event;
    this.isValid = true;
    this.isDisabled = false;
  }

  openDialog(title,data) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '40%',
      disableClose: true,
      data: { headername: title,datarecord: data,buttonType:1},
      hasBackdrop: true,
      panelClass: ''
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result=="YES")
      {
        this.selectedData.isAllCategory=true;
        this.dialogRef.close(this.selectedData);
      }

    });
  }

   savePostTypeToAll()
{
    this.openDialog(this.commonCaptions.Confirmation,"Are you sure that you want to "+(this.applyToAllButtonCaption).toLowerCase()+" ?");
}

  savePostType() {
    this.dialogRef.close(this.selectedData);
  }

  onNoClick() {
    if (this.isValid){
     this.utilities.showAlert(this.commonCaptions.saveChangesMessage, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.dialogRef.close();
        }
       });
    } else {
      this.isValid = false;
      this.dialogRef.close();
    }
  }


  async TriggerSync(){
    this.utilities.ToggleLoader(true, this.captions.PleaseWaitWhileGettingPosttypesFromPMS);
    if(this.isMultiplePmsPropertyEnabled){
       this.SyncMultiPropertyPostTypes();
    }
    else{
      this.SyncSinglePropertyPostTypes();
    }

  }

  async SyncSinglePropertyPostTypes(){
    await this.postTypeBusiness.SyncPosting().then( x=>
      {
        this.tableData = x.result;
      this.tableData.forEach(data => {
        data.postCodeNumber = Number(data.postCode);
        if (data.postCode === this.headerData[this.selectedColumnValue])
        {
          data.radioSelected = true;
        }
      });
      this.BindToGrid();
      this.utilities.ToggleLoader(false);
      }).finally( () =>
        {
          this.utilities.ToggleLoader(false);
      });
  }

  async SyncMultiPropertyPostTypes(){
    await this.postTypeBusiness.SyncMultiPropertyPosting(this.pmsPropCode).then( x=>
      {
        this.tableData = x.result;
      this.tableData.forEach(data => {
        data.postCodeNumber = Number(data.postCode);
        if (data.postCode === this.headerData[this.selectedColumnValue])
        {
          data.radioSelected = true;
        }
      });
      this.BindToGrid();
      this.utilities.ToggleLoader(false);
      }).finally( () =>
        {
          this.utilities.ToggleLoader(false);
      });
  }

  switchNoPosting(event) {
    if (event && event.checked)
    {
      this.tableData.forEach(x => { x.radioSelected = false; x.radioDisabled = true; });
      this.selectedData = { postCode: '—' };

    }
    else
     {
      this.tableData.forEach(x => {
        if ((this.selectedData && x.postCode === this.selectedData.postCode) || x.postCode === this.headerData[this.selectedColumnValue]) {
          x.radioSelected = true;
        } else {
          x.radioSelected = false;
        }
        x.radioDisabled = false;
      });
      this.selectedData = this.OriginalSelectedData;
    }
    this.BindToGrid();
    this.isValid = true;
    this.isDisabled = false;
  }
}
