import { Directive, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({ 
     selector: '[appMenuHightlight]'
})
export class MenuHighLightDirective implements AfterViewInit {
    @Input() OdataKey: string;
    constructor( private elRef: ElementRef,  private route: ActivatedRoute ) { 
    }
    ngAfterViewInit(): void {
        const decParams = JSON.parse(decodeURIComponent(this.route.snapshot.queryParams.data));
        const MasterOdataKey = decParams['master'];
        const tranasctionKey = decParams['transaction'];  
        if ( this.OdataKey === MasterOdataKey || (tranasctionKey !== undefined && tranasctionKey === this.OdataKey)) {
            this.elRef.nativeElement.style.color = '#2A5CF7';
        }
    }
}