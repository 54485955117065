import { BaseReport } from "../common/base-report";
import { ReportUIConfig, ReportAPIOptions, ReportParams, AllReports, StaffFilter, StaffFilterData, StaffReportRequest } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services"; 
import { Product } from '../../../shared/globalsContant';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
export class CommissionReport extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, private productId : number,
        public retailLocalization : RetailLocalization, public patcheableValue) {
        super(reportFormGrp);
    }
    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllStaffs(AllReports.CommissionReport,this.productId),
            dropDownFilterName: this.captions.Users,
            inActiveToggle: true,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false,
            showHelperText : false,
            includeHourlyRate: this.productId == Product.SPA ? true : false,
            hideCorrectedValues: true                       
        };
    }
    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.CommissionReport,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }
    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        // if (this.commonLocalization.isFromJobScheduler)
        // {            

        //     return [{ "pStartDate": toApi(this.startDate) },
        //     { "pEndDate": toApi(this.endDate) },
        //     { "pPageBreak": this.pageBreakToggle }, { "pFilterCaption": this.getFilterCaption },
        //     { "pPropertyName": this.propertyName }, { "pDate": this.printedDate },
        //     { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
        //     { "fromWildCard": this.reportFormGrp.controls.timelineFrom.value},
        //     { "fromIncrement": this.reportFormGrp.controls.timelineCountFrom.value},
        //     { "toWildCard": this.reportFormGrp.controls.timelineTo.value},
        //     { "toIncrement": this.reportFormGrp.controls.timelineCountTo.value},
        //     { "PatchEditParam": true}
        //     ];

        // }

        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle }, { "pFilterCaption": this.getFilterCaption },
        { "pPropertyName": this.propertyName }, { "pDate": this.printedDate },
        { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
        {"pIncludeHourlyRate": this.productId == Product.SPA ? this.reportFormGrp.controls.includeHourlyRate.value : false}];
    }
    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;        
        return [{ "staffType": "User" },{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) },
        { "ProductId": this. productId},
        {"HideCorrectedValues":this.reportFormGrp.controls.hideCorrectedValuesToggle.value}];
    }
    protected formFilters(): StaffReportRequest {
        let selectedLocations: StaffFilter[] = this.selectedFilterOptions;
        let data = selectedLocations.filter(r => r.showInDropDown).map(s => { return { staffId: s.id, staffType: s.staffType } });
       if (this.commonLocalization.isFromJobScheduler) {
        return {
            staffFilter : data,
             fromIncrement:this.reportFormGrp.controls.timelineCountFrom.value,
             fromWildCard: this.reportFormGrp.controls.timelineFrom.value,
             toIncrement:this.reportFormGrp.controls.timelineCountTo.value,
             toWildCard:this.reportFormGrp.controls.timelineTo.value
            };
        }       
        return { staffFilter : data};
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            if (patchItem) {
                this.formGrp.patchValue(patchItem);
                this.commonLocalization.scheduleJobWildCards[1].id
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
            }          
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if (patchItem) {
                if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

                if(patchItem.toWildCard != undefined)
                this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);            

                if(patchItem.fromIncrement != undefined)
                    this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

                if(patchItem.toIncrement != undefined)
                this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
            }           
            
        }, 1);
    }



}
