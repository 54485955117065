
import { GridHeaderConfig, TransactionConfiguration } from '../transaction.interface';
import { HideColumn } from './create-transaction-config.interface';
import _ from 'lodash';

export const NonEditableGridColumn: HideColumn = (
    TransactionCollection: TransactionConfiguration, transactionFrom: string): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig) {
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
            GridHeaderDefination.forEach((header: GridHeaderConfig) => {
                if (header.field === 'SupplierName' || header.field === 'ExchangeRate') {
                    header.editable = false;
                }
            });
            if (transactionFrom === 'processpo') {
                const moreActionButtonIndex = TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig?.
                findIndex(a => ((a.buttonKey === 'null' && a.displayName === 'More Actions') || a.buttonKey === 'assignbids' || 
                  a.buttonKey === 'miscellinouscharges' || a.buttonKey === 'attachedFiles'));
              let assignBidsIndex = -1;
              if (moreActionButtonIndex > -1) {
                assignBidsIndex = TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig[moreActionButtonIndex].
                 moreOption?.optionData?.findIndex(o => o.key === 'assignbids');
                 if (assignBidsIndex > -1) {
                  if (TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig[moreActionButtonIndex].buttonKey === 'assignbids') {
                      TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig[moreActionButtonIndex].buttonKey = 'null';
                      TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig[moreActionButtonIndex].displayName = 'More Actions';
                  }
                  TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig[moreActionButtonIndex].
                    moreOption.optionData.splice(assignBidsIndex, 1);
                  TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig[moreActionButtonIndex].
                    moreOption.optionData = TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig[moreActionButtonIndex].
                    moreOption.optionData;
                 }
              }
            }            
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred NonEditableGridColumn', Error);
    }
};