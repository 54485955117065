import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomField, CustomFieldValue } from '../../../shared/business/view-settings.modals';
import { Host, ButtonType, ButtonOptions, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { BaseResponse } from '../../../shared/business/shared.modals';


@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomFieldComponent implements OnInit {
  FormGrp: UntypedFormGroup;
  dataArray: any = [];
  isFieldTypeList = false;
  selectedIndex: number;
  displayFieldOptionsAvailable = [];
  captions: any;
  isUserAuthorized = true;
  isViewOnly = true;
  isbuttonValid = false;
  floatLabel:string;

  constructor(
    private dialogRef: MatDialogRef<CustomFieldComponent>,
    private Form: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private utils: SpaUtilities,
    private http: HttpServiceCall,
    public localization: SpaLocalization, private vsBusiness: ViewSettingClientBusiness,
    private BP: BreakPointAccess) {
      this.floatLabel = this.localization.setFloatLabel;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingCustomFields]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingCustomFields);
    if (this.isViewOnly) {
      this.utils.disableControls(this.FormGrp);
    }
  }
  checkListValues() {
    if (this.FormGrp.controls.fieldName.value && this.FormGrp.controls.fieldName.value.trim() == "") {
      this.FormGrp.controls.fieldName.setErrors({ invalid: true });
    }
    if (!this.isFieldTypeList) {
      // Valid if the type is not list
      this.FormGrp.controls.displayOptions.setValue(1);
      return true;
    }
    if (this.dataArray && this.dataArray.length > 0) {
      let valid = true;
      for (const element of this.dataArray) {
        if (!element.code || !element.description) {
          valid = false;
          break;
        }
      }
      this.FormGrp.controls.displayOptions.setValue(valid ? this.dataArray.length : null);
    } else {
      this.FormGrp.controls.displayOptions.setValue(null);
    }
  }

  ngOnInit() {
    this.captions = this.localization.captions;
    this.displayFieldOptionsAvailable = [
      { "id": 0, "value": this.captions.setting.Notdisplayed },
      { "id": 1, "value": this.captions.setting.Onclientonly },
      { "id": 2, "value": this.captions.setting.Onappointmentonly },
      { "id": 3, "value": this.captions.setting.Bothonclientandonappointment }
    ];

    this.FormGrp = this.Form.group({
      fieldName: ['', Validators.required],
      requiredOnAppointment: '',
      moveRecordFromClient: '',
      displayOn: ['', Validators.required],
      displayOptions: [null, Validators.required]
    });
    this.FormGrp.patchValue(this.data.datarecord);
    if (["CustomField1", "CustomField2", "CustomField3"].includes(this.data.datarecord.columnName)) {
      this.isFieldTypeList = true;
    }

    this.dataArray = this.data.datarecord.customFieldValues;
    
    if (!(this.dataArray && this.dataArray.length > 0)) {
      if (this.isFieldTypeList) {
        this.dataArray = [{ 'code': '', description: '' }];
      } else {
        this.dataArray = [];
      }
    }
    
    this.checkListValues();
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.ValidateBreakPoint();
  }


  getFieldValues(): CustomFieldValue[] {
    if (this.dataArray && this.dataArray.length > 0) {
      const values: CustomFieldValue[] = [];
      for (const element of this.dataArray) {
        values.push({
          Code: element.code,
          description: element.description,
          IsUtilized: element.isUtilized ? element.isUtilized : false,
          CustomFieldId: this.data.datarecord.id,
          id: element.id ? element.id : 0
        });
      }
      return values;
    }
    return [];
  }

  SaveCustomField() {
    const customField: CustomField = {
      columnName: this.data.datarecord.columnName,
      FieldName: this.FormGrp.controls.fieldName.value,
      DisplayOn: this.FormGrp.controls.displayOn.value,
      moveRecordFromClient: this.FormGrp.controls.moveRecordFromClient.value,
      RequiredOnAppointment: this.FormGrp.controls.requiredOnAppointment.value,
      customFieldValues: this.getFieldValues(),
      Id: this.data.datarecord.id
    }

    this.http.CallApiWithCallback<any[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "UpdateCustomeField",
      uriParams: { id: this.data.datarecord.id },
      method: HttpMethod.Put,
      body: customField,
      showError: true,
      extraParams: []
    });

  }

  setValueInArray($event: any, index: any, string: any) {
    this.FormGrp.markAsDirty();
    if (string == "code") {
      this.dataArray[index].code = $event.target.value;
    } else {
      this.dataArray[index].description = $event.target.value;
    }
    this.checkListValues();
  }

  Cancel() {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  createCodeAndDescription() {
    this.dataArray.push({ code: '', description: '' });
    this.checkListValues();
    this.FormGrp.markAsDirty();
  }

  removeCodeAndDescription(index: any) {
    this.selectedIndex = index;
    this.http.CallApiWithCallback<any[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "IsCustomFieldUsed",
      uriParams: { id: this.dataArray[index].id },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == ButtonOptions.Yes.toLowerCase()) {
      if (this.dataArray[this.selectedIndex]) {
        this.dataArray.splice(this.selectedIndex, 1);
      }
      this.checkListValues();
      this.FormGrp.markAsDirty();
    } else {
      extraParams.close();
    }
  }

  IsFormValid() {
    this.checkListValues();
    return this.FormGrp.valid && this.FormGrp.dirty;
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "UpdateCustomeField") {
      this.dialogRef.close();
    }
    if (callDesc == "IsCustomFieldUsed") {
      const customFieldFlag = <any>result.result;
      if (customFieldFlag) {
        this.utils.ShowErrorMessage(this.captions.setting.CustomFieldDeleteTitle, this.captions.setting.CustomFieldDelete, ButtonType.YesNo, this.PopupCallback.bind(this), null, false);
      } else {
        if (this.dataArray[this.selectedIndex]) {
          this.dataArray.splice(this.selectedIndex, 1);
        }
        this.checkListValues();
        this.FormGrp.markAsDirty();
      }
    }
  }
}

