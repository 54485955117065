import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { ReportKeyword, TemplateData, TemplateDomainData,Events } from './crud-report-templates.model';
import { CrudReportTemplatesBusiness } from './crud-report-template.business';
import * as DecoupledEditor from '../../../ckeditor5-build-decoupled-document-master/build/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

import { cloneDeep } from 'lodash'
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { EventDataServices } from 'src/app/common/dataservices/notification/event.data.service';
import { TemplateServiceCommunication } from '../../templates.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/Models/common.models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Product } from 'src/app/common/enums/shared-enums';
import { SorTypeEnum } from 'src/app/common/shared/shared/enums/enums';
import { DialogOverviewExampleDialog } from 'src/app/common/shared/shared/dialog-popup/dialogPopup-componenet';
import { MatDialog } from '@angular/material/dialog';
import { HtmlPopupComponent } from 'src/app/common/shared/shared/html-popup/html-popup.component';
import juice from 'juice';


@Component({
  selector: 'app-crud-report-template',
  templateUrl: './crud-report-template.component.html',
  styleUrls: ['./crud-report-template.component.scss'],
  providers: [CrudReportTemplatesBusiness, TemplateDataServices, EventDataServices, TemplateServiceCommunication


  ],
  encapsulation: ViewEncapsulation.None
})
export class CrudReportTemplateComponent implements OnInit {

  @Input() editableObj;
  @Output() emittedReportdata: EventEmitter<any> = new EventEmitter();
  @Input() isViewOnly;
  @Input() showTableRecord;
  @Input() isCopyInput;
  isShowCopy: boolean = false;
  isGroupingenabled = true;
  ordertype = SorTypeEnum.asc;
  groupBy = 'groupId';

  HeaderLabel: string;
  saveUpdLabel: string;
  productId: number;
  searchText: string = '';
  Entire_keywords: ReportKeyword[];
  filteredkeywords: ReportKeyword[];
  editableData: TemplateDomainData;
  templatedomaindata: TemplateDomainData;
  isTemplateInUse: boolean = false;
  EnablesaveUpdBtn: boolean = false;
  CrudReportTempForm: UntypedFormGroup;
  crudereporttemplateCaptions: any;
  events: Events[];
  templateCodeMaxlength: number = 12;
  pmsTemplateCodeMaxlength: number = 15;
  message;
  myContent : any;
  captions :any;
  stylevalue:string='<style>\n.text-tiny {font-size: .7em;}\n.text-small {font-size: .85em;}\n.text-big {font-size: 1.4em;}\n.text-huge {font-size: 1.8em;}\n.center\n{display: block;\nmargin-left: auto;\nmargin-right: auto;\nwidth: 50%;}\nimg\n{max-width:100%;}\n</style>\n';

  Editor = DecoupledEditor;
  Config = {
    toolbar: [
      'bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize',
      'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList',
      'bulletedList', 'todoList', 'outdent', 'indent', 'link', 'insertTable', 'undo', 'redo','htmlEmbed',
      'highlight', 'horizontalLine','imageUpload'
    ],
    fontColor: {
      colors: this.commonUtils.getColors()
    },
    fontBackgroundColor: {
      colors: this.commonUtils.getColors()
    },
    table: {
      tableProperties: {
        borderColors: this.commonUtils.getColors(),
        backgroundColors: this.commonUtils.getColors()
      },
      tableCellProperties: {
        borderColors:this.commonUtils.getColors(),
        backgroundColors: this.commonUtils.getColors()
      }
    },
    fontSize: {
      options: this.commonUtils.getFontSize(),
      supportAllValues: true
    },
    extraPlugins: [],//Base64UploaderPlugin
    placeholder: 'Type here',
    toolbarLocation: ['bottom'],
  };
  @ViewChild('ComposeReportTemp') ComposeReportTemp: CKEditorComponent;
  currentEditor: any
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  isDefaultEventObj: any;
  floatLabel: string;
  floatLabelNever: string;
  constructor(private fb: UntypedFormBuilder, public localization: Localization, private _CrudReportTemplatesBusiness: CrudReportTemplatesBusiness,
    private templateServiceCommunication: TemplateServiceCommunication, private commonUtils: CommonUtilities,public dialog: MatDialog) {
    this.crudereporttemplateCaptions = this.localization.captions.settings.utilities;
    this.Config.placeholder = this.crudereporttemplateCaptions.typehere;
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  async ngOnInit() {
    this.templateServiceCommunication.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';

        }
        else {
          loadingContainer.style.display = 'none';

        }
      }
    });
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.templateCodeMaxlength = this.productId == Product.PMS ? this.pmsTemplateCodeMaxlength : this.templateCodeMaxlength;
    this.templateServiceCommunication.loaderEnable.next(true);
    this.generateReportFrom();
    this.isShowCopy = this.isCopyInput?.isCopy;
    await this.GetEvents();
    if (this.editableObj) {
      this.HeaderLabel = !this.isShowCopy ? this.crudereporttemplateCaptions.editreportTemplate : this.crudereporttemplateCaptions.newReportTemplate;
      this.saveUpdLabel = !this.isShowCopy ? this.crudereporttemplateCaptions.update : this.crudereporttemplateCaptions.save;
      await this.GetTemplateData(this.editableObj.templateId);
    }
    else {
      this.HeaderLabel = this.crudereporttemplateCaptions.newReportTemplate;
      this.saveUpdLabel = this.crudereporttemplateCaptions.save;
      await this.GetMasterData();
    }

    if (this.isShowCopy) {
      this.message = this.localization.replacePlaceholders(this.crudereporttemplateCaptions.lbl_templatecopyAlertMessage, ['className'],
        [this.isCopyInput.eventName]);
    }
    this.disableEvents();
    this.CrudReportTempForm.statusChanges.subscribe(x => {
      this.EnablesaveUpdBtn = this.CrudReportTempForm.dirty && this.CrudReportTempForm.valid;
    });
    if(this.isViewOnly){
      this.CrudReportTempForm.disable();
    }
  }

  private async GetEvents() {
    this.events = await this._CrudReportTemplatesBusiness.GetAllEvents();
    this.events.forEach(res => res.disabled = false);
  }

  async GetMasterData() {
    let listOrder = await this._CrudReportTemplatesBusiness.getMaxListOrder();
    if (this.events && this.events.length > 0) {
      let eventarray: number[] = [];
      eventarray.push(this.events[0].id);
      await this.getTemplateKeywords(eventarray);
      this.CrudReportTempForm.controls.eventId.setValue(eventarray);
      this.CrudReportTempForm.controls.listOrder.setValue(listOrder + 1);


    }
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  async GetTemplateData(id: number) {
    this.editableData = await this._CrudReportTemplatesBusiness.getTemplateDetailsById(id);
    await this.getTemplateKeywords(this.editableData.eventIds);
    await this.patchValues();
    this.templateServiceCommunication.loaderEnable.next(false);
  }


  generateReportFrom() {
    this.CrudReportTempForm = this.fb.group({
      eventId: ['', Validators.required],
      templateCode: ['', Validators.required],
      templateName: ['', Validators.required],
      listOrder: ['', Validators.required],
      htmlContent: '',
      isDefault: false,
      templateId: 0,   
      building: ''
    });

  }
  async patchValues() {
    let templatedata = this.editableData.templateData;
    let data = {
      eventId: this.editableData.eventIds,
      templateCode: templatedata.templateCode,
      templateName: templatedata.templateName,
      listOrder: templatedata.listOrder,
      htmlContent: templatedata.htmlContent, 
      isDefault: templatedata.isDefault,
      templateId: templatedata.templateId      
    }
    if (this.isShowCopy) {
      let nextlistOrder = await this._CrudReportTemplatesBusiness.getMaxListOrder();
      data.listOrder = nextlistOrder + 1;
      data.templateCode = '';   
    }
    this.CrudReportTempForm.patchValue(data);
  }

  async showConfirmationSuccessCallback(res) {
    await this.SaveTemplate();
  }

  async showConfirmationErrorCallback(res) {
    this.CrudReportTempForm.value['isDefault'] = false;
    await this.SaveTemplate();
  }

  async SaveTemplate() {

    try {
      this.templateServiceCommunication.loaderEnable.next(true);
      this.EnablesaveUpdBtn = false;
      this.templatedomaindata = undefined;
      this.UpdateTemplateDataObject(this.CrudReportTempForm.value);
      this.CrudReportTempForm.markAsPristine();
      if (this.templatedomaindata) {
        let id = await this._CrudReportTemplatesBusiness.CreateorUpdateTemplate(this.templatedomaindata);
        this.GetTemplateData(id);
      }
      this.templateServiceCommunication.loaderEnable.next(false);
      this.promptForUnSavedChanges();
    } catch (err) {
      this.templateServiceCommunication.loaderEnable.next(false);
    }


  }

  async getTemplateKeywords(selecAct: number[]) {
    this.Entire_keywords = cloneDeep(await this._CrudReportTemplatesBusiness.getReportKeywords());
    this.filteredkeywords = this.Entire_keywords;
  }
  promptForUnSavedChanges() {
    if (this.showTableRecord) {
      if (this.CrudReportTempForm.dirty) {
        this.commonUtils.showCommonAlert(this.crudereporttemplateCaptions.warn_datalost, AlertType.Warning, ButtonTypes.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this.emittedReportdata.emit({ closeCrudWindow: true });
          }
          else {
            return;
          }
        });
      }
      else {
        this.emittedReportdata.emit({ closeCrudWindow: true });
      }
    }
    else {
      this.emittedReportdata.emit({ closeCrudWindow: true });
    }
  }
  async save() {
    const cleanData = this.commonUtils.sanitizeHTMLTemplate(this.CrudReportTempForm.get('htmlContent')?.value);
    this.CrudReportTempForm.get('htmlContent').setValue(cleanData);
    let count=this.countOccurences(this.CrudReportTempForm.value['htmlContent'],'{{{ReportData}}}');
    if(count==0)
    {
      this.commonUtils.showCommonAlert(this.crudereporttemplateCaptions.reportTagMissing, AlertType.Warning, ButtonTypes.Ok);
    }
    else if(count>1)
    {
      this.commonUtils.showCommonAlert(this.crudereporttemplateCaptions.removeReportTag, AlertType.Warning, ButtonTypes.Ok);
    }
    else
    {     
    let isDefault = this.CrudReportTempForm.value['isDefault'];
    if (isDefault) {
      this.templateServiceCommunication.loaderEnable.next(true);
      let result = await this._CrudReportTemplatesBusiness.DefaultTemplateExists(this.CrudReportTempForm.value['eventId'], this.CrudReportTempForm.value['templateId']);
      if (result) {
        this.templateServiceCommunication.loaderEnable.next(false);
        this.templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this), this.showConfirmationErrorCallback.bind(this));
      } else {
        this.templateServiceCommunication.loaderEnable.next(false);
        await this.SaveTemplate();
      }
    }
    else {
      await this.SaveTemplate();
    }
  }
  }
  UpdateTemplateDataObject(formData: UntypedFormGroup) {
    this.AddTemplateDataIfDirty(formData);
  }

  countOccurences(string, word) {
    return string.split(word).length - 1;
 }

  AddTemplateDataIfDirty(formData: UntypedFormGroup) {
    if (this.CrudReportTempForm.dirty) {
      const templateCode = formData['templateCode'];
      const templateNameValue = formData['templateName'];
      const templateContentValue = formData['htmlContent'];
      const ImageData = this.getImageUrlFromHtml(templateContentValue, templateCode + '_');  
      let modifiedHTMLData = juice.inlineContent(this.commonUtils.getCkeditorImgWidth(ImageData.replacedImageSrcURL),this.commonUtils.getCssStringForCkEditor());    
      const templateData: TemplateData = {
        templateName: templateNameValue,
        templateCode: templateCode,        
        isDefault: formData['isDefault'],        
        htmlContent: modifiedHTMLData,
        templateId: formData['templateId'],       
        listOrder: formData['listOrder']     
      };
      let domainData: TemplateDomainData = {
        eventIds: formData['eventId'],
        templateData: templateData
      }
      this.templatedomaindata = domainData;
    }

  }



  getImageUrlFromHtml(htmlData, imageNamePrefix) {
    const parser = new DOMParser();
    const document = parser.parseFromString(htmlData, 'text/html');
    const imageData = document.getElementsByTagName('img'); 
    for (let i = 0; i < imageData.length; i++) {
      const imageBase64Data = this.getBase64Data(imageData[i].src);
    }    
    const replacedImageSrcURL = document.getElementsByTagName('body')[0].innerHTML;
    return { replacedImageSrcURL };
  }

  getBase64Data(url: string) {
    return url.split(',')[1];
  }

  getImageType(url: string) {
    return url.substring(
      url.lastIndexOf(':') + 1,
      url.lastIndexOf(';')
    );
  }
  enteredSrchText() {
    let val = this.searchText.toLowerCase();
    this.filteredkeywords = this.Entire_keywords.filter(x => {
      return (x.name.toLowerCase().indexOf(val) != -1);
    })
  }
  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    this.currentEditor = this.ComposeReportTemp;
  }
  elementClick(arg) {
    const appendData = '{{{' + arg + '}}}';
    const selection = this.currentEditor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    this.currentEditor.editorInstance.model.change(writer => {
      writer.insert(appendData, range.start);
    });
  }

  onChange(currentEditorInstance) {
    this.currentEditor = currentEditorInstance;
  }
  actionChanged(eve) {
    let eventSelec = eve.value;
    this.disableEvents();
    this.getTemplateKeywords(eventSelec);
  }

  disableEvents() {   
    this.events.forEach(res => {     
        if (this.CrudReportTempForm.controls.eventId.value.length > 0) {         
            res.disabled = false;         
        }      
    });
  }

  openClose(e, obj) {
    this.filteredkeywords.forEach((x, i) => {
      if (obj.groupId == x.groupId) {
        x.isExpanded = !x.isExpanded
      }
    })
  }

  enteredgrpSrchText() {
    let searchValue = this.searchText.toLowerCase();
    let tempArray = [];
    this.Entire_keywords.forEach(searchKey => {
      const result = searchKey.name.toLowerCase().indexOf(searchValue) > -1
      if (result) {
        if (searchValue == "") {
          searchKey.isExpanded = false;
        } else {
          searchKey.isExpanded = true;
        }
        tempArray.push(searchKey);
      }
    })
    this.filteredkeywords = tempArray;
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    if (!this.showTableRecord) {
      this.promptForUnSavedChanges();
    }
  }
  showHtmlContent(){
    const cleanData = this.commonUtils.sanitizeHTMLTemplate(this.CrudReportTempForm.get('htmlContent')?.value);
    this.CrudReportTempForm.get('htmlContent').setValue(cleanData);
    let modifyHTML = juice.inlineContent(this.commonUtils.getCkeditorImgWidth(this.stylevalue + this.CrudReportTempForm.get('htmlContent')?.value),this.commonUtils.getCssStringForCkEditor())
    const dialogRef = this.dialog.open(HtmlPopupComponent, {
      height: '90%',
      width: '90%',
      data: { headername: this.crudereporttemplateCaptions.composed, datarecord: modifyHTML, Type:"HTML" },
      panelClass: 'ag_dialog--lg',
      disableClose: true,
      hasBackdrop: true
  });
  }

}
