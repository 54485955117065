import * as constant from '../constant/GlobalConstants';
import moment from 'moment';

export class CellFormaters {
    formatedValue: any;
    gridColumn: any;
   constructor(gridcolumn: any) {
      this.gridColumn = gridcolumn;
   }
  /**
   * @method defineFormaterType()
   * @params None
   * @description define the cell formater type for each column
   */
  defineFormaterType() {
     try {
        this.formatedValue = '';
        const columnType = this.gridColumn['colDef']['type'];
        const rowValue =   this.gridColumn['colDef']['value'];
        // console.log(columnType);
        // console.log(this.gridColumn);
        switch (columnType) {
            case constant.ColumnFieldType.DATECOLUMN:
                this.formatedValue = this.dateFormater(rowValue);
              break;
            case constant.ColumnFieldType.NUMERIC:
                this.formatedValue = rowValue;
              break;
              case constant.ColumnFieldType.NONEDITABLE:
                this.formatedValue = rowValue;
              break;
            default:
                this.formatedValue = rowValue;
          }
          return this.formatedValue;
     } catch (error) {
         console.log('error occured defineformateType', error);
     }
  }
  /**
   * @method dateFormater(rowvalue);
   * @input params row Value
   * @convert the date format
   */
  dateFormater(cellValue: string) {
    try {
      return moment('20111031', 'YYYYMMDD').fromNow();
    } catch (error) {
        console.log('error occoured dateFormater', error);
    }
  }
   init() {
      this.defineFormaterType();
   }
}