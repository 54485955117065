import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Device, ClientCreditCardInfo, CardInfo, HandleRequest, HandleResponse, StoreTokenRequest, PaymentBaseResponse, SaleRequest, SaleResponse, HttpResponseStatus, ValidatePayResponse } from '../../shared/service/payment/payment-model';
import { IDTechHandle, IDTech, PaymentMethods, TokentransactionInfo } from 'src/app/retail/shared/business/shared.modals';
import { UserBreakPoint, UserSessionConfiguration } from 'src/app/retail/retail.modals';
import { CardEntryModeDialogAction, CardEntryModeDialogResult, CardInputMethod, ValidateAuthRequest, ValidateAuthResponse,IFrameURL } from 'src/app/retail/shared/service/payment/payment-business.model';
import { ReplaySubject } from 'rxjs';
import { GatewayConfigurationType, RetailBreakPoint } from 'src/app/retail/shared/globalsContant';
import { takeUntil } from 'rxjs/operators';
import { CardEntryModeComponent } from 'src/app/retail/shared/card-entry-mode/card-entry-mode.component';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../utilities/retail-utilities';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PayAgentService } from '../service/payagent.service';
import { UserMachineConfigurationService } from '../../common/services/user-machine-configuration.service';
import { CreditCardConfigurationService } from '../../sytem-config/credit-cards/credit-card.service';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { BreakPointAccess } from '../service/breakpoint.service';
import { NonPMAgentRequestHandler } from '../../payment/NonPMAgentRequestHandler';
import { RetailFeatureFlagInformationService, SearchTypes } from '../service/retail.feature.flag.information.service';
import { CommonVariablesService } from '../service/common-variables.service';
import { REQUEST_TIMEOUT, RequestTimedOutException } from '../business/Giftcard/rGuestPay-base.service';
import { NonIntegratedCreditCardService } from 'src/app/retail/sytem-config/non-integrated-credit-card/non-integrated-credit-card.service';
import { CelopayPaymentDetailComponent } from '../celopay-payment-detail/celopay-payment-detail.component';
import { NonIntegratedCreditCardConfiguration } from 'src/app/retail/shared/service/payment/payment-model';

@Component({
  selector: 'app-retail-capture-card',
  templateUrl: './capture-card.component.html',
  styleUrls: ['./capture-card.component.scss'],
  providers: [CreditCardConfigurationService]
})
export class RetailCaptureCardComponent implements OnInit {
  @Input() PaymentReferenceID: number;
  @Input() disableCardCapture = false;
  @Input() GatewayConfiguration;
  @Input() folioSettings = null;
  @Input() authInput;
  @Input() CEDS: string = "";
  @Input() displayHeader = true;
  @Input() isCardRemovable = true;
  @Input() isShowPMAgentValidationMessage = true;
  @Input() cardRequired = false;
  @Input() IsResortFinanceFolioAuthFlow = false;
  @Input() IsExpireDateVisible = true;
  @Input() ShowConfirmationForRemoval = true;
  @Input() automation;
  @Input() hideCardDetail: boolean = false;
  @Input() showCardRemovableIcon: boolean = true;
  @Input() showDeviceCapture: boolean = true;
  @Input() showIframeDetails: boolean = false;
  @Input() showNonIntegratedCreditCard: boolean = true;
  @Input() paymentMethodId: number;
  availableDevices: Device[] = [];
  outletId: number = 0;
  @Input('outletId') set setoutletId(value){
    if(value){      
      this.outletId = value;
      this.availableDevices = [];
      this.deviceListFetchInitiated = true;
    }
  }
  selectedDevice: Device = {
    name: '',
    handle: ''
  };
  cardConnectDialog: MatDialogRef<any, any>;
  IDTechCardSwipePopupClosed: boolean;
  EncryptedCardData: any;
  cardInfo: ClientCreditCardInfo[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  displayCardInfo: CardInfo;
  CurrentDevice: string;
  @Output() NewPaymentReferenceID = new EventEmitter<number>();
  @Output() AuthTransactionID = new EventEmitter<ValidatePayResponse>();
  @Output() CardInfo = new EventEmitter<any>();
  @Output() removeDetails = new EventEmitter<any>();
  captions: any;
  userSessionConfiguration: UserSessionConfiguration;
  UseRetailInterface = false;
  productId: number;
  authorizedUserId;
  defaultCardInputMethod: CardInputMethod;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedDeviceHandle: string;
  hostName: string;
  agentVersion: string;
  ipAddress: string;
  allowCOFRemoval = false;
  IframeUrl:string;
  isNonIntegratedCreditCard : boolean;
  config: NonIntegratedCreditCardConfiguration;

  get DefaultOutletId() {
    if(this.outletId > 0){
      return this.outletId;
    }
    return (this.userSessionConfiguration && this.userSessionConfiguration.defaultOutletId > 0) ? this.userSessionConfiguration.defaultOutletId : 0;
  }
  preferredInputSelectedBanner: string = "";
  get showPreferredCardEntryBanner() {
    return (this.GatewayConfiguration
      && this.GatewayConfiguration.cardInputMethod == CardInputMethod.All
      && this.GatewayConfiguration.preferredCardEntryMode > 0
      && this.selectedDevice && this.selectedDevice.name);
  }
  deviceListFetchInitiated = false;
  floatLabel: string;

  constructor(
    private _payAgentService: PayAgentService
    , private _nonPMAgentService: NonPMAgentRequestHandler
    , private _utils: RetailUtilities
    , private _localization: RetailLocalization
    , private _propertyInfo: RetailPropertyInformation
    , public dialog: MatDialog
    , public userMachineConfigurationService: UserMachineConfigurationService
    , private creditCardConfigurationService: CreditCardConfigurationService
    , public commonUtils: QuickLoginUtilities
    , public BPoint: BreakPointAccess
    , private _featureFlag: RetailFeatureFlagInformationService
    , public _ss: CommonVariablesService
    , private nonIntegratedCreditCardService: NonIntegratedCreditCardService
  ) {
    this.floatLabel = this._localization.setFloatLabel;
  }

  async ngOnInit() {
    this.captions = this._localization.captions.shop;
    this.GetUserDefaultDeviceConfiguration();
    this.UseRetailInterface = this._propertyInfo.UseRetailInterface;
    if (!this.GatewayConfiguration) {
      this.GetCreditCardConfiguration();
    }
    this.SetPreferredCardLabel();
    this.validateRemoveCOFUserAccess();
    this.config = await this.nonIntegratedCreditCardService.getNonIntegratedCreditCardConfiguration();
    this.isNonIntegratedCreditCard = this.config.activateInterface;
    if (this.isNonIntegratedCreditCard) {
      this.IframeUrl = await this.nonIntegratedCreditCardService.getIFrameUrl();
    }
    if(!this.GatewayConfiguration.isInterfaceActive){
      this.showDeviceCapture=false;
      this.showIframeDetails =this.isNonIntegratedCreditCard;
    }
  }

  SetPreferredCardLabel() {
    if (this.GatewayConfiguration && this.GatewayConfiguration.preferredCardEntryMode) {
      const preferredInputMap = {
        [CardInputMethod.KeyIn]: this._localization.captions.setting.keyIn,
        [CardInputMethod.SwipeInsertTap]: this._localization.captions.setting.swipeInsertTap
      }
      this.preferredInputSelectedBanner = this._localization.replacePlaceholders(
        this.captions?.preferredInputModeSelectedBanner ?? '', ['preferredMethod'],
        [preferredInputMap[this.GatewayConfiguration.preferredCardEntryMode]]
      );
    }
  }

  async ngOnChanges() {
    if (this.PaymentReferenceID > 0) {
      await this.getCardInfo(this.PaymentReferenceID);
    } else {
      this.displayCardInfo = null;
      this.CardInfo.emit(null);
      this.SelectDevice({
        name: '',
        handle: ''
      });
      this.CurrentDevice = '';
    }
    if (this.CEDS && !this.deviceListFetchInitiated) { this.GetDevices(false, true); }
  }

  @HostListener('window:message', ['$event'])
  async onMessage({ origin, data }) {
    var nonIntegratedGatewayConfig = JSON.parse(this.config.gatewayValue);
    if (!nonIntegratedGatewayConfig.find(x => x.Key.toLowerCase().trim() === IFrameURL.toLowerCase()).Value === origin) return

    if (data) {
      try {
        if (data.accessUrl) {
          let cardTransactionInfo = await this.nonIntegratedCreditCardService.StoreAndGetSwippedCardInfo(data);
          const openDialogs = this.dialog.openDialogs;
          if (openDialogs.length > 0) {
            // Close the celopay
            openDialogs[openDialogs.length - 1].close();
          }
          if (cardTransactionInfo) {
            this.displayCardInfo = cardTransactionInfo[0];
            this.NewPaymentReferenceID.emit(cardTransactionInfo[1]);
          }
        } else {
          // other window messages can be handled here
          let parsedData;
          if (typeof data === 'string') {
            parsedData = JSON.parse(data);
          } else {
            parsedData = data;
          }
          if (parsedData?.reason && parsedData?.message) {
            let errMsg = `${parsedData?.reason}.${parsedData?.message}`;
            this._utils.ShowErrorMessage(this._localization.captions.common.Error, errMsg);
          }  
        }
      } catch (e) {
        console.log(e);
      }

    }
  }

  async GetDevices(isFromIdTech: boolean = false, isOnLoadCall: boolean = false, isShowPMAgentValidationMessage: boolean = true) {
    const isUserEnforcedDeviceFetch = (this.availableDevices.length == 1 && this.availableDevices[0].name == IDTechHandle.name && !isOnLoadCall);
    if (this.availableDevices.length == 0 || isFromIdTech || isUserEnforcedDeviceFetch) {
      let RequestBody: HandleRequest;
      if (this.selectedDevice.name == IDTechHandle.name) {
        RequestBody = {
          tenderId: PaymentMethods.IDTECH.toString(),
          inquiryInfo: {
            cardData: {
              encryptedData: JSON.stringify({
                deviceType: 'idtechkb',
                encryptedData: this.EncryptedCardData
              })
            }
          }
        };
      } else {
        RequestBody = {
          tenderId: PaymentMethods.CreditCard.toString()
        };
      }

      if ((this.userSessionConfiguration.isIdtechSred
        || this.userSessionConfiguration.defaultPaymentDevice == IDTech.id)
        && !this.availableDevices.includes(IDTechHandle)) {
        this.availableDevices.push(IDTechHandle);
        this.SelectDefaultDevice();
        if (this.userSessionConfiguration.defaultPaymentDevice == IDTech.id && !this.EncryptedCardData)
          return;
      }
      this.deviceListFetchInitiated = true;
      if (!isOnLoadCall) this._utils.ToggleLoaderWithMessage(true, this.captions.RetrieveDeviceList);
      const Handles: Promise<HandleResponse> = this._ss.PaymentProcessor.GetHandles(RequestBody, this.DefaultOutletId, this.CEDS, isShowPMAgentValidationMessage);
      Handles.then(response => {
        this._utils.ToggleLoader(false);
        if (response && response.status.toLocaleLowerCase() === 'success' && response.paymentHandle.length > 0) {
          this.hostName = response.hostName;
          this.agentVersion = response.agentVersion;
          this.ipAddress = response.ipAddress;
          this.selectedDeviceHandle = response?.paymentHandle[0]?.handle;
          if (isFromIdTech && this.selectedDevice.name == IDTechHandle.name && this.EncryptedCardData && this.authInput?.IsFolioPosting && this.isValidAuthRequest) {
            this.RequestForAuth(this.authInput.authAmount);
          }
          else if (isFromIdTech && this.selectedDevice.name == IDTechHandle.name && this.EncryptedCardData) {
            this.CreateToken(response.paymentHandle[0].handle, PaymentMethods.IDTECH);
          } else {
            const availableIngenicoDevices = response.paymentHandle;
            availableIngenicoDevices.forEach(device => {
              if (device.name && !this.availableDevices.some(x => x.name == device.name)) {
                this.availableDevices.push(device);
              }
            });
          }
        }
        if (!isFromIdTech) {
          this.SelectDefaultDevice();
        }
        if (this.availableDevices.length <= 0 && !isOnLoadCall) {
          this._utils.ShowError(this._localization.captions.common.Error, response.errorMessage);
        }
      }).catch(err => {
        this._utils.ToggleLoader(false);
        if (!isOnLoadCall) {
          // Show Payment Error Prompt          
          if (err.error[0] != null && err.error[0].Code != null && err.error[0].Code != '')
            this._payAgentService.PaymentErrorPrompt(err.error[0].Code);
          else if (err.error[0] != null && err.error[0].Message == this._payAgentService.INVALID_TENDER_MSG) {
            return this._utils.ShowErrorMessage(
              this._localization.captions.common.Error,
              this._localization.captions.shop.PayAgentConfigError
            );
          }
          else if (err.error[0] != null && err.error[0].Message) {
            return this._utils.ShowErrorMessage(
              this._localization.captions.common.Error,
              err.error[0].Message
            );
          }
          else {
            return this._utils.ShowErrorMessage(
              this._localization.captions.common.Error,
              this._localization.captions.shop.PayAgentUnavailable
            );
          }
        }
      });
    }
  }

  SelectDefaultDevice() {
    if (this.userSessionConfiguration.defaultPaymentDevice?.toLowerCase() == IDTech.id) {
      this.SelectDevice(IDTechHandle);
    } else {
      const defaultDevice = this.availableDevices.find(x => x.name == this.userSessionConfiguration.defaultDeviceName);
      if (defaultDevice) {
        this.SelectDevice(defaultDevice);
      }
    }
  }

  async GetUserDefaultDeviceConfiguration() {
    this.userSessionConfiguration = await this.userMachineConfigurationService.getUserSessionConfiguration(Number(this._localization.GetPropertyInfo('UserId')));
    this.GetDevices(false, true, this.isShowPMAgentValidationMessage);
  }

  async GetCreditCardConfiguration() {
    const result = await this.creditCardConfigurationService.getCreditCardConfiguration({ type: GatewayConfigurationType.CreditCard });
    this.GatewayConfiguration = result.find(t => t.type == GatewayConfigurationType.CreditCard);
    this.SetPreferredCardLabel();
  }

  SelectDevice(deviceObj: Device) {
    console.log(deviceObj);
    this.selectedDevice = deviceObj;
    this.CurrentDevice = deviceObj.name;
  }

  formatCreditCardExpiryDate(date: string): string {
    return this._payAgentService.formatCreditCardExpiryDate(date);
  }

  async ConnectDevice() {
    if (this.isAdyenWithAllEntryMode()) {
      this.proceedForAdyenGatewayPayment();
    } else {
      this.proceedToCaptureCard();
    }
  }

  async getCardInfo(tokenRefId: number) {
    let cardInfo : CardInfo;
    if(this.IsResortFinanceFolioAuthFlow){
      cardInfo = await this._payAgentService.FolioGetCardInfo(tokenRefId);
    }
    else{
      cardInfo = await this._payAgentService.GetCardInfo(tokenRefId);
    }

    if (cardInfo) {
      cardInfo.cardNumber = this._payAgentService.MaskCreditCardNumber(cardInfo.cardNumber);
      cardInfo.cardExpiration = this.formatCreditCardExpiryDate(cardInfo.cardExpiration);
      this.displayCardInfo = cardInfo;
      this.CardInfo.emit(cardInfo);
    }
  }

  CaptureCardWithIDTechDevice() {
    this.IDTechCardSwipePopupClosed = false;
    this.OpenDialog();
    setTimeout(() => {
      if (!this.EncryptedCardData && !this.IDTechCardSwipePopupClosed) {
        this.cardConnectDialog.close();
        this._utils.showError(this._localization.getError(10725));
      }
    }, 45000);
  }

  OpenDialog() {
    const popupMessage = this.isManualKeyInCardEntryMode() ?
      this._localization.captions.shop.KeyInCardCaptureMessage : this._localization.captions.shop.SwipeCardCaptureMessage;
    const isIDTech: boolean = (this.selectedDevice.name == IDTechHandle.name);
    const dataObj = {
      text: popupMessage,
      buttonname: isIDTech ? this._localization.captions.common.Close : '',
      headertext: '',
      isloaderenable: true,
      cardpayment: true,
      isHiddenFieldRequired: isIDTech,
      isManualEntry: this.isManualKeyInCardEntryMode()
    };
    this.cardConnectDialog = this._utils.OpenCardSwipeDialog(dataObj, this.CloseDialog.bind(this));    
  }

  CloseDialog(data: any): void {
    this.IDTechCardSwipePopupClosed = true;
    if (data && data.toLowerCase() != this._localization.captions.common.Close.toLowerCase()
      && this.selectedDevice.name == IDTechHandle.name) {
      this.EncryptedCardData = data;
      this.GetDevices(true);
    }
  }

  CreateToken(handle, tenderID) {
    // Lights up the connected Device and asks for the card swipe
    const tokentransactionInfo: Promise<TokentransactionInfo> = this._ss.PaymentProcessor.CreateToken(
      handle
      , tenderID
      , this.DefaultOutletId
      , this.isManualKeyInCardEntryMode()
      , this.isPartialPayAllowed()
      , this.CEDS);
    tokentransactionInfo.then(async (response) => {
      if (response.status.toLocaleLowerCase() === 'success') {
        response.hostName = response.hostName ?? this.hostName;
        response.agentVersion = response.agentVersion ?? this.agentVersion;
        response.ipAddress = response.ipAddress ?? this.ipAddress;
        const cardTransactionInfo = await this.StoreAndGetSwippedCardInfo(response, tenderID);
        if (cardTransactionInfo) {
          this.displayCardInfo = cardTransactionInfo[0];
          this.NewPaymentReferenceID.emit(cardTransactionInfo[1]);
        }
      }
    }).catch((err) => {
      this.cardConnectDialog.close();
      let errorfromRes: string = err?.error?.result?.errorMessage || err?.error?.message || err?.error?.ErrorMessage || err?.message;
      if (err.error[0] != null && err.error[0].Code != null) {
        this._payAgentService.PaymentErrorPrompt(err.error[0].Code);
      } else if (errorfromRes) {
        const errMsg = RequestTimedOutException == errorfromRes ? REQUEST_TIMEOUT : errorfromRes;
        this._utils.ShowErrorMessage(this._localization.captions.common.Error, errMsg);
      }
      else {
        this._utils.showError(this._localization.captions.shop.PMUnexpectedError);
      }

    });
  }

  async StoreAndGetSwippedCardInfo(tokentransactionInfo: TokentransactionInfo, tenderID: number): Promise<[CardInfo, number]> {
    let swipedcardInfo: ClientCreditCardInfo;
    let newCardInfo: CardInfo;
    newCardInfo = {
      cardNumber: tokentransactionInfo.account.id,
      cardHolderName: tokentransactionInfo.account.name,
      entryMode: tokentransactionInfo.cardInfo.entryMode,
      issuerType: tokentransactionInfo.cardInfo.issuer.toLowerCase(),
      cardExpiration: tokentransactionInfo.cardInfo.cardExpiration,
      cardType: tokentransactionInfo.cardInfo.cardType
    };

    if (!this._payAgentService.ValidateCreditCard(newCardInfo)) {
      this.cardConnectDialog.close(); // BUG - 27519 - Changes
      return;
    }

    let storeTokenReq: StoreTokenRequest = {
      cardInfo: newCardInfo,
      payAgentResponse: {
        account: tokentransactionInfo.account,
        payAgentId: tokentransactionInfo.payAgentId,
        status: tokentransactionInfo.status,
        transactionDetails: tokentransactionInfo.transactionDetails,
        transactionKey: tokentransactionInfo.transactionKey,
      },
      tenderId: tenderID,
      sourceType: this.authInput?.sourceType ? String(this.authInput.sourceType) : '',
      sourceTypeId: this.authInput?.sourceTypeId ? String(this.authInput.sourceTypeId) : '',
      folioNumber: this.authInput?.folioNumber ? String(this.authInput.folioNumber) : '',
      roomNumber: this.authInput?.roomNumber ? String(this.authInput.roomNumber) : '',
      hostName: tokentransactionInfo?.hostName,
      agentVersion: tokentransactionInfo?.agentVersion,
      ipAddress: tokentransactionInfo?.ipAddress
    };

    if (this._featureFlag.SkipPMAgent) {
      storeTokenReq.gatewayResponse = JSON.stringify(tokentransactionInfo?.gatewayResponse);
      storeTokenReq.requestId = tokentransactionInfo?.requestId;
      storeTokenReq.outletId = this.DefaultOutletId
    }

    const baseResponse: PaymentBaseResponse = await this.StoreToken(storeTokenReq);
    if (baseResponse && baseResponse !== null) {
      swipedcardInfo = {
        id: 0,
        tokenTransId: baseResponse.transactionId,
        isActive: true,
        clientId: 0,
        createdTime: this._propertyInfo.CurrentDTTM
      };
      this.cardConnectDialog.close();
    } else {
      // throw error
      this.cardConnectDialog.close();
      return;
    }

    newCardInfo.cardNumber = this._payAgentService.MaskCreditCardNumber(newCardInfo.cardNumber);
    newCardInfo.cardExpiration = this.formatCreditCardExpiryDate(newCardInfo.cardExpiration);
    return [newCardInfo, baseResponse.transactionId];
  }

  async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
    try {
      if(this.IsResortFinanceFolioAuthFlow){
        storeTokenRequest.propertyId = this._propertyInfo.PropertyId;
        return await this._ss.PaymentProcessor.FolioStoreToken(storeTokenRequest);
      }
      else{
        return await this._ss.PaymentProcessor.StoreToken(storeTokenRequest);
      }
    } catch (e) {
      this.cardConnectDialog.close();
      console.error(e);
      throw e;
    }
  }

  isAdyenWithAllEntryMode() {
    return this.GatewayConfiguration &&
      this.GatewayConfiguration.payGatewayID &&
      this.GatewayConfiguration.payGatewayID.trim().toLowerCase() === 'adyen' &&
      this.GatewayConfiguration.cardInputMethod === CardInputMethod.All;

  }

  async proceedForAdyenGatewayPayment(promptForPreferenceOverride = false) {
    if (this.GatewayConfiguration.cardInputMethod === CardInputMethod.All && this.GatewayConfiguration.preferredCardEntryMode == 0 || promptForPreferenceOverride) {
      const dialogRef = this.openCardEntryModeSelectionPopup();
      dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((result: CardEntryModeDialogResult) => {
        if (result.action === CardEntryModeDialogAction.Proceed) {
          this.defaultCardInputMethod = result.cardInputMethod;
          this.proceedToCaptureCard();
        } else {
          // Card Entry Mode dialog action cancelled
        }
      });
    } else {
      this.defaultCardInputMethod = this.GatewayConfiguration.preferredCardEntryMode;
      this.proceedToCaptureCard();
    }
  }

  openCardEntryModeSelectionPopup() {
    return this.dialog.open(CardEntryModeComponent, {
      width: '550px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this._localization.captions.common.Warning,
        headerIcon: 'icon-warning-icon',
        headerMessage: this._localization.captions.shop.CorrectionTransactionMsg,
        buttonName: this._localization.captions.common.Yes,
        noButton: true,
        noButtonName: this._localization.captions.common.No,
        type: 'message'
      },
      disableClose: true
    });
  }

  isPartialPayAllowed(): boolean {
    return this.GatewayConfiguration && this.GatewayConfiguration.isPartialPayAllowed;
  }

  isManualKeyInCardEntryMode(): boolean {
    let isManualEntry = false;
    if (this.GatewayConfiguration &&
      this.GatewayConfiguration.payGatewayID &&
      this.GatewayConfiguration.payGatewayID.trim().toLowerCase() === 'adyen') {
      if (this.GatewayConfiguration.cardInputMethod !== CardInputMethod.All) {
        this.defaultCardInputMethod = this.GatewayConfiguration.cardInputMethod;
      }
      isManualEntry = this.defaultCardInputMethod === CardInputMethod.KeyIn;
    }
    return isManualEntry;
  }
  get isValidAuthRequest() {
    return (this.paymentMethodId == PaymentMethods.NonIntegratedCreditCard) ? false : (this.authInput?.authAmount > 0)
  }
  proceedToCaptureCard() {
    let handle = '';
    if (this.selectedDevice.name != '') {
      if (this.selectedDevice.name !== IDTechHandle.name && this.isValidAuthRequest) {
        this.OpenDialog();
        this.RequestForAuth(this.authInput.authAmount);
      } else if (this.selectedDevice.name != IDTechHandle.name) {
        this.OpenDialog();
        handle = this.selectedDevice.handle;
        this.CreateToken(handle, PaymentMethods.CreditCard);
      } else if (this.selectedDevice.name == IDTechHandle.name) {
        this.CaptureCardWithIDTechDevice();
      }
    }
  }

  async RequestForAuth(authAmount?) {
    let RequestBody: SaleRequest = this.FormAuthRequestBody(authAmount);
    let AuthResponse: Promise<any> = this._ss.PaymentProcessor.RequestAuth(
      RequestBody,
      this.DefaultOutletId,
      this.CEDS
    );
    AuthResponse.then((response) => {
      console.log(response);
      let AuthResult: SaleResponse = response;
      if (AuthResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        this.cardConnectDialog.close();
        this._utils.ToggleLoader(true);
        this.ValidateAuthRequest(AuthResult);
        this._utils.ToggleLoader(false);
      } else {
        this.cardConnectDialog.close();
        this.HandleFailure(response);
      }
    });
    AuthResponse.catch((errorResponse) => {
      this.cardConnectDialog.close();
      this.HandleFailure(errorResponse);
    });
  }

  FormAuthRequestBody(saleAmount?): SaleRequest {
    let userId = this._localization.GetPropertyInfo('UserId');
    return {      
      handle: this.selectedDeviceHandle,
      searchType: SearchTypes.None,
      inquirerInfo: {
        terminalId: "1",
        orderNumber: this.IsResortFinanceFolioAuthFlow? String(this.authInput?.id??0) : String(this.authInput?.sourceTypeId),
        profitCenter: "1",
        financialBins: [],
        isPartialTenderAllowed: this.GatewayConfiguration.isPartialPayAllowed ? this.GatewayConfiguration.isPartialPayAllowed : false,
        tenderId: this.selectedDevice.name == IDTechHandle.name ? String(PaymentMethods.IDTECH) : String(PaymentMethods.CreditCard),
        employeeId: userId ? userId : '0',
        customer: userId ? userId : '0',
				enterprise: String(this._propertyInfo.PropertyId),
        clientId: "",
        postingId: "",
        manualCardEntry: this.isManualKeyInCardEntryMode(),
        posTransactionId: this.IsResortFinanceFolioAuthFlow ? Number(this.authInput?.id??0) : Number(this.authInput?.sourceTypeId),
        isFolioPosting: this.authInput?.IsFolioPosting ? true : false,
        sourceTypeId:  this.authInput?.sourceTypeId
      },
      hostName: this.hostName,
      agentVersion: this.agentVersion,
      ipAddress: this.ipAddress,
      amount: {
        requestAmount: saleAmount
      }      
    };
  }

  async ValidateAuthRequest(AuthResult?): Promise<boolean> {
    const currentSaleAmt = AuthResult ? AuthResult.amount : '';
    if (AuthResult != null) {
      let validateAuthReq: ValidateAuthRequest = {
        tenderId: PaymentMethods.CreditCard,
        payAgentResponse: !AuthResult ? null : {
          payAgentID: AuthResult.payAgentId,
          status: AuthResult.status,
          transactionDetails: AuthResult.transactionDetails,
          transactionKey: AuthResult.transactionKey,
          errorMessage: AuthResult.errorMessage
        },
        cardInfo: {
          cardNumber: AuthResult.account.id,
          cardHolderName: AuthResult.account.name,
          entryMode: AuthResult.cardInfo.entryMode,
          issuerType: AuthResult.cardInfo.issuer,
          cardExpiration: AuthResult.cardInfo.cardExpiration,
          cardType: AuthResult.cardInfo.cardType
        },
        amount: currentSaleAmt.authorisedAmount,
        sourceType: String(this.authInput.sourceType),
        sourceTypeId: String(this.authInput.sourceTypeId),
        folioNumber: String(this.authInput.folioNumber),
        roomNumber: String(this.authInput.roomNumber),
        userId: this.authorizedUserId ? this.authorizedUserId : '',
        requestId: AuthResult?.requestId,
        hostName: this.hostName,
        agentVersion: this.agentVersion,
        ipAddress: this.ipAddress,
        guestGuid: this.authInput?.guestId,
        lodgingData: AuthResult?.lodgingData
      };

      let validateAuthResponse: Promise<any>; 

      if (this.IsResortFinanceFolioAuthFlow) {
        validateAuthReq.propertyId = this._propertyInfo.PropertyId; //Current PropertyId, Passing this value to override RF PropertyId
        validateAuthResponse = this._ss.PaymentProcessor.FolioValidateAuth(validateAuthReq, this.DefaultOutletId, this.CEDS);
      }
      else {
        validateAuthResponse = this._ss.PaymentProcessor.ValidateAuth(validateAuthReq, this.DefaultOutletId, this.CEDS);
      }
      
      console.log(validateAuthResponse);

      validateAuthResponse
        .then((response) => {
          let validateAuthResult: ValidateAuthResponse = response.result;
          if (validateAuthResult) {
            console.log("ValidatePay Response" + response);
            console.log("ValidatePay Response transactionId " + validateAuthResult.transactionId);
            const cardInfo = validateAuthReq.cardInfo;
            cardInfo.cardNumber = this._payAgentService.MaskCreditCardNumber(cardInfo.cardNumber);
            this.CardInfo.emit(cardInfo);
            this.getCardInfo(validateAuthResult.transactionId);
            this.AuthTransactionID.emit(validateAuthResult);
            return true;
          }
        })
        .catch((error) => {
          console.log('Validate Pay Error Response:' + error.error);
          this.AuthTransactionID.emit(null);
          return this._utils.showError(
            this._localization.captions.shop.PMUnexpectedError
          );
        });
    }
    return false;
  }

  HandleFailure(errorResponse) {
    console.log('Error during RequestSale ' + errorResponse.error);
    let errorfromRes: string = errorResponse?.error?.result?.errorMessage || errorResponse?.error?.message || errorResponse?.error?.ErrorMessage || errorResponse?.message;
    // Show Payment Error Prompt
    if (
      errorResponse.error &&
      errorResponse.error[0] != null &&
      errorResponse.error[0].Code != null &&
      errorResponse.error[0].Code != ''
    ) {
      this._payAgentService.PaymentErrorPrompt(errorResponse.error[0].Code);
    }
    else if (errorfromRes) {      
      const errMsg = RequestTimedOutException == errorfromRes ? REQUEST_TIMEOUT : errorfromRes;
      this._utils.ShowErrorMessage(this._localization.captions.common.Error, errMsg);
    }
    else {
      return this._utils.showError(
        this._localization.captions.shop.PMUnexpectedError
      );
    }
  }
  
  CardRemovalConfirmation(result: string) {
    if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase()) {
      this.removeDetails.emit(true);
    }
  }

  removeCardDetails() {
    //Validate User access
    if (!this.allowCOFRemoval) {
      this.BPoint.showBreakPointPopup(this._localization.captions.breakpoint[RetailBreakPoint.REMOVE_CARD_ON_FILE]);
      return;
    }
    //Validate if confirmation popup is required
    if (!this.ShowConfirmationForRemoval) {
      this.removeDetails.emit(true);
      return;
    }
    //Prompt for user confirmation
    this._utils.ShowErrorMessage(
      this._localization.captions.common.Warning,
      this._localization.captions.shop.COF_RemovalConfirmationMsg,
      GlobalConst.ButtonType.YesNo,
      this.CardRemovalConfirmation.bind(this)
    );
  }

  validateRemoveCOFUserAccess() {
    const userBreakPoints : UserBreakPoint[] =  this.BPoint.GetBreakPoint([RetailBreakPoint.REMOVE_CARD_ON_FILE]).result;
    this.allowCOFRemoval = userBreakPoints.find(x => x.breakPointNumber == RetailBreakPoint.REMOVE_CARD_ON_FILE).allow ?? false;
  }

  onPaymentTransactionClick() {
    this.productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    const isApproveRequired: boolean = this.folioSettings ? this.folioSettings?.requiredApprovalCode : false;



    if (this.productId === GlobalConst.Product.PMS && this.authInput.authAmount && isApproveRequired) {
      const quickLoginDialogRef = this.commonUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

        if (quickLoginDialogResult.isLoggedIn) {
          this.authorizedUserId = quickLoginDialogResult.userDetails.userId;
          this.ConnectDevice();
        }
        else {
          return;
        }
      });
    }
    else {
      this.ConnectDevice();
    }


  }

  openpaymentFrame(eve){
    this.openAddActionDialog();
  }
  openAddActionDialog() {
    const dialogRef = this.dialog.open(CelopayPaymentDetailComponent, {
        width: '500px',
        height: '750px',
        maxWidth: '95%',
        disableClose: true,
        hasBackdrop: true,
        data: { mode: 'iframe', iFrameUrl: this.IframeUrl },
        panelClass: 'small-popup'
    });
    dialogRef.afterClosed().subscribe(result => {
        // if (this.clientService.selectedIndex == 1) {
        //     this.RecentClientInformation(this.searchText, this.selectedClientSearchType);
        // } else {
        //     this.searchdata(this.searchText);
        // }
    })
}

}
