import { Injectable } from '@angular/core';
import { from, EMPTY, Observable } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators';
import { CacheOptions } from './cache-model';

// Cache service to hold values in Javascript Object as long 'this' is alive. 
@Injectable({
    providedIn: 'root'
})
export class CacheService {

    private cache: Map<string, ICacheData> = new Map<string, ICacheData>();

    public async resolve<Tapi>(sourcePredicate: (...args) => Promise<Tapi>, options: CacheOptions): Promise<Tapi> {

        const code = this.generateCacheKey(options);
        const cachedData: ICacheData = this.cache.get(code);

        if (cachedData && (new Date().getTime() < cachedData.setTime)) {
            return (this.cache.get(code).data).toPromise();
        }


        const fetchSourceDataFromServer: any = sourcePredicate();
        const freshData = from(fetchSourceDataFromServer).pipe(
            shareReplay(1),
            catchError(err => {
                delete this.cache[code];
                return EMPTY;
            }))
        this.cache.set(code, {
            data: freshData,
            setTime: new Date().getTime() + (options.maxAgeInMinutes * 60000)

        });

        return freshData.toPromise() as Promise<Tapi>;

    }

    private generateCacheKey(options: CacheOptions) {
        return options.code + sessionStorage.getItem('userSession') + (options.invalidateBy || '');
    }

    public clearAll(): void {
        this.cache = new Map<string, ICacheData>();
    }

    public clearCache(option:CacheOptions):void{
        this.cache.delete(this.generateCacheKey(option));
    }

}


interface ICacheData {
    data: Observable<any>;
    setTime: number;
}

