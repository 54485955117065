import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { UiControlService } from 'src/app/eatecui/source/shared/services/ui-controls.service';
import { UiConfiguration } from './ui-control-config.interface';
import { UiControlConfiguration, UiControlPermisssion, UiControls } from './ui-controls.interface';

export const AuthorizedRoutingConfig: UiConfiguration = (
    controlConfiguration: Array<UiControlConfiguration>, 
    RouterUrl: any, 
    EatecUiControls: Array<UiControls>,
    uiControlService: UiControlService,
    RouterNavigateService ?: RouteNavigateService,
    CustomData ?: any
    ): any => {
        try {
            let ifRoute = false;
            if ( controlConfiguration && RouterUrl ) {
                if ( controlConfiguration.length > 0) {
                   const getActivateRoute =  controlConfiguration.filter(x => x.IsActiveRoute);
                   if (getActivateRoute.length > 0) {
                       const filteredConfig = getActivateRoute[0];
                        if (filteredConfig.hasOwnProperty('RedirectUrl') && filteredConfig.RedirectUrl !== '') {
                            ifRoute = true;
                            authorizedControllerEngine(getActivateRoute, EatecUiControls, uiControlService);
                        }
                   }
                }
            } 
            return ifRoute;
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

    function authorizedControllerEngine(
    Authorizerconfig: Array<UiControlConfiguration>, 
    EatecUiControls: Array<UiControls>, 
    uiControlService: UiControlService): void {
        try {
            const AuthorizedPermision = Authorizerconfig[0].UiControlPermisssion;
            AuthorizedPermision.forEach((permissionControl: UiControlPermisssion) => {
              const controlIndex =  EatecUiControls.findIndex((eatecControls: UiControls) =>  
              eatecControls.AuthorizedKey === permissionControl.AuthorizedKey);
              if (controlIndex > -1) {
                EatecUiControls[controlIndex].Visible = false;
                EatecUiControls[controlIndex].IsAllowed = false;
              }
            });
            uiControlService.eatecUiControls = [...EatecUiControls];
            sessionStorage.setItem('Eatec-Ui-Controls', JSON.stringify(EatecUiControls));
        } catch (error) {

        }
    }