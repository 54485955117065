import { Injectable } from '@angular/core';
import { ValidatorFn, Validators, FormGroup, AbstractControl } from '@angular/forms';
import moment from 'moment';
import { ValidationResult } from './form-type.entity';

@Injectable({
  providedIn: 'root',
})

export class FormValidation {
  errorMessage: string;
  validationErrorMessage: string;
  createValidation(validators: any): ValidatorFn[] {
    const validate = [];
    const ss = Validators.required;
    validators.map((x: any) => { validate.push(x.validation); });
    return validate;
  }

  createValidationMessage(e: any, field: any): string {
    field.forEach((element: any) => {
      if (element.key.toLowerCase() === e.toLowerCase()) {
        this.errorMessage = element.validationMessage;
      }
    });
    return this.errorMessage;
  }
  composedValidation(form: any, Field: any): void {
    const fieldType = Object.keys(Field.fieldType).toString();
  }
}

export class CustomValidator {
  static startsWithNumber(control: any): ValidationResult {
    if (control.value !== '' && !isNaN(control.value.charAt(0))) {
      return { 'startsWithNumber': true };
    }
    return null;
  }

  /**
   * @method onlyNumber
   * @params control
   * @description accepts only number values with decimal values
   */
  static onlyNumberWithDecimal(control: any): ValidationResult {
    let isValid = false;
    if (control.value) {
      isValid = !isNaN(parseFloat(control.value)) && isFinite(control.value);
    }
    if (control.value === '' || control.value == null) {
      return null;
    }
    if (isValid) {
      return null;
    } else {
      return {
        onlyNumberWithDecimal: true
      };
    }
  }
  /**
   * @method onlyCheckNumber
   * @params control @method
   * @description accepts only numbers no float values
   */
    static checkMinusPlusFormat(control: any): ValidationResult {
      if ( control && control.value !== '' && control.value != null && control.value !== undefined && !Array.isArray(control.value)) {
      const getValueCount =  control.value.toString().split('');
      if ( getValueCount.length > 0 ){
        const getMinusPlusCount = getValueCount.filter(x => x === '-' || x === '+' || x === '.');
        const getMinusPlusIndex = getValueCount.findIndex(x => x === '-' || x === '+');
        const checkDotPostionIndex = getValueCount.findIndex(x => x === '.');
        const format = /^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        const regerPatternValid = format.test(control.value.toString());
        if ( getMinusPlusCount.length > 1  || getMinusPlusIndex > 0  || regerPatternValid || checkDotPostionIndex === 0 ){
          return {
            checkMinusPlusFormat: true
            };
        }
      }
      }else{
        return null;

      }
    }

  /**
   * @params control
   * @method validateEmail
   * @description Validate Email Values
   */
  static validateEmail(control: any): ValidationResult {
    const validEmail = (/^\w+([\.-]\w+)*@\w+(-\w+)*(\.\w+)+$/.test(control.value));
    const isValid = validEmail;
    return isValid ? null : { invalidEmail: true };
  }

  /**
   * @method OnlyPositiveNumbers
   * @params control
   * @description accepts only positive numbers
   */
  static OnlyPositiveNumbers(control: any): ValidationResult {
    const regex = new RegExp('^[0-9]\\d*$');
    if (control.value !== '' && control.value != null && !regex.test(control.value)) {
      return {
        onlyPositiveNumber: true
      };
    }
    return null;
  }

  /**
   * @method onlyCheckNumber
   * @params control @method
   * @description accepts only numbers no float values
   */
  static onlyCheckNumber(control: any): ValidationResult {
    let isValid = false;
    if (control.value) {
      isValid = !isNaN(parseFloat(control.value));
    }
    if (control.value === '' || control.value == null || control.value === 0) {
      return null;
    }
    if (isValid) {
      return null;
    } else {
      return {
        onlyCheckNumber: true
      };
    }
  }

  // /**
  //  * @method onlyCheckNumber
  //  * @params control @method
  //  * @description accepts only numbers no float values
  //  */
  //   static checkMinusPlusFormat(control: any): ValidationResult {
  //     if ( control && control.value !== '' && control.value != null && !Array.isArray(control.value)) {
  //     const getValueCount =  control.value.split('');
  //     if ( getValueCount.length > 0 ){
  //       const getMinusPlusCount = getValueCount.filter(x => x === '-' || x === '+' );
  //       const getMinusPlusIndex = getValueCount.findIndex(x => x === '-' || x === '+' );
  //       if ( getMinusPlusCount.length > 1  || getMinusPlusIndex > 0 ){
  //         return {
  //           checkMinusPlusFormat: true
  //           };
  //       }
  //     }
  //     }else{
  //       return null;

  //     }
  //   }

  static maxDateValidator(maxDateValue: Date): ValidatorFn {
    return (control: AbstractControl) => {
      let isValid = true;
      if (control.value) {
        const controlDate = moment(control.value).format('MM/DD/YYYY');
        const maxDate = moment(maxDateValue).format('MM/DD/YYYY');
        isValid = moment(controlDate).isSameOrBefore(maxDate);
        if (isValid) {
          return null;
        } else {
          return {
            maxDateValidator: true
          };
        }
      } else {
        return null;
      }
    };
  }

  /**
   *
   * @ param control
   * @method whiteSpaceValidation
   * @returns  Validates White Space
   */
   static whiteSpaceValidation(control: any): ValidationResult {
    try {
      if (control.value) {
        const regex = /^\S*$/;
        const isValid = regex.test(control.value);
        return ( isValid ) ? null : { whitespace: true };
      } else {
        return null;
      }
    } catch (error: any) {
      console.error('White Space Validation Error ', error);
    }
  }
  /**
   *
   * @ param control
   * @method passwordValidation
   * @description Password Validation
   * @ returns
   */
  static passwordValidation(control: any): ValidationResult {
    try {
      if (control.value) {
        const regex = /^(?=.{10,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
        const isValid = regex.test(control.value);
        return ( isValid ) ? null : { invalidpassword: true };
      } else {
        return null;
      }
    } catch (error: any) {
      console.error('Password Validation Error ', error);
    }
  }
}


export class CustomErrorMesssage {
  static createError(fieldType: string, form: FormGroup, field: any): string {
    let errorMessage: string;
    switch (fieldType) {
      case 'SingleData': {
        errorMessage = this.singleControl(form, field);
        break;
      }
      case 'MultipleData': {
        errorMessage = this.multipleControl(form, field);
        break;
      }
      case 'groupingDataSingle': {
        errorMessage = this.groupingControl(form, field);
        break;
      }
      case 'groupingDataMultiple': {
        errorMessage = this.groupingControl(form, field);
        break;
      }
      default: {
        console.log('Error to another way');
        break;
      }
    }
    return errorMessage;
  }
  static singleControl(form: any, field: any): any {
    const fieldType = Object.keys(field.fieldType).toString();
    const fieldName = field.fieldType.SingleData.name;
    if (form.controls[fieldName].errors) {
      const allErrorType = Object.keys(form.controls[fieldName].errors).toString();
      const errorType = allErrorType.split(',')[0];
      return this.createValidationMessage(errorType, field.fieldType[fieldType].FieldValidation);
    }
    return null;
  }

  static multipleControl(form: any, field: any): any {
    const fieldType = Object.keys(field.fieldType).toString();
    const formFieldName = field.fieldType.MultipleData.formName;
    let errorMsg: string;
    form.controls[formFieldName].controls.forEach((element, index) => {
      if (element.errors) {
        const allErrorType = Object.keys(element.errors).toString();
        const errorType = allErrorType.split(',')[0];
        return errorMsg = this.createValidationMessage(errorType, field.fieldType[fieldType].data[index].FieldValidation);
      }
    });
    return errorMsg;
  }

  static groupingControl(form: any, field: any): any {
    const fieldType = Object.keys(field.fieldType).toString();
    const formFieldName = field.fieldType[fieldType].name;
    if (form.controls[formFieldName].errors) {
      const allErrorType = Object.keys(form.controls[formFieldName].errors).toString();
      const errorType = allErrorType.split(',')[0];
      return this.createValidationMessage(errorType, field.fieldType[fieldType].FieldValidation);
    }
    return null;
  }

  static createValidationMessage(errorType: any, field: any): any {
    let errorMessage: string;
    field.forEach((element: any) => {
      if (element.key.toLowerCase() === errorType.toLowerCase()) {
        errorMessage = element.validationMessage;
      }
    });
    return errorMessage;
  }
}
