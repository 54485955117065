import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  HostBinding,
  Input,
  Optional
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { DecimalPipe } from "@angular/common";
import * as _ from 'lodash';
import { FormType } from "../form-type.entity";

@Directive({
  selector: "[decimalConveter]"
})
export class DecimalConveterDirective {
  textbox: HTMLInputElement;
  clonedValue: any;
  @Input() FormField: FormType;
  previousKey: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngControl: NgControl,
    private decimalPipe: DecimalPipe,
  ) { }

  /**
   * @method setIntialValue
   * @input None 
   * @output Element Value
   * @description set Intial Value to the Every Moment
   */
  setIntialValue(): any {
    try {
      return this.el.nativeElement.tagName === "INPUT" ? this.el.nativeElement : this.el.nativeElement.querySelector("input");
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method fixedMethod
   * @input decimal Value
   * @description check and assigned fixed value
   */
  fixedMethod(textvalue: number, ActionType: string, decimalPoint: number): number {
    try {
      let returnTextValue: any = textvalue;
      const convertStringValue: string = textvalue.toString();
      const splitedTextValue: Array<string> = (convertStringValue) ? convertStringValue.split(".") : null;
      if (ActionType === 'visible') {
        if (splitedTextValue.length === 1) {
          returnTextValue = textvalue.toFixed(decimalPoint);
        } else if (splitedTextValue.length > 1 && splitedTextValue[1].length > decimalPoint) {
          returnTextValue = textvalue.toFixed(decimalPoint);
        } else if (splitedTextValue.length > 1 && splitedTextValue[1].length < decimalPoint) {
          returnTextValue = textvalue.toFixed(decimalPoint);
        } else {
          returnTextValue = textvalue;
        }
      } else if (ActionType === 'focus') {
        if (splitedTextValue.length === 1) {
          returnTextValue = textvalue;
        } else if (splitedTextValue.length > 1 && splitedTextValue[1].length > decimalPoint) {
          returnTextValue = textvalue.toFixed(decimalPoint);
        } else {
          returnTextValue = textvalue;
        }
      }
      return returnTextValue;

    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method checkIsNan
   * @input any string or Number
   * @output is Number or None
   * @description check that value number or nan
   */
  checkIsNaN(inputValue: any, decimalPoint: number, ActionType: string): any {
    try {
      let intialValue: any = inputValue;
      const convertValue: any = inputValue * 1;
      const checkIsNan = isNaN(convertValue);
      if (!checkIsNan) {
        intialValue = this.fixedMethod(convertValue, ActionType, decimalPoint);
      }
      return intialValue;
    } catch (error) {
      console.error(error);
    }
  }

  ngAfterViewInit() {
    if (this.FormField && this.FormField.fieldFormaters) {
      this.textbox = this.setIntialValue();
      this.clonedValue = _.cloneDeep(this.textbox.value);
      const formattedVal = this.checkIsNaN(this.textbox.value, this.FormField.fieldFormaters.VisibleDecimal, 'visible');
      // if (this.ngControl) {
      //   this.ngControl.control.setValue(formattedVal, { emitEvent: false });
      // } else {
      this.renderer.setProperty(this.textbox, "value", formattedVal);
      // }
    }
  }

  @HostListener("bluraction", ["$event"]) onBlurAction(event) {
    if (this.FormField && this.FormField.fieldFormaters) {
      const intialDeepValue = this.setIntialValue();
      this.clonedValue = _.cloneDeep(intialDeepValue.value);
      const formattedVal = this.checkIsNaN(this.textbox.value, this.FormField.fieldFormaters.VisibleDecimal, 'visible');
      this.renderer.setProperty(this.textbox, "value", formattedVal);
    }
  }

  @HostListener("blur", ["$event"]) onBlur(event) {
    if (this.FormField && this.FormField.fieldFormaters) {
      const intialDeepValue = this.setIntialValue();
      this.clonedValue = _.cloneDeep(intialDeepValue.value);
      const InitialFormValue = this.checkIsNaN(this.textbox.value, this.FormField.fieldFormaters.FocusDecimal, 'focus');
      if (this.ngControl) {
        this.ngControl.control.setValue(InitialFormValue, { emitEvent: false });
      }
      const formattedVal = this.checkIsNaN(this.textbox.value, this.FormField.fieldFormaters.VisibleDecimal, 'visible');
      // if (this.ngControl) {
      //   this.ngControl.control.setValue(formattedVal, { emitEvent: false });
      // } else {
      this.renderer.setProperty(this.textbox, "value", formattedVal);
      // }
    }
  }

  @HostListener("keydown", ["$event"]) onKeyDown(event) {
    if (this.FormField && this.FormField.fieldFormaters) {
      // const decimalRegex = /^[-]?([\d]{0,15}([\.][\d]{0,6})?)$/m;
      // if (event.key !== 8 &&) {
      //   const testValue = event.target.value + event.key;
      //   const isValid = decimalRegex.test(testValue);
      //   if (!isValid) {
      //     event.preventDefault();
      //     return false;
      //   }
      // } else {
      //   return true;
      // }
      const keyCode = event.keyCode;
      const key = event.key;
      const upTargetValue = (event.target.value).indexOf('.');
      const noOfWholeDigitAllowed = this.FormField.fieldFormaters.WholeNumberLength;
      const noOfDecimalDigitAllowed = this.FormField.fieldFormaters.FocusDecimal;
      let noOfDecimalDigitEntered: number;
      const upStartIndex = event.target.selectionStart;
      if (upTargetValue != -1) {
        noOfDecimalDigitEntered = (event.target.value.length - 1) - upTargetValue;
      }
      if (
        (keyCode >= 48 && keyCode <= 57 &&
          key !== ')' &&  // 48 -ipad
          key !== '!' &&  // 49-ipad
          key !== '@' &&   // 50-ipad
          key !== '#' &&   // 51-ipad
          key !== '$' &&   // 52-ipad
          key !== '%' &&   // 53-ipad
          key !== '^' &&   // 54-ipad
          key !== '&' &&   // 55-ipad
          key !== '*' &&   // 56-ipad
          key !== '('      // 57-ipad
        ) || // NUMERIC 0 TO 9
        (keyCode >= 96 && keyCode <= 105) ||  // NUMERIC 0 TO 9
        keyCode === 8 || // backspace
        keyCode === 9 || // tab
        keyCode === 10 || // line feed
        keyCode === 13 || // enter
        keyCode === 16 || // SHIFT
        keyCode === 17 || // ctrl
        keyCode === 18 || // alt
        keyCode === 20 || // CAPS LOCK
        keyCode === 27 || // escape
        keyCode === 33 || // pageup
        keyCode === 34 || // pagedown
        keyCode === 35 || // end key
        keyCode === 36 || // home key
        (keyCode >= 37 && keyCode <= 40) || // arrow keys
        (this.previousKey === 17 && keyCode === 67) ||  // Allow copy
        (this.previousKey === 17 && keyCode === 86) ||  // Allow Paste
        keyCode === 45 || // insert
        keyCode === 46 || // delete
        (upTargetValue === -1 && (keyCode === 110 || (keyCode === 190 && key !== '>'))) ||
        // decimal point //190 -ipad(greater than)
        (keyCode === 112 && keyCode === 123) || // f1 to f12
        keyCode === 127 || // delete
        keyCode === 144 || // num lock
        ((keyCode === 107 || (keyCode === 187 && key !== '='))) || // PLUS
        ((keyCode === 109 || (keyCode === 189 && key !== '_')))
        // minus //189 -ipad(underscore)
      ) {
        if (event.target.value.split('.')[0].length < noOfWholeDigitAllowed && upTargetValue === -1) {
          this.previousKey = keyCode === 86 || keyCode === 87 ? this.previousKey : keyCode;
          return true;
        } else {
          if (upTargetValue > -1) {
            if (((keyCode >= 48 && keyCode <= 57) || // NUMERIC 0 TO 9
              (keyCode >= 96 && keyCode <= 105)) // NUMERIC 0 TO 9
            ) {
              /**allow only no of decimal digits */
              if (
                upTargetValue != -1 && //decimal point is present
                ((keyCode >= 48 && keyCode <= 57) || //NUMERIC 0 TO 9
                  (keyCode >= 96 && keyCode <= 105)) //NUMERIC 0 TO 9
              ) {
                if (event.target.value.split('.')[0].length <= noOfWholeDigitAllowed) {
                  if (noOfDecimalDigitEntered < noOfDecimalDigitAllowed && upStartIndex >= (upTargetValue + 1)) {// to check n digits
                    noOfDecimalDigitEntered++;
                    this.previousKey = keyCode === 86 || keyCode === 87 ? this.previousKey : keyCode;
                    return true;
                  } else {
                    if (upStartIndex < (upTargetValue + 1) && event.target.value.split('.')[0].length <= noOfWholeDigitAllowed) {
                      this.previousKey = keyCode === 86 || keyCode === 87 ? this.previousKey : keyCode;
                      return true;
                    } else {
                      return false;
                    }
                  }
                } else {
                  return false;
                }

              } else {
                if ((upTargetValue == -1 && (keyCode == 110 || (keyCode == 190 && key != '>')))
                  // decimal point //190 -ipad(greater than)
                  && (upStartIndex < (event.target.value.length - noOfDecimalDigitAllowed))
                ) {
                  return false;
                } else {
                  this.previousKey = keyCode === 86 || keyCode === 87 ? this.previousKey : keyCode;
                  return true;
                }
              }
            } else {
              this.previousKey = keyCode === 86 || keyCode === 87 ? this.previousKey : keyCode;
              return true;
            }
          } else {
            if (keyCode === 8 || // backspace
              keyCode === 9 || // tab
              keyCode === 10 || // line feed
              keyCode === 13 || // enter
              keyCode === 16 || // SHIFT
              (this.previousKey === 17 && keyCode === 67) ||  // Allow copy
              (this.previousKey === 17 && keyCode === 86) ||  // Allow Paste
              keyCode === 18 || // alt
              keyCode === 20 || // CAPS LOCK
              keyCode === 27 || // escape
              keyCode === 33 || // pageup
              keyCode === 34 || // pagedown
              keyCode === 35 || // end key
              keyCode === 36 || // home key
              (keyCode >= 37 && keyCode <= 40) ||
              keyCode === 45 || // insert
              keyCode === 46 || // delete
              (upTargetValue === -1 && (keyCode === 110 || (keyCode === 190 && key !== '>'))) ||
              // decimal point //190 -ipad(greater than)
              (keyCode === 112 && keyCode === 123) || // f1 to f12
              keyCode === 127 || // delete
              keyCode === 144 || // num lock
              ((keyCode === 107 || (keyCode === 187 && key !== '='))) || // PLUS
              ((keyCode === 109 || (keyCode === 189 && key !== '_')))) {
                this.previousKey = keyCode === 86 || keyCode === 87 ? this.previousKey : keyCode;
              return true;
            } else {
              return false;
            }
          }
        }
      } else {
        return false;
      }
    }
  }

  @HostListener("focus", ["$event"]) onFocus(event) {
    if (this.FormField && this.FormField.fieldFormaters) {
      const initVal = this.textbox.value.replace(/,/g, '');
      // if (this.ngControl) {
      //   const clonedValue = this.checkIsNaN(this.clonedValue, this.FormField.fieldFormaters.FocusDecimal, 'focus');
      //   this.ngControl.control.setValue(clonedValue, { emitEvent: false });
      // } else {
      const formValue = this.ngControl ? this.ngControl.control.value : this.clonedValue;
      const clonedValue = this.checkIsNaN(formValue, this.FormField.fieldFormaters.FocusDecimal, 'focus');
      this.renderer.setProperty(this.textbox, "value", clonedValue);
      // }
    }
  }
}
