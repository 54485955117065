import * as signalR from "@microsoft/signalr";
import { EventEmitter, Injectable } from '@angular/core';
import { Localization } from '../../localization/localization';
import { environment } from '../../../../environments/environment';
import { EventConstants } from "../../enums/shared-enums";
import { USER_SESSION } from "../../../app-constants";
import { SignalRMessage } from './signalR.model';

@Injectable({
  providedIn: 'root'
})

export class SignalrService {
  public hubConnection: signalR.HubConnection;
  public startedConnection: Promise<void>;
  public OnConnectionClosed: EventEmitter<Error> = new EventEmitter<Error>();
  
  constructor(private localization: Localization) {
  }
  
  startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(environment["signalrService"],{ 
        headers: { 
          "Authorization": `Bearer ${sessionStorage.getItem('_jwt')}`
        }})
      .withAutomaticReconnect() //  by default auto retry Count 5 (0,2000,10000,30000)
      .build();
     this.hubConnection.keepAliveIntervalInMilliseconds =30 * 60 * 1000; // 30 mins
    
     this.hubConnection.serverTimeoutInMilliseconds = 30 * 60 * 1000; //30 mins
    this.startedConnection =  this.hubConnection
      .start();
      this.hubConnection.onclose((err)=>{
        this.OnConnectionClosed.emit(err);
      });
    return this.startConnection; 
  }

  public stopConnection = () => {
    this.hubConnection.stop().catch(err => console.log(err));
  }


  public addListener = async <T>(ref: any,eventName: string, callBack: (...message: SignalRMessage<T>[]) => void) => {
    if (this.hubConnection == null || this.hubConnection.state == signalR.HubConnectionState.Disconnected) {
      await this.startConnection();
    }
    this.hubConnection.on(eventName, callBack.bind(ref));
    this.subscribeToEvent(eventName);
  };

  public addPropertyListener = async (ref: any,callBack: (...args: any[]) => void) => {
    await this.addListener(ref,EventConstants.PropertyEvents, callBack);
  };

  public addUserListener = async (ref: any,callBack: (...args: any[]) => void) => {
    await this.addListener(ref,EventConstants.UserEvents, callBack);
  };

  public addCacheListener = async(ref:any,callBack:(...args:any[]) => void) =>{
    await this.addListener(ref,EventConstants.CacheEvents, callBack);
  }

  public addCondoEmailListener = async(ref:any, callBack:(...args:any[]) => void) =>{
    await this.addListener(ref, EventConstants.CondoEmail, callBack);
  }

  public destroyListener = (eventName: string) => {
    if(this.hubConnection != null && this.hubConnection.state == signalR.HubConnectionState.Connected){
      this.hubConnection.off(eventName);
      this.UnSubscribeToEvent(eventName);
    }
  }

  public subscribeToEvent(eventName: string) {
    this.hubConnection.invoke('SubscribeToEvent', parseInt(this.getUserId()), parseInt(this.getPropertyId()), this.getUserSessionId(),parseInt(this.getProductId()),parseInt(this.getTenantId()), eventName).catch(err => console.error(err));
    this.setSignalREvent(eventName);
  }

  public UnSubscribeToEvent(eventName: string) {
      this.hubConnection.invoke('UnSubscribeToEvent', parseInt(this.getUserId()), parseInt(this.getPropertyId()), this.getUserSessionId(), eventName).catch(err => console.error(err));
      this.removeSignalrEvent(eventName);
  }

  private getPropertyId() {
    return this.localization.GetsessionStorageValue('propertyInfo', 'PropertyId');
  }
  private getProductId() {
    return this.localization.GetsessionStorageValue('propertyInfo', 'ProductId');
  }

  private getTenantId() {
    return this.localization.GetsessionStorageValue('propertyInfo', 'TenantId');
  }

  private getUserId() {
    return this.localization.GetsessionStorageValue('_userInfo', 'userId');
  }

  private getUserSessionId() {
    return sessionStorage.getItem(USER_SESSION);
  }
  private removeSignalrEvent(event:string){
    let events =this.GetSignalREvents();
    if(events) {
      events=events.filter(x=>x!=event);
      sessionStorage.setItem('SignalREvents', JSON.stringify(events));
    }
  }
  private setSignalREvent(event:string) {
    let events =this.GetSignalREvents();
    
      if(events) events.push(event);
      else events=[event];
      events = [...new Set(events)];
      sessionStorage.setItem('SignalREvents', JSON.stringify(events));
    
    
  }
 
  public GetSignalREvents():string[]{
    const events =JSON.parse(sessionStorage.getItem('SignalREvents'));
    return events;
  }
  
  
}
