import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { ModuleKey } from 'src/app/eatecui/source/shared/enum/global.enum';
import { AuthPayload, IntegerationModel, IntegrationMasterModel, IntegerationMaster, 
ModuleKeyMapping, IntegerationConfig, IntegrationConfiguration, IntegrationCollection } from './integration-interface';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilysysSessionExpiredComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-session-expired/agilysys-session-expired.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomFormBuild, FormBuilderGroupKey, MasterInventoryManagement, SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { BreadCrumbConfiguration, DataOption, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
const httpOptions = {  headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

export class Intergration implements IntegerationModel {
    integrationToken: string;
    integrationMasterData: IntegerationMaster | undefined;
    integrationGridData: any;
    toastrService: ToastrService;
    configService: ConfigService;
    httpService: HttpService;
    integrationModuleData: Array<IntegrationMasterModel> = [];
    integrationMasterDataCollection: Array<IntegrationMasterModel> = [];
    integrationConfigData: IntegerationConfig;
    integerationConfiguration: IntegrationConfiguration;
    masterDataCollection: MasterInventoryManagement;
    MasterSubFormConfig: Array<SubFormConfigs>;
    transactionJsonData: TransactionConfiguration;
    constructor(toastrService: ToastrService, configService: ConfigService, httpService?: HttpService) {
        this.toastrService = toastrService; 
        this.configService = configService;
        this.httpService = httpService;
    }
    /**
     * @method IntegrationAuthLogin
     * @InputParams Username PassWord API Path
     * @Description Login to the API 
     */
    IntegrationAuthLogin( httpService: HttpService, ClientPayload: AuthPayload): void {
        try {
            httpService.GetHttpRequestId(ClientPayload.ClientUrl, ClientPayload.Payload).subscribe((r: any) => {
                this.integrationToken = r['access_token'];
                sessionStorage.setItem('IntegrationToken', this.integrationToken);
            });
        } catch ( error ) {
            console.error(error);
        }
    }

    /**
     * @method IntegrationGet
     * @InputParams ClientPayload
     * @Description Get Data From the Server
     */
    IntegrationGet( httpService: HttpService, ClientPayload: AuthPayload, dialog: MatDialog, dmViaEatec: boolean): Promise<any> {
        try {
            // const httpOptionsFormData = {
            //     headers: new HttpHeaders()
            //   };
            // httpOptionsFormData.headers = httpOptionsFormData.headers.set('x-api-version', '2.0');
            // httpOptionsFormData.headers = httpOptionsFormData.headers.set('Authorization', `Bearer ${this.integrationToken}`);
            // this.integrationGridData = await httpClinetModel.post(ClientPayload.ClientUrl, ClientPayload.Payload).toPromise();
            return new Promise((resolve, reject) => {
                const ingerationMethodType = (dmViaEatec) ? 'PosthttpMethod' : 'HttpClientPost';
                httpService[ingerationMethodType](ClientPayload.ClientUrl, ClientPayload.Payload).subscribe((res: any) => {
                    this.integrationGridData = typeof(res) === 'string' ? JSON.parse(res) : res;
                    console.log('Integration Grid Data', this.integrationGridData);
                    resolve(true);
                }, (error) => {
                    const ConfirmPopUpDialog: ConfirmPopModel = {
                        PopupHeaderContent: 'Error',
                        PopUpContent: dmViaEatec ? error?.Data?.ResponseException?.ExceptionMessage : 
                        error?.ResponseException?.ExceptionMessage
                      };
                      if (ConfirmPopUpDialog.PopUpContent) {
                        const dialogRef = dialog.open(AgilysysSessionExpiredComponent, {
                            width: '480px',
                            height: '222px',
                            data: ConfirmPopUpDialog,
                            disableClose: true
                          });
                          dialogRef.afterClosed().subscribe((r: any) => { 
                            console.log(r);
                            resolve(true);
                          });
                    }                     
                });
            });
        } catch ( error ) {
            console.error(error);
        }
    }

    /**
     * @method IntegerationMasterData
     * @InputParams Username PassWord API Path
     * @Description Login to the API 
     */
    IntegerationMasterData( httpService: HttpService, ClientPayload ?: AuthPayload): void {
        try {
            httpService.GethttpMethod(ClientPayload.ClientUrl).subscribe((r: Array<IntegrationMasterModel> ) => {
                if ( r ) {
                    this.integrationMasterDataCollection = r['value'];
                }
            });
        } catch ( error ) {
            console.error(error);
        }
    }
    /**
     * @method IntegerationMasterData
     * @InputParams Username PassWord API Path
     * @Description Login to the API 
     */
    IntegerationConfigData( httpService: HttpService, ClientPayload ?: AuthPayload): void {
        try {
            httpService.GetHttpRequestId(ClientPayload.ClientUrl, ClientPayload.Payload).subscribe((r: IntegerationConfig ) => {
                if ( r ) {
                    this.integrationConfigData = r;
                }
            });
        } catch ( error ) {
            console.error(error);
        }
    }
    /**
     * @method IntegerationMasterData
     * @InputParams Username PassWord API Path
     * @Description Login to the API 
     */
    IntegerationMasterDataLocal( httpClinetModel: HttpClient, ClientPayload ?: AuthPayload): void {
        try {
            httpClinetModel.get(ClientPayload.ClientUrl).subscribe((r: any ) => {
               this.integrationMasterData = r;
            });
        } catch ( error ) {
            console.error(error);
        }
    }

    /**
     * @method IntegerationMasterData
     * @InputParams Username PassWord API Path
     * @Description Login to the API 
     */
    async IntegerationGridDataLocal( httpClinetModel: HttpClient, ClientPayload ?: AuthPayload): Promise<any> {
        try {
            this.integrationGridData = await httpClinetModel.get(ClientPayload.ClientUrl).toPromise();
            console.log('Grid Data', this.integrationGridData);
        } catch ( error ) {
            console.error(error);
        }
    }

    /**
     * @method IntegerationPostData
     * @InputParams Username PassWord API Path
     * @Description Login to the API 
     */
    IntegerationPostData( httpService: HttpService, dialog: MatDialog, dmViaEatec: boolean, ClientPayload ?: AuthPayload): void {
        try {
            const progressionToast = this.toastrService.info('Inprogress', 'File', {
                progressAnimation: 'increasing',
                progressBar: true,
                positionClass: 'toast-bottom-right'
             });
            const ingerationMethodType = (dmViaEatec) ? 'IntegrationPostMethod' : 'HttpClientPost';
            httpService[ingerationMethodType](ClientPayload.ClientUrl, ClientPayload.Payload).subscribe((r: any) => {
                console.log('DmAuth', r);
                if (r) {
                    this.toastrService.success('Document uploaded successfully', '', {
                        timeOut: 3000,
                        closeButton: true,
                    });
                }
            }, (error) => {
                const ConfirmPopUpDialog: ConfirmPopModel = {
                    PopupHeaderContent: 'Error',
                    PopUpContent: error.Message
                  };
                  const dialogRef = dialog.open(AgilysysSessionExpiredComponent, {
                    width: '400px',
                    height: '222px',
                    data: ConfirmPopUpDialog,
                    disableClose: true
                  });
                  dialogRef.afterClosed().subscribe((r: any) => { 
                    console.log(r);
                  });
            });
        } catch ( error ) {
            console.error(error);
        }
    }

    /**
     * @method IntegerationDelete
     * @InputParams Username PassWord API Path
     * @Description Login to the API 
     */
    IntegerationDelete( httpService: HttpService, dmViaEatec: boolean, ClientPayload ?: AuthPayload): any {
        try {
            return new Promise(resolve => {
                const options = {
                    headers: httpOptions.headers,
                    body : ClientPayload.Payload
                };
                const ingerationMethodType = (dmViaEatec) ? 'IntegrationDeleteMethod' : 'HttpClientDelete';
                httpService[ingerationMethodType](ClientPayload.ClientUrl, options).subscribe((r: any) => {
                    console.log('Delete', r);
                    resolve(true);
                });
            });
        } catch ( error ) {
            console.error(error);
        }
    }

    IntegrationDownload( httpService: HttpService, Url: string, getDocumentPayload: any, FileName: string, dmViaEatec: boolean) {
        try {
            let printHeaders = new HttpHeaders();
            printHeaders = printHeaders.set('x-api-version', '2.0');
            printHeaders = printHeaders.set('Access-Control-Expose-Headers', 'Content-Disposition');
            // httpClinetModel.get<Blob>(Url, { observe: 'response',
            //  headers: printHeaders, responseType: 'blob' as 'json' }).subscribe(res => {
            //     console.log('res', res);
            //     const url = URL.createObjectURL(res.body);
            //     const a: any = document.createElement('a');
            //     a.href = url;
            //     a.download = FileName;
            //     document.body.appendChild(a);
            //     a.style = 'display: none';
            //     a.click();
            //     a.remove();
            //     URL.revokeObjectURL(url);
            // });
            const integrationMethodType = dmViaEatec ? 'PosthttpMethod' : 'HttpClientGet';
            httpService[integrationMethodType](Url, getDocumentPayload, null, { observe: 'response',
             responseType: 'blob'}).subscribe((res: any) => {
                console.log('res', res);
                const url = URL.createObjectURL(res.body);
                const a: any = document.createElement('a');
                a.href = url;
                a.download = res.body.type === 'image/jpeg' ? (FileName + '.jpeg') : FileName;
                // a.download = FileName;
                document.body.appendChild(a);
                a.style = 'display: none';
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            });
        } catch (Error) {
            console.error('Error occurred in IntegrationDownload', Error);
        }
    }

     /**
     * @method IntegerationModuleFilter
     * @InputParams ModuleName: string, IntegrationName: string
     * @Description FilterIntegration
     */
     IntegerationModuleFilter( ModuleName: string, IntegrationName: string ): void {
        try {
            const IntegrationMaster: Array<IntegrationMasterModel> = this.integrationMasterDataCollection;
            if ( IntegrationMaster && IntegrationMaster.length > 0 ) {
                const Modulecol: Array<ModuleKeyMapping> = this.getIntegerationModule(ModuleName);
                if ( Modulecol && Modulecol.length > 0 ) {
                   this.integrationModuleData =  
                   IntegrationMaster.filter((f: IntegrationMasterModel) => f.ModuleId === Modulecol[0].ModuleId);
                }
                
            }
        } catch ( error ) {
            console.error(error);
        }
    }
    /**
     * @method setIntegrationConfiguration()
     * @input None
     * @output None
     * @description
     */
    setIntegrationConfiguration(selectedTransaction: any): void {
        try {
          const _that = this;
          const integrationConfig = {} as IntegrationConfiguration;
          const integCollection: Array<IntegrationCollection> = [];
          const getIntegKeys = this.configService && this.configService.integrationConfig ? 
          Object.keys(this.configService.integrationConfig) : null;
          integrationConfig.EnabledIntegration = this.checkEnableIntegration();
          getIntegKeys?.forEach((key, index ) => {
            const intCollection = {} as IntegrationCollection;
            intCollection.AllowedFileText = '[Only png, jpg, jpeg, pdf, doc, docx and bmp formats allowed.Maximum upload per file size is 2 MB]';
            intCollection.Id = index + 1;
            intCollection.IntegratonName = key;
            intCollection.IsEnabled = ( _that.configService.integrationConfig[key] && 
            _that.configService.integrationConfig[key].toLowerCase() === 'false') ? false : true;
            if (key === 'DataMagine' && intCollection.IsEnabled) {
                intCollection.AllowedFileText = '[Only csv, doc, docx, png, jpg, jpeg, ppt, pptx, pdf, zip, txt, xls, xlsx formats allowed.Maximum upload per file size is 5 MB]';
            }
            integCollection.push(intCollection);
        });   
        integrationConfig.IntegrationCollection = integCollection;
        this.integerationConfiguration = integrationConfig;
        } catch (error) {
            console.error(error);
        }
    }
    /**
     * @method checkEnableIntegration
     * @input None
     * @description check whether integration is enabled or not
     */
    checkEnableIntegration(): boolean {
        let enableInteg = false;
        for (const key in this.configService?.integrationConfig) {
            if (Boolean(this.configService.integrationConfig[key])) {
                const integrationBool: boolean = (this.configService.integrationConfig[key].toLowerCase() === 'false') ? false : true;
                if ( integrationBool) {
                    enableInteg = integrationBool;
                }
            }
        }
        return enableInteg;
    }

    /**
     * @Method getIntegerationModuleId
     * @input ModuleName
     * @Description Filtered Module Key 
     */

    getIntegerationModule( ModuleName: string ): Array<ModuleKeyMapping> {
        try {
      
          const ModuleCollection: Array<ModuleKeyMapping> = [
            {
                ModuleId: 4,
                ModuleName: ModuleKey.RECEIVING
            },
            {
                ModuleId: 5,
                ModuleName: ModuleKey.PURCHASEREQUEST
            },
            {
                ModuleId: 2,
                ModuleName: ModuleKey.TRANSFERSANDTRANSFEROUT
            },
            {
                ModuleId: 3,
                ModuleName: ModuleKey.SPOILAGEANDLOSS
            },
            {
                ModuleId: 1,
                ModuleName: ModuleKey.REQUISITIONS
            },
            {
                ModuleId: 7,
                ModuleName: ModuleKey.PRODUCTION
            },
            {
                ModuleId: 6,
                ModuleName: ModuleKey.SALES
            }
          ];
          const MCollection: Array<ModuleKeyMapping> = ModuleCollection
          .filter((i: ModuleKeyMapping) =>  i.ModuleName.toLowerCase() === ModuleName.toLowerCase());
          return MCollection;  
        } catch ( error ) {
            console.error(error);
        }
    }

    /**
     * @method IntegrationMasterPermission
     * @Input KeyName: string, EnabledPermission
     * @output None
     * @descritption Enabled Integration Persmission
     */
    IntegrationMasterLandingSubPermission( KeyName: string, IsEnabled: boolean): any {
        try{
           if ( this.masterDataCollection ) {
            this.masterDataCollection.NestedFormConfig.ViewFormConfig.forEach((FormConfig: SubFormConfigs)=> {
               if( FormConfig.OdataKey === KeyName){
                FormConfig.Enable = IsEnabled;
               }
            });
            console.log('this.masterDataCollection', this.masterDataCollection);
            return this.masterDataCollection;
           }
        } catch(error){
            console.error(error);
        }
    }

    /**
     * @method IntegrationMasterPermission
     * @Input KeyName: string, EnabledPermission
     * @output None
     * @descritption Enabled Integration Persmission
     */
    IntegrationMasterSubPermission( KeyName: string, IsEnabled: boolean): void {
        try{
           if ( this.MasterSubFormConfig ) {
            this.MasterSubFormConfig.forEach((FormConfig: SubFormConfigs)=> {
               if( FormConfig.OdataKey === KeyName){
                FormConfig.Enable = IsEnabled;
               }
            });
           }
        } catch(error){
            console.error(error);
        }
    }

     /**
     * @method IntegrationMasterPermission
     * @Input KeyName: string, EnabledPermission
     * @output None
     * @descritption Enabled Integration Persmission
     */
     IntegrationMasterFormPermission( KeyName: string, IsEnabled: boolean): void {
        try{
           if ( this.masterDataCollection ) {
            this.masterDataCollection.FormConfig.CustomFormBuilder.forEach((FormBuild: CustomFormBuild)=>{
               if( FormBuild.CustomBuilderName === 'intialform'){
                   FormBuild.CustomBuilderKey.forEach((BuilderGroupKey: FormBuilderGroupKey)=>{
                      if( BuilderGroupKey.OdataKey === KeyName ){
                           BuilderGroupKey.Hide = !IsEnabled;
                      }
                   })
               }
            })
           }
        } catch(error){
            console.error(error);
        }
    }

     /**
     * @method IntegrationTranPermission
     * @Input KeyName: string, EnabledPermission, rolePermission
     * @output None
     * @descritption Enabled Integration Persmission
     */
     IntegrationTransPermission( KeyName: Array<string>, IsEnabled: boolean, permissionKey: string, rolePermission: boolean): void {
        try{
           if ( this.transactionJsonData ) {
            if( permissionKey === 'breadcrumb') {
                this.transactionJsonData.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach((e: BreadCrumbConfiguration)=>{
                    if ( e.DataOption && e.DataOption.length > 0 ){
                        e.DataOption.forEach((f: DataOption )=>{
                            const keyIndex: number = KeyName.findIndex((y: string ) => f.Value === y);
                            if ( keyIndex > -1 ) {
                               f.Enable = IsEnabled && rolePermission;
                            }
                        });
                    }
                     
                });
            }
           }
        } catch(error){
            console.error(error);
        }
    }



}