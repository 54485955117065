import { DisplayNameRemap } from './displayname-remap.interface';
import { FieldDisplayNameRemap } from './field-displayname-remap.interface';
import { FieldVisibleRemap } from './field-visible-remap.interface';
import { FieldTranslateDisplayRemap } from './fieldtranslate-remap.interface';
import { FromDisplayDisabledRemap } from './form-display-disabled-remap.interface';
import { FromDisplayHideRemap } from './form-display-hide-remap.interface';
import { FromDisplayNameRemap } from './form-displayname-remap.interface';

export const ProductDataRemap = {
    DisplayNameRemapEngine: DisplayNameRemap,
    FormDisplayNameRemapEngine: FromDisplayNameRemap,
    FormDisplayHideRemapEngine: FromDisplayHideRemap,
    FormDisplayDisabledRemapEngine: FromDisplayDisabledRemap,
    FieldVisibleRemapEngine: FieldVisibleRemap,
    FieldDisplayNameEngine: FieldDisplayNameRemap,
    FieldTranslateRemapEngine: FieldTranslateDisplayRemap
  };