import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
// eslint-disable-next-line
// eslint-disable-next-line max-len
import { AgilysysConfirmPostPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirm-post-popup/agilysys-confirm-post-popup.component';
import { ConfirmPostPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { transactionButton } from '../buttion-action';
import { TransactionConfiguration } from '../transaction.interface';
import * as constants from '@shared/constant/GlobalConstants';

export const InventoryResetPost = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId?: any,
    SystemPreferenceService?: SysytemPreferenceService,
    router?: ActivatedRoute): any => {
    try {

        const PostModelEndPoint = TransactionCollection.PostEntityName;
        const requetEntity = PostModelEndPoint;
        const ingredients = [];
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(x => {
            ingredients.push(x.IngredientId);
        });
        if (ingredients.length > 0) {
            const ActivitySummaryObject = {
                IngredientId: ingredients,
                LocationId: homeGroup.value.LocationId,
                PostDate: homeGroup.value.PostDate
            };
            const activitySummaryEndPoint = '/ReportData/api/IngredientActivitySummary';
            httpService.PosthttpMethod(activitySummaryEndPoint, ActivitySummaryObject).subscribe(y => {
                console.log(y);
                const postObject = populateInventoryResetHead(TransactionCollection, y, homeGroup);
                if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp &&
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.EnableConfirmationPopUp) {
                    const popupModel: ConfirmPostPopUpModel = {
                        PopupHeaderContent: 'Confirmation',
                        ModuleName: TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.Module,
                        PopUpContent: transactionButton.confirmPopUpInfo(TransactionCollection),
                        FormFields: transactionButton.ConfirmPopFormFields(TransactionCollection),
                        FormHeaderName: TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.FormHeaderName
                    };
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    const dialogRef = dialog.open(AgilysysConfirmPostPopupComponent, {
                        width: '50%',
                        height: 'auto',
                        data: popupModel,
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(res => {
                        if (res[0] === 'Confirm') {
                            console.log('Confirm', res);
                            if (postObject.PhysicalDetails.length > 0) {
                                const EndPointUrl = `/inventory/api/${requetEntity}`;
                                httpService.PosthttpMethod(EndPointUrl, postObject).subscribe(z => {
                                    if (z.hasOwnProperty('Response_Code') && z.Response_Code === 400) {
                                        console.log(z);
                                    } else {
                                        const Id = z.hasOwnProperty('Id') ? z.Id : '';
                                        const toastMessage = `Inventory Reset ${Id} is posted successfully`;
                                        const message: ToastMessage = {
                                            Message: toastMessage,
                                            Show: true,
                                            AutoClose: true,
                                            TimerCount: 20000
                                        };
                                        toastrService.success(message.Message, '', {
                                            timeOut: 3000,
                                            closeButton: true,
                                        });
                                        homeGroup.reset();
                                        homeGroup.controls['TransactionTypeId'].setValue(22);
                                        // TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
                                        //     if (x.buttonKey === 'inventoryreset') {
                                        //         x.displayProperity = true;
                                        //     }
                                        // });
                                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.
                                            AddNewListConfig.ItemAdded([], true);
                                        TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailTableCard.CollapseIconClick();                                         
                                        setTimeout(() => {
                                            const textAreaCollection = document.getElementsByTagName('textarea');
                                            if (textAreaCollection && textAreaCollection.length > 0) {
                                                for (let index = 0; index < textAreaCollection.length; index++) {
                                                    textAreaCollection[index].style.height = 'auto';
                                                }
                                            }
                                        }, 10);        
                                    }

                                });
                            }
                        }
                    });
                }
            });
        }
    } catch (error) {

    }
};

function populateInventoryResetHead(TransactionCollection, ActivityResponse, homeGroup: FormGroup) {
    const itemListObject = [];


    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(x => {
        let sumOfIngActSummaryQty = 0;
        let sumOfIngActSummaryValued = 0;
        if (ActivityResponse && ActivityResponse.length > 0) {
            const activityInventoryList = ActivityResponse.filter(y => y.IngredientId === x.IngredientId);
            if (activityInventoryList && activityInventoryList.length > 0) {
                activityInventoryList.forEach(element => {
                    if (element) {
                        sumOfIngActSummaryQty = sumOfIngActSummaryQty +
                            ((parseInt(element.PurchaseQty, 10) || 0) + (parseInt(element.ProductionQty, 10) || 0)
                                - (parseInt(element.TransferOutQty, 10) || 0)
                                + (parseInt(element.TransferInQty, 10) || 0) - (parseInt(element.TotalUsageQty, 10) || 0));

                        sumOfIngActSummaryValued = sumOfIngActSummaryValued +
                            ((parseInt(element.PurchaseValue, 10) || 0) + (parseInt(element.ProductionValue, 10) || 0)
                                - (parseInt(element.TransferOutValue, 10) || 0)
                                + (parseInt(element.TransferInValue, 10) || 0) - (parseInt(element.TotalUsageValue, 10) || 0));
                    }
                });
            }

        }
        const itemObject = {
            IngredientId: x.IngredientId,
            UnitTypeId: x.UnitTypeId,
            Qty: x.Qty === '' || x.Qty === null || x.Qty === undefined ? 0 : x.Qty,
            CurrentCost: x.UnitCost === '' || x.UnitCost === null || x.UnitCost === undefined ? 0 : x.UnitCost,
            Remark: x.Remark,
            LotQty: 0,
            UnitId: x.UnitId,
            Conversion: x.Conversion,
            SumOfIngActSummaryQty: sumOfIngActSummaryQty,
            SumOfIngActSummaryValued: sumOfIngActSummaryValued
        };
        itemListObject.push(itemObject);
    });
    // const date = new Date(homeGroup.value.PostDate);
    // const newdate = new Date();
    // let postDate: any;
    // // eslint-disable-next-line max-len
    // const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), newdate.getHours(), newdate.getMinutes(), newdate.getSeconds());
    // if (isNaN(currentDate.getTime())) {
    //   postDate = null;
    // } else {
    //   const now_utc = moment.utc(currentDate).format();
    //   postDate = (date) ? now_utc : null;
    // }
    const date = moment(homeGroup.value.PostDate);
    let postDate: any;
    if (!date.isValid()) {
      postDate = null;
    } else {
      postDate = date.startOf('day').format(constants.PostDateTimeFormat);
    }
    const headObject = {
        LocationId: homeGroup.value.LocationId,
        PostDate: postDate,
        Remark: homeGroup.value.Remark,
        TransactionTypeId: homeGroup.value.TransactionTypeId,
        PhysicalDetails: itemListObject
    };
    return headObject;
}