import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

export interface CommissionDetails {

  id?: number;
  itemId: number;
  categoryId: number;
  itemDescription: string;
  locationDescription: string;
  classId: number;
  flatAmount: any;
  percentage: any;
  threshold: any;
  isAfterDiscount: boolean;
  isTotalRevenue: boolean;
  isItemOnly: boolean;
  productId: number;
  outletId: number;
}

export interface CommissionDetailsUI {
  id?: number;
  itemId: number;
  categoryId: number;
  itemDescription: string;
  locationDescription: string;
  classId: number;
  flatAmount: any;
  percentage: any;
  threshold: any;
  isAfterDiscount: boolean;
  isTotalRevenue: boolean;
  isItemOnly: boolean;
  commissionon: string;
  outletId: number;
}

export interface popupConfig {
  operation: string;
  maxListOrder?: number;
  GridOperationType?: GridOperationType;
  fromScreen?: string;
}

export interface BaseResponse<T> {
  result: T;
  errorCode: number;
  errorDescription: string;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}

export enum GridOperationType {
  Create = 1,
  Edit,
  Delete,
  ListOrderDrag
}

export enum PMSActiviyStatus {
  Reserved = 0,
  CheckedIn,
  CheckedOut,
  NoShow,
  Cancelled,
  Unknown
}

export enum menuTypes {
  primary,
  secondary,
  tertiary,
  lowerLevel
}

export class GraphConfiguration {
  clientId: string;
  redirectUri: string;
  cacheLocation: string;
  unprotectedResources: Array<any>;
  protectedResourceMap: Array<any>;
  Scopes: Array<string>;
  tokenScope: Array<string>;
}

export interface AgContactFieldConfig {
  typeControlName?: string;
  typeClass?: string;
  className?: string;
  extensionClass?: string;
  extnPlaceHolder?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formArrayName?: string;
  formControlName?: string;
  placeHolder?: string;
  value?: string | number;
  options?: any[];
  showSwitches?: boolean;
  isPrimaryName?: string;
  isPrivateName?: string;
  emailPlaceHolder?: string;
  isEmailRequired?: boolean;
  requiredErrorMessage?: string;
  disabled?: boolean;
  automationId?: string;
}

export interface PhoneDetail {
  id: number;
  phoneType: number;
  countryCode: string | number;
  phoneNumber: string;
  extension: string;
  isPrimary: boolean;
  isPrivate: boolean;
  primaryPhone?: boolean;
  privateInformtionPhone?: boolean;
}

export interface AgContactConfig {
  typeControlName?: string;
  typeClass?: string;
  className?: string;
  extensionClass?: string;
  extnPlaceHolder?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formArrayName?: string;
  formControlName?: string;
  placeHolder?: string;
  value?: string | number;
  options?: any[];
  showSwitches?: boolean;
  isPrimaryName?: string;
  isPrivateName?: string;
  primarySwitchLabel?: string;
  privateSwitchLabel?: string;
  disabled?: boolean;
  automationId?: string;
}

export enum contactType {
  phone = 1,
  email = 2,
  office = 3
}

export enum SystemConfigKeys {
  CLIENT_PHONE = 'CLIENT_PHONE'
}
export interface SplitValue {
  equalSplit: number;
  upScaleValue: number;
}

export interface AppointmentActionFilter {
  appointmentId: number[];
  appointmentStatus: string[];
  groupId: number;
  fromDate: Date | string;
  toDate: Date | string;
}

export interface StaySearchFilter {
  searchKey: string;
  activityStartDate: string;
  activityEndDate: string;
  reservationSearchCategory: number;
  product: string;
}

export interface AppointmentFilterRequest {
  FirstName: string
  LastName: string
  ScheduleDateTime?: Date
  ConfirmationNumber: string
  BookingSource?: number
  ClientIds: number[]
}

