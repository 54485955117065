import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ButtonValue } from "src/app/common/Models/ag-models";
import { Localization } from "src/app/common/localization/localization";
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ServiceDiscountTypeBusiness } from './service-discount-type.business';

@Component({
  selector: 'app-service-discount-type',
  templateUrl: './service-discount-type.component.html',
  styleUrls: ['./service-discount-type.component.scss'],
  providers: [ServiceDiscountTypeBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ServiceDiscountTypeComponent implements OnInit {

    constructor(  private localization: Localization,private fb: UntypedFormBuilder, private business: ServiceDiscountTypeBusiness) {
      this.captions = this.localization.captions;
    }

    captions: any;
    serviceDiscountForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  serviceGroupOptions = [];
  discountTypeOptions = [];
  serviceOptions = [];
  selectedDiscounts = [];
  isSelectAll: boolean = false;
  
  ngOnInit(): void {
    this.initializeForm();
    this.getFormData();
    this.serviceDiscountForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.enableSave();
    });
  }

  initializeForm(){
   this.serviceDiscountForm = this.fb.group({
      serviceGroup: '',
      services: '',
      discountType: '',
   });
   this.actionButton = {
    type: "primary",
    label: this.captions.btn_save,
    disabledproperty: true,
  };
  this.cancelButton = {
    type: "secondary",
    label: this.captions.btn_cancel,
  };
  }

  getFormData(){
    this.serviceGroupOptions = this.business.getServiceGroupOptions();
    this.discountTypeOptions = this.business.getDiscountTypes();
    this.serviceOptions = this.business.getServiceTypes();
  }

  selectAll(event){
    this.isSelectAll = !this.isSelectAll;
    if(this.isSelectAll){
      this.discountTypeOptions.forEach(x=>x.selected = true);
    }
    else{
      this.discountTypeOptions.forEach(x=>x.selected = false);
    }
    this.selectedDiscounts = this.discountTypeOptions.filter(x=>x.selected);
    this.enableSave();
  }

  radioButtonChange(){
    console.log();
  }

  discountTypeChange(event, selectedObj){
    selectedObj.selected = event.checked;
    this.selectedDiscounts = this.discountTypeOptions.filter(x=>x.selected);
    this.isSelectAll = this.selectedDiscounts.length == this.discountTypeOptions.length;
    this.enableSave();
  }

  enableSave(){
    this.actionButton.disabledproperty = !(this.serviceDiscountForm.get('serviceGroup').value && this.serviceDiscountForm.get('services').value 
    && this.selectedDiscounts.length > 0);
  }

  onAction(e){

  }
  onCancel(e){

  }
}
