import * as _ from 'lodash';
import { Conversion } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { TransactionType, ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';


export const EstimationTotalAlgorithm: Conversion = (value: any, GridData: Array<any>, AllocateCharges: any, transactionType: string,
  MiscCharge?: any, selectedTransactionFrom?: string): any => {
  try {
      try {
        let rguestTenantId = false;
        const checkTenant = sessionStorage.getItem('RGuestTenantId');
        if (checkTenant && JSON.parse(checkTenant)) {
          rguestTenantId = true;
        }
        const decimalValue = sessionStorage.getItem('appsetting') ? JSON.parse(sessionStorage.getItem('appsetting')).DecimalValue :
        ConversionValue.ConversionTwo;
        let count = 0;
        GridData?.forEach((element: any) => {
          if (transactionType && transactionType.toLowerCase() === TransactionType.PRODUCTION.toLowerCase() ) {  // Transaction Type is enum type
            if (element.Qty && element.CurrentCost) {
              const estimationTotal = element.Qty * element.CurrentCost;
              element['Valued'] = estimationTotal ?  parseFloat(estimationTotal.toString()).toFixed(decimalValue) : 0;
              count += element.Valued ? parseFloat(element.Valued) : 0;
            }
          } else {
              if ((selectedTransactionFrom === 'processpo' || selectedTransactionFrom === 'EditPO') && !rguestTenantId) {
                count += element.ValueInForeignCurrency ? parseFloat(element.ValueInForeignCurrency.toString()) : 0;
              } else {
                if (element.Qty && element.UnitCost) {
                  count += element.Valued ? parseFloat(element.Valued) : 0;
                }
              }
            }
          });
          return count;
        } catch (Error) {
        console.error('Error occurred in Estimation Total Calculation ', Error);
      }
  } catch (error) {
    console.log('Error occured Conversion Algorithm ', error);
  }
};
