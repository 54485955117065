import { UserSessionConfiguration } from "../shared/retail.modals";
import { CardInfo } from "../shared/shared/business/shared.modals";
import { IntegrationOperationsTransactionType, Voucher } from "src/app/common/Models/common.models";
import { GiftCardTransactionType } from "../constants";

export interface GatewayConfiguration {
    id?: number;
    payAgentURL: string;
    payGatewayID: string;
    isInterfaceActive: boolean;
    isPartialPayAllowed: boolean;
    isAVSActive: boolean;
    gatewayValues: GatewayValues[];
    type: number;
    cardInputMethod?: CardInputMethod;
    preferredCardEntryMode?: CardInputMethod;
    showCreditCardExpDate: boolean;
}


export enum CardInputMethod {
    None,
    All = 1,
    KeyIn,
    SwipeInsertTap
}

export interface GatewayValues {
    configurationId: number;
    configKey: string;
    configValue: string;
}

export interface GiftCardConfiguration {
    id: number;
    activateGiftCardInterface: boolean;
    allowExpiration: boolean;
    expireDays: number;
    giftCardType: GiftCardType;
    allowCashback: boolean;
    retailItemId: number;
    giftCardDefaultValues: GiftCardDefaultValues[];
}

export interface GiftCardDefaultValues {
    id: number;
    giftCardConfigurationId: number;
    amount: number;
}

export const enum GiftCardType {
    V1GiftCard = 2,
    ExternalGiftCard = 4
}

export interface GiftCardConfigurationStore {
    activateGiftCardInterface: boolean;
    giftCardType: GiftCardType;
}


export namespace UI {
    export interface CreditCardConfiguration {
      id ?:number;
      activateAllPABPRecommendation: boolean
      alwaysRequestSwipeAtCheckIn: boolean
      automaticallyDeferCCAdvanceDepositPost: boolean
      batchWaitTimeSec:number
      enableActivateAVS: boolean
      enableActivateInterface: boolean
      enableCCTracking: boolean
      enableChipAndPin: boolean
      enableChipPinTest: boolean
      enableHouseNumber: boolean
      enablePartialPayment: boolean
      enablePrintCCAuthorization: boolean
      enableRequestCardSwipe: boolean
      incrementalAuthWhenNotSupported: boolean
      noOfSvcReceipts:number
      pbfeNetpfDelay:number
      purgeArchivedCCDaysAfterCO:number
      purgeBookingCCDaysAfterCO:number
      purgeGuestCCDaysAfterCO: number
      purgePostingCCDaysAfterCO:number
      purgeStayCCDaysAfterCO: number
      rGuestPayAgentURL: string
      rGuestPayGatewayID: string
      setCCOverAuthAdjustThreshold:number |string
      skipCCFauxNoPurgeDuringZOut: boolean
      skipDecreaseAuth: boolean
      GatewayPairs : GateWayValues[];
      gatewayId :number;
      defaultConfigValue? :string;
      enableCCAuthLimit : boolean;
      showCreditCardExpDate: boolean;
    }
    
    export interface GateWayValues{
      gateway_value : string;
      gateway_key : string;
      gateway_index : number;
    }
  }

  
export interface GiftCardPMRequest {
    handle:       string;
    Amount:       BalanceAmount;
    InquirerInfo: InquirerInfo;
}

export interface BalanceAmount {
    RequestAmount: string;
}

export interface InquirerInfo {
    TenderId:     string;
    TerminalId:   string;
    OrderNumber:  string;
    Profitcenter: string;
    ZoneOffset:   string;
    id?: string;
    EmployeeId?: string;
    customer?: string;
    doActivateOnly?: boolean;
}



/**
 * contains the interfaces related to Payment requests
 */

//-----------------------------------------------------------------------------------------
//Request Model for Auth/Sale:

export interface SaleRequest {
    handle: string; //(Required)(Encrypted data)
    amount: Amount; //(Required)
    inquirerInfo: InquirerInformation; //(Required)

    originalTenderId?: number;
    requestId?: string;
    transactionType?: IntegrationOperationsTransactionType;
    transactionAmount?: Number;
    transactionId?: number;
    ticketNumber?: number;
    paymentMethodId?: number;
    paymentMethodTableId?: number;
    paymentTypeId?: number;
    parentTypeId?: number;
    roomNumber?: string;
    roomReference?: string;
    guestName?: string;
    paymentReferenceComments?: string;
    transactionPaymentId?: number;  
    multiPMSPropertyIndex?: string;
    multiPropertyPropCode?: string;
    pMSIntegrationHostId?: string;
    postingRecords?: any[]
    multiPropertyFeatureConfigurations?: any[];
    paymentMethodAdditionalConfigurations?: any[];
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    paymentDetails?: PaymentDetails;
}

export interface PaymentDetails {
    paymentMethodId: number;
    paymentMethod: string;
    parentTenderId: number;
    issuerType?: string;
    cardNumber?: string;
    entryMode?: string;
    amount: number;
    patronId?: string;
    cMSType?: number;
    offerName?: string;
    paymentReferenceId?: number;
    vendorName?: string;
    aRAccountNumber?: string;
    aRAccountName?: string;
    redemptionType?: number;
    roomNumber?: string;
    referenceComment?: string;
    disableDelete?: boolean;
    giftcardTransactionType?: GiftCardTransactionType;
    cardType?: string;
    reservationName?: string;
    paymentMethodMasterId?: number;
    folioInvoiceNumber?: string;
    walletNumber?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
}

export interface Amount {
    requestAmount: number; // (Required)
    authorisedAmount?: number;
    tipAmount?: number;
    taxAmount?: number;
    saleAmount?: number;
}

export interface InquirerInformation {
    terminalId: string; // (Required)
    store?: string;
    division?: string;
    enterprise?: string;
    customer?: string;
    mealPeriod?: string;
    coverCount?: string;
    orderType?: string;
    orderNumber: string; // (Required)
    employeeId?: string;
    profitCenter: string; //(Required)
    tenderId: string; //(Required)
    zipcode?: string;
    isPartialTenderAllowed: boolean;
    financialBins?: Array<FinancialBin>;
    sourceTypeId?: string;
    manualCardEntry?: boolean;
}

export interface FinancialBin {
    Id: string;
    type: string;
    amt: string;
}

//-----------------------------------------------------------------------------------------
//Response Model for Auth/Sale:

export interface SaleResponse {
    payAgentId: string;
    status: string;
    account: DisplayInformation;
    amount: Amount;
    transactionDetails: string; //(Encrypted)
    transactionKey: string;
    errorMessage: string;
    cardInfo: PayAgentCardInfo;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
}

export interface DisplayInformation {
    id: string;
    name: string;
    type: string;
}

export interface PayAgentCardInfo {
    entryMode: string;
    issuer: string;
    cardExpiration: string;
    cardType: string;
    token: string;
}


export interface ValidateAuthRequest {
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    cardInfo: CardInfo;
    amount: number;
    sourceType: string;
    sourceTypeId: string;
    roomNumber: string;
    folioNumber: string;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
}

export interface PayAgentResponse {
    payAgentID: string,
    status: string,
    transactionDetails: string,
    transactionKey: string,
    errorMessage?: string
}


export interface CardEntryModeDialogResult {
    action: CardEntryModeDialogAction;
    cardInputMethod?: CardInputMethod;
}

export enum CardEntryModeDialogAction {
    Proceed = 1,
    Cancel
}

export interface ValidatePayResponse {
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    paymentManagerResponse: PMPayResponse;

}
export interface ValidateAuthResponse extends ValidatePayResponse{}

export interface PMPayResponse {
    status?: string;
    transactionId?: string;
    transactionTime: Date | string;
    amount: Amount;
    inquirerInfo: InquirerInformation;
    clientInfo?: ClientInfo;
    errorMessage?: string;
    totalAuthorizedAmount: number;
}

export interface ClientInfo {
    accountName: string;
    accountNumber: string;
    authCode: string;
    roomNumber: string;
}

export interface AuthInformation {
    paymentTransactionRefId: number,
    checkNumber: string,
    outletId: number,
    PaymetRefrenceId: number,
    ProfitCenter: string,
    authAmount: number,
    paymentMethod: string,
    terminalId: string,
    sourceType: number,
    sourceTypeId: string,
    folioNumber: string,
    roomNumber: string,
    zipcode ?: string
    isPartialPayAllowed : boolean;
}


export interface AuthRequest {
    handle: string; //(Required)(Encrypted data)
    amount: Amount; //(Required)
    inquirerInfo: InquirerInformation; //(Required)
}

export interface Amount {
    requestAmount: number; // (Required)
    authorisedAmount?: number;
    tipAmount?: number;
    taxAmount?: number;
    saleAmount?: number;
}

export const HttpResponseStatus = {
    Success: "success",
    Failure: "failure"
}

export interface ValidatePayRequest {
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    cardInfo: CardInfo;
    amount: number;
    aCESPaymentRecord: ACESPaymentRecord;
    cMSPaymentRecord: CMSPaymentDetails;
    giftcardPaymentRecord: GiftCardPaymentRecord;
    aRPostingPaymentRecord: ARPostPaymentResponse;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
}

export interface GiftCardPaymentRecord {
    id?: number;
    paymentTransactionId?: number;
    amount: number;
    type: number;
    isVoided: boolean;
    serviceManagerReferenceId?: number;
    firstName?: string;
    lastName?: string;
    cardNumber: string;
    expiryDate: string;
    isLoaded?: boolean;
    loadedDate?: string;
    isRedeemed?: boolean;
    redemptionDate?: string;
    isIssued?: boolean;
    issuedDate?: string;
    productId?: number;
    referenceId: string;
}

export interface CMSPaymentDetails {
    id?: number;
    paymentTransactionId?: number;
    patronId: string;
    patronName: string;
    amount: number;
    redemptionType: CMSRedemptionType;
    name: string;
    voucherId: string;
    voucherType: string;
    voucherExpirationDate: Date | string;
    voucherRedeemTransactionId: string;
    isVoided?: boolean;
}

export enum CMSRedemptionType {
    Points = 1,
    Comps,
    Offers
}

export interface FolioUserSessionConfiguration extends UserSessionConfiguration {    
    subPropertyId: number;
}

export enum SettingModule {
    SystemSetup = 'SYSTEMSETUP'
  }
  
export enum SettingScreen {
  CreditCardConfiguration = 'CCCONFIGURATION',
  IncidentalSettings = 'INCIDENTALSETTINGS'
}

export interface ARPostPaymentResponse {
    postingStatus: string;
    postingMessage: string;
    amount: number;
    profitCenter: string;
    checkNumber: string;
    financialBins: ARFinancialBin[];
    accountNumber: string;
    accountName: string;
}

export interface ARFinancialBin {
    classId: string;
    typeId: number;
    amount: number;
}

export interface ACESPaymentRecord {
    paymentTransactionId: number,
    transactionId: string,
    acesTransactionid: string,
    memberId: number,
    tenderType: string,
    amount: number,
    paymentMethodType: number,
    memberPin: string
  }

export interface AuthResponse {
    payAgentId: string;
    status: string;
    account: DisplayInformation;
    amount: Amount;
    transactionDetails: string; //(Encrypted)
    transactionKey: string;
    errorMessage: string;
    cardInfo: PayAgentCardInfo;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
}

export interface InquirerInformation {
    terminalId: string; // (Required)
    store?: string;
    division?: string;
    enterprise?: string;
    customer?: string;
    mealPeriod?: string;
    coverCount?: string;
    orderType?: string;
    orderNumber: string; // (Required)
    employeeId?: string;
    profitCenter: string; //(Required)
    tenderId: string; //(Required)
    zipcode?: string;
    isPartialTenderAllowed: boolean;
    financialBins?: Array<FinancialBin>
    sourcetypeid?:string
}

export interface CreditCardConfiguration {
    id?: number,
    configurations: GatewayConfiguration[],
    enableCCTracking: boolean,
    enableRequestCardSwipe: boolean,
    enablePrintCCAuthorization: boolean,
    automaticallyDeferCCAdvanceDepositPost: boolean,
    alwaysRequestSwipeAtCheckIn: boolean,
    enableHouseNumber: boolean,
    enableChipAndPin: boolean,
    enableChipPinTest: boolean,
    skipDecreaseAuth: boolean,
    incrementalAuthWhenNotSupported: boolean,
    noOfSvcReceipts: number,
    batchWaitTimeSec: number,
    pbfeNetpfDelay: number,
    setCCOverAuthAdjustThreshold: number,
    activateAllPABPRecommendation: boolean,
    purgeStayCCDaysAfterCO: number,
    purgeArchivedCCDaysAfterCO: number,
    purgeBookingCCDaysAfterCO: number,
    purgePostingCCDaysAfterCO: number,
    purgeGuestCCDaysAfterCO :number,
    skipCCFauxNoPurgeDuringZOut: boolean,
    enablePartialPayment : boolean,
    enableActivateAVS : boolean,
    enableActivateInterface : boolean
  }
  
  export interface GatewayConfiguration {
    id?: number,
    payAgentURL: string,
    payGatewayID: string,
    isInterfaceActive: boolean
    isPartialPayAllowed: boolean
    isAVSActive: boolean,
    gatewayValues: GatewayValues[],
    type: number
  }
  
  export interface GatewayValues {
    configurationId: number,
    configKey: string,
    configValue: string,    
  }
