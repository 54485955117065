import { AfterViewInit, OnChanges, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

@Directive({
  selector: '[appMatTabHide]'
})
export class MatTabHideDirective implements AfterViewInit, OnChanges {
  private subFormData: SubFormConfigs;
  index: any;
  matTabGroup: MatTabGroup;
  isPunchoutEnabled: boolean;
  vendorEnabled: boolean;
  @Input('appMatTabHide') set setLocalValues(tabValues: SubFormConfigs) {
    this.subFormData = tabValues[0];
    this.index = tabValues[1];
    this.matTabGroup = tabValues[2];
    this.isPunchoutEnabled = tabValues[3];
    this.vendorEnabled = tabValues[4];
  }
  constructor() {
  }

  ngOnChanges(): void {
    this.hideTab();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.hideTab();
    }, 100);
  }

  hideTab() {
    // console.log('Subform', this.subFormData);
      this.subFormData.Enable = this.subFormData.OdataKey === 'PunchoutConfiguration' ?
         this.isPunchoutEnabled && this.vendorEnabled : this.subFormData.Enable;
      const checkEnable = this.subFormData.RoutingLinkUrl || !this.subFormData.Enable || this.subFormData.ShowInEllipsis
        ? true : false;
        const matTabGroupElement = document.getElementsByClassName('inventory-sub-form-groups')[0] as HTMLElement;
      if (checkEnable) {
        if (matTabGroupElement.getElementsByClassName('mat-tab-label')[this.index]) {
        matTabGroupElement.getElementsByClassName('mat-tab-label')[this.index].classList.add('display-none');
        }
        if (matTabGroupElement.getElementsByClassName('mat-tab-body')[this.index]) {
        matTabGroupElement.getElementsByClassName('mat-tab-body')[this.index].classList.add('display-none');
      }      
    } else {
        if (matTabGroupElement.getElementsByClassName('mat-tab-label')[this.index]) {
        matTabGroupElement.getElementsByClassName('mat-tab-label')[this.index].classList.remove('display-none');
        }
        if (matTabGroupElement.getElementsByClassName('mat-tab-body')[this.index]) {
        matTabGroupElement.getElementsByClassName('mat-tab-body')[this.index].classList.remove('display-none');
      }      
    }    
  }
}
