import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommissionDetails, CommissionDetailsUI, BaseResponse, popupConfig  } from '../shared.modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import { Host, PromptType, ButtonOptions } from '../globalsContant';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SpaUtilities } from '../utilities/spa-utilities';
import { UserAlerts } from '../../core/config/alerts-config';
@Component({
  selector: 'app-commission-values',
  templateUrl: './commission-values.component.html',
  styleUrls: ['./commission-values.component.scss']
})
export class CommissionValuesComponent implements OnInit {
  @Input() dataInput;
  @Input() popupConfigs: popupConfig;
  clickbutton: string;
  FormGrp: UntypedFormGroup;
  localize: any;
  captions: any;
  fromScreen: string;
  private commissionData: any;
  activeFormGroup: UntypedFormGroup;
  floatLabel:string;

  constructor(public dialogRef: MatDialogRef<CommissionValuesComponent>,
    private Form: UntypedFormBuilder,
    private http: HttpServiceCall,
    public localization: SpaLocalization,
    private utilities: SpaUtilities,
    private userAlerts: UserAlerts,) {
    this.FormGrp = this.Form.group({
      itemDescription: ['', Validators.required],
      locationDescription: ['', Validators.required],
      threshold: ['', Validators.required],
      flatAmount: ['', Validators.required],
      percentage: ['', Validators.required],
      commissionon: ['', Validators.required],
      isAfterDiscount: ['', Validators.required]
    });
    this.captions = this.localization.captions;
    this.activeFormGroup = this.FormGrp;
    this.floatLabel = this.localization.setFloatLabel;
  }
  ngOnInit() {
    this.clickbutton = this.popupConfigs.operation == "new" ? this.captions.setting.save : this.captions.setting.update;
    this.fromScreen = this.popupConfigs.fromScreen;
    if (typeof this.dataInput !== 'undefined') {
      this.dataInput.flatAmount = parseFloat(this.dataInput.flatAmount as string);
      this.dataInput.threshold = parseFloat(this.dataInput.threshold as string);
      this.dataInput.percentage = parseFloat(this.dataInput.percentage as string);
      this.FormGrp.patchValue(this.dataInput);
      this.commissionData = this.dataInput as CommissionDetailsUI;
    }
  }
  cancel(): void {
    this.promptUserDialog(this.dialogRef);
  }

  /**
   * This is prompt for unsaved changes.
   * will happen only if the form is dirty.
   * @param Dialog Current active Dialog reference.
   */
  promptUserDialog(Dialog: any) {
    if (this.activeFormGroup.dirty) {
      this.userAlerts.showPrompt(PromptType.UnsavedChanges, this.PopupCallback.bind(this), Dialog)
    } else {
      Dialog.close();
    }
  }

  private PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == ButtonOptions.No.toLocaleLowerCase()) {
      extraParams.close();
    }
  }

  UpdateCommissions() {
    let bodyObj: CommissionDetails;
    const bodyObjArr: CommissionDetails[] = [];

    let flatAmount: any = this.FormGrp.controls["flatAmount"].value;
    let percentage: any = this.FormGrp.controls["percentage"].value;
    let threshold: any = this.FormGrp.controls["threshold"].value;

    bodyObj = {
      id: this.commissionData.id,
      itemId: this.commissionData.itemId,
      categoryId: this.commissionData.categoryId,
      itemDescription: this.commissionData.itemDescription,
      locationDescription: this.commissionData.locationDescription,
      classId: this.commissionData.classId,
      flatAmount: isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount,
      percentage: isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage,
      threshold: isNaN(threshold) ? this.localization.currencyToSQLFormat(threshold) : threshold,
      isAfterDiscount: this.FormGrp.controls["isAfterDiscount"].value,
      isTotalRevenue: this.FormGrp.controls["commissionon"].value == this.captions.setting.Revenue ? true : false,
      isItemOnly: this.FormGrp.controls["commissionon"].value == this.captions.setting.Items ? true : false,
      productId: Number(this.utilities.GetPropertyInfo('ProductId')),
      outletId : this.commissionData.outletId
    };

    bodyObjArr.push(bodyObj);
    if (this.fromScreen == 'commission') {
      this.http.CallApiWithCallback<any[]>({
        host: Host.commission,
        success: this.successCallback.bind(this),
        error: this.utilities.errorCallback.bind(this),
        callDesc: "UpdateCommission",
        uriParams: { classId: bodyObj.classId },
        method: HttpMethod.Put,
        body: bodyObjArr,
        showError: true,
        extraParams: []
      });
    } else if (this.fromScreen == 'therapist') {
      this.dialogRef.close({ action: this.popupConfigs.operation, result: bodyObj });
    }

  }

  KeyDownEvent(e, type) {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { // reset only when entered values is numeric
      let defaultvalue = this.localization.localizeCurrency("0", false);
      if (type == "flat") {
        this.FormGrp.get('percentage').setValue(defaultvalue);
      } else if (type == "percentage") {
        this.FormGrp.get('flatAmount').setValue(defaultvalue);
      }
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    if (callDesc == "UpdateCommission") {
      const response = result.result;
      this.dialogRef.close(response);
    }
  }
}
