import { Component } from '@angular/core';
import * as DecoupledEditor from '../../ckeditor5-build-decoupled-document-master/build/ckeditor';
@Component({
  selector: 'app-document-editor',
  templateUrl: './document-editor.component.html'  
})
export class DocumentEditorComponent  {
  public Editor = DecoupledEditor;
  public Config = { toolbar: [ 'bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize', 'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList', 'bulletedList', 'outdent', 'indent', 'link', 'imageUpload', 'undo', 'redo' ] };

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
}
}
