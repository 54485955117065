import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UILiteral,MenuEditObj, Languages, EntityElement } from './menu-edit.model';
import { EditMenuPopupBusiness } from './edit-menu-popup.business';
import { MenuObj } from 'src/app/common/components/menu/menu.model';
import { Localization } from 'src/app/common/shared/localization/Localization';

@Component({
  selector: 'app-edit-menu-popup',
  templateUrl: './edit-menu-popup.component.html',
  styleUrls: ['./edit-menu-popup.component.scss'],
  providers: [EditMenuPopupBusiness]
})
export class EditMenuPopupComponent implements OnInit {
  captions: any;
  editMenuForm: UntypedFormGroup;
  isDisabled = true;
  menuDetails: any;
  originalValue: string | number;
  literals: MenuEditObj[] = [];
  menuCollections: MenuObj[] = [];
  langList: Languages[];
  formPatchvalue = {};
  Userlang: string;
  floatLabel: string;

  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<EditMenuPopupComponent>,
    private localize: Localization, private _editmenupopupbusiness: EditMenuPopupBusiness) {
    this.menuDetails = data.menuDetails;
    this.originalValue = data.menuDetails.value;
    this.Userlang = this.localize.GetPropertyInfo('UserLanguage');
    this.getLanguage();
    this.floatLabel = this.localize.setFloatLabel;
  }

  ngOnInit() {
    this.captions = this.localize.captions.common;
    this.buildForm();
  }

  buildForm() {
    this.editMenuForm = this.fb.group({
      originalValue: [this.originalValue],
      newValue: ['', Validators.required]
    });
    this.editMenuForm.valueChanges.subscribe((x) => {
      this.isDisabled = !(this.editMenuForm.valid && this.editMenuForm.dirty && x.originalValue !== x.newValue.trim());
    });
  }

  async getLanguage() {
    this.langList = await this._editmenupopupbusiness.getLanguages();
  }

  async SaveLiteral() {
    let entityElements: EntityElement[] = await this._editmenupopupbusiness.getEntityElementsByLiteralId(this.menuDetails.textID);

    if (entityElements) {
      if (entityElements.length > 1) {
        let existingLiterals: UILiteral[] = await this._editmenupopupbusiness.getAllLiteralsByLiteralId(this.menuDetails.textID)
        let newLiteralId = await this.CreateLiteral(existingLiterals)
        //update entity element data with new literalId
        if (newLiteralId > 0) {
          let entelement: EntityElement;
          entityElements.forEach(x => { if (x.id == this.menuDetails["EntityElementID"]) entelement = x; });
          entelement.customisedTextID = newLiteralId;
          let menu: MenuObj = this._editmenupopupbusiness.MapEntityElementToMenu(entelement);
          this._editmenupopupbusiness.updateEntityElement(menu);
        }
      }
      else
        await this.UpdateLiteral()
    }
    this.dialogRef.close();
  }

  async CreateLiteral(existingLiterals: UILiteral[]): Promise<number> {
    let LanguageID = this.langList.find(x => x.sub == this.Userlang).id
    return this._editmenupopupbusiness.saveLiterals(existingLiterals, LanguageID, this.editMenuForm.value["newValue"]);
  }

  async UpdateLiteral() {
    let updatedText: any[] = [];
    this.formPatchvalue[this.Userlang] = this.editMenuForm.value["newValue"];
    updatedText.push(this.formPatchvalue);
    await this._editmenupopupbusiness.UpdateLiterals(updatedText[0], this.langList, this.menuDetails.textID)
  }

  Cancel() {
    this.dialogRef.close();
  }
}
