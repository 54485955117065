import { Injectable } from '@angular/core';
import { ReservationCommunication } from 'src/app/common/communication/services/reservationcommunication.service';
import { CommonApiRoutes } from '../common-route';
import { Guest, StayGuestOccupanyInfo } from '../Models/stayguestoccupanyinfo.model';
@Injectable(
    {
        providedIn: 'root'
    }
)
export class ReservationActionDataService {

    constructor(private _v1ReservationCommunication: ReservationCommunication) {
    }


    public getStayContactInfo(stayId: number): Promise<StayGuestOccupanyInfo[]> {
        const result = this._v1ReservationCommunication.getPromise<StayGuestOccupanyInfo[]>(
            { route: CommonApiRoutes.GetStayContactInfo, uriParams: { "stayId": stayId } });
        return result;
    }

    public getGuestInformationById(id: number) {
        const result = this._v1ReservationCommunication.getPromise<Guest>(
            { route: CommonApiRoutes.GetGuestInformationById, uriParams: { id } }, true);
        return result;
    }


}