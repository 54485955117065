import { S } from '@angular/cdk/keycodes';
import { Component, OnInit, Output, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer, UntypedFormControl } from '@angular/forms';
import { Product } from 'src/app/retail/shared/globalsContant';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';
import { ReportBusinessService } from '../../../business/report-business.service';
import { DropDownData } from '../../../business/report.modals';
import { ReportDataService } from '../../../data/report-data.services';

@Component({
  selector: 'app-retail-vendor-inventory-detail',
  templateUrl: './vendor-inventory-detail.component.html',
  styleUrls: ['./vendor-inventory-detail.component.scss']
})
export class RetailVendorInventoryDetailComponent implements OnInit {
  VendorInventoryDetailFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  vendorInfo: DropDownData[] = [];
  public captions: any = this.localization.captions.reports;
  productId: number;
  hideDisposableItem: boolean;
  radioFilter: { id: number; name: string; }[];
  disposableItemsValue: boolean;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  editPatchValue: any;
  isFromEdit = false;
  isFromJobScheduler: boolean = false;
  categoryComponentFilter: any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private dataService: ReportDataService, 
    private controlContainer: ControlContainer, public business: ReportBusinessService, private container: ViewContainerRef) {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.hideDisposableItem = this.productId == Product.PMS ? false : true;
  }

  ngOnInit() {
    this.radioFilter = [{ id: 0, name: this.captions.AllActiveitems }, { id: 1, name: this.captions.SuppressZeroQtyItems }];
    this.VendorInventoryDetailFormGrp = this.fb.group({
      lostCostMethod: false,
      orderBySupplierItem: false,
      itemFilter: 0,
      category: new UntypedFormGroup({}),
      vendorInfo: new UntypedFormControl([this.defaultData]),
      DisposableItems: this.disposableItemsValue == null ? false : this.disposableItemsValue
    });
    this.formReady.emit(this.VendorInventoryDetailFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit){
        this.categoryComponentFilter =
        {
          category: _parent.editableValue.categoryIds,
          subCategory1: _parent.editableValue.subCategory1Ids,
          subCategory2: _parent.editableValue.subCategory2Ids,
          subCategory3: _parent.editableValue.subCategory3Ids,
          subCategory4: _parent.editableValue.subCategory4Ids,
          subCategory5: _parent.editableValue.subCategory5Ids
        };
        this.PatchSchedulercontrols(_parent.edit);
      }
    }
  }

  private PatchSchedulercontrols(isEdit) {
    if (this.localization.isFromJobScheduler && isEdit) {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      if(patchItem.vendorIds != undefined){
        this.dataService.getAllVendor().then(res => {
            let arr = res.filter((item) => {
              return this.editPatchValue.vendorIds.find(x => x == item.id);
          });
          this.vendorInfo = arr;
          this.business.FilterDataSource["allVendors"] = this.vendorInfo.filter(u => u.isActive);
          this.VendorInventoryDetailFormGrp.controls['vendors'].setValue(this.vendorInfo);
        });
      }
      setTimeout(() => {
        if (patchItem.lostCostMethod != undefined)
          this.VendorInventoryDetailFormGrp.controls.lostCostMethod.patchValue(patchItem.lostCostMethod);
        if (patchItem.lostCostMethod != undefined)
          this.VendorInventoryDetailFormGrp.controls.lostCostMethod.patchValue(patchItem.lostCostMethod);

        if (patchItem.categoryView != undefined)
          this.VendorInventoryDetailFormGrp.controls.categoryView.patchValue(patchItem.categoryView);

        if (patchItem.DisposableItems != undefined)
          this.VendorInventoryDetailFormGrp.controls.DisposableItems.patchValue(patchItem.DisposableItems);

        if (patchItem.itemFilter != undefined){
          var itemFilter = patchItem.itemFilter == "true" ? 1 : 0;
          this.VendorInventoryDetailFormGrp.controls.itemFilter.patchValue(itemFilter);
        }           
      }, 1);
    }
  }
  private async onLoad() {
    this.vendorInfo = await this.dataService.getAllVendor();
    this.business.FilterDataSource['allVendors'] = this.vendorInfo.filter(u => u.isActive);
  }
  toggleDisposableItems(event) {
    this.disposableItemsValue = event;
  }

  /**
  * After a form is initialized, we link it to our main form
  */
  formInitialized(form: UntypedFormGroup) {
    this.VendorInventoryDetailFormGrp.setControl('category', form);
  }

  getFormControlValue(event) {
    if (event[0] === 'vendorInfo') {
      this.VendorInventoryDetailFormGrp.setControl('vendorInfo', event[1]);
    }  
  }
  IsSelectionValid(validationObject: boolean) {
    if(this.VendorInventoryDetailFormGrp.controls['vendorInfo'] && this.VendorInventoryDetailFormGrp.controls['vendorInfo'].value.length == 0 
    ||!this.VendorInventoryDetailFormGrp.controls['vendorInfo'])
    {
      this.IsValidSelection.emit({ dropDownName: this.captions.PleaseSelectVendor, validity: false });
    }
    this.IsValidSelection.emit({ dropDownName: '', validity: true });
  }
}
