import { Component, OnInit, Output, EventEmitter,ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-inventory-wash',
  templateUrl: './inventory-wash.component.html',
  styleUrls: ['./inventory-wash.component.scss']
})
export class RetailInventoryWashComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  InventoryWashFormGrp: UntypedFormGroup;
  isFromJobScheduler: boolean = false;
  editPatchValue: any;
  isFromEdit = false;
  categoryComponentFilter: any;
  public captions: any = this.localization.captions.reports; 
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization,private container: ViewContainerRef) { }

  ngOnInit() { 
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
    this.InventoryWashFormGrp = this.fb.group({
      category: new UntypedFormGroup({})
    });
    this.formReady.emit(this.InventoryWashFormGrp);
    if (this.container) 
    {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;

      if(this.editPatchValue && this.isFromJobScheduler)
      {
        this.isFromEdit = true;
        this.categoryComponentFilter =
        {
          category: _parent.editableValue.CategoryIds,
          subCategory1: _parent.editableValue.SubCategory1Ids,
          subCategory2: _parent.editableValue.SubCategory2Ids,
          subCategory3: _parent.editableValue.SubCategory3Ids,
          subCategory4: _parent.editableValue.SubCategory4Ids,
          subCategory5: _parent.editableValue.SubCategory5Ids
        };          
      }     
    
    }
  }



  /**
  * After a form is initialized, we link it to our main form
  */
  formInitialized(form: UntypedFormGroup) {
    this.InventoryWashFormGrp.setControl('category', form);
  }
}
