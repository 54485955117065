import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup, MatTabChangeEvent, MatTabHeader, MatTab } from '@angular/material/tabs';
import { SPAConfig } from '../../core/config/SPA-config';
import * as GlobalConst from '../../shared/globalsContant';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { SettingsService } from '../settings.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'view-setting',
  templateUrl: './view-settings.component.html',
  styleUrls: ['./view-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewSettingComponent implements OnInit {
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  selectedTabIndex = 0;
  selectedIndex = 0;
  locObj: any = {};
  appSetUpShowArray: Array<any>;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  showLoader: boolean = false;
  currentTabId: number;

  constructor(public dialog: MatDialog, public spaConfig: SPAConfig,
    private BPoint: BreakPointAccess, private PropertyInfo: SpaPropertyInformation, private ss: SettingsService) {
      this.ss.tabLoaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader=>{
       this.ToggleLoader(loader);
      });
      this.appSetUpShowArray = ['appsetup', 'staffschedule', 'commisionsetup', 'packageyielding', 'serviceyielding', 'utilities', 'retail', 'inventory', 'systemconfig', 'userconfig'];
  }

  handleSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    this.selectedIndex = event.index;
  }

  ToggleLoader(loader){
    const loadingContainer = document.getElementById('cover-spin');
        if (loadingContainer) {
          if (loader) {
            loadingContainer.style.display = 'block';
          }
          else {
            loadingContainer.style.display = 'none';
          }
        }
  }

  HandleTabClick(tab: MatTab, tabHeader: MatTabHeader, id: number): boolean {
    if(this.currentTabId == id){
      return;
    }
    this.currentTabId = id;
    this.ss.tabLoaderEnable.next(true);
    if (id === this.appSetUpShowArray.indexOf('commisionsetup') && !this.BPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.CommissionClassSetup])) {
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    if (id === this.appSetUpShowArray.indexOf('staffschedule') && !this.BPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.StaffSchedule])) {
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    if (id === this.appSetUpShowArray.indexOf('packageyielding') && !this.BPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingPackageYielding])) {
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    if (id === this.appSetUpShowArray.indexOf('serviceyielding') && !this.BPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingServiceYielding])) {
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    if (id === this.appSetUpShowArray.indexOf('inventory') && !this.BPoint.CheckForAccess([GlobalConst.InventoryBreakPoint.InventoryManagement])) {
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    if (id === this.appSetUpShowArray.indexOf('systemconfig') && !this.BPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings])) {
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }
    return true && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
  }
  /**
   * Intial Loader
   */

  ngOnInit() {
    this.tabGroup._handleClick = this.HandleTabClick.bind(this);
    this.spaConfig.load();
    this.locObj = this.spaConfig.captions.setting;
  }

  // since retail table is not in the same setting module,
  // we are using event handler to show or hide the loader
  loaderHandler(event){
    this.ToggleLoader(event);
  }

  ngOnDestroy(){
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

}
