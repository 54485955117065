import { Directive, ElementRef, HostListener } from '@angular/core';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Directive({
    selector: '[numFormat]'
})
export class NumFormatDirective {
    public numberValue;
    constructor(el: ElementRef, private localization: SpaLocalization) {

        this.numberValue = el.nativeElement;
    }

    @HostListener('keydown', ['$event']) keydown($event: KeyboardEvent) {
        const arrayKeys: any = [9, 27, 13, 17, 86];
        const server = arrayKeys.find(x => x == $event.keyCode);
        if (server ||
            (($event.keyCode == 65 || $event.keyCode == 86 || $event.keyCode == 67) && ($event.ctrlKey === true || $event.metaKey === true))) {
            $event.preventDefault();
        }

        if (this.doNotAllowKey(this.numberValue.value) && (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105))) {
            $event.preventDefault();
        }
    }

    doNotAllowKey(value: any): boolean {
        let donotAllow = false;
        value = value.toString().replace("-", "");
        if (value.indexOf(this.localization.decimalSeparator) == -1) {
            donotAllow = value.length == 4
        } else {
            const splitArray = value.split(this.localization.decimalSeparator);
            donotAllow = splitArray[0].length == 4 && splitArray[1].length == 2;
        }
        return donotAllow;
    }
}