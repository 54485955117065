import * as _ from 'lodash';
import { Conversion } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { ConversionAlgorithm } from '.';
import { TransactionType, ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';

export const AllocateChargesAlgorithm: Conversion = (value: any, GridData: Array<any>, AllocateCharges: any, transactionType: string,
   MiscCharges: any, selectedTransactionFrom: string): any => {
  try {
    try {
      let count = 0;
      let rguestTenantId = false;
      const checkTenant = sessionStorage.getItem('RGuestTenantId');
      if (checkTenant && JSON.parse(checkTenant)) {
        rguestTenantId = true;
      }
      const decimalValue = sessionStorage.getItem('appsetting') ? JSON.parse(sessionStorage.getItem('appsetting')).DecimalValue :
      ConversionValue.ConversionThree; // To get the decimal precision from session
        let EstimateTotal = '0';
        let AllocateTotal = '0';
        let AllocatedCharges = 0;
        EstimateTotal = ConversionAlgorithm.miscTotal(value, GridData, null, transactionType, MiscCharges, selectedTransactionFrom); 
         // To calculate estimation and misc by calling
        // misctotal interface
        // if (transactionType !== TransactionType.APVOUCHER) {  // Transaction Type is enum type
            GridData.forEach((ele: any) => {
              if (ele.hasOwnProperty('AllocatedCharges') && ele.AllocatedCharges !== '') {   // AllocatedCharges is present in PO
                if ((selectedTransactionFrom === 'processpo' || selectedTransactionFrom === 'EditPO') && !rguestTenantId) {
                  AllocatedCharges += ele.ChargesInForeignCurrency ? parseFloat(ele.ChargesInForeignCurrency) : 0;
                } else {
                  AllocatedCharges += ele.AllocatedCharges ? parseFloat(ele.AllocatedCharges) : 0;
                }
              } else if (ele.hasOwnProperty('Charges') && ele.Charges !== '') { // Charges is present in Receiving
                AllocatedCharges += ele.Charges ? parseFloat(ele.Charges) : 0;
              } else {
                AllocatedCharges = 0;
              }
            });
            AllocateTotal = AllocatedCharges.toString();
            count = parseFloat(EstimateTotal) + parseFloat(AllocateTotal);
        // } else {
        //   count = parseFloat(EstimateTotal);
        // }
        return count;
      } catch (Error) {
      console.error('Error occurred in Allocate Charges Calculation ', Error);
    }
  } catch (error) {
    console.log('Error occured Conversion Algorithm ', error);
  }
};
