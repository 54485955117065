import { Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss']
})
export class CommonSharedPopupComponent implements OnInit {
  @ViewChild('vc', { static: true, read: ViewContainerRef }) vc: ViewContainerRef;
  componentName: any;
  componentSubscription$: Subscription = new Subscription();
  data: any;
  enableSave: any;
  constructor(private dialogRef: MatDialogRef<CommonSharedPopupComponent>
    , @Inject(MAT_DIALOG_DATA) private dialogData, private cf: ComponentFactoryResolver
    , private _commonService: CommonService) { 
      this.data = dialogData;
    }

  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    this.componentName = this.dialogData.componentDetails.componentName;
    const componentFactory = this.cf.resolveComponentFactory(this.componentName);
    const componentRef = this.vc.createComponent(componentFactory);
    const eventSpecified = this.dialogData.componentDetails.popUpDetails.eventName;
    if (componentRef.instance[eventSpecified]) {
      this.componentSubscription$ = componentRef.instance[eventSpecified].subscribe((val) => {
        this.enableSave = val;
      });
    }
  }

  close() {
      this.dialogRef.close('close');
  }

  save(e: string) {
    const dialogObj = {
    type: e.toLowerCase(),
    dialogRef: this.dialogRef
  };
    this._commonService.valueChange.emit(dialogObj);
  }
}
