import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function guidValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();

    if (!value) {
      return null; 
    }

    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!guidRegex.test(value)) {
      return { invalidGuid: true }; 
    }

    return null; 
  };
}
