import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { RetailLocalization } from '../localization/retail-localization';
import { GiftCardConfigurationStore, GiftCardConfiguration } from '../../shared/service/payment/payment-model';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';
import { API } from 'src/app/common/Models/property-information.model';
import { MenuObj } from 'src/app/common/shared/shared/components/menu-search/menu.model';
import { RetailBreakPoint } from '../../shared/globalsContant';


@Injectable({ providedIn: 'root' })
export class RetailPropertyInformation  extends CommonPropertyInformation {
 
    public _useGiftCardInterface: boolean;
    public _giftCardConfiguration : GiftCardConfigurationStore;
    public _eatecConfigurationURI: string;
    public  _memberConfiguration: string;
    private _propertyInformation: API.PropertyInformation;
    public eatecConfiguration : string;

    get PropertyZipCode() { return this._propertyInformation?.zip }

    constructor(public localization: RetailLocalization, private _propertyDataService: PropertyInformationDataService) {
        super(localization);
        this.SetDefaultDataOnLoad();
    }

    public get UseRetailInterface() {
        return sessionStorage.getItem('useRetailInterface')?.toLowerCase() === 'true' ? true : false;
    }

    public get HasRevenuePostingEnabled(): boolean {
        let feature = JSON.parse(sessionStorage.getItem('PropertyFeatureConfigurations'));
        return feature && feature.find(x => x.featureName.toLowerCase() == FeatureName.PMS_RevenuePosting.toLowerCase())?.isActive || false;
    }

    public get IsDMPostingEnabled(): boolean {
        let feature = JSON.parse(sessionStorage.getItem('DataMagineConfig'));
        return feature && feature?.postCheckReceiptToDM  || false;
    }
    
    public get SkipPMAgent(): boolean {
        let feature = JSON.parse(sessionStorage.getItem('propConfig'));
       var skipPMAgent = feature && feature.SkipPMAgent?.trim().toLowerCase() === 'true' ? true : false;
       return skipPMAgent;
    }

    public get IsNonIntegratedCreditCard(): boolean {
        let flag = sessionStorage.getItem('nonIntegratedCreditCard');
        return flag ? true : false;
    }

    public get IsAcesMembershipEnabled() {
        let feature = JSON.parse(sessionStorage.getItem('PropertyFeatureConfigurations'));
        let aces  = feature && feature.find(x => x.featureName == FeatureName.ACES_Membership) || {};
        return aces.isActive || false;
    }

    public get IsResortFinanceEnabled() {
        return sessionStorage.getItem('enableResortFinance')?.toLowerCase() === 'true' ? true : false;
    }

    public get IsGLExtractEnabled(): boolean {
        let feature = JSON.parse(sessionStorage.getItem('propConfig'));
        let isEnabled = feature && feature.EnableGLExtract?.toLowerCase() === 'true' ? true : false;
        return isEnabled;
    }

    public get getEatecURI() {
        return sessionStorage.getItem('EIURI');
    }
    public get getMemberConfig() {
       let member = sessionStorage.getItem('memberConfiguration');
       if (member && JSON.parse(member)) {
           return JSON.parse(member);
    }
    }
    public get getQuickIdConfig() {
        let quickIdConfig = sessionStorage.getItem('QuickIdConfig');
        if (quickIdConfig && JSON.parse(quickIdConfig)) {
            return JSON.parse(quickIdConfig);
        }
    }
    public get UseGiftCardInterface() {
        let giftCardConfig: string = sessionStorage.getItem('giftCardConfiguration');
        if (giftCardConfig && JSON.parse(giftCardConfig)) {
            const config = JSON.parse(giftCardConfig);
            return config.activateGiftCardInterface;
        }
    }

    public get GiftCardConfig() {
        return _.cloneDeep(this._giftCardConfiguration);
    } 

    public get IsGiftCardConfigExist(): boolean {
        return (this._giftCardConfiguration != null);
    }

    public get IsUserHasFolioAccess() : boolean{
        var folioAccessJWT = sessionStorage.getItem('_folioJwt');
        return folioAccessJWT? true : false;
    }

    public SetGiftCardConfiguration(giftcardConfig: GiftCardConfiguration) {
        this._giftCardConfiguration = null;
        if (giftcardConfig) {
            this._giftCardConfiguration = { activateGiftCardInterface: giftcardConfig.activateGiftCardInterface, giftCardType: giftcardConfig.giftCardType };
            this._useGiftCardInterface = giftcardConfig.activateGiftCardInterface;
        }
        sessionStorage.setItem('giftCardConfiguration', _.cloneDeep(JSON.stringify(this._giftCardConfiguration)));
    }
    public SetEatecRI(eatecConfiguration: string) {
        this._eatecConfigurationURI = null;
        if (eatecConfiguration) {
            this._eatecConfigurationURI =  eatecConfiguration ;
        }
        sessionStorage.setItem('EIURI', _.cloneDeep(this._eatecConfigurationURI));
    }

    public get getEatecConfiguration() {
        let eatec = sessionStorage.getItem('eatecConfiguration');
        if (eatec && JSON.parse(eatec)) {
            return JSON.parse(eatec);
        }
     }

    public SetEatecConfiguration(eatecConfiguration) {
        this.eatecConfiguration = null;
        if (eatecConfiguration) {
            this.eatecConfiguration =  eatecConfiguration ;
        }
        sessionStorage.setItem('eatecConfiguration', _.cloneDeep(JSON.stringify(this.eatecConfiguration)));
    }

    public SetMemberConfiguration(memberConfiguration) {
        this._memberConfiguration = null;
        if (memberConfiguration) {
            this._memberConfiguration =  memberConfiguration ;
        }
        sessionStorage.setItem('memberConfiguration',  _.cloneDeep(JSON.stringify(this._memberConfiguration)));
    }

    public SetMemberToken(memberTokenObj) {
        let memberObj =null ;
        if (memberTokenObj) {
            memberObj =  memberTokenObj ;
        }
        sessionStorage.setItem('memberTokenObj',  _.cloneDeep(JSON.stringify(memberObj)));
    }

    public GetPaymentConfigValueByKey(configKey: string, outletId: number): string {
        let payConfigValue = '';
        // Payment Configuration
        let paymentConfig: string = sessionStorage.getItem('paymentConfiguration');
        if (paymentConfig && JSON.parse(paymentConfig)) {
            this._paymentConfiguration = JSON.parse(paymentConfig);
            this._paymentConfiguration = _.sortBy(this._paymentConfiguration, "outletId");
        }
        var payConfig = this._paymentConfiguration.find(r => r.propertyId == this._propertyId && r.outletId == outletId && r.configKey == configKey);
        if (!payConfig) { // Get default pay configuration
            payConfigValue = this._paymentConfiguration.find(r => r.propertyId == 0 && r.outletId == 0 && r.configKey == configKey).configValue;
        }
        else {
            payConfigValue = payConfig.configValue;
        }
        return payConfigValue;
    }

    public SetDefaultDataOnLoad(loadPropertyInfo :boolean = true) {
        // Setting propety Date
        let propertyData: any = sessionStorage.getItem('propertyDate');
        if (propertyData) {
            this._currentDate = this.localization.getDate(propertyData);
        }
        else {
            this._currentDate = this.localization.getCurrentDate();
        }
        this._currentDate.setHours(0, 0, 0, 0);

        // Setting Retail-Interface switch value
        let retailSwitch: string = sessionStorage.getItem('useRetailInterface');
        if ((retailSwitch && retailSwitch.toLowerCase() == 'true')) {
            this._useRetailInterface = true;
        }
        else {
            this._useRetailInterface = false;
        }

        // Payment Configuration
        let paymentConfig: string = sessionStorage.getItem('paymentConfiguration');
        if (paymentConfig && JSON.parse(paymentConfig)) {
            this._paymentConfiguration = JSON.parse(paymentConfig);
        }
        else {
            this._paymentConfiguration = [];
        }
        //VAT Enable
        let VATEnableSwitch: string = sessionStorage.getItem('VATEnabled');
        if (VATEnableSwitch && VATEnableSwitch.toLowerCase() == 'true') {
            this._VATEnabled = true;
        } else {
            this._VATEnabled = false;
        }

        // Set PropertyId
        var propertyId = this.GetPropertyInfoByKey('PropertyId');
        if (propertyId) {
            this._propertyId = Number(propertyId);
        }

        // GiftCard Configuration
        let giftCardConfig: string = sessionStorage.getItem('giftCardConfiguration');
        if (giftCardConfig && JSON.parse(giftCardConfig)) {
            this._giftCardConfiguration = JSON.parse(giftCardConfig);
            this._useGiftCardInterface = this._giftCardConfiguration.activateGiftCardInterface;
        }
        else {
            this._giftCardConfiguration = null;
        }

        this.localization.SetLocaleBasedProperties();
        
        //Call API only if logged in
        if (this._propertyId && loadPropertyInfo) {
            this._propertyDataService.getPropertyInformation(this._propertyId).then(info => { this._propertyInformation = info });
        }
    }

    public SetShopItemLoadCount(val: number){
        sessionStorage.setItem('showItemLoadCount', val.toString());
    }

    public SetEnableMachineTransaction(val : boolean)
    {
        sessionStorage.setItem('enableMachineTransaction',val.toString());
    }
 

    public GetShopItemLoadCount() {
        return sessionStorage.getItem('showItemLoadCount');
    }

    public SetWebCommunicationProxyVersionCheck(versionCheck : string){
        sessionStorage.setItem('WebCommunicationProxyVersionCheck', versionCheck);
    }

    setICRoutes(menu: MenuObj[]) {
          const propConfig :{} = JSON.parse(sessionStorage.getItem('propConfig'));
          const enableRetailIC = propConfig? (propConfig['EnableRetailIC'] == 'true'? true: false): null;
          if (propConfig) {
            const overwriteRoutes = propConfig[ICRoutes.OverwriteICRoutes];
            if(!overwriteRoutes) {return ;}
            const linkedElements: MenuObj[] = menu.find(r => r.routePath === '/settings/enhancedInventory').linkedElement;
            if(!linkedElements) { return ;}
            const inventoryRoutes = ['/settings/enhancedInventory/inventory','/settings/enhancedInventory/procurement'];
            const slinkedElements: MenuObj[] = linkedElements.filter(l => l.visibility && inventoryRoutes.includes(l.routePath));
            if(!slinkedElements) {return ;}
            if(slinkedElements) {
                slinkedElements.forEach(l => {
                    const elements = l.linkedElement.filter(sl => sl.visibility && sl.breakPointNumber != 0);
                    if(!enableRetailIC){
                    elements.forEach(sl => {
                        if(sl.breakPointNumber == RetailBreakPoint.EatecInventory) {
                            sl.routePath = propConfig[ICRoutes.EatecInventory] || sl.routePath;
                        } else  if(sl.breakPointNumber == RetailBreakPoint.EatecTransfer) {
                            sl.routePath = propConfig[ICRoutes.EatecTransfer] || sl.routePath;
                        } else  if(sl.breakPointNumber == RetailBreakPoint.EatecPhysicalInventory) {
                            sl.routePath = propConfig[ICRoutes.EatecPhysicalInventory] || sl.routePath;
                        }  else  if(sl.breakPointNumber == RetailBreakPoint.EatecReceiving) {
                            sl.routePath = propConfig[ICRoutes.EatecReceiving] || sl.routePath;
                        } else  if(sl.breakPointNumber == RetailBreakPoint.EatecPurchaseOrder) {
                            sl.routePath = propConfig[ICRoutes.EatecPurchaseOrder] || sl.routePath;
                        }
                    });
                }
                })
            }
          }
      }
      public get IsRentalEnabledProperty() {
        let feature = JSON.parse(sessionStorage.getItem('PropertyFeatureConfigurations'));
        let Rentals  = feature && feature.find(x => x.featureName == FeatureName.Rentals) || {};
        return Rentals.isActive || false;
    }
}
export const FeatureName = {
    EnhancedInventory: "Enhanced Inventory",
    CasinoManagement: "CMS",
    ThirdPartGiftCard: "External Gift Card",
    ARAccountPosting: "ARAccountPosting",
    ACES_Membership: "ACES Membership",
    PMS_RevenuePosting: "PMS Revenue Posting",
    OfferManagementSystem: "Offer Management System",
    COMP: "COMP",
    GroupCharge: "Group Charge",
    MultiPMSRoomGroupCharge: "Multiple Properties",
    PostingReceiptToDM:"PostCheckReceiptToDM ",
    Rentals: "Rentals"
}

export const ICRoutes = {
    EatecInventory: "Inventory",
    EatecTransfer: "Transfer",
    EatecPhysicalInventory: "PhysicalInventory",
    EatecReceiving: "Receiving",
    EatecPurchaseOrder: "PurchaseOrder",
    OverwriteICRoutes: "OverwriteICRoutes",
}
