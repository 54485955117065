import { HttpServiceCall } from './../../../shared/service/http-call.service';
import { Injectable } from '@angular/core';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { Host } from 'src/app/retail/shared/globalsContant';
import { HttpMethod } from 'src/app/retail/shared/service/http-call.service';
import { RevenuePostingDataService } from 'src/app/retail/sytem-config/data-service/revenue-posting.data.service';
import { PaymentMethodInfo, RevenuePostingLog, RevenuePostingSearchRequest, RoomRevenuePostingRequest, UIRevenuePosting, UserInfo,CloseRevenuePosting } from './revenue-posting';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { PaymentMethods } from 'src/app/retail/shared/business/shared.modals';


@Injectable()
export class RevenuePostingBusiness {
  captions: any;
  currencySymbol: string;

  constructor( private localization: RetailLocalization, private dataService: RevenuePostingDataService,
               private utils: RetailUtilities, private http: HttpServiceCall) {
    this.captions = this.localization.captions.shop;
    this.currencySymbol = this.localization.currencySymbol;
  }

  async getClerkInfo(): Promise<UserInfo[]> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetUserInfoByPropertyId',
      method: HttpMethod.Get,
      host: Host.authentication,
      uriParams: { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
      productId: Number(this.utils.GetPropertyInfo('ProductId')) }
    });
    const response: any = result && result.result ? result.result : [];
    return response.map((element) => {
        return {
            id: element.userId,
            firstName: element.firstName,
            lastName: element.lastName,
            userName: element.userName
        };
    });
  }

  async getPaymentMethodsByProductId() {
    const result = await this.http.CallApiAsync({
      callDesc: 'PaymentMethod',
      method: HttpMethod.Get,
      host: Host.payment,
    });
    const response: any = result && result.result ? result.result : [];
    return response.map((element) => {
        return {
            id: element.id,
            paymentTypeId: element.paymentTypeId,
            paymentMethod: element.paymentMethod
        };
    });
  }

  public getTableOptions() {
    return {
      actions: [
      ],
      defaultsortingColoumnKey: 'ticketNumber',
      showTotalRecords: false,
      defaultSortOrder: SorTypeEnum.asc,
      columnFreeze: {
        firstColumn: false,
        lastColumn: true
      },
      noDataLabel:this.captions.NoDataFound,
      isDragDisabled: false,
      isHeaderCheckboxAllowed: true,
      checkboxKey: 'checked',
      ignoreSort: true
    };
  }

  public getHeaderOptions() {
    return  [
      {
        key: 'checked',
        displayNameId: '',
        displayName: '',
        hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
        templateName: ActionTypeEnum.checkbox,
      },
      {
        key: 'ticketNumber',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_ticketNumber ,
        sortingKey: 'ticketNumber',
        sorting: true
      },
      {
        key: 'logDate',
        displayNameId: '',
        displayName: this.captions.date,
        sortingKey: 'logDate',
        sorting: true
      },
      {
        key: 'logTime',
        displayNameId: '',
        displayName: this.captions.Time,
        sortingKey: 'logTime',
        sorting: true
      },
      {
        key: 'transactionType',
        displayNameId: '',
        displayName: this.captions.lbl_transactionType,
        sortingKey: 'transactionType',
        sorting: true
      },
      {
        key: 'paymentMethod',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_payment_type,
        sortingKey: 'paymentMethod',
        sorting: true,
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'failedAmount',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_failedAmount + '(' + this.currencySymbol + ')',
        sortingKey: 'failedAmount',
        sorting: true,
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'errorMessage',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_errorMessage,
        sortingKey: 'errorMessage',
        sorting: true,
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'user',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_user,
        sortingKey: 'user',
        sorting: true
      },
      {
        key: 'Action',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_Action,
        sortingKey: 'Action',
        templateName: ActionTypeEnum.custom
      },
    ];
  }

  public async isBatchProcessingPending(): Promise<boolean> {
    return await this.dataService.isBatchProcessingPending();
  }
  public async PostRevenue(body: RevenuePostingSearchRequest) {
    return await this.dataService.RevenuePost(body);
  }

  public async CloseRevenuePostings(body: CloseRevenuePosting) {
    return await this.dataService.CloseRevenuePostings(body);
  }

  public async getTabledata(model: RevenuePostingSearchRequest, userList: UserInfo[], paymentMethod: PaymentMethodInfo[],
                            isFromDayEnd: boolean = false) {
    if (isFromDayEnd) {
      const request: RoomRevenuePostingRequest = {
        startDate: model.startDate,
        endDate: model.endDate,
        isFromDayEnd: false
      };
      const result = await this.dataService.getRoomRevenuePostingLogs(request);
      return this.UiMapper(result.revenuePostingLog, userList, paymentMethod);
    }else {
      const result = await this.dataService.getRevenuePostingLogs(model);
      return this.UiMapper(result, userList, paymentMethod);
    }
  }



  UiMapper(model: RevenuePostingLog[], userList: UserInfo[], paymentMethod: PaymentMethodInfo[]): UIRevenuePosting[]{
    const data = model.map(x => {
      const paymentMethods = x.paymentMethodId?.length ? paymentMethod.filter(p => x.paymentMethodId.includes(p.paymentTypeId)) : [];
      return {
        id : x.id,
        transactionId: x.transactionId,
        ticketNumber: Number(x.ticketNumber),
        errorMessage: x.errorMessage ? x.errorMessage : '',
        failedAmount: x.failedAmount,
        user: userList && userList.some(u => u.id === x.soldBy) ? userList.find(u => u.id === x.soldBy).userName : '',
        Action: '',
        errorMessagefullView: x.errorInfo ? x.errorInfo : '',
        transactionType: x.transactionType,
        logTime: this.localization.LocalizeTime(x.logTime, true),
        logDate: this.localization.LocalizeShortDate(x.logTime),
        paymentMethod: paymentMethod.length > 0 ? this.getPaymentMethodName(paymentMethods) : '',
        paymentMethodfullView:  paymentMethod.length > 0 ? this.getPaymentMethodfullView(paymentMethods) : '',
       };
    });
    return data;
  }

  getPaymentMethodfullView(paymentMethod: PaymentMethodInfo[]): string{
      const info = paymentMethod?.length === 1 ? paymentMethod[0].paymentMethod : this.getPaymentMethod(paymentMethod).join(', ');
      return `${this.captions.tbl_lbl_payment_type} \n ${info}`;
  }

  getPaymentMethodName(paymentMethod: PaymentMethodInfo[]): string{
    return paymentMethod.length === 1 ? paymentMethod[0].paymentMethod :
          this.getPaymentMethod(paymentMethod)[0] + ' + '   + '' + (paymentMethod.length - 1);
  }

  getPaymentMethod(paymentMethod: PaymentMethodInfo[]): string[]{
    const paymentTypes: number[] = [PaymentMethods.RoomCharge, PaymentMethods.GroupCharge];
    return paymentMethod.some(x => paymentTypes.includes(x.paymentTypeId)) ? this.sortByPaymentType(paymentMethod)
     : paymentMethod.map(x => x.paymentMethod);
  }

  sortByPaymentType(paymentMethod: PaymentMethodInfo[]): string[]{
    const paymentTypes: number[] = [PaymentMethods.RoomCharge, PaymentMethods.GroupCharge];
    const payments = paymentMethod.filter(x => paymentTypes.includes(x.paymentTypeId));
    const result = paymentMethod.filter(x => !paymentTypes.includes(x.paymentTypeId));
    result.unshift(payments[0]);
    return result.map(x => x.paymentMethod);
  }

}
