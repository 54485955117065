import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { ButtonValue } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-printer-default-config',
  templateUrl: './printer-default-config.component.html',
  styleUrls: ['./printer-default-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrinterDefaultConfigComponent implements OnInit {
  captions: any;
  @Input() printerDropdownOptions;
  @Input() isViewOnly = false;
  @Input() data;
  @Input() defalutNoOfCopies;
  selectedOption;
  emptySelected = false;
  @Output() saveEmitter = new EventEmitter();
  @Output() cancelEmitter = new EventEmitter();
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  floatLabel: string;
  constructor(private _localization: Localization) {
    this.captions = this._localization.captions.common;
    this.floatLabel = this._localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.initializeInputs();
  }

  initializeInputs() {
    this.saveButton = {
      type: 'primary',
      label: this.captions.save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.cancel,
    }
  }

  emptyChange(e, midx, pidx, printOption) {
    this.dataBindOnChange(e, midx, pidx, printOption, true);
    this.saveButton.disabledproperty = false;
  }
  optionChange(e, midx, pidx, printOption) {
    this.dataBindOnChange(e, midx, pidx, printOption, false)
    this.saveButton.disabledproperty = false;
  }
  dataBindOnChange(e, midx, pidx, printOption, isempty: boolean = false, isNoOfCopies: boolean = false) {
    this.data.forEach((x, i) => {
      if (i === midx) {
        x.printerArr.forEach((y, j) => {
          if (!isNoOfCopies && j === pidx) {
            y.printerName = isempty ? '' : printOption.value;
            y.selectedValue = printOption;
            y.defaultNoofCopies = isempty ? this.defalutNoOfCopies : (y.defaultNoofCopies ? parseInt(y.defaultNoofCopies) : this.defalutNoOfCopies);
          }
          if (isNoOfCopies && j === pidx) {
            y.defaultNoofCopies = e && e.target.value ? parseInt(e.target.value) : this.defalutNoOfCopies;
            this.saveButton.disabledproperty = false;
          }
        })
      }
    });
  }
  optionCopiesChange(e, midx, pidx) {
    this.data.forEach((x, i) => {
      if (i === midx) {
        x.printerArr.forEach((y, j) => {
          if (j === pidx) {
            y.defaultNoofCopies = e.target.value
          }
        })
      }
    });
    this.saveButton.disabledproperty = false;
  }

  Onsave() {
    this.saveButton.disabledproperty = false;
    this.saveEmitter.emit(this.data);
  }

  Oncancel() {
    this.cancelEmitter.emit(this.data);
  }

}
