import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from '../../transaction.interface';
import { ViewGridHeaderConfiguration } from './grid-header-config.interface';

export const ViewGridSortDirection: ViewGridHeaderConfiguration =
    (   ViewHeaderEntity: any, 
        ViewDetailsEntity: any, 
        TransactionCollection: TransactionConfiguration, 
        httpService: HttpService,
        HeaderObject: any,
        translateService?: TranslateService, TilesHeaderTrascationEntity ?: any): any => {
        try {
            let defaultSortingString = '';
            if (TransactionCollection && TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSort.length > 0) {
                const sortingObject = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSort.filter(
                x => x.SortingOdataKey.toLocaleLowerCase()
                    === HeaderObject['$']['Name'].toLowerCase());
                if (sortingObject.length > 0) {
                    if (sortingObject[0].DefaultSorting) {
                        defaultSortingString = sortingObject[0].SortingType;
                    }
                }
            }
            return defaultSortingString.toLowerCase();
        } catch (error) {
            console.error('Error occurred in Setdefaultsort', error);
        }
    };
