import { Injectable } from "@angular/core";
import { ComponentsLinkingConfigService } from "../dataservices/componentsLinking/components-linking-config.service";
import { PlayerCategory, TransactionType } from "../enums/shared-enums";
import { ComponentAcecessTypeLinkingConfig } from "../Models/components-linking-config.model";

@Injectable({ providedIn: 'root' })
export class ComponentAccessTypeLinkConfigProvider {

    constructor(private _componentLinkingConfigSevice: ComponentsLinkingConfigService) { }

    readonly COMP_ACCESSTYPE_SESSION_KEY: string = "componentAccessTypeLinkingConfig";

    /**
     * @description Retrieves ComponentAccessType Both Master 
     * and Child Linking screen config and Stores it in SessionStorage
     */
    public async SetComponentAccessTypeLinkConfig(): Promise<void> {
        try {
            let configs = await Promise.all([
                this._componentLinkingConfigSevice.getComponentsLinkingConfig(),
                this._componentLinkingConfigSevice.getComponentAccessTypeLinkConfig()
            ]);
            if (configs && configs?.length == 2) {
                const componentAcecessTypeLinkingConfig: ComponentAcecessTypeLinkingConfig = {
                    ComponentLinkMasterConfig: configs?.[0],
                    ComponentLinkChildConfig: configs?.[1]
                }
                sessionStorage.setItem(this.COMP_ACCESSTYPE_SESSION_KEY, JSON.stringify(componentAcecessTypeLinkingConfig));
            }
        } catch (error) {
            console.error("error while fetching ComponentAccessTypeLink Config: ", error);
        }
    }

    /**
     * @description Updates the ComponentAccessConfig value in SessionStorage with the provided config
     * @param config  ComponentAcecessTypeLinkingConfig
     */
    public UpdateComponentAccessConfigSessionValue(config: ComponentAcecessTypeLinkingConfig) {
        if (config) {
            sessionStorage.setItem(this.COMP_ACCESSTYPE_SESSION_KEY, JSON.stringify(config));
        }
    }

    public get ComponentAccessTypeConfig(): ComponentAcecessTypeLinkingConfig {
        let compAccessConfig = null;
        if (sessionStorage.getItem(this.COMP_ACCESSTYPE_SESSION_KEY)) {
            compAccessConfig = JSON.parse(sessionStorage.getItem(this.COMP_ACCESSTYPE_SESSION_KEY))
        }
        return compAccessConfig;
    }

    /**
     * @description Validates given player, transaction types has access to the given payment method
     * @param playerType 
     * @param transactionType 
     * @param paymentMethodId 
     * @returns 
     */
    public CheckComponentAccess(playerType: PlayerCategory, transactionType: TransactionType, paymentMethodId: number): boolean {
        //Validate Parameters
        if (!playerType || !transactionType || !paymentMethodId) return false;

        let hasAccess = (this.ComponentAccessTypeConfig == null);
        if (this.ComponentAccessTypeConfig) {
            //const getTypeId = (type: string) => this.ComponentAccessTypeConfig?.ComponentLinkMasterConfig?.find(x => x.entityType == type)?.typeId;
            const transactionTypeId = ComponentAccessMasterConfig.TransactionType //getTypeId(ComponentAccessMasterEntity.TransactionType);
            const playerCategoryTypeId = ComponentAccessMasterConfig.PlayerCategory //getTypeId(ComponentAccessMasterEntity.PlayerCategory);
            const paymentMethodTypeId = ComponentAccessMasterConfig.PaymentMethod //getTypeId(ComponentAccessMasterEntity.PaymentMethod);

            if (transactionTypeId && playerCategoryTypeId && paymentMethodTypeId) {

                const playerCategoryTransactionTypeLink = this.ComponentAccessTypeConfig?.ComponentLinkChildConfig.find(x =>
                    x.recursiveLinkId == 0 // Top-level
                    && x.childId == playerType && x.parentId == transactionType
                    && x.masterParentTypeId == transactionTypeId //TransactionType should be Parent
                    && x.masterChildTypeId == playerCategoryTypeId //PlayerCategory should be child
                );
                const paymentMethodHasAccess = this.ComponentAccessTypeConfig?.ComponentLinkChildConfig.some(x =>
                    x.recursiveLinkId == playerCategoryTransactionTypeLink.id //Sub-level
                    && x.childId == paymentMethodId && x.parentId == playerType
                    && x.masterParentTypeId == playerCategoryTypeId //PlayerCategory should be parent
                    && x.masterChildTypeId == paymentMethodTypeId //PaymentMethod should be child
                );
                hasAccess = (playerCategoryTransactionTypeLink && paymentMethodHasAccess);
            }
        }
        return hasAccess;
    }

    private GetParentLink(parentId: number, childId: number) {
        return this.ComponentAccessTypeConfig?.ComponentLinkChildConfig.find(x =>
            x.recursiveLinkId == 0 // Top-level
            && x.parentId == parentId
            && x.childId == childId
        );
    }

    /**
     * @description Return all the allowed Payment Method IDs for the given playerType and TransactionType
     * @param playerType 
     * @param transactionType 
     * @returns 
     */
    public GetAllAllowedPaymentMethodIds(playerType: PlayerCategory, transactionType: TransactionType): number[] {
        let paymentMethodIds = []
        if (this.ComponentAccessTypeConfig && playerType && transactionType) {
            const parent = this.GetParentLink(transactionType, playerType);
            paymentMethodIds = this.ComponentAccessTypeConfig?.ComponentLinkChildConfig?.filter(x =>
                x.recursiveLinkId == parent?.id)?.map(x => x.childId);
        }
        return paymentMethodIds;
    }
}

export const ComponentAccessMasterEntity = {
    TransactionType: "TransactionType",
    PlayerCategory: "PlayerCategory",
    PaymentMethod: "PaymentMethod"
}

export enum ComponentAccessMasterConfig {
    TransactionType = 1,
    PlayerCategory,
    PaymentMethod
}