import { Component,  Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { SpaLocalization } from '../../core/localization/spa-localization';



@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleSelectComponent {

  belongto: any;
  buttonContent: any;
  selectedButtonContent: any = [];
  behaviour:any;

  @Input() DataInput:any;
  @Input() selectedDataInput:any;
  @Input() type:any;
  @Input() fromSec:any;
  @Input() automationId:string;

  @Output() resultData: EventEmitter<any> = new EventEmitter();

  
  constructor(public localization:SpaLocalization) {
    
  }
  
  ngOnChanges() {
  this.initValues();
  }

  public initValues() : void{
    this.buttonContent = this.DataInput;
    this.selectedButtonContent = this.formSelectedId(this.DataInput);
    this.behaviour = this.type;
    this.belongto = this.fromSec;
  }

  formSelectedId(data){
    let selectedId=[];
    data.forEach(x=>{
      if(x.setSelected){
        selectedId.push(x.id);
      }
    })
    return selectedId;
  }

  toggleClickbtn(id) {
    let ga = this.selectedButtonContent
    let gv = id;
    if (gv == 0) { /* For all button click */
      if (ga.indexOf(gv) == -1) {
        ga = [];
        this.buttonContent.forEach(x => {
          ga.push(x.id);
        });
      } else {
        ga = [];
      }
    } else { /* For other than all button click */
      if (ga.indexOf(gv) == -1) {
        ga.push(gv);
        if ((this.buttonContent.length - 1) == ga.length) {
          ga.push(0);
        }
      } else {
        ga.splice(ga.indexOf(gv), 1);

        if (ga.indexOf(0) != -1) {
          ga.splice(ga.indexOf(0), 1);
        }
      }
    }
    this.selectedButtonContent = ga;
    this.resultData.emit([this.selectedButtonContent,this.belongto] );
    return;
  }
}
