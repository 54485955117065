<section [formGroup]="form" class="eatec-week-list {{field.class}}" *ngIf="!field.hide">
    <div class="week-collection-section">
        <label class="product-header-4">{{field.label}}</label>
        <div class="week-list">
            <div class="day-name product-header-sites" *ngFor="let day of field.fieldType.SingleData.listCollection; let j=index"
                (click)="dayClick(j, day.Name)" [ngClass]="day.Checked ? 'checked-color' : ''">
                {{day.DisplayName}}
            </div>
        </div>
    </div>
</section>