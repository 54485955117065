import { Component, OnInit, OnDestroy } from '@angular/core';
import { SPAConfig } from '../../../core/config/SPA-config';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { ComboOptions, SystemConfiguration } from '../../../shared/business/view-settings.modals';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaFormAgent } from '../../../shared/spa-form';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SystemConfigBusinessService } from '../system-config.business.service';
import { SystemConfig, PropertyConfig, PhNumber, BaseResponse, ImageData as PropImage } from '../../../shared/business/shared.modals';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { SettingsService } from '../../settings.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { UserMachineConfigurationService } from 'src/app/core/services/user-machine-configuration.service';
import { AlertType, ButtonType } from '../../../shared/globalsContant';
import { ImageProcessorService } from 'src/app/shared/service/image-processor-service';
import { colorPickerModifier } from 'src/app/common/pipes/colorPickerModifier.pipe';



@Component({
  selector: 'app-property-information',
  templateUrl: './property-information.component.html',
  styleUrls: ['./property-information.component.scss'],
  providers: [SystemConfigBusinessService,colorPickerModifier]
})
export class PropertyInformationComponent extends SpaFormAgent implements OnInit, OnDestroy {
  propertyInformation
  settingInfo: SystemConfiguration[] = [];
  propertyInfo: UntypedFormGroup;
  contactPhoneType: ComboOptions[];
  RequiredFieldInfo: any;
  languageType: any[];
  textmaskFormat: string;
  phone: UntypedFormArray;
  address: UntypedFormArray;
  requiredFields: UntypedFormArray;
  addresslength: number = 0;
  captions: any;
  requiredFieldsInfo: any;
  enableSave: boolean;
  CUSTOM_FIELD_1: any;
  CUSTOM_FIELD_2: any;
  CUSTOM_FIELD_3: any;
  CUSTOM_FIELD_4: any;
  CUSTOM_FIELD_5: any;
  isUserAuthorized: boolean = true;
  isViewOnly: boolean = true;
  propertyInfoSubscription: ISubscription;
  initialLoads = true;
  callCounter = 0;
  skipPhoneNumberValidationonBlur = true;
  commonCaptions: any;
  propertyConfigurationDetails: any;
  PhoneType: { id: number; description: any; }[];
  filteredCountries: Observable<any>;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  DefaultCountryCodeConfig: any;
  DefaultEmailTypeConfig: any;
  DefaultPhoneTypeConfig: any;

  contactPhoneTypes: any;
  contactEmailTypes: any;
  base64textString: any;
  thumbnailImg: any;
  url:string;
  isImageUpload: boolean;
  isImageRemoved: boolean;
  imageUploaded: boolean;
  imageId: number;
  imageRemoved: boolean;
  imageReferenceId: string;
  floatLabel:string;
  floatLabelNever:string;

  constructor(private spaConfig: SPAConfig, private BP: BreakPointAccess, private systemConfig: SystemConfigBusinessService,
     private fb: UntypedFormBuilder, private localization: SpaLocalization, private utilities: SpaUtilities, 
     public http: HttpServiceCall, private utils: SpaUtilities, private ss: SettingsService, 
     private userSessionConfig: UserMachineConfigurationService, private imgService: ImageProcessorService, private colorPickerModifier : colorPickerModifier) {
    super(http);
    this.ss.tabLoaderEnable.next(true);
    this.commonCaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.propertyConfigurationDetails = {
      name: '',
      address: [{ addressDetails: "" }, { addressDetails: "" }],
      postalcode: '',
      state: "",
      city: "",
      country: "",
      phone: [{ phonetype: 'Phone', phonelabel: this.commonCaptions.drp_txt_home, phonenumber: '' }, { phonetype: 'Phone', phonelabel: this.commonCaptions.drp_txt_office, phonenumber: '' },
      { phonetype: 'Phone', phonelabel: this.commonCaptions.drp_txt_mobile, phonenumber: '' }],
      language: "",
      tenantId: '',
      propCode: "",
      requiredFields: [],
      defaultCountryCode: '',
      emailLabel: '',
      phoneLabel: ''
    };

    this.PhoneType = [
      { "id": 1, "description": this.commonCaptions.drp_txt_home },
      { "id": 2, "description": this.commonCaptions.drp_txt_office },
      { "id": 3, "description": this.commonCaptions.drp_txt_mobile }
    ];
  }

  ngOnInit() {
    this.propertyInfo = this.fb.group({
      name: '',
      address: this.fb.array([]),
      postalcode: '',
      state: '',
      city: '',
      country: '',
      phone: this.fb.array([]),
      language: '',
      tenantId: [{ value: '', disabled: true }, Validators.required],
      propCode: ['', Validators.required],
      requiredFields: this.fb.array([]),
      defaultCountryCode: ['', Validators.min(1)],
      emailLabel: '',
      phoneNumberLabel: '',
      imagedata :false,
      themeColor : ''
    });
    this.phone = this.propertyInfo.get('phone') as UntypedFormArray;
    this.contactPhoneType = [{ Id: 1, Description: this.commonCaptions.drp_txt_home, Type: "Phone" }, { Id: 2, Description: this.commonCaptions.drp_txt_office, Type: "Phone" }, { Id: 3, Description: this.commonCaptions.drp_txt_mobile, Type: "Phone" }];
    this.contactEmailTypes = this.localization.ContactTypes.Email;
    this.contactPhoneTypes = this.localization.ContactTypes.Phone;

    this.languageType = [{ id: 1, value: "English", code: "en-US" }, { id: 2, value: "Spanish", code: "SPANISH" }, { id: 3, value: "Chinese", code: "Chinese" }];
    this.textmaskFormat = this.localization.propertyCaptions.common && this.localization.propertyCaptions.common.PhoneFormat != '' ? this.localization.propertyCaptions.common.PhoneFormat : "999999999999999999";
    this.address = this.propertyInfo.get('address') as UntypedFormArray;
    this.addresslength = this.address.length;
    this.captions = this.spaConfig.captions.setting;
    this.GetServiceCall('GetAllLanguages');
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this.GetCustomFields();
    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this.utilities.geCountriesJSON().then(res => {
      this.filteredCountries = this.propertyInfo.controls.country.valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        map((country: string) => country ? this.utilities.FilterCountry(country, this.utilities.countryDetails) : [])
      );
      this.propertyInfo.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (this.propertyInfo.controls['city'].value) {
          this.propertyInfo.controls.country.setErrors({ required: true });
        } else {
          this.propertyInfo.controls.country.setErrors(null);
        }
        if (this.propertyInfo.controls['country'].value &&
          !this.utilities.FilterCountryValueFromData(this.propertyInfo.controls['country'].value)) {
          this.propertyInfo.controls.country.setErrors({ invalid: true });
        } else if ((this.propertyInfo.controls['city'].value &&
          this.utilities.FilterCountryValueFromData(this.propertyInfo.controls['country'].value) &&
          this.propertyInfo.controls['country'].value) || (!this.propertyInfo.controls['city'].value &&
            !this.propertyInfo.controls['country'].value)) {
          this.propertyInfo.controls.country.setErrors(null);
        }
        this.propertyInfo.controls['country'].markAsTouched();
      });
    });
  }

  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings]);
    this.isViewOnly = this.BP.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings);
    if (this.isViewOnly) {
      this.utilities.disableControls(this.propertyInfo);
    }
  }

  ngOnDestroy() {
    if (this.propertyInfoSubscription) {
      this.propertyInfoSubscription.unsubscribe();
    }
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  RequiredfieldsBind() {
    const personalInfo = [
      { id: 1, name: this.captions.Title, controlName: "CLIENT_TITLE" },
      { id: 2, name: this.captions.First_Name, controlName: "CLIENT_FIRST_NAME" },
      { id: 3, name: this.captions.Last_Name, controlName: "CLIENT_LAST_NAME" },
      { id: 4, name: this.captions.Birthday, controlName: "CLIENT_BIRTHDAY" },
      { id: 5, name: this.captions.Gender, controlName: "CLIENT_GENDER" },
    ];
    const contactInfo = [
      { id: 1, name: this.captions.Address, controlName: "CLIENT_ADDRESS_LINE_1" },
      { id: 2, name: this.captions.City, controlName: "CLIENT_CITY" },
      { id: 3, name: this.captions.State, controlName: "CLIENT_STATE" },
      { id: 4, name: this.captions.Postal_Code, controlName: "CLIENT_POSTAL_CODE" },
      { id: 5, name: this.captions.Country, controlName: "CLIENT_COUNTRY" },
      { id: 6, name: this.captions.Phone, controlName: "CLIENT_PHONE" },
      { id: 7, name: this.captions.Email, controlName: "CLIENT_EMAIL" },
    ];
    const paymentInfo = [
      { id: 1, name: this.captions.Credit_Card, controlName: "CLIENT_CREDIT_CARD" },
      {
        id: 2, name: this.CUSTOM_FIELD_1.length > 0 ? this.CUSTOM_FIELD_1[0].fieldName :
          this.captions.CUSTOM_FIELD_1, controlName: "CUSTOM_FIELD_1", Disabled: this.CUSTOM_FIELD_1.length <= 0
      },
      {
        id: 3, name: this.CUSTOM_FIELD_2.length > 0 ? this.CUSTOM_FIELD_2[0].fieldName :
          this.captions.CUSTOM_FIELD_2, controlName: "CUSTOM_FIELD_2", Disabled: this.CUSTOM_FIELD_2.length <= 0
      },
      {
        id: 4, name: this.CUSTOM_FIELD_3.length > 0 ? this.CUSTOM_FIELD_3[0].fieldName :
          this.captions.CUSTOM_FIELD_3, controlName: "CUSTOM_FIELD_3", Disabled: this.CUSTOM_FIELD_3.length <= 0
      },
      {
        id: 5, name: this.CUSTOM_FIELD_4.length > 0 ? this.CUSTOM_FIELD_4[0].fieldName :
          this.captions.CUSTOM_FIELD_4, controlName: "CUSTOM_FIELD_4", Disabled: this.CUSTOM_FIELD_4.length <= 0
      },
      {
        id: 6, name: this.CUSTOM_FIELD_5.length > 0 ? this.CUSTOM_FIELD_5[0].fieldName :
          this.captions.CUSTOM_FIELD_5, controlName: "CUSTOM_FIELD_5", Disabled: this.CUSTOM_FIELD_5.length <= 0
      }
    ]
    this.requiredFieldsInfo = [
      {
        name: this.captions.personalInformation,
        requiredInfo: personalInfo
      },
      {
        name: this.captions.contactDetails,
        requiredInfo: contactInfo
      },
      {
        name: this.captions.paymentDetails,
        requiredInfo: paymentInfo
      }
    ];
    this.ValidateBreakPoint();
    this.GetPropertInfo();
    this.GetAllSetting();
    this.requiredFields = this.propertyInfo.get('requiredFields') as UntypedFormArray;
    for (let i = 0; i < this.requiredFieldsInfo.length; i++) {
      switch (this.requiredFieldsInfo[i].name) {
        case this.captions.personalInformation:
          this.requiredFields.push(this.addPersonalDetails());
          break;
        case this.captions.contactDetails:
          this.requiredFields.push(this.addContactDetails());
          break;
        case this.captions.paymentDetails:
          this.requiredFields.push(this.addPaymentDetails());
          break;
      }
    }
    this.propertyInfo.patchValue(this.propertyConfigurationDetails);
    this.propertyInfoSubscription = this.propertyInfo.valueChanges.subscribe(() => {
      if (this.propertyInfo.valid) {
        this.enableSave = true;
      } else {
        this.enableSave = false;
      }
    });
    this.calculateHeight();
  }

  fileDeleted() {    
    this.propertyInfo.markAsDirty();
    this.propertyInfo.markAsTouched();
    this.isImageUpload = true;
    this.propertyInfo.controls['imagedata'].setValue(true);
    this.isImageRemoved = true;
    this.imageUploaded = true;
  }

  fileUploaded(data) {
    this.propertyInfo.markAsDirty();
    this.propertyInfo.markAsTouched(); 
    this.isImageUpload = true;
    this.propertyInfo.controls['imagedata'].setValue(true);
    this.base64textString = data['orgImg'];
    this.thumbnailImg = data['tmbImg'];
    this.thumbnailImg = this.thumbnailImg;    
    this.isImageRemoved = false;      
  }

  fileSizeExceeded() {
    this.utilities.showAlert(this.captions.ImageSizeExceed, AlertType.Info, ButtonType.Ok);
  }

  convertToNumber(value: any) {
    return value && Number(value) ? Number(value) : 0;
  }

  AddDefaultContactInfo(body: any) {
    const defaultCountryCode = this.convertToNumber(this.propertyInfo.controls['defaultCountryCode'].value);
    const phoneNumberLabel = this.convertToNumber(this.propertyInfo.controls['phoneNumberLabel'].value);
    const emailLabel = this.convertToNumber(this.propertyInfo.controls['emailLabel'].value);
    const themeColor = this.propertyInfo.controls['themeColor'].value;

    const switchArrayValue = [{ code: 'DEFAULT_COUNTRY_CODE', value: defaultCountryCode },
    { code: 'DEFAULT_PHONE_TYPE', value: phoneNumberLabel },
    { code: 'DEFAULT_EMAIL_TYPE', value: emailLabel },
    { code: 'THEME_COLOR', value: themeColor}];

    switchArrayValue.forEach(x => {
      const switchSetting = this.settingInfo.find(setting => setting.switch == x.code);
      body.push({
        id: switchSetting.id,
        moduleId: switchSetting.moduleId,
        switch: x.code,
        value: x.value
      });
    });

  }


  GetPropertInfo() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetPropertyInfoByPropertyId",
      uriParams: { Id: this.utils.GetPropertyInfo('PropertyId') },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetlanguageInfo() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetLangugae",
      uriParams: { Id: this.utils.GetPropertyInfo('PropertyId') },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }
  
  GetAllSetting() {
    this.makeGetCall("GetAllSetting", GlobalConst.Host.spaManagement, { Id: this.utils.GetPropertyInfo('PropertyId') });
  }

  GetServiceCall(Route, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }
  calculateHeight() {
    try {
      let col1 = document.getElementById('required-0').offsetHeight;
      let col2 = document.getElementById('required-1').offsetHeight;
      let col3 = document.getElementById('required-2').offsetHeight;
      let maxHeight = Math.max(col1, col2, col3)
      this.setHeight(maxHeight);
    }
    catch (e) { }
  }

  setHeight(height) {
    try {
      document.getElementById('required-0').style.height = height + 'px'
      document.getElementById('required-1').style.height = height + 'px'
      document.getElementById('required-2').style.height = height + 'px'
    }
    catch (e) { }
  }

  addPersonalDetails(): UntypedFormGroup {
    return this.fb.group({
      CLIENT_TITLE: '',
      CLIENT_FIRST_NAME: '',
      CLIENT_LAST_NAME: '',
      CLIENT_BIRTHDAY: '',
      CLIENT_GENDER: ''
    });
  }

  addContactDetails(): UntypedFormGroup {
    return this.fb.group({
      CLIENT_ADDRESS_LINE_1: '',
      CLIENT_CITY: '',
      CLIENT_STATE: '',
      CLIENT_POSTAL_CODE: '',
      CLIENT_COUNTRY: '',
      CLIENT_PHONE: '',
      CLIENT_EMAIL: ''
    });
  }

  addPaymentDetails(): UntypedFormGroup {
    return this.fb.group({
      CLIENT_CREDIT_CARD: '',
      CUSTOM_FIELD_1: '',
      CUSTOM_FIELD_2: '',
      CUSTOM_FIELD_3: '',
      CUSTOM_FIELD_4: '',
      CUSTOM_FIELD_5: ''
    });
  }

  addPhoneDetails() {
    const phoneDetails = this.propertyConfigurationDetails.phone;
    let phoneInfo = [];
    for (let i = 0; i < phoneDetails.length; i++) {
      phoneInfo.push(this.fb.group({
        phonetype: "Phone",
        phonelabel: phoneDetails[i].phonelabel,
        phonenumber: { value: this.utilities.appendLocalizedFormat(phoneDetails[i].phonenumber, this.localization.propertyCaptions.common.PhoneFormat), disabled: !phoneDetails[i].phonelabel }
      }))
    }
    return phoneInfo;
  }

  addPhoneArray(index, phoneLabel, phoneNumber): UntypedFormGroup {
    return this.fb.group({
      phonetype: "Phone",
      phonelabel: phoneLabel,
      phonenumber: { value: this.utilities.appendLocalizedFormat(phoneNumber, this.localization.propertyCaptions.common.PhoneFormat), disabled: !phoneLabel }
    })
  }
  addPhoneItem(index, phoneLabel: any, phoneNumber: any): void {
    this.phone = this.propertyInfo.get('phone') as UntypedFormArray;
    this.phone.insert(1, this.addPhoneArray(index, phoneLabel, phoneNumber));
  }

  removePhoneItem(index: number): void {
    this.phone = this.propertyInfo.get('phone') as UntypedFormArray;
    this.phone.removeAt(index);
  }

  addAddressArray(index, addressDetails): UntypedFormGroup {
    return this.fb.group({
      addressDetails: addressDetails
    });
  }

  addAddress(index: number, addressDetails: any) {
    this.address = this.propertyInfo.get('address') as UntypedFormArray;
    if (this.address.length < 3) {
      this.address.push(this.addAddressArray(index, addressDetails));
    }
    this.addresslength = this.address.length;
  }

  removeAddress(index: number) {
    this.address.removeAt(index);
    this.addresslength--;
  }

  save() {
    let bodySettingData: SystemConfig[] = this.formSettingBody();
    this.UpdateSetting(bodySettingData);
  }

  UpdateSetting(bodyData) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateSetting",
      body: bodyData,
      method: HttpMethod.Put,
      showError: true,
      extraParams: []
    });
  }

  UpdatePropertySetting(bodyPropertyData) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdatePropertyInfoByPropertyId",
      body: bodyPropertyData,
      method: HttpMethod.Post,
      showError: true,
      extraParams: []
    });
  }

  GetImageSetting() {
    this.imgService.GetImagesByReference(this.propertyInformation.imageReferenceId, '', this.successCallback.bind(this), this.errorCallback.bind(this), [], true);
  }

  GetCustomFields() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetCustomFields",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    })
  }

  formSettingBody(): SystemConfig[] {
    let allControls = [this.requiredFields.controls];
    let con = allControls[0];
    let _body: SystemConfig[] = [];
    for (let systemControl of con) {
      let formControl = systemControl as UntypedFormGroup;
      let controls: string[] = Object.keys(formControl.controls);
      for (let control of controls) {
        let switchName = control;
        let _systemConfig: SystemConfig;
        _systemConfig = {
          id: this.settingInfo.find(setting => setting.switch == switchName).id,
          moduleId: this.settingInfo.find(s => s.switch == switchName).moduleId,
          switch: switchName,
          value: formControl.controls[control].value
        }
        _body.push(_systemConfig);
      }
    }
    this.AddDefaultContactInfo(_body);
    return _body;
  }
  formPropertyData(): PropertyConfig {
    let _body: any = {};
    _body.propertyContacts = [];
    let aa = this.propertyInfo.controls["address"].value;
    let _phone = this.propertyInfo.controls["phone"].value
    _body.propertyName = this.propertyInfo.controls['name'].value;
    _body.propertyCode = this.propertyInfo.controls['propCode'].value;
    _body.tenantId = this.propertyInfo.controls['tenantId'].value;
    _body.languageCode = this.propertyInfo.controls['language'].value;
    _body.city = this.propertyInfo.controls['city'].value;
    _body.state = this.propertyInfo.controls['state'].value;
    _body.country = this.utils.FilterCountryValueFromData(this.propertyInfo.controls['country'].value);
    _body.zip = this.propertyInfo.controls['postalcode'].value;
    _body.propertyId = parseInt(this.localization.GetPropertyInfo("PropertyId"));
    _body.address1 = this.propertyInfo.controls.address.value[0].addressDetails;
    const tempAddress2 = ((aa.length < 2) || (this.propertyInfo.controls.address.value[1].addressDetails == null)) ? "" : this.propertyInfo.controls.address.value[1].addressDetails;
    _body.address2 = (aa.length < 2) ? "" : tempAddress2;
    const tempAddress3 = ((aa.length < 3) || (this.propertyInfo.controls.address.value[2].addressDetails == null)) ? "" : this.propertyInfo.controls.address.value[2].addressDetails;
    _body.address3 = (aa.length < 3) ? "" : tempAddress3;


    for (let temp = 0; temp < _phone.length; temp++) {
      let phoneItem = _phone[temp];
      if (phoneItem.phonetype != null) {
        let ph = phoneItem.phonelabel == null ? this.PhoneType[1] : phoneItem.phonelabel
        let phone: PhNumber;
        let contactType = this.PhoneType.filter(res => res.description === ph);
        phone = {
          id: temp + 1,
          number: phoneItem.phonenumber ? (phoneItem.phonenumber).replace(/\D/g, '') : "",
          propertyId: _body.propertyId = this.localization.GetPropertyInfo("PropertyId"),
          contactTypeId: contactType ? contactType[0].id : 0,
          clientId: 1
        };
        _body.propertyContacts.push(phone);
      }
    }
    return _body;
  }

  validateProperty(): boolean {
    return (this.propertyInfo.valid && this.propertyInfo.dirty) && this.propertyInfo.touched;
  }

  RequiredFieldsSetting() {
    let _propJSON: any = {};
    this.settingInfo.forEach(sc => {
      if (sc.switchType == "Boolean" && sc.value) {
        sc.value = this.convertStringToBoolean(sc.value as string);
      }
      _propJSON[sc.switch] = sc.value
      _propJSON[sc.id] = sc.id
    }, this);
    this.systemConfig.systemConfigValues = _propJSON;
    this.formAndPatchRequiredFieldsData(_propJSON);
    this.enableSave = false;
  }

  DefaultSettings() {
    this.setDefaultSettings('defaultCountryCode', 'DEFAULT_COUNTRY_CODE');
    this.setDefaultSettings('phoneNumberLabel', 'DEFAULT_PHONE_TYPE');
    this.setDefaultSettings('emailLabel', 'DEFAULT_EMAIL_TYPE');
    this.setDefaultSettings('themeColor','THEME_COLOR');
  }

  setDefaultSettings(control: string, key: string) {
    const switchValue = this.settingInfo.find(x => x.switch == key);
    if(switchValue.switch == 'THEME_COLOR')
    this.propertyInfo.controls[control].setValue(switchValue.value);
    else
    this.propertyInfo.controls[control].setValue(switchValue && Number(switchValue.value) > 0 ? Number(switchValue.value) : '');
  }

  PropertSetting() {
    let address: any;
    if (this.propertyInformation) {
      address = [this.propertyInformation.address1, this.propertyInformation.address2, this.propertyInformation.address3,];
    }
    else {
      this.addAddress(0, '');
    }
    let phone: any = this.propertyInformation ? this.propertyInformation.propertyContacts : null;
    this.propertyInfo.controls.name.setValue(this.propertyInformation ? this.propertyInformation.propertyName : '');
    this.propertyInfo.controls.postalcode.setValue(this.propertyInformation ? this.propertyInformation.zip : '');
    this.propertyInfo.controls.state.setValue(this.propertyInformation ? this.propertyInformation.state : '');
    this.propertyInfo.controls.city.setValue(this.propertyInformation ? this.propertyInformation.city : '');
    this.propertyInfo.controls.country.setValue(this.propertyInformation ? this.propertyInformation.country : '');
    this.propertyInfo.controls.language.setValue(this.propertyInformation ? this.propertyInformation.languageCode : '');
    this.propertyInfo.controls.tenantId.setValue(this.propertyInformation ? this.propertyInformation.tenantId : '');
    this.propertyInfo.controls.propCode.setValue(this.propertyInformation ? this.propertyInformation.propertyCode : '');
    this.clearFormArray(this.propertyInfo.get("address") as UntypedFormArray);
    for (let index = 0; index < address.length; index++) {
      if ((address[index] && address[index].trim() != "") || index == 0)
        this.addAddress(index, address[index]);
    }
    this.clearFormArray(this.propertyInfo.get('phone') as UntypedFormArray);
    if (phone != null && (!this.phone || this.phone.length != phone.length)) {
      for (let index = 0; index < phone.length; index++) {
        if ((phone[index].number && phone[index].number.toString().trim() != "") || index == 0) {
          let contactType = this.PhoneType.filter(res => res.id === phone[index].contactTypeId);
          this.addPhoneItem(index, contactType && contactType.length ? contactType[0].description : contactType[0], phone[index].number);
        }
      }
    } else {
      this.addPhoneItem(0, '', '');
    }
    const phonenumbers = this.propertyInfo.get('phone') as UntypedFormArray;
    phonenumbers.controls.forEach((obj: UntypedFormGroup) => {
      if (!obj.value['phonelabel']) {
        obj.get('phonenumber').disable();
      }
    });
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    if (callDesc == "GetAllSetting") {
      this.settingInfo = <any>result.result;
      this.RequiredFieldsSetting();
      this.DefaultSettings()
      this.enableSave = false;
    }
    else if (callDesc == "GetPropertyInfoByPropertyId") {
      this.propertyInformation = <any>result.result;
      this.PropertSetting();
      if (this.propertyInformation.imageReferenceId && this.propertyInformation.imageReferenceId !== '00000000-0000-0000-0000-000000000000') {
        this.GetImageSetting();
      } else {
        this.url = null;
      }
      this.imageReferenceId = this.propertyInformation.imageReferenceId;
      this.enableSave = false;
    }
    else if(callDesc == "v2GetImagesByReferenceId") {
      let imageData: PropImage = <any>result.result;
      if (imageData && imageData[0] && imageData[0].thumbnailData !== '') {
        this.url = `${imageData[0].contentType ? imageData[0].contentType : ''},${imageData[0].thumbnailData ? imageData[0].thumbnailData : ''}`
      }
      this.imageId = imageData && imageData[0] ? imageData[0].id : '';
    }
    else if (callDesc == "UpdatePropertyInfoByPropertyId") {
      this.GetPropertInfo();
      this.enableSave = false;
    }
    else if (callDesc == "UpdateSetting") {
      this.GetAllSetting();
      this.reloadSession();
      let bodyPropertyData: PropertyConfig = this.formPropertyData();
      if (this.isImageUpload) {
        bodyPropertyData.imageReferenceId = this.imageReferenceId;
        this.saveImage();
      } else {
        this.UpdatePropertySetting(bodyPropertyData);
      }
      this.enableSave = false;
    } else if(callDesc == "v2saveImage") {
      let imageData = <any>result.result;
      this.imageReferenceId = imageData;
      let bodyPropertyData: PropertyConfig = this.formPropertyData();
      bodyPropertyData.imageReferenceId = this.imageReferenceId;
      this.UpdatePropertySetting(bodyPropertyData);
    }
    else if (callDesc == "GetCustomFields") {
      [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
      this.RequiredFieldInfo = <any>result.result;
      this.CUSTOM_FIELD_1 = this.RequiredFieldInfo.filter(o => o.columnName == 'CustomField1')
      this.CUSTOM_FIELD_2 = this.RequiredFieldInfo.filter(o => o.columnName == 'CustomField2');
      this.CUSTOM_FIELD_3 = this.RequiredFieldInfo.filter(o => o.columnName == 'CustomField3');
      this.CUSTOM_FIELD_4 = this.RequiredFieldInfo.filter(o => o.columnName == 'CustomField4');
      this.CUSTOM_FIELD_5 = this.RequiredFieldInfo.filter(o => o.columnName == 'CustomField5');
      this.RequiredFieldsSetting();
      this.RequiredfieldsBind();
      this.enableSave = false;
    }
    else if (callDesc == 'GetAllLanguages') {
      [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
      if (result.result) {
        let data = <any>result.result;
        this.languageType = data.map(x => { return { id: x.languageID, value: x.languageName, code: x.languageCode } });
      }
    }
  }

  async reloadSession() {
    let defaultsSetting = await this.userSessionConfig.getDefaultSettings();
    sessionStorage.setItem('defaultSettings', JSON.stringify(defaultsSetting));
    setTimeout(()=> {
      let getThemeColor: any  = document.getElementsByClassName('theme-color-wrapper')[0];
      let textColor: any = document.getElementsByClassName('theme-color-wrapper')[0].children[0];
      textColor.style.color = "",
      getThemeColor.style.setProperty("background-color", JSON.parse(sessionStorage.getItem('defaultSettings'))?.find(x=>x.switch == "THEME_COLOR").value, "important");
      textColor.style.setProperty("color", this.colorPickerModifier.transform(JSON.parse(sessionStorage.getItem('defaultSettings'))?.find(x=>x.switch == "THEME_COLOR").value) ? '#fff' : '#000' , "important");
    }, 500);
  }

  cancel() {
    this.RequiredFieldsSetting();
    this.PropertSetting();
    this.DefaultSettings();
    this.enableSave = false;
  }

  formAndPatchRequiredFieldsData(_appJSON) {
    _appJSON['requiredFields'] = [_appJSON, _appJSON, _appJSON];
    this.propertyInfo.patchValue(_appJSON);
  }
  addAddressDetails() {
    const addressDetails = this.propertyConfigurationDetails.address;
    let arr = [];
    for (let i = 0; i < addressDetails; i++) {
      arr.push(this.fb.group({
        address: addressDetails[i].address
      }));
    }
    return arr;
  }

  convertStringToBoolean(value: string): boolean {
    if (typeof (value) !== "boolean") {
      return value.toLowerCase() == "true" ? true : false;
    }
    return value;
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetAllSetting") {
      this.systemConfig.systemConfigValues = this.settingInfo;
    }
    if (callDesc == "UpdateSetting") {
      this.systemConfig.systemConfigValues = this.settingInfo;
    }
    if (callDesc == 'GetAllLanguages' || callDesc == "GetCustomFields") {
      [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
    }
  }

  clearFormArray(formArray: UntypedFormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }


  onPhChange($event, i, item) {
    item['controls']['phonenumber'].enable();
    //settingEmptyvalues 
    if (!$event.value) {
      item.reset();
      item['controls']['phonenumber'].disable();
      item.markAsDirty();
    }
  }

  saveImage() {
    const base64result = this.isImageRemoved ? ['', ''] : this.base64textString.split(',');
    const base64Thumbnail = this.isImageRemoved ? ['', ''] : this.thumbnailImg.split(',');
    const imageDataObj: PropImage = {
      referenceId: 0,
      referenceType: GlobalConst.ImgRefType.property,
      data: base64result[1],
      id: 0,
      thumbnailData: base64Thumbnail ? base64Thumbnail[1] : '',
      contentType: base64result[0],
      sequenceNo: 0
    };
    let imgReferenceId = this.imageReferenceId ? this.imageReferenceId : '00000000-0000-0000-0000-000000000000';
    if (imgReferenceId === undefined || imgReferenceId === '' || imgReferenceId === '00000000-0000-0000-0000-000000000000') {
      const imageGuid = this.utilities.generateUUIDUsingMathRandom();
      imageDataObj.imageReferenceId = imageGuid
      this.imgService.saveImage([imageDataObj],  this.successCallback.bind(this),  this.errorCallback.bind(this), [], true);
    }
    else {
      imageDataObj.imageReferenceId = this.imageReferenceId;
      this.imgService.updateImage(this.imageId, [imageDataObj], this.successCallback.bind(this),  this.errorCallback.bind(this), [], true);
    }
  }

  changeColor($event:any){
    this.propertyInfo.controls.themeColor.setValue($event.color.hex);
    this.enableSave = true;
  }
}
