import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginComponent } from '../../login/login.component';
import { BaseResponse } from '../../../shared/business/shared.modals';
import {SpaLocalization} from '../../localization/spa-localization';
import { PropertySettingDataService } from '../../../settings/system-config/property-setting.data.service';
import { CryptoUtility } from '../../utilities/crypto.utility';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss'],
  providers: [CryptoUtility,LoginComponent, PropertySettingDataService]
})
export class ConfirmPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: any;
  confirmPasswordFormGrp: UntypedFormGroup;
  newPassSubscribe: Subscription;
  IsLengthValid: boolean;
  IsHavingAllTypes: boolean;
  IsLastPassword: boolean;
  IsSameAsUserName: boolean;
  IsHavingLowerCase: boolean;
  IsPasswordValid: boolean;
  confirmPassSubscribe: Subscription;
  oldPassSubscribe: Subscription;
  IsConfirmed: boolean;
  IsOldPassword: boolean;
  lastpassword: string = "12345678";
  username: string = "john";
  buttonDisabled: boolean = true;
  ValidateCredentials: Function;
  paswordctrl: any;
  minCharacter: any;
  maxCharacter: any;
  errorMessage: string;
  allowReuse: any;
  allowUserName: any;
  allowSpecialCharacters: any;
  formatingType: any;
  NoValidation: any = false;
  hidePassword: any = false;
  hideOldPassword: any = false;
  hideConfirmPassword :  any =false
  captions: any;
  commonCaptions: any;
  public confirmJson: any;
  uTempDataPrimary : string ;
  uTempDataSecondary : string;
  floatLabel: string;
  debounceTime = 1500;
  isDoneValid:boolean = true;
  isConfirmPassword = false;
  isSpecialChar = false;

  constructor(private authentication: AuthenticationService, private Form: UntypedFormBuilder, public dialog: MatDialogRef<ConfirmPasswordComponent>, private Login: LoginComponent,
    public localization: SpaLocalization,private crypto : CryptoUtility) {
    this.confirmPasswordFormGrp = this.Form.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
    this.captions = this.localization.captions.login;
    this.commonCaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
  }
  ngOnInit() {
    this.OnFormValueChanges();
    if (this.data.type == 'NP') {
      this.confirmPasswordFormGrp.controls.oldPassword.clearValidators();
    }
    this.paswordctrl = this.data.paswordctrl;
    if(this.data && this.data.uTempData && this.data.uTempData.uTempPri &&  this.data.uTempData.uTempSec)
    {
      this.uTempDataPrimary = this.data.uTempData.uTempPri;
      this.uTempDataSecondary = this.data.uTempData.uTempSec;
    }

    this.confirmJson = {
      "result": {
        "id": 2,
        "tenantId": 1,
        "expireDays": 90,
        "allowReuse": 3,
        "minimumCharacters": 6,
        "maximumCharacters": 20,
        "signInAttempts": 2,
        "formattingType": 4,
        "allowSpecialCharacters": true,
        "allowUserName": false
      }
    }

  }

  ngAfterViewInit() {
    this.errorMessage = this.errorFormating(this.formatingType, this.allowSpecialCharacters);
  }

  ngOnDestroy() {
    if (this.newPassSubscribe) {
      this.newPassSubscribe.unsubscribe();
    }
    if (this.confirmPassSubscribe) {
      this.confirmPassSubscribe.unsubscribe();
    }
    if (this.oldPassSubscribe) {
      this.oldPassSubscribe.unsubscribe();
    }
  }



  OnFormValueChanges(): any {
    this.newPassSubscribe = this.confirmPasswordFormGrp.get('newPassword').valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(res => {
      this.IsConfirmed = false;


      this.IsLengthValid = res.length >= this.minCharacter ? true : false;
      let returnFormat = this.formatingTypeValidation(this.formatingType, res, this.allowSpecialCharacters);
      if(this.confirmPasswordFormGrp.controls['newPassword'].value == res && res != ''){
        this.isSpecialChar = !this.containsSpecialCharacters(res) && !this.allowSpecialCharacters && !this.containsSpecialCharacters(this.confirmPasswordFormGrp.controls['confirmPassword'].value);
      }
      else{
        this.isSpecialChar = false;
      }
      if(this.confirmPasswordFormGrp.controls['confirmPassword'].value == res && res != ''){
        this.isDoneValid = !(this.containsSpecialCharacters(res) && !this.allowSpecialCharacters);
      }
      this.IsHavingAllTypes = returnFormat ? true : false;
      console.log(this.data)
      const tempSameAsUserName = this.allowUserName ? true : !(res.toLowerCase().includes(this.data.userInfo.firstName.toLowerCase()) || res.toLowerCase().includes(this.data.userInfo.lastName.toLowerCase()));
      this.IsSameAsUserName = res != "" ? tempSameAsUserName : false;
      this.PasswordValidCheck();
      if (this.IsLengthValid) {
        if(this.uTempDataPrimary && this.uTempDataSecondary)
          {
            let password = this.crypto.EncryptString(this.confirmPasswordFormGrp.controls['newPassword'].value,this.uTempDataPrimary,this.uTempDataSecondary);
            this.authentication.CheckPasswordExistsPut(this.data.userName, password, this);
          }
          else{
            this.authentication.CheckPasswordExists(this.data.userName, this.confirmPasswordFormGrp.controls['newPassword'].value, this);
          }
      } else {
        this.IsLastPassword = false;
      }
      this.IsConfirmed = (this.confirmPasswordFormGrp.controls['confirmPassword'].value != '' && this.confirmPasswordFormGrp.controls['newPassword'].value == this.confirmPasswordFormGrp.controls['confirmPassword'].value) ? true : false;


    });

    this.confirmPassSubscribe = this.confirmPasswordFormGrp.get('confirmPassword').valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(res => {
      this.IsConfirmed = (this.confirmPasswordFormGrp.controls['newPassword'].value == res && res != '') ? true : false;
      this.isConfirmPassword = (res != '') ? true : false;
      if(this.confirmPasswordFormGrp.controls['confirmPassword'].value == res && res != ''){
        this.isSpecialChar = !this.containsSpecialCharacters(res) && !this.allowSpecialCharacters && !this.containsSpecialCharacters(this.confirmPasswordFormGrp.controls['newPassword'].value);
      }
      else{
        this.isSpecialChar = false;
      }
      if(this.confirmPasswordFormGrp.controls['newPassword'].value == res && res != ''){
        this.isDoneValid = !(this.containsSpecialCharacters(res) && !this.allowSpecialCharacters);
      }
    });

    if (this.data.type == 'CP') {
      this.confirmJson  = this.data.passwordSetting;
      this.validationMessage(this.data.passwordSetting);
      this.errorMessage = this.errorFormating(this.formatingType, this.allowSpecialCharacters);
        this.oldPassSubscribe = this.confirmPasswordFormGrp.get('oldPassword').valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(res => {
        if (this.confirmPasswordFormGrp.controls['oldPassword'].value && this.confirmPasswordFormGrp.controls['oldPassword'].value.length > 0) {
          if(this.uTempDataPrimary && this.uTempDataSecondary)
          {
           let password = this.crypto.EncryptString(this.confirmPasswordFormGrp.controls['oldPassword'].value,this.uTempDataPrimary,this.uTempDataSecondary);
            this.authentication.VerifyPasswordAsyncPut(this.data.userName, password, this);
          }
          else{
            this.authentication.VerifyPassword(this.data.userName, this.confirmPasswordFormGrp.controls['oldPassword'].value, this);
          }
        }
        else{
          this.IsOldPassword = false;
        }

      });
    } else {
      this.IsOldPassword = true;
    }

  }

  savePassword() {
    let oldPassword: string = this.confirmPasswordFormGrp.controls['oldPassword'].value;
    let isValidOldPassword: boolean = oldPassword != undefined && oldPassword.length > 0 ? true : false;
    let newPassword: string = this.confirmPasswordFormGrp.controls['newPassword'].value;
    let isPasswordEncrypted: boolean = false;
    if (this.uTempDataPrimary && this.uTempDataSecondary) {
      isPasswordEncrypted = true;
      newPassword = this.crypto.EncryptString(this.confirmPasswordFormGrp.controls['newPassword'].value, this.uTempDataPrimary, this.uTempDataSecondary);
      if (isValidOldPassword) {
        oldPassword = this.crypto.EncryptString(oldPassword, this.uTempDataPrimary, this.uTempDataSecondary);
      }

    }
    this.authentication.SavePasswordAsyncPost(this.data.userName, newPassword, this, oldPassword, isPasswordEncrypted);
    this.dialog.close(this.confirmPasswordFormGrp.controls['newPassword']);
  }


  /**
   * @function formatingType();
   * @method
   * @param number
   */
  formatingTypeValidation(e, d, f) {
    let returnType;
    if (e == 1) {
      returnType = true;
    } else if (e == 2) {
      if (f) {
        let regexpValue =  new RegExp("^(?=.*[A-Za-z])(?=.*[!@#\$%\^&\*])(?!.*[0-9])");
        returnType = regexpValue.test(d)
        if (returnType) {
          let regx = new RegExp('^[^0-9]+$');
          returnType = regx.test(d);

        }
      } else {
        let regexpValue = new RegExp('^[A-Za-z]+$');
        returnType = regexpValue.test(d)
      }
    } else if (e == 3) {
      if (f) {
        let regexpValue =  new RegExp("^(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?!.*[A-Za-z])");
        returnType = regexpValue.test(d)
        if (returnType) {
          let regx = new RegExp('^[^A-Za-z]+$');
          returnType = regx.test(d);
        }
      } else {
        let regexpValue = new RegExp('^[0-9]+$');
        returnType = regexpValue.test(d)
      }

    } else if (e == 4) {
      if (f) {
        let regexpValue =  new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{4,}$/);
        returnType = regexpValue.test(d)
      } else {
        let regexpValue = new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?!.*[!@#\$%\^&\*])");
        returnType = regexpValue.test(d)
      }
    }
    return returnType;
  }


  /**
   * @function errorFormating();
   * @method
   * @param number
   */
  errorFormating(e, d) {

    let errorMessage
    if (e == 2) {
      errorMessage = (d) ? this.captions.OneSpecialCharactersAndAlphabets : this.captions.OnlyAlphabets
    } else if (e == 3) {
      errorMessage = (d) ? this.captions.OneSpecialCharactersAndNumbers : this.captions.OnlyNumbers
    } else if (e == 4) {
      errorMessage = (d) ? this.captions.OneSpecialCharacterNumberAlphabet : this.captions.AlphabetsAndNumbers
    } else if (e == 1) {
      errorMessage = ""
      this.NoValidation = true;
    }

    return errorMessage;
  }


  /**
   * @function validationMessage();
   * @method
   * @param null
   */
  validationMessage(passwordresult: any) {
    this.minCharacter = passwordresult.minimumCharacters;
    this.maxCharacter = passwordresult.maximumCharacters;
    this.formatingType = passwordresult.formattingType;
    this.allowReuse = passwordresult.allowReuse;
    this.allowUserName = (passwordresult.allowUserName) ? true : false;
    this.allowSpecialCharacters = (passwordresult.allowSpecialCharacters) ? true : false;
  }





  DialogClose = (event) => {
    this.dialog.close(event);
  }

  PasswordValidCheck() {
    this.IsPasswordValid = (this.IsLengthValid && this.IsHavingAllTypes && this.IsLastPassword && this.IsSameAsUserName && this.isDoneValid);
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "SavePassword" ) {
      this.paswordctrl.setValue("");
      let tenantid: number = parseInt(sessionStorage.getItem('Tenantid'));
      this.authentication.login(this.data.userName, this.confirmPasswordFormGrp.controls['newPassword'].value, tenantid, "", this.Login);
    }
    if (callDesc == "SavePasswordPost") {
      this.paswordctrl.setValue("");
      let tenantid: number = parseInt(sessionStorage.getItem('Tenantid'));
      if(this.uTempDataPrimary && this.uTempDataSecondary)
      {
        let password = this.crypto.EncryptString(this.confirmPasswordFormGrp.controls['newPassword'].value,this.uTempDataPrimary,this.uTempDataSecondary);
        this.authentication.loginEnc(this.data.userName, password, tenantid, "", this.Login);
      }
      else{
        this.authentication.login(this.data.userName, this.confirmPasswordFormGrp.controls['newPassword'].value, tenantid, "", this.Login);
      }

    }
    if (callDesc == "CheckPassword"  || callDesc == "CheckPasswordPut") {
      this.IsLastPassword = !Boolean(result.result);
      this.PasswordValidCheck();
    }
    if (callDesc == "VerifyPassword" || callDesc == "VerifyPasswordPut") {
      this.IsOldPassword = Boolean(result.result);
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    let loginErrorMessage: string = '';
    if (callDesc == "SavePassword" || callDesc == "SavePasswordPost") {
      loginErrorMessage = error.errorDescription;
    }
    if (callDesc == "CheckPassword" || callDesc == "CheckPasswordPut") {
      loginErrorMessage = error.errorDescription;
    }
    if (callDesc == "VerifyPassword" || callDesc == "VerifyPasswordPut") {
      loginErrorMessage = error.errorDescription;
    }
    console.error(loginErrorMessage)
  }
  containsSpecialCharacters(text: string): boolean {
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return regex.test(text);
  }
}
