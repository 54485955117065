import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';


export const SetMarkUpPercent: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            const Id =  FormGroupData.controls[FieldGrouping.OdataKey].value;
            httpService.GethttpMethod(`/inventory/api/ProductTypes?$filter=Id eq ${Id}`).subscribe(Res => {
                const ResultSet = Res['value'][0];
                FormGroupData.controls[FieldGrouping.CustomFormAction.EnabledField].setValue(ResultSet.MarkupPercentage);                  
                CommonService.triggerBlurEvent(FieldGrouping.CustomFormAction.EnabledField);
                if (FormGroupData.controls['BaseCurrentCost'].value) {
                    const ResultPrice = parseFloat(FormGroupData.controls['BaseCurrentCost'].value) +
                        (parseFloat(FormGroupData.controls['BaseCurrentCost'].value) / 100) * ResultSet.MarkupPercentage;
                    FormGroupData.controls['ProductPrice'].setValue(ResultPrice);                  
                    CommonService.triggerBlurEvent('ProductPrice');
                }
            });
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

