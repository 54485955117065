import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-field-label-value',
  templateUrl: './form-field-label-value.component.html',
  styleUrls: ['./form-field-label-value.component.scss']
})
export class FormFieldLabelValueComponent {

  @Input() labelHeader: any;
  @Input() labelValue: any;

  

}
