import { Injectable } from "@angular/core";
import { Localization } from '../../localization/localization';
import { DropdownOptions } from "../../Models/ag-models";
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { API, UI,Enum} from "./Kakao-Template-mapping.model";
import { KakaoTemplateDataService } from "../../dataservices/notification/kakaoTemplate.data.service";
import { ActionMode } from "../../enums/shared-enums";
@Injectable()
export class KakaoTemplateMappingBusiness {
Captions : any;
commonCaptions : any;
smsTemplateId : any[] = [];

constructor(private localization: Localization,private _kakaotemplateDataServices: KakaoTemplateDataService,
  private _templateDataServices : TemplateDataServices)
{
    this.Captions = this.localization.captions;
    //this.commonCaptions = this.localization.captions.settings.utilities;
}

public async getAllKakaoMappingTemplate(_includeInactive: boolean) : Promise<UI.KakaoTemplateMapping[]>
{
  let apiModels : API.KakaoTemplateMapping[] = await this._kakaotemplateDataServices.getAllKakaoTemplateMapping(_includeInactive);
  return apiModels.map(x => this.uiMapper(x)); 
}

public async createKakaoMappingTemplate(KakaoTemplateMapping : UI.KakaoTemplateMapping) : Promise<UI.KakaoTemplateMapping[]>
{
  let apiModels: API.KakaoTemplateMapping[] = await this._kakaotemplateDataServices.createKakaoTemplateMapping(this.APIMapper(KakaoTemplateMapping));
  return apiModels.map(x => this.uiMapper(x)); 
}

public async updateKakaoMappingTemplate(KakaoTemplateMapping : UI.KakaoTemplateMapping) : Promise<UI.KakaoTemplateMapping[]>
{
  let apiModels: API.KakaoTemplateMapping[] = await this._kakaotemplateDataServices.updateKakaoTemplateMapping(this.APIMapper(KakaoTemplateMapping));
  return apiModels.map(x => this.uiMapper(x)); 
}

public async deletekakaoMappingTemplate(id: number): Promise<void>
{
  await this._kakaotemplateDataServices.deleteKakaoTemplateMapping(id);
}

public async getKakoTalkTemplateCodes() : Promise<DropdownOptions[]>
  {
    let id = 0;
    let apiModels : DropdownOptions[] = await this._kakaotemplateDataServices.getKakaoTemplateCodes().then(res => {
      return res && res.map(val => {
        id++;
        return {
          id: id,
          value: val.templateCode,
          viewValue: val.templateCode,
        };
      });
    });
    console.log(apiModels);
    return apiModels;
  }

  async getSMSTemplateData(actionMode : ActionMode) : Promise<DropdownOptions[]>{  
     
      let result : DropdownOptions[] =  await this._templateDataServices.GetAllTemplates(2, false).then(res => {
        return res && res.map(val=>
            {return {
            id: val.templateId,
            value: val.templateCode,
            viewValue: val.templateName,
          }});
      });
      if(actionMode == ActionMode.create)
      {
      let apiModels : any[] = await this._kakaotemplateDataServices.getAllKakaoTemplateMapping(true);
      let smsTemplateIds = [];
      if(apiModels)
      apiModels.forEach(kakaoList => 
        {
          smsTemplateIds.push(kakaoList.smsTemplateId)
        });

        smsTemplateIds.forEach(x =>
          {
             result.find(y => y.id == x).disabled = true;
          });
       }
        
        return result;
  }

  getIdFromValue(options: DropdownOptions[], valueToFind: string) {
    const option = options.find(option => option.value === valueToFind || option.viewValue === valueToFind);
    return option ? option.id : null;
  }

  getFallbackMessageType(): Promise<DropdownOptions[]>
  {
    return new Promise<DropdownOptions[]>((resolve, reject) => {
      setTimeout(() => {
        const options: DropdownOptions[] = [
          { id: Enum.FallBackMessageType.ShortMessage, value: Enum.FallBackMessageType.ShortMessage, viewValue : "SM" },
          { id: Enum.FallBackMessageType.LongMessage, value: Enum.FallBackMessageType.LongMessage, viewValue : "LM" }
        ];
        resolve(options);
      }, 1000);
    });
  }

   getEnumName(value: number, myEnum: any): string {
    for (const key in myEnum) {
      if (myEnum[key] === value) {
        return key;
      }
    }
    return '';
  }

  getButtonType()
  {
    return   [
      {
        id: Enum.ButtonType.WebLink,
        value: Enum.ButtonType.WebLink,
        viewValue: "Web Link"
      },
      {
        id: Enum.ButtonType.AddChannel,
        value: Enum.ButtonType.AddChannel,
        viewValue: "Add Channel"
      }
      // {
      //   id: Enum.ButtonType.AppLink,
      //   value: Enum.ButtonType.AppLink,
      //   viewValue: "App Link"
      // },
      // {
      //   id: Enum.ButtonType.DeliveryTracking,
      //   value: Enum.ButtonType.DeliveryTracking,
      //   viewValue: "Delivery Tracking"
      // },
      // {
      //   id: Enum.ButtonType.BotKeyWord,
      //   value: Enum.ButtonType.BotKeyWord,
      //   viewValue: "Bot KeyWord"
      // },
      // {
      //   id: Enum.ButtonType.MessageDelivery,
      //   value: Enum.ButtonType.MessageDelivery,
      //   viewValue: "Message Delivery"
      // },
      // {
      //   id: Enum.ButtonType.ConsultaionTalkConversion,
      //   value: Enum.ButtonType.ConsultaionTalkConversion,
      //   viewValue: "Delivery Tracking"
      // },
      // {
      //   id: Enum.ButtonType.BotConversion,
      //   value: Enum.ButtonType.BotConversion,
      //   viewValue: "Bot Conversion"
      // },
      // {
      //   id: Enum.ButtonType.ImageSecureTransferPlugin,
      //   value: Enum.ButtonType.ImageSecureTransferPlugin,
      //   viewValue: "Image Secure Transfer Plugin"
      // },
      // {
      //   id: Enum.ButtonType.PersonalInformationUsePlugin,
      //   value: Enum.ButtonType.PersonalInformationUsePlugin,
      //   viewValue: "Personal Information Use Plugin"
      // },
      // {
      //   id: Enum.ButtonType.OneClickPaymentPlugin,
      //   value: Enum.ButtonType.OneClickPaymentPlugin,
      //   viewValue: "One Click Payment Plugin"
      // }
]
  }

  public getEditData(data)
  {
    return{
      id : data?.id,
      smsTemplateName : data?.smsTemplateName,
      kakaoTemplateCode : data?.kakaoTemplateCode,
      kakaoTemplate : data?.kakaoTemplate,
      isActive : data?.isActive.value,
      smsTemplates : data?.smsTemplates,
      fallBackMessageType : data?.fallBackMessageType,
      items : data?.items
    }
  }

mapforGrid(smsTemapltes : any[], kakaoMapping :UI.KakaoTemplateMapping[], kakaoTemplateCodes : any[]) : UI.KakaoTemplateMapping[]
{
  const UIData : UI.KakaoTemplateMapping[] = [];
  let KakaoTemplateButtonConfigurationData : UI.KakaoTemplateButtonConfiguration[] = []
  kakaoMapping.forEach(res => {

    const smsTemplate = smsTemapltes.find(x => x.id == res.smsTemplateId);
    const kakaoTemplate = kakaoTemplateCodes.find(x => x.value.toLowerCase() == res.kakaoTemplateCode.toLowerCase());

    res.items.forEach(data => {
      KakaoTemplateButtonConfigurationData.push({
        id : data?.id,
        buttonDisplayName : data?.buttonDisplayName,
        buttonType : data?.buttonType,
        buttonUrlForPc : data?.buttonUrlForPc,
        buttonURLForMobile : data?.buttonURLForMobile,
        isActive : data?.isActive
      });
    });

    UIData.push({
        id: res.id,
        smsTemplateName: smsTemplate ? smsTemplate.viewValue : '',
        kakaoTemplate : kakaoTemplate,
        kakaoTemplateCode: res?.kakaoTemplateCode,
        isActive: res?.isActive,
        fallBackMessageType : res?.fallBackMessageType,
        smsTemplates: smsTemplate,
        items : [...KakaoTemplateButtonConfigurationData]
      } as unknown as UI.KakaoTemplateMapping);
      KakaoTemplateButtonConfigurationData.length = 0;
  });

  return UIData;
}

private uiMapper(kakaoMappingModel : API.KakaoTemplateMapping) : UI.KakaoTemplateMapping
{
  let uiData : UI.KakaoTemplateButtonConfiguration[] = [];
  kakaoMappingModel.kakaoTemplateButtonConfiguration.forEach(element => {
    uiData.push({
      id : element.id,
      buttonDisplayName : element.buttonDisplayName,
      buttonType : element.buttonType,
      buttonUrlForPc : element.buttonUrlForPc,
      buttonURLForMobile : element.buttonUrlForMobile,
      isActive : element.isActive
    });
  });
  const uiMapperdata = {
    id: kakaoMappingModel?.id,
    smsTemplateId: kakaoMappingModel?.smsTemplateId,
    kakaoTemplateCode: kakaoMappingModel?.kakaoTemplateCode,
    isActive: {
      value: kakaoMappingModel?.isActive,
      isDisabled: false
    },
    fallBackMessageType: {
      id: kakaoMappingModel?.fallbackMessageType,
      value: kakaoMappingModel?.fallbackMessageType,
      viewValue: this.getEnumName(kakaoMappingModel?.fallbackMessageType, Enum.FallBackMessageType)
    },
    items: uiData
  } as unknown as UI.KakaoTemplateMapping;  
  return uiMapperdata;
}

private APIMapper(kakaoMappingModel : UI.KakaoTemplateMapping) : API.KakaoTemplateMapping
{
  let apiData : API.KakaoTemplateButtonConfiguration[] = [];
  kakaoMappingModel.items.forEach(element => {
    apiData.push({
      id : element.id,
      buttonDisplayName : element.buttonDisplayName,
      buttonType : element.buttonType,
      buttonUrlForPc : element.buttonUrlForPc,
      buttonUrlForMobile : element.buttonURLForMobile,
      isActive : true
    })
  })
  return {
      id : kakaoMappingModel.id,
      smsTemplateId : kakaoMappingModel.smsTemplates?.id,
      kakaoTemplateCode : kakaoMappingModel.kakaoTemplate.viewValue,
      isActive: typeof kakaoMappingModel?.isActive === 'object' ? kakaoMappingModel?.isActive.value : kakaoMappingModel?.isActive,
      fallbackMessageType : kakaoMappingModel.fallBackMessageType?.value,
      kakaoTemplateButtonConfiguration : apiData      
  } as API.KakaoTemplateMapping
}
}
