import { DropdownEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/select-editor/select-editor.component';
import { TextEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/text-editor/text-editor.component';
import { NumberEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/number-editor/number-editor.component';
import { TextboxWithIconComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/textbox-with-icon/textbox-with-icon.component';
import { CurrencyDropdownComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/currency-dropdown/currency-dropdown.component';
import { DatePickerComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/date-picker/date-picker.component';
import { GridDropDownEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/dropdown/dropdown.component';
import { UnitsDropdownComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/units-dropdown/units-dropdown.component';
import { MultiSelectAutoCompleteComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/multi-select-auto-complete/multi-select-auto-complete.component';
import { DynamicDropDownComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/dynamic-dropdown/dynamic-dropdown';
import { DropdownSelectorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/dropdown-selector/dropdown-selector.component';
import { NomathMenuComponent } from 'src/app/eatecui/source/transaction/nomath-menu/nomath-menu.component';
import { NumberCellEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/number-cell-editor/number-cell-editor.component';

export const SetCustomEditors = (): any => {
    const EditorList = 
    {
        GridCustomCellRenderer: {
            noMathControlEditor: NomathMenuComponent,
            selectEditor: DropdownEditorComponent,
            textEditor: TextEditorComponent,
            textIconEditor: TextboxWithIconComponent,
            numberEditor: NumberEditorComponent,
            currencyEditor: CurrencyDropdownComponent,
            customDatePicker: DatePickerComponent,
            gridDropDown: GridDropDownEditorComponent,
            unitEditor: UnitsDropdownComponent,
            multiSelectAutoComplete: MultiSelectAutoCompleteComponent,
            dynamicDropdown: DynamicDropDownComponent,
            dropdownSelector: DropdownSelectorComponent,
        },
        GridCustomCellEditor: {
            numberCellEditor: NumberCellEditorComponent
        }
    };
    return EditorList;
}