import { Component, forwardRef, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Localization } from '../../../localization/localization';


@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true
    }
  ]
})
export class ToggleSwitchComponent implements ControlValueAccessor {

  constructor(private ls: Localization) {
    this.localisation = ls;
    this.disableToggle = this.disableToggle !== undefined ? this.disableToggle : false;
  }

  get name() {
    return this._value;
  }

  set name(val) {
    this._value = val;

  }
  @Output()  changeToggleEvent: EventEmitter<any> = new EventEmitter();
  @Input() disableToggle = false;
  @Input('value') _value = false;
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  localisation: any;
  onChange = (value: any) => {
    //onChange
  };
  onTouched = () => {
    //onTouched
  };


  writeValue(value): void {
    if (value === true || value === 'true') {
      this.name = true;
    } else {
      this.name = false;
    }

  }

  setDisabledState(isDisabled: boolean): void {
    this.disableToggle  =  isDisabled;
  }

  toggleChange($event) {
    this.name = !this.name;
    this.changeToggleEvent.emit([$event]);
    this.onChange($event);
    this.onTouched();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


}
