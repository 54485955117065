import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { CompLinkform, CategoryArray, EmitCompLinkData, SelectionType } from './component-linking-base.model';
import { cloneDeep } from 'lodash';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { AlertAction, AlertType, ButtonTypes } from '../../Models/common.models';
//Base component for component linking screen
@Component({
  selector: 'component-linking-base',
  templateUrl: './component-linking-base.component.html',
  styleUrls: ['./component-linking-base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ComponentLinkingBaseComponent implements OnInit {
  componentLinkingForm: UntypedFormGroup;
  captions: any;
  typeList: DropdownOptions[];
  typeList$: DropdownOptions[];
  bindCompLinkform: CompLinkform[];
  disableAdd: boolean;
  selectedPaymentMethodsCount: number;
  previousCompNotSelected = false;
  @Input() isViewOnly;
  @Input('bindData')
  set setBindData(value: CompLinkform[]) {
    if (value) {
      this.bindCompLinkform = value;
      if (!this.componentLinkingForm) {
        this.generateForm();
      }
      this.patchValue();
    }
  }
  @Input('dropdownOptions')
  set settypeList(value: DropdownOptions[]) {
    if (value) {
      this.typeList$ = cloneDeep(value);
      this.filterTypeList();
    }
  }

  _singleCategoryArray: CategoryArray[][] = [];
  _multipleCategoryArray: CategoryArray[][] = [];
  @Input('categoryArray')
  set setCategoryArray(value: CategoryArray[]) {
    if (value) {
      this.PushIntoArray(value);
    }
  }

  @Input('bindInitialArrayData')
  set setBindInitialArrayData(value: CategoryArray[][]) {
    if (value) {
      this.patchValue();
      this._singleCategoryArray = cloneDeep(value);
      this._multipleCategoryArray = cloneDeep(value);
      this.selectedPaymentMethodsCount = this.getSelectedRecords();
    }
  }
  automationId:string="ComponentLinkingBase";
  showSelectAll: boolean = false;
  isAllSelected: boolean = false;
  isAllDeSelected: boolean = false;
  @Output() formReady = new EventEmitter();
  @Output() typeChange = new EventEmitter();
  @Output() emittedData: EventEmitter<EmitCompLinkData> = new EventEmitter();
  constructor(private fb: UntypedFormBuilder, private localization: Localization,
    private Commonutils: CommonUtilities) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {

  }

  ngOnChanges(){

  }

  generateForm() {
    this.componentLinkingForm = this.fb.group({
      link: this.fb.array([

      ])
    });
    this.formReady.emit(this.componentLinkingForm);
  }

  patchValue() {
    const formArr = this.componentLinkingForm.get('link') as UntypedFormArray;
    formArr.clear();
    this.bindCompLinkform.forEach((x, index) => {
      let lastIndexMultiSelect;
      if (index == this.bindCompLinkform.length - 1) {
        lastIndexMultiSelect = true
      } else {
        lastIndexMultiSelect = false
      }

      formArr.push(
        this.fb.group({
          type: [x.type, Validators.required],
          multiSelect: lastIndexMultiSelect
        })
      )
    });
    this.previousCompNotSelected = false;
    this.showSelectAll = true;
    this.emitdata(SelectionType.BindDataPatchValue);    
  }

  addItem(index) {
    const formArr = this.componentLinkingForm.get('link') as UntypedFormArray;
    if (this.typeList$?.length > formArr.length) {
      formArr.controls.forEach(x => {
        x['controls']['multiSelect'].setValue(false);
      })
      const formData = this.fb.group({
        type: [null, Validators.required],
        multiSelect: true
      })
      if (index > formArr.length) {
        this.componentLinkingForm.get('link')['controls'][index]['controls']['multiSelect'].setValue(false);
        this.singleCategorySelect[index].map((x, index) => {
          x.checked = index == 0 ? true : false;
        })
        formArr.push(formData);
      } else {
        formArr.insert(index + 1, formData);
      }

      this.previousCompNotSelected = true;
      this.showSelectAll = false;
      this.filterTypeList();
    }
  }

  typeListChange(e, index) {
    const formArr = this.componentLinkingForm.get('link') as UntypedFormArray;
    this.previousCompNotSelected = this.typeList$?.length == formArr.length ? true : false;
    this.typeChange.emit({
      value: e.value,
      index
    });
    this.showSelectAll = true;
    this.makeformDirty();
    this.emitdata(SelectionType.Dropdown);
  }

  filterTypeList() {
    let data;
    const formArr = this.componentLinkingForm.get('link') as UntypedFormArray;
    if (formArr.controls.length > 0) {
      data = this.typeList$.filter(list => {
        return !(formArr.controls.find((x, arrIndex) => {
          if (arrIndex == formArr.controls.length - 1) {
            return false;
          } else {
            return x.value.type == list.id
          }
        }));
      })
    } else {
      data = cloneDeep(this.typeList$);
    }
    this.typeList = [...data];
  }

  removeItem(index) {
    const formArr = this.componentLinkingForm.get('link') as UntypedFormArray;
    this.componentLinkingForm.markAsDirty();
    formArr.removeAt(index);
    if (index != 0) {
      this.componentLinkingForm.get('link')['controls'][formArr.controls.length - 1]['controls']['multiSelect'].setValue(true);
    }
    this.filterTypeList();
    this.PopFromSingleArray(index);
    this.PopFromMultipleArray(index);
    this.previousCompNotSelected = false;
    this.showSelectAll = true;
    this.makeformDirty();
    this.emitdata(SelectionType.RemoveItem);
  }

  emitdata(selectionType: string, discardChanges: boolean = true) {
    const data: EmitCompLinkData = {
      formData: this.componentLinkingForm.getRawValue(),
      form: this.componentLinkingForm,
      singleCategoryData: this._singleCategoryArray,
      multipleCategoryData: this._multipleCategoryArray,
      selectionType: selectionType,
      discardChanges: discardChanges
    }
    this.emittedData.emit(data);
  }

  singleCategorySelect(e, index, Arrindex) {
    if (this.componentLinkingForm.get('link').dirty) {
      this.Commonutils.showCommonAlert(this.localization.captions.common.saveChangesMessage, AlertType.Warning, ButtonTypes.YesNo,
        (res) => {
          if (res === AlertAction.YES) {
            this.singleCategoryEmission(index,Arrindex);
          }
          else {
            this.emitdata(SelectionType.Single, false);            
            return;
          }
        });
    } 
    else
    {
      this.singleCategoryEmission(index,Arrindex);
    }
   
  }
  getSelectedRecords()
  {
    let selectedCount: number = this._multipleCategoryArray[2].filter(x => x.checked).length;
    let totalCount: number = this._multipleCategoryArray[2].length;
    this.isAllSelected = selectedCount === totalCount;
    this.isAllDeSelected = selectedCount === 0 ; 
    return selectedCount;
  }

  singleCategoryEmission(index , Arrindex){
    this._singleCategoryArray[Arrindex].map((x, i) => {
      x.checked = i == index ? true : false;
    });
    this.makeformDirty();    
    this.emitdata(SelectionType.Single);
    this.selectedPaymentMethodsCount = this.getSelectedRecords();
    this.componentLinkingForm.get('link')?.markAsPristine();
    this.componentLinkingForm.get('link')?.markAsUntouched();
  }

  multipleCategorySelect(e: CategoryArray) {
    e.checked = !e.checked;
    this.makeformDirty();
    this.makemultiSelectFormGroupDirty();
    this.emitdata(SelectionType.Multiple);
    this.selectedPaymentMethodsCount = this.getSelectedRecords();
  }

  PushIntoArray(data) {
    if (!data.some(x => x.checked)) {
      data.map((x, index) => { if (index == 0) x.checked = true });
    }
    this._singleCategoryArray.push(cloneDeep(data));
    this._multipleCategoryArray.push(cloneDeep(data));
  }

  PopFromSingleArray(index) {
    if (index != 0 && index) {
      if ((index - 1 < 0) && !this._singleCategoryArray[index - 1].some(x => x.checked)) {
        this._singleCategoryArray[index - 1].map((x, index) => { if (index == 0) x.checked = true });
      }
    }
    if (this._singleCategoryArray[index]) {
      this._singleCategoryArray.pop();
    }
  }

  PopFromMultipleArray(index) {
    if (this._multipleCategoryArray[index]) {
      this._multipleCategoryArray.pop();
    }
  }

  selectAll(e, indx, dataArr) {
    dataArr.map(x => x.checked = true);
    this.makeformDirty();
    this.makemultiSelectFormGroupDirty();
    this.selectedPaymentMethodsCount = this.getSelectedRecords();
    this.emitdata(SelectionType.SelectAll);
  }
  deselectAll(e, indx, dataArr) {
    dataArr.map(x => x.checked = false);
    this.makeformDirty();
    this.makemultiSelectFormGroupDirty();
    this.selectedPaymentMethodsCount = this.getSelectedRecords();
    this.emitdata(SelectionType.DeSelectAll);
  }

  makeformDirty() {
    this.componentLinkingForm.markAsDirty();
    this.componentLinkingForm.updateValueAndValidity();

  }
  makemultiSelectFormGroupDirty() {
    this.componentLinkingForm.get('link')?.markAsDirty();
    this.componentLinkingForm.updateValueAndValidity();
  }

}