import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { SpaUtilities } from "../../shared/utilities/spa-utilities";
import { SpaLocalization } from "../../core/localization/spa-localization";
import { UserSubPropertyAccess } from "../../shared/business/view-settings.modals";
import { BaseResponse } from "../../shared/business/shared.modals";
import { Host } from "../../shared/globalsContant";

@Injectable()

export class UserOutletAccessDataService {

    constructor(private http: HttpServiceCall, private utils: SpaUtilities, private localization: SpaLocalization) { }
    /**
      * @returns {Promise<UserSubPropertyAccess[]>}
      * @memberof UserOutletAccessDataService
      */

    public async GetOutletsAccessByPropertyId(): Promise<UserSubPropertyAccess[]> {
        try {
            return await this.invokeServiceCalls<UserSubPropertyAccess[]>("GetOutletsAccessByPropertyId", HttpMethod.Get, undefined);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    /**
      * @param {number} userId
      * @returns {Promise<UserSubPropertyAccess[]>}
      * @memberof UserOutletAccessDataService
      */
    public async GetOutletsAccessByUser(userId: number): Promise<UserSubPropertyAccess[]> {
        try {
            return await this.invokeServiceCalls<UserSubPropertyAccess[]>("GetSubPropertyAccessByUser", HttpMethod.Get, undefined, { "id": userId });
        } catch (e) {
            this.http.exceptionHandle(e);
      }
    }

    /**
     * @param {UserOutletAccess} userRetailConfiguration
     * @returns {Promise<boolean>}
     * @memberof UserOutletAccessDataService
     */
    public async CreateUserOutletAccess(userOutlets: UserSubPropertyAccess[]): Promise<boolean> {
        try {
            return await this.invokeServiceCalls<boolean>("CreateUserOutletAccess", HttpMethod.Post, userOutlets);
        } catch (e) {
            this.http.exceptionHandle(e);
      }
    }

    /**
     * @param {UserOutletAccess} userRetailConfiguration
     * @returns {Promise<boolean>}
     * @memberof UserOutletAccessDataService
     */
    public async UpdateUserOutletAccess(userOutlets: UserSubPropertyAccess[]): Promise<boolean> {
        try {
            return await this.invokeServiceCalls<boolean>("UpdateUserOutletAccess", HttpMethod.Put, userOutlets);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    private async invokeServiceCalls<T>(callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.retailManagement,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

}