
import { Injectable  } from '@angular/core';
import * as _ from 'lodash';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailLocalization } from '../common/localization/retail-localization';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { Host, InventoryBreakPoint } from '../shared/globalsContant';
import { BaseResponse } from '../shared/business/shared.modals';
import { Inventory } from './inventory.modals';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class InventoryService  {
    constructor(private http: HttpServiceCall, private utils: RetailUtilities,
        public localization: RetailLocalization, private breakPoint: BreakPointAccess) {
    }

    public GetInventoryHeader(isActive) {
        const caption = this.localization.captions.retailInventory;
        const qtyTitle = isActive ? caption.QuantityOnHand : caption.FrozenQunatity;
        return [
            { 'title': caption.Item, 'jsonKey': 'itemNumber', 'alignType': 'right', 'searchable': true, 'sortable': true },
            { 'title': caption.Description, 'jsonKey': 'itemDescription', 'alignType': 'left', 'searchable': true, 'sortable': true },
            { 'title': qtyTitle, 'jsonKey': 'frozenQuantity', 'alignType': 'right', 'searchable': false },
            { 'title': caption.ScannedQuantity, 'jsonKey': 'countedQuantity', 'alignType': 'right', 'sortable': false, 'searchable': false }
        ];
    }

    async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
            return await this.http.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public cancellableObservalble(notifier: Subject<void>, route: string, domain: Host, callType: HttpMethod, body?: any, uriParams?: any): Observable<BaseResponse<any>> {
        return this.http.cancellableObservalble({
            host: domain,
            callDesc: route,
            method: callType,
            body: body,
            uriParams: uriParams
        }, notifier);
    }

    BuildTableData(invOutlet: Inventory.InventoryItemModel, itemData: any, isActive: boolean) {

        let tableData: any = [];
        if (!invOutlet.item || invOutlet.item.length === 0) {
            return tableData;
        }

        const frozenQuantity = (itemId: number) => {
            let frozenQty = 0;
            if (invOutlet.outletCount && invOutlet.outletCount.itemCount && invOutlet.outletCount.itemCount.length > 0) {
                const countData = invOutlet.outletCount.itemCount.find(r => r.itemId === itemId);
                if (countData) {
                    frozenQty = countData.frozenQuantity;
                }
            }
            return frozenQty;
        };

        const updatedQuantity = (itemId: number) => {
            let updatedQty = 0;
            if (invOutlet.outletCount && invOutlet.outletCount.itemCount && invOutlet.outletCount.itemCount.length > 0) {
                const countData: any = invOutlet.outletCount.itemCount.find(r => r.itemId === itemId);
                if (countData) {
                    updatedQty = countData.countedQuantity;
                }
            }
            return updatedQty;
        };

        invOutlet.item.forEach(r => {
            const RetailItem = itemData.find(item => item.id === r.itemId);
            if (RetailItem) {
                tableData.push({
                    id: r.id,
                    itemId: r.itemId,
                    Item: parseInt(RetailItem.itemNumber, 10),
                    Description: RetailItem.itemDescription,
                    FrozenQuantity: isActive ? r.quantity : frozenQuantity(r.itemId),
                    ScannedQuantity: updatedQuantity(r.itemId),
                    Category: RetailItem.category,
                    SubCategory1: RetailItem.subCategory1,
                    SubCategory2: RetailItem.subCategory2,
                    SubCategory3: RetailItem.subCategory3,
                    SubCategory4: RetailItem.subCategory4,
                    SubCategory5: RetailItem.subCategory5,
                    useInventory: RetailItem.useInventory,
                    isActive: RetailItem.isActive
                });
            }
        });

        tableData = tableData.filter(o => o.useInventory === true && o.isActive === true);
        tableData = _.orderBy(tableData, 'Item', 'asc');

        return tableData;
    }

    IsUserAuthorized(type: InventoryActionType): boolean {
        let isAuthorized = false;
        let breakpointNumber: number[] = [];
        switch (type) {
            case InventoryActionType.Freeze:
                breakpointNumber.push(InventoryBreakPoint.FreezeInventory);
                break;
            case InventoryActionType.Release:
                breakpointNumber.push(InventoryBreakPoint.ReleaseInventory);
                break;
            case InventoryActionType.FullUpdate:
                breakpointNumber.push(InventoryBreakPoint.FullInventoryUpdate);
                break;
            case InventoryActionType.PartialUpdate:
                breakpointNumber.push(InventoryBreakPoint.PartialInventoryUpdate);
                break;
            case InventoryActionType.Manual:
                breakpointNumber.push(InventoryBreakPoint.ManualInventory);
                break;
            case InventoryActionType.Transfer:
                breakpointNumber.push(InventoryBreakPoint.ItemTransfer);
                break;
            case InventoryActionType.UpdateQuantityOnHand:
                breakpointNumber.push(InventoryBreakPoint.UpdateQTYonHand);
                break;
            case InventoryActionType.InventoryManagement:
                breakpointNumber.push(InventoryBreakPoint.InventoryManagement);
                break;
            case InventoryActionType.PrintBarCodes:
                breakpointNumber.push(InventoryBreakPoint.PrintBarcodes);
                break;
        }
        if (breakpointNumber.length > 0) {
            isAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
        }
        return isAuthorized;
    }

    BuildItemOutletMappingForTransfer(transferItemData): any {
        let result: any[] = [];
        transferItemData.forEach(item => {
            item.outlets.forEach(outlet => {
                result.push({ itemId: item.itemId, outletId: outlet.toOutletId })
            });
        });
        return result;
    }
}

export const enum InventoryOutletStatus {
    ACTIVE = "ACTIVE",
    FROZEN = "FROZEN"
}

export const enum InventoryCountStatus {
    INPROGRESS = "INPROGRESS",
    COMPLETE = "COMPLETE"
}

export const enum InventoryCountType {
    MANUAL = "MANUAL",
    SCAN = "SCAN"
}

export const enum InventoryCountUpdateType {
    FULL = "FULL",
    PARTIAL = "PARTIAL",
    TEMP = "TEMP"
}

export const enum InventoryActionType {
    Freeze = 1,
    Release = 2,
    FullUpdate = 3,
    PartialUpdate = 4,
    Manual = 5,
    Transfer = 6,
    UpdateQuantityOnHand = 7,
    InventoryManagement = 8,
    PrintBarCodes = 9,
    TempUpdate = 10
}

