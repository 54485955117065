import { BaseReport } from "../common/base-report";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportUIConfig, ReportAPIOptions, ReportParams, AllReports,  StaffFilter, StaffFilterData, StaffReportRequest } from "../report.modals";
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { MiscellaneousSwitch } from 'src/app/retail/shared/globalsContant';

export class ServiceChargeReport extends BaseReport {
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,private PropertyInfo: RetailPropertyInformation, 
        private productId : number,public retailLocalization : RetailLocalization,
        public patcheableValue, private propertySettingsDataService: PropertySettingDataService) {
        super(reportFormGrp);
    }

    houseServiceChargePercentage: string = "0.00";

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.ServiceCharge,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllStaffs(AllReports.ServiceCharge,this.productId),
            inActiveToggle: true,
            dropDownFilterName: this.captions.Users,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false,            
            showHelperText : false,
            hideCorrectedValues: true,
            includeInhouseSC: this.getServiceChargePercentageSettingsValue()
        };
    }

    formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        let houseServiceChargePercentage = this.getHouseServiceChargePercentage();
        houseServiceChargePercentage =  Number(houseServiceChargePercentage)>0? houseServiceChargePercentage : "0.00";
        if(this.reportFormGrp.controls.includeInhouseSCToggle.value == false)
        {
            houseServiceChargePercentage = "0.00";
        }
        if (this.commonLocalization.isFromJobScheduler)
        {
            return [
                { "pStartDate": toApi(this.startDate) },
                { "pEndDate": toApi(this.endDate) },
                { "pPageBreak": this.pageBreakToggle }, 
                { "pFilterCaption": this.getFilterCaption },
                { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
                { "pHouseServiceChargePercentage": houseServiceChargePercentage},
                { "pPropertyName": this.propertyName }, 
                { "pDate": this.printedDate },
                {"pVATEnable":this.PropertyInfo.IsVATEnabled},
                { "fromWildCard": this.reportFormGrp.controls.timelineFrom.value},
                { "fromIncrement": this.reportFormGrp.controls.timelineCountFrom.value},
                { "toWildCard": this.reportFormGrp.controls.timelineTo.value},
                { "toIncrement": this.reportFormGrp.controls.timelineCountTo.value},
                { "PatchEditParam": true},
                { "pInhouseReportType": this.reportFormGrp.controls.reportTypeSCSelection.value}            
            ];
        }
        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle }, { "pFilterCaption": this.getFilterCaption },
        { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
        { "pHouseServiceChargePercentage": houseServiceChargePercentage},
        { "pPropertyName": this.propertyName }, { "pDate": this.printedDate },{"pVATEnable":this.PropertyInfo.IsVATEnabled},
        { "pInhouseReportType": this.reportFormGrp.controls.reportTypeSCSelection.value}];
    }

    formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }, { "reportType": "ServiceCharge" },
        {"HideCorrectedValues":this.reportFormGrp.controls.hideCorrectedValuesToggle.value},
        { "ProductId": this. productId}];
    }

    protected formFilters(): StaffReportRequest {
        let selectedLocations: StaffFilter[] = this.selectedFilterOptions;
        let data = selectedLocations.filter(r => r.showInDropDown).map(s => { return { staffId: s.id, staffType: s.staffType } });
       if(this.commonLocalization.isFromJobScheduler){
        return { staffFilter : data,fromIncrement:this.reportFormGrp.controls.timelineCountFrom.value,
            fromWildCard: this.reportFormGrp.controls.timelineFrom.value,toIncrement:this.reportFormGrp.controls.timelineCountTo.value
            ,toWildCard:this.reportFormGrp.controls.timelineTo.value};
        }       
        return { staffFilter : data};
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);            

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
            
        }, 1);
    }

    getHouseServiceChargePercentage(): string{
        let houseServiceChargePercentage : string = "0.00";
        let houseServiceChargePercentageSession = sessionStorage.getItem('houseServiceChargePercentage');    
        if(houseServiceChargePercentageSession){
            houseServiceChargePercentage = houseServiceChargePercentageSession;
        }
        return houseServiceChargePercentage;
    }

    async getServiceChargePercentageSettingsValue(){
        let showHouseSCToggle = false;
        let miscSettings = await this.propertySettingsDataService.GetMiscConfigurationSetting();
        let houseServiceChargePercentageSettingsValue = miscSettings.find(f => f.switch == MiscellaneousSwitch.HOUSE_SERVICECHARGE_PERCENTAGE);
        if(houseServiceChargePercentageSettingsValue){
            this.houseServiceChargePercentage = String(houseServiceChargePercentageSettingsValue.value); 
        }
        sessionStorage.setItem('houseServiceChargePercentage', this.houseServiceChargePercentage)
        if(Number(this.houseServiceChargePercentage) > 0)
            return true;
        return showHouseSCToggle;
    }
}