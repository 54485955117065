export enum UserAccessBreakPoints {
  TOURNAMENTS = 13015,
  USERSETUP = 2300,
  TOURNAMENTFORMAT = 13000,
  TOURNAMENTPACKAGE = 13005,
  TOURNAMENTCOMPONENTS = 13010,
  TOURNAMENTPLAYERS = 13020,
  TOURNAMENTTEETIMES = 13025,
  TOURNAMENTSCORING = 13030,
  TOURNAMENTPLANCOMPONENTS = 13035,
  CADDYSETUP = 14000,
  TOURNAMENTLISTREPORT = 9050,
  SCORECARDREPORT = 9055,
  CADDYSCHEDULE = 14005,
  RAINCHECKREDEEM = 14500,
  Utilization = 15000,
	TeeTimes = 15005,
	UpcomingTournaments = 15010,
	DashboardWaitList = 15015,
	Lessons = 15020,
	SalesRevenue = 15025,
	Revenue = 15030,
	SalesTop5Items = 15035,
	SalesTop5Categories = 15040,
  DIGITALINVOICEGENERAL = 2000185,
  DIGITALINVOICECONFIG = 2000180,
  CATALOGUETYPE = 2000190,
  CATALOGUESETUP = 2000195,
  TEMPLATEMAPPING = 2000205,
  DIGITALINVOICEMONITOR = 2000210,
  TEMPLATES = 2000200,
  MEMBERPROFILE = 16000,
  MEMBERSTATUS = 16005,
  CREDITBOOK = 16010,
  HOLDTEETIME = 10020,
  LESSONLOCATION = 12045,
  RELEASEHOLD = 10070,
  SQUEEZETEETIME = 10025,
  RATETEETIME = 10035,
  BOOKTEETIME = 10015,
  ROLESETUP = 2305,
  RATETYPE = 12020,
  PLAYERTYPE = 12015,
  CANCELREASONS = 12080,
  LOSTORDENIEDREASONS = 12090,
  GOLFSYSTEMSETUP = 12001,
  SPASYSTEMSETUP = 2085,
  COMMISIONSETUP = 800,
  RECEIPTCONFIGURATION = 7080,
  INVENTORYMANAGEMENT = 8722,
  CODELINKING = 12025,
  COURSE = 12000,
  ALLOCATIONBLOCKASSIGNMENT = 12065,
  RATESETUP = 12055,
  RAINCHECKSETUP = 12035,
  TeeTimeAllocationBlock = 12010,
  CourseCommentSetup = 12040,
  TimeIntervalSetup = 12060,
  TeeColor = 12005,
  TeeSheet = 10010,
  InstructorsSetup = 11000,
  ClearPlayers = 10060,
  Copy = 10045,
  CopytoMany = 10050,
  Move = 10040,
  DragDrop = 10145,
  FROSTDELAY = 10030,
  InstructorSchedule = 11005,
  DefaultUserConfig = 11505,
  CUSTOMFIELD = 12050,
  INSTRUCTORSCHEDULEREPORT = 9070,
  CANCELPLAYERS = 10055,
  TeeTimeSearch = 10080,
  RAINCHECKISSUE = 10140,
  CADDYTYPE = 12030,
  TeeTicketReport = 9025,
  VIEWWAITLIST = 10090,
  ADDWAITLIST = 10085,
  DELETEWAITLIST = 10095,
  CONVERTWAITLIST = 10100,
  LOSTANDFOUND = 11510,
  ROUNDSBYRATETYPE = 9090,
  ROUNDSBYPLAYERTYPE = 9095,
  TeeTimeUtilizationReport = 9075,
  RAINCHECKISSUEDREPORT = 9030,
  CancellationTeeTime = 9005,
  NoShowTeeTime = 9010,
  WaitList = 9015,
  BagTags = 9020,
  BOOKLESSON = 10115,
  CANCELLESSON = 10120,
  BULKMOVE = 10165,
  BULKCANCEL = 10170,
  BULKCLEAR = 10175,
  BULKCOPY = 10180,
  BULKTEETIMESBOOK = 10155,
  BULKTEETIMESEDIT = 10160,
  PRINT = 10075,
  PRINTINVENTRYITEMAGRREMENTS = 10076,
  GROUPTEETIMES = 10150,
  SHOP = 10065,
  TEETIMEREFUND = 10135,
  USERROLECONFIGURATION = 2310,
  DAYEND = 11500,
  TRANSACTIONLOGREPORT = 9120,
  PLAYERPROFILE = 700,
  REOPENTRASACTION = 5000,
  ADD_DEPOSIT = 10105,
  MARKASPAID = 10125,
  CHANGEALLOCATIONBLOCK = 10185,
  ALLOWBLOCKTEETIME = 10190,
  MEMBERCONFIGURATION = 16015,
  BOOKINGPERMISSIONS = 12070,
  OVERRIDEPLAYERTYPEDAYSOUT = 10195,
  CADDYREQUIREMENTS = 14010,
  PACKAGE_ALLOCATIONCODE = 12075,
  COMBINEGUESTRECORDS=2435,
  DEDUPEGUESTPROFILES=12120,
  ZEROMEMBERCHARGE= 10200,
  PRINTERDEFAULTCONFIGURATION =15065,
  CLUBAGREEMENT=15045,
  CARTAGREEMENT=15050,
  SHOEAGREEMENT=15055,
  RETAILSALECHIT=15060,
  ISSUEV1GIFTCARD=3075,
  ISSUEEXTERNALGIFTCARD=3080,
  SETTLETOGROUPCHARGE=3085,
  PLAYERPOINTS = 150055,
  PLAYERCOMPS = 150060,
  QUICKIDCONFIG = 16020,
  OVERRIDERATETYPEDAYSOUT = 15165,
  NIGHTAUDITCONFIG = 16025,
  STAFFREADERBOARD = 20035,
  AUTHORIZEPAYMENT = 3100,
  EXPORT =200410,
  IMPORT =200415,
  FILEIMPORT = 200470,
  EmailTemplate=20040,
  SMSTemplate=20045,
  ReportTemplate=20050,
  WhatsappTemplate = 20080,
  EVENTSCHEDULER = 100775,
  SFTP = 85255,
  JOBSCHEDULERCONFIG = 100776 ,
  DistributionList=20055,
  KakaoMapping = 9190,
  SYSTEMDEFAULT = 200435,
  SAMSOTECHINTEGRATION=85180,
  VCART = 160105,
  VCARTVIEWALLITINERARIES = 160106,
  PMSINTEGRATION = 15130,
  ADDEDITDISCOUNTMAPPING = 42709,
  
  //Generic Extract
  ExtractForAccounting = 190120,
  JobschedulerConfiguration = 100776,
  DOCUMENT_MANAGEMENT_CODES_TYPES_SETTINGS = 85145,

  //VipType
  VipType = 2110,
  //Spa-CancellationNoShowFee
  CancellationNoShowPolicy = 2000151,
  CancellationNoShowMapping = 2000152,
  WaiveOffCharges = 20791,
   //Dashboard
   CUSTOMIZABLEHOMEDASHBOARD = 2320,

   AUTHORIZE_CONFIGURATION = 12110

}
