import { EnvService } from 'src/app/eatecui/source/config.service';
import { environment } from 'src/environments/environment';


// export const BASEURL: string = environment.baseUrl;
// export const OdataUrl: string = environment.libOdataUrl;
// export const UserManagementUrl: string = environment.UserManagementUrl;

export const ENDPOINTURL: object = {
    OdataApi: {
        GetMetaData: '/inventory/api/$metadata',
        GetMetaReportData: '/reportdata/api/$metadata',
    },
    OdataInventoryManagementApi: {
        GetMetaData: '/inventory/api/$metadata',
    },
    UserManagementApi: {
     GetVerifiedUser: '/usermgmt/api/Login/verifyuser',
     GetclientId: '/usermgmt/api/Login/getauthclientid',
     GetToken: '/identity/api/Identity/token',
     GetSSOClientId: '/usermgmt/api/Login/sso',
     GetSSOToken: '/identity/connect/token',
     GetOnPrem: '/api/isOnPrem',
     GetUsersList: '/usermgmt/api/users/all',
     GetUserById: '/usermgmt/api/user/id',
     PostUser: '/usermgmt/api/users/save',
     UpdateUser: '/usermgmt/api/users/update',
     DeleteUser: '/usermgmt/api/User',
     ActivateDeactivateUser: '/usermgmt/api/users/patch',
     PostRole: '/usermgmt/api/Role',
     VerifyADUser: '/usermgmt/api/Login/verifyADUser',
     GetRole: '/usermgmt/api/Role',
     Role: '/usermgmt/api/Role',
     PermissionResource: '/usermgmt/api/PermissionResource',
     ModuleResource: '/usermgmt/api/Module',
     ChangePassword: '/usermgmt/api/Login/changepassword',
     ForgotPassword: '/usermgmt/api/Login/forgotpassword',
     ProductRemapLookup: '/usermgmt/api/UIModuleLookup'
    },
    InvertoryManagement: {
       GetSystemPreference: '/inventory/api/TransactionSystemPreferences',
       GetUnitConversion: '/inventory/api/UnitConversions',
       GetNutrients: '/inventory/api/Nutrients',
       GetUnits: '/inventory/api/Units',
       GetInventoryNutrition: '/inventory/api/NutritionManagement',
       GetIngredientSearch: '/inventory/api/v2/ingredients/masterlookup',
       GetVendorPunchPutConfiguration: '/inventory/api/Integration/VendorPunchoutConfiguration',
       GetInventoryLocation: '/inventory/api/GetLocations',
       GetInventoryCustomer: '/inventory/api/GetCustomers',
       GetDataForFilters: '/inventory/api/GetDataForFilters',
       GetMasterFilterData: '/inventory/api/GetFilterDataForMaster',
       GetPriorities: '/inventory/api/GetPriorities',
       GetEventTypes: '/inventory/api/GetEventTypes',
       GetEventStatus: '/inventory/api/GetEventStatus',
       GetAllEvents: '/inventory/api/GetAllEvents',
       GetRooms: '/inventory/api/GetRooms',
       GetSalesPerson: '/inventory/api/GetSalesPerson',
       GetCustomerContacts: '/inventory/api/GetCustomerContacts',
       GetCustomers: '/inventory/api/GetCustomers',
       GetSuppliers: '/inventory/api/GetSuppliers',
       GetRecipes: '/inventory/api/GetRecipes',
       PlanAverageFormula: '/inventory/api/PlanAverageFormula',
       GetCustomerTypes: '/inventory/api/GetCustomerTypes',
       GetMRPData: '/inventory/api/GetMRPData',
       GetPackageMasterData: '/inventory/api/GetPackageMasterData',
        GetClassesTypesPackages: '/inventory/api/GetClassesTypesPackages',
        GetCurrencies: '/inventory/api/GetCurrencies',
        GetPhysicalInventoryMasterData: '/inventory/api/GetPhysicalInventoryMasterData',
        GetBatchPOMasterData: '/inventory/api/GetBatchPOMasterData',
        GetMasterData: '/inventory/api/GetMasterData',
        GetTransferMasterData: '/inventory/api/GetTransferMasterData',
        GetRequisitionMasterData: '/inventory/api/GetRequisitionMasterData',
        GetSpoilageMasterData: '/inventory/api/GetSpoilageMasterData',
        GetBPOMasterData: '/inventory/api/GetBPOMasterData',
        GetSupplierBidsMasterData: '/inventory/api/GetSupplierBidsMasterData',
        GetVendorPunchthroughConfiguration: '/inventory/api/Integration/VendorPunchthroughConfiguration',
        GetBinUtilityMasterData: '/inventory/api/GetBinUtilityMasterData',
        GetPurchaseRequestMasterData: '/inventory/api/GetPurchaseRequestMasterData',
        GetPurchaseOrderMasterData: '/inventory/api/GetPurchaseOrderMasterData',
        GetReceivingMasterData: '/inventory/api/GetReceivingMasterData',
        GetAPVoucherMasterData: '/inventory/api/GetAPVoucherMasterData',
        GetAPCreditMasterData: '/inventory/api/GetAPCreditMasterData',
        GetPurchaseRequestPunchOutMasterData: '/inventory/api/GetPurchaseRequestPunchOutMasterData',
        GetPurchaseOrderPunchOutMasterData: '/inventory/api/GetPurchaseOrderPunchOutMasterData',
    },
    WexFlow: {
        WexFlowLogin: '/wexflowservice/wexflow/login',
        WexFlowRun: '/wexflowservice/wexflow/start',
        WexFlowSave: '/wexflowservice/wexflow/save',
        WexFlowDelete: '/wexflowservice/wexflow/deleteWorkflows'
     },
    TenantManagementApi : {
        GetTenant: '/tenantservice/api/Tenant',
        GetAdTenant: '/api/Tenant',
        GetUserFlow:  '/tenantservice/api/TenantService/SystemConfiguration?configKey=B2CLoginUserflow'
    },
    Utility: {
        GetOnPrem: '/api/isOnPrem'
    },
    Report: {
        GetMyDataConfig: '/reportdata/api/MyDataConfig',
        GetReportModulePrint: '/inventory/api/Print',
        GetModuleTemplate: '/reportdata/api/FetchTemplateByModuleName',
        GetZplDownloadData: '/reportdata/api/DownloadZPLData',
        GetNetWorkPrinter: '/reportdata/api/LabelPrint/Printers',
        GetReportModules: '/reportdata/api/ReportsModule',
        GetReportModuleById: '/reportdata/api/ReportsModule/SearchReportsByName',
        GetReportFilters: '/reportdata/api/ReportsModule/reportfilter'
    },
    DocumentApproval: {
        GetTransactionApproval: '/inventory/api/GetApprovalTransactions',
        ProcessApprovalTransaction: '/inventory/api/ProcessApprovalTransactions'
    },
    DataImport: {
        WexflowsModuleValue: '/exchange/api/dataimport/wexflows',
        ValidationSummaryHistory: '/exchange/api/dataimport/workflow/history',
        UploadFile: '/exchange/api/dataimport/uploadfile',
        DownloadFile: '/exchange/api/dataimport/download'
    },
    DMIntegration: {
        GetDMData: '/inventory/api/DMIntegrationConfiguration',
        GetLoginToken: '/inventory/api/Integration/GetIntegrationConfigToken',
        DocumentPost: 'https://v1-to-vradatamagine.hospitalityrevolution.com/dmsserver/dmsdoc/api/dms/indexingv2',
        DocumentDelete: 'https://v1-to-vradatamagine.hospitalityrevolution.com/dmsserver/dmsdoc/api/dms/folders/indexeddocs',
        GetDocument: 'https://v1-to-vradatamagine.hospitalityrevolution.com/dmsserver/dmsdoc/api/dms',
        GetMasterData: '/inventory/api/DMIntegrationConfiguration?$filter=IsActive eq true',
        GetConfigData: '/inventory/api/Integration/GetIntegrationConfig',
        GetDocumentSample: '/assets/json/transaction/integrationget.json',
        DocumentDownload: 'https://v1-to-vradatamagine.hospitalityrevolution.com/dmsserver/dmsdoc/api/dms/documentoperations/folders/folderid/documents/documentid'
    },
    Setup: {
        IntegrationConfig: '/inventory/api/Integration/GetIntegrationConfig',
        PostIntegrationConfig: '/inventory/api/Integration/SaveIntegrationConfigFields',
        GetAllCurrency: '/inventory/api/GetAllCurrencies',
        GetAllExchangeRates: '/inventory/api/GetAllExchangeRates',
        SaveExchangeRate: '/inventory/api/SaveExchangeRate',
        GetExchangeRateDetailById: '/inventory/api/GetExchangeRateDetailById',
        DeactivateExchangeRates: '/inventory/api/DeactivateExchangeRates',
        SaveCurrency: '/inventory/api/SaveCurrency',
        GetCurrencyDetailById: '/inventory/api/GetCurrencyDetailById',
        DeactivateCurrencies: '/inventory/api/DeactivateCurrencies',
        GetLabelTemplates: '/reportdata/api/GetLabelTemplates',
        GetLabelTemplateById:'/reportdata/api/GetLabelTemplateById',
        SaveLabelTemplates:'/reportdata/api/LabelTemplates',
        GetLabelVariables: '/reportdata/api/GetLabelVariables',
        DeactivateTemplates: '/reportdata/api/DeactivateTemplates'
    },
    PhysicalHead: {
        getPIImportedData: '/inventory/api/Integration/PhysicalInventory?cxdate=',
        postPIImportedData: '/inventory/api/Integration/SavePhysicalInventory'
    },
    MessagesApi: {
        GetAllMessages: '/inventory/api/GetAllMessages',
        GetMessage: '/inventory/api/GetMessageDetailById',
        GetMessageFolders: '/inventory/api/MessageFolders'
    },
    EatecDMIntegration: {
        DocumentPost: '/inventory/api/Integration/CreateDocument',
        DocumentDelete: '/inventory/api/Integration/DeleteDocument',
        GetDocument: '/inventory/api/Integration/RetrieveAllDocument',
        DocumentDownload: '/inventory/api/Integration/RetrieveDocument',
        Mapping: '/inventory/api/DMIntegrationConfiguration'
    },
    PunchOutAPI: {
        AuthCall: '/inventory/api/Integration/AuthenticateVendorPunchout',
        DeletePunchout: '/inventory/api/Integration/DeletePunchOut'
    },
    HealthCheck: {
        reportdata: '/reportdata/healthcheck',
        report: '/report/healthcheck',
        wexflow: "/wexflowservice/healthcheck",
        notification: "/notification/healthcheck",
        inventory: "/inventory/healthcheck",
        usermgmt: "/usermgmt/healthcheck",
        publisher: "/publisher/healthcheck"
    },
    QuickOrder: {
        GetQuickOrderTiles: '/inventory/api/GetQuickOrderTiles'
    },
    Events: {
        GetAllEvents: '/inventory/api/GetAllEvents',
        GetEventTiles: '/inventory/api/EventTiles',
        DeactivateEvent: '/inventory/api/DeactivateEvent',
        UnlinkQuickOrder: '/inventory/api/UnlinkQuickOrder',
        PostEvent: '/inventory/api/SaveEvent',
        GetByIdEvent: '/inventory/api/GetEventDetailById',
        GetAllQuickOrdersList: '/inventory/api/GetAllQuickOrders',
        FinalizeEvent: '/inventory/api/FinalizeEvent',
        GetCustomerOrderById: '/inventory/api/GetQuickOrderById',
        GetCustomerInvoiceById: '/inventory/api/GetCustomerInvoiceById'
    },
    DeliveryRulesCutOffCheck: {
        CheckDeliveryCutOff: '/inventory/api/CheckDeliveryCutOff'
    },
    IGIntegration: {
        GetAllProductClasses: '/inventory/api/Integration/MenuItemsImport'
    },
    ItemLookup: {
        GetClassesTypesPackages: '/inventory/api/GetClassesTypesPackages'
    }
};

    