import { ConversionFormater } from './conversion-formater.interface';
import { CurrencyFormater } from './currency-formater.interface';
import { DecimalFormater } from './decimal-formaters.interface';
import { QuantityFormater } from './qunatity-formater.interface';

export const Formaters = {
   QuantityFormeter: QuantityFormater,
   CurrencyFormater: CurrencyFormater,
   ConversionFormater: ConversionFormater,
   DecimalFormater: DecimalFormater
};
