import { Component, forwardRef, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Localization } from '../../../localization/localization';


@Component({
  selector: 'app-common-toggle-switch',
  templateUrl: './common-toggle-switch.component.html',
  styleUrls: ['./common-toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => commonToggleSwitchComponent),
      multi: true
    }
  ]
})
export class commonToggleSwitchComponent implements ControlValueAccessor {
  @Output()  changeToggleEvent:EventEmitter<any> = new EventEmitter();
  @Input() disableToggle: boolean = false;
  @Input('value') _value = false;
  @Input() automationId: string;
  onChange = (value: any) => {
    //onchange
  };
  onTouched = () => {
    //ontouched
  };
  localisation:any;

  constructor(private ls:Localization) {
    this.localisation = ls;
    this.disableToggle = this.disableToggle != undefined ? this.disableToggle : false
  }

  get name() {
    return this._value;
  }

  set name(val) {
    this._value = val;

  }


  writeValue(value): void {
    if (value || value == "true") {
      this.name = true;
    }
    else {
      this.name = false;
    }

  }

  setDisabledState(isDisabled :  boolean) :  void {


    this.disableToggle  =  isDisabled;
  }

  toggleChange($event) {
    this.name = !this.name;
    this.changeToggleEvent.emit([$event]);
    this.onChange($event);
    this.onTouched();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
