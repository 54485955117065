import { Component, OnInit, Output, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-retail-sales-mix',
  templateUrl: './sales-mix.component.html',
  styleUrls: ['./sales-mix.component.scss']
})
export class RetailSalesMixComponent implements OnInit {
  @Input() outlets: any;
  public captions: any = this.localization.captions.reports;
  @Output() validateUser = new EventEmitter<boolean>();
  @Output() disableGenerateEvtEmitter: EventEmitter<any> = new EventEmitter();
  SalesMixFormGrp: UntypedFormGroup;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  selectedDropdownType: string = '';
  valuesSelected: any[];
  users: DropDownData[];
  inActiveText = '';  
  validSelection: boolean = true;
  validSelectionErrMsg: string;
  editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, public business: ReportBusinessService, private dataService: ReportDataService, public localization: RetailLocalization
    ,private container: ViewContainerRef) {
    this.inActiveText = this.captions.IncludeInactiveUsers;
  }

  ngOnInit() {
    this.SalesMixFormGrp = this.fb.group({
      users: new UntypedFormControl([]),
      includeInactiveusers: false,
      toggleIncludeReturnWithoutTicket: false   
    });
    this.formReady.emit(this.SalesMixFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
        if(patchItem.UserIds != undefined){
          this.dataService.GetAllUsersByPropertyId().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.UserIds.find(x => x == item.id);
            });
            this.users = arr;     
          });
        } 
        if(patchItem.IncludeReturnWithoutTicket != undefined){
          this.SalesMixFormGrp.controls['toggleIncludeReturnWithoutTicket'].patchValue(patchItem.IncludeReturnWithoutTicket)
        } 
        }
    } 
  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsersByPropertyId();
    this.users = this.business.toggleIncludeInactive(false, this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u => u.showInDropDown);
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.users = this.business.toggleIncludeInactive(IncludeInactiveToo, this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u => u.showInDropDown);
    this.SalesMixFormGrp.controls['users'].setValue([this.users]);
  }

  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      this.validSelectionErrMsg = this.localization.captions.reports.PleaseSelectAtleastOneUser;
    }
  }
  getFormControlValue(event) {
    if (event[0] === 'users') {
      this.SalesMixFormGrp.setControl('users', event[1]);
    }
  }
}
