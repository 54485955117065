import { BaseEventPublisher } from "./base.event.publisher";
import { BaseEventSubscriber } from "./base.event.subscriber";

export class RetailEventPublisher extends BaseEventPublisher {

    constructor() {
        super();
    }

    public addSubscriber(subsriber: BaseEventSubscriber): void {
        if (!this.has(subsriber.getKey()))
            this.add(subsriber);
    }

}