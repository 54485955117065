import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { AlertType, ButtonTypes, AlertAction } from '../Models/common.models';
import { Localization } from 'src/app/common/localization/localization';
import { GridType, LogTypeErrorCodes } from './log-type.model';
import { BaseResponse } from './log-type.model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { EmptyValueValidator } from './empty-value.validator';
import { CommonDataAwaiters } from "src/app/common/shared/events/awaiters/common.data.awaiters";


@Component({
  selector: 'app-log-type',
  templateUrl: './log-type.component.html',
  styleUrls: ['./log-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogTypeComponent implements OnInit {

  logTypeForm: UntypedFormGroup;
  captions: any;
  addButton: ButtonValue;
  cancelButton: ButtonValue;
  floatLabel: string;
  searchText: string = '';
  tableoptions: any;
  tableDataTemp;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isEdit: boolean = false;
  isAddDisable: boolean = false;
  currentId: number = 0;
  searchTempData: string;
  onEditDisableFunction: boolean = true;
  isUserAuthorized = true;
  isViewOnly=true;
  constructor(private localization: Localization, private fb: UntypedFormBuilder,private utils: CommonUtilities)
 {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;

  }

  ngOnInit() {
    this.initializeForm();
    this.buttonInitialize();
    this.ValidateBreakPoint();
    this.bindtoGridData();
  }

  initializeForm() {
    this.logTypeForm = this.fb.group({
      logTypeinput: ['', [Validators.required, EmptyValueValidator]],
      showActive: false,
      toggleActive: ''
    })
    this.logTypeForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      // if(this.lostDenialForm.controls['lostdenialinput'].value.length >=3){
      this.addButton.disabledproperty = !(this.logTypeForm.valid && this.logTypeForm.dirty && this.logTypeForm.value.logTypeinput.length >= 3);
      this.addButton = { ...this.addButton };
      // }
      // else{
      //   this.addButton.disabledproperty = true;
      //   this.addButton = { ...this.addButton };
      // }
    })
  }
  buttonInitialize() {
    this.addButton = {
      type: 'primary',
      label: this.captions.btn_add,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }
  async bindtoGridData(formvalue: any = null, includeinactive: boolean = false, searchByLogTypeInstance: boolean = false) {
    let arrDataHeader = [
      { 'title': this.captions.tbl_hdr_logType, 'jsonkey': 'logTypeInstance', 'searchable': true, 'sortable': true, 'Sortable': 'logType' },
      { 'title': this.captions.lbl_Active, 'jsonkey': 'isActive', 'searchable': false, 'sortable': true, "type": "toggle" },
    ]
    if (formvalue == null && searchByLogTypeInstance == false) {
      this.tableDataTemp = await this.getAllLogType(includeinactive);
    }
    else if (searchByLogTypeInstance == true) {
      this.searchTempData = this.tableDataTemp.filter(t => t.logTypeInstance.toLowerCase().includes(this.searchText.toLowerCase()));
    }
    else {
      this.tableDataTemp = formvalue;
    }
    let showInactiveTogglevalue = this.logTypeForm.controls.showActive.value;
    if (!showInactiveTogglevalue) {
      this.tableDataTemp = this.tableDataTemp.filter(t => t.isActive);
    }

    if (this.searchText != '' && searchByLogTypeInstance == false) {
      this.searchTempData = this.tableDataTemp.filter(t => t.logTypeInstance.includes(this.searchText));
    }
    this.tableoptions = [
      {
        TableHdrData: arrDataHeader,
        TablebodyData: this.searchText != '' ? this.searchTempData : this.tableDataTemp,
        pagination: false,
        sortable: true,
        editable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.WaitListSearchPlaceHolder,
        EnableActions: true,
        SelectRows: false,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: GridType.lostDeniedreason,
        Sortable: 'logType',
        TableId: '',
        disableDelete: false,
        SelectRow: true,
        SelectOnlyRow: true,
        dropdownOptions: '',
        waitlistIcon: false,
        TableDraggable: false,
        sticky: true,
        DoneCancel: false,
        IsViewOnly: this.isViewOnly,
        disableEditButton: this.isViewOnly
      }
    ];
  }
  // onSave(event){
  //   console.log(this.logTypeForm)
  //   this.isEdit = !this.isEdit;
  //   this.addButton.label = this.captions.btn_add;
  //   this.addButton = {...this.addButton}
  //   this.logTypeForm.reset()
  // }

  async onSave(event) {
    this.addButton.disabledproperty = true;
    this.addButton = {...this.addButton};
    let createorupdateresult = await this.createorUpdateLogType();
    this.utils.ToggleLoader(false);
    if (createorupdateresult) {
      this.bindtoGridData(createorupdateresult);
      this.isEdit = this.isEdit ? !this.isEdit : false;
      this.ResetForm();
    }
  }

  async createorUpdateLogType() {
    const logtype: any = {
      logTypeInstance: this.logTypeForm.controls.logTypeinput.value,
      isActive: this.isEdit ? this.logTypeForm.controls.toggleActive.value : true
    };
    let saveorUpdateStatus;
    if (this.isEdit) {
      try {
        this.utils.ToggleLoader(true);
        saveorUpdateStatus =  await CommonDataAwaiters.EditLogType(logtype, this.currentId);
        return saveorUpdateStatus.result;
      } catch (e) {
        console.log(e?.error);
        this.utils.ToggleLoader(false);
        this.showErrorMessageFromLogTypeAPI(e.error);
        throw e;
      }

    } else {
      try {
        this.utils.ToggleLoader(true);
        saveorUpdateStatus = await CommonDataAwaiters.CreateLogType(logtype);
        return saveorUpdateStatus.result;
      } catch (e) {
        console.log(e);
        this.utils.ToggleLoader(false);
        this.showErrorMessageFromLogTypeAPI(e.error);
        throw e;
      }
    }

    
  }

  onCancel(event) {
    this.isEdit = !this.isEdit;
    this.onEditDisableFunction = true;
    this.ResetForm();
  }

  ResetForm() {

    this.addButton.label = this.captions.btn_add;
    this.addButton.disabledproperty = true;
    this.addButton = { ...this.addButton };
    let showActivevalue = this.logTypeForm.controls.showActive.value;
    this.logTypeForm.reset();
    this.logTypeForm.get('showActive').setValue(showActivevalue);
    this.currentId = 0;
    this.isAddDisable = false;
  }

  onToggleChange(event) {
    this.bindtoGridData(null,event[0]);
    this.bindtoGridData(null,null,true);
  }

  searchByLogChange(event) {
    this.searchText = event;
    this.bindtoGridData(null,null,true);
  }
  async DeleteConfirm(event) {
    let needConfirmation = await CommonDataAwaiters.GetConfirmationSetting();
    if(needConfirmation)
    {
      let message = this.captions.deleteConfirmation;
      this.utils.showAlert(message, AlertType.Warning, ButtonTypes.YesNo, async(res) => {
      if (res === AlertAction.YES) {
        let result = await this.DeleteLogType(event[0].id);
        this.bindtoGridData(result);
      }
    });
    }
    else
    {
      let result = await this.DeleteLogType(event[0].id);
      this.bindtoGridData(result);
    }
  }
  EditRecords(event) {
    this.isEdit = true;
    this.addButton.label = this.captions.btn_edit;
    this.addButton.disabledproperty = true;
    this.addButton = { ...this.addButton };
    this.logTypeForm.get('logTypeinput').setValue(event[0].logTypeInstance);
    this.logTypeForm.get('toggleActive').setValue(event[0].isActive);
    this.currentId = event[0].id;
  }
  inactiveSliderChange(eve) {
    this.isAddDisable = true;
  }
  CancelEvent(event) {
    this.ResetForm();
    this.isAddDisable = false;
  }
  async DoneEvent(event) {
    let result = await this.UpdateLogType(event);
  }
  async getAllLogType(includeinactive: boolean) {
    try {
      return await CommonDataAwaiters.GetAllLogType(includeinactive);
      
    } catch (e) {
      console.error(e);
      console.log(e.error);
      throw e;
    }

  }

  async UpdateLogType(inputdata) {
    try {
      this.utils.ToggleLoader(true);
      await CommonDataAwaiters.UpdateLogType(inputdata);
      this.utils.ToggleLoader(false);
      this.isAddDisable = false;
      this.bindtoGridData(null,this.logTypeForm.controls.showActive.value);
      this.successCallback.bind(this);
    } catch (e) {
      this.utils.ToggleLoader(false);
      this.isAddDisable = false;
      this.errorCallback.bind(this),
      console.log(e.error);
      this.showErrorMessageFromLogTypeAPI(e.error);
      throw e;
    }
  }

  errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(result);
    this.utils.ShowError(this.localization.captions.common.Error, this.localization.getError(result.errorCode));
    this.logTypeForm.get('showActive').setValue(false);
    this.bindtoGridData();
    this.ResetForm();
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    this.bindtoGridData(result.result);
    this.isAddDisable = false;
  }
  async DeleteLogType(id:number){
   try {
      return await CommonDataAwaiters.DeleteLogType(id);
    } catch (e) {
      console.error(e);
      console.log(e?.error);
      this.showErrorMessageFromLogTypeAPI(e.error);
      throw e;
    }
  }
   async ValidateBreakPoint(){
    try {
      this.isUserAuthorized = await CommonDataAwaiters.CheckForAccess();
      this.isViewOnly = await CommonDataAwaiters.isViewOnly();
    } catch (e) {
      console.error(e);
      console.log(e.error);
      this.showErrorMessageFromLogTypeAPI(e.error);
      throw e;
    }
    if (this.isViewOnly) {
       this.utils.disableControls(this.logTypeForm);
    }
  }
  showErrorMessageFromLogTypeAPI(errorFromAPI){
    let errorMessageToBeShown = "";
    if(errorFromAPI.errorCode == LogTypeErrorCodes.DUPLICATE_LOGTYPE_INSTANCE){
      errorMessageToBeShown = this.captions.DUPLICATE_LOGTYPE_INSTANCE;
    }
    else if(errorFromAPI.errorCode == LogTypeErrorCodes.LOGTYPE_ID_DOES_NOT_EXIST){
      errorMessageToBeShown = this.captions.LOGTYPE_ID_DOES_NOT_EXIST;
    }
    else if(errorFromAPI.errorCode == LogTypeErrorCodes.LOGTYPE_IS_IN_USE){
      errorMessageToBeShown = this.captions.LOGTYPE_IS_IN_USE;
    }
    if(errorMessageToBeShown && errorMessageToBeShown.length > 0){
      this.utils.showCommonAlert(errorMessageToBeShown,AlertType.Warning);
      this.bindtoGridData();
    }
  }
}
