import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { LookupConfiguration, TransactionConfiguration, TransactionLookupData } from '../transaction.interface';
// import { ItemListSearch } from '../view-transaction/view-configuration.interface';
// import * as LookupData from '../../../../../assets/json/lookup-data.json';
import { LoadOrderGuideModel } from './create-transaction-config.interface';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopUpDataModel } from '../buttion-action/button-config.interface';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { GridTransaction } from '../grid-transaction';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import * as constants from '@shared/constant/GlobalConstants';

export const loadOrderGuide: LoadOrderGuideModel = (
TransactionData: TransactionConfiguration,
httpService: HttpService,
transactionService: TransactionService,
dialog: MatDialog,
systemPreference: SysytemPreferenceService ): any => {
    try  {
        const formGroupData = TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData;
        const lookUpData = {} as LookupConfiguration;
        lookUpData.TransType = ( TransactionData && TransactionData.TransactionType ) ? TransactionData.TransactionType : 1;
        lookUpData.FromLocationId = formGroupData['LocationId'];
        lookUpData.ToLocationId = 
        ( TransactionData && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData
             && TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'] ) ? 
         TransactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'] : undefined;
        lookUpData.TypeIds = [];
        lookUpData.PackageIds = [];
        lookUpData.ClassIds = [];
        // const date = new Date(formGroupData['PostDate']);
        // const newdate = new Date();
        // let postDate: any;
        // // eslint-disable-next-line max-len
        // const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), newdate.getHours(), newdate.getMinutes(), newdate.getSeconds());
        // if (isNaN(currentDate.getTime())) {
        //   postDate = null;
        // } else {
        //   const now_utc = moment.utc(currentDate).format();
        //   postDate = (date) ? now_utc : null;
        // }
        const date = moment(formGroupData['PostDate']);
        let postDate: any;
        if (!date.isValid()) {
          postDate = null;
        } else {
          postDate = moment(date).format(constants.PostDateTimeFormat);
        }
        lookUpData.PostDate = postDate;
        if (TransactionData.OdataKey === 'PurchaseOrderHead' || TransactionData.OdataKey === 'ReqHead' || TransactionData.OdataKey === 'PurchaseReqHead') { 
            if (formGroupData.hasOwnProperty('IsloadOrderGuide') && formGroupData['IsloadOrderGuide']) {
                const options = AssignBidsOptions(TransactionData);
                lookUpData.Id = 0;
                const IsloadOrderGuide =  formGroupData['IsloadOrderGuide'];
                if (IsloadOrderGuide) {
                  lookUpData.SupplierId = formGroupData['SupplierId'] ? 
                                            formGroupData['SupplierId'] : null;
                  lookUpData.IsLoadOrder = lookUpData.SupplierId ? IsloadOrderGuide : false;
                } else {
                  lookUpData.SupplierId = formGroupData['SupplierId'] ? 
                  formGroupData['SupplierId'] : null;
                  lookUpData.IsLoadOrder = lookUpData.SupplierId ? IsloadOrderGuide : false;
                }
                lookUpData.options = options;
                performLoadOrderGuide(lookUpData, TransactionData, httpService, transactionService,
                  systemPreference);
            }
        } else if (TransactionData.OdataKey === 'ReceivingHead') {
            if (formGroupData['SupplierId'] && !formGroupData['PONumber'] ) {
                lookUpData.SupplierId = formGroupData['SupplierId'];
                lookUpData.IsLoadOrder = true;
                const ConfirmPopUpDialog: ConfirmPopModel = {
                    PopupHeaderContent: 'Load Order Guide',
                    PopUpContent: 'Do you wish to load order guide for the selected supplier?',
                  };
                  const dialogRef = dialog.open(AgilysysConfirmationPopupComponent, {
                    width: '400px',
                    height: '222px',
                    data: ConfirmPopUpDialog,
                    disableClose: true
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result === 'Confirm') {
                        performLoadOrderGuide(lookUpData, TransactionData, httpService, transactionService,
                          systemPreference);
                    }
                  });
            }
        }
    } catch (error) {
        console.error('Error occurred in loadOrderGuide', error);
    }
};

function performLoadOrderGuide (lookUpData, transactionJson, httpService, transactionService: TransactionService,
  systemPreference: SysytemPreferenceService) {
    try {
        const GetDataByIdUrl = `/inventory/api/IngredientLookup`;
        httpService.PosthttpMethod(GetDataByIdUrl, lookUpData).subscribe (x => {
            console.log(x);
            const selectedIds = [];
            if (x['Data']['ent']['ResultData'].length > 0) {              
              x['Data']['ent']['ResultData'].forEach((y: any) => {
                if (y.IngredientId !== null) {
                  selectedIds.push(y.IngredientId);
                }
              });
              lookUpData.selectedIngredients = selectedIds && selectedIds.length > 0 ? selectedIds : [];
            }
            lookUpData.flgSearch = false;
            httpService.PosthttpMethod(GetDataByIdUrl, lookUpData).subscribe(response => {
            const itemListData = GridTransaction.GridItemListConfiguration(
                transactionJson, response['Data']['ent']['ResultData'], transactionService.currencyList, transactionService.unitList,
                transactionService.spoilageReasonsList, systemPreference);
            transactionJson.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded(itemListData);
            });
        }) ;

    } catch (error) {

    }
}

function AssignBidsOptions (transactionJson) {
    if (transactionJson.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData) {
      const ExpiredBids = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['ExpiredBids'] ? '1' : '0';
      const Priority = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['Priority'] ? '1' : '0';
      const SkipZero = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['SkipZero'] ? '1' : '0';
      const ClearPrices = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['ClearPrices'] ? '1' : '0';
      const ClearSupplier = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['ClearSupplier'] ? '1' : '0';
      const BidRegion = transactionJson.CreateTranscationConfig.ItemsConfig
      .ItemAssignBids.FormGroupData['BidRegion'] ? '1' : '0';
      const options = ExpiredBids + Priority + SkipZero + ClearPrices + ClearSupplier + BidRegion;
      return options;
    } else {
      return '011000';
    }
  }