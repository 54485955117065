import { Component, Input, OnChanges, AfterViewInit, ViewChild, ElementRef, Renderer2, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons, FieldArray, OnEventArgs } from '../../form-type.entity';
import { FormValidation, CustomErrorMesssage } from '../../form-validation';
@Component({
    selector: 'lib-textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.scss'],
})
export class TextBoxComponent implements OnInit, AfterViewInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @ViewChild('formtext', { read: false, static: true }) formtext: ElementRef;
    formAddBuilderIcons: FormIcons;
    formRemoveBuilderIcons: FormIcons;
    formRemoveIcons: FormIcons;
    // tslint:disable-next-line: no-inferrable-types
    hidePassword: boolean = false;
    // tslint:disable-next-line: no-inferrable-types
    typeField: string = 'text';
    formFieldName: any;
    fieldType: any;
    formFieldlabel: any;
    formArrayName: any;
    formFieldHeader: string;
    errorMessage: string;
    // tslint:disable-next-line: no-inferrable-types
    rowIndex: number = 0;
    validationMesage: string;
    errorLength: boolean;
    formfieldType: any;
    formFieldToolTip: string;
    formHintLabel: string;
    isRequired: boolean;

    get isValid(): any { return this.form.controls[this.formFieldName].valid; }
    get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }
    constructor(
        public agyDynamicFormServices: AgyDynamicFormServices,
        public renderer: Renderer2,
        private formValidation: FormValidation
    ) { }
    iconCallback(e: any): void {
        if (e.iconType.type === 'remove') {
            this.agyDynamicFormServices.RemoveRow(e.formArray, e.formObject, e.objectIndex);
        } else {
            this.agyDynamicFormServices.addnewRow(e.formArray, e.formObject);
        }
    }
    fieldArrayCallback(e: any): void {
        this.field.fieldType.MultipleData.data.push(e);
    }
    showPassword(): void {
        this.hidePassword = !this.hidePassword;
        if (this.hidePassword) {
            this.typeField = 'text';
        } else {
            this.typeField = 'password';
        }
    }
    eventCallback(index?: any, argus?: OnEventArgs): void {
        this.agyDynamicFormServices.formEventCallback(this.field, index, this.form);
    }

    suffixIconClick(): void {
        this.agyDynamicFormServices.formEventCallback(this.field, 'edit', this.form);
    }

    getIndex(e: number): void {
        this.rowIndex = e;
    }

    ngAfterViewInit(): void {
        this.isRequired = this.form.controls[this.formFieldName]?.validator ?
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
        this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
            this.isRequired = this.form.controls[this.formFieldName]?.validator ?
                !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
            this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field);
        });
        if (this.form.controls[this.formFieldName] && this.form.controls[this.formFieldName].errors){
            const errorsArray =  this.form.controls[this.formFieldName].errors.required === true ? true : false;
            this.errorLength = errorsArray;
        }
    }

    ngOnInit(): void {
        this.formfieldType = this.field.type === 'number' ? 'text' : this.field.type;
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
        this.formRemoveBuilderIcons = {
            type: 'remove',
            label: 'remove',
            maxcount: this.field.maxcount
        };
        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formFieldHeader = formField.fieldHeader;
        this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
        ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
        this.formHintLabel = this.field.fieldType[this.fieldType].hintLabel;
    }


    keyPress(event): boolean {
        if (this.field.type === 'number') {
            if (event.key === 'E' || event.key === 'e') {
                event.preventDefault();
                return false;
            }
        }
        //  if (this.field.fieldFormaters && this.field.fieldFormaters.EnableDecimalFormatter) {
        //     const regex = new RegExp('^-?[0-9]{0,14}(\.{0,1}[0-9]{0,5})?$');
        //     if (!regex.test(event.target.value)) {
        //         event.preventDefault();
        //         return false;
        //     }
        //  }
    }

    onPaste(event): void {
        if (this.field.type === 'number') {
            const paste = (event.clipboardData).getData('text');
            if (paste === 'e' || paste === 'E') {
                event.preventDefault();
            }
        }
    }

}
