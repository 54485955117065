export interface HeaderOptionInteface {
    'key': string;
    'description': string;
    'alignment': string;
    'customCss'?: string
    'sorting': boolean;
    'templatecontainer'?: containerInterface;
    'searchable'?: boolean;
}

export interface containerInterface {
    disabledFlag: boolean;
    templateName: templatenameenum;
}

export interface tableOptionInteface {
    'selectType'?: selecttypeenum;
    'options'?: optionstypesenum[];
}
export interface EmittedInteface {
    'fromType': string,
    'array': string[],
    'value': string,
    'Obj': any
}

// enum values

export enum SorTypeenum {
    asc = 'asc',
    desc = 'desc'
}
export enum FromTypeenum {
    done = 'done',
    cancel = 'cancel',
    allcheckbox = 'allcheckbox',
    rowcheck = 'rowcheck',
    edit = 'edit',
    delete = 'delete',
    dragdrop = 'dragdrop',
    claim = 'claim',
    moreoption = 'moreoption'
}
export enum optionstypesenum {
    edit = 'edit',
    delete = 'delete',
    dragdrop = 'dragdrop',
    claim = 'claim',
    moreoption = 'moreoption'
}
export enum selecttypeenum {
    checkbox = 'checkbox',
    radiobutton = 'radiobutton'
}
export enum templatenameenum {
    toggleTemplate = 'toggleTemplate',
    inputTemplate = 'inputTemplate',
    templateIconName = 'templateIconName'
}