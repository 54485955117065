import { Injectable } from '@angular/core';
import { AppService } from '../app-service/AppService';
import { HttpMethod } from '../service/http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import { SpaUtilities } from '../utilities/spa-utilities';
@Injectable(
)
export class SelectOutletModalBusiness {
  constructor(private appService: AppService, private utils: SpaUtilities) {

  }
  /**
   * getOutlets method to fetch array of outlet
   * @param callback
   */
  public async getOutlets(callback?: any) {
    return this.appService.InvokeServiceCall('GetSubPropertyAccessByUser', GlobalConst.Host.retailManagement, HttpMethod.Get, function SuccessCallBack(result) {
      if (callback) callback(result);
    }, function ErrorMessageCallBack(result) {
      if (callback) callback(result);
    }, { userId: this.utils.GetPropertyInfo("UserId") });
  }
}
