import { Component } from '@angular/core';

@Component({
  selector: 'app-report-appointment',
  templateUrl: './report-appointment.component.html',
  styleUrls: ['./report-appointment.component.scss']
})
export class ReportAppointmentComponent  {
 

}
