import { Injectable } from '@angular/core';
import { ComponentsLinkingConfigService } from '../../dataservices/componentsLinking/components-linking-config.service';
import { ComponentsLinkingConfigAPI, ComponentsLinkingConfigUI } from '../../Models/components-linking-config.model';
import { ConfigValues } from '../master-component-linking/master-component-linking.model';


@Injectable()
export class ComponentLinkingBusiness {

  constructor(private service:ComponentsLinkingConfigService) { }

  async getTableValues(): Promise<ComponentsLinkingConfigUI[]> {
   
    let uiModel : ComponentsLinkingConfigUI[] = [];
    uiModel = this.mapComponentsLinkingConfigAPItoUI(await  this.service.getComponentsLinkingConfig());
    return Promise.resolve(uiModel);
  }
  private mapComponentsLinkingConfigAPItoUI(apiModel: ComponentsLinkingConfigAPI[]) :ComponentsLinkingConfigUI[]{
    let uiModel:ComponentsLinkingConfigUI[] = [];
    apiModel.map(x => uiModel.push({id: x.id,  key: x.description, value: x.entityType, typeId: x.typeId}));
    return uiModel;

  }

}
