import { DateFormater } from 'src/app/eatecui/source/shared/formaters/date-formater.interface';
import { TransactionConfiguration } from '../transaction.interface';
import { ConfirmPopUpDataModel } from './button-config.interface';
import { Formaters as Formatter} from 'src/app/eatecui/source/shared/models/formaters';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';

export const confirmPopUpFormFields: ConfirmPopUpDataModel = (
    TransactionCollection: TransactionConfiguration,
    commonService?: CommonService,
    SystemPreferenceService?: SysytemPreferenceService
): any => {
    try {
        if (TransactionCollection) {
            const ModuleName = TransactionCollection.Name;
            const GridTableData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
            GridData.filter(rowData => !rowData.IsDeleted);
            let ReturnFormFields = [];
            const localCurrencyCode = sessionStorage.getItem('LocalCurrencyCode');
            const RawValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: localCurrencyCode }).format(null);
            const Value = CommonService.SetCurrencySymbolInUtility(localCurrencyCode, RawValue, true);
            const TaxesWhenReceiving = SystemPreferenceService?.globalSystemPreference?.find(x => x.LookupName === 'TaxeswhenReceiving').LookupValue;
            switch (ModuleName) {
                case 'Receiving':
                    let MiscTotal = 0;
                    ReturnFormFields = TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.FormFields;
                    ReturnFormFields.forEach(fields => {
                        if (fields.fieldType.SingleData.label.indexOf(`(${Value})`) === -1) {
                            fields.fieldType.SingleData.label += ` (${Value})`; 
                        }
                        fields.fieldFormaters = {
                            EnableDecimalFormatter: true,
                            WholeNumberLength: commonService.GetWholeNumberLength(true),
                            VisibleDecimal: CommonService.GetCurrencyVisible(),
                            FocusDecimal: commonService.GetFocusNumberLength(true)
                        };
                        if (TaxesWhenReceiving === 2 && (fields.fieldType.SingleData.name === 'Tax1Total' ||
                        fields.fieldType.SingleData.name === 'Tax2Total')) {
                            fields.hide = true;
                            fields.fieldType.SingleData.value = 0;
                        }
                    });
                    // GridTableData.forEach(element => {
                    //     element.Valued = element.Qty * element.UnitCost;
                    // });
                    let ItemTotal = GridTableData.map(item => item.Valued).reduce(function(prev, next) {
                        return (prev ? parseFloat(prev) : parseFloat('0')) + (next ? parseFloat(next) : parseFloat('0'));
                    });
                    ItemTotal = GridTableData.length === 1 ? parseFloat(GridTableData[0].Valued.toString()) : ItemTotal;
                    ReturnFormFields[0].fieldType.SingleData.value = Formatter.CurrencyFormater(null, ItemTotal, null, null);
                    let AllocatedTotal = GridTableData.map(item => item.Charges).reduce(function(prev, next) {
                        return (prev ? parseFloat(prev) : parseFloat('0')) + (next ? parseFloat(next) : parseFloat('0'));
                    });
                    AllocatedTotal = GridTableData.length === 1 ? parseFloat(GridTableData[0].Charges ?
                            GridTableData[0].Charges.toString() : '0') : AllocatedTotal;
                    ReturnFormFields[2].fieldType.SingleData.value = Formatter.CurrencyFormater(null, AllocatedTotal, null, null);
                    let Tax1Total, Tax2Total;
                    if (TaxesWhenReceiving === 1) {
                        Tax1Total = GridTableData.map(item => item.TaxAmount1).reduce(function(prev, next) {
                            return (prev ? parseFloat(prev) : parseFloat('0')) + (next ? parseFloat(next) : parseFloat('0'));
                        });
                        ReturnFormFields[5].fieldType.SingleData.value = Formatter.CurrencyFormater(null, Tax1Total, null, null);
                        Tax2Total = GridTableData.map(item => item.TaxAmount2).reduce(function(prev, next) {
                            return (prev ? parseFloat(prev) : parseFloat('0')) + (next ? parseFloat(next) : parseFloat('0'));
                        });
                        ReturnFormFields[6].fieldType.SingleData.value = Formatter.CurrencyFormater(null, Tax2Total, null, null);
                    }
                    if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData 
                        && TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData.length > 0) {
                        const Amount = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.
                        PostData.map(res => {
                            return res.Amount;
                        });
                        MiscTotal = Amount.reduce(function(accumulator, currentValue) {
                            return parseFloat(accumulator) + parseFloat(currentValue);
                        });
                        ReturnFormFields[4].fieldType.SingleData.value = Formatter.CurrencyFormater(null, MiscTotal.toString(), null, null);
                        // MiscTotal.toFixed(3);
                    } else if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.FormFields 
                        && TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.FormFields.length > 0) {
                            let Amount = 0;
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.
                            FormFields.forEach(x => {
                                if (x.fieldType && x.fieldType.SingleData && x.fieldType.SingleData.value) {
                                    Amount = Amount + parseFloat(x.fieldType.SingleData.value);
                                }
                            });
                            ReturnFormFields[4].fieldType.SingleData.value = Formatter.CurrencyFormater(null, Amount.toString(), null, null);
                    } else {
                        ReturnFormFields[4].fieldType.SingleData.value = Formatter.CurrencyFormater(null, MiscTotal.toString(), null, null);
                    }
                    const Balance = TaxesWhenReceiving === 2 ? 0 - (ItemTotal + AllocatedTotal + MiscTotal) : 
                    0 - (ItemTotal + AllocatedTotal + MiscTotal + Tax1Total + Tax2Total);
                    ReturnFormFields[3].fieldType.SingleData.value = Formatter.CurrencyFormater(null, Balance.toString(), null, null);
                    // Balance.toFixed(3);
                break;
                case 'AP RNI':
                    let RNIMiscTotal = 0;
                    let RNIDebitRniTotal = 0;
                    let AllocatedChargesTotal = 0;
                    ReturnFormFields = TransactionCollection.CreateTranscationConfig.ItemsConfig.ConfirmationPopUp.FormFields;
                ReturnFormFields.forEach(fields => {
                      if (fields.fieldType.SingleData.label.indexOf(`(${Value[0]})`) === -1) {
                        fields.fieldType.SingleData.label += ` (${Value[0]})`;
                      }
                        fields.fieldFormaters = {
                            EnableDecimalFormatter: true,
                            WholeNumberLength: commonService.GetWholeNumberLength(true),
                            VisibleDecimal: CommonService.GetCurrencyVisible(),
                            FocusDecimal: commonService.GetFocusNumberLength(true)
                        };
                        if (TaxesWhenReceiving === 2 && (fields.fieldType.SingleData.name === 'Tax1Total' ||
                        fields.fieldType.SingleData.name === 'Tax2Total')) {
                            fields.hide = true;
                            fields.fieldType.SingleData.value = 0;
                        }
                    });
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList
                    .ItemListExistingData.ReceivingDetails.forEach(element => {
                        RNIDebitRniTotal = RNIDebitRniTotal + parseFloat(element.Valued);
                        AllocatedChargesTotal = AllocatedChargesTotal + parseFloat(element.Charges);
                    }); 
                        
                    ReturnFormFields[0].fieldType.SingleData.value = Formatter.CurrencyFormater(null, '0', null, null);
                    const debRniPAllocate = RNIDebitRniTotal + AllocatedChargesTotal;
                    ReturnFormFields[1].fieldType.SingleData.value = Formatter.CurrencyFormater(null, debRniPAllocate.toString(), null, null);
                    let RNIItemTotal = GridTableData
                    .map(item => item.Valued).reduce(function(prev, next) {
                        return parseFloat(prev) + parseFloat(next);
                    });
                    RNIItemTotal = GridTableData.length === 1 ? parseFloat(GridTableData[0].Valued.toString()) : RNIItemTotal;
                    const RNIDebitPvvTotal = RNIItemTotal - RNIDebitRniTotal;
                    ReturnFormFields[2].fieldType.SingleData.value = Formatter.CurrencyFormater(null, RNIDebitPvvTotal.toString(), null, null);
                    let Tax1RNITotal, Tax2RNITotal;
                    if (TaxesWhenReceiving === 1) {
                        Tax1RNITotal = GridTableData.map(item => item.TaxAmount1).reduce(function(prev, next) {
                            return (prev ? parseFloat(prev) : parseFloat('0')) + (next ? parseFloat(next) : parseFloat('0'));
                        });
                        ReturnFormFields[6].fieldType.SingleData.value = Formatter.CurrencyFormater(null, Tax1RNITotal, null, null);
                        Tax2RNITotal = GridTableData.map(item => item.TaxAmount2).reduce(function(prev, next) {
                            return (prev ? parseFloat(prev) : parseFloat('0')) + (next ? parseFloat(next) : parseFloat('0'));
                        });
                        ReturnFormFields[7].fieldType.SingleData.value = Formatter.CurrencyFormater(null, Tax2RNITotal, null, null);
                    }
                    if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData
                        && TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData.length > 0) {
                        const Amount = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.
                        PostData.map(res => {
                            return res.Amount;
                        });
                        RNIMiscTotal = Amount.reduce(function(accumulator, currentValue) {
                            return parseFloat(accumulator) + parseFloat(currentValue);
                        });
                        ReturnFormFields[3].fieldType.SingleData.value = Formatter.CurrencyFormater(null, RNIMiscTotal.toString(), null, null);
                    } else if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.FormFields 
                        && TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.FormFields.length > 0) {
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.
                            FormFields.forEach(x => {
                                if (x.fieldType && x.fieldType.SingleData && x.fieldType.SingleData.value) {
                                    RNIMiscTotal = RNIMiscTotal + parseFloat(x.fieldType.SingleData.value);
                                }
                            });
                            ReturnFormFields[4].fieldType.SingleData.value = Formatter.CurrencyFormater(null, RNIMiscTotal.toString(), null, null);
                    } else {
                        ReturnFormFields[3].fieldType.SingleData.value = Formatter.CurrencyFormater(null, RNIMiscTotal.toString(), null, null);
                    }
                    const RNICreditTotal = TaxesWhenReceiving === 2 ? (RNIItemTotal + RNIMiscTotal + AllocatedChargesTotal) :
                     (RNIItemTotal + RNIMiscTotal + AllocatedChargesTotal +  Tax1RNITotal + Tax2RNITotal);
                    const RNIBalance = 0 - RNICreditTotal;
                    ReturnFormFields[4].fieldType.SingleData.value = Formatter.CurrencyFormater(null, RNICreditTotal.toString(), null, null);
                    ReturnFormFields[5].fieldType.SingleData.value = Formatter.CurrencyFormater(null, RNIBalance.toString(), null, null);
                break;
            }
            return ReturnFormFields;
        }
    } catch (error) {
        console.log('error occured confirmPopUpFormFields', error);
    }

};
