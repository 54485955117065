import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-multi-mail-search',
  templateUrl: './multi-mail-search.component.html',
  styleUrls: ['./multi-mail-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiMailSearchComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  showError: boolean = false;
  showDuplicate: boolean = false;
  floatLabel: string;
  captions: any;
  emails: any[] = [];
  placeHolder: string;
  // @Input() emails : any[];
  @Input('placeHolder')
  set setPlaceholder(value) {
    if (value) {
      this.placeHolder = value ? value : '';
    }
  }
  @Input('emails')
  set setEmail(value) {
    if (value) {
      this.emails = value;
    }
  }
  @Input('AddMailonChecked')
  set setAdd(result) {
    if (result) {
      let data = {
        id: this.emails.length + 1,
        name: result.name,
        mail: result.mail,
        checked: result.checked,
        isDrivenData: result.isDrivenData
      }
      if (this.validateEmail(data.mail)) {
        this.emails.push(data);
        this.onChipAdd.emit(this.emails);
      }
    }
  }
  @Input('RemoveMailonchecked')
  set removeMail(result) {
    if (result?.mail) {
      this.remove(result);
    }
  }
  @Output() onChipRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChipAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();
  constructor(private localization: Localization) {
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
  }

  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;
    this.showError = false;
    if ((value || '').trim()) {
      if (this.validateEmail(value)) {
        this.emails.push({ mail: value.trim() });
        this.onChipAdd.emit(this.emails);
        input.value = '';
        this.showDuplicate = false;
        this.showError = false;
      }
    }
  }

  remove(email): void {
    if (!email.isDrivenData) {
      const index = this.emails.indexOf(email);
      if (index >= 0) {
        this.emails.splice(index, 1);        
      }
    }
    else {
      let a = this.emails.find(x => x.mail == email.mail && x.isDrivenData);
      let index = this.emails.indexOf(a);
      if (index >= 0) {
        this.emails.splice(index, 1);
      }
    }
    this.onChipRemove.emit(email);

  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validateEmail = re.test(String(email).toLowerCase());
    this.showError = !validateEmail;
    this.onError.emit(this.showError);
    if (this.emails && this.emails.length > 0 && !this.showError) {
      let duplicateEmail = this.emails.findIndex(x => x.mail.toLowerCase().trim() == email.toLowerCase().trim());
      this.showDuplicate = !(duplicateEmail <0);
      this.onError.emit(this.showDuplicate);
    }
    else {
      return !this.showError;
    }
    return !(this.showDuplicate || this.showError);
  }

}
