import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GridActionRender, RowActions } from '../interface/common';

@Component({
  selector: 'lib-row-actions',
  templateUrl: './row-actions.component.html',
  styleUrls: ['./row-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RowActionsComponent implements OnInit {

  params: any;
  columnDef: GridActionRender;
  IsSystem = false;
  IsSystemDisplay = false;
  checkIsSystem = false;
  actions: RowActions[] = [
    {
      Id: 1,
      DisplayName: 'Edit',
      Name: 'Edit',
      Show: true,
      IconName: 'icon-edit1-eatec'
    },
    {
      Id: 2,
      DisplayName: 'Copy',
      Name: 'Copy',
      Show: true,
      IconName: 'icon-details-eatec'
    },
    {
      Id: 3,
      DisplayName: 'Deactivate',
      Name: 'Deactivate',
      Show: true,
      IconName: 'icon-requisition-denied-eatec'
    },
    {
      Id: 4,
      DisplayName: 'Activate',
      Name: 'Activate',
      Show: true,
      IconName: 'icon-requisition-approved-eatec'
    },
    {
      Id: 5,
      DisplayName: 'Delete',
      Name: 'Delete',
      Show: true,
      IconName: 'icon-delete-eatec'
    },
    {
      Id: 6,
      DisplayName: 'Item Purchases',
      Name: 'ItemPurchase',
      Show: false,
      IconName: ''
    },
    {
      Id: 7,
      DisplayName: 'Item Transfer',
      Name: 'ItemTransfer',
      Show: false,
      IconName: ''
    },
    {
      Id: 8,
      DisplayName: 'Item Location',
      Name: 'ItemLocation',
      Show: false,
      IconName: ''
    },
    {
      Id: 9,
      DisplayName: 'Item Usage',
      Name: 'ItemUsage',
      Show: false,
      IconName: ''
    },
    {
      Id: 9,
      DisplayName: 'Item Bids',
      Name: 'ItemBids',
      Show: false,
      IconName: ''
    },
    {
      Id: 9,
      DisplayName: 'No Math',
      Name: 'NoMath',
      Show: false,
      IconName: 'icon-NoMathIcon1-eatec'
    },
    {
      Id: 10,
      DisplayName: 'Bid History',
      Name: 'Bidhistory',
      Show: false,
      IconName: 'icon-cancel-eatec'
    },
    {
      Id: 11,
      DisplayName: 'Reverse Invoice',
      Name: 'ReverseInvoice',
      Show: false,
      IconName: ''
    },
    {
      Id: 11,
      DisplayName: 'Start',
      Name: 'Start',
      Show: false,
      IconName: 'icon-px-Templates-eatec'
    },
    {
      Id: 12,
      DisplayName: 'Production',
      Name: 'Production',
      Show: false,
      IconName: ''
    },
    {
      Id: 13,
      DisplayName: 'Unlink Bids',
      Name: 'UnlinkBids',
      Show: false,
      IconName: 'icon-link-products-eatec'
    },
    {
      Id: 14,
      DisplayName: 'Item Sales',
      Name: 'ItemSales',
      Show: false,
      IconName: ''
    },
    {
      Id: 15,
      DisplayName: 'Remove',
      Name: 'Remove',
      Show: false,
      IconName: 'icon-delete-eatec'
    },
    {
      Id: 16,
      DisplayName: 'Update Schedule',
      Name: 'UpdateScheduleReport',
      Show: false,
      IconName: 'icon-edit1-eatec'
    },
    {
      Id: 17,
      DisplayName: 'Unlink',
      Name: 'UnlinkQO',
      Show: false,
      IconName: 'icon-link-products-eatec'
    },
    {
      Id: 18,
      DisplayName: 'History',
      Name: 'History',
      Show: false,
      IconName: 'icon-HIstory-eatec'
    },
    {
      Id: 19,
      DisplayName: 'Export',
      Name: 'Export',
      Show: false,
      IconName: 'icon-Validation_Summary-eatec'
    },
    {
      Id: 20,
      DisplayName: 'Purchase Orders',
      Name: 'PurchaseOrders',
      Show: false,
      IconName: ''
    },
    {
      Id: 21,
      DisplayName: 'Receiving',
      Name: 'Receiving',
      Show: false,
      IconName: ''
    },
    {
      Id: 22,
      DisplayName: 'View',
      Name: 'View',
      Show: false,
      IconName: ''
    },
    {
      Id: 22,
      DisplayName: 'Clear Bin',
      Name: 'ClearBin',
      Show: false,
      IconName: 'icon-Clear-Bin-eatec'
    },
    {
      Id: 23,
      DisplayName: 'Lot History',
      Name: 'LotHistory',
      Show: false,
      IconName: 'icon-HIstory-eatec'
    },
    {
      Id: 24,
      DisplayName: 'Print',
      Name: 'Print',
      Show: false,
      IconName: ''
    }
  ];
  IsActive = true;
  noactions: boolean;
  optionClick = false;
  InvalidQty = false;
  IsLinked: boolean;
  IsRemove: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    this.params = params;
    this.columnDef = this.params.colDef;
    this.IsSystem = this.params.data.hasOwnProperty('issystem') ? this.params.data.issystem : false;
    this.IsSystemDisplay = this.params.data.hasOwnProperty('issystemdisplay') ? this.params.data.issystemdisplay : false;
    this.checkIsSystem = this.IsSystem && this.IsSystemDisplay ? true : false;
    this.IsActive = this.params.data.hasOwnProperty('isactive') ? this.params.data.isactive :
      this.params.data.hasOwnProperty('IsActive') ? this.params.data.IsActive : true;
    if (this.params.data.hasOwnProperty('Qty') && this.params.data.Qty
    && (this.params.data.Qty.toString().toLowerCase() === 'n_c' || this.params.data.Qty.toString().toLowerCase() === 'l_p')) {
      this.InvalidQty = true;
    } else {
      this.InvalidQty = false;
    }
    this.actions.forEach(element => {
      if (this.columnDef.hasOwnProperty(element.Name)) {
        element.Show = this.columnDef[element.Name];
      }
    });
    if (this.IsActive === true) {
      this.actions[2].Show = this.actions[2].Show === false ? this.actions[2].Show : true;
      this.actions[3].Show = false;
    } else if (this.IsActive === false) {
      this.actions[0].Show = false;
      this.actions[1].Show = false;
      this.actions[2].Show = false;
      this.actions[3].Show = this.actions[3].Show === false ? this.actions[3].Show : true;
      this.actions[11].Show = false;
      this.actions[18].Show = false;
    }
    this.IsLinked = this.params.data.hasOwnProperty('IsLinked') ? this.params.data.IsLinked : false;
    if (this.IsLinked) {
      this.actions[15].Show = true;
    }
    this.IsRemove = this.params.data.hasOwnProperty('Id') ? ( this.params.data.Id ? false : true) : false;
    if (this.actions[17].Show === true) {
      this.actions[17].Show = this.IsRemove;
      this.actions[2].Show = this.IsRemove ? false : this.actions[2].Show;
      this.actions[3].Show = this.IsRemove ? false : this.actions[3].Show;
    }
    if (this.IsSystemDisplay) {
      this.actions[2].Show = false;
      this.actions[3].Show = false;
    }
    /**
     * For Events and Quick Order Landing Screen - Actions Disable for Edit option
     * For Events - EventsStatus property is checked (pascalcase - events)
     * For Quick Order - ordernumber and statusid in lowercase is checked
     */
    const statusid = (this.params.data.hasOwnProperty('ordernumber') || this.params.data.hasOwnProperty('EventsStatus')) ?
     (this.params.data.hasOwnProperty('ordernumber') || this.params.data.hasOwnProperty('EventsStatus')) &&
      (this.params.data?.StatusId ? this.params.data?.EventsStatus?.Id : this.params.data.statusid ? this.params.data.statusid : 0) : 0;
    const applicationId = this.params.data.hasOwnProperty('applicationid') ? this.params.data.applicationid : null;
    if (statusid !== 1 && statusid !== 2 && statusid !== 0) {
      this.actions[0].Show = false;
    }
    if (applicationId !== 1 && applicationId !== null) {
      this.actions[0].Show = false;
    }
    /**
     * For Events - Linked Quick Order Table -
     * To disable unlink option for Finalized, Cancelled, Depleted, Invoiced event
     */
    const qostatus = (this.params.data.hasOwnProperty('StatusId') && this.params.data.hasOwnProperty('OrderNumber')) ?
     this.params.data.StatusId : 0;
     if (qostatus !== 1 && qostatus !== 2 && qostatus !== 0) {
      this.actions[19].Show = false;
    }
    this.noactions = this.actions.filter(x => x.Show === false).length === this.actions.length ? true : false;
  }

  /**
   * @method buttonClick
   * @param data;
   * @description Method get triggered when edit or delete button is clicked in row of grid
   */
  buttonClick(data) {
    this.optionClick = true;
    this.params.context.parentComponent.rowActionButtonClick(this.params, this.params.data, data.toLowerCase());
  }

  /**
   * @method moreIcon
   */
  moreIcon(event) {
    event.stopPropagation();
    const allColumns = this.params.columnApi.getAllColumns();
    allColumns.forEach(element => {
      element.colDef.editable = false;
    });
  }

  menuCloses(event) {
    this.params.context.parentComponent.moreData(this.params);
  }

}
