import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration, CustomFormValidation } from './custom-form-configuration.interface';

export const checkFormValidation: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: string
): any => {
    try {
        if (FormGroupData) {
            const key = MasterDataCollection.CreateTranscationConfig.ItemsConfig.KeyToEnablePost;
            MasterDataCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach((buttonconfig: AgilysysButtonModel) => {
              if (buttonconfig.buttonKey.toString().toLowerCase() === 'receivepost' || buttonconfig.buttonKey.toString().toLowerCase() === 'save') {
                const buttonEnable = MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData
                                        .some(e => parseFloat(e[key] === '' ? 0 : e[key]) > 0);
                
                // buttonconfig.displayProperity = !(FormGroupData.valid && buttonEnable);
              }
            });
            if (FieldGrouping.OdataKey === 'PostDate') {
                const Data = MasterDataCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.
                    filter(x => x.OdataKey === 'PayTermId')[0];
                CustomFormField.CustomDueDateSet(
                    ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                    Data, FormGroupData, FormField, CustomData);
            }
           return null;
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};