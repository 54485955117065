import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { SPAConfig } from '../../../core/config/SPA-config';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { GuestType } from '../../../shared/business/view-settings.modals';
import { EmptyValueValidator } from 'src/app/shared/Validators/EmptyValueValidator';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-guest-type',
  templateUrl: './guest-type.component.html',
  styleUrls: ['./guest-type.component.scss']
})
export class GuestTypeComponent implements OnInit {

  clickbutton: any;
  @Input() dataInput:any;
  @Input() popupConfigs: popupConfig;

  sgDataInput: any = [];
  FormGrp: UntypedFormGroup;
  blnReadOnly: boolean;
  maxListOrder: number;
  captions: any = this.localization.captions.setting;
  //placeholders variables
  tCode: string = this.captions.GuestTypeCode;
  tDesc: string = this.captions.GuestTypeDescription;
  tLstOrder: string = this.captions.ListOrder;

  //validation error variables
  vCodeErr: string = this.captions.MissingGuestTypeCode;
  vDescErr: string = this.captions.MissingGuestTypeDescription;
  isUserAuthorized = true;
  isViewOnly=true;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(private spaConfig: SPAConfig,public dialogRef: MatDialogRef<GuestTypeComponent>,
    public localization:SpaLocalization,private http: HttpServiceCall, private vsBusiness: ViewSettingClientBusiness,
    private Form: UntypedFormBuilder, public dialog: MatDialog,
    private utils: SpaUtilities,private BP: BreakPointAccess) { 
      this.floatLabel = this.localization.setFloatLabel;
    }
    ValidateBreakPoint(): void {
      this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.GuestType]);
      this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.GuestType);
      if (this.isViewOnly) {
        this.utils.disableControls(this.FormGrp);
      }
    }
    onNoClick(): void {
      this.vsBusiness.promptUserDialog(this.dialogRef);
    }
    validateSave() {
      return this.FormGrp.valid && this.FormGrp.dirty;
    }
  
    saveGuestType() {
      this.saveButton.disabledproperty = true;
      if (this.popupConfigs.operation == "create") {
        this.createGuestType();
      } else if (this.popupConfigs.operation == "edit") {
        this.updateGuestType();
      }
    }
  
    updateGuestType() {
      const _id =this.dataInput.id;
      const _code = this.FormGrp.controls['code'].value;
      const _listOrder = this.FormGrp.controls['listOrder'].value;
      const _desc = this.FormGrp.controls['description'].value;
      if(_listOrder == '') {
        this.FormGrp.controls["listOrder"].setValue(this.maxListOrder);
      }
      let patchJson = [];
      patchJson = [
      { op: "replace", path: "/code", value: _code },
      { op: "replace", path: "/description", value: _desc },
      { op: "replace", path: "/listOrder", value: this.FormGrp.controls['listOrder'].value}];
      this.http.CallApiWithCallback<GuestType[]>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "UpdateGuestType",
        method: HttpMethod.Patch,
        uriParams: { id: this.dataInput.id },
        body: patchJson,
        showError: true,
        extraParams: []
      });
  
    }
  
    createGuestType() {
      const _code = this.FormGrp.controls['code'].value;
      let _listOrder = this.FormGrp.controls['listOrder'].value;
      const _desc = this.FormGrp.controls['description'].value;
      if(_listOrder == '') {
        _listOrder = this.maxListOrder;
      }
      const bodyObj: GuestType = {
        code: _code.toUpperCase(), listOrder: _listOrder, description: _desc
      }
       this.http.CallApiWithCallback<GuestType[]>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "CreateGuestType",
        method: HttpMethod.Post,
        body: bodyObj,
        showError: true,
        extraParams: [bodyObj]
      });
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
      if (callDesc == "CreateGuestType" || callDesc == "UpdateGuestType") {
        result["NewData"] = extraParams[0];
        this.dialogRef.close(result);
      }
    }
  
    errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
      if (callDesc == "CreateGuestType" || callDesc == "UpdateGuestType") {
        this.saveButton.disabledproperty = false;
        if (error) {error["NewData"] = extraParams[0];}
      }
    }

  ngOnInit(): void {
    this.FormGrp = this.Form.group({
      code: ['', [Validators.required, EmptyValueValidator]],
      description: ['', [Validators.required, EmptyValueValidator]],
      listOrder: ['']
    });
    this.saveButton = {
      type: 'primary',
      label: this.popupConfigs.operation == "create" ? this.captions.save : this.captions.update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.CANCEL,
    }
    this.vsBusiness.activeFormGroup = this.FormGrp;
    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.captions.save;
      this.blnReadOnly = false;
      this.maxListOrder = this.popupConfigs.maxListOrder;
      this.FormGrp.setValue({
        code: this.dataInput ? this.dataInput.code : '',
        listOrder: this.maxListOrder,
        description: this.dataInput ? this.dataInput.description : ''
      });
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.captions.update;
      this.blnReadOnly = true;
      this.maxListOrder = this.dataInput.listOrder;
      this.FormGrp.patchValue(this.dataInput);
    }
    this.ValidateBreakPoint();
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.FormGrp.valid && this.FormGrp.dirty);
      this.saveButton = { ...this.saveButton };
    });
  }

}
