export enum FieldEdmType {
    EDMINT32 = 'Edm.Int32',
    EDMSTRING = 'Edm.String',
    EDMDATEOFFSET = 'Edm.DateTimeOffset',
    EMDBOOLEAN = 'Edm.Boolean',
    EDMINT16 = 'Edm.Int16',
    EDMINTDECIMAL = 'Edm.Decimal',
    EDMFLOAT = 'Edm.Single',
    EDMDOUBLE = 'Edm.Double',
    EDMPICTURE = 'Edm.Picture',
    EDMHIDDEN = 'Edm.hidden',
    EDMMULTIDSELECT = 'Edm.Multiple',
    EDMINT64 = 'Edm.Int64',
    EDMDROPDOWN = 'Edm.Dropdown',
    EDMTIME = 'Edm.Time',
    LABELWITHICON = 'labelwithicon'
  }
  