import { ExpandGridHeader } from "src/app/eatecui/source/shared/models/expandable-grid-interface";
import { GridHeaderConfig } from "src/app/eatecui/source/shared/models/grid-data.interface";
import { Formaters } from 'src/app/eatecui/source/transaction/shared/formaters';
import * as GlobalConstants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const ConstructEngineeringGrid = (GridHeader: ExpandGridHeader[]): GridHeaderConfig[] => {
    try {
        const girdConfig = [] as GridHeaderConfig[];
        const profitColumns = ['TotalProfit', 'ProfitGroup', 'Mix%Group'];
        const iconColumns = ['ItemName', 'ItemClass'];
        if (GridHeader?.length) {
            GridHeader?.forEach(header => {
                const gridHeader = {} as GridHeaderConfig;
                gridHeader.headerName = header.DisplayName;
                gridHeader.field = header.ExpandColumnName;
                gridHeader.resizable = true;
                gridHeader.suppressMovable = true;
                gridHeader.tooltipField = header.ExpandColumnName;
                gridHeader.type = header.AlignText === 'end' ? 'numericColumn' : '';
                gridHeader.minWidth = '100';
                gridHeader.width = '160';
                gridHeader['columnType'] = header.ColumnType;
                if (gridHeader['columnType'] === 'decimal') {
                  gridHeader.valueFormatter = (params: any) => {
                    return Formaters.decimalFormatter(params);
                  };
                  gridHeader['allowDigits'] = GlobalConstants.WholeNumberLength;
                  gridHeader['focusDigits'] = GlobalConstants.FocusNumberLength;
                } else if (gridHeader['columnType'] === 'currency') {
                  gridHeader.valueFormatter = Formaters.currencyFormater;
                  gridHeader['allowDigits'] = GlobalConstants.WholeNumberCurrencyLength;
                  gridHeader['focusDigits'] = GlobalConstants.FocusNumberCurrencyLength;
                }
                if (profitColumns.includes(header.ExpandColumnName)) {
                    gridHeader.cellRenderer = (gridData) => {
                        const ele = document.createElement('span');
                        ele.innerText = gridData.value;
                        ele.className = getClassList(header, gridData);
                        return ele;
                    }
                } else if (iconColumns.includes(header.ExpandColumnName)) {
                    gridHeader.cellRenderer = (gridData) => {
                        const ele = document.createElement('span');
                        ele.innerHTML = `<i class="item-class-icons ${iconName(header.ExpandColumnName === 'ItemName' ? gridData.data : gridData.value)}"></i>${gridData.value}`;
                        return ele;
                    }
                }
                girdConfig.push(gridHeader);
            })
        }
        return girdConfig;
    } catch (error) {
        console.log(error);
    }
}

function getClassList(header: ExpandGridHeader, gridData: any) {
    const classList = {
        'negative-value-bgcolor': header.FieldType === 'number' && gridData.value < 0, 
        'total-profit-color-positive': header.ExpandColumnName === 'TotalProfit' && gridData.value >= 0, 
        'total-profit-color-negative': header.ExpandColumnName === 'TotalProfit' && gridData.value < 0, 
        'profit-group-high-color': (header.ExpandColumnName === 'ProfitGroup' || header.ExpandColumnName === 'Mix%Group') && gridData.value === 'High', 
        'profit-group-low-color': (header.ExpandColumnName === 'ProfitGroup' || header.ExpandColumnName === 'Mix%Group') && gridData.value === 'Low'
    }
    const filteredList = [];
    Object.keys(classList)?.forEach(className => {
        if (classList[className]) {
            filteredList.push(className);
        }
    });
    return filteredList?.join(' ')
}
function iconName(node: any) {
    if (node.LinkDB === 1 && node.DivisionId === 1) {
      return 'icon-Food-and-beverage-eatec-eatec';
    } else if (node.LinkDB === 1 && node.DivisionId === 2) {
      return 'icon-Retail-eatec';
    } else if (node.LinkDB === 2) {
      return 'icon-Recipe-eatec';
    } else if (node.LinkDB === 5) {
      return 'icon-Product-eatec';
    } else if (node === 'Dog') {
      return 'icon-Dog-eatec';
    } else if (node === 'Star') {
      return 'icon-Star-eatec';
    } else if (node === 'Plowhorse') {
      return 'icon-Plowhorse-eatec';
    } else if (node === 'Puzzle') {
      return 'icon-Puzzle-eatec';
    }
  }