
import { Component,  Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { AlertType, ButtonTypes } from 'src/app/common/Models/common.models';
import { UserErrorMessage } from 'src/app/common/constants';



@Component({
  selector: 'app-common-alert-popup',
  templateUrl: './common-alert-popup.component.html',
  styleUrls: ['./common-alert-popup.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CommonAlertPopupComponent  {
  continueText = this._Localization.captions.alertPopup.continue;
  cancelText = this._Localization.captions.alertPopup.cancel;
  YES = this._Localization.captions.alertPopup.yes;
  NO = this._Localization.captions.alertPopup.no;
  okText = this._Localization.captions.alertPopup.okay;
  success = this._Localization.captions.alertPopup.success;
  warning = this._Localization.captions.alertPopup.warning;
  error = this._Localization.captions.alertPopup.error;
  wellDone = this._Localization.captions.alertPopup.welldone;
  info = this._Localization.captions.alertPopup.info;
  accessDenied = this._Localization.captions.alertPopup.accessDenined;
  done = this._Localization.captions.alertPopup.done;
  confirmation = this._Localization.captions.alertPopup.confirmation;
  Caption_User_Notification_ErrorMessage = UserErrorMessage;
  attention = this._Localization.captions.alertPopup.lbl_attention;

  public alertType = AlertType;
  public buttonType = ButtonTypes;

  @ViewChild('btnRef') buttonRef: MatButton;
  constructor(public dialogRef: MatDialogRef<CommonAlertPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Localization: Localization) {
     

  }
  
  ngAfterViewInit() {
    this.buttonRef.focus();
 }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }

  async copyToClipboard(textToCopy) {
    const textToCopyFormatted = `${textToCopy.referenceIdText} | ${textToCopy.message}`;
       await navigator.clipboard.writeText(textToCopyFormatted);
   }
}
