import { Injectable } from "@angular/core";
import { FolioCommunication } from "../foliocommunication.service";
import { FolioRoutes, RetailRoutes } from "../retail-route";
import { CreateDefaultFolioRequest, CreateFolioRequest, FolioDetails, FolioLookup, FolioLookupResponse, GuestSearchInfo,BulkCreateResortFinanceFoliosRequest } from "./Model/folioDetails-model";
import { PostTypeDetails } from "../retail.modals";
import { PaymentCommunication } from "../payment-communication.service";
import { ResortFinanceFolioPostDetail } from "../shared/service/common-variables.service";

@Injectable({
  providedIn: "root",
})

export class FolioService {
  constructor(private folioCommunication: FolioCommunication,
    private retailCommunication: PaymentCommunication) { }

  public async createFolioDetails(body: FolioDetails): Promise<FolioDetails> {
    const result = await this.folioCommunication.postPromise<FolioDetails>(
      { route: FolioRoutes.CreateFolioDetails, body },
      true
    );
    return result;
  }

  public async getFolioDetails(sourceType: string, sourceTypeId: string): Promise<FolioDetails[]> {
    const result = await this.folioCommunication.getPromise<FolioDetails[]>(
      {
        route: FolioRoutes.GetFolioDetailsBySourceTypeAndSourceTypeId,
        uriParams: { id: sourceTypeId, sourceType: sourceType }
      },
      true
    );
    return result;
  }

  public async createDefaultFolio(body: CreateDefaultFolioRequest): Promise<FolioDetails> {
    const result = await this.folioCommunication.postPromise<FolioDetails>(
      { route: FolioRoutes.CreateDefaultFolio, body },
      true
    );
    return result;
  }

  public async saveResortFinanceFolioPostDetail(body: ResortFinanceFolioPostDetail[]): Promise<ResortFinanceFolioPostDetail[]> {
    const result = await this.folioCommunication.postPromise<ResortFinanceFolioPostDetail[]>(
      { route: FolioRoutes.saveResortFinanceFolioPostDetail, body },
      true
    );
    return result;
  }

  public async GetResortFinanceCardTokenReference(guestCardTokenReference: number): Promise<number>{
    const result = await this.retailCommunication.getPromise<number>(
      {
        route: RetailRoutes.GetResortFinanceCardTokenReference,
        uriParams: { guestCardTokenReferenceId: guestCardTokenReference}
      },
      true
    );
    return result;
  }

  public async FolioLookup(body: FolioLookup): Promise<FolioLookupResponse[]> {
    const result = await this.folioCommunication.putPromise<FolioLookupResponse[]>({
      route: FolioRoutes.FolioLookup,
      body
    },false);
    return result;
  }

  public async FolioLookupByGuestDetails(body: GuestSearchInfo): Promise<FolioLookupResponse[]> {
    const result = await this.folioCommunication.putPromise<FolioLookupResponse[]>({
      route: FolioRoutes.FolioLookupByGuestDetails,
      body
    });
    return result;
  }

  //#region PostTypes
  public async getPostTypes(): Promise<PostTypeDetails[]> {
    let result: PostTypeDetails[];
    await this.folioCommunication.getPromise<any[]>(
      {
        route: FolioRoutes.GetAllPostTypes,
        uriParams: { includeInactive: false, includeRelatedData: true }
      },
      true
    ).then(x => {
      result = this.MapPostTypesToPosttypeDetails(x);
    });

    return result;
  }

  MapPostTypesToPosttypeDetails(data: any): PostTypeDetails[] {
    let postTypes: PostTypeDetails[] = [];
    if (data != null && data.length > 0) {
      data.forEach(posttype => {
        postTypes.push({
          id: posttype.id, description: posttype.postTypeName, postCode: posttype.postTypeNumber, department: posttype.department,
          isActive: posttype.isActive, propCode: "", pmsSystem: 1
        });
      });
    }
    return postTypes;
  }
  //#endregion 

  public async GetCheckDetailsByPosTransactionId(posTransactionId: number) {
    const result = await this.folioCommunication.getPromise<CheckZoomResult>({
      route: FolioRoutes.GetCheckDetailsByPosTransactionId,
      uriParams: { posTransactionId: posTransactionId },
    }, false);

    return result;
  }

  public async BulkCreateResortFinanceFolios(body: BulkCreateResortFinanceFoliosRequest): Promise<FolioDetails[]> {
    const result = await this.folioCommunication.postPromise<FolioDetails[]>(
      { route: FolioRoutes.BulkCreateResortFinanceFolios, body },
      true
    );
    return result;
  }

}

export interface CheckZoomResult {
  checkZoomDetails: string;
}