import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { of, Subscription } from 'rxjs';
import { PropertySettingDataService } from '../property-setting.data.service';
import { VatDataService } from './vat-configuration.data.service';
import { VATConfiguration, GrossNetEnum, PropertySetting, Tax, AddOnVatDetails } from '../../retail.modals';
import { EmptyValueValidator } from '../../shared/Validators/EmptyValueValidator';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { AgDateConfig, AgDropdownConfig, AgFieldConfig, AgInputFieldConfig, DropdownOptions } from 'src/app/common/Models/ag-models';
import { BuildingRoomTypeInput, BuildingRoomTypeRadioInput, BuildingRadioInput, AdditionalVATConfiguration, VATPostTypes, VATMaintenance } from './vat-configuration.model';
import { VatConfigurationBusiness } from './vat-configuration.business'
import { AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { TaxTypeBusiness } from '../../retail-code-setup/retail-taxes/tax-type/tax-type.business';
import { API as TaxTypeAPI, TaxBased, TaxMaintenance, UI as TaxTypeUI, TaxTypeOptions } from '../../retail-code-setup/retail-taxes/tax-type/tax-type.model';
import { API } from './vat-configuration.model';
import { TaxTypeDataService } from '../../shared/service/taxtype.data.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-vat-configuration',
  templateUrl: './vat-configuration.component.html',
  styleUrls: ['./vat-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PropertySettingDataService, VatDataService, VatConfigurationBusiness, TaxTypeDataService, TaxTypeBusiness]
})
export class VatComponent implements OnInit, OnDestroy {
  captions: any = this.localization.captions.setting;
  taxCaptions: any = this.localization.captions;
  VatForm: UntypedFormGroup;
  taxForm: UntypedFormGroup;
  enableSave: boolean;
  viewOnly: boolean;
  subscriber: Subscription;
  serviceCharges: { id: number; name: any; }[];
  gratuity: { id: number; name: any; }[];
  commission: { id: number; name: any; }[];
  vatConfiguration: VATConfiguration;
  additionalVATConfiguration: AdditionalVATConfiguration;
  propertyId: number;
  serviceChargeVATId = 0;
  activeVats: Tax[] = [];
  featureEnabled: boolean = true; // Make this flag Enable/Disable based on product
  functionalities: { [key: string]: boolean } = {};
  effectiveStartDate: AgDateConfig;
  effectiveEndDate: AgDateConfig;
  minStartDate: Date;
  minEndDate: Date;
  maxStartDate: Date;
  buildingRoomTypeInput: BuildingRoomTypeInput;
  department: BuildingRadioInput[] = [];
  postings: BuildingRoomTypeRadioInput[] = [];
  searchText = '';
  taxNameInput: AgInputFieldConfig;
  currentProductId: number;
  vatConfig_prdId: number = 6;
  @Output() SearchValueChangeEmitter = new EventEmitter();
  taxList: TaxTypeUI.TaxType[] = [];
  departmentList: API.Departments[];
  posttypeList: API.PostType[];
  minimumPriceInput: AgFieldConfig;
  showLongTermVATConfig: boolean = false;
  departmentForRange: API.Departments[];
  floatLabel: string;
  showLongTermBaseTax: boolean = false;
  multipleVatInput: AgDropdownConfig;
  vatData: DropdownOptions[] = [];
  selectedMultipleVatIds: number[] = [];
  // buildingRoomTypeInput: { buildingList: { checked: boolean; id: number; isSelected: boolean; value: number; viewValue: string; }[]; roomsList: { selectedRooms: { checked: boolean; disabled: boolean; id: number; outletId: number; value: number; viewValue: string; }[]; }[]; };
  constructor(private utilities: RetailUtilities, private fb: UntypedFormBuilder, private localization: RetailLocalization, private business: VatConfigurationBusiness, private _rs: RetailSetupService,
    private propertyInfo: RetailPropertyInformation, private propertySetting: PropertySettingDataService, private vatDataService: VatDataService, private func: RetailFunctionalityBusiness, private taxTypeDataService: TaxTypeDataService, private taxTypeBusiness: TaxTypeBusiness) {
    propertyInfo.SetDefaultDataOnLoad(); // sets property configuration from local storage
    this.minEndDate = this.localization.getDate(propertyInfo.CurrentDate);
    this.minStartDate = this.localization.getDate(propertyInfo.CurrentDate);
    this.floatLabel = this.localization.setFloatLabel;
  }
  ngOnInit() {
    this.propertyId = parseInt(this.localization.GetPropertyInfo("PropertyId"));
    this.currentProductId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.generateVatForm();
    this.generateTaxForm();
    this.initializeFormInputs();
    this.enableSave = false;
    this.VatForm.controls.enableVat.setValue(this.propertyInfo ? this.propertyInfo.IsVATEnabled : false);
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    this.getInitialData();
  }

  generateVatForm() {
    this.VatForm = this.fb.group({
      enableVat: false,
      vatNumber: '',
      serviceCharge: 1,
      gratuity: 1,
      itemComission: 2,
      revenueComission: 2,
      includeVat: false,
      vat: ''

    });   

  }

  generateTaxForm() {
    this.taxForm = this.fb.group({
      baseTax: 1,
      taxName: '',
      taxChange: 0,
      comments: '',
      roomsList: '',
      taxFormArray: this.fb.array([])
    });
  }

  initializeFormInputs() {

    this.serviceCharges = [
      { id: 1, name: this.captions.gross },
      { id: 2, name: this.captions.net }
    ];

    this.gratuity = [
      { id: 1, name: this.captions.gross },
      { id: 2, name: this.captions.net }
    ];

    this.commission = [
      { id: 1, name: this.captions.gross },
      { id: 2, name: this.captions.net }
    ];

    this.effectiveStartDate = {
      className: 'ag_form-control--1',
      form: this.taxForm,
      formControlName: 'effectivestartDays',
      placeHolderId: 'lbl_effectiveDays',
      placeHolder: this.captions.lbl_effectiveStartDate,
      minDate: this.minStartDate,
      isDateRequired: true,
      errorMessage: this.captions.EffectiveStartDateRequired,
    };

    this.effectiveEndDate = {
      className: 'ag_form-control--1',
      form: this.taxForm,
      formControlName: 'effectiveEndDays',
      placeHolderId: 'lbl_effectiveDays',
      placeHolder: this.captions.lbl_effectiveEndDate,
      minDate: this.minEndDate,
      isDateRequired: true,
      errorMessage: this.captions.EffectiveEndDateRequired,
    };

    this.minimumPriceInput = {
      className: 'ag_form-control',
      form: this.taxForm,
      formControlName: 'minimumPrice',
      placeHolder: this.captions.minimumPrice,
      isRequired: false,
      disabled: false
    };

    this.buildingRoomTypeInput = {
      buildingList: [],
      roomsList: []
    };
    this.multipleVatInput = {
      form: this.VatForm,
      formControlName: 'vat',
      selectOptions: of([]),
      placeHolder: this.captions.vat,
      className: 'ag_form-control--lg',
      isAll: false,
      isMultiSelect: true,
    };

  }

  initializeSubscribeEvents() {
    if (this.showLongTermVATConfig) {
      this.subscriber = this.taxForm.valueChanges.subscribe((value) => {
        this.enableSave = (this.VatForm.valid && this.taxForm.valid) && (this.VatForm.dirty || this.taxForm.dirty);
      });
      this.subscriber = this.VatForm.valueChanges.subscribe((value) => {
        this.enableSave = (this.VatForm.valid && this.taxForm.valid) && (this.VatForm.dirty || this.taxForm.dirty);
      });
    }
    else {
      this.subscriber = this.VatForm.valueChanges.subscribe((value) => {
        this.enableSave = this.VatForm.valid && this.VatForm.dirty;
      });
    }
    this.enableVatConfig(this.propertyInfo ? this.propertyInfo.IsVATEnabled : false);
  }

  enableVatConfig(value) {
    if (value) {
      this.VatForm.controls.vatNumber.setValidators([Validators.required, EmptyValueValidator]);
      this.VatForm.controls.serviceCharge.setValidators(Validators.required);
      this.VatForm.controls.gratuity.setValidators(Validators.required);
      this.VatForm.controls.itemComission.setValidators(Validators.required);
      this.VatForm.controls.revenueComission.setValidators(Validators.required);
      if (this.showLongTermVATConfig) {
        this.taxForm.controls.baseTax.setValidators([Validators.required, EmptyValueValidator])
        this.taxForm.controls.taxName.setValidators(Validators.required);
      }
    } else {
      this.VatForm.controls.vatNumber.clearValidators();
      this.VatForm.controls.serviceCharge.clearValidators();
      this.VatForm.controls.gratuity.clearValidators();
      this.VatForm.controls.itemComission.clearValidators();
      this.VatForm.controls.revenueComission.clearValidators();
      if (this.showLongTermVATConfig) {
        this.taxForm.controls.baseTax.clearValidators();
        this.taxForm.controls.taxName.clearValidators();
        this.taxForm.controls.roomsList.clearValidators();
        this.taxForm.controls.taxFormArray.clearValidators();
      }
    }
    this.VatForm.controls.vatNumber.updateValueAndValidity();
    this.VatForm.controls.serviceCharge.updateValueAndValidity();
    this.VatForm.controls.gratuity.updateValueAndValidity();
    this.VatForm.controls.itemComission.updateValueAndValidity();
    this.VatForm.controls.revenueComission.updateValueAndValidity();
    if (this.showLongTermVATConfig) {
      this.taxForm.controls.baseTax.updateValueAndValidity();
      this.taxForm.controls.taxName.updateValueAndValidity();
      this.taxForm.controls.roomsList.updateValueAndValidity();
      this.taxForm.controls.taxFormArray.updateValueAndValidity();
    }
    if (this.showLongTermVATConfig) {
      this.enableSave = (this.VatForm.valid && this.taxForm.valid) && (this.VatForm.dirty || this.taxForm.dirty);
    }
    else {
      this.enableSave = this.VatForm.valid && this.VatForm.dirty;
    }
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  addDynamicControls() {
    if (this.functionalities.ShowNoTaxExempt) {
      this.VatForm.addControl('notaxexempt', new UntypedFormControl(false));
    }
    if (this.functionalities.ShowLongTermVATConfiguration) {
      this.showLongTermVATConfig = true;
    }
  }

  async getInitialData() {
    this.functionalities = await this.func.getRetailFunctionality()
   
    this.addDynamicControls();
    this.initializeSubscribeEvents();
    this.viewOnly = this._rs.retailSetupBreakPoints.find(bp => bp.breakPointNumber == myGlobals.RetailBreakPoint.VATConfiguration).view;
    this.vatConfiguration = await this.vatDataService.GetVatConfigurationInfo(this.propertyId).finally(()=>{
      this.utilities.ToggleLoader(false);
    });
    this.setVatConfiguration();
    this.getConfiguredVATs();
    if (this.showLongTermVATConfig) {
      try {
        await this.taxTypeBusiness.getTaxType(true).then(data => this.taxList = data);
        let additionalVATConfigurationResponse = await this.vatDataService.GetAdditionalVatConfigurationInfo();
        this.additionalVATConfiguration = additionalVATConfigurationResponse[0];
        const result = await this.vatDataService.GetDepartmentAndPostTypes(true);
        if (result) {
          this.departmentList = result.departments;
          this.departmentForRange = cloneDeep(this.departmentList);
          if (this.departmentForRange) {
            let dept: API.Departments = {
              id: 0,
              name: 'All'
            }
            this.departmentForRange.push(dept);
            this.departmentForRange = this.departmentForRange.reverse();
          }
          this.posttypeList = result.postTypes;
          this.department = this.business.MapDepartments(this.departmentList);
          this.postings = this.business.MapPostTypes(this.posttypeList, this.additionalVATConfiguration);
          this.buildingRoomTypeInput = {
            buildingList: this.department,
            roomsList: this.postings
          };
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
      this.setadditionalVatConfiguration();
    }
    this.disableSave();
  }

  includeServiceChargeVAT() {
    this.VatForm.controls['vat'].setValue(0);
  }

  async save() {
    this.utilities.ToggleLoader(true,this.captions.lbl_processing);
    await this.setVat();   
    if (!this.VatForm.controls['enableVat'].value) {
      this.ResetForm();
    }
    else if (!this.VatForm.controls['includeVat'].value) {
      this.VatForm.controls.vat.setValue([]);
    }
    var data: VATConfiguration = this.formVatConfigurationData();
    await this.vatDataService.UpdateVatConfigurationInfo(data);
    this.vatConfiguration = await this.vatDataService.GetVatConfigurationInfo(this.propertyId).finally(()=>{
      this.utilities.ToggleLoader(false);
    })
  
    await this.setTaxId();
    this.setVatConfiguration();
    if (this.showLongTermVATConfig) {
      try {
        var additionalVATData: AdditionalVATConfiguration = this.formAdditionalVatConfigurationData();
        if (this.validateTaxForm(additionalVATData)) {
          await this.vatDataService.UpdateAdditionalVatConfigurationInfo(additionalVATData);
          let additionalVATConfigurationInfo = await this.vatDataService.GetAdditionalVatConfigurationInfo().finally(()=>{
            this.utilities.ToggleLoader(false);
          });
          this.additionalVATConfiguration = additionalVATConfigurationInfo[0];
          this.setadditionalVatConfiguration();
        
          this.utilities.showAlert(this.captions.msg_savedSuccesfully, AlertType.Success, ButtonType.Ok);
          
        }
        else {
          this.utilities.showAlert(this.captions.duplicateRangeMaintenenace, AlertType.Error, ButtonType.Ok);
          this.utilities.ToggleLoader(false);
          this.disableSave();
          return;
        }
      }
      catch (e) {
        console.error(e);
        throw e;
      }
    }
    await this.SetPropertyConfiguration();
    this.disableSave();
  }

  async SetPropertyConfiguration() {
    let propertityConfig = await this.propertySetting.getAllPropertySetting();
    this.propertyInfo.SetPropertySetting(propertityConfig);
  }

  async cancel() {
    this.VatForm.controls.enableVat.setValue(this.propertyInfo ? this.propertyInfo.IsVATEnabled : false);
    this.setVatConfiguration();
    this.setadditionalVatConfiguration();
    this.disableSave();
  }

  setVatConfiguration() {
    if (this.vatConfiguration) {
      this.VatForm.controls.vatNumber.setValue(this.vatConfiguration ? this.vatConfiguration.vatNumber : '');
      const tempServiceCharge = this.vatConfiguration.serviceChargeOnGross ? GrossNetEnum.Gross : GrossNetEnum.Net;
      this.VatForm.controls.serviceCharge.setValue(this.vatConfiguration ? tempServiceCharge : GrossNetEnum.Gross);
      const tempGrautity = this.vatConfiguration.gratuityOnGross ? GrossNetEnum.Gross : GrossNetEnum.Net;
      this.VatForm.controls.gratuity.setValue(this.vatConfiguration ? tempGrautity : GrossNetEnum.Gross);
      const tempItemComission = this.vatConfiguration.itemCommissionOnGross ? GrossNetEnum.Gross : GrossNetEnum.Net;
      this.VatForm.controls.itemComission.setValue(this.vatConfiguration ? tempItemComission : GrossNetEnum.Net);
      const tempRevenuComission = this.vatConfiguration.revenueCommissionOnGross ? GrossNetEnum.Gross : GrossNetEnum.Net;
      this.VatForm.controls.revenueComission.setValue(this.vatConfiguration ? tempRevenuComission : GrossNetEnum.Net);
      this.VatForm.controls.includeVat.setValue(this.vatConfiguration ? this.vatConfiguration.vatEnbledForServiceCharge : false);      
      if (this.functionalities.ShowNoTaxExempt) {
        this.VatForm.controls.notaxexempt.setValue(this.vatConfiguration ? this.vatConfiguration.noTaxExempt : false);
      }
    }
  }

  setadditionalVatConfiguration() {
    if (this.showLongTermVATConfig) {
      this.taxForm.controls.baseTax.setValue(1);
      this.taxForm.controls.taxName.setValue(this.additionalVATConfiguration ? this.additionalVATConfiguration.taxName : '');
      this.taxForm.controls.taxChange.setValue(this.additionalVATConfiguration ? this.additionalVATConfiguration.noOfDaysForTaxChanges : 0);
      this.taxForm.controls.comments.setValue(this.additionalVATConfiguration ? this.additionalVATConfiguration.comments : '');
      this.taxForm.controls.roomsList.setValue(this.business.MapPostTypes(this.posttypeList, this.additionalVATConfiguration));
      this.arrayFormation();
    }
  }

  formVatConfigurationData(): VATConfiguration {
    let _body: any = {};
    _body.id = this.vatConfiguration && this.vatConfiguration?.id ? this.vatConfiguration.id : 0;
    _body.vatNumber = this.VatForm.controls['vatNumber'].value;
    _body.serviceChargeOnGross = this.VatForm.controls['serviceCharge'].value == GrossNetEnum.Gross;
    _body.serviceChargeOnNet = this.VatForm.controls['serviceCharge'].value == GrossNetEnum.Net;
    _body.vatEnbledForServiceCharge = this.VatForm.controls['includeVat'].value;
    _body.serviceChargeVATId = this.VatForm.controls['vat'].value ? this.VatForm.controls['vat'].value[0] : 0;
    _body.gratuityOnGross = this.VatForm.controls['gratuity'].value == GrossNetEnum.Gross;
    _body.gratuityOnNet = this.VatForm.controls['gratuity'].value == GrossNetEnum.Net;
    _body.itemCommissionOnGross = this.VatForm.controls['itemComission'].value == GrossNetEnum.Gross;
    _body.itemCommissionOnNet = this.VatForm.controls['itemComission'].value == GrossNetEnum.Net;
    _body.revenueCommissionOnGross = this.VatForm.controls['revenueComission'].value == GrossNetEnum.Gross;
    _body.revenueCommissionOnNet = this.VatForm.controls['revenueComission'].value == GrossNetEnum.Net;
    _body.propertyId = parseInt(this.localization.GetPropertyInfo("PropertyId"));
    _body.productId = parseInt(this.localization.GetPropertyInfo("ProductId"));
    let addOnVatDetails:AddOnVatDetails[] = [];
    if(this.VatForm.controls['vat'].value?.length > 0)
    {
      this.VatForm.controls['vat'].value.forEach( vat => {
       let vatDetail = this.vatConfiguration.addOnVatDetails.find(d => d.vatId == vat);
       if(vatDetail)
       {
        addOnVatDetails.push(vatDetail);
       }
       else
       {        
        let newVatDetail:AddOnVatDetails = {
          id:0,
          propertyVATConfigurationId: 0,
          vatId:vat,
          type:1    
        }
        addOnVatDetails.push(newVatDetail);
       }        
      })
    }
    _body.addOnVatDetails = addOnVatDetails;
    if (this.functionalities.ShowNoTaxExempt) {
      _body.noTaxExempt = this.VatForm.controls['notaxexempt']?.value;
    }
    return _body;
  }

  formAdditionalVatConfigurationData(): AdditionalVATConfiguration {
    let _body: any = {};
    if (this.showLongTermVATConfig) {
      _body.id = this.additionalVATConfiguration && this.additionalVATConfiguration?.id ? this.additionalVATConfiguration.id : 0;
      _body.baseTaxId = this.taxForm.controls['baseTax'].value ? this.taxForm.controls['baseTax'].value : 0;
      _body.taxName = this.taxForm.controls['taxName'].value;
      _body.noOfDaysForTaxChanges = this.taxForm.controls['taxChange'].value ? this.taxForm.controls['taxChange'].value : 0;
      _body.comments = this.taxForm.controls['comments'].value;
      _body.VATPostTypes = this.getSelectedPostTypes(this.taxForm.controls.roomsList?.value[0]?.selectedRooms);
      _body.VATMaintenance = this.getVATMaintenance(this.taxForm.controls?.taxFormArray?.value);
    }
    return _body;
  }

  getVATMaintenance(taxFormArray): VATMaintenance[] {
    let result: VATMaintenance[] = [];
    let data: VATMaintenance;
    if (taxFormArray && taxFormArray.length > 0) {
      for (let index = 0; index < taxFormArray.length; index++) {
        const formArray = taxFormArray[index];
        if (formArray && formArray.startDate !== '' && formArray.endDate !== '') {
          data = {
            id: formArray.id,
            startDate: formArray.startDate,
            endDate: formArray.endDate,
            minimumPrice: formArray.minPrice,
            departmentId: formArray.dept,
            taxPercentage: formArray.taxPercent
          };
          result.push(data);
        }
      }
    }
    return result;
  }

  getSelectedPostTypes(selectedRooms: any[]): VATPostTypes[] {
    let result: VATPostTypes[] = [];
    let data: VATPostTypes;
    if (selectedRooms) {
      selectedRooms.forEach(postType => {
        if (postType.checked) {
          data = {
            id: 0,
            postTypeId: postType.id,
            departmentId: postType.department
          };
          result.push(data);
        }
      });
    }
    return result;
  }

  ResetForm() {
    this.VatForm.controls.vatNumber.setValue('');
    this.VatForm.controls.serviceCharge.setValue(GrossNetEnum.Gross);
    this.VatForm.controls.gratuity.setValue(GrossNetEnum.Gross);
    this.VatForm.controls.itemComission.setValue(GrossNetEnum.Net);
    this.VatForm.controls.revenueComission.setValue(GrossNetEnum.Net);
    this.VatForm.controls.includeVat.setValue(false);
    this.VatForm.controls.vat.setValue([]);

    if (this.functionalities.ShowNoTaxExempt) {
      this.VatForm.controls.notaxexempt.setValue(false);
    }

    if (this.showLongTermVATConfig) {
      this.taxForm.controls.baseTax.setValue(1);
      this.taxForm.controls.taxName.setValue('');
      this.taxForm.controls.comments.setValue('');
      this.taxForm.controls.taxChange.setValue(0);
      this.taxForm.controls.roomsList.setValue('');
      this.taxForm.controls.taxFormArray = this.fb.array([]);
    }
  }

  async setVat() {
    let setting: Partial<PropertySetting> = {
      vatEnabled: this.VatForm.controls['enableVat'].value
    }
    this.propertySetting.UpdatePropertySetting(setting, "VATEnabled");
  }

  async setTaxId() {
    if (this.VatForm.controls['enableVat'].value && this.VatForm.controls['vatNumber'].value && this.VatForm.controls['vatNumber'].value != '') {
      let setting: Partial<PropertySetting> = {
        taxId: this.VatForm.controls['vatNumber'].value
      }
      this.propertySetting.UpdatePropertyTaxId(setting, "TaxId");
    }
  }

  disableSave() {
    if (this.enableSave) {
      this.enableSave = false;
    }
  }

  async getConfiguredVATs() {
    this.activeVats = await this.vatDataService.getAllActiveVATs();
    this.vatData = await this.mapActiveVatsListToUI(this.activeVats);
    this.multipleVatInput.selectOptions = of(this.vatData);
    this.multipleVatInput = {...this.multipleVatInput};
    if (this.vatConfiguration) {
      let selectedVats = this.vatConfiguration.addOnVatDetails;
      let seletedVatIds = [];
      this.vatData.forEach(v => 
        {
          if(selectedVats.find(sv => sv.vatId == v.id))
          {
            seletedVatIds.push(v.id);
          }
        })
        this.VatForm.get('vat').setValue(seletedVatIds);
    }
   
  }

  public async mapActiveVatsListToUI(data): Promise<DropdownOptions[]> {
    try {
      return await data?.map(x => { return { id: x.id, value: x.taxName, viewValue: x.taxName}; });
     
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  onBuildingRoomchange(e) {
    this.taxForm.controls.roomsList.setValue(e[2]);
    this.enableSave = true;
  }

  onMultiVatChange(e)
  {
    if (e.id > 0) {
      if (!this.selectedMultipleVatIds.includes(e.id))
        this.selectedMultipleVatIds.push(e.id);
      else
        this.selectedMultipleVatIds = this.selectedMultipleVatIds.filter(x => x != e.id);
    }
    else {
      this.selectedMultipleVatIds = this.VatForm.value.vat.map(x => x.id);
    }
    const selectedVat = this.vatData.filter(a => this.selectedMultipleVatIds.includes(Number(a.id)));
    this.VatForm.get('vat').setValue(selectedVat);
  }
  
  baseTaxTypeSelected(e) {
    // if (e.value > 0) {
    //   this.taxForm.controls.baseTax.setValue(e.value);
    // } else {
    //   this.taxForm.controls.baseTax.setValue(0);
    // }
  }

  addtaxArray(e) {
    const form = this.fb.group({
      startDate: this.minStartDate,
      endDate: this.localization.AddDays(this.minEndDate, 30),
      dept: 0,
      minPrice: 0,
      taxPercent: 0
    });
    const control = this.taxForm.get('taxFormArray') as UntypedFormArray;
    control.push(form);
    this.taxForm.markAsDirty();   
    
  }

  removetaxArray(e) {
    const control = this.taxForm.get('taxFormArray') as UntypedFormArray;
    control.removeAt(e);
    this.enableSave = true;
  }

  searchValueChange(searchTerm: string) {
    this.searchText = searchTerm;
  }

  arrayFormation() {
    let taxFormArray = this.taxForm.get('taxFormArray') as UntypedFormArray;
    taxFormArray.clear();
    if (this.taxForm && this.additionalVATConfiguration && this.additionalVATConfiguration.vATMaintenance && this.additionalVATConfiguration.vATMaintenance.length > 0) {
      this.additionalVATConfiguration.vATMaintenance.forEach(element => {
        const postForm = this.createTaxForm(element);
        taxFormArray.push(postForm);
      });
    }
    else {
      const postForm = this.fb.group({
        id: 0,
        startDate: this.minStartDate,
        endDate: this.localization.AddDays(this.minEndDate, 30),
        dept: 0,
        minPrice: 0,
        taxPercent: 0
      });
      taxFormArray.push(postForm);
    }
  }

  createTaxForm(element): UntypedFormGroup {
    return this.fb.group({
      id: element.id,
      startDate: [element.startDate, Validators.required],
      endDate: [element.endDate, Validators.required],
      dept: element.departmentId,
      minPrice: element.minimumPrice,
      taxPercent: element.taxPercentage
    });
  }

  dateControlChange(isStartDateChanged: number, arg) {
    const startDate = this.localization.getDate(arg.controls.startDate.value);
    const endDate = this.localization.getDate(arg.controls.endDate.value);
    if(isStartDateChanged == 1){
      this.minEndDate = this.localization.dateAdd.AddDays(this.localization.getDate(arg.controls.startDate.value),1);
    }
    else{
      this.maxStartDate = this.localization.dateAdd.AddDays(this.localization.getDate(arg.controls.endDate.value),-1);
    }
    
    if (isStartDateChanged && isStartDateChanged == 1 && this.resetTime(startDate) >= this.resetTime(endDate)) {
      this.utilities.showAlert(this.captions.startDateShouldNotBeGreaterThanEndDate, AlertType.Error, ButtonType.Ok);
      arg.controls.startDate.setValue('');
      arg.controls.endDate.setValue('');
      this.enableSave = false;

    }
    if (isStartDateChanged && isStartDateChanged == 2 && this.resetTime(endDate) <= this.resetTime(startDate)) {
      this.utilities.showAlert(this.captions.endDateCannotBePriorToStartDate, AlertType.Error, ButtonType.Ok);
      arg.controls.startDate.setValue('');
      arg.controls.endDate.setValue('');
      this.enableSave = false;
    }
  }

  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  validateTaxForm(additionalVATConfiguration): boolean {
    let result: boolean = true;
    if (additionalVATConfiguration && additionalVATConfiguration.VATMaintenance && additionalVATConfiguration.VATMaintenance.length > 0) {
      additionalVATConfiguration.VATMaintenance.forEach(x => {
        if (x.departmentId !== 0 && additionalVATConfiguration.VATMaintenance.findIndex(y => ((x.id !== 0 && y.id !== 0 && x.id !== y.id) || (x.id == 0 && y.id == 0 && x.id == y.id) || ((x.id !== 0 || y.id !== 0) && x.id !== y.id)) &&
          y.startDate >= x.startDate && y.endDate <= x.endDate && y.departmentId === x.departmentId && y.minimumPrice === x.minimumPrice) >= 0) {
          result = false;
        }
      });
    }
    return result;
  }
}
