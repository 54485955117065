import { Component, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AgFieldConfig } from '../../Models/ag-models';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { Localization } from '../../localization/localization';

@Component({
  selector: 'app-ag-number',
  templateUrl: './ag-number.component.html',
  styleUrls: ['./ag-number.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AgNumberComponent  {
  customClass: string;
  errorMessage: string;
  form: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  maxLength: string | number;
  minlength: string | number;
  minValue: number;
  captions: any;
  isRequired: boolean;
  disabled: boolean;
    maxValue: number;
    customMinErrorMsg: string;
    customMaxErrorMsg: string;
  placeHolderId:string;
  errorMessageId:string;
  floatLabel: string; 
  automationId: any;
  @Output() inputChange = new EventEmitter();
  @Output() outputValue = new EventEmitter();

  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.maxLength = value.maxlength;
    this.minlength = value.minlength;
    this.minValue = value.minValue;
    this.maxValue = value.maxValue;
      this.isRequired = value.isRequired;
      this.customMaxErrorMsg = value.customMaxErrorMsg;
      this.customMinErrorMsg = value.customMinErrorMsg
    this.errorMessageId = value.errorMessageId;
    this.placeHolderId = value.placeHolderId;
    this.automationId = value.automationId ? value.automationId : '';

    if (this.value) {
      this.form.controls[value.formControlName].setValue(this.value);
    }
      if (value.disabled) {
          this.form.controls[value.formControlName].disable();
      } else {
          this.form.controls[value.formControlName].enable();
      }
    const validatorList = [];
    if (this.minValue) {
      validatorList.push(Validators.min(this.minValue));
    }
    if (this.maxValue) {
      validatorList.push(Validators.max(this.maxValue));
    }
    if (validatorList.length) {
      this.form.controls[value.formControlName].setValidators(validatorList);
    
    }
  }


    constructor(private _localization: Localization, public dialog: MatDialog) {
        this.captions = this._localization.captions;
        this.floatLabel = this._localization.setFloatLabel? this._localization.setFloatLabel: '';
    }


  inputChanged(event) {
    this.inputChange.emit(event);
  }

  valueEmit(eve) {
    this.outputValue.emit(this.form.controls[this.controlName].value);
  }
  
  onControlClick() {
    if (document.designMode == 'on') {
    this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: this.placeHolder,
          oldErrorMessage: this.errorMessage,
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result) {
          this.placeHolder = result.newplaceholder;
          this.errorMessage = result.newErrorMessage;
        }
      });
    }
  }
}
