import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { ExportType } from './export-send.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { GuestPolicyDetail, GuestExportNotificationModel } from '../shared/shared.modal';
import { HttpHeaders } from '@angular/common/http';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { JasperReportCommunication } from 'src/app/common/communication/services/jasper-report.service';
import { ExportSendBusiness } from './export-send-business';
import { CommonDataAwaiters } from "src/app/common/shared/events/awaiters/common.data.awaiters";
import {MailTypes } from 'src/app/common/shared/shared/enums/enums';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';


@Component({
  selector: 'app-export-send',
  templateUrl: './export-send.component.html',
  styleUrls: ['./export-send.component.scss'],
  providers:[ExportSendBusiness],
  encapsulation:ViewEncapsulation.None
})
export class ExportSendComponent implements OnInit {

  captions: any;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  ExportTypeEnum = ExportType
  exportForm: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input("inputData") guestData: GuestPolicyDetail;
  exportEmail : string = '';
  exportPhone : string = '';
  exportGuestId : string = '';
  reportDomStringData: Blob;
  reportDOM: Document;
  isAPILoaded: boolean = false;
  showError: boolean = false;
  link;
  
  constructor(private localization:Localization,private dialogRef: MatDialogRef<any>, private business:ExportSendBusiness,
    private utils:CommonUtilities, public storageservice:StorageService,private fb: UntypedFormBuilder, private http: JasperReportCommunication,private PropertySettingService: PropertySettingDataService) {
    this.captions = this.localization.captions;
   }

   ngOnInit(): void {
    this.initializeInputs();
    this.initializeForm();
  }

  initializeForm(){
    if(this.guestData){
      this.exportGuestId = this.guestData.id;
      this.exportEmail = this.guestData.email.length > 0 ? this.GetEmail(this.guestData.email) : null;
      this.guestData.phone.forEach(e=>{
      if(this.exportPhone == '' && e.phoneNumber !='' && (e.primaryPhone || e.privateInformtionPhone)){
        this.exportPhone = e.phoneNumber;
        return true;
      }
      else if(this.exportPhone == '' && e.phoneNumber !=''){
        this.exportPhone = e.phoneNumber;
        return true;
        }
    });
    }

    this.exportForm=this.fb.group({
      exportType:this.ExportTypeEnum.Email,
      emailID:this.exportEmail,
      phoneNumber:this.exportPhone
    })
    this.actionButton.disabledproperty = !( this.exportEmail!="" );//&& this.exportPhone!="" 
    this.actionButton = { ...this.actionButton }
      this.exportForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
        if(this.validateEmail(value.emailID)){
          this.showError = false;
          this.exportEmail = value.emailID
        }
        else{
          this.showError = true;
        }
      this.actionButton.disabledproperty = !(!this.showError && this.exportForm.valid && this.exportForm.dirty);
      this.actionButton = { ...this.actionButton }
  })
  }

  GetEmail(email: any): any {
    let primaryEmail = email.find(x => x.primaryEmail);
    if (primaryEmail) {
      return primaryEmail.emailId;
    }
    let personalEmail = email.find(x => x.emailType == MailTypes.personal);
    if (personalEmail) {
      return personalEmail.emailId;
    } 
    let officeEmail = email.find(x => x.emailType == MailTypes.office);
   if (officeEmail) {
      return officeEmail.emailId;
    } else {
      return email[0].emailId;
    }
  }

  initializeInputs() {
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_send,
      disabledproperty: true
    };

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    };
  this.link="http://www.company.com"
}

  close() {
    this.dialogRef.close();
  }
  onCancel(e) {
    this.dialogRef.close();
  }

  onAction(e){
    this.utils.ToggleLoader(true);
    // const array = ['<q id="a"><span id="b">hey!</span></q>']; // an array consisting of a single string
    // const blob = new Blob(array, { type: "text/html" });
    // this.sendGuestDataEmailRequest(blob).then(result => {
    //   if(result)
    //   {
    //     this.utils.showCommonAlert(this.captions.lbl_msgSentSuccessfully, AlertType.Done, ButtonTypes.Ok, async (res) => {
    //       if (res === AlertAction.CONTINUE) {
    //         this.dialogRef.close();
    //       }
    //     });
    //   }
    //   this.utils.ToggleLoader(false);  
    // });
    this.generateReportPDF().then(x => {    
    this.utils.ToggleLoader(true);  
    this.sendGuestDataEmailRequest(x).then(result => {
      if(result)
      {
        this.utils.showCommonAlert(this.captions.lbl_emailSentSuccessfully, AlertType.Done, ButtonTypes.Ok, async (res) => {
          if (res === AlertAction.CONTINUE) {
            this.dialogRef.close();
          }
        });
      }
      else { 
        this.utils.showCommonAlert(this.captions.ErrorSendingMail, AlertType.Error, ButtonTypes.Ok, async (res) => {
        });
      }
      this.utils.ToggleLoader(false);  
    });
  });    
  }
  onCopy(val){
    const selBox = document.createElement('textarea');
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  downloadFile(){
    this.utils.ToggleLoader(true);
    this.generateReportPDF().then((res) => {
      let fileName = "GuestDetails-"+ this.guestData.lastName;
      this.business.saveFileFunction(res,fileName);
    });
    this.utils.ToggleLoader(false);
    
  }
  private async generateReportPDF(): Promise<Blob> {
    this.isAPILoaded = false;
    this.reportDomStringData = await this.sendReportRequest(false);   
    return this.reportDomStringData;
  }

  private async sendReportRequest(isHtml: boolean = true): Promise<Blob> {
    try {    
      await this.PropertySettingService.UpdateRoleAndAttributeToUser();
      var _authtokenprovider = this.storageservice.getstoragekey('authtokenProvider');
      const headers = new HttpHeaders()
      .set('pp', sessionStorage.getItem("_jwt"))
      .set("tokenProvider",_authtokenprovider);

      const responseType = isHtml ? "text" : "blob";
      let response$: Promise<Blob> = this.http.jasperReportGetWithCustomHeaders({
        route: isHtml ? CommonApiRoutes.GetGuestExportDetailsHtml : CommonApiRoutes.GetGuestExportDetailsPdf,
        uriParams: {GuestId: this.exportGuestId},
        header: headers
      },responseType).toPromise();
      response$.then(x => {
        this.isAPILoaded = true;
      })
      response$.catch(err => {
        let errorTxt: string = "";
        let errorObjStr: string = err.error;
        let errorCode = JSON.parse(errorObjStr).errorCode;
        errorTxt = this.localization.getError(errorCode);
        this.isAPILoaded = true;
      });
      return await response$;
    } catch (e) {
      console.error(e);
      this.utils.ToggleLoader(false);
      this.utils.showCommonAlert(this.captions.ErrorGeneratingReport, AlertType.Error, ButtonTypes.Ok, async (res) => {
        if (res === AlertAction.CONTINUE) {
        }
      });
      throw e;
    }
  }

  
  private async sendGuestDataEmailRequest(attachmentData: Blob) {

    let base64Data = await this.blobToBase64(attachmentData);
    let base64AttachmentData = await base64Data.split(',')[1];

    const details = {
      guestId: this.exportGuestId,
      contactPhone: this.exportPhone,
      contactEmail: this.exportEmail,
      isSendEmail: true,//!this.exportEmail && this.exportEmail!=''
      isSendSMS: false,
      fromName: this.localization.GetPropertyInfo('PropertyName'),
      mailBody: 'Guest Details - ' + this.guestData.lastName,
      mailSubject: 'Guest Details - ' + this.guestData.lastName,
      attachmentContent: base64AttachmentData,
    } as GuestExportNotificationModel;

    var result = await CommonDataAwaiters.SendGuestExportEmail(details)
      .catch(err => {
        this.utils.ToggleLoader(false);
      });
    this.utils.ToggleLoader(false);

    return result;
  }

  private async blobToBase64(blob) : Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  
  

  buttonSelectionChange(e){

  }
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


}
