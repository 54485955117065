import { QueryParams } from '../api-query-params.interface';
import { QueryConfig } from './query-list-config.interface';

export const FormQueryConfig: QueryConfig = ( queryParams: QueryParams): any => {
    try {
        queryParams.filter = '';
        const keys = Object.keys(queryParams);
        let query = '';
        keys.forEach((data, index) => {
            if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
                if (queryParams[data] !== '' && data !== 'filter') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        if (query.length > 0) {
                            query = query + '&$';
                        } else {
                            query = query + '?$';
                        }
                    }
                    query = query + data + '=' + queryParams[data];
                } else if (data === 'filter') {
                    if (queryParams.isactive) {
                        queryParams[data] = 'IsActive eq true';
                    }
                    if (queryParams.searchtext && queryParams.searchtext.length > 0 && queryParams.search) {
                        if (queryParams[data].length > 0) {
                            queryParams[data] = queryParams[data] + ' and ' + queryParams.search;
                        } else {
                            queryParams[data] = queryParams.search;
                        }
                    }
                    if (queryParams.filterQuery && queryParams.filterQuery.length > 0) {
                        if (queryParams[data].length > 0) {
                            queryParams[data] = queryParams[data] + ' and ' + queryParams.filterQuery;
                        } else {
                            queryParams[data] = queryParams.filterQuery;
                        }
                    }
    
                    if (queryParams[data] !== '') {
                        if (index === 0) {
                            query = query + '?$';
                        } else {
                            query = query + '&$';
                        }
                        query = query + data + '=' + queryParams[data];
                    }
                }
            }
        });
        return query;
    } catch (error) {
        console.error(error);
    }
};