import { Pipe, PipeTransform } from "@angular/core";
import { ApplyWithinType } from "../cancellation-no-show-policy.model";

@Pipe({
    name: 'duplicate'
})
export class DuplicatePipe implements PipeTransform {
    
    transform(array, idx): boolean {
        let flag = false;
        let valueToCheck = array[idx].applyWithinHoursDays;
        if (array[idx]['applyWithin'] == ApplyWithinType.Days) {
            valueToCheck = valueToCheck * 24;
        }
        flag = array.some((obj, index) => {
            if (index != idx && obj.isCancellationPolicy) {
                let applyWithinValue = obj.applyWithinHoursDays;
                if (obj.applyWithin == ApplyWithinType.Days)
                    applyWithinValue = applyWithinValue * 24;
                return applyWithinValue === valueToCheck;
            }
        });
        return flag;
    }
}