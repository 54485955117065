import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { LookUpPopupModel } from '../model/pop-up.interface';

@Injectable({
  providedIn: 'root'
})
export class AgilysysLookupPopupService {

  constructor(
    private httpService: HttpService
  ) { }

  GetLookUpDatas(lookUpConfiguration: LookUpPopupModel, TabIndex: number) {
    const CurrentLookUpObject = lookUpConfiguration.Content[TabIndex];
    const FilterId = lookUpConfiguration.RowData['Id'];
    const EntityName = CurrentLookUpObject.OdataKey;
    const EndPointUrl = `/inventory/api/${EntityName}?$filter=IngredientId eq ${FilterId}`;
    this.httpService.GethttpMethod(EndPointUrl).subscribe(res => {
      console.log('Return Data', res);
    });
  }

  assignRowData(ResponseData, ViewModelEntity) {
    try {
        if (ResponseData) {
            ResponseData.forEach(dataobject => {
                for (const prop in dataobject) {
                    if (prop) {
                        if (dataobject[prop] && dataobject[prop] !== null) {
                            const propType = typeof dataobject[prop];
                            if (propType === 'object') {
                                this.assignGridNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                            }
                        }
                    }
                }
            });
            return ResponseData;
        } else {
            return [];
        }
    } catch (error) {
        console.log('error occured assignRowData', error);
    }
}

assignGridNavigationProperity(ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl.hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl['NavigationProperty'].length > 0) {
        ViewModelEntityColl['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'].toLowerCase().toString();
            if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
                entityObject[ProperityName] = ProperirtyObject['Name'];
            }
        });
    }
    return entityObject;
}
}
