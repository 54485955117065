import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { AppointmentpopupService } from 'src/app/shared/service/appointmentpopup.service';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { cloneDeep } from 'lodash';
import { NoDataModel } from 'src/app/shared/business/shared.modals';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';

@Component({
  selector: 'app-appointment-add-ons',
  templateUrl: './appointment-add-ons.component.html',
  styleUrls: ['./appointment-add-ons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentAddOnsComponent implements OnInit {

  clientDetails = [];
  existingAddons = [];
  initialListAddons = [];
  selectedClientId: number;
  searchText: string;
  captions: any;
  serviceId: number;
  therapistIds: number[];
  isMultiClient: boolean = false;
  showAddons: boolean = false;
  currencySymbol: any;
  noDataOptions: NoDataModel;
  hasAccessForEditAddOns: boolean = true;
  isSCMT: boolean = false; //is Single Client Multi Therapist
  therapistDetails = [];
  clientApptDetails = [];
  clientAddonsAvailable: boolean = false;

  @Input("selectedTabindex")
  set ClientBlockInformation(value: any) {
    if (Number(value) == 5) { //5 is for Addons index
      this.showAddons = false;
      this.isSCMT = this.clientAddonsAvailable = false;
      this.loadInitData().then(() => {
        let clientSelectedAddons = this.appointmentservice.recordsArray.find(y => y.clientDetail.id == this.selectedClientId)?.clientDetail.selectedAddOns;
        if (clientSelectedAddons?.length > 0) {
          this.appointmentservice.addOns.forEach(x => {
            if (clientSelectedAddons.some(y => y.addOnId == x.addOnId && y.therapistId == x.therapistId))
              x.isSelected = true;
            else
              x.isSelected = false;
          })
        }
      });
    }
  }

  constructor(public appointmentservice: AppointmentpopupService, public localization: SpaLocalization, public http: HttpServiceCall, public utils: SpaUtilities, private breakPoint: BreakPointAccess) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.noDataOptions = { inputText: this.captions.common.NoDataFound, noDataId: GlobalConst.noRecordsType.noRecords, noDataFontSize: 10 };
    this.appointmentservice.hasAccessForAddOns = this.breakPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.ServiceAddOn], false);
    this.hasAccessForEditAddOns = this.breakPoint.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.ServiceAddOn);
    this.clientDetails = this.appointmentservice.recordsArray;
    this.currencySymbol = this.localization.currencySymbol;
    this.existingAddons = this.existingAddons.concat(...this.appointmentservice.existingAppointmentsRef?.map(x => x.appointmentAddOn));
    this.appointmentservice.makeAddOnsCall = true;
    this.setclientApptDetails();
  }

  OnChange(eve, item) {
    item.isSelected = eve.checked;
    this.appointmentservice.addOnsSaveBtnEnable = true;
    this.appointmentservice.recordsArray.forEach(x => {
      if (x.clientDetail.id == this.selectedClientId) {
        if (x.clientDetail.selectedAddOns) {
          if (eve.checked) {
            x.clientDetail.selectedAddOns.push(item);
          }
          else {
            let indexToRemove = x.clientDetail.selectedAddOns.findIndex(y => {
              return y.addOnId == item.addOnId;
            });
            x.clientDetail.selectedAddOns.splice(indexToRemove, 1);
          }
        } else {
          x.clientDetail['selectedAddOns'] = [];
          x.clientDetail['selectedAddOns'].push(item)
        }
      }
    })
  }

  selectClient(item) {
    this.selectedClientId = item.clientDetail.id;
    this.loadAddons(this.selectedClientId);
    this.appointmentservice.recordsArray.forEach(x => {
      if (x.clientDetail.id == item.clientDetail.id) {
        x.clientDetail['selectedClient'] = true;
        if (x.clientDetail.selectedAddOns && x.clientDetail.selectedAddOns.length >= 0) {
          x.clientDetail.selectedAddOns.forEach(val => {
            this.appointmentservice.addOns.forEach(y => {
              if (val.addOnId == y.addOnId && (this.isSCMT || val.theraipstId == y.theraipstId)) {
                y.isSelected = true;
              }
            })
          })
        }
      } else if (x.clientDetail.id != item.clientDetail.id) {
        x.clientDetail['selectedClient'] = false;
      }
    })

  }

  async loadInitData() {
    if (this.loadAddonsValidation()) {
      this.showAddons = true;
      this.appointmentservice.recordsArray[0].clientDetail['selectedClient'] = true;
      for (let i = 1; i < this.appointmentservice.recordsArray.length; i++)
        this.appointmentservice.recordsArray[i].clientDetail['selectedClient'] = false;
      this.selectedClientId = this.appointmentservice.recordsArray[0].clientDetail.id;
      if (this.appointmentservice.makeAddOnsCall)
        await this.getServiceTherapistAddons(this.serviceId, this.therapistIds);
      this.loadAddons(this.selectedClientId);
    }
  }

  selectExistingAddons() {
    this.existingAddons.forEach(x => {
      this.appointmentservice.addOns.forEach(y => {
        if (y.addOnId == x.addOnId && y.therapistId == x.therapistId && x.appointmentId == this.clientApptDetails?.find(x => x.clientId == this.selectedClientId)?.appointmentId) {
          y.isSelected = true;
        }
      })
    });
    if (this.existingAddons.length > 0)
      this.appointmentservice.recordsArray.find(x => x.clientDetail.id == this.selectedClientId).clientDetail['selectedAddOns'] = this.appointmentservice.addOns.filter(x => x.isSelected == true);
  }

  loadAddonsValidation(): boolean {
    let tempArr = this.appointmentservice.TempHoldArr;
    if (tempArr.length > 0) {
      this.serviceId = tempArr[0].service;
      let serviceDetails = this.appointmentservice.serviceArray.find(x => x.id == this.serviceId);
      let multiClientValidation: boolean = true;
      let clientValidation: boolean = false;
      if (serviceDetails?.maximumGuest > 1) {
        if (serviceDetails.maximumStaff > 1) { //Multi Client Multi Therapist validation
          if (this.appointmentservice.isEditAppointment)
            multiClientValidation = tempArr.every(x => x.therapist.length > 0);
          else
            multiClientValidation = tempArr[0].therapist.length >= serviceDetails.minimumGuest;
        }
        if(this.appointmentservice.recordsArray?.length > 1)
         multiClientValidation &&= this.appointmentservice.validateMultiClient();
      }
      else if (serviceDetails.maximumGuest == 1 && serviceDetails.maximumStaff > 1) { // Single Client Multiple Therapist validation
        multiClientValidation = tempArr[0].therapist.length >= serviceDetails.minimumStaff;
      }
      this.isSCMT = this.appointmentservice.recordsArray?.length == 1 && tempArr[0].therapist.length > 1;
      let therapistId = new Set<number>([].concat(...tempArr.map(x => x.therapist)));
      this.therapistIds = Array.from(therapistId);
      clientValidation = this.appointmentservice.recordsArray?.length >= serviceDetails.minimumGuest;
      return tempArr.every(x => x.service != 0 && x.therapist.length > 0) && multiClientValidation && clientValidation;
    }
    return false;
  }

  searchFunc() {
    const filterValue = this.searchText.toLowerCase();
    let filteredValue = this.initialListAddons.filter(x => x.addOnName.toLowerCase().includes(filterValue))
    this.appointmentservice.addOns = filteredValue;
  }

  searchCancel() {
    this.searchText = '';
    this.appointmentservice.addOns = this.initialListAddons;
  }

  async getServiceTherapistAddons(serviceId, therapistIds) {
    this.therapistDetails = [];
    this.utils.ToggleLoader(true);
    const response = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.schedule,
      callDesc: 'GetServiceTherapistAddons',
      method: HttpMethod.Get,
      uriParams: {
        serviceId: serviceId,
        therapistIds: therapistIds
      },
      showError: true
    });
    this.utils.ToggleLoader(false);
    this.appointmentservice.addOnsResult = <any>response.result;
    this.appointmentservice.makeAddOnsCall = false;
    if (this.isSCMT)
      this.getTherapistDetails();
  }


  loadAddons(clientId) {
    let clientTherapist;
    let therapistAddons = [];
    if (this.appointmentservice.multiClientInfo?.length > 1) {
      clientTherapist = this.appointmentservice.multiClientInfo.find(x => x.id == clientId).TherapistId;
      therapistAddons = cloneDeep(this.appointmentservice.addOnsResult.filter(x => x.therapistId == clientTherapist));
    }
    else {
      clientTherapist = this.appointmentservice.TempHoldArr[0].therapist;
      clientTherapist.forEach(therapist => {
        therapistAddons = therapistAddons.concat(cloneDeep(this.appointmentservice.addOnsResult.filter(x => x.therapistId == therapist)));
      });
    }
    this.appointmentservice.addOns = therapistAddons;
    if (this.appointmentservice.isEditAppointment && this.appointmentservice.recordsArray.find(x => x.clientDetail.id == clientId).clientDetail.selectedAddOns == undefined)
      this.selectExistingAddons();
    this.initialListAddons = this.appointmentservice.addOns;
    this.clientAddonsAvailable = this.appointmentservice.addOns.length > 0;
  }

  getTherapistDetails() {
    this.therapistIds.forEach(therapist => {
      this.therapistDetails.push({
        therapistId: therapist,
        therapistName: this.getTherapistName(therapist),
        hasAddOns: this.checkTherapistHasAddons(therapist)
      });
    });
  }

  getTherapistName(therapistId): string {
    let therapist = this.appointmentservice.therapistArray.find(x => x.id == therapistId);
    return therapist.firstName + ' ' + therapist.lastName;
  }

  checkTherapistHasAddons(therapistId): boolean {
    return this.appointmentservice.addOnsResult.some(x => x.therapistId == therapistId);
  }

  setclientApptDetails() {
    this.appointmentservice.TempHoldArr.forEach(appt => {
      this.clientApptDetails.push({
        appointmentId: Number(appt.TempHoldLinkId),
        clientId: appt.clientId
      });
    });
  }
}
