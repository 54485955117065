import { NavigationMenuReducer } from './navigation-menu.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { GlobalStateReducer } from './global-state.reducer';
import { OdataReducer } from './odata-configuration.reducer';
import { LoginReducer } from './login.reducer';
import { UserListReducer } from './user-list.reducer';
import { TransactionReducer } from './transaction.reducer';

export const appReducers: ActionReducerMap <any> = {
    NavigationMenu: NavigationMenuReducer,
    GlobalState: GlobalStateReducer,
    OdataConfiguration: OdataReducer,
    LoginState: LoginReducer,
    UserListState: UserListReducer,
    TransactionState: TransactionReducer
};

