import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkvirtualpaymentComponent } from './components/cdkvirtual/cdkvirtual.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PopoverModule } from "ngx-smart-popover";
import { CommonSharedModule } from 'src/app/common/shared/shared/shared.module';
import { MaterialModule } from 'src/app/common/material-module';


@NgModule({
  declarations: [
    CdkvirtualpaymentComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
      PopoverModule,
      MaterialModule,
      CommonSharedModule
  ],
  exports: [
    CdkvirtualpaymentComponent,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    MaterialModule,
    CommonSharedModule
]
})
export class PaymentManagerSharedModule { }
