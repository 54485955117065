import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isexistpipe'
})
export class IsExistPipe implements PipeTransform {
    transform(value: boolean, coll, obj): number {
        return coll && coll.some(x => ((x.id || x.id === 0) ? x.id : x) === obj.id);
    }
}
