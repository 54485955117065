import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import {
  FormIcons,
  OnEventArgs,
  AutoCompleteHeader,
  AutocompleteChipHeader,
} from '../../form-type.entity';
import { FormValidation, CustomErrorMesssage } from '../../form-validation';
import _ from 'lodash';

@Component({
  selector: 'lib-search-multi-autocomplete',
  templateUrl: './search-multi-autocomplete.component.html',
  styleUrls: ['./search-multi-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchMultiAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('chipList') chipList;
  @Input() field: any = {};
  @Input() form: FormGroup;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('formtext', { read: false, static: true }) formtext: ElementRef;
  @ViewChild(MatAutocompleteTrigger) searchAuto: MatAutocompleteTrigger;
  searchControl = new FormControl('');
  formAddBuilderIcons: FormIcons;
  formRemoveBuilderIcons: FormIcons;
  formRemoveIcons: FormIcons;
  // tslint:disable-next-line: no-inferrable-types
  hidePassword: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  typeField: string = 'text';
  formFieldName: any;
  fieldType: any;
  formFieldlabel: any;
  formHintLabel: string;
  formArrayName: any;
  formFieldHeader: string;
  errorMessage: string;
  // tslint:disable-next-line: no-inferrable-types
  rowIndex: number = 0;
  validationMesage: string;
  errorLength: number;
  autoCompleteHeader: Array<AutoCompleteHeader> = [];
  autocompleteChipHeader: Array<AutocompleteChipHeader> = [
    { AutoCompleteChipKey: '' },
  ];
  autoCompleteData: Array<any> = [];
  selectedItems = [];
  checked = new FormControl(false);
  selectable = true;
  removable = true;
  addOnBlur = false;
  loading = false;
  EnableSelectedItemsCount = false;
  multiAutocompletePopup = false;
  autocompletedialogRef: any;
  selectedItemsCount: any;
  name = '';
  id = '';
  selectedOption: any;
  formFieldToolTip: string;
  selectedLabels = [];
  checkCleared = false;
  optionselected: string;
  isRequired: boolean;
  get isValid(): any {
    return this.searchControl.valid;
  }
  get isDirty(): any {
    return this.searchControl.dirty;
  }
  get isTouched(): any {
    return this.searchControl.touched;
  }
  constructor(
    public agyDynamicFormServices: AgyDynamicFormServices,
    public renderer: Renderer2,
    private formValidation: FormValidation,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) { 
    window.addEventListener('scroll', this.scrollEvent.bind(this), true);
  }
  scrollEvent() {
    if (this.searchAuto?.panelOpen) {
      this.searchAuto.updatePosition();
    }
  }
  fieldArrayCallback(e: any): void {
    this.field.fieldType.SingleData.autoCompleteData.push(e);
  }
  eventCallback(index?: any, argus?: OnEventArgs): void {
    this.agyDynamicFormServices.formEventCallback(this.field, index, this.form);
  }

  getIndex(e: number): void {
    this.rowIndex = e;
  }
  // selectedOption($event: any): void {
  //     try{
  //         this.agyDynamicFormServices.formEventCallback($event.value, this.field, this.form);
  //     }catch (error){
  //         console.error(error);
  //     }
  // }
  ngAfterViewInit(): void {
    this.isRequired = this.form.controls[this.formFieldName]?.validator ?
        !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
    this.form.controls[this.formFieldName].statusChanges
      .pipe(debounceTime(700))
      .subscribe((x) => {
        this.isRequired = this.form.controls[this.formFieldName]?.validator ?
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
        this.errorMessage = CustomErrorMesssage.createError(
          this.fieldType,
          this.form,
          this.field
        );
        this.searchControl.markAsTouched();
        if (
          this.form.controls[this.formFieldName] &&
          this.form.controls[this.formFieldName].errors
        ) {
          const errorsArray = Object.keys(
            this.form.controls[this.formFieldName].errors
          );
          this.errorLength = errorsArray.length;
        } else {
          this.errorLength = 0;
        }
      });
    this.searchControl.statusChanges
      .pipe(debounceTime(700))
      .subscribe((x) => {
        this.errorMessage = CustomErrorMesssage.createError(
          this.fieldType,
          this.form,
          this.field
        );
        if (
          this.form.controls[this.formFieldName] &&
          this.form.controls[this.formFieldName].errors
        ) {
          const errorsArray = Object.keys(
            this.form.controls[this.formFieldName].errors
          );
          this.errorLength = errorsArray.length;
        } else {
          this.errorLength = 0;
        }
      });
    if (
      this.form.controls[this.formFieldName] &&
      this.form.controls[this.formFieldName].errors
    ) {
      const errorsArray = Object.keys(
        this.form.controls[this.formFieldName].errors
      );
      this.errorLength = errorsArray.length;
    }
    if (this.searchControl) {
      this.agyDynamicFormServices.formEventCallback(this.field, '', this.form);
      this.searchControl.valueChanges
        .pipe(debounceTime(700))
        .subscribe((res) => {
          if (!this.field.fieldType[this.fieldType].disbaledProperty) {
            if (typeof res === 'string') {
              this.field.fieldType.SingleData.autoCompleteData = this
                .selectedItems
                ? _.cloneDeep(this.selectedItems)
                : [];
              this.agyDynamicFormServices.formEventCallback(
                this.field,
                res,
                this.form,
                this.optionselected
              );
            }
          }
        });
    }
    if (this.selectedItems) {
      this.selectedLabels = this.selectedItems?.map((ele) => ele[this.name]);
    }
    this.form.controls[this.formFieldName].valueChanges
      .pipe(debounceTime(700))
      .subscribe((res) => {
        // If TransferOut is not selected the value and searchControl is set to ''
        if (
          this.form.controls[this.formFieldName].value === null ||
          this.form.controls[this.formFieldName].value === ''
        ) {
          this.selectedItems = [];
          this.searchControl.setValue(null, { emitEvent: false });
          this.searchControl.updateValueAndValidity();
        }
        if (typeof res === 'object' && !Array.isArray(res) && res) {
          this.searchControl.setValue(res[this.name], { emitEvent: false });
          this.form.controls[this.formFieldName].setValue(res[this.id]);
        } else if (res && Array.isArray(res) && res?.every(ele => ele[this.id])) {
          const Ids = [];
          res?.forEach((x: any) => {
            if (x.ischecked === true) {
              this.selectedItems.push(x);
              Ids.push(x[this.id]);
            }
          });
          this.form.controls[this.formFieldName].setValue(Ids, { emitEvent: false });
        }
        this.selectedLabels = [];
        if (this.selectedItems) {
          this.selectedLabels = this.selectedItems?.map(
            (ele) => ele[this.name]
          );
        }
      });
  }

  clearSearchText(): void {
    this.optionselected = null;
    this.selectedOption = undefined;
    this.searchControl.setValue('');
    this.form.controls[this.formFieldName].reset();
    this.checkCleared = true;
    // this.field.fieldType.SingleData.autoCompleteData = [];
  }

  ngOnInit(): void {
    this.formAddBuilderIcons = {
      type: 'add',
      label: 'add',
      maxcount: this.field.maxcount,
    };
    this.formRemoveBuilderIcons = {
      type: 'remove',
      label: 'remove',
      maxcount: this.field.maxcount,
    };
    this.fieldType = Object.keys(this.field.fieldType).toString();
    const formField = this.agyDynamicFormServices.generateFormFieldName(
      this.field
    );
    this.formFieldName = formField.fieldName;
    this.formFieldlabel = formField.fieldLabel;
    this.formArrayName = formField.fieldName;
    this.formFieldHeader = formField.fieldHeader;
    this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip
      ?.EnableToolTip
      ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText
      : null;
    if (this.field.hasOwnProperty('autoCompleteHeader')) {
      this.autoCompleteHeader = this.field.autoCompleteHeader;
    }
    if (this.field.fieldType.SingleData) {
      this.field.fieldType.SingleData.IsRequired = this.field.fieldType.SingleData && this.field.fieldType.SingleData.FieldValidation
        ? this.field.fieldType.SingleData.FieldValidation.filter(x => x.key === 'required').length === 0 : false;
    }
    if (this.field.fieldType.SingleData.hasOwnProperty('autoCompleteData')) {
      this.autoCompleteData = this.field.fieldType.SingleData.autoCompleteData;
    }
    if (this.autoCompleteData.length > 0) {
      this.autoCompleteData.forEach((x: any) => {
        if (x.ischecked === true) {
          this.selectedItems.push(x);
        }
      });
    }
    if (this.field.hasOwnProperty('autocompleteChipHeader')) {
      this.autocompleteChipHeader = this.field.autocompleteChipHeader;
      this.autocompleteChipHeader.forEach((chip: any) => {
        if (chip.Hide !== true) {
          this.name = chip.AutoCompleteChipKey;
        } else {
          this.id = chip.AutoCompleteChipKey;
        }
      });
    }
    this.formHintLabel = this.field.fieldType[this.fieldType].hintLabel;
  }

  keyPress(event: any): void {
    this.optionselected = null;
    if (this.field.type === 'number') {
      if (event.which === 101 || event.which === 69) {
        event.preventDefault();
      }
    }
  }
  onKey(event: any): void {
    try {
      this.optionselected = null;
      if (!this.field.fieldType[this.fieldType].disbaledProperty) {
        // this.agyDynamicFormServices.formEventCallback(this.field, event.target.value, this.form);
      }
    } catch (error) {
      console.error(error);
    }
  }
  onPaste(event): void {
    if (this.field.type === 'number') {
      const paste = event.clipboardData.getData('text');
      if (paste === 'e' || paste === 'E') {
        event.preventDefault();
      }
    }
  }

  checkBoxChange(data: any): void {
    data.ischecked = !data.ischecked;
    if (this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.value = '';
      this.searchControl?.setValue('', { emitEvent: false });
    }
    if (data.ischecked) {
      this.selectedItems.push(data);
      this.selectedItemsCount = this.selectedItems.length;
      if (this.selectedItemsCount > 2) {
        this.EnableSelectedItemsCount = true;
      } else {
        this.EnableSelectedItemsCount = false;
      }
    } else {
      const i = this.selectedItems.findIndex(
        (value) => value[this.name] === data[this.name]
      );
      this.selectedItems.splice(i, 1);
    }
    // const getKey = this.selectedItems.map(x => x[this.autocompleteChipHeader.AutoCompleteChipKey]);
    const getIds = this.selectedItems.map((x) => x[this.id]);
    const getValues = Object.values(getIds);
    this.form.controls[this.formFieldName].setValue(getIds);
    this.form.controls[this.formFieldName].updateValueAndValidity();
    // this.agyDynamicFormServices.formEventCallback(this.field, 1, this.form);
  }
  SingleSelectClick(data: any): void {
    // this.searchControl.reset('', {emitEvent: false});
    this.searchControl.setValue(data[this.name], { emitEvent: false });
    // this.searchControl.updateValueAndValidity();
    this.form.controls[this.formFieldName].setValue(data[this.id], {
      emitEvent: false,
    });
    this.selectedOption = data[this.name];
  }
  optionSelect(params: string): void {
    // AutoComplete Closed Event Value set in SearchControl
    if (this.selectedOption) {
      this.optionselected = params;
      this.checkCleared = false;
      this.searchControl.setValue(this.selectedOption, { emitEvent: false });
      this.searchControl.updateValueAndValidity();
    }
  }
  textInputClicked(): void {
    this.optionselected = null;
    if (this.checkCleared && this.selectedOption === undefined) {
      if (this.field.fieldType.SingleData.autoCompleteData.length > 0) {
        this.searchAuto.openPanel();
      } else {
        this.searchAuto.closePanel();
      }
    }
  }
  // tslint:disable-next-line: typedef
  showMore() {
    this.optionselected = null;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    const searchText =
      (this.searchControl.value !== undefined && this.searchControl.value !== null) ? this.searchControl.value
        : (document.getElementById('searchControl')['value'] !== undefined && document.getElementById('searchControl')['value'] !== null) ?
          document.getElementById('searchControl')['value'] : '';
    this.agyDynamicFormServices.formEventCallback(
      this.field,
      searchText,
      this.form,
      true
    );
  }
  remove(data: any): void {
    this.optionselected = null;
    const index = this.selectedItems.indexOf(data);
    data.ischecked = false;
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }
    const getIds = this.selectedItems.map((x) => x[this.id]);
    const getValues = Object.values(getIds);
    this.form.controls[this.formFieldName].setValue(getValues);
    this.selectedItemsCount = this.selectedItems.length;
    if (this.selectedItemsCount > 2) {
      this.EnableSelectedItemsCount = true;
    } else if (this.selectedItemsCount === 0) {
      this.autocompletedialogRef?.close();
    } else {
      this.EnableSelectedItemsCount = false;
    }
    if (!this.selectedItems?.length) {
      this.field.fieldType.SingleData.autoCompleteData = [];
      this.agyDynamicFormServices.formEventCallback(
        this.field,
        '',
        this.form
      );
    }
  }
  menuOpened(): void {
    try {
      if (!this.searchControl?.value) {
        this.field.fieldType.SingleData.autoCompleteData = this
        .selectedItems
        ? _.cloneDeep(this.selectedItems)
        : [];
        this.agyDynamicFormServices.formEventCallback(
          this.field,
          '',
          this.form
        );
      }
    } catch (error) {
      console.error(error);
    }
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.selectedItems.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.formFieldName.setValue(null);
  }
  // tslint:disable-next-line: typedef
  openPopup(templateRef: any) {
    this.optionselected = null;
    this.multiAutocompletePopup = true;
    this.autocompletedialogRef = this.dialog.open(templateRef, {
      width: '500px',
      height: '500px',
    });
  }
}
