import { Pipe, PipeTransform, ViewChild, ElementRef } from '@angular/core';
import { SpaUtilities } from '../utilities/spa-utilities';
import { AppointmentdetailsComponent } from '../appointment-popup/create-appointment/appointmentdetails/appointmentdetails.component';





/**
 * @method filterPipe
 * @description Filter the array with id if return value boolean
 * @input <array> and <number>
 * @output boolean
 */
@Pipe({
  name: 'isexitstPipe',
})
export class IsExist implements PipeTransform {
  transform(e: any, f: any[], d:any): boolean{
        if (f) {
            let index = f.findIndex(x => { return ((x.id || x.id == 0) ? x.id : x) == d.id });
            if(index == -1){
                return true;
            }else{
                return false;
            }
        }

  }
}


@Pipe({
  name: 'filterisexist',
  pure:true
})
export class FilterisExist implements PipeTransform {
  transform(e: any, coll: any, obj: any): boolean{
    if (coll) {
      let index = coll.findIndex(x => { return ((x.id || x.id == 0) ? x.id : x) == obj.id });
      if(index == -1){
          return false;
      }else{
          return true;
      }
  }
}
}

/**
 * @method isbuttonValidpipe
 * @description isbuttonValidpipe value pass the Form group Element if form valid and form dirty is true boolean return true value
 * @input Form Group
 * @output boolean
 */
@Pipe({
    name: 'isbuttonValidpipe',

  })
  export class IsbuttonValid implements PipeTransform {
    transform(e: any, d: any, f: any): boolean{
        return d && f;
    }
  }


/**
 * @method CustomColours
 * @description
 * @input Obj
 * @output boolean
 */

@Pipe({
    name: 'customcolours',
  })
  export class CustomColours implements PipeTransform {
    @ViewChild('appoinmentcolor') appoinmentcolor : ElementRef;
    constructor(public _ut: SpaUtilities){}
    transform(e: any, d: any): any{
        let CustomValue = this._ut.GetAppointmentColors(d);
        return `${CustomValue.Border} ${CustomValue.BackGroundLight}`;
    }
  }


 /**
 * @method validatesave
 * @description IF valid the Button valid or invalid
 * @input Obj
 * @output boolean
 */

  @Pipe({
    name: 'converttime',
  })
  export class ConvertTime implements PipeTransform {
    constructor(public _ad: AppointmentdetailsComponent){}
    transform(e: any, d: any): any{
        return this._ad.convertTime(d);
    }
  }

