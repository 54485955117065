import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CustomFeeConfig } from '../../shared/service/common-variables.service';
import { DropdownOptions } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-add-custom-fee-popup',
  templateUrl: './add-custom-fee-popup.component.html',
  styleUrls: ['./add-custom-fee-popup.component.scss']
})
export class AddCustomFeePopupComponent implements OnInit {
  addCustomFeeForm: UntypedFormGroup;
  customFeeConfigs: CustomFeeConfig[] = [];
  dropdown: DropdownOptions[];
  captions: any;
  shouldAllBeEnabled: boolean = true;
  constructor(private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public utils: RetailUtilities,
    public localization: RetailLocalization,
    private dialogRef: MatDialogRef<AddCustomFeePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.initializeForm();
    this.customFeeConfigs = this.data.customfee;
    this.captions = this.localization.captions;
    this.dropdown = this.mapdropDownOptions(this.customFeeConfigs);
    this.shouldAllBeEnabled = !this.customFeeConfigs.some(x => x.isApplied);
  }

  mapdropDownOptions(data: CustomFeeConfig[]) {
    let res: DropdownOptions[] = data.map(x => {
      return {
        id: x.id,
        viewValue: `${x.name} - ${x.value} (${(x.isPercentage ? '%' : this.localization.currencySymbol)})`,
        value: x.name,
        checked: false,
        disabled: x.isApplied
      } as DropdownOptions
    });
    return res;
  }

  initializeForm(): void {
    this.addCustomFeeForm = this.fb.group({
      customfee: [[], Validators.required]
    });
  }

  apply() {
    console.log(this.addCustomFeeForm.controls.customfee.value);
    this.dialogRef.close(this.addCustomFeeForm.controls.customfee.value)
  }

  close() {
    this.dialogRef.close(null);
  }

  isFormValid() {
    return this.addCustomFeeForm.valid && this.addCustomFeeForm.dirty;
  }

}
