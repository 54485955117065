import { Injectable } from '@angular/core';
import { HttpCallService } from 'src/app/common/communication/common/http-call.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Localization } from 'src/app/common/localization/localization';
import { ServiceParams } from 'src/app/common/Models/http.model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { AppService } from 'src/app/common/app-service';
import { API } from 'src/app/common/Models/authorize-Integration-configuration-model';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { BaseResponse } from 'src/app/common/shared/shared/business/shared.modals';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AuthorizeConfigurationDataService extends HttpCallService {

        constructor(
            private httpclient: HttpClient,
            localization: Localization,
            utilities: CommonUtilities,
            propertyinfo: CommonPropertyInformation,
            appservice: AppService) {
            super(environment["CommonGateway"], httpclient, localization, utilities, propertyinfo, appservice);
        }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        const response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err;
        }
    }


    public GetAuthorizeConfiguration(): Promise<API.AuthorizeConfiguration>
    {
            const result = this.getPromise<API.AuthorizeConfiguration>(
                { route: CommonApiRoutes.GetAuthorizeConfiguration, uriParams: {  } }, false);
            return result;
    }

    public GetAuthorizeConfig(): Promise<API.AuthorizeConfig []>
    {
            const result = this.getPromise<API.AuthorizeConfig[]>(
                { route: CommonApiRoutes.GetAuthorizeConfig, uriParams: {  } }, false);
            return result;
    }

    public CreateAuthorizeConfiguration(body:API.AuthorizeConfiguration): Promise<API.AuthorizeConfiguration> {
        let result = this.postPromise<API.AuthorizeConfiguration>(
            { route: CommonApiRoutes.CreateAuthorizeConfiguration, body: body }, true);
        return result;
    }

    public CreateAuthorizeConfig(body:API.AuthorizeConfig[], isSetupScreen: boolean = false): Promise<API.AuthorizeConfig[]> {
        let result = this.postPromise<API.AuthorizeConfig[]>(
            { route: CommonApiRoutes.CreateAuthorizeConfig, uriParams: { isSetupScreen }, body: body }, true);
        return result;
    }

    public UpdateAuthorizeConfiguration(body:API.AuthorizeConfiguration): Promise<API.AuthorizeConfiguration> {
        let result = this.putPromise<API.AuthorizeConfiguration>(
            { route: CommonApiRoutes.CreateAuthorizeConfiguration, body: body }, true);
        return result;
    }

    public DeleteAuthorizeConfig(body:number[]): Promise<boolean> {
        let result = this.putPromise<boolean>(
            { route: CommonApiRoutes.DeleteAuthorizeConfig, body: body }, true);
        return result;
    }

    public UpdateAuthorizeConfig(body:API.AuthorizeConfig[]): Promise<boolean> {
        let result = this.putPromise<boolean>(
            { route: CommonApiRoutes.CreateAuthorizeConfiguration, body: body }, true);
        return result;
    }
}