import {Component, Inject, OnInit, ViewEncapsulation, ViewContainerRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { ButtonType } from '../globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'dialogPopup-component.html',
    styleUrls: ['./dialogPopup-componenet.scss'],
    encapsulation:ViewEncapsulation.None,
    providers: []
  })
export class DialogOverviewExampleDialog implements OnInit {
  public test: boolean = true;
  datRec: any;
  FormGrp: UntypedFormGroup;
  IsConfirmationPopup: boolean;
  buttonType: ButtonType;
  captions: any = this.localization.captions.common;
  shopCaptions: any = this.localization.captions.shop;
  setupCaption : any = this.localization.captions.retailsetup;
  price: number;
  ShopPricePrompt: boolean;
  @ViewChild("ItemName") ItemName: ElementRef;
  @ViewChild("ItemComment") ItemComment: ElementRef;
  @ViewChild("Membershippin") Membershippin: ElementRef;
  @ViewChild("ShopPrice") ShopPrice: ElementRef;
  pinFieldPopups = ["RedeemRounds", "CMSPin"];
  floatLabel: string;


  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public viewContainerRef: ViewContainerRef
    , private Form: UntypedFormBuilder
    , public localization: RetailLocalization
  ) {
    this.FormGrp = this.Form.group({
      HideDialog: false,
      Reason: '',
      ShopPrice: '',
      ScaledUnit: '',
      Barcodes: '1',
      Membershippin: '',
      ItemName: '',
      ItemComment: '',
      cmsPin: ''
    });
    this.floatLabel = this.localization.setFloatLabel;
  }

     DialogClose(result?: any): void {
      switch (this.data.Type) {
        case "OPENPRICE":
          this.dialogRef.close({
            res: result
            , price: this.FormGrp.controls['ShopPrice'].value
            , itemName: this.FormGrp.controls['ItemName'].value
          });
          this.FormGrp.controls['ItemName'].setValidators(null);
          break;
        case "ENTERCOMMENT":
          this.dialogRef.close({
            res: result,
            comment: this.FormGrp.controls['ItemComment'].value
          });
          this.FormGrp.controls['ItemComment'].setValidators(null);
          break;
        case "SCALEDITEM":
          this.dialogRef.close({ res: result, unit: this.FormGrp.controls['ScaledUnit'].value })
          break;
        case "BC":
          this.dialogRef.close({ res: result, labelPosition: this.FormGrp.controls['Barcodes'].value })
          break;
        case "RedeemRounds":
          this.dialogRef.close({ res: result, labelPosition: this.FormGrp.controls['Membershippin'].value })
          break;
        case "CMSPin":
          this.dialogRef.close({ res: result, labelPosition: this.FormGrp.controls['cmsPin'].value })
          break;
        default:
          this.dialogRef.close(result);
          break;
      }
    }

    ngOnInit(){
        this.datRec = this.data.datarecord;
        this.buttonType = this.data.buttonType ? this.data.buttonType : ButtonType.Ok;
        if (this.data.Type == "OPENPRICE") {
          this.FormGrp.controls['ItemName'].setValue(this.data.data.itemDescription);
          this.FormGrp.controls['ItemName'].setValidators(Validators.required);
        }
        if(this.data.Type ==="ENTERCOMMENT"){ 
          console.log(this.data);
          this.FormGrp.controls['ItemComment'].setValidators(Validators.required);
        }
        if(this.data.Type ==="RedeemRounds"){ 
          this.FormGrp.controls['Membershippin'].setValidators(Validators.required);
        }
        if(this.data.Type == "Confirm")
        {

          this.IsConfirmationPopup = true;
        }
        else
        {
          this.IsConfirmationPopup = false;
        }
    }

    ngAfterViewInit(){
      if (this.data.Type == "OPENPRICE" && this.ItemName && this.ShopPrice) {
        setTimeout(() => {
          if(this.data.data.itemDescription == "")
          {
            this.ItemName.nativeElement.focus();
          }
          else
          {
            this.ShopPrice.nativeElement.focus();
          }
         
        }, 100);
      }

      if (this.data.Type == "RedeemRounds" && this.Membershippin) {
        setTimeout(() => {
          this.Membershippin.nativeElement.focus();
         
        }, 100);
      }
      
      if(this.data.Type === "ENTERCOMMENT" && this.ItemComment){
        this.ItemComment.nativeElement.focus();
      }
    }

  }
