import { Component, OnInit} from '@angular/core';
import { KakaoTemplateMappingBusiness } from '../KAKaO-Template-Mapping/Kakao-Template-mapping.business';
import { FromTypeEnum, ActionTypeEnum, aligment, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { ActionMode, ButtonType,AlertType, TableActions } from '../../enums/shared-enums';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from '../../localization/localization';
import { UntypedFormGroup } from '@angular/forms';
import { createModal } from '../../components/create-modal/create-model';
import { Observable, of } from 'rxjs';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { map } from 'rxjs/operators';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { UserAccessDataService } from '../../dataservices/authentication/useraccess.data.service';


@Component({
    selector: 'app-Kakao-Template-mapping',
    templateUrl: './Kakao-Template-mapping.component.html',
    styleUrls: ['./Kakao-Template-mapping.component.scss'],
    providers: [KakaoTemplateMappingBusiness]
  })
  
  export class KakaoTemplateMappingComponent implements OnInit
  {
    kakaoTemplateMappingForm : UntypedFormGroup;
    showInactiveTog : boolean;
    tableContent : any;
    IsEditModeEnabledFlag : any;
    CreationToggler : any;    
    headerOptions: TableHeaderOptions[];
    tableOptions: TableOptions;
    searchHeaderOption : any;    
    captions: any;
    crudActionInput: any;
    createModalData: createModal;
    createTitle: string;
    UpdateTitle: string;
    originalData: Observable<any>;
    originalData$: Promise<any[]>;
    smsTemplateId : any[] = [];
    isViewOnly: boolean = false;
    searchText: string;
    constructor(private localization:Localization,private kakakoTemplatebusiness:KakaoTemplateMappingBusiness,
      private commonUtils: CommonUtilities, private userAccessDataService : UserAccessDataService) 
      { 
    
      }

    ngOnInit(): void {
      this.captions  = this.kakakoTemplatebusiness.Captions;
      this.initializeSearch();
      this.validateUserAccess();      
      this.tableOptions = this.getTableOptions(false);     
      this.headerOptions = this.getHeaderOptions();
      this.createTitle = this.captions.CreateKakaoTemplate;
      this.UpdateTitle = this.captions.UpdateKakaoTemplate;
      this.CreationToggler = false;
      this.showInactiveTog = true; 
      this.getTableData();
    }

    async validateUserAccess() {
      let userAccess = await this.userAccessDataService.getUserAccess(UserAccessBreakPoints.KakaoMapping);
      if (userAccess?.isViewOnly) {
        this.isViewOnly = true;
        this.searchHeaderOption.createBtnDisabled = this.isViewOnly;
        this.searchHeaderOption = { ...this.searchHeaderOption };
        this.tableOptions = this.getTableOptions(this.isViewOnly);
      }
    }

    initializeSearch() {
      this.searchHeaderOption = {
        searchPlaceHolder: this.captions.lbl_searchBySMSTemplateNameKakaoTemplateName,
        showInactive: true,
        createBtnLabel: this.captions.CreateKakaoTemplate,
        toggleLabel: this.captions.tog_lbl_showInactive,
        maxLength: 100,
        createBtnDisabled: this.isViewOnly
      };
    }

    public getTableOptions(viewOnly) : TableOptions {
      return {
        actions: [
          {
              type: TableActions.edit,
              disabled: viewOnly
          }, 
          {
              type: TableActions.delete,
              disabled: viewOnly
          },
          {
            type : TableActions.copy,
            disabled : viewOnly
          }
      ],
        defaultsortingColoumnKey: 'smsTemplateName',
        showTotalRecords: true,
        defaultSortOrder: SorTypeEnum.asc,        
        isHeaderCheckboxAllowed: true,
        checkboxKey: 'checked',
        ignoreSort: true
      };
    }

    searchChange(event) {
      this.searchText = event;
    }

    createEvent(e,mode?) {      
      this.CreationToggler = true;
      const listorder = '';
      mode = mode == undefined ? 0 : mode
      this.crudActionInput = {
          mode: mode,
          actionButton: mode === ActionMode.update ? this.captions.btn_update : this.captions.btn_create,
          form: e
      };
      this.createModalData = {
          title: mode === 1 ? this.UpdateTitle : this.createTitle
      
    }
  }

  showInactiveToggler(e) {
    if (e.checked) {
      this.showInactiveTog = true;
      this.tableContent = this.originalData.pipe(map(x => x));
    } else {
      this.showInactiveTog = false;
      this.tableContent = this.originalData.pipe(map(x => x.filter(t => t.isActive.value)));
    }
  }

  formReady(event) {
    this.kakaoTemplateMappingForm = event;
  }

  handleClick(event) {
    event.data.items = event.data.items.filter(x => (x.buttonDisplayName != null && x.buttonDisplayName.length > 0) && x.buttonType != null && x.buttonType > 0) 
    switch (event.mode) {
      case ActionMode.create:
      case ActionMode.copy:     
       this.kakakoTemplatebusiness.createKakaoMappingTemplate(event.data).then(response => {
         this.kakaoTemplateMappingForm.reset();
         this.commonUtils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
         this.back(event);
         this.getTableData();
       }).catch(ex => {
        if (ex.error && ex.error.errorCode) {
         const code : number = parseInt(ex.error.errorCode);
         const message: string = this.localization.getError(code);
         this.commonUtils.showAlert(message, AlertType.Error, ButtonType.Ok);
        } 
       });
       this.backAction();
        break;
      case ActionMode.update:
        this.kakakoTemplatebusiness.updateKakaoMappingTemplate(event.data).then(response => {
          this.kakaoTemplateMappingForm.reset();
          this.commonUtils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
          this.back(event);
          this.getTableData();
        }).catch(ex => {
         if (ex.error && ex.error.errorCode) {
          const code : number = parseInt(ex.error.errorCode);
          const message: string = this.localization.getError(code);
          this.commonUtils.showAlert(message, AlertType.Error, ButtonType.Ok);
         } 
        });
        this.backAction();
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
      case ActionMode.delete :
        this.kakakoTemplatebusiness.deletekakaoMappingTemplate(event.Obj.id).then(res => {
          this.getTableData();
        });
        break;
    }
  }

  async getTableData()
  {
      const smsTemplate = await this.kakakoTemplatebusiness.getSMSTemplateData(ActionMode.view);
      const kakaoTemplateCode = await this.kakakoTemplatebusiness.getKakoTalkTemplateCodes();
      this.kakakoTemplatebusiness.getAllKakaoMappingTemplate(true).then(x => {
        console.log(x);
        this.originalData = of(this.kakakoTemplatebusiness.mapforGrid(smsTemplate,x,kakaoTemplateCode));
        this.tableContent = this.originalData.pipe(map(x => x.filter(t => t.isActive.value)));
        this.commonUtils.ToggleLoader(false);
      })   
  }

  tableAction(event) {
    switch(event.fromType)
    {
      case TableActions.edit :
        this.IsEditModeEnabledFlag = true;
        const data = this.kakakoTemplatebusiness.getEditData(event.Obj);
        this.createEvent(data,ActionMode.update);
        break;
      case TableActions.delete :
        this.kakakoTemplatebusiness.deletekakaoMappingTemplate(event.Obj.id).then(res => {
          this.getTableData();
        });
        break;
        case TableActions.copy : 
        this.IsEditModeEnabledFlag = false;
        const copyData = this.kakakoTemplatebusiness.getEditData(event.Obj);
        this.createEvent(copyData,ActionMode.copy);
        break;
    }
  }

  back(event) {
    this.backEmit(event);
    this.getTableData();
  }

  backEmit(e) {
    if (this.kakaoTemplateMappingForm) {
        this.kakaoTemplateMappingForm.reset();
    }
    this.CreationToggler = false;
    this.IsEditModeEnabledFlag = false;
}

  public getHeaderOptions() {
    return  [
      {
        key: 'kakaoTemplateCode',
        displayNameId: 'kakaoTemplate_grid_hdr_Code',
        displayName: this.captions.Kakaolbl_KakaoTemplateId,
        sortingkey : 'kakaoTemplateCode',
        searchable : true,
        sorting : true
       },
       {
        key: 'smsTemplateName',
        displayNameId: 'smsTemplate_grid_hdr_Code',
        displayName: this.captions.smsTemplateName,
        sortingkey : 'smsTemplateName',
        searchable : true,
        sorting : true
      },
       {
        key: 'isActive',
        displayNameId: 'grid_hdr_IsActive',
        displayName: this.captions.grid_hdr_IsActive,
        searchable: false,
        sorting: true,
        sortingKey: 'isActive.value',
        templateName: ActionTypeEnum.toggle
    },
    {
        key: ActionTypeEnum.action,
        templateName: ActionTypeEnum.action,
        displayNameId: 'tbl_hdr_actions',
        displayName: this.captions.tbl_hdr_actions,
        searchable: false
    } 
    ];
  }

  backAction() {
    this.CreationToggler = false;
    this.IsEditModeEnabledFlag = false;
  }
  } 