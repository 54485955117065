import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input, ContentChildren, QueryList, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { VIRTUAL_SCROLL_STRATEGY, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CustomVirtualScrollStrategy, GridTableDataSource } from './cdkvirtual.base';
import { AgDurationConfig, TableOptions, TableHeaderOptions, SorTypeEnum, FromTypeEnum, ActionTypeEnum, HdrActionTypeEnum, aligment, TableActions } from './cdkvirtual.model';
import * as _ from 'lodash';
import { ButtonValue, ButtonTypes, AlertType, AlertAction } from '../../shared-model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

export const TOT_REC_HEIGHT = 24;

@Component({
  selector: 'app-pay-cdkvirtual',
  templateUrl: './cdkvirtual.component.html',
  styleUrls: ['./cdkvirtual.component.scss'],
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }, { provide: MatPaginatorIntl }],
  changeDetection: ChangeDetectionStrategy.OnPush
  ,
  encapsulation: ViewEncapsulation.None
})


export class CdkvirtualpaymentComponent implements OnInit, OnDestroy {

  placeholderHeight = 0;
  dynamicHeight = 110;
  totalrecordHeight = 24;
  originaldata: any[];
  _tableContent: any = [];
  ordertype: string;
  sortingColoumnKey: any;
  _options: TableOptions;
  isFilterByAPI: boolean = false;
  _headerOptions: TableHeaderOptions[];
  uniqueKey: string;
  scrolledIndex: number;
  paginationHeight: number = 0;
  aligment = aligment;
  selectedRowId = -1;
  defaultPageSize;
  floatLabel: string;
  noDataLabel: string;
  _orgtableContent: any;
  @Input('headerOptions')
  set headerValue(value: TableHeaderOptions[]) {
    this._headerOptions = value;
    this.displayedColumns = value && value.map(col => col.key);
    if (this.searchText) { this.SearchFilter(this.searchText) };

  }

  @Input()
  set options(value: TableOptions) {
    this._options = _.cloneDeep(value);
    if (this._options) {
      this.paginationHeight = this._options.enablePagination ? 54 : 0;
      this.ordertype = this._options.defaultSortOrder ? this._options.defaultSortOrder : SorTypeEnum.asc;
      this.sortingColoumnKey = this._options.defaultsortingColoumnKey;
      this.dynamicHeight = ((this._options.showTotalRecords) || (this._options.CDK_showConfigure)) ? this.dynamicHeight + this.totalrecordHeight : this.dynamicHeight
      this.totalrecordHeight = ((this._options.showTotalRecords) || (this._options.CDK_showConfigure)) ? TOT_REC_HEIGHT + this.paginationHeight : 0 + this.paginationHeight;
      this.displayedColumns = this._headerOptions && this._headerOptions.map(col => col.key);
      this.deleteMsgKey = this._options.deleteMsgKey ? this._options.deleteMsgKey : 'name';
      this.showDeleteMsg = !this._options.showDeleteMsg ? false : true;
      this.uniqueKey = this._options.uniqueKey ? this._options.uniqueKey : 'id';
      this.defaultPageSize = this._options.defaultPageSize ? this._options.defaultPageSize : 10;
      this.noDataLabel = this._options?.noDataLabel;
      if (this._options.enablePagination) {
        this.dataSource = new MatTableDataSource(this._tableContent);
        this.dataSource.paginator = this.paginator;
      } else {
        if (!this.dataSource || this.dataSource.hasOwnProperty('filteredData')) {
          this.dataSource = new GridTableDataSource(this._tableContent, this.viewport, this.customRowHeight ? this.customRowHeight : this.itemSize);
          this.dataSource.offsetChange.pipe(takeUntil(this.destroyed$)).subscribe(offset => {
            this.placeholderHeight = offset;
            setTimeout(() => this.cdRef.detectChanges(), 1);
          });
        } else {
          this.dataSource.data = this._tableContent
        }
      }
    }
  }

  @Input() IsEditModeEnabled: boolean;
  @Input() customRowHeight: number;
  searchText: string;
  tableActionEnums: typeof TableActions;
  actionTypeenum = ActionTypeEnum;
  hdrActionTypeenum = HdrActionTypeEnum;
  sorTypeenum = SorTypeEnum;
  captions: any;
  deleteMsgKey: any;
  showDeleteMsg: boolean;
  isDragInProgress: boolean;
  selectedItemId: string | number;
  onDrag: boolean;
  @Input() childTemplate: TemplateRef<any>;
  inlineEditTriggered: boolean;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  commentKey: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  boundary: any;
  dragDisabled: boolean = true;
  isInavalidNumber: boolean;
  durationInput: AgDurationConfig;
  @Output() isEditModeOn = new EventEmitter();
  @Output() SearchChangeEmitter = new EventEmitter();
  @Input() isViewMode: boolean;
  checkboxKey: string;
  totalMsg: string;
  displayedColumns: any[];
  dataSource: GridTableDataSource | MatTableDataSource<any>;
  itemSize = 48;

  @Input()
  set searchOptions(searchtext) { if (searchtext != undefined) { this.searchText = searchtext; this.SearchFilter(searchtext); } }
  get searchOptions() { return this.searchText; }
  @Input('scrollItem')
  set scrollItem(value) {
    if (value) {
      this.scroll(value.selectedIndex);
    }
  }
  @Input()
  set tableContent(value) {
    this.IsEditModeEnabled = false;
    this._orgtableContent = value;
    this._tableContent = value ? _.cloneDeep(value) : [];
    this.checkboxKey = this._options && this._options.checkboxKey ? this._options.checkboxKey : 'isChecked';
    this.originaldata = _.cloneDeep(value);
    if (this.searchText !== undefined && !this.isFilterByAPI) { this.SearchFilter(this.searchText); }
    const tempOrder = (this._options && this._options.defaultSortOrder ? this._options.defaultSortOrder : SorTypeEnum.asc);
    this.ordertype = this.ordertype ? this.ordertype : tempOrder;
    const tempColumn = (this._options && this._options.defaultsortingColoumnKey ? this._options.defaultsortingColoumnKey : this.findFirstColoum());
    this.sortingColoumnKey = this.sortingColoumnKey ? this.sortingColoumnKey : tempColumn;
    if (this._options && !this._options.ignoreSort) {
      this.contentSorting(this.sortingColoumnKey, this.ordertype);
    }
    this.displayedColumns = this._headerOptions && this._headerOptions.map(col => col.key);
    if (this.dataSource && this._tableContent) {
      this.IsEditModeEnabled = false;
      this.inlineEditTriggered = false;
      if (this._options) {
        if (this._options.enablePagination) {
          this.dataSource = new MatTableDataSource<any>(this._tableContent);
          this.dataSource.paginator = this.paginator;
        } else {
          this.dataSource.data = this._tableContent;
          this.itemSize = this.customRowHeight ? this.customRowHeight : 48;
          this.dataSource['itemSize'] = this.itemSize;
          this.viewport.scrollToOffset(this.itemSize * this.scrolledIndex);
        }
      }
    }
  }

  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('edit') edit;
  @ViewChild('delete') delete;
  @ViewChild('copy') copy;
  @ViewChild('download') download;
  @ViewChild('sync') sync;
  @ViewChild('freetext') freetext;
  @ViewChild('alphaNumeric') alphaNumeric;
  @ViewChild('replace') replace;
  @ViewChild('history') history;
  @ViewChild('datepicker') datepicker;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  @Output() EmittedData = new EventEmitter();
  @Output() EmitHdrClick = new EventEmitter();
  @Output() sortEmit = new EventEmitter();
  @Output() dataBind = new EventEmitter();
  constructor(private _Localization: Localization,private utils: CommonUtilities, private cdRef: ChangeDetectorRef, public _MatPaginatorIntl: MatPaginatorIntl) {
    this.captions = this._Localization.captions;
    // this.floatLabel = this._Localization.setFloatLabel;
    this.dataSource = new MatTableDataSource();
    this.noDataLabel = '';
  }

  ngOnInit() {
    this._MatPaginatorIntl = new MatPaginatorIntl();
    this._MatPaginatorIntl.itemsPerPageLabel = this.captions.itemsPerPage;
    this.IsEditModeEnabled = false;
    this.tableActionEnums = TableActions;
    if (this._options) {
      if (this._options.enablePagination) {
        this.dataSource = new MatTableDataSource(this._tableContent)
      } else {
        if (!this.dataSource) {
          this.dataSource = new GridTableDataSource(this._tableContent, this.viewport, this.customRowHeight ? this.customRowHeight : this.itemSize);
          this.dataSource['itemSize'] = this.itemSize;
          this.dataSource.offsetChange.pipe(takeUntil(this.destroyed$)).subscribe(offset => {
            this.placeholderHeight = offset;
          });
        }
      }
    }
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    if (this._tableContent && this.dataSource) {
      this.dataSource.data = this._tableContent;
      this.emitTableDataOnInit();
    }
  }

  ngAfterViewInit() {
    if (this._options) {
      if (this._options.enablePagination) {
        (this.dataSource as MatTableDataSource<any>).paginator = this.paginator;
      } else {
        this.viewport.scrolledIndexChange.pipe(takeUntil(this.destroyed$)).subscribe(x => {
          this.scrolledIndex = x;
        })
      }
    }
  }



  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  scroll(selectedIndex) {
    if (this.paginator) {
      this.paginator.pageIndex = selectedIndex
    } else {
      this.viewport.scrollToOffset(this.itemSize * selectedIndex, 'smooth');
    }
  }

  placeholderWhen(index: number) {
    return index == 0;
  }

  /**
   * row edit click
   * @param eve
   * @param data
   */
  emitEditrowdata(eve, data, key) {
    this.IsEditModeEnabled = true;
    if (this._options.isInternalEdit) {
      data.isEditable = true;
      this.inlineEditTriggered = true;
    }
    this.EmittedData.emit({
      fromType: this.inlineEditTriggered ? FromTypeEnum.inlineEdit : FromTypeEnum.edit,
      array: '',
      value: '',
      Obj: data,
      key
    });
    this.isEditModeOn.emit(this.inlineEditTriggered);
  }

  emitCopyrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.copy,
      array: '',
      value: '',
      Obj: data
    });
  }

  emitReplacerowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.replace,
      array: '',
      value: '',
      Obj: data
    });
  }

  /**
   * row delete click
   * @param eve
   * @param data
   */
  emitDeleterowdata(eve, data) {
    if (this.showDeleteMsg) {
      const deleteWarningMessage = `${this.captions.lbl_delete}  ${data[this.deleteMsgKey]}`;
      this.utils.showCommonAlert(deleteWarningMessage, AlertType.Confirmation, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.deleteEmitData(data);
        }
      });
    } else {
      this.deleteEmitData(data);
    }

  }

  deleteEmitData(data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.delete,
      array: '',
      value: '',
      Obj: data
    });
  }

  // differentiate between mattabledata source (pagination page obj) & gridtabledata source(not a pagination screen)
  pageIndex(event) {
    if (this._options.enablePagination) {
      let paginatorVariable = event.container.data._paginator;
      let currentIndex = (paginatorVariable._pageSize * paginatorVariable._pageIndex) + event.currentIndex;
      return event.container.data['filteredData'][currentIndex];
    } else {
      return event.container.data['visibleData'].getValue()[event.currentIndex]
    }
  }

  /**
  * <th> on click row sorting
  * @param key
  * @param type
  */
  sorting(key, type) {
    this.sortingColoumnKey = key;
    let sortype;
    if (type == SorTypeEnum.asc) {
      sortype = this.ordertype = SorTypeEnum.desc;
    } else {
      sortype = this.ordertype = SorTypeEnum.asc;
    }
    if (this.dataSource && this._tableContent) {
      this.dataSource.data = this.contentSorting(key, sortype);
      this.sortEmit.emit({
        fromType: FromTypeEnum.sort,
        array: this._tableContent,
        sortColumnkey: key,
        sortype: sortype
      });
    }
  }

  /**
  * content sorting
  * @param key
  * @param sortype
  */
  contentSorting(key, sortype) {
    if (this._tableContent && this._tableContent.length > 0) {
      this._tableContent = _.orderBy(this._tableContent, [(o) => {
        const val = this.getDescendantProp(o, key);
        if (typeof (val) == 'string') {
          return val.toLowerCase();
        } else {
          return val;
        }
      }], sortype);
      if (this._options && this._options.enablePagination) {
        this.paginator.pageIndex = 0;
      }
    }
    return this._tableContent;
  }

  getDescendantProp(obj, key) {
    const arr = key.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  }

  findFirstColoum(): string {
    const headerarray = this._headerOptions && this._headerOptions.filter(x => x.searchable).map(x => x.key);
    return headerarray && headerarray[0];
  }

  /**
   * search filter
   * @param searchText
   */
  SearchFilter(searchText: string): any {
    const headerarray = this._headerOptions && this._headerOptions.filter(x => x.searchable).map(x => x.key);
    if (headerarray && headerarray.length > 0 && this.originaldata) {
      this._tableContent = this.originaldata.filter(result => {
        const headerKey = headerarray;
        for (const key in result) {
          if (typeof (result[key]) == 'string' && result[key].toLowerCase().includes(searchText.toLowerCase())) {
            if (headerKey.indexOf(key) != -1) {
              return result[key].toLowerCase().includes(searchText.toLowerCase());
            }
          } else if (typeof (result[key]) == 'number') {
            if (headerKey.indexOf(key) != -1) {
              const matchedValue = Number(result[key].toString().toLowerCase().includes(searchText.toLowerCase()));
              if (matchedValue) {
                return matchedValue;
              }
            }
          } else if (Array.isArray(result[key])) {
            if (headerKey.indexOf(key) != -1) {
              const matchedArr = result[key].filter(x => x.includes(searchText));
              if (matchedArr.length > 0) {
                return matchedArr;
              }
            }
          }
        }
      });
      const sortedResult = this.contentSorting(this.sortingColoumnKey, this.ordertype);
      if (this.dataSource && this._tableContent) {
        this.dataSource.data = sortedResult;
      }
      // this.totalMsg = this._Localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [this._tableContent.length]);
      this.viewport.checkViewportSize()
    }
    this.SearchChangeEmitter.emit(this._tableContent);

    if (this._tableContent?.length == 0) this.noDataLabel = this.captions?.lbl_noDataFound;
  }

  /**
   * Event on Toggle Change in table tow
   * @param change event
   */
  toggleChange(event, row, key) {
    if (row) {
      row[key].value = event.checked;
      this.EmittedData.emit({
        fromType: FromTypeEnum.switch,
        array: this._tableContent,
        value: event.checked,
        Obj: row,
        toggleKey: key
      });
    }
  }

  emitTableData(eve, data, key, flag) {
    if (flag) {
      return;
    }
    data['isRowInvalid'] = this.isInavalidNumber;
    this.EmittedData.emit({
      fromType: FromTypeEnum.input,
      array: this._tableContent,
      value: eve.target.value,
      Obj: data,
      updatedKey: key,
      isInavalidNumber: this.isInavalidNumber
    });
    console.log(this._tableContent);
  }

  emitTableDataOnInit() {
    this.EmittedData.emit({
      fromType: FromTypeEnum.oninit,
      array: this._tableContent,
      value: '',
      Obj: '',
    });
  }

  /**
  * Event on Header Action Click
  * @param event, Hdr Key
  */
  emitDropdownData(eve, data, key) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.dropdown,
      array: this._tableContent,
      value: eve.target.value,
      Obj: data,
      updatedKey: key
    });
    console.log(this._tableContent);
  }
  /**
  * Event on Header Action Click
  * @param event, Hdr Key
  */

  trackByFn(idx, obj) {
    return idx;
  }

  emitPreviewrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.preview,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  emitHistoryrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.history,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  emitSyncrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.sync,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }
}
