import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ButtonValue, DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization'
import { FailurePopupComponent } from '../import/failure-popup/failure-popup.component';
import { ExportBusiness } from './export.business';
import { UI, API } from 'src/app/common/Models/datautilitydetails-model';
import { ExportDataComponent } from './export-data/export-data.component';
import { Product } from '../../shared/shared/globalsContant';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  providers: [ExportBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ExportComponent implements OnInit {
  exportform: UntypedFormGroup;
  captions;
  moduleOptions: DropdownOptions[];
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent: UI.DataUtilityDetails[] = [];
  data = false;
  exportButton: ButtonValue; 
  moduleName: string;
  isViewOnly: boolean = false;
  isAllow : boolean = false;
  showEmptyOption: boolean;
  constructor(private fb: UntypedFormBuilder
    , private localization: Localization, private business: ExportBusiness
    , private dialog: MatDialog, private utils: CommonUtilities) { }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.setValuesBasedOnProductId();
    this.buildForm();
    this.generateTable();
    this.initializeInputs();
    this.validateBreakPoints();
  }

  buildForm() {
    this.exportform = this.fb.group({
      module: ''
    })   
  }

  
  async initializeInputs() {
    this.exportButton = {
      label: this.captions.btn_export,
      type: 'primary',
      disabledproperty: false
    };
    this.moduleOptions = await this.business.getAllModules();
    this.exportform.get('module').setValue(this.moduleOptions[0].id);
    this.moduleName = this.moduleOptions.find(x => x.id == this.moduleOptions[0].id).value;            
    
    this.loadTableData();
  }

  setValuesBasedOnProductId() {
    let prodID = Number(this.localization.GetPropertyInfo('ProductId'));
    switch (prodID) {
      case Product.SPA:
        this.showEmptyOption = false;
        break;
      case Product.GOLF:
        this.showEmptyOption = false;
        break;
        case Product.SNC:
        this.showEmptyOption = false;
        break;
      default:
        this.showEmptyOption = true;
        break;
    }
  }

  generateTable() {
    this.headerOptions = this.business.getHeaderOptions();
    this.tableOptions = this.business.getTableOptions();
  }

  loadTableData(){
    this.utils.ToggleLoader(true);
    if(this.moduleName && this.moduleName != null ){
      this.business.getAllEntries(this.moduleName, API.DataUtilityType.Export).then(x => {
        this.tableContent = x;
        this.utils.ToggleLoader(false);
      });
    } else{
      this.tableContent = [];
      this.utils.ToggleLoader(false);
    }
  }

  onSyncClick() {
    this.loadTableData();
  }

  onTableAction(e) {
  }

  async openPopup(e, element, key, idx) {
    let errors = await this.business.getDataUtilityErrors(element.id);
    const dialogRef = this.dialog.open(FailurePopupComponent, {
      width: '80%',
      height: '88%',
      panelClass: 'view-account-dialog',
      data:{ 
              errors: errors,
              elementId: element.id.toString(),
              elementFileName: element.fileName ? element.fileName.toString() : "",
              isShow: false
            }
    });
    dialogRef.afterClosed().subscribe(res => {

    });
  } 

  async moduleChange(e) {
    this.moduleName = this.moduleOptions.find(x => x.id == e?.value)?.value;
    this.exportform.markAsPristine();
    //this.checkBoxOptions = await this.business.getCheckBoxList(this.moduleOptions.find(x => x.id == e.value).value);
    //this.checkBoxOptions$ = this.checkBoxOptions;
    //this.enableButton();
    this.loadTableData();
  }
  private async validateBreakPoints()
  {
    await this.business.ValidateBreakPoints();
    this.disableControls();
  }

  private disableControls() {
    this.isViewOnly = this.business.isViewOnly;
    this.isAllow =this.business.isAllow;
    if(this.isAllow)
    {
    this.exportButton.disabledproperty=false;
    }
    else{
      this.exportButton.disabledproperty=true;
    }

  }

  /*dataChange(e) {
    this.data = !e.checked;
    this.enableButton();
  }*/  

  async export() {
    //await this.business.export(this.checkBoxOptions.filter(x => x.checked).map(x => x.value), this.moduleName);
    const dialogRef = this.dialog.open(ExportDataComponent, {
      width: '80%',
      height: '88%',
      data: this.moduleName
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res && res.dataUtilityDetails != null && res.dataUtilityDetails != undefined)
      {
        this.tableContent = res.dataUtilityDetails;
      }
    });
  }
  
  async downloadExcel(e, element, key, idx) {
    await this.business.download(element.id);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';

@Pipe({
    name: 'arrayToCommaSeparatedString'
})
export class arrayToCommaSeparatedStringPipe implements PipeTransform {
    transform(items: any): string {
      let screens = "";
      if(items != null)
      {
        for(let i = 0;i < items.length;i++)
        {
          if(i > 0)
          {
            screens += "," + items[i].screenName;
          }
          else
          {
            screens += items[i].screenName;
          }
        }
      }
      return screens;
    }
}
