import { Injectable } from '@angular/core';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { API ,ExtractList , CustomQueryUI,AdhocReportAPI } from '../event-scheduler.model';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { Distribution, DistributionType, TemplateType } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { NotificationTemplate } from 'src/app/common/templates/template-email/crud-email-template/crud-email-template.model';
import { JobSchedulerDataServices } from 'src/app/common/dataservices/notification/job-scheduler.data.service';
import { Localization } from 'src/app/common/localization/localization';
import { QueryBuilderDataService } from "src/app/common/dataservices/querybuilder.data.service";
import { API as SFTPApi } from 'src/app/common/Models/sftp.model';
@Injectable()
export class EventDetailsBusiness {
  captions: any;
  schedulercaptions :any;
  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private localization: Localization
    , private jobSchedulerDataServices: JobSchedulerDataServices
    , private notificationConfigurationService: NotificationConfigurationService
    , private templateDataServices: TemplateDataServices,
    private _queryBuilderDataService: QueryBuilderDataService) {
    this.captions = this.localization.captions;
    this.schedulercaptions =  this.localization.captions.settings.schedulerJobs;
  }

  async getAllScheduleJob(): Promise<DropdownOptions[]> {
    const scheduleJobs: API.ScheduleJob[] = await this.jobSchedulerDataServices.getAllScheduleJob();
    return scheduleJobs.map(o => {
      return {
        id: o.id,
        value: o.jobName,
        viewValue: o.jobName
      };
    });
  }
  async getAllScheduleJobs(): Promise<API.ScheduleJob[]> {
    const scheduleJobs: API.ScheduleJob[] = await this.jobSchedulerDataServices.getAllScheduleJob();
    return scheduleJobs;
  }
  async getAllReportType(): Promise<DropdownOptions[]> {
    const formats = [
      {
        id: 0,
        value: 0,
        viewValue: "PDF"
     },
    {
        id: 2,
        value: 2,
        viewValue: "EXCEL"
    },
    {
        id: 3,
        value: 3,
        viewValue: "WORD"
    },
    {
        id: 5,
        value: 5,
        viewValue: "CSV"
    },
    {
      id: 6,
      value: 6,
      viewValue: "TEXT"
    },
    {
        id: 8,
        value: 8,
        viewValue: "RAWDATA"
    }
   ];
   return formats;
  }

  async getGenericExtractDownloadType(filter:boolean = false): Promise<DropdownOptions[]> {
    const formats = [
      {
        id: 0,
        value: 0,
        viewValue: "PDF"
     },
    {
        id: 2,
        value: 2,
        viewValue: "EXCEL"
    },
    {
        id: 5,
        value: 5,
        viewValue: "CSV"
    }
   ];
    return formats;
  }

  public async getAllCustomQueryGroups(): Promise<CustomQueryUI.CustomQueryGroup[]> {
    const customQueryGroups = await this._queryBuilderDataService.getAllCustomQueryGroups();
    return customQueryGroups.map(x => this.UICustomQueryGroupMapper(x));
}

UICustomQueryGroupMapper(result): CustomQueryUI.CustomQueryGroup {
  if(result && result.queryListItem.length > 0)
  {
  return {
      groupName: result.groupName,
      queryListItem: result.queryListItem
  } as CustomQueryUI.CustomQueryGroup
}
}

  async getAllScheduleOperations(): Promise<DropdownOptions[]> {
    const scheduleAPIs: API.ScheduleOperationDetail[] = await this.jobSchedulerDataServices.getAllScheduleOperations();
    return scheduleAPIs.map(o => {
      return {
        id: o.id,
        value: o.methodName,
        viewValue: o.methodDescription
      };
    });
  }

  async getDistributionList(): Promise<Distribution[]> {
    return await this.notificationConfigurationService.getAllDistributionByProductId();
  }

  getDistributionListByType(distributionList: Distribution[], predicate: DistributionType): DropdownOptions[] {
    return distributionList
      .filter(o => (o.distributionType & predicate ) == predicate)
      .map(o => {
        return {
          id: o.id,
          value: o.distributionName,
          viewValue: o.distributionName
        };
      });
  }

  // TODO: Demote the API call to single call
  async getGuestEmailTemplateSourceList(): Promise<DropdownOptions[]> {
    const emailTemplateList: NotificationTemplate[] = await this.templateDataServices.GetAllTemplates(TemplateType.Email, false);
    return emailTemplateList.map(o => {
      return {
        id: o.id,
        value: o.templateName,
        viewValue: o.templateName
      };
    });
  }

  async getGuestSMSTemplateSourceList(): Promise<DropdownOptions[]> {
    const smsTemplateList: NotificationTemplate[] = await this.templateDataServices.GetAllTemplates(TemplateType.SMS, false);
    return smsTemplateList.map(o => {
      return {
        id: o.id,
        value: o.templateName,
        viewValue: o.templateName
      };
    });
  }

  async getReportList(report): Promise<DropdownOptions[]> {
    return await new Promise<DropdownOptions[]>((resolve) => {
      return resolve(
        Object.keys(report).filter(key => !isNaN(Number(key))).map((o, index) => {
          const reportOptions: DropdownOptions = {
            id: index + 1,
            value: report[o],
            viewValue: report[o]
          };
          return reportOptions;
        }));
    });
  }

  async getAllScheduleJobWildCard(): Promise<DropdownOptions[]> {
    const scheduleJobWildCard: API.ScheduleJobWildCard[] = await this.jobSchedulerDataServices.getAllScheduleJobWildCard();
    return this.scheduleJobWildCardMapper(scheduleJobWildCard);
  }

  scheduleJobWildCardMapper(scheduleJobWildCards: API.ScheduleJobWildCard[]){
    return scheduleJobWildCards.map(o => {
      return {
        id: o.id,
        value: o.wildCard,
        viewValue: this.schedulercaptions[o.wildCard]
      };
    });
  }


  async getadhocReportType(): Promise<DropdownOptions[]> {
    const AdhocViewReportDetails : AdhocReportAPI.AdhocViewReportPathDeatils[] = await this._queryBuilderDataService.getAllAdhocViewReports();
    return this.SetAdhocViewReportMapper(AdhocViewReportDetails);
  }

  SetAdhocViewReportMapper(AdhocViewReportDetails: AdhocReportAPI.AdhocViewReportPathDeatils[]){
    return AdhocViewReportDetails.map((o, index) => {
      return {
        id: index,
        value: o.uri,
        viewValue: o.label
      };
    });

  }

  async getAllSFTPConnectionSetup(): Promise<DropdownOptions[]> {
    const SFTPConnectionSetupDetails : SFTPApi.SFTPConnection[] = await this.jobSchedulerDataServices.getSFTPConfiguration();
    return this.SetASFTPConnectionSetupMapper(SFTPConnectionSetupDetails);
  }
  SetASFTPConnectionSetupMapper(SFTPConnectionSetupDetails: SFTPApi.SFTPConnection[]){
    return SFTPConnectionSetupDetails.map((o, index) => {
      return {
        id: o.id,
        value: o.hostName,
        viewValue: o.connectionName
      };
    });

  }


}
