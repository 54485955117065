import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreRoutingModule } from './core-routing.module';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/auth-guard.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NotificationsComponent } from './notifications/notifications.component';
import { LayoutsModule } from '../layout/layout.module';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { ConfirmPasswordComponent } from './login/confirm-password/confirm-password.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ScrollbarModule } from 'ngx-scrollbar';
import { RouterModule } from '@angular/router';
import { SettingModule } from '../settings/setting.module';
import { SessionManagerService } from './services/session-manager.service';
import { UserMachineConfigurationService } from './services/user-machine-configuration.service';
import { RouteLoaderService } from './services/route-loader.service';
import { RouteGuardService } from './services/route-gaurd.service';
import { MaterialModule } from '../common/material-module';
import { BrowserModule } from '@angular/platform-browser';
import { CanDeactivateGuardService } from 'src/app/core/services/can-component-deactivate.service';
import { RestrictPortraitComponent } from './restrict-portrait/restrict-portrait.component';
import { HttpCacheService } from '../common/services/cache/http-cache.service';
import { CacheInterceptor } from '../common/services/cache/cache-interceptor';
import { ErrorInterceptor } from '@coreModels/interceptors/error.interceptor';
import { RedirectRouteGuard } from '../common/services/redirect-route.guard.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        ScrollbarModule,
        CoreRoutingModule,
        LayoutsModule,
        SettingModule,
        BrowserModule
    ],
    declarations: [LoginComponent, NotFoundComponent, NotificationsComponent, LoginPopupComponent, ConfirmPasswordComponent, RestrictPortraitComponent],
    exports: [
        LayoutsModule,
        RouterModule
    ],
    providers: [
        AuthenticationService,
        AuthGuardService,
        DatePipe,
        SessionManagerService,
        UserMachineConfigurationService,
        RouteLoaderService,
        RouteGuardService,
        CanDeactivateGuardService,
        HttpCacheService, 
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },  
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        RedirectRouteGuard
    ]
})
export class CoreModule { }
