import { Component, OnInit,EventEmitter,Output, ViewEncapsulation, } from '@angular/core';

@Component({
  selector: 'app-util-staff-reader-board',
  templateUrl: './util-staff-reader-board.component.html',
  styleUrls: ['./util-staff-reader-board.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UtilStaffReaderBoardComponent implements OnInit {

  constructor()
  {

  }

  ngOnInit(): void {
  }
}
