import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatter'
})
export class FormatterPipe implements PipeTransform {

  transform(value: any, args: any): unknown {
    let returnValue = value;
    if (args.hasOwnProperty('Formatter')) {
      if (args.Formatter === 'creditcardformatter') {
        const textlength = value.length - 4;
        returnValue = value.slice(0, textlength).replace(/\d/g, 'x') + value.slice(-4);
      }
    }
    return returnValue;
  }

}
