import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import _ from 'lodash';
import { ZeroQty } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { Validation } from 'src/app/eatecui/source/shared/utilities/data.validator';
export const ZeroQtyButcheryTransaction: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType ?: any,
    ModuleKey ?: string,
    dialog ?: MatDialog,
    transactionCollection ?: TransactionConfiguration,
    homeGroup ?: FormGroup
): any => {
    try {
        return new Promise<boolean>((resolve, reject) => {
        if ( GlobalPreference && GlobalPreference.length > 0 && homeGroup) {
            const receivingZeroQtyGlobalPreference = GlobalPreference.filter(x => x.LookupName === PrferenceType);
                const prZeroQty =  receivingZeroQtyGlobalPreference[0];
                   /**
                    * @remarks Added Maually
                    */
                   transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(x => {
                            if ( x && x.Remarks ) {
                                x.Remark = x.Remarks;
                            } 
                   });
                   // As discussed api will send only butchery receip in lookup 
                   // so commented && x.ProdItemId && x.ProdItemName && x.RawItemName
                    const filteredGridData: boolean = transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList
                        .ItemGridConfig.GridData.every(x => x.Qty && x.Qty !== 0 && Math.floor(x.Qty) !== 0);
                        if ( filteredGridData ) {
                        const NegativeQuantityc: Array<any> = transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList
                        .ItemGridConfig.GridData.filter(x => x.Qty && x.Qty < 0 );
                        if ( NegativeQuantityc && NegativeQuantityc.length > 0 ) {
                            const NegativeQuantity: boolean = NegativeQuantityc.some(x => !x.Remarks );
                            if ( NegativeQuantity ) {
                            const receivingZeroQuantity = {} as ErrorPopModel;
                            receivingZeroQuantity.PopupHeaderContent = 'Info';
                            receivingZeroQuantity.PopUpContent = 
                            'One or more FG items with negative quantity should be filled with Remarks';
                            dialog.open(AgilsysErrorPopComponent, {
                                width: '400px',
                                data: receivingZeroQuantity,
                                disableClose: true
                              });
                            reject(false);
                            } else {
                                resolve(true);
                            }
                         } else {
                            resolve(true);
                         }
                        } else {
                            const receivingZeroQuantity = {} as ErrorPopModel;
                            receivingZeroQuantity.PopupHeaderContent = 'Info';
                            // receivingZeroQuantity.PopUpContent = 'One or More FG Items does not map with RawItem, RecipeUsed and Zero Quantity';
                            receivingZeroQuantity.PopUpContent = 'One or more FG items found with ZERO Quantity. Provide valid non-zero quantity & try again';
                            dialog.open(AgilsysErrorPopComponent, {
                                width: '400px',
                                data: receivingZeroQuantity,
                                disableClose: true
                              });
                            reject(false);
                        }
        }
        });
    } catch (error) {
        console.error(error);
    }
};