export interface DashboardConfigDetails{
    id: number,
    value: string,
    viewValue: string,
    isDefaultDashboard: boolean

}

export interface CustomizableDashboardDetails{
    userId: number;
    propertyId: number;
    productId: number;
    dashboardName: string;
    customType: number;
    dashboardURL: string;
    dashboardId?: string;
    userRoleId?: number;
}

export enum CustomType{
    Dashboard1 = 1,
    Dashboard2,
    Dashboard3
}