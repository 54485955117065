import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, HeaderConfiguration, TilesConfiguration, TitleType, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridTittleConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): TitleType => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                const titleType = {} as TitleType;
                if (TransactionCollection.ViewTranscationConfig.GridConfiguration.GridTitleType) {
                    titleType.Type = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridTitleType.Type;
                    titleType.TypeConfig = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridTitleType.TypeConfig;
                    return titleType; 
                }
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };