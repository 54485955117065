import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

export interface Transactions {
   GetTransactionData: TransactionConfiguration;
   EnableTrasactionState: boolean;
   GetInventoryManagementData: MasterInventoryManagement;
   EnableManagementState: boolean;
}

export const intialTransactionState: Transactions = {
    GetTransactionData: null,
    EnableTrasactionState: null,
    GetInventoryManagementData: null,
    EnableManagementState: null,
};

