import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomDueDateSet: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: TransactionConfiguration
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            const Id = FormGroupData.value[FieldGrouping.CustomFormAction.CutomFieldFrom];
            if (Id) {
                const EndPointUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}?$filter=IsActive eq true and Id eq ${Id}`;
                httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                    console.log('Supplier based po filter', res);
                    if (res['value'].length > 0) {
                        const DueDays = res['value'][0].DueDays;
                        const date = (MasterDataCollection.Name === 'MiscInvoiceA/P' || MasterDataCollection.Name === 'MiscInvoiceA/R'
                        || MasterDataCollection.Name === 'Receiving') && FormGroupData.controls.PostDate.value
                            ? new Date(FormGroupData.controls.PostDate.value) : new Date();
                        date.setDate(date.getDate() + DueDays);
                        FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(date);
                    }
                });
            }
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};