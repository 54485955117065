import { FormGroup } from '@angular/forms';
import { TransactionConfiguration } from './transaction.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from '../services/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { DeliveryCutoffCheck } from './create-transcation/create-transaction-config.interface';
import { HttpMethods } from '../enum/transaction-enum';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import moment from 'moment';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { DefaultIniDateFormat } from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const DeliveryDateCutOffCheck: DeliveryCutoffCheck  = (
  TransactionCollection: TransactionConfiguration,
  httpService: HttpService,
  transactionService: TransactionService,
  router: any,
  postModelHederEntity: any,
  postModelDetailEntity: any,
  homeGroup: FormGroup,
  GlobalOdataModel: any,
  toastrService: ToastrService,
  routerNavigateService: RouteNavigateService,
  selectedTransaction: any,
  dialog: MatDialog,
  buttonCallBackValue: any,
  selectedId?: any,
  selectedSeq?: any,
  analysisRedirect?: any,
  translateService?: TranslateService,
  configService?: ConfigService,
  integrationService?: IntegrationService
): any => {
    return new Promise(resolve => {
        try {
            let returnValue = true;
            if (selectedTransaction === 'requisition' || selectedTransaction === 'openpurchaserequest' || selectedTransaction === 'allpurchaserequest') {
              const postObject = transactionService.constractTransactionHeaderPostModel(
                  postModelHederEntity, postModelDetailEntity, TransactionCollection, homeGroup, HttpMethods.Post, GlobalOdataModel);
              const deliveryCheckURL = CONSTANT.ENDPOINTURL['DeliveryRulesCutOffCheck']['CheckDeliveryCutOff'];
              const url = selectedTransaction === 'requisition' ? deliveryCheckURL + `/ReqHead` : deliveryCheckURL + `/PurchaseReqHead` ;
              httpService.PosthttpMethod(url, postObject).subscribe(res => {
               if (res && res.length > 0) {
                    const currentTimeDate = moment(res[0]['PostDate']).format('MM/DD/YYYY');
                    const IniDateFormat = StorageService.GetSessionStorage('IniDateFormat');
                    const dateFormat = IniDateFormat ? IniDateFormat : DefaultIniDateFormat;                    
                    const dateTime = new Date(`${currentTimeDate} ${res[0].OrderTime} UTC`);
                    const momentDateTime = moment(dateTime).format('hh:mm a');
                    const formattedDate = moment(res[0]['PostDate']).format(dateFormat);
                    const dateandTime = formattedDate + ' ' + momentDateTime;
                    const message = res[0].Message.replace('({orderDateTime})', dateandTime);
                    const confirmPopModel = {} as ConfirmPopModel;
                    confirmPopModel.PopUpContent = '';
                    confirmPopModel.InnerHtmlContent = message;
                    confirmPopModel.PopupHeaderContent = translateService.instant('DeliveryRules.DeliveryRules');
                    let dialogRefs = dialog.open(AgilysysConfirmationPopupComponent, {
                        width: '525px',
                        height: '235px',
                        data: confirmPopModel,
                        autoFocus: false,
                        disableClose: true
                      });
                    dialogRefs.afterClosed().subscribe(result => {
                      if (result === 'Confirm') {
                        homeGroup.controls['PriorityId'].setValue(2);
                        homeGroup.controls['IsRush'].setValue(true);
                        resolve(true);    
                      } else {
                        returnValue = false;
                        resolve(false);    
                      }
                    });
               } else {
                resolve(true);
               } 
              });
            } else {
                resolve(returnValue);
            }            
        } catch (error) {
            console.log(error);
        }        
    });
}