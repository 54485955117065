import { TranslateService } from '@ngx-translate/core';
import { ColumnFieldType, FieldDataType } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FieldEdmType } from '../../../../../setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import { TransactionConfiguration } from '../../transaction.interface';
import { ViewGridHeaderConfiguration } from './grid-header-config.interface';

export const ViewGridHeaderFieldType: ViewGridHeaderConfiguration =
    (ViewHeaderEntity: any,
        ViewDetailsEntity: any,
        TransactionCollection: TransactionConfiguration,
        httpService: HttpService,
        HeaderObject: any,
        translateService?: TranslateService, TilesHeaderTrascationEntity?: any): any => {
        try {
            if (TransactionCollection && TransactionCollection.ViewTranscationConfig.GridConfiguration) {
                return getColumnType(HeaderObject, TransactionCollection, ViewHeaderEntity);
            }
        } catch (error) {
            console.error('Error occurred in Setdefaultsort', error);
        }
    };

/**
* @method getColumnType()
* @param type name;
* @description Assign form type
*/
function getColumnType(EntityProperty: any, TransactionCollection: TransactionConfiguration, ViewHeaderEntity: any): string {
    try {
        let columnType = '';
        switch (EntityProperty['$']['Type']) {
            case FieldEdmType.EDMSTRING:
                columnType = FieldDataType.STRING;
                break;
            case FieldEdmType.EDMINT32:
            case FieldEdmType.EDMINT16:
                columnType = checkNavigationProperity(EntityProperty, ViewHeaderEntity);
                break;
            case FieldEdmType.EDMDATEOFFSET:
                columnType = FieldDataType.DATECOLUMN;
                break;
            case FieldEdmType.EDMINTDECIMAL:
            case FieldEdmType.EDMDOUBLE:
            case FieldEdmType.EDMINT64:
            case FieldEdmType.EDMFLOAT:  
                columnType = FieldDataType.NUMERIC;
                break;
            default:
                columnType = FieldDataType.STRING;
        }
        return columnType;

    } catch (error) {
        console.log('error occured', error);
    }
}

/**
 * @functiodn checkNavigationProperity()
 * @prams odataEntity, odataobject
 * @description check navigation properoity
 */

function checkNavigationProperity(OdataObject: any, OdataViewEntityModel: any): string {
    try {
        let fieldType = FieldDataType.NUMERIC;
        if (OdataViewEntityModel && OdataViewEntityModel.length > 0 && OdataViewEntityModel[0].hasOwnProperty('NavigationProperty')) {
            const getNavigationType = OdataViewEntityModel[0]['NavigationProperty'].
                filter(x => x['ReferentialConstraint'] && x['ReferentialConstraint'][0]['$']['Property'] === OdataObject['$']['Name']);
            if (getNavigationType && getNavigationType.length > 0) {
                fieldType = FieldDataType.STRING;
            }
        }
        return fieldType;
    } catch (error) {
        console.error(error);
    }
}

