import { HttpServiceCall, HttpMethod } from "src/app/retail/shared/service/http-call.service";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { Host } from "src/app/retail/shared/globalsContant";
import { Injectable } from "@angular/core";
import { BaseResponse } from "src/app/retail/shared/business/shared.modals";
import { VATConfiguration, Tax } from "../../retail.modals";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
 import {API } from './vat-configuration.model';
import { AdditionalVATConfiguration } from "./vat-configuration.model";

@Injectable()
export class VatDataService {

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization, private propertyInfo: RetailPropertyInformation) { }
    
    private async invokeServiceCall<T>(hostName: Host, callDesc: string, methodType: HttpMethod, uriParams?: any, body?: any, extraParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            host: hostName,
            callDesc: callDesc,
            body: body,
            uriParams: uriParams,
            method: methodType
        });
        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

    public async GetDepartmentAndPostTypes(IncludeInActive: boolean, excludeHiddenDepartments: boolean = false): Promise<{ departments: API.Departments[], postTypes:API.PostType[] }> {
        try {
            let uriParams = { IncludeInActive: IncludeInActive, excludeHiddenDepartments: excludeHiddenDepartments };
          return await this.invokeServiceCall<{ departments: API.Departments[], postTypes: API.PostType[] }>(Host.folio, "FolioService/department-posttype?includeInactive={includeInactive}&excludeHiddenDepartments={excludeHiddenDepartments}", HttpMethod.Get, uriParams);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetVatConfigurationInfo(propertyId: number): Promise<VATConfiguration> {
        try {
            let uriParams = { propertyId: propertyId };
            return await this.invokeServiceCall<VATConfiguration>(Host.authentication, "GetVatConfiguration", HttpMethod.Get, uriParams);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async UpdateVatConfigurationInfo(propertyVatConfiguration) {
        try {
            return await this.invokeServiceCall<boolean>(Host.authentication, "UpdateVatConfiguration", HttpMethod.Put, "", propertyVatConfiguration);
        } catch (e) {
            this.http.exceptionHandle(e);
      }
    }

    public async getAllActiveVATs(): Promise<Tax[]> {
        let result: Tax[] = await this.invokeServiceCall<Tax[]>(Host.retailManagement, "GetTaxesV2", HttpMethod.Get);
        result = result || [];

        if (result.length > 0) {
            result = result.filter(
                x =>  x.taxMaintenances.filter(
                    r => this.utils.getDate(r.startDate).getTime() <= this.propertyInfo.CurrentDate.getTime() && this.utils.getDate(r.endDate).getTime() >= this.propertyInfo.CurrentDate.getTime()
                    ).length > 0
                );
        }

        return result;
    }

    public async GetAdditionalVatConfigurationInfo(): Promise<AdditionalVATConfiguration[]> {
        try {
            return await this.invokeServiceCall<AdditionalVATConfiguration[]>(Host.retailManagement, "RetailManagementService/AdditionalVATConfiguration", HttpMethod.Get);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async UpdateAdditionalVatConfigurationInfo(AdditionalVatConfiguration) {
        try {
            let data : any [] =[];
            data.push(AdditionalVatConfiguration);
            return await this.invokeServiceCall<boolean>(Host.retailManagement, "RetailManagementService/AdditionalVATConfiguration/CreateOrUpdateAdditionalVATConfigurations", HttpMethod.Put, "", data);
        } catch (e) {
            this.http.exceptionHandle(e);
      }
    }
}
