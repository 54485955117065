import { AfterViewInit, Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';
import { JWT_TOKEN, PAYMENT_CONFIGURATION, PROPERTY_DATE, PROPERTY_INFO, REMEMBER_INFO, USERS_SESSSIONS_INFO, USER_INFO, USER_SESSION, USER_SETTINGS, USE_RETAIL_INTERFACE, VAT_ENABLED } from '../../app-constants';


@Directive({
  selector: "[ag_rightclick]" 
})
export class RightclickNewtabDirective {
  @Input() newtab: any;


constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
@HostListener('contextmenu', ['$event']) contectmenu(event) {
  sessionStorage.setItem('isnewtab','true');
  for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i); 
      const value = sessionStorage.getItem(key); 
      localStorage.setItem(key, value);
  }

}
}