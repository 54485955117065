import { Component, Input, ViewEncapsulation, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageData } from '../../models/ag-models';
import { isEmpty } from 'lodash';

@Component({
  selector: 'ag-spa-img-thumbnail',
  templateUrl: './img-thumbnail.component.html',
  styleUrls: ['./img-thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImgThumbnailComponent implements OnChanges {

  @Input() imageContent: ImageData;
  @Input() thumbnail: boolean;
  @Input() imageType: string;
  url: any;
  constructor(private domSanitizer: DomSanitizer) { }

  ngOnChanges() {
    if (this.imageContent && !isEmpty(this.imageContent) && this.imageContent.contentType) {
      const imageUrl = `data:${this.imageContent.contentType};base64,${this.imageContent.thumbnailData}`;
      this.url = imageUrl;
    } else {
      if (this.imageType === 'therapist') {
        this.url = 'assets/images/therapist.png';
      } else if (this.imageType === 'client') {
        this.url = 'assets/images/client.png';
      } else if (this.imageType === 'user') {
        this.url = 'assets/images/user.png';
      } else if (this.imageType === 'retailItem') {
        this.url = 'assets/images/shop/emptyshop.jpg';
      }
    }
  }

}
