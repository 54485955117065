import { Injectable } from '@angular/core';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { SpaUtilities } from '../../utilities/spa-utilities';
import { appointmentService } from '../../service/appointment.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import { CalendarGridType, CalendarGridHeader, CalendarGridBodyProps, PropertyOperatingTime, GridViewType, CalendarGridBodyData, CalendarGridBody, Timing } from '../calendar-grid-models/calendar-grid.models';
import { ReplaySubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Injectable()
export class CalendarGridService {
  public agsysResetHeaderFlag: boolean = false;
  public CalendarGridType: CalendarGridType;
  public EnableToolTip: boolean;
  public allowBookingAfterSpaClosing: any;
  public GridHeaderKeyForLocation: string[] = ['description'];
  public GridHeaderKeyForTherapist: string[] = ['firstName', 'lastName'];
  public GridHeaderKeyForAliasTherapist: string[] = ['alsoKnownAs'];
  public GridHeaderKeyForAllDay: string[] = ['row1', '|', 'row2'];
  public ondraggingEnabled: boolean = false;

  public gridGHeaderArray: any = [1, 2, 3, 4, 5, 6, 7];
  public gridSideContentArray: any = ["09:00 am", "09:30 am", "10:00 am", "10:30 am", "11:00 am", "11:30 am", "12:00 pm", "12:30 pm", "01:00 pm", "01:30 pm", "02:00 pm", "02:30 pm", "03:00 pm", "03:30 pm", "04:00 pm", "04:30 pm", "05:00 pm", "05:30 pm", "06:00 pm", "06:30 pm", "07:00 pm", "07:30 pm", "08:00 pm", "08:30 pm", "09:00 pm", "09:30 pm", "10:00 pm", "10:30 pm", "11:00 pm"]
  private openStatusClass: string = '';
  private closedNoSchedule: string = '';
  onCallStatusClass: string = '';
  propertyEndDatetime: Date;
  propertyStartDatetime: Date;

  public _reloadGrid$: ReplaySubject<any> = new ReplaySubject();

  constructor(private localization: SpaLocalization, private utils: SpaUtilities,
    public _appointmentService: appointmentService, private PropertyInfo: SpaPropertyInformation,private datePipe: DatePipe) { }


  /**
   * Add custom properties to Grid content
   * @param Contentdata - Calendar grid body props
   * @param allDayCheckBox all day flag
   * @param selectedDate selected date
   * @param PropertyOperateTime - property operating time
   * @param timeInterval grid time interval
   */
  public CustomFieldInsertedIntoContentData(Contentdata: CalendarGridBodyProps[], allDayCheckBox: boolean, selectedDate: Date, PropertyOperateTime: PropertyOperatingTime, timeInterval: number, serviceArr?, locationArr?, therapistArr?, managementDataSet?, weekView?): CalendarGridBodyProps[] {

    const setUpColor = this.utils.getLegendColor(this._appointmentService, 'SETUP');
    const breakDownColor = this.utils.getLegendColor(this._appointmentService, 'BREAKDOWN');
    const currentUserSessionId = this.utils.GetCurrentUserSessionId();
    this.setOpenCloseStatusClass();
    this.propertyStartDatetime = this.localization.AddTimeToDate(selectedDate, this.localization.TimeToDate(PropertyOperateTime.StartTime));
    this.propertyEndDatetime = this.localization.AddTimeToDate(selectedDate, this.localization.TimeToDate(PropertyOperateTime.EndTime));
    Contentdata.forEach(cd => {

      // Setting locked appointments as Temp so that actions can not be performed on locked appointments
      if (cd.appointmentDetail.userSessionId && cd.appointmentDetail.userSessionId != currentUserSessionId) {
        cd.appointmentDetail.status = 'TEMP';
      }

      cd.startTimewithsetuptime = (cd.appointmentDetail.setUpTime && cd.appointmentDetail.setUpTime != 0) ? this.localization.SubMins(cd.appointmentDetail.startTimeObj, cd.appointmentDetail.setUpTime) : cd.appointmentDetail.startTimeObj;
      cd.endTimewithbreakdowntime = (cd.appointmentDetail.breakDownTime && cd.appointmentDetail.breakDownTime != 0) ? this.localization.AddMins(cd.appointmentDetail.endTimeObj, cd.appointmentDetail.breakDownTime) : cd.appointmentDetail.endTimeObj;

      if (!allDayCheckBox && !weekView) {
        this.checkExceedingTimelimit(cd, timeInterval);
      }
      cd.appointmentDetail.originalStartTime = this.localization.ConvertDateToISODateTime(cd.appointmentDetail.startTime);
      cd.appointmentDetail.originalEndTime = this.localization.ConvertDateToISODateTime(cd.appointmentDetail.endTime);
      cd.setCss = true;
      cd.statusClass = cd.appointmentDetail ? this.utils.getstatusColour(this._appointmentService, cd.appointmentDetail, cd.breakTypeDetail) : '';
      cd.setupColorClass = setUpColor;
      cd.breakdownColorClass = breakDownColor;
      cd.cellPTDClass = '';

      cd.serviceName = serviceArr ? this.getServiceName(cd.appointmentDetail.serviceId, serviceArr) : '';
      cd.locationName = locationArr ? this.getLocationName(cd.appointmentDetail.locationId, locationArr) : '';
      cd.therapistName = therapistArr ? this.getTherapistName(cd.appointmentTherapists, therapistArr) : '';
      cd.appointmentAddOns = managementDataSet ? this.getAppointmentAddOns(cd.appointmentAddOn, managementDataSet) : '';
    });
    return Contentdata;
  }

  public setOpenCloseStatusClass() {
    this.openStatusClass = this.utils.getLegendColor(this._appointmentService, 'OPEN');
    this.closedNoSchedule = `${this.utils.getLegendColor(this._appointmentService, 'CLOSED')} noschedule`;
    this.onCallStatusClass = `${this.utils.getLegendColor(this._appointmentService, 'ONCALL')} therapist-oncall`;
  }

  /**
   * This method forms Over lap details of appointment grid
   * @param header - Location | Therapist | AllDay header
   * @param isAllDay - all day boolean
   * @param gridViewType - grid view type. TV/LV
   * @param gridBodyProps - grid body params
   */
  public HeaderOverLapFormation(header: any, isAllDay: boolean, gridViewType: GridViewType, gridBodyProps: CalendarGridBodyProps[]): CalendarGridBodyProps[] {
    let gridBodyPropsTemp: CalendarGridBodyProps[] = [];
    let check_allDayCheckBox: boolean = isAllDay;
    let check_viewTypeSelected: GridViewType = gridViewType;
    let gridContentdata: CalendarGridBodyProps[] = gridBodyProps;
    let that = this;
    if (check_allDayCheckBox) {
      if (check_viewTypeSelected == GridViewType.TherapistView || check_viewTypeSelected == GridViewType.AvailableTherapistView) {
        gridBodyPropsTemp = gridContentdata.filter(gcd => {
          return gcd.appointmentTherapists[0] && gcd.appointmentTherapists[0].therapistId == header.id && gcd.appointmentDetail.status!='BREAK'
        });
      }
      else {
        gridBodyPropsTemp = gridContentdata.filter(gcd => { return gcd.appointmentDetail.locationId == header.id });
      }
      if (gridBodyPropsTemp.length > 0) {
        gridBodyPropsTemp.forEach((GCA, i) => {
          let GCA_Strttime = GCA.startTimewithsetuptime;
          GCA.setCss = true;
          GCA.overLapped = 0;
          GCA.overlappedFlag = false;
          GCA.multiGroupOverlap = 0;

          let dumcount = 0;
          let OLdumcount = 0;

          for (let x = 0; x < i; x++) {
            let EachStrttime = gridBodyPropsTemp[x].startTimewithsetuptime;
            let Eachendtime = gridBodyPropsTemp[x].endTimewithbreakdowntime;

            if ((GCA_Strttime.getTime() >= EachStrttime.getTime()) && (GCA_Strttime.getTime() < Eachendtime.getTime())) {
              gridBodyPropsTemp[x].overlappedFlag = true;
              if (gridBodyPropsTemp[x].appointmentDetail.multiGroupId && (gridBodyPropsTemp[x].appointmentDetail.multiGroupId == GCA.appointmentDetail.multiGroupId)) {
                OLdumcount = 1;
              } else {
                OLdumcount = 0;
              }
              dumcount = gridBodyPropsTemp[x].overLapped + 1;
              OLdumcount = gridBodyPropsTemp[x].multiGroupOverlap + OLdumcount;
            }
          }
          GCA.multiGroupOverlap = OLdumcount;
          GCA.overLapped = dumcount;
        });
      }
    } else  // {
      //   gridBodyPropsTemp = gridContentdata.filter(gcd => {
  
      //     if (!header.headerObject.row3)
      //       return true;
  
      //     let hdrDate = that.utils.getDate(header.headerObject.row3);
      //     let apptime = this.utils.GetDateWithoutTime(gcd.appointmentDetail.startTime);
      //     return hdrDate.getTime() == apptime.getTime();
      //   });
      // }
      {
        if (check_viewTypeSelected == GridViewType.TherapistView || check_viewTypeSelected == GridViewType.AvailableTherapistView) {
          gridBodyPropsTemp = gridContentdata.filter(gcd => {
            return gcd.appointmentTherapists[0] && gcd.appointmentTherapists[0].therapistId == header.id
          });
        }
        else {
          gridBodyPropsTemp = gridContentdata.filter(gcd => { return gcd.appointmentDetail.locationId == header.id });
        }
        if (gridBodyPropsTemp.length > 0) {
          gridBodyPropsTemp.forEach((GCA, i) => {
            let GCA_Strttime = GCA.startTimewithsetuptime;
            GCA.setCss = true;
            GCA.overLapped = 0;
            GCA.overlappedFlag = false;
            GCA.multiGroupOverlap = 0;
  
            let dumcount = 0;
            let OLdumcount = 0;
  
            for (let x = 0; x < i; x++) {
              let EachStrttime = gridBodyPropsTemp[x].startTimewithsetuptime;
              let Eachendtime = gridBodyPropsTemp[x].endTimewithbreakdowntime;
  
              if ((GCA_Strttime.getTime() >= EachStrttime.getTime()) && (GCA_Strttime.getTime() < Eachendtime.getTime())) {
                gridBodyPropsTemp[x].overlappedFlag = true;
                if (gridBodyPropsTemp[x].appointmentDetail.multiGroupId && (gridBodyPropsTemp[x].appointmentDetail.multiGroupId == GCA.appointmentDetail.multiGroupId)) {
                  OLdumcount = 1;
                } else {
                  OLdumcount = 0;
                }
                dumcount = gridBodyPropsTemp[x].overLapped + 1;
                OLdumcount = gridBodyPropsTemp[x].multiGroupOverlap + OLdumcount;
              }
            }
            GCA.multiGroupOverlap = OLdumcount;
            GCA.overLapped = dumcount;
          });
        }
      }
    return gridBodyPropsTemp;
  }

  public WeekHeaderOverLapFormation(header: any, isAllDay: boolean, gridViewType: GridViewType, gridBodyProps: CalendarGridBodyProps[],Tid: any) {
    let gridBodyPropsTemp: CalendarGridBodyProps[] = [];
    let gridContentdata: CalendarGridBodyProps[] = gridBodyProps;
    let check_viewTypeSelected: GridViewType = gridViewType;
    if (check_viewTypeSelected == GridViewType.TherapistView || check_viewTypeSelected == GridViewType.AvailableTherapistView) {
      gridBodyPropsTemp = gridContentdata.filter(gcd => {
        return gcd.appointmentDetail && gcd.appointmentTherapists[0] && gcd.appointmentTherapists[0].therapistId == Tid && this.localization.localizedDate(gcd.appointmentDetail.startTimeObj) == this.localization.localizedDate(header.row4) 
      });
    }
    else {
      gridBodyPropsTemp = gridContentdata.filter(gcd => { return gcd.appointmentDetail.locationId == Tid && this.localization.localizedDate(gcd.appointmentDetail.startTimeObj) == this.localization.localizedDate(header.row4) });
    }
    if (gridBodyPropsTemp.length > 0) {
      gridBodyPropsTemp.forEach((GCA, i) => {
        let GCA_Strttime = GCA.startTimewithsetuptime;
        GCA.setCss = true;
        GCA.overLapped = 0;
        GCA.overlappedFlag = false;
        GCA.multiGroupOverlap = 0;

        let dumcount = 0;
        let OLdumcount = 0;

        for (let x = 0; x < i; x++) {
          let EachStrttime = gridBodyPropsTemp[x].startTimewithsetuptime;
          let Eachendtime = gridBodyPropsTemp[x].endTimewithbreakdowntime;

          if ((GCA_Strttime.getTime() >= EachStrttime.getTime()) && (GCA_Strttime.getTime() < Eachendtime.getTime())) {
            gridBodyPropsTemp[x].overlappedFlag = true;
            if (gridBodyPropsTemp[x].appointmentDetail.multiGroupId && (gridBodyPropsTemp[x].appointmentDetail.multiGroupId == GCA.appointmentDetail.multiGroupId)) {
              OLdumcount = 1;
            } else {
              OLdumcount = 0;
            }
            dumcount = gridBodyPropsTemp[x].overLapped + 1;
            OLdumcount = gridBodyPropsTemp[x].multiGroupOverlap + OLdumcount;
          }
        }
        GCA.multiGroupOverlap = OLdumcount;
        GCA.overLapped = dumcount;
      });
    }
    return gridBodyPropsTemp;
  }

  /**
   *
   * @param tempRowData
   * @param hdrArr
   * @param timing
   * @param eachHdrappointments
   * @param rowIndex
   * @param allDayCheckBox
   * @param viewTypeSelected
   * @param selectedDate
   * @param gridTimeInerval
   * @param PropertyOperateTime
   * @param CurrentDateSchedule
   */
  // tempRowDataformation(tempRowData: CalendarGridBodyData[], hdrArr: CalendarGridHeader<any>[], timing: string, eachHdrappointments: Array<CalendarGridBodyProps[]>,
  //   rowIndex: number, allDayCheckBox: boolean, viewTypeSelected: GridViewType, selectedDate: Date, gridTimeInerval: number, PropertyOperateTime: PropertyOperatingTime,
  //   CurrentDateSchedule: any, selectedClient?: any, appointmentList?: any, CurrentSelectedAppointment?: any): CalendarGridBodyData[] {
  //   let TRD_gridtimeinterval = gridTimeInerval;
  //   let checkalldaySelected = allDayCheckBox;
  //   let viewtypeselected: GridViewType = viewTypeSelected;

  //   let dateTime = this.localization.AddTimeToDate(this.utils.getDate(selectedDate), this.localization.TimeToDate(timing));
  //   const propertyOperateEndTime = this.localization.AddTimeToDate(selectedDate, this.localization.TimeToDate(PropertyOperateTime.endTime)).getTime();
  //   tempRowData = hdrArr.map((hdr, headerIndex) => {
  //    return this.dataFormation(eachHdrappointments, hdr, headerIndex, dateTime, rowIndex, checkalldaySelected, viewtypeselected, TRD_gridtimeinterval, selectedDate, propertyOperateEndTime, CurrentDateSchedule, selectedClient, appointmentList, CurrentSelectedAppointment);
  //   });
  //   return tempRowData;
  // }

  rowDataFormation(hdrArr: CalendarGridHeader<any>[], timing: Timing, eachHdrappointments: Array<CalendarGridBodyProps[]>,
    rowIndex: number, allDayCheckBox: boolean, viewTypeSelected: GridViewType, selectedDate: Date, gridTimeInerval: number,
    CurrentDateSchedule: any, selectedClient?: any, appointmentList?: any, CurrentSelectedAppointment?: any): CalendarGridBodyData[] {
    let TRD_gridtimeinterval = gridTimeInerval;
    let checkalldaySelected = allDayCheckBox;
    let viewtypeselected: GridViewType = viewTypeSelected;
    let dateTime = timing.slotTime;
    return hdrArr.map((hdr, headerIndex) => {
      let result = this.dataFormation(eachHdrappointments, hdr, headerIndex, dateTime, rowIndex, checkalldaySelected, viewtypeselected, TRD_gridtimeinterval, selectedDate, CurrentDateSchedule, selectedClient, appointmentList, CurrentSelectedAppointment);
      result.data = result.data.sort((a, b) => a.overLapped - b.overLapped);
      return result;
    });
  }

  weekRowDataFormation(hdrArr: CalendarGridHeader<any>[], timing: Timing, eachHdrappointments: any,
    rowIndex: number, allDayCheckBox: boolean, viewTypeSelected: GridViewType, selectedDate: Date, gridTimeInerval: number,
    CurrentDateSchedule: any, selectedClient?: any, appointmentList?: any, CurrentSelectedAppointment?: any, 
    Tid?: any, weekArr?: any, lastSlotTime?: any, therpaistObj?: any): CalendarGridBodyData[] {
      let TRD_gridtimeinterval = gridTimeInerval;
    let checkalldaySelected = allDayCheckBox;
    let viewtypeselected: GridViewType = viewTypeSelected;
    let dateTime = timing.slotTime;
    return hdrArr.map((hdr, headerIndex) => {
      let result = this.weekdataFormation(eachHdrappointments, hdr, headerIndex, dateTime, rowIndex, checkalldaySelected, viewtypeselected, TRD_gridtimeinterval, selectedDate, CurrentDateSchedule, selectedClient, appointmentList, CurrentSelectedAppointment, Tid, weekArr, lastSlotTime, therpaistObj);
      result.data = result.data.sort((a, b) => a.overLapped - b.overLapped);
      return result;
    });
  }

  weekdataFormation (eachHdrappointments: Array<CalendarGridBodyProps[]>, hdr: CalendarGridHeader<any>,
  headerIndex: number, dateTime: Date, rowIndex: number, alldaycheckvalue: boolean, viewtypevalue: GridViewType,
  gridtimeinterval: number, selectedDate: Date,CurrentDateSchedule: any, selectedClient?: any, appointmentList?: any, 
  CurrentSelectedAppointment?: any, Tid ?: any, weekArr?: any, lastSlotTime?: any, therpaistObj?: any): CalendarGridBodyData {
    let check_allDayCheckBox = alldaycheckvalue;
    let check_viewTypeSelected = viewtypevalue;
    let currenthdrarray = eachHdrappointments[headerIndex];
    let currentcellarray: CalendarGridBodyProps[] = [];
    let tempContentData: Array<CalendarGridBodyProps[]> = [];
    if (currenthdrarray.length > 0) {
      currentcellarray = this.getWeekCellArray(currenthdrarray, gridtimeinterval, dateTime);
      tempContentData.push(_.cloneDeep(currentcellarray));
    }
     const isOnCall = (check_viewTypeSelected === 'TV' || check_viewTypeSelected == 'ATV') ? this.checkTherapistOnCall(Tid, dateTime, CurrentDateSchedule) : false;
    return {
      BlockingSlot: selectedClient ? this.checkSlot(dateTime, selectedClient, appointmentList, gridtimeinterval, CurrentSelectedAppointment) : false,
      overbookcell: (currentcellarray.length > 0 && (currentcellarray[0].overlappedFlag || currentcellarray[0].overLapped > 0)) ? this.overbookcell(currentcellarray, rowIndex, headerIndex, dateTime, gridtimeinterval, selectedDate) : '',
      spaDayout: '',
      noschedule: this.getWeekStatusColor(Tid, dateTime, CurrentDateSchedule, gridtimeinterval,headerIndex,weekArr,hdr,lastSlotTime,viewtypevalue),
      isOnCall: !check_allDayCheckBox && isOnCall ? this.onCallStatusClass : '',
      hdr: therpaistObj,
      data: currentcellarray,
      tempdata: tempContentData,
    };
  }

  /**
   *
   * @param tempRowData
   * @param eachHdrappointments
   * @param hdr
   * @param headerIndex
   * @param timing
   * @param dateTime
   * @param rowIndex
   * @param alldaycheckvalue
   * @param viewtypevalue
   * @param gridtimeinterval
   * @param selectedDate
   * @param PropertyOperateTime
   * @param CurrentDateSchedule
   */
  dataFormation(eachHdrappointments: Array<CalendarGridBodyProps[]>, hdr: CalendarGridHeader<any>,
    headerIndex: number, dateTime: Date, rowIndex: number, alldaycheckvalue: boolean, viewtypevalue: GridViewType,
    gridtimeinterval: number, selectedDate: Date,
    CurrentDateSchedule: any, selectedClient?: any, appointmentList?: any, CurrentSelectedAppointment?: any): CalendarGridBodyData {
    let check_allDayCheckBox = alldaycheckvalue;
    let check_viewTypeSelected = viewtypevalue;
    let currenthdrarray = eachHdrappointments[headerIndex];
    let currentcellarray: CalendarGridBodyProps[] = [];
    let tempContentData: Array<CalendarGridBodyProps[]> = [];
    if (currenthdrarray.length > 0) {
      currentcellarray = check_allDayCheckBox ? this.getEachCellArrayAllDates(currenthdrarray, gridtimeinterval, dateTime) : this.getEachCellArray(currenthdrarray, gridtimeinterval, dateTime);
      tempContentData.push(_.cloneDeep(currentcellarray));
    }
    const isOnCall = (check_viewTypeSelected === 'TV' || check_viewTypeSelected == 'ATV') ? this.checkTherapistOnCall(hdr.id, dateTime, CurrentDateSchedule) : false;
    return {
      BlockingSlot: selectedClient ? this.checkSlot(dateTime, selectedClient, appointmentList, gridtimeinterval, CurrentSelectedAppointment) : false,
      overbookcell: (currentcellarray.length > 0 && (currentcellarray[0].overlappedFlag || currentcellarray[0].overLapped > 0)) ? this.overbookcell(currentcellarray, rowIndex, headerIndex, dateTime, gridtimeinterval, selectedDate) : '',
      spaDayout: this.checkDayout(dateTime),
      noschedule: this.getStatusColor(check_allDayCheckBox, check_viewTypeSelected, hdr.id, dateTime, CurrentDateSchedule, gridtimeinterval, isOnCall, false),
      isOnCall: !check_allDayCheckBox && isOnCall ? this.onCallStatusClass : '',
      hdr: hdr,
      data: currentcellarray,
      tempdata: tempContentData,
    };
  }

  getStatusColor(check_allDayCheckBox, check_viewTypeSelected, id, dateTime, CurrentDateSchedule, gridtimeinterval, isOnCall, isWeekView) {
    if (!check_allDayCheckBox) {
      if (check_viewTypeSelected === 'TV' || check_viewTypeSelected == 'ATV') {
        if (!isOnCall) {
          return this.TherapistScheduleCheck(id, dateTime, CurrentDateSchedule, gridtimeinterval)
        } else if (isOnCall) {
          return '';
        }
      } else if (check_viewTypeSelected === 'LV') {
        return this.LocationScheduleCheck()
      }
    } else {
      return '';
    }
  }

  getWeekStatusColor(id, dateTime, CurrentDateSchedule, gridtimeinterval, idx, weekArr, hdr, lastSlotTime,viewtypevalue) {
    let isWeekView = true
    if (viewtypevalue === 'TV' || viewtypevalue == 'ATV') {
      return this.TherapistScheduleCheck_week_appgrid(id, dateTime, CurrentDateSchedule, gridtimeinterval,isWeekView,idx,weekArr,hdr,lastSlotTime)
    } else if (viewtypevalue === 'LV') {
      return this.LocationScheduleCheck()
    }
  }

  checkTherapistOnCall(id: number, dateTime: Date, CurrentDateSchedule: any): boolean {
    var schedule = _.cloneDeep(CurrentDateSchedule);
    let therapistSchedule = schedule.filter(x => x.therapistId == id);
    if (therapistSchedule.length == 0) {
      let therapistScheduleCopyAndMove = schedule.filter(x => x.therapist.id == id);
      if (this.utils.IsTherapistHaveOnCallScheduleCopyAndMove(dateTime, therapistScheduleCopyAndMove)) {
        if (dateTime.getTime() < this.propertyEndDatetime.getTime()) {
          return true;
        }
      } else {
        return false;
      }
    }
    else if (this.utils.IsTherapistHaveOnCallSchedule(dateTime, therapistSchedule)) {
      if (dateTime.getTime() < this.propertyEndDatetime.getTime()) {
        return true;
      }
    } else {
      return false
    }
  }

  /**
   *
   * @param headerArray
   * @param timing
   * @param gridtimeinterval
   */
  // getEachCellArray(headerArray: CalendarGridBodyProps[], timing: string, gridtimeinterval: number, dateTime: Date): CalendarGridBodyProps[] {
  //   let that = this;
  //   return headerArray.filter((ca, cellIndex) => {

  //     let StartApptTime: Date = that.utils.getDate(ca.appointmentDetail.startTime);
  //     // need to modified . remove moment and try to implement in native script
  //     let fromTime: Date = that.localization.AddTimeToDate(new Date(that.utils.getDate(that.utils.formatDate(StartApptTime))), new Date(this.localization.TimeToDate(timing)));
  //     let toTime: Date = that.localization.AddTimeToDate(new Date(that.utils.getDate(that.utils.formatDate(StartApptTime))), new Date(this.localization.TimeToDate(timing)));
  //     toTime.setTime(fromTime.getTime() + (gridtimeinterval * 60 * 1000));
  //     if (ca.appointmentDetail && StartApptTime.getTime() >= fromTime.getTime() && StartApptTime.getTime() < toTime.getTime()) {
  //       if (this.localization.getTimeFormat() === 12) {
  //         return this.utils.formatAMPM(dateTime) == timing ? true : false;
  //       } else if (this.localization.getTimeFormat() === 24) {
  //         return this.utils.format24HrTime(dateTime) == timing ? true : false;
  //       }
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  getEachCellArray(headerArray: CalendarGridBodyProps[], gridtimeinterval: number, dateTime: Date): CalendarGridBodyProps[] {
    let that = this;
    return headerArray.filter((ca) => {
      ca.appointmentDetail.startTimeObj = new Date(ca.appointmentDetail.startTime);
      ca.appointmentDetail.endTimeObj = new Date(ca.appointmentDetail.endTime);
      let StartApptTime = ca.appointmentDetail.startTimeObj.getTime();
      // need to modified . remove moment and try to implement in native script
      let fromTime: Date = dateTime;
      let toTime: Date = that.localization.AddMins(fromTime, gridtimeinterval);
      return StartApptTime >= fromTime.getTime() && StartApptTime < toTime.getTime();
    });
  }
  getWeekCellArray(headerArray: CalendarGridBodyProps[], gridtimeinterval: number, dateTime: Date): CalendarGridBodyProps[] {
    let that = this;
    return headerArray.filter((ca) => {
      ca.appointmentDetail.startTimeObj = new Date(ca.appointmentDetail.startTime);
      ca.appointmentDetail.endTimeObj = new Date(ca.appointmentDetail.endTime);
      let StartApptTime = ca.appointmentDetail.startTimeObj.getTime();
      // need to modified . remove moment and try to implement in native script
      let hdrDate = ca.appointmentDetail.startTime.split('T');
      let hdrTime = this.datePipe.transform(dateTime, 'HH:mm:ss');
      let date = hdrDate[0]+"T"+hdrTime
      let weekViewDate = new Date(date)
      let fromTime: Date = weekViewDate;
      let toTime: Date = that.localization.AddMins(fromTime, gridtimeinterval);
      console.log(StartApptTime >= fromTime.getTime() && StartApptTime < toTime.getTime())
      return StartApptTime >= fromTime.getTime() && StartApptTime < toTime.getTime();
    });
  }
  getEachCellArrayAllDates(headerArray: CalendarGridBodyProps[], gridtimeinterval: number, dateTime: Date): CalendarGridBodyProps[] {
    let that = this;
    return headerArray.filter((ca) => {
      ca.appointmentDetail.startTimeObj = new Date(ca.appointmentDetail.startTime);
      ca.appointmentDetail.endTimeObj = new Date(ca.appointmentDetail.endTime);
      let StartApptTimeHr = ca.appointmentDetail.startTimeObj.getHours()
      let StartApptTimeMin = ca.appointmentDetail.startTimeObj.getMinutes();
      // need to modified . remove moment and try to implement in native script
      let fromTime: Date = dateTime;
      let start =this.localization.getTime(ca.appointmentDetail.startTimeObj, 24);
      let end =this.localization.getTime(fromTime, 24);
      let toTime: Date = that.localization.AddMins(fromTime, gridtimeinterval);
      // return StartApptTimeHr >= fromTime.getHours() && StartApptTimeHr <= toTime.getHours() &&
      //   StartApptTimeMin >= fromTime.getMinutes() && StartApptTimeMin < toTime.getMinutes();
      return start==end;
    });
  }

  /**
   *
   * @param id
   * @param dateTime
   * @param selectedDate
   * @param PropertyOperateTime
   * @param CurrentDateSchedule
   */
  TherapistScheduleCheck(id: number, dateTime: Date, CurrentDateSchedule: any, gridtimeinterval: number): string {
    if (CurrentDateSchedule[0].id) {
      return this.TherapistScheduleCheck_appgrid(id, dateTime, CurrentDateSchedule, gridtimeinterval);
    } else {
      return this.TherapistScheduleCheck_appCopy(id, dateTime, CurrentDateSchedule)
    }
  }

  LocationScheduleCheck() {
    return this.openStatusClass;
  }

  /**
   *
   * @param id
   * @param dateTime
   * @param selectedDate
   * @param PropertyOperateTime
   * @param CurrentDateSchedule
   */
  TherapistScheduleCheck_appgrid(id: number, dateTime: Date, CurrentDateSchedule: any, gridtimeinterval: number): string {
    let classname = '';
    let schedule = _.cloneDeep(CurrentDateSchedule);
    let obj = schedule.filter(res => { return id && res.therapistId == Number(id) });
    // if(isWeekView) {
    //   obj = schedule.filter(res => { return id && res.therapistId == Number(id) &&  (this.localization.getTime(new Date(hdr.dateObj),this.localization.getTimeFormat()) == this.localization.getTime(new Date(res.scheduleDate),this.localization.getTimeFormat()))});
    // }
    // console.log(obj)
    // if(isWeekView) {
    //   let therapistSchedule = this.utils.IsTherapistHaveSchedule(dateTime, obj, gridtimeinterval)
    //   let tformatDate = this.localization.convertDateTimeToAPIDateTimeSec(schedule[idx].scheduleDate)
    //   let date = tformatDate.split('T');
    //   let tformatTime = this.localization.convertDateTimeToAPIDateTimeSec(dateTime)
    //   let time = tformatTime.split('T');
    //   let weekdate =  date[0]+'T'+time[1];
    //   dateTime = new Date(weekdate);
    //   // let apptTime = this.localization.ToTime(highestAppointmentOperatingHours['EndTime'], "HH:mm");
    //   let endDateforWeek = weekArr[6].row3.split('T')
    //   let endTimeforWeek = this.localization.convertDateTimeToAPIDateTimeSec(lastSlotTime).split('T')
    //   let updatedEndDateTime = endDateforWeek[0]+'T'+endTimeforWeek[1]
    //   // let apptTime = "2023-07-22T22:00:00";
    //   // console.log(apptTime)
    //   let a = this.utils.IsTherapistHaveSchedule(dateTime, obj, gridtimeinterval)
    //   let b = dateTime.getTime() 
    //   let c= new Date(updatedEndDateTime).getTime()
    //   let d = b < c
    //   let e = a && d
    //   console.log(e)
    //   if (e) {
    //     classname = this.openStatusClass;
    //   } else {
    //     classname = this.closedNoSchedule;
    //   }
    // }
    // else {
      if (this.utils.IsTherapistHaveSchedule(dateTime, obj, gridtimeinterval) && dateTime.getTime() < this.propertyEndDatetime.getTime()) {
        classname = this.openStatusClass;
      } else {
        classname = this.closedNoSchedule;
      }
    // }
    return classname;
  }

  TherapistScheduleCheck_week_appgrid(id: number, dateTime: Date, CurrentDateSchedule: any, gridtimeinterval: number,isWeekView: boolean,idx: number,weekArr: any,hdr: any,lastSlotTime: Date) {
    let classname = '';
    let schedule = _.cloneDeep(CurrentDateSchedule);
    let obj = schedule.filter(res => { return moment(new Date(hdr.dateObj)).format('YYYY-MM-DD') === moment(new Date(res.scheduleDate)).format('YYYY-MM-DD')});
    let date = this.localization.convertDateTimeToAPIDateTimeSec(schedule[idx].scheduleDate).split('T');
    let time = this.localization.convertDateTimeToAPIDateTimeSec(dateTime).split('T');
    let updatedDateTime =  date[0]+'T'+time[1];
    dateTime = new Date(updatedDateTime);
    let endDateforWeek = weekArr[6].row3.split('T')
    let endTimeforWeek = this.localization.convertDateTimeToAPIDateTimeSec(lastSlotTime).split('T')
    let updatedEndDateTime = endDateforWeek[0]+'T'+endTimeforWeek[1]
    if (this.utils.IsTherapistHaveSchedule(dateTime, obj, gridtimeinterval) && dateTime.getTime() < new Date(updatedEndDateTime).getTime()) {
      classname = this.openStatusClass;
    } else {
      classname = this.closedNoSchedule;
    }
    return classname;
  }


  /**
   *
   * @param id
   * @param dateTime
   * @param selectedDate
   * @param PropertyOperateTime
   * @param CurrentDateSchedule
   */
  TherapistScheduleCheck_appCopy(id: number, dateTime: Date, CurrentDateSchedule: any): string {

    let classname = '';
    var therapClone = _.cloneDeep(CurrentDateSchedule);
    let obj = therapClone.filter(r => { return r.therapist.id == id });

    let isTherapScheduled = false;
    if (obj && obj.length) {
      for (let i = 0; i < obj.length; i++) {
        const therap = obj[i];
        const timeArr = therap ? therap.scheduleTime : null;
        if (!timeArr) break;
        for (let k = 0; k < timeArr.length; k++) {
          const timeList = timeArr[k];
          if (timeList.startTime && timeList.endTime && dateTime >= this.utils.getDate(timeList.startTime) && dateTime < this.utils.getDate(timeList.endTime)) {
            isTherapScheduled = true;
          }
        }
      }
    }
    if (isTherapScheduled) {
      if (dateTime.getTime() < this.propertyEndDatetime.getTime()) {
        classname = this.openStatusClass;
      }
    } else {
      classname = this.closedNoSchedule;
    }
    return classname;
  }

  /**
   *
   * @param dateTime
   * @param selectedDate
   * @param PropertyOperateTime
   */
  checkDayout(dateTime: Date): string {
    return dateTime.getTime() >= this.propertyEndDatetime.getTime() ? this.closedNoSchedule : '';
  }

  /**
   *
   * @param currentcellarray
   * @param rowIndex
   * @param columnIndex
   * @param date
   * @param time
   * @param gridtimeinterval
   * @param selecteddateIDwithYr
   */
  overbookcell(currentcellarray: CalendarGridBodyProps[], rowIndex: number, columnIndex: number, date: Date,
    gridtimeinterval: number, selectedDate: Date): string {
    let that = this;
    if (this.utils.GetDateWithoutTime(selectedDate).getTime() < this.utils.GetDateWithoutTime(this.PropertyInfo.CurrentDate).getTime()) return;
    let classname = '';
    let uniqueName = `${rowIndex}_${columnIndex}_overbook`

    let firstcellTime = currentcellarray[0].startTimewithsetuptime;

    currentcellarray.sort(function (a, b) {
      if (a.endTimewithbreakdowntime > b.endTimewithbreakdowntime) { return -1; }
      if (a.endTimewithbreakdowntime < b.endTimewithbreakdowntime) { return 1; }
      return 0;
    });
    let lastcellcellTime = currentcellarray[0].endTimewithbreakdowntime;

    let blockcellheight = this.utils.getTimeDifference(that.utils.getDate(firstcellTime), that.utils.getDate(lastcellcellTime), 'Min');
    let cellheight = (blockcellheight / gridtimeinterval) * 100 + (blockcellheight / gridtimeinterval);
    let blockcellTop = this.utils.getTimeDifference(that.utils.getDate(date), that.utils.getDate(firstcellTime), 'Min');
    let cellTop = ((blockcellTop / gridtimeinterval) * 100);

    let cssProp = [
      { property: "width", value: '100%' },
      { property: "height", value: cellheight + "%" },
      { property: "top", value: cellTop + "%" },
      { property: "background", value: "inherit" }];
    classname = this.utils.CreateCssClass(cssProp, uniqueName, 'apptgrid', false);
    classname += this.closedNoSchedule;

    return classname
  }

  /**
   *
   * @param startTime
   * @param selClient
   * @param selectedDate
   * @param gridTimeInterval
   */
  checkSlot(startTime: Date, selClient: any, list: CalendarGridBodyProps[], gridTimeInterval: number, CurrentSelectedAppointment): boolean {

    let fromTime = startTime.getTime(),
      toTime = this.utils.AddMinutes(startTime, gridTimeInterval).getTime();
    let appointmentData = list.filter(result => {
      let StartApptTime = result.appointmentDetail.startTimeObj.getTime(), EndApptTime = result.appointmentDetail.endTimeObj.getTime();
      return result.appointmentDetail.status != "TEMP" && (result.clientDetails && result.clientDetails.id == selClient)
        && ((StartApptTime >= fromTime && StartApptTime < toTime) || (EndApptTime > fromTime && EndApptTime <= toTime && StartApptTime != EndApptTime) || (StartApptTime < fromTime && EndApptTime > toTime))
    });
    let flag = appointmentData.length > 0; //outputData.length > 0 ||

    if (!flag && CurrentSelectedAppointment) { // check  if this is current appointment
      let startApptTimeCurrent = CurrentSelectedAppointment.appointmentDetail.startTimeObj;
      let endApptTimeCurrent = CurrentSelectedAppointment.appointmentDetail.endTimeObj;
      flag = ((startApptTimeCurrent >= fromTime && startApptTimeCurrent < toTime) || (endApptTimeCurrent > fromTime && endApptTimeCurrent <= toTime &&
        startApptTimeCurrent.getTime() != endApptTimeCurrent.getTime()) || (startApptTimeCurrent < fromTime && endApptTimeCurrent > toTime));
    }
    return flag;
  }

  /**
   *
   * @param dataset
   * @param date
   * @param propTime
   * @param timeinterval
   */
  checkExceedingTimelimit(dataset: CalendarGridBodyProps, timeinterval: number): void {
    let Startdateandtime = dataset.startTimewithsetuptime;
    let enddateandtime = dataset.endTimewithbreakdowntime;
    let currentstartdatetime = this.propertyStartDatetime;
    let currentenddatetime = this.propertyEndDatetime;
    let appstartdate = this.utils.getDate(Startdateandtime).getTime();
    let appenddate = this.utils.getDate(enddateandtime).getTime();

    if (!this.allowBookingAfterSpaClosing) {
      if (appstartdate < currentstartdatetime.getTime()) {
        dataset.appointmentDetail.startTime = dataset.startTimewithsetuptime = currentstartdatetime;
      }
      if (appenddate > currentenddatetime.getTime()) {
        dataset.appointmentDetail.endTime = dataset.endTimewithbreakdowntime = currentenddatetime;
      }
      if (appstartdate > currentenddatetime.getTime()) {
        dataset.appointmentDetail.startTime = dataset.startTimewithsetuptime = currentenddatetime;
      }
      if (appenddate < currentstartdatetime.getTime()) {
        dataset.appointmentDetail.endTime = dataset.endTimewithbreakdowntime = currentstartdatetime;
      }
    }
    if (appstartdate <= currentstartdatetime.getTime() && appenddate <= currentstartdatetime.getTime()) {
      dataset.appointmentDetail.startTime = dataset.startTimewithsetuptime = currentstartdatetime;
      dataset.appointmentDetail.endTime = dataset.endTimewithbreakdowntime = this.utils.AddMins(dataset.startTimewithsetuptime, timeinterval);
      dataset.appointmentDetail.setUpTime = dataset.appointmentDetail.breakDownTime = 0;
    }
    if (appenddate >= currentenddatetime.getTime() && appstartdate >= currentenddatetime.getTime()) {
      dataset.appointmentDetail.startTime = dataset.startTimewithsetuptime = currentenddatetime;
      dataset.appointmentDetail.endTime = dataset.endTimewithbreakdowntime = this.utils.AddMins(dataset.startTimewithsetuptime, timeinterval);
      dataset.appointmentDetail.setUpTime = dataset.appointmentDetail.breakDownTime = 0;
    }

  }

  /**
   * This method returns list of CalendarGridHeader
   * @param header list of grid header
   * @param titleKey titles to be showm in header
   */
  public BuildCalendarGridHeader<THeader>(header: THeader[], titleKey: string[]): CalendarGridHeader<THeader>[] {
    let gridHeader: CalendarGridHeader<THeader>[] = [];
    if (header && header.length > 0) {
      gridHeader = header.map(x => {
        let row: any = x;
        const title = row.alsoKnownAs ? this.GridHeaderKeyForAliasTherapist : titleKey;
        return <CalendarGridHeader<THeader>>{
          id: row.id,
          titleKey: title,
          headerObject: x,
          image: row.imgObj ? row.imgObj : row.image,
          titleDesc: String(title.map(t => { return row[t] }).join(" "))
        }
      });
    }
    return gridHeader;
  }

  public BuildWeekCalendarGridHeader(header,therapistObj) {
    let weekgridheader = [];
    if(header) {
      weekgridheader = header.map(x => {
        return {
          dateMonth : x.row1,
          day : x.row2,
          dateObj : x.row3,
          localizedDate:  this.localization.localizedDate(x.row4),
          id : therapistObj.id,
          headerObject : therapistObj
        }
      });
    }
    return weekgridheader;
  }

  getServiceName(serviceId: number, serviceArr: any[]): string | number {
    if (serviceId && serviceArr) {
      let service = serviceArr.find(res => { return res.id == serviceId });
      return service && service.description;
    }
    return serviceId == 0 ? "" : serviceId;
  }

  getLocationName(LocationId: number, locationArr): string | number {
    if (LocationId == 0) {
      return this.localization.captions.setting.Offsite;
    }
    else if (locationArr) {
      var location = locationArr.find(res => { return res.id == LocationId });
      return location && location.description;
    }
    return LocationId;
  }

  getTherapistName(therapistList, therapistArr): string {
    if (therapistList && therapistList.length > 0 && therapistArr) {
      var therapistObj = therapistArr.filter(a => { return a.id == therapistList[0].therapistId });
      const temptherapist = therapistList.length > 1 ? (" +" + (therapistList.length - 1)) : "";
      return (therapistObj.length > 0) ? therapistObj[0].firstName + " " + therapistObj[0].lastName + temptherapist : '';
    }
    return "";
  }
  getAppointmentAddOns(addons: any[], managementDataSet): string {
    let addOnList = managementDataSet["Addons"];
    let addOnName: string = "";
    if (addons && addons.length > 0 && addOnList && addOnList.length > 0) {
      addons.forEach((x,i)=>{
        var addOn = addOnList.find(x => x.id == addons[i].addOnId);
        addOnName = addOn ? addOnName +addOn.addOnName+(i != addons.length -1 ? ", " : '') : "";  
      })
      // if (addons.length > 1)
      //   addOnName += "+" + (addons.length - 1);
    }
    return addOnName;
  }

  reloadGridStyleCss(BodyArray: CalendarGridBody[]) {
    BodyArray.forEach(
      (x) => {
        x.data.forEach(
          (y) => {
            y.data && y.data.forEach((element) => {
              element.setCss = true;
            });

            // y.tempdata[0] && y.tempdata[0].forEach((element, ei) => {
            //   element.setCss = true;
            // });
          }
        );
      }
    );
  }

}
