
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { CreateTransactionComponent } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.component';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { CustomFormField } from '../custom-form';
import { TransactionConfiguration } from '../transaction.interface';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';
import { AddItemListConfig, EnableCustomFormFieldsConfig } from './create-transaction-config.interface';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CreateTransactionItemlist } from './item-transaction';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { MatDialog } from '@angular/material/dialog';
import { AgilysysSessionExpiredComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-session-expired/agilysys-session-expired.component';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';


export const AddItemList: AddItemListConfig = (
    transactionJsonData: TransactionConfiguration,
    rowData: Array<any>,
    searchApplied: any,
    filterApplied: any,
    createTransactionService: CreateTransactionService,
    searchKey: string,
    FilterQuery: any,
    FromData: string,
    isEmpty: boolean,
    httpService: HttpService,
    transactionService: TransactionService,
    createTrasacationHeaderEntity: any,
    createTransactionDetailEntity: any,
    toastrService: ToastrService,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    translateService: TranslateService,
    TilesHeaderTrascationEntity: any,
    homeGroup: FormGroup,
    responseData: any,
    globalSelectedSupplier?: any,
    dialog?: MatDialog,
    commonService?: CommonService
): TransactionConfiguration => {
    try {
        rowData = rowData ? rowData : [];
        if (!createTransactionService.GridRowData) {
            createTransactionService.GridRowData = [];
        }
        if (isEmpty) {
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
            createTransactionService.GridRowData = [];
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = [];
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard = {
                ...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard
            };
        }
        const gridDataLength = transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData ? transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length : 0;
        const cloneDataLength = createTransactionService.GridRowData ? createTransactionService.GridRowData.length : 0;
        if (FromData !== 'processpo') {
            if (gridDataLength > 0 || cloneDataLength > 0) {
                if (transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.DuplicateItemsConfiguration &&
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.DuplicateItemsConfiguration.CheckDuplicateItems) {
                    const enableDuplicate: boolean = CheckDuplicateItems(rowData, transactionJsonData, createTransactionService);
                    if (enableDuplicate) {
                        const ConfirmPopUpDialog: ConfirmPopModel = {
                            PopupHeaderContent: translateService.instant('Common.Warning'),
                            PopUpContent: translateService.instant('Common.DuplicateItemsWarning')
                        };
                        const dialogRef = dialog.open(AgilysysSessionExpiredComponent, {
                            width: '400px',
                            height: '222px',
                            data: ConfirmPopUpDialog,
                            disableClose: false
                        });
                    }
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
                        [...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];

                } else {
                    createTransactionService.GridRowData = createTransactionService.GridRowData.concat(rowData);
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = 
                      transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.concat(rowData);
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
                      [...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
                }
            }
            if (gridDataLength === 0) {
                transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = rowData;                
                // To avoid items pushing duplicately.Below changes added when checking package limitation duplicate check
                if (createTransactionService.GridRowData?.length === 0) {
                    // createTransactionService.GridRowData = rowData;
                    rowData.forEach(rdata => {
                          createTransactionService.GridRowData.push(rdata);
                    });
                } else {
                    rowData.forEach(rdata => {
                      const existIndex = createTransactionService.GridRowData.findIndex(gdata => JSON.stringify(gdata) === JSON.stringify(rdata));
                      if (existIndex === -1) {
                        createTransactionService.GridRowData.push(rdata);
                      }
                    });
                }
                // createTransactionService.GridRowData = createTransactionService.GridRowData.concat(rowData); 
            }
            if ((transactionJsonData.PostEntityName === 'PurchaseOrderHead' ||
                transactionJsonData.PostEntityName === 'PurchaseReqHead') && globalSelectedSupplier) {
                transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(res => {
                    if (globalSelectedSupplier.SupplierId > 0) {
                        res.SupplierId = globalSelectedSupplier.SupplierId;
                        res.SupplierName = globalSelectedSupplier.SupplierName;
                        res.SendVia = globalSelectedSupplier.SendVia;
                        res.CurrencyId = globalSelectedSupplier.CurrencyId;
                        res.ExchangeRate = globalSelectedSupplier.ExchangeRate;
                        res.CurrencyCode = res.Currency.find(item => item.Id === globalSelectedSupplier.CurrencyId)?.Name;
                    }
                });
            }
        }
        if (FromData === 'query' || FromData === 'attachedfiles' || FromData === 'transactionitemaction') {
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody
                = CreateTransactionItemlist.itemListTableCard
                    (createTrasacationHeaderEntity, createTransactionDetailEntity, transactionJsonData,
                        httpService, postModelHederEntity, postModelDetailEntity,
                        translateService, TilesHeaderTrascationEntity, responseData, homeGroup);
        }
        rowData.forEach((searchData: any) => {
            // if (createTransactionService.GridRowData.findIndex(x => x[itemListDataKey] === searchData[itemListDataKey]) === -1 &&
            //     !searchData.RowId) {
            //     createTransactionService.GridRowData.push(searchData);
            // }
            if (FromData === 'processpo') {
                if (!transactionService.poCloneGridData) {
                    transactionService.poCloneGridData = [];
                }
                if (!transactionService.poCloneGridData.includes(searchData)) {
                    searchData.RowId = transactionService.poCloneGridData.length + 1;
                    if (transactionJsonData.CreateTranscationConfig.ItemsConfig &&
                        transactionJsonData.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ProcessPoConfig') &&
                        transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig !== undefined &&
                        transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.hasOwnProperty('EnabledProcessPo') &&
                        transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.EnabledProcessPo) {
                        const supplierId = transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SelectedSupplierId;
                        if (supplierId && supplierId !== undefined) {
                            const suplierList = transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.
                                SupplierList.filter(x => x['SuplierId'] === supplierId);
                            const poSuplierName = suplierList[0]['SuplierName'];
                            const poSuplierId = suplierList[0]['SuplierId'];
                            const poSendVia = suplierList[0]['SendVia'];
                            searchData['SupplierId'] = poSuplierId;
                            searchData['SupplierName'] = poSuplierName;
                            searchData['SendVia'] = poSendVia;
                        }
                    }
                    transactionService.poCloneGridData.push(searchData);
                }
            }
        });
        if (FromData === 'processpo' &&
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig) {
            const SupplierId = transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig['SelectedSupplierId'];
            const filterSupplierData = transactionService.getFilterSupplier(SupplierId, transactionService.poCloneGridData);
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = filterSupplierData;
        }
        // createTransactionService.GridRowData = _.cloneDeep(
        //     transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData);
        if (transactionJsonData.CreateTranscationConfig.ItemsConfig &&
            transactionJsonData.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ProcessPoConfig') &&
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig !== undefined &&
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.hasOwnProperty('EnabledProcessPo') &&
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.EnabledProcessPo) {
            const supplierId = transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SelectedSupplierId;
            if (supplierId && supplierId !== undefined) {
                const suplierList = transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList.filter(x => x['SuplierId'] === supplierId);
                const poSuplierName = suplierList[0]['SuplierName'];
                const poSuplierId = suplierList[0]['SuplierId'];
                const poSendVia = suplierList[0]['SendVia'];
                transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(x => {
                    x['SupplierId'] = poSuplierId;
                    x['SupplierName'] = poSuplierName;
                    x['SendVia'] = poSendVia;
                });
            }

        }
        if (FromData !== 'processpo') {
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach((data, index) => {
                data.RowId = index + 1;
                if (transactionJsonData.Name === 'Inventory Reset') {
                    data.Qty = data.Qty ? data.Qty : Formater('Qty', '0', null, null);
                }
            });
        }
        // if ( gridDataLength && gridDataLength > 0 ) {
        //     transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.map(Data => {
        //         if ( Data.hasOwnProperty('Units') && Data.Units.length > 0 ) {
        //              Data.Units.filter((x: any) => x.hasOwnProperty('Id') && x.Id === 0 ).map((e: any) => {
        //                  Data.UnitTypeId = e.Id;
        //                  Data.UnitCode = e.UnitCode;
        //                  return;
        //                 });
        //         }
        //     });
        // }
        // const ShowCategoryIconObject =
        // transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
        // GridHeaderDefination.find(x => x.field === 'icon');
        // if (ShowCategoryIconObject.categoryIcon.EnableCategoryIcon) {
        //     ShowCategoryIconObject.cellRenderer = function (params: any) {
        //         let a = '';
        //         const IconName = CategoryIcons.categoryIconName(params.data);
        //         if (IconName !== null && IconName !== undefined &&
        //             IconName) {
        //                 a += `<i class="${IconName}" title="${IconName}"></i>`;
        //         }
        //         return a;
        //     };
        // }
        transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = {
            ...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig
        };
        return transactionJsonData;
    } catch (Error) {
        console.error('Error occurred FetchGridProperties', Error);
    }
};

/**
 * @method CheckDuplicateItems
 * @Input Params RowData
 * @Description check duplicate item in existing data
 */
function CheckDuplicateItems(RowData: Array<any>, ItemListData: TransactionConfiguration,
    createTransactionService: CreateTransactionService): boolean {
    try {
        let existingGridData: Array<any> = createTransactionService.GridRowData;
        const DuplicateItemConfig = ItemListData.CreateTranscationConfig.ItemsConfig.ItemList.DuplicateItemsConfiguration;
        const lookUpDataKey: string = DuplicateItemConfig.LookupKey;
        const itemListDataKey: string = DuplicateItemConfig.ItemlistKey;
        const allowDuplicateItem: boolean = DuplicateItemConfig.hasOwnProperty('AllowDuplicateItemsToAdd') ? 
            DuplicateItemConfig.AllowDuplicateItemsToAdd : false;
        // if (allowDuplicateItem) {
        //     existingGridData = _.cloneDeep(createTransactionService.GridRowData);
        // }
        let enableDuplicateBool = false;
        if (RowData.length > 0) {
            RowData.forEach((element: any) => {
                const getIndex: number = existingGridData.findIndex(e => e[itemListDataKey] === element[lookUpDataKey]);
                if (getIndex === -1) {
                    ItemListData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.push(element);
                    createTransactionService.GridRowData.push(element);
                } else {
                    enableDuplicateBool = true;
                    if (allowDuplicateItem) {
                        enableDuplicateBool = false;
                        element.AlreadyExists = true;
                        ItemListData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.push(element);
                        createTransactionService.GridRowData.push(element);
                    }
                }
            });
        }
        return enableDuplicateBool;
    } catch (error) {
        console.error(error);
    }
}