import { Component, OnInit ,Input,ViewEncapsulation} from '@angular/core';
import {   GridType } from '../../shared/globalsContant';

@Component({
  selector: 'app-retail-supplier-details',
  templateUrl: './retail-supplier-details.component.html',
  styleUrls: ['./retail-supplier-details.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class RetailSupplierDetailsComponent implements OnInit {

  @Input() dataInput;
  tableoptions:any;

  ngOnInit() {
    this.BindData();
  }

  BindData(){
    this.tableoptions = [{
      TableHdrData: [
      { "title": 'Description', "jsonkey": "description","searchable": false,"alignType": "left","sortable": false },
      { "title": 'Item #', "jsonkey": "item", "alignType": "left","searchable": false,"sortable": false},
      { "title": 'On-Hand', "jsonkey": "onhand","alignType": "left","searchable": false,"sortable": false},
      { "title": 'MTD', "jsonkey": "mtd", "alignType": "left",  "searchable": false,"sortable": false,},
      { "title": 'YTD', "jsonkey": "ytd", "sortable": false, "searchable": false,"alignType": "left"},
      { "title": 'Par', "jsonkey": "par", "sortable": false, "searchable": false,"alignType": "left"},
      { "title": 'Cost', "jsonkey": "cost", "sortable": false, "searchable": false,"alignType": "left"},
      { "title": 'Price', "jsonkey": "price", "sortable": false, "searchable": false,"alignType": "left"},
      ],
      TablebodyData: this.dataInput,
      pagination: false,
      sortable: false,
      CustomColumn: false,
      EnableActions: false,
      SelectRows: false,
      SelectedSettingId: GridType.supplierDetails,
      Searchable: false,
      EditMoreOption: false,
      Sortable: "",
      TableId: GridType.retail,
      disableDelete: true,
      showToggle: false,
      IsViewOnly: true,
      customHeader:false
    }];
  }
}
