import { Directive, ElementRef, HostListener, OnInit, Input, Renderer2 } from '@angular/core';


@Directive({
  selector: '[appSelectButton]'
})
export class AppselectButtonDirective implements OnInit  {
    @Input('buttonObj') buttonObj: Array<any>;
  constructor(public el: ElementRef, private _renderer: Renderer2) {
  }

  @HostListener('click', ['$event.target'])
  onClick(btn: any) {
    console.log('button', btn);
    const getId = this.buttonObj[0].id;
    if ( getId === 0 ) {
      this.ifSelected();
    }
 }

 ifSelected(): void {
  const isSelect: any = this.el.nativeElement.getAttribute('select');
  if ( isSelect === 'selected' ) {
      this.el.nativeElement.classList.remove('body-bgcolor', 'whitecolor');
      __ngRendererSetElementAttributeHelper(this._renderer, this.el.nativeElement, 'select', 'unselect');
  } else if (isSelect === 'unselect') {
      this.el.nativeElement.classList.add('spaClickButton', 'mb-2', 'clickbtntxtcss',  'mlneg1', 'body-bgcolor', 'whitecolor', 'body-bordercolor');
      __ngRendererSetElementAttributeHelper(this._renderer, this.el.nativeElement, 'select', 'selected');
  }
}

  ngOnInit() {
    this.el.nativeElement.classList.add('spaClickButton', 'mb-2', 'clickbtntxtcss',  'mlneg1', 'body-bgcolor', 'whitecolor', 'body-bordercolor');
    __ngRendererSetElementAttributeHelper(this._renderer, this.el.nativeElement, 'select', 'selected');
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}
