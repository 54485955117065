import { Injectable } from '@angular/core';
import { SPAConfig } from '../common/config/SPA-config';
@Injectable()
export class OutletInventoryService {

  constructor(private spaConfig: SPAConfig) { }

  public GetManualInventoryHeader(isActive) {
    let header = [];
    header = [
      {"title": this.spaConfig.captions.inventory.Item,"jsonKey": "Item","alignType": "left", "searchable": true},
      {"title": this.spaConfig.captions.inventory.Description,"jsonKey": "Description","alignType": "left", "searchable": true},
      {"title": isActive ? this.spaConfig.captions.inventory.QuantityOnHand : this.spaConfig.captions.inventory.FrozenQunatity,"jsonKey": "FrozenQuantity","alignType": "right", "searchable": true},
      {"title": this.spaConfig.captions.inventory.ScannedQuantity,"jsonKey": "ScannedQuantity","alignType": "right"}
    ]
    return header;
  }

  public GetManualInventoryData() {
    let inventoryData = [];
    inventoryData = [
      {
        outletId: 0,
        outletName: 'Visual One Spa',
        itemsList: [
          { "Item": 258, "Description": '37403 Vintage Hood', "FrozenQuantity": 4, "ScannedQuantity": null, "Category": 'Golf Apparels', "SubCategory1": 'Adults', "SubCategory2": '15' },
          { "Item": 259, "Description": '37408 Men’s Jacket', "FrozenQuantity": 4, "ScannedQuantity": null, "Category": 'Golf Bags', "SubCategory1": 'Kids', "SubCategory2": '12' },
          { "Item": 260, "Description": '37409 Men’s Gloves', "FrozenQuantity": 4, "ScannedQuantity": null, "Category": 'Golf Balls', "SubCategory1": 'Women', "SubCategory2": '12' },
          { "Item": 261, "Description": '37410 Polo Tshirt', "FrozenQuantity": 3, "ScannedQuantity": null, "Category": 'Golf Apparels', "SubCategory1": 'Women', "SubCategory2": '12' },
          { "Item": 262, "Description": '37412 Winter Dress', "FrozenQuantity": 4, "ScannedQuantity": null, "Category": 'Golf Bags', "SubCategory1": 'Women', "SubCategory2": '15' },
          { "Item": 263, "Description": '37403 Vintage Hood', "FrozenQuantity": 5, "ScannedQuantity": null, "Category": 'Golf Balls', "SubCategory1": 'Kids', "SubCategory2": '15' },
          { "Item": 264, "Description": '37413 Women’s Jacket', "FrozenQuantity": 2, "ScannedQuantity": null, "Category": 'Golf Apparels', "SubCategory1": 'Men', "SubCategory2": '15' },
          { "Item": 265, "Description": '37414 Women’s Gloves', "FrozenQuantity": 1, "ScannedQuantity": null, "Category": 'Golf Bags', "SubCategory1": 'Adults', "SubCategory2": '13' },
          { "Item": 266, "Description": '37415 Polo Tshirt', "FrozenQuantity": 3, "ScannedQuantity": null, "Category": 'Golf Balls', "SubCategory1": 'Women', "SubCategory2": '13' },
          { "Item": 267, "Description": '37416 Summer Shirt', "FrozenQuantity": 2, "ScannedQuantity": null, "Category": 'Golf Apparels', "SubCategory1": 'Kids', "SubCategory2": '13' },
          { "Item": 268, "Description": '37417 Summer Shirt', "FrozenQuantity": 2, "ScannedQuantity": null, "Category": 'Golf Bags', "SubCategory1": 'Men', "SubCategory2": '13' },
          { "Item": 269, "Description": '37418 Women’s Gloves', "FrozenQuantity": 7, "ScannedQuantity": null, "Category": 'Golf Balls', "SubCategory1": 'Men', "SubCategory2": '15' },
          { "Item": 263, "Description": '37403 Vintage Hood', "FrozenQuantity": 5, "ScannedQuantity": null, "Category": 'Golf Apparels', "SubCategory1": 'Women', "SubCategory2": '15' },
          { "Item": 264, "Description": '37413 Women’s Jacket', "FrozenQuantity": 2, "ScannedQuantity": null, "Category": 'Golf Bags', "SubCategory1": 'Men', "SubCategory2": '15' },
          { "Item": 265, "Description": '37414 Women’s Gloves', "FrozenQuantity": 1, "ScannedQuantity": null, "Category": 'Golf Balls', "SubCategory1": 'Kids', "SubCategory2": '15' },
          { "Item": 263, "Description": '37403 Vintage Hood', "FrozenQuantity": 5, "ScannedQuantity": null, "Category": 'Golf Bats', "SubCategory1": 'Men', "SubCategory2": '14' },
          { "Item": 264, "Description": '37413 Women’s Jacket', "FrozenQuantity": 2, "ScannedQuantity": null, "Category": 'Golf Bags', "SubCategory1": 'Kids', "SubCategory2": '14' },
          { "Item": 265, "Description": '37414 Women’s Gloves', "FrozenQuantity": 1, "ScannedQuantity": null, "Category": 'Golf Balls', "SubCategory1": 'Men', "SubCategory2": '14' }
        ]
      },
      {
        outletId: 1,
        outletName: 'SPA OUTLET',
        itemsList: [
          { "Item": 266, "Description": '37415 Polo Tshirt', "FrozenQuantity": 3, "ScannedQuantity": null, "Category": 'Golf Bags', "SubCategory1": 'Women', "SubCategory2": '15' },
          { "Item": 267, "Description": '37416 Summer Shirt', "FrozenQuantity": 2, "ScannedQuantity": null, "Category": 'Golf Balls', "SubCategory1": 'Men', "SubCategory2": '14' },
          { "Item": 268, "Description": '37417 Summer Shirt', "FrozenQuantity": 2, "ScannedQuantity": null , "Category": 'Golf Apparels', "SubCategory1": 'Adults', "SubCategory2": '12' },
          { "Item": 269, "Description": '37418 Women’s Gloves', "FrozenQuantity": 7, "ScannedQuantity": null, "Category": 'Golf Bats', "SubCategory1": 'Men', "SubCategory2": '12'  },
          { "Item": 263, "Description": '37403 Vintage Hood', "FrozenQuantity": 5, "ScannedQuantity": null, "Category": 'Golf Apparels', "SubCategory1": 'Women', "SubCategory2": '12'  },
          { "Item": 264, "Description": '37413 Women’s Jacket', "FrozenQuantity": 2, "ScannedQuantity": null, "Category": 'Golf Apparels', "SubCategory1": 'Kids', "SubCategory2": '13'  },
          { "Item": 265, "Description": '37414 Women’s Gloves', "FrozenQuantity": 1, "ScannedQuantity": null, "Category": 'Golf Bats', "SubCategory1": 'Kids', "SubCategory2": '13'  }
        ]
      }

    ];
    return inventoryData;
  }

}
