import { TransControlAuthorizedConfig } from './authorized-configuration.interface';
import { PermissionResource } from '../ui-controls.interface';
import { ItemListHeaderConfiguration, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AuthoriztionKey } from '../authorization.enum';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';

export const TransCreateAuth: TransControlAuthorizedConfig = ( TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource): TransactionConfiguration => {
        try {
            const transactionData: TransactionConfiguration = TransactionData;
            transactionData.ViewTranscationConfig.HeaderConfiguration.ActionButtonConfig.forEach((buttonModel: AgilysysButtonModel) => {
                const defaultButtonType = buttonModel.buttonType;
                if (permissionResource.Name === 'CreateRequisitionTemplate') {
                    if (buttonModel.buttonKey === 'newtemplate' && !permissionResource.IsActive) {
                        buttonModel.buttonType = buttonModel.buttonType + ' d-none';
                    }
                } else {
                    buttonModel.buttonType = ( permissionResource.IsActive ) ? buttonModel.buttonType : buttonModel.buttonType + ' d-none';
                }                
            });
            if ( transactionData.ViewTranscationConfig.hasOwnProperty('IsRowNotClickable')) {
                transactionData.ViewTranscationConfig.IsRowNotClickable = ( permissionResource.IsActive ) ? false : false;
            }
            transactionData.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach(breadCrumb => {
                if (breadCrumb.Type === 'dropdown' && breadCrumb.DataOption.length > 0) {
                    breadCrumb.DataOption.forEach(data => {
                        if (data.hasOwnProperty('AuthorizedKey') && data.AuthorizedKey === permissionResource.Name) {
                            data.Enable = permissionResource.IsActive;
                        }
                    });
                }
            });
            return null;
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

   