import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'restrictText'
})
export class RestrictTextPipe implements PipeTransform {

  sample:any;
  transform(inputArray: any[],enableKey:boolean,more:any,value?: any, args?: any): any {
   inputArray.forEach(element => {

      if(element.description.length > 46){
        element.showdesc = true;
        element.moreText = more;
      }
    });
    return inputArray;
  }

}
