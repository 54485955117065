export const CUSTOM_FEE_RECEIPTNAME = "Custom Fee";
export interface CustomFeeAPIModel{
    id: Number,
    code:string,
    name: string,
    value: Number,
    isPercentage: boolean,
    availableDays: Days,
    startTime: string,
    endTime: string,
    linkedItemId: 1,
    isDeleted?: false,
    filter?: CustomFeeFilter
}

export interface ItemSearchRequest {
    itemType: number;
    searchKey: string;
    requestId: string;
}
export interface ItemSearchBaseResponse {
    id: number;
    externalPOSId: number;
    itemNumber: string;
    itemDescription: string;
}

export interface ItemSearchResponse {
    requestId: string;
    retailItems: ItemSearchBaseResponse[];
}
export enum Days {
	Sunday = 1,
	Monday = 2,
	Tuesday = 4,
	Wednesday = 8,
	Thursday = 16,
	Friday = 32,
	Saturday = 64
}

export interface CustomFeeUIModel{
    id: Number,
    name: string,
    value: Number,
    isPercentage: boolean,
    availableDays: Days,
    startTime: Date
    endTime: Date,
    startDate: Date
    endDate: Date,
    linkedItemId: 1,
    isDeleted?: false
}

export interface CustomFeeFilter
{
    startDate: Date,
    endDate: Date
}

export enum modes{
    create = 1,
    edit = 2
}