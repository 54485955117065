import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AgilysysButtonModel, CallBackData, OptionValue } from './interface/button-interface';

@Component({
  selector: 'lib-agilysys-button',
  templateUrl: './agilysys-button.component.html',
  styleUrls: ['./agilysys-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysButtonComponent implements OnInit{
  searchContainerWidth: number;
  @Input() agilysysButtonModel: AgilysysButtonModel[];
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor() { }

  /**
   * @method eventAction(button, i)
   * @input params button, i
   * @description eventAction buttton call back to instance
   */
  eventAction(button: AgilysysButtonModel , i: number): void{
     try{
      if ( button && button.hasOwnProperty('buttonCallBack') ){
          const callbackData = {} as CallBackData;
          callbackData.buttonIndex = i;
          callbackData.buttonValue = button;
          if (callbackData.buttonValue.buttonKey === 'null' || callbackData.buttonValue.buttonKey === null) {
            // this.trigger.openMenu();
            const IdName = `${button.buttonType}-`+ i; 
            const getButtonName = document.getElementById(IdName);
            if( getButtonName ) {
              getButtonName.click();
            }
            this.openMatmenu(null, i);
          } else {
            button.buttonCallBack(callbackData);
          }
      }
     } catch (error) {
       console.error(error);
     }
  }
  /**
   * @method openMatmenu(event)
   */
  openMatmenu(event: any, index: number): void{
    try{
      this.searchContainerWidth = document.getElementsByClassName('lib-agilysys-button')[index]['offsetWidth'];
      const matOption = document.getElementsByClassName('agilysys-mat-option')[0];
      if (matOption) {
        matOption['style'].position = 'relative';
        matOption['style'].right = `-9px`;
        matOption['style'].top = `5px`;
      }
      if (event) {
        event.stopPropagation();
      }
    }catch (error) {
      console.error(error);
    }
  }

  /**
   * @method selectedOption(button, option)
   */

  selectedOption(button: AgilysysButtonModel, option: OptionValue, index: number): void{
    if ( button && button.hasOwnProperty('buttonCallBack') ){
      button.displayName = option.value;
      button.buttonKey = option.key;
      const callbackData = {} as CallBackData;
      callbackData.buttonIndex = index;
      callbackData.buttonValue = button;
      button.buttonCallBack(callbackData);
    }
  }

  ngOnInit(): void {
  }

}
