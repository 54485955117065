import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { AgilysysNomathPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-nomath-popup/agilysys-nomath-popup.component';
import { FormFieldType } from 'src/app/eatecui/source/setup-master/shared/interface/form-interface/fieldType.enum';
import { FieldEdmType } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { DetailsBuilderData, FormConfiguration, GridFetchFields, NomathDetailConfig, NoMathPopUpConfig, TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety, NoMathCalculation, PatchValueReassign } from './create-transaction-config.interface';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { UnitTypes } from 'src/app/eatecui/source/shared/enum/global.enum';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { FormType } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';
import { BaseUnitConversion } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const NomathCalculation: NoMathCalculation = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    dialog: MatDialog,
    NoMathData: any,
    GridApi: any,
    transactionService: TransactionService,
    FormData?: FormGroup,
    actionKey?: string,
    commonService?: CommonService
): any => {
    try {
        if (TransactionCollection.hasOwnProperty('ActionConfig') &&
            TransactionCollection.ActionConfig.length > 0) {
            const noMathUniqueId = ( NoMathData.hasOwnProperty('Id') &&  NoMathData.Id !== 0 ) ? NoMathData.Id : NoMathData['RowId'];
             // Duplicate Ingredient Id will be available so used RowId
            TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math').forEach(e => e.Id = noMathUniqueId );    
            const noMathPopUpConfig = {} as NoMathPopUpConfig;
            noMathPopUpConfig.PopupButtonConfig = TransactionCollection.ActionConfig.filter(
                x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupButtonConfig'];
            noMathPopUpConfig.PopupButtonConfig.forEach((buttonModel: AgilysysButtonModel) => {
                buttonModel.buttonCallBack = function (value: CallBackData) {
                    console.log(value, noMathPopUpConfig.PopupFormConfig);
                    constratctMathEngineCalculation(noMathPopUpConfig, TransactionCollection, NoMathData, value, dialog, GridApi, commonService);
                    transactionService.enableButton(TransactionCollection, FormData);
                };
            });
            noMathPopUpConfig.PopupHeader = (NoMathData['Name']) ? NoMathData['Name'] : '-';
            noMathPopUpConfig.NomathQuantityDisplay = `Entered Quantity (${NoMathData['UnitCode']})`;
            noMathPopUpConfig.NoMathQuantityValue = ( NoMathData['Qty'] && NoMathData['Qty'] !== '') ? NoMathData['Qty'] : 0;
            noMathPopUpConfig.NoMathFormData = [];
            const detailsBuilderDataName = {} as DetailsBuilderData;
            detailsBuilderDataName.OdataKey = 'Qty';
            detailsBuilderDataName.DisplayName = `Entered Quantity (${NoMathData['UnitCode']})`;
            detailsBuilderDataName.EdmType = FieldEdmType.EDMSTRING;
            detailsBuilderDataName.FieldType = FormFieldType.TEXT;
            detailsBuilderDataName.Name = 'Qty';
            detailsBuilderDataName.Value = ( NoMathData['Qty'] && NoMathData['Qty'] !== '') ? NoMathData['Qty'] : 0;
            detailsBuilderDataName.Nullable = false;
            detailsBuilderDataName.DisableProperty = true;
            detailsBuilderDataName.Hide = true;
            noMathPopUpConfig.NoMathFormData.push(detailsBuilderDataName);
            if (NoMathData.hasOwnProperty('Units') && NoMathData['Units'].length > 0) {
                const BaseUnitCode = NoMathData['Units'].filter(x => x.UnitTypeId === UnitTypes.BaseUnit)[0].UnitCode;
                NoMathData['Units'].forEach((units: any) => {
                    const unitsDetailsBuilderDataName = {} as DetailsBuilderData;
                    unitsDetailsBuilderDataName.OdataKey = `${units['UnitTypeName']}`;
                    unitsDetailsBuilderDataName.DisplayName = `${units['UnitTypeName']}(` + `${units['UnitCode']})`;
                    unitsDetailsBuilderDataName.EdmType = FieldEdmType.EDMSTRING;
                    unitsDetailsBuilderDataName.FieldType = FormFieldType.TEXT;
                    unitsDetailsBuilderDataName.Name = units['UnitCode'];
                    unitsDetailsBuilderDataName.Value = '';
                    unitsDetailsBuilderDataName.Nullable = false;
                    unitsDetailsBuilderDataName.DisableProperty = false;
                    unitsDetailsBuilderDataName.Hide = false;
                    const conversionValue = units.Conversion ? units.Conversion : 0;
                    const formatedValue = parseFloat(conversionValue.toString()).toFixed(commonService.GetVisibleNumberLength());
                    unitsDetailsBuilderDataName.Hint = 
                    `1${units['UnitCode']} = ${formatedValue} ${units.UnitTypeId !== 1 ? BaseUnitCode : ''}`;
                    noMathPopUpConfig.NoMathFormData.push(unitsDetailsBuilderDataName);
                });
            }
            const FieldTYpe = [];
            noMathPopUpConfig.NoMathFormData.forEach((BuilderData: DetailsBuilderData) => {
                const formBuilderType = {} as FormType;
                formBuilderType.type = 'number';
                formBuilderType.label = BuilderData.DisplayName;
                formBuilderType.name = BuilderData.OdataKey;
                formBuilderType.hide = BuilderData.Hide;
                formBuilderType.fieldFormaters = {
                    EnableDecimalFormatter: true,
                    WholeNumberLength: commonService.GetWholeNumberLength(),
                    VisibleDecimal: commonService.GetVisibleNumberLength(),
                    FocusDecimal: commonService.GetFocusNumberLength()
                }
                formBuilderType.fieldType = {
                    'SingleData': {
                        'name': BuilderData.OdataKey,
                        'label': BuilderData.DisplayName,
                        'value': BuilderData.Value,
                        'disbaledProperty': BuilderData.DisableProperty,
                        'hintLabel': BuilderData.Hint,
                        'FieldValidation': [
                            { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'},
                        ]
                    }
                };
                FieldTYpe.push(formBuilderType);
            });
            const actionData = TransactionCollection.ActionConfig.filter( x => x.ActionKey === 'No Math')[0];
            const formConfiguration = {} as FormConfiguration;
            formConfiguration.DisplayName = 'No Math';
            formConfiguration.FormFields = FieldTYpe;
            formConfiguration.Key = 'NoMath';
            formConfiguration.Name = 'NoMath';
            noMathPopUpConfig.PopupFormConfig = formConfiguration;
            noMathPopUpConfig.NomathDetails = actionData.ActionPopupConfig.PopupInputData['NomathDetails'];
            actionData.ActionPopupConfig.PopupInputData = noMathPopUpConfig;
            if (actionKey !== 'nomath-editor') {
                dialog.open(AgilysysNomathPopupComponent, {
                    width: '70%',
                    height: '80%',
                    data: TransactionCollection,
                    disableClose: true
                });
            }
            return noMathPopUpConfig;
        }
    } catch (Error) {
        console.error('Error occurred FetchGridProperties', Error);
    }
};


function constratctMathEngineCalculation(
    noMathPopUpConfig: NoMathPopUpConfig,
    TransactionCollection: TransactionConfiguration,
    NoMathData: any, value: CallBackData, dialog: MatDialog, GridApi, commonService: CommonService): void {
    try {
        switch (value.buttonValue.buttonKey) {
            case 'confirm':
                if (TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].
                    ActionPopupConfig['PopupInputData']['FormGroupData'].valid) {
                        dialog.closeAll();
                        const mathFieldValue = TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0]
                            .ActionPopupConfig['PopupInputData']['FormGroupData'].value;
                        const mathCalculateQuantity = calculateMathNomathData(mathFieldValue, NoMathData);
                        const noMathUniqueId = (NoMathData.hasOwnProperty('Id') && NoMathData.Id !== 0) 
                            ? NoMathData.Id : NoMathData['RowId'];
                         // Duplicate Ingredient Id will be available so used RowId
                        NoMathData['Qty'] = mathCalculateQuantity ? parseFloat(mathCalculateQuantity).toFixed(6) : parseFloat('0').toFixed(6);
                        const detailConfig = {} as NomathDetailConfig;
                        detailConfig.NoMathRowId = noMathUniqueId;
                        const QtyValue = parseFloat(mathFieldValue['Qty']).toFixed(commonService.GetVisibleNumberLength());
                        detailConfig.NoMathDetailHeader = `${QtyValue} ` + `${NoMathData['UnitCode']}`;
                        detailConfig.NoMathDetailData = [];
                        for (const property in mathFieldValue) {
                            if (mathFieldValue[property]) {
                                const UnitData = NoMathData['Units'];
                                if (UnitData.length > 0) {
                                    const mathUnits = UnitData.filter(x => x['UnitTypeName'] === property)[0];
                                    if (mathUnits) {
                                        const unitsObject = { MathType: '', MathText: '' };
                                        unitsObject['MathType'] = (mathFieldValue[property] >= 0) ? 'Added' : 'Subtracted';
                                        const symbol = (unitsObject['MathType'] === 'Added') ? '+' : '' ;
                                        const MathValue = parseFloat(mathFieldValue[property]).toFixed(commonService.GetVisibleNumberLength());
                                        unitsObject['MathText'] = `<span class='no-math-type'>` + `${ unitsObject['MathType'] }</span>` + `<span class='math-${unitsObject['MathType']}'> ` + `${symbol} ` + `${MathValue}` + ` (${mathUnits['UnitCode']})</span> ` + `<span class='math-unit-name'> in ${mathUnits['UnitTypeName']}</span>`;
                                        detailConfig.NoMathDetailData.push(unitsObject);
                                    }
                                }
        
                            }
        
                        }
                        if (GridApi) {
                            GridApi.api.refreshCells();
                        }
                        TransactionCollection.ActionConfig.filter(
                        x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['NomathDetails'].push(detailConfig);
                    } else {
                        TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].
                    ActionPopupConfig['PopupInputData']['FormGroupData'].markAllAsTouched();
                    }
                break;
            case 'reset':
                const FormData = TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig['PopupInputData']['FormGroupData'];
                for (const property in FormData.controls) {
                    if ( property !== 'Qty' &&  FormData['controls'][property].value !== '' ) {
                        FormData['controls'][property].setValue('');
                    }
               }
                TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupButtonConfig'].forEach((buttonModel: AgilysysButtonModel ) => {
                      if (buttonModel.buttonKey === 'confirm') {
                        buttonModel.displayProperity = true;
                      }
                });
                break;
                case 'cancel':
                    dialog.closeAll();
                    TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupButtonConfig'].forEach((buttonModel: AgilysysButtonModel ) => {
                          if (buttonModel.buttonKey === 'confirm') {
                            buttonModel.displayProperity = true;
                          }
                    });
                    break;
            default:
            // code block
        }
    } catch (error) {
        console.error(error);
    }
}

function calculateMathNomathData(MathFieldValue: any, NomathData: any): any {
   try {
    const actualQuantity = parseFloat(MathFieldValue['Qty'].toString());
    let calulateValue = 0;
    let unitConversion =  0;
    for (const property in MathFieldValue) {
         if ( property !== 'Qty' &&  MathFieldValue[property] !== '' ) {
            // Converting to Pack size
            NomathData['Units'].forEach(element => {
                // && element.UnitTypeName !== 'Recipe Unit'
                if (element.UnitTypeName === property) {
                    unitConversion = element.Conversion;
                }
                // Commented this becoz recipe conversion is coming as 1/ recipe conversion
                // so commented in UI
                // else if (property === 'Recipe Unit') {
                //     unitConversion = BaseUnitConversion / element.Conversion;
                // }
            });
            // Convert All BaseUnit Qty To Corresponding Unit Qty
            calulateValue += parseFloat( MathFieldValue[property].toString())  
            * parseFloat( unitConversion !== 0 ? unitConversion.toString() : NomathData['Conversion'].toString());
         }
       }
       const CurrentConversion = NomathData.Units ? NomathData.Units.
           filter(x => x.UnitTypeId === NomathData.UnitTypeId)[0].Conversion : 1;
       const formattedcalulateValue = calulateValue !== 0 ? calulateValue / CurrentConversion : 0;
       return (formattedcalulateValue + actualQuantity);
   } catch (error) {
       console.error(error);
   }
}
