import { Directive, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
@Directive({
  selector: "[textmask]"
})

//USAGE
//Eg: <input [maskDisable]="DisableValidation" textmask="99:59" />
// textmask = "99:59" will produce values from 00:00 to 99:59
// textmask can be "(9999)" or "99.99" or "9:99:00" etc.
export class TextMaskDirective {

  constructor(private control: NgControl) {
  }
  @Input() textmask: string;
  @Input() maskDisable: boolean;

@HostListener('input', ['$event']) oninput(event) {
    let e = event;
    if (this.maskDisable) {
        return;
    }
    let mask = this.textmask;
    let fullValue = e.target.value;
    if (e.inputType != "deleteContentBackward") {
        event.currentTarget.value = this.appendFormat(fullValue.replace(/\D/g,''),mask);
        let originalvalue = event.currentTarget.value;
       this.control.control.setValue(originalvalue);
    }
  }

  appendFormat(value:string, format:string){
    if (!value && value == "") {
        return "";
    }
    let userInput = value.toString();
    let returnVal:string ="";
    let indexOfUserValue:number = 0;
    if (format && format == "") {
        return value;
    }
    userInput = (userInput.length > 1 && userInput[0] === '0' && format[1] === '0' ? userInput.replace(/[0]/, '') : userInput);
    for (let i = 0; i < format.length; i++) {
        const char = format[i];
        let charCode = char.toString().charCodeAt(0);
        const IsFormatNumber:boolean = (charCode === 57);
        if (!IsFormatNumber) {
           returnVal = returnVal+format[i];
        }else{
            if (userInput[indexOfUserValue]) {
                returnVal = returnVal+userInput[indexOfUserValue];
                indexOfUserValue++;
            }else{
                break;
            }
        }
    }

    return returnVal;
  }


}
