import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { pipe, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { ViewYieldSetupComponent } from '../view-yield-setup/view-yield-setup.component';
import { ActionMode, API, UI } from '../dynamic-yielding.model';
import { DynamicYieldingGridBusiness } from './dynamic-yirlding-grid.business';
import { cloneDeep } from 'lodash';
import { DynamicYieldingListBusiness } from '../dynamic-yielding-list/dynamic-yielding-list.business';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { SPAScheduleBreakPoint } from 'src/app/shared/globalsContant';

@Component({
  selector: 'app-dynamic-yielding-grid',
  templateUrl: './dynamic-yielding-grid.component.html',
  styleUrls: ['./dynamic-yielding-grid.component.scss'],
  providers: [DynamicYieldingGridBusiness, DynamicYieldingListBusiness],
  encapsulation: ViewEncapsulation.None
})
export class DynamicYieldingGridComponent implements OnInit,OnChanges {
  pricingSkeleton: UI.YieldingSkeleton[] = [];
  pricingSkeletonClone: UI.YieldingDetails[] = [];
  headerOptions: UI.GridHeader[];
  captions: any;
  @Input() isViewOnly: boolean;
  @Input() menuFilter: UI.MenuFilter;
  @Output() actionEmitter = new EventEmitter();
  type = UI.PricingRuleCategory;
  selectedService: string;
  gridType: UI.GridType; // Day or Week
  slotDifference: number;
  @ViewChild('pricingSection') pricingSection: ElementRef;
  pricingSectionWidth: number;
  gridNature = UI.GridType;
  gridAPIData: any;
  showSlide: boolean = false;
  viewData: UI.ViewPricingData;
  gridStartTime: string;
  gridEndTime: string;
  @Input() isDpViewOnly = false;

  @Input('gridOptions')
  set gridrOption(value) {
    if (value) {
      this.gridType = value.gridType;
      this.slotDifference = value.slotDifference;
      this.gridStartTime = value.gridStartTime;
      this.gridEndTime = value.gridEndTime;
      this.generateSkeleton(value);
    }
  }

  @Input('filteredService')
  set selectService(value) {
    if (value) {
      this.selectedService = value.viewValue;
    }
  }

  @Input('gridData')
  set gridDataValue(value) {
    if (value && value.length > 0) {
      this.gridAPIData = value;
      this.mapPricingToGrid(value);
    }else{
      this.pricingSkeleton.map(ps => ps.yielding = [])
      this.pricingSkeletonClone = []
    }
  }

  constructor(private dialog: MatDialog, public localization: SpaLocalization, private business: DynamicYieldingGridBusiness, private utils: SpaUtilities,private listBusiness: DynamicYieldingListBusiness) { }

  ngOnInit(): void {
    this.captions = this.localization.captions;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.pricingSectionWidth = this.pricingSection?.nativeElement?.offsetWidth;
    });
  }
  generateSkeleton(headerData) {
    this.headerOptions = [];
    this.pricingSkeleton = [];
    this.headerOptions = [...this.business.generateDateHeaderOption(headerData.gridType, headerData.gridStartDate, headerData.gridEndDate)];
    this.pricingSkeleton = [...this.business.generateTimeColumn(headerData.gridStartTime, headerData.gridEndTime, headerData.slotDifference)];   
  }

  mapPricingToGrid(dataFromAPI: UI.YieldingDetails[]) {
    this.pricingSkeleton = [...this.business.mapToGrid(this.pricingSkeleton, dataFromAPI, this.slotDifference, this.gridType, this.gridStartTime, this.gridEndTime, true)];
    this.pricingSkeleton = [...this.pricingSkeleton];    
    this.pricingSkeletonClone = cloneDeep(dataFromAPI);
    this.filterGrid();
  }

  editPrice(event, e) {
    let mode = e.isOverride ? ActionMode.EditOverride : ActionMode.Edit;
    console.log(mode);
    this.actionEmitter.emit({
      mode: mode,
      obj: e,
      from: 'slot',
      gridType: this.gridType
    });
  }

  copyPrice(event, e){
    this.actionEmitter.emit({
      mode: ActionMode.Copy,
      obj: e,
      gridType: this.gridType
    });
  }

  deletePrice(event, e) {
    this.actionEmitter.emit({
      mode: ActionMode.Delete,
      obj: e,
      gridType: this.gridType
    });
  }

  viewAllPrice(event, element) {
    // this.viewData = {
    //   startDate: '07/10/2024',
    //   endDate: '07/16/2024',
    //   pricingDetails: [
    //     {
    //       timeRange: '09:00 - 10:00',
    //       startTime: '09:00',
    //       endTime: '10:00',
    //       rules: [{
    //         id: 1,
    //         ruleCategoryId: 1,
    //         pricingCondition: 1,
    //         pricingConditionValue: "10",
    //         isIncrement: true,
    //         isPercent: true,
    //         pricingStrategyValue: 10,
    //         stopAvailability: false
    //       },
    //       {
    //         id: 2,
    //         ruleCategoryId: 2,
    //         pricingCondition: 2,
    //         pricingConditionValue: "12",
    //         isIncrement: false,
    //         isPercent: true,
    //         pricingStrategyValue: 12,
    //         stopAvailability: true
    //       },
    //       {
    //         id: 3,
    //         ruleCategoryId: 3,
    //         pricingCondition: 2,
    //         pricingConditionValue: "14",
    //         isIncrement: false,
    //         isPercent: true,
    //         pricingStrategyValue: 12,
    //         stopAvailability: false
    //       }],
    //       services: [{
    //         id: 1,
    //         name: 'Face Massage',
    //         baseRate: '100',
    //         priceTypes: [{
    //           id: 1,
    //           name: 'Regular',
    //           baseRate: '100',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 2,
    //           name: 'Member',
    //           baseRate: '110',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 3,
    //           name: 'VIP',
    //           baseRate: '120',
    //           updatedRate: '130',
    //         },
    //         {
    //           id: 4,
    //           name: 'Corporate',
    //           baseRate: '130',
    //           updatedRate: '140',
    //         },
    //         {
    //           id: 5,
    //           name: 'Referral',
    //           baseRate: '100',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 6,
    //           name: 'Company',
    //           baseRate: '110',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 7,
    //           name: 'Reserved',
    //           baseRate: '120',
    //           updatedRate: '130',
    //         },
    //         {
    //           id: 8,
    //           name: 'Taxed',
    //           baseRate: '130',
    //           updatedRate: '140',
    //         }]
    //       },
    //       {
    //         id: 2,
    //         name: 'Body Massage',
    //         baseRate: '100',
    //         priceTypes: [{
    //           id: 1,
    //           name: 'Regular',
    //           baseRate: '100',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 2,
    //           name: 'Member',
    //           baseRate: '110',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 3,
    //           name: 'VIP',
    //           baseRate: '120',
    //           updatedRate: '130',
    //         },
    //         {
    //           id: 4,
    //           name: 'Corporate',
    //           baseRate: '130',
    //           updatedRate: '140',
    //         }]
    //       }]
    //     },
    //     {
    //       timeRange: '11:00 - 12:00',
    //       startTime: '09:00',
    //       endTime: '10:00',
    //       rules: [{
    //         id: 1,
    //         ruleCategoryId: 1,
    //         pricingCondition: 1,
    //         pricingConditionValue: "10",
    //         isIncrement: true,
    //         isPercent: true,
    //         pricingStrategyValue: 10,
    //         stopAvailability: false
    //       },
    //       {
    //         id: 2,
    //         ruleCategoryId: 2,
    //         pricingCondition: 2,
    //         pricingConditionValue: "12",
    //         isIncrement: false,
    //         isPercent: true,
    //         pricingStrategyValue: 12,
    //         stopAvailability: false
    //       },
    //       {
    //         id: 3,
    //         ruleCategoryId: 3,
    //         pricingCondition: 2,
    //         pricingConditionValue: "14",
    //         isIncrement: false,
    //         isPercent: true,
    //         pricingStrategyValue: 12,
    //         stopAvailability: false
    //       }],
    //       services: [{
    //         id: 1,
    //         name: 'Head Massage',
    //         baseRate: '100',
    //         priceTypes: [{
    //           id: 1,
    //           name: 'Regular',
    //           baseRate: '100',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 2,
    //           name: 'Member',
    //           baseRate: '110',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 3,
    //           name: 'VIP',
    //           baseRate: '120',
    //           updatedRate: '130',
    //         },
    //         {
    //           id: 4,
    //           name: 'Corporate',
    //           baseRate: '130',
    //           updatedRate: '140',
    //         }]
    //       },
    //       {
    //         id: 2,
    //         name: 'Back Massage',
    //         baseRate: '100',
    //         priceTypes: [{
    //           id: 1,
    //           name: 'Regular',
    //           baseRate: '100',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 2,
    //           name: 'Member',
    //           baseRate: '110',
    //           updatedRate: '120',
    //         },
    //         {
    //           id: 3,
    //           name: 'VIP',
    //           baseRate: '120',
    //           updatedRate: '130',
    //         },
    //         {
    //           id: 4,
    //           name: 'Corporate',
    //           baseRate: '130',
    //           updatedRate: '140',
    //         }]
    //       }]
    //     }]
    // };

    this.dialog.open(ViewYieldSetupComponent, {
      panelClass: 'ag_dialog--lg',
      data: {
          gridObj: element,
          setupForm: null
      }
    });
  }

  override(value) {
    let selectedDateObj = this.pricingSkeleton.filter(x => x[value.key]);
    this.actionEmitter.emit({
      mode: ActionMode.SingleDateOverride,
      obj: {
        selectedDateObj,
        value
      }
    });
  }

  closeSlide(e) {
    this.showSlide = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.menuFilter &&
      changes.menuFilter.currentValue &&
      !changes.menuFilter?.firstChange
    ) {
      this.filterGrid();
    }
  }

  filterGrid()
  {
    this.pricingSkeleton.map(ps => ps.yielding = [])
    const dataClone = cloneDeep(this.pricingSkeletonClone);
    const filtered = dataClone.map(data=>{
      data.pricingDetails = data.pricingDetails.filter((pd) => {
        return (         
          this.filterMenuList(pd)
        );
      });
      return data;
    }).filter(d => d.pricingDetails.length > 0)
    if(filtered && filtered?.length > 0){
      this.pricingSkeleton = [...this.business.mapToGrid(this.pricingSkeleton, filtered, this.slotDifference, this.gridType, this.gridStartTime, this.gridEndTime, false)];
      this.pricingSkeleton = [...this.pricingSkeleton];
    }
  }

  /**
   * filter menu list
   * @param pds
   * @returns
   */
  filterMenuList(pricingDetails: any) {    
    if (this.menuFilter && pricingDetails) {
      const pds = {...pricingDetails};
      const startTime =
        this.menuFilter.startTime !== ""
          ? this.localization.LocalizedTimeToISODateTime(
              this.menuFilter.startTime
            )
          : "";
      const endTime =
        this.menuFilter.endTime !== ""
          ? this.localization.LocalizedTimeToISODateTime(
              this.menuFilter.endTime
            )
          : "";
      const serviceStartTime = this.localization.LocalizedTimeToISODateTime(
        pds.startTime
      );
      const serviceEndTime = this.localization.LocalizedTimeToISODateTime(
        pds.endTime
      );
      const daysOfWeek: [string, number[]] =
                    this.listBusiness.getDaysOfWeek(pds.applicableDays);
      return (
        (this.menuFilter.daysOfWeek.length > 0
          ? daysOfWeek[1].some((d) => this.menuFilter.daysOfWeek.includes(d))
          : true) &&
        (pds.rule.length === 0 || pds.rule.some(
          (r) =>
            (Number(r.conditionValue) >= this.menuFilter.rangeStart &&
            Number(r.conditionValue) <= this.menuFilter.rangeEnd) &&
            (this.menuFilter.availability.length > 0
              ? this.menuFilter.availability.includes(
                  r.dynamicAvailability ? 1 : 0
                )
              : true)
        )) &&
        (startTime === ""
          ? true
          : serviceStartTime.GetDateTimeFromString().getTime() >=
            startTime.GetDateTimeFromString().getTime()) &&
        (endTime === ""
          ? true
          : serviceEndTime.GetDateTimeFromString().getTime() <=
            endTime.GetDateTimeFromString().getTime())
      );
    }
    return true;
  }
}