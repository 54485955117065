import { ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';
import { FormaterConfig } from './formater-config';

export const DecimalFormater: FormaterConfig = ( FieldKey: string, FieldValue: string, HeaderEntity: any, DetailEntity: any): string => {
    try {
        const INICurrencyValue = sessionStorage.getItem('INICurrencyValue');
        const decimalValue = INICurrencyValue ? parseInt(INICurrencyValue, 10) : sessionStorage.getItem('appsetting') ? 
        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength : ConversionValue.ConversionTwo;
        if (FieldValue !== null && FieldValue !== undefined && FieldValue !== '' 
        && !isNaN(parseFloat(FieldValue)) && isFinite(parseFloat(FieldValue))) {
            return parseFloat(FieldValue).toFixed(decimalValue);
        } else {
            return parseFloat('0').toFixed(decimalValue);
        }
    } catch (error) {
        console.error(error);
    }
};