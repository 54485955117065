import { Component, OnInit, ViewEncapsulation, Input, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { popupConfig } from '../shop.modals';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../shop-dialog-popup/shop-dialog-popup.component';

@Component({
  selector: 'app-shop-payment',
  templateUrl: 'shop-payment-component.html',
  styleUrls: ['./shop-payment-component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShopPaymentComponent implements OnInit, OnChanges, AfterViewInit {
  datRec: any;
  popupConfigs: popupConfig;
  showPayment: any;
  cashPayment: any;
  cashBalance: any;
  fullPaymentValidateBoolean: boolean = true;
  isZeroAmount: boolean = false;
  captions: any;
  @Input() datainput: any;
  @ViewChild("cashReceived", { static: false }) cashReceivedField: ElementRef;
  floatLabel: string;


  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public dialogRef: MatDialogRef<ShopDialogPopUp>) {
    this.cashPayment = this.fb.group({
      'amountpaid': ['', Validators.required],
      'cashreceived': ['', Validators.required],
      'balancedue': ['', Validators.required]
    })
    this.captions = this.localization.captions.shop;
    this.floatLabel = this.localization.setFloatLabel;
  }

  /**
   * @method function
   * @function save
   * @param None
   * @description save method data finally save the all data
   */
  save() {
    console.log(this.cashPayment);
  }

  /**
 * @method function
 * @function receivedCash
 * @param None
 * @description received cash method functionality
 */

  receivedCash() {
    let totalAmt = Number(this.datainput.totalAmountInt.customToFixed());
    let balance = totalAmt - this.localization.currencyToSQLFormat(this.cashPayment.controls.cashreceived.value);
    this.cashPayment.controls.balancedue.setValue(balance)    
    this.cashBalance = balance;
    if (this.localization.currencyToSQLFormat(this.cashPayment.controls.cashreceived.value) >= totalAmt) {
      this.fullPaymentValidateBoolean = true;
    } else {
      this.fullPaymentValidateBoolean = false;
    }

  }
  ngOnChanges() {
    const amount = this.datainput.totalamount;
    this.cashPayment = this.fb.group({
      'amountpaid': [{ value: amount, disabled: true }, Validators.required],
      'cashreceived': [amount, Validators.required],
      'balancedue': ['', Validators.required]
    });
    this.receivedCash();
  }

  ngAfterViewInit(){
    if (!this.datainput.patchTotalAmountInCashPopup) {
      this.cashPayment.controls.cashreceived.setValue(this.localization.localizeCurrency(0));      
    }
    setTimeout(() => {
      if (this.cashReceivedField?.nativeElement) {
        this.cashReceivedField.nativeElement.focus();
        this.cashReceivedField.nativeElement.select();
        this.receivedCash();
      }
    }, 200);
  }


  ngOnInit() {
    this.isZeroAmount = Number(this.localization.currencyToSQLFormat(this.datainput.totalamount)) == 0;
    if(this.isZeroAmount)
    {
      this.cashPayment.controls.cashreceived.setValue(this.datainput.totalamount);
      this.cashPayment.controls.cashreceived.disable();
      this.cashBalance = 0;
    }
  }

  closeDialog() {
    const totalAmt = Number(this.datainput.totalAmountInt.customToFixed());
    const AmtValid = (this.localization.currencyToSQLFormat(this.cashPayment.controls.cashreceived.value) >= totalAmt);
    if (!(this.cashPayment.valid && this.fullPaymentValidateBoolean) || !AmtValid) return;
    let cashAmounRequested = Number(this.datainput.totalAmountInt.customToFixed());
    let cashReceived       = this.localization.currencyToSQLFormat(this.cashPayment.controls.cashreceived.value);
    let CashbalanceDue     = cashReceived - cashAmounRequested;
    this.dialogRef.close({paymentStaus: 'cashpaid', amtRequested: cashAmounRequested, amtReceived: cashReceived, amtBalanceDue: CashbalanceDue});
  }

}
