import { ChangeDetectorRef, Component, Inject, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { AddNewPopModel } from '../model/pop-up.interface';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
@Component({
  selector: 'app-agilysys-add-new-popup',
  templateUrl: './agilysys-add-new-popup.component.html',
  styleUrls: ['./agilysys-add-new-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysAddNewPopupComponent implements OnInit, OnChanges {
  agilysysAddNewButton: AgilysysButtonModule[];
  homeGroup: FormGroup;  
  constructor(
    public dialogRef: MatDialogRef<AgilysysAddNewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddNewPopModel,
    public formBuilder: FormBuilder,
    private translateService: TranslateService,
    private ref: ChangeDetectorRef
  ) {
    this.homeGroup = this.formBuilder.group({});
    const _that = this;
    if (this.data.FormGroupData) {
      if (this.data.FormFields.length > 0) {
        this.data.FormGroupData = JSON.parse(JSON.stringify(this.data.FormGroupData));
        this.data.FormFields.forEach(formData => {
          const FormFieldName = formData.fieldType.SingleData.name;
          if (formData.type === 'datepicker' ) {
            const FieldName = FormFieldName === 'Expiredate' ? 'ExpireDateUI' : formData.fieldType.SingleData.name;
            const convertedDate = Formaters.globalDateFormaters(this.data.FormGroupData[FieldName]);
            this.data.FormGroupData[FormFieldName] = convertedDate;
          }
        });
      }
      setTimeout(function () {
        _that.homeGroup.patchValue(_that.data.FormGroupData);
        Object.entries(_that.data.FormGroupData).map(item => {
          console.log('s');
          if (!_that.homeGroup.controls[item[0]]) {
            _that.homeGroup.addControl(item[0], new FormControl(item[1]));
          }
        });
        _that.homeGroup.patchValue(_that.data.FormGroupData);
        _that.data.FormFields.forEach(formDatas => {
          if (formDatas.fieldFormaters) {
            CommonService.triggerBlurEvent(formDatas.fieldType.SingleData.name);
          }
        });
      }, 1000);
    }
  }

  addButtonClick(callBackData: CallBackData) {
    const homeGroupValue = this.homeGroup.value;
    if ( homeGroupValue.hasOwnProperty('EffectiveDate')  && homeGroupValue.hasOwnProperty('Expiredate')) {
       if ( homeGroupValue.Expiredate !== '' && homeGroupValue.Expiredate !== undefined && homeGroupValue.Expiredate !== null) {
          const EffectiveDate = homeGroupValue.EffectiveDate;
          const Expiredate = homeGroupValue.Expiredate;
          if ( EffectiveDate > Expiredate) {
            this.homeGroup.controls['Expiredate'].setErrors({ invaliddaterange: true, incorrect: true, message: 'Expire date should be greater than Effective date.'});
          } else {
            this.homeGroup.controls['Expiredate'].setErrors({ invaliddaterange: false, incorrect: false, message: 'Expire date should be greater than Effective date.'});
            this.homeGroup.controls['Expiredate'].updateValueAndValidity();
          }
       }
    }
    if (this.homeGroup.valid) {
      this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
    } else {
      this.homeGroup.markAllAsTouched();
    }
  }


  getReturnFormValue(value: any) {
    // console.log('FormValue', value.form);
  }

  ngOnChanges(): void {
    const _that = this;
    // if (this.data.FormGroupData) {
    //      setTimeout(function () { _that.homeGroup.patchValue(this.data.FormGroupData); }, 1000);
    // }
  }

  refreshFormFields() {
    this.data.FormFields = [...this.data.FormFields];
    setTimeout(() => {
      this.ref.detectChanges();      
    }, 100);
  }

  ngOnInit(): void {
    this.agilysysAddNewButton = [
      {
        displayProperity: false,
        displayName: this.translateService.instant('Common.Cancel'),
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: this.translateService.instant('Common.' + (displayName(this.data.FormType))),
        buttonKey: 'Add',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.addButtonClick(callBackData);
        }
      }
  ];

  function displayName(FormType) {
      switch (FormType) {
        case 'add':
          return 'Add';
        case 'replace':
          return 'Replace';
        case 'save':
          return 'Save';
        case 'edit':
            return 'Save' ;
        case 'update':
          return 'Update';
        case 'general':
          return 'Ok';
        default:
        return 'Saveas';
      }
    }
  }

}
