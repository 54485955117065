
import { GridHeaderConfig, TransactionConfiguration } from '../transaction.interface';
import { HideColumn } from './create-transaction-config.interface';
import _ from 'lodash';

export const HideCurrencyFieldsForRguest: HideColumn = (
    TransactionCollection: TransactionConfiguration, transactionFrom: string, selectedTransaction?: string): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig) {
            let rguestTenantId = true;
            // const checkTenant = sessionStorage.getItem('RGuestTenantId');
            // if ( checkTenant && JSON.parse(checkTenant)) {
            //     rguestTenantId = true;
            // }
            const DisableCurrencyFields = ['CurrencyCode', 'ExchangeRate', 'UnitCostInForigenCurrency', 'ValueInForeignCurrency',
            'ChargesInForeignCurrency', 'UnitCostInFC', 'AmountInFC', 'ChargesInFC', 'TaxAmountInFC1', 'TaxAmountInFC2'];
            const EnableCurrencyFields = ['UnitCost', 'Valued', 'Charges'];
            const CopyNewDisableCurrencyFields = ['CurrencyCode', 'valueinforeigncurrency', 'exchangerate', 'amountinfc'];
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
            GridHeaderDefination.forEach((header: GridHeaderConfig) => {
                if (transactionFrom === 'processpo' || transactionFrom === 'EditPO') {
                    if (header.field === 'SupplierName' || header.field === 'ExchangeRate') {
                        header.editable = false;
                    }
                }
                if (rguestTenantId && DisableCurrencyFields.findIndex(x => x.toLowerCase() === header.field.toLowerCase()) !== -1) {
                    header.hide = true;
                    header.editable = false;
                }
                if (rguestTenantId && EnableCurrencyFields.findIndex(x => x.toLowerCase() === header.field.toLowerCase()) !== -1) {
                    header.hide = false;
                    header.editable = true;
                }
            });  
            
            TransactionCollection.CopyAsNewConfig.CopyNewItemListConfig.ItemListGridConfig.GridHeaderDefination.forEach((copyHeader: GridHeaderConfig) => {
                if (rguestTenantId && CopyNewDisableCurrencyFields.findIndex(x => x.toLowerCase() === copyHeader.field.toLowerCase()) !== -1) {
                    copyHeader.hide = true;
                    copyHeader.editable = false;
                }
            });

            if (TransactionCollection.CreateTranscationConfig) {
                let rguestTenantId = false;
                const checkTenant = sessionStorage.getItem('RGuestTenantId');
                if ( checkTenant && JSON.parse(checkTenant)) {
                    rguestTenantId = true;
                }
                const EnableFields = ['TransactionCurrencyId', 'ExchangeRate'];
                TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.forEach(fields => {
                    if (rguestTenantId && EnableFields.findIndex(x => x === fields.Name) !== -1) {
                        fields.DisableProperty = true;
                        fields.Hide = true;
                        fields.DisableRequest = false;
                    }
                });
            }
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred NonEditableGridColumn', Error);
    }
};