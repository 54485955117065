import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";

export class MultiPackItems extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.MultiPackItems,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllMultiPacks(),
            dropDownFilterName: this.localization.captions.reports.Types,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }

    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) }, {'pDate': this.printedDate},
                { "pPageBreak": this.pageBreakToggle}, {"pFilterCaption":this.getFilterCaption},{"pPropertyName":this.propertyName}];
    }

    protected formURIParams(): ReportParams[] {
        return [];
    }

    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        let multiPackItemFilterId: number = this.reportFormGrp.controls['customFilterFormGrp'].value.radioFilter;

        return {
            StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            MultiPackIds: this.selectedFilterOptions.map(s => s.id),
            IsAllMultiPacks: 0 == multiPackItemFilterId,
            IsExpirationDateRange: 1 == multiPackItemFilterId,
            IsSoldDate: 2 == multiPackItemFilterId,
            IsAllByGuests: 3 == multiPackItemFilterId,
            IsOutstandingOnly: 4 == multiPackItemFilterId
        };
    }

}


