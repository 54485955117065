import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { AppService } from '../app-service/AppService';
import { SelectOutletModalBusiness } from './select-outlet-modal.business';

@Component({
  selector: 'select-outlet-modal',
  templateUrl: './select-outlet-modal.component.html',
  styleUrls: ['./select-outlet-modal.component.scss'],
  providers: [SelectOutletModalBusiness, AppService]
})
export class SelectOutletModalComponent implements OnInit {
  public arrOutlet: any[]=[];
  public captions: any = this.localization.captions.bookAppointment;
  public defaultOutlet: any;
  public isSaveDisable: boolean = true;
  floatLabel: string;


  constructor(public dialogRef: MatDialogRef<SelectOutletModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private defaultOutletBusiness: SelectOutletModalBusiness, public localization: SpaLocalization) {
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.getOutlets();
  }
  async getOutlets() {
    this.defaultOutletBusiness.getOutlets((res) => {
      if (res && res?.successStatus && res.errorCode === 0)
        this.arrOutlet = res.result;
    });
  }
  onOutletSelection() {
    if (this.defaultOutlet && this.isSaveDisable) {
      this.isSaveDisable = false;
    }
  }
  onSave() {
    let obj = {
      isSave: true,
      outlet: this.defaultOutlet
    }
    this.dialogRef.close(obj);
  }
}
