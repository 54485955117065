import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Localization } from 'src/app/common/localization/localization';
import { AgAddressConfig, AgAddressField, GoogleAddressOutput } from '../../Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';

@Component({
  selector: 'app-ag-address',
  templateUrl: './ag-address.component.html',
  styleUrls: ['./ag-address.component.scss']
}) 
export class AgAddressComponent  {

  contactForm: UntypedFormGroup;
  customClass: string;
  errorMessage: string;
  controlName: string;
  arrayName: string;
  placeHolder: string;
  address: UntypedFormArray;
    addresslength = 0;

    placeHolderId: string;
    errorMessageId: string;
  @ViewChild('placesRef', { static: false }) placesRef: GooglePlaceDirective;
  captions: any;
  isAddressDisabled: boolean;
  isAddressLine1Required: boolean;
  floatLabel: string;
  automationId: string;
  @Input('inputs')
  set inputOptions(value: AgAddressConfig) {
    if(value) {
      this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: '';
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.contactForm = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.address;
    this.arrayName = value.formArrayName ? value.formArrayName : 'address';
    this.address = this.contactForm.get(this.arrayName) as UntypedFormArray;
    this.isAddressDisabled = value.disabled ? value.disabled : false;
    this.isAddressLine1Required = value.isAddressLine1Required ? value.isAddressLine1Required : false;
    this.errorMessageId = value.errorMessageId;
    this.placeHolderId = value.placeHolderId;
    this.automationId = value.automationId;
    }
    
  }
  @Input('values')
  set addressValues(value: string[]) {
    this.SetEditValue(value);
    if(value && value.length > 0){
      this.addressFieldDisabler(this.isAddressDisabled);
    }
  }

  @Input() isViewOnly = false;
  @Output() onAddressChange: EventEmitter<GoogleAddressOutput> = new EventEmitter<GoogleAddressOutput>();
    constructor(private fb: UntypedFormBuilder, private localization: Localization, public dialog: MatDialog) {
    this.captions = this.localization.captions;
  }

  ngOnChanges(){
    if(this.arrayName){
    this.addressFieldDisabler(this.contactForm.get(this.arrayName).disabled);
    }
  }

  addressFieldDisabler(isDisable){
    let addressField = this.contactForm.get(this.arrayName);
    if(isDisable){
      this.isAddressDisabled = true;
      addressField['controls'].forEach(element => {
        element.disable();
      });
    }
  }

  bindAddressFromGoogle(res: any, IsAutoComplete?, index?: number) {
    if (IsAutoComplete) {
      this.updateAddressField(res.address_components, true, index);
      if (!res.status) {
        let addressConct =(res.address_components[0]&&res.address_components[0].long_name)+' '+(res.address_components[1]&&res.address_components[1].long_name);
        this.googleAutoCompleteAddressLineBinding(index,addressConct);
      }
      return;
    }
    if (res.status === 'OK') {
      if (IsAutoComplete) {
        this.updateAddressField(res.address_components, true, index);
      } else {
        this.updateAddressField(res.results[0].address_components, false, index);
      }
    }
    if (res.status === 'ZERO_RESULTS') {
      this.clearAddressFields();
    }
    if (res.status === 'OVER_QUERY_LIMIT') {
      //OVER_QUERY_LIMIT
    }
    if (!res.status) {
      let addressConct =(res.address_components[0]&&res.address_components[0].long_name)+' '+(res.address_components[1]&&res.address_components[1].long_name);
      this.googleAutoCompleteAddressLineBinding(index,addressConct);
    }
  }

  googleAutoCompleteAddressLineBinding(index,addressConct) {
    // google auto complete from search
    const addressFormArr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    // populating auto completed value from text box(work around for angular issue)

 
    const addressCtl: any = addressConct;
    const addressAutoPopulated: string = addressCtl;
    
    addressFormArr.at(index).patchValue({ addressDetails: addressAutoPopulated });
    const ctrl: any = addressFormArr.at(index);
    ctrl.controls.addressDetails.setErrors(null);
  }

  updateAddressField(add: AgAddressField[], IsAutoComplete: boolean, index?: number) {
    let country = '';
    let state = '';
    let city = '';
    let googlePostalCode: string;
    const addressFormArr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    if (add) {
      const ctrl: any = addressFormArr.at(index);
      ctrl.controls.addressDetails.setErrors(null);
    }   
    for (const address of add) {
      const element = address;
      if (element.types[0] === 'administrative_area_level_1') {
        state = element.long_name;
      }
      if (element.types[0] === 'locality') {
        city = element.long_name;
      }
      if (element.types[0] === 'country') {
        country = element.long_name;
      }
      if (element.types[0] === 'postal_code') {
        googlePostalCode = element.long_name;
      }
    }

    let chosenAddress: GoogleAddressOutput = {
      city,
      country,
      state,
      zipCode: googlePostalCode
    };
    this.onAddressChange.emit(chosenAddress);

    if ((this.contactForm.get('postalCode') && googlePostalCode === this.contactForm.get('postalCode').value) || IsAutoComplete) {
      const addressValue = `${(add[0] && add[0].long_name) ? add[0].long_name : ''} ${(add[1] && add[1].long_name) ?
        add[1].long_name : ''}`;
      addressFormArr.at(0).patchValue({
        addressDetails: addressValue
      });
      this.contactForm.patchValue({
        city,
        country,
        state,
        postalCode: googlePostalCode
      });
    } else {
      this.clearAddressFields();
    }
  }
  // Dynamic Address field addition Logic
  addAddressArray(addressDetails): UntypedFormGroup {
    return this.fb.group({
      addressDetails: [addressDetails, Validators.maxLength(255)]
    });
  }

  addAddress(addressDetails: any) {
    if (!this.isViewOnly) {
      this.address.push(this.addAddressArray(addressDetails));
      this.addresslength = this.address.length;
    }
  }

  removeAddress(index: number) {
    if (!this.isViewOnly) {
      this.contactForm.markAsDirty();
      this.address.removeAt(index);
      this.addresslength--;
    }
  }

  clearAddressFields() {
    this.contactForm.patchValue({
      city: '',
      country: '',
      state: ''
    });
  }

  SetEditValue(value) {
    this.addresslength = 0;
    this.address.controls.splice(1,value.length);
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] && value[i][this.controlName]) {
          if (i === 0) {
            this.address.controls[i].patchValue(value[i]);
          } else {
            this.address.push(this.addAddressArray(value[i][this.controlName]));
          }
          this.addresslength++;
        }
      }
    }
  }

    onControlClick() {
        if (document.designMode == 'on') {
            this.dialog.open(AgMenuEditComponent, {
                width: '700px',
                height: '700px',
                data: {
                    oldPlaceHolder: this.placeHolder,
                    oldErrorMessage: this.errorMessage,
                },
                disableClose: true
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.placeHolder = result.newplaceholder;
                    this.errorMessage = result.newErrorMessage;
                }
            });
        }
    }

}
