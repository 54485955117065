<div class="autocomplete-box" *ngIf="field && field.fieldType && field.fieldType.SingleData">
    <div [formGroup]="form">
        <mat-form-field appearance="fill" title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
            selectedLabel : formFieldToolTip}}" class="example-full-width" (click)="openPanel()">
            <mat-label>{{formFieldlabel}}</mat-label>
            <div class="mat-select-arrow-wrapper">
                <div class="mat-select-arrow"></div>
            </div>
            <input [required]="showRequired" type="text" [placeholder]="formFieldlabel" matInput [formControlName]="formFieldName"
                [matAutocomplete]="auto" readonly #inputAutoComplete />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" id="matAutoCompleteBox" (closed)="panelClosed()"
                class="auto-complete-box autocomplete-{{formFieldName}}" (optionSelected)='selectedOption()'>
                <div class="search-input-box" (click)="$event.stopPropagation()">
                    <input type="text" placeholder="Search" [(ngModel)]="searchtext"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="searchChange($event)" />
                    <!-- <i matSuffix class="icon-down"></i> -->
                </div>
                <div class="calc-option-height" *ngIf="field.fieldType.SingleData.options.length > 0">
                    <div class="h-100 single-select" *ngIf="field.fieldType.SingleData.enableSingleSelect">
                        <cdk-virtual-scroll-viewport itemSize="42" class="example-viewport" minBufferPx="1200" maxBufferPx="1200">
                            <mat-option *cdkVirtualFor="let option of field.fieldType.SingleData.options;trackBy:getUniqueId"
                                [value]="option.key" title="{{option.label}}">
                                {{option.label}}
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </div>
                    <div class="h-100 multi-select" *ngIf="!field.fieldType.SingleData.enableSingleSelect">
                        <cdk-virtual-scroll-viewport itemSize="42" class="example-viewport" minBufferPx="1200" maxBufferPx="1200">
                            <mat-option
                                *cdkVirtualFor="let option of field.fieldType.SingleData.options;trackBy:getUniqueId"
                                [value]="option.key">
                                <mat-checkbox (click)="$event.stopPropagation()" [checked]="option.ischecked"
                                title="{{option.label}}"  (change)="selectionChange($event, option)">{{option.label}}</mat-checkbox>
                            </mat-option>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </div>
                <div *ngIf="field.fieldType.SingleData.options.length === 0">
                    <mat-option [disabled]="true">
                        No results found.
                    </mat-option>
                </div>
                <!-- <mat-option *ngFor="let option of field.fieldType.SingleData.options;trackBy:getUniqueId" [value]="option.key">
                    {{option.label}}
                </mat-option> -->
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>