import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClassGroup } from '../../../shared/business/view-settings.modals';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { SPAConfig } from '../../../core/config/SPA-config';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse, popupConfig ,KeyValuePair} from '../../../shared/business/shared.modals';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-class-group',
  templateUrl: './class-group.component.html',
  styleUrls: ['./class-group.component.scss']
})
export class ClassGroupComponent implements OnInit {

  clickbutton: any;
  @Input() dataInput:any;
  @Input() popupConfigs: popupConfig;

  sgDataInput: any = [];
  FormGrp: UntypedFormGroup;
  blnReadOnly: boolean;
  maxListOrder: number;
  captions: any = this.spaConfig.captions;
  //placeholders variables
  tCode: string = this.spaConfig.captions.setting.classGroupCode;
  tDesc: string = this.spaConfig.captions.setting.classGroupDescription;
  tLstOrder: string = this.spaConfig.captions.setting.ListOrder;

  //validation error variables
  vCodeErr: string = this.spaConfig.captions.setting.MissingClassGroupCode;
  vDescErr: string = this.spaConfig.captions.setting.MissingClassGroupDescription;
  isUserAuthorized = true;
  isViewOnly=true;
  floatLabel:string;
  proceedButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(public dialogRef: MatDialogRef<ClassGroupComponent>,
     private Form: UntypedFormBuilder,
     private http: HttpServiceCall,
     public dialog: MatDialog,
     private vsBusiness: ViewSettingClientBusiness,
     private spaConfig: SPAConfig,
     public localization:SpaLocalization,
     private utils: SpaUtilities, private BP: BreakPointAccess) {
      this.floatLabel = this.localization.setFloatLabel;
  }
  onNoClick(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }
  ValidateBreakPoint():void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingClassGroup]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingClassGroup);
    if (this.isViewOnly) {
      this.utils.disableControls(this.FormGrp);
    }
  }
  validateSave() {
    return this.FormGrp.valid && this.FormGrp.dirty;
  }

  saveClassGroup() {
    this.proceedButton.disabledproperty = true;
    if (this.popupConfigs.operation == "create") {
      this.createClassGroup();
    } else if (this.popupConfigs.operation == "edit") {
      this.updateClassGroup();
    }
  }
  /*
  * Edit Service Group
  */
  updateClassGroup() {
    const _code = this.FormGrp.controls['code'].value;
    const _listOrder = this.FormGrp.controls['listOrder'].value;
    const _desc = this.FormGrp.controls['description'].value;
    if(_listOrder == '') {
      this.FormGrp.controls["listOrder"].setValue(this.maxListOrder);
    }
    const bodyObj: ClassGroup = { code: _code, listOrder: _listOrder, description: _desc }
    let patchJson = [];

    const patchData = this.utils.getEditedData(this.FormGrp, "code")
    patchJson = patchData.patchJson;
    let isClass=true;
    let keyValue: KeyValuePair = { key: "isClassGroup", value:  [isClass]};

    this.http.CallApiWithCallback<ClassGroup[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateServiceGrp",
      method: HttpMethod.Patch,
      uriParams: patchData.key,
      body: patchJson,
      showError: true,
      extraParams: [bodyObj],
      queryString: keyValue
    });
  
  }

  /*
  * Create Location
  */
  createClassGroup() {
    const _code = this.FormGrp.controls['code'].value;
    let _listOrder = this.FormGrp.controls['listOrder'].value;
    const _desc = this.FormGrp.controls['description'].value;
    if(_listOrder == '') {
      _listOrder = this.maxListOrder;
    }
    const bodyObj: ClassGroup = {
      code: _code.toUpperCase(), listOrder: _listOrder, description: _desc
    }
    let isClass=true;
    let keyValue: KeyValuePair = { key: "isClassGroup", value:  [isClass]};
    this.http.CallApiWithCallback<ClassGroup[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CreateServiceGrp",
      method: HttpMethod.Post,
      body: bodyObj,
      showError: true,
      extraParams: [bodyObj],
      queryString: keyValue
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateServiceGrp" || callDesc == "UpdateServiceGrp") {
      this.proceedButton.disabledproperty = false;
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateServiceGrp" || callDesc == "UpdateServiceGrp") {
      this.proceedButton.disabledproperty = false;
      if (error) {error["NewData"] = extraParams[0];}
    }
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      code: ['', [Validators.required, EmptyValueValidator]],
      description: ['', [Validators.required, EmptyValueValidator]],
      listOrder: ['']
    });
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.proceedButton = {
      type: 'primary',
      label: this.popupConfigs.operation == "create" ? this.captions.setting.save : this.captions.setting.update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.setting.cancel,
    }
    this.ValidateBreakPoint();
    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.captions.setting.save;
      this.blnReadOnly = false;
      this.maxListOrder = this.popupConfigs.maxListOrder;
      this.FormGrp.setValue({
        code: this.dataInput ? this.dataInput.code : '',
        listOrder: this.maxListOrder,
        description: this.dataInput ? this.dataInput.description : ''
      });
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.captions.setting.update;
      this.blnReadOnly = true;
      this.maxListOrder = this.dataInput.listOrder;
      this.FormGrp.patchValue(this.dataInput);
    }
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.proceedButton.disabledproperty = !(this.FormGrp.valid && this.FormGrp.dirty);
      this.proceedButton = { ...this.proceedButton };
    });
  }

}
