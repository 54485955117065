import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  buttonText: string;  
  constructor(
    public agyDynamicFormServices: AgyDynamicFormServices,
  ) { }

  ngOnInit(): void {
    this.buttonText = this.field.fieldType.SingleData.label
  }

  onButtonClick(): void {
    this.agyDynamicFormServices.formEventCallback(this.field,'button', this.form);
  }

}
