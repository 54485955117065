import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { PlanHeadGet, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from '@shared/constant/GlobalConstants';

export const PlanHeadConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService): any => {
    try {        
        const PostData = {} as PlanHeadGet;
        PostData.LocationIds = LocationIds;
        PostData.FetchAll = false;
        PostData.GetTotalRecCount = true;
        PostData.IsActiveOnly = IsActive;
        PostData.PlanPeriodIds = [];
        PostData.LookupField = '';
        PostData.SearchText = '';
        PostData.PlanPeriodLengthIds = [];
        PostData.Skip = 0;
        PostData.Take = commonService.GetLoadMoreCount();
        PostData.PlanTypeId = 2;
        PostData.totalCount = 0;
        PostData.userId = commonService.GetLoggedInUserId();
        PostData.siteId = commonService.GetLoggedInSiteId();
        PostData.orderByColumn = transactionService.
        setSortColumn(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        PostData.orderByDirection = transactionService.
        setSortDirection(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        PostData.FromDate = null;
        PostData.ToDate = null;
        return PostData;
    } catch (error) {
        console.error('Error occurred in PlanHeadConstruction', error);
    }
};

function formatDateFilter(DateValue, DateType: string) {
  try {
    let currentDate;
    if (DateType === 'ToDate') {
      currentDate = moment(DateValue).endOf('day').format(constants.PostDateTimeFormat);
    } else {
      currentDate =  moment(DateValue).startOf('day').format(constants.PostDateTimeFormat);
    }
    return currentDate;
  } catch (error) {
    console.error('Error occurred in formatDateFilter', error);
  }
}