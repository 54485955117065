import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {  Directive, Input, OnDestroy } from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { Subject, Subscription } from 'rxjs';

@Directive()
export abstract class BaseAGYSDateControl<Tdom, Tform> implements ControlValueAccessor, MatFormFieldControl<Tdom>, OnDestroy {


    @Input() formControlName;
    customFormGrp: UntypedFormGroup;
    containerFormControlValue: Tform;
    internalSetValueCompleted: boolean;
    isWriteValueChange: boolean;
    isOnLoadCompleted: boolean;
    private _disabled: boolean;
    valueChangeSubscription: Subscription;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
        this.updateControlComponent();
    }
    private _required = false;

    @Input()
    public get disabled(): boolean {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
        this.updateControlComponent();
    }

    constructor(public controlContainer: ControlContainer, public fb: UntypedFormBuilder) {
        this.initializeFormGroup();
    }


   
    abstract mapToForm(value: Tdom | null): Tform;
    abstract mapToDom(value: Tform): Tdom;
    protected initializeFormGroup() {
        this.customFormGrp = this.fb.group({
            agysControl: []
        });
    }
    protected subscribeToValueChanges() {
        this.value = this.customFormGrp.controls['agysControl'].value;
        if(this.customFormGrp.controls['agysControl'].dirty){
        this.onChange(this.value);
        this.onTouched(this.value);     
        }       
        this.isOnLoadCompleted = true;
    }
    abstract updateControlComponent();
    abstract value: Tdom;

    stateChanges: Subject<void> = new Subject<void>();

    protected onChange = (_: any) => { 
        //onChange
    };
    protected onTouched = (_: any) => { 
        //onTouched
    };
    writeValue(input: Tdom | null): void {
        this.isWriteValueChange = true;
        this.value = input;
        this.isWriteValueChange = false;
        if (!this.internalSetValueCompleted) {
            this.updateControlContainerValue(input);
        }
        this.internalSetValueCompleted = false;

    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }


    updateControlContainerValue(value: Tdom) {
        if (!this.formControlName) {
            alert("FormControlName is not set AGYS INPUT Control Error");
        }
        const parentCtl = this.controlContainer.control.get(this.formControlName);
        this.internalSetValueCompleted = true;
        this.containerFormControlValue = this.mapToForm(value);
        if (this.containerFormControlValue && parentCtl.value !== this.containerFormControlValue) {
            parentCtl.setValue(this.containerFormControlValue, { emitself: false });
        }
    }


    id: string;
    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;
    ngControl: NgControl;
    focused: boolean;
    empty: boolean;
    shouldLabelFloat: boolean;
    errorState: boolean;
    controlType?: string;
    autofilled?: boolean;
    setDescribedByIds(ids: string[]): void {
        throw new Error('Method not implemented.');
    }
    onContainerClick(event: MouseEvent): void {
        throw new Error('Method not implemented.');
    }


    ngOnDestroy(): void {
        if (this.valueChangeSubscription != undefined) {
            this.valueChangeSubscription.unsubscribe();
        }
        this.stateChanges.complete();
    }

}