import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import * as GlobalConst from 'src/app/shared/globalsContant';
import { ElasticTopExceptionDetails, ExceptionDetails } from "../../Models/logViewer.model";

@Injectable({
  providedIn: "root"
})
export class ExceptionBussines {
  captions;
  constructor(private localization: Localization, public http: HttpServiceCall) {
    this.captions = this.localization.captions;
  }

  async callAPIGetAllExceptions(filterData) {
    let uriParams = {
      fromDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      toDate:   this.localization.ConvertDateToISODateTime(filterData.todateValue)
    };
    let response = await this.http.CallApiAsync<ExceptionDetails>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetExceptionByDate",
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }

  async callAPIGetTopUserException(filterData) {
    let uriParams = {
      startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      endDate:   this.localization.ConvertDateToISODateTime(filterData.todateValue)
    };
    let response = await this.http.CallApiAsync<ElasticTopExceptionDetails>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetTopExceptionsByUsers",
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }

  async callAPIGetTopFrequentException(filterData) {
    let uriParams = {
      startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      endDate:   this.localization.ConvertDateToISODateTime(filterData.todateValue)
    };
    let response = await this.http.CallApiAsync<ElasticTopExceptionDetails>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetTopFrequentExceptions",
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }
}


