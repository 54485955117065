import { FormDisplayDisabledProperityRemapConfig } from './data-remap-config.interface';

export const FromDisplayDisabledRemap: FormDisplayDisabledProperityRemapConfig = 
( remapJson: Array<any>, FieldKey: string, DisplayName: string, DefaultDisabledProperity: boolean ): boolean => {
    try {
        let disabledProperity = DefaultDisabledProperity ? DefaultDisabledProperity : false;
        remapJson?.forEach((remap: any) => {
        //  const remapKey = Object.keys(remap)[0].toString();
        const remapKey = remap.Name.toString().toString();
         if ( FieldKey.toLowerCase() === remapKey.toLowerCase()) {
            const getMapAray = remap.UIFieldLookup;
            if ( getMapAray && getMapAray.length > 0 ) {
                getMapAray.forEach((element: any) => {
                    if ( element.Property_Key.toString().toLowerCase() === DisplayName.toString().toLowerCase()) {
                        disabledProperity = element.Disable;
                    }  
                 });
            }
         }
        });
        return disabledProperity;
    } catch (error) {
        console.error(error);
    }
};