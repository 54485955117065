import { InfiniteScrollItemListCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
  AddNewTypeConfig, HeaderConfiguration, LookupConfiguration,
  MappingItemListSearchUrl,
  SearchListHeaderConfig, SearchShowMoreConfig, TransactionConfiguration, TransactionLookupData
} from '../transaction.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { ItemListSearch } from '../view-transaction/view-configuration.interface';
import { FormGroup } from '@angular/forms';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { InventoryGroupId, InventoryGroupName, SearchCategoryId, SearchCategoryType } from 'src/app/eatecui/source/shared/enum/global.enum';

export const ItemListSPSearch: ItemListSearch = (
  httpService: HttpService,
  searchByKey: any,
  lookupForm: FormGroup,
  searchByType: AddNewTypeConfig,
  sysytemPreferenceService: SysytemPreferenceService,
  searchHeader: SearchListHeaderConfig[], TransactionData: TransactionConfiguration,
  searchShowMoreConfigs: SearchShowMoreConfig, selectedModule: string, selectedGroupName?: string, mappingSearchUrl ?: MappingItemListSearchUrl): any => {
  return new Promise(function (resolve, reject) {
    if (searchByKey && searchByType) {
      const endPointUrl = CONSTANT.ENDPOINTURL['InvertoryManagement'][mappingSearchUrl.MappingSearchUrlKey];
      const createRequest = new RequestEngine(true, []);
      const ScrollItemCount = sessionStorage.getItem('appsetting') ?
        JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid : constants.InfiniteScrollItemCount;
      const orderByColumn = null
      const sortDirection = 1
      const sortingData = new RequestPagination(orderByColumn, sortDirection, createRequest.FetchAll,
        createRequest.LocationIds);
      const requestPayload = sortingData.getPaginationPayload(0, 1000);
      requestPayload.SearchText = searchByKey;
      requestPayload['SearchByCategoryId'] = GetCategoryId(searchByType.Name);
      requestPayload['InventoryGroupCategoryId'] = GetIngredientsGroupId(selectedGroupName);
      requestPayload['IsFromMasterLimit'] = TransactionData && TransactionData.Name === 'PackageLimitation' ? true : false;
      requestPayload.LookupField = "Name";
      if (searchByType.Name === 'Packages') {
        requestPayload['LoadItemsfromPackages'] = true;
      }
      const responseData = httpService.PosthttpMethod(endPointUrl, requestPayload).toPromise();
      resolve(responseData);
    }
  });
};


/**
 * @method GetCategoryId
 * @input name search TypeName
 */
const GetCategoryId = (SearchType: string): number => {
  try {
    let categoryId = 1
    if (SearchType === SearchCategoryType.Packages) {
      categoryId = SearchCategoryId.Packages
    } else if (SearchType === SearchCategoryType.Class) {
      categoryId = SearchCategoryId.Classes
    } else if (SearchType === SearchCategoryType.Type) {
      categoryId = SearchCategoryId.Types
    }
    return categoryId;
  } catch (error) {
    console.error(error);
  }
}

/**
 * @method GetIngredientsGroupId
 * @input name search TypeName
 */
const GetIngredientsGroupId = (GroupName: string): number => {
  try {
    let GroupId = 1
    if (GroupName === InventoryGroupName.Inventory) {
      GroupId = InventoryGroupId.Ingredients
    } else if (GroupName === InventoryGroupName.Recipe || GroupName === InventoryGroupName.Recipes) {
      GroupId = InventoryGroupId.Recipe
    } else if (GroupName === InventoryGroupName.Product) {
      GroupId = InventoryGroupId.Product
    } else if (GroupName === InventoryGroupName.Supplier) {
      GroupId = InventoryGroupId.Supplier
    } else if (GroupName === InventoryGroupName.Customer) {
      GroupId = InventoryGroupId.Customer
    }
    return GroupId;
  } catch (error) {
    console.error(error);
  }
}