import { Component, OnInit } from '@angular/core';
import { AppointmentpopupService } from 'src/app/shared/service/appointmentpopup.service';
import {  DefaultGUID } from 'src/app/shared/globalsContant';
import * as _ from 'lodash';
import { SpaLocalization as Localization } from 'src/app/core/localization/spa-localization';
import { RetailUtilities as Utilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { Subscription } from 'rxjs';
import { PMSActiviyStatus } from 'src/app/shared/shared.modal';
import { ActivityStatus } from 'src/app/retail/shared/service/payment/payment-model';

@Component({
  selector: 'app-package-entitlements',
  templateUrl: './package-entitlements.component.html',
  styleUrls: ['./package-entitlements.component.scss']
})
export class PackageEntitlementsComponent implements OnInit {

  panelExpanded: boolean = true;
  multi: boolean = true;
  imageContent: any = {};
  guestData: any;
  stayData: any;
  captions: any;
  bookings: any = [];
  imgSubscription: Subscription;
  additionalGuests: any = [];
  ActivityStatus = PMSActiviyStatus;
  constructor(public aps: AppointmentpopupService, public localization: Localization, public utils: Utilities) {
    this.captions = this.localization.captions.bookAppointment;
  }

  ngOnInit() {
    const _that = this;
    this.imgSubscription = this.aps.clientImageObj.subscribe(x => {
      const imageObj = x;
      if (imageObj && imageObj.length > 0 && this.aps.resultExistingAppActivity &&
        this.aps.resultExistingAppActivity.guests && this.aps.resultExistingAppActivity.guests.length > 0 &&
        this.aps.resultExistingAppActivity.guests[0] == imageObj[0].imageReferenceId) {
        _that.imageContent = imageObj[0];
        _that.aps.clientImageUrl = _that.imageContent.contentType && _that.imageContent.data ?
          `data:${imageObj[0].contentType};base64,${imageObj[0].data}` :
          '';
        let temp = _that.aps.imageArray.find(arr => {
          return arr.referenceId === _that.imageContent.referenceId && arr.referenceType === _that.imageContent.referenceType;
        });
        if (_that.aps.imageArray.length === 0 || !temp) {
          _that.aps.imageArray.push(imageObj[0]);
        }
      } else {
        _that.imageContent = {};
      }
    });
    this.setData();
  }

  async setData() {
    if (this.aps.isEditAppointment && this.aps.resultExistingAppointment) {
      if (this.aps.resultExistingAppActivity) {
        if (this.aps.resultExistingAppActivity.linkedPMSActivityId && this.aps.resultExistingAppActivity.linkedPMSActivityId != DefaultGUID
          && this.aps.resultPkgEntitlementData && this.aps.resultPkgEntitlementData.stayActivity) {
          let PEData = this.aps.resultPkgEntitlementData;
          this.aps.associatedStayActivity = PEData.stayActivity;
          this.guestData = _.cloneDeep(PEData.stayActivity.additionalGuests.filter(x => x.isPrimaryGuest)[0]);
          this.stayData = PEData.stayActivity;
          if (this.stayData && this.stayData.pmsStayDetails) {
            this.stayData.pmsStayDetails = _.orderBy(_.cloneDeep(this.stayData.pmsStayDetails), 'date', 'asc');
          }
          if (this.stayData && this.stayData.pmsPackageDetails) {
            this.stayData.pmsPackageDetails = _.orderBy(_.cloneDeep(this.stayData.pmsPackageDetails), 'date', 'asc');
            this.stayData.pmsPackageDetails.forEach(element => {
              element.remaining = (this.ConvertStringToNumber(element.price) - element.usedAmount) < 0 ? 0 : this.ConvertStringToNumber(element.price) - element.usedAmount;
            });
          }

          if (PEData.associatedActivities && PEData.associatedActivities.length > 0) {
            this.formBookingsData(PEData.associatedActivities)
            this.additionalGuests = _.cloneDeep(PEData.stayActivity.additionalGuests.filter(x => !x.isPrimaryGuest));
          }
        }
      }
      
    }
  }

  formBookingsData(activities) {
    let spaBookings: any[] = [];
    let golfBookings: any[] = [];
    let otherBookings:any[] = [];
    activities.forEach(element => {
      if (element && element.interfaces && element.interfaces.filter(x => x.activityType == 'rG-Spa')[0]) {
        spaBookings.push(element)
      }
      else if (element && element.interfaces && element.interfaces.filter(x => x.activityType == 'rG-Golf')[0]) {
        golfBookings.push(element);
      }
      else {
        otherBookings.push(element);
      }
    });
    if (spaBookings.length > 0) {
      this.bookings.push(this.formSPABooking(spaBookings));
    }
    if (golfBookings.length > 0) {
      this.bookings.push(this.formGOLFBooking(golfBookings));
    }
  }

  formSPABooking(bookings) {
    return {
      title: this.captions.SpaAppointments,
      bookings: bookings.map(x => {
        return {
          location: x.activityDetail.activityLocation ? x.activityDetail.activityLocation : this.captions.Spa,
          activity: x.activityDetail.activityDescription, status: this.getSpaStatus(x.status),
          date: x.start, allowedOnPkg: true
        }
      })
    }
  }

  formGOLFBooking(bookings) {
    return {
      title: this.captions.TeeTimes,
      bookings: bookings.map(x => {
        return {
          location: x.activityDetail.activityLocation ? x.activityDetail.activityLocation : this.captions.Golf,
          activity: x.activityDetail.activityDescription, status: this.getGolfStatus(x.status),
          date: x.start, allowedOnPkg: true
        }
      })
    }
  }

  ngOnDestroy() {
    this.aps.associatedStayActivity = [];
    this.aps.stayGuestData = null;
    this.aps.resultExistingAppActivity = null;
    this.aps.resultPkgEntitlementData = null;
    this.imgSubscription.unsubscribe();
  }

  getSpaStatus(status: ActivityStatus) {
    switch (status) {
      case ActivityStatus.Reserved:
        return this.captions.Reserved;
      case ActivityStatus.CheckedIn:
        return this.captions.CheckedIn;
      case ActivityStatus.CheckedOut:
        return this.captions.CheckedOut;
      case ActivityStatus.NoShow:
        return this.captions.NoShow;
      case ActivityStatus.Cancelled:
        return this.captions.Cancelled;

      default:
        return this.captions.Unknown;
    }
  }

  getGolfStatus(status: ActivityStatus) {
    switch (status) {
      case ActivityStatus.Reserved:
        return this.captions.Booked;
      case ActivityStatus.CheckedOut:
        return this.captions.Paid;
      case ActivityStatus.NoShow:
        return this.captions.NoShow;
      case ActivityStatus.Cancelled:
        return this.captions.Cancelled;

      default:
        return this.captions.Unknown;
    }
  }

  ConvertStringToNumber(input: string) {
    return Number(input);
  }
}