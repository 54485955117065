import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewImageComponent implements OnInit {

  imgList: any;
  captions: any;
  selectedImageUrl: any;
  activeIndex = 0;
  @ViewChildren('img') widgets: QueryList<ElementRef>;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private localization: Localization,
    private dialogRef: MatDialogRef<ViewImageComponent>) {
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    this.imgList = this.data.imageList;
    if (this.imgList) {
      this.imgList = this.SanitizedImageUrls(this.imgList);
      this.selectedImageUrl = this.imgList && this.imgList[0]
    }
  }

  private SanitizedImageUrls(imageList) {
    let list = [];
    if (imageList && imageList.length) {
      list = imageList.filter(obj => obj.image).map(obj => {
        // const imageUrl = `data:${obj.contentType};base64,${obj.data}`;
        return obj.image;
      });
    }
    return list;
  }

  viewFullSize(arg, idx) {
    this.selectedImageUrl = arg;
    this.activeIndex = idx;
  }

  next() {
    if (this.activeIndex < (this.imgList.length - 1)) {
      this.activeIndex += 1;
    } else {
      this.activeIndex = 0;
    }
    this.scrollToElement();
  }

  previous() {
    if (this.activeIndex > 0) {
      this.activeIndex -= 1;
    } else {
      this.activeIndex = this.imgList.length - 1;
    }
    this.scrollToElement();
  }

  private scrollToElement(){    
    let scrollElement = this.widgets.find((el, idx)=> idx=== this.activeIndex)
    this.selectedImageUrl = this.imgList[this.activeIndex];
    scrollElement.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  close(){
    this.dialogRef.close();
  }
}
