import { Component } from '@angular/core';

@Component({
  selector: 'app-report-giftcards',
  templateUrl: './report-giftcards.component.html',
  styleUrls: ['./report-giftcards.component.scss']
})
export class ReportGiftcardsComponent  {
 

}
