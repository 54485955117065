import {Injectable} from '@angular/core';
import {SftpDataService} from 'src/app/common/dataservices/notification/sftp-data.service';
import {API, FtpProtocols, UI} from 'src/app/common/Models/sftp.model';
import {DropdownOptions} from 'src/app/common/Models/ag-models';
import { ActionTypeEnum, aligment, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { ButtonType, TableActions } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';


@Injectable()
export class SftpBusiness {
    public readonly captions : any;

    constructor(private localization : Localization, private _sftpDataService : SftpDataService, private utilities: CommonUtilities) {
        this.captions = this.localization.captions;
    }

    public async getSftpConfig(): Promise <UI.SFTPConnection[]> {
        const apiModel: API.SFTPConnection[] = await this._sftpDataService.getSFTPConfiguration();
        return apiModel;
    }

    public async getSftpConfigByProtocol(protocol : FtpProtocols): Promise <UI.SFTPConnection> {
        const apiModel: API.SFTPConnection = await this._sftpDataService.getSFTPConfigurationByProtocol(protocol);
        return apiModel;
    }

    public async createSftpConfig(sftpConfig): Promise <UI.SFTPConnection> {
        const apiModels: API.SFTPConnection = await this._sftpDataService.createSFTPConfiguration(this.apiMapper(sftpConfig));
        return apiModels;
    }

    public async updateSftpConfig(sftpConfig): Promise <UI.SFTPConnection> {
        const apiModels: API.SFTPConnection = await this._sftpDataService.UpdateSFTPConfiguration(this.apiMapper(sftpConfig));
        return apiModels;
    }

    public async checkSFTPConnection(sftpConfig): Promise <boolean> {
        return await this._sftpDataService.checkSFTPConnection(this.apiMapper(sftpConfig));
    }

    public async checkConnectionName(connectionName : string) : Promise<Boolean>
    {
        return await this._sftpDataService.checkConnectionName(connectionName);
    }

    public getFtpProtocols(): DropdownOptions[]{
        return Object.keys(FtpProtocols).filter(key => !isNaN(Number(key))).map(
            (o, index) => {
                const protocolOptions: DropdownOptions = {
                    id: index,
                    value: FtpProtocols[o],
                    viewValue: FtpProtocols[o]
                };
                return protocolOptions;
            }
        );
    }

    public apiMapper(formValues : any): API.SFTPConnection {
        const ftpConnection: API.SFTPConnection = {
            id: formValues.id,
            connectionName: formValues.connectionName,
            hostName: formValues.hostName,
            userName: formValues.userName,
            password: formValues.password,
            port: formValues.port,
            ftpProtocol: formValues.protocol && formValues.protocol?.id ? Number(formValues.protocol.id) : Number(formValues.ftpProtocol),
            remotePath: formValues.remotePath,
            isEnabled: formValues.isEnabled
        };
        return ftpConnection;
    }


    public getTableOptions(isViewOnly) {
      return {
          actions: [
              {
                  type: TableActions.edit,
                  disabled: isViewOnly
              },
              {
                  type: TableActions.delete,
                  disabled: isViewOnly
              },

          ],
          defaultsortingColoumnKey: 'room',
          showTotalRecords: false,
          defaultSortOrder: SorTypeEnum.asc,
          columnFreeze: {
              firstColumn: true,
              lastColumn: true
          },
          isDragDisabled: false,
          checkboxKey: 'checked',
          showDeleteMsg: true,
          ignoreSort: true,
          enablePagination: true,
          deleteMsgName: 'connectionName',
          deleteMsgKey: 'connectionName',
      };
  }


  public getHeaderOptions() {
    return [
          {
            key: 'connectionName',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_connectionName,
            sortingKey: 'connectionName',
            sorting: true,
            searchable: true
        },

        {
            key: 'hostName',
            displayNameId: 'tbl_hdr_hostName',
            displayName: this.captions.tbl_hdr_hostName,
            sortingKey: 'hostName',
            sorting: true,
            searchable: true
        },
        {
            key: 'remotePath',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_remotePath,
            sortingKey: 'remotePath',
            sorting: true,
            alignment: aligment.right
        },
        {
            key: 'port',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_port,
            sortingKey: 'port',
            sorting: true,
            alignment: aligment.right
        },
        {
            key: 'isEnabled',
            displayNameId: 'grid_hdr_IsActive',
            displayName: this.captions.grid_hdr_IsActive,
            searchable: false,
            sorting: false,
            sortingKey: 'isEnabled',
             templateName: ActionTypeEnum.toggle
        },
      {
            key: ActionTypeEnum.action,
            templateName: ActionTypeEnum.action,
            displayName: this.captions.tbl_hdr_actions,
            searchable: false
      }
    ];
}

    async getTableContent() {
        const apiModel: API.SFTPConnection[] = await this._sftpDataService.getSFTPConfiguration();
        return this.uiMapper(apiModel);
    }

    uiMapper(apiModels: API.SFTPConnection[]) : API.SFTPConnectionTableContent[] {
        const scheduleGrid:API.SFTPConnectionTableContent[] = [];
        apiModels.map((x, index) => {
            const schedule: API.SFTPConnectionTableContent = {
                connectionName: x?.connectionName,
                hostName: x?.hostName,
                userName: x?.userName,
                remotePath: x?.remotePath,
                port: x?.port,
                id: x?.id,
                password: x?.password,
                ftpProtocol : x?.ftpProtocol,
                isEnabled : { value: x.isEnabled, isDisabled: false }
            };
            scheduleGrid.push(schedule);
      });
      return scheduleGrid;
    }

    public  async deleteSetupConfig(id : number): Promise<boolean> {
        let header: string = this.captions.lbl_deletewarning + '\n' + this.captions.lbl_screen + ':';
        const title: string = this.captions.lbl_delete + " " + this.captions.lbl_sftp;
        var sftpLinkChecker : any = await this._sftpDataService.IsSftpLinkedToJobCustomConfiguration(id);
        if(sftpLinkChecker){
            let screens = [];
            if( sftpLinkChecker.item1){
                screens.push(this.captions.pg_title_jobSchedulerConfiguration);
            }
            if(sftpLinkChecker.item2){
                screens.push(this.captions.pg_title_jobScheduler);
            }
            this.utilities.showDependencyAlert(title, screens, header,ButtonType.Ok);
            return;
        }
        return  this._sftpDataService.deleteSFTPConfigLink(id);
    }

}
