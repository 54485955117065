import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewSettingClientBusiness } from '../business/view-settings.business';
import { PromptType, ButtonOptions } from '../../globalsContant';
import { UserAlerts } from '../../../core/config/alerts-config';
import { popupConfig } from '../../business/shared.modals';
@Component({
  selector: 'app-setting-dialog-common-popup',
  templateUrl: './setting-dialog-common-popup.component.html',
  styleUrls: ['./setting-dialog-common-popup.component.scss']
})
export class SettingDialogPopupCommonComponent implements OnInit {
  public test = true;
  datRec: any;
  popupConfigs: popupConfig;
  CloseClick: boolean;

  constructor(private userAlerts: UserAlerts,
    public dialogRef: MatDialogRef<SettingDialogPopupCommonComponent>,
    private vsBusiness: ViewSettingClientBusiness,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public viewContainerRef: ViewContainerRef) { }

  onNoClick(): void {
    if (this.vsBusiness.activeFormGroup && this.vsBusiness.activeFormGroup.dirty) {
      this.promptUser();
    } else {
      this.dialogRef.close();
    }
  }

  promptUser() {
    if (this.vsBusiness.activeFormGroup.dirty) {
      this.userAlerts.showPrompt(PromptType.UnsavedChanges, this.PopupCallback.bind(this));
    } else {
      this.dialogRef.close();
    }
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() === ButtonOptions.Yes) {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.datRec = this.data.datarecord;
    this.popupConfigs = this.data.popupConfig;
  }
}
