import { Injectable } from '@angular/core';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { TaxExemptCategoryDataService } from '../../../tax-exempt-category/tax-exempt-category.data.service';

@Injectable()
export class CreateTaxTypeBusiness {
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private localization: RetailLocalization, private taxExemptCategoryDataService: TaxExemptCategoryDataService) {
    this.captions = this.localization.captions;
  }

  GetActiveTaxExemptCategories() {
    return this.taxExemptCategoryDataService.getAllTaxExemptCategories(false).then(res => {
      return res?.map(c => {
        return {
          id: c.id,
          viewValue: c.name,
          value: c
        }
      })
    });
  }

}
