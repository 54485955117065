/*
  NOTES TO DEVELOPER
  *****This page for ui developement only, In case any change in this page without authurization will affect UI*****
 */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons, FieldArray } from '../../form-type.entity';
@Component({
    selector: 'lib-checkbox-single',
    templateUrl: './check-box-single.component.html',
    styleUrls: ['./check-box-single.component.scss']
})
export class CheckBoxSingleComponent implements OnInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    formBuilderIcons: FormIcons;
    fieldType: string;
    formFieldName: string;
    formFieldlabel: string;
    formArrayName: string;
    formFieldheader: any;
    constructor(public AgyDynamicFormService: AgyDynamicFormServices) {}
    fieldArrayCallback(e: FieldArray): void {
        this.field.fieldArray.push(e);
    }
    iconCallback(formArray: FormArray, formObject: FormGroup ): void {
        this.AgyDynamicFormService.addnewRow(formArray, formObject);
    }
    get isValid(): any { return this.form.controls[this.field.name].valid; }
    get isDirty(): any { return this.form.controls[this.field.name].dirty; }

    ngOnInit(): void {
        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.AgyDynamicFormService.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formFieldheader = formField.fieldHeader;
    }

    checkBoxChange(): void {
        this.AgyDynamicFormService.formEventCallback(this.field, 0, this.form );
    }
}
