import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import * as GlobalConst from 'src/app/shared/globalsContant';
import { BaseResponse } from "../shared.modal";
import { SystemConfig } from "../business/shared.modals";
import { SpaUtilities } from '../utilities/spa-utilities';
@Injectable(
  { providedIn: "root"}
)
export class LogTypeBussines {
  enableConfirmation: boolean;
  constructor( public http: HttpServiceCall,private utils: SpaUtilities) {
  }

  async callAPIGetAllLog(includeinactive) {
     const results = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllLogType",
      method: HttpMethod.Get,
      uriParams: { "isIncludeInactive": includeinactive }
    });
    return results.result;
  }

  async callAPICreateLog(logtype) {
   return await this.http.CallApiAsync<any[]>({
        host: GlobalConst.Host.spaManagement,
        callDesc: "CreateLogType",
        method: HttpMethod.Post,
        body: logtype
      });
  }

  async callAPIEditLog(logtype, id) {
    return await this.http.CallApiAsync<any[]>({
        host: GlobalConst.Host.spaManagement,
        callDesc: "UpdateLogType",
        method: HttpMethod.Put,
        body: logtype,
        uriParams: { "id": id }
      });
  }

  async callAPIUpdateLog(inputdata) {
   const logType: any = {
      logTypeInstance: inputdata.logTypeInstance,
      isActive: inputdata.isActive
    };
     await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "UpdateLogType",
      method: HttpMethod.Put,
      body: logType,
      uriParams: { "id": inputdata.id },
    });
  }

  async callAPIDeleteLog(id) {
    let deletestatus = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "DeleteLogType",
      method: HttpMethod.Delete,
      uriParams: {"id":id}
    });
    return deletestatus.result;
  }

  async GetSystemSetting() {
    this.utils.getConfiguration(this.successCallback.bind(this), this.errorCallback.bind(this), "Appointment", "CONFIRMATION_DIALOG");
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetConfiguration") {
      let res: SystemConfig = <any>result.result;
      this.enableConfirmation = res.value == "true" ? true : false;
    }
  }
  errorCallback<T>(): void {

    console.log('Error');

  }

async setValue()
{
   return this.GetSystemSetting().then(
    async (res)=>{
      return Promise.resolve(this.enableConfirmation);
    }
   );

}

}
