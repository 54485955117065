import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgilysysButtonsModule } from '../agilysys-buttons/agilysys-buttons.module';
import { AgilsysErrorPopComponent } from './agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysConfirmationPopupComponent } from './agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { AgilysysButtonModule } from 'src/app/eatecui/dist/agilysys-button';
import { AgilysysAddNewPopupComponent } from './agilysys-add-new-popup/agilysys-add-new-popup.component';
import { AgilysysGroupingPopupComponent } from './agilysys-grouping-popup/agilysys-grouping-popup.component';
import { GetIconName } from './pipes/get-Icon-name.pipe';
import { AgilysysDynamicformModule } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { EatecSharedModule } from 'src/app/eatecui/source/shared/shared.module';
import { MaterialModule } from '../material-module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AgilysysSuggestqtyPopupComponent } from './agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { AgilysysDetailExpandPopupComponent } from './agilysys-detail-expand-popup/agilysys-detail-expand-popup.component';
import { AgilysysPicturePopupComponent } from './agilysys-picture-popup/agilysys-picture-popup.component';
import { ConvertBaseSixtyFour } from '../setup-master/shared/pipes/convert-base64.pipe';
import { AgilysysLookupPopupModelComponent } from './agilysys-lookup-popup-model/agilysys-lookup-popup-model.component';
import { AgilysysDynamicGridModule } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { AgilysysattchedfilesPopupComponent } from './agilysys-attchedfiles-popup/agilysys-attchedfiles-popup.component';
import { AgilysysConfirmPostPopupComponent } from './agilysys-confirm-post-popup/agilysys-confirm-post-popup.component';
import { AgilysysNomathPopupComponent } from './agilysys-nomath-popup/agilysys-nomath-popup.component';
import { AgilysysPostPoComponent } from './agilysys-post-po/agilysys-post-po.component';
import { AgilysysPostedPoComponent } from './agilysys-posted-po/agilysys-posted-po.component';
import { AgilysysTempCheckComponent } from './agilysys-temp-check/agilysys-temp-check.component';
import { AgilysysPdfViewerComponent } from './agilysys-pdfviewer/agilysys-pdfviewer-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { IntermediateCheckPipe } from './pipes/intermediate-check.pipe';
import { AgilysysGridZoomPopupComponent } from './agilysys-grid-zoom-popup/agilysys-grid-zoom-popup.component';
import { AgilysysAssignBidsPopupComponent } from './agilysys-assign-bids-popup/agilysys-assign-bids-popup.component';
import { CronEditorModule } from 'cron-editor';
import { AgilysysGridPopupComponent } from './agilysys-grid-popup/agilysys-grid-popup.component';
import { AgilsysIframePopComponent } from './agilysys-iframe-popup/agilysys-iframe-popup.component';
import { SafePipe } from './pipes/safe.pipe';
import { AgilysysPrintPopupComponent } from './agilysys-print-popup/agilysys-print-popup.component';
import { AgilysysFbProductComponent } from './agilysys-fbproduct/agilysys-fbproduct.component';
import { AgilysysQueryListComponent } from './agilysys-querylist-popup/agilysys-querylist-popup.component';
import { AgilysysSessionTimeoutPopupComponent } from './agilysys-session-timeout-popup/agilysys-session-timeout-popup.component';
import { AgilysysCreateReqPopupComponent } from './agilysys-create-req-popup/agilysys-create-req-popup.component';
import { AgilysysSessionExpiredComponent } from './agilysys-session-expired/agilysys-session-expired.component';
import { AgilsysProgressPopComponent } from './agilysys-progress-popup/agilysys-progress-popup.component';
import { ProcessTransactionComponent } from './agilysys-process-transaction/process-transaction.component';
import { AgilysysApprovalSubmissionComponent } from './agilysys-approval-submission/agilysys-approval-submission.component';
import { AgilysysApprovalStatusPopupComponent } from './agilysys-approval-status-popup/agilysys-approval-status-popup.component';
// eslint-disable-next-line max-len
import { AgilysysPrintWorksheetPopupComponent } from './agilysys-print-worksheet-popup/agilysys-print-worksheet-popup.component';
import { AgilysysattachedfilesPopupComponent } from './agilysys-attached-files-popup/agilysys-attached-files-popup.component';
import { ImageTypePipe } from './pipes/file-type.pipe';
import { AgilsysActionPopComponent } from './agilysys-transaction-popup/agilysys-action.component';
import { AgilysysMasterValidationPopupComponent } from './agilysys-master-validation-popup/agilysys-master-validation-popup.component';
import { AgilysysImportPiPopupComponent } from './agilysys-import-pi-popup/agilysys-import-pi-popup.component';
import { AgilysysGridLookupPopupComponent } from './agilysys-grid-lookup-popup/agilysys-grid-lookup-popup.component';
import { AgilysysPrinterPopupComponent } from './agilysys-printer-popup/agilysys-printer-popup.component';
import { AgilysysPartialTransferComponent } from './agilysys-partial-transfer/agilysys-partial-transfer.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AgilysysButtonsModule,
        AgilysysButtonModule,
        AgilysysDynamicformModule,
        NgbPopoverModule,
        EatecSharedModule,
        MaterialModule,
        AgilysysDynamicGridModule,
        PdfViewerModule,
        NgxDocViewerModule,
        CronEditorModule],
    declarations: [
        AgilsysErrorPopComponent,
        AgilysysConfirmationPopupComponent,
        AgilysysAddNewPopupComponent,
        AgilysysGroupingPopupComponent,
        GetIconName,
        AgilysysSuggestqtyPopupComponent,
        AgilysysDetailExpandPopupComponent,
        AgilysysPicturePopupComponent,
        ConvertBaseSixtyFour,
        AgilysysLookupPopupModelComponent,
        AgilysysattchedfilesPopupComponent,
        AgilysysConfirmPostPopupComponent,
        AgilysysNomathPopupComponent,
        AgilysysConfirmPostPopupComponent,
        AgilysysPostPoComponent,
        AgilysysPostedPoComponent,
        AgilysysTempCheckComponent,
        AgilysysPartialTransferComponent,
        AgilysysPdfViewerComponent,
        IntermediateCheckPipe,
        AgilysysGridZoomPopupComponent,
        AgilysysAssignBidsPopupComponent,
        AgilysysGridPopupComponent,
        AgilsysIframePopComponent,
        SafePipe,
        AgilysysPrintPopupComponent,
        AgilysysFbProductComponent,
        AgilysysQueryListComponent,
        AgilysysSessionTimeoutPopupComponent,
        AgilysysCreateReqPopupComponent,
        AgilysysSessionExpiredComponent,
        AgilsysProgressPopComponent,
        ProcessTransactionComponent,
        AgilysysApprovalSubmissionComponent,
        AgilysysApprovalStatusPopupComponent,
        AgilysysPrintWorksheetPopupComponent,
        AgilysysattachedfilesPopupComponent,
        ImageTypePipe,
        AgilsysActionPopComponent,
        AgilysysMasterValidationPopupComponent,
        AgilysysImportPiPopupComponent,
        AgilysysGridLookupPopupComponent,
        AgilysysPrinterPopupComponent,
    ],
    exports: [AgilsysErrorPopComponent]
})
export class AgilysysPopupModule { }
