<section class="multifield" [formGroup]="form">
    <div formArrayName="field.name">
        <div class="group-filed" *ngFor="let comp of form.get(field.name)['controls']; let i=index">
             <mat-form-field >
                     <mat-label>Favorite food</mat-label>
                     <mat-select [formControlName]="field.name" [id]="field.name">
                     <mat-option *ngFor="let opt of field.options" [value]="opt.key">
                     {{opt.label}}
                     </mat-option>
                     </mat-select>
                     <mat-error *ngIf="!isValid && isDirty">{{field.label}} is required</mat-error>
                 </mat-form-field>
                 <mat-form-field class="golftextbox">
                         <input [attr.type]="field.type" [id]="field.name" [name]="field.name" [formControlName]="field.name" matInput placeholder="{{field.label}}">
                          <mat-error *ngIf="!isValid && isDirty">{{field.label}} is required</mat-error>
                 </mat-form-field>
                 <i class="material-icons" (click)="addRow()">add</i>
                 <i class="material-icons" (click)="removeRow()">remove</i>
        </div>
    </div>
   
 </section>