import { HttpMethod, HttpServiceCall, ServiceParamsAsync } from '../../shared/service/http-call.service';
import { Host } from '../../shared/globalsContant';
import { BaseResponse, appointment } from '../../shared/business/shared.modals';
import { Injectable } from '@angular/core';


enum TempHoldApiType {
    Create,
    Update,
    Delete
}

@Injectable()
export class AppointmentTempHoldBusiness  {

    private _appointment: appointment | appointment[];
    private _tempHoldId: number[];

    constructor(private http: HttpServiceCall) {

    }


    /**
     * This Method Creates Appointment Temp hold and retruns TempHold Object/Id
     * @param appointment Appointment Object
     */
    public async CreateAppointmentTempHold(appt: appointment): Promise<number[] | boolean> {
        try {
            this._appointment = appt;
            this.validateAppointment(TempHoldApiType.Create);
            return await this.tempHoldAPICall(TempHoldApiType.Create);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    /**
     * This Method Updates Appointment Temp hold and retruns TempHold Object/Id
     * @param appointment Appointment Object
     */
    public async UpdateAppointmentTempHold(appt: appointment[]): Promise<number[] | boolean> {
        try {
            this._appointment = appt;
            this.validateAppointment(TempHoldApiType.Update);
            return await this.tempHoldAPICall(TempHoldApiType.Update);
        } catch (e) {
            this.http.exceptionHandle(e);
       }
    }

    /**
    * This Method Deletes Appointment Temp hold and retruns boolean
    * @param appointment Appointment Object
    */
    public async DeleteAppointmentTempHoldAsync(id: number[]): Promise<number[] | boolean> {
        try {
            this._tempHoldId = id;
            this.validateAppointment(TempHoldApiType.Delete);
            return await this.tempHoldAPICall(TempHoldApiType.Delete);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
        
    }

    /**
     * This Method calls the API and returns result
     * @param Type - API Call Type
     */
    private async tempHoldAPICall(Type: TempHoldApiType): Promise<number[] | boolean> {
        let serviceParams: ServiceParamsAsync = this.formServiceParamsAsync(Type);
        let result: BaseResponse<number[] | boolean> = await this.http.CallApiAsync<number[] | boolean>(serviceParams);
        return result.result;
    }

    /**
     * Forms and returns ServiceParams
     * @param Type Api Call type
     */
    private formServiceParamsAsync(Type: TempHoldApiType): ServiceParamsAsync {
        const temp = (Type === TempHoldApiType.Update ? "UpdateTempHoldAppointment" : "DeleteTempHold");
        let callDesc: string = Type === TempHoldApiType.Create ? "CreateTempHoldAppointment" : temp;
        const temp1 = (Type === TempHoldApiType.Update ? HttpMethod.Put : HttpMethod.Delete);
        let method: HttpMethod = Type === TempHoldApiType.Create ? HttpMethod.Post : temp1;

        let serviceParams: ServiceParamsAsync = {
            callDesc: callDesc,
            host: Host.schedule,
            method: method
        }

        switch (Type) {
            case TempHoldApiType.Delete:
                serviceParams.body = this._tempHoldId;
                break;
            case TempHoldApiType.Create:
                serviceParams.body = this._appointment;
                serviceParams.uriParams = { isTherapistOverBook: false, isLocationOverBook: false };
                break;
            case TempHoldApiType.Update:
                serviceParams.body = this._appointment;
                break;
            default:
                break;
        }

        return serviceParams;
    }

    /**
     * This Method validates the appointment object and returns boolean
     * @param type Type of Temp hold API call - Create/Update/Delete
     * @param appointment Appointment Object
     */
    private validateAppointment(type: TempHoldApiType): boolean {
        let validity: boolean = true;
        switch (type) {
            case TempHoldApiType.Create:
                let appointmentCreate: appointment = <appointment>this._appointment;
                validity = appointmentCreate && appointmentCreate.appointmentDetail && appointmentCreate.appointmentDetail.id == 0
                break;
            case TempHoldApiType.Update:
                let appointmentsUpdate: appointment[] = <appointment[]>this._appointment;
                validity = appointmentsUpdate && appointmentsUpdate.length > 0 && !appointmentsUpdate.some(x => x.appointmentDetail.id === 0)
                break;
            case TempHoldApiType.Delete:
                validity = this._tempHoldId && this._tempHoldId.length > 0 && !this._tempHoldId.some(x => !x);
                break;
            default:
                break;
        }
        return validity;
    }

}