<button *ngFor="let button of agilysysButtonModel; let i = index" class="lib-agilysys-button {{button.buttonType}}"
  [ngClass]="{'agilysys-button-withicon': button | agilysysbutton, 'agilysys-icon-only':  !(button | agilysysicononly), 'agilysys-option':  button | agilysysoption }"
  [disabled]="button.displayProperity" (click)="eventAction(button, i)">
  <span *ngIf="button | agilysysbutton" class="agilysys-icon icon {{button.icon.iconName}}"></span>
  <span *ngIf="button.iconOption && button.iconOption.enableIcon === false && button.iconOption.isSuffix === false"
  matPrefix class="agilysys-icon-option-prefix icon {{button.iconOption.iconName}}"></span>
  <label class="agilysys-button-display-name" *ngIf="button | agilysysicononly">{{ button.displayName }}</label>
  <span *ngIf="button.iconOption && button.iconOption.enableIcon === false  && button.iconOption.isSuffix === true"
  matSuffix class="agilysys-icon-option-suffix icon {{button.iconOption.iconName}}"></span>
  <span *ngIf="button.count !== undefined" class="agilysys-count">{{button.count}}</span>
  <span id="{{button.buttonType}}-{{i}}" (click)='openMatmenu($event, i)' #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" *ngIf="button | agilysysoption"
    class="test agilysys-option-icon icon {{button.moreOption.optionIcon}}" [ngClass] = "button.displayProperity ? 'disable-option-icon' : ''"></span>
  <mat-menu class="agilysys-mat-option" xPosition="before" yPosition="below" #menu="matMenu">
    <div *ngIf="button && button.moreOption && button.moreOption.optionData && button.moreOption.optionData.length > 0">
      <button (click)="selectedOption(button, optionData, i)" *ngFor="let optionData of button.moreOption.optionData"
        mat-menu-item>
        <span *ngIf="optionData.iconName" class="agilysys-icon icon {{optionData.iconName}} more-option-icon"></span>
        {{optionData.value}}</button>
    </div>
  </mat-menu>
</button>