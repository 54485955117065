import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableHeaderOptions } from 'src/app/common/Models/ag-models';

@Injectable()
export class HistoryBusiness {

    captions : any;
    includeLockerAssignment: boolean;
    constructor(private localization : Localization) {
          this.captions = this.localization.captions;
    }

    getTableHeaderOptions(includeLockerAssignment: boolean): TableHeaderOptions[] {
      let tableHeaders: TableHeaderOptions[] = [
          {
              key: 'beginsAt',
              displayName: this.captions.tbl_hdr_beginAt,
              displayNameId: 'BeginsAt',
              sorting: true,
          },
          {
              key: 'endsAt',
              displayName: this.captions.tbl_hdr_endAt,
              displayNameId: 'EndsAt',
              sorting: true,
          },
          {
              key: 'lockerName',
              displayName: this.captions.lbl_lockerName,
              displayNameId: 'lbl_lockerName',
              sorting: true,
              searchable: true,
          },
          {
              key: 'rate',
              displayName: this.captions.lbl_rate + '(' + this.localization.currencySymbol + ')',
              displayNameId: 'lbl_rate',
              sorting: true,
          },
          {
              key: 'service',
              displayName: this.captions.service,
              displayNameId: 'service',
              sorting: true,
              searchable: true,
          },
          {
              key: 'Comments',
              displayName: this.captions.lbl_comments,
              displayNameId: 'lbl_comments',
              sorting: true,
          }
      ];
  
      if (includeLockerAssignment) {
          tableHeaders.splice(3, 0, {
              key: 'lockerNumber',
              displayName: this.captions.tbl_hdr_lockerNumber,
              displayNameId: 'tbl_hdr_lockerNumber',
              sorting: true,
              searchable: true,
          });
      }
  
      return tableHeaders;
  }
  
      getTableOptions() {
        return {
          defaultSortOrder: SorTypeEnum.asc,
          defaultsortingColoumnKey: 'date',
          uniqueKey: 'date',
        }
      }
      getTableContent(tableModel: any[]){
        return tableModel
      }

}