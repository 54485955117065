import { Pipe, PipeTransform } from '@angular/core';
import { SpaUtilities } from '../utilities/spa-utilities';
import { appointmentService } from '../service/appointment.service';
@Pipe({
  name: 'getLegendBorderWithBgColor'
})
export class GetLegendBorderWithBgColorPipe implements PipeTransform {
  constructor(public utils: SpaUtilities, public _appointmentService: appointmentService) { }
  transform(inputArray: any[], status) {
    return this.utils.getBorderColor(status) + ' ' + this.utils.getOnlyLightBackgoundColor(status);
  }
}
