import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { ReqBackOrder } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CreateTransaction } from '.';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';

export const InventoryResetCancel = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId?: any,
    SystemPreferenceService?: SysytemPreferenceService,
    router?: ActivatedRoute): any => {
    try {
        homeGroup.reset();
        homeGroup.controls['TransactionTypeId'].setValue(22);
        // TransactionCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
        //     if (x.buttonKey === 'inventoryreset') {
        //         x.displayProperity = true;
        //     }
        // });
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true);
        TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailTableCard.CollapseIconClick();
    } catch (error) {

    }
};