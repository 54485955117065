import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SalesTax } from 'src/app/eatecui/source/shared/models/tax-calculation/salestax';
import { DetailHeaderInfo, DetailsHeaderData, DetailTableCardConfiguration, TransactionGridTableCard, TransactionTableData } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import * as GlobalState from 'src/app/eatecui/source/store/actions/global-state.action';
@Component({
    selector: 'app-detail-card',
    templateUrl: './detail-card.component.html',
    styleUrls: ['./detail-card.component.scss']
})

  export class TransactionDetailHeaderComponent implements OnInit, OnChanges   {
      tableCardHeader: Array<DetailsHeaderData>;
      detailsTableCardBuilder: TransactionGridTableCard;
      enableHeaderInfo: boolean;
      customId: any;
      @ViewChild('ColorCheck') elementRef: ElementRef;
      @Input() detailTableCardConfig: DetailTableCardConfiguration;
      @ViewChild(MatMenuTrigger) triggerBtn: MatMenuTrigger;
      @Output() infoIconClick: EventEmitter<any> = new EventEmitter();
    constructor(private store: Store<AppState>) {
      this.enableHeaderInfo = false;
    }

    /**
     * @method infocallBack()
     * @input None
     */
     infocallBack(detailHeaderInfo: DetailHeaderInfo): void {
       try {
        this.detailTableCardConfig.DetailTableFormHeader.forEach((detailHeader: DetailsHeaderData) => {
           if ( detailHeader.hasOwnProperty('HeaderInfo')) {
               detailHeader.HeaderInfo.InfoCallback(detailHeaderInfo);
           }
        });
        this.triggerBtn.openMenu();
       } catch ( error ) {
        console.error(error);
       }
     }


     /**
     * @method iconcallBack()
     * @input None
     */
     iconcallBack(IconCallbackData: TransactionTableData): void {
      try {
        this.infoIconClick.emit(null);
      } catch ( error ) {
       console.error(error);
      }
    }
    openFormDetails(): void {
      this.store.dispatch(new GlobalState.OutSideClickAction(true));
      if (this.detailTableCardConfig.hasOwnProperty('CollapseIconClick')) {
        this.detailTableCardConfig.CollapseIconClick();
      }
    }
    ngOnChanges(): void {
        console.log('Transaction Module loaded');
        this.tableCardHeader = this.detailTableCardConfig.DetailTableFormHeader;
        this.detailsTableCardBuilder = this.detailTableCardConfig.DetailsTableCardBuilder;
        this.customId = this.detailTableCardConfig.CustomCurrencySymbol;
        if ( this.tableCardHeader && this.tableCardHeader.length > 0 ) {
          this.tableCardHeader.forEach((tableHeader: any, i: number) => {
            if ( i === 1 ) {
              this.enableHeaderInfo =   ( tableHeader.hasOwnProperty('HeaderInfo') && tableHeader.HeaderInfo.EnableInfo ) ? true : false;
            }
          });
        }
    }
    ngOnInit(): void {
       console.log('Transaction Module loaded');
    }
  }