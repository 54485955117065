import { BaseReport } from "../common/base-report";
import { ReportUIConfig, ReportAPIOptions, ReportParams, AllReports, ClientListingFilterData } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../report-business.service';
export class clientListing extends BaseReport {
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,public business: ReportBusinessService) {
        super(reportFormGrp);
    }
   
    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.ClientListing,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true,
            format: "CSV"
        };
    }
    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: false,
            endDatePicker: false,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: "PORTRAIT"
        };
    }
    
    protected formReportParams(): ReportParams[] {
       
        let toApi = this.localization.convertDateObjToAPIdate;
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        return [{ "pStartDate": toApi(customFilterData.startDate) }, { "pEndDate": toApi(customFilterData.endDate) }
        ,{"pPropertyName":this.propertyName}
        ,{'pDate': this.printedDate},{"pReportType":customFilterData.itemFilter},{"pFilterCaption":customFilterData.itemFilter}];
    }
    protected formURIParams(): ReportParams[] {
        
        return [];
    }
  
    protected formFilters() {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let toApi = this.localization.convertDateObjToAPIdate;
        const filter: ClientListingFilterData = {
            StartDate : toApi(customFilterData.startDate),
            EndDate : toApi(customFilterData.endDate),
            ReportType : customFilterData.itemFilter,
            ExcludeWithoutPhoneNumbers: customFilterData.excludeprofilephone,
            ExcludeWithoutEmails: customFilterData.excludeprofileemail,
            PhoneNumberFormat: this.localization.propertyCaptions.common.PhoneFormat,
            ExtensionCaption: this.localization.propertyCaptions.common.ExtensionReport,
            AppendReportCaption: this.localization.propertyCaptions.common.AppendReportCaption,
            DateFormat: this.localization.dateFormat
        };
       
    
        return JSON.stringify(filter);
    }    
}