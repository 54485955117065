
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { AgDropdownConfig, DropdownOptions } from './../../models/ag-models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-spa-ag-dropdown',
  templateUrl: './ag-dropdown.component.html',
  styleUrls: ['./ag-dropdown.component.scss']
})
export class AgDropdownComponent implements OnInit {
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  placeHolder: string;
  selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
  isFirstEmpty: boolean;
  isSelect: boolean;
  showRequired: boolean;
  isDisabled = false;
  @Output() selectChange = new EventEmitter();
  captions: any;
  isMultiple: boolean;
  customErrorMessage:string;
  defaultSelectededOptionValue:DropdownOptions;
  @Input('inputs')
  set inputOptions(value: AgDropdownConfig) {
    this.className = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.selectOptions = value.selectOptions;
    this.isFirstEmpty = value.isFirstEmpty ? value.isFirstEmpty : false;
    this.isSelect = value.isSelect ? value.isSelect : false;
    this.showRequired = value.showRequired ? value.showRequired : false;
    this.isDisabled = value.disabled ? value.disabled : false;
    this.isMultiple = value.isMultiSelect ? value.isMultiSelect : false;
    this.defaultSelectededOptionValue=value.defaultSelectededOptionValue;
    this.customErrorMessage = value.customErrorMessage;

    if(this.defaultSelectededOptionValue)
    {
      this.form.controls[this.formControlName].setValue(this.defaultSelectededOptionValue);
    }
    
  }

  constructor(private _localization: SpaLocalization) { }

  ngOnInit() {
    this.captions = this._localization.captions;
  }

  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }

  onSelectionChange(e) {
    this.selectChange.emit(e.value);
  }

}
