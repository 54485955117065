<div class="auto-complete-container">
  <div class="search-input">
     <input autocomplete="off" [(ngModel)]="textValue" class="input-text" (input)="textChanged()"/>
     <i *ngIf="enabledCancel" (click)="cancelautocomplete()" class="search-cancel icon-requisition-denied-eatec"></i>
  </div>
  <div class="search-autocomplete" *ngIf="autoCompleteParams.selectData.length > 0">
     <ul class="search-list">
         <li class="search-list-detail" (click)="addSupplier(list)" *ngFor="let list of autoCompleteParams.selectData">{{list.Value}}</li>
     </ul>
  </div>
</div>