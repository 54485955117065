import { Injectable } from "@angular/core";
import { Localization } from 'src/app/common/localization/localization';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { promise } from "protractor";
import { CgpsLoggingProfileSyncWrapperDataService } from "src/app/shared/service/cgps-logging-profile-sync-wrapper.data.service";
import { API, UI } from "./cgps-logging-profile-sync-wrapper.model";
@Injectable()
export class CgpsLoggingProfileSyncWrapperBusiness{
    captions: any;
    isViewOnly: any;

    constructor(private localization:Localization, private _dataService : CgpsLoggingProfileSyncWrapperDataService)
    {
        this.captions=this.localization.captions
    }

    public async getClientInfoByGuid(guestId) {
      return await this._dataService.getClientInfobyGuid(guestId);
    }

    public async GetAllClientsbyguid(guestId: any[]) {
      return await this._dataService.GetAllClientsbyguid(guestId);
    }

    public async GetClientInfo(clientId) {
      return await this._dataService.getClientInfo(clientId);
    }

    public async GetFailedProfile() : Promise<UI.FailedProfile[]>
    {
        let apiModels : API.FailedProfile[] = await this._dataService.getFailedProfile();
        return apiModels.map(x => this.UIMapper(x)); 
    }

    public DateRangeProfileSync(StartDate : any, EndDate : any)
    {
        return this._dataService.DateRangeProfileSync(this.APIMapper(StartDate,EndDate));
    }

    public singleProfileSync(guestId : any, syncDirection : number)
    {
        return this._dataService.SingleProfileSync(this.APIMapper('','',guestId,syncDirection));
    }
    private UIMapper(apiModel : API.FailedProfile) : UI.FailedProfile
    {
        return {
              lastName: apiModel.guestFirstName,
              firstName: apiModel.guestLastName,
              description: apiModel.failureReason,
              retryCount: apiModel.retryCount.toString(),
              guestId: apiModel.guestId,
              syncDirection: apiModel.syncDirection,
              sync: ''
        } as UI.FailedProfile
    }

    APIMapper(StartDate : any, EndDate : any, guestId: any = '',syncDirection = 0) : API.ProfileSyncInfo
    {
        return{
            startDate : StartDate !== "" ? this.localization.convertDateToAPIdate(new Date(StartDate)) : '',
            enddate : EndDate !== "" ? this.localization.convertDateToAPIdate(new Date(EndDate)) : '',
            guestId : guestId,  
            syncDirection : syncDirection
        }
    }


    public getHeaderOptions()
    {
        return[
            {
              key: 'lastName',
              displayNameId: 'lastName',
              displayName: this.captions.lbl_lastName ,
              sortingKey: 'lastName',
              searchable: true,
              sorting : true
            },
            {
              key: 'firstName',
              displayNameId: 'firstName',
              displayName: this.captions.lbl_firstName ,
              sortingKey: 'firstName',
              searchable: true,
              sorting : true
            },
            {
              key: 'description',
              displayNameId: 'tbl_hdr_description',
              displayName: this.captions.tbl_hdr_description ,
              sortingKey: 'description',
              searchable: true,
              sorting : true
            },
            {
              key: 'retryCount',
              displayNameId: 'lbl_retryCount',
              displayName: this.captions.lbl_retryCount ,
              sortingKey: 'retryCount',
              sorting : true
            },
            {
              key: 'sync',
              displayNameId: 'btn_sync',
              displayName: this.captions.lbl_EditClient,
              sortingKey: 'sync',
              templateName: ActionTypeEnum.custom
            },
            {
              key: ActionTypeEnum.action,
              displayName: this.captions.tbl_hdr_action,
              searchable: false,
              templateName: ActionTypeEnum.action
            }
          ]
    }

    public getTableOptions()
    {
        return{
            actions: [
                {
                  type: TableActions.sync,
                  disabled: this.isViewOnly
              }
              ],
              defaultsortingColoumnKey: 'lastName',
              showTotalRecords: true,
              defaultSortOrder: SorTypeEnum.asc,
              columnFreeze: {
                firstColumn: false,
                lastColumn: false
              },
              isDragDisabled: false,
              isHeaderCheckboxAllowed: true,
              checkboxKey: 'checked'
        }
    }
}
