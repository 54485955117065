import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import moment from 'moment';
import { Formatter } from '../models/formater.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const CommonDateFormater: Formatter = (value: any): any => {
    try {
        const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
        const DateTimeFormat = ( sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null ) ? 
        sessionDateFormat :  constants.DefaultIniDateFormat;
        if (value) {
            const AlreadyFormatted = moment(value, DateTimeFormat).format(DateTimeFormat) === value;
            if (!AlreadyFormatted) {
                // const returnValue = moment.utc(value).local().format(DateTimeFormat);
                const returnValue = moment(value).format(DateTimeFormat);
                // console.log('DateValue', gridData.value, returnValue, AlreadyFormatted);
                return returnValue;
            } else {
                return value;
            }
        } else {
            return '';
        }
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  };
