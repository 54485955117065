import { Localization } from '../../localization/localization';
import { UI, API, DDtablecontent } from './dedupe-guest-ui-model';
import{Guest,GuestProfileContact,guestCreditCardDetail} from './dedupe-guest-ui-model'
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';


export abstract class dedupeGuestRecordBusiness {
    captions: any;
    contactTypes: any;   
   
    isViewOnly: boolean;

    constructor(public localization: Localization) {
        this.captions = this.localization.captions;

    }
    public async getDeDupeGuests(searchCriteria: UI.DeDupeGuestSearchFields): Promise<DDtablecontent[]> {   
        
         return [];
    }
    public apiMapperGuestSearchFields(GuestSearchFieldsUIModel: UI.DeDupeGuestSearchFields): API.GuestSearchFields {

        return {
          lastName: GuestSearchFieldsUIModel.lastName,
          firstName: GuestSearchFieldsUIModel.firstName,
          postalCode: GuestSearchFieldsUIModel.postalCode,
          phone: GuestSearchFieldsUIModel.phone,
          emailAddress: GuestSearchFieldsUIModel.emailAddress,
        } as API.GuestSearchFields;
    }

    async combineGuestInfo(guestInfo){
      // combineGuestInfo
    }

    public async combineGuestRecords (primarydata, secondarydata):Promise<boolean> {
      return false;
    }

    getGuestInformationByGuid(Guid: string) : Promise<Guest>{
      return new Promise(null);
     } 
     /**
* Alert popup to show 'Warning' , 'Error' , 'Success'
* @param {string} message  - Content
* @param {AlertType} type  - Type of Popup
* @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
* @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional )
* @returns - Dialog Reference of the modal with Result of enum AlertAction
* @memberof Utilities
*/
  public showAlert(message: string, type: AlertType, btnType: ButtonType = ButtonType.Ok,
    callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string,
    additionalInfo?: { message: string, class: string }, moreOptionsLabel?: string, moreOptions?: any) {

   return null;
  }
}