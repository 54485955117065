
import {throwError as observableThrowError,  Observable, ReplaySubject } from 'rxjs';

import {catchError, tap} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Router } from '@angular/router';
import { allowedUrl } from '../globalsContant';


@Injectable()
export class loadingInterceptor implements HttpInterceptor, OnDestroy {
  public count: number = 0;
  public statusCount: number = 0;

  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private router: Router) { }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    try {

      const loadingContainer = document.getElementById('cover-spin');
      const ApiEndUrl = req.url.split('/').pop().toString().toLowerCase();
      const AllowedUrl = (allowedUrl.indexOf(ApiEndUrl) !== -1);
      if (AllowedUrl) {
        loadingContainer.style.display = 'block';
      }
      this.count++;

      return next.handle(req).pipe(tap((event: any) => {
        if (event.status === 200) {
          this.statusCount++;
          if (this.count === this.statusCount) {
            if (loadingContainer != null) {
              loadingContainer.style.display = 'none';
            }
          }
        }
      },
        (err: any) => {
          this.statusCount++;
          if (this.count === this.statusCount) {
            if (loadingContainer != null) {
              loadingContainer.style.display = 'none';
            }
          }
        }),
        catchError(err => {

          if (loadingContainer != null) {
            loadingContainer.style.display = 'none';
          }
          return observableThrowError(err);
        }),);

    }
    catch (ex) {
      console.log("Spinner issue caught -> ", ex);
    }
  }
}
