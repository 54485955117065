import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServerPaginationConfiguration } from '../../Models/ag-models';

@Component({
  selector: 'app-ag-server-paginator',
  templateUrl: './ag-server-paginator.component.html',
  styleUrls: ['./ag-server-paginator.component.scss']
})
export class AgServerPaginatorComponent implements OnInit {
  serverPageIndex = 0;
  serverPageSize = 10;
  from: number = 1;
  to: number = 10;
  totalRecordsCount: number = 0;
  @Input('config')
  set configuration(value: ServerPaginationConfiguration) {    
    this.serverPageSize = value? value.initPageSize: 10;
    this.from = value? value.initFrom: 1;
    this.to = value? value.initTo: 10;
    this.serverPageIndex = value? value.initPageIndex: 0;
  }
  @Input('totalLength')
  set totalRecords(value: number){
    this.totalRecordsCount = value? value: 0;
    this.validateActions();
    this.first();
  }
  @Output() serverPageEmit = new EventEmitter();
  isFirst: boolean;
  isLast: boolean;
  constructor() { }
  
  ngOnInit(): void {
  }

  first(){
    let minPageIndex = 0;
    this.serverPageIndex = minPageIndex;
    this.getStartAndEndValues();
    this.serverPageEmit.emit({
      pageIndex:this.serverPageIndex,
      pageSize:this.serverPageSize,
      from:this.from,
      to: this.to
    });
    this.validateActions();
  }
  previous(){
    let minPageIndex = 0;
    if(this.serverPageIndex > minPageIndex){
      this.serverPageIndex -= 1;
      this.getStartAndEndValues();
      this.serverPageEmit.emit({
        pageIndex:this.serverPageIndex,
        pageSize:this.serverPageSize,
        from:this.from,
        to: this.to
      });
    }
    this.validateActions();
  }
  next(){
    let maxPageIndex = Math.floor(this.totalRecordsCount / this.serverPageSize);
    if(this.serverPageIndex < maxPageIndex){
      this.serverPageIndex += 1;
      this.getStartAndEndValues();
      this.serverPageEmit.emit({
        pageIndex:this.serverPageIndex,
        pageSize:this.serverPageSize,
        from:this.from,
        to: this.to
      });
    }
    this.validateActions();
  }
  last(){
    let maxPageIndex = Math.floor(this.totalRecordsCount / this.serverPageSize);
    this.serverPageIndex = maxPageIndex;
    this.getStartAndEndValues();
    this.serverPageEmit.emit({
      pageIndex:this.serverPageIndex,
      pageSize:this.serverPageSize,
      from:this.from,
      to: this.totalRecordsCount
    });
    this.to = this.totalRecordsCount;
    this.validateActions();
  }

  pageSizeChange(e){
    this.calculateCurrentPageIndex();
    this.getStartAndEndValues();
    this.serverPageEmit.emit({
      pageIndex:this.serverPageIndex,
      pageSize:this.serverPageSize,
      from:this.from,
      to: this.to
    });
    this.validateActions();
  }

  getStartAndEndValues(){
    this.from = (this.serverPageIndex * this.serverPageSize) + 1,
      this.to = (this.isLastPage())? this.totalRecordsCount: (this.serverPageIndex * this.serverPageSize) + this.serverPageSize;
  }

  validateActions(){
    if(this.isFirstPage() && this.isLastPage()){
        this.isFirst = true;
        this.isLast = true;
    } else if(this.isLastPage()){
      this.isFirst = false;
      this.isLast = true;
    } else if(this.isFirstPage()){
        this.isFirst = true;
        this.isLast = false;
    } else{ 
      this.isFirst = false;
      this.isLast = false;
    }
  }

  isFirstPage(){
    return (this.serverPageIndex === 0)? true: false;
  }

  isLastPage(){
    return (((this.serverPageIndex + 1) * this.serverPageSize) > this.totalRecordsCount)? true: false;
  }

  calculateCurrentPageIndex(){
    this.serverPageIndex = Math.floor(this.from / this.serverPageSize);
  }
}
