import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ConfirmPopModel } from '../model/pop-up.interface';
import EnUs from 'src/app/eatecui/assets/i18n/en.json';
@Component({
  selector: 'app-agilysys-confirmation-popup',
  templateUrl: './agilysys-confirmation-popup.component.html',
  styleUrls: ['./agilysys-confirmation-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysConfirmationPopupComponent {
  agilysysCofirmationButton: AgilysysButtonModel[];

  constructor(public dialogRef: MatDialogRef<AgilysysConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPopModel, private translateService: TranslateService) {
      if (data.EnableSingleButton === true) {
        let okbutton = ((this.translateService.instant('Common.Ok')).indexOf('.') > -1)? EnUs['Common']['Ok']: this.translateService.instant('Common.Ok');
        this.agilysysCofirmationButton = [
          {
            displayProperity: false,
            displayName: okbutton,
            buttonKey: 'Confirm',
            buttonType: 'agilysys-button-primary',
            buttonCallBack: (callBackData: CallBackData) => {
                this.dialogRef.close(callBackData.buttonValue.buttonKey);
            }
          }
        ]; 
      } else {
        let noButton = ((this.translateService.instant('Common.No')).indexOf('.') > -1)? EnUs['Common']['No']: this.translateService.instant('Common.No');
        let cancelButton = ((this.translateService.instant('Common.Cancel')).indexOf('.') > -1)? EnUs['Common']['Cancel']: this.translateService.instant('Common.Cancel');
        let yesButton = ((this.translateService.instant('Common.Yes')).indexOf('.') > -1)? EnUs['Common']['Yes']: this.translateService.instant('Common.Yes');
        let confirmButton = ((this.translateService.instant('Common.Confirm')).indexOf('.') > -1)? EnUs['Common']['Confirm']: this.translateService.instant('Common.Confirm');
      this.agilysysCofirmationButton = [
        {
          // Name: 'cancel',
          // DisplayName: 'cancel',
          // Class: 'agilysys-button-secondary',
          // DisableProperity: false,
          // onCellClick: (returnValue: AgilysysButtonModel) => {
          //    this.dialogRef.close('Cancel');
          // }
          displayProperity: false,
          displayName: data.ExitPopup ? noButton : cancelButton,
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callBackData: CallBackData) => {
              this.dialogRef.close(callBackData.buttonValue.buttonKey);
          }
        },
        {
          // Name: 'Confirm',
          // DisplayName: 'Confirm',
          // Class: 'agilysys-button-primary',
          // DisableProperity: false,
          // onCellClick: (returnValue: AgilysysButtonModel) => {
          //    this.dialogRef.close('Confirm');
          // }
          displayProperity: false,
          displayName: data.ExitPopup ? yesButton : confirmButton,
          buttonKey: 'Confirm',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
              this.dialogRef.close(callBackData.buttonValue.buttonKey);
          }
        }
      ];
    }
     }
}
