import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

export const CrossReferenceTabCheckDropdown = (TransactionMasterJson: MasterInventoryManagement[]): any => {
    try {
        let rguestTenantId = true;
        const checkTenant = sessionStorage.getItem('RGuestTenantId');
        const isEatecUser = sessionStorage.getItem('isEatecUser');
        if (checkTenant && JSON.parse(checkTenant)) {
            rguestTenantId = true;
        }
        if (rguestTenantId || (isEatecUser && !JSON.parse(isEatecUser))) {
            // const ModuleName = ['Packages'];
            // ModuleName.forEach(moduleNames => {
            //     TransactionMasterJson = TransactionMasterJson.filter(x => x.GroupName !== moduleNames);
            // });
            // const ModuleId = [1];        
            // TransactionMasterJson.forEach(jsonData => {
            //     if (jsonData.GridConfiguration.GridTitleType.Type === 'dropdown' && 
            //         jsonData.GridConfiguration.GridTitleType.TypeConfig &&
            //         jsonData.GridConfiguration.GridTitleType.TypeConfig.length > 0) {
            //         ModuleId.forEach(moduleName => {
            //             jsonData.GridConfiguration.GridTitleType.TypeConfig = 
            //             jsonData.GridConfiguration.GridTitleType.TypeConfig.filter(x =>
            //                 x.Id !== moduleName);
            //         });
            //     }
            // });
            const ModuleId = [1];  
            TransactionMasterJson[0].NestedFormConfig.ViewFormConfig.forEach(element => {
                if (element.Name === 'CrossReference') {
                    ModuleId.forEach(moduleName => {
                        element.NestedGridData.GridTitleType.TypeConfig = element.NestedGridData.GridTitleType.
                        TypeConfig.filter(x => x.Id !== moduleName);
                    });
                    element.NestedGridData.GridTitleType.SelectedTypeId =  element.NestedGridData.
                    GridTitleType.TypeConfig.length > 0 ? element.NestedGridData.
                        GridTitleType.TypeConfig[0].Id : 0;
                }
            });
        }
        return TransactionMasterJson;
    } catch (error) {
        console.error('Error occurred in TransactionTabCheckDropdown', error);
        return TransactionMasterJson;
    }
};