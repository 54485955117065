import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { Localization } from '../localization/localization';

@Directive({
    selector: 'required'
})
export class RequiredAttribute implements AfterViewInit {

    prodId : number;

    constructor(private element: ElementRef,private _Localization: Localization,) {}

    ngAfterViewInit() {
        this.prodId=Number(this._Localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
        if(this.prodId == 6 && !this.element.nativeElement.value && this.element.nativeElement.attributes.hasOwnProperty('required') && this.element.nativeElement.parentElement.parentElement?.nextSibling?.classList != null && this.element.nativeElement.parentElement.parentElement.nextSibling.classList.contains('mat-form-field-underline')){
            if(this.element.nativeElement.parentElement.parentElement?.nextSibling?.classList) 
                this.element.nativeElement.parentElement.parentElement.nextSibling.classList.add('requiredLabelDefault');
        }        
    }
    @HostListener('keyup', ['$event']) onkeyup() {
        this.prodId=Number(this._Localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
        if(this.prodId == 6 && !this.element.nativeElement.value && this.element.nativeElement.attributes.hasOwnProperty('required') && this.element.nativeElement.parentElement.parentElement?.nextSibling?.classList && this.element.nativeElement.parentElement.parentElement.nextSibling.classList.contains('mat-form-field-underline')){
            this.element.nativeElement.parentElement.parentElement.nextSibling.classList.add('requiredLabelDefault');
        } else if(this.element.nativeElement.parentElement.parentElement?.nextSibling?.classList) {
            this.element.nativeElement.parentElement.parentElement.nextSibling.classList.remove('requiredLabelDefault');
        }
    }
    @HostListener('focus', ['$event']) onfocus() {
        this.prodId=Number(this._Localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
        if(this.prodId == 6 && !this.element.nativeElement.value && this.element.nativeElement.attributes.hasOwnProperty('required') && this.element.nativeElement.parentElement.parentElement?.nextSibling?.classList && this.element.nativeElement.parentElement.parentElement.nextSibling.classList.contains('mat-form-field-underline')){
            this.element.nativeElement.parentElement.parentElement.nextSibling.classList.add('requiredLabelDefault');
        } else if(this.element.nativeElement.parentElement.parentElement?.nextSibling?.classList) {
            this.element.nativeElement.parentElement.parentElement.nextSibling.classList.remove('requiredLabelDefault');
        }
    }
}