import { Injectable } from '@angular/core';
import { RetailRoutes } from '../retail-route';
import { RetailCommunication } from '../retailcommunication.service';
import { Ticket } from '../shared/service/common-variables.service';
import { TenderInfoTransactionContract, TransactionContract, TransactionData } from './transaction-engine-model';


@Injectable({
    providedIn: 'root'
})
export class TransactionEngineService {

    constructor(private retailCommunication: RetailCommunication) {

    }

    public CreateTicket(request: TransactionData): Promise<Ticket> {
        return this.retailCommunication.postPromise<Ticket>({ route: RetailRoutes.CreateTicketInfo, body: request });
    }

    public CreateTaxForMultiPackItems(request: TransactionData): Promise<Ticket> {
        return this.retailCommunication.postPromise<Ticket>({ route: RetailRoutes.CreateTaxForMultiPackItems, body: request });
    }

    public ReCalculateTicketBasedOnTender(request: TenderInfoTransactionContract): Promise<Ticket>{
        return this.retailCommunication.postPromise<Ticket>({route : RetailRoutes.ReCalculateTicketBasedOnTender, body : request });
    }

}
