export interface LockerAssignmentDetails {
  id: number;
  lockerId: number;
  uId: string;
  clientId: string;
  bookingType: LockerBookingSourceType;
  bookingTypeId: number;
  lockerPrefix: string;
  lockerBookingStartTime: string;
  lockerBookingEndTime: string;
  duration: number;
  lockerSequence: number;
  lockerNumber: string;
  isActive: boolean;
  amount: number;
  comments: string;
  transactionId?: Number;
}

export enum LockerBookingSourceType {
  Client = 1,
  SpaAppointment,
  SpaClasses
}
export interface LockerAvailability {
  id: number,
  lockerName: string,
  lockerCode: string,
  lockerPrefix: string,
  price: string,
  totalQty: number,
  availableQty: number

}
export interface LockerInformation {
  retailItemId: number;
  lockerId: number;
  lockerName: string;
  lockerCode: string;
  totalQuantity: number;
  availableQuantity: number;
  outofOrderQuantity: number;
}
export interface LockerAvailabilityRequest {
  StartTime: any
  EndTime: any
}

export interface LockerAvailabilityRequest {
  StartTime: any
  EndTime: any
  BookingType?: any
  BookingTypeId?: any
  GuestId?:any
}

export interface LockersBySourceTypeRequest{
  BookingType?: any
  BookingTypeIds?: any[]
}

export interface LockerAssignmentDetailsTranslog{
  lockerBookingStartTime: string;
  lockerBookingEndTime: string;
  duration: number;
  lockerName : string;
  comments : string;
}