import { Injectable } from '@angular/core';
import { HttpServiceCall, HttpMethod } from 'src/app/retail/shared/service/http-call.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { BaseResponse } from 'src/app/retail/shared/business/shared.modals';
import { Host } from 'src/app/retail/shared/globalsContant';
import * as _ from 'lodash';
import { RevenuePostingLog, RevenuePostingSearchRequest, RoomRevenuePostingLog, RoomRevenuePostingRequest ,CloseRevenuePosting} from '../../shop/view-categories/retail-revenue-posting-logs/revenue-posting';
import { RetailRoutes } from '../../retail-route';

@Injectable({ providedIn: 'root' })
export class RevenuePostingDataService {

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization) { }

    private showError(errorCode: number) {
        const errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage('Error', errMsg);
    }

    private async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any): Promise<T> {
        const response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc,
            host,
            method: callType,
            body,
            uriParams: uRIParams
        });
        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    public async RevenuePost(body: RevenuePostingSearchRequest) {
        try {
            return await this.InvokeServiceCall('RevenuePost', HttpMethod.Post, Host.retailPOS, body, {});
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    public async CloseRevenuePostings(body: CloseRevenuePosting): Promise<boolean> {
        try {
            return await this.InvokeServiceCall(RetailRoutes.CloseRevenuePostings, HttpMethod.Put, Host.retailPOS, body, {});
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    public async getFailureRevenuePosting(): Promise<number> {
        return await this.InvokeServiceCall<number>('GetFailureRevenuePosting', HttpMethod.Put, Host.retailPOS);
    }

    public async getRevenuePostingLogs(model: RevenuePostingSearchRequest): Promise<RevenuePostingLog[]> {
        return await this.InvokeServiceCall<RevenuePostingLog[]>('GetRevenuePostingLogs',
         HttpMethod.Put, Host.retailPOS, model);
    }

    public async getRoomRevenuePostingLogs(model: RoomRevenuePostingRequest): Promise<RoomRevenuePostingLog> {
        return await this.InvokeServiceCall<RoomRevenuePostingLog>('GetRoomRevenuePostingLogs',
         HttpMethod.Put, Host.retailPOS, model);
    }

    public async isBatchProcessingPending(): Promise<boolean> {
        return await this.InvokeServiceCall<boolean>('IsBatchProcessingPending', HttpMethod.Put, Host.retailPOS);
    }
}
