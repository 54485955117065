import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { SpaCancellationNoShowFeeService } from '../spa-cancellation-no-show-fee/spa-cancellation-no-show-fee.service';
import { CancellationNoShowPolicy, CancellationNoShowPolicyBaseDetails, SubjectDataType } from 'src/app/common/shared/shared/cancellation-no-show-policy/cancellation-no-show-policy.model';
import { CancallationNoShowSharedService} from 'src/app/common/shared/shared/cancellation-no-show-policy/cancellation-no-show-shared.service'
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints as AccessBreakpoint}  from 'src/app/common/constants/useraccess.constants';

@Component({
  selector: 'app-cancellation-no-show-wrapper',
  templateUrl: './cancellation-no-show-wrapper.component.html',
  styleUrls: ['./cancellation-no-show-wrapper.component.scss'],
  providers: [SpaCancellationNoShowFeeService]
})
export class CancellationNoShowWrapperComponent implements OnInit {

  captions: any;
  tableOptions: any;
  tableContent: CancellationNoShowPolicyBaseDetails[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  chargeOptions: any[] = [];
  isViewOnly: boolean = false;

  constructor(private localization: Localization, private spaCancellationNoShowFeeService: SpaCancellationNoShowFeeService,
    private cancellationNoShowSharedService: CancallationNoShowSharedService, private utils: SpaUtilities, private _userAccessBusiness: UserAccessBusiness)
   {
    this.captions = this.localization.captions;
   }

  async ngOnInit(): Promise<void> {
    this.getTableOptions();
    await this._userAccessBusiness.getUserAccess(AccessBreakpoint.CancellationNoShowPolicy).then(
      x => this.isViewOnly = x.isViewOnly
    );
    this.cancellationNoShowSharedService.data$.pipe(takeUntil(this.destroyed$)).subscribe(async payload => {
      if (payload != null) {
        switch (payload.type) {
          case SubjectDataType.GetPolicy:
            await this.getPolicyDetailsByPolicyId(payload.data);
            break;
          case SubjectDataType.CreatePolicy:
            await this.createCancellationNoShowPolicy(payload.data, false);
            break;
          case SubjectDataType.UpdatePolicy:
            await this.createCancellationNoShowPolicy(payload.data, true);
            break;
          case SubjectDataType.DeletePolicy:
            await this.deleteCancellationNoShowPolicy(payload.data);
            break;
          case SubjectDataType.UpdateActive:
            await this.spaCancellationNoShowFeeService.updatePolicyActive(payload.data);
            break;
        }
      }
    })
    this.chargeOptions = this.spaCancellationNoShowFeeService.getchargeOptions();
    this.tableContent = await this.getTableContent();
    this.assignTableBodyData(this.tableContent);
  }

getTableOptions() {
  let arrDataHeader = [
    { 'title': this.captions.tbl_hdr_policyCode, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
    { 'title': this.captions.tbl_hdr_policyName, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
    { 'title': this.captions.tbl_hdr_description, 'jsonkey': 'description', 'searchable': false, 'sortable': true},
    { 'title': this.captions.tog_lbl_active, 'jsonkey': 'isActive', 'searchable': false, 'sortable': false, "type": "toggle" },

  ]
  this.tableOptions = [
    {
      TableHdrData: arrDataHeader,
      TablebodyData: [],
      pagination: false,
      sortable: true,
      editable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.lbl_searchByPolicyCodeName,
      EnableActions: true,
      SelectRows: false,
      Searchable: true,
      EditMoreOption: false,
      Sortable: 'code',
      TableId: '',
      disableDelete: false,
      SelectRow: true,
      SelectOnlyRow: true,
      dropdownOptions: '',
      waitlistIcon: false,
      TableDraggable: false,
      sticky: false,
      DoneCancel: false,
      IsViewOnly: true,
      disableEditButton: false,
      isCopyEnabled : true,
      ServiceId : 'viptype'
    }
  ];
}

 async getTableContent(){
  return await this.spaCancellationNoShowFeeService.getAllCancellationNoShowPolicies();
}

async getPolicyDetailsByPolicyId(id: number){
  var policyDetails = await this.spaCancellationNoShowFeeService.getCancellationNoShowPolicyDetailsById(id);
  this.sendPolicyDetailsBack(policyDetails);
}

sendPolicyDetailsBack(policyDetails: any){
  this.cancellationNoShowSharedService.sendData(SubjectDataType.SendPolicy, policyDetails);
}

async createCancellationNoShowPolicy(policyData: CancellationNoShowPolicy, isUpdate: boolean){
  let errorMessage = '';
  this.utils.ToggleLoader(true);
  var response = await this.spaCancellationNoShowFeeService.createOrUpdateCancellationNoShowPolicy(policyData, isUpdate);
  this.utils.ToggleLoader(false);
  if (response.errorCode == 54)
    errorMessage = this.localization.replacePlaceholders(this.localization.getError(response.errorCode), ['policyName'], [response.errorDescription]);
  this.cancellationNoShowSharedService.sendData(SubjectDataType.APIResponse, response.errorCode, errorMessage);
  if(response.successStatus){
    this.assignTableBodyData(response.result);
  }
}

async deleteCancellationNoShowPolicy(id: number){
  this.utils.ToggleLoader(true);
  var response = await this.spaCancellationNoShowFeeService.deleteCancellationNoShowPolicy(id);
  this.utils.ToggleLoader(false);
  this.cancellationNoShowSharedService.sendData(SubjectDataType.DeleteAPIResponse, response.errorCode);
  if(response.successStatus){
    this.assignTableBodyData(response.result);
  }
}

assignTableBodyData(result: any){
  var tableData = [...this.tableOptions]
  tableData[0].TablebodyData = result;
  tableData[0].IsViewOnly = this.isViewOnly;
  this.tableOptions = tableData;
}

ngOnDestroy(){
  this.cancellationNoShowSharedService.clearSendData();
  this.destroyed$.next();
  this.destroyed$.complete();
}

}
