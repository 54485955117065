import { Injectable } from '@angular/core';
import { AgilysysButtonModel } from 'src/app/eatecui/source/agilysys-buttons/models/agilysys-buttons.interface';

@Injectable({
    providedIn: 'root',
})
export class AgilysysPopupService {
    AgilysysIframeButton: AgilysysButtonModel;
    
    get IframeButtonValue() {
        return this.AgilysysIframeButton;
    }
    set IframeButtonValue(buttonValue: AgilysysButtonModel) {
        this.AgilysysIframeButton = buttonValue;
    }
}
