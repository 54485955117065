import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DetailsHeaderData, DetailTableCardConfiguration, TransactionGridTableCard, TransactionTableData } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';

@Component({
    selector: 'app-copydetail-card',
    templateUrl: './copy-detail-card.component.html',
    styleUrls: ['./copy-detail-card.component.scss']
})

  export class TransactionCopyDetailCardComponent implements OnInit, OnChanges {
      tableCardHeader: Array<DetailsHeaderData>;
      detailsTableCardBuilder: TransactionGridTableCard;
      @Input() detailTableCardConfig: DetailTableCardConfiguration;
      @ViewChild('ColorCheck') elementRef: ElementRef;
      @Output() infoIconClick: EventEmitter<any> = new EventEmitter();
      customId: any;
      constructor() {
     
    }

    /**
     * @method iconcallBack()
     * @input None
     */
    iconcallBack(IconCallbackData: TransactionTableData,event?:Event): void {
      try {
        this.infoIconClick.emit(event);
      } catch ( error ) {
       console.error(error);
      }
    }
    openFormDetails(): void {
      if (this.detailTableCardConfig.hasOwnProperty('CollapseIconClick')) {
        this.detailTableCardConfig.CollapseIconClick();
      }
    }
    ngOnChanges(): void {
        console.log('Transaction Module loaded');
        this.tableCardHeader = this.detailTableCardConfig.DetailTableFormHeader;
        this.detailsTableCardBuilder = this.detailTableCardConfig.DetailsTableCardBuilder;
        this.customId = this.detailTableCardConfig.CustomCurrencySymbol;
    }
    ngOnInit(): void {
       console.log('Transaction Module loaded');
    }
  }