import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { DeviceOption, GridSearchAction, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridColumnHideConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): DeviceOption[] => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                // eslint-disable-next-line
                //const gridSortAction = {} as DeviceOption;
                // const GridSortArray = [];
                // GridSortArray.push(gridSortAction);
                return TransactionCollection.ViewTranscationConfig.GridConfiguration.GridColumnHide;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };