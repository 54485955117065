import { Component, OnInit ,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  GuestPolicyDetail } from 'src/app/common/shared/shared.modal';
import { BaseResponse } from 'src/app/shared/business/shared.modals';
import { Host , Module }  from 'src/app/shared/globalsContant';
import { HttpServiceCall, HttpMethod } from 'src/app/shared/service/http-call.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { DataPurge } from 'src/app/common/data-retention/data-retention.model';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/enums/shared-enums';

@Component({
  selector: 'app-guest-policy-wrapper',
  templateUrl: './guest-policy-wrapper.component.html',
  styleUrls: ['./guest-policy-wrapper.component.scss']
})
export class GuestPolicyWrapperComponent implements OnInit {
  guestPolicyDetail : GuestPolicyDetail;
  captions: any;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData ,private http: HttpServiceCall, private _utils: SpaUtilities, public dialog: MatDialog, public dialogRef: MatDialogRef<GuestPolicyWrapperComponent>,private _localization: SpaLocalization) { 
    this.guestPolicyDetail = dialogData.guestPolicyDetail as GuestPolicyDetail;
    this.captions = this._localization.captions;
  }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  onCancel(e) {
    this.dialogRef.close();
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void 
  {
    if (callDesc == "ApplyDataPolicyPurgeForGuestId") 
    {
      if (result) 
      {
        let dataPurgingCompleted = this._localization.replacePlaceholders(this.captions.lbl_dataPurgingCompleted, ['ok'], [this.captions.common.ok]);
      this._utils.showCommonAlert(dataPurgingCompleted, AlertType.WellDone, 
      ButtonTypes.Ok, async (res) => {
      if (res === AlertAction.CONTINUE) {
        this.dialogRef.close(result);
      this.dialog.closeAll();
      }
      });
      }
      else 
      {
      this._utils.showCommonAlert(this.captions.lbl_ErrWilePurging, AlertType.Warning, ButtonTypes.Ok);
      this.dialogRef.close(); 
      }
    }
    if (callDesc == "UpdateConsentPolicyDetailsForGuestId") 
    {
      let successMsg = this._localization.replacePlaceholders(this.captions.consentPolicyEnabled, ['consentPolicy'], ['']);
      this._utils.showCommonAlert(successMsg, AlertType.WellDone, ButtonTypes.Ok, 
      async (res) => {
      if (res === AlertAction.CONTINUE) {
      this.dialogRef.close(extraParams[0]);
      }
      })
    }
    if (callDesc == "GetPolicyTypeUsingPolicyId") 
    { 

    }
  }
  errorCallback<T>(callDesc: string): void {
if (callDesc == "ApplyDataPolicyPurgeForGuestId") 
    {
      this._utils.showCommonAlert(this.captions.lbl_ErrWilePurging, AlertType.Warning, ButtonTypes.Ok);
      this.dialogRef.close(); 
    }
  }
  makeHttpUpdatePolicyDetailsForGuestIdCall(applyPolicy: ApplyPolicy) { 
    this.http.CallApiWithCallback<any[]>({
    host: Host.spaManagement,
    success: this.successCallback.bind(this),
    error: this.errorCallback.bind(this), 
    callDesc: "UpdateConsentPolicyDetailsForGuestId",
    uriParams: { module: Module.client },
    method: HttpMethod.Post, 
    body: applyPolicy,
    showError: false,
    extraParams: [applyPolicy]
  });
  } 
  makeHttpApplyDataPolicyPurgeForGuestIdCall(dataPurge: DataPurge) 
  {
    this.http.CallApiWithCallback<any[]>({
    host: Host.spaManagement,
    success: this.successCallback.bind(this), 
    error: this.errorCallback.bind(this),
    callDesc: "ApplyDataPolicyPurgeForGuestId", 
    uriParams: { module: Module.client }, 
    method: HttpMethod.Post, 
    body: dataPurge,
    showError: true,
    extraParams: [dataPurge]
  });
  }
  makeHttpGetPolicyTypeUsingPolicyIdCall(policyId: number) {
    this.http.CallApiWithCallback<any[]>({
    host: Host.spaManagement,
    success: this.successCallback.bind(this),
    error: this.errorCallback.bind(this),
    callDesc: "GetPolicyTypeUsingPolicyId",
    uriParams: { module: Module.client },
    method: HttpMethod.Get,
    showError: false,
    extraParams: []
  });
  }
  
  async UpdatePolicyDetailsForGuestId(applyPolicy: ApplyPolicy) {
    let successMsg = this._localization.replacePlaceholders(this.captions.consentPolicyEnabled, ['consentPolicy'], ['']);
    if(applyPolicy.guestId == '')
    {
      this._utils.showCommonAlert(successMsg, AlertType.WellDone, ButtonTypes.Ok, async (res) => {
        if (res === AlertAction.CONTINUE) {
          this.dialogRef.close(applyPolicy);
        }
      })
    }
    else{
    this.makeHttpUpdatePolicyDetailsForGuestIdCall(applyPolicy);
    }
  }

  async ApplyDataPolicyPurgeForGuestId(dataPurge: DataPurge) {
    this._utils.showCommonAlert(this.captions.lbl_doYouWantToPurge, 
    AlertType.CustomDefault, 
    ButtonTypes.YesNo, 
    async (res) => { 
    if (res === AlertAction.YES) {
    this.makeHttpApplyDataPolicyPurgeForGuestIdCall(dataPurge);
    }
    }) 
  }
  async exportSendMail(value) 
  { 
  }
  
}
