import { MatDialog } from '@angular/material/dialog';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { CustomFormBuild, CustomFormBuildingGrouping, FormBuilderGroupKey, MasterInventoryManagement } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FieldBuilderType } from './form-builder-type.enum';
import { FormModelConfiguration, FormModelCustomBuilder } from './form-config.interface';
// import * as remapjson from '../../../../../assets/json/ui-control/remapsample.json';
import { ModeType } from '../../enum/master-enum';
import { SysytemPreferenceService } from '../../../../setup/system-preference/system-preference.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';

export const FormCustomBuilding: FormModelCustomBuilder = (
    ViewModelEntity: any,
    PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    CustomBuilder: CustomFormBuild, translateService: TranslateService, dialog?: MatDialog, masterService?: any, customFormBuilder?: any,
    sysytemPreferenceService?: SysytemPreferenceService,
    toasterService?: ToastrService, MasterHomeGroup?: FormGroup, MasterParentFormGroup?: FormGroup): any => {
    try {
        const CustomFormBuilder = [];
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0) {
            const entityName = PostModelEntity[0]['$']['Name'].toString();
            // const productremapJson = (remapjson as any).default;
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            if (CustomBuilder && CustomBuilder.CustomBuilderType === 'accordiangrouping') {
                CustomBuilder.CustomBuilderGrouping.forEach((customGroupBuilding: CustomFormBuildingGrouping) => {
                    const formType = {} as FormType;
                    formType.type = 'customrow';
                    formType.name = CustomBuilder.CustomBuilderName;
                    formType.fieldType = {
                        'CustomRow': FormInterface.FormCustomGrouping(
                            ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, customGroupBuilding, translateService)
                    };
                    // formType.fieldType = FormInterface.FormCustomGrouping(
                    // ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, customGroupBuilding);
                    CustomFormBuilder.push(formType);
                });
            } else if (CustomBuilder && CustomBuilder.CustomBuilderType === 'accordian') {
                CustomBuilder.CustomBuilderKey.forEach((FormBuilderGroup: FormBuilderGroupKey) => {
                    const accordianFormType = {} as FormType;
                    accordianFormType.type = FormInterface.FormGroupFieldTypes(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService);
                    if (FormBuilderGroup.hasOwnProperty('autoCompleteHeader') && FormBuilderGroup.autoCompleteHeader.length > 0) {
                        accordianFormType.autoCompleteHeader = FormBuilderGroup.autoCompleteHeader;
                    }

                    if (accordianFormType.type === FormFieldType.DROPDOWN && FormBuilderGroup.hasOwnProperty('EnableDropDownSearch')
                        && FormBuilderGroup.EnableDropDownSearch) {
                        accordianFormType.EnableDropDownSearch = FormBuilderGroup.EnableDropDownSearch;
                    }
                    // accordianFormType.hide = ( FormBuilderGroup.hasOwnProperty('Hide') ) ? FormBuilderGroup.Hide : false;
                    const AccFormHide = ProductDataRemap.FormDisplayHideRemapEngine(
                        productremapJson, entityName, FormBuilderGroup.OdataKey, FormBuilderGroup);
                    accordianFormType.hide = AccFormHide;
                    accordianFormType.fieldType = {
                        'SingleData': FormInterface.FormCustomFieldType(
                            ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                            FormBuilderGroup, translateService, dialog, sysytemPreferenceService, null, null, toasterService, null, 
                            MasterHomeGroup )
                    };
                    // accordianFormType.fieldType = FormInterface.FormCustomFieldType(
                    // ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup);

                    CustomFormBuilder.push(accordianFormType);
                });
            } else {
                CustomBuilder.CustomBuilderKey.forEach((FormBuilderGroup: FormBuilderGroupKey) => {
                    const accordianFormType = {} as FormType;
                    accordianFormType.type = FormInterface.FormGroupFieldTypes(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService);
                    // accordianFormType.hide = ( FormBuilderGroup.hasOwnProperty('Hide') ) ? FormBuilderGroup.Hide : false;
                    const FormHide = ProductDataRemap.FormDisplayHideRemapEngine(
                        productremapJson, entityName, FormBuilderGroup.OdataKey, FormBuilderGroup);
                    accordianFormType.hide = FormHide;
                    accordianFormType.fieldType = {
                        'SingleData': FormInterface.FormCustomFieldType(ViewModelEntity, PostModelEntity,
                            MasterDataCollection, httpService, FormBuilderGroup, translateService, dialog,
                            sysytemPreferenceService, masterService, customFormBuilder, toasterService, null, 
                            MasterHomeGroup, null, null, null, null, null, null, MasterParentFormGroup )
                    };
                    if (accordianFormType.type === FormFieldType.DROPDOWN && FormBuilderGroup.hasOwnProperty('EnableDropDownSearch')
                        && FormBuilderGroup.EnableDropDownSearch) {
                        accordianFormType.EnableDropDownSearch = FormBuilderGroup.EnableDropDownSearch;
                    }
                    if (FormBuilderGroup.ModeType === ModeType.OnlyEdit && MasterDataCollection.FormConfig.FlowMode.toLowerCase() !== 'create') {
                        accordianFormType.fieldType.SingleData.disbaledProperty = true;
                        accordianFormType.EnableSuffixicon = FormBuilderGroup.EnableSuffixIcon;
                        accordianFormType.iconName = FormBuilderGroup.IconName;
                    }
                    // accordianFormType.fieldType = FormInterface.FormCustomFieldType(
                    // ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup);
                    CustomFormBuilder.push(accordianFormType);
                });
            }
        }
        return CustomFormBuilder;

    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};