import { Injectable } from '@angular/core';
import { PaymentMethods } from './shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CMSSettlementData, CMSRedemptionType, RedemptionTypeToHandle, ValidatePayResponse, PaymentHistory, TransactionPaymentAggregate, TransactionPayment, VoucherRedemptionResponse, PaymentMethodDisplayLabel, RedemptionType, CMSPaymentDetails, RedeemPointsResponse } from '../service/payment/payment-business.model';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import { ShopBussinessService } from '../../shop/shop-business.service';
import { MatDialog } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../../shop/shop-dialog-popup/shop-dialog-popup.component';
import * as _ from 'lodash';
import { RetailFeatureFlagInformationService } from '../service/retail.feature.flag.information.service';
import { ShopServiceitemSummaryComponent } from '../../shop/shop-common-components/shop-serviceitem-summary/shop-serviceitem-summary.component';
import { CMSAccountInfo, CMSAccountLookupRequest, CMSPatronLookupRequest, CMSPlayerInfo, RedeemPointsRequest, SMReturnCode, VendorErrorCode, Voucher } from 'src/app/common/Models/common.models';
import { RetailDataAwaiters } from '../events/awaiters/retail.data.awaiters';
import { RetailUtilities } from '../utilities/retail-utilities';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { PayHandle } from '../service/payment/payment-model';
import { ActionMode } from 'src/app/common/enums/shared-enums';

@Injectable()
export class CMSBusinessService {

    private selectedCMSPlayer: TransactionPaymentAggregate[] = [];

    constructor(
        private _localization: RetailLocalization,
        private http: HttpServiceCall,
        public dialog: MatDialog,
        private retailFeatureFlagInfo: RetailFeatureFlagInformationService,
        private utils: RetailUtilities,
        public _ss: CommonVariablesService,
    ) { }

    private IsCompleteReturn($scope) {
        return (($scope._ss.selectedReturnedRetailProducts.length == $scope._ss.returnWithticketItems.length) &&
            ($scope._ss.selectedExchangeRetailProducts.length == 0 && $scope._ss.selectedRetainedRetailProducts.length == 0)) // All the items were returned => COMPLETE REFUND
    }

    /**
     * @description Retrieves the transaction history and Filter out the payment methods and also
     *  Selects CMS as default method if conditions are met
     * @param $scope 
     */
    async HandleCMSPaymentForReturnFlow($scope: ShopBussinessService, compScope) {
        $scope.TransactionPayments = [];
        if ($scope.paymentMethods && $scope.paymentMethods.length > 0 && $scope._ss.transactionId > 0) {
            let response: any = await this.http.CallApiAsync<any>({
                host: GlobalConst.Host.retailPOS,
                callDesc: "GetTransactionPaymentWithAdditionalDetails",
                method: HttpMethod.Get,
                uriParams: { transactionId: $scope._ss.transactionId }
            });
            if (response.successStatus) {
                let transactionResponse: TransactionPaymentAggregate[] = response.result;
                $scope.TransactionPaymentsAggregate = transactionResponse;
                $scope.TransactionPayments = [];
                transactionResponse.forEach(x => $scope.TransactionPayments.push(x.transactionPayment));
                let cmsSaleAmt = 0;
                $scope.TransactionPayments.forEach(x => cmsSaleAmt += x.paymentAmount);
                let paymentMethodToSelect: PaymentMethods = this.ValidateCMSMethodsActivation($scope);
                if (paymentMethodToSelect) {
                    //Select CMS as default Payment method                    
                    let payment = $scope.paymentMethods.find((method) => { return this.utils.GetOriginalTenderId(method.paymentTypeId, method.parentTypeId) == paymentMethodToSelect });
                    const selectedpayment = {
                        id: payment.id,
                        paymentTypeId: paymentMethodToSelect,
                        allowEarn: payment.allowEarn
                    };
                    $scope.ifPaymentChoose = true;
                    $scope.selectpaymentMethod(selectedpayment, compScope, true);

                    //Expand the grid
                    $scope.UpdateGridStateRequired();
                }
            }
        }
    }

    ValidateCMSMethodsActivation($scope: ShopBussinessService) {
        let paymentMethodToSelect: PaymentMethods;
        let collectFlow: boolean = ($scope._ss.finalAmount > 0);
        const cmsExistInOriginalTrans = $scope.GetActiveTransactionPayments($scope.TransactionPayments).some(t => this.utils.GetOriginalTenderId(Number(t.paymentMethod), t.parentTenderId) == PaymentMethods.CompRedemption);
        if ((!collectFlow && $scope.TransactionPayments.length > 0 && !cmsExistInOriginalTrans) || !this.retailFeatureFlagInfo.AllowCompRedemptionVoid) {
            this.EnableAllPaymentMethodsAndRemoveCMS($scope, PaymentMethods.CompRedemption);
        } else {
            if ($scope.paymentMethods.some(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CompRedemption) && cmsExistInOriginalTrans) { //Check if CMS is enabled
                if (!collectFlow) { paymentMethodToSelect = PaymentMethods.CompRedemption; }
            }
        }

        const VoucherMethodsActivationValidator = (paymentMethod: PaymentMethods, allowMethodConfig: boolean) => {
            let offerRedemptionMethod = $scope.paymentMethods.find(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == paymentMethod);
            if (offerRedemptionMethod && $scope.TransactionPayments.length > 0) {
                let newOfferRefundRulesMet: boolean = false;
                let originalTktHasOffers = ($scope.GetActiveTransactionPayments($scope.TransactionPayments).some(t => this.utils.GetOriginalTenderId(Number(t.paymentMethod), t.parentTenderId) == paymentMethod));
                if (originalTktHasOffers) {
                    let originalOfferTrans = $scope.TransactionPayments.find(t => this.utils.GetOriginalTenderId(Number(t.paymentMethod), t.parentTenderId) == paymentMethod);
                    newOfferRefundRulesMet = (Math.abs($scope.remainingAmount) >= Math.abs(originalOfferTrans.paymentAmount)) || //Remaining Amt is greater than offer value
                        this.IsCompleteReturn($scope);
                }
                let isAllowed = collectFlow ? !originalTktHasOffers : (originalTktHasOffers && allowMethodConfig && newOfferRefundRulesMet);
                offerRedemptionMethod.isDisabled = !isAllowed;
                if (!collectFlow && !originalTktHasOffers) { this.EnableAllPaymentMethodsAndRemoveCMS($scope, paymentMethod); }
            }
        }
        const offersSettlement = $scope.TransactionPaymentsAggregate.find(x => String(this.utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod), x.transactionPayment.parentTenderId)) == String(PaymentMethods.OfferRedemption));
        const vendorType = offersSettlement && offersSettlement.additionalInformation.VendorType;
        VoucherMethodsActivationValidator(PaymentMethods.OfferRedemption, this.retailFeatureFlagInfo.ValidateOfferRedemptionVoid(vendorType));
        VoucherMethodsActivationValidator(PaymentMethods.CompSlipRedemption, true); //Since there is no config which controls compslip void passing 'true'

        this.DisableOfferOrCompSlipIfVoucherRedeemed($scope);
        $scope.ifPaymentChoose = $scope.IsOfferOrCompSlipPaymentMethod ? false : $scope.ifPaymentChoose;

        return paymentMethodToSelect;
    }


    /**
     * @description Enables CMS on condition basis and enables all other methods
     * @param $scope 
     */
    EnableAllPaymentMethodsAndRemoveCMS($scope: ShopBussinessService, cmsMethod: PaymentMethods) {
        $scope.paymentMethods = $scope.paymentMethods.filter(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) != cmsMethod);
        if ($scope.selectedpayment && $scope.SelectedPaymentMethodEquals(cmsMethod)) {
            $scope.ResetSelectedPaymentMethod();
        }
        $scope.paymentMethods.forEach(x => { x.isDisabled = false; });
    }

    /**
     * @description Displays a popup where user can choose a patron to proceed refund
     * @param transAdditionalData 
     * @param $scope 
     * @param formGrpObj 
     * @param compScope 
     */
    ShowPlayerSelectionPopup(transAdditionalData: TransactionPaymentAggregate[], $scope, formGrpObj, compScope) {
        //Close the loader
        setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);
        const dialogRef = this.dialog.open(ShopDialogPopUp, {
            width: '500px',
            height: 'auto',
            maxHeight: '700px',
            disableClose: true,
            hasBackdrop: true,
            data: {
                isEdit: false,
                headername: this._localization.captions.shop.CMS.PatronSelectionDialog.header,
                closebool: true,
                templatename: 'CMS',
                data: transAdditionalData
            },
            panelClass: 'shop-payment'
        });

        dialogRef.afterClosed().subscribe(playerInfo => {
            if (playerInfo) {
                this.selectedCMSPlayer = [playerInfo];
                this.ProceedRefund($scope, formGrpObj, compScope, [playerInfo]);
            }
        });
    }

    /**
     * @description This methods decides where the CMS Refund flow should continue
     * @param $scope 
     * @param compScope 
     * @param formGrpObj 
     */
    async RefundExistingCMSPayments($scope: ShopBussinessService, compScope, formGrpObj) {
        let actualRefundAmount = $scope.getSaleAmt(true);
        if ($scope.IsOfferOrCompSlipPaymentMethod) {
            let prevSettlementWithOffer: number = 0;
            if ($scope.TransactionPayments && $scope.TransactionPayments.length > 0 && $scope.TransactionPayments.some(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId)) {
                $scope.TransactionPayments.filter(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == $scope.selectedpayment.paymentTypeId).forEach(x => prevSettlementWithOffer += x.paymentAmount);
            }
            let fullCMSReturnFlow = (actualRefundAmount >= prevSettlementWithOffer && $scope.FullPayment);
            let transaToBeRefunded = _.cloneDeep($scope.TransactionPaymentsAggregate.filter(x => this.utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId) == $scope.selectedpayment.paymentTypeId && x.additionalInformation.Amount > 0));
            if (prevSettlementWithOffer && transaToBeRefunded) {
                this.ProceedRefund($scope, formGrpObj, compScope, transaToBeRefunded, fullCMSReturnFlow);
            }
        } else {
            let cmsTransactions = _.cloneDeep($scope.TransactionPaymentsAggregate.filter(x => this.utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId) == PaymentMethods.CompRedemption && x.additionalInformation.Amount > 0));
            let uniqCMSTransactions = _.uniqWith(cmsTransactions,
                (x, y) => x.additionalInformation.PatronId == y.additionalInformation.PatronId
                    && x.additionalInformation.RedemptionType == y.additionalInformation.RedemptionType);
            //Reset the Amount
            uniqCMSTransactions.forEach(x => x.additionalInformation.Amount = 0);
            //Calculate total settled amt per unique patron and RedemptionType
            uniqCMSTransactions.forEach(x => {
                $scope.TransactionPaymentsAggregate.filter(y => y.additionalInformation.PatronId == x.additionalInformation.PatronId
                    && y.additionalInformation.RedemptionType == x.additionalInformation.RedemptionType).forEach(z => x.additionalInformation.Amount += z.additionalInformation.Amount)
            });

            let prevSettlementWithCMS: number = 0;
            if ($scope.TransactionPayments && $scope.TransactionPayments.length > 0 && $scope.TransactionPayments.some(x => Number(x.paymentMethod) == PaymentMethods.CompRedemption)) {
                $scope.TransactionPayments.filter(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod),x.parentTenderId) == PaymentMethods.CompRedemption).forEach(x => prevSettlementWithCMS += x.paymentAmount);
            }

            let fullCMSReturnFlow = (actualRefundAmount >= prevSettlementWithCMS && $scope.FullPayment); //Proceed to Refund

            const saleNotStarted = (!$scope.ReturnWithTicketResponse && ($scope._ss.finalAmount == $scope.remainingAmount));
            const flowStartedButRemaining = ($scope.ReturnWithTicketResponse && ($scope._ss.finalAmount != $scope.remainingAmount));
            uniqCMSTransactions = uniqCMSTransactions.filter(x => x.additionalInformation.Amount > 0); //After each refund process amount gets reduced, filtering remaining active transns

            if (uniqCMSTransactions.length > 1 && !fullCMSReturnFlow && (saleNotStarted || flowStartedButRemaining)) {
                this.ShowPlayerSelectionPopup(uniqCMSTransactions, $scope, formGrpObj, compScope);
            } else {
                let transaToBeRefunded = ($scope._ss.finalAmount == $scope.remainingAmount) && this.selectedCMSPlayer.length > 0 ? this.selectedCMSPlayer : uniqCMSTransactions;
                this.selectedCMSPlayer = [];
                this.ProceedRefund($scope, formGrpObj, compScope, transaToBeRefunded, fullCMSReturnFlow);
            }
        }
    }

    /**
     * @description This method is to perform the Refund and then continue/close the flow
     * @param $scope 
     * @param formGrpObj 
     * @param compScope 
     * @param transInfo 
     */
    async ProceedRefund($scope: ShopBussinessService, formGrpObj, compScope, transInfo: TransactionPaymentAggregate[], fullCMSReturnFlow: boolean = false) {

        //If user selects cms as initial payment method then perform return first
        if (compScope && !$scope.ReturnWithTicketResponse) {
            compScope.ReturnwithTicket();
            return;
        }

        $scope.ShowTransactionInprogressDialog($scope.ShopCaptions.CMS.RefundInProgressMsg);
        let settlementHistory: CMSSettlementData[] = [];
        const amountNeedToBeRefunded = Math.abs($scope.getAmountPaid(formGrpObj));
        let originallySettledAmt = 0;
        transInfo.forEach(x => originallySettledAmt += x.additionalInformation.Amount);
        // If amt entered/ total refund amt is greater than the original transaction then refund the original amt
        const refundAmt = amountNeedToBeRefunded <= originallySettledAmt ? amountNeedToBeRefunded : originallySettledAmt;

        for (let index = 0; index < transInfo.length; index++) {
            let response: CMSSettlementData[] = await this.VoidCMSPayment($scope, transInfo[index], refundAmt, fullCMSReturnFlow);
            settlementHistory = settlementHistory.concat(response);
        }
        $scope.dialog.closeAll();
        if (settlementHistory && settlementHistory.length > 0) {
            let refundedAmount: number = 0;
            settlementHistory.filter(x => x.refunded).forEach(x => refundedAmount += x.refundAmount);
            settlementHistory.forEach(settlement => {
                if (settlement.refunded) {
                    this.AddSettlementHistory($scope, settlement);
                    //Reduce the refunded amt from original transaction
                    let originalTrans = $scope.TransactionPaymentsAggregate.find(x => x.additionalInformation.PatronId == settlement.patronId && x.additionalInformation.RedemptionType == settlement.redemptionType);
                    if (originalTrans) { originalTrans.additionalInformation.Amount -= settlement.refundAmount; }
                }
            });

            $scope.ShowSettlemtHist = true;
            let remainingAmount = this.CalculateRemainingAmount($scope);

            //Switch to multi payment if we remaining amt to be refunded and No need for any CMS sale
            if (amountNeedToBeRefunded >= refundedAmount && remainingAmount != 0 && settlementHistory.every(x => !x.pmAgentCallReq)) {
                //Close the loader
                setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);
                //After adding settlement history update the buttons
                $scope.CheckSettlementStatusAndUpdateButtons(compScope);
                $scope.SwitchToMultiPayment('', formGrpObj, true);
            }
            // make a try pay request for PMS sale
            else if (settlementHistory.some(x => x.pmAgentCallReq && x.saleAmount > 0)) {
                $scope.isCMSReturnFlow = true;
                let settlementWithCMSSale = settlementHistory.find(x => x.pmAgentCallReq);
                compScope.CMSForm.patchValue({
                    cmsSearch: settlementWithCMSSale.patronId,
                    cmsPaymentSelection: RedemptionTypeToHandle[settlementWithCMSSale.redemptionType]
                });
                if (this.retailFeatureFlagInfo.SkipPMAgentCMS) {
                    try {
                        $scope.ShowTransactionInprogressDialog($scope.ShopCaptions.RetrievePatronDetails);
                        let accountInfo: CMSAccountInfo = await this.GetAccountInfo(settlementWithCMSSale.patronId, settlementWithCMSSale.playerPin, $scope.selectedpayment?.paymentTypeId, true);
                        $scope.dialog.closeAll();
                        if (accountInfo?.returnCode == SMReturnCode.Success) {
                            $scope.cmsPlayerHandles = [];
                            this.CreatePatronGuest(settlementWithCMSSale.patronId);
                            let pointsDetails: PayHandle = {
                                playerId: settlementWithCMSSale.patronId,
                                playerPin: settlementWithCMSSale.playerPin,
                                name: accountInfo.accountName,
                                type: CMSRedemptionType[CMSRedemptionType.Points],
                                balance: accountInfo.cmsBalanceDetails.compRedemtion.points.availablePointsInDollars,
                                handle: "",
                                inquiryInfo: null,
                                isBalanceVisible: false,
                                isPartialTenderAllowed: false,
                                isRefundable: false,
                                additionalAttributes: null,
                                allowedAPIs: [],
                                referenceNumber1: "",
                                referenceNumber2: "",
                            }
                            let compsDetails: PayHandle = {
                                playerId: settlementWithCMSSale.patronId,
                                playerPin: settlementWithCMSSale.playerPin,
                                name: accountInfo.accountName,
                                type: CMSRedemptionType[CMSRedemptionType.Comps],
                                balance: accountInfo.cmsBalanceDetails.compRedemtion.comps.availableCompInDollars,
                                handle: "",
                                inquiryInfo: null,
                                isBalanceVisible: false,
                                isPartialTenderAllowed: false,
                                isRefundable: false,
                                additionalAttributes: null,
                                allowedAPIs: [],
                                referenceNumber1: "",
                                referenceNumber2: "",
                            }
                            $scope.cmsPlayerHandles.push(pointsDetails, compsDetails);
                            $scope.selectedCMSPlayer = {
                                PlayerName: accountInfo.accountName,
                                PatronId: settlementWithCMSSale.patronId
                            }
                            $scope.selectedCMSType = $scope.cmsPlayerHandles.find(x => x.type ==  RedemptionTypeToHandle[settlementWithCMSSale.redemptionType]);
                            this.PerformCMSAutoSaleIfRequired($scope, compScope, settlementWithCMSSale);
                        }
                        else {
                            $scope.cmsPlayerHandles = [];
                            let notFoundMsg = {
                                [PaymentMethods.CompRedemption]: this._localization.captions.shop.NoPlayersFound
                            };
                            $scope.EnableCloseTranBtn = false;
                            $scope.ifPaymentChoose = false;
                            this.utils.ShowErrorMessage(
                                this._localization.captions.common.Error,
                                accountInfo.message ? accountInfo.message
                                    : notFoundMsg[PaymentMethods.CompRedemption]

                            );
                        }
                        setTimeout(() => {
                            $scope._ams.loaderEnable.next('');
                        }, 1000);
                    }
                    catch (error) {
                        console.error("exception occurred while fetching player details:" + error?.message);
                        $scope.dialog.closeAll();
                        this.utils.ShowErrorMessage(
                            this._localization.captions.common.Error,
                            this._localization.captions.shop.NoPlayersFound
                        );
                    }
                }
                else {
                    $scope.GetHandles(compScope.returnTransitionDetails, compScope, settlementWithCMSSale);
                }
            }
            // If none of the prev cond met then check is settled call TenderRetainData 
            else {
                //Close the loader
                setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);

                compScope.TenderRetainData();
                //After adding settlement history update the buttons
                $scope.CheckSettlementStatusAndUpdateButtons(compScope);
            }

            console.log(settlementHistory);
        }
    }

    CalculateRemainingAmount($scope) {
        let remainingAmount = 0;
        let SettledAmt = 0;
        $scope.SettlementHistory.map((settlemt) => SettledAmt += $scope.RoundOffTwo(settlemt.amount));
        if ($scope.IsRefundFlow && $scope.getSaleAmt() < 0)
            remainingAmount = -$scope.RoundOffTwo(($scope.getSaleAmt(true) - (isNaN(SettledAmt) ? 0 : SettledAmt) * -1));
        else
            remainingAmount = $scope.RoundOffTwo($scope.getSaleAmt(true) - (isNaN(SettledAmt) ? 0 : SettledAmt));
        return remainingAmount;
    }


    /**
     * @description Triggers API call to void the transaction made with the given PatronId and RedemptionType
     * @param $scope 
     * @param transInfo 
     * @param refundAmt 
     * @param fullCMSReturnFlow 
     */
    async VoidCMSPayment($scope: ShopBussinessService, transInfo: TransactionPaymentAggregate, refundAmt: number, fullCMSReturnFlow: boolean) {
        let settlementHistory = [];
        const redemptionTypeMap = {
            [PaymentMethods.CompRedemption]: transInfo.additionalInformation.RedemptionType,
            [PaymentMethods.OfferRedemption]: CMSRedemptionType.Offers,
            [PaymentMethods.CompSlipRedemption]: CMSRedemptionType.CompSlip
        }
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: "VoidCMSPayment",
            method: HttpMethod.Put,
            uriParams: {
                transactionId: $scope.ReturnWithTicketResponse.transactionData.id,
                patronId: transInfo.additionalInformation.PatronId,
                redemptionType: redemptionTypeMap[this.utils.GetOriginalTenderId($scope.selectedpayment.paymentTypeId, $scope.selectedpayment.parentTypeId)],
                paymentMethodType: this.utils.GetOriginalTenderId($scope.selectedpayment.paymentTypeId, $scope.selectedpayment.parentTypeId)
            },
            body: !fullCMSReturnFlow || ($scope.IsOfferOrCompSlipPaymentMethod && this.IsCompleteReturn($scope)) ?
                refundAmt : transInfo.additionalInformation.Amount
        });
        if (response.successStatus) {
            settlementHistory = response.result;
        }
        return settlementHistory;
    }

    /**
     * @description Adds the settlement into the settlement history Array
     * @param $scope 
     * @param settlementData 
     */
    AddSettlementHistory($scope: ShopBussinessService, settlementData: CMSSettlementData) {
        let cmsRedemption = RedemptionTypeToHandle[settlementData.redemptionType];
        let SettleMentObject: PaymentHistory = {
            paymentMethodId: $scope.selectedpayment.paymentTypeId,
            parentTenderId:  $scope.selectedpayment?.parentTypeId,
            paymentMethod: $scope.FormatPaymentMethodLabelForSettlementHistory($scope.selectedpayment,
                $scope.paymentMethods, settlementData.patronId, cmsRedemption, settlementData.name),
            amount: -settlementData.refundAmount,
            paymentMethodInfo: $scope.selectedpayment,
            discountExemptRatio:  this._ss.Ticket.tenderReducesDiscountRatio,
            taxExemptRatio:  this._ss.Ticket.compTaxExemptRatio,
            tenderReducesDiscount: $scope.selectedpayment.tenderReducesDiscount,
            tenderReducesTax:  $scope.selectedpayment.isAutoRemoveTax,
        }
        $scope.SettlementHistory.unshift(SettleMentObject);
        this.DisableOfferOrCompSlipIfVoucherRedeemed($scope);
    }

    /**
     * @description Performs the sale when user initiates a refund for partial amount on original settlement
     * @param $scope 
     * @param compScope 
     * @param settlementWithCMSSale 
     */
    PerformCMSAutoSaleIfRequired($scope: ShopBussinessService, compScope, settlementWithCMSSale: CMSSettlementData) {
        // This block is to perform a sale without user interaction
        if ($scope.IsCMSRefund && settlementWithCMSSale) {
            let playerInfo = $scope.cmsPlayerHandles.find(x => x.type == RedemptionTypeToHandle[settlementWithCMSSale.redemptionType]);
            if (playerInfo) {
                $scope.selectedDeviceHandle = playerInfo.handle;
                $scope.SendTryPayRequest(
                    $scope.ReturnWithTicketResponse
                    , PaymentMethods.CompRedemption
                    , compScope.returnTransitionDetails
                    , false
                    , {
                        Amount: settlementWithCMSSale.saleAmount,
                        PlayerPin: settlementWithCMSSale.playerPin
                    }
                    , compScope
                );
            }
        }
    }

    /**
     * @description This method triggers an API call to update the POS that the partial sale for CMS refund is done
     * @param $scope 
     * @param CompScope 
     * @param validatePayResponse 
     */
    async UpdateSuccessfulCMSPayment($scope: ShopBussinessService, CompScope, validatePayResponse: ValidatePayResponse) {
        let isUpdateSuccess: boolean = false;

        //Update the PaymentReferenceId from ValidatePayResponse
        $scope.CurrentTryPayResponse.paymentReferenceId = validatePayResponse.transactionId;

        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: "UpdateSuccessfulPayment",
            method: HttpMethod.Put,
            uriParams: { transactionId: $scope.ReturnWithTicketResponse.transactionData.id },
            body: $scope.CurrentTryPayResponse
        });
        if (response.successStatus) {
            isUpdateSuccess = response.result;
            setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);
            $scope.dialog.closeAll();
            $scope.CheckSettlementStatusAndUpdateButtons(CompScope);
            if ($scope.SettledAmt == $scope.getSaleAmt()) {
                CompScope.TenderRetainData();
            } else {
                $scope.SwitchToMultiPayment('', CompScope.returnTransitionDetails, true)
            }
        }
    }

    /**
     * @description Retrieves the Patron/Player details from CMS system through Payment Microservice
     * @param patronId 
     */
    async GetCMSPlayerInfo(patronId: string, tenderId = 0, pinNumber = "", includePointsCompsDetails = false): Promise<CMSPlayerInfo> {
        let outletDetail = this._ss.GetSelectedOutletDetail();
		let outletProfitCenter = Number((outletDetail && outletDetail?.profitCenter)? outletDetail.profitCenter : 0) ;
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "GetPatronInformation",
            method: HttpMethod.Post,
            uriParams: {},
            body: <CMSPatronLookupRequest>{
                playerId: patronId
                , pinNumber: pinNumber
                , includePointsCompsDetails: includePointsCompsDetails
                , outletProfitCenter
                , tenderId
                , excludeFutureOffers: true
            },
            showError: true
        });

        let playerInfo: CMSPlayerInfo = response.result;
        if (playerInfo && playerInfo?.returnCode == SMReturnCode.Success) {
            //Re-Order the Vouchers based on expiration Date
            playerInfo.cmsBalanceDetails.offerRedemption.vouchers = _.orderBy(playerInfo.cmsBalanceDetails.offerRedemption.vouchers
                , [voucher => new Date(voucher.voucherExpirationDate)], 'asc')
        }
        return playerInfo;
    }


    /**
     * @description Retrieves the Account details from CMS system through Payment Microservice
     * @param patronId 
     */
    async GetAccountInfo(patronId: string, pinNumber = "", tenderId = 0, includePointsCompsDetails = false): Promise<CMSAccountInfo> {
        let outletDetail = this._ss.GetSelectedOutletDetail();
		let outletProfitCenter = Number((outletDetail && outletDetail?.profitCenter)? outletDetail.profitCenter : 0) ;
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "GetAccountInformation",
            method: HttpMethod.Post,
            uriParams: {},
            body: <CMSAccountLookupRequest>{
                playerId: patronId
                , pinNumber: pinNumber
                ,tenderId: tenderId
                ,includePointsCompsDetails: includePointsCompsDetails
                ,outletProfitCenter: outletProfitCenter
            },
            showError: true
        });

        let accountInfo: CMSAccountInfo = response.result;
        return accountInfo;
    }

    /**
     * @description Performs Post voucher redemption logic
     * @param $scope 
     * @param retailTransResponse 
     * @param isReturnWithTicket 
     * @param compScope 
     */
    async PerformVoucherRedemption($scope: any, retailTransResponse, isReturnWithTicket: boolean = false, compScope?: ShopServiceitemSummaryComponent) {
        if ($scope.selectedVoucher && $scope.cmsPlayerInfo && ($scope._pblh.IsFolioPosting || (retailTransResponse && retailTransResponse.transactionData))) {
            $scope.isAPICallInProgress = true;
            let outletDetail = this._ss.GetSelectedOutletDetail();
            let outletProfitCenter = Number((outletDetail && outletDetail?.profitCenter)? outletDetail.profitCenter : 0) ;
            $scope.selectedVoucher.tenderId = $scope.selectedpayment?.paymentTypeId;
            $scope.selectedVoucher.outletProfitCenter = outletProfitCenter;
            let updateRedeemResponse = this.RedeemVoucher($scope.cmsPlayerInfo.playerID, $scope.selectedVoucher);
            updateRedeemResponse.then(async (res) => {
                $scope.isAPICallInProgress = false;
                if (res?.returnCode == SMReturnCode.Success) {
                    //Check if we need to switch to Multi payment
                    let switchToMulti: boolean = ($scope.selectedVoucher.voucherAmount < $scope.remainingAmount);
                    let diffAmt = 0;
                    diffAmt = $scope.remainingAmount < $scope.selectedVoucher.voucherAmount ? $scope.remainingAmount : $scope.selectedVoucher.voucherAmount;

                    if (isReturnWithTicket && compScope) {
                        if (switchToMulti)
                            $scope.SwitchToMultiPayment({ amount: { authorisedAmount: diffAmt } }, compScope.returnTransitionDetails, true);
                        await $scope.ValidatePayRequest(compScope, compScope.returnTransitionDetails, '', diffAmt, res.transactionId);
                        compScope.CMSForm.patchValue({ voucherSelection: '' });
                    } else {
                        if (switchToMulti)
                            $scope._pblh.SwitchToMultiPayment({ amount: { authorisedAmount: diffAmt } }, true);
                        await $scope._pblh.ValidatePayRequest('', false, diffAmt, res.transactionId);
                        $scope.CMSForm.patchValue({ voucherSelection: '' });
                    }
                } else {
                    console.log(res);
                    const clickReturnValue = {
                        from: ActionMode.cancel
                    };
                    $scope.TransactionCompleted.emit(clickReturnValue);
                    $scope.dialog.closeAll();
                    this.utils.showError(res?.message);
                }
            }).catch((err) => {
                console.log(err);
                $scope.isAPICallInProgress = false;
                const clickReturnValue = {
					from: ActionMode.cancel
				};
                $scope.TransactionCompleted.emit(clickReturnValue);
                $scope.dialog.closeAll();
                $scope.utils.ShowError(
                    $scope.localization.captions.common.Error,
                    $scope.utils.replacePlaceholders(
                        $scope.ShopCaptions.PaymentTransactionFailureMsg,
                        ['paymentmethod'],
                        [PaymentMethodDisplayLabel[$scope.selectedpayment.paymentTypeId]]
                    )
                );
            });
        }
    }


    /**
     * @description Performs Points redemption logic
     * @param $scope 
     * @param retailTransResponse 
     * @param saleAmount
     * @param isReturnWithTicket 
     * @param compScope 
     */
    async PerformPointRedemption($scope: any, retailTransResponse, saleAmount, isReturnWithTicket: boolean = false, compScope?: ShopServiceitemSummaryComponent) {
        if ($scope.selectedCMSType && ($scope._pblh?.IsFolioPosting || (retailTransResponse && retailTransResponse?.transactionData))) {
            $scope.isAPICallInProgress = true;
            let outletDetail = this._ss.GetSelectedOutletDetail();
            let outletProfitCenter = Number((outletDetail && outletDetail?.profitCenter)? outletDetail.profitCenter : 0) ;
            let redeemPointsRequest: RedeemPointsRequest = {
                playerId: $scope.selectedCMSType.playerId,
                playerPin: $scope.selectedCMSType.playerPin,
                pointsType: $scope.selectedCMSType.type,
                amount: saleAmount,
                currencyCode: '',
                checkNumber:  $scope._pblh?.IsFolioPosting ? $scope.AdditionalInputs.sourceTypeId : retailTransResponse.transactionData.retailTicketNumber.toString(),
                outletId: $scope._pblh?.IsFolioPosting ? $scope.AdditionalInputs.outletId : retailTransResponse.transactionData.outletId,
                outletProfitCenter: outletProfitCenter
            }
            let updatePointsRedeemResponse = this.RedeemPoints(redeemPointsRequest);
            updatePointsRedeemResponse.then(async (res) => {
                $scope.isAPICallInProgress = false;
                if (res?.returnCode == SMReturnCode.Success) {
                    //Check if we need to switch to Multi payment
                    let switchToMulti: boolean = (Math.abs(saleAmount) < Math.abs($scope.remainingAmount));
                    let diffAmt = 0;
                    diffAmt = Math.abs($scope.remainingAmount) <= Math.abs(saleAmount) ? $scope.remainingAmount : saleAmount;

                    if (isReturnWithTicket && compScope) {
                        if (switchToMulti)
                            $scope.SwitchToMultiPayment({ amount: { authorisedAmount: diffAmt } }, compScope.returnTransitionDetails, true);
                        await $scope.ValidatePayRequest(compScope, compScope.returnTransitionDetails, '', diffAmt, "", res.transactionId);
                    } else {
                        if (switchToMulti)
                            $scope._pblh.SwitchToMultiPayment({ amount: { authorisedAmount: diffAmt } }, true);
                        await $scope._pblh.ValidatePayRequest('', false, diffAmt, "", res.transactionId);
                    }
                } else {
                    console.log(res);
                    const clickReturnValue = {
                        from: ActionMode.cancel
                    };
                    $scope.TransactionCompleted.emit(clickReturnValue);    
                    $scope.dialog.closeAll();
                    this.utils.showError(res?.message);
                }
            }).catch((err) => {
                console.log(err);
                $scope.isAPICallInProgress = false;
                const clickReturnValue = {
					from: ActionMode.cancel
				};
                $scope.TransactionCompleted.emit(clickReturnValue);
                $scope.dialog.closeAll();
                $scope.utils.ShowError(
                    $scope.localization.captions.common.Error,
                    $scope.localization.replacePlaceholders(
                        $scope.ShopCaptions.PaymentTransactionFailureMsg,
                        ['paymentmethod'],
                        [PaymentMethodDisplayLabel[$scope.selectedpayment.paymentTypeId]]
                    )
                );
            });
        }
    }


    /**
     * @description Performs Voucher Redemption
     * @param patronId 
     * @param voucher
     * @returns VoucherRedemptionResponse
     */
    async RedeemVoucher(patronId: string, voucher: Voucher): Promise<VoucherRedemptionResponse> {
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "RedeemVoucher",
            method: HttpMethod.Post,
            uriParams: { patronId: patronId },
            body: voucher
        });
        return response.result;
    }

    /**
 * @description Performs Points/Comps Redemption
 * @param redeemPointsRequest
 * @returns RedeemPointsResponse
 */
    async RedeemPoints(redeemPointsRequest: RedeemPointsRequest): Promise<RedeemPointsResponse> {
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: "RedeemPoints",
            method: HttpMethod.Post,
            body: redeemPointsRequest
        });
        return response.result;
    }

    DisableOfferOrCompSlipIfVoucherRedeemed($scope: any) {
        if ($scope.SettlementHistory.some(x => x.paymentMethodId == PaymentMethods.OfferRedemption && !x.isReversed)) {
            $scope.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.OfferRedemption).isDisabled = true;
        }
        if ($scope.SettlementHistory.some(x => x.paymentMethodId == PaymentMethods.CompSlipRedemption && !x.isReversed)
            && $scope.paymentMethods.some(x =>  this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CompSlipRedemption)) {
            $scope.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.CompSlipRedemption).isDisabled = true;
        }
    }

    CheckIfOfferOrCompSlipCanBeAppliedAndPrefill($scope: ShopBussinessService) {
        let allowedToProceed: boolean = true;
        let refundAmt = 0;        
        if ($scope.TransactionPayments.some(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId)) {
            const remainingAmt = Math.abs($scope.remainingAmount);
            if ($scope.selectedpayment.paymentTypeId != PaymentMethods.CompRedemption) {
                //Only one offer can be applied for a transaction, so using find to get the offer
                let offerTransactionHist = $scope.TransactionPayments.find(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId);                
                refundAmt = this.IsCompleteReturn($scope) && remainingAmt < offerTransactionHist.paymentAmount ? remainingAmt : offerTransactionHist.paymentAmount;
                if (Math.abs(offerTransactionHist.paymentAmount) > remainingAmt && !this.IsCompleteReturn($scope)) {
                    allowedToProceed = false;
                    const errMsg = $scope.localization.replacePlaceholders(
                        $scope.localization.getError(100025)
                        , ["voucherType"]
                        , [$scope.SelectedPaymentMethodEquals(PaymentMethods.OfferRedemption) ? "Offer" : "Comp Slip"]
                    );
                    $scope.utils.ShowErrorMessage($scope.localization.captions.common.Error, errMsg);
                    refundAmt = offerTransactionHist.paymentAmount;
                }
            } else {
                $scope.TransactionPayments.filter(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId)?.forEach(x => refundAmt += x.paymentAmount);
                refundAmt = remainingAmt < refundAmt ? remainingAmt : refundAmt;
            }
        }
        return [allowedToProceed, refundAmt];
    }

    ConstructCMSPaymentRecord($scope, CompScope, SaleResult, transactionId, compTransactionId, formGroupObj?, saleAmount = 0) {
        if (!$scope.IsCMSPayment) return null;
        const tempSaleResult = SaleResult ? SaleResult.amount : '';
        const temp = SaleResult ? SaleResult.amount : '';
        const amount = !formGroupObj ? $scope.getAmountPaid(tempSaleResult, 'authorisedAmount') :
            $scope.getAmountPaid(formGroupObj, temp, 'authorisedAmount', saleAmount);
        
        const redemptionTypeMap = {
            [PaymentMethods.CompRedemption]: RedemptionType[CompScope.CMSForm.controls["cmsPaymentSelection"].value],
            [PaymentMethods.OfferRedemption]: CMSRedemptionType.Offers,
            [PaymentMethods.CompSlipRedemption]: CMSRedemptionType.CompSlip
        }
        const paymentTypeId = ($scope.selectedpayment.parentTypeId == null || $scope.selectedpayment.parentTypeId == undefined || $scope.selectedpayment.parentTypeId == 0) ?  $scope.selectedpayment.paymentTypeId : $scope.selectedpayment.parentTypeId;
        let patronId = $scope.selectedCMSPlayer && $scope.selectedCMSPlayer.PatronId;
        let patronName =  $scope.selectedCMSPlayer && $scope.selectedCMSPlayer.PlayerName;
        let redemptionType = redemptionTypeMap[paymentTypeId];

        if($scope?._pblh?.IsFolioPosting){
            patronId = ($scope?.selectedCMSType?.playerId ??  $scope.selectedCMSType?.inquiryInfo?.id) ?? patronId;
            patronName = $scope?.selectedCMSType?.name ?? patronName;
            redemptionType = $scope?.selectedCMSType?.type ?? redemptionType;
        }
        
        return $scope.IsCMSPayment ? {
            amount: amount,
            patronId: patronId,
            patronName: patronName,
            redemptionType: redemptionType,
            name: $scope.selectedVoucher && $scope.selectedVoucher.name,
            voucherId: $scope.selectedVoucher && $scope.selectedVoucher.voucherId,
            voucherType: $scope.selectedVoucher && $scope.selectedVoucher.voucherType,
            voucherExpirationDate: $scope.selectedVoucher && $scope.selectedVoucher.voucherExpirationDate,
            voucherRedeemTransactionId: transactionId,
            vendorType: $scope.selectedVoucher && $scope.selectedVoucher.vendorType,
            playerPin: CompScope.CMSPin,
            compRedeemTransactionId: compTransactionId,
            retailTicketNumber: $scope.CurrentTryPayResponse?.ticketNumber ?? 0,
            offerPaymentMethodId: $scope.selectedVoucher?.offerPaymentMethodId ?? 0
        } as CMSPaymentDetails : null;
    }

    async GetPaymentTransaction(transactionId: number): Promise<TransactionPayment[]> {
        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: "GetTransactionPayment",
            method: HttpMethod.Get,
            uriParams: { transactionId: transactionId }
        });
        return response.result;
    }

    async CreatePatronGuest(patronId) {
        let existingPlayer = await RetailDataAwaiters.GetExistingPlayer(patronId);
        if (existingPlayer == null) {
            let infoMsg = this._localization.replacePlaceholders(
                this._localization.captions.guest.players.CreateGuestWithPatronID,
                ['patronId'],
                [patronId]
            );
            this.utils.ShowErrorMessage(this._localization.captions.common.Information, infoMsg, GlobalConst.ButtonType.YesNo, this.openCreatePlayerPopup.bind(this), [patronId]);
        }
    }

    openCreatePlayerPopup(result: string, extraParams) {
        if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase()) {
            RetailDataAwaiters.openGuestPatronPopup("orderSummary", () => {
                //call back method
            }, extraParams[0]);
        }
    }

    async GetTransactionPaymentWithAdditionalDetails(transactionId) {
        try {
            return await this.http.CallApiAsync<TransactionPaymentAggregate[]>({
                host: GlobalConst.Host.retailPOS,
                callDesc: "GetTransactionPaymentWithAdditionalDetails",
                method: HttpMethod.Get,
                uriParams: { transactionId: transactionId }
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
}
