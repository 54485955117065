import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { DownLoadDataFilter, PostDownloadZplData, PrintDataModel, PrintLabelModel, PrinterInterface } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrinterCollection, PrinterModelConfig, TemplateInterface } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import * as APIURLConstants from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { printerType } from 'src/app/eatecui/source/shared/enum/global.enum';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPopModel } from '../model/pop-up.interface';
import { AgilysysConfirmationPopupComponent } from '../agilysys-confirmation-popup/agilysys-confirmation-popup.component';

@Component({
  selector: 'app-agilysys-printer-popup',
  templateUrl: './agilysys-printer-popup.component.html',
  styleUrls: ['./agilysys-printer-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysPrinterPopupComponent implements AfterViewInit {
  agilysysCofirmationButton: AgilysysButtonModel[];
  homeGroup: FormGroup;
  PrinterCollection: Array<PrinterCollection>;
  selectedPrinterId: number;
  selectedTemplateId: number;
  defaultPrinterName: string;
  selectedPrinter: string;
  SelectedPrinterListId: number;
  enableLoader = false;
  constructor(public dialogRef: MatDialogRef<AgilysysPrinterPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PrinterInterface,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.homeGroup = this.formBuilder.group({});
    if (data) {
      this.agilysysCofirmationButton = [
        {
          displayProperity: false,
          displayName: 'Cancel',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callBackData: CallBackData) => {
            this.cancelPrint('Cancel');
          }
        },
        {
          displayProperity: false,
          displayName: 'Print',
          buttonKey: 'Confirm',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
            if( this.homeGroup.valid ) {
              const NewPrinterValue: string = this.homeGroup?.controls['ipaddress']?.value;
              if (this.selectedPrinter || (this.selectedPrinterId === 3 && NewPrinterValue)) {
                this.DownloadZplData()
              } else if (NewPrinterValue === '') {
                this.toastrService.error('Please enter valid Printer IP', '', {
                  timeOut: 3000,
                  closeButton: true,
                });
              } else {
                this.toastrService.error('Printer not mapped', '', {
                  timeOut: 3000,
                  closeButton: true,
                });
              }
            } else {
              this.homeGroup.updateValueAndValidity();
            }
           
          }
        }
      ];
      this.data.PrintFormType.forEach((printerFormType: FormType) => {
        printerFormType.fieldType.SingleData.onCellClick = (e, Field: any, Form: FormGroup) => {
          const templateId = Form.controls['printlabel'].value;
          this.selectPrinterTemplate(templateId);
        }
      })

      const defaultId = this.data.PrinterTypes[0].Id;
      this.selectedPrinterId = defaultId;

    }
  }

  /**
  * @method @selectPrinterTemplate
  * @inputParams TemplateId
  * @description selected the DefaultTemplate
  */
  selectPrinterTemplate(TemplateId: number): void {
    try {
      if (this.data.TemplateCollecation && this.data.TemplateCollecation.length > 0) {
        const selectedTemplate: Array<TemplateInterface> = this.data.TemplateCollecation.filter((e: TemplateInterface) => e.Id === TemplateId);
        if (selectedTemplate && selectedTemplate.length > 0) {
          this.defaultPrinterName = selectedTemplate[0].PrinterPath;
          this.selectedPrinter = selectedTemplate[0].PrinterPath
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method setDefaultPrinter
   * @input defaultPrinterName
   * @description User click the default button set defaultprinter name changed 
   */
  setDefaultPrinter(): void {
    try {
      this.selectedPrinter = this.defaultPrinterName;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method @selectPrinterCollection
   * @inputParams PrinterId
   * @description selected the default Printer
   */
  selectPrinterCollection(printerId: number): void {
    try {
      if (printerId !== this.selectedPrinterId || (!this.PrinterCollection)) {
        this.selectedPrinterId = printerId;
        const PrinterModel: Array<PrinterModelConfig> = this.data.PrinterTypes.filter((printModel: PrinterModelConfig) => printModel.Id === printerId);
        this.PrinterCollection = [...PrinterModel[0].PrinterColl];
        if (printerId === 2) {
          this.GetLocalPrinter();
        } else if (printerId === 1) {
          this.GetNetworkPrinter();
        }
        this.changeDetector.detectChanges();
      }
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method GetLocalPrinter
   * @inputParams None
   * desacription GetLocalPrinter
   */
  GetLocalPrinter(): void {
    try {
      this.PrinterCollection = [];
      const GetLocalPrinterUrl = this.data.LocalPrinterUrl;
      this.data.httpClient.get(GetLocalPrinterUrl).subscribe((localPrinter: Array<any>) => {
        if (localPrinter.length > 0) {
          localPrinter.forEach((p: any) => {
            const pCollection = {} as PrinterCollection;
            pCollection.Id = p;
            pCollection.Name = p;
            pCollection.SelectionId = p;
            this.PrinterCollection.push(pCollection);
          })
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
  * @method GetNetworkPrinter
  * @inputParams None
  * desacription GetLocalPrinter
  */
  GetNetworkPrinter(): void {
    try {
      const templateId = this.homeGroup.controls['printlabel'].value;
      this.PrinterCollection = [];
      let GetNetworkPrinterUrl = `${this.data.NetworkPrinterUrl}?reportId=` + `${templateId}`;
      if (this.data?.locationId) {
        GetNetworkPrinterUrl = `${this.data.NetworkPrinterUrl}?reportId=` + `${templateId}&locationId=` + this.data.locationId;
      }
      this.data.httpService.GethttpMethod(GetNetworkPrinterUrl).subscribe((NetworkPrinter: Array<any>) => {
        if (NetworkPrinter.length > 0) {
          let i = 0;
          NetworkPrinter.forEach((p: any) => {
            const pCollection = {} as PrinterCollection;
            pCollection.SelectionId = i++;
            pCollection.Id = p.PrinterHost;
            pCollection.Name = p.PrinterHost;
            pCollection.HelpText = p.PrinterDescription;
            pCollection.IsPrimary = p.IsPrimary;
            if (p.IsPrimary) {
              this.SelectedPrinterListId = pCollection.SelectionId;
            }
            this.PrinterCollection.push(pCollection);
          })
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method selectprintList
   * @input list option
   * @selected printer to the list
   */
  selectprintList(selectedPrinter: PrinterCollection): void {
    try {
      this.selectedPrinter = selectedPrinter.Name;
      this.SelectedPrinterListId = selectedPrinter.SelectionId;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method GetPrinterData
   * @input None
   * @description GetPrinterdata from the list
   */
  DownloadZplData(): void {
    try {
      const SelectedTemplateId = this.homeGroup.controls['printlabel'].value;
      const postDownloadZplData = {} as PostDownloadZplData;
      postDownloadZplData.ReportId = SelectedTemplateId;
      postDownloadZplData.Filters = [];
      const dataFilters = {} as DownLoadDataFilter;
      dataFilters.Property = 'id';
      dataFilters.Value = this.data.selectedId.toString();
      postDownloadZplData.Filters.push(dataFilters)
      if( this.data?.EnableCountText ){
        const inventoryPrint: Array<string> = [ 'printqty', 'isretail'];
        inventoryPrint.forEach((x: string)=>{
          const dataInventoryFilters = {} as DownLoadDataFilter;
          dataInventoryFilters.Property = x;
          dataInventoryFilters.Value = (x === 'printqty') ? this.homeGroup.value.printlabelcount.toString() : this.data.isretail.toString();
          postDownloadZplData.Filters.push(dataInventoryFilters)
        })
      }
      postDownloadZplData.IanaTimeZoneId = 'GST';
      postDownloadZplData.UserTimeZoneId = 'GST';
      const downLondZplData: string = APIURLConstants.ENDPOINTURL['Report']['GetZplDownloadData'];
      this.data.httpService.PosthttpMethod(downLondZplData, postDownloadZplData).subscribe((printerData: PrintDataModel) => {
        this.PrintLabel(printerData);
      })

    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method PrintLabel
   * @input Params
   * @description download printedLable
   */
  PrintLabel(printerData: PrintDataModel): void {
    try {
      const NewPrinterIpAddress = this.homeGroup?.controls['ipaddress']?.value;
      const selectedPrinter = (this.selectedPrinterId === 3 && NewPrinterIpAddress) ? NewPrinterIpAddress : this.selectedPrinter;
      if (selectedPrinter) {
        const validateIpAddress: boolean = this.checkValidIpAddress(selectedPrinter.trim())
        const printerModel = {} as PrintLabelModel;
        printerModel.IPAddress = (validateIpAddress) ? selectedPrinter : null;
        printerModel.printerType = (validateIpAddress) ? printerType.NW : printerType.RP;
        printerModel.PrinterName = selectedPrinter;
        printerModel.Data = printerData.ZplData;
        if (this.selectedPrinterId === 1 || this.selectedPrinterId === 2) {
          printerModel.PrinterName = this.selectedPrinter;
          printerModel.Data = printerData.ZplData;
        } else {
          printerModel.Data = printerData.ZplData;
        }
        this.enableLoader = true;
        this.data.httpClient.post(this.data.PrinterUrl, printerModel).subscribe(x => {
          this.enableLoader = false;
          if (x.toString().toLowerCase() === 'success') {
            this.toastrService.success('Label Printing Inprogress....', '', {
              timeOut: 3000,
              closeButton: true,
            });
          } else {
            this.toastrService.error('Configure a valid printer.', '', {
              timeOut: 3000,
              closeButton: true,
            });
          }

        }, (error) => {
          this.enableLoader = false;
        });
      } else {
        this.toastrService.error('Printer not configured....', '', {
          timeOut: 3000,
          closeButton: true,
        });
      }

    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method checkValidIpAddress
   * @Input 
   * @description check valid address
   */
  checkValidIpAddress(Printerpath: any): boolean {
    try {
      const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
      const IsIpAddress: boolean = regexExp.test(Printerpath);
      return IsIpAddress;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method cancelPrint
   */
  cancelPrint(type: string): void {
    if (type === 'Cancel') {
      if (this.enableLoader) {
        const confirmPopModel = {} as ConfirmPopModel;
        confirmPopModel.PopUpContent = `Are you sure you wish to stop print job?`;
        confirmPopModel.PopupHeaderContent = 'Confirmation';
        const dialogRefss = this.dialog.open(AgilysysConfirmationPopupComponent, {
          width: '440px',
          height: '220px',
          data: confirmPopModel,
          autoFocus: false,
          disableClose: true
        });
        dialogRefss.afterClosed().subscribe(res => {
          if (res === 'Confirm') {
            this.dialog.closeAll();
          }
        });
      } else {
        this.dialog.closeAll();
      }
    }
  }
  ngAfterViewInit(): void {
    const GetTemplateId = this.homeGroup.controls['printlabel'].value;
    this.selectPrinterTemplate(GetTemplateId);
    this.selectPrinterCollection(this.selectedPrinterId);
  }
}
