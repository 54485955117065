import { Component, OnInit } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';

@Component({
  selector: 'lib-number-cell-editor',
  templateUrl: './number-cell-editor.component.html',
  styleUrls: ['./number-cell-editor.component.css']
})
export class NumberCellEditorComponent implements OnInit {
  params: any;
  input: any;
  dotPressed = false;
  DecimalFormatter: Intl.NumberFormat;
  CurrencyFormatter: Intl.NumberFormat;
  div: HTMLDivElement;
  value: any;
  constructor() { }

  ngOnInit(): void {
  }
  agInit(params: ICellEditorParams) {
  }

  keyEnterMethod(key: any, input: HTMLElement, keycode: string) {
    const rowIndex = keycode === KEY_UP ? key.rowIndex - 1 : key.rowIndex + 1;
    const firstCol = key.columnApi.getAllDisplayedColumns()[0];
    key.api.ensureColumnVisible(firstCol);
    let NlcBool = true;
    key.api.forEachNode(function(rowNode: any, index: any) {
         if (index >= rowIndex) {
           if (rowNode.data[key.colDef.field] !== 'N_C' && NlcBool) {
            // key.api.clearFocusedCell();
            key.api.stopEditing();
            key.api.setFocusedCell(rowIndex, key.colDef.field);
            key.api.startEditingCell({
              rowIndex: rowIndex,
              colKey:  key.colDef.field
            });
            NlcBool = false;
           }
         }
    });
  }

  init(params) {
    const _thatmethod = this;
    this.params = params;
    this.value = this.params.value || this.params.value === 0 ? this.params.value : '';
    this.div = document.createElement('div');
    this.div.setAttribute('class', 'input-div');
    this.input = document.createElement('input');
    this.input.type = 'text';
    this.input.setAttribute('class', 'input-number');
    this.input.setAttribute('id', 'input-number-touch');
    this.input.setAttribute('autocomplete', 'off');
    if (params.colDef.hasOwnProperty('placeholder') && params.colDef.placeholder) {
      this.input.setAttribute('placeholder', 'in C' + String.fromCharCode(176));
    }
    this.input.value = this.value;
    this.params.charPress = params.charPress ? params.charPress : '';
    this.div.appendChild(this.input);
    this.input.addEventListener('input', this.quantityCalc.bind(this));
    const alloWDecimalDigit = this.params.colDef.focusDigits;
    const alloWDigit = this.params.colDef.allowDigits;
    const noNegativeSign = params.NoNegativeSign;
    this.input.addEventListener('paste', function (event) {
      const a = (event.originalEvent || event).clipboardData.getData('text/plain');
      if (noNegativeSign && parseInt(a, 10) < 0) {
        event.preventDefault();
      }
      if (isNaN((a.split(',')).join(''))) {
        event.preventDefault();
      } else {
        if (a.length > 20 || (a.split('.')[1] &&
          a.split('.')[1].length >= alloWDecimalDigit)) {
          event.preventDefault();
        } else {
          event.target.value = '';
          return true;
        }
      }
    });

    this.input.addEventListener('keydown', function (event) {
      if (noNegativeSign && event.key === '-') {
        event.preventDefault();
      }
      if ((event.key.toString().toLowerCase() === 'v' || event.key.toString().toLowerCase() === 'c'
        || event.key.toString().toLowerCase() === 'a') && event.ctrlKey === true) {
        return true;
      }
      // event.key === 'Enter' ||
      if (event.key === KEY_ENTER) {
        _thatmethod.keyEnterMethod(params, _thatmethod.input, event.key);
        _thatmethod.params.context.parentComponent.selectedColumn = params.colDef.field;
      }

      if (event.key === KEY_ESCAPE) {
        _thatmethod.params.api.stopEditing();
      }

      // Added for Mac Support
      if (event.metaKey === true) {
        return true;
      }
      const _that = this;
      const hasDot = event.target.value.indexOf('.');
      const upStartIndex = event.target.selectionStart;
      const key = event.key;
      // Not to allow \ and >
      if (event.key.trim() === '' || event.key.trim() === '>' || event.keyCode === 220 || (event.key.trim() === '-' && noNegativeSign)) {
        event.preventDefault();
      }
      // Not to allow double dot
      if (hasDot !== -1 && (event.keyCode === 190 || event.keyCode === 110)) {
        event.preventDefault();
      }
      /****To Prevent Special Characters & allow .,-,backspace****/
      // if (isNaN(event.key) && (event.keyCode !== 190 && event.keyCode !== 8 && event.key !== '-') && event.keyCode !== 110) {
      //     event.preventDefault();
      // }
      if (hasDot !== -1 || event.keyCode === 190 || event.keyCode === 110) {
        const splitValues = event.target.value.split('.');
        if (splitValues[1] && splitValues[1].length > 0) {
          if (splitValues[1] && splitValues[1].length >= alloWDecimalDigit) {
            if (
              (key !== ')' &&  // 48 -ipad
                key !== '!' &&  // 49-ipad
                key !== '@' &&   // 50-ipad
                key !== '#' &&   // 51-ipad
                key !== '$' &&   // 52-ipad
                key !== '%' &&   // 53-ipad
                key !== '^' &&   // 54-ipad
                key !== '&' &&   // 55-ipad
                key !== '*' &&   // 56-ipad
                key !== '(' &&
                key !== '_' &&
                key !== '=' &&
                key !== '>' &&
                key !== '<' &&
                key !== '?' &&
                key !== '+' &&
                key !== ':' &&
                key !== ';' &&
                key !== '"' &&
                key !== ',' &&
                key !== '\'' &&
                key !== '{' &&
                key !== '}' &&
                key !== ']' &&
                key !== '[' &&
                key !== '|' &&
                key !== '`' &&
                key !== '~') &&
              (event.keyCode === 8 ||
                event.keyCode === 9 ||
                event.keyCode === 10 ||
                event.keyCode === 13 ||
                event.keyCode === 16 ||
                event.keyCode === 17 ||
                event.keyCode === 18 ||
                event.keyCode === 20 ||
                event.keyCode === 27 ||
                event.keyCode === 33 ||
                event.keyCode === 34 ||
                event.keyCode === 35 ||
                event.keyCode === 36 ||
                (event.keyCode >= 37 && event.keyCode <= 40) ||
                event.keyCode === 45 ||
                event.keyCode === 46 ||
                (event.keyCode === 112 && event.keyCode === 123) ||
                event.keyCode === 127 ||
                event.keyCode === 144 ||
                (upStartIndex === 0 && event.keyCode === 189) ||
                (upStartIndex === 0 && event.keyCode === 109)
              )
            ) {
              // Allow backspace,tab,arrow keys
              // console.log('inside 1');
              return true;
            } else {
              // console.log('inside 2');
              event.preventDefault();
            }
          } else {
            if (
              (event.keyCode >= 65 && event.keyCode <= 90) ||
              (
                (key === ')' ||  // 48 -ipad
                  key === '!' ||  // 49-ipad
                  key === '@' ||   // 50-ipad
                  key === '#' ||   // 51-ipad
                  key === '$' ||   // 52-ipad
                  key === '%' ||   // 53-ipad
                  key === '^' ||   // 54-ipad
                  key === '&' ||   // 55-ipad
                  key === '*' ||   // 56-ipad
                  key === '(' ||
                  key === '_' ||
                  key === '=' ||
                  key === '>' ||
                  key === '<' ||
                  key === '?' ||
                  key === '+' ||
                  key === ':' ||
                  key === ';' ||
                  key === '"' ||
                  key === ',' ||
                  key === '\'' ||
                  key === '/' ||
                  key === '{' ||
                  key === '}' ||
                  key === ']' ||
                  key === '[' ||
                  key === '|' ||
                  key === '`' ||
                  key === '~'
                )
              ) ||
              (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109)
              )) {
              // Prevent alphabets values
              // console.log('inside 3');
              event.preventDefault();
            } else {
              // console.log('inside 4');
              if (event.target.value.split('.')[0].length >= alloWDigit) {
                if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
                  (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
                    (event.keyCode === 189 || event.keyCode === 109))) {
                  // Prevent alphabets values and numeric values if length greater than allowed length
                  // console.log('inside 5');
                  event.preventDefault();
                } else {
                  // console.log('inside 6');
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        } else {
          if (
            (event.keyCode >= 65 && event.keyCode <= 90) ||
            (
              (key === ')' ||  // 48 -ipad
                key === '!' ||  // 49-ipad
                key === '@' ||   // 50-ipad
                key === '#' ||   // 51-ipad
                key === '$' ||   // 52-ipad
                key === '%' ||   // 53-ipad
                key === '^' ||   // 54-ipad
                key === '&' ||   // 55-ipad
                key === '*' ||   // 56-ipad
                key === '(' ||
                key === '_' ||
                key === '=' ||
                key === '>' ||
                key === '<' ||
                key === '?' ||
                key === '+' ||
                key === ':' ||
                key === ';' ||
                key === '"' ||
                key === ',' ||
                key === '\'' ||
                key === '/' ||
                key === '{' ||
                key === '}' ||
                key === ']' ||
                key === '[' ||
                key === '|' ||
                key === '`' ||
                key === '~'
              )
            ) ||
            (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109)
            )) {
            // Prevent alphabets values
            // console.log('inside 3');
            event.preventDefault();
          } else {
            // console.log('inside 4');
            if (event.target.value.split('.')[0].length >= alloWDigit) {
              if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
                (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
                  (event.keyCode === 189 || event.keyCode === 109))) {
                // Prevent alphabets values and numeric values if length greater than allowed length
                // console.log('inside 5');
                event.preventDefault();
              } else {
                // console.log('inside 6');
                return true;
              }
            } else {
              return true;
            }
          }
        }
      } else {
        if (event.target.value.length >= alloWDigit) {
          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
            (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
              (event.keyCode === 189 || event.keyCode === 109))) {
            // Prevent alphabets values and numeric values if length greater than allowed length
            // console.log('inside 5');
            event.preventDefault();
          } else {
            // console.log('inside 6');
            return true;
          }
        } else {
          if ((event.keyCode >= 65 && event.keyCode <= 90) ||
            (
              (key === ')' ||  // 48 -ipad
                key === '!' ||  // 49-ipad
                key === '@' ||   // 50-ipad
                key === '#' ||   // 51-ipad
                key === '$' ||   // 52-ipad
                key === '%' ||   // 53-ipad
                key === '^' ||   // 54-ipad
                key === '&' ||   // 55-ipad
                key === '*' ||   // 56-ipad
                key === '(' ||
                key === '_' ||
                key === '=' ||
                key === '>' ||
                key === '<' ||
                key === '?' ||
                key === '+' ||
                key === ':' ||
                key === ';' ||
                key === '"' ||
                key === ',' ||
                key === '\'' ||
                key === '/' ||
                key === '{' ||
                key === '}' ||
                key === ']' ||
                key === '[' ||
                key === '|' ||
                key === '`' ||
                key === '~')
            ) || (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109))) {
            // Prevent alphabets values and - minus values
            // console.log('inside 7');
            event.preventDefault();
          } else {
            // console.log('inside 8');
            return true;
          }
        }
      }
    });


  }

  quantityCalc(event, from = '') {
    this.params.context.parentComponent.selectedColumn = this.params.colDef.field;
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    if (this.params.data && this.params.data.hasOwnProperty('Units') && this.params.data.UnitTypeId && this.params.data.Units.length > 0
    && this.params.colDef.from !== 'recipe-additem') {
    const nextUnit = this.params.data.Units.filter(x => x.UnitTypeId === this.params.data.UnitTypeId);
    const Valued = nextUnit && nextUnit.length > 0 ? nextUnit[0].UnitCost * event.target.value : 0;
    // rowNode.setDataValue('Qty', event.target.value);
    // rowNode.setDataValue('Valued', Valued);
    let calAmount: any = 0.0;
    let calCost: any = 0.0;
    let quantity: any = 0.0;
    let packSize: any = 0;
    let allocCharges: any = 0.0;
    if (this.params.data.Valued !== undefined && this.params.data.Valued !== '') {
      calAmount = this.params.data.Valued;
    }
    if (this.params.data.hasOwnProperty('Charges') && this.params.data.Charges !== undefined && this.params.data.Charges !== '') {
      allocCharges = this.params.data.Charges;
    }
    if (this.params.data.UnitCost !== undefined && this.params.data.UnitCost !== '') {
      calCost = this.params.data.UnitCost;
    }
    if (this.params.data.Qty !== '') {
      quantity = this.params.data.Qty;
    }
    if (this.params.data.Conversion !== '') {
      packSize = this.params.data.Conversion;
    }
    calAmount = calAmount.toString().split(',').join('');
    calCost = calCost.toString().split(',').join('');
    quantity = quantity ? quantity.toString().split(',').join('') : 0;
    if (isNaN(calAmount)) {
      calAmount = 0;
    } else {
      calAmount = parseFloat(calAmount);
    }
    if (isNaN(calCost)) {
      calCost = 0;
    } else {
      calCost = parseFloat(calCost);
    }
    if (isNaN(quantity)) {
      quantity = 0;
    } else {
      quantity = parseFloat(quantity);
    }
    if (isNaN(packSize)) {
      packSize = 0;
    }
    if (this.params.colDef.field === 'Valued') {
      calAmount = event.target.value;
      calAmount = calAmount.toString().split(',').join('');
      const valued = calAmount ? parseFloat(calAmount) : 0;
      if (+quantity === 0) {
        quantity = 1;
        calCost = +valued;
      } else {
        calCost = +valued / +quantity;
      }
      const calCostColumn = this.params.columnApi.getColumn('UnitCost');
      const DecimalValue = calCostColumn ?  calCostColumn.colDef.focusDigits :  6;
      calCost = parseFloat(calCost).toFixed(DecimalValue);
    } else if (this.params.colDef.field === 'UnitCost') {
      calCost = event.target.value;
      calCost = calCost.toString().split(',').join('');
      const unitCost = calCost ? parseFloat(calCost) : 0;
      calAmount = +unitCost * +quantity;
      quantity = quantity !== '' ? parseFloat(quantity) : '';
      const ValuedColumn = this.params.columnApi.getColumn('Valued');
      const DecimalValue = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
      calAmount = parseFloat(calAmount).toFixed(DecimalValue);
    } else if (this.params.colDef.field === 'Qty') {
      quantity = from === 'fromGui' ? event : event.target.value;
      quantity = quantity.toString().split(',').join('');
      const qty = quantity ? parseFloat(quantity) : 0;
      calAmount = (event.target && event.target.value === '-') ? 0.0 : +calCost * +qty;
      const ValuedColumn = this.params.columnApi.getColumn('Valued');
      const DecimalValue = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
      calAmount = parseFloat(calAmount).toFixed(DecimalValue);
      // console.log('quantity update', quantity, calCost, calAmount);
    } else if (this.params.colDef.field === 'Conversion') {
      if (packSize.toString() === '0') {
        packSize = 1;
      }
      let data: any = event.target.value ? parseFloat(event.target.value.toString()) : 0;
      if (data !== 0) {
        data = parseFloat(data.toString());
        // const prevVal = calCost / packSize;
        // calCost = prevVal * data;
        // calCost = calCost.toString().split(',').join('');
        // calAmount = +calCost * +quantity;
        // calAmount = parseFloat(calAmount).toFixed(3);
        const unitData =  this.params.data.Units.filter(x => x.UnitTypeId === 1 || x.UnitTypeId === 5)[0];
        let unitCost  = unitData.UnitTypeId > 4 ? unitData.CostPerUnit : unitData.UnitCost;
        unitCost = parseFloat(unitCost);
        calCost = unitCost * data;
        const unitCostColumn = this.params.columnApi.getColumn('UnitCost');
        const DecimalValue = unitCostColumn ?  unitCostColumn.colDef.focusDigits :  6;
        calCost = (calCost !== '' && calCost !== null && calCost !== undefined) ?
        parseFloat(calCost).toFixed(DecimalValue) : parseFloat('0').toFixed(DecimalValue);
        calAmount = +calCost * +quantity;
        const ValuedColumn = this.params.columnApi.getColumn('Valued');
        const DecimalValue1 = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
        calAmount = parseFloat(calAmount).toFixed(DecimalValue1);
      } else {
        calCost = 0;
        calAmount = 0;
      }
    } else if (this.params.colDef.field === 'Charges') {
      allocCharges = event.target.value;
      allocCharges = allocCharges.toString().split(',').join('');
      // calAmount = parseFloat(calAmount) + parseFloat(allocCharges);
      // calAmount = parseFloat(calAmount).toFixed(3);
    }
    calAmount = isFinite(calAmount) ? calAmount : 0;

    rowNode.setDataValue('Price', calAmount);
    rowNode.setDataValue('Valued', calAmount);
    rowNode.setDataValue('UnitCost', calCost);
    rowNode.setDataValue('Qty', quantity);
    rowNode.setDataValue('Charges', allocCharges);

    const Amtdata: GetCellEditorInstancesParams = {
      rowNodes: [rowNode],
      columns: ['Valued'],
    };
    const Amtinstances = this.params.api.getCellEditorInstances(Amtdata);
    if (Amtinstances.length > 0) {
      Amtinstances[0].params.data.Valued = calAmount;
      Amtinstances[0].value = calAmount;
      Amtinstances[0].params.value = calAmount;
      Amtinstances[0].input.value = calAmount;
    }

    const Costdata: GetCellEditorInstancesParams = {
      rowNodes: [rowNode],
      columns: ['UnitCost'],
    };
    const Costinstances = this.params.api.getCellEditorInstances(Costdata);
    if (Costinstances.length > 0) {
      Costinstances[0].params.data.UnitCost = calCost;
      Costinstances[0].value = calCost;
      Costinstances[0].params.value = calCost;
      Costinstances[0].input.value = calCost;
    }

    const Qtydata: GetCellEditorInstancesParams = {
      rowNodes: [rowNode],
      columns: ['Qty'],
    };
    const Qtyinstances = this.params.api.getCellEditorInstances(Qtydata);
    if (Qtyinstances.length > 0) {
      Qtyinstances[0].params.data.Qty = quantity;
      Qtyinstances[0].value = quantity;
      Qtyinstances[0].params.value = quantity;
      Qtyinstances[0].input.value = quantity;
    }
    const TaxAmount1: GetCellEditorInstancesParams = {
      rowNodes: [rowNode],
      columns: ['TaxAmount1'],
    };
    let TaxAmount = 0;
    const TaxRate1 = this.params.data.TaxRate1;
    if (TaxRate1) {
      TaxAmount = (TaxRate1 / 100) * parseFloat(calAmount);
    }
    const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
    if (TaxAmount1instances.length > 0) {
      TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmount;
      TaxAmount1instances[0].value = TaxAmount;
      TaxAmount1instances[0].params.value = TaxAmount;
      TaxAmount1instances[0].input.value = TaxAmount;
    }

    const TaxAmount2: GetCellEditorInstancesParams = {
      rowNodes: [rowNode],
      columns: ['TaxAmount2'],
    };
    const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
    let TaxAmountVal2 = 0;
    const TaxRate2 = this.params.data.TaxRate2;
    if (TaxRate2) {
      TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calAmount);
    }
    if (TaxAmount2instances.length > 0) {
      TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountVal2;
      TaxAmount2instances[0].value = TaxAmountVal2;
      TaxAmount2instances[0].params.value = TaxAmountVal2;
      TaxAmount2instances[0].input.value = TaxAmountVal2;
    }
    // if(this.params.data && this.params.data.hasOwnProperty('TaxAmount1') && this.params.data.hasOwnProperty('TaxAmount2')){
    //   if(TaxAmount !== 0 && TaxAmountVal2 !== 0){
    //     const TaxAmounts = TaxAmount + TaxAmountVal2;
    //     calAmount = parseInt(calAmount) + TaxAmounts;
    //     calAmount = parseFloat(calAmount).toFixed(3);
    //   }
    // }
    rowNode.setDataValue('TaxAmount1', TaxAmount);
    rowNode.setDataValue('TaxAmount2', TaxAmountVal2);
    // rowNode.setDataValue('Valued', calAmount);
    // const Estimationdata: GetCellEditorInstancesParams = {
    //   rowNodes: [rowNode],
    //   columns: ['Valued'],
    // };
    // const Estimationinstances = this.params.api.getCellEditorInstances(Estimationdata);
    // if (Estimationinstances.length > 0) {
    //   Estimationinstances[0].params.data.Valued = calAmount;
    // }
    if (this.params.colDef.from === 'Butchery' || this.params.colDef.from === 'Production' || this.params.colDef.from === 'PR') {
      this.params.colDef.valueParser(this.params, event.target.value);
      return true;
    }
  } else {
    if (this.params.colDef.from === 'salesforecasting' || this.params.colDef.from === 'recipe-additem') {
      this.params.colDef.valueParser(this.params, event.target.value);
      return true;
    }
    let calAmount: any = 0.0;
    let calCost: any = 0.0;
    let quantity: any = 0.0;
    let packSize: any = 0;
    if (this.params.data.hasOwnProperty('Price') && this.params.data.Price !== undefined && this.params.data.Price !== '') {
      calAmount = this.params.data.Price;
    }
    if (this.params.data.hasOwnProperty('Valued') && this.params.data.Valued !== undefined && this.params.data.Valued !== '') {
      calAmount = this.params.data.Valued;
    }
    if (this.params.colDef.from === 'sales') {
      calAmount = this.params.data.Valued !== '' ? this.params.data.Valued : '0.00';
    }
    if (this.params.data.UnitCost !== undefined && this.params.data.UnitCost !== '') {
      calCost = this.params.data.UnitCost;
    }
    if (this.params.data.Qty !== '') {
      quantity = this.params.data.Qty;
    }
    if (this.params.data.Conversion !== '') {
      packSize = this.params.data.Conversion;
    }
    calAmount = calAmount.toString().split(',').join('');
    calCost = calCost.toString().split(',').join('');
    quantity = quantity ? quantity.toString().split(',').join('') : 0;
    if (isNaN(calAmount)) {
      calAmount = 0;
    }
    if (isNaN(calCost)) {
      calCost = 0;
    }
    if (isNaN(quantity)) {
      calAmount = 0;
    }
    if (isNaN(packSize)) {
      packSize = 0;
    }
    if (this.params.colDef.from === 'sales') {
      let TaxAmountValue1: any;
      let TaxAmountValue2: any;
      if (this.params.colDef.field === 'Valued') {
        calCost = event.target.value;
        calCost = calCost.toString().split(',').join('');
        calAmount = +calCost / +quantity;
        quantity = quantity !== '' ? parseFloat(quantity) : '';
        const ValuedColumn = this.params.columnApi.getColumn('UnitCost');
        const DecimalValue1 = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
        calAmount = parseFloat(calAmount).toFixed(DecimalValue1);
        const salesAmount: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['UnitCost'],
        };
        calAmount = isFinite(calAmount) ? calAmount : 0;
        const salesAmountinstances = this.params.api.getCellEditorInstances(salesAmount);
        if (salesAmountinstances.length > 0) {
          salesAmountinstances[0].params.data.Price = calAmount;
          salesAmountinstances[0].value = calAmount;
          salesAmountinstances[0].params.value = calAmount;
          salesAmountinstances[0].input.value = calAmount;
        }
        const TaxAmount1: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['TaxAmount1'],
        };
        let TaxAmount = 0;
        const TaxAmount1Column = this.params.columnApi.getColumn('TaxAmount1');
        const TaxAmountDecimal = TaxAmount1Column ?  TaxAmount1Column.colDef.focusDigits :  6;
        const TaxRate1 = this.params.data.TaxRate1;
        if (TaxRate1) {
          TaxAmount = (TaxRate1 / 100) * parseFloat(calCost);
          TaxAmountValue1 = parseFloat(TaxAmount.toString()).toFixed(TaxAmountDecimal);
        }
        const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
        if (TaxAmount1instances.length > 0) {
          TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmountValue1;
          TaxAmount1instances[0].value = TaxAmountValue1;
          TaxAmount1instances[0].params.value = TaxAmountValue1;
          TaxAmount1instances[0].input.value = TaxAmountValue1;
        }
        const TaxAmount2: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['TaxAmount2'],
        };
        const TaxAmount2Column = this.params.columnApi.getColumn('TaxAmount2');
        const TaxAmount2Decimal = TaxAmount2Column ?  TaxAmount2Column.colDef.focusDigits :  6;
        const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
        let TaxAmountVal2 = 0;
        const TaxRate2 = this.params.data.TaxRate2;
        if (TaxRate2) {
          TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calCost);
          TaxAmountValue2 = parseFloat(TaxAmountVal2.toString()).toFixed(TaxAmount2Decimal);
        }
        if (TaxAmount2instances.length > 0) {
          TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountValue2;
          TaxAmount2instances[0].value = TaxAmountValue2;
          TaxAmount2instances[0].params.value = TaxAmountValue2;
          TaxAmount2instances[0].input.value = TaxAmountValue2;
        }
        rowNode.setDataValue('TaxAmount1', TaxAmountValue1);
        rowNode.setDataValue('TaxAmount2', TaxAmountValue2);
        rowNode.setDataValue('Price', calAmount);
        rowNode.setDataValue('Valued', calCost);
        rowNode.setDataValue('UnitCost', calAmount);
        rowNode.setDataValue('Qty', quantity);
      } else if (this.params.colDef.field === 'Qty') {
        quantity = from === 'fromGui' ? event : event.target.value;
        quantity = quantity.toString().split(',').join('');
        calAmount = (event.target && event.target.value === '-') ? 0.0 : +calCost * +quantity;
        const ValuedColumn = this.params.columnApi.getColumn('Valued');
        const ValuedDecimal = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
        calAmount = parseFloat(calAmount).toFixed(ValuedDecimal);
        const salescalAmount = calAmount * this.params.data.Valued;
        const salesAmount: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['Valued'],
        };
        const salesAmountinstances = this.params.api.getCellEditorInstances(salesAmount);
        if (salesAmountinstances.length > 0) {
          salesAmountinstances[0].params.data.Valued = calAmount;
          salesAmountinstances[0].value = calAmount;
          salesAmountinstances[0].params.value = calAmount;
          salesAmountinstances[0].input.value = calAmount;
        }
        const TaxAmount1: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['TaxAmount1'],
        };
        let TaxAmount = 0;
        const TaxAmount1Column = this.params.columnApi.getColumn('TaxAmount1');
        const TaxAmount1Decimal = TaxAmount1Column ?  TaxAmount1Column.colDef.focusDigits :  6;
        const TaxRate1 = this.params.data.TaxRate1;
        if (TaxRate1) {
          TaxAmount = (TaxRate1 / 100) * parseFloat(calAmount);
          TaxAmountValue1 = parseFloat(TaxAmount.toString()).toFixed(TaxAmount1Decimal);
        }
        const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
        if (TaxAmount1instances.length > 0) {
          TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmountValue1;
          TaxAmount1instances[0].value = TaxAmountValue1;
          TaxAmount1instances[0].params.value = TaxAmountValue1;
          TaxAmount1instances[0].input.value = TaxAmountValue1;
        }
        const TaxAmount2: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['TaxAmount2'],
        };
        const TaxAmount2Column = this.params.columnApi.getColumn('TaxAmount2');
        const TaxAmount2Decimal = TaxAmount2Column ?  TaxAmount2Column.colDef.focusDigits :  6;
        const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
        let TaxAmountVal2 = 0;
        const TaxRate2 = this.params.data.TaxRate2;
        if (TaxRate2) {
          TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calAmount);
          TaxAmountValue2 = parseFloat(TaxAmountVal2.toString()).toFixed(TaxAmount2Decimal);
        }
        if (TaxAmount2instances.length > 0) {
          TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountValue2;
          TaxAmount2instances[0].value = TaxAmountValue2;
          TaxAmount2instances[0].params.value = TaxAmountValue2;
          TaxAmount2instances[0].input.value = TaxAmountValue2;
        }
        rowNode.setDataValue('TaxAmount1', TaxAmountValue1);
        rowNode.setDataValue('TaxAmount2', TaxAmountValue2);
        rowNode.setDataValue('Price', calCost);
        rowNode.setDataValue('Valued', calAmount);
        rowNode.setDataValue('UnitCost', calCost);
        rowNode.setDataValue('Qty', quantity);
      } else if (this.params.colDef.field === 'UnitCost') {
        calCost = event.target.value;
        calCost = calCost.toString().split(',').join('');
        calAmount = +calCost * +quantity;
        quantity = quantity !== '' ? parseFloat(quantity) : '';
        const salesAmount: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['Valued'],
        };
        const ValuedColumn = this.params.columnApi.getColumn('Valued');
        const ValuedDecimal = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
        calAmount = parseFloat(calAmount).toFixed(ValuedDecimal);
        const salesAmountinstances = this.params.api.getCellEditorInstances(salesAmount);
        if (salesAmountinstances.length > 0) {
          salesAmountinstances[0].params.data.Valued = calAmount;
          salesAmountinstances[0].value = calAmount;
          salesAmountinstances[0].params.value = calAmount;
          salesAmountinstances[0].input.value = calAmount;
        }
        const TaxAmount1: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['TaxAmount1'],
        };
        let TaxAmount = 0;
        const TaxAmount1Column = this.params.columnApi.getColumn('TaxAmount1');
        const TaxAmount1Decimal = TaxAmount1Column ?  TaxAmount1Column.colDef.focusDigits :  6;
        const TaxRate1 = this.params.data.TaxRate1;
        if (TaxRate1) {
          TaxAmount = (TaxRate1 / 100) * parseFloat(calAmount);
          TaxAmountValue1 = parseFloat(TaxAmount.toString()).toFixed(TaxAmount1Decimal);
        }
        const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
        if (TaxAmount1instances.length > 0) {
          TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmountValue1;
          TaxAmount1instances[0].value = TaxAmountValue1;
          TaxAmount1instances[0].params.value = TaxAmountValue1;
          TaxAmount1instances[0].input.value = TaxAmountValue1;
        }
        const TaxAmount2: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['TaxAmount2'],
        };
        const TaxAmount2Column = this.params.columnApi.getColumn('TaxAmount2');
        const TaxAmount2Decimal = TaxAmount2Column ?  TaxAmount2Column.colDef.focusDigits :  6;
        const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
        let TaxAmountVal2 = 0;
        const TaxRate2 = this.params.data.TaxRate2;
        if (TaxRate2) {
          TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calAmount);
          TaxAmountValue2 = parseFloat(TaxAmountVal2.toString()).toFixed(TaxAmount2Decimal);
        }
        if (TaxAmount2instances.length > 0) {
          TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountValue2;
          TaxAmount2instances[0].value = TaxAmountValue2;
          TaxAmount2instances[0].params.value = TaxAmountValue2;
          TaxAmount2instances[0].input.value = TaxAmountValue2;
        }
        rowNode.setDataValue('TaxAmount1', TaxAmountValue1);
        rowNode.setDataValue('TaxAmount2', TaxAmountValue2);
        rowNode.setDataValue('Price', calCost);
        rowNode.setDataValue('Valued', calAmount);
        rowNode.setDataValue('UnitCost', calCost);
        rowNode.setDataValue('Qty', quantity);
      }
    } else {
    if (this.params.colDef.field === 'Conversion') {
      if (packSize === '0.00') {
        packSize = 0;
      }
      if (packSize.toString() === '0') {
        packSize = 1;
      }
      const data = event.target.value ? parseFloat(event.target.value.toString()) : 0;
      const ValuedColumn = this.params.columnApi.getColumn('Valued');
      const ValuedDecimal = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
      if (data !== 0) {
        const prevVal = calCost / packSize;
        calCost = prevVal * data;
        calCost = calCost.toString().split(',').join('');
        calAmount = +calCost * +quantity;
        calAmount = parseFloat(calAmount).toFixed(ValuedDecimal);
      }
    } else if (this.params.colDef.field === 'Qty') {
      const ValuedColumn = this.params.columnApi.getColumn('Valued');
      const ValuedDecimal = ValuedColumn ?  ValuedColumn.colDef.focusDigits :  6;
      quantity = from === 'fromGui' ? event : event.target.value;
      quantity = quantity.toString().split(',').join('');
      calAmount = (event.target && event.target.value === '-') ? 0.0 : +calCost * +quantity;
      calAmount = parseFloat(calAmount).toFixed(ValuedDecimal);
    } else if (this.params.colDef.field === 'Cost') {
      const cost = event.target.value;
      const Qtys = this.params.data.Qtys ? this.params.data.Qtys : 1;
      const TotalCost = Qtys * (cost ?? 0);
      const TotalSales = (Qtys * (this.params.data.Valued ?? 0));
      const Costpercent = (TotalCost / (TotalSales === 0 ? 1 : TotalSales) * 100);
      const Margin = TotalSales - TotalCost;
      const Profitpercent = (Margin / (TotalSales === 0 ? 1 : TotalSales) * 100);
      rowNode.setDataValue('TotalSales', TotalSales);
      rowNode.setDataValue('TotalCost', TotalCost);
      rowNode.setDataValue('Costpercent', Costpercent);
      rowNode.setDataValue('Margin', Margin);
      rowNode.setDataValue('Profitpercent', Profitpercent);
      const Profitpercent1: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['Profitpercent'],
      };
      const ProfitpercentIns = this.params.api.getCellEditorInstances(Profitpercent1);
      if (ProfitpercentIns.length > 0) {
        ProfitpercentIns[0].params.data.Profitpercent = Profitpercent;
        ProfitpercentIns[0].value = Profitpercent;
        ProfitpercentIns[0].params.value = Profitpercent;
        ProfitpercentIns[0].input.value = Profitpercent;
      }
      const TotalSales1: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['TotalSales'],
      };
      const TotalSalesIns = this.params.api.getCellEditorInstances(TotalSales1);
      if (TotalSalesIns.length > 0) {
        TotalSalesIns[0].params.data.TotalSales = TotalSales;
        TotalSalesIns[0].value = TotalSales;
        TotalSalesIns[0].params.value = TotalSales;
        TotalSalesIns[0].input.value = TotalSales;
      }
      const TotalCost1: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['TotalCost'],
      };
      const TotalCostinstances = this.params.api.getCellEditorInstances(TotalCost1);
      if (TotalCostinstances.length > 0) {
        TotalCostinstances[0].params.data.TotalCost = TotalCost;
        TotalCostinstances[0].value = TotalCost;
        TotalCostinstances[0].params.value = TotalCost;
        TotalCostinstances[0].input.value = TotalCost;
      }
      const Margin1: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['Margin'],
      };
      const Margininstances = this.params.api.getCellEditorInstances(Margin1);
      if (Margininstances.length > 0) {
        Margininstances[0].params.data.Margin = Margin;
        Margininstances[0].value = Margin;
        Margininstances[0].params.value = Margin;
        Margininstances[0].input.value = Margin;
      }
      const Costpercent1: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['Costpercent'],
      };
      const Costpercentinstances = this.params.api.getCellEditorInstances(Costpercent1);
      if (Costpercentinstances.length > 0) {
        Costpercentinstances[0].params.data.Costpercent = Costpercent;
        Costpercentinstances[0].value = Costpercent;
        Costpercentinstances[0].params.value = Costpercent;
        Costpercentinstances[0].input.value = Costpercent;
      }
    }
    rowNode.setDataValue('Price', calAmount);
    if (this.params.data.hasOwnProperty('Valued')) {
      rowNode.setDataValue('Valued', calAmount);
    }
    rowNode.setDataValue('UnitCost', calCost);
    rowNode.setDataValue('Qty', quantity);
    }
  }
  }
  getValue() {
    if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
    }
    if (this.params.colDef.field === 'Qty') {
      this.input.value = this.input.value ? parseFloat(this.input.value) : this.input.value;
      this.input.value = parseFloat(this.input.value) === -0 ? parseFloat('0') : this.input.value;
      this.params.data.Qty = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.field === 'Valued') {
      this.input.value = this.input.value ? parseFloat(this.input.value) : this.input.value;
      this.params.data.Valued = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.field === 'UnitCost' || this.params.colDef.field === 'CurrentCost') {
      this.input.value = this.input.value ? parseFloat(this.input.value) : this.input.value;
      this.params.data.UnitCost = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.field === 'Conversion' || this.params.colDef.field === 'PurchaseConversion' ||
    this.params.colDef.field === 'TransactionConversion' || this.params.colDef.field === 'RecipeConversion') {
      if (this.params.data.Units && this.params.data.Units.length > 0 && (this.input.value === 0 || this.input.value === ''
      || this.input.value === '0')) {
        const unitData =  this.params.data.Units.filter(x => x.UnitTypeId === this.params.data.UnitTypeId)[0];
        if (unitData && this.params.colDef.from !== 'recipe-additem') {
          const calCost = this.params.data.UnitTypeId > 4 ? unitData.CostPerUnit : unitData.UnitCost;
          const calAmount = +calCost * +this.params.data.Qty;
          const packSize = this.params.data.UnitTypeId === 4 || this.params.data.UnitTypeId === 6
             ? 1 / unitData.Conversion : unitData.Conversion;
          this.input.value = packSize;
          this.params.data.UnitCost = calCost;
          this.params.data.Price = calAmount;
          this.params.data.Values = calAmount;
        }
      }
      this.input.value = this.input.value ? parseFloat(this.input.value) : parseFloat('0');
      this.params.data.Conversion = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.filter === 'Charges') {
      this.input.value = this.input.value ? parseFloat(this.input.value) : this.input.value;
      this.params.data.Charges = this.input.value;
      return this.input.value;
    } else {
      const data = this.input.value;
      return data;
    }
  }

  getGui() {
    return this.input;
  }

  focusIn() {
    this.input.focus();
    this.input.select();
  }

  afterGuiAttached() {
    try {
      if (this.input) {
          if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field
               && this.params.colDef.from !== 'supplierbids') {
            // this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
            this.input.focus();
            this.input.select();
          }
      }
    } catch (error) {
      console.error('Error occurred in number cell editor component of afterGuiAttached function', error);
    }
  }
}
const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';
const KEY_ENTER = 'Enter';
const KEY_ESCAPE = 'Escape';
