import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { CacheType } from 'src/app/eatecui/source/shared/enum/global.enum';
import { EnvService } from '../../../config.service';

const maxAge = 30000;
@Injectable({
    providedIn: 'root'
  })
export class RequestCache  {
constructor(private envService: EnvService) {}
  cache = new Map();
  jsonNames = ['custom-form-action', 'report-icon', 'report-print', 'preferenceaction', 'pre-order-gridcolumn', 'pre-custom-action',
  'formater', 'loader-config'];

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);
    if (req.method === 'POST' || req.method === 'PUT' || req.method === 'PATCH') {
       this.cache.clear();
    }
    if (!cached || req.method === 'POST' || this.checkJsonValues(cached) || req.method === 'PUT' || req.method === 'PATCH') {
      return undefined;
    }
    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.url;
    const entry = { url, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    const expired = Date.now() - maxAge;
    this.cache.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired && this.jsonNames.findIndex(x => expiredEntry.url.includes(x)) === -1) {
        this.cache.delete(expiredEntry.url);
      }
    });
  }
  /**
   * @method manageCacheEngine(CacheKey: string, CacheUrl ?: any)
   * @params CacheKey 
   * @params CacheUrl 
   */

  manageCacheEngine(CacheKey: string, CacheUrl ?: any) {
    try {
       if (CacheKey) {
        switch (CacheKey) {
            case CacheType.CLEARALL:
             this.cache.clear();
              break;
              case CacheType.CLEARINDIVUAL:
              const envService = this.envService.apiUrl;
              const endPointUrl = `${envService}` + `${CacheUrl}`;
              this.cache.delete(endPointUrl);
              break;
            default:
             this.cache.clear();
          }
       }
    } catch (error) {
        console.error(error);
    }
  }

  checkJsonValues (cached) {
    let checkJson = true;
    if (cached.url.endsWith('.json') && this.jsonNames.findIndex(x => cached.url.includes(x)) > -1) {
      checkJson = false;
    }
    return checkJson;    
  }
}
