export interface RadioList {
    id: number;
    viewValue: string;
    value: number | string;
    otherData?: any;
}

export interface Checkbox {
    id?: number;
    checked: boolean;
    disabled: boolean;
    viewValue: string;
    value?: number | string;
    otherData?: any;
}

export interface CompLinkform {
    type: number;
    multiSelect?: boolean;
}

export interface CategoryArray {
    id: number;
    name: string;
    checked: boolean;
}

export interface EmitCompLinkData {
    formData: any;
    form: any;
    singleCategoryData: CategoryArray[][];
    multipleCategoryData: CategoryArray[][];
    selectionType: string;
    discardChanges: boolean;
}

export enum TransactionType{
    Sale = 1,
    Refund =2
}

export enum PlayerCategory{
    Player=1,
    HotelReservation=2,
    Member=3,
    ExistingPlayer=4
}
export enum SelectionType{
    Single = "SingleSelection",
    Multiple = "MultipleSelection",
    Dropdown = "DropdownChange",
    RemoveItem = "RemoveItem",
    SelectAll = "SelectAll",
    DeSelectAll = "DeSelectAll",
    BindDataPatchValue = "BindDataPatchValue"


}
export interface ComponentAccessTypeLink {
    id: number;
    parentId: number;
    childId: number;
    masterParentTypeId: number;
    masterChildTypeId: number;
    recursiveLinkId: number;
    isDefault: boolean ;
}

export enum dropDownOptions {
    TRANSACTION_TYPE = "Transaction Type",
    PLAYER_CATEGORY = "Player Category",
    PAYMENT_METHODS = "Payment Methods"
}
export enum dropDownOptionsId {
    TRANSACTION_TYPE = 1,
    PLAYER_CATEGORY = 2,
    PAYMENT_METHODS = 3
}