import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'getDayName'})
export class GetDayName implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(datCode: number): any {
      if (datCode) {
          let returnValue = '';
          switch (datCode) {
              case 1:
                  const resDay = this.translateService.instant('DeliveryRules.Sunday');
                  returnValue = resDay.substring(0, 3);
                  break;
              case 2:
                  const res2Day = this.translateService.instant('DeliveryRules.Monday');
                  returnValue = res2Day.substring(0, 3);
                  break;
              case 3:
                  const res3Day = this.translateService.instant('DeliveryRules.Tuesday');
                  returnValue = res3Day.substring(0, 3);
                  break;
              case 4:
                  const res4Day = this.translateService.instant('DeliveryRules.Wednesday');
                  returnValue = res4Day.substring(0, 3);
                  break;
              case 5:
                  const res5Day = this.translateService.instant('DeliveryRules.Thursday');
                  returnValue = res5Day.substring(0, 3);
                  break;
              case 6:
                  const res6Day = this.translateService.instant('DeliveryRules.Friday');
                  returnValue = res6Day.substring(0, 3);
                  break;
              case 7:
                  const res7Day = this.translateService.instant('DeliveryRules.Saturday');
                  returnValue = res7Day.substring(0, 3);
                  break;
          }
          return returnValue;
      }
  }
}
