import { UiControlService } from 'src/app/eatecui/source/shared/services/ui-controls.service';
import { RouterConfig, UiConfiguration } from './ui-control-config.interface';
import { UiControlConfiguration, UiControls } from './ui-controls.interface';

export const CheckRouterParams: RouterConfig = (
    RouterUrl: string,
): string => {
    try {
        if (RouterUrl) {
            let selectedTransaction = '';
            let rguestTenantId = false;
            const checkTenant =  sessionStorage.getItem('RGuestTenantId');
            if ( checkTenant && JSON.parse(checkTenant)) {
                rguestTenantId = true;
            }
            switch (RouterUrl) {
                case 'view-spoilage':
                    selectedTransaction = 'spoilage';
                    break;
                case 'view-requisition':
                    selectedTransaction = 'requisition';
                    break;
                case 'view-opentransaction':
                    selectedTransaction = ( rguestTenantId ) ? 'transfer' : 'opentransaction';
                    break;
                case 'view-physicalinventory':
                    selectedTransaction = 'physicalinventory';
                    break;
                case 'view-openpurchaserequest':
                    selectedTransaction = 'openpurchaserequest';
                    break;
                case 'view-purchaseorder':
                    selectedTransaction = 'purchaseorder';
                    break;
                case 'view-Ingredients':
                    selectedTransaction = 'Ingredients';
                    break;
                case 'inventorylist':
                    selectedTransaction = 'Ingredients';
                    break;
                case 'view-Customer':
                    selectedTransaction = 'Customer';
                    break;
                case 'view-Suppliers':
                    selectedTransaction = 'Suppliers';
                    break;
                case 'view-Recipes':
                    selectedTransaction = 'Recipes';
                    break;
                case 'view-receiving':
                    selectedTransaction = 'receiving';
                    break;
                case 'view-Products':
                    selectedTransaction = 'Products';
                case 'view-production-plan':
                    selectedTransaction = 'productionplan';
                    break;
                case 'create-mydata':
                    selectedTransaction = 'mydata';
                    break;
                case 'create-report':
                    selectedTransaction = 'reports';
                    break;
                case 'sales-list':
                    selectedTransaction = 'sales';
                    break;
                case 'sales-forecasting-list':
                    selectedTransaction = 'salesforecasting';
                    break;
                case 'quick-order-list':
                    selectedTransaction = 'quickorders';
                    break;
                case 'cycle-planning-list':
                    selectedTransaction = 'cycleplanning';
                    break;
                case 'view-mrp':
                    selectedTransaction = 'mrp';
                    break;
                default:
                // code block
            }
            return selectedTransaction;
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};