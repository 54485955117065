import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailRoutes } from '../../retail-route';
import { RetailService } from '../../retail.service';
import { RetailCommunication } from '../../retailcommunication.service';
import { ShopBussinessService } from '../../shop/shop-business.service';
import { PropertyFeaturesConfigurationService } from '../../sytem-config/payment-features-config/property-feature-config.service';
import { FeaturesConfigurations, PropertyFeature } from '../business/property-features.model';
import { HandleRequest, HandleResponse, PayeeInfo, PaymentMethods, PropertyInfo } from '../business/shared.modals';
import { Host, MiscellaneousSwitch, ButtonType, FeatureValue, VERSA, V1 } from '../globalsContant';
import { CommonVariablesService } from '../service/common-variables.service';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';
import { PayAgentService } from '../service/payagent.service';
import { GuestRoomSearchResult, GuestRoomSearchType, HttpResponseStatus, PMSIntegrationHostId, PaymentMethod } from '../service/payment/payment-business.model';
import { FeatureName, MultiPropertyDefaultToggle, PMS_SYSTEM, RetailFeatureFlagInformationService, SearchTypes } from '../service/retail.feature.flag.information.service';
import { AlertType } from '../shared.modal';
import { RetailUtilities } from '../utilities/retail-utilities';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { PMSCommunicationReceiverGateway, PMSIntegrationHostConfiguration } from 'src/app/common/components/pms-integration-setup/pms-integration-setup.model';

@Component({
  selector: 'app-guest-room-lookup',
  templateUrl: './guest-room-lookup.component.html',
  styleUrls: ['./guest-room-lookup.component.scss']
})
export class GuestRoomLookupComponent implements OnInit, AfterViewInit {
  searchBoxPlaceHolder: string = "";
  captions: any;
  GuestRoomSearchForm: UntypedFormGroup;
  placeHolderTxt: { [x: number]: any; };
  searchTypeList: any[] = [];
  GuestRoomSearchResult: GuestRoomSearchResult[] = [];
  SelectedGuestRoom: GuestRoomSearchResult;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  subscription: Subscription;
  searchTextChanged = new Subject<string>();
  isDefaultLookUpByName: boolean = false;
  multiPropertyFeatureConfiguration: FeaturesConfigurations[];  
  pmsIntegrationHostConfiguration: PMSIntegrationHostConfiguration[] = [];
  propConfig: any = [];
  SearchValue?: string = null;
  groupChargeSearchType: SearchTypes;

  @Input() GuestRoomNo;  
  @Input('isDefaultLookUpByName')
	public set setDefaultLookUpByName(value) {
    if(value){
        this.isDefaultLookUpByName = true;
        if(this.IsGroupCharge){
          this.GuestRoomSearchForm.get('searchType').setValue(GuestRoomSearchType.SearchByGroupName);
		    }
        else{
          this.GuestRoomSearchForm.get('searchType').setValue(GuestRoomSearchType.SearchByGuestName);
        }
    }
	}
  @Input() payeeInfo: PayeeInfo;
  @Input() isReturnScreen: boolean = false;
  @Input() profitCenter = "";
  @Input() paymentMethodId = 0;
  @Input() OriginalTenderId = 0;
  @Input() SelectedPaymentMethod : PaymentMethod;
  @Input() isResortFinanceMakePaymentFlow : boolean = false;

  selectedPaymentTypeId = 0;
  @Input('selectedPaymentTypeId') 
  public set setSelectedPaymentTypeId(value){
      this.selectedPaymentTypeId = value;      
      if (this.IsMulitPropertyFeatureEnabled) {
        this.GetMultiPropertyConfigs();
        this.SelectDefaultProperty();
      }
      this.FocusSearch();      
  }
  @Input() selectRoomFromResult: EventEmitter<boolean>;
  @Input() AuthAmount = 0;
  @Output() GuestRoomSelected = new EventEmitter();
  @Output() IsCheckZoomEnabled = new EventEmitter<boolean>();
  
  @ViewChild('roomNumber', { static: false }) roomNumber: ElementRef;
  MiscConfig;
  searchFieldType: string = "text";
  isShowGroupBookingId: boolean = false;
  floatLabel: string;
  floatLabelNever: string;
  propertyList = [];
  selectedProperty: PropertyInfo;

  get MultiPropertyFeatureConfig() {
    let featureConfig: PropertyFeature = null;
    try {
      let propertyFeatureStr: any = sessionStorage.getItem("PropertyFeatureConfigurations");
      if (propertyFeatureStr) {
        let propertyFeatures: PropertyFeature[] = JSON.parse(propertyFeatureStr);
        featureConfig = propertyFeatures.find(p => p.featureName == FeatureName.MultiPMSRoomGroupCharge);
      }
    }
    catch (err) {
      console.log("Error Occurred while getting Multi Properties Feature value")
    }
    return featureConfig;
  }

  get IsRoomCharge(){
    return this.OriginalTenderId == PaymentMethods.RoomCharge;
 }

  get IsGroupCharge(){
     return this.OriginalTenderId == PaymentMethods.GroupCharge;
  }

  get IsHotelCompPayment(){
    return this.OriginalTenderId == PaymentMethods.HotelComp;
  }


  get IsMulitPropertyFeatureEnabled() {
    return this.MultiPropertyFeatureConfig?.isActive || (this.propConfig?.EnableMultiplePMSHost && (this.propConfig?.EnableMultiplePMSHost?.toLowerCase() == 'true'))
  }

  get HideSearchByGuestName() {
    const HideSearchByGuestNameKey = "HideSearchByGuestName";
    const payMethodAdditionalConfig = this.SelectedPaymentMethod?.additionalConfigurations ? JSON.parse(this.SelectedPaymentMethod.additionalConfigurations) : null;
    const HideSearchByGuestNameValue = payMethodAdditionalConfig?.find(x => x.Key.trim().toLowerCase() === HideSearchByGuestNameKey.toLowerCase());
    return HideSearchByGuestNameValue?.Value?.trim()?.toLowerCase() == "true"
  }

  get ShowCreditLimit() {
    const ShowCreditLimitKey = "ShowCreditLimit";
    const payMethodAdditionalConfig = this.SelectedPaymentMethod?.additionalConfigurations ? JSON.parse(this.SelectedPaymentMethod.additionalConfigurations) : null;
    const ShowCreditLimitValue = payMethodAdditionalConfig?.find(x => x.Key.trim().toLowerCase() === ShowCreditLimitKey.toLowerCase());
    return ShowCreditLimitValue?.Value?.trim()?.toLowerCase() == "true"
  }

  get IsRoomKeySearchEnabled() {
    return this.searchTypeList.some(x => x.id == GuestRoomSearchType.SearchByRoomkey)
  }

  get ShowSearchTypeSelection() {
    return (((!this.IsGroupCharge && (!this.HideSearchByGuestName || this.IsRoomKeySearchEnabled)) //Hide searchType selection for room charge only if there is more than one radio button
      || (this.IsGroupCharge && (this._featureConfig?.GroupPMSSystem === PMS_SYSTEM.V1 || this._featureConfig?.GroupPMSSystem?.toLowerCase() === PMS_SYSTEM.VISUALONE.toLowerCase() || this.propConfig?.PMSSystem?.toLowerCase() === VERSA.toLowerCase() 
      || this.propConfig?.PMSSystem?.toLowerCase() === V1.toLowerCase()) && !this._featureConfig.GroupHideSearchByGroupName))) && !this.IsHotelCompPayment
  }
  
  constructor(
    private _localization: RetailLocalization
    , private fb: UntypedFormBuilder
    , public _ams: RetailService
    , private payAgentService: PayAgentService
    , private _ss: CommonVariablesService
    , public utils: RetailUtilities
    , private _sbs: ShopBussinessService
    , private _http: HttpServiceCall
    , private _commonHttp: HttpServiceCall
    , private _featureConfig: RetailFeatureFlagInformationService
    , private _propInfo: RetailPropertyInformation
    , private _retailCommunication: RetailCommunication
    , private propertyFeatureservice: PropertyFeaturesConfigurationService
  ) {
    this.captions = _localization.captions.shop;
    this.InitDefaultFormValues();
    this.floatLabel = this._localization.setFloatLabel;
    this.floatLabelNever = this._localization.setFloatLabelNever;    
    this.propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    this.GuestRoomSearchResult = [];    
    this.propertyList = [];
    this.SelectedGuestRoom = null;
    this.SearchValue = null;
    this.groupChargeSearchType = SearchTypes.None;
    console.log('GuestRoomLookupComponent destroyed');
  }

  ngOnInit() {
    this.InitDefaultFormValues();
    this.StartCustomLoaderSubscription();
    this.GetRoomKeySwipeConfig();
    this.initializeSearchSubscription();
    this.selectRoomFromResult?.subscribe((res) => {
      if (res && this.GuestRoomSearchResult[0])
        this.SelectGuestRoom(this.GuestRoomSearchResult[0]);
    });
    if (this.IsMulitPropertyFeatureEnabled) {
      this.GetMultiPropertyConfigs();
    }
    this.groupChargeSearchType = SearchTypes.None;
  }

  ngAfterViewInit(): void {
    this.FocusSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    const changedProperties = Object.keys(changes);
    if (changedProperties && changedProperties.length === 1 && changedProperties[0] === 'AuthAmount'){
      return;
    }
    //To Perform Room lookup automatically if room number received from Payment component    
    if (this.GuestRoomSearchForm && this.GuestRoomSearchForm.controls.searchType.value == GuestRoomSearchType.SearchByRoomNumber && this.GuestRoomNo) {
      this.GuestRoomSearchForm.controls.roomNumber.setValue(this.GuestRoomNo);
      this.searchGuestRoom(true);
    }
    else if (this.GuestRoomSearchForm && (this.GuestRoomSearchForm.controls.searchType.value == GuestRoomSearchType.SearchByGuestName || this.GuestRoomSearchForm.controls.searchType.value == GuestRoomSearchType.SearchByGroupName)&& this.GuestRoomNo){
      this.GuestRoomSearchForm.controls.roomNumber.setValue(this.GuestRoomNo);
      this.searchGuestRoom(true);
    }
    this.SetSearchTypeForGroupCharge();
    this.SelectDefaultProperty();
  }

  StartCustomLoaderSubscription() {
    this._ams.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe((loader) => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        } else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
  }

  initializeSearchSubscription() {
    this.subscription = this.GuestRoomSearchForm.controls.roomNumber.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.$destroyed)).subscribe(searchKey => {
        if (searchKey && searchKey.length >= 3 && this.GuestRoomSearchForm.controls.searchType.value == GuestRoomSearchType.SearchByRoomkey)
          this.searchGuestRoom(false, true);
      });
  }

  SetSearchTypeForGroupCharge() {
    if (this.IsGroupCharge) {
      if (this._featureConfig.GroupPMSSystem === PMS_SYSTEM.LMS) {
        this.searchBoxPlaceHolder = this.captions.SearchByGroupCode;
        if(this.isDefaultLookUpByName){
          this.GuestRoomSearchForm.controls.searchType.setValue(GuestRoomSearchType.SearchByGroupName);
        }
        else{
          this.GuestRoomSearchForm.controls.searchType.setValue(GuestRoomSearchType.SearchByRoomNumber);
        }
        this.ResetSearchList();
      } 
      else if((this._featureConfig.GroupPMSSystem === PMS_SYSTEM.V1 || this._featureConfig?.GroupPMSSystem?.toLowerCase() === PMS_SYSTEM.VISUALONE.toLowerCase() 
                || this.propConfig?.PMSSystem?.toLowerCase() === VERSA.toLowerCase() || this.propConfig?.PMSSystem?.toLowerCase() === V1.toLowerCase()) && this._featureConfig.GroupHideSearchByGroupName) {
        this.searchTypeList = [
          {
            id: GuestRoomSearchType.SearchByBookingID,
            name: this.captions.GroupBookingId,
            checked: true
          }
        ];
        this.selectSearchType({ id: GuestRoomSearchType.SearchByBookingID });
        this.GuestRoomSearchForm.controls.searchType.setValue(GuestRoomSearchType.SearchByBookingID);
        this.searchBoxPlaceHolder = this.captions.SearchByBookingId;
      }
      else {
        this.searchTypeList = [
          {
            id: GuestRoomSearchType.SearchByGroupName,
            name: this.captions.GroupName,
            checked: true
          },
          {
            id: GuestRoomSearchType.SearchByBookingID,
            name: this.captions.GroupBookingId,
            checked: false
          }
        ];
        this.selectSearchType({ id: GuestRoomSearchType.SearchByGroupName });
        this.GuestRoomSearchForm.controls.searchType.setValue(GuestRoomSearchType.SearchByGroupName);
        this.searchBoxPlaceHolder = (this._featureConfig?.GroupPMSSystem === PMS_SYSTEM.V1 || this._featureConfig?.GroupPMSSystem?.toLowerCase() === PMS_SYSTEM.VISUALONE.toLowerCase() 
                                      || this.propConfig?.PMSSystem?.toLowerCase() === VERSA.toLowerCase() || this.propConfig?.PMSSystem?.toLowerCase() === V1.toLowerCase()) ? this.captions.SearchByGroupName
          : this._featureConfig.GroupPMSSystem === PMS_SYSTEM.FSPMS ? this.captions.SearchByGroupCode : this.captions.SearchByGroupNameCode;
      }
    } 
    else if(this.IsHotelCompPayment){
      this.searchBoxPlaceHolder = this.captions.HotelCompNumber;
    }
    else  {
      this.searchTypeList = [
        {
          id: GuestRoomSearchType.SearchByRoomNumber,
          name: this.captions.RoomNumber,
          checked: !this.isDefaultLookUpByName
        }
      ];
      if (!this.HideSearchByGuestName) {
        this.searchTypeList.push({
          id: GuestRoomSearchType.SearchByGuestName,
          name: this.captions.GuestName,
          checked: this.isDefaultLookUpByName
        });
      }
      this.selectSearchType({ id: GuestRoomSearchType.SearchByRoomNumber });
      if(this.isDefaultLookUpByName){
        this.GuestRoomSearchForm.controls.searchType.setValue(GuestRoomSearchType.SearchByGuestName);
      }
      else{
        this.GuestRoomSearchForm.controls.searchType.setValue(GuestRoomSearchType.SearchByRoomNumber);
      }
    }
    this.isShowGroupBookingId = (this.IsGroupCharge && (this._featureConfig?.GroupPMSSystem === PMS_SYSTEM.V1 || this._featureConfig?.GroupPMSSystem?.toLowerCase() === PMS_SYSTEM.VISUALONE.toLowerCase() 
                                  || this.propConfig?.PMSSystem?.toLowerCase() === VERSA.toLowerCase() || this.propConfig?.PMSSystem?.toLowerCase() === V1.toLowerCase()));
  }

  InitDefaultFormValues() {
    this.captions = this._localization.captions.shop;
    this.placeHolderTxt = {
      [GuestRoomSearchType.SearchByGuestName]: this.captions.SearchByGuestName,
      [GuestRoomSearchType.SearchByRoomNumber]: this.captions.SearchByRoomNumber,
      [GuestRoomSearchType.SearchByRoomkey]: this.captions.SearchByRoomKey,
      [GuestRoomSearchType.SearchByGroupName]: this.captions.SearchByGroupName,
      [GuestRoomSearchType.SearchByBookingID]: this.captions.SearchByBookingId
    };
    this.searchTypeList = [
      {
        id: GuestRoomSearchType.SearchByRoomNumber,
        name: this.captions.RoomNumber,
        checked: !this.isDefaultLookUpByName
      }
    ];
    if (!this.HideSearchByGuestName) {
      this.searchTypeList.push({
        id: GuestRoomSearchType.SearchByGuestName,
        name: this.captions.GuestName,
        checked: this.isDefaultLookUpByName
      });
    }
    this.GuestRoomSearchForm = this.fb.group({
      searchType: this.isDefaultLookUpByName? GuestRoomSearchType.SearchByGuestName : GuestRoomSearchType.SearchByRoomNumber,
      roomNumber: "",
      guestRoomSelection: "",
      propertySelection: ""
    });
    this.SetSearchTypeForGroupCharge();
  }

  CheckRoomNumber() {
    this.UpdateNullAndResetSelectionAndFlags();
  }

  UpdateNullAndResetSelectionAndFlags() {
    this.GuestRoomSelected.emit(null);
    this.GuestRoomSearchResult = [];
    this.SelectedGuestRoom = null;
    this.GuestRoomSearchForm.controls.guestRoomSelection.patchValue("");
  }

  selectSearchType(type) {
    this.searchBoxPlaceHolder = this.IsGroupCharge && this._featureConfig.GroupPMSSystem === PMS_SYSTEM.LMS ?
      this.captions.SearchByGroupCode : this.placeHolderTxt[type.id];
    this.ResetSearchList();
    if (type.id == GuestRoomSearchType.SearchByGuestName && this.payeeInfo && this.payeeInfo.name) {
      if (this.payeeInfo.lastName) {
        this.GuestRoomSearchForm.controls.roomNumber.setValue(this.payeeInfo.lastName);
      }
      else {
        this.GuestRoomSearchForm.controls.roomNumber.setValue(this.payeeInfo.name);
      }
    }
    if ((type.id == GuestRoomSearchType.SearchByRoomNumber || type.id == GuestRoomSearchType.SearchByGroupName) && this.GuestRoomNo) {
      this.GuestRoomSearchForm.controls.roomNumber.setValue(this.GuestRoomNo);
    }
    this.searchFieldType = type.id == GuestRoomSearchType.SearchByRoomkey ? "password" : "text";    
    this.FocusSearch();
  }

  ResetSearchList() {
    this.UpdateNullAndResetSelectionAndFlags();
    this.GuestRoomSearchForm.controls.roomNumber.patchValue("");
    if (this.roomNumber && this.roomNumber.nativeElement) {
      this.roomNumber.nativeElement.value = "";
    }
  }

  SelectGuestRoom(room) {
    if (room && room.handle) {
      if (room.isChargingAllowed.toLowerCase() == 'true') {
        this.GuestRoomSelected.emit(room);
      }
      else {
        this.GuestRoomSelected.emit(null);
        this.SelectedGuestRoom = null;
        this.GuestRoomSearchForm.controls.guestRoomSelection.patchValue("");
        if(this.IsHotelCompPayment){
          this.utils.showAlert(this.captions.HotelCompPostingNotAllowed, AlertType.Info, ButtonType.Ok);
        }
        else{
          this.utils.showAlert(this.captions.RoomChargePostingNotAllowed, AlertType.Info, ButtonType.Ok);          
        }
      }
    }
  }

  async searchGuestRoom(isOnloadPrefill = false, triggeredFromDebounce = false) {
    const searchType = this.GuestRoomSearchForm.controls.searchType.value;
    const roomNumber = this.GuestRoomSearchForm.controls.roomNumber.value;
    if(this.IsGroupCharge){
      this.SearchValue = roomNumber === "" ? null : roomNumber;
    }
    if (!roomNumber || (searchType == GuestRoomSearchType.SearchByRoomkey && !triggeredFromDebounce)) { return; }
    if (searchType == GuestRoomSearchType.SearchByRoomkey) {
      let data: string = this.GuestRoomSearchForm.controls.roomNumber.value;
      data = data.substring(this.MiscConfig[MiscellaneousSwitch.ROOM_KEY_CARD_DATA_START_POSITION]
        , this.MiscConfig[MiscellaneousSwitch.ROOM_KEY_CARD_DATA_END_POSITION]);
      if (!data) { return; }
      this.GetRoomDetailsFromRoomKeyData(data);
    } else {
      this.PerformRoomSearch(roomNumber, searchType, isOnloadPrefill);
    }
  }

  async PMSLookup(roomNumber, searchType) {
    if(this._featureConfig.IsSkipPMAgent(this.SelectedPaymentMethod) && !this.isResortFinanceMakePaymentFlow){
      
      const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
      const payMethodAdditionalConfig = this.SelectedPaymentMethod?.additionalConfigurations? JSON.parse(this.SelectedPaymentMethod.additionalConfigurations) : null;
      const PayMethodPMSIntegrationHostId = payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId)
      const pmsIntegrationHostId = this.selectedProperty?.pMSIntegrationHostId? this.selectedProperty?.pMSIntegrationHostId : PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId?.Value : propConfig?.PMSIntegrationHostId??'1'
  
      const lookUpRoute = this.selectedPaymentTypeId == PaymentMethods.RoomCharge ? RetailRoutes.PMSCommunicationSenderRoomLookUp : RetailRoutes.PMSCommunicationSenderGroupLookUp;
      return this._retailCommunication.postPromiseDirect<HandleResponse>({
        route: lookUpRoute,
        body: {
          byNameFlag: (searchType == GuestRoomSearchType.SearchByGuestName || searchType == GuestRoomSearchType.SearchByGroupName),
          inputData: roomNumber,
          tenderId: this.selectedPaymentTypeId,
          multiPMSPropertyIndex: this.IsMulitPropertyFeatureEnabled ? this.selectedProperty.PropCode : "",
          multiPropertyPropCode: this.IsMulitPropertyFeatureEnabled ? this.selectedProperty.multiPMSPropertyIndex : "",
          pMSIntegrationHostId: pmsIntegrationHostId,
          payMethodAdditionalConfig: payMethodAdditionalConfig
        }
      })
    }
    else{
      return this._retailCommunication.postPromiseDirect<HandleResponse>({
        route: RetailRoutes.PMSLookup,
        body: {
          byNameFlag: (searchType == GuestRoomSearchType.SearchByGuestName || searchType == GuestRoomSearchType.SearchByGroupName),
          inputData: roomNumber,
          tenderId: this.selectedPaymentTypeId,
          multiPMSPropertyIndex: this.IsMulitPropertyFeatureEnabled ? this.selectedProperty.multiPMSPropertyIndex : ""
        }
      })
    }
  }

  async GetHandlesViaPMSCommunicationSender(handleRequestBody: HandleRequest, outletId = 0, ceds = "") {
    
    const originalTenderId = this.OriginalTenderId;
		const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    const payMethodAdditionalConfig = this.SelectedPaymentMethod?.additionalConfigurations? JSON.parse(this.SelectedPaymentMethod.additionalConfigurations) : null;
		const PayMethodPMSIntegrationHostId = payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId)
		const pmsIntegrationHostId = this.selectedProperty?.pMSIntegrationHostId ? this.selectedProperty?.pMSIntegrationHostId : PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId?.Value : propConfig?.PMSIntegrationHostId??'1'
		
    //PMS Communication Receiver Lookup Request.
    handleRequestBody.tenderId = await this.payAgentService.FormTenderId(String(this.SelectedPaymentMethod?.paymentTypeId??'0'), outletId, ceds);
    handleRequestBody.tenderName = this.SelectedPaymentMethod?.paymentMethod??'';
    handleRequestBody.originalTenderId = originalTenderId;
    handleRequestBody.multiPMSPropertyIndex = '';
    handleRequestBody.multiPropertyPropCode = this.selectedProperty?.PropCode??'';
    handleRequestBody.pMSIntegrationHostId = pmsIntegrationHostId;
    handleRequestBody.multiPropertyFeatureConfigurations = this.multiPropertyFeatureConfiguration
    handleRequestBody.paymentMethodAdditionalConfigurations = payMethodAdditionalConfig;
    //PMS Communication Receiver Lookup Route.
    const getHandlesRoute = this.GetPMSCommunicationSenderLookUpRoute(this.OriginalTenderId);
    
    //Invoke PMS Communication Sender For Lookup.
    return this._retailCommunication.postPromiseDirect<HandleResponse>({
      route: getHandlesRoute,
      body: handleRequestBody
    })
  }

  GetPMSCommunicationSenderLookUpRoute(originalTenderId: number) : string{
    let pmsCommunicationSenderRoute = '';
    switch(originalTenderId){
      case PaymentMethods.RoomCharge:
        {
          pmsCommunicationSenderRoute = RetailRoutes.PMSCommunicationSenderGetRooms;
          break;
        }
      case PaymentMethods.GroupCharge:
        {
          pmsCommunicationSenderRoute = RetailRoutes.PMSCommunicationSenderGetGroups;   
          break;       
        }
      case PaymentMethods.HotelComp:
        {
          pmsCommunicationSenderRoute = RetailRoutes.PMSCommunicationSenderGetHotelComp;  
          break;         
        }
    }
    return pmsCommunicationSenderRoute; 
  }

  PerformRoomSearch(roomNumber, searchType, isOnloadPrefill = false) {
    if(this.IsGroupCharge){
      this.SetSearchTypesForGroupCharge(searchType);
    }
    let isViaPMSCommunicationReceiver = this._featureConfig.IsSkipPMAgent(this.SelectedPaymentMethod) || this.IsHotelCompPayment;
    if(this.IsMulitPropertyFeatureEnabled && !this.selectedProperty?.PropertyName){
      this.utils.ShowErrorMessage(
        this._localization.captions.common.Error, this.captions.SelectPropertyToLookUp);
    }
    else{
      let loaderCaption = this.IsHotelCompPayment? this.captions.HotelCompAuthorizeLoaderMsg : this.captions.RetrieveRoomDetails;
      this._ams.loaderEnable.next(loaderCaption);
      this.GuestRoomSearchResult = [];
      this.SelectedGuestRoom = null;
      const RequestBody: HandleRequest = {
        tenderId: String(this.selectedPaymentTypeId),
        originalTenderId: this.utils.GetOriginalTenderId(this.SelectedPaymentMethod?.paymentTypeId??0, this.SelectedPaymentMethod.parentTypeId??0),
        inquiryInfo: {
          id: roomNumber,
          type: searchType == GuestRoomSearchType.SearchByGuestName || searchType == GuestRoomSearchType.SearchByGroupName
            ? 'Guest' : 'Room',
          propertyInfo: this.IsMulitPropertyFeatureEnabled ? this.selectedProperty : null
        },
        authAccountNum: roomNumber,
        amount:{
          requestAmount : this.AuthAmount
        },
        profitCenterId: Number(this.profitCenter)
      };
      if (this.isReturnScreen) {
        this._sbs.GetDetailedFinancialBin();
      }
      let handleResult: Promise<HandleResponse>;
      if (((this._featureConfig.SkipPMSXML && this._propInfo.IsVersaIntegration) && this._propInfo.HasRevenuePostingEnabled) || (this.propConfig?.PMSSystem?.toUpperCase()?.trim() == VERSA && isViaPMSCommunicationReceiver)) {
        handleResult = this.PMSLookup(roomNumber, searchType);
      } else {
        if(isViaPMSCommunicationReceiver){
          handleResult = this.GetHandlesViaPMSCommunicationSender(
            RequestBody,
            Number(this._ss.SelectedOutletId)
          );        
        }
        else{          
          handleResult = this.payAgentService.GetHandles(
            RequestBody,
            Number(this._ss.SelectedOutletId)
          );
        }
      }
      handleResult
        .then((response) => {
          setTimeout(() => {
            this._ams.loaderEnable.next('');
          }, 1000);
          if (response.status.toLocaleLowerCase() == HttpResponseStatus.Success && response.paymentHandle?.length > 0) {
            const handleArr = response.paymentHandle;
            
            this.GuestRoomSearchResult = [];
            handleArr.forEach(x => {
              if (x.inquiryInfo && x.inquiryInfo.id && x.name) {
                
                //Added For Hotel Comp
                let authorizedAmount = 0;
                if(this.IsHotelCompPayment){
                  const parsedHandleData = JSON.parse(x.handle);
                  authorizedAmount = parsedHandleData.authorizedAmount;
                }
                const groupChargeWithRoomNumber = [
                  PMS_SYSTEM.V1.toLowerCase(),
                  PMS_SYSTEM.VISUALONE.toLowerCase(),
                  VERSA.toLowerCase(),
                  PMS_SYSTEM.FSPMS.toLowerCase()
                ];

                this.GuestRoomSearchResult.push({
                  RoomNumber: this.IsHotelCompPayment ? x.referenceNumber1 : (!this.IsGroupCharge || (this.IsGroupCharge &&
                    (groupChargeWithRoomNumber.includes(this._featureConfig?.GroupPMSSystem?.toLowerCase()) || groupChargeWithRoomNumber.includes(this.propConfig?.PMSSystem?.toLowerCase()))) ? x.RoomNumber : ""),
                  GuestName: x.name,
                  handle: x.handle ? x.handle : (this._propInfo.IsVersaIntegration && this._propInfo.HasRevenuePostingEnabled) ? "Versa" : "",
                  isChargingAllowed: x.additionalAttributes?.ChargingAllowedFlag,
                  RoomReference: x.referenceNumber1,
                  groupBookingId: x.referenceNumber2,
                  propertyInfo: this.selectedProperty,
                  phoneNumber: x.phoneNumber,
                  authorizedAmount: authorizedAmount,
                  balance: x.balance,
                  creditLimit: this.ShowCreditLimit ? this.RemoveCurrencySymbolsAndConvertToNumber(x.creditLimit, this.utils.localization.currencySymbol) : 0,
                  SearchValue : this.SearchValue,
                  SearchType : this.groupChargeSearchType,
                  HotelId : x.hotelId
                } as GuestRoomSearchResult);
              }
            });
            if (handleArr && handleArr?.length == 1 && this.GuestRoomSearchResult[0]?.isChargingAllowed) {
              this.SelectGuestRoom(this.GuestRoomSearchResult[0]);
              this.GuestRoomSearchForm.controls.guestRoomSelection.setValue(this.GuestRoomSearchResult[0].GuestName);
              if(this.IsHotelCompPayment){
                  this.SelectedGuestRoom = this.GuestRoomSearchResult[0];
              }
            }
          } else {
            this.GuestRoomSelected.emit(null);
            this.utils.ShowErrorMessage(
              this._localization.captions.common.Error,
              response?.errorMessage ?? this.captions.NoRoomsFound
            );
          }
        }).catch((err) => {
          setTimeout(() => {
            this._ams.loaderEnable.next('');
          }, 1000);
          this.ResetSearchList();
        });
    }    
  }

  SetSearchTypesForGroupCharge(searchType){
    switch(this.propConfig?.PMSSystem){      
      case PMS_SYSTEM.LMS:
        {
          this.groupChargeSearchType = SearchTypes.GroupCode;
          break;
        }
      case PMS_SYSTEM.STAY:
        {
          this.groupChargeSearchType = SearchTypes.GroupName;
          break;       
        }
      case VERSA:
      case PMS_SYSTEM.V1:
        {
          this.groupChargeSearchType = SearchTypes.GroupCode
          break;         
        }
        case PMS_SYSTEM.VISUALONE:
        {
          this.groupChargeSearchType = (searchType == GuestRoomSearchType.SearchByGroupName) ? SearchTypes.GroupName : SearchTypes.BookingId;
          break;         
        }
        default:
          this.groupChargeSearchType = SearchTypes.None;
          break;
    }
  }

  RemoveCurrencySymbolsAndConvertToNumber(input: string, currencySymbol: string): number {
    if(!input){
      return 0;
    }
    // Remove currency symbols using replace() method with the regex
    const stringWithoutCurrencySymbols = input.replace(currencySymbol, '');

    const stringWithoutThousandSeperator = this.utils.localization.removeThousandSeparator(stringWithoutCurrencySymbols);
    const finalValue = Number(stringWithoutThousandSeperator);
    return isNaN(finalValue)? 0 : finalValue;
}

GetRoomKeySwipeConfig() {
    let miscRes = this._http.CallApiAsync({
      callDesc: "GetAllSettingByModule",
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { module: 3 }
    });
    miscRes.then(res => {
      console.log(res.result);
      this.MiscConfig = <any[]>res.result && res.result[0];
      if (this.MiscConfig) {
        const roomKeySwipeEnabled = this.MiscConfig[MiscellaneousSwitch.ENABLE_ROOM_KEY_SWIPE_WITH_MSR];
        this.IsCheckZoomEnabled.emit(this.MiscConfig[MiscellaneousSwitch.ENABLE_CHECKZOOM]);
        if (roomKeySwipeEnabled && !this.IsGroupCharge) {
          this.searchTypeList.forEach(element => {
            element.checked = false;
          });
          this.searchTypeList.push(
            {
              id: GuestRoomSearchType.SearchByRoomkey,
              name: this.captions.RoomKey,
              checked: true
            }
          );
          this.GuestRoomSearchForm.controls.searchType.setValue(GuestRoomSearchType.SearchByRoomkey);
          this.selectSearchType({ id: GuestRoomSearchType.SearchByRoomkey });
        }
      }
    }).catch(err => {
      console.log(err);
    });
  }

  async GetRoomDetailsFromRoomKeyData(data) {
    if (data && this.MiscConfig) {
      this._ams.loaderEnable.next(this.captions.RetrieveRoomDetails);
      const clearLoaderAndShowRoomNotFound = () => {
        setTimeout(() => {
          this._ams.loaderEnable.next('');
        }, 1000);
        this.GuestRoomSelected.emit(null);
        this.utils.ShowErrorMessage(
          this._localization.captions.common.Error,
          this.captions.NoRoomsFound
        );
      }
      const RequestBody = {
        Data: data,
        ProxyURL: this.MiscConfig[MiscellaneousSwitch.WEBCOMMUNICATION_PROXY_ENDPOINT],
        StartPosition: this.MiscConfig[MiscellaneousSwitch.ROOM_KEY_CARD_DATA_START_POSITION],
        EndPosition: this.MiscConfig[MiscellaneousSwitch.ROOM_KEY_CARD_DATA_END_POSITION],
        CardSourceType: this.MiscConfig[MiscellaneousSwitch.ROOM_KEY_CARD_DATA_SOURCE_TYPE],
        ProfitCenter: Number(this.profitCenter),
        PaymentMethodId: Number(this.paymentMethodId)
      }
      let lookupRes: any = this._http.CallApiAsync({
        callDesc: "RoomLookupWithRoomKeyData",
        host: Host.payment,
        method: HttpMethod.Put,
        body: RequestBody,
        showError: true
      });
      lookupRes.then(res => {
        if (res && res.result) {
          const roomDetails = JSON.parse(res.result);
          if (roomDetails && roomDetails.RoomNumber) {
            this.PerformRoomSearch(roomDetails.RoomNumber, GuestRoomSearchType.SearchByRoomNumber);
          } else
            clearLoaderAndShowRoomNotFound();
        } else {
          clearLoaderAndShowRoomNotFound();
        }
      }).catch(err => {
        clearLoaderAndShowRoomNotFound();
        console.log(err);
      });
    }
  }

  async GetMultiPropertyConfigs() {
    this.propertyList = [];
    if(this._featureConfig.IsSkipPMAgent(this.SelectedPaymentMethod) || this.IsHotelCompPayment){

      //Rewamped PMS Integration Flow Multi Property 
      this.pmsIntegrationHostConfiguration = await this.InvokeGetAllPMSIntegrationHostConfigurationsAPICall();
      console.log(this.pmsIntegrationHostConfiguration);
      this.propertyList = [];
      if (this.pmsIntegrationHostConfiguration?.length > 0) {
        this.pmsIntegrationHostConfiguration?.sort((a,b) => 0 - (a.configurationKey > b.configurationKey ? -1 : 1))?.map(c => {
          let configKeySplit = c.configurationKey;
          let propertyInd = Number(c?.hostId??0)
          let configKey = c?.configurationKey?.toString();
          if (!this.propertyList[propertyInd]) {
            this.propertyList.push({ [configKey]: c.configurationValue });
          } else {
            this.propertyList[propertyInd][configKey] = c.configurationValue;
          }
          if(this.propertyList && this.propertyList[propertyInd] && !this.propertyList[propertyInd]?.pMSIntegrationHostId){
            this.propertyList[propertyInd].pMSIntegrationHostId = Number(c?.hostId??0);
          }
        });
        this.propertyList = this.propertyList?.filter(f => f.PropertyName);
        this.SelectDefaultProperty();
      }
    }
    else{

      //Old PMS Integration Multi Property Flow Fow Backward Compatibility.
      this.multiPropertyFeatureConfiguration = await this.propertyFeatureservice.getFeatureConfiguration(this.MultiPropertyFeatureConfig.id, this.MultiPropertyFeatureConfig.moduleId);
      console.log(this.multiPropertyFeatureConfiguration);
      this.propertyList = [];
      if (this.multiPropertyFeatureConfiguration?.length > 0) {
        this.multiPropertyFeatureConfiguration.map(c => {
          let configKeySplit = c.configurationKey.split(".");
          let propertyInd = Number(configKeySplit[1]) - 1, configKey = configKeySplit[2];
          if (!this.propertyList[propertyInd]) {
            this.propertyList.push({ [configKey]: c.configurationValue })
          } else {
            this.propertyList[propertyInd][configKey] = c.configurationValue
          }
          if(!this.propertyList[propertyInd]["multiPMSPropertyIndex"]){
            this.propertyList[propertyInd].multiPMSPropertyIndex = `${FeatureValue.Property}.${propertyInd+1}`
          }
        });
        this.SelectDefaultProperty();
    }
    }
    
    console.log(this.propertyList);
  }

  SelectDefaultProperty() {
    let defaultProperty: any;
    if (!this.IsMulitPropertyFeatureEnabled) return
    if (this.IsGroupCharge && this.propertyList.length > 0) {
      defaultProperty = this.propertyList.find(p => p.defaultGroupCharge == "true" && p.PropertyName);      
    } 
    else if (this.IsHotelCompPayment && this.propertyList.length > 0) {
      defaultProperty = this.propertyList.find(p => p.defaultHotelComp == "true" && p.PropertyName);      
    } 
    else if (this.IsRoomCharge && this.propertyList.length > 0) {
      defaultProperty = this.propertyList.find(p => p.defaultRoomCharge == "true" && p.PropertyName);
    }

    if(defaultProperty){          
      this.selectProperty(defaultProperty)
    }
  }

  selectProperty(property) {
    if (property) {
      this.GuestRoomSearchForm.controls.propertySelection.setValue(property.PropertyName);
      this.selectedProperty = property
    }
  }

  async InvokeGetAllPMSIntegrationHostConfigurationsAPICall(): Promise<PMSIntegrationHostConfiguration[]> {
    this.utils.ToggleLoader(true);
    let response: PMSIntegrationHostConfiguration[] = [];
    const pmsCommunicationReceiverURI = this.GetPMSCommunicationReceiverURL(this.propConfig);
    if (pmsCommunicationReceiverURI) {
      this.utils.ToggleLoader(true);
      let URI = pmsCommunicationReceiverURI + CommonApiRoutes.GetAllPMSIntegrationHostConfigurations;
      
      await this._http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(t => {
        response = t.result;
      });
      this.utils.ToggleLoader(false);
    }
    this.utils.ToggleLoader(false);
    return response;
  }

  GetPMSCommunicationReceiverURL(propConfig){
    let pmsCommunicationReceiverURI: String = '';
    var propConfigUpperCase = {}
    Object.entries(propConfig)?.forEach(([k, v]) => {propConfigUpperCase[k?.toUpperCase()] = v})
    propConfig = propConfigUpperCase

    if(propConfig && propConfig.PMSCOMMUNICATIONRECEIVER && propConfig?.PMSCOMMUNICATIONRECEIVERDOMAIN)
    {
        pmsCommunicationReceiverURI = `${propConfig.PMSCOMMUNICATIONRECEIVERDOMAIN}/${PMSCommunicationReceiverGateway}/${propConfig.PMSCOMMUNICATIONRECEIVER}`;
    }
    return pmsCommunicationReceiverURI;
  }
  FocusSearch(){
    if (this.roomNumber && this.roomNumber.nativeElement) {
      setTimeout(() => {
        this.roomNumber.nativeElement.focus();
      }, 100);
    }
  }
}