import { MasterDefineProduct } from './custom-define-products.interface';
import { MasterLinkedItemSearchList } from './custom-linked-item-search.interface';
import { MasterCustomNavigationValue } from './custom-navigation.interface';
import { MasterDetail } from './detail-data.interface';
import { MasterDetailPicture } from './master-detail-picture.interface';
import { MasterNestedDetail } from './master-nested-detail.interface';
import { MasterNestedDetailTabChange } from './master-nested-tab-change.interface';
import { MasterToggle } from './toggle-button-change.interface';
import { MasterZoomDetail } from './zoom-data.interface';

export const DetailInterface = {
    MasterDetails: MasterDetail,
    MasterNestedDetails: MasterNestedDetail,
    MasterNestedDetailTabChange: MasterNestedDetailTabChange,
    MasterCustomNavigation: MasterCustomNavigationValue,
    MasterToggleChange: MasterToggle,
    MasterDetailZoom: MasterZoomDetail,
    MasterLinkedItemSearchList: MasterLinkedItemSearchList,
    MasterDefineProduct: MasterDefineProduct,
    MasterDetailPicture: MasterDetailPicture
};
