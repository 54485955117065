import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SchedulingAssistantView } from '../../business/shared.modals';

@Component({
  selector: 'app-schedule-asst-table',
  templateUrl: './schedule-asst-table.component.html',
  styleUrls: ['./schedule-asst-table.component.scss']
})
export class ScheduleAsstTableComponent implements OnInit, OnChanges {
  color = 'primary';
  mode = 'indeterminate';
  values = 40;
  radius = 100;
  SchedulingAssistantView = SchedulingAssistantView;
  @Input() ScheduleData;
  @Input() selectedView;
  @Output() emitSelectedScheduleData: EventEmitter<any> = new EventEmitter();
  isCollapsed = false;
  public sideContentArray: any = [];
  captions: any;
  constructor(private localization: SpaLocalization) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.ScheduleData) {
      this.sideContentArray = this.ScheduleData[0] && this.ScheduleData[0].slots.map(res => res.localizedTime);
      this.ScheduleData.forEach(s => s.isCollapsed = true);
    }
  }

  collapseChangeEvent(event, index) {
    this.ScheduleData[index].isCollapsed = !this.ScheduleData[index].isCollapsed;
    this.ScheduleData = [...this.ScheduleData];
  }

  trackByFn(idx, el) {
    return idx;
  }

  tableCellClick(event, ScheduleData, groupIndex, rowIndex, colIndex) {
    this.ScheduleData.forEach(s => s.innerData.forEach(t => t.slots.forEach(u => u.highlight = false)));
    this.ScheduleData[groupIndex].innerData[rowIndex].slots[colIndex].highlight = true;
    this.emitSelectedScheduleData.emit([ScheduleData, groupIndex, rowIndex, colIndex]);
  }
}
