import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { ScrollbarModule } from 'ngx-scrollbar';
import { RetailToggleSwitchModule } from '../retail-toggle-switch/retail-toggle-switch.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module'; 
import { RetailSharedModule } from '../shared/retail-shared.module';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollbarModule,
    RetailToggleSwitchModule,
    RetailSharedModule,
    SharedModule
  ],
  exports: []
})
export class SystemConfigModule { }
