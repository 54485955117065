import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, HostListener, Input } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Host, GridType, RetailBreakPoint, ButtonType } from '../../shared/globalsContant';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import * as _ from 'lodash';
import { ScrollbarComponent } from 'ngx-scrollbar';

@Component({
  selector: 'app-retail-sub-categories',
  templateUrl: './retail-sub-categories.component.html',
  styleUrls: ['./retail-sub-categories.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailSubCategoriesComponent implements OnInit {
  captions: any;
  showExpanded: boolean = false;
  tableoptions: any = [];
  searchPlaceholderValue: string = "Search";
  setupFormGroup: UntypedFormGroup;
  searchText: any;
  commonCaptions: any;
  enableEdit: boolean = false;
  transactionData: any;
  isSearchEnabled: boolean = true;
  setText: string;
  isEditModeSet: boolean = false;
  editBool: boolean = false;
  allSubCategories: any;
  availableLevels: any;
  currIndex: any;
  IsViewOnly: any;
  levelAfterOpration: any;
  currentId: number;
  currentLevel: number;
  subCategoryInUseErrorCode: number = 11303;
  enableToggleButton: boolean = false;
  maxInputLength = 100;
  floatLabel: string;
  @ViewChild('expandContainer', { static: true }) expandContainer: ElementRef;
  @ViewChild('selectContainer', { static: true }) selectContainer: ElementRef;
  isIpad: boolean;
  floatLabelNever: any;
  @HostListener('mousedown', ['$event', '$event.target'])
  onMousedown(e: MouseEvent, element: any) {
    const isDraggable = element.classList.contains('draggable');
    if (!isDraggable && element.tagName !== 'INPUT' && element.tagName !== 'SPAN') {
      e.preventDefault();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setHeight();
  }
  levelValues: any = [{ "id": 1, "value": 1 }, { "id": 2, "value": 2 }, { "id": 3, "value": 3 }, { "id": 4, "value": 4 }, { "id": 5, "value": 5 }];
  completeTableOptions: any = [];
  @ViewChild(ScrollbarComponent, { static: false }) scrollRef: ScrollbarComponent;
  @ViewChild('searchInput', { static: false }) private searchInput;
  @Input() throttleTime: number = 3000;

  constructor(public _ls: RetailLocalization, private http: HttpServiceCall, private Form: UntypedFormBuilder, private utils: RetailUtilities, private retailService: RetailSetupService) {
    this.isIpad = this.utils.isIpad();
  }


  ngOnInit() {
    this.captions = this._ls.captions.retailsetup;
    this.commonCaptions = this._ls.captions.common;
    this.floatLabel = this._ls.setFloatLabel;
    this.floatLabelNever = this._ls.setFloatLabelNever;
    this.setText = this.commonCaptions.ADD;
    this.setupFormGroup = this.Form.group({
      subCategory: ['', [Validators.required]], 
      activetoggle: true,
      levelValue: [1, Validators.required]
    });
    this.getAllSubCategories();
    this.populateTableInfo();
    this.setHeight();
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.RetailSubCategories).view;
    if (this.IsViewOnly)
      this.utils.disableControls(this.setupFormGroup);
  }


  setHeight() {
    let height = this.selectContainer.nativeElement.offsetHeight;
    this.expandContainer.nativeElement.style.height = "calc(100% - " + height + ')px';
  }

  getAllSubCategories() {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetAllSubCategories",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      extraParams: []
    });
  }

  dragDrop(e: any) {
    const dragIndex = e[0];
    const dropIndex = e[1];
    const settingBelongsTo = e[2];
    const level = e[4];
    this.levelAfterOpration = level;
    let uriParam: any = dragIndex.toString() + "/" + dropIndex.toString() + "/" + level.toString();
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: "SubCategoryDragDrop",
      uriParams: uriParam,
      method: HttpMethod.Patch,
      showError: true,
      extraParams: settingBelongsTo
    });
    console.log('dropped', e);
  }

  bindData() {
    this.availableLevels = [{
      "id": 1,
      "name": this.captions.level1,
      "showExpanded": false,
      "levels": this.allSubCategories.filter(o => o.level == 1)
    },
    {
      "id": 2,
      "name": this.captions.level2,
      "showExpanded": false,
      "levels": this.allSubCategories.filter(o => o.level == 2)
    },
    {
      "id": 3,
      "name": this.captions.level3,
      "showExpanded": false,
      "levels": this.allSubCategories.filter(o => o.level == 3)
    },
    {
      "id": 4,
      "name": this.captions.level4,
      "showExpanded": false,
      "levels": this.allSubCategories.filter(o => o.level == 4)
    },
    {
      "id": 5,
      "name": this.captions.level5,
      "showExpanded": false,
      "levels": this.allSubCategories.filter(o => o.level == 5)
    }];
    this.setupFormGroup.controls['levelValue'].setValue(null);
    this.setupFormGroup.reset();
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetAllSubCategories" || "CreateSubCategory" || "EditSubCategory" || "SubCategoryDragDrop" || "DeleteSubCategory") {
      let response = <any>result.result;
      this.allSubCategories = [];
      response.forEach(element => {
        let subCategory: any = {
          id: element.id,
          subCategory: element.name,
          active: element.isActive,
          listOrder: element.listOrder,
          level: element.level,
          parentCategoryId: element.parentCategoryId
        }
        this.allSubCategories.push(subCategory);
      });

      let prev = this.setupFormGroup.controls['levelValue'].value;
      this.bindData();
      this.setupFormGroup.controls['levelValue'].setValue(prev);
      if (callDesc == "CreateSubCategory" || "EditSubCategory" || "DeleteSubCategory" || "SubCategoryDragDrop") {
        this.showExpandedData(this.availableLevels.filter(x => x.id == this.levelAfterOpration)[0], false, true);
      }
    }
    this.utils.ToggleLoader(false);
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this.utils.ToggleLoader(false);
  }

  searchValueChanged(e) {
    this.searchText = e;
    this.expandAllRows();
  }


  async delete(e) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.levelAfterOpration = e.level ? e.level : 1;
    this.currentId = e.id;
    this.currentLevel = e.level;

    try {
      let result = await this.http.CallApiAsync<any>({
        host: Host.retailManagement,
        callDesc: 'DeleteSubCategory',
        method: HttpMethod.Delete,
        showError: true,
        uriParams: { id: e.id, levelId: e.level }
      }).then(result =>{
        this.successCallback(result, 'DeleteSubCategory', []);
      }); 
    }
    catch (err) {
      this.utils.ToggleLoader(false);
      if(err.error != null && err.error.errorCode == this.subCategoryInUseErrorCode)
      {
          this.utils.ShowErrorMessage(this.captions.Confirmation, this.captions.ForceDeleteSubcategoryConfirmation, ButtonType.YesNo, this.PopupCallback.bind(this));
      }
      else
          this.utils.ShowErrorMessage(this.captions.Error, err.message, ButtonType.Ok);
    }
  }

  async PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == myGlobals.ButtonOptions.Yes.toLowerCase()) {
      this.forceDeleteApiCall(this.currentId, this.currentLevel);
    }
  }

  forceDeleteApiCall(id, level) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    this.levelAfterOpration = level ? level : 1;
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "DeleteSubCategory",
      uriParams: { id: id, levelId: level, forceDelete: true},
      method: HttpMethod.Delete,
      showError: true,
      extraParams: []
    });
  }

  populateTableInfo() {
    this.tableoptions = [{
      TableHdrData: [{ title: this.captions.subCategoryName, jsonkey: "subCategory", alignType: "left", sortable: true },
      { title: this.captions.Active, jsonkey: "active", alignType: "left", "type": "toggle", sortable: true, searchable: false },
      { title: this.captions.ListOrder, jsonkey: "listOrder", alignType: "right", sortable: true, searchable: false }],
      SelectedSettingId: GridType.subCategory,
      TableId: myGlobals.GridType.subCategory,
      pagination: false,
      CustomColumn: true,
      PlaceHoldertext: this.captions.Search,
      EnableActions: true,
      SelectRows: false,
      IsCommission: true,
      Searchable: false,
      EditMoreOption: false,
      disableDelete: false,
      customHeader: false,
      pageTitle: 'roleSetup',
      ServiceId: 'roleSetup',
      InactiveRoles: true,
      DoneCancel: true,
      Sortable: "listOrder",
      TableDraggable: true,
      IsViewOnly: this.IsViewOnly
    }];
  }

  expandAllRows() {
    this.availableLevels.forEach((level) => {
      this.showExpandedData(level, true);
    });
  }

  showExpandedData(level, exapndAll, fromCreateOrEdit?) {
    if (level) {
      this.tableoptions[0].setId = level.id;
      this.tableoptions[0].IsViewOnly = this.IsViewOnly;
      level.showExpanded = exapndAll ? level.showExpanded = true : !level.showExpanded;
      if (level.tableoptions == undefined) {
        this.tableoptions[0].TablebodyData = level.levels;
        let tableBodyData = _.cloneDeep(this.tableoptions[0].TablebodyData);
        level.tableoptions = _.cloneDeep(this.tableoptions);
        level.tableoptions[0].TablebodyData = tableBodyData;
        let newTableData = _.cloneDeep(level);
        this.completeTableOptions.push(newTableData);
      }
      if (fromCreateOrEdit) {
        setTimeout(() => {
          const table = document.getElementsByClassName('retail-CustomDataTable')[0] as HTMLElement;
          const tableStartHeight = table && table.offsetHeight;
          const tableEndHeight = table && table.offsetTop;
          this.scrollRef.scrollYTo(tableStartHeight + tableEndHeight, 500);
        }, 2000);
      }
    }
  }

  onToggleChange(event) {
    this.setupFormGroup.reset();
    this.setupFormGroup.disable();
    this.isEditModeSet = true;
    this.isSearchEnabled = false;
  }

  Done(e) {
    this.levelAfterOpration = e.level;
    const subCategoryObj = {
      "name": e.subCategory,
      "id": e.id,
      "level": e.level,
      "isActive": e.active,
      "listOrder": e.listOrder,
      "retailCategoryGroupId": 0
    };
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "EditSubCategory",
      method: HttpMethod.Put,
      uriParams: { id: e.id },
      body: subCategoryObj,
      showError: true,
      extraParams: []
    });
    this.isSearchEnabled = true;
    this.isEditModeSet = false;
    this.setupFormGroup.enable();
  }
  omit_special_char(event) {
    let k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  EditRecords(e) {
    console.log(e);
    this.currIndex = this.availableLevels[e[0].level - 1].levels.findIndex(item => item.id == e[0].id);
    this.tableoptions[0].TablebodyData = this.availableLevels[e[0].level - 1].levels;
    this.setText = this.commonCaptions.UPDATE;
    this.enableEdit = true;
    this.setupFormGroup.controls['activetoggle'].setValue(e[0].active);
    this.setupFormGroup.controls['subCategory'].setValue(e[0].subCategory);
    this.setupFormGroup.controls['levelValue'].setValue(e[0].level);
    this.enableEdit = true;
    this.isSearchEnabled = false;
    this.isEditModeSet = true;
  }
  resetValue() {
    this.searchText = "";
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.RetailSubCategories).view;
  }
  cancelUpdate() {
    this.enableEdit = false;
    this.setText = this.commonCaptions.ADD;
    this.isEditModeSet = false;
    this.setupFormGroup.reset();
    this.isEditModeSet = false;
    this.setupFormGroup.controls['levelValue'].setValue(1);
    if (document.getElementsByClassName('highlight').length > 0) {
      document.getElementsByClassName("highlight")[0].classList.remove("highlight");
    }
    this.isSearchEnabled = true;
  }

  checkForMinValidation(subCategoryName): boolean {
    let valid: boolean = true;
    if (!(subCategoryName.trim().length >= 2)) {
      let errMsg: string = this._ls.getError(10712);
      this.utils.ShowErrorMessage(this._ls.captions.common.Error, errMsg);
      valid = false;
    }
    return valid;
  }
  updateValue() {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
      this.levelAfterOpration = this.setupFormGroup.controls['levelValue'].value ? this.setupFormGroup.controls['levelValue'].value : 1;
      if (this.setText.toUpperCase() == this.commonCaptions.ADD.toUpperCase()) {
        let subCategoryObj = {
          name: this.setupFormGroup.controls['subCategory'].value.trim(),
          level: this.setupFormGroup.controls['levelValue'].value,
          isActive: true,
          listOrder: Number(this.availableLevels[this.setupFormGroup.controls['levelValue'].value - 1].levels.length ? this.availableLevels[this.setupFormGroup.controls['levelValue'].value - 1].levels.length : 0) + 1,
          parentCategoryId: 0
        }

        this.http.CallApiWithCallback({
          host: Host.retailManagement,
          success: this.successCallback.bind(this),
          error: this.errorCallback.bind(this),
          callDesc: "CreateSubCategory",
          method: HttpMethod.Post,
          body: subCategoryObj,
          showError: true,
          extraParams: []
        })
        console.log(this.setupFormGroup.value);
      }
      else {
        let subCategoryObj = {
          name: this.setupFormGroup.controls['subCategory'].value.trim(),
          level: this.setupFormGroup.controls['levelValue'].value,
          isActive: this.setupFormGroup.controls['activetoggle'].value ? this.setupFormGroup.controls['activetoggle'].value : 0,
          listOrder: Number(this.availableLevels[this.setupFormGroup.controls['levelValue'].value - 1].levels.length ? this.availableLevels[this.setupFormGroup.controls['levelValue'].value - 1].levels.length : 0) + 1,
          parentCategoryId: 0
        }
        this.http.CallApiWithCallback({
          host: Host.retailManagement,
          success: this.successCallback.bind(this),
          error: this.errorCallback.bind(this),
          callDesc: "EditSubCategory",
          method: HttpMethod.Put,
          uriParams: { id: this.tableoptions[0].TablebodyData[this.currIndex].id },
          body: subCategoryObj,
          showError: true,
          extraParams: []
        });
        console.log(this.setupFormGroup.value);
      }
      this.enableEdit = false;
      this.isEditModeSet = false;
      this.setText = this.commonCaptions.ADD;

    if (this.setText.toLowerCase() == this.commonCaptions.ADD) {
      console.log(this.setupFormGroup.value);
    }
    else {
      console.log(this.setupFormGroup.value);
    }
    this.enableEdit = false;
    this.setText = this.commonCaptions.ADD;
    this.isEditModeSet = false;
    if (document.getElementsByClassName('highlight').length > 0) {
      document.getElementsByClassName("highlight")[0].classList.remove("highlight");
    }
    this.isSearchEnabled = true;
  }

  checkValidState(event) {

  }

}
