import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { select, Store } from '@ngrx/store';
import { odataInventorySuccess } from 'src/app/eatecui/source/store/selectors/odata-configuration.selector';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { TranslateService } from '@ngx-translate/core';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { GetEndPointUrl } from 'src/app/eatecui/source/transaction/shared/interface/getEndpoint.interface';
import { GetListEndPointUrl } from 'src/app/eatecui/source/transaction/shared/interface/getListEndpoint.interface';
import { GridConfiguration, HeaderConfiguration, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransaction } from 'src/app/eatecui/source/transaction/shared/interface/view-transaction';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import { DynamicGridService } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import moment from 'moment';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { Formaters } from 'src/app/eatecui/source/shared/models/formaters';
import { HttpClient } from '@angular/common/http';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';

@Component({
  selector: 'app-transaction-action',
  templateUrl: './transaction-action.component.html',
  styleUrls: ['./transaction-action.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TransactionActionComponent {
  TransactionDataSuccess: Subscription;
  OdataGlobalEntityModel: OdataGlobalEntity;
  trasacationHeaderEntity: any;
  transactionDetailEntity: any;
  transactionTilesHeaderEntity: any;
  postModelHederEntity: any;
  postModelDetailEntity: any;
  transactionJsonData: TransactionConfiguration;
  params = {} as QueryParams;
  homeGroup: FormGroup;
  selectedId: any;
  selectedTransaction: any;
  responseData: Array<any>;
  headerConfiguration: HeaderConfiguration;
  actionGridTransactionData: GridConfiguration;
  gridOdataKey: any;
  originalactionGridTransactionData: any;
  from: any;
  selectedTransactionId: any;
  constructor(
    private store: Store<AppState>,
    private masterManagementService: MasterManagementService,
    private transactionService: TransactionService,
    private httpService: HttpService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private transactionViewService: ViewTransactionService,
    private dynamicGridService: DynamicGridService,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private ref: ChangeDetectorRef
  ) {
    this.homeGroup = this.formBuilder.group({});
    this.selectedTransactionId = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'id', null);
    this.selectedTransaction = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'transaction', null);
    this.from = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'from', null);
    this.TransactionDataSuccess = this.store.pipe(select(odataInventorySuccess)).subscribe((x: OdataGlobalEntity) => {
      if (!this.OdataGlobalEntityModel) {
      if (x && x !== null) {
        this.OdataGlobalEntityModel = x;
        this.getTransactionJsonData();
      } else {
        this.masterManagementService.GetOdataGlobalCollection();
      }
    }
    });
  }
  /**
* @getTransactionJsonData
*/
  async getTransactionJsonData() {
    try {
      const selectItemlist = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'actionKey', null);
      const transactionFrom = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'transactionFrom', null);
      const id = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'id', 'id');
      const selectedSeqNo = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'transSequenceNo', 'transSequenceNo');
      const customid = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'customId', null);
      this.transactionJsonData = await this.transactionService.GetTransactionJsonData(this.selectedTransaction);
      console.log('transactionJsonData', this.transactionJsonData);
      this.headerConfiguration = this.transactionJsonData.ActionConfig.filter(x => x.ActionKey === selectItemlist)[0].HeaderConfiguration;
      this.headerConfiguration.BreadCrumbConfig.forEach(x => {
        if (x.hasOwnProperty('RoutingQueryParams')) {
          const person = new Object();
          person['from'] = 'transactionitemaction';
          x.RoutingQueryParams = person;
        }
        if ((transactionFrom && (transactionFrom === 'AllTransaction' || transactionFrom === 'Transactions' || transactionFrom === 'AllTransactions'
        || transactionFrom === 'LoadFrom PO' || transactionFrom === 'Receiving')) || this.selectedTransaction === 'spoilage' 
            || this.selectedTransaction === 'production' || this.selectedTransaction === 'salesforecasting' || this.selectedTransaction === 'butchery'
            || this.selectedTransaction === 'template') {
          if (x.hasOwnProperty('RoutingQueryParams')) {
            x.RoutingUrl = `/settings/enhancedInventory/transaction/create-${this.selectedTransaction}`;
            if (this.from === 'detailspage') {
              x.Name = customid ? customid : id;
              x.DisplayName = customid ? customid : id;
              // x.Name = customid ? customid : id;
              // x.DisplayName = customid ? customid : id;
              x.RoutingUrl = this.selectedTransaction === 'salesforecasting' ? `sales-management/copynew-${this.selectedTransaction}` : 
              `transaction/copynew-${this.selectedTransaction}`;
            }
            const person = new Object();
            person['rowId'] = id;
            person['transSequenceNo'] = selectedSeqNo;
            person['transaction'] = this.selectedTransaction;
            person['transactionFrom'] = transactionFrom;
            person['from'] = 'transactionitemaction';
            x.RoutingQueryParams = person;
          }
        } else if (transactionFrom && (transactionFrom === 'OpenRequisition' || transactionFrom === 'OpenRequests' || transactionFrom === 'EditPO' ||
        transactionFrom === 'SavedReceiving' || transactionFrom === 'LoadRequisition' || transactionFrom === 'LoadPurchase Requests'
        || transactionFrom === 'AllRNI')) {
          if (x.hasOwnProperty('RoutingQueryParams')) {
            x.RoutingUrl = `/settings/enhancedInventory/transaction/create-${this.selectedTransaction}`;
            const person = new Object();
            person['rowId'] = id;
            person['transSequenceNo'] = selectedSeqNo;
            person['transaction'] = this.selectedTransaction;
            person['transactionFrom'] = transactionFrom;
            person['from'] = 'transactionitemaction';
            x.RoutingQueryParams = person;
            if (transactionFrom === 'EditPO' || transactionFrom === 'SavedReceiving' || transactionFrom === 'OpenRequests' ||
              transactionFrom === 'OpenRequisition') {
                x.Name = selectedSeqNo ? selectedSeqNo : id;
                x.DisplayName = selectedSeqNo ? selectedSeqNo : id;
              // x.Name = customid ? customid : id;
              // x.DisplayName = customid ? customid : id;
            }
          }
        } else if (this.selectedTransaction === 'sales') {
          if (x.Name === 'TransactionId') {
            x.Name = this.selectedTransactionId;
          }
        }
      });
      this.headerConfiguration = { ...this.headerConfiguration };
      console.log('Heade configuration', this.headerConfiguration);
      
      const getHeaderOdataKey = this.transactionJsonData.ActionConfig.filter(x => x.ActionKey === selectItemlist)[0].OdataKey;
      this.gridOdataKey = this.transactionJsonData.ActionConfig
        .filter(x => x.ActionKey === selectItemlist)[0].GridConfiguration.GridOdataKey;
      this.trasacationHeaderEntity = this.masterManagementService.GetViewModelData(
        this.OdataGlobalEntityModel.OdataViewModelEntity, getHeaderOdataKey);
      
      const getDetailOdataKey = this.transactionJsonData.ActionConfig.filter(x => x.ActionKey === selectItemlist)[0].OdataKey;
      this.transactionDetailEntity = this.masterManagementService.GetViewModelData(
        this.OdataGlobalEntityModel.OdataViewModelEntity, getDetailOdataKey);
      
      const getTilesHeaderOdataKey = this.transactionJsonData.ActionConfig.filter(x => x.ActionKey === selectItemlist)[0].OdataKey;
      this.transactionTilesHeaderEntity = this.masterManagementService.GetViewModelData(
        this.OdataGlobalEntityModel.OdataViewModelEntity, getTilesHeaderOdataKey);
      
      this.postModelHederEntity = this.masterManagementService.GetPostModelData(
      this.OdataGlobalEntityModel.OdataPostModelEntity, getHeaderOdataKey);
      
      this.postModelDetailEntity = this.masterManagementService.GetPostModelData(
        this.OdataGlobalEntityModel.OdataPostModelEntity, getDetailOdataKey);
      // eslint-disable-next-line
      //const actionKey = this.route.snapshot.queryParams['actionKey'];
      // this.params.orderby = this.transactionViewService.setSort(this.transactionJsonData.ActionConfig
      //   .filter(x => x.ActionKey === actionKey)[0].GridConfiguration.GridSort);
      this.contractTransAction();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method contractTransAction()
   */

  contractTransAction(): void {
    try {
      let endPointUrl = '';
      const selectedRowId = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'rowId', 'rowId');
      const selectedSeqNo = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'transSequenceNo', 'transSequenceNo');
      const actionKey = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'actionKey', null);
      const seleId = this.createActionFilter(actionKey, selectedRowId);
      const selectItemlist1 = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'actionKey', null);
      const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
      this.params.filterQuery = GetDataFilter;
      if (GetDataFilter !== '' && (this.trasacationHeaderEntity.length > 0)) {
        if (this.trasacationHeaderEntity[0]['$']['Name'] !== 'ProductionDetails' && 
         this.trasacationHeaderEntity[0]['$']['Name'] !== 'ReceivingDetails' && this.trasacationHeaderEntity[0]['$']['Name'] !== 'IngredientUsage'
         && this.trasacationHeaderEntity[0]['$']['Name'] !== 'SalesDetails') {
        endPointUrl = GetListEndPointUrl(this.trasacationHeaderEntity, this.transactionDetailEntity,
          this.transactionJsonData, this.httpService, this.params);
      } else {
        if (this.transactionJsonData && (this.transactionJsonData.Name === 'sales' || selectItemlist1 === 'itemsales')) {
          endPointUrl = `/inventory/api/ProductSales?productId=${selectedRowId}`;
        }  else {
          if (this.trasacationHeaderEntity.length > 0 && this.transactionJsonData) {
            if (this.trasacationHeaderEntity[0]['$']['Name'] === 'ItemTransfer') {
              endPointUrl = this.gridOdataKey;
              endPointUrl = `/inventory/api/${endPointUrl}/${selectedRowId}`;
            } else if (this.trasacationHeaderEntity[0]['$']['Name'] === 'IngredientUsage') {
              endPointUrl = this.gridOdataKey;
              let loggedInSiteId: any = sessionStorage.getItem('LoggedInSiteId');
              loggedInSiteId = loggedInSiteId ? parseInt(loggedInSiteId, 10) : 1;
              endPointUrl = `/reportdata/api/IngredientUsages?IngredientId=${selectedRowId}&loggedInSiteId=${loggedInSiteId}`;
            } else if (this.trasacationHeaderEntity[0]['$']['Name'] === 'ReceivingDetails') {
              endPointUrl = this.gridOdataKey;
              endPointUrl = `/inventory/api/${endPointUrl}?$filter=${GetDataFilter}` + '&$expand=ReceivingDetailsSupplier($select=id,name,number),Units($select=id,name),ReceivingDetailsPOHead($select=id),ReceivingDetailsLocation($select=id,Name)';
            } else if (this.trasacationHeaderEntity[0]['$']['Name'] === 'ProductionDetails') {
              endPointUrl = this.gridOdataKey;
              // endPointUrl = `/inventory/api/${endPointUrl}?$filter=${GetDataFilter}` + 
              // '&$expand=ProductionHead($select=id,Reference),ProdDetailUnit($select=id,name),Locations($select=id,name)';
              endPointUrl = `/inventory/api/GetProductionDetails/Ingredients/${selectedRowId}`;
            } else {
              endPointUrl = GetListEndPointUrl(this.trasacationHeaderEntity, this.transactionDetailEntity,
                this.transactionJsonData, this.httpService, this.params);
            }
          }
        }
      } 
    } else {
        if (this.trasacationHeaderEntity.length > 0 && this.transactionJsonData) {
          if (this.trasacationHeaderEntity[0]['$']['Name'] === 'ItemTransfer') {
            endPointUrl = this.gridOdataKey;
            endPointUrl = `/inventory/api/${endPointUrl}/${selectedRowId}`;
          } else if (this.trasacationHeaderEntity[0]['$']['Name'] === 'IngredientUsage') {
            endPointUrl = this.gridOdataKey;
            let loggedInSiteId: any = sessionStorage.getItem('LoggedInSiteId');
            loggedInSiteId = loggedInSiteId ? parseInt(loggedInSiteId, 10) : 1;
            endPointUrl = `/reportdata/api/IngredientUsages?IngredientId=${selectedRowId}&loggedInSiteId=${loggedInSiteId}`;
          } else if (this.trasacationHeaderEntity[0]['$']['Name'] === 'ReceivingDetails') {
            endPointUrl = this.gridOdataKey;
            endPointUrl = `/inventory/api/${endPointUrl}?$filter=${GetDataFilter}` + '&$expand=ReceivingDetailsSupplier($select=id,name,number),Units($select=id,name),ReceivingDetailsPOHead($select=id),ReceivingDetailsLocation($select=id,Name)';
          } else if (this.trasacationHeaderEntity[0]['$']['Name'] === 'ProductionDetails') {
            endPointUrl = this.gridOdataKey;
            // endPointUrl = `/inventory/api/${endPointUrl}?$filter=${GetDataFilter}` + 
            // '&$expand=ProductionHead($select=id,Reference),ProdDetailUnit($select=id,name),Locations($select=id,name)';
            endPointUrl = `/inventory/api/GetProductionDetails/Ingredients/${selectedRowId}`;
          } else {
            endPointUrl = GetListEndPointUrl(this.trasacationHeaderEntity, this.transactionDetailEntity,
              this.transactionJsonData, this.httpService, this.params);
          }
        }
      }
      this.httpService.GethttpMethod(endPointUrl).subscribe(data => {
        let responseData = [];
        const selectItemlist = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'actionKey', null);
        if (this.trasacationHeaderEntity && this.trasacationHeaderEntity.length > 0) {
        if (this.trasacationHeaderEntity[0]['$']['Name'] === 'ItemTransfer') {
          const dataObject = JSON.parse(data['value']);
          const entityName = this.trasacationHeaderEntity[0]['$']['Name'] === 'ItemTransfer'
            ? this.trasacationHeaderEntity[0]['$']['Name'] + 's' : this.trasacationHeaderEntity[0]['$']['Name'];
          responseData = dataObject[entityName];
        } else {
          if (this.trasacationHeaderEntity[0]['$']['Name'] === 'IngredientUsage' || this.trasacationHeaderEntity[0]['$']['Name'] === 'SalesDetails') {
            responseData = data;
          } else {
            if (this.trasacationHeaderEntity[0]['$']['Name'] === 'IngredientsBids' && data['value'] && data['value'].length > 0) {
              data['value'].forEach(x => {
                x.spec1 = Formater
                ('Conversion', ((x.Price ? x.Price : 0) / (x.Conversion && x.Conversion !== 0 ? x.Conversion : 1)), null, null);
              });
            }
            responseData = data['value'];
          }
        } } else {
          if (this.trasacationHeaderEntity[0]['$']['Name'] === 'IngredientsBids' && data['value'] && data['value'].length > 0) {
            data['value'].forEach(x => {
              x.spec1 = Formater
              ('Conversion', ((x.Price ? x.Price : 0) / (x.Conversion && x.Conversion !== 0 ? x.Conversion : 1)), null, null);
            });
          }
          responseData = data['value'];
        }
        const responseDataArray = [];
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any) => {
            const dataObject = _.mapKeys(element, function (v, k) { return k.toLowerCase(); });
            responseDataArray.push(dataObject);
          });  
        }
        this.transactionJsonData.ViewTranscationConfig.GridConfiguration = {
          ...this.transactionJsonData.ActionConfig
            .filter(x => x.ActionKey === selectItemlist)[0].GridConfiguration
        };
        const transactionActionGrid = ViewTransaction.HeaderGridConfiguration(
          this.trasacationHeaderEntity, this.transactionDetailEntity, this.transactionJsonData, this.httpService, this.translateService,
          this.transactionTilesHeaderEntity, responseDataArray, this.httpClient);
        if (transactionActionGrid.GridOdataKey === 'ReceivingDetails' && transactionActionGrid.GridHeaderDefination.length > 0) {

          transactionActionGrid.GridHeaderDefination.filter(x => x.field === 'unitcost')[0].valueGetter = params => {
            params.data.unitcost = params.data.qty && params.data.qty !== 0 
                                   ? parseFloat(params.data.amount) / parseFloat(params.data.qty) : 0;
            ​​​​​​return params.data.unitcost.toFixed(3);
          }​​​​​​;
          transactionActionGrid.GridHeaderDefination.filter(x => x.field === 'itemlotlabelqty')[0].valueGetter = params => {
            params.data.itemlotlabelqty =
              params.data.qty && params.data.qty !== 0 ? parseFloat(params.data.conversion) * parseFloat(params.data.qty) : 0;
            ​​​​​​return params.data.itemlotlabelqty.toFixed(2);
          }​​​​​​;
          transactionActionGrid.GridHeaderDefination.filter(x => x.field === 'amountinfc')[0].valueGetter = params => {
            params.data.amountinfc =  params.data.amount && params.data.amount !== 0 ? parseFloat(params.data.amount) : 0;
            ​​​​​​return params.data.amountinfc.toFixed(3);
          }​​​​​​;
          // transactionActionGrid.GridHeaderDefination.filter(x => x.field === 'id')[0].valueGetter = params => {
          //   params.data.id = params.data.receivingdetailssupplier.Id && params.data.receivingdetailssupplier.Number !== 0 
          //   ? params.data.receivingdetailssupplier.Number : 0; 
          //   ​​​​​​return params.data.id;
          // }​​​​​​;
          transactionActionGrid.GridHeaderDefination.filter(x => x.field === 'chargesinfc')[0].valueGetter = params => {
            params.data.chargesinfc = params.data.qty && params.data.qty !== 0 
            ? parseFloat(params.data.amount) / (parseFloat(params.data.conversion) * parseFloat(params.data.qty)) : 0;
            ​​​​​​return params.data.chargesinfc.toFixed(3);
          }​​​​​​;
        }
        this.actionGridTransactionData = { ...transactionActionGrid };
        console.log(this.actionGridTransactionData);
        setTimeout(() => {
          this.ref.detectChanges();
        }, 100);
        this.originalactionGridTransactionData = _.cloneDeep(this.actionGridTransactionData);
        this.gridActionEvent();
        if (this.actionGridTransactionData.GridSort.length > 0) {
          const value = [
            {
              colId: this.actionGridTransactionData.GridSort[0].SortingOdataKey.toLowerCase(),
              sort: this.actionGridTransactionData.GridSort[0].SortingType.toLowerCase()
            }
          ];
          this.actionGridTransactionData.sortChanged(value);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  gridActionEvent() {
    this.actionGridTransactionData.sortChanged = (value: any) => {
      // this.params.orderby = value[0].colId + ' ' + value[0].sort;
      const orderBy = value[0].sort;
      const tempKey = value[0].colId;
      const key = this.actionGridTransactionData.GridHeaderDefination.filter(x => x.field === value[0].colId)[0].fieldType;
      const data = this.actionGridTransactionData.GridData;
      this.actionGridTransactionData.GridSort[0].SortingOdataKey = tempKey;
      this.actionGridTransactionData.GridSort[0].SortingType = orderBy;
      const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
      const DateTimeFormat = (sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null) ?
        sessionDateFormat :  constants.DefaultIniDateFormat;
      if (key.toString() === 'date') {
        data.forEach(x => {
          if (x[tempKey] && x[tempKey].indexOf('T') !== -1) {
            x[tempKey] = moment(new Date(x[tempKey])).format(DateTimeFormat);
          }
        });
        data.sort((a, b) => {
          const data1 = a[tempKey] ? Number(moment(a[tempKey], DateTimeFormat).format('X')) : 0;
          const data2 = b[tempKey] ? Number(moment(b[tempKey], DateTimeFormat).format('X')) : 0;
          if (orderBy.toString() === 'desc') {
            return data2 - data1;
          } else {
            return data1 - data2;
          }

        });
      } else if (key.toString() === 'number') {
        data.sort((a, b) => {
          const data1 = Number(a[tempKey]);
          const data2 = Number(b[tempKey]);
          if (orderBy.toString() === 'desc') {
            return data2 - data1;
          } else {
            return data1 - data2;
          }
        });
      } else if (key.toString() === 'string') {
        data.sort((a, b) => {
          const data1 = a[tempKey] ? a[tempKey].replace(/\s/g, '').toLowerCase() : '';
          const data2 = b[tempKey] ? b[tempKey].replace(/\s/g, '').toLowerCase() : '';
          if (orderBy.toString() === 'desc') {
            // if (data2 < data1) {
            //   return -1;
            // }
            // if (data2 > data1) {
            //   return 1;
            // }
            // return 0;
            return data2.localeCompare(data1, undefined, {numeric: true});
          } else {
            // if (data1 < data2) {
            //   return -1;
            // }
            // if (data1 > data2) {
            //   return 1;
            // }
            // return 0;
            return data1.localeCompare(data2, undefined, {numeric: true});
          }

        });
      }
      this.actionGridTransactionData.GridData = data;
      this.actionGridTransactionData.GridData = [...this.actionGridTransactionData.GridData]; 
      this.actionGridTransactionData = { ...this.actionGridTransactionData };
    };
    this.actionGridTransactionData.searchTextChanged = (searchText) => {
      this.searchTextChange(searchText);
    };
    this.actionGridTransactionData.columnChanged = (value: any) => {
      const columnJson = this.dynamicGridService.assignColumns(value[1], value[0]);
      this.actionGridTransactionData.GridActions.ShuffleColumnConfig.ColumnsList = columnJson;
      this.actionGridTransactionData.GridHeaderDefination = columnJson;
      this.actionGridTransactionData = { ...this.actionGridTransactionData };
    };
  }

  /***
   * @method createActionFilter(ActionKey: string)
   */
  createActionFilter(ActionKey: string, rowId: number): string {
    try {
      let filterString = '';
      const getSessionLocation = JSON.parse(sessionStorage.getItem('FromLocId'));
      switch (ActionKey) {
        case 'itemtransfer':
          filterString = '';
          break;
        case 'itemlocation':
          case 'itembids':
            case 'itemusage':
          filterString = 'IsActive eq true and IngredientId eq ' + rowId;
          break;
        case 'itempurchase':
          filterString = 'IsPosted eq true and IngredientId eq ' + rowId;
          break;
          case 'production':
            case 'butchery':
            filterString = 'IngredientId eq ' + rowId;
            break;
        case 'itemsales':
          filterString = 'productId eq ' + rowId;
          break;
        default:
          filterString = `SpoilageDetails/any(d: d/IngredientId eq ${rowId})`;
      }
      return filterString;
    } catch (error) {
      console.error(error);
    }
  }

  searchTextChange(searchkey) {
    const rowData = this.originalactionGridTransactionData.GridData;
    const key = this.actionGridTransactionData.GridSearch[0].SearchKey.toLowerCase();
    let returnData = [];
    if (searchkey.length > 0) {
      returnData = rowData.filter(x => x[key].toString().toLowerCase().includes(searchkey.toLowerCase()));
    } else {
      returnData = rowData;
    }
    this.actionGridTransactionData.GridData = [];
    this.actionGridTransactionData.GridData = returnData;
    if (this.actionGridTransactionData.GridSort.length > 0) {
      const value = [
        {
          colId: this.actionGridTransactionData.GridSort[0].SortingOdataKey.toLowerCase(),
          sort: this.actionGridTransactionData.GridSort[0].SortingType.toLowerCase()
        }
      ];
      this.actionGridTransactionData.sortChanged(value);
    }
    this.actionGridTransactionData.GridActions.TotalItems = returnData.length;
    this.actionGridTransactionData = { ...this.actionGridTransactionData };
  }

}
