import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TherapistUnavailability } from '../../../shared/business/view-settings.modals';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { Host, ButtonType, GridOperationType, SPAManagementBreakPoint } from '../../../shared/globalsContant';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-therapist-unavailability',
  templateUrl: './therapist-unavailability.component.html',
  styleUrls: ['./therapist-unavailability.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TherapistUnavailabilityComponent implements OnInit {
  //any
  selectedServices: any[] = [];
  therapists = [];
  therapistOptions = [];
  captions: any = {};

  //boolean
  isViewOnly = false;
  showServiceError:boolean=false;

  //Date
  minDate: Date = this.PropertyInfo.CurrentDate;

  //FormGroup
  FormGrp: UntypedFormGroup;

  //number
  serviceBookedErrorCode = 173;
  duplicateTherpaistUnavailability = 174;
  therapistId = 0;
  totalSelectedServices: number;

  //string
  therapist_name: string;

  therapistUnavailDetails: TherapistUnavailability;
  @ViewChild('therapistInput') therapistInput: ElementRef;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private Form: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<TherapistUnavailabilityComponent>,
    private http: HttpServiceCall, private utils: SpaUtilities, public localization: SpaLocalization, private vsBusiness: ViewSettingClientBusiness,
    private breakPoint: BreakPointAccess, private PropertyInfo: SpaPropertyInformation) {
      this.placeholderFormat = this.localization.inputDateFormat;
      this.commonCaptions = this.localization.captions.common;
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      therapistSelected: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      reason: ['', [Validators.required, EmptyValueValidator]],
      isClassView: [false]
    });
    this.captions = this.localization.captions.utilities;
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.totalSelectedServices = 0;
    this.therapistUnavailDetails = { id: 0,
        startDate: null,
        endDate: null,
        therapistCode: '',
        therapistName: '',
        services: [],
        unavailabilityReason: ''
      };
      this.saveButton = {
        type: 'primary',
        label: this.data.popupConfig.GridOperationType == GridOperationType.Create?this.captions.save:this.captions.update,
        disabledproperty: true
      }
      this.cancelButton = {
        type: 'tertiary',
        label: this.captions.cancel
      }

    if (this.data.popupConfig.GridOperationType == GridOperationType.Create) {
      this.LoadTherapists(false);
      this.FormGrp.get('isClassView')?.enable();
    } else {
      let isClassView:boolean=false;
      if(this.data.popupConfig?.isClassView)
      {
        isClassView=true;
      }
      else
      {
        isClassView=false;
      }
      this.LoadTherapistsWithQueryString(true,{ key: "isInstructor", value:  [isClassView]});
      this.FormGrp.controls["isClassView"].setValue(isClassView);
      this.FormGrp.get('isClassView')?.disable();
      this.breakPoint.CheckForAccess([SPAManagementBreakPoint.TherapistUnavailability], false);
      if (this.breakPoint.IsViewOnly(SPAManagementBreakPoint.TherapistUnavailability)) {
        this.isViewOnly = true;
        this.utils.disableControls(this.FormGrp);
      }
    }
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.enableSave();
    })
  }

  enableSave(){
    this.saveButton.disabledproperty = !(this.therapistId && this.FormGrp.valid && this.FormGrp.dirty && this.totalSelectedServices > 0 )
  }

  searchTherapist(): void {
    const searchName = this.therapistInput.nativeElement.value;
    if (searchName && searchName.length >= 1) {
      this.therapistOptions = this.therapists.
        filter(therapist => {
          return (therapist.firstName ? therapist.firstName : '').toLowerCase().includes(searchName.toLowerCase())
            || (therapist.lastName ? therapist.lastName : '').toLowerCase().includes(searchName.toLowerCase())
            || (therapist.code ? therapist.code : '').toLowerCase().includes(searchName.toLowerCase());
        });
    } else {
      this.therapistOptions = [];
    }
  }

  // Select Therapist Services eg: Foot Massage
  selectService(serviceId: any): void {
    this.FormGrp.markAsDirty();
    if (this.selectedServices.includes(serviceId)) {
      this.totalSelectedServices--;
      this.selectedServices.splice(this.selectedServices.indexOf(serviceId), 1);
    } else {
      this.totalSelectedServices++;
      this.selectedServices.push(serviceId);
    }
    this.enableSave();
    this.showErrorMessage();
  }

  onOptionChange(event, name): void {
    this.therapistInput.nativeElement.blur();
    this.therapistId = event.option.value;
    this.getTherapistServices();
    this.therapistNameDisplay(this.therapistId);
  }

  //Display format of therapist name
  therapistNameDisplay(therapistId): any {
    let details = [];
    details = therapistId ? this.therapists.filter(option => option.id == therapistId) : this.therapists;
    this.therapistInput.nativeElement.value = details.length > 0 ? `${details[0].firstName} ${details[0].lastName}- ${details[0].code}` : '';
    this.therapist_name = this.therapistInput.nativeElement.value;
    return this.therapistInput.nativeElement.value;
  }

  save(byPassAppointments = false): void {
    this.saveButton.disabledproperty = true;
    const services = [];
    this.selectedServices.forEach(x => {
      services.push({ serviceId: x });
    });
    const therapistUnavailability = {
      therapistId: this.therapistId,
      reason: this.therapistUnavailDetails.unavailabilityReason,
      startDate: this.utils.formatDate(this.therapistUnavailDetails.startDate),
      endDate: this.utils.formatDate(this.therapistUnavailDetails.endDate),
      TherapistUnavailableServices: services
    };
    const isUpdate = this.data.popupConfig.GridOperationType == GridOperationType.Edit;
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      uriParams: isUpdate ? { id: this.data.datarecord.id, byPassAppointments: byPassAppointments } : { byPassAppointments: byPassAppointments },
      callDesc: isUpdate ? 'UpdateTherapistUnavailability' : 'SaveTherapistUnavailability',
      body: therapistUnavailability,
      method: isUpdate ? HttpMethod.Put : HttpMethod.Post,
      showError: false,
      extraParams: []
    });
  }

  cancel(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  
  isTypeChange(event)
  {
    this.therapistInput.nativeElement.value = '';
    this.therapistId = 0;
    this.therapistUnavailDetails.services = [];
    this.selectedServices = [];
    this.totalSelectedServices = 0;
    this.FormGrp.setErrors({ incorrect: true });
    if(!event.value)
      {
        this.LoadTherapists(false);
      }
      else{
        this.LoadTherapistsWithQueryString(false,{ key: "isInstructor", value:  [true]});
      }
  }

  LoadTherapists(isEdit) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'getActiveTherapist',
      method: HttpMethod.Get,
      showError: true,
      extraParams: [isEdit]
    });
  }

  LoadTherapistsWithQueryString(isEdit,keyValue) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'getActiveTherapist',
      method: HttpMethod.Get,
      showError: true,
      extraParams: [isEdit],
			queryString: keyValue
    });
  }

  getTherapistServices() {
    if (this.therapistId) {
      this.http.CallApiWithCallback<any>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "getTherapistCertification",
        uriParams: { id: this.therapistId, includeServiceDetail: true },
        method: HttpMethod.Get,
        showError: true,
        extraParams: []
      });
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (['SaveTherapistUnavailability', 'UpdateTherapistUnavailability'].includes(callDesc)) {
      this.dialogRef.close();
    }
    if (callDesc == 'getActiveTherapist') {
      this.therapists = <any>result.result;
      if (extraParams[0]) {
        this.setEditValues(this.data.datarecord);
      }
    } else if (callDesc == 'getTherapistCertification') {
      this.therapistUnavailDetails.services = <any>result.result;
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (['SaveTherapistUnavailability', 'UpdateTherapistUnavailability'].includes(callDesc)) {
      this.saveButton.disabledproperty = false;
      if (error.errorCode == this.serviceBookedErrorCode) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(error.errorCode) , ButtonType.YesNo, this.ByPassAppointments.bind(this));
      }
      if (error.errorCode == this.duplicateTherpaistUnavailability) {
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(error.errorCode) , ButtonType.Ok);
    }
    }
  }

  ByPassAppointments(result, extraParams): void {
    if (result.toLowerCase() == 'yes') {
      this.save(true);
    }
  }

  therapistDeleteCheck($event) {
    if (($event.keyCode === 46 || $event.keyCode === 8) && this.therapistId > 0) {
      this.therapistInput.nativeElement.value = '';
      this.therapistId = 0;
      this.therapistUnavailDetails.services = [];
      this.selectedServices = [];
      this.totalSelectedServices = 0;
      this.FormGrp.setErrors({ incorrect: true });
    }
  }

  setEditValues(data: any): void {
    this.therapistId = data.therapistId;
    this.therapistNameDisplay(this.therapistId);
    const therapist = this.therapists.filter(res => res.id == this.therapistId);
    this.selectedServices = data.therapistUnavailableServices && data.therapistUnavailableServices.length > 0 ? data.therapistUnavailableServices.map(x => x.serviceId) : [];
    this.totalSelectedServices = this.selectedServices.length;
    this.minDate = this.utils.GetDateWithoutTime(this.PropertyInfo.CurrentDate).getTime() < this.utils.getDate(data.startDate).getTime() ? this.utils.formatDate(this.PropertyInfo.CurrentDate) : data.startDate;
    this.therapistUnavailDetails =
      {
        id: data.therapistId,
        startDate: data.startDate,
        endDate: data.endDate,
        therapistCode: therapist.length > 0 ? therapist[0].code : '',
        therapistName: therapist.length > 0 ? `${therapist[0].firstName} ${therapist[0].lastName}` : '',
        services: this.selectedServices,
        unavailabilityReason: data.reason
      };
    this.getTherapistServices();
  }

  showErrorMessage(){
    if(this.therapistUnavailDetails.services && this.therapistUnavailDetails.services.length > 0 && this.totalSelectedServices == 0){
      this.showServiceError=true;
    }else{
      this.showServiceError=false;
    }
  }
}
