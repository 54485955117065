<section *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'" class="{{field.class}} search-autocomplete">
    <div [formGroup]="form" #formtext>
    <!-- <i *ngIf="field.iconName" class="icon-{{field.iconName}} pr8"></i> -->
    <mat-form-field title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
        selectedLabels : formFieldToolTip}}" [hideRequiredMarker]="false" class="golftextbox {{field.class}}" appearance="fill" [ngClass]="field.fieldType[fieldType].disbaledProperty === true ? 'textbox-disabled':  ''">
        <mat-label>{{formFieldlabel}}</mat-label>
      <input 
      [attr.type]="hidePassword ? typeField : field.type " 
      [id]="formFieldName" 
      [name]="formFieldName"
      [formControlName]="formFieldName" 
      matInput 
      placeholder="{{formFieldlabel}}" 
      (keypress)="keyPress($event)" 
      (keyup)="onKey($event)" 
      (paste)="onPaste($event)"
      [readonly]="field.fieldType[fieldType].disbaledProperty" 
      [required]="errorLength > 0"
      [matAutocomplete]="auto"
      >
      <mat-error>{{errorMessage}}</mat-error>
      <i *ngIf="field.type == 'password'"
         matSuffix [ngClass]="hidePassword ? 'icon-password-show-eatec' : 'icon-password-hide-eatec '"  (click)="showPassword()"
         ></i>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  (optionSelected)='selectedOption($event.option)' class="search-auto-complete autocomplete-{{formFieldName}}" (closed)="panelClose()">
           <div *ngIf="field.fieldType.SingleData.autoCompleteData.length > 0">
            <mat-option class="header-option" [disabled]="true">
                <div class="panel-header-conatiner" *ngIf="autoCompleteHeader && autoCompleteHeader.length > 0 && field.fieldType.SingleData.autoCompleteData.length > 0">
                    <div class="panel-header-list" *ngFor="let header of autoCompleteHeader">
                        <span *ngIf="!header.Disable">{{header.DisplayName}}</span>
                    </div>
                </div>
            </mat-option>
            <div *ngIf="field.fieldType.SingleData.autoCompleteData.length > 0" class="data-container" [ngClass]="autoCompleteHeader.length === 1 ? 'data-w-100' : ''">
                <mat-option class="data-option" *ngFor="let data of field.fieldType.SingleData.autoCompleteData" [value]="data">
                    <span class="data-list" *ngFor="let headerData of autoCompleteHeader" title="{{data[headerData.Name]}}">{{data[headerData.Name]}}</span>
                </mat-option>
            </div>
            </div>
            <div id="search-autocompleted-nodata-found" *ngIf="field.fieldType.SingleData.autoCompleteData.length === 0 && form.controls[formFieldName].value" class="data-container">
                <mat-option class="data-option" [disabled]="true">
                    <span class="data-list no-data-found" >No Data Found</span>
                </mat-option>
            </div>
        </mat-autocomplete>
    </mat-form-field>
    </div>
</section>