import { Pipe, PipeTransform } from '@angular/core';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { SpaLocalization } from './spa-localization';

@Pipe({
  name: 'localizeDateTime'
})
export class LocalizeDateTimePipe implements PipeTransform {

  constructor(private localization: SpaLocalization, private utilities: SpaUtilities) {

  }
  transform(value: any, duration = 0): any {
    let formattedDate;
    if (duration) {
      formattedDate = this.localization.LocalizeDate(value) + ' ' + this.localization.LocalizeTime(value) + ' - ' + this.localization.LocalizeTime(this.utilities.AddMinutes(new Date(value), duration));
    } else {
      formattedDate = value ? this.localization.LocalizeDate(value) + " - " + this.localization.LocalizeTime(value) : "";
    }
    return formattedDate;
  }

}
