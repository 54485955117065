import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { AgilysysButtonModel } from './models/agilysys-buttons.interface';
@Component({
    selector: 'app-agilysys-button',
    templateUrl: './agilysys-buttons.component.html',
    styleUrls: ['./agilysys-buttons.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export  class AgilysysButtonComponent implements OnChanges {
    @Input() AgilysysButton: AgilysysButtonModel[];
    constructor() { }
    /**
     * @method buttonAction(action)
     * @params action obj
     */
    buttonAction( button: AgilysysButtonModel, index: number) {
     try {
       this.AgilysysButton[index].onCellClick(button);
     } catch (error) {
         console.error(error);
     }
    }
    ngOnChanges() {
        console.log('Agilysys button called');
    }
}