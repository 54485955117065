import { GridFilterAction } from '../master.interface';
import { FieldKeyMapping } from './grid-config.interface';

export const GridFilterKeyMapping: FieldKeyMapping = (GridFilterDataObj: GridFilterAction, ResponseData: any): any => {
    try {
        let formKey = ( ResponseData.hasOwnProperty('Id')) ? ResponseData.Id : ResponseData.id;
        if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('CustomMapping')) {
            formKey = ResponseData[GridFilterDataObj.CustomMapping.CustomKey];
        }
        return formKey;
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  };