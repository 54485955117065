import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { DetailsPayload, UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { AgilysysPicturePopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-picture-popup/agilysys-picture-popup.component';
import { MasterName } from 'src/app/eatecui/source/setup-master/shared/enum/master-enum';
import {
  DetailPictureConfig,
  FormBuilderGroupKey, MasterInventoryManagement
} from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomAddPicture: CustomFormConfiguration = async (
  ViewModelEntity: any, PostModelEntity: any,
  MasterDataCollection: MasterInventoryManagement,
  httpService: HttpService,
  FieldGrouping: FormBuilderGroupKey,
  FormGroupData: FormGroup, FormField: any, CustomData: MatDialog,
  sysytemPreferenceService?: SysytemPreferenceService,
  serviceData?: any,
  customBuilderData?: any,
  FileEvent?: any,
  domSanitizer?: any,
  toastrService?: ToastrService
): Promise<any> => {
  try {
    if (FileEvent && FileEvent.hasOwnProperty('actionType')) {
      const FileDetails: DetailsPayload = {
        ItemId: 0,
        GroupId: 1
      };
      switch (MasterDataCollection.Key) {
        case MasterName.MasterIngredients: FileDetails.GroupId = 1; break;
        case MasterName.MasterProducts: FileDetails.GroupId = 3; break;
        case MasterName.MasterReceipe: FileDetails.GroupId = 2; break;
    }
      let fileDataCollection: Array<any> = [];
      if (!MasterDataCollection.DetailsConfig.UploadFileCollection) {
        MasterDataCollection.DetailsConfig.UploadFileCollection = [];
      }
      fileDataCollection = await FileData.FileUpload(FileEvent.FileEvent, httpService, FileDetails,
           fileDataCollection, toastrService);
      if (fileDataCollection && fileDataCollection.length > 0) {
        MasterDataCollection.DetailsConfig.UploadFileCollection = 
        [...MasterDataCollection.DetailsConfig.UploadFileCollection, ...fileDataCollection];
      }
    } else {
      const actionMode = 'edit';
      const detailPictureConfig = {} as DetailPictureConfig;
      detailPictureConfig.action = actionMode;
      detailPictureConfig.PictureKey = FieldGrouping.OdataKey;
      const pictureName = MasterDataCollection.FormConfig.FlowMode === 'Create' ? 'Image Upload' :
        (MasterDataCollection.DetailsConfig.DetailsObservation ? MasterDataCollection.DetailsConfig.DetailsObservation
        : FormGroupData.value.Name);
      detailPictureConfig.PictureHeaderName = pictureName;
      detailPictureConfig.FormData = FormGroupData;
      detailPictureConfig.PictureDetail = [];
      detailPictureConfig.IsActivePictures = [];
      detailPictureConfig.PictureUrl = 'InventoryPictures';
      const pictureValue = MasterDataCollection.DetailsConfig.UploadFileCollection;
      // FormGroupData.controls[FieldGrouping.Name].value;
      pictureValue?.forEach((picturesBase64: any, index: number) => {
        picturesBase64['Name'] = (picturesBase64.hasOwnProperty('FileDetails')) ? picturesBase64.FileDetails.FileName :
        (picturesBase64.hasOwnProperty('FileName')) ?
        picturesBase64.FileName : picturesBase64.Name;
        picturesBase64['Url'] = picturesBase64['Picture'] ?
        picturesBase64['Picture'] :
        FormGroupData.controls[FieldGrouping.Name]?.value[index]?.Picture;
        picturesBase64['IsFavorite'] = (picturesBase64['IsFavorite'] === undefined) ? false :
        (picturesBase64.hasOwnProperty('FileDetails')) ? picturesBase64.FileDetails.IsFavorite : picturesBase64['IsFavorite'];
        picturesBase64['SortOrder'] = (picturesBase64['SortOrder'] === undefined) ? index : picturesBase64['SortOrder'];
        detailPictureConfig.PictureDetail.push(picturesBase64);
      });
      MasterDataCollection.DetailsConfig.DetailPictures = detailPictureConfig;
      let GroupId: number;
      switch (MasterDataCollection.Key) {
        case MasterName.MasterIngredients: GroupId = 1; break;
        case MasterName.MasterProducts: GroupId = 3; break;
        case MasterName.MasterReceipe: GroupId = 2; break;
      }
      MasterDataCollection.DetailsConfig.DetailPictures.GroupId = GroupId;
      const dialogRef = CustomData.open(AgilysysPicturePopupComponent, {
        width: '75%',
        height: '75%',
        data: MasterDataCollection.DetailsConfig.DetailPictures,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((result: Array<UploadMetaData>) => {
        MasterDataCollection.DetailsConfig.UploadFileCollection = result;
        // result.sort(function(x, y) {
        //   return (x.FileDetails.IsFavorite === y.FileDetails.IsFavorite) ? 0 : x.FileDetails.IsFavorite ? -1 : 1;
        // });
        // FormGroupData.controls[FieldGrouping.Name].setValue(result);
      });
    }

  } catch (error) {
    console.log('error occured CustomDefaultSelect', error);
  }
};
