import { ErrorHandler, Injectable } from '@angular/core';
import { ApmErrorHandler } from '@elastic/apm-rum-angular'

@Injectable()
export class GlobalErrorHandler extends ApmErrorHandler implements ErrorHandler {
 
  handleError(error: any): void {
   super.handleError(error)   
   const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}