import { Injectable } from '@angular/core';
import { NotificationCommunication } from '../../communication/services/notification-communication-service';
import { CommonControllersRoutes } from '../../communication/common-route';
import { API, FtpProtocols } from 'src/app/common/Models/sftp.model';


@Injectable( { providedIn: 'root' })
export class SftpDataService {

    constructor(private _notificationCommunication: NotificationCommunication) {
    }

    public getSFTPConfiguration(): Promise<API.SFTPConnection[]> {
        let result = this._notificationCommunication.getPromise<API.SFTPConnection[]>(
            { route: CommonControllersRoutes.GetSFTPConfigLink}, true);
        return result;
    }

    public getSFTPConfigurationByProtocol(protocol: FtpProtocols = FtpProtocols.FTP): Promise<API.SFTPConnection> {
        let result = this._notificationCommunication.getPromise<API.SFTPConnection>(
            {
              route: CommonControllersRoutes.GetSFTPSetupByProtocol,
              uriParams: { "protocol": protocol }
            }, true);
        return result;
    }

    public createSFTPConfiguration(body: API.SFTPConnection): Promise<API.SFTPConnection> {
        let result = this._notificationCommunication.postPromise<API.SFTPConnection>(
            { route: CommonControllersRoutes.CreateSFTPConfigLink, body: body }, true);
        return result;
    }

    public UpdateSFTPConfiguration(body: API.SFTPConnection): Promise<API.SFTPConnection> {
        let result = this._notificationCommunication.putPromise<API.SFTPConnection>(
            { route: CommonControllersRoutes.UpdateSFTPConfigLink, body: body }, true);
        return result;
    }

    public checkSFTPConnection(body: API.SFTPConnection): Promise<boolean> {
        let result = this._notificationCommunication.postPromise<boolean>(
            { route: CommonControllersRoutes.CheckSFTPConnectionLink, body: body }, true);
        return result;
    }

    public  deleteSFTPConfigLink(id : number): Promise<boolean> {
        let result =  this._notificationCommunication.deletePromise<boolean>(
            { route: CommonControllersRoutes.DeleteSFTPConfigLink, uriParams: { "id": id }}, true);
        return result;
    }

    public checkConnectionName(connectionName : string) : Promise<boolean> {
        let result =  this._notificationCommunication.postPromise<boolean>(
            { route: CommonControllersRoutes.CheckConnectionName, uriParams: { "connectionName": connectionName }}, true);
        return result;
    }

    public IsSftpLinkedToJobCustomConfiguration(id: number) : Promise<[boolean, boolean]> {
        let result =  this._notificationCommunication.getPromise<[boolean, boolean]>(
            { route: CommonControllersRoutes.IsSftpLinkedToJobCustomConfigurationAsync, uriParams: { "id": id }}, true);
        return result;
    }

}