import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopModel, SuggestQuantityPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { ActivatedRoute } from '@angular/router';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { ConversionAlgorithm } from 'src/app/eatecui/source/shared/algorithms';

export const AllocateChargesInfo: PostPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    Result: PostPOSupplierConfig,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId: number,
    selectedSeqNo: any,
    analysisRedirect: any,
    commonService: CommonService,
    router?: ActivatedRoute,
    SystemPreferenceService?: SysytemPreferenceService
    ): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ItemAllocateCharges')) {
            let rguestTenantId = false;
            const checkTenant = sessionStorage.getItem('RGuestTenantId');
            if (checkTenant && JSON.parse(checkTenant)) {
                rguestTenantId = true;
            }
            const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
            const VisibleDigit = INIDecimalValue ? parseInt(INIDecimalValue, 10) :
                JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength;
            const DataInfo = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges;
            if (transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges &&
                transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges.FormGroupData) {
                DataInfo.FormGroupData = transactionService.transactionConfigurationData.CreateTranscationConfig.
                    ItemsConfig.ItemAllocateCharges.FormGroupData;
            }
            if (DataInfo.FormGroupData) {
                DataInfo.FormGroupData.Value = DataInfo.FormGroupData && DataInfo.FormGroupData.Value !== '' ?
                    Formater('Currency', DataInfo.FormGroupData.Value, null, null) :
                    Formater('Currency', 0, null, null);
            }
            DataInfo.FormFields[0].fieldFormaters = {
                EnableDecimalFormatter: true,
                WholeNumberLength: commonService.GetWholeNumberLength(true),
                VisibleDecimal: CommonService.GetCurrencyVisible(),
                FocusDecimal: commonService.GetFocusNumberLength(true)
            };
            DataInfo.FormFields[0].fieldType.SingleData.FieldValidation = [
                { validation: CustomValidator.onlyCheckNumber, validationMessage: 'Enter only number', key: 'onlyCheckNumber' },
                { validation: CustomValidator.checkMinusPlusFormat, validationMessage: 'Enter Correct Format', key: 'checkMinusPlusFormat' }
            ];
            const SuggestQtyDialog: SuggestQuantityPopUpModel = {
                PopupHeaderContent: DataInfo.PopupHeaderName,
                FormFields: DataInfo.FormFields,
                FormGroupData: DataInfo.FormGroupData,
                Classname: 'allocate-charges'
            };
            const dialogRef = dialog.open(AgilysysSuggestqtyPopupComponent, {
                width: '25%',
                height: 'auto',
                data: SuggestQtyDialog,
                autoFocus: false,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result[0] === 'Confirm') {
                    console.log('Confiem Allocate Charges', result[1]);
                    let total = 0;
                    let UseId = 1;
                    const ExchangeRate = homeGroup.value.ExchangeRate ? parseFloat(homeGroup.value.ExchangeRate.toString()) : 0;
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges.FormGroupData = result[1].value;
                    transactionService.transactionConfigurationData.CreateTranscationConfig
                        .ItemsConfig.ItemAllocateCharges.FormGroupData = result[1].value;
                    if (result[1].value.Use.toString() === 'Value') {
                        UseId = 2;
                        total = TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.reduce(function (prev, element) {
                                return prev + (element.Valued ? parseFloat(element.Valued) : 0);
                            }, 0);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.forEach(element => {
                                const percent = total !== 0 ? (element.Valued ? parseFloat(element.Valued) : 0)
                                    / parseFloat(total.toString()) : 0;
                                const val = percent * parseFloat(result[1].value.Value);
                                if (rguestTenantId) {
                                    element.Charges = val ? val.toFixed(4) : val;
                                } else {
                                    element.ChargesInFC = val ? val.toFixed(4) : val;
                                }
                            });
                    } else if (result[1].value.Use.toString() === 'Quantity') {
                        UseId = 3;
                        total = TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.reduce(function (prev, element) {
                                return prev + parseFloat(element.Qty ? element.Qty : 0);
                            }, 0);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.forEach(element => {
                                const percent = total !== 0 ? parseFloat(element.Qty ? element.Qty : 0)
                                    / parseFloat(total.toString()) : 0;
                                if (rguestTenantId) {
                                    element.Charges = (percent * parseFloat(result[1].value.Value))
                                        ? (percent * parseFloat(result[1].value.Value)).toFixed(4)
                                        : (percent * parseFloat(result[1].value.Value));
                                } else {
                                    element.ChargesInFC = (percent * parseFloat(result[1].value.Value))
                                        ? (percent * parseFloat(result[1].value.Value)).toFixed(4)
                                        : (percent * parseFloat(result[1].value.Value));
                                }
                            });
                    } else if (result[1].value.Use.toString() === 'UnitCost') {
                        UseId = 4;
                        total = TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.reduce(function (prev, element) {
                                return prev + parseFloat(element.UnitCost.toString());
                            }, 0);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.forEach(element => {
                                const percent = total !== 0 ? parseFloat(element.UnitCost) / parseFloat(total.toString()) : 0;
                                if (rguestTenantId) {
                                    element.Charges = (percent * parseFloat(result[1].value.Value))
                                        ? (percent * parseFloat(result[1].value.Value)).toFixed(4)
                                        : (percent * parseFloat(result[1].value.Value));
                                } else {
                                    element.ChargesInFC = (percent * parseFloat(result[1].value.Value))
                                        ? (percent * parseFloat(result[1].value.Value)).toFixed(4)
                                        : (percent * parseFloat(result[1].value.Value));
                                }
                            });
                    } else if (result[1].value.Use.toString() === 'Equal') {
                        UseId = 5;
                        TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.forEach(element => {
                                const percent = parseFloat(result[1].value.Value)
                                    / parseFloat(TransactionCollection.CreateTranscationConfig.ItemsConfig
                                        .ItemList.ItemGridConfig.GridData.length.toString());
                                if (rguestTenantId) {
                                    element.Charges = percent ? percent.toFixed(4) : percent;
                                } else {
                                    element.ChargesInFC = percent ? percent.toFixed(4) : percent;
                                }
                            });
                    } else if (result[1].value.Use.toString() === 'None') {
                        TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData.forEach(element => {
                                element.ChargesInFC = parseFloat('0').toFixed(4);
                                if (rguestTenantId) {
                                    element.Charges = parseFloat('0').toFixed(4);
                                } else {
                                    element.ChargesInFC = parseFloat('0').toFixed(4);
                                }
                            });
                    }
                    TransactionCollection.CreateTranscationConfig.ItemsConfig
                    .ItemList.ItemGridConfig.GridData.forEach(ele => {
                        if (rguestTenantId) {
                            const val = parseFloat(ele.Charges ? ele.Charges.toString() : '0') * ExchangeRate;
                            ele.ChargesInFC =  parseFloat(val ? val.toString() : '0').toFixed(4);
                        } else {
                            const val2 = parseFloat(ele.ChargesInFC ? ele.ChargesInFC.toString() : '0') / ExchangeRate;
                            ele.Charges =  parseFloat(val2 ? val2.toString() : '0').toFixed(4);
                        }
                    });                    
                    TransactionCollection.CreateTranscationConfig.ItemsConfig
                        .ItemList.ItemGridConfig.GridData.forEach(element => {
                            element.ReceivingItemsOtherTotals = element.ReceivingItemsOtherTotals &&
                                element.ReceivingItemsOtherTotals.length > 0 ? element.ReceivingItemsOtherTotals : [];
                            if (element.ReceivingItemsOtherTotals.length === 0) {
                                if (rguestTenantId) {
                                    element.ReceivingItemsOtherTotals.push({
                                        'Id': 0,
                                        'CategoryId': 3,
                                        'CategoryDetailId': UseId,
                                        'Amount': parseFloat(element.Charges),
                                        'AmountInFC': ExchangeRate > 0 && parseFloat(element.Charges) > 0 ?
                                        Number((parseFloat(element.Charges) * ExchangeRate).toFixed(4)) : 0
                                    });
                                } else {
                                    element.ReceivingItemsOtherTotals.push({
                                        'Id': 0,
                                        'CategoryId': 3,
                                        'CategoryDetailId': UseId,
                                        'Amount': ExchangeRate > 0 && parseFloat(element.ChargesInFC) > 0 ?
                                            Number((parseFloat(element.ChargesInFC) / ExchangeRate).toFixed(4)) : 0,
                                        'AmountInFC': parseFloat(element.ChargesInFC)
                                    });
                                }
                            } else if (element.ReceivingItemsOtherTotals.length > 0) {
                                const AllocCharges = element.ReceivingItemsOtherTotals.findIndex(x => x.CategoryId === 3);
                                if (AllocCharges === -1) {
                                    if (rguestTenantId) {
                                        element.ReceivingItemsOtherTotals.push({
                                            'Id': 0,
                                            'CategoryId': 3,
                                            'CategoryDetailId': UseId,
                                            'Amount': parseFloat(element.Charges),
                                            'AmountInFC': ExchangeRate > 0 && parseFloat(element.Charges) > 0 ?
                                            Number((parseFloat(element.Charges) * ExchangeRate).toFixed(4)) : 0
                                        });
                                    } else {
                                        element.ReceivingItemsOtherTotals.push({
                                            'Id': 0,
                                            'CategoryId': 3,
                                            'CategoryDetailId': UseId,
                                            'Amount': ExchangeRate > 0 && parseFloat(element.ChargesInFC) > 0 ?
                                                Number((parseFloat(element.ChargesInFC) / ExchangeRate).toFixed(4)) : 0,
                                            'AmountInFC': parseFloat(element.ChargesInFC)
                                        });
                                    }
                                } else {
                                    if (rguestTenantId) { 
                                        element.ReceivingItemsOtherTotals[AllocCharges].CategoryDetailId = UseId;
                                        element.ReceivingItemsOtherTotals[AllocCharges].Amount = parseFloat(element.Charges);                                        
                                        element.ReceivingItemsOtherTotals[AllocCharges].AmountInFC = ExchangeRate > 0 && parseFloat(element.Charges) > 0 ?
                                        Number((parseFloat(element.Charges) * ExchangeRate).toFixed(4)) : 0;
                                    } else {
                                        element.ReceivingItemsOtherTotals[AllocCharges].CategoryDetailId = UseId;
                                        element.ReceivingItemsOtherTotals[AllocCharges].Amount = ExchangeRate > 0 && parseFloat(element.ChargesInFC) > 0 ?
                                            Number((parseFloat(element.ChargesInFC) / ExchangeRate).toFixed(4)) : 0;
                                        element.ReceivingItemsOtherTotals[AllocCharges].AmountInFC = parseFloat(element.ChargesInFC);
                                    }
                                }
                            }

                        });

                    // const allocated = TransactionCollection.CreateTranscationConfig.ItemsConfig
                    //     .ItemList.ItemGridConfig.GridData.reduce(function (prev, element) {
                    //         return prev + parseFloat(element.Charges);
                    //     }, 0);
                    const totalamount = transactionService.calculateReceivingTotal(TransactionCollection.CreateTranscationConfig
                        .ItemsConfig.ItemList.ItemGridConfig.GridData,
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData,
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges.FormGroupData,
                        selectedTransaction);
                    const routerParams = JSON.parse(decodeURIComponent(router.snapshot.queryParams.data));
                    const receivingTaxCalcamount = ConversionAlgorithm.receivingTaxAlgoithm(parseFloat(totalamount), TransactionCollection.CreateTranscationConfig
                        .ItemsConfig.ItemList.ItemGridConfig.GridData,
                        SystemPreferenceService, selectedTransaction, selectedId, routerParams['copyasnew'] );
                    TransactionCollection.CreateTranscationConfig.DetailsConfig
                        .DetailsFormModel.DetailFormHeader[1].Value = Formater('Currency', receivingTaxCalcamount, null, null);;
                    TransactionCollection.CreateTranscationConfig.ItemsConfig
                        .ItemList.ItemGridConfig.GridData = [...TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig.GridData];
                    TransactionCollection.CreateTranscationConfig.ItemsConfig
                        .ItemList.ItemGridConfig = {
                        ...TransactionCollection.CreateTranscationConfig.ItemsConfig
                            .ItemList.ItemGridConfig
                    };
                }
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred AllocateChargesInfo', Error);
    }
};


function aasignPostObject(FormData: FormGroup, selectedId: any, MiscCharges: Array<any>) {
    try {
        const TransactionMiscCharges = [];
        for (const key in FormData.value) {
            if (FormData.value.hasOwnProperty(key)) {
                if (selectedId > 0) {
                    const index = MiscCharges.findIndex(x => x.MiscChargeId.toString() === key.toString());
                    if (index !== -1) {
                        MiscCharges[index].Amount = parseFloat(FormData.value[key]);
                        TransactionMiscCharges.push(MiscCharges[index]);
                    } else {
                        const MiscCharge = {
                            Id: 0,
                            MiscChargeId: parseInt(key, 10),
                            Amount: parseFloat(FormData.value[key])
                        };
                        TransactionMiscCharges.push(MiscCharge);
                    }
                } else {
                    const MiscCharge = {
                        Id: 0,
                        MiscChargeId: parseInt(key, 10),
                        Amount: parseFloat(FormData.value[key])
                    };
                    TransactionMiscCharges.push(MiscCharge);
                }
            }
        }
        return TransactionMiscCharges;
    } catch (error) {
        console.error('Error occurred in aasignPostObject', error);
    }
}
