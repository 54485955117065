import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { DiaryBusiness } from './diary.business'
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import {  Subject, ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { debounceTime, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
import { CalenderCount, UI } from '../Models/diary.model';
import { ButtonValue, FilterGroup, AsideFilterConfig,MultiImageUpload,TableSearchHeader,DropdownOptions } from '../Models/ag-models';
import { createModal } from '../components/create-modal/create-model';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
import { AlertType, ButtonType } from '../enums/shared-enums';
// import { ViewImageComponent } from 'src/app/common/shared/shared/view-image/view-image.component'


import { SEARCH_DEBOUNCE_TIME,SEARCH_TEXT_LENGTH } from '../shared/shared/setupConstants';
import { ViewImageComponent } from '../shared/shared/view-image/view-image.component';
import { AgPreviewReportComponent } from '../components/preview-report/preview-report.component';
import { ReportAPIOptions, ReportParams } from '../shared/retail.modals';
import { Localization } from '../localization/localization';
@Component({
  selector: 'app-diary',
  templateUrl: './diary.component.html',
  styleUrls: ['./diary.component.scss'],
  encapsulation:ViewEncapsulation.None,
  providers: [DiaryBusiness]

})
export class DiaryComponent implements OnInit {

  createModalData: createModal;
  captions: any;
  searchHeaderOption: TableSearchHeader;
  title: string;
  form: UntypedFormGroup;
  floatLabel: string;
  actionButton: ButtonValue;
    cancelButton: ButtonValue;
    showError: boolean = false;
  applyButton: ButtonValue;
  resetButton: ButtonValue;
  searchButton: ButtonValue;
  createButton: ButtonValue;
  alertsArr :any =[];
  // alertsOriginalArr :any =[];
  diaryEventList : UI.DiarySearchResult[]=[];
  showCreate : boolean = false;
  showRightSection : boolean = false;
  viewDiaryData: UI.DiarySearchResult;
  showEditAction: boolean = false;
  noResult: boolean = false;
  headerFilter : any =[];
  index: number = 0;
  isEdit : boolean = false;
  userName : string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  allTagData : UI.DiaryTag[] =[];
  allSearchTagData : UI.DiaryTag[] =[];
  searchTag : DropdownOptions[];
  selectedGuestTagKey: string[] = ['name'];
  autoCompleteKeys: string[] =  ['name'];
  searchGuestMaxLength = 50;
  tagSearchKey: string[] =  ['name'];
  searchselectedTagData: UI.DiaryTag[] = [];
  selectedTagData: UI.DiaryTag[] = [];
  tagArr : any[] =[];
  isSlideOpen: boolean = false;
  filtergroups: FilterGroup[] = [];
  filterConfig: AsideFilterConfig;
  @Output() onClose = new EventEmitter();
  imageList: MultiImageUpload[];
  tagSearchTextChanged = new Subject<string>();
  tagsearchSearchTextChanged = new Subject<string>();
  maxYear: number;
  minYear: number;
  maxMonth: number;
  minMonth: number;
  diaryAuditInfos : UI.DiaryAuditInfo[]= [];
  subjectMaxLength : number = 100;
  eventMaxLength : number = 2000;
  minFromDate : Date;
  maxFromDate : Date;
  minStartDate:Date;
  maxStartDate: Date
  minEndDate:Date;
  maxEndDate: Date
  selecteddiaryDate : any;
  dateCount : CalenderCount[]=[];
  @ViewChild('chipSearch') chipSearch: ElementRef;
  constructor(private cd: ChangeDetectorRef,private localization: Localization,private utilities: CommonUtilities,
     private business: DiaryBusiness,private fb: FormBuilder,private propertyInfo: CommonPropertyInformation,
     private dialog: MatDialog) { 
    this.captions = this.localization.captions;
    this.title = this.captions.lbl_Alerts;
    this.floatLabel=this.localization.setFloatLabel;
    this.userName = this.localization.GetUserInfo('userName');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id : [0],
      subject: [''],
      event: ['', Validators.required],
      tag: [''],
      startDate : [this.propertyInfo.CurrentDate],
      endDate : [this.propertyInfo.CurrentDate],
      searchTag: [''],
      diaryDate: [this.selecteddiaryDate]
    });
    const propertyDate = this.localization.getDate(this.propertyInfo.CurrentDate)
    
    const minDate = new Date(this.localization.getDate(this.propertyInfo.CurrentDate).setFullYear(propertyDate.getFullYear() - 20));
    const maxDate = new Date(this.localization.getDate(this.propertyInfo.CurrentDate).setFullYear(propertyDate.getFullYear() + 20));
    this.minFromDate = minDate;
    this.maxFromDate = maxDate
    this.minStartDate = minDate;
    this.minEndDate = minDate;
    this.maxEndDate = maxDate;
    this.maxStartDate = maxDate
    this.initialize();
    this.initializeButton();
    this.initializesearchHeaderOption();
  }

  private async LoadCalanderCount(Cdate : Date){
    let date = this.localization.getDate(Cdate); 
    let monthIndex = date.getMonth(); 
    const days = this.getDays(date.getFullYear(),monthIndex);
    this.dateCount = await this.business.GetDiaryCount(days[0] ,days[days.length-1]);
    this.dateCount = [...this.dateCount];
  }
  getDays(year, month){
    let daysArr =[];
    let days = new Date(year, month+1, 0).getDate();
    for(let i=1;i<=days;i++){
      let dateObj = new Date(year,month,i);
      daysArr.push(this.localization.convertDateObjToAPIdate(dateObj));
    }
    return daysArr;
  };
  initialize(){
    this.createModalData = {
      title: this.captions.lbl_diary
    };
    // this.allTagData = this.business.getTag();
    // this.searchTag = this.business.getSearchTag();
    // this.modified = this.business.getModifiedList();
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.actionButton.disabledproperty = !(this.form.valid && this.form.dirty);
      this.actionButton = { ...this.actionButton }
    });
    this.filterConfig = {
      filterText: this.captions.lbl_filter,
      resetText : this.captions.lbl_reset,
      // isCloseRequired:true,
      displayCount: 4,
      isMultiSelect: true,
      viewMoreConfig: {
        apply: this.captions.btn_apply,
        cancel: this.captions.btn_cancel,
        searchByPlaceHolder: this.captions.lbl_searchBy
      },
    };
    // let displayByOptions = this.business.getDisplayByOptions();
    // this.filtergroups = [
    //   {
    //     id: 1,
    //     name: 'status',
    //     title: this.captions.lbl_byTags,
    //     filters: displayByOptions,
    //     filtered: displayByOptions.filter(x => x.isSelected),
    //     isSingleSelect: false,
    //     expand: true
    //   }
    // ]

    this.tagSearchTextChanged.pipe(
      debounceTime(SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      mergeMap(source => this.searchtagChange(source))
    ).pipe(takeUntil(this.destroyed$)).subscribe();
    this.tagsearchSearchTextChanged.pipe(
      debounceTime(SEARCH_DEBOUNCE_TIME),
      distinctUntilChanged(),
      mergeMap(source => this.searchsearchtagChange(source))
    ).pipe(takeUntil(this.destroyed$)).subscribe();
    
  }

  initializeButton(){
    this.actionButton = {
      type: 'primary',
      label: this.isEdit ? this.captions.btn_SAVE : this.captions.btn_create,
      disabledproperty : true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.applyButton = {
      type: 'primary',
      label: this.captions.btn_apply
    },
    this.resetButton = {
      type: 'tertiary',
      label: this.captions.btn_reset,
      disabledproperty: false
    };
    this.searchButton = {
      type: 'primary',
      label: this.captions.btn_search
    }
    this.createButton = {
      type: 'primary',
      label: this.captions.btn_create
    }
    }

    onBlur(event: any)
    {

    }

    onGuestChipRemoveEvent(event: any)
    {
      //remove from list
     }
     onSearchGuestChipRemoveEvent(event: any)
     {
       //remove from list
      }

  initializesearchHeaderOption() {
    this.searchHeaderOption = {
      createBtnLabel: this.captions.btn_create,
      searchPlaceHolder: this.captions.lbl_searchByEvent,
      showInactive: false,
      hideActionBtn: false,
      advancedSearch: this.captions.lnk_advancedSearch
    };

  }

  async searchtagChange(pattern) {
    const tagSearchText = (pattern ? pattern : "").trim();
    if (tagSearchText.length > SEARCH_TEXT_LENGTH) {
      this.allTagData= await this.business.getTagBySearchParams(tagSearchText);      
    }
    else {
      this.allTagData = [];
    }
    this.cd.detectChanges();
  }
  async searchsearchtagChange(pattern) {
    const tagSearchText = (pattern ? pattern : "").trim();
    if (tagSearchText.length > SEARCH_TEXT_LENGTH) {
      this.allSearchTagData= await this.business.getTagBySearchParams(tagSearchText);      
    }
    else {
      this.allSearchTagData = [];
    }
    this.cd.detectChanges();
  }
  onSubjectChange(e){

  }
 async onAction(e){
    if(this.isEdit) {
      const diaryvalues = this.form.getRawValue();
      const tags = this.selectedTagData.map(x=>x.name);
      const date = this.localization.convertDateObjToAPIdate(diaryvalues.diaryDate);
      const propDateTime = this.localization.convertDateTimeToAPIDateTime(this.propertyInfo.CurrentDate)
      this.utilities.ToggleLoader(true);
      const issucess = await this.business.UpdateDiaryEvent(diaryvalues.id,diaryvalues.subject,diaryvalues.event,date,propDateTime,tags,this.imageList,this.viewDiaryData.diaryInfo.diaryDocuments);
      this.utilities.ToggleLoader(false);
      if(issucess){
        this.utilities.showAlert(this.captions.msg_savedSuccesfully, AlertType.Success, ButtonType.Ok);
        await this.refreshList();
      }


    } else {
      const diaryvalues = this.form.getRawValue();
      const tags = this.selectedTagData.map(x=>x.name);
      const date = this.localization.convertDateObjToAPIdate(diaryvalues.diaryDate);
      const propDateTime = this.localization.convertDateTimeToAPIDateTime(this.propertyInfo.CurrentDate)
      this.utilities.ToggleLoader(true);
      const issucess = await this.business.CreateDiaryEvent(diaryvalues.id,diaryvalues.subject,diaryvalues.event,date,propDateTime,tags,this.imageList);
      this.utilities.ToggleLoader(false);
      if(issucess){
        this.utilities.showAlert(this.captions.msg_savedSuccesfully, AlertType.Success, ButtonType.Ok);
        await this.refreshList();
      }
    }
    this.imageList = [];
    this.showCreate = false;
    this.noResult = true;
    this.tagArr = [];
    this.resetCreateForm()
  }

  async refreshList(){
    this.onSearch(null);
  }
  onCancel(e){
    this.imageList = [];
    this.cd.detectChanges();
    this.resetCreateForm();
   
  }

  async selectedTagEmit(event) {
    this.selectedTagData = event.map(x => { return { id: x.id, name: x.name } as UI.DiaryTag });
    this.actionButton.disabledproperty = !this.form.valid;
    this.actionButton = { ...this.actionButton }
    // this.allTagData = event.map(x=>{return {id:x.id,name:x.name} as UI.DiaryTag});

    // this.form.get('tag').setValue(this.selectedGuestInfo);

  }
  async searchSelectedTagEmit(event) {
  this.searchselectedTagData = event.map(x=>{return {id:x.id,name:x.name} as UI.DiaryTag});
  // this.allSearchTagData = event.map(x=>{return {id:x.id,name:x.name} as UI.DiaryTag});
    }
  
  getTypeClass(key){
    return key;
  }
  createEvent(e){
    this.imageList = [];
    this.showRightSection = true;
    this.showCreate = true;
    this.noResult = false;
    this.showEditAction = false;
    this.isEdit = false;
    // this.form.reset();
    this.resetCreateForm();
    this.selectedTagData = [];
    this.cd.detectChanges();
  }
  viewData(data,i){
    this.showRightSection = true;
    this.showEditAction = true;
    this.noResult = false;
    this.viewDiaryData = data;
    this.diaryAuditInfos = this.viewDiaryData.diaryAuditInfos;
    this.showCreate = false;
    this.index = i;
    this.cd.detectChanges();
  }
  onDelete(event,value){
    event.stopPropagation();
  }
  async openEdit(){
    this.showEditAction = false;
    this.showCreate = true;
    this.isEdit = true;
    this.form.controls['id'].patchValue(this.viewDiaryData.diaryInfo.id);
    this.form.controls['subject'].patchValue(this.viewDiaryData.diaryInfo.subject);
    this.form.controls['event'].setValue(this.viewDiaryData.diaryInfo.notes);
    this.form.controls['diaryDate'].setValue(this.viewDiaryData.diaryInfo.date);
    this.selectedTagData = this.viewDiaryData.diaryInfo.diaryTagMappings.map(x=>{return {id:0,name:x.tagName} as UI.DiaryTag});
    if(this.viewDiaryData.diaryInfo.diaryDocuments.length > 0){
      this.utilities.ToggleLoader(true);
      const docresponse =  await this.business.GetAllImages(this.viewDiaryData.diaryInfo.diaryDocuments);
      this.utilities.ToggleLoader(false);
      this.imageList =  this.business.DocumentUItoAPIMapper(docresponse,this.viewDiaryData.diaryInfo.diaryDocuments);
    }
    this.initializeButton();
    this.cd.detectChanges();
  }
  tagSearchTextEmit(pattern){
    this.tagSearchTextChanged.next(pattern);
  }
  tagsearchSearchTextEmit(pattern){
    this.tagsearchSearchTextChanged.next(pattern);
  }
  showFilter(value){
    this.isSlideOpen = value;
  }
  back(){
    this.onClose.emit(false);
  }

  CategoryFilter(e){
    this.headerFilter = e.filters.filter(x => x.isSelected);
  }
  imgAdd(arg) {
    this.imageList = arg;
    this.actionButton.disabledproperty = !this.form.valid;
    this.actionButton = { ...this.actionButton }
  }
  imgDelete(arg) {
    this.imageList = arg;
    this.actionButton.disabledproperty = !this.form.valid;
    this.actionButton = { ...this.actionButton }
  }
  async viewAllImages() {
    this.utilities.ToggleLoader(true);
  const docresponse =  await this.business.GetAllImages(this.viewDiaryData.diaryInfo.diaryDocuments);
  this.utilities.ToggleLoader(false);
  this.imageList =  this.business.DocumentUItoAPIMapper(docresponse,this.viewDiaryData.diaryInfo.diaryDocuments);
    this.dialog.open(ViewImageComponent, {
      data: {
        imageList: this.imageList
      }
    });
  }

  fileSizeExceeded(arg) {
    this.utilities.showAlert(this.captions.PhotoSizeExceed, AlertType.Info, ButtonType.Ok);
  }
  onSave(e){ //filter save button
    // let arr=[];
    // for(let x of this.headerFilter){
    //   if(x.name=="All"){
    //     this.alertsArr = this.alertsOriginalArr;
    //     break;
    //   } else {
    //     arr.push(this.alertsOriginalArr.filter(m => m.subject == x.name));
    //     this.alertsArr = arr;
    //   }
    // }
  }
  startdateChange(eve){
    this.minEndDate = this.localization.getDate(this.form.controls['startDate'].value);
  }
  enddateChange(eve){
    this.maxStartDate = this.localization.getDate(this.form.controls['endDate'].value);
  }
    getDate(eve){
    this.selecteddiaryDate = eve;
    this.form.controls['startDate'].setValue(this.localization.getDate(this.selecteddiaryDate));
    this.form.controls['endDate'].setValue(this.localization.getDate(this.selecteddiaryDate));
    this.maxStartDate = this.localization.getDate(this.form.controls['endDate'].value);
    this.minEndDate = this.localization.getDate(this.form.controls['startDate'].value);
    this.resetCreateForm();
    this.showRightSection = false;
    this.onSearch(null);
  }
  async onSearch(eve){
    this.LoadCalanderCount(this.localization.getDate(this.form.controls['startDate'].value));
    const startDate = this.localization.convertDateObjToAPIdate(this.form.controls['startDate'].value);
    const endDate = this.localization.convertDateObjToAPIdate(this.form.controls['endDate'].value);
    const tags = this.searchselectedTagData.map(x=>x.name);
    this.diaryEventList = await  this.business.SearchDiary(startDate,endDate,tags);
    this.cd.detectChanges();
  }
  print(){
this.previewComponent(this.formReportOption());
  }
  formReportOption() {
    const formFilters = JSON.stringify({
      startDate: this.localization.convertDateObjToAPIdate(this.form.controls['startDate'].value),
      endDate: this.localization.convertDateObjToAPIdate(this.form.controls['endDate'].value),
      tags: this.searchselectedTagData.map(x=>x.name),
    });

    const reportOptions: ReportAPIOptions = {
      code: 'Diary',
      params: this.formReportParams(),                                                                                                                                                                                    
      URIParams: [],
      filters: formFilters,
      pageBreak: true
    };
    return reportOptions;
  }
  formReportParams(): ReportParams[] {
    return [
      { pPropertyName: this.localization.GetPropertyInfo('PropertyName') },
      { pReportName: 'Diary' },
      { pStartDate: this.localization.convertDateObjToAPIdate(this.form.controls['startDate'].value)},
      { pEndDate: this.localization.convertDateObjToAPIdate(this.form.controls['endDate'].value)},
      { pDate: this.localization.ConvertDateToISODateTime(new Date()) }
    ];

  }
  previewComponent(reportOptions) {
    const dialogRef = this.dialog.open(AgPreviewReportComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: {
        reportOptions,
        downloadFileName: this.captions.lbl_diary,
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result) {
        // No Implementation
      }
    });
  }
  resetCreateForm(){
    this.form.controls['id'].setValue(0);
    this.form.controls['subject'].setValue('');
    this.form.controls['event'].setValue('');
    this.form.controls['diaryDate'].setValue(this.selecteddiaryDate);
    this.form.markAsUntouched();
    this.imageList = [];
    this.showRightSection = true;
    this.isEdit = false;
    this.showCreate = true;
    this.noResult = false;
    this.showEditAction = false;
    this.selectedTagData = [];
    this.cd.detectChanges();


    
  }

}


@Pipe({
  name: 'DisplayDateTime',
})
export class DisplayDateTime implements PipeTransform {
  constructor(private localization: Localization) { }
  transform(value: any, formdata: any) {
    if (typeof value == 'string') { value = this.localization.getDate(value); }
    let str = this.localization.localizeDisplayDate(value) + ' ';
    let time ='';
    if (this.localization.getTimeFormat() == 12) 
      time = this.localization.formatAMPM(value, true);
  else 
    time = this.localization.format24HrTime(value);
    return str + time;
  }
  }

