import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints } from '../shared/constants/useraccess.constants';
import { Localization } from 'src/app/common/localization/localization';
import { LetterTableContent, LetterTemplateType } from './letter.model';
import {API,UI} from './template.model'
import { Tags, TemplateTags } from '../shared/shared.modal';
import { TemplateDataService } from '../shared/service/data- services/template.data.service';
import { RetailLocalization } from '../common/localization/retail-localization';
@Injectable()
export class LetterSetupBusiness {
  captions: any;
  isViewOnly: boolean;
  isAllow:boolean;
  constructor(
    private localization: Localization, private templateDataService: TemplateDataService,
     private _userAccessBusiness: UserAccessBusiness,private retailLocalization : RetailLocalization
  ) {
    this.captions = this.localization.captions.retailsetup;
  }

  public async getTemplateTags(type: number): Promise<TemplateTags[]> {
    let apiModel: Tags[] = await this.templateDataService.getLetterSetupTemplateTags(type);
    return this.templateTagsMapper(apiModel);
  }

  private templateTagsMapper(ApiTags: Tags[]): TemplateTags[] {
    const templateTags = new Array<TemplateTags>();

    //Uncomment below to use if Tags without grouping is needed
    // templateTags.push(
    //   {
    //     title: '',
    //     tags: ApiTags,
    //     showGroupTitle: false
    //   });


    const tags = groupBy(ApiTags, 'categoryCode');
    const keys = Object.keys(tags);
    for (const key of keys) {
      const category = ApiTags.find(tag => tag.categoryCode === key);
      templateTags.push(
        {
          title: category ? category.categoryName : '',
          tags: tags[key],
          showGroupTitle: true,
          titleCode: category ? category.categoryCode : ''
        });
    }
    return templateTags;
  }

  async deleteTemplate(id: number) {
    const apiModels: boolean = await this.templateDataService.deleteTemplate(id);
    return apiModels;
  }

  async getEditData(type: number, id: number) {
    const apiModel: API.Template = await this.templateDataService.getTemplateById(type, id);
    return this.uiEditMapper(apiModel);

    // const sampleData = btoa('<p><strong>Hi </strong><i><strong>Hello</strong></i><strong> <u>How are you</u></strong></p>');
    // return {
    //   code: event.Obj.code,
    //   name: event.Obj.name,
    //   listOrder: event.Obj.listOrder,
    //   active: event.Obj.active.value,
    //   templateData: atob(sampleData)
    // };
  }

  async getCopyData(type: number, id: number) {
    const apiModel: API.Template =await this.templateDataService.getTemplateById(type, id, true);
    return this.uiCopyMapper(apiModel);
  }

  public async create(letter: UI.Template): Promise<boolean> {
    const apiModels: boolean =  await this.templateDataService.createTemplate(this.APIMapper(letter));
    return apiModels;
  }

  public async update(letter: UI.Template): Promise<boolean> {
    const apiModels: boolean = await this.templateDataService.updateTemplate(this.APIMapper(letter));
    return apiModels;
  }
  public async updateListOrder(id: number, listOrder: number): Promise<boolean> {
    const apiModels: boolean = await this.templateDataService.UpdateListOrder(this.APIListOrderMapper(id, listOrder));
    return apiModels;
  }

  public async getNextListOrder(type: number): Promise<number> {
    const listOrder = await this.templateDataService.getNextListOrder(type);
    return listOrder;
  }

  public async getTemplates(type: number, includeInactive: boolean): Promise<LetterTableContent[]> {
    let apiModels: API.Template[] = await this.templateDataService.getAllTemplatesByType(type, includeInactive);
    return apiModels.map(x => this.uiMapper(x))
  }

  private uiEditMapper(template: API.Template): UI.Template {
    return {
      code: template.code,
      type: template.type,
      name: template.name,
      templateData: atob(template.value),
      active: template.isActive,
      listOrder: template.listOrder,
      id: template.id,
      value: template.value,
      default: template.isDefault
    } as UI.Template;
  }

  private uiCopyMapper(template: API.Template): UI.Template {
    return {
      code: '',
      type: template.type,
      name: template.name,
      templateData: atob(template.value),
      active: template.isActive,
      listOrder: template.listOrder,
      id: null,
      default: template.isDefault
    } as UI.Template;
  }


  private uiMapper(template: API.Template): LetterTableContent {
    return {
      id: template.id,
      code: template.code,
      name: template.name,
      type : template.type,
      listOrder: template.listOrder,
      active:  template.isActive,
      isInActive: template.isDefault,
      templateData: atob(template.value),
      value: template.value
    } as LetterTableContent;
  }

  private APIMapper(letterSetup: UI.Template): API.Template {
    return {
      // Implement property mapping
      code: letterSetup.code,
      type: letterSetup.type ? letterSetup.type : 1,
      name: letterSetup.name,
       value: btoa(this.retailLocalization.curlyQuotesfix(letterSetup.templateData)),
      isActive: letterSetup.active,
      listOrder: letterSetup.listOrder,
      id: letterSetup.id ? letterSetup.id : 0,
      isDefault: letterSetup.default


    } as API.Template;
  }

  private APIListOrderMapper(id: number, listorder: number): API.ListOrderTemplate {
    return {
      id: id ? id : 0,
      listOrder: listorder,

    } as API.ListOrderTemplate;
  }

  getBreakpointNumber(type) {
    switch (type) {
      case LetterTemplateType.ClubAgreement:
        return UserAccessBreakPoints.CLUBAGREEMENT;
      case LetterTemplateType.CartAgreement:
        return UserAccessBreakPoints.CARTAGREEMENT;
      case LetterTemplateType.ShoeAgreement:
        return UserAccessBreakPoints.SHOEAGREEMENT;
        case LetterTemplateType.RetailSaleChit:
          return UserAccessBreakPoints.RETAILSALECHIT;
    }
  }
}
