

/* Extend Proto Type of Date */
interface Date {
    getDSTOffsetDifference(): number;
    getDSTTimezoneOffset(): number;
    isDSTTimezone(): boolean;
    addDays(days: number): Date;
}
 

(function () {
    /* Get the DST Time zone offset difference */
    Date.prototype.getDSTOffsetDifference = function () {
        var NonDSTdate = new Date(this.getFullYear(), 0, 1);
        var DSTdate = new Date(this.getFullYear(), 6, 1);
        return NonDSTdate.getTimezoneOffset() - DSTdate.getTimezoneOffset();
    }

    /* Get the DST Time zone offset of the current timezone  */
    Date.prototype.getDSTTimezoneOffset = function () {
        var NonDSTdate = new Date(this.getFullYear(), 0, 1);
        var DSTdate = new Date(this.getFullYear(), 6, 1);
        return Math.min(NonDSTdate.getTimezoneOffset(), DSTdate.getTimezoneOffset());
    }

    /* Returns true if DST (Daylight Saving Time) is Observed in given time. */
    Date.prototype.isDSTTimezone = function () {
        return this.getDSTTimezoneOffset() < this.getTimezoneOffset();
    }

    Date.prototype.addDays = function (days: number) {
        var date: Date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

})();
