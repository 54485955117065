import { I } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { CartFolioAuthorizationsUI } from '../vcart/vcart.modal';

@Component({
  selector: 'app-card-type',
  templateUrl: './card-type.component.html',
  styleUrls: ['./card-type.component.scss']
})
export class CardTypeComponent implements OnInit {
  buttonObj: { customSaveText: string; disabled: boolean; isEdit: boolean; };
  cartFolioAuthorizations: CartFolioAuthorizationsUI[];
  activeCard: CartFolioAuthorizationsUI;
  confirmationID: string;
  captions: any;
  previousActiveCard: CartFolioAuthorizationsUI;
  constructor(public dialog: MatDialog, private localization: Localization,
    private dialogRef: MatDialogRef<CardTypeComponent>, @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.previousActiveCard = data?.obj.cartFolioAuthorizations.find(o => o.isActive);
    this.cartFolioAuthorizations = data?.obj.cartFolioAuthorizations;
    this.cartFolioAuthorizations.forEach(x=> x.cardCode = x.cardCode.toLocaleLowerCase());
    this.captions = this.localization.captions.common;
    this.confirmationID = this.captions.lbl_chooseCard + " - " + data?.obj?.confirmationId
  }

  ngOnInit(): void {
    this.buttonObj = {
      customSaveText: this.captions.btn_save,
      disabled: true,
      isEdit: false
    };
    
  }

  close() {
    this.dialogRef.close()
  }

  save(e) {
    this.dialogRef.close(this.activeCard.id != this.previousActiveCard.id ? this.activeCard : null)
  }

  radioChange(x) {
    this.buttonObj.disabled = false;
    this.buttonObj = { ...this.buttonObj }
    this.activeCard = x;
  }

}
