import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { Host, PromptType, ButtonOptions } from '../../../shared/globalsContant';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { UserAlerts } from '../../../core/config/alerts-config';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-package-popup',
  templateUrl: './package-popup.component.html',
  styleUrls: ['./package-popup.component.scss']
})
export class PackagePopupComponent implements OnInit {
  packageData: UntypedFormGroup;
  packageCodeDetails: any;
  packageClassDetails: any;
  defaultCode: any;
  btnName: string;
  minFromDate: Date;
  minToDate: Date;
  captions;
  sold: number;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel:string;
  isEditPackage:boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private ss: SettingsService,
    public dialogRef: MatDialogRef<PackagePopupComponent>,
    public formBuilder: UntypedFormBuilder,
    private http: HttpServiceCall,
    public localization: SpaLocalization,
    private util: SpaUtilities,
    private userAlert: UserAlerts,
    private PropertyInfo: SpaPropertyInformation) {
    this.packageData = this.formBuilder.group({
      defaultStartDate: [data.defaultDate.fromDate],
      defaultEndDate: [data.defaultDate.toDate],
      packageCode: ['', Validators.required],
      packageClass: [''],
      available: ['', Validators.required]
    });
    this.minFromDate = this.minToDate = this.PropertyInfo.CurrentDate;
    this.captions = localization.captions.setting;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.commonCaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.btnName = this.data.action.toLowerCase() == 'new' ? this.localization.captions.common.SAVE : this.localization.captions.common.UPDATE;
    this.makeGetCallsByDesc('GetAllSpaPackage');
    this.makeGetCallsByDesc('GetSpaPackageClass');
    if (this.data.action.toLowerCase() == 'edit') {
      const packageArray = this.data.packageYieldArray[0];
      this.packageData.controls['packageCode'].setValue(packageArray.packageId);
      this.packageData.controls['packageClass'].setValue(packageArray.packageClassId);
      this.packageData.controls['available'].setValue(packageArray.available);
      this.sold = Number(packageArray.sold);
      this.isEditPackage = true;
    }
  }

  fromDateChange($event) {
    let todate: Date;
    todate = this.packageData.controls["defaultEndDate"].value;
    if (this.util.getDate(todate) < this.util.getDate($event.value)) {
      this.packageData.controls["defaultEndDate"].setValue($event.value);
    }
    this.minToDate = this.util.getDate($event.value);
  }

  onNoClick(): void {
    if (this.packageData.dirty) {
      this.userAlert.showPrompt(PromptType.UnsavedChanges, this.onNoClickCallBack.bind(this));
    } else {
      this.dialogRef.close();
    }
  }
  onNoClickCallBack(resultFromPopup: string) {
    if (resultFromPopup.toLocaleLowerCase() === ButtonOptions.Yes) {
      this.dialogRef.close();
    }
  }

  makeGetCallsByDesc(callDesc: string) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      callDesc: callDesc,
      method: HttpMethod.Get,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      showError: true,
      extraParams: []
    });
  }

  save = () => {
    const startDate = this.packageData.controls['defaultStartDate'].value;
    const endDate = this.packageData.controls['defaultEndDate'].value;
    const packageId = this.packageData.controls['packageCode'].value;
    const packageClass = this.packageData.controls['packageClass'].value ? this.packageData.controls['packageClass'].value : 0;
    const available = this.packageData.controls['available'].value;
    if (this.data.action.toLowerCase() == 'edit') {
      const yieldArray: any = [{
        id: this.data.packageYieldArray[0].id,
        yieldDate: this.util.formatDate(startDate),
        packageId: packageId,
        available: available,
        sold: this.sold,
        remaining: (Number(available) - this.sold) < 0 ? 0 : Number(available) - this.sold,
        packageClass: packageClass
      }];
      this.http.CallApiWithCallback<any>({
        host: Host.spaManagement,
        callDesc: 'UpdatePackageYield',
        method: HttpMethod.Put,
        showError: true,
        body: yieldArray,
        success: this.successCallback.bind(this),
        error: this.errorCallback,
        extraParams: []
      });

    } else if (this.data.action.toLowerCase() == 'new') {
      const dayDiff = this.ss.DateDiff.inDays(startDate, endDate) + 1;
      const packageYieds: any = [];
      for (let i = 0; i < dayDiff; i++) {
        packageYieds.push({ packageId: packageId, yieldDate: this.util.formatDate(this.util.AddDays(startDate, i)), available: available, sold: 0, remaining: available, packageClass: packageClass })
      }
      this.http.CallApiWithCallback<any>({
        host: Host.spaManagement,
        callDesc: 'CreatePackageYield',
        method: HttpMethod.Post,
        showError: true,
        body: packageYieds,
        success: this.successCallback.bind(this),
        error: this.errorCallback,
        extraParams: []
      });
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
    if (callDesc == "GetSpaPackageClass") {
      const results = <any>result.result;
      this.packageClassDetails = results.map(x => ({ id: x.id, value: x.className }));
    } else if (callDesc == "GetAllSpaPackage") {
      const results = <any>result.result;
      this.packageCodeDetails = results.map(x => ({ id: x.id, packcode: x.code }));
    } else if (['CreatePackageYield', 'UpdatePackageYield'].includes(callDesc)) {
      if (result.result) {
        this.dialogRef.close();
      }
    }
  }

  errorCallback(): void {
    console.error('error');
  }
}
