

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-service-group-cascading',
  templateUrl: './service-group-cascading.component.html',
  styleUrls: ['./service-group-cascading.component.scss']
})
export class ServiceGroupCascadingComponent implements OnInit {
  serviceGroupForm: UntypedFormGroup;
  @Input() dropdown;
  @Output() EmitData = new EventEmitter();
  filterDropdown: any[] = [];
  floatLabel:string;

  constructor(private fb: UntypedFormBuilder,public localization: SpaLocalization) {
    this.floatLabel = this.localization.setFloatLabel;
    this.serviceGroupForm = this.fb.group({
      serviceItem: this.fb.array([]),
    });
  }
  ngOnInit(): void {
    this.filterDropdown = this.dropdown;
    this.addItem();
  }

  newItem(): UntypedFormArray {
    return this.serviceGroupForm.get("serviceItem") as UntypedFormArray
  }

  newService(): UntypedFormGroup {
    return this.fb.group({
      serviceCode: '',
      availableQty: '',
      requireQty: ''
    })
  }

  addItem() {
    this.newItem().push(this.newService());
    this.EmitData.emit(this.serviceGroupForm.value);
  }

  removeItem(index: number) {
    this.newItem().removeAt(index);
  }

  selectionChange(value: any, index: number, v: any) {
    let availQtyData = this.dropdown.find(f => f.id == value.value);
    this.newItem().at(index).get('availableQty').setValue(availQtyData.quantity);
    // this.EmitData.emit(this.serviceGroupForm.value);
  }

  updateInput(value: any) {
    // this.EmitData.emit(this.serviceGroupForm.value);
  }

  getControlValue(type: string, index: number) {
    return this.newItem().at(index).get(type).value;
  }

}