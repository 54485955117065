import { Pipe, PipeTransform } from '@angular/core';
import { Query } from 'src/app/core/utilities/query.utility';

@Pipe({
    name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {

    /**
     * Filters Data(array) based on the query's applied using Query<T>
     * Returns All if no expression supplied.
     * @param {any[]} value
     * @param {Query<any>} expression
     * @returns {*}
     * @memberof DataFilterPipe
     */
    transform<T>(value: T[], expression: Query<T>): any {

        if (expression) {
            return expression.apply(value);
        }
        return value;

    }
}
