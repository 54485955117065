import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { SpaUtilities } from '../../../../shared/utilities/spa-utilities';
import { StaffScheduleService } from '../../staff-schedule.service';

@Component({
  selector: 'app-therapist-break-time',
  templateUrl: './therapist-break-time.component.html',
  styleUrls: ['./therapist-break-time.component.scss']
})
export class TherapistBreakTimeComponent implements OnChanges {
  captions: any;
  @Input() selectedDayData;
  @Input() breakTimeCount;
  showBreakTime: boolean;
  timeFormat: number;
  blankDateTime: any = "HH:mm";
  breakTimeFormGrp: UntypedFormGroup;
  @Output() breakTimeEmit = new EventEmitter();
  floatLabel:string;
  removedBreakTimeId: UntypedFormControl = new FormControl(0);
  constructor(private localization: SpaLocalization,
    public _ss: StaffScheduleService,
    private utils: SpaUtilities,
    private propertyInfo: SpaPropertyInformation) {
    this.timeFormat = this.localization.getTimeFormat();
    this.captions = this.localization.captions.staffSchedule;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnChanges() {
    this.breakTimeFormGrp = this.selectedDayData && _.cloneDeep(this.selectedDayData.recurringData);
    this.breakTimeFormGrp && this.breakTimeFormGrp.markAsUntouched();
  }

  closeSlideInfo() {
    document.getElementsByClassName('goldtheme')[0].classList.remove('slideInfoDisplayed');
    this._ss.showBreakTime = false;
    this.selectedDayData = null;
  }

  openPicker(event) {
    document.getElementById("staff-creation-section").classList.add("transform3dnone");
  }

  isClosed(event, index) {
    document.getElementById("staff-creation-section").classList.remove("transform3dnone");
    this.validateAll();
  }

  validateAll() {
    const breakstartTime = this.breakTimeFormGrp.controls.WorkBreakTime['controls'][this.selectedDayData.selectedIndex] as UntypedFormArray;
    for (let i = 0; i < breakstartTime.length; i++) {
      if (!this.validate(i)) {
        return false;
      }
    }
  }

  validate(index) {
    const selectedBreakTimeData = this.breakTimeFormGrp.controls.WorkBreakTime['controls'][this.selectedDayData.selectedIndex].controls;
    const selectedBreakEndTimeData = this.breakTimeFormGrp.controls.WorkBreakEndTime['controls'][this.selectedDayData.selectedIndex].controls;
    const CurrentDate = this.utils.formatDate(this.propertyInfo.CurrentDate);
    const breakEndTime = selectedBreakEndTimeData[index].value.BreakEndTime;
    const breakStartTime = selectedBreakTimeData[index].value.BreakStartTime;
    selectedBreakTimeData[index].controls.BreakStartTime.setErrors(null);
    selectedBreakEndTimeData[index].controls.BreakEndTime.setErrors(null);
    let startTime = this.utils.getDate(`${CurrentDate} ${breakStartTime}`);
    let endTime = this.utils.getDate(`${CurrentDate} ${breakEndTime}`);
    if (!breakStartTime) {
      selectedBreakTimeData[index].controls.BreakStartTime.setErrors({ incorrect: true });
      selectedBreakTimeData[index].controls.BreakStartTime.markAsDirty();
      selectedBreakTimeData[index].controls.BreakStartTime.markAsTouched();
      return false;
    }
    if (!breakEndTime || startTime >= endTime) {
      selectedBreakEndTimeData[index].controls.BreakEndTime.setErrors({ incorrect: true });
      selectedBreakEndTimeData[index].controls.BreakEndTime.markAsDirty();
      selectedBreakEndTimeData[index].controls.BreakEndTime.markAsTouched();
      return false;
    }
    for (let i = index + 1; i < selectedBreakTimeData.length; i++) {
      if (breakStartTime) {
        let curStartTime = selectedBreakTimeData[i].value.BreakStartTime;
        let curEndTime = selectedBreakEndTimeData[i].value.BreakEndTime;
        let selectedStartTime = this.utils.getDate(`${CurrentDate} ${curStartTime}`);
        let selectedEndTime = this.utils.getDate(`${CurrentDate} ${curEndTime}`);
        selectedBreakTimeData[i].controls.BreakStartTime.setErrors(null);
        selectedBreakEndTimeData[i].controls.BreakEndTime.setErrors(null);
        if (!curStartTime || (selectedStartTime.getTime() !== startTime.getTime() && !(selectedStartTime.getTime() <= startTime.getTime() || endTime.getTime() <= selectedStartTime.getTime())) || selectedStartTime.getTime() == startTime.getTime()) {
          selectedBreakTimeData[i].controls.BreakStartTime.setErrors({ incorrect: true });
          selectedBreakTimeData[i].controls.BreakStartTime.markAsDirty();
          selectedBreakTimeData[i].controls.BreakStartTime.markAsTouched();
          return false;
        }
        if (!curEndTime || (selectedEndTime.getTime() !== endTime.getTime() && !(selectedEndTime.getTime() <= startTime.getTime() || endTime.getTime() <= selectedStartTime.getTime())) || selectedStartTime >= selectedEndTime) {
          selectedBreakEndTimeData[i].controls.BreakEndTime.setErrors({ incorrect: true });
          selectedBreakEndTimeData[i].controls.BreakEndTime.markAsDirty();
          selectedBreakEndTimeData[i].controls.BreakEndTime.markAsTouched();
          return false;
        }
      }
      if (!selectedBreakTimeData[i].controls.BreakStartTime.errors) {
        selectedBreakTimeData[i].controls.BreakStartTime.setErrors(null);
        selectedBreakTimeData[i].controls.BreakStartTime.markAsDirty();
        selectedBreakTimeData[i].controls.BreakStartTime.markAsTouched();
      }
      if (!this.breakTimeFormGrp.controls.WorkBreakEndTime['controls'][this.selectedDayData.selectedIndex].controls[i].controls.BreakEndTime.errors) {
        this.breakTimeFormGrp.controls.WorkBreakEndTime['controls'][this.selectedDayData.selectedIndex].controls[i].controls.BreakEndTime.setErrors(null);
        this.breakTimeFormGrp.controls.WorkBreakEndTime['controls'][this.selectedDayData.selectedIndex].controls[i].controls.BreakEndTime.markAsDirty();
        this.breakTimeFormGrp.controls.WorkBreakEndTime['controls'][this.selectedDayData.selectedIndex].controls[i].controls.BreakEndTime.markAsTouched();
      }
    }
    return true;
  }

  saveBreakTime() {
    this.breakTimeEmit.emit(this.breakTimeFormGrp);
    this.closeSlideInfo();
  }

  cancel() {
    this.closeSlideInfo();
  }

  addBreakTimeAndBreakDuration(rowIndex: number): void {
    const breakstartTime = this.breakTimeFormGrp.controls.WorkBreakTime['controls'][this.selectedDayData.selectedIndex] as UntypedFormArray;
    const breakendTime = this.breakTimeFormGrp.controls.WorkBreakEndTime['controls'][this.selectedDayData.selectedIndex] as UntypedFormArray;
    breakstartTime.push(new UntypedFormGroup({
      BreakStartTime: new UntypedFormControl("", Validators.required),
      Id: this.removedBreakTimeId
    }));
    breakendTime.push(new UntypedFormGroup({
      BreakEndTime: new UntypedFormControl("", Validators.required),
      Id: this.removedBreakTimeId
    }));
  }

  removeBreakTimeAndBreakDuration(rowIndex: number, breakTimeId: number): void {
    this.removedBreakTimeId = new FormControl(breakTimeId);
    const breakstartTime = this.breakTimeFormGrp.controls.WorkBreakTime['controls'][this.selectedDayData.selectedIndex] as UntypedFormArray;
    const breakendTime = this.breakTimeFormGrp.controls.WorkBreakEndTime['controls'][this.selectedDayData.selectedIndex] as UntypedFormArray;
    breakstartTime.removeAt(rowIndex);
    breakendTime.removeAt(rowIndex);
    this.validateAll();
    this.breakTimeFormGrp.markAsTouched();
  }
}
