
import { Component, OnInit, ViewEncapsulation, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { HttpMethod, HttpServiceCall } from '../../../service/http-call.service';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { Host, DefaultGUID } from '../../../globalsContant';
import { BaseResponse } from '../../../../shared/business/shared.modals';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { Subscription } from 'rxjs';
import { ActivityStatus } from 'src/app/retail/shared/service/payment/payment-model';


@Component({
    selector: 'itenerary-records',
    templateUrl: './Itinerary.component.html',
    styleUrls: ['./Itinerary.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IteneraryComponent implements OnInit {
    public SelectedValue: string = "day";
    public sidebarArray: any = [];
    public itenaryarrayList: any = [];
    public itenaryarrayListFull: any = [];
    public deepcloneArray: any = [];
    public calenderValue: any;
    public calenderDate: Date;
    public weekStart: any;
    public WeekEnd: any;
    public monthinDays: any;
    public monthYear: any;
    public dateDynamicArray: any = [];
    private _thisRef = this;
    private _localization: SpaLocalization;
    public commonCaptions = this.localization.captions.common;
    APPdate: Date;
    /**
     * Itenary Array for looping the data to Dom
     */
    itenaryArray = []
    /**
    * Week array looping the Mon to Sunday
    */
    WeakArray = this.localization.getDaysModel(false);

    /**
     * Day Array Looping the day count
    */
    dateArray = [];

    /**
     * Time Slot
     */

    timeSlot = [
        { value: this.LocalizeHeaderTime("00 - 01"), start: "00:00", end: "01:00" },
        { value: this.LocalizeHeaderTime("01 - 02"), start: "01:00", end: "02:00" },
        { value: this.LocalizeHeaderTime("02 - 03"), start: "02:00", end: "03:00" },
        { value: this.LocalizeHeaderTime("03 - 04"), start: "03:00", end: "04:00" },
        { value: this.LocalizeHeaderTime("04 - 05"), start: "04:00", end: "05:00" },
        { value: this.LocalizeHeaderTime("05 - 06"), start: "05:00", end: "06:00" },
        { value: this.LocalizeHeaderTime("06 - 07"), start: "06:00", end: "07:00" },
        { value: this.LocalizeHeaderTime("07 - 08"), start: "07:00", end: "08:00" },
        { value: this.LocalizeHeaderTime("08 - 09"), start: "08:00", end: "09:00" },
        { value: this.LocalizeHeaderTime("09 - 10"), start: "09:00", end: "10:00" },
        { value: this.LocalizeHeaderTime("10 - 11"), start: "10:00", end: "11:00" },
        { value: this.LocalizeHeaderTime("11 - 12"), start: "11:00", end: "12:00" },
        { value: this.LocalizeHeaderTime("12 - 13"), start: "12:00", end: "13:00" },
        { value: this.LocalizeHeaderTime("13 - 14"), start: "13:00", end: "14:00" },
        { value: this.LocalizeHeaderTime("14 - 15"), start: "14:00", end: "15:00" },
        { value: this.LocalizeHeaderTime("15 - 16"), start: "15:00", end: "16:00" },
        { value: this.LocalizeHeaderTime("16 - 17"), start: "16:00", end: "17:00" },
        { value: this.LocalizeHeaderTime("17 - 18"), start: "17:00", end: "18:00" },
        { value: this.LocalizeHeaderTime("18 - 19"), start: "18:00", end: "19:00" },
        { value: this.LocalizeHeaderTime("19 - 20"), start: "19:00", end: "20:00" },
        { value: this.LocalizeHeaderTime("20 - 21"), start: "20:00", end: "21:00" },
        { value: this.LocalizeHeaderTime("21 - 22"), start: "21:00", end: "22:00" },
        { value: this.LocalizeHeaderTime("22 - 23"), start: "22:00", end: "23:00" },
        { value: this.LocalizeHeaderTime("23 - 00"), start: "23:00", end: "00:00" },

    ];
    clientWindowConvertion: Subscription;
    isFirstTime: boolean = true;
    sampleArray = [];
    floatLabel:string;
    constructor(private http: HttpServiceCall, private utils: SpaUtilities, private appointmentService: AppointmentpopupService,
        public localization: SpaLocalization, private PropertyInfo: SpaPropertyInformation) {
            this.floatLabel = this.localization.setFloatLabel;
    }


    /**
     * @function buttonChange
     * @param e
     * @description Change toogle button on DOM
     * @argument "e" Passed to the Function Assign the SelectedValue Value and sidebarArray Value
     */
    buttonChange(e) {
        this.SelectedValue = e;
        this.APPdate = this.PropertyInfo.CurrentDate;
        this.sidebarArray = (e == "week") ? this.WeakArray : this.dateArray;
        if (e == "week") {
            this.weekStart = moment(this.PropertyInfo.CurrentDate).startOf('week');
            this.WeekEnd = moment(this.PropertyInfo.CurrentDate).endOf('week');
            this.calenderValue = moment(this.PropertyInfo.CurrentDate).startOf('week').format("DD MMM, YYYY") + '-' + moment(this.PropertyInfo.CurrentDate).endOf('week').format("DD MMM, YYYY");
            let fromDate = this.weekStart.toDate();
            let toDate = this.WeekEnd.toDate();
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(toDate, 1), "week");
        }
        else if (e == "month") {
            let fromDate; let toDate;
            this.monthinDays = moment(this.PropertyInfo.CurrentDate).daysInMonth();
            this.createDate();
            this.calenderValue = moment(this.PropertyInfo.CurrentDate).format("MMM, YYYY")
            this.calenderDate = moment(this.PropertyInfo.CurrentDate).toDate();
            fromDate = moment(this.PropertyInfo.CurrentDate).startOf('month').toDate();
            toDate = moment(fromDate).endOf('month').toDate();
            this.monthYear = this.utils.parseDateToDateWithHours(moment(this.calenderValue).format("YYYY-MM-DD")).getMonth();
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(toDate, 1), "month");
        }
        else {
            this.calenderValue = moment(this.PropertyInfo.CurrentDate).format("DD MMM, YYYY")
            this.calenderDate = moment(this.PropertyInfo.CurrentDate).toDate();
            let fromDate: Date = this.calenderDate;
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(fromDate, 1), "day");
        }
    }

    /**
     * @function WeekdaysCalculation
     * @param e ==> Date
     * @description Passed the date to the Function its return the particular date in week or not
     * @argument date string Format
     *
     */
    WeeakdaysCalculation(e) {
        let incomingDate = moment(e);
        return incomingDate.isBetween(this.weekStart, this.WeekEnd, null, '[]');
    }

    stringExplode(e) {
        let startTime = e.startTime.toLowerCase().replace("am", "").replace('pm', '');
        let trimStartDate = startTime.trim();
        let endDate = e.endTime.toLowerCase().trim();
        let concat = trimStartDate + '-' + endDate;
        let trimConcat = concat.trim();
        return trimConcat.replace(/\s/g, "");
    }

    CompareDateTime(data, timeSlot) {
        var startDateTime = this.utils.getDate(data.date + "T" + data.startTime);
        var startTimeSlot = this.utils.getDate(data.date + "T" + timeSlot.start);
        var endTimeSlot = this.utils.getDate(data.date + "T" + timeSlot.end);
        return startDateTime >= startTimeSlot && startDateTime < endTimeSlot;
    }


    /**
     * @function checkMonth
     * @param passed boolean Value
     * @description Pass the date check this month is current date
     */

    checkMonth(e) {
        let iscurrentMonth;
        let currentMonth = this.PropertyInfo.CurrentDate.getMonth() + 1;
        let monthObj = 1 + moment(e, 'YYYY-MM-DD').month();
        if (currentMonth.toString() == monthObj.toString()) {
            iscurrentMonth = true;
        } else {
            iscurrentMonth = false;
        }
        return iscurrentMonth;
    }

    /**
     * @function changemonthdateYear
     * @param string
     * @description pass the Next or prev value to function and Calculate the day, month, year.
     * @output calendervalue
     */

    changemonthdateYear(e) {
        let preNext = (e == "next") ? 1 : -1;
        if (this.SelectedValue == "day") {
            this.calenderDate = moment(this.calenderValue, "DD MMM, YYYY").add(preNext, 'days').toDate();
            this.calenderValue = moment(this.calenderValue, "DD MMM, YYYY").add(preNext, 'days').format("DD MMM, YYYY");
            let fromDate: Date = this.calenderDate;
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(fromDate, 1), "day");
        }
        else if (this.SelectedValue == "week") {
            this.calenderValue = moment(this.weekStart).startOf('week').add(preNext, 'weeks').format("DD MMM, YYYY") + '-' + moment(this.WeekEnd).endOf('week').add(preNext, 'weeks').format("DD MMM, YYYY");
            this.weekStart = moment(this.weekStart).startOf('week').add(preNext, 'weeks');
            this.WeekEnd = moment(this.WeekEnd).endOf('week').add(preNext, 'weeks');
            let fromDate = this.weekStart.toDate();
            let toDate = this.WeekEnd.toDate();
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(toDate, 1), "week");
        }
        else {
            let fromDate; let toDate;
            this.calenderValue = moment(this.calenderValue, 'MMM, YYYY').add(preNext, 'months').format("MMM, YYYY");
            this.calenderDate = moment(this.calenderValue, 'MMM, YYYY').toDate();
            this.monthYear = this.utils.parseDateToDateWithHours(moment(this.calenderValue).format("YYYY-MM-DD")).getMonth();
            this.monthinDays = moment(this.calenderValue).daysInMonth();
            fromDate = moment(this.calenderDate).startOf('month').toDate();
            toDate = moment(fromDate).endOf('month').toDate();
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(toDate, 1), "month");
            this.createDate()
        }
    }

    /**
     * @function switchtoWeek
     * @param date time
     * @description pass the date to function
     */

    switchtoWeek(e) {
        this.APPdate = e.value;
        if (this.SelectedValue == "day") {
            this.calenderDate = e.value;
            this.calenderValue = moment(e.value, "DD MMM, YYYY").format("DD MMM, YYYY");
            let fromDate: Date = e.value;
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(fromDate, 1), "day");
        } else if (this.SelectedValue == "week") {
            this.weekStart = moment(e.value).startOf('week');
            this.WeekEnd = moment(e.value).endOf('week');
            this.calenderValue = moment(e.value).startOf('week').format("DD MMM, YYYY") + '-' + moment(e.value).endOf('week').format("DD MMM, YYYY");
            let fromDate: Date = this.weekStart.toDate();
            let toDate: Date = this.WeekEnd.toDate();
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(toDate, 1), "week");
        } else {
            let fromDate; let toDate;
            this.calenderDate = e.value;
            this.calenderValue = moment(e.value).format("MMM, YYYY");
            this.monthinDays = moment(this.calenderValue).daysInMonth();
            this.createDate();
            fromDate = moment(e.value).startOf('month').toDate();
            toDate = moment(fromDate).endOf('month').toDate();
            this.monthYear = this.utils.parseDateToDateWithHours(moment(this.calenderValue).format("YYYY-MM-DD")).getMonth();
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(toDate, 1), "month");
        }
    }
    /**
     * @function createDate()
     * @param datevalue
     * @description create the date Array from the loop
     */

    createDate() {
        this.sidebarArray = [];
        for (let index = 0; index < this.monthinDays; index++) {
            let dayCountIndex = index + 1;
            let dayCount = ('0' + dayCountIndex).slice(-2)
            this.sidebarArray.push(dayCount)
        }
    }

    /**
     * Converts a javascript date to localized time string.
     * @param Date javascript date.    *
     */
    LocalizeHeaderTime(value: string): string {
        let timeFormat = this.localization.getTimeFormat();
        if (timeFormat == 24) {
            return value;
        }
        else {
            let timeArr = value.split('-')
            let fromTime = moment(timeArr[0], "HH").format('hh');
            let toTime = moment(timeArr[1], "HH").format('hh A');
            return `${fromTime} - ${toTime}`
        }

    }

    ngOnInit() {
        this.APPdate = this.PropertyInfo.CurrentDate;
        this.initializeFormData();
    }

    initializeFormData() {
        this.calenderDate = this.PropertyInfo.CurrentDate;
        this.calenderValue = moment(this.PropertyInfo.CurrentDate).format("DD MMM, YYYY");
        this.monthinDays = moment(this.PropertyInfo.CurrentDate).daysInMonth();
        this.createDate()
        if (this.appointmentService.clientEditData && this.appointmentService.clientEditData.clientDetail && this.appointmentService.clientEditData.clientDetail.guestId && this.appointmentService.clientEditData.clientDetail.guestId != DefaultGUID) {
            let fromDate: Date = this.calenderDate;
            this.GetItenaryByDateRange(fromDate, this.utils.AddDays(fromDate, 1), "day");
        }
        this.clientWindowConvertion = this.appointmentService.convertToEdit.subscribe(x => {
            if (x && x.id > 0 && this.isFirstTime) {
                this.isFirstTime = false;
                this.initializeFormData();
            }
        });
    }

    GetItenaryByDateRange(fromDate: Date, toDate: Date, type: string) {
        var fromDateToAPI = fromDate ? this.localization.convertDateObjToAPIdate(fromDate) : '';
        var toDatetoAPI = toDate ? this.localization.convertDateObjToAPIdate(toDate) : '';
        this.http.CallApiWithCallback({
            host: Host.schedule,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "GetIteneraryByDateRange",
            method: HttpMethod.Get,
            uriParams: { clientId: this.appointmentService.clientEditData.clientDetail.guestId, fromDate: fromDateToAPI, toDate: toDatetoAPI },
            showError: true,
            extraParams: [type]
        });
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        let timeFormat = this.localization.getTimeFormat();
        if (callDesc == "GetIteneraryByDateRange") {
            let clientIteneraries = <any>result.result;
            this.itenaryArray = [];
            for (let clientItenerary of clientIteneraries) {
                const tempStatus = clientItenerary.packageName == "TeeTime" && clientItenerary.status == 2 ? "Paid" : ActivityStatus[clientItenerary.status];
                this.itenaryArray.push({
                    "id": clientItenerary.id,
                    "date": this.utils.formatDate(clientItenerary.start),
                    "description": clientItenerary.name,
                    "status": clientItenerary.status,
                    "startTime": this.localization.getTime(clientItenerary.start, 12),
                    "endTime": this.localization.getTime(clientItenerary.end, 12),
                    "displayStartTime": this.localization.getTime(clientItenerary.start, timeFormat),
                    "displayEndTime": this.localization.getTime(clientItenerary.end, timeFormat),
                    "packageName": clientItenerary.packageName,
                    "displayStatus": clientItenerary.platformActivityStatus ? clientItenerary.platformActivityStatus : tempStatus,
                    "activityLocation": clientItenerary.activityDetail ? clientItenerary.activityDetail.activityLocation : ''
                });
            }
            this.itenaryarrayListFull = this.itenaryarrayList = this.itenaryArray;
            this.itenaryarrayList = this.sortTimeData(this.itenaryarrayList);
        }

    }
    errorCallback() {
        console.error('error');
    }

    ngOnDestroy(): void {
        if (this.clientWindowConvertion) {
            this.clientWindowConvertion.unsubscribe()
        }
        this.isFirstTime = true;
    }

    sortTimeData(timeData) {
        return _.orderBy(timeData, ['orgStartDateTime'], ['asc']);
    }

}
@Pipe({
    name: 'bindItineraryRecords',
    pure: true
})
export class BindItineraryPipe implements PipeTransform {
    constructor(private utils: SpaUtilities) { }
    transform(record, timeSlot, sideBar, SelectedValue) {
        const iscurrentSlot = this.CompareDateTime(record, timeSlot);
        if (SelectedValue === 'week') {
            const inDay = this.datetoDay(record);
            if (sideBar && inDay.toString() === sideBar.code && iscurrentSlot) {
                return record.packageName ? record.packageName : record.description;
            }
        }
        if (SelectedValue === 'month') {
            const inDate = moment(record.date).format('DD')
            if (inDate.toString() === sideBar.toString() && iscurrentSlot) {
                return record.packageName ? record.packageName : record.description;
            }
        }
    }

    CompareDateTime(data, timeSlot) {
        if (timeSlot.start == '23:00') {
            timeSlot.end = '23:59';
        }
        const startDateTime = this.utils.getDate(data.date + 'T' + data.startTime);
        const startTimeSlot = this.utils.getDate(data.date + 'T' + timeSlot.start);
        const endTimeSlot = this.utils.getDate(data.date + 'T' + timeSlot.end);
        return startDateTime >= startTimeSlot && startDateTime < endTimeSlot;
    }

    datetoDay(e) {
        let dayValue;
        const day = moment(e.date);
        const dow = day.day();
        switch (dow) {
            case 0:
                dayValue = 'Sun';
                break;
            case 1:
                dayValue = 'Mon';
                break;
            case 2:
                dayValue = 'Tue';
                break;
            case 3:
                dayValue = 'Wed';
                break;
            case 4:
                dayValue = 'Thu';
                break;
            case 5:
                dayValue = 'Fri';
                break;
            case 6:
                dayValue = 'Sat';
                break;
            default:
                dayValue = 'Sat'
        }
        return dayValue;
    }
}
