import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AgInputFieldConfig, ButtonValue } from '../Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { Localization } from '../localization/localization';
import { takeUntil } from 'rxjs/operators';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertAction, ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { SamsotechIntegrationBusinessService } from './common-samsotech-integration.business.service';
import { SamsotechIntegrationConfig } from 'src/app/common/Models/common.models';
import { InternalIntegrationSettingData } from 'src/app/common/constants';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';

@Component({
  selector: 'app-common-samsotech-integration',
  templateUrl: './common-samsotech-integration.component.html',
  styleUrls: ['./common-samsotech-integration.component.scss'],
  providers: [SamsotechIntegrationBusinessService, FacadeService, UserAccessService]
})
export class CommonSamsotechIntegrationComponent implements OnInit {

  captions: any;
  samsotechIntegrationForm: UntypedFormGroup;
  samsotechScannerEndpointInput: AgInputFieldConfig;
  samsotechReadChipEndpointInput: AgInputFieldConfig;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  isCreate : boolean ;
  settingsId: number = 0;
  isViewOnly: boolean;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private localization: Localization,private fb: UntypedFormBuilder, private business: SamsotechIntegrationBusinessService,
    private facadeService: FacadeService, private utils: CommonUtilities) { 
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadData();
    this.validateUserAccess();
    this.samsotechIntegrationForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.saveButton.disabledproperty = !(this.samsotechIntegrationForm.valid && this.samsotechIntegrationForm.dirty) 
      this.saveButton = { ... this.saveButton };
    });
  }

  initializeForm(){
    this.samsotechIntegrationForm = this.fb.group({
      enableSamsotech: false,
      enableFlipCard: false,
      samsotechScannerEndpoint:'',
      samsotechReadChipEndpoint:''
    })

    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    }; 


    this.samsotechScannerEndpointInput = {
      className: 'ag_form-control--1',
      form: this.samsotechIntegrationForm,
      formControlName: 'samsotechScannerEndpoint',
      placeHolderId: 'lbl_samsotech_scanner_endpoint',
      placeHolder: this.captions.lbl_samsotech_scanner_endpoint,
      showRequired: false,
      disabled: this.isViewOnly
    };

    this.samsotechReadChipEndpointInput = {
      className: 'ag_form-control--1',
      form: this.samsotechIntegrationForm,
      formControlName: 'samsotechReadChipEndpoint',
      placeHolderId: 'lbl_samsotech_readchip_endpoint',
      placeHolder: this.captions.lbl_samsotech_readchip_endpoint,
      showRequired: false,
      disabled: this.isViewOnly
    };

  }

  private loadData() {
    var res = this.business.GetSamsotechConfiguration();
    res.then(r => {
      if (r && r.configValue) {
        this.isCreate = false;
        this.settingsId = r.id;
        var samsotechConfig = (r.configValue as unknown) as SamsotechIntegrationConfig;
        this.samsotechIntegrationForm.controls['enableSamsotech'].setValue(samsotechConfig.enableSamsotech);
        this.samsotechIntegrationForm.controls['enableFlipCard'].setValue(samsotechConfig.enableFlipCard);
        this.samsotechIntegrationForm.controls['samsotechScannerEndpoint'].setValue(samsotechConfig.scannerEndpointUrl);
        this.samsotechIntegrationForm.controls['samsotechReadChipEndpoint'].setValue(samsotechConfig.readChipEndpointUrl);
        if(!this.isViewOnly)
        {
          this.samsotechScannerEndpointInput.disabled = !samsotechConfig.enableSamsotech;
          this.samsotechScannerEndpointInput = { ...this.samsotechScannerEndpointInput };
          this.samsotechReadChipEndpointInput.disabled = !samsotechConfig.enableSamsotech;
          this.samsotechReadChipEndpointInput = { ...this.samsotechReadChipEndpointInput };
        }
      }
      else {
        this.samsotechScannerEndpointInput.disabled = true;
        this.samsotechScannerEndpointInput = { ...this.samsotechScannerEndpointInput };
        this.samsotechReadChipEndpointInput.disabled = true;
        this.samsotechReadChipEndpointInput = { ...this.samsotechReadChipEndpointInput };
        this.isCreate = true;
      }
    }).finally(()=>{
      this.saveButton.disabledproperty = true;
      this.saveButton = { ...this.saveButton };
    });
  }

  async validateUserAccess() {
    const userAccess = await this.facadeService.getUserAccess(UserAccessBreakPoints.SAMSOTECHINTEGRATION, true);
    
    this.isViewOnly = userAccess?.isViewOnly || !userAccess.isAllow;
    if (this.isViewOnly) {
      this.samsotechScannerEndpointInput.disabled = true;
      this.samsotechScannerEndpointInput = { ...this.samsotechScannerEndpointInput };
      this.samsotechReadChipEndpointInput.disabled = true;
      this.samsotechReadChipEndpointInput = { ...this.samsotechReadChipEndpointInput };
      this.samsotechIntegrationForm.controls['enableSamsotech'].disable();
      this.samsotechIntegrationForm.controls['enableFlipCard'].disable();
      this.saveButton.disabledproperty = true;
      this.saveButton = { ...this.saveButton };
    }
  }

  onChangeConfig(e)
  {
    this.samsotechScannerEndpointInput.disabled = !e.checked || this.isViewOnly;
    this.samsotechReadChipEndpointInput.disabled = !e.checked || this.isViewOnly;
    this.samsotechScannerEndpointInput = { ...this.samsotechScannerEndpointInput };
    this.samsotechReadChipEndpointInput = { ...this.samsotechReadChipEndpointInput };
  }

  async onSave(eve){
    var res=await this.business.SaveSamsotechConfiguration(this.samsotechIntegrationForm.controls,this.isCreate,this.settingsId);
    if(res)
    {
      if(this.isCreate)
      {
        this.loadData();

      }
      else if(res.length > 0)
      {
         var config = res.filter(x=>x.screenName == InternalIntegrationSettingData.ScreenNameSamsotechIntegration);
         this.settingsId = config.length > 0 ? config[0].id : 0;
      }
      
      this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
      this.saveButton.disabledproperty =  true;
      this.saveButton = { ...this.saveButton };    
      this.samsotechIntegrationForm.markAsPristine();  
    }
  }

  onCancel(eve){
    if (this.samsotechIntegrationForm.dirty) {
      this.utils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res => {
        if (res === AlertAction.YES) {
          this.samsotechIntegrationForm.reset();
          this.loadData();
          this.saveButton.disabledproperty =  true;
          this.saveButton = { ...this.saveButton };
          this.samsotechIntegrationForm.markAsPristine();
        }
        else
        {
          this.cancelButton.disabledproperty = false;
          this.cancelButton = { ...this.cancelButton };
        }
      }));
    } 
  }

}
