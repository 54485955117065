import { MasterInventoryManagement } from '../master.interface';
import { ColumnFieldType } from './columnType.enum';
import { FieldEdmType } from './fieldEdmType.enum';
import { GridModelConfiguration, GridModelHeaderDefination } from './grid-config.interface';

export const GridHeaderColumnType: GridModelHeaderDefination = ( 
    ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,  EntityProperty: any): any => {
    try {
      return getColumnType(EntityProperty, MasterDataCollection, ViewModelEntity );
    } catch (error) {
      console.log('error occured GridColumnType', error);
    }
  };

  /**
     * @method getColumnType()
     * @param type name;
     * @description Assign form type
     */
    function getColumnType(EntityProperty: any, MasterDataCollection: MasterInventoryManagement, ViewModelEntity: any): string {
      try {
          let columnType = '';
          switch (EntityProperty['$']['Type']) {
              case FieldEdmType.EDMSTRING:
                columnType = ColumnFieldType.NONEDITABLE;
                break;
              case FieldEdmType.EDMINT32:
                columnType = checkNavigationProperity(EntityProperty, ViewModelEntity);
                break;
                case FieldEdmType.EDMDATEOFFSET:
                  columnType = ColumnFieldType.DATECOLUMN;
                break;
              default:
                columnType = ColumnFieldType.NONEDITABLE;
            }
            return columnType;

      } catch (error) {
          console.log('error occured', error);
      }
  }

     /**
      * @functiodn checkNavigationProperity()
      * @prams odataEntity, odataobject
      * @description check navigation properoity
      */

     function checkNavigationProperity(OdataObject: any, OdataViewEntityModel: any): string {
       try {
        let fieldType = ColumnFieldType.NUMERIC;
        if ( OdataViewEntityModel && OdataViewEntityModel.length > 0 && OdataViewEntityModel[0].hasOwnProperty('NavigationProperty')) {
         const getNavigationType = OdataViewEntityModel[0]['NavigationProperty'].
         filter(x => x['ReferentialConstraint'] && x['ReferentialConstraint'][0]['$']['Property'] === OdataObject['Name']);
         if (getNavigationType && getNavigationType.length > 0 ) {
          fieldType = ColumnFieldType.NONEDITABLE;
         }
        }
        return fieldType;
       } catch (error) {
         console.error(error);
       }
    }