import { SetupAuthorizedConfig} from './ui-control-config.interface';
import { AuthorizationSubModule, AuthorizedDataModel, PermissionResource } from './ui-controls.interface';
// import * as AuthSample from '../../../../assets/json/ui-control/authoriztion-sample.json';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { SetUpGroup, SetUpGrouping, SubGroupListing } from 'src/app/eatecui/source/setup/shared/interface/setup-links.interface';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';

export const SetupAuthorization: SetupAuthorizedConfig = (
    setUpGroup: Array<SetUpGroup>, AuthorizedData: Array<AuthorizedDataModel>, 
    integrationService?: IntegrationService): TransactionConfiguration => {
        try {
            const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
            const authSample = null;
            // eslint-disable-next-line max-len
            const AuthorizedDataResult: Array<AuthorizedDataModel> = ( AuthorizedData && AuthorizedData !== null ) ? AuthorizedData :
            ( AuthorizationData && AuthorizationData !== null ) ? AuthorizationData : authSample;
            if ( setUpGroup && AuthorizedDataResult && AuthorizedDataResult.length > 0 ) {
               const subModuleSetupName = 'setup';
               // eslint-disable-next-line max-len
               const authDataModel: Array<AuthorizedDataModel> = 
               AuthorizedDataResult.filter((x: AuthorizedDataModel) => x.SubModules.findIndex(e => e.Name === subModuleSetupName) > -1 );
               if ( authDataModel && authDataModel.length > 0 ) {
                authDataModel.forEach((authModel: AuthorizedDataModel) => {
                     if ( authModel.hasOwnProperty('SubModules') && authModel.SubModules.length > 0 ) {
                        authModel.SubModules.forEach((e: AuthorizationSubModule) => {
                           if ( e.hasOwnProperty('PermissionResource') && e.PermissionResource.length > 0 ) {
                            e.PermissionResource.forEach((p: PermissionResource) => {
                                 if ( setUpGroup && setUpGroup.length > 0 ) {
                                    setUpGroup.forEach((x: SetUpGroup ) => {
                                       if ( x.hasOwnProperty('SetUpSubGroup') && x.SetUpSubGroup.length > 0 ) {
                                            x.SetUpSubGroup.forEach((subGroup: SetUpGrouping) => {
                                                if ( subGroup.hasOwnProperty('SubGroupList') && subGroup.SubGroupList.length > 0 ) {
                                                    const dmIntegCollect = integrationService.IntegrationConfig.
                                                    IntegrationCollection.filter(y => y.IntegratonName === 'DataMagine')[0];
                                                    const restrictedPermissionResource = ['DMMappings', 'Integration', 'Transaction Approval Routing'];
                                                    const isAgysUser = StorageService.GetSessionStorage('isAgilysysEmployee');
                                                    subGroup.SubGroupList.forEach((groupList: SubGroupListing) => { 
                                                        if ( p.Name === groupList.OdataKey ) {
                                                            groupList['Authorization'] = p.IsActive;
                                                        }
                                                        const index1 = restrictedPermissionResource.findIndex(y => y === groupList.Name);
                                                        if (index1 > -1) {
                                                            if (groupList.Name === 'DMMappings' && !dmIntegCollect.IsEnabled) {
                                                                groupList['Authorization'] = false;
                                                            } else if( groupList.Name === 'Transaction Approval Routing' ) {
                                                                const documentAp = integrationService.IntegrationConfig.
                                                                IntegrationCollection.filter(y => y.IntegratonName === 'DocApprovals');
                                                                if( documentAp && documentAp.length > 0 && !documentAp[0].IsEnabled ){
                                                                    groupList['Authorization'] = documentAp[0].IsEnabled;
                                                                }
                                                            } else if ((groupList.Name === 'Integration') && 
                                                            (!integrationService.IntegrationConfig.EnabledIntegration || 
                                                            (isAgysUser.toLowerCase() === 'false'))) {
                                                                groupList['Authorization'] = false;
                                                            }                                                            
                                                        }
                                                    });
                                                    subGroup.Authorization = subGroup.SubGroupList.
                                                        some(subGrp => subGrp.Authorization === true);
                                                    const rpnames = ['DataMagine', 'Interface Mappings'];
                                                    const index2 = rpnames.findIndex(z => z === subGroup.SubGroupName);
                                                        if (index2 > -1) {
                                                            if (subGroup.SubGroupName === 'DataMagine' && !dmIntegCollect.IsEnabled) {
                                                                subGroup.Authorization = false;
                                                            } else if ((subGroup.SubGroupName === 'Interface Mappings') && 
                                                            ( !integrationService.IntegrationConfig.EnabledIntegration || 
                                                            (isAgysUser.toLowerCase() === 'false'))) {
                                                                subGroup.Authorization = false;
                                                            }                                                            
                                                        }
                                                        
                                                    // if (subGroup.SubGroupName === 'DataMagine' && !dmIntegCollect.IsEnabled) {
                                                    //   subGroup.Authorization = false;
                                                    // }
                                                    // if (subGroup.SubGroupName === 
                                                    // 'Integration' && !integrationService.IntegrationConfig.EnabledIntegration) {
                                                    //     subGroup.Authorization = false;
                                                    // }
                                                }
                                            });
                                            x.Authorization = x.SetUpSubGroup.some(subGrp => subGrp.Authorization === true);
                                       }
                                    });   
                                 }
                            });
                           }
                        });
                     }
                });
               }
            } 
            return null;
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

   