import { BaseReport } from './base-report';
import { AllReports } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import { Injectable } from '@angular/core';
import { MultiPackItems } from '../retail-reports/multiPackItem.report';
import { ReturnedItems } from '../retail-reports/returnedItems.report';
import { InventorySummary } from '../retail-reports/inventorySummary.report';
import { InventoryDetails } from '../retail-reports/inventoryDetails.report';
import { InventoryTransfer } from '../retail-reports/inventoryTransfer.report';
import { InventoryWash } from '../retail-reports/inventoryWash.report';
import { InventoryAudit } from '../retail-reports/inventoryAudit.report';
import { SalesBySubCategory } from '../retail-reports/salesBySubCategory.report';
import { SalesByCategory } from '../retail-reports/salesByCategory.report';
import { SaleByItem } from '../retail-reports/saleByItem.report';
import { CorrectVoid } from '../retail-reports/correctVoid.report';
import { SalesByDiscountTypes } from '../retail-reports/salesByDiscountTypes.report';
import { Shift } from '../retail-reports/shift.report';
import { RevenueSummary } from '../retail-reports/revenueSummary.report';
import { Transaction } from '../retail-reports/transaction.report';
import { ReportBusinessService } from '../report-business.service';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { StaffSummaryReport } from '../commissionGratuity-reports/staffMemberSummary.report';
import { ServiceChargeReport } from '../commissionGratuity-reports/servicecharge.report';
import { GratuityReport } from '../commissionGratuity-reports/gratuity.report';
import { CommissionReport } from '../commissionGratuity-reports/commision.report';
import { SurchargesReport } from '../commissionGratuity-reports/surcharges.report';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { GiftCardRedeemReport } from '../gift-cards/GiftCardRedeemReport.report';
import { GiftCardIssuedReport } from '../gift-cards/GiftCardIssuedReport.report';
import { TransactionDetail } from '../retail-reports/transactionDetail.report';
import { TopSpenders } from '../retail-reports/topSpenders.report';
import { TaxExemptSales } from '../retail-reports/taxexemptsales.report';
import { Accrual } from '../retail-reports/accrual.reports';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { SalesMix } from '../retail-reports/salesMix.report';
import { InventoryReport } from '../retail-reports/inventoryReport.report';
import { InventoryPickupDelivery } from '../retail-reports/inventory-pickup-delivery.report';
import { ItemsExtract } from '../retail-reports/itemsExtract.report';
import { VendorInventoryDetail } from '../retail-reports/vendorInventoryDetail.report';
import { SalesByVendor } from '../retail-reports/SalesByVendor.report';
import { InventorySummaryByVendor } from '../retail-reports/inventorySummaryByVendor.report';
import {InventoryOnHand } from '../retail-reports/inventoryOnHand.report';
import { TransactionExtract } from '../retail-reports/transactionExtract.report';
import { InventoryVariance } from '../retail-reports/variance.report';
import { Receiving } from '../retail-reports/receiving.report';
import { PurchaseOrders } from '../retail-reports/purchaseOrders.report';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { Localization } from 'src/app/common/localization/localization';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { InventoryValueandActivity } from '../retail-reports/inventoryValueandActivity.report';
import { InventoryValuation } from '../retail-reports/inventoryValuation.report';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { InventoryAvailabilitySummary } from '../retail-reports/InventoryAvailability.report';
@Injectable()
export class ReportFactory {
	private readonly _reportFormGrp: UntypedFormGroup;
	private readonly _dataService: ReportDataService;
	private readonly _business: ReportBusinessService;
	private readonly _utils: RetailUtilities;
	private readonly _propertyInfo: RetailPropertyInformation;
	private readonly _retailfunctionality: RetailFunctionalityBusiness;
	private readonly _localization: RetailLocalization;
	private readonly _retailfeaturesflag: RetailFeatureFlagInformationService;
	constructor(
		reportFormGrp: UntypedFormGroup,
		dataService: ReportDataService,
		business: ReportBusinessService,
		private utils: RetailUtilities,
		propertyInfo: RetailPropertyInformation,
		retailfunctionality: RetailFunctionalityBusiness,
		localization: RetailLocalization,
		private _CommonLocalization: Localization,
		private retailfeaturesflag: RetailFeatureFlagInformationService,
		private propertySettingsDataService: PropertySettingDataService
		) {
		this._reportFormGrp = reportFormGrp;
		this._dataService = dataService;
		this._business = business;
		this._utils = utils;
		this._propertyInfo = propertyInfo;
		this._retailfunctionality = retailfunctionality;
		this._localization = localization;
		this._localization.isFromJobScheduler = this._CommonLocalization.isFromJobScheduler;
		this._localization.scheduleJobWildCards = this._CommonLocalization.scheduleJobWildCards;
		this._retailfeaturesflag = retailfeaturesflag;
		}

	public Get(code: string, editableValue: any): BaseReport {
		let productId = Number(this.utils.GetPropertyInfo('ProductId'));
		switch (code) {
			case AllReports.MultiPackItems:
				return new MultiPackItems(this._reportFormGrp, this._dataService);
			case AllReports.ReturnedItems:
				return new ReturnedItems(this._reportFormGrp, this._dataService, this._propertyInfo, this._retailfunctionality, this._localization);
			case AllReports.InventorySummary:
				return new InventorySummary(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization, this._retailfeaturesflag);
			case AllReports.InventoryDetails:
				return new InventoryDetails(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._retailfeaturesflag);
			case AllReports.InventoryTransfer:
				return new InventoryTransfer(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo);
			case AllReports.InventoryWash:
				return new InventoryWash(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.InventoryAudit:
				return new InventoryAudit(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization, this._retailfeaturesflag);
			case AllReports.SalesByDiscountTypes:
				return new SalesByDiscountTypes(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.SalesBySubCategory:
				return new SalesBySubCategory(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization, this._retailfeaturesflag);
			case AllReports.SalesByCategory:
				return new SalesByCategory(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization, this._retailfeaturesflag);
			case AllReports.SaleByItem:
				return new SaleByItem(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization, this._retailfeaturesflag);
			case AllReports.SalesByVendor:
				return new SalesByVendor(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.CorrectVoid:
				return new CorrectVoid(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.Shift:
				return new Shift(this._reportFormGrp, this._dataService, this._business, this._utils, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.Transaction:
				return new Transaction(this._reportFormGrp, this._dataService, this._business, this._utils,
					this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.RevenueSummary:
				return new RevenueSummary(this._reportFormGrp, this._dataService, this._business, this._utils,
					this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.StaffMemberSummary:
				return new StaffSummaryReport(this._reportFormGrp, this._dataService, productId, this._propertyInfo, this._localization, editableValue, this.propertySettingsDataService);
			case AllReports.ServiceCharge:
				return new ServiceChargeReport(this._reportFormGrp, this._dataService, this._propertyInfo, productId, this._localization, editableValue, this.propertySettingsDataService);
			case AllReports.Gratuity:
				return new GratuityReport(this._reportFormGrp, this._dataService, productId, this._localization, editableValue, this.propertySettingsDataService);
			case AllReports.CommissionReport:
				return new CommissionReport(this._reportFormGrp, this._dataService, productId, this._localization, editableValue);
			case AllReports.Surcharges:
				return new SurchargesReport(this._reportFormGrp, this._dataService, productId, this._propertyInfo, this._localization, editableValue);
			case AllReports.GiftCardRedeem:
				return new GiftCardRedeemReport(this._reportFormGrp, this._dataService, this._business, productId, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.GiftCardIssued:
				return new GiftCardIssuedReport(this._reportFormGrp, this._dataService, this._business, productId,
					this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.TransactionDetail:
				return new TransactionDetail(this._reportFormGrp, this._dataService, this._business, this._utils, this._propertyInfo, this._localization);
			case AllReports.TopSpenders:
				return new TopSpenders(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._localization);
			case AllReports.TaxExemptSales:
				return new TaxExemptSales(this._reportFormGrp, this._dataService, this._business,
					productId, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.Accrual:
				return new Accrual(this._reportFormGrp, this._dataService, this._business, this._utils, this._localization);
			case AllReports.SalesMix:
				return new SalesMix(this._reportFormGrp, this._dataService, this._business, this._utils, this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.InventoryReport:
				return new InventoryReport(this._reportFormGrp, this._dataService, this._business, this._localization);
			case AllReports.InventoryPickupDelivery:
				return new InventoryPickupDelivery(this._reportFormGrp, this._dataService, this._business, this._localization);
			case AllReports.ItemsExtract:
				return new ItemsExtract(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization)
			case AllReports.InventorySummaryByVendor:
				return new InventorySummaryByVendor(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality,
					this._propertyInfo, this._localization);
			case AllReports.InventoryDetailVendor:
				return new VendorInventoryDetail(this._reportFormGrp, this._dataService, this._business,
					this._retailfunctionality, this._propertyInfo, this._localization);
			case AllReports.InventoryOnHand:
				return new InventoryOnHand(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._retailfeaturesflag);
			case AllReports.TransactionExtract:
				return new TransactionExtract(this._reportFormGrp, this._localization);
			case AllReports.InventoryVariance:
				return new InventoryVariance(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo);
			case AllReports.Receiving:
				return new Receiving(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo);
			case AllReports.PurchaseOrders:
				return new PurchaseOrders(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo);
			case AllReports.InventoryValuation:
				return new InventoryValuation(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo);
			case AllReports.InventoryValueAndActivity:
				return new InventoryValueandActivity(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo);
			case AllReports.InventoryAvailability:
				return new InventoryAvailabilitySummary(this._reportFormGrp, this._dataService, this._business, this._retailfunctionality, this._propertyInfo, this._localization);
				default:
				break;
		}
	}
}
