import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import * as _ from 'lodash';
import { DropDownData } from '../../../business/report.modals';
import { ValidationMessage } from '../../../basereport/base-report.component';
import { ReportDataService } from '../../../data/report-data.services';

@Component({
  selector: 'app-retail-category-component',
  templateUrl: './category-component.component.html',
  styleUrls: ['./category-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailCategoryComponentComponent implements OnInit , OnChanges {

  @Output() formReady = new EventEmitter<UntypedFormArray>();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  @Input() categoryReportGroup: any = [];

  @Input() fromJobSchedulerEdit: boolean;
  editPatchValue: any;
  @Input('editValues') 
	set patchVal(value){
		if(value){
			this.editPatchValue = value;
      this.patchValues(this.editPatchValue);
		}
	}
  OrgcategoryForm: UntypedFormArray;
  categoryForm: UntypedFormGroup;
  IsViewOnly: boolean = false;
  categoryLinkingObjNamelist: string[];
  categoryLinkingObjArray: any[] = [];
  ORGcategoryLinkingObjArray: any[];
  categorySubCategoryArray: any[] = [];
  categoryArray: any[] = [];
  public captions: any = this.localization.captions.retailsetup;
  inventoryCaptions: any = this.localization.captions.inventory;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: this.localization.captions.common.all,
    showInDropDown: true
  };
  tempcategoryObj:any;
  floatLabel: string;
  constructor(private _retailReportService: ReportDataService, private localization: RetailLocalization) {
    this.floatLabel = this.localization.setFloatLabel;
   }

  ngOnInit() {
    this.OrgcategoryForm = new UntypedFormArray([]);
    this.categoryForm = new UntypedFormGroup({ categoriesData: new UntypedFormArray([]) });
    if(!this.fromJobSchedulerEdit)
    {
      this.onLoad();
    }
  } 

  private async patchValues(patchItem)
  {
    if (this.localization.isFromJobScheduler && this.fromJobSchedulerEdit) {  
      if(patchItem != undefined)
      {
      this.categorySubCategoryArray = await this._retailReportService.GetAllCategoryAndSubCategoryLink();
      if(patchItem.category != undefined){
      this.categorySubCategoryArray['category'] = this.categorySubCategoryArray['category'].filter(x => patchItem.category.includes(x.id)); 
      this.categoryArray = this.categorySubCategoryArray['category'];
      }
      if(patchItem.subCategory1 != undefined){
        this.categorySubCategoryArray['subCategoryOne'] = this.categorySubCategoryArray['subCategoryOne'].filter(x => patchItem.subCategory1.includes(x.id));
      }
      if(patchItem.subCategory2 != undefined){
        this.categorySubCategoryArray['subCategoryTwo'] = this.categorySubCategoryArray['subCategoryTwo'].filter(x => patchItem.subCategory2.includes(x.id));
      }
      if(patchItem.subCategory3 != undefined){
        
        this.categorySubCategoryArray['subCategoryThree'] = this.categorySubCategoryArray['subCategoryThree'].filter(x => patchItem.subCategory3.includes(x.id));
      }
      if(patchItem.subCategory4 != undefined){
        this.categorySubCategoryArray['subCategoryFour'] = this.categorySubCategoryArray['subCategoryFour'].filter(x => patchItem.subCategory4.includes(x.id));
      }
      if(patchItem.subCategory5 != undefined){
        this.categorySubCategoryArray['subCategoryFive'] = this.categorySubCategoryArray['subCategoryFive'].filter(x => patchItem.subCategory5.includes(x.id));
      }
      
    this.formLinkArray([this.categorySubCategoryArray]);
    }
  }
}
  private async onLoad() {
    this.categorySubCategoryArray = await this._retailReportService.GetAllCategoryAndSubCategoryLink();
     
    this.categoryArray = this.categorySubCategoryArray['category'];
    this.formLinkArray([this.categorySubCategoryArray]);
    this.formReady.emit(<UntypedFormArray>this.categoryForm.controls.categoriesData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.fromJobSchedulerEdit){
    this.OrgcategoryForm = new UntypedFormArray([]);
    this.categoryForm = new UntypedFormGroup({ categoriesData: new UntypedFormArray([]) });
    this.categorySubCategoryArray['category'] = this.categoryArray.filter(r=> this.categoryReportGroup.map(t=>t.id).includes(r.retailCategoryGroupId));
    this.formLinkArray([this.categorySubCategoryArray]);
    this.formReady.emit(<UntypedFormArray>this.categoryForm.controls.categoriesData);
          
    }
  }


  formLinkArray(CLO) {
    this.categoryLinkingObjNamelist = [];
    this.ORGcategoryLinkingObjArray = [];
    this.categoryLinkingObjArray = [];
    Object.keys(CLO[0]).forEach((prop, index) => {
      this.categoryLinkingObjNamelist.push(prop);
      let newobj = new UntypedFormGroup({
        catName: new UntypedFormControl(prop),
        OrgcatArr: new UntypedFormControl(CLO[0][prop]),
        catArr: new UntypedFormControl(CLO[0][prop]),
        selectedCat: new UntypedFormControl(CLO[0][prop]),
        disabledFlag: new UntypedFormControl(false)
      });
      this.OrgcategoryForm.push(newobj);
    });
    this.categoryForm = new UntypedFormGroup({ categoriesData: this.OrgcategoryForm });
    this.topdownCheck(this.categoryForm.controls.categoriesData['controls'], 0);
  }


  trackByFn(index, tca) {
    return index; // or item.id
  }

  toggleSelection(eve, index, from) {
    if (from == 'All') {
      if (eve.checked) {
        let newval: any = [];
        newval = newval.concat(this.categoryForm.controls.categoriesData['controls'][index].controls.catArr.value);
        this.categoryForm.controls.categoriesData['controls'][index].controls.selectedCat.setValue(newval);
      } else {
        this.categoryForm.controls.categoriesData['controls'][index].controls.selectedCat.setValue([]);
      }
    } else {
      let checkalltobeselected = this.categoryForm.controls.categoriesData['controls'][index].controls.selectedCat.value;
      checkalltobeselected = checkalltobeselected.filter(x => x != 0);
      if (checkalltobeselected.length == this.categoryForm.controls.categoriesData['controls'][index].controls.catArr.value.length) {
        
        let newval: any = [];
        newval = newval.concat(this.categoryForm.controls.categoriesData['controls'][index].controls.catArr.value);
        this.categoryForm.controls.categoriesData['controls'][index].controls.selectedCat.setValue(newval);
      } else {
        let newval;
        newval = this.categoryForm.controls.categoriesData['controls'][index].controls.selectedCat.value.filter(x => x != 0);
        this.categoryForm.controls.categoriesData['controls'][index].controls.selectedCat.setValue(newval);
      }
    }
    this.resetcatarr(); 
    this.topdownCheck(this.categoryForm.controls.categoriesData['controls'], index);
    
    this.formReady.emit(<UntypedFormArray>this.categoryForm.controls.categoriesData);
    let validity = this.categoryForm.controls.categoriesData['controls'][0].controls.selectedCat.value.length > 0;
    this.IsValidSelection.emit({ dropDownName: this.inventoryCaptions.Category, validity: validity });
  }
  topdownCheck(list, ind) {
    console.log("topdownCheck list",list);
    let der_childSubCategories: any;
    list.forEach((element, index) => {
      der_childSubCategories = [];
      if (element.value.catArr.length > 0) {
        if (element.value.selectedCat.length > 0) {
          element.value.selectedCat.forEach(CA => {
            if (CA.childSubCategories) {
              der_childSubCategories = der_childSubCategories.concat(CA.childSubCategories);
            }
          });
        } else { 
          element.value.selectedCat.forEach(CA => {
            if (CA.childSubCategories) {
              der_childSubCategories = der_childSubCategories.concat(CA.childSubCategories);
            }
          });
        }
      }
      if (der_childSubCategories && der_childSubCategories.length > 0) {
        der_childSubCategories = _.uniqBy(der_childSubCategories, 'id');
        let newcatarrDD: any[] = list[index + 1].value.OrgcatArr.filter(x => {
          return _.find(der_childSubCategories, ['id', x.id]);
        })
        let newselectedcatarrDD: any[] = list[index + 1].value.selectedCat.filter(x => {
          return _.find(der_childSubCategories, ['id', x.id]);
        })
        list[index + 1].controls.catArr.setValue(newcatarrDD);
         
        let checkallselected = list[index + 1].controls.selectedCat.value.indexOf(0)

        if (checkallselected != -1) {
          newselectedcatarrDD = newselectedcatarrDD.concat(0);
          list[index + 1].controls.selectedCat.setValue(newselectedcatarrDD);
        } else if (index >= ind) {
          list[index + 1].controls.selectedCat.setValue(newcatarrDD);
        } else {
          list[index + 1].controls.selectedCat.setValue(newselectedcatarrDD);
        }
      } else {
        if (list[index + 1]) {
          list[index + 1].controls.catArr.setValue([]);
          list[index + 1].controls.selectedCat.setValue([]);
        }
      }
    });
  }

  resetcatarr() {
    this.categoryForm.controls.categoriesData['controls'].forEach((element: UntypedFormGroup) => {
      element.controls.catArr.setValue(element.controls.OrgcatArr.value);
    });
  }

}
