import { Component, OnInit } from '@angular/core';
import { RetailLocalization } from '../common/localization/retail-localization';
import { enhancedInventoryMaster,Outlet,InventorySyncParams, InventorySyncIdType} from '../retail.modals';
import { RetailOutletsDataService } from '../retail-code-setup/retail-outlets/retail-outlets-data.service';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';
import { Tenant } from 'src/app/common/Models/property-information.model';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { HttpMethod } from '../shared/service/http-call.service';
import { Host } from '../shared/globalsContant';
import { RetailRoutes } from '../retail-route';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { AlertType } from '../shared/shared.modal';
import { ButtonType } from '../shared/globalsContant';
import { MatDialog } from '@angular/material/dialog';
import { UseInventoryPopupComponent } from './use-inventory-popup/use-inventory-popup.component';
@Component({
  selector: 'app-enahanced-inventory-master-sync',
  templateUrl: './enahanced-inventory-master-sync.component.html',
  styleUrls: ['./enahanced-inventory-master-sync.component.scss'],
  providers: [ RetailOutletsDataService,
    PropertyInformationDataService
  ]
})
export class EnahancedInventoryMasterSyncComponent implements OnInit {
  captions;
  alertcaptions;
  enhancedInventoryMaster: enhancedInventoryMaster;
  isTenantSet;
  isPropertySet;
  isOutletSet: any[] = [];
  isOutletItemSet: any[] = [];
  isOutletSetVendor;
  exportArray: InventorySyncIdType[] = [];
  outlet: Outlet[];
  tenantInfo:Tenant;
  tenantId:number;
  InventorySyncParams: InventorySyncIdType[] = [];
  constructor(public localization: RetailLocalization,public outletData: RetailOutletsDataService, public propertyInfoService: PropertyInformationDataService,
     public utils: RetailUtilities, private retailService: RetailSetupService, public dialog: MatDialog) {
    this.captions = this.localization.captions.utilities;
  }

  async ngOnInit(){
    this.utils.ToggleLoader(true,this.localization.captions.lbl_processing);
    this.outlet = await this.outletData.getOutlets();
    this.outlet = this.outlet.filter(x => x.isActive == true);
    this.tenantInfo= await this.propertyInfoService.GetTenantByTenantId(Number(this.localization.GetPropertyInfo('TenantId')));
    this.enhancedInventoryMaster = {
      TenantId:Number(this.localization.GetPropertyInfo('TenantId')),
      TenantName: this.tenantInfo.tenantName,
      Properties : {
        PropertyId : Number(this.localization.GetPropertyInfo('PropertyId')),
        PropertyName : this.localization.GetPropertyInfo('PropertyName'),
        Outlets: this.outlet
      }

    };
    this.utils.ToggleLoader(false);
  }

  setObject(checked, type, id ) {
    if (checked) {
      this.exportArray.push({ id : String(id) , type : type } );
    } else {
      let spliceIndex = this.exportArray.findIndex(x => x.id == id && x.type == type);
      this.exportArray.splice(spliceIndex, 1);
    }

  }

 async onSync() {
  await this.retailService.InvokeServiceCallAsync(RetailRoutes.InventoryStatus,Host.retailManagement,HttpMethod.Get).then(async res=>{
    if(res.result)
    {
      this.utils.showAlert(this.captions.syncWarning,  AlertType.Warning, ButtonType.Ok,(res) => {
        if (res) {
          this.resetCheckBoxes();
          return;
        }
      });
    }
    else{
     this.InventorySyncParams=  this.exportArray;
    console.log(this.InventorySyncParams);
    await this.retailService.InvokeServiceCallAsync(RetailRoutes.InventorySync,Host.retailManagement,HttpMethod.Post,this.InventorySyncParams).then(res=>{
      if(res.result)
      {
        this.utils.showAlert(this.captions.syncSuccess,  AlertType.Success, ButtonType.Ok,(res) => {
          if (res) {
            this.resetCheckBoxes();
            return;
          }
        });
      }
    });
    }
  });
 
  }
  
  onCancel() {
   this.resetCheckBoxes();
  }

  resetCheckBoxes() {
    this.isTenantSet = false;
    this.isPropertySet = false;
    this.isOutletSetVendor = false;
    this.isOutletSet =[];
    this.isOutletItemSet = [];
    // This loop is to have ngModel set as false by default for all the checkboxes running in the loop
    if (this.enhancedInventoryMaster && this.enhancedInventoryMaster.Properties && this.enhancedInventoryMaster.Properties.Outlets && this.enhancedInventoryMaster.Properties.Outlets.length > 0) {
      for (let index = 0; index < this.enhancedInventoryMaster.Properties.Outlets.length; index++) {
        this.isOutletSet.push(false);
        this.isOutletItemSet.push(false);
      }
    }
    this.exportArray = [];
  }

  useInventory(eve) {
    this.dialog.open(UseInventoryPopupComponent, {
      height: '80%',
      width: '95%',
      panelClass: 'small-popup',
      // data: {
      //   discountInput: this.discountInput,
      //   miscSetting: this.miscSetting,
      // },
      disableClose: true
    }).afterClosed().subscribe(result => {
      // this.searchTextBarCode.focus()
    });
  }

}
