import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const GetCustomPriceCalculation: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: TransactionConfiguration
): any => {
    try {
        if (FieldGrouping.Name === 'Price') {
            const Price = FormGroupData.controls['Price'].value ? parseFloat(FormGroupData.controls['Price'].value.toString()) : 0;
            const ExchangeRate = FormGroupData.controls['ExchangeRate'].value ?
                 parseFloat(FormGroupData.controls['ExchangeRate'].value.toString()) : 0;
            const PriceInFc = parseFloat((Price * ExchangeRate).toString()).toFixed(4);
            FormGroupData.controls['PriceInFC'].setValue(PriceInFc);
            CommonService.triggerBlurEvent('PriceInFC');
            const Conversion =  FormGroupData.controls['Conversion'].value ? parseFloat( FormGroupData.controls['Conversion'].value.toString()) : 0;
            const Normalized = Price > 0 && Conversion > 0 ? parseFloat((Price / Conversion).toString()).toFixed(4) : 0;
            FormGroupData.controls['Normalized'].setValue(Normalized);
            CommonService.triggerBlurEvent('Normalized');
        } else if (FieldGrouping.Name === 'PriceInFC') {
            const PriceInFC = FormGroupData.controls['PriceInFC'].value ? parseFloat(FormGroupData.controls['PriceInFC'].value.toString()) : 0;
            const ExchangeRate = FormGroupData.controls['ExchangeRate'].value ?
                 parseFloat(FormGroupData.controls['ExchangeRate'].value.toString()) : 0;
            const Price = PriceInFC > 0 && ExchangeRate > 0 ? parseFloat((PriceInFC / ExchangeRate).toString()).toFixed(4) : 0;
            FormGroupData.controls['Price'].setValue(Price);
            CommonService.triggerBlurEvent('Price');
        } else if (FieldGrouping.Name === 'ExchangeRate') {
            const ExchangeRate = FormGroupData.controls['ExchangeRate'].value ?
            parseFloat(FormGroupData.controls['ExchangeRate'].value.toString()) : 0;
            const Price = FormGroupData.controls['Price'].value ? parseFloat(FormGroupData.controls['Price'].value.toString()) : 0;
            const PriceInFC = parseFloat((Price * ExchangeRate).toString()).toFixed(4);
            FormGroupData.controls['PriceInFC'].setValue(PriceInFC);
            CommonService.triggerBlurEvent('PriceInFC');
        } else if (FieldGrouping.Name === 'Conversion') {
            const Price = FormGroupData.controls['Price'].value ? parseFloat(FormGroupData.controls['Price'].value.toString()) : 0;
            const Conversion =  FormGroupData.controls['Conversion'].value ? parseFloat( FormGroupData.controls['Conversion'].value.toString()) : 0;
            const Normalized = Price > 0 && Conversion > 0 ? parseFloat((Price / Conversion).toString()).toFixed(4) : 0;
            FormGroupData.controls['Normalized'].setValue(Normalized);
            CommonService.triggerBlurEvent('Normalized');
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};