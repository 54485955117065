import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomPrefillPO: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: string
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            const SupplierId = FormGroupData.value[FieldGrouping.CustomFormAction.CutomFieldFrom];
            if (FormField.length !== MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields.length) {
                FormField = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields;
            }
            const EndUrl = `/inventory/api/Suppliers?$filter=Id eq ${SupplierId}`;
            const Supplier = FormGroupData.value.SupplierId;
                httpService.GethttpMethod(EndUrl).subscribe(response => {
                    const supplierResult = response['value'].filter(x=>x.Id == Supplier);
                    console.log('Response', supplierResult);
                    if (CustomData !== 'false') {
                        FormGroupData.controls['PayTermId'].setValue(supplierResult.PayTermId? supplierResult.PayTermId : 1);
                        const CurrencyList : any = JSON.parse(sessionStorage.getItem('CurrencyList'));
                        const PropertyCurrencyCode = sessionStorage.getItem('LocalCurrencyCode');
                        const BaseCurrencyId = CurrencyList.filter(x=>x.Code == PropertyCurrencyCode)[0]?.Id;
                        sessionStorage.setItem('BaseCurrency', (BaseCurrencyId ? BaseCurrencyId : 164));
                        FormGroupData.controls['TransactionCurrencyId'].setValue(supplierResult.CurrencyId ? supplierResult.CurrencyId : BaseCurrencyId);
                        const Data = MasterDataCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.
                            filter(x => x.OdataKey === 'PayTermId')[0];
                        CustomFormField.CustomDueDateSet(
                            ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                            Data, FormGroupData, FormField, CustomData);
                    }                   
                    switch (supplierResult[0].RNIOptionId) {
                        case 1:
                            FormGroupData.controls['IsInvoiced'].setValue(true);
                            break;
                        case 2:
                            FormGroupData.controls['IsInvoiced'].setValue(false);
                            break;
                        case 3:
                            FormField[12].fieldType.SingleData.disbaledProperty = false;
                            break;
                        case null:
                            FormGroupData.controls['IsInvoiced'].setValue(true);
                            // FormField[12].fieldType.SingleData.disbaledProperty = false;
                            break;
                    }                    
                    const TransactionCurrency = MasterDataCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.
                            filter(x => x.OdataKey === 'TransactionCurrencyId')[0];
                    CustomFormField.GetCustomExchangeRate(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        TransactionCurrency, FormGroupData, FormField, CustomData);
                });
            // const LocIds =  JSON.parse(sessionStorage.getItem('FromLocId'));
            // const EndPointUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}
            // ?$filter=POHeadLocationId/Id in (${LocIds.join()}) and TransactionStatusId eq 1 and SupplierId eq ${SupplierId}`;
            // httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                // console.log('Supplier based po filter', res);
            if (MasterDataCollection.OdataKey === 'ReceivingHead') { 
                FormField[2].fieldType.SingleData.disbaledProperty = true;
                FormField[0].fieldType.SingleData.disbaledProperty = false;
                FormField[1].fieldType.SingleData.disbaledProperty = false;
            } else {
                FormField[0].fieldType.SingleData.disbaledProperty = true;
                FormField[1].fieldType.SingleData.disbaledProperty = false;
                FormField[2].fieldType.SingleData.disbaledProperty = false;
            }   
            FormField[3].fieldType.SingleData.disbaledProperty = false;
            FormField[4].fieldType.SingleData.disbaledProperty = false;
            FormField[5].fieldType.SingleData.disbaledProperty = false;
            FormField[7].fieldType.SingleData.disbaledProperty = false;
            FormField[8].fieldType.SingleData.disbaledProperty = false;
            FormField[9].fieldType.SingleData.disbaledProperty = false;
            // });
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};