import { Injectable } from "@angular/core";
import { API } from "src/app/common/Models/printer-default-configuration.model";
import { CommonApiRoutes } from "../common-route";
import { TenantManagementCommunication } from "../communication/services/tenantmanagement-communication-service";


@Injectable({providedIn:"root"})
export class MachinePrinterConfigDataService{
    constructor(private  _http: TenantManagementCommunication){
    }
    public getMachinePrinterConfigurationByMachineId(machineId : number):Promise<API.MachinePrinterConfiguration[]>{
        return this._http.getPromise({
            route: CommonApiRoutes.GetMachinePrinterConfigurationByMachineId,           
            uriParams: {'machineId' : machineId}
        })
    } 
    public getMachinePrinterConfiguration():Promise<API.MachinePrinterConfiguration[]>{
        return this._http.getPromise({
            route: CommonApiRoutes.GetMachinePrinterConfiguration,           
        })
    } 
    public CreateMachinePrinterConfiguration(machinePrinterConfiguration : API.MachinePrinterConfiguration[]):Promise<Boolean>{
        return this._http.postPromise({
            route: CommonApiRoutes.CreateMachinePrinterConfiguration,           
            body: machinePrinterConfiguration
        })
    }
}