import { registerEvent, unregisterEvent } from "../globalsContant";
import { Injectable, OnDestroy } from "@angular/core";
@Injectable()
export abstract class rGuestBaseService implements OnDestroy {


    // Array of BaseService (reset method)
    // All service extends BaseService
    // All service constructor should call register and register itself
    // The register method in the baseservice should maintain the registered services in an array
    // While logging out the baseservice's clear data method will be called
    // This will loop through al registered services and call the rest method in it

    constructor() {
        this.registerService(this);
    }

    ngOnDestroy() {
        this.unregisterService(this);
    }

    abstract reset(): void;

    private registerService(service: rGuestBaseService): void {
        registerEvent.emit(service);
    }

    private unregisterService(service: rGuestBaseService): void {
        unregisterEvent.emit(service);
    }

}


@Injectable()
export class ServiceRegistry {

    private ServiceArray: rGuestBaseService[] = [];
    constructor() {
        registerEvent.subscribe((x: rGuestBaseService) => this.registerService(x));
        unregisterEvent.subscribe((x: rGuestBaseService) => this.unregisterService(x));
    }

    public resetAllServiceData(): void {
        if (this.ServiceArray && this.ServiceArray.length > 0) {
            for (var service of this.ServiceArray) {
                if (service.reset)
                    service.reset();
            }
        }
    }

    private registerService(service: rGuestBaseService) {
        this.ServiceArray.push(service);
    }

    private unregisterService(service: rGuestBaseService) {
        var index = this.ServiceArray.indexOf(service);
        if (index >= 0) {
            this.ServiceArray.splice(index, 1);
        }
    }

}

