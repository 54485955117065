import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';


export const setSubType: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            const fromFieldValue = FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value ? 
                     parseFloat(FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value) : 0;
            let catergoryName = '';         
            MasterDataCollection.NestedFormConfig.EditFormConfig.map(function(Resultset) {  
               Resultset.NestedForm.filter(x => x.FormFields.filter(y => {
                  if (y.fieldType && y.fieldType.SingleData) {
                      if (y.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldFrom) {
                        catergoryName = y.fieldType.SingleData.options
                        .filter(opt => opt.key === fromFieldValue)[0]?.label;
                      }
                   }
                 }));
            }); 
            const EndPointUrl =  `/inventory/api/${FieldGrouping.CustomFormAction.Url}?$filter= RecipeTypeId eq ` 
            + fromFieldValue;
            httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                const responseData = res['value'];
                const listData = responseData.map(person => ({ key: person.Id, label: person.Name + ' - ' + catergoryName }));
                MasterDataCollection.NestedFormConfig.EditFormConfig.map(function(Resultset) {  
                Resultset.NestedForm.filter(x => x.FormFields.filter(y => {
                   if (y.fieldType && y.fieldType.SingleData) {
                       if (y.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo) {
                        y.fieldType.SingleData.options = listData;
                        y.fieldType.SingleData.disbaledProperty = false;
                       }
                    }
                  }));
               }); 
            });
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

