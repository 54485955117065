import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { ChangeRestrictionConfig } from './custom-form-configuration.interface';
import { TranslateService } from '@ngx-translate/core';

export const LocationChangeRestriction: ChangeRestrictionConfig = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: any, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, createTransactionService: any, MatDialog: MatDialog, translateService: TranslateService): any => {
        try {
            if (FieldGrouping.CustomFormAction && MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData && 
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0) { 
                const clonedObject = JSON.stringify(createTransactionService?.prefiledlistObject);               
                const confirmPopModel = {} as ConfirmPopModel;
                confirmPopModel.PopUpContent = translateService.instant('PhysicalHead.FieldsChangePopupContent');
                confirmPopModel.PopupHeaderContent = translateService.instant('PhysicalHead.Confirmation');
                const dialogRefs = MatDialog?.open(AgilysysConfirmationPopupComponent, {
                  width: '440px',
                  height: '220px',
                  data: confirmPopModel,
                  autoFocus: false,
                  disableClose: true
                });
                dialogRefs?.afterClosed().subscribe(res => {
                    if (res === 'Cancel' && clonedObject) {
                       const parsedObject = JSON.parse(clonedObject);
                       FormGroupData.controls[FieldGrouping.OdataKey].setValue(parsedObject[FieldGrouping.OdataKey]);
                       FormGroupData.updateValueAndValidity();
                    } else if (res === 'Confirm') {
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = 
                          [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = [];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = 
                        [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard = 
                        {...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard};
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = 
                          {...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig};
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true);                          
                    }
                });
            }
        } catch (error) {
            console.log('Error Occurred', error);
        }
    };
