import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'lib-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CurrencyDropdownComponent implements OnInit {

  params: any;
  value: any;
  defaultValue: number;
  dropdown: FormControl = new FormControl('');
  dropdownOptions: any;
  updateProperty: string;
  fieldName: string;

  constructor() { }

  ngOnInit(): void {
  }
  agInit(params) {
    // console.log('Dropdown Editor', params);
    this.defaultValue = params.data[params.UpdatePropertyId];
    this.updateProperty = params.UpdatePropertyId;
    this.fieldName = params.colDef.field;
    const dropdownField = params.DropdownListName;
    this.params = params;
    this.dropdown.setValue(this.defaultValue);
    this.dropdownOptions = this.params.data[dropdownField];
  }

  init(params) {
    this.dropdown.valueChanges.subscribe(res => {
      this.defaultValue = parseInt(res, 10);
      const rowNode = this.params.api.getRowNode(this.params.rowIndex);
      rowNode.setDataValue(this.updateProperty, this.defaultValue);
    });
  }

  dropdownChangeEvent = (event) => {
    // console.log('Dropdown Change Event', event);
    this.params.context.parentComponent.selectedColumn = this.params.colDef.field;
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    this.defaultValue = parseInt(event.target.value, 10);
    this.params.data[this.updateProperty] = this.defaultValue;
    if (this.dropdownOptions.filter(x => x.Id === this.defaultValue).length > 0) {
      this.params.data[this.fieldName] = this.dropdownOptions.filter(x => x.Id === this.defaultValue)[0].Name;
    }
    rowNode.setDataValue(this.updateProperty, this.defaultValue);
    rowNode.setDataValue(this.fieldName, this.params.data[this.fieldName]);
  }

  getValue(): any {
    if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
    }
    if (this.dropdownOptions && this.dropdownOptions.length > 0 &&
      this.dropdownOptions.filter(x => x.Id === this.defaultValue).length > 0) {
      const returnValue = this.dropdownOptions.filter(x => x.Id === this.defaultValue)[0].Name;
      return returnValue;
    }
  }

  focusIn() {
    const selectEditor = document.getElementById('currencyEditor');
    if (selectEditor) {
      selectEditor.focus();
    }
  }
  
  afterGuiAttached() {
    try {
      const currencyEditor = document.getElementById('currencyEditor');
      if (currencyEditor && (this.params.context.parentComponent.selectedColumn === this.params.colDef.field)) {
            currencyEditor.focus();
      }
    } catch (error) {
      console.error('Error occurred in number cell editor component of afterGuiAttached function', error);
    }
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  keyDown(event, params: ICellEditorParams) {
    // console.log('key down', event);
    if (event.key === KEY_ESCAPE) {
      this.params.api.stopEditing();
    }
  }

}

const KEY_ESCAPE = 'Escape';