import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import _ from 'lodash';
import { ZeroQty } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { Validation } from 'src/app/eatecui/source/shared/utilities/data.validator';
export const ZeroQtyTransaction: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType ?: any,
    ModuleKey ?: string,
    dialog ?: MatDialog,
    transactionCollection ?: TransactionConfiguration,
    homeGroup ?: FormGroup
): any => {
    try {
        return new Promise<boolean>((resolve, reject) => {
        if ( GlobalPreference && GlobalPreference.length > 0 && homeGroup) {
            const receivingZeroQtyGlobalPreference = GlobalPreference.filter(x => x.LookupName === PrferenceType);
            if ( receivingZeroQtyGlobalPreference && receivingZeroQtyGlobalPreference.length > 0 ) {
                const prZeroQty =  receivingZeroQtyGlobalPreference[0];
                if (prZeroQty.LookupValue === ZeroQty.NoZeroQty) {
                    const filteredGridData: Array<any>  = transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList
                        .ItemGridConfig.GridData.filter(x => new Validation.NullDataValidator().isCheck(x.Qty));
                        if ( filteredGridData.length > 0  ) {
                            transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
                             [...filteredGridData];
                            resolve(true);
                        } else {
                            const receivingZeroQuantity = {} as ErrorPopModel;
                            receivingZeroQuantity.PopupHeaderContent = 'Info';
                            receivingZeroQuantity.PopUpContent = 'Must enter at least one item Qty greater than zero';
                            dialog.open(AgilsysErrorPopComponent, {
                                width: '400px',
                                data: receivingZeroQuantity,
                                disableClose: true
                              });
                            reject(false);
                        }
                } else {
                    resolve(true);
                }
            }
        }
        });
    } catch (error) {
        console.error(error);
    }
};