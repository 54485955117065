import { Formatter } from 'src/app/eatecui/source/shared/models/formater.interface';

export const NumberFormater: Formatter = (gridData: any): any => {
    try {
        if (gridData.value) {
            const returnValue = gridData.value;
            return returnValue;
        } else {
            return '';
        }
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  };
