import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Input, AfterViewInit, OnChanges, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalendarDayConfig, CalendarEventsConfig } from 'src/app/eatecui/source/shared/models/calender-widget.interface';
import moment from 'moment';

@Directive({ 
     selector: '[appCalendar]'
})
export class AppCalendarDirective implements AfterViewInit {
    @Input() CalendarCellData: CalendarDayConfig;
    @Input() CalendarEventData: Array<CalendarEventsConfig>;
    @Input() calendarMode: string;
    constructor( private elRef: ElementRef,  private route: ActivatedRoute, private renderer: Renderer2) { 
    }
    /**
     * @method constractMonthModecell
     * @input None
     * @description contract the month mode data cell and data will be there 
     */
    constractMonthModecell(): void {
        try{
            const dateLabel = this.renderer.createElement('label');
            const calendarDate = this.renderer.createText(`${this.CalendarCellData.CalendarDate}`);
            this.renderer.appendChild(dateLabel, calendarDate);

           this.CalendarEventData.forEach((calendarConfig: CalendarEventsConfig)=> {
               const eventDate = moment(calendarConfig.EventDate).format('MM/DD/YYYY');
               const CalendarDate = moment(this.CalendarCellData.CalendarFullDate).format('MM/DD/YYYY');
               const isSameDate: boolean = moment(eventDate).isSame(CalendarDate);
               if ( isSameDate ){
                const calendarDiv = this.renderer.createElement('div');
                const calendarSpan = this.renderer.createElement('div');
                const spanText = this.renderer.createText('Valued');
                const text = this.renderer.createText(`${calendarConfig.EventDescription}`);
                this.renderer.addClass(calendarDiv, 'event-day-cell');
                this.renderer.addClass(calendarDiv, 'product-body-2');
                this.renderer.appendChild(calendarDiv, text);
                this.renderer.appendChild(calendarSpan, spanText);
                this.renderer.addClass(calendarSpan, 'span-event-day-cell');
                this.renderer.setAttribute(calendarSpan, 'matMenuTriggerFor', 'menu')
                 calendarSpan.innerHTML = '<mat-menu #menu="matMenu">t</mat-menu>'
                this.renderer.appendChild(calendarDiv, calendarSpan);
                this.renderer.appendChild(this.elRef.nativeElement, calendarDiv);
                 
               }
           });

        } catch(error){
            console.error(error);
        }
    }
    ngAfterViewInit(): void {
         if( this.calendarMode === 'Month'){
             this.constractMonthModecell();   
         }
    }
}