import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ConfirmPopModel } from '../model/pop-up.interface';
@Component({
  selector: 'app-agilysys-partial-transfer',
  templateUrl: './agilysys-partial-transfer.component.html',
  styleUrls: ['./agilysys-partial-transfer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysPartialTransferComponent {
  agilysysCofirmationButton: AgilysysButtonModel[];
  constructor(public dialogRef: MatDialogRef<AgilysysPartialTransferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPopModel) {
    this.agilysysCofirmationButton = [
      {
        // Name: 'cancel',
        // DisplayName: 'cancel',
        // Class: 'agilysys-button-secondary',
        // DisableProperity: false,
        // onCellClick: (returnValue: AgilysysButtonModel) => {
        //    this.dialogRef.close('Cancel');
        // }
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        // Name: 'Confirm',
        // DisplayName: 'Confirm',
        // Class: 'agilysys-button-primary',
        // DisableProperity: false,
        // onCellClick: (returnValue: AgilysysButtonModel) => {
        //    this.dialogRef.close('Confirm');
        // }
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      }
    ];
   }
}
