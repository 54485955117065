import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { AddPreferenceConfig } from './system-preference-configuration';


export const MaxLengthSystemPreference: AddPreferenceConfig = (
sysytemPreferenceService: SysytemPreferenceService, GroupName, LookupName, LookupValue
): any => {
    try {
        let preferenceValue = null;
        const GlobalPreference: Array<GlobalSystemPreference> = sysytemPreferenceService.globalSystemPreference
        .filter((x: GlobalSystemPreference) => (x.GroupName === GroupName && x.LookupName === LookupName ));
        if ( GlobalPreference && GlobalPreference.length > 0 ) {
            preferenceValue = GlobalPreference[0][LookupValue];
        }
        return preferenceValue; 
       
    } catch (error) {
        console.error(error);
    }
};