import { Directive, ElementRef, HostListener,  Input } from '@angular/core';

@Directive({
  selector: '[LimitExceed]'
})
export class LimitExceedDirective   {

  constructor(public el: ElementRef) { }
 
  @Input() numUpto;
  @HostListener('keydown', ['$event']) keydown($event: KeyboardEvent) {
    let topNum = this.numUpto ? this.numUpto : 100 ;

    let data = this.el.nativeElement.value+$event.key;
    if(parseInt(data) < 0  || parseInt(data) > topNum){
      $event.preventDefault();
    }else{
      return;
    }
  }


}
