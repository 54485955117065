import { Component } from '@angular/core';

@Component({
  selector: 'app-report-pos',
  templateUrl: './report-pos.component.html',
  styleUrls: ['./report-pos.component.scss']
})
export class ReportPosComponent  {


}
