import { Pipe, PipeTransform } from '@angular/core';
import { SpaLocalization } from '../../core/localization/spa-localization';

 
@Pipe({
  name: 'LocalizeTimePipes'
})
export class LocalizeTimePipesPipe implements PipeTransform {
  constructor(public localization: SpaLocalization) { }
  transform(inputArray: any) {
    return this.localization.LocalizeTime(inputArray)
  }
}
