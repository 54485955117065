import { Injectable } from "@angular/core";
import { DataMagineIntegrationDataService } from "../../dataservices/data-magine-integration.data.service";
import { Localization } from 'src/app/common/localization/localization';
@Injectable()
export class SearchTemplateBusiness {
  captions: any;
  constructor(private localization: Localization, private DMdataService: DataMagineIntegrationDataService) {
    this.captions = this.localization.captions;

  }

  public async getDMToken(authenticationUrl: string, clientID: string, clientSecret: string) {
    return await this.DMdataService.getDataMagineToken({ authURL: authenticationUrl, clientID, clientSecret });
  }

  public async getEformsDropDownList(token: string, DMEFormsListURL: string, tenantId: string, property: string) {
    let res: any[] = []
    let eforms = await this.DMdataService.getEforms(token, DMEFormsListURL, tenantId, property);
    if (eforms && eforms.errorMessage == null) {
      eforms.result.map(x => {
        res.push({
          id: x.eFormId,
          label: x.formBusiness
        });
      });
    }
    return res;
  }
}