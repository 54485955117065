import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponenet } from './reports.component';
import { ReportAppointmentComponent } from './report-appointment/report-appointment.component';
import { ReportRetailComponent } from './report-retail/report-retail.component';
import { ReportClientComponent } from './report-client/report-client.component';
import { ReportPosComponent } from './report-pos/report-pos.component';
import { ReportGiftcardsComponent } from './report-giftcards/report-giftcards.component';
import { ReportTransactionLogComponent } from './report-transaction-log/report-transaction-log.component';
import { RouteGuardService } from '../core/services/route-gaurd.service';
import { ReportBreakPoint } from '../shared/globalsContant';
import { ReportSecurityComponent } from './report-security/report-security.component';
import { ReportMemberComponent } from './report-member/report-member.component';
import { ReportInventorycontrolComponent } from './report-inventorycontrol/report-inventorycontrol.component';
import { QuerybuilderComponent } from './querybuilder/querybuilder.component';

const routes: Routes = [{
  path: '', component: ReportsComponenet,
  children: [
    { path: '', redirectTo: 'appointment', pathMatch: 'full' },
    {
      path: 'appointment',
      component: ReportAppointmentComponent
    },
    {
      path:  'retail',
      component: ReportRetailComponent
    },
    {
      path: 'client',
      component: ReportClientComponent
    },
    {
      path: 'commission',
      component: ReportPosComponent
    },
    {
      path: 'transactionlog',
      component: ReportTransactionLogComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: ReportBreakPoint.TransactionLog, redirectTo: '/reports/giftcards' }
    },
    {
      path: 'giftcards',
      component: ReportGiftcardsComponent
    },
    {
      path: 'inventorycontrol',
      component: ReportInventorycontrolComponent
    },
    {
      path: 'security',
      component: ReportSecurityComponent    
    },
    {
      path: 'member',
      component: ReportMemberComponent  
    },
    { path: 'querybuilder', component: QuerybuilderComponent }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
