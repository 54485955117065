import { Action } from '@ngrx/store';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

export enum ETransaction {
   GetTransaction = '[GetTransaction] Get GetTransaction',
   GetMasterManagement = '[GetMasterManagement] Get GetMasterManagement',
   EnableTranctionAction = '[EnableTranction]',
   EnableMasterManagement = '[EnableMasterManagement]'
}

export class GetTransaction implements Action {
    readonly type = ETransaction.GetTransaction;
    constructor(public payload: TransactionConfiguration) { }
}
export class EnableTranction implements Action {
    readonly type = ETransaction.EnableTranctionAction;
    constructor(public payload: boolean) { }
}
export class GetMasterManagement implements Action {
    readonly type = ETransaction.GetMasterManagement;
    constructor(public payload: MasterInventoryManagement) { }
}

export class EnableMasterManagement implements Action {
    readonly type = ETransaction.EnableMasterManagement;
    constructor(public payload: boolean) { }
}

export type TransactionAction = GetTransaction | EnableTranction | GetMasterManagement | EnableMasterManagement;