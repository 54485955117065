import { BaseReport } from "../common/base-report";
import {  ReportParams,  ReportAPIOptions, ReportUIConfig, AllReports, DropDownData } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";

/**
 * Appointment By Location Report Configuration
 * @export
 * @class AppointmentByLocation
 * @extends {BaseReport}
 */
export class AppointmentByLocation extends BaseReport {


    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public patcheableValue) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.AppointmentListingByLocation,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

     getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            //this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
         return {
             startDatePicker: true,
             endDatePicker: true,
             dropDownFilters: this.data.getAllLocation(),
             dropDownFilterName: this.captions.Locations,
             inActiveToggle: false,
             inActiveToggleName: "",
             pageBreakToggle: true,
             layout: 'PORTRAIT'
         };
    }

    formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle },{"pFilterCaption": this.getFilterCaption},
        {"pPropertyName": this.propertyName}, {"pDate": this.printedDate},
        { pShowComment: this.reportFormGrp.value.customFilterFormGrp.ExcludeAppointmentComments },
        { pExcludeComment: this.reportFormGrp.value.customFilterFormGrp.ExcludeAppointmentComments ? this.captions.Yes : this.captions.No }
    ];
    }

    formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }];
    }

    formFilters(): any {
        let selectedLocations:DropDownData[] = this.selectedFilterOptions;
		if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
			let obj = {
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement,
                StartDate:(this.formGrp.get('startDate').value),
                EndDate: (this.formGrp.get('endDate').value),
                Data: selectedLocations.map(s=>s.id)
            };
            return obj;
        }
        return selectedLocations.map(s=>s.id);
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }
}
