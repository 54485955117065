import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-color-link',
  templateUrl: './color-link.component.html',
  styleUrls: ['./color-link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColorLinkComponent implements OnInit {
  linkForm: UntypedFormGroup;
  colorSelected = "#FFFFFF";
  minDate: Date = this.PropertyInfo.CurrentDate;
  minEndDate: Date = this.PropertyInfo.CurrentDate;
  blnReadOnly: boolean;
  captions: any = this.localization.captions.setting;
  common: any = this.localization.captions.utilities;
  clickbutton: any = this.common.save;
  maxListOrder: number;
  isUserAuthorized = true;
  isViewOnly = true;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(public fb: UntypedFormBuilder,
    private vsBusiness: ViewSettingClientBusiness,
    private BP: BreakPointAccess, private http: HttpServiceCall,
    public dialogRef: MatDialogRef<SettingDialogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private utils: SpaUtilities,
    public localization: SpaLocalization,
    private PropertyInfo: SpaPropertyInformation) {
      this.placeholderFormat = this.localization.inputDateFormat;
      this.commonCaptions = this.localization.captions.common;
      this.floatLabel = this.localization.setFloatLabel;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingColorLinking]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingColorLinking);
    if (this.isViewOnly) {
      this.utils.disableControls(this.linkForm);
    }
  }
  createLinkCode():void {
    const startDate = this.utils.formatDate(this.linkForm.controls.startDate.value);
    const endDate = this.utils.formatDate(this.linkForm.controls.endDate.value);
    const linkcodeObj: any = {
      Code: this.linkForm.controls.code.value,
      Description: this.linkForm.controls.description.value,
      StartDate: startDate,
      EndDate: endDate,
      ListOrder: this.linkForm.controls.listOrder.value == "" ? 0 : this.linkForm.controls.listOrder.value,
      Color: this.colorSelected
    };
    try {
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "CreateLinkCode",
        method: HttpMethod.Post,
        body: linkcodeObj,
        showError: true,
        extraParams: []
      });
    }
    finally{
      this.saveButton.disabledproperty = false;
    }
  }

  GetLinkCodeDetail(id: any): any {
    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetLinkCode",
      method: HttpMethod.Get,
      uriParams: { id: id },
      showError: true,
      extraParams: []
    });
  }

  updateLinkCode():void{
    let patchJson = [];
    const startDate = this.utils.formatDate(this.linkForm.controls.startDate.value);
    const endDate = this.utils.formatDate(this.linkForm.controls.endDate.value);
    patchJson = [{ op: "replace", path: "/description", value: this.linkForm.controls.description.value },
    { op: "replace", path: "/code", value: this.linkForm.controls.code.value },
    { op: "replace", path: "/startDate", value: startDate },
    { op: "replace", path: "/endDate", value: endDate },
    { op: "replace", path: "/listOrder", value: this.linkForm.controls.listOrder.value },
    { op: "replace", path: "/color", value: this.colorSelected }];
    try{
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "UpdateLinkCode",
        method: HttpMethod.Patch,
        uriParams: { id: this.data.datarecord.id },
        body: patchJson,
        showError: true,
        extraParams: []
      });
    }
    finally{
      this.saveButton.disabledproperty = false;
    }
  }

  changeColor($event:any):void{
    this.colorSelected = $event.color.hex;
    this.linkForm.markAsDirty();
    this.enableSave();
  }

  saveInfo():void{
    setTimeout(() => {
      this.saveButton.disabledproperty = true;
    },1);
    if (this.clickbutton == this.common.save) {
      this.createLinkCode();
    } else if (this.clickbutton == this.common.update) {
      this.updateLinkCode();
    }
  }

  close():void{
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  SetMinimumDate():void{
    this.minEndDate =  this.linkForm.controls.startDate.value;
  }

  ngOnInit() {
    this.linkForm = this.fb.group({
      code:['',Validators.required],
      description:['',[Validators.required, EmptyValueValidator]],
      startDate: ['',Validators.required],
      endDate: ['',Validators.required],
      color:[''],
      listOrder:['']
    });
    this.saveButton = {
      type: 'primary',
      label: this.data.popupConfig.operation == "edit" ? this.common.update : this.common.save,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.common.cancel,
    }
    this.linkForm.controls.startDate.setValue(this.PropertyInfo.CurrentDate);
    this.linkForm.controls.endDate.setValue(this.PropertyInfo.CurrentDate);
    this.maxListOrder = this.data.popupConfig.maxListOrder;
    this.linkForm.controls.listOrder.setValue(this.maxListOrder);
    this.linkForm.controls.color.setValue(this.colorSelected);
    if (this.data.popupConfig.operation == "edit") {
      this.GetLinkCodeDetail(this.data.datarecord.id)
      this.clickbutton = this.common.update;
     }
     this.vsBusiness.activeFormGroup = this.linkForm;
    this.ValidateBreakPoint();
    this.blnReadOnly = false;
    if (this.data.popupConfig.operation == "edit") {
      this.blnReadOnly = true;
      this.minEndDate = this.linkForm.controls.startDate.value;
    }
    this.linkForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.enableSave();
    });
  }
  enableSave(){
    this.saveButton.disabledproperty = !(this.linkForm.valid && this.linkForm.dirty);
    this.saveButton = { ...this.saveButton };
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateLinkCode" || callDesc == "UpdateLinkCode") {
      this.dialogRef.close();
    } else if (callDesc == "GetLinkCode") {
      const resultData = <any>result.result;
      this.linkForm.controls.code.setValue(resultData.code);
      this.linkForm.controls.description.setValue(resultData.description);
      this.linkForm.controls.startDate.setValue(this.localization.getDate(resultData.startDate));
      this.linkForm.controls.endDate.setValue(this.localization.getDate(resultData.endDate));
      this.linkForm.controls.listOrder.setValue(resultData.listOrder);
      this.colorSelected = resultData.color;
      const CheckDate = this.utils.getDate(this.linkForm.controls.startDate.value);
      this.minEndDate = this.linkForm.controls.startDate.value;
      if (CheckDate.setHours(0, 0, 0, 0) < this.minDate.setHours(0, 0, 0, 0)) {
        this.minDate = this.linkForm.controls.startDate.value;
        this.minEndDate = this.minDate;
      }
    }
  }
}
