import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { DetailsBuilderData, GridFetchFields, TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety, PatchValueReassign } from './create-transaction-config.interface';

export const FormValueReset: PatchValueReassign = (
        TransactionCollection: TransactionConfiguration, responseData: any,
        ViewHeaderEntity: any,
        ViewDetailsEntity: any, 
        FormGroupData: FormGroup, 
        TilesHeaderTrascationEntity?: any,
        httpService?: HttpService, 
        translateService?: TranslateService): any => {
        try {
            if (TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder &&
                TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.length > 0) {
                const detailsBuilder = TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder;
                detailsBuilder.forEach((x: DetailsBuilderData) => {
                  if (x.hasOwnProperty('AutoFillData') && x.AutoFillData.EnableAutoFill && x.AutoFillData.FetchFromStorage) {
                      if (x.AutoFillData.hasOwnProperty('StorageName')) {
                        FormGroupData.controls[x.OdataKey].setValue(sessionStorage.getItem(x.AutoFillData.KeyToFetch));   
                      } 
                  }
                });
                return null;
            }
        } catch (Error) {
            console.error('Error occurred FetchGridProperties', Error);
        }
    };