
import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { NotificationConfigurationService } from './notification-configuration.service';
import { Events, EventUI, Distribution, NotificationEventTemplate, EventConfigurationUI, EventNotificationGroup, EventConfiguration, VIPType, EventNotification, EventScheduler, ScheduleType, Collection, GuestCategory, statusFlag } from './notification-configuration.model';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../../app-service';
import { INotificationDataProvider } from '../../Models/notification.model';
import { RguestCommunication } from '../../communication/common/rguest-communication';
import { HttpClient } from '@angular/common/http';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { ServiceParams } from '../../Models/http.model';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { Product } from '../../shared/shared/globalsContant';
import { UserAccessBusiness } from '../../dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';


@Injectable()
export class NotificationConfigurationBusiness {
    loaderEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
    productId: number;
    public isViewOnly: boolean = false;
    
    constructor(private _Localization: Localization, public _NotificationConfigurationService: NotificationConfigurationService
        , public _appService: AppService, private httpclient: HttpClient, private localization: Localization, private utilities: CommonUtilities, private PropertyInfo: CommonPropertyInformation
        , public _userAccessBusiness : UserAccessBusiness) {
        this.productId = Number(this._Localization.GetPropertyInfo('ProductId'));
    }


    exceptionHandle(ex: any) {
        console.error(ex);
        throw ex;
    }

    async getAllVIPCode(): Promise<VIPType[][]> {
        const dataProviders: INotificationDataProvider[] = this._appService.notificationDataProvider.providers;
        let providers$: Promise<Collection[]>[] = [];
        dataProviders.forEach(p => {
            let _provider: Promise<Collection[]>;
            _provider = this.getStaticEventActors(p)
            providers$.push(_provider);
        });


        const result: Collection[][] = await Promise.all(providers$);
        let arrayCollection = [];
        for (let i = 0; i < 3; i++) {
            const actorsArr: any = result[i][0].list;   // Workaround to use Flat()
            let actorsUi: VIPType[] = actorsArr.flat() as VIPType[];
            const data = actorsUi.map(a => {
                return <VIPType>{
                    id: a.id,
                    code: a.code,
                    name: a.name
                }
            });
            arrayCollection.push(data);
        }
        return arrayCollection;

    }
    async getStaticEventActors(provider: INotificationDataProvider): Promise<Collection[]> {
        const host = this.validateAndResolveHost(provider.host);
        const communication = this.createRguestCommunication(host);
        const params: ServiceParams = { route: provider.route }
        const result = await communication.getPromise<any[]>(params);
        return [
            <Collection>{
                list: provider.uiMapper(result)
            }];
    }
    private validateAndResolveHost(host: string) {
        let validhost = host;
        const lastCharIndex = host.length - 1;
        if (host[lastCharIndex] == '/') {
            validhost = host.slice(0, lastCharIndex);
        }
        return validhost;
    }

    createRguestCommunication(host: string): RguestCommunication {
        return new RguestCommunication(host, this.httpclient, this.localization, this.utilities, this.PropertyInfo, this._appService);
    }


    async getAllEvents(): Promise<EventUI[]> {
        const excludeEvents : EventNotification[] = [
            EventNotification.GolfNightAuditReminder, EventNotification.GolfNightAuditCompletion, EventNotification.GolfNightAuditFailure
            ,EventNotification.SpaNightAuditReminder, EventNotification.SpaNightAuditCompletion, EventNotification.SpaNightAuditFailure
            ,EventNotification.RetailNightAuditReminder, EventNotification.RetailNightAuditCompletion, EventNotification.RetailNightAuditFailure,
             EventNotification.IntakeForm, EventNotification.EForm]
            if(this.productId == Product.SNC){
                excludeEvents.push(EventNotification.NightAuditReminder, EventNotification.NightAuditCompletion, EventNotification.NightAuditFailure);
            }   
        const Events: Events[] = await this._NotificationConfigurationService.getAllEventsByProductId();
        let filterEvents: Events[] = Events.filter(o => !excludeEvents.includes(o.id));
        return this.mapEventToUI(filterEvents);
    }

    async getAllDistributionByProductId(): Promise<Distribution[]> {
        try {
            return await this._NotificationConfigurationService.getAllDistributionByProductId();
        } catch (e) {
            this.exceptionHandle(e);
        }
    }

    async getGuestCategoryByProductId(): Promise<GuestCategory[]> {
        try {
            return await this._NotificationConfigurationService.getGuestCategoryByProductId();
        } catch (e) {
            // this.exceptionHandle(e);
            return [];
        }
    }

    async getTemplateDetailsByEventId(eventId: number): Promise<NotificationEventTemplate[]> {
        try {
            return await this._NotificationConfigurationService.GetTemplateDetailsByEventId(eventId);
        } catch (e) {
            this.exceptionHandle(e);
        }
    }

    async getAllEventConfigurationByProductId(events: EventUI[]): Promise<EventConfigurationUI[]> {
        const eventConfiguration: EventNotificationGroup[] = await this._NotificationConfigurationService.GetEventNotificationGroupByProduct();
        return this.mapEventConfigurationToUI(eventConfiguration, events);
    }

    getlocal_AllEventConfigurationByProductId() {
        return [{
            events: 1,
            guestEmail: true,
            guestEmailDistLst: 3,
            guestEmailTemp: 2,
            guestId: 42,
            guestSMSDistLst: 1,
            guestSms: true,
            guestSmsTemp: 11,
            hrs: 0,
            min: 0,
            days: 0,
            sec: 0,
            userEmail: true,
            userEmailDistLst: 3,
            userEmailTemp: 2,
            userId: 43,
            userSMSDistLst: 1,
            userSms: true,
            userSmsTemp: 11,
            ISdbdata: true
        }, {
            events: 2,
            guestEmail: true,
            guestEmailDistLst: 3,
            guestEmailTemp: 3,
            guestId: 47,
            guestSMSDistLst: 0,
            guestSms: false,
            guestSmsTemp: 0,
            hrs: 0,
            min: 0,
            days: 0,
            sec: 0,
            userEmail: false,
            userEmailDistLst: 0,
            userEmailTemp: 0,
            userId: 0,
            userSMSDistLst: 0,
            userSms: false,
            userSmsTemp: 0,
            ISdbdata: true
        }];
    }

    async getEventConfigurationByEventId(eventId: number): Promise<EventNotificationGroup[]> {
        try {
            return await this._NotificationConfigurationService.GetEventNotificationGroupByEventId(eventId);
        } catch (e) {
            this.exceptionHandle(e);
        }
    }

    async SaveEventConfiguration(EventConfiguration: EventConfigurationUI[], events: EventUI[]): Promise<boolean> {
        try {
            const EventNotificationGroup: EventConfiguration[] = this.mapEventConfigurationToAPI(EventConfiguration, events);
            return await this._NotificationConfigurationService.SaveEventConfiguration(EventNotificationGroup);
        } catch (e) {
            this.exceptionHandle(e);
        }
    }

    mapEventToUI(events: Events[]): EventUI[] {
        return events.map(r => {
            return {
                id: r.id,
                description: r.eventName,
                reminder: r.isReminder,
                defaultEmailSubject: r.defaultEmailSubject ? r.defaultEmailSubject : ""
            }
        });
    }

    mapEventConfigurationToUI(eventConfiguration: EventNotificationGroup[], events: EventUI[]): EventConfigurationUI[] {
        let eventConfigurationUI: EventConfigurationUI[] = [];

        eventConfiguration.forEach(element => {
            if (element.eventScheduler) {
                var scheduleAt = this._Localization.getDate(element.eventScheduler.scheduleAt);
                element.scheduledAt = scheduleAt;
            }
            else {
                element.scheduledAt = null;
            }
        });
        let eventConfigurationdata = eventConfiguration.filter(x => x.eventId != EventNotification.VIPArrivals);
        // group by eventid and scheduledAt   
        let eventNotify: any[] = []
        if (this.productId == Product.PMS) {
            eventNotify = _.chain(eventConfigurationdata).groupBy((item) => item.eventId + '#' + item.scheduledAt + '#' + JSON.stringify(item.additionalConfig)).map((value, key) => ({ eventId: key, notificationGroups: value })).value();
        }
        else {
            eventNotify = _.chain(eventConfigurationdata).groupBy((item) => item.eventId + '#' + item.scheduledAt).map((value, key) => ({ eventId: key, notificationGroups: value })).value();
        }
        let eventConfigurationdataVIP = eventConfiguration.filter(x => x.eventId == EventNotification.VIPArrivals);
        //group by eventId and Vipcode
        let eventNotifylist: any[] = _.chain(eventConfigurationdataVIP).groupBy((item) => item.eventId + '#' + item.vipCodeId).map((value, key) => ({ eventId: key, notificationGroups: value })).value();

        eventNotifylist.forEach(t => {
            eventNotify.push(t);
        });

        eventConfigurationUI = this.mapEventNotificationGroupToUI(eventNotify);
        return eventConfigurationUI;
    }

    GetReminderDateForUI(ReminderDateTime: Date): number {
        var fixedDate = this.GetFixedReminderTime();
        return ReminderDateTime.getTime() - fixedDate.getTime();

    }

    mapEventNotificationGroupToUI(EventNotificationGroup: any[]): EventConfigurationUI[] {
        let EventConfigurationUILst: EventConfigurationUI[] = [];
        EventNotificationGroup.forEach(eve => {
            let days: number; let hours: number; let minutes: number; let seconds: number;
            const guestConfig = eve.notificationGroups.find(x => x.groupName.toUpperCase() == "GUEST");
            const userConfig = eve.notificationGroups.find(x => x.groupName.toUpperCase() == "USER");
            const tempConfig = ((userConfig && userConfig.eventScheduler != null) ? this._Localization.getDate(userConfig.eventScheduler.scheduleAt) : undefined);
            const ReminderDateTime: Date = (guestConfig && guestConfig.eventScheduler != null) ? this._Localization.getDate(guestConfig.eventScheduler.scheduleAt) : tempConfig;
            if (ReminderDateTime) {
                var fixedDate = this.GetFixedReminderTime();
                days = Math.floor((Date.UTC(ReminderDateTime.getFullYear(), ReminderDateTime.getMonth(), ReminderDateTime.getDate()) - Date.UTC(fixedDate.getFullYear(), fixedDate.getMonth(), fixedDate.getDate())) / (1000 * 60 * 60 * 24));
                hours = ReminderDateTime.getHours();
                minutes = ReminderDateTime.getMinutes();
                seconds = ReminderDateTime.getSeconds();
            }
            const tempEvent = (userConfig ? userConfig.eventId : 0);
            const eventConfigurationUI: EventConfigurationUI = {
                guestId: (guestConfig) ? guestConfig.id : 0,
                events: (guestConfig) ? guestConfig.eventId : tempEvent,
                emailSubject : guestConfig ? guestConfig.emailSubject : "",
                authorize: guestConfig ? guestConfig?.additionalConfig?.authorize : false,
                guestEmail: guestConfig && guestConfig.sendMail ? true : false,
                guestSms: guestConfig && guestConfig.sendSMS ? true : false,
                guestWhatsapp: guestConfig && guestConfig.sendWhatsApp ? true : false,
                guestEmailDistLst: (guestConfig && guestConfig.emailDistributionId != 0) ? guestConfig.emailDistributionId : null,
                guestSMSDistLst: (guestConfig && guestConfig.smsDistributionId != 0) ? guestConfig.smsDistributionId : null,
                guestWhatsappDistLst: (guestConfig && guestConfig.whatsAppDistributionId != 0) ? guestConfig.whatsAppDistributionId : null,
                guestEmailTemp: (guestConfig && guestConfig.emailTemplateId != 0) ? guestConfig.emailTemplateId : null,
                guestSmsTemp: (guestConfig && guestConfig.smsTemplateId != 0) ? guestConfig.smsTemplateId : null,
                guestWhatsappTemp: (guestConfig && guestConfig.whatsAppTemplateId != 0) ? guestConfig.whatsAppTemplateId : null,
                userId: userConfig ? userConfig.id : 0,
                userEmail: userConfig && userConfig.sendMail ? true : false,
                userSms: userConfig && userConfig.sendSMS ? true : false,
                userWhatsapp: userConfig && userConfig.sendWhatsApp ? true : false,
                userEmailDistLst: (userConfig && userConfig.emailDistributionId != 0) ? userConfig.emailDistributionId : null,
                userSMSDistLst: (userConfig && userConfig.smsDistributionId != 0) ? userConfig.smsDistributionId : null,
                userWhatsappDistLst: (userConfig && userConfig.whatsAppDistributionId != 0) ? userConfig.whatsAppDistributionId : null,
                userEmailTemp: (userConfig && userConfig.emailTemplateId != 0) ? userConfig.emailTemplateId : null,
                userSmsTemp: userConfig && (userConfig.smsTemplateId != 0) ? userConfig.smsTemplateId : null,
                userWhatsappTemp: userConfig && (userConfig.whatsAppTemplateId != 0) ? userConfig.whatsAppTemplateId : null,
                hrs: (ReminderDateTime) ? hours : 0,
                min: (ReminderDateTime) ? minutes : 0,
                days: (ReminderDateTime) ? days : 0,
                sec: (ReminderDateTime) ? seconds : 0,
                ISdbdata: true,
                status: 0,
                guestEventScheduler: guestConfig ? guestConfig.eventScheduler : null,
                userEventScheduler: userConfig ? userConfig.eventScheduler : null,
                intakeForm: guestConfig ? guestConfig.intakeForm : false,
                enableEForm: guestConfig ? guestConfig?.additionalConfig?.enableEForm : false,
                vipCodeId: guestConfig ? guestConfig.vipCodeId : 0,
                guestType: guestConfig?.additionalConfig?.guestTypeIds ?? '',
                building: guestConfig?.additionalConfig?.buildingIds ?? '',
                guestEmailCategory: guestConfig?.additionalConfig?.emailGuestCategories ?? [],
                guestSmsCategory: guestConfig?.additionalConfig?.smsGuestCategories ?? [],
                eFormData: guestConfig?.additionalConfig?.eFormData ?? []
            }
            EventConfigurationUILst.push(eventConfigurationUI);
        });
        return EventConfigurationUILst;
    }


    GetFixedReminderTime(): Date {
        return this._Localization.getDate(new Date(2000, 0, 1, 0, 0, 0));

    }

    SetReminderTime(date: Date, eventConfigurationUI: EventConfigurationUI): string {
        let reminderDate: Date;
        reminderDate = this._Localization.AddDays(date, eventConfigurationUI.days);
        reminderDate.setHours(eventConfigurationUI.hrs);
        reminderDate.setMinutes(eventConfigurationUI.min);
        reminderDate.setSeconds(eventConfigurationUI.sec);
        return this._Localization.convertDateTimeToAPIDateTimeSec(reminderDate);
    }

    mapEventConfigurationToAPI(eventConfigurationUI: EventConfigurationUI[], events: EventUI[]): EventConfiguration[] {
        const eventConfiguration: EventConfiguration[] = [];
        eventConfigurationUI.forEach(r => {
            let guestEventScheduler = null;
            let userEventScheduler = null;
            let fixedDate = this.GetFixedReminderTime();
            let reminderDate = this.SetReminderTime(fixedDate, r);
            const eventconfig = {} as EventConfiguration;
            eventconfig.eventId = r.events;
            eventconfig.emailSubject = r.emailSubject ? r.emailSubject : "";
            eventconfig.eventNotificationsGroup = [];
            let isReminder = events.find(x => x.id == r.events).reminder;
            if (isReminder) {
                guestEventScheduler = {} as EventScheduler;
                userEventScheduler = {} as EventScheduler;
                guestEventScheduler.id = (r.guestEventScheduler) ? r.guestEventScheduler.id : 0;
                guestEventScheduler.eventId = r.events;
                guestEventScheduler.scheduleAt = reminderDate;
                guestEventScheduler.scheduleType = ScheduleType.Custom;

                userEventScheduler.id = (r.userEventScheduler) ? r.userEventScheduler.id : 0;
                userEventScheduler.eventId = r.events;
                userEventScheduler.scheduleAt = reminderDate;
                userEventScheduler.scheduleType = ScheduleType.Custom;
            }

            const guestEventConfig: EventNotificationGroup = {
                id: r.guestId,
                eventId: r.events,
                groupName: "Guest",
                emailSubject: r.emailSubject ? r.emailSubject : "",
                emailTemplateId: (r.guestEmailTemp != null) ? r.guestEmailTemp : 0,
                smsTemplateId: (r.guestSmsTemp != null) ? r.guestSmsTemp : 0,
                whatsappTemplateId: (r.guestWhatsappTemp != null) ? r.guestWhatsappTemp : 0,
                emailDistributionId: (r.guestEmailDistLst != null) ? r.guestEmailDistLst : 0,
                smsDistributionId: (r.guestSMSDistLst != null) ? r.guestSMSDistLst : 0,
                whatsappDistributionId: (r.guestWhatsappDistLst != null) ? r.guestWhatsappDistLst : 0,
                sendMail: r.guestEmail,
                sendSMS: r.guestSms,
                sendWhatsapp: r.guestWhatsapp,
                status: r.status,
                intakeForm: r.intakeForm,                
                vipCodeId: r.vipCodeId,
                eventScheduler: guestEventScheduler,
                additionalConfig: {
                    guestTypeIds: r.guestType ?? [0],
                    buildingIds: r.building ?? [0],
                    emailGuestCategories: r.guestEmailCategory ?? [0],
                    smsGuestCategories: r.guestSmsCategory ?? [0],
                    authorize: r.authorize?? false,
                    enableEForm:r.enableEForm?? false,
                    eFormData: r.enableEForm? (r.eFormData ?? []) : []

                }
            }

            const userEventConfig: EventNotificationGroup = {
                id: r.userId,
                eventId: r.events,
                groupName: "User",
                emailSubject: r.emailSubject ? r.emailSubject : "",
                emailTemplateId: (r.userEmailTemp != null) ? r.userEmailTemp : 0,
                smsTemplateId: (r.userSmsTemp != null) ? r.userSmsTemp : 0,
                whatsappTemplateId: (r.userWhatsappTemp != null) ? r.userWhatsappTemp : 0,
                emailDistributionId: (r.userEmailDistLst != null) ? r.userEmailDistLst : 0,
                smsDistributionId: (r.userSMSDistLst != null) ? r.userSMSDistLst : 0,
                whatsappDistributionId: (r.userWhatsappDistLst != null) ? r.userWhatsappDistLst : 0,
                sendMail: r.userEmail,
                sendSMS: r.userSms,
                sendWhatsapp: r.userWhatsapp,
                status: r.status,
                eventScheduler: userEventScheduler,
                intakeForm: r.intakeForm,
                vipCodeId: r.vipCodeId,
                additionalConfig: {
                    guestTypeIds: r.guestType ?? [0],
                    buildingIds: r.building ?? [0],
                    emailGuestCategories: r.guestEmailCategory ?? [0],
                    smsGuestCategories: r.guestSmsCategory ?? [0],
                    authorize: r.authorize ?? false,
                    enableEForm: r.enableEForm ?? false,
                    eFormData: r.enableEForm ? (r.eFormData ?? []) : []
                }

            }
            eventconfig.eventNotificationsGroup.push(guestEventConfig);
            eventconfig.eventNotificationsGroup.push(userEventConfig);
            eventConfiguration.push(eventconfig);
        });
        return eventConfiguration;
    }

    public async validateBreakPoints(): Promise<boolean> {
        let breakpointNumber : number = (this.productId == Product.GOLF) ? UserAccessBreakPoints.GOLFSYSTEMSETUP
        : ((this.productId == Product.SPA) ? UserAccessBreakPoints.SPASYSTEMSETUP : 0);        
        if(breakpointNumber > 0) {
            let result = await this._userAccessBusiness.getUserAccess(breakpointNumber,true);
            this.isViewOnly = result.isViewOnly;
            return result.isAllow;
        }
        return true;
      }
    

    // mock datas

    getEvents() {
        return [
            { 'id': 1, 'description': 'Appointment Booking', 'reminder': false },
            { 'id': 2, 'description': 'Appointment Sceduling', 'reminder': false },
            { 'id': 3, 'description': 'Appointment Reminder', 'reminder': true },
        ];
    }
    getGemailDL(): Distribution[] {
        return [
            { id: 1, distributionName: 'Therapist Notifications', distributionType: 1, fromAddress: 'sdf', distributionConfigs: null },
            { id: 2, distributionName: 'Managers Notifications', distributionType: 1, fromAddress: 'sdf', distributionConfigs: null },
            { id: 3, distributionName: 'All Users Notifications', distributionType: 2, fromAddress: 'sdf', distributionConfigs: null },
            { id: 4, distributionName: 'Users Notifications', distributionType: 2, fromAddress: 'sdf', distributionConfigs: null },
            { id: 4, distributionName: 'Users Notifications both', distributionType: 3, fromAddress: 'sdf', distributionConfigs: null },
            { id: 4, distributionName: 'Users Notifications both1', distributionType: 3, fromAddress: 'sdf', distributionConfigs: null },
        ];
    }
    getGemailTemp(): NotificationEventTemplate[] {
        return [
            { id: 1, templateName: 'Appointment Confirmation1', eventId: 1, templateId: '1', isDefault: true, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Appointment Confirmation2', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Confirmation with Promotion Message', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 2 }
        ];
    }
    getGsmsTemp() {
        return [
            { id: 1, templateName: 'Appointment Confirmation1', eventId: 1, templateId: '1', isDefault: true, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Appointment Confirmation2', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Confirmation with Promotion Message', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 2 }
        ];
    }
    getUemailTemp() {
        return [
            { id: 1, templateName: 'Appointment Confirmation1', eventId: 1, templateId: '1', isDefault: true, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Appointment Confirmation2', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Confirmation with Promotion Message', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 2 }
        ];
    }
    getUsmsTemp() {
        return [
            { id: 1, templateName: 'Appointment Confirmation1', eventId: 1, templateId: '1', isDefault: true, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Appointment Confirmation2', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 1 },
            { id: 1, templateName: 'Confirmation with Promotion Message', eventId: 1, templateId: '1', isDefault: false, isActive: true, listOrder: 1, templateCode: '34', typeId: 2 }
        ];
    }

}