import { Component, OnInit, ViewEncapsulation,Input } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import { Host, SPAManagementBreakPoint } from '../../../globalsContant';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { Subscription } from 'rxjs';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';


@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MedicalHistoryComponent implements OnInit {

  isEditScreen: boolean = false;
  deepCloneArray: any = [] //Deep Clone Array Value
  selectionId: number = 0;
  buttonServeArray: any = [];
  allSelect: boolean = false;
  checkAll: boolean = false;
  isSelected: boolean = false;
  checkIndex: any = []
  /**
   * Intial Medical Array
   */

  medicalArray: any = []

  /**
   * Search Item Value
   */

  searchvalueArray = [];
  captions: any;
  hasAccessPrivate: boolean;
  medicalHistoryFormGrp: UntypedFormGroup;
  clientWindowConvertion: Subscription;
  isFirstTime: boolean = true;
  isGuestRecordPurged: boolean = false;
  showClose: boolean = true;
  messageForWarning: string;
  @Input('changedClientData')
  set changeClientData(value){
    if(value){
      this.initializeFormData();
    }
  }
  constructor(public _as: AppointmentpopupService, private httpService: HttpServiceCall, public localization: SpaLocalization, private util: SpaUtilities,
    private breakPoint: BreakPointAccess, public fb: UntypedFormBuilder, private PropertyInfo: SpaPropertyInformation) {
    this.hasAccessPrivate = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.ViewPrivateMedicalCondition], false)
    this.captions = this.localization.captions.bookAppointment;
    this.medicalHistoryFormGrp = this.fb.group({});
  }
  /**
   * @function searchMedicoHistory
   * @param e
   * @output array
   */
  searchMedicoHistory(e) {
    this.checkAll = false;
    this.checkIndex = [];
    let arrayres = this.buttonServeArray.filter(buttonarray => buttonarray == e.id);
    if (e.id == 1) {
      if (arrayres.length == 0) {
        this.buttonServeArray = [];
        this.selectionId = e.id;
        this.medicalArray = [];
        let resultArray = this.deepCloneArray.filter(medicalList => { return medicalList.privateCondition.toString().toLowerCase() == 'true' });
        this.medicalArray = resultArray;
        this.buttonServeArray.push(e.id)
      } else {
        this.medicalArray = this.deepCloneArray;
        this.selectionId = 0;
        this.buttonServeArray = [];
      }
    } else if (e.id == 2) {
      if (arrayres.length == 0) {
        this.buttonServeArray = [];
        this.selectionId = e.id;
        this.medicalArray = [];
        let resultArray = this.deepCloneArray.filter(medicalList => medicalList.loggedTime ? this.dateCalc(medicalList.loggedTime) > -7 : false)
        this.medicalArray = resultArray;
        this.buttonServeArray.push(e.id)
      } else {
        this.medicalArray = this.deepCloneArray;
        this.selectionId = 0;
        this.buttonServeArray = [];
      }
    } else if (e.id == 3) {
      if (arrayres.length == 0) {
        this.buttonServeArray = [];
        this.selectionId = e.id;
        this.medicalArray = [];
        this.medicalArray = this.deepCloneArray;
        this.buttonServeArray.push(e.id)
      } else {
        this.medicalArray = this.deepCloneArray;
        this.selectionId = 0;
        this.buttonServeArray = [];
      }
    }
  }

  /**
   * @function dateCalc
   * @input date Array
   * @output number
   * @description Calculate the difference between the two dats
   */

  dateCalc(e) {
    let startdate = moment(e.toString(), "YYYY-MM-DD");
    let MomentDate = moment(this.PropertyInfo.CurrentDate).format('YYYY-MM-DD');
    return moment.duration(startdate.diff(MomentDate)).asDays();
  }

  /**
   * @function selectAll
   * @input Array
   * @Output boolean
   * @description check All box from the table
   *
   */
  selectAll() {
    if (!this.checkAll) {
      this.medicalArray.map(x => x.isChecked = true);
      this.checkAll = this.medicalArray.every(x => x.isChecked);
      this.checkIndex = [];
      this.medicalArray.map(x => this.checkIndex.push(x.id)) //Push the All id if Select the all data
    } else {
      this.medicalArray.map(x => x.isChecked = false);
      this.checkAll = false;
      this.checkIndex = []; //Empty the all array id if unselect the data
    }
    this._as.medicalhistoryArray = this.medicalArray.filter(x => x.isChecked);
    this.medicalHistoryFormGrp.markAsDirty();

  }

  /**
 * @function selectmedicalList
 * @input Array
 * @Output boolean
 * @description check sigle check box
 *
 */

  selectmedicalList(medicohistory, v, medicalArray) {
    const index = this.checkIndex.findIndex(indexCheck => indexCheck == medicohistory.id);
    if (index == -1) {
      this.checkIndex.push(medicohistory.id);
      medicohistory.isChecked = true;
    } else {
      let ReduceIndex = this.checkIndex.filter(indexFilter => indexFilter != medicohistory.id);
      medicohistory.isChecked = false;
      this.checkIndex = [];
      this.checkIndex = ReduceIndex;
    }
    this.checkAll = medicalArray.every(x => x.isChecked)
    this._as.medicalhistoryArray = medicalArray.filter(x => x.isChecked);
    this.medicalHistoryFormGrp.markAsDirty();
  }

  ngOnInit() {
    this.initializeFormData();
    if(this._as?.clientEditData?.clientDetail?.isPurged){
      this.isGuestRecordPurged = true;
      this.messageForWarning = this._as?.allCaptions?.lbl_guestRecordPurged;
    }
  }

  initializeFormData() {
    this._as.medicalHistoryFormGrp = this.medicalHistoryFormGrp;
    this._as.hasAccessForMedicalHistory = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.AccessClientMedicalHistory], false)
    this.searchvalueArray = this.hasAccessPrivate ? [{ "id": 1, "value": this.captions.Private, "isChecked": false },
    { "id": 2, "value": this.captions.Recent, "isChecked": false },
    { "id": 3, "value": this.captions.Oldest, "isChecked": false }] :
      [{ "id": 2, "value": this.captions.Recent, "isChecked": false },
      { "id": 3, "value": this.captions.Oldest, "isChecked": false }]
    if (this._as.hasAccessForMedicalHistory) {
      this.getAllMedicalHistory();
    }

    setTimeout(() => {
      this.calculateWidth();
    }, 1);
    this.clientWindowConvertion = this._as.convertToEdit.subscribe(x => {
      if (x && x.id > 0 && this.isFirstTime) {
        this.isFirstTime = false;
        this.initializeFormData();
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculateWidth();
    }, 1);
  }

  ngAfterViewChecked() {
    if (document.getElementById('scroll-content')) {
      this.calculateWidth();
    }

  }

  getAllMedicalHistory() {
    // MedCon
    this.httpService.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.util.errorCallback.bind(this),
      callDesc: "getAllMedicalConditions",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }
  successCallback<T>(result: any, callDesc: string, extraParams?: any[]) {

    this.medicalArray = result.result;
    if (!this.hasAccessPrivate) {
      this.medicalArray = this.medicalArray.filter(x => !x.privateCondition);
    }
    if (this._as.clientEditData && this._as.clientWidowActionType == "EDIT") {
      this.isEditScreen = true;
      let selectedMedicalConditions = this._as.clientEditData.clientMedicalCondition;
      this.medicalArray.map(x => {
        let obj = selectedMedicalConditions.find(condition => condition.medicalConditionId == x.id);
        if (obj) {
          x.isChecked = true
          x.loggedTime = obj['loggedTime']
        }
      }
      )
    }
    this.medicalArray.map(x => {
      x['isPrivate'] = x.privateCondition ? this.localization.captions.common.Yes : this.localization.captions.common.No;
    });
    this.medicalArray = _.sortBy(this.medicalArray, (x) => { return x.description })

    this.checkIndex = this.medicalArray.map(x => {
      if (x.isChecked) {
        return x.id;
      }
    })
    this.deepCloneArray = _.cloneDeep(this.medicalArray);
  }


  calculateWidth() {
    if (document.querySelectorAll('#scroll-content>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')[0]) {
      let tableHeight = document.querySelectorAll('#scroll-content>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')[0]['offsetHeight'];
      let parentHeight = document.getElementById('grid-table').offsetHeight;
      if (parentHeight > tableHeight) {
        document.getElementById('scroll-content').style.height = tableHeight + 2 + 'px';
      }
      else if (parentHeight < tableHeight) {
        document.getElementById('scroll-content').style.height = parentHeight + 'px';
      }
    }
  }
  ngOnDestroy(): void {
    if (this.clientWindowConvertion) {
      this.clientWindowConvertion.unsubscribe()
    }
    this.isFirstTime = true;
  }

  closeWarning(){
    this.showClose = false;
  }

}
