import { BooleanFormater } from './boolean-formatter.interface';
import { DateFormater } from './date-formater.interface';
import { DecimalFormater } from 'src/app/eatecui/source/shared/formaters/decimal-formater.interface';
import { CurrencyFormater } from 'src/app/eatecui/source/shared/formaters/currency-formaters.interface';

export const Formaters = {
  dateformater: DateFormater,
  booleanformater: BooleanFormater,
  decimalformater: DecimalFormater,
  currencyFormater: CurrencyFormater
};

