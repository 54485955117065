// import * as config from '../assets/json/config.json';

export class EnvironmentGlobal {
    apiUrl: string;

    setEnvVariables(configData: ConfigModel): ConfigModel {
        const hostname = window && window.location && window.location.hostname;
        // const configjson = (config as any).default;
        const configjson = configData;
        for (const element of Object.keys(configjson)) {
            const configuration: ConfigModel = configjson[element];
            if (new RegExp(configuration.locationname).test(hostname)) {
                localStorage.setItem('deployEnvironment', configuration.deployEnvironment);                
                return configuration;
            }
        }
        for (const element of Object.keys(configjson)) {
            const configuration: ConfigModel = configjson[element];
            if (configuration.stage === 'default') {
                return configuration;
            }
        }
        // if (/^.*localhost.*/.test(hostname)) {
        //     this.apiUrl = config.local.apiUrl;
        // } else if (/^eatec-webui-dev.rguest.com/.test(hostname)) {
        //     this.apiUrl = config.dev.apiUrl;
        // } else if (/^eatec-webui-qa.rguest.com/.test(hostname)) {
        //     this.apiUrl = config.qa.apiUrl;
        // } else if (/^eatec-webui-release.rguest.com/.test(hostname)) {
        //     this.apiUrl = config.release.apiUrl;
        // } else {
        //     console.warn(`Cannot find environment for host name ${hostname}`);
        // }
        // return this.apiUrl;
    }
}

export interface ConfigModel {
    apiUrl: string;
    stage: string;
    locationname: RegExp;
    instrumentationKey ?: string;
    productVersion ?: string;
    buildVersion ?: string;
    serverVersion ?: string;
    deployEnvironment?: string;
    helpDocUrl?: string;
}