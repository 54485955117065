import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { Transactions } from 'src/app/eatecui/source/store/states/transaction.state';

const TransactionSelector = (state: AppState) => state.TransactionState;
export const TranasctionSucess = createSelector(TransactionSelector, (state: Transactions) => state.GetTransactionData);
export const enableTransction = createSelector(TransactionSelector, (state: Transactions) => {
    return state.EnableTrasactionState;
});
export const InventoryManagementGetDataSuccess =
     createSelector(TransactionSelector, (state: Transactions) => state.GetInventoryManagementData);
export const enableManagementData = createSelector(TransactionSelector, (state: Transactions) => {
    return state.EnableManagementState;
});
