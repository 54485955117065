import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ItemSearchService {

  constructor() { }

  getCountValues(Key, Constants, fromJson = true) {
    try {
      if (fromJson) {
        const AppSettings = sessionStorage.getItem('appsetting');
        let returnValue;
        if (AppSettings) {
          returnValue = JSON.parse(AppSettings)[Key];
        } else {
          returnValue = Constants[Key];
        }
        return returnValue;
      } else {
        const AppSettings = sessionStorage.getItem(Key);
        const returnValue = parseInt(AppSettings);      
        return returnValue;
      }
    } catch (error) {
      console.error('Error occurred in getSelectedCount', error);
    }
  }
}
