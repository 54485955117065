import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ZebraPrinters, ZebraPrinter, BarcodePrintRequest } from '../../retail/retail.modals';

@Injectable()
export class ZebraPrintService {

    ZebraPrinters: ZebraPrinter[];
    DefaultZebraPrinter: string;
    printerClientURL = 'http://localhost:9100';

    constructor(private http: HttpClient) { }

    getLocalDevices(): Promise<ZebraPrinters> {
        return this.http.get<ZebraPrinters>(`${this.printerClientURL}/available`).toPromise();
    }

    getDefaultDevice(): Observable<string> {
        return this.http.get(`${this.printerClientURL}/default`, { responseType: 'text' });
    }

    sendLabels(printdata: BarcodePrintRequest): Promise<any> {
        let request = new Request(`${this.printerClientURL}/write`,
            {
                method: 'POST',
                mode: 'no-cors',
                cache: 'no-cache',
                body: JSON.stringify(printdata)
            });
        return fetch(request);
    }

    read(devicedata: ZebraPrinter): Observable<string> {
        return this.http.post<string>(`${this.printerClientURL}/read`, devicedata);
    }
}