import { Injectable } from '@angular/core';
import { GridConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
@Injectable({
  providedIn: 'root'
})
export class AgilysysAttachedFilesPopupService {

  constructor() { }

  formGridConfiguration(IntegrationGridData: any, transactionService: TransactionService) {
    try {
      const GridData: GridConfig = {
        GridData: [],
        TotalRecords: 0,
        GridHeaderDefination: [
          {
            'headerName': '',
            'checkboxSelection': true,
            'field': 'check',
            'headerCheckboxSelection': true,
            'sortable': false,
            'suppressMovable': true,
            'width': '50',
            'editable': false
          },
          {
            'headerName': 'Folder Number',
            'field': 'FolderNumber',
            'editable': false,
            'suppressMovable': true,
            'sortable': false,
            'tooltipField': 'FolderNumber',
            'resizable': true,
            'customSort': true
          },
          {
            'headerName': 'Folder Name',
            'field': 'FolderName',
            'editable': false,
            'suppressMovable': true,
            'sortable': false,
            'tooltipField': 'FolderName',
            'resizable': true,
            'customSort': true
          },
          {
            'headerName': 'Total Documents',
            'field': 'TotalDocuments',
            'editable': false,
            'suppressMovable': true,
            'sortable': false,
            'tooltipField': 'TotalDocuments',
            'resizable': true,
            'customSort': true
          },
          {
            'headerName': 'Document Type',
            'field': 'DocumentType',
            'editable': false,
            'suppressMovable': true,
            'sortable': false,
            'tooltipField': 'DocumentType',
            'resizable': true,
            'customSort': true
          },
          {
            'headerName': 'Document Number',
            'field': 'DocumentNumber',
            'editable': false,
            'suppressMovable': true,
            'sortable': false,
            'tooltipField': 'DocumentNumber',
            'resizable': true,
            'customSort': true
          },
          {
            'headerName': 'Created Date',
            'field': 'CreatedDate',
            'editable': false,
            'suppressMovable': true,
            'sortable': false,
            'tooltipField': 'CreatedDate',
            'resizable': true,
            'customSort': true
          },
          {
            'cellRenderer': 'linkActions',
            'cellRendererParams': { 'EntityName': 'Download' },      
            'editable': false,
            'field': '',
            'headerName': '',
            'width': '70'
          },
          {
            'cellRenderer': 'linkActions',
            'cellRendererParams': { 'EntityName': 'Delete' },      
            'editable': false,
            'field': '',
            'headerName': '',
            'width': '70'
          }
        ],
        GridActions: {},
        GridItemListAction: {
          'EnableCustomGridAction': true,
          'EnableSearch': true,
          'EnableFilter': true,
          'ShowSelectedCount': true,
          'ActionIcons': [
            {
              'Id': 1,
              'Name': 'Delete',
              'IconName': 'icon-delete-eatec',
              'Enable': false,
              'Tooltip': 'Delete'
            }
          ]
        }
      };
      if (IntegrationGridData && IntegrationGridData.Response_Code === 200) {
        GridData.GridData = IntegrationGridData.Data.Result;
      } else  if (IntegrationGridData && IntegrationGridData.StatusCode === 200) {
        GridData.GridData = IntegrationGridData.Result;
      }
      return GridData;
    } catch (error) {
      console.error('Error occurred in formGridConfiguration', error);
    }
  }
}
