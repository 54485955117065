import { FileDataPost } from './file-post.interface';
import { FileUpload } from './file-upload.interface';
import { FileThumbNailView } from './file-thumbnail-view.interface';
import { FileVideoView } from './file-video-view.interface';
import { ThumbNailGenerate } from './file-generate-thumbnail.interface';
export const FileData = {
    FileUpload: FileUpload,
    FileDataPost: FileDataPost,
    FileThumbView: FileThumbNailView,
    FileVideo: FileVideoView,
    FileThumbNailGenearate: ThumbNailGenerate
};
