import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AgFieldConfig } from '../../models/ag-models';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-spa-ag-incremental',
  templateUrl: './ag-incremental.component.html',
  styleUrls: ['./ag-incremental.component.scss']
})
export class AgIncrementalComponent implements OnInit {
  customClass: string;
  errorMessage: string;
  form: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  captions: any;
  isRequired: boolean;
  disabled: boolean;
  maxValue: number;
  increment: number;
  @Output() inputChange = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  minValue: number;
  customErrorMessage:string;
  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.maxValue = value.maxValue;
    this.isRequired = value.isRequired;
    this.minValue = value.minValue ? value.minValue : 0;
    this.increment = value.incrementBy ? value.incrementBy : 1;
    this.customErrorMessage = value.customErrorMessage
    if (this.value) {
      this.form.controls[value.formControlName].setValue(this.value);
    }
    if (value.disabled) {
      this.form.controls[value.formControlName].disable();
    }

    if (this.maxValue) {
      this.form.controls[value.formControlName].setValidators(Validators.max(this.maxValue));
    }
  }
  constructor(private _localization: SpaLocalization) { }

  ngOnInit() {
    this.captions = this._localization.captions;
  }

  inputChanged(event) {
    this.inputChange.emit(event);
  }

  valueEmit(_eve) {
    this.outputValue.emit(this.form.controls[this.controlName].value);
  }

}
