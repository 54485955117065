import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { ConfigValues } from './master-component-linking.model';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { ActionMode, AlertAction, AlertType, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { TableHeaderOptions, TableOptions, TableSearchHeader } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { MasterComponentLinkingBusiness } from './master-component-linking.business';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { CreateMasterComponentLinkingComponent } from './create-master-component-linking/create-master-component-linking.component';
import { ComponentsLinkingConfigAPI, ComponentsLinkingConfigUI } from '../../Models/components-linking-config.model';

@Component({
  selector: 'app-master-component-linking',
  templateUrl: './master-component-linking.component.html',
  styleUrls: ['./master-component-linking.component.scss'],
  providers: [MasterComponentLinkingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ChildMasterComponentLinkingComponent implements OnInit, OnDestroy {


  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  IsEditModeEnabledFlag = false;
  showTable = false;
  searchText: string;
  masterComponentLinkingForm: UntypedFormGroup;
  captions: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;

  searchHeaderOption: TableSearchHeader;
  @Input() isViewOnly: boolean;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];

  _tableContent: Promise<ComponentsLinkingConfigUI[]>;
  @Input() tableContent: Promise<ComponentsLinkingConfigUI[]> = Promise.resolve([]);
  @Output() updateGrid = new EventEmitter();
  constructor(private _fb: UntypedFormBuilder,
    private business: MasterComponentLinkingBusiness,
    private utils: CommonUtilities,
    private dialog: MatDialog,
    private localization: Localization) { }


  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.initializeInputs();
    this.generateTable();
  }

  initializeInputs() {
    this.searchHeaderOption = {
      createBtnLabel: this.captions.btn_createConfig,
      searchPlaceHolder: this.captions.lbl_searchbyKeyValue,
      toggleLabel: '',
      showInactive: false,
      toggleDisabled: false,
      createBtnDisabled: this.isViewOnly,
      hasHeaderAction: false,
      searchDisabled: false,
      showPrint: false,
      searchBarhide: false
    };
  }


  generateTable() {
    this.tableOptions = this.business.getTableOptions(this.isViewOnly);
    this.headerOptions = this.business.getTableHeaderOptions();
    this._tableContent = this.business.getTableValues();
    this.showTable = true;
  }

  searchChange(e) {
    this.searchText = e;
  }

  createEvent(e) {
    const data = {
      key: '',
      value: '',
      listOrder: 1,
    }
    this.openDialog(ActionMode.create, data);
  }

  editEvent(e) {
    const data = e.Obj;
    this.openDialog(ActionMode.update, data);
  }

  tableAction(e) {
    switch (e.fromType) {
      case FromTypeEnum.edit:
        this.editEvent(e);
        this.IsEditModeEnabledFlag = true;
        break;
      case FromTypeEnum.delete:
        this.delete(e);
        break;
    }
  }



  delete(event) {
    let warningMsg = this.localization.replacePlaceholders(this.captions.lbl_deleteConfigValues, ['key'], [event.Obj.key])
    this.utils.showCommonAlert(warningMsg, AlertType.Warning, ButtonTypes.YesNo, (res) => {
      if (res === AlertAction.YES) {
        this.business.deleteComponentsLinkingConfig(event.Obj.id).then(x => {
          if (x) {
            this._tableContent = this.business.getTableValues();
          }

        });
      }
    })
  }

  openDialog(mode, data) {
    this.IsEditModeEnabledFlag = true;
    this.dialog.open(CreateMasterComponentLinkingComponent, {
      width: '75%',
      height: '75%',
      data: {
        mode,
        isViewOnly: this.isViewOnly,
        data
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      this.IsEditModeEnabledFlag = false;
      // result.data - get form values
      if (result.from != 'cancel') {
        if (result.id === 0) {
          this.business.createComponentsLinkingConfig(result.data).then(x => {
            if (x) {
              this._tableContent = this.business.getTableValues();
            }
          });
        }
        else {
          this.business.updateComponentsLinkingConfig(result.data, result.id).then(
            x => {
              if (x) {
                this._tableContent = this.business.getTableValues();
              }
            }

          );
        }
      }
    });
  }
  async IsComponentsLinkingConfigTypeIdExists(typeId: number): Promise<boolean> {
    return await this.business.getComponentsLinkingConfigByTypeId(typeId);
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

}
