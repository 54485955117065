import { Injectable } from "@angular/core";
import { ExtractList, UI } from 'src/app/shared/models/generic-extract-model';
import { SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { Localization } from "src/app/common/localization/localization";
import { TableHeaderOptions } from "src/app/common/Models/ag-models";
import { QueryBuilderDataService } from "src/app/common/dataservices/querybuilder.data.service";

@Injectable()
export class QueryBuilderBusiness {
    captions: any;

    constructor(private localization: Localization,
        private _queryBuilderDataService: QueryBuilderDataService) {
        this.captions = this.localization.captions;
    }

    public getEasyQueryUrl(): string {
        return this._queryBuilderDataService.getEasyQueryUrl();
    }

    public async getAllCustomQueryGroups(): Promise<UI.CustomQueryGroup[]> {
        const customQueryGroups = await this._queryBuilderDataService.getAllCustomQueryGroups();
        return customQueryGroups.map(x => this.UICustomQueryGroupMapper(x)).filter(x => x.groupName != ExtractList.Accounting && x.groupName != ExtractList.Membership);
    }

    public async fetchExtract(queryJson: string) : Promise<any> {
        let requestObj = { "query": JSON.parse(queryJson) };
        const result = await this._queryBuilderDataService.DownloadExtract(requestObj, requestObj.query.modelId);
        return result;
    }

    public async updateExtractedData(extractData: UI.CustomQueryExtractData):Promise<string> {
        const result = await this._queryBuilderDataService.UpdateExtractedData(extractData);
        return result;
    }

    UICustomQueryGroupMapper(result): UI.CustomQueryGroup {
        return {
            groupName: result.groupName,
            queryListItem: result.queryListItem
        } as UI.CustomQueryGroup
    }

    getExtractResultTableOptions() {
        return {

            defaultsortingColoumnKey: ' ',
            defaultSortOrder: SorTypeEnum.asc,
            showTotalRecords: true,
            columnFreeze: {
                firstColumn: false,
                lastColumn: false
            },
            isDragDisabled: true,
            deleteMsgKey: '',

        };

    }

    getExtractResultHeaderOptions(cols): TableHeaderOptions[] {
        var result = [];
       // var queryJsonData = JSON.parse(queryJson);
        var headers = this.getExtractResultColumnHeaders(cols);
        headers.forEach(x => {
            var value = x.substr(x.indexOf(".") + 1)
            result.push({
                key: value,
                displayNameId: value,
                displayName: value,
                searchable: false,
                sorting: false,
                sortingKey: '',
            });
        });
        return result;
    }

    getExtractResultColumnHeaders(cols) {
        var result = [];
        cols.forEach(element => {
            var header = element.label;
            result.push(header);
        });
        return result.filter(x => x != undefined);
    }
}