import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-ag-button-toggler',
  templateUrl: './ag-button-toggler.component.html',
  styleUrls: ['./ag-button-toggler.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgButtonTogglerComponent implements OnInit {

  @Input() label: any;
  @Input() value: any;
  @Input() name: any;
  @Input() dataObject: any;
  @Input() custmatTooltip:any;
  @Output() selectEmit = new EventEmitter();
  @Input() disabled: boolean = false;

  ngOnInit(): void {
    this.custmatTooltip = this.custmatTooltip ? this.custmatTooltip : this.label;
  }

  // This method will emit an object that should have checked attribute stating true or false
  selectEmitter(e) {
    this.selectEmit.emit(true);
  }

}
