import { Component, OnInit } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { EmailConfiguarationSetupService } from './email-configuration-setup.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';

@Component({
  selector: 'app-email-configuration-setup',
  templateUrl: './email-configuration-setup.component.html',
  styleUrls: ['./email-configuration-setup.component.scss'],
  providers: [EmailConfiguarationSetupService]
})
export class EmailConfigurationSetupComponent implements OnInit {
  [x: string]: any;
  captions: any;
  emailConfigForm: UntypedFormGroup;
  saveButton: any;
  cancelButton: any;
  showEmailConfigurationsection: boolean;
  sendEmailSection: boolean;
  bccmailInfo: any[] = [];
  tomailInfo: any[] = [];
  contactEmailType: any;
  Email: UntypedFormArray;
  appJson: any;
  emailSetupSubscription$: any;
  enableSave: boolean = false;
  isUserAuthorized: boolean = true;
  isViewOnly: boolean = false;
  floatLabel:string;

  constructor(private _FormBuilder: UntypedFormBuilder, private localization: SpaLocalization, private BP: BreakPointAccess, private utilities: SpaUtilities,
    private _emailConfiguarationSetupService: EmailConfiguarationSetupService, private Form: UntypedFormBuilder) { 
      this.floatLabel = this.localization.setFloatLabel;
    }

  async ngOnInit() {
    this.contactEmailType = this.localization.ContactTypes.Email;
    this.defaultFormGenerator();
    this.captionsGenerator();

    await this.GetEmailConfigurationSetting();
    this.PatchFormValues();

    this.emailConfigForm.statusChanges.subscribe(x => {
      this.enableSave = this.emailConfigForm.dirty && this.emailConfigForm.valid;
    });

    this.emailConfigForm.controls["BCC_EMAILADDRESS"].statusChanges.subscribe(x => {
      this.emailChange();
    });

    this.ValidateBreakPoint();
  }

  defaultFormGenerator() {
    this.emailConfigForm = this._FormBuilder.group({
      ALLOW_MAIL_CONFIGURATION: false,
      AUTOMATIC_MAIL_ON_APPOINTMENT_BOOKING: false,
      AUTOMATIC_SMS_ON_APPOINTMENT_BOOKING: false,
      AUTOMATIC_MAIL_ON_APPOINTMENT_REMAINDER: false,
      AUTOMATIC_SMS_ON_APPOINTMENT_REMAINDER: false,
      APPOINTMENT_REMAINDER_TIME: [0, Validators.required],
      AUTOMATIC_MAIL_ON_EDITORMOVE_APPOINTMENT: false,
      AUTOMATIC_SMS_ON_EDITORMOVE_APPOINTMENT: false,
      AUTOMATIC_MAIL_ON_APPOINTMENT_CANCELLATION: false,
      AUTOMATIC_SMS_ON_APPOINTMENT_CANCELLATION: false,
      AUTOMATIC_MAIL_ON_APPOINTMENT_NOSHOW: false,
      AUTOMATIC_SMS_ON_APPOINTMENT_NOSHOW: false,
      APPOINTMENT_NOSHOW_TIME: [0, Validators.required],
      AUTOMATIC_MAIL_ON_APPOINTMENT_CHECKIN_NOTIFICATION: false,
      AUTOMATIC_SMS_ON_APPOINTMENT_CHECKIN_NOTIFICATION: false,
      AUTOMATIC_MAIL_ON_APPOINTMENT_CHECKOUT_NOTIFICATION: false,
      AUTOMATIC_SMS_ON_APPOINTMENT_CHECKOUT_NOTIFICATION: false,
      AUTOMATIC_MAIL_ON_RETAIL_TRANSACTION_RECEIPTS: false,
      AUTOMATIC_SMS_ON_RETAIL_TRANSACTION_RECEIPTS: false,
      AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_DATEANDTIME: false,
      AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_PRICETYPE: false,
      AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_SERVICES: false,
      AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_LOCATION: false,
      AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_ASSIGNED_THERAPIST: false,
      toEmail: this.Form.array([this.createtoEmailItem(0, 10, '')]),
      BCC_EMAILADDRESS: this.Form.array([this.createbccEmailItem(0, '', '')]),
    });
  }

  createtoEmailItem(arr: number, _EmailLabel?: any, _EmailId?: any, _existingId?: number): UntypedFormGroup {
    return this.Form.group({
      toEmailLabel: [_EmailLabel, Validators.required],
      toEmailId: [_EmailId, Validators.compose([Validators.required, Validators.email])],
      id: _existingId
    });
  }

  createbccEmailItem(arr: number, _EmailLabel?: any, _EmailId?: any, _existingId?: number): UntypedFormGroup {
    return this.Form.group({
      bccEmailId: [_EmailId, Validators.email],
      id: _existingId
    });
  }

  addEmailItem(i, _EmailLabel?: any, _EmailId?: any, _existingId?: number): void {
    this.Email = this.emailConfigForm.get('BCC_EMAILADDRESS') as UntypedFormArray;
    const email: any = this.contactEmailType;
    const tempEmail = email ? '' : '';
    this.Email.push(this.createbccEmailItem(i, _EmailLabel !== '' ? _EmailLabel : tempEmail, _EmailId, _existingId));
    this.emailChange();
  }

  removeEmailItem(i: any) {
    this.Email.removeAt(i);
    this.emailChange();
  }

  captionsGenerator() {
    this.captions = this.localization.captions.emailConfigurationsetup;
  }

  enableEmailConfigurationFunc(e) {
    this.showEmailConfigurationsection = e;
    if (this.showEmailConfigurationsection) {
      this.emailConfigForm.controls["toEmail"].enable();
      this.emailConfigForm.controls["toEmail"].markAllAsTouched();
    } else {
      this.emailConfigForm.controls["toEmail"].disable();
    }
  }

  sendEmailsectiontoggle(e,alternatecontrol) { 
    let boolFlag = this['emailConfigForm'].controls[alternatecontrol].value;
    this.sendEmailSection = (e || boolFlag) ;
  }

  toggleChange(form, currentField,altcurrentField, destField, eve) { 
    let boolFlag = this[form].controls[altcurrentField].value;
    if (eve || boolFlag) {
      this.enableDisableControl(form, destField, 'enable');
    } else {
      this.enableDisableControl(form, destField, 'disable');
    }
  }

  enableDisableControl(groupName, controlName, flag) {
    if (flag == 'enable') {
      this[groupName].controls[controlName].enable();
      if (controlName === 'APPOINTMENT_REMAINDER_TIME' || controlName === 'APPOINTMENT_NOSHOW_TIME') {
        this[groupName].controls[controlName].markAsTouched();
      }
    } else {
      this[groupName].controls[controlName].disable();
    }
  }

  async onSave(e) {
    if (!this.emailConfigForm.controls['ALLOW_MAIL_CONFIGURATION'].value) {
      this.PatchDefaultValuesForForm();
      this.toggleChange('emailConfigForm', 'AUTOMATIC_MAIL_ON_APPOINTMENT_REMAINDER', 'AUTOMATIC_SMS_ON_APPOINTMENT_REMAINDER',
      'APPOINTMENT_REMAINDER_TIME', this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_REMAINDER'].value);
      this.toggleChange('emailConfigForm', 'AUTOMATIC_MAIL_ON_APPOINTMENT_NOSHOW', 'AUTOMATIC_SMS_ON_APPOINTMENT_NOSHOW',
      'APPOINTMENT_NOSHOW_TIME', this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_NOSHOW'].value);
    } else if (!this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_EDITORMOVE_APPOINTMENT'].value &&
      !this.emailConfigForm.controls['AUTOMATIC_SMS_ON_EDITORMOVE_APPOINTMENT'].value) {
      this.patchDefaultValuesForEditOrMove();
    }
    this.enableSave = false;
    this.appJson = await this._emailConfiguarationSetupService.UpdateEmailConfigurationSetting(this.emailConfigForm);
  }

  onCancel(e) {
    if (this.isViewOnly) {
      return;
    }
    this.emailConfigForm.reset();
    this.PatchFormValues();
    this.enableSave = false;
  }

  emailChange() {
    if (!this.emailConfigForm.dirty) {
      this.emailConfigForm.markAsDirty();
    }
    this.emailConfigForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  PatchFormValues() {
    this.emailConfigForm.patchValue(this.appJson);
    this.showEmailConfigurationsection = this.emailConfigForm.controls['ALLOW_MAIL_CONFIGURATION'].value;
    this.sendEmailSection = this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_EDITORMOVE_APPOINTMENT'].value;
    this.toggleChange('emailConfigForm', 'AUTOMATIC_MAIL_ON_APPOINTMENT_REMAINDER', 'AUTOMATIC_SMS_ON_APPOINTMENT_REMAINDER',
      'APPOINTMENT_REMAINDER_TIME', this.appJson.AUTOMATIC_MAIL_ON_APPOINTMENT_REMAINDER);
    this.toggleChange('emailConfigForm', 'AUTOMATIC_MAIL_ON_APPOINTMENT_NOSHOW', 'AUTOMATIC_SMS_ON_APPOINTMENT_NOSHOW',
      'APPOINTMENT_NOSHOW_TIME', this.appJson.AUTOMATIC_MAIL_ON_APPOINTMENT_NOSHOW);
      this.sendEmailsectiontoggle(this.appJson.AUTOMATIC_MAIL_ON_EDITORMOVE_APPOINTMENT,'AUTOMATIC_SMS_ON_EDITORMOVE_APPOINTMENT');

    this.RemoveBCCControls();
    for (let i = 0; i < this.appJson.BCC_EMAILADDRESS.length; i++) {
      this.Email.push(this.createbccEmailItem(i, '', this.appJson.BCC_EMAILADDRESS[i]));
    }
  }

  async GetEmailConfigurationSetting() {
    this.appJson = await this._emailConfiguarationSetupService.GetEmailConfigurationSetting();
  }

  patchDefaultValuesForEditOrMove() {
    this.emailConfigForm.controls['AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_DATEANDTIME'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_PRICETYPE'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_SERVICES'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_BOOKING_LOCATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_NOTIFICATION_ON_CHANGE_IN_ASSIGNED_THERAPIST'].patchValue(false);
  }

  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings]);
    this.isViewOnly = this.BP.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings);
    if (this.isViewOnly) {
      this.utilities.disableControls(this.emailConfigForm);
    }
  }

  PatchDefaultValuesForForm() {
    this.emailConfigForm.controls['ALLOW_MAIL_CONFIGURATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_BOOKING'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_APPOINTMENT_BOOKING'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_REMAINDER'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_APPOINTMENT_REMAINDER'].patchValue(false);
    this.emailConfigForm.controls['APPOINTMENT_REMAINDER_TIME'].patchValue(0);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_EDITORMOVE_APPOINTMENT'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_EDITORMOVE_APPOINTMENT'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_CANCELLATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_APPOINTMENT_CANCELLATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_NOSHOW'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_APPOINTMENT_NOSHOW'].patchValue(false);
    this.emailConfigForm.controls['APPOINTMENT_NOSHOW_TIME'].patchValue(0);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_CHECKIN_NOTIFICATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_APPOINTMENT_CHECKIN_NOTIFICATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_APPOINTMENT_CHECKOUT_NOTIFICATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_APPOINTMENT_CHECKOUT_NOTIFICATION'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_MAIL_ON_RETAIL_TRANSACTION_RECEIPTS'].patchValue(false);
    this.emailConfigForm.controls['AUTOMATIC_SMS_ON_RETAIL_TRANSACTION_RECEIPTS'].patchValue(false);
    this.patchDefaultValuesForEditOrMove();
    this.emailConfigForm.controls['toEmail'].patchValue([{ toEmailLabel: 10, toEmailId: '', id: null }]);
    this.RemoveBCCControls();
    this.Email.push(this.createbccEmailItem(0, '', ''));
  }

  RemoveBCCControls() {
    this.Email = this.emailConfigForm.get('BCC_EMAILADDRESS') as UntypedFormArray;
    const currentLength = this.Email.length;
    for (let i = 0; i < currentLength; i++) {
      this.Email.removeAt(0);
    }
  }
}
