import { Component, Inject, OnInit, ViewContainerRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromptType } from '../../shared/globalsContant';
import { UserAlerts } from '../../common/config/alerts-config';
import { popupConfig } from '../../shared/business/shared.modals';
import { RetailService } from '../../retail.service';
import { CreateOutletTerminalComponent } from '../../retail-code-setup/retail-outlet-terminal/create-outlet-terminal/create-outlet-terminal.component';

@Component({
  selector: 'app-setting-dialog-popup',
  templateUrl: './setting-dialog-popup.component.html',
  styleUrls: ['./setting-dialog-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingDialogPopupComponent implements OnInit {
  public test = true;
  datRec: any;
  popupConfigs: popupConfig;
  CloseClick: boolean;
  tableData:any;
  @ViewChild(CreateOutletTerminalComponent) outletTemplate: CreateOutletTerminalComponent;
  constructor(private userAlerts: UserAlerts,
    public dialogRef: MatDialogRef<SettingDialogPopupComponent>,
    private vsBusiness: RetailService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public viewContainerRef: ViewContainerRef) { }

  onNoClick(): void {
    if (this.data && this.data.templatename === 'OT') {
      this.outletTemplate.onCancel();
      return;
    }
    if (this.vsBusiness.activeFormGroup && this.vsBusiness.activeFormGroup.dirty) {
      this.promptUser();
    } else {
      this.dialogRef.close();
    }
  }

  promptUser() {
    if (this.vsBusiness.activeFormGroup.dirty) {
      this.userAlerts.showPrompt(PromptType.UnsavedChanges, this.PopupCallback.bind(this));
    } else {
      this.dialogRef.close();
    }
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "no") {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.datRec = this.data.datarecord;
    this.popupConfigs = this.data.popupConfig;
    this.tableData=this.data.tabledata;
  }
}
