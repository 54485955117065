import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
@Component({
  selector: 'app-transaction-extract',
  templateUrl: './transaction-extract.component.html',
  styleUrls: ['./transaction-extract.component.scss']
})
export class TransactionExtractComponent implements OnInit {
  @Output() formReady = new EventEmitter<FormGroup>();
  TransactionExtractFormGrp: FormGroup;
  public captions: any = this.localization.captions.reports;

  constructor(private fb: FormBuilder, public localization: RetailLocalization) {
   
   }

   async ngOnInit() {
    this.TransactionExtractFormGrp = this.fb.group({
      displayInSingleSheetToggle:false
    });
    this.formReady.emit(this.TransactionExtractFormGrp);
  }
}
