import { Component } from '@angular/core';

@Component({
  selector: 'app-report-retail',
  templateUrl: './report-retail.component.html',
  styleUrls: ['./report-retail.component.scss']
})
export class ReportRetailComponent  {


}
