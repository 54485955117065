import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { DetailsPayload, FileViewConfig, FileViewData, ThumbViewModel } from './upload-configuration.interface';
import { DomSanitizer } from '@angular/platform-browser';

export const FileThumbNailView: any = (ViewResponse: Array<FileViewData>,
  FileDetails: DetailsPayload, httpService: HttpService, sanitizer: DomSanitizer, FetchAll = false ): Promise<Array<ThumbViewModel>> =>  {
  try {
    return new Promise(resolve => {
    let blob = null;
    const thumbFileCollection: Array<ThumbViewModel> = [];
    let Url = FileDetails.EndpointUrl + `groupId=` + FileDetails.GroupId + `&itemId=` + FileDetails.ItemId;
    if (FileDetails.hasOwnProperty('IsTransaction')) {
      Url = Url + `&IsTransaction=${FileDetails.IsTransaction}`;
    } else  {
      Url = Url + `&IsTransaction=false`;
    }
     httpService.GethttpMethod(Url).subscribe(response => {
    if (response.length > 0) {
      ViewResponse = [...response];
      const TotalCount = response.length;
      if (!FetchAll) {
      let filterFavoriteCollection: Array<any>;
      filterFavoriteCollection = [...response];
      if (ViewResponse.length > 0 && FileDetails.IsTransaction !== true) {
        filterFavoriteCollection = filterFavoriteCollection.filter((x: any) => x.IsFavorite === true);
      }
      if (filterFavoriteCollection.length === 0) {
        filterFavoriteCollection.push(ViewResponse[0]);
      }
      ViewResponse = filterFavoriteCollection;
      }
      if (ViewResponse.length > 0) {
        ViewResponse.forEach(async (detailResponse: any) => {
          if (detailResponse.filePath && detailResponse.filePath !== null && detailResponse.hasOwnProperty('filePath')) {
            const thumbFile = {} as ThumbViewModel;
            const FilePath = detailResponse.filePath;

            const EndPointUrl =  FileDetails.EndPoint + `fileName=${detailResponse?.fileName}` + `&IsThumbnail=false`; // To download File
            const CustomHeaderOptions = {
              'Key': 'Range',
              'Value': `bytes=${0}-`
            };
             const result = await httpService.GethttpBlobMethod(EndPointUrl, CustomHeaderOptions).toPromise();
             blob = new Blob([result], { type: 'blob' });
             const b64 = await blobToBase64(blob);
             thumbFile.Name = FilePath;
             thumbFile.FilePath = FilePath;
             
             thumbFile.Picture =  detailResponse.contentType==="application/pdf"? b64.toString().split(';base64,')[1]:b64;
             thumbFile.FileDetails = detailResponse;
             thumbFile.TotalCount = TotalCount;
             thumbFileCollection.push(thumbFile);
             if ( ViewResponse.length === thumbFileCollection.length ) {
              resolve(thumbFileCollection);
             }
          }
        });

        return thumbFileCollection;
      }
    } else {
      resolve(thumbFileCollection);
    }
  }); // TO get details
});
  } catch (error) {
    console.error(error);
  }
};

const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};





