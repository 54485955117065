import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterInventoryManagement, NestedBuilderData, SubFormConfigs } from '../../setup-master/shared/interface/master.interface';
import { AgilysysGroupingPopupService } from './agilysys-grouping-popup.service';
import { Subscription } from 'rxjs';
import { odataInventorySuccess } from 'src/app/eatecui/source/store/selectors/odata-configuration.selector';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { CacheType } from 'src/app/eatecui/source/shared/enum/global.enum';
import { RequestCache } from 'src/app/eatecui/source/core/interceptors/services/request-cache.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationSubModule, AuthorizedDataModel, PermissionResource } from 'src/app/eatecui/source/shared/models/ui-controls/ui-controls.interface';

@Component({
  selector: 'app-agilysys-grouping-popup',
  templateUrl: './agilysys-grouping-popup.component.html',
  styleUrls: ['./agilysys-grouping-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysGroupingPopupComponent implements OnInit {
  agilysysCofirmationButton: AgilysysButtonModel[];
  enabledFormGroup: boolean;
  homeGroup: FormGroup;
  datalist: Array<NestedBuilderData>;
  SelectedCount = 0;
  searchText = new FormControl();
  apiQueryParams = {} as QueryParams;
  selectedId: number;
  OdataGlobalEntityModel: OdataGlobalEntity;
  MasterDataSuccess: Subscription;
  selectedItem: Array<any> = [];
  allowCreate: boolean;
  
  constructor(public dialogRef: MatDialogRef<AgilysysGroupingPopupComponent>,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    @Inject(MAT_DIALOG_DATA) public masterInventoryManagement: MasterInventoryManagement,
    private formBuilder: FormBuilder,
    private agilysysGroupingPopupService: AgilysysGroupingPopupService,
    private httpService: HttpService,
    private store: Store<AppState>,
    private masterManagementService: MasterManagementService,
    private requestCache: RequestCache,
    private translateService: TranslateService) {
    this.enabledFormGroup = false;
    this.datalist = [];
    this.homeGroup = this.formBuilder.group({});
    this.getPermissionForCreate();
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: this.translateService.instant('Common.Cancel'),
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: this.translateService.instant('Common.Save'),
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          if ((this.homeGroup.valid && this.enabledFormGroup) || (!this.homeGroup.valid && !this.enabledFormGroup)) {
          const PostModelEntity = this.OdataGlobalEntityModel.OdataPostModelEntity.filter(x =>
            x['$']['Name'] === 'PackLists')[0];
          const PostObject = this.agilysysGroupingPopupService.formPostModelData(this.masterInventoryManagement, this.datalist,
          PostModelEntity );
          this.homeGroup.controls['TypeId'].setValue(this.masterInventoryManagement.packageTypeId);
          const PostNewObject =  this.homeGroup.value;
          // PostNewObject['ItemList'] = this.MasterInventoryManagement.DetailsConfig.DetailsData[0].Id.toString();
          PostNewObject['PackLists'] = [
            {
              Id: 0,
              IsActive: true,
              IsDeleted: false,
              Item: this.masterInventoryManagement.DetailsConfig.DetailsData[0].Id,
              Position: 1
            }
          ];
          this.dialogRef.close([callBackData.buttonValue.buttonKey, PostNewObject,
            PostObject, this.agilysysGroupingPopupService.changedIds]);
          } else if (!this.homeGroup.valid) {
            this.homeGroup.markAllAsTouched();
          }
        }
      }
    ];
  }

  getPermissionForCreate() {
    try {
      const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
      if (AuthorizationData) {
        const authDataModel: Array<AuthorizedDataModel> = AuthorizationData.
              filter((x: AuthorizedDataModel) => x.Name === 'mastermanagement');
        const authSubModule: Array<AuthorizationSubModule> = authDataModel[0].SubModules.filter((y: AuthorizationSubModule) => y.Name === 'package');
        const createPermission: PermissionResource =  authSubModule[0].PermissionResource.filter(z => z.Name === 'CreatePackage')[0];
        console.log('Create Permssion ', createPermission);
        this.allowCreate = createPermission ? createPermission.IsActive : true;
      }
    } catch (error) {
      console.log('Error occurred in getPermissionForCreate', error);
    }
  }

  createNewForm() {
    try {
      this.enabledFormGroup = !this.enabledFormGroup;
      this.homeGroup.reset();
    } catch (error) {
      console.error(error);
    }
  }

  searchCancel() {
    try {
      this.searchText.setValue('');
    } catch (error) {
      console.error(error);
    }
  }

  SearchChanged(searchValue) {
    try {
      this.apiQueryParams.search = this.agilysysGroupingPopupService.SearchChanged(searchValue, this.masterInventoryManagement);
      this.getGroupingData();
    } catch (error) {
      console.error('Error occurred in SearchChanged', error);
    }
  }

  getGroupingData() {
    try {
      const packageType = this.masterInventoryManagement.packageTypeId;
      this.apiQueryParams.isactive = true;
      this.apiQueryParams.filterQuery = `TypeId eq ${packageType}`;
      this.apiQueryParams.expand = 'PackLists($select=Item,IsDeleted,IsActive,Id)';
      const apiQueryParam = this.agilysysGroupingPopupService.formQueryParams(this.apiQueryParams);
      const GetDataByIdUrl = `/inventory/api/${this.masterInventoryManagement.GridConfig.GridOdataKey}?` + apiQueryParam;
      this.httpService.GethttpMethod(GetDataByIdUrl).subscribe((response: any) => {
        this.requestCache.manageCacheEngine(CacheType.CLEARINDIVUAL, GetDataByIdUrl);
        const nestedFormConfiguration =  this.agilysysGroupingPopupService.groupingNestedFormConstraction
        (this.masterInventoryManagement, response );
        this.masterInventoryManagement.NestedFormConfig = nestedFormConfiguration;
        this.masterInventoryManagement.NestedFormConfig.ViewFormConfig.forEach((x: SubFormConfigs) => {
          if (x.Type === 'listing') {
            const datacontains = x.data[0].NestedData;
            this.datalist = [...datacontains];
            this.datalist.forEach(element => {
              const dataName = element.DisplayName.substring(0, element.DisplayName.lastIndexOf('('));
              const data = this.masterInventoryManagement.GridConfig.GridData.filter( val => val.Name === dataName)[0];
              if (data.PackLists && data.PackLists.findIndex( value => value.IsActive === true && 
                value.Item.toString() === this.masterInventoryManagement.DetailsConfig.DetailsData[0].Id.toString()) > -1) {
                element.Selected = true;
              } else {
                element.Selected = false;
              }
            });
           const selectedItem =  this.setSelectedItem();
          }
        });
      });
    } catch (error) {
      console.error('Error occurred in getGroupingData', error);
    }
  }

  ItemAddedInPackage() {
    this.selectedItem = [];
    this.SelectedCount = this.datalist.filter(x => x.Selected === true).length;
    this.selectedItem = this.datalist.filter(x => x.Selected === true);
  }

  setSelectedItem(): void {
    try {
      if ( this.selectedItem.length > 0 ) {
           this.datalist.map( f => {
              const MapexitistingSelected =  this.selectedItem.filter(e => e.DisplayName === f.DisplayName );
              if ( MapexitistingSelected.length > 0 ) {
                f.Selected = true;
               } else {
                f.Selected = false;
               }
            });
      }
      this.SelectedCount = this.datalist.filter(x => x.Selected === true).length;
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit(): void {
    console.log('MasterInventoryManagement', this.masterInventoryManagement);
    if (this.masterInventoryManagement && this.masterInventoryManagement.NestedFormConfig 
      && this.masterInventoryManagement.NestedFormConfig.ViewFormConfig.length > 0) {
      this.masterInventoryManagement.NestedFormConfig.ViewFormConfig.forEach((x: SubFormConfigs) => {
        if (x.Type === 'listing') {
          const datacontains = x.data[0].NestedData;
          this.datalist = [...datacontains];
          this.datalist.forEach(element => {
            const dataName = element.DisplayName.substring(0, element.DisplayName.lastIndexOf('('));
            const data = this.masterInventoryManagement.GridConfig.GridData.filter( val => val.Name === dataName)[0];
            if (data.PackLists && data.PackLists.findIndex( value => value.IsActive === true &&
              value.Item.toString() === this.masterInventoryManagement.DetailsConfig.DetailsData[0].Id.toString()) > -1) {
              element.Selected = true;
            } else {
              element.Selected = false;
            }
          });
          this.ItemAddedInPackage();
        }
      });
    }

    this.MasterDataSuccess = this.store.pipe(select(odataInventorySuccess)).subscribe((x: OdataGlobalEntity) => {
      if (x && x !== null) {
        this.OdataGlobalEntityModel = x;
      }  else {
        this.masterManagementService.GetOdataGlobalCollection();
      }
    });

    this.searchText.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).
    subscribe(searchValue => {
      if (searchValue !== undefined && searchValue !== null) {
        this.SearchChanged(searchValue);
      }
    });
  }
}
