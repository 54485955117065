import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Localization as CommonLocalization, JsonDataSourceType } from 'src/app/common/localization/localization';
import { JSONReaderService } from 'src/app/common/shared/services/load-json.service';

export const enum DateTimeFormat {
  HHmmss = 1,
  HHmm
}

@Injectable()
export class SpaLocalization extends CommonLocalization {
  

  constructor(public jsonReader:JSONReaderService) {
    super(jsonReader);
    this.SetLocaleBasedProperties();
  }

  protected getJsonUrl(type: JsonDataSourceType): string {
    let url = '';
    if (type == JsonDataSourceType.ContactType) {
      url = `./assets/i18n/DataSource/${this.GetPropertyInfo("UserLanguage")}.ContactTypes.json`
    }
    return url;
  }  

  /**
   * Converts a javascript date to localized short date string.
   * @param Date javascript date.
   */
  LocalizeShortDate(value: Date): string {
    if (typeof value == "string") {
      value = this.getDate(moment(value).format("YYYY-MM-DDTHH:mm"));
    }
    return moment(value).format(this.inputDateFormat);
  }

  /**
   * Converts a Localized Short date string to javascript date.
   * @param value Localized Short date.
   */
  DeLocalizeShortDate(value: string): Date {
    return this.getDate(moment(value, "ll").format("YYYY-MM-DDTHH:mm"));
  } 

  /**
   * Converts a javascript date to localized short date time string.
   * @param Date javascript date.
   */
  LocalizeShortDateTime(value: Date): string {
    return this.LocalizeShortDate(value) + " " + this.LocalizeTime(value);
  }

  /**
   * Converts a javascript date to localized string of format 11-January-2019 11:11 am
   * @param Date javascript date.
   */
  LocalizeDateTimeFormatDDMMMMYYYY(value: Date): string {
    if (typeof value == 'string') value = this.getDate(value);
    let separator: string = "-";
    let localizedMonth: string = this.getLocalizedMonth(value);
    return this.localizeDisplayDate(value) + " " + this.LocalizeTime(value);
  }

  /**
   * Converts a javascript date to localized time string.
   * @param Date javascript date.    *
   */
  LocalizeTime(value: Date | string, isCapital = false): string {
    if (typeof value == "string") {
      value = this.getDate(moment(value).format("YYYY-MM-DDTHH:mm"));
    }
    if(this.time24formatFlag == 24){
      return this.format24HrTime(value);
    } else if(this.time24formatFlag == 12){
      return this.formatAMPM(value);
    } else{
      if (isCapital) {
        return moment(value).format('LT');
      } else {
        return moment(value)
          .format('LT')
          .toLowerCase();
      }
    }
    
  }
  /**
   * Converts localized time to ISO time format(24 hour).
   * @param string Localized time string*
   */
  DeLocalizeTime(value: string): string {
    if (value.trim() == "")
      return "";
    if(this.time24formatFlag == 24){
      return this.format24HrTime(this.TimeToDate(value));
    } else {
      return moment(value, this.timeFormat).format('HH:mm');
    }  
  }

  getDefaultTime(): string {
    let date: Date = this.getCurrentDate();
    date.setHours(0, 0, 0, 0);
    return this.LocalizeTime(date);
  }
 

  // Generate Time Array starts
  dayTimeArray(strt: Date, end: Date, incby, typ, isTimeRounded = false) {
    //isTimeRounded - Time given is rounded, like 9:00, 9:30.. Not like 8.59
    let adaytime = [];
    let nexttime;
    let incrbyhrs;
    if (typ == "hours") {
      incrbyhrs = incby;
    } else {
      incrbyhrs = incby / 60;
    }

    let drstrt = moment(strt);
    let drend = moment(end);
    let timespan = drend.diff(drstrt, "hours") / incrbyhrs;
    if (timespan == 0) timespan = drend.diff(drstrt, "hours", true) / incrbyhrs;
    if (incrbyhrs < 1) {
      if (!isTimeRounded) timespan = timespan + 1 / incrbyhrs - 1;
      else timespan = timespan - 1;
    }
    for (let i = 0; i <= timespan; i++) {
      if (i == 0) {
        adaytime.push(
          this.LocalizeTime(
            this.getDate(
              moment(strt)
                .startOf(typ)
                .format('YYYY-MM-DDTHH:mm:ss')
            )
          )
        );
      } else {
        let localizedDate: any;
        strt = moment(strt)
          .startOf(typ)
          .add(incby, typ)
          .toDate();
        localizedDate = moment(strt).format('YYYY-MM-DDTHH:mm:ss');
        adaytime.push(this.LocalizeTime(this.getDate(localizedDate)));
      }
    }
    return adaytime;
  }

  getTimeDifference(fromTime, endTime, type) {
    fromTime = moment(fromTime, this.timeFormat).toDate();
    endTime = moment(endTime, this.timeFormat).toDate();
    var difference = endTime.getTime() - fromTime.getTime(); // This will give difference in milliseconds
    let result = 0;
    if (type == "Min") {
      result = Math.round(difference / 60000);
    }
    return result;
  }
  

  formatAMPM(date: Date, isCapital = false, isHours2Digit = true) {
    if (typeof date == "string") {
      date = this.getDate(date);
    }

    let Am_string = isCapital ? "AM" : "am";
    let Pm_string = isCapital ? "PM" : "pm";
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? Pm_string : Am_string;
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutestr = minutes < 10 ? "0" + minutes : minutes;
    const tempHrs = isHours2Digit ? "0" + hours : hours;
    return (hours > 9 ? hours : tempHrs) +
      ":" +
      minutestr +
      " " +
      ampm;
  } 

  // Returns client's timezone offset (eg: +05:30)
  getSystemTimezoneOffset(): string {
    var date = this.getCurrentDate(),
      timezoneOffset = date.getTimezoneOffset(),
      hours = ('00' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2),
      minutes = ('00' + Math.abs(timezoneOffset % 60)).slice(-2),
      string = (timezoneOffset >= 0 ? '-' : '+') + hours + ':' + minutes;
    return string;
  }

  /**
  * Returns formatted date and day as "Wednesday, April 17, 2019"
  * @param Date
  */
  getLocalizedDayMonthDateYear(value: Date): string {
    return `${this.getLocalizedDay(value)}, ${moment(value).format("LL")}`;
  }

  isFallBetweenDates(startDate, endDate, date) {
    if (date === undefined || date == '') {
      return false;
    }
    return this.getDate(this.GetFormattedDate(startDate)) <= this.getDate(this.GetFormattedDate(date)) && this.getDate(this.GetFormattedDate(endDate)) >= this.getDate(this.GetFormattedDate(date));
  }
  isFallBetweenMultipleDates(startDate, endDate, fromDate, toDate) {
    if (fromDate === undefined || fromDate == '' || toDate === undefined || toDate == '') {
      return false;
    }
    return this.getDate(this.GetFormattedDate(startDate)) <= this.getDate(this.GetFormattedDate(fromDate)) && this.getDate(this.GetFormattedDate(endDate)) >= this.getDate(this.GetFormattedDate(toDate));
  }

  public GetUserInfo(name: string) {
    return this.GetLocalStorageValue('_userInfo', name);
  }
  
  public GetLocalStorageValue(key: string, name: string) {
    let nameEQ = name + '=';
    let propertyInfo = sessionStorage.getItem(key)
    if (propertyInfo != null) {
      let ca = propertyInfo.split(';');

      for (let caItem of ca) {
        let c = caItem.trim();
        while (c.charAt(0) == ' ') { c = c.substring(1, c.length); }
        if (c.indexOf(nameEQ) == 0) { return c.substring(nameEQ.length, c.length); }
      }
    }
    return null;
  }

  public generateTimeRangeObj(startTime: Date, endTime: Date, increment: number){    
    let rangeArr = [];    
    let diffMs = (endTime.getTime() - startTime.getTime()); // milliseconds between now & Christmas
    let diffMins = Math.round(((diffMs / 1000) / 60)); // minutes
    const noOfIncrements = diffMins / increment;
    if (noOfIncrements> 0) {
      const noOfIncrementsRounded = Math.floor(noOfIncrements);
      let _date: Date;
      for (let i = 0; i <= noOfIncrementsRounded; i++) {
        _date = this.AddMinutes(startTime, increment*i);
        rangeArr.push({
          slotTime: _date,
          localizedSlotTime: this.LocalizeTime(_date)});
      }
    }
    return rangeArr;
  }
  ComparePastDateAndPropertyDate(date1: Date, date2: Date) {
    let result = new Date(date2) < new Date(date1);
    return result;
  }
}

