import { Injectable } from '@angular/core';
import { DistributionListDataService } from '../../dataservices/notification/distributionList.service';
import { AppService } from '../../app-service';
import { DistributionListApi, EventActor, CollectionType, IEventActorDataProvider } from '../../Models/notification.model';
import { EventDataServices } from '../../dataservices/notification/event.data.service';
import { Product } from 'src/app/common/enums/shared-enums';

@Injectable()

export class DistributionListBusiness {

    constructor(private _distributionListDataService: DistributionListDataService,
        private _eventDataServices: EventDataServices, private _appService: AppService) {
    }

    public async getDistributionList(): Promise<DistributionListApi[]> {

        const result = await Promise.all([
            this._distributionListDataService.getDistributionList(this._appService.productId),
            this._eventDataServices.getAllEventActors(this._appService.productId)
        ]);

        const distributionLists: DistributionListApi[] = result[0];
        const eventActors: EventActor[] = result[1];
        const dataProvider: IEventActorDataProvider[] = this._appService.notificationEventDataProvider.providers;

        distributionLists.forEach(dl => {
            dl.distributionConfigs.forEach(dlc => {
                const actorCode = eventActors?.find(ea => ea.id == dlc.eventActorId)?.actor;  // from API             
                dlc.eventActorName = dataProvider?.find(p => p.actor == actorCode)?.actorDescription // localized name
            });
            dl.eventActorsTo = this.concatEvtActor(CollectionType.To, dl);
            dl.eventActorsCC = this.concatEvtActor(CollectionType.Cc, dl);
            dl.eventActorsBcc = this.concatEvtActor(CollectionType.Bcc, dl);
        });

        return distributionLists;
    }

    private concatEvtActor(type: CollectionType, dl: DistributionListApi): string {
        return dl.distributionConfigs
            .filter(c => c.collectionType == type)
            .map(dlc => dlc.eventActorName)
            .join(',');
    }

    public deleteDistributionList(id: number): Promise<boolean> {
        return this._distributionListDataService.deleteDistributionList(id);
    }

    public async GetProductDistListIds(productId){
        if(productId == Product.SALESANDCATERING){
        return await this._distributionListDataService.GetSNCDistListIds();
        }
        else{
            return [];
        }
      }
}