import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";

export class InventoryTransfer extends BaseReport {
    subCategoryFilter: string;
    functionalities: { [key: string]: boolean} = {};
    inventoryTypeFiler: string;
    retailCaptions: any = this.localization.captions.retailsetup;
    reportCaptions: any = this.localization.captions.reports;
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService
                , private func: RetailFunctionalityBusiness, public propertyInfo: RetailPropertyInformation) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.InventoryTransfer,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: "PORTRAIT",
            allowFutureDate: false
        }; 
    }

    // To do: Send appropriate filters
    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let inventoryTransferCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let categoryFilter: string[] = this.business.getSelectedCategoryCaptions(inventoryTransferCustomFilterData);
        let inventoryFilter:string = this.business.getFilterCaption(this.business.FilterDataSource["inventorytype"],inventoryTransferCustomFilterData.inventorytype.map(x => x.id));

        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle },
        { "pCategoryOnly": inventoryTransferCustomFilterData.categoryView},
        { "pSummaryView" : inventoryTransferCustomFilterData.categoryView},
        { "pOutletFilter": this.functionalities.ShowOutletSelectionFieldInInventoryTransferReport ? this.getFilterCaption : this.getDefaultOutletCaption},
        { "pCategoryFilter": categoryFilter[0] },
        {"pSubCategory1": categoryFilter[1]},
        {"pSubCategory2": categoryFilter[2]},
        {"pSubCategory3": categoryFilter[3]},
        {"pSubCategory4": categoryFilter[4]},
        {"pSubCategory5": categoryFilter[5]},
        { "pPropertyName": this.propertyName },
        { "pType": inventoryFilter },
        { "pDate" : this.printedDate },
        {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInInventoryTransferReport ? true : false }
    ];
    }

    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }];
    }

    protected formFilters() {
        let inventoryTransferCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        return {
            CategoryIds: this.fillFilter(inventoryTransferCustomFilterData.category[0]),
            SubCategory1Ids: this.fillFilter(inventoryTransferCustomFilterData.category[1]),
            SubCategory2Ids: this.fillFilter(inventoryTransferCustomFilterData.category[2]),
            SubCategory3Ids: this.fillFilter(inventoryTransferCustomFilterData.category[3]),
            subCategory4Ids: this.fillFilter(inventoryTransferCustomFilterData.category[4]),
            SubCategory5Ids: this.fillFilter(inventoryTransferCustomFilterData.category[5]),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInInventoryTransferReport ? this.selectedFilterOptions.map(x => x.id) :
                tempOutFalse,
            Type: inventoryTransferCustomFilterData.inventorytype.map(x => x.id)
        };
    }

    fillFilter(customFilterData: any): any {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
        if(customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0){
            ids =[];
        }
        return ids;
    }

    fillInventoryCaptions(customFilterData: any) : string{
        return customFilterData.inventorytype.length == 7 ? this.reportCaptions.All : (customFilterData.inventorytype.map(o => o.description).join());
    }
}


