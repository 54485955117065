export namespace UI {
    export interface ScheduleJobHistoryFilter {
        jobType: string;
        jobStatus?: number;
        from: Date | string;
        to: Date | string;
    }
    export interface ScheduleJobHistory {
        id: number;
        referenceId: number;
        jobType: string;
        jobName: string;
        retryReference?: string;
        remindAt: string;
        remindAtSortKey: string;
        distributionList: string;
        emailSendStatus: string;
        emailSendAt: string;
        sFTPFolderPath: string;
        jobStatus?: number;
        menuList: ScheduleHistoryActions[];
        downloadFormat?: string;
        isDownloadAllowed?: boolean;
        errorMessage: string;
    }

    export enum ScheduleHistoryActionOptions {
        download,
        retry,
        emailSentStatus
    }

    export interface ScheduleHistoryActions {
        id: ScheduleHistoryActionOptions;
        menu: string;
        isDisabled?: boolean;
    }
}

export enum JobStatus {
    Initiated,
    Running,
    Completed,
    Failed
}

export namespace API {
  export interface ScheduleJobHistoryFilter {
    jobType: string;
    jobStatus?: number;
    from: Date | string;
    to: Date | string;
  }

  export interface ScheduleJobHistory {
    id: number;
    referenceId: number;
    jobType: string;
    jobName: string;
    retryReference?: string;
    remindAt: Date;
    distributionListId: number;
    emailSendStatus: string;
    emailSendAt: string;
    sftpFolderPath: string;
    jobStatus?: JobStatus;
    downloadFormat?: string;
    isDownloadAllowed?: boolean;
    errorMessage: string;
  }
}

