import { Injectable } from "@angular/core";
import { RetailSharedModule } from "../retail-shared.module";

@Injectable({ providedIn: RetailSharedModule })
export class CSVGenerator {
    constructor() { }

    private readonly FILETYPE: string = "text/csv;charset=utf-8;";

    private GetColumnHeaders(data: object): string[] {
        return Object.getOwnPropertyNames(data);
    }

    /**
     * @description This method whill generate the CSV file with the given collection and with the file name
     * @param data Collection which will be exported into CSV
     * @param fileName Filename in which CSV file shoule get exported
     */
    downloadFile(data: Array<object>, fileName: string = 'sample') {
        try {
            let csvData = this.ConvertToCSV(data, this.GetColumnHeaders(data[0]));
            let blob = new Blob(['\ufeff' + csvData], { type: this.FILETYPE });
            let dwldLink = document.createElement("a");
            let url = URL.createObjectURL(blob);
            let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
            if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
                dwldLink.setAttribute("target", "_blank");
            }
            dwldLink.setAttribute("href", url);
            dwldLink.setAttribute("download", fileName + ".csv");
            dwldLink.style.visibility = "hidden";
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        } catch (error) {
            console.error("Error occured while generating CSV file", error);
        }
    }

    private ConvertToCSV(objArray: Array<object>, headerList: string[]) {
        try {
            let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            let str = '';
            let row = '';

            for (let i = 0; i < headerList.length; i++) {
                row += headerList[i] + ',';
            }
            row = row.slice(0, -1);
            str += row + '\r\n';
            for (let i = 0; i < array.length; i++) {
                let line = '';
                for (let j = 0; j < headerList.length; j++) {
                    let head = headerList[j];
                    line += array[i][head] + ',';
                }
                str += line + '\r\n';
            }
            return str;
        } catch (error) {
            console.log("Error occured while converting JSON to CSV")
        }
    }
}