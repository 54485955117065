


export enum AlertType {
    Success = 1,
    Warning = 2,
    Error = 3,
    WellDone = 4,
    Info = 5,
    AccessDenied = 6,
    Done = 7
}

export enum ButtonType {
    YesNo = 1,
    YesNoCancel = 2,
    OkCancel = 3,
    Ok = 4,
    SaveCancel = 5,
    Continue = 6,
    ContinueCancel = 7,
    AddCancel = 8,
    Custom = 11,
    OKOverride = 12

}
export enum ContactType {
    email = 'Email',
    phone = 'Phone'
}

export enum PhoneTypes {
    mobile = 1,
    home = 2,
    office = 3
}

export enum MailTypes {
    personal = 4,
    office = 5,
}

export enum ContactReferenceType {
    MANAGER = 1,
    CONTACT = 2,
    ACCOUNT = 3,
    INQUIRY = 4
}
export enum ContactInformationReferenceType {
    CONTACT = 1,
    ACCOUNT
}
export enum ContactAddressReferenceType {
    CONTACT = 1,
    ACCOUNT
}

export enum DisplayLine {
    all = 0,
    holiday = 1,
    hotDates = 2
}

export enum ManagerType {
    Sales = 1,
    ConferenceService,
    Catering
}
export enum InquiryDeniedReportType {
    Inquiry = 1,
    Denied = 2,
    Both = 3,
    BookingCreated = 4
}

export enum SalesCallFilterType {
    Account = 1,
    Manager = 2,
    CallType = 3,
    NextStep = 4,
    CallDate = 5
}

export enum AssociateType {
    Building = 1,
    SalesManager,
    ConferenceManager,
    CateringManager

}

export enum DialogViewOnly {
    print = 'print',
    cancel = 'cancel'
} 
