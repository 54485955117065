import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { TransactionConfiguration } from '../transaction.interface';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { pairwise, startWith } from 'rxjs/operators';
import { QueryInterface } from 'src/app/eatecui/source/shared/models/query-interface';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { GridItemListActions } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';

export const CustomPOLocationChange: CustomFormConfiguration = async (
    ViewModelEntity: any, PostModelEntity: any,
    transactionJsonData: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: FormType[], dialog: MatDialog, sysytemPreferenceService: SysytemPreferenceService, 
    createTransactionService?: CreateTransactionService, translateService?: TranslateService, fromEdit = false
): Promise<any> => {
    try {     
        const systemPrefEnabled = sysytemPreferenceService?.globalSystemPreference?.filter(x => x.LookupName === 'AllowIngredientSiteLevelSegregation')[0].IsActive;
        if (transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig &&
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList && !fromEdit) {
            const Data: ConfirmPopModel = {
                PopUpContent: 'Changing the Location would affect the Item list. Do you still wish to proceed?',
                PopupHeaderContent: 'Confirmation'
            };
            const dialogRef = dialog.open(AgilysysConfirmationPopupComponent, {
                width: '400px',
                height: '223px',
                data: Data,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(res => {
                console.log('Response', res);
                const SupplierId = transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData[0].SupplierId;
                const SupplierDetails = transactionJsonData.CreateTranscationConfig.
                    ItemsConfig.PreviousValuePO.filter(x => x.ParentId === SupplierId)[0];
                const SupplierIndex = transactionJsonData.CreateTranscationConfig.
                    ItemsConfig.PreviousValuePO.findIndex(x => x.ParentId === SupplierId);
                if (res === 'Cancel') {
                    FormGroupData.patchValue(SupplierDetails.FormData);
                } else {
                    transactionJsonData.CreateTranscationConfig.
                        ItemsConfig.PreviousValuePO[SupplierIndex].FormData = FormGroupData.value;
                }
            });
        } else if (transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData && 
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0
             && systemPrefEnabled && sessionStorage.getItem('RGuestTenantId') && JSON.parse(sessionStorage.getItem('RGuestTenantId')) && !fromEdit) { 
            const confirmPopModel = {} as ConfirmPopModel;
            confirmPopModel.PopUpContent = translateService.instant('PurchaseOrderHead.FieldsChangePopupContent');
            confirmPopModel.PopupHeaderContent = translateService.instant('PhysicalHead.Confirmation');
            const dialogRefs = dialog.open(AgilysysConfirmationPopupComponent, {
              width: '440px',
              height: '220px',
              data: confirmPopModel,
              autoFocus: false,
              disableClose: true
            });
            dialogRefs?.afterClosed().subscribe(res => {
                if (res === 'Cancel' && createTransactionService.previousSelectedLocation) {
                    const LocationId = createTransactionService.previousSelectedLocation;
                   FormGroupData.controls[FieldGrouping.OdataKey].setValue(LocationId);
                   FormGroupData.updateValueAndValidity();                   
                   bidRegionChange(FieldGrouping, FormGroupData, httpService, transactionJsonData, FormField, fromEdit);
                } else if (res === 'Confirm') {
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = 
                      [...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
                      transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = [];
                      transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = 
                    [...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody];
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard = 
                    {...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemTableCard};
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = 
                      {...transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig};
                      transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true);  
                }
            });
        }        
        bidRegionChange(FieldGrouping, FormGroupData, httpService, transactionJsonData, FormField, fromEdit);
    } catch (error) {
        console.log('error occured CustomDefaultSelect', error);
    }
};

function bidRegionChange(FieldGrouping: FormBuilderGroupKey, FormGroupData: FormGroup, httpService: HttpService,
         transactionJsonData: TransactionConfiguration, FormField: FormType[], fromEdit: boolean): void {
    try {       
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            const LocationId = FormGroupData.value['LocationId'];
            const paramsQuery: QueryParams = {
                count: true,
                top: InfiniteScrollItemCount,
                skip: 0,
                expand: '',
                select: 'Id, BidRegionId, SiteId',
                filter: '',
                orderby: '',
                isactive: true,
                search: '',
                filterQuery: 'Id in (' + LocationId + ')',
                searchtext: ''
            };
            const EndPointUrl = '/inventory/api/Locations' + `${QueryInterface.FormQuery(paramsQuery)}`;

            httpService.GethttpMethod(EndPointUrl).subscribe(res => {               
                const ResponseData = res['value'][0];
                const siteId = ResponseData ? ResponseData.SiteId : 0;
                const siteParamsQuery: QueryParams = {
                    count: true,
                    top: InfiniteScrollItemCount,
                    skip: 0,
                    expand: '',
                    select: 'Id, BidRegionId',
                    filter: '',
                    orderby: '',
                    isactive: true,
                    search: '',
                    filterQuery: 'Id in (' + siteId + ')',
                    searchtext: ''
                };

                if (ResponseData != null && ResponseData.BidRegionId != null && ResponseData.BidRegionId > 0) {
                    FormGroupData.controls['BidRegionId'].setValue(ResponseData.BidRegionId);
                } else {
                    const SiteUrl = '/inventory/api/Sites' + `${QueryInterface.FormQuery(siteParamsQuery)}`;
                    httpService.GethttpMethod(SiteUrl).subscribe(ress => {
                        const siteData = ress['value'][0];
                        if (siteData != null && siteData.BidRegionId != null && siteData.BidRegionId > 0) {
                            FormGroupData.controls['BidRegionId'].setValue(siteData.BidRegionId);
                        }
                        // else
                        // {
                        //     FormGroupData.controls['BidRegionId'].setValue(0);
                        // }
                    });
                }               
            });
            if (transactionJsonData.OdataKey === 'PurchaseReqHead' || transactionJsonData.PostEntityName === 'PurchaseOrderHead' ||
            transactionJsonData.OdataKey === 'ReqHead' || transactionJsonData.OdataKey === 'VendorPunchout') {
                if ((transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.hasOwnProperty('ShowDropdown')
                    && transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.ShowDropdown) ||
                    (fromEdit)) {
                    transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.DropdownOptions =
                        dropdownOptions(transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.
                            ItemGridConfig.GridItemListAction, httpService, transactionJsonData as TransactionConfiguration, FormGroupData, FormField, fromEdit);
                }
            }
        } 
    } catch (error) {
        console.log(error);
    }
}

function dropdownOptions(DropDown: GridItemListActions, httpService: HttpService, TransactionCollection: TransactionConfiguration,
    FormGroupData: FormGroup, FormField: FormType[], fromEdit: boolean) {
    try {
        const locationId = FormGroupData.value.LocationId;
        const postObject = {
            'Skip': 0,
            'Take': 0,
            'FetchAll': true,
            'GetTotalRecCount': true,
            'TotalCount': 0,
            'LookupField': '',
            'OrderByColumn': '',
            'OrderByDirection': 0,
            'TransTypeId': 0,
            'LocationId': 0,
            'UserId': 0,
            'SiteId': 0,
            'Ids': 0,
            'IsActiveOnly': true,
            'SearchText': '',
            'FromLocationId': locationId
        };
        httpService.PosthttpMethod(DropDown['DropdownUrl'], postObject).subscribe(res => {
            let ReturnData = [];
            if (TransactionCollection.PostEntityName.toLowerCase() === 'purchaseorderhead') {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate
                    };
                });
            } else {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate,
                        CurrencyName: result.CurrencyName
                    };
                });
            }
            const data = { 'Id': 0, 'Name': 'None' };
            ReturnData.unshift(data);
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.DropdownOptions
                = ReturnData;
            if (!TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig) {
                const getCurrentLocationId = (FormGroupData?.controls && FormGroupData.controls['LocationId']) ?
                    FormGroupData.controls['LocationId'].value : null;
                    const supplierDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'SupplierId');    
                if (getCurrentLocationId) { 
                    let mapDataDropDownArray: RadioOptions[] = [];
                    res?.map(e => {
                        const radioOptions = {} as RadioOptions;
                        radioOptions.key = e['Id'];
                        radioOptions.label = e['Name'];
                        mapDataDropDownArray.push(radioOptions);
                    });  
                    if (!fromEdit) {
                        if (FormGroupData.value.IsloadOrderGuide) {
                            FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                            // FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                            FormGroupData.controls['SupplierId'].setValidators([Validators.required]);
                            FormGroupData.controls['SupplierId'].markAsTouched();
                        } else if (TransactionCollection.OdataKey === 'VendorPunchout') {
                            FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                            FormGroupData.controls['SupplierId'].setValidators([Validators.required]);
                            FormGroupData.controls['SupplierId'].markAsTouched();
                            // FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                        }
                        if (FormGroupData.controls['SupplierId'].value !== '' && FormGroupData.controls['SupplierId'].value !== null &&
                            FormGroupData.controls['SupplierId'].value !== undefined && FormGroupData.controls['SupplierId'].value !== ' ') {
                            if (mapDataDropDownArray.length > 0) {
                                const existIndInNewCollec = mapDataDropDownArray.findIndex(f => f.key === FormGroupData.value.SupplierId);
                                if (existIndInNewCollec === -1) {
                                    FormGroupData.controls['SupplierId'].setValue(null);
                                    FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                                    FormGroupData.controls['SupplierId'].setValidators([Validators.required]);
                                    FormGroupData.controls['SupplierId'].markAsTouched();
                                }
                            } else {
                                FormGroupData.controls['SupplierId'].setValue(null);
                                FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                                FormGroupData.controls['SupplierId'].setValidators([Validators.required]);
                                FormGroupData.controls['SupplierId'].markAsTouched();
                            }
                        }                    
                        FormGroupData.controls['SupplierId'].updateValueAndValidity();
                    } 
                    FormField[supplierDropdownIndex].fieldType.SingleData.options = mapDataDropDownArray;
                } else {
                    FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = true;
                    FormGroupData.controls['SupplierId'].reset();
                    FormGroupData.controls['SupplierId'].clearValidators();
                    FormGroupData.controls['SupplierId'].updateValueAndValidity();
                    // FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = true;                    
                }
            } else {
                const supplierDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'SupplierId');
                let mapDataDropDownArray: RadioOptions[] = [];
                res?.map(e => {
                    const radioOptions = {} as RadioOptions;
                    radioOptions.key = e['Id'];
                    radioOptions.label = e['Name'];
                    mapDataDropDownArray.push(radioOptions);
                });
                if (FormGroupData.value.IsloadOrderGuide && mapDataDropDownArray.length > 0) {
                    FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = true;
                    FormField[supplierDropdownIndex].fieldType.SingleData.options = mapDataDropDownArray;
                }
            }
        });
        return [];
    } catch (error) {
        console.error('Error occurred in dropdownOptions', error);
    }
}