import { CustomMergeField } from './custion-action-merge.interface';
import { CustomActionUrl } from './custom-action-url.interface';
import { ChangeFormType } from './custom-action-change-type.interface';
import { CustomMergeIcon } from './custom-action-icon-merge.interface';
import { CustomRestDataCollection } from './custom-restdata.interface';

export const CustomAction = {
    CustomUrlAction: CustomActionUrl,
    CustomFieldMerge: CustomMergeField,
    ChangeType: ChangeFormType,
    CustomIconMerge: CustomMergeIcon,
    CustomMasterRestData: CustomRestDataCollection
 };
 