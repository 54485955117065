import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';


@Component({
  selector: 'app-comments-component',
  templateUrl: './comments-component.component.html',
  styleUrls: ['./comments-component.component.scss']
})
export class CommentsComponentComponent implements OnInit {


  captions: any;
  currentComment: any;
  title: any;
  constructor( private dialogRef: MatDialogRef<CommentsComponentComponent>,
    @Inject(MAT_DIALOG_DATA) private data,private localization:Localization) { 
      this.captions=this.localization.captions
      this.currentComment=this.data?.currentComment;
      this.title=this.data?.title;
    }

  ngOnInit(): void {
  }
  closePopUp()
  {
    this.dialogRef.close()
  }
}
