import { Injectable } from '@angular/core';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Injectable()
export class DialogFooterService {
    captions:any;
    constructor(private _localization:SpaLocalization){}
    getCaptions() {
        this.captions = this._localization.captions;
        return this.captions;        
    }
}