import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';


// This class must not include or have any dependecy on the http-call.service.ts
@Injectable()
export class SessionManagerService {

    token = {
        refresh_token: 'refreshtokencode',
        exp: '',
        access_token: {
            username: 'user',
            roles: ['Admin', 'RegisteredUser', 'Super User']
        }
    };

    scope: string = "Spa"
    state: string = Date.now() + "" + this.getRandomDecimal();
    tokenKey: string = "a5smm_utoken"
    propertyKey: string = "propertyInfo"
    url: string = "";
    tenantId: any = 1;
    locations: any[];
    propertyValues: any[];
    userSessionId: string = "userSession";
    isGoogleApiResetSession = new Subject();

    constructor(private router: Router
        , public dialogRef: MatDialog, public storageservice:StorageService
        ) {

    }

    goToLogin(){
        this.router.navigate(['login']);
    }

    logout() {
        this.removeToken();
        this.dialogRef.closeAll();
        this.clearLocalStore();
        this.isGoogleApiResetSession.next(true)
    }

    getToken() {
        return JSON.parse(sessionStorage.getItem(this.tokenKey));
    }

    setToken(token = this.token) {
        sessionStorage.setItem(this.tokenKey, JSON.stringify(token));
    }

    getAccessToken() {
        return JSON.parse(sessionStorage.getItem(this.tokenKey))['access_token'];
    }

    isAuthenticated() {
        let token = sessionStorage.getItem(this.tokenKey);

        if (token) {
            return true;
        }
        else {
            return false;
        }
    }


    removeToken() {
        sessionStorage.removeItem(this.tokenKey);
     
        sessionStorage.removeItem("_jwt");
    }

    clearLocalStore() {
        this.storageservice.clearlocalkey();
        sessionStorage.removeItem("propertyDate");
        sessionStorage.removeItem("propertyInfo");

        sessionStorage.removeItem("_userInfo");
        sessionStorage.removeItem("userSession");

      

        sessionStorage.removeItem("taskReminderPopUp");
        sessionStorage.removeItem("APtaskReminderPopUp");

        sessionStorage.removeItem("IsCondoAvailable");
    }

    getRandomDecimal(): number {
        const crypto = window.crypto;
        return crypto.getRandomValues(new Uint32Array(1))[0]/2**32;
    }
}
