import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { StaffScheduleService } from '../staff-schedule.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-staff-schedule-comments-popup',
  templateUrl: './staff-schedule-comments-popup.component.html',
  styleUrls: ['./staff-schedule-comments-popup.component.scss'],
  providers: [StaffScheduleService],
  encapsulation: ViewEncapsulation.None
})
export class StaffScheduleCommentsPopupComponent
implements OnInit {
  sendValues:any;
  formGroup: any;
  captions: any;
  comments: any;
  ischange: boolean;
  enableSave = false;
  floatLabel:string;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  scheduledData: any;
  isSaveDisabled = true;
  isReadOnly:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StaffScheduleCommentsPopupComponent>,
    public fb:UntypedFormBuilder,
    public localization: SpaLocalization,
    public _su: StaffScheduleService,
    public dialog: MatDialog,
    public breakPoint: BreakPointAccess,
  ) {
    this.scheduledData = data;
    this.floatLabel = this.localization.setFloatLabel;
    this.isReadOnly = this.data.tca.modifyAvailable ? false:true;
    }

  ngOnInit(): void {
    this.captions = this.localization.captions.common; 
    this.formGroup=this.fb.group({
      comment: ['']
    });
    this.formGroup.controls.comment.setValue(this.data.tca.comments);
    this.formGroup.controls.comment.valueChanges.pipe(takeUntil(this.$destroyed))
      .subscribe(() =>
        this.isSaveDisabled = false
      );          
  }

  onSave() {
    this.dialogRef.close(
      this.sendValues = {
        comment: this.formGroup.controls['comment'].value,
        button: true
      }
    );
  }

  onCancel() {
    this.dialogRef.close(
      this.sendValues = {
        button: false
      }
    );
  }

  ngOnDestroy(){
    this.$destroyed.next();
    this.$destroyed.complete();
  }
  
}


