import { Directive, ElementRef,   Input,  AfterViewInit, Output, EventEmitter, Renderer2, OnChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Localization } from 'src/app/common/localization/localization';

@Directive({
  selector: '[showMore]'
})
export class ShowmoreDirective implements AfterViewInit, OnChanges {
  public intialBool = true;
  @Output() setShowMore: EventEmitter<any> = new EventEmitter();
  @Input() showMore: ReadMoreConfig;
  height: any;
  lineHeightValue: any;
  inital = true;
  text = '';
  readMoreSet = false;
  setHeight = true;
  nativeElementHeight: number;
  constructor(public el: ElementRef, public renderer: Renderer2, private localization : Localization) {

  }
  actualWord: any = [];
  ngAfterViewInit(): void {
    this.addEllipsis();
  }
  ngOnChanges(): void {
    this.addEllipsis();
  }
  addEllipsis() {
    let words = this.showMore.text.split(' ');
    this.actualWord = cloneDeep(words);
    if (words.length === 1 && words[0].length > 10) {
      const newWord = [];
      let wordLength = 10;
      for (let i = 0; i < words[0].length; i = i + 10) {
        newWord.push(words[0].substring(i, wordLength - 1));
        wordLength = wordLength + 10;
      }
      words = newWord;
    }

    let content;
    const child1 = document.createElement('span');
    const child = document.createElement('span');
    this.renderer.setStyle(child1, 'display', 'inline-block');
    const lineHeightValue = Math.round(parseInt(getComputedStyle(this.el.nativeElement).fontSize, 10));
    this.renderer.setStyle(child1, 'fontSize', lineHeightValue);
    this.renderer.appendChild(this.el.nativeElement, child1);
    if (this.el.nativeElement.scrollHeight > this.el.nativeElement.clientHeight) {
      const height = this.el.nativeElement.offsetHeight;
      this.nativeElementHeight = height;
      for (const o of words) {
        content = `${o} `;
        // child.innerHTML = child.innerHTML + content;
        // child1.innerHTML = child1.innerHTML + content;
        this.localization.decodeHTMLEntityByTagName(child.innerHTML + content,child);
        this.localization.decodeHTMLEntityByTagName(child1.innerHTML + content,child1);
        if (child1.offsetHeight > height || child1.offsetHeight === height) {
          this.addTripleDot(child.innerText);
          if (this.el.nativeElement.offsetHeight >= height) {
            this.addTripleDot(this.el.nativeElement.innerText);
          }
        }
      }
    }
  }

  addTripleDot(text) {
    const str = text.trim().split(' ');
    this.el.nativeElement.innerText = ' ';
    this.el.nativeElement.innerText = str.join(' ');
    this.el.nativeElement.innerText = this.el.nativeElement.innerText.substring(0,
      this.el.nativeElement.innerText.length - (this.showMore.showMoreText.length + 10));
    this.text = cloneDeep(this.el.nativeElement.innerText);
    this.appendSpan();
  }

  appendSpan(isShowLess?) {
    const showMoreHtml = document.createElement('div');
    const spanText = isShowLess ? this.showMore.showLessText : this.showMore.showMoreText;
    const showMoreContent = document.createTextNode(spanText);
    showMoreHtml.appendChild(showMoreContent);
    showMoreHtml.style.cssFloat = 'right';
    showMoreHtml.style.color = this.showMore.showMoreColor;
    showMoreHtml.style.fontSize = this.showMore.showMoreFont;
    showMoreHtml.style.cursor = 'pointer';
    showMoreHtml.style.transition = 'height 2s';
    showMoreHtml.style.marginBottom = '5px';
    showMoreHtml.style.textDecoration = 'underline';
    this.el.nativeElement.append(showMoreHtml);
    this.addEvent();
  }

  addEvent() {
    if (this.el.nativeElement.children[0]) {
      this.renderer.listen(this.el.nativeElement.children[0], 'click', (_event) => {
        if (this.el.nativeElement.children[0].innerText.toLowerCase() == this.showMore.showMoreText.toLowerCase()) {
          this.el.nativeElement.innerText = this.showMore.text;
          this.renderer.setStyle(this.el.nativeElement, 'transition', 'height 1s');
          this.renderer.setStyle(this.el.nativeElement, 'height', this.el.nativeElement.scrollHeight + 15 + 'px');
          this.height = this.el.nativeElement.parentElement.offsetHeight;
          this.el.nativeElement.parentElement.style.height = this.el.nativeElement.scrollHeight + 50 + 'px';
          this.appendSpan(true);
        } else {
          this.el.nativeElement.style.height = this.nativeElementHeight + 'px';
          this.el.nativeElement.parentElement.style.height = this.height + 'px';
          this.el.nativeElement.innerText = this.text;
          this.appendSpan();
        }
      });
    }
  }

}

export interface ReadMoreConfig {
  text: string;
  showMoreText: string;
  showLessText: string;
  showMoreColor: string;
  showMoreFont: string;
}
