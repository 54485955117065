import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';


@Component({
  selector: 'app-payment-Message',
  templateUrl: './paymentMessage.component.html',
  styleUrls: ['./paymentMessage.component.scss']
})
export class paymentMessageComponent implements OnInit {

  localisation:any;
  voidGroup:UntypedFormGroup;
  isPaymentMessageRequired: boolean;
  floatLabel: string;

  constructor(private fb:UntypedFormBuilder , private ls:RetailLocalization, private dialogRef:MatDialogRef<paymentMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isPaymentMessageRequired = data.isCommentRequired;
      this.floatLabel = this.ls.setFloatLabel;
  }

  onCancelClick(){
    this.dialogRef.close({action : 'cancel'});
  }
  save(){
    this.dialogRef.close({action : 'ok', paymentComments : this.voidGroup.controls['comments'].value});
  }

  ngOnInit() {
    this.localisation= this.ls.captions.shop;
    if(this.isPaymentMessageRequired){
      this.voidGroup = this.fb.group({
        comments: ['', Validators.required]
      });
    }
    else{
      this.voidGroup = this.fb.group({
        comments: ''
      });
    }    
  }

}
