import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { TranslateService } from '@ngx-translate/core';
import { FormType, RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';

export const CustomReceivingValidation: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, dialog?: MatDialog, sysytemPreferenceService?: SysytemPreferenceService, 
    createTransactionService?: CreateTransactionService, translateService?: TranslateService, fromEdit = false
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod && !fromEdit) {
            const poNumber = FormGroupData.value[FieldGrouping.CustomFormAction.CutomFieldFrom];
            const systemPrefEnabled = sysytemPreferenceService?.globalSystemPreference?.filter(x => x.LookupName === 'AllowIngredientSiteLevelSegregation')[0]?.IsActive;
            if (MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData && 
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0
                 && systemPrefEnabled && sessionStorage.getItem('RGuestTenantId') && JSON.parse(sessionStorage.getItem('RGuestTenantId'))) { 
                const confirmPopModel = {} as ConfirmPopModel;
                confirmPopModel.PopUpContent = translateService.instant('ReceivingHead.FieldsChangePopupContent');
                confirmPopModel.PopupHeaderContent = translateService.instant('PhysicalHead.Confirmation');
                const dialogRefs = dialog.open(AgilysysConfirmationPopupComponent, {
                  width: '440px',
                  height: '220px',
                  data: confirmPopModel,
                  autoFocus: false,
                  disableClose: true
                });
                dialogRefs?.afterClosed().subscribe(res => {
                    if (res === 'Cancel' && createTransactionService.previousSelectedLocation) {
                        const LocationId = createTransactionService.previousSelectedLocation;
                       FormGroupData.controls[FieldGrouping.OdataKey].setValue(LocationId);
                       FormGroupData.updateValueAndValidity();
                    } else if (res === 'Confirm') {
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = 
                          [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = [];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = 
                        [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard = 
                        {...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard};
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = 
                          {...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig};
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true); 
                        orderandReceivingLocationValidation(poNumber, FormField, FieldGrouping, FormGroupData, dialog);                         
                    }
                });
            }
            else {
                orderandReceivingLocationValidation(poNumber, FormField, FieldGrouping, FormGroupData, dialog);
            }
        }
        if (FormGroupData.value.PONumber === '') {
            getSupplierDropdownOptions(httpService, MasterDataCollection, FieldGrouping, FormGroupData, FormField, fromEdit);
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function orderandReceivingLocationValidation(poNumber: any, FormField: any, FieldGrouping: FormBuilderGroupKey, FormGroupData: FormGroup, dialog: MatDialog): void {
    try {
        let LocationName = '';
        if (poNumber) {
            FormField.forEach(formField => {
                if (formField.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldFrom) {
                    const selectedPo = formField.fieldType.SingleData.autoCompleteData.filter(x => x.Id === poNumber)[0];
                    if (selectedPo && FormGroupData.value[FieldGrouping.CustomFormAction.CutomFieldTo] !== selectedPo.LocationId) {
                        
                        FormField.forEach(element => {
                            if (element.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo) {
                                LocationName = element.fieldType.SingleData.options
                                        .filter(y => y.key === selectedPo.LocationId)[0].label;
                            }
                        });
    
                        
                        const Data: ConfirmPopModel = {
                            PopUpContent: 'Order location ' + LocationName + ' does not match receiving location.',
                            PopupHeaderContent: 'Confirmation'
                        };
                        const dialogRef = dialog.open(AgilysysConfirmationPopupComponent, {
                            width: '400px',
                            height: '223px',
                            data: Data,
                            disableClose: true
                        });
                        dialogRef.afterClosed().subscribe(res => {
                            console.log('Response', res);
                            if (res === 'Cancel') {
                                FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(selectedPo.LocationId);
                            } 
                        });
                    }
                }
            });
        }
    } catch (error) {
        console.log(error);
    }    
}
async function getSupplierDropdownOptions(httpService: HttpService, TransactionCollection: TransactionConfiguration, 
    FieldGrouping: FormBuilderGroupKey, FormGroupData: FormGroup, FormField: FormType[], fromEdit: boolean) {
    try {
        const getCurrentLocationId = (FormGroupData?.controls && FormGroupData.controls[FieldGrouping.OdataKey]) ?
                FormGroupData.controls[FieldGrouping.OdataKey].value : null;
            const supplierDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'SupplierId');
            if (getCurrentLocationId) {
                let authPayload = {} as any;
                authPayload.ClientUrl = '/inventory/api/GetSuppliers';
                const supplierPostPayload = {} as any;
                supplierPostPayload.skip = 0;
                supplierPostPayload.take = 100;
                supplierPostPayload.fetchAll = true;
                supplierPostPayload.getTotalRecCount = true;
                supplierPostPayload.totalCount = 0;
                supplierPostPayload.searchText = '';
                supplierPostPayload.lookupField = '';
                supplierPostPayload.orderByColumn = 'Name';
                supplierPostPayload.orderByDirection = 1;
                supplierPostPayload.transTypeId = TransactionCollection.TransactionType;
                supplierPostPayload.locationId = FormGroupData?.value?.LocationId;
                supplierPostPayload.IsActive = true;
                authPayload.Payload = supplierPostPayload;
                const resultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
                FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                let mapDataDropDownArray: RadioOptions[] = []
                resultData.ResultData?.map(e => {
                    const radioOptions = {} as RadioOptions;
                    radioOptions.key = e['Id'];
                    radioOptions.label = e['Name'];
                    mapDataDropDownArray.push(radioOptions);
                });
                FormField[supplierDropdownIndex].fieldType.SingleData.options = mapDataDropDownArray;
                // const requiredValIndex = FormField[supplierDropdownIndex].fieldType.SingleData.FieldValidation.findIndex(i => i.key === 'required');
                // FormField[supplierDropdownIndex].fieldType.SingleData.FieldValidation.splice(requiredValIndex, 1);
                // FormGroupData.controls['SupplierId'].clearValidators();
                if (fromEdit) {
                    FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = true;
                }
                if (FormGroupData.controls['SupplierId'].value !== '' && FormGroupData.controls['SupplierId'].value !== null ||
                    FormGroupData.controls['SupplierId'].value !== undefined) {
                    if (mapDataDropDownArray.length > 0) {
                        const existIndInNewCollec = mapDataDropDownArray.findIndex(f => f.key === FormGroupData.value.SupplierId);
                        if (existIndInNewCollec === -1) {
                            FormGroupData.controls['SupplierId'].setValue(null);
                        }
                    } else {
                        FormGroupData.controls['SupplierId'].setValue(null);
                    }
                }
                FormGroupData.controls['SupplierId'].updateValueAndValidity();
            } else {
                FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = true;
                FormGroupData.controls['SupplierId'].reset();
            }
    } catch (error) {
        console.log(error);
    }
}