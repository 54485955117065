import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectindexof'
})
export class ObjectindexofPipe implements PipeTransform {
  transform(_value: any, arrayData: any, data: any): any {
    if (!arrayData) { return -1; }
    if (arrayData) {
      const numArray = arrayData.map(res => res.id);
      return numArray.indexOf(data.id);
    }
  }
}
