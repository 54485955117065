import { QueryGridDataConfig } from './grid-data-config.interface';
import { QueryDisplayName } from './query-displayname.interface';
import { QueryGridHeaders } from './query-grid-header.interface';
import { QueryGridTransactionDataConfig } from './query-grid-transaction-data.interface';

export const QueryTransaction = {
    QueryDisplayName: QueryDisplayName,
    QueryGridHeaders: QueryGridHeaders,
    QueryGridData: QueryGridDataConfig,
    QueryIngredientsGridData: QueryGridTransactionDataConfig
};