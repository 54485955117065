import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ConversionValue, DecimalRoundOff } from 'src/app/eatecui/source/shared/enum/global.enum';


export const CalcAllUnitCosts: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            const decimalPoint = sessionStorage.getItem('appsetting') ? 
            JSON.parse(sessionStorage.getItem('appsetting')).DecimalPointRoundOff :
            DecimalRoundOff.SIX;
            const UnitCostValues = [
                {
                    UnitCostName: 'BaseCurrentCost',
                    UnitConversionName: 'BaseConversion'
                },
                {
                    UnitCostName: 'PurchaseCurrentCost',
                    UnitConversionName: 'PurchaseConversion'
                },
                {
                    UnitCostName: 'TransactionCurrentCost',
                    UnitConversionName: 'TransactionConversion'
                },
                {
                    UnitCostName: 'RecipeCurrentCost',
                    UnitConversionName: 'RecipeConversion'
                }
            ];
            UnitCostValues.forEach(names => {
                if (names.UnitCostName !== FieldGrouping.OdataKey) {
                    const CostFieldName = names.UnitCostName !== 'BaseCurrentCost' && FieldGrouping.CustomFormAction.CustomMiddleField ?
                        FieldGrouping.CustomFormAction.CustomMiddleField : FieldGrouping.OdataKey;
                    const cost =  isNaN(Number(FormGroupData.value[CostFieldName])) ? 0 : FormGroupData.value[CostFieldName];
                    const conversion = names.UnitCostName === 'BaseCurrentCost' ?
                    (isNaN(Number(FormGroupData.value[FieldGrouping.CustomFormAction.EnabledField])) ? 0 : 
                    FormGroupData.value[FieldGrouping.CustomFormAction.EnabledField]) :
                    (isNaN(Number(FormGroupData.value[names.UnitConversionName])) ?
                    0 : FormGroupData.value[names.UnitConversionName]);
                    switch (names.UnitCostName) {
                        case 'BaseCurrentCost':
                            const calcValue = FieldGrouping.OdataKey !== 'RecipeCurrentCost' ?
                                 cost / conversion : cost * conversion;
                            FormGroupData.controls[names.UnitCostName].setValue(calcValue ? parseFloat(calcValue.toString()).toFixed(4) : 0);
                            triggerBlurEvent(names.UnitCostName);
                            break;
                        case 'TransactionCurrentCost':
                        case 'PurchaseCurrentCost':
                            const calcValue1 = cost * conversion;
                            FormGroupData.controls[names.UnitCostName].setValue(calcValue1 ? parseFloat(calcValue1.toString()).toFixed(4) : 0);
                            triggerBlurEvent(names.UnitCostName);
                            break;
                        case 'RecipeCurrentCost':
                            const calcValue2 = (1 / conversion) * cost;
                            FormGroupData.controls[names.UnitCostName].setValue(calcValue2 ? parseFloat(calcValue2.toString()).toFixed(4) : 0);
                            triggerBlurEvent(names.UnitCostName);
                            break;
                    }
                }
            });
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function triggerBlurEvent(ElementName: string): void {
    try {
        if (document.getElementById(ElementName)) {
            document.getElementById(ElementName).dispatchEvent(new CustomEvent('bluraction', { 'detail': 'customaction' }));
        }
    } catch (error) {
        console.error('Error occurred in triggerBlurEvent', error);
    }
}