import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from "angular-oauth2-oidc";
import { Localization } from 'src/app/common/localization/localization';
import { NewPasswordDetail } from 'src/app/common/Models/common.models';
import { ADB2CAuthConfiguration } from 'src/app/login/auth.config';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { RetailServiceRegistry } from 'src/app/retail/shared/service/base.service';
import { BaseResponse } from '../../shared/business/shared.modals';
import { Host } from '../../shared/globalsContant';
import { rGuestBaseService, ServiceRegistry } from '../../shared/service/base.service';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { SessionManagerService } from './session-manager.service';

@Injectable()
export class AuthenticationService extends rGuestBaseService {

  scope: string = "Spa"
  state: string = Date.now() + "" + this.utils.getRandomDecimal();
  tokenKey: string = "a5smm_utoken"
  propertyKey: string = "propertyInfo"
  url: string = "";
  tenantId: any = 1;
  locations: any[];
  propertyValues: any[];
  userSessionId: string = "userSession";

  constructor(private serviceRegistry: ServiceRegistry
    , private router: Router
    , public http: HttpServiceCall
    , private utils: SpaUtilities
    , private oauthService: OAuthService
    , public dialogRef: MatDialog
    , private route: ActivatedRoute
    , public sessionManagerService: SessionManagerService
    , private retailServiceRegistry: RetailServiceRegistry
    , private manageSessionService : ManageSessionService
    , private _localization: Localization
    , private adb2cAuthConfiguration: ADB2CAuthConfiguration) {
    super();
  }

  reset(): void {
    console.log('reset');
  }

  async login(username, password, customerId, tenantCode, login: any) {
    await this.ServerLogin(username, password, customerId, tenantCode, login);
    return true;
  } 
  async loginEnc(username, password, customerId, tenantCode, login: any) {
    await this.ServerLoginEnc(username, password, customerId, tenantCode, login);
    return true;
  }
  ngOnInit() {
    // grab the current username
    this.tenantId = this.route.snapshot.queryParamMap.get('TenantId');
  }

  CheckPasswordExists(userName, password, confirmpassword: any) {
    this.CheckPassword(userName, password, confirmpassword);
  }

  SavePassword(userId, password, confirmpassword: any) {
    this.SavePasswordAsync(userId, password, confirmpassword)
  }

  VerifyPasswordPut(userName, password, confirmpassword: any) {
    this.VerifyPasswordAsyncPut(userName, password, confirmpassword);
  }
  CheckPasswordExistsPut(userName, password, confirmpassword: any) {
    this.CheckPasswordPut(userName, password, confirmpassword);
  }

  SavePasswordPost(userId, password, confirmpassword: any) {
    this.SavePasswordAsyncPost(userId, password, confirmpassword,"",false)
  }

  VerifyPassword(userName, password, confirmpassword: any) {
    this.VerifyPasswordAsync(userName, password, confirmpassword);
  }

  PasswordSetting(confirmpassword: any) {
    this.PasswordSettingAsync(confirmpassword);
  }

 async logout() {
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.classList.remove('new-mat-view');
    let UserName = this.utils.GetPropertyInfo("userName");
    await this.ServerLogOut(UserName);
    this.UpdateSession();
    if(sessionStorage.getItem('supportUserMailId')){
      this.router.navigate(["supportlogin"]);
    } else {
      this.router.navigate(['login']);
    }
    this.sessionManagerService.logout();
    this.manageSessionService.stopTimerForAppointmentLockRelease();
    this.http.removeHelpUserSession();
    this.serviceRegistry.resetAllServiceData();
    this.retailServiceRegistry.resetAllServiceData();
    if(this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled)
    {
      console.log('adb2c logout');
      this.oauthService.logOut(); //ADB2C logout 
    }
    this.manageSessionService.logout();
  }

  getToken() {
    return this.sessionManagerService.getToken();
  }

  setToken() {
    this.sessionManagerService.setToken();
  }

  getAccessToken() {
    return this.sessionManagerService.getAccessToken();
  }

  isAuthenticated() {
    this.sessionManagerService.isAuthenticated();
  }

  UpdateSession() {
    const utcDate: Date = this._localization.getUTCDateTimeNow();
    let sessionData = {
      isActive: false,
      endTime: this._localization.ConvertDateToISODateTime(utcDate)
    };

    const sessionId: string = sessionStorage.getItem(this.userSessionId);
    if (!sessionId) {
      return;
    }

    // Update User session as inactive
    this.InvokeServiceCallAsync("UpdateSession", Host.authentication, HttpMethod.Put, { sessionId: sessionId }, sessionData);

    // Remove appointment lock if locked by current session
    this.InvokeServiceCallAsync("ReleaseAppointmentLock", Host.schedule, HttpMethod.Put, { sessionId: sessionId }, []);
  }

  async ServerLogin(username: string, password: string, customerId: number, tenantCode, login: any) {
    this.tenantId = customerId;
    sessionStorage.setItem('Tenantid', customerId.toString());
    let _userModel: any = {
      UserName: username, Password: password, TenantId: this.tenantId, ProductId: 1, TenantCode: tenantCode
    }
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "Login",
      method: HttpMethod.Post,
      body: _userModel,
      uriParams: { Username: username, Password: password, TenantId: this.tenantId, TenantCode: tenantCode },
      showError: false,
      extraParams: [false]
    });
  }
  async ServerLoginEnc(username: string, password: string, customerId: number, tenantCode, login: any) {
    this.tenantId = customerId;
    sessionStorage.setItem('Tenantid', customerId.toString());
    let _userModel: any = {
      UserName: username, Password: password, TenantId: this.tenantId, ProductId: 1, TenantCode: tenantCode
    }
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "LoginEncrypted",
      method: HttpMethod.Post,
      body: _userModel,
      uriParams: { Username: username, Password: password, TenantId: this.tenantId, TenantCode: tenantCode },
      showError: false,
      extraParams: [false]
    });
  }
  async ServerLogOut(username: string) {
    this.tenantId = this.utils.GetPropertyInfo('TenantId');
    await this.http.CallApiAsync<any>({
      host: Host.authentication,
      // success: this.successCallback.bind(this),
      // error: this.errorCallback.bind(this),
      callDesc: "LogOut",
      method: HttpMethod.Post,
      uriParams: { Username: username, TenantId: this.tenantId, PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      showError: false,
     // extraParams: [false]
    });
  }


  CheckPassword(userId: Int32Array, password: string, confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "CheckPassword",
      method: HttpMethod.Get,
      uriParams: { UserId: userId, NewPassword: encodeURIComponent(password), TenantId: this.tenantId },
      showError: false,
      extraParams: [false]
    });
  }
  CheckPasswordPut(userId: Int32Array, password: string, confirmpassword: any) {
    let newPasswordDetail : NewPasswordDetail = { userName: userId.toString(), newPassword: password, tenantId: this.tenantId,propertyId: 1,oldPassword:"",isPasswordEncrypted:false } ;
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "CheckPasswordPut",
      method: HttpMethod.Put,
      body: newPasswordDetail,
      showError: false,
      extraParams: [false]
    });
  }

  async getEatecToken(callback: any) {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.authentication,
        callDesc: 'EatecToken',
        method: HttpMethod.Post,
        showError: true
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getEngageToken(callback: any) {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.authentication,
        callDesc: 'AcesToken',
        method: HttpMethod.Post,
        showError: true
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  VerifyPasswordAsync(userId: Int32Array, password: string, confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "VerifyPassword",
      method: HttpMethod.Get,
      uriParams: { UserId: userId, NewPassword: encodeURIComponent(password), TenantId: this.tenantId },
      showError: false,
      extraParams: [false]
    });
  }  
  VerifyPasswordAsyncPut(userId: Int32Array, password: string, confirmpassword: any) {
    let newPasswordDetail : NewPasswordDetail = { userName: userId.toString(), newPassword: password, tenantId: this.tenantId,propertyId: 1,oldPassword:"",isPasswordEncrypted:false  } ;

    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "VerifyPasswordPut",
      body : newPasswordDetail,
      method: HttpMethod.Put,
      showError: false,
      extraParams: [false]
    });
  }
  PasswordSettingAsync(confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "PasswordSetting",
      method: HttpMethod.Get,
      uriParams: { TenantId: this.tenantId },
      showError: false,
      extraParams: [false]
    });
  }

  SavePasswordAsync(userId: Int32Array, password: string, confirmpassword: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "SavePassword",
      method: HttpMethod.Post,
      uriParams: { UserId: userId, NewPassword: encodeURIComponent(password), TenantId: this.tenantId, PropertyId: 1 },
      showError: false,
      extraParams: [false]
    });
  }
  SavePasswordAsyncPost(userId: Int32Array, newPassword: string, confirmpassword: any,oldPassword:string, isEncrypted:boolean = false) {
    let newPasswordDetail : NewPasswordDetail = { userName: userId.toString(), newPassword: newPassword, tenantId: this.tenantId,propertyId: 1 ,
      oldPassword:oldPassword, isPasswordEncrypted:isEncrypted } ;
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: confirmpassword.successCallback.bind(confirmpassword),
      error: confirmpassword.errorCallback.bind(confirmpassword),
      callDesc: "SavePasswordPost",
      method: HttpMethod.Post,
      body : newPasswordDetail,
      showError: false,
      extraParams: [false]
    });
  }  
  async CreateSession(sessionData, login: any, isFromPMS: boolean) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: login.successCallback.bind(login),
      error: login.errorCallback.bind(login),
      callDesc: "CreateSession",
      body: sessionData,
      method: HttpMethod.Post,
      showError: false,
      extraParams: [isFromPMS]
    });
  }
  async GetEncryptionKey() {
   return  this.http.CallApiAsync<any>({
      host: Host.authentication,
      callDesc: "GetEncryptKey",
      method: HttpMethod.Get, 
      body: '',
      uriParams: '',
      showError : false
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "LogOut") {
      // Empty if condition.
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
// error call back
  }

  async AuthorizeBySession(propertyID: string,productId:number) {
    try {
      this.tenantId = this.utils.GetPropertyInfo('TenantId');
      let _userModel: any = {
        Client: this.utils.GetPropertyInfo('userName'), TenantId: Number(this.tenantId), PropertyId: Number(propertyID), TenantCode: '', ProductId: productId, ClientSecret: sessionStorage.getItem(this.userSessionId) ? sessionStorage.getItem(this.userSessionId) : ''
      }
      return await this.http.CallApiAsync<any>({
        host: Host.authentication,
        callDesc: 'AuthorizeBySession',
        method: HttpMethod.Post,
        body: _userModel,
        showError: true
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
}

