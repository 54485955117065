import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import _ from 'lodash';
import { CustomFormConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/custom-form/custom-form-configuration.interface';
import { CustomFormField } from 'src/app/eatecui/source/transaction/shared/interface/custom-form';
import { TransactionConfiguration } from '../transaction.interface';
import { QueryInterface } from 'src/app/eatecui/source/shared/models/query-interface';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { GridItemListActions } from 'src/app/eatecui/dist/AgilysysDynamicGrid';

export const GetBidRegion: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    DataCollection: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            const LocationId = FormGroupData.value[FieldGrouping.OdataKey];
            const paramsQuery: QueryParams = {
                count: true,
                top: InfiniteScrollItemCount,
                skip: 0,
                expand: '',
                select: 'Id, BidRegionId, SiteId',
                filter: '',
                orderby: '',
                isactive: true,
                search: '',
                filterQuery: 'Id in (' + LocationId + ')',
                searchtext: ''
            };
            const EndPointUrl = '/inventory/api/Locations' + `${QueryInterface.FormQuery(paramsQuery)}`;

            httpService.GethttpMethod(EndPointUrl).subscribe(async res => {
                const ResponseData = res['value'][0];
                const siteId = ResponseData ? ResponseData.SiteId : 0;
                const siteParamsQuery: QueryParams = {
                    count: true,
                    top: InfiniteScrollItemCount,
                    skip: 0,
                    expand: '',
                    select: 'Id, BidRegionId',
                    filter: '',
                    orderby: '',
                    isactive: true,
                    search: '',
                    filterQuery: 'Id in (' + siteId + ')',
                    searchtext: ''
                };

                if (ResponseData != null && ResponseData.BidRegionId != null && ResponseData.BidRegionId > 0) {
                    FormGroupData.controls['BidRegionId'].setValue(ResponseData.BidRegionId);
                } else {
                    const SiteUrl = '/inventory/api/Sites' + `${QueryInterface.FormQuery(siteParamsQuery)}`;
                    httpService.GethttpMethod(SiteUrl).subscribe(ress => {
                        const siteData = ress['value'][0];
                        if (siteData != null && siteData.BidRegionId != null && siteData.BidRegionId > 0) {
                            FormGroupData.controls['BidRegionId'].setValue(siteData.BidRegionId);
                        }
                        // else
                        // {
                        //     FormGroupData.controls['BidRegionId'].setValue(0);
                        // }
                    });
                }
                if ( FieldGrouping.CustomFormAction.hasOwnProperty('DisabledField')) {
                    let authPayload = {} as any;
                    authPayload.ClientUrl = '/inventory/api/GetCustomers';
                    const customerPostPayload = {} as any;
                    customerPostPayload.transTypeId = DataCollection.TransactionType;
                    customerPostPayload.locationId = FormGroupData.value.RequestLocId;
                    customerPostPayload.searchText = '';
                    customerPostPayload.skip = 0;
                    customerPostPayload.take = 100;
                    customerPostPayload.getTotalRecCount = true;
                    customerPostPayload.fetchAll = false;
                    customerPostPayload.totalCount = 0;
                    customerPostPayload.lookupField = '';
                    customerPostPayload.orderByColumn = 'Name';
                    customerPostPayload.orderByDirection = 1;
                    authPayload.Payload = customerPostPayload;
                    const customerResultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
                    FormField.forEach((x: any) => {
                        if (x.hasOwnProperty('fieldType') && x.fieldType.hasOwnProperty('SingleData') && 
                        x.fieldType.SingleData.name === 'CustomerId') {
                            x.fieldType.SingleData.disbaledProperty = false;
                        }
                    });
                    const customerDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'CustomerId');
                    FormField[customerDropdownIndex].fieldType.SingleData.autoCompleteData = customerResultData.ResultData;
                    FormField[customerDropdownIndex].autoCompleteConfig.EnableLoadMore = 
                    customerResultData.ResultData?.length === customerResultData.TotalCount ? false : true;
                }
            });

            if (DataCollection.OdataKey === 'PurchaseReqHead' || DataCollection.PostEntityName === 'PurchaseOrderHead') {
                if (DataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.hasOwnProperty('ShowDropdown')
                    && DataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.ShowDropdown) {
                    DataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.DropdownOptions =
                        dropdownOptions(DataCollection.CreateTranscationConfig.ItemsConfig.ItemList.
                            ItemGridConfig.GridItemListAction, httpService, DataCollection as TransactionConfiguration, LocationId);
                }
            }
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};


function dropdownOptions(DropDown: GridItemListActions, httpService: HttpService, TransactionCollection: TransactionConfiguration,
    LocationId: number) {
    try {
        const postObject = {
            'Skip': 0,
            'Take': 0,
            'FetchAll': true,
            'GetTotalRecCount': true,
            'TotalCount': 0,
            'LookupField': '',
            'OrderByColumn': '',
            'OrderByDirection': 0,
            'TransTypeId': 0,
            'LocationId': 0,
            'UserId': 0,
            'SiteId': 0,
            'Ids': 0,
            'IsActiveOnly': true,
            'SearchText': '',
            'FromLocationId': LocationId
        };
        httpService.PosthttpMethod(DropDown['DropdownUrl'], postObject).subscribe(res => {
            let ReturnData = [];
            if (TransactionCollection.PostEntityName.toLowerCase() === 'purchaseorderhead') {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate
                    };
                });
            } else {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate,
                        CurrencyName: result.CurrencyName
                    };
                });
            }
            const data = { 'Id': 0, 'Name': 'None' };
            ReturnData.unshift(data);
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.DropdownOptions
                = ReturnData;
        });
        return [];
    } catch (error) {
        console.error('Error occurred in dropdownOptions', error);
    }
}
