import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AgFieldConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { BaseResponse, RetailItemType } from 'src/app/common/shared/retail.modals';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { ItemSearchRequest, ItemSearchResponse } from 'src/app/shared/business/view-settings.modals';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { RetailSetupService } from 'src/app/retail/retail-setup/retail-setup.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import * as HostEnum from 'src/app/retail/shared/globalsContant';
import { ImageProcessorService } from 'src/app/shared/service/image-processor-service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { AlertType as AlertEnum, ButtonType as ButtonEnum} from '../../../shared/globalsContant';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import * as myGlobals from 'src/app/shared/globalsContant';
import { RetailRoutes } from 'src/app/retail/retail-route';

@Component({
  selector: 'app-create-lockers',
  templateUrl: './create-lockers.component.html',
  styleUrls: ['./create-lockers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateLockersComponent implements OnInit {
  isCreate: boolean;
  createLockersForm: UntypedFormGroup;
  propertyInfo: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() formReady = new EventEmitter();
  @Output() handleClickEvent = new EventEmitter();
  DialogCloseEnum = DialogCloseEnum;
  isDataDropped: boolean;
  searchTextChanged = new Subject<{ searchtxt }>();
  fileName: string;
  hoursInput: AgFieldConfig;
  minutesInput: AgFieldConfig;
  isToggleEnabled: boolean = false;
  LockerRetailItems: any[] = [];
  selectedFromList: any = [];
  searchKey = [];
  autoCompleteKeys: string[] = ['name'];
  selectedChipKey: string[] = ['name'];
  selectedNewChipKey: string[] = ['name'];
  searchPlaceholder: string = '';
  floatLabel: string;
  currencySymbol: any;
  title: string;
  Imageurl = '';
  linkedItemList: any;
  selectedItem: any;
  isImageRemoved: boolean;
  ImageUploaded: boolean;
  base64textString: string;
  thumbnailImg: any;
  isEdit: boolean = false;
  isImageUpload: boolean;
  imageUploaded: boolean;
  imageId: number;
  imageRemoved: boolean;
  imageReferenceId: string;
  editImageId: any;
  sequenceNo: any;
  includeLockerAssignment: Boolean;
  showError: boolean = false;
  outOfOrderMaxValue:number=999;
  isViewOnly:boolean = false;
  constructor(private localization: Localization, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<CreateLockersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private utils: SpaUtilities, private http: HttpServiceCall, public _retailservice: RetailSetupService, public PropertyInfo: RetailPropertyInformation, private imgService: ImageProcessorService, public _featureFlagInfo: RetailFeatureFlagInformationService, private utilities :CommonUtilities, private BP: BreakPointAccess) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.currencySymbol = this.localization.currencySymbol;
    this.includeLockerAssignment = this._featureFlagInfo.includeLockerAssignment ;
    let isAuthorised = this.BP.CheckForAccess([myGlobals.RetailBreakPoint.LockerType]);
    this.isViewOnly = this.BP.IsViewOnly(myGlobals.RetailBreakPoint.LockerType);
   }

  ngOnInit(): void {
    this.formGenerator();
    this.createLockersForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(_res => {
      this.actionButton.disabledproperty = !(!this.isViewOnly && this.createLockersForm.valid && this.createLockersForm.dirty && (this.selectedFromList.length > 0));
      this.actionButton = { ...this.actionButton };
    });
    if(this.isViewOnly) {
      this.actionButton.disabledproperty = true;
    }
  }
  async GetRetailItem(body: ItemSearchRequest){
    let result = await this.SearchRetailItem(body);
    this.linkedItemList = result;
    this.LockerRetailItems = result.retailItems.map(x => {
      return {
        id: x.id,
        name: x.itemNumber + ' - ' + x.itemDescription
      }
    });
  } 

  ngAfterViewInit() {
    this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(({ searchtxt }) => {
        let body: ItemSearchRequest = {
          itemType: RetailItemType.Lockers,
          requestId: searchtxt,
          searchKey: searchtxt
        }
        this.GetRetailItem(body);
      });
  }
  async SearchRetailItem(body: ItemSearchRequest): Promise<ItemSearchResponse> {
    let result: ItemSearchResponse;
    if(!body.searchKey || body.searchKey.trim() == ''){
      return result;
    }
    let response: BaseResponse<ItemSearchResponse> = await this.http.CallApiAsync<any>({
      host: Host.retailManagement,
      callDesc: 'SearchRetailItemsByType',
      method: HttpMethod.Put,
      body: body,
      showError: true
    });
    if (response && response.successStatus) {
      result = response.result;
    }
    return result;
  }
  formGenerator(){
    this.title = this.captions.lbl_createLockerType;
    this.createLockersForm = this.fb.group({
      id: '',
      lockerName: ['', Validators.required],
      lockerCode: ['', Validators.required],
      prefix: '',
      lockerQty: ['', Validators.required],
      listOrder: '',
      fileDate: '',
      fileName: '',
      fileExtension: '',
      fileStream: '',
      availableOnWeb: false,
      active: true,
      outofOrder: '',
      rateType: 0,
      rate: '',
      retailItemId: 0,
      itemId: '',
      hourlyRate: this.fb.array([
        this.fb.group({
          id: '',
          lockerInformationId: '',
          slabStart: ['1',Validators.required],
          slabEnd: ['',Validators.required],
          amount: ['0.00',Validators.required]
        })
      ])
    });
    if(!this.includeLockerAssignment){
      this.createLockersForm.controls.prefix.setValidators(null);
      this.createLockersForm.controls.prefix.updateValueAndValidity();
    }
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_create,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
    this.hoursInput = {
      className: 'w-75 pr-3',
      form: this.createLockersForm,
      formControlName: 'hours',
      placeHolderId: 'lbl_hours',
      placeHolder: this.captions.lbl_hours,
      maxlength: 2,
      isRequired: true,
      disabled: false,
      maxValue: 24,
      minValue: 0
    };
    this.minutesInput = {
      className: 'w-75',
      form: this.createLockersForm,
      formControlName: 'minutes',
      placeHolderId: 'lbl_minutes',
      placeHolder: this.captions.lbl_minutes,
      maxlength: 2,
      isRequired: true,
      disabled: false,
      maxValue: 60,
      minValue: 0
    };
    this.searchPlaceholder = `${this.captions.lbl_item_number} - ${this.captions.lbl_item_description}`;
    if (this.data) {
      this.isEdit = true;
      this.imgService.GetImagesByReference(this.data.editData.id, HostEnum.ImgRefType.locker, this.successCallback.bind(this), this.errorCallback.bind(this), []);
      this.getRetailItemById(this.data.editData.retailItemId).then(res => {
        if (res.result)
          this.selectedFromList = [{
            id: res.result.id,
            name: res.result.retailItemDetail.itemNumber + ' - ' + res.result.retailItemDetail.itemDescription
          }]
      });
      this.createLockersForm.patchValue(this.data.editData);
      this.actionButton.label = this.captions.btn_update;
      this.actionButton = {...this.actionButton};
      this.title = this.captions.lbl_editLockers;
      this.createLockersForm.controls.id.setValue(this.data.editData.id);
      this.createLockersForm.controls.lockerCode.setValue(this.data.editData.code);
      this.createLockersForm.controls.lockerName.setValue(this.data.editData.name);
      this.createLockersForm.controls.active.setValue(this.data.editData.isActive);
      this.createLockersForm.controls.listOrder.setValue(this.data.editData.listOrder);
      this.createLockersForm.controls.outofOrder.setValue(this.data.editData.outofOrderQuantity);
      this.createLockersForm.controls.prefix.setValue(this.data.editData.lockerPrefix);
      this.createLockersForm.controls.lockerQty.setValue(this.data.editData.quantity);
      this.createLockersForm.controls.availableOnWeb.setValue(this.data.editData.availableOnWeb);
      this.createLockersForm.controls.rate.setValue(this.data.editData.rate);
      this.createLockersForm.controls.rateType.setValue(this.data.editData.rateType);
      this.createLockersForm.controls.retailItemId.setValue(this.data.editData.retailItemId)
      const array = this.createLockersForm.get('hourlyRate') as FormArray;
      array.clear(); 
      this.data.editData?.lockerSlabRates.forEach(y => {
        const slabRates = this.fb.group({
          id: 0,
          lockerInformationId: 0,
          slabStart: [y.slabStart || 0],
          slabEnd: [y.slabEnd || 0],
          amount: [y.amount || 0]
        });
        array.push(slabRates);
      });
    }else{
      this.getNextListOrder().then(res => {
        if (res.result)
          this.createLockersForm.controls.listOrder.setValue(res.result);
      });
    }
    this.formReady.emit(this.createLockersForm);
  }
  async getRetailItemById(retailItemId) {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.retailManagement,
        callDesc: "GetRetailItemById",
        method: HttpMethod.Get, uriParams: { itemId: retailItemId },
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async getNextListOrder() {
    try {
      return await this.http.CallApiAsync<any>({
        host: Host.retailManagement,
        callDesc: RetailRoutes.GetNextListOrder_LockerInformation,
        method: HttpMethod.Get,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  updateLockerImage(lockerId: number) {
    if (this.base64textString && typeof this.base64textString !== 'undefined') {
      const base64result = this.base64textString.split(',');
      const base64Thumbnail = this.thumbnailImg.split(',');
      const imageDataObj = {
        referenceId: lockerId,
        referenceType: HostEnum.ImgRefType.locker,
        data: base64result[1],
        id: this.editImageId ? this.editImageId : 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: this.sequenceNo
      };
      if (this.editImageId) {
        this.imgService.updateImage(this.editImageId, imageDataObj, this.successCallback.bind(this), this.errorCallback.bind(this), []);
      }else{
        this.imgService.saveImage(imageDataObj, this.successCallback.bind(this),  this.errorCallback.bind(this), []);
      }
    } else {
      if (this.editImageId && this.isImageRemoved) {
        this.imgService.DeleteImageByReference(lockerId, HostEnum.ImgRefType.locker, this.successCallback.bind(this), this.errorCallback.bind(this), []);
      }
    }
  }
  
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    switch (callDesc) {
      case "getImagesByReference": {
        const retailItemImageDetails = result.result;
        if (retailItemImageDetails[0]) {
          this.Imageurl = `${retailItemImageDetails[0].contentType},${retailItemImageDetails[0].data}`;
          this.editImageId = retailItemImageDetails[0].id;
          this.sequenceNo = retailItemImageDetails[0].sequenceNo;
        } else {
          this.Imageurl = '';
          this.editImageId = '';
        }
      }
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  onAction(_eve) {
    if (this.isEdit) {
      this.updateLockerImage(this.data.editData.id);
    }
    this.handleClickEvent.emit({
      form: this.createLockersForm,
    });
    this.closePopUp(DialogCloseEnum.Action);
  }
  onCancel(_eve) {
    this.handleClickEvent.emit({
      form: this.createLockersForm,
      
    });
    this.closePopUp(DialogCloseEnum.Cancel)
  }
  closePopUp(from) {
    if (from == 2) {
      const base64result = this.isImageRemoved ? ['', ''] : this.base64textString?.split(',');
      const base64Thumbnail = this.isImageRemoved ? ['', ''] : this.thumbnailImg?.split(',');
      this.dialogRef.close({
        from: from,
        form: this.createLockersForm,
        base64result : base64result,
        base64Thumbnail: base64Thumbnail,
        isImageRemoved: this.isImageRemoved
      })
    } else {
      this.dialogRef.close();
    }
  }
  fileDeleted() {    
    this.createLockersForm.markAsTouched();
    this.createLockersForm.updateValueAndValidity();
    this.isImageUpload = true;
    this.isImageRemoved = true;
    this.imageUploaded = true;
  }

  fileUploaded(data) {
    this.createLockersForm.markAsDirty();
    this.createLockersForm.markAsTouched(); 
    this.createLockersForm.updateValueAndValidity();
    this.isImageUpload = true;
    this.base64textString = data['orgImg'];
    this.thumbnailImg = data['tmbImg'];
    this.thumbnailImg = this.thumbnailImg;    
    this.isImageRemoved = false;      
  }

  fileSizeExceeded() {
    this.utils.showAlert(this.captions.ImageSizeExceed, AlertEnum.Info, ButtonEnum.Ok);
  }
  onToggleChange(eve) {
    this.isToggleEnabled = eve[0];
  }

  selectedChipDataEmit(event) {
    if (event) {
      this.selectedItem = event[0];
      this.createLockersForm.controls.retailItemId.setValue(this.selectedItem.id);
      this.createLockersForm.markAsDirty();
    }
  }

  
  searchByKeyword(e) {
    if (e.length >= this.utilities.minCharSearch()) {
      this.searchTextChanged.next({ searchtxt: e });
    }else{
      this.searchTextChanged.next({ searchtxt: '' });
      this.LockerRetailItems = [];
    }
  }
  onChipRemoveEvent(event) {
    this.createLockersForm.get('retailItemId').setValue(0);
    this.createLockersForm.markAsDirty();
  }

  rateTypeChange(eve) {
    if(eve.value == 0) {
      this.createLockersForm.get('hourlyRate').enable();
      this.createLockersForm.get('rate').disable();
      this.createLockersForm.get('rate').setValidators(null)
    } else {
      this.createLockersForm.get('hourlyRate').disable();
      this.createLockersForm.get('rate').enable();
      this.createLockersForm.get('rate').setValidators([Validators.required])
    }
  }
  addItem(index){
    const controls=this.fb.group({
      slabStart: ['',Validators.required],
      slabEnd: ['',Validators.required],
      amount: ['',Validators.required]
    });
    let array = this.createLockersForm.get('hourlyRate') as UntypedFormArray;
    array.push(controls);
    this.updateRanges();
  }

  removeItem(index) {
    let array = this.createLockersForm.get('hourlyRate') as UntypedFormArray;
    array.removeAt(index);
    this.updateRanges();
  }
  minutesInputChange(index) {
    this.updateRanges();
  }
  updateRanges(): void {
    const currentRanges = this.createLockersForm.get('hourlyRate')['controls'];
    for (let i = 0; i < currentRanges.length; i++) {
      const start = i === 0 ? 1 : +currentRanges[i - 1].get('slabEnd').value + 1;
      currentRanges[i].controls.slabStart.setValue(start);
      if(currentRanges[i].controls.slabStart.value > currentRanges[i].controls.slabEnd.value){
        currentRanges[i]['controls']['slabEnd'].setErrors({ invalid: true });
      }
    }
  }
  onBlur() {
    this.showError = this.selectedFromList.length == 0 ? true : false;
  }
  lockerQuantityChange(eve) {
    if(Number(this.createLockersForm.controls.outofOrder.value) > Number(eve.target.value)) {
      this.createLockersForm.controls.outofOrder.setErrors({ invalid: true });
      this.createLockersForm.updateValueAndValidity();
    } else {
      this.createLockersForm.controls.outofOrder.setErrors(null);
      this.createLockersForm.updateValueAndValidity();
    }
    this.outOfOrderMaxValue = eve.target.value;
  }
  outOfOrderChange(eve) {
    if(Number(eve.target.value) > Number(this.outOfOrderMaxValue)) {
      this.createLockersForm.controls.outofOrder.setErrors({ invalid: true });
      this.createLockersForm.updateValueAndValidity();
    } else {
      this.createLockersForm.controls.outofOrder.setErrors(null);
      this.createLockersForm.updateValueAndValidity();
    }
    
  }
}

