import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AgFieldConfig } from '../../models/ag-models';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';
@Component({
  selector: 'app-spa-ag-number',
  templateUrl: './ag-number.component.html',
  styleUrls: ['./ag-number.component.scss']
})
export class AgNumberComponent implements OnInit {
  customClass: string;
  errorMessage: string;
  form: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  maxLength: string | number;
  minlength: string | number;
  captions: any;
  isRequired: boolean;
  disabled: boolean;
  maxValue: number;
  @Output() inputChange = new EventEmitter();
  @Output() outputValue = new EventEmitter();

  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.maxLength = value.maxlength;
    this.minlength = value.minlength;
    this.maxValue = value.maxValue;
    this.isRequired = value.isRequired;
    this.maxValue = value.maxValue;
    if (this.value) {
      this.form.controls[value.formControlName].setValue(this.value);
    }
    if (value.disabled) {
      this.form.controls[value.formControlName].disable();
    }

    if (this.maxValue) {
      this.form.controls[value.formControlName].setValidators(Validators.max(this.maxValue));
      this.form.controls[value.formControlName].markAsTouched();
    }
  }


  constructor(private _localization: SpaLocalization) { }

  ngOnInit() {
    this.captions = this._localization.captions;
  }

  inputChanged(event) {
    this.inputChange.emit(event);
  }

  valueEmit(_eve) {
    this.outputValue.emit(this.form.controls[this.controlName].value);
  }

}
