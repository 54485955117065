import { Component, OnInit, Output, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { RetailReportControl } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-retail-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class RetailTransactionComponent implements OnInit {
  @Input() outlets: any;
  public captions: any = this.localization.captions.reports;
  @Output() validateUser = new EventEmitter<boolean>();
  @Output() disableGenerateEvtEmitter: EventEmitter<any> = new EventEmitter();
  TransactionFormGrp: UntypedFormGroup;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  selectedDropdownType: string = '';
  valuesSelected: any[];
  users: DropDownData[];
  machineName: DropDownData[] = [];
  inActiveText = '';
  registers: any;
  validSelection: boolean = true;
  validSelectionErrMsg:string;
  showMachineDropDown: boolean = false; 
  isFromJobScheduler: boolean = false;
  editPatchValue: any;
  isFromEdit = false;
  categoryComponentFilter: any;
  timelineOptions: DropdownOptions[];
  constructor(private fb: UntypedFormBuilder, public business: ReportBusinessService, private dataService: ReportDataService, 
    public localization: RetailLocalization, private propertyInfo: RetailPropertyInformation,private container: ViewContainerRef)
  {
    this.inActiveText = this.captions.IncludeInactiveUsers;
  }

  ngOnInit() {
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
    this.showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;    
    this.TransactionFormGrp = this.fb.group({
      users: new UntypedFormControl([]),
      machineName: new UntypedFormControl([this.defaultData]),
      includeInactiveusers: false 
    });
    this.formReady.emit(this.TransactionFormGrp);
    this.onLoad();

 

    if (this.container) 
    {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;   
      this.isFromEdit = _parent.edit;
      this.PatchSchedulercontrols(_parent.edit);      
    }

    if(!this.isFromEdit)
      this.onLoad();

  }

  private PatchSchedulercontrols(isEdit) {
    if (this.localization.isFromJobScheduler && isEdit) {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      setTimeout(() => {      

        if(patchItem.UserIds != undefined){
          this.dataService.GetAllUsersByPropertyId().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.UserIds.find(x => x == item.id);
            });
            this.users = arr;
            this.users = this.business.toggleIncludeInactive(false,this.users);
            this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
          });
        }       

        if(patchItem.MachineNameId != undefined){
          this.dataService.GetMachineName().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.MachineNameId.find(x => x == item.id);
            });
            this.machineName = arr;
            this.business.FilterDataSource["allMachineNames"] = this.machineName;
          });
        }
     

      }, 1);
    }else{
      this.onLoad();
    }

  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsersByPropertyId();
    this.machineName = await this.dataService.GetMachineName();
    this.users = this.business.toggleIncludeInactive(false,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
    this.business.FilterDataSource["allMachineNames"] = this.machineName;
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.users = this.business.toggleIncludeInactive(IncludeInactiveToo,this.users);
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown);
    this.TransactionFormGrp.controls['users'].setValue([this.users]);
  }
 
  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption:any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validSelectionErrMsg = this.localization.replacePlaceholders(caption,["option"],[this.captions.User]);

    }
  }
  getFormControlValue(event) {
    if (event[0] === 'users') {
    this.TransactionFormGrp.setControl('users', event[1]);
    }
    else if (event[0] === 'machineName') {
      this.TransactionFormGrp.setControl('machineName', event[1]);
      } 
  }



}
