import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { NomathmenuFormComponent } from 'src/app/eatecui/source/transaction/nomathmenu-form/nomathmenu-form.component';
import { HeaderOverlayRef } from 'src/app/eatecui/dist/AgilysysDynamicGrid';

interface HeaderConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  columns?: any;
  gridName?: string;
  filterOptions?: string;
}

const DEFAULT_CONFIG: HeaderConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: '',
  columns: null
};

@Injectable({
  providedIn: 'root'
})
export class NomathMenuService {
  params: any;
  DivisionId: number;
  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  private createOverlay(config: HeaderConfig, reorder: ElementRef) {
    const overlayConfig = this.getOverlayConfig(config, reorder);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: HeaderConfig, reorder: ElementRef): OverlayConfig {
    const info = reorder.nativeElement.getBoundingClientRect();
    const remainingHeight = window.innerHeight - info.top;
    let offsetY: number = info.height;
    if (remainingHeight < 480 && this.DivisionId === 1) {
      offsetY = info.height - 490;
    } else if (remainingHeight < 310 && this.DivisionId === 2) {
      offsetY = info.height - 320;
    }
    const pos: ConnectedPosition [] = [
      {
        // offsetX : (info.right - 300),
        offsetY : offsetY,
        overlayX: 'end',
        overlayY: 'top',
        originX: 'end',
        originY: 'top',
      }
  ];
    const positionStrategy = this.overlay.position().flexibleConnectedTo(reorder).
    withPositions(pos);
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      disposeOnNavigation: true
    });

    return overlayConfig;
  }

  openResizeRows(config: HeaderConfig = {}, resize: ElementRef) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig, resize);

    // Instantiate remote control
    const dialogRef = new HeaderOverlayRef(overlayRef);

    const overlayComponent = this.attachReSizeDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe(_ => {dialogRef.close(); });
    return dialogRef;
  }

  private attachReSizeDialogContainer(overlayRef: OverlayRef, config: HeaderConfig, dialogRef: HeaderOverlayRef) {
    // const injectionTokens = new WeakMap();
    // injectionTokens.set(HeaderOverlayRef, dialogRef);
    // // injectionTokens.set(GRID_NAME, config.gridName);
    // const injector = new PortalInjector(this.injector, injectionTokens);
    // const containerPortal = new ComponentPortal(NomathmenuFormComponent, null , injector);
    // const containerRef: ComponentRef<NomathmenuFormComponent> = overlayRef.attach(containerPortal);
    // return containerRef.instance;
  }
}
