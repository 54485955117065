import { Injectable } from '@angular/core';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
 import {API } from './vat-configuration.model';
import { BuildingRoomTypeInput, BuildingRoomTypeRadioInput, BuildingRadioInput, AdditionalVATConfiguration } from './vat-configuration.model';
import {VatDataService} from './vat-configuration.data.service';

@Injectable()
export class VatConfigurationBusiness {
    captions: any = this.localization.captions.setting;

    constructor(private localization: RetailLocalization, private vatDataService: VatDataService) {
    }

    async GetDepartmentAndPostTypes(includeInActive : boolean)
    {
       return await this.vatDataService.GetDepartmentAndPostTypes(includeInActive);
    }

    MapDepartments(depts: API.Departments[]): BuildingRadioInput[] {
      let departmentData: BuildingRadioInput[] = [];
      let department: BuildingRadioInput;
      if (depts) {
        depts.forEach(dept => {
          department = {
            isSelected: false,
            id: dept.id,
            value: dept.id,
            viewValue: dept.name,
            selectedRooms: []
          };
          departmentData.push(department);
        }
        );
      }
      return departmentData;
    }
    
    MapPostTypes(inputPostTypes: API.PostType[], additionalVATConfiguration : AdditionalVATConfiguration): BuildingRoomTypeRadioInput[] {
      let postTypes: BuildingRoomTypeRadioInput[] = [];
      let postType: BuildingRoomTypeRadioInput;
      let selectedRooms : any[] =[];
      let selectedPostTypes : number[] =[];
      if(additionalVATConfiguration)
      {
       additionalVATConfiguration?.vatPostTypes?.forEach(postType=>
        {
          selectedPostTypes.push(postType.postTypeId);
        })
      }
      if (inputPostTypes) {
        inputPostTypes.forEach(post => {
          let selectedRoom = {
              id : post.id,
              departmentId : post.department,
              checked : selectedPostTypes ? selectedPostTypes?.findIndex(x=> x === post.id) !== -1 : false,
              value : post.id,
              viewValue : post.postTypeName,
              disabled : false
            }
            selectedRooms.push(selectedRoom);
          });
        inputPostTypes.forEach(post => {
          postType = {
            checked: selectedPostTypes ? selectedPostTypes.findIndex(x=> x === post.id) !== -1 : false,
            id: post.id,
            value: post.id,
            viewValue: post.postTypeName,
            departmentId: post.department,
            selectedRooms : selectedRooms
          };
          postTypes.push(postType);
        }
        );
      }
      return postTypes;
    }

    getDepartmentList() {
        return [
            {
                id: 1,
                value: 122,
                viewValue: "Rooms",
                isSelected: false,
                checked: false,
            },
            {
                id: 2,
                value: 123,
                viewValue: "Telephone",
                isSelected: false,
                checked: false,
            }
        ]
    }
    getPostingList() {
        return [
            {
              selectedRooms: [
                {
                  checked: false,
                  disabled: false,
                  id: 184,
                  outletId: 1,
                  value: 184,
                  viewValue: "Room Tax",
    
                }
              ]
            },
            {
                selectedRooms: [
                  {
                    checked: false,
                    disabled: false,
                    id: 184,
                    outletId: 1,
                    value: 184,
                    viewValue: "Room Charge",
      
                  }
                ]
              }
          ]
    }
}