import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from '../../localization/Localization';


@Component({
  selector: 'app-html-popup',
  templateUrl: './html-popup.component.html',
  styleUrls: ['./html-popup.component.scss']
})
export class HtmlPopupComponent implements OnInit {

  datRec:any;
  cancelButton: ButtonValue;
  captions: any;


  constructor( private localization:Localization,
    public dialogRef: MatDialogRef<HtmlPopupComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.datRec = this.data.datarecord;
    this.initializeInputs();
  }
  initializeInputs() {
    this.cancelButton = {
      type: 'primary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
 }
  dialogClose() {
    this.dialogRef.close();
  }
}
