import { Injectable } from "@angular/core";
import { CommonPaymentBusinessService } from "src/app/common/dataservices/payment-business.service";
import { PaymentMethod } from "src/app/common/shared/shared/business/shared.modals";
import { NonPMAgentRequestHandler } from '../agent-handler/NonPMAgentRequestHandler';

@Injectable()
export class AgentRequestHandler {

    isSkipPMAgent: boolean = false;
    constructor(
        private _payAgentService: CommonPaymentBusinessService,
        private _nonPMAgentService: NonPMAgentRequestHandler) {
        this.setSkipPMAgent(null);
    }

    checkIsSkipPMAgent() : boolean{
        return this.isSkipPMAgent;
    }

    get PaymentProcessor() : CommonPaymentBusinessService | NonPMAgentRequestHandler {
        return this.isSkipPMAgent ? this._nonPMAgentService : this._payAgentService;
    }

    private setSkipPMAgent(paymentMethod: PaymentMethod) {
        let result: boolean = false;

        //Property Level SkipPMAgent.
        const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        const IsPropertyLevelSkipPMAgent = propConfig?.SkipPMAgent ?? '';

        if (paymentMethod && paymentMethod?.additionalConfigurations) {
            //Payment Method Level SkipPMAgent.
            const paymentMethodAdditionalConfig = JSON.parse(paymentMethod?.additionalConfigurations);
            const payMethodConfig = paymentMethodAdditionalConfig?.find((x) => x.Key.toLowerCase().trim() === 'skippmagent');
            const IsPayMethodLevelSkipPMAgent = payMethodConfig?.Value ?? '';
            //To Handle UnSkip At Payment Method Level.
            if (IsPayMethodLevelSkipPMAgent.trim().toLowerCase() == 'false') {
                result = false;
            }
            else {
                result = (IsPropertyLevelSkipPMAgent.trim().toLowerCase() == 'true' || IsPayMethodLevelSkipPMAgent.trim().toLowerCase() == 'true');
            }
        }
        else {
            result = (IsPropertyLevelSkipPMAgent.trim().toLowerCase() == 'true');
        }
        this.isSkipPMAgent = result;
    }
}