import { TranslateService } from '@ngx-translate/core';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridSearchAction, GridSortAction, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridSearchConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): GridSearchAction[] => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                // const entityName = ViewHeaderEntity[0]['$']['Name'].toString();
                // const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                // TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSearch.forEach((searchConfig: GridSearchAction) => {
                //     searchConfig.SearchPlaceholderName = ProductDataRemap.FieldTranslateRemapEngine(
                //         productremapJson, entityName, searchConfig.SearchPlaceholderName, translateService);
                // });
                if (TransactionCollection.ViewTranscationConfig.hasOwnProperty('GridTypeSearchConfig') &&
                   TransactionCollection.ViewTranscationConfig.GridTypeSearchConfig.length > 0) {
                    let selectedId = 0;
                    TransactionCollection.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach(data => {
                        if (data.Type === 'dropdown') {
                            selectedId = data.SelectedTypeId;
                        }
                    });
                    const gridSearch =
                    TransactionCollection.ViewTranscationConfig.GridTypeSearchConfig.filter(x => x.TypeId === selectedId);
                    TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSearch = gridSearch;
                    return gridSearch;
                } else {
                    const gridSearch = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSearch;
                    return gridSearch;
                }
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };