import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';


@Component({
  selector: 'common-comment',
  templateUrl: './common-comment.component.html',
  styleUrls: ['./common-comment.component.scss']
})
export class CommonCommentComponent implements OnInit {

  localisation:any;
  voidGroup:UntypedFormGroup;
  @ViewChild("commentField") commentField: ElementRef;
  floatLabel: string;


  constructor(private fb:UntypedFormBuilder , private localization:RetailLocalization, private dialogRef:MatDialogRef<CommonCommentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  onCancelClick(){
    this.dialogRef.close({action : 'cancel'});
  }
  save(){
    this.dialogRef.close({action : 'ok', reason : this.voidGroup.controls['comments'].value});
  }

  ngOnInit() {
    this.localisation= this.localization.captions.common;
    this.voidGroup = this.fb.group({
      comments: ['']
    });
  }

  ngAfterViewInit() {
    if (this.commentField && this.commentField.nativeElement) {
      this.commentField.nativeElement.focus();
    }
  }

}
