import { Component } from '@angular/core';

@Component({
  selector: 'app-report-client',
  templateUrl: './report-client.component.html',
  styleUrls: ['./report-client.component.scss']
})
export class ReportClientComponent  {
 

}
