import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CellPosition, Column, GridOptions, NavigateToNextCellParams, TabToNextCellParams } from 'ag-grid-community';
import { GridHeaderComponent } from '../grid-header/grid-header.component';
// tslint:disable-next-line:max-line-length
import { ActionIconButtons, GridActionsConfig, GridConfig, GridFilterData, GridHeaderConfig, GridItemListActions, ResizeRows, TitleType } from '../interface/common';
import { NoItemsFoundComponent } from '../no-items-found/no-items-found.component';
import { RowActionsComponent } from '../row-actions/row-actions.component';
import * as globalConstants from '../shared/globalConstants';
import { DynamicGridService } from '../agilysys-grid.service';
import { Subscription } from 'rxjs';
import { IconRendererComponent } from '../icon-renderer/icon-renderer.component';
import { NumberCellEditorComponent } from '../cell-editors/number-cell-editor/number-cell-editor.component';
import { NameWithIconRendererComponent } from '../cell-renderer/name-with-icon-renderer/name-with-icon-renderer.component';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { TemperatureRendererComponent } from '../cell-renderer/temperature-renderer/temperature-renderer.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { GridActionsComponent } from '../grid-actions/grid-actions.component';
import { ApprovalActionsComponent } from '../cell-renderer/approval-actions/approval-actions.component';
import { TransactionTypeRendererComponent } from '../cell-renderer/transaction-type-renderer/transaction-type-renderer.component';
import { LinkActionsComponent } from '../cell-renderer/link-actions/link-actions.component';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';
import { GridHeaderService } from '../grid-header/grid-header.service';

@Component({
  selector: 'lib-grid-table',
  templateUrl: './grid-table.component.html',
  styleUrls: ['./grid-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() gridInput: GridConfig;
  @Input() configOption: any;
  params: any;
  gridApi: any;
  gridColumnApi: any;
  from: string;
  viewport: HTMLElement;
  lastIndex = 0;
  setColumn: Subscription;
  resizeRow: Subscription;
  resizeRowClass: string;
  filterEnable: boolean;
  gridOptions: GridOptions;
  rowData: any;
  columnData: any;
  globalConfiguration: GridActionsConfig;
  RowSelectable;
  rowClickedObject: any;
  getRowClass;
  previousClickedObject: any;
  frameworkComponents: any;
  selectedColumn = 'Qty';
  popupParent: any;
  overlayLoadingTemplate: any;
  @ViewChild('gridAction', { static: false }) gridAction: GridActionsComponent;
  constructor(
    private dynamicGridService: DynamicGridService,
    private dynamiceHeaderService: GridHeaderService
  ) {
    this.popupParent = document.querySelector("body");
    const _that = this;
    this.RowSelectable = function (rowNode) {
      if (_that.gridInput.hasOwnProperty('GridRowSelectable') && _that.gridInput.GridRowSelectable) {
        const data = _that.gridInput.GridRowSelectable[0];
        if (data.DefaultValue.indexOf(rowNode.data[data.OdataKey]) > -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    };
  }

  ngOnChanges(): void {
    console.log('AgilysysgridInput', this.gridInput);
    if (this.gridInput) {
      this.overlayLoadingTemplate = ( this.gridInput?.overlayLoadingTemplate ) ?  this.gridInput?.overlayLoadingTemplate : null;
      this.gridInput.GridData = this.gridInput.GridData === undefined ? [] : this.gridInput.GridData;
      this.rowData = this.gridInput.GridData === undefined || this.gridInput.GridData === null ? null : this.gridInput.GridData;
      this.columnData = this.gridInput.GridHeaderDefination;
      let selectedColumns = [];
      const hasQty = this.columnData?.findIndex(x => x.field === 'Qty');
      if (hasQty === -1) {
        selectedColumns = this.columnData?.filter(x => (x.headerCheckboxSelection  === undefined ||
          x.headerCheckboxSelection  === false) && x.editable === true);
          if (selectedColumns.length > 0) {
            this.selectedColumn = selectedColumns[0].field ? selectedColumns[0].field : this.selectedColumn;
          }
      }
      const globalConfig = globalConstants.GlobalConfig;
      this.gridInput.GridActions = this.gridInput.GridActions === undefined ? {} : this.gridInput.GridActions;
      if (!this.gridInput.TotalRecords) {
        this.gridInput.TotalRecords = this.gridInput.GridActions.TotalItems;
      }
      if (!this.gridInput.GridItemListAction) {
        this.gridInput.GridItemListAction = {} as GridItemListActions;
        this.gridInput.GridItemListAction.EnableCustomGridAction = false;
      }
      if (this.gridInput.GridItemListAction) {
        this.gridInput.GridItemListAction.SelectedListCount = this.gridInput.GridItemListAction.SelectedListCount ?
          this.gridInput.GridItemListAction.SelectedListCount : 0;
      }
      if (this.gridInput.GridItemListAction) {
        this.gridInput.GridItemListAction.IsActiveCount = this.gridInput.GridItemListAction.IsActiveCount ?
          this.gridInput.GridItemListAction.IsActiveCount : 0;
      }
      if (!this.gridInput.GridActions.hasOwnProperty('SearchConfig')) {
        this.gridInput.GridActions.SearchConfig = globalConfig.GlobalSearchConfiguartion;
      }
      if (!this.gridInput.GridActions.hasOwnProperty('PaginationConfig')) {
        this.gridInput.GridActions.PaginationConfig = globalConfig.GlobalPaginationConfiguration;
      }
      if (!this.gridInput.GridActions.hasOwnProperty('FilterConfig')) {
        this.gridInput.GridActions.FilterConfig = globalConfig.GlobalFilterConfiguration;
      }
      if (!this.gridInput.GridActions.hasOwnProperty('ShuffleColumnConfig')) {
        this.gridInput.GridActions.ShuffleColumnConfig = globalConfig.GlobalReshuffleConfiguration;
        if (globalConfig.GlobalReshuffleConfiguration.EnableColumnShuffle) {
          this.gridInput.GridActions.ShuffleColumnConfig.GridName = 'test';
          this.gridInput.GridActions.ShuffleColumnConfig.ColumnsList = this.gridInput.GridHeaderDefination;
        }
      } else if (this.gridInput.GridActions.hasOwnProperty('ShuffleColumnConfig')) {
        this.gridInput.GridActions.ShuffleColumnConfig.ColumnsList =
        this.gridInput.GridActions.ShuffleColumnConfig.ColumnsList ? this.gridInput.GridActions.ShuffleColumnConfig.ColumnsList
        : this.gridInput.GridHeaderDefination;
      }
      if (!this.gridInput.GridActions.hasOwnProperty('ResizeRowsConfig')) {
        this.gridInput.GridActions.ResizeRowsConfig = globalConfig.GlobalResizeRows;
      }
      if (!this.gridInput.GridActions.hasOwnProperty('InfiniteScrollConfig')) {
        this.gridInput.GridActions.InfiniteScrollConfig = globalConfig.GlobalInfiniteScrollConfiguration;
        if (globalConfig.GlobalInfiniteScrollConfiguration.EnableInfiniteScroll) {
          this.gridInput.GridActions.InfiniteScrollConfig.TotalRecords = this.gridInput.TotalRecords;
        }
      }
      if (this.gridInput['GridFilter'] && this.gridInput['GridFilter'].length > 0) {
        if (!this.gridInput.GridActions.FilterConfig['FilterOptions']) {
          this.gridInput.GridActions.FilterConfig['FilterOptions'] = [];
          this.gridInput['GridFilter'].forEach(element => {
            if (element.FilterValue && element.FilterValue.length > 0) {
              this.gridInput.GridActions.FilterConfig.FilterOptions.push(element);
            }
          });
        } else if (this.gridInput.GridActions.FilterConfig['FilterOptions'].length === 0) {
          this.gridInput.GridActions.FilterConfig['FilterOptions'] = [];
          this.gridInput['GridFilter'].forEach(element => {
            if (element.FilterValue && element.FilterValue.length > 0) {
              this.gridInput.GridActions.FilterConfig.FilterOptions.push(element);
            }
          });
        }
        if (this.gridInput.GridActions.FilterConfig.FilterOptions.length > 0) {
          this.gridInput.GridActions.FilterConfig.EnableFilter = true;
        } else {
          this.gridInput.GridActions.FilterConfig.EnableFilter = false;
        }
      }
      this.gridInput.GridActions.ShowRefresh = this.gridInput.GridActions.ShowRefresh ? this.gridInput.GridActions.ShowRefresh : false;
      if (!this.gridInput.GridActions.hasOwnProperty('GridTitleType')) {
        this.gridInput.GridActions.GridTitleType = {} as TitleType;
        this.gridInput.GridActions.GridTitleType.Type = 'text';
        this.gridInput.GridActions.GridTitleType.TypeConfig = [];
      }
      // Switching links in aside bar
      // Need to reset last index and scroll
      if (this.gridInput.ResetScroll) {
        this.from = '';
        this.lastIndex = 0;
        this.gridInput.ResetScroll = false;
      }

      const selectedLanguage = (this.configOption && this.configOption.language) ? this.configOption.language : globalConstants.Language.en;
      this.getTranslation(selectedLanguage);
    }
  }

  ngOnInit(): void {
    let rowHeight;
    if (localStorage.getItem('rowHeightData') && !JSON.parse(localStorage.getItem('rowHeightData'))) {
      localStorage.setItem(globalConstants.rowHeightData, JSON.stringify(globalConstants.resizeRows[1]));
      localStorage.setItem(globalConstants.defaultRowHeightData, JSON.stringify(globalConstants.resizeRows[1]));
      rowHeight = globalConstants.GridRowHeight.Comfort;
      this.resizeRowClass = globalConstants.resizeRows[1].Name.toLowerCase();
    } else {
      let rowheightDetails: ResizeRows = JSON.parse(localStorage.getItem(globalConstants.rowHeightData));
      rowheightDetails = rowheightDetails ? rowheightDetails : JSON.parse(localStorage.getItem(globalConstants.defaultRowHeightData));
      if (!rowheightDetails) {
        rowheightDetails = globalConstants.resizeRows[1];
      }
      localStorage.setItem(globalConstants.rowHeightData, JSON.stringify(rowheightDetails));
      localStorage.setItem(globalConstants.defaultRowHeightData, JSON.stringify(rowheightDetails));
      rowHeight = globalConstants.GridRowHeight[rowheightDetails?.Name];
      this.resizeRowClass = rowheightDetails?.Name.toLowerCase();
    }

    // Added for custom header component
    if (this.gridInput) {
      this.gridInput.GridCellRenderer = this.gridInput.GridCellRenderer === undefined ? {} : this.gridInput.GridCellRenderer;
    } else {
      this.gridInput = {} as GridConfig;
    }
    this.gridInput.GridCellRenderer = {
      headerComponent: GridHeaderComponent,
      actionRenderer: RowActionsComponent,
      iconRenderer: IconRendererComponent,
      // selectEditor: DropdownEditorComponent,
      // textEditor: TextEditorComponent,
      // textIconEditor: TextboxWithIconComponent,
      // numberEditor: NumberEditorComponent,
      nameWithIconRenderer: NameWithIconRendererComponent,
      // currencyEditor: CurrencyDropdownComponent,
      autocomplete: AutoCompleteComponent,
      // customDatePicker: DatePickerComponent,
      // gridDropDown: GridDropDownEditorComponent,
      temprenderer: TemperatureRendererComponent,
      // unitEditor: UnitsDropdownComponent,
      // multiSelectAutoComplete: MultiSelectAutoCompleteComponent,
      // dynamicDropdown: DynamicDropDownComponent,
      approvalActions: ApprovalActionsComponent,
      transactionType: TransactionTypeRendererComponent,
      linkActions: LinkActionsComponent,
      // dropdownSelector: DropdownSelectorComponent
    };
    // this.gridInput.GridCellEditor = {
    //   numberCellEditor: NumberCellEditorComponent
    // };
    if (this.gridInput.GridCustomComponents) {
      this.gridInput.GridCellRenderer = Object.assign(this.gridInput.GridCellRenderer, this.gridInput.GridCustomComponents.GridCustomCellRenderer);
      this.gridInput.GridCellEditor = this.gridInput.GridCustomComponents.GridCustomCellEditor
    }
    const _that = this;
    this.gridOptions = ({
      frameworkComponents: this.gridInput.GridCellRenderer,
      context: { parentComponent: this },
      components: this.gridInput.GridCellEditor,
      editType: 'fullRow',
      rowHeight: rowHeight,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      singleClickEdit: true,
      stopEditingWhenGridLosesFocus: this.gridInput.hasOwnProperty('stopEditingWhenGridLosesFocus') ? this.gridInput.stopEditingWhenGridLosesFocus : true,
      enableBrowserTooltips: true,
      noRowsOverlayComponentFramework: NoItemsFoundComponent,
      suppressPropertyNamesCheck: true,
      suppressColumnVirtualisation: true,
      rowDragManaged: true,
      rowClassRules: this.gridInput.RowClassRules ? 
      this.gridInput.RowClassRules : {
        'ag-row-selected': function (params) {
          if (_that.rowClickedObject && params.data.Id === _that.rowClickedObject.data.Id && _that.gridInput.HighlightRowOnClick) {
            return true;
          } else {
            return false;
          }
        },
        'ag-row-duplicate': function (params) {
          if (params && params.data && params.data.hasOwnProperty('AlreadyExists') && params.data.AlreadyExists) {
            return true;
          } else {
            return false;
          }
        }
      },
      tabToNextCell: this.tabToNextCell.bind(_that.gridApi, this),
      navigateToNextCell: this.navigateToNextCell.bind(_that.gridApi, this),
      suppressKeyboardEvent: function(params) {
        const KEY_SPACE = 32;
        const KEY_ENTER = 13;
        const KEY_ESCAPE = 27;
        const event = params.event;
        const key = event.which;
        const keysToSuppress = [KEY_SPACE, KEY_ESCAPE];
        const suppress = keysToSuppress.indexOf(key) >= 0;
        // console.log('suppress', suppress);
        return suppress;
      },
      enableRangeSelection: true
    } as GridOptions);

    this.setColumn = this.dynamicGridService.setColumnDefs.subscribe(x => {
      if (x) {
        if (x[0].length > 0 && this.gridApi && x[1] === this.gridInput.GridActions.ShuffleColumnConfig.GridName) {
          this.gridApi.setColumnDefs([]);
          this.gridApi.setColumnDefs(x[0]);
          if (this.gridInput.hasOwnProperty('columnChanged')) {
            this.gridInput.columnChanged(x);
          }
        }
      }
    }, (error) => {
      console.error('Error occurred in subscribe of setColumnDefs', error);
    });

    this.resizeRow = this.dynamicGridService.resizeRows.subscribe(x => {
      if (x) {
        this.rearrangeHeight(x);
      }
    }, (error) => {
      console.error('Error occurred in subscribe of resizeRows', error);
    });
  }

  onGridReady(params): void {
    try {
      this.params = params;
      this.gridApi = params.api;
      this.gridInput.GridApi = params.api;
      this.gridColumnApi = params.columnApi;
      if (this.rowData && this.rowData.length === 0) {
        this.gridApi.showNoRowsOverlay();
      }

      if (this.gridInput.GridActions && this.gridInput.GridActions.InfiniteScrollConfig.EnableInfiniteScroll) {
        const parentElement = this.gridInput.ParentId ? document.getElementById(this.gridInput.ParentId)
        : document;
        this.viewport = parentElement ? parentElement.getElementsByClassName('ag-body-viewport')[0] as HTMLElement
        : null;
        if (this.viewport) {
          this.viewport.addEventListener('scroll', this.scrollEvent);
        }
      }
    } catch (error) {
      console.error('Error occurred in onGridReady', error);
    }
  }

  /**
   * @method scrollEvent
   * @param event;
   * @description Scroll function for infinite scroll concept when scroll reaches bottom event will be emitted to parent to get next data
   */
  scrollEvent = (event) => {
    try {
      // console.log('Scroll Height, Top', Math.ceil(event.target.scrollHeight),
      //   Math.ceil(event.target.scrollTop + event.target.offsetHeight));
      if (Math.ceil(event.target.scrollHeight) === Math.ceil(event.target.scrollTop + event.target.offsetHeight)
      || Math.ceil(event.target.scrollHeight) <= Math.ceil(event.target.scrollTop + event.target.offsetHeight)) {
        if (this.gridInput.TotalRecords !== this.gridInput.GridData.length) {
          this.from = 'scroll';
          if (this.gridInput.hasOwnProperty('bottomReached')) {
            this.gridInput.bottomReached(true);
          }
        }
      }
    } catch (error) {
      console.error('Error occurred in scrollEvent', error);
    }
  }

  /**
   * @method rowDataChanged
   * @param event;
   * @description When rowdata changed , to retain the scroll to previous position
   */

  rowDataChanged(event): void {
    try {
      this.gridInput.GridApi = event.api;
      if (this.from === 'scroll') {
        const itemcount = this.gridInput.ItemsPerPage ? this.gridInput.ItemsPerPage
          : globalConstants.itemsPerPage;
        this.lastIndex = this.lastIndex + itemcount;
        this.gridApi.ensureIndexVisible(this.lastIndex - 1, 'bottom');
      }
      if (this.gridInput.hasOwnProperty('rowDataChanged')) {
        this.gridInput.rowDataChanged(event);
      }
    } catch (error) {
      console.error('Error occurred in rowDataChanged', error);
    }
  }

  /**
   * @method sortData
   * @param event;
   * @description sort order changed
   */

  sortData(event: any) {
    try {
      this.lastIndex = 0;
      this.from = '';
      if (this.gridInput.hasOwnProperty('sortChanged')) {
        this.gridInput.sortChanged(event);
      }
      if (this.gridInput.hasOwnProperty('columnChanged')) {
        this.gridInput.columnChanged([event[2], this.gridInput.GridActions.GridTitle]);
      }
    } catch (error) {
      console.error('Error occurred in rowClicked', error);
    }
  }

  /**
   * @method rowClicked
   * @param event;
   * @description Row clicked to navigate to next page or do some other actions
   */

  rowClicked(event) {
    try {
      // event.context.parentComponent.selectedColumn = 'Qty';
      if (this.gridInput.hasOwnProperty('rowClicked')) {
        this.previousClickedObject = this.rowClickedObject;
        this.rowClickedObject = event;
        this.gridInput.rowClicked(event);
        if (this.gridInput.HighlightRowOnClick) {
          const row = this.gridApi.getDisplayedRowAtIndex(event.rowIndex);
          this.gridApi.redrawRows({ rowNodes: [row] });
          if (this.previousClickedObject) {
            const rowa = this.gridApi.getDisplayedRowAtIndex(this.previousClickedObject.rowIndex);
            this.gridApi.redrawRows({ rowNodes: [rowa] });
          }
        }
      }
    } catch (error) {
      console.error('Error occurred in rowClicked', error);
    }
  }

  /**
   * @method onSelectionChanged
   * @param event;
   * @description Row is checked
   */

  onSelectionChanged(event) {
    try {
      if (this.gridInput.hasOwnProperty('rowChecked')) {
        const selectedData = this.gridApi.getSelectedRows();
        this.gridApi.forEachNode((rowNode, index) => {
          if (rowNode.data) {
            rowNode.data.Checked = rowNode.isSelected();
          }
        });
        selectedData.map(data => {
          data.Checked = true;
        });
        this.gridInput.GridItemListAction.IsActiveCount = selectedData?.filter(item => item?.IsActive).length;
        this.gridInput.GridItemListAction.SelectedListCount = selectedData.length;
        if (this.gridInput.GridItemListAction && this.gridInput.GridItemListAction.ActionIcons &&
           this.gridInput.GridItemListAction.ActionIcons.length > 0) {
          this.gridInput.GridItemListAction.ActionIcons[0].Enable = selectedData.length > 0 ? true : false;
          if (this.gridInput.GridItemListAction.ActionIcons[1] && this.gridInput.GridItemListAction.ActionIcons[1].Name !== 'ColumnConfiguration') {
            this.gridInput.GridItemListAction.ActionIcons[1].Enable = selectedData.length > 0 ? true : false;
          }
        }
        this.gridInput.rowChecked(selectedData, this.gridInput.GridData);
      }
    } catch (error) {
      console.error('Error occurred in onSelectionChanged', error);
    }
  }

  /**
   * @method rowValueChanged
   * @param event;
   * @description Rowdata value changed will be triggered for edit scenario
   */

  rowValueChanged(event) {
    try {
      if (this.gridInput.hasOwnProperty('rowValueChanged') && this.gridInput['rowValueChanged'] !== undefined) {
        this.gridInput.rowValueChanged(event);
      }
    } catch (error) {
      console.error('Error occurred in rowValueChanged', error);
    }
  }
    /**
   * @method onCellValueChanged
   * @param event;
   * @description Rowdata value changed will be triggered for edit scenario
   */

     onCellValueChanged(event: any) {
      try {
        if (this.gridInput.hasOwnProperty('onCellValueChanged')) {
          this.gridInput.onCellValueChanged(event);
        }
      } catch (error) {
        console.error('Error occurred in rowValueChanged', error);
      }
    }

  /**
   * @method searchTextChange
   * @param event;
   * @description Search text value change function
   */

  searchTextChange(event) {
    try {
      this.lastIndex = 0;
      this.from = '';
      if (this.gridInput.hasOwnProperty('searchTextChanged')) {
        this.gridInput.searchTextChanged(event);
      }
    } catch (error) {
      console.error('Error occurred in searchTextChange', error);
    }
  }

  /**
   * @method rowActionButtonClick
   * @param event;
   * @description Edit or delete in row is clicked
   */

  rowActionButtonClick(params, event, data) {
    try {
      if (this.gridInput.hasOwnProperty('rowActionClicked')) {
        this.gridInput.rowActionClicked([event, data, params]);
      }
    } catch (error) {
      console.error('Error occurred in rowActionButtonClick', error);
    }
  }

  ngOnDestroy(): void {
    try {
      if (this.setColumn) {
        this.setColumn.unsubscribe();
      }
      if (this.resizeRow) {
        this.resizeRow.unsubscribe();
      }
    } catch (error) {
      console.error('Error occurred in ngOnDestroy', error);
    }
  }

  /***
   * @param data: ResizeRows
   * @method rearrangeHeight
   * @description To reset the height based on selection
   */
  rearrangeHeight(data: ResizeRows) {
    try {
      let rowHeight = 45;
      switch (data.Id) {
        case 1: {
          rowHeight = 60;
          break;
        }
        case 2: {
          rowHeight = 45;
          break;
        }
        case 3: {
          rowHeight = 30;
          break;
        }
      }
      setTimeout(() => {
        this.gridOptions.rowHeight = rowHeight;
        if (this.gridApi) {
          this.gridApi.resetRowHeights();
        }
        this.resizeRowClass = data.Name.toLowerCase();
      }, 100);
    } catch (error) {
      console.error('Error occurred in rearrangeHeight', error);
    }
  }

  /**
   * @method getTranslation
   * @params lang
   * @description to get json dynamically based on language
   */
  async getTranslation(lang: string) {
    try {
      // tslint:disable-next-line:prefer-const
      let result: any;
      // await import(`../../assets/i18n/${lang}.json`).then((data) => {
      //   result = data.default;
      // });
      this.dynamicGridService.languageModifier = result;
    } catch (error) {
      console.error('Error occurred in getTranslation', error);
    }
  }

  fetchFilterValues(event: GridFilterData) {
    try {
      if (this.gridInput.hasOwnProperty('fetchFilterDatas')) {
        this.gridInput.fetchFilterDatas(event);
      }
    } catch (error) {
      console.error('Error occurred in fetchFilterValues', error);
    }
  }

  /**
   * @method applyFilter
   * @params event
   * @description Output emitter for parent component
   */
  applyFilter(event) {
    this.lastIndex = 0;
    this.from = '';
    if (this.gridInput.hasOwnProperty('filterApplied')) {
      this.gridInput.filterApplied(event);
    }
  }

  toggleChange(event) {
    if (this.gridInput.hasOwnProperty('toggleChange')) {
      this.gridInput.toggleChange(event);
    }
  }

  dropdownChanged(event) {
    if (this.gridInput.hasOwnProperty('dropdownChange')) {
      this.gridInput.dropdownChange(event);
    }
  }

  addButtonClick(data: GridActionsConfig) {
    if (this.gridInput.hasOwnProperty('addNewButtonClicked')) {
      this.gridInput.addNewButtonClicked(data);
    }
  }

  refreshButtonClick(data: GridActionsConfig) {
    if (this.gridInput.hasOwnProperty('refreshButtonClicked')) {
      this.from = '';
      this.lastIndex = 0;
      this.gridInput.refreshButtonClicked(data);
    }
  }

  moreData(data, value) {
    if (this.gridApi) {
      this.gridApi.setColumnDefs();
      this.gridApi.setColumnDefs(this.columnData);
    }
  }

  iconActionClick(data: ActionIconButtons) {
    if (this.gridInput.hasOwnProperty('iconActionClicked')) {
      this.gridInput.iconActionClicked(data);
    }
  }
  deactivateClick(data: any) {
    if (this.gridInput.hasOwnProperty('deactivateClicked')) {
      this.gridInput.deactivateClicked(data);
    }
  }

  rowNameIconClicked(params) {
    if (this.gridInput.hasOwnProperty('rowNameInfoClicked')) {
      this.gridInput.rowNameInfoClicked(params);
    }
  }

  componentChanged(event) {
    // For Selecting the row data
    if (this.gridInput.GridItemListAction && this.gridInput.GridItemListAction.ActionIcons) {
      const activeList = this.rowData.filter(x => x.IsActive === true && x.Checked === true).length;
      const selectedList = this.rowData.filter(x => x.Checked === true).length;
      const selectedIds = [];
      this.rowData.map(res => {
        if (res.Checked) {
          selectedIds.push(res.Id);
        }
      });
      if (selectedList > 0) {
        this.gridApi.forEachNode((node, index) => {
          if (node && selectedIds.includes(node.data.Id)) {
            node.setSelected(true);
          }
        });
      }
      console.log('activeList', activeList);
      this.gridInput.GridItemListAction.IsActiveCount = activeList;
      this.gridInput.GridItemListAction.SelectedListCount = selectedList;
      if (this.gridInput.GridItemListAction.ActionIcons[0]) {
        this.gridInput.GridItemListAction.ActionIcons[0].Enable = selectedList > 0 ? true : false;
      }
      if (this.gridInput.GridItemListAction.ActionIcons[1] &&
          this.gridInput.GridItemListAction.ActionIcons[1].Name !== 'ColumnConfiguration') {
        this.gridInput.GridItemListAction.ActionIcons[1].Enable = selectedList > 0 ? true : false;
      }
    }
    if (this.gridInput.RowSelectedList && this.gridInput.RowSelectedList.length > 0) {
      this.gridApi.forEachNode((node, index) => {
        if (node && this.gridInput.RowSelectedList.includes(node.data.GridId)) {
          node.setSelected(true);
        }
      });
    }
  }

  lookupPopupOpen(params) {
    if (this.gridInput.hasOwnProperty('lookUpClick')) {
      this.gridInput.lookUpClick(params);
    }
  }

  globaldeleteClick() {
    if (this.gridInput.hasOwnProperty('globalDeleteClick')) {
      this.gridInput.globalDeleteClick();
    }
  }

  rowDragEnded(event) {
    console.log('rowDragEnded', event);
    const DraggedObject = event.node.data;
    const currentIndex = event.overIndex;
    const previousIndex = this.rowData.findIndex(x => x === DraggedObject);
    // this.rowData.splice(DraggedDataIndex, 1, DraggedObject);
    moveItemInArray(this.rowData, previousIndex, currentIndex);
    console.log('Row Data', this.rowData);
    this.gridInput.GridData = [...this.rowData];
    if (this.gridInput.hasOwnProperty('rowDragEnded')) {
      this.gridInput.rowDragEnded(this.rowData);
    }
  }

  tabToNextCell (grid, params: TabToNextCellParams): CellPosition | null {
    const nextCellPosition = params.nextCellPosition;
    const previousCellPosition = params.previousCellPosition;
    if (nextCellPosition.column.getColDef().editable === true && nextCellPosition.column.getColDef().cellEditor !== 'textIconEditor') {
      grid.selectedColumn = params.nextCellPosition.column['colDef']['field'];
      return params.nextCellPosition;
    } else {
      // tslint:disable-next-line:prefer-const
      let rowIndex = previousCellPosition.rowIndex;
      const allColumns: Array<GridHeaderConfig> = grid.columnData;
      const editableColumns = allColumns.filter(x => x.editable === true && x.cellEditor !== 'textIconEditor');
      const currentColumnIndex = editableColumns.findIndex(x => x.field === previousCellPosition.column.getColDef().field);
      const nextEditableCellField = editableColumns[currentColumnIndex + 1];
      let result: CellPosition;
      if (nextEditableCellField) {
        const nextEditableCellPosition: Column = grid.gridColumnApi.getColumn(nextEditableCellField.field);
        grid.selectedColumn = nextEditableCellPosition['colDef']['field'];
        result = {
          rowIndex: rowIndex,
          column: nextEditableCellPosition,
          rowPinned: null
        };
      } else {
        // tslint:disable-next-line:prefer-const
        let nextRowIndex = params.backwards ? rowIndex - 1 : rowIndex + 1;
        grid.gridApi.stopEditing();
        const nextEditablePosition: Column = grid.gridColumnApi.getColumn(editableColumns[0].field);
        grid.selectedColumn = nextEditablePosition['colDef']['field'];
        grid.gridApi.startEditingCell({
          rowIndex: nextRowIndex,
          colKey: editableColumns[0].field
        });
        const firstColumn: Column = grid.gridColumnApi.getColumn(grid.columnData[0].field);
        grid.gridApi.ensureColumnVisible(firstColumn);
        grid.gridApi.setFocusedCell(nextRowIndex, editableColumns[0].field);
        result = {
          rowIndex: nextRowIndex,
          column: nextEditablePosition,
          rowPinned: null
        };
      }
      console.log('Params', params);
      console.log('NextCellPosition', result);
      return result;
    }
  }

  headerCheckBoxChange(event, params: any) {
    try {
      if (this.gridInput.hasOwnProperty('headerCheckBoxChecked')) {
        this.gridInput.headerCheckBoxChecked(event, params);
      }
    } catch (error) {
      console.error('Error occurred in headerCheckBoxChange', error);
    }
  }

  filterEnabled(enable: boolean) {
    try {
      this.filterEnable = enable;
      if (this.gridInput.hasOwnProperty('gridFilterEnabled')) {
        this.gridInput.gridFilterEnabled(enable);
      }
    } catch (error) {
      console.error('Error occurred in filterEnabled', error);
    }
  }

  onCellClicked(event) {
    try {
      if (event) {
        const firstEditable = this.gridInput.GridHeaderDefination.filter(x => x.editable === true)[0];
        if (firstEditable) {
          const columnName = event.colDef.editable ? event.colDef.field : firstEditable.field;
          if (this.selectedColumn !== columnName && columnName) {
            this.selectedColumn = columnName ? columnName : this.selectedColumn;
            this.gridApi.setFocusedCell(event.rowIndex, this.selectedColumn);
            const columnData: GetCellEditorInstancesParams = {
              rowNodes: [event.node],
              columns: [this.selectedColumn],
            };
            const columnDatainstances = this.params.api.getCellEditorInstances(columnData);
            if (columnDatainstances.length > 0) {
              columnDatainstances[0].focusIn();
            }      
          }
        }
      }
    } catch (error) {
      console.error('Error occurred in onCellClicked', error);
    }
  }

  navigateToNextCell(grid, params: NavigateToNextCellParams): CellPosition | null {
    const previousCell = params.previousCellPosition,
      suggestedNextCell = params.nextCellPosition;
    // tslint:disable-next-line:prefer-const
    let nextRowIndex, renderedRowCount;
    if (params.event.key.toString() === KEY_LEFT || params.event.key.toString() === KEY_RIGHT) {
      grid.selectedColumn = suggestedNextCell.column['colDef']['field'] !== 'check' &&
      suggestedNextCell.column['colDef']['field'] !== '' ? suggestedNextCell.column['colDef']['field'] : grid.selectedColumn;
    } else {
      grid.selectedColumn = previousCell.column['colDef']['field'];
    }
    return suggestedNextCell;
    // switch (params.event.key.toString()) {
    //   case KEY_DOWN:
    //     // return the cell above
    //     nextRowIndex = previousCell.rowIndex + 1;
    //     renderedRowCount = grid.gridApi.getModel().getRowCount();
    //     if (nextRowIndex >= renderedRowCount) {
    //       return previousCell;
    //     } // returning null means don't navigate
    //     grid.selectedColumn = previousCell.column['colDef']['field'];
    //     grid.gridApi.setFocusedCell(nextRowIndex, grid.selectedColumn);
    //     console.log('Focuesed Cell', grid.gridApi.getFocusedCell());
    //     return {
    //       rowIndex: nextRowIndex,
    //       column: previousCell.column,
    //       rowPinned: previousCell.rowPinned,
    //     };
    //   case KEY_UP:
    //     // return the cell below
    //     nextRowIndex = previousCell.rowIndex - 1;
    //     if (nextRowIndex < 0) {
    //       return previousCell;
    //     } // returning null means don't navigate
    //     grid.selectedColumn = previousCell.column['colDef']['field'];
    //     grid.gridApi.setFocusedCell(nextRowIndex, grid.selectedColumn);
    //     console.log('Focuesed Cell', grid.gridApi.getFocusedCell());
    //     return {
    //       rowIndex: nextRowIndex,
    //       column: previousCell.column,
    //       rowPinned: previousCell.rowPinned,
    //     };
    //   case KEY_LEFT:
    //   case KEY_RIGHT:
    //     grid.selectedColumn = suggestedNextCell.column['colDef']['field'];
    //     grid.gridApi.setFocusedCell(suggestedNextCell.rowIndex, grid.selectedColumn);
    //     return suggestedNextCell;
    //   default:
    //     throw Error(
    //       'this will never happen, navigation is always one of the 4 keys above'
    //     );
    // }
  }

  zoomInfoClicked(event, value) {
    try {
      if (this.gridInput.hasOwnProperty('zoomInfoClicked')) {
        this.gridInput.zoomInfoClicked(event, value);
      }
    } catch (error) {
      console.log('Error occurred in zoomInfoClicked', error);
    }
  }
}


  const KEY_LEFT = 'ArrowLeft';
  const KEY_UP = 'ArrowUp';
  const KEY_RIGHT = 'ArrowRight';
  const KEY_DOWN = 'ArrowDown';
