import { SpaServiceTherapist, TherapistDetails } from "src/app/shared/business/view-settings.modals";
import { ImageData } from 'src/app/shared/business/shared.modals';

export interface Therapist {
    id: number;
    serviceTherapist: SpaServiceTherapist[];
    therapistDetail: TherapistDetails;
}

export interface ServiceLocation {
    id: number;
    serviceId: number;
    locationId: number;
}

export interface ServiceGroup {
    id: number;
    code: string;
    description: string;
    listOrder: number
}

export interface TherapistView {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
    gender: string;
    seniorityLevel: string;
    isActive: boolean;
    listOrder: number;
    serviceGroup: ServiceGroup[];
    services: TherapistServiceInfo[];
    image: ImageData;
}

export interface LocationView {
    id: number;
    name: string;
    serviceGroup: ServiceGroup[];
    services: LocationServiceInfo[];
}

export interface TherapistServiceInfo {
    id: number;
    code: string;
    description: string;
    serviceGroupId: number
    serviceCount: number | string;
    seniorityLevel: string;
    certified: boolean;
    isActive: boolean;
}

export interface LocationServiceInfo {
    id: number;
    code: string;
    description: string;
    serviceGroupId: number
    certified: boolean;
    isActive: boolean;
}

export enum AppointmentView {
    TherapistView = 'TV',
    LocationView = 'LV',
    AvailableTherapistView = 'ATV'
}

export enum searchtype {
    ByTherapist = 0,
    ByLocation = 1
}

export interface Earnings{
    id: number;
    folioInvoiceNumber: string;
    earningNumber: string;
    clientName: string;
    serviceDescription: string;
    timeline: string;
    gratuity: number;
    commission: number;
    serviceCharge: number;
    total: number;
}
