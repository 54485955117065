import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AgFieldConfig } from '../../Models/ag-models';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';
import { Localization } from "src/app/common/localization/localization";

@Component({
  selector: 'app-ag-postal-code',
  templateUrl: './ag-postal-code.component.html'
})
export class AgPostalCodeComponent  {
  customClass: string;
  errorMessage: string;
  groupName: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  placeHolderId: string;
  errorMessageId: string;
  floatLabel: string;


  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.groupName = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.errorMessageId = value.errorMessageId;
    this.placeHolderId = value.placeHolderId;
  }


  constructor(public dialog: MatDialog,private localization: Localization ) {this.floatLabel = this.localization.setFloatLabel; }

  onControlClick() {
    if (document.designMode == 'on') {
     this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: this.placeHolder,
          oldErrorMessage: this.errorMessage,
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result) {
          this.placeHolder = result.newplaceholder;
          this.errorMessage = result.newErrorMessage;
        }
      });
    }
  }
}
