import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { LoaderService } from 'src/app/eatecui/source/core/services/loader.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { FieldEdmType } from '../../../setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import { EnvService } from '../../../config.service';
import { CreditPaymentDetails, FGCollection, FileAttachmentConfig, GridHeaderConfig, OrderSummary, PlanDetailsQty, POTaxDetails,
   SalesOthersSummary, TaxDetails, TransactionConfiguration, UnitModel } from '../interface/transaction.interface';
import * as _ from 'lodash';
import { transactionPost } from '../interface/transaction-post';
import moment from 'moment';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { ToastrService } from 'ngx-toastr';
import { Formaters as ValueFormatter} from 'src/app/eatecui/source/shared/models/formaters';
import { Validation } from 'src/app/eatecui/source/shared/utilities/data.validator';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import * as constants from '@shared/constant/GlobalConstants';

@Injectable({
  providedIn: 'root'
})

export class TransactionService {
  transactionConfigurationData: TransactionConfiguration;
  initialTransactionFormGroup: any;
  transactionFormGroup: FormGroup;
  currencyList: any;
  unitList = [];
  spoilageReasonsList = [];
  shoppingGridList = [];
  poCloneGridData: Array<any>;
  PostDeactivate: any;
  FlowMode: any;
  FileAttachmentConfig: FileAttachmentConfig;
  intergerationInstance: any;
  postMappingFormValue: any;
  serviceCharges = new Subject();
  quickOrderSummaryData: any;
  constructor(
    private envService: EnvService,
    private store: Store<AppState>,
    private loaderService: LoaderService,
    private httpService: HttpService,
    private http: HttpClient,
    private sysytemPreferenceService: SysytemPreferenceService,
    private toastrService: ToastrService,
    private commonService: CommonService
  ) { }

  /**
*@method GetTransactionJsonData()
*@params jsonData and odatakey
*@description getjsonresponseform the data
* */
  GetTransactionJsonData(JsonKey: string) {
    try {
      const checkTenant =  sessionStorage.getItem('RGuestTenantId');
      if ( checkTenant && JSON.parse(checkTenant) && JsonKey === 'transfer'  ) {
        JsonKey = `rguest-${JsonKey}`;
      }
      if (JsonKey && JsonKey.toLowerCase() === 'packages') {
        JsonKey = 'EatecPackages';
      }
      const getTransaction = 'app/eatecui/assets/json/transaction/' + JsonKey + '.json';
      return this.http.get<TransactionConfiguration>(getTransaction).toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @getOdataKey(transactionConfiguration:TransactionConfiguration, transactionKey)
   * @prams transactionConfiguration
   */
  getOdataKey(transactionConfiguration: TransactionConfiguration, transactionKey: string): string {
    let OdataKey = transactionConfiguration.OdataKey;
    if (transactionConfiguration.hasOwnProperty('ViewTranscationConfig')) {
      OdataKey = transactionConfiguration['ViewTranscationConfig'][transactionKey];
    }
    return OdataKey;
  }

  /**
   * @method constractPostModelEntity(HeaderEntity: any, DetailEntity: any, TransactionData: TransactionConfiguration)
   * @prams HeaderEntity: any,
   *        DetailEntity: any,
   *        TransactionData: TransactionConfiguration
   */
  constractTransactionHeaderPostModel(
    PostModelHeaderEntity: any, PostModelDetailEntity: any, TransactionData: TransactionConfiguration, homeGroup: FormGroup,
    requestMethod: string, GlobalOdataModel?: any) {
    if (PostModelHeaderEntity && PostModelDetailEntity && TransactionData) {
      let combineObject = {};
      // if (PostModelHeaderEntity) {
      const headerModelProperity = PostModelHeaderEntity[0]['Property'];
      const HeaderPostModelData = [];
      headerModelProperity.forEach((HeaderProperity: any) => {
        const postModelHeadName = HeaderProperity['$'].Name;
        const ModelType = HeaderProperity['$'].Type;
        const formConrolObject = homeGroup.value;
        const modelObject = this.assignToPropertyType(ModelType, postModelHeadName, formConrolObject, HeaderProperity);
        HeaderPostModelData.push(modelObject);
      });
      const addDetailsName = PostModelDetailEntity[0]['$']['Name'];
      const deatilDataObject = new Object();
      deatilDataObject[addDetailsName] = [];
      HeaderPostModelData.push(deatilDataObject);
      combineObject = HeaderPostModelData.reduce(function (acc, val) {
        return Object.assign(acc, val);
      }, {});
      // }
      // if (PostModelDetailEntity) {
      const itemListData = TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
      // Added for sending the deleted Items in edit flow
      const deletedItemListData = TransactionData.CreateTranscationConfig.ItemsConfig.DeletedItemList;
      if (deletedItemListData.length > 0 && requestMethod === 'PuthttpMethod') {
        deletedItemListData.forEach(deleteItem => {
          if (itemListData.findIndex(item => item.Id === deleteItem.Id) === -1) {
            itemListData.push(deleteItem);
          }
        });
      }
      if (itemListData.length > 0) {
        const detailPostModelData = [];
        let detailCombine = {};
        itemListData.forEach(GridData => {
          const detailModelProperity = PostModelDetailEntity[0]['Property'];
          detailModelProperity.forEach((detailProperity: any) => {
            const detailName = detailProperity['$'].Name;
            const ModelType = detailProperity['$'].Type;
            if ((detailName !== 'Id' && requestMethod === 'PosthttpMethod') || requestMethod === 'PuthttpMethod') {
              const detailObject = this.assignToPropertyType(ModelType, detailName, GridData, detailProperity);
              detailPostModelData.push(detailObject);
            }
          });
          const detailNavigationProperity = PostModelDetailEntity[0]['NavigationProperty'];
          if (detailNavigationProperity) {
            detailNavigationProperity.forEach(NavigationProperty => {
              const NavigationEntityName = NavigationProperty['$']['Name'];
              if (NavigationEntityName === 'ReceivingItemsOtherTotals') {
                const TaxInfo1 = {} as TaxDetails;
                const TaxInfo2 = {} as TaxDetails;
                if (!GridData[NavigationEntityName]) {
                  GridData[NavigationEntityName] = [];
                }
                if (GridData.Tax1) {
                  const Tax1Index = GridData[NavigationEntityName].findIndex(x => x.CategoryId === 1 &&
                    x.CategoryDetailId === GridData.Tax1);
                  if (Tax1Index === -1) {
                    TaxInfo1.Id = 0;
                    TaxInfo1.CategoryId = 1;
                    TaxInfo1.CategoryDetailId = GridData.Tax1;
                    TaxInfo1.TaxRate = GridData.TaxRate1;
                    TaxInfo1.Amount = GridData.TaxAmount1;
                    TaxInfo1.AmountInFC = GridData.TaxAmountInFC1;
                    GridData[NavigationEntityName].push(TaxInfo1);
                  } else {
                    GridData[NavigationEntityName][Tax1Index].Amount = GridData.TaxAmount1;
                    GridData[NavigationEntityName][Tax1Index].AmountInFC = GridData.TaxAmountInFC1;
                  }
                }
                if (GridData.Tax2) {
                  const Tax2Index = GridData[NavigationEntityName].findIndex(x => x.CategoryId === 1 &&
                    x.CategoryDetailId === GridData.Tax2);
                  if (Tax2Index === -1) {
                    TaxInfo2.Id = 0;
                    TaxInfo2.CategoryId = 1;
                    TaxInfo2.CategoryDetailId = GridData.Tax2;
                    TaxInfo2.TaxRate = GridData.TaxRate2;
                    TaxInfo2.Amount = GridData.TaxAmount2;
                    TaxInfo2.AmountInFC = GridData.TaxAmountInFC2;
                    GridData[NavigationEntityName].push(TaxInfo2);
                  } else {
                    GridData[NavigationEntityName][Tax2Index].Amount = GridData.TaxAmount2;
                    GridData[NavigationEntityName][Tax2Index].AmountInFC = GridData.TaxAmountInFC2;
                  }
                }
                if (GridData[NavigationEntityName] && GridData[NavigationEntityName].length > 0) {
                  detailPostModelData.push({
                    ReceivingItemsOtherTotals: GridData[NavigationEntityName]
                  });
                }
              } else if (NavigationEntityName === 'TransactionTaxAmount') {
                const TaxInfo1 = {} as POTaxDetails;
                const TaxInfo2 = {} as POTaxDetails;
                if (!GridData[NavigationEntityName]) {
                  GridData[NavigationEntityName] = [];
                }
                if (GridData.Tax1) {
                  const Tax1Index = GridData[NavigationEntityName].findIndex(x => x.TaxId === GridData.Tax1);
                  if (Tax1Index === -1) {
                    TaxInfo1.Id = 0;
                    TaxInfo1.TaxId = GridData.Tax1;
                    TaxInfo1.TaxRate = GridData.TaxRate1 ? GridData.TaxRate1 : 0;
                    TaxInfo1.TaxAmount = GridData.TaxAmount1;
                    TaxInfo1.SortOrder = 1;
                    GridData[NavigationEntityName].push(TaxInfo1);
                  } else {
                    GridData[NavigationEntityName][Tax1Index].SortOrder = 1;
                    GridData[NavigationEntityName][Tax1Index].TaxAmount = GridData.TaxAmount1;
                  }
                }
                if (GridData.Tax2) {
                  const Tax2Index = GridData[NavigationEntityName].findIndex(x => x.TaxId === GridData.Tax2);
                  if (Tax2Index === -1) {
                    TaxInfo2.Id = 0;
                    TaxInfo2.TaxId = GridData.Tax2;
                    TaxInfo2.TaxRate = GridData.TaxRate2 ? GridData.TaxRate2 : 0;
                    TaxInfo2.TaxAmount = GridData.TaxAmount2;
                    TaxInfo2.SortOrder = 2;
                    GridData[NavigationEntityName].push(TaxInfo2);
                  } else {
                    GridData[NavigationEntityName][Tax2Index].SortOrder = 2;
                    GridData[NavigationEntityName][Tax2Index].TaxAmount = GridData.TaxAmount2;
                  }
                }
                if (GridData[NavigationEntityName] && GridData[NavigationEntityName].length > 0) {
                  detailPostModelData.push({
                    TransactionTaxAmount: GridData[NavigationEntityName]
                  });
                }
              } else if (NavigationEntityName === 'PlanDetailQtys') {
                // Need to write code here
                const GridHeaderDefs = TransactionData.CreateTranscationConfig.ItemsConfig.
                  ItemList.ItemGridConfig.GridHeaderDefination;
                if (GridData.PlanDetailQtys) {
                  GridData['copyofPlanDetailQty'] = GridData.PlanDetailQtys;
                  delete GridData.PlanDetailQtys;
                }
                const PlanDetailsQtyList = GridData.copyofPlanDetailQty;
                const detailsQty = [];
                GridHeaderDefs.forEach(head => {
                  if (head.hasOwnProperty('headerWithCustomCheckbox') && head.headerWithCustomCheckbox) {
                    const IdPresent = PlanDetailsQtyList ? PlanDetailsQtyList.
                      findIndex((x: PlanDetailsQty) => x.Remark === head.headerName) : -1;
                    if (IdPresent === -1 || requestMethod === 'PosthttpMethod') {
                      const PlanDetailsQtys = {} as PlanDetailsQty;
                      PlanDetailsQtys.Id = 0;
                      PlanDetailsQtys.IsDeleted = false;
                      PlanDetailsQtys.PlanDetailId = 0;
                      PlanDetailsQtys.Remark = head.headerName;
                      PlanDetailsQtys.Qty = parseFloat(GridData[head.field]);
                      PlanDetailsQtys.SortOrder = detailsQty.length + 1;
                      detailsQty.push(PlanDetailsQtys);
                    } else if (requestMethod === 'PuthttpMethod') {
                      PlanDetailsQtyList[IdPresent].Qty = parseFloat(GridData[head.field]);
                      detailsQty.push(PlanDetailsQtyList[IdPresent]);
                    }
                  }
                });
                // Added code for edit mode 
                if (PlanDetailsQtyList && PlanDetailsQtyList.length > 0 && detailsQty.length === 0) {
                  PlanDetailsQtyList.forEach(previousQty => {
                    if (requestMethod === 'PosthttpMethod') {
                      previousQty.Id = 0;
                      previousQty.PlanDetailId = 0;
                      previousQty.IsDeleted = false;
                    }
                    detailsQty.push(previousQty);
                  });
                }
                detailPostModelData.push({
                  PlanDetailQtys: detailsQty
                });
              }
            });
          }
          detailCombine = detailPostModelData.reduce(function (acc, val) {
            return Object.assign(acc, val);
          }, {});
          const detailDataName = PostModelDetailEntity[0]['$']['Name'];
          combineObject[detailDataName].push(detailCombine);
        });
      }
      // }
      PostModelHeaderEntity[0]['NavigationProperty'].forEach((NavigationProperty: any) => {
        const NavigationEntityName = NavigationProperty['$']['Name'];
        if (NavigationEntityName === 'TransactionPictures') {
          // const PostModelObject = PostModelHeaderEntity.filter(x => x['$'].Name === NavigationEntityName);
          // if (this.transactionConfigurationData.AttachedFilesConfig) {
          //   combineObject = transactionPost.transactionPicturesConfig(PostModelHeaderEntity, PostModelDetailEntity,
          //     this.transactionConfigurationData, this.httpService, combineObject, NavigationEntityName, GlobalOdataModel, this,
          //     requestMethod);
          // }
        } else if (NavigationEntityName === 'TransactionMiscCharges') {
          combineObject[NavigationEntityName] = homeGroup.value[NavigationEntityName];
        } else if (NavigationEntityName === 'ReceivingHeadOtherTotals') {
          combineObject[NavigationEntityName] = homeGroup.value[NavigationEntityName] ? homeGroup.value[NavigationEntityName] : [];
        } else if (NavigationEntityName === 'SalesOtherSummary') {
          const GridData = TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
          const salesSummary = homeGroup.value[NavigationEntityName];
          const TaxInfo1 = {} as SalesOthersSummary;
          const TaxInfo2 = {} as SalesOthersSummary;
          if (GridData.length > 0) {
            let TaxAmount1 = 0;
            let TaxAmount2 = 0;
            GridData.forEach((x: any) => {
              if (x.hasOwnProperty('TaxAmount1') && x.hasOwnProperty('TaxAmount2')) {
                x.TaxAmount1 = x.TaxAmount1 !== undefined ? x.TaxAmount1 : 0;
                x.TaxAmount2 = x.TaxAmount2 !== undefined ? x.TaxAmount2 : 0;
                TaxAmount1 = parseFloat(TaxAmount1.toString()) + parseFloat(x.TaxAmount1);
                TaxAmount2 = parseFloat(TaxAmount2.toString()) + parseFloat(x.TaxAmount2);
              }
              TaxInfo1.ItemId = x.Tax1 !== null && x.Tax1 !== undefined ? x.Tax1 : -1;
              TaxInfo2.ItemId = x.Tax2 !== null && x.Tax2 !== undefined ? x.Tax2 : -1;
            });
            TaxInfo1.Id = 0;
            TaxInfo1.TypeId = 2;
            TaxInfo1.Valued = TaxAmount1;
            TaxInfo2.Id = 0;
            TaxInfo2.TypeId = 2;
            TaxInfo2.Valued = TaxAmount2;
            salesSummary.push(TaxInfo1);
            salesSummary.push(TaxInfo2);
            combineObject['TaxTotals'] = ((TaxAmount1 + TaxAmount2).toString());
          }          
          combineObject[NavigationEntityName] = salesSummary;
        } else if (NavigationEntityName === 'QuickOrderSettings') {
          combineObject[NavigationEntityName] = homeGroup.value[NavigationEntityName] ? homeGroup.value[NavigationEntityName] :{};
        } else if (NavigationEntityName === 'QuickOrderSummary') {
          const GridData = TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
          const IsTaxChecked = this.quickOrderSummaryData?.value?.QuickOrderSettings?.IsTaxChecked;
          let quickOrderSummary = homeGroup.value['QuickOrderSummary'];
          let TaxIndex = -1;
          if (quickOrderSummary) {
            quickOrderSummary.forEach((quickOrderSum: any, index: number) => {
              if (quickOrderSum.QOSummaryLinkTypeId === 2) {
                TaxIndex = index;
              } 
            });
          }
          if (GridData && GridData.length > 0) {
            // Tax Value Calculation -> QOLinkTypeId = 2
            let TaxValue = 0;
            GridData.forEach((x: any, index: any) => {
              if (x.hasOwnProperty('TaxValue')) {
                TaxValue = TaxValue + (x.TaxValue ? parseFloat(x.TaxValue.toString()) : 0);
              } 
            });
            if (TaxIndex > -1) {
              let serviceTax = 0;
              if (parseFloat(TaxValue.toString()) > parseFloat(quickOrderSummary[TaxIndex].Valued)) {
                  serviceTax = parseFloat(quickOrderSummary[TaxIndex].Valued)
              } else if (parseFloat(TaxValue.toString()) < parseFloat(quickOrderSummary[TaxIndex].Valued)) {
                  serviceTax = parseFloat(quickOrderSummary[TaxIndex].Valued) - parseFloat(TaxValue.toString());
              }
              TaxValue += (!IsTaxChecked ? 0 : serviceTax);
              quickOrderSummary[TaxIndex].Valued = TaxValue;
            } else if (TaxIndex === -1) {
                  const object = {} as OrderSummary;
                  object.Id = 0;
                  object.QuickOrderHeadId = 0;
                  object.TrnType = 2;
                  object.QOSummaryLinkTypeId = 2;
                  object.AccId = null;
                  object.Valued = parseFloat(TaxValue.toString()).toFixed(4);
                  quickOrderSummary.push(object);
            }
            // Calculation for Type based Estimation Total for Products
            quickOrderSummary = quickOrderSummary?.filter(qoSummary => qoSummary.QOSummaryLinkTypeId !== 1);
            GridData.forEach((x: any )=> {
             const summaryIndex: number = quickOrderSummary.findIndex(e=> e.LinkItemId === x.TypeId && e.QOSummaryLinkTypeId === 1);
              if( summaryIndex > -1 ){
                quickOrderSummary[summaryIndex].Valued = parseFloat(quickOrderSummary[summaryIndex].Valued) + parseFloat(x.Valued.toString());
              } else {
                const orderSummary = {} as OrderSummary;
                orderSummary.Id = 0;
                orderSummary.QuickOrderHeadId = 0;
                orderSummary.QOSummaryLinkTypeId = 1;
                orderSummary.TrnType = 2;
                orderSummary.AccId = null;
                orderSummary.Valued = parseFloat(x.Valued.toString()).toFixed(4);
                orderSummary.LinkItemId = x.TypeId;
                quickOrderSummary.push(orderSummary);
              }
            });
          }
          combineObject[NavigationEntityName] = quickOrderSummary;
        } 
      });
      return combineObject;
    }
  }

  /**
   * @description Assign property values based on property type
   * @param ModelType ;
   * @param postModelHeadName ;
   * @param formConrolObject ;
   * @param HeaderProperity ;
   */
  assignToPropertyType(ModelType, postModelHeadName, formConrolObject, HeaderProperity) {
    const modelObject = {};
    switch (ModelType) {
      case FieldEdmType.EDMINT32:
      case FieldEdmType.EDMINT16:
      case FieldEdmType.EDMINT64:
        modelObject[postModelHeadName] = (formConrolObject[postModelHeadName] !== null && formConrolObject[postModelHeadName] !== undefined
          && formConrolObject[postModelHeadName] !== '') ?
          parseInt(formConrolObject[postModelHeadName], 10) : this.setDeafultValue(HeaderProperity);
        break;
      case FieldEdmType.EDMINTDECIMAL:
      case FieldEdmType.EDMFLOAT:
      case FieldEdmType.EDMDOUBLE:
        modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) ?
          parseFloat(formConrolObject[postModelHeadName]) : this.setDeafultValue(HeaderProperity);
        break;

      // modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) ?
      //   parseFloat(formConrolObject[postModelHeadName]) : this.setDeafultValue(HeaderProperity);
      // break;
      case FieldEdmType.EDMDATEOFFSET:
        if (formConrolObject[postModelHeadName]) {
          if ( postModelHeadName === 'ExpireDate') {
            formConrolObject[postModelHeadName] = Formaters.globalDateFormaters(formConrolObject[postModelHeadName]);
          }
          const date = new Date(formConrolObject[postModelHeadName]);
          // const newdate = new Date();
          // eslint-disable-next-line max-len
          // const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), newdate.getHours(), newdate.getMinutes(), newdate.getSeconds());
          // if (isNaN(currentDate.getTime())) {
          //   modelObject[postModelHeadName] = this.setDeafultValue(HeaderProperity);
          // } else {
            // const now_utc = moment.utc(currentDate).format();
            // modelObject[postModelHeadName] = (date) ? now_utc : this.setDeafultValue(HeaderProperity);
            modelObject[postModelHeadName] = moment(date).isValid() 
            ? moment(date).startOf('day').format(constants.PostDateTimeFormat) : this.setDeafultValue(HeaderProperity);
          // }
        } else {
          modelObject[postModelHeadName] = this.setDeafultValue(HeaderProperity);
        }
        break;
      case FieldEdmType.EDMSTRING:
        modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) ?
          formConrolObject[postModelHeadName].toString() : this.setDeafultValue(HeaderProperity);
        break;

      // modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) ?
      //   parseInt(formConrolObject[postModelHeadName], 10) : this.setDeafultValue(HeaderProperity);
      // break;

      // modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) ?
      //   parseInt(formConrolObject[postModelHeadName], 10) : this.setDeafultValue(HeaderProperity);
      // break;
      case FieldEdmType.EMDBOOLEAN:
        modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) !== null &&
          (formConrolObject[postModelHeadName]) !== undefined && (formConrolObject[postModelHeadName]) !== '' ?
          formConrolObject[postModelHeadName] : this.setDeafultValue(HeaderProperity);
        break;

      // modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) ?
      //   parseFloat(formConrolObject[postModelHeadName]) : this.setDeafultValue(HeaderProperity);
      // break;
      default:
        modelObject[postModelHeadName] = (formConrolObject[postModelHeadName]) ? formConrolObject[postModelHeadName] :
          this.setDeafultValue(HeaderProperity);
    }
    return modelObject;
  }

  /**
   * setDeafultValue(HeaderPropeconstrity)
   * @params transactionData
   * @params verifiedKey
   */
  setDeafultValue(HeaderProperity: any) {
    try {
      const ModelType = HeaderProperity['$'].Type;
      const nullable = HeaderProperity['$'].hasOwnProperty('Nullable') ? true : false;
      let defaultValue: any = '';
      switch (ModelType) {
        case FieldEdmType.EDMINT32:
          defaultValue = (nullable) ? 0 : null;
          break;
        case FieldEdmType.EDMINTDECIMAL:
          defaultValue = (nullable) ? 0 : null;
          break;
        case FieldEdmType.EDMDATEOFFSET:
          // const newdate = new Date();
          // const currentDate = new Date(newdate.getFullYear(), newdate.getMonth(), newdate.getDate(),
          //  newdate.getHours(), newdate.getMinutes(), newdate.getSeconds());
          // defaultValue = (nullable) ? moment.utc(currentDate).format() : null;
          defaultValue = (nullable) ? moment().startOf('day').format() : null;
          break;
        case FieldEdmType.EDMSTRING:
          defaultValue = (nullable) ? '' : null;
          break;
        case FieldEdmType.EDMINT16:
          defaultValue = (nullable) ? 0 : null;
          break;
        case FieldEdmType.EDMINT64:
          defaultValue = (nullable) ? 0 : null;
          break;
        case FieldEdmType.EDMFLOAT:
          defaultValue = (nullable) ? 0 : null;
          break;
        case FieldEdmType.EMDBOOLEAN:
          defaultValue = (nullable) ? false : null;
          break;
        case FieldEdmType.EDMDOUBLE:
          defaultValue = (nullable) ? 0 : null;
          break;
        default:
          defaultValue = (nullable) ? '' : null;
      }
      return defaultValue;
    } catch (error) {

    }
  }

  /**
  * @method checkTransactionItemQuantity(transactionData: TransactionConfiguration)
  * @prams HeaderEntity: any,
  *        DetailEntity: any,
  *        TransactionData: TransactionConfiguration
  */
  // checkTransactionItemQuantity(transactionData: TransactionConfiguration, verifiedKey: string): boolean {
  //   try {
  //     const allowSystemZeroQuantity = false;
  //     let enableRequest = true;
  //     if (transactionData && transactionData.CreateTranscationConfig && transactionData.CreateTranscationConfig.ItemsConfig.ItemList) {
  //       const itemListData = transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
  //       if (itemListData.length > 0 && !allowSystemZeroQuantity) {
  //         const quantityDataGreatter = itemListData.findIndex(x => x[verifiedKey] > 0);
  //         if (quantityDataGreatter > -1) {
  //           enableRequest = true;
  //         }
  //       }
  //     }
  //     return enableRequest;
  //   } catch (error) {

  //   }
  // }

  /**
   * @method enableButton(transactionData: TransactionConfiguration, buttonName: string)
   * @prams HeaderEntity: any,
   *        DetailEntity: any,
   *        TransactionData: TransactionConfiguration
   */
  enableButton(transactionData: TransactionConfiguration, FormData?: FormGroup): void {
    try {
      if (transactionData && transactionData.CreateTranscationConfig &&
        transactionData.CreateTranscationConfig.HeaderConfig.ActionButtonConfig) {
        const key = transactionData.CreateTranscationConfig.ItemsConfig.KeyToEnablePost;
        const additionKey = transactionData.CreateTranscationConfig.ItemsConfig.AdditionalkeyToEnablePost;
        const selectedData = transactionData.CreateTranscationConfig.ItemsConfig.ItemList
          .ItemGridConfig.GridData;
        // const ZeroQuantity = selectedData.filter(x => parseFloat(x[key] === '' ? 0 : x[key]) === 0);
        // const NegativeQuantity = selectedData.filter(x => parseFloat(x[key] === '' ? 0 : x[key]) < 0);
        // const GreaterZero = selectedData.filter(x => parseFloat(x[key] === '' ? 0 : x[key]) > 0);
        // let EnableButton = false;
        // if (ZeroQuantity.length === selectedData.length) {
        //   EnableButton = false;
        // } else {
        //   if (GreaterZero.length > 0) {
        //     EnableButton = false;
        //   } else {
        //     EnableButton = true;
        //   }
        // }
        const actionButton = transactionData.CreateTranscationConfig.HeaderConfig.ActionButtonConfig;
        actionButton.forEach((button: AgilysysButtonModel) => {
          const ss = ['confirm', 'processpo', 'piconfirm', 'receivepost', 'save', 'editpo', 'receive', 'savereceive', 'transfer',
            'inventoryreset', 'productionPost', 'postsales', 'confirm-sales', 'ButcheryPost', 'confirmPackages'];
          if (ss.findIndex(x => button.buttonKey === x) > -1) {
            if ( key || additionKey ) {
              const data = this.sysytemPreferenceService.globalSystemPreference.filter(x => x.LookupName === 'NegativeTransfers');
              if ( key ) {
                const buttonEnable = selectedData.some(e => parseFloat(e[key] === '' ? 0 : e[key]) > 0);
                button.displayProperity = !buttonEnable;
              }
              if (transactionData.Name === 'Transfer') {
                if (data && data[0].LookupValue === 3) {
                  button.displayProperity = selectedData.some(e => e[key] === '');
                } else if (data && data[0].LookupValue === 2) {
                  button.displayProperity = selectedData.some(x => x[key] === '' || (x[key] < 0 &&
                       (x.Remark === null || x.Remark === undefined || x.Remark === '')));
                }
              }
              // Added for PR to check every row has supplier id
              if (additionKey) {
                button.displayProperity = !selectedData.every(row => row[additionKey] > 0);
              }
              if (additionKey && key) {
                button.displayProperity = !selectedData.every(row => row[additionKey] > 0 && row[key] > 0);
              }
              if (!FormData.valid) {
                button.displayProperity = true;
              }
              if (transactionData.Name === 'sales' || transactionData.Name === 'quickorder') {
                const PostbuttonEnable = selectedData.some(e => parseFloat(e[key] === '' ? 0 : e[key]));
                button.displayProperity = !PostbuttonEnable;
              }
              if (transactionData.Name === 'Physical Inventory') {
                let Enable = selectedData.some(e => e[key] !== '' && e[key] !== undefined && e[key].toString().toLowerCase() !== 'n_c'
                  && e[key].toString().toLowerCase() !== 'l_p');
                const isNegative = selectedData.some(x => x[key] ? parseFloat(x[key]) < 0 : false);
                Enable = Enable ? (isNegative ? !isNegative : Enable) : Enable;
                button.displayProperity = !Enable;
              }
            } else {
              if (selectedData.length > 0) {
                button.displayProperity = false;
              } else {
                button.displayProperity = true;
              }
            }
            if (transactionData.Name === 'cycleplanning') {
              button.displayProperity = 
              transactionData.CreateTranscationConfig?.ItemsConfig?.CyclePlanItemConfig?.CyclePlanDetailMealCovers?.length > 0 ?
               false : true;
            } 
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @description estimated total is calculated with valued property
   * @param value ;
   * @param GridRowData ;
   */
  calculateValues(value: any, GridRowData: Array<any>, ModuleFrom = null, IsDiscount = true) {
    try {
      let count = 0;
      let TaxValue = 0;
      const IsTaxChecked = this.quickOrderSummaryData?.value?.QuickOrderSettings?.IsTaxChecked;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      GridRowData.forEach(element => {
        const dataObject = _.mapKeys(element, function (v, k) {
          return k.charAt(0).toUpperCase() + k.slice(1);
        });
        if (  (this.transactionConfigurationData && 
          this.transactionConfigurationData.Name === 'Production') || 
          ModuleFrom === 'Production' ) {
          if (dataObject.Qty && dataObject.CurrentCost) {
            // dataObject.Valued = dataObject.Qty * dataObject.Conversion;
            const estimationTotal = dataObject.Qty * dataObject.CurrentCost;
            dataObject['Valued'] = ValueFormatter.CurrencyFormater(null, estimationTotal.toString(), null, null);
            // estimationTotal ?  parseFloat(estimationTotal.toString()).toFixed(2) : 0;
            count += dataObject.Valued ? parseFloat(dataObject.Valued) : 0;
          }
        } else if ((this.transactionConfigurationData && (this.transactionConfigurationData.Name === 'sales')) || ModuleFrom === 'sales') {
          if (dataObject.Qty && dataObject.Valued) {
            // Grand Total Calculation = SubTotal + TotalTax
            // SubTotal = Sum of Valued(Amount)
            dataObject.Valued = dataObject.Valued ? parseFloat(dataObject.Valued) : 0;
            // const TaxValue1 = (dataObject.Valued * dataObject.TaxAmount1 ) / 100;
            // const TaxValue2 = (dataObject.Valued * dataObject.TaxAmount2 ) / 100;
            dataObject.TaxAmount1 = dataObject.TaxAmount1 !== undefined ? dataObject.TaxAmount1 : 0;
            dataObject.TaxAmount2 = dataObject.TaxAmount2 !== undefined ? dataObject.TaxAmount2 : 0;
            dataObject.TaxValue = parseFloat(dataObject.TaxAmount1) + parseFloat(dataObject.TaxAmount2);
            count += dataObject.Valued + dataObject.TaxValue;
          }
        } else if ((this.transactionConfigurationData && (this.transactionConfigurationData.Name === 'quickorder')) || ModuleFrom === 'sales') {
          if (dataObject.Qty && dataObject.Valued) {
            // Grand Total Calculation = SubTotal + TotalTax
            // SubTotal = Sum of Valued(Amount)
            dataObject.Valued = dataObject.Valued ? parseFloat(dataObject.Valued) : 0;
            // In Get By Id TaxValue Property is already present so not calculated again
            if ((!dataObject.TaxValue || dataObject.TaxValue === 0 || dataObject.TaxValue === null || dataObject.TaxValue === undefined) && IsTaxChecked) {
              dataObject.TaxAmount1 = dataObject.TaxAmount1 !== undefined ? dataObject.TaxAmount1 : 0;
              dataObject.TaxAmount2 = dataObject.TaxAmount2 !== undefined ? dataObject.TaxAmount2 : 0;
              dataObject.TaxValue = parseFloat(dataObject.TaxAmount1) + parseFloat(dataObject.TaxAmount2); 
            }
            TaxValue += parseFloat(dataObject.TaxValue ? dataObject.TaxValue : '0');
            count += (parseFloat(dataObject.Valued ? dataObject.Valued : '0'))// + (!IsTaxChecked ? parseFloat(dataObject.TaxValue ? dataObject.TaxValue : '0') : 0));            
          }
        }else if ( (this.transactionConfigurationData && this.transactionConfigurationData.Name === 'butchery') || ModuleFrom === 'butchery' ) {
          if (dataObject.Amount) {
            dataObject.Amount = dataObject.Amount ? parseFloat(dataObject.Amount) : 0;
            count += dataObject.Amount;
          }
        } else {
          if (dataObject.Qty && dataObject.Valued) {
            // dataObject.Valued = dataObject.Qty * dataObject.Conversion;
            // -> becoz valued calculation moved to grid lib in unit dropdown change calculation
            dataObject.Valued = dataObject.Valued ? parseFloat(dataObject.Valued) : 0;
            count += dataObject.Valued;
          }
        }
      });
      if ((this.transactionConfigurationData && this.transactionConfigurationData.Name === 'quickorder' &&
      this.quickOrderSummaryData?.value?.QuickOrderSummary && IsDiscount) || ModuleFrom === 'sales') {
        // serive charge
        // discount
        const serviceCharge = this.calculateQuickOrderSummary(this.quickOrderSummaryData.value.QuickOrderSummary, null);
        let serviceTax = 0;
        if ( TaxValue === 0 &&  count === 0) {
          serviceTax = 0;
        } else if (parseFloat(TaxValue.toString()) > parseFloat(serviceCharge['totalTax'])) {
          serviceTax = parseFloat(serviceCharge['totalTax'])
        } else if (parseFloat(TaxValue.toString()) < parseFloat(serviceCharge['totalTax'])) {
          serviceTax = parseFloat(serviceCharge['totalTax']) - parseFloat(TaxValue.toString());
        }
        count += (!IsTaxChecked ? 0 : TaxValue);
        const LatestServicechargesPercentage = CommonService.ServiceChargeRate; 
        const LatestServicecharges = Formater('Currency', (count * LatestServicechargesPercentage / 100), null, null);
        count += parseFloat(LatestServicecharges);
        count += (!IsTaxChecked ? 0 : serviceTax);
        const LatestDiscount = Formater('Currency', (parseFloat(this.quickOrderSummaryData.value?.QuickOrderSettings['Discount']) ?  parseFloat(this.quickOrderSummaryData.value?.QuickOrderSettings['Discount']) * count / 100 : 0), null, null);
        count -= parseFloat(LatestDiscount);
        this.quickOrderSummaryData.value.QuickOrderSummary.forEach((quickOrderSummary: any) => {
          if (quickOrderSummary.QOSummaryLinkTypeId === 3) {
            quickOrderSummary.Valued = LatestServicecharges.toString();
          } else if (quickOrderSummary.QOSummaryLinkTypeId === 6) {
            quickOrderSummary.Valued = LatestDiscount.toString();
          }
        });
        this.quickOrderSummaryData.value.QuickOrderSettings['DiscountValue'] = LatestDiscount;
      }
      return count;
    } catch (Error) {
      console.error('Error occurred in calculateValues', Error);
    }
  }

  calculateQuickOrderSummary(quickOrderSummaryDetails: any, TotalAmount: any): any {
    let serviceCharge = 0;
    let discountCost = 0;
    let TotalTax = 0;
    if (quickOrderSummaryDetails && quickOrderSummaryDetails.length > 0) {
        quickOrderSummaryDetails.forEach((quickOrderSummary: any) => {
            if (quickOrderSummary.QOSummaryLinkTypeId === 3) {
                serviceCharge += parseFloat(quickOrderSummary.Valued);
            } else if (quickOrderSummary.QOSummaryLinkTypeId === 6) {
                discountCost += parseFloat(quickOrderSummary.Valued);
            } else if (quickOrderSummary.QOSummaryLinkTypeId === 2) {
              TotalTax += parseFloat(quickOrderSummary.Valued);
            }
        });
    } 
    const currencyDecimallength = this.commonService.GetFocusNumberLength(true);
    const returnValue = {
        serviceCharge: !isNaN(serviceCharge) && serviceCharge !== null && serviceCharge !== undefined ? parseFloat(serviceCharge.toString()).toFixed(currencyDecimallength) : 0,
        discountCost: !isNaN(discountCost) && discountCost !== null && discountCost !== undefined ? parseFloat(discountCost.toString()).toFixed(currencyDecimallength) : 0,
        totalTax : !isNaN(TotalTax) && TotalTax !== null && TotalTax !== undefined ? parseFloat(TotalTax.toString()).toFixed(currencyDecimallength) : 0
    };
    return returnValue ? returnValue : 0;
}

  calculateReceivingTotal(GridRowData: Array<any>, MiscCharges: any, AllocateCharges: any, transaction: any) {
    let count = this.calculateValues(MiscCharges, GridRowData);
    let misctotal = 0;
    const allocCharges = 0;
    let AllocateTotal = '0';
    if (MiscCharges && MiscCharges.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      MiscCharges.forEach(element => {
        misctotal = misctotal + parseFloat(element.Amount);
      });
    }
    /**
     * For Bug Fix - 197126
     */
    // if (transaction !== 'apvoucherrni') {
      // if (AllocateCharges && AllocateCharges.Use) {
      //   AllocateTotal = AllocateCharges.Use === 'None' ? '0' : AllocateCharges.Value;
      //   count = count + misctotal + (AllocateCharges ? parseFloat(AllocateTotal) : allocCharges);
      // }
      // else {
        let Charges = 0;
        GridRowData.forEach(element => {
          Charges += element.Charges ? parseFloat(element.Charges) : 0;
        });
        AllocateTotal = Charges.toString();
        count = count + misctotal + parseFloat(AllocateTotal);
      // }

    // } else {
    //   count = count + misctotal;
    // }
    return Formater('Currency', count, null, null);

  }
  addZeroes(num: any): any {
    let value = num;
    const res = num.split('.');
    if (res.length === 1 || (res[1].length < 3)) {
      value =  Formater('FixedFormat', value, null, null);
      // value.toFixed(2);
    }
    return value;
  }

  reqDetailChangeResponseData(responseData: Array<any>, PropertyName, UnitPropertyName, CopyOfResponseData?: any,
    JsonData?: TransactionConfiguration): Array<any> {
    try {
      const BaseCurrency = parseInt(sessionStorage.getItem('BaseCurrency'), 10);
      // responseData.forEach(x => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      responseData[PropertyName]?.forEach((element: any, rowIndex) => {
        const UnitsObject = {};
        if (PropertyName === 'MRPDetails') {
          if (parseFloat(element?.Cost) && parseFloat(element.Qty)) {
            element.Amount = parseFloat(element.Cost) * parseFloat(element.Qty);
          } else {
            element.Amount = 0;
          }
        } else {
          element['UnitCode'] = element['Units'] ? element['Units'].Code : '';
          element['Units'] =  (JsonData.hasOwnProperty('SpModuleKey') && JsonData.SpModuleKey === 'Butchery') ? 
          element['Units'] : '';
          element['classId'] = '';
          element['typeId'] = '';
        }
        if (PropertyName === 'ReceivingDetails') {
          element.OnRecipt = element?.ReceivingDetailsIngredient?.OnRecipt;
          element.Valued = element.Amount;
          element.UnitCostInFC = element.UnitCost && element.UnitCost > 0 && element.ExchangeRate &&
          element.ExchangeRate > 0 ? (element.UnitCost * element.ExchangeRate).toFixed(4) : 0;
        }
        if (UnitPropertyName) {
          element['Units'] = element[UnitPropertyName].Units ? element[UnitPropertyName].Units : '';
          if (element.Units && element.Units.length > 0 && this.transactionConfigurationData.Name === 'Template') {
            element.Units.forEach(u => {
              u['UnitCode'] = u.UnitDetails.Code;
              u['UnitName'] = u.UnitDetails.Name;
              u['UnitTypeName'] = u.UnitTypeDetails.Name;
              u['UnitTypeDetails'] = null;
            });
            element.UnitTypes = null;
          }
          element['ClassId'] = element[UnitPropertyName].ClassId;
          element['TypeId'] = element[UnitPropertyName].TypeId;
          let transactionUnitsDetails: any = null;
          if ( element.hasOwnProperty('Units') && element.Units !== null && element.Units !== '' && element.Units !== undefined) {
            transactionUnitsDetails = element['Units'].filter((unitDetails: any) => unitDetails['UnitId'] === element['UnitId'])[0];
          }
          if ( transactionUnitsDetails !== null && transactionUnitsDetails ) { 
            element['UnitCode'] = transactionUnitsDetails.UnitCode ? transactionUnitsDetails.UnitCode :element.UnitCode;
            if (PropertyName !== 'ReceivingDetails') {
              element['UnitCost'] = transactionUnitsDetails.UnitCost;
            }

            if (element['UnitCost'] === 0) {
              if (element.Qty && element.Valued && element.Qty !== 0 && element.Valued !== 0) {
                element['UnitCost'] = parseFloat(element.Valued) / parseFloat(element.Qty);
              } else {
                element['UnitCost'] = ( PropertyName !== 'ReceivingDetails' ) ? 0 : transactionUnitsDetails.UnitCost;
                // bug fix 181125 in receiving unit cost data is not saved in table,
                // so if zero comes for unit cost , then respective unit cost will be shown in table
                // Sentri Worl Issue Unicost is zero RcvUnitCost
              }
            }
            if ( PropertyName === 'PurchaseReqDetail' || PropertyName === 'PurchaseOrderDetails') {
              if (element.Qty && element.Valued && element.Qty !== 0 && element.Valued !== 0) {
                element['UnitCost'] = parseFloat(element.Valued) / parseFloat(element.Qty);
              } else {
                element['UnitCost'] = 0;
              }
            }
            
            // Added for AP Voucher HF Fix
            if (element.Qty === 0) {
              element['RcvUnitCost'] = transactionUnitsDetails.UnitCost;
            }
          } else {
            element['UnitCode'] = null;
          }
          element['CurrentCost'] = element[UnitPropertyName].CurrentCost;
          element['Number'] = element[UnitPropertyName].Number;
          element['QtyOnOrder'] = element[UnitPropertyName].QtyOnOrder;
          element['QtyOnHand'] = element[UnitPropertyName].QtyOnHand;
          element['IsActive'] = element[UnitPropertyName].IsActive;
        }

        if (responseData['IsPunchoutPO'] || responseData['IsPunchoutPR']) {
          element.EnableCheckBox = (!element.IsActive || !element.UnitId
            || !element.UnitTypeId) ? true : false;
        }
        if ( JsonData.hasOwnProperty('SpModuleKey') && JsonData.SpModuleKey === 'Butchery') {
          if ( element['FGRecipes'] && element['FGRecipes'].length > 0  ) {
            element['FGRecipes'].map(x => {
               x['Id'] = x['RecipeId'];
               x['Name'] = x['RecipeName'];
            }); 
            const fgRecipeItems: FGCollection = this.convertFGRecipe(element);
            
            element['RawItemName'] = ( fgRecipeItems.FilterFG && fgRecipeItems.FilterFG.length > 0 && 
            fgRecipeItems.FilterFG[0].RawItemName) ? fgRecipeItems.FilterFG[0].RawItemName : null;
            // element['QtyUsed'] = ( fgRecipeItems.FilterFG && fgRecipeItems.FilterFG.length > 0 && 
            // fgRecipeItems.FilterFG[0].QuantityUsed) ? fgRecipeItems.FilterFG[0].QuantityUsed * element.Qty : null;
          }
          element['Units'] = element['Units'] ? element['Units'] : '';
          element['ProdItemName'] = element['RecipeName'] ? element['RecipeName'] : '';
          element['ProdItemId'] = element['RecipeId'] ? element['RecipeId'] : '';
          element['IngredientId'] = element['FGItemId'];
          element['Valued'] = element['Amount'];
          let transactionUnitsDetails: any = null;
          if ( element.hasOwnProperty('Units') && element.Units !== null && element.Units !== '' && element.Units !== undefined) {
            transactionUnitsDetails = element['Units'].filter((unitDetails: any) => unitDetails['UnitId'] === element['UnitId'])[0];
          }
          if ( transactionUnitsDetails !== null && transactionUnitsDetails ) {
            element['UnitCode'] = transactionUnitsDetails.UnitCode;
            element['UnitCost'] = transactionUnitsDetails.UnitCost;
          }
        }
        
        if (this.spoilageReasonsList && this.spoilageReasonsList.length > 0) {
          const ReasonData = this.spoilageReasonsList.filter(x => x.Id === element['ReasonId']);
          element['ReasonName'] = element['Reasons'] ? element['Reasons'].Name :
            (ReasonData.length > 0 ? ReasonData[0].Name : '');
          element['Reasons'] = this.spoilageReasonsList;
        }
        if (this.currencyList && this.currencyList.length > 0) {
          element['ForeignCurrencyId'] = element['ForeignCurrencyId'] ? element['ForeignCurrencyId'] : BaseCurrency;
          const CurrencyData = this.currencyList.filter(x => x.Id === element['ForeignCurrencyId']);
          element['CurrencyCode'] = element['Currency'] ? element['Currency'].Name :
            (CurrencyData.length > 0 ? CurrencyData[0].Name : '');
          element['Currency'] = this.currencyList;
          element['ExchangeRate'] = element['ExchangeRate'] ? element['ExchangeRate'] : 1;
        }
        if (this.unitList && this.unitList.length > 0) {
          /**
          * As per Discuss to sivamani show only 4 unitstype sa per legacy
          */
          // element['Units'] = this.reassignUnitsArray(this.unitList, element['Units']);
          const SelectedUnitTypeId = element['Units'].filter(x => x.UnitId === element['UnitId']);
          if (SelectedUnitTypeId.length === 1) {
            element['UnitTypeId'] = SelectedUnitTypeId[0].UnitTypeId;
          }
        }
        // element['UnitCost'] = Formater('UnitCost', element.UnitCost, null, null);
        // element['Qty'] = Formater('Qty', element.Qty, null, null);
        // element['Valued'] = Formater('Valued', element.Valued, null, null);
        // element['QtyOnHand'] = Formater('Qty', element.QtyOnHand, null, null);
        // element['Conversion'] = Formater('Conversion', element.Conversion, null, null);
        if (PropertyName === 'ReceivingDetails' || PropertyName === 'ProductionDetails') {
          // element.Valued = Formater('Currency', element.Valued, null, null);
          // element.ItemLotLabelQty = Formater('Qty', element.ItemLotLabelQty, null, null);
        }
        if (PropertyName === 'PurchaseOrderDetails') {
          element.OnRecipt = element?.PODetailIngredientId?.OnRecipt;
          element.SendVia = element.PODetailSupplierId.SendVia;
          element.UnitCostInForigenCurrency = element.ValueInForeignCurrency && element.ExchangeRate &&
          parseFloat(element.ValueInForeignCurrency.toString()) > 0 && parseFloat(element.ExchangeRate.toString()) > 0 ?
          element.ValueInForeignCurrency / element.ExchangeRate : 0;
        }
        if (PropertyName === 'PurchaseReqDetail') {
          element.OnRecipt = element?.PRDetailIngredientId?.OnRecipt;
        }
        if (PropertyName === 'ReqDetails') {
          element.OnRecipt = element?.ReqIngredients?.OnRecipt;
        }
        if ((PropertyName === 'PurchaseReqDetail' || PropertyName === 'ReqDetails' || PropertyName === 'PurchaseOrderDetails') &&
          element.TransactionTaxAmount) {
          if (element.TransactionTaxAmount.length > 0) {
            if (element.TransactionTaxAmount[0]) {
              element['TaxName1'] = element.TransactionTaxAmount[0].TaxName;
              element['TaxAmount1'] = element.OnRecipt ? element.TransactionTaxAmount[0].TaxAmount : 0;
              element['TaxRate1'] = element.TransactionTaxAmount[0].TaxRate;
              element['Tax1'] = element.TransactionTaxAmount[0].TaxId;
            }
            if (element.TransactionTaxAmount[1]) {
              element['TaxName2'] = element.TransactionTaxAmount[1].TaxName;
              element['TaxAmount2'] = element.OnRecipt ? element.TransactionTaxAmount[1].TaxAmount : 0;
              element['TaxRate2'] = element.TransactionTaxAmount[1].TaxRate;
              element['Tax2'] = element.TransactionTaxAmount[1].TaxId;
            }
          }
        }
        if (PropertyName === 'PurchaseReqDetail') {
          element.UnitCostInForigenCurrency = element.ValueInForeignCurrency && element.ExchangeRate &&
          parseFloat(element.ValueInForeignCurrency.toString()) > 0 && parseFloat(element.ExchangeRate.toString()) > 0 ?
          element.ValueInForeignCurrency / element.ExchangeRate : 0;
        }
        if (PropertyName === 'ReqDetails' && JsonData.PostEntityName === 'PurchaseOrderHead') {
          element.UnitCostInForigenCurrency = parseFloat((element.UnitCost * element.ExchangeRate).toString()).toFixed(4);
          element.ValueInForeignCurrency = parseFloat((element.Valued * element.ExchangeRate).toString()).toFixed(4);
        }
        if (PropertyName === 'PurchaseReqDetail') {
          element.SendVia = element.PRDetailSupplierId.SendVia;
        }
        if (PropertyName === 'PurchaseReqDetail') {
          element.SendVia = element.PRDetailSupplierId.SendVia;
        }
        if (PropertyName === 'PurchaseReqDetail') {
          element.SendVia = element.PRDetailSupplierId.SendVia;
        }
        if ((PropertyName === 'ReceivingDetails') && element.ReceivingItemsOtherTotals) {
          if (element.ReceivingItemsOtherTotals.length > 0) {
            const TaxInfoDetails = element.ReceivingItemsOtherTotals.filter(x => x.CategoryId === 1);
            if (TaxInfoDetails[0]) {
              element['TaxName1'] = TaxInfoDetails[0].Name;
              element['TaxAmount1'] = element.OnRecipt ? TaxInfoDetails[0].Amount : 0;
              element['TaxRate1'] = TaxInfoDetails[0].TaxRate;
              element['Tax1'] = TaxInfoDetails[0].CategoryDetailId;
              element['TaxAmountInFC1'] = element.OnRecipt ? TaxInfoDetails[0].TaxAmountInFC1 : 0;
            }
            if (TaxInfoDetails[1]) {
              element['TaxName2'] = TaxInfoDetails[1].Name;
              element['TaxAmount2'] = element.OnRecipt ? TaxInfoDetails[1].Amount : 0;
              element['TaxRate2'] = TaxInfoDetails[1].TaxRate;
              element['Tax2'] = TaxInfoDetails[1].CategoryDetailId;
              element['TaxAmountInFC2'] = element.OnRecipt ? TaxInfoDetails[0].TaxAmountInFC2 : 0;
            }
          }
        }
        if (PropertyName === 'PackLists') {
          if (responseData['TypeId'] !== 1) {
            element['Barcode'] = element.Number;
          }
          element['IsDeleted'] = false;
          element['IsActive'] = true;
          element['Item'] = element.Item;
          // const Data = CopyOfResponseData.filter(x => x.Item === element.Item && x.IsActive === true);
          // element.Id = Data.length > 0 ? Data[0].Id : 0;
        }
        if ( PropertyName === 'ProductionDetails' ) {
           element['Name'] = UnitPropertyName !== '' ? element[UnitPropertyName]['Name'] : 
           element['FGItemName'] ? element['FGItemName'] : '';
        }
        if (PropertyName === 'PlanDetails') {
          if (JsonData.OdataKey === 'PlanHead') {
            const MappingFields = [
              {
                From: 'Name', To: 'Name'
              },
              {
                From: 'TypeId', To: 'TypeId'
              },
              {
                From: 'ProductsTypeId', To: 'TypeName', SecondaryKey: 'Name'
              }
            ];
            MappingFields.forEach(res => {
              if (res.hasOwnProperty('SecondaryKey')) {
                element[res.To] = element.LinkedProductItem[res.From][res.SecondaryKey];
              } else {
                element[res.To] = element.LinkedProductItem[res.From];
              }
            });

            element.TotalSales = element.TotalQty * element.Price;
            element.TotalCost = element.TotalQty * element.Cost;
            element.Margin = element.Price - element.Cost;
            element.ProfitPercent = (element.Margin / element.TotalSales) * 100;
            element.CostPercent = (element.TotalCost / element.TotalSales) * 100;
          }
        }
      });
      // });
      return responseData;
    } catch (error) {
      console.error(error);
    }

    return [];
  }

  /**
 * @method convertFGRecipe
 * @input response Data
 * @output None
 */
convertFGRecipe(responseData: any ): FGCollection {
  try {
   const fCollection = {} as FGCollection;
    if ( responseData ) {
        if ( responseData && responseData.hasOwnProperty('FGRecipes') ) {
              const FGRecipesColl: Array<any> = responseData.FGRecipes;
             if ( FGRecipesColl && FGRecipesColl.length > 0 ) {
                 fCollection.FgCollection = FGRecipesColl;
                 if ( responseData && responseData.RecipeId ) {
                   fCollection.FilterFG =  FGRecipesColl.filter(s => s.Id === responseData.RecipeId);
                 }
             }
        }
        return fCollection;
    }
    
  } catch ( error ) {
    console.error(error);
  }
}

  getCurrencyList() {
    const CurrencyGetUrl = CONSTANT.ENDPOINTURL['InvertoryManagement']['GetCurrencies'];
    this.httpService.GethttpMethod(CurrencyGetUrl).subscribe(res => {
      console.log('Currencies List', res);
      this.currencyList = res['ResultData'] ? res['ResultData'] : res;
    });
  }

  getReasonsLists() {
    this.httpService.GethttpMethod('/inventory/api/SpoilageReasons?$filter=IsActive eq true').subscribe(res => {
      this.spoilageReasonsList = res['value'];
    });
  }

  getUnitList() {
    this.httpService.GethttpMethod('/inventory/api/Units?$filter=IsActive eq true').subscribe(res => {
      console.log('Currencies List', res);
      this.unitList = res['value'];
    });
  }

  getFilterSupplier(SupplierId: number, GridData: Array<any>) {
    return GridData.filter(x => x['SupplierId'] === SupplierId);
  }

  reassignUnitsArray(UnitList: Array<any>, InventoryUnitArray) {
    try {
      InventoryUnitArray.forEach(element => {
        const Index = UnitList.findIndex(x => x.Id === element.UnitId);
        if (Index !== -1) {
          UnitList.splice(Index, 1);
        }
      });
      const ReturnUnitArray: UnitModel[] = InventoryUnitArray;
      UnitList.forEach(element => {
        const newUnit: UnitModel = {
          UnitCode: element.Code,
          UnitId: element.Id,
          UnitName: element.Name,
          UnitTypeId: ReturnUnitArray.length + 1
        };
        ReturnUnitArray.push(newUnit);
      });
      return ReturnUnitArray;
    } catch (error) {
      console.error('Error occurred in reassignUnitsArray', error);
    }
  }

  constractAddNewCycleData(transactionData: TransactionConfiguration, InventoryReassignData: TransactionConfiguration,
    IfAciveBool: boolean, transactionFormGroup: FormGroup) {
    try {
      let addNewInventoryBool = false;
      if (IfAciveBool) {
        transactionFormGroup.patchValue(InventoryReassignData.CreateTranscationConfig.TransactionFormConfig.FormGroupData);
        transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
          [...InventoryReassignData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
        addNewInventoryBool = true;
      }
      return addNewInventoryBool;
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * 
   * @param lisArray 
   * @param homeGroup 
   * To Remove Errors and Validators 
   * Vendor Punchout
   */
  removeValidators(lisArray: any, homeGroup: any) {
    lisArray.forEach((fields: any) => {
      if (homeGroup.controls[fields]) {
        homeGroup.controls[fields].setErrors(null);
        homeGroup.controls[fields].setValidators(null);
      }
     });
     homeGroup.updateValueAndValidity();
  }
}
