import { Validators } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { FormBuilderGroupKey, MasterInventoryManagement } from '../master.interface';
import { FormModelConfiguration, FormModelFieldBuilder } from './form-config.interface';
export const FormFieldValidation: FormModelFieldBuilder = (
        ViewModelEntity: any, PostModelEntity: any, 
        MasterDataCollection: MasterInventoryManagement, 
        httpService: HttpService, 
        FieldGrouping: FormBuilderGroupKey
    ): Array<any> => {
    try {
        return odataFormValidation(FieldGrouping);
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  };

function odataFormValidation(OdataObject: FormBuilderGroupKey) {
  const NoRangeValidatorFields = ['Phone1', 'Phone2', 'Fax'];
  const NoRangeValidation = NoRangeValidatorFields.findIndex(fields => fields === OdataObject['OdataKey']) !== -1 ? true : false;
  const NoDecimalValidatorFields = ['BaseCurrentCost', 'PurchaseCurrentCost', 'TransactionCurrentCost', 'RecipeCurrentCost'];
  const NoDecimalValidation = NoDecimalValidatorFields.findIndex(fieldkey => fieldkey === OdataObject['OdataKey']) !== -1
    ? true : false;
  const odataValidation = [];
  if (OdataObject && OdataObject.hasOwnProperty('Nullable')) {
    const requiredValidation =  { validation: Validators.required, validationMessage: 'This Field is required', key: 'required' };
    odataValidation.push(requiredValidation);
  }
  if (OdataObject && OdataObject.hasOwnProperty('MinLength')) {
    const minLengthValidation =  { validation: Validators.minLength(OdataObject['MinLength']) , validationMessage: 'Min length', key: 'minLength'};
    odataValidation.push(minLengthValidation);
  }
  if (OdataObject && OdataObject.hasOwnProperty('MaxLength') && !OdataObject.hasOwnProperty('FieldFormaters')) {
    const maxLengthValidation = { validation: Validators.maxLength(OdataObject['MaxLength']) , validationMessage: `Max length is ${OdataObject['MaxLength']}`, key: 'maxLength'};
    odataValidation.push(maxLengthValidation);
  }
  if (OdataObject && OdataObject.hasOwnProperty('FieldFormaters')) {
    if (OdataObject.FieldFormaters?.DecimalFormaterConfig && OdataObject.FieldFormaters?.DecimalFormaterConfig?.EnableCustomFormater) {
      const DecimalValidator = { validation: CustomValidator.validateDecimal , validationMessage: 'Value exceeds allowed limit', key: 'invalidDecimal'};
      odataValidation.push(DecimalValidator);
    } else if (OdataObject.FieldFormaters?.CurrencyFormaterConfig && OdataObject.FieldFormaters?.CurrencyFormaterConfig?.EnableCustomFormater) {
      const CurrencyValidator = { validation: CustomValidator.validateCurrency , validationMessage: 'Value exceeds allowed limit', key: 'invalidCurrency'};
      odataValidation.push(CurrencyValidator);
    }
  }
  if (OdataObject && OdataObject.hasOwnProperty('EdmType') && OdataObject['EdmType'] === FieldEdmType.EDMINT32 &&
    OdataObject['FieldType'] !== 'multiselectdropdown') {
    const onlyNumber = { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'};
    odataValidation.push(onlyNumber);
    if (OdataObject['FieldType'] && OdataObject['FieldType'] === 'number') {
      const NumberValidator = { validation: CustomValidator.checkMinusPlusFormat , validationMessage: 'Enter only number', key: 'checkMinusPlusFormat'};
      odataValidation.push(NumberValidator);
    }
  }
  if (OdataObject && OdataObject.hasOwnProperty('EdmType') && OdataObject['EdmType'] === FieldEdmType.EDMINTDECIMAL) {
    const onlyNumber = { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'};
    odataValidation.push(onlyNumber);
    const NumberValidator = { validation: CustomValidator.checkMinusPlusFormat , validationMessage: 'Enter only number', key: 'checkMinusPlusFormat'};
    odataValidation.push(NumberValidator);
  }
  if (OdataObject && OdataObject.hasOwnProperty('EdmType')) {
    if (OdataObject['EdmType'] === FieldEdmType.EDMINT16) {
      const onlyWholeNumberValidation = {validation: CustomValidator.nonDecimalValidators, validationMessage: 'Enter only non decimal value', key: 'InvalidNonDecimalValue'};
      odataValidation.push(onlyWholeNumberValidation);
      const onlyNumberValidation = { validation: CustomValidator.onlyCheckNumber, validationMessage: 'Enter only number', key: 'onlyCheckNumber' };      
      odataValidation.push(onlyNumberValidation);
    } else if (OdataObject['EdmType'] === FieldEdmType.EDMDOUBLE && !NoRangeValidation) {
    } else if (OdataObject['EdmType'] === FieldEdmType.EDMFLOAT) {
    } else if (OdataObject['EdmType'] === FieldEdmType.EDMINT64) {
    } 
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'Email') {
    const emailValid = { validation: CustomValidator.validateEmail , validationMessage: 'Enter valid email', key: 'invalidEmail'};
    odataValidation.push(emailValid);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'EffectiveDate') {
    const emailValid = { validation: CustomValidator.validateEffectiveDate , validationMessage: 'Enter valid effective date', key: 'invalidEffectiveData'};
    odataValidation.push(emailValid);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'InvalidCharacter') {
    const specialCharacter = { validation: CustomValidator.specialCharacterValidation , validationMessage: 'Enter Valid Character', key: 'invalidcharacter'};
    odataValidation.push(specialCharacter);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'ExpirationDate') {
    const emailValid = { validation: CustomValidator.validateExpirationDate , validationMessage: 'Enter valid expiration date', key: 'invalidExpirationData'};
    odataValidation.push(emailValid);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'PositiveNumber') {
    const emailValid = { validation: CustomValidator.OnlyPositiveNumbers , validationMessage: 'Enter only positive number', key: 'onlyPositiveNumber'};
    odataValidation.push(emailValid);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'NumberOnly') {
    const onlyNumber = { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'};
    odataValidation.push(onlyNumber);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'InvalidMRPStartDate') {
    const onlyNumber = { validation: CustomValidator.validateMRPStartDate , validationMessage: 'Please Enter Valid Start Date', key: 'invalidMRPStartDate'};
    odataValidation.push(onlyNumber);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'InvalidMRPEndDate') {
    const onlyNumber = { validation: CustomValidator.validateMRPEndDate , validationMessage: 'Please Enter Valid End Date', key: 'invalidMRPEndDate'};
    odataValidation.push(onlyNumber);
  }
  if (OdataObject && OdataObject.hasOwnProperty('MaxValue') && !OdataObject.hasOwnProperty('FieldFormaters')) {
    const maxLengthValidation = { validation: Validators.max(OdataObject['MaxValue']), validationMessage: `Max Value is ${OdataObject['MaxValue']}`, key: 'max'};
    odataValidation.push(maxLengthValidation);
  }
  if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'PhoneNumber') {
    const onlyNumber = { validation: CustomValidator.phoneNumberValidation , validationMessage: 'Enter valid format', key: 'onlyPhoneNumber'};
    odataValidation.push(onlyNumber);
  }
  // if (OdataObject && OdataObject.hasOwnProperty('Validator') && OdataObject['Validator'] === 'InvalidRange') {
  //   const dateRange = 
  // { validation: CustomValidator.OnlyPositiveNumbers , validationMessage: 'Invalid date range', key: 'invaliddaterange'};
  //   odataValidation.push(dateRange);
  // }
  return odataValidation;
}

function addFormValidation(validationKey: Array<any>): Array<any> {
    const requiredFieldValidation = [
        { validation: Validators.required, validationMessage: 'This Field is required', key: 'required'  },
        { validation: Validators.minLength(5) , validationMessage: 'Min length', key: 'minLength'},
        { validation: Validators.maxLength(5) , validationMessage: 'Min length', key: 'maxlenth'},
        { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlynumber'},
        { validation: CustomValidator.validateEmail , validationMessage: 'Enter valid email', key: 'invalidEmail'},
        { validation: CustomValidator.specialCharacterValidation , validationMessage: 'Invalid Character', key: 'invalidcharacter'},
        { validation: CustomValidator.digitLimitValidator, validationMessage: 'Value is too long. Enter correct value.', key: 'digitlimit'}
      ];
    try {
        if (validationKey && validationKey.length > 0) {
            const validationArray: Array<any>  = [];
            validationKey.forEach(valKey => {
                const index = requiredFieldValidation.findIndex(x => x.key === valKey);
                if (index > -1) {
                    const validationObject = requiredFieldValidation[index];
                    validationArray.push(validationObject);
                }
            });
            return validationArray;
        }
    } catch (error) {
        console.log('error occured addFormValidation', error);
    }
}
