import { Injectable } from "@angular/core";
import { Localization } from 'src/app/common/localization/localization';
import { ActionTypeEnum, aligment } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { CommonDataAwaiters } from "../../shared/events/awaiters/common.data.awaiters";
import { VipType } from "./vip-type.model";


@Injectable(
    { providedIn: "root"}
)
export class VIPTypeBusiness {
  captions:any;

constructor(private _localization: Localization) {
    this.captions = this._localization.captions;
  }
    
    async getTableContent(includeInActive){
        return await CommonDataAwaiters.GetAllVipType(includeInActive);
    }

    async createVipType(vipType){
        return await CommonDataAwaiters.CreateVipType(vipType);
    }

    async updateVipType(vipType, id){
        return await CommonDataAwaiters.UpdateVipType(vipType, id);
    }

    async deleteVipType(id){
        return await CommonDataAwaiters.DeleteVipType(id);
    }

    async getNextListOrder(){
        return await CommonDataAwaiters.GetNextListOrderofVipType();
    }

    async dragDrop(fromOrder, toOrder, includeInActive){
        return await CommonDataAwaiters.DragDropVipType(fromOrder, toOrder, includeInActive);
    }
    async getVipTypeBreakpoint(){
        return await CommonDataAwaiters.GetVipTypeBreakpoint();
    }
    
}