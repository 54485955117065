import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/common/app-service';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { HttpCallService } from 'src/app/common/communication/common/http-call.service';
import { Localization } from 'src/app/common/localization/localization';
import { ServiceParams } from 'src/app/common/Models/http.model';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { BaseResponse } from 'src/app/common/shared/shared/business/shared.modals';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { environment } from 'src/environments/environment';
import { DataMagineTokenRequest, GetEforms, SignatureCaptureRequest, SignatureCaptureResponse, EformRenderRequest } from '../data-magine/data-magine-integration/data-magine-models';
import { DmDocument, DmSigCapture } from '../Models/common.models';
import { DmFile, DmIndexModel, DmIntegrationResponse, DmTokenInfo, DmViewModel } from '../Models/data-magine.model';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';

@Injectable({
    providedIn: 'root'
})
// export class DataMagineIntegrationDataService {

//   constructor(private dataService:CommonCommunication) {

//    }

//    async getDmIntegrationInfo(screenName:string,retriveConfig:boolean,retriveAllDocs:boolean) : Promise<any>
//    {
//       await this.dataService.getPromise({
//         route : environment["PMSManagerService"]+'/V1InternalIntegrationService/DataMagine/GetDmIntegrationInfo'
//       });
//    }

// }

@Injectable({ providedIn: 'root' })
export class DataMagineIntegrationDataService extends HttpCallService {

    constructor(
        private httpclient: HttpClient,
        localization: Localization,
        utilities: CommonUtilities,
        propertyinfo: CommonPropertyInformation,
        public storageservice:StorageService,
        appservice: AppService) {
        super(appservice.hosts.PMSManagerService, httpclient, localization, utilities, propertyinfo, appservice);
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        const response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromiseCancellable<T>(params: ServiceParams, handleErr: boolean = true, notifier: Subject<void>): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.putCancellablePromise<BaseResponse<T>>(params, notifier);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }
    /*
    This is not the correct way to get return type as base response.
    Todo: IMPORTANT. NEED TO REVAMPED
    */
    public async postPromiseReturnsBaseResponse<T>(params: ServiceParams, handleErr: boolean = true): Promise<BaseResponse<T>> {

        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response;
    }

    public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }


    public async patchPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.patchPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }


    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err;
        }
    }

    async getDmIntegrationInfo(): Promise<DmIntegrationResponse> {
        return await this.getPromise<DmIntegrationResponse>({
            route: CommonApiRoutes.GetAllDmSettings
        }, false);
    }

    async getDmTokenInfo(url: string, userName: string, password: string): Promise<DmTokenInfo> {
        var res = await this.httpclient.post<DmTokenInfo>(url + CommonApiRoutes.CheckConnection, { username: userName, password: password }).toPromise();
        if (res && res.StatusCode == 200 && res.Result && res.Result.access_token) {
            this.storageservice.setstoragekey("dm_token", "Bearer " + res.Result.access_token);
        }
        return res;
    }
    async getDMTokenInfo(url: string, userName: string, password: string): Promise<DmTokenInfo> {
        var res = await this.httpclient.post<DmTokenInfo>(url, { username: userName, password: password }).toPromise();
        if (res && res.StatusCode == 200 && res.Result && res.Result.access_token) {
            this.storageservice.setstoragekey("dm_token", "Bearer " + res.Result.access_token);
        }
        return res;
    }
    async getDmTokenInfoSNC(url: string, userName: string, password: string): Promise<DmTokenInfo> {
        let header = new HttpHeaders({
            "x-api-version": "2.0",
        });
        let body = new FormData();
        body.append('ClientId', `${userName}`);
        body.append('ClientSecret', `${password}`);
        var res = await this.httpclient.post<DmTokenInfo>(url + CommonApiRoutes.CheckSNCConnection, body, { headers: header }).toPromise();
        if (res && !res.StatusCode) {
            this.storageservice.setstoragekey("dm_snc_token", "Bearer " + res.access_token);
            res.StatusCode = 200;
        }
        return res;
    }


    async createDocumentIndex(url: string, viewIdentifier: string, file: DmFile, indexInfo: string[], fileExtension: string, body): Promise<any> {

        let combinedUrl = url + CommonApiRoutes.IndexingUrl;

        let header = new HttpHeaders({
            "Authorization": this.storageservice.getstoragekey("dm_token"),
            "x-api-version": "2",
        });

        let fd = new FormData();

        fd.append("BreakMultiplePdf", "false");
        fd.append("BreakMultipleTiff", "false");
        fd.append("PdfToTiffConvert", 'false');
        fd.append("Overwriteflag", '1'); //Need to check
        fd.append("PCname", 'V1PC');
        fd.append("Annotation", '');
        fd.append("SecurityLevel", "1000"); //Need to get it from config
        fd.append("Viewname", viewIdentifier);

        if (indexInfo.length > 0) {
            indexInfo.forEach(item => {
                if (body[item]) {
                    fd.append("Indexvaluelist", body[item]);
                } else {
                    fd.append("Indexvaluelist", '');
                }
            });
        }

        let blob = new Blob([file.content], { type: (file.fileType && file.fileType.length >0) ? file.fileType : 'image/png' })
        fd.append("Files", blob, file.fileName);
        var res = await this.httpclient.post(combinedUrl, fd, { headers: header }).toPromise<any>();
        return { response: res, request: combinedUrl, fileName: file.fileName, fileExtension: fileExtension };
    }

    async getAllViews(baseUrl: string, userName: string, password: string): Promise<DmViewModel[]> {
        if (this.storageservice.getstoragekey("dm_token") == null) {
            let tkn = await this.getDmTokenInfo(baseUrl, userName, password);
            this.storageservice.setstoragekey("dm_token", tkn.Result.access_token);
        }
        let url = baseUrl + CommonApiRoutes.GetAllViews;
        let header = new HttpHeaders({
            "Authorization":this.storageservice.getstoragekey("dm_token"),
            "x-api-version": "2",
        });

        var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        return res.result;
    }

    async getAllIndexesByView(baseUrl: string, viewName: string, userName: string, password: string): Promise<DmIndexModel[]> {
        if (this.storageservice.getstoragekey("dm_token") == null) {
            let tkn = await this.getDmTokenInfo(baseUrl, userName, password);
            this.storageservice.setstoragekey("dm_token", tkn.Result.access_token);
        }
        let url = baseUrl + CommonApiRoutes.GetIndexesByViewName.replace("{viewName}", viewName);
        let header = new HttpHeaders({
            "Authorization":this.storageservice.getstoragekey("dm_token"),
            // "x-api-version": "2",
        });

        var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        return res.Result;
    }

    async searchDmDoc(baseUrl: string, body) {
        let url = baseUrl + CommonApiRoutes.SearchDmDoc;
        let header = new HttpHeaders({
            "Authorization": this.storageservice.getstoragekey("dm_token"),
            "x-api-version": "2",
        });
        var res = await this.httpclient.post(url, body, { headers: header }).toPromise<any>();
        return res;
    }

    async exportDoc(baseUrl: string, id: string, exportInOriginalFormat: boolean) {
        let url = baseUrl + CommonApiRoutes.ExportDmDocs.replace("{ids}", id).replace("{exportInOriginalFormat}", exportInOriginalFormat.toString());
        let header = new HttpHeaders({
            "Authorization":this.storageservice.getstoragekey("dm_token"),
            "x-api-version": "2",
        });

        let res = await Promise.all([this.httpclient.get(url, { headers: header, responseType: 'blob' as 'json' }).toPromise().then(
            (response: any) => {
                return response;
            }
        )]);
        return res[0];
    }

    public async deleteDoc(baseUrl: string, folderId: string, docId: string) {
        let url = baseUrl + CommonApiRoutes.DeleteDmDoc.replace("{folderId}", folderId).replace("{documentId}", docId);
        let header = new HttpHeaders({
            "Authorization": this.storageservice.getstoragekey("dm_token"),
        });
        var res = await this.httpclient.delete(url, { headers: header }).toPromise<any>();
        return res;
    }

    async getDmFormDetails(screenId: string, id: string): Promise<any> {
        var input: ServiceParams = {
            route: '',//CommonApiRoutes.GetDmFormDetails,
            uriParams: { "screenId": screenId, "id": id }
        };
        var res = await this.getPromise<any>(input);
        return res;
    }

    public async getIdScanner(baseUrl: string): Promise<any> {
        let url = baseUrl;
        // let header = new HttpHeaders({
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Request-Method": "GET"
        //     // "Allow-Control-Allow-Credentials": "true",
        //     // "Access-Control-Allow-Origin": "*",
        //     // "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS"
        // });
        // var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        // let request = new Request(url,
        //     {
        //         method: 'GET',
        //         mode: 'no-cors',
        //         cache: 'no-cache',
        //     });
        // return await fetch(request);
        var res = await this.httpclient.get(url).toPromise<any>();
        return res;
    }

    public async getIdScanner1(baseUrl: string): Promise<any> {
        let url = baseUrl;
        // let header = new HttpHeaders({
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Request-Method": "GET"
        //     // "Allow-Control-Allow-Credentials": "true",
        //     // "Access-Control-Allow-Origin": "*",
        //     // "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS"
        // });
        // var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        let request = new Request(url,
            {
                method: 'GET',
                mode: 'no-cors',
                cache: 'no-cache',
            });
        fetch(request).then(res => {
            console.log(res);
        });
        //var res = await this.httpclient.get(url).toPromise<any>();
        //return res;
    }

    public GetSigCaptureByTranId(transactionId: string): Promise<DmSigCapture> {
        const result = this.getPromise<DmSigCapture>(
            { route: CommonApiRoutes.GetSigCaptureByTranId, uriParams: { transactionId } }, false);

        return result;
    }

    public async inititateSigCapDevice(stayId: string, propertyId: string, tenantId: string, tenantCode: string, transactionId: string, baseUrl: string): Promise<SignatureCaptureResponse> {
        let callBackURL = environment["InternalIntegration"] + CommonApiRoutes.SigCaptureCallBack;        
        if (!(callBackURL.indexOf("http://") > -1 || callBackURL.indexOf("https://") > -1))
        {
            callBackURL = window.location.origin + callBackURL;
        }  
        var signaturerequest: SignatureCaptureRequest = {
            propertyName: propertyId,
            resid: stayId,
            machineID: "",
            transactionId: transactionId,
            callBackURL: callBackURL,
            callBackWithSignature: "false",
            port: 0,
            deviceId: "",
            tenantId: Number(tenantId),
            formSet: "V1",
            tenantCode: tenantCode,
        };
        var res = await this.httpclient.post(baseUrl, signaturerequest).toPromise<any>();

        return res;
    }

    public async inititateEformRender(eFormUrl: string, eFormRenderUrl: string): Promise<SignatureCaptureResponse> {
        var signaturerequest: EformRenderRequest = {
            url: eFormUrl
        };
        
        var res = await this.httpclient.post(eFormRenderUrl, signaturerequest).toPromise<any>();
        return res;
    }

    public async inititateEformCancel(eFormCancelUrl: string) {
        let body = {};
        await this.httpclient.post(eFormCancelUrl, body).toPromise<any>();
    }

    public async getDataMagineToken(dataMagineTokenRequest: DataMagineTokenRequest) {
        let header = new HttpHeaders({
            "x-api-version": "2.0",
        });
        
        let fd = new FormData();
        fd.append("ClientId", dataMagineTokenRequest.clientID);
        fd.append("ClientSecret", dataMagineTokenRequest.clientSecret);

        var res = await this.httpclient.post(dataMagineTokenRequest.authURL, fd, { headers: header }).toPromise<any>();
        return res;
    }
    public async getDMLoginToken(authURL: string, username: string, password: string) {
       let url = authURL + CommonApiRoutes.DMLogin;
        let header = new HttpHeaders({
            "x-api-version": "1.0",
        });

        let body = {
            username : username,
            password : password,
            toggleSeat: false
        };
        
        var res = await this.httpclient.post(url, body, { headers: header }).toPromise<any>();
        return res;
    }

    public async getDataMagineLoginToken(authURL: string, username: string, password: string) {
        let url = authURL + CommonApiRoutes.DataMagineAuthLogin;
         let header = new HttpHeaders({
             "x-api-version": "1.0",
         });
 
         let body = {
             username : username,
             password : password,
             toggleSeat: false
         };
         
         var res = await this.httpclient.post(url, body, { headers: header }).toPromise<any>();
         return res;
     }

    public async getEforms(token: string, DMEFormsListURL: string, tenantID: string, propertyID: string) {
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token
        });
        let url: string = `${DMEFormsListURL}`;
        url = url.replace("{0}", tenantID).replace("{1}",propertyID);


        var res = await this.httpclient.get<GetEforms>(url, { headers: header }).toPromise<GetEforms>();
        return res;
    }

    public async getformsLinkURL(formID: string, token: string, DMFormLinkURL: string, stayDetail: {confirmationNumber: string; stayID: string, isWebcheckIn: boolean} ) {
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token
        });
        let url: string;

        if(stayDetail.confirmationNumber && stayDetail.stayID) {
            url = `${DMFormLinkURL}?EFormId=${formID}&QueryParameters=encryptedConfirmation=${stayDetail.confirmationNumber}&isWebCheckin=${stayDetail.isWebcheckIn}&Resid=${stayDetail.stayID}`;
        } else {
            url = `${DMFormLinkURL}?EFormId=${formID}`;
        }
        var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        return res;
    }

    public async getEformsLinkURL(formID: string, token: string, DMFormLinkURL: string, stayDetail: {transactionId: string; stayID: string, userName: string} ) {
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token
        });
        let url: string;

        if(stayDetail.transactionId && stayDetail.stayID && stayDetail.userName) {
            url = `${DMFormLinkURL}?EFormId=${formID}&QueryParameters=stayId%3d${stayDetail.stayID}%26transactionId%3d${stayDetail.transactionId}%26userName%3d${stayDetail.userName}`;
        } else {
            url = `${DMFormLinkURL}?EFormId=${formID}`;
        }
        var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        return res;
    }

    public async getSpaformsLinkURL(formID: string, token: string, DMFormLinkURL: string, appointmentDetail: { appointmentId: number; guestId: string} ) {
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token
        });
        let url: string = `${DMFormLinkURL}`;
        
        if(appointmentDetail.appointmentId) {
            url = url.replace("{0}", formID).replace("{1}",appointmentDetail.appointmentId.toString() ).replace("{2}", formID);
        } else {
            url = url.replace("{0}", formID);
        }
        var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        return res;
    }

    public async getSubmittedEforms(serverUrl: string, token: string, body: any ) {
        let url = serverUrl+ CommonApiRoutes.SearchDmDoc;
       
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token,
        });
        
       
        var res = await this.httpclient.post(url, body, { headers: header }).toPromise<any>();
        return res;
    }

    public async getDMSubmittedEforms(serverUrl: string, token: string, body: any ) {
        let url = serverUrl;
       
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token,
        });
        
       
        var res = await this.httpclient.post(url, body, { headers: header }).toPromise<any>();
        return res;
    }

    public async getSubmittedEformsById(serverUrl: string, token: string, folderId: number, docId: number ) {

        let url = serverUrl+ CommonApiRoutes.GetSubmittedDoc.replace("{folderId}", folderId.toString());
        url = url.replace("{documentId}", docId.toString());
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token,
        });
        
        var res = await Promise.all([this.httpclient.get(url, { headers: header,  responseType: 'blob' as 'json'}).toPromise().then(
            (response: any) => {
               
                var blob = new Blob([response], { type: 'application/pdf' })            
                this.showFile(blob);
            }
        )]);
    }

    public async getDMSubmittedEformsById(serverUrl: string, token: string, folderId: number, docId: number ) {

        let url = serverUrl.replace("{folderId}", folderId.toString());
        url = url.replace("{documentId}", docId.toString());
        let header = new HttpHeaders({
            "x-api-version": "2.0",
            "Authorization": "Bearer"+" "+token,
        });
        
        var res = await Promise.all([this.httpclient.get(url, { headers: header,  responseType: 'blob' as 'json'}).toPromise().then(
            (response: any) => {
               
                var blob = new Blob([response], { type: 'application/pdf' })            
                this.showFile(blob);
            }
        )]);
    }

    private showFile(blob) {

        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        let newTab: Window = window.open(data);
        newTab.focus();
        newTab.print();  
    }
   

    async getViewByViewName(baseUrl: string, viewName: string) {
        let url = baseUrl + CommonApiRoutes.GetViewByViewName.replace("{viewName}", viewName);
        let header = new HttpHeaders({
            "Authorization": this.storageservice.getstoragekey("dm_token"),
            "x-api-version": "2",
        });

        var res = await this.httpclient.get(url, { headers: header }).toPromise<any>();
        return res.result;
    }
    

}

