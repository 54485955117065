import { GridSortAction, MasterInventoryManagement } from '../master.interface';
import { GridModelConfiguration } from './grid-config.interface';

export const GridSortingOrder: GridModelConfiguration = (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement): any => {
    try {
        let sortOrder = '';
        if ( MasterDataCollection && MasterDataCollection.GridConfig && MasterDataCollection.GridConfig.GridSort ) {
            const sort = MasterDataCollection.GridConfig.GridSort.filter( ( sortorder: GridSortAction) => 
            sortorder.DefaultSorting === true);
            sortOrder = sort[0].SortingOdataKey + ' ' + sort[0].SortingType.toLowerCase();
            return sortOrder;
        } else {
            return '';
        }
    } catch (error) {
        console.log('error occured SetSortOrder', error);
    }
};