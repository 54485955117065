import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import * as _ from "lodash";
@Component({
  selector: 'app-sales-by-category',
  templateUrl: './sales-by-category.component.html',
  styleUrls: ['./sales-by-category.component.scss']
})
export class SalesByCategoryComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  CategoryFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  registers:any;
  clerks:any[];
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any[];
  categoryGroupSelected : any[] = [];
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, public business: ReportBusinessService, private dataService: ReportDataService) { }

  ngOnInit() {
    this.radioFilter = [/*{ id: 0, name: 'Detail By Register' }, { id: 1, name: 'Summary By Register' },*/ { id: 0, name: 'Detail By Outlet' }, { id: 1, name: 'Summary By Outlet' }];
    this.CategoryFormGrp = this.fb.group({
      reportcategory: new UntypedFormControl(),
      register: new UntypedFormControl(),
      clerks: new UntypedFormControl(),
      itemFilter: 0
    });
    this.formReady.emit(this.CategoryFormGrp);
    this.onLoad();
  }

  private async onLoad() {
    this.clerks = await this.dataService.GetAllUsersByPropertyId();
    this.clerks= _.orderBy(this.clerks, [user => user.description.toLowerCase()], 'asc');
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();

    this.business.FilterDataSource['allCategoryGroups'] = this.allCategoryGroups;
    this.business.FilterDataSource['clerks'] = this.clerks;
  }

  formInitialized(form: UntypedFormGroup) {
    this.CategoryFormGrp.setControl('category', form);
  }
 

  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
    this.CategoryFormGrp.setControl('reportcategory', event[1]);
    } else if (event[0] === 'clerks') {
      this.CategoryFormGrp.setControl('clerks', event[1]);
      }
  }

  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }
}
