import { CustomerDataUI,ARAccountStatus, SearchBy } from './ar-customer-search.model';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ArCustomerSearchBusiness } from './ar-customer-search.business';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { ButtonValue, TableOptions, TableHeaderOptions, FilterGroup, AsideFilterConfig } from 'src/app/common/Models/ag-models';
import { SearchDropdownOptions } from 'src/app/common/shared/shared.modal';
import { debounceTime, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-ar-customer-search',
  templateUrl: './ar-customer-search.component.html',
  styleUrls: ['./ar-customer-search.component.scss'],
  providers: [ArCustomerSearchBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ArCustomerSearchComponent implements OnInit {
  captions: any;
  DialogCloseEnum = DialogCloseEnum;
  proceedButton: ButtonValue;
  cancelButton: ButtonValue;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent: Observable<CustomerDataUI[]> = of([]);
  originaltableContent: CustomerDataUI[];
  searchText: string;
  searchTextChanged = new Subject<string>();
  SEARCH_TRIGGER_LENGTH = 3;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  filtergroups: FilterGroup[] = [];
  filterConfig: AsideFilterConfig;
  selectedCustomer: any;
  selectedFilter: number = 1;
  dropdownSearchOptions: SearchDropdownOptions[];
  onlyActiveAccounts : boolean;
  showWarning: boolean;
  statusMessage: string;
  constructor(
    private business: ArCustomerSearchBusiness,
    private dialogRef: MatDialogRef<ArCustomerSearchComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.captions = this.business.captions;
  }

  ngOnInit(): void {
    this.proceedButton = {
      type: 'primary',
      label: this.captions.btn_proceed,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
    this.selectedFilter = SearchBy.AccountName;
    this.intializeTableInputs();
    this.initializeFilterInputs();
    this.searchOptions();
  }

  intializeTableInputs() {
    this.onlyActiveAccounts =this.data?.onlyActiveAccounts;
    this.tableOptions = this.business.getTableOptions(this.data?.isMultiple);
    this.headerOptions = this.business.getHeaderOptions(SearchBy.AccountNumber, this.data?.isMultiple);
  }
  initializeFilterInputs() {
    const alphabet = this.captions.alphabets;
    if (alphabet.indexOf(this.captions.lbl_all) < 0) {
      alphabet.unshift(this.captions.lbl_all);
    }
    this.filterConfig = {
      filterText: this.captions.lbl_filter,
      resetText: this.captions.lbl_reset,
      displayCount: 3,
      panelClass: 'lg',
      alphabets: this.captions.alphabet,
      isMultiSelect: true,
      viewMoreConfig: {
        apply: this.captions.btn_apply,
        cancel: this.captions.btn_cancel,
        alphabets: alphabet,
        searchByPlaceHolder: this.captions.lbl_searchBy
      }
    };
    let statusOptions = this.business.getStatus();
    this.filtergroups = [
      {
        id: 1,
        name: 'status',
        title: this.captions.lbl_byStatus,
        filters: statusOptions,
        filtered: statusOptions.filter(x => x.isSelected),
        isSingleSelect: false
      }

    ];

    this.searchTextChanged.pipe(
      mergeMap(searchKey => this.business.GetAccountsByFilter(searchKey, this.selectedFilter,this.onlyActiveAccounts)),
      takeUntil(this.destroyed$)
    ).subscribe(x => {
      this.originaltableContent = x;
      this.tableContent = of(this.originaltableContent);
    });
  }

  closePopUp(from) {
    this.dialogRef.close({
      from: from,
      data: this.selectedCustomer
    })
  }

  async searchCustomer(searchTerm: string) {
    this.searchText = searchTerm;
    if (searchTerm && searchTerm.length >= this.SEARCH_TRIGGER_LENGTH) {
      this.searchTextChanged.next(searchTerm);
    }
  }

  onRowClick(event) {
    if(!event.Obj.isRowClickDisable)
    {
      this.showWarning=false;
      if (event.fromType == FromTypeEnum.rowClick) {
        this.selectedCustomer = event.Obj;
        this.proceedButton.disabledproperty = false;
        this.proceedButton = { ...this.proceedButton };
      }
    }
    else
    {
      this.showWarning=true;
      switch(event.Obj.statusId)
      {
      case ARAccountStatus.Hold:
        this.statusMessage =this.captions.HoldMsg ;
        break;
      case ARAccountStatus.AccessToFacilities:
        this.statusMessage = this.captions.AccessToFacilityMsg ;
        break;
      case ARAccountStatus.Frozen:
        this.statusMessage = this.captions.FrozenMsg ;
        break;
      case ARAccountStatus.Expired:
        this.statusMessage = this.captions.ExpiredMsg ;
        break;
      case ARAccountStatus.Suspended:
        this.statusMessage = this.captions.SuspendMsg ;
        break;
      }
    }
  }

  onAction() {

    this.closePopUp(DialogCloseEnum.Action);

  }

  onCancel() {
    this.closePopUp(DialogCloseEnum.Close);
  }

  filterHandler(event) {
    var status = event.filtered.map(o => o.name);
    if (status.length > 0) {
      this.tableContent = of(this.originaltableContent.filter(o => status.includes(o.status)));
    }
    else this.resetfilter();
  }

  resetfilter() {
    this.tableContent = of(this.originaltableContent);
  }

  searchByChange(event) {
    this.selectedFilter = event;
    this.headerOptions = [...this.business.getHeaderOptions(event)];
  }
  searchOptions() {
    this.dropdownSearchOptions = this.business.getSearchDropdownOptions();
  }

  onTableAction(event) {
    switch (event.fromType) {
      case FromTypeEnum.rowcheck:
      case FromTypeEnum.allcheckbox:
        this.selectedCustomer = event.array.filter(item => item.checked);
        this.proceedButton.disabledproperty = false;
        this.proceedButton = { ...this.proceedButton };
        break;
    }
  }


  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

}
