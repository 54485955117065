import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridFilterData, GridFilterValue } from 'src/app/eatecui/dist/AgilysysDynamicGrid/lib/interface/common';
import { GridFilterAction } from '../app-grid.interface';
import { GridFilterKeys } from '../grid-data.interface';
import { FilterOption } from '../grid.interface';

export const GetFilterOption: FilterOption = (FilterKeys: GridFilterAction[], ApiUrl: string,
    httpService: HttpService): any => {
        return new Promise(function (resolve, reject) {
            const GridFilterRowData = [];
            if (FilterKeys && FilterKeys.length > 0) {
                FilterKeys.forEach(async (Keys: GridFilterAction) => {
                    const getEndPointUrl = assignEnpointUrl(Keys, ApiUrl);
                    if (getEndPointUrl !== null) {
                        const response = await httpService.GethttpMethod(getEndPointUrl).toPromise();
                        if (response && response['value'] && response['value'].length > 0) {
                            let filterKeyData = [];
                            if (Keys.IsCustomFilterName) {
                                filterKeyData = response['value'].map((data: any) => {
                                    const filterKey = {} as GridFilterValue;
                                    filterKey.FilterId = data[Keys.CustomFilterName[0].ColumnName];
                                    filterKey.FilterName = data[Keys.CustomFilterName[1].ColumnName];
                                    filterKey.Checked = false;
                                    return filterKey;
                                });
                            } else {                                
                                filterKeyData = response['value'].map((data: any) => {
                                    const filterKey = {} as GridFilterValue;
                                    filterKey.FilterId = data.Id;
                                    filterKey.FilterName = data.Name;
                                    filterKey.Checked = false;
                                    return filterKey;
                                });
                            }
                            const filterDisplay = {} as GridFilterData;
                            filterDisplay.FilterKey = Keys.FilterDisplayName;
                            filterDisplay.FilterValue = filterKeyData;
                            filterDisplay.FilterOriginalKey = Keys.FilterKey;
                            // filterDisplay.FilterNavigationKey = NavigationReferentialConstraint;
                            filterDisplay.Type = 'multiselect';
                            GridFilterRowData.push(filterDisplay);
                            console.log('GridFilterRowData', GridFilterRowData);
                        }
                    } else if (Keys.CustomFilterOptions) {
                        const customfilterKeyData = Keys.CustomFilterOptions.map((data: any) => {
                            const filterKey = {} as GridFilterValue;
                            filterKey.FilterId = data.key;
                            filterKey.FilterName = data.label;
                            filterKey.Checked = false;
                            return filterKey;
                        });
                        const filterDisplay = {} as GridFilterData;
                            filterDisplay.FilterKey = Keys.FilterDisplayName;
                            filterDisplay.FilterValue = customfilterKeyData;
                            filterDisplay.FilterOriginalKey = Keys.FilterKey;
                            // filterDisplay.FilterNavigationKey = NavigationReferentialConstraint;
                            filterDisplay.Type = 'multiselect';
                            GridFilterRowData.push(filterDisplay);
                            console.log('GridFilterRowData', GridFilterRowData);
                    }
                    resolve(GridFilterRowData);
                });
            } else {
                resolve([]);
            }
        });

};

function assignEnpointUrl(GridFilterDataObj: GridFilterAction, BaseUrl: string): string {
    try {
        let endpointUrl = null;
        if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('NavigationUrl') &&
            GridFilterDataObj.NavigationUrl) {
            endpointUrl = '/' + GridFilterDataObj.NavigationUrl;
        }
        return endpointUrl;
    } catch (error) {
        console.error(error);
    }
}
