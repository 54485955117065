import { BaseResponse, OverBookOptions, PackageMoveValidators, AppointmentPrice, ServiceDetail, ServiceViewModel, clientMultiPack, GenderOverrideTransLog, LogEntry } from '../../shared/business/shared.modals';
import { HttpServiceCall, HttpMethod } from "../service/http-call.service";
import * as GlobalConst from '../globalsContant';
import { appointmentService } from "../service/appointment.service";
import { AppointmentpopupService } from "../service/appointmentpopup.service";
import { SpaUtilities } from "./spa-utilities";
import { Injectable, ViewContainerRef } from "@angular/core";
import { appointment, appointmentDetail, appointmentTherapist, TempHoldApp, ServiceClientMedicalWarning } from "../business/shared.modals";
import * as _ from 'lodash'
import { SpaLocalization } from "../../core/localization/spa-localization";
import { BreakPointAccess } from '../service/breakpoint.service';
import { MedicalWarningDialogComponent } from '../appointment-popup/create-appointment/medical-warning-dialog/medical-warning-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionLike as ISubscription } from "rxjs";
import { DepositInfoDialogComponent } from '../appointment-popup/create-appointment/deposit-info-dialog/deposit-info-dialog.component';

export enum AppointmentStatus {
    RESV = "RESV",
    CKIN = "CKIN",
    CKOUT = "CKOUT",
    NOSHOW = "NOSHOW",
    TEMP = "TEMP"
}

export interface TempHoldParams {
    StartTime?: string
    EndTime?: string
    ServiceId?: number
    LocationId?: number
    TherapistId?: number[]
    ClientId?: number
    AppointmentId?: number
    status: AppointmentStatus
}

@Injectable()
export class AppointmentUtilities {
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    subscription: ISubscription;

    constructor(private http: HttpServiceCall, private apptService: AppointmentpopupService, private utils: SpaUtilities,
        private _appService: appointmentService, private localization: SpaLocalization, private breakPoint: BreakPointAccess, public dialog: MatDialog) {

    }

    appointmentId: number;
    tempHoldObj: TempHoldApp;

    ValidateTempHoldParam(params: TempHoldParams): boolean {
        return true;
    }

    CreateTempHoldAppointment(appointmentDetails: appointment, successCallback?: (callDesc: string, result: any) => void,
        errorCallback?: (callDesc: string, result: any) => void) {

        let uriParams = { istherapistoverbook: false, islocationoverbook: false };
        appointmentDetails.appointmentDetail.startTime = appointmentDetails.appointmentDetail.startTime ? appointmentDetails.appointmentDetail.startTime : undefined;
        appointmentDetails.appointmentDetail.endTime = appointmentDetails.appointmentDetail.endTime ? appointmentDetails.appointmentDetail.endTime : appointmentDetails.appointmentDetail.startTime;
        this.http.CallApiWithCallback({
            host: GlobalConst.Host.schedule,
            success: successCallback ? successCallback : this.successCallback.bind(this),
            error: errorCallback ? errorCallback : this.errorCallback.bind(this),
            callDesc: "CreateTempHoldAppointment",
            uriParams: uriParams,
            method: HttpMethod.Post,
            body: appointmentDetails,
            showError: true,
            extraParams: [appointmentDetails]
        });
    }

    CreateTempForCopyMove(appointmentDetails: appointment, isLocationView: boolean, successCallback?: (callDesc: string, result: any) => void,
        errorCallback?: (callDesc: string, result: any) => void) {

        let uriParams = { isLocationView: isLocationView };
        this.http.CallApiWithCallback({
            host: GlobalConst.Host.schedule,
            success: successCallback ? successCallback : this.successCallback.bind(this),
            error: errorCallback ? errorCallback : this.errorCallback.bind(this),
            callDesc: "CreateTempForCopyMove",
            uriParams: uriParams,
            method: HttpMethod.Post,
            body: appointmentDetails,
            showError: true,
            extraParams: [appointmentDetails]
        });
    }

    async UpdateTempHoldAppointment(appointmentDetails: any) {
        this.http.CallApiWithCallback({
            host: GlobalConst.Host.schedule,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "UpdateTempHoldAppointment",
            method: HttpMethod.Put,
            body: appointmentDetails,
            showError: false,
            extraParams: []
        });
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {

        if (callDesc == "CreateTempHoldAppointment") {
            if (result.result) {
                let newTempApt = _.cloneDeep(this.tempHoldObj);
                newTempApt.appointmentId = result.result[0];
                let apiBody: appointment = extraParams[0];
                if (apiBody) {
                    newTempApt.clientId = apiBody.appointmentDetail.clientId;
                }
                this.apptService.TempHoldArr.push(newTempApt);
            }
        }
        else if (callDesc == "DeleteTempHold") {
            if (result.result) {
                this.TempHoldArrRemoveAppt(extraParams);
            }
        }

        else if (callDesc == "GetItineraryForAppointment") {
            let clientIteneraryByDate = <any>result.result;
            this.apptService.resultNewAppointment.timelineData = clientIteneraryByDate;
        }
    }


    errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        if (callDesc == "UpdateTempHoldAppointment") {
            if (this.apptService.IsTempRemovedFromBackEnd(error)) {
                this._appService.tempRecordCreated = false;
                this.apptService.TempHoldArr = [];
                this.apptService.recreateTempAppointments = true;
            }
        }
    }


    TempHoldArrRemoveAppt(tempIds: any) {
        for (let i = 0; i < tempIds.length; i++) {
            const tempIdToRemove = tempIds[i];
            if (tempIdToRemove > 0) {
                let tempHoldIndex = this.apptService.TempHoldArr.findIndex(function (tempobj) {
                    return tempobj.appointmentId == tempIdToRemove;
                });

                if (tempHoldIndex !== -1) {
                    this.apptService.TempHoldArr.splice(tempHoldIndex, 1);
                }
            }
        }

    }

    //Create a temp hold for new appt.
    async CreateTempHoldObject(tempHoldValues: TempHoldApp, successCallback?: (callDesc: string, result: any) => void,
        errorCallback?: (callDesc: string, result: any) => void, isValidationRequired: boolean = false, isLocationView: boolean = false) {

        let isTherapistoverbook: boolean = false, isLocationOverbook = false;
        if (this.apptService.isOverbook) {
            if (this.apptService.isTherapistView) {
                isTherapistoverbook = true;
            }
            else {
                isLocationOverbook = true;
            }
        }
        this.tempHoldObj = tempHoldValues;
        const tempStartTime = tempHoldValues.startTime ? tempHoldValues.startTime : '';
        let apptDetail: appointmentDetail = {
            startTime: tempStartTime,
            endTime: tempHoldValues.endTime ? tempHoldValues.endTime : tempStartTime,
            originalStartTime: tempStartTime,
            originalEndTime: tempHoldValues.endTime ? tempHoldValues.endTime : tempStartTime,
            serviceGroupId: tempHoldValues.serviceGroupId,
            clientId: tempHoldValues.clientId,
            serviceId: tempHoldValues.service ? tempHoldValues.service : 0,
            locationId: tempHoldValues.location ? tempHoldValues.location : 0,
            comments: tempHoldValues.comments ? tempHoldValues.comments : "",
            status: AppointmentStatus.TEMP,
            cancelId: "",
            duration: tempHoldValues.duration ? tempHoldValues.duration : 0,
            setUpTime: tempHoldValues.setUpTime ? tempHoldValues.setUpTime : 0,
            breakDownTime: tempHoldValues.breakDownTime ? tempHoldValues.breakDownTime : 0,
            price: tempHoldValues.price ? this.localization.currencyToSQLFormat(tempHoldValues.price) : 0,
            doNotMove: tempHoldValues.doNotMove ? tempHoldValues.doNotMove : false,
            requestStaff: tempHoldValues.requestStaff ? tempHoldValues.requestStaff : false,
            isBookingLocked: tempHoldValues.isBookingLocked ? tempHoldValues.isBookingLocked : false,
            isVip: tempHoldValues.isVip ? tempHoldValues.isVip : false,
            genderPreference: tempHoldValues.genderPreference ? tempHoldValues.genderPreference : 0,
            checkOutComments: tempHoldValues.checkOutComments ? tempHoldValues.checkOutComments : "",
            noShowComments: tempHoldValues.noShowComments ? tempHoldValues.noShowComments : "",
            noShow: tempHoldValues.noShow ? tempHoldValues.noShow : false,
            packageId: tempHoldValues.packageId ? tempHoldValues.packageId : 0,
            appointmentType: "",
            cancelReason: 0,
            cancelComments: "",
            cancelFee: 0,
            customField1: 0,
            customField2: 0,
            customField3: 0,
            customField4: "0",
            customField5: "0",
            tempHoldId: "",
            tempHoldLinkId: 0,
            isTherapistOverbook: isTherapistoverbook,
            isLocationOverbook: isLocationOverbook,
            clientComments: "",
            ServiceCharge: tempHoldValues.ServiceCharge,
            Gratuity: tempHoldValues.Gratuity,
            TotalAmount: tempHoldValues.TotalAmount,
            yieldPrice: 0,
            intakeForm: false,
            guestTypeId:tempHoldValues.guestTypeId ? tempHoldValues.guestTypeId : 0,
            discountType: tempHoldValues.discountType ? tempHoldValues.discountType : ""
        }

        let apptTherapist: appointmentTherapist[] = [];

            for (let index = 0; index < tempHoldValues.therapist.length; index++) {
                const element = tempHoldValues.therapist[index];
                let therapist: appointmentTherapist = {
                    therapistId: element,
                    appointmentId: tempHoldValues.appointmentId
                }


                apptTherapist.push(therapist);

            }
        let apptAddon: any = [];
        let apptPmsActivityId = this.apptService.pmsActivityId?this.apptService.pmsActivityId:null;
        let externalPMSActivityId = this.apptService.externalPMSConfNo?this.apptService.externalPMSConfNo:null;

        let appointmentDetails: appointment = {
            appointmentDetail: apptDetail,
            appointmentTherapists: apptTherapist,
            appointmentAddOn: apptAddon,
            isFromAssignTherapist: tempHoldValues.isFromAssignTherapist ? tempHoldValues.isFromAssignTherapist : false,
            pMSActivityId: apptPmsActivityId,
            externalPMSConfNo:externalPMSActivityId
        }

        if (this.apptService.fromClientModule) {
            await this.CreateAppointmentTempHold(appointmentDetails);
        }
        else {
            if (isValidationRequired) {
                this.CreateTempForCopyMove(appointmentDetails, isLocationView, successCallback, errorCallback)
            }
            else {
                this.CreateTempHoldAppointment(appointmentDetails, successCallback, errorCallback);
            }
        }
    }

    async CreateAppointmentTempHold(appointmentDetails: appointment) {
        let uriParams = { istherapistoverbook: false, islocationoverbook: false };
        let result = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.schedule,
            callDesc: "CreateTempHoldAppointment",
            uriParams: uriParams,
            method: HttpMethod.Post,
            body: appointmentDetails
        });
        let newTempApt = _.cloneDeep(this.tempHoldObj);
        newTempApt.appointmentId = <any>result.result[0];
        this.apptService.TempHoldArr.push(newTempApt);
    }

    //Update the temphold values incase of dropdown changes
    async UpdateTempHoldObject(tempHoldValuesArr: TempHoldApp[]) {

        if (!tempHoldValuesArr || tempHoldValuesArr.length == 0 || tempHoldValuesArr.some(r => !r)) {
            return;
        }



        let apptArr: appointment[] = [];
        for (let index = 0; index < tempHoldValuesArr.length; index++) {
            const element = tempHoldValuesArr[index];

            const tempStartTime = element.startTime ? element.startTime : '';
            let apptDetail: appointmentDetail = {
                startTime: tempStartTime,
                endTime: element.endTime ? element.endTime : tempStartTime,
                originalStartTime: tempStartTime,
                originalEndTime: element.endTime ? element.endTime : tempStartTime,
                serviceGroupId: element.serviceGroupId,
                clientId: element.clientId,
                serviceId: element.service ? element.service : 0,
                locationId: element.location ? element.location : 0,
                comments: element.comments ? element.comments : "",
                status: AppointmentStatus.TEMP,
                cancelId: "",
                duration: element.duration ? element.duration : 0,
                setUpTime: element.setUpTime ? element.setUpTime : 0,
                breakDownTime: element.breakDownTime ? element.breakDownTime : 0,
                price: element.price ? this.localization.currencyToSQLFormat(element.price) : 0,
                doNotMove: element.doNotMove ? element.doNotMove : false,
                requestStaff: element.requestStaff ? element.requestStaff : false,
                isBookingLocked: element.isBookingLocked ? element.isBookingLocked : false,
                isVip: element.isVip ? element.isVip : false,
                genderPreference: element.genderPreference ? element.genderPreference : 0,
                checkOutComments: element.checkOutComments ? element.checkOutComments : "",
                noShowComments: element.noShowComments ? element.noShowComments : "",
                noShow: element.noShow ? element.noShow : false,
                packageId: element.packageId ? element.packageId : 0,
                appointmentType: "",
                cancelReason: 0,
                cancelComments: "",
                cancelFee: 0,
                customField1: 0,
                customField2: 0,
                customField3: 0,
                customField4: "0",
                customField5: "0",
                tempHoldId: "",
                tempHoldLinkId: element.TempHoldLinkId,
                id: element.appointmentId,
                isTherapistOverbook: false,
                isLocationOverbook: false,
                clientComments: "",
                ServiceCharge: element.ServiceCharge,
                Gratuity: element.Gratuity,
                TotalAmount: element.TotalAmount,
                yieldPrice: 0,
                intakeForm: false,
                guestTypeId: element.guestTypeId ? element.guestTypeId:0,
                discountType: ""
            }

            let apptTherapist: appointmentTherapist[] = [];
            for (let j = 0; j < element.therapist.length; j++) {
                const therapistObj = element.therapist[j];
                let therapist: appointmentTherapist = {
                    therapistId: therapistObj,
                    appointmentId: element.appointmentId
                }
                apptTherapist.push(therapist);

            }
            let apptPmsActivityId = this.apptService.pmsActivityId?this.apptService.pmsActivityId:null;
            let externalPMSActivityId = this.apptService.externalPMSConfNo?this.apptService.externalPMSConfNo:null;
            let apptAddon: any = [];
            let appointmentObj: appointment = {
                appointmentDetail: apptDetail,
                appointmentTherapists: apptTherapist,
                appointmentAddOn: apptAddon,
                pMSActivityId: apptPmsActivityId,
                externalPMSConfNo:externalPMSActivityId
            }
            apptArr.push(appointmentObj);
        }

        //invoke DB and send an array of updated objects.
        await this.UpdateTempHoldAppointment(apptArr);

    }

    deleteTempHold(tempHoldId: any, successCallback?: (callDesc: string, result: any) => void,
        errorCallback?: (callDesc: string, result: any) => void) {

        if (tempHoldId.length > 0) {
            let tempHoldIds: number[] = tempHoldId.map(x => x.appointmentId);

            this.http.CallApiWithCallback({
                host: GlobalConst.Host.schedule,
                success: successCallback ? successCallback : this.successCallback.bind(this),
                error: errorCallback ? errorCallback : this.errorCallback.bind(this),
                callDesc: "DeleteTempHold",
                method: HttpMethod.Delete,
                body: tempHoldIds,
                showError: false,
                extraParams: [tempHoldIds.join(",")]
            });
        }
    }

    insertTransLogforGenderPreferenceOverride(genderOverrideTransLog: GenderOverrideTransLog, successCallback?: (callDesc: string, result: any) => void,
        errorCallback?: (callDesc: string, result: any) => void) {

        if (genderOverrideTransLog.appointmentIds.length > 0) {


            this.http.CallApiWithCallback({
                host: GlobalConst.Host.schedule,
                success: successCallback ? successCallback : this.successCallback.bind(this),
                error: errorCallback ? errorCallback : this.errorCallback.bind(this),
                callDesc: "GenderOverrideTransLog",
                method: HttpMethod.Put,
                body: genderOverrideTransLog,
                showError: false,
                extraParams: []
            });
        }
    }

    CreateTransLogForAppointment(typeId : number,logType : string, TransLogEntry: LogEntry, successCallback?: (callDesc: string, result: any) => void,
        errorCallback?: (callDesc: string, result: any) => void) {
        if (TransLogEntry) {
            this.http.CallApiWithCallback({
                host: GlobalConst.Host.schedule,
                success: successCallback ? successCallback : this.successCallback.bind(this),
                error: errorCallback ? errorCallback : this.errorCallback.bind(this),
                callDesc: "CreateAppointmentTransLog",
                method: HttpMethod.Post,
                body: TransLogEntry,
                uriParams: { typeId: typeId,logType: logType },
                showError: false,
                extraParams: []
            });
        }
    }

    GetServiceDescById(id: number): string {
        let serviceName: string = '';
        let serviceList: any[] = this._appService.managementData["Service"];
        const tempServiceList = (this.apptService && this.apptService.serviceArray
            && this.apptService.serviceArray.length > 0 ? this.apptService.serviceArray : null);
        serviceList = serviceList != null ? serviceList : tempServiceList;
        if (serviceList) {
            let service: any = serviceList.filter(res => { return res.id == id })[0];
            if (service) {
                serviceName = service.description;
            }
        }
        return serviceName;
    }

    GetServiceRetailItemId(id: number): number {
        let retailItemId: number = 0;
        let serviceList: any[] = this._appService.managementData["Service"];
        if (serviceList) {
            let service: any = serviceList.filter(res => { return res.id == id })[0];
            if (service) {
                retailItemId = service.retailItemId;
            }
        }
        return retailItemId;
    }

    GetAddOnsRetailItemId(id: number): number {
        let retailItemId: number = 0;
        let addOnsList: any[] = this._appService.managementData["Addons"];
        if (addOnsList) {
            let addOn: any = addOnsList.filter(res => { return res.id == id })[0];
            if (addOn) {
                retailItemId = addOn.retailItemId;
            }
        }
        return retailItemId;
    }

    GetTherapistNameById(id: number): string {
        let therapistName: string = '';
        let therapistList: any[] = this._appService.managementData["Therapist"];
        let activeTherapists: any[]=this._appService.managementData["ActiveTherapists"];
        if (therapistList) {
            let therapist: any = therapistList.filter(res => { return res.id == id })[0];
            if (therapist) {
                therapistName = therapist.firstName + " " + therapist.lastName;
            }
            else{
               let therapist: any = activeTherapists?.filter(res =>{ return res.id == id})[0];
               if(therapist){
                therapistName = therapist.firstName + " " + therapist.lastName;
               }
            }
        }
        return therapistName;
    }

    GetLocationDescById(id: number): string {
        let locationName: string = '';
        let locationList: any[] = this._appService.managementData["Location"];
        if (locationList) {
            let service: any = locationList.filter(res => { return res.id == id })[0];
            if (service) {
                locationName = service.description;
            }
            else {
                locationName = this.localization.captions.setting.Offsite;
            }
        }
        return locationName;
    }

    async CreateTempholdForClientSelected(clientId: number) {

        //TEMP HOLD CHANGES
        if (this.apptService.resultExistingAppointment && Object.keys(this.apptService.resultExistingAppointment).length > 0) // Edit appointment
        {
            let tempHoldIndex = this.apptService.TempHoldArr.findIndex(function (tempobj) {
                return tempobj.originalClientId == clientId;
            });
            if (tempHoldIndex != -1) {
                this.apptService.TempHoldArr[tempHoldIndex].clientId = clientId
                let tempArr: TempHoldApp[] = [];
                tempArr.push(this.apptService.TempHoldArr[tempHoldIndex]);
                this.UpdateTempHoldObject(tempArr);
            }
            else {
                this.apptService.tempHoldValues = _.cloneDeep(this.apptService.TempHoldArr[0]);
                this.apptService.tempHoldValues.clientId = clientId;
                await this.CreateTempHoldObject(this.apptService.tempHoldValues);
            }
        }
        else if (this.apptService.resultNewAppointment && Object.keys(this.apptService.resultNewAppointment).length > 0) //New appointment
        {
            if (this.apptService.multiClientInfo.length == 1) {
                if ((this.apptService.TempHoldArr == null || this.apptService.TempHoldArr.length == 0) && !this._appService.hasActiveTempHold) {
                    //craete new temp hold //incase user creates client first
                    this.apptService.tempHoldValues.clientId = clientId;
                    await this.CreateTempHoldObject(this.apptService.tempHoldValues);

                }
                if (this.apptService.TempHoldArr.length == 1) {
                    //update the client id //user selected few values and navigates to client
                    this.apptService.TempHoldArr[0].clientId = clientId;
                    let tempArr: TempHoldApp[] = [];
                    tempArr.push(this.apptService.TempHoldArr[0]);
                    this.UpdateTempHoldObject(tempArr);

                }
            }
            if (this.apptService.multiClientInfo.length > 1) {
                //craete new temp holdrecord
                //this.apptService.tempHoldValues.clientId = clientId;
                //await this.CreateTempHoldObject(this.apptService.tempHoldValues);
                 let tempHold = this.apptService.TempHoldArr.filter(x=> x.clientId == clientId)
                 if(tempHold.length == 0){
                    this.apptService.tempHoldValues.clientId = clientId;
                    await this.CreateTempHoldObject(this.apptService.tempHoldValues);
                 }
                else{
                  tempHold[0].therapist = [];
                  this.apptService.multiClientInfo.forEach(clientinfo=>{
                    if(clientinfo.id == this.apptService.clientId)
                        tempHold[0].therapist.push(clientinfo.TherapistId);
                  });
                   this.UpdateTempHoldObject(tempHold);
                }
            }

        }
        let dt: Date =
            typeof this.apptService.resultNewAppointment.dateField == "string"
                ? this.utils.getDate(this.apptService.resultNewAppointment.dateField)
                : this.apptService.resultNewAppointment.dateField;
        if (dt && dt.getTime() && this.apptService.resultNewAppointment.time) {
            let startTime = this.utils.convertDateFormat(this.utils.getDate(
                dt.getFullYear() +
                "-" +
                this.utils.getFullMonth(dt) +
                "-" +
                this.utils.getFullDate(dt) +
                "T" +
                this.localization.DeLocalizeTime(this.apptService.resultNewAppointment.time)
            )
            );
            let guestId = this.apptService.clientGuestMap.filter(x => x.ClientId == clientId).map(y => y.GuestId)[0];
            if (startTime && guestId != GlobalConst.DefaultGUID) {
                this.http.CallApiWithCallback({
                    host: GlobalConst.Host.schedule,
                    success: this.successCallback.bind(this),
                    error: this.errorCallback.bind(this),
                    callDesc: "GetItineraryForAppointment",
                    method: HttpMethod.Get,
                    uriParams: { clientId: guestId, dateTime: startTime },
                    showError: false,
                    extraParams: []
                });
            }
        }
    }

    GetDefaultAppointmentDetailObject(): appointmentDetail {
        return {
            startTime: '',
            endTime: '',
            originalStartTime: '',
            originalEndTime: '',
            serviceGroupId: 0,
            clientId: 0,
            serviceId: 0,
            locationId: 0,
            comments: "",
            status: AppointmentStatus.TEMP,
            cancelId: "",
            duration: 0,
            setUpTime: 0,
            breakDownTime: 0,
            price: 0,
            doNotMove: false,
            requestStaff: false,
            isBookingLocked: false,
            isVip: false,
            genderPreference: 0,
            checkOutComments: "",
            noShowComments:"",
            noShow: false,
            packageId: 0,
            appointmentType: "",
            cancelReason: 0,
            cancelComments: "",
            cancelFee: 0,
            customField1: 0,
            customField2: 0,
            customField3: 0,
            customField4: "0",
            customField5: "0",
            tempHoldId: "",
            tempHoldLinkId: 0,
            isTherapistOverbook: false,
            isLocationOverbook: false,
            clientComments: "",
            ServiceCharge: 0,
            Gratuity: 0,
            TotalAmount: 0,
            yieldPrice: 0,
            intakeForm: false,
            guestTypeId:0,
            discountType:""
        }
    }

    /**
     * Validate Overbooking Rule
     * @param errArr - List of error codes
     * @param isLocationView - location view
     * @param isTherapistView - therapist view
     */
    ValidateOverBookingRule(errArr: number[], isLocationView: boolean, isTherapistView: boolean): OverBookOptions {
        let errArrForValidation: number[] = errArr ? _.cloneDeep(errArr) : [];
        let response: OverBookOptions = {
            isClientOverbook: false,
            isLocationOverbook: false,
            isTherapistOverbook: false,
            canOverbook: false
        };
        // Remove AddOn for validation
        let index = errArrForValidation.indexOf(GlobalConst.ErrorConstants.TherapistNotCertifiedForAddOn);
        if (index > -1) {
            errArrForValidation.splice(index, 1);
        }
        // Allowing only (client & Therapist) or (Client & Location) Overbook
        if (errArrForValidation.some(r => r == GlobalConst.ErrorConstants.ClientCannotBeOverBooked ||
            r == GlobalConst.ErrorConstants.LocationUnderMaintenance ||
            r == GlobalConst.ErrorConstants.TherapistIsOnCall)) {
            errArrForValidation = errArrForValidation.filter(r => r != GlobalConst.ErrorConstants.ClientCanBeOverBooked);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getErrorText(errArrForValidation), GlobalConst.ButtonType.Ok);
            return response;
        }

        let overbookErrorCodes: number[] = [GlobalConst.ErrorConstants.ClientCanBeOverBooked,
        GlobalConst.ErrorConstants.LocationAlreadyBlocked,
        GlobalConst.ErrorConstants.TherpistAlreadyBlocked,
        GlobalConst.ErrorConstants.TherapistNotScheduled];

        let therapistOverbookErrors: number[] = [GlobalConst.ErrorConstants.TherpistAlreadyBlocked, GlobalConst.ErrorConstants.TherapistNotScheduled];

        response.isClientOverbook = errArrForValidation.some(r => { return r == GlobalConst.ErrorConstants.ClientCanBeOverBooked });
        if (errArrForValidation.some(x => { return overbookErrorCodes.includes(x) })) {
            // Check only client or Location or Therapist Overbook
            if (errArrForValidation.length == 1) {
                response.isLocationOverbook = isLocationView && (errArrForValidation[0] == GlobalConst.ErrorConstants.LocationAlreadyBlocked);
                response.isTherapistOverbook = isTherapistView && therapistOverbookErrors.some(r => { return r == errArrForValidation[0] });
            }
            else if (response.isClientOverbook && (errArrForValidation.some(r => { return (r == GlobalConst.ErrorConstants.TherpistAlreadyBlocked || r == GlobalConst.ErrorConstants.TherapistNotScheduled || r == GlobalConst.ErrorConstants.LocationAlreadyBlocked) }))) {
                response.isLocationOverbook = isLocationView && errArrForValidation.some(r => { return (r == GlobalConst.ErrorConstants.LocationAlreadyBlocked) }) && !errArrForValidation.some(r => { return (r == GlobalConst.ErrorConstants.TherapistNotScheduled || r == GlobalConst.ErrorConstants.TherpistAlreadyBlocked) });
                response.isTherapistOverbook = isTherapistView && errArrForValidation.some(r => { return (r == GlobalConst.ErrorConstants.TherpistAlreadyBlocked || r == GlobalConst.ErrorConstants.TherapistNotScheduled) }) && !errArrForValidation.some(r => { return (r == GlobalConst.ErrorConstants.LocationAlreadyBlocked) });
            }
        }
        if (response.isTherapistOverbook || response.isLocationOverbook) {
            response.canOverbook = true;
        }
        else if (response.isClientOverbook && errArrForValidation.length == 1) {
            response.canOverbook = true;
        }
        if (!response.canOverbook && errArrForValidation.length > 0) {
            errArrForValidation = errArrForValidation.filter(r => r != GlobalConst.ErrorConstants.ClientCanBeOverBooked);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getErrorText(errArrForValidation), GlobalConst.ButtonType.Ok);
        }
        return response;
    }

    IsUserAuthorizedForOverBook(overBookOptions: OverBookOptions, isLocationView: boolean): boolean {
        // Client Overbook doesn't have breakpoint access
        if (overBookOptions.isClientOverbook && !overBookOptions.isTherapistOverbook && !overBookOptions.isLocationOverbook) {
            return true;
        }
        let isAuthorized: boolean = false;
        let breakPointNumber: number[] = [];
        if (isLocationView) {
            breakPointNumber.push(GlobalConst.SPAScheduleBreakPoint.OverbookLocation);
        }
        else {
            breakPointNumber.push(GlobalConst.SPAScheduleBreakPoint.OverbookTherapist);
        }
        isAuthorized = this.breakPoint.CheckForAccess(breakPointNumber);
        return isAuthorized
    }

    async MakeAsyncCall(callDesc: string, host: GlobalConst.Host, method: HttpMethod, uriParams: any): Promise<any> {
        try {
            return await this.http.CallApiAsync({
                callDesc: callDesc,
                host: host,
                method: method,
                uriParams: uriParams
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    async getPackageAppointmentValidationsForMove(id: number, guid: string): Promise<PackageMoveValidators> {
        let result: BaseResponse<PackageMoveValidators> = await this.MakeAsyncCall("PackageMoveValidations", GlobalConst.Host.schedule, HttpMethod.Get, { id: id, guid: guid });
        return result.result;
    }

    async UpdatePackageYield(appt: appointment, newDate: Date): Promise<void> {
        let uriParams = {
            packageId: appt.appointmentDetail.packageId,
            packageGroupId: appt.appointmentDetail.packageGroupId,
            fromDate: this.utils.formatDate(appt.appointmentDetail.startTime),
            toDate: this.utils.formatDate(newDate)
        };
        let result = await this.MakeAsyncCall("UpdatePackageYieldOnMove", GlobalConst.Host.spaManagement, HttpMethod.Put, uriParams);
        console.log(result);
    }

    deleteTempOnUnload() {
        if (this._appService.isAppointmentFromPackage) {
            if (this._appService.PackageTempHoldIdList && this._appService.PackageTempHoldIdList.length > 0) {
                let array = [];
                this._appService.PackageTempHoldIdList.forEach(element => {
                    array.push({ appointmentId: element })
                });
                this.deleteTempHold(array, this.successCallback.bind(this), this.errorCallback.bind(this));
            }
        }
        else {
            if (this.apptService.TempHoldArr && this.apptService.TempHoldArr.length > 0) {
                let tempHoldIds = this.apptService.TempHoldArr.filter(function (tempObj) { return tempObj.appointmentId });
                if (tempHoldIds.length > 0) {
                    this.deleteTempHold(tempHoldIds, this.successCallback.bind(this), this.errorCallback.bind(this));
                }
            }
        }
    }

    formAppointmentObject(result: any): appointment {
        let appObj: appointment;
        let appDetail: appointmentDetail;
        let appTherap: appointmentTherapist[] = [];

        appDetail = {
            id: result[0].appointmentDetail.id,
            breakDownTime: result[0].appointmentDetail.breakDownTime,
            cancelComments: result[0].appointmentDetail.cancelComments,
            cancelFee: result[0].appointmentDetail.cancelFee,
            cancelReason: result[0].appointmentDetail.cancelReason,
            checkOutComments: result[0].appointmentDetail.checkOutComments,
            noShowComments:result[0].appointmentDetail.noShowComments,
            clientId: result[0].appointmentDetail.clientId,
            comments: result[0].appointmentDetail.comments,
            doNotMove: result[0].appointmentDetail.doNotMove,
            duration: result[0].appointmentDetail.duration,
            endTime: result[0].appointmentDetail.endTime,
            originalEndTime: result[0].appointmentDetail.endTime,
            genderPreference: result[0].appointmentDetail.genderPreference,
            isVip: result[0].appointmentDetail.isVip,
            locationId: result[0].appointmentDetail.locationId,
            noShow: result[0].appointmentDetail.noShow,
            price: result[0].appointmentDetail.price,
            requestStaff: result[0].appointmentDetail.requestStaff,
            isBookingLocked: result[0].appointmentDetail.isBookingLocked,
            serviceGroupId: result[0].appointmentDetail.serviceGroupId,
            serviceId: result[0].appointmentDetail.serviceId,
            setUpTime: result[0].appointmentDetail.setUpTime,
            startTime: result[0].appointmentDetail.startTime,
            originalStartTime: result[0].appointmentDetail.startTime,
            status: result[0].appointmentDetail.status,
            cancelId: result[0].appointmentDetail.cancelId,
            appointmentType: result[0].appointmentDetail.appointmentType,
            packageId: result[0].appointmentDetail.packageId,
            packageGroupId: result[0].appointmentDetail.packageGroupId,
            customField1: result[0].appointmentDetail.customField1,
            customField2: result[0].appointmentDetail.customField2,
            customField3: result[0].appointmentDetail.customField3,
            customField4: result[0].appointmentDetail.customField4,
            customField5: result[0].appointmentDetail.customField5,
            tempHoldId: '',
            tempHoldLinkId: 0,
            isTherapistOverbook: result[0].appointmentDetail.isTherapistOverbook,
            isLocationOverbook: result[0].appointmentDetail.isLocationOverbook,
            linkCodeId: result[0].appointmentDetail.linkCodeId,
            clientComments: result[0].appointmentDetail.clientComments,
            activityId: result[0].appointmentDetail.activityId,
            clientMultiPackId: result[0].appointmentDetail.clientMultiPackId,
            priceTypeId: result[0].appointmentDetail.priceTypeId,
            serviceChargePercent: result[0].appointmentDetail.serviceChargePercent,
            gratuityPercent: result[0].appointmentDetail.gratuityPercent,
            isClientOverbook: result[0].appointmentDetail.isClientOverbook,
            yieldPrice: result[0].appointmentDetail.yieldPrice,
            intakeForm: result[0].appointmentDetail.intakeForm,
            linkedAppointmentID: result[0].appointmentDetail.linkedAppointmentID,
            guestTypeId: result[0].appointmentDetail.guestTypeId,
            discountType: result[0].appointmentDetail.discountType?result[0].appointmentDetail.discountType:""
        }
        appTherap = this.formAppointmentTherapistObject(result);
        let apptPmsActivityId = this.apptService.pmsActivityId;
        let externalPMSActivityId=this.apptService.externalPMSConfNo;

        appObj = {
            appointmentDetail: appDetail,
            appointmentTherapists: appTherap,
            appointmentAddOn: [],
            pMSActivityId: apptPmsActivityId,
            appointmentBillingDetail: result[0].appointmentBillingDetail,
            externalPMSConfNo:externalPMSActivityId
        }

        return appObj;

    }

    formAppointmentTherapistObject(result: any): appointmentTherapist[] {
        let appTher: appointmentTherapist;
        let appTherArr: appointmentTherapist[] = [];
        for (let item of result) {
            for (const therapist of item.appointmentTherapists) {
                appTher = {
                    therapistId: therapist.therapistId,
                    appointmentId: therapist.appointmentId
                }
                appTherArr.push(appTher);
            }
        }
        return appTherArr;
    }

    formTempHoldObjects(result: any): TempHoldApp[] {

        let tempholdAppArray: TempHoldApp[] = [];
        result.forEach(appt => { tempholdAppArray.push(this.formTempHoldObject(appt)) });
        return tempholdAppArray;
    }

    formTempHoldObject(result: any): TempHoldApp {
        let tempholdApp: TempHoldApp;
        let therapistArr: any = [];
        for (const therapist of result.appointmentTherapists) {
            therapistArr.push(therapist.therapistId);
        }
        tempholdApp = {
            TempHoldLinkId: result.appointmentDetail.tempHoldLinkId,
            multiGroupId: result.appointmentDetail.multiGroupId,
            originalClientId: result.appointmentDetail.clientId,
            appointmentId: result.appointmentDetail.id,
            breakDownTime: result.appointmentDetail.breakDownTime,
            checkOutComments: result.appointmentDetail.checkOutComments,
            noShowComments:result.appointmentDetail.noShowComments,
            clientId: result.appointmentDetail.clientId,
            comments: result.appointmentDetail.comments,
            doNotMove: result.appointmentDetail.doNotMove,
            duration: result.appointmentDetail.duration,
            endTime: result.appointmentDetail.endTime,
            genderPreference: result.appointmentDetail.genderPreference,
            isVip: result.appointmentDetail.isVip,
            location: result.appointmentDetail.locationId,
            noShow: result.appointmentDetail.noShow,
            price: result.appointmentDetail.price,
            requestStaff: result.appointmentDetail.requestStaff,
            isBookingLocked: result.appointmentDetail.isBookingLocked,
            serviceGroupId: result.appointmentDetail.serviceGroupId,
            service: result.appointmentDetail.serviceId,
            setUpTime: result.appointmentDetail.setUpTime,
            startTime: result.appointmentDetail.startTime,
            status: "TEMP",
            cancelId: result.appointmentDetail.cancelId,
            packageId: 0,
            TempHoldId: '',
            therapist: therapistArr,
            appointmentDate: '',
            guestTypeId:result.appointmentDetail.guestTypeId,
            discountType: result.appointmentDetail.discountType?result.appointmentDetail.discountType:""
        }


        return tempholdApp;
    }

    public async CheckMedicalWarning(serviceId: number, clientIds: any[], successcallback, errorcallback) {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.spaManagement,
            success: successcallback,
            error: errorcallback,
            callDesc: "GetMedicalWarning",
            method: HttpMethod.Put,
            showError: true,
            uriParams: { id: serviceId },
            body: clientIds,
            extraParams: []
        });
    }
    /**
    * @description Get medical warning popup based on the medical conditions.
    * @function GetMedicalWarning
    * @param medicalWarningList
    * @param viewContainerRef
    * @param source
    * @param clientDetails
    * @param callBack
    */
    public GetMedicalWarning(medicalWarningList: ServiceClientMedicalWarning, viewContainerRef: ViewContainerRef, source, clientDetails?: any, callBack?: any) {
        let clientMedicalCondition = [];
        let medicalWarning: any[] = <any>medicalWarningList;
        if (medicalWarning != null && medicalWarning.length > 0) {
            if (source === 'package' || source === 'changeservice') {
                medicalWarning.forEach(warning => {
                    const tempClientNameValue = (source === 'changeservice' ? clientDetails.clientName : clientDetails.firstName + ' ' + clientDetails.lastName);
                    let clientName: string = clientDetails != null ? tempClientNameValue : '';
                    warning.medicalConditionWarning.forEach(e => {
                        if (e.medicalConditionMatched) {
                            clientMedicalCondition.push({
                                "id": e.MedicalConditionCode,
                                "name": e.medicalDescription,
                                "description": e.medicalWarning,
                                "isMatching": true
                            })
                        }
                        else {
                            clientMedicalCondition.push({
                                "id": e.MedicalConditionCode,
                                "name": e.medicalDescription,
                                "description": e.medicalWarning,
                                "isMatching": false
                            })
                        }

                    });
                    if (clientMedicalCondition != null && clientMedicalCondition.filter(x => x.isMatching).length > 0) {
                        this.openMedicalWarning(clientMedicalCondition, false, viewContainerRef, clientName, warning.clientId,
                            this.GetServiceDescById(warning.serviceId), callBack.bind(this));
                    }
                    else {
                        this.openMedicalWarning(clientMedicalCondition, true, viewContainerRef, '', null, null, callBack.bind(this));
                    }
                });
            }
            else if (source === 'appointment' || source === 'client') {
                if (medicalWarning.filter(x => x.clientId).length > 0) {
                    medicalWarning.forEach(warning => {
                        clientMedicalCondition = [];
                        let clientName: string = '';
                        if (clientDetails != null && clientDetails != "") {
                            clientName = clientDetails.FirstName + ' ' + clientDetails.LastName;
                        }
                        else {
                            clientName = this.apptService != null && this.apptService.recordsArray != null && this.apptService.recordsArray.length > 0 ?
                                this.apptService.recordsArray.find(x => x.id === warning.clientId).clientDetail.firstName + ' ' +
                                this.apptService.recordsArray.find(x => x.id === warning.clientId).clientDetail.lastName : '';
                        }

                        warning.medicalConditionWarning.forEach(e => {
                            if (e.medicalConditionMatched) {
                                clientMedicalCondition.push({
                                    "id": e.MedicalConditionCode,
                                    "name": e.medicalDescription,
                                    "description": e.medicalWarning
                                })
                            }
                        });
                        this.openMedicalWarning(clientMedicalCondition, false, viewContainerRef, clientName, warning.clientId, this.GetServiceDescById(warning.serviceId), callBack.bind(this));
                    });
                }
                else if (medicalWarning.filter(x => x.medicalConditionWarning.length > 0).length > 0 && source === 'appointment') {
                    medicalWarning.forEach(warning => {
                        warning.medicalConditionWarning.forEach(e => {
                            clientMedicalCondition.push({
                                "id": e.MedicalConditionCode,
                                "name": e.medicalDescription,
                                "description": e.medicalWarning
                            })
                        });
                        this.openMedicalWarning(clientMedicalCondition, true, viewContainerRef, '', null, null, callBack.bind(this));
                    });
                }
            }
        }
    }

    /**
    * @description opens client medical warings as an alert.
    * @function openMedicalWarning
    * @param medicalWarnings
    * @param medicalConditionMatched
    * @param viewContainerRef
    * @param clientName
    * @param clientId
    * @param ServiceName
    * @param callBack
    */
    public openMedicalWarning(medicalWarnings: any[], isMedicalInfo: boolean, viewContainerRef: ViewContainerRef, clientName?: string, clientId?: number, serviceName?: string, callBack?: any) {
        let panel;
        if (viewContainerRef) {
            let nativeElement = viewContainerRef.element.nativeElement;
            panel = nativeElement.closest('.small-popup');
            if (panel) {
                panel.classList.add('d-none');
            }
        }
        let data;
        const tempHeaderName = clientName != '' ? clientName + `'s` + ' ' + this.localization.captions.bookAppointment.MedicalWarning
            : this.localization.captions.bookAppointment.MedicalWarning;
        data = {
            headername: isMedicalInfo ? this.localization.captions.bookAppointment.MedicalWarning : tempHeaderName,
            okbuttonName: this.localization.captions.common.OK,
            buttonName: this.localization.captions.common.Yes,
            noButtonName: this.localization.captions.common.No,
            message: isMedicalInfo ? this.localization.captions.bookAppointment.MedicalCondition : this.localization.replacePlaceholders
                (this.localization.captions.bookAppointment.ClientMedicalWarningPrompt, ["ServiceName"], [serviceName]),
            isInfo: isMedicalInfo,
            warnings: medicalWarnings
        }
        const dialogRef = this.dialog.open(MedicalWarningDialogComponent, {
            height: 'auto',
            width: '600px',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data: data,
            disableClose: true
        });
        dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
            if (panel) {
                panel.classList.remove('d-none');
            }
            let popupObj = {
                popupCloseInfo: result,
                clientId: clientId
            }
            if (callBack) {
                callBack(popupObj);
            }
        })
    }

    public async GetAppointmentPrice(service: ServiceViewModel, appointmentDate: Date): Promise<AppointmentPrice> {
        let price: AppointmentPrice;

        if (!service) return price;

        let serviceAggregate = await this._appService.GetServiceAggregate([service.id]);
        let serviceInfo = serviceAggregate.find(r => r.serviceDetail.id == service.id);
        let serviceActualPrice = this.utils.GetServiceBasePrice(serviceInfo.serviceDetail, serviceInfo.serviceSeasonalDates, appointmentDate);
        let yieldPrice = await this._appService.CalculateYieldPrice(serviceActualPrice, service.id, this.localization.convertDateObjToAPIdate(appointmentDate))

        let serviceCharge: number = 0;
        let gratuity: number = 0;
        let totalAmount: number = 0;

        const tempServiceValue = (service.serviceChargePercent > 0 ? (yieldPrice * service.serviceChargePercent / 100) : service.serviceChargeAmount);
        serviceCharge = service.isAutoServiceCharge ? tempServiceValue : 0;
        const tempGratuityValue = (service.gratuityPercent > 0 ? (yieldPrice * service.gratuityPercent / 100) : service.gratuityAmount);
        gratuity = service.isAutoGratuity ? tempGratuityValue : 0;
        totalAmount = yieldPrice + serviceCharge + gratuity;

        price = {
            price: yieldPrice,
            serviceCharge: serviceCharge,
            gratuity: gratuity,
            totalAmount: totalAmount,
            serviceChargePercent: service.serviceChargePercent,
            gratuityPercent: service.gratuityPercent,
            yieldPrice: yieldPrice
        };
        return price;
    }

    public async getPriceDetailsForAction(service: ServiceDetail, servicePrice: number, linkMultipack: boolean, availableMultiPack: clientMultiPack): Promise<AppointmentPrice> {
        service = _.cloneDeep(service);
        if (linkMultipack) {
            servicePrice = availableMultiPack.salePrice
            service.retailItemId = availableMultiPack.retailItemId;
            service.isAutoGratuity = availableMultiPack.gratuity > 0;
            service.gratuityPercent = service.isAutoGratuity ? this.utils.GetPercentageFromPercentageAmount(servicePrice, availableMultiPack.gratuity) : 0;
            service.isAutoServiceCharge = availableMultiPack.serviceCharge > 0;
            service.serviceChargePercent = service.isAutoServiceCharge ? this.utils.GetPercentageFromPercentageAmount(servicePrice, availableMultiPack.serviceCharge) : 0;
        }
        let priceModel = await this.apptService.getPriceForServiceChargeAndGratuity(servicePrice, service);
        let serviceCharge = this.apptService.GetServiceChargeGratuityValue(service, priceModel.priceForServiceCharge, GlobalConst.SERVICECHARGE);
        let gratuity = this.apptService.GetServiceChargeGratuityValue(service, priceModel.priceForGratuity, GlobalConst.GRATUITY);
        return {
            price: servicePrice,
            serviceCharge: serviceCharge,
            gratuity: gratuity,
            totalAmount: servicePrice + serviceCharge + gratuity,
            serviceChargePercent: service.serviceChargePercent,
            gratuityPercent: service.gratuityPercent
        }
    }
   
    /**
     * open deposit info dialog on update service and if deposit paid
     * @param depositData -- {existingServiceAmount,tempServiceAmount,depositAmount,remainingAmount}
     * @param callback -- ConfirmAppointment() 
     * @param extraParams -- {durationOverBook, overbookOptions, isAddToCart}
     * @returns -- dialog ref
     */
    public showDepositInfoDialog(
        depositData: any,
        callback?: (durationOverBook?: boolean, overbookOptions?: any, isAddToCart?: boolean) => void,
        extraParams?: any[]
    ) {
        const dialogRef = this.dialog.open(DepositInfoDialogComponent, {
          height: "auto",
          width: depositData?.guestDetails.length > 1 ? "650px" : "500px",
          data: depositData,
          panelClass: "small-popup",
          disableClose: true,
          hasBackdrop: true,
        });

        this.subscription = dialogRef.afterClosed().subscribe((res) => {
          if (callback) {
            callback(extraParams[0].durationOverBook,extraParams[0].overbookOptions,extraParams[0].isAddToCart);
          }
        });
        return dialogRef;
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        this.subscription.unsubscribe();
    }
}
