import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import * as _ from 'lodash';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailFeatureFlagInformationService } from "src/app/retail/shared/service/retail.feature.flag.information.service";
export class InventoryDetails extends BaseReport {
    subCategoryFilter: string;
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService,private func: RetailFunctionalityBusiness,
        public propertyInfo: RetailPropertyInformation,private featureFlag: RetailFeatureFlagInformationService) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.InventoryDetails,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return{
            startDatePicker: false,
            endDatePicker: false,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: "PORTRAIT"
        }; 
    }

    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let inventoryDetailCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let radioFilterId: number = this.reportFormGrp.controls['customFilterFormGrp'].value.itemFilter;

        let categoryFilter: string[] = this.business.getSelectedCategoryCaptions(inventoryDetailCustomFilterData);
        let subCategoryFilter = this.business.fillSubCategoryFilterCaptions(inventoryDetailCustomFilterData, this.propertyCaptions.All)

        return [{ "isSuppressZeroQuantity": radioFilterId === 1 ? "true" : "false" },
        { "isLastCostMethod": inventoryDetailCustomFilterData.lostCostMethod },
        { "pPageBreak": this.pageBreakToggle },
        { "pSuppressZeroQty": radioFilterId === 1 ? "true" : "false" },
        { "pCategoryOnly": inventoryDetailCustomFilterData.categoryView},
        { "pLastCostMethod": inventoryDetailCustomFilterData.lostCostMethod },
        { "pDisposableItems": inventoryDetailCustomFilterData.DisposableItems },
        { "pCategoryFilter": categoryFilter[0] },
        { "pSubCategory1": categoryFilter[1] },
        { "pSubCategory2": categoryFilter[2] },
        { "pSubCategory3": categoryFilter[3] },
        { "pSubCategory4": categoryFilter[4] },
        { "pSubCategory5": categoryFilter[5] },
        { "pPropertyName": this.propertyName },
        { "pDate" : this.printedDate },
        { "pOutletFilter": this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ? this.getFilterCaption : this.getDefaultOutletCaption},
        {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ? true : false },
        {"pIsEatecEnabled":this.featureFlag.IsEatecEnabled==true ? "true" : "false"} ];
    }

    protected formURIParams(): ReportParams[] {
        return [];
    }

    protected formFilters() {

        let inventoryDetailCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        return {
            categoryIds: this.getCategoriesAndSubCategories(inventoryDetailCustomFilterData, 0),
            subCategory1Ids: this.getCategoriesAndSubCategories(inventoryDetailCustomFilterData, 1),
            subCategory2Ids: this.getCategoriesAndSubCategories(inventoryDetailCustomFilterData, 2),
            subCategory3Ids: this.getCategoriesAndSubCategories(inventoryDetailCustomFilterData, 3),
            subCategory4Ids: this.getCategoriesAndSubCategories(inventoryDetailCustomFilterData, 4),
            subCategory5Ids: this.getCategoriesAndSubCategories(inventoryDetailCustomFilterData, 5),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInInventoryDetailsReport ? this.selectedFilterOptions.map(x => x.id) :
                tempOutFalse,
            useInventory: true
        };
    }

    fillFilter(customFilterData: any): number[] {
        return customFilterData.selectedCat.length === 0 ? customFilterData.catArr.map(x => x.id! = 0) : customFilterData.selectedCat.map(x => x.id);
    }

    private getCategoriesAndSubCategories(customFilterData: any, index: number): number[] {
        let catArray: number[] = [];
        if (_.get(customFilterData, 'category.length')) {
            console.log(customFilterData.category);
           catArray =  customFilterData.category[index].selectedCat.length === 0 ?
           [] : customFilterData.category[index].selectedCat.map(x => x.id);
           if((customFilterData.category[index].selectedCat.length === customFilterData.category[index].catArr.length) && customFilterData.category[index].catArr.length > 0){
            catArray.length = 0;
        }
        }
        return catArray;
    }
}


