/*
  NOTES TO DEVELOPER
  *****This page for ui developement only, In case any change in this page without authurization will affect UI*****
 */
import { Component, Input, OnChanges, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons, FieldArray } from '../../form-type.entity';
import { CustomErrorMesssage } from '../../form-validation';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'lib-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropDownComponent implements OnChanges, OnInit, AfterViewInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    formBuilderIcons: FormIcons;
    fieldType: string;
    formFieldName: any;
    formFieldlabel: any;
    formArrayName: any;
    formFieldHeader: string;
    errorMessage: any;
    formAddBuilderIcons: FormIcons;
    formRemoveBuilderIcons: FormIcons;
    isRequired: boolean;
    errorLength: number;
    dropDownsearchControl = new FormControl();
    deepCloneFilterArray: Array<any>;
    enableDropDownSearch: boolean;
    enableClearOption: boolean;
    formFieldToolTip: string;
    formHintLabel: string;
    selectedLabel: any;
    get isValid(): any { return this.form.controls[this.formFieldName].valid; }
    get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }
    constructor(public AgyDynamicFormService: AgyDynamicFormServices) {
        this.enableDropDownSearch = (this.field.hasOwnProperty('EnableDropDownSearch') && this.field.EnableDropDownSearch) ? true : false;
        this.enableClearOption = (this.field.hasOwnProperty('EnableClearOption') && this.field.EnableClearOption) ? true : false;
        if (this.fieldType === 'groupingDataMultiple') {
            this.enableDropDownSearch = this.field.fieldType[this.fieldType].hasOwnProperty('EnableDropDownSearch') ?
            this.field.fieldType[this.fieldType].EnableDropDownSearch : false;
        }
    }

    ngAfterViewInit(): void {
        const that = this;
        this.isRequired = this.form.controls[this.formFieldName]?.validator ?
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
        this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
            this.isRequired = this.form.controls[this.formFieldName]?.validator ?
                !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
            this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field);
        });
        this.form.controls[this.formFieldName].valueChanges.subscribe(x => {
            this.selectedLabel = '';
            if (x) {
                this.selectedLabel = this.field.fieldType[this.fieldType].options?.find(opt => opt.key === x)?.label;
            }
        });
        if (this.form.controls[this.formFieldName] && this.form.controls[this.formFieldName].errors) {
            const errorsArray = Object.keys(this.form.controls[this.formFieldName].errors);
            this.errorLength = errorsArray.length;
        }
        this.dropDownsearchControl.valueChanges.pipe(debounceTime(500)).subscribe(x => {
            const searchKey = x ? x.toString().toLowerCase() : '';
            if (that.deepCloneFilterArray.length === 0) {
                that.deepCloneFilterArray = this.field.fieldType[this.fieldType].options;
            }
            if (that.deepCloneFilterArray && that.deepCloneFilterArray.length > 0) {
                const filtered = that.deepCloneFilterArray
                    .filter(arrayOptions => arrayOptions.label.toString().toLowerCase().includes(searchKey));
                this.field.fieldType[this.fieldType].options = [...filtered];
            }
        });
        this.enableDropDownSearch = (this.field.hasOwnProperty('EnableDropDownSearch') && this.field.EnableDropDownSearch) ? true : false;
        this.enableClearOption = (this.field.hasOwnProperty('EnableClearOption') && this.field.EnableClearOption) ? true : false;
        if (this.fieldType === 'groupingDataMultiple') {
            this.enableDropDownSearch = this.field.fieldType[this.fieldType].hasOwnProperty('EnableDropDownSearch') ?
            this.field.fieldType[this.fieldType].EnableDropDownSearch : false;
        }
        if (this.form.controls[this.formFieldName]?.value) {
            this.selectedLabel = this.field.fieldType[this.fieldType].options?.find(opt =>
                 opt.key === this.form.controls[this.formFieldName].value)?.label;
        }
    }

    ngOnInit(): void {
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
        this.formRemoveBuilderIcons = {
            type: 'remove',
            label: 'remove',
            maxcount: this.field.maxcount
        };
        if (this.field.fieldType.SingleData) {
            this.field.fieldType.SingleData.IsRequired = this.field.fieldType.SingleData && this.field.fieldType.SingleData.FieldValidation
                ? this.field.fieldType.SingleData.FieldValidation.filter(x => x.key === 'required').length === 0 : false;
        }
        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.AgyDynamicFormService.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formFieldHeader = formField.fieldHeader;
        this.deepCloneFilterArray = this.field.fieldType[this.fieldType].options;
        this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip ?
         this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
        this.enableDropDownSearch = (this.field.hasOwnProperty('EnableDropDownSearch') && this.field.EnableDropDownSearch) ? true : false;
        this.enableClearOption = (this.field.hasOwnProperty('EnableClearOption') && this.field.EnableClearOption) ? true : false;
        if (this.fieldType === 'groupingDataMultiple') {
            this.enableDropDownSearch = this.field.fieldType[this.fieldType].hasOwnProperty('EnableDropDownSearch') ?
            this.field.fieldType[this.fieldType].EnableDropDownSearch : false;
        }
        this.formHintLabel = this.field.fieldType[this.fieldType].hintLabel;

    }
    eventCallback(index?: any, argus?: any): void {
        this.AgyDynamicFormService.formEventCallback(this.field, index, this.form);
        this.dropDownsearchControl.reset();
        this.selectedLabel = '';
        if (argus?.value) {
            this.selectedLabel = this.field.fieldType[this.fieldType].options?.find(opt => opt.key === argus.value)?.label;
        }
    }
    clearOption(index?: any, argus?: any): void {
        this.form?.controls[this.field.name]?.setValue(null);
        this.form.updateValueAndValidity();
        this.AgyDynamicFormService.formEventCallback(this.field, index, this.form);
        this.dropDownsearchControl.reset();
        this.selectedLabel = '';
    }

    suffixIconClick(): void {
        this.AgyDynamicFormService.formEventCallback(this.field, 'edit', this.form);
    }
    openedChange(event): void {
        // console.log('Open Change', event);
        if (event === false) {
            this.dropDownsearchControl.reset();
        } else {
            const dropdownElement = document.getElementById(this.formFieldName);
            if (dropdownElement) {
                dropdownElement.autofocus = true;
                dropdownElement.focus();
            }
            this.deepCloneFilterArray = this.field.fieldType[this.fieldType].options;
        }
    }

    fieldArrayCallback(e: FieldArray): void {
        this.field.fieldType[this.fieldType].data.push(e);
    }
    ngOnChanges(): void {
        this.formBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
    }




    iconCallback(e: any): void {
        if (e.iconType.type === 'remove') {
            this.AgyDynamicFormService.RemoveRow(e.formArray, e.formObject, e.objectIndex);
        } else {
            this.AgyDynamicFormService.addnewRow(e.formArray, e.formObject);
        }
    }
}
