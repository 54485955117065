import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { AgDateConfig } from '../../models/ag-models';
@Component({
  selector: 'app-spa-ag-date-picker',
  templateUrl: './ag-date-picker.component.html',
  styleUrls: ['./ag-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgDatePickerComponent implements OnInit {
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  hidden = false;
  maxDate: string | Date;
  minDate: string | Date;
  placeHolder: string;
  value: string | Date;
  captions = {
    invalid: 'Invalid'
  };
  isDateRequired: boolean;
  @Output() datePickerChange = new EventEmitter();
  isDisabled: boolean;
  captionsCommon: any;
  placeHolderFormat: string;
  @Input('inputs')
  set inputOptions(value: AgDateConfig) {
    this.className = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.hidden = value.hidden ? value.hidden : false;
    this.maxDate = value.maxDate;
    this.minDate = value.minDate ? value.minDate : new Date('1/1/1900');
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.isDateRequired = value.isDateRequired ? value.isDateRequired : false;
    this.isDisabled = value.isDisabled ? value.isDisabled : false;
  }
  constructor(private localization:SpaLocalization) {
    this.captionsCommon = this.localization.captions.common;
  }

  ngOnInit() {
    this.placeHolderFormat = this.localization.inputDateFormat;
  }

  dateChanged() {
    this.datePickerChange.emit([this.form, this.formControlName]);
  }
}
