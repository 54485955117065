import { Component, OnInit, Inject, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-void-reason',
  templateUrl: './void-reason.component.html',
  styleUrls: ['./void-reason.component.scss']
})
export class VoidReasonComponent implements OnInit {

  localisation:any;
  voidGroup:UntypedFormGroup;
  floatLabel: string;
  @ViewChildren('commentnew') commentnew: ElementRef;


  constructor(private fb:UntypedFormBuilder , private ls:RetailLocalization, private dialogRef:MatDialogRef<VoidReasonComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: any) {
      this.floatLabel = this.ls.setFloatLabel;
  }

  onCancelClick(){
    this.dialogRef.close({action : 'cancel'});
  }
  save(){
    this.dialogRef.close({action : 'ok', reason : this.voidGroup.controls['comments'].value});
  }

  ngOnInit() {
    this.localisation= this.ls.captions.shop;
    this.voidGroup = this.fb.group({
      comments: ['', Validators.required]
    });
   
  }

  ngAfterViewInit()
  {
    
    setTimeout(() => {
      this.commentnew['first'].nativeElement.focus();
     }, 100)

   
  }

}
