import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormConfiguration, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';

@Component({
  selector: 'app-agilysys-nomath-popup',
  templateUrl: './agilysys-nomath-popup.component.html',
  styleUrls: ['./agilysys-nomath-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysNomathPopupComponent implements OnInit {
  agilysysNomathButton: AgilysysButtonModel[];
  agilysysNomathForm: FormConfiguration;
  agilysysNomathFormHeader: string;
  mathDetailsList: Array<any>;
  QuantityHeader: string;
  QuantityValue: any;
  homeGroup: FormGroup;
  UnitCode: any;
  conversionFormula: any;

  constructor(public dialogRef: MatDialogRef<AgilysysNomathPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionConfiguration, private formBuilder: FormBuilder) {
       this.agilysysNomathButton = this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupButtonConfig'];
       this.agilysysNomathForm = this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupFormConfig'];
       this.homeGroup = this.formBuilder.group({});
       this.agilysysNomathFormHeader = this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupHeader'];
       this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['FormGroupData'] = this.homeGroup;
       const npMathId = this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].Id;
       this.mathDetailsList = this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['NomathDetails'].filter(f => f.NoMathRowId === npMathId);
       this.QuantityHeader = this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['NomathQuantityDisplay'];
       this.UnitCode = this.QuantityHeader.split(' ')[2].replace('(', '').replace(')', '');
       this.QuantityValue = this.data.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['NoMathQuantityValue'];
       this.homeGroup.valueChanges.subscribe(x => {
        for (const property in x) {
            if ( property !== 'Qty' ) {
              const mathValues = Object.values(x).splice(1);
             const enbaleButton =  mathValues.some( e => e !== '');
             this.agilysysNomathButton.forEach( ( buttomModel: AgilysysButtonModel) => {
              if (buttomModel.buttonKey === 'confirm' || buttomModel.buttonKey === 'reset') {
                   buttomModel.displayProperity = !enbaleButton;
              }
           });
            }
        }
       });
     }

  ngOnInit(): void {
    this.conversionFormula = this.agilysysNomathForm;
    this.conversionFormula.DisplayName = `${this.conversionFormula.DisplayName} Calculation`;
    this.conversionFormula.FormFields.forEach(value => {
      value.name = value.name.split(' ')
      .map(word => word[0])
      .join('');
    });
    this.conversionFormula.FormFields[0].label = this.conversionFormula.FormFields[0].label.slice(0, -5);
  }

}
