import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class LiteralsUpdatesService {
  literals: any;
  userRole: any;
  isDesignAuthor: boolean;

  constructor(private localization: Localization) {
    this.literals = this.localization.captions;
    this.userRole = this.localization.GetUserInfo('roleName');
    this.isDesignAuthor = (this.userRole == 'Design Author') ? true : false;
  }

  public updateLiterals() {
    let editables: any[] = [];
    document.querySelectorAll('label').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('mat-error').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('h4').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('h1').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('h2').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('mat-checkbox').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('div').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('span').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('mat-label').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });  
    document.querySelectorAll('mat-select').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    document.querySelectorAll('th').forEach(x => { if (x.attributes["LiteralID"] != undefined) editables.push(x) });
    
    
    const map = new Map<string, string>();
    for (let listofElements in Object.keys(editables)) {
      let LitKey = editables[listofElements].getAttribute('LiteralID');
      if (LitKey && LitKey.length > 0)
        map.set(LitKey, editables[listofElements].textContent)
    }

    map.forEach((value: string, key: string) => {
      let captions: any[] = key.split('$');
      let literalsCollection: object = this.literals;

      let count: number = 1;

      if (captions.length > 0) {
        for (let obj of captions) {
          if (count == captions.length)
            literalsCollection[obj] = value;
          else
            literalsCollection = literalsCollection[obj]
          count++;
        }
      }
    });
  }

  public download() {
    this.updateLiterals();
    const blob = new Blob([JSON.stringify(this.literals, null, '\t')], { type: 'application/json', endings: 'transparent' });
    let userlang = this.localization.GetPropertyInfo("UserLanguage")
    saveAs(blob, userlang + '.json');
  }
}
