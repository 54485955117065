import * as _ from 'lodash';
import { SalesTaxConfig } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';

export const SalesTaxAlgorithm: SalesTaxConfig = (GridData: Array<any>, Saleskey: string, Info: any): any => {
  try {
      try {
            let returnValue = 0;
            if ( Info.hasOwnProperty('KeyConfig') && Info.KeyConfig.ValidationType === 'sum') {
                returnValue = 0;
                GridData.forEach(x => {
                  x[Info.KeyConfig.PrimaryKey] = x[Info.KeyConfig.PrimaryKey] !== undefined ?
                   x[Info.KeyConfig.PrimaryKey] : 0;
                   x[Info.KeyConfig.SecondaryKey] = x[Info.KeyConfig.SecondaryKey] !== undefined ?
                   x[Info.KeyConfig.SecondaryKey] : 0;
                  x.TaxValue = Formater
                  ('Currency', (parseFloat(x[Info.KeyConfig.PrimaryKey]) + parseFloat(x[Info.KeyConfig.SecondaryKey])), null, null);
                  returnValue +=  parseFloat(x.TaxValue);
                });
            } else if ( Info.hasOwnProperty('KeyConfig') && Info.KeyConfig.ValidationType === 'multiply') {
              returnValue = 0;
              if (GridData.length > 0) {                
                  GridData.forEach(x => {
                    returnValue +=  x[Info.KeyConfig.PrimaryKey] * x[Info.KeyConfig.SecondaryKey];
                });
              }
          }
          returnValue = Formater('Currency', returnValue, null, null);
          return returnValue;
        } catch (Error) {
        console.error('Error occurred in Sales Calculation', Error);
      }
  } catch (error) {
    console.log('Error occurred in Sales Calculation', error);
  }
};
