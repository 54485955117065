import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { DatePickerComponent } from 'src/app/eatecui/dist/AgilysysDynamicGrid/public_api';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { ConfigurationType, FormBuilderGroupKey, MasterInventoryManagement, NestedBuilderData, NestedDataBuilder } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FieldBuilderType } from './form-builder-type.enum';
import { FormModelConfiguration, FormModelCustomBuilder, FormNestedModelCustomBuilder } from './form-config.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { HttpClient } from '@angular/common/http';

export const NestedFormCustomBuilding: FormNestedModelCustomBuilder = (
ViewModelEntity: any,
PostModelEntity: any,
MasterDataCollection: MasterInventoryManagement,
httpService: HttpService,
NestedDataBuild: NestedDataBuilder, translateService: TranslateService, dialog: MatDialog, selectedValue?: any,
sysytemPreferenceService?: SysytemPreferenceService, MasterHomeGroup?: FormGroup, commonService?: CommonService, http?: HttpClient, customData?: MatDialog, MasterParentFormGroup?: FormGroup): any => { // Here customData will be coming as MatDialog 
    try {
        const NestedFormBuilder = [];
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 ) {
            const entityName = PostModelEntity[0]['$']['Name'].toString();
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            if (NestedDataBuild && NestedDataBuild.NestedType === 'accordian') {
                NestedDataBuild.NestedData.forEach((FormBuilderGroup: NestedBuilderData) => {
                    const accordianFormType = {} as FormType;
                    accordianFormType.hide = ( FormBuilderGroup.hasOwnProperty('EdmType') &&
                    FormBuilderGroup.EdmType === 'Edm.hidden' ) ? true : false;
                    // eslint-disable-next-line
                    //FormDisplayHideRemapEngine Need FormBuildeGrouping
                   const FormBuilderGrouping = {} as FormBuilderGroupKey;
                   FormBuilderGrouping.Hide = accordianFormType.hide;
                    accordianFormType.hide = ProductDataRemap.FormDisplayHideRemapEngine(
                    productremapJson, entityName + NestedDataBuild.NestedName, FormBuilderGroup.OdataKey, FormBuilderGrouping);
                    accordianFormType.type = FormInterface.FormGroupFieldTypes(
                    ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService);
                    accordianFormType.ParentClass =
                    ( FormBuilderGroup.hasOwnProperty('ParentClass') || FormBuilderGroup.OdataKey === 'CostPercentage') ?
                    FormBuilderGroup.ParentClass : 'agy-md-3';
                    FormBuilderGroup.FieldType = accordianFormType.type;
                    accordianFormType.maxCount = FormBuilderGroup['MaxLength'];
                    FormBuilderGroup.ParentNestedKey = NestedDataBuild.NestedName;
                    if (FormBuilderGroup.hasOwnProperty('autoCompleteHeader') && FormBuilderGroup['autoCompleteHeader'].length > 0) {
                      accordianFormType.autoCompleteHeader = FormBuilderGroup['autoCompleteHeader'];
                    }
                    if (FormBuilderGroup.hasOwnProperty('autoCompleteConfig')) {
                      accordianFormType.autoCompleteConfig = FormBuilderGroup['autoCompleteConfig'];
                    }
                    if (FormBuilderGroup.hasOwnProperty('autocompleteChipHeader')) {
                      accordianFormType.autocompleteChipHeader = FormBuilderGroup['autocompleteChipHeader'];
                    }
                    if ( FormBuilderGroup && FormBuilderGroup.FieldFormaters ) {
                      if (FormBuilderGroup.FieldFormaters.DecimalFormaterConfig) {
                        accordianFormType.fieldFormaters = {
                            EnableDecimalFormatter:
                            FormBuilderGroup.FieldFormaters.DecimalFormaterConfig.EnableCustomFormater,
                            WholeNumberLength: commonService.GetWholeNumberLength(),
                            VisibleDecimal: commonService.GetVisibleNumberLength(),
                            FocusDecimal: commonService.GetFocusNumberLength()
                        };
                    } else if (FormBuilderGroup.FieldFormaters.CurrencyFormaterConfig) {
                      accordianFormType.fieldFormaters = {
                            EnableDecimalFormatter:
                            FormBuilderGroup.FieldFormaters.CurrencyFormaterConfig.EnableCustomFormater,
                            WholeNumberLength: commonService.GetWholeNumberLength(true),
                            VisibleDecimal: CommonService.GetCurrencyVisible(),
                            FocusDecimal: commonService.GetFocusNumberLength(true)
                        };
                    }
                    }
                    accordianFormType.EnableSuffixicon = FormBuilderGroup['EnableSuffixicon'];
                    accordianFormType.iconName = FormBuilderGroup['iconName'];
                    accordianFormType.fieldType = {
                       'SingleData' : FormInterface.FormCustomFieldType(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService, dialog,
                        sysytemPreferenceService, null, null, null, selectedValue, null, null, accordianFormType, null, customData, http, NestedDataBuild, MasterParentFormGroup)
                    };
                    if (accordianFormType.type === FormFieldType.DROPDOWN && FormBuilderGroup.hasOwnProperty('EnableDropDownSearch')
                        && FormBuilderGroup.EnableDropDownSearch) {
                        accordianFormType.EnableDropDownSearch = FormBuilderGroup.EnableDropDownSearch;
                    } else if (accordianFormType.type === FormFieldType.DATEPICKER && 
                       FormBuilderGroup.hasOwnProperty('DateConfiguration') && FormBuilderGroup.DateConfiguration.EnableConfiguration) {
                        FormBuilderGroup.DateConfiguration.ConfigurationType.forEach((x: ConfigurationType) => {
                          accordianFormType[x.Type] =  dateconfiguration(FormBuilderGroup, x);
                        });  
                    }
                    NestedFormBuilder.push(accordianFormType);
                });
            }
        }
        return NestedFormBuilder;

    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function dateconfiguration(FormBuilder: NestedBuilderData, configurationType: ConfigurationType): any {
     try {
       let dateFormat = null;
      switch ( configurationType.Type ) {
        case 'minDate':
          if ( configurationType.Range === 'today' ) {
            dateFormat = new Date();
          }
          break;
        case 'maxDate':
          if ( configurationType.Range === 'today' ) {
            dateFormat = new Date();
          }
          break;
        default:
          dateFormat = null;
      }
      return dateFormat;
     } catch ( error ) {
       console.error(error);
     }
}
