import { Component, EventEmitter, OnInit, Output, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RetailLocalization } from '../../../../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../../../../common/services/retail-property-information.service';
import { RetailReportControl, ValidationMessage } from '../../../../basereport/base-report.component';
import { ReportBusinessService } from '../../../../business/report-business.service';
import { DropDownData } from '../../../../business/report.modals';
import { ReportDataService } from '../../../../data/report-data.services';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
@Component({
  selector: 'app-sales-by-vendor',
  templateUrl: './sales-by-vendor.component.html',
  styleUrls: ['./sales-by-vendor.component.scss']
})
export class SalesByVendorComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  ItemFormGrp: UntypedFormGroup;
  ShiftFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  inActiveText = this.captions.NewPageAfterEachOutlet;
  DetailView = this.captions.DetailView;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any[];
  clerks: DropDownData[] = [];
  registers: any;
  categoryGroupSelected: any[] = [];
  IsNewPageAfterEachOutlet: UntypedFormControl;
  IsDetailView: UntypedFormControl;
  isFromJobScheduler: boolean = false;
  editPatchValue: any;
  isFromEdit = false;
  categoryComponentFilter: any;
  timelineOptions: DropdownOptions[];
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization,
              public business: ReportBusinessService, private dataService: ReportDataService,
              private propertyInfo: RetailPropertyInformation,private container: ViewContainerRef) { }

   ngOnInit() {
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
   this.ItemFormGrp = this.fb.group({
      filterItems: false,
      searchbyitem: '',
      includeInactiveusers: false,
      registers: new UntypedFormControl([this.defaultData]),
      clerks: new UntypedFormControl([this.defaultData]),
      itemFilter: 0,
      IsNewPageAfterEachOutlet: false,
      IsDetailView: false
    });
   this.formReady.emit(this.ItemFormGrp);
   this.onLoad();
   this.isValidText(true);

 

    if (this.container) 
    {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      this.categoryComponentFilter =
      {
        category: _parent.editableValue.CategoryIds,
        subCategory1: _parent.editableValue.SubCategory1Ids,
        subCategory2: _parent.editableValue.SubCategory2Ids,
        subCategory3: _parent.editableValue.SubCategory3Ids,
        subCategory4: _parent.editableValue.SubCategory4Ids,
        subCategory5: _parent.editableValue.SubCategory5Ids
      }; 
      this.PatchSchedulercontrols(_parent.edit);      
    }
  }

  private PatchSchedulercontrols(isEdit) {
    if (this.localization.isFromJobScheduler && isEdit) {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      setTimeout(() => {     

        if(patchItem.VendorIds != undefined){
          this.dataService.getAllVendor().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.VendorIds.find(x => x == item.id);
            });
            this.clerks = arr;
            this.business.FilterDataSource["allClerks"] = this.clerks;
          });
        }       

        if(patchItem.CategoryIds != undefined){
          this.dataService.GetAllCategoryGroups().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.CategoryIds.find(x => x == item.id);
            });
            this.allCategoryGroups = arr;
            this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
          });
        }   
      }, 1);
    }
  }

 

  ngOnDestroy() {
    this.isValidText(true);
  }

  private async onLoad() {
    this.clerks = await this.dataService.getAllVendor();
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.business.FilterDataSource["allClerks"] = this.clerks;
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
  }

  detailToggleView(detailToggleView: boolean) {
    this.ItemFormGrp.controls['IsNewPageAfterEachOutlet'].setValue(this.ItemFormGrp.controls['IsNewPageAfterEachOutlet'].value);
    this.ItemFormGrp.controls['IsDetailView'].setValue(detailToggleView);
  }

  formInitialized(form: UntypedFormGroup) {
    this.ItemFormGrp.setControl('category', form);
  }

  changeToggleEvent(event) {
    this.ItemFormGrp.controls['IsNewPageAfterEachOutlet'].setValue(event);
    this.ItemFormGrp.controls['IsDetailView'].setValue(this.ItemFormGrp.controls['IsDetailView'].value);
  }

  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
        this.ItemFormGrp.setControl('reportcategory', event[1]);
        }
    else if (event[0] === 'clerks') {
        this.ItemFormGrp.setControl('clerks', event[1]);
    }
  }

  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }


}
