<section *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'" class="{{field.class}} search-multi-autocomplete">
  <div [formGroup]="form" #formtext>
  <!-- <i *ngIf="field.iconName" class="icon-{{field.iconName}} pr8"></i> -->
  <mat-form-field  title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
    selectedLabels : formFieldToolTip}}" [hideRequiredMarker]="false" class="golftextbox {{field.class}}" appearance="fill" *ngIf="field.autoCompleteConfig && field.autoCompleteConfig.EnableMultiSelect === true"
   [ngClass]="field.fieldType[fieldType].disbaledProperty === true ? 'textbox-disabled':  ''">
      <mat-label>{{formFieldlabel}}</mat-label>
      <mat-chip-list #chipList [formControlName]="formFieldName" [required]="isRequired">
      <div *ngIf="autocompleteChipHeader">
      <mat-chip *ngFor="let item of selectedItems | slice:0:2" [selectable]="selectable" [removable]="removable" (removed)="remove(item)">
        <span class="chip-text" title="{{item[name]}}">{{item[name]}}</span>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </div>
    <!-- {{searchControl.value}} -->
    
    <input *ngIf="field.autoCompleteConfig.EnableMultiSelect === true"
    [attr.type]="hidePassword ? typeField : field.type "
    id="searchControl"
    name="searchControl"
    [formControl]="searchControl"
    matInput
    placeholder= "Search"
    (keypress)="keyPress($event)"
    (keyup)="onKey($event)"
    (paste)="onPaste($event)"
    [readonly]="field.fieldType[fieldType].disbaledProperty"
    [required]="isRequired"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputAddOnBlur]="addOnBlur"
    #searchInput
    >
    <!-- (matChipInputTokenEnd)="add($event)" -->
    <span class="count-field" *ngIf="selectedItems.length > 2" (click)="openPopup(template)">+ {{selectedItems.length - 2}}</span>
  </mat-chip-list>
    <mat-error *ngIf="chipList.errorState">{{errorMessage}}</mat-error>
    <mat-hint *ngIf="formHintLabel">{{formHintLabel}}</mat-hint>
    <i *ngIf="field.type == 'password'"
       matSuffix [ngClass]="hidePassword ? 'icon-password-show-eatec' : 'icon-password-hide-eatec '"  (click)="showPassword()"
       ></i>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="search-autocomplete-multiple autocomplete-{{formFieldName}}" (opened)="menuOpened()">
         <div *ngIf="field.fieldType.SingleData.autoCompleteData && field.fieldType.SingleData.autoCompleteData.length > 0 && field.autoCompleteConfig.EnableMultiSelect === true" class="search-secondary">
          <mat-option class="header-option" [disabled]="true">
              <div class="panel-header-conatiner" *ngIf="autoCompleteHeader && autoCompleteHeader.length > 0 && field.fieldType.SingleData.autoCompleteData && field.fieldType.SingleData.autoCompleteData.length > 0">
                  <div class="panel-header-list" *ngFor="let header of autoCompleteHeader">
                      <span *ngIf="!header.Disable">{{header.DisplayName}}</span>
                  </div>
              </div>
          </mat-option>
          <div class="data-container" [ngClass]="autoCompleteHeader.length === 1 ? 'data-w-100' : ''">
            <div class="data-options" *ngFor="let data of field.fieldType.SingleData.autoCompleteData">
              <mat-option class="data-option" [value]="selectedItems">
                <mat-checkbox [checked]="data.ischecked"  *ngIf="field.autoCompleteConfig.EnableMultiSelect === true"  (change)="checkBoxChange(data)" class="search-multi-dropdown-checkbox" (click)="$event.stopPropagation()">
                  <span class="data-list" *ngFor="let headerData of autoCompleteHeader" title="{{data[headerData.Name]}}">{{data[headerData.Name]}}</span>
                </mat-checkbox>
              </mat-option>
            </div>
          </div>
          <div class="load-more-container" *ngIf="field.autoCompleteConfig.EnableLoadMore === true">
            <button [class.spinner]="loading" [disabled]="loading"  class="show-more-button" (click)="showMore()">Show More</button>
          </div>
          </div>
          <div id="search-autocompleted-nodata-found" *ngIf="field.fieldType.SingleData.autoCompleteData && field.fieldType.SingleData.autoCompleteData.length === 0" class="data-container">
              <mat-option class="data-option" [disabled]="true">
                  <span class="data-list no-data-found" >No Data Found</span>
              </mat-option>
          </div>
      </mat-autocomplete>
  </mat-form-field>
  <mat-form-field title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
    selectedLabels : formFieldToolTip}}" [hideRequiredMarker]="false" class="golftextbox {{field.class}} search-single-autocomplete" appearance="fill" *ngIf="field.autoCompleteConfig && field.autoCompleteConfig.EnableMultiSelect !== true"
   [ngClass]="field.fieldType[fieldType].disbaledProperty === true ? 'textbox-disabled':  ''">
    <mat-label>{{formFieldlabel}}</mat-label>
<input *ngIf="field.autoCompleteConfig && field.autoCompleteConfig.EnableMultiSelect !== true"
  [attr.type]="hidePassword ? typeField : field.type "
  id="searchControl"
  name="searchControl"
  [formControl]="searchControl"
  matInput
  placeholder= "Search"
  (keypress)="keyPress($event)"
  (keyup)="onKey($event)"
  (paste)="onPaste($event)"
  [readonly]="field.fieldType[fieldType].disbaledProperty"
  [required]="isRequired"
  [matAutocomplete]="auto"
  #trigger="matAutocompleteTrigger"
  (click)="textInputClicked()"
  >
  <mat-error>{{errorMessage}}</mat-error>  
  <mat-hint *ngIf="formHintLabel">{{formHintLabel}}</mat-hint>
  <i *ngIf="searchControl.value" matSuffix class="cancel-icon icon-requisition-denied-eatec"  (click)="clearSearchText()"
     ></i>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="search-autocomplete-multiple autocomplete-{{formFieldName}}" (closed)="optionSelect('optionclicked')">
        <div *ngIf="field.fieldType.SingleData.autoCompleteData && field.fieldType.SingleData.autoCompleteData.length > 0 && field.autoCompleteConfig.EnableMultiSelect !== true" class="search-secondary-single">
          <mat-option class="header-option" [disabled]="true">
            <div class="panel-header-conatiner" *ngIf="autoCompleteHeader && autoCompleteHeader.length > 0 && field.fieldType.SingleData.autoCompleteData && field.fieldType.SingleData.autoCompleteData.length > 0">
                <div class="panel-header-list" *ngFor="let header of autoCompleteHeader">
                    <span *ngIf="!header.Disable">{{header.DisplayName}}</span>
                </div>
            </div>
        </mat-option>
        <div class="data-containers">
           <mat-option  class="data-option" *ngFor="let data of field.fieldType.SingleData.autoCompleteData" [value]="data" (onSelectionChange)="SingleSelectClick(data);">
                <span class="data-list" *ngFor="let headerData of autoCompleteHeader" title="{{data[headerData.Name]}}">{{data[headerData.Name]}}</span>
            </mat-option>
        </div>
        <div class="load-more-container" *ngIf="field.autoCompleteConfig.EnableLoadMore === true">
          <button [class.spinner]="loading" [disabled]="loading"  class="show-more-button" (click)="showMore()">Show More</button>
        </div>
        </div>
        <div id="search-autocompleted-nodata-found" *ngIf="field.fieldType.SingleData.autoCompleteData && field.fieldType.SingleData.autoCompleteData.length === 0 && searchControl.value" class="data-container label-color-change">
            <mat-option class="data-option" [disabled]="true">
                <span class="data-list no-data-found" >No Data Found</span>
            </mat-option>
        </div>
        <div id="search-autocompleted-nodata-found" *ngIf="field.fieldType.SingleData.autoCompleteData && field.fieldType.SingleData.autoCompleteData.length === 0 && !searchControl.value" class="data-container">
          <mat-option class="data-option" [disabled]="true">
              <span class="data-list no-data-found" >Search by {{formFieldlabel}}</span>
          </mat-option>
      </div>
    </mat-autocomplete>
</mat-form-field>
  </div>
</section>

<!-- <section class="agilysys-multi-auto-complete-popup" *ngIf="multiAutocompletePopup"> -->
  <ng-template #template>
    <header class="popup-header">Selected Items ({{selectedItems.length}}) <mat-icon matDialogClose align="end">close</mat-icon>
    </header>
    <main class="popup-main">
    <mat-chip-list #chipLists>
      <div *ngIf="autocompleteChipHeader">
      <mat-chip *ngFor="let item of selectedItems" [selectable]="selectable" [removable]="removable" (removed)="remove(item)">
        {{item[name]}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </div>
    </mat-chip-list>
  </main>
    <footer class="popup-footer">
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose color="primary">Cancel</button>
    </mat-dialog-actions>
  </footer>
   </ng-template>
<!-- </section> -->
