import { Component, OnInit, ViewEncapsulation,Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { AppointmentPopupComponent } from '../../appointment-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { GridType, Host } from 'src/app/shared/globalsContant';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { BaseResponse, HttpMethod } from 'src/app/common/Models/http.model';
import { HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { ClientInfo,ImageData } from 'src/app/shared/business/shared.modals';
import { AppModuleService } from 'src/app/app.service';
import * as global from '../../../globalsContant';
import { ClientLabel } from 'src/app/shared/business/new-booking.model';
import _ from 'lodash'

@Component({
  selector: 'app-client-relationship',
  templateUrl: './client-relationship.component.html',
  styleUrls: ['./client-relationship.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ClientRelationshipComponent implements OnInit {
  relationList: any = [];
  captions: any;
  relationshipForm: UntypedFormGroup;
  floatLabel: string;
  searchText: string = '';
  addButton: ButtonValue;
  public tableDataTemp: any = [];
  public tableoptions: any;
  tableContent: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  onEditDisableFunction: boolean = true;
  isEdit: boolean = false;
  cancelButton: ButtonValue;
  dropDownvalue: any;
  currentId :number =0;
  editObj;
  showClose: boolean = true;
  isGuestRecordPurged: boolean = false;
  requestUid = '';
  messageForWarning: string;
  searchTextPlaceHolder: string;
  clientInfo :any=[];
  formattedData: any;
  clientData: any[];
  formattedPhoneNo: any[];
  formattedPhoneNos: any[];
  formattedEmails: any[];
  formattedEmail: any[];
  clientService: any;
  guestNameFromGlobalSearch: any;
  selectedClientSearchType: number;
  clientName: string;
  searchLimit=1;
  selectedRelation: any;
  currentRelation: any;
  guestIds:any=[];
  contactGuestInfo:any[]=[];
  clearSearchText: boolean=false;
  contactClientId: any;
  currentEditClient: any;
  selectedClientInfo: any;
  deleteSelectedRecords: boolean = false;
  allTagData = [];
  selectedTagData = [];
  tagSearchKey: string[] =  ['firstName','lastName','phoneNumber','emailId'];
  autoCompleteKeys: string[] =  ['firstName','lastName','phoneNumber','emailId'];
  selectedGuestTagKey: string[] = ['firstName','lastName'];
  searchGuestMaxLength = 50;
  tagArr : any[] =[];
  clientFirstName: any;
  clientLastName: any;
  clientPhone: any;
  clientEmail: any;
  disableUpdateButton: boolean = false;
  originalRelationList: any = [];
  @Input('changedClientData')
  set changeClientData(value){
    if(value){
      this.initializeForm();
    }
  }

  constructor(public dialog: MatDialog,private localization: SpaLocalization, private utils: SpaUtilities, private http: HttpServiceCall,
    private fb: UntypedFormBuilder, public appointmentservice: AppointmentpopupService,public _as: AppModuleService) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.initializeForm();
  }


  async initializeForm(){
    this.messageForWarning = this.appointmentservice?.allCaptions?.lbl_guestRecordPurged;
    this.isGuestRecordPurged = this.appointmentservice?.clientEditData?.clientDetail?.isPurged;
    this.relationshipForm = this.fb.group({
      relation: [],
    })
    this.addButton = {
      type: 'primary',
      label:  this.captions.btn_add,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.appointmentservice.guestContactFormGrp = this.fb.group({});
    this.relationshipForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.addButton.disabledproperty = !(this.relationshipForm.valid && this.relationshipForm.dirty);
      this.addButton = { ...this.addButton };
    })
    this.relationList = await this.GetAllRelationships();
    this.originalRelationList = _.cloneDeep(this.relationList);
    this.isGuestRecordPurged ? this.relationshipForm.disable() : '';
    this.searchTextPlaceHolder = this.captions.lbl_clientSearch;
    if(this.appointmentservice.clientWidowActionType=="EDIT")
    {
      this.contactGuestInfo.push(this.appointmentservice?.clientEditData?.guestContact);
      //this.appointmentservice?.clientEditData?.guestContact?.forEach(g=> this.guestIds.push(g.contactGuestId)); //Change
      this.appointmentservice?.clientEditData?.guestContact?.forEach(g=>{
      this.currentRelation =  this.relationList.filter(r=> r.id==g.contactRelationshipId);
      let editData={
        guestId: g.contactGuestId,
        clientName:g.contactGuestFirstName+" "+g.contactGuestLastName,
        firstName:g.contactGuestFirstName,
        lastName:g.contactGuestLastName,
        relationshipId:g.contactRelationshipId,
        relationship:(g.contactRelationshipId == -1)? this.captions.related : this.currentRelation[0].name
      }
      this.tableDataTemp.push(editData);
      this.guestIds.push(g.contactGuestId);
    });
    }
    this.bindTable();
    this.selectedClientSearchType = global.clientSearchType.All
  }
  

  bindTable(){
    let arrDataHeader = [
      { 'title': this.captions.lbl_clientName, 'jsonkey': 'clientName', 'searchable': true, 'sortable': true, 'Sortable': 'clientName' },
      { 'title': this.captions.lbl_relationship, 'jsonkey': 'relationship', 'searchable': true, 'sortable': true, 'Sortable': 'relationship' },
    ]

    this.tableoptions = [
        {
            TableHdrData: arrDataHeader,
            TablebodyData: this.tableDataTemp,
            pagination: false,
            sortable: false,
            editable: true,
            CustomColumn: true,
            PlaceHoldertext: this.captions.WaitListSearchPlaceHolder,
            EnableActions: true,
            SelectRows: false,
            Searchable: false,
            EditMoreOption: false,
            SelectedSettingId: GridType.lostDeniedreason,
            Sortable: '',
            TableId: '',
            disableDelete: false,
            SelectRow: true,
            SelectOnlyRow: true,
            dropdownOptions: '',
            waitlistIcon: false,
            TableDraggable: false,
            sticky: true,
            DoneCancel: true
        }
    ];
  }

  EditRecords(eve) {
    if(eve[0].relationshipId == -1)
    {
      this.relationList.unshift({
        id: -1,
        name: this.captions.related
      });
    }
    this.isEdit = true;
    this.editObj=eve;
    this.addButton.label = this.captions.btn_edit;
    this.addButton.disabledproperty = true;
    this.addButton = {...this.addButton};
    this.relationshipForm.get('relation').setValue(eve[0].relationshipId);
    this.dropDownvalue=eve[0].relationship;
    this.currentEditClient = eve[0].guestId;
    this.selectedTagData= [eve[0]];
  }

  async GetAllRelationships(){
      const allRelations = await this.http.CallApiAsync<any>({
          host: Host.spaManagement,
          callDesc: "GetAllRelationships",
          uriParams: {"isIncludeInactive":false},
          method: HttpMethod.Get,
          showError: true
      });
      return allRelations.result;
  }


  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
        return await this.http.CallApiAsync({
            host: domain,
            callDesc: route,
            method: callType,
            body: body,
            uriParams: uriParams,
        });
    } catch (e) {
        console.error(e);
        throw e;
    }
}

  OnRelationChange(e){
    this.dropDownvalue=e.value;
    this.disableUpdateButton = this.dropDownvalue == -1;
    this.currentRelation=this.relationList.filter(r=>r.id==e.value);
    this.selectedRelation=this.currentRelation[0].name;
  }
 

  makeSearchCall(name: string,searchType: number, requestUid: string) {
    this.appointmentservice.clientsearchArray = [];
    this.appointmentservice.showNodata = false;
    this.appointmentservice.showLoading = true;
     this.requestUid = requestUid;
    this.http.CallApiWithCallback<any[]>({
      host: global.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "clientSearch",
      method: HttpMethod.Get,
      uriParams: { name: encodeURIComponent(name),searchType:searchType, requestUid: requestUid },
      showError: false,
      extraParams: ['dataBelongTo']
    });
  
  }


  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
    if (callDesc == "clientSearch") {
          let searchApiResponse = <any>result.result;
          let resultClient:any =[];
          searchApiResponse=searchApiResponse.filter(a=>a.id!==this.appointmentservice?.clientEditData?.clientDetail?.id);
          searchApiResponse=searchApiResponse.filter(x=>!this.guestIds.includes(x.clientDetail.guestId));
          searchApiResponse.forEach(element=>{
            element.clientDetail.phoneNumber = element.phoneNumbers[0]?.number ? (" | " + this.utils.getFormattedPhNo(element.phoneNumbers[0])):'';
            element.clientDetail.emailId = element.emails[0]?.emailId?(" | " + element.emails[0]?.emailId):'';
            resultClient.push(element.clientDetail)           
            });

          this.allTagData=resultClient;
          console.log(this.allTagData);
         }
      }

  errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(result.result);
  }

  onAdd(e){
    this.relationList = _.cloneDeep(this.originalRelationList);
    if(this.isEdit)
    {
    if(this.appointmentservice.clientWidowActionType=="EDIT"){
    let indexToUpdate = this.appointmentservice.clientEditData?.guestContact.findIndex(item => item.contactGuestId ===  this.currentEditClient);
    this.appointmentservice.clientEditData.guestContact[indexToUpdate].contactRelationshipId = this.dropDownvalue;
    this.appointmentservice.clientEditData.guestContact = Object.assign([], this.appointmentservice.clientEditData?.guestContact);
    this.appointmentservice.guestContact=this.appointmentservice.clientEditData?.guestContact;
  }else{
    let indexToUpdate = this.appointmentservice.guestContact.findIndex(item => item.contactGuestId ===  this.currentEditClient);
    this.appointmentservice.guestContact[indexToUpdate].contactRelationshipId = this.dropDownvalue;
    this.appointmentservice.guestContact = Object.assign([], this.appointmentservice.guestContact);
    }
    let index = this.tableDataTemp.findIndex(item => item.guestId ===  this.currentEditClient);
    this.tableDataTemp[index].relationshipId=this.dropDownvalue;
    this.tableDataTemp[index].relationship = this.selectedRelation;
    this.bindTable();
    this.isEdit=false;
    this.addButton.label = this.captions.btn_add;
    }
    else{
    let newData={
      guestId:this.contactClientId,
      clientName:this.clientName,
      firstName: this.clientFirstName,
      lastName: this.clientLastName,
      phoneNumber:this.clientPhone,
      emailId:this.clientEmail,
      relationshipId:this.dropDownvalue,
      relationship:this.selectedRelation

    }
    this.guestIds.push(this.contactClientId);
    this.clearSearchText = true;
     this.tableDataTemp.push(newData);
     this.bindTable();
     if(this.appointmentservice.clientWidowActionType=="EDIT"){
    this.appointmentservice?.clientEditData?.guestContact.push({  'id' : 0,
      'primaryGuestId' : '00000000-0000-0000-0000-000000000000',
      'contactGuestId' : this.appointmentservice.currentClient[0].guestId,
      'contactGuestFirstName':this.appointmentservice.currentClient[0].firstName,
      'contactGuestLastName':this.appointmentservice.currentClient[0].lastName,
      'contactRelationshipId' : this.dropDownvalue,
      'contactRelationshipName': ''
    });
    this.appointmentservice.guestContact=this.appointmentservice?.clientEditData?.guestContact;
  }
  else{
    this.appointmentservice.guestContact.push({  'id' : 0,
      'primaryGuestId' : '00000000-0000-0000-0000-000000000000',
      'contactGuestId' : this.appointmentservice.currentClient[0].guestId,
      'contactGuestFirstName':this.appointmentservice.currentClient[0].firstName,
      'contactGuestLastName':this.appointmentservice.currentClient[0].lastName,
      'contactRelationshipId' : this.dropDownvalue,
      'contactRelationshipName': ''
    });

  }

   }
   this.appointmentservice.isContactRelationUpdateRequired=true;
   this.relationshipForm.reset();
   this.appointmentservice.guestContactFormGrp.markAsDirty();
   this.appointmentservice.guestContactFormGrp.markAsTouched();
   this.deleteSelectedRecords = !this.deleteSelectedRecords;
   //this.selectedGuestTagKey=[];
   this.selectedTagData = [];
   this.disableUpdateButton = false;
  }
   
  openAddClient(){
    let data = {
      closebool: true,
      fromPackage: true
  };
    let dialogRef = this.dialog.open(AppointmentPopupComponent, {
      width: '85%',
      height: '85%',
      disableClose: true,
      hasBackdrop: true,
      data: data
  });

  dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
  });
  }

  DeleteList(e){
    if(this.appointmentservice.clientWidowActionType=="EDIT"){
      let indexToUpdate = this.appointmentservice.clientEditData.guestContact.findIndex(item => item.contactGuestId ===  e[0].guestId);
      this.appointmentservice.clientEditData.guestContact.splice(indexToUpdate,1);
      this.appointmentservice.guestContact=this.appointmentservice.clientEditData.guestContact;
    }else{
    let indexToUpdate = this.appointmentservice.guestContact.findIndex(item => item.contactGuestId ===  e[0].guestId);
    this.appointmentservice.guestContact.splice(indexToUpdate,1);
    }
    let index=this.guestIds.indexOf(e[0].guestId);
    if (index !== -1) {
      this.guestIds.splice(index, 1);
    }
    this.tableDataTemp = this.tableDataTemp.filter(t=>t.guestId != e[0].guestId);
    this.bindTable();
    this.appointmentservice.isContactRelationUpdateRequired=true;
    this.appointmentservice.guestContactFormGrp.markAsDirty();
    this.appointmentservice.guestContactFormGrp.markAsTouched();
    this.appointmentservice.isContactRelationUpdateRequired=true;
  }
    
  onCancel(){
    this.relationList = _.cloneDeep(this.originalRelationList);
    this.isEdit = !this.isEdit;
    this.onEditDisableFunction = false;
    this.ResetForm();
  }
  ResetForm(){
    this.addButton.label = this.captions.btn_add;
    this.addButton.disabledproperty = true;
    this.addButton = {...this.addButton};
    this.relationshipForm.reset();
    this.currentId =0;
    this.disableUpdateButton = false;
  }
  
  closeWarning(){
    this.showClose = false;
  }
  selectedTagEmit(e){
    this.tagArr=e;
    this.selectedClientInfo = e;
    let lastAddedClient = this.selectedClientInfo[0];
      let clientFormatedName: string = lastAddedClient.firstName + " " + lastAddedClient.lastName;
      this.clientName = clientFormatedName;
      this.clientFirstName=lastAddedClient.firstName;
      this.clientLastName=lastAddedClient.lastName;
      this.clientPhone=lastAddedClient.phoneNumber;
      this.clientEmail=lastAddedClient.emailId;
      this.contactClientId = lastAddedClient.guestId;
      this.appointmentservice.currentClient=this.tagArr;
  }
  onGuestChipRemoveEvent($eve) {
    this.ResetForm();
  }
  
  tagSearchTextEmit(e){
  if(e.length>=3){
  this.makeSearchCall(e,6,'');
  this.searchText=e;
  }
  }
  
  onBlur(event: any)
    {

    }
}
