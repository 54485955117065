import { Component, OnInit, ViewEncapsulation,Input, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ButtonValue,DropdownOptions } from 'src/app/common/Models/ag-models';
import { environment } from 'src/environments/environment';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JasperServerCommonDataService } from 'src/app/common/dataservices/jasperServerCommon.data.service';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';

@Component({
  selector: 'app-jasper-report-popup',
  templateUrl: './jasper-report-popup.component.html',
  styleUrls: ['./jasper-report-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JasperReportPopupComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder,
     private propertyInfo:CommonPropertyInformation,
     public storageservice:StorageService,
     private utils: CommonUtilities,private http: HttpClient,
     @Inject(MAT_DIALOG_DATA) public data,
     private dialogRef: MatDialogRef<JasperReportPopupComponent>,
     private jasperServerCommon: JasperServerCommonDataService
     ) 
  { 
    this.reportUrlPath = data.reportUrlPath;
    this.sanitizedreportUrl = this.reportURL;
  }
  jaspersoftReport?: boolean = false;
  reportUrlPath : string;
  

  title = 'JasperReportsApp';
  sanitizedreportUrl: SafeResourceUrl = "";
  jasperReportForm: UntypedFormGroup;
  baseURL : string = "";
  reportResource : string = "";  
  reportParams = "?_flowId=viewReportFlow&standAlone=true&decorate=no"
  authentication : string = "";
  reportURL : string = "";

  async ngOnInit() {
    let jasperBaseUri = sessionStorage.getItem("jaspersofturi").toString();
    this.baseURL = jasperBaseUri != "" || jasperBaseUri != undefined ? jasperBaseUri : await this.jasperServerCommon.GetJasperServerBaseURL();
    this.onAction();
  }

  ngAfterViewInit(){ }
  FormURL(){
    this.reportResource = "&reportUnit="+ this.reportUrlPath;
    return this.baseURL + "/flow.html" + this.reportParams + this.reportResource;
  }

  onAction()
  {  
    var authtokenprovider = this.storageservice.getstoragekey('authtokenProvider');
    
    if(!authtokenprovider)
      authtokenprovider = "oauth2";
    

    let reportURL = this.FormURL();  
    var xhr = new XMLHttpRequest();
     xhr.withCredentials = true;
     xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) 
        {
           var myIframe = document.querySelector('#jasperiframe');
            myIframe['src'] = reportURL;
        }
      });

    var data = "";
    xhr.open("GET", this.baseURL + "/rest_v2/serverInfo");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("pp", sessionStorage.getItem("_jwt"));
    xhr.setRequestHeader("tokenProvider",authtokenprovider);
    xhr.send(data);
  }

  close() {
        this.dialogRef.close();
  }
}
