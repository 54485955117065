import { Component, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ButtonValue, ReturnButtonValue } from '../../retail.modals';
@Component({
  selector: 'app-shop-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnChanges {
  @ViewChild('dynamicbutton', { read: true }) dynamicbutton: ElementRef;
  @Input() buttontype: ButtonValue;
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  constructor(public _EL: ElementRef) {}


  @Output() valueChange = new EventEmitter();

  handleclick(e: ReturnButtonValue): void {
    this.valueChange.emit(e);
  }
  
  /**
   * @method function;
   * @function ngOnChanges;
   * @description change the input value
   */
  ngOnChanges() {
    console.log('ngOnChanges');
  }
}
