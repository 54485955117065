import { Pipe, PipeTransform } from '@angular/core';
import { AgilysysButtonModel } from '../interface/button-interface';
@Pipe({name: 'agilysysbutton'})
export class AgilysysButtonIconPipe implements PipeTransform {
    ifButtonWithIcon: boolean;
    transform(value: AgilysysButtonModel): boolean {
        this.ifButtonWithIcon = false;
        if (value.hasOwnProperty('icon')) {
            this.ifButtonWithIcon = true;
        }
        return this.ifButtonWithIcon;
      }
}