import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopModel, SuggestQuantityPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { FormType } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';
import { ConversionAlgorithm } from 'src/app/eatecui/source/shared/algorithms';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const PoMiscellanuousCharge: PostPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    Result: PostPOSupplierConfig,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId: number,
    selectedSeqNo?: number,
    analysisRedirect ?: any,
    commonService?: CommonService): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ItemMiscCharges')) {
            const EntityName = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.EntityName;
            const Url = `/inventory/api/${EntityName}?$filter=IsActive eq true`;
            httpService.GethttpMethod(Url).subscribe(res => {
                const Response = res['value'];
                const FormFields = [];
                Response.forEach(element => {
                    const AssignedValue = homeGroup.value['TransactionMiscCharges'] ?
                    homeGroup.value['TransactionMiscCharges'].filter(x => x.MiscChargeId === element.Id)[0] : '';
                    const Value = AssignedValue ? AssignedValue.Amount : '';
                    const FormObject: FormType = {
                        type: 'number',
                        class: '',
                        fieldFormaters: {
                            EnableDecimalFormatter: true,
                            WholeNumberLength: commonService.GetWholeNumberLength(true),
                            VisibleDecimal: CommonService.GetCurrencyVisible(),
                            FocusDecimal: commonService.GetFocusNumberLength(true)
                        },
                        fieldType: {
                            SingleData: {
                                name: element.Id,
                                label: element.Name,
                                value: Value,
                                FieldValidation: [
                                    { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'},
                                    { validation: CustomValidator.checkMinusPlusFormat , validationMessage: 'Enter Correct Format', key: 'checkMinusPlusFormat'}
                                ],
                            }
                        }
                    };
                    FormFields.push(FormObject);
                });
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.FormFields = FormFields;
                const DataInfo = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges;
                const SuggestQtyDialog: SuggestQuantityPopUpModel = {
                    PopupHeaderContent: DataInfo.PopupHeaderName,
                    FormFields: DataInfo.FormFields,
                    FormGroupData: DataInfo.FormGroupData,
                    Classname: 'misc-charges'
                };
                const dialogRef = dialog.open(AgilysysSuggestqtyPopupComponent, {
                    width: '87%',
                    height: '80vh',
                    data: SuggestQtyDialog,
                    maxWidth: '100vw',
                    panelClass: 'misc-charges-popup',
                    hasBackdrop: true,
                    backdropClass: 'detail-expand-backdrop-class',
                    position: {
                        right: '0'
                    },
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result[0] === 'Confirm') {
                        const FormData = result[1] as FormGroup;
                        const ReturnData = aasignPostObject(FormData, selectedId, homeGroup.value.TransactionMiscCharges);
                        homeGroup.controls[DataInfo.Name].setValue(ReturnData);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData = ReturnData;
                        receivingEstimationTotal(ReturnData,
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData,
                        transactionService, TransactionCollection, selectedTransaction);
                      }
                });
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred PoMiscellanuousCharge', Error);
    }
};

function receivingEstimationTotal(
_rowData: any, GridRowData,  transactionServices: TransactionService,
transactionConfigurationData: TransactionConfiguration, selectedTransaction) {
  // Commented below code for using Algorithms Interface //

  // const total = transactionServices.calculateReceivingTotal(GridRowData, _rowData, 0, selectedTransaction);
  const total = ConversionAlgorithm.allocateTotal( _rowData, GridRowData, null,
     selectedTransaction, transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData);
  // 0 for Allocated Charges Hardcoded (If AllocatedCharges is undefined => Not going into calculateReceivingTotal method)
transactionConfigurationData.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailFormHeader[1].Value =
Formater('Valued', total, null, null);
// transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData[0].Valued = total;
}
function aasignPostObject(FormData: FormGroup, selectedId: any, MiscCharges: Array<any>) {
    try {
        const TransactionMiscCharges = [];
        for (const key in FormData.value) {
            if (FormData.value.hasOwnProperty(key) && FormData.value[key]) {
                if (selectedId > 0 && MiscCharges) {
                    const index = MiscCharges.findIndex(x => x.MiscChargeId.toString() === key.toString());
                    if (index !== -1) {
                        MiscCharges[index].Amount = parseFloat(FormData.value[key]);
                        TransactionMiscCharges.push(MiscCharges[index]);
                    } else {
                        const MiscCharge = {
                            Id: 0,
                            MiscChargeId: parseInt(key, 10),
                            Amount: parseFloat(FormData.value[key] ? FormData.value[key] : '0')
                        };
                        TransactionMiscCharges.push(MiscCharge);
                    }
                } else {
                    const MiscCharge = {
                        Id: 0,
                        MiscChargeId: parseInt(key, 10),
                        Amount: parseFloat(FormData.value[key] ? FormData.value[key] : '0')
                    };
                    TransactionMiscCharges.push(MiscCharge);
                }
            }
        }
        return TransactionMiscCharges;
    } catch (error) {
        console.error('Error occurred in aasignPostObject', error);
    }
}
