export enum FormFieldType {
    TEXT = 'text',
    DROPDOWN = 'dropdown',
    CHECKBOX = 'checkbox',
    DATEPICKER = 'datepicker',
    RADIO = 'radio',
    SWITCH = 'switch',
    TOGGLEGROUP = 'toggleGroup',
    GROUPHORIZONTAL = 'groupedhorizontal',
    CUSTOMROW = 'customrow',
    GROUPED = 'grouped',
    TIMEPICKER = 'timePicker',
    TEXTAREA = 'textarea',
    NUMBER = 'number',
    CHECKBOXSINGLE = 'checkboxsingle',
    PICTURE = 'dragdrop',
    HIDDEN = 'hidden',
    MULTISELECT = 'multiselectdropdown',
    PASSWORD = 'password',
    MULTIAUTOCOMPLETE = 'multipleautocomplete',
    CHIPLIST = 'chiplist',
    LABELWITHICON = 'labelwithicon'
  }

  export enum FieldCustomValidationTypes {
    ComparisonField =  'ComparedFieldValidation'
  }
