import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { ImportBusiness } from '../import.business';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-download-template',
  templateUrl: './download-template.component.html',
  styleUrls: ['./download-template.component.scss'],
  providers: [ImportBusiness],
})
export class DownloadTemplateComponent implements OnInit {
  downloadTemplateForm: UntypedFormGroup;
  header: string;
  captions;
  checkBoxOptions;
  checkBoxOptions$;
  searchText: string;
  isAllSelected = false;
  downloadButton: ButtonValue;
  filesNotFoundErrorCode: string = '950100106';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<any>,
    private localization: Localization, private business: ImportBusiness, private utils: CommonUtilities) {
  }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.buildForm();
    this.initializeInputs();
  }

  async initializeInputs() {
    this.header = this.data.from == 'file-import' ? this.captions.pg_title_importTemplate : this.captions.pg_title_downloadTemplate;
    this.downloadButton = {
      label: this.data.from == 'file-import' ? this.captions.lbl_importbtn : this.captions.btn_download,
      type: 'primary',
      disabledproperty: true
    };

    if (this.data.from == 'file-import') {
      this.checkBoxOptions = await this.business.getFilesImportList(this.data.isFastTrack);
      if (this.data.isFastTrack) {
        this.isAllSelected = true;
        this.downloadButton.disabledproperty = false;
      }
    } else {
      this.checkBoxOptions = this.business.getCheckBoxList(this.data);
    }
    this.checkBoxOptions$ = this.checkBoxOptions;
  }

  buildForm() {
    this.downloadTemplateForm = this.fb.group({
      withData: '',
    })
  }

  close(e) {
    this.dialogRef.close({
      dataUtilityDetails: null
    });
  }

  searchValueChange(e) {
    this.searchText = e;
    this.checkBoxOptions = Object.assign([], this.checkBoxOptions$).filter(
      item => item.value.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
    if (this.checkBoxOptions.length == 0)
      this.checkBoxOptions = [];
  }

  enableButton() {
    let flag = this.checkBoxOptions.filter(x => x.checked).length > 0 ? true : false;
    this.downloadButton.disabledproperty = !(this.downloadTemplateForm.dirty && flag);
  }

  onAllCheck(e: { checked: boolean; }) {
    this.checkBoxOptions.forEach(x => {
      x.checked = e.checked;
    });
    this.checkBoxOptions = [...this.checkBoxOptions];
    this.isAllchecked();
    this.enableButton();
  }

  isAllchecked() {
    this.downloadTemplateForm.markAsDirty();
    const isEverychecked = this.checkBoxOptions.every(x => x.checked);
    this.isAllSelected = isEverychecked;
    const fiteredStatus = this.checkBoxOptions.filter(x => x.checked);
    this.enableButton();
  }

  statusChange(event, obj, i) {
    this.downloadTemplateForm.markAsDirty();
    this.checkBoxOptions[i].checked = event.checked;
    this.checkBoxOptions = [...this.checkBoxOptions];
    this.isAllchecked();
    this.enableButton();
  }

  async download() {
    this.downloadButton.disabledproperty = true;
    if (this.data.from == 'file-import') {
      this.business.PostDataFilesForBulkImport(this.checkBoxOptions$.filter(x => x.checked),
        this.data.isFastTrack).then(result => {
          this.dialogRef.close({
            dataUtilityDetails: result
          });
        })
        .catch(exception => {
          if (exception.error.errorCode == this.filesNotFoundErrorCode) {
            let errorCaption = this.localization.captions.common.bulkImportfilesNotFoundCaption + ": " + exception.error.result;
            this.utils.showError(errorCaption);
          } else {
            this.showBusinessError(exception.error.errorCode);
          }
        });
    }
  }

  private showBusinessError(errorCode: string) {
    let code: number = parseInt(errorCode);
    let message: string = this.localization.getError(code);
    this.utils.showError(message);
  }
}
