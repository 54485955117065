import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { PostedPOPopUp } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilysysPostedPoComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-posted-po/agilysys-posted-po.component';
import { UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { CreateTransaction } from '.';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const PostPOSupplierConfiguration: PostPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    Result: PostPOSupplierConfig,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog, selectedId = null, selectedSeqNo = null, analysisRedirect = null): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig) {
            console.log('Overall Grid Data', transactionService.poCloneGridData);
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = transactionService.poCloneGridData;
            const postSuppliers = CreateTransaction.PostPOSuppliers(Result, TransactionCollection, homeGroup, 
                transactionService, postModelHederEntity, postModelDetailEntity, HttpMethods.Post, GlobalOdataModel, httpService);          
            console.log('POPostSuppliers', postSuppliers);
            const postData = {
                POPostSuppliers: postSuppliers
            };
            const EndPointUrl = `/inventory/api/TransactionPurchaseOrder`;
            httpService[HttpMethods.Post](EndPointUrl, postData).subscribe(x => {
                console.log('After http call', x);
                const ProcessOptions = JSON.parse(sessionStorage.getItem('ProcessingOptions'));
                const ResultData = x.Data.resultEntity.POPostSuppliers;
                const ErrorMessages = x.errorMsg.length > 0 ? x.errorMsg.filter((value, index) => x.errorMsg.indexOf(value) === index) : [];
                const AllSuccessSuppliers = ResultData.every(sup => sup.IsValidEntry === true);
                // if (ProcessOptions && ProcessOptions['ShowDialogue']) {
                const PostedPODetails = [];
                const MailAttachmentRequest = {
                    TransType: 2,
                    Details: []
                };
                ResultData.forEach(element => {
                    const Index = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                        ProcessPoConfig.SupplierList.findIndex(y => y.SuplierId === element.SupplierId);
                    let sum = 0;
                    const PoItemTotals: any = element.PurchaseOrderHead.PurchaseOrderDetails.map(e => {
                      sum = sum + (e.Valued ? parseInt(e.Valued, 10) : 0);
                      return sum;
                    });
                    const PoItem = PoItemTotals[PoItemTotals.length  - 1];
                    const SupplierName = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                        ProcessPoConfig.SupplierList[Index].SuplierName;
                    const ErrorMsg = '';
                    const ErrorArray = [];
                    if (!element.IsValidEntry) {
                        ErrorMessages.forEach(elements => {
                            let Data = elements.toString().split(element.SupplierId);
                            Data = Data[1] ? Data[1].split(':-') : '';
                            ErrorArray.push(Data[Data.length - 1]);
                        });
                    }
                    // const seqNo = element.PurchaseOrderHead.TransSequenceNo != null && element.PurchaseOrderHead.TransSequenceNo >= 0
                    //     ? element.PurchaseOrderHead.TransSequenceNo : element.PurchaseOrderHead.Id;
                    const seqNo = element.PurchaseOrderHead.Id;

                    const INICurrencyValue = sessionStorage.getItem('INICurrencyValue');
                    const VisibleDigit = INICurrencyValue ? parseInt(INICurrencyValue, 10) :
                    JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberCurrencyLength;
                    const BaseCurrency= parseInt(sessionStorage.getItem('BaseCurrency'), 10);                  
                    const CurrencyCode = CommonService.getCurrencyCode(BaseCurrency);
                    const RawValue = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: CurrencyCode,
                        minimumFractionDigits: VisibleDigit,
                        maximumFractionDigits: VisibleDigit,
                        minimumIntegerDigits: 1
                    }).format(PoItem);
                    const returnValue = CommonService.SetCurrencySymbolInUtility(CurrencyCode, RawValue);                  

                    const Id = {
                        'Purchase Order#': seqNo !== 0 ? seqNo : '-',
                        'Supplier': SupplierName,
                        'Values': returnValue,
                        'Remarks': element.IsValidEntry ? element.ResponseMessage :
                            `${ErrorArray.join()}`
                    };
                    PostedPODetails.push(Id);
                    if (element.IsValidEntry && element.PurchaseOrderHead.DoMail) {
                        MailAttachmentRequest.Details.push({ Id: element.PurchaseOrderHead.Id });
                    }
                });

                // Commented for Email attachment post 184344
                // if (MailAttachmentRequest.Details.length > 0) {
                //     httpService[HttpMethods.Post]('/ReportData/api/EmailAttachment', MailAttachmentRequest).subscribe();
                // }

                const PostedPO: PostedPOPopUp = {
                    PopupHeaderContent: 'Posted Purchase Orders',
                    RowData: PostedPODetails
                };
                const dialogRef = dialog.open(AgilysysPostedPoComponent, {
                    width: '50%',
                    height: '50%',
                    data: PostedPO,
                    autoFocus: false,
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'Ok') {
                        console.log('Result', result);
                        const SupplierListLength = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                            ProcessPoConfig.SupplierList.length;
                        if (AllSuccessSuppliers && ResultData.length === SupplierListLength) {
                            let transActionFrom = { queryParams: { transaction: selectedTransaction } };
                            let transActionrouterUrl = `/settings/enhancedInventory/transaction/view-${selectedTransaction.toLowerCase()}`;
                            if ( analysisRedirect && analysisRedirect !== null ) {
                                transActionFrom = { queryParams: analysisRedirect };
                                if (analysisRedirect?.isFromPlan) {
                                    transActionrouterUrl = `sales-management/copynew-${analysisRedirect?.isFromPlan}`;
                                } else {
                                    transActionrouterUrl = 'inventory-management/analysis';
                                }
                            }
                            routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
                            sessionStorage.removeItem('ProcessingOptions');
                        } else {
                            const SuccessSupplierIds = [];
                            ResultData.forEach(supp => {
                                if (supp.IsValidEntry) {
                                    SuccessSupplierIds.push(supp);
                                }
                            });
                            SuccessSupplierIds.forEach(y => {
                                const index = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                                    ProcessPoConfig.SupplierList.findIndex(z => z.SuplierId === y.SupplierId);
                                if (index !== -1) {
                                    TransactionCollection.CreateTranscationConfig.ItemsConfig.
                                        ProcessPoConfig.SupplierList.splice(index, 1);
                                }
                                transactionService.poCloneGridData = transactionService.
                                    poCloneGridData.filter(sup => sup.SupplierId !== y.SupplierId);
                            });
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SelectedSupplierId =
                                TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList[0].SuplierId;

                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
                                transactionService.poCloneGridData.filter(z => z.SupplierId ===
                                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SelectedSupplierId);
                        }
                        if (TransactionCollection.AttachedFilesConfig) {
                            ResultData.forEach(podata => {
                                if (podata.IsValidEntry) {
                                    const fileAttachedData: Array<UploadMetaData> = TransactionCollection.AttachedFilesConfig.
                                        AttachMentConfig.FileAttachedData;
                                    if (fileAttachedData && fileAttachedData.length > 0) {
                                        fileAttachedData.forEach(req => {
                                            req.IsTransaction = true;
                                            req.ItemId = podata.PurchaseOrderHead.Id;
                                        });
                                        FileData.FileDataPost(fileAttachedData, httpService, toastrService);
                                    }
                                }
                            });
                        }
                    }
                });
                console.log('Posted PO', PostedPODetails);
                // }
                // else {
                //     const SupplierListLength = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                //         ProcessPoConfig.SupplierList.length;
                //     if (AllSuccessSuppliers && ResultData.length === SupplierListLength) {
                //         const SuccessSupplierIds = [];
                //         ResultData.forEach(supp => {
                //             SuccessSupplierIds.push(`#${supp.PurchaseOrderHead.Id}`);
                //         });
                //         const message = {
                //             Message: `${TransactionCollection.Name}(s) ${SuccessSupplierIds.join()} Posted Successfully`,
                //             Show: true,
                //             AutoClose: true,
                //             TimerCount: 20000
                //         };
                //         toastrService.success(message.Message, '', {
                //             timeOut: 3000,
                //             closeButton: true,
                //         });
                //         const transActionFrom = { queryParams: { transaction: selectedTransaction } };
                //         const transActionrouterUrl = `transaction/view-${selectedTransaction.toLowerCase()}`;
                //         routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
                //         sessionStorage.removeItem('ProcessingOptions');
                //     } else {
                //         const SuccessSupplierIds = [];
                //         ResultData.forEach(supp => {
                //             if (supp.IsValidEntry) {
                //                 SuccessSupplierIds.push(supp);
                //             }
                //         });
                //         if (SuccessSupplierIds.length > 0) {
                //             const SupplierNames = SuccessSupplierIds.map(y => {
                //                 const returnName = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                //                     ProcessPoConfig.SupplierList.filter(supp => supp.SuplierId === y.SupplierId)[0];
                //                 return returnName.SuplierName;
                //             });
                //             const message = {
                //                 Message: `${TransactionCollection.Name}(s) Posted Successfully for ${SupplierNames.join()} Supplier(s)`,
                //                 Show: true,
                //                 AutoClose: true,
                //                 TimerCount: 20000
                //             };
                //             toastrService.success(message.Message, '', {
                //                 timeOut: 3000,
                //                 closeButton: true,
                //             });
                //         }
                //         SuccessSupplierIds.forEach(y => {
                //             const index = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                //                 ProcessPoConfig.SupplierList.findIndex(z => z.SuplierId === y.SupplierId);
                //             if (index !== -1) {
                //                 TransactionCollection.CreateTranscationConfig.ItemsConfig.
                //                     ProcessPoConfig.SupplierList.splice(index, 1);
                //             }
                //             transactionService.poCloneGridData = transactionService.
                //                 poCloneGridData.filter(sup => sup.SupplierId !== y.SupplierId);
                //         });
                //         TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SelectedSupplierId =
                //             TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList[0].SuplierId;

                //         TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
                //             transactionService.poCloneGridData.filter(z => z.SupplierId ===
                //                 TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SelectedSupplierId);
                //         if (!AllSuccessSuppliers) {
                //             const popupModels: ErrorPopModel = {
                //                 PopupHeaderContent: 'Error',
                //                 PopUpContent: ErrorMessages.join()
                //             };
                //             dialog.open(AgilsysErrorPopComponent, {
                //                 width: '400px',
                //                 data: popupModels,
                //                 autoFocus: false
                //             });
                //         }
                //     }
                // }
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred PostPOSupplierConfiguration', Error);
    }
};
