import { Component, EventEmitter, Input,  Output } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RoomTypeAssignmentRetailItem } from '../../retail.modals';

@Component({
  selector: 'app-room-type-assignment',
  templateUrl: './room-type-assignment.component.html',
  styleUrls: ['./room-type-assignment.component.scss']
})
export class RoomTypeAssignmentComponent  {

  @Input() isEdit: boolean;
  @Input() roomTypes: RoomTypeAssignmentRetailItem[];
  @Output() roomTypeSelection: EventEmitter<RoomTypeAssignmentRetailItem[]> = new EventEmitter();
  captions: any;

  constructor(private localization: RetailLocalization) {
    this.captions = this.localization.captions.common;
  }

  selectroomTypes(arg, idx) {
    if (this.roomTypes[idx].roomTypeId === 0) {
      console.log(this.roomTypes[idx]);
      const isSelected = !this.roomTypes[idx].selected;
      this.roomTypes.forEach(x => {
        x.selected = isSelected;
      });
    }
    else {
      const index = this.roomTypes.findIndex(x => x.roomTypeId === 0);
      this.roomTypes[idx].selected =  !this.roomTypes[idx].selected;
      this.roomTypes[index].selected = this.roomTypes.filter(x => x.selected && x.roomTypeId !== 0).length ===
                                       this.roomTypes.filter(x =>  x.roomTypeId !== 0).length;
    }
    this.roomTypeSelection.emit(this.roomTypes.filter(x => x.selected));
  }

}
