import { Component, OnInit,  Output, EventEmitter } from '@angular/core';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { ReportDataService } from '../../../data/report-data.services';
import { UntypedFormGroup, UntypedFormBuilder} from '@angular/forms'; 
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ValidationMessage } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-inventory-audit',
  templateUrl: './inventory-audit.component.html',
  styleUrls: ['./inventory-audit.component.scss']
})
export class InventoryAuditComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  InventoryTransferFormGrp: UntypedFormGroup;
  validateOutlets: ValidationMessage = {
    dropDownName: this.localization.captions.reports.PleaseSelectAnyOutlet,
    validity: false
  };
  outlets: any[];
  selectedDropdownType: any;
  valuesSelected: any[];
  floatLabel:string;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, private dataservice: ReportDataService, public business: ReportBusinessService) {
    this.floatLabel = this.localization.setFloatLabel;
   }

  ngOnInit() {
    this.InventoryTransferFormGrp = this.fb.group({
      outlets: [],
      searchbyitem: '',
      searchbydesc: '',
      searchbysupplier: '',
      searchbybarcode: '',
      searchbyotherscannercode: '',
      searchbysupplieritem: '',
      purchaseorder: '',
      includeinactiveitems: false,
      includenoninventoryitems: false,
      category: new UntypedFormGroup({})
    });
    this.formReady.emit(this.InventoryTransferFormGrp);
    this.IsValidSelection.emit(this.validateOutlets);
    this.getOutlets();
  }

  async getOutlets() {
    this.outlets = await this.dataservice.getAllOutlets();
  }

  // filterDropDownSelected(event: MatSelectChange, data: DropDownData, allData: DropDownData[], dropDownType) {
  //   if (dropDownType !== this.selectedDropdownType) {
  //     this.valuesSelected = [];
  //   }
  //   if (data && data.description.toLowerCase() === 'all') {
  //     this.valuesSelected = this.business.toggleClickbtn(this.defaultData, allData, this.valuesSelected, this.defaultData);
  //   } else {
  //     this.valuesSelected = this.business.toggleClickbtn(data, allData, this.valuesSelected, this.defaultData);
  //   }
  //   this.InventoryTransferFormGrp.controls[dropDownType].setValue(this.valuesSelected);
  //   this.selectedDropdownType = dropDownType;
  // }

  formInitialized(form: UntypedFormGroup) {
    this.InventoryTransferFormGrp.setControl('category', form);
  }

  getFormControlValue(event) {
    if (event[0] === 'outlets') {
      this.InventoryTransferFormGrp.setControl('outlets', event[1]);
    }
  }

  validateOutlet(event, data: any): void {
    this.validateOutlets.validity = data !== null;
    this.IsValidSelection.emit(this.validateOutlets);
  }
}
