import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { COMPRESSION_LIMIT } from '../../constants';
import { remove } from "lodash";
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { MultiImageUpload } from 'src/app/shared/globalsContant';

@Component({
  selector: 'ag-spa-image-multi-upload',
  templateUrl: './ag-image-multi-upload.component.html',
  styleUrls: ['./ag-image-multi-upload.component.scss']
})

export class AgImageMultiUploadComponent implements OnInit {

  constructor(private imageCompress: NgxImageCompressService,private _Localization:SpaLocalization) { }
  file: any;
  uploadedfileName: string;
  imgResultAfterCompress: string;
  sizeOFCompressedImage:number;
  sizeOFCompressedthumbNail:number;
  maxImageUploaded:boolean=false;
  FileSizeExceeded:boolean=false;
  captions:any;
  imageData:string=null;
  thumbNailData:string=null;
  imageArray :MultiImageUpload[]=[];
  checkDuplicateFile:boolean;
  @ViewChild('inputFile', {static: false }) inputFile: ElementRef; 

  @Input() maxImage:number;
  @Input() disableControl;
  @Output() compressedImage = new EventEmitter();
  @Output() imageDeleted = new EventEmitter();
  @Output() fileSizeExceeded = new EventEmitter();

  @Input('imageInput')
  set imageInput(value) { 
    if(value && value.length > 0 ){
      if(value.length >= this.maxImage){
        this.imageArray = value.splice(0,5);
      }else{
        this.imageArray = value;
      }
      
      this.maxImageUploaded= this.imageArray.length >= this.maxImage  ? true: false;
    } 
  }

  selectFile(event: any) {
    this.file = event.target.files.length != 0 && event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (eve: any) => {
        const fileSize = this.imageCompress.byteCount(eve.target.result) / (1024);
        let checkDuplicate = this.imageArray.length > 0 && this.imageArray.find(x=> x.filename == this.file['name']);
        this.FileSizeExceeded=false; 
        if(!checkDuplicate){
          this.checkDuplicateFile = false;
          if(fileSize >= COMPRESSION_LIMIT){
            this.maxImageUploaded= this.imageArray.length  >= this.maxImage  ? true: false;
            this.FileSizeExceeded=true;
            if(this.inputFile) {
            this.inputFile.nativeElement.value = null;
            }
            this.fileSizeExceeded.emit();
          }else{
            let fullImageCompress = this.compressFile(eve.target.result, this.file['name']);
            let thumbNailImageCompress = this.compressThumbnail(eve.target.result);
            Promise.all([fullImageCompress,thumbNailImageCompress ]).then(_res => {
            this.imageArray.push({
                image:this.imgResultAfterCompress,
                imageSize: this.sizeOFCompressedImage,
                thumbnail:this.thumbNailData,
                thumbnailSize: this.sizeOFCompressedthumbNail,
                filename:this.uploadedfileName});
                this.maxImageUploaded= this.imageArray.length >= this.maxImage  ? true: false;
                this.inputFile.nativeElement.value = null;
              this.compressedImage.emit(this.imageArray);
            });
          }
        }else{
          this.checkDuplicateFile = true;
          if(this.inputFile) {
          this.inputFile.nativeElement.value = null;
          }
        }
      }
      
      reader.readAsDataURL(event.target.files[0]);
    }
  }

   compressFile(image, fileName) {
    var orientation = -1;
    return this.imageCompress.compressFile(image, orientation).then(result => {
      this.imgResultAfterCompress = result;
      this.FileSizeExceeded=false;
      this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024);
      const imageFile = new File([result], fileName, { type: 'image/jpeg' });
      this.uploadedfileName = imageFile['name'];      
    });
      
  }

   compressThumbnail(image){
    return this.imageCompress.compressFile(image, 1, 30, 30).then(result => {
      this.sizeOFCompressedthumbNail = this.imageCompress.byteCount(result) / (1024);
      this.thumbNailData =  result;
    });
    
  }

  deleteUpload(index){
    remove(this.imageArray, this.imageArray[index]);
    this.maxImageUploaded= this.imageArray.length >= this.maxImage ? true: false;
    this.FileSizeExceeded= false;
    this.imageData = null;
    this.checkDuplicateFile = false;
    if(this.inputFile) {
    this.inputFile.nativeElement.value = null;
    }
    this.imageDeleted.emit(this.imageArray);
  }

  ngOnInit() {
    this.captions = this._Localization.captions;
  }
}