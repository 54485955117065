import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ButtonData, single, multiple } from './multi-select-button.modal';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-spa-multi-select-button',
  templateUrl: './multi-select-button.component.html',
  styleUrls: ['./multi-select-button.component.scss']
})


export class MultiSelectButtonComponent implements OnInit, OnChanges {

  more: string;
  all: string;

  buttonData: ButtonData[];
  changedData: boolean;
  multiSelectFrmGrp: UntypedFormGroup;
  noOfButtons = 100;
  morepopover = true;

  @Input() data: ButtonData[];
  @Input() defaultSelected: ButtonData[] | [] | null;
  @Input() isEnableAll: boolean;
  @Input() title: string;
  @Input() type: string = multiple;
  @Output() buttonSelected: EventEmitter<any> = new EventEmitter();
  isAllDisabled: boolean;
  actionBtn: ButtonData[];
  allData: { id: number; name: any; description: string; };

  constructor(private formBuilder: UntypedFormBuilder, private _localization: SpaLocalization) { }
  captions: any;

  ngOnInit() {
    this.captions = this._localization.captions;
    this.allData = { id: 0, name: this.captions.lbl_all, description: 'All' };
  }

  ngOnChanges() {
    this.multiSelectFrmGrp = this.formBuilder.group({
      controlname: []
    });
    if (!this.data) {
      return;
    }
    this.buttonData = this.data;
    this.actionBtn = this.buttonData.filter(x => !x.disabled);
    this.multiSelectFrmGrp = this.formBuilder.group({
      controlname: this.defaultSelected ? [this.defaultSelected] : []
    });
    this.isAllDisabled = this.buttonData.every(x => x.disabled);
    this.buttonSelected.emit((
      this.multiSelectFrmGrp.controls.controlname
    ) as UntypedFormControl);
  }



  buttonSelectionChange(buttonData: ButtonData) {
    if (this.type === single) {
      this.multiSelectFrmGrp.controls.controlname.setValue(buttonData);
    } else if (this.type === multiple) {
      const data = this.multiSelectFrmGrp.controls.controlname.value
        ? this.multiSelectFrmGrp.controls.controlname.value
        : [];
      const index = data ? data.findIndex(res => res.id === buttonData.id) : -1;
      if (index !== -1) {
        const currentSelectedData = this.multiSelectFrmGrp.controls.controlname.value.filter(
          (res: ButtonData) => res.id !== buttonData.id
        );
        this.multiSelectFrmGrp.controls.controlname.setValue(
          currentSelectedData
        );
      } else if (index === -1) {
        data.push(buttonData);
        this.multiSelectFrmGrp.controls.controlname.setValue(data);
      }
      this.changedData = !this.changedData;
    }
    this.buttonSelected.emit(this.multiSelectFrmGrp.controls
      .controlname as UntypedFormControl);
  }

  allButtonSelectionChange(event) {
    if (event.id === 0) {
      if (
        (this.multiSelectFrmGrp.controls.controlname.value &&
          this.multiSelectFrmGrp.controls.controlname.value.length ===
          this.buttonData.length) ||
        (this.multiSelectFrmGrp.controls.controlname.value &&
          this.multiSelectFrmGrp.controls.controlname.value.length ===
          this.actionBtn.length)
      ) {
        this.multiSelectFrmGrp.controls.controlname.setValue([]);
      } else if (
        !this.multiSelectFrmGrp.controls.controlname.value ||
        this.multiSelectFrmGrp.controls.controlname.value.length !==
        this.buttonData.length
      ) {
        this.multiSelectFrmGrp.controls.controlname.setValue(this.buttonData);
        this.changedData = !this.changedData;
      }
    }
    this.buttonSelected.emit(this.multiSelectFrmGrp.controls
      .controlname as UntypedFormControl);
  }

  noOfChipsEmit(noOfButtons) {
    this.noOfButtons = noOfButtons;
    this.morepopover = false;
  }
}
