import { Component, Inject, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetailExpandConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
@Component({
  selector: 'app-agilysys-detail-expand-popup',
  templateUrl: './agilysys-detail-expand-popup.component.html',
  styleUrls: ['./agilysys-detail-expand-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysDetailExpandPopupComponent implements OnInit, OnChanges {
  constructor(
    public dialogRef: MatDialogRef<AgilysysDetailExpandPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DetailExpandConfiguration
    ) {
        console.log(this.data);
  }
  

 

  getReturnFormValue(value: any) {
    console.log('sss');
  }

  ngOnChanges(): void {
    console.log('sss');
  }

  ngOnInit(): void {
    console.log('sss');
  }

}
