import { Action } from '@ngrx/store';

export enum EodataConfiguration {
    GetOdata = '[GetOdata] Get GetOdata',
    GetOdataSucess = '[GetOdataSucess] Get GetOdataSucess',
    GetInventoryOdata = '[GetInventoryOdata] Get GetInventoryOdata',
    GetInventoryOdataSucess = '[GetInventoryOdataSucess] Get GetInventoryOdataSucess'
}

export class GetOdata implements Action {
    readonly type = EodataConfiguration.GetOdata;
    constructor(public payload: any) { }
}
export class GetOdataSucess implements Action {
    readonly type = EodataConfiguration.GetOdataSucess;
    constructor(public payload: any) { }
}

export class GetInventoryOdata implements Action {
    readonly type = EodataConfiguration.GetInventoryOdata;
    constructor(public payload: any) { }
}
export class GetInventoryOdataSucess implements Action {
    readonly type = EodataConfiguration.GetInventoryOdataSucess;
    constructor(public payload: any) { }
}

export type OdataAction = GetOdata | GetOdataSucess | GetInventoryOdata | GetInventoryOdataSucess;
