import { MasterModuleExitPopup } from 'src/app/eatecui/source/shared/module-exit-comparison/master-module-exit';
import { ModuleExitPopup } from 'src/app/eatecui/source/shared/module-exit-comparison/module-exit';
import { AllocateChargesAlgorithm } from './allocateChargesAlgorithm';
import { EstimationTotalAlgorithm } from './estimationTotalAlgorithm';
import { MiscTotalAlgorithm } from './miscTotalAlgorithm';
import { SalesTaxAlgorithm } from './salesTaxAlgorithm';
import { PrinterAlgorithm } from './printerAlgorithm';
import { QuickOrderAlgorithm } from './quickorder-algorithm.interface';
import { receivingTaxAlgorithm } from './receivingTaxCalculation.interface';

export const ConversionAlgorithm = {
  estimationTotal: EstimationTotalAlgorithm,
  miscTotal: MiscTotalAlgorithm,
  allocateTotal: AllocateChargesAlgorithm,
  exitModule: ModuleExitPopup,
  masterExitModule: MasterModuleExitPopup,
  salesTaxAlgorithms: SalesTaxAlgorithm,
  quickorderAlgorithm: QuickOrderAlgorithm,
  Printer: PrinterAlgorithm,
  receivingTaxAlgoithm: receivingTaxAlgorithm
};
