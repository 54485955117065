import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-icon-renderer',
  templateUrl: './icon-renderer.component.html',
  styleUrls: ['./icon-renderer.component.scss']
})
export class IconRendererComponent implements OnInit {

  IsActive = false;
  toolTipValue: string;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    this.IsActive = params.value;
    this.toolTipValue = params.value === true ? 'Active' : 'Inactive';
  }

}
