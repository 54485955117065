import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { BaseReport } from "../common/base-report";
import { AllReports, ReportAPIOptions, ReportParams, ReportUIConfig } from "../report.modals";


export class BatchClientItinerary extends BaseReport{
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public patcheableValue){
        super(reportFormGrp);
    }
    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: null,
            inActiveToggle: true,
            inActiveToggleName: this.captions.ExcludeAppointmentComments,
            pageBreakToggle: false,
            layout: "PORTRAIT",
            disableGenerateButton: false
        };
    }
    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.BatchClientItinerary,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }
    protected formReportParams(): ReportParams[] {
       return [{"pDate": this.printedDate}, {"pExcludeAppointmentComments": this.reportFormGrp.value.includeInactiveusers},{"pPropertyName":this.propertyName}];
    }
    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;              
        return [{ "startDate": toApi(this.startDate) }, { "endDate": toApi(this.endDate) }];
    }
    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
            return JSON.stringify({
                fromDate: toApi(this.formGrp.get('startDate').value),
            	toDate: toApi(this.formGrp.get('endDate').value),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            });  
        }
        return [];
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);            

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
            
        }, 1);
    }
    

}