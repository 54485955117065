import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-textbox-with-icon',
  templateUrl: './textbox-with-icon.component.html',
  styleUrls: ['./textbox-with-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextboxWithIconComponent implements OnInit, ICellEditorAngularComp {
  textValue: any;
  params: any;

  constructor() { }

  ngOnInit(): void {
    console.log('NgOnInit');
  }

  agInit(params) {
    console.log('Text Editor with icon', params);
    this.textValue = params.value;
    this.params = params;
  }
  init(params) {
    this.params = params;
  }

  getValue(): any {
    console.log('Changed data', this.textValue);
    return this.textValue;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  textChanged() {
    console.log('Text Changed', this.textValue);
    this.params.context.parentComponent.lookupPopupOpen(this.params);
    this.params.api.stopEditing();
  }

}
