import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ChangeDetectorRef  } from '@angular/core';
import { passwordMgmt, SettingsGrid } from '../../shared/business/view-settings.modals';
import { GridType, GridOperationType, Host, PromptType, SPAManagementBreakPoint, RetailBreakPoint } from '../../shared/globalsContant';
import { ViewSettingClientBusiness } from '../../shared/common-functionalities/business/view-settings.business';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { UserAlerts } from '../../core/config/alerts-config';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { UserBreakPoint, BaseResponse, popupConfig } from '../../shared/business/shared.modals';
import { TableComponent } from '../../shared/table/table.component';
import { SubscriptionLike as ISubscription, ReplaySubject } from 'rxjs';
import { SettingDialogPopupComponent } from '../setting-dialog-popup/setting-dialog-popup.component';
import { UntypedFormControl } from '@angular/forms';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { SettingsService } from '../settings.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-utilities',
	templateUrl: './utilities.component.html',
	styleUrls: ['./utilities.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UtilitiesComponent implements OnInit, AfterViewInit {
	//any
	locObj: any = this.localization.captions.utilities;
	tableoptions: any;
	ArrayList = [];
	selectedTableRows = [];
	equipmentMaintenanceData: any = [];
	locationMaintenanceData: any = [];
	GridType = GridType;
	utilityBreakPoints;
	isClassView:string='false';
	utilities = [
		{ id: GridType.equipmentMaintenance, viewValue: this.locObj.EquipmentMaintenance, callDesc: "GetAllEquipmentMaintenance", breakPointNumber: SPAManagementBreakPoint.EquipmentMaintenance, IsAuthorized: true },
		{ id: GridType.locationMaintenance, viewValue: this.locObj.LocationMaintenance, callDesc: "GetAllLocationMaintenance", breakPointNumber: SPAManagementBreakPoint.LocationMaintenance, IsAuthorized: true },
		{ id: GridType.therapistUnavailability, viewValue: this.locObj.TherapistUnAvailability, callDesc: "getTherapistUnavailability", breakPointNumber: SPAManagementBreakPoint.TherapistUnavailability, IsAuthorized: true },
		{ id: GridType.multipleAppointments, viewValue: this.locObj.MoveMultipleAppointments, callDesc: "", breakPointNumber: SPAManagementBreakPoint.MoveMultipleAppointments, IsAuthorized: true },
    { id: GridType.recieptConfiguration, viewValue: this.locObj.ReceiptConfiguration, breakPointNumber: RetailBreakPoint.ReceiptConfiguration, IsAuthorized: true },
    { id: GridType.userMachineConfiguration, viewValue: this.locObj.UserMachineConfiguration, callDesc: "GetUserSessionConfiguration", breakPointNumber: RetailBreakPoint.UserSessionConfiguration, IsAuthorized: true },
    { id: GridType.templates, viewValue: this.locObj.Templates, callDesc: "", breakPointNumber: RetailBreakPoint.UserSessionConfiguration, IsAuthorized: true },
    { id: GridType.distributionList, viewValue: this.locObj.DistributionList, callDesc: "", breakPointNumber: RetailBreakPoint.UserSessionConfiguration, IsAuthorized: true },
	{ id: GridType.combineGuestRecords, viewValue: this.locObj.CombineGuestRecords, callDesc: "", breakPointNumber: SPAManagementBreakPoint.CombineGuestRecords, IsAuthorized: true },
	{ id: GridType.dedupeGuestProfiles, viewValue: this.locObj.DedupeGuestRecordsComponent, callDesc: "", breakPointNumber: SPAManagementBreakPoint.DeDupeGuestRecords, IsAuthorized: true },
	{ id: GridType.inventorysync, viewValue: this.locObj.InventorySync, breakPointNumber: RetailBreakPoint.inventorySync, IsAuthorized: true }
	];


	//boolean
	isViewOnly: boolean;

	//number
	utilityId: number;
	multipleAppointmentsId: number = GridType.multipleAppointments;
	userMachineConfigurationId: number = GridType.userMachineConfiguration;
	therapistUnavailabilityId: number = GridType.therapistUnavailability;
	password: number = GridType.password;

	//FormControl
	historicBoolControl: UntypedFormControl;

	passMgmt: passwordMgmt[] = [{
		id: 1,
		status: true,
		ruleName: 'Override staff required at checkin'
	}, {
		id: 2,
		status: false,
		ruleName: 'Zero priced service'
	},
	{
		id: 1,
		status: true,
		ruleName: 'Last name changes '
	}, {
		id: 2,
		status: false,
		ruleName: 'Change service status'
	},
	{
		id: 1,
		status: true,
		ruleName: 'Oversell yielded package '
	}, {
		id: 2,
		status: false,
		ruleName: 'Override package request staff memebrs'
	}];

	//string
	PlaceHolder: string;
	ButtonName: string;
	DeleteButtonName: string;

	@ViewChild('selectUtilSetup') selectUtilSetup: MatSelect;
	@ViewChild('tableComponent') tableComponent: TableComponent;
	dialogSubscription: ISubscription;
	initialLoads = true;
	callCounter = 0;
  searchText: any;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
	constructor(private ViewSettingBusiness: ViewSettingClientBusiness, private userAlert: UserAlerts, public dialog: MatDialog,
		public localization: SpaLocalization, private utils: SpaUtilities, private ss: SettingsService,
		private http: HttpServiceCall, private breakPoint: BreakPointAccess, private _cdRef: ChangeDetectorRef, public propertyInfo: SpaPropertyInformation, private route: ActivatedRoute) {
			this.historicBoolControl = new UntypedFormControl(false);
			this.ss.tabLoaderEnable.next(true);
	}

	ngOnInit(): void {
		this.selectedTableRows = [];
		this.utilities = this.getSetupBreakPoints(this.utilities);
    this.utilityId = this.utilities[0].id; //Default DropDown
    this.route.data.pipe(takeUntil(this.$destroyed)).subscribe((data) => {
      this.utilityId = data.id; //Default DropDown
    });
    this.onUtilChange(true);
	}

	ngAfterViewInit() {
		this.onUtilChange();
		this._cdRef.detectChanges();
	}

	ngOnDestroy() {
		if (this.dialogSubscription) {
			this.dialogSubscription.unsubscribe();
		}
    this.$destroyed.next(true);
    this.$destroyed.complete();
	}

	deleteConfirm(event: any) {
		if (this.isViewOnly) {
			return;
		}
		const ids: any = [];
		ids.push(event[0].id);
		if (this.utilityId == GridType.locationMaintenance) {
			this.deleteRecords('DeleteLocationMaintenance', ids, this.utilityId, 'GetAllLocationMaintenance');
		} else if (this.utilityId == GridType.equipmentMaintenance) {
			this.deleteRecords('DeleteEquipmentMaintenance', ids, this.utilityId, 'GetAllEquipmentMaintenance');
		} else if (this.utilityId == GridType.therapistUnavailability) {
			this.deleteRecords('DeleteTherapistUnavailability', ids, this.utilityId, 'getTherapistUnavailability');
		}
	}

	multiDelete(resultFromPrompt: string) {
		if (resultFromPrompt != "ok") {
			return;
		}
		const ids: any = [];
		if (this.selectedTableRows.length == 1) {
			ids.push(this.selectedTableRows[0].id);
		} else {
			_.each(this.selectedTableRows, (row) => {
				if (_.includes(this.tableoptions[0].TablebodyData, row)) {
					ids.push(row.id);
				}
			});
		}
		if (this.utilityId == GridType.locationMaintenance) {
			this.deleteRecords('DeleteLocationMaintenance', ids, this.utilityId, 'GetAllLocationMaintenance');
		} else if (this.utilityId == GridType.equipmentMaintenance) {
			this.deleteRecords('DeleteEquipmentMaintenance', ids, this.utilityId, 'GetAllEquipmentMaintenance');
		} else if (this.utilityId == GridType.therapistUnavailability) {
			this.deleteRecords('DeleteTherapistUnavailability', ids, this.utilityId, 'getTherapistUnavailability');
		}
	}

	promptForDelete() {
		this.userAlert.showPrompt(PromptType.Delete, this.multiDelete.bind(this));
	}

	historicRecords(utilityId: number, $event?) {
		this.historicBoolControl.setValue($event[0]);

		if (utilityId == GridType.locationMaintenance) {
			this.filterLocationMaintenanceData(this.locationMaintenanceData, utilityId);
		} else if (utilityId == GridType.equipmentMaintenance) {
			this.filterEquipmentMaintenanceData(this.equipmentMaintenanceData, utilityId);
		}
	}
	radioChange(utilityId: number, isClass:any) {

		if (utilityId == GridType.locationMaintenance) {
			if(isClass.value == 'false')
				{
					this.getDataForGrid("GetAllLocationMaintenance", utilityId, true);
				}
				else{
					this.getDataForGridWithQueryString("GetAllLocationMaintenance", utilityId,{ key: "isClassLocation", value:  [true]}, true);
				}
		} else if (utilityId == GridType.equipmentMaintenance) {
			if(isClass.value == 'false')
			{
				this.getDataForGrid("GetAllEquipmentMaintenance", utilityId, true);
			}
			else{
				this.getDataForGridWithQueryString("GetAllEquipmentMaintenance", utilityId,{ key: "isClassEquipment", value:  [true]}, true);
			}
		}else if(utilityId ==GridType.therapistUnavailability){
			if(isClass.value == 'false')
				{
					this.getDataForGrid("getTherapistUnavailability", utilityId, true);
				}
				else{
					this.getDataForGridWithQueryString("getTherapistUnavailability", utilityId,{ key: "isInstructor", value:  [true]}, true);
				}
		}
	}

	rowSelected(selectedRows) {
		this.selectedTableRows = selectedRows;
	}

	onUtilChange($event?: any) {


		if($event)
			{
			this.historicBoolControl.setValue(false);
			this.isClassView='false';
			}
		let utilId: GridType; 
		let callDesc: string;
		let isInitial = true;
		if (this.tableComponent) {
			this.tableComponent.clearSearchText();
    }
    utilId = this.utilityId;
		callDesc = this.utilities.filter(x => x.id === utilId)[0].callDesc;
		[this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
		this.ArrayList = [];
		switch (utilId) {
			case GridType.password:
				this.PlaceHolder = '';
				const gridDS: SettingsGrid<any> = {
					dataheader: this.ViewSettingBusiness.getUtilitiesDataHeader(utilId),
					datacolumn: this.passMgmt
				};
				this.ArrayList.push(gridDS);
				this.populateTableInfo(false, false, utilId, '', 'ruleName', '');
				break;
			case GridType.locationMaintenance:
				this.ButtonName = this.locObj.CreateNew;
				this.DeleteButtonName = this.locObj.Delete;
				this.PlaceHolder = this.locObj.LocationSearchBy;
				if(this.isClassView=='true')
				{
					this.getDataForGridWithQueryString(callDesc, utilId,{ key: "isClassLocation", value:  [true]}, isInitial);
				}
				else
				{
					this.getDataForGrid(callDesc, utilId, isInitial);
				}
				break;
			case GridType.equipmentMaintenance:
				this.ButtonName = this.locObj.CreateNew;
				this.DeleteButtonName = this.locObj.Delete;
				this.PlaceHolder = this.locObj.EquipmentSearchBy;
				if(this.isClassView=='true')
				{
				this.getDataForGridWithQueryString("GetAllEquipmentMaintenance", utilId,{ key: "isClassEquipment", value:  [true]}, isInitial);
				}
				else
				{
				this.getDataForGrid("GetAllEquipmentMaintenance", utilId, isInitial);
				}
				break;
			case GridType.therapistUnavailability:
				this.ButtonName = this.locObj.CreateNew;
				this.DeleteButtonName = this.locObj.Delete;
				this.PlaceHolder = this.locObj.TherapistSearchBy;
				if(this.isClassView=='true')
				{
				this.getDataForGridWithQueryString(callDesc, utilId,{ key: "isInstructor", value:  [true]}, isInitial);
				}
				else
				{
				this.getDataForGrid(callDesc, utilId, isInitial);
				}
				break;
			case GridType.multipleAppointments:
				this.PlaceHolder = '';
				break;
			case GridType.userMachineConfiguration:
			this.PlaceHolder = '';
			break;
			default:
				break;
		}
	}

	EditRowRecords(event: Array<any>): void {
		const popUpbelonTo: GridType = event[1];
		this.openEditDialog(event[0], popUpbelonTo, event[2]);
	}

	openEditDialog(event, belonTo: number, preTit: string): void {
		let popupConfiguration: popupConfig;
		if (preTit.toLowerCase() == "Edit".toLowerCase()) {
			popupConfiguration = {
				operation: preTit.toLowerCase(),
				GridOperationType: GridOperationType.Edit,
				isClassView: this.isClassView=='true'?true:false
			};
		} else {
			popupConfiguration = {
				operation: preTit.toLowerCase(),
				GridOperationType: GridOperationType.Create
			};
		}
		this.triggerPopup(event, belonTo, preTit, popupConfiguration);
	}

	filterEquipmentMaintenanceData(data: any, utilId: number) {
		this.ArrayList = [];
		const equipmentMaintenance: SettingsGrid<any> = {
			dataheader: this.ViewSettingBusiness.getUtilitiesDataHeader(utilId),
			datacolumn: data.filter(x => {
				if (this.historicBoolControl.value) {
					return true;
				}
				return this.utils.GetDateWithoutTime(x.endTime).getTime() >= this.utils.GetDateWithoutTime(this.propertyInfo.CurrentDate).getTime();
			}).map(x => {
				return {
					id: x.id, equipmentCode: x.equipmentCode, equipmentDescription: x.equipmentDescription,
					equipmentId: x.equipmentId, comments: x.comments,
					beginAt: this.localization.LocalizeShortDateTime(x.startTime),
					endAt: this.localization.LocalizeShortDateTime(x.endTime),
					startTime: x.startTime,
					endTime: x.endTime
				};
			})
		};
		this.ArrayList.push(equipmentMaintenance);
		this.populateTableInfo(false, false, utilId, '', 'beginAt', '', true);
	}

	filterLocationMaintenanceData(data: any, utilId: number) {
		this.ArrayList = [];
		const locationMaintenance: SettingsGrid<any> = {
			dataheader: this.ViewSettingBusiness.getUtilitiesDataHeader(utilId),
			datacolumn: data.filter(x => {
				if (this.historicBoolControl.value) {
					return true;
				}
				return this.utils.GetDateWithoutTime(x.endTime).getTime() >= this.utils.GetDateWithoutTime(this.propertyInfo.CurrentDate).getTime();
			}).map(x => {
				return {
					id: x.id, locationCode: x.locationCode, locationDescription: x.locationDescription,
					locationId: x.locationId, comments: x.comments,
					beginAt: this.localization.LocalizeShortDateTime(x.startTime),
					endAt: this.localization.LocalizeShortDateTime(x.endTime),
					startTime: x.startTime,
					endTime: x.endTime
				};
			})
		};
		this.ArrayList.push(locationMaintenance);
		this.populateTableInfo(false, false, utilId, '', 'beginAt', '', true);
	}

	getDataForGrid(callDesc, utilId, isInitial?) {
		this.http.CallApiWithCallback<any>({
			host: Host.spaManagement,
			callDesc: callDesc,
			method: HttpMethod.Get,
			showError: false,
			success: this.successCallback.bind(this),
			error: this.errorCallback.bind(this),
			extraParams: [utilId, isInitial]
		});
	}

	getDataForGridWithQueryString(callDesc, utilId,keyValue, isInitial?) {
		
		this.http.CallApiWithCallback<any>({
			host: Host.spaManagement,
			callDesc: callDesc,
			method: HttpMethod.Get,
			showError: false,
			success: this.successCallback.bind(this),
			error: this.errorCallback.bind(this),
			extraParams: [utilId, isInitial],
			queryString: keyValue
		});
	}

	deleteRecords(callDesc, ids, utilId, refreshCallDesc) {
		this.http.CallApiWithCallback<any>({
			host: Host.spaManagement,
			success: this.successCallback.bind(this),
			error: this.errorCallback.bind(this),
			callDesc: callDesc,
			method: HttpMethod.Delete,
			body: ids,
			showError: true,
			extraParams: [utilId, refreshCallDesc]
		});
	}

	successCallback<T>(
		result: BaseResponse<T>,
		callDesc: string,
		extraParams: any[]): void {
		if (callDesc == "GetAllLocationMaintenance") {
			[this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
			const data: any = result.result ? result.result : [];
			this.locationMaintenanceData = data;
			this.filterLocationMaintenanceData(data, extraParams[0])
		}
		if (callDesc == "GetAllEquipmentMaintenance") {
			[this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
			const data: any = result.result ? result.result : [];
			this.equipmentMaintenanceData = data;
			this.filterEquipmentMaintenanceData(data, extraParams[0]);
		}
		if (["DeleteLocationMaintenance", "DeleteEquipmentMaintenance", "DeleteTherapistUnavailability"].includes(callDesc)) {
			this.onUtilChange();
		}
		if (callDesc == "getTherapistUnavailability") {
			this.ArrayList=[];
			[this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
			const therapistUnavailability: SettingsGrid<any> = {
				dataheader: this.ViewSettingBusiness.getUtilitiesDataHeader(extraParams[0], this.isClassView == 'true' ? true : false),
				datacolumn: this.buildTherapistUnavailabilityData(result.result)
			};
			this.ArrayList.push(therapistUnavailability);
			this.populateTableInfo(false, false, extraParams[0], '', 'therapistName', '', extraParams[1]);
		}
	}

	errorCallback<T>(
		error: BaseResponse<T>,
		callDesc: string,
		extraParams: any[]
	): void {
switch(callDesc){
	case "GetAllLocationMaintenance":
			case "GetAllEquipmentMaintenance":
					case "getTherapistUnavailability":
	[this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
	break;
}
	}

	buildTherapistUnavailabilityData(data: any): any {
		if (data && data.length > 0) {
			for (let i = 0; i < data.length; i++) {
				const element = data[i];
				element.startDateView = this.localization.LocalizeDate(this.utils.getDate(element.startDate));
				element.endDateView = this.localization.LocalizeDate(this.utils.getDate(element.endDate));
				element.services = element.therapistUnavailableServices && element.therapistUnavailableServices.length > 0 ? element.therapistUnavailableServices.map(x => x.serviceName).join(", ") : "";
			}
			return data;
		} else {
			return [];
		}
	}

	triggerPopup(event, belonTo, preTit, popupConfiguration): void {
		const Dialogtitle = this.getpopData(belonTo)[0];
		const DialogTemplate = this.getpopData(belonTo)[1];

		let popupWidth = '661px';
		let popupHeight = 'auto';

		if (belonTo == GridType.password) {
			popupWidth = '50%';
			popupHeight = '55%';
		}

		if (belonTo == GridType.therapistUnavailability) {
			popupWidth = '850px';
			popupHeight = '600px';
		}

		if (belonTo == GridType.locationMaintenance || belonTo == GridType.equipmentMaintenance) {
			popupWidth = '80%';
			popupHeight = '80%';
		}
		const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
			height: popupHeight,
			width: popupWidth,
			data: { headername: preTit + '  ' + Dialogtitle, closebool: true, templatename: DialogTemplate, datarecord: event, popupConfig: popupConfiguration, type: belonTo },
			panelClass: 'small-popup',
			disableClose: true,
			hasBackdrop: true
		});

		this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
			this.onUtilChange();
		});
	}

	getpopData(title) {
		const diaTit = [];
		switch (title) {
			case GridType.password:
				diaTit[0] = this.localization.captions.utilities.Passcode;
				diaTit[1] = "PM";
				break;
			case GridType.locationMaintenance:
				diaTit[0] = this.localization.captions.utilities.LocationMaintenance;
				diaTit[1] = "LM";
				break;
			case GridType.equipmentMaintenance:
				diaTit[0] = this.localization.captions.utilities.EquipmentMaintenance;
				diaTit[1] = "LM";
				break;
			case GridType.therapistUnavailability:
				diaTit[0] = this.localization.captions.utilities.TherapistUnAvailability;
				diaTit[1] = "TU";
				break;
			default:
				break;
		}
		return diaTit;
	}


	populateTableInfo(draggable: boolean, blnPagination: boolean, selectedSettingID: number, checkType: string, sortedByDefault: string, lastModifiedData: any, isInitial?: boolean): void {
		this.tableoptions = [{
			TableHdrData: this.ArrayList[0].dataheader,
			TablebodyData: this.ArrayList[0].datacolumn,
			ServiceId: this.utilityId,
			pagination: blnPagination,
			sortable: true,
			CustomColumn: true,
			TableSearchText: '',
			PlaceHoldertext: this.PlaceHolder,
			EnableActions: selectedSettingID != GridType.password ? true : false,
			TableDraggable: draggable,
			InactiveTherapists: checkType == 'T' ? true : false,
			InactiveService: checkType == 'S' ? true : false,
			SelectedSettingId: selectedSettingID,
			Sortable: sortedByDefault,
			SelectRows: (selectedSettingID == GridType.therapistUnavailability || selectedSettingID == GridType.locationMaintenance || selectedSettingID == GridType.equipmentMaintenance) ? true : false,
			NewData: lastModifiedData ? lastModifiedData.NewData : {},
			isInitial: isInitial,
			Searchable: selectedSettingID != GridType.password ? true : false,
			EditMoreOption: (selectedSettingID == GridType.therapistUnavailability || selectedSettingID == GridType.locationMaintenance || selectedSettingID == GridType.equipmentMaintenance || selectedSettingID == GridType.password) ? true : false,
			Editable: selectedSettingID == GridType.therapistUnavailability ? true : false,
			IsReadOnly: this.isViewOnly
		}];
		this.selectedTableRows = [];
	}

	openDialog(event, belonTo, preTit): void {
		this.openEditDialog(event, belonTo, preTit);
	}

	IsAuthorized(id: GridType): boolean {
		let isUserAuthorized: boolean = true;
		const breakpointNumber: number[] = [];
		switch (id) {
			case GridType.locationMaintenance:
				breakpointNumber.push(SPAManagementBreakPoint.LocationMaintenance);
				break;
			case GridType.equipmentMaintenance:
				breakpointNumber.push(SPAManagementBreakPoint.EquipmentMaintenance);
				break;
			case GridType.therapistUnavailability:
				breakpointNumber.push(SPAManagementBreakPoint.TherapistUnavailability);
				break;
			case GridType.multipleAppointments:
				isUserAuthorized = (this.breakPoint.CheckForAccess([SPAManagementBreakPoint.MoveMultipleApptAppointmentLocation], false) ||
					this.breakPoint.CheckForAccess([SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments], false) ||
					this.breakPoint.CheckForAccess([SPAManagementBreakPoint.MoveMultipleApptTherapistLocation], false));
				if (!isUserAuthorized) {
					this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.locObj.NoAccessForMoveMultipleAppointment);
					this.utilityId = GridType.password;
				}
				break;

		}
		if (breakpointNumber.length > 0) {
			isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
			this.isViewOnly = this.breakPoint.IsViewOnly(breakpointNumber[0]);
		}
		return isUserAuthorized;
	}

	public getSetupBreakPoints(utilityMenu: any[]): any[] {
		this.utilityBreakPoints = this.breakPoint.GetBreakPoint([SPAManagementBreakPoint.LocationMaintenance,
		SPAManagementBreakPoint.EquipmentMaintenance, SPAManagementBreakPoint.TherapistUnavailability,SPAManagementBreakPoint.CombineGuestRecords,
		SPAManagementBreakPoint.MoveMultipleApptAppointmentLocation, SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments,
		SPAManagementBreakPoint.MoveMultipleApptTherapistLocation, RetailBreakPoint.ReceiptConfiguration, RetailBreakPoint.UserSessionConfiguration]).result;

		utilityMenu.find(um => um.id == GridType.locationMaintenance).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.LocationMaintenance);
		utilityMenu.find(um => um.id == GridType.equipmentMaintenance).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.EquipmentMaintenance);
		utilityMenu.find(um => um.id == GridType.therapistUnavailability).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.TherapistUnavailability);
		utilityMenu.find(um => um.id == GridType.recieptConfiguration).IsAuthorized = this.IsActionAllowed(RetailBreakPoint.ReceiptConfiguration);
		utilityMenu.find(um => um.id == GridType.multipleAppointments).IsAuthorized = this.isMultipleAppointmentAllowed();
		utilityMenu.find(um => um.id == GridType.userMachineConfiguration).IsAuthorized = this.IsActionAllowed(RetailBreakPoint.UserSessionConfiguration);
		utilityMenu.find(um => um.id == GridType.combineGuestRecords).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.CombineGuestRecords);
		utilityMenu.find(um => um.id == GridType.dedupeGuestProfiles).IsAuthorized = this.IsActionAllowed(SPAManagementBreakPoint.DeDupeGuestRecords);
		utilityMenu.find(um => um.id == GridType.inventorysync).IsAuthorized = this.IsActionAllowed(RetailBreakPoint.inventorySync);
		return utilityMenu;
	}

	isMultipleAppointmentAllowed(): boolean {
		return this.IsActionAllowed(SPAManagementBreakPoint.MoveMultipleApptAppointmentLocation) ||
			this.IsActionAllowed(SPAManagementBreakPoint.MoveMultipleApptTherapistAppointments) ||
			this.IsActionAllowed(SPAManagementBreakPoint.MoveMultipleApptTherapistLocation);
	}

	private IsActionAllowed(breakPoint: number): boolean {
		const _breakPoint: UserBreakPoint = this.utilityBreakPoints.find(bp => bp.breakPointNumber == breakPoint);
		return _breakPoint ? (_breakPoint.allow || _breakPoint.view) : false;
	}

	accessCheckAndAlert(utilMenu: any) {
		this.isViewOnly = false;
		if (!utilMenu.IsAuthorized) {
			this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[utilMenu.breakPointNumber]);
		} else {
			const _breakPoint: UserBreakPoint = this.utilityBreakPoints.find(bp => bp.breakPointNumber == utilMenu.breakPointNumber);
			if(_breakPoint){
				this.isViewOnly = _breakPoint.view;
			}
		}
  }

  serachData(event) {
    this.searchText = event.target.value;
  }

}
