import { Modules, Host } from '../globalsContant';

export interface PropertyFeature {
    id: number;
    isActive: boolean;
    moduleId: number;
    featureName: string;
}

export interface ProductFeatures
{
    id : number;
    productName : string;
    isStandAlone : boolean
    features : Array<Features>; 
}

export interface Features
{
    id : number;
    moduleId : number;
    featureName : string
    featureDescription : string; 
    isActive: boolean;
    propertyFeaturesId: number;
}

export interface FeaturesConfigurations {
    configurationKey: string;
    configurationValue: string;
    propertyFeatureId: number;
}

export const ModuleHosts = {
    [Modules.POS]: Host.retailPOS,
    [Modules.PAYMENT]: Host.payment,
    [Modules.SPAIMG]: Host.image,
    [Modules.SPAMGT]: Host.spaManagement,
    [Modules.SPASCH]: Host.schedule,
    [Modules.COMMISSION]: Host.commission,
    [Modules.RETAILMGT]: Host.retailManagement
}

export const ModuleGetFeatureRoute = {    
    [Modules.PAYMENT]: "GetPropertyFeatureConfiguration",    
    [Modules.RETAILMGT]: "GetRetailPropertyFeatureConfiguration"
}

export const ModuleSetFeatureRoute = {    
    [Modules.PAYMENT]: "SaveFeatureConfiguration",    
    [Modules.RETAILMGT]: "SaveRetailFeatureConfiguration"
}