import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-report-transaction-log',
  templateUrl: './report-transaction-log.component.html',
  styleUrls: ['./report-transaction-log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportTransactionLogComponent  {
 

}
