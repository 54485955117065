import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { GridColumnCustomSort } from '../grid.interface';

export const GridCustomSort: GridColumnCustomSort = ( fieldConfig: string, GridSortFrom = null, ModelConfiguaration ?: any ): boolean => {
    let defaultGridSort = true;
    if ( GridSortFrom !== null && GridSortFrom === 'Transaction' ) {
       const TransaModelConfiguaration: TransactionConfiguration = ModelConfiguaration;
       const disabledSortColumns = ['PoTotalInFC','POItemTotalInFC','MiscTotalInFC'];
       const disabledColumnIndex = disabledSortColumns.findIndex(x => x.toLowerCase() === fieldConfig);
       if ( disabledColumnIndex > -1 ) {
        defaultGridSort = false;
       }       
    } else if (GridSortFrom !== null && GridSortFrom === 'SetUp') {
       const disabledSortColumns = ['UserRole', 'UserSite', 'UserLocation'];
       const disabledColumnIndex = disabledSortColumns.findIndex(x => x === fieldConfig);
       if ( disabledColumnIndex > -1 ) {
        defaultGridSort = false;
       }  
    }
    return defaultGridSort;
};