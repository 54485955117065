import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, HeaderConfiguration, TilesConfiguration, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridShowAddNewConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): any => {
        try {
            let ShowAddNew = false;
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                if (TransactionCollection.ViewTranscationConfig &&
                    TransactionCollection.ViewTranscationConfig.GridConfiguration &&
                    TransactionCollection.ViewTranscationConfig.GridConfiguration.ShowAddNew
                    ) {
                        ShowAddNew = TransactionCollection.ViewTranscationConfig.GridConfiguration.ShowAddNew;
                }
                return ShowAddNew; 
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };