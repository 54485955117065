import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { DynamicYieldingBusiness } from '../dynamic-yielding.business';
import { DaysOfWeek, RuleType, UI } from '../dynamic-yielding.model';


@Component({
  selector: 'app-view-yield-setup',
  templateUrl: './view-yield-setup.component.html',
  styleUrls: ['./view-yield-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[DynamicYieldingBusiness]
})
export class ViewYieldSetupComponent implements OnInit {
  gridDate: FormControl;
  captions: any;
  dateInput;
  floatLabel: string;
  maxDate: Date;
  minDate: Date;
  viewData:any;
  viewDataClone:any;
  setupForm: any;
  serviceData: { id: number; name: string; baseRate: string; priceTypes: { id: number; name: string; baseRate: string; updatedRate: string; }[]; }[];
  searchText: any;
  filteredServiceData: {
    id: number; name: string; baseRate: string; priceTypes: {
      id: number; name: string; baseRate: string; updatedRate: string;
    }[];
  }[];
  groupId: any;
  dpStDate: any;
  dpEdDate: any;
  serviceIds: number[];
  weekDays: { id: DaysOfWeek; value: string; viewVal: string; isAvailable: boolean; weekId: number; }[];
  constructor(private localization: SpaLocalization, private dialogRef: MatDialogRef<ViewYieldSetupComponent>,
    @Inject(MAT_DIALOG_DATA) private data, private business: DynamicYieldingBusiness) { 
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    // this.viewData = this.data.viewData;
    if(this.data.setupForm){
      this.setupForm = this.data.setupForm;
    }
    
    if(this.data.gridObj){
      this.groupId = this.data.gridObj.groupId;
      this.dpStDate = this.data.gridObj.startDate;
      this.dpEdDate = this.data.gridObj.endDate;
    }
  }

  ngOnInit(): void {
    this.gridDate = new FormControl('');
    if(this.groupId){
      this.gridDate = new FormControl(this.localization.getDate(this.dpStDate));
      this.dateInput = this.localizedDate(this.gridDate.value);
      this.minDate = this.localization.getDate(this.dpStDate);
      this.maxDate = this.localization.getDate(this.dpEdDate);
      this.fetchPricingForGrid();
    }
    if(this.setupForm){
      this.gridDate = new FormControl(this.localization.getDate(this.setupForm.get('startDate').value));
      this.dateInput = this.localizedDate(this.gridDate.value);
      this.minDate = this.localization.getDate(this.setupForm.get('startDate').value);
      this.maxDate = this.localization.getDate(this.setupForm.get('endDate').value);
      this.fetchPricing();
    }
  }

  async fetchPricing(){
    await this.getServiceDatafromAPI();
    this.mapViewData(null);
  }

  async fetchPricingForGrid(){
    await this.getViewfromAPI();
  }

  getPricingDetails(){
    let arr = [];
    let curDay = this.localization.getDate(this.gridDate.value).getDay();
    let selectedDateID = null;
    if(curDay > -1){
      let week = this.getWeekList();
      let curDayEnum = week.find(x=>x.weekId == curDay);
      let dayMap = this.daysMapper(curDayEnum.id);
      let selectedDate = dayMap.find(x=>x.isAvailable);
      if(selectedDate){
        selectedDateID = selectedDate.id;
      }
    }
    
    let serviceYieldArray = this.setupForm.get('rules') as UntypedFormArray;
    serviceYieldArray.controls.forEach((element) => {
      let currentRule = element.get('daysOfWeek').value;
      if(currentRule.find(x=>x.id == selectedDateID) && currentRule.find(x=>x.id == selectedDateID).isClicked){
        arr.push({
          timeRange: (element.get('startTime') && element.get('endTime')) ?  (element.get('startTime').value) + ' - ' +  (element.get('endTime').value): "All Day",
          startTime: element.get('startTime')? element.get('startTime').value: '',
          endTime: element.get('endTime')? element.get('endTime').value: '',
          rules: this.getRules(element),
          ruleTypeId: element.get('type').value,
          services: this.getServicePricetypes(element.get('services').value)
        })
      }
    });
    return arr;
  }

  getRules(serviceYieldArray){
    let arr = [];
    serviceYieldArray.get('conditionArray').controls.forEach((rulecondition)=>{
      arr.push({
        id: 1,
        ruleCategoryId: 1,
        ruleTypeId: serviceYieldArray?.get('type')?.value,
        pricingCondition: rulecondition.controls.condition.value,
        pricingConditionValue: rulecondition.controls.occupancy.value,
        isIncrement: rulecondition.controls.rateChange.value == 0? true: false,
        isPercent: rulecondition.controls.priceType.value == 0? true: false,
        pricingStrategyValue: rulecondition.controls.priceValue.value,
        stopAvailability: rulecondition.controls.stopAvailability.value
      })
    })
    return arr;
  }

  getServicePricetypes(serviceIdsArray){
    let arr = [];
    serviceIdsArray.forEach((service)=>{
      let serviceDetails = this.filteredServiceData.find((serv)=>serv.id === service);
      if(serviceDetails){
        arr.push(serviceDetails);
      }
    });
    return arr;
  }



  async mapViewData(searchValue){
    this.filteredServiceData = await this.getServiceData(searchValue);
    if(this.setupForm){
      this.viewData = {
        startDate: this.setupForm.get('startDate').value,
        endDate: this.setupForm.get('endDate').value,
        pricingDetails: this.getPricingDetails()
      };
      this.viewData = {...this.viewData};
    }else{
      let clone = cloneDeep(this.viewDataClone)
      clone.pricingDetails = clone?.pricingDetails?.map(pd=>{
        pd.services = pd.services.filter(d => {
          return this.filteredServiceData.some(fs => fs.id === d.id)
        }) 
        return pd
      }).filter(s => s.services.length > 0)
      this.viewData = clone
    }
  }

  searchValueChange(eve){
    this.searchText = eve;
    if(this.searchText.length > 0){
      this.mapViewData(this.searchText);
    } else{
      this.mapViewData(null);
    }
  }

  async getServiceDatafromAPI(){
    let serviceIds = [];
    let serviceYieldArray = this.setupForm.get('rules') as UntypedFormArray;
    serviceYieldArray.controls.forEach((element) => {
      let serviceList = element.get('services').value;
      serviceList.forEach((service)=>{
        if(serviceIds.indexOf(service) == -1){
          serviceIds.push(service);
        }
      });
    });
    this.serviceData = await this.business.PreviewDynamicPricing(this.localization.convertDateTimeToAPIDateTime(this.gridDate.value), serviceIds);
  }

  async getViewfromAPI(){
    let curDay = this.localization.getDate(this.gridDate.value).getDay();
    let curDayEnum = null;
    if(curDay > -1){
      let week = this.getWeekList();
      curDayEnum = week.find(x=>x.weekId == curDay).id;
    }
    this.viewData = await this.business.ViewDynamicPricing(this.groupId);
    this.serviceIds = [];
    if(this.viewData.pricingDetails && this.viewData.pricingDetails.length > 0){
      this.viewData.pricingDetails.forEach((x)=>{
        const startTime = this.localization.ConvertDateToTime(x.startTime);
        const endTime = this.localization.ConvertDateToTime(x.endTime);
        x.timeRange = `${startTime} - ${endTime}`
        let serviceList = x.services;
        if(serviceList && serviceList.length > 0){
          serviceList.forEach((serviceItem) => {
            if(this.serviceIds.indexOf(serviceItem.id) == -1){
              this.serviceIds.push(serviceItem.id);
            }
          });
        }
      });
    }
    this.viewData.pricingDetails = this.viewData.pricingDetails.filter((x)=>{
      this.daysMapper(x.daysOfWeek);
      let currentDayAvailability = this.weekDays.find(wd=>wd.id == curDayEnum);
      if(currentDayAvailability && currentDayAvailability.isAvailable){
        return x;
      }
    });
    this.viewData = {...this.viewData};
    this.viewDataClone = cloneDeep(this.viewData);
    this.serviceData = await this.business.PreviewDynamicPricing(this.localization.convertDateTimeToAPIDateTime(this.gridDate.value), this.serviceIds);
  }

  getServiceData(searchValue){
    let returnValue = cloneDeep(this.serviceData);
    if(searchValue){
      return returnValue = returnValue.filter(x=>x.name.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      return returnValue;
    }
  }


  toggleRuleBody(e){
    if(e.currentTarget){
      e.currentTarget.nextSibling.classList.toggle('ag_display--none');
      if(e.currentTarget.children[0].classList.contains('icon-filled-up-arrow1')){
        e.currentTarget.children[0].classList = 'icon-filled-down-arrow';
      } else {
        e.currentTarget.children[0].classList = 'icon-filled-up-arrow1';
      }
    }
  }

  async dateChanged(e){
    this.gridDate.setValue(e);
    this.dateInput = this.localizedDate(e);
    if(this.groupId && !this.setupForm){
      this.serviceData = await this.business.PreviewDynamicPricing(this.localization.convertDateTimeToAPIDateTime(this.gridDate.value), this.serviceIds);
    } else {
      this.fetchPricing();
    }
  }

  async movePrevDate(e){
    let date = new Date(this.dateInput);
    if(this.localization.compareDates(this.minDate, date)){
      this.gridDate.setValue(date.setDate(new Date(date).getDate() - 1));
      this.dateInput = this.localizedDate(this.gridDate.value);
      if(this.groupId && !this.setupForm){
        this.fetchPricingForGrid();
        // this.serviceData = await this.business.PreviewDynamicPricing(this.localization.convertDateTimeToAPIDateTime(this.gridDate.value), this.serviceIds);
      } else {
        this.fetchPricing();
      }
    }
  }

  async moveNextDate(e){
    let date = new Date(this.dateInput);
    if(this.localization.compareDates(date, this.maxDate)){
      this.dateInput = this.gridDate.setValue(date.setDate(new Date(date).getDate() + 1));
      this.dateInput = this.localizedDate(this.gridDate.value);
      if(this.groupId && !this.setupForm){
        this.fetchPricingForGrid();
        // this.serviceData = await this.business.PreviewDynamicPricing(this.localization.convertDateTimeToAPIDateTime(this.gridDate.value), this.serviceIds);
      } else {
        this.fetchPricing();
      }
    }
  }

  close(){
    this.dialogRef.close();
  }
  
  localizedDate(value): string {
    return moment(value).format('DD MMM YYYY');
  }

  daysMapper(daysOfWeek: DaysOfWeek) {
    this.weekDays = this.getWeekList();
    this.weekDays.forEach(wd=>wd.isAvailable = ((daysOfWeek & wd.id) == wd.id));
    return this.weekDays;
  }

  getWeekList() {
    return [
        {
            id: DaysOfWeek.Monday,
            value: "M",
            viewVal: "Mon",
            isAvailable: false,
            weekId: 1
        },
        {
            id: DaysOfWeek.Tuesday,
            value: "T",
            viewVal: "Tue",
            isAvailable: false,
            weekId: 2
        },
        {
            id: DaysOfWeek.Wednesday,
            value: "W",
            viewVal: "Wed",
            isAvailable: false,
            weekId: 3
        },
        {
            id: DaysOfWeek.Thursday,
            value: "T",
            viewVal: "Thu",
            isAvailable: false,
            weekId: 4
        },
        {
            id: DaysOfWeek.Friday,
            value: "F",
            viewVal: "Fri",
            isAvailable: false,
            weekId: 5
        },
        {
            id: DaysOfWeek.Saturday,
            value: "S",
            viewVal: "Sat",
            isAvailable: false,
            weekId: 6
        },
        {
            id: DaysOfWeek.Sunday,
            value: "S",
            viewVal: "Sun",
            isAvailable: false,
            weekId: 0
        },
    ]
  }     



}


