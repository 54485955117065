import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password, 
@Component({
    selector: 'lib-mutiple-field',
    templateUrl: './multiple-field.component.html',
    styleUrls: ['./multiple-field.component.scss']
})
export class MultipleFieldComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
    get isValid(): any { return this.form.controls[this.field.name].valid; }
    get isDirty(): any { return this.form.controls[this.field.name].dirty; }
    constructor() { 
        console.log('mul');
    }
}