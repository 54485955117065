import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailItemType } from 'src/app/retail/retail.modals';
import { SelectedProducts } from 'src/app/retail/shared/service/common-variables.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RentTypes } from '../../shop.modals';
import { ShowItemPricePipe } from '../retail-items/retail-items.component';

@Component({
  selector: 'app-retail-cart',
  templateUrl: './retail-cart.component.html',
  styleUrls: ['./retail-cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ShowItemPricePipe]
})
export class RetailCartComponent {

  groupedCategory;
  captions: any;
  itemType = RetailItemType;
  totalValue = 0;
  roomIndex: number;
  rentDurationCaption: string;
  rentTypeDays = RentTypes.Days;
  @Input() rentTypes;
  @Input() stayId;
  @Input() saveCart;
  @Input('roomNumber')
  set setRoomNumber(value) {
    this.roomIndex = Number(value) - 1;
  }
  @Output() removeproductFromCart: EventEmitter<SelectedProducts> = new EventEmitter<SelectedProducts>();
  @Input('products')
  set groupProducts(value) {
    if (value && value.length > 0) {
      const grouped = this.groupBy(value, 'ItemType');
      this.groupedCategory = Object.keys(grouped)?.map((key) => {
        return {
          products: grouped[key],
          itemType: key,
          category: RetailItemType.RentalItem === Number(key) ? this.captions.lbl_rent : this.captions.lbl_buy
        };
      });
    } else {
      this.groupedCategory = [];
    }
    this.calculateRentalItemsTotal(value);
  }

  constructor(
    public localization: RetailLocalization,
    private utilities: RetailUtilities
  ) {
    this.captions = this.localization.captions;
    this.rentDurationCaption = this.captions.lbl_RentDays;
  }

  groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  calculateRentalItemsTotal(value: SelectedProducts[]) {
    this.totalValue = 0;
    if (value) {
      value.forEach(prod => {
        this.totalValue += prod.productDetails.map(o => o.total).reduce((a, c) => a + c);
      });
    }
  }

  removeproduct(selectedProduct: SelectedProducts) {
    this.removeproductFromCart.emit(selectedProduct);
  }

  calculateItemPrice(product: SelectedProducts) {
    let orgAmount = 0;
    let price = '';
    const totalprice = (product.Noofitems * product.ProductPrice);
    if (product.Discount) {
      if (product.isReturn) {
        let perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.RoundOff2DecimalPlaces(product.DiscountPercentage * Math.abs(totalprice) / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > Math.abs(totalprice)) ? totalprice : perItemDiscount;
        orgAmount = totalprice + perItemDiscount;
      }
      else {
        let perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.RoundOff2DecimalPlaces(product.DiscountPercentage * totalprice / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
        orgAmount = totalprice - perItemDiscount;
      }
    }
    else {
      orgAmount = totalprice;
    }

    if (orgAmount < 0) {
      price = `(${this.localization.localizeCurrency(orgAmount * -1)})`;
    }
    else {
      price = this.localization.localizeCurrency(orgAmount);
    }
    return price;
  }

}
