import { Injectable } from '@angular/core';
import { SftpDataService } from 'src/app/common/dataservices/notification/sftp-data.service';
import { Localization } from 'src/app/common/localization/localization';
import { API, UI } from 'src/app/common/Models/sftp.model';

@Injectable()
export class SftpSetupBusiness {

  constructor(private localization: Localization, private _sftpDataService: SftpDataService
  ) {
  }

  public async getSftpConfig(): Promise<UI.SFTPConnection[]> {
    const apiModel: API.SFTPConnection[] = await this._sftpDataService.getSFTPConfiguration();
    return apiModel;
  }

  public async createSftpConfig(sftpConfig): Promise<UI.SFTPConnection> {
    const apiModels: API.SFTPConnection = await this._sftpDataService.createSFTPConfiguration(sftpConfig);
    return apiModels;
  }

  public async updateSftpConfig(sftpConfig): Promise<UI.SFTPConnection> {
    const apiModels: API.SFTPConnection = await this._sftpDataService.UpdateSFTPConfiguration(sftpConfig);
    return apiModels;
  }
  
  public async checkSFTPConnection(sftpConfig): Promise<boolean> {

      return await this._sftpDataService.checkSFTPConnection(sftpConfig);
    
  }

}




