
import { Injectable } from '@angular/core';
import { RetailRoutes } from '../../retail-route';
import { RetailCommunication } from '../../retailcommunication.service';
import { API } from './tax-exempt-category.model';

@Injectable()
export class TaxExemptCategoryDataService {

    constructor(private retailCommunication: RetailCommunication) {
    }

    public getAllTaxExemptCategories(IncludeInActive: boolean = false): Promise<API.TaxExemptCategory[]> {
        return this.retailCommunication.getPromise<API.TaxExemptCategory[]>(
            { route: RetailRoutes.GetAllTaxExemptCategory, uriParams: { "includeInactive": IncludeInActive } }, false);
    }
    public getTaxExemptCategory(id: number): Promise<API.TaxExemptCategory> {
        return this.retailCommunication.getPromise<API.TaxExemptCategory>(
            { route: RetailRoutes.GetTaxExemptCategory, uriParams: { "id": id } }, false);
    }
    public createTaxExemptCategory(body: API.TaxExemptCategory): Promise<API.TaxExemptCategory[]> {
        return this.retailCommunication.postPromise<API.TaxExemptCategory[]>(
            { route: RetailRoutes.CreateTaxExemptCategory, body: body }, true);
    }
    public updateTaxExemptCategory(body: API.TaxExemptCategory): Promise<API.TaxExemptCategory[]> {
        return this.retailCommunication.putPromise<API.TaxExemptCategory[]>(
            { route: RetailRoutes.UpdateTaxExemptCategory, body: body }, true);
    }
    public deleteTaxExemptCategory(id: number): Promise<API.TaxExemptCategory> {
        return this.retailCommunication.deletePromise<API.TaxExemptCategory>(
            { route: RetailRoutes.DeleteTaxExemptCategory, uriParams: { "id": id } }, true);
    }

    public async getNextListOrder(): Promise<number> {
        try {
            return await this.retailCommunication.getPromise<number>(
                { route: RetailRoutes.GetNextListOrder_TaxExemptCategory });
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}