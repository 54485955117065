import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'summaryIcon'
})

export class SummaryIconPipe implements PipeTransform {
    transform(value: any): any {
      let returnValue = '';
      const iconColor = [
        {
            'Id': 1,
            'Name': 'Credit',
            'ClassColor': 'bg-red'
        },
        {
            'Id': 2,
            'Name': 'Debit',
            'ClassColor': 'bg-green'
        }
      ];
      iconColor.forEach((x: any) => {
        if (x.Name === value) {
            returnValue =  x.ClassColor;
        }
      });
      console.log(returnValue);
      return returnValue;
    }
}