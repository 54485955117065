import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DynamicGridService } from '../agilysys-grid.service';
import _ from 'lodash';
import { GridHeaderService } from './grid-header.service';
import { HeaderOverlayRef } from './header-overlay-ref';
import * as constants from '../shared/globalConstants';

@Component({
  selector: 'lib-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridHeaderComponent implements OnInit {

  params: any;
  order = 'asc';
  userDefCols: any;
  allColumns: any;
  nextPosition = 0;
  @ViewChild('reorder') reorder: ElementRef;
  showSort: boolean;
  showShuffle: boolean;

  constructor(
    private dynamiCGridService: DynamicGridService,
    private gridHeaderService: GridHeaderService) { }

  ngOnInit(): void {
  }
  

  agInit(params): void {
    try {
      this.params = params;
      this.userDefCols = this.params.column.colDef;
      this.params.column.colDef.headerChecked = false;
      this.allColumns = _.cloneDeep(this.params.columnApi.columnController.columnDefs);
      this.showSort = this.params.context.parentComponent.gridInput.GridData?.length > 1 ? true : false;
    } catch (error) {
      console.log('Error ocurred in agInit of grid-header', error);
    }
  }


  headerCheckBoxChange(event): void {
    try {
      this.params.column.colDef.headerChecked = event.checked;
      this.params.context.parentComponent.headerCheckBoxChange(event, this.params);
    } catch (error) {
      console.error('Error occurred in headerCheckBoxChange', error);
    }
  }

  /**
   * @method sortOrder
   * @description sort order icon clicked
   */
  sortOrder() {
    try {
      if (this.params.context.parentComponent.rowData.length > 1) {
        if (this.userDefCols.customSort) {
        if (this.params.column.colDef.sortDirection === '' || this.params.column.colDef.sortDirection === undefined) {
          this.dynamiCGridService.sortorder = '';
          this.dynamiCGridService.sortorder = constants.SortDirection.Ascending;
        } else if (this.params.column.colDef.sortDirection === constants.SortDirection.Ascending) {
          this.dynamiCGridService.sortorder = '';
          this.dynamiCGridService.sortorder = constants.SortDirection.Descending;
        } else if (this.params.column.colDef.sortDirection === constants.SortDirection.Descending) {
          this.dynamiCGridService.sortorder = '';
          this.dynamiCGridService.sortorder = constants.SortDirection.Ascending;
        }
        this.params.column.colDef.sortDirection = this.dynamiCGridService.sortorder;
        const sortOrder = {
          colId: this.params.column.colDef.field,
          sort: this.dynamiCGridService.sortorder
        };
        const allColumnDef = this.params.columnApi.columnController.columnDefs;
        allColumnDef.forEach(element => {
          if (element.field !== this.params.column.colDef.field) {
             element.sortDirection = '';
          } else {
             element.sortDirection = this.dynamiCGridService.sortorder;
          }
        });
        this.params.api.setColumnDefs(allColumnDef);
        this.params.context.parentComponent.sortData([sortOrder, this.params, allColumnDef]);
        this.userDefCols.sortDirection = this.dynamiCGridService.sortorder;
        this.dynamiCGridService.sortorder = this.dynamiCGridService.sortorder === constants.SortDirection.Ascending ?
        constants.SortDirection.Descending : constants.SortDirection.Ascending;
        }
      }
    } catch (error) {
      console.log('Error ocurred in sortOrder', error);
    }
  }

}
