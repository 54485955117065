import { Component } from '@angular/core';

@Component({
  selector: 'app-report-member',
  templateUrl: './report-member.component.html',
  styleUrls: ['./report-member.component.scss']
})
export class ReportMemberComponent  {
  constructor() { }

  ngOnInit() {
  }

}
