import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FileDetails, FileUploadConfig, UploadMetaData } from './upload-configuration.interface';
import { DetailsPayload } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { ToastrService } from 'ngx-toastr';

export const FileUpload: FileUploadConfig =
( FileEvent: Array<any>, httpService: HttpService, FileDetail: DetailsPayload, FileDataCollection: Array<UploadMetaData>,
  toasterService?: ToastrService, DMEnabled = false, MaximumSize = 1, BlockLength = 1048576, masterPictureDetails = [] ): Promise<any> => {
    try {
        if ( FileEvent && FileEvent.length > 0 ) {
          const invalidFormats = [];
          const fileExceeded = [];
          const moreThanTwoVideos = [];
          const fileCollection = [];
          console.log('Event Files', FileEvent);
          return new Promise((resolve, reject) => {
            Array.from(FileEvent).forEach(async (FileDataEvent: any, index: number) => {
                const FileType = FileDataEvent.type;
                const FileSize = FileDataEvent.size;
                const FileName = FileDataEvent.name;
                const validFormatIndex = fileFormats(FileType, DMEnabled, FileDetail.IsFromMaster, FileDetail.IsFromDataImport);
                if ( validFormatIndex > -1 ) {
                    const fileExceed = DMEnabled ? (FileSize > 5242880) : fileSize(FileType, FileSize);
                    const FileMetaData = '/inventory/api/File/MetaData';
                     if ( !fileExceed ) {
                      setFileDetails(FileDataEvent, FileDetail).then((res: any) => {
                      const numberOfBlocks = Math.ceil(FileSize / BlockLength);
                       const uploadMetaData = {} as UploadMetaData;
                       uploadMetaData.FileSize = FileSize;
                       uploadMetaData.BlocksCount = numberOfBlocks;
                       uploadMetaData.FileName = FileName;
                       uploadMetaData.GroupId = FileDetail.GroupId;
                       uploadMetaData.IsFavourite = false;
                       uploadMetaData.SortOrder = index;
                       uploadMetaData.ContentType = FileType;
                       uploadMetaData.ItemId = FileDetail.ItemId;
                       uploadMetaData.FileData = FileDataEvent;
                       uploadMetaData.FileDetails =  res;
                       uploadMetaData.CloudFileName = '';
                       uploadMetaData.IsMetaCreated = false;
                       let alreadyHadVideo = FileDataCollection.findIndex(x => x.ContentType === 'video/mp4');
                       if (masterPictureDetails.length > 0) {
                         alreadyHadVideo = masterPictureDetails.findIndex(p => p.FileDetails.ContentType === 'video/mp4');
                       }
                       if (FileDetail.IsFromMaster && alreadyHadVideo > -1 && FileType === 'video/mp4') {
                        moreThanTwoVideos.push(true);
                       } else {
                        if (FileDataCollection && FileDataCollection.length > 0 ) {
                          const TwoVideos = FileDataCollection.filter(x => x.ContentType === 'video/mp4').length === 1 ? true : false;
                          if (TwoVideos && FileType === 'video/mp4') {
                            moreThanTwoVideos.push(true);
                          } else {
                            FileDataCollection.push(uploadMetaData);
                            fileCollection.push(uploadMetaData); 
                          }
                         } else {
                          FileDataCollection.push(uploadMetaData); 
                          fileCollection.push(uploadMetaData);                        
                         }
                       }                       
                       if (FileEvent.length === (fileCollection.length + invalidFormats.length + fileExceeded.length
                        + moreThanTwoVideos.length)) {
                        if (moreThanTwoVideos.filter(x => x === true).length > 0) {
                          toasterService.error('Video atatchment already exist.', '', 
                          {
                            closeButton: true,
                            timeOut: 3000
                          });
                        }
                        return resolve(FileDataCollection); // fix for adding files multiple times
                      }                       
                      });

                    //    const contentType = 'multipart/form-data';
                    //     const form_data: FormData  = new FormData();
                    //     for (const property in uploadMetaData) {
                    //     if ( property ) {
                    //         form_data.append(property, uploadMetaData[property]);
                    //     }
                    //     }
                    //    httpService.PostFilehttpMethod(FileMetaData, form_data, null, true, contentType).subscribe(x => {
                    //        console.log(x);
                    //    });
                     } else if (fileExceed) {
                       fileExceeded.push(true);
                       if (FileEvent.length === (fileCollection.length + invalidFormats.length + fileExceeded.length
                        + moreThanTwoVideos.length)) {
                        if (moreThanTwoVideos.filter(x => x === true).length > 0) {
                          toasterService.error('Video atatchment already exist.', '', 
                          {
                            closeButton: true,
                            timeOut: 3000
                          });
                        }
                        return resolve(FileDataCollection); // fix for adding files multiple times
                      }
                     }
                    //  return resolve(FileDataCollection);
                } else if (validFormatIndex === -1) {
                  invalidFormats.push(true);
                  if (FileEvent.length === (fileCollection.length + invalidFormats.length + fileExceeded.length
                    + moreThanTwoVideos.length)) {
                    if (moreThanTwoVideos.filter(x => x === true).length > 0) {
                      toasterService.error('Video atatchment already exist.', '', 
                      {
                        closeButton: true,
                        timeOut: 3000
                      });
                    }
                    return resolve(FileDataCollection); // fix for adding files multiple times
                  }
                }
             });
            if (invalidFormats.filter(x => x === true).length > 0) {
              alert('Invalid File Formats Uploaded');
            }
            if (fileExceeded.filter(x => x === true).length > 0) {
              alert('One or more file(s) has exceeded allowed upload file size.');
            }
              // return resolve(FileDataCollection);
          });
        }
    } catch (error) {
        console.error(error);
    }
};
const fileFormats = (FileFormat: string, DMEnabled: boolean, IsFromMaster = false, IsFromDataImport = false): number => {
    try {
        let FileType = DMEnabled ? ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/docx', 'text/csv', 
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
        'application/zip', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
        'application/x-zip-compressed'] : IsFromDataImport ? [ 'text/csv', 'application/x-zip-compressed' ] : 
        ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'video/mp4', 'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/docx', 'text/csv'];
         FileType = IsFromMaster ? ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'video/mp4'] : FileType;
        const GetIndex = FileType.findIndex(e => e === FileFormat);
        return GetIndex;
    } catch ( error ) {
        console.error(error);
    }
};

const fileSize = (FileFormat: string, FileSize: number): boolean => {
    const imageSizeLimit = 2097152;
    const videoSizeLimit = 20971520;
    const zipSizeLimit = 20000000;
    try {
        let FileExceed = false;
        const GetType = FileFormat.split('/')[0];
        if ( GetType === 'video' ) {
            FileExceed = ( FileSize > videoSizeLimit ) ? true : false;
        } else if (FileFormat === 'application/x-zip-compressed') {
            FileExceed = (FileSize > zipSizeLimit) ? true : false;
        } else {
          FileExceed = ( FileSize > imageSizeLimit ) ? true : false;
        }
        return FileExceed;
    } catch ( error ) {
        console.error(error);
    }
};

async function setFileDetails (FileDataEvent: any, FileDetailss: any): Promise<any> {
 try {
   return new Promise(async (resolve, reject) => {
    const filedata = await readFileBinary(FileDataEvent);
    // const imageBaseConvert = domSanitizer.bypassSecurityTrustUrl(filedata);
    if (FileDataEvent.type !== 'video/mp4') {
      const detail: FileDetails = {
        ContentType: FileDataEvent.type,
        FileName: FileDataEvent.name,
        FilePath: FileDataEvent.webkitRelativePath,
        Id: 0,
        InventoryGroupId: FileDetailss.GroupId,
        InventoryId: FileDetailss.ItemId,
        IsFavorite: false,
        Picture: filedata,
        Size: FileDataEvent.size,
        SortOrder: 0
      };
      resolve(detail);
    } else {
      FileData.FileThumbNailGenearate('assets/images/video.png').then((res: any) => {
        const detail: FileDetails = {
          ContentType: FileDataEvent.type,
          FileName: FileDataEvent.name,
          FilePath: FileDataEvent.webkitRelativePath,
          Id: 0,
          InventoryGroupId: FileDetailss.GroupId,
          InventoryId: FileDetailss.ItemId,
          IsFavorite: false,
          Picture: res,
          Size: FileDataEvent.size,
          SortOrder: 0,
          LocalUrl: filedata
        };
        resolve(detail);
      });
    }
   });
 } catch (error) {
   console.log(error);
 }
}

function readFileBinary(UploadedFile: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(UploadedFile);
    fileReader.onload = (fileLoaded) => {
      return resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
