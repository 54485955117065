import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';

/**
 *User Access Report
 * @export
 * @class User Access Report
 * @extends {BaseReport}
 */
export class UserAccessReport extends BaseReport {
	constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService) {
		super(reportFormGrp);
	}

	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: AllReports.UserAccessReport,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		}; 
	}

	getReportUIConfig(): ReportUIConfig {
		return {
			startDatePicker: false,
			endDatePicker: false,
			dropDownFilters: this.data.GetUsersByPropertyId(false),
			dropDownFilterName: this.captions.Users,
			inActiveToggle: false,
			inActiveToggleName: this.captions.IncludeInactiveUsers,
			pageBreakToggle: false,
			layout: 'LANDSCAPE'
		}; 
	}

	protected formReportParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [			
			{ pPropertyName: this.propertyName },
			{ pFilterCaption: this.getFilterCaption },
			{ pDate: this.printedDate }
		];
	}

	protected formURIParams(): ReportParams[] {		
		return [];
	}

	protected formFilters(): number[] {
		return this.selectedFilterOptions.filter(x => x.showInDropDown).map((s) => s.id);
	}
}
