import { timer as observableTimer, Observable, Subject, Subscription, ReplaySubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { AlertType, ButtonTypes } from 'src/app/common/Models/common.models';
import { SpaUtilities } from '../utilities/spa-utilities';
import { Localization } from 'src/app/common/localization/localization';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertPopupWithTimerComponent } from 'src/app/common/shared/shared/alert-popup-with-timer/alert-popup-with-timer.component';
import { AutologoffTimerService } from 'src/app/common/shared/shared/autologoff-timer-service';

@Injectable()

export class IdleTimeoutService implements OnDestroy {
  private _count = 0;
  private _timeoutSeconds = 60;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  public resetOnTrigger = false;
  public timeoutExpired: Subject<number> = new Subject<number>();
  public tokenTimerSubscription: Subscription;
  private tokenTimer: Observable<number>;
  enableAlert = false;
  timeoutMethod: any;
  logOutWaitingtime:number = 120000; //milliseconds
  subject = new ReplaySubject<number>(2);
  _subscription : Subscription;
  idealTime:boolean = false;
  autoLogoffTimer:any;
  isIdealDialogOpen:boolean = false;

  constructor(private authentication: AuthenticationService, private utils: SpaUtilities, private localisation: Localization,
    private cartUtils: CartUtilities, public dialog: MatDialog, private autoLogOfftimerService: AutologoffTimerService) {
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
      }
  }

  ngOnDestroy() {
    this.timeoutExpired.unsubscribe();
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  logout() {   
    this.resetOnTrigger = false;
    this.authentication.logout();
    if (this.timeoutMethod)
    {
    clearTimeout(this.timeoutMethod);
    }
  }

  public GetPropertyInfo(name: string) {
    const nameEQ = name + '=';
    const propertyInfo = sessionStorage.getItem('propertyInfo');
    if (propertyInfo != null) {
      const ca = propertyInfo.split(';');

      for (let resultCa of ca) {
        let c = resultCa.trim();
        while (c.charAt(0) == ' ') { c = c.substring(1, c.length); }
        if (c.indexOf(nameEQ) == 0) { return c.substring(nameEQ.length, c.length); }
      }
    }
    return null;
  }

  public startTimer(logOffAfter: any, tokenExpiry: number) 
  {
    if (logOffAfter != 0) 
    {
      if (this.timerSubscription) 
      {
        this.timerSubscription.unsubscribe();
      }
      this._timeoutSeconds = logOffAfter * 60;
      this.timer = observableTimer(this._timeoutSeconds * 1000);
      this.timerSubscription = this.timer.subscribe(n => {
        this.IdealtimerComplete();
        this.idealTime = true;
      });
    }
    if(logOffAfter==0)
    {
      if (tokenExpiry > 122) 
      {
        tokenExpiry = tokenExpiry - 122; //  buffer time for token expiry
      }
      this.tokenTimer = observableTimer(tokenExpiry * 1000);
      this.tokenTimerSubscription = this.tokenTimer.subscribe(n => 
      {
        this.enableAlert = true;
        this.timerComplete(this.enableAlert);
      });
    }
  }

  //Below method called after refresh.
  public forceLogOff() {
    let jwtExpiryTime:any = sessionStorage.getItem('jwtExpiryTime');
    jwtExpiryTime = new Date(jwtExpiryTime);
    let currentTime:any = new Date();
    let expirySeconds = jwtExpiryTime - currentTime;  
    if(!sessionStorage.getItem('popupEnabled'))
    {                
      if (this.tokenTimerSubscription) 
       {
          this.tokenTimerSubscription.unsubscribe();
       }
      if (expirySeconds > this.logOutWaitingtime) 
      {
          expirySeconds = expirySeconds - this.logOutWaitingtime;
      }
      this.tokenTimer = observableTimer(expirySeconds);
      this.tokenTimerSubscription = this.tokenTimer.subscribe(n => 
      {
        this.enableAlert = true;
        this.timerComplete(this.enableAlert);    
      });
    } 
    else
    {
      if(expirySeconds > 0)
      {
        this.timeoutMethod = setTimeout(() => {
          this.logout();        
        }, expirySeconds);
      }
      else
      {
        this.logout();
      }
     
    }
  
  }

  public stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
  }
  public resetTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timer = observableTimer(this._timeoutSeconds * 1000);
      this.timerSubscription = this.timer.subscribe(n => {
        !this.idealTime? this.timerComplete(false) : this.IdealtimerComplete()
      });
  }
  private IdealtimerComplete() {
    let dialogref;
    if(!this.isIdealDialogOpen) {
      this.isIdealDialogOpen = true;
      this.resetOnTrigger = false;
      dialogref = this.dialog.open(AlertPopupWithTimerComponent, {
      height: 'auto',
      width: 'auto',
      panelClass: 'small-popup',
      disableClose: true,
    });
    }
    
  dialogref.afterClosed().subscribe(res => {
    this.isIdealDialogOpen = false;
    clearTimeout(this.autoLogoffTimer);
      if (res.from == 'logout') {
        this.timeoutExpired.next(++this._count);
        this.stopTimer();
        sessionStorage.setItem('popupEnabled','true');
        this.logout();
      } else {
        this.resetOnTrigger = true;
      }
  
  });
  dialogref.afterOpened().subscribe(_ => {
    this.isIdealDialogOpen = false;
    this.autoLogoffTimer = setTimeout(() => {
      this.autoLogOfftimerService.buttonDisabled.next(true);
      this.timeoutExpired.next(++this._count);
      this.stopTimer();
      sessionStorage.setItem('popupEnabled','true');
      this.logout();
    }, this.logOutWaitingtime)
  })
  }
  getTimer(){
    return this.subject.asObservable();
  }
  private timerComplete(displayAlert: boolean) {
    if (!this.cartUtils.isEmbed()) {
    this.timeoutExpired.next(++this._count);
    this.stopTimer();
    if (!displayAlert) {
      this.logout();
    } else {
      this.utils.showCommonAlert(this.localisation.captions.common.AutologoffWarning, AlertType.Warning, ButtonTypes.Ok);
      sessionStorage.setItem('popupEnabled','true')
      this.timeoutMethod = setTimeout(() => {
        this.logout();        
      }, this.logOutWaitingtime);
    }
   }
  }

  onUserLogOutClick() {
    this.resetOnTrigger = false;
    if (this.timeoutExpired) {
      this.timeoutExpired.complete();
    }
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.timeoutMethod) {
      clearTimeout(this.timeoutMethod);
    }
    this.enableAlert = false;
    this._timeoutSeconds = 0;
  }
}
