import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { UiControlService } from 'src/app/eatecui/source/shared/services/ui-controls.service';
import { NavigationAuthorizedConfig, TransactionAuthorizedConfig, UiConfiguration } from './ui-control-config.interface';
import { AddInventoryModel, AuthorizationSubModule, AuthorizedDataModel, 
AuthorizedGroupModel, PermissionResource, UiControlConfiguration, UiControls } from './ui-controls.interface';
// import * as AuthSample from '../../../../assets/json/ui-control/authoriztion-sample.json';
import { ActionIconButtons, CustomFilterQuery, DetailsBuilderData, ItemListHeaderConfiguration, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AuthorizationModule, AuthoriztionKey, AuthSubModule } from './authorization.enum';
import { AuthControls } from './authorization-controls';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { InventoryDefaultSelect } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { IntegrationCollection } from 'src/app/eatecui/source/shared/integration/integration-interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';

export const TransactionAuthorization: TransactionAuthorizedConfig = (controlConfiguration: Array<UiControlConfiguration>,
EatecUiControls: Array<UiControls>, uiControlService: UiControlService, TransactionData: TransactionConfiguration, 
AuthorizedData: Array<AuthorizedDataModel>,
RouterNavigateService ?: RouteNavigateService, AuthorizationModuleName = null, intergrationService ?: IntegrationService, systemPreferenceService?: SysytemPreferenceService ): TransactionConfiguration => {
        try {
            const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
            const transactionData: TransactionConfiguration = TransactionData;
            const authSample = null;
            const selectedInventoryType = sessionStorage.getItem('appsetting') ?
                JSON.parse(sessionStorage.getItem('appsetting')).InventoryselectDefault : InventoryDefaultSelect;
            // eslint-disable-next-line max-len
            const AuthorizedDataResult: Array<AuthorizedDataModel> = ( AuthorizedData && AuthorizedData !== null ) ? AuthorizedData : 
            ( AuthorizationData && AuthorizationData !== null ) ? AuthorizationData : authSample;
            if ( transactionData && AuthorizedDataResult && AuthorizedDataResult.length > 0 ) {
               const transactionName = ( transactionData && transactionData.hasOwnProperty('AuthorizationKey') ) ? 
               transactionData.AuthorizationKey.toString().toLowerCase() : transactionData.Name.toString().toLowerCase().replace(' ', '');
               // eslint-disable-next-line max-len
               const GetAuthorizationModuleName = ( AuthorizationModuleName && AuthorizationModuleName !== null ) ? AuthorizationModuleName : AuthorizationModule.TRANSACTION;
               // eslint-disable-next-line max-len
               const authDataModel: Array<AuthorizedDataModel> = AuthorizedDataResult.filter((x: AuthorizedDataModel) => x.Name === GetAuthorizationModuleName);
               if ( authDataModel && authDataModel.length > 0 ) {
                authDataModel.forEach((DataModel: AuthorizedDataModel) => {
                    if (DataModel && DataModel.hasOwnProperty('SubModules') && DataModel.SubModules.length > 0) {
                        DataModel.SubModules.forEach((authSubModels: AuthorizationSubModule) => {
                            const includeTransactionName = authSubModels.Name.toString().toLowerCase().includes(transactionName);
                            if (
                                transactionName === authSubModels.Name.toString().toLowerCase() ||
                                includeTransactionName ||
                                authSubModels.Name === AuthoriztionKey.LOADORDERGUIDE ||
                                authSubModels.Name === AuthoriztionKey.REVERSE ||
                                authSubModels.Name === AuthoriztionKey.ASSIGNBIDS ||
                                authSubModels.Name === AuthoriztionKey.SUGGESTQTY || 
                                authSubModels.Name === AuthSubModule.APPROVALS ||
                                authSubModels.Name.toString().toLowerCase() === AuthoriztionKey.PACKAGESMODULENAME.toString().toLowerCase() ||
                                (transactionName === AuthSubModule.TEMPLATE && authSubModels.Name === AuthSubModule.REQUISTION) ||
                                transactionName === AuthSubModule.MASTERLIMIT
                            ) {
                                authSubModels.PermissionResource.forEach((permissionResource: PermissionResource) => {
                                    const permissiionRName = permissionResource.Name;
                                    if (
                                        permissiionRName === AuthoriztionKey.CANCELREQUISITION ||
                                        permissiionRName === AuthoriztionKey.CANCELPURCHASEREQUEST ||
                                        permissiionRName === AuthoriztionKey.CANCELPURCHASEORDER
                                    ) {
                                        AuthControls.TransCancellation(transactionData, permissionResource, systemPreferenceService);
                                    }
                                    //  Create Transaction Implemnetation
                                    if (permissiionRName === AuthoriztionKey.CREATEREQUISITION || 
                                        permissiionRName === AuthoriztionKey.CREATEPACKAGE || 
                                        permissiionRName === AuthoriztionKey.CREATESALES ||
                                        permissiionRName === AuthoriztionKey.CREATEREQUISITIONTEMPLATE) {
                                        AuthControls.TransCreate(transactionData, permissionResource);
                                    }
                                    if ((permissiionRName === AuthoriztionKey.CANCELREQUISITIONTEMPLATE && transactionName === 'template') ||
                                     permissiionRName === AuthoriztionKey.REACTIVATEDEACTIVATEMASTERLIMITATION) {
                                        AuthControls.TransCancellation(transactionData, permissionResource);
                                    }
                                    //  Transaferout Implementation
                                    if (permissiionRName === AuthoriztionKey.CREATETRANFEROUT) {
                                        transactionTransaferOut(transactionData, permissionResource , DataModel.SubModules);
                                    }
                                    if (permissiionRName === AuthoriztionKey.CREATETRANFER) {
                                        transactionTransafer(transactionData, permissionResource, DataModel.SubModules);
                                    }
                                    if (permissiionRName === AuthoriztionKey.CREATEPURCHASEORDER) {
                                        transactionPOReq(transactionData, permissionResource, DataModel.SubModules);
                                        transactionPOPR(transactionData, permissionResource, DataModel.SubModules);
                                    }
                                    //  Load order guide Implementation
                                    if (permissiionRName.toString().toLowerCase() === transactionName.replace(/ +/g, '') 
                                        && authSubModels.Name === AuthoriztionKey.LOADORDERGUIDE ) {
                                        transactionloadeOrderGuide(transactionData, permissionResource);
                                    }
                                    // Reverse transfer Implementation
                                    if (
                                        permissiionRName === AuthoriztionKey.REVERSETRANSFEROUT ||
                                        permissiionRName === AuthoriztionKey.REVERSESPOILAGE ||
                                        permissiionRName === AuthoriztionKey.REVERSEINVOICE ||
                                        permissiionRName === AuthoriztionKey.REVERSEAP ||
                                        permissiionRName === AuthoriztionKey.REVERSESALES ) {
                                        transactionAuthReverse(transactionData, permissionResource, transactionName);
                                    }
                                    // AssignBids Implementation
                                    if (authSubModels.Name === AuthoriztionKey.ASSIGNBIDS) {
                                        transactionAssignBids(transactionData, permissionResource);
                                    }
                                    if (authSubModels.Name === AuthoriztionKey.SUGGESTQTY) {
                                        transactionSuggestQuantity(transactionData, permissionResource);
                                    }
                                    if ( permissiionRName === AuthoriztionKey.ACTIVEDEACTIVEPACKAGES ) {
                                        transactionActiveDeactive(transactionData, permissionResource);
                                    }
                                    // Below lines are commented same method call above
                                    // if ( permissiionRName === AuthoriztionKey.ACTIVEDEACTIVEPACKAGES ) {
                                    //     transactionActiveDeactive(transactionData, permissionResource);
                                    // }
                                    if ( permissiionRName === AuthoriztionKey.DOCUMENTAPPROVALS ) {
                                        transactionapproval(transactionData, permissionResource, intergrationService);
                                    }
                                });
                            }

                        });
                    }
                });
            }

            // Add the line for Iframe Inventory Loaded Authorization 
            // eslint-disable-next-line max-len
            const authMasterDataModel: Array<AuthorizedDataModel> = AuthorizedDataResult.filter((x: AuthorizedDataModel) => x.Name === AuthorizationModule.MASTERMANAGEMENT);
            if (authMasterDataModel && authMasterDataModel.length > 0) {
                authMasterDataModel.forEach((DataModel: AuthorizedDataModel) => {
                    if (DataModel && DataModel.hasOwnProperty('SubModules') && DataModel.SubModules.length > 0) {
                        const addinventoryModel: AddInventoryModel  = transctionAddNewInventory(transactionData);
                        // eslint-disable-next-line max-len
                        // const InventoryCheck = DataModel.SubModules.filter(x => x.Name.includes('inventory')).filter(e => e.PermissionResource.filter(f => ( f.Name === 'CreateFBInventory' || f.Name === 'CreateRetailInventory' || f.Name === 'CreateRecipe') && f.IsActive).length > 0 );
                        let AddNewCheck = false;
                        const FBInventory = DataModel.SubModules
                        .filter(x => x.Name === addinventoryModel.fbSubmoduleName)[0].PermissionResource;
                        const EnableAddFBButton  = FBInventory.filter(z => addinventoryModel.fbPermissionKey
                        .findIndex(e => e === z.Name) > -1).every(e => e.IsActive);
                        
                        const RetailInventory = DataModel.SubModules
                        .filter(x => x.Name === addinventoryModel.retailSubmoduleName)[0].PermissionResource;
                        
                        const EnableAddRetailButton  = RetailInventory
                        .filter(z => addinventoryModel.retailPermissionKey.findIndex(e => e === z.Name) > -1).every(e => e.IsActive);
                        
                        AddNewCheck = EnableAddFBButton || EnableAddRetailButton;
                        // eslint-disable-next-line max-len
                        if ( TransactionData && TransactionData.CreateTranscationConfig && TransactionData?.CreateTranscationConfig?.ItemsConfig?.ItemList?.AddNewListConfig?.hasOwnProperty('AddNewInventory') ) {
                            // eslint-disable-next-line max-len
                            TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewInventory.EnableAddNewInventory = AddNewCheck;
                            if ( EnableAddFBButton && EnableAddRetailButton ) {
                                TransactionData.CreateTranscationConfig.ItemsConfig.ItemList
                                .AddNewListConfig.AddNewInventory['InventoryType'] = selectedInventoryType;
                                TransactionData.CreateTranscationConfig.ItemsConfig.ItemList
                                .AddNewListConfig.AddNewInventory.SystemPreference = 3;
                            } else if ( AddNewCheck ) {
                                TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewInventory['InventoryType'] = EnableAddRetailButton ? 2 : 1;
                                TransactionData.CreateTranscationConfig.ItemsConfig.ItemList
                                .AddNewListConfig.AddNewInventory.SystemPreference = EnableAddRetailButton ? 2 : 1;
                            }
                        }

                    }      
                });
            }
            // Add the line for Iframe Inventory Loaded Authorization 
            // eslint-disable-next-line max-len
            const authReportDataModel: Array<AuthorizedDataModel> = AuthorizedDataResult.filter((x: AuthorizedDataModel) => x.Name === AuthorizationModule.REPORTSMANGEMENT);
            if (authReportDataModel && authReportDataModel.length > 0) {
                authReportDataModel.forEach((DataModel: AuthorizedDataModel) => {
                    if (DataModel && DataModel.hasOwnProperty('SubModules') && DataModel.SubModules.length > 0) {
                        // eslint-disable-next-line max-len
                        const PrintCheck = DataModel.SubModules.filter(x => x.Name.includes('reports')).filter(e => e.PermissionResource.filter(f => (f.Name === 'Reports') && f.IsActive).length > 0);
                        // eslint-disable-next-line max-len
                        if (TransactionData && TransactionData.CreateTranscationConfig && TransactionData.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.filter(x => x.displayName.indexOf('Print') >= 0).length > 0) {
                            // eslint-disable-next-line max-len
                            TransactionData.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(buttons => {
                                if (buttons.displayName.indexOf('Print') >= 0) {
                                    buttons.buttonType = (PrintCheck.length > 0) ? buttons.buttonType : buttons.buttonType + ' d-none';
                                    buttons.buttonKey = (PrintCheck.length > 0) ? buttons.buttonKey : 'DisabledButton';
                                    // buttons.displayProperity = true;
                                }
                                //
                            });
                        }
                        if (TransactionData && TransactionData.hasOwnProperty('ViewTranscationConfig') &&
                        TransactionData.ViewTranscationConfig.hasOwnProperty('HeaderConfiguration') &&
                        TransactionData.ViewTranscationConfig.HeaderConfiguration.hasOwnProperty('ActionButtonConfig') &&
                        TransactionData.ViewTranscationConfig.HeaderConfiguration.ActionButtonConfig.filter(x =>
                             x.hasOwnProperty('displayName') &&
                                x.displayName.indexOf('Print') > -1).length > 0) {
                            // eslint-disable-next-line max-len
                            TransactionData.ViewTranscationConfig.HeaderConfiguration.ActionButtonConfig.forEach(buttons => {
                                if (buttons.displayName.indexOf('Print') > -1) {
                                    buttons.buttonType = (PrintCheck.length > 0) ? buttons.buttonType : buttons.buttonType + ' d-none';
                                    buttons.buttonKey = (PrintCheck.length > 0) ? buttons.buttonKey : 'DisabledButton';
                                    // buttons.displayProperity = (PrintCheck.length > 0 ) ? false : true;
                                }
                                //
                            });
                        }
                        if (TransactionData && TransactionData.hasOwnProperty('CopyAsNewConfig') && 
                        TransactionData.CopyAsNewConfig.hasOwnProperty('CopyNewItemListConfig') &&
                        TransactionData.CopyAsNewConfig.CopyNewItemListConfig.hasOwnProperty('ItemListHeaderConfig') &&
                        TransactionData.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.filter(x =>
                             x.Name === 'Print').length > 0) {
                            TransactionData.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.forEach(header => {
                                if (header.Name === 'Print') {
                                    header.Enable = (PrintCheck.length > 0) ? true : false;
                                }
                            });   
                        }
                    }
                });
            }
        }
        return null;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};
function transactionAssignBids(TransactionData: TransactionConfiguration, permissionResource: PermissionResource): void {
    try {
        const transationName = TransactionData.Name;
        if (transationName.replace(' ', '') === permissionResource.Name || permissionResource.Name.includes(transationName)) {
            TransactionData.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach((buttonModel: AgilysysButtonModel) => {
                if (buttonModel.hasOwnProperty('moreOption') && buttonModel.moreOption) {
                    if (!permissionResource.IsActive) {
                        const assignBidsIndex = buttonModel.moreOption.optionData
                            .findIndex(x => x.key.toString().toLowerCase() === AuthoriztionKey.ASSIGNBIDS.toString().toLowerCase());
                        const optionList = buttonModel.moreOption.optionData.splice(assignBidsIndex, 1);
                    }
                }
            });
        }
    } catch (error) {
        console.error(error);
    }
}

    function transactionSuggestQuantity(TransactionData: TransactionConfiguration, permissionResource: PermissionResource): void {
        try {
            const transationName = TransactionData.Name;
            if ( transationName.replace(' ', '') === permissionResource.Name || permissionResource.Name.includes(transationName)) {
            TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.ActionIcons.
                forEach((ActionIcons: ActionIconButtons, index: number) => {
                    if (ActionIcons.Name === 'SuggestQuantity') {
                        if (!permissionResource.IsActive) {
                            // eslint-disable-next-line max-len
                            TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.ActionIcons.splice(index, 1);
                        }
                    }
                });
        }
    } catch (error) {
        console.error(error);
    }
}

    function transactionAuthReverse(TransactionData: TransactionConfiguration, permissionResource: PermissionResource,
            ModuleName: string): void {
        try {
            if (TransactionData.CopyAsNewConfig.CopyNewItemListConfig && 
                TransactionData.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig &&
                TransactionData.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.length > 0) {
                TransactionData.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.forEach((x: ItemListHeaderConfiguration) => {
                    if (x.hasOwnProperty('AuthorizationKey') && x.AuthorizationKey === permissionResource.Name) {
                        x['Enable'] = permissionResource.IsActive;
                    }
                });
            }
            // Added becoz reverse invoice is applicable for grid also and checked receiving module name becoz if reverse 
            // invoice is unchecked and hided the checkbox column got reflected in req, transfer and other modules.
            // So by wanted checked the condition when receiving module comes
            // Fixed for bug 54227
            if (TransactionData.ViewTranscationConfig.GridConfiguration &&
                TransactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination &&
                TransactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.length > 0
                && ((permissionResource.Name === AuthoriztionKey.REVERSEINVOICE && ModuleName === 'receiving') || 
                (permissionResource.Name === AuthoriztionKey.REVERSESALES && ModuleName === 'sales')) ) {
                // This block is to hide the checkbox column in grid 
                if (TransactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.length > 0 ) {
                    TransactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.forEach(header => {
                        if (header.hasOwnProperty('headerCheckboxSelection') && header.hasOwnProperty('checkboxSelection')) {
                            header.hide = !permissionResource.IsActive;
                        } 
                    });
                }
            }
            // Added for reverse ap
            if (TransactionData.ViewTranscationConfig.GridConfiguration &&
                TransactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination && 
                TransactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.length > 0
                && permissionResource.Name === AuthoriztionKey.REVERSEAP) {
                TransactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.forEach(header => {
                    if (header.hasOwnProperty('cellRenderer') && header.cellRenderer === 'actionRenderer') {
                        header.hide = !permissionResource.IsActive;
                    }
                });
            }
        } catch ( error ) {
            console.error(error);
        }
    }

function transactionTransaferOut(TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource, SubModules: AuthorizationSubModule[]): void {
    try {
        TransactionData.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.forEach((x: DetailsBuilderData) => {
            if (x.OdataKey === 'IsTransferOut') {
                x.Hide = !permissionResource.IsActive;
            }
        });
        const subModule = SubModules.filter(x => x.Name === 'requisitions')[0];
        const permissionResources = subModule.PermissionResource[0].IsActive;
        TransactionData.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach(breadCrumb => {
            if (breadCrumb.Type === 'dropdown' && breadCrumb.DataOption.length > 0) {
                breadCrumb.DataOption.forEach(data => {
                    if (data.hasOwnProperty('AuthorizedKey') && data.AuthorizedKey === subModule.PermissionResource[0].Name) {
                        data.Enable = permissionResources;
                    }
                });
                // breadCrumb.SelectedTypeId = breadCrumb.DataOption.filter(x => x.Enable === true)[0].Id;
            }
        });
        TransactionData.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.forEach((BuilderData: DetailsBuilderData) => {
        if ( BuilderData.hasOwnProperty('CustomFilterQuery') && BuilderData.CustomFilterQuery.length > 0 ) {
            BuilderData.CustomFilterQuery.forEach((customFilterQuery: CustomFilterQuery) => {
                if ( customFilterQuery.hasOwnProperty('AuthorizationKey') && customFilterQuery.AuthorizationKey.length > 0 ) {
                    const CustomAuthKey = customFilterQuery.AuthorizationKey.findIndex(x => x === permissionResource.Name);
                    if ( CustomAuthKey > -1 ) {
                         if ( !permissionResource.IsActive ) {
                             customFilterQuery.DefaultValue = [2, 3, 9, 10, 6, 5, 1 ];
                         }
                    }
                }
             }); 
            }
        });

    } catch (error) {
        console.error(error);
    }
}
function transactionTransafer(TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource, SubModules: AuthorizationSubModule[]): void {
    try {
        TransactionData.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.forEach((BuilderData: DetailsBuilderData) => {
            if ( BuilderData.hasOwnProperty('CustomFilterQuery') && BuilderData.CustomFilterQuery.length > 0 ) {
            BuilderData.CustomFilterQuery.forEach((customFilterQuery: CustomFilterQuery) => {
                if ( customFilterQuery.hasOwnProperty('AuthorizationKey') && customFilterQuery.AuthorizationKey.length > 0 ) {
                    const CustomAuthKey = customFilterQuery.AuthorizationKey.findIndex(x => x === permissionResource.Name);
                    if ( CustomAuthKey > -1 ) {
                         if ( !permissionResource.IsActive ) {
                             customFilterQuery.DefaultValue = [ 8 ];
                         }
                    }
                }
             }); 
            }
        });

    } catch (error) {
        console.error(error);
    }
}

function transactionPOReq(TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource, SubModules: AuthorizationSubModule[]): void {
    try {
        const subModule = SubModules.filter(x => x.Name === 'requisitions')[0];
        const permissionResources = subModule.PermissionResource[0].IsActive;
        TransactionData.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach(breadCrumb => {
            if (breadCrumb.Type === 'dropdown' && breadCrumb.DataOption.length > 0) {
                breadCrumb.DataOption.forEach(data => {
                    if (data.hasOwnProperty('AuthorizedKey') && data.AuthorizedKey === subModule.PermissionResource[0].Name) {
                        data.Enable = permissionResources;
                    }
                });
            }
        });

    } catch (error) {
        console.error(error);
    }
}

function transactionPOPR(TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource, SubModules: AuthorizationSubModule[]): void {
    try {
        const subModule = SubModules.filter(x => x.Name === 'purchaserequest')[0];
        const permissionResources = subModule.PermissionResource[0].IsActive;
        TransactionData.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach(breadCrumb => {
            if (breadCrumb.Type === 'dropdown' && breadCrumb.DataOption.length > 0) {
                breadCrumb.DataOption.forEach(data => {
                    if (data.hasOwnProperty('AuthorizedKey') && data.AuthorizedKey === subModule.PermissionResource[0].Name) {
                        data.Enable = permissionResources;
                    }
                });
            }
        });

    } catch (error) {
        console.error(error);
    }
}

function transactionloadeOrderGuide(TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource): void {
    try {
        TransactionData.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.forEach((x: DetailsBuilderData) => {
            if (x.OdataKey === 'IsloadOrderGuide' || x.OdataKey === 'SupplierId' && TransactionData.Name !== 'Receiving') {
                x.Hide = !permissionResource.IsActive;
            }
        });
        if (TransactionData.hasOwnProperty('LoadOrderGuide')) {
            TransactionData.LoadOrderGuide = permissionResource.IsActive;
        }
    } catch (error) {
        console.error(error);
    }
}

function transactionActiveDeactive(TransactionData: TransactionConfiguration,
permissionResource: PermissionResource): void {
     try {
            const TransactionGridAction = TransactionData.ViewTranscationConfig.GridConfiguration.GridRowActions;
            for (const property in TransactionGridAction) {
                if ( property ) {
                    if ( property === 'Deactivate' || property === 'Activate') {
                        TransactionGridAction[property] = permissionResource.IsActive ;
                    }
                }
              }
     } catch ( error ) {

     }
}

function transctionAddNewInventory(TransactionData: TransactionConfiguration): AddInventoryModel {
    try {
        const salesModule: Array<string> = ['sales', 'PlanHead'];
        const addInventoryModel = {} as AddInventoryModel;
        if ( salesModule.findIndex(e => e === TransactionData.Name ) > -1 ) {
          addInventoryModel.fbSubmoduleName = 'fbProduct';
          addInventoryModel.retailSubmoduleName = 'retailProduct';
          addInventoryModel.fbPermissionKey = ['CreateFBProduct', 'ViewFBProduct'];
          addInventoryModel.retailPermissionKey = ['CreateRetailProduct', 'ViewRetailProduct'];
        } else {
          addInventoryModel.fbSubmoduleName = 'fbinventory';
          addInventoryModel.retailSubmoduleName = 'retailinventory';
          addInventoryModel.fbPermissionKey = ['CreateFBInventory', 'ViewFBInventory'];
          addInventoryModel.retailPermissionKey = ['CreateRetailInventory', 'ViewRetailInventory'];
        }
        return addInventoryModel;
    } catch ( error ) {
        console.error(error);
    }
}

function transactionapproval(TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource, intergrationService ?: IntegrationService): void {
         try {
             if ( TransactionData.CopyAsNewConfig && TransactionData.CopyAsNewConfig.HeaderConfig ) {
                const GetApprovalColl: Array<IntegrationCollection> = intergrationService.IntegrationConfig.IntegrationCollection.filter(x=> x.IntegratonName === 'DocApprovals');
                const approvalCollections: Array<string> = ['hold', 'deny', 'approve'];
                TransactionData.CopyAsNewConfig.HeaderConfig.ActionButtonConfig
                .forEach((buttonModel: AgilysysButtonModel ) => {
                   if ( approvalCollections.findIndex((e: string) => e === buttonModel.buttonKey) > -1 ) {
                       buttonModel.buttonType = ( permissionResource.IsActive && (GetApprovalColl.length > 0 && GetApprovalColl[0].IsEnabled) ) ? 'agilysys-button-secondary' : 'agilysys-button-secondary d-none';
                   }
                });
             }
         } catch ( error ) {
    
         }
    }

