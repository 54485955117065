import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryRulesDataCollection } from 'src/app/eatecui/source/transaction/shared/interface/custom-form/custom-form-configuration.interface';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import _ from 'lodash';

@Component({
    selector: 'app-delivery-rules-popup',
    templateUrl: './delivery-rules-popup.component.html',
    styleUrls: ['./delivery-rules-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

export class DeliveryRulesPopupComponent implements OnInit {
  agilysysButtonModel: AgilysysButtonModel[];
  clonedDataCollection: any;
  data: DeliveryRulesDataCollection;
  constructor(@Inject(MAT_DIALOG_DATA) public dataCollec, public dialogRef: MatDialogRef<DeliveryRulesPopupComponent>) {    
    if (this.clonedDataCollection) {
      this.data = this.clonedDataCollection;
    } else {
      this.data = dataCollec.data;
      this.clonedDataCollection = _.cloneDeep(this.data);
    }    
  }
  /**
   * @method orderDayClick
   * @param index 
   * @param orderDayName 
   */
  orderDayClick(index: number, orderDayName: string): void {
    try {
      const orderDayIndex = this.data.DeliveryRulesCollection[index].OrderDay.findIndex(x => x.Name === orderDayName);
      this.data.DeliveryRulesCollection[index].OrderDay[orderDayIndex].IsChecked = !this.data.DeliveryRulesCollection[index].OrderDay[orderDayIndex].IsChecked;
      if (!this.data.DeliveryRulesCollection[index].OrderDay[orderDayIndex].IsChecked) {
        this.data.DeliveryRulesCollection[index].SelectedOrderDay = '';
      } else {
        this.data.DeliveryRulesCollection[index].SelectedOrderDay = orderDayName;
      }      
      this.data.DeliveryRulesCollection[index].OrderDay.forEach(d => {
        if (d.Name !== this.data.DeliveryRulesCollection[index].OrderDay[orderDayIndex].Name)
        d.IsChecked = false;
      });
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method cancelDeliveryRule
   */
  cancelDeliveryRule(): void {
    try {
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method saveDeliveryRule
   */
  saveDeliveryRule(): void {
    try {
      this.clonedDataCollection = this.data;
      this.dialogRef.close(this.data);
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method resetDeliveryRule
   */
  resetDeliveryRule(): void {
     try {
       this.data.DeliveryRulesCollection.forEach(collec => {
          collec.OrderDay.forEach(orderDay => {
            orderDay.IsChecked = false;
          });
          collec.OrderTime = '12:00 AM';
          collec.SelectedOrderDay = '';
       });
       this.clonedDataCollection = _.cloneDeep(this.data);

     } catch (error) {
      console.log(error);
     }
  }
  /**
   * @method resetRow
   */
  resetRow(index: number): void {
    try {
      console.log(index);
      this.data.DeliveryRulesCollection[index].OrderDay.forEach(oday => {
        oday.IsChecked = false;
      });
      this.data.DeliveryRulesCollection[index].OrderTime = '12:00 AM';
      this.data.DeliveryRulesCollection[index].SelectedOrderDay = '';
    } catch(error) {
      console.log(error);
    }
  }
  ngOnInit() {
    this.agilysysButtonModel = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.cancelDeliveryRule();
        }
      },
      {
        displayProperity: false,
        displayName: 'Reset',
        buttonKey: 'Reset',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
         this.resetDeliveryRule();
        }
      },
      {
        displayProperity: false,
        displayName: 'Save',
        buttonKey: 'Save',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
        this.saveDeliveryRule();
        }
      }
    ];
  }
}