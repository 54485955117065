import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-warning-bar',
  templateUrl: './warning-bar.component.html',
  styleUrls: ['./warning-bar.component.scss']
})
export class WarningBarComponent  {
  @Input() message;
  @Input() showClose;
  @Input() hyperLink;
  @Output() closeWarning: EventEmitter<any> = new EventEmitter();
  @Output() hyperLinkClick: EventEmitter<any> = new EventEmitter();

  
  closeBar() {
    this.closeWarning.emit();
  }
  hyperLinkEmit(){
    this.hyperLinkClick.emit();
  }

}
