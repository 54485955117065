import { Injectable } from '@angular/core';
// import { Observable, of } from 'rxjs';
// import { DropdownOptions, TableHeaderOptions } from 'src/app/common/Models/ag-models';
// import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
// import { PMSLocalization } from 'src/app/core/localization/pms-localization';

import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { ReservationDetailDataService } from '../../../dataservices/reservationdetail.data.service';
import { NotificationTemplate, NotificationTemplateContents } from 'src/app/common/templates/template-email/crud-email-template/crud-email-template.model';
import { DefaultLanguage, EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { UI, API } from './preview-report-content.models';
import { NotifyTemplateType, sendEmailRequest } from '../preview-report.model';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { Product } from 'src/app/common/enums/shared-enums';

@Injectable()
export class AgPreviewReportContentBusiness {
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private localization: Localization,
    private templateDataService: TemplateDataServices,
    private dataService: ReservationDetailDataService,
    private _utils: CommonUtilities) {
    this.captions = this.localization.captions;

  }


  public async NotifyConfirmationLetter(letterRequestDetails: sendEmailRequest): Promise<any> {
    const apiModels = await this.dataService.SendEmailWithAttachment(letterRequestDetails);
    return apiModels;
  }

  async generateTableData(type: number, includeInactive: boolean, preferredLanguageId: number) {
    let currentProductId: number = Number(this._utils.GetPropertyInfo('ProductId'));
    let isLanguageTemplateRequired: boolean = (currentProductId == Product.PMS && type == NotifyTemplateType.Email);
    let apiModel: NotificationTemplate[] = await this.templateDataService.GetAllTemplates(type, includeInactive);
    apiModel = apiModel.filter(x => x.eventIds.indexOf(EventNotification.FolioStatement) !== -1);
    return apiModel.map((x, index) => this.uiEditMapper(x, index, isLanguageTemplateRequired, preferredLanguageId));
  }

  private uiEditMapper(template: NotificationTemplate, index: number, isLanguageTemplateRequired: boolean, preferredLanguageId: number): UI.Template {
    let templateId: string = '';
    if (isLanguageTemplateRequired) {
      templateId = this.fetchLanguageTemplateId(template?.notificationTemplateContents, preferredLanguageId);
    }
    return {
      code: template.templateCode,
      type: template.typeId,
      rcode: template.templateCode,
      name: template.templateName,
      templateData: '',
      active: template.isActive,
      listOrder: template.listOrder,
      id: (templateId == undefined || templateId == '') ? template.templateId : templateId,
      checked: index === 0,
      default: template.isDefault
    } as UI.Template;
  }

  private fetchLanguageTemplateId(notificationTemplateContents: NotificationTemplateContents[], preferredLanguageId: number) {
    let templateId: string;
    if(notificationTemplateContents == undefined || notificationTemplateContents.length < 1){
      return templateId;
    }
    if (preferredLanguageId > 0) {
      templateId = notificationTemplateContents.find(x => x.languageId == preferredLanguageId)?.templateId ?? '';
    }
    if (templateId == undefined || templateId == '') {
      templateId = notificationTemplateContents.find(x => x.languageId == Number(this._utils.GetPropertyInfo('LanguageId')))?.templateId ?? '';
    }
    if (templateId == undefined || templateId == '') {
      templateId = notificationTemplateContents.find(x => x.languageId == DefaultLanguage.English)?.templateId ?? '';
    }
    return templateId;
  }

}
