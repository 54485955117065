import { Pipe, PipeTransform } from '@angular/core';
import { RetailLocalization } from '../common/localization/retail-localization';

@Pipe({
  name: 'localizedDecimalValue'
})
export class LocalizedDecimalValuePipe implements PipeTransform {

  constructor(private localization:RetailLocalization){}
  transform(value: any): any {
    return this.localization.localizeCurrency(value,false);
  }

}
