<section class="golf__formsection {{field.class}}" [formGroup]="form"  *ngIf="fieldType == 'MultipleData'">
    <div [formArrayName]="formArrayName">
      <div *ngFor="let formObj of form.get(formArrayName).controls; let j=index">
        <div>
          <mat-radio-group [id]="j" [name]="j" class="golf__radio" [value]="field.fieldType.MultipleData.data[j].value" formControlName='{{j}}'  aria-labelledby="example-radio-group-label">
          <label class="golf--form--label">{{field.label}}</label>
          <mat-radio-button *ngFor="let opt of field.fieldType.MultipleData.data[j].options" [value]="opt.key" class="golf--form-radio-button">
             {{opt.label}}
          </mat-radio-button>
          <mat-error *ngIf="!isValid && isDirty">{{errorMessage}}</mat-error>
          </mat-radio-group>
        </div>
        <app-form-icon *ngIf="field.fieldType.MultipleData.addMore && j !== 0" [objectIndex]="j" [formIconType]="formRemoveBuilderIcons"
        [formArray]="form.get(formArrayName)" [formObject]="formArrayName" (iconEmitter)="iconCallback($event, form)">
      </app-form-icon>
      <app-form-icon class="golf--add-more"
        *ngIf="field.fieldType.MultipleData.addMore && j == (form.get(formArrayName).controls.length-1) && field.fieldType.MultipleData.maxCount >= form.get(formArrayName).controls.length"
        [formIconType]="formAddBuilderIcons" [formArray]="form.get(formArrayName)" [formObject]="field.fieldType.MultipleData.data[j]"
        (iconEmitter)="iconCallback($event)" (fielArrayEmiter)="fieldArrayCallback($event)">
      </app-form-icon>
      </div>
    </div>
  </section>

  <section *ngIf="fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'" class="{{field.class}}">
    <div [formGroup]="form" #formtext>
    <!-- <i *ngIf="field.iconName" class="icon-{{field.iconName}} pr8"></i> -->
    <mat-radio-group class="golf__radio" [value]="field.fieldType[fieldType].value" [formControlName]="formFieldName" [disabled]="field.fieldType.SingleData.disbaledProperty"  aria-labelledby="example-radio-group-label">
      <label class="golf--form--label" [ngClass] = "field.fieldType.SingleData.disbaledProperty ? 'disable-class' : '' ">{{formFieldlabel}}</label> 
      <mat-radio-button *ngFor="let opt of field.fieldType[fieldType].options" (change)="eventCallback(0, null)" [value]="opt.key" class="golf--form-radio-button">
         {{opt.label}}
      </mat-radio-button>
      <mat-error *ngIf="!isValid && isDirty">{{errorMessage}}</mat-error>
      </mat-radio-group>  
    </div>
</section>



