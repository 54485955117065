import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { CreateTransactionItemlist } from '../create-transcation/item-transaction';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomPOSelectFillData: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: FormType[], CustomData?: TransactionConfiguration
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            const POId = parseInt(FormGroupData.value[FieldGrouping.CustomFormAction.CutomFieldFrom], 10);
            if (FormField.length !== MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields.length) {
                FormField = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields;
            }
            const EndPointUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}/${POId}?transtype=3`;
            httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                const ResultJson = JSON.parse(res['value']);
                console.log('PO Response', ResultJson);
                FormGroupData.controls['LocationId'].setValue(ResultJson.LocationId);
                FormGroupData.controls['DepartmentId'].setValue(ResultJson.DepartmentId);
                FormGroupData.controls['DebitAccId'].setValue(ResultJson.DebitAccId);
                const PODetails = ResultJson.PurchaseOrderDetails;
                const returnData = AssignPODetails(PODetails, MasterDataCollection);                
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded(PODetails, true);
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody
                = CreateTransactionItemlist.itemListTableCard
                (ViewModelEntity, PostModelEntity, MasterDataCollection,
                    httpService, PostModelEntity, ViewModelEntity,
                    null, null, returnData, FormGroupData);
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard = {
                    ... MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard
                };
                FormField[1].fieldType.SingleData.disbaledProperty = false;
                FormField[2].fieldType.SingleData.disbaledProperty = false;
                FormField[3].fieldType.SingleData.disbaledProperty = false;
                FormField[4].fieldType.SingleData.disbaledProperty = false;
                FormField[5].fieldType.SingleData.disbaledProperty = false;
                FormField[7].fieldType.SingleData.disbaledProperty = false;
                FormField[8].fieldType.SingleData.disbaledProperty = false;
                FormField[9].fieldType.SingleData.disbaledProperty = false;
                FormField[11].fieldType.SingleData.disbaledProperty = false;
            });
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function AssignPODetails(PODetails, MasterDataCollection: TransactionConfiguration) {
    try {
        PODetails.forEach(element => {            
            element.PODetailId = element.Id;
            element.Id = 0;
            element.Name = element.PODetailIngredientId.Name;
            element.Quantity = element.Qty;
            element.Units = element.PODetailIngredientId.Units;
            const transactionUnitsDetails = element['Units'].filter((unitDetails: any) =>
            unitDetails['UnitTypeId'] === element['UnitTypeId'])[0];
            if (transactionUnitsDetails) {
                element['UnitCode'] = transactionUnitsDetails.UnitCode;
                element['UnitCost'] = transactionUnitsDetails.UnitCost;
            }
        });
        return PODetails;
    } catch (error) {
        console.error('Error occurred in AssignPODetails');
    }
}