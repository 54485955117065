import { Injectable } from '@angular/core';
import { CommonControllersRoutes } from '../../communication/common-route';
import { API } from 'src/app/common/shared/shared/event-scheduler/event-scheduler.model'
import { Localization } from 'src/app/common/localization/localization';
import { NotificationCommunication } from '../../communication/services/notification-communication-service';
import{API as HistoryAPI} from 'src/app/common/shared/shared/event-scheduler-history/event-scheduler-history.model';
import { API as SFTPApi } from 'src/app/common/Models/sftp.model';

@Injectable(
    { providedIn: 'root' }
)
export class JobSchedulerDataServices {
    captions: any;
    constructor(private _notificationCommunication: NotificationCommunication, public localization: Localization) {
        this.captions = this.localization.captions;
    }

    public async isServiceAccountExist(): Promise<boolean> {
      return this._notificationCommunication.getPromise({
        route: CommonControllersRoutes.IsServiceAccountExist
      }, false);
    }

    public async getAllScheduleJob(includeInactive: boolean = true): Promise<API.ScheduleJob[]> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetAllScheduleJob,
          uriParams: { 'includeInactive': includeInactive }
        }, false);
      }

      public async getAllJobDefaultConfiguration(): Promise<API.JobDefaultConfiguration[]> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetAllScheduleJobDefaultConfiguration
        }, false);
      }

      public async getAllCustomUrlConfiguration(ids: number[]): Promise<API.JobCustomUrlConfiguration[]> {
        return this._notificationCommunication.putPromise({
          route: CommonControllersRoutes.GetAllCustomUrlConfiguration,
          body: ids
        }, false);
      }

      public async GetCustomUrlConfigurationById(id: number): Promise<API.JobCustomUrlConfiguration> {
        return this._notificationCommunication.getPromise<API.JobCustomUrlConfiguration>({
          route: CommonControllersRoutes.GetCustomUrlConfigurationById,
          uriParams: {id}
        }, false);
      }

      public async getAllSchedule(includeRelatedData: boolean = true): Promise<API.ScheduleJobConfiguration[]> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetAllSchedule,
          uriParams: { 'includeRelatedData': includeRelatedData }
        }, false);
      }

      public async getScheduleById(id: number, includeRelatedData: boolean = true): Promise<API.ScheduleJobConfiguration> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetScheduleById,
          uriParams: {id, 'includeRelatedData': includeRelatedData}
        }, false);
      }

      public async getAllScheduleJobWildCard(includeInactive: boolean = false): Promise<API.ScheduleJobWildCard[]> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetAllScheduleJobWildCard,
          uriParams: { 'includeInactive': includeInactive }
        }, false);
      }

      public retryJobManually(id: number): Promise<boolean> {
        const result = this._notificationCommunication.getPromise<boolean>({
          route: CommonControllersRoutes.RetryJobManually,
          uriParams: {id}
        });
        return result;
      }

      public exportJobOutput(id: number): Promise<boolean> {
        const result = this._notificationCommunication.getPromise<boolean>({
          route: CommonControllersRoutes.ExportJobOutput,
          uriParams: {id}
        });
        return result;
      }

      public GetAllJobHistory(data: HistoryAPI.ScheduleJobHistoryFilter): Promise<HistoryAPI.ScheduleJobHistory[]> {
        const result = this._notificationCommunication.putPromise<HistoryAPI.ScheduleJobHistory[]>({
          route: CommonControllersRoutes.GetAllJobHistory,
          body: data
        });
        return result;
      }

      public async getAllScheduleOperations(): Promise<API.ScheduleOperationDetail[]> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetAllScheduleOperations
        }, false);
      }

      public createSchedule(data: API.ScheduleJobConfiguration): Promise<boolean> {
        const result = this._notificationCommunication.postPromise<boolean>({
          route: CommonControllersRoutes.CreateSchedule,
          body: data
        }, true);
        return result;
      }

      public updateSchedule(data: API.ScheduleJobConfiguration): Promise<boolean> {
        const result = this._notificationCommunication.putPromise<boolean>({
          route: CommonControllersRoutes.UpdateSchedule,
          body: data
        }, true);
        return result;
      }

      public deleteSchedule(id: number): Promise<boolean> {
        const result = this._notificationCommunication.deletePromise<boolean>({
          route: CommonControllersRoutes.DeleteSchedule,
          uriParams: {id}
        });
        return result;
      }

      public refreshDistributionList(id: number): Promise<boolean> {
        const result = this._notificationCommunication.getPromise<boolean>({
          route: CommonControllersRoutes.RefreshDistributionList,
          uriParams: {id}
        });
        return result;
      }

      public pauseSchedule(id: number): Promise<boolean> {
        const result = this._notificationCommunication.getPromise<boolean>({
          route: CommonControllersRoutes.PauseSchedule,
          uriParams: {id}
        });
        return result;
      }

      public resumeSchedule(id: number): Promise<boolean> {
        const result = this._notificationCommunication.getPromise<boolean>({
          route: CommonControllersRoutes.ResumeSchedule,
          uriParams: {id}
        });
        return result;
      }

      public getSFTPConfiguration(): Promise<SFTPApi.SFTPConnection[]> {
        let result = this._notificationCommunication.getPromise<SFTPApi.SFTPConnection[]>(
            { route: CommonControllersRoutes.GetSFTPConfigLink}, true);
        return result;
      }

      public getSFTPConfigurationById(d : number): Promise<SFTPApi.SFTPConnection> {
        let result = this._notificationCommunication.getPromise<SFTPApi.SFTPConnection>(
            { route: CommonControllersRoutes.GetSFTPConfigLinkById}, true);
        return result;
      }
}