import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';

@Component({
  selector: 'app-multipack-item',
  templateUrl: './multipack-item.component.html',
  styleUrls: ['./multipack-item.component.scss']
})
export class MultipackItemComponent implements OnInit {
  multiPackItemFrmGrp: UntypedFormGroup;
  captions: any;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();
  radioFilter = [] ;
  constructor(private fb: UntypedFormBuilder, private controlContainer: ControlContainer,public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
    this.radioFilter =[{ id: 0, name: this.captions.AllMultipacks },
    { id: 1, name: this.captions.ExpirationDateRange },
    { id: 2, name: this.captions.SoldDate},
    { id: 3, name: this.captions.AllByGuests},
    { id: 4, name: this.captions.OutstandingOnly}];
   }

  ngOnInit() {
    this.multiPackItemFrmGrp = this.fb.group({
      radioFilter: 0
    });
    this.formReady.emit(this.multiPackItemFrmGrp);
  }

}
