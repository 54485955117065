/*
  NOTES TO DEVELOPER
  *****This page for ui developement only, In case any change in this page without authurization will affect UI*****
 */
import { Component, Input, OnInit, OnChanges, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit } from '@angular/core';
import { FormIcons } from '../form-type.entity';
import { FormArray } from '@angular/forms';
import { AdItem } from '../agi-ad-item';
import { TextBoxComponent } from '../atoms/textbox/textbox.component';
import { DropDownComponent } from '../atoms/dropdown/dropdown.component';
import { CheckBoxComponent } from '../atoms/checkbox/check-box.component';
import { DatePickerComponent } from '../atoms/date-picker/date-picker.component';
import { RadioComponent } from '../atoms/radio/radio.component';
import { SwitchComponent } from '../atoms/switch/switch.component';
import { TimePickerComponent } from '../atoms/time-picker/time-picker.component';
import { ToggleGroupComponent } from '../atoms/toggle-group/toggle-group.component';
import { MultipleFieldComponent } from '../atoms/multiple-field/multiple-field.component';
import { TextAreaComponent } from '../atoms/text-area/text-area.component';
import { CustomRowComponent } from '../atoms/custom-row/custom-row.component';
import { MultiselectDropdownComponent } from '../atoms/multiselect-dropdown/multiselect-dropdown.component';
import { DragDropComponent } from '../atoms/files/files.component';
import { MultiSelectActionComponent } from '../atoms/multi-select-action/multi-select-action.component';
import { HeaderLabelComponent } from '../atoms/header-label/header-label.component';
import { GroupedCheckboxComponent } from '../atoms/grouped-checkbox/grouped-checkbox.component';
import { CheckBoxSingleComponent } from '../atoms/check-box-single/check-box-single.component';
import { AgyDynamicFormServices } from '../agilysys-dynamicform-business';
import { GroupedItemsProductComponent } from '../atoms/grouped-items/grouped-items.component';
import { SearchAutoCompleteComponent } from '../atoms/search-autocomplete/search-autocomplete.component';
import { SearchMultiAutocompleteComponent } from '../atoms/search-multi-autocomplete/search-multi-autocomplete.component';
import { ButtonComponent } from '../atoms/button/button.component';
import { ChipListComponent } from '../atoms/chip-list/chip-list.component';
import { LabelIconComponent } from '../atoms/label-icon/label-icon.component';
import { AutocompleteBoxComponent } from '../atoms/autocomplete-box/autocomplete-box.component';
import { DaterangePickerComponent } from '../atoms/daterange-picker/daterange-picker.component';
import { LabelTextComponent } from '../atoms/label-text/label-text.component';
import { WeekListComponent } from '../atoms/week-list/week-list.component';

export interface AdComponent {
  form: any;
  field: any;
  components: any;
}

@Component({
    selector: 'lib-field-builder',
    templateUrl: './field-builder.component.html',
    styleUrls: ['./field-builder.component.scss']
})


export class FieldBuilderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() field: any;
  @Input() form: any;
  formBuilderIcons: FormIcons;
  componentLoader: any = [];
  get isValid(): any { return this.form.controls[this.field.name].valid; }
  get isDirty(): any { return this.form.controls[this.field.name].dirty; }
  @ViewChild('dynamicComponent', { read: ViewContainerRef, static: false }) dynamicComponent: ViewContainerRef;

  constructor(
    public AgyDynamicFormService: AgyDynamicFormServices,
    private componentFactoryResolver: ComponentFactoryResolver,
    ) {
      this.componentLoader = [
        new AdItem(TextBoxComponent, { form: this.form, field: this.field, type: 'text'}),
        new AdItem(TextBoxComponent, { form: this.form, field: this.field, type: 'number'}),
        new AdItem(TextBoxComponent, { form: this.form, field: this.field, type: 'password'}),
        new AdItem(TextBoxComponent, { form: this.form, field: this.field, type: 'hidden'}),
        new AdItem(DropDownComponent, { form: this.form, field: this.field, type: 'dropdown'}),
        new AdItem(CheckBoxComponent, { form: this.form, field: this.field, type: 'checkbox'}),
        new AdItem(DatePickerComponent, { form: this.form, field: this.field, type: 'datepicker'}),
        new AdItem(RadioComponent, { form: this.form, field: this.field, type: 'radio'}),
        new AdItem(SwitchComponent, { form: this.form, field: this.field, type: 'switch'}),
        new AdItem(ToggleGroupComponent, { form: this.form, field: this.field, type: 'toggleGroup'}),
        new AdItem(CustomRowComponent, { form: this.form, field: this.field, type: 'customrow'}),
        new AdItem(GroupedItemsProductComponent, { form: this.form, field: this.field, type: 'grouped'}),
        new AdItem(TimePickerComponent, { form: this.form, field: this.field, type: 'timePicker'}),
        new AdItem(TextAreaComponent, { form: this.form, field: this.field, type: 'textarea'}),
        new AdItem(MultipleFieldComponent, { form: this.form, field: this.field, type: 'multifield'}),
        new AdItem(MultiselectDropdownComponent, { form: this.form, field: this.field, type: 'multiselectdropdown'}),
        new AdItem(DragDropComponent, { form: this.form, field: this.field, type: 'dragdrop'}),
        new AdItem(MultiSelectActionComponent, { form: this.form, field: this.field, type: 'multiselectwithaction'}),
        new AdItem(HeaderLabelComponent, { form: this.form, field: this.field, type: 'label'}),
        new AdItem(GroupedCheckboxComponent, { form: this.form, field: this.field, type: 'grouped-checkbox'}),
        new AdItem(CheckBoxSingleComponent, { form: this.form, field: this.field, type: 'checkboxsingle'}),
        new AdItem(SearchAutoCompleteComponent, { form: this.form, field: this.field, type: 'autocomplete'}),
        new AdItem(SearchMultiAutocompleteComponent, { form: this.form, field: this.field, type: 'multipleautocomplete'}),
        new AdItem(ButtonComponent, { form: this.form, field: this.field, type: 'button'}),
        new AdItem(ChipListComponent, { form: this.form, field: this.field, type: 'chiplist'}),
        new AdItem(LabelIconComponent, {form: this.form, field: this.field, type: 'labelwithicon'}),
        new AdItem(LabelTextComponent, {form: this.form, field: this.field, type: 'labelwithtext'}),
        new AdItem(AutocompleteBoxComponent, {form: this.form, field: this.field, type: 'autocompletebox'}),
        new AdItem(DaterangePickerComponent, {form: this.form, field: this.field, type: 'daterange'}),
        new AdItem(WeekListComponent, {form: this.form, field: this.field, type: 'week'})
      ];
     }

ngAfterViewInit(): void {
  this.componentLoader.forEach(element => {
    if (element.data.type === this.field.type) {
     const componentFactory = this.componentFactoryResolver.resolveComponentFactory(element.component);
     const componentRef = this.dynamicComponent.createComponent(componentFactory);
     (componentRef.instance as AdComponent).form = this.form;
     (componentRef.instance as AdComponent).field = this.field;
     (componentRef.instance as AdComponent).components = this.componentLoader;
    }
  });
}

ngOnChanges(): void {}

  ngOnInit(): void{
    const formData = { form: this.form, field: this.field };
  }
}
