import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import { Host } from "src/app/shared/globalsContant";
import { Occupancy, UpdateOccupancy } from "./spa-occupancy.model";

@Injectable()
export class SpaOccupancyBusiness {
    captions: any;

    constructor(private localization: Localization, private http: HttpServiceCall) {
        this.captions = this.localization.captions;
    }

    getHeaderOptions(){
        return[
          {
            key: 'date',
            displayName: this.captions.tbl_hdr_extractDate,
            sortingKey: "date",
            sorting: true,
            sortType: null,
          },
          {
            key: 'startTime',
            displayName: this.captions.lbl_startTime,
            sortingKey: "startTime",
            sorting: true,
            sortType: null,
          },
          {
            key: 'endTime',
            displayName: this.captions.lbl_endTime,
            sortingKey: "endTime",
            sorting: true,
            sortType: null,
          },
          {
            key: 'noOfRooms',
            displayName: this.captions.lbl_noOfRooms,
            sortingKey: "noOfRooms",
            sorting: true,
            sortType: null,
          },
          {
            key: 'actualOccupancy',
            displayName: this.captions.lbl_actualOccupancy,
            sortingKey: "actualOccupancy",
            sorting: true,
            sortType: null,
          },
          {
            key: 'applied',
            displayName: this.captions.lbl_applied
          },
          {
            key: 'overrideOcc',
            displayName: this.captions.lbl_overrideOcc,
            sortingKey: "overrideOcc",
            sorting: true,
            sortType: null,
          },
          {
            key: 'actions',
            displayName: this.captions.tbl_hdr_actions
          }
        ]
    }

  public async getOccupancyDetails(startDate: string, endDate: string): Promise<any> {
    try {
      const response = (await this.http.CallApiAsync({
        callDesc: "GetOccupancy",
        host: Host.schedule,
        method: HttpMethod.Get,
        uriParams: { startDate: startDate, endDate: endDate },
        showError: false,
      })) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public mapOccupancyDetails(occupancyDetails: any[]): Occupancy[]{
    var occupancyResult: Occupancy[] = [];
    occupancyDetails?.forEach(o =>{
      occupancyResult.push({
        date: this.localization.localizedDate(this.localization.ToDate(o.occupancyDate, "YYYY-MM-DD")),
        startTime: this.localization.LocalizeISOTime(o.startTime),
        endTime: this.localization.LocalizeISOTime(o.endTime),
        noOfRooms: o.numberOfRooms,
        actualOccupancy: o.actualOccupancy,
        applied: o.isOverride ? '1' : '0',
        overrideOcc: o.overrideOccupancy,
        serviceMinutes: o.serviceMinutes,
        serviceTimes: o.serviceTimes,
        cleanupTimes: o.cleanupTimes,
      })
    });
    return occupancyResult;
  }

  public mapUpdateOccupancy(occupancyData: Occupancy[], startDate: any): UpdateOccupancy[] {
    let occupancy: UpdateOccupancy[] = [];
    let date = this.localization.getDate(startDate);
    occupancyData?.forEach(o => {
      occupancy.push({
        occupancyDate: this.localization.convertDateToAPIdateUDF(date),
        isOverride: o.applied == '1',
        override: this.localization.currencyToSQLFormat(String(o.overrideOcc))
      })
      date = this.localization.AddDays(date, 1);
    });
    return occupancy;
  }

  public async saveOccupancy(bodyObj: UpdateOccupancy[]): Promise<boolean> {
    try {
      const response = (await this.http.CallApiAsync({
        callDesc: "SaveOccupancy",
        host: Host.schedule,
        method: HttpMethod.Put,
        body: bodyObj,
        showError: false,
      })) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
    
}