import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { MasterInventoryManagement } from '../master.interface';
import { FormModelConfiguration } from './form-config.interface';

export const FormTypes: FormModelConfiguration = (
ViewModelEntity: any, PostModelEntity: any, MasterDataCollection: MasterInventoryManagement, 
httpService: HttpService, postModelEntityObject: any): any => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 ) {
            const formType = {} as FormType;
            formType.type =   FormInterface.FormFieldType(ViewModelEntity, PostModelEntity, 
            MasterDataCollection, httpService, postModelEntityObject);
            formType.formRenderType = FormInterface.FormRendererType(ViewModelEntity, PostModelEntity, 
            MasterDataCollection, httpService, postModelEntityObject);
            formType.name = postModelEntityObject['$']['Name'];
            formType.Nullable = postModelEntityObject['$']['Nullable'];
            formType.OdataType = postModelEntityObject['$']['Type'];
            return formType;
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};