import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Localization } from '../../localization/localization';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomizableDashboardDetails } from '../dashboard-jasper-config/dashboard-jasper-config.model';

@Component({
  selector: 'app-dashboard-config-slider',
  templateUrl: './dashboard-config-slider.component.html',
  styleUrls: ['./dashboard-config-slider.component.scss'],
})

export class DashboardConfigSliderComponent implements OnInit {
    captions: any;
    floatLabel: string;
    dashboardConfigurationForm: UntypedFormGroup;
    dashboardData: CustomizableDashboardDetails[] = [];
    @Output() intializeForm = new EventEmitter();
    @Input('dashboardConfigDetails')
    set setDashboardConfig(value){
        if(value){
            this.dashboardData = value;
            this.patchForm(this.dashboardData)
        }
    }
    constructor(private localization: Localization, private _fb: UntypedFormBuilder){
        this.captions = this.localization.captions;
        this.floatLabel = this.localization.setFloatLabel;
    }
    ngOnInit(){
        this.formGenerator();
        this.patchForm(this.dashboardData);
    }

    formGenerator() {
        this.dashboardConfigurationForm = this._fb.group({
            dashboardConfigArray: this._fb.array([])
        });
    }

    patchForm(dashboardData: CustomizableDashboardDetails[]){
        if(!this.dashboardConfigurationForm){
            this.formGenerator();
        }
        const arr = this.dashboardConfigArray
        arr.clear();
        if(dashboardData && dashboardData.length > 0){
          dashboardData.forEach(x => {
            let data = this._fb.group({
              dashboardName: [x.dashboardName, Validators.required],
              dashboardUrl: [x.dashboardURL, Validators.required]
            })
            arr.push(data)
          })
        }
        else{
          let data = this._fb.group({
            dashboardName: ['', Validators.required],
            dashboardUrl: ['', Validators.required]
          })
          arr.push(data)
        }
        this.intializeForm.emit(this.dashboardConfigArray);
    }

    get dashboardConfigArray() {
        return this.dashboardConfigurationForm.get('dashboardConfigArray') as UntypedFormArray;
    }

    addDashboard() {
        const arr = this.dashboardConfigArray;
        let data = this._fb.group({
            dashboardName: ['', Validators.required],
            dashboardUrl: ['', Validators.required]
        })
        arr.push(data);
        this.dashboardConfigArray.markAsDirty()
        this.dashboardConfigArray.markAsTouched()
        this.intializeForm.emit(this.dashboardConfigArray);
    }

    removeDashboard(index) {
        const arr = this.dashboardConfigArray;
        this.dashboardConfigurationForm.markAsDirty();
        arr.removeAt(index);
        this.dashboardConfigArray.markAsDirty()
        this.dashboardConfigArray.markAsTouched()
        this.intializeForm.emit(this.dashboardConfigArray);
    }

    validateUrl(index) {
        let values = this.dashboardConfigArray.value
        if (values && values[index]) {
            let dashboardUrl = values[index].dashboardUrl;
            if (dashboardUrl != '' && dashboardUrl != null) {
                const UrlReg = new RegExp(
                    `^https?:\\/\\/(?:[a-zA-Z0-9-]+\\.)*[a-zA-Z0-9-]+` + // Domain name or subdomain
                    `|(?:\\d{1,3}\\.){3}\\d{1,3}` + // OR IP address
                    `(:[0-9]{1,5})?` + // Port
                    `(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*` + // Path
                    `(\\?[;&a-zA-Z0-9%_.~+=-]*)?` + // Query string
                    `(\\#[-a-zA-Z0-9@:%._\\+~#=]*)?$`, // Fragment
                    'i'
                );

                if (!UrlReg.test(String(dashboardUrl).toLowerCase())) {
                    this.dashboardConfigArray.controls[index].get('dashboardUrl').setErrors({ invalid: true });
                }
                else {
                    this.dashboardConfigArray.controls[index].get('dashboardUrl').setErrors(null);
                }
            }
        }
    }
}