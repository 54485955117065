import { ETransaction, TransactionAction } from 'src/app/eatecui/source/store/actions/transaction.action';
import { intialTransactionState } from 'src/app/eatecui/source/store/states/transaction.state';

export function TransactionReducer(state = intialTransactionState, action: TransactionAction) {
    switch (action.type) {
        case ETransaction.GetTransaction: {
          return {
            ...state,
            GetTransactionData: action.payload
          };
        }
        case ETransaction.EnableTranctionAction: {
            return {
              ...state,
              EnableTrasactionState: action.payload
            };
          }
          case ETransaction.GetMasterManagement: {
            return {
              ...state,
              GetInventoryManagementData: action.payload
            };
          }
          case ETransaction.EnableMasterManagement: {
            return {
              ...state,
              EnableManagementState: action.payload
            };
          }
        default:
      return state;
  }
}