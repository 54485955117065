import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssignBidsPopupModel } from '../model/pop-up.interface';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';

@Component({
  selector: 'app-agilysys-assign-bids-popup',
  templateUrl: './agilysys-assign-bids-popup.component.html',
  styleUrls: ['./agilysys-assign-bids-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysAssignBidsPopupComponent implements OnInit, AfterViewInit {
  agilysysAddNewButton: AgilysysButtonModule[];
  homeGroup: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AgilysysAssignBidsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssignBidsPopupModel,
    public formBuilder: FormBuilder
  ) { 
    this.homeGroup = this.formBuilder.group({});
    const _that = this;
    if (this.data.FormGroupData) {
      setTimeout(function () {
        _that.homeGroup.patchValue(_that.data.FormGroupData);
      }, 100);
    }
  }
  addButtonClick(callBackData: CallBackData) {
    if (this.homeGroup.valid) {
      this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
    } else {
      this.homeGroup.markAllAsTouched();
    }
  }
  getReturnFormValue(value: any) {
    console.log(value);
  }
  ngAfterViewInit(): void {
    if (this.data.FormGroupData) {
      const _that = this;
      setTimeout(function () {
        _that.homeGroup.patchValue(_that.data.FormGroupData);
      }, 300);
    }
  }
  ngOnInit(): void {
    this.agilysysAddNewButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.addButtonClick(callBackData);
        }
      }
    ];
  }

}
