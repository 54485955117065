import { Pipe, PipeTransform } from '@angular/core';
import { SpaUtilities } from '../utilities/spa-utilities';
@Pipe({
    name: 'sortPipe',
    pure: true
})
export class sortPipe implements PipeTransform {

    constructor(private utils: SpaUtilities) {

    }
    transform(inputArray: any[string], sortType?: string, defaultColHeader?: any, sortColumn?: string, sortColumnDatatype?: string,groupSort?:boolean,groupSortType ?:any,groupSortJsonKey?:string): any[] {
        if(groupSort){
            let duplicateArray = JSON.parse(JSON.stringify(inputArray));
            let groupedSortedResult=[];
            if(groupSortType.length > 0){
                for(let LoopType=0;LoopType<groupSortType.length;LoopType++){
                    let groupType = groupSortType[LoopType];
                    if(duplicateArray.length > 0){
                        let currentGroupedArray = [];
                        for(let loopData=0; loopData < duplicateArray.length;loopData++){
                            if(groupType == duplicateArray[loopData][groupSortJsonKey]){
                                currentGroupedArray.push(duplicateArray[loopData]);
                                duplicateArray.splice(loopData,1);
                                loopData--;
                            }
                        }
                        let sortedCurrentGroupedArray = this.sorting(currentGroupedArray, sortType, defaultColHeader, sortColumn, sortColumnDatatype);
                        groupedSortedResult = groupedSortedResult.concat(sortedCurrentGroupedArray);
                    }
                }
            }
            return groupedSortedResult;
        }else{
           return this.sorting(inputArray, sortType, defaultColHeader, sortColumn, sortColumnDatatype);
        }
    }

sorting(inputArray: any[string], sortType?: string, defaultColHeader?: any, sortColumn?: string, sortColumnDatatype?: string){
    if (!inputArray) return [];
    if (sortColumn != null)
        defaultColHeader = sortColumn;
    if (!defaultColHeader) return inputArray;
    if (!sortType) return inputArray;
    if (inputArray.every(x => x[defaultColHeader] == '')) return inputArray;
    let utils: SpaUtilities = this.utils;
    if (sortType == 'asc') {
        return inputArray.sort(function (row1, row2) {
            let firstRowValue;
            let secondRowValue;
            let rowDataType = 'string';
            if (sortColumnDatatype != null)
                rowDataType = sortColumnDatatype;
            else if (typeof row1[defaultColHeader] !== 'undefined' || typeof row2[defaultColHeader] !== 'undefined')
                rowDataType = typeof (row1[defaultColHeader]);
            if (rowDataType == 'string') {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
                if (firstRowValue != null && firstRowValue.length>0)
                    firstRowValue = firstRowValue.toLowerCase();
                if (secondRowValue != null && secondRowValue.length>0)
                    secondRowValue = secondRowValue.toLowerCase();

            }
            else if (rowDataType == 'number') {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
            }
            else if (rowDataType == 'decimal') {
                firstRowValue = parseFloat(row1[defaultColHeader]);
                secondRowValue = parseFloat(row2[defaultColHeader]);
            }
            else if (rowDataType == 'boolean') {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
            }
            else if (rowDataType == 'Date') {
                firstRowValue = utils.getDate(row1[defaultColHeader]).getTime();
                secondRowValue = utils.getDate(row2[defaultColHeader]).getTime();
            }
            else {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
            }
            if (firstRowValue == null) firstRowValue = '';
            if (secondRowValue == null) secondRowValue = '';
            if (firstRowValue < secondRowValue) {
                return -1;
            } else if (firstRowValue > secondRowValue) {
                return 1;
            } else {
                return 0;
            }
        });
    }
    else if (sortType == 'desc') {
        return inputArray.sort(function (row1, row2) {
            let firstRowValue;
            let secondRowValue;
            let rowDataType = 'string';
            if (sortColumnDatatype != null)
                rowDataType = sortColumnDatatype;
            else if (typeof row1[defaultColHeader] !== 'undefined' || typeof row2[defaultColHeader] !== 'undefined')
                rowDataType = typeof (row1[defaultColHeader]);
            if (rowDataType == 'string') {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
                if (firstRowValue != null && firstRowValue.length>0)
                    firstRowValue = firstRowValue.toLowerCase();
                if (secondRowValue != null && secondRowValue.length>0)
                    secondRowValue = secondRowValue.toLowerCase();
            }
            else if (rowDataType == 'number') {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
            }
            else if (rowDataType == 'decimal') {
                firstRowValue = parseFloat(row1[defaultColHeader]);
                secondRowValue = parseFloat(row2[defaultColHeader]);
            }
             else if (rowDataType == 'boolean') {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
            }
            else if (rowDataType == 'Date') {
                firstRowValue = utils.getDate(row1[defaultColHeader]).getTime();
                secondRowValue = utils.getDate(row2[defaultColHeader]).getTime();
            }
            else {
                firstRowValue = row1[defaultColHeader];
                secondRowValue = row2[defaultColHeader];
            }
            if (firstRowValue == null) firstRowValue = '';
            if (secondRowValue == null) secondRowValue = '';
            if (firstRowValue < secondRowValue) {
                return 1;
            } else if (firstRowValue > secondRowValue) {
                return -1;
            } else {
                return 0;
            }
        });
    }
}

}
