import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridCalculationService {

  constructor( ) { }

  calculateForeignCurrency(params: any) {
    try { 
      if (params.data.hasOwnProperty('ForeignCurrencyId') && params.data.ForeignCurrencyId > 0) {
        const rowNode = params.api.getRowNode(params.rowIndex);
        const RowData = params.data;
        const UnitCostInForigenCurrency = RowData.ExchangeRate && RowData.UnitCost &&
         parseFloat(RowData.ExchangeRate.toString()) > 0 && parseFloat(RowData.UnitCost.toString()) > 0 ?
        RowData.ExchangeRate * RowData.UnitCost : 0;
        rowNode.setDataValue('UnitCostInForigenCurrency', parseFloat(UnitCostInForigenCurrency ? UnitCostInForigenCurrency.toString() : '0').toFixed(4));
        const ValueInForeignCurrency = RowData.ExchangeRate && RowData.Valued &&
        parseFloat(RowData.ExchangeRate.toString()) > 0 && parseFloat(RowData.Valued.toString()) > 0 ?
        RowData.ExchangeRate * RowData.Valued : 0; 
        rowNode.setDataValue('ValueInForeignCurrency', parseFloat(ValueInForeignCurrency ? ValueInForeignCurrency.toString() : '0').toFixed(4));
      }
    } catch (error) {
      console.log('Error occurred in calculateForeignCurrency', error);
    }
  }
}
