import { FromTypeEnum } from "../common/components/cdkvirtual/cdkvirtual.model";
import { RetailItemDetail } from "../common/shared/retail.modals";
import { SorTypeEnum } from "../common/shared/shared/enums/enums";
import { ClassStatus } from "./book-classes/book-classes.model";
import { UI } from "./class-grid/class-grid.modal";

export namespace ClassCommonModel {
  export interface IClassSliderInfo {
    id: number | null;
    instructorDetail: UI.ClassInstructor | null;
    locationDetail: UI.ClassLocation | null;
    classAppointmentDetail: UI.ClassDetails;
    startTime: string;
    endTime: string;
    appointmentDate: Date;
    slotDifference: number;
  }

  //configured in route.json//
  export const routeConfig = {
    CreateTempHoldClass: "CreateTempHoldClass",
    UpdateTempHoldClass: "UpdateTempHoldClass",
    classAvailability: "classAvailability",
    deleteClassTempHold: "deleteClassTempHold",
    deleteClassTempHolds: "deleteClassTempHolds",
    createUpdateClassAppointment: "createUpdateClassAppointment",
    GetClientDetailsForClass: "GetClientDetailsForClass",
    checkInClassClient: "checkInClassClient",
    undoCheckInClassClient: "undoCheckInClassClient",
    saveClientNotes: "saveClientNotes",
    cancelClientRequest: "cancelClientRequest",
    deleteClassClient: "deleteClassClient",
    getClassClientDeposit: "getClassClientDeposit",
    getClassClientDetails: "getClassClientDetails",
    UndoCheckOutClassClient: "UndoCheckOutClassClient",
    CreateTempForCopyMoveClass: "CreateTempForCopyMoveClass"
  };

  export interface ICloseSlider {
    isClose: boolean;
    requireRefresh: boolean;
  }

  export interface IViewClientHeader {
    displayName: string;
    key: string;
    sortingKey?: string;
    sorting: boolean;
    sortType?: SorTypeEnum | null;
  }

  export interface IViewClient {
    confirmationNumber: string;
    isExpanded: boolean;
    clientDetails: IClientGridView[];
  }

  export interface IClientGridView {
    firstName: string;
    lastName: string;
    status: string;
    statusId: number;
    emailAddress: string;
    phoneNumber: string;
    colorCode: string;
    checked: boolean;
    clientId: number;
    isDelete: boolean;
    classClientId: number;
    comments: string;
    transactionId: number,
    guestId : string
  }

  export interface IClientViewStatus {
    name: string;
    isSelected: boolean;
    id: ClassClientModel.ClassClientStatus;
    colorCode?: string;
  }

  export interface CDKTableAction<T> {
    fromType: FromTypeEnum;
    array: T[];
    value: any;
    Obj: T | string;
    updatedKey: string;
    checkedData: T[];
  }

  export const Switch = {
    operatingHours: "APPOINTMENT_OPERATING_HOURS",
    gridTimeInterval: "APPOINMENT_GRIDTIMEINTERVAL"
  }
}

export namespace ClassAppointmentModel {
  export interface IAuditColumn {
    createdAt: Date;
    createdBy: number;
    modifiedAt: Date;
    modifiedBy: number;
  }

  export interface IClassAppointment {
    id: number;
    startTime: Date | string;
    endTime: Date | string;
    classGroupId: number;
    classId: number;
    locationId: number;
    status: ClassStatus;
    duration: number;
    setupTime: number;
    breakdownTime: number;
    maxClientsAllowed: number;
    isHalted: boolean;
    fee: number;
    classClients: IClassClient[];
    classInstructors: IClassInstructor[];
    tempHoldLinkId: number | null;
  }

  export enum SourceOfBooking {
    RGuestSpa = 1,
    RGuestBook,
  }

  export interface IClassClient extends IAuditColumn {
    id: number;
    classAppointmentId: number;
    clientId: number;
    comments: string;
    status: ClassClientModel.ClassClientStatus;
    bookingSource: SourceOfBooking;
    confirmationId: string;
    packageId: number;
    fee: number;
    tax: number;
    serviceCharge: number;
    gratuity: number;
    totalAmount: number;
    classDeposits: IClassDeposit[];
    serviceChargePercent: number;
    gratuityPercent: number;
    transactionId: number;
    transactionDetailId: number;
    guestId: string;
    activityId: string;
  }

  export interface IClassInstructor {
    classAppointmentId: number;
    instructorId: number;
  }

  export interface IClassDeposit {
    id: number;
    classClientId: number;
    fee: number;
    gratuity: number;
    serviceCharge: number;
    tax: number;
    transactionStatus: number;
    amountPaid: number;
    depositTransactionId: number;
    depositTransactionDetailId: number;
    isVoided: boolean;
    refundAmount: number;
    refundTransactionId: number;
  }

  export interface IConflictClassResponseModel {
    classId: number;
    clientId: number;
    clientName: string;
    instructorName: string;
    locationName: string;
    startDate: string;
    endDate: string;
    guestId: string;
    className: string;
  }

  export interface ICommandResponse {
    isSuccess: boolean;
    errorList: number[];
    conflictingClients: clientInfo[];
    warningErrorCodes: number[];
    additionalInformation: { [key: string]: any };
  }

  export interface clientInfo {
    firstName: string;
    lastName: string;
  }

  export interface IClassActionCommandResponse extends ICommandResponse {
    tempId: number;
    classAppointment: IClassAppointment;
  }
}

export namespace ClassClientModel {
  export enum ClassClientStatus {
    All = 0,
    Scheduled,
    CheckedIn,
    CheckedOut,
    NoShow,
    Cancelled,
    Deleted,
  }

  export enum ClassClientAction {
    CheckIn = 1,
    CheckOut = 2,
    UndoCheckIn = 3,
    UndoCheckOut = 4,
    CheckInAndOut = 5,
    Delete = 6,
    Cancel = 7,
    Notify = 8,
    AddLockers = 9
  }

  export enum ClientStatusSwitch {
    Scheduled = "APPOINMENT_SCHEDULED_COLOR",
    CheckedIn = "APPOINMENT_CHECKEDIN_COLOR",
    CheckedOut = "APPOINMENT_CHECKEDOUT_COLOR",
    Cancelled = "APPOINMENT_CANCEL_COLOR",
    NoShow = "APPOINMENT_NOSHOW_COLOR",
  }

  export interface ClassClient {
    clientId: number;
    firstName: string;
    lastName: string;
    emailId: string;
    phoneNumber: string;
    confirmationId: string;
    gender: string;
    title: string;
    fee: number;
    classClientStatus: ClassClientStatus;
    isClientDeleteAllowed: boolean;
    guestId: string;
    id: number;
    comments?: string;
    transactionId: number,
    pmsActivityId?: string
  }

  export interface ClientData {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    confirmationNumber: number;
    isValid: boolean;
  }

  export interface IClientCancelReason {
    id: number;
    reasonCode: string;
  }

  export interface ICancelClient {
    id: number;
    clientId?: number;
    cancelReasonId: number;
    cancelComment: string;
  }

  export interface IClassClientCancelResponse {
    id: number;
    isCancelled: boolean;
    errorCode: number;
    errorDescription: string;
    classDeposits: ClassClientDeposit.IClassDeposit[];
  }

  export interface ISearchClientActionRequest {
    classAppointmentId: number;
    classClientId: number;
    clientId: number;
    transactionId: number;
    status: any;
  }
}

export namespace ClassAvailabilityModel {
  export interface IInstructorInfo {
    id: number;
    firstName: string;
    lastName: string;
    locationId: number | null;
  }

  export interface ILocationInfo {
    id: number;
    name: string;
    maxGuests: string;
  }

  export interface IClassEquipmentAvailability {
    classId: number;
    equipmentId: number;
    requiredQuantity: number;
    availableQuantity: number;
    isEquipmentAvailable: boolean;
  }

  export interface IClassInfo {
    id: number;
    code: string;
    name: string;
    duration: number;
    setupTime: number;
    breakDownTime: number;
    fee: number;
    maximumGuest: number;
    instructorIds: number[];
    locationIds: number[];
    equipmentAvailabilities: IClassEquipmentAvailability[];
    maximumInstructors: number;
    classGroup: number;
    isOffSite: boolean;
    medicalConditions:IClassMedicalCondition[]
  }

  export interface IClassMedicalCondition {
    classId: number;
    medicalConditionId: number;
    medicalConditionName: string;
  }  

  export interface IClassAvailabilityViewModel {
    classInfos: IClassInfo[];
    instructorInfos: IInstructorInfo[];
    locationInfos: ILocationInfo[];
    equipmentAvailabilities: IClassEquipmentAvailability[];
  }
}

export namespace ClassClientDeposit {
  export interface IClassClientDepositResponse {
    classAppointmentId: number;
    classClientId: number;
    clientId: number;
    clientName: string;
    className: string;
    confirmationNumber: string;
    tax: number;
    price: number;
    deposit: number;
    transactionId: number;
    isVoided: boolean;
    clientType: number;
    clientLinkId: string;
    memberCorpId: number;
  }

  export interface IDepositGridDetail {
    checked: boolean;
    isCheckboxDisabled: boolean;
    clientName: string;
    confirmationNumber: string;
    price: string;
    tax: string;
    itemizedAmount: string;
    deposit: string;
    depositPaid: string;
    balanceDue: string;
    classAppointmentId: number;
    classClientId: number;
    clientId: number;
    clientType?: number;
    clientLinkId: string;
    memberCorpId?: number;
  }

  export interface IRetailItem {
    id: number;
    multiPackDetail: any[];
    outletItem: any[];
    packagedItem: any[];
    retailItemBarCode: any[];
    retailItemDetail: RetailItemDetail;
    retailItemTax: any[];
    retailItemVendor: any[];
  }

  export interface IClassDeposit {
    id: number;
    classClientId: number;
    fee: number;
    gratuity: number;
    serviceCharge: number;
    tax: number;
    transactionStatus: number;
    amountPaid: number;
    depositTransactionId: number;
    depositTransactionDetailId: number;
    isVoided: boolean;
    refundAmount: number;
    refundTransactionId: number;
  }

  export const depositErrorCode: number = 3110;

  export interface IClassAppointmentDetails {
    classAppointmentId: number;
    classDateTime: string;
    classDuration: number;
    clientConfirmationNumber: string;
    clientId: number;
    classClientId: number;
    classId: number;
    retailItemId: number;
    className: string;
    locationId: number;
    clientFirstName: string;
    clientLastName: string;
    instructorId: number[];
    status: ClassClientModel.ClassClientStatus;
    classAmount: number;
    classDeposit: IClassDeposit[];
    clientType?: number;
    clientLinkId: string;
    memberCorpId?: number;
    guestId?:string;
  } 
}

export interface ClassClientValidation {
  classId: number
  minimumAge: number
  medicalConditionId: number
  medicalConditionName: string
}
