import { Injectable } from '@angular/core';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollItemCount, NestedFilterKey } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FilterOptions } from 'src/app/eatecui/source/shared/models/app-grid.interface';
import { GridActionsService } from 'src/app/eatecui/source/shared/services/grid-actions.service';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GetEndPointUrl } from 'src/app/eatecui/source/transaction/shared/interface/getEndpoint.interface';
import { GridTransaction } from 'src/app/eatecui/source/transaction/shared/interface/grid-transaction';
import { DefineRoutingInterFace, GridSortAction, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { DynamicGridService, GridTableComponent } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import _ from 'lodash';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { IntegrationCollection, IntegrationConfiguration } from 'src/app/eatecui/source/shared/integration/integration-interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import * as constants from '@shared/constant/GlobalConstants';
@Injectable({
  providedIn: 'root'
})
export class ViewTransactionService {
  params = {} as QueryParams;
  TransactionOdataGlobalEntityModel: OdataGlobalEntity;
  LoadMoreCount: number;
  selectedTransaction: string;
  constructor(
    private gridActionService: GridActionsService,
    private dynamicGridService: DynamicGridService,
    private httpService: HttpService,
    private transactionService: TransactionService,
    private masterManagementService: MasterManagementService,
    private translateService: TranslateService,
    private systemPreferenceService: SysytemPreferenceService,
    private commonservice: CommonService,
  ) {
    this.LoadMoreCount = sessionStorage.getItem('appsetting') ?
    JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid : InfiniteScrollItemCount;
   }

  /**
   * @method searchTextChange
   * @param value ;
   * @param TransactionJson ;
   */
  searchTextChange(value, TransactionJson: TransactionConfiguration, typeId: number,gridTable: GridTableComponent) {
    try {
      let currentFilter = '';
      const searchFields = TransactionJson.ViewTranscationConfig.GridConfiguration.GridSearch.map(x => {
        return {
          OdateKey: x.SearchKey,
          columnName: x.SearchKey
        };
      });
      currentFilter = this.gridActionService.formSearchQuery(searchFields, currentFilter, value);
      this.params.searchtext = value;
      this.params.search = currentFilter;
      const returnData = this.getRowDataList(TransactionJson, typeId,gridTable);
      return returnData;
    } catch (erro) {
      console.error('Error occurred in searchTextChange');
    }
  }

   /**
   * @method searchTextChange
   * @param value ;
   * @param TransactionJson ;
   */
    toggleChange(value, TransactionJson: TransactionConfiguration, typeId: number,gridTable: GridTableComponent) {
      try {
        const returnData = this.getRowDataList(TransactionJson, typeId,gridTable);
        return returnData;
      } catch (erro) {
        console.error('Error occurred in searchTextChange');
      }
    }
    /**
     * @method verifyVendorDetail()
     * @input clickedRowData
     * @output Boolean
     * @description check is vendor detail fill or not
     */
    verifyVendorDetail(selectedData: any, selectedTransaction: string ): boolean {
      try {
        let isVerified: boolean = true;
        if( ( selectedTransaction === 'vendorpunchoutpo' || selectedTransaction === 'vendorpunchout' ) && selectedData && selectedData.data && !selectedData.data.ispunchoutdetails) {
          isVerified = false;
        }
        return isVerified;
      } catch(error){
        console.error(error);
      }
    }

  /**
   * @method sortChange
   * @param value ;
   */
  sortChange(value, TransactionJson: TransactionConfiguration, typeId: number,gridTable: GridTableComponent) {
    try {
      this.params.orderby = value[0].colId === 'ponumber' ? 'POHeadId '+ value[0].sort : value[0].colId + ' ' + value[0].sort;
      this.params.expand = '';
      TransactionJson.ViewTranscationConfig.GridConfiguration['ResetScroll'] = true;
      const returnData = this.getRowDataList(TransactionJson, typeId,gridTable);
      return returnData;
    } catch (error) {
      console.log('Error occurred in SortChange');
    }
  }

  /**
   * @method columnsChanged
   * @param event ;
   * @param transactionJsonData ;
   */
  columnsChanged(event: any, transactionJsonData: TransactionConfiguration, typeId?: number) {
    try {
      const columnJson = this.dynamicGridService.assignColumns(event[1], event[0]);
      transactionJsonData.ViewTranscationConfig.GridConfiguration.GridActions.ShuffleColumnConfig.ColumnsList = columnJson;
      transactionJsonData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination = columnJson;
      return transactionJsonData;
    } catch (error) {
      console.error('Error occurred in ColumnsChanged');
    }
  }

  columnsChangedItemList(event: any, transactionJsonData: TransactionConfiguration, typeId?: number) {
    try {
      const columnJson = this.dynamicGridService.assignColumns(event[1], event[0]);
      transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig
        .GridActions.ShuffleColumnConfig.ColumnsList = columnJson;
      transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig
        .GridHeaderDefination = columnJson;
      return transactionJsonData;
    } catch (error) {
      console.error('Error occurred in ColumnsChanged');
    }
  }

  /**
   * @method applyFilter;
   * @param value ;
   * @param transactionJsonData ;
   */
  applyFilter(value, transactionJsonData: TransactionConfiguration, typeId: number,gridTable: GridTableComponent) {
    let currentFilter = '';
    currentFilter = this.formFilterQuery(value[0], currentFilter, transactionJsonData);
    this.params.filterQuery = currentFilter;
    const returnData = this.getRowDataList(transactionJsonData, typeId,gridTable);
    return returnData;
  }

  /**
   * @method formFilterQuery
   * @param filterArray ;
   * @param currentFilter ;
   * @param gridFilterConfig ;
   */
  formFilterQuery(filterArray: FilterOptions[], currentFilter: any, gridFilterConfig: TransactionConfiguration) {
    let query: any = '';
    currentFilter = '';
    const FilterCOnfig = gridFilterConfig.ViewTranscationConfig.GridConfiguration.GridActions.FilterConfig;
    filterArray.forEach((element, index1) => {
      const FilterIndex = FilterCOnfig.FilterOptions.findIndex(x => x.FilterOriginalKey === element.FilterOriginalKey);
     const NestedFilterLength = NestedFilterKey.filter(x => x.Name ===
      gridFilterConfig.ViewTranscationConfig.GridConfiguration.GridFilter[0].FilterOdataKey).length;
      if ( NestedFilterLength > 0) {
        const NestedFilterObject = NestedFilterKey.filter(x => x.Name ===
          gridFilterConfig.ViewTranscationConfig.GridConfiguration.GridFilter[0].FilterOdataKey)[0];
        if (element.FilterValue.filter(x => x.Checked).length > 0) {
          const checkedObjects = [];
          element.FilterValue.forEach((data, index) => {
            if (data.Checked) {
              checkedObjects.push(data.FilterId);
            }
          });
          const datas = checkedObjects.join(',');
          // eslint-disable-next-line max-len
          query = element.FilterOriginalKey + '/any(c: c/' + NestedFilterObject.FilterEntityName + '/' + NestedFilterObject.FilterColumnKey + ' in (' + datas + '))';
        }
      } else {
        if (element.FilterValue.filter(x => x.Checked).length > 0) {
          if (element.FilterNavigationKey) {
            query = FilterCOnfig.FilterOptions[FilterIndex].FilterOriginalKey + '/' + element.FilterNavigationKey + ' in (';
          } else {
            query = FilterCOnfig.FilterOptions[FilterIndex].FilterOriginalKey + ' in (';
          }
        } else if (element.Type === 'daterange') {
          let fromDate = element.FilterValue[0].Value ? element.FilterValue[0].Value : '';
          let toDate = element.FilterValue[1].Value ? element.FilterValue[1].Value : '';
          if (fromDate && toDate) {
            element.FilterValue.forEach(dates => {
              const date = new Date(dates.Value);
              // const newdate = new Date();
              const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                0, 0, 0);
              const now_utc = moment(currentDate).format('YYYY-MM-DD');
              dates.Value = now_utc;
            });
            fromDate = element.FilterValue[0].Value;
            toDate = element.FilterValue[1].Value;
            const key = FilterCOnfig.FilterOptions[FilterIndex].FilterOriginalKey;
            query = `${key} ge ${fromDate} and ${key} le ${toDate}`;
          }
        }
        const checkedObjects = [];
        element.FilterValue.forEach((data, index) => {
          if (data.Checked) {
            checkedObjects.push(data.FilterId);
          }
        });
        if (checkedObjects !== undefined && checkedObjects.length > 0) {
          query = query + checkedObjects.join(',') + ')';
        }
        if (index1 > 0 && currentFilter.length > 0 && query.length > 0) {
          query = ' and ' + query;
        }
      }
      currentFilter = currentFilter + query;
      query = '';
    });
    return currentFilter;
  }

  /**
   * @method formQueryParams;
   * @param params ;
   */
  formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
      if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
        if (params[data] !== '' && data !== 'filter') {
          if (index === 0) {
            query = query + '?$';
          } else {
            if (query.length > 0) {
              query = query + '&$';
            } else {
              query = query + '?$';
            }
          }
          query = query + data + '=' + params[data];
        } else if (data === 'filter') {
          if (params.isactive) {
            params[data] = 'IsActive eq true';
          }
          if (params.searchtext && params.searchtext.length > 0 && params.search) {
            if (params[data].length > 0) {
              params[data] = params[data] + ' and ' + params.search;
            } else {
              params[data] = params.search;
            }
          }
          if (params.filterQuery && params.filterQuery.length > 0) {
            if (params[data].length > 0) {
              params[data] = params[data] + ' and ' + params.filterQuery;
            } else {
              params[data] = params.filterQuery;
            }
          }

          if (params[data] !== '') {
            if (index === 0) {
              query = query + '?$';
            } else {
              query = query + '&$';
            }
            query = query + data + '=' + params[data];
          }
        }
      }
    });
    return query;
  }

  /**
   * @method setSort
   * @param GridSort ;
   */
  setSort(GridSort) {
    let sortOrder = '';
    if (GridSort.length > 0) {
      const sort: GridSortAction = GridSort.filter(x => x.DefaultSorting === true)[0];
      sortOrder = sort.SortingOdataKey + ' ' + sort.SortingType.toLowerCase();
      return sortOrder;
    } else {
      return '';
    }
  }

  setSortColumn(GridSort) {
    if (GridSort?.length > 0) {
      const sort: GridSortAction = GridSort?.filter(x => x.DefaultSorting === true)[0];
      return sort.SortingOdataKey;
    } else {
      return '';
    }
  }

  setSortDirection(GridSort) {
    if (GridSort?.length > 0) {
      const sort: GridSortAction = GridSort?.filter(x => x.DefaultSorting === true)[0];
      return sort.SortingType.toLowerCase() === 'asc' ? 1 : 2;
    } else {
      return 1;
    }
  }

  /**
   * @method pageChanged
   */
  pageChanged(value, transactionJsonData: TransactionConfiguration, typeId: number,gridTable: GridTableComponent) {
    try {
      this.params.skip = this.params.skip + this.LoadMoreCount;
      const returnData = this.getRowDataList(transactionJsonData, typeId,gridTable);
      return returnData;
    } catch (error) {
      console.error('Error occurred in pageChanged');
    }
  }

  getRowDataList(transactionJsonData: TransactionConfiguration, typeid: number,gridTable: GridTableComponent) {
    const getHeaderOdataKey = this.transactionService.getOdataKey(transactionJsonData, 'ViewHeaderOdataKey');
    const ViewTrasacationHeaderEntity = this.masterManagementService.GetViewModelData(
      this.TransactionOdataGlobalEntityModel.OdataViewModelEntity, getHeaderOdataKey);
    const getDetailOdataKey = this.transactionService.getOdataKey(transactionJsonData, 'ViewDetailOdataKey');
    const ViewTransactionDetailEntity = this.masterManagementService.GetViewModelData(
      this.TransactionOdataGlobalEntityModel.OdataViewModelEntity, getDetailOdataKey);
    const getTilesHeaderOdataKey = this.transactionService.getOdataKey(transactionJsonData, 'ViewHeaderTilesOdataKey');
    const ViewTransactionTilesHeaderEntity = this.masterManagementService.GetViewModelData(
      this.TransactionOdataGlobalEntityModel.OdataViewModelEntity, getTilesHeaderOdataKey);
    const transaction = (this.selectedTransaction && this.selectedTransaction !== undefined) ? 
    this.selectedTransaction : transactionJsonData.Name;
    const endPointUrl = GetEndPointUrl(
      ViewTrasacationHeaderEntity, ViewTransactionDetailEntity, transactionJsonData, this.httpService, this.params, typeid,
      null, null, this.systemPreferenceService, transaction);
      this.commonservice.enableGridLoader(gridTable, true);
    this.httpService.GethttpMethod(endPointUrl).subscribe((response: any) => {
        this.commonservice.enableGridLoader(gridTable, false);
      const responseData = [];
      response.value.forEach((element: any, index: number) => {
        const dataObject = _.mapKeys(element, function (v, k) { return k.toLowerCase(); });
        // Added for keep data selected in search 161415 bug 12
        dataObject.GridId = dataObject.id;
        responseData.push(dataObject);
      });
      response.value = responseData;
      const GridData = GridTransaction.GridData(ViewTrasacationHeaderEntity, ViewTransactionDetailEntity, transactionJsonData,
        this.httpService, this.translateService, ViewTransactionTilesHeaderEntity, responseData);
      if (this.params.skip && this.params.skip > 0) {
        transactionJsonData.ViewTranscationConfig.GridConfiguration.GridData =
        [...transactionJsonData.ViewTranscationConfig.GridConfiguration.GridData, ...GridData];
      } else {
        transactionJsonData.ViewTranscationConfig.GridConfiguration.GridData = GridData;
      }
      transactionJsonData.ViewTranscationConfig.GridConfiguration.TotalRecords = response['count'];
      transactionJsonData.ViewTranscationConfig.GridConfiguration.GridActions.TotalItems = response['count'];
      transactionJsonData.ViewTranscationConfig.GridConfiguration = { ...transactionJsonData.ViewTranscationConfig.GridConfiguration };
    },  err => {
        this.commonservice.enableGridLoader(gridTable, false)
    });
    return transactionJsonData;
  }

  /***calculate GridHeightCalculation */
  calculateGridDynamicHeight(): void {
    try {
      const getHeaderContainer = document.getElementsByClassName('trascation-header-container')[0];
      const getTileContainer = document.getElementsByClassName('transaction-tiles')[0];
      if ( getHeaderContainer && getHeaderContainer['length'] > 0  && getTileContainer && getTileContainer['length'] > 0 ) {
           const headerHeight = getHeaderContainer['offsetHeight'];
           const tileHeight = getTileContainer['offsetHeight'];
           if ( headerHeight.length > 0 && tileHeight.length > 0 ) {
               console.log(headerHeight, tileHeight);
           } 
      }
    } catch (error) {
      console.error(error);
    }
  }
  // formatDateFilter(DateValue, DateType: string) {
  //   try {
  //     let currentDate;
  //     if (DateType === 'FromDate') {
  //       const FromDate = new Date(DateValue);
  //       currentDate = moment.utc([FromDate.getFullYear(), FromDate.getMonth(), FromDate.getDate(), 0, 0, 0]).format();
  //     } else {
  //       const ToDate = new Date(DateValue);
  //       currentDate =  moment.utc([ToDate.getFullYear(), ToDate.getMonth(), ToDate.getDate(), 23, 59, 59]).format();
  //     }
  //     return currentDate;
  //   } catch (error) {
  //     console.error('Error occurred in formatDateFilter', error);
  
  //   }
  // }
  formatDateFilter(DateValue, DateType: string) {
    try {
      let currentDate = DateValue;
      if (DateType === 'ToDate') {
        currentDate = moment(DateValue).endOf('day').format(constants.PostDateTimeFormat);
      } else {
        currentDate =  moment(DateValue).startOf('day').format(constants.PostDateTimeFormat);
      }
      return currentDate;
    } catch (error) {
      console.error('Error occurred in formatDateFilter', error);
  
    }
  }
  /**
   * @method defineRouting()
   * @input Method selectedtranscation data selected tranaction type
   */
  defineRouting(selectedTransaction: string, selectedTransactionType: string, rowData: any, DefaultRouting: string, DefaultTransFrom: string, IntegrationConfig: IntegrationConfiguration ): DefineRoutingInterFace {
    try{
            const defineRouting = {} as DefineRoutingInterFace;
            defineRouting.RoutingKey = DefaultRouting;
            defineRouting.TranasctionFrom = DefaultTransFrom;
            defineRouting.SelectedTranasctionType = selectedTransactionType;
            defineRouting.SelectedTransaction = selectedTransaction;
            if( (selectedTransaction === 'requisition' && selectedTransactionType !== 'All Transaction') || selectedTransaction === 'openpurchaserequest' ){
                  const GetApprovalColl: Array<IntegrationCollection> = IntegrationConfig.IntegrationCollection.filter(x=> x.IntegratonName === 'DocApprovals');
                  if ( GetApprovalColl && GetApprovalColl.length > 0 ){
                       const IsEnabledApproval: boolean = GetApprovalColl[0].IsEnabled;
                       if( IsEnabledApproval ){
                        const GetAppStatusId = ( rowData.approvalstatus ) ? rowData.approvalstatus.Id : ( rowData.prheadapprovalstatusid ) ? rowData.prheadapprovalstatusid.Id : '';
                            if ( GetAppStatusId === 1 ){
                              defineRouting.RoutingKey = 'create'
                            } else {
                              defineRouting.RoutingKey = 'copynew'
                              defineRouting.TranasctionFrom = (selectedTransaction === 'requisition') ? 'AllTransactions' : 'Transactions';
                              defineRouting.SelectedTranasctionType = (selectedTransaction === 'requisition') ? 'openreq' : 'openpr';
                            }
                       }
                  }
                  
            }
            return defineRouting;
    } catch(error){
      console.error(error);
    }
  }
}
