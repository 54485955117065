import { Component, OnInit } from '@angular/core';
import { RetailService } from 'src/app/retail/retail.service';
import { Product } from 'src/app/retail/shared/globalsContant';
import { RetailLocalization } from "../../../common/localization/retail-localization";
import { RetailDataAwaiters } from '../../../shared/events/awaiters/retail.data.awaiters';
import { CommonVariablesService } from '../../../shared/service/common-variables.service';
import { ImageProcessorService } from 'src/app/common/shared/shared/service/image-processor-service';
import { BaseResponse } from 'src/app/retail/shared/business/shared.modals';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
@Component({
  selector: 'app-shop-details-section',
  templateUrl: './shop-details-section.component.html',
  styleUrls: ['./shop-details-section.component.scss'],
  providers: [ImageProcessorService]
})
export class ShopDetailsSectionComponent implements OnInit {

  isAPICallInProgress: boolean = false
  showFullData: boolean = false;
  currentProductId: number;
  thumbnailImageData: ImageData;
  constructor(
    public localization: RetailLocalization
    , public _ss: CommonVariablesService
    , public _ams: RetailService
    , public imgService: ImageProcessorService
    , private utilities: CommonUtilities
  ) { }

  ngOnInit() {
    if (this._ss.selectedPayeeId) { this.GetPayeeInfo(this._ss.selectedPayeeId); }
    else { this._ss.selectedclient = null; }
  }

  showOrHide() {
    this.showFullData = !this.showFullData;
  }
  get showProfilePicture() {
    this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
		return this.currentProductId == Product.SPA || this.currentProductId == Product.RETAIL || this.currentProductId == Product.GOLF || (this.currentProductId == Product.PMS && (this._ss.memberCardNumber && this._ss.memberCardNumber != "0"));
	}

  async GetPayeeInfo(playerId) {
    let propertyDate: any = sessionStorage.getItem('propertyDate');
    this._ss.selectedclient = await RetailDataAwaiters.getPayeeInfo(playerId);
    if (this._ss.ProductId == Product.GOLF || this._ss.ProductId == Product.SPA || this._ss.ProductId == Product.RETAIL) {
      if (this._ss.selectedclient.guestProfileId != null && this._ss.selectedclient.guestProfileId != "" && !(this._ss.selectedclient.playerCategoryId && this._ss.selectedclient.playerCategoryId === 3)) {
        await this.imgService.GetImagesByGuid(this._ss.selectedclient.guestProfileId, this.successCallback.bind(this), this.errorCallback.bind(this), true, []);
      }
    }
    if ((this._ss.selectedclient.playerCategoryId && this._ss.selectedclient.playerCategoryId === 3) || (this._ss.ProductId == Product.SPA && this._ss.selectedclient.guesttype == 2)) {
      this._ams.clientSelect = 1
      const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
      await RetailDataAwaiters.getMemberInfo(this._ss.selectedclient.guestProfileId, new Date(propertyDate).toISOString(), corpId).then(
        res => {
          this._ss.selectedclient.arAccountNumber = res.arAccountNumber;
          this._ss.selectedMemberInfo = res;
          this._ss.memberImageUrl = res.photoUrl;
        });
    } else {
      this._ams.clientSelect = 2
    }

  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string) {
    switch (callDesc) {
      case 'GetImagesByGuid':
        let imgResponse: any = result.result;
        if (imgResponse != null) {
          this.thumbnailImageData = imgResponse[0];
        }
        break;
      default:
        break;
    }
  }

  errorCallback<T>() {
  }


}
