import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake.min';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { API ,ErrorCode} from 'src/app/shared/models/generic-extract-model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { QueryBuilderBusiness } from '../querybuilder.business';

import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-easypopup',
  templateUrl: './easypopup.component.html',
  styleUrls: ['./easypopup.component.scss'],
  providers: [QueryBuilderBusiness],
})

export class EasypopupComponent implements OnInit {

  public dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[];
  columnResponse: any[];
  rowResponse: any[];
  pdfBtn: ButtonValue;
  excelBtn: ButtonValue;
  csvBtn: ButtonValue;
  headerOptions: TableHeaderOptions[];
  tableContent;
  tableOptions: TableOptions;
  captions: any;
  excelImport = import('xlsx');
  totalRecords : number
  recordLimit : number

  constructor(
    public dialogRef: MatDialogRef<EasypopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public localization: Localization,
    private business: QueryBuilderBusiness,
    private utilities: CommonUtilities) {
      this.captions = this.business.captions;
  }

  ngOnInit() {

    this.pdfBtn = {
      label: this.captions.btn_exportToPdf,
      type: 'primary',
      customclass: 'ag_ml--auto'
    };

    this.excelBtn = {
      label: this.captions.btn_exportToExcel,
      type: 'primary',
      customclass: 'ag_ml--auto'
    };

    this.csvBtn = {
      label: this.captions.btn_exportToCsv,
      type: 'primary',
      customclass: 'ag_ml--auto'
    };

    this.onFetch();
  }

  stringFormat(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
};

  async onFetch() {

    this.utilities.ToggleLoader(true);
    const response = await this.business.fetchExtract(this.dialogRef.componentInstance.data.easyQueryJson);
    this.totalRecords = response.meta.totalRecords as number;
    this.recordLimit = response.meta.offset as number;
    this.columnResponse = (response.resultSet as any).cols;
    this.rowResponse = (response.resultSet as any).rows;
    if(this.totalRecords < 0)
    {
      let code: number = ErrorCode.EasyQueryErrorCode;
      let message: string = this.localization.getError(code);
      const format = message.replace('{0}', this.recordLimit.toString());
      this.utilities.showError(format);
    }
    else
    {
    let columns = [];
    let rows = [];
    let distinctRows = [];
    if (this.columnResponse.length > 0) {
      for (let i = 0; i < this.columnResponse.length; i++) {
        let header = {
          field: this.columnResponse[i].label,
        };
        columns.push(header);
      }
    }

    this.displayedColumns = columns.map(column => column.field);

    columns.push({"field": "UniqueField"});

    if (this.rowResponse.length > 0) {
      for (let i = 0; i < this.rowResponse.length; i++) {
        let item = {};
        for (let j = 0; j < columns.length; j++) {
           if(columns[j].field == "UniqueField")
           {
             item["UniqueField"] = this.rowResponse[i].toString();
            } 
           else
           item[columns[j].field] = this.rowResponse[i][j];
          }        
        rows.push(item);
      }
    }
    distinctRows = rows.filter((item,i,arr) => arr.findIndex((t) => t.UniqueField === item.UniqueField) === i);

    distinctRows.forEach(x => delete x.UniqueField);

    var colDetailsJsonString = JSON.stringify(this.columnResponse);
    var rowsJsonString = JSON.stringify(distinctRows);
    var extractData = {
    columnDetails : colDetailsJsonString,
    reportData : rowsJsonString
    } as API.CustomQueryExtractData;
    rowsJsonString = await this.business.updateExtractedData(extractData);
    rows = JSON.parse(rowsJsonString);
    this.dataSource.data = rows;
    this.generateTable(this.columnResponse, this.dataSource.data);
    this.utilities.ToggleLoader(false);
  }
  }

  onNoClick() {
    this.dialogRef.close()
  }

  getPageOrientation() {
    if (this.displayedColumns.length > 5) {
      return 'landscape'
    }
    else {
      return 'portrait'
    }
  }

  excelExport() {
    this.excelImport.then(XLSX=>{
    //Create a new Work Sheet using the data stored in an Array of Arrays.
    const workSheet = XLSX.utils.aoa_to_sheet(this.buildTableBodyForExcel(this.dataSource.data, this.displayedColumns));
    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { data: workSheet, cols: [] },
      SheetNames: ["data"],
    };
    // Exporting the file with the desired name and extension.
    const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(fileData, this.dialogRef.componentInstance.data.easyQueryName + ".xlsx");
   });
  };

  csvExport() {
    // specify how you want to handle null values here
    const replacer = (key, value) => value === null ? '' : value;
    let dataArray = this.buildTableBodyForExcel(this.dataSource.data, this.displayedColumns);
    const header = Object.keys(dataArray[0]);
    let csv = dataArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, this.dialogRef.componentInstance.data.easyQueryName + ".csv");
  }
  setPageSize()
  {
   if(this.displayedColumns.length > 25)
    {
      return {
        width: 2383.94,
        height: 1683.78   // A0 PaperSize
        } 
    }
     else if (this.displayedColumns.length > 20) {
      return {
        width: 1683.78,
        height: 1190.55   // A1 PaperSize
        }   
    }
    else if (this.displayedColumns.length > 13) {
      return {
        width: 1190.55,
        height: 841.89   // A2 PaperSize
        }
      }
    else if(this.displayedColumns.length > 5){
      return {
        width: 841.89,
        height: 595.28  // A3 PaperSize
        }
      }
      else{
        return {
          width: 595.28, // A4 PaperSize
          height: 419.53
          }
        }       
    } 
  

  pdfExport() {
    var dd = {
              
            pageOrientation: 'landscape',
            pageSize: this.setPageSize(),
            pageMargins: [10, 10, 10, 10],
            content: [
              { text: this.dialogRef.componentInstance.data.easyQueryName + '\n', style: 'header' },
              this.table(this.dataSource.data, this.displayedColumns)             
            ],
            styles: {
              header: {
                fontSize: 12,
                bold: true,
                alignment: 'left',
                arial: true
              },
              table: {
                fontSize: 8,
                bold: false,                
                margin: 5
              }
            }
          }
    pdfMake.createPdf(dd).download(this.dialogRef.componentInstance.data.easyQueryName);
  }

  buildTableBody(data, columns) {
    var body = [];
    body.push([...columns]);
    for (let i = 0; i < data.length; i++) {
      var dataRow = [];
      for (let j = 0; j < columns.length; j++) {
        if (i == data.length - 1) {
          dataRow.push({ text: Object.values(data[i])[j]?.toString() ?? '', border: [true, false, true, true] });
        }
        else {
          dataRow.push({ text: Object.values(data[i])[j]?.toString() ?? '', border: [true, false, true, false] });
        }
      }
      body.push(dataRow);
    }
    return body;
  }

  buildTableBodyForExcel(data, columns) {
    var body = [];
    body.push([...columns]);
    for (let i = 0; i < data.length; i++) {
      var dataRow = [];
      for (let j = 0; j < columns.length; j++) {
        dataRow.push(Object.values(data[i])[j]?.toString() ?? '');
      }
      body.push(dataRow);
    }
    return body;
  }

  table(data, columns) {
    return {
      style: 'table',
      table: {
        widths: this.getWidthArray(),
        headerRows: 1,
        body: this.buildTableBody(data, columns),

      }
    };
  }

  getWidthArray() {
    var widthArray = [];
    for (var i = 0; i < this.displayedColumns.length; i++) {
      if (this.displayedColumns.length < 5) {
        widthArray.push('*');
      }
      else {
        widthArray.push('auto');
      }
    }
    return widthArray;
  }

  async generateTable(cols, data) {
    this.tableOptions = this.business.getExtractResultTableOptions();
    this.headerOptions = this.business.getExtractResultHeaderOptions(cols);
    this.tableContent = data;
    this.tableContent = [...this.tableContent]
  }
}
