import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';

@Component({
  selector: 'app-transaction-log-report',
  templateUrl: './transaction-log-report.component.html',
  styleUrls: ['./transaction-log-report.component.scss']
})
export class TransactionLogReportComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  TransactionLogFormGrp: UntypedFormGroup;
  private captions: any = this.localization.captions.reports;
  @Input() outlets: any;
  minDate : Date;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel:string;
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization) { 
    this.placeholderFormat = this.localization.inputDateFormat;
    this.commonCaptions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.TransactionLogFormGrp = this.fb.group({
      logtype: '',
      transactionid: '',
      firstname: '',
      lastname: '',
      date: '',
      appointmentid: '',
      userid: '',
      otherscannedcode: ''
    });
    this.formReady.emit(this.TransactionLogFormGrp);
  }



  /**
  * After a form is initialized, we link it to our main form
  */
  formInitialized(form: UntypedFormGroup) {
    this.TransactionLogFormGrp.setControl('transactionlog', form);
  }
}
