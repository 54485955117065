import { Component, Input, OnInit } from '@angular/core';
import { Localization } from '../../localization/localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { UntypedFormGroup } from '@angular/forms';
import { AgCountryConfig } from '../../Models/ag-models';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ag-country',
  templateUrl: './ag-country.component.html',
  styleUrls: ['./ag-country.component.scss']
})
export class AgCountryComponent implements OnInit {

  captions: any;
  className: string;
  errorMessage: string;
  floatLabel: string;
  form: UntypedFormGroup;
  formControlName: string;
  value: string;
  automationId: string;
  countryDetails = [];
  filteredCountries: Observable<any>;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isRequired : boolean = false;

  @Input('inputs')
  set inputOptions(value: AgCountryConfig) {
    if (value) {
      this.className = value.className ? value.className : '';
      this.floatLabel = this._Localization.setFloatLabel? this._Localization.setFloatLabel: value.floatLabel;
      // this.errorMessage = value.errorMessage;
      this.isRequired = value.isRequired ? value.isRequired: false;
      this.form = value.form;
      this.formControlName = value.formControlName;
      this.value = value.value;
      this.automationId = value.automationId ? value.automationId : '';
    }
  }

  constructor(private _Localization: Localization, private utilities: CommonUtilities, public http: HttpClient) { 
    this.captions = this._Localization.captions.common;
  }

  ngOnInit(): void {
    this.geCountriesJSON().then(res => {
      this.filteredCountries = this.form.controls.country.valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        map((country: string) => country ? this.utilities.FilterCountry(country, this.countryDetails) : [])
      );
    });
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (this.form.controls['country'].value &&
        !this.FilterCountryValueFromData(this.form.controls['country'].value)) {
        this.form.controls.country.setErrors({ invalid: true });
      }
      this.form.controls['country'].markAsTouched();
    });
  }
  setCountryName(eve){
    this.form.controls[this.formControlName].setValue(eve.option.value);
  }
  FilterCountryValueFromData(country) {
    let countryValue = '';
    if (country && this.utilities.FilterCountryByName(country, this.countryDetails).length) {
        countryValue = country;
    } else {
        countryValue = '';
    }
    return countryValue;
  }
  async geCountriesJSON() {
    let countryDetails: any = await this.http.get(`assets/i18n/Countries/en-US.Countries.json`).toPromise();
    this.countryDetails = countryDetails.Countries;
    return this.countryDetails;
  }



  clearOutCountry(curvalue){
      let getPlaceholder = this.countryDetails.filter(country => country.CountryName == curvalue.target.value.trim());
      if(getPlaceholder.length === 0){
        this.form.controls[this.formControlName].setValue('');
      }
 }
}
