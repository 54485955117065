import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { ButtonType, DialogCloseEnum, MailTypes } from 'src/app/common/enums/shared-enums';
import { AgInputFieldConfig, AgDropdownConfig, DropdownOptions, ButtonValue, AgDateConfig } from 'src/app/common/Models/ag-models';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { of, ReplaySubject } from 'rxjs';
import { PropertyInformation } from '../shared/services/property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { SamsotechDataService } from 'src/app/common/dataservices/samsotech.data.service';
import { samsotechScanResponse } from 'src/app/common/Models/common.models';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/Models/common.models';

@Component({
  selector: 'app-samsotech-preview',
  templateUrl: './samsotech-preview.component.html',
  styleUrls: ['./samsotech-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SamsotechPreviewComponent implements OnInit {

  captions: any;
  dialogCloseEnum = DialogCloseEnum;
  submitButton: ButtonValue;
  cancelButton: ButtonValue;
  addressInput: AgInputFieldConfig;
  address2Input: AgInputFieldConfig;
  scanform: UntypedFormGroup;
  postalCodeConfig: AgInputFieldConfig;
  cityConfig: AgInputFieldConfig;
  stateConfig: AgInputFieldConfig;
  countryConfig: AgInputFieldConfig;
  firstNameConfig: AgInputFieldConfig;
  lastNameConfig: AgInputFieldConfig;
  nationalityConfig: AgInputFieldConfig;
  birthInput: AgInputFieldConfig;
  mobileConfig: AgInputFieldConfig;
  emailConfig: AgInputFieldConfig;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  pic1: any;
  pic2: any;
  samsotechScannerUrl: string;
  samsotechReadchipUrl: string;
  isSamsotechFlipCardEnabled: string;
  action: string;
  deviceUpdates = 'Please wait while loading the services...';
  showLoader: boolean = false;
  scanResults: {
    firstName: string;
    lastName: string;
    personalDetail: {
      nationality: string;
      birthDate: string;
    };
    addressInfo: {
      addrLine1: string;
      addrLine2: string;
      postalCode: string;
      city: string;
      state: string;
      country: string;
    };
    contactInfo: {
      phoneNumber: string;
      email: string;
    };
  };

  constructor(private localization: Localization,
    private dialogRef: MatDialogRef<SamsotechPreviewComponent>,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private utils: CommonUtilities,
    private samsotechService: SamsotechDataService,
    @Inject(MAT_DIALOG_DATA) private dialogData) {
    this.captions = this.localization.captions;
    this.action = this.dialogData?.action;
    this.samsotechScannerUrl = this.dialogData?.samsotechScannerUrl;
    this.samsotechReadchipUrl = this.dialogData?.samsotechReadchipUrl;
    this.isSamsotechFlipCardEnabled = this.dialogData?.isSamsotechFlipCardEnabled;
  }

  async ngOnInit(): Promise<void> {
    this.buildform();
    this.initializeinputs();
    await this.getData();
  }
  async getData() {
    if(this.action == 'SamsotechScan')
    {
      await this.InitScanDocument();
    }
    else{
      await this.InitProcessSmartCard();
    }
  }

  private async InitScanDocument()
  {
    this.showLoader = true;
    var responseString = await this.samsotechService.InitScanDocument(this.samsotechScannerUrl);
    let response1 : samsotechScanResponse = JSON.parse(responseString);
    let response2 : samsotechScanResponse = null;
      
    if (response1 && response1.result)
    {
      if (this.isSamsotechFlipCardEnabled && (response1.idType == 'NID' || response1.idType == 'OC'))
      {
        await this.utils.showCommonAlert(this.captions.info_FlipCard, AlertType.Warning, ButtonTypes.YesNo).afterClosed()
        .toPromise()
        .then(async res => {
          if (res === AlertAction.YES) {
            this.showLoader = true;
            var otherSideResponseString = await this.samsotechService.InitScanDocument(this.samsotechScannerUrl);
            this.showLoader = false;
            let otherSideResponse : samsotechScanResponse = JSON.parse(otherSideResponseString);
            if (otherSideResponse && otherSideResponse.result)
            {
              response2 = otherSideResponse;
            }
          }
        });
      }
      this.showLoader = false;
      this.patchValues(response1, response2);
    }
    else if(response1?.error_message)
    {
      this.showLoader = false;
      this.utils.showAlert(response1.error_message, AlertType.Error, ButtonType.Ok);
    }
    else
    {
      this.showLoader = false;
      this.utils.showAlert(this.captions.lbl_failed, AlertType.Error, ButtonType.Ok);
    }
  }

  private async InitProcessSmartCard()
  {
    this.showLoader = true;
    var responseString = await this.samsotechService.InitProcessSmartCard(this.samsotechReadchipUrl);
    this.showLoader = false;
    let response1 : samsotechScanResponse = JSON.parse(responseString);
      
    if (response1 && response1.result)
    {
      this.patchValues(response1, null);
    }
    else if(response1?.error_message)
    {
      this.utils.showAlert(response1.error_message, AlertType.Error, ButtonType.Ok);
    }
    else
    {
      this.utils.showAlert(this.captions.lbl_failed, AlertType.Error, ButtonType.Ok);
    }
  }

  patchValues(response1 : samsotechScanResponse, response2 :samsotechScanResponse)
  {
    this.scanform.markAsDirty();
    this.pic1 = response1.fullImage ? `data:image/jpeg;base64,` + response1.fullImage : '';
    this.pic2 = response2?.fullImage ? `data:image/jpeg;base64,` + response2?.fullImage : '';
    this.scanform.patchValue({
      postalCode: this.GetValidValue(response1.zip, response2?.zip),
      city: this.GetValidValue(response1.city, response2?.city),
      state: this.GetValidValue(response1.state, response2?.state),
      country: this.GetValidValue(response1.countryOfBirth, response2?.countryOfBirth),
      mobile: this.GetValidValue(response1.personalNumber, response2?.personalNumber),
      email: this.GetValidValue(response1.Email, response2?.Email),
      nationality: this.GetValidValue(response1.nationality, response2?.nationality),
      firstName: this.GetValidValue(response1.firstName, response2?.firstName),
      lastName: this.GetValidValue(response1.lastName, response2?.lastName),
      dateofbirth: this.GetValidValue(response1.dateOfBirth , response2?.dateOfBirth),
      addressDetail1: this.GetValidValue(response1.address1, response2?.address1),
      addressDetail2: this.GetValidValue(response1.address2, response2?.address2),
      documentNumber: this.GetValidValue(response1.documentNumber, response2?.documentNumber)
    });

    this.submitButton.disabledproperty = false;
    this.submitButton = { ...this.submitButton };
  }

  private GetValidValue(field1 : string, field2 : string)
  {
    if(field1)
      return field1;

    else if(field2)
      return field2;

    else
      return '';
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = base64.replace(/\\n/g, '');
    binary_string = atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  buildform() {
    this.scanform = this.fb.group({
      postalCode: '',
      city: '',
      county: '',
      state: '',
      country: '',
      mobile: '',
      email: '',
      nationality: '',
      documentNumber: '',
      firstName: '',
      lastName: '',
      dateofbirth: '',
      addressDetail1: '',
      addressDetail2: '',
    });
  }
  onCancel(event) {
    this.closePopUp(this.dialogCloseEnum.Cancel);
  }
  closePopUp(from) {
    this.dialogRef.close({
      from: from
    });
  }
  initializeinputs() {
    this.firstNameConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'firstName',
      placeHolderId: 'lbl_firstName',
      placeHolder: this.captions.lbl_firstName,
      inputType: 'noprespace',
      minlength: 1,
      maxlength: 50,
      errorMessageId: 'err_missingGuestFirstname',
      errorMessage: this.captions.err_missingGuestFirstname,
      readonly: true
    };
    this.lastNameConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'lastName',
      placeHolderId: 'lbl_lastName',
      placeHolder: this.captions.lbl_lastName,
      inputType: 'noprespace',
      minlength: 1,
      maxlength: 50,
      errorMessageId: 'err_missingGuestLastname',
      errorMessage: this.captions.err_missingGuestLastname,
      readonly: true
    };
    this.addressInput = {
      className: 'ag_w--100 ag_pr--8',
      form: this.scanform,
      formControlName: 'addressDetail1',
      placeHolderId: 'lbl_address_1',
      placeHolder: this.captions.lbl_address_1,
      minlength: 1,
      maxlength: 255,
      readonly: true
    };
    this.address2Input = {
      className: 'ag_w--100 ag_pr--8',
      form: this.scanform,
      formControlName: 'addressDetail2',
      placeHolderId: 'lbl_address_2',
      placeHolder: this.captions.lbl_address_2,
      minlength: 1,
      maxlength: 255,
      readonly: true
    };
    this.cityConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'city',
      placeHolderId: 'lbl_city',
      placeHolder: this.captions.lbl_city,
      inputType: 'noprespace',
      minlength: 1,
      maxlength: 50,
      errorMessageId: 'err_missingCity',
      errorMessage: this.captions.err_missingCity,
      readonly: true
    };
    this.postalCodeConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'postalCode',
      placeHolderId: 'lbl_postalCode',
      placeHolder: this.captions.lbl_postalCode,
      inputType: 'noprespace',
      maxlength: 25,
      errorMessageId: 'err_missingPostalCode',
      errorMessage: this.captions.err_missingPostalCode,
      readonly: true
    };
    this.birthInput = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'dateofbirth',
      placeHolderId: 'lbl_dateofBirth',
      placeHolder: this.captions.lbl_dateofBirth,
      inputType: 'noprespace',
      maxlength: 10,
      readonly: true
    };
    this.stateConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'state',
      placeHolderId: 'lbl_state',
      placeHolder: this.captions.lbl_state,
      inputType: 'noprespace',
      maxlength: 25,
      minlength: 2,
      errorMessageId: 'err_State',
      errorMessage: this.captions.err_State,
      readonly: true
    };
    this.nationalityConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'nationality',
      placeHolderId: 'lbl_nationality',
      placeHolder: this.captions.lbl_nationality,
      inputType: 'noprespace',
      maxlength: 25,
      minlength: 2,
      readonly: true
    };
    this.countryConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'country',
      placeHolderId: 'lbl_country',
      placeHolder: this.captions.lbl_country,
      inputType: 'noprespace',
      errorMessageId: 'err_missingCountry',
      errorMessage: this.captions.err_missingCountry,
      maxlength: 25,
      minlength: 2,
      readonly: true
    };
    this.mobileConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'mobile',
      placeHolderId: 'lbl_state',
      placeHolder: this.captions.lbl_phone,
      inputType: 'noprespace',
      maxlength: 25,
      minlength: 2,
      errorMessageId: 'err_State',
      errorMessage: this.captions.err_invalidPhone,
      readonly: true
    };
    this.emailConfig = {
      className: 'ag_w--50 ag_pr--8',
      form: this.scanform,
      formControlName: 'email',
      placeHolderId: 'lbl_state',
      placeHolder: this.captions.lbl_email,
      inputType: 'noprespace',
      maxlength: 25,
      minlength: 2,
      errorMessageId: 'err_State',
      errorMessage: this.captions.err_invalidEmailFormat,
      readonly: true
    };
    this.submitButton = {
      type: 'primary',
      label: this.captions.lbl_Submit,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.common.CANCEL,
      disabledproperty: false
    };
    this.submitButton.disabledproperty = true;
    this.submitButton = { ...this.submitButton };
  }
  onAdd(eve) {
    const formValues = this.scanform.getRawValue();
    if(formValues) {
      this.scanResults = {
        "firstName" : formValues.firstName,
        "lastName" : formValues.lastName,
        "addressInfo" : {
          "postalCode": formValues.postalCode,
          "country": formValues.country,
          "state": formValues.state,
          "addrLine1": formValues.addressDetail1,
          "addrLine2": formValues.addressDetail2,
          "city": formValues.city,
        },
        "personalDetail" : {
          "birthDate": this.localization.getformattedDateFromDDMMYYYY(formValues.dateofbirth).toDateString(),
          "nationality": formValues.nationality,
        },
        "contactInfo" : {
          "phoneNumber": formValues.mobile,
          "email" : formValues.email
        }
      } 
    }
    this.dialogRef.close({
      from: this.dialogCloseEnum.Action,
      scanResults: this.scanResults
    });
  }
}
