import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, ReplaySubject } from 'rxjs';
import { AgDropdownConfig, AgTimeConfig, ButtonValue, DropdownOptions } from 'src/app/common/Models/ag-models';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ActionMode, UI, MapUI, API, DaysOfWeek } from './dynamic-yielding.model';
import moment from 'moment';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { DynamicYieldingBusiness } from './dynamic-yielding.business';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { MatMenuTrigger } from '@angular/material/menu';
import _ from 'lodash';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { AlertAction, AlertType, ButtonType, SPAScheduleBreakPoint } from 'src/app/shared/globalsContant';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';

@Component({
  selector: 'app-dynamic-yielding',
  templateUrl: './dynamic-yielding.component.html',
  styleUrls: ['./dynamic-yielding.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DynamicYieldingBusiness]
})
export class DynamicYieldingComponent implements OnInit {
  captions: any
  serviceYeilding: UntypedFormGroup;
  gridViewEnum = UI.GridType;
  listViewEnum = UI.ViewType;
  createYieldButton: ButtonValue;
  serviceInput: AgDropdownConfig;
  serviceMultipleInput: AgDropdownConfig;
  strategyInput: AgDropdownConfig;
  serviceGroupInput: AgDropdownConfig;
  serviceOptions: DropdownOptions[];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  dateInput: any;
  startDateInput: any;
  endDateInput: any;
  propertyDate: Date;
  startofWeek: any;
  endofWeek: any;
  masterData: API.DynamicPricingServiceGroup[];
  pricingData: API.YieldingDetails[];
  viewType: UI.ViewType; // Grid or List
  gridType: UI.GridType; // Day or Week
  gridStartDate: Date;
  gridEndDate: Date;
  gridStartTime: string;
  gridEndTime: string;
  filteredService: DropdownOptions;
  slotDifference: number = 60;
  gridOptions: { gridType: UI.GridType; gridStartDate: any; gridEndDate: any; gridStartTime: any; gridEndTime: any; slotDifference: number; };
  createToggler = false;
  createModalData: createModal;
  crudActionInput;
  minDate;
  maxDate;
  floatLabel;
  isExpandAll: boolean = true;
  resetList:boolean = false;
  strategyOptions: DropdownOptions[]=[];
  strategyList: UI.StrategyList[] = [];
  editData: MapUI.DynamicPricingRules[];
  DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';
  @ViewChild('filterConfig') filterConfig: MatMenuTrigger;
  applyButton: ButtonValue;
  resetButton: ButtonValue;
  startTimeInputs: AgTimeConfig;
  endTimeInputs: AgTimeConfig;
  daysOfWeekList : any = [];
  availabilityList: any = [];
  batchStart: number = 0;
  batchEnd: number = 100;
  batchMin: number = 0;
  batchMax: number = 100;
  selectedStrategy;
  selectedStrategyName;
  selectedServiceGroup;
  selectedService;
  appointmentOperatingHours: Object;
  config : object;
  viewDate: { startDate: Date; endDate: Date, forceReload?: boolean };
  listServiceDetails: [
    DropdownOptions[],
    DropdownOptions[],
    DropdownOptions[]
  ] = [[], [], []]; //serviceGroup,service,strategy
  strategyFilterSelected: UI.IListViewServiceFilter;
  serviceFilterSelected: UI.IListViewServiceFilter;
  serviceGroupFilterSelected: UI.IListViewServiceFilter;
  daysOfWeekFilter: number[] = [];
  availabilityFilter: number[] = [];
  menuFilter: UI.MenuFilter = {
    startTime : '',
    endTime: '',
    daysOfWeek: [],
    availability: [],
    rangeStart: 0,
    rangeEnd: 100
  };  
  deletedGroupId:string= '';
  serviceGroupMultipleInput: AgDropdownConfig;
  strategyMultipleInput: AgDropdownConfig;
  defaultSelected=[];
  selectedStrategyServiceGroupList=[];
  selectedStrategyServiceOptionsList=[];
  toggleActive : boolean = false
  fieldUpdate : {mathRandom:number,maxValue:number,minValue:number};
  stopSlice: boolean;
  isViewOnly = false;
  filteredListData : API.YieldingDetails[] = [];

  constructor(public fb: UntypedFormBuilder,
    private localization: SpaLocalization,public dialog: MatDialog, private utils: SpaUtilities,private PropertyInfo: SpaPropertyInformation, 
    private business: DynamicYieldingBusiness, private breakPoint: BreakPointAccess) {
      this.captions = this.localization.captions;
      this.floatLabel = this.localization.setFloatLabel;
    }

  ngOnInit(): void {
    this.stopSlice = true;
    this.propertyDate = this.PropertyInfo.CurrentDate;
    this.config = JSON.parse(JSON.parse(sessionStorage.getItem('defaultSettings'))?.filter(x => x.switch === "APPOINTMENT_OPERATING_HOURS")[0]?.value);
    this.formGenerator();
    this.availabilityList = this.getAvailabilityFilterOptions();
    this.daysOfWeekList = this.getDaysOfWeekFilterOptions();
    this.validateBreakPoint();
  }

  validateBreakPoint(){
    this.breakPoint.CheckForAccess([SPAScheduleBreakPoint.SettingServiceYielding], false);
    this.isViewOnly = this.breakPoint.IsViewOnly(SPAScheduleBreakPoint.SettingServiceYielding);
    this.createYieldButton.disabledproperty = this.isViewOnly;
    this.createYieldButton = {...this.createYieldButton};
  }

  formGenerator(){
    this.serviceYeilding = this.fb.group({
      gridView:UI.GridType.Day,
      strategy: '',
      serviceGroup:'',
      service:'',
      listView: UI.ViewType.Grid,
      gridDate: '',
      startDate:'',
      endDate:'',
      startTime:'',
      endTime:'',
      isActive:false
    })
    this.createYieldButton = {
      label: this.captions.btn_createYield,
      type: 'primary',
      disabledproperty: false,
    };
    this.serviceGroupInput = {
      form: this.serviceYeilding,
      formControlName: 'serviceGroup',
      selectOptions: of(this.serviceOptions),
      placeHolder:this.captions.lbl_serviceGroup,
      automationId : "'Dd_DynamicYeilding_serviceGroup'"
    };
    this.serviceInput = {
      form: this.serviceYeilding,
      formControlName: 'service',
      selectOptions: of(this.serviceOptions),
      placeHolder: this.captions.lbl_service
    };
    this.strategyInput = {
      form: this.serviceYeilding,
      formControlName: 'strategy',
      selectOptions: null,
      placeHolder: this.captions.lbl_strategyName,
      automationId: "'Dd_DynamicYeilding_service'"
    };
    this.serviceMultipleInput = {
      form: this.serviceYeilding,
      formControlName: "service",
      selectOptions: of(this.serviceOptions),
      placeHolder: this.captions.lbl_service,
      isMultiSelect: true,
      isAll: true,
      isAllSelected: true,
      automationId: "'Dd_DynamicYeilding_service'",
    };
    this.applyButton = {
      type: "primary",
      label: this.captions.btn_apply,
      disabledproperty: false,
    };
    this.resetButton = {
      type: "secondary",
      label: this.captions.btn_reset,
    };
    this.startTimeInputs = {
      className: '',
      form: this.serviceYeilding,
      formControlName: 'startTime',
      placeHolder: this.captions.lbl_startTime,
      automationId: "'Txt_DynamicYeilding_startTime'"
    };
    this.endTimeInputs = {
      className: '',
      form: this.serviceYeilding,
      formControlName: 'endTime',
      placeHolder: this.captions.lbl_endTime,
      automationId: "'Txt_DynamicYeilding_endTime'"
    };
    this.strategyMultipleInput = {
      form: this.serviceYeilding,
      formControlName: "strategy",
      selectOptions: of([]),
      placeHolder: this.captions.lbl_strategyName,
      isMultiSelect: true,
      isAll: true,
      isAllSelected: true,
      automationId: "'Dd_DynamicYeilding_strategy'",
    };
    this.serviceGroupMultipleInput = {
      form: this.serviceYeilding,
      formControlName: "serviceGroup",
      selectOptions: of([]),
      placeHolder: this.captions.lbl_serviceGroup,
      isMultiSelect: true,
      isAll: true,
      isAllSelected: true,
      automationId: "'Dd_DynamicYeilding_serviceGroup'",
    };
    this.viewDate = {startDate: this.propertyDate, endDate: this.propertyDate}
    this.dateChanged(this.propertyDate);
  }

  async passGridInputs(type, startDate, endDate){
    this.gridType = type;
    if(type == UI.GridType.Week){
      this.gridStartDate = startDate;
      this.gridEndDate = endDate;
      this.serviceYeilding.controls.startDate.setValue(this.localization.getDate(this.gridStartDate));
      this.serviceYeilding.controls.endDate.setValue(this.localization.getDate(this.gridEndDate));
    } else{
      this.gridStartDate = startDate;
      this.gridEndDate = endDate;
      this.serviceYeilding.controls.gridDate.setValue(this.localization.getDate(this.gridStartDate));
    }
    this.appointmentOperatingHours = this.utils.getDayTimeForTheDate(this.config);
    this.gridStartTime = this.localization.getTime(this.localization.TimeToDate(this.appointmentOperatingHours['StartTime']), this.localization.getTimeFormat());
    this.gridEndTime = this.localization.getTime(this.localization.TimeToDate(this.appointmentOperatingHours['EndTime']), this.localization.getTimeFormat());
    this.gridOptions = {
      gridType: this.gridType,
      gridStartDate: this.gridStartDate,
      gridEndDate: this.gridEndDate,
      gridStartTime: this.gridStartTime,
      gridEndTime: this.gridEndTime,
      slotDifference: this.slotDifference
    };
    this.gridOptions = {...this.gridOptions};
    this.serviceYeilding.updateValueAndValidity();
    if (this.serviceYeilding.controls.listView.value === UI.ViewType.List)
      return;
    await this.getMasterData(startDate,endDate);
  }

  async getMasterData(startDate, endDate) {
    this.strategyList = [];
    this.masterData = await this.business.GetDynamicPricingServices(this.localization.convertDateTimeToAPIDateTime(startDate), this.localization.convertDateTimeToAPIDateTime(endDate));
    
    if (this.masterData && this.masterData.length > 0) {
      // let arr = _.uniqBy(this.masterData, 'groupId');
      let dpNameList = _.groupBy(this.masterData, o=>o.groupId);
      
      for(let groupId in dpNameList) {
        let Obj = dpNameList[groupId];
        let strategyObj;
        let SGList = [];
        Obj.forEach(x => {
          SGList.push(this.formServicegroup(x,Obj));
        });
        strategyObj = {
          strategyName: Obj[0]?.name,
          groupId: Obj[0]?.groupId,
          serviceGroupList : SGList
        }
        strategyObj.serviceGroupList = _.uniqBy(strategyObj.serviceGroupList, 'id');
        this.strategyList.push(strategyObj);
      }
      
      this.strategyList = _.uniqBy(this.strategyList,'groupId');
      let strategyIndex:number = 0;
      let serviceGroupIndex:number = 0;
      if(typeof this.selectedStrategy !== "undefined" && this.selectedStrategy !== null){
        const previousStrategyIndex = this.strategyList.findIndex(sl => sl.groupId === this.selectedStrategy) 
        strategyIndex = previousStrategyIndex > -1 ? previousStrategyIndex : 0;
      }
      if(strategyIndex >= 0 && typeof this.selectedServiceGroup !== "undefined" && this.selectedServiceGroup !== null){
        const previousGroupIndex = this.strategyList[strategyIndex].serviceGroupList.findIndex(sg => sg.id === this.selectedServiceGroup);
        serviceGroupIndex = previousGroupIndex > -1 ? previousGroupIndex : 0;
      }

      this.setStrategyServiceGroupWithService(strategyIndex,serviceGroupIndex, true, 'strategy');
      // this.gridType === UI.GridType.Day ? this.serviceYeilding.controls.gridDate.setValue(this.localization.getDate(startDate)): this.setdefaultValue(startDate, endDate);

      await this.getTableData();
    }  else {
      this.serviceYeilding.controls.strategy.setValue('');
      this.serviceYeilding.controls.serviceGroup.setValue('');
      this.serviceYeilding.controls.service.setValue('');
      this.selectedService = null;
      this.selectedStrategy = null;
      this.selectedServiceGroup = null;
      this.selectedStrategyName = null;
      this.strategyInput.selectOptions = of([]);
      this.serviceGroupInput.selectOptions = of([]);
      this.serviceInput.selectOptions = of([]);
      this.strategyInput = Object.assign({},this.strategyInput)
      this.serviceInput = Object.assign({},this.serviceInput)
      this.serviceGroupInput = Object.assign({},this.serviceGroupInput)
      this.pricingData = [];
    }
  }


  async delete(eve) {
    if(eve){
      let isOverrideDelete = eve && eve.obj && eve.obj.isOverride ? eve.obj.isOverride : false;
      this.utils.showAlert(isOverrideDelete ? this.captions.lbl_showOverrideDeleteMsg : this.captions.lbl_showDeleteMsg ,AlertType.Warning,ButtonType.YesNo,async (res) => {
          if (res == AlertAction.YES) {
            let isOverrideDelete = eve && eve.obj && eve.obj.isOverride ? eve.obj.isOverride : false;
            if (isOverrideDelete) {
              await this.deleteDynamicPricingByOverrideGroupId(eve.obj);
            } else {
              await this.deleteDynamicPricingByGroupId(eve);
            }
          }
        }
      );
    }
  }

  async deleteDynamicPricingByGroupId(eve) {
    await this.business.DeleteDynamicPricing(eve.obj.groupId).then((x) => {
      
      if(this.serviceYeilding.controls.listView.value === this.listViewEnum.List){
        this.viewDate.forceReload = true;
        this.viewDate = Object.assign({}, this.viewDate)     
      }else if (this.serviceYeilding.controls.gridView.value === UI.GridType.Day) {
        this.passGridInputs(
          UI.GridType.Day,
          this.serviceYeilding.controls.gridDate.value,
          this.serviceYeilding.controls.gridDate.value
        );
      } else {
        this.passGridInputs(
          UI.GridType.Week,
          this.serviceYeilding.controls.startDate.value,
          this.serviceYeilding.controls.endDate.value
        );
      }
    });
  }

  async deleteDynamicPricingByOverrideGroupId(data) {
    let overrideGroupId = data.overrideGroupId;
    let dynamicPricingGroupId = data.groupId;
    await this.business.DeleteOverrideDynamicPricingByGroupId(overrideGroupId, dynamicPricingGroupId).then(res => {
       this.refresh();
       this.viewDate.forceReload = true;
       this.viewDate = Object.assign({}, this.viewDate)     
      })
      .catch(res => {
        this.business.showBusinessError(res,(res: string) => {
            if (res == AlertAction.YES) {
              this.business.DeleteOverrideDynamicPricingByGroupId(overrideGroupId, dynamicPricingGroupId, true).then(() => {
                this.refresh();
                this.viewDate = Object.assign({}, this.viewDate)     
              }).catch((err) => {
                this.business.showBusinessError(err);
              });
            }
          })
      });
  }

  refresh() {
    if (this.serviceYeilding.controls.gridView.value === UI.GridType.Day) {
      this.passGridInputs(
        UI.GridType.Day,
        this.serviceYeilding.controls.gridDate.value,
        this.serviceYeilding.controls.gridDate.value
      );
    } else {
      this.passGridInputs(
        UI.GridType.Week,
        this.serviceYeilding.controls.startDate.value,
        this.serviceYeilding.controls.endDate.value
      );
    }
  }

  Copy(e) {
    this.edit(e, ActionMode.Copy, this.captions.hdr_copyYieldSetup,this.DEFAULT_GUID);
  }
    
  setStrategyServiceGroupWithService(index, serviceGroupIdx , fromInit = false, fromChange) {
      let strategyNameList = this.strategyList.map(item => ({id: item.groupId, value: item.strategyName, viewValue: item.strategyName}));
      let serviceGroupList = this.strategyList[index].serviceGroupList.map(item => ({id: item.id, value: item.code, viewValue: item.code}));
      let serviceList = this.strategyList[index].serviceGroupList[serviceGroupIdx].services.map(item => ({id: item.id, value: item.code, viewValue: item.code}));
      
      this.strategyInput.selectOptions = of(strategyNameList);
      this.strategyInput = {...this.strategyInput};

      this.serviceGroupInput.selectOptions = of(serviceGroupList);
      this.serviceGroupInput = {...this.serviceGroupInput};

      this.serviceInput.selectOptions = of(serviceList);
      this.serviceInput = {...this.serviceInput};
      
      this.selectedStrategyServiceGroupList = serviceGroupList;

      let serviceIndex: number = 0;
      if(typeof this.selectedService !== "undefined" && this.selectedService !== null){
        const previousServiceIndex =  serviceList.findIndex(sl => sl.id === this.selectedService);
        serviceIndex = previousServiceIndex > -1 ? previousServiceIndex : 0;
      }

      if(fromInit || fromChange === 'strategy') {
        this.serviceYeilding.controls.strategy.setValue(strategyNameList[index]);
        this.serviceYeilding.controls.serviceGroup.setValue(serviceGroupList[serviceGroupIdx]);
        this.serviceYeilding.controls.service.setValue(serviceList[serviceIndex]);
      }
      if (fromChange === 'servicegroup') {
        this.serviceYeilding.controls.service.setValue(serviceList[0]);
      }
     
      this.selectedService = serviceList[serviceIndex].id;
      this.filteredService = serviceList[serviceIndex];
      this.selectedStrategy = strategyNameList[index].id;
      this.selectedStrategyName = strategyNameList[index].viewValue;
      this.selectedServiceGroup = serviceGroupList[serviceGroupIdx].id;
  }

  setdefaultValue(startDate, endDate) {
    this.serviceYeilding.controls.startDate.setValue(this.localization.delocalizeDisplayDate(startDate));
    this.serviceYeilding.controls.endDate.setValue(this.localization.delocalizeDisplayDate(endDate));
  }

  formServicegroup(data, Obj) {
    let servicesList = Obj.filter(x => x.serviceGroupId === data.serviceGroupId).map(item => ({ id: item.serviceId, code: item.serviceCode }));
    servicesList = _.uniqBy(servicesList, 'code');
    return {
      id: data.serviceGroupId,
      code: data.serviceGroupCode,
      services: servicesList
    }
  }

  async getTableData() {
    this.utils.ToggleLoader(true);
    this.pricingData = [];
    this.gridOptions = {
      gridType: this.gridType,
      gridStartDate: this.gridStartDate,
      gridEndDate: this.gridEndDate,
      gridStartTime: this.gridStartTime,
      gridEndTime: this.gridEndTime,
      slotDifference: this.slotDifference
    };
    this.gridOptions = {...this.gridOptions};
    let startDate = this.gridType === UI.GridType.Day ? this.localization.convertDateTimeToAPIDateTime(new Date(this.serviceYeilding.controls.gridDate.value)) : this.localization.convertDateTimeToAPIDateTime(this.localization.getDate(this.gridStartDate));
    let endDate = this.gridType === UI.GridType.Day ? this.localization.convertDateTimeToAPIDateTime(new Date(this.serviceYeilding.controls.gridDate.value)) :this.localization.convertDateTimeToAPIDateTime(this.localization.getDate(this.gridEndDate));
    let response = await this.business.GetPricingDetails(startDate, endDate ,this.selectedStrategy);
    this.pricingData = response.map(obj => {
      return {
        ...obj,
        pricingDetails: obj.pricingDetails.filter(pd => pd.services.includes(this.selectedService))
      };
    })
    .filter(obj => obj.pricingDetails.length > 0);
    console.log(this.pricingData);
    this.pricingData = [...this.pricingData];
    this.utils.ToggleLoader(false);
  }

  async strategyChange(e) {
    this.utils.ToggleLoader(true);
    if(e.id) {
      let idx = this.strategyList.findIndex(x => x.groupId === e.id);
      this.setStrategyServiceGroupWithService(idx, 0, false, 'strategy');
      await this.getTableData();
      this.utils.ToggleLoader(false);
    }    
  }

  async serviceGroupChange(e) {
    this.utils.ToggleLoader(true);
    if(e.id) {
      let idx = this.strategyList.findIndex(x => x.groupId === this.selectedStrategy);
      let serviceGrpIdx = this.strategyList[idx].serviceGroupList.findIndex(x => x.id === e.id);
      this.setStrategyServiceGroupWithService(idx, serviceGrpIdx ,false,'servicegroup');
      await this.getTableData();
      this.utils.ToggleLoader(false);
    }    
  }


  async serviceChange(eve) {
    this.utils.ToggleLoader(true);
    this.selectedService = eve.id;
    this.filteredService = eve;
    await this.getTableData();
    this.utils.ToggleLoader(false);
  }

  getServiceOptions(){
    return[
      {
        id: 0,
        value: 'test',
        viewValue: 'test'
      }
    ]
  }

  getStrategyOptions(){
    return[
      {
        id: 1,
        value: 'test',
        viewValue: 'test'
      }
    ]
  }

  gridViewChange(e){
    this.cancelFilter(true);
    this.dateChanged(this.viewDate ? this.viewDate.startDate : this.propertyDate);
  }

  async listViewChange() {
    if (this.serviceYeilding.controls.listView.value == UI.ViewType.List) {
      this.toggleActive = false;
      this.serviceYeilding.controls.isActive.setValue(false);
      this.serviceYeilding.controls.startDate.setValue(this.viewDate.startDate);
      this.startDateInput = this.localizedDate(this.viewDate.startDate);
      this.startofWeek = this.localizedDate(this.viewDate.startDate);
      if(this.viewDate.endDate){
        this.serviceYeilding.controls.endDate.setValue(this.viewDate.endDate);
        this.endDateInput = this.localizedDate(this.viewDate.endDate);
        this.endofWeek = this.localizedDate(this.viewDate.endDate);
      }else{
        this.endDateInput = "";
        this.endofWeek = ""
        this.serviceYeilding.controls.endDate.setValue(null);
      }
      this.setServiceFilter(null);
      this.cancelFilter(true);
    } else {
      this.serviceYeilding.controls.gridDate.setValue(this.viewDate.startDate);
      if (this.serviceYeilding.controls.gridView.value == UI.GridType.Day) {
        this.dateInput = this.localizedDate(this.viewDate.startDate);
        await this.passGridInputs(UI.GridType.Day, this.dateInput, this.dateInput);
      }else if (
        this.serviceYeilding.controls.gridView.value == UI.GridType.Week
      ) {
        this.startofWeek = this.localizedDate(
          this.viewDate.startDate
        );
        if(!this.viewDate.endDate){
          this.viewDate.endDate = this.viewDate.startDate 
        }
        this.endofWeek = this.localizedDate(this.viewDate.endDate ? this.viewDate.endDate : this.viewDate.startDate);
        this.dateInput = this.startofWeek + " - " + this.endofWeek;
        this.startDateInput = this.startofWeek;
        this.endDateInput = this.endofWeek;
        await this.passGridInputs(UI.GridType.Week, this.startofWeek, this.endofWeek);
      }
    }
  }

  async dateChanged(e) {
    this.utils.ToggleLoader(true);
    this.serviceYeilding.controls.gridDate.setValue(e);
    if (this.serviceYeilding.controls.gridView.value == UI.GridType.Day) {
      this.dateInput = this.localizedDate(e);
      await this.passGridInputs(UI.GridType.Day, this.dateInput, this.dateInput);
      this.viewDate = { startDate: e, endDate: e };
      this.utils.ToggleLoader(false);
    } else if(this.serviceYeilding.controls.gridView.value == UI.GridType.Week) {
      this.startofWeek = this.localizedDate(this.localization.getStartOfWeekTenant(e));
      this.endofWeek = this.localizedDate(this.localization.getLastOfWeekTenant(e));
      this.dateInput = this.startofWeek + " - " + this.endofWeek;
      this.startDateInput = this.startofWeek;
      this.endDateInput = this.endofWeek;
      await this.passGridInputs(UI.GridType.Week, this.startofWeek, this.endofWeek);
      this.viewDate = {
        startDate: this.localization.getStartOfWeek(e),
        endDate: this.localization.getLastOfWeek(e),
      };
      this.utils.ToggleLoader(false);
    }    
  }

  startdateRangeChanged(e) {
    this.serviceYeilding.controls.startDate.setValue(e);
    this.startDateInput = this.localizedDate(e);
    this.startofWeek = this.localizedDate(e);
    if (this.viewDate) {
      this.viewDate.startDate = e;
    }
  }

  async enddateRangeChanged(e) {
    this.utils.ToggleLoader(true);
    if (e != null) {
      this.serviceYeilding.controls.endDate.setValue(e);
      this.endDateInput = this.localizedDate(e);
      this.endofWeek = this.localizedDate(e);
      if (this.startofWeek && this.endofWeek) {
        await this.passGridInputs(UI.GridType.Week, this.startofWeek, this.endofWeek);
        this.utils.ToggleLoader(false);
      }        
      if (this.viewDate) {
        this.viewDate.endDate = e;
        this.utils.ToggleLoader(false);
      }
    } else {
      if (this.viewDate) {
        this.utils.ToggleLoader(false);      
        this.viewDate.endDate = null;
        this.endDateInput = "";
        this.endofWeek = "";
      }
    }    
  }

  moveNextDate(e){
    if(this.serviceYeilding.controls.listView.value == UI.ViewType.Grid){
      if(this.serviceYeilding.controls.gridView.value == UI.GridType.Day){
        let date = new Date(this.dateInput)
        this.dateChanged(date.setDate(new Date(date).getDate() + 1));
      }
      else{
        let date = new Date(this.endofWeek)
        this.dateChanged(date.setDate(new Date(date).getDate() + 1));
      }
    }
    else if(this.serviceYeilding.controls.listView.value == UI.ViewType.List){
      let enddate = new Date(this.endDateInput);
    }
  }
  moveBeforeDate(e){
    if(this.serviceYeilding.controls.listView.value == UI.ViewType.Grid){
      if(this.serviceYeilding.controls.gridView.value == UI.GridType.Day){
        let date = new Date(this.dateInput)
        this.dateChanged(date.setDate(new Date(date).getDate() - 1));
      }
      else{
        let date = new Date(this.startofWeek)
        this.dateChanged(date.setDate(new Date(date).getDate() - 1));
      }
    }
    else if(this.serviceYeilding.controls.listView.value == UI.ViewType.List){
      let startdate = new Date(this.startDateInput);
    }
  }

  localizedDate(value): string {
    return moment(value).locale('en-us').format('DD MMM YYYY');
  }

  createYieldSetup(){
    this.crudActionInput = {
      mode: ActionMode.Create,
      actionButton: this.captions.btn_create,
      form: undefined
  };
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_newYieldSetup
    };
  }

  backAction(form?) {
    this.createToggler = false;
  }

  handleClick(event){
    switch (event.from) {
      case ActionMode.Create:
        this.crudActionInput = {
          mode: ActionMode.Create,
          actionButton: this.captions.btn_save,
          form: null
        };
        this.createToggler = true;
        this.createModalData = {
          title: this.captions.hdr_newYieldSetup
        };
        break;
      case ActionMode.Cancel:
        this.backAction(event);
        break;
      case ActionMode.Edit:
          this.backAction();
        break;

    }
  }

  saveClick(eve) {
    if(eve) {
      this.backAction();
      if (this.serviceYeilding.controls.listView.value == UI.ViewType.List) {
        if(this.viewDate.startDate){
          const endDate = this.viewDate.endDate ? this.viewDate.endDate : this.viewDate.startDate;
          this.viewDate.endDate = endDate;
          this.viewDate.forceReload = true;
          this.viewDate = Object.assign({}, this.viewDate);
        }
        
      }else{
        this.dateChanged(this.viewDate.startDate);       
      }
    }
  }

  setFilteredListData(filteredListData: API.YieldingDetails[])
  {
    this.filteredListData = filteredListData;
  }

  multipleOverride() {
    let services = [];
    if (this.serviceYeilding.controls.listView.value !== this.listViewEnum.List) {
      services = this.strategyList
        .filter((strategy) => strategy.groupId === this.selectedStrategy)
        .flatMap((strategy) => strategy.serviceGroupList)
        .flatMap((group) => group.services)
        .map((service) => ({
          id: service.id,
          viewValue: service.code,
          value: service.code,
        }));
    }
  
    if (this.serviceYeilding.controls.listView.value == this.listViewEnum.List) {
      if (this.filteredListData.length > 0) {
        const defaultStrategy = this.filteredListData[0];
        const serviceGroupIds = [
          ...new Set(
            defaultStrategy.pricingDetails.flatMap((pd) => pd.serviceGroup)
          ),
        ];
        const serviceIds = [
          ...new Set(defaultStrategy.pricingDetails.flatMap((pd) => pd.services)),
        ];
        this.crudActionInput = {
          mode: ActionMode.MultiDateOverride,
          actionButton: this.captions.btn_create,
          form: {
            date: this.propertyDate,
            name: defaultStrategy.name,
            strategyId: defaultStrategy.groupId,
            isActive: true,
            serviceGroupOptions: [],
            serviceOptions: [],
            serviceGroup: serviceGroupIds,
            serviceId: serviceIds,
          },
          startDate: new Date(defaultStrategy.startDate),
          endDate: new Date(defaultStrategy.endDate),
        };
      } else {
        this.utils.showError(this.captions.RequiredStrategy);
        return;
      }
    } else if (
      this.serviceYeilding.controls.gridView.value == this.gridViewEnum.Week
    ) {
      this.crudActionInput = {
        mode: ActionMode.MultiDateOverride,
        actionButton: this.captions.btn_create,
        form: {
          date: this.propertyDate,
          name: this.selectedStrategyName,
          strategyId: this.selectedStrategy,
          isActive: true,
          serviceGroupOptions: this.selectedStrategyServiceGroupList,
          serviceOptions: services,
          serviceGroup: this.selectedStrategyServiceGroupList.map((x) => x.id),
          serviceId: services.map((x) => x.id),
        },
        startDate:
          this.pricingData.length > 0
            ? this.pricingData[0].startDate
            : this.serviceYeilding.controls.startDate.value,
        endDate:
          this.pricingData.length > 0
            ? this.pricingData[0].endDate
            : this.serviceYeilding.controls.endDate.value,
      };
    } else if (
      this.serviceYeilding.controls.gridView.value == this.gridViewEnum.Day
    ) {
      this.crudActionInput = {
        mode: ActionMode.SingleDateOverride,
        actionButton: this.captions.btn_create,
        form: {
          date: this.propertyDate,
          name: this.selectedStrategyName,
          strategyId: this.selectedStrategy,
          isActive: true,
          serviceGroupOptions: this.selectedStrategyServiceGroupList,
          serviceOptions: services,
          serviceGroup: this.selectedStrategyServiceGroupList.map((x) => x.id),
          serviceId: services.map((x) => x.id),
        },
        startDate:
          this.pricingData.length > 0
            ? this.pricingData[0].startDate
            : this.serviceYeilding.controls.startDate.value,
        endDate:
          this.pricingData.length > 0
            ? this.pricingData[0].endDate
            : this.serviceYeilding.controls.endDate.value,
        gridDate: this.serviceYeilding.controls.gridDate.value,
      };
    }
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_overrideDynamicYield,
    };
    this.createModalData = { ...this.createModalData };
    this.crudActionInput = { ...this.crudActionInput };
  }

  onActionEmit(e){
    switch (e.mode) {
      case ActionMode.SingleDateOverride:
        this.override(e, ActionMode.SingleDateOverride, this.captions.hdr_overrideDynamicYield, this.DEFAULT_GUID);
        break;
      case ActionMode.Edit:
          if (e.mode == ActionMode.Edit) {
            this.edit(e, ActionMode.Edit, this.captions.hdr_editYieldSetup, this.DEFAULT_GUID);
          }
          break;
      case ActionMode.Delete:
          this.delete(e);
          break;
      case ActionMode.Copy:
        this.Copy(e);
          break; 
      case ActionMode.MultiDateOverride:
        this.override(e, ActionMode.MultiDateOverride, this.captions.hdr_overrideDynamicYield, this.DEFAULT_GUID);
        break;  
      case ActionMode.EditOverride:
        if (e.mode == ActionMode.EditOverride) {
          this.edit(e, ActionMode.EditOverride, this.captions.hdr_editYieldSetup, e.obj.overrideGroupId);
        }
        break;        
    }
  }

  async edit(eve, actionMode, header, overrideGuid) {
    await this.business.GetServiceByServiceIdAndDate(eve.obj.groupId, overrideGuid).then(x => {
      this.editData = this.business.editData;
      let isOverrideExist : boolean = false
      if(x && actionMode === ActionMode.Edit){
        isOverrideExist = x.some(d=> typeof d.hasOverride === "undefined" ? false : d?.hasOverride)
      }
      this.crudActionInput = {
        mode: actionMode,
        actionButton: this.captions.btn_update,
        form: x,
        infoMessage: isOverrideExist ? this.captions.lbl_dbEditInfo : ''
      };
      this.createToggler = true;
      this.createModalData = {
        title: header
      };
      this.createModalData = {...this.createModalData};
      this.crudActionInput = {...this.crudActionInput};
    })
  }

  override(eve, actionMode, header, overrideGuid) {
    let dateObj = eve.obj.value.value;   
    console.log(this.strategyList);
    const services = this.strategyList
                    .filter(strategy => strategy.groupId === this.selectedStrategy)
                    .flatMap(strategy => strategy.serviceGroupList)
                    .flatMap(group => group.services)
                    .map(service => ({
                      id: service.id,
                      viewValue: service.code,
                      value: service.code
                    }));
    this.crudActionInput = {
      mode: actionMode,
      actionButton: this.captions.btn_update,
      form: {
        date : dateObj,
        name: this.selectedStrategyName, 
        strategyId: this.selectedStrategy,
        isActive: true,      
        serviceGroupOptions:this.selectedStrategyServiceGroupList,
        serviceOptions: services,
        serviceGroup: this.selectedStrategyServiceGroupList.map(x => x.id),
        serviceId: services.map(x => x.id)
      },
      gridDate: this.serviceYeilding.controls.gridDate.value,
      startDate: this.pricingData.length > 0 ? this.pricingData[0].startDate : this.serviceYeilding.controls.startDate.value,
      endDate: this.pricingData.length > 0 ? this.pricingData[0].endDate : this.serviceYeilding.controls.endDate.value
    };
    this.createToggler = true;
    this.createModalData = {
      title: header
    };
    this.createModalData = {...this.createModalData};
    this.crudActionInput = {...this.crudActionInput};
  }

  toggleAccordian(e){
    this.isExpandAll = !this.isExpandAll;
  }
  
  applyFilter() {
    this.filterConfig.closeMenu();
    this.menuFilter = {
      startTime: this.serviceYeilding.controls.startTime?.value ?? "",
      endTime: this.serviceYeilding.controls.endTime?.value ?? "",
      daysOfWeek: this.daysOfWeekFilter,
      availability: this.availabilityFilter,
      rangeStart: this.batchStart ?? 0,
      rangeEnd: this.batchEnd ?? 100
    };
    this.menuFilter = Object.assign({}, this.menuFilter);
  }

  cancelFilter(isReset: boolean) {
    this.filterConfig.closeMenu();
    if (isReset) {
      this.menuFilter = Object.assign(
        {},
        {
          startTime: "",
          endTime: "",
          daysOfWeek: [],
          availability: [],
          rangeStart: this.batchMin,
          rangeEnd: this.batchMax,
          isActive: this.serviceYeilding.controls.isActive?.value ?? false
        }
      );
      this.serviceYeilding.controls.startTime.setValue("");
      this.serviceYeilding.controls.endTime.setValue("");
      this.daysOfWeekFilter = [];
      this.availabilityFilter = [];
      this.batchMin = 0;
      this.batchStart = this.batchMin;
      this.batchMax = 100;
      this.batchEnd = this.batchMax;
      this.defaultSelected=[];
      this.fieldUpdate = {
        'mathRandom' : Math.random(),
        'minValue' : this.batchMin,
        'maxValue' : this.batchMax
      }
    }
  }

  openPicker(event) {}

  buttonSelectionChange(buttonData: any, from: string) {
    if (!buttonData) return;

    if (from === "daysOfWeek") {
      this.daysOfWeekFilter = buttonData.value
        ? buttonData.value.map((data) => data.id)
        : [];
    } else if (from === "availability") {
      this.availabilityFilter = buttonData.value
        ? buttonData.value.map((data) => data.id)
        : [];
    }
  }

  getAvailabilityFilterOptions(){
    return[
      {
        id: 0,
        name: 'Available'
      },
      {
        id: 1,
        name: 'Unavailable'
      }
    ]
  }

  getDaysOfWeekFilterOptions(){
    return[
      {
        id: DaysOfWeek.Monday,
        name: 'Mon'
      },
      {
        id: DaysOfWeek.Tuesday,
        name: 'Tue'
      },
      {
        id: DaysOfWeek.Wednesday,
        name: 'Wed'
      },
      {
        id: DaysOfWeek.Thursday,
        name: 'Thu'
      },
      {
        id: DaysOfWeek.Friday,
        name: 'Fri'
      },
      {
        id: DaysOfWeek.Saturday,
        name: 'Sat'
      },
      {
        id: DaysOfWeek.Sunday,
        name: 'Sun'
      }
    ]
  }
  sliderChange(_eve) {
    this.batchStart = _eve.value;
    this.batchEnd = _eve.highValue;
  }

  /**
   * set filter dropdown values
   * @param serviceDetails [0] - ServiceGroup , [1] - Service, [2] - Strategy
   */
  setServiceFilter(
    serviceDetails: [DropdownOptions[], DropdownOptions[], DropdownOptions[]]
  ) {
    if (serviceDetails) {
      this.serviceGroupMultipleInput.selectOptions = of(serviceDetails[0]);
      this.serviceMultipleInput.selectOptions = of(serviceDetails[1]);
      this.strategyMultipleInput.selectOptions = of(serviceDetails[2]);
      this.setFilterFormValue(serviceDetails);
      this.listServiceDetails = _.cloneDeep(serviceDetails);
    } else {
      this.serviceMultipleInput.selectOptions = of([]);
      this.serviceGroupInput.selectOptions = of([]);
      this.strategyMultipleInput.selectOptions = of([]);
      this.setFilterFormValue([[], [], []]);
      this.listServiceDetails = [[], [], []];
    }
    this.serviceMultipleInput = Object.assign({}, this.serviceMultipleInput);
    this.serviceGroupMultipleInput = Object.assign(
      {},
      this.serviceGroupMultipleInput
    );
    this.strategyMultipleInput = Object.assign({}, this.strategyMultipleInput);
  }

  setFilterFormValue(
    serviceDetails: [DropdownOptions[], DropdownOptions[], DropdownOptions[]]
  ) {
    this.serviceYeilding.controls.serviceGroup.setValue(serviceDetails[0]);
    this.serviceYeilding.controls.service.setValue(serviceDetails[1]);
    this.serviceYeilding.controls.strategy.setValue(serviceDetails[2]);
    this.serviceGroupFilterSelected = Object.assign(
      {},
      {
        selectedOptions: this.serviceYeilding.controls.serviceGroup.value,
        isFilterRequired: false,
      }
    );
    this.serviceFilterSelected = Object.assign(
      {},
      {
        selectedOptions: this.serviceYeilding.controls.service.value,
        isFilterRequired: false,
      }
    );
    this.strategyFilterSelected = Object.assign(
      {},
      {
        selectedOptions: this.serviceYeilding.controls.strategy.value,
        isFilterRequired: true,
      }
    );
  }

  onDatePickerClose() {
    if(this.viewDate.startDate && this.viewDate.endDate){
      this.resetList = false;
      this.viewDate = Object.assign({}, this.viewDate);
    }else{
      this.resetList = true
    }
  }

  onMultiSelectChange(value: DropdownOptions, from: string) {
    if (from === "strategy") {
      this.strategyFilterSelected = Object.assign(
        {},
        {
          selectedOptions: this.serviceYeilding.controls.strategy.value,
          isFilterRequired: true,
        }
      );
    } else if (from === "serviceGroup") {
      this.serviceGroupFilterSelected = Object.assign(
        {},
        {
          selectedOptions: this.serviceYeilding.controls.serviceGroup.value,
          isFilterRequired: true,
        }
      );
    } else if (from === "service") {
      this.serviceFilterSelected = Object.assign(
        {},
        {
          selectedOptions: this.serviceYeilding.controls.service.value,
          isFilterRequired: true,
        }
      );
    }
  }

  setFilteredServiceDetails(
    filteredDetails: [
      DropdownOptions[],
      DropdownOptions[],
      DropdownOptions[],
      DropdownOptions[]
    ]
  ) {
    this.serviceGroupMultipleInput.selectOptions = of(filteredDetails[0]);
    this.serviceMultipleInput.selectOptions = of(filteredDetails[1]);
    this.serviceMultipleInput = Object.assign({}, this.serviceMultipleInput);
    this.serviceGroupMultipleInput = Object.assign(
      {},
      this.serviceGroupMultipleInput
    );
    this.serviceYeilding.controls.serviceGroup.setValue(filteredDetails[2]);
    this.serviceYeilding.controls.service.setValue(filteredDetails[3]);
  }

  onIsActiveChange(event:any){
    if(this.menuFilter){
      this.serviceYeilding.controls['isActive']?.setValue(event);
      this.toggleActive = event;
    }
  }

  /**
   * set filtered StrategyOptions on active toggle
   * @param event 
   */
  setStrategyOptions(event:DropdownOptions[])
  {
    if(event){
      this.strategyMultipleInput.selectOptions = of(event);
      this.strategyMultipleInput = Object.assign({},this.strategyMultipleInput)
      this.serviceYeilding.controls.strategy.setValue(event.filter(e => e.checked));
      this.strategyFilterSelected = Object.assign(
        {},
        {
          selectedOptions: this.serviceYeilding.controls.strategy.value,
          isFilterRequired: true,
        }
      );
    }
  }

  filterClick() {
    setTimeout(() => { window.dispatchEvent(new Event('resize')); 
    $(window).trigger('resize'); }, 100);
  }
}
