import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormType, GroupOptions, SubHeaderOptions } from '../../form-type.entity';

@Component({
  selector: 'lib-grouped-checkbox',
  templateUrl: './grouped-checkbox.component.html',
  styleUrls: ['./grouped-checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GroupedCheckboxComponent implements OnInit {
  @Input() field = {} as FormType;
  @Input() form: FormGroup;
  selectedList: Array<any> = [];
  fieldType: string;
  formFieldName: any;
  formFieldlabel: any;
  constructor(
    public AgyDynamicFormService: AgyDynamicFormServices
  ) {
  }

  selectSubGroupName(module: GroupOptions, event, subgroupindex): void {
    try {
      this.selectedList = this.field.fieldType.SingleData.groupOptions.map(element => {
        if (element.Id === module.Id) {
          element.SubHeaderGroup.map((subGrp, index) => {
            if (index === subgroupindex) {
              subGrp.IsActive = event.checked;
              subGrp.SubGroupOptions.map(subOptions => {
                subOptions.IsActive = event.checked;
              });
            }
          });
        }
      });
      this.form.controls[this.formFieldName].setValue(this.field.fieldType.SingleData.groupOptions);
      this.AgyDynamicFormService.formEventCallback(this.field, 0, this.form);
    } catch (error) {

    }
  }

  selectSubGroup(resource: SubHeaderOptions, index: any, subgroupindex: any): void {
    try {
      const selectedGroup = this.field.fieldType.SingleData.groupOptions[index].SubHeaderGroup[subgroupindex].SubGroupOptions.filter(x =>
        x.IsActive === true).length;
      const resourceGroup = this.field.fieldType.SingleData.groupOptions[index].SubHeaderGroup[subgroupindex].SubGroupOptions.length;
      if (selectedGroup === resourceGroup) {
        this.field.fieldType.SingleData.groupOptions.map(grp => {
          if (grp.Id === resource.Id) {
            grp.IsActive = true;
            grp.SubHeaderGroup[subgroupindex].IsActive = true;
          }
        });
        this.form.controls[this.formFieldName].setValue(this.field.fieldType.SingleData.groupOptions);
        this.AgyDynamicFormService.formEventCallback(this.field, 0, this.form);
      } else {
        this.field.fieldType.SingleData.groupOptions.map(grp => {
          if (grp.Id === resource.Id) {
            grp.IsActive = false;
            grp.SubHeaderGroup[subgroupindex].IsActive = false;
          }
        });
        this.form.controls[this.formFieldName].setValue(this.field.fieldType.SingleData.groupOptions);
        this.AgyDynamicFormService.formEventCallback(this.field, 0, this.form);
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit(): void {
    this.fieldType = Object.keys(this.field.fieldType).toString();
    const formField = this.AgyDynamicFormService.generateFormFieldName(this.field);
    this.formFieldName = formField.fieldName;
    this.formFieldlabel = formField.fieldLabel;
  }

}
