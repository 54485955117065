import { OnInit, Injectable } from '@angular/core';
import { AppointmentpopupService } from '../service/appointmentpopup.service';
import { SpaUtilities } from '../utilities/spa-utilities';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';
import { Host, ButtonType } from '../globalsContant';
import { BaseResponse } from './shared.modals';
import { SlideInformationService } from '../slide-information/slide-information.service';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { TransactionStatus } from '../../retail/shared/service/common-variables.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';

@Injectable()
export class AppointmentBusiness implements OnInit {
  therapistId: any;
  appointmentId: any;
  startTime: any;
  UNDOCHKOUT: string = 'UNDOCHKOUT';
  CHANGESTATUS: string = 'CHANGESTATUS';
  constructor(public SlideService: SlideInformationService,
    private http: HttpServiceCall, private utils: SpaUtilities, public appointmentPopupService: AppointmentpopupService,
    private commonUtils: QuickLoginUtilities,
    private localization: SpaLocalization, private propertyInfo: SpaPropertyInformation) {

  }

  ngOnInit() {
    this.therapistId = this.SlideService.fieldData.therapistId;
    this.appointmentId = this.SlideService.fieldData.appointmentId;
    this.startTime = this.SlideService.fieldData.startDTTM;
  }
  DeleteTherapistBreak(appointmentId, therapistId, startTime, callBack: (result: any) => void) {
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "DeleteTherapistBreak",
      method: HttpMethod.Delete,
      uriParams: {
        id: appointmentId, therapistId: therapistId, breakTime: startTime
      },
      showError: true,
      extraParams: [callBack]
    });
  }

  UndoCheckout(appointmentId, transactionId, callBack: (result: any) => void) {
    if (transactionId && transactionId > 0) {
      this.InvokeServiceCall('GetTransactionInfoById', Host.retailPOS, HttpMethod.Get, { transactionId: transactionId }, null, [appointmentId, transactionId, callBack, this.UNDOCHKOUT])
    }
    else {
      this.proceedUndoCheckOut(appointmentId, callBack);
    }
  }

  InvokeServiceCall(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any, showError: boolean = true) {
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: showError,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    this.commonUtils.resetQuickIdDetails();
    switch (callDesc) {
      case "DeleteTherapistBreak":
      case "UndoCheckOutAppointment":
      case "UndoCheckOutAppointmentByTransactionId":
        {
          let func = extraParams[0];
          if (func) {
            func(result);
          }
        } break;

      case "GetTransactionInfoById":
        {
          let transaction: any = result.result;
          if (transaction && transaction.transactionData) {
            let transactionStatus: string = transaction.transactionData.status;
            let retailTransactionStatus: string = transaction.transactionData.retailTransactionType;
            let ticketNumber = this.propertyInfo.UseRetailInterface ? transaction.transactionData.ticketNumber : transaction.transactionData.retailTicketNumber;
            if (transactionStatus.toUpperCase() === TransactionStatus.CLOSED && retailTransactionStatus?.toUpperCase() != TransactionStatus.VOID) {
              let errorMsg = extraParams[3] === this.UNDOCHKOUT ? this.localization.captions.bookAppointment.UndocheckOutError : this.localization.captions.bookAppointment.ChangeStatusNotAllowed;
              this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
            }
            else if (transactionStatus.toUpperCase() === TransactionStatus.OPEN) {
              this.utils.ShowErrorMessage(this.localization.captions.common.Confirmation, this.localization.replacePlaceholders(this.localization.getError(100302), ["TicketNumber"], [ticketNumber]), ButtonType.YesNo, this.cancelTransactionConfimation.bind(this), extraParams);
            }
            else {
              extraParams[3] === this.UNDOCHKOUT ? this.proceedUndoCheckOutByTransaction(extraParams[1], extraParams[2]) : this.proceedChangeStatus(extraParams[2], extraParams[0], extraParams[4]);
            }
          }
        } break;

      case "CancelTransaction":
        {
          if (extraParams[3] === this.UNDOCHKOUT) {
            this.proceedUndoCheckOutByTransaction(extraParams[1], extraParams[2])
          }
          else if (extraParams[3] === this.CHANGESTATUS) {
            this.proceedChangeStatus(extraParams[2], extraParams[0], extraParams[4]);
          }
        }
    }

  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    this.commonUtils.resetQuickIdDetails();
  }

  proceedUndoCheckOut(appointmentId, callBack) {
    let body: number[] = [appointmentId]
    this.InvokeServiceCall('UndoCheckOutAppointment', Host.schedule, HttpMethod.Put, '', body, [callBack]);
  }

  proceedUndoCheckOutByTransaction(transactionId, callBack) {
    this.InvokeServiceCall('UndoCheckOutAppointmentByTransactionId', Host.schedule, HttpMethod.Put, { transactionId: transactionId }, null, [callBack]);
  }

  cancelTransactionConfimation(res, extraParams) {
    if (res.toUpperCase() === 'YES') {
      let uriParam = { transactionId: extraParams[1] }
      this.InvokeServiceCall('CancelTransaction', Host.retailPOS, HttpMethod.Delete, uriParam, null, extraParams)
    }
  }

  changeStatusForChkout(appointmentId, transactionId, toStatus, callBack: (result: any) => void) {
    if (transactionId && transactionId > 0) {
      this.InvokeServiceCall('GetTransactionInfoById', Host.retailPOS, HttpMethod.Get, { transactionId: transactionId }, null, [appointmentId, transactionId, callBack, this.CHANGESTATUS, toStatus])
    }
    else {
      this.proceedChangeStatus(callBack, appointmentId, toStatus);
    }
  }

  proceedChangeStatus(func, id, toStatus) {
    if (func) {
      func(id, toStatus);
    }
  }

}
