import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ShopBussinessService } from '../../shop/shop-business.service';
import { PaymentMethods } from '../business/shared.modals';

export function ReturnSettleValidator(ParentScope: ShopBussinessService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        let localizedVal = Number(ParentScope.localization.currencyToSQLFormat(control.value));
        const controlVal = (localizedVal < 0 ? localizedVal * -1 : localizedVal);

        let prevSettlement: number = 0,
            selectedPayment = ParentScope._utils.GetOriginalTenderId(ParentScope.selectedpayment.paymentTypeId, ParentScope.selectedpayment.parentTypeId);
        selectedPayment = selectedPayment == PaymentMethods.CardOnFile || selectedPayment == PaymentMethods.IDTECH ? PaymentMethods.CreditCard : selectedPayment;

        //IDTech methods are derived based on their parent methods so need to combine their settlements together for validation
        const currSelectedPayMethodWithSub = IncludeEquivalentIDTechPaymentMethod(selectedPayment);

        if (ParentScope.returnOriginalPaymentOnly) {
            if (ParentScope.TransactionPaymentsAggregate && ParentScope.TransactionPaymentsAggregate.length > 0 &&
                ParentScope.TransactionPaymentsAggregate.some(x => currSelectedPayMethodWithSub.includes(ParentScope._utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId)))) {
                ParentScope.TransactionPaymentsAggregate.filter(x => currSelectedPayMethodWithSub.includes(ParentScope._utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId)))
                    .forEach(x => prevSettlement += x.transactionPayment.paymentAmount);
            }
        }
        if(!ParentScope.returnOriginalPaymentOnly && (selectedPayment == PaymentMethods.ARPost || selectedPayment == PaymentMethods.CreditBook))
        {
            if (ParentScope.TransactionPaymentsAggregate && ParentScope.TransactionPaymentsAggregate.length > 0 &&
                ParentScope.TransactionPaymentsAggregate.some(x => currSelectedPayMethodWithSub.includes(ParentScope._utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId)))) {
                ParentScope.TransactionPaymentsAggregate.filter(x => currSelectedPayMethodWithSub.includes(ParentScope._utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod),x.transactionPayment.parentTenderId)))
                    .forEach(x => prevSettlement += x.transactionPayment.paymentAmount);
            }
        }
        let settlementAmt: number = 0;
        if (ParentScope.SettlementHistory.some(x => currSelectedPayMethodWithSub.includes(ParentScope._utils.GetOriginalTenderId(x.paymentMethodId, x.parentTenderId)))) {
            ParentScope.SettlementHistory.filter(x => currSelectedPayMethodWithSub.includes(ParentScope._utils.GetOriginalTenderId(x.paymentMethodId, x.parentTenderId))).forEach(x => settlementAmt += x.amount);
        }

        //Overall limit
        let OverAllMaxAmt = Math.abs(ParentScope.RoundOffTwo(prevSettlement + settlementAmt));
        if (control.value !== undefined && selectedPayment && (isNaN(localizedVal) || controlVal > OverAllMaxAmt)) {
            return { 'cmsLimitExceeded': true };
        }
        return null;
    };
}

/**
 * @description returns the paymentMethod combined with its equivalent sub IDTechMethod
 * @param selectedPayment 
 * @returns 
 */
export function IncludeEquivalentIDTechPaymentMethod(selectedPayment: PaymentMethods) {
    const currSelectedPayMethodWithSub = [selectedPayment];
    const paymentMethodsWithIDTechSubMethod = [
        PaymentMethods.ExternalGiftCard,
        PaymentMethods.V1GiftCard,
        PaymentMethods.AgilysysGiftCard
    ]
    if (paymentMethodsWithIDTechSubMethod.includes(selectedPayment)) {
        switch (selectedPayment) {
            case PaymentMethods.ExternalGiftCard:
                currSelectedPayMethodWithSub.push(PaymentMethods.ExternalGiftCardIdTech);
                break;
            case PaymentMethods.V1GiftCard:
                currSelectedPayMethodWithSub.push(PaymentMethods.V1GiftCardIdTech);
                break;
            case PaymentMethods.AgilysysGiftCard:
                currSelectedPayMethodWithSub.push(PaymentMethods.AgilysysGiftCardIdTech);
                break;
            default:
                break;
        }
    }
    return currSelectedPayMethodWithSub;
}