import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { DataImportWorkFlow, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';

export const DataImportUploadConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, value?: any, selectedId?: number, envService?: EnvService): any => {
    try {   
        const dataImportModel = {} as DataImportWorkFlow;
        dataImportModel.wexflowAuthToken = sessionStorage.getItem('WexFlowLogin');
        dataImportModel.fileName = JsonData.FileAttachedData[0].FileName;
        dataImportModel.inputFile = value.inputFile;
        dataImportModel.workFlowName = value.workFlowName;
        dataImportModel.isMultipleUpload = JsonData.CreateTranscationConfig.TransactionFormConfig.FormGroupData.UploadTypeId === 2 ?
         true : false;
        dataImportModel.uploadCategory = (JsonData.CreateTranscationConfig.TransactionFormConfig.FormGroupData.CategoryId === 1) ?
         0 : (JsonData.CreateTranscationConfig.TransactionFormConfig.FormGroupData.CategoryId === 2) ? 1 : null;
        dataImportModel.wexflowAuthToken = sessionStorage.getItem('WexFlowLogin');
        dataImportModel.tenantIdentifier = sessionStorage.getItem('TenantIdentifier');
        dataImportModel.userId = parseInt(sessionStorage.getItem('UserId'), 10);
        dataImportModel.userName = sessionStorage.getItem('UserName');
        dataImportModel.wexflowBatchSize = 100;
        dataImportModel.identityAuthToken = StorageService.GetSessionStorage('AccessToken');
        dataImportModel.inventoryServiceUri = envService.apiUrl + '/inventory';
        const PostData = Object.assign(dataImportModel);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Data Import Upload Construction', error);
    }
};
