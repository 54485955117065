import { Injectable } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { DeDupeGuestDataService } from 'src/app/shared/service/dedupeGuest.data.service';
import { dedupeGuestRecordBusiness } from 'src/app/common/components/dedupe-guest-records/dedupe-guest-records.business';
import { UI, API, DDtablecontent } from 'src/app/common/components/dedupe-guest-records/dedupe-guest-ui-model';
import { API as GuestAPI } from 'src/app/common/components/combine-guest-records/combine-guest-ui-model';
import { MatDialog } from '@angular/material/dialog';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { AlertPopupComponent } from 'src/app/retail/shared/alert-popup/alert-popup.component';


@Injectable()
export class DeDupeGuestRecordsService extends dedupeGuestRecordBusiness  {
    captions: any;
    contactTypes: any;
   
    isViewOnly: boolean;
    subscription: any;
  
    constructor(public localization: SpaLocalization, public deDupeGuestDataService: DeDupeGuestDataService,public dialog:MatDialog) 
    {
        super(localization);
        this.captions = this.localization.captions;
  
    }

    public async getDeDupeGuests(searchCriteria: UI.DeDupeGuestSearchFields): Promise<DDtablecontent[]> {   
        const searchCriteriaAPIModel: API.GuestSearchFields = this.apiMapperGuestSearchFields(searchCriteria);
        const apiGuestResponse: any = await this.deDupeGuestDataService.getGuestsBySearchCriteria(searchCriteriaAPIModel);   
        const apiGuestModels: API.Guest[] = apiGuestResponse.result;    
        console.log(apiGuestModels);
        return apiGuestModels.map(x => this.uiGridMapper(x));
    }
    public apiMapperGuestSearchFields(GuestSearchFieldsUIModel: UI.DeDupeGuestSearchFields): API.GuestSearchFields {

        return {
          lastName: GuestSearchFieldsUIModel.lastName,
          firstName: GuestSearchFieldsUIModel.firstName,
          postalCode: GuestSearchFieldsUIModel.postalCode,
          phone: GuestSearchFieldsUIModel.phone,
          emailAddress: GuestSearchFieldsUIModel.emailAddress,
          groupfirstName: GuestSearchFieldsUIModel.groupfirstName,
          grouplastName: GuestSearchFieldsUIModel.grouplastName,
          groupemailAddress: GuestSearchFieldsUIModel.groupemailAddress,
          groupphone: GuestSearchFieldsUIModel.groupphone,
          grouppostalCode: GuestSearchFieldsUIModel.grouppostalCode
        } as API.GuestSearchFields;
      }
    public uiGridMapper(GuestAPIModel: API.Guest): DDtablecontent {
        return {
          guestId: GuestAPIModel.guestId,
          platformGuestUuid: GuestAPIModel.platformGuestUuid,
          lastName: GuestAPIModel.lastName,
          middleName: GuestAPIModel.middleName,
          firstName: GuestAPIModel.firstName,
          title: GuestAPIModel.title,
          address: GuestAPIModel.address,
          postalCode: GuestAPIModel.zipCode,
          city: GuestAPIModel.city,
          phoneNumber: GuestAPIModel.cellPhone,
          homePhone: GuestAPIModel.homePhone,
          personalEmail: GuestAPIModel.personalEmail,
          emailAddress: GuestAPIModel.officeEmail,    
          state: GuestAPIModel.state,
          country: GuestAPIModel.country,
          groupNumber: GuestAPIModel.groupNumber,
          isPrimary: false,
          isSecondary: false,
          isExpanded: false
        } as DDtablecontent;
      }
      
    public async combineGuestRecords(primarydata, secondarydata) {
      return await this.deDupeGuestDataService.MergeGuestsRecords(primarydata, secondarydata);   
    }
    public async combineGuestInfo(guestInfo) {
        await this.deDupeGuestDataService.UpdateGuestInformation(guestInfo.guestId, guestInfo);
    }
    public async getGuestInformationByGuid(Guid: string): Promise<GuestAPI.Guest> {
      const guestData: GuestAPI.Guest = await this.deDupeGuestDataService.getClientDataByGuid([Guid]);
      return this.uiMapper_GuestInformation(guestData);
    }
    public uiMapper_GuestInformation(GuestAPIModel: GuestAPI.Guest): GuestAPI.Guest {
      const guestItem: GuestAPI.Guest = {} as GuestAPI.Guest;
      Object.assign(guestItem, GuestAPIModel);
    
      return guestItem;
    }
         /**
    * Alert popup to show 'Warning' , 'Error' , 'Success'
    * @param {string} message  - Content
    * @param {AlertType} type  - Type of Popup
    * @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
    * @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional )
    * @returns - Dialog Reference of the modal with Result of enum AlertAction
    * @memberof Utilities
    */
    public showAlert(message: string, type: AlertType, btnType: ButtonType = ButtonType.Ok,
      callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string,
      additionalInfo?: { message: string, class: string }, moreOptionsLabel?: string, moreOptions?: any) {

      const dialogRef = this.dialog.open(AlertPopupComponent, {
        height: 'auto',
        width: '300px',
        data: { type, message, buttontype: btnType, header: headerText, additionalInfo, moreOptionsLabel, moreOptions },
        panelClass: 'small-popup',
        disableClose: true,
      });
      this.subscription = dialogRef.afterClosed().subscribe(res => {
        if (callback) {
          callback(res, extraParams);
       }
      });
      return dialogRef;
    }
  }



