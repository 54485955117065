import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { GridConfiguration, PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopModel, ErrorPopModel, ReceiveTempCheck } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilysysTempCheckComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-temp-check/agilysys-temp-check.component';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';

export const ReceivePost: PostPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    Result: PostPOSupplierConfig,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId?: any): any => {
        if (TransactionCollection.CreateTranscationConfig) {
            return new Promise<boolean>((resolve, reject) => {
                return checkTemperature(TransactionCollection, dialog).then( function(response) {
                    return allocateChargesCheck(TransactionCollection, dialog).then(function( secondresponse ) {
                        return zeroPriceCheck(TransactionCollection, dialog, homeGroup).then(function(finalresponse) {
                            resolve(finalresponse);
                        });
                    });
                }).catch((x) => { 
                    return x;
                });
            });
        }
};

function formTempCheckGridData(GridRowData: Array<any>) {
    const gridInput: GridConfiguration = {
        GridData: GridRowData,
        GridColumnHide: [],
        GridName: 'Item List',
        GridFilter: [],
        GridHeaderDefination: [
            {
                headerName: 'Item Name',
                field: 'Name',
                editable: false,
                suppressMovable: true,
                sortable: false,
                tooltipField: 'Name',
            },
            {
                headerName: 'Temperature Range',
                field: 'temperaturerange',
                editable: false,
                suppressMovable: true,
                sortable: false,
                tooltipField: 'temperaturerange',
                cellRenderer: 'temprenderer'
            },
            {
                headerName: 'Temperature',
                field: 'Temperature',
                editable: true,
                suppressMovable: true,
                sortable: false,
                cellEditor: 'numberCellEditor',
                tooltipField: 'temperature',
                type: 'numericColumn'
            },
            {
                headerName: 'Remarks',
                field: 'Remark',
                editable: true,
                suppressMovable: true,
                sortable: false,
                cellEditor: 'textEditor',
                tooltipField: 'remarks',
            }
        ],
        GridOdataKey: 'TempCheck',
        GridSearch: [],
        GridSort: [],
        GridTitleType: null,
        ShowActiveToggle: false,
        ShowAddNew: false,
        ClearSearchFilter: false,
        GridActions: {
            SearchConfig: {
                EnabledSearch: false,
                SearchPlaceholder: '',
                SearchType: '',
                SearchTypeConfig: null
            },
            ShuffleColumnConfig: {
                EnableColumnShuffle: false
            },
            ResizeRowsConfig: {
                EnableResizeRows: false
            }
        }
    };
    return gridInput;
}


function checkTemperature(TransactionCollection: TransactionConfiguration, dialog) {
    // First check for temperature
    return new Promise<boolean>((resolve, reject) => {
        const TempCheckData = [];
        const GridRowData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
        GridRowData.forEach(rowData => {
            if (rowData.MinTemperature && rowData.MaxTemperature && rowData.MinTemperature !== 0 && rowData.MaxTemperature !== 0) {
                rowData.Temperature = !rowData.Temperature ? 0 : rowData.Temperature;
                // with in range 
                let result = rowData.Temperature >= rowData.MinTemperature && rowData.Temperature <= rowData.MaxTemperature;
                // if remarks given eliminate item from check
                if (!result) {
                    result = rowData.Remark ? (rowData.Remark.trim().length > 0) : false;
                }
                if (!result) {
                    TempCheckData.push(rowData);
                }
            }
        });
        if (TempCheckData.length > 0) {
            reject(false);
            const GridInfo: GridConfiguration = formTempCheckGridData(TempCheckData);
            const TempCheck: ReceiveTempCheck = {
                PopupHeaderContent: 'Enter Temperature',
                GridInput: GridInfo
            };
            const dialogRef = dialog.open(AgilysysTempCheckComponent, {
                width: '70%',
                height: '70%',
                data: TempCheck,
                autoFocus: false,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(response => {
                if (response[0] === 'Confirm') {
                    const RowData = response[1].GridData;
                    RowData.forEach(element => {
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.
                        ItemGridConfig.GridData.find(x => x.RowId === element.RowId).Temperature = parseFloat(element.Temperature);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.
                        ItemGridConfig.GridData.find(x => x.RowId === element.RowId).Remark = element.Remark;
                        checkTemperature(TransactionCollection, dialog);
                    });
                }
            });
        } else {
            resolve(true);
        }
        return true;
    });
    
}

function allocateChargesCheck(TransactionCollection: TransactionConfiguration, dialog ) {
    return new Promise<boolean>((resolve, reject) => {
        const GridRowData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
        const ZeroQuantityList = GridRowData.filter(x => x.Qty === 0 || x.Qty === null || x.Qty === undefined || x.Qty === '0' || x.Qty === ''
        || x.Qty === '0.00');
        if (ZeroQuantityList.length > 0) {
            const ChargesAllocated = ZeroQuantityList.every(x => x.Charges > 0);
            if (ChargesAllocated) {
                const ConfirmPopUpDialog: ConfirmPopModel = {
                    PopupHeaderContent: 'Validations',
                    PopUpContent: 'Allocation charge assigned on zero quantity item',
                  };
                  const dialogRef = dialog.open(AgilysysConfirmationPopupComponent, {
                    width: '400px',
                    height: '222px',
                    data: ConfirmPopUpDialog,
                    disableClose: true
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result === 'Confirm') {  
                        resolve(true);      
                        console.log('Continue to post');       
                    } else {
                        reject(false);
                        console.log('Not continue to post');
                    }
                  });
            } else {
                resolve(true);
            }
        } else {
            resolve(true);
        }
        return false;
    });
    
}

function zeroPriceCheck(TransactionCollection: TransactionConfiguration, dialog: any, homeGroup: FormGroup) {
    return new Promise<boolean>((resolve, reject) => {
        const GridRowData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
        let isZeroPrice = false;
        let isNegativePrice = false;
        let PopUpContent = '';
        GridRowData.forEach(x => {
            if ((x.UnitCost ? parseFloat(x.UnitCost) : 0)  === 0) {
                isZeroPrice = true;
                PopUpContent = 'Transaction contains a zero cost. Confirm to proceed?';
            } else if ((x.UnitCost ? parseFloat(x.UnitCost) : 0) < 0) {
                isNegativePrice = true;
                PopUpContent = 'Unit cost cannot be less than zero, Please check and try again';
            }
        });
        if ((isZeroPrice && !homeGroup.value['AllowZeroCostItem']) || isNegativePrice) {
            const ConfirmPopUpDialog: ConfirmPopModel = {
                PopupHeaderContent: 'Validations',
                PopUpContent: PopUpContent,
              };
              const dialogRef = dialog.open(AgilsysErrorPopComponent, {
                width: '400px',
                data: ConfirmPopUpDialog,
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result === 'Ok') {
                    if (isZeroPrice) {
                        homeGroup.controls['AllowZeroCostItem'].setValue(true);
                        resolve(true);
                    }
                } else {
                    reject(false);
                }
              });
              return true;
        } else {
            resolve(true);
            return false;
        }
    });
    
}