import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SigCapRequestWithoutCallback, SigCapResponseWithSignature, SignatureCaptureRequest, SignatureCaptureResponse,DMReceiptPosting } from 'src/app/common/data-magine/data-magine-integration/data-magine-models';
import { RetailRoutes } from '../../retail-route';
import { RetailCommunication } from '../../retailcommunication.service';
import { TransactionSignature } from '../shop.modals';
import { SigcapLog,SigcapLogSearchRequest,UserInfo,CloseFailedDMPosting } from 'src/app/retail/shop/view-categories/signature-capture/signature-capture.model';
import { HttpMethod } from 'src/app/retail/shared/service/http-call.service';
import { HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import { Host } from 'src/app/retail/shared/globalsContant';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';

@Injectable({
  providedIn: 'root'
})
export class SignatureCaptureService {

  constructor(private httpclient: HttpClient,
    private retailCommunication: RetailCommunication,private http:HttpServiceCall,private utils: RetailUtilities) { }
  public async inititateSigCapDevice(
    transactionId: string, baseUrl: string, receiptString: string, retailTransactionId, ticketNumber)
    : Promise<SignatureCaptureResponse> {
      const indexTransactionId: string = retailTransactionId != null ? retailTransactionId.toString() : "NoTransactionId";
      const indexTicketNumber: string = ticketNumber != null ? ticketNumber.toString() : "NoTicketNumber";


    var signaturerequest: SigCapRequestWithoutCallback = {
      callBackWithPDF: "true",
      callBackWithSignature: "true",
      transactionId: transactionId,
      formSet: "RECEIPT",
      pageData: [receiptString],
      labels: [],
      indexes: [indexTransactionId, indexTicketNumber]
    };
    var res = await this.httpclient.post(baseUrl, signaturerequest).toPromise<any>();

    return res;
  }
  public async getSigCapResponseByTransactionId(
    transactionId: string, baseUrl: string)
    : Promise<SigCapResponseWithSignature> {
    let getURL = baseUrl +"/status/"+ transactionId;
    var res = await this.httpclient.get(getURL).toPromise<any>();
    return res;
  }
  public async addTransactionSignature(transactionId, signature){
    let requestBody : TransactionSignature = {signature: signature};
    return this.retailCommunication.putPromise<boolean>(
      { route: RetailRoutes.AddTransactionSignature, body: requestBody, uriParams:{TransactionId: transactionId} }, true);

  }
  public async PostReceiptToDM(receipt:DMReceiptPosting){
    
     this.http.CallApiAsync({
      callDesc: RetailRoutes.PostReceiptToDM,
      method: HttpMethod.Post,
      host : Host.retailPOS,
      body: receipt,
      uriParams:{TransactionId: receipt.TransactionId}
    })
  }
  public async GetFailedDMPosting(searchRequest:SigcapLogSearchRequest): Promise<SigcapLog[]>{
    // return this.retailCommunication.putPromise<SigcapLog[]>(
    //   {route: RetailRoutes.GetFailedDMPosting,body:searchRequest}
    // )

    const result = await this.http.CallApiAsync({
      callDesc:  RetailRoutes.GetFailedDMPosting,
      method: HttpMethod.Put,
      host : Host.retailPOS,
      body:searchRequest
       })

    const response: any = result && result.result ? result.result : [];
    return response.map((element) => {
        return {
          id: element.id,
          transactionId:element.transactionId,
          propertyDateTimeOfPosting:element.propertyDateTimeOfPosting,
          ticketNumber: element.ticketNumber,
          errorMessage: element.errorMessage,
          errorInfo: element.errorInfo,
          transactionType: element.transactionType,
          clerkId: element.clerkId
        };
    });
  }

  async CloseFailedDMPosting(request : CloseFailedDMPosting){
     await this.http.CallApiAsync({
      callDesc: RetailRoutes.CloseFailedDMPosting,
      method: HttpMethod.Put,
      host : Host.retailPOS,
      body: request
     
    });
  }
  async RetryDMPosting(request :SigcapLogSearchRequest){
    await this.http.CallApiAsync({
     callDesc: RetailRoutes.RetryPostReceiptToDM,
     method: HttpMethod.Post,
     host : Host.retailPOS,
     body: request
    
   });
 }
 async isBatchProcessingPending(){
  const result = await this.http.CallApiAsync({
    callDesc: RetailRoutes.CheckBatchProcess,
    method: HttpMethod.Put,
    host : Host.retailPOS,
   
  });
  return result;
 }

  async getClerkInfo(): Promise<UserInfo[]> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetUserInfoByPropertyId',
      method: HttpMethod.Get,
      host : Host.authentication,
      uriParams: { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
      productId: Number(this.utils.GetPropertyInfo('ProductId')) }
    });
    const response: any = result && result.result ? result.result : [];
    return response.map((element) => {
        return {
            id: element.userId,
            firstName: element.firstName,
            lastName: element.lastName,
            userName: element.userName
        };
    });
  }
}
