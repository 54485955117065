import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ICellEditorParams } from 'ag-grid-community';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { GridCalculationService } from 'src/app/eatecui/source/shared/services/grid-alogrithm/grid-calculation.service';

@Component({
  selector: 'app-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CurrencyDropdownComponent implements OnInit {

  params: any;
  value: any;
  defaultValue: number;
  dropdown: FormControl = new FormControl('');
  dropdownOptions: any;
  updateProperty: string;
  fieldName: string;
  previousCurrencyId: number;

  constructor(private httpService: HttpService,
    private transactionService: TransactionService,
    private gridCalculationService: GridCalculationService) { }

  ngOnInit(): void {
    console.log('NgOnInit');
  }
  agInit(params) {
    // console.log('Dropdown Editor', params);
    console.log('AgInit');
    this.defaultValue = params.data[params.UpdatePropertyId];
    this.updateProperty = params.UpdatePropertyId;
    this.fieldName = params.colDef.field;
    const dropdownField = params.DropdownListName;
    this.params = params;
    this.dropdown.setValue(this.defaultValue);
    this.dropdownOptions = this.params.data[dropdownField];
  }

  init(params) {
    this.dropdown.valueChanges.subscribe(res => {
      this.defaultValue = parseInt(res, 10);
      const rowNode = this.params.api.getRowNode(this.params.rowIndex);
      rowNode.setDataValue(this.updateProperty, this.defaultValue);
    });
  }

  dropdownChangeEvent(event) {
    this.previousCurrencyId = this.params.data.ForeignCurrencyId;
    // console.log('Dropdown Change Event', event);
    this.params.context.parentComponent.selectedColumn = this.params.colDef.field;
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    this.defaultValue = parseInt(event.target.value, 10);
    this.params.data[this.updateProperty] = this.defaultValue;
    if (this.dropdownOptions.filter(x => x.Id === this.defaultValue).length > 0) {
      this.params.data[this.fieldName] = this.dropdownOptions.filter(x => x.Id === this.defaultValue)[0].Name;
    }
    rowNode.setDataValue(this.updateProperty, this.defaultValue);
    rowNode.setDataValue(this.fieldName, this.params.data[this.fieldName]);
    rowNode.setDataValue('ExchangeRate', 82.40);
    const SetDynamicValueFields = ['ExchangeRate', 'UnitCostInForigenCurrency', 'ValueInForeignCurrency'];
    SetDynamicValueFields.forEach(fields => {
      const Fielddata: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: [fields],
      };
      const Fieldinstances = this.params.api.getCellEditorInstances(Fielddata);
      if (Fieldinstances.length > 0) {
        // Fieldinstances[0].params.data.TaxAmount2 = para;
        Fieldinstances[0].value = this.params.data[fields];
        Fieldinstances[0].params.value = this.params.data[fields];
        Fieldinstances[0].input.value = this.params.data[fields];
      }
    });
    this.previousCurrencyId = !this.previousCurrencyId ? this.transactionService?.transactionFormGroup?.value?.TransactionCurrencyId : this.previousCurrencyId;
    if (this.previousCurrencyId && this.params.data.ForeignCurrencyId) {
      this.httpService.GethttpMethod(`/inventory/api/GetExchangeRate/${this.previousCurrencyId}/${this.params.data.ForeignCurrencyId}`).subscribe(Res => {
        console.log('Currency Rate', Res);
        rowNode.setDataValue('ExchangeRate', 82.40);
        const Fielddata: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['ExchangeRate'],
        };
        const Fieldinstances = this.params.api.getCellEditorInstances(Fielddata);
        if (Fieldinstances.length > 0) {
          // Fieldinstances[0].params.data.TaxAmount2 = para;
          Fieldinstances[0].value = this.params.data['ExchangeRate'];
          Fieldinstances[0].params.value = this.params.data['ExchangeRate'];
          Fieldinstances[0].input.value = this.params.data['ExchangeRate'];
        }
        this.gridCalculationService.calculateForeignCurrency(this.params);
      });
    }
  }

  getValue(): any {
    if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
    }
    if (this.dropdownOptions && this.dropdownOptions.length > 0 &&
      this.dropdownOptions.filter(x => x.Id === this.defaultValue).length > 0) {
      const returnValue = this.dropdownOptions.filter(x => x.Id === this.defaultValue)[0].Name;
      return returnValue;
    }
  }

  focusIn() {
    const selectEditor = document.getElementById('currencyEditor');
    if (selectEditor) {
      selectEditor.focus();
    }
  }
  
  afterGuiAttached() {
    try {
      const currencyEditor = document.getElementById('currencyEditor');
      if (currencyEditor && (this.params.context.parentComponent.selectedColumn === this.params.colDef.field)) {
            currencyEditor.focus();
      }
    } catch (error) {
      console.error('Error occurred in number cell editor component of afterGuiAttached function', error);
    }
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  keyDown(event, params: ICellEditorParams) {
    // console.log('key down', event);
    if (event.key === KEY_ESCAPE) {
      this.params.api.stopEditing();
    }
  }

}

const KEY_ESCAPE = 'Escape';