import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import {TemplatesModule} from '../common/templates/templates.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TemplatesModule
  ],
  declarations: [LayoutComponent,HeaderComponent,SpinnerComponent],
  exports:[LayoutComponent],
  providers:[]
})
export class LayoutsModule { }
