import { Component } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  notifications:any[]=[{
    "name": "John Lisa",
    "status":"Checked In",
    "time":"1 hour"
  },
  {
    "name": "John Wick",
    "status":"Checked In",
    "time":"1 min"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"2 hours"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"1 hour"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"3 hour"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"1 hour"
  },
  {
    "name": "John Wick",
    "status":"Checked In",
    "time":"1 min"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"2 hours"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"1 hour"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"3 hour"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"1 hour"
  },
  {
    "name": "John Wick",
    "status":"Checked In",
    "time":"1 min"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"2 hours"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"1 hour"
  },
  {
    "name": "John Lisa",
    "status":"Checked In",
    "time":"3 hour"
  }
];
  
}
