import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableActions } from '../../enums/shared-enums';
import { Localization } from '../../localization/localization';
import { ButtonValue, TableHeaderOptions, TableOptions, TableSearchHeader } from '../../Models/ag-models';
import { ActionTypeEnum, FromTypeEnum, HdrActionTypeEnum, SorTypeEnum } from '../cdkvirtual/cdkvirtual.model';
import { CgpsSyncComponent } from './cgps-sync/cgps-sync.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-cgps-logging-profile-sync',
  templateUrl: './cgps-logging-profile-sync.component.html',
  styleUrls: ['./cgps-logging-profile-sync.component.scss']
})
export class CgpsLoggingProfileSyncComponent  {
  searchHeaderOption: TableSearchHeader;
  captions: any;
  IsEditModeEnabledFlag:boolean=false;
  isViewOnly:boolean=false;
  headerOptions:TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent: any;
  originalData: any;
  searchText:string="";
  isPopOverOpen=false;
  filterForm: UntypedFormGroup;
  applyButton: ButtonValue;
  statusFilterList: { id: number, value: string, checked: boolean }[];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() onSave = new EventEmitter();
  @Output() onTableEmit = new EventEmitter();
  @Output() OnEditEmit = new EventEmitter();
  @Output() onRefreshGrid = new EventEmitter();

  @Input('searchHeaderOption')
  set searchHeaderOptionfunc(val){
    this.searchHeaderOption=val;  
    }

    @Input('tableOptions')
    set tableOptionsfunc(val){
      this.tableOptions=val;  
    }
      
      @Input('headerOptions')
      set headerOptionsfunc(val){
        this.headerOptions=val;  
     }    
     @Input('tableContent')
     set tableContentfunc(val){
       this.tableContent=val;  
       this.originalData = val;
    }
  constructor(private localization:Localization, public dialog: MatDialog,private fb: UntypedFormBuilder, private utilities: CommonUtilities ) { 
    this.captions=this.localization.captions;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  searchChange(e)
  {
   this.searchText=e;
  }

  showInactiveToggler(e)
  {

  }

  refreshGridData(e)
  {
    this.utilities.ToggleLoader(true);
    this.onRefreshGrid.emit(e);
    this.utilities.ToggleLoader(false);
  }

  createEvent(e)
  {
    const dialogRef = this.dialog.open(CgpsSyncComponent, {
      height: '300px',
      width: '600px',
      data: {},
      panelClass: 'small-popup',
      disableClose: true,
  }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
    this.onSave.emit(res)
  });
  }

  tableAction(e)
  {
    switch (e.fromType) {
      case FromTypeEnum.sync:
        this.onTableEmit.emit(e);
        break;
    }
  }

  onSyncClick(event)
  {
    this.OnEditEmit.emit(event);
  }

  buildForm() {
    this.filterForm = this.fb.group({
      status: ''
    });
    this.applyButton = {
      type: 'primary',
      label: this.captions.btn_apply,
      disabledproperty: false
    }
    this.statusFilterList = [{
      id: 1,
      value: this.captions.lbl_complete,
      checked: false
    },
    {
      id: 2,
      value: this.captions.lbl_incomplete,
      checked: false
    }]
  }

  filterTableData(e, item) {
    this.statusFilterList.forEach((x, i) => {
      if (x.id === item.id) {
        x.checked = e.checked;
      }
    });
    this.filterForm.controls.status.setValue(this.statusFilterList.filter(x => x.checked));
  }

  apply() {
    if (this.filterForm.value.status.length == 0) {
      this.tableContent = [...this.originalData];
    } else {
      this.tableContent = this.filterForm.value.status.length > 0 && this.filterForm.value.status.map(x => x.id !== 4)[0] ? this.originalData.filter(x => this.filterForm.value.status.map(y => y.id).includes(x.statusId)) : this.originalData;
    }
  }
}
