<section class="resize-rows">
  <div class="resize-list">
    <div class="row-list" *ngFor="let data of resizeRowsList" (click)="selectedSize(data)">
        <span class="{{data.IconName}} icon-class" [ngClass]="(SelectedSize && SelectedSize.Id === data.Id) ? 'selected-icon' : 
        ''"></span>
        <span class="display-text">{{data.DisplayName}}</span>
    </div>
  </div>
    <div class="footer">
        <button mat-button class="mr-4"
          (click)="cancel()">Set To Default</button>
        <button mat-button class="apply-button mr-4" 
          (click)="apply()">Apply</button>
      </div>
</section>