import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { fetchHttp } from 'src/app/eatecui/source/shared/models/fetch-data.interface';

export const CustomInventoryTypeChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
    ): any => {
        try {
            const MasterJson: any = fetchHttp('app/eatecui/assets/json/master/retail-inventory.json');
            const RetailJson: MasterInventoryManagement = MasterJson.filter(x => x.Key === 'Ingredients')[0];
            const ReplaceMasterObject = {} as MasterInventoryManagement;
            
                ReplaceMasterObject.Key = RetailJson.Key;
                ReplaceMasterObject.Name = RetailJson.Name;
                ReplaceMasterObject.NestedFormConfig = RetailJson.NestedFormConfig;
                ReplaceMasterObject.SelectedTypeId = RetailJson.SelectedTypeId;
                ReplaceMasterObject.packageTypeId = RetailJson.packageTypeId;
                ReplaceMasterObject.DetailsConfig = RetailJson.DetailsConfig;
                ReplaceMasterObject.FormConfig = RetailJson.FormConfig;
                ReplaceMasterObject.GridConfig = RetailJson.GridConfig;
                ReplaceMasterObject.MasterRestDataCollection = RetailJson.MasterRestDataCollection;
                ReplaceMasterObject.OnCellClick = MasterDataCollection.OnCellClick;
                ReplaceMasterObject.MasterBreadCrumbConfig = RetailJson.MasterBreadCrumbConfig;
                ReplaceMasterObject.MasterKeys = RetailJson.MasterKeys;
                const Returndata = [ReplaceMasterObject];
                MasterDataCollection.OnCellClick(Returndata);
            // else {
            //     MasterDataCollection.Key = RetailJson.Key;
            //     MasterDataCollection.Name = RetailJson.Name;
            //     MasterDataCollection.NestedFormConfig = RetailJson.NestedFormConfig;
            //     MasterDataCollection.SelectedTypeId = 2;
            //     MasterDataCollection.packageTypeId = RetailJson.packageTypeId;
            //     MasterDataCollection.DetailsConfig = RetailJson.DetailsConfig;
            //     MasterDataCollection.FormConfig = RetailJson.FormConfig;
            //     MasterDataCollection.GridConfig = RetailJson.GridConfig;
            // }
        } catch (error) {
            console.log('error occured CustomDefaultSelect', error);
        }
    };

  