import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { GridCustomColExpand } from 'src/app/eatecui/source/shared/models/app-grid.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { DataDetail, GridTableCard, MasterInventoryManagement } from '../master.interface';
import { MasterDetailConfiguration } from './master-detail-config.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { MasterName } from '../../enum/master-enum';
import { UicontrolInterface } from 'src/app/eatecui/source/shared/models/ui-controls';
import { DetailsPayload, FileViewData, ThumbViewModel } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { DetailInterface } from '.';
import EnUs from 'src/app/eatecui/assets/i18n/en.json';

export const MasterDetail: MasterDetailConfiguration =
    (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
        httpService: HttpService, DetailsObject: GridTableCard,
        ResponseData: any, translateService: TranslateService, domSanitizer: any, dynamicToolTipConfig: any): any => {
        try {
            if (ViewModelEntity.length > 0 && MasterDataCollection) {
                const entityName = ViewModelEntity[0]['$']['Name'].toString();
                const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                // const Url = ViewModelEntity[0]['$']['Name'];
                // let seleId = `id eq ${DetailsObject.Id}`;
                // if (MasterDataCollection.GridConfig.GridCustomExpand && MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                //     let queryString = '';
                //     let queryString1 = '';
                //     if (MasterDataCollection.GridConfig.GridCustomExpand[0] &&
                //         !MasterDataCollection.GridConfig.GridCustomExpand[0].IsCustomExpand) {
                //         queryString = MasterDataCollection.GridConfig.GridCustomExpand[0].SelectQuery + ' eq ' + DetailsObject.Id;
                //     }
                //     if (MasterDataCollection.GridConfig.GridCustomExpand[1] &&
                //         !MasterDataCollection.GridConfig.GridCustomExpand[1].IsCustomExpand) {
                // eslint-disable-next-line , max-len
                //         queryString1 = MasterDataCollection.GridConfig.GridCustomExpand[1].SelectQuery +
                // ' eq ' + DetailsObject.SecondaryId;
                //     }
                //     if (queryString1.length > 0 && queryString.length > 0) {
                //         seleId = queryString + ' and ' + queryString1;
                //     } else if (queryString1.length > 0) {
                //         seleId = queryString1;
                //     } else if (queryString.length > 0) {
                //         seleId = queryString;
                //     }
                // }
                // const navigationProperity = NavigationProperty(ViewModelEntity, MasterDataCollection);
                // const params = {} as QueryParams;
                // params.expand = navigationProperity.join();
                // params.filterQuery = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                //     return '%' + c.charCodeAt(0).toString(16);
                // });
                // const getQueryParams = (Url === 'Ingredients' || Url === 'Products' || Url === 'Suppliers' || Url === 'Recipes')
                //     ? `/${DetailsObject.Id}` : formQueryParams(params);
                // eslint-disable-next-line max-len
                // const GetDataByIdUrl = `/inventory/api/${Url + getQueryParams}`;
                // httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                    // const convertValue = (typeof x['value'] === 'string') ? [JSON.parse(x['value'])] : x['value'];
                    // x['value'] = convertValue;
                    const x = ResponseData;
                    if (x && x['value'].length > 0) {
                        if (MasterDataCollection.DetailsConfig) {
                            MasterDataCollection.DetailsConfig.DetailsData = [];
                            const response = x['value'][0];
                            MasterDataCollection.DetailsConfig.DetailPatchValueData = response;
                            MasterDataCollection.DetailsConfig.Id = response.Id;
                            MasterDataCollection.DetailsConfig.DetailsObservation = response.Name;
                            MasterDataCollection.DetailsConfig.DetailActions.IsActive = response.IsActive;
                            if (MasterDataCollection.DetailsConfig.DetailActions) {
                                if (!MasterDataCollection.DetailsConfig.DetailActions.NoActiveandDeactive) {
                                    if (MasterDataCollection.DetailsConfig.DetailActions.hasOwnProperty('Deactivate')) {
                                        MasterDataCollection.DetailsConfig.DetailActions.Deactivate = response.hasOwnProperty('IsActive')
                                            && response.IsActive ? true : false;
                                    }
                                    if (MasterDataCollection.DetailsConfig.DetailActions.hasOwnProperty('Activate')) {
                                        MasterDataCollection.DetailsConfig.DetailActions.Activate = response.hasOwnProperty('IsActive')
                                            && !response.IsActive ? true : false;
                                    }
                                    if ( MasterDataCollection.DetailsConfig.DetailActions.Activate) {
                                        MasterDataCollection.DetailsConfig.DetailActions.Edit = false;
                                        MasterDataCollection.DetailsConfig.DetailActions.Copy = false;
                                        MasterDataCollection.DetailsConfig.DetailActions.Print = false;
                                    } else if (!MasterDataCollection.DetailsConfig.DetailActions.Activate) {
                                        MasterDataCollection.DetailsConfig.DetailActions.Edit = true;
                                        MasterDataCollection.DetailsConfig.DetailActions.Copy = true;
                                        MasterDataCollection.DetailsConfig.DetailActions.Print = true;
                                    }

                                } else {
                                    MasterDataCollection.DetailsConfig.DetailActions.Activate = false;
                                    MasterDataCollection.DetailsConfig.DetailActions.Deactivate = false;
                                    if (response.hasOwnProperty('IsActive') && !response.IsActive) {
                                        MasterDataCollection.DetailsConfig.DetailActions.Edit = false;
                                        MasterDataCollection.DetailsConfig.DetailActions.Copy = false;
                                        MasterDataCollection.DetailsConfig.DetailActions.Print = false;
                                    } else if (response.hasOwnProperty('IsActive') && response.IsActive) {
                                        MasterDataCollection.DetailsConfig.DetailActions.Edit = true;
                                        MasterDataCollection.DetailsConfig.DetailActions.Copy = true;
                                        MasterDataCollection.DetailsConfig.DetailActions.Print = true;
                                    }
                                }
                                if (MasterDataCollection.DetailsConfig.DetailActions.hasOwnProperty('NoActions') &&
                                    MasterDataCollection.DetailsConfig.DetailActions.NoActions) {
                                    MasterDataCollection.DetailsConfig.DetailActions.Edit = false;
                                    MasterDataCollection.DetailsConfig.DetailActions.Copy = false;
                                    MasterDataCollection.DetailsConfig.DetailActions.Print = false;
                                }

                            }
                            // if (MasterDataCollection.DetailsConfig.hasOwnProperty('DetailPictures')) {
                            //     const keyName = MasterDataCollection.DetailsConfig.DetailPictures.PictureKey;
                            //     const responseDetailPicture = detailPicture(response[keyName], domSanitizer);
                            //     MasterDataCollection.DetailsConfig.DetailPictures.PictureHeaderName =
                            //         MasterDataCollection.DetailsConfig.DetailsObservation;
                            //     MasterDataCollection.DetailsConfig.DetailPictures.PictureDetail = [...responseDetailPicture];
                            // }
                            MasterDataCollection.DetailsConfig
                                    .AlreadyDefinedMaster = response['IsProductDefined'];
                            MasterDataCollection.DetailsConfig
                                    .FinishedGoodMaster = response['IsProductionRecipe'];
                            MasterDataCollection.DetailsConfig
                                    .IsButchery = response['IsButcheryRecipe'];
                            MasterDataCollection.DetailsConfig.DetailsHeader.forEach((name, index) => {
                                const data = {} as DataDetail;
                                data.Id = index + 1;
                                data.Name = name;
                                if(translateService){
                                    let dataName = translateService.instant(entityName + '.' + data.Name);
                                    if(dataName.indexOf('.') > -1){
                                        data.Name = EnUs[entityName][data.Name];
                                    } else{
                                        data.Name = dataName;
                                    }
                                }
                                // eslint-disable-next-line max-len
                                const text = ViewModelEntity[0]['$']['Name'] + '.' + name;
                                translateService.get(text).subscribe(res => {
                                    data.HeaderName = res;
                                });
                                data.HeaderName = ProductDataRemap.FieldDisplayNameEngine(
                                productremapJson, entityName, data.HeaderName, entityName + `Detail-${data.Name}`) ;
                                let value: any = '';
                                const propType = typeof response[name];
                                if (propType === 'object' && response[name]) {
                                    value = response[name]['Name'];
                                } else {
                                    value = response[name] === '' || response[name] === undefined
                                        || response[name] === null ? '-' : response[name];
                                }
                                if (name === 'ProductDietaries' && Array.isArray(response[name])) {
                                    value = [];
                                    response[name]?.forEach(dietary => {
                                        if (dietary?.ProductDietarieDietaryPreference?.Name) {
                                            value?.push(dietary?.ProductDietarieDietaryPreference?.Name);
                                        }
                                    });
                                    value = value?.length ? value : '-';
                                }
                                // else if (name === 'Supplier') {
                                //     const apiUrl = '/inventory/api/Suppliers';
                                // eslint-disable-next-line max-len
                                //     const Id = (response && response.hasOwnProperty('IngredientsOtherDetails') && response['IngredientsOtherDetails'] !== null && response['IngredientsOtherDetails'].hasOwnProperty('PrimarySupplierId') && response['IngredientsOtherDetails']['PrimarySupplierId']) ? response['IngredientsOtherDetails']['PrimarySupplierId'] : 1;
                                //     if (Id) {
                                //         customDetailValue(apiUrl, Id, httpService, data);
                                //     }
                                // }
                                data.Value = value;
                                const selectedInventoryType = dynamicToolTipConfig?.selectedInventoryType;
                                const selectedInventoryKey = data.Name + (selectedInventoryType && 
                                    !isNaN(selectedInventoryType) ? selectedInventoryType : '');
                                if (dynamicToolTipConfig && dynamicToolTipConfig.dynamicToolTipData
                                        && dynamicToolTipConfig.dynamicToolTipData[selectedInventoryKey]) {
                                    data.CustomToolTip = dynamicToolTipConfig.dynamicToolTipData[selectedInventoryKey];
                                    if (translateService) {
                                        data.CustomToolTip.ToolTipText = translateService
                                            .instant(entityName + '.' + data.CustomToolTip.ToolTipKey);
                                        }
                                }
                                data.Visible = ProductDataRemap.FieldVisibleRemapEngine(productremapJson, entityName, entityName + `Detail-${name}`);
                                MasterDataCollection.DetailsConfig.DetailsData.push(data);
                            });
                            const MasterCollection: Array<MasterInventoryManagement> = [];
                            MasterCollection.push(MasterDataCollection);
                            UicontrolInterface.MasterManagementAuthorizationAuth(MasterCollection, null, null);
                        }
                    }
                // // });
                // if (MasterDataCollection.DetailsConfig.hasOwnProperty('DetailPictures') &&
                //     MasterDataCollection.DetailsConfig.DetailPictures.hasOwnProperty('PictureUrl') &&
                //     (MasterDataCollection.Name === 'Inventory' || MasterDataCollection.Key === 'Products' 
                // || MasterDataCollection.Key === 'Recipes')) {
                //     MasterDataCollection.DetailsConfig.DetailPictures.PictureDetail = [];
                //     let InventoryGroupId: any;
                //     switch (MasterDataCollection.Key) {
                //         case MasterName.MasterIngredients: InventoryGroupId = 1; break;
                //         case MasterName.MasterProducts: InventoryGroupId = 3; break;
                //         case MasterName.MasterReceipe: InventoryGroupId = 2; break;
                //     }
                //     const ResponseView =
                //     DetailInterface.MasterDetailPicture(MasterDataCollection, 
                // DetailsObject, InventoryGroupId, httpService, domSanitizer);
                //     return ResponseView;
                //   } else {
                    return MasterDataCollection.DetailsConfig;
                }

        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

function customDetailValue(CustomUrl: string, CustomId: any, httpService: HttpService, DetailDataView: DataDetail) {
    // eslint-disable-next-line radix
    const id = parseInt(CustomId);
    const seleId = `id eq ${id}`;
    const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
    const params: QueryParams = {
        count: true,
        top: InfiniteScrollItemCount,
        skip: 0,
        expand: '',
        select: '',
        filter: '',
        orderby: '',
        isactive: false,
        search: '',
        filterQuery: GetDataFilter,
        searchtext: ''
    };
    const getQueryParams = formQueryParams(params);
    const GetDataByIdUrl = `${CustomUrl + getQueryParams}`;
    httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
        if (x['value'].length > 0) {
            DetailDataView.Value = x['value'][0]['Name'];
        }
    });
}

function NavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                    const navigationName = element['$'].Name;
                    const referentialRelationId = element['ReferentialConstraint'] && element['ReferentialConstraint'][0]['$'].Property
                        ? element['ReferentialConstraint'][0]['$'].Property : '';
                    if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                        const properityIndex = ViewModelEntity[0]['Property']
                            .findIndex(x => x['$'].Name === referentialRelationId);
                        if (properityIndex > -1) {
                            let expandQuery = '($select=id,name)';
                            if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey: GridCustomColExpand) => {
                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand && GridCustomExpandKey.IsCustomExpand
                                        && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                        expandQuery = `($select=id,${expandCode})`;
                                    }
                                });
                            }
                            const properityString = `${navigationName}` + `${expandQuery}`;
                            navigationPrperity.push(properityString);
                        } else {
                            if (navigationName !== 'ProductPictures') {
                                const properityString = `${navigationName}`;
                                navigationPrperity.push(properityString);
                            }
                        }
                    }
                });
                return navigationPrperity;
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function detailPicture(pictureArray: Array<any>, domSanitizer: any) {
    const detailPictures = [];
    try {
        if (pictureArray && pictureArray.length > 0) {
            pictureArray.forEach(pictures => {
                const pictureObject = {};
                pictureObject['Id'] = ( pictures.hasOwnProperty('FileDetails')) ?
                 pictures.FileDetails.Id : pictures['Id'];
                pictureObject['Name'] = pictures['Name'];
                pictureObject['Url'] = typeof(pictures['Picture']) === 'string' ?
                 domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${pictures['Picture']}`)
                  : pictures['Picture'];
                pictureObject['IsActive'] = pictures['IsActive'];
                pictureObject['InventoryId'] = ( pictures.hasOwnProperty('FileDetails')) ?
                pictures.FileDetails.InventoryId : pictures['InventoryId'];
                pictureObject['InventoryGroupId'] = ( pictures.hasOwnProperty('FileDetails')) ?
                pictures.FileDetails.InventoryGroupId : pictures['InventoryGroupId'];
                pictureObject['IsFavorite'] = ( pictures.hasOwnProperty('FileDetails')) ?
                pictures.FileDetails.IsFavorite : pictures['IsFavorite'];
                pictureObject['SortOrder'] = ( pictures.hasOwnProperty('FileDetails')) ?
                pictures.FileDetails.SortOrder : pictures['SortOrder'];
                pictureObject['FileDetails'] = pictures['FileDetails'];
                pictureObject['Picture'] = pictures['Picture'];
                pictureObject['FilePath'] = pictures['FilePath'];
                detailPictures.push(pictureObject);
            });
        }
        return detailPictures;
    } catch (error) {
        console.error(error);
    }
}
