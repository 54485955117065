import { Injectable } from "@angular/core";
import { CommonApiRoutes } from "../common-route";
import { PMSManagerCommunication } from "../communication/services/pms-manager.services";
import { PrintCardResponse, sendEmailRequest } from '../components/preview-report/preview-report.model';

@Injectable({
    providedIn: 'root'
})
export class ReservationDetailDataService {


    constructor(private pmscommunication: PMSManagerCommunication) {

    }

    public async SendEmailWithAttachment(requestDetails: sendEmailRequest): Promise<any> {
        const apiModels: PrintCardResponse = await this.pmscommunication.postPromise<any>({
          route: CommonApiRoutes.SendEmailWithAttachment,
          body: requestDetails
        }, false);
        return apiModels;
    }
}