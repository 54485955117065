export enum CacheType {
  CLEARALL = 'clearAll',
  CLEARINDIVUAL = 'clearIndivual',
  CACHEDNO = 'cachedNo'
}

export enum CornTypes {
  MONTHCRON = 'Month',
  DAYCRON = 'Day',
  HOURSCRON = 'Hours',
  MINUTESCRON = 'Minutes',
  SECONDSCRON = 'Seconds'
}
export enum CornKeys {
  EVERYMONTH = 'everymonth',
  SPECIFYMONTH = 'specifymonth',
  EVERYDAY = 'everyday',
  SPECIFYDAY = 'specifyday',
  SPECIFYDATE = 'specifydate',
  BEFOREENDMONTH = 'beforeendmonth',
  MONTHBETWEEN = 'monthbetween',
  SECONDSCRON = 'Seconds',
  EVERYHOUR = 'everyhour',
  SPECIFYHOUR = 'specifyhour',
  EVERYMINUTE = 'everyminute',
  SPECIFYMINUTE = 'specifyminute',
  EVERYMINUTESTART = 'everyminutetart',
  EVERYHOURTART = 'everyhourstart',
  EVERYSECOND = 'everysecond',
  SPECIFYSECOND = 'specifysecond'
}

export enum CornDefaultValues {
  MONTH = '*',
  SPECIFYMONTH = 'specifymonth',
  HOURSCRON = 'Hours',
  MINUTESCRON = 'Minutes',
  SECONDSCRON = 'Seconds'
}

export enum AuthendicatedType {
  SIGGLETURNUSER = 2,
  MULTITURNUSER = 1
}

export enum ConversionType {
  TOFIXED = 'toFixed',
  ROUND = 'round'
}
export enum ConversionValue {
  ConversionOne = 1,
  ConversionTwo = 2,
  ConversionThree = 3,
  ConversionSix = 6,
  ConversionFour = 4
}
export enum DecimalRoundOff {
  SIX = 3,
}

export enum TransactionType {
  PRODUCTION = 'Production',
  APVOUCHER = 'apvoucherrni'
}

export enum ItemListType {
  REQUISITION = 'ReqDetails',
  PHYSICALINVENTORY = 'PhysicalDetails',
  PURCHASEREQUEST = 'PurchaseReqDetail',
  PURCHASEORDER = 'PurchaseOrderDetails',
  RECEIVING = 'ReceivingDetails',
  PACKAGES = 'PackLists',
  SALESDETAILS = 'SalesDetails',
  PLANDETAILS = 'PlanDetails'
}

export enum TransTypeId {
  Template = 24,
  Requisition = 4
}


export enum MasterDivisionType {
  InventoryDivision = 1,
  RecipeDivision = 2
}

export enum UnitTypes {
  BaseUnit = 1,
  PurchaseUnit = 2,
  TransactionUnit = 3,
  RecipeUnit = 4,
  PortionUnit = 6,
  BatchSizeUnit = 5,
  SellingUnit = 7
}
export enum UnitCollecionName {
  RecipeUnits = 'RecipeUnits',
  Units = 'Units'
}

export enum PeriodLength {
  Meal = 4,
  Day = 1,
  Week = 2,
  Month = 3
}

export enum ApprovalStatusEnum {
  Approved = 4,
  Pending = 1,
  Denied = 2,
  Hold = 3
}



export enum UnitTypeName {
  BaseUnit = 'Base Unit',
  PurchaseUnit = 'Purchase Unit',
  TransactionUnit = 'Transaction Unit',
  RecipeUnit = 'Recipe Unit'
}

export enum ActionIconTypes {
  Deactivate = 'deactivate',
  Activate = 'activate'
}

export enum GetAllMethodType {
  OdataCore = 'OdataCore',
  NonOdataCore = 'NonOdataCore'
}

export enum ModuleNames {
  PlanHead = 'PlanHead',
  CyclePlanning = 'cycleplanning',
  ProductionPlan = 'productionplan',
  Butchery = 'Butchery' ,
  DataImportSummary = 'DataImportSummary',
  DataImportUpload = 'DataImportUpload',
  DataImportWexFlowsModule = 'DataImportWexFlowsModule',
  ValidationSummaryDownload = 'ValidationSummaryDownload',
  LoadSalesHistory = 'LoadSalesHistory',
  AllMessages = 'AllMessages',
  SendMessage = 'sendMessage',
  ScheduleReport = 'ScheduleReport',
  EditScheduleReport = 'EditScheduleReport',
  SaveScheduleReport = 'SaveScheduleReport',
  VendorPunchout = 'Vendor Punchout',
  Template = 'Template',
  CreateEvent = 'create-event',
  QuickOrderHead = 'quickorder',
  GetAllEvents = 'GetAllEvents',
  AccountActivity = 'Account Activity',
  EditEvent = 'edit-event',
  Production = 'Production',
  SalesHead = 'sales',
  MRP = 'MRP',
  PackageLimitation = 'PackageLimitation',
  CreatePackageLimitation = 'Create-PackageLimitation',
  SetupGridList = 'SetupGridList',
  CreateSetup = 'CreateSetup',
  Receiving = 'Receiving',
  PurchaseOrder = 'Purchaseorder'
}

export enum PrintLabelModuleName {
  RECEIVING = "RECEIVING",
  PRODUCTION = "PRODUCTION",
  PRODUCTIONBATCHPRINT = "PRODUCTIONBATCHPRINT",
  PURCHASEORDER = "PURCHASEORDER",
  INGREDIENT = "INGREDIENT"
}

export enum RguestRoutekey {
  Inventory = 'EatecInventory',
  Transfer = 'EatecTransfer',
  PhysicalInventory = 'EatecPhysicalInventory',
  Receiving = 'EatecReceiving',
  PurchaseOrder = 'EatecPurchaseOrder'
}

export enum ModuleKey {
  RECEIVING = 'Receiving',
  PURCHASEREQUEST = 'Purchase Request',
  TRANSFERSANDTRANSFEROUT = 'Transfer',
  REQUISITIONS = 'Requisition',
  SPOILAGEANDLOSS = 'spoilage',
  PRODUCTION = 'Production',
  SALES = 'sales',
  PLANHEAD = 'PlanHead'
}

export enum IntegrationURLs {
  RETRIEVEALL = 'RetrieveAllAPIURL',
  POSTURL = 'CreateAPIURL',
  DELETEURL = 'DeleteAPIURL',
  RETRIEVEURL = 'RetrieveAPIURL'
}

export enum HttpType {
   GETMETHOD = 'GetMethod',
   POSTMETHOD = 'PostMethod',
   POSTMETHODBYID = 'PostMethodById'
}

export enum SearchCategoryType {
  Packages = 'Packages',
  Class = 'Class',
  Type = 'Type',
  Inventory = "Inventory",
  Product = "Product"
}

export enum SearchCategoryId {
  Classes = 2,
  Types = 3,
  Packages = 4
}

export enum InventoryGroupCategoryId {
  Ingredients = 1,
  Recipe = 2,
  Product = 3,
  Supplier = 4,
  Customer = 5
}

export enum InventoryGroupName {
  Inventory = 'Inventory',
  Recipe = 'Recipe',
  Product = 'Product',
  Supplier = 'Supplier',
  Customer = 'Customer',
  Recipes = 'Recipes'
}

export enum InventoryGroupId {
  Ingredients = 1,
  Recipe = 2,
  Product = 3,
  Supplier = 4,
  Customer = 5
}

export enum EFormType {
  SingleField = "SingleField",
  MultipleField = "MultipleField",
  GroupingFieldMultiple = "GroupingFieldMultiple",
  GroupingFieldSingle = "GroupingFieldSingle",
  CustomRow = "CustomRow"
}

export enum EFormFieldType {
  DropDown = "dropdown",
  Text = "text",
  TextArea = "textarea",
}

export enum printerType {
    RP = 'RP',
    NW = 'NW'
}
export enum ECalendarMode {
    MONTH = "Month",
    WORKWEEK = "WorkWeek",
    WEEK = "Week",
    DAY = "Day"
}

export enum LoaderText {
  LoaderSpan = '<span class="ag-overlay-loading-center" style="max-width: 25%;min-width: 20%;box-shadow:none;font-size:12px;color:#6c757d"><img style="width:20%" src="app/eatecui/assets/images/eatec_loader.gif"><div style="position:relative;top:-6px">Please be patient</div><div>We\'re loading the data...</div></span>'
}