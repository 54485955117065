import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class HeaderOverlayRef {

  constructor(private overlayRef: OverlayRef) { }

  close(): void {
    console.log('dispose');
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }
}
