import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { Injectable } from '@angular/core';
import { RetailUtilities } from '../../utilities/retail-utilities';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { TenantConfigurations } from 'src/app/retail/letter-setup/letter.model';
import { PropertyConfig, TenantConfig } from 'src/app/retail/retail.modals';
import { RetailRoutes } from 'src/app/retail/retail-route';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class TenantConfigurationDataService {
    constructor(private _tenantManagement: TenantManagementCommunication, private _utilities: RetailUtilities, private _localization: RetailLocalization) { }

    public getTenantConfiguration(): Promise<TenantConfig<any>> {
        return this._tenantManagement.getPromise<TenantConfig<any>>(
            {
                route: RetailRoutes.TenantConfiguration,
                uriParams: {
                    configurationName: TenantConfigurations.TenantConfiguration
                }
            }
        )
    }

    public GetTenantConfiguration(): Promise<TenantConfig<any>> {
        return this._tenantManagement.postPromise<TenantConfig<any>>(
            {
                route: RetailRoutes.GetTenantConfig,
                body: { "tenantId": parseInt(this._utilities.GetPropertyInfo('TenantId')), "configurationName": "TENANTSETUPCONFIGURATION" }
            }
        )
    }


    public updateSettings<T>(data: PropertyConfig<T>): Promise<boolean> {
        const setting: PropertyConfig<string> = {
            id: data.id,
            propertyId: data.propertyId,
            productId: data.productId,
            configurationName: data.configurationName,
            configValue: JSON.stringify(data.configValue),
            defaultValue: JSON.stringify(data.defaultValue)
        };
        return this._tenantManagement.putPromise<boolean>(
            { route: RetailRoutes.UpdatePropertyConfiguration, body: setting });
        
    }


}