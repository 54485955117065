import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  CurrencyFormatter = new Intl.NumberFormat;
  CurrencyFormatterWithOutStyle = new Intl.NumberFormat;
}
