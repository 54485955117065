import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as myGlobals from '../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailLocalization } from '../common/localization/retail-localization';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { BaseResponse } from '../shared/business/shared.modals';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RetailService } from '../retail.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { AgDropdownConfig, ButtonValue, DropdownOptions } from "src/app/common/Models/ag-models";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DiscountConfiguration, DiscountLevel, DiscountType, Pricing } from '../retail-code-setup/retail-discount-types/discount-types.model';
import { takeUntil } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs';
import { RetailCategoryAndDiscountTypeDetails } from '../shop/shop.modals';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-retail-discount-configuration',
  templateUrl: './retail-discount-configuration.component.html',
  styleUrls: ['./retail-discount-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailDiscountConfigurationComponent implements OnInit {

  searchText: string = "";
  captions: any;
  header: any = [];
  applyValue: any = '0.00';
  updateDisable: boolean = true;
  selectedFieldName: string;
  selectedFieldID: number = undefined;
  tableoptions: any = [];
  copyOfDiscountConfig: any = [];
  fieldSelected: boolean = false;
  valueApplied: boolean = false;
  userEditing: boolean = false;
  IsViewOnly: boolean;
  discountConfigData: any = [];
  selecteddiscountType: any[] = [];
  disableEditButton: boolean = false;
  editData = false;
  applyValid: boolean = false;
  maxLength = myGlobals.Maxlength.PERCENTAGE;
  floatLabel: string;
  floatLabelNever: string;
  applyButton: ButtonValue;
  resetButton: ButtonValue;
  filterForm: UntypedFormGroup;
  categoryOptions: AgDropdownConfig ;
  discountTypeOptions: AgDropdownConfig;
  filtercategoryOptions: AgDropdownConfig ;
  filterdiscountTypeOptions: AgDropdownConfig;  
  discountTypes: DiscountType[] = [];
  playerTypeOptions = [];
  rateTypeOptions = [];
  showFilters: boolean = false;
  applyResetButton: ButtonValue;
  resetFilterButton: ButtonValue;
  errorMessage: any;
  isPercentage = true;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  bulkEditConfig: DiscountConfiguration[] = [];
  bulkEditNonApplicableConfig: DiscountConfiguration[] = [];
  isCellsAreHighlighted : boolean = false;
  discountConfiguration: RetailCategoryAndDiscountTypeDetails[] = [];
  hideBadge : boolean = true;
  constructor(public _utilities: RetailUtilities, public localization: RetailLocalization, private http: HttpServiceCall, private retailService: RetailSetupService, private business: RetailService, private fb: UntypedFormBuilder) {
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.initialize();
    this.InvokeServiceCall("GetActiveDiscountTypes", myGlobals.Host.retailManagement, HttpMethod.Get);
    this.applyValue = this.LoadDecimalValue(this.applyValue);
    let getviewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.DiscountConfiguration);
    this.IsViewOnly = getviewOnly && getviewOnly.view;
    this.retailService.tabLoaderEnable.next(false);
  }

  initializeFormgroup(){
    this.filterForm = this.fb.group({
      filterdiscountType: '',
      filtercategory: '',
      category: '',
      // playerType: '',
      // rateType: '',
      applyValue: '',
      discountType: '',
      percentAmount: 0,
      searchText: ''
    });
  }

  initialize() {
    this.initializeFormgroup();
    this.applyButton = {
      type: "primary",
      label: this.localization.captions.btn_apply,
      disabledproperty: true,
    };
    this.resetButton = {
      type: "secondary",
      label: this.localization.captions.btn_reset,
    };
    this.applyResetButton = {
      type: "primary",
      label: this.localization.captions.btn_apply,
      disabledproperty: true,
    };
    this.resetFilterButton = {
      type: "secondary",
      label: this.localization.captions.btn_reset,
    };
    this.categoryOptions = {
      form: this.filterForm,
      formControlName: 'category',
      selectOptions: of([]),
      placeHolder: this.captions.category,
      className: '',
      isAll: true,
      isMultiSelect: true,
      showRequired: true
    };
    this.filtercategoryOptions = {
      form: this.filterForm,
      formControlName: 'filtercategory',
      selectOptions: of([]),
      placeHolder: this.captions.category,
      className: 'w-100',
      isAll: true,
      isMultiSelect: true,
    };
    this.discountTypeOptions = {
      form: this.filterForm,
      formControlName: 'discountType',
      selectOptions: of([]),
      placeHolder: this.captions.discountType,
      className: '',
      isAll: true,
      isMultiSelect: true,
      showRequired: true
    };
    this.filterdiscountTypeOptions = {
      form: this.filterForm,
      formControlName: 'filterdiscountType',
      selectOptions: of([]),
      placeHolder: this.captions.discountType,
      className: 'w-100',
      isAll: true,
      isMultiSelect: true,
    };
    this.filterForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(_res => {
      this.applyButton.disabledproperty = !(this.filterForm.valid && this.filterForm.dirty);
      this.applyButton = { ...this.applyButton };
      if(this.showFilters){
        this.applyResetButton.disabledproperty = !(this.filterForm.value.filterdiscountType?.length > 0 || this.filterForm.value.filtercategory?.length > 0) ;
        this.applyResetButton = {...this.applyResetButton};
      }
    });
  }

  openFilter() {
    this.showFilters = !this.showFilters;
    this.resetCells();
  }
 
  reset() {
    this.hideBadge = true;
    this.filterForm.controls.filtercategory.setValue('');
    this.filterForm.controls.filterdiscountType.setValue('');
    this.setCategory();
    this.setFilterDiscountTypes();
    this.formGridData();
  }

  setFilterDiscountTypes(){
    this.filterdiscountTypeOptions.selectOptions = of(this.discountTypes.map(x => {
      return {
        id: x.id,
        value: x.type,
        viewValue: x.type,
        otherData: x
      }
    }));
    this.filterdiscountTypeOptions = {...this.filterdiscountTypeOptions};
  }

  setDiscountType(){
    this.filterForm.get('discountType').setValue([]);
    this.discountTypeOptions.selectOptions = of(this.discountTypes.filter(x => x.isPercentage == this.isPercentage).map(x => {
      return {
        id: x.id,
        value: x.type,
        viewValue: x.type,
        otherData: x
      }
    }));
    this.discountTypeOptions = {...this.discountTypeOptions};
  }

  togglebuttonVal(e){
    this.isPercentage = !this.isPercentage;
    this.setDiscountType();
  }

  InvokeServiceCall(route: string, domain: myGlobals.Host, callType: HttpMethod, uriParams?: any, body?: any, extraParams?: any) {
    this._utilities.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing)
    this.http.CallApiWithCallback<any>({
      host: domain,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: route,
      method: callType,
      body: body,
      showError: true,
      extraParams: extraParams,
      uriParams: uriParams
    });
  }

  //Edited Data
  Done(event) {
    let updateArr = [];
    if (event[2] != null) { // column-wise discount edit
      this.updateDiscountConfigurations(event[2].id, event[2].jsonkey, event[0]);
    }
    else { // row-wise discount edit
      const discountData = [];
      Object.keys(event[0]).map(key => (
        discountData.push({
          name: key,
          value: Array.isArray(event[0][key]) ? event[0][key] : [event[0][key]]
        })));
      event[0].cdiscountTypeDetails.forEach(x => {
        const discountConfig = discountData.find(d => d.name == x.discountName);
        if (discountConfig) {
          x.discountvalue = this.localization.currencyToSQLFormat(discountConfig.value);
          updateArr.push(x);
        }
      });
      this.InvokeServiceCall("UpdateDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, { id: event[0].id }, updateArr);
    }
    this.userEditing = false;
  }

  updateDiscountConfigurations(discountTypeId, discountName, tableData) {
    let updateArr = [];
    tableData.forEach(disc => {
      const discountData = disc[discountName];
      const discountConfig = disc.cdiscountTypeDetails.find(x => x.discountTypeId == discountTypeId);
      if (discountConfig) {
        discountConfig.discountvalue = this.localization.currencyToSQLFormat(discountData);
        updateArr.push(discountConfig);
      }
    });
    if (updateArr.length > 0)
      this.InvokeServiceCall("UpdateDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, { id: updateArr[0].retailCategoryId }, updateArr);
  }


  LoadDecimalValue(val: any): string {
    return this.localization.localizeCurrency(val, false);
  }

  resetPopOver(){
    this.filterForm.controls.category.setValue('');
    this.filterForm.controls.discountType.setValue('');
    this.filterForm.controls.filtercategory.setValue('');
    this.filterForm.controls.filterdiscountType.setValue('');
    this.isPercentage = true;
    this.setDiscountType();
    this.setCategory();
    this.filterForm.reset();
    this.filterForm.markAsPristine();
    this.filterForm.controls.percentAmount.setValue(0);
    this.filterForm.controls.searchText.setValue('');
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetActiveDiscountTypes") {      
      this.InvokeServiceCall("GetDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Get);
      this.discountTypes  = <any>result.result;
      this.discountTypes = this.discountTypes.filter(x => x.discountLevel == DiscountLevel.Item);
      this.header = this.discountTypes;
      this.isPercentage = true;
      this.setFilterDiscountTypes();
      this.setDiscountType();
    }
    if (callDesc == "GetDiscountConfiguration" || callDesc == "UpdateDiscountConfiguration" || callDesc == "ResetDiscountConfiguration") {
      const response =  <any>result.result;
      this.discountConfiguration = response as  RetailCategoryAndDiscountTypeDetails[];
      this.formGridData();
    }
    this._utilities.ToggleLoaderWithMessage(false)
  }

  onSave() {
    this.hideBadge = false;
    this.applyResetButton.disabledproperty = true;
    const discountTypes: number[] = this.filterForm.controls.filterdiscountType.value? this.filterForm.controls.filterdiscountType.value?.map(x => Number(x.id)) : [];
    const category: number[] =  this.filterForm.controls.filtercategory.value ? this.filterForm.controls.filtercategory.value.map(cx => cx.id) : [];
    if(category.length > 0 || discountTypes.length > 0){
      const tableData = this.formTableData(category.length > 0 ? this.discountConfiguration.filter(x => category.includes(x.retailCategoryId)) : this.discountConfiguration, discountTypes);
      this.BindTableData(tableData,discountTypes);
    }
  }

  resetCells(){
    if(this.isCellsAreHighlighted){
      this.formGridData();
    }
  }

  formGridData(){
    this.isPercentage = true;
    this.resetPopOver();
    this.isCellsAreHighlighted = this.bulkEditConfig?.length > 0 || this.bulkEditNonApplicableConfig?.length > 0;
    this.discountConfigData = this.formTableData(this.discountConfiguration);
    this.bulkEditConfig = [];
    this.bulkEditNonApplicableConfig = [];
    this.copyOfDiscountConfig = cloneDeep(this.discountConfigData);
    this.setCategory();
    this.BindTableData(this.discountConfigData);
  }

  formTableData(discountConfiguration: RetailCategoryAndDiscountTypeDetails[], filterDiscountTypes?: number[] ){
    const configData = [];
    let data;
    discountConfiguration.forEach(element => {
      data = {
        'id': element.retailCategoryId,
        'category_name': element.retailCategoryName,
        'cdiscountTypeDetails': []
      }
      const discountTypeDetails = filterDiscountTypes?.length > 0 ?  element.discountTypeDetails.filter(d => filterDiscountTypes.includes(d.discountTypeId)) : element.discountTypeDetails;
      discountTypeDetails.forEach((header, index) => {
        data[header.discountTypeName] = this.localization.localizeCurrency(header.discountValue, false);
        data[header.discountTypeName + 'highLightYellow'] = this.bulkEditConfig?.some(x => x.retailCategoryId == element.retailCategoryId && x.discountTypeId == header.discountTypeId);
        data[header.discountTypeName + 'highLightRed'] = this.bulkEditNonApplicableConfig?.some(x => x.retailCategoryId == element.retailCategoryId && x.discountTypeId == header.discountTypeId);
        data.cdiscountTypeDetails.push(
          {
            'discountTypeId': header.discountTypeId,
            'discountvalue': this.localization.localizeCurrency(header.discountValue, false),
            'retailCategoryId': element.retailCategoryId,
            'discountName': header.discountTypeName,
            'isPercentage': header.isPercentage,
          });
      });
      configData.push(data);
    });
    return configData;
  }


  setCategory(){
    this.categoryOptions.selectOptions = this.filtercategoryOptions.selectOptions = of(this.discountConfigData.map(x => {
      return {
        id: x.id,
        value: x.category_name,
        viewValue: x.category_name,
      }
    }));
    this.categoryOptions = {...this.categoryOptions};
    this.filtercategoryOptions = {...this.filtercategoryOptions};
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this._utilities.ToggleLoaderWithMessage(false)
  }

  selectedField(event) {
    let filterData = this.header.filter(x => x.id == event.id);
    this.selectedFieldName = filterData[0].type;
    this.fieldSelected = true;
    this.checkValid();
  }
  applyFunc() {
    const value: number = this.localization.currencyToSQLFormat(this.applyValue);
    if (value > 100) {
      let errorMessage = this.localization.replacePlaceholders(this.captions.MaximumDiscountValue, ['MaxNumber'], [this.localization.localizeCurrency('100.00', false)]);
      this.business.openErrorDialog(errorMessage, this.localization.captions.common.Error, this.localization.captions.common.Error);
      this.applyValue = this.localization.localizeCurrency('0.00', false);
      return;
    }
    this.updateDisable = false;
    let indexvalue = this.selecteddiscountType.findIndex(x => x.discountDetails == this.selectedFieldID['id']);
    if (indexvalue == -1) {
      let newObj = {
        'discountDetails': this.selectedFieldID,
        'discountTypeId': this.selectedFieldID['id'],
        'discountvalue': this.applyValue
      }
      this.selecteddiscountType.push(newObj);
    } else {
      this.selecteddiscountType[indexvalue]['discountvalue'] = this.applyValue;
    }
    this.selecteddiscountType.forEach(SDT => {
      let typename = SDT.discountDetails['type'];
      this.discountConfigData.forEach(DC => {
        DC['details' + typename].discountvalue = SDT.discountvalue;
        DC[typename] = SDT.discountvalue;
      });
    });
    this.disableEditButton = true;
    this.BindTableData(this.discountConfigData);
  }

  UpdateAppliedValues() {
    this.selecteddiscountType.forEach(x => {
      x.discountvalue = this.localization.currencyToSQLFormat(x.discountvalue);
    });
    console.log("this.selecteddiscountType", this.selecteddiscountType);
    this.InvokeServiceCall("ApplyAndUpdateDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, undefined, this.selecteddiscountType);
    this.updateDisable = true;
    this.selectedFieldID = undefined;
    this.applyValue = this.localization.localizeCurrency('0.00', false);
    this.fieldSelected = false;
    this.selecteddiscountType = [];

    this.applyValid = false;
    this.disableEditButton = false;
    this.BindTableData(this.discountConfigData);
  }
  resetValues() {
    this.updateDisable = true;
    this.selectedFieldID = undefined;
    this.applyValue = this.localization.localizeCurrency('0.00', false);
    this.fieldSelected = false;
    this.selecteddiscountType = [];
    this.disableEditButton = false;
    this.BindTableData(this.discountConfigData);
  }
  //Delete data
  deletedRecord(event: any) {
    let updateArr = [];
    event.cdiscountTypeDetails.forEach(element => {
      element.discountvalue = 0;
      updateArr.push(element)
    });
    this.InvokeServiceCall("ResetDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, { id: event.id }, updateArr);
    this.userEditing = false;
  }

  userEdit(event : boolean) {
    this.userEditing = event;
    if(!event){
      this.resetCells();
    }
  }

  editCancelled(event) {
    this.userEditing = false;
  }

  BindTableData(data, filterDiscountTypes?: number[]) {
    let newhdr = [{ "title": this.captions.Category, "jsonkey": "category_name", "searchable": false, "alignType": "left", "sortable": true, "isInput": false, "isEdit": false, "isPercentage": false ,
       }];
    const header = filterDiscountTypes?.length > 0 ?  this.header.filter(x => filterDiscountTypes.includes(x.id)) : this.header;
    header.filter(x => x.discountLevel == DiscountLevel.Item).forEach(element => {
      let temphdr = {
        "title": element.type + (!element.isPercentage ? "("+this.localization.currencySymbol+")" : ""),
        "jsonkey": element.type,
        "alignType": "right",
        "searchable": false,
        "sortable": true,
        "type": "number",
        "isInput": true,
        "isEdit": true,
        "datatype": "decimal",
        "sortcolumndatatype": "decimal",
        "isPercentage": element.isPercentage,
        "id": element.id,
        "pricing": element.pricing,
        "amount" : element.amount,
        "highLightYellow": element.type + "highLightYellow",
        "highLightRed" : element.type + "highLightRed"
      }
      newhdr.push(temphdr);
    });
    this.tableoptions = [{
      TableHdrData: newhdr,
      TablebodyData: data,
      pagination: false,
      sortable: true,
      CustomColumn: false,
      PlaceHoldertext: this.captions.SearchByCategory,
      EnableActions: header.length > 0 ? true : false,
      SelectRows: false,
      SelectedSettingId: myGlobals.GridType.retail,
      Searchable: false,
      EditMoreOption: false,
      Sortable: "category_name",
      TableId: myGlobals.GridType.retail,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: this.disableEditButton,
      automationId: 'retailDiscountConfiguration'
    }];
  }


  checkValid() {
    if (this.selectedFieldID && this.applyValue) {
      this.applyValid = true;
    } else {
      this.applyValid = false;
    }
  }
  includeInactive(e) {

  }
  maxError(e) {
    this.errorMessage = e
  }
  onAction(e) {
    const discountTypes: DropdownOptions[] = this.filterForm.controls.discountType.value;
    const category : DropdownOptions[]  = this.filterForm.controls.category.value;
    const applyValue = this.localization.currencyToSQLFormat(this.filterForm.controls.applyValue.value);
    const selectedDiscounts = this.discountTypes.filter(x => discountTypes.map(d => d.id).includes(x.id));
    this.bulkEditConfig = [];
    this.bulkEditNonApplicableConfig = [];
    category.forEach(element => {
      const result = this.getDiscountConfiguration(Number(element.id), applyValue, selectedDiscounts);
      this.bulkEditConfig = [...this.bulkEditConfig , ... result.applicableData];
      this.bulkEditNonApplicableConfig = [...this.bulkEditNonApplicableConfig , ... result.nonApplicableData];
    });
    if(this.bulkEditConfig.length > 0) {
      this.InvokeServiceCall("UpdateDiscountConfiguration", myGlobals.Host.retailManagement, HttpMethod.Put, { id: this.bulkEditConfig[0].retailCategoryId }, this.bulkEditConfig);
    } else if(this.bulkEditNonApplicableConfig.length > 0){
      this.formGridData();
    }
    this.resetPopOver();
  }

  getDiscountConfiguration(categoryId: number, discountValue: number, selectedDiscounts: DiscountType[]) {
    const configs: {
      applicableData: DiscountConfiguration[],
      nonApplicableData: DiscountConfiguration[]
    } = { applicableData: [], nonApplicableData: [] }
    selectedDiscounts.forEach(disc => {
      if (disc.pricing == Pricing.Open && discountValue <= disc.amount) {
        configs.applicableData.push({
          retailCategoryId: categoryId,
          discountTypeId: disc.id,
          isPercentage: disc.isPercentage,
          discountValue: discountValue
        });
      } else if (disc.pricing == Pricing.Fixed) {
        configs.applicableData.push({
          retailCategoryId: categoryId,
          discountTypeId: disc.id,
          isPercentage: disc.isPercentage,
          discountValue: discountValue
        });
      } else {
        configs.nonApplicableData.push({
          retailCategoryId: categoryId,
          discountTypeId: disc.id,
          isPercentage: disc.isPercentage,
          discountValue: discountValue
        });
      }
    });
    return configs;
  }

  search(e) {
    this.searchText = e.target.value;
  }

  clear(){
    this.filterForm.controls.searchText.setValue('');
    this.resetCells();
  }
}

