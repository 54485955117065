import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
@Directive({
  selector: "[textmask]"
})
 
export class TextMaskDirective {

  constructor(private control: NgControl, private utils: SpaUtilities) {
  }
  @Input() textmask: string;
  @Input() maskDisable: boolean;

  @HostListener('input', ['$event']) oninput(event) {
    const e = event;
    if (this.maskDisable) {
      return;
    }
    const mask = this.textmask;
    const fullValue = e.target.value;
    if (e.inputType != 'deleteContentBackward') {
      event.currentTarget.value = this.utils.appendFormat(fullValue.replace(/\D/g, ''), mask);
      const originalvalue = event.currentTarget.value;
      this.control.control.setValue(originalvalue);
    }
  }
}
