import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { EmailConfigModel, SavedFiltersModel, ScheduleReportDataModel, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from '@shared/constant/GlobalConstants';

export const ScheduleReportDataConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, Value?: any): any => {
    try {   
        const homeGroupValue = Value.homeGroup.value;
        const FilterString = Value.FilterString;
        const ScheduleReportObject = {} as ScheduleReportDataModel;
        ScheduleReportObject.Id = 0;        
        ScheduleReportObject.Name = homeGroupValue.Name ? homeGroupValue.Name : '';
        ScheduleReportObject.FileName = homeGroupValue.FileName ? homeGroupValue.FileName : '';
        ScheduleReportObject.Remarks = homeGroupValue.Remarks ? homeGroupValue.Remarks : '';
        ScheduleReportObject.SiteId = Value.SiteId ? Value.SiteId : '';
        ScheduleReportObject.Description = homeGroupValue.Description ? homeGroupValue.Description : '';
        ScheduleReportObject.SavedFilters = {} as SavedFiltersModel;
        ScheduleReportObject.SavedFilters.Id = 0;
        ScheduleReportObject.SavedFilters.IsActive = true;
        ScheduleReportObject.SavedFilters.FilterString = FilterString ? JSON.stringify(FilterString) : ''; 
        ScheduleReportObject.SavedFilters.Period = homeGroupValue.period;
        ScheduleReportObject.Cron = homeGroupValue.CronExpression ? homeGroupValue.CronExpression : '';
        ScheduleReportObject.StartDate = homeGroupValue.StartDate ? 
        getStartDate(homeGroupValue.StartDate) : '';
        ScheduleReportObject.EndDate = homeGroupValue.EndDate ? 
        getEndDate(homeGroupValue.EndDate) : '';
        const currentDate = moment().format();
        ScheduleReportObject.Frequency = (homeGroupValue.frequency !== null &&
            homeGroupValue.frequency !== undefined) ? 
        homeGroupValue.frequency : null;
        ScheduleReportObject.RecurrenceArray = (homeGroupValue.recurrence && 
            homeGroupValue.recurrence.length > 0) ? homeGroupValue.recurrence : 
            (homeGroupValue.monthrecurence && homeGroupValue.monthrecurence.length > 0) ? 
                homeGroupValue.monthrecurence : null;
        ScheduleReportObject.ScheduledTime = homeGroupValue.shedulereporttime ? 
        getUTCTime(homeGroupValue.shedulereporttime) : '';
        ScheduleReportObject.LastRunTime = currentDate;
        ScheduleReportObject.NextRunTime = currentDate;
        ScheduleReportObject.UserId = '';
        ScheduleReportObject.IsActive = IsActive ? IsActive : false;
        ScheduleReportObject.CreatedDateTime = currentDate;
        ScheduleReportObject.LastUpdatedDateTime = currentDate;
        ScheduleReportObject.CreatedBy = 'eatec';
        ScheduleReportObject.LastUpdatedBy = currentDate;
        // ScheduleReportObject.CurrnetProcessingInstanceId = '0';
        ScheduleReportObject.ProcessingStartTime = currentDate;
        ScheduleReportObject.EmailConfig = {} as EmailConfigModel;
        ScheduleReportObject.EmailConfig.Id = 0;
        ScheduleReportObject.EmailConfig.Description = '';
        ScheduleReportObject.EmailConfig.ToEmail = homeGroupValue.EmailTo ? homeGroupValue.EmailTo : '';
        ScheduleReportObject.EmailConfig.BCCEmail = '';
        ScheduleReportObject.EmailConfig.CCEmail = '';
        ScheduleReportObject.EmailConfig.FromEmail = '';
        ScheduleReportObject.IanaTimeZoneId = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
        ScheduleReportObject.UserTimeZoneId = new Date()?.toString()?.split("(")[1]?.split(")")[0];
        const PostData = Object.assign({}, ScheduleReportObject);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Schedule Report Data Construction', error);
    }
};

function getUTCTime(timeValue: any): any {
    try {
        // const currentTimeDate = new Date();
        // const TimeFormats = moment(timeValue, [
        //     'hh:mm a',
        //   ]).format('HH:mm');
        //   const Hours = parseInt(TimeFormats.split(':')[0], 10);
        // const Minutes = parseInt(TimeFormats.split(':')[1], 10);
        // const datetime = new Date(
        //   currentTimeDate.getFullYear(),
        //   currentTimeDate.getMonth(),
        //   currentTimeDate.getDate(),
        //   Hours,
        //   Minutes,
        //   0
        // );
        // const ScheduleTime = moment.utc(datetime).format();
        const currentDate = moment().format('YYYY-MM-DD');
        const scheduleTime = moment(`${currentDate} ${timeValue}`, 'YYYY-MM-DD hh:mm A').format(constants.PostDateTimeFormat);
        return scheduleTime;
    } catch (error) {
        console.error('Error Occured ', timeValue);
    }
}

function getStartDate(startDate: any): any {
    let returnValue = moment(startDate).startOf('day').format(constants.PostDateTimeFormat);
    return returnValue;
}

function getEndDate(endDate: any): any {
    let returnValue = moment(endDate).endOf('day').format(constants.PostDateTimeFormat);
    return returnValue;
}