import { Pipe, PipeTransform } from '@angular/core';
import { SpaLocalization } from '../../../core/localization/spa-localization';

@Pipe({
  name: 'formatValue'
})
export class FormatValuePipe implements PipeTransform {
  constructor(private localization: SpaLocalization) {}
  transform(value: any): any {
    return this.localization.localizePercentage(value);
  }
}
