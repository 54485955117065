import { FormGroup } from "@angular/forms";
import { PostPOSupplierConfig, TransactionConfiguration } from "../transaction.interface";
import { TransactionService } from "src/app/eatecui/source/transaction/shared/services/transaction.service";
import { PostPOSupplierConfiguration } from "./create-transaction-config.interface";
import { HttpService } from "src/app/eatecui/source/shared/services/http.service";
import { CreateTransaction } from ".";

export const PostPOSupplier: PostPOSupplierConfiguration = (
    Result: PostPOSupplierConfig,
    TransactionCollection: TransactionConfiguration,
    homeGroup: FormGroup,
    transactionService: TransactionService,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    post: any,
    GlobalOdataModel: any, httpService?: HttpService): any  => {
        try {
            const POPostSuppliers = [];
            const supplierIdList = Result ? Result.SupllierIdList : getSupplierIdList(TransactionCollection, httpService, transactionService);
            supplierIdList.forEach(supplier => {
                const returnData = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                    PreviousValuePO.filter(x => x.ParentId === supplier)[0];
                homeGroup.patchValue(returnData?.FormData);
                if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData &&
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData.length > 0) {
                    homeGroup.controls['TransactionMiscCharges'].
                        setValue(TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData);
                } else {
                    homeGroup.controls['TransactionMiscCharges'].setValue(null);
                }
                const DataForm = homeGroup;
                const DataExtra = Result?.SupplierProcessingList.filter(x => x.Id === supplier)[0];
                if (DataExtra) {
                    DataForm.value['NoExport'] = DataExtra.NoExport;
                    DataForm.value['DoMail'] = DataExtra.Email;
                    DataForm.value['IsApprovalRequired'] = !DataExtra.EnableApproval ? true :  DataExtra.Approval;
                }
                const postModelData = transactionService.constractTransactionHeaderPostModel(
                    postModelHederEntity, postModelDetailEntity, TransactionCollection, DataForm, post, GlobalOdataModel);
                const copyPostModelData = JSON.parse(JSON.stringify((postModelData)));
                const PostHeadObject = JSON.parse(JSON.stringify(copyPostModelData));
                const SupplierItemList = PostHeadObject['PurchaseOrderDetails'].filter(x => x.SupplierId === supplier);
                PostHeadObject['PurchaseOrderDetails'] = SupplierItemList;
                const SupplierPostObject = {
                    'SupplierId': supplier,
                    'PurchaseOrderHead': PostHeadObject,
                    'IsValidEntry': true,
                    'ClientUrl': homeGroup.value.ClientURL
                };
                POPostSuppliers.push(SupplierPostObject);
            });
            return POPostSuppliers;
        } catch (error) {
            console.log(error);
        }
    }

    function getSupplierIdList(TransactionCollection: any ,httpService: HttpService, transactionService: TransactionService): Array<number> {
      try {
        const returnSuppliers = [];
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList.forEach(data => {
            if (returnSuppliers.length > 0) {
                const ind = returnSuppliers.findIndex(x => x === data.SuplierId);
                if (ind === -1) {
                    returnSuppliers.push(data.SuplierId);
                }
            } else {
                returnSuppliers.push(data.SuplierId);
            }          
        });
        return returnSuppliers;
      } catch (error) {
        console.log(error);
      }
    }