import { Injectable } from '@angular/core';
import { CommonControllersRoutes } from '../../communication/common-route';
import { ComponentsLinkingCommunication } from '../../communication/services/components-linking-communication.service';
import { ComponentsLinkingConfigAPI } from '../../Models/components-linking-config.model';
import { ComponentsLinking } from '../../Models/components-linking.model';

@Injectable({
  providedIn: 'root'
})
export class ComponentsLinkingConfigService {

  constructor(private _componentsLinkingCommunication: ComponentsLinkingCommunication) { }
  public createComponentsLinkingConfig(componentsLinkingConfig: ComponentsLinkingConfigAPI): Promise<boolean>{
    return this._componentsLinkingCommunication.postPromise<boolean>({
      route: CommonControllersRoutes.CreateComponentAccessTypeMaster,
      body: componentsLinkingConfig     

    });

  }
  public updateComponentsLinkingConfig(componentsLinkingConfig: ComponentsLinkingConfigAPI, id: number):Promise<boolean>{
    componentsLinkingConfig.id = id;
    return this._componentsLinkingCommunication.putPromise<boolean>({
      route: CommonControllersRoutes.UpdateComponentAccessTypeMaster,
      body: componentsLinkingConfig,
      uriParams:{id}     

    })
  }
  public deleteComponentsLinkingConfig( id: number):Promise<boolean>{
  
    return this._componentsLinkingCommunication.deletePromise<boolean>({
      route: CommonControllersRoutes.DeleteComponentAccessTypeMaster,     
      uriParams:{id}     

    })
  }
  public getComponentsLinkingConfig(): Promise<ComponentsLinkingConfigAPI[]> {
    return this._componentsLinkingCommunication.getPromise<ComponentsLinkingConfigAPI[]>(
      {
        route: CommonControllersRoutes.GetComponentAccessTypeMaster
      }
    );
  }
  public getComponentsLinkingConfigByTypeId(typeId: number): Promise<ComponentsLinkingConfigAPI>{    
    return this._componentsLinkingCommunication.getPromise({
      route:CommonControllersRoutes.GetComponentAccessTypeMasterByTypeId,
      uriParams: {id :typeId}
    });
  }

  public getComponentAccessTypeLinkConfig(): Promise<ComponentsLinking[]> {
    return this._componentsLinkingCommunication.getPromise({
      route: CommonControllersRoutes.GetComponentAccessTypeLink,
    });
  }
}
