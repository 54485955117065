<section class="chip-list-section">
    <mat-form-field class="chip-list">
        <mat-chip-list #chipList aria-label="chip selection">
          <mat-chip
            *ngFor="let chip of chipLists  | slice:0:2"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(chip)">
            <span class="chip-text" title="{{chip}}">{{chip}}</span>
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="{{formFieldlabel}}"
            #chipListInput
            [formControl]="chipListCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
            (focusout)="focusOut()">
            <span class="count-field" *ngIf="chipLists.length > 2" (click)="openPopup(template)">+ {{chipLists.length - 2}}</span>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let filterChips of filteredChips | async" [value]="filterChips">
            {{filterChips}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
</section>

<ng-template #template>
    <header class="chip-list-popup-header">Selected Items ({{chipLists.length}}) <mat-icon matDialogClose align="end">close</mat-icon>
    </header>
    <main class="chip-list-popup-main">
    <mat-chip-list #chipListData>
      <mat-chip *ngFor="let item of chipLists" [selectable]="selectable" [removable]="removable" (removed)="remove(item)">
        <span class="chip-text" title="{{item}}">{{item}}</span>
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </main>
    <footer class="chip-list-popup-footer">
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose color="primary">Cancel</button>
    </mat-dialog-actions>
  </footer>
   </ng-template>