import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType } from 'src/app/common/enums/shared-enums';
import { ButtonType } from 'src/app/common/shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { JWT_TOKEN, PROPERTY_DATE, USER_SESSION } from '../app-constants';
import { SpaLocalization } from '../localization/spa-localization';


@Injectable()
export class RouteGuardService implements CanActivate,CanActivateChild, OnDestroy {

    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    childbreakPointNumbers: any = [];
    accessArray: any = {
        breakpoints: [],
        paths: []
    }
    checkAuthorization: any;

    constructor(private router: Router,private utils: CommonUtilities, private breakpoint: BreakPointAccess, private localization: SpaLocalization) {
    }

    ngOnDestroy() {
        if (this.destroyed$) {
            this.destroyed$.next(true);
            this.destroyed$.complete();
        }
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            return await this.checkAccess(route, state);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async checkAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const breakpointNumber = route.data.breakPointNumber;
        const redirectLocation = route.data.redirectTo;
        const hasChild = route.data.hasChild;
        const extras = this.router.getCurrentNavigation().extras;
        const isModuleChange = extras.state ? extras.state.onModuleChange : false;
        const isSuModuleChange = extras.state ? (extras.state.onSubmoduleChange || extras.state.onChildChange) : false;
        const isFromPMS = this.router.url.indexOf('/Pms') !== -1;
        let userTherapist = sessionStorage.getItem('UserTherapist');
		let isTherapistLogin = userTherapist ? true : false;
		if(isTherapistLogin){
			let canRedirect = true;
			let isRouteDisabledonSingleTherapist = route.data.disableRouteonSingleTherapist;
			if(isRouteDisabledonSingleTherapist && isTherapistLogin){
				this.redirectToApptURL('/appointment/newappointment');
				canRedirect = false;
			}
			return canRedirect;
		}

        if (hasChild && !(isModuleChange || isSuModuleChange)) {
            let isAuthorised = this.getChildBreakPointNos(route, breakpointNumber, redirectLocation);
            if (!isAuthorised) {
                this.redirectToURL(redirectLocation, route)
                return false;
            }
        }

        if (isFromPMS) {
          const childBreakPoints = this.getChildBreakPointNos(route, breakpointNumber, redirectLocation);
        if (!childBreakPoints) {
          let lastBreakPoint = this.childbreakPointNumbers[this.childbreakPointNumbers.length - 1];
          this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[lastBreakPoint]);
          this.router.navigateByUrl('home');
          return false;
        }
        }

        if (isModuleChange || isSuModuleChange) {
            let isAuthorised = this.getChildBreakPointNos(route, breakpointNumber, redirectLocation);
            if (!isAuthorised) {
                let lastBreakPoint = this.childbreakPointNumbers[this.childbreakPointNumbers.length - 1];
                this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[lastBreakPoint]);
                return false;
            }
            else {
                if (this.accessArray.paths.length > 0 && this.checkAuthorization.authorizationIndex) {
                    this.redirectToAccessibleURL(this.accessArray.paths[this.checkAuthorization.authorizationIndex])
                    return true;
                }
                else {
                    this.redirectToCurrentURL(route);
                    return true;
                }
            }
        }

        if (breakpointNumber) {
            let isAuthorised = this.breakpoint.CheckForAtleastOneAccess([breakpointNumber]);
            if (isAuthorised.hasAccess) {
                return true;
            }
            else {
                if (redirectLocation) {
                    this.redirectToURL(redirectLocation, route);
                }
            }
        }
        else {
            return true;
        }

    }

    redirectToApptURL(redirectLocation) {
        this.router.navigate([redirectLocation]);
    }

    getChildBreakPointNos(route: ActivatedRouteSnapshot, breakpoint, redirectLocation) {
        this.childbreakPointNumbers = breakpoint ? [breakpoint] : [];
        let sliceEnd = route['_routerState'].url.indexOf(route.routeConfig.path) + route.routeConfig.path.length;
        const parentUrl = route['_routerState'].url.slice(0, sliceEnd) + '/';
        this.accessArray = {
            breakpoints: [],
            paths: []
        }
        if (route && route.routeConfig && route.routeConfig.children && route.routeConfig.children.length > 0) {
            route.routeConfig.children.forEach(child => {
                if (child) {
                    this.createChildMenuPath(child, parentUrl, this.accessArray.paths);
                }
            });
        }
        this.accessArray.breakpoints = this.childbreakPointNumbers;

        if (this.childbreakPointNumbers && this.childbreakPointNumbers.length > 0) {
            this.checkAuthorization = this.breakpoint.CheckForAtleastOneAccess(this.childbreakPointNumbers);
            if (this.checkAuthorization.hasAccess) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    createChildMenuPath(child, parentUrl, pathArray) {
        if (child && child.data && child.data.breakPointNumber) {
            this.childbreakPointNumbers.push(child.data.breakPointNumber);
            pathArray.push(parentUrl.concat(child.path));
        }
        else {
            let pathMatch = (child.pathMatch && (child.pathMatch == "full"))? true : false;
            if (!pathMatch) {
                this.childbreakPointNumbers.push(null);
                pathArray.push(parentUrl.concat(child.path));
            }
        }
        let childUrl = parentUrl.concat(child.path) + '/';
        if (child.children && child.children.length > 0) {
            child.children.forEach(y => {
                this.createChildMenuPath(y, childUrl, pathArray);
            });
        }
    }

    redirectToURL(redirectLocation, route: ActivatedRouteSnapshot) {
        this.router.navigate([redirectLocation]);
    }

    redirectToCurrentURL(route: ActivatedRouteSnapshot) {
        this.router.navigate([route['_routerState'].url]);
    }

    redirectToAccessibleURL(route) {
        this.router.navigate([route]);
    }
    backTrack() {
        let sessionFromURLArray = sessionStorage.getItem('fromURLPath');
        let sessionFromURLRouteStateArray = sessionStorage.getItem('fromURLRouteState');
        let fromURLPathArray = sessionFromURLArray ? JSON.parse(sessionFromURLArray) : [];
        let fromURLRouteStateArray = sessionFromURLRouteStateArray ? JSON.parse(sessionFromURLRouteStateArray) : [];
        if (fromURLPathArray.length > 0) {
            let stateValue = fromURLRouteStateArray[fromURLPathArray.length - 1];
            
            if (stateValue) {
                stateValue.fromBack = true;
                this.router.navigateByUrl(fromURLPathArray[fromURLPathArray.length - 1], {
                    state: stateValue 
                });
            }
            else {
                if(fromURLPathArray[fromURLPathArray.length - 1] != "" && fromURLPathArray[fromURLPathArray.length - 1] != null){
                    this.router.navigateByUrl(fromURLPathArray[fromURLPathArray.length - 1], {
                        state: { fromBack: true }
                    });
                }
            }
           
            fromURLPathArray.pop();
            fromURLRouteStateArray.pop();
            sessionStorage.setItem('fromURLPath', JSON.stringify(fromURLPathArray));
            sessionStorage.setItem('fromURLRouteState', JSON.stringify(fromURLRouteStateArray));
        }
    }
    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.checkLocalStore()) {
            this.utils.showAlert(this.localization.captions.common.lbl_userSessionLogout, AlertType.Warning, ButtonType.Ok, (res) => {
                if (res == AlertAction.CONTINUE) {
                    this.router.navigate(['/login']);
                }
            });
            return false;
        }
        let returnValue: boolean = await this.checkAccess(route, state);
        return returnValue;
    }
    checkLocalStore() {
        const jwt = sessionStorage.getItem(JWT_TOKEN);
        const propertyDate = sessionStorage.getItem(PROPERTY_DATE);
        const sessionId = sessionStorage.getItem(USER_SESSION);

        // To do: to be uncommented
        if (jwt != "null" && jwt != "undefined" && jwt != undefined && propertyDate != null && sessionId !== '' && sessionId !== null && sessionId !== undefined) {
            return false;
        }
        return true;
    }
}
