import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgFieldConfig, AgInputFieldConfig } from 'src/app/common/Models/ag-models';
import { CCTerminal } from 'src/app/common/shared/shared/setupConstants';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { ButtonValue } from 'src/app/retail/retail.modals';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { AlertAction, AlertType, GridOperationType } from 'src/app/retail/shared/shared.modal';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RetailCreditCardTerminalDataService } from '../retail-credit-card-terminal-data.service';
import { ButtonType, RetailFunctionalities } from 'src/app/retail/shared/globalsContant';

@Component({
  selector: 'app-create-credit-card-terminal',
  templateUrl: './create-credit-card-terminal.component.html',
  styleUrls: ['./create-credit-card-terminal.component.scss'],
  providers: [RetailCreditCardTerminalDataService]
})
export class CreateCreditCardTerminalComponent implements OnInit {
  codeMinLength: number;
  codeMaxLength: number;
  codeTextInputConfig: AgInputFieldConfig;

  merchantNumberMaxLength: number;
  merchantNumberInputConfig: AgInputFieldConfig;

  nameMinLength: number;
  nameMaxLength: number;
  nameTextInputConfig: AgInputFieldConfig;

  listOrderInput: AgFieldConfig;
  listOrderMinLength = 1;
  listOrderMaxLength = 5;
  listOrderMaxValue = 99999;

  isActive: false;
  folioMinLength: number;
  folioMaxLength: number;
  folioMinValue: number;
  folioMaxValue: number;
  folioInput: AgFieldConfig;

  storeIdMinLength: number;
  storeIdMaxLength: number;
  storeIdTextInputConfig: AgInputFieldConfig;
  captions: any;
  showRequired: boolean;
  form: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  isViewOnly: boolean = false;
  dataInput: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  functionalities: { [key: string]: boolean} = {};  
  ShowDefaultToggle: boolean;
  hideInAccounting: boolean = false;
  mode = GridOperationType;
  public get isResortFinanceEnabled(){
    return this.propertyInfo.isResortFinanceEnabled;
  }

  constructor(private fb: UntypedFormBuilder,
    private func: RetailFunctionalityBusiness,
    private localization: RetailLocalization,
    private retailUtilities: RetailUtilities,
    private propertyInfo: RetailPropertyInformation,
    @Inject(MAT_DIALOG_DATA) data: any,
		private creditCardService: RetailCreditCardTerminalDataService,
    private dialogRef: MatDialogRef<CreateCreditCardTerminalComponent>) {
    this.dataInput = data;
  }

  ngOnInit(): void {
    this.captions = this.localization.captions.retailsetup;
    this.isViewOnly = this.dataInput && this.dataInput.isViewOnly;
    this.showRequired = true;
    this.getValidators();
    this.createCreditCardTerminalForm();
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      this.hideInAccounting = this.functionalities.HideOptionsForAccountingProduct
      this.addDynamicControls();
    });
    this.pageInitialization();
    this.validateUserAccess();
    this.editAction();
    if (this.dataInput.isViewOnly) {
      this.retailUtilities.disableControls(this.form);
    }
  }

  editAction() {
    if (this.dataInput.datarecord.mode === GridOperationType.Edit) {
      this.codeTextInputConfig.readonly = true;
      this.codeTextInputConfig = { ...this.codeTextInputConfig };
    }
  }

  async pageInitialization() {
    this.actionButton = {
      type: 'primary',
      label: this.captions.SAVE,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.Cancel,
    };

    this.codeTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'cTerminalId',
      placeHolderId: 'CreditCardTerminallbl_Code',
      placeHolder: this.captions.CreditCardTerminallbl_Code,
      maxlength: this.codeMaxLength,
      showRequired: true,
      errorMessageId: 'CreditCardTerminalerr_missing_Code',
      errorMessage: this.captions.CreditCardTerminalerr_missing_Code,
      changeLabelOnEdit: true,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_createCreditCardTerminal_codeText'
    };
    this.merchantNumberInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'merchantnumber',
      placeHolderId: 'merchantNumber',
      placeHolder: this.captions.merchantNumber,
      maxlength: this.merchantNumberMaxLength,
      showRequired: true,
      errorMessageId: 'MissingMerchantNumber',
      errorMessage: this.captions.MissingMerchantNumber,
      changeLabelOnEdit: false,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_createCreditCardTerminal_merchantNo'
    };
    this.nameTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'name',
      placeHolderId: 'CreditCardTerminallbl_Name',
      placeHolder: this.captions.CreditCardTerminallbl_Name,
      maxlength: this.nameMaxLength,
      showRequired: true,
      errorMessageId: 'CreditCardTerminalerr_missing_Name',
      errorMessage: this.captions.CreditCardTerminalerr_missing_Name,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_createCreditCardTerminal_nameText'
    };
    this.listOrderInput = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'listOrder',
      placeHolderId: 'CreditCardTerminallbl_ListOrder',
      placeHolder: this.captions.CreditCardTerminallbl_ListOrder,
      maxlength: this.listOrderMaxLength,
      isRequired: false,
      disabled: false,
      maxValue: this.listOrderMaxValue,
      minValue: this.listOrderMinLength,
      customErrorMessageId: 'CreditCardTerminalerr_missing_ListOrder',
      customErrorMessage: this.captions.CreditCardTerminalerr_missing_ListOrder,
      automationId: 'Txt_createCreditCardTerminal_listOrder'
    };

    this.folioInput = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'folio',
      placeHolderId: 'CreditCardTerminallbl_Folio',
      placeHolder: this.captions.CreditCardTerminallbl_Folio,
      maxlength: this.folioMaxLength,
      minlength: this.folioMinLength,
      minValue: this.folioMinValue,
      maxValue: this.folioMaxValue,
      isRequired: false,
      errorMessageId: 'CreditCardTerminalerr_missing_Folio',
      errorMessage: this.captions.CreditCardTerminalerr_missing_Folio,
    };
    this.storeIdTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'storeId',
      placeHolderId: 'CreditCardTerminallbl_StoreId',
      placeHolder: this.captions.CreditCardTerminallbl_StoreId,
      maxlength: this.storeIdMaxLength,
      showRequired: false,
      errorMessageId: 'CreditCardTerminalerr_missing_StoreId',
      errorMessage: this.captions.CreditCardTerminalerr_missing_StoreId,
      inputType: 'noprespace,nospecailchar',
      automationId: 'Txt_createCreditCardTerminal_storeId'
    };
    this.patchValues();
  }

  validateUserAccess() {
    if (this.isViewOnly) {
      this.retailUtilities.disableControls(this.form);
    }
  }

  patchValues() {
    if (this.dataInput.datarecord.mode === GridOperationType.Edit) {
      this.form.patchValue({
        id: this.dataInput.datarecord.form.id,
        cTerminalId: this.dataInput.datarecord.form.cTerminalId,
        merchantnumber: this.dataInput.datarecord.form.merchantnumber,
        name: this.dataInput.datarecord.form.name,
        listOrder: this.dataInput.datarecord.form.listOrder,
        isActive: this.dataInput.datarecord.form.isActive,
        folio: this.dataInput.datarecord.form.folio === 0 ? '' : this.dataInput.datarecord.form.folio,
        storeId: this.dataInput.datarecord.form.storeId,
        isDefault: this.dataInput.datarecord.form.isDefault
      });
    }
   this.form.markAsUntouched();
   this.form.markAsPristine();
  }

  createCreditCardTerminalForm() {
    this.form = this.fb.group({
      id: 0,
      cTerminalId: ['', Validators.required],
      isActive: true,
      isDefault:false
    });

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.actionButton.disabledproperty = !(this.form.dirty && this.form.valid)
    });

  }

  private addDynamicControls() {
    if (this.functionalities.ShowCreditCardTerminalMerchantNumber) {
      this.form.addControl('merchantnumber', new UntypedFormControl(''));
      this.setValidator('merchantnumber');
    }
    if(this.functionalities.ShowDefaultToggleInCreditCardTerminalCreation)
    {
      this.ShowDefaultToggle = this.functionalities[RetailFunctionalities.ShowDefaultToggleInCreditCardTerminalCreation] || false;
    }
    if (this.functionalities.ShowCreditCardTerminalOtherInfo) {
      this.form.addControl('name', new UntypedFormControl(''));
      this.form.addControl('listOrder', new UntypedFormControl(this.dataInput.datarecord.nextListOrder));
      this.form.addControl('folio', new UntypedFormControl(''));
      this.form.addControl('storeId', new UntypedFormControl(''));
      this.setValidator('name');
    }

    this.patchValues();
    
  }

  setValidator(controlName) {
    this.form.controls[controlName].setValidators([Validators.required]);
    this.form.controls[controlName].updateValueAndValidity();
    this.form.controls[controlName].markAsDirty();
  }

  removeValidator(controlName) {
    this.form.controls[controlName].clearValidators();
    this.form.controls[controlName].updateValueAndValidity();
    this.form.controls[controlName].markAsDirty();
  }

  getValidators() {
    this.codeMinLength = CCTerminal.CREDITCARDTERMINALCODE_MINLENGTH;
    this.codeMaxLength = CCTerminal.CREDITCARDTERMINALCODE_MAXLENGTH;

    this.merchantNumberMaxLength = CCTerminal.MERCHANTNUMBER_MAXLENGTH

    this.nameMinLength = CCTerminal.CREDITCARDTERMINALNAME_MINLENGTH;
    this.nameMaxLength = CCTerminal.CREDITCARDTERMINALNAME_MAXLENGTH;

    this.folioMinLength = CCTerminal.CREDITCARDTERMINALFOLIO_MINLENGTH;
    this.folioMaxLength = CCTerminal.CREDITCARDTERMINALFOLIO_MAXLENGTH;
    this.folioMinValue = CCTerminal.CREDITCARDTERMINALFOLIO_MINVALUE;
    this.folioMaxValue = CCTerminal.CREDITCARDTERMINALFOLIO_MAXVALUE;
    this.storeIdMinLength = CCTerminal.CREDITCARDTERMINALSTOREID_MINLENGTH;
    this.storeIdMaxLength = CCTerminal.CREDITCARDTERMINALSTOREID_MAXLENGTH;

  }

  async onAction(e) {
    this.actionButton.disabledproperty = true;
    const clickReturnValue = {
      from: this.dataInput.datarecord.mode,
      formValues: this.form.value
    };
    if (!clickReturnValue.formValues.isActive && clickReturnValue.formValues.isDefault) {
      this.retailUtilities.showAlert(this.captions.alert_defaultCCTerminalInactive, AlertType.Warning, ButtonType.Ok, res =>{
        this.form.controls['isActive'].markAsPristine();
        this.form.controls['isActive'].markAsUntouched();
        this.form.controls['isActive'].setValue(true);
      });
      return;
    }
    if (clickReturnValue.formValues.isDefault) {
      let defaultTerminal = await this.GetDefaultCCTerminal();
      if (defaultTerminal && defaultTerminal.id != clickReturnValue.formValues.id) {
        this.retailUtilities.showAlert(this.captions.alert_DefaultCCTerminalAlreadyExists, AlertType.Warning, ButtonType.YesNo, res => {
          if (res === AlertAction.YES) {
            this.dialogRef.close(clickReturnValue);
          }
        });
      }
      else {
        this.dialogRef.close(clickReturnValue);
      }
    }
    else {
      this.dialogRef.close(clickReturnValue);
    }
  }

  async isDefaultCCTerminalExists() {
    return await this.creditCardService.isDefaultCCTerminalExists();
  }

  async GetDefaultCCTerminal()
  {
    return await this.creditCardService.GetDefaultCCTerminal()
  }

  onCancel(e) {
    this.dialogRef.close(undefined);
  }

  close(e) {
    this.dialogRef.close(undefined);
  }

}
