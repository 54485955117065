export const CategoryIconName: any = (GridValue: any): any => {
    let returnValue = {
        'IconName': '',
        'IconToolTip': ''
    };
    const PropertyNames = ['receivingdetailsingredient', 'ReceivingDetailsIngredient', 'podetailingredientid',
    'PODetailIngredientId', 'ReqIngredients', 'IngredientTypes', 'PRDetailIngredientId', 'reqingredients', 'prdetailingredientid',
    'ingredients', 'Ingredients', 'PhyDetailIngredientId'];
    if ((GridValue.hasOwnProperty('LinkTypeId')  && GridValue.LinkTypeId !== null &&
         GridValue.LinkTypeId !== undefined ) || (GridValue.hasOwnProperty('linktypeid') &&
        GridValue.linktypeid !== null && GridValue.linktypeid !== undefined)) {
        returnValue = CatIconName(GridValue);        
     } else if ( GridValue.hasOwnProperty('DivisionId') && GridValue.DivisionId !== null) {
        if (GridValue.DivisionId === 1 || GridValue.divisionid === 1) {
            returnValue.IconName = 'icon-Food-and-beverage-eatec';
            returnValue.IconToolTip = 'Food-and-beverage';
        } else if (GridValue.DivisionId === 2 || GridValue.divisionid === 2) {
            returnValue.IconName = 'icon-Retail-eatec';
            returnValue.IconToolTip = 'Retail';
        }
     } else {
        PropertyNames.forEach((x: any) => {
            if (GridValue.hasOwnProperty(x) && GridValue[x] !== null) {
                returnValue = CatIconName(GridValue[x]);
            }
        });
     }
    return returnValue;
};

function CatIconName(GridValue: any): any {
    const returnValue = {
        'IconName': '',
        'IconToolTip': ''
    };
    if (GridValue && ((GridValue.LinkTypeId === 1 || GridValue.linktypeid === 1) &&
        (GridValue.DivisionId === 1 || GridValue.divisionid === 1))) {
            returnValue.IconName = 'icon-Food-and-beverage-eatec';
            returnValue.IconToolTip = 'Food-and-beverage-eatec';
        } else if (GridValue && ((GridValue.LinkTypeId === 1 || GridValue.linktypeid === 1) &&
        (GridValue.DivisionId === 2 || GridValue.divisionid === 2))) {
            returnValue.IconName = 'icon-Retail-eatec';
            returnValue.IconToolTip = 'Retail';
        } else if (GridValue && (GridValue.LinkTypeId === 2 || GridValue.linktypeid === 2)) {
            returnValue.IconName = 'icon-Recipe-eatec';
            returnValue.IconToolTip = 'Recipe';
        } else if (GridValue && (GridValue.LinkTypeId === 5 || GridValue.linktypeid === 'Product'
        || GridValue.linktypeid === 5)) {
            returnValue.IconName = 'icon-Product-eatec';
            returnValue.IconToolTip = 'Product';
        } else if ( GridValue.hasOwnProperty('DivisionId') && GridValue.DivisionId !== null) {
            if (GridValue.DivisionId === 1 || GridValue.divisionid === 1) {
                returnValue.IconName = 'icon-Food-and-beverage-eatec';
                returnValue.IconToolTip = 'Food-and-beverage';
            } else if (GridValue.DivisionId === 2 || GridValue.divisionid === 2) {
                returnValue.IconName = 'icon-Retail-eatec';
                returnValue.IconToolTip = 'Retail';
            }
        }
    return returnValue;  
}