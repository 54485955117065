import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { MedicalConditions } from '../../../shared/business/view-settings.modals';
import { SPAConfig } from '../../../core/config/SPA-config';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
@Component({
  selector: 'app-medical-condition',
  templateUrl: './medical-condition.component.html',
  styleUrls: ['./medical-condition.component.scss']
})
export class MedicalConditionComponent implements OnInit {

  clickbutton: any;
  @Input() dataInput;
  @Input() popupConfigs: popupConfig;

  sgDataInput: any = [];
  flagStatus: any;
  blnReadOnly: boolean;
  FormGrp: UntypedFormGroup;

  //translated placeholders
  tCode: string = this.spaConfig.captions.setting.ConditionCode;
  tPrivate: string = this.spaConfig.captions.setting.PrivateCondition;
  tDesc: string = this.spaConfig.captions.setting.MedicalDesc;
  tWarn: string = this.spaConfig.captions.setting.ConditionWarning;

  //translated validation error msgs
  vCode: string = this.spaConfig.captions.setting.MissingConditionCode;
  vDesc: string = this.spaConfig.captions.setting.MissingConditionDescription;
  vWarn: string = this.spaConfig.captions.setting.MissingConditionWarning;

  captions: any = this.spaConfig.captions;
  isUserAuthorized = true;
  isViewOnly = true;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(public dialogRef: MatDialogRef<MedicalConditionComponent>,
    private Form: UntypedFormBuilder, private http: HttpServiceCall,
    private vsBusiness: ViewSettingClientBusiness, private spaConfig: SPAConfig,
     private utils: SpaUtilities, private BP: BreakPointAccess,public localization: SpaLocalization) {
      this.floatLabel = this.localization.setFloatLabel;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingMedicationCondition]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingMedicationCondition);
    if (this.isViewOnly) {
      this.utils.disableControls(this.FormGrp);
    }
  }
  onNoClick(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }
  ngOnInit() {


    this.FormGrp = this.Form.group({
      code: ['', Validators.required],
      privateCondition: false,
      description: ['', [Validators.required,EmptyValueValidator]],
      warning: ['']
    });
    this.saveButton = {
      type: 'primary',
      label: this.popupConfigs.operation == "create" ? this.captions.setting.save : this.captions.setting.update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.setting.cancel,
    }
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.ValidateBreakPoint();
    this.clickbutton = typeof this.dataInput === 'undefined' ? this.captions.setting.save : this.captions.setting.update;
    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.captions.setting.save;
      this.blnReadOnly = false;
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.captions.setting.update;
      this.blnReadOnly = true;
    }

    if (typeof this.dataInput !== 'undefined') {
      this.FormGrp.patchValue(this.dataInput);
    }
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.FormGrp.valid && this.FormGrp.dirty);
      this.saveButton = { ...this.saveButton };
    });
  }
  saveGroupCode() {
    this.saveButton.disabledproperty = true;
    const _code = this.FormGrp.controls['code'].value;
    const _isprivate = this.FormGrp.controls['privateCondition'].value;
    const _desc = this.FormGrp.controls['description'].value;
    const _warning = this.FormGrp.controls['warning'].value;
    const bodyObj: MedicalConditions = { code: _code, privateCondition: _isprivate, description: _desc, warning: _warning }

    if (this.popupConfigs.operation == "create") {
      this.createMedicalCondition(bodyObj);
    } else if (this.popupConfigs.operation == "edit") {
      this.updateMedicalCondition(bodyObj);
    }
  }

  createMedicalCondition(bodyObj) {
    this.http.CallApiWithCallback<MedicalConditions[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: "CreateMC",
      method: HttpMethod.Post,
      body: bodyObj,
      showError: true,
      extraParams: [bodyObj]
    });
  }


  updateMedicalCondition(bodyObj) {
    let patchJson = [];
    const patchData = this.utils.getEditedData(this.FormGrp, "code")
    patchJson = patchData.patchJson;
    this.http.CallApiWithCallback<MedicalConditions[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: "UpdateMC",
      method: HttpMethod.Patch,
      uriParams: patchData.key,
      body: patchJson,
      showError: true,
      extraParams: [bodyObj]
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateMC" || callDesc == "UpdateMC") {
      this.saveButton.disabledproperty = false;
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateMC" || callDesc == "UpdateMC") {
      this.saveButton.disabledproperty = false;
      if (error) {
        error["NewData"] = extraParams[0];
      }
    }
  }

}




