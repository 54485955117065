import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';

export interface DropDownDataModel {
    Id: number;
    Value: string;
}
@Component({
  selector: 'lib-dropdown-editor',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridDropDownEditorComponent implements OnInit, ICellEditorAngularComp {
  textValue: string;
  params: any;
  value: any;
  defaultValue: any;
  dropdown = new FormControl();
  gridDropDownParams: any;
  dropDownList: Array<DropDownDataModel> = [];
  displayValue: string;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    console.log('Text Editor', params);
    this.displayValue = params.value;
    this.gridDropDownParams = params;
    if (params.hasOwnProperty('addAdditionalList') && params.addAdditionalList) {
        const UnitArray = [];
        if (params.data.Units && params.data.Units.length > 0) {
          params.data.Units.forEach(unit => {
            UnitArray.push({
              Id: unit.UnitId + '_' + unit.UnitTypeId,
              Value: unit.UnitName + ` (${unit.UnitTypeName})`
            });
            params.dataList = params.dataList.filter(x => x.Id !== unit.UnitId);
          });
        }
        params.dataList = UnitArray.concat(params.dataList);
    }
    this.dropDownList = params.dataList;
    const setDefaultValue = params.dataList.filter(x => x.Value === params.value);
    if ( setDefaultValue && setDefaultValue.length > 0 ) {
        this.dropdown.setValue(setDefaultValue[0].Id);
    }
  }

  init(params) {
    this.gridDropDownParams = params;
  }

  focusIn() {
    const selectEditor = document.getElementById('dropdown-' + this.params.colDef.field);
    if (selectEditor) {
      selectEditor.focus();
    }
  }

  dropdownChangeEvent($event: any): void {
      try {
          const getId = $event.target.value;
          if ( this.dropDownList &&  this.dropDownList.length > 0) {
            this.displayValue = this.dropDownList.filter(x => x.Id.toString().toLowerCase() === getId.toString().toLowerCase())[0].Value;
            this.gridDropDownParams.data[this.gridDropDownParams.UpdatedProperty] =
            this.dropDownList.filter(x => x.Id.toString().toLowerCase() === getId.toString().toLowerCase())[0].Id;
          }
        console.log($event);
      } catch (error) {
          console.error(error);
      }
  }

  getValue(): any {
    return this.displayValue;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  textChanged() {
    console.log('Text Changed', this.textValue);
  }
}
