import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';

@Injectable()
export class DialogFooterService {
    captions: any;
    constructor(private _localization: Localization) { }
    getCaptions() {
        this.captions = this._localization.captions;
        return this.captions;
    }
}