import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridInterface } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface';
import { CustomFilterNavigationUrl } from '../../../../setup-master/shared/interface/master.interface';
import {
    GridFilterAction, GridFilterData, GridFilterValue, LookUpConfiguration} from '../transaction.interface';
import { GridFilterLookUp } from './create-transaction-config.interface';
export const GridLookUpFilter: GridFilterLookUp = (
    ItemLookUPConfig: LookUpConfiguration, ViewHeaderDetailEntity, httpService: HttpService): GridFilterAction[] => {
    const GridFilterRowData = [];
    try {
        if (ViewHeaderDetailEntity && ItemLookUPConfig) {       
                if (ItemLookUPConfig.GridConfiguration.GridFilter.length > 0) {
                    ItemLookUPConfig.GridConfiguration.GridFilter.forEach((filter: GridFilterAction) => {
                        const navigationProp = {} as CustomFilterNavigationUrl;
                        const Navigation = ViewHeaderDetailEntity[0]['NavigationProperty']
                            .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] === filter.FilterOdataKey);
                        if (Navigation.length > 0) {
                            ViewHeaderDetailEntity[0]['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
                                const NavigationProperityName = NavigationProperty['$']['Name'];
                                if (NavigationProperityName === filter.FilterOdataKey) {
                                    let NavigationReferentialConstraint = '';
                                    if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                                        NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                                    }
                                    const EntityNameList = NavigationProperty['$']['Type'].split('.');
                                    let EntityName = '';
                                    if (EntityNameList.length > 0) {
                                        EntityName = EntityNameList[EntityNameList.length - 1];
                                        navigationProp.RequestUrl = `/inventory/api/${EntityName}`;
                                        navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                                        navigationProp.EnabledCustomField = false;
                                    }
                                }
                            });
                        } else {
                            if (filter && filter.hasOwnProperty('CustomFilters') &&
                                filter.CustomFilters.EnableCustomFilter) {
                                navigationProp.RequestUrl = filter.CustomFilters.CustomNavigationUrl;
                                navigationProp.EnabledCustomField = true;
                                navigationProp.NavigationContraintName = filter.FilterOdataKey;
                            }
                        }
                        if (navigationProp && navigationProp.RequestUrl !== null && navigationProp.RequestUrl !== null) {
                            httpService.GethttpMethod(navigationProp.RequestUrl).subscribe((response: any) => {
                                if (response && response.value && response.value.length > 0) {
                                    const filterKeyData = response.value.map((data: any) => {
                                        const filterKey = {} as GridFilterValue;
                                        filterKey.FilterId = GridInterface.GridFilterKeyMapping(filter, data);
                                        filterKey.FilterName = GridInterface.GridFilterLabelMapping(filter, data);
                                        filterKey.Checked = false;
                                        return filterKey;
                                    });
                                    const filterDisplay = {} as GridFilterData;
                                    filterDisplay.FilterDisplayName = filter.FilterDisplayName;
                                    filterDisplay.FilterKey = filter.FilterDisplayName;
                                    filterDisplay.FilterValue = filterKeyData;
                                    filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                    filterDisplay.FilterOdataKey = filter.FilterOdataKey;
                                    filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                    filterDisplay.Type = 'multiselect';
                                    GridFilterRowData.push(filterDisplay);
                                }
                                ItemLookUPConfig.GridConfiguration.GridActions.FilterConfig.EnableFilter
                                    = GridFilterRowData.length > 0 ? true : false;
                                ItemLookUPConfig.GridConfiguration
                                ['GridActions']['FilterConfig']['FilterOptions'] = GridFilterRowData;
                            },
                                (responseError) => {
                                    console.error('error caught in component', responseError);
                                });
                        }
                    });

                }    
        }
        return GridFilterRowData;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};