import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import moment from 'moment';
import { GlobalDateFormatter } from '../models/formater.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const GlobalDateFormaters: GlobalDateFormatter = (value: any, utcFormat = false): any => {
    try {
        const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
        const DateTimeFormat = ( sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null ) ? 
        sessionDateFormat :  constants.DefaultIniDateFormat;
        if (utcFormat) {
            return MomentUtcDateFormat(DateTimeFormat, value);
        } else {
            return  MomentDateFormat(DateTimeFormat, value);
        }
    } catch (error) {
        console.log('error occured GlobalDateFormatter', error);
    }
};

function MomentDateFormat(IniDateFormat: string, DateValue: any ): any {
    try {
        const momentDate = moment(DateValue, IniDateFormat);
        if (momentDate.isValid()) {
            const IniConvertDate = momentDate.toDate();
            return IniConvertDate;
        } else {
            return null;
        }
    } catch ( error ) {
        console.error(error);
    }
}

function MomentUtcDateFormat(IniDateFormat: string, DateValue: any ): any {
    try {
        if ( DateValue && DateValue !== '' && DateValue !== null ) {
            const AlreadyFormatted = moment(DateValue, IniDateFormat).format(IniDateFormat) === DateValue;
            if ( AlreadyFormatted ) {
               return DateValue;
            } else {
                const IniUtcConvertDate = moment(DateValue).format(IniDateFormat); 
                return IniUtcConvertDate;
            }
        } else {
            return '';
        }
    } catch ( error ) {
        console.error(error);
    }
}