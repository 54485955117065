import { Component, OnInit, Input, Output, ViewEncapsulation, OnChanges, EventEmitter } from '@angular/core';
import { DropDownData } from '../business/report.modals';
import { ReportBusinessService } from '../business/report-business.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { sortPipe } from '../../shared/pipes/sort-pipe.pipe';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';


@Component({
  selector: 'app-retail-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  @Input() dropDownControlname: string;
  @Input() dropDownName: string;
  @Input() dropDownFilterData;
  @Input() reportName: string;
  @Input() selectedData;
  sortPipe: sortPipe;
  @Input()
  set activeToggle(value) {
this.valuesSelected = [];
  }
  @Output() dropDownFrmControl: EventEmitter<any> = new EventEmitter<any>();
  @Output() IsAnySelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() categoryGroup : EventEmitter<any> = new EventEmitter<any>();
  selectedDropdownType = '';
  valuesSelected: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: this.localization.captions.common.all,
    showInDropDown: true
  };
  dropDownFrmGrp: UntypedFormGroup;
  floatLabel: string;

  constructor(public business: ReportBusinessService, public localization: RetailLocalization, private utils: RetailUtilities) { 
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.sortPipe = new sortPipe(this.utils);
  }
  ngOnChanges() {
    this.dropDownFrmGrp = new UntypedFormGroup({
      [this.dropDownControlname]: new UntypedFormControl([])
    }); 
    if (this.selectedData) {
      this.dropDownFrmGrp.controls[this.dropDownControlname].setValue(this.selectedData);
    } else {
      this.dropDownFrmGrp.controls[this.dropDownControlname].setValue(this.dropDownFilterData);
    }
    
 
    this.dropDownFrmControl.emit([this.dropDownControlname, <UntypedFormControl>this.dropDownFrmGrp.controls[this.dropDownControlname]]);
    if(this.dropDownName.toLowerCase().includes('outlet')){
      if(this.sortPipe){
        this.sortPipe.sorting(this.dropDownFilterData,'asc','description');
      }
    }

  }

  filterDropDownSelected(event: any, data: DropDownData, allData: DropDownData[], dropDownType) {

    if (this.reportName !== this.selectedDropdownType) {
      this.valuesSelected = allData.map(x=>x);
    }
    if (data && data.code && data.code.toLowerCase() === 'all') {
      this.valuesSelected = this.business.toggleClickbtn(this.defaultData, allData, this.valuesSelected, this.defaultData, event.checked);
    } else {
      this.valuesSelected = this.business.toggleClickbtn(data, allData, this.valuesSelected, this.defaultData);
    }
    this.dropDownFrmGrp.controls[dropDownType].setValue(this.valuesSelected);
    this.selectedDropdownType = this.reportName;
    this.IsAnySelected.emit(this.valuesSelected.length > 0);
    if(dropDownType == "reportcategory")
    {
        this.categoryGroup.emit(this.valuesSelected);
    }

  }
}
