import { Component, OnInit, Input, } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { PhoneNumberValidator } from './ag-phone-number-validator';
import { PhoneTypes, ContactType } from '../../enums';
import { AgContactConfig, AgPhoneDetail } from '../../models/ag-models';
import * as CONSTANTS from '../../constants';

@Component({
  selector: 'app-spa-ag-phone-number',
  templateUrl: './ag-phone-number.component.html',
  styleUrls: ['./ag-phone-number.component.scss']
})
export class AgPhoneNumberComponent implements OnInit {
  customClass: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  typeControlName: string;
  typeClass: string;
  options: any[];
  arrayName: string;
  textMaskPhone: string;
  phoneTypes = PhoneTypes;
  captions: any;
  textMaskExtension: string;
  showSwitches: boolean;
  primarySwitchName: string;
  privateSwitchName: string;
  extensionClass: string;
  extensionPlaceHolder: string;
  duplicateError: string;
  extensionLength: number;
  primaryLabel: string;
  privateLabel: string;
  phoneLength: number;
  isPhoneDisabled: boolean;

  @Input('inputs')
  set inputOptions(value: AgContactConfig) {
    this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.err_invalidPhone;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.lbl_phone;
    this.extensionPlaceHolder = value.extnPlaceHolder ? value.extnPlaceHolder : this.captions.lbl_extension;
    this.controlName = value.formControlName ? value.formControlName : 'phoneNumber';
    this.typeControlName = value.typeControlName ? value.typeControlName : 'phoneType';
    this.customClass = value.className ? value.className : 'ag_form-control--md';
    this.typeClass = value.typeClass ? value.typeClass : 'ag_form-control--xs';
    this.extensionClass = value.extensionClass ? value.extensionClass : 'ag_form-control--md';
    this.primaryLabel = value.primarySwitchLabel ? value.primarySwitchLabel : this.captions.lbl_PrimaryPhone;
    this.privateLabel = value.privateSwitchLabel ? value.privateSwitchLabel : this.captions.lbl_PrivateInformation;
    this.isPhoneDisabled = value.disabled ? value.disabled : false;
    this.showSwitches = value.showSwitches;
    if (this.showSwitches) {
      this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryPhone';
      this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionPhone';
    }

    this.contactForm = value.form;
    this.arrayName = value.formArrayName;

    if (!this.arrayName) {
      this.contactForm.get(this.controlName).disable();
    } else {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.controls.forEach((obj) => {
        if (!obj.value[this.typeControlName]) {
          obj.get(this.controlName).disable();
        }
      });
    }
  }

  @Input('valuePatched')
  set ControlState(_value: boolean) {
    const contactControl = this.contactForm.get(this.controlName);
    contactControl.setValidators(PhoneNumberValidator);
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.typeControlName).value) {
      contactControl.enable();
    } else {
      contactControl.disable();
    }
  }

  @Input('values')
  set SetFormArray(value: AgPhoneDetail[]) {
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.phoneFieldDisabler(this.isPhoneDisabled);
    }
  }

  @Input() isViewOnly = false;

  constructor(private fb: UntypedFormBuilder, private localization: SpaLocalization) {
    this.captions = this.localization.captions;
    this.duplicateError = this.captions.duplicatePhone;
  }

  ngOnInit() {
    this.textMaskPhone = this.maskPhoneNo();
    this.textMaskExtension = this.maskExtension();
    this.extensionLength = 5;
    this.options = this.getPhoneOptions();
    this.getValidators();
  }

  ngOnChanges(){
    if(this.arrayName){
      this.phoneFieldDisabler(this.contactForm.get(this.arrayName).disabled);
    }
  }

  phoneFieldDisabler(isDisable){
    let phoneField = this.contactForm.get(this.arrayName);
    if(isDisable){
      this.isPhoneDisabled = true;
      phoneField['controls'].forEach(element => {
        element.disable();
      });
    }
  }

  checkValidity(_e, i) {
    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const contactControl = phone.controls[i].get(this.controlName);
    this.checkPhoneControlValidity(contactControl);
  }

  checkValiditySinglePhone(_e) {
    const contactControl = this.contactForm.get(this.controlName);
    this.checkPhoneControlValidity(contactControl);
  }

  checkPhoneControlValidity(contactControl) {
    const val = contactControl.value;
    const res = val.replace(/\D/g, '');
    if (contactControl.dirty && (res.length < this.phoneLength) && (res.length !== 0)) {
      contactControl.setErrors({ incorrect: true });
    }
  }

  getValidators() {
    const lengthValidators = this.getPhoneValidator();
    this.phoneLength = lengthValidators.phoneLength;
  }

  addPhoneArray(index, phoneLabel, phoneNumber, extn, isPrimary?, isPrivate?,isSynced?): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.phone,
      extension: extn,
      id: index,
      isSynced: isSynced
    });
    currentForm.addControl(this.typeControlName, new UntypedFormControl(phoneLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: phoneNumber, disabled: !phoneLabel }, PhoneNumberValidator));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isPrimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    return currentForm;
  }
  addPhoneItem(index, phoneLabel: string,
    phoneNumber: string | number,
    extn: string | number,
    primaryPhone: string | boolean,
    privateInformtionPhone: string | boolean,
    isSynced: string| boolean): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.push(this.addPhoneArray(index, phoneLabel, phoneNumber, extn, primaryPhone, privateInformtionPhone,isSynced));
    }
  }

  removePhoneItem(index: number): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      this.contactForm.markAsDirty();
      phone.removeAt(index);
    }
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const ctrls = arr.controls.filter((_x, idx) => idx !== formGroupName);
    ctrls.forEach(x => {
      const grp = x as UntypedFormGroup;
      grp.controls[this.primarySwitchName].setValue(false);
    });
  }

  typeChange(arg, formGroup: UntypedFormGroup) {
    formGroup.get(this.controlName).enable();
    if (arg.value === this.phoneTypes.office && !formGroup.get('extension')) {
      formGroup.addControl('extension', new UntypedFormControl());
    }
    else {
      if (formGroup.get("extension")) {
        formGroup.get("extension").setValue("");
      }
    }
  }

  private patchOrAdd(value) {
    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
    value.forEach((obj, idx) => {
      const group = phone.controls[idx] as UntypedFormGroup;
      if (group) {
        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.phone));
        }
        if (obj && obj[this.typeControlName] === this.phoneTypes.office) {
          group.addControl('extension', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
      } else {
        const id = obj ? obj.id : '';
        const phoneType = obj ? obj[this.typeControlName] : '';
        const phoneNumber = obj ? obj[this.controlName] : '';
        const extension = obj ? obj.extension : '';
        const primaryPhone = obj ? obj.primaryPhone : false;
        const privateInformtionPhone = obj ? obj.privateInformtionPhone : false;
        const isSynced = obj? obj.isSynced:false;
        this.addPhoneItem(id, phoneType, phoneNumber, extension, primaryPhone, privateInformtionPhone,isSynced);
      }
    });
  }

  private maskPhoneNo(): string {
    return this.captions.PhoneFormat ?
      this.captions.PhoneFormat : '999999999999999999';
  }

  private maskExtension(): string {
    return this.captions.ExtensionFormat ?
      this.captions.ExtensionFormat : '9999999';
  }

  private getPhoneOptions() {
    return [
      { id: this.phoneTypes.home, description: this.captions.drp_txt_home, type: ContactType.phone },
      { id: this.phoneTypes.office, description: this.captions.drp_txt_office, type: ContactType.phone },
      { id: this.phoneTypes.mobile, description: this.captions.drp_txt_mobile, type: ContactType.phone }
    ];
  }

  getPhoneValidator() {
    return {
      phoneLength: CONSTANTS.PHONE_LENGTH
    };
  }
}
