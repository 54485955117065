import { Component, OnInit,  Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { TableOptions, TableHeaderOptions, ButtonValue } from 'src/app/common/Models/ag-models';
import {of } from 'rxjs';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { AlertType, AlertAction } from 'src/app/common/shared/shared.modal';
import { ActionMode, ButtonType } from 'src/app/common/enums/shared-enums';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-fiscal-period',
  templateUrl: './fiscal-period.component.html',
  styleUrls: ['./fiscal-period.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiscalPeriodComponent implements OnInit {

  captions: any;
  createFiscalPeriodForm: UntypedFormGroup;
  fiscalPeriodProcessForm:UntypedFormGroup;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent=null;
  editContent = null;
  defaultSelectedYear: number;
  isViewOnly: boolean;
  @Output() handleTableAction = new EventEmitter();
  @Output() addPeriodAction = new EventEmitter();
  @Output() closeAction = new EventEmitter();
  @Output() processAction = new EventEmitter();
  @Output() eventAction = new EventEmitter();
  header: string;
  IsEditModeEnabled = false;
  showSlide : boolean = false;
  sliderInputMode: any;
  actionButton: ButtonValue;
  actionButton2: ButtonValue;
  minLength = 4;
  maxLength = 4;
  AddYear = 0;
  yearsList;
  periodNumberList;
  viewOnly: boolean;
  @Input('headerOptions')
  set setHeaderOptions(value){
    this.headerOptions = value;
  }

  @Input('tableOptions')
  set setTableOptions(value){
    this.tableOptions = value;
  }

  @Input('tableContent')
  set setTableContent(value){
    this.tableContent = value;
  }

  @Input('showSlide')
  set setShowSlide(value){
    this.showSlide = value;
  }

  @Input('IsEditModeEnabled')
  set setEditMode(value){
    this.IsEditModeEnabled = value;
  }

  @Input('header')
  set setSliderHeader(value){
    this.header = value;
  }

  @Input('sliderInputMode')
  set setsliderInput(value){
    this.sliderInputMode = value;
  }

  @Input('periodNumberList')
  set setPeriodList(value){
    this.periodNumberList = value;
  }

  @Input('yearsList')
  set setYearList(value){
    this.yearsList = value;
  }

  @Input('editContent')
  set setEditContent(value){
    this.editContent = value;
  }

  @Input('defaultSelectedYear')
  set setdefaultSelectedYear(value){
    this.defaultSelectedYear = value;
    if(this.fiscalPeriodProcessForm != undefined){
      this.fiscalPeriodProcessForm.controls['year'].patchValue(this.defaultSelectedYear);
    }
  }

  @Input('viewOnly')
  set setViewOnly(value){
    this.viewOnly = value;
    this.actionButton != undefined ? this.actionButton.disabledproperty = this.viewOnly : '';
    this.actionButton2 != undefined ? this.actionButton2.disabledproperty = this.viewOnly : '';
  }

  constructor(
    private formbuilder: UntypedFormBuilder,
    private utilities: CommonUtilities,
    private localization: Localization,) {
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_addPeriod,
      disabledproperty: this.viewOnly
    };
    this.actionButton2 = {
      type: 'primary',
      label: this.captions.lbl_process,
      disabledproperty: this.viewOnly
    };
    this.fiscalPeriodProcessForm = this.formbuilder.group({
      year:[this.defaultSelectedYear,Validators.minLength(4)]
    })
  }
  
  ProcessYearChange(e){

  }

  buttonOnlyHeaderEvent(e){
    this.addPeriodAction.emit(e)
  }

  buttonOnlyHeaderEventProcess(e){
   
    this.processAction.emit(this.fiscalPeriodProcessForm.value.year);
  }

  tableAction(eve){
    this.handleTableAction.emit(eve)
  }

  closeSlide(){
    this.closeAction.emit()
  }

  handlePeriodEvent(eve){
    this.eventAction.emit(eve)
  }

  formStatusChangeEvent(eve){
    let yearValue = this.fiscalPeriodProcessForm.value.year ;
    this.actionButton2.disabledproperty = (!yearValue || yearValue <1000 || yearValue>9999);
  }

}
