import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ToastrService } from 'ngx-toastr';
import { CreateTransaction } from '../create-transcation';
import { HeaderConfiguration, TransactionConfiguration } from '../transaction.interface';
import { ViewTransactionConfiguration } from '../view-transaction/view-configuration.interface';
import { transactionButtonConfig } from './button-config.interface';

export const ViewtransButtonCallBack: transactionButtonConfig = (
    postModelHederEntity: any,
    postModelDetailEntity: any,
    buttonCallBackValue: CallBackData,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    selectedTransaction: any,
    selectedId: number,
    homeGroup: FormGroup,
    routerNavigateService: RouteNavigateService,
    router: ActivatedRoute,
    toastrService: ToastrService,
    GlobalOdataModel: any,
    transactionFrom: any,
    dialog: MatDialog,
    translateService?: TranslateService
): void => {
    try {
        if (buttonCallBackValue) {
            const buttonKey = buttonCallBackValue.buttonValue.buttonKey;
            // const MessageName = TransactionCollection.Name.toLowerCase();
            switch (buttonKey) {
                case 'confirm':
                    const queryParams = { queryParams: { transaction: selectedTransaction } };
                    const routerUrl = `/settings/enhancedInventory/transaction/create-${selectedTransaction.toLowerCase()}`;
                    routerNavigateService.navigateRoute(routerUrl, queryParams);
                    break;
                case 'vendornewprequest':
                    const queryParamsPR = { queryParams: { transaction: 'allpurchaserequest' } };
                    const routerUrlPR = 'transaction/create-allpurchaserequest';
                    routerNavigateService.navigateRoute(routerUrlPR, queryParamsPR);
                    break;
                case 'vendornewpo':
                    const queryParamsPo = { queryParams: { transaction: 'purchaseorder' } };
                    const routerUrlPo = 'transaction/create-purchaseorder';
                    routerNavigateService.navigateRoute(routerUrlPo, queryParamsPo);
                    break;
                case 'newtemplate':
                    const transaction = 'template';
                    const queryTemplateParams = { queryParams: { transaction: transaction } };
                    const routerTemplateUrl = `transaction/create-${transaction.toLowerCase()}`;
                    routerNavigateService.navigateRoute(routerTemplateUrl, queryTemplateParams);
                    break;
                case 'newrequisition':
                    const req = 'requisition';
                    const queryReqParams = { queryParams: { transaction: req } };
                    const routerReqUrl = `transaction/create-${req.toLowerCase()}`;
                    routerNavigateService.navigateRoute(routerReqUrl, queryReqParams);
                    break;
                case 'confirm-sales':
                    const sale = 'sales';
                    const querySalesParams = { queryParams: { sales: sale } };
                    const routersaleUrl = `sales-management/create-${sale.toLowerCase()}`;
                    routerNavigateService.navigateRoute(routersaleUrl, querySalesParams);
                    break;
                case 'confirm-salesforecasting':
                    const salesforecasting = 'salesforecasting';
                    const querysParams = { queryParams: { sales: salesforecasting } };
                    const routersUrl = `sales-management/create-${salesforecasting.toLowerCase()}`;
                    routerNavigateService.navigateRoute(routersUrl, querysParams);
                    break;
                case 'confirm-cycleplanning':
                    const cycleplanning = 'cycleplanning';
                    const querycParams = { queryParams: { sales: cycleplanning } };
                    const routercUrl = `sales-management/create-${cycleplanning.toLowerCase()}`;
                    routerNavigateService.navigateRoute(routercUrl, querycParams);
                    break;
                case 'confirm-productionplan':
                    const productionplan = 'productionplan';
                    const params = { queryParams: { sales: productionplan } };
                    const Url = `sales-management/create-${productionplan.toLowerCase()}`;
                    routerNavigateService.navigateRoute(Url, params);
                    break;
                case 'confirm-mrp':
                    const mrp = 'mrp';
                    const mrpParams = { queryParams: { sales: mrp } };
                    const mrpUrl = `sales-management/create-${mrp.toLowerCase()}`;
                    routerNavigateService.navigateRoute(mrpUrl, mrpParams);
                    break;
                case 'confirm-master':
                    const queryParamsMaster = { queryParams: { master: selectedTransaction } };
                    const routerUrlMater = `inventory-management/create-${selectedTransaction.toLowerCase()}`;
                    routerNavigateService.navigateRoute(routerUrlMater, queryParamsMaster);
                    break;
                case 'piadjusment':
                    const piQueryParams = { queryParams: { transaction: buttonKey } };
                    const piRouterUrl = `/settings/enhancedInventory/transaction/create-${selectedTransaction.toLowerCase()}`;
                    routerNavigateService.navigateRoute(piRouterUrl, piQueryParams);
                    break;
                case 'pientercounts':
                    const piQueryParam = { queryParams: { transaction: buttonKey } };
                    const piRouterUrls = `/settings/enhancedInventory/transaction/create-${selectedTransaction.toLowerCase()}`;
                    routerNavigateService.navigateRoute(piRouterUrls, piQueryParam);
                    break;
                case 'pientercyclecounts':
                    const piParam = { queryParams: { transaction: buttonKey } };
                    const piRouteUrls = `/settings/enhancedInventory/transaction/create-${selectedTransaction.toLowerCase()}`;
                    routerNavigateService.navigateRoute(piRouteUrls, piParam);
                    break;
                case 'piprint':
                    const piprint = { queryParams: { transaction: buttonKey } };
                    const piprintUrl = `/settings/enhancedInventory/transaction/create-${selectedTransaction.toLowerCase()}`;
                    routerNavigateService.navigateRoute(piprintUrl, piprint);
                    break;
                case 'savedreceiving':
                    const receiving = { queryParams: { transaction: buttonKey } };
                    const receivingUrl = `/settings/enhancedInventory/transaction/view-${buttonKey.toLowerCase()}`;
                    routerNavigateService.navigateRoute(receivingUrl, receiving);
                    break;
                case 'processoptions':
                    CreateTransaction.ProcessOptions(TransactionCollection, httpService, transactionService,
                        null, postModelHederEntity, postModelDetailEntity, homeGroup, GlobalOdataModel, toastrService,
                        routerNavigateService, selectedTransaction, dialog, selectedId);
                    break;
                case 'importPI':
                    CreateTransaction.ImportPI(translateService, dialog);
                    break;
                case 'confirm-quickorder':
                    const quickorders = 'quickorders';
                    const QOquerysParams = { queryParams: { sales: quickorders } };
                    const QOroutersUrl = `sales-management/create-${quickorders.toLowerCase()}`;
                    routerNavigateService.navigateRoute(QOroutersUrl, QOquerysParams);
                    break;
                case 'null':
                    console.log('Null');
                    break;
                default:
                    break;

            }
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }

};