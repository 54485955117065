import { BackOrderRequisitions } from './backorderrequisitions.interface';
import { ZeroQtyButcheryTransaction } from './butchery-transaction.interface';
import { NoZeroQtyCyclePlanning } from './cycle-planning-post-check.interface';
import { NoZeroQuantityTransaction } from './no-zero-qty-transaction.interface';
import { PoBackOrder } from './pobackorder.interface';
import { ZeroQtyTransaction } from './zero-qty-transactions.interface';
import { ZeroCosting } from './zerocosting.interface';
import { ZeroQtyPR } from './zeroqty-pr.interface';
import { NegativeQtyWithRemarks } from './negative-qty-with-remarks.interface';

export const SystemPreferenceAction = {
    PoBackOrder: PoBackOrder,
    BackOrderRequisitions : BackOrderRequisitions,
    ZeroCosting: ZeroCosting,
    ZeroQtyPR: ZeroQtyPR,
    ZeroQtyTransaction: ZeroQtyTransaction,
    ZeroButchery: ZeroQtyButcheryTransaction,
    NoZeroQuantityTransctions: NoZeroQuantityTransaction,
    NoZeroQuantityCyclePlanning: NoZeroQtyCyclePlanning,
    NegativeQtyWithRemarks: NegativeQtyWithRemarks
  };