import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { VCartConfiguration } from 'src/app/common/Models/property-information.model';

@Component({
  selector: 'app-set-cart-timer',
  templateUrl: './set-cart-timer.component.html',
  styleUrls: ['./set-cart-timer.component.scss']
})
export class SetCartTimerComponent implements OnInit {
  buttonObj: { customSaveText: string; disabled: boolean; isEdit: boolean; };
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  captions;
  cartForm: UntypedFormGroup;
  maxHoldtime: number = 1440; // 24 hours in munutes
  totalMinutes: number=0;
  constructor(public dialog: MatDialog,private localization:Localization , private dialogRef: MatDialogRef<SetCartTimerComponent>, @Inject(MAT_DIALOG_DATA) data: {config: VCartConfiguration<any>},private fb:UntypedFormBuilder) { 
    this.captions=this.localization.captions;
    if(data.config && data.config.configValue && data.config.configValue.vcartMaxHoldTime)
    {
      let maxHoldTimeConfigValue: any=  data.config.configValue.vcartMaxHoldTime.toString().trim();
      if(!isNaN(maxHoldTimeConfigValue))
      {
        this.maxHoldtime = Number(maxHoldTimeConfigValue);
      }
    }
  }

  ngOnInit(): void {
    this.formGenerator()
    this.buttonObj = {
      customSaveText:this.captions.btn_save ,
      disabled: true,
      isEdit: false
  };

  this.cartForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
    this.buttonObj.disabled = !(this.cartForm.valid && this.cartForm.dirty);
     
    if(value.days)
    {
      this.totalMinutes = Number((value.days * 24 * 60) + (value.hours * 60)+ (value.setminutes));
    }else{
      this.totalMinutes = Number((value.hours * 60)+ (value.setminutes));
    }
   
    console.log("totalMinutes",this.totalMinutes);

    if( this.cartForm.get('days').value == null || this.cartForm.get('hours').value == null || this.cartForm.get('setminutes').value == null ||
      (this.cartForm.get('setminutes').value > 0 && this.cartForm.get('setminutes').value  > this.maxHoldtime) )
      {
          this.buttonObj.disabled = true;
      }
    this.buttonObj = { ...this.buttonObj };
  });
  }




  formGenerator()
  {
    this.cartForm=this.fb.group({
      setminutes:'',
      hours:'',
      days:''
    })
  }
  close()
  {
  this.dialogRef.close()
  }

  save(e)
  {
   this.dialogRef.close(this.totalMinutes)
  }
  ngOnDestroy() {
    if (this.destroyed$) {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}

}
