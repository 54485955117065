import { ModuleNames } from 'src/app/eatecui/source/shared/enum/global.enum';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from './construct-config.interface';
import { DefineModuleBasedObject } from './data-per-module';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { SetupConfiguration } from 'src/app/eatecui/source/setup/shared/interface/setup.interface';

export const DefineDataObject: DefineDataObjectConfig = (ModuleKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration | SetupConfiguration, LocationIds: any, TransactionService: ViewTransactionService, IsActive: boolean,
         sysytemPreferenceService: SysytemPreferenceService, requestengine?: RequestEngine, Value?: any, selectedId?: number, envService?: EnvService): any => {
    try {   
        let returnPost: any;
        const fetchAll = ( requestengine?.FetchAll) ? requestengine.FetchAll : false;
        const createRequest = new RequestEngine(fetchAll, LocationIds);
        switch (ModuleKey) {
            case ModuleNames.PlanHead:
                returnPost = DefineModuleBasedObject.
                    SalesForecasting(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest);
                break;
            case ModuleNames.CyclePlanning:
                returnPost = DefineModuleBasedObject.
                    CyclePlanning(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest);
                break;
            case ModuleNames.ProductionPlan:
                returnPost = DefineModuleBasedObject.
                    ProductionPlan(ModuleKey, commonService, JsonData as any, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest);
                break;
            case ModuleNames.MRP:
                returnPost = DefineModuleBasedObject.
                MRP(ModuleKey, commonService, JsonData, LocationIds, TransactionService, IsActive, 
                sysytemPreferenceService, createRequest);
                break;
            case ModuleNames.Butchery:
                returnPost = DefineModuleBasedObject.
                    Butchery(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest);
                break;
            case ModuleNames.DataImportSummary:
                returnPost = DefineModuleBasedObject.
                    DataImportSummary(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.DataImportUpload:
                returnPost = DefineModuleBasedObject.
                DataImportUpload(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive, 
                sysytemPreferenceService, createRequest, Value, null, envService);
                break;
            case ModuleNames.DataImportWexFlowsModule:
                returnPost = DefineModuleBasedObject.
                    DataImportWexFlowsModule(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest);
                break;
            case ModuleNames.ValidationSummaryDownload:
                returnPost = DefineModuleBasedObject.
                    ValidationSummaryDownload(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.LoadSalesHistory:
                returnPost = DefineModuleBasedObject.
                    LoadSalesHistory(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.ScheduleReport:
                returnPost = DefineModuleBasedObject.ScheduleReport(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds,
                    TransactionService, IsActive, sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.EditScheduleReport:
                returnPost = DefineModuleBasedObject.EditScheduleReport(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds,
                    TransactionService, IsActive, sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.SaveScheduleReport:
                returnPost = DefineModuleBasedObject.SaveScheduleReport(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds,
                    TransactionService, IsActive, sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.VendorPunchout:
                returnPost = DefineModuleBasedObject.
                    VendorPunchOutGet(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.Template:
                returnPost = DefineModuleBasedObject.
                    ReqTemplate(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest);
                break;
            case ModuleNames.AccountActivity:
                returnPost = DefineModuleBasedObject.
                    AccountActivityGet(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                        sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.Production:
            case ModuleNames.SalesHead:
            case ModuleNames.PackageLimitation:
                returnPost = DefineModuleBasedObject.GetPayload(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                    sysytemPreferenceService, createRequest, Value);
                break;
            case ModuleNames.SetupGridList:
            returnPost = DefineModuleBasedObject.GetPayload(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
            sysytemPreferenceService, createRequest, Value);
            break;
            case ModuleNames.CreateSetup:
                returnPost = DefineModuleBasedObject.CreateSetUp(ModuleKey, commonService, JsonData as TransactionConfiguration, LocationIds, TransactionService, IsActive,
                    sysytemPreferenceService, createRequest, Value);
                break;
        }
        return returnPost;
    } catch (error) {
        console.error('Error occurred in DefineDataObject', error);
    }
};