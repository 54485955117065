import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { RetailSetupService } from '../retail-setup.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormArray, Validators, UntypedFormBuilder } from '@angular/forms';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { RetailBreakPoint, Host } from '../../shared/globalsContant';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { AddVendorsPopupComponent } from './add-vendors-popup/add-vendors-popup.component';
import { VendorAssign } from './add-vendors-popup/add-vendors-popup.model';
import { RetailItemVendor } from '../../retail.modals';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { HttpMethod } from '../../shared/service/http-call.service';
import { RetailDataAwaiters } from '../../shared/events/awaiters/retail.data.awaiters';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';


@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InventoryComponent implements OnInit, OnDestroy {
  public selectedOutletMgrArr: any[];
  selectedOutletSubscription: Subscription;
  inventoryFormGroup: UntypedFormGroup;
  inventoryFormArr: UntypedFormArray;
  @Input() isEdit;
  @Input() useInventory;
  IsViewOnly: boolean = false;
  TempQuantity: number;
  InitProc: boolean = false;
  captions: any;
  settingDialogSubscription: Subscription;
  assignVendor: any[] = [];
  useRetailInterface: boolean = false;
  isSNC: boolean = false;
  functionalities: { [key: string]: boolean } = {};
  @ViewChild("ReasonforInventoryAdjustment") ReasonforInventoryAdjustmentField: ElementRef;
  validateBtnSubscription: Subscription;
  validateBtn: boolean;
  floatLabel: string;
  eatecEnabled: boolean = false;
  @Input() parentForm;
  throttleTime: number = 1000;

  constructor(public retailService: RetailSetupService, private dialog: MatDialog, private dialogRef: MatDialogRef<RetailPopupComponent>, public localization: RetailLocalization, private breakpoint: BreakPointAccess
    , private Form: UntypedFormBuilder, private featureFlags: RetailFeatureFlagInformationService, private PropertyInfo: RetailPropertyInformation
    , private func: RetailFunctionalityBusiness) {
    this.useRetailInterface = this.PropertyInfo.UseRetailInterface;
    this.isSNC = Number(this.PropertyInfo.GetPropertyInfoByKey('ProductId')) == 5;
    this.floatLabel = this.localization.setFloatLabel;

  }

  ngOnInit() {
    this.captions = this.localization.captions.retailsetup;
    this.inventoryFormGroup = this.Form.group({ inventoryFormArr: this.Form.array([]) });
    this.selectedOutletSubscription = this.retailService.selectedOutletMgrArr.subscribe(outlet => {
      this.selectedOutletMgrArr = outlet;
      this.ConfigureFormControl();
    });

    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == RetailBreakPoint.ItemSetup).view;
    this.retailService.inventoryItemEdited = false;
    this.eatecEnabled = this.featureFlags.IsEatecEnabled && (this.featureFlags.IsEatecConfigured ||this.featureFlags.IsRetailIcEnabled);
    this.retailService.GeneralFormGrp.get('inventory').valueChanges.subscribe(x => {
      if ((this.featureFlags.IsEatecConfigured ||this.featureFlags.IsRetailIcEnabled) && this.featureFlags.IsEatecEnabled) {
        if (this.inventoryFormArr) {
          this.inventoryFormArr.controls.forEach(form => {
            if (x) {
              form.get('UpdatedQuantity').disable();
            }
            else form.get('UpdatedQuantity').enable();
          });
        }
      }
    });
    this.validateBtnSubscription = this.retailService.validateBtn.subscribe(isValid =>
      this.validateBtn = isValid
    );
    this.retailService.ValidateUpdate();
    this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
    });
    if(this.parentForm){
      this.parentForm.addControl('inventoryFormGroup', this.inventoryFormGroup);
    }
  }

  ngOnDestroy() {
    this.selectedOutletSubscription.unsubscribe();
    if (this.settingDialogSubscription) {
      this.settingDialogSubscription.unsubscribe();
    }
    this.validateBtnSubscription.unsubscribe();
  }

  close() {
    this.dialogRef.close();
  }
  UpdatedQuantityChanged(event, index, outlet, fieldName) {
    const tempUnitCost = this.inventoryFormGroup.controls.inventoryFormArr["controls"][0].
      controls["UnitCost"].value;
    if ((!tempUnitCost || tempUnitCost === 0) && (!isNaN(parseInt(this.retailService.GeneralFormGrp.value.curitemcost)))) {
      this.inventoryFormGroup.controls.inventoryFormArr["controls"][0].
        controls["UnitCost"].setValue(this.retailService.GeneralFormGrp.value.curitemcost);
      this.selectedOutletMgrArr[index]['unitCost'] = this.localization.currencyToSQLFormat(this.retailService.GeneralFormGrp.value.curitemcost);
    }
    this.inventoryTabChanged(index, outlet, fieldName);
  }
  inventoryTabChanged(index, outlet, fieldName) {
    this.retailService.inventoryItemEdited = true;
    this.retailService.ValidateUpdate();
    if (fieldName == "reason") {
      if (outlet['controls'].ReasonforInventoryAdjustment.invalid) {
        this.retailService.inventoryItemEdited = false;
        this.retailService.ValidateUpdate();
      }
    }
    this.retailService.InventoryGrp = this.inventoryFormGroup;
    let FormControlName = {
      'updatedQuantity': 'UpdatedQuantity',
      'itemPar': 'ItemPar',
      'unitCost': 'UnitCost',
      'reason': 'ReasonforInventoryAdjustment',
      'isDisable': 'IsDisableUnitCost',
      'weightedAverageCost': 'WeightedAverageCost',
    }
    this.selectedOutletMgrArr[index][fieldName] = this.validateForm(fieldName, outlet.controls[FormControlName[fieldName]].value);
  }


  showUnitCostField(index) {
    if (this.featureFlags && this.featureFlags.IsEatecEnabled) { return true; }
    const displayUnitCost = this.inventoryFormGroup.get('inventoryFormArr')['controls'][index].controls.UpdatedQuantity.value > 0;
    return this.isEdit ?
      (this.selectedOutletMgrArr[index] && this.selectedOutletMgrArr[index].quantity > 0 || displayUnitCost) : displayUnitCost;

  }

  validateForm(fieldName, value) {
    let NumericFields = ['itemPar', 'unitCost', 'updatedQuantity']
    if (NumericFields.indexOf(fieldName) != -1) {
      if (!isNaN(parseInt(value)) && !value.includes("-")) {
        return this.localization.currencyToSQLFormat(value);
      } else {
        return null;
      }
    }
    else {
      return value;
    }
  }



  ConfigureFormControl() {
    let disableUpdateQuantity = false;

    if ((this.featureFlags.IsEatecConfigured || this.featureFlags.IsRetailIcEnabled) && this.featureFlags.IsEatecEnabled) {
      disableUpdateQuantity = this.retailService.GeneralFormGrp.get('inventory').value;
    }
    if (this.selectedOutletMgrArr.length > 0) {
      this.inventoryFormGroup = this.Form.group({ inventoryFormArr: this.Form.array([]) });
      this.selectedOutletMgrArr.forEach(element => {
        this.inventoryFormArr = this.inventoryFormGroup.get('inventoryFormArr') as UntypedFormArray;
        this.inventoryFormArr.push(this.Form.group({
          UpdatedQuantity: [{ value: null, disabled: disableUpdateQuantity }, null],
          ItemPar: [element.itemPar],
          UnitCost: [element.unitCost],
          WeightedAverageCost: [{ value: element.weightedAverageCost, disabled: true }, element.weightedAverageCost],
          ReasonforInventoryAdjustment: [null, null],
          IsDisableUnitCost: element.quantity
        }));
      });
    }
    this.retailService.InventoryGrp = this.inventoryFormGroup;
  }

  SetTempQuantity(outlet) {
    if (this.isEdit && !this.InitProc) {
      this.InitProc = true;
      this.TempQuantity = outlet.get('UpdatedQuantity').value;
    }
  }

  CheckForQUpdate(outlet) {
    if (this.isEdit) {
      if (outlet.get('UpdatedQuantity').value !== '' && outlet.get('UpdatedQuantity').value != null && this.TempQuantity !== outlet.get('UpdatedQuantity').value) {
        outlet.controls.ReasonforInventoryAdjustment.setValidators([Validators.required]);
        outlet.controls.ReasonforInventoryAdjustment.updateValueAndValidity();
        this.retailService.inventoryItemEdited = false;
         this.retailService.ValidateUpdate();
        this.inventoryFormArr.controls.forEach(element => {
          if (element.get('UpdatedQuantity').value != null && element.get('ReasonforInventoryAdjustment').value == null) {
            element.get('ReasonforInventoryAdjustment').markAsDirty();
            element.get('ReasonforInventoryAdjustment').markAsTouched();
          }
        });

      } else {
        outlet.controls.ReasonforInventoryAdjustment.setValidators('');
        outlet.controls.ReasonforInventoryAdjustment.updateValueAndValidity();
      }
    }
  }

  stopdefaultClick(e) {
    if (this.isEdit && this.IsViewOnly) {
      e.preventDefault();
    }
  }

  MapVendorToApi(data: RetailItemVendor[]) {
    if (data && data.length > 0) {
      return data.map(c => {
        return {
          id: c.id,
          vendorId: c.vendorId,
          retailItemId: c.retailItemId,
          vendorCode: c.vendorCode,
          vendorName: c.vendorName,
          supplierItemNumber: c.supplierItemId,
          qoh: c.quantiyOnHand,
          leadDays: c.leadDays,
          minimumOrder: c.minOrder,
          unitQty: c.unitQuantity
        }
      });
    }
    else return [];
  }

  assignVendors(e) {
    const data = this.retailService.vendorAPIInfo && !this.retailService.VendorInfoChanges ?
      this.MapVendorToApi(this.retailService.vendorAPIInfo) : this.assignVendor;
    this.openDialog(data);
  }

  openDialog(data: VendorAssign[]): any {
    let popupConfiguration: any;
    popupConfiguration = {
      data: data,
      caption: this.captions.AddRetailVendor
    };
    let afterClosed = true;
    return this.triggerPopup(popupConfiguration, afterClosed);
  }

  triggerPopup(popupConfiguration: any, afterClosed = true): any {
    const dialogRef = this.dialog.open(AddVendorsPopupComponent, {
      width: '992px',
      maxWidth: "1000px;",
      height: "675px",
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: { headername: popupConfiguration.caption, closebool: true, templatename: 'VENDOR', popupConfig: popupConfiguration },
      disableClose: true
    });

    if (afterClosed) {
      this.settingDialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.retailService.vendorInfo = result;
          this.retailService.VendorInfoChanges = true;
          this.assignVendor = result;
          this.retailService.ValidateUpdate();
        }
      });
    }
    return dialogRef;
  }

  async save() {

    this.retailService.ValidateUpdate();
    if (!this.validateBtn) return;
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    let updateBody = this.retailService.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;

    let itemId = 0;
    if (this.isEdit) {
      response = await this.retailService.InvokeServiceCallAsync("UpdateRetailItem", Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id })
      if (response) {
        this.retailService.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    }
    else {
      response = await this.retailService.InvokeServiceCallAsync("CreateRetailItem", Host.retailManagement, HttpMethod.Post, updateBody)
      if (response) {
        this.retailService.saveImage(response.result)
        itemId = response.result;
      }
    }
    if (this.retailService.showRetailSetupRoomTypesAssignmentTab) {
      RetailDataAwaiters.createOrUpdateRoomTypeAssignment(updateBody.retailItem.roomTypeAssignmentRetailItemMapping, itemId)
    }

    if (response && response.result) {
      this.retailService.retailItemUpdateEvent.next(itemId);
      this.dialogRef.close(updateBody);
    }
  }
}
