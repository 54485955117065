import { RetailEventSubscriber } from "./shared/service/retail-subscriber/retail.event.subscriber";
import { Injectable } from "@angular/core";
import { DataAwaiterService } from "./shared/service/awaiters/data.awaiter.service";

@Injectable()
export class AppBusiness {
    constructor(private _retailEventSubscriber: RetailEventSubscriber
        , private _setDataAwaiters: DataAwaiterService
    ) {
    }
}