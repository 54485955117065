import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Time } from '@angular/common';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { AgTimeConfig } from '../../models/ag-models';
import moment from 'moment';

@Component({
  selector: 'app-spa-ag-time-picker',
  templateUrl: './ag-time-picker.component.html',
  styleUrls: ['./ag-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgTimePickerComponent implements OnInit {
  captions: any;
  className: string;
  errorMessage: string;
  floatLabel: string;
  form: UntypedFormGroup;
  formControlName: string;
  hidden = false;
  maxTime: string | Time;
  minTime: string | Time;
  defaultTime: string;
  placeHolder: string;
  value: string | Time;
  isInvalid: any;
  customErrorMessage: string;
  isTimeRequired: boolean;
  @Output() timeChangeEvent = new EventEmitter();
  @Output() timepickerOpen = new EventEmitter();
  timeformat: number;
  @Input('inputs')
  set inputOptions(value: AgTimeConfig) {
    this.className = value.className;
    this.floatLabel = value.floatLabel;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.hidden = value.hidden ? value.hidden : false;
    this.maxTime = value.maxTime;
    this.minTime = value.minTime;
    this.value = value.value;
    this.defaultTime = this.value ? this.value.toString() : this.minTime && this.minTime.toString();
    this.isTimeRequired = value.isTimeRequired ? value.isTimeRequired : false;
    this.placeHolder = value.placeHolder;
    this.customErrorMessage = value.customErrorMessage;
    this.isInvalid = this.minTime ? (this.form.value[this.formControlName] &&
      this.checkInvalid(this.minTime, this.form.value[this.formControlName])) : false;
    let invalidError;
    let customErrorMsg;

    if (this.isInvalid) {
      invalidError = true;
    } else {
      invalidError = false;
    }

    if (this.customErrorMessage) {
      customErrorMsg = true;
    } else {
      customErrorMsg = false;
    }
    this.form.controls[this.formControlName].setErrors({
      invalid: invalidError,
      customError: customErrorMsg
    });
    if (invalidError || customErrorMsg) {
      this.form.controls[this.formControlName].markAsTouched();
    }
    if (!invalidError && !customErrorMsg) {
      this.form.controls[this.formControlName].updateValueAndValidity();
    }


  }

  @Input('clearTime')
  set clearTime(value) {
    if(value){
      this.form.controls[this.formControlName].setValue(null);
      this.minTime = null;
      this.maxTime = null;
      this.form.controls[this.formControlName].reset();
      this.form.controls[this.formControlName].markAsDirty();
      this.form.controls[this.formControlName].updateValueAndValidity();
    }
  }

  
  constructor(private _Localization: SpaLocalization) { }

  ngOnInit() {
    this.captions = this._Localization.captions;
    this.timeformat = this._Localization.getTimeFormat();
  }

  timeChanged() {
    this.timeChangeEvent.emit([this.form, this.formControlName]);
  }

  checkInvalid(minTime, formControlName) {
    const minimumTime = moment(minTime, 'h:mma');
    const maximumTime = moment(formControlName, 'h:mma');
    return maximumTime.isBefore(minimumTime);
  }

  timepickerOpened(event) {
    this.timepickerOpen.emit(event);
  }
}
