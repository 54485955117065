import { Injectable } from '@angular/core';
import { DataMigrationServiceApiRoutes } from '../common-route';
import { DataMigrationCommunication } from '../communication/services/data-migration-communication-service';
import { DataMigrationStatisticsRequest, DataMigrationStatistics, StatisticalDetails } from '../shared/shared/components/data-migration-exp/data-migration-exp.modal';
@Injectable({
    providedIn: 'root'
})
export class DataMigrationStatisticsDataService {
    constructor(private dataMigrationService: DataMigrationCommunication) {
    }

    public processDataMigrationStatisticsRequest(body: DataMigrationStatisticsRequest): Promise<DataMigrationStatistics> {
        let result = this.dataMigrationService.postPromise<DataMigrationStatistics>(
            { route: DataMigrationServiceApiRoutes.processDataMigrationStatistics, body: body }, false);
        return result;

    }

    public downloadExcel(statisticalDetailsId: number, downloadFileType: string): Promise<any> {
        let result = this.dataMigrationService.putDownloadPromise(
            { route: DataMigrationServiceApiRoutes.downloadExcelForDataMigrationStatistics, uriParams: { statisticalDetailsId: statisticalDetailsId, downloadFileType: downloadFileType } });
        return result;
    }

}