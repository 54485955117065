import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Localization } from '../localization/localization';

@Injectable({
    providedIn: 'root'
})

export class ChangePropertySevice {

    constructor(private localization: Localization){

    }

    private readonly roleName = new BehaviorSubject<string>(null);
    private readonly propertyName = new BehaviorSubject<string>(null);
    private readonly isPropertyChanged = new BehaviorSubject<boolean>(null);

    readonly roleName$ = this.roleName.asObservable();
    readonly propertyName$ = this.propertyName.asObservable();
    readonly propertyChanged$ = this.isPropertyChanged.asObservable();

    public GetRoleName(): string {
        return this.roleName.getValue();
    }

    public SetRoleName(roleName: string) {
        this.roleName.next(roleName || this.localization.GetUserInfo('roleName'));
    }

    public GetPropertyName(): string {
        return this.propertyName.getValue();
    }

    public SetPropertyName(propertyName?: string) {
        this.propertyName.next(propertyName || this.localization.GetsessionStorageValue('propertyInfo', 'PropertyName'));
    }

    public SetPropertyChanged(propertyChanged?: boolean) {
        this.isPropertyChanged.next(propertyChanged);
    }

    public UnSubscribePropertyChangedEvent() {
        this.isPropertyChanged.next(false);
    }
}
