import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { CCTerminal } from "../../retail.modals";
import { Host } from "../../shared/globalsContant";
import { BaseResponse } from "../../shared/business/shared.modals";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { RetailRoutes } from "../../retail-route";


@Injectable()
export class RetailCreditCardTerminalDataService {
    captions: any;
    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization,) {
        this.captions = this.localization.captions.retailsetup;
     }

    public async getAllCCTerminal(): Promise<CCTerminal[]> {
        try {
            return await this.invokeCCTerminalCalls<CCTerminal[]>("getAllCCTerminals", HttpMethod.Get);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async isDefaultCCTerminalExists() : Promise<boolean>
    {
        try {
            return (await this.invokeCCTerminalCalls(RetailRoutes.IsDefaultCCTerminalExists, HttpMethod.Get));
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetDefaultCCTerminal() : Promise<CCTerminal>
    {
        try {
            return (await this.invokeCCTerminalCalls(RetailRoutes.GetDefaultCCTerminal, HttpMethod.Get));
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async createCCTerminal(body: CCTerminal): Promise<CCTerminal[]> {
        try {
            return await this.invokeCCTerminalCalls<CCTerminal[]>("createCCTerminal", HttpMethod.Post, body);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async updateCCTerminal(body: CCTerminal): Promise<CCTerminal[]> {
        this.utils.ToggleLoaderWithMessage(true,this.captions.lbl_processing)
        try {
            return await this.invokeCCTerminalCalls<CCTerminal[]>("updateCCTerminal", HttpMethod.Put, body, { id: body.id });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async deleteCCTerminal(id: number): Promise<CCTerminal[]> {
        this.utils.ToggleLoaderWithMessage(true,this.captions.lbl_processing)
        try {
            return await this.invokeCCTerminalCalls<CCTerminal[]>("deleteCCTerminal", HttpMethod.Delete, undefined, { id: id });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async updateListOrder(from: number, to: number): Promise<CCTerminal[]> {
        this.utils.ToggleLoaderWithMessage(true,this.captions.lbl_processing)
        try {
            let uriParms = { 'fromorder': from, 'toorder': to }
            return await this.invokeCCTerminalCalls<CCTerminal[]>("UpdateCCTerminalListOrder", HttpMethod.Put, undefined, uriParms);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async getNextListOrder(): Promise<Number> {
        try {
            return await this.invokeCCTerminalCalls<Number>("getCCTerminalNextListOrder", HttpMethod.Get, undefined, undefined);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }


    private async invokeCCTerminalCalls<T>(callDesc: string, callType: HttpMethod, body?: CCTerminal, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.payment,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus && callDesc != RetailRoutes.GetDefaultCCTerminal) {
            this.showError(response.errorCode);
        }
        this.utils.ToggleLoaderWithMessage(false);
        return response.result;
    }

}
