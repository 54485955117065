import { Pipe, PipeTransform } from '@angular/core';
import { SpaUtilities } from '../utilities/spa-utilities';
import { appointmentService } from '../service/appointment.service';

// @Pipe({
//   name: 'getLegendColorPipes'
// })
// export class GetLegendColorPipesPipe implements PipeTransform {

//   transform(value: any, args?: any): any {
//     return null;
//   }

// }

@Pipe({
  name: 'getLegendColorPipes'
})
export class GetLegendColorPipesPipe implements PipeTransform {

  constructor(public utils: SpaUtilities, public _appointmentService: appointmentService) { }
  transform( blglegnd) {
    return this.utils.getLegendColorCode(this._appointmentService, blglegnd);
  }
}