import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import * as _ from "lodash";
import { RetailReportControl } from '../../../basereport/base-report.component';


@Component({
  selector: 'app-retail-top-spenders',
  templateUrl: './top-spenders.component.html',
  styleUrls: ['./top-spenders.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailTopSpendersComponent implements OnInit {

  TopSpendersFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  @Input() ShowOutletSelection : boolean ;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  selectedDropdownType: string = '';
  valuesSelected: any[];
  categories: any[];
  allCategories: any[];
  allCategoryGroups: any[];
  isCategory: boolean = false;

  radioFilter: { id: number; name: string; }[];
  outlets: any;
  allOutlets: any;
  dCategoryGroups: any[];
  dcategories: any[];
  floatLabel: string;
  editPatchValue : any;


  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, private dataService: ReportDataService
    ,private container: ViewContainerRef) {
    this.floatLabel = this.localization.setFloatLabel;
   }

  ngOnInit() {
    this.radioFilter = [{ id: 1, name: this.captions.TopSpendersByCategoryGroups },
      { id: 0, name: this.captions.TopSpendersByRetailCategories } ];
    this.TopSpendersFormGrp = this.fb.group({
      topSpenders: ['',Validators.required],
      itemFilter: 1,
      outlets: new UntypedFormControl([]),
      reportcategory: new UntypedFormControl([]),
      category: new UntypedFormControl([]),
      includeInactiveOutlets: false,
      includeInactiveCategoryGroups: false,
      includeInactiveRetailCategories: false,
      defaultOutlets : [],
      unmodifiedOutlets : [],
      defaultcategory : [],
      defaultcategorygroup : [],
    });

    this.formReady.emit(this.TopSpendersFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
        if(patchItem.OutletIds != undefined){
          this.dataService.getAllOutletsByUser().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.OutletIds.find(x => x == item.id);
            });
            this.outlets = arr;     
          });
        } 
        if(patchItem.CategoryGroupIds != undefined){
          this.dataService.GetAllCategoryGroups().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.CategoryGroupIds.find(x => x == item.id);
            });
            this.allCategoryGroups = arr;     
          });
        } 
        if(patchItem.CategoryIds != undefined){
          this.dataService.GetAllCategories().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.CategoryIds.find(x => x == item.id);
            });
            this.categories = arr;     
          });
        } 
        if(patchItem.Spenders != undefined){
          this.TopSpendersFormGrp.controls['topSpenders'].patchValue(patchItem.Spenders);
        } 
        if(patchItem.Itemfilter != undefined){
          this.TopSpendersFormGrp.controls['itemFilter'].patchValue(patchItem.Itemfilter);
            } 
        }
    } 
  }

  private async onLoad() {
    this.outlets = this.allOutlets = await this.dataService.getAllOutletsByUser();
    this.TopSpendersFormGrp.controls.unmodifiedOutlets.setValue(this.outlets);
    this.outlets = this.outlets.filter(u => u.showInDropDown);
    this.business.FilterDataSource["outlets"] = this.outlets.filter(u => u.showInDropDown);

    this.allCategoryGroups = this.dCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.allCategoryGroups = this.allCategoryGroups.filter(u => u.showInDropDown);
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups.filter(u => u.showInDropDown);

    this.categories = this.dcategories = await this.dataService.GetAllCategories();
    this.categories = this.categories ? _.orderBy(this.categories, [x => x.listOrder]) : this.categories;
    this.categories =  this.categories.filter(u => u.showInDropDown);
    this.business.FilterDataSource["categories"] = this.categories.filter(u => u.showInDropDown);
    this.initializeDefaultValues();
  }

  initializeDefaultValues(){
    this.TopSpendersFormGrp.controls.defaultOutlets.setValue(this.outlets);
    this.TopSpendersFormGrp.controls.defaultcategory.setValue(this.categories);
    this.TopSpendersFormGrp.controls.defaultcategorygroup.setValue(this.allCategoryGroups);
  }

  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
      this.TopSpendersFormGrp.setControl('reportcategory', event[1]);
    } else if (event[0] === 'category') {
      this.TopSpendersFormGrp.setControl('category', event[1]);
    } else if (event[0] === 'outlets') {
      this.TopSpendersFormGrp.setControl('outlets', event[1]);
    }
  }
  getCategoryGroupValue(selectedCategoryGroupArray) {
    let filteredCategory = this.allCategories.filter(element => selectedCategoryGroupArray.map(r => r.id).includes(element.categoryGroup));
    this.categories = filteredCategory;
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean, type: string) {

    switch (type) {
      case 'outlets':
        const outlets = IncludeInactiveToo ? this.allOutlets : this.allOutlets.filter(u => u.showInDropDown);
        this.outlets = this.business.toggleIncludeInactive(IncludeInactiveToo, outlets);
        this.business.FilterDataSource["outlets"] = this.outlets.filter(u => u.showInDropDown);
        this.TopSpendersFormGrp.controls['outlets'].setValue(this.outlets);
        break;
      case 'categoryGroups':
        let categoryGroups = IncludeInactiveToo ? this.dcategories : this.dcategories.filter(u => u.showInDropDown);
        categoryGroups = categoryGroups ? _.orderBy(categoryGroups, [x => x.listOrder]) : categoryGroups;
        this.categories = this.business.categoryToggleIncludeInactive(IncludeInactiveToo, categoryGroups);
        this.business.FilterDataSource["categories"] = this.categories.filter(u => u.showInDropDown);
        this.TopSpendersFormGrp.controls['category'].setValue(this.categories);
        break;
      case 'retailCategories':
        const retailCategories = IncludeInactiveToo ? this.dCategoryGroups : this.dCategoryGroups.filter(u => u.showInDropDown);
        this.allCategoryGroups = this.business.categoryToggleIncludeInactive(IncludeInactiveToo, retailCategories);
        this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups.filter(u => u.showInDropDown);
        this.TopSpendersFormGrp.controls['reportcategory'].setValue(this.allCategoryGroups);
        break;
    }
  }
}

