import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from './http-call.service';
import { ClientType, Host } from '../globalsContant';
import {API} from "src/app/settings/Utilities/cgps-logging-profile-sync-wrapper/cgps-logging-profile-sync-wrapper.model"
import { ClientInfo } from "src/app/retail/shop/shop.modals";
import { BaseResponse } from "../business/shared.modals";
import { RetailRoutes } from "src/app/retail/retail-route";
import { PayeeInfo } from "src/app/retail/shared/business/shared.modals";
import * as GlobalConst from "src/app/shared/globalsContant";
import { FormatText } from "src/app/common/shared/shared/pipes/formatText-pipe.pipe";
import { SpaLocalization } from "src/app/core/localization/spa-localization";

@Injectable()
export class CgpsLoggingProfileSyncWrapperDataService
{
    constructor(private http: HttpServiceCall, public formatphno: FormatText,private localization: SpaLocalization)
    {

    }

    public async getFailedProfile(): Promise<API.FailedProfile[]> {
        try {
          let response: BaseResponse<API.FailedProfile[]> = await this.http.CallApiAsync({
            callDesc: 'GetAllFailedGuestProfile',
            host: Host.spaManagement,
            method: HttpMethod.Get,
          });
          return response.result;
        } catch (e) {
          this.http.exceptionHandle(e);
        }
      }

    public async DateRangeProfileSync(profileSyncInfo : API.ProfileSyncInfo) {
        try {
          return await this.http.CallApiAsync({
            callDesc: 'ProfileSyncManaulTrigger',
            host: Host.spaManagement,
            method: HttpMethod.Put,
            body: profileSyncInfo
          }) as any;
        } catch (e) {
          this.http.exceptionHandle(e);
        }
      }

    public async SingleProfileSync(profileSyncInfo : API.ProfileSyncInfo) {
        try {
          return await this.http.CallApiAsync({
            callDesc: 'ProfileSyncManaulTrigger',
            host: Host.spaManagement,
            method: HttpMethod.Put,
            body: profileSyncInfo
          }) as any;
        } catch (e) {
          this.http.exceptionHandle(e);
        }
      }

      public async getClientInfobyGuid(GuidID: string): Promise<ClientInfo> {
        let response: BaseResponse<ClientInfo> = await this.http.CallApiAsync<ClientInfo>({
            callDesc: RetailRoutes.GetClientInfobyGuid,
            host: Host.retailPOS,
            method: HttpMethod.Get,
            uriParams: { guid: GuidID }
        });
        return response.result;
    }

    public async getClientInfo(id: number) {
      var response = await this.InvokeServiceCallAsync(
          "getClientInfo",
          GlobalConst.Host.spaManagement,
          HttpMethod.Get,
          { id: id }
      );
      return response.result;
  }

    async GetAllClientsbyguid(guestIds: any[]):Promise<PayeeInfo[]>{
      const response= await this.InvokeServiceCallAsync(
       "GetClientsByGuestIds",
        GlobalConst.Host.spaManagement,
        HttpMethod.Put,
        '',
        guestIds
      );
      let clientDetails: PayeeInfo[] = [];
      if (response.successStatus && response.result && response.result.length > 0) {
          response.result.forEach(client => {
              clientDetails.push(this.BuildPayeeData(client));
          });
      }
      return [response.result, clientDetails];
  }

    async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
      try {
          return await this.http.CallApiAsync({
              host: domain,
              callDesc: route,
              method: callType,
              body: body,
              uriParams: uriParams,
          });
      } catch (e) {
          console.error(e);
          throw e;
      }
  }

  private BuildPayeeData(client): PayeeInfo {
    return {
        id: client.clientDetail.id,
        name: client.clientDetail.firstName + ' ' + client.clientDetail.lastName,
        address: (client.addresses && client.addresses.length > 0) ? (client.addresses[0].line1 + ' ' + client.addresses[0].state) : '',
        country: (client.addresses && client.addresses.length > 0) ? client.addresses[0].country : '',
        zip: (client.addresses && client.addresses.length > 0) ? client.addresses[0].zip : '',
        city: (client.addresses && client.addresses.length > 0) ? client.addresses[0].city : '',
        guestProfileId: client.clientDetail.clientType == ClientType.Member? client.clientDetail.clientLinkId:client.clientDetail.guestId,
        cardInfo: client.clientCreditCardInfo,
        patronId: client.clientDetail.loyaltyDetail && client.clientDetail.loyaltyDetail[0] ? client.clientDetail.loyaltyDetail[0].patronId : '',
        rank: client.clientDetail.loyaltyDetail && client.clientDetail.loyaltyDetail[0] ? client.clientDetail.loyaltyDetail[0].rank : '',
        phoneNumber: this.GetClientPhoneNumber(client),
        emailId: this.GetClientEmail(client),
        lastName: client.clientDetail.lastName,
        guestId : client.clientDetail.guestId,
        guesttype : client.clientDetail.clientType
    };
}

private GetClientPhoneNumber(clientInfo) {
    if (clientInfo && clientInfo.phoneNumbers && clientInfo.phoneNumbers.length) {
        let notPrivate = clientInfo.phoneNumbers.filter(x => !x.isPrivate);
        if (notPrivate && notPrivate.length) {
            let primary = notPrivate.find(x => x.isPrimary);
            if (primary == null || primary == undefined) {
                primary = notPrivate[0];
            }
            return this.getFormattedPhNo(primary);
        }
    }
    return '';
}

private GetClientEmail(clientInfo) {
    if (clientInfo && clientInfo.emails && clientInfo.emails.length) {
        //let notPrivate = clientInfo.emails.filter(x => !x.isPrivate);
        let notPrivate = clientInfo.emails;
        if (notPrivate && notPrivate.length) {
            let primary = notPrivate.find(x => x.isPrimary);
            if (primary && primary.emailId) {  
                return primary.emailId;
            }
            return notPrivate[0].emailId;
        }
    }
    return '';
}

private getFormattedPhNo(PhoneNo: any): any {
    //ADDING FOR EXTENSION FIELD
    let _number: any,
        number = PhoneNo.number;
    let countryCode = [];
    countryCode = number.split('|');
    if (countryCode != undefined && countryCode[0] != undefined && countryCode[0] != '' && countryCode[1] != undefined) {
        let extension = [];
        PhoneNo.countryCode = countryCode[0];
        number = countryCode[1];
        extension = countryCode[0].split(':');
        if (extension != undefined && extension[0] != undefined && extension[0] != '' && extension[1] != undefined) {
            countryCode[0] = extension[1];
            number = extension[0] + ":" + number;
        }
    }
    if (number != undefined && number != '') {
        if (PhoneNo.contactTypeId == '3') {
            let PhnoExt = [];
            PhnoExt = number.split(':');
            if (PhnoExt.length > 1) {
                PhoneNo.extension = PhnoExt[0];
                number = PhnoExt[1];
            }
        }
        _number = PhoneNo.extension ? this.formatphno.transform(`${PhoneNo.extension}:${number}`, this.localization.captions.common.ExtensionFormat) : this.formatphno.transform(number, this.localization.captions.common.PhoneFormat);
        if (countryCode != undefined && countryCode[0] != undefined && countryCode[0] != '' && countryCode[1] != undefined) {
            _number = countryCode[0] + " - " + _number;
        }
    }
    return _number;
}

}