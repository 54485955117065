import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from "lodash";
import { SpaLocalization } from '../../core/localization/spa-localization';
import { AuthenticationService } from '../../core/services/authentication.service';
import { SPAConfig } from '../../core/config/SPA-config';
import { AppModuleService } from '../../app.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertMessagePopupComponent } from 'src/app/shared/alert-message-popup/alert-message-popup.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { HttpServiceCall } from 'src/app/shared/service/http-call.service';




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  
  moreListItem: any = [];
  navLinks: any = [];
  menuShowingArr: any = [];
  headerPopOver: any;
  logOutPopOver: any;
  firstName: string = "";
  lastName: string = "";
  userName: string;
  userRole: string = "";
  userText: string;
  moreTextName = "More";
  captions: any;
  searchOpen: boolean = false;
  menusearchOpen:boolean = false;
  @ViewChild('navBar') navBar: ElementRef;
  constructor(private dialog: MatDialog
    ,public router: Router
    , private localization: SpaLocalization
    , private authentication: AuthenticationService
    , public appService: AppModuleService
    , private featureflagService: RetailFeatureFlagInformationService
    , private spaConfig: SPAConfig, private http: HttpServiceCall) {
  }


  ngOnInit() {
    
    this.userName = this.GetsessionStorageValue("_userInfo", "userName");
    this.firstName = this.GetsessionStorageValue("_userInfo", "firstName");
    if (this.firstName == "undefined" || this.lastName == "undefined" || this.firstName == undefined || this.lastName == undefined) {
      this.userText = this.userName.charAt(0).toUpperCase();
    }
    else {
      this.userText = this.firstName.charAt(0).toUpperCase() + this.lastName.charAt(0).toUpperCase();
    }
    this.lastName = this.GetsessionStorageValue("_userInfo", "lastName");
    this.userRole = this.GetsessionStorageValue("_userInfo", "roleName");
    if (this.userRole == "undefined" || this.userRole == undefined || this.userRole == null || this.userRole == "null") {
      this.userRole = "";
    }
    else {
      this.userRole = this.userRole.toUpperCase();
    }    
    this.captions = this.localization.captions.header;
    const routeList = [];
    routeList.push({ icon: 'icon-home', iconText: this.captions.Home, route: 'home' });
    routeList.push({ icon: 'icon-apppointments', iconText: this.captions.Appointment, route: 'appointment' });
    routeList.push({ icon: 'icon-guest', iconText: this.captions.Client, route: 'client' });
    routeList.push({ icon: 'icon-shop', iconText: this.captions.Shop, route: 'shop' });
    routeList.push({ icon: 'icon-reports', iconText: this.captions.Reports, route: 'report' });
    routeList.push({ icon: 'icon-Audit', iconText: this.captions.Audit, route: 'audit' });
    routeList.push({ icon: 'icon-settings', iconText: this.captions.Settings, route: 'settings' });
    this.navLinks = routeList;
    this.moreTextName = this.captions.More.toUpperCase();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.bindHeaderData();
    }, 1);
  }
  //Calculation for more options
  bindHeaderData() {
    let menuItem = _.cloneDeep(this.navLinks);
    let menuTobeShown = [];
    this.moreListItem = [];
    let headerWidth = 0;
    let moreCalc: boolean = false;
    const parentelementWidth = this.navBar.nativeElement.clientWidth;
    for (let menu of menuItem) {
      let elementWidth = this.getTextWidth(menu.iconText, "100 14px LatoWeb");
      headerWidth += elementWidth + 66;
      if ((parentelementWidth - headerWidth) >= elementWidth) {
        menuTobeShown.push(menu);
      } else {
        moreCalc = true;
        break;
      }
    }
    if (moreCalc) {
      menuTobeShown = [];
      headerWidth = 0;
      let moreWidth = this.getTextWidth(this.moreTextName, "100 14px LatoWeb");
      const LegendsWidth = parentelementWidth - (moreWidth + 66);
      for (let menuData of menuItem) {
        let elementWidth = this.getTextWidth(menuData.iconText, "100 14px LatoWeb");
        headerWidth += elementWidth + 66;
        if ((LegendsWidth - headerWidth) >= elementWidth) {
          menuTobeShown.push(menuData);
        } else {
          this.moreListItem.push({ icon: menuData.icon, iconText: menuData.iconText, route: menuData.route });
        }
      }
    }
    this.menuShowingArr = menuTobeShown;
    this.onResize();
  }
  //Text width
  getTextWidth(text, font) {
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text.toUpperCase());
    if (canvas) {
      canvas.remove();
    }
    return Math.ceil(metrics.width);
  }
  //Local storage session
  public GetsessionStorageValue(key: string, name: string) {
    var nameEQ = name + "=";
    var propertyInfo = sessionStorage.getItem(key)
    if (propertyInfo != null) {
      var ca = propertyInfo.split(";");

      for (let property of ca) {
        var c = property.trim();
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  onResize() {
    // For closing the more pop over
    if (this.headerPopOver)
      this.headerPopOver.hide();

    //For closing the logout pop over
    if (this.logOutPopOver)
      this.logOutPopOver.hide();
  }
  logout() {
    this.authentication.logout();
  }
  openPopUp(popOver) {
    this.headerPopOver = popOver;
  }
  openGlobalSearch(e, type) {
    this.searchOpen = !this.searchOpen;
    this.menusearchOpen=false;
    console.log(" searchOpen ", type);
  }
  openMenuSearch() {
    this.menusearchOpen = !this.menusearchOpen;
    this.searchOpen=false;
  }
  onSearch($event) {
    this.searchOpen = !this.searchOpen;
  }

  MenuOptionSelected(event) {
    this.menusearchOpen = false;
  }

  async iconClick() {
    let help_hosturl= this.spaConfig.getUrl('host.documentation') + 'spa/';
    let help_page = 'AgilysysSpa_Home.htm';
    let appver = sessionStorage.getItem('productVersion');
    let dotRegex = /\./gi;
    let productVersion = appver && appver != 'null' ? appver :'12.2';
    let _productVersion = productVersion.replace(dotRegex, '_');

    let url = help_hosturl+_productVersion+'/'+help_page;
    let userSesion = sessionStorage.getItem('_jwt');
    const session = sessionStorage.getItem('userSession');
    let isAuthorized = await this.http.createHelpUserSession();
    if(isAuthorized && userSesion){
      url = url + '?doc_id=' + session;
      setTimeout(() => {window.open(url, '_blank')}, 1000)
    }
  }

  openAboutDialog() {
    let data = '';
    let dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '350px',
      maxWidth: '1000px',
      height: '272px',
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: { headername: this.captions.about, closebool: true, type: 'AL', datarecord: data, buttonName: this.localization.captions.common.OK},
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe();
  }

}
