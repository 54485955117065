import { FormGroup } from '@angular/forms';
import { UnitCollecionName, UnitTypes } from 'src/app/eatecui/source/shared/enum/global.enum';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, MasterInventoryManagement } from '../master.interface';
import { MasterProductDefine } from './master-detail-config.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const MasterDefineProduct: MasterProductDefine =
    (MasterDataCollection: MasterInventoryManagement, MasterId: any, httpService: HttpService,
        FormGroupData: FormGroup, moduleId?: any, unitsArray?: any, masterFormFieldList?: Array<FormType>): any => {
        try {
            return new Promise<any>((resolve, reject) => {
                if (MasterId) {
                    const endpointUrl = moduleId === 3 ? 'Recipes' : 'Ingredients';
                    httpService.GethttpMethod(`/inventory/api/${endpointUrl}/${MasterId}`).subscribe(res => {
                        let dataResponse = res['value'];
                        if (typeof dataResponse === 'string') {
                            const resposeConvertArray = [];
                            const dataResponseObject = JSON.parse(res['value']);
                            resposeConvertArray.push(dataResponseObject);
                            dataResponse = resposeConvertArray;
                        }
                        if (dataResponse.length > 0) {
                            FormGroupData.controls['Number'].setValue(dataResponse[0].Number);
                            if (endpointUrl === 'Recipes') {
                                dataResponse[0]['LinkTypeId'] = 2;
                                const DefineProdcutName = (MasterDataCollection.Name === 'Product') ? dataResponse[0].Name : `FG ${dataResponse[0].Name}`;
                                FormGroupData.controls['Name'].setValue(DefineProdcutName);
                                if (FormGroupData.controls.hasOwnProperty('ProdRecipeId')) {
                                    const optionsData = {
                                        Id: MasterId,
                                        Name: dataResponse[0].Name
                                    };
                                    FormGroupData.controls['ProdRecipeId'].setValue(optionsData);
                                    const formIndex = masterFormFieldList.findIndex(x => x.fieldType.SingleData.name === 'ProdRecipeId');
                                    if (formIndex !== -1) {
                                        masterFormFieldList[formIndex].fieldType.SingleData.autoCompleteData = [];
                                        masterFormFieldList[formIndex].fieldType.SingleData.autoCompleteData = [...[optionsData]];
                                    }
                                }
                                if ( FormGroupData && Object.keys(FormGroupData.controls).length > 0 ) {
                                    const UnitIndex = ( MasterDataCollection.Name === 'Product') ? 1 : 0;
                                    const UnitId = dataResponse[0].RecipeUnits[UnitIndex].UnitId;
                                    if ( UnitId ) {
                                        const UnitsColletion: Array<string> = ['BaseUnit', 'PurchaseUnit', 'TransactionUnit', 'RecipeUnit', 'SellingUnitId'];
                                        UnitsColletion.forEach((e: string) => {
                                           if ( Object.keys(FormGroupData.controls).includes(e) ) {
                                            FormGroupData.controls[e].setValue(UnitId);
                                           }
                                        });
                                    }
                                }
                            } else {
                                dataResponse[0]['LinkTypeId'] = 1;
                                dataResponse[0]['DivisionId'] = dataResponse[0].InventoryType;
                                FormGroupData.controls['Name'].setValue(dataResponse[0].Name);
                                if ( dataResponse[0].hasOwnProperty('BaseUnit') && FormGroupData.controls.hasOwnProperty('SellingUnitId')) {
                                    FormGroupData.controls['SellingUnitId'].setValue(dataResponse[0].BaseUnit);
                                }
                            }
                        }
                        if (MasterDataCollection.NestedFormConfig && MasterDataCollection.NestedFormConfig.EditFormConfig) {
                            MasterDataCollection.NestedFormConfig.EditFormConfig.forEach((SubForm: any) => {
                                if (SubForm.OdataKey === 'ProductItemDetails') {
                                    SubForm.LinkedItems.LinkItemGridConfig.GridData =
                                        assignProductItemDetails(dataResponse, MasterDataCollection, MasterId, endpointUrl);
                                    SubForm.LinkedItems.LinkItemGridConfig.GridData = [...SubForm.LinkedItems.LinkItemGridConfig.GridData];
                                    if (MasterDataCollection.FormConfig.NavigationCollection.filter(yx => yx.odataKey
                                        === SubForm.OdataKey).length > 0) {
                                        const index = MasterDataCollection.FormConfig.NavigationCollection.
                                            findIndex(z => z.odataKey === SubForm.OdataKey);
                                        MasterDataCollection.FormConfig.NavigationCollection[index].ModelCollection =
                                            SubForm.LinkedItems.LinkItemGridConfig.GridData;
                                        calculateCosting(SubForm.LinkedItems.LinkItemGridConfig, FormGroupData);
                                    }
                                }
                            });
                            resolve(MasterDataCollection.NestedFormConfig.EditFormConfig);
                        }
                    });
                }
            });
        } catch (error) {
            console.error('Error occurred in MasterDefineProduct', error);
        }
    };

function assignProductItemDetails(responseData: any, MasterDataCollection: MasterInventoryManagement, MasterId: any, IdentifyVar?: any) {
    try {
        const UnitArrayName = (IdentifyVar === 'Recipes') ? UnitCollecionName.RecipeUnits : UnitCollecionName.Units;
        const UnitTypeId = (IdentifyVar === 'Recipes') ? UnitTypes.BatchSizeUnit : UnitTypes.RecipeUnit;
        const BaseUnitTypeId = (IdentifyVar === 'Recipes') ? UnitTypes.BatchSizeUnit : UnitTypes.BaseUnit;
        const defaultUnitTypeId = (IdentifyVar === 'Recipes') ? UnitTypes.PortionUnit : UnitTypes.RecipeUnit;
        responseData.forEach((element: any) => {
            element['LinkedItemUnits'] = element[UnitArrayName];
            // if ( IdentifyVar === 'Recipes' && element.hasOwnProperty(UnitArrayName) && element[UnitArrayName].length > 0 )
            if (element.hasOwnProperty(UnitArrayName) && element[UnitArrayName].length > 0) {
                const BaseDefineUnit: Array<any> = element[UnitArrayName].filter((e: any) => e.UnitTypeId === BaseUnitTypeId);
                const DefaultDefineUnit: Array<any> = element[UnitArrayName].filter((e: any) => e.UnitTypeId === defaultUnitTypeId);
                if (DefaultDefineUnit && DefaultDefineUnit.length > 0) {
                    element['UnitCode'] = DefaultDefineUnit[0].UnitName + ` (${DefaultDefineUnit[0].UnitCode})`;
                    element['UnitId'] = DefaultDefineUnit[0].UnitId;
                    element['Price'] = DefaultDefineUnit[0].UnitCost;
                    element['UnitCost'] = DefaultDefineUnit[0].UnitCost;
                    element['UnitTypeId'] = DefaultDefineUnit[0].UnitTypeId;
                    element['Conversion'] = DefaultDefineUnit[0].UnitTypeId === 4 ? 
                    parseFloat(parseFloat((1 / DefaultDefineUnit[0].Conversion).toString()).toFixed(6)) :
                    DefaultDefineUnit[0].Conversion;
                }
                if (BaseDefineUnit && BaseDefineUnit.length > 0) {
                    element['BaseUnitCost'] = BaseDefineUnit[0].UnitCost;
                }
                element['Qty'] = Formater('Quantity', '1', null, null);
            }
            if (MasterDataCollection.FormConfig.FlowMode === 'Create') {
                element.Id = 0;
            }
            element['LinkItemType'] = ( IdentifyVar === 'Recipes' ) ? 2 : 1;
            element['ItemId'] = MasterId;
        });
        return responseData;
    } catch (error) {
        console.error('error occured in assignProductItemDetails', error);
    }
}

function calculateCosting(gridData: GridConfiguration, homeGroup: FormGroup) {
    try {
      const NetQty = gridData.GridData.reduce(function (prev, element) {
          return prev + ((element.Qty && element.Conversion) ? (parseFloat(element.Qty) * parseFloat(element.Conversion)) : 0);
      }, 0);
      // const CostRU = gridData.GridData.reduce(function (prev, element) {
      //   return prev + (element.UnitCost ? parseFloat(element.UnitCost) : 0);
      // }, 0);
    const price = gridData.GridData.reduce(function (prev, element) {
        return prev + (element.Price ? parseFloat(element.Price) : 0);
      }, 0);
      homeGroup.controls['Price'].setValue(price.toFixed(4));
      CommonService.triggerBlurEvent('Price');
      const CostBU = gridData.GridData.reduce(function (prev, element) {
        return prev + (element.BaseUnitCost ? parseFloat(element.BaseUnitCost) : 0);
      }, 0);
      const CostRU = gridData.GridData.reduce(function (prev, element) {
        return prev + (element.Price ? parseFloat(element.UnitCost) : 0);
      }, 0);
      const PriceFormValue = homeGroup.controls['Price'].value;
      if (PriceFormValue && CostRU) {
        const CostPercentage = PriceFormValue !== 0 && CostRU !== 0 ? (CostRU / PriceFormValue) * 100 : 0;
        homeGroup.controls['CostPercentage'].setValue(Formater('FixedFormat', CostPercentage, null, null));                    
      } else {
        if (CostBU && CostRU) {
            const CostPercentage = CostRU !== 0 && CostBU !== 0 ? (CostRU / CostBU) * 100 : 0;
            homeGroup.controls['CostPercentage'].setValue(Formater('FixedFormat', CostPercentage, null, null));         
        }
      }
      CommonService.triggerBlurEvent('CostPercentage');
      homeGroup.controls['Qty'].setValue(parseFloat(NetQty ? NetQty.toString() : '0').toFixed(6));
      CommonService.triggerBlurEvent('Qty');
      homeGroup.controls['CostRU'].setValue(parseFloat(CostRU ? CostRU.toString() : '0').toFixed(4));
      CommonService.triggerBlurEvent('CostRU');
      homeGroup.controls['CostBU'].setValue(parseFloat(CostBU ? CostBU.toString() : '0').toFixed(4));
      CommonService.triggerBlurEvent('CostBU');
      // homeGroup.controls['CostPercentage'].setValue(CostPercentage.toFixed(2));
    } catch (error) {
        console.error('Error occurred in calculateCosting', error);
    }
}