import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, OutletAndCategoryFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailFeatureFlagInformationService } from "src/app/retail/shared/service/retail.feature.flag.information.service";
export class SaleByItem extends BaseReport {
    functionalities: { [key: string]: boolean } = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness, private propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization,private featureFlag: RetailFeatureFlagInformationService) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.SaleByItem,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let reportcategoryCaption: string;
        let captions: string[] = [];
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let radioFilterId: number = this.reportFormGrp.controls['customFilterFormGrp'].value.itemFilter;
        if (!customFilterData.filterItems) {
            reportcategoryCaption = this.business.getFilterCaption(this.business.FilterDataSource["allCategoryGroups"], customFilterData.reportcategory.map(x => x.id));
            captions = this.business.getSelectedCategoryCaptions(customFilterData);
        }
        let clerkCaption: string = customFilterData.clerks ? this.business.getFilterCaption(this.business.FilterDataSource["allClerks"], customFilterData.clerks.map(x => x.id)) : "";
        let machineCaption: string = customFilterData.machineName ? this.business.getFilterCaption(this.business.FilterDataSource["allMachineNames"], customFilterData.machineName.map(x => x.id)) : "";

        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) }, { "pPageBreak": this.pageBreakToggle }
            , { "pSummaryByOutlet": radioFilterId == 1 ? "true" : "false" }, { "pSearchByItem": customFilterData.filterItems ? "true" : "false" },
        { "pOutlet": this.functionalities.ShowOutletSelectionFieldInSaleByItemReport ? this.getFilterCaption : this.getDefaultOutletCaption },
        { "pPropertyName": this.propertyName }, { "pUser": clerkCaption }, { "pReportCategory": reportcategoryCaption },
        { "pCategory": captions[0] },
        { "pSubCategory1": captions[1] },
        { "pSubCategory2": captions[2] },
        { "pSubCategory3": captions[3] },
        { "pSubCategory4": captions[4] },
        { "pSubCategory5": captions[5] },
        { 'pDate': this.printedDate },
        { 'pMachineName': machineCaption },
        {'pIncludeInactiveUsers':customFilterData.includeInactiveusers},
        {'pFilterItems':customFilterData.filterItems},
        { 'pPrintMachineName': this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false },
        { 'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInSaleByItemReport ? true : false },
        {"pIsEatecEnabled":this.featureFlag.IsEatecEnabled==true ? "true" : "false"} ];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }


    protected formFilters() {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let toApi = this.localization.convertDateObjToAPIdate;
        let ids: number[];
        if (customFilterData.filterItems && customFilterData.searchbyitem != "") {
            ids = customFilterData.searchbyitem.split(',');
        }
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        let showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
        let isAllMachineSelected: boolean = customFilterData.machineName ? this.business.checkAllSelected(this.business.FilterDataSource["allMachineNames"], customFilterData.machineName.map(x => x.id)) : true;
        const tempOutletFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        const tempMachineNameIds = isAllMachineSelected ? [] : customFilterData.machineName.map(x => x.id);
        const reportGroups = this.business.FilterDataSource['allCategoryGroups'];
        let filterReportGroupIds: number[] = [];
        if (customFilterData.reportcategory) {
            filterReportGroupIds = customFilterData.reportcategory.map(t => t.id);
        }     
        if(reportGroups && reportGroups.length > 0 && reportGroups.length === filterReportGroupIds.length && customFilterData.includeCategoryNotMapped){
            filterReportGroupIds = [];
        }
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return JSON.stringify({
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByItemReport ?
                    this.selectedFilterOptions.map(x => x.id) : tempOutletFalse,
                ClerkIds: customFilterData.clerks.map(x => x.id),
                ReportGroupId: filterReportGroupIds,
                MachineNameIds: showMachineDropDown ? tempMachineNameIds : [],
                CategoryIds: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[0]),
                SubCategory1Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[1]),
                SubCategory2Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[2]),
                SubCategory3Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[3]),
                SubCategory4Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[4]),
                SubCategory5Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[5]),
                SelectedItemIds: ids ? ids : null,
                FilterItems: customFilterData.filterItems,
                ReportCode: AllReports.SaleByItem,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
        let filter: Partial<OutletAndCategoryFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInSaleByItemReport ?
                this.selectedFilterOptions.map(x => x.id) : tempOutletFalse,
            ClerkIds: customFilterData.clerks.map(x => x.id),
            ReportGroupId: filterReportGroupIds,
            MachineNameIds: showMachineDropDown ? tempMachineNameIds : [],
            CategoryIds: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[0]),
            SubCategory1Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[1]),
            SubCategory2Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[2]),
            SubCategory3Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[3]),
            SubCategory4Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[4]),
            SubCategory5Ids: customFilterData.filterItems ? null : this.fillFilter(customFilterData.category[5]),
            SelectedItemIds: ids ? ids : null,
            FilterItems: customFilterData.filterItems,
            ReportCode: AllReports.SaleByItem
        };
        return JSON.stringify(filter);
    }

    fillFilter(customFilterData: any): number[] {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
         if (customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0) {
             ids = [];
         }
        return ids;
    }
}


