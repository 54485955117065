import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PriceType } from '../../../shared/business/view-settings.modals';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-price-type',
  templateUrl: './price-type.component.html',
  styleUrls: ['./price-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PriceTypeComponent implements OnInit {
  priceType: UntypedFormGroup;
  clickButton: any;
  blnReadOnly = false;
  captions: any;
  isUserAuthorized = true;
  isViewOnly = true;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public formBuilder: UntypedFormBuilder, public localization: SpaLocalization, private vsBusiness: ViewSettingClientBusiness, private http: HttpServiceCall, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<PriceTypeComponent>,
    private utils: SpaUtilities, private BP: BreakPointAccess) {
      this.floatLabel = this.localization.setFloatLabel;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingPriceType]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingPriceType);
    if (this.isViewOnly) {
      this.utils.disableControls(this.priceType);
    }
  }
  save(): void {
    setTimeout(() => {
      this.saveButton.disabledproperty = true;
    },1);
    if (this.data.popupConfig.operation == "create") {
      this.createPriceType();
    } else if (this.data.popupConfig.operation == "edit") {
      this.updatePriceType();
    }
  }

  createPriceType(): void {
    const priceTypeObj: PriceType = {
      Code: this.priceType.controls.priceCode.value,
      Description: this.priceType.controls.priceDescription.value,
    };
    try{
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "CreatePriceType",
      method: HttpMethod.Post,
      body: priceTypeObj,
      showError: true,
      extraParams: []
    });
    }
    finally{
      this.saveButton.disabledproperty = false;
    }
  }

  updatePriceType(): void {
    let patchJson = [];
    patchJson = [{ op: "replace", path: "/description", value: this.priceType.controls.priceDescription.value },
    { op: "replace", path: "/code", value: this.priceType.controls.priceCode.value }];
    try {
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "UpdatePriceType",
        method: HttpMethod.Patch,
        uriParams: { id: this.data.datarecord.id },
        body: patchJson,
        showError: true,
        extraParams: []
      });
    }
    finally{
      this.saveButton.disabledproperty = false;
    }
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreatePriceType" || callDesc == "UpdatePriceType") {
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
  }

  cancel(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  ngOnInit() {
    this.captions = this.localization.captions.setting;
    this.priceType = this.formBuilder.group({
      priceCode: ['', Validators.required],
      priceDescription: ['', [Validators.required, EmptyValueValidator]]
    });
    this.saveButton = {
      type: 'primary',
      label: this.data.popupConfig.operation == "edit" ? this.captions.UPDATE : this.captions.SAVE,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.CANCEL,
    }
    if (this.data.popupConfig.operation == "edit") {
      this.priceType.controls.priceCode.setValue(this.data.datarecord.code);
      this.priceType.controls.priceDescription.setValue(this.data.datarecord.description);
      this.clickButton = this.captions.UPDATE;
    }
    this.vsBusiness.activeFormGroup = this.priceType;
    this.ValidateBreakPoint();
    if (this.data.popupConfig.operation == "create") {
      this.clickButton = this.captions.SAVE;
      this.priceType.setValue({
        priceCode: '',
        priceDescription: ''
      });
    } else if (this.data.popupConfig.operation == "edit") {
      this.clickButton = this.captions.UPDATE;
      this.blnReadOnly = true;
      const priceData = this.data.datarecord;
      this.priceType.setValue({
        priceCode: priceData.priceCode,
        priceDescription: priceData.priceDescription
      });
    }
    this.priceType.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.priceType.valid && this.priceType.dirty);
     this.saveButton = { ...this.saveButton };
    });
  }
}
