import { TranslateService } from '@ngx-translate/core';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, HeaderConfiguration, TilesConfiguration, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridNameConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): any => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                const entityName = ViewHeaderEntity[0]['$']['Name'].toString();
                const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                return ProductDataRemap.FieldTranslateRemapEngine( productremapJson, entityName, 
                TransactionCollection.ViewTranscationConfig.GridConfiguration.GridName, translateService); 
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };