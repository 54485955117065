import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceEquipments } from '../../../shared/business/view-settings.modals';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { SPAConfig } from '../../../core/config/SPA-config';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse, popupConfig, NoDataModel } from '../../../shared/business/shared.modals';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-sevice-equipment',
  templateUrl: './sevice-equipment.component.html',
  styleUrls: ['./sevice-equipment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SeviceEquipmentComponent implements OnInit {
  clickbutton: any;
  @Input() dataInput: any;
  @Input() popupConfigs: popupConfig;

  slDataInput: any = [];
  FormGrp: UntypedFormGroup;
  blnReadOnly = false;
  captions: any = this.spaConfig.captions;
  //placeholders variables with current system Language transalated.
  tCode: string = this.spaConfig.captions.setting.equipcode;
  tDesc: string = this.spaConfig.captions.setting.equipDescription;
  tQty: string = this.spaConfig.captions.setting.Quantity;
  isUserAuthorized = true;
  isViewOnly = true;
  //translated validation errors
  vCode: string = this.spaConfig.captions.setting.MissingEquipmentCode;
  vDesc: string = this.spaConfig.captions.setting.MissingEquipmentDescription;
  vQty: string = this.spaConfig.captions.setting.MissingQuantity;
  floatLabel:string;
  serviceDetails: any=[];
  noDataOptions: NoDataModel;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public dialogRef: MatDialogRef<SeviceEquipmentComponent>,
    public dialog: MatDialog,
    private Form: UntypedFormBuilder,
    private http: HttpServiceCall,
    private vsBusiness: ViewSettingClientBusiness,
    private spaConfig: SPAConfig, private utils: SpaUtilities,
    public localization:SpaLocalization,
    private BP: BreakPointAccess) {
      this.floatLabel = this.localization.setFloatLabel;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingServiceEquipment]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingServiceEquipment);
    if (this.isViewOnly) {
      this.utils.disableControls(this.FormGrp);
    }
  }
  onNoClick(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }
  ngOnInit() {
    this.FormGrp = this.Form.group({
      code: ['', [Validators.required, EmptyValueValidator]],
      description: ['', [Validators.required, EmptyValueValidator]],
      quantity: ['', [Validators.required, EmptyValueValidator]]
    });
    this.saveButton = {
      type: 'primary',
      label: this.popupConfigs.operation == "create" ? this.captions.setting.save : this.captions.setting.update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.setting.cancel,
    }
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.ValidateBreakPoint();
    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.captions.setting.save;
      this.blnReadOnly = false;
      this.FormGrp.setValue({
        code: this.dataInput ? this.dataInput.code : '',
        quantity: this.dataInput ? this.dataInput.quantity : 1,
        description: this.dataInput ? this.dataInput.description : '',
      });
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.captions.setting.update;
      this.blnReadOnly = true;
      this.FormGrp.patchValue(this.dataInput);
      this.serviceDetails = this.dataInput.servicesMapped
    }
    this.noDataOptions = { inputText: this.localization.captions.common.NoDataFound, noDataId: GlobalConst.noRecordsType.noRecords, noDataFontSize: 10 };
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.FormGrp.valid && this.FormGrp.dirty);
      this.saveButton = { ...this.saveButton };
    });
  }

  saveServiceEquipment() {
    this.saveButton.disabledproperty = true;
    if (!this.FormGrp.valid) {
      return;
    }
    if (this.popupConfigs.operation == "create") {
      this.createEquipment();
    } else if (this.popupConfigs.operation == "edit") {
      this.updateEquipment();
    }
  }

  createEquipment() {
    const _code = this.FormGrp.controls['code'].value;
    const _desc = this.FormGrp.controls['description'].value;
    const _qty = this.FormGrp.controls['quantity'].value || 0;
    const bodyObj: ServiceEquipments = {
      code: _code.toUpperCase(), description: _desc, quantity: _qty
    }

    this.http.CallApiWithCallback<ServiceEquipments[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CreateEquip",
      method: HttpMethod.Post,
      body: bodyObj,
      showError: true,
      extraParams: [bodyObj]
    });
  }

  updateEquipment() {
    const _code = this.FormGrp.controls['code'].value;
    const _desc = this.FormGrp.controls['description'].value;
    const _qty = this.FormGrp.controls['quantity'].value || 0;
    const bodyObj: ServiceEquipments = { code: _code, description: _desc, quantity: _qty }
    let patchJson = [];
    const patchData = this.utils.getEditedData(this.FormGrp, "code")
    patchJson = patchData.patchJson;
    this.http.CallApiWithCallback<ServiceEquipments[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateEquip",
      method: HttpMethod.Patch,
      uriParams: patchData.key,
      body: patchJson,
      showError: true,
      extraParams: [bodyObj]
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateEquip" || callDesc == "UpdateEquip") {
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateEquip" || callDesc == "UpdateEquip") {
      this.saveButton.disabledproperty = false;
      if (error) { error["NewData"] = extraParams[0]; }
    }
  }

  quantityChange(x)
  {
    var qty = x.target.value;
    for (var i = 0; i < this.serviceDetails.length; i++) {
      this.serviceDetails[i].quantity = qty;
    }
    
  }
}