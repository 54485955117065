import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform/public-api';
import { FGCollection, TransactionConfiguration, UnitModel } from '../transaction.interface';
import { GridItemListConfig } from './grid-transaction-config.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
export const GridItemListConfiguration: GridItemListConfig = (
    TransactionCollection: TransactionConfiguration,
    responseData: Array<any>, CurrencyList: Array<any>, UnitList: Array<any>, ReasonList: Array<any>,
    systemPreference: SysytemPreferenceService): any => {
    try {
        if (TransactionCollection && responseData) {
            const defaultUnitTypeId = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.defaultUnitType;
            const transactionName = TransactionCollection.Name;
            responseData.forEach(data => {
                const keys = keyChecker(data);
                data['IsActive'] = transactionName === 'Physical Inventory' ? data.IsActive : true;
                const loaderBool = ( data.hasOwnProperty('IsLoadOrder') && data.IsLoadOrder );
                const isLoadOrder = (transactionName === 'Purchase Order' || transactionName === 'Receiving') && loaderBool;
                const UnitTypeId = ( data['UnitTypeId'] ) ? data['UnitTypeId'] : defaultUnitTypeId;
                if (data.hasOwnProperty('Units') && data['Units'] && data['Units'].length > 0) { 
                    data['Units'] = typeof data['Units'] === 'string' ? JSON.parse(data['Units']) : data['Units'];
                    const UnitCollection: any[] = data['Units'];
                    const transactionUnitsDetails = UnitsDatafilter(UnitCollection, data, UnitTypeId, keys, transactionName); 
                    
                    // logic moved to above called function for bug 202176
                    // if ( transactionName === 'Purchase Request' || transactionName === 'Purchase Order'
                    //      || transactionName === 'Receiving') {
                    //     if (data.hasOwnProperty('UnitTypeId') && data.hasOwnProperty('UnitId')) {
                    //         transactionUnitsDetails = UnitCollection.filter((unitDetails: any) =>                                
                    //             ( unitDetails['UnitTypeId'] === data['UnitTypeId'] && 
                    //             unitDetails['UnitId'] === data['UnitId'] ));                                
                    //         if (transactionUnitsDetails.length > 0) {                                
                    //           transactionUnitsDetails[0].Conversion = data.hasOwnProperty('PackSize')  && data['PackSize'] !== null? 
                    //           data['PackSize'] : transactionUnitsDetails[0].Conversion;
                    //         }
                    //     } 
                    //     if (transactionUnitsDetails.length === 0 && data.hasOwnProperty('UnitId')) {
                    //         transactionUnitsDetails = UnitCollection.filter((unitDetails: any) => 
                    //             unitDetails['UnitId'] === data['UnitId'] );                                
                    //         if (transactionUnitsDetails.length > 0) {                               
                    //           transactionUnitsDetails[0].Conversion = data.hasOwnProperty('PackSize') && data['PackSize'] !== null ? 
                    //           data['PackSize'] : transactionUnitsDetails[0].Conversion;
                    //         }
                    //     }
                    //     // Bug Fix 151782
                    //     if (transactionUnitsDetails.length === 0 && data.hasOwnProperty('UnitTypeId')) {
                    //         transactionUnitsDetails = UnitCollection.filter((unitDetails: any) => 
                    //             unitDetails['UnitTypeId'] === data['UnitTypeId'] );                                
                    //         if (transactionUnitsDetails.length > 0) {                               
                    //           transactionUnitsDetails[0].Conversion = data.hasOwnProperty('PackSize') && data['PackSize'] !== null ? 
                    //           data['PackSize'] : transactionUnitsDetails[0].Conversion;
                    //         }
                    //     }
                    //     if (transactionUnitsDetails.length === 0) {                            
                    //         transactionUnitsDetails = UnitCollection
                    //         .filter((unitDetails: any) => unitDetails['UnitTypeId'] === defaultUnitTypeId);
                    //     }                       
                    // } else {
                    //     if (UnitCollection.filter(unitType => unitType.UnitTypeId > 4).length > 0) {
                    //         UnitCollection.forEach(unitCol => {
                    //             unitCol.UnitCost = unitCol.CostPerUnit;
                    //         });
                    //     }
                    //     transactionUnitsDetails = UnitCollection.filter((unitDetails: any) => unitDetails['UnitTypeId'] === UnitTypeId);
                    //     if (transactionUnitsDetails.length === 0) {                            
                    //         transactionUnitsDetails = UnitCollection
                    //         .filter((unitDetails: any) => unitDetails['UnitTypeId'] === defaultUnitTypeId);    
                    //     }
                    // }
                    
                    // // if (isLoadOrder) {
                    // //     const defaultUnit = UnitCollection.filter((unitDetails: any) => unitDetails['Id'] === 0)[0];
                    // //     if (defaultUnit) {
                    // //         transactionUnitsDetails[0] = defaultUnit;
                    // //     }
                    // // }
                    // if (transactionUnitsDetails.length === 0) {
                    //     transactionUnitsDetails[0] = UnitCollection[0];
                    // }
                    transactionUnitsDetails.forEach((transDetails: any) => {
                        for (const property in transDetails) {
                            if (property) {
                            const detailsCollection = ['UnitId', 'UnitCode', 'Conversion', 'UnitCost', 'UnitTypeId', 'IngredientId'];
                            const getIndex = detailsCollection.findIndex(x => x === property);
                            if (getIndex > -1 && property !== 'UnitCost') {
                                // Added for post ingrdientid
                                data[property] = (property === 'IngredientId') ? 
                                ((data[property]) ? data[property] : data.Id ) : transDetails[property];
                            } else if (property === 'UnitCost') {
                                // Added for bug fix 152883 
                                // Unit Cost will be calculated based on selected unit type id's conversion * ingredients current cost
                                // if (transDetails.UnitTypeId !== 4) {
                                    data[property] = transDetails.UnitCost;                                    
                                // } else {
                                    // Reciepe Unit Calculation
                                //     data[property] = (1 / transDetails.Conversion) * data.CurrentCost;
                                // }
                            }
                        }
                        }
                    });
                    // // For Bug Fix 156050
                    // // As Discussed with Sivamani if default unit type is reciepe 
                    // // conversion will be Base Unit Conversion (default value is 1) / Recipe Unit Conversion 
                    // if (data.UnitTypeId === 4) {
                    //     data.Conversion = (1 / data.Conversion);
                    // }

                    data['Conversion'] = data.Conversion;
                    data['UnitCost'] = data.UnitCost;
                    const transactions = ['Purchase Order', 'spoilage', 'Production']
                    const isPOLoadOrder = (transactions.includes(transactionName) && loaderBool);
                    data['Qty'] = isPOLoadOrder && data['Qty'] ? data['Qty'] : '';
                    if (isPOLoadOrder) {
                        data['Valued'] = (data.Qty ? parseFloat(data['Qty']) : 0) *
                            (data['UnitCost'] ? parseFloat(data['UnitCost']) : 0);
                        data['Valued'] = data['Valued'];
                    } else {
                        data['Valued'] = data['Valued'];
                    }
                    data['Checked'] = false;
                    if ( transactionName === 'Receiving' || transactionName === 'Purchase Order') {
                        data['OnRecipt'] = data['OnRecipt'];
                    }
                    /**
                     * As per Discuss to sivamani show only 4 unitstype sa per legacy
                     */
                    // if (TransactionCollection.Name === 'Purchase Request' || TransactionCollection.Name === 'Purchase Order'
                    //     || TransactionCollection.Name === 'Receiving') {
                    //     data['Units'] = reassignUnitsArray(UnitList, data['Units']);
                    // }
                    if ( transactionName === 'Purchase Request' || transactionName === 'Purchase Order') {
                        data['Currency'] = CurrencyList;
                        data['ForeignCurrencyId'] = data.CurrencyId ? data.CurrencyId :
                             null; //TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData['TransactionCurrencyId'];
                        const CurrencyCode = CurrencyList.findIndex(x => x.Id === data['ForeignCurrencyId']);
                        data['CurrencyCode'] = CurrencyCode !== -1 ? CurrencyList[CurrencyCode].Name : null;
                        data['ExchangeRate'] = data['ExchangeRate'] ? data['ExchangeRate'] : 1;
                        if ( transactionName === 'Purchase Order') {
                            data['BidId'] = data['BidId'] === 0 ? null : data['BidId'];
                        }
                        data['UnitCostInForigenCurrency'] = (data.UnitCost * data.ExchangeRate).toFixed(4);
                        data['ValueInForeignCurrency'] =  ((data.Valued ? data.Valued : 0) * data.ExchangeRate).toFixed(4);
                    } else if ( transactionName === 'Physical Inventory') {
                        data['Qty'] = data['StorageNotes'] ? data['StorageNotes'] : '';
                        data['CurrentCost'] = Formater('CurrentCost', data.CurrentCost, null, null);
                        data['QtyOnHand'] = Formater('QtyOnHand', data.QtyOnHand, null, null);
                        data['TypeName'] = data['IngredientTypes']['Name'];
                    } else if ( transactionName === 'Receiving') {
                        const defaultTracking = systemPreference.globalSystemPreference.
                        filter(x => x.LookupName === 'Defaultnumberoftrackinglabelsforreceiving')[0].LookupValue === 1 ? 
                        true : false;
                        if (!defaultTracking) {
                            const ItemLotLabel = data.ItemLotLabelQty ? data.ItemLotLabelQty : '0';
                            data['ItemLotLabelQty'] = Formater('Quantity', ItemLotLabel, null, null);
                        } else {
                            const Qty = data.Qty ? data.Qty : '0';
                            data['ItemLotLabelQty'] = Formater('Qty', Qty, null, null);
                        }
                        data['IngBidNumber'] = data.SupplierBidNumber;
                        if (data['CatchWeight'] && data['CatchWeight'] === 2) {
                            const baseUnitsDetails = data['Units'].filter((unitDetails: any) => unitDetails['UnitTypeId'] === 1)[0];
                            if (baseUnitsDetails) {
                                const unitsCollection = ['UnitId', 'UnitCode', 'Conversion', 'UnitCost', 'UnitTypeId'];
                                for (const BaseUnitproperty in baseUnitsDetails) {
                                    if ( BaseUnitproperty ) {
                                     const collIndex = unitsCollection.findIndex(x => x === BaseUnitproperty);
                                     if ( collIndex > -1 ) {
                                        data[ BaseUnitproperty ] = baseUnitsDetails[BaseUnitproperty];
                                     }
                                    }
                                }
                            }
                        }
                        data.ForeignCurrencyId = TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData?.TransactionCurrencyId;
                        data.ExchangeRate = TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData?.ExchangeRate;
                        data.UnitCostInFC = data.UnitCost && data.ExchangeRate && parseFloat(data.ExchangeRate.toString()) > 0 && parseFloat(data.UnitCost.toString()) > 0 ?
                        (data.UnitCost * data.ExchangeRate).toFixed(4) : 0;
                    } else if (transactionName === 'Packages' || transactionName === 'PackageLimitation') {
                        data['Item'] = data['Item'] ? data['Item'] : data.Id;
                        data['check'] = '';
                        data['IsActive'] = true;
                        data['Id'] = 0;
                        // const sourceName = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig?.AddNewType?.
                        //   filter(x => x.DefaultSelect === true)[0];
                        // data['LinkTypeId'] = (sourceName?.Name === 'Recipe') ? 2 : data['LinkTypeId'];
                    } else if (transactionName === 'spoilage') {
                        data['Reasons'] = ReasonList;
                        data['ReasonId'] = null;
                        data['ReasonName'] = null;
                        data['LinkedItemId'] = data.IngredientId;
                        // data['LinkTypeId'] = 1; // Added for testing
                    } else if (transactionName === 'PlanHead') {
                        data['Valued'] = data.CostBU;
                    } else if (transactionName === 'Batch Item Maintenance') {
                        data['BaseConversion'] = Formater('Conversion', data.BaseConversion, null, null);
                        data['PurchaseConversion'] = Formater('Conversion', data.PurchaseConversion, null, null);
                        data['TransactionConversion'] = Formater('Conversion', data.TransactionConversion, null, null);
                        data['RecipeConversion'] = Formater('Conversion', data.RecipeConversion, null, null);
                        data['CurrentCost'] = Formater('CurrentCost', data.CurrentCost, null, null);
                    }
                } else {
                    if (transactionName === 'Packages' || transactionName === 'PackageLimitation') {
                        const sourceName = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig?.
                            AddNewType?.filter(x => x.DefaultSelect === true)[0];
                        data['Item'] = data['Item'] ? data['Item'] : data.Id;
                        data['check'] = '';
                        data['IsActive'] = true;
                        if (sourceName.TypeId !== 1 && sourceName.TypeId !== 2 && sourceName.TypeId !== 3 && sourceName.TypeId !== 4) {
                            data['Barcode'] = data.Number;
                        }
                        data['Id'] = 0;
                        data['LinkTypeId'] = (sourceName?.Name === 'Recipe') ? 2 : data['LinkTypeId'];
                    } 
                    if (transactionName === 'sales' || transactionName === 'quickorder') {
                        const sourceName = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig
                            .AddNewType.filter(x => x.DefaultSelect === true)[0];
                        const salesData = ['CostBU', 'Valued', 'Qty', 'IsActive', 'Price', 'ProductId', 'UnitCost'];
                        salesData.forEach((x: any) => {
                                    if (x === 'IsActive') {
                                        data[x] = true;
                                    } else if (x === 'ProductId') {
                                        data[x] = data[x] ? data[x] : data['Id'];
                                    } else if (x === 'UnitCost' || x === 'Price') {
                                        data[x] = data['Price'];
                                        // ? data['CostBU'] : parseFloat('0').toFixed(decimalValue);
                                    } else {
                                        if (x === 'Valued') {
                                            data[x] = data[x];
                                            // Converters.FixedConverter(data[x], 3);
                                        } else {
                                            data[x] = data[x];
                                        }
                                    }
                        });
                    } else if (transactionName === 'PlanHead') {
                        const salesForecastingFields = [
                            {
                                FromField: 'Id',
                                ToField: 'LinkedItemId'
                            },
                            {
                                FromField: 'SellingUnitId',
                                ToField: 'UnitId'
                            },
                            {
                                FromField: 'UnitTypeId',
                                ToField: 'UnitTypeId'
                            },
                            {
                                FromField: 'LinkTypeId',
                                ToField: 'LinkTypeId'
                            },
                            {
                                FromField: 'UnitCost',
                                ToField: 'Cost'
                            }
                        ];
                        salesForecastingFields.forEach(field => {
                            if (field.ToField !== 'UnitTypeId' && field.ToField !== 'LinkTypeId') {
                                data[field.ToField] = data[field.FromField];
                            } else if (field.ToField === 'UnitTypeId') {
                                data[field.ToField] = 7; // For Product assigning selling unit as type
                            } else if (field.ToField === 'LinkTypeId') {
                                data[field.ToField] = 5; // For Product its 5
                            }
                        });
                        data['Price'] = Formater('Price', data['Price'], null, null);
                        data['Cost'] = Formater('Cost', data['Cost'], null, null);
                        data['UnitId'] = data['UnitId'] ? data['UnitId'] : 1;
                    // } else if (transactionName === 'PackageLimitation') {
                    //     data['Item'] = data['Item'] ? data['Item'] : data['Id'];
                    }
                }
                data['Checked'] = false;
            });
            if (transactionName === 'Production') {
                responseData.forEach((x: any) => {
                    if (x.Id) {                       
                        const defaultTracking = systemPreference.globalSystemPreference.
                        filter(x => x.LookupName === 'Defaultnumberoftrackinglabelsforproduction')[0].LookupValue === 1 ? 
                        true : false;
                        if (!defaultTracking) {
                            const ItemLotLabel = x['ItemLotLabelQty'] ? x['ItemLotLabelQty'] : '0';
                            x['ItemLotLabelQty'] = Formater('Quantity', ItemLotLabel, null, null);
                        } else {
                            const Qty = x['Qty'] ? x['Qty'] : '0';
                            x['ItemLotLabelQty'] = Formater('Qty', Qty, null, null);
                        }
                        x['FGRecipes'] = x.FGRecipes ? JSON.parse(x.FGRecipes) : [];
                        x['ProdItemName'] = x['FGRecipes'].length > 0 ? x['FGRecipes'][0]['Name'] : null;
                        x['ProdItemId'] = x['FGRecipes'].length > 0 ? x['FGRecipes'][0]['Id'] : null;
                    }
                });
            }
            if (transactionName === 'productionplan') {
                responseData.forEach((x: any) => {
                    const salesForecastingFields = [
                        {
                            FromField: 'Id',
                            ToField: 'LinkedItemId'
                        },
                        {
                            FromField: 'UnitCost',
                            ToField: 'Cost'
                        },
                        {
                            FromField: 'Units',
                            ToField: 'UnitsArr'
                        }
                    ];
                    salesForecastingFields.forEach(field => {
                        x[field.ToField] = x[field.FromField];
                        delete x[field.FromField];
                    });
                    x['Cost'] = Formater('Cost', x['Cost'], null, null);
                })
            }
            if (transactionName === 'Butchery') {
                responseData.forEach((x: any) => {
                    if (x.Id) {
                        const Fg: FGCollection  = convertFGRecipe(x);
                        if (Object.keys(Fg).length > 0) {
                            x['FGRecipes'] = Fg?.FgCollection;
                            const butcheryRecipeIndex = Fg?.FgCollection?.findIndex(i => i.IsButcheryRecipe === true);
                            const indexPosition = ( butcheryRecipeIndex > -1 ) ? butcheryRecipeIndex : 0;
                            const fgCollection = Fg.FgCollection ? Fg.FgCollection[indexPosition] : null;
                            if (fgCollection) {                                
                                x['ProdItemName'] = fgCollection.Name;
                                x['RawItemName'] = fgCollection.RawItemName;
                                x['ProdItemId'] = fgCollection.Id;
                                x['QtyUsed'] = fgCollection.QtyUsed;
                            }
                        }                        
                        // x['UnitCost'] = x['CurrentCost'];
                        // x['RawItemName'] = null;
                          
                        if (x['ExpireDate'] !== null && x['ExpireDate'] !== '') {
                            const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
                            const DateTimeFormat = ( sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null ) ? 
                            sessionDateFormat :  constants.DefaultIniDateFormat;
                            const AlreadyFormatted = moment(x['ExpireDate'], DateTimeFormat).format(DateTimeFormat) === x['ExpireDate'];
                            if (!AlreadyFormatted) {
                                // const returnValue = moment.utc(x['ExpireDate'].toString()).local(true).format(DateTimeFormat);
                                const returnValue = moment(x['ExpireDate'].toString()).local(true).format(DateTimeFormat);
                                x['ExpireDate'] = returnValue;
                            } else {
                                x['ExpireDate'] = x['ExpireDate'];
                            }
                        }
                    }
                });
            }
            if (transactionName === 'Ingredients') {
                responseData.forEach((x: any) => {
                    if (x.Id) {
                        x['SupplierId'] = x.Id;
                    }
            });
            }
           // Add below line New Itemlist Id should be 0
            if (transactionName !== 'cycleplanning') {
                responseData.filter(x => x.hasOwnProperty('Id')).map(e => e.Id = 0);
            }
            return responseData;
        } else {
            return responseData;
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

/**
 * @method UnitsDatafilter
 * @input response Data, keys array, defualt unit
 * @output units collection
 */

function UnitsDatafilter(UnitCollection: Array<any>, data: any, UnitTypeId: number, keys: Array<any>, transactionName: string) {
    let transactionUnitsDetails = [];
    const moduleList = ['Purchase Request', 'Purchase Order', 'Receiving'];
    if(moduleList.findIndex(x => x === transactionName) > -1){
        if(keys.length && keys.length > 0) {
            let UnitsList = UnitCollection;
            keys.forEach(key => {
                UnitsList = UnitsList.filter(unit => unit[key] === data[key]);
            });
            transactionUnitsDetails = UnitsList;
            
        } else {
            transactionUnitsDetails = UnitCollection
                .filter((unitDetails: any) => unitDetails['UnitTypeId'] === UnitTypeId);
        }
    } else {
        if (UnitCollection.filter(unitType => unitType.UnitTypeId > 4).length > 0) {
            UnitCollection.forEach(unitCol => {
                unitCol.UnitCost = unitCol.CostPerUnit;
            });
        }
        transactionUnitsDetails = UnitCollection.filter((unitDetails: any) => unitDetails['UnitTypeId'] === UnitTypeId);
    }
    if (transactionUnitsDetails.length === 0) {
            transactionUnitsDetails[0] = UnitCollection[0];
        }
    return transactionUnitsDetails;
}

/**
 * @method KeyChecker
 * @input response Data
 * @output keys
 */
function keyChecker(data: any) {
    const keys = [];
    for (const property in data) {
        if( ( property === 'UnitTypeId' || property === 'UnitId') && data[property]){
            keys.push(property);
        }
      }
      return keys;
}
/**
 * @method convertFGRecipe
 * @input response Data
 * @output None
 */
function convertFGRecipe(responseData: any ): FGCollection {
   try {
    const fCollection = {} as FGCollection;
     if ( responseData ) {
         if ( responseData && responseData.hasOwnProperty('FGRecipes') ) {
               fCollection.FgCollection = [];
               const FGRecipesColl: Array<any> = responseData.FGRecipes ? JSON.parse(responseData.FGRecipes) : [];
              if ( FGRecipesColl && FGRecipesColl.length > 0 ) {
                  fCollection.FgCollection = FGRecipesColl;
                  if ( responseData && responseData.ProdRecipeId ) {
                    fCollection.FilterFG =  FGRecipesColl.filter(s => s.Id === responseData.ProdRecipeId);
                  }
              }
         }
         return fCollection;
     }
     
   } catch ( error ) {
     console.error(error);
   }
}

// function reassignUnitsArray(UnitList: Array<any>, InventoryUnitArray) {
//     try {
//         InventoryUnitArray.forEach(element => {
//             const Index = UnitList.findIndex(x => x.Id === element.UnitId);
//             if (Index !== -1) {
//                 UnitList.splice(Index, 1);
//             }
//         });
//         const ReturnUnitArray: UnitModel[] = InventoryUnitArray;
//         UnitList.forEach(element => {
//             const newUnit: UnitModel = {
//                 UnitCode: element.Code,
//                 UnitId: element.Id,
//                 UnitName: element.Name,
//                 UnitTypeId: ReturnUnitArray.length + 1
//             };
//             ReturnUnitArray.push(newUnit);
//         });
//         return ReturnUnitArray;
//     } catch (error) {
//         console.error('Error occurred in reassignUnitsArray', error);
//     }
// }


