import { Component, Inject, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridZoomPopUp } from '../model/pop-up.interface';
import _ from 'lodash';

@Component({
  selector: 'app-agilysys-grid-zoom-popup',
  templateUrl: './agilysys-grid-zoom-popup.component.html',
  styleUrls: ['./agilysys-grid-zoom-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysGridZoomPopupComponent implements OnInit, OnChanges {
  copyOfData: GridZoomPopUp;
  constructor(
    public dialogRef: MatDialogRef<AgilysysGridZoomPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GridZoomPopUp
    ) {
        console.log(this.data);
        this.copyOfData = _.cloneDeep(this.data);
  }

  getReturnFormValue(value: any) {
    console.log('sss');
  }

  ngOnChanges(): void {
    console.log('sss');
  }

  ngOnInit(): void {
    console.log('sss');
    this.data.GridConfig.searchTextChanged = (value: any) => {
      this.searchChanged(value);
    };
    this.data.GridConfig.rowClicked = (value: any) => {
      if (this.data.EnableRowClick) {
        this.dialogRef.close(value.data);
      }
    };
  }

  searchChanged (searchkey) {
    try {
      const rowData = this.copyOfData.GridConfig.GridData;
      const key = this.copyOfData.ZoomDetailConfig.GridConfig.GridSearch[0].SearchKey;
      let returnData = [];
      if (searchkey.length > 0) {
        returnData = rowData.filter(x => x[key] ? x[key].toString().toLowerCase().includes(searchkey.toLowerCase()) :
        ('').toString().toLowerCase().includes(searchkey.toLowerCase()));
      } else {
        returnData = rowData;
      }
      this.data.GridConfig.GridData = [];
      this.data.GridConfig.GridData = [...returnData];
      this.data.GridConfig = {...this.data.GridConfig};
    } catch (error) {
      console.error('Error occurred in Search', error);
    }
  }

}
