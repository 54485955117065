import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ReportService } from '../report-service.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SPAConfig } from '../../core/config/SPA-config';
import { ReportOptions, Users, TranslogType, DropDownData } from '../business/report.modals';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { Host } from '../../shared/globalsContant';
import { BaseResponse } from '../../shared/business/shared.modals';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { translog } from '../business/translogTypes';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import * as _ from "lodash";
import { ReportDataService } from '../data/report-data.services';
import { ReportBusinessService } from '../business/report-business.service';
import { routingMenuService } from 'src/app/common/components/menu/menu.service';


@Component({
  selector: 'app-transaction-log',
  templateUrl: './transaction-log.component.html',
  styleUrls: ['./transaction-log.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[ReportDataService,ReportBusinessService]
})
export class TransactionLogComponent implements OnInit {

  public tableoptions: any;
  public transactionData: any;
  public logType: TranslogType[];
  public users: Users[] = [];
  public therapist: DropDownData[]=[];
  public multipleUsers: DropDownData[]=[];
  public transactionForm: UntypedFormGroup;
  public captions: any;
  public commonCaptions: any;
  public navbarStatus: boolean = false;
  public showIncludeTemp: boolean = false;
  public minDate: Date = null;
  public maxDate: Date = this.PropertyInfo.CurrentDate;
  public isAuditReport = false;
  showError: boolean = false;
  disableSearchBtn: boolean = false;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  transactionHeader: any = [{ title: "Log Date", jsonkey: "logDate", alignType: "left" },
  { title: "Type", jsonkey: "type", alignType: "left" }, { title: "Description", jsonkey: "description", alignType: "left" },
  { title: "User ID", jsonkey: "userId", alignType: "left" }, { title: "Appointment #", jsonkey: "appointment", alignType: "left" },
  { title: "Transaction #", jsonkey: "transaction", alignType: "left" }, { title: "Client Name", jsonkey: "clientName", alignType: "left" }];
  placeholderFormat: string;
  defaultMinDate = new Date('01/01/1900');
  floatLabel: string;
  isStaffScheduleCreateEditDelete: boolean =false;
  isAllChecked: boolean = true;
  isAllUserChecked: boolean = true;

  constructor(private spaConfig: SPAConfig, private _rs: ReportService, private fb: UntypedFormBuilder, private http: HttpServiceCall, private utils: SpaUtilities, private localization: SpaLocalization,
    private dataService: ReportDataService, public business: ReportBusinessService,
    private PropertyInfo: SpaPropertyInformation, private _routingMenuService:routingMenuService) {
    this.logType = translog;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
  }

  async getTransactionData() {
    this.transactionData = await this._rs.getTransactionLog().toPromise();
    this.populateTableInfo();
  }

  populateTableInfo(): void {
    this.tableoptions = [{
      TableHdrData: this.transactionHeader,
      TablebodyData: this.transactionData,
      pagination: false,
      sortable: false,
      CustomColumn: true,
      TableSearchText: '',
      PlaceHoldertext: '',
      EnableActions: false,
      TableDraggable: false,
      Searchable: false,
      SelectedSettingId: 'transactionLog',
      enableKey: true,
      moreText: 'Show More',
      lessText: 'Show Less'
    }];
  }

  asideArrow(e?: any): void {
    this.navbarStatus = !this.navbarStatus;
  }

  resetSearch(event?: any): void {
    const currentreport: string = this.transactionForm.controls.logType.value;
    this.transactionForm.reset();
    this.showIncludeTemp = false;
    this.transactionForm.controls.logType.setValue(currentreport);
    let currentDate = this.PropertyInfo.CurrentDate;
    this.transactionForm.controls.date.setValue(currentDate);
    this.transactionForm.controls.therapist.setValue(this.therapist);
    this.transactionForm.controls.multipleUsers.setValue(this.multipleUsers);
  }

  searchReport(event?: any): void {
    if (this.validateEntries()) {
      let _params: ReportOptions = this.formReportParams();
      this.reportOption = _params;
    }

  }
  formReportParams(): ReportOptions {
    let ctl = this.transactionForm.controls;
    let toApi = this.localization.convertDateObjToAPIdate;
    let _logDate = ctl["date"].value != null && ctl["date"].value != "" ? toApi(ctl["date"].value) : null;
    let _propertyName = this.localization.GetPropertyInfo("PropertyName");
    const userPreferredLang = this.localization.GetPropertyInfo('UserLanguage');
    const preferredLanguage = userPreferredLang !== '' ? userPreferredLang : this.localization.GetPropertyInfo('Language');

    if (this.isAuditReport) {
      return {
        code: "AuditReport",
        params: [{ "pPropertyName": _propertyName },
        { "pDate": this.localization.ConvertDateToISODateTime(new Date()) },
        { "pReportDate": _logDate }        
        ],
        URIParams: [{ "StartDate": _logDate }],
        Filter: [],
        pageBreak: false,
        layout: "Portrait",
        language: preferredLanguage
      };
    }
    else if(this.isStaffScheduleCreateEditDelete)
    {
      var therapistName = this.business.getFilterCaption(this.therapist, ctl["therapist"].value.map(t=>t.id));
      if(this.therapist.length==ctl["therapist"].value.length || ctl["therapist"].value.length==0)
      {
        this.isAllChecked =true;
      }
      else{
        this.isAllChecked =false;
      }
     this.isAllUserChecked = (this.users.length == ctl["multipleUsers"].value.length || ctl["multipleUsers"].value.length == 0);
      return {
      code: "TransLog",
      params: [{ "pPropertyName": _propertyName },
      { "pDate": this.localization.ConvertDateToISODateTime(new Date()) },
      { "pFilterDate": _logDate },
      { "pTransactionNumber": ctl["transactionNumber"].value },
      { "pUserId": this.isAllUserChecked ? "ALL" : ctl["multipleUsers"].value.map(t => t.id).join(",") },
      { "pClientFirstName": ctl["firstName"].value },
      { "pClientLastName": ctl["lastName"].value },
      { "pAppointmentNumber": ctl["appointmentNumber"].value },
      { "pIncludeTempAppointment": ctl["includetempappointment"].value },
      { "pTherapists":therapistName}
      ],
      URIParams: [
      { "LogDate": _logDate },{ "FirstName": ctl["firstName"].value }, { "LastName": ctl["lastName"].value },
      { "UserId": ctl["userID"].value }, { "TransactionId": ctl["transactionNumber"].value },
      { "appointmentId": ctl["appointmentNumber"].value }, 
      { "LogType": ctl["logType"].value }, { "IncludeTempAppointment": ctl["includetempappointment"].value }
      ],
      Filter: [],
      filters: [
        {"TherapistIds":this.isAllChecked ? [] : ctl["therapist"].value.map(t => t.id)},
        {"UserIds":this.isAllUserChecked ? [] : ctl["multipleUsers"].value.map(t => t.id)}
      ],
      pageBreak: false,
      layout: "Portrait",
      language: preferredLanguage
    };

    }
    else {
      return {
        code: "TransLog",
        params: [{ "pPropertyName": _propertyName },
        { "pDate": this.localization.ConvertDateToISODateTime(new Date()) },
        { "pFilterDate": _logDate },
        { "pTransactionNumber": ctl["transactionNumber"].value },
        { "pUserId": ctl["userID"].value },
        { "pClientFirstName": ctl["firstName"].value },
        { "pClientLastName": ctl["lastName"].value },
        { "pAppointmentNumber": ctl["appointmentNumber"].value },
        { "pIncludeTempAppointment": ctl["includetempappointment"].value }
        ],
        URIParams: [{ "FirstName": ctl["firstName"].value }, { "LastName": ctl["lastName"].value },
        { "UserId": ctl["userID"].value }, { "TransactionId": ctl["transactionNumber"].value },
        { "appointmentId": ctl["appointmentNumber"].value }, { "LogDate": _logDate },
        { "LogType": ctl["logType"].value }, { "IncludeTempAppointment": ctl["includetempappointment"].value }
        ],
        Filter: [],
        pageBreak: false,
        layout: "Portrait",
        language: preferredLanguage
      };
    }
  }

  private async selectionChange(evt: any) {
    if (evt.value == 'AuditReport') {
      this.isAuditReport = true;
      this.maxDate = null;
      this.minDate = this.PropertyInfo.CurrentDate;
      this.isStaffScheduleCreateEditDelete=false;
    }
    else if(evt.value == "StaffScheduleEdit" || evt.value == "StaffScheduleCreate" ||  evt.value == "StaffScheduleDelete")
    {
      this.isAuditReport = false;
      this.minDate = null;
      this.maxDate = this.PropertyInfo.CurrentDate;
      this.isStaffScheduleCreateEditDelete = true;
      this.therapist = await this.dataService.getAllTherapist(this.transactionForm.controls['logType'].value,true);          
      this.multipleUsers = this.users.map(user => {       
        return {
          description: user.userName,
          id: user.userId,
          isActive: user.isActive,
          showInDropDown: user.isActive
        }
      });
    }
    else {
      this.isAuditReport = false;
      this.minDate = null;
      this.maxDate = this.PropertyInfo.CurrentDate;
      this.isStaffScheduleCreateEditDelete =false;
    }
  }

  // private ctlValue(ctrl: string): string {
  //   return this.transactionForm.controls[ctrl].value
  // }

  async ngOnInit() {
    let currentDate = this.PropertyInfo.CurrentDate;
    this.transactionForm = this.fb.group({
      logType: [''],
      transactionNumber: [''],
      startDate: [''],
      endDate: [''],
      firstName: [''],
      lastName: [''],
      therapist: new UntypedFormControl([this.defaultData]),
      date: [currentDate],
      appointmentNumber: [''],
      includetempappointment: false,
      userID: [''],
      multipleUsers: new UntypedFormControl([this.defaultData])
    })
    this.captions = this.spaConfig.captions.reports;
    this.commonCaptions = this.spaConfig.captions.common;

    this.getTransactionData();
    this.users=await this.getUserInfoByPropertyId();  
    let selectedMenuTxt = this._routingMenuService.selectedRoutingMenu?.text;
    let selectedLogType;
    if(selectedMenuTxt){
      selectedLogType = this.logType.find(x => x.logtype == selectedMenuTxt)?this.logType.find(x => x.logtype == selectedMenuTxt):this.logType[0];
      this.transactionForm.valueChanges.subscribe(a => this.validateEntries());
      this.transactionForm.controls.logType.setValue(selectedLogType.code);     
      const logSelection = { value: selectedLogType.code };      
      await this.selectionChange(logSelection);
    }
    else{
       this.transactionForm.valueChanges.subscribe(a => this.validateEntries());
       this.transactionForm.controls.logType.setValue(this.logType[0].code);
    }    
  }   

  async getUserInfoByPropertyId(): Promise<Users[]> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetAllUsersByPropertyId',
      method: HttpMethod.Get,
      host: Host.authentication,
      uriParams: {
        propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
        productId: Number(this.utils.GetPropertyInfo('ProductId'))
      }
    });
    const response: any = result && result.result ? result.result : [];
   return this.users= _.orderBy(response, [response => response.userName.toLowerCase()], 'asc');   
  }

  private validateEntries(): boolean {
    let IsValid: boolean = Object.values(this.transactionForm.value).filter(value => (value != "" && value != null)).length > 0;
    this.showError = !IsValid;
    this.disableSearchBtn = this.showError;
    this.showTempAppointmentToggle();
    return IsValid;
  }

  showTempAppointmentToggle() {
    if (this.transactionForm.value.logType == "" || this.transactionForm.value.logType == null || this.transactionForm.value.logType == "AppointmentCreate" || this.transactionForm.value.logType == "AppointmentEdit") {
      this.showIncludeTemp = true;
    } else {
      this.showIncludeTemp = false;
    }
  }
 
  reportOption: ReportOptions;
  //event from fast report component for disabling generate button when API call is in progress
  disableGenerateButtonEvent(IsEnable: boolean) {
    this.disableSearchBtn = IsEnable;
  }

  changeToggleEvent(event) {
    if (event[0]) {
      this.transactionForm.controls['includetempappointment'].setValue(true);
    } else {
      this.transactionForm.controls['includetempappointment'].setValue(false);
    }
  }

  getFormControlValue(event) {
    if (event[0] === 'therapist') {
      this.transactionForm.setControl('therapist', event[1]);
    }
    if (event[0] === 'multipleUsers') {
      this.transactionForm.setControl('multipleUsers', event[1]);
    }
  }


}
