export namespace API {

    export interface DataUtilityDetails {
        id: number,
        fileName: string;
        moduleName: string;
        status: any;
        utilityType: DataUtilityType;
        dataUtilityErrors?: DataUtilityErrors[],
        totalRows: number,
        completedRows: number,
        dataUtilityImportData: DataUtilityImportData[],
        dataUtilityExportData: DataUtilityExportData[],
        base64ExportFileData: string 
    }

    export interface DataUtilityErrors{
        errorRowNo: number;
        errorCode: string;
        errorMessage: string;
        screenName: string;
    }

    export interface DataUtilityImportData{
        importData: string;
        totalRows: number;
        excelSheetName: string;
    }

    export interface DataUtilityExportData{
        screenName: string;
    }

    export enum Status {
        Queued = 1,
        Inprogress,
        CompletedWithErrors,
        Completed,
        Failed,
        Started
    }

    export enum DataUtilityType
    {
        Export = 1,
        Import
    }

    export interface ModuleFile{
        moduleName: string;
        fileName: string;
        listOrder: number;
        disabled: boolean;
    }

    export interface BulkImport{
        id: number;
        propertyId: number;
        subPropertyId: number;
        productId: number;
        status: number;
        isActive: boolean;
    }

    export interface BulkImportDetails{
        id: number;
        bulkImportId: number;
        fileName: string;
        moduleName: string;
        status: number;
        dataUtilityId: number;
        isDeleted: boolean;
    }

    export interface BulkImportStatusCount{
        isActive: boolean;
        queued: number;
        inprogress: number;
        completedWithErrors: number;
        completed: number;
        failed: number;
        started: number;
        progressCount: number;
    }

    export interface DataImportDetails {
        datautilityId: number;
        serialNumber: number;
        fileName: string;
        status: any;
        totalRows: number;
        completedRows: number;
        date: any;
        userName: string;
    }
    export interface VersaSettings{
        skipGuestAdvanceSearch : boolean;
        allowOverrideGuestGuid : boolean;
    }
    export interface CommonSettings{
        maximumImportCount : number;  
    }
    export interface Setting  {
        id: number;        
        settingType: string;
        value: string;        
    }

    export enum SettingKeys{        
        VersaSettings = 'VersaSettings',
        CommonSettings = 'CommonSettings'
    }
    
    export enum ProductSetting {
        CommonSettings = 1, 
        VersaSettings = 2        
    }

}
export namespace UI {
    export interface DataUtilityDetails {
        id: number,
        fileName: string;
        moduleName: string;
        status: Status;
        utilityType: DataUtilityType;
        dataUtilityErrors?: DataUtilityErrors[],
        totalRows: number,
        completedRows: number,
        dataUtilityImportData: DataUtilityImportData[],
        dataUtilityExportData: DataUtilityExportData[],
        exportFileStream: any //Export Link to be displayed in export history table
    }
    export interface DataImportDetails {
        id: number;
        serialNumber: number;
        fileName: string;
        status: any;
        totalRows: number;
        completedRows: number;
        date: string;
        userName: string;
    }
    export interface DataUtilityErrors{
        errorRowNo: number;
        errorCode: string;
        errorMessage: string;
        screenName: string;
    }

    export interface DataUtilityImportData{
        importData: string;
        totalRows: number;
    }

    export interface DataUtilityExportData{
        screenName: string;
    }

    export interface fileImport {
        id: number,
        queue: string,
        fileName: string,
        status: number,
        dataUtilityErrors?: DataUtilityErrors[],
    }

    export enum Status {
        Queued=1,
        Inprogress,
        CompletedWithErrors,
        Completed,
        Failed,
        Started
    }

    export enum DataUtilityType
    {
        Export = 1,
        Import
    }

    export interface BulkImportModuleFiles{
        moduleName: string;
        value: string;
        checked: boolean;        
    }

    export interface BulkImportGrid{
        id: number;
        queue: string;
        fileName: string;
        status: number;    
        dataUtilityId:number;    
    }

    export interface VersaSettings{        
        skipGuestAdvanceSearch : boolean;
        allowOverrideGuestGuid : boolean;
        maximumImportCount : number;
    }
    
    export interface Setting  {
        id: number;        
        settingType: string;
        value: string;        
    }
}

export interface DataMigrationStatisticsRequest
{
    moduleName: string;
    startDate: string;
    endDate: string;
}

export interface DataMigrationStatistics
{
    id: number;
    moduleName: string;
    startDate: string;
    endDate: string;
    processedDate: string;
    statisticalDetails?: StatisticalDetails[];
}

export interface StatisticalDetails
{
    id: number;
    dataMigrationStatisticsId: number;
    screenName: string;
    externalPMSCount: number;
    versaCount: number;
    failedCount: number;
    totalDurationMins: number;        
}
