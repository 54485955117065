import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { StateService } from './state.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RouteNavigateService {
  reloadRestrictRoutes = ['/setup/setup-form'];
  codec = new HttpUrlEncodingCodec;
  constructor( private router: Router, private stateService: StateService) {}

  navigateRoute(routeUrl: string, extras: NavigationExtras = null) {
    let routigUrl = `/${routeUrl}`;
    // if (!this.stateService.onPrem) {
    //   routigUrl = `/${this.stateService.tenant}/${routeUrl}`;
    // }
    if (extras) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          data: this.jsEncode(JSON.stringify(extras.queryParams))
        }
      };
      this.router.navigate([routigUrl], navigationExtras);
    } else {
      this.router.navigate([routigUrl]);
    }
  }

  jsEncode(param: string) {
    return encodeURIComponent(param);
  }

  libnavigateRoute(routeUrl: any) {
    let routigUrl = `/${routeUrl.NavigationUrl}`;
    if (!this.stateService.onPrem) {
      routigUrl = `/${this.stateService.tenant}/${routeUrl.NavigationUrl}`;
    }
    if ( routeUrl && routeUrl.hasOwnProperty('NavigationExtras') && routeUrl.NavigationExtras !== null) {
      this.router.navigate([routigUrl], routeUrl.NavigationExtras);
    }
    if ( routeUrl && routeUrl.hasOwnProperty('NavigationExtras') && routeUrl.NavigationExtras === null) {
      this.router.navigate([routigUrl]);
    }   
  }

  navigateSameRoute(routeUrl: string, extras: NavigationExtras = null) {
    let routigUrl = `/${routeUrl}`;
    if (!this.stateService.onPrem) {
      routigUrl = `/${this.stateService.tenant}/${routeUrl}`;
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      if (extras) {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            data: this.jsEncode(JSON.stringify(extras.queryParams))
          }
        };
        this.router.navigate([routigUrl], navigationExtras);
      } else {
        this.router.navigate([routigUrl]);
      }
    });
  }
}
