import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-instructor-schedule-edit-modal',
  templateUrl: './instructor-schedule-edit-modal.component.html',
  styleUrls: ['./instructor-schedule-edit-modal.component.scss']
})
export class InstructorScheduleEditModalComponent implements OnInit {
  @Input() dataInput;
  scheduledData: any;
  therapistId = 0;
  value: number = 0;
  captions: any;
  constructor(public dialogRef: MatDialogRef<InstructorScheduleEditModalComponent>, private localization: SpaLocalization) { }

  ngOnInit() {
    this.scheduledData = this.dataInput.data;
    this.captions = this.localization.captions.staffSchedule;
    this.value = this.scheduledData[0].id;
  }

  editSchedule() {
    let scheduleToEdit = this.scheduledData.find(x => x.id === this.value);
    this.dialogRef.close(scheduleToEdit);
  }

  cancel() {
    this.dialogRef.close();
  }
}


