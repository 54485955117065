import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DetailsPayload, FileViewData, UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button/public-api';
import { ToastrService } from 'ngx-toastr';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { Subscription } from 'rxjs';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FormGroup } from '@angular/forms';
import { FileAttachedConfig, ImageAttachedConfig, TransactionConfiguration, TransactionTableData, VideoAttachedConfig, ViewAttached } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { odataInventorySuccess } from 'src/app/eatecui/source/store/selectors/odata-configuration.selector';
import { DeletePicture } from '../agilysys-picture-popup/agilysys-picture-popup.component';
import { DocFormats, ExcelFormats, ImageFormats, VideoFormats } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import _ from 'lodash';
import { AgilysysConfirmationPopupComponent } from '../agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { AgilysysVideoStremingComponent } from '../agilysys-video-streming/agilysys-video-streming.component';
import { PdfViewerPopup, StreamingModel } from '../model/pop-up.interface';
import { StateService } from 'src/app/eatecui/source/shared/services/state.service';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { AgilysysPdfViewerComponent } from '../agilysys-pdfviewer/agilysys-pdfviewer-popup.component';
import { DataMagineDelete, DataMagineDownload, } from 'src/app/eatecui/source/shared/integration/integration-interface';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { AuthPayload } from 'src/app/eatecui/source/shared/integration/integration-interface';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { AgilysysAttachedFilesPopupService } from './agilysys-attached-files-popup.service';
import { ActionIconButtons, GridConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

@Component({
    selector: 'app-agilysys-attached-files-popup',
    templateUrl: './agilysys-attached-files-popup.component.html',
    styleUrls: ['./agilysys-attached-files-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class AgilysysattachedfilesPopupComponent implements OnInit {
    @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
    ifNoitemsFound: boolean;
    agilysysDeleteButton: AgilysysButtonModel[];
    agilysysCofirmationButton: AgilysysButtonModel[];
    fileFormats: any[];
    fileSizeLimit = 20000000000000;
    fileSizeExceeded = [];
    files: any = [];
    fileArray: any = [];
    validFormats: Array<any> = [];
    fileDataCollection: Array<UploadMetaData> = [];
    TransactionGroupId: number;
    TransactionDataSuccess: Subscription;
    OdataGlobalEntityModel: OdataGlobalEntity;
    createTrasacationHeaderEntity: any;
    createTransactionDetailEntity: any;
    createTransactionTilesHeaderEntity: any;
    postModelHederEntity: any;
    postModelDetailEntity: any;
    transactionJsonData: any;
    params = {} as QueryParams;
    selectedVideo: boolean;
    homeGroup: FormGroup;
    selectedTransaction: any;
    ifviewFlow: boolean;
    selectedId: number;
    from: any;
    transactionFrom: any;
    selectedSeqNo: number;
    fileViewCollection: Array<any>;
    saveBeforeLogout: Subscription;
    videoChecked: boolean;
    fileChecked: boolean;
    imageChecked: boolean;
    workbookChecked: boolean;
    jsonChecked: boolean;
    pptChecked: boolean;
    zipChecked: boolean;
    textChecked: boolean;
    checkedVideoLength: any;
    checkedImageLength: any;
    checkedFileLength: any;
    checkedWorkBookLength: any;
    checkedJsonLength: any;
    checkedPPTLength: any;
    checkedZipLength: any;
    checkedTextLength: any;
    selectedImage: any;
    showFlag: boolean;
    selectedImageIndex: number;
    showPdf: boolean;
    totalSegments: number;
    segmentLength: number;
    bytesFetched: any = 0;
    requestedSegments = [];
    segmentDuration = 0;
    assetUrl: string;
    pdfUrl: any;
    enableUpload = true;
    gridData: GridConfig;
    showGrid = false;
    fromPage: string;
    selectedList: Array<any> = [];
    dmIntegCollect: any;
    allowedFileText: string;
    selectedMessageId: number;
    showmp4Text = false;
    isAgysUser: string;
    setupDMMappingsEnabled = false;
    fromCopyAsNew = false;
    PopupHeaderName: string;
    //showmp4Text = false;
    constructor(public dialogRef: MatDialogRef<AgilysysattachedfilesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpservice: HttpService,
    private masterManagementService: MasterManagementService,
    private router: ActivatedRoute,
    private toasterService: ToastrService,
    private store: Store<AppState>,
    private transactionService: TransactionService,
    private httpService: HttpService,
    private routeNavigateService: RouteNavigateService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private stateService: StateService,
    private envService: EnvService,
    public configService: ConfigService,
    private attachedFilesService: AgilysysAttachedFilesPopupService,
    private integrationService: IntegrationService) {
      this.isAgysUser = StorageService.GetSessionStorage('isAgilysysEmployee');  
      const authorizeData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
      const setupSubModules = authorizeData?.filter(x => x.Name === 'setupmanagement')[0].SubModules;
      const setupPermissionResources = setupSubModules?.filter(m => m.Name === 'setup')[0].PermissionResource;
      this.setupDMMappingsEnabled = setupPermissionResources?.filter(r => r.Name === 'DMMappings')[0].IsActive; 
        this.TransactionDataSuccess = this.store.pipe(select(odataInventorySuccess)).subscribe((x: OdataGlobalEntity) => {
            if (!this.OdataGlobalEntityModel) {
            if (x && x !== null) {
              this.OdataGlobalEntityModel = x;
              this.selectedTransaction =
              this.data.queryParams.transaction === 'sales' ? 
              this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'sales', null) : 
              this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'transaction', null);
              this.transactionFrom =
               this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'transactionFrom', null);
              this.selectedId =
               parseInt( this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'rowId', 'rowId'), 10);
              if (this.data.queryParams.transaction.indexOf('message') > -1) {
                this.selectedMessageId = parseInt(
                   this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'messageId', 'messageId'), 10);
              }
              this.selectedSeqNo =
               ( this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'customId', null));
              this.from =
                this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'from', null);
              this.fromCopyAsNew = this.masterManagementService.masterDecodeUri(this.router.snapshot.queryParams, 'copyasnew', null);
              this.PopupHeaderName = this.data.queryParams.from === 'detailsPage' ?  'View Attachment(s)' : 'Upload File(s)';
              this.transactionJsonData = this.data.queryParams.transactionJson;
              this.fromPage = this.data.queryParams.fromPage;              
              this.dmIntegCollect = this.integrationService.IntegrationConfig ? 
                this.integrationService.IntegrationConfig.IntegrationCollection.filter(y =>
                   y.IntegratonName === 'DataMagine')[0] : null;
              this.getTransactionJsonData();
            } else {
              this.masterManagementService.GetOdataGlobalCollection();
            }
          }
          });
          this.agilysysCofirmationButton = [
            {
              displayProperity: false,
              displayName: 'Cancel',
              buttonKey: 'Cancel',
              buttonType: 'agilysys-button-secondary',
              buttonCallBack: (callBackData: CallBackData) => {
                const buttonCallbackEvent = {
                  'ButtonEvent': callBackData.buttonValue.buttonKey,
                  'FileCollection': this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData
                };
                this.dialogRef.close(buttonCallbackEvent);
              }
            },
            {
              displayProperity: true,
              displayName: 'Confirm',
              buttonKey: 'Confirm',
              buttonType: 'agilysys-button-primary',
              buttonCallBack: (callBackData: CallBackData) => {
                  const buttonCallbackEvent = {
                    'ButtonEvent': callBackData.buttonValue.buttonKey,
                    'FileCollection': this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData
                  };
                  this.dialogRef.close(buttonCallbackEvent);
              }
            }
        ];
      const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
        'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
      const IntegrationIndex: number = AllowedIntegration.findIndex(x => x === this.selectedTransaction); 
        this.fileFormats = (this.configService.enableIntegration && IntegrationIndex > -1 && this.dmIntegCollect.IsEnabled && this.setupDMMappingsEnabled) ? 
        ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
        'application/msword', 'application/docx', 'text/csv', 'application/vnd.ms-powerpoint', 
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/zip', 'text/plain', 'application/vnd.ms-excel', 
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-zip-compressed'] :
      (this.data && this.data.queryParams && this.data.queryParams.transaction.indexOf('message') > -1) ? [
        'image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'video/mp4', 'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/docx', 'text/csv',
     'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] :
       ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'video/mp4', 'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'application/docx', 'application/x-zip-compressed'];
       this.fileSizeLimit = (this.configService.enableIntegration && IntegrationIndex > -1 && this.dmIntegCollect.IsEnabled && this.setupDMMappingsEnabled) ? 5242880 :
       this.fileSizeLimit;
          if (this.data.queryParams.from === 'detailsPage' || this.data.queryParams.from === 'view-message') {
            this.enableUpload = false;
          }
        if (this.transactionJsonData !== undefined) {
          if (this.transactionJsonData.AttachedFilesConfig?.AttachMentConfig !== undefined) {
            if (!this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData) {
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData = [];
            }
            this.agilysysCofirmationButton.forEach((x: any) => {
              if (this.transactionJsonData.AttachedFilesConfig?.AttachMentConfig.FileAttachedData.length > 0) {
                if (x.buttonKey === 'Confirm') {
                  x.displayProperity = false;
                }
              }
            });
          }
        }
        // this.ifNoitemsFound = true;
        this.imageChecked = false;
        this.fileChecked = false;
        this.videoChecked = false;
        this.workbookChecked = false;
        this.showFlag = false;
        this.selectedImageIndex = -1;
        this.showPdf = false;
        this.totalSegments = 5;
        this.segmentLength = 0;
        if (IntegrationIndex > -1) {
          if (data.queryParams.attachedFileConfig.IntegrationCollection && 
            data.queryParams.attachedFileConfig?.IntegrationCollection?.filter(x => x.IntegratonName === 'DataMagine' 
             && x.IsEnabled).length > 0 && this.setupDMMappingsEnabled) {
              this.allowedFileText = data.queryParams.attachedFileConfig.IntegrationCollection.filter(x => 
                x.IntegratonName === 'DataMagine' 
              && x.IsEnabled)[0].AllowedFileText;
              this.showmp4Text = false;
          } else {
            this.allowedFileText = 
            '[Only png, jpg, jpeg, pdf, doc, docx and bmp formats allowed.Maximum upload per file size is 2 MB]';
            
            const mp4NotAllowed = ['openpurchaserequest', 'allpurchaserequest', 'purchaseorder', 'openpurchaseorder', 'poopenpr', 'poopenrequisition',
            'receiving', 'savedreceiving', 'requisition', 'opentransaction', 'transfer', 'in-transit', 'spoilage', 'apvoucherrni', 'returntovendor'];
          const isMp4NotAllowed = mp4NotAllowed.findIndex(x => x === this.selectedTransaction);
          this.showmp4Text = (isMp4NotAllowed > -1) ? false : true;
          }          
        } else {
          this.allowedFileText = 
          '[Only png, jpg, jpeg, pdf, doc, docx and bmp formats allowed.Maximum upload per file size is 2 MB]';
          
          const mp4NotAllowed = ['openpurchaserequest', 'allpurchaserequest', 'purchaseorder', 'openpurchaseorder', 'poopenpr', 'poopenrequisition',
            'receiving', 'savedreceiving', 'requisition', 'opentransaction', 'transfer', 'in-transit', 'spoilage', 'apvoucherrni', 'returntovendor'];
          const isMp4NotAllowed = mp4NotAllowed.findIndex(x => x === this.selectedTransaction);
          this.showmp4Text = (isMp4NotAllowed > -1) ? false : true;
        }
    }
    CancelClick() {
      const buttonCallbackEvent = {
        'ButtonEvent': 'Cancel',
        'FileCollection': this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData
      };
      this.dialogRef.close(buttonCallbackEvent);
    }
    async getTransactionJsonData() {
      try {
        const _that = this;
        if (!this.transactionJsonData) {
          this.transactionJsonData = await this.transactionService.GetTransactionJsonData(this.selectedTransaction);
        }
        const getHeaderOdataKey = this.transactionService.getOdataKey(this.transactionJsonData, 'ViewHeaderOdataKey');
        this.createTrasacationHeaderEntity = this.masterManagementService.GetViewModelData(
          this.OdataGlobalEntityModel.OdataViewModelEntity, getHeaderOdataKey);
        const getDetailOdataKey = this.transactionService.getOdataKey(this.transactionJsonData, 'ViewDetailOdataKey');
        this.createTransactionDetailEntity = this.masterManagementService.GetViewModelData(
          this.OdataGlobalEntityModel.OdataViewModelEntity, getDetailOdataKey);
        const getTilesHeaderOdataKey = this.transactionService.getOdataKey(this.transactionJsonData, 'ViewHeaderTilesOdataKey');
        this.createTransactionTilesHeaderEntity = this.masterManagementService.GetViewModelData(
          this.OdataGlobalEntityModel.OdataViewModelEntity, getTilesHeaderOdataKey);
        const postentityname = this.transactionJsonData.PostEntityName;
        this.postModelHederEntity = this.masterManagementService.GetPostModelData(
          this.OdataGlobalEntityModel.OdataPostModelEntity, postentityname);
        this.postModelDetailEntity = this.masterManagementService.GetPostModelData(
          this.OdataGlobalEntityModel.OdataPostModelEntity, getDetailOdataKey);
        if (this.transactionService.transactionConfigurationData &&
          this.transactionService.transactionConfigurationData.AttachedFilesConfig) {
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig =
          // eslint-disable-next-line brace-style
          {...this.transactionService.transactionConfigurationData.AttachedFilesConfig.AttachMentConfig};
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData = [];
            this.transactionJsonData = { ...this.transactionJsonData };
            if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.length === 0 &&
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.length === 0 &&
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.length === 0 &&
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.length === 0 &&
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.length === 0 &&
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.length === 0 &&
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.length === 0 &&
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.length === 0) {
                this.ifNoitemsFound = true;
            } else {
              this.ifNoitemsFound = false;
            }
        }
        if (this.selectedId && this.selectedId > 0 && !this.fromCopyAsNew) {
          this.showGrid = this.fromPage === 'copyasnew' ? true : false;
          const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
            'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
          const IntegrationIndex: number = AllowedIntegration.findIndex(x => x === this.selectedTransaction);
            if (this.configService.enableIntegration && IntegrationIndex > -1 && this.dmIntegCollect.IsEnabled && this.setupDMMappingsEnabled) {            
                this.integrationService.dmIntergerationInstance.GetModuleIndexValue(this.transactionJsonData.Name, 'DMIntegration');
            const GridTableData: Array<TransactionTableData> = this.fromPage !== 'copyasnew' ?
            this.transactionJsonData.CreateTranscationConfig.DetailsConfig.DetailTableCard.TransactionGridTableCard.GridTableData
            : this.transactionJsonData.CopyAsNewConfig.CopyNewTableCard.DetailsTableCardBuilder.GridTableData; 
            const gridData =  _.cloneDeep(GridTableData);
            let idData: any;
              switch (this.selectedTransaction) {
                case 'receiving':
                  idData = {};
                  idData.DisplayName = 'Transaction ID';
                  idData.HeaderName = 'TransactionID';
                  idData.Odatakey = 'TransactionID';
                  idData.Value = this.selectedId;
                  break;
                case 'production':
                  idData = {};
                  idData.DisplayName = 'Production #';
                  idData.HeaderName = 'Production';
                  idData.Odatakey = 'Production';
                  idData.Value = this.selectedId;
                  break;
                case 'requisition':
                  idData = {};
                  idData.DisplayName = 'Requisition ID#';
                  idData.HeaderName = 'RequisitionsID';
                  idData.Odatakey = 'RequisitionsID';
                  idData.Value = this.selectedId;
                  break;
                case 'transfer':
                  idData = {};
                  idData.DisplayName = 'Transfer ID#';
                  idData.HeaderName = 'TransfersID';
                  idData.Odatakey = 'TransfersID';
                  idData.Value = this.selectedId;
                  break;
                case 'spoilage':
                  idData = {};
                  idData.DisplayName = 'Spoilage ID#';
                  idData.HeaderName = 'SpoilageID';
                  idData.Odatakey = 'SpoilageID';
                  idData.Value = this.selectedId;
                  break;
                case 'allpurchaserequest':
                case 'openpurchaserequest':
                  const IndexNo = gridData.findIndex(x => x.Odatakey.toLowerCase() === 'transsequenceno');
                  if (IndexNo !== -1) {
                    gridData[IndexNo].Value = this.selectedId;
                  }
                  break;
              }
            // const idData = {
            //   DisplayName: this.selectedTransaction === 'receiving' ? 'Transaction ID' :
            //     this.selectedTransaction === 'production' ? 'Production #' : this.selectedTransaction === 'sales' ? 'Sale #' : 
            //     this.selectedTransaction === 'requisition' ? 'Requisition ID#' : this.selectedTransaction === 'transfer' ?
            //     'Transfer ID#' : this.selectedTransaction === 'spoilage' ? 'Spoilage ID#' : '',
            //   HeaderName: this.selectedTransaction === 'receiving' ? 'TransactionID' :
            //     this.selectedTransaction === 'production' ? 'Production' : this.selectedTransaction === 'sales' ? 'Sales' : 
            //     this.selectedTransaction === 'requisition' ? 'RequisitionsID' : this.selectedTransaction === 'transfer' ?
            //     'TransfersID' : this.selectedTransaction === 'spoilage' ? 'SpoilageID' : '',
            //   Odatakey: this.selectedTransaction === 'production' ? 'Production' :
            //     this.selectedTransaction === 'receiving' ? 'TransactionID' : this.selectedTransaction === 'sales' ? 'Sales' : 
            //     this.selectedTransaction === 'requisition' ? 'RequisitionsID' : this.selectedTransaction === 'transfer' ?
            //     'TransfersID' : this.selectedTransaction === 'spoilage' ?  'SpoilageID' : '',
            //   Value: this.selectedId
            // };
            if (idData) {
              gridData.push(idData);
            }
              const documentPayloadModel = this.integrationService.dmIntergerationInstance.ConstractDataDocumentPayload(gridData);
              if (documentPayloadModel) {
                await this.integrationService.dmIntergerationInstance.GetDataMagineDocument(documentPayloadModel);
              }
              if (this.showGrid) {
                // this.gridData = this.attachedFilesService.
                //   formGridConfiguration(this.integrationService.dmIntergerationInstance.integrationGridData, this.transactionService); 
                this.gridData = this.integrationService.dmIntergerationInstance.constractGridData();
                this.gridData.rowChecked = (value: any) => {
                  console.log('Row Checked', value);
                  this.selectedList = value;
                };
                this.gridData.rowActionClicked = (value: any) => {
                  console.log('Row Action Clicked', value);
                  if (value[1] === 'download') {
                    console.log('DownLoad Click', value);
                    const dataMagineDownload: DataMagineDownload = {
                      FolderId: value[0].FolderNumber,
                      DocumentId: value[0].DocumentNumber,
                      FolderName: value[0].FolderName,
                      CreatedDate: value[0].CreatedDate
                    };
                    this.integrationService.dmIntergerationInstance.DownLoadDataMagineFile(dataMagineDownload);
                  } else if (value[1] === 'delete') {
                    const dataMagineDelete: DataMagineDelete = {
                      ids: value[0].FolderNumber + '-' + value[0].DocumentNumber,
                      ViewName: documentPayloadModel.Viewname
                    };
                    this.integrationService.dmIntergerationInstance.DelteDataMagineFile(dataMagineDelete).then(res => {
                      console.log('Response Delete', res);
                      if (res) {
                        this.gridData.GridData.splice(value[2].rowIndex, 1);
                        this.gridData.GridData = [...this.gridData.GridData];
                        this.gridData = {...this.gridData};
                        this.toasterService.success('Files Deleted Successfully', '', {
                          timeOut: 3000,
                          closeButton: true,
                        });
                      }
                    });
                  }
                };
                this.gridData.iconActionClicked = (value: ActionIconButtons) => {
                  console.log('Icon Action Button', value);
                  if (value.Name === 'Delete') {
                    const selectedList = [];
                    this.selectedList.forEach(res => {
                      selectedList.push( res.DocumentNumber).toString();
                    });
                    const folderNumber = this.selectedList[0].FolderNumber + '-' + selectedList.join(',');
                    const dataMagineDelete: DataMagineDelete = {
                      ids: folderNumber,
                      ViewName: documentPayloadModel.Viewname
                    };
                    this.integrationService.dmIntergerationInstance.DelteDataMagineFile(dataMagineDelete).then(result => {
                      console.log('Response Delete', result);
                      if (result) {
                        selectedList.forEach(sl => {
                          const index = this.gridData.GridData.findIndex(d => d.DocumentNumber === sl);
                          if (index > -1) {
                            this.gridData.GridData.splice(index, 1);
                            this.gridData.GridData = [...this.gridData.GridData];
                            this.gridData = {...this.gridData};
                          }
                        });                        
                        this.toasterService.success('Files Deleted Successfully', '', {
                          timeOut: 3000,
                          closeButton: true,
                        });
                      }
                    });
                  }
                };
              } else {
                this.constractSavedAttachedImage(this.integrationService.dmIntergerationInstance?.integrationGridData?.Result);
              }
              console.log(this.integrationService.dmIntergerationInstance.integrationGridData);
          } else {
            this.showGrid = false;
            this.getTransactionPictures();
          }
        } else if (this.selectedMessageId && this.selectedMessageId > 0) {
          this.getTransactionPictures();
        }
      } catch (error) {
        console.error(error);
      }
    }
    async uploadFile(event: any) {
        this.files = [];
        const IngrdientId = this.data.queryParams.rowId;
        const transactionName = this.data.queryParams.transaction;
        const FileDetails: DetailsPayload = {
          ItemId: 0,
          GroupId: 0
        };
        switch (transactionName) {
          case 'openpurchaserequest':
          case 'allpurchaserequest':
            this.TransactionGroupId = 1;
            break;
          case 'purchaseorder':
          case 'openpurchaseorder':
          case 'poopenpr':
          case 'poopenrequisition':
            this.TransactionGroupId = 2;
            break;
          case 'receiving':
          case 'savedreceiving':
            this.TransactionGroupId = 3;
            break;
          case 'requisition':
            this.TransactionGroupId = 4;
            break;
          case 'opentransaction':
          case 'transfer':
            this.TransactionGroupId = 5;
            break;
          case 'spoilage':
            this.TransactionGroupId = 11;
            break;
          case 'apvoucherrni':
            this.TransactionGroupId = 16;
            break;
          case 'production':
            this.TransactionGroupId = 14;
            break;
          case 'butchery':
            this.TransactionGroupId = 30;
            break;
          case 'sales':
            this.TransactionGroupId = 12;
            break;
          case 'salesforecasting':
            this.TransactionGroupId = 27;
            break;
          case 'cycleplanning':
            this.TransactionGroupId = 28;
            break;
          default:
            this.TransactionGroupId = 1;
            break;
        }
        FileDetails.ItemId = IngrdientId;
        FileDetails.GroupId = this.TransactionGroupId;
        const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
        'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
      const IntegrationIndex: number = AllowedIntegration.findIndex(x => x === this.selectedTransaction);    
      const dmEnabled = this.configService.enableIntegration && IntegrationIndex > -1 && this.dmIntegCollect.IsEnabled;
        const FileUploadResult: Array<any> = await FileData.FileUpload(event, this.httpservice, FileDetails, [],
           this.toasterService, dmEnabled);
           
        this.fileDataCollection = [...FileUploadResult];
        
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < event.length; index++) {
          const element = event[index];
          if (this.fileFormats.includes(event[index].type)) {
            if (event[index].size <= this.fileSizeLimit) {
              const filedata = await this.readFileBinary(element);
              
              element['Base64Url'] =element.type==="application/pdf"? filedata.split(';base64,')[1]:filedata;
              element['Base64string']=filedata.split(';base64,')[1]
              
              if (this.fileDataCollection.findIndex(x => x.FileName === event[index].name) !== -1 ) {
                this.files.push(element);
              }
            } else {
              this.fileSizeExceeded.push(true);
            }
          } else {
            this.validFormats.push(false);
          }
        }
        // if (this.validFormats.filter(x => x === false).length > 0) {
        //   alert('Invalid File Formats Uploaded');
        //   this.validFormats = [];
        // }
        // if (this.fileSizeExceeded.filter(x => x === true).length > 0) {
        //   alert('One or more file(s) has exceeded allowed upload file size.');
        //   this.fileSizeExceeded = [];
        // }
        if (this.files.length > 0) {
          this.ifNoitemsFound = false;
          const result = {
            ButtonEvent: '',
            Files: this.files,
            FileCollection: this.fileDataCollection
          };
          result.FileCollection.forEach((x: any) => {
              this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData.push(x);
          });          
          this.constractAttachedImage(result);
        }
        if (this.fileInput) {
          this.fileInput.nativeElement.value = '';
        }
    }
    readFileBinary(UploadedFile: any): Promise<any> {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(UploadedFile);
            fileReader.onload = (fileLoaded) => {
              return resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
              reject(error);
            };
          });
    }
    constractAttachedImage(AttachedImage: any): void {
        try {
          const attachedImageFiles = AttachedImage.Files;
          const attachedFileDataCollection = AttachedImage.FileCollection;
          this.ifNoitemsFound = false;
          
          attachedImageFiles.forEach((files: any, index: any) => {
            const attachedType = (files.type === 'text/csv' || files.type === 'application/json' || 
            files.type === 'application/vnd.ms-powerpoint' ||  files.type === 'application/vnd.ms-excel' ||
            files.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            files.type === 'application/x-zip-compressed') ? 
            files.type.split('/')[1]  : files.type.split('/')[0];    
                 
            switch (attachedType) {
              case 'image':
                const imageAttached = {} as ImageAttachedConfig;
                imageAttached.Id = 0;
                imageAttached.EnteredBy = 'Eatec';
                imageAttached.EnteredOn = new Date();
                imageAttached.FilePath = files.name;
                imageAttached.LinkDb = 0;
                imageAttached.OldIntNum = null;
                imageAttached.Picture = files.Base64Url;
                imageAttached.Name = files.name;
                imageAttached.Position = 0;
                imageAttached.Remark = 'Null';
                imageAttached.TransactionId = null;
                imageAttached.TransactionTypeId = this.transactionJsonData.TransactionType;
                imageAttached.Type = files.type;
                imageAttached.isChecked = false;
                imageAttached.IsActive = true;
                imageAttached.ContentType = files.type;
                imageAttached.FileName = files.name;
                const imageViewAttached = {} as ViewAttached;
                imageViewAttached.image = `data:image/gif;base64,${files.Base64Url}`;
                imageViewAttached.title = files.name;
                imageViewAttached.alt = files.name;
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.push(imageAttached);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(imageViewAttached);
                break;
              case 'video':
                const videoAttached = {} as VideoAttachedConfig;
                videoAttached.Id = 0;
                videoAttached.EnteredBy = 'Eatec';
                videoAttached.EnteredOn = new Date();
                videoAttached.FilePath = files.name;
                videoAttached.LinkDb = 0;
                videoAttached.OldIntNum = null;
                videoAttached.Picture = files.Base64Url;
                videoAttached.Name = files.name;
                videoAttached.Position = 0;
                videoAttached.Remark = 'Null';
                videoAttached.TransactionId = null;
                videoAttached.TransactionTypeId = this.transactionJsonData.TransactionType;
                videoAttached.Type = files.type;
                videoAttached.ContentType = files.type;
                videoAttached.FileName = files.name;
                videoAttached.isChecked = false;
                videoAttached.IsActive = true;
                const existLocalUrlCheck = attachedFileDataCollection.filter((x: any) => x.FileName === files.name)[0];
                if (existLocalUrlCheck) {
                  videoAttached.LocalUrl = existLocalUrlCheck.FileDetails.LocalUrl;
                }
                const videoViewAttached = {} as ViewAttached;
                videoViewAttached.video = `data:video/mp4;base64,${files.Base64Url}`;
                videoViewAttached.title = files.name;
                videoViewAttached.alt = files.name;
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.push(videoAttached);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(videoViewAttached);
                break;              
              case 'application':
                const fileAttachedConfig = {} as FileAttachedConfig;
                fileAttachedConfig.Id = 0;
                fileAttachedConfig.EnteredBy = 'Eatec';
                fileAttachedConfig.EnteredOn = new Date();
                fileAttachedConfig.FilePath = files.name;
                fileAttachedConfig.LinkDb = 0;
                fileAttachedConfig.OldIntNum = null;
                fileAttachedConfig.Picture = files.Base64Url;
                fileAttachedConfig.Name = files.name;
                fileAttachedConfig.Position = 0;
                fileAttachedConfig.Remark = 'Null';
                fileAttachedConfig.TransactionId = null;
                fileAttachedConfig.TransactionTypeId = this.transactionJsonData.TransactionType;
                fileAttachedConfig.Type = files.type;
                fileAttachedConfig.ContentType = files.type;
                fileAttachedConfig.FileName = files.name;
                fileAttachedConfig.isChecked = false;
                fileAttachedConfig.IsActive = true;
                const pdfViewAttached = {} as ViewAttached;
                pdfViewAttached.image = `data:image/gif;base64,${files.Base64Url}`;
                pdfViewAttached.title = files.name;
                pdfViewAttached.alt = files.name;
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.push(fileAttachedConfig);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(pdfViewAttached);
                break;
              case 'csv':
              case 'vnd.ms-excel':
              case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                const fileattachedConfig = this.getFileAttachedConfig(files);
                const viewAttachedConfig = this.getViewAttachedConfig(files);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.push(fileattachedConfig);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(viewAttachedConfig);
                break;
              case 'json':
                const jsonattachedConfig = this.getFileAttachedConfig(files);
                const jsonviewAttachedConfig = this.getViewAttachedConfig(files);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.push(jsonattachedConfig);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(jsonviewAttachedConfig);
                break;
              case 'vnd.ms-powerpoint':
              case 'vnd.openxmlformats-officedocument.presentationml.presentation':
                const pptattachedConfig = this.getFileAttachedConfig(files);
                const pptviewAttachedConfig = this.getViewAttachedConfig(files);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.push(pptattachedConfig);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(pptviewAttachedConfig);
                break;
              case 'x-zip-compressed':
                const zipattachedConfig = this.getFileAttachedConfig(files);
                const zipviewAttachedConfig = this.getViewAttachedConfig(files);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.push(zipattachedConfig);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(zipviewAttachedConfig);
                break;
              case 'text':
                const textattachedConfig = this.getFileAttachedConfig(files);
                const textviewAttachedConfig = this.getViewAttachedConfig(files);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.push(textattachedConfig);
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(textviewAttachedConfig);
                break;
              default:
              // code block
            }
          });
          //   this.transactionJsonData.AttachedFilesConfig.HeaderConfig.ActionButtonConfig[1].displayProperity = true;
          if (this.transactionJsonData.AttachedFilesConfig) {
            this.transactionService.transactionConfigurationData.AttachedFilesConfig = { ...this.transactionJsonData.AttachedFilesConfig };
          }
          this.enabledDeleteButton();
        } catch (error) {
          console.error(error);
        }
    }
    getFileAttachedConfig(files: any): FileAttachedConfig {
      try {
        const fileAttachedConfig = {} as FileAttachedConfig;
        fileAttachedConfig.Id = 0;
        fileAttachedConfig.EnteredBy = 'Eatec';
        fileAttachedConfig.EnteredOn = new Date();
        fileAttachedConfig.FilePath = files.name;
        fileAttachedConfig.LinkDb = 0;
        fileAttachedConfig.OldIntNum = null;
        fileAttachedConfig.Picture = files.Base64Url;
        fileAttachedConfig.Name = files.name;
        fileAttachedConfig.Position = 0;
        fileAttachedConfig.Remark = 'Null';
        fileAttachedConfig.TransactionId = null;
        fileAttachedConfig.TransactionTypeId = this.transactionJsonData.TransactionType;
        fileAttachedConfig.Type = files.type;
        fileAttachedConfig.ContentType = files.type;
        fileAttachedConfig.FileName = files.name;
        fileAttachedConfig.isChecked = false;
        fileAttachedConfig.IsActive = true;
        return fileAttachedConfig;
      } catch (error) {
        console.log(error);
      }      
    }
    getViewAttachedConfig(files: any): ViewAttached {
      try {
        const fileViewAttached = {} as ViewAttached;
        fileViewAttached.image = `data:image/gif;base64,${files.Base64Url}`;
        fileViewAttached.title = files.name;
        fileViewAttached.alt = files.name;
        return fileViewAttached;
      } catch (error) {
        console.log(error);
      }
    }
  async getTransactionPictures() {
    const hasStorageData = sessionStorage.getItem('StorageData');
    if (JSON.parse(hasStorageData) == null) {
      const FileDetails: DetailsPayload = {
        ItemId: 0,
        GroupId: 0,
        EndpointUrl: '',
        EndPoint: '',
        IsTransaction: true
      };
      switch (this.transactionJsonData.Name) {
        case 'Purchase Request':
          this.TransactionGroupId = 1;
          break;
        case 'Purchase Order':
          this.TransactionGroupId = 2;
          break;
        case 'Receiving':
          this.TransactionGroupId = 3;
          break;
        case 'Requisition':
          this.TransactionGroupId = 4;
          break;
        case 'Transfer':
          this.TransactionGroupId = 5;
          break;
        case 'spoilage':
          this.TransactionGroupId = 11;
          break;
        case 'AP RNI':
          this.TransactionGroupId = 16;
          break;
        case 'Production':
          this.TransactionGroupId = 14;
          break;
        case 'Butchery':
          this.TransactionGroupId = 30;
          break;
        case 'sales':
          this.TransactionGroupId = 12;
          break;
        case 'PlanHead':
          this.TransactionGroupId = 27;
          break;
        case 'cycleplanning':
          this.TransactionGroupId = 28;
          break;
        default:
          this.TransactionGroupId = 1;
          break;
      }
      if (this.data.queryParams.transaction.indexOf('message') > -1) {
        FileDetails.ItemId = !Number.isNaN(this.selectedMessageId) ?
         this.selectedMessageId : this.selectedId;
        FileDetails.GroupId = 6;
        FileDetails.IsTransaction = false;
      } else {
        FileDetails.ItemId = this.selectedId;
        FileDetails.GroupId = this.TransactionGroupId;
      }
      FileDetails.EndpointUrl = `/inventory/api/File/Details?`;
      FileDetails.EndPoint = `/inventory/api/File/Download?`;
      const ViewResponse: Array<FileViewData> = [];
      const FileViewResponse: any = await FileData.FileThumbView(ViewResponse, FileDetails, this.httpService, this.sanitizer, true);
      // this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.map(x => x.Picture = FileViewResponse);
      // this.fileViewCollection = [...FileViewResponse];
      if (FileViewResponse) {
        // console.log('this.agilysysDeleteButton',this.agilysysDeleteButton)
        // // this.agilysysDeleteButton[0].displayProperity = true;
        // this.agilysysDeleteButton[0].displayProperity = true;
        //     this.agilysysDeleteButton[0] = {...this.agilysysDeleteButton[0]};
        this.constractSavedAttachedImage(FileViewResponse);
      } else {
        this.ifNoitemsFound = true;
      }
      // this.httpService.GethttpMethod(Url).subscribe(response => {
      //   console.log(response);
      //   alert(response);
      //   const responseValue = response['value'];
      //   if (responseValue.length > 0) {
      //     this.constractSavedAttachedImage(responseValue);
      //   }
      //   console.log('Pictures', this.transactionJsonData.AttachedFilesConfig.AttachMentConfig);
      // });
    } else {
      this.ifNoitemsFound = true;
    }
  }
    constractSavedAttachedImage(AttachedImage: any): void {
        try {
          const attachedImageFiles = AttachedImage;
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached = [];
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached = [];
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached = [];
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached = [];
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached = [];
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached = [];
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached = [];
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.length === 0) {
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached = [];
          }
          const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
            'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
          const IntegrationIndex: number = AllowedIntegration.findIndex(x => x === this.selectedTransaction);
            if (this.configService.enableIntegration && IntegrationIndex > -1 && this.dmIntegCollect.IsEnabled && this.setupDMMappingsEnabled) {
            attachedImageFiles.forEach((files: any, index: any) => {
              const attachedType = files.DocumentType;
              switch (attachedType) {
                case 'Image':
                  files.isChecked = false;
                  if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.
                      findIndex(x => x.Name === files.Name) === -1) {
                    files.Name = files.FolderNumber + '-' + files.DocumentNumber;
                    files.showDownload = true;
                    files.integrationDetails = files;
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.push(files);
                    const imageViewAttached = {} as ViewAttached;
                    imageViewAttached.image = `assets/images/image.png`;
                    imageViewAttached.title = files.FolderNumber + '-' + files.DocumentNumber;
                    imageViewAttached.alt = files.FolderNumber + '-' + files.DocumentNumber;
                    imageViewAttached.fileDetails = files.FileDetails;
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(imageViewAttached);
                  }
                  break;
                case 'Foreign':
                  files.isChecked = false;
                  files.Name = files.FolderNumber + '-' + files.DocumentNumber;
                  files.showDownload = true;
                  files.integrationDetails = files;
                  if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.
                    findIndex(x =>  x.Name === files.Name) === -1) {
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.push(files);
                    const fileViewAttached = {} as ViewAttached;
                    fileViewAttached.image = `data:image/gif;base64,${files.Picture}`;
                    fileViewAttached.title = files.Name;
                    fileViewAttached.alt = files.Name;
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(fileViewAttached);
                  }
                  break;
                default:
                // code block
              }
            });
          } else {
            attachedImageFiles.forEach((files: any, index: any) => {
              
              const fileTypes = files.FileDetails.fileName.split('.')[1];
             // files.FilePath.split('.')[files.FilePath.split('.').length - 1];
              files['Type'] = fileTypes;
              
              files['Name'] =files.FileDetails.fileName
              const attachedType = this.detectFileType(files.Type.toLowerCase());
              
              switch (attachedType) {
                case 'image':
                  files.isChecked = false;
                  if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.
                      findIndex(x => x.Name === files.Name) === -1) {
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.push(files);
                    const imageViewAttached = {} as ViewAttached;
                    imageViewAttached.image = `data:image/gif;base64,${files.Picture}`;
                    imageViewAttached.title = files.Name;
                    imageViewAttached.alt = files.Name;
                    imageViewAttached.fileDetails = files.FileDetails;
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(imageViewAttached);
                  }
                  break;
                case 'video':
                  files.isChecked = false;
                  if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.
                    findIndex(x => x.Name === files.Name) === -1) {
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.push(files);
                    const videoViewAttached = {} as ViewAttached;
                    videoViewAttached.video = `data:video/mp4;base64,${files.Picture}`;
                    videoViewAttached.title = files.Name;
                    videoViewAttached.alt = files.Name;
                    videoViewAttached.fileDetails = files.FileDetails;
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(videoViewAttached);
                  }
                  break;
                case 'application':
                  files.isChecked = false;
                  if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.
                    findIndex(x =>  x.FileDetails.Id === files.FileDetails.Id) === -1) {
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.push(files);
                    const fileViewAttached = {} as ViewAttached;
                    fileViewAttached.image = `data:image/gif;base64,${files.Picture}`;
                    fileViewAttached.title = files.Name;
                    fileViewAttached.alt = files.Name;
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(fileViewAttached);
                  }
                  break;
                case 'excel':
                  files.isChecked = false;
                  if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.
                    findIndex(x =>  x.FileDetails.Id === files.FileDetails.Id) === -1) {
                      this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.push(files);
                      const fileViewAttached = {} as ViewAttached;
                      fileViewAttached.image = `data:image/gif;base64,${files.Picture}`;
                      fileViewAttached.title = files.Name;
                      fileViewAttached.alt = files.Name;
                      this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.push(fileViewAttached);
                    }
                      break;
                default:
                // code block
              }
            });
          }
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.length > 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.length > 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.length > 0 || 
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.length > 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.length > 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.length > 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.length > 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.length > 0) {
            this.ifNoitemsFound = false;
          } else if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.length === 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.length === 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.length === 0 || 
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.length === 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.length === 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.length === 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.length === 0 ||
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.length === 0) {
              this.ifNoitemsFound = true;
          }
          if (this.data && this.data.hasOwnProperty('queryParams') && 
          this.data.queryParams.hasOwnProperty('transaction')) {
            if (this.data.queryParams.transaction === 'copy-message') {
              const arrayFieldNames = [
                'ImageAttached', 'VideoAttached', 'FileAttached', 'ViewAttached', 
                'FileAttachedData', 'WorkBookAttached', 'JsonAttached', 'PPTAttached',
                'ZipAttached', 'TextAttached'
              ];
              arrayFieldNames.forEach((arrayNames: any) => {
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[arrayNames] = [];
              });
            }
          }
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig = { 
            ...this.transactionJsonData.AttachedFilesConfig.AttachMentConfig };
          this.transactionJsonData.AttachedFilesConfig = { ...this.transactionJsonData.AttachedFilesConfig };
          this.transactionJsonData = { ...this.transactionJsonData };
    
        } catch (error) {
          console.error(error);
        }
    }
    detectFileType(type: any) {
        if (type === ImageFormats.BMP || type === ImageFormats.GIF || type === ImageFormats.JFIF ||
          type === ImageFormats.JPEG || type === ImageFormats.JPG || type === ImageFormats.PNG) {
          return 'image';
        } else if (type === VideoFormats.MP4) {
          return 'video';
        } else if (type === DocFormats.DOC || type === DocFormats.DOCX || type === DocFormats.PDF) {
          return 'application';
        } else if (type === ExcelFormats.CSV || type === ExcelFormats.MSEXCEL ||
          type === ExcelFormats.SPREADSHEETS) {
          return 'excel';
        }
    }
    downloadExcel(workBookFile: any) {
      if (this.data.queryParams.transaction.indexOf('message') > -1) {
        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:application/octet-stream;base64,' + workBookFile.Picture;
        downloadLink.download = workBookFile.Name;
        downloadLink.click();
      }
    }
    downloadImage(imagefile: any) {
      const downloadLink = document.createElement('a');
      const fileName = imagefile.hasOwnProperty('FileDetails') ?
       imagefile.FileDetails.FileName : imagefile.hasOwnProperty('FileName') ? 
       imagefile.FileName : '';
      const fileType = imagefile.hasOwnProperty('FileDetails') ? 
      imagefile.FileDetails.ContentType.split('/')[0] : imagefile.hasOwnProperty('ContentType') ? 
      imagefile.ContentType : '';
      let Base64Url: any;
      switch (fileType) {
        case 'image': 
          Base64Url = `data:image/gif;base64,${imagefile.Picture}`;
          break;
        default:
          break;
      }
      downloadLink.href = Base64Url;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    downLoadPictures (Pictures: any, index: number, event: any, checkedArray: any) {
      try {
        const dataMagineDownload: DataMagineDownload = {
          FolderId: Pictures.integrationDetails.FolderNumber,
          DocumentId: Pictures.integrationDetails.DocumentNumber,
          CreatedDate: Pictures.integrationDetails.CreatedDate,
          FolderName: Pictures.integrationDetails.FolderName
        };
        this.integrationService?.dmIntergerationInstance?.DownLoadDataMagineFile(dataMagineDownload);
      } catch (error) {
        console.error('Error occurred in downLoad', error);
      }
    }

    deletePictures(Pictures: any, index: number, event: any, checkedArray: any) {
        try {
          event.stopPropagation();
          const confirmPopModel = {} as ConfirmPopModel;
          confirmPopModel.PopUpContent = `Are you sure you want to delete ?`;
          confirmPopModel.PopupHeaderContent = 'Confirmation';
          const dialogRefs = this.dialog.open(AgilysysConfirmationPopupComponent, {
            width: '440px',
            height: '220px',
            data: confirmPopModel,
            autoFocus: false,
            disableClose: true
          });
          dialogRefs.afterClosed().subscribe(res => {
            if (res === 'Confirm') {

              const Index =
               this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].findIndex(x => x.Name === Pictures.Name);
              if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray][Index].FileDetails !== undefined) {
              if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray][Index].FileDetails.id &&
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray][Index].FileDetails.id !== 0) {
                  let DeleteUrl: any;
                  let DeleteData: DeletePicture;
                  Pictures.IsActive = false;
                  if (this.data.queryParams.transaction.indexOf('message') > -1) {
                    DeleteData = {
                      fileName: Pictures.Name,
                      groupId: 6,
                      itemId: Pictures.FileDetails.inventoryId,
                      filePath : Pictures.FilePath
                    };
                    DeleteUrl =
                  `/inventory/api/File/Delete?id=${DeleteData.itemId}&fileName=${DeleteData.filePath}&IsTransaction=false`;
                  } else {
                    DeleteData = {
                      fileName: Pictures.Name,
                      groupId: this.TransactionGroupId,
                      itemId: Pictures.FileDetails.id,
                      filePath : Pictures.FilePath
                    };
                    DeleteUrl =
                  `/inventory/api/File/Delete?id=${DeleteData.itemId}&fileName=${DeleteData.fileName}&IsTransaction=true`;
                  }
                  
                  this.httpService.GethttpMethod(DeleteUrl).subscribe((response: any) => {
                      console.log('Deleted', response);
                      if (response) {
                        this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].splice(index, 1);
                          this.toasterService.success(response.toString(), '', {
                          timeOut: 3000,
                          closeButton: true,
                        });
                        this.enabledDeleteButton();
                      } else {
                          this.toasterService.error('Delete Failed', '', {
                          timeOut: 3000,
                          closeButton: true,
                          });
                          this.enabledDeleteButton();
                      }
                    }
                    );
                } } else {
                  this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].splice(index, 1);
                  this.fileDataCollection.splice(index, 1);
                  const fileIndex =
                  this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData.findIndex(y =>
                     y.FileName === Pictures.Name);
                  this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData.splice(fileIndex, 1);
                  this.enabledDeleteButton();
              }
            }
          });
        } catch (error) {
            console.error('Deleting Video Error Occured', error);
        }
    }
    deleteAllPictures(event: any, checkedArray: any) {
        try {
          event.stopPropagation();
          const confirmPopModel = {} as ConfirmPopModel;
          confirmPopModel.PopUpContent = `Are you sure you want to delete ?`;
          confirmPopModel.PopupHeaderContent = 'Confirmation';
          const dialogRefs = this.dialog.open(AgilysysConfirmationPopupComponent, {
            width: '440px',
            height: '220px',
            data: confirmPopModel,
            autoFocus: false,
            disableClose: true
          });
          dialogRefs.afterClosed().subscribe(res => {
          if (res === 'Confirm') {
              const pictureSelected =
               this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].filter(x => x.isChecked === true);
              pictureSelected.forEach((x: any) => {
                if (x.FileDetails !== undefined) {
                if (x.FileDetails.id && x.FileDetails.id !== 0) {
                  let DeleteUrl: any;
                  let DeleteData: DeletePicture;
                  if (this.data.queryParams.transaction.indexOf('message') > -1) {
                    DeleteData = {
                      fileName: x.Name,
                      groupId: 6,
                      itemId: x.FileDetails.inventoryId,
                      filePath : x.FilePath
                    };
                    DeleteUrl =
                  `/inventory/api/File/Delete?id=${DeleteData.itemId}&fileName=${DeleteData.fileName}&IsTransaction=false`;
                  } else {
                    DeleteData = {
                          fileName: x.Name,
                          groupId: this.TransactionGroupId,
                          itemId: x.FileDetails.Id,
                          filePath : x.FilePath
                    };
                    DeleteUrl =
                  `/inventory/api/File/Delete?id=${DeleteData.itemId}&fileName=${DeleteData.filePath}&IsTransaction=true`;
                  }
                  this.httpService.GethttpMethod(DeleteUrl).subscribe((response: any) => {
                    console.log('Deleted', response);
                    if (response) {
                      this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].splice(x, 1);
                      this.toasterService.success(response.toString(), '', {
                        timeOut: 3000,
                        closeButton: true,
                      });
                      this.enabledDeleteButton();
                    } else {
                      this.toasterService.error('Delete Failed', '', {
                        timeOut: 3000,
                        closeButton: true,
                      });
                      this.enabledDeleteButton();
                    }
                  });
                } } else {
                  if (x.isChecked === true) {
                    const Index =
                     this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].findIndex(y => y.Name === x.Name);
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].splice(Index, 1);
                    const FileIndex = this.fileDataCollection.findIndex(y => y.FileName === x.Name);
                    this.fileDataCollection.splice(FileIndex, 1);
                    const fileIndex =
                     this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData.findIndex(y => y.FileName === x.Name);
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttachedData.splice(fileIndex, 1);
                  }
                }
              });
              this.transactionJsonData = { ...this.transactionJsonData };
              this.transactionService.transactionConfigurationData.AttachedFilesConfig =
               this.transactionJsonData.AttachedFilesConfig;
              this.enabledDeleteButton();
            }
          });
        } catch (error) {
            console.error('Deleting All Pictures Error Occured', error);
        }
    }
    checkedEvent(FilesData: FileAttachedConfig, event: any): void {
      try {
        FilesData.isChecked = event.checked;
        this.enabledDeleteButton();
      } catch (error) {
        console.error(error);
      }
    }
    checkedAllPictures(FilesData: FileAttachedConfig, event: any, checkedArray: string): void {
      try {
        if (event.checked) {
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].map(x => x.isChecked = event.checked);
          this.transactionJsonData.AttachedFilesConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
            if (x.buttonKey === 'deletefiles') {
              x.displayProperity = false;
            }
          });
          this.enabledDeleteButton();
        } else {
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[checkedArray].map(x => x.isChecked = event.checked);
          this.enabledDeleteButton();
        }
      } catch (error) {
        console.error(error);
      }
    }
    enabledDeleteButton(): void {
      try {
        const fileChecked =
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.some(x => x.isChecked);
        this.fileChecked =
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.every(x => x.isChecked);
        const videoChecked =
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.some(x => x.isChecked);
        this.videoChecked =
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.every(x => x.isChecked);
        const ImageChecked =
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.some(x => x.isChecked);
        this.imageChecked =
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.every(x => x.isChecked);
        this.workbookChecked = 
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.every(x => x.isChecked);
        const WorkBookChecked = 
         this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.some(x => x.isChecked);
        this.jsonChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.every(x => x.isChecked);
        const JsonChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.some(x => x.isChecked);
        this.pptChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.every(x => x.isChecked);
        const PPTChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.some(x => x.isChecked);
        this.zipChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.every(x => x.isChecked);
        const ZipChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.some(x => x.isChecked);
        this.textChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.every(x => x.isChecked);
        const TextChecked = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.some(x => x.isChecked);
        if (ImageChecked || videoChecked || fileChecked || WorkBookChecked || JsonChecked || PPTChecked || ZipChecked) {
          this.checkedVideoLength =
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.filter(x => x.isChecked === true).length;
          this.checkedImageLength =
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.filter(x => x.isChecked === true).length;
          this.checkedFileLength =
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.filter(x => x.isChecked === true).length;
          this.checkedWorkBookLength = 
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.filter(x => x.isChecked === true).length;
          this.checkedJsonLength =
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.filter(x => x.isChecked === true).length;
          this.checkedPPTLength = 
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.filter(x => x.isChecked === true).length;
          this.checkedZipLength = 
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.filter(x => x.isChecked === true).length;
          this.checkedTextLength = 
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.filter(x => x.isChecked).length;
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.length === 0 && 
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.length === 0) {
              this.ifNoitemsFound = true;
          } else {
            this.ifNoitemsFound = false;
            this.agilysysDeleteButton[0].displayProperity = false;
            this.agilysysDeleteButton[0] = {...this.agilysysDeleteButton[0]};
        }
      } else {
          this.checkedVideoLength =
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.filter(x =>
           x.isChecked === true).length;
          this.checkedImageLength =
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.filter(x =>
             x.isChecked === true).length;
          this.checkedFileLength =
           this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.filter(x =>
             x.isChecked === true).length;
          this.agilysysDeleteButton[0].displayProperity = true;
          if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.length === 0 && 
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.length === 0 &&
            this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.length === 0) {
              this.ifNoitemsFound = true;
          } else {
            this.ifNoitemsFound = false;
          }
        }
        if (this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.FileAttached.length > 0 || 
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ImageAttached.length > 0 ||
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.VideoAttached.length > 0 ||
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.WorkBookAttached.length > 0 ||
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.JsonAttached.length > 0 ||
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.PPTAttached.length > 0 ||
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ZipAttached.length > 0 ||
          this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.TextAttached.length > 0) {
          this.agilysysCofirmationButton.forEach(x => {
            if (x.buttonKey === 'Confirm') {
              x.displayProperity = false;
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    showLightbox(index: number, details: any) {
      const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
       'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
      const IntegrationIndex: number = AllowedIntegration.findIndex(x => x === this.selectedTransaction);
        if (!this.configService.enableIntegration || IntegrationIndex === -1 || !this.dmIntegCollect.IsEnabled || this.setupDMMappingsEnabled) {
        const VIndex = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig.ViewAttached.findIndex(x => x.title === details.Name);
        this.selectedImageIndex = VIndex;
        this.showFlag = true;
        let Type = '';
        // this.stremingVideo(details);
        if (details.Type !== 'mp4') {
           Type = details.Type.split('/')[1];
        } else {
          Type = details.Type;
        }
        // if (Type === VideoFormats.MP4) {
          this.videoStreming(details);
        // }
      }      
    }
    async videoStreming(fileDetails: any, startSize = 0): Promise<void> {
       try {
        const fileDataModel = {} as StreamingModel;
        fileDataModel.FileData = fileDetails;
        this.dialog.open(AgilysysVideoStremingComponent, {
          width: '80%',
          height: '80%',
          data: fileDataModel,
          disableClose: true
        });
       } catch (error) {
         console.error(error);
       }
      }
  
    /**
     * @ @method stremingVideo()
     * @ param index
     * @ param details
     */
    async stremingVideo(fileDetails: any, startSize = 0): Promise<void> {
      try {
        this.totalSegments = Math.ceil(fileDetails.FileDetails.Size / 1048576);
        this.segmentLength = 0;
        this.bytesFetched = 0;
        this.requestedSegments = [];
        this.segmentDuration = 0;
        const video = document.querySelector('video');
        const filePath = fileDetails.FileDetails.FilePath;
        const downloadUrl = '/api/File/Download';
        const EndPointUrl = downloadUrl + `?fileName=${filePath}` + `&IsThumbnail=false`;
        this.assetUrl = EndPointUrl;
        const mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
        let mediaSource = null;
        for (let i = 1; i <= this.totalSegments; i++) {
          this.requestedSegments[i] = false;
        }
        if ('MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
          mediaSource = new MediaSource();
          video.src = URL.createObjectURL(mediaSource);
          mediaSource.addEventListener('sourceopen', () => {
            // this.sourceOpen(mediaSource, video);
            this.chunckSourceOpen(mediaSource, video, mimeCodec, fileDetails);
          });
        } else {
          console.error('Unsupported MIME type or codec: ', mimeCodec);
        }
      } catch (error) {
        console.error(error);
      }
    }
    chunckSourceOpen(mediaSource: MediaSource, video: any, mimeCodec: any, fileDetails: any) {
      try {
        const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
        const _that = this;
        // this.getFileLength(_that.assetUrl, function (fileLength: any) {
         const fileLength = fileDetails.FileDetails.Size;
        _that.segmentLength = Math.round( fileLength / _that.totalSegments );
        _that.segmentLength = 1048576; // segment length is constant
        _that.fetchRange(_that.assetUrl, 0, _that.segmentLength, (chunk: any) => {
          _that.appendSegment(chunk, sourceBuffer);
        });
        _that.requestedSegments[0] = true;
        video.addEventListener('timeupdate', (e: any) => {
          _that.checkBuffer(mediaSource, video, sourceBuffer);
        });
        video.addEventListener('canplay', function () {
          console.log(video.currentTime);
          _that.segmentDuration = video.duration / _that.totalSegments;
          video.play();
        });
        video.addEventListener('seeking', (e: any) => {
          console.log(e);
        });
      } catch (error) {
        console.error(error);
      }
    }
    checkBuffer(mediaSource: MediaSource, video, sourceBuffer: SourceBuffer) {
      const currentSegment = this.getCurrentSegment(video);
      const NextSegment = this.shouldFetchNextSegment(currentSegment, video);
      if (currentSegment === this.totalSegments && this.haveAllSegments()) {
        console.log('last segment', mediaSource.readyState);
        mediaSource.endOfStream();
        video.removeEventListener('timeupdate', () => {
          this.checkBuffer(mediaSource, video, sourceBuffer);
        });
      } else if (NextSegment) {
        this.requestedSegments[currentSegment] = true;
        console.log('time to fetch next chunk', video.currentTime);
        this.fetchRange(this.assetUrl, this.bytesFetched, this.bytesFetched + this.segmentLength, (chunk) => {
          this.appendSegment(chunk, sourceBuffer);
        });
      }
    }
    getCurrentSegment(video: any) {
      const segmentCalc = video.currentTime / this.segmentDuration;
      // eslint-disable-next-line no-bitwise
      const segmentCal = ((segmentCalc) | 0) + 1;
      // eslint-disable-next-line no-bitwise
      return segmentCal;
    }
  
    haveAllSegments() {
      return this.requestedSegments.every(function (val) { return !!val; });
    }
  
    shouldFetchNextSegment(currentSegment, video) {
      const segcurrentDuration = this.segmentDuration * currentSegment * 0.8;
      const nextSegmentCal = video.currentTime > segcurrentDuration;
      const nextFetchSeg = nextSegmentCal && !this.requestedSegments[currentSegment];
      return nextFetchSeg;
    }
  
    fetchRange(url, start, end, cb) {
      const _that = this;
      const xhr = new XMLHttpRequest;
      xhr.open('get', this.envService.apiUrl + url);
      xhr.responseType = 'arraybuffer';
      xhr.setRequestHeader('Range', 'bytes=' + start + '-');
      xhr.setRequestHeader('Authorization', `Bearer ${this.stateService.token}`);
      xhr.setRequestHeader('tenant', this.stateService.tenant);
      xhr.onload = function () {
        console.log('fetched bytes: ', start, end);
        _that.bytesFetched += end - start;
        cb(xhr.response);
      };
      xhr.send();
    }
    async appendSegment(chunk, sourceBuffer: any) {
      sourceBuffer.appendBuffer(chunk);
    }
    
    getFileLength(url: any, cb: any) {
      const xhr = new XMLHttpRequest;
      xhr.open('get', this.envService.apiUrl + url);
      xhr.setRequestHeader('Authorization', `Bearer ${this.stateService.token}`);
      xhr.setRequestHeader('tenant', this.stateService.tenant);
      xhr.onload = function () {
        cb(xhr.getResponseHeader('content-length'));
      };
      xhr.send();
      xhr.onerror = function (e) {
        alert('Error Status: ' + e.target);
      };
    }
    documentViewer(index: number, details: any) {
      const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
      'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
      const IntegrationIndex: number = AllowedIntegration.findIndex(x => x === this.selectedTransaction);
        if (!this.configService.enableIntegration || IntegrationIndex === -1 || !this.dmIntegCollect.IsEnabled || this.setupDMMappingsEnabled) {
        const selectedFile = this.transactionJsonData.AttachedFilesConfig.
          AttachMentConfig.ViewAttached.filter(x => x.title === details.Name);
        const fileContent = selectedFile[0].title.split('.')[1];
        const contentType = this.getType(selectedFile[0].title);
        const convertBlob = this.converBase64toBlob(selectedFile[0].image.split(',')[1], contentType);
        this.pdfUrl = window.URL.createObjectURL(convertBlob);
        // const tab = window.open();
        // tab.location.href = this.pdfUrl;
        const pdfViewerPopup = {} as PdfViewerPopup;
        pdfViewerPopup.PopupHeaderContent = selectedFile[0].title.split('.')[0];
        pdfViewerPopup.PdfViewerUrl = this.pdfUrl;
        pdfViewerPopup.type = fileContent;
        this.dialog.open(AgilysysPdfViewerComponent, {
          width: '50%',
          height: 'auto',
          data: pdfViewerPopup,
          disableClose: true
        });
        this.showPdf = true;
      }
    }
    converBase64toBlob(content: any, contentType: any) {
      contentType = contentType || '';
      const sliceSize = 512;
      const byteCharacters = window.atob(content.replace(/\s/g, '')); // method which converts base64 to binary
      const byteArrays = [
      ];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {
        type: contentType
      });
      return blob;
    }
  
    getType(fileName: string): string {
      try {
        let imageName: string;
        const type = fileName.split('.')[1];
        switch (type.toString().toLowerCase()) {
          case 'pdf':
            imageName = 'application/pdf';
            break;
          case 'doc':
            imageName = 'application/msword';
            break;
          case 'docx':
            imageName = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            break;
          default:
            imageName = 'application/pdf';
            break;
        }
        return imageName;
      } catch (error) {
        console.error(error);
      }
    }
    /**
     * @method deleteFiles()
     */
    deleteFiles(callBackData): void {
      try {
        console.log(callBackData);
        const confirmPopModel = {} as ConfirmPopModel;
        confirmPopModel.PopUpContent = `Are you sure you want to delete ?`;
        confirmPopModel.PopupHeaderContent = 'Confirmation';
        const dialogRefs = this.dialog.open(AgilysysConfirmationPopupComponent, {
          width: '440px',
          height: '220px',
          data: confirmPopModel,
          autoFocus: false,
          disableClose: true
        });
        dialogRefs.afterClosed().subscribe(res => {
          if (res === 'Confirm') {
            let hasCheckedData = false;
            hasCheckedData = (this.checkedFileLength > 0 || this.checkedImageLength > 0 || this.checkedJsonLength > 0 || this.checkedPPTLength > 0 ||
              this.checkedTextLength > 0 || this.checkedVideoLength > 0 || this.checkedWorkBookLength > 0 || this.checkedZipLength);
            if (hasCheckedData) {
              for (var attachedConfig in this.transactionJsonData.AttachedFilesConfig.AttachMentConfig) {
                let checkedDatas = [];
                this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[attachedConfig].forEach((data) => {
                  if (data.isChecked) {
                    checkedDatas.push(data.FileName);
                  }
                });
                if (checkedDatas.length > 0) {
                  checkedDatas.forEach(cdata => {
                    const index = this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[attachedConfig].findIndex(n => n.FileName === cdata);
                    this.transactionJsonData.AttachedFilesConfig.AttachMentConfig[attachedConfig].splice(index, 1);
                    const FileIndex = this.fileDataCollection?.findIndex(y => y.FileName === cdata);
                    this.fileDataCollection.splice(FileIndex, 1);

                  });
                }
              }
              this.transactionJsonData = { ...this.transactionJsonData };
              if (this.transactionService?.transactionConfigurationData?.AttachedFilesConfig) {
                this.transactionService.transactionConfigurationData.AttachedFilesConfig =
                  this.transactionJsonData.AttachedFilesConfig;
              }
              this.enabledDeleteButton();
              // this.transactionService.transactionConfigurationData.AttachedFilesConfig =
              //   this.transactionJsonData.AttachedFilesConfig;
              // this.enabledDeleteButton();
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    ngOnInit(): void {
      this.agilysysDeleteButton = [
        {
          displayProperity: true,
          displayName: 'Delete File(s)',
          buttonKey: 'delete',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callBackData: CallBackData) => {
            this.deleteFiles(callBackData);
          }
        }
      ]
    }
    
  }
