import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridInterface } from '.';
import { GridFilterData, GridFilterValue } from '../master.interface';
import { GridSingleFilterFetch } from './grid-config.interface';

export const GridSingleFilterOptionFetch: GridSingleFilterFetch = async (FilterData: any,
    httpService: HttpService): Promise<any> => {
    try {   
        if (FilterData) {
            return new Promise((resolve) => {
                httpService.GethttpMethod(FilterData.FilterUrl).subscribe(response => {
                    if (response && response['value'] && response['value'].length > 0) {
                        const filterKeyData = response['value'].map((data: any) => {
                        const filterKey = {} as GridFilterValue;
                        filterKey.FilterId = GridInterface.GridFilterKeyMapping(FilterData, data);
                        filterKey.FilterName = GridInterface.GridFilterLabelMapping(FilterData, data);
                        filterKey.Checked = false;
                        return filterKey;
                        });
                        resolve(filterKeyData);
                    } else {
                        resolve([]);
                    }
                });
            });
        }
    } catch (error) {  
        console.error('Error occurred in GridSingleFilterOptionFetch', error);
    }
};