import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module';
import { ScrollbarModule } from 'ngx-scrollbar';
import { InventoryRoutingModule } from './inventory-routing.module';
import { RetailInventorySettingComponent } from './inventory.component';
import { RetailInventoryTableComponent } from './inventory-table/inventory-table.component';
import { RetailSharedModule } from '../shared/retail-shared.module';
import { SPAConfig } from '../common/config/SPA-config';
import { HttpServiceCall } from '../shared/service/http-call.service';
import { HttpRequestService } from '../shared/service/http-request.service';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { SessionManagerService } from '../common/services/session-manager.service';
import { MoreSectionServiceService } from '../shared/more-section/more-section-service.service';
import { FormatText } from '../shared/pipes/formatText-pipe.pipe';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { RetailService } from '../retail.service';
import { MessageService } from '../shared/service/messageservice.service';
import { UserAlerts } from '../common/config/alerts-config';
import { InventoryService } from './inventory.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RetailToggleSwitchModule } from '../retail-toggle-switch/retail-toggle-switch.module';
@NgModule({
    declarations: [
        RetailInventorySettingComponent,
        RetailInventoryTableComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ScrollbarModule,
        InventoryRoutingModule,
        RetailSharedModule,
        BsDropdownModule.forRoot({}),
        RetailToggleSwitchModule
    ],
    exports: [
        RetailInventorySettingComponent,
        RetailInventoryTableComponent
    ],
    providers: [
        SPAConfig,
        HttpServiceCall,
        HttpRequestService,
        RetailPropertyInformation,
        SessionManagerService,
        MoreSectionServiceService,
        FormatText,
        BreakPointAccess,
        RetailService,
        MessageService,
        UserAlerts,
        InventoryService
    ]
})
export class InventoryModule { }
