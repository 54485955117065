import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PlayerInformationService } from '../service/player.information.service';
import { Localization } from 'src/app/common/localization/localization';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SMReturnCode } from 'src/app/common/Models/common.models';
import { CommonUtilities } from '../utilities/common-utilities';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { FacadeService } from 'src/app/common/services/facade.service';

@Component({
  selector: 'app-player-worth-details',
  templateUrl: './player-worth-details.component.html',
  styleUrls: ['./player-worth-details.component.scss']
})
export class PlayerWorthDetailsComponent implements OnInit {
  @Input() dataInput = { patronId: '', isCMSRequirePin: false, isPlayerPointsView : true, isPlayerCompsView : true }; // PatronId
  allCaptions: any;
  clickbutton: any;
  shopCaptions: any;
  playerWorthInfo: { pointValue: string, compValue: string };
  appointmentsCaptions: any;
  showLoader = false;
  compDetailsFetched = false;
  floatLabelNever: string;
  currentProductId: number;
  versaProductId:number=6;
  get IsCMSRequirePin() { return this.dataInput?.isCMSRequirePin }
  get AllowPatronLookup() {
    return !this.IsCMSRequirePin ? (this.dataInput.patronId != '') : (!this.FormGrp?.controls?.patronPin.value || this.FormGrp?.controls?.patronPin?.valid)
  }
  FormGrp: UntypedFormGroup;
  hidePassword = false;
  captions;
  showPoints: boolean = true;
  showComps: boolean = true;
  NoViewAccess: boolean = false;

  constructor(private localization: Localization
    , private dialogRef: MatDialogRef<PlayerWorthDetailsComponent>
    , private playerService: PlayerInformationService
    , private form: UntypedFormBuilder
    , public utils: CommonUtilities
  ) {  
    this.allCaptions = this.localization.captions.common;
    this.captions = this.localization.captions;
    this.shopCaptions = this.localization.captions.shop;
    this.appointmentsCaptions = this.localization.captions.bookAppointment;   
    this.floatLabelNever = this.localization.setFloatLabelNever; 
  }

  async ngOnInit() {  
    this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));  
    this.FormGrp = this.form.group({
      patronPin: ['', [Validators.minLength(4), Validators.maxLength(10)]]
    })
    this.playerWorthInfo = {
      compValue: this.localization.localizeCurrency(0),
      pointValue: this.localization.localizeCurrency(0)
    };
    if (!this.IsCMSRequirePin)
      this.getPlayerInformation();
    this.clickbutton = this.allCaptions.OK;
  }

  async getPlayerInformation() {
    if (this.AllowPatronLookup) {
      this.showLoader = true;
      this.compDetailsFetched = false;
      try {
        this.playerWorthInfo = {
          compValue: this.localization.localizeCurrency(0),
          pointValue: this.localization.localizeCurrency(0)
        };
        let playerInfo = await this.playerService.GetPlayerInformation(this.dataInput?.patronId, this.FormGrp.controls.patronPin.value, true);
        
        let viewPoints = this.dataInput?.isPlayerPointsView;
        let viewComps = this.dataInput?.isPlayerCompsView;
        if(!viewPoints)
        {
          this.showPoints = false;
        }
        if(!viewComps)
        {
          this.showComps = false;
        }
        if(!viewPoints && !viewComps)
        {
          this.NoViewAccess = true;
        }
        
        if (playerInfo?.returnCode == SMReturnCode.Success && playerInfo.cmsBalanceDetails && playerInfo.cmsBalanceDetails.compRedemtion) {
          this.playerWorthInfo = {
            pointValue: this.localization.localizeCurrency(playerInfo.cmsBalanceDetails.compRedemtion.points.availablePointsInDollars),
            compValue: this.localization.localizeCurrency(playerInfo.cmsBalanceDetails.compRedemtion.comps.availableCompInDollars)
          };
          this.compDetailsFetched = true;
        } else {
          this.utils.ShowError(
            this.localization.captions.common.Error,
            playerInfo?.message ? playerInfo?.message : this.localization.captions.shop.NoPlayersFound
          );
        }
        this.showLoader = false;
      } catch (error) {
        this.showLoader = false;
        console.log(error?.message);
      }
    }
  }

  CloseDialog() {
    this.dialogRef.close(true);
  }
}
