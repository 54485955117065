import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { FilterTypes } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import moment from 'moment';
import { GridInterface } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface';
import { FieldEdmType } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import { CustomFilterNavigationUrl } from '../../../../setup-master/shared/interface/master.interface';
import {
  CustomFilterQuery,
    GridFilterAction, GridFilterData, GridFilterValue, GridTypeFilterConfig,
    TransactionConfiguration
} from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
import * as constants from '@shared/constant/GlobalConstants';
export const ViewGridFilterConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity?: any): GridFilterAction[] => {
    const GridFilterRowData = [];
    try {
        if (ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
            if (TransactionCollection.ViewTranscationConfig.hasOwnProperty('GridTypeFilterConfig') &&
                TransactionCollection.ViewTranscationConfig.GridTypeFilterConfig) {
                let selectedId = 0;
                TransactionCollection.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach(data => {
                    if (data.Type === 'dropdown') {
                        selectedId = data.SelectedTypeId;
                    }
                });
                if (TransactionCollection.ViewTranscationConfig.GridTypeFilterConfig.length > 0) {
                    TransactionCollection.ViewTranscationConfig.GridTypeFilterConfig.forEach((filter: GridTypeFilterConfig) => {
                        if (filter.TypeId === selectedId) {

                            const navigationProp = {} as CustomFilterNavigationUrl;
                            const Navigation = ViewHeaderEntity[0]['NavigationProperty']
                                .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] === filter.FilterOdataKey);
                            if (Navigation.length > 0) {
                                ViewHeaderEntity[0]['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
                                    const NavigationProperityName = NavigationProperty['$']['Name'];
                                    if (NavigationProperityName === filter.FilterOdataKey) {
                                        let NavigationReferentialConstraint = '';
                                        if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                                            NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                                        }
                                        const EntityNameList = NavigationProperty['$']['Type'].split('.');
                                        let EntityName = '';
                                        if (EntityNameList.length > 0) {
                                            EntityName = EntityNameList[EntityNameList.length - 1];
                                            navigationProp.RequestUrl = `/inventory/api/${EntityName}`;
                                            navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                                            navigationProp.EnabledCustomField = false;
                                        }
                                    }
                                });
                            } else {
                                if (filter && filter.hasOwnProperty('CustomFilters') &&
                                    filter.CustomFilters.EnableCustomFilter) {
                                    navigationProp.RequestUrl = filter.CustomFilters.CustomNavigationUrl;
                                    navigationProp.EnabledCustomField = true;
                                    navigationProp.NavigationContraintName = filter.FilterOdataKey;
                                    if (navigationProp.FilterValueLocation && navigationProp.FilterLocationKey) {
                                        if (navigationProp.FilterValueLocation === 'SessionStorage') {
                                            const filterValues = StorageService.GetSessionStorage(navigationProp.FilterLocationKey);
                                            const filterValue = `?$filter=Id in (${filterValues})`;
                                            navigationProp.RequestUrl = navigationProp.RequestUrl + filterValue;
                                        }
                                    }
                                } else if (filter && filter.hasOwnProperty('CustomOptions')) {
                                    const filterDisplay = {} as GridFilterData;
                                    filterDisplay.FilterKey = filter.FilterDisplayName;
                                    filterDisplay.FilterValue = filter.CustomOptions;
                                    filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                    filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                    filterDisplay.Type = filter.hasOwnProperty('CustomType') ? filter.CustomType : 'multiselect';
                                    navigationProp.RequestUrl = null;
                                    GridFilterRowData.push(filterDisplay);
                                }
                            }
                            if (navigationProp && navigationProp.RequestUrl !== null && navigationProp.RequestUrl !== undefined && 
                                (!filter.LoadOnClick)) {
                                httpService.GethttpMethod(navigationProp.RequestUrl).subscribe((response: any) => {
                                    if (response && response.value && response.value.length > 0) {
                                        const filterKeyData = response.value.map((data: any) => {
                                            const filterKey = {} as GridFilterValue;
                                            filterKey.FilterId = GridInterface.GridFilterKeyMapping(filter, data);
                                            filterKey.FilterName = GridInterface.GridFilterLabelMapping(filter, data);
                                            filterKey.Checked = false;
                                            return filterKey;
                                        });
                                        const filterDisplay = {} as GridFilterData;
                                        filterDisplay.FilterDisplayName = filter.FilterDisplayName;
                                        filterDisplay.FilterKey = filter.FilterDisplayName;
                                        filterDisplay.FilterValue = filterKeyData;
                                        filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                        filterDisplay.FilterOdataKey = filter.FilterOdataKey;
                                        filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                        filterDisplay.Type = 'multiselect';
                                        GridFilterRowData.push(filterDisplay);
                                    }
                                    if (!TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions']) {
                                        TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions'] = {};
                                        if (!TransactionCollection.ViewTranscationConfig.GridConfiguration.GridActions['FilterConfig']) {
                                            TransactionCollection.ViewTranscationConfig.GridConfiguration.GridActions['FilterConfig'] = {
                                                EnableFilter: false,
                                                FilterOptions: []
                                            };
                                        }
                                    }
                                    TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions']
                                    ['FilterConfig']['EnableFilter'] = GridFilterRowData.length > 0 ? true : false;
                                    TransactionCollection.ViewTranscationConfig.GridConfiguration
                                    ['GridActions']['FilterConfig']['FilterOptions'] = GridFilterRowData;
                                    TransactionCollection.ViewTranscationConfig.GridConfiguration
                                    ['GridActions'] = {
                                        ...TransactionCollection.ViewTranscationConfig.GridConfiguration
                                        ['GridActions']
                                    };
                                    TransactionCollection.ViewTranscationConfig.GridConfiguration = {
                                        ...TransactionCollection.ViewTranscationConfig.GridConfiguration};
                                },
                                    (responseError) => {
                                        console.error('error caught in component', responseError);
                                    });
                            } else if (filter.hasOwnProperty('LoadOnClick') && filter.LoadOnClick) {
                                const filterDisplay = {} as GridFilterData;
                                filterDisplay.FilterDisplayName = filter.FilterDisplayName;
                                filterDisplay.FilterKey = filter.FilterDisplayName;
                                filterDisplay.FilterValue = [];
                                filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                filterDisplay.FilterOdataKey = filter.FilterOdataKey;
                                filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                filterDisplay.Type = 'multiselect';
                                filterDisplay.FilterUrl = navigationProp.RequestUrl;
                                filterDisplay.EnableLoader = filter.EnableLoader;
                                filterDisplay.LoadOnClick = filter.LoadOnClick;
                                GridFilterRowData.push(filterDisplay);

                                if (!TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions']) {
                                    TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions'] = {};
                                    if (!TransactionCollection.ViewTranscationConfig.GridConfiguration.GridActions['FilterConfig']) {
                                        TransactionCollection.ViewTranscationConfig.GridConfiguration.GridActions['FilterConfig'] = {
                                            EnableFilter: false,
                                            FilterOptions: []
                                        };
                                    }
                                }
                                TransactionCollection.ViewTranscationConfig.GridConfiguration.GridFilter = GridFilterRowData;
                                TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions']
                                ['FilterConfig']['EnableFilter'] = GridFilterRowData.length > 0 ? true : false;
                                TransactionCollection.ViewTranscationConfig.GridConfiguration
                                ['GridActions']['FilterConfig']['FilterOptions'] = GridFilterRowData;
                                TransactionCollection.ViewTranscationConfig.GridConfiguration
                                ['GridActions'] = {
                                    ...TransactionCollection.ViewTranscationConfig.GridConfiguration
                                    ['GridActions']
                                };
                                TransactionCollection.ViewTranscationConfig.GridConfiguration = {
                                    ...TransactionCollection.ViewTranscationConfig.GridConfiguration
                                };
                            }
                        }
                    });

                }
            } else {
                if (TransactionCollection.ViewTranscationConfig.GridConfiguration.GridFilter.length > 0) {
                    TransactionCollection.ViewTranscationConfig.GridConfiguration.GridFilter.forEach((filter: GridFilterAction) => {
                        const navigationProp = {} as CustomFilterNavigationUrl;
                        const Navigation = ViewHeaderEntity[0]['NavigationProperty']
                            .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] === filter.FilterOdataKey);
                        let httpMethodType = 'GethttpMethod';
                        if (Navigation.length > 0) {
                            ViewHeaderEntity[0]['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
                                const NavigationProperityName = NavigationProperty['$']['Name'];
                                if (NavigationProperityName === filter.FilterOdataKey) {
                                    let NavigationReferentialConstraint = '';
                                    if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                                        NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                                    }
                                    const EntityNameList = NavigationProperty['$']['Type'].split('.');
                                    let EntityName = '';
                                    if (EntityNameList.length > 0) {
                                        EntityName = EntityNameList[EntityNameList.length - 1];
                                        navigationProp.RequestUrl = `/inventory/api/${EntityName}`;
                                        if (filter.hasOwnProperty('CustomQuery') && filter.CustomQuery) {
                                              navigationProp.RequestUrl = navigationProp.RequestUrl + filter.CustomQuery;
                                            }
                                            if (filter.hasOwnProperty('FilterQuery') && filter.FilterQuery) {
                                              navigationProp.RequestUrl = navigationProp.RequestUrl + filter.FilterQuery;
                                            }
                                            if ( filter.hasOwnProperty('CustomFilterQuery') && filter.CustomFilterQuery) {
                                             const filterQuery =  getFilterQuery(filter.CustomFilterQuery);
                                            navigationProp.RequestUrl = navigationProp.RequestUrl + '?$filter=' + filterQuery;
                                        }
                                        navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                                        navigationProp.EnabledCustomField = false;
                                    }
                                }
                            });
                        } else {
                            if (filter && filter.hasOwnProperty('CustomFilters') &&
                                filter.CustomFilters.EnableCustomFilter) {
                                navigationProp.RequestUrl = filter.CustomFilters.CustomNavigationUrl;
                                navigationProp.EnabledCustomField = true;
                                navigationProp.NavigationContraintName = filter.FilterOdataKey;
                                const key = filter.CustomFilters.FilterLocationKey;
                                if (filter.CustomFilters.FilterValueLocation && filter.CustomFilters.FilterLocationKey) {
                                    if (filter.CustomFilters.FilterValueLocation === 'SessionStorage') {
                                        const filterValues = sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : [];
                                        const filtervalue = `Id in (${filterValues})`;
                                        let selectQuery, filterQuery = '';
                                        if (filter.CustomFilters.CustomSelectQuery) {
                                            selectQuery = `$select=${filter.CustomFilters.CustomSelectQuery}`;
                                        }
                                        if (filter.CustomFilters.CustomFilterQuery) {
                                            filterQuery = filter.CustomFilters.CustomFilterQuery ?
                                                `$filter=${filter.CustomFilters.CustomFilterQuery} and ${filtervalue}` : `$filter=${filtervalue}`;
                                        }
                                        navigationProp.RequestUrl = selectQuery ? 
                                            navigationProp.RequestUrl + `?${selectQuery}&${filterQuery}` : navigationProp.RequestUrl + `?${filterQuery}`;
                                    }
                                } else {
                                    if (filter.CustomFilters.CustomNavigationUrl.includes('/inventory/api/GetLocations')) {
                                        navigationProp.RequestUrl = filter.CustomFilters.CustomNavigationUrl;
                                    } else if (filter.CustomFilters.CustomNavigationUrl.includes('/inventory/api/GetCustomers')) {
                                        httpMethodType = 'PosthttpMethod';
                                        navigationProp.RequestUrl = filter.CustomFilters.CustomNavigationUrl;
                                        const customerPostPayload = {} as any;
                                        customerPostPayload.transTypeId = TransactionCollection.TransactionType;
                                        customerPostPayload.locationId = 0;
                                        customerPostPayload.searchText = '';
                                        customerPostPayload.skip = 0;
                                        customerPostPayload.take = 0;
                                        customerPostPayload.getTotalRecCount = true; 
                                        customerPostPayload.fetchAll = true;
                                        customerPostPayload.totalCount = 0;
                                        customerPostPayload.lookupField = '';
                                        customerPostPayload.orderByColumn = 'Name';
                                        customerPostPayload.orderByDirection = 1;
                                        navigationProp.Payload = customerPostPayload;
                                    }
                                }
                            } else if (filter && filter.hasOwnProperty('CustomOptions')) {
                                const filterDisplay = {} as GridFilterData;
                                filterDisplay.FilterKey = filter.FilterDisplayName;
                                filterDisplay.FilterValue = filter.CustomOptions;
                                filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                filterDisplay.Type = filter.hasOwnProperty('CustomType') ? filter.CustomType : 'multiselect';
                                navigationProp.RequestUrl = null;
                                GridFilterRowData.push(filterDisplay);
                            } else if (filter && filter.hasOwnProperty('CustomType')) {
                                if ( filter.hasOwnProperty('CustomGridFilterData') && filter.CustomGridFilterData.length > 0  ) {
                                    filter.CustomGridFilterData.forEach(res => {
                                        GridFilterRowData.push(res);
                                    });
                                }
                            }
                        }
                        if (navigationProp && navigationProp.RequestUrl !== null && navigationProp.RequestUrl !== undefined
                            && !filter.LoadOnClick) {
                            httpService[httpMethodType](navigationProp.RequestUrl, navigationProp.Payload).subscribe((response: any) => {
                                if ((response && response.value && response.value.length > 0) || (response && response.ResultData && response.ResultData.length > 0)) {
                                    const resData = (response.value && response.value.length > 0) ? response.value : response.ResultData;  
                                    const filterKeyData = resData.map((data: any) => {
                                        const filterKey = {} as GridFilterValue;
                                        filterKey.FilterId = GridInterface.GridFilterKeyMapping(filter, data);
                                        filterKey.FilterName = GridInterface.GridFilterLabelMapping(filter, data);
                                        filterKey.Checked = false;
                                        return filterKey;
                                    });
                                    const filterDisplay = {} as GridFilterData;
                                    filterDisplay.FilterDisplayName = (filter.FilterDisplayName === undefined) ?
                                    filter['FilterDisplayName'] : filter.FilterDisplayName;
                                    filterDisplay.FilterKey = (filter.FilterDisplayName === undefined) ?
                                    filter['FilterDisplayName'] : filter.FilterDisplayName;
                                    filterDisplay.FilterValue = filterKeyData;
                                    filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                    filterDisplay.FilterOdataKey = filter.FilterOdataKey;
                                    filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                    filterDisplay.Type = 'multiselect';
                                    filterDisplay.SortNumber = filter.SortingNumber;
                                    filterDisplay.MappingKeyForNonOdataCore = filter.MappingKeyForNonOdataCore;
                                    GridFilterRowData.push(filterDisplay);
                                }
                                if ( filter.hasOwnProperty('CustomGridFilterData') && filter.CustomGridFilterData.length > 0  ) {
                                    GridFilterRowData.concat(filter.CustomGridFilterData);
                                }
                                updateFilterInGridData(TransactionCollection, GridFilterRowData);
                            },
                                (responseError) => {
                                    console.error('error caught in component', responseError);
                                });
                        }  else if (filter.hasOwnProperty('LoadOnClick') && filter.LoadOnClick) {
                            const filterDisplay = {} as GridFilterData;
                            filterDisplay.FilterDisplayName = filter.FilterDisplayName;
                            filterDisplay.FilterKey = filter.FilterDisplayName;
                            filterDisplay.FilterValue = [];
                            filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                            filterDisplay.FilterOdataKey = filter.FilterOdataKey;
                            filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                            filterDisplay.Type = 'multiselect';
                            filterDisplay.FilterUrl = navigationProp.RequestUrl;
                            filterDisplay.EnableLoader = filter.EnableLoader;
                            filterDisplay.LoadOnClick = filter.LoadOnClick;
                            filterDisplay.MappingKeyForNonOdataCore = filter.MappingKeyForNonOdataCore;
                            GridFilterRowData.push(filterDisplay);
                            updateFilterInGridData(TransactionCollection, GridFilterRowData);
                        }
                    });


                }
            }
        }
        return GridFilterRowData;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};
function getFilterQuery(CustomFilterQuerys: Array<CustomFilterQuery>) {
    if (CustomFilterQuerys.length > 0) {
        const queryList = [];
        CustomFilterQuerys.forEach(querys => {
            let querystring = '';
            switch (querys.Type) {
                case FilterTypes.Multiple: {
                    let value = '';
                    if (querys.CustomOdataUrlName) {
                        value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                        querystring = querys.OdataKey + ' in (' + value + ')';
                        if ( querys.hasOwnProperty('Name')) {
                          querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                        }
                    } else if (querys.Name === querys.OdataKey) {
                        value = querys.DefaultValue.join();
                        querystring = querys.OdataKey + ' in (' + value + ')';
                    } else {
                        value = querys.DefaultValue.join();
                        querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                    }
                    break;
                }
                case FilterTypes.GreaterThanEqual: {
                    if (querys.FieldType === FieldEdmType.EDMDATEOFFSET) {
                        // const date = new Date();
                        // Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
                        //     date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                        // // const currentDate = new Date(now_utc);
                        // const utcdate = moment.utc().format();
                        // querystring = querys.OdataKey + ' ge ' + utcdate;
                        querystring = querys.OdataKey + ' ge ' + moment().startOf('day').format(constants.PostDateTimeFormat);
                    } else {
                        querystring = querys.OdataKey + ' ge ' + querys.DefaultValue;
                    }
                    break;
                }
                case FilterTypes.Equal: {
                    querystring = querys.OdataKey + ' eq ' + querys.DefaultValue;
                    break;
                }
                case FilterTypes.NotEqual: {
                    // querystring = 'not(' + query.OdataKey + ' eq ' + query.DefaultValue + ')';
                    querystring = querys.OdataKey + ' ne ' + querys.DefaultValue;
                    break;
                }
            }
            queryList.push(querystring);
        });
        const returnData = queryList.join(' and ');
        return returnData;
    }
}

function updateFilterInGridData(TransactionCollection: TransactionConfiguration, GridFilterRowData) {
    try {
        let OrderFilter = [];
        if (!TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions']) {
            TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions'] = {};
            if (!TransactionCollection.ViewTranscationConfig.GridConfiguration.GridActions['FilterConfig']) {
                TransactionCollection.ViewTranscationConfig.GridConfiguration.GridActions['FilterConfig'] = {
                    EnableFilter: false,
                    FilterOptions: []
                };
            }
        }
        if (GridFilterRowData.length > 1) {
            GridFilterRowData.sort((a, b) => a.SortNumber - b.SortNumber);
            OrderFilter = GridFilterRowData;
        } else {
            OrderFilter = GridFilterRowData;
        }
        TransactionCollection.ViewTranscationConfig.GridConfiguration['GridActions']['FilterConfig']['EnableFilter']
            = GridFilterRowData.length > 0 ? true : false;
        TransactionCollection.ViewTranscationConfig.GridConfiguration
        ['GridActions']['FilterConfig']['FilterOptions'] = OrderFilter;
        TransactionCollection.ViewTranscationConfig.GridConfiguration
        ['GridActions'] = {...TransactionCollection.ViewTranscationConfig.GridConfiguration
            ['GridActions']};
        TransactionCollection.ViewTranscationConfig.GridConfiguration = {
            ...TransactionCollection.ViewTranscationConfig.GridConfiguration};
    } catch (error) {
        console.error('Error occurred in updateFilterInGridData', error);
    }
}