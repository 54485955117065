export const JSBARCODE_CDN: string = "https://cdn.jsdelivr.net/jsbarcode/3.6.0/JsBarcode.all.min.js";
export const BARCODE_SCRIPT: string = `
function run() {
    JsBarcode(".jsbarcode").init();
    window.print();
}
`
export const BARCODE_PRINT_STYLES: string = `
@media print {

  @page {
      size: letter portrait;
      margin: 0cm;
  }

  table {
      page-break-after: always;
      page-break-inside: avoid;
  }

}

div {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

table {
  margin-top: 0.5in;
  margin-left: 0.15in;
  width: 100%;
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

td {
  width: 33.3%;
  height: 0.98in;
  padding-top: 0.1in;
}

.sheet {
  width: 8.35in;
  height: 10in;
}

.barcode {
  text-align: center;
}

.page {
  page-break-after: always;
}

.page:last-child {
  page-break-after: avoid;
}
`;