import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as GlobalConst from '../../../shared/globalsContant';
import { MatDialog } from '@angular/material/dialog';
import { ActionMode, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { TableHeaderOptions } from 'src/app/common/Models/ag-models';
import { CgpsLoggingProfileSyncWrapperBusiness } from './cgps-logging-profile-sync-wrapper.business';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AppointmentPopupComponent } from 'src/app/shared/appointment-popup/appointment-popup.component';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { AppointmentpopupService } from 'src/app/shared/service/appointmentpopup.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { HttpServiceCall } from 'src/app/shared/service/http-call.service';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
@Component({
  selector: 'app-cgps-logging-profile-sync-wrapper',
  templateUrl: './cgps-logging-profile-sync-wrapper.component.html',
  styleUrls: ['./cgps-logging-profile-sync-wrapper.component.scss'],
  providers: [CgpsLoggingProfileSyncWrapperBusiness]
})
export class CgpsLoggingProfileSyncWrapperComponent implements OnInit {
  searchHeaderOption: { searchPlaceHolder: string; createBtnLabel: string; };
  tableOptions: { actions: { type: any; disabled: any; }[]; defaultsortingColoumnKey: string; showTotalRecords: boolean; defaultSortOrder: any; columnFreeze: { firstColumn: boolean; lastColumn: boolean; }; isDragDisabled: boolean; isHeaderCheckboxAllowed: boolean; checkboxKey: string;};
  isViewOnly: any;
  headerOptions: TableHeaderOptions[];
  captions: any;
  EnableSave: boolean = true;
  tableContent: any;
  clientInfo: any;
  originalData: Observable<any>;
  newGuestToggle: boolean = false;
  guestInputs: {
    mode: ActionMode;
    data?: Observable<any>
  };
  creaGuestForm: any;
  routerState: any;
  constructor(private localization: Localization
    , private appointmentservice: AppointmentpopupService
    , private breakPoint: BreakPointAccess
    , private _business: CgpsLoggingProfileSyncWrapperBusiness
    , private utilities: CommonUtilities
    , private dialog: MatDialog
    , private router: Router) {
    this.captions = this.localization.captions;
    const currentNavigation = this.router.getCurrentNavigation();
    this.routerState = currentNavigation ? currentNavigation.extras.state : null;
  }

  ngOnInit(): void {
    this.searchHeaderOption = {
      searchPlaceHolder: "Search by LastName, FirstName, Description",
      createBtnLabel: "Sync",
    }
    this.tableOptions = this._business.getTableOptions();
    this.headerOptions = this._business.getHeaderOptions();
  }

  ngAfterViewInit() {
    this.utilities.ToggleLoader(true);
    this.getTableData();
  }

  refreshGridData(event)
    {
      this.utilities.ToggleLoader(true);
      this.getTableData();
    }

  getTableData() {
    this._business.GetFailedProfile().then(value => {
      this.tableContent = value;
    });
    this.utilities.ToggleLoader(false);
  }


  saveEmitValue(eve) {
    if(eve.startDate && eve.endDate)
    {
      let result = this._business.DateRangeProfileSync(eve.startDate, eve.endDate);
      if (result) {
        this.utilities.showAlert(this.captions.lbl_processInitiated, AlertType.Info, ButtonType.Ok);
    }
  }
  }

  tableAction(event) {
    let result = this._business.singleProfileSync(event.Obj.guestId, event.Obj.syncDirection);
    if (result) {
      this.utilities.showAlert(this.captions.lbl_processInitiated, AlertType.Info, ButtonType.Ok);
    }
  }

  async EditEvent(event) {
    this.utilities.ToggleLoader(true);
    if (event) {
      //let clientInformation = await this._business.getClientInfoByGuid(event.guestId);
      let clientInfos = await this._business.GetAllClientsbyguid([event.guestId]);
      let clientInfo = clientInfos[1];
      //clientInformation.client.id = clientInfo[0].id;
      //clientInformation.id = clientInfo[0].id;
      let clientInformation = await this._business.GetClientInfo(clientInfo[0].id);
      //this.openEditDialog(clientInfo.client.id, clientInfo);
      this.EditRecords(clientInfo[0].id, clientInfo[0].guestId, clientInformation);
    }
  }

  EditRecords(clientId, guestId, clientInformation) {
    
    if (this.breakPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.EditClientProfile])) {
      this.appointmentservice.add_client = true;
      this.appointmentservice.IsAddClientFromSPA = true;
      this.appointmentservice.popupTitle = this.captions.bookAppointment.EditClient;
      this.appointmentservice.clientWidowActionType = 'EDIT';
      this.appointmentservice.saveText = this.localization.captions.common.Update;

      this.appointmentservice.clientId = clientId;
      this.appointmentservice.guestId = guestId;  
      this.appointmentservice.clientEditData = clientInformation;
      
      this.openEditDialog(guestId, clientInformation);
    }
  }


  openEditDialog(guestId: any, clientInformation) {
    const dialogRef = this.dialog.open(AppointmentPopupComponent, {
      width: '95%',
      height: '85%',
      disableClose: true,
      hasBackdrop: true,
      data: { data: '',  from: 'CGPS', guestId: guestId, action: 'Edit', closebool: true, clientInfo: clientInformation},
      panelClass: 'small-popup'
    });
    dialogRef.afterClosed().subscribe(params => {
      this.utilities.ToggleLoader(false);
      this.utilities.ToggleLoader(true);
      this.getTableData();
      this.utilities.ToggleLoader(false);
    })
  }

}
