import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Localization } from 'src/app/common/localization/localization';
import { ButtonValue, TableHeaderOptions, TableOptions, TableSearchHeader } from 'src/app/common/Models/ag-models';
import { ViewNonIntegratedPaymentsBusiness } from './view-non-integrated-payments.business';
import { ReprintAdvancedSearch } from '../../retail-table/reprint-advanced-search/reprint-advanced-search.component';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import { Host, NonIntegratedCreditCardPaymentStatus, NonIntegratedCreditCardState, RetailFunctionalities, RetailTransactions } from 'src/app/retail/shared/globalsContant';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { BaseResponse, OutletSubProperty, PayeeInfo } from 'src/app/retail/shared/business/shared.modals';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailDataAwaiters } from 'src/app/retail/shared/events/awaiters/retail.data.awaiters';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { NonIntegratedTransaction } from 'src/app/retail/shared/service/payment/payment-model';
import { UntypedFormGroup, UntypedFormBuilder, NgModel } from '@angular/forms';
import { TransactionSearchQuery } from '../retail-transactions-log/retail-transactions-log';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { dateRangeUIModel } from '../../shop.modals';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
@Component({
  selector: 'app-view-non-integrated-payments',
  templateUrl: './view-non-integrated-payments.component.html',
  styleUrls: ['./view-non-integrated-payments.component.scss'],
  providers: [ViewNonIntegratedPaymentsBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ViewNonIntegratedPaymentsComponent implements OnInit {
  searchHeaderOption: TableSearchHeader;
  transcForm: UntypedFormGroup;
  captions: any;
  searchText: string;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  outletArray: OutletSubProperty[];

  selectedOutlet: any;
  isAdvancedSearchLoading: boolean;
  userList: any[] = [];
  PayeeSearchTypes = { member: 1, guest: 2 };
  enableSaveBtn = false;
  transactionResult: NonIntegratedTransaction[] = [];
  serviceID: any;
  fromDate: any;
  toDate: any;
  floatLabel: string;
  placeholderFormat: string;
  showOutlet : boolean = false;
  functionalities: { [key: string]: boolean} = {};
  @ViewChild('fromDateModel') fromDateModelControl: NgModel;
  @ViewChild('toDateModel') toDateModelControl: NgModel;
  @Output() dateEvtEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder,
    private business: ViewNonIntegratedPaymentsBusiness
    , private localization: Localization
    , private _http: HttpServiceCall
    , private _utils: RetailUtilities
    , private _prop: RetailPropertyInformation
    , private _memberService: MemberBusinessService
    , public propertyInfo: RetailPropertyInformation,
      private retailFunc: RetailFunctionalityBusiness,
  ) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.placeholderFormat = this.localization.inputDateFormat;
  }


  async ngOnInit() {
    this.serviceID = RetailTransactions.NonIntegratedPaymentLog;
    this.searchHeaderOption = {
      searchPlaceHolder: this.captions.lbl_searchByRoomNumber,
      hideActionBtn: true
    }    
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: this.enableSaveBtn
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    };
    this.retailFunc.getRetailFunctionality().then(res => {
      this.functionalities = res;
      this.showOutlet = this.functionalities[RetailFunctionalities.ShowOutletFieldInNonIntegratedPaymentLog] || false;
    });
    this.fromDate = this._prop.CurrentDate;
    this.toDate = this._prop.CurrentDate;
    this.tableOptions = this.business.getTableOptions();
    this.headerOptions = this.business.getTableHeaders(this.showOutlet);
    await this.GetUserOutlets();
    await this.getClerkInfo();
  }

  async GetNonIntegratedTransactions() {
    this.tableContent = [];
    this.enableSaveBtn = false;

    const defaultSearch: TransactionSearchQuery = {
      outletId: this.selectedOutlet,
      fromDate: this.localization.convertDateTimeToAPIDateTime(this.fromDate),
      toDate:this.localization.convertDateTimeToAPIDateTime(this.toDate),
      status: '',
      ticketNumber: "",
      amount: 0,
      clerkIdList: [],
      cardType: '',
      cardNumber: '',
      memberIdList: [],
      guestIdList: []

    }
    const transaction = await this.SearchTransaction(defaultSearch)
    this.tableContent = await this.business.getTableContent(transaction, this.userList, this.outletArray.find(x => x.subPropertyID === this.selectedOutlet).subPropertyName, []);
  }

  async GetUserOutlets() {
    const outletRes = await this._http.CallApiAsync<OutletSubProperty[]>({
      callDesc: 'GetSubPropertyAccessByUser',
      host: Host.retailManagement,
      method: HttpMethod.Get,
      uriParams: { userId: this._utils.GetPropertyInfo("UserId") }
    });
    const apiResponse: BaseResponse<OutletSubProperty[]> = outletRes;
    if (apiResponse && apiResponse.successStatus && apiResponse.result && apiResponse.result.length > 0) {
      this.outletArray = apiResponse.result;
      this.selectedOutlet = this.outletArray[0].subPropertyID;

      if(!this.showOutlet)
        this.selectedOutlet = 0;
    }
  }

  async onAdvancedSearchClick(params: ReprintAdvancedSearch) {
    this.isAdvancedSearchLoading = true;
    try {
      const userInfo = this.getClerkInfo();
      const memberSearch = this.getMemberSearch(params);
      const guestSearch = this.getClientSearch(params);
      const nameSearchTask = [userInfo, memberSearch, guestSearch];
      const nameSearchResult = await Promise.all(nameSearchTask);
      const searchParams = this.getAdvancedSearchParams(params);
      const isClerkSearched = (params.clerkId || '').trim() !== '';
      let members = [], guests = [];
      // To stop searching transaction if client/member/clerk not found
      let canSearchTransaction = true;
      // Clerk
      if (nameSearchResult[0] != null && isClerkSearched) {
        searchParams.clerkIdList = nameSearchResult[0].map(x => x.userId).filter(x => x > 0);
        canSearchTransaction = canSearchTransaction && searchParams.clerkIdList.length > 0;
      }
      // member
      if (nameSearchResult[1] != null) {
        members = nameSearchResult[1][1];
        // guestProfileId - playerLinkId
        searchParams.memberIdList = members.map(x => x.guestProfileId).filter(x => x > 0);
        canSearchTransaction = canSearchTransaction && searchParams.memberIdList.length > 0;
      }
      // guest
      if (nameSearchResult[2] != null) {
        guests = nameSearchResult[2][1];
        searchParams.guestIdList = guests.map(x => x.id).filter(x => x > 0);
        canSearchTransaction = canSearchTransaction && searchParams.guestIdList.length > 0;
      }

      const transactionSearch = canSearchTransaction ? await this.SearchTransaction(searchParams) : [];
      const result = {
        transaction: transactionSearch,
        clerks: nameSearchResult[0],
        members: members,
        guests: guests,
        voidedByClerks: nameSearchResult[3]
      };
      this.filterTransaction(result, params);
      this.isAdvancedSearchLoading = false;
    } catch {
      this.isAdvancedSearchLoading = false;
    }
  }

  async SearchTransaction(searchRequest: TransactionSearchQuery): Promise<NonIntegratedTransaction[]> {
    try {
      this._utils.ToggleLoader(true);
      this.transactionResult = [];
      let searchRes = await this._http.CallApiAsync<NonIntegratedTransaction[]>({
        callDesc: RetailRoutes.GetNonIntegratedCreditCardTransactions,
        host: Host.retailPOS,
        method: HttpMethod.Put,
        body: searchRequest
      });
      this._utils.ToggleLoader(false);
      this.transactionResult = searchRes.result;
      return searchRes.result;
    } catch (error) {
      this._utils.ToggleLoader(false);
      console.log("error while searching the transactions" + error);
    }
  }

  async getClerkInfo(): Promise<any[]> {
    let result = await this._http.CallApiAsync({
      callDesc: "GetAllUsers",
      method: HttpMethod.Get,
      host: Host.authentication,
      uriParams: { tenantId: Number(this._utils.GetPropertyInfo('TenantId')) }
    });
    let response: any = result && result.result ? result.result : [];
    this.userList = response;
    return response;
  }

  async filterTransaction(results, params: ReprintAdvancedSearch) {

    const isGuestSearched = (params.clientName || '').trim() !== '';
    const isMemberSearched = (params.memberName || '').trim() !== '';
    const isClerkSearched = (params.clerkId || '').trim() !== '';

    let transactions = results.transaction.result || [];
    const clerks = results.clerks || [];
    const members = results.members || [];
    const guests = results.guests || [];

    // filter transaction
    transactions = isClerkSearched ? this.filterTransactionByClerk(clerks, transactions) : transactions;
    transactions = isMemberSearched ? this.filterTransactionByMember(members, transactions) : transactions;
    transactions = isGuestSearched ? this.filterTransactionByGuest(guests, transactions) : transactions;
    let isCardNumberSearch = params.cardNumber != '' || params.cardType != '';
    this.tableContent = await this.business.getTableContent(results.transaction, this.userList, this.outletArray.find(x => x.subPropertyID === this.selectedOutlet)?.subPropertyName ?? '', guests, members);
  }

  sortCardNumbersBySearch(transaction, searchValue) {
    if (transaction.cardNumber.length > 1) {
      let index = transaction.cardNumber.findIndex((a) => {
        return a.includes(searchValue);
      });
      if (index != 0) {
        const value = transaction.cardNumber[index];
        transaction.cardNumber.splice(index, 1);
        transaction.cardNumber.unshift(value);
      }
    }
  }

  async getClientSearch(params: ReprintAdvancedSearch) {
    try {
      const uuid = `${Date.now()}${this._utils.getRandomDecimal() * 10000}`;
      const clientName = (params.clientName || '').trim();
      return !clientName ? null : await RetailDataAwaiters.searchTransactionGuest(clientName, this.PayeeSearchTypes.guest, uuid);
    } catch (e) {
      this._http.exceptionHandle(e);
    }
  }

  async getMemberSearch(params: ReprintAdvancedSearch) {
    try {
      const memberName = (params.memberName || '').trim();
      return !memberName ? null : await this._memberService.searchGuest(memberName, this.PayeeSearchTypes.member, true);
    } catch (e) {
      this._http.exceptionHandle(e);
    }
  }

  async getClerkSearch(params: ReprintAdvancedSearch) {

    let clerkInfo = this.userList && this.userList.length > 0 ? [...this.userList]
      : await this.getClerkInfo();

    if (params.clerkId && params.clerkId.trim() !== '') {
      clerkInfo = clerkInfo.filter(x => {
        const userName = (x.userName || '').toLowerCase();
        const clerkId = (params.clerkId || '').toLowerCase();
        return userName.includes(clerkId);
      });
    }

    return clerkInfo;
  }

  filterTransactionByGuest(clients: PayeeInfo[], transactions: any[]) {
    const guestList = clients.map(x => x.id);
    transactions = transactions.filter(x => {
      return guestList.includes(x.guestId);
    });
    return transactions;
  }

  filterTransactionByMember(clients: PayeeInfo[], transactions: any[]) {
    const memberList = clients.map(x => Number(x.guestProfileId));
    transactions = transactions.filter(x => {
      return memberList.includes(Number(x.memberId));
    });
    return transactions;
  }

  filterTransactionByClerk(clerks: any[], transactions: any[]) {
    const clerkList = clerks.map(x => x.userId);
    transactions = transactions.filter(x => {
      return clerkList.includes(x.clerkId);
    });
    return transactions;
  }

  getAdvancedSearchParams(params: ReprintAdvancedSearch): any {

    return {
      outletId: this.selectedOutlet,
      ticketNumber: params.ticketNumber,
      amount: this.localization.currencyToSQLFormat(params.amount),
      fromDate: this.localization.convertDateToAPIdate(this.fromDate),
      toDate: this.localization.convertDateToAPIdate(this.toDate),
      cardNumber: params.cardNumber,
      cardType: params.cardType,
      clerkIdList: [],
      memberIdList: [],
      guestIdList: []

    }
  }

  tableAction(event) {
    switch (event.fromType) {
      case FromTypeEnum.customAction:
      case FromTypeEnum.dropdown:
        this.enableSaveBtn = true;
        event.Obj.isUpdated = true;
        this.tableContent = event.array;
        break;
    }
  }
  onDateChange(_controlType: string, _value: MatDatepickerInputEvent<Date>) {
    if (this.fromDate >= this.toDate) {
      this.toDate = this.fromDate;
    }
    let _dateRange = {
      "startDate": this.localization.getDate(this.fromDate),
      "endDate": this.localization.getDate(this.toDate)
    };
    let event: dateRangeUIModel = {
      dateRange: _dateRange,
      value: _value.value,
      controlchanged: _controlType
    };
    this.dateEvtEmitter.emit(event);
  }
  onAdvancedSearchClose() { }

  searchTextChange(event) {
    this.searchText = event
  }

  async onAction() {
    let updatedTransactions = [];
    const updatedRows = this.tableContent.filter(x => x.isUpdated);
    if (updatedRows.length == 0) return;
    else {
      updatedRows.map(u => {
        let apiObj = this.transactionResult.find(t => t.id == u.id);
        apiObj.referenceComment = u.paymentComment;
        const statusMap = {
          [NonIntegratedCreditCardState.Completed]: NonIntegratedCreditCardPaymentStatus.Completed,
          [NonIntegratedCreditCardState.Realized]: NonIntegratedCreditCardPaymentStatus.Realized,
          [NonIntegratedCreditCardState.Failed]: NonIntegratedCreditCardPaymentStatus.Failed
        }
        apiObj.status = statusMap[Number(u.action)];
        updatedTransactions.push(apiObj);
      });
    }
    this._utils.ToggleLoader(true);
    let searchRes = await this._http.CallApiAsync<NonIntegratedTransaction[]>({
      callDesc: RetailRoutes.UpdateNonIntegratedCreditCardTransactions,
      host: Host.payment,
      method: HttpMethod.Put,
      body: updatedTransactions
    });
    this._utils.ToggleLoader(false);
    this.GetNonIntegratedTransactions();
  }

  onCancel() {
    this.GetNonIntegratedTransactions();
  }

  onPaymentCommentChange(event) {
    this.enableSaveBtn = true;
    this.tableContent.forEach(element => {
      if(element.id === event.id){
        element.paymentComment = event.paymentComment;
        element.isUpdated = true;
      }
    });
  }

  outletChange(eve) {

  }

}
