import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-spa-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleSearchComponent implements AfterViewInit {

  searchText = '';
  width: number;
  searchPlaceholder: string;
  searchDisabled = false;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchOptionChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() options;
  @Input() inputMaxLength: number;
  @Input() enableChangedOnClear: boolean;
  @Input('value')
  set setSearchField(value: string) {
    this.searchText = value;
    this.searchValueChange();
  }
  @Input() focusField: boolean;
  @Input('disabled')
  set setDisabled(value: boolean) {
    this.searchDisabled = value;
    if (value) {
      this.clearText();
    }
  }

  @Input('placeholder')
  set setPlaceHolder(value: string) {
    if (value) {
      this.searchPlaceholder = value;
      this.width = value.length + 5;
    }
  }

  resize: boolean;
  @Input('noResize')
  set callResizeSearchBox(value: boolean) {
    if (value) {
      this.resize = value;
    }
    if (!value && this.resize) {
      this.calculateSearchBoxWidth();
      this.resize = false;
    }
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateSearchBoxWidth();
    }, 20);
    if (this.focusField) {
      this.searchInput.nativeElement.focus();
    }
  }
  calculateSearchBoxWidth() {
    const headerWrapper = document.getElementsByClassName('header-wrapper')[0];
    let pageHeader = headerWrapper ?
      headerWrapper['offsetWidth'] : 0;
    const searchInput = document.getElementsByClassName('searchbox')[0];
    let matFormWidth = this.setMatformWidth();
    if (!matFormWidth) {
      matFormWidth = this.setMatformWidth();
    }
    if (pageHeader > 0) {
      pageHeader = pageHeader - matFormWidth - 60;
    }
    const inputLength = this.searchInput ? this.searchInput.nativeElement.placeholder.length : 1;
    const inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 10 + 20;
    if (searchInput && pageHeader > 0) {
      const calculatedWidth = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      searchInput['style'].width = calculatedWidth;
      if (headerWrapper.classList.contains('--actionFirst')) {
        document.getElementsByClassName('right-action-section')[0]['style'].width = calculatedWidth;
      }
    }

  }

  setMatformWidth() {
    let minWidthElement;
    
    if (document.getElementsByClassName('header-wrapper')[0]?.classList.contains('--actionFirst')) {
      minWidthElement = 'left-action-section';
    } else {
      minWidthElement = 'right-action-section';
    }
    return document.getElementsByClassName(minWidthElement)[0] ?
      document.getElementsByClassName(minWidthElement)[0]['offsetWidth'] : 300; //min-300 max-470
  }

  searchValueChange(event?: boolean) {
    if (this.searchText || this.enableChangedOnClear || event) {
      const searchText = this.searchText ? this.searchText.trim() : '';
      this.searchChange.emit(searchText);
    }
  }

  clearText() {
    this.searchText = '';
    this.searchValueChange();
  }

  clearTextValue() {
    this.searchText = '';
    this.searchChange.emit(this.searchText.trim());
  }

  optionSelected(arg) {
    this.searchText = arg.option.value.viewValue;
    this.searchOptionChange.emit(arg.option.value);
  }
}
