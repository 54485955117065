import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as constants from './shared/globalConstants';

@Injectable({
  providedIn: 'root'
})
export class DynamicGridService {
  sortorder: string;
  setColumnDefs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  resizeRows: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  language: any;
  constructor() { }

  /**
   * @method assignColumns
   * @param jsonName, columnName
   * @description Here will compate the common shuffled values in local storage with columns given in component
   */
  assignColumns(jsonName, columnName) {
    try {
      const modifiedColumns = [];
      const localData = localStorage.getItem(constants.columnInfo) === undefined || localStorage.getItem(constants.columnInfo) === ''
      ? null : localStorage.getItem(constants.columnInfo);
      const data = JSON.parse(localData);
      if (data) {
        const columnInfo = data[jsonName];
        if (columnInfo) {
          const columnValues = Object.values(columnInfo);
          columnValues.forEach(value => {
            const columnKeys = Object.keys(value);
            for (const elements of columnName) {
              if (elements.field !== '' && elements.headerName !== '') {
                if (columnKeys[0] === elements.field) {
                  elements.hide = !value[elements.field];
                  if (!modifiedColumns.includes(elements)) {
                    modifiedColumns.push(elements);
                    break;
                  }
                }
              } else {
                if (!modifiedColumns.includes(elements)) {
                  elements.hide = false;
                  modifiedColumns.push(elements);
                  if (modifiedColumns.filter(x => x.field === columnKeys[0]).length !== 0) {
                    break;
                  }
                }
              }
            }
          });
          if (columnName.length !== modifiedColumns.length) {
            modifiedColumns.push(columnName[columnName.length - 1]);
          }
          return modifiedColumns;
        } else {
          return columnName;
        }
      } else {
        return columnName;
      }
    } catch (error) {
      console.error('Error occurred in assignColumns', error);
    }
  }

  get languageModifier(): any {
    return this.language;
  }
  set languageModifier(languageObject: any) {
    this.language = languageObject;
  }
}
