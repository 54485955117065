import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DecimalRoundOff } from 'src/app/eatecui/source/shared/enum/global.enum';
import { ElementRef, Renderer2 } from '@angular/core';

export const CalcPurchaseUnitCost: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            const decimalPoint = sessionStorage.getItem('appsetting') ? 
            JSON.parse(sessionStorage.getItem('appsetting')).DecimalPointRoundOff : DecimalRoundOff.SIX;
            const CurrentCostData = FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value;
            const CurrentCost = CurrentCostData && !isNaN(Number(CurrentCostData)) ? parseFloat(CurrentCostData) : 0;
            const PurchaseConversionData = FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].value;
            const PurchaseConversion = PurchaseConversionData && !isNaN(Number(PurchaseConversionData)) ?
                 parseFloat(PurchaseConversionData) : 0;
            const PurchaseCost = PurchaseConversion && PurchaseConversion > 0 ?
             (FieldGrouping.CustomFormAction.EnabledField === 'RecipeCurrentCost' ?
                (1 / PurchaseConversion) * CurrentCost : CurrentCost * PurchaseConversion) : 0;
            FormGroupData.controls[FieldGrouping.CustomFormAction.EnabledField].setValue(PurchaseCost ? parseFloat(PurchaseCost.toString()).toFixed(4) : 0);
            setFormatedValue(FieldGrouping.CustomFormAction.EnabledField);
            // if (FormGroupData.controls['Markup'] && FormGroupData.controls['Markup'].value && 
            // parseFloat(FormGroupData.controls['CurrentCost'].value)) {
            //     const ResultPrice = parseFloat(FormGroupData.controls['CurrentCost'].value) + 
            //    (parseFloat(FormGroupData.controls['CurrentCost'].value) / 100) * FormGroupData.controls['Markup'].value;
            //     FormGroupData.controls['ProductPrice'].setValue(ResultPrice);
            // }
            // current cost prodcut price calculation
            if ( FieldGrouping.OdataKey === 'BaseCurrentCost' ||  FieldGrouping.OdataKey === 'Markup') {
               const GetCurrentCostValue = FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value;
               const GetMarkupValue = ( FormGroupData.controls.hasOwnProperty('Markup')) ? FormGroupData.controls['Markup'].value : 0;
               const ParseMarkupValue = ( GetMarkupValue &&  parseFloat(GetMarkupValue) !== undefined ) ? GetMarkupValue : 0;
               if ( GetCurrentCostValue && GetCurrentCostValue !== '' && GetCurrentCostValue !== null ) {
                const currentcostparsedValue = parseFloat(GetCurrentCostValue);
                if ( currentcostparsedValue && currentcostparsedValue > 0 ) {
                    const ResultProductPrice = currentcostparsedValue + ( currentcostparsedValue * ParseMarkupValue / 100) ;
                    if ( FormGroupData && FormGroupData.controls.hasOwnProperty('ProductPrice'))  {
                        FormGroupData.controls['ProductPrice'].setValue(ResultProductPrice ? parseFloat(ResultProductPrice.toString()).toFixed(4) : 0);
                        setFormatedValue('ProductPrice');
                    }
                } else {
                    if ( FormGroupData && FormGroupData.controls.hasOwnProperty('ProductPrice'))  {
                        FormGroupData.controls['ProductPrice'].setValue(0);
                        setFormatedValue('ProductPrice');
                    }
                }
               }
               
            }
            if (FieldGrouping.OdataKey === 'BaseCurrentCost') {
                if (FormGroupData.controls['TransactionCurrentCost']) {
                    const TransactionConversion = FormGroupData.controls['TransactionConversion'].value ?
                        parseFloat(FormGroupData.controls['TransactionConversion'].value) : 0;
                    const TransactionCost =  CurrentCost * TransactionConversion;
                    FormGroupData.controls['TransactionCurrentCost'].setValue(TransactionCost ? parseFloat(TransactionCost.toString()).toFixed(4) : 0);
                    setFormatedValue('TransactionCurrentCost');
                }
                if (FormGroupData.controls['RecipeCurrentCost']) {
                    const RecipeConversion = FormGroupData.controls['RecipeConversion'].value ?
                        parseFloat(FormGroupData.controls['RecipeConversion'].value) : 0;
                    const RecipeCost =  (1 / RecipeConversion) * CurrentCost;
                    FormGroupData.controls['RecipeCurrentCost'].setValue(RecipeCost ? parseFloat(RecipeCost.toString()).toFixed(4) : 0);
                    setFormatedValue('RecipeCurrentCost');
                }
            }
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function setFormatedValue(ElementName: string): void {
    try {
        let renderer2: Renderer2;
        let el: ElementRef;
        if (document.getElementById(ElementName)) {
            document.getElementById(ElementName).dispatchEvent(new CustomEvent('bluraction', {'detail': 'customaction'}));
        }
    } catch (error) {
        console.error('Error occurred in setFormatedValue', error);
    }
}
