import { TranslateService } from '@ngx-translate/core';
import { ColumnFieldType } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FieldEdmType } from '../../../../../setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import { TransactionConfiguration } from '../../transaction.interface';
import { ViewGridHeaderConfiguration } from './grid-header-config.interface';

export const ViewGridHeaderType: ViewGridHeaderConfiguration =
  (ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    HeaderObject: any,
    translateService?: TranslateService, TilesHeaderTrascationEntity?: any): any => {
    try {
      if (TransactionCollection && TransactionCollection.ViewTranscationConfig.GridConfiguration) {
        if ( HeaderObject['$'].Name === 'Id' ||  HeaderObject['$'].Name === 'TransSequenceNo'
          || HeaderObject['$'].Name === 'TransAndTransOutSequenceNo') {
          return ColumnFieldType.NONEDITABLE;
        } else {
          return getColumnType(HeaderObject, TransactionCollection, ViewHeaderEntity);
        }
      }
    } catch (error) {
      console.error('Error occurred in Setdefaultsort', error);
    }
  };

/**
* @method getColumnType()
* @param type name;
* @description Assign form type
*/
function getColumnType(EntityProperty: any, TransactionCollection: TransactionConfiguration, ViewHeaderEntity: any): string {
  try {
    let columnType = '';
    switch (EntityProperty['$']['Type']) {
      case FieldEdmType.EDMSTRING:
        columnType = ColumnFieldType.NONEDITABLE;
        break;
      case FieldEdmType.EDMINT32:
      case FieldEdmType.EDMINT16:
        columnType = checkNavigationProperity(EntityProperty, ViewHeaderEntity);
        break;
      case FieldEdmType.EDMDATEOFFSET:
        columnType = ColumnFieldType.DATECOLUMN;
        break;
      case FieldEdmType.EDMINT64:
        columnType = ColumnFieldType.NUMERIC;
        break;
      case FieldEdmType.EDMDOUBLE:
      case FieldEdmType.EDMFLOAT:
      case FieldEdmType.EDMINTDECIMAL:
        columnType = ColumnFieldType.DECIMAL;
        break;
      default:
        columnType = ColumnFieldType.NONEDITABLE;
    }
    return columnType;

  } catch (error) {
    console.log('error occured', error);
  }
}

/**
 * @functiodn checkNavigationProperity()
 * @prams odataEntity, odataobject
 * @description check navigation properoity
 */

function checkNavigationProperity(OdataObject: any, OdataViewEntityModel: any): string {
  try {
    let fieldType = ColumnFieldType.NUMERIC;
    if (OdataViewEntityModel && OdataViewEntityModel.length > 0 && OdataViewEntityModel[0].hasOwnProperty('NavigationProperty')) {
      const getNavigationType = OdataViewEntityModel[0]['NavigationProperty'].
        filter(x => x['ReferentialConstraint'] && x['ReferentialConstraint'][0]['$']['Property'] === OdataObject['$']['Name']);
      if (getNavigationType && getNavigationType.length > 0) {
        fieldType = ColumnFieldType.NONEDITABLE;
      }
    }
    return fieldType;
  } catch (error) {
    console.error(error);
  }
}

