import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-add-comments',
  templateUrl: './add-comments.component.html',
  styleUrls: ['./add-comments.component.scss']
})
export class AddCommentsComponent implements OnInit {

  captions: any;
  headerdata: string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  comments:string;
  floatLabel:string
  constructor(private dialogRef: MatDialogRef<AddCommentsComponent>, private localization: Localization,@Inject(MAT_DIALOG_DATA) public data: any,) { 
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.headerdata = this.data?.isEdit ? this.captions.lbl_editComment : this.captions.lbl_addComments;
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };

    if (this.data?.lockerComment) {
      this.comments = this.data.lockerComment
    }
  }

  onCommentsTyped(event) {
    if (event && event.length > 0) {
      this.saveButton.disabledproperty = false;
    } else {
      this.saveButton.disabledproperty = true;
    }
    this.comments = event;
    this.saveButton = { ...this.saveButton };
  }

  save() {
    this.dialogRef.close(this.comments);
  }
  cancel() {
    this.dialogRef.close();
  }

  close(eve) {
    this.dialogRef.close();
  }
}
