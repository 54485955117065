import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer, UntypedFormControl } from '@angular/forms';
import { DropDownData } from 'src/app/common/shared/retail.modals';
import { Product } from 'src/app/retail/shared/globalsContant';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';

@Component({
  selector: 'app-retail-inventory-summary-by-vendor',
  templateUrl: './inventory-summary-By-Vendor.component.html',
  styleUrls: ['./inventory-summary-By-Vendor.component.scss']
})
export class RetailInventorySummaryByVendorComponent implements OnInit {
  InventorySummaryByVendorFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  productId: number;
  hideDisposableItem: boolean;
  radioFilter: { id: number; name: string; }[];
  disposableItemsValue: boolean;
  allVendors: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  editPatchValue: any;
  isFromEdit = false;
  isFromJobScheduler: boolean = false;
  categoryComponentFilter: any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private controlContainer: ControlContainer,
    private dataService: ReportDataService,public business: ReportBusinessService, private container: ViewContainerRef) {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.hideDisposableItem = this.productId == Product.PMS ? false : true;
   }

  async ngOnInit() {
    this.radioFilter = [{id: 0, name: this.captions.AllActiveitems}, {id: 1, name: this.captions.SuppressZeroQtyItems}];
    this.InventorySummaryByVendorFormGrp = this.fb.group({
      lostCostMethod: false,
      categoryView: false,
      itemFilter: 0,
      category: new UntypedFormGroup({}),
      vendors: new UntypedFormControl(this.defaultData),
      DisposableItems: this.disposableItemsValue == null ? false : this.disposableItemsValue
    });
    this.formReady.emit(this.InventorySummaryByVendorFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        await this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit){
        this.categoryComponentFilter =
        {
          category: _parent.editableValue.categoryIds,
          subCategory1: _parent.editableValue.subCategory1Ids,
          subCategory2: _parent.editableValue.subCategory2Ids,
          subCategory3: _parent.editableValue.subCategory3Ids,
          subCategory4: _parent.editableValue.subCategory4Ids,
          subCategory5: _parent.editableValue.subCategory5Ids
        };
        this.PatchSchedulercontrols(_parent.edit);
      }
    }
  }

  private PatchSchedulercontrols(isEdit) {
    if (this.localization.isFromJobScheduler && isEdit) {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      if(patchItem.vendorIds != undefined){
        this.dataService.getAllVendor().then(res => {
            let arr = res.filter((item) => {
              return this.editPatchValue.vendorIds.find(x => x == item.id);
          });
          this.allVendors = arr;
          this.business.FilterDataSource["allVendors"] = this.allVendors.filter(u => u.isActive);
          this.InventorySummaryByVendorFormGrp.controls['vendors'].setValue(this.allVendors);
        });
      }
      setTimeout(() => {
        if (patchItem.lostCostMethod != undefined)
          this.InventorySummaryByVendorFormGrp.controls.lostCostMethod.patchValue(patchItem.lostCostMethod);
        if (patchItem.lostCostMethod != undefined)
          this.InventorySummaryByVendorFormGrp.controls.lostCostMethod.patchValue(patchItem.lostCostMethod);

        if (patchItem.categoryView != undefined)
          this.InventorySummaryByVendorFormGrp.controls.categoryView.patchValue(patchItem.categoryView);

        if (patchItem.DisposableItems != undefined)
          this.InventorySummaryByVendorFormGrp.controls.DisposableItems.patchValue(patchItem.DisposableItems);

        if (patchItem.itemFilter != undefined){
          var itemFilter = patchItem.itemFilter == "true" ? 1 : 0;
          this.InventorySummaryByVendorFormGrp.controls.itemFilter.patchValue(itemFilter);
        }           
      }, 1);
    }
  }

  toggleDisposableItems(event){
    this.disposableItemsValue = event;
  }

  private async onLoad() {
    this.allVendors = await this.dataService.getAllVendor();
    this.business.FilterDataSource["allVendors"] = this.allVendors.filter(u => u.isActive);
    this.InventorySummaryByVendorFormGrp.controls['vendors'].setValue(this.allVendors);
  }

   /**
   * After a form is initialized, we link it to our main form
   */
  formInitialized(form: UntypedFormGroup) {
    this.InventorySummaryByVendorFormGrp.setControl('category', form);
  }


  IsSelectionValid(validationObject: ValidationMessage) {
  
    if ((this.InventorySummaryByVendorFormGrp.controls['vendors'] && this.InventorySummaryByVendorFormGrp.controls['vendors'].value.length == 0 && this.InventorySummaryByVendorFormGrp.controls["category"] && this.InventorySummaryByVendorFormGrp.controls["category"].value[0] && this.InventorySummaryByVendorFormGrp.controls["category"].value[0].selectedCat.length == 0)
    || (!this.InventorySummaryByVendorFormGrp.controls['vendors'] && this.InventorySummaryByVendorFormGrp.controls["category"] && this.InventorySummaryByVendorFormGrp.controls["category"].value[0] && this.InventorySummaryByVendorFormGrp.controls["category"].value[0].selectedCat.length == 0)
    ) {
      let validSelectionErrMsg = this.localization.captions.reports.PleaseSelectVendor;
      this.IsValidSelection.emit({ dropDownName: validSelectionErrMsg, validity: false });
    }
    else {
      this.IsValidSelection.emit({ dropDownName: '', validity: true });
    }
 
  }

  getFormControlValue(event) {   
   
    if (event[0] === 'vendors') {
      this.InventorySummaryByVendorFormGrp.setControl('vendors', event[1]);
      } 
  }
}
