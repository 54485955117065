import { AbstractControl } from '@angular/forms';

export function PhoneNumberValidator(ctrl: AbstractControl) {
    const val = ctrl.value ? ctrl.value :'';
    const res = val.replace(/\D/g, '');
    if (ctrl.touched && (res.length < 8 || res.length > 15)) {
        ctrl.setErrors({incorrect: true});
    }
    return null;
}
