import { Injectable } from '@angular/core';
import { JWT_TOKEN, PAYMENT_CONFIGURATION, PROPERTY_DATE, PROPERTY_INFO, REMEMBER_INFO, USE_RETAIL_INTERFACE, USER_INFO, USER_SESSION, USER_SETTINGS, USERS_SESSSIONS_INFO, VAT_ENABLED } from '../shared.modal';
declare let $: any;
@Injectable({
    providedIn: 'root'
})
export class StorageService {

     getstoragekey(key){
        return sessionStorage.getItem(key)
       }
        setstoragekey(key,value){
         return sessionStorage.setItem(key,value)
       }
        removestoragekey(key){
         return sessionStorage.removeItem(key)
       }
          getlocalkey(key){
        return localStorage.getItem(key)
       }
         setlocalkey(key,value){
         return localStorage.setItem(key,value)
       }
        removelocalkey(key){
         return localStorage.removeItem(key)
       }
        clearstoragekey(){
         sessionStorage.clear();
      }
       clearlocalkey(){
         localStorage.clear();
      }
      setlocalstorage()
      {
        for (let i = 0; i < sessionStorage.length; i++) {
          const key = sessionStorage.key(i); 
          const value = sessionStorage.getItem(key); 
          localStorage.setItem(key, value);
      }
      }
    }

