import { Pipe, PipeTransform } from '@angular/core';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';

@Pipe({
  name: 'getTime'
})
export class GetTimePipe implements PipeTransform {
  constructor(private _Utilities: SpaUtilities) { }

  transform(start: any, end?: any): any {
    const flag = end.some(x => {
      return this._Utilities.isDateInGivenRange(this._Utilities.getDate(start.startTime), this._Utilities.getDate(x.startTime), this._Utilities.getDate(x.endTime)) &&
        this._Utilities.isDateInGivenRange(this._Utilities.getDate(start.endTime), this._Utilities.getDate(x.startTime), this._Utilities.getDate(x.endTime));
    });
    return !flag;
  }

}