import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { popupConfig } from '../../../shared/business/shared.modals';

import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { Subscription } from 'rxjs';
import { SpaLocalization } from '../../../core/localization/spa-localization';
@Component({
  selector: 'app-password-mgmt',
  templateUrl: './password-mgmt.component.html',
  styleUrls: ['./password-mgmt.component.scss']
})
export class PasswordManagement implements OnInit, OnDestroy {
  //any
  clickbutton: any;
  captions: any;

  //boolean
  IsLengthValid: boolean;      // 8 minimum req
  IsHavingNo: boolean;         // 1 minimum req
  IsHavingLowerCase: boolean;  // 1 minimum req
  IsHavingUpperCase: boolean;  // 1 minimum req
  IsPasswordValid: boolean;
  IsConfirmed: boolean;
  IsEditPasscode: boolean;
  hidePassword: boolean;
  hideConfirmPassword: boolean;

  //FormGroup
  FormGrp: UntypedFormGroup;

  //Input
  @Input() dataInput;
  @Input() popupConfigs: popupConfig;

  //String
  ruleNameLabel: string;

  //Subscription
  confirmPassSubscribe:Subscription;
  newPassSubscribe:Subscription;
  floatLabel:string;

  constructor(public dialogRef: MatDialogRef<PasswordManagement>, public dialog: MatDialog, private Form: UntypedFormBuilder,
    public localization: SpaLocalization, private vsBusiness: ViewSettingClientBusiness) {
      this.floatLabel = this.localization.setFloatLabel;
    }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      oldPassword: ['', Validators.required],
      newPassword: '',
      confirmPassword: ''
    });
    this.captions = this.localization.captions;
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.ruleNameLabel = this.dataInput.ruleName;
    if (this.popupConfigs.operation == "new") {
      this.clickbutton = this.captions.utilities.save
    } else {
      this.clickbutton = this.captions.utilities.update
      this.IsEditPasscode = true;
      this.FormGrp.patchValue(this.dataInput[0]);
    }
    this.OnFormValueChanges();
  }

  ngOnDestroy(): void {
    if (this.newPassSubscribe) {
      this.newPassSubscribe.unsubscribe();
      this.confirmPassSubscribe.unsubscribe();
    }
  }

  onNoClick(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  OnFormValueChanges(): any {
    this.newPassSubscribe = this.FormGrp.get('newPassword').valueChanges.subscribe(res => {
      let matchNo = res.match(/[0-9]/);
      let matchCaps = res.match(/[A-Z]/);
      let matchSmall = res.match(/[a-z]/);
      this.IsLengthValid = res.length > 7 ? true : false;
      this.IsHavingNo = matchNo ? true : false;
      this.IsHavingLowerCase = matchSmall ? true : false;
      this.IsHavingUpperCase = matchCaps ? true : false;
      this.IsPasswordValid = (this.IsLengthValid && this.IsHavingNo && this.IsHavingLowerCase && this.IsHavingUpperCase);
    });
    this.confirmPassSubscribe = this.FormGrp.get('confirmPassword').valueChanges.subscribe(res => {
      this.IsConfirmed = (this.FormGrp.controls['newPassword'].value == res && res != '') ? true : false;
    });
  }

  savePassword() {
    if (!this.IsEditPasscode) {
      //save functionality goes here
    } else {
      //update functionality goes here
    }
  }
}
