import * as _ from 'lodash';
import { TransactionType, ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';
import { receivingTaxCalculation } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';


export const receivingTaxAlgorithm: receivingTaxCalculation = (receivingPreValue: any, GridData : Array<any>, sysytemPreferenceService: SysytemPreferenceService, selectedTransction: string, selectedId: number, fromCopy: boolean): any => {
  try {
      try {
        const TaxesWhenReceiving = sysytemPreferenceService.globalSystemPreference.find(x => x.LookupName === 'TaxeswhenReceiving').LookupValue;
         let TotalTaxAmount = 0  
        if ( (fromCopy || !selectedId) &&  selectedTransction === 'receiving' && TaxesWhenReceiving === 1 ) {
                TotalTaxAmount = GridData?.reduce(function (prev, element) {
                    const taxAmountOne = parseFloat( element.TaxAmount1.toString() ) ?  element.TaxAmount1 : 0;
                    const taxAmountTwo = parseFloat( element.TaxAmount2.toString() ) ?  element.TaxAmount2 : 0;
                    const receivngTaxValue = taxAmountOne + taxAmountTwo;
                    return prev + receivngTaxValue;
                }, 0);
           };
          return TotalTaxAmount + receivingPreValue;
        } catch (error) {
        console.error('Error occurred in Estimation Total Calculation ', error);
      }
  } catch (error) {
    console.log('Error occured Conversion Algorithm ', error);
  }
};
