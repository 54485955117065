import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentsLinkingConfigAPI, ComponentsLinkingConfigUI } from '../../Models/components-linking-config.model';
import { PropertyInformation } from '../../shared/services/property-information.service';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { ConfigValues } from '../master-component-linking/master-component-linking.model';
import { ComponentLinkingBusiness } from './component-linking-config.business';



@Component({
  selector: 'app-component-linking-config',
  templateUrl: './component-linking-config.component.html',
  styleUrls: ['./component-linking-config.component.scss'],
  providers: [ComponentLinkingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ComponentLinkingConfigComponent implements OnInit {
  isViewOnly: boolean = true;;
  captions: any;
  tableContent: Promise<ComponentsLinkingConfigUI[]>;
  constructor(
    public Commonutils: CommonUtilities,
    public business: ComponentLinkingBusiness,
    public propertyInformation: PropertyInformation) { }

  ngOnInit(): void {
    this.validateBreakPoint();
    this.initialize();
  }

  async initialize() {
    this.tableContent =  this.business.getTableValues();    
  }

  validateBreakPoint(): void {
    this.isViewOnly = false;
  }

}
