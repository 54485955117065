import { 
    Component,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';

export interface AdComponent {
    form: any;
    field: any;
  }

@Component({
    selector: 'lib-custom-row',
    templateUrl: './custom-row.component.html',
    styleUrls: ['./custom-row.component.scss'],
})

export class CustomRowComponent implements OnInit, AfterViewInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Input() components: any;
    fieldType: string;
    rowHeader: string;

    @ViewChild('customComponent', { read: ViewContainerRef, static: false }) customComponent: ViewContainerRef;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        public formBuilder: FormBuilder,
        public AgyDynamicFormService: AgyDynamicFormServices,
        ) {}

        ngAfterViewInit(): void {
            this.field.fieldType[this.fieldType].data.forEach(element => {
                const adItem = this.AgyDynamicFormService.getComponentIndex(element, this.components);
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
                const componentRef = this.customComponent.createComponent(componentFactory);
                (componentRef.instance as AdComponent).form = this.form;
                (componentRef.instance as AdComponent).field = this.objectRestructure(element, 'SingleData');
            });
        }

        objectRestructure(element: any, elementName: string): any {
            const groupingSinglevalue = {};
            groupingSinglevalue[elementName] = element;
            const reStructureObject = {
                type: element?.type,
                fieldType: groupingSinglevalue,
            };
            return reStructureObject;
           }

        ngOnInit(): void {
            this.fieldType = Object.keys(this.field.fieldType).toString();
            this.rowHeader = this.field.fieldType[this.fieldType].rowHeader;
        }
}
