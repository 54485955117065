import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { DefaultIniDateFormat } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import moment from 'moment';
import { Formatter } from '../formater.interface';

export const DateTimeFormater: Formatter = (gridData: any): string => {
    try {
        const IniDateFormat = StorageService.GetSessionStorage('IniDateFormat');
        let dateFormat = IniDateFormat ? IniDateFormat : DefaultIniDateFormat;
        const dateTimeFormat = dateFormat += ' hh:mm A'; 
        const dateTime = moment(gridData.value).format(dateTimeFormat)
        return dateTime;
    } catch (error) {
        console.error(error);
    }
};