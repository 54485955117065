import { Injectable } from '@angular/core';
import { Host } from '../globalsContant';
import { RetailFunctionality } from '../shared.modal';
import { HttpMethod, HttpServiceCall } from './http-call.service';

@Injectable({ providedIn: 'root' })
export class RetailFunctionalityService {

    constructor(private http: HttpServiceCall) {

    }

    public async getRetailFunctionality(productId: number) {
        try {
            const routeUrl = 'RetailFunctionalitiesByProduct';
            return await this.http.CallApiAsync<RetailFunctionality[]>({ host: Host.retailManagement, method: HttpMethod.Get, callDesc: routeUrl, uriParams: { productId } })
        } catch (ex) {
        }

        return { result: [] };
    }

    public async getDefaultOutlet() {
        return this.http.CallApiAsync<number>({ host: Host.retailManagement, method: HttpMethod.Get, callDesc: 'GetDefaultOutlet' });
    }


}
