import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { Localization } from '../localization/localization';
@Directive({
    selector: '[numFormat]'
})
export class numFormat {
    public numberValue;
    @Input() preDecimalLength = 4;
    constructor(el: ElementRef, private utilities: CommonUtilities, private localization: Localization) {

        this.numberValue = el.nativeElement;
    }

    @HostListener('keydown', ['$event']) keydown($event: KeyboardEvent) {
        let arrayKeys: any = [9, 27, 13, 17, 86]
        let server = arrayKeys.find(x => x == $event.keyCode);
        if (server ||
            (($event.keyCode == 65 || $event.keyCode == 86 || $event.keyCode == 67) && ($event.ctrlKey === true || $event.metaKey === true))) {
            $event.preventDefault();
        }

        if (this.doNotAllowKey(this.numberValue.value) && (($event.keyCode >= 48 && $event.keyCode <= 57)||($event.keyCode >= 96 && $event.keyCode <= 105))) {
            $event.preventDefault();
        }
    }

    doNotAllowKey(value: any): boolean {
        let donotAllow = false;
        value = value.toString().replace("-", "");
        if (value.indexOf(this.localization.decimalSeparator) == -1) {
            donotAllow = value.length == this.preDecimalLength;
        }
        else {
            let splitArray = value.split(this.localization.decimalSeparator);
            donotAllow = splitArray[0].length == this.preDecimalLength && splitArray[1].length == 2;
        }
        return donotAllow;
    }
}