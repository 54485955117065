import { GridColumnType } from '../grid.interface';
import * as constant from '../../constant/GlobalConstants';
import { GridAppConfig } from '../app-grid.interface';

export const GridType: GridColumnType = (HeaderName: string, GridActionConfig: GridAppConfig): string => {
    if (HeaderName) {
        if (constant.DateFormatters.findIndex(x => x.toLowerCase() === HeaderName.toLowerCase()) > -1) {
            return constant.ColumnFieldType.DATECOLUMN;
        } else if (constant.NumberFormatters.findIndex(x => x.toLowerCase() === HeaderName.toLowerCase()) > -1) {
            return constant.ColumnFieldType.NUMERIC;
        } else if (constant.BooleanFormatters.findIndex(x => x.toLowerCase() === HeaderName.toLowerCase()) > -1) {
            return constant.ColumnFieldType.BOOLEAN;
        } else {
            return constant.ColumnFieldType.NONEDITABLE;
        }
    } else {
        return '';
    }
};