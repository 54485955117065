import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFilterQuery, TransactionConfiguration } from '../transaction.interface';
import { CustomSearchMultiAutocompleteConfiguration } from './custom-form-configuration.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { FormInterface } from 'src/app/eatecui/source/setup-master/shared/interface/form-interface';
import { FilterTypes } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import moment from 'moment';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FieldEdmType } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import { QueryListInterface } from 'src/app/eatecui/source/shared/models/query-list';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
export const TemplateMultiAutoCompleteSearch: CustomSearchMultiAutocompleteConfiguration = (
  ViewModelEntity: any, PostModelEntity: any,
  MasterDataCollection: any,
  httpService: HttpService,
  FieldGrouping: FormBuilderGroupKey,
  FormGroupData: FormGroup, FormField: any,
  CustomData?: any, showMoreEvent?: any
): any => {
  try {
    let customerFilter = '';
    let infiniteSkipValue;
    let selectedIdIndex;
    let autoCompleteHeaderData;
    const selectedIds = FormGroupData.controls[FieldGrouping.OdataKey].value;
    let autoCompleteData: Array<any> = FormField.filter((x: FormType) => x.type === 'multipleautocomplete');
    autoCompleteData = getAutoCompleteData(MasterDataCollection, autoCompleteData, FieldGrouping);
    autoCompleteHeaderData = autoCompleteData.length > 0 ? autoCompleteData[0].autoCompleteHeader :
    FieldGrouping.autoCompleteHeader;
    FieldGrouping = getFieldGrouping(MasterDataCollection, autoCompleteData, FieldGrouping);
    FormField = getFormFields(MasterDataCollection, autoCompleteData, FieldGrouping);
      if (FieldGrouping.CustomFormAction.EnableCustomMethod &&
          FieldGrouping.hasOwnProperty('autoCompleteHeader') &&
          FieldGrouping.autoCompleteHeader.length > 0 && (typeof CustomData === 'string' || CustomData === null)) {
          if (FieldGrouping.hasOwnProperty('autoCompleteHeader') && FieldGrouping.autoCompleteHeader.length > 0) {
              if (showMoreEvent === true) {
                  infiniteSkipValue += constants.InfiniteScrollItemCount;
              }
              const autoFormFields: any = autoCompleteData.length > 0 ?
                  autoCompleteData[0] : autoCompleteData;
              let searchKey = '';
              let skipValues: any;
              if (autoCompleteHeaderData[0].hasOwnProperty('autocompleteChipHeader')) {
                  searchKey = autoCompleteHeaderData[0].autocompleteChipHeader[0].AutoCompleteChipKey;
              } else {
                  if (autoCompleteData[0] && autoCompleteData[0].hasOwnProperty('autocompleteChipHeader')) {
                      autoCompleteData[0].autocompleteChipHeader.forEach((x: any) => {
                          if (x.Hide === false) {
                              searchKey = x.AutoCompleteChipKey;
                          }
                      });
                  } else {
                      if (autoCompleteData.hasOwnProperty('autocompleteChipHeader')) {
                          autoCompleteData['autocompleteChipHeader'].forEach((x: any) => {
                              if (x.Hide === false) {
                                  searchKey = x.AutoCompleteChipKey;
                              }
                          });
                      }
                  }
              }
              if (autoFormFields.length > 0) {
                  skipValues = autoFormFields[0].fieldType.SingleData.autoCompleteData &&
                      autoFormFields[0].fieldType.SingleData.autoCompleteData.length > 0
                      ? autoFormFields[0].fieldType.SingleData.autoCompleteData.length : 0;
              } else {
                  skipValues = autoFormFields.fieldType.SingleData.autoCompleteData &&
                      autoFormFields.fieldType.SingleData.autoCompleteData.length > 0
                      ? autoFormFields.fieldType.SingleData.autoCompleteData.length : 0;
              }                  
              let authPayload = {} as any;
              authPayload.ClientUrl = null;
              const locProp = FormGroupData?.value?.FromLocId ? 'FromLocId' : FormGroupData?.value?.RequestLocId ?
              'RequestLocId' : 'LocationId';
              if (FormGroupData && FormGroupData.value && FormGroupData.controls[locProp] &&
                FormGroupData.controls[locProp].value) {
                authPayload.ClientUrl = FieldGrouping.CustomOdataUrl;  
                const customerPostPayload = {} as any;
                customerPostPayload.transTypeId = MasterDataCollection.TransactionType;
                if (Array.isArray(FormGroupData.controls[locProp].value)) {
                  customerPostPayload.LocationIds = FormGroupData.controls[locProp].value;
                } else {
                  customerPostPayload.locationId = MasterDataCollection.Name === 'Transfer' ? 
                  FormGroupData.value.ToLocationId : FormGroupData.controls[locProp].value;
                }                
                customerPostPayload.searchText = CustomData;
                customerPostPayload.skip = skipValues;
                customerPostPayload.take = 1000;
                customerPostPayload.getTotalRecCount = true; 
                customerPostPayload.fetchAll = false;
                customerPostPayload.totalCount = 0;
                customerPostPayload.lookupField = '';
                customerPostPayload.orderByColumn = 'Name';
                customerPostPayload.orderByDirection = 1;
                authPayload.Payload = customerPostPayload;
              }              
              if (authPayload.ClientUrl && authPayload.ClientUrl !== null) {
                  httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).subscribe(res => {
                      const responseData = res['ResultData'];
                      const ResponseDataArray = [];
                      if (autoCompleteData && autoCompleteData.length > 0) {
                        autoFormFields.fieldType.SingleData.disbaledProperty = false;
                          autoFormFields.autocompleteChipHeader = FieldGrouping.autocompleteChipHeader;
                          autoFormFields.autoCompleteConfig = FieldGrouping.autoCompleteConfig;
                          if (FieldGrouping.OdataKey === 'CustomerId' && responseData && responseData.length > 0) {
                              responseData.map((x: any) => {
                                  if (selectedIds && selectedIds.length > 0) {
                                      selectedIdIndex = selectedIds.findIndex(selectedId => selectedId === x['Id']);
                                  }
                                  const responseMap = {} as any;
                                  responseMap['Id'] = x['Id'];
                                  responseMap['ischecked'] = selectedIdIndex > -1;
                                  responseMap[FieldGrouping.autocompleteChipHeader[0].AutoCompleteChipKey] =
                                      x[FieldGrouping.autocompleteChipHeader[0].AutoCompleteChipKey];
                                  ResponseDataArray.push(responseMap);
                              });
                          }
                          if (autoFormFields.length > 0) {
                              autoFormFields[0].fieldType.SingleData.autoCompleteData =
                                  [...autoFormFields[0].fieldType.SingleData.autoCompleteData, ...ResponseDataArray];
                              autoFormFields[0].autoCompleteConfig.EnableLoadMore =
                                  (res['TotalCount'] === autoFormFields[0].fieldType.SingleData.autoCompleteData.length)
                                      ? false : true;
                          } else {
                              // autoFormFields.fieldType.SingleData.autoCompleteData =
                              //     [...autoFormFields.fieldType.SingleData.autoCompleteData, ...ResponseDataArray];
                            if (ResponseDataArray.length > 0 && autoFormFields.fieldType.SingleData.autoCompleteData &&
                              autoFormFields.fieldType.SingleData.autoCompleteData.length > 0) {
                              ResponseDataArray.forEach(resData => {
                                  const alreadyExistIndex = autoFormFields.fieldType.SingleData.autoCompleteData.findIndex(fi => fi.Id === resData.Id);
                                  if (alreadyExistIndex === -1) {
                                    autoFormFields.fieldType.SingleData.autoCompleteData.push(resData);
                                  }
                              });
                            } else {
                              autoFormFields.fieldType.SingleData.autoCompleteData = ResponseDataArray;
                            }
                              autoFormFields.autoCompleteConfig.EnableLoadMore =
                                  (res['TotalCount'] === autoFormFields.fieldType.SingleData.autoCompleteData.length)
                                      ? false : true;
                          }
                      }
                  });
              }
          }
      }
  } catch (error) {
    console.log('error occured CustomTypeChange', error);
  }
};

function getFilterQuery(CustomFilterQuerys: Array<CustomFilterQuery>) {
  if (CustomFilterQuerys.length > 0) {
      const queryList = [];
      const LoginSiteId = JSON.parse(sessionStorage.getItem('LoggedInSiteId'));
      CustomFilterQuerys.forEach((querys: CustomFilterQuery) => {
          let querystring = '';
          switch (querys.Type) {
              case FilterTypes.Multiple: {
                  let value = '';
                  if (querys.CustomOdataUrlName) {
                      value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                      if (value && querys.Name) {
                          querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                      } else if (value) {
                          querystring = querys.OdataKey + ' in (' + value + ')';
                      }
                      if ( LoginSiteId === 1 && querys.hasOwnProperty('CheckInterco') && querys.CheckInterco ) {
                          querystring = `((${querystring}`;
                      }
                  } else {
                      if (querys.Name === querys.OdataKey) {
                          value = querys.DefaultValue.join();
                          querystring = querys.OdataKey + ' in (' + value + ')';
                      } else {
                          value = querys.DefaultValue.join();
                          querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                          if ( LoginSiteId === 1 && querys.hasOwnProperty('CheckInterco') && querys.CheckInterco ) {
                              querystring = `${querystring}) or LocationTypes/Id in (8))`;
                          }
                      }
                  }
                  break;
              }
              case FilterTypes.GreaterThanEqual: {
                  if (querys.FieldType === FieldEdmType.EDMDATEOFFSET) {
                      // const date = new Date();
                      //   Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
                      //   date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                      // const utcdate = moment.utc().format();
                      // querystring = querys.OdataKey + ' ge ' + utcdate;
                      querystring = querys.OdataKey + ' ge ' + moment().startOf('day').format(constants.PostDateTimeFormat);
                  } else {
                      querystring = querys.OdataKey + ' ge ' + querys.DefaultValue;
                  }
                  break;
              }
              case FilterTypes.Equal: {
                  if (querys.CustomOdataUrlName) {
                      const value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                      if (value) {
                          querystring = querys.OdataKey + ' eq ' + value;
                      }
                  } else if (querys.ExpandName) {
                      querystring = querys.ExpandName + '/' + querys.OdataKey + ' eq ' + querys.DefaultValue ;
                  } else {
                      querystring = querys.OdataKey + ' eq ' + querys.DefaultValue;
                  }
                  break;
              }
              case FilterTypes.NotEqual: {
                  // querystring = 'not(' + query.OdataKey + ' eq ' + query.DefaultValue + ')';
                  querystring = querys.OdataKey + ' ne ' + querys.DefaultValue;
                  break;
              }
          }
          if (querystring) {
              queryList.push(querystring);
          }
      });
      const returnData = queryList.join(' and ');
      return returnData;
  }
}
function getAutoCompleteData(MasterDataCollection: any, autoCompleteData: any, FieldGrouping: any) {
  let returnValue: any;
  if (autoCompleteData.length === 0) {
    if (MasterDataCollection.hasOwnProperty('OdataKey')) {
      switch (MasterDataCollection.OdataKey) {
        case 'Events':
          returnValue = MasterDataCollection.CreateTranscationConfig['EventFormFields'].
          filter((x: FormType) => x.type === 'multipleautocomplete');
          break;
        case 'InvoiceHead':
          returnValue = MasterDataCollection.CreateExpenseConfig.TransactionFormConfig.FormFields.
          filter((x: FormType) => x.type === 'multipleautocomplete');
          break;
        case 'PaymentHead':
          returnValue = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields.
          filter((x: FormType) => x.type === 'multipleautocomplete');
          break;
        default:
          returnValue = autoCompleteData;
          break;
      }
    } else if (MasterDataCollection.hasOwnProperty('Key')) {
      switch (MasterDataCollection.Key) {
        case 'Ingredients':
          const nestedFormValue = MasterDataCollection.NestedFormConfig.EditFormConfig.
          filter((x: any) => x.OdataKey === 'Inventory Details');
          const nestedForm = nestedFormValue[0].NestedForm.filter((x: any) => x.FormName === 'Other Fields');
          const autoComplete = nestedForm[0].FormFields.filter((x: FormType) => x.type === 'multipleautocomplete');
          autoComplete.forEach((x: any) => {
            if (x.fieldType.SingleData.name === FieldGrouping.OdataKey) {
              autoCompleteData.push(x);
            }
          });
          returnValue = autoCompleteData;
          break;
        default:
          break;
      }
    }
  } else {
    returnValue = autoCompleteData;
  }
  return returnValue;
}
function getFormFields(MasterDataCollection: any, autoCompleteData: any, FieldGrouping: any) {
  let returnValue: any;
  switch (MasterDataCollection.OdataKey) {
    case 'Events':
      returnValue = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields;
      break;
    case 'Ingredients':
      const nestedFormValue = MasterDataCollection.NestedFormConfig.EditFormConfig.
      filter((x: any) => x.OdataKey === 'Inventory Details');
      const nestedForm = nestedFormValue[0].NestedForm.filter((x: any) => x.FormName === 'Other Fields');
      returnValue = nestedForm[0].FormFields;
      break;
    case 'InvoiceHead':
      returnValue = MasterDataCollection.CreateExpenseConfig.TransactionFormConfig.FormFields;
      break;
    case 'PaymentHead':
      returnValue = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields;
      break;
    default:
      returnValue = '';
      break;
  }
}
function getFieldGrouping(MasterDataCollection: any, autoCompleteData: any, FieldGrouping: any) {
  switch (MasterDataCollection.OdataKey) {
    case 'Events':
      FieldGrouping.autocompleteChipHeader = FieldGrouping.autoCompleteHeader[0]['autocompleteChipHeader'];
      FieldGrouping.autoCompleteConfig = FieldGrouping.autoCompleteHeader[0]['autoCompleteConfig'];
      break;
    case 'Ingredients':
      FieldGrouping.autocompleteChipHeader = autoCompleteData[0]['autocompleteChipHeader'];
      FieldGrouping.autoCompleteConfig = autoCompleteData[0]['autoCompleteConfig'];
      break;
    case 'InvoiceHead':
    case 'PaymentHead':
      FieldGrouping.autocompleteChipHeader = FieldGrouping['autocompleteChipHeader'];
      FieldGrouping.autoCompleteConfig = FieldGrouping['autoCompleteConfig'];
      break;
    default:
      break;
  }
  return FieldGrouping;
}