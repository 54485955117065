import {FlatTreeControl} from '@angular/cdk/tree';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ExpandableGrid, ExpandReturnValue, GridFlatNode } from 'src/app/eatecui/source/shared/models/expandable-grid-interface';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

@Component({
  selector: 'app-expandable-grid',
  templateUrl: './expandable-grid.component.html',
  styleUrls: ['./expandable-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpandableGridComponent implements OnInit, OnChanges {
  @Input() ExpandableGrid: ExpandableGrid;
  treeControl = new FlatTreeControl<GridFlatNode>(node => node.level, node => node.Expandable);
  dataSource: any;
  levelArray: Array<any> = [];
  maxexpandLevel: number;
  nodeLevel = 200;
  isExpanded = false;
  icontype = '';
  visibleCurrencyValue: number;
  visibleDecimalValue: number;
  constructor(private routeNavigateService: RouteNavigateService,
    private commonService: CommonService) {
    const treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.Expandable, node => node.Children);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, treeFlattener);
        if ( this.levelArray && this.levelArray.length > 0 ) {
          this.maxexpandLevel = Math.max.apply(Math, this.levelArray);
        }    
        this.visibleCurrencyValue = CommonService.GetCurrencyVisible();
        this.visibleDecimalValue = this.commonService.GetVisibleNumberLength();
  }
  _transformer = (node: any, level: number) => {
    this.levelArray.push(level);
    return this.consatactTransformerObject(node, level);
  }
  consatactTransformerObject(node: any, level: number): any {
    try {
      const GridHeader: Array<any> = this.ExpandableGrid.GridHeader;
      const transFormObject = new Object();
      transFormObject['Expandable'] = !!node.Children && node.Children.length > 0;
      transFormObject['level'] = level;
      transFormObject['Id'] = node.hasOwnProperty('ItemId') ? node['ItemId'] : 1;
      transFormObject['DivisionId'] = node.hasOwnProperty('DivisionId') ? node['DivisionId'] : 1;
      transFormObject['ItemType'] = node.hasOwnProperty('LinkDB') ? node['LinkDB'] : 1;
      transFormObject['IsFGItem'] = node.hasOwnProperty('IsFGItem') ? node['IsFGItem'] : false;
      transFormObject['IsButcheryItem'] = node.hasOwnProperty('IsButcheryItem') ? node['IsButcheryItem'] : false;
      if ( GridHeader && GridHeader.length > 0 ) {
        GridHeader.forEach((GridTransHeader: any) => {
          const ObjectName = GridTransHeader['ExpandColumnName'];
          transFormObject[ObjectName] = node[ObjectName];
        });
      }
      return transFormObject;
    } catch (error) {
      console.error(error);
    }
  }
  hasChild = (_: number, node: GridFlatNode) => node.Expandable;
  /**
   * @method expandableAction()
   * @input params Row Node
   * @outPut None
   * @description Trigger to the Parent Compoenent
   */
   expandableAction(Node ?: any): void {
     try {
        const expandValue = {} as ExpandReturnValue;
        expandValue.Id = 1;
        expandValue.ActionType = 'row';
        expandValue.rowValue = Node;
        this.nodeLevel = 200;
        this.ExpandableGrid.GridExpandable.ExpendableClick(expandValue);
     } catch ( error ) {
       console.error(error);
     }
   }
  expandAll(isExpanded): void {
    if (!isExpanded) {
      this.treeControl.expandAll();
      this.isExpanded = true;
    } else {
      this.treeControl.collapseAll();
      this.isExpanded = false;
    }

  }
  subFormClick(data: any, node: any): void {
    this.ExpandableGrid.GridExpandable.SubMenuClick(data, node);
    console.log(data, node);
  }
  ngOnChanges(): void {
    // if (this.ExpandableGrid.GridData && this.ExpandableGrid.GridData.length > 0) {
    //   this.ExpandableGrid.GridData.forEach(function iter(e) {
    //     e.QuantityOnReq = Math.round(e.QuantityOnReq);
    //     e.QuantityOnHand = Math.round(e.QuantityOnHand);
    //     e.QuantityOnOrder = Math.round(e.QuantityOnOrder);
    //     e.UnitCost = Math.round(e.UnitCost);
    //     e.Valued = Math.round(e.Valued);
    //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //     e.Children && e.Children.forEach(iter);
    // });
    // }
    
    this.dataSource.data = this.ExpandableGrid.GridData;
    if ( this.levelArray && this.levelArray.length > 0 ) {
      const maxLevel = Math.max.apply(Math, this.levelArray);
       this.maxexpandLevel = ( maxLevel * 40 ) +  this.nodeLevel;
    }
  }
  ngOnInit(): void {
      console.log('log');
  }
  iconName(node: any) {
    if (node.ItemType === 1 && node.DivisionId === 1) {
      return 'icon-Food-and-beverage-eatec';
    } else if (node.ItemType === 1 && node.DivisionId === 2) {
      return 'icon-Retail-eatec';
    } else if (node.ItemType === 2) {
      return 'icon-Recipe-eatec';
    } else if (node.ItemType === 5) {
      return 'icon-Product-eatec';
    } else if (node === 'Dog') {
      return 'icon-Dog-eatec';
    } else if (node === 'Star') {
      return 'icon-Star-eatec';
    } else if (node === 'Plowhorse') {
      return 'icon-Plowhorse-eatec';
    } else if (node === 'Puzzle') {
      return 'icon-Puzzle-eatec';
    }
  }
}
