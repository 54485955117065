import { Component } from '@angular/core';
import { menuTypes } from 'src/app/shared/shared.modal';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
@Component({
  selector: 'app-spa-utilities',
  templateUrl: './spa-utilities.component.html',
  styleUrls: ['./spa-utilities.component.scss']
})
export class SpaUtilitiesComponent {

  menuList: any;
  menuType = menuTypes;
  codeRoute: any;
  EnableRetailIC: boolean;
  constructor(private routeDataService: RouteLoaderService, private propertyInfo: SpaPropertyInformation ) {   
    this.codeRoute = this.routeDataService.GetChildMenu('/settings/utilities', 3);  
    let propConfig = sessionStorage.getItem('propConfig') ? JSON.parse(sessionStorage.getItem('propConfig')) : null;
    this.EnableRetailIC = propConfig?.EnableRetailIC?.toLowerCase() == 'true'? true: false;
    this.codeRoute.linkedElement.map((res) => {
      if (res) {
        if (res.routePath === '/settings/utilities/inventorysync') {
          res.visibility = false;
          if (this.propertyInfo.IsEatecEnabled || this.EnableRetailIC) {
            res.visibility = true;
          }
        }
      }
    });
    this.menuList = {
      menu: this.codeRoute.linkedElement,
      menuType : menuTypes.tertiary
    };
  }

}
