import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ApprovalSubmissionPopUpModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-approval-submission',
  templateUrl: './agilysys-approval-submission.component.html',
  styleUrls: ['./agilysys-approval-submission.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysApprovalSubmissionComponent implements OnInit {
  agilysysAddNewButton: AgilysysButtonModel[];
  remarks: string;
  constructor(
    public dialogRef: MatDialogRef<AgilysysApprovalSubmissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ApprovalSubmissionPopUpModel,
  ) { }

  ngOnInit(): void {
    this.agilysysAddNewButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, this.remarks]);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, this.remarks]);
        }
      }
  ];
  }

}
