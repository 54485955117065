
export interface UserBreakPoint {
    userRoleId: number;
    breakPointNumber: number;
    allow: boolean;
    view: boolean;
}

export enum BreakPoint {
    ShopScreen = 3000,

    // user module
    UserSetup = 2300,
    // Retail Reports
    ShiftReport = 19741,
    TransactionReport= 19746

}

export const enum ReportBreakPoint {
}

export const CipherKey = 'AGYS SNC Key';
