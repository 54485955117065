import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { SettingsService } from '../../settings.service';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import * as GlobalConst from '../../../shared/globalsContant';
import * as _ from 'lodash';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';
import { UserOutletAccessDataService } from '../useroutletaccess.data.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CryptoUtility } from 'src/app/core/utilities/crypto.utility';
import { UTempDataUtilities } from 'src/app/common/shared/shared/utilities/utempdata-utilities';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
  providers: [UserOutletAccessDataService]
})
export class NewUserComponent implements OnInit, OnDestroy {
  @Input() popupConfigs: popupConfig;
  /* Selected tab change event */
  caption: any;
  existingUserId: any[];
  existingQuickId: any[];
  IsReadOnly: boolean;
  IsSpaRoleSelected: boolean = true;
  IsRetailRoleSelected: boolean = true;
  selectedTabIndex: number = 0;
  ActionButton: string;
  subPropertyAccess: any = [];
  IsDiableActionButton: boolean;
  throttleTime = 1000;
  uTempDataPrimary: string;
  uTempDataSecondary: string;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  filteredActiveTherapist: any[];
  filteredUserTherapist: any[];
  @Input() isADB2CConfigEnabled: boolean;

  constructor(public localization: SpaLocalization, public _servicesetting: SettingsService,
    private dialogRef: MatDialogRef<SettingDialogPopupComponent>, private http: HttpServiceCall,
    private utils: SpaUtilities, private PropertyInfo: SpaPropertyInformation,
    private _userOutletAccessDataService: UserOutletAccessDataService, private crypto: CryptoUtility,private utempdatautils: UTempDataUtilities) {

  }

  ngOnInit() {
    this.caption = this.localization.captions;
    this.ActionButton = this.caption.setting.save;
    this.IsReadOnly = this._servicesetting.breakpoints.find(bp => bp.breakPointNumber == GlobalConst.SPAScheduleBreakPoint.UserSetup).view;
    if (this.popupConfigs && this.popupConfigs.operation && this.popupConfigs.operation == 'Edit') {
      this.ActionButton = this.caption.setting.update;
      this._servicesetting.selectedAccess = this._servicesetting.editUserInfo.clientInfo.userPropertyAccesses.filter(x => x.hasAccess).map(y => y.productId);
      var serviceGroup = this._servicesetting.editUserInfo.spaInfo.filter(t=> !t.isClassGroup);
      var classGroup = this._servicesetting.editUserInfo.spaInfo.filter(t=> t.isClassGroup);

      this._servicesetting.selectedServiceGrp = serviceGroup.map(x => {
       
          let servGroup = this._servicesetting.serviceGroups.filter(y => y.id == x.serviceGroupId );
          return {
            id: x.serviceGroupId,
            name: servGroup && servGroup.length > 0 ? servGroup[0].description : ''
          }
       
      });

      this._servicesetting.selectedClassGrp = classGroup.map(x => {
       
        let servGroup = this._servicesetting.classGroups.filter(y => y.id == x.serviceGroupId );
        return {
          id: x.serviceGroupId,
          name: servGroup && servGroup.length > 0 ? servGroup[0].description : ''
        }
     
    });

      let selectedCurrSG = this._servicesetting.selectedServiceGrp.filter(x => this._servicesetting.serviceGroups.map(y => y.id).includes(x.id));

      if (selectedCurrSG.length >= this._servicesetting.serviceGroups.length) {
        this._servicesetting.selectedServiceGrp.push({ id: 0, name: 'ALL' })
      }

      let selectedCurrCG = this._servicesetting.selectedClassGrp.filter(x => this._servicesetting.classGroups.map(y => y.id).includes(x.id));

      if (selectedCurrCG.length >= this._servicesetting.classGroups.length) {
        this._servicesetting.selectedClassGrp.push({ id: 0, name: 'ALL' })
      }

      this._servicesetting.selectedOutlets = this._servicesetting.editUserInfo.retailOutletMap ? this._servicesetting.editUserInfo.retailOutletMap.filter(y => y.hasAccess && this._servicesetting.propOutlets && this._servicesetting.propOutlets.map(o => o.subPropertyID).includes(y.subPropertyID)).map(x => { return { id: x.subPropertyID, name: this._servicesetting.propOutlets.filter(y => y.subPropertyId == x.subPropertyID)[0] ? this._servicesetting.propOutlets.filter(y => y.subPropertyId == x.subPropertyID)[0].subPropertyName : '' } }) : [];
      let selectedCurrOuts = this._servicesetting.selectedOutlets.filter(x => this._servicesetting.propOutlets.map(y => y.subPropertyID).includes(x.id));
      if (selectedCurrOuts.length >= this._servicesetting.propOutlets.length) {
        this._servicesetting.selectedOutlets.push({ id: 0, name: 'ALL' })
      }
      if (this._servicesetting.spaSettingsFormGrp.controls.istherapistscheduleview.value) {
        this.filteredUserTherapist = this._servicesetting.allUserTherapist.filter(x => x.therapistId != this._servicesetting.editUserInfo.userTherapistInfo.therapistId);
        this.filteredActiveTherapist = this._servicesetting.allTherapist.filter(x => !this.filteredUserTherapist.map(y => y.therapistId).includes(x.id));
      }
      else{
        this.filteredActiveTherapist = this._servicesetting.allTherapist.filter(x => !this._servicesetting.allUserTherapist.map(y => y.therapistId).includes(x.id));
      }
      this._servicesetting.isRadioButtonsChange = false;
    }
    else {
      this.ActionButton = this.caption.setting.save;
      this._servicesetting.userSettingsFormGrp.controls.activeuser.setValue(true);
      this._servicesetting.userSettingsFormGrp.controls.newpassword.setValue(true);
      this.GetServiceCall('GetAllUserTherapist', []);
    }

    this._servicesetting.selectedOutlets = this._servicesetting.selectedOutlets.map(x => x.id);
    this._servicesetting.selectedServiceGrp = this._servicesetting.selectedServiceGrp.map(x => x.id);
    this._servicesetting.selectedClassGrp = this._servicesetting.selectedClassGrp.map(x => x.id);

    this.GetAllUserbyTenantId();
    this._servicesetting.userSettingsFormGrp.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(res => {
      if (this._servicesetting.userSettingsFormGrp.dirty || this._servicesetting.spaSettingsFormGrp.dirty || this._servicesetting.retailSettingsFormGrp.dirty || this._servicesetting.isRadioButtonsChange) {
        this.IsDiableActionButton = false;
      }
    });
    this.uTempDataPrimary = this.utempdatautils.GetUTempData(3);
    this.uTempDataSecondary = this.utempdatautils.GetUTempData(1);
  }
  //check userId already exist by tenantId
  async IsUserIdDuplicate()
  {
    const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync('GetDuplicateUserByName', GlobalConst.Host.authentication, HttpMethod.Get, {userId:0, tenantId: Number(this.utils.GetPropertyInfo('TenantId')),userName: this._servicesetting.userSettingsFormGrp.controls.userid.value.toUpperCase() });
    return apiResponse.result;
  }

  async GetAllUserbyTenantId() {
    let apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync('GetAllUsers', GlobalConst.Host.authentication, HttpMethod.Get, { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) });
    if (apiResponse && apiResponse.result) {
      this._servicesetting.existingUserIds = apiResponse.result.map(sc => sc.userName);
      this._servicesetting.existingQuickIds = apiResponse.result.filter(x => x.quickId).map(sc => sc.quickId);

      if (this.popupConfigs && this.popupConfigs.operation && this.popupConfigs.operation == 'Edit') {
        //clone existing user ids
        this.existingUserId = _.cloneDeep(this._servicesetting.existingUserIds);
        let index = this.existingUserId.indexOf(this._servicesetting.userSettingsFormGrp.controls.userid.value.toUpperCase());
        if (index >= 0) {
          this.existingUserId.splice(index, 1);
        }
        //clone existing quick ids
        this.existingQuickId = _.cloneDeep(this._servicesetting.existingQuickIds);
        let index1 = this.existingQuickId.indexOf(this._servicesetting.userSettingsFormGrp.controls.quickid.value);
        if (index1 >= 0) {
          this.existingQuickId.splice(index1, 1);
        }
      }
    }
  }
  handleSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  async SaveOrUpdate() {
    this.IsDiableActionButton = true;
    let serviceSettingControl = this._servicesetting.userSettingsFormGrp.controls;
    if (this.popupConfigs && this.popupConfigs.operation && this.popupConfigs.operation == 'Edit') {
      if (this.existingUserId.includes(serviceSettingControl.userid.value.toUpperCase())) {
        this.utils.ShowErrorMessage(this.caption.common.Error, this.caption.setting.DuplicateUserID, GlobalConst.ButtonType.Ok);
        this.IsDiableActionButton = false;
      }
      else if (this.existingQuickId.includes(serviceSettingControl.quickid.value)) {
        this.utils.ShowErrorMessage(this.caption.common.Error, this.caption.setting.DuplicateQuickID, GlobalConst.ButtonType.Ok);
        this.IsDiableActionButton = false;
      }
      else if (serviceSettingControl.fname.value.trim() == '') {
        serviceSettingControl.fname.setValue('');
        serviceSettingControl.fname.markAsTouched();
      }
      else if (serviceSettingControl.lname.value.trim() == '') {
        serviceSettingControl.lname.setValue('');
        serviceSettingControl.lname.markAsTouched();
      } else if ((!serviceSettingControl.email.value || serviceSettingControl.email.value.trim() == '') && this.isADB2CConfigEnabled) {
        this.utils.showError(this.caption.setting.MissingEmail);
      }
      else {
        this.Edit();
      }
    }
    else {
      if(await this.IsUserIdDuplicate())
      {
        this.utils.ShowErrorMessage(this.caption.common.Error, this.caption.setting.DuplicateUserID, GlobalConst.ButtonType.Ok);
      }
      else if (this._servicesetting.existingQuickIds.includes(serviceSettingControl.quickid.value)) {
        this.utils.ShowErrorMessage(this.caption.common.Error, this.caption.setting.DuplicateQuickID, GlobalConst.ButtonType.Ok);
      }
      else if (serviceSettingControl.fname.value.trim() == '') {
        serviceSettingControl.fname.setValue('');
        serviceSettingControl.fname.markAsTouched();
      }
      else if (serviceSettingControl.lname.value.trim() == '') {
        serviceSettingControl.lname.setValue('');
        serviceSettingControl.lname.markAsTouched();
      } else if ((!serviceSettingControl.email.value || serviceSettingControl.email.value.trim() == '') && this.isADB2CConfigEnabled) {
        this.utils.showError(this.caption.setting.MissingEmail);
      }
      else {
        this.save();
      }
    }
  }


  save() {
    let serviceRetailControls = this._servicesetting.retailSettingsFormGrp.controls;
    let serviceUserControls = this._servicesetting.userSettingsFormGrp.controls;
    let serviceSettingControls = this._servicesetting.spaSettingsFormGrp.controls;
    let subPropAccess: any = [];
    for (let i = 0; i < this._servicesetting.propOutlets.length; i++) {
      subPropAccess.push({
        hasAccess: this._servicesetting.selectedOutlets.includes(this._servicesetting.propOutlets[i].subPropertyID),
        subPropertyID: this._servicesetting.propOutlets[i].subPropertyID
      })
    }
    this.subPropertyAccess = subPropAccess;
    let proAccess: any = [];
    let spaProdId = this._servicesetting.products.filter(x => x.productName.replace(/ /g, '').toUpperCase() == 'SPA')[0].id;
    let retailProdId = this._servicesetting.products.filter(x => x.productName.replace(/ /g, '').toUpperCase() == 'RETAIL')[0].id;
    if (serviceRetailControls.allowcommission.value && (!serviceRetailControls.commissionclass.value || serviceRetailControls.commissionclass.value == 0)) {
      serviceRetailControls.commissionclass.setValue('');
      serviceRetailControls.commissionclass.markAsTouched();
      this.selectedTabIndex = 2;
      return;
    }

    if (this._servicesetting.selectedAccess.includes(spaProdId)) {
      let roleid = this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? this._servicesetting.spaSettingsFormGrp.controls.rolename.value : Number(0);
      if (roleid == 0) {
        this.IsSpaRoleSelected = false;
        this.selectedTabIndex = 1;
        return;
      }
    }

    proAccess.push({
      propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
      subPropertyID: 0,
      userAuthorityLevel: 0,
      hasAccess: this._servicesetting.selectedAccess.includes(spaProdId),
      productId: spaProdId,
      roleId: serviceSettingControls.rolename.value ? serviceSettingControls.rolename.value : Number(0),
      accountBlocked: serviceSettingControls.accountblocked.value ? serviceSettingControls.accountblocked.value : false,
      autoLogOff: serviceSettingControls.autologoff.value ? serviceSettingControls.autologoff.value : false,
      logOffAfter: serviceSettingControls.autologoff.value ? serviceSettingControls.logoffafter.value?serviceSettingControls.logoffafter.value:Number(0) : Number(0),
      userSubPropertyAccess: []
    });

    proAccess.push({
      propertyID: Number(this.utils.GetPropertyInfo('PropertyId')),
      subPropertyID: 0,
      userAuthorityLevel: 0,
      hasAccess: this._servicesetting.selectedAccess.includes(retailProdId),
      productId: retailProdId,
      roleId: serviceSettingControls.rolename.value ? serviceSettingControls.rolename.value : Number(0),
      accountBlocked: serviceRetailControls.accountblocked.value ? serviceRetailControls.accountblocked.value : false,
      autoLogOff: serviceRetailControls.autologoff.value ? serviceRetailControls.autologoff.value : false,
      logOffAfter: serviceRetailControls.autologoff.value ? serviceRetailControls.logoffafter.value : Number(0),
      userSubPropertyAccess: []
    });

    let userPassword: string = serviceUserControls.nPassword.value;
    if (this.uTempDataPrimary && this.uTempDataSecondary && userPassword.length > 0) {
      userPassword = this.crypto.EncryptString(userPassword, this.uTempDataPrimary, this.uTempDataSecondary);
    }

    let userObj = {
      tenantId: Number(this.utils.GetPropertyInfo('TenantId')),
      userName: serviceUserControls.userid.value.toUpperCase(),
      firstName: this.utils.capitalizeFirstLetter(serviceUserControls.fname.value),
      lastName: this.utils.capitalizeFirstLetter(serviceUserControls.lname.value),
      password: userPassword.length > 0 ? userPassword : null,
      isActive: serviceUserControls.activeuser.value ? serviceUserControls.activeuser.value : false,
      isNewUser: serviceUserControls.newpassword.value ? serviceUserControls.newpassword.value : false,
      passwordexpiredate: serviceUserControls.pwdexpirationdate.value ? this.utils.convertDateFormat(serviceUserControls.pwdexpirationdate.value) : null,
      createdOn: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate),
      quickId: serviceUserControls.quickid.value ? serviceUserControls.quickid.value : '',
      roleId: serviceSettingControls.rolename.value ? serviceSettingControls.rolename.value : Number(0),
      languageId: serviceUserControls.language.value ? serviceUserControls.language.value : Number(0),
      email: serviceUserControls.email.value ? serviceUserControls.email.value : '',
      userPropertyAccesses: proAccess,
      loggedUser: this.utils.GetPropertyInfo('userName')
    }

    let retailData = {
      userId: 0,
      allowGratuity: serviceRetailControls.allowgratuity.value ? serviceRetailControls.allowgratuity.value : false,
      allowServiceCharge: serviceRetailControls.allowservicecharge.value ? serviceRetailControls.allowservicecharge.value : false,
      allowCommission: serviceRetailControls.allowcommission.value ? serviceRetailControls.allowcommission.value : false,
      commissionClass: serviceRetailControls.allowcommission.value ? Number(serviceRetailControls.commissionclass.value) : Number(0)
    }

    let userTherapist = serviceSettingControls.userTherapist?.value ? {
      userId: 0,
      therapistId: serviceSettingControls.usertherapist.value
    } : null;
    let spaData = this._servicesetting.selectedServiceGrp;
    spaData = _.uniq(spaData);
    let index = spaData.indexOf(0);
    if (index >= 0) {
      spaData.splice(index, 1);
    }

    
    let selclassGrp = this._servicesetting.selectedClassGrp;
    selclassGrp = _.uniq(selclassGrp);
    let cindex = selclassGrp.indexOf(0);
    if (cindex >= 0) {
      selclassGrp.splice(cindex, 1);
    }


    this.CreateUser(userObj, GlobalConst.Host.authentication, 'CreateUser', [spaData, retailData, userTherapist,selclassGrp], { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
  }

  Edit() {

    let userPassword: string = "";
    if (this.uTempDataPrimary && this.uTempDataSecondary && this._servicesetting.userSettingsFormGrp.controls.newpassword.value) {
      userPassword = this._servicesetting.userSettingsFormGrp.controls.nPassword.value;
      userPassword = this.crypto.EncryptString(userPassword, this.uTempDataPrimary, this.uTempDataSecondary);
    }

    let editedInfo = _.cloneDeep(this._servicesetting.editUserInfo.clientInfo);
    let retailConf = _.cloneDeep(this._servicesetting.editUserInfo.retainInfo);
    let userTherapistInfo = _.cloneDeep(this._servicesetting.editUserInfo.userTherapistInfo);
    editedInfo.userName = this._servicesetting.userSettingsFormGrp.controls.userid.value.toUpperCase();
    editedInfo.firstName = this.utils.capitalizeFirstLetter(this._servicesetting.userSettingsFormGrp.controls.fname.value);
    editedInfo.lastName = this.utils.capitalizeFirstLetter(this._servicesetting.userSettingsFormGrp.controls.lname.value);
    editedInfo.isActive = this._servicesetting.userSettingsFormGrp.controls.activeuser.value ? this._servicesetting.userSettingsFormGrp.controls.activeuser.value : false;
    editedInfo.isNewUser = this._servicesetting.userSettingsFormGrp.controls.newpassword.value ? this._servicesetting.userSettingsFormGrp.controls.newpassword.value : false;
    editedInfo.passwordexpiredate = this._servicesetting.userSettingsFormGrp.controls.pwdexpirationdate.value ? this.utils.convertDateFormat(this.utils.getDate(this._servicesetting.userSettingsFormGrp.controls.pwdexpirationdate.value)) : null;
    editedInfo.quickId = this._servicesetting.userSettingsFormGrp.controls.quickid.value ? this._servicesetting.userSettingsFormGrp.controls.quickid.value : '';
    editedInfo.languageId = this._servicesetting.userSettingsFormGrp.controls.language.value ? this._servicesetting.userSettingsFormGrp.controls.language.value : Number(0);
    editedInfo.email = this._servicesetting.userSettingsFormGrp.controls.email.value ? this._servicesetting.userSettingsFormGrp.controls.email.value : '';
    editedInfo.loggedUser = this.utils.GetPropertyInfo('userName');
    editedInfo.password = userPassword.length > 0 ? userPassword : null
    editedInfo.roleId = this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.rolename.value) : Number(0);

    let retailProdId = this._servicesetting.products.filter(x => x.productName.replace(/ /g, '').toUpperCase() == 'RETAIL')[0].id;
    let spaProdId = this._servicesetting.products.filter(x => x.productName.replace(/ /g, '').toUpperCase() == 'SPA')[0].id;
    let retailRowIndex = editedInfo.userPropertyAccesses.findIndex(x => x.productId == retailProdId);
    let spaRowIndex = editedInfo.userPropertyAccesses.findIndex(x => x.productId == spaProdId);

    let serviceRetailControls = this._servicesetting.retailSettingsFormGrp.controls;

    if (serviceRetailControls.allowcommission.value &&
      (!serviceRetailControls.commissionclass.value || serviceRetailControls.commissionclass.value == 0)) {
      serviceRetailControls.commissionclass.setValue('');
      serviceRetailControls.commissionclass.markAsTouched();
      this.selectedTabIndex = 2;
      return;
    }

    let selectedOuts = this._servicesetting.selectedOutlets;
    selectedOuts = _.uniq(selectedOuts);
    let oindex = selectedOuts.indexOf(0);
    if (oindex >= 0) {
      selectedOuts.splice(oindex, 1);
    }
    this.subPropertyAccess = [];
    if (this._servicesetting.propOutlets) {
      this._servicesetting.propOutlets.forEach(propOutlet => {
        let subPropAccess = editedInfo.retailOutletMap.find(o => o.subPropertyID === propOutlet.subPropertyID);
        if (subPropAccess) {
          subPropAccess.hasAccess = selectedOuts && selectedOuts.length > 0 ? selectedOuts.includes(subPropAccess.subPropertyID) : false;
        }
        else {
          subPropAccess = {
            hasAccess: selectedOuts.includes(propOutlet.subPropertyID) ? true : false,
            subPropertyID: propOutlet.subPropertyID,
            userID: editedInfo.userId,
            userPropertyAccessID: 0,
            userSubPropertyAccessID: 0,
          }
        }

        this.subPropertyAccess.push(subPropAccess);
      });


    }

    if (spaRowIndex != -1) {
      if (this._servicesetting.selectedAccess.includes(spaProdId)) {
        let roleid = this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.rolename.value) : Number(0);
        if (roleid == 0) {
          this.IsSpaRoleSelected = false;
          this.selectedTabIndex = 1;
          return;
        }
      }
      this.IsSpaRoleSelected = true;
      editedInfo.userPropertyAccesses[spaRowIndex].hasAccess = this._servicesetting.selectedAccess.includes(spaProdId);
      editedInfo.userPropertyAccesses[spaRowIndex].roleId = this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.rolename.value) : Number(0);
      editedInfo.userPropertyAccesses[spaRowIndex].accountBlocked = this._servicesetting.spaSettingsFormGrp.controls.accountblocked.value ? this._servicesetting.spaSettingsFormGrp.controls.accountblocked.value : false;
      editedInfo.userPropertyAccesses[spaRowIndex].autoLogOff = this._servicesetting.spaSettingsFormGrp.controls.autologoff.value ? this._servicesetting.spaSettingsFormGrp.controls.autologoff.value : false;
      editedInfo.userPropertyAccesses[spaRowIndex].logOffAfter = this._servicesetting.spaSettingsFormGrp.controls.autologoff.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.logoffafter.value) : Number(0);
    }
    if (retailRowIndex != -1) {
      if (this._servicesetting.selectedAccess.includes(retailProdId)) {
        let roleid = this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.rolename.value) : Number(0);
        if (roleid == 0) {
          this.IsSpaRoleSelected = false;
          this.selectedTabIndex = 1;
          return;
        }
      }
      this.IsSpaRoleSelected = true;
      editedInfo.userPropertyAccesses[retailRowIndex].hasAccess = this._servicesetting.selectedAccess.includes(spaProdId);
      editedInfo.userPropertyAccesses[retailRowIndex].roleId = this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.rolename.value) : Number(0);
      editedInfo.userPropertyAccesses[retailRowIndex].accountBlocked = this._servicesetting.retailSettingsFormGrp.controls.accountblocked.value ? this._servicesetting.spaSettingsFormGrp.controls.accountblocked.value : false;
      editedInfo.userPropertyAccesses[retailRowIndex].autoLogOff = this._servicesetting.retailSettingsFormGrp.controls.autologoff.value ? this._servicesetting.spaSettingsFormGrp.controls.autologoff.value : false;
      editedInfo.userPropertyAccesses[retailRowIndex].logOffAfter = this._servicesetting.retailSettingsFormGrp.controls.autologoff.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.logoffafter.value) : Number(0);
    }
    let newRetailConf;
    if (retailConf) {
      retailConf.allowGratuity = this._servicesetting.retailSettingsFormGrp.controls.allowgratuity.value ? this._servicesetting.retailSettingsFormGrp.controls.allowgratuity.value : false;
      retailConf.allowServiceCharge = this._servicesetting.retailSettingsFormGrp.controls.allowservicecharge.value ? this._servicesetting.retailSettingsFormGrp.controls.allowservicecharge.value : false;
      retailConf.allowCommission = this._servicesetting.retailSettingsFormGrp.controls.allowcommission.value ? this._servicesetting.retailSettingsFormGrp.controls.allowcommission.value : false;
      retailConf.commissionClass = this._servicesetting.retailSettingsFormGrp.controls.allowcommission.value ? Number(this._servicesetting.retailSettingsFormGrp.controls.commissionclass.value) : Number(0);
      retailConf.rolename = this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.rolename.value) : Number(0);
    } else {
      newRetailConf = {
        userId: editedInfo.userId,
        allowGratuity: this._servicesetting.retailSettingsFormGrp.controls.allowgratuity.value ? this._servicesetting.retailSettingsFormGrp.controls.allowgratuity.value : false,
        allowServiceCharge: this._servicesetting.retailSettingsFormGrp.controls.allowservicecharge.value ? this._servicesetting.retailSettingsFormGrp.controls.allowservicecharge.value : false,
        allowCommission: this._servicesetting.retailSettingsFormGrp.controls.allowcommission.value ? this._servicesetting.retailSettingsFormGrp.controls.allowcommission.value : false,
        commissionClass: this._servicesetting.retailSettingsFormGrp.controls.allowcommission.value ? Number(this._servicesetting.retailSettingsFormGrp.controls.commissionclass.value) : Number(0),
        rolename: this._servicesetting.spaSettingsFormGrp.controls.rolename.value ? Number(this._servicesetting.spaSettingsFormGrp.controls.rolename.value) : Number(0)
      }
    }

    let userTherapist;
    let deleteUserTherapistId;
    if (userTherapistInfo) {
      if (!this._servicesetting.spaSettingsFormGrp.controls.istherapistscheduleview.value) {
        deleteUserTherapistId = userTherapistInfo.therapistId;
        userTherapistInfo = null;
      } else if(userTherapistInfo.therapistId === this._servicesetting.spaSettingsFormGrp.controls.usertherapist.value){
          userTherapistInfo = undefined;
      }
      else {
        userTherapistInfo.therapistId = this._servicesetting.spaSettingsFormGrp.controls.usertherapist.value
      }
    }
    else {
      userTherapist = this._servicesetting.spaSettingsFormGrp.controls.usertherapist?.value ? {
        userId: editedInfo.userId,
        therapistId: this._servicesetting.spaSettingsFormGrp.controls.usertherapist.value
      } : null;
    }
    let selServiceGrp = this._servicesetting.selectedServiceGrp;
    selServiceGrp = _.uniq(selServiceGrp);
    let sindex = selServiceGrp.indexOf(0);
    if (sindex >= 0) {
      selServiceGrp.splice(sindex, 1);
    }

    let selclassGrp = this._servicesetting.selectedClassGrp;
    selclassGrp = _.uniq(selclassGrp);
    let cindex = selclassGrp.indexOf(0);
    if (cindex >= 0) {
      selclassGrp.splice(cindex, 1);
    }

    editedInfo.retailOutletMap = undefined;

    this.EditUser(editedInfo, GlobalConst.Host.authentication, 'UpdateUser', [selServiceGrp, retailConf, newRetailConf, userTherapistInfo, userTherapist, deleteUserTherapistId,selclassGrp], { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) })
  }

  cancel() {
    this.dialogRef.close("cancelled");
  }


  GetServiceCall(Route, extraParams, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: extraParams
    });
  }

  async GetEncryptionKey(data, host, callDesc, extraParams, uri?) {

    this.http.CallApiWithCallback<any>({
      host: host,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      method: HttpMethod.Get,
      body: '',
      showError: false,
      extraParams: extraParams,
      uriParams: uri
    });
  }

  CreateUser(data, host, callDesc, extraParams, uri?) {
    this.http.CallApiWithCallback<any>({
      host: host,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      method: HttpMethod.Post,
      body: data,
      showError: true,
      extraParams: extraParams,
      uriParams: uri
    });
  }

  DeleteUserTherapist(host, callDesc, extraParams, uri?) {
    this.http.CallApiWithCallback<any>({
      host: host,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      method: HttpMethod.Delete,
      showError: true,
      extraParams: extraParams,
      uriParams: uri
    });
  }

  EditUser(data, host, callDesc, extraParams, uri?) {
    this.http.CallApiWithCallback<any>({
      host: host,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      method: HttpMethod.Put,
      body: data,
      showError: true,
      extraParams: extraParams,
      uriParams: uri
    });
  }
  async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): Promise<void> {
    if (callDesc == 'CreateUser') {
      if (result.result) {
        extraParams[1].userId = Number(result.result)
        if (extraParams[0] && extraParams[0].length > 0) {
          let spausergroup =[];
       
        extraParams[0].forEach(element => {
          let userObj = {
            UserId: result.result,
            IsClassGroup:false,
            ServiceGroupId:element
          }
          spausergroup.push(userObj);
        });

        extraParams[3].forEach(element => {
          let userObj = {
            UserId: result.result,
            IsClassGroup:true,
            ServiceGroupId:element
          }
          spausergroup.push(userObj);
        });

          this.CreateUser(spausergroup, GlobalConst.Host.spaManagement, 'CreateUserSpaConfig', [], { id: result.result })
        }
        if (extraParams[2]) {
          extraParams[2].userId = Number(result.result)
          this.CreateUser(extraParams[2], GlobalConst.Host.spaManagement, 'CreateUserTherapist', [])
        }
        this.CreateUser(extraParams[1], GlobalConst.Host.retailManagement, 'CreateUserRetailConfig', []);
        if(this.subPropertyAccess?.length > 0){
          this.subPropertyAccess.forEach(x => x.userID = extraParams[1].userId);
          await this._userOutletAccessDataService.CreateUserOutletAccess(this.subPropertyAccess);
        }
        this.dialogRef.close("saved");
      }
    }
    else if (callDesc == 'UpdateUser') {
      if (result.result) {
        let updated = <any>result.result;
        let spausergroup =[];
       
        extraParams[0].forEach(element => {
          let userObj = {
            UserId: updated.userId,
            IsClassGroup:false,
            ServiceGroupId:element
          }
          spausergroup.push(userObj);
        });

        extraParams[6].forEach(element => {
          let userObj = {
            UserId: updated.userId,
            IsClassGroup:true,
            ServiceGroupId:element
          }
          spausergroup.push(userObj);
        });
       

        this.EditUser(spausergroup, GlobalConst.Host.spaManagement, 'UpdateUserSpaConfig', [], { id: updated.userId });

        if (extraParams[1]) {
          this.EditUser(extraParams[1], GlobalConst.Host.retailManagement, 'UpdateUserRetailConfig', [])
        } else {
          this.CreateUser(extraParams[2], GlobalConst.Host.retailManagement, 'CreateUserRetailConfig', [])
        }
        if (extraParams[3]) {
          this.EditUser(extraParams[3], GlobalConst.Host.spaManagement, 'UpdateUserTherapist', [])
        }
        else if (extraParams[4]) {
          this.CreateUser(extraParams[4], GlobalConst.Host.spaManagement, 'CreateUserTherapist', [])
        }
        else if (extraParams[5]) {
          this.DeleteUserTherapist(GlobalConst.Host.spaManagement, 'DeleteUserTherapist', [], { therapistId : extraParams[5]});
        }
        if (this.subPropertyAccess && this.subPropertyAccess.length > 0) {
          await this._userOutletAccessDataService.UpdateUserOutletAccess(this.subPropertyAccess);
        }

        this.dialogRef.close("saved");
      }
    }
    else if (callDesc == 'GetAllUserTherapist') {
      if (result.result) {
        this.filteredUserTherapist = <any>result.result;
        this.filteredActiveTherapist = this._servicesetting.allTherapist.filter(x=> !this.filteredUserTherapist.map(y=> y.therapistId).includes(x.id));
      }
    }
  }

  errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    this.IsDiableActionButton = false;
    console.error(result.result);
  }



  async CreateUserConfig(callDesc, host, body, uri?) {
    let info = await this.http.CallApiAsync({
      host: host,
      uriParams: uri,
      callDesc: callDesc,
      method: HttpMethod.Post,
      body: body
    });

    return info.result;
  }

  handleclick(eve) {
    eve.stopPropagation();
  }

  ngOnDestroy() {
    if (this.$destroyed) {
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }
  }
}
