import { ResizeRows } from '../interface/common';

export const columnInfo = 'columninfo';
export const columnInfoInitial = 'columninfoInitial';
export const itemsPerPage = 100;
export const rowHeightData = 'rowHeightData';
export const defaultRowHeightData = 'defaultRowHeightData';

export const resizeRows: ResizeRows[] = [{
    Id: 1,
    Name: 'Cozy',
    IconName: 'icon-default-eatec',
    DisplayName: 'Cozy'
}, {
    Id: 2,
    Name: 'Comfort',
    IconName: 'icon-comfortable-eatec',
    DisplayName: 'Comfort'
}, {
    Id: 3,
    Name: 'Compact',
    IconName: 'icon-compact-eatec',
    DisplayName: 'Compact'
}];

export const Language = {
    en: 'en',
    fr: 'fr'
};

export enum GridRowHeight {
    Cozy = 60,
    Comfort = 45,
    Compact = 30
}

export enum SortDirection {
    Ascending = 'asc',
    Descending = 'desc'
}

export enum FilterType {
    SelectType = 'multiselect',
    DateType = 'date',
    DateRangeType = 'daterange',
    RadioType = 'radio'
}

export const GlobalConfig = {
    'GlobalSearchConfiguartion': {
        'EnabledSearch': true,
        'SearchType': 'server',
        'SearchTypeConfig': {
            'ServerSideSearch': {
                'Searchby': ['all'],
                'SearchMinKey': 2
            }
        },
        'SearchPlaceholder': 'Search',
        'SearchKey': 'Key'
    },
    'GlobalPaginationConfiguration': {
        'EnabledPaginationation': false,
        'PaginationPageSize': 10,
        'TotalRecords': 0,
        'StartIndex': 0,
        'EndIndex': 10,
        'MaxSizeForPageNumber': 5
    },
    'GlobalInfiniteScrollConfiguration': {
        'EnableInfiniteScroll': true,
        'TotalRecords': 0
    },
    'GlobalReshuffleConfiguration': {
        'EnableColumnShuffle': true
    },
    'GlobalFilterConfiguration': {
        'EnableFilter': false
    },
    'GlobalResizeRows': {
        'EnableResizeRows': true
    },
    'GlobalGridActionRendererConfiguration': {
        'headerName': '',
        'field': '',
        'editable': false,
        'cellRenderer': 'actionRenderer',
        'hide': false
    },
    'GlobalHeaderConfiguration': {
        'editable': false,
        'sortOrder': [],
        'sortable': true,
        'hide': false
    }
};
