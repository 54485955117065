import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { FormBuilderGroupKey, MasterInventoryManagement } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FormModelConfiguration, FormModelFieldBuilder } from './form-config.interface';

export const FormGroupFieldType: FormModelFieldBuilder = (
ViewModelEntity: any,
PostModelEntity: any,
MasterDataCollection: MasterInventoryManagement,
httpService: HttpService,
FieldGrouping: FormBuilderGroupKey): any => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 ) {
          if (!FieldGrouping.EdmType) {
            const Type = ViewModelEntity[0].Property.filter(x => x['$'].Name === FieldGrouping.OdataKey);
            if (Type.length > 0) {
              FieldGrouping.EdmType = Type[0]['$'].Type;
            }
          }
            //return getModelType(FieldGrouping, ViewModelEntity);
        }
        return getModelType(FieldGrouping, ViewModelEntity);
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function getModelType(FieldGrouping: FormBuilderGroupKey, ViewModelEntity: Array<any>): string {
    try {
        let fieldType = '';
        switch (FieldGrouping['EdmType']) {
            case FieldEdmType.EDMSTRING:
                fieldType = (FieldGrouping && FieldGrouping.hasOwnProperty('FieldType')) ? FieldGrouping.FieldType : FormFieldType.TEXT;
              break;
            case FieldEdmType.EDMINT32:
                fieldType = checkNavigationDropdown(FieldGrouping, ViewModelEntity);
              break;
              case FieldEdmType.EDMINT16:
              fieldType = (FieldGrouping && FieldGrouping.hasOwnProperty('FieldType')) ? FieldGrouping.FieldType : FormFieldType.TEXT;
              break;
              case FieldEdmType.EDMDATEOFFSET:
                fieldType = FormFieldType.DATEPICKER;
              break;
              case FieldEdmType.EMDBOOLEAN:
                fieldType = ((FieldGrouping['Name'] === 'IsActive' || FieldGrouping.IsCheckbox) && FieldGrouping.FieldType !== 'switch')
                ? FormFieldType.CHECKBOXSINGLE : FormFieldType.SWITCH;
              break;
              case FieldEdmType.EDMINTDECIMAL:
                fieldType = FormFieldType.NUMBER;
              break;
              case FieldEdmType.EDMPICTURE:
                fieldType = FormFieldType.PICTURE;
              break;
              case FieldEdmType.EDMHIDDEN:
                fieldType = FormFieldType.HIDDEN;
              break;
              case FieldEdmType.EDMMULTIDSELECT:
                fieldType = FormFieldType.MULTISELECT;
              break;
              case FieldEdmType.EDMFLOAT:
                fieldType = FormFieldType.NUMBER;
              break;
              case FieldEdmType.EDMDROPDOWN:
                fieldType = FormFieldType.DROPDOWN;
              break;
              case FieldEdmType.EDMTIME:
                fieldType = (FieldGrouping && FieldGrouping.hasOwnProperty('FieldType')) ? 
                FieldGrouping.FieldType : FormFieldType.TIMEPICKER;
                break;
              case FieldEdmType.LABELWITHICON:
                fieldType = FormFieldType.LABELWITHICON;
                break;
            default:
                fieldType = FormFieldType.TEXT;
          }
          return fieldType;

    } catch (error) {
        console.log('error occured', error);
    }
}


function checkNavigationDropdown(FieldGrouping: FormBuilderGroupKey, ViewModelEntity: Array<any>) {
    let modelFieldType: string = FormFieldType.NUMBER;
    if (ViewModelEntity && ViewModelEntity.length > 0 && ViewModelEntity[0].hasOwnProperty('NavigationProperty') ) {
      const modelIndex =  ViewModelEntity[0]['NavigationProperty']
      .findIndex(x => x['ReferentialConstraint']
      && FieldGrouping.OdataKey === x['ReferentialConstraint'][0]['$'].Property);
      if (modelIndex > -1) {
         modelFieldType = FormFieldType.DROPDOWN;
      }
      if (FieldGrouping.hasOwnProperty('CustomOdataUrl') && FieldGrouping.hasOwnProperty('FieldType')) {
        modelFieldType = FieldGrouping.FieldType;
      } else if (FieldGrouping.hasOwnProperty('CustomOptions') && FieldGrouping.hasOwnProperty('FieldType')) {
        modelFieldType = FieldGrouping.FieldType;
      }
      if ( FieldGrouping.hasOwnProperty('autoCompleteHeader') && FieldGrouping.autoCompleteHeader.length > 0 ) {
        modelFieldType = FieldGrouping.FieldType;
      }
    } else {
      if (FieldGrouping.hasOwnProperty('CustomOdataUrl') && FieldGrouping.hasOwnProperty('FieldType')) {
        modelFieldType = FieldGrouping.FieldType;
      } else if (FieldGrouping.hasOwnProperty('CustomOptions') && FieldGrouping.hasOwnProperty('FieldType')) {
        modelFieldType = FieldGrouping.FieldType;
      }
      if ( FieldGrouping.hasOwnProperty('autoCompleteHeader') && FieldGrouping.autoCompleteHeader.length > 0 ) {
        modelFieldType = FieldGrouping.FieldType;
      }
    }
    return modelFieldType;
  }
