import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
export class InventoryWash extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService,
         private func: RetailFunctionalityBusiness, public propertyInfo: RetailPropertyInformation,public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.InventoryWash,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();

        let inventoryTransferCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let categoryFilter: string[] = this.business.getSelectedCategoryCaptions(inventoryTransferCustomFilterData);
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle },
        { "pOutletFilter": this.functionalities.ShowOutletSelectionFieldInInventoryWashReport ? this.getFilterCaption  : this.getDefaultOutletCaption},
        { "pCategoryFilter": categoryFilter[0] },
        {"pSubCategory1": categoryFilter[1]},
        {"pSubCategory2": categoryFilter[2]},
        {"pSubCategory3": categoryFilter[3]},
        {"pSubCategory4": categoryFilter[4]},
        {"pSubCategory5": categoryFilter[5]},
        { "pPropertyName": this.propertyName },
        { "pDate" : this.printedDate },
        {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInInventoryWashReport ? true : false }];
    }


    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }];
    }


    protected formFilters() {
        let inventoryWashCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempOutlets = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if (this.retailLocalization.isFromJobScheduler)
        {
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
            return {
                CategoryIds: this.fillFilter(inventoryWashCustomFilterData.category[0]),
                SubCategory1Ids: this.fillFilter(inventoryWashCustomFilterData.category[1]),
                SubCategory2Ids: this.fillFilter(inventoryWashCustomFilterData.category[2]),
                SubCategory3Ids: this.fillFilter(inventoryWashCustomFilterData.category[3]),
                subCategory4Ids: this.fillFilter(inventoryWashCustomFilterData.category[4]),
                SubCategory5Ids: this.fillFilter(inventoryWashCustomFilterData.category[5]),
                OutletIds: this.functionalities.ShowOutletSelectionFieldInInventoryWashReport ? this.selectedFilterOptions.map(x => x.id) : tempOutlets,
                useInventory: true,
                fromWildCard,
                fromIncrement,
                toWildCard,
                toIncrement
                
            };
        }
        return {
            CategoryIds: this.fillFilter(inventoryWashCustomFilterData.category[0]),
            SubCategory1Ids: this.fillFilter(inventoryWashCustomFilterData.category[1]),
            SubCategory2Ids: this.fillFilter(inventoryWashCustomFilterData.category[2]),
            SubCategory3Ids: this.fillFilter(inventoryWashCustomFilterData.category[3]),
            subCategory4Ids: this.fillFilter(inventoryWashCustomFilterData.category[4]),
            SubCategory5Ids: this.fillFilter(inventoryWashCustomFilterData.category[5]),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInInventoryWashReport ? this.selectedFilterOptions.map(x => x.id) : tempOutlets,
            useInventory: true
        };
    }

    fillFilter(customFilterData: any): number[] {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
        if(customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0){
           ids = [];
        }
        return ids;
    }
}


