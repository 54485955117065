import { Component, OnInit } from '@angular/core';
import { SpaLocalization } from '../../core/localization/spa-localization';

import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import * as GlobalConst from '../../shared/globalsContant';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { menuTypes } from 'src/app/shared/shared.modal';
@Component({
  selector: 'app-system-config',
  templateUrl: './system-config.component.html',
  styleUrls: ['./system-config.component.scss']
})
export class SystemConfigComponent implements OnInit {
  caption: any;
  systemConfigCategories: any;
  systemConfigId: string;

  isUserAuthorized: boolean;
  isViewOnly: boolean;
  menuList: any;
  menuType = menuTypes;
  constructor(private localization: SpaLocalization
    , private breakpoint: BreakPointAccess
    , private propertyInformation: SpaPropertyInformation,private routeDataService: RouteLoaderService) {
      const value = this.routeDataService.GetChildMenu('/settings/systemconfig');
      this.menuList = {
        menu: value.linkedElement,
        menuType : menuTypes.lowerLevel
      };

  }

  ngOnInit() {
    this.caption = this.localization.captions;
    this.systemConfigCategories = [
      { id: 'propertyInfo', viewValue: this.caption.bookAppointment.PropertyInformation, callDesc: '' },
      { id: 'appointment', viewValue: this.caption.bookAppointment.Appointment, callDesc: '' },
      { id: 'miscellaneous', viewValue: this.caption.bookAppointment.Miscellaneous, callDesc: '' },
      { id: 'rguestbooksetup', viewValue: this.caption.bookAppointment.rGuestBookSetup, callDesc: '' },
      { id: 'notificationConfigurationSetup', viewValue: this.caption.emailConfigurationsetup.NotifyConfigurationssetup, callDesc: '' },
      { id: 'dashboardConfiguration', viewValue: this.caption, callDesc: '' }
    ];
    this.systemConfigId = this.systemConfigCategories[0].id;
    this.validateBreakPoint();
  }

  validateBreakPoint(): void {
    this.isUserAuthorized = this.breakpoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings]);
    this.isViewOnly = this.breakpoint.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings);
    if (!this.isUserAuthorized) {
      this.systemConfigId = '';
    }
  }

  configChange(configOption) {
    if (!this.isUserAuthorized) {
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings]);
      return;
    }

    this.systemConfigId = configOption.id;
  }
}
