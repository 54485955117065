import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropFileComponent } from './drag-drop-file.component';
import { DndDirective } from './drag-drop-directive';
import { CommonSharedModule } from '../../shared/shared/shared.module';



@NgModule({
  declarations: [
    DragDropFileComponent,
    DndDirective
  ],
  imports: [
    CommonModule,
    CommonSharedModule
  ],
  exports:[
    DragDropFileComponent,
    DndDirective
  ]
})
export class DragDropFileModule { }
