import { TranslateService } from '@ngx-translate/core';
import { GridColumnHeaderName } from '../grid.interface';
// import * as LanguageJson from '@assets/i18n/en.json';
import { ProductDataRemap } from '../product-data-remap';

export const GridHeaderName: GridColumnHeaderName = (HeaderName: string, Key: string, translateService ?: TranslateService): any => {
    try {
        // let langJson = (LanguageJson as any).default;
        // const productremapJson = (remapjson as any).default;
        // const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
        // langJson = ProductDataRemap.DisplayNameRemapEngine(null, productremapJson);
        const translatedName = translateService.instant(Key + `.${HeaderName}`);
        const commonTranslatedName = translateService.instant(`Common.${HeaderName}`);
        const Name = ( translatedName ) ? translatedName :
        ( commonTranslatedName ? commonTranslatedName : HeaderName);
        // console.log('Headername', langJson[HeaderName], HeaderName);
        return HeaderName.toLowerCase() === 'isactive' ? '' : Name;
    } catch (error) {
        console.log('error occured GridColumnHeaderName', error);
    }
};