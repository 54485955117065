import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { AddCustomFilterAction, CustomIconList, IconListConfig, MergedFields } from '../custom-action.interface';
import { GlobalCustomConfig } from '../global-custom-config.interface';

export const CustomMergeIcon: GlobalCustomConfig = (
  Name: string,
  Odatakey: string,
  FieldKey: string,
  ActionType: string,
  TypeName?: string,
  IsCustomUrl?: boolean,
  CustomUrl?: any,
  CustomFilter?: Array<AddCustomFilterAction>,
  IsCustomMerge?: boolean,
  MergerFields?: Array<MergedFields>,
  MergeSymbol?: any,
  ResponseObject?: Array<any>,
  TransaactionCollection?: TransactionConfiguration,
  MasterCollection?: MasterInventoryManagement,
  SetupCollection?: any,
  IsCustomIconMerge?: boolean,
  CustomIconsList?: Array<CustomIconList>,
  CustomIcons?: Array<IconListConfig>): any => {
  try {
    if (CustomIconsList && Object.keys(CustomIconsList).length > 0 && IsCustomIconMerge) {
      const DefaultValue = function (params: any) {
        let a = `<span title="${params.value}">${params.value}</span>`;
        CustomIconsList.forEach((icons, index) => {
          if (index === 0) {
            a += '<span class="icon-section">';
          }
          if (params.data[icons.PropertyCheck] === icons.ValueToCheck && icons.IconName) {
            a += `<i class="${icons.IconName}" title="${icons.ToolTipValue}"></i>`;
          } else if (icons.PropertyCheck && !icons.IconName) {
            let IconData;
            if (icons.SecondaryPropertyCheck && params.data[icons.PropertyCheck]) {
              IconData = CustomIcons.filter(x => x.IconId === params.data[icons.PropertyCheck][icons.SecondaryPropertyCheck])[0];
            } else {
              IconData = CustomIcons.filter(x => x.IconId === params.data[icons.PropertyCheck])[0];
            }
            let IconName = '';
            if (IconData) {
              IconName = IconData.IconName;
              a += `<i class="${IconName}" title="${IconData.ToolTipValue}"></i>`;
            }
          }
          if (index === CustomIconsList.length - 1) {
            a += '</span>';
          }
        });
        
        return a;
      };
      return DefaultValue;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};