import { Injectable } from '@angular/core';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { SelectedProducts } from 'src/app/retail/shared/service/common-variables.service';
import { BaseResponse, RetailItemType } from 'src/app/retail/retail.modals';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { ShopAddItemModal } from '../../shop.modals';


@Injectable()
export class ShopAddItemBusiness {

    constructor(
        public localization: RetailLocalization,
        private http: HttpServiceCall
    ) { }

    getInventoryData(data: ShopAddItemModal): any {
        const dateDiff = this.localization.getDatesForGivenRange(data.stayarrivalDate, data.staydepartureDate);
        const invstatus = [];
        let prodPrice1 = data.products.ProductPrice;
        let prodPrice = this.localization.localizeCurrency(prodPrice1);
        if (data.products.ItemType === RetailItemType.RentalItem) {
            prodPrice1 = data.products.perDayRentalRate;
            const prodPrice2 = data.products.perHourRentalRate;
            prodPrice = this.localization.localizeCurrency(prodPrice1) + '/' + this.localization.localizeCurrency(prodPrice2);
        }
        dateDiff.forEach(element => {
            const currDateStay = data.inventory.stayResponse.find(x => this.localization.LocalizeDate(x.stayDate) === element.date);
            const inHouse = currDateStay ? currDateStay.inHouseStatus : 0;
            const resv = currDateStay ? currDateStay.resvStatus : 0;
            let avail = 0;
            let total = 0;
            let invObj = {
                date: element.date,
                values: [
                    {
                        value: total,
                        bgColor: 'steelblue'
                    },
                    {
                        value: inHouse,
                        bgColor: 'yellow',
                    },
                    {
                        value: resv,
                        bgColor: 'lightcyan'
                    },
                    {
                        value: avail,
                        bgColor: 'turquoise',
                    },
                    {
                        value: prodPrice,
                        bgColor: '#fff'
                    }
                ]
            };
            if (data.products.ItemType === RetailItemType.RetailItemRetailPOSOnly) {
                avail = (data?.inventory?.invResponse?.quantity) ?? 0;
                invObj = {
                    date: element.date,
                    values: [
                        {
                            value: inHouse,
                            bgColor: 'yellow',
                        },
                        {
                            value: resv,
                            bgColor: 'lightcyan'
                        },
                        {
                            value: avail,
                            bgColor: 'turquoise',
                        },
                        {
                            value: prodPrice,
                            bgColor: '#fff'
                        }
                    ]
                };
            }
            else if (data.products.ItemType == RetailItemType.RentalItem) {
                
                avail = (data?.inventory?.invResponse?.initialQuantity - inHouse - resv) ?? 0;
                total = data?.inventory?.invResponse?.initialQuantity ?? 0;
                invObj = {
                    date: element.date,
                    values: [
                        {
                            value: total,
                            bgColor: 'steelblue'
                        },
                        {
                            value: inHouse,
                            bgColor: 'yellow',
                        },
                        {
                            value: resv,
                            bgColor: 'lightcyan'
                        },
                        {
                            value: avail,
                            bgColor: 'turquoise',
                        },
                        {
                            value: prodPrice,
                            bgColor: '#fff'
                        }
                    ]
                };
            }
            invstatus.push(invObj);
        });
        return invstatus;
    }

    async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
          return await this.http.CallApiAsync({
            host: domain,
            callDesc: route,
            method: callType,
            body: body,
            uriParams: uriParams,
          });
        } catch (e) {
          this.http.exceptionHandle(e);
        }
      }

}
