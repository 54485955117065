import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef,
  ViewEncapsulation, OnDestroy, OnChanges, SimpleChange, HostListener
} from '@angular/core';
import { cloneDeep } from 'lodash'; // STORAGE THE BACK ARRAY
import { SlideInformationService } from '../slide-information/slide-information.service';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { CalendarGridService } from './calendar-grid-service/calendar-grid.service'
import { GridViewType, GridCellClickEventParam, GridItemDropEventParam, CalendarGridHeader, CalendarGridProperty, CalendarGridBody, CalendarGridBodyProps, CalendarGridType } from './calendar-grid-models/calendar-grid.models';
import { SpaUtilities } from '../utilities/spa-utilities';
import { DropEvent } from 'ng-drag-drop';
import { editStaffSchedule, StaffScheduleService } from 'src/app/settings/staff-schedule/staff-schedule.service';
import { StaffSchedulePopupComponent } from 'src/app/settings/staff-schedule/staff-schedule-popup/staff-schedule-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Host, SPAManagementBreakPoint, DisplayTherapist} from '../globalsContant';
import { BreakPointAccess } from '../service/breakpoint.service';
import { DefaultGridType } from '../business/shared.modals';
import { SystemConfiguration } from '../business/view-settings.modals';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';
import { DatePipe } from '@angular/common';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';


@Component({
  selector: 'app-calendar-grid',
  templateUrl: './calendar-grid.component.html',
  styleUrls: ['./calendar-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaffScheduleService]
})

export class AgsysCalendarGridComponent implements OnInit, OnDestroy, OnChanges {
  public gridHeaderArray: CalendarGridHeader<any>[] = [];
  public gridHeaderDDArray: CalendarGridHeader<any>[] = [];
  public tableDisplayDDArray: CalendarGridBody[];
  public allowSingleHeader: boolean;
  public sideContentArray: any = [];
  public tableDisplayArray: CalendarGridBody[] = [];
  public selectedDateWithYear: Date = this.propertyInfo.CurrentDTTM
  public headerKeyArray: any;
  public allDayCheckbox: boolean;
  public gridTimeInterval: number;
  public showOverlapFlag: any;
  public toolTipLeft: number = 0;
  public toolTipTop: number = 0;
  public tooltipPlacementPos = '';
  public captions: any = this.localization.captions.bookAppointment;
  public showmorelink: boolean = true;
  public selectedHeaderValue: any;
  public loadScrollToCurrTime: boolean;

  public GridViewType: typeof GridViewType = GridViewType;
  public CalendarGridType: typeof CalendarGridType = CalendarGridType;
  public selectedGridViewType: GridViewType;
  public dragEnableFlag: boolean = true;
  CalendarGridProperty: CalendarGridProperty<any>;
  lodertimeflag: boolean = true;
  selectedTherapist: CalendarGridHeader<any>;
  currentData: any[];
  isLoaderEnable = false;
  fitToGridWidth = 0;
  @Input() clientIcons;
  @Input() automationId: string='';
  private _showOverlap: any;
  cellHeightClass: string;
  eachCellHeight: number;
  isLineIndicationEnabled: boolean = true;
  isInitLoad: boolean = true;
  previousSelectedDate: string;
  previousGridInterval: number;
  appointmentConfiguration:any;
  isWeekEnabled: boolean;
  isWeekEnabledforAllTherapist: boolean;
  from: string = 'main'
  @ViewChild('agsysCellinfoPopup', { static: false }) cellPopover: any;
  moreObj: any;
  currentDate: string;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27 && this.cellPopover.popover.visible) { // 27===ESC Key
        this.cellPopover.hide();
    }
  }
  /*
   * Inputs for Calendar component
   */
  @Input('from')
  set setFrom(value) {
    this.from = value;
  }
  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @Input('InputCalendarGridProperty')
  set InputCalendarGridProperty(data: CalendarGridProperty<any>) {
    console.log(data);
    this.lodertimeflag = false;
    this.CalendarGridProperty = data;
    this.NoOfAppts = null;
    this.refreshGrid();
    // setTimeout(() => {
    // this.calendarGridService.reloadGridStyleCss(this.tableDisplayArray);
    this.lodertimeflag = true;
    this.isWeekEnabled = data && data.isWeekEnabled ? data.isWeekEnabled : false;
    this.isWeekEnabledforAllTherapist = data && data.isWeekEnabledforAllTherapist ? data.isWeekEnabledforAllTherapist : false
    if(!this.isInitLoad) {
      if(this.previousSelectedDate != this.localization.localizedDate(new Date(data.SelectedDate))) {
        this.isInitLoad = true;
      }
      else if(this.previousGridInterval != data.GridTimeInterval) {
        this.isInitLoad = true;
        let highlightClass = document.querySelectorAll('.highlight-current-time');
        highlightClass.forEach((x,index) => {
          (highlightClass[index] as HTMLElement).style.top = 0+'px';
        })
      }
      else if(data.isTodayDateChange) {
        if(this.localization.localizedDate(data.SelectedDate) == this.localization.localizedDate(this.propertyInfo.CurrentDate)) {
          this.isInitLoad = true;
        }
      }
    }
    if(this.isInitLoad && data) {
      this.scrolltoCurrentTime();
      this.isInitLoad = false;
      this.previousSelectedDate = this.localization.localizedDate(new Date(data.SelectedDate));
      this.previousGridInterval = data.GridTimeInterval
    }
   this.currentDate = this.localization.localizedDate(this.propertyInfo.CurrentDate);
    // }, (1));
  }
  // @Input('headerArr')
  // set header(value: CalendarGridHeader<any>[]){
  //   this.gridHeaderArray = value;
  // }
  @Input('loading')
  set isLoading(value: boolean) {
    if (value) {
      this.isLoaderEnable = true;
      this.calendarGridService.gridGHeaderArray = [1, 2, 3, 4, 5, 6, 7];
    } else {
      this.isLoaderEnable = false;
    }
  }
  @Input() FitToGrid: boolean;
  @Input('showOverlap')
  set overLapValue(value) {
    this._showOverlap = value;
    // if(this.CalendarGridProperty){
    //   this.calendarGridService.reloadGridStyleCss(this.tableDisplayArray);
    // }
  }
  @Input() currentDateValue: any[];
  @Input('appointmentConfiguration')
  set isAppointmentConfiguration(value){
    this.appointmentConfiguration=value;
    if(this.appointmentConfiguration)
    { 
      this.setbg = {
        modify : this.appointmentConfiguration[0]["APPOINTMENT_MODIFY_THERAPIST_VIEW"],
        name :  false,
        image: false
       }
    let DisplayTherapistNumber=this.appointmentConfiguration[0]["APPOINTMENT_DISPLAY_THERAPIST"];
    if(this.setbg.modify){
     this.setbg.name=(Number(DisplayTherapistNumber)==DisplayTherapist.TherapistName)?true:false;
     this.setbg.image=(Number(DisplayTherapistNumber)==DisplayTherapist.TherapistPicture)?true:false;
     this.setbg.fColor = this.appointmentConfiguration[0]["APPOINTMENT_FEMALE_THERAPIST"];;
     this.setbg.mColor = this.appointmentConfiguration[0]["APPOINTMENT_MALE_THERAPIST"];
    }
  }
  else{
    this.setbg = {
      modify : false,
      name :  false,
      image: false
     }
  }
  }
  
  @Input() DisplayDefaultGridType: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() restrictScrollToCurrTime: boolean;
  @Input() isRefreshed: boolean;
  /**
   * Output/Events of Calendar Component
   */
  @Output() cellClickEvent: EventEmitter<GridCellClickEventParam> = new EventEmitter();
  @Output() itemDropEvent: EventEmitter<any> = new EventEmitter();
  @Output() gridRefreshafterDelete: EventEmitter<any> = new EventEmitter();
  @Output() onSpecialitiesClick = new EventEmitter();
  @Output() emitHeaderClick = new EventEmitter();
  @Output() selectedDateforWeekview: EventEmitter<any> = new EventEmitter();
  @Output() getScheduleforWeekViewedit: EventEmitter<any> = new EventEmitter();
  @ViewChild('agsysblockedCell') blockedCellRef: ElementRef;
  @ViewChild('calendarGrid') calenderGrid: ElementRef;
  dragRowIndex: number;
  dragColumnIndex: number;
  DragFromHdrColumn: any;
  isPastDate: boolean;
  isCurrentDate: boolean;
  arrowRight: boolean;
  NoOfAppts = 0;
  imageContent: any;
  staffEnable: boolean = true;
  DisplayAppointmentGridType = DefaultGridType;
  enableDrag: boolean = true;
  isTherapistView: boolean = false;
  calendarGridId: string = "agsys-calendar-grid";
  setbg: any;
  schedulesforWeekViewEdit: any;
  @Input('isSingleTherapistView')
  set isSingleTherapistEnabled(value){
    this.isTherapistView = value;
    this.enableDrag = value? false: true; 
  }
  @Input('gridId')
  set gridid(value) {
    this.calendarGridId = value ? value : 'agsys-calendar-grid';
  }
  @Input('schedulesforWeekviewedit')
  set schedulesforWeekViewedit(value) {
    if(value) {
      this.schedulesforWeekViewEdit = {
        'schedules' : value.schedules,
        'value' : value.value,
        'from' : value.from,
      }
      this.OpenEditStaffScheduleDialog(this.schedulesforWeekViewEdit.value,this.schedulesforWeekViewEdit.schedules,this.schedulesforWeekViewEdit.from)
    }
  }

  constructor(public slideService: SlideInformationService,
    public localization: SpaLocalization, private propertyInfo: SpaPropertyInformation,
    public calendarGridService: CalendarGridService, private utils: SpaUtilities, private staffService: StaffScheduleService, private dialog: MatDialog, public breakPoint: BreakPointAccess,
		private http: HttpServiceCall,private datePipe: DatePipe) {
  }

  /**
   * On Init Life Cylce
   */
  ngOnInit(): void {
    this.staffService.staffScheduleUpdateNotifier$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result.type === editStaffSchedule.deleted) {
          this.gridRefreshafterDelete.emit(result.therapistId);
        }
      });
  }
  /**
   * On Destroy Life Cylce
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  ngAfterViewInit(): void {
      setInterval(()=>{
        this.autoHighlightCurentTime();
      }, 50000);
  }

  /**
   * On changes Life Cycle
   */
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if ((changes['DisplayDefaultGridType'] || changes['loading'] || changes['currentDateValue']) || this.isRefreshed) {
      this.cellHeightClass = this.DisplayAppointmentGridType[this.DisplayDefaultGridType];
      this.eachCellHeight = this.GetCellHeightByType(this.DisplayDefaultGridType) + 1; // Added 1px for padding
      this.calendarGridService.agsysResetHeaderFlag = false;
      // this.NoOfAppts = null;
      if (this.CalendarGridProperty) {
        this.calendarGridService.reloadGridStyleCss(this.tableDisplayArray);
      }
    }
  }
  /**
   * Set all values related to grid for refresh
   */
  public refreshGrid(): void {
    if (this.CalendarGridProperty) {
      this.loadScrollToCurrTime = false;
      this.allDayCheckbox = this.CalendarGridProperty.AllDayCheckBox;
      this.tableDisplayArray = this.CalendarGridProperty.BodyArray;
      this.gridHeaderArray = this.CalendarGridProperty.HeaderArray;
      this.sideContentArray = this.tableDisplayArray.map(x => x.timing);
      // if (this.allDayCheckbox) {
      //   this.sideContentArray = this.localization.generateTimeRange(this.localization.ToTime(this.CalendarGridProperty.StartTime, "HH:mm"), this.localization.ToTime(this.CalendarGridProperty.EndTime, "HH:mm"), this.CalendarGridProperty.GridTimeInterval);
      // } else {
      //   const calendarStartDateTime = this.localization.combineDateAndTime(this.CalendarGridProperty.SelectedDate, this.CalendarGridProperty.StartTime);
      //   const calendarEndDateTime = this.localization.combineDateAndTime(this.CalendarGridProperty.SelectedDate, this.CalendarGridProperty.EndTime);
      //   this.sideContentArray = this.localization.generateTimeRange(calendarStartDateTime, calendarEndDateTime, this.CalendarGridProperty.GridTimeInterval);
      // }
      this.gridTimeInterval = this.CalendarGridProperty.GridTimeInterval;
      this.showOverlapFlag = this.CalendarGridProperty.EnableShowOverlapFlag;
      this.selectedDateWithYear = this.CalendarGridProperty.SelectedDate;
      this.calendarGridService.CalendarGridType = this.CalendarGridProperty.GridType;
      this.calendarGridService.EnableToolTip = this.CalendarGridProperty.EnableToolTip;
      this.allowSingleHeader = this.CalendarGridProperty.allowSingleHeader;
      this.selectedGridViewType = this.CalendarGridProperty.viewTypeSelected;
      const selectedDate = this.utils.GetDateWithoutTime(this.CalendarGridProperty.SelectedDate);
      const currentDate = this.utils.GetDateWithoutTime(this.propertyInfo.CurrentDate);
      this.isPastDate = selectedDate.getTime() < currentDate.getTime();
      this.isCurrentDate = (selectedDate.getTime() === currentDate.getTime());
      this.calculateNoOfAppts();
    }
  }

  scrolltoCurrentTime() {
    let currentDate = this.localization.localizedDate(this.propertyInfo.CurrentDate);
    if(this.CalendarGridProperty) {
      let selectedDate = this.localization.localizedDate(new Date (this.CalendarGridProperty?.SelectedDate));
      this.isLineIndicationEnabled = currentDate == selectedDate ? true : false;
      if(this.isLineIndicationEnabled) {
        let timer = setTimeout(() => {
          if (this.calenderGrid?.nativeElement){
              this.autoscrolltocurrtime();
            }
          clearTimeout(timer);
        }, 3000);
      }
    }
  }

  calculateNoOfAppts() {
    if (!this.NoOfAppts) {
      const currentData = [];
      let HeaderLength = this.calendarGridService.gridGHeaderArray.length;
      let AllData = [];
      if (this._showOverlap) {
        AllData = this.tableDisplayArray.map(r => r.data.map(s => {
          let overlapped = 0;
          overlapped = s.data.reduce((acc, shot) => acc > shot.overLapped ? acc : shot.overLapped, 0);
          const tempOverlapped = (s.data.length > overlapped) ? s.data.length : overlapped + 1;
          return s.data.length ? tempOverlapped : 1;
        }));
      } else {
        AllData = this.tableDisplayArray.map(r => r.data.map(s => {
          let overlapped = 0;
          let multiGroupAppointments = s.data.filter((o) => o.appointmentDetail.multiGroupId).length;
          multiGroupAppointments = (multiGroupAppointments > 1 ? multiGroupAppointments - 1 : 0);
          overlapped = s.data.reduce((acc, shot) => (acc > shot.overLapped ? acc : shot.overLapped), 0);
          const tempValue = (s.data.length > overlapped) ? s.data.length - multiGroupAppointments : overlapped - multiGroupAppointments + 1;
          return s.data.length ? tempValue : 1;
        }));
      }

      Object.keys(AllData[0]).forEach(function (c) {
        let temp = AllData.map(function (r) { return r[c]; });
        currentData.push(Math.max(...temp));
      });
      this.currentData = currentData;
      this.NoOfAppts = currentData && currentData.length ? currentData.reduce((a, b) => a + b) : HeaderLength;
      //No Appointments available for the current date and therapist scheduled for the current date
      if (this.FitToGrid && this.tableDisplayArray.every(res => res.data.every(s => s.data.length === 0))) {
        const blockeElem = this.blockedCellRef.nativeElement;
        const widthSize = (blockeElem.clientWidth - 100) / HeaderLength;
        this.fitToGridWidth = widthSize;
      }
    }
  }

  /**
   * Cell Click event handler
   * @param event - Mouse Event
   * @param timing - Time
   * @param header  - Header Object
   * @param viewTypeSelected  - Grid View Type
   * @param selectedDateWithYear - Date with year
   * @param allDayCheckbox - all day checkbox
   * @param obj - selected appoinment object
   * @param rowIndex - row Index
   * @param columnIndex - column index
   * @param cellObjectIndex - cell object index
   */
  cellClickEventHandler(event: MouseEvent, timing: string, header: CalendarGridHeader<any>, viewTypeSelected: GridViewType,
    selectedDateWithYear: Date, allDayCheckbox: boolean, obj: CalendarGridBodyProps, rowIndex: number,
    columnIndex: number, cellObjectIndex: number): void {
      if(this.isWeekEnabled || this.isWeekEnabledforAllTherapist) {
        selectedDateWithYear = this.dateChangeforWeekview(columnIndex);
        let isPreviousDate = this.localization.compareDates(new Date(selectedDateWithYear),new Date(this.propertyInfo.CurrentDate))
        isPreviousDate ? this.CalendarGridProperty['isViewOnly'] = true : this.CalendarGridProperty['isViewOnly'] = false;
      }
      if(!this.CalendarGridProperty?.isViewOnly) {
        event.stopImmediatePropagation();
        const clickedSectionPosition = Math.floor(event['layerY'] / 90);
        const formattedDate = new Date(this.localization.TimeToDate(timing));
        const calculatedTime = new Date(formattedDate.setMinutes(formattedDate.getMinutes() + (clickedSectionPosition * this.gridTimeInterval)));
        timing = this.localization.getTime(calculatedTime, this.localization.getTimeFormat());
        if (this.cellClickEvent) {
          const clickEventParam: GridCellClickEventParam = {
            event: event,
            timing: timing,
            hdr: header.headerObject,
            viewTypeSelected: viewTypeSelected,
            selectedDateWithYear: selectedDateWithYear,
            alldaycheckbox: allDayCheckbox,
            obj: obj,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            cellObjectIndex: cellObjectIndex,
            gridtimeinterval: this.gridTimeInterval
          };
          this.cellClickEvent.emit(clickEventParam);
        }
        this.utils.setLastVisitedApptSlot(this.viewport.elementRef.nativeElement.scrollTop.toString());
      }
  }

  /**
   * This method called & Event emitted when the data is dropped on the grid cell
   * @param event - Drop Event Object
   * @param time - Dropped cell time
   * @param hdrColoumn - header
   * @param celldata  - cell data
   */
  onItemDrop(event: DropEvent, time: string, hdrColoumn: any, celldata: any, rowIndex: number, columnIndex: number): void {
    this.calendarGridService.ondraggingEnabled = false;
    if (this.itemDropEvent && (this.dragRowIndex !== rowIndex || this.dragColumnIndex !== columnIndex)) {
      const gridItemDropEventParam: GridItemDropEventParam = {
        event: event,
        time: time,
        FromhdrColoumn: this.DragFromHdrColumn,
        TohdrColoumn: hdrColoumn,
        celldata: celldata,
        rowIndex: rowIndex,
        columnIndex: columnIndex,
        CalendarGridType: this.CalendarGridProperty.GridType,
        GridViewType: this.CalendarGridProperty.viewTypeSelected,
        isPastDate: this.isPastDate,
        selectedDateforWeekView: this.dateChangeforWeekview(columnIndex)
      };
      if(event?.dragData)
      {
        if(event.dragData.appointmentDetail){
          if(event.dragData.appointmentDetail?.doNotMove)
          {
            let errorMsg = this.localization.getError(956);
            this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
            return;
          }
        }
      }
      this.itemDropEvent.emit(gridItemDropEventParam);
    }
  }

  watchDrag(e) {
    let scrollSpeed = 15;
    let divHeight = document.getElementById(this.calendarGridId).offsetHeight;
    let divWidth = document.getElementById(this.calendarGridId).offsetWidth;
    let outleftheight = window.innerHeight - divHeight;
    let outleftWidth = window.innerWidth - divWidth;

    let verScroll = e.pageY - outleftheight;
    let HorScroll = e.pageX - outleftWidth;
    let scrollElement = document.getElementById(this.calendarGridId);
    if (verScroll < 0) {
      // this.AppSchscrollRef.scrollYTo(this.AppSchscrollRef.viewRef.nativeElement.scrollTop - scollSpeed);
      scrollElement.scrollTop = scrollElement.scrollTop - scrollSpeed;
    } else if (verScroll > (divHeight - 40)) {
      // this.AppSchscrollRef.scrollYTo(this.AppSchscrollRef.viewRef.nativeElement.scrollTop + scollSpeed);
      scrollElement.scrollTop = scrollElement.scrollTop + scrollSpeed;
    }
    if (HorScroll < 0) {
      scrollElement.scrollLeft = scrollElement.scrollLeft - scrollSpeed;
      // this.AppSchscrollRef.scrollXTo(this.AppSchscrollRef.viewRef.nativeElement.scrollLeft - scollSpeed);
    } else if (HorScroll > (divWidth - 40)) {
      scrollElement.scrollLeft = scrollElement.scrollLeft + scrollSpeed;
      // this.AppSchscrollRef.scrollXTo(this.AppSchscrollRef.viewRef.nativeElement.scrollLeft + scollSpeed);
    }
  }

  OnDragStart(rowIndex: number, columnIndex: number, fromHdrdata: any): void {
    this.DragFromHdrColumn = fromHdrdata;
    setTimeout(() => {
      this.calendarGridService.ondraggingEnabled = true;
    }, 1);
    this.dragRowIndex = rowIndex;
    this.dragColumnIndex = columnIndex;
  }

  OnDragEnd(): void {
    this.DragFromHdrColumn = null;
    this.dragRowIndex = -1;
    this.dragColumnIndex = -1;
    this.calendarGridService.ondraggingEnabled = false;
  }


  OnDragging(e, agsysCellinfoPopup) {
    agsysCellinfoPopup.hide();
  }
  /**
   * This Method set the styles for appointment cell
   * @param fullobj - Appointment array
   * @param obj - current object
   * @param rowIndex  - row index
   * @param columnIndex  - column index
   * @param cellObjectIndex - cell object index
   */
  getAgsyscalendarCellStyle(fullobj: any[], obj: CalendarGridBodyProps, rowIndex: number, columnIndex: number, cellObjectIndex: number): string {
    let currentcelltime = fullobj[rowIndex].timingObj;
    let fullobjlen: number = fullobj[rowIndex].data[columnIndex].data.length;
    let widthSize: number = 200;
    let defaultWidthSize: number = 200;
    let setfullwidth: boolean = false;
    // if(this.isWeekEnabled || this.isWeekEnabledforAllTherapist) {
      // setfullwidth = true
    // }
    let showoverlaptoggle: boolean = this.showOverlapFlag;
    let currentselectedDivTH = document.getElementById(this.calendarGridService.CalendarGridType + '_AppointmentHDR_' + rowIndex + '_' + columnIndex);
    let currentselectedDiv = document.getElementById(this.calendarGridService.CalendarGridType + '_cellEachAppoinment_' + rowIndex + '_' + columnIndex + '_' + cellObjectIndex);
    let currentselectedSetupTimeDiv = document.getElementById(this.calendarGridService.CalendarGridType + '_cellsetuptime_' + rowIndex + '_' + columnIndex + '_' + cellObjectIndex);
    let currentselectedBreakDownDiv = document.getElementById(this.calendarGridService.CalendarGridType + '_cellbreakdowntime_' + rowIndex + '_' + columnIndex + '_' + cellObjectIndex);

    let currentTableHeaderColumn = document.getElementById('table--header__cell'+'_'+ this.from + columnIndex);

    if (currentselectedDivTH && currentselectedDiv && currentselectedSetupTimeDiv && currentselectedBreakDownDiv) {
      // let eachcellheight = currentselectedDivTH['offsetHeight'];
      if (showoverlaptoggle) {
        if (fullobjlen == 1 && obj.overLapped <= 0 && !obj.overlappedFlag) {
          setfullwidth = true
          widthSize = 100;
        }
      } else {
        if (((obj.overLapped - obj.multiGroupOverlap) <= 0) && !obj.overlappedFlag) {
          setfullwidth = true
          widthSize = 100;
        }
      }
      let newDlen = 0;

      let setupTime: number = obj.appointmentDetail.setUpTime;
      let breakdownTime: number = obj.appointmentDetail.breakDownTime;

      let appfromTime = obj.appointmentDetail.startTimeObj;
      let apptoTime = obj.appointmentDetail.endTimeObj;
      let fromTime: Date;
      let toTime: Date = appfromTime;
      let differenceFA: number
      if(this.isWeekEnabled || this.isWeekEnabledforAllTherapist) {
        let date = obj.appointmentDetail.endTime.split('T');
        let time = this.datePipe.transform(fromTime, 'HH:mm:ss');
        let newDate = date[0]+"T"+time
        fromTime = new Date(newDate)
      } else {
        fromTime = currentcelltime;
      }
      differenceFA = this.getMinutesBetweenDates(fromTime, toTime);

      fromTime = appfromTime;
      toTime = apptoTime;
      let differenceFT = this.getMinutesBetweenDates(fromTime, toTime);

      const cellTop = (differenceFA / this.gridTimeInterval) * this.eachCellHeight;
      const cellheight = (differenceFT / this.gridTimeInterval) * this.eachCellHeight;

      const cellSUheight = (setupTime / this.gridTimeInterval) * this.eachCellHeight;
      const cellBDheight = (breakdownTime / this.gridTimeInterval) * this.eachCellHeight;

      if (newDlen < (obj.overLapped + 1)) {
        if (showoverlaptoggle) {
          newDlen = (obj.overLapped + 1);
        } else {
          newDlen = (obj.overLapped - obj.multiGroupOverlap + 1);
        }
      }
      if (this.FitToGrid) {
        const blockeElem = this.blockedCellRef.nativeElement;
        let HeaderLength = this.calendarGridService.gridGHeaderArray.length;
        // if (!this.NoOfAppts) {
        //   const currentData = [];
        //   let AllData = [];
        //    if (this._showOverlap) {
        //     AllData = fullobj.map(r => r.data.map(s => {
        //       let overlapped = 0;
        //       overlapped = s.data.reduce((acc, shot) => acc > shot.overLapped ? acc : shot.overLapped, 0);
        //       const tempOverlapped = (s.data.length > overlapped) ? s.data.length : overlapped + 1;
        //       return s.data.length ? tempOverlapped : 1;
        //     }));
        //   }else {
        //     AllData = fullobj.map(r => r.data.map(s => {
        //       let overlapped = 0;
        //       let multiGroupAppointments = _.filter(s.data, function (o) { return o.appointmentDetail.multiGroupId; }).length;
        //       multiGroupAppointments = (multiGroupAppointments > 1 ? multiGroupAppointments - 1 : 0);
        //       overlapped = s.data.reduce((acc, shot) => (acc > shot.overLapped ? acc : shot.overLapped), 0);
        //       const tempValue = (s.data.length > overlapped) ? s.data.length - multiGroupAppointments : overlapped - multiGroupAppointments + 1;
        //       return s.data.length ? tempValue : 1;
        //     }));
        //   }

        //   Object.keys(AllData[0]).forEach(function (c) {
        //     let temp = AllData.map(function (r) { return r[c]; });
        //     currentData.push(Math.max(...temp));
        //   });
        //   this.currentData = currentData;
        //   this.NoOfAppts = currentData ? currentData.reduce((a, b) => a + b) : HeaderLength;
        // }
        HeaderLength = this.NoOfAppts ? this.NoOfAppts : HeaderLength;
        widthSize = (blockeElem.clientWidth - 100) / HeaderLength;
        this.fitToGridWidth = widthSize;
        // this.gridHeaderArray.forEach((data, index) => {
        // const headerColumn: HTMLElement = <HTMLElement>document.getElementsByClassName('table--header__cell' + '_' + columnIndex)[0];
        // let width = (widthSize * this.currentData[columnIndex]) + 'px';
        // headerColumn.style.width = width;
        // headerColumn.style.maxWidth = width;
        // headerColumn.style.minWidth = width;
        // });

      } else {
        currentTableHeaderColumn.style.minWidth = this.currentData ? this.currentData[columnIndex] * defaultWidthSize + 'px' : defaultWidthSize + 'px';
      }

      let cellwidth: string = setfullwidth ? '' : widthSize + 'px';
      let cellwidthSB: string = setfullwidth ? '100%' : widthSize + 'px';
      let cellLeft: string = !showoverlaptoggle ? (widthSize * (obj.overLapped - obj.multiGroupOverlap)) + 'px' : (widthSize * obj.overLapped) + 'px';
      let hdrTDWidth: string;
      if (currentselectedDivTH['offsetWidth'] < (widthSize * newDlen)) {
        hdrTDWidth = setfullwidth ? '' : (widthSize * newDlen) + 'px';
      } else if (currentselectedDivTH['offsetWidth'] >= (widthSize * newDlen)) {
        hdrTDWidth = widthSize + 'px';
      } else {
        hdrTDWidth = setfullwidth ? '' : currentselectedDivTH['offsetWidth'] + 'px';
      }
      currentselectedDiv.style.width = cellwidth;
      currentselectedDiv.style.height = cellheight + 'px';
      currentselectedDiv.style.top = cellTop + 'px';
      currentselectedDiv.style.left = cellLeft;

      currentselectedSetupTimeDiv.style.width = cellwidthSB;
      currentselectedSetupTimeDiv.style.height = cellSUheight + 'px';
      currentselectedSetupTimeDiv.style.top = cellTop - cellSUheight + 'px';
      currentselectedSetupTimeDiv.style.left = cellLeft;

      currentselectedBreakDownDiv.style.width = cellwidthSB;
      currentselectedBreakDownDiv.style.height = cellBDheight + 'px';
      currentselectedBreakDownDiv.style.top = cellTop + cellheight + 'px';
      currentselectedBreakDownDiv.style.left = cellLeft;

      // currentselectedDivTH.style.minWidth = hdrTDWidth;
      obj.setCss = false;
    }
    return '';
  }
  /**
   * Appointment Tool Tip Event
   * @param eve - Event
   * @param appointmentObject - Appointment Object
   * @param rowIndex - row index
   * @param columnIndex - column index
   * @param cellObjectIndex - cell object index
   * @param celldata - cell data
   */
  agsysCellMouseover(eve: MouseEvent, appointmentObject: CalendarGridBodyProps, rowIndex: number, columnIndex: number) {
    if (!this.calendarGridService.EnableToolTip) return
    eve.stopImmediatePropagation();
    this.arrowRight = false;
    this.toolTipLeft = this.toolTipTop = 0;
    this.tooltipPlacementPos = '';
    const blockeElem = this.blockedCellRef.nativeElement;
    let widthSize = 200;
    let windowHeight = window.innerHeight;
    let MOshowoverlaptoggle: boolean = this.showOverlapFlag;
    let popoverContentHeight = document.getElementsByClassName('app_celltooltip')[0].querySelector('.popover').clientHeight + 15; // 15 Arrow Height
    const currentselectedDiv: number = document.getElementById(this.calendarGridService.CalendarGridType + '_AppointmentHDR_' + rowIndex + '_' + columnIndex).offsetWidth;
    const appgridleftSpace = document.getElementById(this.calendarGridId).offsetLeft;
    let appgriddivWidth = document.getElementById(this.calendarGridId).offsetWidth;
    let currentOverCellHeight = eve.currentTarget['clientHeight'];
    if (this.FitToGrid) {
      let HeaderLength = this.NoOfAppts ? this.NoOfAppts : this.calendarGridService.gridGHeaderArray.length;
      widthSize = (blockeElem.clientWidth - 100) / HeaderLength;
    }
    const tootalnofcellplaced: number = currentselectedDiv / widthSize;

    let approxcenterposition = 0;
    // fix for multiGroupOverlap in single cell
    if (MOshowoverlaptoggle) {
      if (!(appointmentObject.overLapped <= 0 && !appointmentObject.overlappedFlag)) {
        approxcenterposition = (tootalnofcellplaced - 1) * (eve.currentTarget['offsetWidth'] / 2);
      }
    } else {
      if (!(((appointmentObject.overLapped - appointmentObject.multiGroupOverlap) <= 0) && !appointmentObject.overlappedFlag)) {
        approxcenterposition = (tootalnofcellplaced - 1) * (eve.currentTarget['offsetWidth'] / 2);
      }
    }

    let bottom: boolean = ((windowHeight - (eve.pageY + (currentOverCellHeight - eve['layerY']))) > popoverContentHeight);

    let hiddentooltipleftcalc = (eve.pageX - eve['layerX']) - appgridleftSpace;
    let hiddentooltiprightcalc = (((eve.pageX - eve['layerX']) + eve.currentTarget['clientWidth']) - appgridleftSpace);
    if (hiddentooltipleftcalc < 0) {
      this.toolTipLeft = (eve.pageX - (eve['layerX'] + approxcenterposition)) - ((hiddentooltipleftcalc / 2) - 50); // 50 for side time section
    } else if (hiddentooltiprightcalc > appgriddivWidth) {
      this.toolTipLeft = (eve.pageX - (eve['layerX'] + approxcenterposition)) - (((hiddentooltiprightcalc - appgriddivWidth) / 2) + 100);
      this.arrowRight = true;
    } else {
      this.toolTipLeft = (eve.pageX - (eve['layerX'] + approxcenterposition));
    }
    if (bottom) {

      this.tooltipPlacementPos = "bottom";

      this.toolTipTop = (eve.pageY - eve['layerY']) + currentOverCellHeight + 5;
    } else {

      this.tooltipPlacementPos = "top";

      this.toolTipTop = (eve.pageY - eve['layerY']);
      if (this.toolTipTop < 300) {
        this.toolTipTop = 300;
      }
    }



    const clientName: string = appointmentObject.clientDetails ? `${appointmentObject.clientDetails.firstName} ${appointmentObject.clientDetails.lastName}` : '';
    const phoneNumber: string = appointmentObject.clientDetails?.phoneNumber ?appointmentObject.clientDetails.phoneNumber:"";
    const email: string = appointmentObject.clientDetails?.email? appointmentObject.clientDetails.emailId:"";
    const clientGender: string = appointmentObject.clientDetails ? appointmentObject.clientDetails.gender : '';
    const startTime: string = this.localization.LocalizeTime(appointmentObject.appointmentDetail.originalStartTime);
    const endTime: string = this.localization.LocalizeTime(appointmentObject.appointmentDetail.originalEndTime);
    const clientType: number = appointmentObject.clientDetails ?.clientType;
    const clientLinkId: string =appointmentObject.clientDetails?.clientLinkId;
    let displayIsHotelGuest = '';
    if (appointmentObject.appointmentIconDetail && appointmentObject.appointmentIconDetail.IsHotelGuest) {
      displayIsHotelGuest = '( ' + this.captions.HotelGuest + ' )';
    }
    this.slideService.cellTooltipdata = {
      serviceName: appointmentObject.serviceName,
      Status: appointmentObject.appointmentDetail.status,
      timDuration: startTime + '-' + startTime,
      clientName: clientName,
      gender: clientGender,
      Location: (appointmentObject.appointmentDetail.locationId),
      ID: appointmentObject.appointmentDetail.id,
      ContactNumber: appointmentObject.appointmentDetail.locationId,
      email: email,
      addOns: appointmentObject.appointmentAddOn ? appointmentObject.appointmentAddOn : '',
      groupName: appointmentObject.bookingName,
      clientLinkId: clientLinkId,
      sectionArr:
      [
        { 'iconKey': 'icon-timepicker', 'data': startTime + '-' + endTime, 'field': 'time' },
        { 'iconKey': (clientGender == 'Male' || clientGender == 'Female') ? `icon-${clientGender}-Sign` : 'icon-profile', 'data': clientName , 'Addinfo': displayIsHotelGuest, 'field': 'clientName', 'client' : clientType},
        { 'iconKey': 'icon-Phone', 'data':phoneNumber, 'field': 'phoneNumber'},
        { 'iconKey': 'icon-location ', 'data': appointmentObject.locationName, 'field': 'location' },
        { 'iconKey': 'icon-hash', 'data': 'ID# ' + appointmentObject.appointmentDetail.id, 'field': 'ID' },
        { 'iconKey': 'icon-woman', 'data': appointmentObject.therapistName, 'field': 'therapistName' },
        { 'iconKey': 'icon-Ticket-Confirmation','data':'#' + appointmentObject.appointmentDetail.confirmationId ? appointmentObject.appointmentDetail.confirmationId : '', 'field': 'confirmationNo'},
        { 'iconKey': 'icon-spa-package','data': appointmentObject.packageName ? appointmentObject.packageName : '', 'field': 'package'},
        { 'iconKey': 'icon-day-pass','data': appointmentObject.packageName ? appointmentObject.packageName : '', 'field': 'dayPass'},
        { 'iconKey': 'icon-Member_new','data':'#' + (clientType == 2) ? clientLinkId : '', 'field': 'MemberID'},
        { 'iconKey': 'icon-transaction', 'data': '#' + appointmentObject.appointmentDetail.transactionId ? appointmentObject.ticketNumber : '', 'field': 'transactionId' },
        { 'iconKey': 'icon-add', 'data': appointmentObject.appointmentAddOns, 'field': 'addOns' },
      ],
      comments: "Teststest" ?? appointmentObject.appointmentDetail ? appointmentObject.appointmentDetail.comments : '',
      pmsActivityStatus : appointmentObject && (appointmentObject.pmsActivityStatus==4 ||appointmentObject.pmsActivityStatus==10) ? appointmentObject.pmsActivityStatus:0,
    };
    if (appointmentObject.appointmentDetail.status === 'BREAK') {
      const removeField = ['ID', 'clientName', 'location', 'transactionId', 'addOns','confirmationNo','package','dayPass'];
      this.slideService.cellTooltipdata.sectionArr = this.slideService.cellTooltipdata.sectionArr.
        filter(x => {
          return !removeField.some(name => name === x.field);
        });
    }
    if(appointmentObject.appointmentDetail.status !== 'BLOCK' || phoneNumber === '' )
    {
      const removeField = ['phoneNumber'];
      this.slideService.cellTooltipdata.sectionArr = this.slideService.cellTooltipdata.sectionArr.
        filter(x => {
          return !removeField.some(name => name === x.field);
        });
    }
    if(!appointmentObject.appointmentIconDetail.isSpaPackage ||appointmentObject.appointmentDetail.status === 'BLOCK' ||appointmentObject.appointmentDetail.dayPassId > 0)
    {
      const removeField = ['package'];
      this.slideService.cellTooltipdata.sectionArr = this.slideService.cellTooltipdata.sectionArr.
        filter(x => {
          return !removeField.some(name => name === x.field);
        });
    }
    if(!appointmentObject.appointmentIconDetail.isSpaPackage ||appointmentObject.appointmentDetail.status === 'BLOCK' ||appointmentObject.appointmentDetail.dayPassId == 0)
      {
        const removeField = ['dayPass'];
        this.slideService.cellTooltipdata.sectionArr = this.slideService.cellTooltipdata.sectionArr.
          filter(x => {
            return !removeField.some(name => name === x.field);
          });
      }
  }

  /**
   * This method returns minute difference bw two datetime
   * @param startDate - Start Date Time
   * @param endDate - End Date Time
   */
  getMinutesBetweenDates(startDate: Date, endDate: Date): number {
    const difference: number = endDate.getTime() - startDate.getTime(); // This will give difference in milliseconds
    let minutes = Math.round(difference / 60000);
    const timeZoneOffsetDiff = endDate.getTimezoneOffset() - startDate.getTimezoneOffset();
    return timeZoneOffsetDiff > 0 ? minutes - timeZoneOffsetDiff : minutes;
  }

  toggleMoreLess(rowIndex: number, columnIndex, btnName: string,obj) {
    this.moreObj = obj.slice(3,obj.length);
    // let cellclass = document.getElementsByClassName(this.calendarGridService.CalendarGridType + '_appointmentBox_' + rowIndex + '_' + columnIndex)[0];
    // let moreclass = document.getElementsByClassName(this.calendarGridService.CalendarGridType + '_appointmentBoxShowMore_' + rowIndex + '_' + columnIndex)[0];
    // let lessclass = document.getElementsByClassName(this.calendarGridService.CalendarGridType + '_appointmentBoxShowLess_' + rowIndex + '_' + columnIndex)[0];
    // let alldayserviceclass: any = document.getElementsByClassName(this.calendarGridService.CalendarGridType + '_alldayservicename_' + rowIndex + '_' + columnIndex);
    // if (btnName == 'more') {
    //   for (let alldayserviceclassItem of alldayserviceclass) {
    //     if (alldayserviceclassItem.classList.contains('visiblityHidden')) {
    //       alldayserviceclassItem.classList.remove('visiblityHidden')
    //       alldayserviceclassItem.classList.add('visiblityShow');
    //     }
    //   }

    //   cellclass.classList.remove('h70');

    //   moreclass.classList.remove('d-block');
    //   moreclass.classList.add('d-none');
    //   lessclass.classList.remove('d-none');
    //   lessclass.classList.add('d-block');
    // }
    // else if (btnName == 'less') {

    //   for (let alldayserviceclassLess of alldayserviceclass) {
    //     if (alldayserviceclassLess.classList.contains('visiblityShow')) {
    //       alldayserviceclassLess.classList.add('visiblityHidden');
    //       alldayserviceclassLess.classList.remove('visiblityShow');
    //     }
    //   }
    //   cellclass.classList.add('h70');

    //   moreclass.classList.add('d-block');
    //   moreclass.classList.remove('d-none');
    //   lessclass.classList.add('d-none');
    //   lessclass.classList.remove('d-block');
    // }
  }

  calendarTableHeaderChange(hdrObj: any) {
    if(this.enableDrag && !this.CalendarGridProperty?.isViewOnly){
      this.lodertimeflag = false;
      this.showOverlapFlag = true;
      this.gridHeaderDDArray = this.gridHeaderArray;
      this.tableDisplayDDArray = cloneDeep(this.tableDisplayArray);
      let newhdrObj: any[] = [];
      this.selectedTherapist = this.gridHeaderArray.find(x => x.id === hdrObj.id);
      newhdrObj.push(hdrObj);
      this.imageContent = hdrObj.image;
      this.calendarGridService.agsysResetHeaderFlag = true;
      let hdrkey = this.CalendarGridProperty.viewTypeSelected == GridViewType.TherapistView ? this.calendarGridService.GridHeaderKeyForTherapist : this.calendarGridService.GridHeaderKeyForLocation;
      let newhdrarray = this.calendarGridService.BuildCalendarGridHeader(newhdrObj, hdrkey);
      this.selectedHeaderValue = newhdrarray[0];
      this.gridHeaderArray = newhdrarray;
      this.tableDisplayArray = this.tableDisplayArray.map(x => {
        x.data = x.data.filter(y => {
          return y.hdr.id == hdrObj.id;
        }); return x;
      });
      setTimeout(() => { this.calendarGridService.reloadGridStyleCss(this.tableDisplayArray); this.lodertimeflag = true; }, (1));
      this.emitHeaderClick.emit(true);
    }
  }

  resetHeaderFilter(): void {
    this.lodertimeflag = false;
    this.showOverlapFlag = this.CalendarGridProperty && this.CalendarGridProperty.EnableShowOverlapFlag;
    this.calendarGridService.agsysResetHeaderFlag = false;
    this.gridHeaderArray = this.gridHeaderDDArray;
    this.tableDisplayArray = this.tableDisplayDDArray;
    setTimeout(() => { this.calendarGridService.reloadGridStyleCss(this.tableDisplayArray); this.lodertimeflag = true; }, (1));
    this.emitHeaderClick.emit(false);
  }

  changeGridDD(selVal: any): void {
    this.gridHeaderArray = this.gridHeaderDDArray;
    this.tableDisplayArray = this.tableDisplayDDArray;
    this.calendarTableHeaderChange(selVal)
  }

  //Auto scroll down th time start
  autoscrolltocurrtime(): void {
    // This will work only in linux timezone format
    // let propertyCurDateTime = this.utils.getPropertyTime();

    // let currentMin = parseInt(moment(propertyCurDateTime).format("mm"));
    let date = this.localization.convertDateTimeToAPIDateTime(new Date(this.propertyInfo.CurrentDate)).split('T');
    // let time = this.localization.convertDateTimeToAPIDateTime(new Date()).split('T');
    let time = this.localization.convertTimePartToAPIDateTime(this.localization.getLocalizedPropertyCurrentTime(false)).split('T')
    let currentDate = new Date (date[0]+"T"+time[1]);
    let currentMin = currentDate.getMinutes();
    let currentHour = currentDate.getHours()

    let autotimeinterval = this && this.gridTimeInterval ? this.gridTimeInterval : 0;
    let diffinMin = currentMin / autotimeinterval;
    let elms, curtime;
    if (!isNaN(diffinMin)) {
      let newaddMin = autotimeinterval * Math.floor(diffinMin);
      currentDate.setHours(currentHour, newaddMin, 0, 0)
      curtime = this.localization.LocalizeTime(currentDate);
      elms = document.getElementById(curtime);
    }
    if (elms) {
      let scrolltoPos = document.getElementById(curtime).offsetTop - this.calenderGrid?.nativeElement.querySelector('#grid-header').clientHeight;
      document.getElementById(this.calendarGridId).scrollTop = scrolltoPos - 5;
      var convertedTimeforClass = curtime.replace(':', '\\:');
      let highlighting;
      highlighting = document.querySelectorAll('.T'+convertedTimeforClass)
      let highlightedClass = document.querySelectorAll('.onLoadTime')
      if(highlightedClass.length > 0) {
        highlightedClass[0].classList.remove('onLoadTime');
      }
      highlighting.forEach(value => {
          value.children[0].firstChild.classList.add('onLoadTime');
        })
        this.autoHighlightCurentTime()
      this.loadScrollToCurrTime = true;
    }
    else {
      this.isLineIndicationEnabled = false;
    }
    if (this.sideContentArray.indexOf(curtime) === -1) {
      this.loadScrollToCurrTime = true;
    }

  }

  autoHighlightCurentTime() {
    let date = this.localization.convertDateTimeToAPIDateTime(new Date(this.propertyInfo.CurrentDate)).split('T');
    // let time = this.localization.convertDateTimeToAPIDateTime(new Date()).split('T');
    let time = this.localization.convertTimePartToAPIDateTime(this.localization.getLocalizedPropertyCurrentTime(false)).split('T')
    let currentDate = new Date (date[0]+"T"+time[1]) ;
    let currentMin = currentDate.getMinutes();
    let currentHour = currentDate.getHours()
    let autotimeinterval = this && this.gridTimeInterval ? this.gridTimeInterval : 0;
    let diffinMin = currentMin / autotimeinterval;
    let elms, curtime;
    if (!isNaN(diffinMin)) {
      let newaddMin = autotimeinterval * Math.floor(diffinMin);
      currentDate.setHours(currentHour, newaddMin, 0, 0)
      curtime = this.localization.LocalizeTime(currentDate);
      elms = document.getElementById(curtime);
    }
    if (elms) {
      let scrolltoPos = document.getElementById(curtime).offsetTop - this.calenderGrid?.nativeElement.querySelector('#grid-header').clientHeight;
      let date = this.localization.convertDateTimeToAPIDateTime(new Date(this.propertyInfo.CurrentDate)).split('T');
      let time = this.localization.convertTimePartToAPIDateTime(this.localization.getLocalizedPropertyCurrentTime(false)).split('T')
      let presentDate = new Date (date[0]+"T"+time[1]) ;
      let presentDateasMinutes = presentDate.getMinutes();
      let currentDateasMinutes = currentDate.getMinutes();
      let rowHeight = document.getElementById(curtime).clientHeight;
      let splitTime = rowHeight/autotimeinterval;
      let differenceinMinutes = presentDateasMinutes - currentDateasMinutes;
      let highlightClass = document.querySelectorAll('.highlight-current-time');
      highlightClass.forEach((x,index) => {
        if(presentDateasMinutes > currentDateasMinutes) {
          (highlightClass[index]  as HTMLElement).style.top = scrolltoPos + (splitTime * differenceinMinutes) + 38 +'px';
        }
        else if (presentDateasMinutes == currentDateasMinutes) {
          (highlightClass[index]  as HTMLElement).style.top = scrolltoPos + 38 +'px';
        }
        (highlightClass[index] as HTMLElement).style.visibility = "visible"
      })
    }
  }
  OpenpopUp(data: any, therapistId: number, therpaistName: string) {

    let dialogRef = this.dialog.open(StaffSchedulePopupComponent, {
      hasBackdrop: true,
      width: "60%",
      height: "85%",
      data: {
        value: data,
        closebool: true,
        TherpaistName: therpaistName,
        TherapistId: therapistId,
        imageContent: this.imageContent, 
        isFromSpaGrid : true
      },
      disableClose: true,
      panelClass: "action-dialog-overlay",
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe((result) => {
      this.calendarGridService._reloadGrid$.next();
    });
  }

  detailsClick(value: any) {
    this.onSpecialitiesClick.emit(value)
  }

  OpenEditStaffScheduleDialog(value: any,editschedules?: any, from?: any) {
    console.log(value);
    this.staffEnable = false;

    var selectData;
    if(this.isWeekEnabled) {
      if(!from) {
        this.getScheduleforWeekViewedit.emit(value);
      }
      else {
        selectData = editschedules[0];
      }
    } else {
      selectData = this.currentDateValue.find(
        (f) => f.therapistId == value.id
      );
    }
    let element = selectData.availableTime;
    let final = element.map(y => this.bindData(selectData,y))
    this.OpenpopUp(final, value.id, value.headerObject.headerObject.name);
  }

  bindData(selectedData: any, filterData: any): any {
    return {
      availableTime: {
        availableOnCall: filterData.availableOnCall, createdAt: filterData.createdAt,
        fromTime: filterData.fromTime, id: filterData.id,
        locationId: filterData.locationId, lunchId: filterData.lunchId,
        therapistScheduleId: filterData.therapistScheduleId, toTime: filterData.toTime,
      },
      breakTime: selectedData.breakTime, createdAt: selectedData.createdAt,
      id: selectedData.id, isOff: selectedData.isOff, scheduleDate: selectedData.scheduleDate, therapistId: selectedData.therapistId, comments: selectedData.comments
    };
  }

  deleteSchedule(value: any) {
    this.staffEnable = false;
    const selectData = this.currentDateValue.find(
      (f) => f.therapistId == value.id
    );
    selectData.availableTime.forEach(element => {
      element.fromTime = this.localization.convertDateTimeToAPIDateTime(element.fromTime);
      element.toTime = this.localization.convertDateTimeToAPIDateTime(element.toTime);
    });
    let final = {
      modifyAvailable: this.getModifyAvailableStatus(value),
      off: selectData.isOff,
      onCall: false,
      scheduleTime: selectData.availableTime,
      breakTime: selectData.breakTime,
      createdAt: selectData.createdAt,
      id: selectData.id,
      isOff: selectData.isOff,
      scheduleDate: selectData.scheduleDate,
      therapistId: selectData.therapistId,
      comments: selectData.comments
    };
    this.staffService.DeleteStaffSchedule(final, value.id);
  }

  getModifyAvailableStatus(value: any): boolean {
    if (this.CalendarGridProperty.viewTypeSelected == "LV") {
      return false;
    }
    else {
      const therapistId = value.id;
      const data = this.currentDateValue.find(
        (f) => f.therapistId == therapistId
      );
      let fromTime = this.utils.getDate(data?.scheduleDate);
      return (this.localization.getDate(this.localization.getDate(fromTime).setHours(0, 0, 0, 0)
      ) >= this.propertyInfo.CurrentDate
      );
    }
  }

  GetCellHeightByType(arg) {
    // let highlightClass = document.querySelectorAll('.highlight-current-time');
    //     highlightClass.forEach((x,index) => {
    //       (highlightClass[index] as HTMLElement).style.top = 0+'px';
    //     })
    switch (arg) {
      case DefaultGridType.Standard:
        return 90;
      case DefaultGridType.Minimal:
        return 35;
      case DefaultGridType.Compact:
        return 20;
    }
  }

  trackByFn(idx, el) {
    return idx;
  }

  dateChangeforWeekview(columnIndex) {
    let updatedDate =  new Date(this.gridHeaderArray[columnIndex]['dateObj']);
    this.selectedDateforWeekview.emit(updatedDate);
    return updatedDate;
  }
}
