import { Component, OnInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'lib-number-calc-renderer',
  templateUrl: './number-calc-renderer.component.html',
  styleUrls: ['./number-calc-renderer.component.scss']
})
export class NumberCalcRendererComponent implements OnInit, ICellEditorAngularComp {
  textValue: any;
  params: any;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    this.textValue = params.value;
    this.params = params;
  }

  init(params) {
  }

  getValue(): any {
    console.log('Changed data', this.textValue);
    return this.textValue;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }
}
