import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    AddNewTypeConfig, GridConfiguration, ItemQueryListConfiguaration,
    LookupConfiguration, SearchShowMoreConfig, TransactionConfiguration
} from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { TransQueryLookupConfig } from './query-list-config.interface';
import _ from 'lodash';
import { QueryTransaction } from 'src/app/eatecui/source/transaction/shared/interface/query-details';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { MasterInventoryManagement, SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { QueryParams } from '../api-query-params.interface';

export const MasterQueryListLookupTabClick: TransQueryLookupConfig = async (
    addNewTypeConfig: AddNewTypeConfig,
    transactionConfigurationData: TransactionConfiguration, httpService: HttpService, translateService: TranslateService,
    headerEntity: any, detailEntity: any, transactionService: TransactionService, selectedTransaction: string,
    OdataGlobalEntityModel: OdataGlobalEntity, masterManagementService: MasterManagementService,
    selectedId?: Array<any>, selectedList?: any, showMoreConfig?: SearchShowMoreConfig): Promise<any> => {
    try {
        const transactionJsonData = transactionConfigurationData;
        // const getHeaderOdataKey = transactionService.getOdataKey(transactionJsonData, 'ViewHeaderOdataKey');
        const getHeaderOdataKey = 'Ingredients';
        const queryTrasacationHeaderEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity,
            getHeaderOdataKey);
        const getDetailOdataKey = transactionService.getOdataKey(transactionJsonData, 'ViewDetailOdataKey');
        const queryTransactionDetailEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity,
            getDetailOdataKey);
        const jsondata = transactionService.transactionConfigurationData;
        const searchByKey = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds;
        const searchListArray = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList;
        let endPointUrl = '';
        endPointUrl = `/inventory/api/${addNewTypeConfig.EndPointUrl}`;
        let LocId = 0;
        const ModuleName = transactionJsonData.Name;
        switch (ModuleName) {
            case 'Transfer':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'spoilage':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Requisition':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'Purchase Request':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['RequestLocId'];
                break;
            case 'Physical Inventory':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Receiving':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Purchase Order':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Item Pars':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
        }
        const lookUpData = {} as LookupConfiguration;
        lookUpData.Id = 0;
        lookUpData.TransType = transactionJsonData.TransactionType;
        lookUpData.FromLocationId = LocId;
        lookUpData.ToLocationId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'];
        lookUpData.flgSearch = true;
        lookUpData.FetchAll = false;
        lookUpData.Skip = showMoreConfig ? showMoreConfig.Skip : 0;
        lookUpData.Take = showMoreConfig ? showMoreConfig.Take : 100;
        lookUpData.IsQuery = true;
        if (addNewTypeConfig.Name === 'Class') {
            lookUpData.ClassIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Type') {
            lookUpData.TypeIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Packages') {
            lookUpData.PackageIds = searchByKey;
        }
        const x = await httpService.PosthttpMethod(endPointUrl, lookUpData).toPromise();
        const resultSet = x['PackageResults'] ?
            x['PackageResults'] : x['Data']['ent']['ResultData'] ? x['Data']['ent']['ResultData']
                : x['Data']['ent']['PackageResults'] ?
                    x['Data']['ent']['PackageResults'] : [];
        // if (resultSet.length > 0) {
        const responseData = [];
        const itemHeaderList = [];
        let resultData = [];
        let values;
        if (addNewTypeConfig.Name === 'Inventory') {
            resultSet.forEach((element: any) => {
                const dataObject = _.mapKeys(element, function (v, k) {
                    return k.charAt(0).toUpperCase() + k.slice(1);
                });
                responseData.push(dataObject);
            });
            values = _(responseData)
                .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                .map((ItemLists: any, Id: any) => ({
                    Id: parseInt(Id, 10),
                    ItemLists,
                    Name: ItemLists[0][addNewTypeConfig.Url]['Name']
                }))
                .value();
        } else {
            resultData = x['PackageResults'] ?
                x['PackageResults'] :
                x['Data']['ent']['PackageResults'] ?
                    x['Data']['ent']['PackageResults'] : [];
            const loopvalues = Object.keys(resultData);
            values = resultData[loopvalues[0]];
            // values = [];
            // loopvalues.forEach(pack => {
            //     const data = {} as QueryDatas;
            //     data.Id = pack;
            //     data.Name = pack;
            //     data.ItemList = resultData[pack];
            //     values.push(data);
            // });
        }
        const TabIndex = selectedId.findIndex(y => y === searchByKey[0]);
        const ItemList = values;
        const TotalCount = x['TotalCount'] ? x['TotalCount'] :
            x['Data']['ent']['TotalCount'];
        const GridDataRows = QueryTransaction.QueryIngredientsGridData(queryTrasacationHeaderEntity,
            queryTransactionDetailEntity, transactionJsonData, httpService, translateService, '', ItemList);
        GridDataRows.forEach(element => {
            element['GridId'] = `${TabIndex}_` + (element.Id ? element.Id : element.IngredientId
                ? element.IngredientId : element.ProductId);
        });
        return [GridDataRows, TotalCount];

    } catch (error) {
        console.error(error);
    }
};

export interface QueryDatas {
    Id: string;
    Name: string;
    ItemList: Array<any>;
}