import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpServiceCall } from '../../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../../shared/utilities/spa-utilities';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { SPAConfig } from 'src/app/core/config/SPA-config';
@Component({
  selector: 'app-client-listing',
  templateUrl: './client-listing.component.html',
  styleUrls: ['./client-listing.component.scss']
})
export class ClientListingComponent implements OnInit {
  CilentListingFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidSelection = new EventEmitter();
 
  
radioFilter: { id: number; name: string; }[];
  showDate: boolean = true;
  commonCaptions: any;
  isCategory : boolean = false;
  disableGenerateBtn: boolean;
  minEndDate : Date;
  minDefaultDate = new Date('01/01/1900');
  maxDate : Date;
  placeholderFormat: string;
  floatLabel: string;
 
constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private http: HttpServiceCall, private utils: SpaUtilities, public business: ReportBusinessService, private dataService: ReportDataService,private PropertyInfo: SpaPropertyInformation,private spaConfig: SPAConfig) { 
  this.placeholderFormat = this.localization.inputDateFormat;
  this.floatLabel = this.localization.setFloatLabel;
}
  
ngOnInit() {
  this.radioFilter = [{id: 0, name: this.captions.byLastName}, {id: 1, name: this.captions.byLastVisit}];
  let currentDate = this.PropertyInfo.CurrentDate;
  currentDate.setDate(currentDate.getDate()-1);
  this.CilentListingFormGrp = this.fb.group({
       itemFilter:0,
       excludeprofilephone: true,
       excludeprofileemail: true,
       startDate: [currentDate],
       endDate: [currentDate],
  });
  this.commonCaptions = this.spaConfig.captions.common;
  this.formReady.emit(this.CilentListingFormGrp);
  this.IsValidSelection.emit();
  this.maxDate = this.CilentListingFormGrp.controls.startDate.value;
  this.minEndDate = this.CilentListingFormGrp.controls.startDate.value;
  
}

OnFromDateValueChange(arg) {
  if (this.isStartDateExceedsEndDate()) {
    this.CilentListingFormGrp.controls.endDate.setValue(this.CilentListingFormGrp.controls.startDate.value);
  }

  this.minEndDate = this.CilentListingFormGrp.controls.startDate.value;
}

isStartDateExceedsEndDate(): boolean {
  const startDate = this.CilentListingFormGrp.controls.startDate.value;
  const endDate = this.CilentListingFormGrp.controls.endDate.value;
  return this.resetTime(startDate) > this.resetTime(endDate);
}

resetTime(date: Date): Date {
  return new Date(date.setHours(0, 0, 0, 0));
}
}