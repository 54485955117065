import { Injectable } from "@angular/core";
import { ReportAPIOptions, ReportDownloadFormat,ReportAPIModel } from '../../retail-reports/business/report.modals';
import { FastReportBusinessService } from '../../retail-reports/fast-report/fastreport.business.service';
import { SPAConfig } from "../../common/config/SPA-config";
import { Host } from "../../shared/globalsContant";
import * as saveAs from 'file-saver'
import { RetailLocalization } from '../../common/localization/retail-localization';
import { HttpServiceCall } from '../../shared/service/http-call.service';
import { PrintManagerDataService } from "src/app/common/dataservices/printmanager.data.services";
import { PrintDocumentInput } from "src/app/common/Models/common.models";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });    
    saveAs(blob, fileName);
};
@Injectable()
export class CaddyShackService {
    constructor( private spaConfig: SPAConfig ,private http: HttpServiceCall, private localization: RetailLocalization,private _printManager :PrintManagerDataService,private Utilites : RetailUtilities) {

    }

    public printCaddyShack(format: ReportDownloadFormat, options: ReportAPIOptions, callBack?: any,caddyShackPrinter?: PrintDocumentInput): void {
        this.downloadReport(format, options, true, caddyShackPrinter,callBack);
     
    }
    public async downloadReport(format: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean = false,caddyShackPrinter?:PrintDocumentInput, callBack?: any, isDownloadWithPreview?: boolean) {
        let downloadURL = this.spaConfig.getHost(Host.reporting) + '/'  + this.spaConfig.getUrl("GetReport");
        let autoPrint =  await this.validatePrinterConfiguration(caddyShackPrinter)
        format = autoPrint ?  'HTML' : format;
        caddyShackPrinter ? caddyShackPrinter.printFormat = "IMAGE" : undefined;
        this.downloadFile(downloadURL, format, options, showPrintDialog,autoPrint, callBack, isDownloadWithPreview , caddyShackPrinter);
    }
   
    private async downloadFile(url: string, type: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean,autoPrint: boolean, callBack?: any, isDownloadWithPreview?: boolean, caddyShackPrinter?:PrintDocumentInput): Promise<void> {
        let fileName: string;
        if (type == "PDF") {
            fileName = `${options.code}.pdf`
        }
        else if (type == "WORD") {
            fileName = `${options.code}.docx`
        } else if (type == "EXCEL" || type == "RAWDATA") {
            fileName = `${options.code}.xlsx`
        } else if (type == "IMAGE") {
            fileName = `${options.code}.jpeg`
        }
        options.format = type;
        let apiModel: ReportAPIModel = this.createAPIOptions(options);
      
            this.http.putHTTPBlobData(url, apiModel).subscribe(async res => {
                if (showPrintDialog || isDownloadWithPreview) {
                    this.showOrPrintFile(res,autoPrint,caddyShackPrinter,options.code);
                    if (callBack) {
                        callBack();
                    }
                }
                if (!showPrintDialog || isDownloadWithPreview) {
                    saveFile(res, fileName);
                }
            });
       
    }
    private async validatePrinterConfiguration(receiptPrinter?: PrintDocumentInput): Promise<boolean>
    {
        let printerManagerURI = this.localization.GetPrinterManagerURI();
        let autoPrint=true;
        if (!printerManagerURI || printerManagerURI == '' || !receiptPrinter || !receiptPrinter.printerName || receiptPrinter.printerName == '') {
            autoPrint=false;
        }
        if(autoPrint)
        {
            try{
                let healthCheck = await this._printManager.PrinterManagerHeartBeat(printerManagerURI);
                autoPrint = true;
            }
            catch(e)
            {
                autoPrint = false;
                this.Utilites.showError(this.localization.captions.common.NoPrinterFound);
            }

        }
        return autoPrint;
    }
    async showOrPrintFile(res: any,autoPrint: boolean,caddyShackPrinter: PrintDocumentInput,fileName: string) {     
        let printerManagerURI = this.localization.GetPrinterManagerURI();
        if (!autoPrint) 
        {   
            this.showFile(res);
        }   
        else
        {           
            let byte:any;
            byte = await this.Utilites.getByteData(res);
            caddyShackPrinter.reportData=byte;
            caddyShackPrinter.documentName=fileName;
            this._printManager.Print(printerManagerURI, caddyShackPrinter);
        }     
                
    }
     async showFile(res: any) {     
        const newBlob = new Blob([res], { type: 'application/pdf' });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
            return;
        }
        const loadingContainer = document.getElementById('cover-spin');
        if (loadingContainer != null) {
            loadingContainer.style.display = 'none';
        }
        const data = window.URL.createObjectURL(newBlob);
        let newTab: Window = window.open(data);
        newTab.focus();   
        newTab.print();  
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);       
                
    }

     createAPIOptions(options: ReportAPIOptions): ReportAPIModel {
        let _format = options.format ? options.format : "HTML";
        return {
            code: options.code,
            format: options.format ? options.format : "HTML",
            downloadFileName: "Download",
            parameters: this.arrayToObject(options.params,_format,true),
            uRIParams: this.arrayToObject(options.URIParams,_format,false),
            filterBody: options.filters,
            dateFormat: this.localization.dateFormat
        };
    }
    formatArray : any = { 'PDF':0, 'HTML':1,'EXCEL':2,'WORD':3,'IMAGE':4}
     arrayToObject(objectArr: any[], format:any, includeFormat:boolean): { [key: string]: string } {
        var result = {};
        objectArr.forEach(o => {
            result[Object.keys(o)[0]] = Object.values(o)[0];
        });
        if(includeFormat){
            result['pFormat'] = this.formatArray[format];
          }
        return result;   
    }


}
