import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { AppointmentpopupService } from '../service/appointmentpopup.service';
import { MatDialog } from '@angular/material/dialog';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SpaUtilities } from '../utilities/spa-utilities';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import { BaseResponse, AppointmentFilterOutput, ClientScreenProperties, LocationViewModel, TherapistViewModel, WaitListAppointmentInfo, AppointmentWaitListClient, WaitListPriority, ServiceViewModel } from '../business/shared.modals';
import { WaitListService } from '../addwaitlist-popup/waitlist-popup.service';
import { appointmentService } from '../service/appointment.service';
import { ViewMoreServiceService } from '../view-more/view-more-service.service';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { GuaranteeMethodBusiness } from '../appointment-popup/create-appointment/guarantee-method/guarantee-method.business';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import * as global from '../globalsContant';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { AgTimeConfig } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'add-waitlist',
  templateUrl: './add-waitlist.component.html',
  styleUrls: ['./add-waitlist.component.scss'],
  providers: [GuaranteeMethodBusiness],
  encapsulation: ViewEncapsulation.None
})
export class AddwaitlistComponent implements OnInit, OnDestroy {
  minDate: any;
  sgCount: number;
  priority: any = [];
  FormGrp: any;
  ServiceGroups: any[] = [];
  minTher = 1;
  maxTher = 1;
  therapistCanBeAdded = true;
  StaffDropDownFormArr: any = [];
  captions: any = this.localization.captions.bookAppointment;
  commonCaptions: any = this.localization.captions.common;
  utilsCaptions: any = this.localization.captions.utilities;
  IsTherapistSelectionValid: boolean;
  waitlistDetails: any = [];
  IsReadOnly = false;
  filterData: AppointmentFilterOutput;
  services: ServiceViewModel[] = [];
  location: LocationViewModel[] = [];
  therapist: TherapistViewModel[] = [];
  minFromDate: Date;
  allLocations: any;
  @Input("clientBlockInfos")
  set ClientBlockInformation(clientBlockInfo: any) {
    if (clientBlockInfo && clientBlockInfo.length > 0) {
      this.ClientBlockValidationTrigger();
    }
  }

  isTherapistAddedWhileLoad: boolean = true;
  selectedServiceGrp: any[] = [];
  therapistHelperText = '';
  minToDate: Date;
  appointmentInfo: WaitListAppointmentInfo;
  errStart: any = this.captions.StartDate;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  timeFormat: number;
  placeholderFormat: string;
  poppedTherapistIndex = 0;
  isTherapistDirty = false;
  isServiceDirty = false;
  overrideClientBlockForTherapist: any = [];
  overrideClientBlockForService: any = [];
  floatLabel:string;
  startTimeInputs: AgTimeConfig;
  endTimeInputs: AgTimeConfig;

  constructor(public appointmentPopupService: AppointmentpopupService, private dialog: MatDialog, public fb: UntypedFormBuilder, public localization: SpaLocalization,
    public Form: UntypedFormBuilder, public utils: SpaUtilities, public waitListService: WaitListService,
    public apptService: appointmentService, public propertyInfo: SpaPropertyInformation, public _ViewMoreServiceService: ViewMoreServiceService,
    private http: HttpServiceCall, private guaranteePaymentMethodBusiness: GuaranteeMethodBusiness, private _quickLoginUtil: QuickLoginUtilities) {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
    this.FormGrp = Form.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      starttime: ['', Validators.required],
      endtime: ['', Validators.required],
      location: '0',
      therapist: '0',
      service: ['', Validators.required],
      clientpriority: 1,
      comments: '',
      StaffDropDownFormArr: Form.array([this.createTherapistDropDown()])
    });
    this.startTimeInputs = {
      className: '',
      form: this.FormGrp,
      formControlName: 'starttime',
      placeHolder: this.utilsCaptions.StartTime,
      isTimeRequired: true,
      automationId:"'Txt_AddWaitlist_starttime'"
    };
    this.endTimeInputs = {
      className: '',
      form: this.FormGrp,
      formControlName: 'endtime',
      placeHolder: this.utilsCaptions.EndTime,
      isTimeRequired: true,
      automationId:"'Txt_AddWaitlist_endtime'"
    };
    waitListService.appointmentDetailFormGroup = this.FormGrp;
    this.ServiceGroups = apptService.managementData['ServiceGroup'];
    this.minToDate = this.propertyInfo.CurrentDate;
    this.priority = [{ 'id': WaitListPriority.High, 'name': this.localization.captions.bookAppointment.WaitListPriorityHigh },
    { 'id': WaitListPriority.Medium, 'name': this.localization.captions.bookAppointment.WaitListPriorityMedium },
    { 'id': WaitListPriority.Low, 'name': this.localization.captions.bookAppointment.WaitListPriorityLow }]
    this.minFromDate = propertyInfo.CurrentDate;
    this.timeFormat = this.localization.getTimeFormat();
  }

  createTherapistDropDown() {
    return this.Form.group({
      therapist: '0',
    });
  }

  emitedValue(event) {
    this.toggleButtonClick(event[0], event[1], event[2]);
  }
  toggleButtonClick(e, f, givenObj) {
    this.selectedServiceGrp = this.utils.getToggleFilter(this.ServiceGroups, this.selectedServiceGrp, givenObj);
    this.FilterResources(e, 'servicegroup')
  }

  checkForMinStaffValidation(): boolean {
    let uniqueTherapistArr = [];
    if (this.appointmentPopupService.clientScreenProperties.selectedStaff) {
      uniqueTherapistArr = this.utils.removeDuplicates(this.appointmentPopupService.clientScreenProperties.selectedStaff);
      return uniqueTherapistArr.length >= this.minTher;
    }
    return false;
  }

  async ngOnInit() {

    this.SetFilters();

    if (this.waitListService.appointmentDetail) {
      this.appointmentInfo = this.waitListService.appointmentDetail;
      this.FormGrp.controls.fromdate.setValue(this.getOnlyDate(this.appointmentInfo.startDate));
      this.FormGrp.controls.todate.setValue(this.getOnlyDate(this.appointmentInfo.endDate));
      this.FormGrp.controls.starttime.setValue(moment(this.appointmentInfo.startTime, this.localization.timeFormat).format(this.localization.timeFormat));
      this.FormGrp.controls.endtime.setValue(moment(this.appointmentInfo.endTime, this.localization.timeFormat).format(this.localization.timeFormat));
      this.FormGrp.controls.service.setValue(this.appointmentInfo.serviceId);
      this.appointmentInfo.locationId == 0 ? this.FormGrp.controls.location.setValue('0') : this.FormGrp.controls.location.setValue(this.appointmentInfo.locationId);
      this.FormGrp.controls.clientpriority.setValue(this.appointmentInfo.priority);
      this.FormGrp.controls.comments.setValue(this.appointmentInfo.comments);
      if (this.appointmentInfo.serviceId > 0) {
        this.maxTher = this.GetMinMaxCount(this.appointmentInfo.serviceId, 'THERAPIST', 'MAX');
        this.minTher = this.GetMinMaxCount(this.appointmentInfo.serviceId, 'THERAPIST', 'MIN');
        this.SetTherapistHelperText(this.appointmentInfo.serviceId);
        this.SetClientHelperText(this.appointmentInfo.serviceId);
      }
      let therapist: number[] = [];
      if (this.appointmentInfo.client && this.appointmentInfo.client.length > 0) {
        therapist = this.appointmentInfo.client.map(r => r.therapistId);
        therapist = Array.from(new Set(therapist)); // Unique
      }
      this.SetTherapistsDropdown(therapist);
      //this.selectedServiceGrp = this.appointmentInfo.selectedServiceGroup;
      if (this.appointmentInfo.startDate) {
        this.minToDate = this.getOnlyDate(this.appointmentInfo.startDate);
      }
      this.initalizeGuaranteeMethodLocalValues();
    }
    else {
      this.waitListService.InitializeData();
      this.appointmentInfo = this.waitListService.appointmentDetail;
      this.appointmentInfo.selectedServiceGroup = this.selectedServiceGrp;
    }

    if (this.waitListService.action == global.OperationType.Edit) {
      this.minFromDate = this.getOnlyDate(this.appointmentInfo.startDate).setHours(0, 0, 0, 0) > this.propertyInfo.CurrentDate.getTime() ? this.propertyInfo.CurrentDate : this.getOnlyDate(this.appointmentInfo.startDate);
    }

    this.OnFormChanges();
    if (this.waitListService.action == global.OperationType.Edit) {
      this.validateForm();
    }
    this.allLocations = await this.LoadAllLocations();
    this.appointmentPopupService.GetAllVipTypes();
    this.FormGrp.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(_res => {
      this.validateForm();
    });
  }


  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.overrideClientBlockForService = [];
    this.overrideClientBlockForTherapist = [];
    this.appointmentPopupService.isFromWaitlist=false;
  }

  async SetFilters() {
    if (this.waitListService.filterData) {
      this.SetFilterObject(_.cloneDeep(this.waitListService.filterData));
    }
    else {
      this.GetFilters();
    }
  }


  ClientBlockValidationTrigger() {
    if (this.isServiceDirty) {
      this.ClientBlockvalidateValidation(this.FormGrp.controls["service"].value, "service");
    }
    if (this.isTherapistDirty) {

      const therapists = this.FormGrp.value.StaffDropDownFormArr && this.FormGrp.value.StaffDropDownFormArr.length
        ? this.FormGrp.value.StaffDropDownFormArr.map(x => x.therapist) :
        this.FormGrp.controls["therapist"].value ? [this.FormGrp.controls["therapist"].value] : [];

      therapists.map((x, i) => {
        this.ClientBlockvalidateValidation(x, "therapist", i);
      });
    }
  }

  initalizeGuaranteeMethodLocalValues() {
    // assigning in client popup opened scenario
    if (!this.waitListService.appointmentDetail.billingDetail && this.waitListService.appointmentDetail?.guaranteePaymentMethod) {
      this.waitListService.appointmentDetail.billingDetail = {
        id: 0,
        appointmentWaitListId: 0,
        billingDetail: this.guaranteePaymentMethodBusiness.GetAPIStringJSON(this.waitListService.appointmentDetail.guaranteePaymentMethod)
      }
    }
    this.waitListService.assignGuaranteeInput();
  }
  mouseDown(e) {
    this.validateForm();
  }

  SetTherapistsDropdown(therapists: any) {
    for (let i = 1; i <= therapists.length; i++) {
      this.AddTherapistDropdownForUpdateLoad(i - 1, therapists[i - 1].toString(), i > 1);
    }
  }

  AddTherapistDropdownForUpdateLoad(i, value, addnewDropDownElement: boolean = true) {
    this.isTherapistAddedWhileLoad = true;
    if (addnewDropDownElement) {
      this.addTherapistDropDown();
    }
    else {
      this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
      if (this.IsReadOnly) {
        this.StaffDropDownFormArr.disable();
      }
    }
    this.StaffDropDownFormArr.at(i).setValue({ therapist: value });
  }

  bindElements(arg) {
    this.sgCount = arg;
  }

  addTherapistDropDown() {
    this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
    let _existingSelectionCount = this.StaffDropDownFormArr.controls.length;
    if (_existingSelectionCount <= this.maxTher) {
      this.StaffDropDownFormArr.push(this.createTherapistDropDown());
    }
    if (!this.maxTher) {
      this.StaffDropDownFormArr.push(this.createTherapistDropDown());
    }
    if (this.IsReadOnly) {
      this.StaffDropDownFormArr.disable();
    }
  }
  removeTherapistDropDown(i) {
    this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
    let staffId: number = Number(this.StaffDropDownFormArr.value[i].therapist) ? this.StaffDropDownFormArr.value[i].therapist : 0;
    this.FormGrp.controls.therapist.markAsDirty();
    this.StaffDropDownFormArr.removeAt(i);
    this.RemoveMappedTherapistWithMultiClient(staffId);
    if (this.appointmentPopupService.isEditAppointment && staffId > 0) {
      this.validateForm();
    }
  }

  removeTherapistValue(value: any) {

    this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
    let therpistResponse = this.StaffDropDownFormArr.value;
    if (therpistResponse && therpistResponse.length > 0) {
      therpistResponse.map(x => {
        if (Number(x.therapist) == Number(value)) {
          x.therapist = "";
        }
      });
      this.FormGrp.get('StaffDropDownFormArr').setValue(therpistResponse);
    }
  }

  private RemoveMappedTherapistWithMultiClient(therapistId: number) {
    if (this.appointmentPopupService.multiClientInfo.length > 0) {
      this.appointmentPopupService.multiClientInfo.forEach(e => {
        if (therapistId == e.TherapistId)
          e.TherapistId = 0;
      });
    }
  }

  validateForm() {
    this.IsTherapistSelectionValid = this.checkForMinStaffValidation();
    this.waitListService.isAppointmentDetailFormValid = this.FormGrp.valid &&
      this.ValidateDuration();
    this.ValidateDirty();
  }

  ValidateDuration(): boolean {
    let isValid: boolean = false;
    let fromdate = this.FormGrp.controls["fromdate"].value;
    let fromTime = moment(this.FormGrp.controls["starttime"].value, this.localization.timeFormat).format("hh:mm a");
    var fromdateTime = this.localization.ConvertDateToISODate(fromdate) + "T" + fromTime;

    let toDate = this.FormGrp.controls["todate"].value;
    let toTime = moment(this.FormGrp.controls["endtime"].value, this.localization.timeFormat).format("hh:mm a");
    var toDateTime = this.localization.ConvertDateToISODate(toDate) + "T" + toTime;

    isValid = this.utils.getDate(toDateTime).getTime() > this.utils.getDate(fromdateTime).getTime();
    return isValid;
  }

  ValidateDirty() {
    this.waitListService.isFormDirty = this.FormGrp.dirty;
  }

  async GetFilters() {
    let apiResponse: AppointmentFilterOutput = await this.waitListService.GetAllFilters();
    if (apiResponse) {
      this.SetFilterObject(apiResponse);
    }
  }

  async SetFilterObject(result: AppointmentFilterOutput) {
    this.filterData = result;
    this.services = this.filterData.serviceViewModels;
    this.location = this.waitListService.GetAllLocations(this.filterData.locationViewModels);
    this.therapist = this.waitListService.GetAllTherapists(this.filterData.therapistViewModels);
    this.waitListService.filterData = _.cloneDeep(this.filterData);
  }

  async LoadAllLocations(ismanual: boolean = false) {
    let locations = await this.http.CallApiAsync<any>({
      host: global.Host.spaManagement,
      callDesc: "GetAllLocByUserId",
      method: HttpMethod.Get,
      showError: true
    });
    return locations.result;
  }
  FilterResources(e: any, type: string, therapistIndex?: number) {
    this.appointmentPopupService.disableRadioButton();
    switch (type) {
      case "servicegroup":
        this.FormGrp.controls["service"].setValue("");
        this.FormGrp.controls.location.setValue('0');
        this.FormGrp.controls.therapist.setValue('0');
        this.waitlistDetails.LocationId = 0;
        this.waitlistDetails.TherapistId = 0;
        this.waitlistDetails.ServiceId = "";
        break;
      case "service":
        this.isServiceDirty = true;
        this.ClientBlockvalidateValidation(e.value, 'service');
        break;
      case "therapist":
        this.isTherapistDirty = true;
        this.ClientBlockvalidateValidation(e.value, 'therapist', therapistIndex);
        break;
      default: break;
    }
   
  }

  filterDropDown() {
    if (!this.filterData) return;
    this.location = this.waitListService.FilterLocation(_.cloneDeep(this.filterData), this.FormGrp, this.selectedServiceGrp);
    this.therapist = this.waitListService.FilterTherapist(_.cloneDeep(this.filterData), this.FormGrp, this.selectedServiceGrp);
    this.services = this.waitListService.FilterService(_.cloneDeep(this.filterData), this.FormGrp, this.selectedServiceGrp);
  }

  async OnFormChanges() {

    this.FormGrp.get("fromdate").valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.minToDate = val;
      this.appointmentInfo.startDate = this.FormGrp.controls["fromdate"].value;
      this.appointmentPopupService.waitListFromDate = this.FormGrp.controls['fromdate'].value;
      this.ValidateStartDate();
      this.refreshPMSStayDetail();
      this.waitListService.assignGuaranteeInput();
    });
    this.FormGrp.get("todate").valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.ValidateStartDate();
      this.appointmentPopupService.waitListToDate = this.FormGrp.controls['todate'].value;
      this.appointmentInfo.endDate = this.FormGrp.controls['todate'].value;
      this.refreshPMSStayDetail();
      this.waitListService.assignGuaranteeInput();
    });

    this.FormGrp.get("starttime").valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.waitListService.appointmentDetail.startTime = this.FormGrp.controls['starttime'].value;
    });
    this.FormGrp.get("endtime").valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.waitListService.appointmentDetail.endTime = this.FormGrp.controls['endtime'].value;
    });

    this.FormGrp.get('service').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {

      if (this.hasValue(this.FormGrp.controls["location"].value) && this.hasValue(this.FormGrp.controls["service"].value)) {
        let currentService = this.services.find(x => x.id === Number(this.FormGrp.controls["service"].value));
        let selectedLocationInfo = this.allLocations.find(x => x.id === Number(this.FormGrp.controls["location"].value));
        if (currentService && selectedLocationInfo && selectedLocationInfo.maxGuests > 0 && selectedLocationInfo.maxGuests < currentService.minimumGuest) {
          this.FormGrp.controls['service'].setValue(null);
          const message = this.captions.LocationMaxGuestServiceMinGuestConflict.interpolate({ location: selectedLocationInfo.maxGuests, service: currentService.minimumGuest });
          this.utils.showAlert(message, global.AlertType.Warning, global.ButtonType.Ok);
          return;
        }
      }

      this.filterDropDown();
      let service = this.clientCountValidation(res);
      this.AdjustTherapistCountBasedOnServiceChange(service);
      this.appointmentInfo.serviceId = this.FormGrp.controls["service"].value;
      this.appointmentInfo.serviceGroupId = this.GetServiceGroupByServiceId(this.appointmentInfo.serviceId);
    });
    this.FormGrp.get('location').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.hasValue(this.FormGrp.controls["location"].value) && this.hasValue(this.FormGrp.controls["service"].value)) {
        let currentService = this.services.find(x => x.id === Number(this.FormGrp.controls["service"].value));
        let selectedLocationInfo = this.allLocations.find(x => x.id === Number(this.FormGrp.controls["location"].value));
        this.setMaxGuestLocation();
        if (currentService && selectedLocationInfo && selectedLocationInfo.maxGuests > 0 && selectedLocationInfo.maxGuests < currentService.minimumGuest) {
          this.FormGrp.controls['location'].setValue(null);
          const message = this.captions.ServiceMinGuestLocationMaxGuestConflict.interpolate({ location: selectedLocationInfo.maxGuests, service: currentService.minimumGuest });
          this.utils.showAlert(message, global.AlertType.Warning, global.ButtonType.Ok);
          return;
        }
      }
      this.filterDropDown();
      this.appointmentInfo.locationId = this.FormGrp.controls["location"].value ? this.FormGrp.controls["location"].value : 0;
    });
    this.FormGrp.get('therapist').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.filterDropDown();
    });

    this.FormGrp.get('StaffDropDownFormArr').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.staffSelectionValueChanged();
    });

    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.appointmentInfo.priority = res.clientpriority;
      this.appointmentInfo.startTime = res.starttime;
      this.appointmentInfo.endTime = res.endtime;
      this.appointmentInfo.endDate = res.todate;
      this.validateForm();
    });
    this.staffSelectionValueChanged();
    this.appointmentPopupService.waitListFromDate = this.FormGrp.controls['fromdate'].value;
    this.appointmentPopupService.waitListToDate = this.FormGrp.controls['todate'].value;
  }

  setMaxGuestLocation() {
      if (this.allLocations && this.hasValue(this.FormGrp.controls['location'].value)) {
          const currentLocation = this.allLocations.find(x => x.id === Number(this.FormGrp.controls['location'].value));
          if (currentLocation) {
              this.apptService.clientScreenProperties.maxGuestLocation = currentLocation.maxGuests;
          }
      }
      else {
          this.apptService.clientScreenProperties.maxGuestLocation = 0;
      }
  }

  hasValue(input) {
    return input && input != "" && input != "0";
  }

  ValidateStartDate() {
    if (!this.FormGrp.controls['todate'].value || !this.FormGrp.controls['fromdate'].value) {
      return;
    }
    const startDate = this.utils.getDate(this.FormGrp.controls['fromdate'].value);
    const endDate = this.utils.getDate(this.FormGrp.controls['todate'].value);
    if (startDate.setHours(0, 0, 0, 0) > endDate.setHours(0, 0, 0, 0)) { // compare date alone
      this.FormGrp.controls['fromdate'].setErrors({ incorrect: true });
    }
    else {
      this.FormGrp.controls['fromdate'].setErrors(null);
    }
  }

  GetServiceGroupByServiceId(serviceId: number): number {
    var service = this.filterData.serviceViewModels.find(r => r.id == serviceId);
    return service ? service.serviceGroupId : 0;
  }

  GetServicePriceByServiceId(serviceId: number): number {
    var service = this.filterData.serviceViewModels.find(r => r.id == serviceId);
    return service ? service.price : 0;
  }

  async UpdateComments() {
    this.appointmentInfo.comments = this.FormGrp.controls["comments"].value;
  }

  clientCountValidation(serviceId): any {
    let clientValMsg = '';
    let minGuestReq;
    let maxGuestReq;
    let minTherapistReq;
    let maxtherapistReq;
    let service: any;
    let maxGuestLocation = 0;
    //set all the properties needed for validation and other helper text in client tab while booking an appointment.
    let clientScreenProp: ClientScreenProperties;
    if (this.filterData) {
      service = this.filterData["serviceViewModels"].filter(s => { return s.id == serviceId });
    }
    else {
      service = _.cloneDeep(this.services).filter(val => { return val.id == Number(serviceId) });
    }
    if (service.length > 0) {
      minGuestReq = service[0].minimumGuest;
      maxGuestReq = service[0].maximumGuest;
      if (this.allLocations && this.hasValue(this.FormGrp.controls["location"].value)) {
        const currentLocation = this.allLocations.find(x => x.id === Number(this.FormGrp.controls["location"].value));
        if (currentLocation && currentLocation.maxGuests > 0 && currentLocation.maxGuests < maxGuestReq) {
          maxGuestLocation = currentLocation.maxGuests;
        }
      }
      minTherapistReq = service[0].minimumStaff;
      maxtherapistReq = service[0].maximumStaff;
      this.maxTher = service[0].maximumStaff;
      this.minTher = service[0].minimumStaff;
      clientValMsg = this.captions.Min + " " + minGuestReq + ", " + this.captions.Max + " " + maxGuestReq + " " + this.captions.tobeselected;
      this.therapistHelperText = "(" + this.captions.Min + " " + minTherapistReq + ", " + this.captions.Max + " " + maxtherapistReq + " " + this.captions.tobeselected + ")";
      this.waitListService.appointmentDetail.therapistHelperText = this.therapistHelperText;
      clientScreenProp = {
        helperText: clientValMsg,
        minGuest: minGuestReq,
        maxGuest: maxGuestReq,
        maxGuestLocation: maxGuestLocation
      }
      if (maxtherapistReq == 1) {
        this.therapistCanBeAdded = false;
      }
      else {
        this.therapistCanBeAdded = true;
      }
      if (this.appointmentPopupService.clientScreenProperties.selectedStaff) {
        clientScreenProp.selectedStaff = this.appointmentPopupService.clientScreenProperties.selectedStaff;
      }
      this.appointmentPopupService.clientScreenProperties = clientScreenProp;
      this.apptService.clientScreenProperties = clientScreenProp;
    }
    return service;
  }

  async AdjustTherapistCountBasedOnServiceChange(service: any) {
    let maxtherapistReq: number;
    if (service && service.length > 0) {
      maxtherapistReq = service[0].maximumStaff;

      let selectedTherapist = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
      if (selectedTherapist && selectedTherapist.length > 0) {
        let therapistToRemove: number = selectedTherapist.length - maxtherapistReq;
        for (let i = 1; i <= therapistToRemove; i++) {
          this.removeTherapistDropDown(selectedTherapist.length - i);
        }
      }
    }
  }

  async SetTherapistHelperText(serviceId: number) {
    this.therapistHelperText = "(" + this.captions.Min + " " + this.GetMinMaxCount(serviceId, 'THERAPIST', 'MIN') + ", " + this.captions.Max + " " + this.GetMinMaxCount(serviceId, 'THERAPIST', 'MAX') + " " + this.captions.tobeselected + ")";
  }

  async SetClientHelperText(serviceId: number) {
    var MinClient = this.GetMinMaxCount(serviceId, 'CLIENT', 'MIN');
    var maxClient = this.GetMinMaxCount(serviceId, 'CLIENT', 'MAX');
    var clientValMsg = this.captions.Min + " " + MinClient + ", " + this.captions.Max + " " + maxClient + " " + this.captions.tobeselected;
    if (this.appointmentPopupService.clientScreenProperties) {
      this.appointmentPopupService.clientScreenProperties.maxGuest = maxClient;
      this.appointmentPopupService.clientScreenProperties.minGuest = MinClient;
      this.appointmentPopupService.clientScreenProperties.helperText = clientValMsg;
    }
    this.apptService.clientScreenProperties = { maxGuest: maxClient };
  }

  staffSelectionValueChanged() {
    this.appointmentPopupService.therapistNotSelected = false;
    this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
    let staffArr: any[] = this.StaffDropDownFormArr.value;
    let selectedTherapistObj: any = [];
    let therpistData: AppointmentWaitListClient[] = [];
    for (const _selctd of staffArr) {
      for (let i = 0; i < this.therapist.length; i++) {
        const element = this.therapist[i];
        if (element.therapistId == _selctd.therapist) {
          element['id'] = element.therapistId;
          selectedTherapistObj.push(element);
          therpistData.push({ id: 0, appointmentWaitListId: 0, therapistId: element.therapistId, clientId: 0, comments: '' });
        }
      }
    }
    therpistData = therpistData.filter(x => x.therapistId > 0);
    selectedTherapistObj = selectedTherapistObj.filter(x => x.therapistId > 0);
    if (therpistData.length == 0) {
      selectedTherapistObj.push({ id: 0, serviceId: 0, therapistId: 0, therapistName: '' });
      therpistData.push({ id: 0, appointmentWaitListId: 0, therapistId: 0, clientId: 0, comments: '' });
    }
    therpistData = therpistData.filter(x => x.therapistId > 0);
    selectedTherapistObj = selectedTherapistObj.filter(x => x.therapistId > 0);
    if (therpistData.length == 0) {
      selectedTherapistObj.push({ id: 0, serviceId: 0, therapistId: 0, therapistName: '' });
      therpistData.push({ id: 0, appointmentWaitListId: 0, therapistId: 0, clientId: 0, comments: '' });
    }
    this.appointmentPopupService.clientScreenProperties.selectedStaff = selectedTherapistObj;
    this.appointmentInfo.client = therpistData;
  }

  GetMinMaxCount(serviceId: number, resource: string, type: string): number {
    var result: number = 1;
    var service = this.waitListService.filterData.serviceViewModels.find(r => r.id == serviceId);
    if (!service) {
      return result;
    }
    if (resource == 'CLIENT') {
      result = type == 'MIN' ? service.minimumGuest : service.maximumGuest;
    }
    else if (resource == 'THERAPIST') {
      result = type == 'MIN' ? service.minimumStaff : service.maximumStaff;
    }
    return result;
  }

  getFullItenerary() {
    let guestId = this.appointmentPopupService.guestId ?
      this.appointmentPopupService.guestId : global.DefaultGUID;
    if (guestId && guestId != global.DefaultGUID && this.appointmentPopupService.waitListFromDate) {
      this.http.CallApiWithCallback({
        host: global.Host.schedule,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "GetItineraryForAppointment",
        method: HttpMethod.Get,
        uriParams: { clientId: guestId, dateTime: this.utils.convertDateFormat(this.appointmentPopupService.waitListFromDate) },
        showError: true,
        extraParams: []
      });
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {

    if (callDesc == "GetItineraryForAppointment") {
      let GetItenerary = <any>result.result;
      this.appointmentPopupService.resultNewAppointment.clientFullItinerary = GetItenerary;
      this.appointmentPopupService.subscribeWaitListDetail(GetItenerary, this.FormGrp.controls['fromdate'].value, this.FormGrp.controls['todate'].value);
    }

  }

  private refreshPMSStayDetail() {
    if (this.appointmentPopupService.resultNewAppointment.clientFullItinerary && this.appointmentPopupService.resultNewAppointment.clientFullItinerary.length > 0) {
      this.appointmentPopupService.subscribeWaitListDetail(this.appointmentPopupService.resultNewAppointment.clientFullItinerary, this.FormGrp.controls['fromdate'].value, this.FormGrp.controls['todate'].value);
    } else {
      this.getFullItenerary();
    }
  }

  ClientBlockvalidateValidation(value: any, type: string, index?: number) {
    if (!value)
      return;

    if (!this.overrideClientBlockForService.find(y => y === Number(value)) && type === 'service' && this.appointmentPopupService.clientBlockInfos && this.appointmentPopupService.clientBlockInfos.find(x => !x.blockClient)) {

      const blockedService = this.appointmentPopupService.clientBlockInfos.find(x =>
        !x.blockClient
        && x.serviceBlock
        && x.serviceBlock.find(y => y === Number(value)));

      if (blockedService) {

        const serviceInfo = this.services.find(x => value == x.id);
        this.isServiceDirty = false;
        const message = this.captions.ClientBlockedForService.interpolate({ service: serviceInfo.description });

        this.utils.showAlert(message, global.AlertType.Warning,
          this.appointmentPopupService.clientBlockOverrideAccess ? global.ButtonType.Ok : global.ButtonType.OKOverride, async (res) => {
            if (this.appointmentPopupService.clientBlockOverrideAccess) {
              this.overrideClientBlockForService.push(Number(value));
            }
            else {
              if (res === global.AlertAction.CONTINUE) {
                this.FormGrp.controls["service"].setValue("");
                this.waitListService.selectedIndex = 0;
              }
              else {
                const quickLoginDialogRef = this._quickLoginUtil.QuickLogin({ breakPointNumber: global.SPAScheduleBreakPoint.overrideClientBlock });
                quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

                  if (quickLoginDialogResult.isLoggedIn) {
                    this.overrideClientBlockForService.push(Number(value));
                  }

                  else {
                    this.FormGrp.controls["service"].setValue("");
                    this.waitListService.selectedIndex = 0;
                  }

                });
              }
            }
          });
      }
    }
    else if (!this.overrideClientBlockForTherapist.find(y => y === Number(value)) && type === 'therapist' && this.appointmentPopupService.clientBlockInfos && this.appointmentPopupService.clientBlockInfos.find(x => !x.blockClient)) {
      const blockedTherapist = this.appointmentPopupService.clientBlockInfos.find(x =>
        !x.blockClient
        && x.therapistBlock
        && x.therapistBlock.find(y => y === Number(value)));

      if (blockedTherapist) {
        const therapistInfo = this.therapist.find(x => Number(value) === x.therapistId);
        this.isTherapistDirty = false;
        const message = this.captions.ClientBlockedForTherapist.interpolate({ therapist: therapistInfo.therapistName });
        this.poppedTherapistIndex = value;
        this.utils.showAlert(message, global.AlertType.Warning,
          this.appointmentPopupService.clientBlockOverrideAccess ? global.ButtonType.Ok : global.ButtonType.OKOverride, async (res) => {
            if (this.appointmentPopupService.clientBlockOverrideAccess) {
              this.overrideClientBlockForTherapist.push(Number(value));
              this.poppedTherapistIndex = 0;
            }
            else {
              if (res === global.AlertAction.CONTINUE) {
                this.removeTherapistValue(this.poppedTherapistIndex);
                this.waitListService.selectedIndex = 0;
              }
              else {
                const quickLoginDialogRef = this._quickLoginUtil.QuickLogin({ breakPointNumber: global.SPAScheduleBreakPoint.overrideClientBlock });
                quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

                  if (quickLoginDialogResult.isLoggedIn) {
                    this.overrideClientBlockForTherapist.push(Number(value));
                    this.poppedTherapistIndex = 0;
                  }
                  else {
                    this.removeTherapistValue(this.poppedTherapistIndex);
                    this.waitListService.selectedIndex = 0;
                  }
                });
              }

            }
          });
      }
    }


  }

  getOnlyDate(input: any): Date {
    let dt = new Date(input);
    return new Date(dt.getFullYear() + '/' + (((dt.getMonth() + 1) < 10) ? '0' : '') + (dt.getMonth() + 1) + '/' + ((dt.getDate() < 10) ? '0' : '') + dt.getDate());
  }
}
