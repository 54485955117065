import { Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-ag-acc-range-slider',
  templateUrl: './ag-range-slider.component.html',
  styleUrls: ['./ag-range-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgRangeSliderComponent implements OnInit {
  value: any;
  highValue: any;
  minValue  = 0;
  maxValue = 100;
  @Input('triggerInput')
  set triggerInput(triggerVal){
    this.valueChange({value:triggerVal.minValue,highValue:triggerVal.maxValue})
  }
  @Input('value')
   set mySlideval(val){
    this.value=val;
    this.InputMinvalue=this.value?this.value:0;
    this.minValue = Number(this.InputMinvalue);
  }
  @Input('highValue')
  set mySlidehighval(val){
   this.highValue=val;
   this.InputhighValue=this.highValue?this.highValue:0;
 }
  options: Options;
  @Output() sliderChange:EventEmitter<{value: number; highValue: number; saveEnable?: boolean;}> = new EventEmitter();
  InputMinvalue : string;
  InputhighValue : string;
  minValueValidation: boolean;
  maxValueValidation: boolean;
  saveEnable: boolean;

  @Input('options')
  set fromType(value) {
    if (value) {
      this.options = value;
    }
  }
  constructor(private _cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  valueChange(arg){  
      if(arg.value || arg.value == 0)
      {
       this.InputMinvalue=arg.value
      }
      if(arg.highValue)
      {
        this.InputhighValue=arg.highValue
      }
      this.sliderChange.emit({
        value: this.InputMinvalue ? this.InputMinvalue : this.value,
        highValue: this.InputhighValue ? this.InputhighValue : this.highValue,
      })
  }

  minimum(eve) {
    if( parseInt(this.InputMinvalue) <  parseInt(this.InputhighValue) )
    {
      this.value = this.InputMinvalue 
      this.maxValueValidation = false;
      this.minValueValidation = false;
    }
    else{
      if(parseInt(this.InputMinvalue) >  parseInt(this.InputhighValue)) {
        this.minValueValidation = true;
        this.maxValueValidation = false;
        this.InputhighValue = this.InputMinvalue
      }
      if(this.InputhighValue && parseInt(this.InputMinvalue) <  parseInt(this.InputhighValue)) {
        this.maxValueValidation = true;
        this.minValueValidation = false;
      }  
    }
    this.valueChange(eve);
  }

  maximum(eve) {
    if( parseInt(this.InputhighValue) > parseInt(this.InputMinvalue) )
    {
      this.highValue = this.InputhighValue;
      this.minValueValidation = false;
      this.maxValueValidation = false; 
    }
    else{
      if( parseInt(this.InputhighValue) < parseInt(this.InputMinvalue) ) {
        this.maxValueValidation = true; 
        this.minValueValidation = false;
        this.InputMinvalue = this.InputhighValue
      }
      if(this.InputMinvalue &&  parseInt(this.InputhighValue) > parseInt(this.InputMinvalue)) {
        this.minValueValidation = true;
        this.maxValueValidation = false;
      } 
    }
    this.valueChange(eve);
  }
  onInput(event, value){
    if (value == 'min' && event.target.value > Number(this.highValue)) {
        this.InputMinvalue = this.minValue.toString();
    }
    if (value == 'max' && event.target.value > Number(this.highValue)) {
        this.InputhighValue = this.highValue.toString();
    }
  }
}
