import { Pipe, PipeTransform } from '@angular/core';
import { SpaUtilities } from '../utilities/spa-utilities';


@Pipe({
  name: 'getServiceNamePipes'
})
export class GetServiceNamePipesPipe implements PipeTransform {
  constructor(public utils: SpaUtilities) { }
  transform(inputArray: any, serviceArr) {
    if (inputArray && serviceArr) {
      var service = serviceArr.filter(res => { return res.id == inputArray });
      return service && service.length > 0 && service[0].description;
    }
    return inputArray == 0 ? "" : inputArray;
  }
}
