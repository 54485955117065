import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicFormPage } from 'src/app/eatecui/source/shared/models/common-form-page.interface';
import { AgilysysDynamicformComponent } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { SetUpService } from 'src/app/eatecui/source/setup/shared/services/setup.service';

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormPageComponent implements AfterContentInit {

  @Input() FormInput: DynamicFormPage;
  homeGroup: FormGroup;
  @ViewChild(AgilysysDynamicformComponent) formControlGroup: AgilysysDynamicformComponent;

  constructor(
    private formBuilder: FormBuilder,
    private setupService: SetUpService
  ) {
    this.homeGroup = this.formBuilder.group({});
    this.setupService.setupFormGroup = this.homeGroup;
  }

  getReturnFormValue(event) {
    if (this.FormInput && this.FormInput.hasOwnProperty('FormReturnValue')) {
        this.FormInput.FormReturnValue(event, this.formControlGroup.form);
    }
  }

  ngAfterContentInit() {
    let timeInterval: any;
    timeInterval = setInterval(() => {
      if (this.homeGroup && this.homeGroup.controls && this.FormInput && this.FormInput.hasOwnProperty('FormGroupControls')) {
        this.FormInput.FormGroupControls(this.homeGroup);
        clearInterval(timeInterval);
      }
    });
  }

}
