import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridHeaderConfig, GridSearchAction, GridSortAction, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
import * as constant from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { GridInterface } from '../../../../setup-master/shared/interface/grid-interface';
import { GridHeaderTransaction } from './grid-header';
import { GridActionRender } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { ColumnFieldType } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
// import * as PreOrderColumn from '../../../../../assets/json/ui-control/pre-order-gridcolumn.json';
import { GlobalCustomFormAction } from 'src/app/eatecui/source/shared/models/custom-action/global-custom-action.interface';
import { ActionTypes } from 'src/app/eatecui/source/shared/models/custom-action/custom-enum.interface';
import { GridColumnModels } from 'src/app/eatecui/source/shared/models/grid-header-models';
import { fetchHttp } from 'src/app/eatecui/source/shared/models/fetch-data.interface';
import { HttpClient } from '@angular/common/http';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { IntegrationCollection } from 'src/app/eatecui/source/shared/integration/integration-interface';
const CurrencyFormatKeys = [
    'amount',
    'valued',
    'amountinfc',
    'charges',
    'chargesinfc',
    'unitcost',
    'value',
    'currentcost',
    'Valued',
    'UnitCost',
    'CurrentCost',
    'pototal',
    'misctotal',
    'poitemtotal',
    'poitemtotalinfc',
    'pototalinfc',
    'misctotalinfc'
];
export const ViewGridHeaderDefinationConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity?: any, responseData?: Array<any>,
    httpClient?: HttpClient, integrationService?: IntegrationService, precolumnOrderJson?: any): GridHeaderConfig[] => {
    try {
        const GridDataHeaderColumn: GridHeaderConfig[] = [];
        const deviceName = getDeviceName();
        if (ViewHeaderEntity && ViewDetailsEntity &&
            TransactionCollection && ViewDetailsEntity[0].Property && ViewDetailsEntity[0].Property.length > 0 &&
            TransactionCollection.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.length === 0) {  // Need to alter module name condition
            ViewHeaderEntity[0].Property.forEach((HeaderColumnObject: object) => {
                const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                const entityName = ViewHeaderEntity[0]['$']['Name'].toString();
                const GridHeaderConfigObj = {} as GridHeaderConfig;
                const GridDeviceOriented = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridColumnHide[0][deviceName].
                    filter((x: any) => x.ColumnName.toLowerCase() === HeaderColumnObject['$']['Name'].toString().toLowerCase());
                if (GridDeviceOriented.length === 0) {
                    GridHeaderConfigObj.headerName = GridHeaderTransaction.GridHeaderNameConfig(ViewHeaderEntity,
                        ViewDetailsEntity, TransactionCollection, httpService, HeaderColumnObject, translateService);
                    GridHeaderConfigObj.field = HeaderColumnObject['$']['Name'].toString().toLowerCase();
                    const remapVisble = ProductDataRemap.FieldVisibleRemapEngine(
                        productremapJson, entityName, GridHeaderConfigObj.field);
                    const hideValue = checkRguestTenant(remapVisble, GridHeaderConfigObj);
                    GridHeaderConfigObj.hide = !hideValue;
                    GridHeaderConfigObj.sortOrder = ['Asc'];
                    GridHeaderConfigObj.editable = false;
                    GridHeaderConfigObj.sortable = false;
                    GridHeaderConfigObj.customSort = GridColumnModels.CustomSort(GridHeaderConfigObj.field, 'Transaction',
                        TransactionCollection);
                    GridHeaderConfigObj.suppressMovable = true;
                    GridHeaderConfigObj.tooltipField = HeaderColumnObject['$']['Name'].toString().toLowerCase();
                    const ReplacableObject = constant.ReplaceTextWithIcon.filter(x => x.OdataKey.toLowerCase() ===
                        HeaderColumnObject['$']['Name'].toLowerCase())[0];
                    if (ReplacableObject) {
                        GridHeaderConfigObj.width = ReplacableObject.Width;
                    }
                    GridHeaderConfigObj.sortDirection = GridHeaderTransaction.GridHeaderSortDirection(
                        ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, HeaderColumnObject);
                    // GridHeaderConfigObj.sortDirection = GridHeaderTransaction.GridHeaderSortDirection(
                    // ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, HeaderColumnObject);
                    GridHeaderConfigObj.type = GridHeaderTransaction.GridHeaderTypeConfig(
                        ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, HeaderColumnObject);
                    GridHeaderConfigObj.valueFormatter = GridHeaderConfigObj.type === ColumnFieldType.DATECOLUMN ? Formaters.dateformater :
                        (GridHeaderConfigObj.type === ColumnFieldType.DECIMAL ? Formaters.decimalFormater : null);
                    GridHeaderConfigObj.type = GridHeaderConfigObj.type === ColumnFieldType.DECIMAL ? ColumnFieldType.NUMERIC
                        : GridHeaderConfigObj.type;
                    GridHeaderConfigObj.fieldType = GridHeaderTransaction.GridHeaderFieldTypeCOnfig(
                        ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, HeaderColumnObject);
                    GridHeaderConfigObj.resizable = true;
                    GridHeaderConfigObj.minWidth = '75';
                    if (GridHeaderConfigObj.field === 'isactive') {
                        GridHeaderConfigObj.cellRenderer = 'iconRenderer';
                        GridHeaderConfigObj.width = '40';
                        GridHeaderConfigObj.minWidth = '0';
                        GridHeaderConfigObj.resizable = false;
                        GridHeaderConfigObj.headerName = '';
                    }
                    if (GridHeaderConfigObj.hasOwnProperty('columnType') && GridHeaderConfigObj.columnType === 'decimal') {
                        GridHeaderConfigObj.valueFormatter = Formaters.decimalFormater;
                    }
                    if (CurrencyFormatKeys && CurrencyFormatKeys.length > 0) {
                        if (CurrencyFormatKeys.indexOf(GridHeaderConfigObj.field) > -1) {
                            GridHeaderConfigObj.valueFormatter = Formaters.currencyFormater;
                        }
                    }
                    if (GridHeaderConfigObj.field === 'approvalstatusid') {
                        const enableTranasctionApproval: Array<IntegrationCollection> = integrationService.IntegrationConfig.IntegrationCollection.filter(y => y.IntegratonName === 'DocApprovals');
                        GridHeaderConfigObj.cellRenderer = 'transactionType';
                        if (enableTranasctionApproval && enableTranasctionApproval.length > 0 && !enableTranasctionApproval[0].IsEnabled) {
                            GridHeaderConfigObj['cellClass'] = 'tranasction-approval-config';
                        }
                    }
                    if (GridHeaderConfigObj.field === 'priorityid') {
                        GridHeaderConfigObj.cellRenderer = 'transactionType';
                    }
                    const mergedValue = GlobalCustomFormAction(
                        GridHeaderConfigObj.field, GridHeaderConfigObj.field, entityName,
                        'GridAction', ActionTypes.CUSTOMICONMERGE, null, null, null, null, null, null, responseData);
                    if (mergedValue && mergedValue !== undefined && mergedValue !== null) {
                        GridHeaderConfigObj.cellRenderer = mergedValue;
                    }
                    GridDataHeaderColumn.push(GridHeaderConfigObj);
                }
            });

            if (TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.ShowCheckbox) {
                const checkboxHeader = {} as GridHeaderConfig;
                checkboxHeader.headerCheckboxSelection = true;
                checkboxHeader.checkboxSelection = true;
                checkboxHeader.suppressMovable = true;
                checkboxHeader.width = '50';
                checkboxHeader.editable = false;
                checkboxHeader.headerName = '';
                checkboxHeader.sortable = false;
                checkboxHeader.field = 'check';
                checkboxHeader.minWidth = '0';
                GridDataHeaderColumn.unshift(checkboxHeader);
            }

            if (TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.ShowActionColumn) {
                const cellRenderer = getActionRendererColumn(TransactionCollection);
                GridDataHeaderColumn.push(cellRenderer);
            }
            const RearrangedHeaderDefination = TransactionCollection.SpModuleKey !== 'IngredientsLocations' ? rearrangeHeaderColumn(
                GridDataHeaderColumn, TransactionCollection.TransUniqueName, httpClient, precolumnOrderJson) : GridDataHeaderColumn;
            return RearrangedHeaderDefination;
        } else {
            const GridHeaders = [] as Array<GridHeaderConfig>;
            TransactionCollection.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.forEach(gridheader => {
                // gridheader.field = gridheader.field.toLowerCase();
                gridheader.resizable = true;
                gridheader.minWidth = '75';
                gridheader.type = assigngridType(gridheader.type);
                let valueFormatterParm=gridheader.type;
                if (CurrencyFormatKeys && CurrencyFormatKeys.length > 0) {
                    if (CurrencyFormatKeys.indexOf(gridheader.field) > -1) {
                        gridheader.columnType = (gridheader.columnType) ? gridheader.columnType : null;
                        valueFormatterParm=gridheader.columnType;
                    }
                }
                gridheader.valueFormatter = assignValueFormater(valueFormatterParm);
                const mergedValue = GlobalCustomFormAction(
                gridheader.field, gridheader.field, TransactionCollection.OdataKey,
                'GridAction', ActionTypes.CUSTOMICONMERGE, null, null, null, null, null, null, responseData);
                if (mergedValue && mergedValue !== undefined && mergedValue !== null) {
                    gridheader.cellRenderer = mergedValue;
                }
                if (gridheader.field === 'approvalstatusid') {
                    const enableTranasctionApproval: Array<IntegrationCollection> = integrationService.IntegrationConfig.IntegrationCollection.filter(y => y.IntegratonName === 'DocApprovals');
                    gridheader.cellRenderer = 'transactionType';
                    if (enableTranasctionApproval && enableTranasctionApproval.length > 0 && !enableTranasctionApproval[0].IsEnabled) {
                        gridheader['cellClass'] = 'tranasction-approval-config';
                    }
                }
                GridHeaders.push(gridheader);
            });
            return GridHeaders;
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

/**
 * @method assigngridType
 * @param GridHeaderType 
 * @param Assign grid type to the Header Defination 
 * @returns 
 */
const assigngridType = (GridHeaderType: string) => {
    try {
        let GridType = GridHeaderType;
        if (GridHeaderType === ColumnFieldType.DECIMAL) {
            GridType = ColumnFieldType.NUMERIC
        }
        return GridType;
    } catch (error) {
        console.error(error);
    }
}

/**
 * @method assignValueFormater
 * @param GridHeaderType 
 * @param Assign grid type to the Header Defination 
 * @returns 
 */
const assignValueFormater = (columnType: string) => {
    try {
        let valueFormater = null
        switch (columnType) {
            case ColumnFieldType.CURRENCY:
                valueFormater = Formaters.currencyFormater;
                break;
            case ColumnFieldType.DECIMAL:
                valueFormater = Formaters.decimalFormater;
                break;
            case ColumnFieldType.DATECOLUMN:
                valueFormater = Formaters.dateformater;
                break;
            default:
                valueFormater = null;
                break;
        }
        return valueFormater;
    } catch (error) {
        console.error(error);
    }
}

function checkRguestTenant(hideValue: boolean, GridHeaderConfigObj: GridHeaderConfig) {
    try {
        const checkTenant = sessionStorage.getItem('RGuestTenantId');
        let rguestTenantId = false;
        if (checkTenant && JSON.parse(checkTenant)) {
            rguestTenantId = true;
        }
        if (rguestTenantId && GridHeaderConfigObj.field.toLowerCase().includes('infc')) {
            return false;
        } else {
            return hideValue;
        }
    } catch (error) {
        console.log('Error occurred in checkRguestTenant', error);
    }
}

/**
* @description To get the name of the device
*/
function getDeviceName() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let deviceName = '';
    if (width > constant.desktopminwidth && constant.desktopminheight > 768) {
        deviceName = constant.DeviceName.Desktop;
    } else if ((width === constant.tablandscapewidth && height === constant.tablandscapeheight) ||
        (width >= constant.tabpotraitminwidth && width <= constant.tabpotraitmaxwidth &&
            height >= constant.tabpotraitminheight && height <= constant.tabpotraitmaxheight)) {
        deviceName = constant.DeviceName.Tablet;
    } else if (width >= constant.mobileminwidth && width <= constant.mobilemaxwidth &&
        height >= constant.mobileminheight && height <= constant.mobilemaxheight) {
        deviceName = constant.DeviceName.Mobile;
    }
    return deviceName === '' ? constant.DeviceName.Desktop : deviceName;
}
function getActionRendererColumn(TransactionCollection: TransactionConfiguration) {
    const actionRenderer: GridActionRender = {
        headerName: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.HeaderName,
        cellRenderer: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.CellRenderer,
        hide: false,
        editable: false,
        field: '',
        showIcon: true,
        showButton: false,
        pinned: 'right',
        width: '120',
        suppressMovable: true,
        Edit: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.Edit,
        Delete: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.Delete,
        Deactivate: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.Deactivate,
        Activate: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.Activate,
        Copy: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.Copy,
        ReverseInvoice: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.ReverseInvoice,
        Start: TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions.Start
    };
    return actionRenderer;
}

function rearrangeHeaderColumn(HeaderDefination, GridOdataKey: string, httpClient: HttpClient, precolumnOrderJson: any) {
    const IsActiveIndex = HeaderDefination.findIndex(x => x.field.toLowerCase() === 'isactive');
    const IsActiveObject = HeaderDefination.filter(x => x.field.toLowerCase() === 'isactive')[0];
    const CheckBoxEnabled = HeaderDefination.filter(x => x.headerCheckboxSelection === true).length > 0 ? true : false;
    if (IsActiveIndex > -1) {
        HeaderDefination.splice(IsActiveIndex, 1);
        HeaderDefination.splice(CheckBoxEnabled ? 1 : 0, 0, IsActiveObject);
    }
    GridColumnMove(HeaderDefination, GridOdataKey, httpClient, precolumnOrderJson);
    return HeaderDefination;
}

async function GridColumnMove(HeaderDefination: Array<any>, GridOdataKey: string, httpClient: HttpClient, precolumnOrderJson?: any): Promise<Array<any>> {
    const RetailProductJson = precolumnOrderJson ? precolumnOrderJson :
        await httpClient.get<any>('app/eatecui/assets/json/ui-control/pre-order-gridcolumn.json').toPromise();
    let reorderHeaderDefination = HeaderDefination;
    // const columnOrder = PreOrderColumn['default'];
    const columnOrder = RetailProductJson;
    columnOrder.forEach((columnorder: any, orderIndex: number) => {
        const headerINdex = HeaderDefination.findIndex(x => x.field === columnorder.Key && columnorder.Odatakey === GridOdataKey);
        if (headerINdex > -1) {
            reorderHeaderDefination = array_move(HeaderDefination, headerINdex, columnorder.Order);
        }
    });
    return HeaderDefination;
}

function array_move(arr: any, old_index: number, new_index: number) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}