import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'lib-transaction-type-renderer',
  templateUrl: './transaction-type-renderer.component.html',
  styleUrls: ['./transaction-type-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionTypeRendererComponent implements OnInit {
  @ViewChild('clickInfoIconTrigger') clickInfoIconTrigger: MatMenuTrigger;
  params: any;
  iconLists: Array<any>;
  className: string;
  approvalStatusField = false;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    if (params?.colDef?.field === 'approvalstatusid' || params?.colDef?.field === 'ApprovalStatus') {
      this.iconLists = [{
        StatusId: 3,
        StatusName: 'Hold',
        StatusClass: 'status-hold'
      }, {
        StatusId: 1,
        StatusName: 'Pending',
        StatusClass: 'status-pending'
      }, {
        StatusId: 2,
        StatusName: 'Denied',
        StatusClass: 'status-denied'
      }, {
        StatusId: 4,
        StatusName: 'Approved',
        StatusClass: 'status-approved'
      }, {
        StatusId: 5,
        StatusName: 'Resubmit',
        StatusClass: 'status-resubmit'
      }, {
        StatusId: 6,
        StatusName: 'Submitted',
        StatusClass: 'status-submit'
      }, {
        StatusId: 7,
        StatusName: 'Yet To Register',
        StatusClass: 'status-yet-to-register'
      }
    ];
    } else if (params?.colDef?.field === 'Status') {
      this.iconLists = [{
        StatusId: 1,
        StatusName: 'Open',
        StatusClass: 'status-pending'
      }, {
        StatusId: 2,
        StatusName: 'Saved',
        StatusClass: 'status-hold'
      }, {
        StatusId: 3,
        StatusName: 'Cancelled',
        StatusClass: 'status-denied'
      }, {
        StatusId: 4,
        StatusName: 'Accepted',
        StatusClass: 'status-approved'
      }, {
        StatusId: 5,
        StatusName: 'Submitted',
        StatusClass: 'status-submit'
      }, {
        StatusId: 6,
        StatusName: 'Rejected',
        StatusClass: 'status-denied'
      }, {
        StatusId: 7,
        StatusName: 'Finalized',
        StatusClass: 'status-approved'
      }];
    } else {
      this.iconLists = [{
        StatusId: 1,
        StatusName: 'Normal',
        StatusClass: 'status-pending'
      }, {
        StatusId: 2,
        StatusName: 'Emergency',
        StatusClass: 'status-denied'
      }, {
        StatusId: 3,
        StatusName: 'Low',
        StatusClass: 'status-approved'
      }];
    }
    this.approvalStatusField =  params?.colDef?.field === 'approvalstatusid' ||
    (params?.colDef?.field === 'ApprovalStatus' && params?.value === 'Resubmit');
    this.params = params;
    const Index = this.iconLists.findIndex(x => x.StatusName ===  this.params.value);
    this.className = Index !== -1 ? this.iconLists[Index].StatusClass : '';
  }

  infoClick(event) {
      event.stopPropagation();
      this.clickInfoIconTrigger?.closeMenu();
      this.params.context.parentComponent.rowNameIconClicked(this.params);
      const allColumns = this.params.columnApi.getAllColumns();
      allColumns.forEach(element => {
        element.colDef.editable = false;
      });
      setTimeout(() => {
        this.params.context.parentComponent.moreData(this.params);
      }, 1000);
  }

  closeMenu() {
    this.clickInfoIconTrigger.closeMenu();
  }
}
