import { Injectable } from '@angular/core';
import { TherapistData, TherapistGridData, LinkCode } from '../../shared/business/view-settings.modals';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { GridType, SPAScheduleBreakPoint } from '../../shared/globalsContant';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';

@Injectable()
export class SetupService {
  constructor(private localization: SpaLocalization, private BP: BreakPointAccess) {

  }
  formTherapistGridData(result: TherapistData[]): any[] {
    const dataArr: TherapistGridData[] = [];
    let therapistObj: TherapistGridData;
    for (let i = 0; i < result.length; i++) {
      const element = result[i];
      therapistObj = {
        code: element.code,
        name: element.firstName + ' ' + element.lastName,
        listOrder: element.listOrder,
        id: element.id,
        dateOfHire: element.dateOfHire,
        isActive: element.isActive
      };
      dataArr.push(therapistObj);
    }
    return dataArr;
  }

  formLinkCodeData(result: LinkCode[]): any[] {
    const dataArr = <any>result;
    for (let i = 0; i < dataArr.length; i++) {
      dataArr[i].startDate = this.localization.LocalizeDate(dataArr[i].startDate);
      dataArr[i].endDate = this.localization.LocalizeDate(dataArr[i].endDate);
    }
    return dataArr;
  }

  IsAuthorized(id: GridType): boolean {
    let isUserAuthorized = true;
    const breakpointNumber: number[] = [];
    switch (id) {
      case GridType.group:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingServiceGroup);
        break;
      case GridType.location:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingServiceLocation);
        break;
      case GridType.equipment:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingServiceEquipment);
        break;
      case GridType.medical:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingMedicationCondition);
        break;
      case GridType.addons:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingServiceAddOn);
        break;
      case GridType.spaServices:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingSpaService);
        break;
      case GridType.therapist:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingTherapistSetup);
        break;
      case GridType.spaPackage:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingSpaPackage);
        break;
      case GridType.colorLink:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingColorLinking);
        break;
      case GridType.customfield:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingCustomFields);
        break;
      case GridType.breakType:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingBreakType);
        break;
      case GridType.priceType:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingPriceType);
        break;
      case GridType.packageClasses:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingPackageClasses);
        break;
      case GridType.cancelReasons:
        breakpointNumber.push(SPAScheduleBreakPoint.SettingCancelReason);
        break;
      // Include Therapist break breakpoints.
    }
    if (breakpointNumber.length > 0) {
      isUserAuthorized = this.BP.CheckForAccess(breakpointNumber);
    }
    return isUserAuthorized;
  }

  getpopData(tit: any) {
    const diaTit = [];
    switch (tit) {
      case GridType.group:
        diaTit[0] = this.localization.captions.setting.ServiceGroup;
        diaTit[1] = "SG";
        break;
      case GridType.location:
        diaTit[0] = this.localization.captions.setting.ServiceLocation;
        diaTit[1] = "SL";
        break;
      case GridType.therapist:
        diaTit[0] = this.localization.captions.setting.TherapistSetup;
        diaTit[1] = "TS";
        break;
      case GridType.medical:
        diaTit[0] = this.localization.captions.setting.MedicalCondition;
        diaTit[1] = "MC";
        break;
      case GridType.addons:
        diaTit[0] = this.localization.captions.setting.ServiceAddon;
        diaTit[1] = "SA";
        break;
      case GridType.equipment:
        diaTit[0] = this.localization.captions.setting.CreateServiceEquipment;
        diaTit[1] = "SE";
        break;
      case GridType.spaServices:
        diaTit[0] = this.localization.captions.setting.SpaService;
        diaTit[1] = "SS";
        break;
      case GridType.spaPackage:
        diaTit[0] = this.localization.captions.setting.SpaPackage;
        diaTit[1] = "SP";
        break;
      case GridType.cancelReasons:
        diaTit[0] = this.localization.captions.setting.CancelReason;
        diaTit[1] = "CR";
        break;
      case GridType.packageClasses:
        diaTit[0] = this.localization.captions.setting.PackageClass;
        diaTit[1] = "PC";
        break;
      case GridType.colorLink:
        diaTit[0] = this.localization.captions.setting.ColorLink;
        diaTit[1] = "CL";
        break;
      case GridType.lunchsetup:
        diaTit[0] = this.localization.captions.setting.LunchSetup;
        diaTit[1] = "LS";
        break;
      case GridType.breakType:
        diaTit[0] = this.localization.captions.setting.BreakType;
        diaTit[1] = "BR";
        break;
      case GridType.priceType:
        diaTit[0] = this.localization.captions.setting.PriceType;
        diaTit[1] = "PT";
        break;
      case GridType.customfield:
        diaTit[0] = this.localization.captions.setting.CustomField;
        diaTit[1] = "CF";
        break;
      case GridType.guestType:
        diaTit[0] = this.localization.captions.setting.GuestType;
        diaTit[1] = "GT";
        break;
      case GridType.relationship:
        diaTit[0] = this.localization.captions.setting.Relationship;
        diaTit[1] = "GR";
        break;
      case GridType.classGroup:
        diaTit[0] = this.localization.captions.setting.classGroup;
        diaTit[1] = "CG";
        break;
      case GridType.classSetup:
        diaTit[0] = this.localization.captions.setting.classSetup;
        diaTit[1] = "CS";
        break;
      case GridType.instructorSetup:
        diaTit[0] = this.localization.captions.setting.instructorSetup;
        diaTit[1] = "IS";
        break;
      case GridType.classLocation:
        diaTit[0] = this.localization.captions.setting.classLocation;
        diaTit[1] = "Cl";
        break;
      case GridType.classEquipment:
        diaTit[0] = this.localization.captions.setting.classEquipment;
        diaTit[1] = "CE";
        break;
      default:
        break;
    }
    return diaTit;
  }


}
