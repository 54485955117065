import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingRoutingModule } from './setting-routing.module';
import { SettingComponent } from './setting.component';
import { ViewSettingComponent } from './view-settings/view-settings.component';
import { ServiceGroupComponent } from './Setup/service-group/service-group.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceLocationComponent } from './Setup/service-location/service-location.component';
import { MedicalConditionComponent } from './Setup/medical-condition/medical-condition.component';
import { SeviceEquipmentComponent } from './Setup/sevice-equipment/sevice-equipment.component';
import { SeviceAddOnsComponent } from './Setup/sevice-add-ons/sevice-add-ons.component';
import { SpaServicesComponent } from './Setup/spa-services/spa-services.component';
import { SharedModule } from '../shared/shared.module';
import { SetUpComponent } from './Setup/setup.component';
import { StaffScheduleComponent } from './staff-schedule/staff-schedule.component';
import { StaffSchedulePipe } from './staff-schedule/staff-schedule-pipe.pipe';
import { SpaPackageComponent,YeildingPrice } from './Setup/spa-package/spa-package.component';
import { PackageYieldingComponent } from './package-yielding/package-yielding.component';
import { SettingsService } from './settings.service';
import { CancelReasonComponent } from './Setup/cancelreason/cancelreason.component';
import { PackageClassComponent } from './Setup/package-class/package-class.component';
import { ColorLinkComponent } from './Setup/color-link/color-link.component';
import { LunchSetupComponent } from './Setup/lunch-setup/lunch-setup.component';
import { PriceTypeComponent } from './Setup/price-type/price-type.component';
import { BreakTypeComponent } from './Setup/break-type/break-type.component';
import { CustomFieldComponent } from './Setup/custom-field/custom-field.component';
import { UtilitiesComponent } from './Utilities/utilities.component';
import { MaintenanceComponent } from './Utilities/maintenance/maintenance.component';
import { LunchSetupEditComponent } from './Setup/lunch-setup/lunch-setup-edit/lunch-setup-edit.component';
import { MultipleAppointmentsComponent } from './Utilities/multiple-appointments/multiple-appointments.component';
import { TherapistUnavailabilityComponent } from './Utilities/therapist-unavailability/therapist-unavailability.component';
import { PasswordManagement } from './Utilities/password-mgmt/password-mgmt.component';
import { ServiceYieldingComponent } from './service-yielding/service-yielding.component';
import { ServiceYieldingEditComponent } from './service-yielding/service-yielding-edit/service-yielding-edit.component';
import { ServiceYieldingGridComponent } from './service-yielding/service-yielding-grid/service-yielding-grid.component';
import { AddbreakComponent } from './Setup/add-break/add-break.component';
import { RetailModule } from '../retail/retail.module';
import { PackagePopupComponent } from './package-yielding/package-popup/package-popup.component';
import { UserConfigComponent } from './user-config/user-config.component';
import { InterfacesComponent } from './interfaces/interfaces.component';
import { RoleSetupComponent } from './user-config/role-setup/role-setup.component';
import { UserRoleComponent } from './user-config/user-role/user-role.component';
import { UserSetupComponent } from './user-config/user-setup/user-setup.component';
import { NewUserComponent } from './user-config/new-user/new-user.component';
import { SystemConfigComponent } from './system-config/system-config.component';
import { PropertyInformationComponent } from './system-config/property-information/property-information.component';
import { EmailConfigurationSetupComponent } from './system-config/email-configuration-setup/email-configuration-setup.component';

import { AppointmentComponent } from './system-config/appointment/appointment.component';
import { UserSettingsComponent } from './user-config/new-user/user-settings/user-settings.component';
import { SpaSettingsComponent } from './user-config/new-user/spa-settings/spa-settings.component';
import { RetailSettingsComponent } from './user-config/new-user/retail-settings/retail-settings.component';
import { CopyRolesComponent } from './user-config/copy-roles/copy-roles.component';

import { ServiceYieldingService } from './service-yielding/service-yielding-service';
import { AbsoluteValuePipe } from './service-yielding/service-yielding-grid/absolute-value.pipe';
import { FormatValuePipe } from './service-yielding/service-yielding-grid/format-value.pipe';
import { AccordianUserConfigComponent } from './user-config/accordian-user-config/accordian-user-config.component';
import { SearchItemsComponent } from './search-items/search-items.component';
import { DataMagineConfigComponent } from 'src/app/common/data-magine/data-magine-config/data-magine-config.component';
import { SettingDialogPopupComponent } from './setting-dialog-popup/setting-dialog-popup.component';
import { ReceiptConfigurationComponent } from './Utilities/receipt-configuration/receipt-configuration.component';
import { UserMachineConfigurationComponent } from './Utilities/user-machine-configuration/user-machine-configuration.component';
import { RguestBookSetupComponent } from './system-config/rguest-book-setup/rguest-book-setup.component';
import { CommissionSetupModule } from '../retail/commission-setup/commission-setup.module';
import { InventoryModule } from '../retail/inventory/inventory.module';
import { SystemConfigModule } from '../retail/sytem-config/system-config.module';
import { StaffScheduleDeleteModalComponent } from './staff-schedule/staff-schedule-delete-modal/staff-schedule-delete-modal.component';
import { StaffScheduleEditModalComponent } from './staff-schedule/staff-schedule-edit-modal/staff-schedule-edit-modal.component';
import { StaffWeeklyDataTableComponent } from './staff-schedule/staff-weekly-data-table/staff-weekly-data-table.component';
import { TemplatesModule } from '../common/templates/templates.module';
import { SpaUtilitiesComponent } from './Utilities/spa-utilities/spa-utilities.component';
import { SpaSetupComponent } from './Setup/spa-setup/spa-setup.component';
import { SpaTemplatesComponent } from './Utilities/spa-templates/spa-templates.component';
import { TherapistSetupComponent } from './Setup/therapist-setup/therapist-setup.component';
import { TherapistBreakTimeComponent } from './staff-schedule/staff-weekly-data-table/therapist-break-time/therapist-break-time.component';
import { QuickidConfigComponent } from './Utilities/quickid-config/quickid-config.component';
import { StaffSchedulePopupComponent } from './staff-schedule/staff-schedule-popup/staff-schedule-popup.component';
import { combineGuestRecordBusiness } from '../common/components/combine-guest-records/combine-guest-records.business';
import { combineGuestRecordService } from './Utilities/combine-guest-records/combine-guest-records.service';
import { PrinterDefaultConfigurationComponent } from './Utilities/spa-utilities/manager-utilities/printer-default-configuration/printer-default-configuration.component';
import { ManagerUtilitiesComponent } from './Utilities/spa-utilities/manager-utilities/manager-utilities.component';
import { LostDenialReasonsSetupComponent } from './Setup/lost-denial-reasons-setup/lost-denial-reasons-setup.component';
import { ReportSchedularWrapperComponent } from './system-config/report-schedular-wrapper/report-schedular-wrapper.component';
import { EventSliderComponent } from './system-config/report-schedular-wrapper/event-slider/event-slider.component';
import { ReportModule } from '../report/reports.module';
import { RetailReportModule } from '../retail/retail-reports/reports.module';
import { LogTypeComponent } from '../common/log-type/log-type.component';
import { RelationshipComponent } from './Setup/relationship/relationship.component';
import { CommonSharedModule } from 'src/app/common/shared/shared/shared.module';
import { GuestTypeComponent } from './Setup/guest-type/guest-type.component';
import { UtilStaffReaderBoardComponent } from './Utilities/util-staff-reader-board/util-staff-reader-board.component';
import { dedupeGuestRecordBusiness } from '../common/components/dedupe-guest-records/dedupe-guest-records.business';
import { DeDupeGuestRecordsService } from './Utilities/dedupe-guest-records/dedupe-guest-records.service';
import { StaffScheduleBulkDeleteComponent } from './staff-schedule/staff-schedule-bulk-delete/staff-schedule-bulk-delete.component';
import { CgpsLoggingProfileSyncWrapperComponent } from './Utilities/cgps-logging-profile-sync-wrapper/cgps-logging-profile-sync-wrapper.component';
import { CgpsLoggingProfileSyncWrapperDataService } from '../shared/service/cgps-logging-profile-sync-wrapper.data.service';
import { EatecModule } from './eatec/eatec.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_DATE_FORMATS } from 'src/app/common/shared/localization/custom.dateAdapter';
import { JobSchedulerComponent } from './Utilities/job-scheduler/job-scheduler.component';
import { EventSchedulerConfigurationComponent } from '../common/shared/shared/event-scheduler-configuration/event-scheduler-configuration.component';
import { ClassGroupComponent } from './Setup/class-group/class-group.component';
import { ClassSetupComponent } from './Setup/class-setup/class-setup.component';
import { InstructorSetupComponent } from './Setup/instructor-setup/instructor-setup.component';
import { ClassLocationComponent } from './Setup/class-location/class-location.component';
import { ClassEquipmentComponent } from './Setup/class-equipment/class-equipment.component';
import { InstructorScheduleComponent } from './instructor-schedule/instructor-schedule.component';
import { InstructorScheduleBulkDeleteComponent } from './instructor-schedule/instructor-schedule-bulk-delete/instructor-schedule-bulk-delete.component';
import { InstructorScheduleDeleteModalComponent } from './instructor-schedule/instructor-schedule-delete-modal/instructor-schedule-delete-modal.component';
import { InstructorScheduleEditModalComponent } from './instructor-schedule/instructor-schedule-edit-modal/instructor-schedule-edit-modal.component';
import { InstructotSchedulePopupComponent } from './instructor-schedule/instructot-schedule-popup/instructot-schedule-popup.component';
import { InstructorWeeklyDataTableComponent } from './instructor-schedule/instructor-weekly-data-table/instructor-weekly-data-table.component';
import { InstructorBreakTimeComponent } from './instructor-schedule/instructor-weekly-data-table/instructor-break-time/instructor-break-time.component';
import { CancellationNoShowWrapperComponent } from './cancellation-no-show-wrapper/cancellation-no-show-wrapper.component';
import { CancellationNoShowPolicyMappingWrapperComponent } from './cancellation-no-show-policy-mapping-wrapper/cancellation-no-show-policy-mapping-wrapper.component';
import { DynamicYieldingComponent } from './dynamic-yielding/dynamic-yielding.component';
import { DynamicYieldingGridComponent } from './dynamic-yielding/dynamic-yielding-grid/dynamic-yielding-grid.component';
import { DynamicYieldingListComponent } from './dynamic-yielding/dynamic-yielding-list/dynamic-yielding-list.component';
import { CreateYieldSetupComponent } from './dynamic-yielding/create-yield-setup/create-yield-setup.component';
import { ViewYieldSetupComponent } from './dynamic-yielding/view-yield-setup/view-yield-setup.component';
import { PriceTypePipe } from './dynamic-yielding/dynamic-yielding-grid/pricetype.pipe';
import { SpaCancellationNoShowFeeComponent } from './spa-cancellation-no-show-fee/spa-cancellation-no-show-fee.component';
import { ServiceDiscountTypeComponent } from './Utilities/service-discount-type/service-discount-type.component';
import { PricingConditionPipe } from './dynamic-yielding/pricing-condition.pipe';
import { AgRangeSliderModule } from '../accounting/ag-range-slider/ag-range-slider.module';
import { LockersComponent } from './Utilities/lockers/lockers.component';
import { CreateLockersComponent } from './Utilities/create-lockers/create-lockers.component';
import { DragDropFileModule } from 'src/app/common/components/drag-drop-file/drag-drop-file.module';
import { RetailToggleSwitchModule } from '../retail/retail-toggle-switch/retail-toggle-switch.module';
import { SpaOccupancyComponent } from './Utilities/spa-occupancy/spa-occupancy.component';
import { PriceupdatePipe } from './dynamic-yielding/priceupdate.pipe';
import { AcrSetupComponent } from './system-config/acr-setup/acr-setup.component';
import { HomeDashboardConfigurationComponent } from './system-config/home-dashboard-configuration/home-dashboard-configuration.component';
import { RetailSharedModule } from '../retail/shared/retail-shared.module';
import { PriceRoundPipe } from './dynamic-yielding/priceround.pipe';
import { StaffScheduleCommentsPopupComponent } from './staff-schedule/staff-schedule-comments-popup/staff-schedule-comments-popup.component';




@NgModule({
    imports: [
        CommonModule,
        SettingRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        RetailModule,
        CommissionSetupModule,
        InventoryModule,
        SystemConfigModule,
        TemplatesModule,
        ReportModule,
        RetailReportModule,
        CommonSharedModule,
        EatecModule,
        AgRangeSliderModule,
        DragDropFileModule,
        RetailToggleSwitchModule,
        RetailSharedModule
    ],
    declarations: [AccordianUserConfigComponent, AddbreakComponent, CancelReasonComponent, SettingComponent, ViewSettingComponent, ServiceGroupComponent, ServiceLocationComponent, MedicalConditionComponent,
        SeviceEquipmentComponent, SeviceAddOnsComponent, SpaServicesComponent, SetUpComponent,
        StaffScheduleComponent, StaffSchedulePipe, PackageYieldingComponent, SettingDialogPopupComponent, SpaPackageComponent,YeildingPrice,
        PackageClassComponent, ColorLinkComponent, LunchSetupComponent, PriceTypeComponent, BreakTypeComponent, CustomFieldComponent, UtilitiesComponent,
        MultipleAppointmentsComponent, LunchSetupEditComponent, MaintenanceComponent, PasswordManagement, MultipleAppointmentsComponent, ServiceYieldingComponent,
        TherapistUnavailabilityComponent, DataMagineConfigComponent,ServiceYieldingEditComponent, ServiceYieldingGridComponent, PackagePopupComponent, UserConfigComponent, InterfacesComponent, RoleSetupComponent, UserRoleComponent, UserSetupComponent, NewUserComponent, SystemConfigComponent,
        PropertyInformationComponent, EmailConfigurationSetupComponent, AppointmentComponent, UserSettingsComponent, SpaSettingsComponent, RetailSettingsComponent, CopyRolesComponent, AbsoluteValuePipe, FormatValuePipe, SearchItemsComponent, ReceiptConfigurationComponent, LostDenialReasonsSetupComponent,
        UserMachineConfigurationComponent, RguestBookSetupComponent, StaffScheduleDeleteModalComponent, StaffWeeklyDataTableComponent, StaffScheduleEditModalComponent, SpaUtilitiesComponent, SpaSetupComponent, SpaTemplatesComponent, TherapistSetupComponent, TherapistBreakTimeComponent, QuickidConfigComponent, StaffSchedulePopupComponent,
        PrinterDefaultConfigurationComponent, ManagerUtilitiesComponent, ReportSchedularWrapperComponent, EventSliderComponent, LogTypeComponent, RelationshipComponent, GuestTypeComponent, UtilStaffReaderBoardComponent, StaffScheduleBulkDeleteComponent, CgpsLoggingProfileSyncWrapperComponent, JobSchedulerComponent,
        EventSchedulerConfigurationComponent,
        ClassGroupComponent,
        ClassSetupComponent,
        InstructorSetupComponent,
        ClassLocationComponent,
        ClassEquipmentComponent,
        InstructorScheduleComponent,
        InstructorScheduleBulkDeleteComponent,
        InstructorScheduleDeleteModalComponent,
        InstructorScheduleEditModalComponent,
        InstructotSchedulePopupComponent,
        InstructorWeeklyDataTableComponent,
        InstructorBreakTimeComponent,
        CancellationNoShowWrapperComponent,
        CancellationNoShowPolicyMappingWrapperComponent,
        DynamicYieldingComponent,
        DynamicYieldingGridComponent,
        DynamicYieldingListComponent,
        CreateYieldSetupComponent,
        ViewYieldSetupComponent,
        PriceTypePipe,
        SpaCancellationNoShowFeeComponent,
        ServiceDiscountTypeComponent,
        PricingConditionPipe,
        LockersComponent,
        CreateLockersComponent,
        SpaOccupancyComponent,
        PriceupdatePipe,
        PriceRoundPipe,
        AcrSetupComponent,
        HomeDashboardConfigurationComponent,
        StaffScheduleCommentsPopupComponent
        ],
    exports: [AddbreakComponent, CancelReasonComponent, ServiceGroupComponent, ServiceLocationComponent, MedicalConditionComponent, SeviceEquipmentComponent, SeviceAddOnsComponent, SpaServicesComponent, SharedModule, PackageYieldingComponent, SpaPackageComponent, CustomFieldComponent,
        MaintenanceComponent, PasswordManagement, PropertyInformationComponent, EmailConfigurationSetupComponent, AppointmentComponent, TherapistSetupComponent],
    providers: [SettingDialogPopupComponent, SettingsService, CgpsLoggingProfileSyncWrapperDataService,
        ServiceYieldingService,
        {
            provide: DateAdapter, useClass: CustomDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
        {
            provide: combineGuestRecordBusiness,
            useClass: combineGuestRecordService
        },
        {
            provide: dedupeGuestRecordBusiness,
            useClass: DeDupeGuestRecordsService
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SettingModule { }
