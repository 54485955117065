import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomPrefillInvoice: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: string
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields.forEach(x => {
                if (x.fieldType.SingleData.name === 'SupplierId' || x.fieldType.SingleData.name === 'TransactorId') {
                    x.fieldType.SingleData.disbaledProperty = FormGroupData[x.fieldType.SingleData.name] ? true : false;
                }
                if (x.fieldType.SingleData.name === 'CreditAccId') {
                    x.fieldType.SingleData.disbaledProperty = false;
                }
                if (x.fieldType.SingleData.name === 'InvoiceId') {
                    x['fieldType']['SingleData']['autoCompleteData'] = [];
                }
            });
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};