import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SpaLocalization } from '../localization/spa-localization';
import { PaymentConfiguration, PropertyConfiguration } from '../../shared/business/view-settings.modals';
import { GiftCardConfiguration, GiftCardConfigurationStore } from 'src/app/retail/shared/service/payment/payment-model';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';

@Injectable()
export class SpaPropertyInformation extends CommonPropertyInformation {

    public _giftCardConfiguration: GiftCardConfigurationStore;

    constructor(public localization: SpaLocalization) {
        super(localization);
        this.SetDefaultDataOnLoad();
    }

    public SetPropertyConfiguration(settings: PropertyConfiguration<any>) {
        sessionStorage.setItem('propConfig', JSON.stringify(settings.configValue));
    }

    public GetPropertyConfiguration() {
        const _config = sessionStorage.getItem('propConfig');
        return _config && JSON.parse(_config);
    }

    public SetPaymentConfiguration(payConfig: PaymentConfiguration[]) {
        this._paymentConfiguration = [];
        if (payConfig && payConfig.length > 0) {
            this._paymentConfiguration = payConfig;
        }
        sessionStorage.setItem('paymentConfiguration', _.cloneDeep(JSON.stringify(this._paymentConfiguration)));
    }

    public SetGiftCardConfiguration(giftcardConfig: GiftCardConfiguration) {
        this._giftCardConfiguration = null;
        if (giftcardConfig) {
            this._giftCardConfiguration = { activateGiftCardInterface: giftcardConfig.activateGiftCardInterface, giftCardType: giftcardConfig.giftCardType };
        }
        sessionStorage.setItem('giftCardConfiguration', _.cloneDeep(JSON.stringify(this._giftCardConfiguration)));
    }

    public GetPaymentConfigValueByKey(configKey: string, outletId: number): string {
        let payConfigValue = '';
        var payConfig = this._paymentConfiguration.find(r => r.propertyId == this._propertyId && r.outletId == outletId && r.configKey == configKey);
        if (!payConfig) { // Get default pay configuration
            payConfigValue = this._paymentConfiguration.find(r => r.propertyId == 0 && r.outletId == 0 && r.configKey == configKey).configValue;
        }
        else {
            payConfigValue = payConfig.configValue;
        }
        return payConfigValue;
    }

    public SetAuthorizeConfiguration(authConfig: any[]) {
        this._authorizeConfiguration = [];
        if (authConfig && authConfig.length > 0) {
            this._authorizeConfiguration = authConfig;
        }
        sessionStorage.setItem('authorizeConfiguration', _.cloneDeep(JSON.stringify(this._authorizeConfiguration)));
    }

    public SetDefaultDataOnLoad() {
        // Setting propety Date
        let propertyData: any = sessionStorage.getItem('propertyDate');
        if (propertyData) {
            this._currentDate = this.localization.getDate(propertyData);
        }
        else {
            this._currentDate = this.localization.getCurrentDate();
        }
        this._currentDate.setHours(0, 0, 0, 0);

        // Setting Retail-Interface switch value
        let retailSwitch: string = sessionStorage.getItem('useRetailInterface');
        if ((retailSwitch && retailSwitch.toLowerCase() == 'true')) {
            this._useRetailInterface = true;
        }
        else {
            this._useRetailInterface = false;
        }

        // Payment Configuration
        let paymentConfig: string = sessionStorage.getItem('paymentConfiguration');
        if (paymentConfig && JSON.parse(paymentConfig)) {
            this._paymentConfiguration = JSON.parse(paymentConfig);
        }
        else {
            this._paymentConfiguration = [];
        }

        // GiftCard Configuration
        let giftCardConfig: string = sessionStorage.getItem('giftCardConfiguration');
        if (giftCardConfig && JSON.parse(giftCardConfig)) {
            this._giftCardConfiguration = JSON.parse(giftCardConfig);
        }
        else {
            this._giftCardConfiguration = null;
        }


        //VAT Enable
        let VATEnableSwitch: string = sessionStorage.getItem('VATEnabled');
        if (VATEnableSwitch && VATEnableSwitch.toLowerCase() == 'true') {
            this._VATEnabled = true;
        } else {
            this._VATEnabled = false;
        }

        // Set PropertyId
        var propertyId = this.GetPropertyInfoByKey('PropertyId');
        if (propertyId) {
            this._propertyId = Number(propertyId);
        }
    }
    
    public SetSupportedPMAgentVersion(supportedPMAgentVersion) {
        sessionStorage.setItem('supportedPMAgentVersion', _.cloneDeep(JSON.stringify(supportedPMAgentVersion)));
    }

    public get isResortFinanceEnabled() {
        return sessionStorage.getItem('enableResortFinance') ? sessionStorage.getItem('enableResortFinance').toLowerCase() === "true" : false;
    }
}
