import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AgilysysButtonModel } from '../../agilysys-buttons/models/agilysys-buttons.interface';
import { ErrorPopModel } from '../model/pop-up.interface';
@Component({
    selector: 'app-agilysys-error',
    templateUrl: './agilysys-error-popup.component.html',
    styleUrls: ['./agilysys-error-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgilsysErrorPopComponent {
    agilysyserrorlogButton: AgilysysButtonModel[];
    VisibleInfoMessage: boolean;
    constructor(public dialogRef: MatDialogRef<AgilsysErrorPopComponent>, private toastrService: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: ErrorPopModel) {
            this.agilysyserrorlogButton = [
                {
                  Id: 1,
                  Name: 'Ok',
                  DisplayName: 'OK',
                  Class: 'agilysys-button-primary',
                  DisableProperity: false,
                  onCellClick: (returnValue: AgilysysButtonModel) => {
                     this.dialogRef.close(returnValue.Name);
                  }
                }
              ];
              this.VisibleInfoMessage = ( this.data.InfoBtnState === 'More' ) ? true : false;
              this.data.InfoBtnState = (this.VisibleInfoMessage) ? 'Less' : 'More';
        }

        showErrorInfo(): void {
          try {
            this.VisibleInfoMessage = !this.VisibleInfoMessage;
            this.data.InfoBtnState = (this.VisibleInfoMessage) ? 'Less' : 'More';
          } catch ( error ) {
            console.error(error);
          }
        }

}