import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ServiceYieldingService } from './service-yielding-service';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
  selector: 'app-service-yielding',
  templateUrl: './service-yielding.component.html',
  styleUrls: ['./service-yielding.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceYieldingComponent implements OnInit, OnDestroy {
  showNewYield:boolean = false;
  subscription: ISubscription;

  ngOnInit(): void {
    this.subscription = this._yieldService.newYieldSubject.subscribe(val => {
      this.showNewYield = val;
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  constructor(public _yieldService: ServiceYieldingService) {
  
  }
}
