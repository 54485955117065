import { Component, OnInit,  Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DropDownData} from "../../../business/report.modals"; 
import { ReportBusinessService } from '../../../business/report-business.service';
import { SpaUtilities } from '../../../../shared/utilities/spa-utilities';

@Component({
  selector: 'app-inventory-transfer',
  templateUrl: './inventory-transfer.component.html',
  styleUrls: ['./inventory-transfer.component.scss']
})
export class InventoryTransferComponent implements OnInit {
  InventoryTransferFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter();
  public captions: any = this.localization.captions.retailsetup;
    itemcatradiogrp: { id: number; description: any; showInDropDown: boolean }[];
  valuesSelected: any[] = [];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, private controlContainer: ControlContainer, public business: ReportBusinessService, private utils: SpaUtilities) {
    this.itemcatradiogrp = [{ id: 1, description: this.captions.RetailItemRetailPOSOnly, showInDropDown: true },
      { id: 2, description: this.captions.RetailItemAvailableForSpaPackages, showInDropDown: true },
      { id: 3, description: this.captions.SpaServices, showInDropDown: true }, { id: 4, description: this.captions.SpaPackage, showInDropDown: true },
      { id: 5, description: this.captions.AppointmentAddon, showInDropDown: true }, { id: 7, description: this.captions.RentalItem, showInDropDown: true }];
  }

  ngOnInit() {
  
    this.InventoryTransferFormGrp = this.fb.group({
      inventorytype: new UntypedFormControl([this.defaultData]),
      categoryView: this.localization.captions.reports.CategoryView,
      category: new UntypedFormGroup({})
    });
    this.business.FilterDataSource["inventorytype"] = this.itemcatradiogrp;
    this.formReady.emit(this.InventoryTransferFormGrp);
  }

  /**
  * After a form is initialized, we link it to our main form
  */
  formInitialized(form: UntypedFormGroup) {
    this.InventoryTransferFormGrp.setControl('category', form);
  }
  getFormControlValue(event) {
    if (event[0] === 'inventorytype') {
    this.InventoryTransferFormGrp.setControl('inventorytype', event[1]);
    }
  }
}
