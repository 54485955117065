import { Injectable } from '@angular/core';

@Injectable()
export class ViewMoreServiceService {
  showViewMore: boolean = false;
  VM_displayData: { 'width': number; 'height': number; 'left': any; 'top': any; 'popupposition': any; 'disaplydataArr': any; 'selecteddisplaydataArr': any; 'SDcount': any; 'section': any; 'dispArrKey': any; };

  setdata(e,disaplydataArr,selecteddisplaydataArr,dispcount,section,dispArrKey){
    let left, top, popupposition ;
    this.showViewMore =true;
    let popupwidth = (((disaplydataArr.length - dispcount) * 147)  < 294) ? (((disaplydataArr.length - dispcount) * 147)+46) : 364 ;
    let noOfrows = Math.ceil((disaplydataArr.length - dispcount) / 2);
    let popupHeight = ((37*noOfrows)+25) < 126 ? ((37*noOfrows)+25) : 126;
    left = (e.clientX - e.offsetX) - ( popupwidth/2) + (e.target.parentElement.offsetWidth/2);
    if((window.innerHeight - (e.clientY + e.offsetY  )) > (popupHeight+ 10))  {
       popupposition = 'top';
       top = (e.clientY - e.offsetY )+ e.target.parentElement.offsetHeight + 10; //10 - up arrow height;
    }else{
       popupposition = 'bottom';
       top = (e.clientY - e.offsetY - popupHeight  ) + e.target.parentElement.offsetHeight - 20; //20 - down arrow height;
    }

    this.VM_displayData={
      'width':popupwidth ,
      'height':popupHeight,
      'left':left,
      'top':top ,
      'popupposition':popupposition,
      'disaplydataArr':disaplydataArr,
      'selecteddisplaydataArr':selecteddisplaydataArr,
      'SDcount':dispcount,
      'section':section,
      'dispArrKey':dispArrKey
    }
  }


}
