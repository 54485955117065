import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer} from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {
  depositFrmGrp: UntypedFormGroup;
  captions: any;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();
  radioFilter : any  = [];
  constructor(private fb: UntypedFormBuilder, private controlContainer: ControlContainer, public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
    this.radioFilter = [
      { id: 0, name: this.captions.Both },
      { id: 1, name: this.captions.DepositDueOnly },
      { id: 2, name: this.captions.DepositAppliedOnly }
    ];
  }

  ngOnInit() {
    this.depositFrmGrp= this.fb.group({
      radioFilter: 0,
    });

    this.formReady.emit(this.depositFrmGrp);
  }

}