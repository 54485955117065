
import { FormGroup, Validators } from '@angular/forms';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomTransferOutToggle: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: any, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: FormType[]
    ): any => {
        try {
           if (FieldGrouping.CustomFormAction) {
                const ToLocationId = FormGroupData.value[FieldGrouping.OdataKey];

                const CustomUrl = `/inventory/api/Locations`;
                const GetDataByIdUrl = `${CustomUrl + '?$filter=id eq ' + ToLocationId}`;
                httpService.GethttpMethod(GetDataByIdUrl).subscribe(async locationdata => {
                   if (locationdata && locationdata['value'] && locationdata['value'].length > 0) {
                       const dataValue = locationdata['value'][0];
                       const EnableField =
                        FormField.filter(x => x.fieldType.SingleData.name === FieldGrouping.CustomFormAction.EnabledField)[0];
                        const DisabledField =
                        FormField.filter(x => x.fieldType.SingleData.name === FieldGrouping.CustomFormAction.DisabledField)[0];
                        const EnableFieldIndex =
                        FormField.findIndex(x => x.fieldType.SingleData.name === FieldGrouping.CustomFormAction.EnabledField);
                       if (dataValue && dataValue.LocationTypeId === 8) {
                           let authPayload = {} as any;
                           authPayload.ClientUrl = '/inventory/api/GetCustomers';
                           const customerPostPayload = {} as any;
                           customerPostPayload.transTypeId = MasterDataCollection.TransactionType;
                           customerPostPayload.locationId = FormGroupData.value.ToLocationId;
                           customerPostPayload.searchText = '';
                           customerPostPayload.skip = 0;
                           customerPostPayload.take = 100;
                           customerPostPayload.getTotalRecCount = true;
                           customerPostPayload.fetchAll = false;
                           customerPostPayload.totalCount = 0;
                           customerPostPayload.lookupField = '';
                           customerPostPayload.orderByColumn = 'Name';
                           customerPostPayload.orderByDirection = 1;
                           authPayload.Payload = customerPostPayload;
                           const customerResultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
                           FormField[EnableFieldIndex].fieldType.SingleData.autoCompleteData = customerResultData.ResultData;
                           FormField[EnableFieldIndex].autoCompleteConfig.EnableLoadMore =
                               customerResultData.ResultData?.length === customerResultData.TotalCount ? false : true;
                           FormField[EnableFieldIndex].hide = false;
                           FormField[EnableFieldIndex].fieldType.SingleData.disbaledProperty = false;
                           FormGroupData.controls[DisabledField.fieldType.SingleData.name].setValue(true);
                           FormGroupData.controls[DisabledField.fieldType.SingleData.name].clearValidators();
                           FormGroupData.controls[DisabledField.fieldType.SingleData.name].updateValueAndValidity();
                           FormGroupData.controls[EnableField.fieldType.SingleData.name].enable();
                           FormGroupData.controls[EnableField.fieldType.SingleData.name].setValidators([Validators.required]);
                           FormGroupData.controls[EnableField.fieldType.SingleData.name].updateValueAndValidity();
                       } else if (dataValue && dataValue.LocationTypeId !== 8) {
                        FormField[EnableFieldIndex].hide = true;
                        FormField[EnableFieldIndex].fieldType.SingleData.disbaledProperty = true;
                        FormGroupData.controls[EnableField.fieldType.SingleData.name].setValue('');
                        FormGroupData.controls[EnableField.fieldType.SingleData.name].clearValidators();
                        FormGroupData.controls[EnableField.fieldType.SingleData.name].updateValueAndValidity();
                        FormGroupData.controls[DisabledField.fieldType.SingleData.name].setValue(false);
                        FormGroupData.controls[DisabledField.fieldType.SingleData.name].enable();
                        FormGroupData.controls[DisabledField.fieldType.SingleData.name].updateValueAndValidity();
                       }
                    console.log(locationdata);
                   }
                });
           }
        } catch (error) {
            console.log('error occured CustomDefaultSelect', error);
        }
    };