import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DialogFooterService } from './dialog-footer.service';
import { SaveButtonObj } from '../../models/ag-models';

@Component({
  selector: 'app-spa-dialog-footer',
  templateUrl: './dialog-footer.component.html',
  styleUrls: ['./dialog-footer.component.scss'],
  providers: [DialogFooterService]
})
export class DialogFooterComponent {
  isEdit: boolean;
  isDisabled: boolean;
  saveButtonText: string;
  cancelButtonText: string;
  captions: any;
  @Input('clickThrottleTime') clickThrottleTime: number;
  @Input('buttonObj')
  set buttonObj(value: SaveButtonObj) {
    this.isEdit = value.isEdit;
    this.isDisabled = value.disabled;
    this.cancelButtonText = value.customCancelText ? value.customCancelText : this.captions.btn_cancel;
    this.getSaveButtonText(value.customSaveText);
  }
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  constructor(private _footerService: DialogFooterService) {
    this.captions = this._footerService.getCaptions();
  }

  saveHandler() {
    this.save.emit();
  }

  cancelHandler() {
    this.cancel.emit();
  }

  private getSaveButtonText(customButtonText: string) {
    if (customButtonText) {
      this.saveButtonText = customButtonText;
    } else {
      this.saveButtonText = this.isEdit ? this.captions.btn_update : this.captions.btn_save;
    }
  }

}
