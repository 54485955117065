import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { CreateTransactionItemlist } from '../create-transcation/item-transaction';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomCreditsFormConfiguration, CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';


export const CreditCardCalcApCredit: CustomCreditsFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, 
    CustomData?: TransactionConfiguration,
    transaction ?: TransactionConfiguration
): any => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod) {
          MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData[0]['AllocatedAmount'] = 
            FormGroupData.controls.CreditTotal.value;
          const balance = MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData[0]['Balance'];
            MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
              [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
            MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = {
               ...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig };
            MasterDataCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailFormHeader[1].Value = 
            `${Formater('Currency', FormGroupData.controls.CreditTotal.value, null, null)}`;
          if ( FormGroupData.controls.Supplierbalance.value >=  FormGroupData.controls.CreditTotal.value ) {
            const creditTotolvalue = FormGroupData.controls.CreditTotal.value;
            const supplierBalance = FormGroupData.controls.Supplierbalance.value;
            const remainigValue = supplierBalance - creditTotolvalue;
            const WholeNumberCurrencyLength = CommonService.GetCurrencyVisible();
            FormGroupData.controls.Remaining.setValue(remainigValue.toFixed(WholeNumberCurrencyLength));
            CommonService.triggerBlurEvent('Remaining');
            if (FormGroupData.controls.CreditTotal.value) {
              FormGroupData.controls.Undistributed.setValue(0);
              CommonService.triggerBlurEvent('Undistributed');
            }
          } else {
            const creditTotolvalue = FormGroupData.controls.CreditTotal.value;
            const supplierBalance = FormGroupData.controls.Supplierbalance.value;
            const remainigValue = supplierBalance - creditTotolvalue;
            const WholeNumberCurrencyLength = CommonService.GetCurrencyVisible();
            FormGroupData.controls.Remaining.setValue(remainigValue.toFixed(WholeNumberCurrencyLength));
            if (FormGroupData.controls.CreditTotal.value) {
              FormGroupData.controls.Undistributed.setValue(0);
            }
          }
          if ( FormGroupData.controls.CreditTotal.value === '' || FormGroupData.controls.CreditTotal.value === null) {
            FormGroupData.controls.Remaining.setValue('');
            CommonService.triggerBlurEvent('Remaining');
          }
          // if (((FormGroupData.controls.Supplierbalance.value !== '' && FormGroupData.controls.Supplierbalance.value !== null)
          //  && (FormGroupData.controls.CreditTotal.value !== '' && FormGroupData.controls.CreditTotal.value !== null)) &&
          //  ((balance === parseFloat(FormGroupData.controls.CreditTotal.value)) ||
          // (parseFloat(FormGroupData.controls.Supplierbalance.value) === parseFloat(FormGroupData.controls.CreditTotal.value)))) {
          //   MasterDataCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
          //     if (x.buttonKey === 'confirm') {
          //        x.displayProperity = !FormGroupData.valid; 
          //     }    
          //   });
          // } else { 
          //   MasterDataCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
          //     if (x.buttonKey === 'confirm') {
          //        x.displayProperity = true; 
          //     }    
          //   });
          // }
        }
        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

