import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AgilysysButtonModel } from '../../agilysys-buttons/models/agilysys-buttons.interface';
import { PdfViewerPopup } from '../model/pop-up.interface';
@Component({
    selector: 'app-agilysys-pdfviewer',
    templateUrl: './agilysys-pdfviewer-popup.component.html',
    styleUrls: ['./agilysys-pdfviewer-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgilysysPdfViewerComponent {
    agilysyserrorlogButton: AgilysysButtonModel[];
    constructor(public dialogRef: MatDialogRef<AgilysysPdfViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PdfViewerPopup, private sanitizer: DomSanitizer) {
            this.agilysyserrorlogButton = [
                {
                  Id: 1,
                  Name: 'Ok',
                  DisplayName: 'Download',
                  Class: 'agilysys-button-primary',
                  DisableProperity: false,
                  onCellClick: (returnValue: AgilysysButtonModel) => {
                    const fileType = ( data.type === 'pdf') ? '.pdf' : ( data.type === 'doc') ? '.doc' : '.docx' ;
                     this.dialogRef.close();
                     const a = document.createElement('a');
                     a.href = data.PdfViewerUrl;
                     a.target = '_blank';
                     a.download = 'Eatec:' + data.PopupHeaderContent + ':' + new Date().toISOString().split('.')[0] + fileType;
                     a.click();
                     a.remove();
                  }
                }
              ];
        }

}