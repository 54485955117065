<section class="multiselectdropdown {{field.class}}" id="multiselectdropdown"
  *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'">
  <div [formGroup]="form">
    <mat-form-field title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
      selectedLabels : formFieldToolTip}}" appearance="fill" class="dropdown-container" [ngClass]="field.fieldType?.SingleData?.disbaledProperty === true ? 'textbox-disabled':  ''">
      <mat-label>{{formFieldlabel}}</mat-label>
      <mat-select (selectionChange)="changeSelect($event)" (openedChange)="openedChange($event)" [required]="isRequired" [disableOptionCentering]="true" class="dropdown-container-select" #multiselect [formControlName]="formFieldName" multiple="true">
        <!-- <input  *ngIf="enableDropDownSearch" class="agilysys-dropdown-search" type="text" placeholder="search" [formControl]="dropDownsearchControl"/> -->        
        <mat-option *ngIf="enableDropDownSearch" [value]="null" class="multiselectdropdown-search-option" (click)="$event.stopPropagation();$event.preventDefault();">
            <mat-form-field class="agilysys-multiselectdropdown-search-field" appearance="outline" (click)="$event.stopPropagation();">
              <input matInput #search type="text" placeholder="Search" [formControl]="dropDownsearchControl" (keydown)="$event.stopPropagation()">
          </mat-form-field>
        </mat-option>
        <mat-checkbox *ngIf="field.fieldType[fieldType].options?.length > 0 && enableSelectAll" (change)="checkBoxChange($event)" class="multi-dropdown-checkbox" [formControl]="checked">Select All</mat-checkbox>
        <mat-option (click)="selectAll(ev)" #ev class="multiselect__option" 
          [ngClass]="searchKey && !data?.label?.toString()?.toLowerCase()?.includes(searchKey.toLowerCase()) ? 'd-none' : ''" 
          *ngFor="let data of field.fieldType[fieldType].options" [value]="data.key" [disabled]="data?.isdisabled">
          {{data.label}}
        </mat-option>
        <span class="mat-option" *ngIf="(field.fieldType[fieldType].options?.length === 0 && dropDownsearchControl.value) || !enableSelectAll"
        (click)="$event.stopPropagation();">
        No Data Found
      </span>
      </mat-select>
      <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
    </mat-form-field>
  </div>
</section>
