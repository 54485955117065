import { Injectable } from '@angular/core';
import { SpaFormAgent } from '../spa-form';
import { HttpServiceCall } from './http-call.service';
import { UserBreakPoint, BaseResponse } from '../business/shared.modals';
import { Host, ButtonType } from '../globalsContant';
import { SPAConfig } from '../../core/config/SPA-config';
import { SpaUtilities } from '../utilities/spa-utilities';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { HttpCacheService } from 'src/app/common/services/cache/http-cache.service';
import { Product } from 'src/app/common/shared/shared/globalsContant';
import { cloneDeep } from 'lodash';
import { Localization } from 'src/app/common/localization/localization';
import { HttpResponse } from '@angular/common/http';
import { UICache } from 'src/app/common/services/cache/cache-constants';
declare var $: any;



@Injectable()
export class BreakPointAccess extends SpaFormAgent {

    private IsOnAppLoad: boolean = false;
    private ForViewOnly: UserBreakPoint[] = [];
    private FunctionName: string = '';
    public AllBreakPoint: UserBreakPoint[] = [];
    authorizationIndex: any = null;
    uiCache : UICache; 
    propertyId:number;

    constructor(public http: HttpServiceCall, public spaConfig: SPAConfig, public utils: SpaUtilities, public localization: SpaLocalization,
        public httpCacheService: HttpCacheService,public commonLocalization: Localization) {
        super(http);
    }

    public IsViewOnly(breakPoint: number): boolean {
        let _isViewOnly: boolean = false;
        if (this.ForViewOnly.length > 0) {
            let _bp: UserBreakPoint[] = this.ForViewOnly.filter(bp => bp.breakPointNumber == breakPoint);
            if (_bp.length > 0) {
                _isViewOnly = _bp[0].view;
            }
        }
        return _isViewOnly;
    }

    public CheckForAccess(breakPoints: number[], showUserMessage: boolean = true): boolean {
        let hasAccess: boolean = false;
        this.ForViewOnly = [];
        if (this.IsOnAppLoad) {
            hasAccess = this.IsAuthorized(breakPoints, this.AllBreakPoint);
        }
        else {
            let _bp: BaseResponse<UserBreakPoint[]> = this.GetBreakPoint(breakPoints);
            hasAccess =  _bp && this.IsAuthorized(breakPoints, _bp.result);
        }
        if (!hasAccess && showUserMessage) {
            this.showBreakPointPopup();
        }
        return hasAccess;
    }

    public CheckForAtleastOneAccess(breakPoints: number[], showUserMessage: boolean = true): any {
        let hasAccess: boolean = false;
        this.ForViewOnly = [];
        if (this.IsOnAppLoad) {
            hasAccess = this.IsAuthorized(breakPoints, this.AllBreakPoint);
        }
        else {
            let _bp: BaseResponse<UserBreakPoint[]> = this.GetBreakPoint(breakPoints);
            hasAccess =  _bp && this.IsAtleastOneAuthorized(breakPoints, _bp.result);
        }
        return {
            hasAccess: hasAccess,
            authorizationIndex: this.authorizationIndex
        };
    }

    private IsAtleastOneAuthorized(requestedBreakPoints: number[], breakPointResponse: UserBreakPoint[]): boolean {
        if (!breakPointResponse || breakPointResponse.length == 0) {
            return false;
        }
        let hasAccess = false;
        this.authorizationIndex = null;
        for (let i = 0; i < requestedBreakPoints.length; i++) {
            const element = requestedBreakPoints[i];
            if(element === null){
                hasAccess = true;
                this.authorizationIndex = i;
                break;
            }
            const _breakpoint = breakPointResponse.find(bp => bp.breakPointNumber == element);
            if (_breakpoint && (_breakpoint.allow || _breakpoint.view)) {
                hasAccess = true;
                this.authorizationIndex = i;
                break;
            }
        }
        return hasAccess;
    }

    private IsAuthorized(requestedBreakPoints: number[], breakPointResponse: UserBreakPoint[]): boolean {
        if (!breakPointResponse || breakPointResponse.length == 0) {
            return false;
        }
        let respBPArr: number[] = [];
        this.ForViewOnly = breakPointResponse.filter(r => requestedBreakPoints.indexOf(r.breakPointNumber) > -1);
        breakPointResponse.forEach(bp => respBPArr.push(bp.breakPointNumber));
        for (let i = 0; i < requestedBreakPoints.length; i++) {
            const element = requestedBreakPoints[i];
            const _breakpoint = breakPointResponse.find(bp => bp.breakPointNumber == element);
            if (_breakpoint && (_breakpoint.allow || _breakpoint.view)) {
                continue;
            } else {
                this.FunctionName = this.localization.captions.breakpoint[_breakpoint.breakPointNumber];
                return false;
            }
        }
        return true;
    }

    public showBreakPointPopup(functionName?: string, callback?: (result: string, extraParams?: any[]) => void) {
        let message: string;
        if (functionName) {
            message = `${this.localization.captions.common.BreakPointAccessDeniedMsg}
             <br><br>${this.localization.captions.common.Breakpoint}: ${functionName}`;
        }
        else {
            message = `${this.localization.captions.common.BreakPointAccessDeniedMsg}
             <br><br>${this.localization.captions.common.Breakpoint}: ${this.FunctionName}`;
        }
        this.utils.ShowErrorMessage(this.localization.captions.common.AccessDenied, message, ButtonType.Ok, callback);
    }
    public GetAllBreakPoints(): void {
        this.makeGetCall("GetAllBreakPoint", Host.authentication, []);
    }

    public GetBreakPoint(breakPoint: number[]): BaseResponse<UserBreakPoint[]> {
        let _breakpoint: BaseResponse<UserBreakPoint[]>;
        let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
        this.propertyId =Number(this.commonLocalization.GetPropertyInfo('PropertyId'));
        let enableUICache = propConfig?.UICacheEnabled;
        if (enableUICache && enableUICache.toLowerCase() == "true") {
            let cachedResponse = this.httpCacheService.get(this.propertyId+"_"+this.buildBreakPointRouteRole());
            if (cachedResponse) {
                _breakpoint = cachedResponse.body;
            } else {
                $.ajax({
                    url: this.buildBreakPointRouteRole(),
                    headers: {
                        "Authorization": "Bearer" + " " + sessionStorage.getItem("_jwt")
                    },
                    async: false,
                    success: function (result) {
                        _breakpoint = result;
                    },
                    error: function (result) {
                        console.error(result);
                    }
                })
                if (_breakpoint) {
                    const response = new HttpResponse({
                        body: _breakpoint,
                        status: 200,
                        headers: null,
                        url: this.buildBreakPointRouteRole()
                    });
                    this.setCache(response);
                }

            }
            return _breakpoint;
        }
        else {
            $.ajax({
                url: this.buildBreakPointRoute(breakPoint),
                async: false,
                success: function (result) {
                    _breakpoint = result;
                },
                error: function (result) {
                    console.error(result);
                }
            });
            return _breakpoint;
        }
    }

    buildBreakPointRoute(breakPoint: number[]) {
        let route = this.spaConfig.getUrl("host." + Host.authentication) + this.spaConfig.getUrl("GetBreakPoint");
        let id = this.utils.GetPropertyInfo('RoleId');
        return route + id + '?bkpn=' + breakPoint.join("&bkpn=");
    }
    buildBreakPointRouteRole() {
        let route = this.spaConfig.getHost(Host.authentication) + '/' + this.spaConfig.getUrl("GetBreakpointforRoleAsync");
        let id = this.utils.GetPropertyInfo('RoleId');
        const productId = this.utils.GetPropertyInfo('ProductId');
        if (Number(productId) === Product.GOLF || Number(productId) === Product.SNC || Number(productId) === Product.RETAIL || Number(productId) === Product.PMS) {
            id = this.utils.GetUserInfo('roleId');
        }
        return route + id;
    }


    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
        if (callDesc == "GetAllBreakPoint") {
            this.AllBreakPoint = <any>result.result;
        }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
console.error(error.result);
  }

    setCache(result: any) {
        this.uiCache = cloneDeep(this.commonLocalization.uiCacheData);
        const productId = Number(this.utils.GetPropertyInfo('ProductId'));
        let product = this.uiCache?.products.find(product => product.productId === productId);
        let containsPartialURL = false;
        if (product) {
            this.iterateEntitiesInModules(product.modules, (entityName, entityDetails) => {
                const partialURL = this.buildBreakPointRouteRole();
                containsPartialURL = entityDetails.apiRouteContains ?
                    partialURL.includes(entityDetails.apiRouteContains) : partialURL.endsWith(entityDetails.apiRouteEndsWith);
                if (containsPartialURL) {
                    this.httpCacheService.set(entityName, this.propertyId+"_"+this.buildBreakPointRouteRole(), result, entityDetails.cacheExpiry);
                }
            });
        }

    }
    iterateEntitiesInModules(modules: any[], callback: (entityName: string, entityDetails: any) => void): void {
        modules.forEach(module => {
            for (let entityName in module.entitiestobeCached) {
                if (module.entitiestobeCached.hasOwnProperty(entityName)) {
                    let entityDetails = module.entitiestobeCached[entityName];
                    callback(entityName, entityDetails);
                }
            }
        });
    }

}
