import { QueryInterface } from 'src/app/eatecui/source/shared/models/query-interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { rejects } from 'assert';
import { MasterInventoryManagement, RestDataCollection, RestDataConfiguration } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { GlobalMasterRestData } from '../global-custom-config.interface';

export const CustomRestDataCollection: GlobalMasterRestData = (httpservice: HttpService, MasterInventory ?: MasterInventoryManagement, 
TransCollection ?: TransactionConfiguration ): any => {
    try {
        return new Promise((resolve, reject) => {
          if ( MasterInventory && MasterInventory.hasOwnProperty('MasterRestDataCollection') ) {
              if ( MasterInventory && MasterInventory.MasterRestDataCollection.RestDataConfig.length > 0  ) {
                MasterInventory.MasterRestDataCollection.RestDataConfig.forEach(async (restconfig: RestDataConfiguration, i: number) => {
                    let endPointUrl: any = restconfig.RestCustomFilter;
                    if ( restconfig.RestCustomFilter && restconfig.RestCustomFilter.length > 0 ) {
                        const query = QueryInterface.FormFilterQuery(restconfig.RestCustomFilter);
                        endPointUrl  = restconfig.RestUrl + `?$filter=${query}`;
                    }
                    if ( restconfig && restconfig.hasOwnProperty('RestCustomSelect')) {
                        endPointUrl = endPointUrl + `&$select=${restconfig.RestCustomSelect}`;
                    }
                    if ( restconfig && restconfig.hasOwnProperty('RestCustomSort')) {
                        endPointUrl = endPointUrl + `&$orderby=${restconfig.RestCustomSort}`;
                    }
                    const dataResult = await httpservice.GethttpMethod(endPointUrl).toPromise();
                    if ( dataResult ) {
                        const restData: RestDataCollection = {
                            [restconfig.Name]: dataResult.hasOwnProperty('value') ? dataResult['value'] : dataResult
                          };
                        MasterInventory.MasterRestDataCollection.RestDataCollection.push(restData);
                    }
                    if ( MasterInventory && MasterInventory.MasterRestDataCollection.RestDataConfig.length - 1 === i ) {
                        resolve(true);
                    }
                });
              }
          }
        });
    } catch (error) {
        console.error(error);
    }
};