import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RetailInventorySettingComponent } from './inventory.component';

const routes: Routes = [
  {
    path: '',
    component: RetailInventorySettingComponent,
    children: [
      { path: '', redirectTo: 'inventory', pathMatch: 'full' },
      { path: 'inventory', component: RetailInventorySettingComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryRoutingModule { }
