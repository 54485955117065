import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { sortBy } from 'lodash';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { GuestPolicyDetail } from '../shared/shared.modal';
import { DataRetentionBusiness } from './data-retention.business';
import { DataPurge } from './data-retention.model';
@Component({
  selector: 'app-data-retention',
  templateUrl: './data-retention.component.html',
  styleUrls: ['./data-retention.component.scss'],
  providers: [DataRetentionBusiness],
  encapsulation: ViewEncapsulation.None
})
export class DataRetentionComponent implements OnInit {

  captions: any;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  sectionList;
  fieldList = []
  selectedSections = []
  selectedFields = []
  selectedSection = 0;
  isAllSelected: boolean = false;
  automationId:string="DataRetention";
  @Output() onSaveEmit = new EventEmitter();
  @Output() onCancelEmit = new EventEmitter();
  @Input("inputData") guestData: GuestPolicyDetail;
  constructor(private localization: Localization, private business: DataRetentionBusiness
    , private utils: CommonUtilities) {
    this.captions = this.localization.captions;
  }


  ngOnInit(): void {
    this.initializeInputs()
    this.initializeDataGroupFeilds();
  }
  initializeInputs() {
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_purge,
      disabledproperty: true
    };

    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel
    };
  }
  initializeDataGroupFeilds() {
    if (this.guestData) {
      this.business.GetDataGroups(0, this.guestData.policyCategoryType).then(res => {
        this.sectionList = res
        this.fieldList = sortBy(this.sectionList[0].selectedList, "value")
      })
    }
  }
  onCancel(e) {
    this.onCancelEmit.emit();
  }
  onSave(e) {
    let dataPurge: DataPurge = this.getDataPurgeData();
    this.onSaveEmit.emit(dataPurge);
  }
  getDataPurgeData(): DataPurge {
    let dataPurge: DataPurge = {
      GuestId: '',
      DataGroupIds: []
    }
    if (this.selectedFields.length > 0) {
      let dataGroupIds = this.selectedFields.map(x => x.id);
      dataPurge.GuestId = this.guestData.id;
      dataPurge.DataGroupIds = dataGroupIds;
    }
    return dataPurge;
  }

  onFieldsCheck(event, item) {
    this.fieldList.forEach(element => {
      if (element.value == item.value) {
        item.checked = event.checked;
        if (event.checked) {
          this.selectedFields.push(item);
        }
        else {
          this.selectedFields = this.fieldList.filter(res => res.checked);
        }
      }
    });
    this.isAllSelected = (this.selectedFields.length == this.fieldList.length);
    this.actionButton.disabledproperty = !(this.selectedFields.length > 0);
    this.actionButton = { ...this.actionButton };
  }

  onAllCheck(e) {
    this.isAllSelected = e.checked;
    this.fieldList.forEach(element => {
      element.checked = e.checked
    });
    this.selectedFields = this.fieldList.filter(res => res.checked);
    this.actionButton.disabledproperty = !(this.selectedFields.length > 0);
    this.actionButton = { ...this.actionButton };
  }
}
