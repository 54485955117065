import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuardService {

	constructor(private router: Router) { }

	canActivate(): boolean | Promise<boolean> {

		if (sessionStorage.getItem('a5smm_utoken') != null && sessionStorage.getItem('propertyDate') != null) {
			return true;
		}
		this.router.navigate(['/login']);
		return false;
	
	}

	redirectToLoginPage() {
		this.router.navigate(['/login']);
	}

}
