import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { ServiceGroup, ServiceYield } from '../../../shared/business/view-settings.modals';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { HttpMethod } from '../../../shared/service/http-call.service';
import { PromptType, ButtonOptions,ButtonType, Maxlength } from '../../../shared/globalsContant';
import * as moment from 'moment';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { UserAlerts } from '../../../core/config/alerts-config';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { ReplaySubject } from 'rxjs';
import { ServiceYieldingService } from '../service-yielding-service';
import { BaseResponse } from '../../../shared/business/shared.modals';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import { ViewMoreServiceService } from '../../../shared/view-more/view-more-service.service';
import { takeUntil } from 'rxjs/operators';
import { ButtonValue } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-service-yielding-edit',
  templateUrl: './service-yielding-edit.component.html',
  styleUrls: ['./service-yielding-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceYieldingEditComponent implements OnInit, OnDestroy {
  newYieldForm: UntypedFormGroup;
  minStartDate: Date = this.PropertyInfo.CurrentDate;
  minEndDate: Date = this.PropertyInfo.CurrentDate;
  selectedDaysArr: any = [];
  availableDaysArr: any = [];
  DaysArr: any = this.utilities.getShortWeekArrayLocaleSorted();

  public ServiceGroups: any = [];

  selectedServiceGrpArr: any = [];

  Services: any = [];
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  serviceDescription: any = "Service Description";

  errStartDate: string = this.captions.MissingStartDate;
  errEndDate: string = this.captions.MissingEndDate;
  errMissingValue: string = this.captions.MissingYieldValue;

  errStart: any = this.captions.StartDate;
  errEnd: any = this.captions.EndDate;

  AvailPercent: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  AvailServices: any;
  SelectedServices: any;
  Y1BookingPercent: any = '';
  Y2BookingPercent: any = '';
  Y3BookingPercent: any = '';
  yieldCaption = `${this.captions.Value} (%)`;
  yieldCurrencyCaption = `${this.captions.Value} (${this.localization.currencySymbol})`;

  Y1ValuePlaceHolder: any = this.yieldCaption;
  Y2ValuePlaceHolder: any = this.yieldCaption;
  Y3ValuePlaceHolder: any = this.yieldCaption;

  Y1FlatAmt = false;
  Y2FlatAmt = false;
  Y3FlatAmt = false;
  Y1Value: any = "";
  Y2Value: any = "";
  Y3Value: any = "";

  serviceArray = false;
  serviceArrayMore = false;
  daysArrayVar = false;
  EnableSavePipe = false;
  maxToSell: any = "";
  isClose = false;
  isShowAllServices: any;
  isclicked: boolean = false;

  saveText: string = this.allCaptions.common.Save;
  isUpdate = false;

  HeaderLabel: string = this.captions.NewYieldSetup;

  isStartDateTouched = false;
  isEndDateTouched = false;
  isMaxToSellTouched = false;
  isCloseTouched = false;
  isYield1PercentTouched = false;
  isYield1FlatTouched = false;
  isYield1ValueTouched = false;
  isYield2PercentTouched = false;
  isYield2FlatTouched = false;
  isYield2ValueTouched = false;
  isYield3PercentTouched = false;
  isYield3FlatTouched = false;
  isYield3ValueTouched = false;
  isAvailableDaysTouched = false;
  isServiceTouched = false;
  statusSet = false;
  rightArrowDisabled = false;
  leftArrowDisabled = false;
  yieldMaxLength1 = Maxlength.PERCENTAGE;
  yieldMaxLength2 = Maxlength.PERCENTAGE;
  yieldMaxLength3 = Maxlength.PERCENTAGE;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('buttonView1') btnView: ElementRef;
  @ViewChild('buttonView2') btnView2: ElementRef;
  @ViewChild('buttonView3') btnView3: ElementRef;
  availableBtnSpace: any;
  CloseYield: any;
  @HostListener('window:resize', ['$event'])
  noShowIcon: any;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel:string;
  onResize(event) {
    this.testText();
  }
  totalbtnWidth: any;
  totalScrollWidth: any;
  selectedBtn: any; selectedBtn1: any;
  isEditService:boolean=false;
  isOverwiteExisting:boolean=false;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  constructor(private _yieldService: ServiceYieldingService,
    private userAlert: UserAlerts,
    private Form: UntypedFormBuilder,
    private business: ViewSettingClientBusiness,
    private utilities: SpaUtilities,
    public localization: SpaLocalization,
    private PropertyInfo: SpaPropertyInformation,
    public _ViewMoreServiceService: ViewMoreServiceService) {
      this.placeholderFormat = this.localization.inputDateFormat;
      this.floatLabel = this.localization.setFloatLabel;
      this.commonCaptions = this.localization.captions.common;
    this.newYieldForm = this.Form.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      Y1Value: [''],
      Y2Value: [''],
      Y3Value: [''],
      maxToSell: [''],
      isClose: false,
      Y1FlatAmt: false,
      Y2FlatAmt: false,
      Y3FlatAmt: false,
      isShowAllServices: false,
      Y1BookingPercent: [''],
      Y2BookingPercent: [''],
      Y3BookingPercent: ['']
    });

    if (this._yieldService.CurrentData) {
      let startDate: any = this.utilities.getDate(this._yieldService.CurrentData.yieldDate);
      startDate.setHours(0, 0, 0, 0);
      this.newYieldForm.controls.isClose.setValue(this._yieldService.CurrentData.isClose);
      this.CloseYield = this._yieldService.CurrentData.isClose;
      this.newYieldForm.controls.Y1FlatAmt.setValue(this._yieldService.CurrentData.isYield1FlatAmount);
      this.newYieldForm.controls.Y2FlatAmt.setValue(this._yieldService.CurrentData.isYield2FlatAmount);
      this.newYieldForm.controls.Y3FlatAmt.setValue(this._yieldService.CurrentData.isYield3FlatAmount);
      this.isUpdate = true;
      const startDateProperty: any = this.PropertyInfo.CurrentDate;
      startDateProperty.setHours(0, 0, 0, 0);
      this.newYieldForm.controls.startDate.setValue(startDate);
      this.newYieldForm.controls.endDate.setValue(startDate);
      if(this._yieldService.editYield)
      {
        this.HeaderLabel = this.captions.EditYieldSetup;
        this.isEditService=true;
        this.saveText = this.captions.update;
      }
      if(this._yieldService.copyYield)
      {
        this.HeaderLabel = this.captions.CopyYieldSetup;
        if(this.PropertyInfo.CurrentDate>startDate)
        {
          this.newYieldForm.controls.startDate.setValue(startDateProperty);
          this.newYieldForm.controls.endDate.setValue(startDateProperty);
        }
      }
      this.yieldMaxLength1 = this._yieldService.CurrentData.isYield1FlatAmount ? Maxlength.FLATAMOUNT : Maxlength.PERCENTAGE;
      this.yieldMaxLength2 = this._yieldService.CurrentData.isYield2FlatAmount ? Maxlength.FLATAMOUNT : Maxlength.PERCENTAGE;
      this.yieldMaxLength3 = this._yieldService.CurrentData.isYield3FlatAmount ? Maxlength.FLATAMOUNT : Maxlength.PERCENTAGE;

    } else {
      const startDate: any = this.PropertyInfo.CurrentDate;
      startDate.setHours(0, 0, 0, 0);
      this.newYieldForm.controls.startDate.setValue(startDate);
      this.newYieldForm.controls.endDate.setValue(startDate);
    }

      this.minEndDate = this.newYieldForm.controls.startDate.value;
  }

  testText() {
    this.totalbtnWidth = this.btnView.nativeElement.offsetWidth;
    this.totalScrollWidth = this.btnView.nativeElement.scrollWidth;
    this.availableBtnSpace = Math.floor(this.totalbtnWidth / 50);
    let ff = Math.floor(this.btnView.nativeElement.offsetWidth / this.availableBtnSpace);
    let navChildren = this.btnView.nativeElement.querySelectorAll('.percentButton');
    navChildren.forEach(element => {
      element.style.width = ff + 'px';
    });

    this.btnView.nativeElement.scrollTo(this.selectedBtn.x - this.selectedBtn1.x, 0)
    if (this.totalbtnWidth < this.totalScrollWidth) {
      this.noShowIcon = true;
    }
    else {
      this.noShowIcon = false;
    }
  }

  CheckAvailableDays(fromControl?: string) {
    if(fromControl && fromControl === 'START'){
      this.startDateChanged();
    }
    if(fromControl === 'START')
    {
      this.isStartDateTouched=true
    }
    if(fromControl === 'END')
    {
      this.isEndDateTouched=true
    }
    if(this.newYieldForm.invalid){
      this.EnableSavePipe = false;
    }
    const strt = moment(this.localization.ConvertDateToISODate(this.utilities.getDate(this.newYieldForm.controls['startDate'].value)));
    const end = moment(this.localization.ConvertDateToISODate(this.utilities.getDate(this.newYieldForm.controls['endDate'].value)));
    this.availableDaysArr = this._yieldService.WeekArray(strt, end, this.captions.AllDays);
    this.RemoveUnavailabileDays();
    this.toggleAllFilter(false);
    this.EnableSave();
  }
  startDateChanged() {
    if(this.newYieldForm.controls.startDate.value === null){
      this.newYieldForm.controls.endDate.setValue(null);
      this.minEndDate = this.PropertyInfo.CurrentDate;
    }
    else{
      if (this.isStartDateExceedsEndDate()) {
        this.newYieldForm.controls.endDate.setValue(this.newYieldForm.controls.startDate.value);
      }
      this.minEndDate = this.newYieldForm.controls.startDate.value;
    }
  }

  isStartDateExceedsEndDate(): boolean {
    const startDate = this.newYieldForm.controls.startDate.value;
    const endDate = this.newYieldForm.controls.endDate.value;
    return this.resetTime(startDate) > this.resetTime(endDate);
  }

  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  viewPrevMenu(event) {
    this.rightArrowDisabled = false;
    let element = this.btnView.nativeElement;
    let scrollValue = element.scrollLeft - element.offsetWidth;
    element.scrollLeft = scrollValue;
    if (event.currentTarget.offsetParent.id == "TimeID1") {
      document.getElementById('buttonStyle1').scrollLeft = scrollValue;
      this.btnView.nativeElement.scrollTo(0, scrollValue, 0);
    }
    else if (event.currentTarget.offsetParent.id == "TimeID2") {
      document.getElementById('buttonStyle2').scrollLeft = scrollValue;
      this.btnView2.nativeElement.scrollTo(0, scrollValue, 0);
    }
    else if (event.currentTarget.offsetParent.id == "TimeID3") {
      document.getElementById('buttonStyle3').scrollLeft = scrollValue;
      this.btnView3.nativeElement.scrollTo(0, scrollValue, 0);
    }


    if (scrollValue <= 0) {
      this.leftArrowDisabled = true;
    }
  }
  viewNextMenu(event) {

    this.leftArrowDisabled = false;
    this.rightArrowDisabled = true; 
    let element2 = this.btnView.nativeElement;
    let scrollValue = element2.scrollLeft + (this.availableBtnSpace * 40);
    let scrollWidth = element2.scrollWidth;
    let offsetWidth = element2.offsetWidth;
    this.btnView.nativeElement.scrollLeft = scrollValue;
    if (event.currentTarget.offsetParent.id == "TimeID1") {
      document.getElementById('buttonStyle1').scrollLeft = scrollValue;
      this.btnView.nativeElement.scrollTo(0, scrollValue, 0);
    }
    else if (event.currentTarget.offsetParent.id == "TimeID2") {
      document.getElementById('buttonStyle2').scrollLeft = scrollValue;
      this.btnView2.nativeElement.scrollTo(0, scrollValue, 0);
    }
    else if (event.currentTarget.offsetParent.id == "TimeID3") {
      document.getElementById('buttonStyle3').scrollLeft = scrollValue;
      this.btnView3.nativeElement.scrollTo(0, scrollValue, 0);
    }

    if ((scrollWidth - (scrollValue + offsetWidth)) > 0) {
      this.rightArrowDisabled = false;
    }

  }
  RemoveUnavailabileDays() {
    for (let i = 0; i < this.selectedDaysArr.length; i++) {
      if (!this.availableDaysArr.includes(this.selectedDaysArr[i].name)) {
        this.selectedDaysArr.splice(i, 1);
      }
    }
  }

  toggleButtonClick = ($event, frm, Driven) => {
    if (Driven.id == 7) {
      this.toggleAllFilter();
      return;
    }
    if (frm.toLowerCase() === 'day') {
      this.RemoveUnavailabileDays();
      this.PP(this.selectedDaysArr, Driven);
    } else {
      this.ButtonSelect(this.selectedDaysArr, Driven);
    }
    this.EnableSave();
  }

  PP(ga, gv) {
    if (this.isExist(ga, gv) === -1) {
      ga.push(gv);
      if (ga.length === this.availableDaysArr.length - 1) {
        this.selectedDaysArr = [];
        for (let i = 0; i < this.DaysArr.length; i++) {
          for (let j = 0; j < this.availableDaysArr.length; j++) {
            if (this.DaysArr[i].name === this.availableDaysArr[j]) {
              this.selectedDaysArr.push(this.DaysArr[i]);
            }
          }
        }
      }
    } else {
      ga.splice(this.isExist(ga, gv), 1);
      if (ga.length === this.availableDaysArr.length - 1) {
        const obj = ga.find(x => x.id === 7);
        if (obj) {
          ga.splice(obj, 1);
        }
      }
    }
    this.daysArrayVar = !this.daysArrayVar;
  }

  ButtonSelect(Arr, value, setFlag: boolean = true) {
    Arr.splice(0, 1);
    Arr.push(value);
    if (setFlag) {
      this.newYieldForm.markAsDirty();
    }
  }

  isExist(coll, obj) {
    let index = -1;
    if (coll) {
      index = coll.findIndex(x => x.id === obj.id);
    }
    return index;
  }
  toggleAllFilter(isDisable: boolean = true) {
    if (this.selectedDaysArr.length !== this.availableDaysArr.length) {
      this.selectedDaysArr = [];
      for (let i = 0; i < this.DaysArr.length; i++) {
        for (let j = 0; j < this.availableDaysArr.length; j++) {
          if (this.DaysArr[i].name === this.availableDaysArr[j]) {
            this.selectedDaysArr.push(this.DaysArr[i]);
          }
        }
      }
    } else if (isDisable) {
      this.selectedDaysArr = [];
    }
  }

  ngOnInit() {
    this.saveButton = {
      type: 'primary',
      label: this.saveText,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.allCaptions.common.Cancel
    }
    if (this._yieldService.CurrentData) {
      const dayOfWeek = this.utilities.getDate(this._yieldService.CurrentData.yieldDate).getDay();
      this.selectedDaysArr = this.utilities.getToggleFilter(this.DaysArr, this.selectedDaysArr, this.DaysArr.find(x => x.id === dayOfWeek), true);
    } else {
      this.selectedDaysArr = this.utilities.getToggleAllFilter(this.DaysArr, this.selectedDaysArr);
    }

    this.newYieldForm.controls['Y1BookingPercent'].valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.checkIfYieldHasValue(Number(this.Y1BookingPercent), this.newYieldForm.controls['Y1Value']);
    });
    this.newYieldForm.controls['Y2BookingPercent'].valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.checkIfYieldHasValue(Number(this.Y2BookingPercent), this.newYieldForm.controls['Y2Value']);
    });
    this.newYieldForm.controls['Y3BookingPercent'].valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.checkIfYieldHasValue(Number(this.Y3BookingPercent), this.newYieldForm.controls['Y3Value']);
    });

    this.business.getServiceData<ServiceGroup>(HttpMethod.Get, "GetAllServiceGrp", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    this._yieldService.LoadServices(this.successCallback.bind(this), this.utilities.errorCallback);
    this.CheckAvailableDays();
  }
  ngAfterViewInit() {
    if (this.AvailPercent.length > 0) {
      this.selectedBtn1 = this.btnView.nativeElement.getBoundingClientRect();
      this.selectedBtn = this.btnView.nativeElement.querySelectorAll('.percentButton')[0].getBoundingClientRect();
    }
    this.testText();
  }

  goToYieldGrid(resultFromUserPrompt: string) {
    if (resultFromUserPrompt.toLocaleLowerCase() === ButtonOptions.Yes) {  // ignore all changes and go to YieldGrid
      this.resetYieldGrid();
    }
  }

  promptForUnSavedChanges() {
    if (this.newYieldForm.dirty) {
      this.userAlert.showPrompt(PromptType.UnsavedChanges, this.goToYieldGrid.bind(this));
    } else {
      this.resetYieldGrid();
    }
  }

  private resetYieldGrid() {
    this._yieldService.ChangeNewYield(false);
    this._yieldService.CurrentData = null;
    document.getElementsByClassName('menuSecondary-container')[0].classList.remove("d-none");
  }

  emitedValue(event) {
    this.toggleSingleButtonClick(event[2]);
  }

  toggleSingleButtonClick(Driven) {
    const serviceGroups = [...this.ServiceGroups];
    if (Driven.id === 0) {
      this.selectedServiceGrpArr = [...this.utilities.getToggleAllFilter(serviceGroups, this.selectedServiceGrpArr)];
    } else {
      this.selectedServiceGrpArr = [...this.utilities.getToggleFilter(serviceGroups, this.selectedServiceGrpArr, Driven)];
      if (this._ViewMoreServiceService.VM_displayData) {
        this._ViewMoreServiceService.VM_displayData.selecteddisplaydataArr = this.selectedServiceGrpArr;
      }
    }
    if (this.selectedServiceGrpArr.length > 0) {
      this.AvailServices = this.Services.filter(res => {
        return this.selectedServiceGrpArr.includes(res.serviceGroupId);
      });
    } else {
      this.AvailServices = _.cloneDeep(this.Services);
    }
    if (this.SelectedServices && this.SelectedServices.length > 0) {
      this.AvailServices.forEach(element => {
        const SelectedServiceMap = this.SelectedServices.filter(x => x.id === element.id);
        element.checked = SelectedServiceMap.length > 0 ? SelectedServiceMap[0].checked : false;
      });
    }
    this.isShowAllServices = this.AvailServices && this.AvailServices.length > 0 && this.AvailServices.every(x => x.checked);
    this.EnableSave();
  }

  togglebookingPercent(percent, yieldType) {
    this.setPercents(percent, yieldType);
  }
  setBookingPercent(event, yieldType) {
    this.setPercents(event.target.value, yieldType);
  }

  private setPercents(percent, yieldType) {
    switch (yieldType) {
      case 'Y1':
        this.Y1BookingPercent = percent;
        this.isYield1PercentTouched = true;
        break;
      case 'Y2':
        this.Y2BookingPercent = percent;
        this.isYield2PercentTouched = true;
        break;
      case 'Y3':
        this.Y3BookingPercent = percent;
        this.isYield3PercentTouched = true;
        break;
    }
    this.EnableSave();
  }

  toggleService(event, service) {
    if (service === 'All') {
      this.AvailServices.map(x => x.checked = !event);
    } else {
      service.checked = !event;
      this.isShowAllServices = this.toggleService && this.AvailServices.length > 0 && this.AvailServices.every(x => x.checked);
    }
    this.SelectedServices = [...this.AvailServices];
    this.isServiceTouched = true;
    this.isShowAllServices = this.AvailServices.every((x) => x.checked);
    this.EnableSave();
  }

  toggleConfirmationProcess(item) {
    switch (item) {
      case "Yield1":
        {
         this.localization.currencyToSQLFormat(this.newYieldForm.controls['Y1Value'].value).customToFixed();
          if (this.Y1FlatAmt) {
            this.Y1FlatAmt = false;
            this.Y1ValuePlaceHolder = this.yieldCaption;
            this.newYieldForm.controls.Y1Value.setValue(0);
            this.yieldMaxLength1 = Maxlength.PERCENTAGE;
          } else {
            this.Y1FlatAmt = true;
            this.Y1ValuePlaceHolder = this.yieldCurrencyCaption;
            this.newYieldForm.controls.Y1Value.setValue(0);
            this.yieldMaxLength1 = Maxlength.FLATAMOUNT;
          }
          this.isYield1FlatTouched = true;
        }
        break;
      case "Yield2":
        {
           this.localization.currencyToSQLFormat(this.newYieldForm.controls['Y2Value'].value).customToFixed();
          if (this.Y2FlatAmt) {
            this.Y2FlatAmt = false;
            this.Y2ValuePlaceHolder = this.yieldCaption;
            this.newYieldForm.controls.Y2Value.setValue(0);
            this.yieldMaxLength2 = Maxlength.PERCENTAGE;
          } else {
            this.Y2FlatAmt = true;
            this.Y2ValuePlaceHolder = this.yieldCurrencyCaption;
            this.newYieldForm.controls.Y2Value.setValue(0);
            this.yieldMaxLength2 = Maxlength.FLATAMOUNT;
          }
          this.isYield2FlatTouched = true;
        }
        break;
      case "Yield3":
        {
          this.localization.currencyToSQLFormat(this.newYieldForm.controls['Y3Value'].value).customToFixed();
          if (this.Y3FlatAmt) {
            this.Y3FlatAmt = false;
            this.Y3ValuePlaceHolder = this.yieldCaption;
            this.newYieldForm.controls.Y3Value.setValue(0);
            this.yieldMaxLength3 = Maxlength.PERCENTAGE;
          } else {
            this.Y3FlatAmt = true;
            this.Y3ValuePlaceHolder = this.yieldCurrencyCaption;
            this.newYieldForm.controls.Y3Value.setValue(0);
            this.yieldMaxLength3 = Maxlength.FLATAMOUNT;
          }
          this.isYield3FlatTouched = true;
        }
        break;
      default:
        {
          this.newYieldForm.controls.isClose.setValue(item[0]);
          this.isCloseTouched = true;
        }
        break;
    }
    this.EnableSave();
  }

  search(event) {
    const inputText = event.srcElement.value.toLowerCase();
    const newAraySet = this.Services.filter(res => {
      return res.code.toLowerCase().includes(inputText) || res.description.toLowerCase().includes(inputText);
    });
    this.AvailServices = newAraySet;

    if (this.SelectedServices && this.SelectedServices.length > 0) {
      this.AvailServices.forEach(element => {
        const SelectedServiceMap = this.SelectedServices.filter(x => x.id === element.id);
        element.checked = SelectedServiceMap && SelectedServiceMap.length > 0 && SelectedServiceMap[0].checked;
      });
    }
    this.isShowAllServices = this.AvailServices && this.AvailServices.length > 0 && this.AvailServices.every(x => x.checked);
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    switch (callDesc) {
      case "GetAllServiceGrp": {
        this.ServiceGroups.push({ "id": 0, "code": "All", "description": this.allCaptions.common.all, "listOrder": 0 });
        this.ServiceGroups = this.ServiceGroups.concat(<any>result.result);
      }
        break;
      case "GetAllSpaService": {
        const allServices = <any>result.result;
        this.Services = allServices.filter(x => x.isActive);
        this.AvailServices = [...this.Services];
        if (this._yieldService.CurrentData) {
          this._yieldService.LoadExistingYieldData(this._yieldService.CurrentData, this.successCallback.bind(this), this.utilities.errorCallback);
        }
        this.AvailServices.forEach(element => {
          element.checked = false;
        });
      }
        break;
      case "CreateServiceYield": {
        this._yieldService.FirstServiceId = extraParams[0].selectedServices[0].id;
        this.goToYieldGrid(ButtonOptions.Yes);  //'yes' is by default because no user prompt for Unsaved changes is not needed here.
      }
        break;
      case "GetDateYield": {
        const fullYieldDetails = <any>result.result;
        const yieldData = fullYieldDetails[0];

        this.Y1BookingPercent = yieldData.yield1BookingPercent;
        this.Y1FlatAmt = yieldData.isYield1FlatAmount;
        this.newYieldForm.controls.Y1Value.setValue(this.Y1FlatAmt ? this.localization.localizeCurrency(yieldData.yield1Value, false) : this.localization.localizePercentage(yieldData.yield1Value.toFixed(2)));
        if (this.Y1FlatAmt) {
          this.Y1ValuePlaceHolder = this.yieldCurrencyCaption;
        }

        this.Y2BookingPercent = yieldData.yield2BookingPercent;
        this.Y2FlatAmt = yieldData.isYield2FlatAmount;
        this.newYieldForm.controls.Y2Value.setValue(this.Y2FlatAmt ? this.localization.localizeCurrency(yieldData.yield2Value, false) : this.localization.localizePercentage(yieldData.yield2Value.toFixed(2)));
        if (this.Y2FlatAmt ) {
          this.Y2ValuePlaceHolder = this.yieldCurrencyCaption;
        }

        this.Y3BookingPercent = yieldData.yield3BookingPercent;
        this.Y3FlatAmt = yieldData.isYield3FlatAmount;
        this.newYieldForm.controls.Y3Value.setValue(this.Y3FlatAmt ? this.localization.localizeCurrency(yieldData.yield3Value, false) : this.localization.localizePercentage(yieldData.yield3Value.toFixed(2)));
        if (this.Y3FlatAmt) {
          this.Y3ValuePlaceHolder = this.yieldCurrencyCaption;
        }

        this.newYieldForm.controls.maxToSell.setValue(yieldData.available);
        this.isClose = yieldData.isClose;

        const serviceIds = fullYieldDetails.map(x => x.serviceId);
        this.AvailServices.forEach(element => {
          if (serviceIds.indexOf(element.id) >= 0) {
            element.checked = true;
          }
        });
        this.SelectedServices = [...this.AvailServices];
      }
        break;
    }

  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateServiceYield") {
      this.utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.getError(500206), ButtonType.YesNo, this.OverBookCallBack.bind(this), extraParams[0]);
      this.saveButton.disabledproperty = false;
    }
}
private OverBookCallBack(result: any) {
  if (result.toLowerCase() == ButtonOptions.Yes.toLowerCase()) {
    this.isOverwiteExisting=true;
    this.save();
  }
  else
  {
    this.isOverwiteExisting=false;
  }
}

  CheckForDate(value: any): boolean {
    const sDate = this.newYieldForm.controls['startDate'].value;
    const eDate =this.newYieldForm.controls['endDate'].value
    const startDate = this.utilities.getDate(this.newYieldForm.controls['startDate'].value);
    const endDate = this.utilities.getDate(this.newYieldForm.controls['endDate'].value);
    if (value === 'startDate') {
      if(sDate ===null){
        this.newYieldForm.controls['startDate'].setErrors({ incorrect: true });
        this.newYieldForm.controls['endDate'].markAsTouched();
        return true;
      }else if (startDate.getTime() > endDate.getTime()) {
        this.newYieldForm.controls['startDate'].setErrors({ incorrect: true });
        this.newYieldForm.controls['endDate'].markAsTouched();
        return true;
      } else {
        this.newYieldForm.controls['startDate'].setErrors(null);
        this.newYieldForm.controls['endDate'].setErrors(null);
        return false;
      }
    } else if (value === 'endDate') {
      if(eDate ===null){
        this.newYieldForm.controls['endDate'].setErrors({ incorrect: true });
        this.newYieldForm.controls['startDate'].markAsTouched();
        return true;
      }
      if (endDate.getTime() < startDate.getTime()) {
        this.newYieldForm.controls['endDate'].setErrors({ incorrect: true });
        this.newYieldForm.controls['startDate'].markAsTouched();
        return true;
      } else {
        this.newYieldForm.controls['startDate'].setErrors(null);
        this.newYieldForm.controls['endDate'].setErrors(null);
        return false;
      }
    }
  }

  EnableSave() {
    let isSaveEnabled = true;
    if (this.selectedDaysArr && this.selectedDaysArr.length === 0) {
      isSaveEnabled = false;
    }
    
    if(this.newYieldForm.get('startDate').invalid || this.newYieldForm.get('endDate').invalid){
      isSaveEnabled = false;
    }
    if (this.AvailServices) {
      const selectedServices = this.AvailServices.filter(x => x.checked);
      if (selectedServices.length === 0) {
        isSaveEnabled = false;
      }
    }
    if (!this.CheckifYieldDataIsValid()) {
      isSaveEnabled = false;
    }
    if (!this.CheckAdditionalValidations()) {
      isSaveEnabled = false;
    }
    if (this.isUpdate && !this.CheckifAnyControlIsTouched()) {
      isSaveEnabled = false;
    }
    this.EnableSavePipe = isSaveEnabled;
    this.saveButton.disabledproperty = !this.EnableSavePipe;
  }

  CheckAdditionalValidations(): boolean {
    const maxToSell = Number(this.newYieldForm.controls['maxToSell'].value);
    return (Number(this.Y1BookingPercent) > 0 || Number(this.Y2BookingPercent) || Number(this.Y3BookingPercent)
      || maxToSell > 0 || this.newYieldForm.controls['isClose'].value
      || (this.isUpdate && this.isCloseTouched) || (this.isUpdate && this.isMaxToSellTouched && maxToSell >= 0));
  }

  CheckifAnyControlIsTouched(): boolean {
    return (this.isStartDateTouched || this.isEndDateTouched || this.isAvailableDaysTouched || this.isServiceTouched
      || this.isMaxToSellTouched || this.isCloseTouched ||
      this.isYield1PercentTouched || this.isYield1FlatTouched || this.isYield1ValueTouched ||
      this.isYield2PercentTouched || this.isYield2FlatTouched || this.isYield2ValueTouched ||
      this.isYield3PercentTouched || this.isYield3FlatTouched || this.isYield3ValueTouched);
  }

  CheckifYieldDataIsValid(): boolean {
    let isValid = true;
    const y1HasError = this.checkIfYieldHasValue(Number(this.Y1BookingPercent), this.newYieldForm.controls['Y1Value']);
    const y2HasError = this.checkIfYieldHasValue(Number(this.Y2BookingPercent), this.newYieldForm.controls['Y2Value']);
    const y3HasError = this.checkIfYieldHasValue(Number(this.Y3BookingPercent), this.newYieldForm.controls['Y3Value']);

    if (y1HasError || y2HasError || y3HasError) {
      isValid = false;
    }
    return isValid;

  }

  BookingPercentDuplicate(): boolean {
    let isDuplicateBookingPercent = false;
    const Y1BookingPercent = Number(this.Y1BookingPercent);
    const Y2BookingPercent = Number(this.Y2BookingPercent);
    const Y3BookingPercent = Number(this.Y3BookingPercent);
    isDuplicateBookingPercent = (((Y1BookingPercent === Y2BookingPercent) && Y1BookingPercent > 0) ||
      ((Y2BookingPercent === Y3BookingPercent) && Y2BookingPercent > 0) ||
      ((Y1BookingPercent === Y3BookingPercent) && Y1BookingPercent > 0));
    return isDuplicateBookingPercent;
  }

  save() {
    this.saveButton.disabledproperty = true;
    if (this.BookingPercentDuplicate()) {
      this.utilities.ShowErrorMessage(this.allCaptions.common.Error, this.captions.DuplicateBookingPercentage);
      this.saveButton.disabledproperty = false;
      return;
    } else {
      const dates: any = this.DetermineDates();
      const objBody: ServiceYield[] = [];
      let selectedServices: any;
      if(this._yieldService.editYield)
      {
        this.isOverwiteExisting=true;
      }
      if (dates.length > 0) {
        selectedServices = this.AvailServices.filter(x => x.checked);
        if (selectedServices.length > 0) {
          selectedServices.forEach(x => {
            dates.forEach(element => {
              const serviceYieldObject: ServiceYield = {
                yieldDate: `${this.utilities.formatDate(element)}T00:00`,
                serviceId: x.id,
                yield1BookingPercent: Number(this.Y1BookingPercent),
                isYield1FlatAmount: this.Y1FlatAmt,
                yield1Value: Number(this.localization.currencyToSQLFormat(this.newYieldForm.controls['Y1Value'].value)),
                yield2BookingPercent: Number(this.Y2BookingPercent),
                isYield2FlatAmount: this.Y2FlatAmt,
                yield2Value: Number(this.localization.currencyToSQLFormat(this.newYieldForm.controls['Y2Value'].value)),
                yield3BookingPercent: Number(this.Y3BookingPercent),
                isYield3FlatAmount: this.Y3FlatAmt,
                yield3Value: Number(this.localization.currencyToSQLFormat(this.newYieldForm.controls['Y3Value'].value)),
                available: this.newYieldForm.controls['maxToSell'].value != null && this.newYieldForm.controls['maxToSell'].value != "" ? Number(this.newYieldForm.controls['maxToSell'].value) : null,
                sold: 0,
                remaining: this.newYieldForm.controls['maxToSell'].value != null && this.newYieldForm.controls['maxToSell'].value != "" ? Number(this.newYieldForm.controls['maxToSell'].value) : null,
                isClose: this.newYieldForm.value.isClose
              };
              objBody.push(serviceYieldObject);
            });
          });
        }
      }

      this._yieldService.createServiceYield(objBody, selectedServices,this.isOverwiteExisting, this.successCallback.bind(this), this.errorCallback.bind(this));
    }

  }
  disableYields(event) {
    this.newYieldForm.controls['isClose'].setValue(event.checked);
    this.isCloseTouched = true;
    this.CloseYield = !this.CloseYield;
    this.EnableSave();
  }

  DetermineDates(): any {
    let dates: any = [];
    const startDate = this.utilities.getDate(this.newYieldForm.controls['startDate'].value);
    startDate.setHours(0, 0, 0, 0);
    const endDate = this.utilities.getDate(this.newYieldForm.controls['endDate'].value);
    endDate.setHours(0, 0, 0, 0);
    const isAllDaysSelected = this.selectedDaysArr.filter(x => x.id == 7); //All Days
    if (isAllDaysSelected.length > 0) {
      while (startDate.getTime() <= endDate.getTime()) {
        dates.push(_.cloneDeep(startDate));
        startDate.setDate(startDate.getDate() + 1);
      }
    } else {
      const start = moment(startDate);
      this.selectedDaysArr.forEach(element => {
        const dayKey: string = element.name;
        let dateAt = 0;
        switch (dayKey) {
          case this.localization.captions.calendar.Mon:
            dateAt = 1;
            break;
          case this.localization.captions.calendar.Tue:
            dateAt = 2;
            break;
          case this.localization.captions.calendar.Wed:
            dateAt = 3;
            break;
          case this.localization.captions.calendar.Thu:
            dateAt = 4;
            break;
          case this.localization.captions.calendar.Fri:
            dateAt = 5;
            break;
          case this.localization.captions.calendar.Sat:
            dateAt = 6;
            break;
          case this.localization.captions.calendar.Sun:
            dateAt = 0;
            break;
        }
        const tmp: any = start.clone().day(dateAt);
        dates = this._yieldService.GetDates(dates, tmp, startDate, endDate);
      });
    }
    return dates;
  }

  checkIfYieldHasValue(bookingPercent: number, yieldValue: AbstractControl) {
    let isErrorPresent = false;
    if (bookingPercent > 0 && this.localization.currencyToSQLFormat(yieldValue.value) === 0) {
      yieldValue.setErrors({ 'incorrect': true });
      yieldValue.markAllAsTouched();
      isErrorPresent = true;
    } 
    else {
      yieldValue.setErrors(null);
    }
    this.newYieldForm.updateValueAndValidity();
    return isErrorPresent;
  }

  DateChanged(event) {
    this.isStartDateTouched = event === "START";
    this.isStartDateTouched = event === "END";
  }

  setTouched(item?: string) {
    if (item === "Y1Value") {
      this.isYield1ValueTouched = true;
    } else if (item === "Y2Value") {
      this.isYield2ValueTouched = true;
    } else if (item === "Y3Value") {
      this.isYield3ValueTouched = true;
    } else {
      this.isMaxToSellTouched = true;
    }
    this.EnableSave();
  }
  ngOnDestroy() {
    this._yieldService.ChangeNewYield(false);
    this.$destroyed.next(true);
    this.$destroyed.complete();
    this.promptForUnSavedChanges();
  }

  BlurPercentageDecimalYield1() {
    this.SetPercentDecimal(this.Y1FlatAmt, this.newYieldForm.controls['Y1Value']);

  }
  BlurPercentageDecimalYield2() {
    this.SetPercentDecimal(this.Y2FlatAmt, this.newYieldForm.controls['Y2Value']);

  }
  BlurPercentageDecimalYield3() {
    this.SetPercentDecimal(this.Y3FlatAmt, this.newYieldForm.controls['Y3Value']);
  }

  private SetPercentDecimal(flatAmount, control: AbstractControl) {
    const value = control.value;
    if (!flatAmount && value.toString().trim().length > 0) {
      const percent = this.localization.currencyToSQLFormat(value);
      control.setValue(this.localization.localizePercentage(percent.toString()));
    }
  }
}

