import { Component, Input, AfterViewInit, ViewChild, ElementRef, Renderer2, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons, OnEventArgs, AutoCompleteHeader } from '../../form-type.entity';
import { FormValidation, CustomErrorMesssage } from '../../form-validation';
@Component({
    selector: 'lib-autocomplete',
    templateUrl: './search-autocomplete.component.html',
    styleUrls: ['./search-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchAutoCompleteComponent implements OnInit, AfterViewInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @ViewChild('formtext', { read: false, static: true }) formtext: ElementRef;
    formAddBuilderIcons: FormIcons;
    formRemoveBuilderIcons: FormIcons;
    formRemoveIcons: FormIcons;
    // tslint:disable-next-line: no-inferrable-types
    hidePassword: boolean = false;
    // tslint:disable-next-line: no-inferrable-types
    typeField: string = 'text';
    formFieldName: any;
    fieldType: any;
    formFieldlabel: any;
    formArrayName: any;
    formFieldHeader: string;
    errorMessage: string;
    // tslint:disable-next-line: no-inferrable-types
    rowIndex: number = 0;
    validationMesage: string;
    errorLength: number;
    autoCompleteHeader: Array<AutoCompleteHeader> = [];
    autoCompleteData: Array<any> = [];
    formFieldToolTip: string;
    selectedLabels = [];
    optionSelected = false;
    get isValid(): any { return this.form.controls[this.formFieldName].valid; }
    get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }
    constructor(
        public agyDynamicFormServices: AgyDynamicFormServices,
        public renderer: Renderer2,
        private formValidation: FormValidation
    ) { }
    iconCallback(e: any): void {
        if (e.iconType.type === 'remove') {
            this.agyDynamicFormServices.RemoveRow(e.formArray, e.formObject, e.objectIndex);
        } else {
            this.agyDynamicFormServices.addnewRow(e.formArray, e.formObject);
        }
    }
    fieldArrayCallback(e: any): void {
        this.field.fieldType.MultipleData.data.push(e);
    }
    showPassword(): void {
        this.hidePassword = !this.hidePassword;
        if (this.hidePassword) {
            this.typeField = 'text';
        } else {
            this.typeField = 'password';
        }
    }
    eventCallback(index?: any, argus?: OnEventArgs): void {
        this.agyDynamicFormServices.formEventCallback(this.field, index, this.form);
    }

    getIndex(e: number): void {
        this.rowIndex = e;
    }
    selectedOption($event: any): void {
        try{
            this.optionSelected = true;
            this.agyDynamicFormServices.formEventCallback(this.field, $event.value, this.form);
        }catch (error){
            console.error(error);
        }
    }
    panelClose(): void {
        try {
          if (!this.optionSelected) {
            this.form.controls[this.formFieldName].setValue(null);
          }
        } catch(error) {
            console.log(error);
        }
    }
    ngAfterViewInit(): void {
        this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
            this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field);
        });
        if (this.form.controls[this.formFieldName] && this.form.controls[this.formFieldName].errors){
            const errorsArray =  Object.keys(this.form.controls[this.formFieldName].errors);
            this.errorLength = errorsArray.length;
        }
        if(this.form.controls[this.formFieldName].value?.length > 0) {
            this.selectedLabels = this.form.controls[this.formFieldName].value?.map(ele => this.field.fieldType[this.fieldType].options?.find(opt => opt.key === ele)?.label);
        }
        if (this.form.controls[this.formFieldName]){
            this.form.controls[this.formFieldName].valueChanges.pipe(debounceTime(700)).subscribe(res => {
                if (!this.field.fieldType[this.fieldType].disbaledProperty) {
                    this.agyDynamicFormServices.formEventCallback(this.field, res, this.form);
                }
                this.selectedLabels = [];
                if(Array.isArray(res) && res?.length > 0) {
                    this.selectedLabels = res?.map(ele => this.field.fieldType[this.fieldType].options?.find(opt => opt.key === ele)?.label);
                }
            });
        }
    }

    ngOnInit(): void {
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
        this.formRemoveBuilderIcons = {
            type: 'remove',
            label: 'remove',
            maxcount: this.field.maxcount
        };
        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formFieldHeader = formField.fieldHeader;
        this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
        ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
        if (this.field.hasOwnProperty('autoCompleteHeader')) {
            this.autoCompleteHeader = this.field.autoCompleteHeader;
        }
        if (this.field.fieldType.SingleData.hasOwnProperty('autoCompleteData')) {
            this.autoCompleteData = this.field.fieldType.SingleData.autoCompleteData;
        }
    }


    keyPress(event: any): void {
        if (this.field.type === 'number') {
            if (event.which === 101 || event.which === 69) {
                event.preventDefault();
            }
        }
    }
    onKey(event: any): void{
        try{
            if (!this.field.fieldType[this.fieldType].disbaledProperty) {
                // this.agyDynamicFormServices.formEventCallback(this.field, event.target.value, this.form);
            }
        }catch (error){
            console.error(error);
        }
    }

    onPaste(event): void {
        if (this.field.type === 'number') {
            const paste = (event.clipboardData).getData('text');
            if (paste === 'e' || paste === 'E') {
                event.preventDefault();
            }
        }
    }

}
