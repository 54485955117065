import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppointmentpopupService } from '../service/appointmentpopup.service';
import * as _ from 'lodash';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SlideInformationService } from '../slide-information/slide-information.service';
import { SpaUtilities } from '../utilities/spa-utilities';
import { UserClient } from '../business/shared.modals';
import { ClientLabel } from '../business/new-booking.model';

@Component({
  selector: 'deposit-search',
  templateUrl: 'add-depositsearch.component.html',
  styleUrls: ['add-depositsearch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdddespositsearchComponent implements OnInit, OnChanges {
  @ViewChild('dataInput') dataInput: ElementRef;
  @Input() automationId: string;
  @Input() DataInput: any = [];
  @Input() DatainputobjId: any;
  @Input() ClientData: any = [];
  @Input() LinkCodeInputData: any;
  @Input() LinkedAppointmentsInputData: any;
  @Input() hideLinkCode: boolean;
  @Input() showPreviewIcon: boolean;
  @Input() billingClients: UserClient[];
  @Input() showSearchBox: boolean;
  @Input() SelectedOutletId: number;
  @Output() messageEvent = new EventEmitter<any>();
  @Output() emitBillingClient = new EventEmitter<number>();
  @Output() emitPreview = new EventEmitter();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>

  objidArray = [];
  checkeddArray = [];
  linkedAppointmentId = "";
  dateFrom = this.SlideService.fieldData.dateField ? this.utils.getDate(this.SlideService.fieldData.dateField) : this.utils.getDate(this.SlideService.fieldData.appointmentDate);
  dateTo = this.SlideService.fieldData.dateField ? this.utils.getDate(this.SlideService.fieldData.dateField) : this.utils.getDate(this.SlideService.fieldData.appointmentDate);

  selected: any;
  selectedLinkedAppointment: any;
  closedPop: boolean = true;
  checkArray: boolean = false;
  dirtyArray: any = [];
  originalArray: any;
  appointmentId: any;
  linkId: any;
  clientIds: any;
  minDate: any = this.dateFrom;
  depositSearch: UntypedFormGroup;
  captions: any = this.localization.captions.bookAppointment;
  common: any = this.localization.captions.common;
  clientName: any;
  SearchText: any = '';
  timer = null;
  public inputIcons: any = false;
  selectedBillingClientId: number;
  isSpaWizardCheckout = false;
  commonCaptions: any;
  clientsearchArray: any = [];
  placeholderFormat: string;
  selectable = true;
  removable = true;
  searchLength = 3;
  floatLabel:string;
  primaryClientId :number;
  disableApply :boolean = false;
  constructor(public _as: AppointmentpopupService, public SlideService: SlideInformationService, public localization: SpaLocalization, private utils: SpaUtilities) {
    this.commonCaptions = this.localization.captions.common;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
  }


  ngOnInit() {
    this.ClientData = [];
    this._as.labelRecords=[];
    this.appointmentId = this.SlideService.fieldData.appointmentId;
    this.selected = this.DataInput ? this.DataInput.linkCode.toString() : '';
    this.linkId = this.DataInput ? this.DataInput.linkCode : 0;
    //this.objidArray.push(0);
    this.checkeddArray.push(0);
    this.isSpaWizardCheckout = this.SlideService &&
      this.SlideService.fieldData &&
      this.SlideService.fieldData.isFromSpaWizard &&
      this.SlideService.fieldData.isSpaWizardCheckout;
    if (this.isSpaWizardCheckout) {
      this.selectedBillingClientId = this.billingClients && this.billingClients.length > 0 ? this.billingClients[0].id : 0;
    }
    if (this.SlideService.fieldData.isFromSpaWizard) {
      this.objidArray = this.objidArray.concat(this.SlideService.fieldData.clientIds);
      this.dateFrom = this.utils.getDate(this.SlideService.fieldData.groupStartDate);
      this.dateTo = this.utils.getDate(this.SlideService.fieldData.groupEndDate);
    } else {
      this.objidArray.push(this.SlideService.fieldData.clientId);
      this.primaryClientId = this.SlideService.fieldData.clientId;
    }
    this.clientName = this.SlideService.fieldData.clientName;
    this._as.depositStartDate = this.dateFrom;
    this._as.depositEndDate = this.dateTo;
    this._as.linkId = this.linkId;
    this._as.objidArray = this.objidArray;

    if (this.showSearchBox) {
      if (this.SlideService.fieldData.clientLabelData && this.SlideService.fieldData.clientLabelData.length > 0) {
        this._as.labelRecords = this.SlideService.fieldData.clientLabelData;
      }
      else {
        var clientData: ClientLabel = {
          Id: this.SlideService.fieldData.clientId,
          FirstName: this.SlideService.fieldData.clientName,
          LastName: '',
          clientType: this.SlideService.fieldData.clientType,
        };
        this._as.labelRecords.push(clientData);
      }
    }

    if(this.SelectedOutletId != undefined)
      this._as.GetAllDepositAppointments(this.appointmentId, this.objidArray, this.dateFrom, this.dateTo, this.linkId, this.SelectedOutletId);
  }

  ngOnChanges(): void {
    if (this.isSpaWizardCheckout) {
      this.selectedBillingClientId = this.billingClients && this.billingClients.length > 0 ? this.billingClients[0].id : 0;
      this.emitBillingClient.emit(this.selectedBillingClientId);
    }
  }


  orgValueChange(e) {
    this.messageEvent.emit({ "valueObj": e, "keypair": "date" });
    this.minDate = this.dateFrom;
    this._as.depositStartDate = this.dateFrom;
    this._as.depositEndDate = this.dateTo;
    this._as.GetAllDepositAppointments(this.appointmentId, this._as.objidArray, this._as.depositStartDate, this._as.depositEndDate, this._as.linkId, this.SelectedOutletId);
  }

  /**
   * @function getId
   * @input params id, index, event
   * @output Array
   */

  getId(i, e) {
    // set dirty array

    let userIdIdx = this.dirtyArray.findIndex(x => { return x == e });
    if (userIdIdx < 0) {
      this.dirtyArray.push(e);
    }
    else {
      this.dirtyArray.splice(userIdIdx, 1)
    }

    if(i.checked == false && e == this.primaryClientId){
      this.disableApply = true;
    }
    else if(i.checked == true && e == this.primaryClientId){
      this.disableApply = false;
    }
    // if (i.checked) {
    //   this.objidArray.push(e);
    // } else {
    //   let filteredPeople = this.objidArray.filter(item => item != e);
    //   this.objidArray = filteredPeople;
    // }
  }
  /**
   * @function sendId
   * @input params Array
   * @output Array
   */

  sendId() {

      if (this.showSearchBox) {
        this.objidArray =  this._as.labelRecords.map(a => a.Id);
      }else{
        this.objidArray = this.DatainputobjId.filter(t=> t.checked).map(s=> s.id);
        this.messageEvent.emit({ "valueObj": this.objidArray, "keypair": "cs", "linkId": { value: this.linkId }, "linkedAppointmentsId": this.linkedAppointmentId });
      }

      this._as.objidArray = this.objidArray;
      this._as.GetAllDepositAppointments(this.appointmentId, this._as.objidArray, this._as.depositStartDate, this._as.depositEndDate, this._as.linkId, this.SelectedOutletId);
      this._as.checkSelected();
      this.closedPop = true;

  }



  linkcodechange(e) {
    this.messageEvent.emit({ "valueObj": e, "keypair": "lk", "clientData": this.objidArray, "linkedAppointmentsId": this.linkedAppointmentId });
    this.linkId = e.value ? e.value : 0;
    this._as.linkId = this.linkId;
    this._as.GetAllDepositAppointments(this.appointmentId, this._as.objidArray, this._as.depositStartDate, this._as.depositEndDate, this._as.linkId, this.SelectedOutletId);
    this._as.checkSelected();
  }

  linkedAppointmentchange(e) {
    this.linkedAppointmentId = e.value;
    this.messageEvent.emit({ "linkedAppointmentsId": this.linkedAppointmentId, "keypair": "la", "clientData": this.objidArray, "linkCode": this.linkId });
  }


  showPop() {
    this.disableApply = false;
    this.originalArray = _.clone(this.objidArray);
    this.DatainputobjId?.map(x => {
      x.checked = this.objidArray.some(y => { return y == x.id })
      return x;
    });
    this.dirtyArray = [];
  }

  remove(clientId: number): void {

    if ( this._as.labelRecords.length == 1) {
      this.inputIcons = false;
    }
    else if (clientId != 0) {
       this._as.labelRecords =  this._as.labelRecords.filter(r => r.Id != clientId);
    }

  }

  clearInput() {
    if (this.dataInput.nativeElement.value.length <= 2) {
      this.ClientData = [];
    }
  }

  clearSearchText()
  {
    this.dataInput.nativeElement.value="";
    this.ClientData = [];
    this.inputIcons=false;
  }

  resetFilter() {
    if (this.showSearchBox) {
      this.objidArray = this.originalArray;
      this._as.checkSelected();
      if(this.showSearchBox)
      {
        this.dataInput.nativeElement.value="";
        this.ClientData = [];
        this.inputIcons=false;
      }
    }
  }
  billingClientChange(e) {
    this.emitBillingClient.emit(this.selectedBillingClientId);
  }

  printPreview() {
    this.emitPreview.emit();
  }

  //** @funtion sendMessage()
  //Event Emiter to ClientInfo Component

  sendMessage(e: any) {
    this.inputIcons = true;
    let inputValue = this.dataInput.nativeElement.value;
    if (inputValue.length >= this.searchLength) {
      if (this.timer) {
        clearTimeout(this.timer); //cancel the previous timer.
        this.timer = null;
      }
      this.timer = setTimeout(this.emitEvent.bind(this), 1000, this.dataInput.nativeElement.value);
    } else {
      this.ClientData = [];
      this.inputIcons = false;
    }
  }

  emitEvent(searchValue, requestUid) {
    this.messageEvent.emit({ searchValue: searchValue, requestUid: requestUid })
  }

  async searchValueSelected(event: any) {
    this.inputIcons = false;
    let clientDetails = this.ClientData.filter(client => client.guestId === event.value);
    var clientData: ClientLabel = {
      Id: clientDetails[0].id,
      FirstName: clientDetails[0].firstName,
      LastName: clientDetails[0].lastName,
      clientType: clientDetails[0].clientType
    };
     this._as.labelRecords.push(clientData);
    this.dataInput.nativeElement.value = "";
    this.ClientData = [];
  }


  getToolTip(objname){
    const name = objname?.name ? objname?.name : '';
    const phoneNo = objname.phoneNumber?.number ? `| ${objname.phoneNumber?.number}` : '';
    const email = objname.Email?.emailId ? `| ${objname.Email?.emailId}` : '';
    const corpName = objname.corpName ? ` | ${objname.corpName}` : '';
    return `${name} ${phoneNo} ${email} ${corpName}`;
  }

  getAddressTooltip(clientInfo){
    const line1 = clientInfo?.addresses?.line1 ? clientInfo?.addresses?.line1 : '';
    const line2 = clientInfo?.addresses?.line2 ? clientInfo?.addresses?.line2 : '';
    const line3 = clientInfo?.addresses?.line3 ? clientInfo?.addresses?.line3 : '';
    const state = clientInfo?.addresses?.state ? clientInfo?.addresses?.state : '';
    const country = clientInfo?.addresses?.country ? clientInfo?.addresses?.country : '';
    const zip = clientInfo?.addresses?.zip ? clientInfo?.addresses?.zip : '';
   return `${line1} ${line2} ${line3} ${state} ${country} ${zip}`
  }
}
