import { Injectable } from '@angular/core';
import { CommonApiRoutes } from '../common-route';
import { TenantManagementCommunication } from '../communication/services/tenantmanagement-communication-service';
import { API, UI } from "../Models/staff-reader-board-model";

@Injectable()
export class StaffReaderBoardDataService {

    constructor(private _tenantManagementCommunication: TenantManagementCommunication,) {

    }

    public async CreateStaffReaderBoard(staffReaderBoard: API.StaffReaderBoard): Promise<API.StaffReaderBoard> {
        const result = await this._tenantManagementCommunication.postPromise<API.StaffReaderBoard>(
            { route: CommonApiRoutes.CreateStaffReaderBoard, body: staffReaderBoard }, true);
        return result;
    }

    public async UpdateStaffReaderBoard(staffReaderBoard: API.StaffReaderBoard): Promise<boolean> {
        const result = await this._tenantManagementCommunication.putPromise<boolean>(
            { route: CommonApiRoutes.UpdateStaffReaderBoard, body: staffReaderBoard }, true);
        return result;
    }

    public async DeleteStaffReaderBoard(id: number): Promise<boolean> {
        const result = await this._tenantManagementCommunication.deletePromise<boolean>(
            { route: CommonApiRoutes.DeleteStaffReaderBoard, uriParams: { id } }, true);
        return result;
    }

    public async GetAllStaffReaderBoardList(userId: number): Promise<UI.ViewAllStaffReaderBoard[]> {
        const result = await this._tenantManagementCommunication.getPromise<UI.ViewAllStaffReaderBoard[]>(
            { route: CommonApiRoutes.GetAllStaffReaderBoardList, uriParams: { userId } }, true);
        return result;
    }

    public async FilterAllStaffReaderBoard(staffReaderBoardFilterRequest: API.StaffReaderBoardFilterRequest): Promise<UI.ViewAllStaffReaderBoard[]> {
        const result = await this._tenantManagementCommunication.putPromise<UI.ViewAllStaffReaderBoard[]>(
            { route: CommonApiRoutes.FilterAllStaffReaderBoard, body: staffReaderBoardFilterRequest }, true);
        return result;
    }

    public async GetAllStaffReaderBoardDetails(id: number): Promise<UI.ViewAllStaffReaderBoardDetails> {
        const result = await this._tenantManagementCommunication.getPromise<UI.ViewAllStaffReaderBoardDetails>(
            { route: CommonApiRoutes.GetAllStaffReaderBoardDetails, uriParams: { id } }, true);
        return result;
    }

    public async GetStaffReaderBoardById(id: number): Promise<API.StaffReaderBoard> {
        const result = await this._tenantManagementCommunication.getPromise<API.StaffReaderBoard>(
            { route: CommonApiRoutes.GetStaffReaderBoardById, uriParams: { id } }, true);
        return result;
    }

    public async UpdateStaffReaderBoardDetailsIsViewed(id: number): Promise<boolean> {
        const result = await this._tenantManagementCommunication.putPromise<boolean>(
            { route: CommonApiRoutes.UpdateStaffReaderBoardDetailsIsViewed, uriParams: { id } }, true);
        return result;
    }

    public async UpdateStaffReaderBoardDetailsIsAcknowledged(id: number): Promise<boolean> {
        const result = await this._tenantManagementCommunication.putPromise<boolean>(
            { route: CommonApiRoutes.UpdateStaffReaderBoardDetailsIsAcknowledged, uriParams: { id } }, true);
        return result;
    }

    public async UpdateMultipleStaffReaderBoardDetailsIsAcknowledged(ids: number[]): Promise<boolean> {
        const result = await this._tenantManagementCommunication.putPromise<boolean>(
            { route: CommonApiRoutes.UpdateMultipleStaffReaderBoardDetailsIsAcknowledged, body: ids }, true);
        return result;
    }

    public async UpdateStaffReaderBoardDetailsIsSnoozed(id: number, isSnoozed: boolean, interval: number): Promise<boolean> {
        const result = await this._tenantManagementCommunication.putPromise<boolean>(
            { route: CommonApiRoutes.UpdateStaffReaderBoardDetailsIsSnoozed, uriParams: { id, isSnoozed, interval } }, true);
        return result;
    }

    public async GetAllNotes(userId: number): Promise<UI.ViewAllNotes[]> {
        const result = await this._tenantManagementCommunication.getPromise<UI.ViewAllNotes[]>(
            { route: CommonApiRoutes.GetAllNotes, uriParams: { userId } }, true);
        return result;
    }

    public async FilterAllNotes(staffReaderBoardFilterRequest: API.StaffReaderBoardFilterRequest): Promise<UI.ViewAllNotes[]> {
        const result = await this._tenantManagementCommunication.putPromise<UI.ViewAllNotes[]>(
            { route: CommonApiRoutes.FilterAllNotes, body: staffReaderBoardFilterRequest }, true);
        return result;
    }

    public async GetRecentNotes(userId: number, isShowNoteAsPopup: boolean): Promise<UI.ViewAllNotes[]> {
        const result = await this._tenantManagementCommunication.getPromise<UI.ViewAllNotes[]>(
            { route: CommonApiRoutes.GetRecentNotes, uriParams: { userId, isShowNoteAsPopup } }, true);
        return result;
    }

    public async GetRecentNotesCount(userId: number, isShowNoteAsPopup: boolean): Promise<number> {
        const result = await this._tenantManagementCommunication.getPromise<number>(
            { route: CommonApiRoutes.GetRecentNotesCount, uriParams: { userId, isShowNoteAsPopup } }, true);
        return result;
    }
}