import { Directive, ElementRef,  Input, ViewChild } from '@angular/core';

@Directive({
  selector: '[appoinmentcolours]'
})
export class CustomcolorDirectives   {
@Input('appoinmentcolours') highlightColor: string;
@ViewChild('appoinmentcolor') appoinmentcolor : ElementRef;
  constructor(public el: ElementRef) {

  }
 }
