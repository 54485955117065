import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridType, Host, ButtonType } from '../../../shared/globalsContant';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SPAConfig } from '../../../core/config/SPA-config';
import { SpaUtilities, RecurringType } from '../../../shared/utilities/spa-utilities';
import { SettingsService } from '../../settings.service';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { LocalizedMonthsModel, BaseResponse } from '../../../shared/business/shared.modals';
import { ImageProcessorService } from '../../../shared/service/image-processor-service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { AgTimeModalConfig } from 'src/app/common/Models/ag-models';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MaintenanceComponent implements OnInit {
  //any
  minFromValue: any;
  minToValue: any;
  locObj: any;
  startTime: any;
  endTime: any;
  selectedDate: any;
  startDate: any;
  endDate: any;
  conflictingAppointments: any = [];
  recurringMonth: any;
  recurringWeek: any;
  recurringYear: any;
  recurringDay: any;
  form: any;
  locationOrEquipments: any = [];
  commonCaptions: any = this.localization.captions.common;
  occurenceArray: any = [{ "id": 'daily', "name": this.commonCaptions.Daily, "isDisabled": false }, 
  { "id": 'weekly', "name": this.commonCaptions.Weekly, "isDisabled": false }, 
  { "id": 'monthly', "name": this.commonCaptions.Monthly, "isDisabled": false }, 
  { "id": 'yearly', "name": this.commonCaptions.Yearly, "isDisabled": false }];
  selectedMonthType: any = "day";
  selectedOccuranceType: any = [];
  maintenance: any = { selectedValue: "", startDate: "", endDate: "", allDay: "", startTime: "", endTime: "", recurring: "", repeat: "" };
  selectedValue: any;
  captions: any = this.localization.captions.utilities;
  defaultTime: any;
  public WeeklydaysArrayValue: any[] = [];
  public MonthlydaysArrayValue: any[] = [];
  public YearlydaysArrayValue: any[] = [];
  public repeatTime: any = [
    { "id": '0', "name": this.captions.First },
    { "id": '1', "name": this.captions.Second },
    { "id": '2', "name": this.captions.Third },
    { "id": '3', "name": this.captions.Fourth }
  ];
  recurring: any;
  //boolean
  overAllStartTime = false;
  overAllEndTime = false;
  Recurring = false;
  AllDay = false;
  weekly = false;
  daily = true;
  monthly = false;
  yearly = false;
  monthlyDay = false;
  monthlyDate = true;
  enableSaveButton = false;

  //number
  Type: number;
  selectedOccurItem: number = 0;
  maintenanceObjectId: number;

  //string
  selectLabel: string;
  selectedOccurType: string = this.commonCaptions.Days;
  comments: string;

  //FormGroup
  day: UntypedFormGroup;
  maintenanceForm: UntypedFormGroup;
  startTimeInputs: AgTimeModalConfig;
  endTimeInputs: AgTimeModalConfig;
  readonly defaultStartTime = '09:00';
  readonly defaultEndtime = '21:00';
  public yearArray: LocalizedMonthsModel[] = [];
  placeholderFormat: string;
  @ViewChild('startModel') startModelValue: NgModel;
  @ViewChild('endDateModel') endModelValue: NgModel;
  floatLabel:string;
  @ViewChild('myCalendar') myCalendar;
  isStartTimeValid: boolean = true;
  isEndTimeValid: boolean = true;
  minTime: any;
  sameTime:boolean = false;
  isClassView:boolean;

  constructor(private dialogRef: MatDialogRef<MaintenanceComponent>,
    public fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpServiceCall,
    private spaConfig: SPAConfig,
    private utils: SpaUtilities,
    private _su: SettingsService,
    public localization: SpaLocalization,
    public _imgService: ImageProcessorService,
    private PropertyInfo: SpaPropertyInformation) {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.locObj = this.spaConfig.captions.utilities;
    this.maintenanceForm = this.fb.group({
      allDay: false,
      recurring: false,
      isClassView: [false]
    });
    this.recurringWeek = this.fb.group({
      everyValue: [''],
      availableDays: ['']
    });
    this.recurringDay = this.fb.group({
      everyValue: ['']
    });
    this.recurringMonth = this.fb.group({
      everyValue: [''],
      dayOrDate: [''],
      day: this.fb.group({
        repeatedWeek: [''],
        repeatedDays: ['']
      }),
      date: this.PropertyInfo.CurrentDate
    });
    this.recurringYear = this.fb.group({
      everyValue: [''],
      repeatedWeek: [''],
      repeatedDays: ['']
    });
    this.startTimeInputs = {
      className: '',
      isTimeRequired: true,
      automationId: 'Txt_Maintenance_startTime',
      placeHolder: this.locObj.StartTime
    };
    this.endTimeInputs = {
      className: '',
      isTimeRequired: true,
      automationId: 'Txt_Maintenance_endTime',
      placeHolder: this.locObj.EndTime
    }
    this.Type = this.data.type;
    this.minFromValue = this.PropertyInfo.CurrentDate;
    this.minToValue = this.PropertyInfo.CurrentDate;
    this.defaultTime = this.localization.getDefaultTime();
    const recurringArray = this._su.BuildWeekDayArrayForRecurring();
    this.WeeklydaysArrayValue = _.cloneDeep([...recurringArray]);
    this.MonthlydaysArrayValue = _.cloneDeep([...recurringArray]);
    this.YearlydaysArrayValue = _.cloneDeep([...recurringArray]);

    this.yearArray = this.localization.monthsArray;
    if (this.Type == GridType.locationMaintenance) {
      this.selectLabel = this.locObj.Location;
      this.loadSelectValues("GetAllLoc")
    } else if (this.Type == GridType.equipmentMaintenance) {
      this.selectLabel = this.locObj.Equipment;
      this.loadSelectValues("GetAllEquip")
    }
    this.startDate = this.selectedDate = this.utils.getDate(moment(this.PropertyInfo.CurrentDate).add(0, "days").format());
    this.endDate = this.utils.getDate(moment(this.PropertyInfo.CurrentDate).add(6, "days").format());
    this.selectedOccuranceType.push(this.occurenceArray[0]);
    this.getDateWithoutTime(this.startDate, this.endDate);
    this.recurringMonth.controls.dayOrDate.setValue('date');
    this.startTime = this.localization.getTime(this.localization.TimeToDate('00:00'), this.localization.getTimeFormat());
    this.endTime = this.localization.getTime(this.localization.TimeToDate('00:05'), this.localization.getTimeFormat());
    this.overAllStartTime = this.overAllEndTime = false;
    this.form = this.fb.group({
      isClassView: [false] // Initialize with default value false
    });
  }

  isClosed(event) {
    document.getElementById('time-overlay').classList.remove('maintainance-transform3dnone');
  }

  isTypeChange(event)
  {
    this.maintenanceForm.controls["isClassView"].setValue(event.value);
    this.maintenanceObjectId=0;
    if(!event.value)
      {
        if (this.Type == GridType.locationMaintenance) {
          this.selectLabel = this.locObj.Location;
          this.loadSelectValues("GetAllLoc")
        } else if (this.Type == GridType.equipmentMaintenance) {
          this.selectLabel = this.locObj.Equipment;
          this.loadSelectValues("GetAllEquip")
        }
      }
      else
      {
      if (this.Type == GridType.locationMaintenance) {
        this.selectLabel = this.locObj.Location;
        this.loadSelectValuesWithQueryString("GetAllLoc",{ key: "isClassLocation", value:  [true]})
      } else if (this.Type == GridType.equipmentMaintenance) {
        this.selectLabel = this.locObj.Equipment;
        this.loadSelectValuesWithQueryString("GetAllEquip",{ key: "isClassEquipment", value:  [true]})
      }
    }
    this.enableSaveButton = this.validateSave();
  }

  openPicker(event) {
    if(event && (event.name == 'startTime')){
      this.startTime = event.value;
      this.isStartTimeValid = (event && (event.name == 'startTime'))? event.isValid: false;
      this.onChange(event.value,'startTime')
    }
    if(event && (event.name == 'endTime')){
      this.endTime = event.value;
      this.isEndTimeValid = (event && (event.name == 'endTime'))? event.isValid: false;
      this.onChange(event.value,'endTime')
    }
    this.enableSaveButton = this.validateSave();
    document.getElementById('time-overlay').classList.add('maintainance-transform3dnone');
  }

  selectedOccurance(item: any) {
    if (this.selectedOccuranceType.indexOf(item) == -1) {
      this.selectedOccuranceType = [];
      this.selectedOccuranceType.push(item);
    }
    switch (item.id) {
      case 'daily':
        this.setOccuranceValues(this.commonCaptions.Days, false, false, false, true, 0);
        break;
      case 'weekly':
        this.setOccuranceValues(this.commonCaptions.Weeks, false, false, true, false, 1);
        break;
      case 'monthly':
        this.setOccuranceValues(this.commonCaptions.Months, false, true, false, false, 2);
        break;
      case 'yearly':
        this.setOccuranceValues("", true, false, false, false, 3);
        break;
      default:
        break;
    }
    this.enableSaveButton = this.validateSave();
  }

  setOccuranceValues(occuranceType: any, yearly: boolean, monthly: boolean, weekly: boolean, daily: boolean, occurItem: number): void {
    this.selectedOccurType = occuranceType;
    this.yearly = yearly;
    this.monthly = monthly;
    this.weekly = weekly;
    this.daily = daily;
    this.selectedOccurItem = occurItem;
  }

  loadSelectValues(callDesc) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      callDesc: callDesc,
      method: HttpMethod.Get,
      showError: false,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      extraParams: []
    });
  }

  loadSelectValuesWithQueryString(callDesc,keyValue) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      callDesc: callDesc,
      method: HttpMethod.Get,
      showError: false,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      extraParams: [],
			queryString: keyValue
    });
  }

  getDateWithoutTime = (selectedStartDate, selectedEndDate) => {
    const startDate = this.utils.GetDateWithoutTime(selectedStartDate);
    const endDate = this.utils.GetDateWithoutTime(selectedEndDate);
    const dayDiff = this._su.DateDiff.inDays(startDate, endDate) + 1;
    const monthDiff = this._su.DateDiff.inMonths(startDate, endDate);
    const yearDiff = this._su.DateDiff.inYears(startDate, endDate);
    this.occurenceArray.forEach(repeatType => {
      switch (repeatType.id) {
        case 'daily':
          repeatType.isDisabled = dayDiff < 1;
          break;
        case 'weekly':
          repeatType.isDisabled = dayDiff < 8;
          break;
        case 'monthly':
          repeatType.isDisabled = monthDiff < 1;
          break;
        case 'yearly':
          repeatType.isDisabled = yearDiff < 1;
          break;
      }
    });
    const selectedOccuranceValues = this.selectedOccuranceType && this.selectedOccuranceType.length > 0 ? this.occurenceArray.filter(selectedOccurances => selectedOccurances.id == this.selectedOccuranceType[0].id) : [];
    if (selectedOccuranceValues && selectedOccuranceValues.length > 0 && selectedOccuranceValues[0].isDisabled) {
      this.selectedOccurance(this.occurenceArray[0]);
    }
  }

  successCallback<T>(
    result: BaseResponse<T>,
    callDesc: string,
    extraParams: any[]): void {
    if (["GetAllLoc", "GetAllEquip"].includes(callDesc)) {
      const values: any = result.result;
      this.locationOrEquipments = values.map(x => { return { id: x.id, value: x.description } })
    }
    if (["GetLocationConflictAppointments", "GetEquipmentConflictAppointments","GetLocationConflictClasses","GetEquipmentConflictClasses"].includes(callDesc)) {
      const appointments: any = result.result;
      let guestIds = appointments.map(x => x.guestId);
      if (guestIds && guestIds.length > 0) {
        guestIds = _.uniq(guestIds);
        this._imgService.V2GetAllImagesByReference(guestIds, true, this.successCallback.bind(this), this.utils.errorCallback.bind(this), [])
      }
      this.conflictingAppointments = appointments.map(x => {
        return {
          id: x.appointmentId, locationName: x.locationName,
          clientId: x.clientId,
          clientName: x.clientName,
          therapistName: x.therapistName, date: this.utils.GetFormattedDate(this.utils.getDate(x.startDate)) == this.utils.GetFormattedDate(this.PropertyInfo.CurrentDate)
            ? this.spaConfig.captions.common.Today : this.localization.LocalizeDate(x.startDate),
          time: this.utils.FormatAppointmentTime(x.startDate, x.endDate),
          guestId: x.guestId,
          className: x?.className
        }
      })
    }
    if (callDesc == "CreateLocationMaintenance" || callDesc == "CreateEquipmentMaintenance") {
      if (result.result) {
        this.dialogRef.close();
      }
    }
    if (callDesc == "v2GetAllImagesByReferenceId") {
      const images = <any>result.result;
      this.conflictingAppointments = this.conflictingAppointments.map(x => {
        const clientImg = images.find(img => img.imageReferenceId == x.guestId);
        x['imgObj'] = clientImg;
        return x;
      });
    }

  }
  radioChange($event: any): void {
    this.selectedValue = this.selectedMonthType;
    if ($event.value == 'day') {
      this.monthlyDate = false;
      this.monthlyDay = true;
    } else {
      this.monthlyDate = true;
      this.monthlyDay = false;
    }
    this.enableSaveButton = this.validateSave();
  }

  onFocus(type: any): void {
    if (type == 'startTime') {
      this.startTime = this.defaultStartTime;
    } else if (type == 'endTime') {
      this.endTime = this.defaultEndtime;
    }
  }

  SetOverAllStartTime(): void {
    if (!this.overAllStartTime) {
      this.overAllStartTime = true;
      this.startTime = this.defaultStartTime;
      this.inputValueChange();
    }
  }

  SetOverAllEndTime(): void {
    if (!this.overAllEndTime) {
      this.overAllEndTime = true;
      this.endTime = this.defaultEndtime;
      this.inputValueChange();
    }
  }

  ClearTimeOnDelete(keyEvent: any, controlName: any): void {

    if (keyEvent.keyCode === 8 || keyEvent.keyCode === 46) {
      if (controlName === 'StartTime') {
        this.startTime = '';
      } else if (controlName === 'EndTime') {
        this.endTime = '';
      }
    }
  }

  sliderChange(event: any): void {
    this.AllDay = !this.AllDay;
    this.maintenance.allDay = event[0];
    this.enableSaveButton = this.validateSave();
    if (this.enableSaveButton) {
      this.getConflictingAppointments();
    } else {
      this.conflictingAppointments = [];
    }
  }


  validateSave(): boolean {
    if (this.Recurring) {
      if (this.maintenanceObjectId == null || this.maintenanceObjectId <= 0) {
        return false;
      }
      if (!this.AllDay && !this.IsValidTime(this.startTime, this.endTime, true)) {
        return false;
      }
      if (this.getDates().length == 0) {
        return false;
      }
      if (this.daily) {
        return this.recurringDay.controls.everyValue.value && this.recurringDay.controls.everyValue.value != "0"
      } else if (this.weekly) {
        return this.recurringWeek.controls.everyValue.value && this.recurringWeek.controls.everyValue.value != "0" && this._su.type1Array.length > 0
      } else if (this.monthly) {
        if (this.recurringMonth.controls.dayOrDate.value == "date") {
          return this.recurringMonth.controls.everyValue.value && this.selectedDate != undefined && this.selectedDate != null
        } else {
          return this.recurringMonth.controls.everyValue.value && this.recurringMonth.controls.everyValue.value != "0" && this.recurringMonth.controls.day.controls.repeatedWeek.value && this._su.type2Array.length > 0
        }
      } else if (this.yearly) {
        return this.recurringYear.controls.everyValue.value.toString() != "" && this._su.type3Array.length > 0 && this.recurringYear.controls.repeatedWeek.value
      }
      return true;
    } else {
      if (this.maintenanceObjectId == null || this.maintenanceObjectId <= 0) {
        return false;
      }

      if (!this.AllDay && !this.IsValidTime(this.startTime, this.endTime, this.utils.GetFormattedDate(this.utils.getDate(this.startDate)) == this.utils.GetFormattedDate(this.utils.getDate(this.endDate)))) {
        return false;
      }

      return true;
    }
  }

  IsValidTime(fromTime: any, toTime: any, day?: any): boolean {
    if (fromTime == "" && toTime == "") { return true; }
    if ((fromTime != "" && toTime == "") || (fromTime == "" && toTime != "") || !this.isStartTimeValid || !this.isEndTimeValid) {
      return false;
    }
    let _dt = (time: string) => this.utils.getDate(`${this.utils.formatDate(this.PropertyInfo.CurrentDate)}T${this.localization.DeLocalizeTime(time)}`);
    if (day && _dt(toTime) <= _dt(fromTime)) {
      return false;
    }
    return true;
  }

  buildRecurringDates(): Date[] {
    let schedule: Date[] = [];
    const startDate: Date = this.utils.GetDateWithoutTime(this.utils.getDate(this.startDate));
    const endDate: Date = this.utils.GetDateWithoutTime(this.utils.getDate(this.endDate));
    let everyValue = 0;
    if (this.daily) {
      everyValue = this.recurringDay.controls.everyValue.value ? Number(this.recurringDay.controls.everyValue.value) : 1;
      schedule = this._su.getRecurringDates(RecurringType.Daily, startDate, endDate, everyValue);
    } else if (this.weekly) {
      everyValue = this.recurringWeek.controls.everyValue.value ? Number(this.recurringWeek.controls.everyValue.value) : 1;
      schedule = this._su.getRecurringDates(RecurringType.Weekly, startDate, endDate, everyValue, this.WeeklydaysArrayValue.filter(a => { return this._su.type1Array.includes(a.name) }).map(x => x.value));
    } else if (this.monthly) {
      everyValue = this.recurringMonth.controls.everyValue.value ? Number(this.recurringMonth.controls.everyValue.value) : 1;
      if (this.monthlyDay) {
        schedule = this._su.getRecurringDates(RecurringType.Monthly, startDate, endDate, everyValue,
          this.MonthlydaysArrayValue.filter(a => { return this._su.type2Array.includes(a.name) }).map(x => x.value), this.utils.getDate(this.selectedDate), this.recurringMonth.controls.day.controls.repeatedWeek.value)
      } else if (this.monthlyDate) {
        schedule = this._su.getRecurringDates(RecurringType.Monthly, startDate, endDate, everyValue, null, this.utils.getDate(this.selectedDate))
      }
    } else if (this.yearly) {
      everyValue = this.recurringYear.controls.everyValue.value || this.recurringYear.controls.everyValue.value == 0 ? Number(this.recurringYear.controls.everyValue.value) : 1;
      const repeatedWeekDay = this.recurringYear.controls.repeatedWeek.value;
      const days = this.YearlydaysArrayValue.filter(a => { return this._su.type3Array.includes(a.name) }).map(x => x.value);
      schedule = this._su.getRecurringDates(RecurringType.Yearly, startDate, endDate, everyValue,
        days, null, repeatedWeekDay)
    }
    return schedule;
  }



  onChange(data: any, type: string): void {
    switch (type) {
      case 'startDate':
        this.minToValue = this.utils.getDate(data);
        if (this.utils.getDate(this.endDate) < this.utils.getDate(data)) {
          this.endDate = this.utils.getDate(data);
        }
        this.startDate = this.selectedDate = this.utils.getDate(data);
        this.validateTimewithDate();
        this.inputValueChange();
        this.getDateWithoutTime(this.startDate, this.endDate);
        break;
      case 'endDate':
        this.endDate = this.utils.getDate(data);
        this.validateTimewithDate();
        this.inputValueChange();
        this.getDateWithoutTime(this.startDate, this.endDate);
        break;
      case 'startTime':
        this.startTime = data;
        this.validateTimewithDate();
        this.inputValueChange();
        break;
      case 'endTime':
        this.endTime = data;
        // if (this.startTime && this.startDate && this.endDate && !this.AllDay && !this.IsValidTime(this.startTime, this.endTime, (this.utils.GetFormattedDate(this.utils.getDate(this.startDate)) == this.utils.GetFormattedDate(this.utils.getDate(this.endDate))) || this.maintenanceForm.get('recurring').value)) {
        //   this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.captions.EndTimeCannotBeOlderThanTheStartTime, ButtonType.Ok)
        // }
        this.validateTimewithDate();
        this.inputValueChange();
        break;
      case 'selectedDate':
        this.selectedDate = this.startDate = data;
        this.minToValue = this.utils.getDate(data);
        if (this.utils.getDate(this.endDate) < this.utils.getDate(data)) {
          this.endDate = this.utils.getDate(data);
        }
        this.inputValueChange()
    }
    if(this.monthlyDate && !this.myCalendar){
      this.myCalendar.activeDate = data;
    }
  }

  validateTimewithDate(){
    if(this.utils.GetFormattedDate(this.utils.getDate(this.startDate)) == this.utils.GetFormattedDate(this.utils.getDate(this.endDate)))
    {
      this.minTime = this.startTime;
    } else {
      this.minTime = null
    }
  }

  inputValueChange($event?: any): void {
    if ($event) {
      this.Recurring = $event[0];
    }
    if (this.startModelValue.control.valid && this.endModelValue.control.valid) {
      this.enableSaveButton = this.validateSave();
      if (this.enableSaveButton) {
        this.getConflictingAppointments();
      }
      else {
        this.conflictingAppointments = [];
      }
    }
  }

  createMaintenance(callDesc: any, bodyObj: any) {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      callDesc: callDesc,
      method: HttpMethod.Post,
      showError: true,
      body: bodyObj,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      extraParams: []
    });
  }

  getConflictingAppointments() {
    let callDesc: string;
    let uriParams: any;
    if (this.Type == GridType.locationMaintenance) {
      callDesc = this.maintenanceForm.get('isClassView').value?"GetLocationConflictClasses":"GetLocationConflictAppointments"
      uriParams = { locationId: this.maintenanceObjectId };
    } else if (this.Type == GridType.equipmentMaintenance) {
      callDesc = this.maintenanceForm.get('isClassView').value?"GetEquipmentConflictClasses":"GetEquipmentConflictAppointments"
      uriParams = { equipmentId: this.maintenanceObjectId };
    }
    const startEndDate: any = this.getDates();

    if (this.maintenanceObjectId && this.maintenanceObjectId > 0 && startEndDate) {
      this.http.CallApiWithCallback<any>({
        host: Host.schedule,
        callDesc: callDesc,
        method: HttpMethod.Put,
        showError: false,
        body: startEndDate,
        uriParams: uriParams,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        extraParams: []
      });
    }
  }

  async getConflictingAppointmentsAsync() {
    let callDesc: string;
    let uriParams: any;
    if (this.Type == GridType.locationMaintenance) {
      callDesc = this.maintenanceForm.get('isClassView').value?"GetLocationConflictClasses":"GetLocationConflictAppointments"
      uriParams = { locationId: this.maintenanceObjectId };
    } else if (this.Type == GridType.equipmentMaintenance) {
      callDesc = this.maintenanceForm.get('isClassView').value?"GetEquipmentConflictClasses":"GetEquipmentConflictAppointments"
      uriParams = { equipmentId: this.maintenanceObjectId };
    }
    const startEndDate: any = this.getDates();

    if (this.maintenanceObjectId && this.maintenanceObjectId > 0 && startEndDate) {
      const result = await this.http.CallApiAsync<any>({
        host: Host.schedule,
        callDesc: callDesc,
        method: HttpMethod.Put,
        body: startEndDate,
        uriParams: uriParams,
      });
      if (result.result) {
        const appointments = result.result;
        this.conflictingAppointments = appointments.map(x => {
          return {
            id: x.appointmentId, locationName: x.locationName, clientName: x.clientName,
            therapistName: x.therapistName, date: this.utils.GetFormattedDate(this.utils.getDate(x.startDate)) == this.utils.GetFormattedDate(this.PropertyInfo.CurrentDate)
              ? this.spaConfig.captions.common.Today : this.localization.LocalizeDate(x.startDate),
            time: this.utils.FormatAppointmentTime(x.startDate, x.endDate),
            className: x?.className
          }
        });
      }
    }
  }

  getDates(): any {
    let startEndDate: any = [];
    let startTime, endTime;
    if (this.AllDay) {
      startTime = "00:00:00";
      endTime = "23:59:59";
  }
  else {
      startTime = this.startTime;
      endTime = this.endTime;
  }
    if (this.Recurring) {
      let dates: any = this.buildRecurringDates();
      for (let i = 0; i < dates.length; i++) {
        startEndDate.push({
          fromTime: this.utils.getAPIDateTimeFromDateAndTimeControls(dates[i], startTime),
          toTime: this.utils.getAPIDateTimeFromDateAndTimeControls(dates[i], endTime)
        });
      }
    }
    else {
      startEndDate.push({
        fromTime: this.utils.getAPIDateTimeFromDateAndTimeControls(this.startDate, startTime),
        toTime: this.utils.getAPIDateTimeFromDateAndTimeControls(this.endDate,  endTime)
      });
    }
    return startEndDate;
  }

  async Save() {
    this.enableSaveButton = false;
    const maintenanceObj: any = [];
    await this.getConflictingAppointmentsAsync();
    if (this.Type == GridType.locationMaintenance) {
      if (this.conflictingAppointments.length > 0) {
        this.utils.ShowErrorMessage(this.spaConfig.captions.common.Error, this.locObj.ConflictingAppointmentsatSelectedLocationCannotSave, ButtonType.Ok);
      } else {
        const dates = this.getDates();
        if (dates && dates.length > 0) {
          for (let i = 0; i < dates.length; i++) {
            maintenanceObj.push({
              locationId: this.maintenanceObjectId,
              startTime: dates[i].fromTime,
              endTime: dates[i].toTime,
              comments: this.comments
            });
          }
          this.createMaintenance("CreateLocationMaintenance", maintenanceObj);
        }
      }
    } else if (this.Type == GridType.equipmentMaintenance) {
      if (this.conflictingAppointments.length > 0) {
        this.utils.ShowErrorMessage(this.spaConfig.captions.common.Error, this.locObj.ConflictingAppointmentsuseSelectedEquipmentCannotSave, ButtonType.Ok);
      } else {
        const dates = this.getDates();
        if (dates && dates.length > 0) {
          for (let i = 0; i < dates.length; i++) {
            maintenanceObj.push({
              equipmentId: this.maintenanceObjectId,
              quantity: 1,
              startTime: dates[i].fromTime,
              endTime: dates[i].toTime,
              comments: this.comments
            });
          }
          this.createMaintenance("CreateEquipmentMaintenance", maintenanceObj);
        }
      }

    }
  }

  Cancel() {
    this.dialogRef.close();
  }
  selectedCollection(event: any, type: any, orgcollection: any) { // this._su.type1Array its named as serviceVaraiable
    const serviceVaraiable = [];
    const selectedArray = event[0];
    orgcollection.forEach(x => {
      if (selectedArray.indexOf(x.id) != -1) {
        serviceVaraiable.push(x.name);
        x.setSelected = true;
      } else {
        x.setSelected = false;
      }
    })
    if (type == 'type1') {
      this._su.type1Array = serviceVaraiable;
    }
    if (type == 'type2') {
      this._su.type2Array = serviceVaraiable;
    }
    if (type == 'type3') {
      this._su.type3Array = serviceVaraiable;
    }
    this.enableSaveButton = this.validateSave();
  }
}
