import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";

export interface ButtonValue {
  customclass?: string;
  disabledproperty?: boolean;
  label: string;
  type: string;
}
export enum AlertType {
  Success = 1,
  Warning = 2,
  Error = 3,
  WellDone = 4,
  Info = 5,
  AccessDenied = 6,
  Done = 7,
  Confirmation = 8,
  CustomDefault = 9
}

export enum AlertAction {
  CONTINUE = 'CONTINUE',
  CANCEL = 'CANCEL',
  YES = 'YES',
  NO = 'NO'
}

export enum ButtonTypes {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  Continue = 6,
  ContinueCancel = 7,
  AddCancel = 8,
  Save = 9,
  Update = 10
}
export interface ButtonValue {
  customclass?: string;
  disabledproperty?: boolean;
  label: string;
  type: string;
}

export interface ReturnButtonValue {
  buttontype?: string;
  event: object;
  value?: number;
}

export interface SplitMoreAction {
  action: string;
  class?: string;
  disabled?: boolean;
  icon?: string;
  label: string;
}

export interface AgFieldConfig {
  id?: string;
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  placeHolder?: string;
  placeHolderId?: string;
  value?: string | number;
  maxlength?: string | number;
  minlength?: string | number;
  disabled?: boolean;
  isRequired?: boolean;
  maxValue?: number;
  showLabel?: boolean;
  labelText?: string;
  minValue?: number;
  incrementBy?: number;
  customErrorMessage?: string;
  maximumValueErrorMsg?: string;
  minimumValueErrorMsg?: string;
  allowNegative?: boolean; // used for currency
  customMaxErrorMsg?: string;
  customMinErrorMsg?: string;
  floatLabel?: string;
  inputType?: string;
  maximumValueErrorMsgId?: string;
  customErrorMessageId?: string;//To fill custom error message value's caption key from json & value will be in customerrorMessage
  errorMessageId?: string;//To fill custom error message value's caption key from json & value will be in errorMessage
  readOnly?: boolean;
}

export interface AgInputFieldConfig {
  id?: string;
  className?: string;
  errorMessageId?: string;//To fill custom error message value's caption key from json & value will be in errorMessage
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  placeHolder?: string;
  placeHolderId?: string;
  value?: string | number;
  disabled?: boolean;
  customErrorMessageId?: string;//To fill custom error message value's caption key from json & value will be in customerrorMessage
  customErrorMessage?: string;
  maximumValueErrorMsg?: string;
  inputType?: string;
  autocomplete?: boolean;
  showRequired?: boolean;
  minlength?: number;
  maxlength?: number;
  changeLabelOnEdit?: boolean;
  isEditMode?: boolean;
  readonly?: boolean;
  maxValue?: number;
  minValue?: number;
  floatLabel?: string;
  focus?: boolean;
  type?: string;
  isRequired?: boolean;
}

export interface AgTextBoxAttributes {
  type: AgTextBoxType;
  maxLength: number;
  minLength?: number;
  directives: InputTypeText[] | InputTypeNumbers[];
  toDomMapper: (text: string | number) => string | number;
  toFormMapper: (text: string | number) => string | number;
  customClass: string;
  defaultValue: string | number;
}

export enum InputTypeText {
  CAP = 'capitalise',
  TEXT = 'textonly',
  NOSPL = 'nospecailchar',
  NOSPACE = 'notallowspace',
  EMAIL = 'email',
  FIRST_CHAR_CAP = 'firstcharcapitalise',
  NOPRESPACE = 'noprespace',
  WEBSITE = 'website',
  RESERVEDCHAR = 'reservedchar',
  HYPHENBRACES = "hyphenbraces",
  CODEINPUTTYPE = "noprespace,notallowspace",
  NAMEINPUTTYPE = "noprespace"
}

export enum InputTypeNumbers {
  NUMBERS = 'onlynumber',
  ONLYPOSITIVE = 'nonnegative',
  ONLYNEGATIVE = 'onlynegative',
  NODECIMAL = 'nodecimal',
  DECIMAL = 'decimal',
  ROUNDOFF = 'roundoff2',
  PERCENT = 'validPercentage',
  POSITIVEDECIMAL = 'onlyPositiveDecimal',
  POSITIVEDECIMALORNUMERIC = 'PositiveDecimalOrNumeric',
  NUMBERWITHSEPARATOR = 'numberWithSeparator',
  PREMAXDECIMAL = 'preMaxdecimal'
}

export interface AgDropdownConfig<T = any> {
  data?: T;
  className?: string;
  errorMessage?: string;
  form: UntypedFormGroup;
  formControlName: string;
  placeHolder?: string;
  placeHolderId?: string;
  selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
  isFirstEmpty?: boolean;
  isSelect?: boolean;
  showRequired?: boolean;
  disabled?: boolean;
  isMultiSelect?: boolean;
  customErrorMessage?: string;
  defaultSelectededOptionValue?: DropdownOptions;
  customErrorMessageId?: string;//To fill custom error message value's caption key from json & value will be in customerrorMessage
  errorMessageId?: string;//To fill custom error message value's caption key from json & value will be in errorMessage
  isAllSelected?: boolean;
  dropdownWithSearch?: boolean;
  isFormLogicApplied?: boolean;
}

export interface DropdownOptions {
  checked?: boolean;
  id?: number | string;
  value?: string | any;
  viewValue: string;
  disabled?: boolean;
  otherData?: any;
  showInDropDown?: boolean;
}


export enum AgDropDownType {
  Default = 'DEFAULT',
  Mulitple = 'MULTIPLE'
}


export enum AgTextBoxType {
  FreeText = 'FREETEXT',
  Currency = 'CURRENCY',
  Number = 'NUMBER',
  Code = 'CODE',
  Percent = 'PERCENTAGE',
  NoWhiteSpace = 'NOWHITESPACE'
}


export interface AgTextBoxAttributes {
  type: AgTextBoxType;
  maxLength: number;
  minLength?: number;
  directives: InputTypeText[] | InputTypeNumbers[];
  toDomMapper: (text: string | number) => string | number;
  toFormMapper: (text: string | number) => string | number;
  customClass: string;
  defaultValue: string | number;
}
export enum AgDateType {
  DEFAULT = 'DEFAULT'
}
export interface AgDateConfig {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  hidden?: boolean;
  isDateRequired?: boolean;
  isDisabled?: boolean;
  maxDate?: Date | string;
  minDate?: Date | string;
  placeHolder?: string;
  placeHolderId?: string;
  value?: Date | string;
  minDateErrorMessage?: string;
  errorMessageId?: string;
}

export interface AgDateAttributes {
  type: AgDateType;
  minDate: Date;
  maxDate: Date;
  toDomMapper: (value: string | Date) => Date; // Accepts Date Obj or ISO Date String
  toFormMapper: (value: string | Date) => string;  // Retuns "1996-10-15T00:00:00"
  customClass: string;
}

export class AgysControlOnDateChange {
  date: Date;
  dateISOString: string;
  dateInMilliSeconds: number;
  dayCode: string;
  localizedDisplayDate: string;
  localizedDay: string;
  localizedDayLong: string;
  localizedMonthShort: string;
  localizedMonthLong: string;
  day: number;
  year: number;
  month: number;
  hour: number;
  minute: number;
  second: number;
}

export interface DaysModel {
  id: number;
  short: string;
  long: string;
  code: string;
  longCode?: string;
}

export interface LocalizedMonthsModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface Calendar {
  Sun: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

export interface SaveButtonObj {
  isEdit: boolean;
  disabled: boolean;
  customSaveText?: string;
  customCancelText?: string;
}

export enum DialogType {
  SAVE,
  CANCEL,
  CLOSE
}
export interface AgDateConfig {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  hidden?: boolean;
  isDateRequired?: boolean;
  isDisabled?: boolean;
  maxDate?: Date | string;
  minDate?: Date | string;
  placeHolder?: string;
  placeHolderId?: string;
  value?: Date | string;
  minDateErrorMessage?: string;
  errorMessageId?: string;
}
export interface AgToggleConfig<T = any> {
  className?: string;
  data?: T;
  disabled?: boolean;
  formControlName?: string;
  group: UntypedFormGroup;
  horizontal?: boolean;
  label?: string;
  labelID?: string;
  name?: string;
  value?: any;
}
