import { BaseReport } from "../common/base-report";
import { ReportUIConfig, ReportAPIOptions, ReportParams , AllReports, StaffFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services"; 
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { Product } from '../../../shared/globalsContant';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class SurchargesReport extends BaseReport {   
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, private productId: number, 
        private PropertyInfo: RetailPropertyInformation,public retailLocalization : RetailLocalization,
        public patcheableValue) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.Surcharges,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllStaffs(AllReports.Surcharges,this.productId),
            dropDownFilterName: this.captions.Users,
            inActiveToggle: true,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false,
            showHelperText : false
        };
    }

    formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;   

        if (this.commonLocalization.isFromJobScheduler)
        {
            return [
                { "pStartDate": toApi(this.startDate) },
                { "pEndDate": toApi(this.endDate) },
                { "pPageBreak": this.pageBreakToggle }, 
                { "pFilterCaption": this.getFilterCaption },
                { "pPropertyName": this.propertyName }, 
                { "pDate": this.printedDate },
                { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
                { "fromWildCard": this.reportFormGrp.controls.timelineFrom.value},
                { "fromIncrement": this.reportFormGrp.controls.timelineCountFrom.value},
                { "toWildCard": this.reportFormGrp.controls.timelineTo.value},
                { "toIncrement": this.reportFormGrp.controls.timelineCountTo.value},
                { "caredTypes": this.reportFormGrp.controls['customFilterFormGrp'].value.card},
                { "PatchEditParam": true}  
            ];
        }

        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle }, { "pFilterCaption": this.getFilterCaption },
        { "pPropertyName": this.propertyName }, { "pDate": this.printedDate },
        { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
        { "pCardTypes": JSON.stringify(this.reportFormGrp.controls['customFilterFormGrp'].value.card)}
        ];
    }

    formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }, { "ProductId": this.productId }];
    }

    formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;   
        let selectedStaffs: StaffFilter[] = this.selectedFilterOptions;
        let cardTypes =  this.reportFormGrp.controls['customFilterFormGrp'].value.card;
        let data = selectedStaffs.filter(r => r.showInDropDown).map(s => { return { staffId: s.id, staffType: s.staffType } });
        if(this.commonLocalization.isFromJobScheduler){
            return { includeZeroValue: this.reportFormGrp.controls['customFilterFormGrp'].value.card, staffs : data,fromIncrement:this.reportFormGrp.controls.timelineCountFrom.value,
                fromWildCard: this.reportFormGrp.controls.timelineFrom.value,toIncrement:this.reportFormGrp.controls.timelineCountTo.value
                ,toWildCard:this.reportFormGrp.controls.timelineTo.value};
            }       

        return {
            StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            IssuerTypeIds: cardTypes.filter(r => r.showInDropDown).map(x => x.id),
            UserIds: selectedStaffs.filter(r => r.showInDropDown).map(s => s.id)
        };
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);            

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
            
        }, 1);
    }
}