import { User } from 'src/app/eatecui/source/core/models/user-auth-model';
import { Action } from '@ngrx/store';

export enum EUserLogin {
    GetUserLogin = '[GetUserLogin] Get GetUserLogin',
    GetUserLoginSuccess = '[GetUserLoginSuccess] Get GetUserLoginSuccess',
    GetUserLoginFailure = '[GetUserLoginFailure] Get GetUserLoginFailure',
    GetClientId = '[GetClientId] Get GetClientId',
    GetClientIdSuccess = '[GetClientIdSuccess] Get GetClientIdSuccess',
    GetClientIdFailure = '[GetClientIdFailure] Get GetClientIdFailure',
    GetToken = '[GetToken] Get GetToken',
    GetTokenSuccess = '[GetTokenSuccess] Get GetTokenSuccess',
    GetTokenFailure = '[GetTokenFailure] Get GetTokenFailure',

    
    GetSSOClientId = '[GetSSOClientId] Get GetSSOClientId',
    GetSSOClientIdSuccess = '[GetSSOClientIdSuccess] Get GetSSOClientIdSuccess',
    GetSSOClientIdFailure = '[GetSSOClientIdFailure] Get GetSSOClientIdFailure',
    GetSSOToken = '[GetSSOToken] Get GetSSOToken',
    GetSSOTokenSuccess = '[GetSSOTokenSuccess] Get GetSSOTokenSuccess',
    GetSSOTokenFailure = '[GetSSOTokenFailure] Get GetSSOTokenFailure',
}

export class GetUserLogin implements Action {
    readonly type = EUserLogin.GetUserLogin;
    constructor(public payload: User) { }
}

export class GetUserLoginSuccess implements Action {
    readonly type = EUserLogin.GetUserLoginSuccess;
    constructor(public payload: any) {}
}

export class GetUserLoginFailure implements Action {
    readonly type = EUserLogin.GetUserLoginFailure;
    constructor(public payload: any) {}
}

export class GetClientId implements Action {
    readonly type = EUserLogin.GetClientId;
    constructor(public payload: any) { }
}

export class GetClientIdSuccess implements Action {
    readonly type = EUserLogin.GetClientIdSuccess;
    constructor(public payload: any) {}
}

export class GetClientIdFailure implements Action {
    readonly type = EUserLogin.GetClientIdFailure;
    constructor(public payload: any) {}
}

export class GetToken implements Action {
    readonly type = EUserLogin.GetToken;
    constructor(public payload: any) { }
}

export class GetTokenSuccess implements Action {
    readonly type = EUserLogin.GetTokenSuccess;
    constructor(public payload: any) {}
}

export class GetTokenFailure implements Action {
    constructor(public payload: any) {}
    readonly type = EUserLogin.GetTokenFailure;
}

export class GetSSOClientId implements Action {
    readonly type = EUserLogin.GetSSOClientId;
    constructor(public payload: any) { }
}

export class GetSSOClientIdSuccess implements Action {
    readonly type = EUserLogin.GetSSOClientIdSuccess;
    constructor(public payload: any) {}
}

export class GetSSOClientIdFailure implements Action {
    readonly type = EUserLogin.GetSSOClientIdFailure;
    constructor(public payload: any) {}
}

export class GetSSOToken implements Action {
    readonly type = EUserLogin.GetSSOToken;
    constructor(public payload: any) { }
}

export class GetSSOTokenSuccess implements Action {
    readonly type = EUserLogin.GetSSOTokenSuccess;
    constructor(public payload: any) {}
}

export class GetSSOTokenFailure implements Action {
    constructor(public payload: any) {}
    readonly type = EUserLogin.GetSSOTokenFailure;
}

export type LoginAction = GetUserLogin | GetUserLoginSuccess | GetUserLoginFailure | GetClientId | GetClientIdSuccess
| GetClientIdFailure | GetToken | GetTokenSuccess | GetTokenFailure | GetSSOClientId | GetSSOClientIdSuccess | GetSSOClientIdFailure 
| GetSSOToken | GetSSOTokenSuccess | GetSSOTokenFailure;

/***Log out clearstate */
    export class ActionTypes {
      static LOGOUT = '[App] logout';
    }

    export class Logout implements Action {
       readonly type = ActionTypes.LOGOUT;
    }

      
