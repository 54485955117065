import { CustomFormActions } from './custom-formaction.interface';
import { FormAutoFillDataForm } from './form-auto-filldata.interface';
import { FormBuilding } from './form-building.interface';
import { FormCheckFlow } from './form-checkflow.interface';
import { FormCreateNestedGridDetail } from './form-create-grid-data.interface';
import { FormCustomBuilding } from './form-custom-builder.interface';
import { FormCustomFieldTypes } from './form-custom-fieldType.interface';
import { FormFieldGrouping } from './form-field-grouping.interface';
import { FormFieldOptions } from './form-field-options.interface';
import { FormFieldTypes } from './form-field-type.interface';
import { FormFieldValidation } from './form-field-validation.interface';
import { FormNestedGridDetail } from './form-grid-data.interface';
import { FormGroupFieldType } from './form-group-fieldType.interface';
import { FormGrouping } from './form-group.interface';
import { FormRendererType } from './form-renderer-type.interface';
import { FormTypes } from './form-type.interface';
import { NestedFormBuilding } from './nested-form-building.interface';
import { NestedFormCustomBuilding } from './nested-form-custom-builder.interface';
import { WizardNestedFormCustomBuilding } from './wizard-nested-form.interface';

export const FormInterface = {
   FormField: FormTypes,
   FormFieldType: FormFieldTypes,
   FormRendererType: FormRendererType,
   FormBuilder: FormBuilding,
   FormCustomBuilder: FormCustomBuilding,
   FormCustomGrouping: FormGrouping,
   FormFieldGrouping: FormFieldGrouping,
   FormGroupFieldTypes: FormGroupFieldType,
   FormFieldValidations: FormFieldValidation,
   FormFieldOption: FormFieldOptions,
   FormCustomFieldType: FormCustomFieldTypes,
   FormNestedBuilding: NestedFormBuilding,
   FormNestedCustomBuilding: NestedFormCustomBuilding,
   FormNestedGridData: FormNestedGridDetail,
   FormCustomAction: CustomFormActions,
   FormAutoFillData: FormAutoFillDataForm,
   FormCheckFlowConfig: FormCheckFlow,
   FormWizardNestedCustomBuilding: WizardNestedFormCustomBuilding,
   FormCreateNestedGridDetail: FormCreateNestedGridDetail
};