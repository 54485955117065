import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { AgInputFieldConfig } from './../../Models/ag-models';
import { Localization } from '../../localization/localization';
import { MatDialog } from '@angular/material/dialog';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';


@Component({
  selector: 'ag-textbox',
  templateUrl: './ag-input.component.html',  
  encapsulation: ViewEncapsulation.None
})
export class AgInputComponent implements OnInit {
  captions: any;
  config: AgInputFieldConfig;
  @ViewChild('inputFocus') inputFocus: ElementRef;
  floatLabel: string;
  @Input('config')
  set configOptions(value: AgInputFieldConfig) {
    if (value) {
      this.config = value;
      this.config.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel : value.floatLabel;
      if (this.config.disabled) {
        this.config.form.controls[this.config.formControlName].disable();
      } else {
        this.config.form.controls[this.config.formControlName].enable();
      }

    }
  }
  @Output() eventChange = new EventEmitter();
  @Output() eventChangeOnBlur = new EventEmitter();


  constructor(private localization: Localization, public dialog: MatDialog) {
    this.floatLabel = this.localization.setFloatLabel;
   }

  ngOnInit() {
    this.captions = this.localization.captions;
  }

  ngAfterViewInit(){
    if(this.config && this.config.focus){
      this.inputFocus.nativeElement.focus();
    }
  }

    emitValue(e)
    {
        this.eventChange.emit(e);
    }

    emitBlurValue(e)
    {
      this.eventChangeOnBlur.emit(e);
    }

  onclick() {
    if (document.designMode == 'on') {
     this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: this.config.placeHolder,
          oldErrorMessage: this.config.errorMessage,
          oldCustomErrorMessage: this.config.customErrorMessage
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result != undefined) {
          this.config.placeHolder = result.newplaceholder;
          this.config.errorMessage = result.newErrorMessage;
          this.config.customErrorMessage = result.newCustomErrorMessage;
        }
      });
    }
  }
}
