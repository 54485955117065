import { ToastrService } from "ngx-toastr";
import { ConfigService } from "src/app/eatecui/source/msal-module/config.service";
import { CustomToolTipConfig, MasterInventoryManagement, SubFormConfigs } from "src/app/eatecui/source/setup-master/shared/interface/master.interface";
import { Intergration } from "./integration";
import { IntegrationCollection } from "./integration-interface";
import { FormFieldType, FormType, SingleField } from '../models/app-form-field.interface';
import { HttpService } from "src/app/eatecui/source/shared/services/http.service";
import { resolve } from "dns";
import { TransactionConfiguration } from "src/app/eatecui/source/transaction/shared/interface/transaction.interface";
import { Validators } from "@angular/forms";
import { AgilysysButtonModel } from "src/app/eatecui/dist/agilysys-button";
import { AuthorizationSubModule, AuthorizedDataModel, PermissionResource } from 'src/app/eatecui/source/shared/models/ui-controls/ui-controls.interface';
import { AuthorizationModule } from 'src/app/eatecui/source/shared/models/ui-controls/authorization.enum';

export class VendorPunchoutIntegeration extends Intergration {
    constructor(toastrService: ToastrService, configService: ConfigService, httpService: HttpService) {
        super( toastrService, configService, httpService)
    }
    /*
     * @method TabPermissionConfig
     * @input None
     * @output None
     * @description Permission to the tab 
    */  
    TabPermissionConfig( MasterSubFormConfig: Array<SubFormConfigs>, IsPunchoutEnabled: boolean): void {
        try {
          super.setIntegrationConfiguration(null);
          super.MasterSubFormConfig = MasterSubFormConfig;
          const keyName: string =  'PunchoutConfiguration';
          let enabledIntegration: boolean = false;
          this.integerationConfiguration.IntegrationCollection.forEach((vendorInt: IntegrationCollection)=>{
            if( vendorInt.IntegratonName === 'PunchOut'){
                enabledIntegration = vendorInt.IsEnabled;
            }
          });
          super.IntegrationMasterSubPermission(keyName, IsPunchoutEnabled) 
        } catch(error){

        }
    }

    LandingTabPermissionConfig( masterDataCollection: MasterInventoryManagement, IsPunchoutEnabled: boolean): any {
      try {
        super.setIntegrationConfiguration(null);
        super.masterDataCollection = masterDataCollection;
        const keyName: string =  'PunchoutConfiguration';
        let enabledIntegration: boolean = false;
        this.integerationConfiguration.IntegrationCollection.forEach((vendorInt: IntegrationCollection)=>{
          if( vendorInt.IntegratonName === 'PunchOut'){
              enabledIntegration = vendorInt.IsEnabled;
          }
        });
        return super.IntegrationMasterLandingSubPermission(keyName, IsPunchoutEnabled) 
      } catch(error){

      }
    }

       /*
     * @method vendorPermission
     * @input None
     * @output None
     * @description Permission to the tab 
    */  
       vendorPermission( masterDataCollection: MasterInventoryManagement): void {
        try {
          super.setIntegrationConfiguration(null);
          super.masterDataCollection = masterDataCollection;
          const keyName: string =  'IsPunchoutEnabled';
          let enabledIntegration: boolean = false;
          this.integerationConfiguration.IntegrationCollection.forEach((vendorInt: IntegrationCollection)=>{
            if( vendorInt.IntegratonName === 'PunchOut'){
                enabledIntegration = vendorInt.IsEnabled;
            }
          });
          super.IntegrationMasterFormPermission(keyName, enabledIntegration) 
        } catch(error){

        }
    }
      /*
     * @method vendorPermission
     * @input None
     * @output None
     * @description Permission to the tab 
    */  
      vendorTransPermission( transaction: TransactionConfiguration, permissionKey: string): void {
        try {
          super.setIntegrationConfiguration(null);
          super.transactionJsonData = transaction;
          const keyName: Array<string> =  ['Vendor Punchout'];
          let enabledIntegration: boolean = false;
          const PermissionEnabled = this.punchOutPermission(transaction);
          this.integerationConfiguration.IntegrationCollection.forEach((vendorInt: IntegrationCollection)=>{
            if( vendorInt.IntegratonName === 'PunchOut'){
                enabledIntegration = vendorInt.IsEnabled;
                transaction.ViewTranscationConfig.HeaderConfiguration.ActionButtonConfig.forEach((e: AgilysysButtonModel) => {
                  if (e.buttonKey.includes('vendorpunchout') && enabledIntegration && PermissionEnabled) {
                    e.buttonType = e.buttonType.replace('d-none', '');
                  }
                });
            }
          });
          super.IntegrationTransPermission(keyName, enabledIntegration, permissionKey, PermissionEnabled);
        } catch(error){

        }
    }

  punchOutPermission(transaction: TransactionConfiguration): boolean {
    try {
      let AccessEnabled = false;
      const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
      const GetAuthorizationModuleName = AuthorizationModule.TRANSACTION;
      const authDataModel: Array<AuthorizedDataModel> = AuthorizationData.
        filter((x: AuthorizedDataModel) => x.Name === GetAuthorizationModuleName);
      let ShowWarehouseProcurmentWidget: boolean;
      const PermissionName = transaction.Name === 'Purchase Request' ? 'CreatePRPunchOut' : 'CreatePOPunchOut';
      if (authDataModel && authDataModel.length > 0) {
        authDataModel.forEach((DataModel: AuthorizedDataModel) => {
          if (DataModel && DataModel.hasOwnProperty('SubModules') && DataModel.SubModules.length > 0) {
            DataModel.SubModules.forEach((authSubModels: AuthorizationSubModule) => {
              authSubModels.PermissionResource.forEach((permissionResource: PermissionResource) => {
                if (permissionResource.Name === PermissionName) {
                  AccessEnabled = permissionResource.IsActive;
                }
              });
            });
          }
        });
      }
      return AccessEnabled;
    } catch (error) {
      console.log('Error occurred in punchOutPermission', error);
    }
  }

    /**
     * @method punchoutFieldsConstruction
     */
    punchoutFieldsConstruction(MasterSubFormConfig: Array<SubFormConfigs>, ResponseData: any, IsAgilysysEmployee ?: boolean): Array<SubFormConfigs> {
      try {
        const index = MasterSubFormConfig.findIndex(x => x.OdataKey === 'PunchoutConfiguration');
        const FieldValidation: Array<any> = [{ validation: Validators.required, validationMessage: 'Field is required', key: 'required' }]
        MasterSubFormConfig[index].NestedForm.forEach(form => {
          let formFields: FormType[] = [];
          const mappingData: Array<any> =  ResponseData.PunchoutMapping.filter(e => e.ConfigGroup === form.FormName);
          if (mappingData.length > 0) {
            mappingData.forEach(async data => {
              const formField = {} as FormType;
              formField.ParentClass = 'agy-md-3';
              formField.hide = false;
              formField.type = this.getType(data.ControlTypeName);
              const fieldTypeData = {} as FormFieldType;
              const singleData = {} as  SingleField;
              singleData.disbaledProperty = false;
              singleData.label = data.ConfigKey;
              singleData.name = data.ConfigMappingKey;
              singleData.value = '';
              singleData.hide = false;
              singleData.FieldValidation = ( data.IsRequired ) ? FieldValidation : [];
              if (data.ControlTypeName === 'Dropdown') {
                if (data.ConfigMappingKey === 'InventoryType') {
                  let divisionId = 1;
                  if (ResponseData.PunchoutFieldMapping.length > 0) {
                    const typeIndex = ResponseData.PunchoutFieldMapping.findIndex(t => t.ConfigMappingKey === 'Division');
                    if (typeIndex > -1) {
                      divisionId = (ResponseData.PunchoutFieldMapping[typeIndex].ConfigValues) ? 
                        parseInt(ResponseData.PunchoutFieldMapping[typeIndex].ConfigValues, 10) : 1;
                    }
                  }                  
                  divisionId = !isNaN(divisionId) ? divisionId : 1;
                  singleData.options = await this.getOptions('InventoryType', divisionId);
                } else if (data.ConfigMappingKey === 'Division') {   
                  let value = 1;  
                  if (ResponseData.PunchoutFieldMapping.length > 0) {                    
                    const typeIndex = ResponseData.PunchoutFieldMapping.findIndex(t => t.ConfigMappingKey === 'InventoryType');
                    if (typeIndex > -1) {
                      value = (ResponseData.PunchoutFieldMapping[typeIndex].ConfigValues) ? 
                        parseInt(ResponseData.PunchoutFieldMapping[typeIndex].ConfigValues, 10) : 1;
                    }
                  }   
                  singleData.value = !isNaN(value) ? value : 1;           
                  singleData.options = await this.getOptions('Division');                  
                  singleData.onCellClick = async (field: any, formValue: any, value: any) => {
                    const divisionId = value.value.Division;
                    const indx = form.FormFields.findIndex(y => y.fieldType.SingleData.name === 'InventoryType');
                    let optList = [];
                    this.httpService.GethttpMethod(`/inventory/api/IngredientTypes?$filter=IsActive eq true and DivisionId eq ${divisionId}&$select=Id,Name`).subscribe((rest: any) => {
                      rest.value?.forEach(f => {
                        let obj = {} as any;
                        obj.key = f.Id;
                        obj.label = f.Name;
                        optList.push(obj);               
                      });
                      form.FormFields[indx].fieldType.SingleData.options = [];
                      form.FormFields[indx].fieldType.SingleData.options = optList;
                      form.FormFields[indx].fieldType.SingleData.options = [...form.FormFields[indx].fieldType.SingleData.options];
                    });
                    // form[indx].fieldType.SingleData.options = await this.getOptions('DefaultIngredientType', divisionId);
                    // form[indx].fieldType.SingleData.options = [...form[indx].fieldType.SingleData.options];
                  }                  
                }
              }
              const customTooltip = {} as CustomToolTipConfig;
              customTooltip.EnableToolTip = true;
              customTooltip.ToolTipKey = data.ConfigKey;
              customTooltip.ToolTipText = data.ConfigKey;
              singleData['CustomToolTip'] = customTooltip;
              fieldTypeData.SingleData = singleData;
              formField['fieldType'] = fieldTypeData;
              formFields.push(formField);
            });
            form.FormFields = formFields;
          } else if( form.FormName === 'URL' || form.FormName === 'TemplateConfig'){
              form.FormFields.forEach((x: FormType)=> {
                if( x.fieldType.SingleData.name === 'AuthenticationURL'){
                   x.fieldType.SingleData.FieldValidation = FieldValidation;
                }
                const rField = ['AuthenticationTemplate', 'PurchaseProcessXSLTemplate'];
                const rIndex:number = rField.findIndex(e=> e === x.fieldType.SingleData.name);
                   if( rIndex > -1  && IsAgilysysEmployee){
                      x.fieldType.SingleData.FieldValidation = FieldValidation;
                   }
              })
          }    
        });  
        return MasterSubFormConfig;
      } catch (error) {
        console.error(error);
      }
    }
    /**
     * @method getType
     */
    getType(type: string): string {
      try {
        let returnType = 'text';
        switch(type) {
          case 'TextBox':
            returnType = 'text';
            break;
          case 'Dropdown':
            returnType = 'dropdown';
            break;
          default:
            returnType = 'text';
            break;
        }
        return returnType;
      } catch (error) {
        console.log(error);
      }
    }
    /**
     * @method getOptions
     */
    getOptions(Name: string, DivisionId?: number): any {
      try {        
          let returnOptions = [];
          const url = (Name === 'InventoryType') ? `/inventory/api/IngredientTypes?$filter=IsActive eq true and DivisionId eq ${DivisionId}&$select=Id,Name` :
            (Name === 'Division') ? '/inventory/api/InventoryDivisions' : null;
          this.httpService.GethttpMethod(url).subscribe((rest: any) => {
            rest.value?.forEach(f => {
              let obj = {} as any;
              obj.key = f.Id;
              obj.label = f.Name;
              returnOptions.push(obj);
            });
            return returnOptions;
          });            
          return returnOptions;     
      } catch (error) {
        console.log(error);
      }
    }
}