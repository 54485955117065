import { EnableFilterOption } from './enable-filter-option.interface';
import { GridHeaderColumn } from './grid-header-column.interface';
import { GetSearchPlaceholder } from './grid-search-placeholder.interace';
import { GetFilterOption } from './grid-filter-options.interface';
import { SetSortOrder } from './set-sort-order.interface';
import { GetRowData } from './grid-row-data.interface';
import { getTotalCount } from './grid-total-count.interface';
import { CustomExpandForEntity } from './get-custom-column-expand.interface';
import { SetCustomEditors } from './set-custom-editors.interface';

export const GridModels = {
  gridColumnDefination: GridHeaderColumn,
  getSearchPlaceHolder: GetSearchPlaceholder,
  enableFilterOption: EnableFilterOption,
  getFilterOption: GetFilterOption,
  setSortOrder: SetSortOrder,
  getCustomExpand: CustomExpandForEntity,
  gridRowData: GetRowData,
  getTotalCount: getTotalCount,
  setCustomEditors: SetCustomEditors
};

