import { Component, Input,  Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { SearchDropdownOptions } from '../../shared/shared.modal';
import { ButtonValue } from '../../Models/ag-models';
import { Localization } from '../../localization/localization';
@Component({
  selector: 'app-ag-dropdown-search',
  templateUrl: './ag-dropdown-search.component.html',
  styleUrls: ['./ag-dropdown-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgDropdownSearchComponent implements  AfterViewInit {
  captions: any;
  searchtextvalue: string;
  searchBtn: ButtonValue;
  @Input() dropdownOptions: SearchDropdownOptions[];
  floatLabel: string;
  isShowDate: boolean = false;
  @Input('searchPlaceholder')
  set placeholder(value: string) {
    this.placeholderSearch = value;
    this.searchBtn = {
      label: this.captions.btn_search,
      type: 'secondary',
      disabledproperty: true
    };
  }
  @Input() defaultOption: string | number;
  @Output() onDropdownChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onSearchValueChange = new EventEmitter();
  @Output() onDateEmit = new EventEmitter();
  placeholderSearch: string;
  searchBy: string | number;
  date: string;
  @Input('showDate')
  set showDate(value){
    this.isShowDate = value
  }
  constructor(private _localization: Localization) {
    this.captions = this._localization.captions;
    this.floatLabel = this._localization.setFloatLabel;
   }

  ngAfterViewInit(): void {
    this.searchBy = this.defaultOption ? this.defaultOption : this.dropdownOptions && this.dropdownOptions[0].id;
  }

  onChange(event) {
    const selectedOptions = this.dropdownOptions.find(option => option.id === this.searchBy);
    this.placeholderSearch = selectedOptions.searchPlaceHolder ? selectedOptions.searchPlaceHolder : this.placeholderSearch;
    this.date = ''
    this.searchBtn.disabledproperty = (this.searchtextvalue=='');
    this.onDropdownChange.emit(event.value);
  }

  search(eve) {
    this.searchtextvalue = eve;
    this.searchBtn.disabledproperty = (this.searchtextvalue=='');
    this.onSearchValueChange.emit(eve);
  }

  newsearchvalue(eve){
    this.onSearch.emit(this.searchtextvalue);
  }

  onDateChange(eve){
    this.searchBtn.disabledproperty = (eve.value =='');
    this.onDateEmit.emit(eve.value)
  }

}
