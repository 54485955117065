import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {  FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { AgInputFieldConfig, ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { combineGuestRecordBusiness } from './combine-guest-records.business';
import { CGcombinememInput, CGconfigHdrOpt, CGtablecontent, UI } from './combine-guest-ui-model';
import { Observable } from 'rxjs';
import { Localization } from '../../shared/localization/Localization';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { map } from 'rxjs/operators';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'ag-combine-guest-records',
  templateUrl: './combine-guest-records.component.html',
  styleUrls: ['./combine-guest-records.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgCombineGuestRecordsComponent implements OnInit {
  captions: any;
  CGSform: UntypedFormGroup;
  lastNameInput: AgInputFieldConfig;
  firstNameInput: AgInputFieldConfig;
  postalCodeInput: AgInputFieldConfig;
  patronIdInput: AgInputFieldConfig;
  searchButton: ButtonValue;
  combineguestBTN: ButtonValue;
  headerOptions: TableHeaderOptions[];
  options: TableOptions;
  tableContent: CGtablecontent[];
  showcombinemember: boolean = false;
  _configureHeaderOptions: CGconfigHdrOpt[];
  combinememberInputs: CGcombinememInput;
  showBadge: boolean = false;
  primarySelectedData: CGtablecontent;
  SearchCriteria: UI.GuestSearchFields;
  originalData: Observable<CGtablecontent[]>;
  formChangedData;
  isViewOnly: boolean;
  confirmResult:boolean=false;
  isLoading:boolean=false;
  constructor(private _localization: Localization, private fb: UntypedFormBuilder, public _combineGuestRecordBusiness: combineGuestRecordBusiness, private utils: CommonUtilities) {
    this.captions = this._localization.captions;
  }

  ngOnInit(): void {
    this.createForm();
    this.initializeInput();
    this.tableGridInitialize();
    this.CGSform.statusChanges.subscribe(x => {
      let object = this.CGSform.value;
      let checkempty = !Object.values(object).some(value => (value !== null && value !== ''));
      this.searchButton.disabledproperty = checkempty;
    });
  }
  tableGridInitialize() {
    this.options = this._combineGuestRecordBusiness.getTableOptions();
    this.headerOptions = this._combineGuestRecordBusiness.headerGenerator();
    this._configureHeaderOptions = this._combineGuestRecordBusiness.getConfigureDataList(this.headerOptions);

  }
  createForm() {
    this.CGSform = this.fb.group({
      lastName: '',
      firstName: '',
      company: '',
      postalCode: '',
      patronId: ''
    });
  }
  initializeInput() {
    this.lastNameInput = {
      className: 'ag_form-control',
      placeHolderId: 'lbl_lastName',
      placeHolder: this.captions.vendors.lbl_lastName,
      form: this.CGSform,
      formControlName: 'lastName',
      automationId: 'Txt_combineGuestRecords_lastName',
    };
    this.firstNameInput = {
      className: 'ag_form-control',
      placeHolderId: 'lbl_firstName',
      placeHolder: this.captions.vendors.lbl_firstName,
      form: this.CGSform,
      formControlName: 'firstName',
      automationId: 'Txt_combineGuestRecords_firstName'
    };
    this.postalCodeInput = {
      className: 'ag_form-control',
      placeHolderId: 'lbl_postalcode',
      placeHolder: this.captions.vendors.lbl_postalCode,
      form: this.CGSform,
      formControlName: 'postalCode',
      automationId: 'Txt_combineGuestRecords_postal'
    };
    this.patronIdInput = {
      className: 'ag_form-control',
      placeHolderId: 'lbl_patronId',
      placeHolder: this.captions.lbl_patronId,
      form: this.CGSform,
      formControlName: 'patronId',
      automationId: 'Txt_combineGuestRecords_patron'
    };
    this.searchButton = {
      label: this.captions.btn_search,
      type: 'primary',
      disabledproperty: true
    }
    this.combineguestBTN = {
      label: this.captions.btn_combineGuestS,
      type: 'primary',
      disabledproperty: true
    };

  }

  async searchGuestRecords(eve) {
    this.isLoading=true;
    this.utils.ToggleLoader(true);
    this.SearchCriteria = {
      id: 0,
      firstName: (this.CGSform.get('firstName').value != null) ? this.CGSform.get('firstName').value : "",
      lastName: (this.CGSform.get('lastName').value != null) ? this.CGSform.get('lastName').value : "",
      marketChannelId: 0,
      cityName: "",
      stateCode: "",
      postalCode: (this.CGSform.get('postalCode').value != null) ? this.CGSform.get('postalCode').value : "",
      stateName: "",
      county: "",
      countryName: "",
      nationality: "",
      phone: "",
      socialSecurityNumber: "",
      emailAddress: "",
      driversLicense: "",
      title:"",
      address:"",
      memberTypeId: 0,
      playerId: (this.CGSform.get('patronId').value != null) ? this.CGSform.get('patronId').value : "",
      includeInactive: false,
      isFilterApplied:false,
      additionalSearch: {
        clubMember: "",
        companyName:  "",
        createdOn: "",
        guestID: "",
        memberCategory: "",
        memberNumber: "",
        memberType: "",
        ownerARNo: "",
        ownerContractNo: "",
        ownerRoomNo: "",
        updatedOn: ""
      }
    };
    this.tableContent = await this._combineGuestRecordBusiness.getGuests(this.SearchCriteria).finally(()=>{
      this.utils.ToggleLoader(false);
    });
    this.isLoading=false;
  }

  combineguest(eve) {
    this.combinememberInputs = {
      'PrimarySelectedData': this.primarySelectedData,
      'targetSrchCriteriaList': this.SearchCriteria,
      'morePopupOptions': this._configureHeaderOptions,
      'formchangeddata': this.formChangedData
    }
    this.showcombinemember = true;
    this.combinememberInputs = { ...this.combinememberInputs }
  }

  tableAction(event) {
    if(event.fromType==FromTypeEnum.radioButton)
    {
      this.primarySelectedData = event.Obj;
      this.combineguestBTN.disabledproperty = (Object.keys(this.primarySelectedData).length === 0);
    }
  }

  closeModelAction(eve) {
    if(eve=="cancel")
    {
      this.CGSform.reset();
      this.tableContent=[];
      this._configureHeaderOptions.forEach(f=>f.checked=false);
      this.showBadge=false;
    }
    this.showcombinemember = false;
    this.combineguestBTN.disabledproperty=true;

  }

  configureOptionClick(eve, _configureHeaderOptions, headerOption, i) {
    headerOption.checked = eve.checked;
    let checkeddata = _configureHeaderOptions.filter(x => x.checked);
    this.showBadge = checkeddata.length > 0;
  }

 canDeactivate(): Observable<boolean> | boolean {
    if (this.CGSform.dirty || (this.tableContent!==undefined && this.tableContent.length > 0)) {
      if(!this.confirmResult)
      {
        this.confirmResult=true;
        return this.confirm();
      }
    }

    return true;
  }

  confirm(): Observable<boolean> {
    let confirmation = this._combineGuestRecordBusiness.showAlert(this._localization.captions.combineGuest_warning, AlertType.Warning, ButtonType.YesNo).afterClosed();
    return confirmation.pipe(map((a) => {
      if (a == AlertAction.NO)
      {
        this.confirmResult=false;
        return false
      }
      else
       {
         return true
       }
      }));
  }

}
