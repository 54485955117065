import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { UI, API } from 'src/app/common/Models/datautilitydetails-model';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { DataUtilityDataService } from '../../dataservices/datautility.data.service';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { UserAccessBusiness } from '../../dataservices/authentication/useraccess.business';
import { Product } from '../../shared/shared/globalsContant';

@Injectable()
export class ExportBusiness {
  captions: any;
  public isViewOnly: boolean = false;
  public isAllow: boolean = false;
  productName: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private _localization: Localization,
    private _dataservice: DataUtilityDataService,
    private _userAccessBusiness: UserAccessBusiness) {
    this.captions = this._localization.captions;
  }

  getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'id',
        displayName: this.captions.tabl_hdr_sNo,
        displayNameId: 'tabl_hdr_sNo',
        sorting: true
      },
      {
        key: 'dataUtilityExportData',
        displayName: this.captions.lbl_screenNames,
        displayNameId: 'lbl_screenNames',
        templateName: ActionTypeEnum.custom,
        sorting: false
      },
      {
        key: 'exportFileStream',
        displayName: this.captions.lbl_export,
        displayNameId: 'lbl_export',
        templateName: ActionTypeEnum.custom,
        sorting: false
      },
      {
        key: 'status',
        displayName: this.captions.tbl_hdr_status,
        displayNameId: 'tbl_hdr_status',
        templateName: ActionTypeEnum.custom
      },
    ]
  }

  getTableOptions(): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.desc,
      defaultsortingColoumnKey: 'id',
      columnFreeze: {
        firstColumn: true,
        lastColumn: true
      }
    }
  }
  async ValidateBreakPoints(): Promise<boolean> {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.EXPORT, true);
    this.isViewOnly = result.isViewOnly;
    this.isAllow = result.isAllow;
    return result.isAllow;
  }

  public async getAllEntries(serviceName: string, dataUtilityType: API.DataUtilityType): Promise<UI.DataUtilityDetails[]> {
    let apiModels: API.DataUtilityDetails[] = await this._dataservice.getAllDataUtilityExportDetails(serviceName);
    apiModels = apiModels.filter(x => x.utilityType == dataUtilityType);
    return apiModels.map(x => this.uiMapper(x));

    // return Promise.resolve([]);
  }
  public async getDataUtilityErrors(dataUtilityDetailId : number): Promise<UI.DataUtilityErrors[]> {
    let apiModels: API.DataUtilityErrors[] = await this._dataservice.getAllDataUtilityErrors(dataUtilityDetailId);
    return apiModels ? apiModels.map(x => ({ screenName: x.screenName, errorRowNo: x.errorRowNo, errorMessage: x.errorMessage, errorCode: x.errorCode } as UI.DataUtilityErrors)) : [];
  }

  private uiMapper(DataUtilityDetailsAPIModel: API.DataUtilityDetails): UI.DataUtilityDetails {
    return {
      id: DataUtilityDetailsAPIModel.id,
      status: DataUtilityDetailsAPIModel.status,
      dataUtilityErrors: DataUtilityDetailsAPIModel.dataUtilityErrors ? DataUtilityDetailsAPIModel.dataUtilityErrors.map(x => ({ errorRowNo: x.errorRowNo, errorMessage: x.errorMessage, screenName: x.screenName })) : [],
      exportFileStream: null,
      dataUtilityExportData: DataUtilityDetailsAPIModel.dataUtilityExportData ? this.mapDefaultUtilityExportData(DataUtilityDetailsAPIModel.dataUtilityExportData.map(x => ({ screenName: x.screenName }))) : [],
    } as UI.DataUtilityDetails;
  }

  public async getAllModules(): Promise<DropdownOptions[]> {
    let id = 1;
    let apiModels:string[] = []; 
    let prodID = Number(this._localization.GetPropertyInfo('ProductId'));
    if(prodID == Product.GOLF || prodID == Product.SPA ||Product.SNC){
      apiModels = await this._dataservice.getAllModules();
      apiModels = this.filterModulesWithoutTransaction(apiModels);
    }else
    {
      apiModels = await this._dataservice.getAllModules();
    }
    let value: DropdownOptions[] = apiModels.map(x => { return { value: x, viewValue: x, id: id++ }; });
    return value;
    // return Promise.resolve([]);
  }

  filterModulesWithoutTransaction(modules: string[]): string[]{
    modules = modules.filter(x=>x != 'Transactions');
    return modules;
  }

  async getCheckBoxList(moduleName: string) {
    let id = 1;
    let apiModels = await this._dataservice.getAllTables(moduleName);
    let value = apiModels.map(x => { return { value: x, checked: false, id: id++ }; });
    return value;
    // return "Test";
  }

  async export(screenNames: string[], serviceName: string, dataUtilityType: API.DataUtilityType): Promise<UI.DataUtilityDetails[]> {
    let isCreate: boolean = true;
    let prodID = Number(this._localization.GetPropertyInfo('ProductId'));
    if(prodID == Product.SPA || prodID == Product.GOLF){
      isCreate = false;
    }
    let apiModels: API.DataUtilityDetails[] = await this._dataservice.exportExcel(screenNames, serviceName,isCreate);
    apiModels = apiModels.filter(x => x.utilityType == dataUtilityType);
    return apiModels.map(x => this.uiMapper(x));
    // return [];
  }

  getFileNameByProduct(): string {
    let fileName = "";
    let prodID = Number(this._localization.GetPropertyInfo('ProductId'));
    this.productName = ''
    switch (prodID) {
      case Product.SPA:
        this.productName = 'SPA';
        break;
      case Product.RETAIL:
        this.productName = 'RETAIL';
        break;
      case Product.GOLF:
        this.productName = 'GOLF';
        break;
      case Product.COMMON:
        this.productName = 'COMMON';
        break;
      case Product.SNC:
        this.productName = 'SNC';
        break;
      case Product.PMS:
        this.productName = 'PMS';
        break;
      case Product.ACCOUNTING:
        this.productName = 'ACCOUNTING';
        break;
      case Product.FOLIO:
        this.productName = 'FOLIO';
        break;
    }

    fileName = this.productName + '_' + 'Export' + '_' + moment().format('MM-DD-YYYY_hh:mm:ss').toString() + '.xlsx'
    return fileName;
  }

  async download(dataUtilityId: number) {
    let file = await this._dataservice.downloadExcel(dataUtilityId);

    let fileName = this.getFileNameByProduct();


    FileSaver.saveAs(file, fileName);
    return true;
  }

  public mapDefaultUtilityExportData(dataUtilityExportData) {
    let screenNames: string = "";
    screenNames += dataUtilityExportData.map(i => { return i.screenName; }).join(', ');
    return screenNames;
  }
}
