import { BaseResponse } from '../../shared/business/shared.modals';
import { Host } from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { Injectable } from '@angular/core';
import { SpaUtilities } from '../utilities/spa-utilities';
@Injectable()
export class AppointmentServiceInformationService {
  constructor(
    public http: HttpServiceCall,
    public utils: SpaUtilities) {
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  async deleteTempHold(tempHoldIds: any[]) {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('DeleteTempHold', Host.schedule, HttpMethod.Delete, null, tempHoldIds);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetMultiClientAppointmentById(appointmentId: number): Promise<any> {
    const uriParam = { id: appointmentId };
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetMultiAppointment', Host.schedule, HttpMethod.Get, uriParam);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetServiceInformation(): Promise<any> {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetAllSpaServiceByUserId', Host.schedule, HttpMethod.Get);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetAllTherapistInformation(): Promise<any> {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetAllTherapist', Host.schedule, HttpMethod.Get);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetCustomFields(): Promise<any> {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetCustomFieldsWithValues', Host.schedule, HttpMethod.Get);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetAllLocations(): Promise<any> {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetAllLoc', Host.schedule, HttpMethod.Get);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetClientInformation(clientIds: number[]): Promise<any> {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetClientByIds', Host.spaManagement, HttpMethod.Put, '', clientIds);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetMedicalWarning(serviceId: number): Promise<any> {
    const uriParam = { id: serviceId };
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetMedicalCondition', Host.spaManagement, HttpMethod.Get, uriParam);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetClientByIds(ids: number[]): Promise<any> {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetClientByIds', Host.spaManagement, HttpMethod.Put, '', ids);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }

  async GetPackageById(packageId: number): Promise<any> {
    if (packageId !== 0) {
      const uriParam = { id: packageId };
      const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetSpecificPackage', Host.spaManagement, HttpMethod.Get, uriParam);
      if (apiResponse && apiResponse.successStatus && apiResponse.result) {
        return apiResponse.result;
      }
    }
    return null;
  }

  async UpdateAppointmentAction(requestBody: any): Promise<any> {
    const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('UpdateAppointmentAction', Host.schedule, HttpMethod.Put, '', requestBody);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      return apiResponse.result;
    }
    return null;
  }
  async GetGroupAppointmentById(groupAppointmentId: number): Promise<any> {
    if (groupAppointmentId !== 0) {
      const uriParam = { id: groupAppointmentId };
      const apiResponse: BaseResponse<any> = await this.InvokeServiceCallAsync('GetGroupAppointmentById', Host.schedule, HttpMethod.Get, uriParam);
      if (apiResponse && apiResponse.successStatus && apiResponse.result) {
        return apiResponse.result;
      }
    }
    return null;
  }
}
