import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { CreateTransaction } from 'src/app/eatecui/source/transaction/shared/interface/create-transcation';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { NoMathPopUpConfig, NomathDetailConfig, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { HeaderOverlayRef } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { NomathMenuService } from './nomath-menu.service';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injector } from '@angular/core';
import { NomathmenuFormComponent } from 'src/app/eatecui/source/transaction/nomathmenu-form/nomathmenu-form.component';
interface HeaderConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  columns?: any;
  gridName?: string;
  filterOptions?: string;
}

const DEFAULT_CONFIG: HeaderConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: '',
  columns: null
};

@Component({
  selector: 'app-nomath-menu',
  templateUrl: './nomath-menu.component.html',
  styleUrls: ['./nomath-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NomathMenuComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private dialog: MatDialog,
    private transactionService: TransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private noMathService: NomathMenuService,
    private injector: Injector,
    private overlay: Overlay
  ) { }
  input: any;
  params: any
  value: any;
  Units = [];
  homeGroup: FormGroup;
  FormFieldArray: FormType[];
  agilysysCofirmationButton: AgilysysButtonModel[];
  noMathPopUpConfig = {} as NoMathPopUpConfig;
  qtyvalue: any;
  @ViewChild('clickMenuTrigger') clickHoverMenuTrigger: MatMenuTrigger;
  @ViewChild('reSizeRows') reSize: ElementRef;
  enterIndex = 1;
  DivisionId: number;

  ngOnInit(): void {
    console.log('ngOnInit')
  }

  agInit(params: ICellEditorParams) {
    this.params = params;
    this.Units = params.data.Units;
    this.homeGroup = this.formBuilder.group({});
    this.qtyvalue = this.params.data.Qty;
    this.DivisionId = params.data.DivisionId ? params.data.DivisionId : 
    params.data.PhyDetailIngredientId?.DivisionId;
    this.noMathService.DivisionId = this.DivisionId;
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.enterIndex = 1;
          this.constratctMathEngineCalculation(this.noMathPopUpConfig, this.transactionService.transactionConfigurationData,
            this.params.data, callBackData, this.dialog, this.params);
          const inputBox = document.getElementById('input-number-touch');
          if (inputBox) {
            inputBox.focus();
          }
          this.clickHoverMenuTrigger.closeMenu();
          this.homeGroup.reset();
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.enterIndex = 1;
          this.constratctMathEngineCalculation(this.noMathPopUpConfig, this.transactionService.transactionConfigurationData,
            this.params.data, callBackData, this.dialog, this.params);
          const inputBox = document.getElementById('input-number-touch');
          if (inputBox) {
            inputBox.focus();
          }
          this.clickHoverMenuTrigger.closeMenu();
          this.homeGroup.reset();
        }
      }
    ];
  }

  enterKeyNavigation(event) {
    console.log('Enter Key', event, this.enterIndex);
    this.enterIndex = this.enterIndex + 1;
    if (this.enterIndex <= 4) {
      this.focusInputBox();
    } else if (this.enterIndex === 5) {
      const NoMathSection = document.querySelector('.nomath-menu-section');
      const confirmButton = NoMathSection.getElementsByClassName('agilysys-button-primary')[0];
      confirmButton.setAttribute('id', 'nomath-primary');
      const noMathButton = document.getElementById('nomath-primary');
      setTimeout(() => {
        if (noMathButton) {
          noMathButton.focus();
        }
      }, 10);
    }
  }

  reset() {
    this.enterIndex = 1;
    this.homeGroup.reset();
  }

  closeMenu() {
    this.clickHoverMenuTrigger.closeMenu();
    this.homeGroup.reset();    
  }

  keyDownform(event) {
    console.log('Event', event);
    if (event.keyCode === 9) { //tab key code
      event.preventDefault();
      event.stopPropagation();
    }
  }

  formClick(event) {
    event.stopPropagation();
  }

  iconClick() {
    console.log('Icon Click');
    this.noMathService.params = this.params;
    const dialogRef: HeaderOverlayRef = this.openResizeRows({
      gridName: 'nomathmenu'
    }, this.reSize);
    const FormFields = CreateTransaction.NomathCalculation(this.transactionService.transactionConfigurationData, this.httpService,
      this.dialog, this.params.data, this.params, this.transactionService,
      this.transactionService.transactionFormGroup, 'nomath-editor', this.commonService);
    this.noMathPopUpConfig = FormFields;
    this.FormFieldArray = FormFields.PopupFormConfig.FormFields;
    setTimeout(() => {
      this.focusInputBox();
    }, 100);
  }

  focusInputBox() {
    const focusInput = document.getElementById(this.FormFieldArray[this.enterIndex]?.fieldType.SingleData.name);
      if (focusInput) {
        focusInput.focus();
    }
  }

  keyEnterMethod(key: any, input: HTMLElement, keycode: string) {
    const rowIndex = keycode === KEY_UP ? key.rowIndex - 1 : key.rowIndex + 1;
    const firstCol = key.columnApi.getAllDisplayedColumns()[0];
    key.api.ensureColumnVisible(firstCol);
    let NlcBool = true;
    key.api.forEachNode(function (rowNode: any, index: any) {
      if (index >= rowIndex) {
        if (rowNode.data[key.colDef.field] !== 'N_C' && NlcBool) {
          // key.api.clearFocusedCell();
          key.api.stopEditing();
          key.api.setFocusedCell(rowIndex, key.colDef.field);
          key.api.startEditingCell({
            rowIndex: rowIndex,
            colKey: key.colDef.field
          });
          NlcBool = false;
        }
      }
    });
  }

  keyDown(event) {
    try {
      const _thatmethod = this;
      const alloWDecimalDigit = 6;
      const alloWDigit = 10;
      const noNegativeSign = this.params.NoNegativeSign;
      if (noNegativeSign && event.key === '-') {
        event.preventDefault();
      }
      if ((event.key.toString().toLowerCase() === 'v' || event.key.toString().toLowerCase() === 'c'
        || event.key.toString().toLowerCase() === 'a') && event.ctrlKey === true) {
        return true;
      }
      // event.key === 'Enter' || 
      if (event.key === KEY_ENTER) {
        _thatmethod.keyEnterMethod(this.params, _thatmethod.input, event.key);
        _thatmethod.params.context.parentComponent.selectedColumn = this.params.colDef.field;
      }

      if (event.key === KEY_ESCAPE) {
        _thatmethod.params.api.stopEditing();
      }

      // Added for Mac Support
      if (event.metaKey === true) {
        return true;
      }
      const _that = this;
      const hasDot = event.target.value.indexOf('.');
      const upStartIndex = event.target.selectionStart;
      const key = event.key;
      // Not to allow \ and >
      if (event.key.trim() === '' || event.key.trim() === '>' || event.keyCode === 220 || (event.key.trim() === '-' && noNegativeSign)) {
        event.preventDefault();
      }
      // Not to allow double dot
      if (hasDot !== -1 && (event.keyCode === 190 || event.keyCode === 110)) {
        event.preventDefault();
      }
      /****To Prevent Special Characters & allow .,-,backspace****/
      // if (isNaN(event.key) && (event.keyCode !== 190 && event.keyCode !== 8 && event.key !== '-') && event.keyCode !== 110) {
      //     event.preventDefault();
      // }
      if (hasDot !== -1 || event.keyCode === 190 || event.keyCode === 110) {
        const splitValues = event.target.value.split('.');
        if (splitValues[1] && splitValues[1].length > 0) {
          if (splitValues[1] && splitValues[1].length >= alloWDecimalDigit) {
            if (
              (key !== ')' &&  // 48 -ipad
                key !== '!' &&  // 49-ipad
                key !== '@' &&   // 50-ipad
                key !== '#' &&   // 51-ipad
                key !== '$' &&   // 52-ipad
                key !== '%' &&   // 53-ipad
                key !== '^' &&   // 54-ipad
                key !== '&' &&   // 55-ipad
                key !== '*' &&   // 56-ipad
                key !== '(' &&
                key !== '_' &&
                key !== '=' &&
                key !== '>' &&
                key !== '<' &&
                key !== '?' &&
                key !== '+' &&
                key !== ':' &&
                key !== ';' &&
                key !== '"' &&
                key !== ',' &&
                key !== '\'' &&
                key !== '{' &&
                key !== '}' &&
                key !== ']' &&
                key !== '[' &&
                key !== '|' &&
                key !== '`' &&
                key !== '~') &&
              (event.keyCode === 8 ||
                event.keyCode === 9 ||
                event.keyCode === 10 ||
                event.keyCode === 13 ||
                event.keyCode === 16 ||
                event.keyCode === 17 ||
                event.keyCode === 18 ||
                event.keyCode === 20 ||
                event.keyCode === 27 ||
                event.keyCode === 33 ||
                event.keyCode === 34 ||
                event.keyCode === 35 ||
                event.keyCode === 36 ||
                (event.keyCode >= 37 && event.keyCode <= 40) ||
                event.keyCode === 45 ||
                event.keyCode === 46 ||
                (event.keyCode === 112 && event.keyCode === 123) ||
                event.keyCode === 127 ||
                event.keyCode === 144 ||
                (upStartIndex === 0 && event.keyCode === 189) ||
                (upStartIndex === 0 && event.keyCode === 109)
              )
            ) {
              // Allow backspace,tab,arrow keys
              // console.log('inside 1');
              return true;
            } else {
              // console.log('inside 2');
              event.preventDefault();
            }
          } else {
            if (
              (event.keyCode >= 65 && event.keyCode <= 90) ||
              (
                (key === ')' ||  // 48 -ipad
                  key === '!' ||  // 49-ipad
                  key === '@' ||   // 50-ipad
                  key === '#' ||   // 51-ipad
                  key === '$' ||   // 52-ipad
                  key === '%' ||   // 53-ipad
                  key === '^' ||   // 54-ipad
                  key === '&' ||   // 55-ipad
                  key === '*' ||   // 56-ipad
                  key === '(' ||
                  key === '_' ||
                  key === '=' ||
                  key === '>' ||
                  key === '<' ||
                  key === '?' ||
                  key === '+' ||
                  key === ':' ||
                  key === ';' ||
                  key === '"' ||
                  key === ',' ||
                  key === '\'' ||
                  key === '/' ||
                  key === '{' ||
                  key === '}' ||
                  key === ']' ||
                  key === '[' ||
                  key === '|' ||
                  key === '`' ||
                  key === '~'
                )
              ) ||
              (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109)
              )) {
              // Prevent alphabets values
              // console.log('inside 3');
              event.preventDefault();
            } else {
              // console.log('inside 4');
              if (event.target.value.split('.')[0].length >= alloWDigit) {
                if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
                  (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
                    (event.keyCode === 189 || event.keyCode === 109))) {
                  // Prevent alphabets values and numeric values if length greater than allowed length
                  // console.log('inside 5');
                  event.preventDefault();
                } else {
                  // console.log('inside 6');
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        } else {
          if (
            (event.keyCode >= 65 && event.keyCode <= 90) ||
            (
              (key === ')' ||  // 48 -ipad
                key === '!' ||  // 49-ipad
                key === '@' ||   // 50-ipad
                key === '#' ||   // 51-ipad
                key === '$' ||   // 52-ipad
                key === '%' ||   // 53-ipad
                key === '^' ||   // 54-ipad
                key === '&' ||   // 55-ipad
                key === '*' ||   // 56-ipad
                key === '(' ||
                key === '_' ||
                key === '=' ||
                key === '>' ||
                key === '<' ||
                key === '?' ||
                key === '+' ||
                key === ':' ||
                key === ';' ||
                key === '"' ||
                key === ',' ||
                key === '\'' ||
                key === '/' ||
                key === '{' ||
                key === '}' ||
                key === ']' ||
                key === '[' ||
                key === '|' ||
                key === '`' ||
                key === '~'
              )
            ) ||
            (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109)
            )) {
            // Prevent alphabets values
            // console.log('inside 3');
            event.preventDefault();
          } else {
            // console.log('inside 4');
            if (event.target.value.split('.')[0].length >= alloWDigit) {
              if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
                (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
                  (event.keyCode === 189 || event.keyCode === 109))) {
                // Prevent alphabets values and numeric values if length greater than allowed length
                // console.log('inside 5');
                event.preventDefault();
              } else {
                // console.log('inside 6');
                return true;
              }
            } else {
              return true;
            }
          }
        }
      } else {
        if (event.target.value.length >= alloWDigit) {
          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
            (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
              (event.keyCode === 189 || event.keyCode === 109))) {
            // Prevent alphabets values and numeric values if length greater than allowed length
            // console.log('inside 5');
            event.preventDefault();
          } else {
            // console.log('inside 6');
            return true;
          }
        } else {
          if ((event.keyCode >= 65 && event.keyCode <= 90) ||
            (
              (key === ')' ||  // 48 -ipad
                key === '!' ||  // 49-ipad
                key === '@' ||   // 50-ipad
                key === '#' ||   // 51-ipad
                key === '$' ||   // 52-ipad
                key === '%' ||   // 53-ipad
                key === '^' ||   // 54-ipad
                key === '&' ||   // 55-ipad
                key === '*' ||   // 56-ipad
                key === '(' ||
                key === '_' ||
                key === '=' ||
                key === '>' ||
                key === '<' ||
                key === '?' ||
                key === '+' ||
                key === ':' ||
                key === ';' ||
                key === '"' ||
                key === ',' ||
                key === '\'' ||
                key === '/' ||
                key === '{' ||
                key === '}' ||
                key === ']' ||
                key === '[' ||
                key === '|' ||
                key === '`' ||
                key === '~')
            ) || (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109))) {
            // Prevent alphabets values and - minus values
            // console.log('inside 7');
            event.preventDefault();
          } else {
            // console.log('inside 8');
            return true;
          }
        }
      }
    } catch (error) {
      console.error('Error occurred in keyDown', error);
    }
  }

  pasteFunc(event) {
    try {
      const alloWDecimalDigit = 16;
      const alloWDigit = 20;
      const noNegativeSign = this.params.NoNegativeSign;
      const a = (event.originalEvent || event).clipboardData.getData('text/plain');
      if (noNegativeSign && parseInt(a) < 0) {
        event.preventDefault();
      }
      if (isNaN((a.split(',')).join(''))) {
        event.preventDefault();
      } else {
        if (a.length > 20 || (a.split('.')[1] &&
          a.split('.')[1].length >= alloWDecimalDigit)) {
          event.preventDefault();
        } else {
          event.target.value = '';
          return true;
        }
      }
    } catch (error) {
      console.error('Error occurred in pasteFunc', error);
    }
  }

  init(params) {
    const _thatmethod = this;
    this.params = params;
    this.value = this.params.value || this.params.value === 0 ? this.params.value : '';
  }

  quantityCalc(event, from = '') {
    this.params.context.parentComponent.selectedColumn = this.params.colDef.field;
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    if (this.params.data && this.params.data.hasOwnProperty('Units') && this.params.data.UnitTypeId && this.params.data.Units.length > 0
      && this.params.colDef.from !== 'recipe-additem') {
      const nextUnit = this.params.data.Units.filter(x => x.UnitTypeId === this.params.data.UnitTypeId);
      const Valued = nextUnit && nextUnit.length > 0 ? nextUnit[0].UnitCost * event.target.value : 0;
      // rowNode.setDataValue('Qty', event.target.value);
      // rowNode.setDataValue('Valued', Valued);
      let calAmount: any = 0.0;
      let calCost: any = 0.0;
      let quantity: any = 0.0;
      let packSize: any = 0;
      let allocCharges: any = 0.0;
      if (this.params.data.Valued !== undefined && this.params.data.Valued !== '') {
        calAmount = this.params.data.Valued;
      }
      if (this.params.data.hasOwnProperty('Charges') && this.params.data.Charges !== undefined && this.params.data.Charges !== '') {
        allocCharges = this.params.data.Charges;
      }
      if (this.params.data.UnitCost !== undefined && this.params.data.UnitCost !== '') {
        calCost = this.params.data.UnitCost;
      }
      if (this.params.data.Qty !== '') {
        quantity = this.params.data.Qty;
      }
      if (this.params.data.Conversion !== '') {
        packSize = this.params.data.Conversion;
      }
      calAmount = calAmount.toString().split(',').join('');
      calCost = calCost.toString().split(',').join('');
      quantity = quantity ? quantity.toString().split(',').join('') : 0;
      if (isNaN(calAmount)) {
        calAmount = 0;
      } else {
        calAmount = parseFloat(calAmount).toFixed(2);
      }
      if (isNaN(calCost)) {
        calCost = 0;
      } else {
        calCost = parseFloat(calCost).toFixed(2);
      }
      if (isNaN(quantity)) {
        quantity = 0;
      } else {
        quantity = parseFloat(quantity).toFixed(2);
      }
      if (isNaN(packSize)) {
        packSize = 0;
      }
      if (this.params.colDef.field === 'Valued') {
        calAmount = event.target.value;
        calAmount = calAmount.toString().split(',').join('');
        const valued = calAmount ? parseFloat(calAmount).toFixed(2) : 0;
        if (+quantity === 0) {
          quantity = 1;
          calCost = +valued;
        } else {
          calCost = +valued / +quantity;
        }
        calCost = parseFloat(calCost).toFixed(3);
      } else if (this.params.colDef.field === 'UnitCost') {
        calCost = event.target.value;
        calCost = calCost.toString().split(',').join('');
        const unitCost = calCost ? parseFloat(calCost).toFixed(2) : 0;
        calAmount = +unitCost * +quantity;
        quantity = quantity !== '' ? parseFloat(quantity).toFixed(2) : '';
        calAmount = parseFloat(calAmount).toFixed(2);
      } else if (this.params.colDef.field === 'Qty') {
        quantity = from === 'fromGui' ? event : event.target.value;
        quantity = quantity.toString().split(',').join('');
        const qty = quantity ? parseFloat(quantity).toFixed(2) : 0;
        calAmount = (event.target && event.target.value === '-') ? 0.0 : +calCost * +qty;
        calAmount = parseFloat(calAmount).toFixed(2);
        // console.log('quantity update', quantity, calCost, calAmount);
      } else if (this.params.colDef.field === 'Conversion') {
        if (packSize.toString() === '0') {
          packSize = 1;
        }
        let data: any = event.target.value ? parseFloat(event.target.value.toString()) : 0;
        if (data !== 0) {
          data = parseFloat(data.toString()).toFixed(4);
          // const prevVal = calCost / packSize;
          // calCost = prevVal * data;
          // calCost = calCost.toString().split(',').join('');
          // calAmount = +calCost * +quantity;
          // calAmount = parseFloat(calAmount).toFixed(3);
          const unitData = this.params.data.Units.filter(x => x.UnitTypeId === 1 || x.UnitTypeId === 5)[0];
          let unitCost = unitData.UnitTypeId > 4 ? unitData.CostPerUnit : unitData.UnitCost;
          unitCost = parseFloat(unitCost).toFixed(2);
          calCost = unitCost * data;
          calAmount = +calCost * +quantity;
          calAmount = parseFloat(calAmount).toFixed(2);
        } else {
          calCost = 0;
          calAmount = 0;
        }
      } else if (this.params.colDef.field === 'Charges') {
        allocCharges = event.target.value;
        allocCharges = allocCharges.toString().split(',').join('');
        // calAmount = parseFloat(calAmount) + parseFloat(allocCharges);
        // calAmount = parseFloat(calAmount).toFixed(3);
      }

      rowNode.setDataValue('Price', calAmount);
      rowNode.setDataValue('Valued', calAmount);
      rowNode.setDataValue('UnitCost', calCost);
      rowNode.setDataValue('Qty', quantity);
      rowNode.setDataValue('Charges', allocCharges);

      const Amtdata: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['Valued'],
      };
      const Amtinstances = this.params.api.getCellEditorInstances(Amtdata);
      if (Amtinstances.length > 0) {
        Amtinstances[0].params.data.Valued = calAmount;
        Amtinstances[0].value = calAmount;
        Amtinstances[0].params.value = calAmount;
        Amtinstances[0].input.value = calAmount;
      }

      const Costdata: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['UnitCost'],
      };
      const Costinstances = this.params.api.getCellEditorInstances(Costdata);
      if (Costinstances.length > 0) {
        Costinstances[0].params.data.UnitCost = calCost;
        Costinstances[0].value = calCost;
        Costinstances[0].params.value = calCost;
        Costinstances[0].input.value = calCost;
      }

      const Qtydata: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['Qty'],
      };
      const Qtyinstances = this.params.api.getCellEditorInstances(Qtydata);
      if (Qtyinstances.length > 0) {
        Qtyinstances[0].params.data.Qty = quantity;
        Qtyinstances[0].value = quantity;
        Qtyinstances[0].params.value = quantity;
        if (Qtyinstances[0].input) {
          Qtyinstances[0].input.value = quantity;
        }
      }
      const TaxAmount1: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['TaxAmount1'],
      };
      let TaxAmount = 0;
      const TaxRate1 = this.params.data.TaxRate1;
      if (TaxRate1) {
        TaxAmount = (TaxRate1 / 100) * parseFloat(calAmount);
      }
      const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
      if (TaxAmount1instances.length > 0) {
        TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmount.toFixed(3);
        TaxAmount1instances[0].value = TaxAmount.toFixed(3);
        TaxAmount1instances[0].params.value = TaxAmount.toFixed(3);
        TaxAmount1instances[0].input.value = TaxAmount.toFixed(3);
      }

      const TaxAmount2: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['TaxAmount2'],
      };
      const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
      let TaxAmountVal2 = 0;
      const TaxRate2 = this.params.data.TaxRate2;
      if (TaxRate2) {
        TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calAmount);
      }
      if (TaxAmount2instances.length > 0) {
        TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountVal2.toFixed(3);
        TaxAmount2instances[0].value = TaxAmountVal2.toFixed(3);
        TaxAmount2instances[0].params.value = TaxAmountVal2.toFixed(3);
        TaxAmount2instances[0].input.value = TaxAmountVal2.toFixed(3);
      }
      // if(this.params.data && this.params.data.hasOwnProperty('TaxAmount1') && this.params.data.hasOwnProperty('TaxAmount2')){
      //   if(TaxAmount !== 0 && TaxAmountVal2 !== 0){
      //     const TaxAmounts = TaxAmount + TaxAmountVal2;
      //     calAmount = parseInt(calAmount) + TaxAmounts;
      //     calAmount = parseFloat(calAmount).toFixed(3);
      //   }
      // }
      rowNode.setDataValue('TaxAmount1', TaxAmount);
      rowNode.setDataValue('TaxAmount2', TaxAmountVal2);
      // rowNode.setDataValue('Valued', calAmount);
      // const Estimationdata: GetCellEditorInstancesParams = {
      //   rowNodes: [rowNode],
      //   columns: ['Valued'],
      // };
      // const Estimationinstances = this.params.api.getCellEditorInstances(Estimationdata);
      // if (Estimationinstances.length > 0) {
      //   Estimationinstances[0].params.data.Valued = calAmount;
      // }
    } else {
      if (this.params.colDef.from === 'salesforecasting' || this.params.colDef.from === 'recipe-additem') {
        this.params.colDef.valueFormatter(this.params, event.target.value);
        return true;
      }
      let calAmount: any = 0.0;
      let calCost: any = 0.0;
      let quantity: any = 0.0;
      let packSize: any = 0;
      if (this.params.data.hasOwnProperty('Price') && this.params.data.Price !== undefined && this.params.data.Price !== '') {
        calAmount = this.params.data.Price;
      }
      if (this.params.data.hasOwnProperty('Valued') && this.params.data.Valued !== undefined && this.params.data.Valued !== '') {
        calAmount = this.params.data.Valued;
      }
      if (this.params.colDef.from === 'sales') {
        calAmount = this.params.data.Valued !== '' ? this.params.data.Valued : "0.00";
      }
      if (this.params.data.UnitCost !== undefined && this.params.data.UnitCost !== '') {
        calCost = this.params.data.UnitCost;
      }
      if (this.params.data.Qty !== '') {
        quantity = this.params.data.Qty;
      }
      if (this.params.data.Conversion !== '') {
        packSize = this.params.data.Conversion;
      }
      calAmount = calAmount.toString().split(',').join('');
      calCost = calCost.toString().split(',').join('');
      quantity = quantity ? quantity.toString().split(',').join('') : 0;
      if (isNaN(calAmount)) {
        calAmount = 0;
      }
      if (isNaN(calCost)) {
        calCost = 0;
      }
      if (isNaN(quantity)) {
        calAmount = 0;
      }
      if (isNaN(packSize)) {
        packSize = 0;
      }
      if (this.params.colDef.from === 'sales') {
        let TaxAmountValue1: any;
        let TaxAmountValue2: any;
        if (this.params.colDef.field === 'Valued') {
          calCost = event.target.value;
          calCost = calCost.toString().split(',').join('');
          calAmount = +calCost / +quantity;
          quantity = quantity !== '' ? parseFloat(quantity).toFixed(2) : '';
          calAmount = parseFloat(calAmount).toFixed(3);
          const salesAmount: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['UnitCost'],
          };
          const salesAmountinstances = this.params.api.getCellEditorInstances(salesAmount);
          if (salesAmountinstances.length > 0) {
            salesAmountinstances[0].params.data.Price = calAmount;
            salesAmountinstances[0].value = calAmount;
            salesAmountinstances[0].params.value = calAmount;
            salesAmountinstances[0].input.value = calAmount;
          }
          const TaxAmount1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TaxAmount1'],
          };
          let TaxAmount = 0;
          const TaxRate1 = this.params.data.TaxRate1;
          if (TaxRate1) {
            TaxAmount = (TaxRate1 / 100) * parseFloat(calCost);
            TaxAmountValue1 = parseFloat(TaxAmount.toString()).toFixed(3);
          }
          const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
          if (TaxAmount1instances.length > 0) {
            TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmountValue1;
            TaxAmount1instances[0].value = TaxAmountValue1;
            TaxAmount1instances[0].params.value = TaxAmountValue1;
            TaxAmount1instances[0].input.value = TaxAmountValue1;
          }
          const TaxAmount2: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TaxAmount2'],
          };
          const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
          let TaxAmountVal2 = 0;
          const TaxRate2 = this.params.data.TaxRate2;
          if (TaxRate2) {
            TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calCost);
            TaxAmountValue2 = parseFloat(TaxAmountVal2.toString()).toFixed(3);
          }
          if (TaxAmount2instances.length > 0) {
            TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountValue2;
            TaxAmount2instances[0].value = TaxAmountValue2;
            TaxAmount2instances[0].params.value = TaxAmountValue2;
            TaxAmount2instances[0].input.value = TaxAmountValue2;
          }

          rowNode.setDataValue('TaxAmount1', TaxAmountValue1);
          rowNode.setDataValue('TaxAmount2', TaxAmountValue2);
          rowNode.setDataValue('Price', calAmount);
          rowNode.setDataValue('Valued', calCost);
          rowNode.setDataValue('UnitCost', calAmount);
          rowNode.setDataValue('Qty', quantity);
        } else if (this.params.colDef.field === 'Qty') {
          quantity = from === 'fromGui' ? event : event.target.value;
          quantity = quantity.toString().split(',').join('');
          calAmount = (event.target && event.target.value === '-') ? 0.0 : +calCost * +quantity;
          calAmount = parseFloat(calAmount).toFixed(3);
          const salescalAmount = calAmount * this.params.data.Valued;
          const salesAmount: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['Valued'],
          };
          const salesAmountinstances = this.params.api.getCellEditorInstances(salesAmount);
          if (salesAmountinstances.length > 0) {
            salesAmountinstances[0].params.data.Valued = calAmount;
            salesAmountinstances[0].value = calAmount;
            salesAmountinstances[0].params.value = calAmount;
            salesAmountinstances[0].input.value = calAmount;
          }
          const TaxAmount1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TaxAmount1'],
          };
          let TaxAmount = 0;
          const TaxRate1 = this.params.data.TaxRate1;
          if (TaxRate1) {
            TaxAmount = (TaxRate1 / 100) * parseFloat(calAmount);
            TaxAmountValue1 = parseFloat(TaxAmount.toString()).toFixed(3);
          }
          const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
          if (TaxAmount1instances.length > 0) {
            TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmountValue1;
            TaxAmount1instances[0].value = TaxAmountValue1;
            TaxAmount1instances[0].params.value = TaxAmountValue1;
            TaxAmount1instances[0].input.value = TaxAmountValue1;
          }
          const TaxAmount2: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TaxAmount2'],
          };
          const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
          let TaxAmountVal2 = 0;
          const TaxRate2 = this.params.data.TaxRate2;
          if (TaxRate2) {
            TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calAmount);
            TaxAmountValue2 = parseFloat(TaxAmountVal2.toString()).toFixed(3);
          }
          if (TaxAmount2instances.length > 0) {
            TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountValue2;
            TaxAmount2instances[0].value = TaxAmountValue2;
            TaxAmount2instances[0].params.value = TaxAmountValue2;
            TaxAmount2instances[0].input.value = TaxAmountValue2;
          }

          rowNode.setDataValue('TaxAmount1', TaxAmountValue1);
          rowNode.setDataValue('TaxAmount2', TaxAmountValue2);
          rowNode.setDataValue('Price', calCost);
          rowNode.setDataValue('Valued', calAmount);
          rowNode.setDataValue('UnitCost', calCost);
          rowNode.setDataValue('Qty', quantity);
        } else if (this.params.colDef.field === 'UnitCost') {
          calCost = event.target.value;
          calCost = calCost.toString().split(',').join('');
          calAmount = +calCost * +quantity;
          quantity = quantity !== '' ? parseFloat(quantity).toFixed(2) : '';
          calAmount = parseFloat(calAmount).toFixed(3);
          const salesAmount: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['Valued'],
          };
          const salesAmountinstances = this.params.api.getCellEditorInstances(salesAmount);
          if (salesAmountinstances.length > 0) {
            salesAmountinstances[0].params.data.Valued = calAmount;
            salesAmountinstances[0].value = calAmount;
            salesAmountinstances[0].params.value = calAmount;
            salesAmountinstances[0].input.value = calAmount;
          }
          const TaxAmount1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TaxAmount1'],
          };
          let TaxAmount = 0;
          const TaxRate1 = this.params.data.TaxRate1;
          if (TaxRate1) {
            TaxAmount = (TaxRate1 / 100) * parseFloat(calAmount);
            TaxAmountValue1 = parseFloat(TaxAmount.toString()).toFixed(3);
          }
          const TaxAmount1instances = this.params.api.getCellEditorInstances(TaxAmount1);
          if (TaxAmount1instances.length > 0) {
            TaxAmount1instances[0].params.data.TaxAmount1 = TaxAmountValue1;
            TaxAmount1instances[0].value = TaxAmountValue1;
            TaxAmount1instances[0].params.value = TaxAmountValue1;
            TaxAmount1instances[0].input.value = TaxAmountValue1;
          }
          const TaxAmount2: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TaxAmount2'],
          };
          const TaxAmount2instances = this.params.api.getCellEditorInstances(TaxAmount2);
          let TaxAmountVal2 = 0;
          const TaxRate2 = this.params.data.TaxRate2;
          if (TaxRate2) {
            TaxAmountVal2 = (TaxRate2 / 100) * parseFloat(calAmount);
            TaxAmountValue2 = parseFloat(TaxAmountVal2.toString()).toFixed(3);
          }
          if (TaxAmount2instances.length > 0) {
            TaxAmount2instances[0].params.data.TaxAmount2 = TaxAmountValue2;
            TaxAmount2instances[0].value = TaxAmountValue2;
            TaxAmount2instances[0].params.value = TaxAmountValue2;
            TaxAmount2instances[0].input.value = TaxAmountValue2;
          }

          rowNode.setDataValue('TaxAmount1', TaxAmountValue1);
          rowNode.setDataValue('TaxAmount2', TaxAmountValue2);
          rowNode.setDataValue('Price', calCost);
          rowNode.setDataValue('Valued', calAmount);
          rowNode.setDataValue('UnitCost', calCost);
          rowNode.setDataValue('Qty', quantity);
        }
      } else {
        if (this.params.colDef.field === 'Conversion') {
          if (packSize === '0.00') {
            packSize = 0;
          }
          if (packSize.toString() === '0') {
            packSize = 1;
          }
          const data = event.target.value ? parseFloat(event.target.value.toString()) : 0;
          if (data !== 0) {
            const prevVal = calCost / packSize;
            calCost = prevVal * data;
            calCost = calCost.toString().split(',').join('');
            calAmount = +calCost * +quantity;
            calAmount = parseFloat(calAmount).toFixed(3);
          }
        } else if (this.params.colDef.field === 'Qty') {
          quantity = from === 'fromGui' ? event : event.target.value;
          quantity = quantity.toString().split(',').join('');
          calAmount = (event.target && event.target.value === '-') ? 0.0 : +calCost * +quantity;
          calAmount = parseFloat(calAmount).toFixed(3);
        } else if (this.params.colDef.field === 'Cost') {
          let cost = event.target.value;
          const Qtys = this.params.data.Qtys ? this.params.data.Qtys : 1;
          const TotalCost = Qtys * (cost ?? 0);
          const TotalSales = (Qtys * (this.params.data.Valued ?? 0));
          const Costpercent = (TotalCost / (TotalSales == 0 ? 1 : TotalSales) * 100);
          const Margin = TotalSales - TotalCost;
          const Profitpercent = (Margin / (TotalSales == 0 ? 1 : TotalSales) * 100);
          rowNode.setDataValue('TotalSales', TotalSales);
          rowNode.setDataValue('TotalCost', TotalCost);
          rowNode.setDataValue('Costpercent', Costpercent);
          rowNode.setDataValue('Margin', Margin);
          rowNode.setDataValue('Profitpercent', Profitpercent);
          const Profitpercent1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['Profitpercent'],
          };
          const ProfitpercentIns = this.params.api.getCellEditorInstances(Profitpercent1);
          if (ProfitpercentIns.length > 0) {
            ProfitpercentIns[0].params.data.Profitpercent = Profitpercent;
            ProfitpercentIns[0].value = Profitpercent;
            ProfitpercentIns[0].params.value = Profitpercent;
            ProfitpercentIns[0].input.value = Profitpercent;
          }
          const TotalSales1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TotalSales'],
          };
          const TotalSalesIns = this.params.api.getCellEditorInstances(TotalSales1);
          if (TotalSalesIns.length > 0) {
            TotalSalesIns[0].params.data.TotalSales = TotalSales;
            TotalSalesIns[0].value = TotalSales;
            TotalSalesIns[0].params.value = TotalSales;
            TotalSalesIns[0].input.value = TotalSales;
          }
          const TotalCost1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['TotalCost'],
          };
          const TotalCostinstances = this.params.api.getCellEditorInstances(TotalCost1);
          if (TotalCostinstances.length > 0) {
            TotalCostinstances[0].params.data.TotalCost = TotalCost;
            TotalCostinstances[0].value = TotalCost;
            TotalCostinstances[0].params.value = TotalCost;
            TotalCostinstances[0].input.value = TotalCost;
          }
          const Margin1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['Margin'],
          };
          const Margininstances = this.params.api.getCellEditorInstances(Margin1);
          if (Margininstances.length > 0) {
            Margininstances[0].params.data.Margin = Margin;
            Margininstances[0].value = Margin;
            Margininstances[0].params.value = Margin;
            Margininstances[0].input.value = Margin;
          }
          const Costpercent1: GetCellEditorInstancesParams = {
            rowNodes: [rowNode],
            columns: ['Costpercent'],
          };
          const Costpercentinstances = this.params.api.getCellEditorInstances(Costpercent1);
          if (Costpercentinstances.length > 0) {
            Costpercentinstances[0].params.data.Costpercent = Costpercent;
            Costpercentinstances[0].value = Costpercent;
            Costpercentinstances[0].params.value = Costpercent;
            Costpercentinstances[0].input.value = Costpercent;
          }
        }
        rowNode.setDataValue('Price', calAmount);
        if (this.params.data.hasOwnProperty('Valued')) {
          rowNode.setDataValue('Valued', calAmount);
        }
        rowNode.setDataValue('UnitCost', calCost);
        rowNode.setDataValue('Qty', quantity);
      }
    }
  }

  getValue() {
    if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
    }
    if (this.params.colDef.field === 'Qty') {
      this.input.value = this.input.value ? parseFloat(this.input.value).toFixed(6) : this.input.value;
      this.input.value = parseFloat(this.input.value) === -0 ? parseFloat('0').toFixed(6) : this.input.value;
      this.params.data.Qty = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.field === 'Valued') {
      this.input.value = this.input.value ? parseFloat(this.input.value).toFixed(2) : this.input.value;
      this.params.data.Valued = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.field === 'UnitCost') {
      this.input.value = this.input.value ? parseFloat(this.input.value).toFixed(2) : this.input.value;
      this.params.data.UnitCost = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.field === 'Conversion') {
      if (this.params.data.Units && this.params.data.Units.length > 0 && (this.input.value === 0 || this.input.value === ''
        || this.input.value === '0')) {
        const unitData = this.params.data.Units.filter(x => x.UnitTypeId === this.params.data.UnitTypeId)[0];
        if (unitData && this.params.colDef.from !== 'recipe-additem') {
          const calCost = this.params.data.UnitTypeId > 4 ? unitData.CostPerUnit : unitData.UnitCost;
          let calAmount = +calCost * +this.params.data.Qty;
          const packSize = this.params.data.UnitTypeId === 4 || this.params.data.UnitTypeId === 6
            ? 1 / unitData.Conversion : unitData.Conversion;
          this.input.value = packSize;
          this.params.data.UnitCost = calCost;
          this.params.data.Price = calAmount;
          this.params.data.Values = calAmount;
        }
      }
      this.input.value = this.input.value ? parseFloat(this.input.value).toFixed(4) : parseFloat('0').toFixed(4);
      this.params.data.Conversion = this.input.value;
      return this.input.value;
    } else if (this.params.colDef.filter === 'Charges') {
      this.input.value = this.input.value ? parseFloat(this.input.value).toFixed(2) : this.input.value;
      this.params.data.Charges = this.input.value;
      return this.input.value;
    } else {
      const data = this.input.value;
      return data;
    }
  }

  focusIn() {
    this.input = document.getElementById('input-number-touch');
    if(!this.noMathService.params) {
    this.input.focus();
    this.input.select();
    }
  }

  afterGuiAttached() {
    try {
      this.input = document.getElementById('input-number-touch');
      if (this.input && !this.noMathService.params) {
        if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field
          && this.params.colDef.from !== 'supplierbids') {
          // this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
          this.input.focus();
          this.input.select();
        }
      }
    } catch (error) {
      console.error('Error occurred in number cell editor component of afterGuiAttached function', error);
    }
  }

  constratctMathEngineCalculation(
    noMathPopUpConfig: NoMathPopUpConfig,
    TransactionCollection: TransactionConfiguration,
    NoMathData: any, value: CallBackData, dialog: MatDialog, GridApi): void {
    try {
      switch (value.buttonValue.buttonKey) {
        case 'confirm':
          if (this.homeGroup.valid) {
            dialog.closeAll();
            const mathFieldValue = this.homeGroup.value;
            const mathCalculateQuantity = this.calculateMathNomathData(mathFieldValue, NoMathData);
            const noMathUniqueId = (NoMathData.hasOwnProperty('Id') && NoMathData.Id !== 0)
              ? NoMathData.Id : NoMathData['RowId'];
            // Duplicate Ingredient Id will be available so used RowId
            NoMathData['Qty'] = mathCalculateQuantity ? parseFloat(mathCalculateQuantity).toFixed(6)
              : parseFloat('0').toFixed(6);
            this.qtyvalue = NoMathData['Qty'];
            const detailConfig = {} as NomathDetailConfig;
            detailConfig.NoMathRowId = noMathUniqueId;
            detailConfig.NoMathDetailHeader = `${mathFieldValue['Qty']} ` + `${NoMathData['UnitCode']}`;
            detailConfig.NoMathDetailData = [];
            for (const property in mathFieldValue) {
              if (mathFieldValue[property]) {
                const UnitData = NoMathData['Units'];
                if (UnitData.length > 0) {
                  const mathUnits = UnitData.filter(x => x['UnitTypeName'] === property)[0];
                  if (mathUnits) {
                    const unitsObject = { MathType: '', MathText: '' };
                    unitsObject['MathType'] = (mathFieldValue[property] >= 0) ? 'Added' : 'Subtracted';
                    const symbol = (unitsObject['MathType'] === 'Added') ? '+' : '';
                    unitsObject['MathText'] = `<span class='no-math-type'>` + `${unitsObject['MathType']}</span>` + `<span class='math-${unitsObject['MathType']}'> ` + `${symbol} ` + `${mathFieldValue[property]}` + ` (${mathUnits['UnitCode']})</span> ` + `<span class='math-unit-name'> in ${mathUnits['UnitTypeName']}</span>`;
                    detailConfig.NoMathDetailData.push(unitsObject);
                  }
                }

              }

            }
            if (GridApi) {
              GridApi.api.refreshCells();
            }
            TransactionCollection.ActionConfig.filter(
              x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['NomathDetails'].push(detailConfig);
          } else {
            TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].
              ActionPopupConfig['PopupInputData']['FormGroupData'].markAllAsTouched();
          }
          break;
        case 'reset':
          const FormData = this.homeGroup;
          for (const property in FormData.controls) {
            if (property !== 'Qty' && FormData['controls'][property].value !== '') {
              FormData['controls'][property].setValue('');
            }
          }
          break;
        case 'cancel':
          dialog.closeAll();
          TransactionCollection.ActionConfig.filter(x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['PopupButtonConfig'].forEach((buttonModel: AgilysysButtonModel) => {
            if (buttonModel.buttonKey === 'confirm') {
              buttonModel.displayProperity = true;
            }
          });
          break;
        default:
        // code block
      }
    } catch (error) {
      console.error(error);
    }
  }

  calculateMathNomathData(MathFieldValue: any, NomathData: any): any {
    try {
      const actualQuantity = parseFloat(MathFieldValue['Qty'].toString());
      let calulateValue = 0;
      let unitConversion = 0;
      for (const property in MathFieldValue) {
        if (property !== 'Qty' && MathFieldValue[property] !== '') {
          // Converting to Pack size
          NomathData['Units'].forEach(element => {
            // && element.UnitTypeName !== 'Recipe Unit'
            if (element.UnitTypeName === property) {
              unitConversion = element.Conversion;
            }
            // Commented this becoz recipe conversion is coming as 1/ recipe conversion
            // so commented in UI
            // else if (property === 'Recipe Unit') {
            //     unitConversion = BaseUnitConversion / element.Conversion;
            // }
          });
          // Convert All BaseUnit Qty To Corresponding Unit Qty
          calulateValue += parseFloat(MathFieldValue[property].toString())
            * parseFloat(unitConversion !== 0 ? unitConversion.toString() : NomathData['Conversion'].toString());
        }
      }
      const CurrentConversion = NomathData.Units ? NomathData.Units.
        filter(x => x.UnitTypeId === NomathData.UnitTypeId)[0].Conversion : 1;
      const formattedcalulateValue = calulateValue !== 0 ? calulateValue / CurrentConversion : 0;
      return (formattedcalulateValue + actualQuantity);
    } catch (error) {
      console.error(error);
    }
  }

  isPopup() {
    return false;
  }

  private createOverlay(config: HeaderConfig, reorder: ElementRef) {
    const overlayConfig = this.getOverlayConfig(config, reorder);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: HeaderConfig, reorder: ElementRef): OverlayConfig {
    const info = reorder.nativeElement.getBoundingClientRect();
    const remainingHeight = window.innerHeight - info.top;
    let offsetY: number = info.height;
    if (remainingHeight < 480 && this.DivisionId === 1) {
      offsetY = info.height - 490;
    } else if (remainingHeight < 310 && this.DivisionId === 2) {
      offsetY = info.height - 320;
    }
    const pos: ConnectedPosition [] = [
      {
        // offsetX : (info.right - 300),
        offsetY : offsetY,
        overlayX: 'end',
        overlayY: 'top',
        originX: 'end',
        originY: 'top',
      }
  ];
    const positionStrategy = this.overlay.position().flexibleConnectedTo(reorder).
    withPositions(pos);
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      disposeOnNavigation: true
    });

    return overlayConfig;
  }

  openResizeRows(config: HeaderConfig = {}, resize: ElementRef) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig, resize);

    // Instantiate remote control
    const dialogRef = new HeaderOverlayRef(overlayRef);

    const overlayComponent = this.attachReSizeDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe(_ => {dialogRef.close(); });
    return dialogRef;
  }

  private attachReSizeDialogContainer(overlayRef: OverlayRef, config: HeaderConfig, dialogRef: HeaderOverlayRef) {
    const injectionTokens = new WeakMap();
    injectionTokens.set(HeaderOverlayRef, dialogRef);
    // injectionTokens.set(GRID_NAME, config.gridName);
    const injector = new PortalInjector(this.injector, injectionTokens);
    const containerPortal = new ComponentPortal(NomathmenuFormComponent, null , injector);
    const containerRef: ComponentRef<NomathmenuFormComponent> = overlayRef.attach(containerPortal);
    return null;
  }
  
}

const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';
const KEY_ENTER = 'Enter';
const KEY_ESCAPE = 'Escape';
