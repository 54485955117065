import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { CreateTransaction } from 'src/app/eatecui/source/transaction/shared/interface/create-transcation';
import {
  AddNewListConfiguration, AddNewTypeConfig, AddNewTypeGroupConfig, LastSessionUrl,
  LookupConfiguration, MappingItemListSearchUrl, SearchListHeaderConfig, SearchShowMoreConfig, TransactionConfiguration
} from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ConfirmPopModel, ErrorPopModel, QueryListModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { QueryListInterface } from 'src/app/eatecui/source/shared/models/query-list';
import { TranslateService } from '@ngx-translate/core';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { select, Store } from '@ngrx/store';
import { odataInventorySuccess } from 'src/app/eatecui/source/store/selectors/odata-configuration.selector';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { Subscription } from 'rxjs';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { GridTransaction } from 'src/app/eatecui/source/transaction/shared/interface/grid-transaction';
import { ActivatedRoute } from '@angular/router';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CoreFacadeService } from 'src/app/eatecui/source/core/services/core-facade.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { IframePopupModel, ItemFbProductModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysIframePopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-iframe-popup/agilysys-iframe-popup.component';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { AgilysysFbProductComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-fbproduct/agilysys-fbproduct.component';
import { AgilysysQueryListComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-querylist-popup/agilysys-querylist-popup.component';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { ItemSearchService } from './item-search.service';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { IsOutsideClicked } from 'src/app/eatecui/source/store/selectors/global-state.selector';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';
import { AdvancedSearchConfig, OptionsConfig } from 'src/app/eatecui/source/shared/models/advanced-search.interface';
import { HttpClient } from '@angular/common/http';
import { FilterFactory } from 'src/app/eatecui/source/shared/factory/filterFactory';
import { Filters } from 'src/app/eatecui/source/shared/nonodatacore/filters';
import { FilterFactoryArguments, FilterArguments } from 'src/app/eatecui/source/shared/nonodatacore/models/filterArguments.interface';
import { Filter } from 'src/app/eatecui/source/shared/nonodatacore/models/filter.interface';
import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { InventoryGroupName, SearchCategoryType } from 'src/app/eatecui/source/shared/enum/global.enum';
@Component({
  selector: 'app-item-search',
  templateUrl: './item-search.component.html',
  styleUrls: ['./item-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TransactionItemSearchComponent implements OnInit, OnChanges, AfterViewInit {
  form: FormGroup;
  transactionSearchType: AddNewTypeConfig[];
  transactionSearchGroupOption: AddNewTypeGroupConfig[];
  transactionOptionHeader: Array<SearchListHeaderConfig>;
  searchListData: Array<any> = [];
  localSearchListData: Array<any> = [];
  searchPlaceHolder: string;
  defaultSelect = new FormControl();
  searchControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  itemListForm: FormGroup;
  searchContainerWidth: number;
  expandChildPanel: boolean;
  selectedChildId: number;
  resultData: Array<any>;
  enableSearchListContainer: boolean;
  @Input() addNewListConfiguration: AddNewListConfiguration;
  @Input() transactionJson: TransactionConfiguration;
  @Input() MasterCollection: MasterInventoryManagement;
  advancedSearchConfiguration: Array<AdvancedSearchConfig>;
  searchConfig: AdvancedSearchConfig;
  selectedName: any;
  selectedIcon: any;
  allSelect = false;
  actionButtonConfig: AgilysysButtonModel[] = [];
  params = {} as QueryParams;
  TransactionDataSuccess: Subscription;
  OdataGlobalEntityModel: OdataGlobalEntity;
  selectedTransaction: any;
  transactionFrom: any;
  rowId: number;
  currencyList: any;
  unitsList: any = [];
  spoilageReasonsList: any = [];
  sourceNameFilter: any = ['Supplier', 'Customer', 'Recipes', 'Recipe']; // commented 'Inventory', for performance changes
  transactionJsonFilter: any = ['Packages', 'Inventory Reset'];
  MasterCollectionNameFilter: any = ['Inventory', 'Suppliers', 'Recipe'];
  selectedNameFilter: any = ['Inventory', 'Supplier', 'Customer', 'Product', 'Recipes', 'Recipe'];
  inventoryType: any;
  masterType: any;
  systemPreference: any;
  selectedGroupName: string;
  selectedDisplayName: any;
  isBarcodeScanned = false;
  pastedData: any;
  isTriggered: boolean;
  ViewModelEntity: any;
  SelectedItemList: any[] = [];
  ItemListSearchFrom: string;
  enableSearchOption = false;
  searchKey: any;
  showMoreConfig = {
    FetchAll: false,
    Skip: 0,
    Take: constants.InfiniteScrollItemListCount
  } as SearchShowMoreConfig;
  enableShowMore = true;
  enableNoDataFound = false;
  ScrollItemCount: number;
  ItemSelectionCount: number;
  TotalItemListRowCount: number;
  from: string;
  searchPattern: Array<OptionsConfig>;
  lookupFields: Array<OptionsConfig>;
  setLookupContainerHeight = false;
  // filter: Filter;
  // requestPagination: RequestPagination;
  constructor(
    private httpService: HttpService,
    private store: Store<AppState>,
    private transactionService: TransactionService,
    private routeNavigateService: RouteNavigateService,
    private masterManagementService: MasterManagementService,
    private createTransactionService: CreateTransactionService,
    private sysytemPreferenceService: SysytemPreferenceService,
    private translateService: TranslateService,
    private route: ActivatedRoute, private coreFacadeService: CoreFacadeService, private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private itemSearchService: ItemSearchService, private httpClient: HttpClient, public formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      searchPattern: ['1'],
      lookupFields: ['Name']
    });
    this.ScrollItemCount = sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting')).LoadMoreItems : constants.InfiniteScrollItemCount;
    this.ItemSelectionCount = this.itemSearchService.getCountValues('ItemListSelectionCount', constants, false);
    this.showMoreConfig.Take = this.ScrollItemCount;
    this.TransactionDataSuccess = this.store.pipe(select(odataInventorySuccess)).subscribe(async (x: OdataGlobalEntity) => {
      if (!this.OdataGlobalEntityModel) {
        if (x && x !== null) {
          this.OdataGlobalEntityModel = x;
          this.selectedTransaction = this.masterManagementService.
            masterDecodeUri(this.route.snapshot.queryParams, 'transaction', null);
          await this.getAdvancedSearchConfigData();
          if (this.selectedTransaction === undefined) {
            this.selectedTransaction = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'master', null);
          }
          if (this.selectedTransaction === undefined) {
            this.selectedTransaction = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'sales', null);
          }
          this.transactionFrom = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'transactionFrom', null);
          this.rowId = parseInt(this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'rowId', 'rowId'), 10);
          if (this.rowId === undefined || isNaN(this.rowId)) {
            this.rowId = parseInt(this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'id', 'id'), 10);
          }
          this.inventoryType = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'inventorytype', null);
          this.masterType = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'type', null);
          this.systemPreference = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'systemPreference', null);
          this.currencyList = this.transactionService.currencyList;
          this.unitsList = this.transactionService.unitList;
          this.spoilageReasonsList = this.transactionService.spoilageReasonsList;
        }
      }
    });
    this.store.pipe(select(IsOutsideClicked)).subscribe(x => {
      this.isTriggered = x;
      console.log('this.isTriggered', this.isTriggered);
    });
    this.expandChildPanel = false;
    this.isTriggered = false;
    // this.resultData = Preference['default'];
    this.enableSearchListContainer = false;
    this.actionButtonConfig = [
      {
        displayProperity: true,
        displayName: 'Query',
        buttonType: 'agilysys-button-secondary',
        buttonKey: 'query',
        buttonCallBack: (value: CallBackData) => {
          this.callBack(value);
        }
      },
      {
        displayProperity: false,
        displayName: 'Tretiary Button',
        buttonKey: 'tretiary',
        buttonType: 'agilysys-button-tretiary',
      },
      {
        displayProperity: true,
        displayName: 'Select',
        buttonType: 'agilysys-button-primary',
        buttonKey: 'select',
        buttonCallBack: (value: CallBackData) => {
          this.callBack(value);
        }
      }
    ];
  }

  async getAdvancedSearchConfigData(): Promise<void> {
    this.advancedSearchConfiguration = await this.httpClient.get<Array<any>>('app/eatecui/assets/json/advancedsearch-config.json').toPromise();
    console.log('Advanced Search Configuration', this.advancedSearchConfiguration);
    this.advancedSearchConfiguration.forEach(searchConfiguration => {
      const index = searchConfiguration.ModuleName.findIndex(name => name === this.selectedTransaction);
      if (index > -1) {
        this.searchConfig = searchConfiguration;
      }
    });
  }

  async callBack(value: CallBackData) {
    try {
      this.isTriggered = true;
      this.from = value.buttonValue.buttonKey;
      if (value.buttonValue.buttonKey === 'select') {
        const butcheryCheck: boolean = this.checkButcheryRecipe();
        if (butcheryCheck) {
          await this.checkLimitations().then(async result => {
            if (result && butcheryCheck) {
              const groupTypeData = this.transactionSearchType.filter(groupOpt => groupOpt.DefaultSelect)[0];
              const sourceName = groupTypeData.OptionGroup.filter(y => y.DefaultSelect === true)[0];
              let selectedList = this.addNewListConfiguration.SelectedItemList.filter(x => x.Checked === true);
              // Added condition for ProductLookup in Manual Sales
              if ((this.sourceNameFilter.indexOf(sourceName.Name) === -1 &&
                this.selectedTransaction !== 'pientercounts' &&
                this.selectedTransaction !== 'pientercyclecounts' &&
                (sourceName.EndPointUrl !== 'GetRecipeIngredientLookup' ||
                  (sourceName.EndPointUrl === 'GetRecipeIngredientLookup' && sourceName.Name !== 'Inventory')) &&
                (sourceName.EndPointUrl !== 'ProductLookup' ||
                  (sourceName.EndPointUrl === 'ProductLookup' && sourceName.Name !== 'Inventory'))) ||
                (this.sourceNameFilter.indexOf(sourceName.Name) !== -1 && (this.selectedTransaction === 'sales' ||
                  this.selectedTransaction === 'salesforecasting'))) {
                const selectedIds = [];
                let InventoryIds = [];
                selectedList.forEach(res => {
                  if (res.Checked && res.Id && ((sourceName.Name !== 'Inventory')
                    || (sourceName.EndPointUrl === 'GetRecipeIngredientLookup'))) {
                    selectedIds.push(res.Id);
                  } else if (sourceName.Name === 'Inventory') {
                    InventoryIds.push(res.IngredientId);
                    // } else if (sourceName.Name === 'Packages' && res.hasOwnProperty('PackLists') && res.PackLists.length > 0) {
                    //   InventoryIds = [...InventoryIds, ...res.PackLists.map((ids: any) => ids.Item)];
                  } else if (sourceName.Name === 'Product') { // Condition for ProductLookup Second call for TaxAmount 
                    if (res.ProductId && res.Checked) {
                      InventoryIds.push(res.ProductId);
                    }
                  }
                });
                this.getItemList(sourceName, selectedIds, InventoryIds);
                this.resetSearchandCollection();
              } else if (this.selectedTransaction === 'pientercounts' || this.selectedTransaction === 'pientercyclecounts') {
                const inventoryselectedIds = [];
                selectedList.forEach(res => {
                  if (res.Checked && res.IngredientId) {
                    inventoryselectedIds.push(res.IngredientId);
                  }
                });
                this.createTransactionService.constractItemDataPrefiled(
                  this.transactionService.transactionConfigurationData, inventoryselectedIds);
              } else {
                const FbItemlistData = selectedList;
                const GetFbProdcutItem = FbItemlistData.filter(x => x.hasOwnProperty('IsFGItem') && x.IsFGItem);
                if (GetFbProdcutItem && GetFbProdcutItem.length > 0) {
                  const fgDataItem = await this.checkFbProductItem(GetFbProdcutItem);
                  if (fgDataItem && fgDataItem.length > 0) {
                    const GetFgItemId: Array<any> = fgDataItem.map(x => x.Id ? x.Id : x.IngredientId);
                    const GetFilteredList = selectedList
                      .filter(e => !(GetFgItemId.findIndex(f => f === (e.Id ? e.Id : e.IngredientId)) > -1));
                    selectedList = [...GetFilteredList];
                  }

                }
                const itemListData = GridTransaction.GridItemListConfiguration(
                  this.transactionService.transactionConfigurationData, selectedList, this.currencyList, this.unitsList,
                  this.spoilageReasonsList, this.sysytemPreferenceService);
                // Comment for bug fix 135598
                // if (this.selectedTransaction === 'spoilage') {
                //   if (sourceName.TypeId !== 5) {
                //     itemListData.forEach(element => {
                //       element['TypeId'] = 1;
                //     });
                //   }
                // }
                this.addNewListConfiguration.ItemAdded(itemListData);
              }
              this.searchListData.forEach(res => {
                res.CategoryIconName = CategoryIcons.categoryIconName(res).IconName;
                res.Checked = false;
              });
              this.resetSearchandCollection();
              this.allSelect = false;
              this.enableSearchListContainer = false;
              this.expandChildPanel = false;
              this.selectedChildId = null;
            }
          });
        }
      } else if (value.buttonValue.buttonKey === 'cancel') {
        console.log('cancel Selected List');
        this.enableSearchListContainer = false;
        this.expandChildPanel = false;
        this.selectedChildId = null;
        this.enableNoDataFound = false;
        this.searchListData = [];
        this.resetSearchandCollection();
      } else if (value.buttonValue.buttonKey === 'query') {
        const groupTypeData = this.transactionSearchType.filter(groupOpt => groupOpt.DefaultSelect)[0];
        const sourceName = groupTypeData.OptionGroup.filter(y => y.DefaultSelect === true)[0];
        this.enableSearchListContainer = false;
        const selectedIds = [];
        const selectedLists = [];
        // if ((this.transactionJson && this.transactionJson.Name === 'Packages' &&
        //   this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 1 &&
        //   this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 7
        //   && sourceName.Name !== 'Class') || this.MasterCollection) {
        //   if (sourceName.Name === 'Packages' && sourceName.EndPointUrl !== 'IngredientLookup') {
        //     this.searchListData.forEach(x => {
        //       if (x.Checked) {
        //         selectedIds.push(x);
        //       }
        //     });
        //   } else {
        //     this.searchListData.forEach(res => {
        //       if (res.Checked && res.Id) {
        //         selectedIds.push(res.Id);
        //         selectedLists.push(res);
        //       }
        //     });
        //   }
        // } else {
          this.searchListData.forEach(res => {
            if (res.Checked && res.Id) {
              selectedIds.push(res.Id);
              selectedLists.push(res);
            }
          });
        // }
        console.log('Selected Ids, List', selectedIds, selectedLists);
        this.addNewListConfiguration.QueryList(selectedIds, selectedLists);
        this.allSelect = false;
        let from: any;
        if (this.rowId && this.transactionFrom) {
          from = { queryParams: { transaction: this.selectedTransaction, rowId: this.rowId, transactionFrom: this.transactionFrom } };
        } else if (this.transactionFrom) {
          from = { queryParams: { transaction: this.selectedTransaction, transactionFrom: this.transactionFrom } };
        } else if (this.rowId) {
          if (this.transactionJson && (this.transactionJsonFilter.indexOf(this.transactionJson.Name) > -1)) {
            from = { queryParams: { master: this.selectedTransaction, rowId: this.rowId } };
          } else if (this.MasterCollection && (this.MasterCollectionNameFilter.indexOf(this.MasterCollection.Name) > -1)) {
            from = {
              queryParams: {
                master: this.selectedTransaction, id: this.rowId, type: this.masterType,
                inventorytype: this.inventoryType, systemPreference: this.systemPreference
              }
            };
          } else {
            from = { queryParams: { transaction: this.selectedTransaction, rowId: this.rowId } };
          }
        } else {
          if (this.transactionJson && (this.transactionJsonFilter.indexOf(this.transactionJson.Name) > -1)) {
            from = { queryParams: { master: this.selectedTransaction, rowId: this.rowId } };
          } else if (this.MasterCollection && (this.MasterCollectionNameFilter.indexOf(this.MasterCollection.Name) > -1)) {
            from = {
              queryParams: {
                master: this.selectedTransaction, id: this.rowId, type: this.masterType,
                inventorytype: this.inventoryType, systemPreference: this.systemPreference
              }
            };
          } else {
            from = { queryParams: { transaction: this.selectedTransaction } };
          }
        }
        const MasterModuleName: Array<any> = ['Recipes', 'Ingredients', 'Suppliers', 'Products', 'Packages', 'packagelimitation'];
        const ModuleIndex = MasterModuleName.findIndex(x => x === this.selectedTransaction);
        let QueryListLookup = {} as TransactionConfiguration;
        if (ModuleIndex > -1) {
          QueryListLookup = await QueryListInterface.QueryTransMaster(sourceName, this.transactionJson,
            this.httpService, this.translateService, this.ViewModelEntity, this.ViewModelEntity, this.transactionService,
            this.selectedTransaction, this.OdataGlobalEntityModel, this.masterManagementService, selectedIds, selectedLists);
        }

        if (this.transactionJson !== undefined && ModuleIndex === -1) {
          QueryListLookup = await QueryListInterface.QueryTransData(sourceName, this.transactionJson,
            this.httpService, this.translateService, this.ViewModelEntity, this.ViewModelEntity, this.transactionService,
            this.selectedTransaction, this.OdataGlobalEntityModel, this.masterManagementService);
        }

        const queryListModel = {} as QueryListModel;
        queryListModel.PopupHeaderContent = sourceName.DisplayName;
        queryListModel.transactionJsonData = QueryListLookup;
        queryListModel.AddNewListConfigs = sourceName;
        queryListModel.SelectedIds = selectedIds;
        queryListModel.OdataGlobalEntity = this.OdataGlobalEntityModel;
        queryListModel.SelectedQueryList = selectedLists;
        const dialogRef = this.dialog.open(AgilysysQueryListComponent, {
          width: '87%',
          height: '80vh',
          maxWidth: '100vw',
          data: queryListModel,
          disableClose: true,
          panelClass: this.IsRguestEnabled() ? 'rguest-attached-files-popup' : 'attached-files-popup',
          hasBackdrop: true,
          backdropClass: 'detail-expand-backdrop-class',
          position: {
            right: '0'
          }
        });
        dialogRef.afterClosed().subscribe(async (result: Array<any>) => {
          const confirmation = result[0];
          if (confirmation === 'Confirm') {
            const selectedItemList = result[1];
            let subString;
            const InventoryIds = selectedItemList.map((res) => {
              subString = res.GridId.substring(0, res.GridId.indexOf('_') + 1);
              return res.GridId.toString().replace(subString, '');
            });
            this.SelectedItemList = [...selectedItemList];
            if (sourceName.EndPointUrl === 'GetRecipeIngredientLookup') {
              const GetFbProdcutItem = selectedItemList.filter(g => g.hasOwnProperty('IsFGItem') && g.IsFGItem);
              if (GetFbProdcutItem && GetFbProdcutItem.length > 0) {
                GetFbProdcutItem.map(g => g['Checked'] = true);
                const fgDataItem = await this.checkFbProductItem(GetFbProdcutItem);
                if (fgDataItem && fgDataItem.length > 0) {
                  const GetFgItemId: Array<any> = fgDataItem.map(n => n.Id ? n.Id : n.IngredientId);
                  const GetFilteredList = selectedItemList
                    .filter(e => !(GetFgItemId.findIndex(f => f === (e.Id ? e.Id : e.IngredientId)) > -1));
                  this.SelectedItemList = [...GetFilteredList];
                }
              }
              const itemListData = GridTransaction.GridItemListConfiguration(
                this.transactionService.transactionConfigurationData, this.SelectedItemList, this.currencyList, this.unitsList,
                this.spoilageReasonsList, this.sysytemPreferenceService);
              this.addNewListConfiguration.ItemAdded(itemListData);
            } else if (sourceName.EndPointUrl === 'GetSuppliers' || sourceName.EndPointUrl === 'GetCustomers'
              || sourceName.EndPointUrl === 'GetRecipes' || sourceName.EndPointUrl === 'Products') {
              const itemListData = GridTransaction.GridItemListConfiguration(
                this.transactionService.transactionConfigurationData, this.SelectedItemList, this.currencyList, this.unitsList,
                this.spoilageReasonsList, this.sysytemPreferenceService);
              this.addNewListConfiguration.ItemAdded(itemListData);
            } else {
              this.getItemList(sourceName, selectedIds, InventoryIds);
            }
          }
        });
      }
    } catch (error) {
      console.error('Error occurred in callBack', error);
    }
  }

  resetSearchandCollection() {
    this.searchControl.setValue('', { emitEvent: false });
    this.addNewListConfiguration.SelectedItemList = [];
    this.actionButtonConfig[2].displayName = 'Select';
  }

  checkLimitations() {
    try {
      return new Promise(resolve => {
        if (this.transactionService.transactionConfigurationData &&
          this.transactionService.transactionConfigurationData.CreateTranscationConfig &&
          this.transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig &&
          this.transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList &&
          this.transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig) {
          const ItemListCount = this.transactionService.transactionConfigurationData.
            CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length;
          const ConfirmPopUpDialog: ConfirmPopModel = {
            PopupHeaderContent: this.translateService.instant('Common.Info'),
            PopUpContent: '',
          };
          let ShowPopUp = false;
          let RecordCount = 0;
          switch (this.transactionJson?.OdataKey) {
            case 'itemmaintenance': this.TotalItemListRowCount = this.itemSearchService.getCountValues('BatchItemListRowCount', constants);
            break;
            default : this.TotalItemListRowCount = this.itemSearchService.getCountValues('ItemListPostLimitation', constants, false);
            break;
          }
          const SelectedCount = this.addNewListConfiguration.SelectedItemList.length;
          if (SelectedCount > this.ItemSelectionCount) {
            ShowPopUp = true;
            ConfirmPopUpDialog.PopUpContent = `You have exceeded your maximum limit of item selection.
              So first ${this.ItemSelectionCount} will be selected and added to item list.`;
          } else if (ItemListCount === this.TotalItemListRowCount && SelectedCount > 0) {
            ShowPopUp = true;
            ConfirmPopUpDialog.PopUpContent = 'You have reached your maximum limit of items allowed.';
            ConfirmPopUpDialog.EnableSingleButton = true;
          } else if ((ItemListCount + SelectedCount) > this.TotalItemListRowCount) {
            ShowPopUp = true;
            RecordCount = this.TotalItemListRowCount - ItemListCount;
            ConfirmPopUpDialog.PopUpContent = `You will be reaching your maximum limit of items allowed when all selected items are added.
            So first ${RecordCount} will be added to item list.`;
          } else {
            ShowPopUp = false;
            resolve(true);
          }
          const SearchValue = this.searchControl.value;
          if (ShowPopUp) {
            const dialog = this.dialog.open(AgilysysConfirmationPopupComponent, {
              width: '400px',
              height: '222px',
              data: ConfirmPopUpDialog,
              disableClose: true,
            });
            dialog.afterClosed().subscribe(res => {
              if (res === 'Confirm') { 
                // If User Exceeds the maximum limit we are closing the option panel and clearing the search field
                if (ConfirmPopUpDialog.EnableSingleButton) {
                  this.enableSearchListContainer = false;
                  this.expandChildPanel = false;
                  this.selectedChildId = null;
                  this.enableNoDataFound = false;
                  this.searchListData = [];        
                  this.resetSearchandCollection();
                  resolve(false);
                } else {
                  // If User Not exceeds the limit
                  if (SelectedCount > this.ItemSelectionCount) {
                    RecordCount = SelectedCount - this.ItemSelectionCount;
                    this.addNewListConfiguration.SelectedItemList.splice(this.ItemSelectionCount, RecordCount);
                  } else if ((ItemListCount + SelectedCount) > this.TotalItemListRowCount) {
                    RecordCount = this.TotalItemListRowCount - ItemListCount;
                    this.addNewListConfiguration.SelectedItemList.splice(RecordCount, ItemListCount + SelectedCount);
                  } 
                  this.actionButtonConfig[2].displayName = 'Select(' + this.addNewListConfiguration.SelectedItemList.length + ')';
                  resolve(true);
                }
              } else {
                // when user clicks cancel in info pop up
                // Commented for Bug 190230
                // this.addNewListConfiguration.SelectedItemList = [];
                // this.actionButtonConfig[2].displayName = 'Select';
                // this.enableSearchListContainer = true;
                // this.searchControl.setValue(SearchValue, { emitEvent: false });
                resolve(false);
              }
            });
          }
        } else {
          resolve(true);
        }
      });
    } catch (error) {
      console.error('Error occurred in checkLimitations', error);
    }
  }

  checkFbProductItem(selectedItemList: Array<any>) {
    try {
      return new Promise<any>((resolve, reject) => {
        if (selectedItemList && selectedItemList.length > 0) {
          const ConfirmPopUpDialog: ItemFbProductModel = {
            PopupHeaderContent: 'Add FG Products',
            SelectedItem: selectedItemList
          };
          const dialogRef = this.dialog.open(AgilysysFbProductComponent, {
            width: '600px',
            height: '400px',
            data: ConfirmPopUpDialog,
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(result => {
            const buttonKey = result[0];
            if (buttonKey === 'remove') {
              resolve(result[1]);
            } else {
              resolve(result[1]);
            }
          });
        } else {
          resolve(true);
        }
      });


    } catch (error) {
      console.error(error);
    }
  }

  getItemList(addNewTypeConfig: AddNewTypeConfig, selectedIds: any, selectedInventoryIds = null) {
    try {
      const selectedTypeIds = selectedIds;
      const seleId = `${addNewTypeConfig.QueryString} (` + `${selectedTypeIds})`;
      this.params.expand = addNewTypeConfig.QueryExpand;
      const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
      this.params.filterQuery = GetDataFilter;
      let LocId = 0;
      let options = '';
      const lookUpData = {} as LookupConfiguration;
      const ModuleName = (this.transactionJson && this.transactionJson.Name) ? this.transactionJson.Name : undefined;
      switch (ModuleName) {
        case 'Transfer':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
          break;
        case 'spoilage':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          break;
        case 'Requisition':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
          break;
        case 'Purchase Request':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['RequestLocId'];
          options = this.AssignBidsOptions();
          break;
        case 'Physical Inventory':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          break;
        case 'Receiving':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          break;
        case 'Purchase Order':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          options = this.AssignBidsOptions();
          break;
        case 'Production':
        case 'productionplan':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          break;
        case 'Item Pars':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
          break;
        case 'Butchery':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          break;
        case 'sales':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SalesLocationId'];
          break;
        case 'PlanHead':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          break;
        case 'cycleplanning':
          const locId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          LocId = locId > 0 ? locId : 0;
          break;
        case 'quickorder':
          LocId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
          break;
        case 'Batch Item Maintenance':
          lookUpData.DivisionId = this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['Division'];
          lookUpData.IsBatchItem = true;
          break;
      }
      const selectItemTypeId = addNewTypeConfig.DefaultTypeId ? addNewTypeConfig.DefaultTypeId : addNewTypeConfig.TypeId;
      lookUpData.Id = 0;
      lookUpData.TransType = (this.transactionJson) ? this.transactionService.transactionConfigurationData.TransactionType : 0;
      if (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup') {
        lookUpData.itemType = selectItemTypeId;
      }
      if (addNewTypeConfig.EndPointUrl === 'IngredientLookup') {
        lookUpData.SupplierId = (this.transactionJson && this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SupplierId']) ?
          this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SupplierId'] : 0;
        lookUpData.PostDate = (this.transactionJson && this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate']) ?
          this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate'] : null;
      }
      lookUpData.searchText = '';
      lookUpData.FromLocationId = LocId;
      lookUpData.ToLocationId = (this.transactionJson) ?
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.
          TransactionFormConfig.FormGroupData['ToLocationId'] : 0;
      lookUpData.TypeIds = [];
      lookUpData.PackageIds = [];
      lookUpData.ClassIds = [];
      lookUpData.options = options;
      // Added 'addNewTypeConfig.Name === 'Product' ' for Product Lookup
      lookUpData.flgSearch = this.checkIsFlagProperty(addNewTypeConfig.Name)
      lookUpData.FetchAll = true;
      lookUpData.IsQuery = false;
      lookUpData.selectedIngredients = selectedInventoryIds && selectedInventoryIds.length > 0 ? selectedInventoryIds : [];
      lookUpData.IsFromMasterLimit = this.transactionJson?.Name === 'PackageLimitation' ? true : false;
      if (addNewTypeConfig.Name === 'Class') {
        lookUpData.ClassIds = selectedTypeIds;
        if (this.transactionJson?.Name === 'PackageLimitation' || this.transactionJson?.Name === 'Packages') {
          lookUpData.IsFromMasterLimit = this.transactionJson.Name === 'PackageLimitation' ? true : false;
        }
      } else if (addNewTypeConfig.Name === 'Type') {
        lookUpData.TypeIds = selectedTypeIds;
        if (this.transactionJson?.Name === 'PackageLimitation' || this.transactionJson?.Name === 'Packages') {
          lookUpData.IsFromMasterLimit = this.transactionJson.Name === 'PackageLimitation' ? true : false;
        }
      } else if (addNewTypeConfig.Name === 'Packages') {
        lookUpData.PackageIds = selectedTypeIds;
        lookUpData.LoadItemsfromPackages = true
        if( this.transactionJson?.Name === 'PackageLimitation' || this.transactionJson?.Name === 'Packages'){
          lookUpData.IsFromMasterLimit = this.transactionJson.Name === 'PackageLimitation' ? true : false;
          lookUpData.Skip = 0;
          lookUpData.Take = 1000;
          lookUpData.GetTotalRecCount = true;
          lookUpData.TransTypeId = lookUpData.TransType;          
        }
      }
      if (lookUpData.selectedIngredients.length > 0) {
        lookUpData.ClassIds = [];
        lookUpData.TypeIds = [];
        lookUpData.PackageIds = [];
      }
      if (this.selectedTransaction === 'Recipes' && selectItemTypeId === 1) {
        lookUpData.DivisionIds = [1];
      }
      if (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup') {
        if (lookUpData.hasOwnProperty('flgSearch')) {
          delete lookUpData.flgSearch;
        }
        if (lookUpData.hasOwnProperty('IsQuery')) {
          delete lookUpData.IsQuery;
        }
      }
      if (ModuleName === 'Butchery') {
        lookUpData.IsButchery = true;
      }
      const endPointUrl = `/inventory/api/${addNewTypeConfig.EndPointUrl}`;
      // if ((this.transactionJson && this.transactionJson.Name === 'Packages' &&
      //   this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 1 &&
      //   this.transactionJson.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 7)
      //   || (this.MasterCollection && this.MasterCollection.Key !== 'Suppliers' && this.MasterCollection.Key !== 'Recipes')) {
      //   // if (addNewTypeConfig.Name === 'Packages') {
      //   //   const Data = [];
      //   //   this.searchListData.forEach(x => {
      //   //     if (x.Checked) {
      //   //       x.PackLists.forEach(element => {
      //   //         if (element.IsActive) {
      //   //           Data.push(element.Item);
      //   //         }
      //   //       });
      //   //     }
      //   //   });

      //   //   const seleId1 = `${addNewTypeConfig.QueryString} (` + `${Data.join()})`;
      //   //   const GetDataFilter1 = encodeURIComponent(`${seleId1}`).replace(/[!'()*]/g, function (c) {
      //   //     return '%' + c.charCodeAt(0).toString(16);
      //   //   });
      //   //   this.params.filterQuery = GetDataFilter1;
      //   // }
      //   // const endPointUrl1 = endPointUrl + `?$filter=${this.params.filterQuery}`;
      //   // this.httpService.GethttpMethod(endPointUrl1).subscribe(x => {
      //   //   const responseData = x['value'];
      //   //   console.log('responseData', responseData);
      //   //   const itemListData = GridTransaction.GridItemListConfiguration(
      //   //     this.transactionService.transactionConfigurationData, responseData, this.currencyList, this.unitsList,
      //   //     this.spoilageReasonsList, this.sysytemPreferenceService);
      //   //   this.addNewListConfiguration.ItemAdded(itemListData);
      //   // });
      // } else {
        this.httpService.PosthttpMethod(endPointUrl, lookUpData).subscribe(async x => {
          let responseData = addNewTypeConfig.Name === 'Packages' && addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup' ?
            x['Data']['ent']['PackageIds'] : (x['Data'] ? x['Data']['ent']['ResultData'] : x['ResultData']);
          // const responseData = x['Data'] ? x['Data']['ent']['ResultData'] : x['ResultData'];
          if (responseData && responseData.length > 0) {
            if (addNewTypeConfig.Name === 'Packages' && addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup') {
              const resultData = x['Data']['ent']['PackageResults'];
              const values = Object.keys(resultData);
              const ItemList = [];
              values.forEach((data: any, Index: number) => {
                if (resultData[data].length > 0) {
                  resultData[data].forEach(element => {
                    ItemList.push(element);
                  });
                }
              });
              responseData = ItemList;
            }
            let reAssignresponseData = [];
            responseData.forEach((element: any) => {
              const dataObject = _.mapKeys(element, function (v, k) {
                return k.charAt(0).toUpperCase() + k.slice(1);
              });
              reAssignresponseData.push(dataObject);
            });
            const FbItemlistData = reAssignresponseData;
            const GetFbProdcutItem = FbItemlistData.filter(g => g.hasOwnProperty('IsFGItem') && g.IsFGItem);
            if (GetFbProdcutItem && GetFbProdcutItem.length > 0) {
              GetFbProdcutItem.map(g => g['Checked'] = true);
              const fgDataItem = (this.selectedTransaction !== 'production' && this.selectedTransaction !== 'butchery' && this.selectedTransaction !== 'productionplan') ? await this.checkFbProductItem(GetFbProdcutItem) : [];
              if (fgDataItem && fgDataItem.length > 0) {
                const GetFgItemId: Array<any> = fgDataItem.map(n => n.Id ? n.Id : n.IngredientId);
                const GetFilteredList = reAssignresponseData
                  .filter(e => !(GetFgItemId.findIndex(f => f === (e.Id ? e.Id : e.IngredientId)) > -1));
                reAssignresponseData = [...GetFilteredList];
              }
            }

            if (this.isBarcodeScanned) {
              const sound = responseData.length > 0 ? 'assets/BeepAudio/success.mp3' : 'assets/BeepAudio/failure.mp3';
              const audio = new Audio(sound);
              audio.play();
              setTimeout(() => {
                const itemListData = GridTransaction.GridItemListConfiguration(
                  this.transactionService.transactionConfigurationData, responseData, this.currencyList, this.unitsList,
                  this.spoilageReasonsList, this.sysytemPreferenceService);
                this.addNewListConfiguration.SelectedItemList = itemListData;
                this.addNewListConfiguration.ItemAdded(itemListData);
                this.searchControl.setValue('');
                this.isBarcodeScanned = false;
                this.pastedData = null;
              }, 10);
            } else {
              const itemListData = GridTransaction.GridItemListConfiguration(
                this.transactionService.transactionConfigurationData, reAssignresponseData, this.currencyList, this.unitsList,
                this.spoilageReasonsList, this.sysytemPreferenceService);
              // if (this.selectedTransaction === 'spoilage') {
              //   if (addNewTypeConfig.TypeId !== 5) {
              //     itemListData.forEach(element => {
              //       element['TypeId'] = 1;
              //     });
              //   }
              // }
              this.addNewListConfiguration.SelectedItemList = itemListData
              await this.checkLimitations().then(res => {
                this.addNewListConfiguration.ItemAdded(this.addNewListConfiguration.SelectedItemList);
                this.addNewListConfiguration.SelectedItemList = [];
                this.actionButtonConfig[2].displayName = 'Select';
              });
            }
          } else {
            // if ((lookUpData.searchText || this.searchKey) && responseData.length === 0) {
            //   this.setLookupContainerHeight = (this.searchListData.length) >= 9 ? true : false;
            //   this.searchListData = [];
              // this.enableNoDataFound = true;
              // this.enableSearchOption = false;
              this.ref.detectChanges();
              this.enableSearchListContainer = false;
            // }
          }
        });
      // }
    } catch (error) {
      console.error('Error occured in getItemList', error);
    }
  }

  /**
 *  checkIsFlagPropertty
   * @param params 
   * @returns  this.from === 'query' && (addNewTypeConfig.Name === 'Class' || addNewTypeConfig.Name === 'Type')
   */
  checkIsFlagProperty(TypeName: string): boolean {
    try {
      let isFlagSearch = true;
      if ( TypeName === SearchCategoryType.Inventory ) {
        isFlagSearch = false
      } if ( TypeName === SearchCategoryType.Product ) {
        isFlagSearch = false
      } else if ( TypeName === SearchCategoryType.Packages ) {
        isFlagSearch = false
      } else if( this.from === 'query' && ( TypeName === SearchCategoryType.Class || TypeName === SearchCategoryType.Type )){
        isFlagSearch = false
      }
      return isFlagSearch;
    } catch (error) {
      console.error(error);
    }
  }

  formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
      if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
        if (params[data] !== '' && data !== 'filter') {
          if (index === 0) {
            query = query + '?$';
          } else {
            if (query.length > 0) {
              query = query + '&$';
            } else {
              query = query + '?$';
            }
          }
          query = query + data + '=' + params[data];
        } else if (data === 'filter') {
          if (params.isactive) {
            params[data] = 'IsActive eq true';
          }
          if (params.searchtext && params.searchtext.length > 0 && params.search) {
            if (params[data].length > 0) {
              params[data] = params[data] + ' and ' + params.search;
            } else {
              params[data] = params.search;
            }
          }
          if (params.filterQuery && params.filterQuery.length > 0) {
            if (params[data].length > 0) {
              params[data] = params[data] + ' and ' + params.filterQuery;
            } else {
              params[data] = params.filterQuery;
            }
          }
          if (params[data] !== '') {
            if (index === 0) {
              query = query + '?$';
            } else {
              query = query + '&$';
            }
            query = query + data + '=' + params[data];
          }
        }
      }
    });
    return query;
  }

  getLocalSearchData(searchKey: any, typeData: AddNewTypeConfig, transactionOptionHeader: SearchListHeaderConfig[]) {
    try {
      if (this.localSearchListData.length > 0 && this.searchKey) {
        // Code to perform local search for name, description and barcode if its inventory
        this.searchListData = this.localSearchListData.filter(x => x.Name.toLowerCase().includes(this.searchKey) ||
          (typeData.Name === 'Inventory' ?
            (x.hasOwnProperty('Barcode') ? (x.Barcode ? x.Barcode.toLowerCase().includes(this.searchKey) : false) : true) ||
            (x.hasOwnProperty('Description') ? (x.Description ? x.Description.toLowerCase().includes(this.searchKey) : false) : true)
            : true));
        this.enableSearchListContainer = true;
        this.enableSearchOption = this.searchListData.length === 0 ? true : false;
      } else {
        this.enableSearchOption = this.searchKey && this.searchListData.length === 0 ? true : false;
        this.searchListData = this.localSearchListData;
      }
    } catch (error) {
      console.error('Error occurred in getLocalSearchData', error);
    }
  }

  searchForOptions() {
    this.enableSearchListContainer = true;
    this.enableSearchOption = true;
    this.searchListData = [];
    const groupTypeData = this.transactionSearchType.filter(groupOpt => groupOpt.DefaultSelect)[0];
    const typeData = groupTypeData.OptionGroup.filter(y => y.Id === this.defaultSelect.value)[0];
    this.showMoreConfig.FetchAll = false;
    this.showMoreConfig.Take = 10;
    this.showMoreConfig.Skip = 0;
    this.getFilterData(this.httpService, this.searchKey, typeData, this.transactionOptionHeader, this.showMoreConfig);
  }

  async getFilterData(
    httpService: HttpService, searchKey: any,
    typeData: AddNewTypeConfig, transactionOptionHeader: SearchListHeaderConfig[],
    searchShowMore: SearchShowMoreConfig) {
    try {
      const _that = this;
      if (this.isBarcodeScanned) {
        this.enableSearchOption = false;
      }
      // Removing Focus from textbox while api call is pending
      const searchTextBox = document.getElementById('add-new-item');
      if (searchKey) {
        if (searchTextBox) {
          searchTextBox.blur();
        }
      }
      let listDataata;
      let spLookup = false;
      const lookupType: Array<string> = ['Packages'];
      const lookUpIndex: number = lookupType.findIndex(x => x === typeData.Name);
      const mappingItemList: MappingItemListSearchUrl = this.mappingSearchUrl(typeData);
      if ( mappingItemList.EnvModule === 'SP' ) {
        listDataata = CreateTransaction.ItemListSPSearch(httpService, searchKey, this.form, typeData, this.sysytemPreferenceService, transactionOptionHeader,
          this.transactionService.transactionConfigurationData, searchShowMore,
          this.selectedTransaction, this.selectedGroupName, mappingItemList);
        spLookup = true;
      } else {
        listDataata = CreateTransaction.itemListSearch(
          httpService, searchKey, this.form, typeData, this.sysytemPreferenceService, transactionOptionHeader,
          this.transactionService.transactionConfigurationData, searchShowMore,
          this.selectedTransaction);
      }
      listDataata.then(x => {
        searchTextBox.focus();
        const responseData = [];
        const resultSet: Array<any> = this.responseDatavalueMapping(x);
        let totalCount = 0;
        if (spLookup) {
          totalCount = resultSet.length;
        } else if (typeData.Name === 'Inventory' || typeData.Name === 'Recipes') {
          totalCount = x['Data']['ent']['TotalCount'];
        } else if (typeData.Name === 'Product') {
          totalCount = x['TotalCount'];
        } else {
          totalCount = x['@odata.count'];
        }
        resultSet.forEach((element: any) => {
          if (typeData.IsDisableRow && typeData.DisableRefField) {
            element.IsDisable = element[typeData.DisableRefField] === undefined
              ? false : (element[typeData.DisableRefField] === true ? false : true);
          } else {
            const IconObject = CategoryIcons.categoryIconName(element);
            element.CategoryIconName = IconObject.IconName;
            element.CategoryIconToolTip = IconObject.IconToolTip;
            element.IsDisable = false;
          }
          if (element.hasOwnProperty('ChildIngredients') && element.ChildIngredients) {
            element.PanelOpen = true;
            element.ChildIngredients = JSON.parse(element.ChildIngredients);
          } else {
            element.ChildIngredients = [];
          }
          const dataObject = _.mapKeys(element, function (v, k) {
            return k.charAt(0).toUpperCase() + k.slice(1);
          });
          responseData.push(dataObject);
        });
        // const FromBarcodeScan = responseData.length === 1 && _that.defaultSelect.value === 1 &&
        //   this.pastedData === responseData[0].Barcode ? true : false;
        // _that.searchListData = _.cloneDeep([...responseData]);
        const FromBarcodeScan = (_that.defaultSelect.value === 1 && this.pastedData &&
          responseData && responseData.length <= 1) ? true : false;
        if (!FromBarcodeScan) {
          if (this.showMoreConfig.Skip > 0 && responseData.length > 0) {
            responseData.forEach(z => {
              _that.searchListData.push(z);
            });
          } else {
            _that.searchListData = _.cloneDeep([...responseData]);
          }
          const checkProperty = typeData.Name === 'Inventory' ? 'IngredientId' :
            typeData.Name === 'Product' ? 'ProductId' : 'Id';
          _that.searchListData.forEach(res => {
            res.Checked = _that.addNewListConfiguration.SelectedItemList.length > 0 &&
              _that.addNewListConfiguration.SelectedItemList.findIndex(ing => ing[checkProperty]
                === res[checkProperty] && ing.Checked === true) > -1 ? true : false;
          });
          const count = _that.searchListData.filter(y => y.Checked === true).length;
          if (count > 0) {
            this.actionButtonConfig[2].displayProperity = false;
            if (this.selectedNameFilter.indexOf(this.selectedName) === -1) {
              this.actionButtonConfig[0].displayProperity = false;
            }
            this.actionButtonConfig[2].displayName = 'Select(' + count + ')';
          } else {
            this.actionButtonConfig[2].displayProperity = true;
            if (this.selectedNameFilter.indexOf(this.selectedName) === -1) {
              this.actionButtonConfig[0].displayProperity = true;
            }
            this.actionButtonConfig[2].displayName = 'Select';
          }
          _that.searchListData = [..._that.searchListData];
          const totalItems = _that.searchListData.reduce((count, current) =>
            count + (current.ChildIngredients ? current.ChildIngredients.length : 0), 0);
          //_that.enableShowMore =  _that.ScrollItemCount > (totalItems +_that.searchListData.length) ? false : true;
          _that.enableShowMore = totalCount === (totalItems + _that.searchListData.length) ? false : true;
          _that.setLookupContainerHeight = (_that.searchListData.length + totalItems) >= 9 ? true : false;
          if (_that.searchListData.length > 0) {
            _that.enableNoDataFound = false;
          } else {
            _that.enableSearchOption = false;
            _that.enableNoDataFound = true;
          }
          _that.ref.detectChanges();
          _that.enableSearchListContainer = true;
        } else {
          if (typeData.EndPointUrl === 'IngredientLookup' && this.selectedTransaction !== 'pientercounts'
            && this.selectedTransaction !== 'pientercyclecounts') {
            this.getItemList(typeData, [], (responseData[0] ? [responseData[0].IngredientId] : []));
          } else if (typeData.EndPointUrl === 'ProductLookup') {
            this.getItemList(typeData, [], (responseData[0] ? [responseData[0].ProductId] : []));
          } else {
            const sound = responseData.length > 0 ? 'assets/BeepAudio/success.mp3' : 'assets/BeepAudio/failure.mp3';
            const audio = new Audio(sound);
            audio.play();
            setTimeout(() => {
              if ((this.selectedTransaction === 'pientercounts' || this.selectedTransaction === 'pientercyclecounts')
                && responseData.length > 0) {
                const inventoryselectedIds = [];
                responseData.forEach(res => {
                  if (res.IngredientId) {
                    inventoryselectedIds.push(res.IngredientId);
                  }
                });
                this.createTransactionService.constractItemDataPrefiled(
                  this.transactionService.transactionConfigurationData, inventoryselectedIds);
              } else {
                const itemListData = GridTransaction.GridItemListConfiguration(
                  this.transactionService.transactionConfigurationData, responseData, this.currencyList, this.unitsList,
                  this.spoilageReasonsList, this.sysytemPreferenceService);
                this.addNewListConfiguration.ItemAdded(itemListData);
              }
              this.searchControl.setValue('');
              this.isBarcodeScanned = false;
              this.pastedData = null;
            }, 10);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @method mappingSearchUrl
   * @input TypeData
   * @description mappingSearchUrlData
   */
  mappingSearchUrl(TypeConfig : AddNewTypeConfig): MappingItemListSearchUrl {
    try{
       const urlMapping = {} as MappingItemListSearchUrl;
       urlMapping.EnvModule = "odata"
       if( TypeConfig.Name === 'Packages' || TypeConfig.Name === 'Type' || TypeConfig.Name === 'Class'){
            urlMapping.EnvModule = "SP";
            urlMapping.MappingSearchUrlKey = "GetClassesTypesPackages";
            urlMapping.MappingDetailDataUrlKey = 'IngredientLookup';
       } else if( TypeConfig.Name === 'Customer' ){
          urlMapping.EnvModule = "SP";
          urlMapping.MappingSearchUrlKey = "GetCustomers";
          urlMapping.MappingDetailDataUrlKey = 'GetCustomers';
       } else if( TypeConfig.Name === 'Supplier' ){
        urlMapping.EnvModule = "SP";
        urlMapping.MappingSearchUrlKey = "GetSuppliers";
        urlMapping.MappingDetailDataUrlKey = "GetSuppliers";
     } else if ( TypeConfig.Name === 'Recipe') {
      urlMapping.EnvModule = "SP";
      urlMapping.MappingSearchUrlKey = "GetRecipes";
      urlMapping.MappingDetailDataUrlKey = "GetRecipes";
     }
     return urlMapping;

    } catch(error){
      console.error(error);
    }
  }

  /**
   * @method responseDatavalueMapping
   * @input ResultData
   * @description arrange Result DATA
   */
  responseDatavalueMapping(ResposeData: any): Array<any> {
    try {
      let resultData: Array<any> = [];
      if (ResposeData && ResposeData.Data && ResposeData.Data.ent && ResposeData.Data.ent.ResultData) {
        resultData = ResposeData.Data.ent.ResultData
      } else if (ResposeData && ResposeData.value) {
        resultData = ResposeData.value
      } else if (ResposeData && ResposeData.ResultData) {
        resultData = ResposeData.ResultData
      } else if (ResposeData && ResposeData.length > 0 && ResposeData[0].ResultData) {
        resultData = ResposeData[0].ResultData
      }
      return resultData;
    } catch (error) {
      console.error(error);
    }
  }

  addNewInventory(): void {
    try {
      this.isTriggered = true;
      this.resetSearchandCollection();
      this.enableSearchListContainer = false;
      if (this.addNewListConfiguration && this.addNewListConfiguration.hasOwnProperty('AddNewInventory') &&
        this.addNewListConfiguration.AddNewInventory) {
        const AddNewQueryParams = {
          queryParams: this.addNewListConfiguration.AddNewInventory.AddNewQueryParams
        };
        let lastSessionParams = null;
        if (this.route && this.route.snapshot && this.route.snapshot.queryParams &&
          Object.keys(this.route.snapshot.queryParams).length > 0 && this.route.snapshot.queryParams.data) {
          if (this.route.snapshot.queryParams.hasOwnProperty('data')) {
            const urlDataObject = JSON.parse(decodeURIComponent(this.route.snapshot.queryParams.data));
            const url = this.route.snapshot.url;
            if (Object.keys(urlDataObject).length > 0) {
              const lastSessionUrl = {} as LastSessionUrl;
              lastSessionUrl.QueryParams = urlDataObject;
              lastSessionUrl.Url = `settings/enhancedInventory/transaction/${url}`;
              lastSessionParams = lastSessionUrl;
            }
          }
        }
        const iframePopupModel = {} as IframePopupModel;
        iframePopupModel.PopupHeaderContent = this.selectedName === 'Supplier' ? 'Add New ' + this.selectedName : 'Add New Item List';
        iframePopupModel.PopUpContent = this.getAddNewTypeUrl();
        iframePopupModel.addNewListConfiguration = this.selectedTransaction === 'sales' ? this.addNewListConfiguration
          : null;
        iframePopupModel.ButtonName = this.selectedName;
        StorageService.SetSessionStorage('enableIframe', JSON.stringify('true'));
        StorageService.SetSessionStorage('EnableIframeButton', JSON.stringify(null));
        const dialogRef = this.dialog.open(AgilsysIframePopComponent, {
          // width: '90%',
          // height: '90%',
          data: iframePopupModel,
          disableClose: true,
          width: '87%',
          height: '80vh',
          maxWidth: '100vw',
          maxHeight: '100vw',
          panelClass: 'product-popup',
          // hasBackdrop: true,
          // backdropClass: 'detail-expand-backdrop-class',
          position: {
            right: '0'
          }
        });
        dialogRef.afterClosed().subscribe(x => {
          StorageService.SetSessionStorage('enableIframe', JSON.stringify(null));
          const getInventoryValue = StorageService.GetSessionStorage('EnableIframeButton');
          StorageService.SetSessionStorage('EnableIframeButton', JSON.stringify(null));
          if (x === 'confirm') {
            this.searchListData = [];
            document.getElementById('add-new-item').focus();
            this.searchControl.setValue(JSON.parse(getInventoryValue));
          }
        });
        StorageService.SetSessionStorage('LastSessionUrl', JSON.stringify(lastSessionParams));
        const transaObjectReassign = JSON.parse(JSON.stringify(this.transactionService.transactionConfigurationData));
        this.coreFacadeService.dispatchGetTranction(transaObjectReassign);
        //  this.routeNavigateService.navigateRoute( this.addNewListConfiguration.AddNewInventory.RoutingUrl, AddNewQueryParams);
      }
    } catch (error) {
      console.error(error);
    }
  }

  selected(data: any, all = null, child: boolean = false, parentData = null) {
    if (all) {
      this.allSelect = !this.allSelect;
      if (this.allSelect) {
        this.addNewListConfiguration.SelectedItemList = [];
        this.searchListData.forEach(res => {
          if (!res.IsDisable) {
            res.Checked = true;
            this.addNewListConfiguration.SelectedItemList.push(res);
            if (res.hasOwnProperty('ChildIngredients') && res.ChildIngredients && res.ChildIngredients.length > 0) {
              res.ChildIngredients.forEach(element => {
                element.Checked = true;
                this.addNewListConfiguration.SelectedItemList.push(element);
              });
            }
          }
        });
      } else {
        this.searchListData.forEach(res => {
          if (!res.IsDisable) {
            res.Checked = false;
            if (res.hasOwnProperty('ChildIngredients') && res.ChildIngredients && res.ChildIngredients.length > 0) {
              res.ChildIngredients.forEach(element => {
                element.Checked = false;
              });
            }
          }
        });
        this.addNewListConfiguration.SelectedItemList = [];
      }
    } else {
      data.Checked = !data.Checked;
      if (data.Checked && !this.addNewListConfiguration.SelectedItemList.includes(data)) {
        this.addNewListConfiguration.SelectedItemList.push(data);
        if (data.hasOwnProperty('ChildIngredients') && data.ChildIngredients && data.ChildIngredients.length > 0) {
          data.ChildIngredients.forEach(element => {
            element.Checked = true;
            if (element.Checked && !this.addNewListConfiguration.SelectedItemList.includes(element)) {
              this.addNewListConfiguration.SelectedItemList.push(element);
            }
          });
        }
      } else if (data.Checked && this.addNewListConfiguration.SelectedItemList.includes(data)) {
        if (data.hasOwnProperty('ChildIngredients') && data.ChildIngredients && data.ChildIngredients.length > 0) {
          data.ChildIngredients.forEach(element => {
            element.Checked = true;
            if (element.Checked && !this.addNewListConfiguration.SelectedItemList.includes(element)) {
              this.addNewListConfiguration.SelectedItemList.push(element);
            }
          });
        }
      } else if (!data.Checked) { // && this.addNewListConfiguration.SelectedItemList.includes(data)
        if (child) {
          const searchlistindex = this.searchListData.findIndex(x => x.Id === parentData.Id);
          // this.searchListData[searchlistindex].Checked = false;
          const childIndex = this.searchListData[searchlistindex].ChildIngredients.findIndex(y => y.Id === data.Id);
          this.searchListData[searchlistindex].ChildIngredients[childIndex].Checked = false;
        } else {
          const searchlistindex = this.selectedName === 'Inventory' ?
            this.searchListData.findIndex(x => x.IngredientId === data.IngredientId)
            : this.searchListData.findIndex(x => x.Id === data.Id);
          this.searchListData[searchlistindex].Checked = false;

          if (data.hasOwnProperty('ChildIngredients') && data.ChildIngredients && data.ChildIngredients.length > 0) {
            data.ChildIngredients.forEach(element => {
              element.Checked = false;
              if (!element.Checked && this.addNewListConfiguration.SelectedItemList.includes(element)) {
                const childindex = this.addNewListConfiguration.SelectedItemList.findIndex(x => x.Id === element.Id);
                this.addNewListConfiguration.SelectedItemList[childindex].Checked = false;
              }
            });
          }
        }
        const index = this.selectedName === 'Inventory' ?
          this.addNewListConfiguration.SelectedItemList.findIndex(x => x.IngredientId === data.IngredientId)
          : this.addNewListConfiguration.SelectedItemList.findIndex(x => x.Id === data.Id);
        // this.addNewListConfiguration.SelectedItemList[index].Checked = false;
        if (index !== -1) {
          this.addNewListConfiguration.SelectedItemList.splice(index, 1);
        }
      }
    }
    const allSelect = this.searchListData.filter(x => x.Checked === true && x.IsDisable === false &&
      (x.ChildIngredients ? x.ChildIngredients.every(y => y.Checked === true) : true)).length;
    if (allSelect === (this.searchListData.filter(x => x.IsDisable === false).length)) {
      this.allSelect = true;
    } else {
      this.allSelect = false;
    }
    const count = this.addNewListConfiguration.SelectedItemList.filter(x => x.Checked === true).length;
    if (count > 0) {
      this.actionButtonConfig[2].displayProperity = false;
      if (this.selectedNameFilter.indexOf(this.selectedName) === -1) {
        this.actionButtonConfig[0].displayProperity = false;
      }
      this.actionButtonConfig[2].displayName = 'Select(' + count + ')';
    } else {
      this.actionButtonConfig[2].displayProperity = true;
      if (this.selectedNameFilter.indexOf(this.selectedName) === -1) {
        this.actionButtonConfig[0].displayProperity = true;
      }
      this.actionButtonConfig[2].displayName = 'Select';
    }
  }

  openPanel(event): void {
    event.stopPropagation();
  }

  prevent(event: any): void {
    event.stopPropagation();
  }

  touchserchtag(): void {
    this.isTriggered = true;
    this.enableSearchListContainer = false;
  }

  handleClose() {
    // this.searchListData = this.localSearchListData;
    if (this.dialog.openDialogs.length === 0) {
      this.enableShowMore = false;
      this.enableNoDataFound = false;
      this.searchControl.reset();
      this.addNewListConfiguration.SelectedItemList = [];
      this.actionButtonConfig[2].displayName = 'Select';
      console.log('Trigered Handle close');
    }
  }

  selectedChildPanel(data: any, event: any, index: number): void {
    try {
      this.expandChildPanel = event;
      data.selectedChildPanel = event;
      this.searchListData[index].PanelOpen = event;
      if (event) {
        this.selectedChildId = data.IngredientId;
      } else {
        this.selectedChildId = null;
      }
      const totalItems = this.searchListData.reduce((count, current) =>
        count + (current.ChildIngredients && current.PanelOpen ? current.ChildIngredients.length : 0), 0);
      const totalLength = this.searchListData.length + totalItems;
      if (totalLength >= 9) {
        this.setLookupContainerHeight = true;
      } else {
        this.setLookupContainerHeight = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  ngAfterViewInit(): void {
    this.searchContainerWidth = (document.getElementsByClassName('transaction-search-container') && document.getElementsByClassName('transaction-search-container').length > 0) ?
      document.getElementsByClassName('transaction-search-container')[0]['offsetWidth'] : 721;
    const _that = this;
    // const filterFactoryArg = {} as FilterFactoryArguments;
    // const filterArg = {} as FilterArguments;
    // filterArg.collectionObject = this.transactionService.transactionConfigurationData;
    // filterFactoryArg.model = Filters.AdvancedSearch;
    // const newFactory = new FilterFactory();
    // this.filter = newFactory.createFilter(filterFactoryArg);
    // this.filter.init(filterArg);
    // const LocIds = sessionStorage.getItem('FromLocId') ? 
    // JSON.parse(sessionStorage.getItem('FromLocId')) : [];
    // this.requestPagination = new RequestPagination('', 0, this.showMoreConfig.FetchAll, LocIds);
    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(x => {
      const searchKey = x ? x.toString().toLowerCase() : '';
      this.searchKey = searchKey;
      this.allSelect = false;
      this.isTriggered = false;
      this.showMoreConfig.Take = this.ScrollItemCount;
      this.showMoreConfig.Skip = 0;
      if (this.searchKey) {
        // this.searchListData = [];        
        // this.enableSearchListContainer = false;
        this.addNewListConfiguration.SelectedItemList = [];
        this.addNewListConfiguration.SelectedQueryIds = [];
        this.addNewListConfiguration.SelectedQueryList = [];
        this.actionButtonConfig[2].displayName = 'Select';
        // this.setLookupContainerHeight = (this.searchListData.length) >= 9 ? true : false;
      } else {
        this.enableNoDataFound = true;
        this.enableSearchListContainer = false;
      }
      // const typeData = this.transactionSearchType.filter(
      //   (addNewTypeConfig: AddNewTypeConfig) => addNewTypeConfig.Id === _that.defaultSelect.value)[0];
      const groupTypeData = this.transactionSearchType.filter(groupOpt => groupOpt.DefaultSelect)[0];
      const typeData = groupTypeData.OptionGroup.filter(y => y.Id === _that.defaultSelect.value)[0];
      // this.getLocalSearchData(searchKey, typeData, _that.transactionOptionHeader);
      _that.getFilterData(_that.httpService, searchKey, typeData, _that.transactionOptionHeader, _that.showMoreConfig);
      // if (this.isBarcodeScanned) {
      //    _that.getFilterData(_that.httpService, searchKey, typeData, _that.transactionOptionHeader, _that.showMoreConfig);
      // } else {
      //   this.getLocalSearchData(searchKey, typeData, _that.transactionOptionHeader);
      // }
    });
    // const NTypeInventory = this.masterManagementService.masterDecodeUri(this.route.snapshot.queryParams, 'NTypeInventory', null);
    // if ( NTypeInventory && NTypeInventory !== null ) {
    //   this.allSelect = false;
    //   this.searchListData = [];
    //   this.searchControl.setValue(NTypeInventory);
    // }
  }

  // searchFocus(): void {
  //   if (this.searchControl.value) {
  //     this.searchControl.setValue('');
  //   }
  //   // this.actionButtonConfig[2].displayName = 'Select';
  //   // this.actionButtonConfig[2].displayProperity = true;
  //   // this.searchListData = [];
  //   // this.addNewListConfiguration.SelectedItemList = [];
  //   // this.enableSearchListContainer = false;
  //   // this.expandChildPanel = false;
  //   // this.selectedChildId = null;
  //   // this.searchListData = [];
  //   // this.enableNoDataFound = false;
  // }

  ngOnChanges(): void {
    const _that = this;
    this.transactionSearchType = this.addNewListConfiguration.AddNewType;
    // this.searchPlaceHolder = (this.selectedTransaction === 'itempars' && this.addNewListConfiguration && 
    // this.addNewListConfiguration.SearchPlaceHolder) ? this.addNewListConfiguration.SearchPlaceHolder : 'Search';
    // this.searchPlaceHolder = this.addNewListConfiguration.SearchPlaceHolder;
    // const seletedType = this.transactionSearchType.filter((addNewTypeConfig: AddNewTypeConfig) => addNewTypeConfig.DefaultSelect);
    const SelectedGroup = this.transactionSearchType.filter(groupOption => groupOption.DefaultSelect);
    const seletedType = SelectedGroup.length > 0 ? SelectedGroup[0].OptionGroup.filter(x => x.DefaultSelect) :
      this.transactionSearchGroupOption ? this.transactionSearchGroupOption[0].OptionGroup : [];
    this.searchPlaceHolder = this.getPlaceHolderText(SelectedGroup[0].Name, seletedType[0].DisplayName);
    this.selectedGroupName = SelectedGroup.length > 0 ? SelectedGroup[0].DisplayName : '';
    if (seletedType.length > 0) {
      this.selectedName = seletedType[0].Name;
      this.selectedDisplayName = seletedType[0].DisplayName;
      this.selectedIcon = seletedType[0].IconName;
      this.defaultSelect.setValue(seletedType[0].Id);
      this.defaultSelect.updateValueAndValidity();
    }
    this.transactionOptionHeader = this.addNewListConfiguration.AddNewItemSearchList.SearchListHeader
      .filter((SearchHeaderConfig: SearchListHeaderConfig) => seletedType.length && SearchHeaderConfig.TypeId === seletedType[0].TypeId);

    this.defaultSelect.valueChanges.subscribe(x => {
      let optionGroup: any;
      this.isTriggered = true;
      this.localSearchListData = [];
      this.searchListData = [];
      this.enableSearchOption = false;
      this.enableSearchListContainer = false;
      this.transactionSearchType.forEach((addNewTypeConfig: AddNewTypeConfig) => {
        addNewTypeConfig.DefaultSelect = false;
        optionGroup = addNewTypeConfig.OptionGroup ? addNewTypeConfig.OptionGroup.filter(y => y.Id === x) : [];
        if (optionGroup.length > 0) {
          addNewTypeConfig.DefaultSelect = true;
        }
      });

      const groupdata = this.transactionSearchType.filter(group => group.DefaultSelect)[0];

      // if (this.MasterCollection && this.MasterCollection.Name === 'Recipe') {
      //   groupdata = x === 2 ? this.transactionSearchType[1] : this.transactionSearchType[0];
      // }

      const data = groupdata.OptionGroup.filter(sub => sub.Id === x)[0];
      this.selectedName = data.Name;
      this.selectedDisplayName = data.DisplayName;
      this.selectedIcon = data.IconName;
      this.selectedGroupName = groupdata.DisplayName;
      this.transactionOptionHeader = this.addNewListConfiguration.AddNewItemSearchList.SearchListHeader
        .filter((SearchHeaderConfig: SearchListHeaderConfig) => SearchHeaderConfig.TypeId === data.TypeId);
      this.actionButtonConfig[0].displayProperity = true;
      this.actionButtonConfig[2].displayProperity = true;
      this.searchControl.setValue('');
      const searchTextBox = document.getElementById('add-new-item');
      const enableBlur = this.selectedTransaction === 'itempars' && this.transactionService.transactionFormGroup.status === 'INVALID';
      if (searchTextBox && !enableBlur) {
        searchTextBox.focus();
      } else if (searchTextBox && enableBlur) {
        searchTextBox.blur();
      }
      this.searchListData = [];
      this.showMoreConfig.Take = this.ScrollItemCount;
      this.showMoreConfig.Skip = 0;
      this.addNewListConfiguration.SelectedItemList = [];
      this.addNewListConfiguration.SelectedQueryIds = [];
      this.addNewListConfiguration.SelectedQueryList = [];
      this.transactionSearchType.forEach((addNewTypeConfig: AddNewTypeConfig) => {
        // if (addNewTypeConfig.Id === x) {
        //   addNewTypeConfig.DefaultSelect = true;
        // } else {
        //   addNewTypeConfig.DefaultSelect = false;
        // }
        const SelectedData = addNewTypeConfig.OptionGroup ? addNewTypeConfig.OptionGroup.filter(y => y.Id === x) : [];
        if (SelectedData.length > 0) {
          addNewTypeConfig.DefaultSelect = true;
          addNewTypeConfig.OptionGroup.forEach(optGrp => {
            if (optGrp.Id === x) {
              optGrp.DefaultSelect = true;
            } else {
              optGrp.DefaultSelect = false;
            }
          });
        } else {
          addNewTypeConfig.DefaultSelect = false;
          if (addNewTypeConfig.OptionGroup) {
            addNewTypeConfig.OptionGroup.forEach(optGrp => {
              optGrp.DefaultSelect = false;
            });
          }
        }
      });
      if (this.transactionJson) {
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList.
          AddNewListConfig.AddNewType = this.transactionSearchType;
      }
      if (this.MasterCollection) {
        this.addNewListConfiguration.AddNewType = this.transactionSearchType;
      }
      if (this.transactionService.transactionConfigurationData) {
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList.
          AddNewListConfig.SelectedQueryIds = [];
        this.transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList.
          AddNewListConfig.SelectedQueryList = [];
      }
      const SelectedGroup = this.transactionSearchType.filter(groupOption => groupOption.DefaultSelect);
      const seletedType = SelectedGroup.length > 0 ? SelectedGroup[0].OptionGroup.filter(x => x.DefaultSelect) :
        this.transactionSearchGroupOption ? this.transactionSearchGroupOption[0].OptionGroup : [];
      this.searchPlaceHolder = this.getPlaceHolderText(SelectedGroup[0].Name, seletedType[0].DisplayName);
    });
    this.actionButtonConfig = this.addNewListConfiguration.ButtonConfiguration;
    this.actionButtonConfig.forEach((agilysysButtonModel: AgilysysButtonModel) => {
      agilysysButtonModel.buttonCallBack = function (value) {
        _that.callBack(value);
      };
    });
  }

  AssignBidsOptions() {
    if (this.transactionJson.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData) {
      const ExpiredBids = this.transactionJson.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ExpiredBids'] ? '1' : '0';
      const Priority = this.transactionJson.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['Priority'] ? '1' : '0';
      const SkipZero = this.transactionJson.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['SkipZero'] ? '1' : '0';
      const ClearPrices = this.transactionJson.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ClearPrices'] ? '1' : '0';
      const ClearSupplier = this.transactionJson.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ClearSupplier'] ? '1' : '0';
      const BidRegion = this.transactionJson.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['BidRegion'] ? '1' : '0';
      const options = ExpiredBids + Priority + SkipZero + ClearPrices + ClearSupplier + BidRegion;
      return options;
    } else {
      return '011000';
    }
  }

  getPlaceHolderText(groupName: string, typeName: string): string {
    try {
      let placeHolderText = 'Search';
      switch (groupName) {
        case 'Inventory':
          if (typeName === 'Items') {
            placeHolderText = 'Search by Name, Number, Barcode, Description';
          } else {
            placeHolderText = 'Search by Name';
          }
          break;
        case 'Recipes':
          if (typeName === 'Items') {
            placeHolderText = 'Search by Name, Number, Description';
          } else {
            placeHolderText = 'Search by Name';
          }
          break;
        case 'Recipe':
        case 'Product':
          if (typeName === 'Items') {
            placeHolderText = 'Search by Name, Number, Description';
          } else {
            placeHolderText = 'Search by Name';
          }
          break;
        default:
          placeHolderText = 'Search by Name';
          break;
      }
      return placeHolderText;
    } catch (error) {
      console.log(error);
    }
  }

  getAddNewTypeUrl(): string {
    try {
      if (this.addNewListConfiguration && this.addNewListConfiguration.AddNewType.length > 0) {
        // if (this.selectedTransaction === 'sales') {
        //   if (this.addNewListConfiguration.AddNewType[0].Name === 'Product') {
        //     this.addNewListConfiguration.AddNewInventory.EnableAddNewInventory = false;
        //   }
        //  }
        const pathUrl = `${window.location.pathname}`;
        const locationUrl = pathUrl ? `${window.location.origin}${pathUrl}#/` : `${window.location.origin}/#/`;
        const tenatName = `${StorageService.GetSessionStorage('Tenant')}/`;
        let url = 'sss';
        // Previous it was not as option group now its option group
        // const getSelectedType = this.addNewListConfiguration.AddNewType.filter((x: AddNewTypeConfig) => x.DefaultSelect);
        const getGroupSelectedType = this.addNewListConfiguration.AddNewType.filter((x: AddNewTypeConfig) => x.DefaultSelect);
        const getSelectedType = getGroupSelectedType[0].OptionGroup.filter(x => x.DefaultSelect === true);
        if (getSelectedType && getSelectedType.length > 0) {
          const params = {
            master: getSelectedType[0].Url,
            inventorytype: this.addNewListConfiguration.AddNewInventory.InventoryType,
            systemPreference: this.addNewListConfiguration.AddNewInventory.SystemPreference,
            iframe: true
          };
          const createTypeUrl = (getSelectedType[0].Url === 'Packages') ? getSelectedType[0].Url.toString().toLowerCase() :
            getSelectedType[0].Url;
          const valueUri = encodeURIComponent(JSON.stringify(params));
          url = locationUrl + tenatName + `inventory-management/create-${createTypeUrl}?data=` + `${valueUri}`;
          return url;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  jsEncode(param: string) {
    return encodeURIComponent(param);
  }

  pasted(event: any) {
    this.pastedData = event.clipboardData.getData('text');
    this.isBarcodeScanned = true;
  }

  getMoreData() {
    const groupTypeData = this.transactionSearchType.filter(groupOpt => groupOpt.DefaultSelect)[0];
    const typeData = groupTypeData.OptionGroup.filter(y => y.Id === this.defaultSelect.value)[0];
    this.showMoreConfig.FetchAll = false;
    // this.showMoreConfig.Take = this.showMoreConfig.Take + this.ScrollItemCount;
    // this.showMoreConfig.Skip = 0;
    this.showMoreConfig.Take = this.ScrollItemCount;
    this.showMoreConfig.Skip += this.ScrollItemCount;
    this.getFilterData(this.httpService, this.searchKey, typeData, this.transactionOptionHeader, this.showMoreConfig);
    this.allSelect = false;
    // const searchBoxList = document.getElementById('searchItemOptions');
    // if (searchBoxList) {
    //   searchBoxList.scrollTop = 0;
    // }
  }

  checkButcheryRecipe(): boolean {
    try {
      let allowedItemAdded = true;
      if (this.MasterCollection && this.MasterCollection.AddItemList) {
        const GridLength: number = this.MasterCollection.AddItemList.GridConfiguration.GridData.length;
        const selectedItemList: Array<any> = this.addNewListConfiguration.SelectedItemList.filter(x => x.Checked === true);
        if (this.masterManagementService.RowData.IsButcheryRecipe) {
          const butcheryInfo = {} as ErrorPopModel;
          butcheryInfo.PopupHeaderContent = this.translateService.instant('Common.Info');
          butcheryInfo.PopUpContent = 'Butchery Recipe allowed for only one items';
          if (GridLength > 0) {
            allowedItemAdded = false;
            this.showInfoPopup(butcheryInfo);
            return;
          }
          if (selectedItemList && selectedItemList.length > 1) {
            allowedItemAdded = false;
            this.showInfoPopup(butcheryInfo);
            return;
          }
        }
      }
      return allowedItemAdded;
    } catch (error) {
      console.log('Check Butchery Recipe Error: ', error);
    }
  }
  /**
   * @method showInfoPopup
   * @input ErrorModel
   * @description showpopupBased on Logics
   */
  showInfoPopup(InfoPopupModel: ErrorPopModel): void {
    try {
      const dialog = this.dialog.open(AgilsysErrorPopComponent, {
        width: '400px',
        height: 'auto',
        data: InfoPopupModel,
        disableClose: true
      });
    } catch (error) {
      console.error(error);
    }
  }

  IsRguestEnabled(): boolean {    
    let rguestTenantId;
    const checkTenant = sessionStorage.getItem('RGuestTenantId');
    const isEatecUser = sessionStorage.getItem('isEatecUser');
    if (checkTenant && JSON.parse(checkTenant)) {
      rguestTenantId = true;
    }
    return (rguestTenantId || (isEatecUser && !JSON.parse(isEatecUser)));
  }
  ngOnInit(): void {
    console.log('Transaction Module loaded');
    this.actionButtonConfig[2].displayProperity = true;
    this.actionButtonConfig[0].displayProperity = true;
  }
}
