import { EodataConfiguration, OdataAction } from 'src/app/eatecui/source/store/actions/odata-configuration.action';
import { intialOdataState } from 'src/app/eatecui/source/store/states/odata-configuration.state';

export function OdataReducer(state = intialOdataState, action: OdataAction) {
    switch (action.type) {
        case EodataConfiguration.GetOdata: {
            return{
                ...state,
                GetOdata : action.payload
            };
        }
        case EodataConfiguration.GetOdataSucess: {
            return{
                ...state,
                GetOdataSuccess : action.payload
            };
        }
        case EodataConfiguration.GetInventoryOdata: {
            return{
                ...state,
                GetInventoryOdata : action.payload
            };
        }
        case EodataConfiguration.GetInventoryOdataSucess: {
            return{
                ...state,
                GetInventoryOdataSucess : action.payload
            };
        }
        default:
       return state;
    }
}