import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { COMPRESSION_LIMIT, ALLOWED_IMAGE_SIZE, AlertType, ButtonType } from '../globalsContant';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {

  @Input() readOnly: boolean;
  @Input() emptyImgCaption: string;
  @Input('imgData')
  set ImageData(value) {
    if (value) {
      this.url = value;
      this.ImageUploaded = true;
    } else {
      this.ImageUploaded = false;
      this.url = '';
    }
  }
  @Output() fileDeleted = new EventEmitter();
  @Output() fileUploaded = new EventEmitter();
  @Output() fileSizeExceeded = new EventEmitter();
  url: any;
  ImageUploaded: boolean;
  captions: any;
  allCaptions: any;
  isViewOnly:boolean = false; // was not declared
  oldURL: any;
  constructor(private imageCompress: NgxImageCompressService, public _ls:SpaLocalization,private utils: CommonUtilities) { }

  ngOnInit() {

    this.captions = this._ls.captions.common;
    this.allCaptions = this._ls.captions;
  }

  compressFile() {
    let fileSize = 0;
    this.oldURL = cloneDeep(this.url);
    this.ImageUploadLoader(true, this.allCaptions.lbl_processing);
    this.imageCompress.uploadFileOrReject().then(({ image, orientation }) => {
      this.url = image;
      // convert to MB
      const supportedFileFormat = ['jpg','png','jpeg','svg']
      const uploadFileFormat = image?.split(";")[0]?.split(":")[1]?.split("/")[1]
      if(supportedFileFormat.some(v => uploadFileFormat?.includes(v))){
        fileSize = this.imageCompress.byteCount(image) / (1024);
        console.log('Size in kilo bytes was:', fileSize);
        if (fileSize > COMPRESSION_LIMIT) {
          this.imageCompress.compressFile(image, orientation).then(
            result => {
              const compressedfileSize = this.imageCompress.byteCount(result) / (1024 * 1024);
              console.log('Size in Mega bytes was:', compressedfileSize);
              if (compressedfileSize <= ALLOWED_IMAGE_SIZE) {
                this.url = result;
                this.ImageUploaded = true;
                this.compressThumbnail(result);
              } else {
                this.ImageUploadLoader(false);
                this.fileSizeExceeded.emit();
              }
            }
          ).finally(()=>{
            this.ImageUploadLoader(false);
          });
        } else {
          this.ImageUploaded = true;
          this.compressThumbnail(image);
        }
      } else {
        this.url = cloneDeep(this.oldURL);
        this.utils.showAlert(this.captions.lbl_Image_validation, AlertType.Error, ButtonType.Ok);
      }
      
    }).finally(()=>{
      if(fileSize==0)
      this.ImageUploadLoader(false);
    });
  }

  compressThumbnail(image){
    let imgData = {orgImg: image};
    this.imageCompress.compressFile(image, 1, 30, 30).then(result=>{
      const compressedfileSize = this.imageCompress.byteCount(result) / (1024);
      console.log('Size in Kilo bytes was:', compressedfileSize);
      imgData['tmbImg'] = result;
      this.ImageUploadLoader(false);
      this.fileUploaded.emit(imgData);
    })
  }

  onFileDelete() {
    this.ImageUploaded = false;
    this.fileDeleted.emit();
  }
  ImageUploadLoader(loader, loaderMessage?) {
    const loadingContainer = document.getElementById('imageUpload-cover-spin');
    const loadingContainerMessage = document.getElementById('imageUpload-cover-message');
    if (loadingContainer) {
        if (loader) {
            loadingContainer.style.display = 'block';
        } else {
            loadingContainer.style.display = 'none';
        }
        if (loaderMessage) {
            loadingContainerMessage.innerText = loaderMessage;
        } else {
            loadingContainerMessage.innerText = '';
        }
    }
}
}
