import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { MasterInventoryManagement, NestedBuilderData, NestedDataBuilder } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FieldBuilderType } from './form-builder-type.enum';
import { FormModelConfiguration, FormModelCustomBuilder, FormNestedModelCustomBuilder } from './form-config.interface';

export const WizardNestedFormCustomBuilding: FormNestedModelCustomBuilder = (
ViewModelEntity: any, 
PostModelEntity: any, 
MasterDataCollection: MasterInventoryManagement, 
httpService: HttpService, 
NestedDataBuild: NestedDataBuilder, translateService: TranslateService, dialog: MatDialog): any => {
    try {
        const NestedFormBuilder = [];
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 ) {
            if (NestedDataBuild && NestedDataBuild.NestedType === 'accordian') {
                const Filter = 'IsActive eq true';
                const GetDataFilter = encodeURIComponent(`${Filter}`).replace(/[!'()*]/g, function (c) {
                    return '%' + c.charCodeAt(0).toString(16);
                });
                const wizardUrl = '/inventory/api/DescriptorGroups';
                const endPointUrl = wizardUrl + '?' + '$filter=' + GetDataFilter;
                httpService.GethttpMethod(endPointUrl).subscribe(x => {
                  console.log(x);
                });
                NestedDataBuild.NestedData.forEach((FormBuilderGroup: NestedBuilderData) => {
                    const accordianFormType = {} as FormType;
                    accordianFormType.hide = (FormBuilderGroup.hasOwnProperty('EdmType') && 
                    FormBuilderGroup.EdmType === 'Edm.hidden') ? true : false;
                    accordianFormType.type = FormInterface.FormGroupFieldTypes(
                    ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService);
                    accordianFormType.fieldType = {
                       'SingleData' : FormInterface.FormCustomFieldType(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FormBuilderGroup, translateService, dialog)
                    };
                    NestedFormBuilder.push(accordianFormType);
                });
            } 
        }
        return NestedFormBuilder;
        
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function generateWizardFormType( ViewModelEntity: any, PostModelEntity: any, MasterDataCollection: any, httpService: any, 
FormBuilderGroup: any): FormType {
    try {
    const wizardFromType = {} as FormType;
    return wizardFromType;
    } catch (error) {
        console.error(error);
    }
}