import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubGroupListing } from '../interface/setup-links.interface';
import * as constants from '../../../shared/constant/GlobalConstants';
import { CoreFacadeService } from 'src/app/eatecui/source/core/services/core-facade.service';
import { GridFilterConfigurtion } from 'src/app/eatecui/source/shared/models/grid-data.interface';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { FormConfig, GlobalConfiguration } from 'src/app/eatecui/source/shared/models/grid-data.interface';
import { AppGridJsonConfig } from 'src/app/eatecui/source/shared/models/overall-grid-config.interface';
import { GridAppConfig } from 'src/app/eatecui/source/shared/models/app-grid.interface';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { FormType, PostModelObject } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})

export class SetUpService {

    menuObject: SubGroupListing;
    basicConfiguration: any;
    globalConfigurtion: any;
    masterDataCollection: any[];
    odataCollectionArray: Array<any> = [];
    odataPostModelAEntity: Array<any> = [];
    Tenant: string;
    OnPrem: boolean;
    gridFilterConfiguration: GridFilterConfigurtion[];
    AppGridConfig: GridAppConfig[];
    AppGlobalGridData: GlobalConfiguration;
    AppGlobalGridInput: AppGridJsonConfig;
    AppGlobalFormData: PostModelObject[];
    UserFormConfig: FormConfig;
    setupFormGroup: FormGroup;
    PostDeactivate: any;
    RoleFormGroup: any;
    initialRolesList: any;
    RolesList: any;
    constructor(
        private http: HttpClient,
        private coreFacadeService: CoreFacadeService,
        private envService: EnvService,
        private routeNavigateService: RouteNavigateService
    ) {
        coreFacadeService.GetTenant().subscribe(x => {
            this.Tenant = x;
        });
        coreFacadeService.GetOnPrem().subscribe(x => {
            this.OnPrem = x;
        });
    }

    getBasicConfiguration() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        new Promise(async (resolve) => {
            const _that = this;
            const data = await this.http.get(`assets/json/lib/setup/config.json`).toPromise();
            if (data) {
                this.basicConfiguration = {} as any;
                this.basicConfiguration = data as any;
                this.basicConfiguration.BaseUrl = this.envService.apiUrl;
                this.basicConfiguration.Tenant = this.Tenant;
                this.basicConfiguration.OnPrem = this.OnPrem;
                localStorage.setItem(constants.EndPointUrl, this.basicConfiguration.BaseUrl);
                this.basicConfiguration.OncellClick = function (e: any) {
                    if (e) {
                        if (e.ActionKey === 'routing') {
                            _that.routeNavigateService.libnavigateRoute(e);
                        }
                    }
                };
            }
            resolve(data);
        });
    }

    getGlobalConfiguration() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        new Promise(async (resolve) => {
            const data = await this.http.get(`assets/json/lib/setup/global-grid-config.json`).toPromise();
                if (data) {
                    this.globalConfigurtion = data as any;
                }
            resolve(data);
        });
    }

    getFilterConfig() {
             // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        new Promise(async (resolve) => {
            const data = await this.http.get(`assets/json/lib/setup/grid-action-config.json`).toPromise();
                if (data) {
                    this.gridFilterConfiguration = data as GridFilterConfigurtion[];
                }
            resolve(data);
        });
    }

    async getAppEntitesGridConfig() {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            new Promise(async () => {
                this.AppGridConfig = await this.http.get(`assets/json/setup/app-grid-config.json`).toPromise() as GridAppConfig[];
            });

    }

    async getAppGridConfigJson() {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            new Promise(() => {
                this.http.get(`app/eatecui/assets/json/global-grid-data.json`).subscribe((data: GlobalConfiguration) => {
                    if (data) {
                        this.AppGlobalGridData = data;
                    }
                });
            });
    }

    async getAppGridInputJson() {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            new Promise(() => {
                this.http.get(`assets/json/global-grid-input.json`).subscribe((data: AppGridJsonConfig) => {
                    if (data) {
                        this.AppGlobalGridInput = data;
                    }
                });
            });
    }

    getPostModelEntityJson() {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            new Promise(() => {
                this.http.get(`assets/json/setup/app-form-config.json`).subscribe((data: PostModelObject[]) => {
                    if (data) {
                        this.AppGlobalFormData = data;
                    }
                });
            });

    }
}
