import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lib-number-editor',
  templateUrl: './number-editor.component.html',
  styleUrls: ['./number-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NumberEditorComponent implements OnInit {

  params: any;
  input: any;
  dotPressed = false;
  DecimalFormatter: Intl.NumberFormat;
  CurrencyFormatter: Intl.NumberFormat;
  div: HTMLDivElement;
  constructor() { }
  value: any;

  isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
  }

  isKeyPressedNumeric(event) {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

  getCharCodeFromEvent(event) {
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  }
  keyEnterMethod(key: any) {
    const rowIndex = key.rowIndex + 1;
    const firstCol = key.columnApi.getAllDisplayedColumns()[0];
    key.api.ensureColumnVisible(firstCol);
    let NlcBool = true;
    key.api.forEachNode(function (rowNode: any, index: any) {
      if (index >= rowIndex) {
        if (rowNode.data.quantity !== 'N_C' && NlcBool) {
          key.api.setFocusedCell(index, firstCol);
          NlcBool = false;
        }
      }
    });
  }

  init(params) {
    const _thatmethod = this;
    this.params = params;
    this.value = this.params.value ? this.params.value : '';
    this.div = document.createElement('div');
    this.div.setAttribute('class', 'input-div');
    this.input = document.createElement('input');
    this.input.type = 'text';
    this.input.setAttribute('class', 'input-number');
    this.input.setAttribute('id', 'input-number-touch');
    if (params.colDef.hasOwnProperty('placeholder') && params.colDef.placeholder) {
      this.input.setAttribute('placeholder', 'in C' + String.fromCharCode(176));
    }
    this.input.value = this.value;
    this.params.charPress = params.charPress ? params.charPress : '';
    this.input.value = this.isCharNumeric(this.params.charPress) ? this.params.charPress : this.value;
    this.div.appendChild(this.input);
    this.input.addEventListener('input', this.quantityCalc.bind(this));
    this.input.addEventListener('focusout', this.blurEvent.bind(this));
    const alloWDecimalDigit = 16;
    const alloWDigit = 20;
    this.input.addEventListener('paste', function (event) {
      const a = (event.originalEvent || event).clipboardData.getData('text/plain');
      if (isNaN((a.split(',')).join(''))) {
        event.preventDefault();
      } else {
        if (a.length > 20 || (a.split('.')[1] &&
          a.split('.')[1].length >= alloWDecimalDigit)) {
          event.preventDefault();
        } else {
          event.target.value = '';
          return true;
        }
      }
    });

    this.input.addEventListener('keydown', function (event) {
      if ((event.key.toString().toLowerCase() === 'v' || event.key.toString().toLowerCase() === 'c'
        || event.key.toString().toLowerCase() === 'a') && event.ctrlKey === true) {
        return true;
      }
      if (event.key === 'Enter') {
        _thatmethod.keyEnterMethod(params);
      }
      // Added for Mac Support
      if (event.metaKey === true) {
        return true;
      }
      const _that = this;
      const hasDot = event.target.value.indexOf('.');
      const upStartIndex = event.target.selectionStart;
      const key = event.key;
      // Not to allow \ and >
      if (event.key.trim() === '' || event.key.trim() === '>' || event.keyCode === 220) {
        event.preventDefault();
      }
      // Not to allow double dot
      if (hasDot !== -1 && (event.keyCode === 190 || event.keyCode === 110)) {
        event.preventDefault();
      }
      /****To Prevent Special Characters & allow .,-,backspace****/
      // if (isNaN(event.key) && (event.keyCode !== 190 && event.keyCode !== 8 && event.key !== '-') && event.keyCode !== 110) {
      //     event.preventDefault();
      // }
      if (hasDot !== -1 || event.keyCode === 190 || event.keyCode === 110) {
        const splitValues = event.target.value.split('.');
        if (splitValues[1] && splitValues[1].length > 0) {
          if (splitValues[1] && splitValues[1].length >= alloWDecimalDigit) {
            if (
              (key !== ')' &&  // 48 -ipad
                key !== '!' &&  // 49-ipad
                key !== '@' &&   // 50-ipad
                key !== '#' &&   // 51-ipad
                key !== '$' &&   // 52-ipad
                key !== '%' &&   // 53-ipad
                key !== '^' &&   // 54-ipad
                key !== '&' &&   // 55-ipad
                key !== '*' &&   // 56-ipad
                key !== '(' &&
                key !== '_' &&
                key !== '=' &&
                key !== '>' &&
                key !== '<' &&
                key !== '?' &&
                key !== '+' &&
                key !== ':' &&
                key !== ';' &&
                key !== '"' &&
                key !== ',' &&
                key !== '\'' &&
                key !== '{' &&
                key !== '}' &&
                key !== ']' &&
                key !== '[' &&
                key !== '|' &&
                key !== '`' &&
                key !== '~') &&
              (event.keyCode === 8 ||
                event.keyCode === 9 ||
                event.keyCode === 10 ||
                event.keyCode === 13 ||
                event.keyCode === 16 ||
                event.keyCode === 17 ||
                event.keyCode === 18 ||
                event.keyCode === 20 ||
                event.keyCode === 27 ||
                event.keyCode === 33 ||
                event.keyCode === 34 ||
                event.keyCode === 35 ||
                event.keyCode === 36 ||
                (event.keyCode >= 37 && event.keyCode <= 40) ||
                event.keyCode === 45 ||
                event.keyCode === 46 ||
                (event.keyCode === 112 && event.keyCode === 123) ||
                event.keyCode === 127 ||
                event.keyCode === 144 ||
                (upStartIndex === 0 && event.keyCode === 189) ||
                (upStartIndex === 0 && event.keyCode === 109)
              )
            ) {
              // Allow backspace,tab,arrow keys
              // console.log('inside 1');
              return true;
            } else {
              // console.log('inside 2');
              event.preventDefault();
            }
          } else {
            if (
              (event.keyCode >= 65 && event.keyCode <= 90) ||
              (
                (key === ')' ||  // 48 -ipad
                  key === '!' ||  // 49-ipad
                  key === '@' ||   // 50-ipad
                  key === '#' ||   // 51-ipad
                  key === '$' ||   // 52-ipad
                  key === '%' ||   // 53-ipad
                  key === '^' ||   // 54-ipad
                  key === '&' ||   // 55-ipad
                  key === '*' ||   // 56-ipad
                  key === '(' ||
                  key === '_' ||
                  key === '=' ||
                  key === '>' ||
                  key === '<' ||
                  key === '?' ||
                  key === '+' ||
                  key === ':' ||
                  key === ';' ||
                  key === '"' ||
                  key === ',' ||
                  key === '\'' ||
                  key === '/' ||
                  key === '{' ||
                  key === '}' ||
                  key === ']' ||
                  key === '[' ||
                  key === '|' ||
                  key === '`' ||
                  key === '~'
                )
              ) ||
              (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109)
              )) {
              // Prevent alphabets values
              // console.log('inside 3');
              event.preventDefault();
            } else {
              // console.log('inside 4');
              if (event.target.value.split('.')[0].length >= alloWDigit) {
                if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
                  (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
                    (event.keyCode === 189 || event.keyCode === 109))) {
                  // Prevent alphabets values and numeric values if length greater than allowed length
                  // console.log('inside 5');
                  event.preventDefault();
                } else {
                  // console.log('inside 6');
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        }
      } else {
        if (event.target.value.length >= alloWDigit) {
          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) ||
            (event.keyCode >= 65 && event.keyCode <= 90) || (upStartIndex > 0 &&
              (event.keyCode === 189 || event.keyCode === 109))) {
            // Prevent alphabets values and numeric values if length greater than allowed length
            // console.log('inside 5');
            event.preventDefault();
          } else {
            // console.log('inside 6');
            return true;
          }
        } else {
          if ((event.keyCode >= 65 && event.keyCode <= 90) ||
            (
              (key === ')' ||  // 48 -ipad
                key === '!' ||  // 49-ipad
                key === '@' ||   // 50-ipad
                key === '#' ||   // 51-ipad
                key === '$' ||   // 52-ipad
                key === '%' ||   // 53-ipad
                key === '^' ||   // 54-ipad
                key === '&' ||   // 55-ipad
                key === '*' ||   // 56-ipad
                key === '(' ||
                key === '_' ||
                key === '=' ||
                key === '>' ||
                key === '<' ||
                key === '?' ||
                key === '+' ||
                key === ':' ||
                key === ';' ||
                key === '"' ||
                key === ',' ||
                key === '\'' ||
                key === '/' ||
                key === '{' ||
                key === '}' ||
                key === ']' ||
                key === '[' ||
                key === '|' ||
                key === '`' ||
                key === '~')
            ) || (upStartIndex > 0 && (event.keyCode === 189 || event.keyCode === 109))) {
            // Prevent alphabets values and - minus values
            // console.log('inside 7');
            event.preventDefault();
          } else {
            // console.log('inside 8');
            return true;
          }
        }
      }
    });


  }
  quantityCalc(event, from = '') {
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    rowNode.setDataValue('Qty', event.target.value);
  }

  // agInit(params) {
  //   this.init(params);
  // }

  afterGuiAttached() {
    try {
      if (this.input) {
        if (this.input.value) {
          this.input.value = this.input.value.split(',').join('');
        }
        if (this.input.value !== '' && this.params.colDef.field === 'quantity') {
          this.quantityCalc(this.input.value, 'fromGui');
        }
        if (this.input.value !== '' && this.params.colDef.field === 'Quantity') {
          this.quantityCalc(this.input.value, 'fromGui');
        }
        const columns = this.params.columnApi.columnController.columnDefs;
        if ((columns[columns.length - 1].from !== 'frompo' && columns[columns.length - 1].from !== 'fromreceiving' &&
          columns[columns.length - 1].from !== 'frompurchaserequest' &&
          columns[columns.length - 1].from !== 'frombpo')
          || ((columns[columns.length - 1].from === 'frompo' || columns[columns.length - 1].from === 'fromreceiving' ||
            columns[columns.length - 1].from === 'frompurchaserequest' ||
            columns[columns.length - 1].from === 'frombpo')
            && this.params.colDef.field === 'quantity')) {
          this.input.focus();
          // if (this.input.value === '') {
          this.input.select();
          // }
        }
      }
    } catch (err) {
      console.log('Error', err);
    }

  }

  isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
  };

  isCancelAfterEnd = function () {

  };

  getValue() {
    try {
      // this.formatter();
      const columns = this.params.columnApi.columnController.columnDefs;
      if (columns[columns.length - 1].from === 'frompo' || columns[columns.length - 1].from === 'fromreceiving'
        || columns[columns.length - 1].from === 'frompurchaserequest' || columns[columns.length - 1].from === 'frombpo') {
        if (this.params.colDef.field === 'calCost' || this.params.colDef.field === 'calAmount') {
          this.input.value = this.input.value === '-' || this.input.value === '.'
            || this.input.value === '-.' || this.input.value === '.-' ? '' : (this.input.value.split(',')).join('');
          const data = this.input.value !== '' ? this.CurrencyFormatter.format(this.input.value).toString() : '';
          return data;
        } else {
          this.input.value = this.input.value === '-' || this.input.value === '.'
            || this.input.value === '-.' || this.input.value === '.-' ? '' : (this.input.value.split(',')).join('');
          const data = this.input.value !== '' ? this.DecimalFormatter.format(this.input.value).toString() : '';
          this.params.value = data;
          this.input.value = data;
          if (this.params.colDef.field === 'quantity') {
            this.params.data.quantity = data;
          }
          return data;
        }
      } else {
        this.input.value = this.input.value === '-' || this.input.value === '.'
          || this.input.value === '-.' || this.input.value === '.-' ? '' : (this.input.value.split(',')).join('');
        const data = this.input.value !== '' ? this.DecimalFormatter.format(this.input.value).toString() : '';
        return data;
      }
    } catch (err) {
      console.log('error');
    }
  }

  focusIn() {
    this.input.focus();
    this.input.select();
    // console.log('NumericCellEditor.focusIn()');
  }

  blurEvent() {
    const columns = this.params.columnApi.columnController.columnDefs;
    if (columns[columns.length - 1].from !== undefined && (
      columns[columns.length - 1].from.toString() === 'frompo' || columns[columns.length - 1].from.toString() === 'fromreceiving')) {
      if ((this.params.context.parentComponent.rowData.length - 1) === this.params.rowIndex &&
        this.params.colDef.field.toString() === 'calAmount') {
        this.params.stopEditing();
      }
    }
  }

  focusOut() {
    // console.log('NumericCellEditor.focusOut()');
  }

  updateCalcAmt(data) {
    this.input.value = data.data.calAmount;
  }

  updatecalCost(data) {
    this.input.value = data.data.calCost;
  }

  updateQty(data) {
    this.input.value = data.data.quantity;
  }

  updatePackSize(data) {
    this.input.value = data.data.packSize;
  }

  getGui() {
    return this.input;
  }

  ngOnInit() {
  }

  customFunction(data) {
    // console.log('number editor', data, this.params);
    this.input.value = data.data.quantity;
  }

  exchangeRate(data) {
    this.input.value = data.data.ExchangeRate;
  }

  formatter() {
    let iniDecimalFormatSetting: string;
    let minimumDecimalIntegerDigits: any;
    let minimumDecimalFractionDigits: any;
    let maximumDecimalFractionDigits: any;
    let minimumCurrencyFractionDigits: any;
    let maximumCurrencyFractionDigits: any;
    let minimumCurrencyIntegerDigits: any;
    let iniCurrencyFormatSetting: any;
    if (JSON.parse(localStorage.getItem('GlobalSettings'))) {
      const data = JSON.parse(localStorage.getItem('GlobalSettings'));
      iniDecimalFormatSetting = data.EatecINISettings.GeneralFormatsGroupSettings.TypeFormatDecimal;
      iniCurrencyFormatSetting = data.EatecINISettings.GeneralFormatsGroupSettings.TypeFormatCurrency;
    }
    if (iniDecimalFormatSetting && iniDecimalFormatSetting.length > 0) {
      const decimalDigits: any[] = iniDecimalFormatSetting.split('.');
      if (decimalDigits.length > 0) {
        const decimalIntergerDigits: string = decimalDigits[0];
        const decimalFractionDigits: string = decimalDigits[1];
        minimumDecimalIntegerDigits = decimalIntergerDigits.length <= 0 ? minimumDecimalIntegerDigits : decimalIntergerDigits.length;
        minimumDecimalFractionDigits = decimalFractionDigits.length <= 0 ? minimumDecimalFractionDigits : decimalFractionDigits.length;
        maximumDecimalFractionDigits = decimalFractionDigits.length <= 0 ? maximumDecimalFractionDigits : decimalFractionDigits.length;
      }
    }

    this.DecimalFormatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minimumDecimalFractionDigits,
      maximumFractionDigits: maximumDecimalFractionDigits,
      minimumIntegerDigits: minimumDecimalIntegerDigits
    });

    if (iniCurrencyFormatSetting && iniCurrencyFormatSetting.length > 0) {
      const currencyDigits: any[] = iniCurrencyFormatSetting.split('.');
      if (currencyDigits.length > 0) {
        const intergerDigits: string = currencyDigits[0];
        const fractionDigits: string = currencyDigits[1];
        minimumCurrencyIntegerDigits = intergerDigits.length <= 0 ? minimumCurrencyIntegerDigits : intergerDigits.length;
        minimumCurrencyFractionDigits = fractionDigits.length <= 0 ? minimumCurrencyFractionDigits : fractionDigits.length;
        maximumCurrencyFractionDigits = fractionDigits.length <= 0 ? maximumCurrencyFractionDigits : fractionDigits.length;
      }
    }

    this.CurrencyFormatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: minimumCurrencyFractionDigits,
      maximumFractionDigits: maximumCurrencyFractionDigits,
      minimumIntegerDigits: minimumCurrencyIntegerDigits
    });
  }
}
