import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchedulingAssistantBusiness } from 'src/app/appointment/business/scheduling-assistant.business';
import { TimeIntervals } from 'src/app/appointment/spa-wizard/spa-wizard.modal';
import { AgDateConfig, AgDropdownConfig, AgTimeConfig, DropdownOptions, SaveButtonObj } from 'src/app/common/Models/ag-models';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { SchedulingAssistant, SchedulingAssistantView } from '../business/shared.modals';
import { AppointmentpopupService } from '../service/appointmentpopup.service';
import { SpaUtilities } from '../utilities/spa-utilities';
import { SchedulingAssitantInput, SelectedData } from './scheduling-assistant.modal';

@Component({
  selector: 'app-scheduling-assistant',
  templateUrl: './scheduling-assistant.component.html',
  styleUrls: ['./scheduling-assistant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulingAssistantComponent implements OnInit, OnDestroy {
  SchedulingAssitantFormGroup: UntypedFormGroup;
  SchedulingAssitantData: SchedulingAssitantInput;
  buttonObj: SaveButtonObj;
  serviceTypeFieldData: AgDropdownConfig;
  servicesFieldData: AgDropdownConfig;
  appointmentDateFieldData: AgDateConfig;
  TimeIntervalFieldData: AgDropdownConfig;
  ScheduleData: SchedulingAssistant[];
  displayOptionFilter: { id: number; viewValue: string; }[];
  selectedView: SchedulingAssistantView;
  propertyStartTime: any;
  propertyEndTime: any;
  serviceData:any;
  timeIntervals: TimeIntervals[];
  selectedData: SelectedData;
  captions: any;
  Data: any;
  viewByFieldData: DropdownOptions[];
  schedulingAssistantView = SchedulingAssistantView;
  appointmentStartTimeFieldData: AgTimeConfig;
  appointmentEndTimeFieldData: AgTimeConfig;
  appointmentCaptions: any;
  operatingHours: any;
  selectedDate: Date;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<SchedulingAssistantComponent>,
    private fb: UntypedFormBuilder,
    private localization: SpaLocalization,
    private propertyInformation: SpaPropertyInformation,
    private schedulingAssistantBusiness: SchedulingAssistantBusiness,
    private cdr: ChangeDetectorRef,
    private utils: SpaUtilities,
    private appointmentService: AppointmentpopupService) {
    this.Data = data;
    this.SchedulingAssitantData = data.data;
    this.operatingHours = this.SchedulingAssitantData.operatingHours;
    this.propertyStartTime = this.SchedulingAssitantData.propertyStartTime;
    this.propertyEndTime = this.SchedulingAssitantData.propertyEndTime;
    this.selectedDate = this.SchedulingAssitantData.appointmentDate;
    this.selectedView = SchedulingAssistantView.Therapist;
    this.captions = this.localization.captions;
    this.appointmentCaptions = this.localization.captions.bookAppointment;
  }


  async ngOnInit() {
    this.pageInitializations();
  }

  async pageInitializations() {
    this.displayOptionFilter = this.schedulingAssistantBusiness.getdisplayOptionFilterData();
    const serviceTypeFormattedData = this.schedulingAssistantBusiness.getFormattedDropdownData(this.SchedulingAssitantData.serviceType, 'code').filter(map => this.SchedulingAssitantData.selectedServiceType.map(v => v.id).includes(map.id));
    const serviceFormattedData = this.schedulingAssistantBusiness.getFormattedDropdownData(this.SchedulingAssitantData.services).filter(map => this.SchedulingAssitantData.selectedServices == map.id);
    this.SchedulingAssitantFormGroup = this.fb.group({
      date: this.selectedDate ? this.selectedDate : this.localization.getDate(this.propertyInformation.CurrentDate),
      servicetype: serviceTypeFormattedData && serviceTypeFormattedData.length ? [serviceTypeFormattedData] : '',
      services: serviceFormattedData ? serviceFormattedData : '',
      viewby: this.selectedView,
      timeinterval: '',
      startTime: this.propertyStartTime ? this.localization.ConvertDateToTime(this.propertyStartTime) : "12:00 am",
      endTime: this.propertyEndTime ? this.localization.ConvertDateToTime(this.propertyEndTime) : "11:59 pm"
    });
    this.buttonObj = {
      customSaveText: this.captions.lbl_Continue,
      customCancelText: this.captions.lbl_Cancel,
      disabled: this.selectedData ? false : true,
      isEdit: false
    };
    this.appointmentDateFieldData = {
      className: 'ag_form-control--md',
      form: this.SchedulingAssitantFormGroup,
      formControlName: 'date',
      placeHolderId: 'lbl_startDate',
      placeHolder: this.captions.lbl_AppointmentDate,
      minDate: this.localization.getDate(this.propertyInformation.CurrentDate),
      isDateRequired: false,
      isDisabled: false
    };
    this.appointmentStartTimeFieldData = {
      className: 'ag_form-control--md',
      form: this.SchedulingAssitantFormGroup,
      formControlName: 'startTime',
      placeHolderId: 'lbl_startTime',
      placeHolder: this.captions.lbl_StartTime,
      isTimeRequired: false
    };
    this.appointmentEndTimeFieldData = {
      className: 'ag_form-control--md',
      form: this.SchedulingAssitantFormGroup,
      formControlName: 'endTime',
      placeHolderId: 'lbl_endTime',
      placeHolder: this.captions.lbl_EndTime,
      isTimeRequired: false,
      minTime: this.SchedulingAssitantFormGroup.controls.startTime.value
    };

    const serviceTypeData = this.schedulingAssistantBusiness.getFormattedDropdownData(this.SchedulingAssitantData.serviceType, 'code');
    this.serviceTypeFieldData = {
      className: 'ag_form-control--lg',
      placeHolderId: 'lbl_serviceType',
      placeHolder: this.appointmentCaptions.ServiceGroup,
      form: this.SchedulingAssitantFormGroup,
      formControlName: 'servicetype',
      showRequired: false,
      selectOptions: Promise.resolve(serviceTypeData),
      isMultiSelect: true,
      isAll: true,
      isAllSelected: (this.SchedulingAssitantData.serviceType.length === this.SchedulingAssitantData.selectedServiceType.length ? true : false)
    };

    const servicesData = this.schedulingAssistantBusiness.getFormattedDropdownData(this.SchedulingAssitantData.services);
    this.servicesFieldData = {
      className: 'ag_form-control--lg',
      placeHolderId: 'lbl_services',
      placeHolder: this.captions.lbl_Services,
      form: this.SchedulingAssitantFormGroup,
      formControlName: 'services',
      showRequired: false,
      selectOptions: Promise.resolve(servicesData)
    };

    this.viewByFieldData  = this.schedulingAssistantBusiness.getViewbyType();
    const TimeIntervalData = this.schedulingAssistantBusiness.getTimeIntervalType();
    this.TimeIntervalFieldData = {
      className: 'ag_form-control--lg',
      placeHolderId: '',
      placeHolder: '',
      form: this.SchedulingAssitantFormGroup,
      formControlName: 'timeinterval',
      showRequired: false,
      selectOptions: Promise.resolve(TimeIntervalData)
    };
    this.handleServiceTypeChange();
    this.timeIntervals = await this.schedulingAssistantBusiness.buildSlotData(new Date(this.SchedulingAssitantFormGroup.controls.date.value), this.propertyStartTime, this.propertyEndTime,this.serviceData);
      this.bindTableData();
  }

  setStartAndEndTime(){
    let startTime = this.operatingHours ? this.localization.ToDate(this.utils.getDayTimeForTheDate(this.operatingHours,this.selectedDate)['StartTime'], "HH:mm") : this.localization.ToDate("00:00", "HH:mm");
    let endTime = this.operatingHours ? this.localization.ToDate(this.utils.getDayTimeForTheDate(this.operatingHours,this.selectedDate)['EndTime'], "HH:mm") : this.localization.ToDate("23:59", "HH:mm");
    this.SchedulingAssitantFormGroup.controls['startTime'].setValue(this.localization.ConvertDateToTime(startTime.toString()));
    this.SchedulingAssitantFormGroup.controls['endTime'].setValue(this.localization.ConvertDateToTime(endTime.toString()));
    this.propertyStartTime = this.localization.combineDateAndTime(this.selectedDate, startTime);
    this.propertyEndTime = this.localization.combineDateAndTime(this.selectedDate, endTime);
  }

  close() {
    this.dialogRef.close({ from: 'cancel' });
  }

  ngOnDestroy(): void {
    this.schedulingAssistantBusiness.resetData();
  }

  async dateChangeEvent(event) {
    this.selectedDate = new Date(this.SchedulingAssitantFormGroup.controls.date.value);
    this.setStartAndEndTime();
    this.timeIntervals = await this.schedulingAssistantBusiness.buildSlotData(this.selectedDate, this.propertyStartTime, this.propertyEndTime,this.serviceData);
    this.bindTableData();
  }

  async handleViewChange(event) {
    this.selectedView = event.value;
    const selectedServiceId = this.SchedulingAssitantFormGroup.controls.services.value.id;
    if (selectedServiceId > 0) {
      const dateObj = new Date(this.SchedulingAssitantFormGroup.controls.date.value);
      this.ScheduleData = await this.schedulingAssistantBusiness.buildDataForViewChange(selectedServiceId, dateObj, this.timeIntervals, this.selectedView, this.SchedulingAssitantData);
    }
    this.buttonObj.disabled = true;
    this.buttonObj = {...this.buttonObj};    
    this.selectedData = null;
    this.cdr.detectChanges();
  }

  async timeChangeEvent(event) {
    const startTime = this.localization.combineDateAndTime(this.SchedulingAssitantFormGroup.controls.date.value, this.localization.ToDate(this.SchedulingAssitantFormGroup.controls.startTime.value, "hh:mm A"));
    const endTime = this.localization.combineDateAndTime(this.SchedulingAssitantFormGroup.controls.date.value, this.localization.ToDate(this.SchedulingAssitantFormGroup.controls.endTime.value, "hh:mm A"));
    this.timeIntervals = await this.schedulingAssistantBusiness.buildSlotData(new Date(this.SchedulingAssitantFormGroup.controls.date.value), startTime, endTime,this.serviceData);
    this.appointmentEndTimeFieldData.minTime = this.SchedulingAssitantFormGroup.controls.startTime.value;
    this.appointmentEndTimeFieldData = {...this.appointmentEndTimeFieldData};
    this.bindTableData();
  }
  
  async handleServiceChange(event) {
    this.bindTableData();
  }

  handleServiceTypeChange() {
    const filteredServices = this.SchedulingAssitantData.services.filter(res => this.SchedulingAssitantFormGroup.controls.servicetype.value.map(x => x.id).includes(res.serviceGroupId));
    this.servicesFieldData.selectOptions = Promise.resolve(this.schedulingAssistantBusiness.getFormattedDropdownData(filteredServices));
    this.servicesFieldData = {...this.servicesFieldData};
  }

  async save(event) {
    let uriParams: any;
    let appointmentFilter: any;
    let occupiedLocationDetails: any;
    if (this.selectedData) {
      uriParams = {
        isTherapistOverbook: this.SchedulingAssitantData.isTherapistOverbook,
        isLocationOverbook: this.SchedulingAssitantData.isLocationOverbook,
        id: this.SchedulingAssitantData.appointmentId && this.SchedulingAssitantData.appointmentId[0] ? this.SchedulingAssitantData.appointmentId[0] : 0,
        timeAvailable: 'true',
        date: this.utils.convertDateFormat(this.selectedData.slotDateTime),
        tempIds: this.SchedulingAssitantData.tempHoldIds ? JSON.stringify(this.SchedulingAssitantData.tempHoldIds) : null
      };
     [appointmentFilter, occupiedLocationDetails] = await Promise.all([this.schedulingAssistantBusiness.getAppointmentFilters(uriParams), 
        this.appointmentService.GetOccupiedLocationDetails(this.selectedData.slotDateTime, this.SchedulingAssitantData.tempHoldIds)]);
    }
    this.dialogRef.close({ action: 'continue', selectedData: this.selectedData, filterResponse: appointmentFilter, occupiedLocation: occupiedLocationDetails });
  }

  checkBoxChangeEmitEvent(event) {

  }

  async bindTableData() {
    this.selectedData = null;
    const selectedServiceId = this.SchedulingAssitantFormGroup.controls.services.value ? this.SchedulingAssitantFormGroup.controls.services.value.id : 0;
    if (selectedServiceId > 0) {
      this.ScheduleData = null;
      const dateObj = new Date(this.SchedulingAssitantFormGroup.controls.date.value);
      this.ScheduleData = await this.schedulingAssistantBusiness.buildSchedulingAssistanceData(selectedServiceId, dateObj, this.timeIntervals, this.selectedView, this.SchedulingAssitantData);
      this.cdr.detectChanges();
    } else {
      this.ScheduleData = [];
    }
  }

  selectedDataEvent(event) {
    // event[0] - data  1- group index, 2 - rowindex,3- column index
    const data = event[0];
    const groupIndex = event[1];
    const rowIndex = event[2];
    const columnIndex = event[3];
    const selectedGroupId = data[groupIndex].id;
    const selectedInnerDataId = data[groupIndex].innerData[rowIndex].id;
    this.selectedData = {
      therapistId: this.selectedView === SchedulingAssistantView.Therapist ? selectedGroupId : selectedInnerDataId,
      locationId: this.selectedView === SchedulingAssistantView.Location ? selectedGroupId : selectedInnerDataId,
      slotDateTime: data[groupIndex].slots[columnIndex].dateTime,
      selectedView: this.selectedView,
      serviceId: this.SchedulingAssitantFormGroup.controls.services.value.id
    };
    this.buttonObj = {
      customSaveText: this.captions.lbl_Continue,
      customCancelText: this.captions.lbl_Cancel,
      disabled: this.selectedData ? false : true,
      isEdit: false
    };
  }
}
