import { clone, cloneDeep, difference, differenceWith, union, isEqual } from 'lodash';
import { Injectable } from '@angular/core';
import { Filter } from '../../models/ag-models';
@Injectable()
export class AsideFilterService {
    unTouched: any;

    ApplyFilter(filterGroup, filter) {
        const filteredIdx = filterGroup.filtered.findIndex((x) => {
            return x.id === filter.id;
        });
        const isAll = filterGroup.filters.find((x) => x.id === filter.id && x.id === 0);
        if (filteredIdx === -1) {
            if (isAll) {
                filterGroup.filters = filterGroup.filters.map(x => {
                    x.isSelected = true;
                    return x;
                });
                filterGroup.filtered = clone(filterGroup.filters);
            } else {
                filter.isSelected = true;
                filterGroup.filtered.push(filter);
                const diff = difference<Filter>(filterGroup.filters, filterGroup.filtered);

                if (diff.length === 1 && diff[0].id === 0) {
                    filterGroup.filtered.push(filterGroup.filters[0]);
                    const allFilter = filterGroup.filters.find(x => x.id === 0);
                    if (allFilter) {
                        allFilter.isSelected = true;
                    }
                }
            }

        } else {
            if (isAll) {
                this.clearFilterGroup(filterGroup);
            } else {
                filter.isSelected = false;
                filterGroup.filtered.splice(filteredIdx, 1);
                const allIndex = filterGroup.filtered.findIndex((x) => x.id === 0);
                if (allIndex >= 0) {
                    filterGroup.filtered.splice(allIndex, 1);
                    const allFilter = filterGroup.filters.find(x => x.id === 0);
                    if (allFilter) {
                        allFilter.isSelected = false;
                    }
                }
            }
        }
        filterGroup.filters = this.orderData(filterGroup);
        return filterGroup;
    }

    orderData(DataArr): Filter[] {
        const isAllDataArray = DataArr.filters.filter(data => {
            if (data.id === 0) {
                return data;
            }
        });
        const selectedArray = DataArr.filters.filter(data => DataArr.filtered.includes(data.id));
        const dataArray = DataArr.filters;
        return union<Filter>(isAllDataArray, selectedArray, dataArray);
    }

    clearFilteredOptions(filterGroups, isInitialLoad?) {
        if (filterGroups) {
            filterGroups.forEach(filterGroup => this.clearFilterGroup(filterGroup, isInitialLoad));
        }
    }

    GetViewMoreFilters(filters) {
        return filters.map(filter => {
            return {
                id: filter.id,
                moreCustomCBxlabelText: filter.name,
                moreCustomCBxChecked: filter.isSelected
            };
        }); 
    }

    private clearFilterGroup(filterGroup, isInitialLoad?) {
        if (filterGroup && filterGroup.filters) {
            if (!filterGroup.isSingleSelect) {
                filterGroup.filters.forEach(filterObj => {
                    filterObj.isSelected = isInitialLoad && filterObj.isSelected ? filterObj.isSelected : false;
                });
                filterGroup.filtered = !filterGroup.filtered || !isInitialLoad ? [] : filterGroup.filtered;
            } else {
                if (!isInitialLoad) {
                    filterGroup.filters.forEach((filterObj, idx) => {
                        filterObj.isSelected = idx === 0;
                    });
                    filterGroup.filtered = filterGroup.filters[0];
                }
            }
        }
    }

    calculateFilteredDataLength(filterGroups) {
        if (filterGroups) {
            const filtered = filterGroups.map(x => x.filtered);
            return filtered.reduce((acc, filteredArray) => acc + (filteredArray ? filteredArray.length : 0), 0);
        }
    }

    setUnTouched(value) {
        this.unTouched = cloneDeep(value);
    }

    isModified(value) {
        const diff = differenceWith(this.unTouched, value, isEqual);
        return diff && diff.length;
    }

}
