export const JWT_TOKEN = '_jwt';
export const USER_SESSION = 'userSession';
export const REMEMBER_INFO = '_rememberInfo';
export const USER_INFO = '_userInfo';
export const USER_SETTINGS = 'userSettings';
export const PROPERTY_DATE = 'propertyDate';
export const PROPERTY_INFO = 'propertyInfo';
export const USE_RETAIL_INTERFACE = 'useRetailInterface';
export const VAT_ENABLED = 'VATEnabled';
export const PAYMENT_CONFIGURATION = 'paymentConfiguration';
export const PROPERTY_CONFIGURATION_SETTINGS = 'PROPERTYCONFIGURATION';
export const USERS_SESSSIONS_INFO = 'usersSessionsInfo';
export const GOOGLE_MAP_API = 'https://maps.googleapis.com/maps/api/geocode/json?address='
export const FULL_STORY_ORG_ID = 'FullStoryOrgId';
export const NO_OF_DECIMAL_DIGITS = 'NoOfDecimalDigits';
export const SPA_PRODUCT_ID = 1;
export const SUPPORT_TENANT = 1001;
export const SUPPORT_USERNAME = 'AGYSSUPPORT';
