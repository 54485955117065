import { Pipe, PipeTransform } from '@angular/core';
import { DropdownOptions } from '../../Models/ag-models';

@Pipe({
  name: 'getDropdownText'
})
export class getDropdownTextPipe implements PipeTransform {
  transform(value: string | number, list: DropdownOptions[]) {
    const data = list.find(x => x.id == value);
    return data.viewValue;
  }
}