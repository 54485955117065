import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';

@Component({
  selector: 'lib-week-list',
  templateUrl: './week-list.component.html',
  styleUrls: ['./week-list.component.scss']
})
export class WeekListComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  constructor(public AgyDynamicFormService: AgyDynamicFormServices) { }
  
  ngOnInit(): void {
    this.form.controls['Weekdays'].setValue(this.field.fieldType.SingleData.listCollection);
  }

  dayClick(index: number, dayName: string): void {
    try {
      this.field.fieldType.SingleData.listCollection[index].Checked = !this.field.fieldType.SingleData.listCollection[index].Checked;
      this.form.controls['Weekdays'].setValue(this.field.fieldType.SingleData.listCollection);
      const emptyDayCollec = this.field.fieldType.SingleData.listCollection.every(c => !c.Checked);
      if (emptyDayCollec) {
        this.form.controls['ScheduleId'].setValue(null);
      }      
      this.form.updateValueAndValidity();
    } catch (error) {
      console.log(error);
    }    
  }
}
