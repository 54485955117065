import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EqContext } from '@easyquery/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExportExtractComponent } from 'src/app/common/generic-extract/export-extract/export-extract.component';
import { Localization } from 'src/app/common/localization/localization';
import { AgInputFieldConfig, SaveButtonObj } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-save-easyquery-popup',
  templateUrl: './save-easyquery-popup.component.html',
  styleUrls: ['./save-easyquery-popup.component.scss']
})
export class SaveEasyqueryPopupComponent implements OnInit {
  saveQueryForm: UntypedFormGroup;
  queryName: AgInputFieldConfig;
  buttonObj: SaveButtonObj;
  titleHeader: string = '';
  captions: any;
  private context: EqContext;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  modeldata: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder, private localization: Localization, public dialogRef: MatDialogRef<ExportExtractComponent>,) {
    this.captions = this.localization.captions;

  }

  ngOnInit(): void {
    this.saveQueryForm = this.formBuilder.group({
      queryName: ['', Validators.required]
    });
    // if(this.data){
    //   this.titleHeader = (this.data.isSave)?this.captions.lbl_saveQuery:"";
    // }
    this.queryName = {
      className: 'ag_form-control--lg',
      form: this.saveQueryForm,
      formControlName: 'queryName',
      placeHolderId: 'txt_queryname',
      placeHolder: this.captions.txt_queryname,
      maxlength: 50,
      showRequired: false,
      changeLabelOnEdit: true,
      disabled: false

    };
    this.buttonObj = {
      customSaveText: this.captions.OK,
      disabled: false,
      isEdit: false
    };
    this.saveQueryForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.buttonObj.disabled = !(this.saveQueryForm.valid && this.saveQueryForm.dirty)
      this.buttonObj = { ... this.buttonObj };
     

    });
    this.saveQueryForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.buttonObj.disabled = !(this.saveQueryForm.valid && this.saveQueryForm.dirty)
      this.buttonObj = { ... this.buttonObj };
     

    });
  }

  saveExtract() {
    this.dialogRef.close(
      {
        easyQueryName: this.saveQueryForm.controls['queryName'].value

      }
    );

  }
  cancel(event) {
    this.dialogRef.close(event);
  }
  closeDialog() {
    this.dialogRef.close();
  }



}
