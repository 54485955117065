import { Pagination } from '../models/pagination.interface';
import { RequestDataModel } from '../models/requestdata.interface';
import { RequestEngine } from '../request-engine/requestengine';

export class RequestPagination extends RequestEngine implements Pagination {
    requestPayload: RequestDataModel;
    get getReqPayload() { return this.requestPayload; }
    constructor(orderByColumn: string, orderByDirection: number, FetchAll: boolean, LocationIds: Array<number>, ModuleName?: string) {
        super(FetchAll, LocationIds);
        if (ModuleName !== 'Template') {
            this.requestPayload = super.getRequestPayload();
        } else {
            this.requestPayload = super.getTemplateRequestPayload();
        }
        this.requestPayload.orderByColumn = orderByColumn;
        this.requestPayload.orderByDirection = orderByDirection;
    }

    public getPaginationPayload(skip: number, take: number): RequestDataModel {
        this.requestPayload.Skip = skip;
        this.requestPayload.Take = take;
        return this.requestPayload;
    }
    public getSortPayload(orderByColumn: string, orderByDirection: number): RequestDataModel {
        this.requestPayload.orderByColumn = orderByColumn;
        this.requestPayload.orderByDirection = orderByDirection;
        return this.requestPayload;
    }
}