import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { GridFetchFields, TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety, PatchValueReassign, SetReferenceForPO } from './create-transaction-config.interface';

export const SetPOReference: SetReferenceForPO = (
        TransactionCollection: TransactionConfiguration, responseData: any,
        FormGroupData: FormGroup, RoutingParams: any): any => {
        try {
            console.log('Set Reference for PO', RoutingParams);
            // const RowId = RoutingParams['rowId'];
            const transaction = RoutingParams['transaction'];
            const transactionFrom = RoutingParams['transactionFrom'];
            let Reference = '';
            let LocationId = '';
            if (transactionFrom === 'LoadRequisition' && transaction === 'poopenrequisition') {
                Reference = `req#:${responseData.Id}`;
                LocationId = responseData.FromLocId;
                FormGroupData.controls['ReqHeadId'].setValue(responseData.Id);
            } else if (transactionFrom === 'LoadPurchase Requests' && transaction === 'poopenpr') {
                Reference = `rqa#:${responseData.Id}`;
                LocationId = responseData.RequestLocId;
                FormGroupData.controls['PurchaseReqHeadId'].setValue(responseData.Id);
            }
            if (Reference) {
               FormGroupData.controls['Reference'].setValue(Reference);
            }
            if (LocationId) {
                FormGroupData.controls['LocationId'].setValue(LocationId);
            }
        } catch (Error) {
            console.error('Error occurred SetPOReference', Error);
        }
    };