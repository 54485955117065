import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-appointment-by-user',
  templateUrl: './appointment-by-user.component.html',
  styleUrls: ['./appointment-by-user.component.scss']
})
export class AppointmentByUserComponent implements OnInit {

  appointmentByUserForm: UntypedFormGroup;
  captions: any;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();
  radioFilter = [] ;
  constructor(private fb: UntypedFormBuilder, private controlContainer: ControlContainer,public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
    this.radioFilter =[
    { id: 0, name: this.captions.AppointmentDate },
    { id: 1, name: this.captions.AppointmentBookedDate}
   ];
   }

  ngOnInit() {
    this.appointmentByUserForm = this.fb.group({
      radioFilter: 0
    });

    this.formReady.emit(this.appointmentByUserForm);
  }

}
