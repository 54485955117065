import { UntypedFormGroup } from "@angular/forms";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { BaseReport } from "../common/base-report";
import { ReportUIConfig, ReportAPIOptions, ReportParams, TransactionExtractFilter, AllReports } from "../report.modals";

export class TransactionExtract extends BaseReport{
    constructor(public reportFormGroup: UntypedFormGroup, public retailLocalization : RetailLocalization){
        super(reportFormGroup);
    }
    getReportUIConfig(): ReportUIConfig {
      return{
          startDatePicker:true,
          endDatePicker:true,
          inActiveToggle: false,
          pageBreakToggle: false,
          layout:'PORTRAIT',
          allowFutureDate: false

      };
    }
    generateReportAPIOptions(): ReportAPIOptions {
        
        return {
            code: AllReports.TransactionExtract,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true,
            format: "EXCEL"
        };
    }
    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;  
        const customFilterData: any = this.reportFormGroup.controls['customFilterFormGrp'].value;      
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) }, {'pIsConsolidatedData':customFilterData?.displayInSingleSheetToggle ?? false }];
    }
    protected formURIParams(): ReportParams[] {
       return [];
    }
    protected formFilters() {
       
        let toApi = this.localization.convertDateObjToAPIdate;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGroup.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGroup.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGroup.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGroup.controls['timelineCountTo'].value;
            return JSON.stringify({
                StartDate : toApi(this.startDate),
                EndDate : toApi(this.endDate),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
        const customFilterData: any = this.reportFormGroup.controls['customFilterFormGrp'].value;
        const filter: TransactionExtractFilter = {
            StartDate : toApi(this.startDate),
            EndDate : toApi(this.endDate),
            IsConsolidatedData : customFilterData?.displayInSingleSheetToggle ?? false 
        };      
    
        return JSON.stringify(filter);    }

}