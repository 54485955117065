import { Component, EventEmitter, OnInit, Output, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailDataAwaiters } from 'src/app/retail/shared/events/awaiters/retail.data.awaiters';
import { Host, Product,clientSearchType } from 'src/app/retail/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import { BaseResponse } from 'src/app/retail/shared/shared.modal';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RetailReportControl } from '../../../basereport/base-report.component';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ClientDetailForReport, DropDownData } from '../../../business/report.modals';
import { ReportDataService } from '../../../data/report-data.services';

@Component({
  selector: 'app-accrual',
  templateUrl: './accrual.component.html',
  styleUrls: ['./accrual.component.scss']
})
export class AccrualComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  AccrualFormGrp: UntypedFormGroup;
  validSelection: boolean = false;
  validSelectionErrMsg: string = "";
  validSelectionUsers: boolean = false;
  validSelectionErrMsgUsers: string = "";
  userData: DropDownData[] = null;
  outletData: DropDownData[] = null;
  reportSubscription: Subscription[] = [];
  requestUid = '';
  timer = null;
  Clients: Array<any> = null;
  SearchedClients: Array<any> = [];
  allUsers: DropDownData[] = [];
  public captions: any = this.localization.captions.reports;
  searchByCaption:string = "";
  floatLabel: string;
  editPatchValue : any;

  constructor(private fb: UntypedFormBuilder, private http: HttpServiceCall, private utils: RetailUtilities,
    public business: ReportBusinessService, private dataService: ReportDataService, public localization: RetailLocalization,
    private propertyInfo: RetailPropertyInformation, private container: ViewContainerRef) { 
      this.floatLabel = this.localization.setFloatLabel;
    }

  async ngOnInit() {
    this.AccrualFormGrp = this.fb.group({
      Outlets: new UntypedFormControl([], Validators.required),
      Users: new UntypedFormControl([], Validators.required),
      IncludeInActiveUsers: false,
      clientTobeSelected: false
    });
    this.formReady.emit(this.AccrualFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        await this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
        if(patchItem.UserIds != undefined){
          this.dataService.GetAllUsersByPropertyId().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.UserIds.find(x => x == item.id);
            });
            this.userData = arr;     
          });
        } 
        if(patchItem.OutletIds != undefined){
          this.dataService.getAllOutletsByUser().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.OutletIds.find(x => x == item.id);
            });
            this.outletData = arr;     
          });
        } 
        }
    } 
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.reportSubscription && this.reportSubscription.length) {
      this.reportSubscription.forEach(x => {
        x.unsubscribe();
      });
    }
  }

  async onLoad() {
    [this.allUsers, this.outletData] = await Promise.all([this.dataService.GetAllUsersByPropertyId(), this.dataService.getAllOutletsByUser()]);
    if (this.allUsers) {
      this.userData = this.allUsers.filter(x => x.isActive);
      this.business.FilterDataSource["allUsers"] = this.userData;
    }
    if (this.outletData) {
      this.business.FilterDataSource["outletData"] = this.outletData;
    }
    this.ChangeSubscriber();
    if (Number.parseInt(this.utils.GetPropertyInfo('ProductId')) == Product.GOLF) {
      this.searchByCaption = this.captions.SearchByGuestName;
    }
    else {
      this.searchByCaption = this.captions.SearchByClientName;
    }
  }

  ChangeSubscriber() {
    this.reportSubscription.push(this.AccrualFormGrp.controls['clientTobeSelected'].valueChanges.subscribe(result => {
      if (result.length > 2) {
        this.requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
        if (this.timer) {
          clearTimeout(this.timer); //cancel the previous timer.
          this.timer = null;
        }
        this.timer = setTimeout(this.searchClient.bind(this), 1000, result);
      }
    }));
    this.reportSubscription.push(this.AccrualFormGrp.controls['IncludeInActiveUsers'].valueChanges.subscribe(result => {
      if (result) {
        this.userData = this.allUsers;
      }
      else {
        this.userData = this.allUsers.filter(x => x.isActive);
      }
      this.business.FilterDataSource["allUsers"] = this.userData;
    }));
  }

  IsSelectionValid(validity: boolean, controlName: string) {
    if (controlName == 'Outlets') {
      this.validSelection = !validity;
      if (!validity) {
        this.AccrualFormGrp.controls['Outlets'].setErrors(Validators.required);
        this.validSelectionErrMsg = "Outlet is Missing";
      }
    }
    else if (controlName == 'Users'){
      this.validSelectionUsers = !validity;            
      if (!validity) {
        this.AccrualFormGrp.controls['Users'].setErrors(Validators.required);
        this.validSelectionErrMsgUsers = "User is Missing";
      }
    }
  }

  async searchClient(clientName?: string) {
    if (!clientName)
      clientName = this.AccrualFormGrp.controls['clientTobeSelected'].value
    if (Number.parseInt(this.utils.GetPropertyInfo('ProductId')) == Product.SPA) {
     
      this.http.CallApiWithCallback<any>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: 'clientSearch',
        method: HttpMethod.Get,
        uriParams: { name: encodeURIComponent(clientName), searchType:clientSearchType.All,requestUid: this.requestUid },
        showError: false,
        extraParams: []
      });
    }
    else if (Number.parseInt(this.utils.GetPropertyInfo('ProductId')) == Product.RETAIL || Number.parseInt(this.utils.GetPropertyInfo('ProductId')) == Product.GOLF) {
      this.requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
      let payeeDetails = await RetailDataAwaiters.searchPayee(clientName, 2, this.requestUid);
      let guestInfo: Array<any> = payeeDetails[1] as unknown as Array<any>;
      if (guestInfo && guestInfo.length) {
        this.Clients = (guestInfo.filter(x => x.patronId) || []).map(C => {
          return {
            name: C.name,
            id: C.id,
            patronId: C.patronId
          };
        })
      }
    }
  }

  successCallback(result: BaseResponse<any>, callDesc: string, extraParams: any[]) {
    const _result: any[] = <any>result.result;
    var responseUid = "";
    if (callDesc == 'clientSearch') {
      let client: ClientDetailForReport[] = _result as ClientDetailForReport[];
      if (client != null) {

        if (client.length > 0) {
          responseUid = client[0].requestUid;
          client = client.filter(x => x.clientDetail.loyaltyDetail && x.clientDetail.loyaltyDetail.length && x.clientDetail.loyaltyDetail[0].patronId);
          if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
            this.Clients = client && client.length && client.map(C => {
              return {
                name: `${C.clientDetail.firstName} ${C.clientDetail.lastName}`,
                id: C.id,
                patronId: C.clientDetail.loyaltyDetail && C.clientDetail.loyaltyDetail.length && C.clientDetail.loyaltyDetail[0].patronId
              };
            })
          }
        }
      }
      else { return []; }
    }
  }

  errorCallback() {
    console.error('error');
  }

  displayFn(client?: any): string {
    if (client) { return client.name; }
  }

  getFormControlValue(event) {
    if (event[0] === 'Users') {
      this.AccrualFormGrp.setControl('Users', event[1]);
    }
    if (event[0] === 'Outlets') {
      this.AccrualFormGrp.setControl('Outlets', event[1]);
    }
  }
}
