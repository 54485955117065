import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ConfirmPostPopUpModel, ErrorPopModel } from '../model/pop-up.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { AgilsysErrorPopComponent } from '../agilysys-error-popup/agilysys-error-popup.component';

@Component({
  selector: 'app-agilysys-confirm-post-popup',
  templateUrl: './agilysys-confirm-post-popup.component.html',
  styleUrls: ['./agilysys-confirm-post-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysConfirmPostPopupComponent implements OnInit, AfterViewInit {
  agilysysCofirmationButton: AgilysysButtonModel[];
  homeGroup: FormGroup;
  SupplierTotalReconcile: number;
  constructor(public dialogRef: MatDialogRef<AgilysysConfirmPostPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPostPopUpModel,
    public formBuilder: FormBuilder,
    public commonService: CommonService,
    private systemPreferenceService: SysytemPreferenceService, 
    private dialog: MatDialog) {
    this.homeGroup = this.formBuilder.group({});
    this.SupplierTotalReconcile = this.systemPreferenceService.globalSystemPreference.filter(x => x.LookupName === 'SupplierTotalonMA')[0].LookupValue;
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          // Receiving Module Changes with Supplier Total Reconcile - 3
          if (this.data?.transactionConfiguration?.Name === 'Receiving' && this.SupplierTotalReconcile === 3 ) {
            if (parseFloat(this.homeGroup.value.Balance) === 0) {
              this.homeGroup.controls['IsUICalculated'].setValue(true);
              this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
            } else {
              // show reconcile pop up
              const ErrorPopUpDialog: ErrorPopModel = {
                PopupHeaderContent: 'Validation failed',
                PopUpContent: 'Must reconcile with supplier total in MA.',
            };
              this.dialog.open(AgilsysErrorPopComponent, {
                  width: '400px',
                  height: 'auto',
                  data: ErrorPopUpDialog,
                  disableClose: true,
                  maxWidth: '100%'
              });
            }
          } else {
            // Supplier Total - 2 and 1
            if (this.homeGroup.controls['IsUICalculated']) {
              this.homeGroup.controls['IsUICalculated'].setValue(true);
            }
            this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
          }          
        }
      }
    ];
  }

  getReturnFormValue(data) {
    console.log('Return Data', data);
  }

  ngAfterViewInit() {
    console.log('Confirm Pop up');
    // setTimeout(() => {
    //   if (this.homeGroup.controls['SupplierTotal']) {
        // this.homeGroup.controls['SupplierTotal'].valueChanges.subscribe(res => {
        //   res = res ? res : '0';
        //   if (this.data && this.data.transactionConfiguration &&
        //     this.data.transactionConfiguration.CustomPostSchema 
        //     && this.data.transactionConfiguration.CustomPostSchema.PostKey === 'APVouchers') {
        //       const creditAp = this.homeGroup.controls['CreditAP'].value;
        //       const SupTotal = parseFloat(res);
        //       const Balance = SupTotal - creditAp;
        //       this.homeGroup.controls['Balance'].setValue(parseFloat(Balance.toString()).toFixed(this.commonService.GetVisibleNumberLength()));
        //       CommonService.triggerBlurEvent('Balance');
        //   } else {
        //     const ItemTotal = parseFloat(this.homeGroup.controls['ItemTotal'].value);
        //     const AllocatedTotal = parseFloat(this.homeGroup.controls['AllocatedTotal'].value);
        //     const MiscTotal = parseFloat(this.homeGroup.controls['MiscTotal'].value);
        //     const SupTotal = parseFloat(res);
        //     const Balance = SupTotal - (ItemTotal + AllocatedTotal + MiscTotal);
        //     this.homeGroup.controls['Balance'].setValue(parseFloat(Balance.toString()).toFixed(this.commonService.GetVisibleNumberLength()));
        //      CommonService.triggerBlurEvent('Balance');
        //   }
        // });
    //   }
    // }, 100);
  }
  ngOnInit(): void {
    console.log('Grid Table Card', this.data.PopUpContent);
    const _that = this;
    this.data.FormFields.forEach(fieldss => {
      if (!fieldss.fieldType.SingleData.disbaledProperty) {
        fieldss.fieldType.SingleData.onCellClick = function (field: any, Value: any, Form: any) {
          if (_that.data && _that.data.transactionConfiguration &&
            _that.data.transactionConfiguration.CustomPostSchema 
            && _that.data.transactionConfiguration.CustomPostSchema.PostKey === 'APVouchers') {
              const creditAp = _that.homeGroup.controls['CreditAP'].value;
              const SupTotal = parseFloat(_that.homeGroup.controls['SupplierTotal'].value);
              const Balance = SupTotal - creditAp;
              _that.homeGroup.controls['Balance'].setValue(parseFloat(Balance.toString()).toFixed(_that.commonService.GetVisibleNumberLength()));
              CommonService.triggerBlurEvent('Balance');
          } else {
            const TaxesWhenReceiving = _that.systemPreferenceService.globalSystemPreference.filter(x => x.LookupName === 'TaxeswhenReceiving')[0].LookupValue;
            const ItemTotal = parseFloat(_that.homeGroup.controls['ItemTotal'].value);
            const AllocatedTotal = parseFloat(_that.homeGroup.controls['AllocatedTotal'].value);
            const MiscTotal = parseFloat(_that.homeGroup.controls['MiscTotal'].value);
            const SupTotal = parseFloat(_that.homeGroup.controls['SupplierTotal'].value);
            const TaxTotal1 = parseFloat(_that.homeGroup.controls['Tax1Total'].value);
            const TaxTotal2 = parseFloat(_that.homeGroup.controls['Tax2Total'].value);
            const Balance = TaxesWhenReceiving === 2 ? SupTotal - (ItemTotal + AllocatedTotal + MiscTotal) :
            SupTotal - (ItemTotal + AllocatedTotal + MiscTotal + TaxTotal1 + TaxTotal2);
            _that.homeGroup.controls['Balance'].setValue(parseFloat(Balance.toString()).toFixed(_that.commonService.GetVisibleNumberLength()));
            CommonService.triggerBlurEvent('Balance');
          }          
        };
      }
    });
  }
}
