import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    CalcultionField,
    CustomFormBuild, FormBuilderGroupKey, GridConfiguration, GridCustomColExpand,
    GridFilterData,
    GridHeaderConfig,
    GridTableCard, MasterInventoryManagement, NestedBuilderData, NestedDataBuilder, SubFormConfigs
} from '../master.interface';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import {
    AutoCompleteData,
    DropDownDataModel,
    GridActionRender,
    GridActionsConfig, GridColumnReorder, GridConfig, GridFilter, GridInfiniteScrollConfig,
    GridPaginationConfig, GridResizeRows, GridSearchConfig
} from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { TranslateService } from '@ngx-translate/core';
import { FormGridDataConfiguration } from './form-config.interface';
import { Formaters } from '../../formaters';
import { GridInterface } from '../grid-interface';
import { MasterManagementService } from '../../service/master.service';
import * as _ from 'lodash';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { UnitTypeName } from 'src/app/eatecui/source/shared/enum/global.enum';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';

export const FormNestedGridDetail: FormGridDataConfiguration =
    (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
        httpService: HttpService, translateService: TranslateService, masterManagementService: MasterManagementService,
        AllViewModelEntity: any): any => {
        try {
            return new Promise<any>((resolve, reject) => {
                if (ViewModelEntity.length > 0 && MasterDataCollection) {
                    const EntityName = ViewModelEntity[0]['$']['Name'];
                    const ParentEnitityName = ViewModelEntity[0]['$']['Name'];
                    const navigationProperity = NavigationProperty
                        (ViewModelEntity, MasterDataCollection, AllViewModelEntity, ParentEnitityName);
                    let seleId = `id eq ${MasterDataCollection.GridConfig.GridTableCards[0].Id}`;
                    if (MasterDataCollection.GridConfig.GridCustomExpand && MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                        let queryString = '';
                        let queryString1 = '';
                        MasterDataCollection.GridConfig.GridCustomExpand.forEach((x: any, index: any) => {
                          if (!x.IsCustomExpand) {
                            MasterDataCollection.GridConfig.GridTableCards.forEach((tableCards: any) => {
                              if (tableCards.hasOwnProperty('Id') && index === 0) {
                                queryString = x.SelectQuery + ' eq ' + tableCards.Id;
                              } else {
                                if (tableCards.hasOwnProperty('SecondaryId') && index === 1) {
                                  queryString1 = x.SelectQuery + ' eq ' + tableCards.SecondaryId;
                                }
                              }
                            });
                          }
                        });
                        // if (MasterDataCollection.GridConfig.GridCustomExpand[0] &&
                        //     !MasterDataCollection.GridConfig.GridCustomExpand[0].IsCustomExpand) {
                        //     queryString = MasterDataCollection.GridConfig.GridCustomExpand[0].SelectQuery +
                        //         ' eq ' + MasterDataCollection.GridConfig.GridTableCards[0].Id;
                        // }
                        // if (MasterDataCollection.GridConfig.GridCustomExpand[1] &&
                        //     !MasterDataCollection.GridConfig.GridCustomExpand[1].IsCustomExpand) {
                        // eslint-disable-next-line max-len
                        //     queryString1 = MasterDataCollection.GridConfig.GridCustomExpand[1].SelectQuery + ' eq ' +
                        // MasterDataCollection.GridConfig.GridTableCards[0].SecondaryId;
                        // }
                        if (queryString1.length > 0 && queryString.length > 0) {
                            seleId = queryString + ' and ' + queryString1;
                        } else if (queryString1.length > 0) {
                            seleId = queryString1;
                        } else if (queryString.length > 0) {
                            seleId = queryString;
                        }
                    }
                    // eslint-disable-next-line max-len
                    const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                        return '%' + c.charCodeAt(0).toString(16);
                    });
                    const params: QueryParams = {
                        count: true,
                        top: InfiniteScrollItemCount,
                        skip: 0,
                        expand: '',
                        select: '',
                        filter: '',
                        orderby: '',
                        isactive: true,
                        search: '',
                        filterQuery: GetDataFilter,
                        searchtext: ''
                    };
                    params.expand = navigationProperity.join();
                    // eslint-disable-next-line
                    //const getQueryParams = formQueryParams(params);
                    const customEntityCollection = ['Ingredients', 'Products', 'Suppliers', 'Recipes'];
                    const entityIndex = customEntityCollection.findIndex(collectionEntity => collectionEntity === EntityName);
                    const getQueryParams = ( entityIndex > -1 ) ? `/${MasterDataCollection.GridConfig.GridTableCards[0].Id}` :
                    formQueryParams(params);
                    const GetDataByIdUrl = `/inventory/api/${EntityName + getQueryParams}`;
                    httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                        const convertValue = (typeof x['value'] === 'string') ? [JSON.parse(x['value'])] : x['value'];
                        x['value'] = convertValue;
                        if (x['value'].length > 0) {
                            const dataObject = x['value'][0];
                            if (MasterDataCollection.NestedFormConfig && MasterDataCollection.NestedFormConfig.EditFormConfig) {
                                MasterDataCollection.NestedFormConfig.EditFormConfig.forEach((SubForm: any) => {
                                    if ((SubForm.Type === 'Grid' || SubForm.Type === 'ListingGrid') && SubForm.OdataKey !== 'ProductItemDetails') {
                                        const ViewModelName = SubForm.hasOwnProperty('CustomOdataKey')
                                            && SubForm.CustomOdataKey ? SubForm.CustomOdataKey : SubForm.OdataKey;
                                        const ChildNavigationProperty = AllViewModelEntity
                                            .filter((view: any) => view['$']['Name'] === ViewModelName)[0];
                                        if (SubForm.Name === 'SupplierBids') {
                                            if (x['value'][0].SupplierBids && x['value'][0].SupplierBids.length > 0) {
                                                x['value'][0].SupplierBids.forEach((supBids: any) => {
                                                    if (supBids.ingredientDetails) {
                                                        supBids.Units = supBids.ingredientDetails.Units;
                                                        supBids.ingredientDetails = supBids.Ingredients;
                                                    }
                                                });
                                            }
                                        }
                                        SubForm.NestedGridInfo = AssignGridData(
                                            x['value'], SubForm, MasterDataCollection, translateService, masterManagementService,
                                            ChildNavigationProperty, httpService, ViewModelEntity
                                        );
                                        if (SubForm.OdataKey === 'IngredientsBarCodes') {
                                            const IngredientBarcode = dataObject.Barcode;
                                            if (SubForm.NestedGridInfo.GridData.length > 0) {
                                                SubForm.NestedGridInfo.GridData.forEach(element => {
                                                    if (element.UnitTypeId === 1 && element.Barcode === IngredientBarcode) {
                                                        element.issystem = true;
                                                    } else {
                                                        element.issystem = false;
                                                    }
                                                });
                                            } else {
                                                SubForm.NestedGridInfo.GridData.forEach(element => {
                                                    element.issystem = false;
                                                });
                                            }
                                        }
                                        SubForm.NestedGridData.GridData = SubForm.NestedGridInfo.GridData;
                                    } else if (SubForm.OdataKey === 'ProductItemDetails') {
                                        SubForm.LinkedItems.LinkItemGridConfig.GridHeaderDefination.map(values => {
                                            const text = SubForm.Name + '.' + values.field;
                                            translateService.get(text).subscribe(res => {
                                                values.headerName = res;
                                            });
                                            const entityName = ViewModelEntity[0]['$']['Name'].toString();
                                            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                                            values.headerName = ProductDataRemap.FieldDisplayNameEngine(
                                                productremapJson, entityName, values.headerName, entityName + SubForm.Name + `-${values.field}`);
                                            if (
                                                values.field === 'icon' &&
                                                values.hasOwnProperty('categoryIcon') &&
                                                values.categoryIcon.EnableCategoryIcon
                                              ) {
                                                values.cellRenderer = function (param: any) {
                                                  let a = `<div class='grid-icon'>`;
                                                  const IconObject = CategoryIcons.categoryIconName(param.data);
                                                  if (IconObject !== null && IconObject !== undefined && IconObject) {
                                                    a += `<i class='${IconObject.IconName}' title='${IconObject.IconToolTip}'></i>`;
                                                  }
                                                  a += `</div>`;
                                                  return a;
                                                };
                                                values.minWidth = '10';
                                            }
                                        });

                                        SubForm.LinkedItems.LinkItemGridConfig.GridData =
                                            assignProductItemDetails(dataObject[SubForm.OdataKey], MasterDataCollection);
                                        if (MasterDataCollection.FormConfig.NavigationCollection.filter(yx => yx.odataKey
                                            === SubForm.OdataKey).length > 0) {
                                            const index = MasterDataCollection.FormConfig.NavigationCollection.
                                                findIndex(z => z.odataKey === SubForm.OdataKey);
                                            MasterDataCollection.FormConfig.NavigationCollection[index].ModelCollection =
                                                SubForm.LinkedItems.LinkItemGridConfig.GridData;
                                        }
                                    }
                                });
                                resolve(MasterDataCollection.NestedFormConfig);
                            }
                        }
                    });
                }
            });
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

/**
    *@method GetMasterJsonData()
    *@params jsonData and odatakey MasterData['default']
    *@description getjsonresponseform the data
    * */
function GetMasterJsonData(MasterJson: MasterInventoryManagement[], key: string): MasterInventoryManagement {
    try {
        let masterJsonData = [];
        const ReturnMasterData = MasterJson.filter((MasterDatav: MasterInventoryManagement) => MasterDatav.Key === key);
        if (ReturnMasterData && ReturnMasterData.length > 0) {
            masterJsonData = ReturnMasterData;
        }
        return masterJsonData[0];
    } catch (error) {
        console.error(error);
    }
}


function NavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement, AllViewModelEntity: any,
    ParentEnitityName: string) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                    const navigationName = element['$'].Name;
                    const referentialRelationId = element['ReferentialConstraint'] && element['ReferentialConstraint'][0]['$'].Property
                        ? element['ReferentialConstraint'][0]['$'].Property : '';
                    if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                        const properityIndex = ViewModelEntity[0]['Property']
                            .findIndex(x => x['$'].Name === referentialRelationId);
                        if (properityIndex > -1) {
                            let expandQuery = '($select=id,name)';
                            if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey: GridCustomColExpand) => {
                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand && GridCustomExpandKey.IsCustomExpand
                                        && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                        expandQuery = `($select=id,${expandCode})`;
                                    }
                                });
                            }
                            const properityString = `${navigationName}` + `${expandQuery}`;
                            navigationPrperity.push(properityString);
                        } else {
                            // Added for supplier supplier bids name change
                            const properityString = navigationName === 'SupplierBids' ? 'IngredientsBids' : navigationName;
                            if (MasterDataCollection.NestedFormConfig.EditFormConfig.findIndex(x => x.OdataKey === navigationName) > -1) {
                                const ChildNavigation = [];
                                if (AllViewModelEntity.filter(view => view['$']['Name'] === properityString).length > 0) {
                                    const ChildNavigationProperty = AllViewModelEntity
                                        .filter(view => view['$']['Name'] === properityString)[0];
                                    ChildNavigationProperty['NavigationProperty'].forEach(elements => {
                                        if (elements['$'].Name !== ParentEnitityName) {
                                            let ChildNavigationPropertys = `${elements['$'].Name}($select=id,name)`;
                                            if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                                MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey:
                                                        GridCustomColExpand) => {
                                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand &&
                                                        GridCustomExpandKey.IsCustomExpand
                                                        && GridCustomExpandKey.PrimaryExpand === elements['$'].Name) {
                                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                                        ChildNavigationPropertys = `${elements['$'].Name}($select=id,${expandCode})`;
                                                    }
                                                });
                                            }
                                            ChildNavigation.push(ChildNavigationPropertys);
                                        }
                                    });
                                }
                                const data = navigationName + '($expand=' + ChildNavigation.join() + ')';
                                // properityString = properityString + '($expand=' + ChildNavigation.join() + ')';
                                navigationPrperity.push(data);
                            }
                        }
                    }
                });
                return navigationPrperity;
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function AssignGridData(
    dataObject: any,
    SubForm: any,
    MasterDataCollection: any,
    translateService: TranslateService,
    masterManagementService: MasterManagementService,
    ChildNavigationProperty: any,
    httpService: HttpService, ViewModelEntity: any): any {
    try {
        if (ViewModelEntity[0]['$']['Name'] === 'Suppliers' &&
             SubForm.OdataKey === 'SupplierBids') {
                dataObject.forEach((el: any) => {
                    if (el.hasOwnProperty('SupplierBids') && el.SupplierBids.length > 0) {
                        el.SupplierBids.forEach((sup: any) => {
                            if (sup.hasOwnProperty('Ingredients') && sup.Ingredients) {
                                sup.LinkTypeId = sup.Ingredients.LinkTypeId;
                                sup.DivisionId = sup.Ingredients.DivisionId;
                            }
                        });
                    }
                });
            }
        const entityName = ViewModelEntity[0]['$']['Name'].toString();
        const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
        if (SubForm.OdataKey === 'IngredientsBids') {
            if (dataObject[0][SubForm.OdataKey] && dataObject[0][SubForm.OdataKey].length > 0) {
                dataObject[0][SubForm.OdataKey].forEach(rowInfo => {
                    rowInfo.IngredientBidRegions = dataObject[0].IngredientBidRegions ?
                    dataObject[0].IngredientBidRegions.filter(x => x.IngredientBidId === rowInfo.Id) : [];
                });
            }
        }
        if (SubForm.OdataKey === 'IngredientsLocations') {
            dataObject[0][SubForm.OdataKey]?.forEach(rowInfo => {
                rowInfo.Locations = rowInfo.Name;
            });
        }
        const gridData = {} as GridConfig;
        gridData.GridData = assignRowData(dataObject[0][SubForm.OdataKey], ChildNavigationProperty,
                SubForm.NestedGridData.GridHeaderDefination);
        if (SubForm.OdataKey !== 'SupplierBids' && SubForm.OdataKey !== 'IngredientsBids'
            && SubForm.OdataKey !== 'IngredientsBarCodes') {
            gridData.GridData = gridData.GridData.filter(x => x.IsActive === true);
        }
        if (MasterDataCollection.FormConfig.NavigationCollection.filter(x => x.odataKey === SubForm.OdataKey).length > 0) {
            const selectedData = [];
            // gridData.GridData.forEach(rowdata => {
            //     if (rowdata.IsActive) {
            //         const dataObjects = {};
            //         SubForm.data[0].NestedData.forEach(data => {
            //             dataObjects[data.OdataKey] = rowdata[data.OdataKey];
            //         });
            //         selectedData.push(dataObjects);
            //     }
            // });
            // gridData.GridData = gridData.GridData.filter( x => x.IsActive === true);
            const index = MasterDataCollection.FormConfig.NavigationCollection.findIndex(x => x.odataKey === SubForm.OdataKey);
            MasterDataCollection.FormConfig.NavigationCollection[index].ModelCollection = gridData.GridData;
            // gridData.GridData = selectedData;
        }
        if (SubForm.data) {
            SubForm.data.forEach(data => {
                data.NestedData.forEach(formdata => {
                    if (formdata.Calculation) {
                        gridData.GridData.forEach(rowData => {
                            const returnValue = calculationOperation(formdata.Calculation, rowData);
                            rowData[formdata.OdataKey] = returnValue;
                        });
                    }
                });
            });
        }
        
        if (SubForm.OdataKey === 'IngredientsBids' || SubForm.OdataKey === 'SupplierBids') {
            gridData.GridData.forEach(rowInfo => {
                let TypeName: string = UnitTypeName.PurchaseUnit;
                let UnitType = rowInfo.IngredientBidsUnits;
                if ( rowInfo.UnitTypeId !== null ) {
                    switch (rowInfo.UnitTypeId) {
                        case 1 : TypeName = UnitTypeName.BaseUnit; break;
                        case 2 : TypeName = UnitTypeName.PurchaseUnit; break;
                        case 3 : TypeName = UnitTypeName.TransactionUnit; break;
                        case 4 : TypeName = UnitTypeName.RecipeUnit; break;
                    }
                    UnitType = rowInfo.IngredientBidsUnits + ` (${TypeName})`;
                }
              
                // const UnitType = rowInfo.IngredientBidsUnits + ` (${TypeName})`;
                // const UnitType = rowInfo.IngredientBidsUnits;
                rowInfo.IngredientBidsUnits = UnitType;
            });
        }
        const actionRenderer = getActionRendererColumn(SubForm.NestedGridData);
        let headerDefs = [];
        if (SubForm.NestedGridData) {
            headerDefs = SubForm.NestedGridData.GridHeaderDefination.map(values => {
                const text = SubForm.Name + '.' + values.field;
                translateService.get(text).subscribe(res => {
                    values.headerName = res;
                });
                values.suppressMovable = true; // Added for Bug Fix 175191
                values.headerName = ProductDataRemap.FieldDisplayNameEngine(
                    productremapJson, entityName, values.headerName, entityName + SubForm.Name + `-${values.field}`);
                if (values.type === 'dateColumn') {
                    values.valueFormatter = Formaters.dateformater;
                } else if (values.columnType === 'decimal') {
                    values.valueFormatter = Formaters.decimalformater;
                } else if (values.columnType === 'currency') {
                    values.valueFormatter = Formaters.currencyFormater;
                }
                if (values.cellRenderer !== 'actionRenderer') {
                    values.resizable = true;
                    values.minWidth = '75';
                    values.tooltipField = values.field;
                }
                if (values.field === 'icon' &&
                values.hasOwnProperty('categoryIcon') && values.categoryIcon.EnableCategoryIcon) {
                    values.cellRenderer = function (params: any) {
                            let a = `<div class='grid-icon'>`;
                            const IconObject = CategoryIcons.categoryIconName(params.data);
                            if (IconObject !== null && IconObject !== undefined &&
                                IconObject) {
                                    a += `<i class='${IconObject.IconName}' title='${IconObject.IconToolTip}'></i>`;
                            }
                            a += `</div>`;
                            return a;
                        };
                    values.minWidth = '10';
                    }
                const columnHide = ProductDataRemap.FieldVisibleRemapEngine(
                    productremapJson, entityName, entityName + SubForm.Name + `-${values.field}`, values.hide);
                values.hide = !columnHide;
                // if (values.hasOwnProperty('cellEditor') && (values.cellEditor === 'autocomplete' || values.cellEditor === 'multiSelectAutoComplete')) {
                //     values.valueFormatter = (params: any, fromGridSearch = false) => {
                //         if (params.searchParams && fromGridSearch) {
                //             const ElementName = 'Name';
                //             const filter = 'contains(cast(' + ElementName + ',Edm.String),\'' + params.searchParams + '\')';
                //             const GetDataFilter = encodeURIComponent(`${filter}`).replace(/[!'()*]/g, function (c) {
                //                 return '%' + c.charCodeAt(0).toString(16);
                //             });
                //             const Supplierparams: QueryParams = {
                //                 count: true,
                //                 top: InfiniteScrollItemCount,
                //                 skip: 0,
                //                 expand: '',
                //                 select: '',
                //                 filter: '',
                //                 orderby: '',
                //                 isactive: true,
                //                 search: '',
                //                 filterQuery: GetDataFilter,
                //                 searchtext: ''
                //             };
                //             const GetDataByIdUrl = `/inventory/api/${params.colDef.field}${formQueryParams(Supplierparams)}`;
                //             httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                //                 const SupplierData: Array<AutoCompleteData> = [];
                //                 const SuppliersValue = x['value'];
                //                 let supplierList: Array<any> = [];
                //                 if (values.cellEditor !== 'multiSelectAutoComplete') {
                //                     supplierList = SuppliersValue.map((e: any) => {
                //                         const customSullierData = {} as AutoCompleteData;
                //                         customSullierData.Id = e.Id;
                //                         customSullierData.Value = e.Name;
                //                         return customSullierData;
                //                     });
                //                 }
                //                 if (values.cellEditor === 'multiSelectAutoComplete') {
                //                     supplierList = SuppliersValue;
                //                 }
                //                 if (values.cellEditor === 'multiSelectAutoComplete') {
                //                     supplierList.forEach(data => {
                //                         data.Checked = false;
                //                         delete data['@odata.type'];
                //                     });
                //                     if (params.data[params.UpdatedProperty]) {
                //                         params.data[params.UpdatedProperty].forEach(element => {
                //                             const Index = supplierList.findIndex(y => y.Id === element.Id);
                //                             if (Index !== -1) {
                //                                 supplierList[Index].Checked = true;
                //                             }
                //                         });
                //                     }
                //                 }
                //                 params.colDef.cellEditorParams.selectData = [...supplierList];
                //                 params.selectData = [...supplierList];
                //             });
                //         }

                //     };
                // }
                // if (values.hasOwnProperty('cellEditor') && values.cellEditor === 'gridDropDown') {
                //     const GetDataByIdUrl = values.cellEditorParams.enpointUrl;
                //     httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                //         const responseValue = x['value'];
                //         const dropDownArray = [];
                //         const mappedList = responseValue.map((e: any) => {
                //             const checkActiveBool = (e.hasOwnProperty('IsActive')) ? (e.IsActive) ? true : false : true;
                //             if (checkActiveBool) {
                //                 const dropdownData = {} as DropDownDataModel;
                //                 dropdownData.Id = e[values.cellEditorParams.keyId];
                //                 dropdownData.Value = e[values.cellEditorParams.keyName];
                //                 dropDownArray.push(dropdownData);
                //             }
                //         });
                //         values.cellEditorParams.dataList = [...dropDownArray];
                //     });
                // }
                return values;
            });
            if (SubForm.NestedGridData.GridRowActions.ShowActionColumn) {
                headerDefs.push(actionRenderer);
            }
        }
        gridData.GridHeaderDefination = headerDefs;
        gridData.TotalRecords = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions = {} as GridActionsConfig;
        gridData.GridActions.ShowAddButton = SubForm.NestedGridData.ShowAddNew ? SubForm.NestedGridData.ShowAddNew : false;
        gridData.GridActions.ShowRefresh = SubForm.NestedGridData.ShowRefresh ? SubForm.NestedGridData.ShowRefresh : false;
        gridData.GridActions.ShowActiveIndication = SubForm.NestedGridData.ShowActiveIndication ?
            SubForm.NestedGridData.ShowActiveIndication : false;
        gridData.GridActions.EnableToggle = SubForm.NestedGridData.EnableToggle ?
        SubForm.NestedGridData.EnableToggle : false;
        gridData.GridActions.GridTitle = ProductDataRemap.FieldDisplayNameEngine(
            productremapJson, entityName, SubForm.DisplayName, entityName + SubForm.Name + `-${SubForm.DisplayName}`);
        gridData.GridActions.TotalItems = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
        gridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
        gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
        gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
        gridData.GridActions.SearchConfig = {} as GridSearchConfig;
        if (SubForm.NestedGridData.hasOwnProperty('GridSearch') && SubForm.NestedGridData.GridSearch.length > 0) {
            gridData.GridActions.SearchConfig.EnabledSearch = true;
            gridData.GridActions.SearchConfig.SearchType = SubForm.NestedGridData.GridSearch[0]['SearchKey'];
            gridData.GridActions.SearchConfig.SearchPlaceholder = SubForm.NestedGridData.GridSearch[0]['SearchPlaceholderName'];
            gridData.GridActions.SearchConfig.SearchKey = SubForm.NestedGridData.GridSearch[0]['SearchKey'];
            gridData.GridActions.SearchConfig.SearchPlaceholder = ProductDataRemap.FieldDisplayNameEngine(
                productremapJson, entityName, gridData.GridActions.SearchConfig.SearchPlaceholder, entityName + SubForm.Name + `-${gridData.GridActions.SearchConfig.SearchPlaceholder}`);
        }
        if (SubForm.NestedGridData && SubForm.NestedGridData.hasOwnProperty('GridFilter') && SubForm.NestedGridData.GridFilter.length > 0) {
            gridData.GridActions.FilterConfig = {} as GridFilter;
            const gridFilter = {} as GridFilter;
            gridFilter.EnableFilter = true;
            gridFilter.FilterOptions = {} as GridFilterData[];
            gridData.GridActions.FilterConfig = gridFilter;
            gridData.GridActions.FilterConfig.FilterOptions = GridInterface.GridCustomFilter(
                ViewModelEntity, MasterDataCollection, httpService, SubForm.NestedGridData.GridFilter) as unknown as GridFilterData[];
        }
        gridData.GridActions.PaginationConfig = {} as GridPaginationConfig;
        gridData.GridActions.PaginationConfig.EnabledPaginationation = false;
        gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
        gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
        gridData.rowActionClicked = SubForm.NestedGridData.rowActionClicked;
        gridData.addNewButtonClicked = SubForm.NestedGridData.addNewButtonClicked;
        gridData.refreshButtonClicked = SubForm.NestedGridData.refreshButtonClicked;
        gridData.searchTextChanged = SubForm.NestedGridData.searchTextChanged;
        gridData.filterApplied = SubForm.NestedGridData.filterApplied;
        gridData.onCellValueChanged = SubForm.NestedGridData.onCellValueChanged;
        gridData.rowClicked = SubForm.NestedGridData.rowClicked;
        gridData.rowValueChanged = SubForm.NestedGridData.rowValueChanged;
        gridData.toggleChange = SubForm.NestedGridData.toggleChange;
        // if (masterManagementService && masterManagementService.cloneGridData) {
        //     masterManagementService.cloneGridData.forEach(element => {
        //         if (element.Name === SubForm.Name) {
        //             element.NestedGridInfo.GridData = _.clone(gridData.GridData);
        //         }
        //     });
        // }
        return gridData;
    } catch (error) {
        console.error('Error occurred in AssignGridData', error);
    }
}


function getActionRendererColumn(MasterDataCollection: GridConfiguration) {
    if (MasterDataCollection.GridRowActions) {
        const actionRenderer: GridActionRender = {
            headerName: MasterDataCollection.GridRowActions.HeaderName,
            cellRenderer: MasterDataCollection.GridRowActions.CellRenderer,
            suppressMovable: true,
            hide: false,
            editable: false,
            field: '',
            showIcon: true,
            showButton: false,
            pinned: 'right',
            width: '120',
            Edit: MasterDataCollection.GridRowActions.Edit,
            Delete: MasterDataCollection.GridRowActions.Delete,
            Deactivate: MasterDataCollection.GridRowActions.Deactivate,
            Activate: MasterDataCollection.GridRowActions.Activate,
            Copy: MasterDataCollection.GridRowActions.Copy,
            Bidhistory: MasterDataCollection.GridRowActions.Bidhistory,
            UnlinkBids: MasterDataCollection.GridRowActions.UnlinkBids,
            Remove: MasterDataCollection.GridRowActions.Remove
        };
        return actionRenderer;
    }
}

function assignRowData(ResponseData, ViewModelEntity, GridHeaderDefinition?: Array<GridHeaderConfig>) {
    try {
        if (ResponseData) {
            ResponseData.forEach((dataobject, index) => {
                dataobject['RowDataId'] = index + 1;
                for (const prop in dataobject) {
                    if (prop) {
                        // dataobject[prop] = Formater(prop, dataobject[prop], null, ViewModelEntity);
                        if (dataobject[prop] && dataobject[prop] !== null) {
                            const propType = typeof dataobject[prop];
                            const isArrayprop = Array.isArray(dataobject[prop]);
                            if (propType === 'object' && !isArrayprop) {
                                const objProperity = dataobject[prop];
                                assignNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                            } else if (isArrayprop) {
                                assignGridNavigationArrayProperty(prop, dataobject[prop], dataobject, ViewModelEntity,
                                    GridHeaderDefinition);
                            }
                        }
                    }
                }
            });
            return ResponseData;
        } else {
            return [];
        }
    } catch (error) {
        console.log('error occured assignRowData', error);
    }
}


function assignGridNavigationArrayProperty (ProperityName: any, ProperirtyObject: Array<any>,
    PrimaryProperity: object, ViewModelEntityColl: any, GridHeaderDefinition?: Array<GridHeaderConfig>) {
try {
    const entityObject = PrimaryProperity;
    const MultiArrayGridHeader = GridHeaderDefinition.filter(x => x['cellEditorParams'] &&
        x['cellEditorParams'].UpdatedProperty === ProperityName);
    if (MultiArrayGridHeader.length > 0) {
        const GridViewData = [];
        const GridEditData = [];
        const GridPropertyName = MultiArrayGridHeader[0].field;
        const GridEditPropertyName = MultiArrayGridHeader[0]['cellEditorParams']['EntityPropertyName'];
        const GridUpdatePropertyName = MultiArrayGridHeader[0]['cellEditorParams']['UpdatedProperty'];
        ProperirtyObject.forEach(data => {
            let GridValue: any;
            if (data[GridEditPropertyName]) {
                GridEditData.push(data[GridEditPropertyName]);
            }
            if (MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue) {
                MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue.forEach((element, index) => {
                    data = data[element];
                    if (index === (MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue.length - 1)) {
                        GridValue = data;
                    }
                });
                GridViewData.push(GridValue);
            } else {
                GridViewData.push(data);
            }
        });
        entityObject[ProperityName] = GridEditData;
        entityObject[GridPropertyName] = GridViewData.join(', ');
        entityObject[GridUpdatePropertyName] = GridEditData.map(x => x.Id);
    } else {
        const arrayOfObject = entityObject[ProperityName].every(z => typeof(z) === 'object');
        if (!arrayOfObject) {
            entityObject[ProperityName] = ProperirtyObject.join(', ');
        }        
    }
    return entityObject;
} catch (error) {
    console.error('Error occurred in assignGridNavigationArrayProperty', error);
}
}

function assignNavigationProperity(ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl.hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl['NavigationProperty'].length > 0) {
        ViewModelEntityColl['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'].toLowerCase().toString();
            if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
                //  if (element['ReferentialConstraint']) {
                //     const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toString();
                entityObject[ProperityName] = ProperirtyObject['Name'];
                //  }
            }
        });
    }
    return entityObject;
}

function calculationOperation(calcField: CalcultionField, FormData: any) {
    try {
        let returnValue = 0;
        let FirstOperand = isNaN(parseFloat(FormData[calcField.OperandFields[0].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[0].OdataKey]);
        let SecondOperand = isNaN(parseFloat(FormData[calcField.OperandFields[1].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[1].OdataKey]);
        if (isNaN(FirstOperand)) {
            FirstOperand = 0;
        }
        if (isNaN(SecondOperand)) {
            SecondOperand = 0;
        }
        switch (calcField.OperationType) {
            case 'Add':
                returnValue = FirstOperand + SecondOperand;
                break;
            case 'Multiply':
                returnValue = FirstOperand * SecondOperand;
                break;
            case 'Divide':
                if (FirstOperand !== 0 && SecondOperand !== 0) {
                    returnValue = FirstOperand / SecondOperand;
                    if (isNaN(returnValue)) {
                        returnValue = 0;
                    }
                }
                break;
        }
        return returnValue;
    } catch (error) {
        console.error('Error occurred in calculationOperation', error);
    }
}

function assignProductItemDetails(responseData, MasterDataCollection: MasterInventoryManagement) {
    try {
        responseData.forEach(element => {
            element.UnitCode = element.ProductUnits ? element.ProductUnits.Name + ` (${element.ProductUnits.Code})` : '';
            element.UnitCost = element.Price / element.Qty;
            element.Name = element.ItemName;
            const baseUnitCost: Array<any> = element['LinkedItemUnits'] && element['LinkedItemUnits'].length > 0 ?
                element['LinkedItemUnits'].filter((e: any) => (e.UnitTypeId === 1 || e.UnitTypeId === 5)) : [];
            element['BaseUnitCost'] = (baseUnitCost.length > 0) ? (baseUnitCost[0].hasOwnProperty('CostPerUnit')) ?
                baseUnitCost[0].CostPerUnit : baseUnitCost[0].UnitCost : 0;
            if (MasterDataCollection.FormConfig.FlowMode === 'Create') {
                element.Id = 0;
            }
        });
        return responseData;
    } catch (error) {
        console.error('error occured in assignProductItemDetails', error);
    }
}
