import { MasterInventoryManagement } from '../master.interface';
import { GridModelConfiguration } from './grid-config.interface';

export const GridOdataKey: GridModelConfiguration = (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement): string => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection ) {
            return  ViewModelEntity[0]['$']['Name'];
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};