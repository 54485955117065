import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { BreakType } from '../../../shared/business/view-settings.modals';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-break-type',
  templateUrl: './break-type.component.html',
  styleUrls: ['./break-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreakTypeComponent implements OnInit {
  breakTypeForm: UntypedFormGroup;
  blnReadOnly: boolean;
  colorSelected = '#000000';
  clickbutton: any;
  includeUtilizationCheck = false;
  captions: any;
  isUserAuthorized = true;
  isViewOnly = true;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public fb: UntypedFormBuilder,
    private utils: SpaUtilities,
    private vsBusiness: ViewSettingClientBusiness,
    public localization: SpaLocalization,
    private http: HttpServiceCall,
    public dialogRef: MatDialogRef<SettingDialogPopupComponent>,
    private BP: BreakPointAccess, @Inject(MAT_DIALOG_DATA) public data) {
      this.floatLabel = this.localization.setFloatLabel;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingBreakType]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingBreakType);
    if (this.isViewOnly) {
      this.utils.disableControls(this.breakTypeForm);
    }
  }
  changeColor($event: any): void {
    this.colorSelected = $event.color.hex;
    this.breakTypeForm.markAsDirty();
    this.enableSave();
  }
  enableSave(){
    this.saveButton.disabledproperty = !(this.breakTypeForm.valid && this.breakTypeForm.dirty);
    this.saveButton = { ...this.saveButton };
  }

  close(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  saveInfo(): void {
    setTimeout(() => {
      this.saveButton.disabledproperty = true;
    },1);
    if (this.data.popupConfig.operation == "create") {
      this.createBreakType();
    } else if (this.data.popupConfig.operation == "edit") {
      this.updateBreakType();
    }
  }

  ngOnInit() {
    this.captions = this.localization.captions.setting;
    // this.clickbutton = this.captions.SAVE;
    this.breakTypeForm = this.fb.group({
      breakTypeCode: ['', Validators.required],
      breakTypeDesc: ['', [Validators.required, EmptyValueValidator]],
      isUtilized: [''],
      breakTypeColor: [''],
    });
    this.saveButton = {
      type: 'primary',
      label: this.data.popupConfig.operation == "edit" ? this.captions.UPDATE : this.captions.SAVE,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.CANCEL,
    }
    this.blnReadOnly = false;
    if (this.data.popupConfig.operation == "edit") {
      this.breakTypeForm.controls.breakTypeCode.setValue(this.data.datarecord.code);
      this.breakTypeForm.controls.breakTypeDesc.setValue(this.data.datarecord.description);
      this.breakTypeForm.controls.isUtilized.setValue(this.data.datarecord.isUtilized);
      this.includeUtilizationCheck = this.data.datarecord.isUtilized;
      this.colorSelected = this.data.datarecord.color;
      // this.clickbutton = this.captions.UPDATE;
      this.blnReadOnly = true;
    }
    this.vsBusiness.activeFormGroup = this.breakTypeForm;
    this.ValidateBreakPoint();
    this.breakTypeForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.enableSave();
    });
  }

  createBreakType(): void {
    const breakTypeObj: BreakType = {
      Code: this.breakTypeForm.controls.breakTypeCode.value,
      Description: this.breakTypeForm.controls.breakTypeDesc.value,
      Color: this.colorSelected,
      IsUtilized: this.breakTypeForm.controls.isUtilized.value ? true : false
    };
    try{
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
  
        error: this.utils.errorCallback.bind(this),
        callDesc: 'CreateBreakType',
        method: HttpMethod.Post,
        body: breakTypeObj,
        showError: true,
        extraParams: []
      });
    }
    finally{
      this.saveButton.disabledproperty = false;
    }
  }

  updateBreakType(): void {
    let patchJson = [];
    patchJson = [{ op: "replace", path: "/color", value: this.colorSelected },
    { op: "replace", path: "/description", value: this.breakTypeForm.controls.breakTypeDesc.value },
    { op: "replace", path: "/code", value: this.breakTypeForm.controls.breakTypeCode.value },
    { op: "replace", path: "/isUtilized", value: this.breakTypeForm.controls.isUtilized.value ? true : false }];
    try { 
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "UpdateBreakType",
      method: HttpMethod.Patch,
      uriParams: { id: this.data.datarecord.id },
      body: patchJson,
      showError: true,
      extraParams: []
    });
    }
    finally{
      this.saveButton.disabledproperty = false;
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateBreakType" || callDesc == "UpdateBreakType") {
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
  }

}
