import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetailToggleSwitchComponent } from './retail-toggle-switch.component';
import { UiSwitchModule } from 'ngx-ui-switch';



@NgModule({
  declarations: [RetailToggleSwitchComponent],
  imports: [
    CommonModule,
    UiSwitchModule
  ],
  exports: [RetailToggleSwitchComponent],
})
export class RetailToggleSwitchModule { }
