import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonControllersRoutes } from '../../communication/common-route';
import { TenantManagementCommunication } from '../../communication/services/tenantmanagement-communication-service';
import { PMSRequestInfo, TokenLoginModel, PMSAction } from '../pms-request.model';
import { AppService } from '../../app-service';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { DefaultGUID } from '../../shared/shared/globalsContant';
import { CartUtilities } from '../../components/menu/vcart/cart.utilities';

@Injectable({
  providedIn: 'root'
})
export class PmsLoginResolver implements Resolve<any> {
  constructor(private router: Router, private loginService: TenantManagementCommunication, private cartUtils: CartUtilities, private appService: AppService, private utilities: CommonUtilities) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<TokenLoginModel> {
    let pmsLoginData: Promise<TokenLoginModel>;
    const tryParseParam = (date: string) => date ? date : undefined;
    try {
      var activityId = tryParseParam(route.queryParams['activityid']);
       const isEmbed = route.queryParams['embed'] == 1;
            if (isEmbed){
                this.cartUtils.setEmbedFlag();
                let appURL = decodeURIComponent(route.queryParams['appurl']);
              sessionStorage.setItem('appurl',appURL);
              }
      const routeValues: PMSRequestInfo = {
        stayFromDate: route.queryParams['stayfromdate'] ? tryParseParam(route.queryParams['stayfromdate']) : undefined,
          stayToDate: route.queryParams['staytodate'] ? tryParseParam(route.queryParams['staytodate']) : undefined,
          activityDate: route.queryParams['activitydate'] ? tryParseParam(route.queryParams['activitydate']) : undefined,
       gpsId: (route.queryParams['action'] && route.queryParams['action'] != PMSAction.DashBoard)  && !isEmbed ? this.parseParam('gpsid', route) : '',
        activityId: activityId,
        action: Number(this.parseParam('action', route)),
        userId: this.parseParam('userid', route),
        tenantCode: this.parseParam('tenantCode', route),
        token: this.parseParam('token', route),
        propertyCode: this.parseParam('propertycode', route),
        isEdit: activityId && activityId != "" && activityId != DefaultGUID ? true : false
      };
      this.utilities.setSession('PMSRequestInfo', JSON.stringify(routeValues));
      this.utilities.setSession("_jwt", routeValues.token);

      pmsLoginData = this.validateUserByToken(pmsLoginData, routeValues);



    } catch (error) {
      pmsLoginData = Promise.resolve(undefined);
    }

    return pmsLoginData;
  }

  private validateUserByToken(pmsLoginData: Promise<TokenLoginModel>, routeValues: PMSRequestInfo): Promise<TokenLoginModel> {
    pmsLoginData = this.loginService.putPromise<TokenLoginModel>({
      route: CommonControllersRoutes.ValidateLoginByToken,
      body: {
        tenantCode: routeValues.tenantCode,
        productId: this.appService.productId,
        userId: routeValues.userId,
        idToken: routeValues.token,
        propertyCode: routeValues.propertyCode
      }
    }, false)
      .then(
        (resp: TokenLoginModel) => {
          resp.loginStatus = true;
          sessionStorage.setItem('loginDuration', resp.loginDuration.toString());
          resp = this.validatePropertyAccess(resp, routeValues.propertyCode);
          return resp;
        }
      )
      .catch(s => {
        const code = s.error && s.error.errorCode;
        return <TokenLoginModel>{
          loginStatus: false,
          errorCode: code ? code : s.status
        };
      });
    return pmsLoginData;
  }


  private validatePropertyAccess(response: TokenLoginModel, propertyCode: string): TokenLoginModel {
    const isPropertyValid = response.userProperties.some(p => p.propertyCode == propertyCode);
    const loginResponse: TokenLoginModel = response;
    if (!isPropertyValid) {
      response.loginStatus = false;
      response.errorCode = 5010;
    }
    return loginResponse;
  }


  private parseParam<T>(key, route: ActivatedRouteSnapshot): T {
    const value = route.queryParams[key];
    if (!value) {
      alert(`Not getting ${key} from PMS request`);  // need to comment
      throw "Error in parsing External Request";
    }
    return value as T;
  }
}
