import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { CascadeDropDownOptions } from '../business/view-settings.modals';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-cascading-custom-dropdown',
  templateUrl: './cascading-custom-dropdown.component.html',
  styleUrls: ['./cascading-custom-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class CascadingCustomDropdownComponent {

  cascadeFormGroup: UntypedFormGroup;
  items: UntypedFormArray;
  selectedItems: (string | number)[];
  addedItems: CascadeDropDownOptions[];
  ExtensionInput: boolean;
  isInputMandatory: boolean;
  dropdownPlaceHolder: string;
  dropdownError: string;
  inputPlaceholer: string;
  inputError: string;
  missingError: string;
  cascadeLabel: string;
  displayProperty: string;
  defaultInput: string | number;
  inputType: string;
  availableDefaultValue: any;
  availableDropdownPlaceHolder: any;
  floatLabel:string;

  @Input()
  set CascadeData(value) {
    this.cascadeFormGroup = this.fb.group({
      DropDownArray: this.fb.array([])
    })
    this.addedItems = value.addedItems;
    this.ExtensionInput = value.extendedInput;
    this.isInputMandatory = value.inputRequired;
    this.cascadeLabel = value.label;
    this.displayProperty = value.displayProperty;
    this.dropdownPlaceHolder = value.placeholder;
    this.availableDropdownPlaceHolder = value.availablePlaceholder;
    this.dropdownError = value.dropdownError;
    this.selectedItems = this.addedItems.map((obj) => obj.selectedValue && obj.selectedValue.id);
    this.addedItems.forEach((opt) => {
      this.AddItem(opt.selectedValue, opt.value);
    })
  }
  @Input() DropdownData;
  @Input()
  set InputData(value){
    if(value){
    this.inputPlaceholer = value.inputPlaceholder;
    this.inputError = value.inputError;
    this.defaultInput = value.inputDefaultValue;
    this.availableDefaultValue = value.availableDefaultValue;
    this.inputType = value.inputType;
    this.missingError = value.missingError;
    }
  }

  @Output() optionChange = new EventEmitter();

  constructor(private fb: UntypedFormBuilder,public localization:SpaLocalization) {
    this.floatLabel = this.localization.setFloatLabel;
    this.cascadeFormGroup = this.fb.group({
      DropDownArray: this.fb.array([])
    })
  }

  public get IsValid(): boolean {
    return this.cascadeFormGroup.valid;
  }

  selectionChange(e, i) {
    if (e.value) {
      let thisSelected = e.value;
      this.selectedItems[i] = thisSelected.id;
      if(this.ExtensionInput && this.defaultInput ){
        let extendedInput = this.items.at(i).get('extInput');
        extendedInput.setValidators([Validators.required]);
        if(e.value!='none'){
        extendedInput.patchValue(this.defaultInput);
        extendedInput.enable();
        }
        else{
          extendedInput.reset();
          extendedInput.clearValidators();
          extendedInput.setErrors(null);
          extendedInput.disable();
        }
      }
    }
    this.addedItems.forEach((x, idx) => {
      let dropdownValue = this.items.at(idx).get('dropdown').value;
      let inputValue = this.items.at(idx).get('extInput').value;
      x.options = this.DropdownData.filter(y => {
        return y.id == this.selectedItems[idx] || this.selectedItems.indexOf(y.id) == -1;
      })
      x.selectedValue = dropdownValue? dropdownValue: '';
      x.value = inputValue? inputValue: '';
    })
    this.optionChange.emit({addedItems: this.addedItems, DropDownControls: this.cascadeFormGroup });
  }

  addremove(type, index) {
    if (type == 'add') {
      this.addedItems.push({ 'options': this.DropdownData, 'selectedValue': '', value: 0 });
      this.selectedItems.push(-1);
      this.AddItem('', '');
    } else if (type == 'remove') {
      this.addedItems.splice(index, 1);
      this.selectedItems.splice(index, 1);
      this.items = this.cascadeFormGroup.get('DropDownArray') as UntypedFormArray;
      this.items.removeAt(index);
    }
    this.selectionChange('', '');
  }

  AddItem(value, inputValue) {
    this.items = this.cascadeFormGroup.get('DropDownArray') as UntypedFormArray;
    let group = this.fb.group({
      dropdown: value
    });
    if (this.ExtensionInput) {
      let control = new UntypedFormControl(inputValue);
      if(value){
        control.setValidators([Validators.required]);
      }else{
        control.disable();
      }

      group.addControl('extInput', control);
    }
    this.items.push(group);
  }

  updateInput(obj, event, i) {
   if ((this.cascadeFormGroup.controls.DropDownArray['controls'][i].controls.extInput.value) > (this.cascadeFormGroup.controls.DropDownArray['controls'][i].controls.dropdown.value.quantity)){
         this.cascadeFormGroup.controls.DropDownArray['controls'][i].controls.extInput.setErrors({customError: true})
   } else {
         if (this.cascadeFormGroup.controls.DropDownArray['controls'][i].controls.extInput.value != ''){
          if (this.cascadeFormGroup.controls.DropDownArray['controls'][i].controls.extInput.hasError('customError')){
            this.cascadeFormGroup.controls.DropDownArray['controls'][i].controls.extInput.setErrors(null)
       }
     }
   }
    obj.value = event.target.value;
    this.optionChange.emit({addedItems: this.addedItems, DropDownControls: this.cascadeFormGroup });
  }
}
