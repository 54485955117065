import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
@Component({
  selector: 'app-alert-message-popup',
  templateUrl: './alert-message-popup.component.html',
  styleUrls: ['./alert-message-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertMessagePopupComponent implements OnInit, AfterViewInit, OnDestroy {
  noButton = false;
  IDTHiddenField: string;
  dataform: UntypedFormGroup;
  @ViewChild('cardfield', { static: false }) cardfield: ElementRef;
  inputSubscription$: any;
  cardEntryModeGif: string;
  spinnerClass: string;
  progressMsg = "";
  progressPercentage = 0;
  floatLabel: any;
  captions: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<AlertMessagePopupComponent>, private localization: Localization) {
    this.noButton = data.noButton ? data.noButton : false;
    this.SubscribeDataUpdates();
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    this.dataform = new UntypedFormGroup({
      carddata: new UntypedFormControl('')
    });
    this.inputSubscription$ = this.dataform.valueChanges.pipe(debounceTime(1000))
      .subscribe(val => {
        console.log(val);
        this.dialog.close(val.carddata);
      });
    this.cardEntryModeGif = this.data && this.data.isManualEntry ? './assets/images/CardKeyIn.gif' : './assets/images/Cardpayment.gif';
    this.spinnerClass = this.data && this.data.isManualEntry ? 'spinner-image-key-in' : 'spinner-image';
  }

  ngAfterViewInit() {
    if (this.data.isHiddenFieldRequired && this.cardfield) {
      this.cardfield.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.inputSubscription$.unsubscribe();
  }

  DialogClose = (result) => {
    this.dialog.close(result);
  }

  SubscribeDataUpdates(){
    if(this.data && this.data.showProgress){
      this.data.progressPercent.subscribe(data => { this.progressPercentage = data });
      this.data.progressMsg.subscribe(data => { this.progressMsg = data });
    }
  }
}
