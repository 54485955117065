import { HttpServiceCall, HttpMethod } from "../../../shared/service/http-call.service";
import { SpaUtilities } from "../../../shared/utilities/spa-utilities";
import { SpaLocalization } from "../../../core/localization/spa-localization";
import { Host } from "../../../shared/globalsContant";
import { BaseResponse } from "../../../shared/business/shared.modals";
import {  PropertyReceiptModel,ReceiptModel} from "../../../retail/retail.modals";
import { Injectable } from "@angular/core";
import { RetailRoutes } from "src/app/retail/retail-route";
@Injectable()
export class ReceiptConfigurationDataService {
    constructor(private http: HttpServiceCall, private utils: SpaUtilities, private localization: SpaLocalization) { }

    public async getOutletInfo(): Promise<ReceiptModel[]> {
      return this.invokeServiceCall<ReceiptModel[]>(Host.retailManagement, "getReceiptInfo", HttpMethod.Get);
    }

    public async createReceipt(objData): Promise<ReceiptModel[]>
    {
        return this.invokeServiceCall<ReceiptModel[]>(Host.retailManagement, "createReceipt", HttpMethod.Post, objData);
    }

    private async invokeServiceCall<T>(hostName: Host, callDesc: string, methodType: HttpMethod, body?: any, uriParams?: any, extraParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            host: hostName,
            callDesc: callDesc,
            body: body,
            uriParams: uriParams,
            method: methodType
        });
        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;

    }
    
    public async getPropertyReceiptConfig(): Promise<PropertyReceiptModel> {
        return this.invokeServiceCall<PropertyReceiptModel>(Host.retailManagement, RetailRoutes.GetPropertyReceiptConfiguration, HttpMethod.Get);
      }
  
      public async createPropertyConfig(objData): Promise<PropertyReceiptModel> {
        return this.invokeServiceCall<PropertyReceiptModel>(Host.retailManagement, RetailRoutes.CreatePropertyReceiptConfiguration, HttpMethod.Post,objData);
      }
      public async updatePropertyConfig(objData): Promise<PropertyReceiptModel> {
        return this.invokeServiceCall<PropertyReceiptModel>(Host.retailManagement,  RetailRoutes.UpdatePropertyReceiptConfiguration, HttpMethod.Put,objData);
      }
    
    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }
}