export enum FilterTypes {
    Multiple = 'Multiple',
    Equal = 'Equal',
    GreaterThanEqual = 'GreaterThanEqual',
    NotEqual = 'NotEqual',
    InterCoFilter = 'InterCoFilter'
}

export enum HttpMethods {
    Put = 'PuthttpMethod',
    Post = 'PosthttpMethod'
}

export enum TransactionType {
    Save = 4,
    Post = 1
}

export enum ModuleName {
    physicalinventory = 'Physical Inventory',
    receiving = 'Receiving'
}

export enum ButtonKey {
   PiConfirm = 'piconfirm',
   Save = 'save',
   AttachedFiles = 'attachedFiles',
   Cancel = 'cancel',
   Transfer = 'transfer',
   AssignBids = 'assignbids',
   InventoryReset = 'inventoryreset',
   Null = 'null',
   Update = 'update',
   EditParsValue = 'editparsvalue',
   Reset = 'reset',
   Clear = 'clear',
   Import = 'import',
   SaveasDraft = 'SaveasDraft',
   Delete = 'Delete',

   GlobalGridSupplier = 'globalGridSupplier'
}

export enum NonQuantity {
  NLC = 'N_C',
  LP = 'L_P'
}

export enum ImageFormats {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    BMP = 'bmp',
    GIF = 'gif',
    JFIF = 'jfif'
}

export enum DocFormats {
    PDF = 'pdf',
    DOC = 'doc',
    DOCX = 'docx'
}
export enum ExcelFormats {
    CSV = 'csv',
    MSEXCEL = 'vnd.ms-excel',
    SPREADSHEETS = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export enum VideoFormats {
    MP4 = 'mp4'
}

export enum TransactionFrom {
   QueryPage = 'query',
   AttchedFilesPage = 'attachedfiles',
   TransactionItemActionPage = 'transactionitemaction',
   ProcessPo = 'processpo',
   SavePI = 'savepi',
   BackOrderReq = 'transferbackorder'
}

export enum ReqBackOrder {
    NoBackOrder = 1,
    RequisitionBackOrderEnabled = 2,
    CreateNewReqWithBalance = 3
  }

  export enum PoBackOrderOptions {
    NoBackOrderbyType = 1,
    AllowBackOrderbyType = 2,
    BackOrderbyItem = 3
  }

  export enum ZeroQty {
    NoZeroQty = 0,
    AllowZeroQty = 1    
  }