import { Injectable } from "@angular/core";
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { SignatureCaptureService } from "../../signature-capture/signature-capture.service";
import { SigcapLog,SigcapLogSearchRequest,UISigcap,UserInfo,CloseFailedDMPosting } from 'src/app/retail/shop/view-categories/signature-capture/signature-capture.model';

@Injectable()
export class SignatureCaptureBusiness {
    captions: any;
    currencySymbol: string;
  
    constructor( private localization: RetailLocalization,private utils: RetailUtilities,private sigCapService: SignatureCaptureService) {
      this.captions = this.localization.captions.shop;
      this.currencySymbol = this.localization.currencySymbol;
    }
    getHeaderOptions() {
        return  [
          {
            key: 'checked',
            displayNameId: '',
            displayName: '',
            hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
            templateName: ActionTypeEnum.checkbox,
          },
          {
            key: 'ticketNumber',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_ticketNumber ,
            sortingKey: 'ticketNumber',
            sorting: true
          },
          {
            key: 'logDate',
            displayNameId: '',
            displayName: this.captions.date,
            sortingKey: 'logDate',
            sorting: true
          },
          {
            key: 'PropertyDateTimeOfPosting',
            displayNameId: '',
            displayName: this.captions.Time,
            sortingKey: 'PropertyDateTimeOfPosting',
            sorting: true
          },
          {
            key: 'transactionType',
            displayNameId: '',
            displayName: this.captions.lbl_transactionType,
            sortingKey: 'transactionType',
            sorting: true
          },
          {
            key: 'errorMessage',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_errorMessage,
            sortingKey: 'errorMessage',
            sorting: true,
            templateName: ActionTypeEnum.custom
          },
          {
            key: 'user',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_user,
            sortingKey: 'user',
            sorting: true
          },
          {
            key: 'Action',
            displayNameId: '',
            displayName: this.captions.tbl_hdr_Action,
            sortingKey: 'Action',
            templateName: ActionTypeEnum.custom
          },
        ];
    }
    getTableOptions() {
        return {
          actions: [
          ],
          defaultsortingColoumnKey: 'ticketNumber',
          showTotalRecords: false,
          defaultSortOrder: SorTypeEnum.asc,
          columnFreeze: {
            firstColumn: false,
            lastColumn: true
          },
          noDataLabel:this.captions.NoDataFound,
          isDragDisabled: false,
          isHeaderCheckboxAllowed: true,
          checkboxKey: 'checked',
          ignoreSort: true
        };
    }
  public async getTableContent(searchRequest: SigcapLogSearchRequest, userInfo: UserInfo[]) {
    let response = await this.sigCapService.GetFailedDMPosting(searchRequest);
    return this.UiMapper(response, userInfo);
  }

  UiMapper(model: SigcapLog[], userList: UserInfo[],): UISigcap[] {
    const data = model.map(x => {
      return {
        id: x.id,
        transactionId: x.transactionId,
        ticketNumber: Number(x.ticketNumber),
        errorMessage: x.errorMessage ? x.errorMessage : '',
        user: userList && userList.some(u => u.id === x.clerkId) ? userList.find(u => u.id === x.clerkId).userName : '',
        Action: '',
        errorMessagefullView: x.errorInfo ? x.errorInfo : '',
        transactionType: x.transactionType,
        PropertyDateTimeOfPosting: this.localization.LocalizeTime(x.propertyDateTimeOfPosting, true),
        logDate: this.localization.LocalizeShortDate(x.propertyDateTimeOfPosting)
      };
    });
    return data;
  }

  async CloseFailedDMPosting(request: CloseFailedDMPosting) {
    return await this.sigCapService.CloseFailedDMPosting(request);
  }

  RetryDMPosting(request: SigcapLogSearchRequest) {
    return this.sigCapService.RetryDMPosting(request);
  }

  isBatchProcessingPending() {
    return this.sigCapService.isBatchProcessingPending();
  }
}