import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AgInputFieldConfig, ButtonValue, ServerPaginationConfiguration } from '../../Models/ag-models';
import { Localization } from '../../shared/localization/Localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CombineGuestComponent } from './combine-guest/combine-guest.component';
import { dedupeGuestRecordBusiness } from './dedupe-guest-records.business';
import { DDcombineInput, DDtablecontent, UI } from './dedupe-guest-ui-model'

@Component({
  selector: 'app-dedupe-guest-records',
  templateUrl: './dedupe-guest-records.component.html',
  styleUrls: ['./dedupe-guest-records.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DedupeGuestRecordsComponent implements OnInit {

  searchParameters: any[] = [];
  lastNameInput: AgInputFieldConfig;
  firstNameInput: AgInputFieldConfig;
  emailAddressInput: AgInputFieldConfig;
  phoneNumberInput: AgInputFieldConfig;
  postalCodeInput: AgInputFieldConfig;
  captions: any;
  DGRform: UntypedFormGroup;
  tableArray: any[] = [];
  headerOptions: any[] = [];
  filterKeys: any[] = []; ss
  searchguestBTN: ButtonValue;
  combineAllBtn: ButtonValue;
  selectedSearchParams: any;
  isExpanded: boolean = true;
  firstName = [];
  isLoading: boolean = false;
  response: DDtablecontent[];
  SearchCriteria: UI.DeDupeGuestSearchFields;
  guestGroup: Map<string, DDtablecontent[]>;
  combinememberInputs: DDcombineInput;
  primarySelectedData: DDtablecontent;
  formChangedData;
  showcombinemember: boolean = false;
  primarysecondarydedupeguestData: UI.PrimarySecondaryDedupeGuestData[] = [];
  serverPageConfig: ServerPaginationConfiguration;
  totalRecords: any;
  endIndex: number;
  fromIndex: number;
  isexpandAll: boolean;
  @Output() onCombine = new EventEmitter();
  @Output() serverPageEmit = new EventEmitter();
  
  constructor(private _localization: Localization, public _dedupeGuestRecordBusiness: dedupeGuestRecordBusiness, private fb: UntypedFormBuilder, public dialog: MatDialog, private utils: CommonUtilities) {
    this.captions = this._localization.captions;
  }

  ngOnInit(): void {
    this.initalize();
  }

  initalize() {
    this.fromIndex = 0;
    this.endIndex = 5;
    this.guestGroup = new Map<string, DDtablecontent[]>();
    this.DGRform = this.fb.group({
      lastName: '',
      firstName: '',
      email: '',
      phoneNumber: '',
      postalCode: '',
      patronId: ''
    });
    this.lastNameInput = {
      className: 'pl-3',
      placeHolderId: 'lbl_LastName',
      placeHolder: this.captions.lbl_LastName,
      form: this.DGRform,
      formControlName: 'lastName',
      automationId: 'Txt_combineGuestRecords_lastName'
    };
    this.firstNameInput = {
      className: 'pl-3',
      placeHolderId: 'lbl_FirstName',
      placeHolder: this.captions.lbl_FirstName,
      form: this.DGRform,
      formControlName: 'firstName',
      automationId: 'Txt_combineGuestRecords_lastName'
    };
    this.emailAddressInput = {
      className: 'pl-3',
      placeHolderId: 'lbl_emailAddress',
      placeHolder: this.captions.lbl_emailAddress,
      form: this.DGRform,
      formControlName: 'email',
      automationId: 'Txt_combineGuestRecords_lastName'
    };
    this.phoneNumberInput = {
      className: 'pl-3',
      placeHolderId: 'lbl_phone',
      placeHolder: this.captions.lbl_phoneNumber,
      form: this.DGRform,
      formControlName: 'phoneNumber',
      automationId: 'Txt_combineGuestRecords_lastName'
    };
    this.postalCodeInput = {
      className: 'pl-3',
      placeHolderId: 'postalCode',
      placeHolder: this.captions.postalCode,
      form: this.DGRform,
      formControlName: 'postalCode',
      automationId: 'Txt_combineGuestRecords_lastName'
    };
    this.serverPageConfig = {
      initPageSize: 5,
      initFrom: 1,
      initTo: 5,
      initPageIndex: 0
    }

    this.searchParameters = [
      {
        parameters: this.captions.firstName,
        isChecked: true
      },
      {
        parameters: this.captions.lastName,
        isChecked: true
      },
      {
        parameters: this.captions.lbl_emailAddress,
        isChecked: false
      },
      {
        parameters: this.captions.lbl_phone,
        isChecked: false
      },
      {
        parameters: this.captions.postalCode,
        isChecked: false
      },
    ];
    this.searchguestBTN = {
      label: this.captions.btn_search,
      type: 'primary',
      disabledproperty: false
    };
    this.combineAllBtn = {
      label: this.captions.lbl_combineAll,
      type: 'primary',
      disabledproperty: false
    }
    this.headerOptions = [
      {
        'displayName': ''
      },
      {
        'displayName': 'Last Name',
        'formControlName': 'lastName',
        'selectOptions': []
      },
      {
        'displayName': 'First Name',
        'formControlName': 'firstName',
        'selectOptions': []
      },
      {
        'displayName': 'Title',
        'formControlName': 'title',
        'selectOptions': []
      },
      {
        'displayName': 'Address',
        'formControlName': 'address',
        'selectOptions': []
      },
      {
        'displayName': 'Postal Code',
        'formControlName': 'postalCode',
        'selectOptions': []
      },
      {
        'displayName': 'City',
        'formControlName': 'city',
        'selectOptions': []
      },
      {
        'displayName': 'Phone Number',
        'formControlName': 'phoneNumber',
        'selectOptions': []
      },
      {
        'displayName': 'Email Address',
        'formControlName': 'emailAddress',
        'selectOptions': []
      },
      {
        'displayName': 'Mark As',
      },
      {
        'displayName': 'Actions'
      }
    ]

    this.selectedSearchParams = this.searchParameters.filter(x => x.isChecked);
  }

  onParametersClick(eve, idx) {
    this.searchParameters[idx].isChecked = !this.searchParameters[idx].isChecked;
  }


  async guestSearch(eve) {
    this.tableArray = [];
    this.guestGroup.clear();
    this.totalRecords = 0;
    this.utils.ToggleLoader(true);
    this.isLoading = true;
    this.SearchCriteria = {
      firstName: (this.DGRform.get('firstName').value != null) ? this.DGRform.get('firstName').value : "",
      lastName: (this.DGRform.get('lastName').value != null) ? this.DGRform.get('lastName').value : "",
      emailAddress: (this.DGRform.get('email').value != null) ? this.DGRform.get('email').value : "",
      phone: (this.DGRform.get('phoneNumber').value != null) ? this.DGRform.get('phoneNumber').value : "",
      postalCode: (this.DGRform.get('postalCode').value != null) ? this.DGRform.get('postalCode').value : "",
      groupfirstName: this.searchParameters.find(parameter => parameter.parameters === this.captions.firstName).isChecked,
      grouplastName: this.searchParameters.find(parameter => parameter.parameters === this.captions.lastName).isChecked,
      groupemailAddress: this.searchParameters.find(parameter => parameter.parameters === this.captions.lbl_emailAddress).isChecked,
      groupphone: this.searchParameters.find(parameter => parameter.parameters === this.captions.lbl_phoneNumber).isChecked,
      grouppostalCode: this.searchParameters.find(parameter => parameter.parameters === this.captions.postalCode).isChecked,

    }
    this.response = await this._dedupeGuestRecordBusiness.getDeDupeGuests(this.SearchCriteria);
    this.groupGuestResponse(this.response);
    this.isLoading = false;
    this.utils.ToggleLoader(false);
  }
  groupGuestResponse(result: DDtablecontent[]) {
    result.forEach(guest => {
      const key = guest.groupNumber;
      if (this.guestGroup.has(key)) {
        guest.isSecondary = true;
        this.guestGroup.get(key).push(guest);
      }
      else {
        guest.isPrimary = true;
        this.guestGroup.set(key, [guest]);
      }
    });
    this.tableArray = Array.from(this.guestGroup.values());
    this.totalRecords = this.tableArray.length;
  }

  combineguest(eve) {
    this.combinememberInputs = {
      'PrimarySelectedData': this.primarySelectedData,
      'targetSrchCriteriaList': this.SearchCriteria,
      'formchangeddata': this.formChangedData
    }
    this.showcombinemember = true;
    this.combinememberInputs = { ...this.combinememberInputs }
  }

  collapseAll() {
    this.isexpandAll = false;
    let className = '.leafNode';
    let accordians = document.querySelectorAll(className);
    accordians.forEach((x) => {
      if (!(x.classList.contains('d-none'))) {
        x.classList.add('d-none');
      }
    })
    this.tableArray.forEach((x, i) => {
      x?.forEach((y, j) => {
        this.tableArray[i][j].isExpanded = false;
        this.tableArray = [...this.tableArray]
      })
    })
  }
  expandAll() {
    this.isexpandAll = true;
    let className = '.leafNode';
    let accordians = document.querySelectorAll(className);
    accordians.forEach((x) => {
      if (x.classList.contains('d-none')) {
        x.classList.remove('d-none');
      }
    })
    this.tableArray.forEach((x, i) => {
      x?.forEach((y, j) => {
        this.tableArray[i][j].isExpanded = true;
        this.tableArray = [...this.tableArray]
      })
    })
  }
  toggleGroup(e, outindex, innerindex) {
    let className = '.leafNode.group' + outindex;
    let accordians = document.querySelectorAll(className);
    accordians.forEach((x) => {
      if (x.classList.contains('d-none')) {
        x.classList.remove('d-none');
        this.tableArray[outindex][innerindex].isExpanded = true;
        this.tableArray = [...this.tableArray]
      } else {
        x.classList.add('d-none');
        this.tableArray[outindex][innerindex].isExpanded = false;
        this.tableArray = [...this.tableArray]
      }
    });
  }

  openCombinePopup(group) {
    this.primarysecondarydedupeguestData = [];
    group.forEach(x => {
      this.primarysecondarydedupeguestData.push({
        guestId: x.guestId,
        isprimary: x.isPrimary,
        isSecondary: x.isSecondary,
        guestRecords: x
      })
    })
    this.dropdownFormation(group);

    const dialogRef = this.dialog.open(CombineGuestComponent, {
      height: '80%',
      width: '60%',
      data: { headerData: this.headerOptions, groupIds: this.primarysecondarydedupeguestData },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe((key) => {
      if (key !== undefined) {
        let guestMerged = this.guestGroup.get(key);
        let guest = guestMerged.filter((item) => item.isPrimary || !item.isSecondary);
        if (guest.length > 1) {
          this.guestGroup.set(key, guest);
        } else {
          this.guestGroup.delete(key);
        }
        this.tableArray = Array.from(this.guestGroup.values());
        this.totalRecords = this.tableArray.length;
      }
    });
  }

  dropdownFormation(group) {
    this.headerOptions.forEach((y, i) => {
      if (y.hasOwnProperty('selectOptions')) {
        y.selectOptions = group.filter((x) => x.isPrimary || x.isSecondary).map((x) => {
          return {
            formControlvalue: x[y['formControlName']],
            isPrimary: x.isPrimary,
            isSecondary: x.isSecondary,
          }
        }).map((z, idx) => {
          return {
            id: idx,
            value: z.formControlvalue,
            viewValue: z.formControlvalue,
            isPrimary: z.isPrimary,
            isSecondary: z.isSecondary
          }
        })
      }
    })
  this.Duplicate(this.headerOptions);
  }
  Duplicate(hdrOpt) {
    hdrOpt.forEach((obj)=>{
      if(obj.hasOwnProperty("selectOptions")) {
        const selectOpt = obj.selectOptions;
        const uniqueValues = new Set();
        obj.selectOptions = selectOpt.filter((option)=> {
          if(!uniqueValues.has(option.viewValue)) {
            uniqueValues.add(option.viewValue);
            return true;
          }
          return false;
        });
      }
    });
  }
  primaryClick(group, idx) {
    if (group[idx].isPrimary == false) {
      group.forEach(x => {
        x.isPrimary = false;
        x.isSecondary = true;
      })
      group[idx].isSecondary = false;
      group[idx].isPrimary = true;
    }
    let groupKey = group.find(x => x.isPrimary).groupNumber;
    this.guestGroup.set(groupKey, group);
  }

  secondaryClick(group, idx) {
    if (group[idx].isSecondary == true) {
      group[idx].isSecondary = false;
    }
    else {
      if (!group[idx].isPrimary)
        group[idx].isSecondary = true;
    }
    let groupKey = group.find(x => x.isPrimary).groupNumber;
    this.guestGroup.set(groupKey, group);
  }

  async combineAll() {
    this.utils.ToggleLoader(true);

    await Promise.all(this.tableArray.map(async (guestProfiles) => {
      try {
        let guestprofilesvaluetocombine: { primarydata: any; secondarydata: any; };
        const primaryGuestProfile = guestProfiles.find(x => x.isPrimary)?.guestId;
        const secondaryGuestProfile = guestProfiles.filter((profile) => profile.isSecondary).map((profile) => profile.guestId);
        guestprofilesvaluetocombine = { primarydata: primaryGuestProfile, secondarydata: secondaryGuestProfile };

        await this._dedupeGuestRecordBusiness.combineGuestRecords(guestprofilesvaluetocombine.primarydata, guestprofilesvaluetocombine.secondarydata);
        this.removeGuestProfiles(guestProfiles);
      }
      catch (ex) {
        console.log(ex, ex.stack);
      }
    }));

    this.utils.ToggleLoader(false);
  }

  removeGuestProfiles(guestProfiles) {
    const key = guestProfiles.find(item => item.groupNumber)?.groupNumber;
    let guestMerged = this.guestGroup.get(key);
    let guest = guestMerged.filter((item) => (item.isPrimary) || !(item.isSecondary));
    if (guest.length > 1) {
      this.guestGroup.set(key, guest);
    }
    else {
      this.guestGroup.delete(key);
    }
    this.tableArray = Array.from(this.guestGroup.values());
    this.totalRecords = this.tableArray.length;
  }
  onServerPageEmit(event) {
    // let emitServerPageData = {
    //   searchText: this.searchText,
    //   sortingType: this.ordertype,
    //   sortColKey: this.sortingColoumnKey
    // }
    this.serverPageEmit.emit({ ...event });
    this.fromIndex = event.from - 1;
    this.endIndex = event.to;
    if (this.isexpandAll) {
      this.collapseAll();
    }
    else {
      this.expandAll();
    }
  }
}
