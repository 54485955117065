import { GridType, Host, GridOperationType } from "../globalsContant";
import { HttpServiceCall, HttpMethod } from "../service/http-call.service";
import { BaseResponse, popupConfig } from "../business/shared.modals";
import { MatDialog } from "@angular/material/dialog";
import { Injectable } from "@angular/core";
import { SettingDialogPopupCommonComponent } from "./setting-dialog-common-popup/setting-dialog-common-popup.component";

@Injectable()
export class CommonFunctionalities {

    constructor(public http: HttpServiceCall, public dialog: MatDialog) {

    }


    /*
     * event parameters
     * @param e
     */
    EditRecords(e: Array<any>, afterClose = true) {
        const popUpbelonTo: GridType = e[1];
        const id = e[0].id;
        switch (popUpbelonTo) {
            case GridType.therapist:
                this.GetDetailsForId(id, 'GetTherapistAggregate');
                break;
            case GridType.spaServices:
                this.GetDetailsForId(id, 'GetSpecificService');
                break;
            case GridType.spaPackage:
                this.GetDetailsForId(id, 'GetSpecificPackage');
                break;
            case GridType.customfield:
                if (id && parseInt(id) > 0) {
                    this.GetDetailsForId(id, 'GetCustomSpecificField');
                }
                break;
            default:
                this.openDialog(e[0], popUpbelonTo, 'Edit', afterClose);
                break;
        }
    }



    GetDetailsForId(id: string, callDesc: string): any {
        this.http.CallApiWithCallback<number>({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback,
            callDesc: callDesc,
            method: HttpMethod.Get,
            uriParams: { id: id },
            showError: true,
            extraParams: []
        });
    }


    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        if (callDesc == "GetTherapistAggregate") {
            this.openDialog(result.result, GridType.therapist, 'Edit');
        }
    }

    errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        console.error(error.result);
    }

    openDialog(e: any, belonTo: any, preTit: any, afterClosed = true): any {
        let popupConfiguration: popupConfig;

        if (preTit == "Edit") {
            popupConfiguration = {
                operation: "edit",
                GridOperationType: GridOperationType.Edit
            };
        } else {
            popupConfiguration = {
                operation: "create",
                GridOperationType: GridOperationType.Create
            };
        }
        return this.triggerPopup(e, belonTo, preTit, popupConfiguration, afterClosed);
    }

    triggerPopup(e: any, belonTo: any, preTit: any, popupConfiguration: any, afterClosed = true): any {
        let Dialogtitle = 'Therapist'
        const panelClass = ['small-popup'];
 
        let  popupWidth = "90%";
        let popupHeight = "85%";
        let popupMinWidth = "900px";
        const dialogRef = this.dialog.open(SettingDialogPopupCommonComponent, {
            height: popupHeight,
            minWidth: popupMinWidth,
            width: popupWidth,
            maxWidth: popupWidth,
            data: { headername: preTit + '  ' + Dialogtitle, closebool: true, templatename: 'TS', datarecord: e, popupConfig: popupConfiguration, type: belonTo },
            panelClass: panelClass,
            disableClose: true,
            hasBackdrop: true
        });
        if (afterClosed) {
            dialogRef.afterClosed().subscribe();
        }
        return dialogRef;
    }


}


