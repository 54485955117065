import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { MasterInventoryManagement } from '../master.interface';
import { GridModelConfiguration } from './grid-config.interface';

export const GridName: GridModelConfiguration = (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement): string => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection ) {
            const entityName = ViewModelEntity[0]['$']['Name'].toString();
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            return ProductDataRemap.FormDisplayNameRemapEngine(productremapJson, entityName, 
            MasterDataCollection.Name);
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};
