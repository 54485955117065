import { IAppService, Hosts } from './common/app-service';
import { Product, Host } from './shared/globalsContant'; 
import { EventActorsDataProvider } from './common/Models/notification.model';
import { SpaEventActorDataProvider } from './spa-eventactor.dataprovider';
import { SpaUtilities } from './shared/utilities/spa-utilities';
import { SpaLocalization } from './core/localization/spa-localization';
import { SPAConfig } from './core/config/SPA-config';


/**
 * Factory service to hold product level configurations and objects
 */
export class SPAAppService implements IAppService {

    constructor(private utilities: SpaUtilities, private localization: SpaLocalization, private spaConfig:SPAConfig) { }

    /**
    * Assign Hosts to AppService
    * @memberof IAppService
    */
    get hosts(): Hosts {
        const hostNodeName:string = 'host.';
        return <Hosts>{
            TenantManagement: this.replaceURL(this.spaConfig.getUrl(hostNodeName +Host.authentication)),
            Report: this.replaceURL(this.spaConfig.getUrl(hostNodeName + Host.reporting)),
            Common: this.replaceURL(this.spaConfig.getUrl(hostNodeName +Host.common)),
            CommonGateway: this.replaceURL(this.spaConfig.getUrl(hostNodeName +Host.CommonGateway)),
            RetailManagement: this.replaceURL(this.spaConfig.getUrl(hostNodeName +Host.retailManagement))
        }
    }

   private replaceURL(URL: string): string {
       // Removes trailing slash from URL
        return URL.replace(/\/$/, '');
    }

    /**
    * Assign productId to AppService
    * @memberof IAppService
    */
    get productId(): number {
        return Product.SPA;
    }

    get notificationEventDataProvider(): EventActorsDataProvider {
        return { providers: new SpaEventActorDataProvider(this.utilities, this.localization.captions, this.spaConfig).providers }
    }

}