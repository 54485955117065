import { Injectable } from '@angular/core';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailLocalization } from '../localization/retail-localization';
import { DefaultSettings, Host } from "../../shared/globalsContant";
import { BaseResponse } from '../../shared/business/shared.modals';
import { DefaultUserConfigurationTenant, DefaultUserConfigurationTenantModel, NextId } from "../../../retail/retail.modals";
import { UserSessionConfiguration } from "../../retail.modals"; 
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { PropertyApiRoutes } from 'src/app/common/common-route';

@Injectable({ providedIn: 'root' })
export class UserMachineConfigurationService {
  userSessionConfigKey: string = 'userSessionConfigInfo';
  constructor(
    private http: HttpServiceCall,
    private utils: RetailUtilities,
    private localization: RetailLocalization,
    private _authenticationCommunication: TenantManagementCommunication
  ) { }

  public async GetUserMachineConfiguration(propertyId: number): Promise<any[]> {
    try {
      return await this.invokeUserSessionConfigurationCalls<any[]>(
        "GetMachineNamesByPropertyId",
        HttpMethod.Get,
        undefined,
        { propertyId });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async getUserSessionConfiguration(userId: number): Promise<UserSessionConfiguration> {
    let result = await this.invokeUserSessionConfigurationCalls<UserSessionConfiguration>(
      "GetUserSessionConfiguration",
      HttpMethod.Get,
      undefined,
      { userId });
      if (result === null) {
        result = this.initializeUserSessionConfiguration(userId);
      }
    return result;
  }

  initializeUserSessionConfiguration(userId: number): UserSessionConfiguration {
    return {
      id: 0,
      userId: userId,
      defaultOutletId: 0,
      defaultPaymentId: 0,
      defaultCourseId: 0,
      defaultPaymentDevice: '',
      defaultDeviceName: '',
      isIdtechSred: false,
      smallStickersPrinter: '',
      hangingTicketsPrinter: '',
      propertyId: 0
    }
  }

  public async createUserSessionConfiguration(body: UserSessionConfiguration): Promise<NextId> {
    let result = await this.http.CallApiAsync<NextId>({
      callDesc: "CreateUserSessionConfiguration",
      host: Host.retailManagement,
      method: HttpMethod.Post,
      body: body
    });
    return result.result;
  }

  public async updateUserSessionConfiguration(body: UserSessionConfiguration): Promise<UserSessionConfiguration> {
    try {
      return await this.invokeUserSessionConfigurationCalls<UserSessionConfiguration>("UpdateUserSessionConfiguration",
        HttpMethod.Put,
        body,
        { UserId: body.userId });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  private async invokeUserSessionConfigurationCalls<T>(callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {
    let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
      callDesc: callDesc,
      host: Host.retailManagement,
      method: callType,
      body: body,
      uriParams: uRIParams
    });

    if (!response.successStatus) {
      this.showError(response.errorCode);
    }
    return response.result;
  }

  private showError(errorCode: number) {
    let errMsg = this.localization.getError(errorCode);
    this.utils.ShowErrorMessage("Error", errMsg);
  }

  public getLoggedinUserSessionConfiguration(): UserSessionConfiguration {
    if (sessionStorage.getItem(this.userSessionConfigKey) != null) {
      return {
        id: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'Id')),
        userId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'UserId')),
        defaultOutletId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultOutletId')),
        defaultCourseId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultCourseId')),
        defaultPaymentDevice: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultPaymentDevice'),
        defaultDeviceName: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultDeviceName'),
        isIdtechSred: Boolean(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'IsIdtechSred')),
        smallStickersPrinter: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'SmallStickersPrinter'),
        hangingTicketsPrinter: this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'HangingTicketsPrinter'),
        propertyId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'PropertyId')),
        defaultPaymentId: Number(this.localization.GetsessionStorageValue(this.userSessionConfigKey, 'DefaultPaymentId'))
      }
    }
    return null;
  }

  // Test methods
  public log(message: string): void {
    const timeString: string = new Date().toLocaleTimeString();
    console.log(`${message} (${timeString})`)
  }

  error(message: string): void {
    console.error(`ERROR: ${message}`);
  }

  public async GetPaymentMethods(): Promise<any[]> {
    let result = await this.http.CallApiAsync<any[]>({
      callDesc: "PaymentMethod",
      host: Host.payment,
      method: HttpMethod.Get      
    });
    return result.result;
  }
  public CreateTenantDefaultUserConfiguration(defaultUserConfiguration : DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>):Promise<any>{
    return this._authenticationCommunication.postPromise({
        route: PropertyApiRoutes.CreateDefaultTenantUserConfiguration  ,        
        body: defaultUserConfiguration
    })
}

public UpdateTenantDefaultUserConfiguration(defaultUserConfiguration : DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>){
    return this._authenticationCommunication.putPromise({
        route: PropertyApiRoutes.UpdateDefaultTenantUserConfiguration  ,
          body: defaultUserConfiguration
    })
}

public GetTenantDefaultUserConfiguration(configName:string,propertyId : number,productId:number,userId: string):Promise<DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>>{
    return this._authenticationCommunication.getPromise({
        route: PropertyApiRoutes.GetDefaultTenantUserConfiguration  ,          
        uriParams: {"configurationName" : configName ,"propertyId":propertyId,"productId":productId, 'userId' : userId}
    })
} 

public async getMiscSettings(): Promise<DefaultSettings[]> {
  let result = await this.invokeUserSessionConfigurationCalls<DefaultSettings[]>( "GetMiscConfiguration", HttpMethod.Get);
  return result;
}

public async getAllClientSetting(): Promise<DefaultSettings[]> {
  let result = await this.invokeUserSessionConfigurationCalls<DefaultSettings[]>( "GetAllClientSetting", HttpMethod.Get);
  return result;
}


}
