import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { PlanHeadButchery, PlanHeadGet, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const ProductionPlanConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine): any => {
    try {   
      const ScrollItemCount = sessionStorage.getItem('appsetting') ? 
      JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid : constants.InfiniteScrollItemCount;     
        const orderByColumn = transactionService.
        setSortColumn(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortDirection = transactionService.
        setSortDirection(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortingData = new RequestPagination(orderByColumn, sortDirection, requestEngine.FetchAll,
        requestEngine.LocationIds );
        const requestPayload = sortingData.getPaginationPayload(0, ScrollItemCount);
        const ProductionPlanModel = {} as PlanHeadButchery;
        ProductionPlanModel.PlanTypeId = 1;
        ProductionPlanModel.PlanPeriodLengthIds = [];
        // As discussed for GetAllPlans LocationIds will be empty array
        ProductionPlanModel.LocationIds = []; 
        const PostData = Object.assign(requestPayload, ProductionPlanModel);
        return PostData;
    } catch (error) {
        console.error('Error occurred in ProductionPlanConstruction', error);
    }
};

function formatDateFilter(DateValue, DateType: string) {
  try {
    let currentDate;
    if (DateType === 'ToDate') {
      currentDate = moment(DateValue).endOf('day').format(constants.PostDateTimeFormat);
    } else {
      currentDate =  moment(DateValue).startOf('day').format(constants.PostDateTimeFormat);
    }
    return currentDate;
  } catch (error) {
    console.error('Error occurred in formatDateFilter', error);

  }
}