<section class="multi-select-action {{field.class}}" #listOption *ngIf="form && field  && !field.hide" [formGroup]="form">
    <mat-form-field appearance="fill" title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
        this.displayControl?.value : formFieldToolTip}}" class="mat-form-input">
        <mat-label>{{formFieldlabel}}</mat-label>
		<input type="text"
        [name]="formFieldName" [formControlName]="formFieldName" matInput placeholder="{{formFieldlabel}}" [required]="isRequired" [matAutocomplete]="auto" 
        (keypress)="inputChange($event)">
		<mat-autocomplete autoActiveFirstOption=false #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <div class="options-list" >
                <mat-option *ngFor="let data of field.fieldType[fieldType].options" [value]="data.key">
                    <mat-checkbox [checked]="data.checked" (change)="selected(data)" (click)="prevent($event)">{{data.label}}</mat-checkbox>
                </mat-option>
            </div>
            <div class="option-footer" (click)="addNew()" *ngIf="field && field.fieldType.SingleData && field.fieldType.SingleData.enableFooter">
                <span>Add New {{formFieldlabel}}</span>
            </div>
		</mat-autocomplete>
        <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
	</mat-form-field>
</section>