
import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomCustomerLocation: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: FormType[]
): any => {
    try {
        if (FieldGrouping.CustomFormAction) {
            const getCurrentLocationId = JSON.parse(sessionStorage.getItem('FromLocId'));
            const seleId = `Id in (${getCurrentLocationId.join()}) and LocationTypeId eq 8`;
            const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            const params: QueryParams = {
                count: true,
                top: InfiniteScrollItemCount,
                skip: 0,
                expand: '',
                select: '',
                filter: '',
                orderby: '',
                isactive: false,
                search: '',
                filterQuery: GetDataFilter,
                searchtext: ''
            };
            const getQueryParams = formQueryParams(params);
            const CustomUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}`;
            const GetDataByIdUrl = `${CustomUrl + getQueryParams}`;
            httpService.GethttpMethod(GetDataByIdUrl).subscribe(locationdata => {
                if (locationdata && locationdata['value'] && locationdata['value'].length > 0) {
                    const data  = locationdata['value'][0];
                    FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(data.Id);
                } else {
                    FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(0);
                }
            });
        }
    } catch (error) {
        console.log('error occured CustomDefaultSelect', error);
    }
};
function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}