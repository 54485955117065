import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { EqContext, i18n } from '@easyquery/core';
import { EqViewOptions, AdvancedSearchView } from '@easyquery/ui';
import { QueryBuilderBusiness } from './querybuilder.business';
import '@easyquery/enterprise'
import { UI, ExtractList } from 'src/app/shared/models/generic-extract-model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EasypopupComponent } from './easypopup/easypopup.component';
import { SaveEasyqueryPopupComponent } from './save-easyquery-popup/save-easyquery-popup.component';
import { of } from 'rxjs';
import { AgDropdownConfig, ButtonValue, DropdownOptions } from 'src/app/common/Models/ag-models';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { Localization } from 'src/app/common/localization/localization';
import { FacadeService } from 'src/app/common/services/facade.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ReportBreakPoint } from 'src/app/reports/common/report.constants';

@Component({
  selector: 'app-querybuilder',
  templateUrl: './querybuilder.component.html',
  styleUrls: ['./querybuilder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [QueryBuilderBusiness, FacadeService]
})
export class QuerybuilderComponent implements AfterViewInit {

  private context: EqContext;
  private view: AdvancedSearchView;

  captions: any;
  saveButton: ButtonValue;
  saveAsButton: ButtonValue;
  cancelButton: ButtonValue;
  fetchButton: ButtonValue;
  queryBuilderForm: UntypedFormGroup;
  customQueryGroupInput: AgDropdownConfig;
  customQueryInput: AgDropdownConfig;
  customQueryGroupSource = [];
  filteredcustomQueryGroupSource = [];
  customQuerySource = [];
  customQueryGroups: UI.CustomQueryGroup[];

  isFormDisabled: boolean = false;

  hasFolioAccess: boolean = true;
  hasGenericExtractAccess: boolean = true;
  hasGroupAccess: boolean = true;
  hasGuestAccess: boolean = true;
  hasReservationAccess: boolean = true;
  hasRetailAccess: boolean = true;
  hasRoomInventoryAccess: boolean = true;
  FrontDesk: boolean = true;
  Sales: boolean = true;
  Default: boolean = true;
  floatLabel: string;
  
  constructor(private business: QueryBuilderBusiness,
    private utils: CommonUtilities,
    private _userAccessBusiness: UserAccessBusiness,
    private formBuilder: UntypedFormBuilder, private facadeservice: FacadeService,
    public dialog: MatDialog, private localization : Localization) {
    this.captions = this.business.captions;
    this.floatLabel=this.localization.setFloatLabel;
  }

  ngOnInit() {

   
    const lang = this.utils.GetLocalStorageValue('propertyInfo', 'UserLanguage');
    i18n.updateLocaleInfo(lang, this.captions.easyQueryLocaleInfo);
    i18n.setCurrentLocale(lang);

    this.queryBuilderForm = this.formBuilder.group({
      customQueryGroup: '',
      customQuery: '',
    });

    this.buttonInitialize();

  }

  getAllCustomQueryGroups() {
    this.customQuerySource = [];
    this.customQueryGroupSource = [];

    this.business.getAllCustomQueryGroups().then(result => {
      this.customQueryGroups = result;

      for (let i = 0; i < result.length; ++i) {

        this.customQueryGroupSource.push({
          id: i,
          value: result[i].groupName,
          viewValue: result[i].groupName,
          disabled: false,
          isAuthorized: this.getExtractAccess(result[i].groupName)
        });

      }
      const validIndex = this.customQueryGroupSource.find(x=>x.isAuthorized)?.id;
      this.assignQueryLoaderInit(validIndex);
      this.filteredcustomQueryGroupSource = this.customQueryGroupSource;

      this.refreshCustomQueryInput();
      if (this.customQuerySource && this.customQuerySource?.length) {
        this.patchQueryDropdown(this.customQuerySource[0].value, this.customQuerySource[0].otherData);
      }
    });
  }

  assignQueryLoaderInit(index: number){
    if(index>=0)
    {
      if (this.customQueryGroups[index].queryListItem.length > 0) {
        for (let j = 0; j < this.customQueryGroups[index].queryListItem.length; j++) {
          this.customQuerySource.push({
            id: j,
            value: this.customQueryGroups[index].queryListItem[j].id,
            viewValue: this.customQueryGroups[index].queryListItem[j].name,
            otherData: this.customQueryGroups[index].groupName,
            disabled: false
          });
        }
      }
      // this.customQueryGroupInput.selectOptions = of(this.customQueryGroupSource);
      // this.customQueryGroupInput = { ... this.customQueryGroupInput };
      this.queryBuilderForm.controls.customQueryGroup.patchValue(this.customQueryGroupSource[index].value);
      this.context.loadModel({ modelId: this.customQueryGroupSource[index].value });
    }
    else{
      this.disableForm(true);
    }
  

  }

  buttonInitialize() {
    this.saveButton = {
      type: 'primary',
      label: this.captions.lbl_saveQuery,
      disabledproperty: false
    };
    this.saveAsButton = {
      type: 'primary',
      label: this.captions.lbl_saveAsQuery,
      disabledproperty: false
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.lbl_queryClear,
      disabledproperty: false
    };
    this.fetchButton = {
      type: 'primary',
      label: this.captions.btn_fetchData,
      disabledproperty: false
    };
    this.customQueryGroupInput = {
      form: this.queryBuilderForm,
      formControlName: 'customQueryGroup',
      selectOptions: of([]),
      placeHolderId: 'lbl_queryModule',
      placeHolder: this.captions.lbl_queryModule,
      className: 'ag_form-control--xlg modelSelector',
      disabled: false
    };
    this.customQueryInput = {
      form: this.queryBuilderForm,
      formControlName: 'customQuery',
      selectOptions: of([]),
      placeHolderId: 'lbl_queryLoad',
      placeHolder: this.captions.lbl_queryLoad,
      className: 'ag_form-control--xlg modelSelector',
      disabled: false,
      automationId:"'Dd_queryBuilder_customQuery'"
    };
  }

  getExtractAccess(value: string) {
    switch (value) {
      case ExtractList.Folio:
        return this.hasFolioAccess;
      case ExtractList.GenericExtract:
        return this.hasGenericExtractAccess;
      case ExtractList.Group:
        return this.hasGroupAccess;
      case ExtractList.Guest:
        return this.hasGuestAccess;
      case ExtractList.Reservations:
        return this.hasReservationAccess;
      case ExtractList.Retail:
        return this.hasRetailAccess;
      case ExtractList.RoomInventory:
        return this.hasRoomInventoryAccess;
        case ExtractList.FrontDesk:
        return this.FrontDesk;
        case ExtractList.Sales:
        return this.Sales;
        case ExtractList.Default:
        return this.Default;
    }
  }
  customQueryGroupChange(event) {
    this.context.clearQuery();
    var hasaccess = this.isAuthorized(event);
    if (hasaccess) {
      this.customQuerySource.length = 0;
      let queries = this.customQueryGroups[event.id];

      this.context.loadModel({ 'modelId': queries.groupName });

      if (queries.queryListItem.length > 0) {
        for (let j = 0; j < queries.queryListItem.length; j++) {
          this.customQuerySource.push({
            id: j,
            value: queries.queryListItem[j].id,
            viewValue: queries.queryListItem[j].name,
            otherData: queries.groupName,
            disabled: false
          });
        }

        this.refreshCustomQueryInput();
        if (this.customQuerySource && this.customQuerySource?.length) {
          this.patchQueryDropdown(this.customQuerySource[0].value, this.customQuerySource[0].otherData);
        }
      }
      else {
        this.context.newQuery();
      }
    }

  }

  customQueryChange(event) {
    this.context.clearQuery();
    this.context.loadQuery({
      modelId: this.customQuerySource.find(x => x.id == event.id).otherData,
      queryId: this.customQuerySource.find(x => x.id == event.id).value
    });
  }

  ngAfterViewInit() {

    //options for QueryBuilder view
    const viewOptions: EqViewOptions = {
      enableExport: true,
      serverExporters: ['pdf', 'excel', 'csv'],
      loadModelOnStart: false,
      loadQueryOnStart: false,
      disableConfirmationOnQueryChange: true,
      handlers: {
        onError: (context, error) => {
          console.error(error.action + " error:\n" + error.text);
        },
      },
      widgets: {
        entitiesPanel: {
          showCheckboxes: true,
          //To hide the columns where Useinresult is set to false in xml
          showAttributes: { useInResult: true, useInConditions: false, useInSorting: false }
        },
        columnsPanel: {
          allowAggrColumns: true,
          allowCustomExpressions: true,
          attrElementFormat: "{entity} {attr}",
          titleElementFormat: "{attr}",
          showColumnCaptions: true,
          adjustEntitiesMenuHeight: false,
          customExpressionText: 2,
          showPoweredBy: false,
          menuOptions: {
            showSearchBoxAfter: 2,
            activateOnMouseOver: true
          }
        },
        queryPanel: {
          showPoweredBy: false,
          alwaysShowButtonsInGroups: false,
          allowParameterization: true,
          allowInJoinConditions: true,
          autoEditNewCondition: true,
          buttons: {
            condition: ["menu"],
            group: ["addCondition", "addGroup", "enable", "delete"]
          },
          menuOptions: {
            showSearchBoxAfter: 20,
            activateOnMouseOver: true
          },
        }
      },
      result: {
        showChart: false
      }
    }

    this.view = new AdvancedSearchView();
    this.context = this.view.getContext();
    var http = this.context.getServices().getHttpClient();

    // Add SessionId to all http requests 
    http.defaultHeaders['SessionId'] = sessionStorage.getItem('userSession') != null ? sessionStorage.getItem('userSession') : '';

    this.context
      .useEndpoint(this.business.getEasyQueryUrl())
      .useEnterprise(() => {
        this.view.init(viewOptions);
      });

    this.validateCustomGroupUserAccess();
    
  }

  onSave(e) {
      this.context.saveQuery();
  }

  onSaveAs(e) {
    let queryObj = this.context.getQuery();
    this.dialog.open(SaveEasyqueryPopupComponent, {
      width: '30%',
      height: '25%',
      disableClose: true,
      data: {
        isSave: false
      }
    }).afterClosed().subscribe(async result => {
      queryObj.setName(result.easyQueryName);
      queryObj.regenerateId();
      this.context.saveQuery();

      let arrayLength = this.customQuerySource.length;
      this.customQuerySource.push({
        id: arrayLength,
        value: queryObj.getId(),
        viewValue: result.easyQueryName,
        otherData: this.queryBuilderForm.controls.customQueryGroup.value.groupName,
        disabled: false
      });

      this.customQueryGroups[this.getCurrentItemIndex(this.queryBuilderForm.controls.customQueryGroup.value)].queryListItem.push({
        id: queryObj.getId(),
        name: result.easyQueryName,
        text: this.queryBuilderForm.controls.customQueryGroup.value.groupName,
        description: this.queryBuilderForm.controls.customQueryGroup.value.groupName,
      });

      this.refreshCustomQueryInput();
      this.patchQueryDropdown(queryObj.getId(), this.queryBuilderForm.controls.customQueryGroup.value.groupName);
    });
  }

  onFetch(e) {
    let queryObj = this.context.getQuery();
    this.dialog.open(EasypopupComponent, {
      height: '100%',
      minWidth: '100%',
      width: '100%',
      maxWidth: '100%',
      data: { easyQueryName: queryObj.getName(), easyQueryJson: queryObj.toJSON() }
    });
  }

  addNewQuery() { 

    this.dialog.open(SaveEasyqueryPopupComponent, {
      width: '30%',
      height: '25%',
      disableClose: true,
      data: {
        isSave: true
      }
    }).afterClosed().subscribe(async result => {

      if(result != undefined){
        this.context.clearQuery();
        this.context.newQuery();
        let queryObj = this.context.getQuery();

        queryObj.setName(result.easyQueryName);
        this.context.saveQuery();
  
        let arrayLength = this.customQuerySource.length;
        this.customQuerySource.push({
          id: arrayLength,
          value: queryObj.getId(),
          viewValue: result.easyQueryName,
          otherData: this.queryBuilderForm.controls.customQueryGroup.value.groupName,
          disabled: false
        });
  
        this.customQueryGroups[this.getCurrentItemIndex(this.queryBuilderForm.controls.customQueryGroup.value)].queryListItem.push({
          id: queryObj.getId(),
          name: result.easyQueryName,
          text: this.queryBuilderForm.controls.customQueryGroup.value.groupName,
          description: this.queryBuilderForm.controls.customQueryGroup.value.groupName,
        });
  
        this.refreshCustomQueryInput();
        this.patchQueryDropdown(queryObj.getId(), this.queryBuilderForm.controls.customQueryGroup.value.groupName);
      }

    });
  }

  removeQuery() {
    let queryObj = this.context.getQuery();
    let queryId = queryObj.getId();

    this.customQuerySource = this.customQuerySource.filter(item => item.value !== queryId);
    this.customQueryGroups[this.getCurrentItemIndex(this.queryBuilderForm.controls.customQueryGroup.value)].queryListItem = this.customQueryGroups[this.getCurrentItemIndex(this.queryBuilderForm.controls.customQueryGroup.value)].queryListItem.filter(item => item.id !== queryObj.getId());

    this.context.clearQuery();
    this.context.removeQuery();

    this.mapIdWithIndex();
    this.refreshCustomQueryInput();

    if (this.customQuerySource && this.customQuerySource?.length) {
      this.patchQueryDropdown(this.customQuerySource[0].value, this.customQuerySource[0].otherData);
    }
  }

  getCurrentItemIndex(modelName : string){
    return this.customQueryGroups.findIndex(x=>x.groupName == modelName);
  }
  onCancel() {
    this.context.clearQuery();
  }


  refreshCustomQueryInput() {
    this.customQueryInput.selectOptions = of(this.customQuerySource);
    this.customQueryInput = { ... this.customQueryInput };
  }

  patchQueryDropdown(queryId: string, model: string) {
    this.queryBuilderForm.controls.customQuery.patchValue(this.customQuerySource.find(x => x.value == queryId));
    this.context.loadQuery({ modelId: model, queryId: queryId });
  }

  mapIdWithIndex() {
    for (let i = 0; i < this.customQuerySource.length; i++) {
      this.customQuerySource[i].id = i;
    }
  }

  validateCustomGroupUserAccess() {
    const userAccess = this.facadeservice.getUserAccesses([ReportBreakPoint.Folio, ReportBreakPoint.Group,ReportBreakPoint.Guest,ReportBreakPoint.Reservations,ReportBreakPoint.RoomInventory,ReportBreakPoint.Retail,ReportBreakPoint.GenericExtract,ReportBreakPoint.FrontDesk,ReportBreakPoint.Sales,ReportBreakPoint.Default], true);
    userAccess.then(data => {

      this.hasFolioAccess = this.getAccessByBreakPointNumber(data, ReportBreakPoint.Folio);
      this.hasGenericExtractAccess = this.getAccessByBreakPointNumber(data, ReportBreakPoint.GenericExtract);
      this.hasGroupAccess = this.getAccessByBreakPointNumber(data, ReportBreakPoint.Group);
      this.hasGuestAccess = this.getAccessByBreakPointNumber(data, ReportBreakPoint.Guest);
      this.hasReservationAccess = this.getAccessByBreakPointNumber(data, ReportBreakPoint.Reservations);
      this.hasRoomInventoryAccess = this.getAccessByBreakPointNumber(data, ReportBreakPoint.RoomInventory);
      this.hasRetailAccess = this.getAccessByBreakPointNumber(data, ReportBreakPoint.Retail);
      this.FrontDesk = this.getAccessByBreakPointNumber(data,ReportBreakPoint.FrontDesk);
      this.Sales = this.getAccessByBreakPointNumber(data,ReportBreakPoint.Sales);
      this.Default = this.getAccessByBreakPointNumber(data,ReportBreakPoint.Default);

      this.getAllCustomQueryGroups();
    });
  }

  getAccessByBreakPointNumber(breakPoints, bpArr) {
    const data = breakPoints && breakPoints.find(x => x.breakPointNumber == bpArr);
    return data.isAllow;
  }


  private _filter(value: string) {

    return this.customQueryGroupSource.filter(option => option.value.toLowerCase().indexOf(value.toLowerCase()) === 0)
  }

  filterOptions(event) {
    this.filteredcustomQueryGroupSource = this._filter(event.target.value);
  }
  setSelected() {
    /*if (!this.filteredOptions.length) {
      this.defaultReport = this.report.code;
      this.defaultReportdesc = this.report.value;
      this.updateReport(this.report);
    	
    }*/

  }
  private isAuthorized(event): boolean {
    if (!event.isAuthorized) {
      switch(event.value){
        case ExtractList.Folio:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.Folio);
          break;
        case ExtractList.GenericExtract:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.GenericExtract);
          break;
        case ExtractList.Group:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.Group);
          break;
        case ExtractList.Guest:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.Guest);
          break;
        case ExtractList.Reservations:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.Reservations);
          break;
        case ExtractList.Retail:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.Retail);
          break;
        case ExtractList.RoomInventory:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.RoomInventory);
          break;
        case ExtractList.FrontDesk:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.FrontDesk);
          break;
        case ExtractList.Sales:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.Sales);
         break;
         case ExtractList.Default:
          this._userAccessBusiness.showBreakPointError(ReportBreakPoint.Default);
         break;
      }
     
    }
    return event.isAuthorized;
  }
  disableForm(value: boolean){
    this.isFormDisabled = value;
    this.saveButton.disabledproperty = this.isFormDisabled;
    this.saveButton = { ...this.saveButton};
    this.saveAsButton.disabledproperty = this.isFormDisabled;
    this.saveAsButton = { ...this.saveAsButton};
    this.fetchButton.disabledproperty = this.isFormDisabled;
    this.fetchButton = { ...this.fetchButton};
    this.cancelButton.disabledproperty = this.isFormDisabled;
    this.cancelButton = { ...this.cancelButton};
  }
}
