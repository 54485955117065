import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { CreateTransactionItemlist } from '../create-transcation/item-transaction';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomCreditsFormConfiguration, CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import moment from 'moment';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const AutoCompleteSearchApCredit: CustomCreditsFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, 
    CustomData?: any
): any => {
    try {
        const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
        if (
            FieldGrouping.CustomFormAction.EnableCustomMethod &&
            FieldGrouping.hasOwnProperty('autoCompleteHeader') &&
            FieldGrouping.autoCompleteHeader.length > 0 && typeof CustomData === 'string') {
            let apFilter = '';
            let expand = '';
            let LoggedInSiteId = JSON.parse(sessionStorage.getItem('LoggedInSiteId'));
            LoggedInSiteId = LoggedInSiteId === 1 ? 0 : LoggedInSiteId;
            const AutoSearchparams = ( CustomData === '' || CustomData === null || CustomData === undefined ) ? '' : CustomData;
            if ( FieldGrouping.hasOwnProperty('autoCompleteHeader') && FieldGrouping.autoCompleteHeader.length > 0 ) {
                if ( FieldGrouping.OdataKey === 'TransactorId' ) {
                    apFilter = `indexof(ReceivingHeadSupplier/Name, '${AutoSearchparams}') gt ` + `${-1}` 
                                    + `  and ReceivingHeadLocation/SiteId eq ${LoggedInSiteId} and IsInvoiced eq true and RecevingTransactionStatusId ne 2 and  amount gt 0 and (amount sub  TotalPayments sub  TotalCredits) ne 0`;
                    expand = '&$expand=ReceivingHeadLocation($select=id,name,SiteId),ReceivingHeadLocation($expand=Sites($select=id,name)),ReceivingHeadSupplier($select=id,name)';
                } else if (FieldGrouping.OdataKey === 'InvoiceId') {
                    const supplierId = FormGroupData.controls.TransactorId.value;
                    // eslint-disable-next-line max-len
                    apFilter = `indexof(InvoiceNumber, '${AutoSearchparams}') gt ` + `${-1}` + (supplierId ? ` and SupplierId eq ` + supplierId : '')
                                     + ` and ReceivingHeadLocation/SiteId eq ${LoggedInSiteId} and IsInvoiced eq true and RecevingTransactionStatusId ne 2 and  amount gt 0 and (amount sub  TotalPayments sub  TotalCredits) ne 0`;
                    expand = '&$expand=ReceivingHeadLocation($select=id,name,SiteId),ReceivingHeadLocation($expand=Sites($select=id,name)),ReceivingHeadSupplier($select=id,name), ReceivingHeadCreditAcc($select=id,name), ReceivingHeadDebitAcc($select=id,name)';
                }
            }    
            const GetDataFilter = encodeURIComponent(`${apFilter}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            const filterString = '?$filter=' + GetDataFilter + expand;
            const EndPointUrl = `/inventory/api/${FieldGrouping.CustomFormAction.Url}` + `${filterString}`;
            FormField = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields;
            const getDocumentIdValue = document.getElementById('search-autocompleted-nodata-found');
            if ( getDocumentIdValue && getDocumentIdValue !== undefined ) {
                getDocumentIdValue.style.display = 'none';
            }
            httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                if ( getDocumentIdValue && getDocumentIdValue !== undefined ) {
                    getDocumentIdValue.style.display = 'inherit';
                }
                let responseData = res['value'];
                responseData = responseData.filter(x => ((x.Amount - x.TotalPayments - x.TotalCredits) !== 0));
                const autoCompleteData = FormField.filter((x: FormType) => x.type === 'autocomplete');
                if (autoCompleteData && autoCompleteData.length > 0) {
                    const autoFormFields = autoCompleteData[0];
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [];
                    if ( FieldGrouping.OdataKey === 'TransactorId' ) {
                      responseData.map((x: any) => {
                        x['SupplierName'] = x['ReceivingHeadSupplier'].Name;
                    });
                    } else if (FieldGrouping.OdataKey === 'InvoiceId') {
                        responseData.map((x: any) => {
                            const suppierName = `${x['ReceivingHeadSupplier'].Name}`;
                            x['InvoiceId'] = x['InvoiceNumber'];
                            x['LocationName'] = x['ReceivingHeadLocation'] ? x['ReceivingHeadLocation'].Name : '';
                            x['SiteName'] = x['ReceivingHeadLocation'] ? (x['ReceivingHeadLocation'].Sites 
                            ? x['ReceivingHeadLocation'].Sites.Name : '') : '';
                            x['Balance'] = (x['Amount'] ? parseFloat(x['Amount']) : 0) - 
                            (x['TotalPayments'] ? parseFloat(x['TotalPayments']) : 0) 
                            - (x['TotalCredits'] ? parseFloat(x['TotalCredits']) : 0);
                            x['SupplierName'] = suppierName;
                            const DateInvoice = x['InvoiceDate'] ? x['InvoiceDate'] : x['PostDate'];
                            const DateTimeFormat = ( sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null ) ? 
                            sessionDateFormat :  constants.DefaultIniDateFormat;
                            // moment.utc(DateInvoice).local().format(DateTimeFormat);
                            x['InvoiceDate'] = Formaters.globalDateFormaters(DateInvoice, true);
                        });
                    }
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [...responseData];
                }
            });
        }
        if (
            FieldGrouping.CustomFormAction.EnableCustomMethod &&
            FieldGrouping.hasOwnProperty('autoCompleteHeader') &&
            FieldGrouping.autoCompleteHeader.length > 0 && CustomData && typeof CustomData !== 'string') {
              if ( FieldGrouping.OdataKey === 'TransactorId' ) {
                FormGroupData.controls['TransactorId'].setValue(CustomData['SupplierName']);
              } else if (FieldGrouping.OdataKey === 'InvoiceId') {
                FormGroupData.controls['InvoiceId'].setValue(CustomData['InvoiceId']);
                FormGroupData.controls['TransactorId'].setValue(CustomData['SupplierId']);
                // const selectedCreditValue = (CustomData.hasOwnProperty('ReceivingHeadCreditAcc')) ? 
                // CustomData['ReceivingHeadCreditAcc'].Id : null;
                const selectedDepartMentValue = ( CustomData.hasOwnProperty('ReceivingHeadDebitAcc') 
                && CustomData['ReceivingHeadDebitAcc'] !== null ) ? CustomData['ReceivingHeadCreditAcc'].Id : null;
                FormGroupData.controls['DepartmentId'].setValue(selectedDepartMentValue);
                FormGroupData.controls['Supplierbalance'].setValue(CustomData['Amount']);  
                CommonService.triggerBlurEvent('Supplierbalance');
                MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields.forEach(x => {
                    if (x.fieldType.SingleData.name === 'TransactorId' || x.fieldType.SingleData.name === 'InvoiceId') {
                        x.fieldType.SingleData.disbaledProperty = true;
                    }
                    if (x.fieldType.SingleData.name === 'CreditAccId') {
                        x.fieldType.SingleData.disbaledProperty = false;
                    }
                });
                // const gridDataValue = FormGroupData.value;
                const DateTimeFormat = ( sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null ) ? 
                sessionDateFormat :  constants.DefaultIniDateFormat;
                // const returnValue = CustomData.InvoiceDueDate ? moment.utc(CustomData.InvoiceDueDate).local().format(DateTimeFormat) :
                // '';
                CustomData.InvoiceDueDate = Formaters.globalDateFormaters(CustomData.InvoiceDueDate, true);
                // const returnInvoiceValue = CustomData.InvoiceDate ? moment.utc(CustomData.InvoiceDate).local().format(DateTimeFormat) :
                // '';
                CustomData.InvoiceDate = Formaters.globalDateFormaters(CustomData.InvoiceDate, true);
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.push(CustomData);
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = 
                [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData ];
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = {
                    ...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig };
                // MasterDataCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
                //     if (x.buttonKey === 'confirm') {
                //        x.displayProperity = !FormGroupData.valid; 
                //     }    
                //  });
              }
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function AssignPODetails(PODetails, MasterDataCollection: TransactionConfiguration) {
    try {
        PODetails.forEach(element => {
            element.PODetailId = element.Id;
            element.Id = 0;
            element.Name = element.PODetailIngredientId.Name;
            element.Quantity = element.Qty;
            element.Units = element.PODetailIngredientId.Units;
            const transactionUnitsDetails = element['Units'].filter((unitDetails: any) =>
                unitDetails['UnitTypeId'] === element['UnitTypeId'])[0];
            if (transactionUnitsDetails) {
                element['UnitCode'] = transactionUnitsDetails.UnitCode;
                element['UnitCost'] = element.Valued / element.Qty;
            }
        });
        return PODetails;
    } catch (error) {
        console.error('Error occurred in AssignPODetails', error);
    }
}