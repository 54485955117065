import { Component } from '@angular/core';

@Component({
  selector: 'app-report-security',
  templateUrl: './report-security.component.html',
  styleUrls: ['./report-security.component.scss']
})
export class ReportSecurityComponent  {
  constructor() { }

  ngOnInit() {
  }

}
