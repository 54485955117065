
import { Injectable } from '@angular/core';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CommonControllersRoutes } from '../../communication/common-route';
import { TenantManagementCommunication } from '../../communication/services/tenantmanagement-communication-service';
import { Localization } from '../../localization/localization';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { LogUserMenuAccess } from '../../shared/shared/components/menu-search/menu.model';
import { Product } from 'src/app/common/enums/shared-enums';
import { Observable } from 'rxjs';

@Injectable(
    { providedIn: 'root' }
)
export class UserAccessDataService {

    constructor(private _tenantMangementCommunication: TenantManagementCommunication, private _utilities: CommonUtilities, private localization: Localization) {
    }

    public async getUserAccess(breakPointNumber: number, roleId?: string): Promise<UserAccessModel.BreakPointResult> {
        // default value - allow view only
        let result: UserAccessModel.BreakPointResult = {
            isAllow: true,
            isViewOnly: false
        };
        // Note : Commented for now,we should uncomment while implement the user access
        const userRoleId = roleId ? roleId : this._utilities.GetLocalStorageValue('_userInfo', 'roleId');     
        let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
        let enableUICache = propConfig?.UICacheEnabled;      
        let accesses;
        if(enableUICache && enableUICache.toLowerCase() == "true"){
            accesses = await this.getAllUserAccess(userRoleId);
        } else {
            accesses = await this._tenantMangementCommunication.getPromise<UserAccessModel.BreakPointAccess[]>({
                route: CommonControllersRoutes.GetUserClaimsAsync
                , uriParams: { roleID: userRoleId, bkpn: breakPointNumber }
            });
        }
        accesses = accesses ? accesses : [];
        let access: UserAccessModel.BreakPointAccess = accesses.find(x => x.breakPointNumber == breakPointNumber);
        if (access) {
            result = {
                isAllow: access.allow,
                isViewOnly: access.view,
                breakPointNumber: access.breakPointNumber
            };
        }
        return result;
    }

    public async getUserAccesses(breakPointNumber: number[]): Promise<UserAccessModel.BreakPointResult[]> {
        let result: UserAccessModel.BreakPointResult[] = [];
        const userRoleId = this.localization.GetsessionStorageValue('_userInfo', 'roleId');      
        let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
        let enableUICache = propConfig?.UICacheEnabled;   
        let accesses;
        if(enableUICache && enableUICache.toLowerCase() == "true"){
            accesses = await this.getAllUserAccess(userRoleId);
        } else {
            accesses = await this._tenantMangementCommunication.getPromise<UserAccessModel.BreakPointAccess[]>({
                route: CommonControllersRoutes.GetUserClaimsAsync
                , uriParams: { roleID: userRoleId, bkpn: breakPointNumber }
            });
        }
        accesses = accesses ? accesses : [];
        result = accesses.map(access => {
            return <UserAccessModel.BreakPointResult>{
                isAllow: access.allow,
                isViewOnly: access.view,
                breakPointNumber: access.breakPointNumber
            };
        });
        return result;
    }

    public async getAllUserAccess(userRoleId): Promise<UserAccessModel.BreakPointAccess[]>{
        return await this._tenantMangementCommunication.getPromise<UserAccessModel.BreakPointAccess[]>({
            route: CommonControllersRoutes.GetUserClaimsForRoleAsync
            , uriParams: { RoleId: userRoleId}
        });
    }

    public getAllUserAccessObservable(userRoleId): Observable<UserAccessModel.BreakPointAccess[]>{
        return this._tenantMangementCommunication.getObservable<UserAccessModel.BreakPointAccess[]>({
            route: CommonControllersRoutes.GetUserClaimsForRoleAsync
            , uriParams: { RoleId: userRoleId}
        });
    }

    public async isViewOnly(breakPointNUmber: number): Promise<boolean> {
        return true;
    }

    private getProductId() {
        return this.localization.GetsessionStorageValue('propertyInfo', 'ProductId');
    }

    public logUserMenuAccess(menuAccessed: LogUserMenuAccess): boolean {
        this._tenantMangementCommunication.postPromise<UserAccessModel.UserScreenView>({
            route: CommonControllersRoutes.LogUserMenuAccess
            , body: menuAccessed
        });
        return true;
    }
}