import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';

@Component({
  selector: 'app-inventory-wash',
  templateUrl: './inventory-wash.component.html',
  styleUrls: ['./inventory-wash.component.scss']
})
export class InventoryWashComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  InventoryWashFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  // @Input() customReport: FormGroup;
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, private controlContainer: ControlContainer) { }

  ngOnInit() {
    // this.customReport = <FormGroup>this.controlContainer.control;
    // this.customReport.addControl('category', new FormControl(0));
    this.InventoryWashFormGrp = this.fb.group({
      category: new UntypedFormGroup({})
    });
    this.formReady.emit(this.InventoryWashFormGrp);
  }



  /**
  * After a form is initialized, we link it to our main form
  */
  formInitialized(form: UntypedFormGroup) {
    this.InventoryWashFormGrp.setControl('category', form);
  }
}
