import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StateService } from 'src/app/eatecui/source/shared/services/state.service';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
// import * as MasterData from '../../assets/json/lib/config.json';
import { AdDataModel, AzureAdModel, intialAdDataModel, OAuthSettingsConfig, TenantInformation, TenantINIConfig, TenantModel } from './azure-ad.interface';
// import * as config from '../../assets/json/config.json';
// import * as TenantInformationDefault from '../../assets/json/lib/tenant-info.json';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { ConfigModel, EnvironmentGlobal } from 'src/app/eatecui/environments/environment-global';
import { MatDialog } from '@angular/material/dialog';
import { AgilsysErrorPopComponent } from '../agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from '../agilysys-popup/model/pop-up.interface';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { QueryInterface } from 'src/app/eatecui/source/shared/models/query-interface';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private settings: any;
  private http: HttpClient;
  tenantInformation: TenantInformation;
  AzureAdInformation: AzureAdModel;
  enableIntegration: boolean;
  integrationName: string;
  integrationConfig: any;
  enableDataImport: any;
  enableDMIntegration: boolean;
  enableSculptureIntegration: boolean;
  enablePunchthrough: boolean;
  enableInfoGenesis: boolean;
  enableExportARGL: boolean;
  enableSnCIntegration: boolean;
  enableOnDemandIntegration: boolean;
  passwordComplexTenantConfig: Array<any> = [];
  dataImportConfig: any;
  licenseConfig: Array<any> = [];
  constructor(
    private readonly httpHandler: HttpBackend,
    private stateService: StateService,
    private httpService: HttpService,
    private dialog: MatDialog,
  ) {
    this.http = new HttpClient(httpHandler);
    this.enableIntegration = false;
    this.integrationName = null;
  }

  init(endpoint: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const MasterData: any = await this.http.get<any>('assets/json/lib/config.json').toPromise();
      this.settings = MasterData;
      const tenantInfo: any = await this.http.get<any>('assets/json/lib/default-info.json').toPromise();
      this.tenantInformation = tenantInfo;
      const appsettingUrl = 'assets/json/app-settings.json';
      const appSettingJson: any = await this.http.get<any>(appsettingUrl).toPromise();
      if (!sessionStorage.getItem('SessionInactivityEnable')) {
        sessionStorage.setItem('SessionInactivityEnable', '1');
      }
      if (!sessionStorage.getItem('SessionInactivityTime')) {
        sessionStorage.setItem('SessionInactivityTime', appSettingJson.SessionInactivityTime);
      }
      const appSettingString = JSON.stringify(appSettingJson);
      sessionStorage.setItem('appsetting', appSettingString);
      const currentTennat = StorageService.GetSessionStorage(constants.Tenant);
      const tenanatName = window.location.hash.split('/')[1];
      const tenantIdentifier = (tenanatName && tenanatName !== '/') ? tenanatName :
        (currentTennat && currentTennat !== undefined && currentTennat !== '' && currentTennat !== null) ?
          currentTennat : this.stateService.tenant;
      const getPath = document.location.pathname;
      const Environ = new EnvironmentGlobal();
      const getTransaction = 'assets/json/config.json';
      const configData: ConfigModel = await this.http.get<ConfigModel>(getTransaction).toPromise();
      const EnvConfig: ConfigModel = Environ.setEnvVariables(configData);
      const regex = /^[A-Za-z1-9-]+$/;
      const isValid = regex.test(tenantIdentifier);
      const that = this;
      if (isValid) {
        // For Eatec
        // const requestUrl = EnvConfig.apiUrl + `/tenantservice/api/TenantService/tenants?tenantIdentifier=${tenantIdentifier}`;
        // Rguest
        const requestUrl = 'assets/json/tenant-info.json';
        const UserFlowUrl = EnvConfig.apiUrl + CONSTANT.ENDPOINTURL['TenantManagementApi']['GetUserFlow'];
        this.http.get(requestUrl).pipe(map(res => res))
          .subscribe((responseData: TenantModel) => {
            this.http.get(UserFlowUrl).subscribe((UserFlow: Array<any>) => {      
              resolve(true);
              const productUserFlow = (UserFlow && UserFlow.length > 0) ? UserFlow[0].ConfigValue : null;
              const TenantReponseData: TenantModel = responseData;
              if (responseData && Object.keys(responseData).length > 0) {
                const IsMFAEnabled = (TenantReponseData.hasOwnProperty('isMFAEnabled')) ? TenantReponseData.isMFAEnabled : null;
                const APGLIntegration = (TenantReponseData.hasOwnProperty('customSQLConfiguration')) ?
                  TenantReponseData.customSQLConfiguration : null;
                const IsAdEnabled = (TenantReponseData.hasOwnProperty('isAdEnabled')) ? TenantReponseData.isAdEnabled : null;
                const IsProductAuthentication = (TenantReponseData.hasOwnProperty('isProductAuthentication')) ? TenantReponseData.isProductAuthentication : null;
                const rGuestTenantId = (TenantReponseData.hasOwnProperty('rGuestTenantId')) ? TenantReponseData.rGuestTenantId : null;
                const StorageData = (TenantReponseData.hasOwnProperty('storageDataEnabled') &&
                TenantReponseData.storageDataEnabled) ? 'true' : null;
                sessionStorage.setItem('TenantIdentifier', tenantIdentifier);
                // set storage data in session
                sessionStorage.setItem('StorageData', StorageData);
                // set defaultinidateformat for application
                StorageService.SetSessionStorage(constants.IniDateFormat, constants.DefaultIniDateFormat);
                sessionStorage.setItem(constants.IniDateFieldFormat, constants.DefaultIniDateFormat);
                // set ismfaenabled boolean
                StorageService.SetSessionStorage(constants.IsMFAEnabled, IsMFAEnabled.toString());
                // set IsAdEnabled boolean
                StorageService.SetSessionStorage(constants.IsAdEnabled, IsAdEnabled.toString());
                StorageService.SetSessionStorage(constants.IsProductAuthentication, IsProductAuthentication.toString());
                // set rGuestTenantId
                // StorageService.SetSessionStorage('RGuestTenantId', rGuestTenantId);
                sessionStorage.setItem('RGuestTenantId', rGuestTenantId);
                // set apglintegration customsqlconfiguration
                sessionStorage.setItem(constants.APGLIntegration, APGLIntegration.toString());
                // set tenant name          
                sessionStorage.setItem('TenantName', TenantReponseData.tenantName);
                // set tenant id
                sessionStorage.setItem('TenantId', TenantReponseData.id);
                // set date configuration from the tenant based
                if (TenantReponseData.hasOwnProperty('tenantINIConfig') && TenantReponseData.tenantINIConfig.length > 0) {
                  sessionStorage.setItem(constants.IniEnableGridSort, JSON.stringify(constants.DefaultIniEnableGridSort)); // Add Default Sorting;
                  StorageService.SetSessionStorage(constants.IniDateFormat, constants.DefaultIniDateFormat);
                  sessionStorage.setItem(constants.IniDateFieldFormat, constants.DefaultIniDateFormat);
                  sessionStorage.setItem(constants.IniItemListSelectionCount, JSON.stringify(constants.ItemSelectionCount));
                  sessionStorage.setItem(constants.IniPostLimit, JSON.stringify(constants.TotalItemListRowCount));

                  TenantReponseData.tenantINIConfig.forEach((iniConfig: TenantINIConfig)=> {
                       if( iniConfig.configKey === 'UILongDateFormat'){
                          const UIDateFormat = ( iniConfig?.configValue ) ? iniConfig?.configValue : constants.DefaultIniDateFormat
                          StorageService.SetSessionStorage(constants.IniDateFormat, UIDateFormat);
                          sessionStorage.setItem(constants.IniDateFieldFormat, UIDateFormat);
                       }
                       if( iniConfig.configKey === 'EnableGridSorting' ){
                         const enableGridSorting = ( iniConfig?.hasOwnProperty('configValue') ) ? 
                         ( iniConfig.configValue.toLowerCase() === 'true' ) ? true : false : constants.DefaultIniEnableGridSort;
                         sessionStorage.setItem(constants.IniEnableGridSort, JSON.stringify(enableGridSorting));
                       }
                       if( iniConfig.configGroup === 'PasswordValidation' ){
                        this.passwordComplexTenantConfig.push(iniConfig)
                      } 
                      if( iniConfig.configGroup === 'License' ){
                        this.licenseConfig.push(iniConfig)
                      }
                      if (iniConfig.configGroup === 'ItemListCountLimitation') {
                        const ItemSelectionCount = ( iniConfig?.hasOwnProperty('configValue') ) ? 
                         iniConfig.configValue : constants.ItemSelectionCount;
                        sessionStorage.setItem(constants.IniItemListSelectionCount, ItemSelectionCount.toString());
                      }
                      if (iniConfig.configGroup === 'PostItemLimitation') {
                        const PostItemLimitation = ( iniConfig?.hasOwnProperty('configValue') ) ? 
                         iniConfig.configValue : constants.IniPostLimit;
                        sessionStorage.setItem(constants.IniPostLimit, PostItemLimitation.toString());
                      }
                  });
                }
                // set Global Ad Data Information
                if (TenantReponseData.hasOwnProperty('adData')) {
                  const adModelObject: AdDataModel = (TenantReponseData.adData !== null) ? JSON.parse(TenantReponseData.adData) :
                    intialAdDataModel;
                  /**
                    * sample for AdAzure */ 
                  // adModelObject.AzureAdType = "4";
                  // adModelObject.ClientId = "ce940e84-f08a-4196-863a-ad05cfdd62d3";
                  // adModelObject.TenantId = "9750a820-9364-4bc3-9990-123c1645274b";

                  /***Store Tenanat Information */
                  const azureTenantInformation = {} as TenantInformation;
                  azureTenantInformation.Id = 1;
                  azureTenantInformation.TenantID = adModelObject.TenantId;
                  azureTenantInformation.TenantName = this.stateService.tenant;
                  azureTenantInformation.TenantIdentifier = this.stateService.tenant;
                  // azureTenantInformation.ClientSecret = adModelObject.ClientSecret;
                  azureTenantInformation.ClientSecret = null;
                  azureTenantInformation.ClientID = adModelObject.ClientId;
                  azureTenantInformation.IsAdEnabled = true;
                  azureTenantInformation.IsActive = true;
                  azureTenantInformation.AzureAdType = (adModelObject.hasOwnProperty('AzureAdType')) ?
                    adModelObject.AzureAdType : null;
                  azureTenantInformation.EnableProductAuthentication = TenantReponseData.isProductAuthentication;
                  // eslint-disable-next-line max-len
                  azureTenantInformation.ProductAuthority = `${adModelObject.AzureADB2CAuthority}/` + `${adModelObject.AzureAdB2CTenant}/` + productUserFlow;
                  azureTenantInformation.ProductUserFlow = productUserFlow;
                  azureTenantInformation.AzureADB2CAuthority = (adModelObject.hasOwnProperty('AzureADB2CAuthority')) ?
                    adModelObject.AzureADB2CAuthority : null;
                  azureTenantInformation.AzureAdB2CUserflow = (adModelObject.hasOwnProperty('AzureAdB2CUserflow')) ?
                    adModelObject.AzureAdB2CUserflow : null;

                  const AzureAuthAdType = (adModelObject.hasOwnProperty('AzureAdType')) ? adModelObject.AzureAdType : null;
                  const OAuthSettings = {} as OAuthSettingsConfig;
                  OAuthSettings.appId = adModelObject.ClientId;
                  OAuthSettings.scopes = (AzureAuthAdType !== null) ? ['openid'] : ['openid', 'profile', 'User.Read', 'offline_access'];
                  azureTenantInformation.OAuthSettings = OAuthSettings;
                  azureTenantInformation.AdData = adModelObject;
                  this.tenantInformation = { ...azureTenantInformation }; // set all tenant Information
                  /****Store Azure Ad Model */
                  const AzureAdType = (adModelObject.hasOwnProperty('AzureAdType')) ? adModelObject.AzureAdType : null;
                  const adAureModel = {} as AzureAdModel;
                  adAureModel.clientID = adModelObject.ClientId;
                  adAureModel.redirectUri = (AzureAdType !== null && AzureAdType !== constants.AzureAdType.LDAP) ? `${window.location.origin}`: window.location.origin;
                  adAureModel.cacheLocation = MasterData.cacheLocation;
                  // adAureModel.authority = (AzureAdType !== null && AzureAdType !== constants.AzureAdType.LDAP) ? `${adModelObject.AzureADB2CAuthority}/` + `${adModelObject.AzureAdB2CTenant}/` + `${adModelObject.AzureAdB2CUserflow}` : MasterData.authority;
                  adAureModel.authority = this.constractAdAuthority(AzureAdType, adModelObject, MasterData)
                  OAuthSettings.authority = adAureModel.authority;
                  azureTenantInformation.OAuthSettings = OAuthSettings;
                  adAureModel.extraQueryParameters = null;
                  // adAureModel.protectedResourceMap = MasterData.protectedResourceMap; Remove as per discussed
                  adAureModel.protectedResourceMap = MasterData.protectedResourceMap;
                  adAureModel.unprotectedResources = MasterData.unprotectedResources;
                  adAureModel.tenant = adModelObject.TenantId;
                  adAureModel.tenantIdentifier = TenantReponseData ? TenantReponseData.tenantIdentifier.toLowerCase() :
                    this.stateService.tenant;
                  if (AzureAdType !== null && AzureAdType !== constants.AzureAdType.LDAP) {
                    adAureModel.signUpSignInPolicy = adModelObject.AzureAdB2CUserflow;
                  }
                  // window.localStorage.setItem('clientId', adModelObject.ClientId);
                  if (adModelObject?.ClientId !== null) {
                    window.localStorage.setItem('clientId', adModelObject.ClientId);
                  }
                  this.stateService.authClientBool = true;
                  this.settings = adAureModel;
                  this.AzureAdInformation = adAureModel;
                }
                if ( TenantReponseData?.integrationCofig ) {
                  this.integrationConfig = JSON.parse(TenantReponseData.integrationCofig);
                 const GetIntKeys = Object.keys(this.integrationConfig);
                  this.enableIntegration = this.checkEnableIntegration();
                  this.integrationName = GetIntKeys[0];
                  const dmIntegrationBool: boolean = (this.integrationConfig.DataMagine.toLowerCase() === 'false') ? false : true;
                  this.enableDMIntegration = dmIntegrationBool;
                  this.enableSculptureIntegration = (this.integrationConfig.Sculpture.toLowerCase() === 'false') ? false : true;
                  this.enablePunchthrough = true;
                  this.enableInfoGenesis = (this.integrationConfig.InfoGenesis.toLowerCase() === 'false') ? false : true;
                  this.enableExportARGL = (this.integrationConfig.ARGLExport.toLowerCase() === 'false') ? false : true;
                  this.enableSnCIntegration = (this.integrationConfig['S&C'].toLowerCase() === 'false') ? false : true;
                  this.enableOnDemandIntegration = (this.integrationConfig['OnDemand'].toLowerCase() === 'false') ? false : true;
                  StorageService.SetSessionStorage(constants.DocApprovals, this.integrationConfig.DocApprovals);
                }
                if (TenantReponseData.hasOwnProperty('dataImportConfig') && TenantReponseData.dataImportConfig) {
                  this.dataImportConfig = JSON.parse(TenantReponseData.dataImportConfig);
                }
                this.enableDataImport = TenantReponseData.enableDataImport ? TenantReponseData.enableDataImport : '';
                sessionStorage.setItem('enableDataImport', this.enableDataImport.toString());                
              }
              // });
            });
          },
          (error) => {
            resolve(true);
          });
      } else {
        setTimeout(() => {
          resolve(true);
          const ErrorPopUpDialog: ErrorPopModel = {
            PopupHeaderContent: 'Info',
            PopUpContent: 'Invalid Input Character',
          };
          const dialogRef = that.dialog.open(AgilsysErrorPopComponent, {
            width: '400px',
            data: ErrorPopUpDialog,
            disableClose: true
          });
        }, 100);

      }
    });
  }

  //   setEnvVariables(): string {
  //     let apiUrl = '';
  //    const hostname = window && window.location && window.location.hostname;
  //    if (/^.*localhost.*/.test(hostname)) {
  //      apiUrl = config.local.apiUrl;
  //    } else if (/^eatec-webui-dev.rguest.com/.test(hostname)) {
  //      apiUrl = config.dev.apiUrl;
  //    } else if (/^eatec-webui-qa.rguest.com/.test(hostname)) {
  //      apiUrl = config.qa.apiUrl;
  //    }  else if (/^eatec-webui-release.rguest.com/.test(hostname)) {
  //       apiUrl = config.release.apiUrl;
  //    } else if (/^eatec-webui-prod-westus.onagilysys.com/.test(hostname)) {
  //       apiUrl = config.prodwestus.apiUrl;
  //    } else if (/^eatec.onagilysys.com/.test(hostname)) {
  //       apiUrl = config.prodwestus.apiUrl;
  //    } else {
  //      apiUrl = config.dev.apiUrl;
  //    }
  //    return apiUrl;
  //  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    const result = key.reduce((acc: any, current: string) => acc && acc[current], this.settings);

    return result;
  }
  /**
     * @method checkEnableIntegration
     * @input None
     * @description check whether integration is enabled or not
     */
  checkEnableIntegration(): boolean {
    let enableInteg = false;
    for (const key in this.integrationConfig) {
        if (Boolean(this.integrationConfig[key])) {
          const integrationBool: boolean = (this.integrationConfig[key].toLowerCase() === 'false') ? false : true;
          if ( integrationBool) {
              enableInteg = integrationBool;
          }
        }
    }
    return enableInteg;
  }

  /**

   * @method contractAdAuthority

   * @input method AdType and AdModelObject

   * @description constract AzureAd Data

   */

  constractAdAuthority( AzureAdType: string, adModelObject: AdDataModel, MasterData: any ): string {
    try{
      let adAuthority: string = MasterData.authority
       if ( AzureAdType ){
            if( AzureAdType === constants.AzureAdType.LDAP ){
              adAuthority = MasterData.authority;
            } else if( AzureAdType === constants.AzureAdType.CUSTOMERB2C || AzureAdType === constants.AzureAdType.PRODUCTB2C ){
              adAuthority = `${adModelObject.AzureADB2CAuthority}/` + `${adModelObject.AzureAdB2CTenant}/` + `${adModelObject.AzureAdB2CUserflow}`;
            } else if( AzureAdType === constants.AzureAdType.AzureAdSingle){
              // adAuthority = `https://login.microsoftonline.com/` + `${adModelObject.TenantId}/federationmetadata/2007-06/federationmetadata.xml?appid=${adModelObject.ClientId}`;
              // adAuthority = `/9750a820-9364-4bc3-9990-123c1645274b/federationmetadata/2007-06/federationmetadata.xml?appid=9f8f8514-8349-4805-967a-9912a13c0206`;
              //adAuthority = 'https://login.microsoftonline.com/9750a820-9364-4bc3-9990-123c1645274b/federationmetadata/2007-06/federationmetadata.xml?appid=9f8f8514-8349-4805-967a-9912a13c0206';
              adAuthority = `https://login.microsoftonline.com/${adModelObject.TenantId}/federationmetadata/2007-06/federationmetadata.xml?appid=`+`${adModelObject.ClientId}`;
            }
            return adAuthority;
       }

    } catch(error){

      console.error(error);

    }

  }
}
