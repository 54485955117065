import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { ApplyWithinType, CancellationNoShowDetails, CancellationNoShowPolicy, ChargeType, DataPayload, SubjectDataType, 
TeeTimePriceType, MappingDataPayload, MappingSubjectDataType, MainGroup} from './cancellation-no-show-policy.model';
import { Guid } from 'guid-typescript';
import { Localization } from '../../localization/Localization';
import { DefaultGUID } from '../globalsContant';

@Injectable({providedIn:'root'})
export class CancallationNoShowSharedService{
    private dataSubject = new BehaviorSubject<DataPayload | null>(null);
    private mappingDataSubject = new BehaviorSubject<MappingDataPayload | null>(null);
    public policyGroupId: string = Guid.EMPTY;

    data$ = this.dataSubject.asObservable();
    mappingData$ = this.mappingDataSubject.asObservable();

    constructor( private localization: Localization){}

    sendData(type: SubjectDataType, data: any, errorMessage: string = '') {
        this.dataSubject.next({type, data, errorMessage});
    }

    clearSendData() {
        this.dataSubject.next(null);
    }

    sendMappingData(type: MappingSubjectDataType, data: any, errorMessage: string = '') {
        this.mappingDataSubject.next({type, data, errorMessage});
    }

    clearSendMappingData() {
        this.mappingDataSubject.next(null);
    }

    mapPolicyToObject(policyFormDetails: any, isUpdate: boolean = false): CancellationNoShowPolicy {
        var obj: CancellationNoShowPolicy = {
            id: isUpdate ? policyFormDetails.id : 0,
            code: policyFormDetails.policyCode,
            name: policyFormDetails.policyName,
            description: policyFormDetails.policyDescription,
            isActive: policyFormDetails.isActive,
            cancellationNoShowDetails: this.mapPolicyDetails(policyFormDetails, isUpdate)
        }
        return obj;
    }

    mapPolicyDetails(policyFormDetails: any, isUpdate: boolean = false): CancellationNoShowDetails[] {
        var detailsObj: CancellationNoShowDetails[] = [];
        var cancellationRecord = policyFormDetails.charges?.find(c => c.isCancellationPolicy);

        //NoShow Charge
        detailsObj.push({
            id: isUpdate ? policyFormDetails.noShowDetailsId : 0,
            poicyId: isUpdate ? policyFormDetails.id : 0,
            isCancellationPolicy: false,
            includeCharges: Array.isArray(policyFormDetails.noShowIncludeCharges) ? JSON.stringify(policyFormDetails.noShowIncludeCharges) : null,
            isActive: policyFormDetails.isNoShowActive,
            isApplyWithinDays: true,
            applyWithinDuration: 0,
            isFullPrice: policyFormDetails.noShowPriceType == TeeTimePriceType.FullPrice,
            isPercentage: policyFormDetails.noShowChargeType == ChargeType.Percentage,
            charge: this.localization.currencyToSQLFormat(policyFormDetails.noShowCharge)
        });

        if (cancellationRecord != null) {
            policyFormDetails.charges.forEach(charge => {
                if (charge.isCancellationPolicy) {
                    detailsObj.push({
                        id: isUpdate ? charge.id : 0,
                        poicyId: isUpdate ? policyFormDetails.id : 0,
                        isCancellationPolicy: true,
                        includeCharges: Array.isArray(policyFormDetails.cancellationIncludeCharges) ? JSON.stringify(policyFormDetails.cancellationIncludeCharges) : null,
                        isActive: policyFormDetails.isCancellationActive,
                        isApplyWithinDays: charge.applyWithin == ApplyWithinType.Days,
                        applyWithinDuration: charge.applyWithinHoursDays == null ? 0 : charge.applyWithinHoursDays,
                        isFullPrice: charge.priceType == TeeTimePriceType.FullPrice,
                        isPercentage: charge.chargeType == ChargeType.Percentage,
                        charge: this.localization.currencyToSQLFormat(charge.charge)
                    })
                }
            });
        }
        return detailsObj;
    }

    mapPolicyMappingDetails(mappingDetails: any, applyToOption: any, mainGroupList: any, isUpdate: boolean = false)
    {

        return {
            id: 0,
            startDate: this.localization.convertDateObjToAPIDateOnly(mappingDetails.startDate),
            endDate: this.localization.convertDateObjToAPIDateOnly(mappingDetails.endDate),
            policyId: mappingDetails.policyId,
            guestTypes: applyToOption.filter(a => a.selected).map(a => a.id),
            mappingGroupId: isUpdate ? this.policyGroupId : DefaultGUID,
            policyMapping: mainGroupList
        };
    }

    clearMainGroupList(mainGroup: MainGroup[]){
        mainGroup.forEach(m => {
            if(m.selected){
                m.subGroup.forEach(s => s.selected = false);
                m.selected = false;
                m.isSubGroupSelected = false;
            }
        });
    }

    clearApplyToOptions(applyToOptions: any){
        applyToOptions?.forEach(a => a.selected = false);
    }

}