import { QueryInterface } from 'src/app/eatecui/source/shared/models/query-interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { CustomFilterQuery, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { rejects } from 'assert';
import { FormBuilderGroupKey, MasterInventoryManagement, RestDataCollection, RestDataConfiguration } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { GlobalClientFilter, GlobalMasterRestData } from '../global-custom-config.interface';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';

export const CustomClientFilter: GlobalClientFilter = (collection: RestDataCollection, FormBuilderGrouping: FormBuilderGroupKey, 
CollectionKey: string): any => {
    try {
        const radioOption =  [];
        if ( collection ) {
            const GetdataCollection: Array<any> = collection[CollectionKey];
            if ( GetdataCollection && GetdataCollection.length > 0 ) {
                const getClientFilter: Array<any> = FormBuilderGrouping.CustomFilterQuery;
                if ( getClientFilter && getClientFilter.length > 0 ) {
                    getClientFilter.forEach((cusFilter: CustomFilterQuery) => {
                        if ( cusFilter.hasOwnProperty('EnableClientFilter') && cusFilter.EnableClientFilter) {
                            if ( cusFilter.Type === 'Equal' ) {
                                const DataCollection = GetdataCollection.filter(x => x [cusFilter.OdataKey] === cusFilter.DefaultValue);
                                if ( DataCollection && DataCollection.length > 0 ) {
                                    DataCollection.forEach((optionCollection: any) => {
                                        const option = {} as RadioOptions;
                                        option.key = optionCollection['Id'];
                                        option.label = optionCollection['Name'];
                                        radioOption.push(option);
                                    });
                                }
                            }
                        } else {
                            GetdataCollection.forEach((datacollection: any) => {
                                const option = {} as RadioOptions;
                                option.key = datacollection['Id'];
                                option.label = datacollection['Name'];
                                radioOption.push(option);
                            });
                        }
                    });
                } else {
                    GetdataCollection.forEach((datacollection: any) => {
                        const option = {} as RadioOptions;
                        option.key = datacollection['Id'];
                        option.label = datacollection['Name'];
                        radioOption.push(option);
                    });
                    return radioOption;
                }
            }
        }
        return radioOption;
    } catch (error) {
        console.error(error);
    }
};