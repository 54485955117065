import { Injectable } from '@angular/core';
import { DataUtilityDataService } from 'src/app/common/dataservices/datautility.data.service';
import {API, UI} from 'src/app/common/Models/datautilitydetails-model'

@Injectable()
export class DMBusiness {
    constructor(private _dataservice: DataUtilityDataService) {
    }  
    
    public getAllConfiguration(settingType: number): Promise<API.Setting[]> {        
        return this._dataservice.getAllConfiguration(settingType);
    }
    public saveDMDefaults(dMDefaults:  {[key: string]: any }, settingIds : {[key: string]: number }) {
        return this._dataservice.createConfiguration(this.APIMapper(dMDefaults,settingIds));
    }
    private APIMapper(dMDefaults: {[key: string]: any }, settingIds : {[key: string]: number }): API.Setting[] {
        let dMDefaultsList:API.Setting[] = [];
        const maximumImportCount:API.CommonSettings ={maximumImportCount : dMDefaults.maximumImportCount}
        const versConfigs: API.VersaSettings = {
            skipGuestAdvanceSearch : dMDefaults.skipAdvanceSearch ?? false,
            allowOverrideGuestGuid : dMDefaults.allowGuestIdOverride ?? false
        }
        const commonSettings:API.Setting ={
            id: settingIds.commonSettingsId,
            settingType: API.SettingKeys.CommonSettings,
            value: JSON.stringify(maximumImportCount)
        } 
        const versaSetting: API.Setting = {          
            id: settingIds.versaSettingsId,          
            settingType: API.SettingKeys.VersaSettings,
            value: JSON.stringify(versConfigs)
        };
        dMDefaultsList.push(commonSettings);
        dMDefaultsList.push(versaSetting);
        return dMDefaultsList;
    }
    
}
