<h4 class="golf--group--header" *ngIf="formFieldHeader">{{formFieldHeader}}</h4>
<section *ngIf="fieldType == 'MultipleData'">
<div [formGroup]="form" #formtext>
  <div formArrayName="{{formArrayName}}">
    <div *ngFor="let formObj of form.get(formArrayName).controls; let j=index; let l= last;">
      <div>
        <!-- <i *ngIf="field.iconName" class="icon-{{field.iconName}} pr8"></i> -->
        <mat-form-field class="golftextbox" title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
          form.controls[this.formFieldName]?.value : formFieldToolTip}}" appearance="fill">
          <input [attr.type]="hidePassword ? typeField : field.type " [id]="j" [name]="j"
            formControlName="{{j}}" matInput placeholder="{{field.fieldType.MultipleData.data[j].label}}" (click)="getIndex(j)" (blur)="eventCallback(j)" autocomplete="off">
            <mat-error *ngIf="!isValid && isDirty">{{ validationMesage | errorvalidation : form.controls[formFieldName].controls[j].errors : field.fieldType.MultipleData.data[j]}}</mat-error>
          <i *ngIf="field.type == 'password'"
             matSuffix [ngClass]="hidePassword ? 'icon-password-show-eatec' : 'icon-password-hide-eatec '"  (click)="showPassword()"
             ></i>
        </mat-form-field>
        <app-form-icon *ngIf="field.fieldType.MultipleData.addMore && j !== 0" [objectIndex]="j" [formIconType]="formRemoveBuilderIcons"
          [formArray]="form.get(formArrayName)" [formObject]="formArrayName" (iconEmitter)="iconCallback($event, form)">
        </app-form-icon>
        <app-form-icon class="golf--add-more"
          *ngIf="field.fieldType.MultipleData.addMore && j == (form.get(formArrayName).controls.length-1) && field.fieldType.MultipleData.maxCount >= form.get(formArrayName).controls.length"
          [formIconType]="formAddBuilderIcons" [formArray]="form.get(formArrayName)" [formObject]="field.fieldType.MultipleData.data[j]"
          (iconEmitter)="iconCallback($event)" (fielArrayEmiter)="fieldArrayCallback($event)">
        </app-form-icon>
      </div>
    </div>
  </div>
</div>
</section>
<section *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'" class="{{field.class}} agilysys-lib-textbox text-{{formFieldName}}">
    <div [formGroup]="form" #formtext class="agilysys-lib-textbox-div" title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
      form.controls[this.formFieldName]?.value : formFieldToolTip}}">
    <!-- <i *ngIf="field.iconName" class="icon-{{field.iconName}} pr8"></i> -->
    <mat-form-field [hideRequiredMarker]="false" class="golftextbox {{field.class}}" appearance="fill" [ngClass]="field.fieldType[fieldType].disbaledProperty === true ? 'textbox-disabled':  ''">
      <i  *ngIf="field.EnableDropDownSearch" matPrefix class="agilysys-prefix-icons icon-{{field.iconName}}"></i>
      <mat-label>{{formFieldlabel}}</mat-label>
      <!-- <i class="label-icon-class icon-Approve_Filled"></i> -->
      <input [attr.type]="hidePassword ? typeField : formfieldType " [id]="formFieldName" [name]="formFieldName" 
        [formControlName]="formFieldName" matInput placeholder="{{formFieldlabel}}" (blur)="eventCallback()"  (keydown)="keyPress($event)" (paste)="onPaste($event)"
        [readonly]="field.fieldType[fieldType].disbaledProperty" [required]="isRequired" autocomplete="off" decimalConveter [FormField]="field" [ngClass]="field.EnableSuffixicon ? 'inputbox-with-icon': ''">
      <mat-error>{{errorMessage}}</mat-error>
      <i *ngIf="field.type == 'password'"
         matSuffix [ngClass]="hidePassword ? 'icon-password-show-eatec' : 'icon-password-hide-eatec '"  (click)="showPassword()"
         ></i>
        <mat-hint *ngIf="formHintLabel">{{formHintLabel}}</mat-hint>
    </mat-form-field>
    <i  *ngIf="field.EnableSuffixicon" matSuffix class="agilysys-suffix-icons icon-{{field.iconName}}" (click)="suffixIconClick()"></i>
    </div>
</section>
