import { Component,  Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-medical-warning-dialog',
  templateUrl: './medical-warning-dialog.component.html',
  styleUrls: ['./medical-warning-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MedicalWarningDialogComponent  {

  constructor(public dialogRef: MatDialogRef<MedicalWarningDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  DialogClose(result?:any): void {
      this.dialogRef.close(result);
  }

}
