import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ServiceParams} from '../../Models/http.model';
import { HttpCallService } from './http-call.service';
import { AppService } from '../../app-service';
import { Localization } from '../../localization/localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';

export class RguestPayCommunication extends HttpCallService {
    constructor(route: string, httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService) {
        super(route, httpclient, localization, utilities, PropertyInfo, appService);
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        try {
            const response$: Promise<T> = super.getPromise<T>(params);

            // on error =>
            response$.catch(err => this.error(err, handleErr));

            // on success =>
            return await response$;
        } catch (e) {
            console.error(e);
            throw e;
       }
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        try {
            const response$: Promise<T> = super.postPromise<T>(params);

            // on error =>
            response$.catch(err => this.error(err, handleErr));

            // on success =>
            return await response$;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
    
    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err.error;
        }
    }

}