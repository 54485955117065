import { TranslateService } from '@ngx-translate/core';
import { DefaultIniEnableGridSort, IniEnableGridSort } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';
import { GridColumnModels } from 'src/app/eatecui/source/shared/models/grid-header-models';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridHeaderConfig, QueryGridHeaderList, TransactionConfiguration } from '../transaction.interface';
import { QueryConfiguration } from './query-configuration.interface';
export const QueryGridHeaders: QueryConfiguration = (
    QueryHeaderEntity: any, 
    QueryDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService,
    responseData ?: any
    ): GridHeaderConfig[] => {
        try {
            if ( QueryHeaderEntity && QueryDetailsEntity && TransactionCollection) {
                const GridQuerHeader = [];
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryGridHeaderList.forEach(
                (queryGridHeaderList: QueryGridHeaderList) => {
                    const gridHeaderConfig = {} as GridHeaderConfig;
                    gridHeaderConfig.headerName = queryGridHeaderList.DisplayName;
                    gridHeaderConfig.field = queryGridHeaderList.Odatakey;
                    gridHeaderConfig.editable = false;
                    gridHeaderConfig.hide = GridColumnModels.Hide(responseData);
                    gridHeaderConfig.sortOrder = GridColumnModels.SortOder(responseData);
                    gridHeaderConfig.sortable = GridColumnModels.Sortable(responseData);
                    gridHeaderConfig.customSort = GridColumnModels.CustomSort(responseData);
                    gridHeaderConfig.sortDirection = queryGridHeaderList.Order === 'one' ? 'asc' : ''; 
                    gridHeaderConfig.tooltipField = queryGridHeaderList.Odatakey;
                    gridHeaderConfig.suppressMovable = true;
                    gridHeaderConfig.resizable = true;
                    gridHeaderConfig.minWidth = '75';
                    gridHeaderConfig.fieldType = queryGridHeaderList.FieldType;
                    if (queryGridHeaderList.Order === 'one') {
                        gridHeaderConfig.width = '400';
                    }
                    GridQuerHeader.push(gridHeaderConfig);
                });              
                const checkboxHeader = {} as GridHeaderConfig;
                checkboxHeader.headerCheckboxSelection = true;
                checkboxHeader.checkboxSelection = true;
                checkboxHeader.suppressMovable = true;
                checkboxHeader.width = '50';
                checkboxHeader.editable = false;
                checkboxHeader.headerName = '';
                checkboxHeader.sortable = false;
                checkboxHeader.field = 'check';                
                GridQuerHeader.unshift(checkboxHeader);  

                const iconRenderer = {} as GridHeaderConfig;
                iconRenderer.headerName = '';
                iconRenderer.field =  'icon';
                iconRenderer.sortable =  false;
                iconRenderer.editable =  false;
                iconRenderer.minWidth =  '10';
                iconRenderer.width =  '30';
                iconRenderer.categoryIcon =  {
                    EnableCategoryIcon:  true,
                    CategoryIconNames: ''
                };           
                iconRenderer.cellRenderer = function (params: any) {
                    let a = `<div class='grid-icon'>`;
                    const IconObject = CategoryIcons.categoryIconName(params.data);
                    // console.log(IconObject);
                    if (IconObject !== null && IconObject !== undefined &&
                        IconObject) {
                        a += `<i class="${IconObject.IconName}" title="${IconObject.IconToolTip}"></i>`;
                    }
                    a += `</div>`;
                    return a;
                };
                GridQuerHeader.splice(1, 0, iconRenderer);   
                const itemListSortEnabled = sessionStorage.getItem(IniEnableGridSort) ? 
                   JSON.parse(sessionStorage.getItem(IniEnableGridSort)) : DefaultIniEnableGridSort;
                GridQuerHeader.forEach(header => {
                    header.customSort = !itemListSortEnabled && header.customSort ? false : header.customSort;
                });
                return GridQuerHeader; 
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };