import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import * as GlobalConst from 'src/app/shared/globalsContant';
import { BaseResponse } from "../shared.modal";
import { SystemConfig } from "../business/shared.modals";
import { SpaUtilities } from '../utilities/spa-utilities';

@Injectable(
  { providedIn: "root"}
)
export class VipTypBusiness {
  constructor( public http: HttpServiceCall,private utils: SpaUtilities) {
  }

  async getAllVipType(includeInactive) {
    const results = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllVipType",
      method: HttpMethod.Get,
      uriParams: { "isIncludeInactive": includeInactive }
      });
    return results.result;
  }

  async createVipType(vipType) {
    return await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "CreateVipType",
      method: HttpMethod.Post,
      body: vipType,
      showError: true
    });
  }

  async updateVipType(vipType, id) {
    return await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "UpdateVipType",
      method: HttpMethod.Put,
      body: vipType,
      uriParams: { "id": id },
      showError: true
    });
  }

  async deleteVipType(id) {
    return await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "DeleteVipType",
      method: HttpMethod.Delete,
      uriParams: { "id": id },
      showError: true
    });
  }

  async getNextListOrderofVipType() {
    var response = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "VipTypeNextListOrder",
      method: HttpMethod.Get,
    });
    return response.result;
  }

  async dragDropVipType(fromOrder, toOrder, includeInactive){
    var response = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "VipTypeDrag",
      method: HttpMethod.Patch,
      uriParams: { "fromOrder": fromOrder, "toOrder": toOrder,  "includeInactive": includeInactive}
    });
    return response.result;
  }
}