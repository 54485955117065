import { Component, ViewEncapsulation } from '@angular/core';
import { RouteLoaderService } from '../core/services/route-loader.service';
import { menuTypes } from '../shared/shared.modal';
import { SettingsService } from './settings.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailService } from '../retail/retail.service';
import { CommissionSetupService } from '../retail/commission-setup/commission-setup.service';
import { RetailPropertyInformation } from '../retail/common/services/retail-property-information.service';


@Component({
  selector: 'setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingComponent  {

  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  menuList: any;
  menuType = menuTypes;
  menu :any;

  constructor(private routeDataService: RouteLoaderService,private ss: SettingsService,private rs: RetailService,private cs:CommissionSetupService
    , private propertyInfo : RetailPropertyInformation) {
    const value = this.routeDataService.GetChildMenu('/settings');
    this.menu = value.linkedElement;

    if (this.propertyInfo.IsEatecAsMaster) {
      const configsToRemove = ['/settings/enhancedInventory', '/settings/inventory'];
      this.menu = this.menu.filter(r => !configsToRemove.includes(r.routePath));
    } else if (this.propertyInfo.IsEatecEnabled) {
      const configsToRemove = ['/settings/inventory'];
      this.menu = this.menu.filter(r => !configsToRemove.includes(r.routePath));
      this.propertyInfo.setICRoutes(this.menu);
    }
    else if (!this.propertyInfo.IsEatecEnabled) {
      let configsToRemove = ['/settings/enhancedInventory'];
      this.menu = this.menu.filter(r => !configsToRemove.includes(r.routePath));
    }


    this.menuList = {
      menu: this.menu,
      menuType : menuTypes.secondary
    };

    this.ss.tabLoaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader=>{
      this.ToggleLoader(loader);
     });

     //inventory loader
     this.rs.tabLoaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader=>{
      this.ToggleLoader(loader);
     });

     //commision setup loader
     this.cs.tabLoaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader=>{
      this.ToggleLoader(loader);
     });

  }
 
  ngOnDestroy() {
    if (this.$destroyed) {
        this.$destroyed.next(true);
        this.$destroyed.complete();
    }
}

  ToggleLoader(loader){
    const loadingContainer = document.getElementById('cover-spin');
        if (loadingContainer) {
          if (loader) {
            loadingContainer.style.display = 'block';
          }
          else {
            loadingContainer.style.display = 'none';
          }
        }
  }

}
