import { ConstructEngineeringGrid } from './engineering-grid.interface';
import { GridActionData } from './grid-action.interface';
import { GridSearchFilter } from './grid-client-searchfilter.interface';
import { GridClientSort } from './grid-client-sort.interface';
import { GetCellRendererColumn } from './grid-column-renderer.interface';
import { GridColumnResize } from './grid-column-resize.interface';
import { GridHeaderColumnType } from './grid-column-type.interface';
import { GridFilterCustom } from './grid-custom-filter.interface';
import { GridData } from './grid-data.interface';
import { GridFilterRefresh } from './grid-filter-refresh.interface';
import { GridFilter } from './grid-filter.interface';
import { GridHeaderColumn } from './grid-header-column.interface';
import { GridColumnHeaderName } from './grid-header-name.interface';
import { GridFilterKeyMapping } from './grid-keymapping.interface';
import { GridFilterLabelMapping } from './grid-labelmapping.interface';
import { GridSingleFilterOptionFetch } from './grid-master-singlefilter-fetch.interface';
import { GridName } from './grid-name.interface';
import { GridOdataKey } from './grid-odatakey.interface';
import { GridSearchPlaceholder } from './grid-search.interface';
import { GridShowActive } from './grid-showactive.interface';
import { GridShowAdd } from './grid-showadd.interface';
import { GridSortingOrder } from './grid-sorting.interface';
import { GridTableCards } from './grid-table-card.interface';
import { SetDefaultSort } from './set-default-sort.interface';

export const GridInterface = {
    GridName: GridName,
    GridOdataKey: GridOdataKey,
    GirdShowActive: GridShowActive,
    GirdShowAdd: GridShowAdd,
    GridFilter: GridFilter,
    GridFilterKeyMapping: GridFilterKeyMapping,
    GridFilterLabelMapping: GridFilterLabelMapping,
    GridSortingOrder: GridSortingOrder,
    GridSearch: GridSearchPlaceholder,
    GridHeaderColumn: GridHeaderColumn,
    GridHeaderColumnName: GridColumnHeaderName,
    GridHeaderColumnType: GridHeaderColumnType,
    GridHeaderRenderer: GetCellRendererColumn,
    GridColumnResize: GridColumnResize,
    GridData: GridData,
    GridActionData: GridActionData,
    GridSetDefaultSort: SetDefaultSort,
    GridTableCard: GridTableCards,
    GridCustomFilter: GridFilterCustom,
    GridSearchFilterData: GridSearchFilter,
    GridSortConfig: GridClientSort,
    GridSingleFilterFetch: GridSingleFilterOptionFetch,
    GridFilterRefresh: GridFilterRefresh,
    ConstructEngineeringGrid: ConstructEngineeringGrid
  };