import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { ActionTypes } from 'src/app/eatecui/source/shared/models/custom-action/custom-enum.interface';
import { GlobalCustomFormAction } from 'src/app/eatecui/source/shared/models/custom-action/global-custom-action.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { MasterInventoryManagement, NestedBuilderData } from '../master.interface';
import { MasterCustomNavigation } from './master-detail-config.interface';

export const MasterCustomNavigationValue: MasterCustomNavigation =
    (OdataKey: any,
        CustomUrl: string,
        httpService: HttpService, nestedData: NestedBuilderData, ViewModelEntity: any, ResponseData: any): any => {
        try {
            let customValue = OdataKey;
            const customOdatakey = ViewModelEntity[0]['$']['Name'].toLowerCase().toString() + '-data';
            if (ResponseData && ResponseData.length > 0 && nestedData.hasOwnProperty('ResponseDataKey')) {
                const dataValue = responseDataValueMapping(nestedData, ResponseData);
                customValue = MergeValue(nestedData, dataValue, customOdatakey, ResponseData[0]);
                nestedData.Value = customValue;
            } else {
                const id = nestedData.hasOwnProperty('MultipleFilter') && nestedData.MultipleFilter ?
                 OdataKey : parseInt(OdataKey, 10);
                const seleId = nestedData.hasOwnProperty('MultipleFilter') && nestedData.MultipleFilter ? 
                `id in (${id})` : `id eq ${id}`;
                const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                    return '%' + c.charCodeAt(0).toString(16);
                });
                const params: QueryParams = {
                    count: true,
                    top: InfiniteScrollItemCount,
                    skip: 0,
                    expand: '',
                    select: '',
                    filter: '',
                    orderby: '',
                    isactive: false,
                    search: '',
                    filterQuery: GetDataFilter,
                    searchtext: ''
                };
                const getQueryParams = formQueryParams(params);
                let GetDataByIdUrl = `${CustomUrl + getQueryParams}`;
                if (id) {
                    const getReturnValue = GlobalCustomFormAction(nestedData.Name, ViewModelEntity[0]['$'].Name,
                        nestedData.OdataKey, 'GridAction', ActionTypes.ADDCUSTOMURL);
                    if (getReturnValue && getReturnValue !== null) {
                        GetDataByIdUrl = getReturnValue;
                        GetDataByIdUrl = GetDataByIdUrl + `&$filter=${GetDataFilter}`;
                    }
                    httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                        if (x['value'].length > 0) {
                            if (nestedData.hasOwnProperty('MultipleFilter') && nestedData.MultipleFilter) {
                                nestedData.Value = x['value'].map(res => {return res.Name;}).join(', ');
                            } else {
                                customValue = MergeValue(nestedData, x['value'][0]['Name'], customOdatakey, x['value'][0]);
                                nestedData.Value = customValue;
                            }
                        }
                        return customValue;
                    });
                }
            }
            return customValue;
        } catch (error) {
            console.error('Error occurred in Setdefaultsort', error);
        }
    };

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function MergeValue( nestedData: NestedBuilderData, defaultValue: any, CustomOdataKey: string, resposeData: any ): any {
   try { 
      let returnMergedValue: any = ( defaultValue ) ? defaultValue : '-';
      const mergedValue = GlobalCustomFormAction(
        nestedData.OdataKey, CustomOdataKey, nestedData.OdataKey, 'GridAction',
        ActionTypes.CUSTOMMERGE, null, null, null, null, null, null, resposeData,
        null, null, null);
    if (mergedValue && mergedValue !== undefined && mergedValue !== null) {
        returnMergedValue = mergedValue;
    }
    return returnMergedValue;
   } catch ( error ) {
     console.error(error);
   }
}

function responseDataValueMapping(nestedData: NestedBuilderData, ResponseData: Array<any>): string | number {
    try {
        const responseData = ResponseData[0];
        let reasignValue = '-';
        for (const property in responseData) {
            if (nestedData.ResponseDataKey === property) {
                if ( responseData.hasOwnProperty(property) ) {
                    reasignValue = responseData[property];
                }
                if ( responseData[property] && responseData[property].Name ) {
                    reasignValue = responseData[property].Name;
                }
                if (nestedData.SubResponseDataKey) {
                    const sIndex: number = Object.keys(responseData[property])
                        .findIndex(e => e === nestedData.SubResponseDataKey);
                    if (sIndex > -1) {
                        if (  responseData[property] && responseData[property].hasOwnProperty(nestedData.SubResponseDataKey)) {
                            reasignValue = responseData[property][nestedData.SubResponseDataKey];
                            if ( responseData[property][nestedData.SubResponseDataKey] && 
                                 responseData[property][nestedData.SubResponseDataKey].Name ) {
                                reasignValue = responseData[property][nestedData.SubResponseDataKey].Name;

                            }              
                        }
                        
                    }
                }
            }
        }
        return reasignValue;
    } catch (error) {
        console.error(error);
    }
}
