import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SpaLocalization } from "src/app/core/localization/spa-localization";
import { UI, API, ActionMode } from "../dynamic-yielding.model";
import { ViewYieldSetupComponent } from "../view-yield-setup/view-yield-setup.component";
import { DynamicYieldingListBusiness } from "./dynamic-yielding-list.business";
import { DynamicYieldingBusiness } from "../dynamic-yielding.business";
import { DropdownOptions } from "src/app/common/Models/ag-models";
import { SpaUtilities } from "src/app/shared/utilities/spa-utilities";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-dynamic-yielding-list",
  templateUrl: "./dynamic-yielding-list.component.html",
  styleUrls: ["./dynamic-yielding-list.component.scss"],
  providers: [DynamicYieldingListBusiness],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicYieldingListComponent implements OnInit, OnChanges {
  listViewData: API.YieldingDetails[] = [];
  listViewDataClone: API.YieldingDetails[] = [];
  captions: any;
  @Output() actionEmitter = new EventEmitter();
  @Input() isViewOnly = false;
  @Input() viewDate: { startDate: Date; endDate: Date; forceReload?: boolean };
  @Input() serviceGroupFilterSelected: UI.IListViewServiceFilter;
  @Input() serviceFilterSelected: UI.IListViewServiceFilter;
  @Input() strategyFilterSelected: UI.IListViewServiceFilter;
  @Input() menuFilter: UI.MenuFilter;
  @Input() deletedGroupId: string = "";
  isInActive: boolean = false;
  @Input("toggleActive")
  set active(value) {
    this.isInActive = value;
    this.toggleActive(value);
  }
  previousViewDate: { startDate: Date; endDate: Date } | undefined;
  viewData: UI.ViewPricingData;

  @Input("isExpandAll")
  set expansion(value) {
    this.toggleAccordion(value);
  }

  @Input("resetList")
  set reset(value) {
    if (value)
      this.setListAndFilter({ dynamicServiceGroup: [], dynamicPricing: [] });
  }

  @Output() serviceDetails = new EventEmitter<
    [DropdownOptions[], DropdownOptions[], DropdownOptions[]]
  >();
  @Output() filteredServiceDetails = new EventEmitter<
    [DropdownOptions[], DropdownOptions[], DropdownOptions[], DropdownOptions[]]
  >();
  @Output() strategyOptions = new EventEmitter<DropdownOptions[]>();
  filterDropDownClone: [
    DropdownOptions[],
    DropdownOptions[],
    DropdownOptions[]
  ] = [[], [], []];
  @Output() filteredListData = new EventEmitter<API.YieldingDetails[]>();

  constructor(
    private dialog: MatDialog,
    private localization: SpaLocalization,
    private business: DynamicYieldingListBusiness,
    private dynamicBusiness: DynamicYieldingBusiness,
    public utilities: SpaUtilities
  ) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.getListView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewDate && changes.viewDate?.isFirstChange()) {
      this.previousViewDate = null;
    }

    if (
      changes.viewDate &&
      changes.viewDate.currentValue &&
      changes.viewDate.previousValue
    ) {
      const previousValue = this.previousViewDate;
      const currentValue = changes.viewDate.currentValue;
      if (!this.viewDate.endDate) return;

      if (!previousValue) {
        this.getListView();
        this.previousViewDate = changes.viewDate.previousValue;
        return;
      }
      if (
        this.localization.GetFormattedDateDDMMYY(previousValue.startDate) !==
          this.localization.GetFormattedDateDDMMYY(currentValue.startDate) ||
        this.localization.GetFormattedDateDDMMYY(previousValue.endDate) !==
          this.localization.GetFormattedDateDDMMYY(currentValue.endDate)
      ) {
        this.getListView();
      } else if (currentValue?.forceReload) {
        this.getListView();
      }
      this.previousViewDate = changes.viewDate.previousValue;
    }

    if (
      changes.strategyFilterSelected &&
      changes.strategyFilterSelected.currentValue
    ) {
      if (this.strategyFilterSelected.isFilterRequired) {
        this.filterList();
      }
    }

    if (
      changes.serviceFilterSelected &&
      changes.serviceFilterSelected.currentValue
    ) {
      if (this.serviceFilterSelected.isFilterRequired) {
        this.filterList();
      }
    }

    if (
      changes.serviceGroupFilterSelected &&
      changes.serviceGroupFilterSelected.currentValue
    ) {
      if (this.serviceGroupFilterSelected.isFilterRequired) {
        this.filterList();
      }
    }

    if (
      changes.menuFilter &&
      changes.menuFilter.currentValue &&
      !changes.menuFilter?.firstChange
    ) {
      this.filterList();
    }

    if (changes.deletedGroupId && changes.deletedGroupId.currentValue) {
      this.listViewDataClone = this.listViewDataClone.filter(
        (d) => d.groupId !== this.deletedGroupId
      );

      this.filterList();
    }
  }

  /**
   * get service yieling list view
   */
  async getListView() {
    if (this.viewDate && this.viewDate.startDate && this.viewDate.endDate) {
      this.utilities.ToggleLoader(true);
      try {
        await this.dynamicBusiness
          .getPricingDetailsListView(
            this.localization.convertDateTimeToAPIDateTime(
              this.viewDate.startDate
            ),
            this.localization.convertDateTimeToAPIDateTime(
              this.viewDate.endDate
            )
          )
          .then((data) => {
            if (data) {
              this.setListAndFilter(data);
            }
          })
          .catch(() => {
            this.listViewData = [];
          })
          .finally(() => {
            this.utilities.ToggleLoader(false);
          });
      } catch (e) {
        this.utilities.ToggleLoader(false);
      }
    } else {
      this.listViewData = [];
      this.listViewDataClone = [];
    }
  }

  /**
   * set list service drop down filters
   * @param data
   */
  setListAndFilter(data: any) {
    let serviceGroupDetails = [];
    let serviceDetails = [];
    let strategy = [];
    this.listViewData = this.listViewDataClone = [];
    if (data.dynamicServiceGroup?.length > 0) {
      serviceGroupDetails = [
        ...new Map(
          data.dynamicServiceGroup.map((item) => [
            item["serviceGroupId"],
            {
              id: item.serviceGroupId,
              value: item.serviceGroupId,
              viewValue: item.serviceGroupName,
              checked: true,
            },
          ])
        ).values(),
      ].sort((a: any, b: any) => a.viewValue.localeCompare(b.viewValue));

      serviceDetails = [
        ...new Map(
          data.dynamicServiceGroup.map((item) => [
            item["serviceId"],
            {
              id: item.serviceId,
              value: item.serviceGroupId,
              viewValue: item.serviceName,
              checked: true,
            },
          ])
        ).values(),
      ].sort((a: any, b: any) => a.viewValue.localeCompare(b.viewValue));
    }

    if (data.dynamicPricing?.length > 0) {
      this.listViewData = data.dynamicPricing
        .sort((a, b) => {
          return (
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          );
        })
        .map((data) => {
          data.startDate = this.localization.getformattedDateMMDDYYYY(
            new Date(data.startDate)
          );
          data.endDate = this.localization.getformattedDateMMDDYYYY(
            new Date(data.endDate)
          );
          data.pricingDetails = data.pricingDetails
            .filter((d) => !d.isOverride)
            .map((pd) => {
              const daysOfWeek: [string, number[]] =
                this.business.getDaysOfWeek(pd.applicableDays);
              const startTime = this.localization.ConvertDateToTime(
                pd.startTime
              );
              const endTime = this.localization.ConvertDateToTime(pd.endTime);
              pd.serviceAvailableTimeRange = `${startTime} - ${endTime}`;
              pd.isRuleExpanded = true;
              pd.applicableDays = daysOfWeek[0];
              pd.daysOfWeek = daysOfWeek[1];
              pd.serviceNames = serviceDetails
                .filter((data) => pd.services.includes(data.id))
                .map((s) => s.viewValue);
              const rules: any[] = pd.rule;
              pd.rule = rules.map((r) => {
                return {
                  id: r.id,
                  ruleCategoryId: r.dynamicPricingRuleId,
                  pricingCondition: r.dynamicPricingCondition,
                  pricingConditionValue: r.conditionValue,
                  dynamicAvailability: r.dynamicAvailability,
                  isIncrement: r.isIncrement,
                  isPercent: r.isPercent,
                  pricingStrategyValue: r.dynamicPricingStrategyValue,
                  stopAvailability: r.dynamicAvailability,
                };
              });
              return pd;
            });
          if (!data.pricingDetails) {
            data.pricingDetails = [];
          }
          if (data.overrideDetails) {
            data.overrideDetails
              .filter((o) => o.pricingDetails.length > 0)
              .map((od) => {
                od.startDate = this.localization.getformattedDateMMDDYYYY(
                  new Date(od.startDate)
                );
                od.endDate = this.localization.getformattedDateMMDDYYYY(
                  new Date(od.endDate)
                );
                const priceDetails = od.pricingDetails
                  .filter((d) => d.isOverride)
                  .map((opd) => {
                    const daysOfWeek: [string, number[]] =
                      this.business.getDaysOfWeek(opd.applicableDays);
                    const startTime = this.localization.ConvertDateToTime(
                      opd.startTime
                    );
                    const endTime = this.localization.ConvertDateToTime(
                      opd.endTime
                    );
                    opd.serviceAvailableTimeRange = `${startTime} - ${endTime}`;
                    opd.isRuleExpanded = true;
                    opd.applicableDays = daysOfWeek[0];
                    opd.daysOfWeek = daysOfWeek[1];
                    opd.serviceNames = serviceDetails
                      .filter((data) => opd.services.includes(data.id))
                      .map((s) => s.viewValue);
                    const rules: any[] = opd.rule;
                    opd.rule = rules.map((r) => {
                      return {
                        id: r.id,
                        ruleCategoryId: r.dynamicPricingRuleId,
                        pricingCondition: r.dynamicPricingCondition,
                        pricingConditionValue: r.conditionValue,
                        dynamicAvailability: r.dynamicAvailability,
                        isIncrement: r.isIncrement,
                        isPercent: r.isPercent,
                        pricingStrategyValue: r.dynamicPricingStrategyValue,
                        stopAvailability: r.dynamicAvailability,
                      };
                    });
                    return opd;
                  });

                if (priceDetails && priceDetails?.length > 0) {
                  od.pricingDetails = [
                    ...new Map(
                      priceDetails.map((item) => {
                        item.serviceNames = [
                          ...new Set(
                            priceDetails
                              .filter(
                                (pds) =>
                                  pds.overrideGroupId ===
                                  item["overrideGroupId"]
                              )
                              .flatMap((s) => s.serviceNames)
                          ).values(),
                        ].sort((a: any, b: any) => a.localeCompare(b));
                        return [item["overrideGroupId"], item];
                      })
                    ).values(),
                  ];
                } else {
                  od.pricingDetails = [];
                }
              });
          }
          return data;
        })
        .filter(
          (d) => d.pricingDetails.length > 0 || d.overrideDetails?.length > 0
        );
      strategy = [
        ...new Map(
          this.listViewData.map((item) => [
            item["groupId"],
            {
              id: item.groupId,
              value: item.groupId,
              viewValue: item.name,
              checked: true,
              otherData: item.isActive,
            },
          ])
        ).values(),
      ].sort((a: any, b: any) => a.viewValue.localeCompare(b.viewValue));
    }
    this.listViewDataClone = cloneDeep(this.listViewData);
    serviceDetails = strategy?.length > 0 ? serviceDetails : [];
    serviceGroupDetails = strategy?.length > 0 ? serviceGroupDetails : [];
    this.filterDropDownClone = cloneDeep([
      serviceGroupDetails,
      serviceDetails,
      strategy,
    ]);
    this.serviceDetails.emit([
      <DropdownOptions[]>serviceGroupDetails,
      <DropdownOptions[]>serviceDetails,
      <DropdownOptions[]>(
        strategy.filter((s) => (this.isInActive ? true : s.otherData))
      ),
    ]);
  }

  /**
   * filter list
   */
  filterList() {
    let filterView = cloneDeep(this.listViewDataClone);

    let groupId: string[] = this.strategyFilterSelected?.selectedOptions.map(
      (s) => s.value
    );

    const serviceId: number[] = this.serviceFilterSelected?.selectedOptions.map(
      (s) => Number(s.id)
    );
    const serviceGroupId: number[] =
      this.serviceGroupFilterSelected?.selectedOptions.map((s) => Number(s.id));
    const groupFilter = filterView.filter((data) => {
      return groupId.includes(data.groupId);
    });
    if (groupId.length === 0) {
      this.listViewData = [];
      this.filteredServiceDetails.emit([[], [], [], []]);
      this.filteredListData.emit(this.listViewData);
      return;
    }

    this.cascadeDropdown(cloneDeep(groupFilter));
    this.listViewData = groupFilter
      .map((data) => {
        data.pricingDetails = data.pricingDetails
          .map((pd) => {
            pd.rule = pd.rule.filter((r) => {
              return (
                (this.menuFilter.availability.length > 0
                  ? this.menuFilter.availability.includes(
                      r.dynamicAvailability ? 1 : 0
                    )
                  : true) &&
                Number(r.pricingConditionValue) >= this.menuFilter.rangeStart &&
                Number(r.pricingConditionValue) <= this.menuFilter.rangeEnd
              );
            });
            return pd;
          })
          .filter((d) => {
            return (
              d.services.some((sv) => serviceId.includes(sv)) &&
              d.serviceGroup.some((sv) => serviceGroupId.includes(sv)) &&
              (this.menuFilter.availability.length > 0
                ? this.menuFilter.availability.includes(
                    d.stopAvailability ? 1 : 0
                  ) || d.rule.length > 0
                : true) &&
              this.filterMenuList(d)
            );
          });
        if (data.overrideDetails) {
          data.overrideDetails = data.overrideDetails
            .map((od) => {
              od.pricingDetails = od.pricingDetails.filter((d) => {
                return (
                  d.services.some((sv) => serviceId.includes(sv)) &&
                  d.serviceGroup.some((sv) => serviceGroupId.includes(sv)) &&
                  (this.menuFilter.availability.length > 0
                    ? this.menuFilter.availability.includes(
                        d.stopAvailability ? 1 : 0
                      ) || d.rule.length > 0
                    : true) &&
                  (d.rule.length === 0 ||
                    d.rule.some((r) => {
                      return this.menuFilter.availability.length > 0
                        ? this.menuFilter.availability.includes(
                            r.dynamicAvailability ? 1 : 0
                          )
                        : true;
                    }))
                );
              });
              return od;
            })
            .filter(
              (d) =>
                (this.isInActive ? true : d.isActive) &&
                d.pricingDetails.length > 0
            );
        }
        return data;
      })
      .filter(
        (d) =>
          (this.isInActive ? true : d.isActive) &&
          (d.pricingDetails.length > 0 || d.overrideDetails?.length > 0)
      );
    this.filteredListData.emit(this.listViewData);
  }

  /**
   * cascade service group and service dropdown
   * @param groupFilter
   */
  cascadeDropdown(groupFilter: API.YieldingDetails[]) {
    let serviceIds = new Set<number>();
    let serviceGroupIds = new Set<number>();
    let filterDropDown = cloneDeep(this.filterDropDownClone);
    let serviceGroupId: number[] =
      this.serviceGroupFilterSelected?.selectedOptions.map((s) => Number(s.id));
    let serviceId: number[] = this.serviceFilterSelected?.selectedOptions.map(
      (s) => Number(s.id)
    );
    groupFilter.forEach((data) => {
      data.pricingDetails.forEach((data) => {
        data.serviceGroup.map((d) => serviceGroupIds.add(d));
        data.services.map((d) => serviceIds.add(d));
      });
      if (data.overrideDetails) {
        data.overrideDetails.forEach((od) => {
          od.pricingDetails.forEach((opd) => {
            opd.serviceGroup.map((d) => serviceGroupIds.add(d));
            opd.services.map((d) => serviceIds.add(d));
          });
        });
      }
    });
    const serviceGroup = filterDropDown[0].filter((d) =>
      serviceGroupIds.has(<number>d.id)
    );
    serviceGroupId = serviceGroupId.filter((d) =>
      serviceGroup.some((sg) => sg.id === d)
    );
    const service = filterDropDown[1].filter(
      (d) => serviceIds.has(<number>d.id) && serviceGroupId.includes(d.value)
    );
    this.filteredServiceDetails.emit([
      serviceGroup,
      service,
      serviceGroup.filter((d) => serviceGroupId.includes(Number(d.id))),
      service.filter((d) => serviceId.includes(Number(d.id))),
    ]);
  }

  /**
   * filter menu list
   * @param pds
   * @returns
   */
  filterMenuList(pds: API.PricingDetails) {
    if (this.menuFilter && pds) {
      const startTime =
        this.menuFilter.startTime !== ""
          ? this.localization.LocalizedTimeToISODateTime(
              this.menuFilter.startTime
            )
          : "";
      const endTime =
        this.menuFilter.endTime !== ""
          ? this.localization.LocalizedTimeToISODateTime(
              this.menuFilter.endTime
            )
          : "";
      const serviceStartTime = this.localization.LocalizedTimeToISODateTime(
        this.localization.ConvertDateToTime(pds.startTime)
      );
      const serviceEndTime = this.localization.LocalizedTimeToISODateTime(
        this.localization.ConvertDateToTime(pds.endTime)
      );
      return (
        (this.menuFilter.daysOfWeek.length > 0
          ? pds.daysOfWeek.some((d) => this.menuFilter.daysOfWeek.includes(d))
          : true) &&
        (pds.rule.some(
          (r) =>
            Number(r.pricingConditionValue) >= this.menuFilter.rangeStart &&
            Number(r.pricingConditionValue) <= this.menuFilter.rangeEnd
        ) ||
          pds.rule.length === 0) &&
        (startTime === ""
          ? true
          : serviceStartTime.GetDateTimeFromString().getTime() >=
            startTime.GetDateTimeFromString().getTime()) &&
        (endTime === ""
          ? true
          : serviceEndTime.GetDateTimeFromString().getTime() <=
            endTime.GetDateTimeFromString().getTime())
      );
    }
    return true;
  }

  /**
   * toggle date Accordion
   * @param strategy
   */
  toggleDateAccordion(strategy: any) {
    strategy.isDateExpanded = !strategy.isDateExpanded;
  }

  /**
   * toggle rule Accordion
   * @param timeline
   * @param fromOverride
   */
  toggleRuleAccordion(timeline: any, fromOverride: any) {
    if (!fromOverride) {
      timeline.isRuleExpanded = !timeline.isRuleExpanded;
    }
  }

  /**
   * toggle list Accordion
   * @param value
   */
  toggleAccordion(value: any) {
    this.listViewData.forEach((element) => {
      element.isDateExpanded = value;
      element.pricingDetails.forEach((el) => {
        el.isRuleExpanded = value;
      });
    });
    this.listViewData = [...this.listViewData];
  }

  /**
   * edit price
   * @param event
   * @param e -- API.YieldingDetails
   */
  editPrice(event: any, e: API.YieldingDetails) {
    if (e.pricingDetails[0]) {
      let mode = e.pricingDetails[0]?.isOverride
        ? ActionMode.EditOverride
        : ActionMode.Edit;
      this.actionEmitter.emit({
        mode: mode,
        obj: e.pricingDetails[0],
        from: "slot",
      });
    }
  }

  /**
   * copy price
   * @param event
   * @param e -- API.YieldingDetails
   */
  copyPrice(event: any, e: API.YieldingDetails) {
    if (e.pricingDetails[0]) {
      this.actionEmitter.emit({
        mode: ActionMode.Copy,
        obj: e.pricingDetails[0],
      });
    }
  }

  /**
   * delete price
   * @param event
   * @param e -- API.YieldingDetails
   */
  deletePrice(event: any, e: API.YieldingDetails) {
    if (e.pricingDetails[0]) {
      this.actionEmitter.emit({
        mode: ActionMode.Delete,
        obj: e.pricingDetails[0],
      });
    }
  }

  viewAllPrice(event: any, element: API.YieldingDetails) {
    if (element) {
      this.dialog.open(ViewYieldSetupComponent, {
        panelClass: "ag_dialog--lg",
        data: {
          gridObj: element,
          setupForm: null,
        },
      });
    }
  }

  /**
   * toggle override active filter
   * @param isActive -- true/false
   */
  toggleActive(isShowInActive: boolean) {
    let filterDropDown = cloneDeep(this.filterDropDownClone);
    if (filterDropDown[2] && filterDropDown[2]?.length > 0) {
      let strategyOptions = filterDropDown[2].filter((d) =>
        isShowInActive ? true : d.otherData
      );

      let strategyIds = new Set(
        this.strategyFilterSelected?.selectedOptions.map((s) => String(s.id))
      );
      if (strategyIds.size > 0 || strategyOptions.length > 0) {
        strategyOptions.map((data) => {
          data.checked =
            strategyIds.has(String(data.id)) ||
            (isShowInActive && !data.otherData);
        });
      } else {
        strategyOptions = [];
      }

      this.strategyOptions.emit(strategyOptions);
    }
  }
}
