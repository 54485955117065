import { Component } from '@angular/core';
import { SpaLocalization } from '../../core/localization/spa-localization';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent {

  constructor(public localization: SpaLocalization) { }

}
