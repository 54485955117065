<div *ngIf="!field.hide">
  <mat-form-field title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
    form.controls[this.formFieldName]?.value : formFieldToolTip}}" class="" [formGroup]="form" appearance="fill" [ngClass]="field.fieldType[fieldType].disbaledProperty === true ? 'textbox-disabled':  ''">
    <mat-label>{{formFieldlabel}}</mat-label>
    <input matInput [format]="hourFormat" type="text" [formControlName]="formFieldName" [id]="formFieldName" [disableClick]="true" [value]=""
      [ngxTimepicker]="toggleTimepicker" autocomplete="off" [readonly]="true" [required]="isRequired" (click)="openPicker()">
    <ngx-material-timepicker-toggle matSuffix class="time-picker" [for]="toggleTimepicker"
      [disabled]="field.disbaledproperity"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker hoursOnly="{{isHoursOnly}}" (opened)="pickerTimeOpen($event)" (closed)="pickerTimeChanged($event)" #toggleTimepicker></ngx-material-timepicker>
    <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
  </mat-form-field>
</div>
