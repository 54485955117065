import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { AgDropdownConfig, DropdownOptions } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-select-outlet-popup',
  templateUrl: './select-outlet-popup.component.html',
  styleUrls: ['./select-outlet-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectOutletPopupComponent implements OnInit {
  captions;
  OutletConfig: AgDropdownConfig;
  outletForm: UntypedFormGroup;
  outlets: Promise<any[]>;
  isSaveDisabled = true;
  dialogObj;
  floatLabel: string;
  constructor(private localization: Localization, private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<SelectOutletPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData) {
                this.floatLabel = this.localization.setFloatLabel;
                this.outlets = dialogData.outlets;
               }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.buildForm();
  }

  buildForm() {
    this.outletForm = this.fb.group({
      defaultOutlet: ['', Validators.required]
    });
    this.outletForm.valueChanges.subscribe(x => {
      this.isSaveDisabled = !(this.outletForm.valid && this.outletForm.dirty);
    })
  }

  close() {
    if (this.dialogData.disableClose) {
      this.dialogObj = {
        type: 'close',
        dialogRef: this.dialogRef,
        // selectedIndex: this.stepperService.selectedIndex
      };
      // this.stepperService.valueChange.emit(this.dialogObj);
    } else {
      this.dialogRef.close('close');
      // this.stepperService.selectedIndex = 0;
    }
   
  
  }

  outletChange(eve) {

  }

  onSave() {
    let obj = {
      from: 'YES',
      outlet: this.outletForm.controls.defaultOutlet.value,
      actionType:this.dialogData.teeTimeType,
      info:this.dialogData.info
    }
    this.dialogRef.close(obj);
  }

  onCancel() {
    let obj = {
      from: 'NO'
    }
    this.dialogRef.close(obj);
  }

}
