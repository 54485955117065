import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { FormType, IconConfigs, SingleField } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { QueryInterface } from 'src/app/eatecui/source/shared/models/query-interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { FormInterface } from '.';
import { FormBuilderGroupKey, NestedDataBuilder } from '../master.interface';
import { FormModelFieldBuilder } from './form-config.interface';
import { MatDialog } from '@angular/material/dialog';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { LabelWithIconConfig, RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { RequestDataModel } from 'src/app/eatecui/source/shared/nonodatacore/models/requestdata.interface';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import * as constants from '@shared/constant/GlobalConstants';

export const FormCustomFieldTypes: FormModelFieldBuilder = (
    ViewModelEntity: any,
    PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey, translateService: TranslateService, CustomData?: any,
    sysytemPreferenceService?: SysytemPreferenceService,
    masterService?: any, customBuilder?: any, toasterService?: ToastrService, selectedValue?: any, MasterHomeGroup?: FormGroup,
    FormGroupSingleData?: any, FormBuilder?: any, createTransactionService?: CreateTransactionService, matDialog?: MatDialog, http?: HttpClient, 
    NestedDataBuild?: NestedDataBuilder, MasterParentFormGroup?: FormGroup): any => {
    try {
        const FormTypeSingleData = {} as SingleField;
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0) {
            let entityName = PostModelEntity[0]['$']['Name'].toString();
            if (FieldGrouping.hasOwnProperty('ParentNestedKey') && FieldGrouping.ParentNestedKey !== undefined &&
                  entityName !== FieldGrouping.ParentNestedKey) {
                entityName = entityName + FieldGrouping.ParentNestedKey;
            }
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            FormTypeSingleData.name = FieldGrouping.hasOwnProperty('CustomName') ? FieldGrouping.CustomName : FieldGrouping.OdataKey;
            if (FieldGrouping.hasOwnProperty('CustomToolTip') && FieldGrouping.CustomToolTip.EnableToolTip) {
                FormTypeSingleData.CustomToolTip = FieldGrouping.CustomToolTip;
                if ( translateService && FieldGrouping.CustomToolTip.ToolTipKey) {
                    FormTypeSingleData.CustomToolTip.ToolTipText = translateService
                    .instant(entityName + '.' + FieldGrouping.CustomToolTip.ToolTipKey);
                }
            }
            if (FieldGrouping.hasOwnProperty('hintLabel')) {
                FormTypeSingleData.hintLabel = FieldGrouping.hintLabel;
            }
            // FormTypeSingleData.disbaledProperty = FieldGrouping.DisableProperty ? FieldGrouping.DisableProperty : false;
            FormTypeSingleData.disbaledProperty = ProductDataRemap.FormDisplayDisabledRemapEngine(
                productremapJson, entityName, FieldGrouping.OdataKey, FieldGrouping.DisableProperty);
            if (FieldGrouping.OdataKey === 'IsActive' && MasterDataCollection.FormConfig &&
                MasterDataCollection.FormConfig.FlowMode.toLowerCase() === 'create') {
                FormTypeSingleData.disbaledProperty = true;
            } else if (FieldGrouping.OdataKey === 'IsActive' && MasterDataCollection.FormConfig &&
                MasterDataCollection.FormConfig.FlowMode.toLowerCase() !== 'create') {
                FormTypeSingleData.disbaledProperty = false;
            }
            FormTypeSingleData.label = ProductDataRemap.FieldDisplayNameEngine(productremapJson, entityName, FieldGrouping.DisplayName,
                FieldGrouping.OdataKey, translateService);
            //    FormTypeSingleData.hide = ( FieldGrouping.hasOwnProperty('Hide') ) ? FieldGrouping.Hide : false;
            const formHide = ProductDataRemap.FormDisplayHideRemapEngine(
                productremapJson, entityName, FieldGrouping.OdataKey, FieldGrouping);
            FormTypeSingleData.hide = formHide;
            if (FieldGrouping.FieldType === 'labelwithicon') {
                let labelwithIconConfigObj = {} as LabelWithIconConfig;
                if (FieldGrouping.OdataKey === 'DeliveryRulesSuppliers') {
                    let defaultIconConfig =  {} as IconConfigs;
                    defaultIconConfig.IconEnable = true;
                    defaultIconConfig.IconName = 'icon-Delivery-Rules-eatec';
                    labelwithIconConfigObj.DefaultIconConfig = defaultIconConfig;
                    let additionalIconConfig = {} as IconConfigs
                    additionalIconConfig.IconEnable = false;
                    additionalIconConfig.IconName = 'icon-Approve_Filled-eatec';
                    labelwithIconConfigObj.AdditionalIconConfig = additionalIconConfig;
                }
                FormTypeSingleData.LabelWithIconConfig = labelwithIconConfigObj;
            }
            FormTypeSingleData.value = FormInterface.FormAutoFillData(
                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, translateService);
            if (FieldGrouping.OdataKey === 'IsApproved') {
                /*
                use case 1:

                Recipe Approval - true, IsRecipeAdmin - true

                New Recipe - disable Approval for use, uncheck the checkbox
                Edit Recipe - When Approval for use is not set before, then enable Approval for use, uncheck the checkbox
                Edit Recipe - When Approval for use is already set before, then disable Approval for use, check the checkbox by default

                use case 2:

                Recipe Approval - false, IsRecipeAdmin - true

                New Recipe - disable Approval for use ,check the checkbox by default
                Edit Recipe - When Approval for use is not set before, then disable Approval for use, check the checkbox by default
                Edit Recipe - When Approval for use is already set before, then disable Approval for use, check the checkbox by default

                use case 3:

                Recipe Approval - true, IsRecipeAdmin - false
                New Recipe - disable Approval for use, uncheck the checkbox
                Edit Recipe - When Approval for use is not set before, then disable Approval for use, uncheck the checkbox by default
                Edit Recipe - When Approval for use is already set before, then disable Approval for use, check the checkbox by default

                use case 4:

                Recipe Approval - false, IsRecipeAdmin - false
                New Recipe - disable Approval for use, check the checkbox by default
                Edit Recipe - When Approval for use is not set before, then disable Approval for use, check the checkbox by default
                Edit Recipe - When Approval for use is already set before, then disable Approval for use, check the checkbox by default
                */
                const isRecipeAdmin = JSON.parse(sessionStorage.getItem('IsReceipeAdmin') !== null
                    ? sessionStorage.getItem('IsReceipeAdmin') : 'false');
                const isRecipeApproval = sysytemPreferenceService.globalSystemPreference.filter(x =>
                    x.LookupName === 'RecipeApproval' && x.LookupValue === 1).length > 0;
                const flowMode = MasterDataCollection.FormConfig.FlowMode.toLowerCase();
                if (isRecipeApproval) {
                    FormTypeSingleData.value = '';
                } else if (!isRecipeApproval) {
                    FormTypeSingleData.value = true;
                }
                if (flowMode === 'create') {
                    FormTypeSingleData.disbaledProperty = true;
                } else {
                    FormTypeSingleData.disbaledProperty = !(isRecipeApproval && isRecipeAdmin);
                }
            }
            FormTypeSingleData.FieldValidation = FormInterface.FormFieldValidations(
                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, translateService);
            FormTypeSingleData.options = FormInterface.FormFieldOption(
                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, translateService,
                 null, null, null, null, null, selectedValue, MasterHomeGroup, FormTypeSingleData, FormBuilder);
            if (CustomData === 'customrow') {
                FormTypeSingleData.type = FormInterface.FormGroupFieldTypes(
                    ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, translateService);
            }
            if (FieldGrouping.hasOwnProperty('AllowedPickerDate')) {
                FormTypeSingleData.AllowedPickerDate = FieldGrouping.AllowedPickerDate;
                /**
                 * Bug Fix - 202826, 202827
                 * TransUniqueName is used for check
                 * System Preference for Allow Posting of RQ/PR/PO to past dates.
                 */
                const AllowPastDateModulesList = ['PROpenRequest', 'PRTransactions', 
                'POOpenPurchaseRequest', 'POOpenRequisition', 'Requisition', 'POAllPurchaseOrders',
                'vendorpunchout'];
                if (sysytemPreferenceService.globalSystemPreference && 
                    sysytemPreferenceService.globalSystemPreference.length > 0) {
                        const filteredValue = sysytemPreferenceService.globalSystemPreference.
                        filter(x => x.LookupName === 'AllowpostingofRQ/PR/POtransactionstopastdates')[0];
                        const systemPreferenceLookupValue = filteredValue.LookupValue;
                        if (systemPreferenceLookupValue === 1 && 
                            (AllowPastDateModulesList.includes(MasterDataCollection.TransUniqueName))) {
                            FormTypeSingleData.AllowedPickerDate = '';
                        }
                }
            }
            FormTypeSingleData.autoCompleteData = [];
            FormTypeSingleData.onCellClick = function (e: any, field: any, form: any, showMore?: any) {
                if (MasterDataCollection.CreateTranscationConfig && MasterDataCollection.CreateTranscationConfig.flowFrom === 'copynew') {
                    MasterDataCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(buttonConfig => {
                      if ((buttonConfig.buttonKey === 'confirm' || buttonConfig.buttonKey === 'processpo' || 
                             buttonConfig.buttonKey === 'productionPost') && form.status === 'VALID') {
                        buttonConfig.displayProperity = false;
                      }
                    });
                }
                if (FieldGrouping.hasOwnProperty('CustomFormAction') && FieldGrouping.CustomFormAction.EnableCustomMethod) {
                    // If custom data for the interface is e form value
                    if (FieldGrouping.hasOwnProperty('autoCompleteHeader') && FieldGrouping.autoCompleteHeader.length > 0) {
                        const CheckArray = ['PONumber', 'ReplaceItem'];
                        const checkIndex = CheckArray.findIndex(x => x === FieldGrouping.Name);
                        if (e !== '' && checkIndex !== -1) {
                            FormInterface.FormCustomAction(
                                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, form,
                                field, e, sysytemPreferenceService, null, null, null, null, null, null, createTransactionService, matDialog, translateService);
                        } else if (checkIndex !== -1) {
                            const autoCompleteData = field.filter((x: FormType) => x.type === 'autocomplete');
                            const autoFormFields = autoCompleteData[0];
                            if (autoFormFields) {
                                autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [...[]];
                            }
                        } 
                        else if( FieldGrouping && FieldGrouping.Name === 'CustomerId' && MasterDataCollection && MasterDataCollection['Name'] === 'quickorder'){
                            FormInterface.FormCustomAction(
                                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, form,
                                field, e, sysytemPreferenceService, null, null, null, showMore, null, null, createTransactionService, matDialog, translateService);
                                cascading(field, MasterDataCollection, httpService, FieldGrouping, form);
                        } 
                        else {
                            FormInterface.FormCustomAction(
                                ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, form,
                                field, e, sysytemPreferenceService, null, null, null, showMore, null, null, createTransactionService, matDialog, translateService);
                        }
                    } else {
                        if( FieldGrouping && FieldGrouping.Name === 'OrderDate' && MasterDataCollection && MasterDataCollection['Name'] === 'quickorder'){
                                cascadingEvent(field, MasterDataCollection, httpService, FieldGrouping, form);
                        } 
                        else {
                        FormInterface.FormCustomAction(
                            ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, form,
                            field, CustomData, sysytemPreferenceService, masterService, customBuilder, e, null, toasterService, null, createTransactionService, 
                            matDialog, translateService, http, NestedDataBuild, MasterParentFormGroup);
                        }
                    }
                }
            };
            // if ( sessionStorage.getItem('RGuestTenantId') && JSON.parse(sessionStorage.getItem('RGuestTenantId'))) {
            //     if (FormTypeSingleData.name === 'LocationId' && FieldGrouping.hasOwnProperty('hintLabel')) {
            //         FormTypeSingleData.hintLabel = 'Note: ItemList will be cleared when the location is changed';
            //         FormTypeSingleData.onCellClick = function () {
            //             MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
            //         };
            //     }
            // }
        }
        return FormTypeSingleData;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function cascading(field: Array<FormType>, MasterDataCollection: any, httpService: HttpService, FieldGrouping: FormBuilderGroupKey, form: FormGroup ): void {
    try{
        let customerId = form.controls['CustomerId'].value;
        customerId = customerId && customerId.Id ? customerId.Id : customerId;
        if (customerId) {
            const UserId = sessionStorage.getItem('UserId') ? 
        parseInt(JSON.parse(sessionStorage.getItem('UserId')), 10) : 1;
        const LoggedInSiteId = sessionStorage.getItem('LoggedInSiteId') ? 
        parseInt(JSON.parse(sessionStorage.getItem('LoggedInSiteId')), 10) : 1;
        const LocationIds = JSON.parse(sessionStorage.getItem('FromLocId')) ?
        JSON.parse(sessionStorage.getItem('FromLocId')) : [];
        const payload = {} as RequestDataModel;{
            payload.FetchAll = true;
            payload.LocationIds = LocationIds;
            payload.userId = UserId;
            payload.siteId = LoggedInSiteId;
            payload.GetTotalRecCount = true;
            payload.IsActiveOnly = true;
            payload.LookupField = '';
            payload.SearchText = '';
            payload.totalCount = 0;
            payload.CustomerIds = [customerId];
            payload.orderByColumn = "Name";
            payload.orderByDirection = 1;
            payload.Skip = 0;
            payload.Take = 10000;
        }
        const conntactApiUrl = `/inventory/api/GetCustomerContacts`;
        httpService.PosthttpMethod(conntactApiUrl, payload).subscribe(e=> {
            if( MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields?.length > 0 && e && e['ResultData']) {
                MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                   if( x.fieldType.SingleData.name === 'CustomerContactId' ) {
                    if( e['ResultData'] && e['ResultData'].length > 0 ){
                        const contactValue: Array<any> = e['ResultData'];
                        const radioOPrion: Array<RadioOptions> = [];
                        contactValue.forEach(x=> {
                            const radiooption = {} as RadioOptions;
                            radiooption.key = x['Id'];
                            radiooption.label = x['LastName'] ? x['FirstName'] + ' ' + x['LastName'] : x['FirstName'];
                            radioOPrion.push(radiooption);
                        })
                        x.fieldType.SingleData.options = [...radioOPrion]
                        x.fieldType.SingleData.value = radioOPrion[0].key;
                        x.fieldType.SingleData.disbaledProperty = false;
                        form.controls['CustomerContactId'].setValue(radioOPrion[0].key);
                    } else {
                        x.fieldType.SingleData.options = [];
                        x.fieldType.SingleData.value = '';
                        x.fieldType.SingleData.disbaledProperty = true;
                    }
                   } else if (x.fieldType.SingleData.name === 'SalesPersonId') {
                    const contactValue: Array<any> = e['ResultData'];
                       if( e['ResultData'] && e['ResultData'].length > 0 && contactValue[0].SalesPersonId && 
                          x.fieldType.SingleData.options && x.fieldType.SingleData.options.length > 0 && x.fieldType.SingleData.options.filter(x => x.key === contactValue[0].SalesPersonId).length > 0){
                        x.fieldType.SingleData.value = contactValue[0].SalesPersonId;
                        form.controls['SalesPersonId'].setValue(contactValue[0].SalesPersonId);
                       }
                   }
                })
                MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach(x => x.fieldType.SingleData.name);
            } else {
                MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                    if( x.fieldType.SingleData.name === 'CustomerContactId' ) {
                        x.fieldType.SingleData.options = [];
                        x.fieldType.SingleData.value = '';
                        x.fieldType.SingleData.disbaledProperty = true;
                    }
                });
            }
           console.log(MasterDataCollection)
        });
        } else {
            MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                if( x.fieldType.SingleData.name === 'CustomerContactId' ) {
                    x.fieldType.SingleData.options = [];
                    x.fieldType.SingleData.value = '';
                    x.fieldType.SingleData.disbaledProperty = true;
                }
            });
        }
    } catch(error){

    }
}

function cascadingEvent(field: Array<FormType>, MasterDataCollection: any, httpService: HttpService, FieldGrouping: FormBuilderGroupKey, form: FormGroup ): void {
    try{
        let OrderDate = form.controls['OrderDate'].value;
        const currentStart = new Date(OrderDate);
        const currentDate = new Date();
        const utcStartDate = new Date(
            currentStart.getFullYear(),
            currentStart.getMonth(),
            currentStart.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes(),
            currentDate.getSeconds()
        );
        // const returnValue = moment
        //     .utc(utcStartDate)
        //     .format();
        const returnValue = moment(OrderDate).startOf('day').format(constants.PostDateTimeFormat);
        if (returnValue) {
            const UserId = sessionStorage.getItem('UserId') ? 
        parseInt(JSON.parse(sessionStorage.getItem('UserId')), 10) : 1;
        const LoggedInSiteId = sessionStorage.getItem('LoggedInSiteId') ? 
        parseInt(JSON.parse(sessionStorage.getItem('LoggedInSiteId')), 10) : 1;
        const LocationIds = JSON.parse(sessionStorage.getItem('FromLocId')) ?
        JSON.parse(sessionStorage.getItem('FromLocId')) : [];
        const payload = {} as RequestDataModel;{
            payload.FetchAll = false;
            payload.LocationIds = LocationIds;
            payload.userId = UserId;
            payload.siteId = LoggedInSiteId;
            payload.GetTotalRecCount = true;
            payload.IsActiveOnly = true;
            payload.LookupField = '';
            payload.SearchText = '';
            payload.totalCount = 0;
            payload.CustomerIds = [];
            payload.orderByColumn = "Name";
            payload.orderByDirection = 1;
            payload.Skip = 0;
            payload.Take = 10000;
            payload.postDate = returnValue;
            payload.statusIds = [1, 2];

        }
        const conntactApiUrl = `/inventory/api/GetAllEvents`;
        httpService.PosthttpMethod(conntactApiUrl, payload).subscribe(e=> {
            if( MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields?.length > 0 && e && e['ResultData']) {
                MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                   if( x.fieldType.SingleData.name === 'EventId' ) {
                    if( e['ResultData'] && e['ResultData'].length > 0 ){
                        const contactValue: Array<any> = e['ResultData'];
                        const radioOPrion: Array<RadioOptions> = [];
                        contactValue.forEach(x=> {
                            const radiooption = {} as RadioOptions;
                            radiooption.key = x['Id'];
                            radiooption.label = x['Name'] ? x['Name'] : '';
                            radioOPrion.push(radiooption);
                        })
                        x.fieldType.SingleData.options = [...radioOPrion]
                    } else {
                        x.fieldType.SingleData.options = [];
                        x.fieldType.SingleData.value = '';
                    }
                   } 
                })
                MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach(x => x.fieldType.SingleData.name);
            } else {
                MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                    if( x.fieldType.SingleData.name === 'EventId' ) {
                        x.fieldType.SingleData.options = [];
                        x.fieldType.SingleData.value = '';
                    }
                });
            }
           console.log(MasterDataCollection)
        });
        } else {
            MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                if( x.fieldType.SingleData.name === 'EventId' ) {
                    x.fieldType.SingleData.options = [];
                    x.fieldType.SingleData.value = '';
                }
            });
        }
    } catch(error){

    }
}

function checkReceieApprovalStatus(httpService: HttpService) {
    try {
        return new Promise<any>(resolve => {
            const LoginUser = sessionStorage.getItem('UserName');
            const paramsQuery: QueryParams = {
                count: true,
                top: 10,
                skip: 0,
                select: 'IsRecipeAdmin',
                filter: '',
                orderby: '',
                isactive: true,
                search: '',
                filterQuery: 'UserName eq \'' + LoginUser + '\'',
                searchtext: ''
            };
            const EndPointUrl = '/usermgmt/api/User' + `${QueryInterface.FormQuery(paramsQuery)}`;
            httpService.GethttpMethod(EndPointUrl).subscribe(x => {
                resolve(x);
            });
        });
    } catch (error) {
        console.error(error);
    }
}
