import { Injectable, OnDestroy } from '@angular/core';
import { HttpServiceCall, HttpMethod } from './http-call.service';
import { ClientType, Host, ImgRefType } from '../globalsContant';
import { UntypedFormArray } from '@angular/forms';
import { AppointmentpopupService } from './appointmentpopup.service';
import { SpaUtilities } from '../utilities/spa-utilities';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { MatDialog } from '@angular/material/dialog';
import { appointmentService } from './appointment.service';
import { GuestSearchData } from 'src/app/shared/appointment-popup/create-appointment/client-information/GuestSearch-model'
import { ClientInfo, GuestBasicInformation, ImageData, SubscribeProfile, ClientConfiguration, CustomFieldInfo } from '../business/shared.modals';


@Injectable()
export class ClientService implements OnDestroy {
  captions: any = this.localization.captions.bookAppointment;
  common: any = this.localization.captions.common;
  $destroyed: any;
  isVip: any = false;
  selectedIndex: any = 0;
  emailRequired = false;
  phoneRequired = false;
  requestUid = '';
  isExistingGuestSelectedForLinking: boolean = false;
  clientConfigurations: ClientConfiguration[];
  customFieldData: CustomFieldInfo[];

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
  constructor(private http: HttpServiceCall, private appointmentpopservice: AppointmentpopupService,
    private Utilities: SpaUtilities,
    public localization: SpaLocalization, public dialog: MatDialog, public appointmentServiceObject: appointmentService) { }

  async MandatoryFieldsValidation(clientData: any): Promise<boolean> {

    if (!this.clientConfigurations || !this.customFieldData || this.clientConfigurations.length == 0 || this.clientConfigurations.length == 0) {
      await this.GetClientMandatoryandCustomFields();
    }

    let valid: boolean = true;
    if (this.clientConfigurations && this.clientConfigurations.length > 0) {
      let config: any = this.clientConfigurations[0];

      valid = ((config.CLIENT_FIRST_NAME && !clientData.clientDetail.firstName ? false : true)
        &&
        (config.CLIENT_FIRST_NAME && !clientData.clientDetail.lastName ? false : true)
        &&
        (config.CLIENT_TITLE && !clientData.clientDetail.title ? false : true)
        &&
        (config.CLIENT_GENDER && !clientData.clientDetail.gender ? false : true)
        &&
        ((config.CLIENT_STATE && !(clientData.addresses && clientData.addresses.length > 0 && clientData.addresses[0].state)) ? false : true)
        &&
        ((config.CLIENT_CITY && !(clientData.addresses && clientData.addresses.length > 0 && clientData.addresses[0].city)) ? false : true)
        &&
        ((config.CLIENT_COUNTRY && !(clientData.addresses && clientData.addresses.length > 0 && clientData.addresses[0].country)) ? false : true)
        &&
        ((config.CLIENT_POSTAL_CODE && !(clientData.addresses && clientData.addresses.length > 0 && clientData.addresses[0].zip)) ? false : true)
        &&
        (config.CLIENT_BIRTHDAY && !clientData.clientDetail.dateOfBirth ? false : true)
        &&
        ((config.CLIENT_EMAIL && !(clientData.emails && clientData.emails.length > 0 && clientData.emails[0].emailId)) ? false : true)
        &&
        ((config.CLIENT_PHONE && !(clientData.phoneNumbers && clientData.phoneNumbers.length > 0 && clientData.phoneNumbers[0].number)) ? false : true)
        &&
        ((config.CLIENT_ADDRESS_LINE_1 && !(clientData.addresses && clientData.addresses.length > 0 && clientData.addresses[0].line1)) ? false : true)
        &&
        (config.CUSTOM_FIELD_1 && (this.customFieldData[0]?.displayOn == 1 || this.customFieldData[0]?.displayOn == 3) && !clientData.clientDetail.customField1 ? false : true)
        &&
        (config.CUSTOM_FIELD_2 && (this.customFieldData[1]?.displayOn == 1 || this.customFieldData[1]?.displayOn == 3) && !clientData.clientDetail.customField2 ? false : true)
        &&
        (config.CUSTOM_FIELD_3 && (this.customFieldData[2]?.displayOn == 1 || this.customFieldData[2]?.displayOn == 3) && !clientData.clientDetail.customField3 ? false : true)
        &&
        (config.CUSTOM_FIELD_4 && (this.customFieldData[3]?.displayOn == 1 || this.customFieldData[3]?.displayOn == 3) && !clientData.clientDetail.customField4 ? false : true)
        &&
        (config.CUSTOM_FIELD_5 && (this.customFieldData[4]?.displayOn == 1 || this.customFieldData[4]?.displayOn == 3) && !clientData.clientDetail.customField5 ? false : true)
      )
    }

    return valid;
  }

  async GetClientMandatoryandCustomFields() {
    let result: any = await this.http.CallApiAsync({
      callDesc: 'GetClientConfiguration',
      host: Host.spaManagement,
      method: HttpMethod.Get
    });
    this.clientConfigurations = result.result;

    let customField: any = await this.http.CallApiAsync({
      callDesc: 'GetCustomFields',
      host: Host.spaManagement,
      method: HttpMethod.Get
    });
    this.customFieldData = customField.result;
    this.customFieldData = this.customFieldData.sort((a, b) => a.id - b.id);
  }

  validateClientSave(): boolean {
    if (!this.appointmentpopservice.personalDetailsFormGroup.valid) {
      if (!this.appointmentpopservice.isClientViewOnly) {
        //prevents tab change while in viewonly mode.
        if (this.appointmentpopservice['isFromItinerary'] && this.appointmentpopservice.ClientSelectedTab != 0) {
          this.appointmentpopservice.ClientSelectedTab = 7;
        } else {
          this.appointmentpopservice.ClientSelectedTab = 0;
        }
      }
      this.appointmentpopservice.personalDetailsFormGroup.controls['firstName'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['lastName'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['pronounced'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['dob'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['pincode'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['title'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['Email'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['Phone'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['Address'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['emailPrimary'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['phonePrimary'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['state'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['city'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['country'].markAsTouched();
      this.appointmentpopservice.personalDetailsFormGroup.controls['postal_code'].markAsTouched();
      this.validatePhoneAndEmail();
      let addressArray = this.appointmentpopservice.personalDetailsFormGroup.get('Address') as UntypedFormArray;
      let addrElem: any = addressArray.at(0);
      addrElem.controls.addressLine.markAsTouched();
      return false;
    }

    if (this.appointmentpopservice.personalDetailsFormGroup.valid && this.appointmentpopservice.ClientSelectedTab != 1 && !this.appointmentpopservice.additionalDetailsFormGroup.valid) {

      if (!this.isPhoneNumberValid()) {
        return false;
      }
      if (!this.isEmailValide()) {
        return false;
      }
      this.appointmentpopservice.additionalDetailsFormGroup.controls['card_details'].markAsTouched();
      this.appointmentpopservice.additionalDetailsFormGroup.controls['expiry_date'].markAsTouched();
      this.appointmentpopservice.additionalDetailsFormGroup.controls['customField1'].markAsTouched();
      this.appointmentpopservice.additionalDetailsFormGroup.controls['customField2'].markAsTouched();
      this.appointmentpopservice.additionalDetailsFormGroup.controls['customField3'].markAsTouched();
      this.appointmentpopservice.additionalDetailsFormGroup.controls['customField4'].markAsTouched();
      this.appointmentpopservice.additionalDetailsFormGroup.controls['customField5'].markAsTouched();
      this.appointmentpopservice.additionalDetailsFormGroup.controls['comments'].markAsTouched();
      setTimeout(() => this.appointmentpopservice.ClientSelectedTab = 1, 500);
      return false;
    }

    this.validatePhoneAndEmail();

    return true;
  }

  isPhoneNumberValid() {
    let phoneArr = this.appointmentpopservice.personalDetailsFormGroup.get('Phone') as UntypedFormArray;
    let phoneElem: any = phoneArr.at(0);
    phoneElem.controls.PhoneNumber.markAsTouched();
    phoneElem.controls.PhoneNumberLabel.markAsTouched();
    if (!this.validatePhone(phoneElem.controls['PhoneNumber'].value)) {
      phoneElem.controls['PhoneNumber'].setErrors({ 'incorrect': true });
      return false;
    }
    return true;
  }

  isEmailValide() {
    let emailArr = this.appointmentpopservice.personalDetailsFormGroup.get('Email') as UntypedFormArray;
    let emailElem: any = emailArr.at(0);
    let email = emailElem.controls['EmailId'].value;
    if (!emailElem.controls['EmailId'].valid && emailElem.controls['EmailLabel'].value == "") {
      emailElem.controls['EmailLabel'].setErrors({ 'incorrect': true });
    }
    if (!this.validateEmail(email)) {
      if (email.trim() != "") {
        emailElem.controls['EmailId'].setErrors({ 'incorrect': true });
        this.appointmentpopservice.personalDetailsFormGroup.setErrors({ 'incorrect': true });
        return false;
      }
    }
    return true;
  }

  validatePhoneAndEmail() {
    if (this.appointmentpopservice.personalDetailsFormGroup.controls['Phone'].touched) {
      let phoneArr = this.appointmentpopservice.personalDetailsFormGroup.get('Phone') as UntypedFormArray;
      let phoneElem: any = phoneArr.at(0);
      phoneElem.controls.PhoneNumber.markAsTouched();
      phoneElem.controls.PhoneNumberLabel.markAsTouched();
      if (!phoneElem.controls['PhoneNumber'].valid && phoneElem.controls['PhoneNumberLabel'].value == "" && this.phoneRequired) {
        phoneElem.controls['PhoneNumberLabel'].setErrors({ 'required': true });
      } else if (phoneElem.controls['PhoneNumber'].value == "" && phoneElem.controls['PhoneNumber'].enabled) {
        phoneElem.controls['PhoneNumber'].setErrors({ 'required': true });
      }
      phoneArr.updateValueAndValidity();
    }
    if (this.appointmentpopservice.personalDetailsFormGroup.controls['Email'].touched) {
      let emailArr = this.appointmentpopservice.personalDetailsFormGroup.get('Email') as UntypedFormArray;
      let emailElem: any = emailArr.at(0);
      emailElem.controls.EmailId.markAsTouched();
      emailElem.controls.EmailLabel.markAsTouched();
      if (!emailElem.controls['EmailId'].valid && emailElem.controls['EmailLabel'].value == "" && this.emailRequired) {
        emailElem.controls['EmailLabel'].setErrors({ 'incorrect': true });
      }
      let email = emailElem.controls['EmailId'].value;
      if (!this.validateEmail(email)) {
        if (email && email.trim() != "") {
          emailElem.controls['EmailId'].setErrors({ 'incorrect': true });
          this.appointmentpopservice.personalDetailsFormGroup.setErrors({ 'incorrect': true });
        }
      }
      emailArr.updateValueAndValidity();
    }
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePhone(phone: string): boolean {
    let result: boolean;
    if (phone == '') {
      result = true;
    }
    else {
      result = phone.length == this.common.PhoneFormat.length;
    }
    return result;
  }

  async checkClientMandatoryFields(): Promise<boolean> {
    let valid = true;
    if (this.appointmentpopservice.recordsArray && this.appointmentpopservice.recordsArray.length > 0) {
      await this.GetClientMandatoryandCustomFields();
      for (let i = 0; i < this.appointmentpopservice.recordsArray.length; i++) {
        if (this.appointmentpopservice.recordsArray[i].clientDetail.clientType != ClientType.Member) {
          valid = await this.MandatoryFieldsValidation(this.appointmentpopservice.recordsArray[i]);
          if (!valid) {
            this.Utilities.ShowErrorMessage(this.captions.ErrorinCreatingAppointment, this.localization.replacePlaceholders(this.localization.getError(100005), ["clientName",], [this.appointmentpopservice.recordsArray[i].clientDetail.firstName.concat(' ', this.appointmentpopservice.recordsArray[i].clientDetail.lastName)]));
            return valid;
          }
        }
      }
    }
    return valid;
  }

  async getMatchingGuests(guestSearchData: GuestSearchData): Promise<GuestBasicInformation[]> {
    let response: any = await this.http.CallApiAsync({
      callDesc: 'GetMatchingGuests',
      host: Host.spaManagement,
      method: HttpMethod.Put,
      body: guestSearchData
    });
    return this.mapGuestBasicInformation(this.filterAlreadyLinkedGuests(response.result));
  }

  async SearchClientInformation(searchText: any, isVip: any, clientGuid: any = "0", searchType: any, IncludePurgedData = true, isExternalSearch = false, isPlatformGuestSearch = false): Promise<GuestBasicInformation[]> {
    this.requestUid = Date.now() + "" + this.Utilities.getRandomDecimal() * 10000;
    let response: any = await this.http.CallApiAsync<number>({
      host: Host.spaManagement,
      callDesc: "SearchClientInformation",
      method: HttpMethod.Put,
      body: { searchText: searchText },
      uriParams: { isVip: isVip, clientGuid: clientGuid, searchType: searchType, requestUid: this.requestUid, IncludePurgedData: IncludePurgedData, isExternalSearch: isExternalSearch, isPlatformGuestSearch: isPlatformGuestSearch },
    });
    return this.mapGuestBasicInformation(this.filterAlreadyLinkedGuests(response.result));
  }

  async createClientByGuestId(clientData: ClientInfo, guestGuid: any): Promise<any> {
    let response: any = await this.http.CallApiAsync({
      callDesc: 'createClientByGuestId',
      host: Host.spaManagement,
      method: HttpMethod.Put,
      body: clientData,
      uriParams: { guid: guestGuid }
    });
    return response.result;
  }

  async getClientInfoByGuid(guestId: any, isCopyClient = false): Promise<any> {
    let response: any = await this.http.CallApiAsync({
      callDesc: 'getClientInfoByGuid',
      host: Host.spaManagement,
      method: HttpMethod.Get,
      showError: true,
      uriParams: { guid: guestId, isCopyClient: isCopyClient }
    });
    return response.result;
  }

  async clientProfileLink(subscribeProfile: SubscribeProfile): Promise<boolean> {
    let response: any = await this.http.CallApiAsync({
      callDesc: 'clientprofilelink',
      host: Host.spaManagement,
      method: HttpMethod.Post,
      body: subscribeProfile,
      showError: true
    });
    return response.successStatus;
  }

  private filterAlreadyLinkedGuests(guestData: any): any {
    return guestData?.filter(g => !g.isExternalGuestLinked);
  }

  private mapGuestBasicInformation(guestData: any): GuestBasicInformation[] {
    return guestData?.map(guest => {
      const { id, guestId, firstName, lastName, thumbNail, email, phoneNumber } = guest;
      return {
        id,
        guestId,
        firstName,
        lastName,
        imageContent: thumbNail != '' ? this.getImageData(thumbNail) : null,
        phoneNumber: this.getphoneNumber(phoneNumber),
        emailAddress: this.getEmailAddress(email)
      }
    });
  }

  private getImageData(thumbNail: any): ImageData {
    return {
      referenceId: 0,
      referenceType: ImgRefType.client,
      data: thumbNail,
      id: 0,
      thumbnailData: thumbNail,
      contentType: 'base64',
      sequenceNo: 0
    }
  }

  private getphoneNumber(phoneNumbers: any[]): string {
    if (phoneNumbers.length > 0) {
      const primaryPhoneNumber = phoneNumbers.find(p => p.isPrimary);
      if (primaryPhoneNumber) {
        return primaryPhoneNumber.number;
      }
      const firstPhoneNumber = phoneNumbers[0];
      if (firstPhoneNumber) {
        return firstPhoneNumber.number;
      }
    }
    return '';
  }

  private getEmailAddress(emailIds: any[]): string {
    if (emailIds.length > 0) {
      const primaryEmailAddress = emailIds.find(p => p.isPrimary);
      if (primaryEmailAddress) {
        return primaryEmailAddress.emailId;
      }
      const firstEmailAddress = emailIds[0];
      if (firstEmailAddress) {
        return firstEmailAddress.emailId;
      }
    }
    return '';
  }
}

