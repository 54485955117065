import { GridHeaderConfig } from '../app-grid.interface';
import { ResizeColumns } from '../grid.interface';

export const GridColumnResizable: ResizeColumns = (ColumnHeaders: GridHeaderConfig): boolean => {
    try {
        if (ColumnHeaders.cellRenderer !== '' && ColumnHeaders.cellRenderer !== undefined) {
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.log('Error occurred in GridColumnResize');
    }
};