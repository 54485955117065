import { UntypedFormGroup } from "@angular/forms";

export enum HdrActionTypeEnum {
  hdrAdd = 'hdrAdd',
  hdrCheckbox = 'hdrCheckbox',
  hdrFilter = 'hdrFilter',
  hdrNone = '',
  hdrSave = 'hdrSave'
}
export enum aligment {
  center = 'td-align-center',
  left = 'td-align-left',
  right = 'td-align-right'
}

export enum SorTypeEnum {
  asc = 'asc',
  desc = 'desc'
}
export enum FromTypeEnum {
  allcheckbox = 'allcheckbox',
  cancel = 'cancel',
  claim = 'claim',
  comment = 'comment',
  convert = 'convert',
  copy = 'copy',
  delete = 'delete',
  done = 'done',
  dragdrop = 'dragdrop',
  dropdown = 'dropdown',
  duration = 'duration',
  edit = 'edit',
  input = 'input',
  menuoption = 'menuoption',
  oninit = 'oninit',
  preview = 'preview',
  radioButton = 'radioButton',
  rowcheck = 'rowcheck',
  sort = 'sort',
  switch = 'switch',
  userBlock = 'userBlock',
  replace = 'replace',
  history = 'history',
  rowClick = 'rowClick',
  customAction  = 'customAction',
  folioAction = 'folioAction',
  sync = 'sync',
  inlineEdit = 'inlineEdit',
  valueBind = 'valueBind'
}
export enum ActionTypeEnum {
  freetext = 'freetext',
  alphaNumeric = 'alphaNumeric',
  dropdown = 'dropdown',
  edit = 'edit',
  delete = 'delete',
  sync = 'sync',
  download = 'download',
  link = 'link',
  toggle = 'toggle',
  datepicker = 'datepicker',
}


export enum optionstypesenum {
  edit = 'edit',
  delete = 'delete',
  dragdrop = 'dragdrop',
  claim = 'claim',
  convert = 'convert',
  moreoption = 'moreoption',
  userBlock = 'userBlock',
  custommoreoption = 'custommoreoption'
}
export enum selecttypeenum {
  checkbox = 'checkbox',
  radiobutton = 'radiobutton'
}
export enum templatenameenum {
  toggleTemplate = 'toggleTemplate',
  inputTemplate = 'inputTemplate',
  templateIconName = 'templateIconName'
}
export enum isRestrictedEnum {
  isRestrictEdit = 'isRestrictEdit',
  isRestrictDelete = 'isRestrictDelete',
  isRestrictClaim = 'isRestrictClaim',
  isRestrictDrag = 'isRestrictDrag',
  isRestrictuserBlock = 'isRestrictuserBlock',
  isRestrictCheckbox = 'isRestrictCheckbox'
}
export enum TableActions {
  add = 'add',
  cancel = 'cancel',
  comment = 'comment',
  copy = 'copy',
  delete = 'delete',
  discount = 'discount',
  done = 'done',
  drag = 'drag',
  edit = 'edit',
  editBooking = 'editBooking',
  menu = 'menu',
  preview = 'preview',
  salescallletter = 'salescallletter',
  splitFunction = 'splitFunction',
  userBlock = 'userBlock',
  replace = 'replace',
  sync = 'sync',
  history = 'history',
  customAction = 'customAction',
  folioAction = 'folioAction'
}

export class TableHeaderOptions {
  alignment?: aligment;
  subheaderName?:string;
  displayName: string;
  hdrtemplateName?: HdrActionTypeEnum;
  inputType?: ActionTypeEnum;
  isInlineEditable?: boolean;
  key: string;
  searchable?: boolean;
  sorting?: boolean;
  sortingKey?: string;
  templateName?: ActionTypeEnum;
  inputs?: any;
  maxValue?: string | number;
  isCheckboxDisabled?: boolean;
  headerData?: any;
  inputTypeDirective?: string;
  blurEventdisable?: boolean;
  duration?: AgDurationConfig;
  radioKey?: string;
  allowNegative?: boolean; // as of now used for currency
  displayNameId?: string;
  headerInfo?: string; // Detailed description of the header , it will be shown in tooltip
  minValue?: string | number; // In case of Date picker pass the property name in the row obejct
  isHidden?: boolean;    
  isDisabled?: boolean;
  displaySubName?:string;
  disabeRadiokeyName?:string;
  sortInputType?:string;
}

export interface TableOptions {
  actions?: TableActions[];
  checkboxKey?: string;
  columnFreeze?: {
      firstColumn: boolean;
      lastColumn: boolean;
  };
  isSubheader?:boolean;
  isFilterByAPI?: boolean;
  commentKey?: string;
  defaultsortingColoumnKey: string;
  defaultSortOrder: string;
  deleteMsgKey?: string;
  ignoreSort?: boolean;
  inlineActions?: TableActions[];
  isDragDisabled?: boolean;
  isHeaderCheckboxAllowed?: boolean;
  isInternalEdit?: boolean;
  isReadOnly?: boolean;
  maxRecordCount?: number;
  noDataLabel?: string;
  readOnlyKey?: string;
  showDeleteMsg?: boolean;
  showTotalRecords?: boolean;
  CDK_showConfigure?: boolean
  isRowClick?: boolean;
  defaultClickedRowIndex?: number;
  uniqueKey?: string;
  enablePagination?: boolean;
  enableExportExcel?:boolean;
  defaultPageSize?:boolean;
}

export interface AgDurationConfig {
  class?: string;
  controlName?: string;
  disabled?: boolean;
  formGroup?: UntypedFormGroup;
  invalidErrorMessage?: string;
  label?: string;
  maxValueErrorMessage?: string;
  minValueErrorMessage?: string;
  required?: boolean;
}
