import { GridColumnFormatter } from '../grid.interface';
import * as constant from '../../constant/GlobalConstants';
import { Formaters } from '../../formaters';

export const GridValueFormatter: GridColumnFormatter = (ColumnType: string): string => {
    try {
        let returnValue: any;
        switch (ColumnType) {
            case constant.ColumnFieldType.DATECOLUMN:
                returnValue = Formaters.dateformater;
                break;
            case constant.ColumnFieldType.BOOLEAN:
                returnValue = Formaters.booleanFormater;
                break;
            default:
                returnValue = Formaters.columnValueFormater;
                break;
        }
    return returnValue;
    } catch (error) {
        console.error('Error occurred in GridValueFormatter');
    }
};