import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopModel, SuggestQuantityPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { FormModels } from 'src/app/eatecui/source/shared/models/form-models';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { FormType } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const ReceivingMiscellanuousCharge: PostPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    Result: PostPOSupplierConfig,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId: number,
    selectedSeqNo?: number,
    analysisRedirect ?: any,
    commonService?: CommonService): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ItemMiscCharges')) {let rguestTenantId = false;
            const checkTenant = sessionStorage.getItem('RGuestTenantId');
            if ( checkTenant && JSON.parse(checkTenant)) {
                rguestTenantId = true;
            }
            const EntityName = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.EntityName;
            const Url = `/inventory/api/${EntityName}?$filter=IsActive eq true`;
            httpService.GethttpMethod(Url).subscribe(res => {
                console.log('Response', res['value']);
                const Response = res['value'];
                const FormFields = [];
                Response.forEach(element => {
                    const AssignedValue = homeGroup.value['ReceivingHeadOtherTotals'] ?
                    homeGroup.value['ReceivingHeadOtherTotals'].filter(x => x.CategoryDetailId === element.Id)[0] : '';
                    const Value = AssignedValue ? (rguestTenantId ? AssignedValue.Amount : AssignedValue.AmountInFC) : '';
                    const FormObject: FormType = {
                        type: 'text',
                        class: '',
                        fieldFormaters: {
                            EnableDecimalFormatter: true,
                            WholeNumberLength: commonService.GetWholeNumberLength(true),
                            VisibleDecimal: CommonService.GetCurrencyVisible(),
                            FocusDecimal: commonService.GetFocusNumberLength(true)
                        },
                        fieldType: {
                            SingleData: {
                                name: element.Id,
                                label: element.Name,
                                value: Value,
                                FieldValidation: [ 
                                    { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'},
                                    { validation: CustomValidator.checkMinusPlusFormat , validationMessage: 'Enter Correct Format', key: 'checkMinusPlusFormat'}
                                ]
                            }
                        }
                    };
                    FormFields.push(FormObject);
                });
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.FormFields = FormFields;
                const DataInfo = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges;
                const SuggestQtyDialog: SuggestQuantityPopUpModel = {
                    PopupHeaderContent: DataInfo.PopupHeaderName,
                    FormFields: DataInfo.FormFields,
                    FormGroupData: DataInfo.FormGroupData,
                    Classname: 'misc-charges'
                };
                const dialogRef = dialog.open(AgilysysSuggestqtyPopupComponent, {
                    width: '87%',
                    height: '80vh',
                    data: SuggestQtyDialog,
                    maxWidth: '100vw',
                    panelClass: 'misc-charges-popup',
                    hasBackdrop: true,
                    backdropClass: 'detail-expand-backdrop-class',
                    position: {
                        right: '0'
                    },
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result[0] === 'Confirm') {
                        const FormData = result[1] as FormGroup;
                        const ReturnData = aasignPostObject(FormData, selectedId, homeGroup.value.ReceivingHeadOtherTotals, homeGroup, rguestTenantId);
                        const total = transactionService.calculateReceivingTotal(TransactionCollection.CreateTranscationConfig
                                            .ItemsConfig.ItemList.ItemGridConfig.GridData, ReturnData, 
                                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemAllocateCharges.FormGroupData,
                                            selectedTransaction);
                        TransactionCollection.CreateTranscationConfig.DetailsConfig
                                            .DetailsFormModel.DetailFormHeader[1].Value = total;
                        homeGroup.controls[DataInfo.Name].setValue(ReturnData);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData = ReturnData;
                    }
                });
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred PoMiscellanuousCharge', Error);
    }
};


function aasignPostObject(FormData: FormGroup, selectedId: any, MiscCharges: Array<any>, homeGroup: FormGroup, rguestTenantId: boolean) {
    try {
        const TransactionMiscCharges = [];
        const ExchangeRate = homeGroup.value.ExchangeRate ? parseFloat(homeGroup.value.ExchangeRate.toString()) : 0;
        for (const key in FormData.value) {
            if (FormData.value.hasOwnProperty(key) && FormData.value[key]) {
                if (selectedId > 0 && MiscCharges) {
                    const index = MiscCharges.findIndex(x => x.CategoryDetailId.toString() === key.toString());
                    if (index !== -1) {
                        if (rguestTenantId) {
                            MiscCharges[index].Amount = Number(parseFloat(FormData.value[key])) ;
                            MiscCharges[index].AmountInFc = ExchangeRate > 0 ? Number(parseFloat((MiscCharges[index].Amount * ExchangeRate).toString()).toFixed(4)) : 0;
                        } else {
                            MiscCharges[index].AmountInFC = Number(parseFloat(FormData.value[key])) ;
                            MiscCharges[index].Amount = ExchangeRate > 0 ? Number(parseFloat((MiscCharges[index].AmountInFC / ExchangeRate).toString()).toFixed(4)) : 0;
                        }
                        TransactionMiscCharges.push(MiscCharges[index]);
                    } else {
                        let MiscCharge: any;
                        if (rguestTenantId) {
                            const Amount = Number(parseFloat(FormData.value[key] ? FormData.value[key] : '0'));
                            MiscCharge = {
                                Id: 0,
                                CategoryId: 2,
                                CategoryDetailId: parseInt(key, 10),
                                AmountInFC:  ExchangeRate > 0  && Amount && parseFloat(Amount.toString()) > 0 
                                    ? Number(parseFloat((Amount * ExchangeRate).toString()).toFixed(4)) : 0,
                                Amount: Amount                                 
                            };
                        } else {
                            const AmountInFCValue = Number(parseFloat(FormData.value[key] ? FormData.value[key] : '0'));
                            MiscCharge = {
                                Id: 0,
                                CategoryId: 2,
                                CategoryDetailId: parseInt(key, 10),
                                AmountInFC:  AmountInFCValue,
                                Amount: ExchangeRate > 0 ? Number(parseFloat((AmountInFCValue / ExchangeRate).toString()).toFixed(4)) : 0
                            };
                        }
                        TransactionMiscCharges.push(MiscCharge);
                    }
                } else {
                    let MiscCharge: any;
                    if (rguestTenantId) {
                        const Amount = Number(parseFloat(FormData.value[key] ? FormData.value[key] : '0'));
                        MiscCharge = {
                            Id: 0,
                            CategoryId: 2,
                            CategoryDetailId: parseInt(key, 10),
                            AmountInFC: ExchangeRate > 0 ? Number(parseFloat((Amount * ExchangeRate).toString()).toFixed(4)) : 0,
                            Amount: Amount
                        };
                    } else {
                        const AmountInFCValue = Number(parseFloat(FormData.value[key] ? FormData.value[key] : '0'));
                        MiscCharge = {
                            Id: 0,
                            CategoryId: 2,
                            CategoryDetailId: parseInt(key, 10),
                            AmountInFC: AmountInFCValue,
                            Amount: ExchangeRate > 0 ? Number(parseFloat((AmountInFCValue / ExchangeRate).toString()).toFixed(4)) : 0
                        };
                    }
                    TransactionMiscCharges.push(MiscCharge);
                }
            }
        }
        return TransactionMiscCharges;
    } catch (error) {
        console.error('Error occurred in aasignPostObject', error);
    }
}