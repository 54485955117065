export namespace API {
    export interface LockerInformationAPI {
        id: number;
        imageRefId: number;
        name: string;
        code: string;
        lockerPrefix: string;
        quantity: number;
        listOrder: number;
        availableOnWeb: boolean;
        isActive: boolean;
        outofOrderQuantity: number;
        rateType: LockerRateType;
        rate: number;
        retailItemId: number;
        lockerSlabRates: LockerSlabRates[];
    }


    export interface LockerSlabRates {
        id: number,
        lockerInformationId: number,
        slabStart: number,
        slabEnd: number,
        amount: number
    }
    export enum LockerRateType {
        Hourly = 0,
        Fixed = 1
    }

}
export enum errorCode{
    LockerInUseErrorCodeDelete = -261,
    LockerInUseErrorCodeInActive = -262
} 
