import { Injectable } from '@angular/core';
import { InfiniteScrollItemCount, NestedFilterKey } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridInterface } from '../interface/grid-interface';
import {
    BatchExportPosModel,
    DataDetail,
    DetailPictureConfig,
    FormConfiguration,
    GridConfiguration, GridCustomColExpand, GridSortAction, GridTableCard,
    MasterFormConfiguration,
    MasterInventoryManagement,
    MaterDetailsConfig, NestedBuilderData, NestedDataBuilder, NestedFormConfiguration, SubFormConfigs, TransactionMasterData
} from '../interface/master.interface';
import _ from 'lodash';
import { FilterOptions, GridFilterAction } from 'src/app/eatecui/source/shared/models/app-grid.interface';
import { DynamicGridService, GridColumnReorder, GridConfig, GridFilter, GridInfiniteScrollConfig, GridResizeRows, GridSearchConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { DetailInterface } from '../interface/master-detail-interface';
import { TranslateService } from '@ngx-translate/core';
import { FormInterface } from '../interface/form-interface';
import { DomSanitizer } from '@angular/platform-browser';
import { MasterManagementService } from './master.service';
import { CacheType } from 'src/app/eatecui/source/shared/enum/global.enum';
import { RequestCache } from 'src/app/eatecui/source/core/interceptors/services/request-cache.service';
import { AgilysysGroupingPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-grouping-popup/agilysys-grouping-popup.component';
import { AgilysysGroupingPopupService } from 'src/app/eatecui/source/agilysys-popup/agilysys-grouping-popup/agilysys-grouping-popup.service';
import { ToastrService } from 'ngx-toastr';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { MatDialog } from '@angular/material/dialog';
import { AgilysysGridPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-grid-popup/agilysys-grid-popup.component';
import { BatchExportGridData } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { HttpClient } from '@angular/common/http';
import { ENDPOINTURL } from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';

@Injectable({
    providedIn: 'root'
})


export class MasterGridManagementService {
    params: QueryParams = {
        count: true,
        top: InfiniteScrollItemCount,
        skip: 0,
    };
    selectedRecord: any;
    OdataGlobalEntity: OdataGlobalEntity;
    constructor(
        private httpService: HttpService,
        private dynamicGridService: DynamicGridService,
        private translateService: TranslateService,
        private domSanitizer: DomSanitizer,
        private masterManagementService: MasterManagementService,
        private requestCache: RequestCache,
        private agilysysGroupingPopupService: AgilysysGroupingPopupService,
        private toastrService: ToastrService,
        private dialog: MatDialog,
        private httpClient: HttpClient,
        private integrationService: IntegrationService
    ) {
    }

    /***
     * @method gridDataContruction(ViewModelEntity, MasterDataCollection)
     */
    async gridDataContruction(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
         ResponseData: any, AllViewModelEnity?: any,
        dynamicToolTipConfig?: any) {
        try {
            if (ViewModelEntity && MasterDataCollection) {
                const GridDataCollection = {} as MasterInventoryManagement;
                GridDataCollection.Key = MasterDataCollection.Key;
                GridDataCollection.Name = MasterDataCollection.Name;
                GridDataCollection.GridConfig = await this.gridConstraction(ViewModelEntity, MasterDataCollection, ResponseData);
                const tableCards = GridDataCollection.GridConfig && GridDataCollection.GridConfig.GridTableCards.length > 0 ?
                    GridDataCollection.GridConfig.GridTableCards[0] : MasterDataCollection.GridConfig.GridTableCards[0];
                GridDataCollection.DetailsConfig = MasterDataCollection.DetailsConfig;
                GridDataCollection.NestedFormConfig = MasterDataCollection.NestedFormConfig;
                this.getMastersDataById(ViewModelEntity, MasterDataCollection, tableCards).then(async responseData => {
                    this.selectedRecord = responseData;
                    GridDataCollection.DetailsConfig = this.getDetailConstraction(ViewModelEntity,
                        MasterDataCollection, this.httpService, tableCards, responseData, dynamicToolTipConfig);
                    const hasStorageData = sessionStorage.getItem('StorageData');
                    if (responseData['value'][0].IsFilesAvailable && JSON.parse(hasStorageData) !== null) {
                        GridDataCollection.DetailsConfig.DetailPictures =
                            DetailInterface.MasterDetailPicture(MasterDataCollection,
                                responseData['value'][0].id, this.httpService, this.domSanitizer);
                    } else {
                        GridDataCollection.DetailsConfig.DetailPictures.PictureHeaderName = '';
                        GridDataCollection.DetailsConfig.DetailPictures.PictureDetail = [];
                        GridDataCollection.DetailsConfig.UploadFileCollection = [];                        
                        GridDataCollection.DetailsConfig.DetailPictures.EnablePictureProgress = false;
                    }
                    GridDataCollection.NestedFormConfig = this.getNestedViewConstraction(
                        ViewModelEntity, MasterDataCollection, this.httpService,
                        tableCards, AllViewModelEnity, this.masterManagementService, responseData, dynamicToolTipConfig);
                });
                GridDataCollection.SystemPreference = MasterDataCollection.hasOwnProperty('SystemPreference') ?
                    MasterDataCollection.SystemPreference : [];
                GridDataCollection.DetailExpand = MasterDataCollection.hasOwnProperty('DetailExpand') ?
                    MasterDataCollection.DetailExpand : [];
                return GridDataCollection;
            }
        } catch (error) {
            console.error(error);
        }
    }

    getMastersDataById(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
        DetailsObject: GridTableCard) {
        const Url = ViewModelEntity[0]['$']['Name'];
        let seleId = `id eq ${DetailsObject.Id}`;
        if (MasterDataCollection.GridConfig.GridCustomExpand && MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
            let queryString = '';
            let queryString1 = '';
            if (MasterDataCollection.GridConfig.GridCustomExpand[0] &&
                !MasterDataCollection.GridConfig.GridCustomExpand[0].IsCustomExpand) {
                queryString = MasterDataCollection.GridConfig.GridCustomExpand[0].SelectQuery + ' eq ' + DetailsObject.Id;
            }
            if (MasterDataCollection.GridConfig.GridCustomExpand[1] &&
                !MasterDataCollection.GridConfig.GridCustomExpand[1].IsCustomExpand) {
                // eslint-disable-next-line max-len
                queryString1 = MasterDataCollection.GridConfig.GridCustomExpand[1].SelectQuery + ' eq ' + DetailsObject.SecondaryId;
            }
            if (queryString1.length > 0 && queryString.length > 0) {
                seleId = queryString + ' and ' + queryString1;
            } else if (queryString1.length > 0) {
                seleId = queryString1;
            } else if (queryString.length > 0) {
                seleId = queryString;
            }
        }
        const navigationProperity = this.NavigationProperty(ViewModelEntity, MasterDataCollection);
        const params = {} as QueryParams;
        params.expand = navigationProperity.join();
        params.filterQuery = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
        const getQueryParams = (Url === 'Ingredients' || Url === 'Products' || Url === 'Suppliers' || Url === 'Recipes')
            ? `/${DetailsObject.Id}` : this.formQueryParams(params);
        const GetDataByIdUrl = `/inventory/api/${Url + getQueryParams}`;
        return new Promise(async (resolve, reject) => {
            this.httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                const convertValue = (typeof x['value'] === 'string') ? [JSON.parse(x['value'])] : x['value'];
                x['value'] = convertValue;
                resolve(x);
            });
        });
    }

    /**
     * @method getNestedViewObject();
     * @params ViewModelEntity 
     * @params MasterDataCollection 
     */
    getNestedViewObject(MasterDataCollection: MasterInventoryManagement, GridTableCards: GridTableCard): SubFormConfigs {
        if (MasterDataCollection.hasOwnProperty('NestedFormConfig') &&
            MasterDataCollection.NestedFormConfig.hasOwnProperty('ViewFormConfig')) {
            MasterDataCollection.NestedFormConfig.ViewFormConfig[0].Id = GridTableCards.Id;
            return MasterDataCollection.NestedFormConfig.ViewFormConfig[0];
        }
    }
    /***
     * @method constractGroupingModel(MasterDataCollection: MasterInventoryManagement)
     */
    async constractGroupingModel(
        ViewDataEntity: any,
        PostModelEntity: any,
        masterDataCollection: MasterInventoryManagement,
        GroupingObject: SubFormConfigs,
        ResponseObject: any,
        selectedId: number
    ): Promise<MasterInventoryManagement> {
        try {
            if (masterDataCollection && ViewDataEntity) {
                const MasterDataCollection = {} as MasterInventoryManagement;
                MasterDataCollection.Key = masterDataCollection.Key;
                MasterDataCollection.Name = masterDataCollection.Name;
                MasterDataCollection.GridConfig = await this.gridConstraction(ViewDataEntity, masterDataCollection, ResponseObject);
                MasterDataCollection.DetailsConfig = this.groupingDetailConstration(
                    ViewDataEntity, PostModelEntity, masterDataCollection, GroupingObject, ResponseObject, selectedId);
                MasterDataCollection.FormConfig = this.groupingModelConstraction(
                    ViewDataEntity, PostModelEntity, masterDataCollection, GroupingObject, ResponseObject, selectedId);
                MasterDataCollection.NestedFormConfig = this.groupingNestedFormConstraction(
                    ViewDataEntity, PostModelEntity, masterDataCollection, GroupingObject, ResponseObject, selectedId);
                return MasterDataCollection;
            }
        } catch (error) {
            console.error(error);
        }
    }

    /**
    * @method groupingModelConstraction()
    * @param odataObject;
    *
    */
    groupingDetailConstration(
        ViewModelEntity: any,
        PostModelEntity: any,
        MasterDataCollection: MasterInventoryManagement,
        GroupingObject: SubFormConfigs,
        ResponseObject: any, selectedId: number): MaterDetailsConfig {
        try {
            if (
                MasterDataCollection.NestedFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig.length > 0) {
                const detailsConfig = {} as MaterDetailsConfig;
                detailsConfig.DetailsHeader = [];
                detailsConfig.DetailsObservation = MasterDataCollection.Name;
                const dataDetail = {} as DataDetail;
                dataDetail.Id = selectedId;
                if (MasterDataCollection.GridConfig.GridTableCards.filter(
                    x => x.Id === selectedId).length > 0) {
                    dataDetail.HeaderName = MasterDataCollection.GridConfig.GridTableCards.filter(
                        x => x.Id === selectedId)[0]['GridTableData'][0]['Value'];
                    dataDetail.Value = MasterDataCollection.GridConfig.GridTableCards.
                        filter(x => x.Id === selectedId)[0]['GridTableData'][1]['Value'];
                }
                // dataDetail.HeaderName = MasterDataCollection.GridConfig.GridTableCards.filter(
                //     x => x.Id === selectedId)[0]['GridTableData'][0]['Value'];

                detailsConfig.DetailsData = [];
                detailsConfig.DetailsData.push(dataDetail);
                return detailsConfig;
            }
        } catch (error) {
            console.error(error);
        }
    }



    /**
  * @method groupingModelConstraction()
  * @param odataObject;
  *
  */
    groupingModelConstraction(
        ViewModelEntity: any,
        PostModelEntity: any,
        MasterDataCollection: MasterInventoryManagement,
        GroupingObject: SubFormConfigs,
        ResponseObject: any, selectedId: number): FormConfiguration {
        try {
            if (
                MasterDataCollection.NestedFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig.length > 0) {
                const formConfiguration = {} as FormConfiguration;
                MasterDataCollection.NestedFormConfig.ViewFormConfig.forEach((x: SubFormConfigs, i) => {
                    if (x.Type === 'Form') {
                        formConfiguration.Name = x.Name;
                        formConfiguration.DisplayName = x.DisplayName;
                        formConfiguration.Key = x.OdataKey;
                        formConfiguration.FormFields = FormInterface.FormNestedCustomBuilding(
                            ViewModelEntity, PostModelEntity, MasterDataCollection, this.httpService, x['data'][0], this.translateService);
                    }
                    MasterDataCollection.FormConfig = { ...formConfiguration };

                });
                return formConfiguration;

            }
        } catch (error) {
            console.error(error);
        }
    }

    /**
    * @method groupingModelConstraction()
    * @param odataObject;
    *
    */
    groupingNestedFormConstraction(
        ViewModelEntity: any,
        PostModelEntity: any,
        MasterDataCollection: MasterInventoryManagement,
        GroupingObject: SubFormConfigs,
        ResponseObject: any, selectedId: number): NestedFormConfiguration {
        try {
            if (
                MasterDataCollection.NestedFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig.length > 0) {
                const formConfiguration = {} as FormConfiguration;
                MasterDataCollection.NestedFormConfig.ViewFormConfig.forEach((x: SubFormConfigs, i) => {
                    if (x.Type === 'listing') {
                        if (ResponseObject['value'].length > 0) {
                            x.data[0].NestedData = [];
                            ResponseObject['value'].forEach((element: any) => {
                                const NestedDatabuilder = {} as NestedBuilderData;
                                const Count = element.hasOwnProperty('PackLists') && element.PackLists.length > 0 ?
                                 element['PackLists'].filter(pack => pack.IsActive === true).length : 0;
                                NestedDatabuilder.DisplayName = `${element['Name']}(` + `${Count})`;    
                                NestedDatabuilder.Value = element['Description'] ? element['Description'] : '-';
                                x.data[0].NestedData.push(NestedDatabuilder);
                            });
                        } else {
                            x.data[0].NestedData = [];
                        }
                    }
                });
                return MasterDataCollection.NestedFormConfig;
            }
        } catch (error) {
            console.error(error);
        }
    }


    /**
    * @method NavigationProperty()
    * @param odataObject;
    *
    */
    NavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement) {
        try {
            if (ViewModelEntity && ViewModelEntity.length > 0) {
                if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                    const navigationPrperity = [];
                    ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                        const navigationName = element['$'].Name;
                        // Added condition for inventory landing should not have inventory pictures 
                        if (navigationName !== 'InventoryPictures' && navigationName !== 'ProductPictures' && navigationName !== 'RecipePictures') {
                            const referentialRelationId = element['ReferentialConstraint'] &&
                                element['ReferentialConstraint'][0]['$'].Property
                                ? element['ReferentialConstraint'][0]['$'].Property : '';
                            if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                                const properityIndex = ViewModelEntity[0]['Property']
                                    .findIndex(x => x['$'].Name === referentialRelationId);
                                if (properityIndex > -1) {
                                    let expandQuery = '($select=id,name)';
                                    if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                        MasterDataCollection.GridConfig.GridCustomExpand.forEach(
                                            (GridCustomExpandKey: GridCustomColExpand) => {
                                                if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand
                                                    && GridCustomExpandKey.IsCustomExpand
                                                    && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                                    const expandCode = GridCustomExpandKey.SelectQuery;
                                                    expandQuery = `($select=id,${expandCode})`;
                                                }
                                            });
                                    }
                                    const properityString = `${navigationName}` + `${expandQuery}`;
                                    navigationPrperity.push(properityString);
                                } else {
                                    // Added for supplier supplier bids name change
                                    const properityString = navigationName === 'SupplierBids' ? 'IngredientsBids' : navigationName;
                                    if (MasterDataCollection.NestedFormConfig.EditFormConfig.
                                        findIndex(x => x.OdataKey === navigationName) > -1) {
                                        const ChildNavigation = [];
                                        const ParentEnitityName = navigationName;
                                        const AllViewModelEntity = this.OdataGlobalEntity.OdataViewModelEntity;
                                        if (AllViewModelEntity.filter(view => view['$']['Name'] === properityString).length > 0) {
                                            const ChildNavigationProperty = AllViewModelEntity
                                                .filter(view => view['$']['Name'] === properityString)[0];
                                            ChildNavigationProperty['NavigationProperty'].forEach(elements => {
                                                if (elements['$'].Name !== ParentEnitityName) {
                                                    let ChildNavigationPropertys = `${elements['$'].Name}($select=id,name)`;
                                                    if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                                        MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                                        MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey:
                                                            GridCustomColExpand) => {
                                                            if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand &&
                                                                GridCustomExpandKey.IsCustomExpand
                                                                && GridCustomExpandKey.PrimaryExpand === elements['$'].Name) {
                                                                const expandCode = GridCustomExpandKey.SelectQuery;
                                                                ChildNavigationPropertys =
                                                                    `${elements['$'].Name}($select=id,${expandCode})`;
                                                            }
                                                        });
                                                    }
                                                    ChildNavigation.push(ChildNavigationPropertys);
                                                }
                                            });
                                        }
                                        const data = navigationName + '($expand=' + ChildNavigation.join() + ')';
                                        // properityString = properityString + '($expand=' + ChildNavigation.join() + ')';
                                        navigationPrperity.push(data);
                                    }
                                }
                            }
                        }
                    });
                    return navigationPrperity;
                }
            }
        } catch (error) {
            console.log('error occured NavigationProperty', error);
        }
    }
    /**
     * @method getMasterRowData()
     * @param ViewModelEntity ;
     * @param MasterDataCollection ;
     */
    getMasterRowData(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement): Array<any> {
        try {
            let responseData: Array<any> = [];
            let endPointUrl = ViewModelEntity[0]['$']['Name'];
            const navigationProperity = this.NavigationProperty(ViewModelEntity, MasterDataCollection);
            if (navigationProperity && navigationProperity.length > 0 && endPointUrl !== 'Ingredients') {
                this.params.expand = navigationProperity.join();
                const getQueryParams = this.formQueryParams(this.params);
                endPointUrl = `/inventory/api/${endPointUrl}` + getQueryParams;
            } else if (endPointUrl === 'Ingredients') {
                endPointUrl = `/inventory/api/${endPointUrl}`;
            } else {
                const getQueryParams = this.formQueryParams(this.params);
                endPointUrl = endPointUrl + getQueryParams;
            }
            this.httpService.GethttpMethod(endPointUrl).subscribe((response: any) => {
                const griDataConfig = {} as GridConfiguration;
                responseData = [];
                response.value.forEach((element: any) => {
                    const dataObject = _.mapKeys(element, function (v, k) { return k.toLowerCase(); });
                    responseData.push(dataObject);
                });
                return responseData;
            });
            return responseData;
        } catch (error) {
            console.error(error);
        }
    }


    /***
     * @method gridConstraction(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement)
     */
    async gridConstraction(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
         ResponseData): Promise<GridConfiguration> {
        try {
            const GridConfigurationData = {} as GridConfiguration;
            this.resetParamsObject();
            GridConfigurationData.GridName = GridInterface.GridName(ViewModelEntity, MasterDataCollection);
            GridConfigurationData.GridOdataKey = GridInterface.GridOdataKey(ViewModelEntity, MasterDataCollection);
            GridConfigurationData.ShowActiveToggle = GridInterface.GirdShowActive(ViewModelEntity, MasterDataCollection);
            GridConfigurationData.ShowAddNew = GridInterface.GirdShowAdd(ViewModelEntity, MasterDataCollection);
            GridConfigurationData.GridFilter = GridInterface.GridFilter(ViewModelEntity, MasterDataCollection, this.httpService);
            GridConfigurationData.GridSearch = GridInterface.GridSearch(ViewModelEntity, MasterDataCollection);
            GridConfigurationData.GridColumnHide = MasterDataCollection.GridConfig.GridColumnHide;
            GridConfigurationData.GridHeaderDefination = await 
                GridInterface.GridHeaderColumn(ViewModelEntity, MasterDataCollection, this.translateService, this.httpClient);
            GridConfigurationData.GridData = GridInterface.GridData(ViewModelEntity, MasterDataCollection,
                this.httpService, this.params, ResponseData['value']);
            GridConfigurationData.GridActions =
                GridInterface.GridActionData(ViewModelEntity, MasterDataCollection, ResponseData);
            GridConfigurationData.GridHeaderDefination = this.dynamicGridService.assignColumns(ViewModelEntity[0]['$']['Name'],
                GridConfigurationData.GridHeaderDefination);
            GridConfigurationData.GridTableCards = GridInterface.GridTableCard(
                ViewModelEntity, MasterDataCollection, GridConfigurationData.GridData, GridConfigurationData.GridHeaderDefination, this.translateService);
            GridConfigurationData.GridRowActions = MasterDataCollection.GridConfig.GridRowActions;
            GridConfigurationData.GridCustomExpand = MasterDataCollection.GridConfig.GridCustomExpand;
            return GridConfigurationData;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @method getDetailConstraction( ViewModelEntity: any, 
       MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService,
    DetailsObject: GridTableCard)
     * @params ViewModelEntity 
     * @params MasterDataCollection 
     * @params Params 
     */
    getDetailConstraction(
        ViewModelEntity: any,
        MasterDataCollection: MasterInventoryManagement,
        httpService: HttpService,
        DetailsObject: GridTableCard,
        ResponseData: any,
        dynamicToolTipConfig: any
    ): MaterDetailsConfig {
        try {
            return DetailInterface.MasterDetails(ViewModelEntity, MasterDataCollection,
                httpService, DetailsObject, ResponseData, this.translateService, this.domSanitizer, dynamicToolTipConfig, this.integrationService );
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @method getNestedViewConstraction( ViewModelEntity: any, 
       MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService,
    DetailsObject: GridTableCard)
     * @params ViewModelEntity 
     * @params MasterDataCollection 
     * @params Params 
     */
    getNestedViewConstraction(
        ViewModelEntity: any,
        MasterDataCollection: MasterInventoryManagement,
        httpService: HttpService,
        GridTableCardDataObject: GridTableCard,
        AllViewModelEnity: any,
        masterManagementService: MasterManagementService,
        ResposeData: any,
        dynamicToolTipConfig: any): NestedFormConfiguration {
        try {
            return DetailInterface.MasterNestedDetails(ViewModelEntity, MasterDataCollection, httpService,
                GridTableCardDataObject, ResposeData, this.translateService, AllViewModelEnity, masterManagementService, 
                dynamicToolTipConfig);
        } catch (error) {
            console.error(error);
        }
    }

    getEndPointUrl(ViewModelEntity, MasterDataCollection: any, Params?: QueryParams) {
        try {
            if (Params) {
                this.params = Params;
            }
            if (ViewModelEntity.length > 0 && MasterDataCollection) {
                let endPointUrl = ViewModelEntity[0]['$']['Name'];
                const navigationProperity = this.NavigationProperty(ViewModelEntity, MasterDataCollection);
                if (navigationProperity && navigationProperity.length > 0 && endPointUrl !== 'Ingredients' && endPointUrl !== 'Products'
                    && endPointUrl !== 'Suppliers' && endPointUrl !== 'Customer') {
                    // Commented for Suppliers and Customer should not send any expand querys
                    // this.params.expand = navigationProperity.join();
                    const getQueryParams = this.formQueryParams(this.params);
                    endPointUrl = `/inventory/api/${endPointUrl}` + getQueryParams;
                } else if (endPointUrl === 'Ingredients') {
                    if (this.params.filterQuery && this.params.filterQuery.indexOf(`IngredientTypes/DivisionId eq ${MasterDataCollection.SelectedTypeId}`) === -1) {
                        // eslint-disable-next-line max-len
                        this.params.filterQuery = `IngredientTypes/DivisionId eq ${MasterDataCollection.SelectedTypeId} and ` + this.params.filterQuery;
                    } else if (!this.params.filterQuery) {
                        this.params.filterQuery = `IngredientTypes/DivisionId eq ${MasterDataCollection.SelectedTypeId}`;
                    }
                    this.params.select = 'Name,Number,Id,IsActive';
                    this.params.expand = 'IngredientsBarCodes($filter=IsActive eq true;$select=Barcode)';
                    const getQueryParams = this.formQueryParams(this.params);
                    endPointUrl = `/inventory/api/${endPointUrl}` + getQueryParams;
                } else if (endPointUrl === 'Products' && MasterDataCollection.SelectedTypeId) {
                    if (this.params.filterQuery && this.params.filterQuery.indexOf(`ProductsTypeId/DivisionId eq ${MasterDataCollection.SelectedTypeId}`) === -1) {
                        // eslint-disable-next-line max-len
                        this.params.filterQuery = `ProductsTypeId/DivisionId eq ${MasterDataCollection.SelectedTypeId} and ` + this.params.filterQuery;
                    } else if (!this.params.filterQuery) {
                        this.params.filterQuery = `ProductsTypeId/DivisionId eq ${MasterDataCollection.SelectedTypeId}`;
                    }
                    this.params.select = 'Name,Number,Id,IsActive';
                    const getQueryParams = this.formQueryParams(this.params);
                    endPointUrl = `/inventory/api/${endPointUrl}` + getQueryParams;
                } else if (endPointUrl === 'Suppliers') {
                    this.params.select = 'Name,Number,Id,IsActive,IsPunchoutEnabled';
                    const getQueryParams = this.formQueryParams(this.params);
                    endPointUrl = `/inventory/api/${endPointUrl}` + getQueryParams;
                } else if (endPointUrl === 'Customer') {
                    this.params.select = 'Name,Number,Id,IsActive';
                    const getQueryParams = this.formQueryParams(this.params);
                    endPointUrl = `/inventory/api/${endPointUrl}` + getQueryParams;
                } else {
                    const getQueryParams = this.formQueryParams(this.params);
                    endPointUrl = endPointUrl + getQueryParams;
                }
                return endPointUrl;
            }
        } catch (error) {

        }
    }

    GetNavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement) {
        try {
            if (ViewModelEntity && ViewModelEntity.length > 0) {
                if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                    const navigationPrperity = [];
                    ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                        const navigationName = element['$'].Name;
                        // Added condition for inventory landing should not have inventory pictures 
                        // if (navigationName !== 'InventoryPictures') {
                        const referentialRelationId = element['ReferentialConstraint'] &&
                            element['ReferentialConstraint'][0]['$'].Property ? element['ReferentialConstraint'][0]['$'].Property : '';
                        if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                            const properityIndex = ViewModelEntity[0]['Property']
                                .findIndex(x => x['$'].Name === referentialRelationId);
                            if (properityIndex > -1) {
                                let expandQuery = '($select=id,name)';
                                if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                    MasterDataCollection.GridConfig.GridCustomExpand.forEach(
                                        (GridCustomExpandKey: GridCustomColExpand) => {
                                            if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand
                                                && GridCustomExpandKey.IsCustomExpand
                                                && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                                const expandCode = GridCustomExpandKey.SelectQuery;
                                                expandQuery = `($select=id,${expandCode})`;
                                            }
                                        });
                                }
                                const properityString = `${navigationName}` + `${expandQuery}`;
                                navigationPrperity.push(properityString);
                            } else {
                                let expandQuery = '';
                                if (navigationName === 'DeliveryRulesSuppliers' || navigationName === 'SupplierSitesAccConfig') {
                                    // expandQuery = '($select=id,name)';
                                    if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                        MasterDataCollection.GridConfig.GridCustomExpand.forEach(
                                            (GridCustomExpandKey: GridCustomColExpand) => {
                                                if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand
                                                    && GridCustomExpandKey.IsCustomExpand
                                                    && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                                    const expandCode = GridCustomExpandKey.SelectQuery;
                                                    const filterCode = GridCustomExpandKey.SelectFilter;
                                                    if (expandCode && !filterCode) {
                                                    expandQuery = `($select=id,${expandCode})`;
                                                    } else if (filterCode && !expandCode) {
                                                        expandQuery = `($filter=${filterCode})`;
                                                    } else if (filterCode && expandCode) {
                                                        expandQuery = `($filter=${filterCode},$select=${expandCode})`;
                                                    }
                                                }
                                            });
                                    }
                                }                                
                                const properityString = `${navigationName}` + (expandQuery !== '' ? `${expandQuery}` : '');
                                navigationPrperity.push(properityString);
                            }
                        }
                        // }
                    });
                    return navigationPrperity;
                }
            }
        } catch (error) {
            console.log('error occured NavigationProperty', error);
        }
    }

    resetParamsObject() {
        this.params = {
            count: true,
            top: InfiniteScrollItemCount,
            skip: 0,
            expand: '',
            select: '',
            filter: '',
            orderby: '',
            isactive: true,
            search: '',
            filterQuery: '',
            searchtext: ''
        };
    }

    formQueryParams(params: QueryParams) {
        params.filter = '';
        const keys = Object.keys(params);
        let query = '';
        keys.forEach((data, index) => {
            if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
                if (params[data] !== '' && data !== 'filter') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        if (query.length > 0) {
                            query = query + '&$';
                        } else {
                            query = query + '?$';
                        }
                    }
                    query = query + data + '=' + params[data];
                } else if (data === 'filter') {
                    if (params.isactive) {
                        params[data] = 'IsActive eq true';
                    }
                    if (params.searchtext && params.searchtext.length > 0 && params.search) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.search;
                        } else {
                            params[data] = params.search;
                        }
                    }
                    if (params.filterQuery && params.filterQuery.length > 0) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.filterQuery;
                        } else {
                            params[data] = params.filterQuery;
                        }
                    }

                    if (params[data] !== '') {
                        if (index === 0) {
                            query = query + '?$';
                        } else {
                            query = query + '&$';
                        }
                        query = query + data + '=' + params[data];
                    }
                }
            }
        });
        return query;
    }

    /**
     * @description to form the query for api
     * @params searchBy
     * @params currentFilter
     * @params searchText
     * @params headerDefinition
     */
    formSearchQuery(searchBy: Array<any>, currentFilter: any, searchText: any) {
        const SearchText = searchText.replaceAll('\'', '\'\'');
        searchText = encodeURIComponent(SearchText).replace(/[!'()*]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
        searchBy.forEach(element => {
            if (element) {
                if (element.columnName === 'IngredientsBarCodes') {
                    const query = element.columnName + `/any(c: c/Barcode eq '${searchText.toLowerCase()}')`;
                    currentFilter = this.addFilter(currentFilter, query);
                } else if (element.ChildSearchKey && element.ChildSearchKey.length > 0) {
                    let combinedfilter = '';
                    const childQuery = element.ChildSearchKey.map((x, index) => {
                        // const returnQuery = 'contains(' + x.SearchKey + ',\'' + searchText.toLowerCase() + '\')';
                        // const returnQuery = `substringof('${searchText.toLowerCase()}', ${x.SearchKey}) eq true`;
                        // const returnQuery = `${x.SearchKey} eq '${searchText.toLowerCase()}'`;
                        const returnQuery = x.SearchKey + ',\'' + searchText.toLowerCase() + '\'';
                        const returnValue = element.columnName + `/any(c: c/IsActive eq true and contains(c/${returnQuery}))`;
                        if (index === 0) {
                            combinedfilter = returnValue;
                        } else {
                            combinedfilter += ' or ' + returnValue;
                        }
                    });
                    const query = combinedfilter;
                    currentFilter = this.addFilter(currentFilter, query);
                } else {
                    const query = 'contains(tolower(' + element.columnName + '),\'' + searchText.toLowerCase() + '\')';
                    currentFilter = this.addFilter(currentFilter, query);
                }
            }
        });
        return currentFilter;
    }

    addFilter(currentFilter, filter) {
        if (currentFilter.length === 0) {
            currentFilter = filter;
        } else {
            currentFilter += ' or ' + filter;
            currentFilter = '(' + currentFilter + ')';
        }
        return currentFilter;
    }

    /**
* @description to form filter query for api
* @params filterArray
* @params currentFilter
* @params gridFilterConfig
*/
    formFilterQuery(filterArray: FilterOptions[], currentFilter: any, gridFilterConfig: MasterInventoryManagement, GridData: any = null) {
        let query: any = '';
        currentFilter = '';

        filterArray.forEach((element, index1) => {
            const FilterIndex = gridFilterConfig.GridConfig.GridFilter.findIndex(x => x.FilterOdataKey === element.FilterOriginalKey);
            if (NestedFilterKey.filter(x => x.Name === gridFilterConfig.GridConfig.GridFilter[0].FilterOdataKey).length > 0) {
                const NestedFilterObject = NestedFilterKey.filter(x => x.Name ===
                    gridFilterConfig.GridConfig.GridFilter[0].FilterOdataKey)[0];
                if (element.FilterValue.filter(x => x.Checked).length > 0) {
                    const checkedObjects = [];
                    element.FilterValue.forEach((data, index) => {
                        if (data.Checked) {
                            checkedObjects.push(data.FilterId);
                        }
                    });
                    const datas = checkedObjects.join(',');
                    // eslint-disable-next-line max-len
                    query = element.FilterOriginalKey + '/any(c: c/' + NestedFilterObject.FilterEntityName + '/' + NestedFilterObject.FilterColumnKey + ' in (' + datas + '))';
                }
            } else {
                if (element.FilterOriginalKey === 'IsActive') {
                    if (element.FilterValue.filter(x => x.Checked).length === 1) {
                        const FilterData = element.FilterValue.filter(x => x.Checked)[0];
                        const BoolCheck = FilterData.FilterId === 1 ? true : false;
                        query = query + element.FilterOriginalKey + ' eq ' + BoolCheck;
                    }
                } else {
                    if (element.FilterValue.filter(x => x.Checked).length > 0) {
                        if (element.FilterNavigationKey) {
                            query = gridFilterConfig.GridConfig.GridFilter[FilterIndex].FilterOdataKey + '/' + element.FilterNavigationKey + ' in (';
                        } else {
                            query = gridFilterConfig.GridConfig.GridFilter[FilterIndex].FilterOdataKey + ' in (';
                        }
                    }
                    const checkedObjects = [];
                    element.FilterValue.forEach((data, index) => {
                        if (data.Checked) {
                            checkedObjects.push(data.FilterId);
                        }
                    });
                    if (checkedObjects !== undefined && checkedObjects.length > 0) {
                        query = query + checkedObjects.join(',') + ')';
                    }
                }
                if (index1 > 0 && currentFilter.length > 0 && query.length > 0) {
                    query = ' and ' + query;
                }
            }
            currentFilter = currentFilter + query;
            query = '';
        });
        return currentFilter;
    }

    getRowData(ViewModelEntity, MasterDataCollection, ResponseData) {
        try {
            const GridData = GridInterface.GridData(ViewModelEntity, MasterDataCollection,
                this.httpService, this.params, ResponseData['value']);
            return GridData;
        } catch (error) {
            console.error('Error occurred in getRowData', error);
        }
    }

    getTableCards(ViewModelEntity, MasterDataCollection, GridData, HeaderDefinition) {
        const GridTableCards = GridInterface.GridTableCard(
            ViewModelEntity, MasterDataCollection, GridData, HeaderDefinition, undefined);
        return GridTableCards === undefined ? [] : GridTableCards;
    }

    setSort(GridSort) {
        let sortOrder = '';
        if (GridSort.length > 0) {
            const sort: GridSortAction = GridSort.filter(x => x.DefaultSorting === true)[0];
            sortOrder = sort.SortingOdataKey + ' ' + sort.SortingType.toLowerCase();
            return sortOrder;
        } else {
            return '';
        }
    }

    /**@
     * @method reConstractModelData(activeBoolen, MasterData)
     */
    reConstractModelData(activeBoolen: boolean, MasterData: MasterInventoryManagement) {
        try {
            if (MasterData.DetailsConfig.hasOwnProperty('DetailPatchValueData') &&
                MasterData.DetailsConfig.DetailPatchValueData) {
                for (const prop in MasterData.DetailsConfig.DetailPatchValueData) {
                    if (prop) {
                        if ((prop === 'IsActive' || (MasterData.DetailsConfig.DetailPatchValueData[prop] &&
                            MasterData.DetailsConfig.DetailPatchValueData[prop] !== null))) {
                            const isArrayprop = Array.isArray(MasterData.DetailsConfig.DetailPatchValueData[prop]);
                            const propType = MasterData.DetailsConfig.DetailPatchValueData[prop] ?
                                typeof MasterData.DetailsConfig.DetailPatchValueData[prop] : false;
                            if (propType === 'object' && !isArrayprop) {
                                console.log('Is Object Properiy');
                            } else if (isArrayprop) {
                                if (MasterData.DetailsConfig.DetailPatchValueData[prop] &&
                                    MasterData.DetailsConfig.DetailPatchValueData[prop].length > 0) {
                                    MasterData.DetailsConfig.DetailPatchValueData[prop].forEach(element => {
                                        if (element.hasOwnProperty('IsActive')) {
                                            element['IsActive'] = activeBoolen;
                                        }
                                    });
                                }
                            } else {
                                if (prop === 'IsActive') {
                                    MasterData.DetailsConfig.DetailPatchValueData[prop] = activeBoolen;
                                }
                            }
                        }
                    }
                }
            }
            return MasterData.DetailsConfig.DetailPatchValueData;

        } catch (error) {
            console.error(error);
        }
    }

    getZoomData(ViewModelEntity, masterDataCollection, rowData, action, dialog) {
        const Data = DetailInterface.MasterDetailZoom(ViewModelEntity, masterDataCollection,
            this.httpService, this.translateService, rowData.Id, action, dialog);
    }

    nestedFormGrouping(
        data: SubFormConfigs,
        masterODataGlobalEntity: OdataGlobalEntity,
        masterDataCollection: MasterInventoryManagement,
        selectedInventoryType: number,
        selectedMaster: string,
        selectedId: any,
        gridData: GridConfig
    ) {
        try {
            const packageType = this.fetchPackageType(selectedMaster);
            const seleId = `TypeId eq ${packageType} and IsActive eq true`;
            // eslint-disable-next-line max-len
            const GetDataByIdUrl = `/inventory/api/${data.OdataKey}` + '?%24expand=PackLists($select=Item,IsDeleted,IsActive,Id)&%24filter=' + encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });

            const productexportUrl = `/inventory/api/${data.OdataKey}` + '?$count=true&$filter=IsActive eq true and IsExport eq false&$expand=ProductsTypeId($select=Id,Name),ProductsClassId($select=Id,Name)&$select=Id,Name,Number,TypeId,ClassId,Description,Price&$top=1000';

            const endPointUrl = data.Name === 'Packages' || data.Name === 'Group' ? GetDataByIdUrl : productexportUrl;
            this.httpService.GethttpMethod(endPointUrl).subscribe(async (response: any) => {
                if (data.Name === 'Packages' || data.Name === 'Group') {
                    this.requestCache.manageCacheEngine(CacheType.CLEARINDIVUAL, GetDataByIdUrl);
                    const nestedId = (selectedId) ? selectedId : masterDataCollection.GridConfig.GridTableCards[0].Id;
                    data.Id = nestedId;
                    const ViewModelEntityData = this.masterManagementService.GetViewModelData(
                        masterODataGlobalEntity.OdataViewModelEntity, data.OdataKey);
                    const PostModelEntityData = this.masterManagementService.GetPostModelData(
                        masterODataGlobalEntity.OdataPostModelEntity, data.OdataKey);
                    const MasterJsonData = this.masterManagementService.GetMasterJsonData(data.Name, selectedInventoryType);
                    MasterJsonData[0].GridConfig.GridTableCards = masterDataCollection.GridConfig.GridTableCards;
                    const MasterInventoryManagements = await this.constractGroupingModel(ViewModelEntityData,
                        PostModelEntityData, MasterJsonData[0], data, response, nestedId);
                    MasterInventoryManagements.packageTypeId = packageType;
                    this.groupingPopupModel(MasterInventoryManagements, gridData, selectedId);
                } else {
                    console.log(response);
                    const ViewModelEntityData = this.masterManagementService.GetViewModelData(
                        masterODataGlobalEntity.OdataViewModelEntity, data.OdataKey);
                    const responseData = this.getRowData(ViewModelEntityData, masterDataCollection, response);
                    this.batchExportPopupModel(responseData, data, gridData, selectedId, masterDataCollection, response);
                }
            });
        } catch (error) {
            console.error('error occured in nestedFormGrouping method', error);
        }
    }

    fetchPackageType(selectedMaster) {
        const packageTypeName = [
            {
                'PackageTypeId': 1,
                'PackageTypeName': 'Ingredients'
            },
            {
                'PackageTypeId': 2,
                'PackageTypeName': 'Recipes'
            },
            {
                'PackageTypeId': 3,
                'PackageTypeName': 'Equipment'
            },
            {
                'PackageTypeId': 5,
                'PackageTypeName': 'Customer'
            },
            {
                'PackageTypeId': 6,
                'PackageTypeName': 'Suppliers'
            },
            {
                'PackageTypeId': 7,
                'PackageTypeName': 'Products'
            }
        ];
        const typeId = packageTypeName.filter(x => x.PackageTypeName === selectedMaster)[0].PackageTypeId;
        if (typeId) {
            return typeId;
        } else {
            return 0;
        }
    }

    /***
     * @method openGroupingPopupModel()
     * @params GroupingData
     */
    groupingPopupModel(GroupingData: MasterInventoryManagement, gridData: GridConfig, selectedId: number) {
        // try {
        //     const dialogRef = this.dialog.open(AgilysysGroupingPopupComponent, {
        //         width: '80vw',
        //         height: '80vh',
        //         data: GroupingData,
        //         disableClose: true
        //     });
        //     dialogRef.afterClosed().subscribe(result => {
        //         if (result[0] === 'Confirm') {
        //             const data: any = result[1];
        //             const PutUrl = `/inventory/api/PackagesList`;
        //             if (!result[2]) {
        //                 result[2] = [];
        //             }
        //             if (data && data.Name) {
        //                 data['Id'] = 0;
        //                 data['IsActive'] = true;
        //                 result[2].push(data);
        //             }
        //             if (result[2].length > 0) {
        //                 this.agilysysGroupingPopupService.changedIds = [];
        //                 const PostObject = {
        //                     PackagesCollection: result[2]
        //                 };
        //                 this.httpService.PosthttpMethod(PutUrl, PostObject).subscribe(response => {
        //                     this.requestCache.manageCacheEngine(CacheType.CLEARALL);
        //                     const Message = `${this.masterManagementService.getTranslation('Common', GroupingData.Key)} ${this.masterManagementService.getTranslation('Common', 'DetailsUpdated')}`;
        //                     this.toastrService.success(Message, '', {
        //                         timeOut: 3000,
        //                         closeButton: true,
        //                     });
        //                     console.log('Package Created or Updated', response);
        //                     // this.TabIndex = 0;
        //                     this.requestCache.manageCacheEngine(CacheType.CLEARALL);
        //                     // gridData.rowClicked({ Id: selectedId, From: 'GroupingPopup' });

        //                 });
        //             }
        //         }
        //         // }
        //     });
        // } catch (error) {
        //     console.error(error);
        // }
    }

    batchExportPopupModel(responseData: any, data: SubFormConfigs, gridDatas: GridConfig, selectedId: number,
        masterDataCollection: MasterInventoryManagement, response: any) {
        try {

            const gridData = {} as GridConfig;
            gridData['GridSearch'] = data.NestedGridData.GridSearch;
            gridData['GridFilter'] = data.NestedGridData.GridFilter;
            gridData.GridData = responseData;
            data.NestedGridData.GridHeaderDefination.forEach(header => {
                header.headerName = this.translateService.instant(data.OdataKey + '.' + header.field);
            });
            gridData.GridHeaderDefination = data.NestedGridData.GridHeaderDefination;
            gridData.GridActions = {};
            gridData.GridActions.GridTitle = data.NestedGridData.GridName;
            gridData.GridActions.SearchConfig = {} as GridSearchConfig;
            gridData.GridActions.SearchConfig.EnabledSearch = true;
            gridData.GridActions.SearchConfig.SearchPlaceholder = data.NestedGridData.GridSearch[0].SearchPlaceholderName;
            gridData.GridActions.FilterConfig = {} as GridFilter;
            gridData.GridActions.FilterConfig.FilterOptions = [];
            gridData.GridActions.FilterConfig.EnableFilter = data.NestedGridData.GridFilter.length > 0 ? true : false;
            // const ViewModelEntityData = this.masterManagementService.
            //     GetViewModelData(this.OdataGlobalEntity.OdataViewModelEntity, 'Ingredients');
            // gridData.GridActions.FilterConfig.FilterOptions = 
            //     GridInterface.GridFilter(ViewModelEntityData, masterDataCollection, this.httpService);
            masterDataCollection.GridConfig.GridActions.FilterConfig.FilterOptions.forEach(option => {
                data.NestedGridData.GridFilter.forEach(nestedGridFilterOption => {
                    if (option.FilterOriginalKey === nestedGridFilterOption.FilterOdataKey) {
                        gridData.GridActions.FilterConfig.FilterOptions.push(option);
                    }
                });
            });
            gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
            gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
            gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
            gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
            gridData.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
            gridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = true;
            gridData.GridActions.InfiniteScrollConfig.TotalRecords = response['totalCount'];
            gridData.TotalRecords = response['totalCount'];
            gridData.GridActions.TotalItems = response['totalCount'];

            const batchExport: BatchExportGridData = {
                GridData: gridData,
                PopupHeaderContent: 'Batch Export POS',
                MasterManagementData: masterDataCollection
            };
            // const dialogRef = this.dialog.open(AgilysysGridPopupComponent, {
            //     width: '80vw',
            //     height: '80vh',
            //     data: batchExport,
            //     disableClose: true
            // });

            // dialogRef.afterClosed().subscribe(result => {
            //     if (result[0] === 'Confirm') {
            //         // eslint-disable-next-line @typescript-eslint/no-shadow
            //         const data: BatchExportPosModel = result[1];

            //         if (data != null && data.ProductIds != null && data.ProductIds.length > 0) {
            //             const postUrl = `/inventory/api/ProductPosExport`;

            //             // eslint-disable-next-line @typescript-eslint/no-shadow
            //             this.httpService.PosthttpMethod(postUrl, data).subscribe(responseData => {
            //                 this.requestCache.manageCacheEngine(CacheType.CLEARALL);
            //                 const Message = `${this.masterManagementService.getTranslation('Products', 'BatchExport')} ${data.ProductIds.length} row(s) affected.`;
            //                 this.toastrService.success(Message, '', {
            //                     timeOut: 3000,
            //                     closeButton: true,
            //                 });
            //                 console.log('Batch Export executed', responseData);
            //                 // this.TabIndex = 0;
            //                 this.requestCache.manageCacheEngine(CacheType.CLEARALL);
            //                 // gridData.rowClicked({ Id: selectedId, From: 'GroupingPopup' });
            //             });
            //         }
            //     }
            // });
        } catch (error) {
            console.error(error);
        }
    }
}
