import { Component, OnInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpMethod, HttpServiceCall } from '../../../shared/service/http-call.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { CancelReason } from '../../../shared/business/view-settings.modals';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'cancel-reason',
  templateUrl: './cancelreason.component.html',
  styleUrls: ['./cancelreason.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CancelReasonComponent implements OnInit {
  @Input() dataInput: any;
  cancelReason: UntypedFormGroup;
  blnReadOnly: boolean;
  clickbutton: string;
  captions: any;
  isViewOnly = true;
  floatLabel:string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public dialogRef: MatDialogRef<SettingDialogPopupComponent>,
    private utils: SpaUtilities,
    private http: HttpServiceCall,
    private Form: UntypedFormBuilder,
    private vsBusiness: ViewSettingClientBusiness,
    public localization: SpaLocalization,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.captions = this.localization.captions.setting;
    this.cancelReason = this.Form.group({
      description: ['', [Validators.required, EmptyValueValidator]],
      reason: ['', Validators.required]
    });
    this.vsBusiness.activeFormGroup = this.cancelReason;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.ValidateBreakPoint();
    this.clickbutton = typeof this.dataInput === 'undefined' ? this.captions.SAVE : this.captions.UPDATE;
    this.blnReadOnly = typeof this.dataInput === 'undefined' ? false : true;
    this.cancelReason = this.Form.group({
      description: ['', [Validators.required, EmptyValueValidator]],
      reason: [false]
    });
    this.saveButton = {
      type: 'primary',
      label: typeof this.dataInput === 'undefined' ? this.captions.SAVE : this.captions.UPDATE,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.CANCEL,
    }
    if (this.dataInput) {
      this.cancelReason.patchValue({
        description: this.dataInput.reasonCode,
        reason: this.dataInput.isActive
      });
    }
    else{
      this.cancelReason.controls.reason.setValue(true);
    }
    this.vsBusiness.activeFormGroup = this.cancelReason;
    this.cancelReason.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.cancelReason.valid && this.cancelReason.dirty);
      this.saveButton = { ...this.saveButton };
    });
  }

  ValidateBreakPoint(): void {
    const viewOnly = this.vsBusiness.setupBreakPoints.find(r => r.breakPointNumber == SPAScheduleBreakPoint.SettingCancelReason);
    this.isViewOnly = viewOnly ? viewOnly.view : false;
    if (this.isViewOnly) {
      this.utils.disableControls(this.cancelReason);
    }
  }

  save() {
    this.saveButton.disabledproperty = true;
    this.cancelReason.controls.description.setValue(this.cancelReason.controls.description.value.trim());
    const cancelReasonObj: CancelReason = {
      ReasonCode: this.cancelReason.controls.description.value,
      IsActive: false
    };
    if (this.clickbutton == this.localization.captions.setting.SAVE) {
      cancelReasonObj.IsActive = this.cancelReason.controls.reason.value ? true : false;
      this.SaveCancelReason(cancelReasonObj, 'CreateCancelReason', HttpMethod.Post);
    } else if (this.clickbutton == this.localization.captions.setting.UPDATE) {
      cancelReasonObj.IsActive = (this.cancelReason.controls.reason.value || this.cancelReason.controls.reason.value == "Yes") ? true : false;

      this.SaveCancelReason(cancelReasonObj, 'UpdateCancelReason', HttpMethod.Put, {id : this.dataInput.id});
    }
  }

  SaveCancelReason(cancelReasonObj: CancelReason, callDesc: string, type: HttpMethod, uriPrams? : any) {
    try{
      this.http.CallApiWithCallback({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback,
        callDesc: callDesc,
        method: type,
        uriParams : uriPrams,
        body: cancelReasonObj,
        showError: true,
        extraParams: []
      });
    }finally{
      this.saveButton.disabledproperty = false;
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateCancelReason" || callDesc == "UpdateCancelReason") {
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
  }

  close() {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }
}


