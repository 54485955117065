import { Injectable } from "@angular/core";
import { CommonApiRoutes } from "../common-route";
import { TenantManagementCommunication } from "../communication/services/tenantmanagement-communication-service";
import { ConsentPolicyUIModel, PolicyDetails } from "../consent-management/consent-management.model";
import { DataGroupUIModel, DataRetentionPolicyModel } from "../data-retention/data-retention.model";


@Injectable({ providedIn: "root" })
export class GuestDataPolicyDataService {
  constructor(private _tenantManagementCommunication: TenantManagementCommunication) {
  }
  public async GetDataRetentionPolicyConfiguredFlag(tenantId: number): Promise<boolean> {
    const result = await this._tenantManagementCommunication.getPromise<boolean>(
      { route: CommonApiRoutes.GetDataRetentionPolicyConfiguredFlag, uriParams: { tenantId } }, false);
    return result;
  }

  public async GetDataRetentionPolicies(tenantId: number, platformTenantId: string = ""): Promise<DataRetentionPolicyModel[]> {
    const result = await this._tenantManagementCommunication.getPromise<DataRetentionPolicyModel[]>(
      { route: CommonApiRoutes.GetDataRetentionPolicies, uriParams: { tenantId:tenantId, platformTenantId: "" } }, false);
    return result;
  }

  public async GetPolicyTypeUsingPolicyId(policyId: number): Promise<number> {
    const result = await this._tenantManagementCommunication.getPromise<number>(
      { route: CommonApiRoutes.GetPolicyTypeUsingPolicyId, uriParams: { policyId } }, false);
    return result;
  }

  public async GetDataGroups(tenantId: number, policyId: number, categoryid: number): Promise<DataGroupUIModel[]> {
    const result = await this._tenantManagementCommunication.getPromise<DataGroupUIModel[]>(
      { route: CommonApiRoutes.GetDataGroups, uriParams: { tenantId: tenantId, policyId: policyId, categoryId: categoryid } }, false);
    return result;
  }

  public async GetConsentPoliciesForProduct(tenantId: number, productId: number): Promise<ConsentPolicyUIModel[]> {
    const result = await this._tenantManagementCommunication.getPromise<ConsentPolicyUIModel[]>(
      { route: CommonApiRoutes.GetConsentPoliciesForProduct, uriParams: { tenantId: tenantId, productId: productId } }, false);
    return result;
  }
  public async GetConsentPoliciesUsingCategoryId(tenantId: number, productId: number, categoryId: number): Promise<ConsentPolicyUIModel[]> {
    const result = await this._tenantManagementCommunication.getPromise<ConsentPolicyUIModel[]>(
      { route: CommonApiRoutes.GetConsentPoliciesForProductUsingCategoryId, uriParams: { tenantId: tenantId, productId: productId, categoryId: categoryId } }, false);
    return result;
  }
  public async GetPolicyDetailsUsingPolicyId(policyId: number) : Promise<PolicyDetails>{
    const result = await this._tenantManagementCommunication.getPromise<PolicyDetails>(
        { route: CommonApiRoutes.GetPolicyDetailsUsingPolicyId,  uriParams: { policyId: policyId } }, false);
    return result;
  }
}
