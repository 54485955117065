import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall, KeyValuePair } from "../../shared/service/http-call.service";
import { BaseResponse } from "../../shared/business/shared.modals";
import { Host, USER, THERAPIST } from "../../shared/globalsContant";
import { SpaUtilities } from "../../shared/utilities/spa-utilities";
import { SpaLocalization } from "../../core/localization/spa-localization";
import { DropDownData, TherapistData, LinkCodes, MultiPackTypes, Users, AllReports, CancelReasons } from "../business/report.modals";
import { SpaPropertyInformation } from "../../core/services/spa-property-information.service";
import { ReportBusinessService } from "../business/report-business.service";
import { SubPropertyModel } from "../../retail/retail.modals";
import { CustomField } from "../../shared/business/view-settings.modals";
import * as _ from 'lodash';
@Injectable()
export class ReportDataService {

    constructor(private http: HttpServiceCall, private utils: SpaUtilities, private localization: SpaLocalization, private propertyInfo: SpaPropertyInformation,
        private business: ReportBusinessService) {
    }

    public async getAllLocation(): Promise<DropDownData[]> {
        let locations = await this.invokeCalls<any[]>('GetAllLoc', HttpMethod.Get, Host.spaManagement);
        return locations.map(loc => {
            return {
                id: loc.id,
                description: loc.description,
                showInDropDown: true
            }
        });
    }

    public async getCustomField(customFieldColumnName: string): Promise<DropDownData[]> {

        let customFields = await this.invokeCalls<CustomField[]>('GetCustomFieldsWithValues', HttpMethod.Get, Host.spaManagement);
        return customFields.find(cf => cf.columnName.toLowerCase() == customFieldColumnName.toLowerCase())
            .customFieldValues.map(loc => {
                return {
                    id: loc.id,
                    description: loc.description,
                    showInDropDown: true
                }
            });
    }

    public async getAllTherapist(reportCode: string, includeInactive: boolean = true): Promise<DropDownData[]> {
        let therapists = await this.invokeCalls<any[]>('GetAllTherapist', HttpMethod.Get, Host.spaManagement);
        therapists = this.fillAllValidTherapist(therapists, reportCode);
        let data: DropDownData[] = therapists.map(therapist => {
            return {
                description: therapist.firstName + ' ' + therapist.lastName,
                id: therapist.id,
                isActive: therapist.isActive,
                showInDropDown: therapist.isActive
            }
        });
        data = _.orderBy(data, [ data=> data.description.toLowerCase()], 'asc');
        if(reportCode!=AllReports.ProjectedRevenue){
        if (!includeInactive) {
            data = this.business.toggleIncludeInactive(false, data);
        }
    }

        return data;
    }

    public async getAllLinkCodes(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetAllLinkCodes', HttpMethod.Get, Host.spaManagement);
        return _result.map((lc: LinkCodes) => {
            let _isActive: boolean = (this.utils.getDate(lc.startDate) <= this.propertyInfo.CurrentDate && this.propertyInfo.CurrentDate >= this.utils.getDate(lc.endDate));
            return {
                id: lc.id,
                description: lc.description,
                isActive: _isActive,
                showInDropDown: true
            }
        });
    }

    public async getAllCancelReasons(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetAllCancelReasons', HttpMethod.Get, Host.spaManagement);
        return _result.map((cr: CancelReasons) => {
            
            return {
                id: cr.id,
                description: cr.reasonCode,                      
                showInDropDown: true
            }
        });
    }

    public async getAllMultiPacks(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('getAllMultiPacks', HttpMethod.Get, Host.retailPOS);
        return _result.map((mt: MultiPackTypes) => {
            return {
                id: mt.id,
                description: mt.itemDescription,
                showInDropDown: true
            }
        });
    }

    public async getAllOutlets(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetOutletsByProperty', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        return _result.map((lc: SubPropertyModel) => {
            return {
                id: lc.subPropertyID,
                description: lc.subPropertyName,
                showInDropDown: true
            };
        });
    }

    public async GetAllCategory(): Promise<DropDownData[]> {
        let _result = await this.invokeCalls<any[]>('GetCategoryAndSubCategoryLink', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        return _result.map((lc: LinkCodes) => {
            let _isActive: boolean = (this.propertyInfo.CurrentDate <= this.utils.getDate(lc.endDate));
            return {
                id: lc.id,
                description: lc.description,
                isActive: _isActive,
                showInDropDown: true
            }
        });
    }


    public async GetAllCategoryAndSubCategoryLink(): Promise<DropDownData[]> {
        try {
            return await this.invokeCalls<any[]>('GetCategoryAndSubCategoryLink', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
       }
    }

    /**
     * GetAll
     */
    public async GetAllUsers(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<Users[]>('GetAllUsers', HttpMethod.Get, Host.authentication, null, { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) });
        return response.map((element: Users) => {
            return {
                id: element.userId,
                description: element.firstName + ' ' + element.lastName,
                showInDropDown: true,
                isActive: element.isActive
            };
        });

    }


    public async GetAllUsersByPropertyId(includeInactive: boolean = true): Promise<DropDownData[]> {
        let response = await this.invokeCalls<Users[]>('GetAllUsersByPropertyId', HttpMethod.Get, Host.authentication, null, { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')), productId: Number(this.utils.GetPropertyInfo('ProductId')) });
        let data: DropDownData[] = response.map((element: Users) => {
            return {
                id: element.userId,
                description: element.firstName + ' ' + element.lastName,
                showInDropDown: true,
                isActive: element.isActive
            };
        });
        if (!includeInactive) {
            data = this.business.toggleIncludeInactive(false, data);
        }

        return data;
    }

    public async GetUsersByPropertyId(includeInactive: boolean = true): Promise<DropDownData[]> {
        let response = await this.invokeCalls<Users[]>('GetAllUsersByPropertyId', HttpMethod.Get, Host.authentication, null, { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')), productId: Number(this.utils.GetPropertyInfo('ProductId')) });
        let data: DropDownData[] = response.map((element: Users) => {
            return {
                id: element.userId,
                description: element.userName + ' - ' + element.firstName + ' ' + element.lastName,
                showInDropDown: true,
                isActive: element.isActive
            };
        });
        if (!includeInactive) {
            data = this.business.toggleIncludeInactive(false, data);
        }

        return data;
    }

    public async GetAllCategoryGroups(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<any[]>('GetAllCategoryGroups', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        let clerks = [];
        clerks = response.map((element: any) => {
            return {
                id: element.id,
                description: element.name,
                showInDropDown: true
            };
        });
        return clerks;

    }

    public async GetAllCategories(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<any[]>('GetAllCategories', HttpMethod.Get, Host.retailManagement, null, { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        return response.map(element => {
            return {
                id: element.id,
                description: element.name,
                categoryGroup: element.retailCategoryGroupId,
                isActive: element.isActive,
                listOrder: element.listOrder,
                eligibleForDiscount: element.eligibleForDiscount,
                showInDropDown: true
            }
        });

    }

    public async GetDiscountTypes(): Promise<DropDownData[]> {
        let response = await this.invokeCalls<any[]>('GetDiscountTypes', HttpMethod.Get, Host.retailManagement, null);
        return response.map(element => {
            return {
                id: element.id,
                description: element.type,
                active: element.isActive,
                listOrder: element.listOrder,
                showInDropDown: true
            };
        });

    }

    private fillAllValidTherapist(Alltherapist: TherapistData[], reportCode: string): TherapistData[] {
        if (reportCode == AllReports.ServiceCharge) {
            Alltherapist = Alltherapist.filter(therapist => therapist.allowServiceCharge);
        } else if (reportCode == AllReports.CommissionReport) {
            Alltherapist = Alltherapist.filter(therapist => therapist.allowCommission);
        } else if (reportCode == AllReports.Gratuity) {
            Alltherapist = Alltherapist.filter(therapist => therapist.allowGratuity);
        } else if (reportCode == AllReports.StaffMemberSummary) {
            Alltherapist = Alltherapist.filter(therapist => (therapist.allowCommission || therapist.allowGratuity || therapist.allowServiceCharge));
        }
        return Alltherapist;
    }


    private async invokeCalls<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any, queryString?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: host,
            method: callType,
            body: body,
            uriParams: uRIParams,
            queryString: queryString
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

    public async getAllTherapistWithStaffType(reportCode: string, includeInactive: boolean = true): Promise<DropDownData[]> {
        let therapists = await this.invokeCalls<any[]>('GetAllTherapist', HttpMethod.Get, Host.spaManagement);
        therapists = this.fillAllValidTherapist(therapists, reportCode);
        let data: DropDownData[] = therapists.map(therapist => {
            return {
                description: therapist.firstName + ' ' + therapist.lastName,
                id: therapist.id,
                isActive: therapist.isActive,
                showInDropDown: therapist.isActive,
                staffType: THERAPIST
            }
        });
        if (!includeInactive) {
            data = this.business.toggleIncludeInactive(false, data);
        }

        return data;
    }

    public async getAllStaffs(reportCode): Promise<DropDownData[]> {
        const therapists = await this.getAllTherapistWithStaffType(reportCode);
        const users = await this.GetAllUsers();
        const usersreportconfig: any = await this.invokeCalls('GetAllUserRetailConfiguration', HttpMethod.Get, Host.retailManagement);

        let userData = [];
        users.forEach((element) => {
            let data = usersreportconfig.filter((res) => {
                return res.userId === element.id;
            });

            let FormattedUserData = data.map(result => {
                let dat = [];
                if (reportCode === AllReports.CommissionReport && result.allowCommission) {

                    return {
                        description: element.description,
                        id: result.userId,
                        isActive: element.isActive,
                        showInDropDown: element.isActive,
                        staffType: USER
                    };
                } else if (reportCode === AllReports.Gratuity && result.allowGratuity) {
                    return {
                        description: element.description,
                        id: result.userId,
                        isActive: element.isActive,
                        showInDropDown: element.isActive,
                        staffType: USER
                    };
                } else if (reportCode === AllReports.ServiceCharge && result.allowServiceCharge) {
                    return {
                        description: element.description,
                        id: result.userId,
                        isActive: element.isActive,
                        showInDropDown: element.isActive,
                        staffType: USER
                    };
                } else if (reportCode === AllReports.StaffMemberSummary) {
                    if (result.allowCommission || result.allowGratuity || result.allowServiceCharge) {
                        return {
                            description: element.description,
                            id: result.userId,
                            isActive: element.isActive,
                            showInDropDown: element.isActive,
                            staffType: USER
                        };
                    }
                }
            });
            if (FormattedUserData[0]) {
                userData.push(FormattedUserData[0]);
            }
        });
        return _.concat(therapists, userData);
    }
    public async getServiceGroup(): Promise<DropDownData[]> {
        let serviceGroups = await this.invokeCalls<any[]>('GetAllServiceGrp', HttpMethod.Get, Host.spaManagement);
        return serviceGroups.map(sg => {
            return {
                id: sg.id,
                description: sg.description,
                showInDropDown: true
            }
        }) as DropDownData[];
    }

    public async getServices(): Promise<any[]> {
        let services = await this.invokeCalls<any[]>('GetAllSpaService', HttpMethod.Get, Host.spaManagement);
        let data: DropDownData[] = services.map(s => {
            return {
                id: s.id,
                description: s.description,
                showInDropDown: true
            }
        });

        return services;
    }

    public async getClassGroup(): Promise<DropDownData[]> {
        var querystring: KeyValuePair = { key: "isClassGroup", value: [true] };
        let serviceGroups = await this.invokeCalls<any[]>('GetAllServiceGrpByUserId', HttpMethod.Get, Host.spaManagement,null,null,querystring);
        return serviceGroups.map(sg => {
            return {
                id: sg.id,
                description: sg.description,
                showInDropDown: true
            }
        }) as DropDownData[];
    }


    public async getClasses(): Promise<any[]> {
        var querystring: KeyValuePair = { key: "isClass", value: [true] };
        let services = await this.invokeCalls<any[]>('GetAllSpaService', HttpMethod.Get, Host.spaManagement,null,null,querystring);
        let data: DropDownData[] = services.map(s => {
            return {
                id: s.id,
                description: s.description,
                showInDropDown: true
            }
        });

        return services;
    }

        public async getAllInstructors(reportCode: string, includeInactive: boolean = true): Promise<DropDownData[]> {
            let therapists = await this.invokeCalls<any[]>('GetAllTherapist', HttpMethod.Get, Host.spaManagement,null,null,{ key: "isInstructor", value:  [true]});
            therapists = this.fillAllValidTherapist(therapists, reportCode);
            let data: DropDownData[] = therapists.map(therapist => {
                return {
                    description: therapist.firstName + ' ' + therapist.lastName,
                    id: therapist.id,
                    isActive: therapist.isActive,
                    showInDropDown: therapist.isActive
                }
            });
            data = _.orderBy(data, [ data=> data.description.toLowerCase()], 'asc');
    
            return data;
        }
}
