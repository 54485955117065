import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ButtonType, AlertType } from '../../enums';
import { MatButton } from '@angular/material/button';


@Component({
  selector: 'app-spa-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent {
  continueText = this._Localization.captions.alertPopup.continue;
  cancelText = this._Localization.captions.alertPopup.cancel;
  YES = this._Localization.captions.alertPopup.yes;
  NO = this._Localization.captions.alertPopup.no;
  okText = this._Localization.captions.alertPopup.okay;
  success = this._Localization.captions.alertPopup.success;
  warning = this._Localization.captions.alertPopup.warning;
  error = this._Localization.captions.alertPopup.error;
  wellDone = this._Localization.captions.alertPopup.welldone;
  info = this._Localization.captions.alertPopup.info;
  accessDenied = this._Localization.captions.alertPopup.accessDenined;
  done = this._Localization.captions.alertPopup.done;
  RESEV = this._Localization.captions.alertPopup.resev;
  ALL = this._Localization.captions.alertPopup.all;
  About = this._Localization.captions.alertPopup.about;
  Override = this._Localization.captions.alertPopup.override;
  productVersion: string;

  
  public alertType = AlertType;
  public buttonType = ButtonType;
  @ViewChild('btnRef') buttonRef: MatButton;
  constructor(public dialogRef: MatDialogRef<AlertPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Localization: SpaLocalization) {
    let appver = sessionStorage.getItem('userProductVersion');
    this.productVersion = appver && appver != "null" ? appver : '12.0';
  }

  ngAfterViewInit() {
    this.buttonRef.focus();
 }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }
 
}
