import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[nowhitespace]'
})
 

export class no_white_space_Directive {
    constructor(private element: ElementRef, private control: NgControl) { }

    @HostListener('blur', ['$event']) onblur(_event) {
        let value = String(this.control.control.value || "");
        this.noWhiteSpaceValidator(value);
    }
    @HostListener('paste', ['$event']) onpaste(_event) {
        this.control.control.markAsDirty();
        let value = String(this.control.control.value || "");
        this.noWhiteSpaceValidator(value);
    }

    noWhiteSpaceValidator(value) {
        this.control.control.setValue(value.trim());
    }
}