import { Injectable } from "@angular/core";
import { PayAgentService } from "src/app/retail/shared/service/payagent.service";
import { NonPMAgentRequestHandler } from 'src/app/retail/payment/NonPMAgentRequestHandler';
import { PaymentMethod } from 'src/app/retail/shared/service/payment/payment-business.model';
@Injectable({ providedIn: 'root' })
export class PMAgentServiceProvider {      
    constructor(
          private _payAgent: PayAgentService
        , private _nonPMAgentRequestHandler: NonPMAgentRequestHandler
    ) {
        
    }
    RefreshConfig(){
        this.PaymentProcessor._featureFlagService.$scope = this.PaymentProcessor;
        this.PaymentProcessor._featureFlagService.RefreshConfig();
    }
    get PaymentProcessor(){ 
        return this.IsSkipPMAgent ? this._nonPMAgentRequestHandler : this._payAgent;
    }
    private get IsSkipPMAgent(): boolean {    
        //Property Level SkipPMAgent.
        const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        const IsPropertyLevelSkipPMAgent = propConfig?.SkipPMAgent ?? '';
        return (IsPropertyLevelSkipPMAgent.trim().toLowerCase() == 'true');
    }
}