import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import * as moment from "moment";
import * as _ from "lodash"; // STORAGE THE BACK ARRAY
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../settings.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { Host, SPAScheduleBreakPoint, PromptType, ButtonOptions } from '../../../shared/globalsContant';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { UserAlerts } from '../../../core/config/alerts-config';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';
@Component({
  selector: 'app-lunch-setup',
  templateUrl: './lunch-setup.component.html',
  styleUrls: ['./lunch-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LunchSetupComponent implements OnInit, OnDestroy {
  weekstart: any;
  weekend: any;
  aWeekArr: any[];
  lunchForm: UntypedFormGroup;
  startDate: any;
  endDate: any;
  lunchlist: any;
  lunchInfoList: any[];
  captions: any = this.localization.captions.setting;
  common: any = this.localization.captions.utilities;
  minDate: Date = this.PropertyInfo.CurrentDate;
  maxDate: Date = this.utils.AddDays(this.minDate, 6);
  propertyDate: any;
  startDateArray: any;
  endDateArray: any;
  isUserAuthorized = true;
  isViewOnly = true;
  dialogSubscription: ISubscription;
  placeholderFormat: string;
  commonCaptions: any;
  floatLabel:string;
  constructor(private Form: UntypedFormBuilder, private BP: BreakPointAccess, public dialog: MatDialog, public ss: SettingsService, private http: HttpServiceCall,
    public utils: SpaUtilities, public localization: SpaLocalization, private userAlert: UserAlerts, private PropertyInfo: SpaPropertyInformation) {
      this.placeholderFormat = this.localization.inputDateFormat;
      this.commonCaptions = this.localization.captions.common;
    this.weekstart = moment(this.PropertyInfo.CurrentDate).startOf("isoWeek");
    this.weekend = moment(this.PropertyInfo.CurrentDate).endOf("isoWeek");
    this.startDateArray = this.minDate;
    this.endDateArray = this.utils.AddDays(this.startDateArray, 6);
    this.lunchForm = this.Form.group({
      startDate: [this.startDateArray, Validators.required],
      endDate: [this.endDateArray, Validators.required]
    });
    ss.startDate = this.lunchForm.controls.startDate.value;
    ss.endDate = this.lunchForm.controls.endDate.value;
    this.floatLabel = this.localization.setFloatLabel;
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingLunchSetup]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingLunchSetup);
    if (this.isViewOnly) {
      this.utils.disableControls(this.lunchForm);
    }
  }
  ngOnInit() {
    this.ValidateBreakPoint();
    this.propertyDate = this.utils.convertDateFormat(this.PropertyInfo.CurrentDate);
    moment.locale(this.localization.localeCode);
    this.aWeekArr = this.WeekArray(
      moment(_.cloneDeep(this.startDateArray)),
      moment(_.cloneDeep(this.endDateArray))
    );
    this.lunchForm = this.Form.group({
      startDate: [this.startDateArray, Validators.required],
      endDate: [this.endDateArray, Validators.required]
    });
    this.startDate = this.utils.formatDate(this.lunchForm.controls.startDate.value);
    this.endDate = this.utils.formatDate(this.lunchForm.controls.endDate.value);

    this.ss.GetAllLunchSlots(this.startDate, this.endDate);
  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  switchtoWeek = () => {
    this.startDate = this.lunchForm.controls.startDate.value;
    this.maxDate = this.utils.AddDays(this.startDate, 6);
    this.endDate = this.utils.AddDays(this.startDate, 6);
    const endDateData = this.lunchForm.controls.endDate.value;
    this.aWeekArr = this.WeekArray(moment(this.startDate), moment(this.endDate));
    this.ss.startDate = this.lunchForm.controls.startDate.value;
    this.ss.endDate = this.lunchForm.controls.endDate.value;
    this.ss.GetAllLunchSlots(this.utils.formatDate(this.startDate), this.utils.formatDate(endDateData));
  }

  // Generate Week Array starts
  WeekArray(strt, end) {
    const aweek = [];
    let row1, row2, row3, nxtday;
    let noOfDayDisp = end.diff(strt, "days");
    for (var i = 0; i <= noOfDayDisp; i++) {
      if (i == 0) {
        row1 = strt.format("DD/MM/YYYY");
        row2 = strt.format("dddd");
        row3 = this.localization.LocalizeDate(strt.toDate());
        aweek.push({ row1: row1, row2: row2, row3: row3, year: strt.format("YYYY") });
      } else {
        nxtday = strt.add(1, "day");
        row1 = strt.format("DD/MM/YYYY");
        row2 = nxtday.format("dddd");
        row3 = this.localization.LocalizeDate(strt.toDate());
        aweek.push({ row1: row1, row2: row2, row3: row3, year: nxtday.format("YYYY") });
      }
    }
    return aweek;
  }

  edit(lunchdata, curdata, index) {
    let popupConfiguration: popupConfig;
    popupConfiguration = {
      operation: "edit"
    }
    let dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      height: 'auto',
      width: '700px',
      data: { headername: this.captions.EditLunchSetup, closebool: true, templatename: 'LS', datarecord: { lunchdata, curdata, index }, popupConfig: popupConfiguration },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });

    this.dialogSubscription = dialogRef.afterClosed().subscribe(() => {
      this.startDate = this.utils.formatDate(this.lunchForm.controls.startDate.value);
      this.endDate = this.utils.formatDate(this.lunchForm.controls.endDate.value);
      this.ss.GetAllLunchSlots(this.startDate, this.endDate);
    });
  }
  delete(lunchData) {

    const dateTime = this.localization.ConvertDateToISODateTime(this.localization.ConvertStringDateTimeToDate(lunchData[1],lunchData[0].lunchTime));
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "DeleteLunchSetup",
      uriParams: { id: lunchData[0].id, lunchTime: dateTime },
      method: HttpMethod.Delete,
      showError: true,
      extraParams: []
    });
  }

  promptForDelete(lunchData, startDate) {
    this.userAlert.showPrompt(PromptType.Delete, this.promptForDeleteCallBack.bind(this), [lunchData, startDate])
  }

  promptForDeleteCallBack(resultFromPopup: string, lunchData) {
    if (resultFromPopup.toLowerCase() == ButtonOptions.Ok.toLowerCase()) {
      this.delete(lunchData);
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "DeleteLunchSetup") {
      this.startDate = this.utils.formatDate(this.lunchForm.controls.startDate.value);
      this.endDate = this.utils.formatDate(this.lunchForm.controls.endDate.value);
      this.ss.GetAllLunchSlots(this.startDate, this.endDate);
    }
  }


}
