import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import { DetailsBuilderData } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { FormType, RadioOptions, SingleField } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { GlobalSystemPreference, SystemPreference, SystemPreferenceConfig } from '../shared/interface/system-preference.interface';
import { FormFieldType } from 'src/app/eatecui/source/setup-master/shared/interface/form-interface/fieldType.enum';
import * as _ from 'lodash';
@Injectable({
    providedIn: 'root',
})
export class SysytemPreferenceService {
    globalSystemPreference: Array<GlobalSystemPreference> = [];
    backOrderReqData: any;
    constructor(private httpService: HttpService, private http: HttpClient) {
        console.log('reports');
    }

    getSystemPreferenceConfig() {
        try {
            const requestUrl = '/inventory/api/TransactionSystemPreferences';
            return requestUrl;
        } catch (error) {
            console.error(error);
        }
    }

    constractPreferenceList(systemPreference: SystemPreference, fromSearch = false, name = null): any {
         try {
            systemPreference.SyastemFormType = this.constractPreferenceFormType(systemPreference, fromSearch, name);
            return systemPreference;
         } catch (error) {

         }
    }

    constractPreferenceFormType(systemPreference: SystemPreference, fromSearch = false, name: string = ''): Array<FormType> {
     try {
        const FormTypeArray: Array<FormType> = [];
        systemPreference.SystemPreferences.forEach((preferenceConfig: SystemPreferenceConfig) => {
          const systemFormType = {} as FormType;
          systemFormType.class = name && fromSearch && preferenceConfig.Description.toLowerCase().
            includes(name.toLowerCase()) ? 'highlight-search-class' : '';
          systemFormType.name = preferenceConfig.Description;
          systemFormType.type = (preferenceConfig.InputType !== null &&  preferenceConfig.InputType === 2) 
                                ? FormFieldType.DROPDOWN : (preferenceConfig.InputType !== null &&  
                                preferenceConfig.InputType === 1 ? FormFieldType.CHECKBOXSINGLE : FormFieldType.TEXT);
          systemFormType.label = preferenceConfig.Description;
          systemFormType.fieldType = {
            'SingleData': {
                name: preferenceConfig.Description,
                label: preferenceConfig.Description,
                options: this.constractModelOption(preferenceConfig),
                value: preferenceConfig.InputType === 1 ? (preferenceConfig.LookupValue === 1 ? true : false) 
                        : preferenceConfig.LookupValue,
                
            }
          };
          FormTypeArray.push(systemFormType);
        });
      return FormTypeArray;
     } catch (error) {
         console.error(error);
     }
    }

    constractModelOption(preferenceConfig: SystemPreferenceConfig) {
        try {
            const optionList: Array<RadioOptions> = [];
            if ( preferenceConfig.LookupData !== null ) {
                const splitLookUp = preferenceConfig.LookupData.split('~');
                const lookUpkey = splitLookUp[0].split(',');
                const lookUpValue = splitLookUp[1].split(',');
               lookUpkey.map((x: any, i: number) => {
                  const options = {} as RadioOptions;
                  options.key = parseInt(x, 10);
                  options.label = lookUpValue[i];
                  optionList.push(options);
                });
            }
            return optionList; 
        } catch (error) {
            console.error(error);
        }
    }

    constructPostObject(formValues: any, preferences: SystemPreference[]) {
        const systemPreferenceList = [] as  SystemPreference[];
        
        const formKeys = Object.keys(formValues.controls);
        preferences.forEach(pref => {
            pref.SystemPreferences.forEach(SysPref => {
                if (formKeys.indexOf(SysPref.Description) !== -1) {
                    const compareValue = SysPref.InputType === 1 ? (SysPref.LookupValue === 1 ? true : false) : SysPref.LookupValue;
                    if (formValues.value[SysPref.Description] !== compareValue) {
                        const sysPrefObject = _.cloneDeep(SysPref);
                        sysPrefObject.LookupValue = (formValues.value[sysPrefObject.Description].toString() === 'true') ? 1 : 
                            (formValues.value[sysPrefObject.Description].toString() === 'false' ? 0 
                            : formValues.value[sysPrefObject.Description]);
                        const systemPref = {} as SystemPreference;
                        systemPref.SystemPreferences = Array<SystemPreferenceConfig>();
                        systemPref.GroupName = pref.GroupName;
                        systemPref.SystemPreferences.push(sysPrefObject);
                        if (systemPreferenceList.length === 0 ) {
                            systemPreferenceList.push(systemPref);
                        } else {
                            if (systemPreferenceList.filter(x => x.GroupName === systemPref.GroupName).length > 0) {
                                systemPreferenceList.forEach(x => {
                                    if (x.GroupName === sysPrefObject.GroupName) {
                                        x.SystemPreferences.push(sysPrefObject);
                                    }
                                });
                            } else {
                                systemPreferenceList.push(systemPref);
                            }
                        }
                    }
                }
            });
        });
        console.log(systemPreferenceList);
        return systemPreferenceList;
    }


}