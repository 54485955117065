import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { sortBy } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from '../../localization/localization';
import { ButtonValue } from '../../Models/ag-models';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { SearchTemplateBusiness } from './search-eform-template.business';

@Component({
  selector: 'app-search-eform-template',
  templateUrl: './search-eform-template.component.html',
  styleUrls: ['./search-eform-template.component.scss'],
  providers: [SearchTemplateBusiness],
})
export class SearchEformTemplateComponent implements OnInit {

  item = [];
  captions: any;
  searchText1: any;
  templateOptions: any;
  clonedTemplateOptions: any;
  eformSearchForm: UntypedFormGroup;
  title: string;
  token: string = '';
  dialogData : any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  filteredSet: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() dataMagineConfigData;
  @Input() selectedForm;
  @Output() searchValue = new EventEmitter();
  constructor(
    private localization: Localization,
    private business: SearchTemplateBusiness, 
    private fb: UntypedFormBuilder, 
    private utils: CommonUtilities,
    private dialogRef: MatDialogRef<SearchEformTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.initialize();
    this.eformSearchForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(result => {
      this.saveButton.disabledproperty = !(this.eformSearchForm.valid && this.eformSearchForm.dirty);
      this.saveButton = { ...this.saveButton };
    });
    
  }

  async initialize() {
    this.utils.ToggleLoader(true);
    this.dialogData = this.data;
    this.eformSearchForm = this.fb.group({
      templates: '',
    });

    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    }

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    }

    if(this.dialogData?.title) {
      this.title = this.dialogData.title
    }

    let authurl:string;
    let clientId:string;
    let clientSecret:string;
    let eformlisturl:string;
    let tenantID:string;
    let propertyID:string;
    
    if(this.data.from === 'eFormSigCapture')
    {
      var dmEformsConfig = this.data?.dmEformsConfig;
      authurl = dmEformsConfig?.authURL;
      clientId = dmEformsConfig?.clientId;
      clientSecret = dmEformsConfig?.clientSecret;
      eformlisturl = dmEformsConfig?.eformsListURL;
      tenantID = dmEformsConfig?.eformsTenantId;
      propertyID = dmEformsConfig?.eformsPropertyId;
    }
    else
    {
      authurl = this.dataMagineConfigData?.authurl;
      clientId = this.dataMagineConfigData?.clientId;
      clientSecret = this.dataMagineConfigData?.clientSecret;
      eformlisturl = this.dataMagineConfigData?.eformlisturl;
      tenantID = this.dataMagineConfigData?.tenantID;
      propertyID = this.dataMagineConfigData?.propertyID;
    }

    var res = await this.business.getDMToken(authurl, clientId, clientSecret);
    if (res && res?.access_token) {
      this.token = res?.access_token;
    }
    this.utils.ToggleLoader(true);
    var eformList = await this.business.getEformsDropDownList(this.token, eformlisturl, tenantID, propertyID);
    this.clonedTemplateOptions = this.templateOptions = sortBy(eformList, "label");
    this.searchText1 = this.templateOptions.filter(x=> x.id === this.selectedForm)[0]?.label;
  }

  onSave(){
    this.dialogRef.close({
      data: this.filteredSet?.id
    });
  }
  onCancel(){
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close();
  }
  searchValueChange(eve) {
  this.searchText1 = eve;
    if(eve == '') {
      this.clonedTemplateOptions = [...this.templateOptions];
      this.eformSearchForm.controls.templates.setValue('');
      this.searchValue.emit('');
    }
    else {
      const filterValue = eve.toLowerCase();
      this.clonedTemplateOptions = this.templateOptions.filter(x=> x.label.toLowerCase().includes(filterValue))
      this.clonedTemplateOptions = [...this.clonedTemplateOptions];
    }
  }

  registerChange(eve) {
    this.filteredSet = this.templateOptions.filter(x=> x.id === eve.value)[0]
    this.searchText1 = this.filteredSet.label;
    this.searchValue.emit(this.filteredSet.id);
  }

}
