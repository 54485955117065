import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'multiLineEllipses'
})
export class MultiLineEllipses implements PipeTransform {
    /**
     * @param ellipseAt this is the number(position in the given string) thereby ellipses(...) begin.
     */

    transform(value: string, ellipseAt: number): any {
        if (value && value != '') {
            return value.length < ellipseAt ? value : value.substring(0, ellipseAt) + "...";
        } else {
            return value;
        }
    }

}
