import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationDetail, ServiceLocations, SpaServiceLinkLocation, SpaServiceLocation } from '../../../shared/business/view-settings.modals';
import { HttpMethod, HttpServiceCall } from '../../../shared/service/http-call.service';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { SPAConfig } from '../../../core/config/SPA-config';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { MatTabGroup, MatTabHeader, MatTab } from '@angular/material/tabs';
import { UntypedFormControl } from '@angular/forms';
import { ButtonType } from '../../../shared/globalsContant';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';



@Component({
  selector: 'app-service-location',
  templateUrl: './service-location.component.html',
  styleUrls: ['./service-location.component.scss']
})
export class ServiceLocationComponent implements OnInit {

  clickbutton: any;
  @Input() dataInput: any;
  @Input() popupConfigs: popupConfig;
  isUserAuthorized = true;
  isViewOnly = true;
  slDataInput: any = [];
  FormGrp: UntypedFormGroup;
  blnReadOnly: boolean;
  maxListOrder: number;
  maxGuests: number;
  tCode: string = this.spaConfig.captions.setting.LocationCode;
  tName: string = this.spaConfig.captions.setting.LocName;
  tDesc: string = this.spaConfig.captions.setting.LocDesc;
  tLstOrder: string = this.spaConfig.captions.setting.ListOrder;
  vCodeErr: string = this.spaConfig.captions.setting.MissingLocationCode;
  vNameErr: string = this.spaConfig.captions.setting.MissingLocationName;
  vDescErr: string = this.spaConfig.captions.setting.MissingLocationDescription;
  tMaxGuest: string = this.spaConfig.captions.setting.MaxGuests;
  floatLabel:string;
  serviceMapped : any = [];
  public tableDataTemp: any = [];
  public tableoptions: any;
  public tableDataArray: any = [];
  tableHeader: any = [];
  historicBoolControl: UntypedFormControl;
  captions: any = this.spaConfig.captions;
  allCaptions: any;
  isEditLocation = false;
  existingLisOrder: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public dialogRef: MatDialogRef<ServiceLocationComponent>,public localization:SpaLocalization, public dialog: MatDialog, private Form: UntypedFormBuilder, 
    private http: HttpServiceCall, private vsBusiness: ViewSettingClientBusiness, private spaConfig: SPAConfig, 
    private utils: SpaUtilities, private BP: BreakPointAccess, public propertyInfo: SpaPropertyInformation) {
    this.floatLabel = this.localization.setFloatLabel;
    this.allCaptions = this.localization.captions;
    this.historicBoolControl = new UntypedFormControl(false);
}
  onNoClick(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingServiceLocation]);
    this.isViewOnly = this.BP.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.SettingServiceLocation);
    if (this.isViewOnly) {
      this.utils.disableControls(this.FormGrp);
    }
  }
  /***
   * @function generatepopupConfig();
   * @method functinality non returm method
   * @inutparams non
   * @output params none
   * @description check this.popupConfigs.operation is create or edit working as based
   */

  generatepopupConfig(): void {
    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.captions.setting.save;
      this.blnReadOnly = false;
      this.maxListOrder = this.popupConfigs.maxListOrder;
      this.FormGrp.patchValue({
        code: this.dataInput ? this.dataInput.location.code : '',
        listOrder: this.maxListOrder,
        description: this.dataInput ? this.dataInput.location.description : '',
        comments: this.dataInput ? this.dataInput.location.comments : ''
      });
    } else if (this.popupConfigs.operation == "edit") {
      this.clickbutton = this.captions.setting.update;
      this.blnReadOnly = true;
      this.FormGrp.patchValue(this.dataInput.location);
      this.existingLisOrder = this.dataInput.location.listOrder;
      this.isEditLocation = true;
    }
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      code: ['', [Validators.required, EmptyValueValidator]],
      description: ['', [Validators.required, EmptyValueValidator]],
      comments: [''],
      listOrder: [this.popupConfigs.maxListOrder],
      maxGuests: [0, [Validators.required, Validators.min(0), Validators.max(999)]]
    });
    this.saveButton = {
      type: 'primary',
      label: this.popupConfigs.operation == "create" ? this.captions.setting.save : this.captions.setting.update,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.setting.cancel,
    }
    this.ValidateBreakPoint();
    this.generatepopupConfig();
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.GetLocationMaintenaceRecords()
    this.GetAllServiceDetails();
    this.BindTable();
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.saveButton.disabledproperty = !(this.FormGrp.valid && this.FormGrp.dirty);
      this.saveButton = { ...this.saveButton };
    });
  }
  saveServiceLocation() {
    this.saveButton.disabledproperty = true;
    if (this.popupConfigs.operation == "create") {
      this.createLocation();
    } else if (this.popupConfigs.operation == "edit") {
      this.updateLocation();
      this.isEditLocation = true;
    }
  }

  /*
   * Edit Location
   */
  async updateLocation() {
    const _code = this.FormGrp.controls['code'].value;
    let _listOrder = this.FormGrp.controls['listOrder'].value;
    let _maxGuests = this.FormGrp.controls['maxGuests'].value;
    if (!_listOrder || _listOrder==='') {
        _listOrder = this.existingLisOrder;
      } 

    if (!_maxGuests || _maxGuests === '') {
      _maxGuests = 0;
    }

    const _desc = this.FormGrp.controls['description'].value;
    const _comm = this.FormGrp.controls['comments'].value;
    const locationDetail : ServiceLocations = { id: this.dataInput.location.id, code: _code, listOrder: _listOrder,description: _desc,comments: _comm, maxGuests: _maxGuests }
    const _serviceLocations : SpaServiceLinkLocation[] = await this.BuildLinkedLocation()
    const bodyObj: LocationDetail = {location: locationDetail, serviceLocations: _serviceLocations};

    this.http.CallApiWithCallback<ServiceLocations[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateLoc",
      method: HttpMethod.Put,
      uriParams: bodyObj.location.code,
      body: bodyObj,
      showError: true,
      extraParams: [bodyObj]
    });
  }


  /*
  * Create Location
  */
  createLocation() {
    const _code = this.FormGrp.controls['code'].value;
    const _listOrder = this.FormGrp.controls['listOrder'].value != "" ? this.FormGrp.controls['listOrder'].value : this.maxListOrder;
    const _desc = this.FormGrp.controls['description'].value;
    const _comm = this.FormGrp.controls['comments'].value;
    const _maxGuests = this.FormGrp.controls['maxGuests'].value;
    const locationDetail : ServiceLocations = {
      code: _code.toUpperCase(), listOrder: _listOrder,description: _desc,comments: _comm, maxGuests: _maxGuests
    };
    const _serviceLocations : SpaServiceLinkLocation[] = this.BuildLinkedLocation();
    const bodyObj: LocationDetail = {location: locationDetail, serviceLocations: _serviceLocations};

    this.http.CallApiWithCallback<ServiceLocations[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CreateLoc",
      method: HttpMethod.Post,
      body: bodyObj,
      showError: true,
      extraParams: [bodyObj]
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateLoc" || callDesc == "UpdateLoc") {
      this.saveButton.disabledproperty = false;
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
    if(callDesc == "GetAllSpaService"){
      this.serviceMapped = this.MapToServiceLocation(result.result);
      this.serviceMapped = this.utils.sortObj(this.serviceMapped, 'listOrder');
      this.setLinkValues(this.dataInput == null ? "" : this.dataInput.serviceLocations);
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    this.saveButton.disabledproperty = false;
    if (callDesc == "CreateLoc" || callDesc == "UpdateLoc") {
      if (error) { error["NewData"] = extraParams[0]; }
    }
  }


  async selectLocations(event: any, index: any){
    if(this.isEditLocation){
    if (this.serviceMapped[index].selected) {
      const isUsedInAppointment = await this.IsLocationInUse(this.serviceMapped[index].id);
      if (isUsedInAppointment) {
        this.utils.ShowErrorMessage(this.captions.setting.Error, this.captions.setting.ServiceInUse, ButtonType.Ok);
        return; 
      }
      else {
        const IsServiceUnMappingAllowed = await this.IsServiceUnMappingAllowed(this.serviceMapped[index].id);
        if(!IsServiceUnMappingAllowed){
          this.utils.ShowErrorMessage(this.captions.setting.Error, this.captions.setting.ServiceUnMappingNotAllowed, ButtonType.Ok)
          return;
        }
      } 
    }
  }
    this.serviceMapped[index].selected = !this.serviceMapped[index].selected;
    this.FormGrp.markAsTouched();
    this.saveButton = {
      type: 'primary',
      label: this.popupConfigs.operation == "create" ? this.captions.setting.save : this.captions.setting.update,
      disabledproperty: false
    }
    this.FormGrp.markAsDirty();
  }

  async IsLocationInUse(serviceId: number): Promise<boolean> {
    const results = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.schedule,
      callDesc: 'IsLocationInUse',
      method: HttpMethod.Get,
      uriParams: { serviceId: serviceId, locationId: this.dataInput.location.id }
    });
    return results.result;
  }

  async IsServiceUnMappingAllowed(serviceId: number): Promise<boolean> {
    const results = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: 'isServiceHasOneLocation',
      method: HttpMethod.Get,
      uriParams: { serviceId: serviceId, locationId: this.dataInput.location.id }
    });
    return results.result;
  }

 BindTable() {
      this.tableHeader = [
        { 'title': this.allCaptions.tbl_hdr_beginAt, 'jsonkey': 'beginAt', 'searchable': false, 'sortable': true, 'Sortable': 'beginAt' },
        { 'title': this.allCaptions.tbl_hdr_endAt, 'jsonkey': 'endAt', 'searchable': false, 'sortable': true, 'Sortable': 'endsAt' },
        { 'title': this.allCaptions.tbl_hdr_comments, 'jsonkey': 'comments', 'searchable': false, 'sortable': true, 'Sortable': 'comments', 'hover': 'comments' },
      ]
     this.tableoptions = [
      {
        TableHdrData: this.tableHeader,
        TablebodyData: this.tableDataTemp,
        pagination: false,
        sortable: false,
        Editable: true,
        CustomColumn: true,
        EnableActions: false,
        SelectRows: false,
        Searchable: false,
        EditMoreOption: false,
        Sortable: false,
        TableId: '',
        disableDelete: false,
        SelectRow: true,
        SelectOnlyRow: true,
        dropdownOptions: '',
        waitlistIcon: false,
        lostDeniedIcon: false,
        TableDraggable: false,
        doneCancel: false,
        sticky: false
      }
    ];
  }

 EditRowRecords(data){}

  deleteConfirm(data){}

  rowSelected(data){}

  historicRecords(e) {
    this.historicBoolControl.setValue(e[0]);
    this.GetLocationMaintenaceRecords();
  }

  async GetAllServiceDetails(){
    await this.vsBusiness.getServiceData<SpaServiceLocation[]>(HttpMethod.Get, "GetAllSpaService", this.successCallback.bind(this), this.utils.errorCallback.bind(this), true)
  }

  setLinkValues(services){
    const loc = services;
    if (loc && loc.length > 0) {
      for (let locItem of loc) {
        for (let ServiceLocationsItem of this.serviceMapped) {
          if (ServiceLocationsItem.id == locItem.serviceId) {
            ServiceLocationsItem.selected = true;
          }
        }
      }
    }
  }

  private MapToServiceLocation(result){
    let serviceLocations : SpaServiceLocation[] = [];
    if(result != null && result.length > 0){
      const asctiveServices = result.filter(x => x.isActive);
      asctiveServices.forEach(val => {
        serviceLocations.push({
          id: val.id,
          code: val.code,
          name: val.description,
          description: val.description,
          listOrder: val.listOrder,
          selected: false
        });
      });
    }
    return serviceLocations;
  }

  private BuildLinkedLocation(): SpaServiceLinkLocation[] {
    const locations: any[] = this.serviceMapped.filter(value => value.selected);
    const result: SpaServiceLinkLocation[] = [];
    const prevLocations: SpaServiceLinkLocation[] = this.dataInput && this.dataInput.serviceLocations ? this.dataInput.serviceLocations : [];
    if (locations.length > 0 && locations[0].id !== 0) {
      for (let item of locations) {
        const selected: SpaServiceLinkLocation = prevLocations.find(x => x.serviceId == item.id);
        result.push({
          id: selected ? selected.id : 0,
          serviceId: item.id,
          locationId: this.dataInput ? this.dataInput.location.id : 0
        });
      }
    }
    return result;
  }

  private GetLocationMaintenaceRecords(){
    const data = this.dataInput && this.dataInput.locationMaintenances ? this.dataInput.locationMaintenances : [];
    const locationMaintenace = data.filter(x => {
      if (this.historicBoolControl.value) {
        return true;
      }
      return this.utils.GetDateWithoutTime(x.endTime).getTime() >= this.utils.GetDateWithoutTime(this.propertyInfo.CurrentDate).getTime();
    }).map(x => {
      return {
        id: x.id, locationCode: x.locationCode, locationDescription: x.locationDescription,
        locationId: x.locationId, comments: x.comments,
        beginAt: this.localization.LocalizeShortDateTime(x.startTime),
        endAt: this.localization.LocalizeShortDateTime(x.endTime),
        startTime: x.startTime,
        endTime: x.endTime
      };
    });
    this.tableDataTemp = locationMaintenace;
    this.BindTable();
  }
}
