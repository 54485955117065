import { Injectable } from "@angular/core";
import { PropertyApiRoutes } from "../common-route";
import { MachineName, UserMachineInfo } from "../shared/shared.modal";
import * as _ from "lodash";
import { TenantManagementCommunication } from "../communication/services/tenantmanagement-communication-service";

@Injectable({
    providedIn: 'root'
})
export class MachineNameDataService {
    constructor(private _tenantManagement: TenantManagementCommunication) {
    }

    public async GetMachineNames(propertyId: number): Promise<MachineName[]> {
        let machineNames: MachineName[] = await this._tenantManagement.getPromise({
            route: PropertyApiRoutes.GetMachineNamesByPropertyId,
            uriParams: { propertyId: propertyId }
        });
        machineNames = _.orderBy(machineNames,'listOrder','asc');
        return machineNames;
    }
    public async GetMachineNamesConfigurationInfo(userId:number,productId:number,propertyIds:number[]): Promise<UserMachineInfo> {
        let machineNames: UserMachineInfo = await this._tenantManagement.putPromise({
            route: PropertyApiRoutes.GetMachineNamesConfigurationInfo,
            body : propertyIds,
            uriParams: { productId: productId ,userId:userId}
        });
        return machineNames;
    }
}