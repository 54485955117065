import { Component, OnInit, Directive, ElementRef, Input, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { OnEventArgs } from '../../form-type.entity';
import { CustomErrorMesssage } from '../../form-validation';


@Component({
  selector: 'lib-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimePickerComponent implements OnInit, AfterViewInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  formFieldName: any;
  fieldType: any;
  formFieldlabel: any;
  errorLength: number;
  errorMessage: string;
  isInputDisabled: any;
  formFieldToolTip: string;
  isHoursOnly: boolean;
  hourFormat: number;
  isRequired: boolean;
  get isValid(): any { return this.form.controls[this.formFieldName].valid; }
  @ViewChild('toggleTimepicker') toggleTimepicker: any;
  // get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }
  constructor(private agyDynamicFormServices: AgyDynamicFormServices) { }

  pickerTimeChanged(index?: any, argus?: OnEventArgs): void {
    this.agyDynamicFormServices.formEventCallback(this.field, index, this.form);
  }

  openPicker() {
    try {
      if (this.toggleTimepicker) {
        this.toggleTimepicker.open();
      }
    } catch (error) {
      console.log('Error occurred in openPicker', error);
    }
  }


  ngAfterViewInit(): void {
    this.isRequired = this.form.controls[this.formFieldName]?.validator ?
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
    this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
      this.isRequired = this.form.controls[this.formFieldName]?.validator ?
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
      this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field);
    });
    if (this.form.controls[this.formFieldName] && this.form.controls[this.formFieldName].errors) {
      const errorsArray = Object.keys(this.form.controls[this.formFieldName].errors);
      this.errorLength = errorsArray.length;
    }
    this.isHoursOnly = (this.field?.timePickerHoursOnly !== undefined) ? this.field.timePickerHoursOnly : false;
    this.hourFormat = (this.field?.timePickerhoursFormat !== undefined) ? this.field.timePickerhoursFormat : null;
  }

  ngOnInit(): void {
    this.fieldType = Object.keys(this.field.fieldType).toString();
    const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
    this.formFieldName = formField.fieldName;
    this.formFieldlabel = formField.fieldLabel;
    this.isHoursOnly = (this.field?.timePickerHoursOnly !== undefined) ? this.field.timePickerHoursOnly : false;
    this.hourFormat = (this.field?.timePickerhoursFormat !== undefined) ? this.field.timePickerhoursFormat : null;
    this.isInputDisabled = this.field.fieldType[this.fieldType].disbaledProperty ?
      this.field.fieldType[this.fieldType].disbaledProperty : false;
    this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
    ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
   
  }

}


