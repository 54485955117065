import { RequestDataModel, TemplateDataModel } from '../models/requestdata.interface';
import * as myGlobals from '../../constant/GlobalConstants';

export class RequestEngine implements RequestDataModel {
    FetchAll: boolean;
    GetTotalRecCount: boolean;
    IsActiveOnly: boolean;
    LocationIds: Array<number>;
    LookupField: any;
    SearchText: string;
    Skip: number;
    Take: number;
    orderByColumn: string;
    orderByDirection: number;
    siteId: number;
    totalCount: number;
    userId: number;
    constructor( FetchAll: boolean, LocationIds:  Array<number> ) {
      const UserId = sessionStorage.getItem('UserId') ? 
      parseInt(JSON.parse(sessionStorage.getItem('UserId')), 10) : 1;
      const LoggedInSiteId = sessionStorage.getItem('LoggedInSiteId') ? 
      parseInt(JSON.parse(sessionStorage.getItem('LoggedInSiteId')), 10) : 1;
      const LoadMoreCount = sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid :
      myGlobals.InfiniteScrollItemCount;
      
      this.FetchAll = FetchAll;
      this.LocationIds = LocationIds;
      this.userId = UserId; 
      this.siteId = LoggedInSiteId;
      this.GetTotalRecCount = true;
      this.IsActiveOnly = true;
      this.LookupField = '';
      this.SearchText = '';
      this.Skip = 0;
      this.Take = LoadMoreCount;
      this.totalCount = 0;
    }
    public getRequestPayload(): RequestDataModel {
        try {
            const requestDataModel = {} as RequestDataModel;
            requestDataModel.FetchAll = this.FetchAll;
            requestDataModel.LocationIds = this.LocationIds;
            requestDataModel.userId = this.userId;
            requestDataModel.siteId = this.siteId;
            requestDataModel.GetTotalRecCount = this.GetTotalRecCount;
            requestDataModel.IsActiveOnly = this.IsActiveOnly;
            requestDataModel.LookupField = this.LookupField;
            requestDataModel.SearchText = this.SearchText;
            requestDataModel.totalCount = this.totalCount;
            return requestDataModel;
        } catch ( error ) {
            console.error(error);
        }
    }
    public getTemplateRequestPayload(): TemplateDataModel {
        try {
            const templatePostModel = {} as TemplateDataModel;
            templatePostModel.FetchAll = this.FetchAll;
            templatePostModel.siteId = this.siteId;
            templatePostModel.GetTotalRecCount = this.GetTotalRecCount;
            templatePostModel.LookupField = this.LookupField;
            templatePostModel.SearchText = this.SearchText;
            return templatePostModel;
        } catch (error) {
            console.error(error);
        }
    }
}