import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';


@Component({
  selector: 'app-cart-timeout-alter',
  templateUrl: './cart-timeout-alter.component.html',
  styleUrls: ['./cart-timeout-alter.component.scss']
})
export class CartTimeoutAlterComponent implements OnInit {
  captions;
  data: any;

  constructor(private localization:Localization,private dialogRef: MatDialogRef<CartTimeoutAlterComponent>, @Inject(MAT_DIALOG_DATA) data: any, public dialog: MatDialog) { 
    this.captions=this.localization.captions;
    this.data=data?.obj;
  }

  ngOnInit(): void {
  }

  okay()
  {
  this.dialog.closeAll();
  }

}
