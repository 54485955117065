import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss']
})
export class ToggleGroupComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  get isValid(): any { return this.form.controls[this.field.name].valid; }
  get isDirty(): any { return this.form.controls[this.field.name].dirty; }

  constructor() {
   console.log('load constractor');
  }

  ngOnInit(): void {
    console.log('load constractor');
  }

}
