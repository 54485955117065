import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { GridType } from '../../shared/globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-item-assigned',
  templateUrl: './item-assigned.component.html',
  styleUrls: ['./item-assigned.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemAssignedComponent implements OnInit {
  tableoptions: any;
  captions: any;
  tabledata: any[]
  constructor(private _localization: RetailLocalization, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this.captions = this._localization.captions.vendors;
    this.tabledata = this.data.popupConfig.data ? this.data.popupConfig.data : [];
    this.tableoptions = [{
      TableHdrData: this.GetHeader(),
      TablebodyData: this.tabledata,
      pagination: false,
      sortable: false,
      CustomColumn: false,
      EnableActions: false,
      SelectRows: false,
      SelectedSettingId: GridType.retail,
      Searchable: false,
      EditMoreOption: false,
      TableId: GridType.vendor,
      disableDelete: false,
      showToggle: false,
      IsViewOnly: true,
      disableEditButton: true,
      TableDraggable: false
    }];
  }

  private GetHeader() {
    return [
      { "title": this.captions.lbl_description, "jsonkey": "description", "searchable": false, "sortable": false },
      { "title": this.captions.lbl_itemNumber, "jsonkey": "itemNumber", "searchable": false, "sortable": false },
      { "title": this.captions.lbl_onHand, "jsonkey": "onHand", "searchable": false, "sortable": false },
      { "title": this.captions.lbl_mtd, "jsonkey": "mtd", "searchable": false, "sortable": false },
      { "title": this.captions.lbl_ytd, "jsonkey": "ytd", "searchable": false, "sortable": false },
      { "title": this.captions.lbl_par, "jsonkey": "par", "searchable": false, "sortable": false },
      { "title": this.captions.lbl_cost, "jsonkey": "cost", "searchable": false, "sortable": false },
      { "title": this.captions.lbl_price, "jsonkey": "price", "searchable": false, "sortable": false },
    ];
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
