import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety } from './create-transaction-config.interface';

export const FetchGridProperties: FetchGridDataPropety =
    (TransactionCollection: TransactionConfiguration, responseData: Array<any>,
        ViewHeaderEntity: any,
        ViewDetailsEntity: any, TilesHeaderTrascationEntity?: any,
        httpService?: HttpService, translateService?: TranslateService): any => {
        try {
            if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.hasOwnProperty('GridFetchFields') &&
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFetchFields.length > 0) {
                const FetchFields = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFetchFields;
                const data = QueryTransaction.QueryGridData(ViewHeaderEntity, ViewDetailsEntity, TransactionCollection,
                    httpService, translateService, TilesHeaderTrascationEntity, responseData);
                    data?.forEach((rowData: any) => {
                    FetchFields.forEach(fields => {
                        if (rowData.hasOwnProperty(fields.FieldFrom) &&
                        rowData[fields.FieldFrom] !== undefined && rowData[fields.FieldFrom] !== null) {
                            rowData[fields.FieldTo] = rowData[fields.FieldFrom];
                        }
                        if (fields.AssignFrom) {
                            if (fields.AssignFrom.FirstKey && fields.AssignFrom.SecondKey && rowData[fields.AssignFrom.FirstKey]) {
                                rowData[fields.AssignFrom.AssignTo] = rowData[fields.AssignFrom.FirstKey][fields.AssignFrom.SecondKey];
                            }
                        }
                    });
                });
                return data;
            } else {
                const data = QueryTransaction.QueryGridData(ViewHeaderEntity, ViewDetailsEntity, TransactionCollection,
                    httpService, translateService, TilesHeaderTrascationEntity, responseData);
                return data;
            }
        } catch (Error) {
            console.error('Error occurred FetchGridProperties', Error);
        }
    };