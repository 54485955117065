import { Component, ViewEncapsulation, Input } from '@angular/core';
import { NoDataModel } from '../../shared/business/shared.modals';
import * as myGlobal from '../../shared/globalsContant';
@Component({
  selector: 'ag-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoDataFoundComponent  {

  @Input() noDataOptions:NoDataModel;
  recordsType = myGlobal.noRecordsType; 
  
}


