import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridColumnReorder, GridConfig, GridFilter, GridResizeRows, GridSearchConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { AgilysysGridZoomPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-grid-zoom-popup/agilysys-grid-zoom-popup.component';
import { GridZoomPopUp } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { MasterInventoryManagement, SubFormConfigs } from '../master.interface';
import { MasterZoomInfo } from './master-detail-config.interface';

export const MasterZoomDetail: MasterZoomInfo =
    (ViewModelEntity, MasterDataCollection: MasterInventoryManagement, httpService: HttpService,
        translateService: TranslateService, RowId: number,  SubFormAction: SubFormConfigs, dialog: MatDialog): any => {
        try {
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            const endPointUrl = `/inventory/api/${SubFormAction.ZoomPackage.OdataKey}/${RowId}`;
            httpService.GethttpMethod(endPointUrl).subscribe(res => {
                const convertValue = (typeof res['value'] === 'string') ? [JSON.parse(res['value'])] : res['value'];
                res['value'] = convertValue;
                console.log('Zoom Package Response', res);
                const response = res['value'][0];
                SubFormAction.ZoomPackage.PopUpHeaderName = SubFormAction.ZoomPackage.Name + ' Zoom';
                SubFormAction.ZoomPackage.ZoomName = response.Name;
                SubFormAction.ZoomPackage.DetailsConfig.forEach((name, index) => {
                    name.NestedData.forEach(data => {
                      const showDisplayName = (data.hasOwnProperty('displayNameMandatory') && data.displayNameMandatory === true) ? 
                      data.DisplayName : data.OdataKey;
                        data.DisplayName = translateService.instant(SubFormAction.ZoomPackage.OdataKey + '.' + showDisplayName);
                        const propType = typeof response[data.OdataKey];
                        let value;
                        if (propType === 'object' && response[data.OdataKey]) {
                            value = response[data.OdataKey]['Name'];
                        } else if (data.OdataKey === 'LinkedItemCatId') {
                          value = (response['LinkedItemCatId'] === 1) ? 'Inventory' : (response['LinkedItemCatId'] === 2) ? 'Recipe' : '-';
                        } else {
                            value = response[data.OdataKey] === undefined || response[data.OdataKey] === null ? '-' :
                                response[data.OdataKey];
                        }
                        data.Value = value;
                    });
                });
                console.log('SubFormAction', SubFormAction);
                let gridData = null;
                if (SubFormAction.ZoomPackage.GridConfig && response.hasOwnProperty(SubFormAction.ZoomPackage.GridPropertyKey) &&
                   response[SubFormAction.ZoomPackage.GridPropertyKey].length > 0 ) {
                    gridData = {} as GridConfig;
                    gridData.GridData = response[SubFormAction.ZoomPackage.GridPropertyKey].filter(x => x.IsActive === true);
                    if (SubFormAction.ZoomPackage.OdataKey === 'Products') {
                        gridData.GridData.forEach(element => {
                            element.UnitCode = element.ProductUnits ? element.ProductUnits.Name + ` (${element.ProductUnits.Code})` : '';
                            element.UnitCost = element.Price / element.Qty;
                            element.Name = element.ItemName;
                        });
                    } else if (SubFormAction.ZoomPackage.OdataKey === 'Recipes') {
                        gridData.GridData.forEach(element => {
                            const Units = element.Units.filter(x => x.UnitTypeId === element.UnitTypeId)[0];
                            element.UnitName = Units ? Units.UnitName + ` (${Units.UnitCode})` : '';
                        });
                    }
                    gridData.GridHeaderDefination = SubFormAction.ZoomPackage.GridConfig.GridHeaderDefination;
                    gridData.GridHeaderDefination.forEach(element => {
                        element.headerName = translateService.instant(SubFormAction.ZoomPackage.GridPropertyKey + '.' + element.field);
                        element.resizable = true;
                        element.tooltipField = element.field;
                    });
                    gridData.GridActions = {};
                    gridData.GridActions.GridTitle = SubFormAction.ZoomPackage.GridConfig.GridName;
                    gridData.GridActions.SearchConfig = {} as GridSearchConfig;
                    gridData.GridActions.SearchConfig.EnabledSearch = true;
                    gridData.GridActions.SearchConfig.SearchPlaceholder =
                        SubFormAction.ZoomPackage.GridConfig.GridSearch[0].SearchPlaceholderName;
                    gridData.GridActions.FilterConfig = {} as GridFilter;
                    gridData.GridActions.EnableFilter = false;
                    gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
                    gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
                    gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
                    gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
                }
                const PopUpData: GridZoomPopUp = {
                    GridConfig: gridData,
                    ZoomDetailConfig: SubFormAction.ZoomPackage
                };
                dialog.open(AgilysysGridZoomPopupComponent, {
                    width: '900px',
                    height: '700px',
                    data: PopUpData,
                    autoFocus: false,
                    disableClose: true
                });
            });
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };
