import { MasterInventoryManagement } from '../master.interface';
import { GridModelHeaderDefination } from './grid-config.interface';
import { ReplaceTextWithIcon } from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const GridColumnResize: GridModelHeaderDefination = ( 
ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,  EntityProperty: any): any => {
    let returnReseize = true;
    try {
        const ReplacableObject = ReplaceTextWithIcon.filter(x => x.OdataKey.toLowerCase() === EntityProperty['$']['Name'].toLowerCase())[0];
        if (ReplacableObject) {
            returnReseize = false;
        }
        return returnReseize;
    } catch (error) {
        console.log('Error occurred in GridColumnResize');
    }

};
