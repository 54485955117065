import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    AddNewTypeConfig, GridConfiguration, ItemQueryListConfiguaration,
    LookupConfiguration, SearchShowMoreConfig, TransactionConfiguration
} from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { TransQueryLookupConfig } from './query-list-config.interface';
import _ from 'lodash';
import { QueryTransaction } from 'src/app/eatecui/source/transaction/shared/interface/query-details';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';

export const TransQueryListLookupTabClick: TransQueryLookupConfig = async (
    addNewTypeConfig: AddNewTypeConfig,
    transactionConfigurationData: TransactionConfiguration, httpService: HttpService, translateService: TranslateService,
    headerEntity: any, detailEntity: any, transactionService: TransactionService, selectedTransaction: string,
    OdataGlobalEntityModel: OdataGlobalEntity, masterManagementService: MasterManagementService,
    selectedId?: Array<any>, selectedList?: any, showMoreConfig?: SearchShowMoreConfig): Promise<any> => {
    try {
        const transactionJsonData = transactionConfigurationData;
        // const getHeaderOdataKey = transactionService.getOdataKey(transactionJsonData, 'ViewHeaderOdataKey');
        const getHeaderOdataKey = 'Ingredients';
        const queryTrasacationHeaderEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity,
            getHeaderOdataKey);
        const getDetailOdataKey = transactionService.getOdataKey(transactionJsonData, 'ViewDetailOdataKey');
        const queryTransactionDetailEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity,
            getDetailOdataKey);

        const jsondata = transactionService.transactionConfigurationData;
        const searchByKey = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds;
        const searchListArray = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList;
        let endPointUrl = '';
        endPointUrl = `/inventory/api/${addNewTypeConfig.EndPointUrl}`;
        let LocId = 0;
        const lookUpData = {} as LookupConfiguration;
        const ModuleName = transactionJsonData.Name;
        switch (ModuleName) {
            case 'Transfer':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'spoilage':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Requisition':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'Purchase Request':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['RequestLocId'];
                break;
            case 'Physical Inventory':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Receiving':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Purchase Order':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Item Pars':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
                break;
            case 'sales':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['SalesLocationId'];
                break;
            case 'PlanHead':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'cycleplanning':
                const locId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                LocId = locId > 0 ? locId : 0;
                break;
            case 'Butchery':
            case 'Production':
            case 'productionplan':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'quickorder':
                LocId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
                break;
            case 'Batch Item Maintenance':
                lookUpData.DivisionId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['Division'];
                lookUpData.IsBatchItem = true;
                break;
        }
        lookUpData.Id = 0;
        lookUpData.TransType = transactionJsonData.TransactionType;
        lookUpData.FromLocationId = LocId;
        lookUpData.ToLocationId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'];
        lookUpData.flgSearch = true;
        lookUpData.FetchAll = false;
        lookUpData.Skip = showMoreConfig ? showMoreConfig.Skip : 0;
        lookUpData.Take = showMoreConfig ? showMoreConfig.Take : 100;
        lookUpData.searchText = showMoreConfig?.SearchKey ? showMoreConfig.SearchKey : '';
        lookUpData.IsQuery = true;
        if (addNewTypeConfig.Name === 'Class') {
            lookUpData.ClassIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Type') {
            lookUpData.TypeIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Packages') {
            lookUpData.PackageIds = searchByKey;
        }
        const x = await httpService.PosthttpMethod(endPointUrl, lookUpData).toPromise();
        const resultSet = x?.PackageResults ? 
        x['PackageResults'] : x?.Data?.ent?.ResultData ? x['Data']['ent']['ResultData']
        : x?.Data?.ent?.PackageResults ?
        x['Data']['ent']['PackageResults'] : [];
        // if (resultSet.length > 0) {
        const responseData = [];
        const itemHeaderList = [];
        let resultData = [];
        let values;
        if (addNewTypeConfig.Name === 'Inventory') {
            resultSet.forEach((element: any) => {
                const dataObject = _.mapKeys(element, function (v, k) {
                    return k.charAt(0).toUpperCase() + k.slice(1);
                });
                responseData.push(dataObject);
            });
            values = _(responseData)
                .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                .map((ItemLists: any, Id: any) => ({
                    Id: parseInt(Id, 10),
                    ItemLists,
                    Name: ItemLists[0][addNewTypeConfig.Url]['Name']
                }))
                .value();
        } else {
            resultData = x?.PackageResults ?
            x['PackageResults'] :
            x?.Data?.ent?.PackageResults ? 
            x['Data']['ent']['PackageResults'] : [];
            const loopvalues = Object.keys(resultData);
            values = resultData[loopvalues[0]];
            // values = [];
            // loopvalues.forEach(pack => {
            //     const data = {} as QueryDatas;
            //     data.Id = pack;
            //     data.Name = pack;
            //     data.ItemList = resultData[pack];
            //     values.push(data);
            // });
        }
        const TabIndex = selectedId.findIndex(y => y === searchByKey[0]);
        const ItemList = values;
        const TotalCount = x?.TotalCount ? x['TotalCount'] :
        x?.Data?.ent?.TotalCount ?? 0;
            const GridDataRows = QueryTransaction.QueryIngredientsGridData(queryTrasacationHeaderEntity,
                queryTransactionDetailEntity, transactionJsonData, httpService, translateService, '', ItemList) ?? [];
            GridDataRows?.forEach(element => {
                element['GridId'] = `${TabIndex}_` + (element.Id ? element.Id : element.IngredientId
                    ? element.IngredientId : element.ProductId);
            });
        // if (values.length < searchByKey.length) {
        //     searchListArray.forEach(key => {
        //         if (values.findIndex(z => z.Id === key.Id) === -1) {
        //             values.push({
        //                 Id: key.Id,
        //                 ItemList: [],
        //                 Name: key.Name
        //             });
        //         }
        //     });
        // }
        // console.log('Grouped Data', values);
        // values.forEach((data: any, Index: number) => {
        //     const itemQueryListConfiguaration = {} as ItemQueryListConfiguaration;
        //     itemQueryListConfiguaration.DisplayName = data.Name;
        //     itemQueryListConfiguaration.Enable = true;
        //     itemQueryListConfiguaration.IconName = 'Na';
        //     itemQueryListConfiguaration.Id = data['id'];
        //     itemQueryListConfiguaration.ItemSelected = [];
        //     itemQueryListConfiguaration.Name = data.Name;
        //     itemQueryListConfiguaration.RoutingLinkUrl = addNewTypeConfig.Url;
        //     itemQueryListConfiguaration.ShowInEllipsis = false;
        //     itemQueryListConfiguaration.Type = 'grid';
        //     itemQueryListConfiguaration.listAuthorized = true;
        //     const ItemList = data.ItemList;
        //     const GridDataRows = QueryTransaction.QueryIngredientsGridData(queryTrasacationHeaderEntity,
        //         queryTransactionDetailEntity, transactionJsonData, httpService, translateService, '', ItemList);
        //     GridDataRows.forEach(element => {
        //         element['GridId'] = Index + '_' + (element.Id ? element.Id : element.IngredientId);
        //     });
        //     itemQueryListConfiguaration.listGridData = {} as GridConfiguration;
        //     itemQueryListConfiguaration.listGridData = {
        //         GridName: 'Item List',
        //         GridOdataKey: 'Requisition',
        //         ShowActiveToggle: false,
        //         ShowAddNew: false,
        //         GridTitleType: {
        //             Type: 'text',
        //             TypeConfig: [
        //                 {
        //                     Id: 1,
        //                     Name: 'Item List',
        //                     DisplayName: 'Item List'
        //                 }
        //             ]
        //         },
        //         GridFilter: [],
        //         GridSort: [
        //             {
        //                 DefaultSorting: true,
        //                 SortingOdataKey: 'Name',
        //                 SortingType: 'asc',
        //                 SortingDisplayName: ''
        //             }
        //         ],
        //         GridSearch: [],
        //         GridColumnHide: [],
        //         GridData: GridDataRows,
        //         GridHeaderDefination: QueryTransaction.QueryGridHeaders(queryTransactionDetailEntity,
        //             queryTransactionDetailEntity, transactionJsonData, httpService, translateService, data
        //         ),
        //         GridActions: {
        //             GridTitle: 'Inventory List',
        //             TotalItems: ItemList.length,
        //             InfiniteScrollConfig: {
        //                 EnableInfiniteScroll: false,
        //                 TotalRecords: ItemList.length
        //             },
        //             ResizeRowsConfig: {
        //                 EnableResizeRows: false
        //             },
        //             ShuffleColumnConfig: {
        //                 EnableColumnShuffle: false
        //             },
        //             FilterConfig: {
        //                 EnableFilter: false
        //             }
        //         },
        //     };
        //     if (addNewTypeConfig.Name === 'Packages') {
        //         itemQueryListConfiguaration.listGridData.GridHeaderDefination.forEach(header => {
        //             header.sortDirection = '';
        //         });
        //         itemQueryListConfiguaration.listGridData.GridSort = [];
        //     }
        //     itemHeaderList.push(itemQueryListConfiguaration);
        // });
        // transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig = [...itemHeaderList];
        return [GridDataRows, TotalCount];
    } catch (error) {
        console.error(error);
    }
};

export interface QueryDatas {
    Id: string;
    Name: string;
    ItemList: Array<any>;
}