import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { CreateLockersComponent } from '../create-lockers/create-lockers.component';
import { TableSearchHeader } from 'src/app/common/Models/ag-models';
import { LockersBusiness } from './lockers.business';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { API, errorCode } from "src/app/settings/Utilities/lockers/lockers.model";
import { RetailRoutes } from 'src/app/retail/retail-route';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { ViewSettingClientBusiness } from 'src/app/shared/common-functionalities/business/view-settings.business';
import {ImageData as PropImage } from '../../../shared/business/shared.modals';
import * as HostEnum from 'src/app/retail/shared/globalsContant';
import { BaseResponse } from 'src/app/common/shared/retail.modals';
import { ImageProcessorService } from 'src/app/shared/service/image-processor-service';
import * as myGlobals from 'src/app/shared/globalsContant';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
@Component({
  selector: 'app-lockers',
  templateUrl: './lockers.component.html',
  styleUrls: ['./lockers.component.scss'],
  providers: [LockersBusiness, ViewSettingClientBusiness]
})
export class LockersComponent implements OnInit {

  createToggler: boolean;
  captions: any;
  vendorCategoryData: any;
  searchHeaderOption: TableSearchHeader;
  vendorListInput: any;
  createModalData: { title: any; };
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  headerOptions: any;
  tableContent: any;
  tableOptions: any;
  searchText: string;
  IsEditModeEnabledFlag: boolean;
  LockerInforResult: any[];
  lockerAllInfo: any;
  searchFilter: any[];
  lockerItemMiss: boolean;
  guid: string;
  retailItemType = 3;
  showInactiveTog = false;
  Imageurl: string;
  editImageId: any;
  sequenceNo: any;
  isViewOnly: boolean;
  checkLockerUsed : any;

  constructor( private localization: Localization, private dialog: MatDialog, private business: LockersBusiness, private http: HttpServiceCall, private imgService: ImageProcessorService, private BP: BreakPointAccess, private utils : CommonUtilities) {
    this.captions = this.localization.captions;
  }

  async ngOnInit() {
    await this.intialize();
  }

  async getAllLockerInformation(includeInactive: any): Promise<any[]> {
    try {
      this.LockerInforResult = [];
      let routeForIncludeInactive = RetailRoutes.GetLockersInformation;
      const response = await this.InvokeServiceCallAsync(routeForIncludeInactive, GlobalConst.Host.retailManagement, HttpMethod.Get, { includeInactive: includeInactive });
      this.LockerInforResult = response.result;
      return response.result;
    } catch (error) {
      console.error("error while fetching Lock information" + error);
    }
  }
  async getLockerInformationById(id: any): Promise<any> {
    try {
      let getLockerInfo: any;
      const response = await this.InvokeServiceCallAsync(RetailRoutes.GetLockerInformationById, GlobalConst.Host.retailManagement, HttpMethod.Get, { id: id });
      getLockerInfo = response.result;
      return response.result;
    } catch (error) {
      console.error("error while fetching Lock information by Id" + error);
    }
  }
  async createLockerInformation(body: any): Promise<any> {
    try {
      this.LockerInforResult = [];
      const response = await this.InvokeServiceCallAsync(RetailRoutes.CreateLockersInformation, GlobalConst.Host.retailManagement, HttpMethod.Post, '', '', body);
      this.LockerInforResult = response.result;
      return response.result;
    } catch (error) {
      console.error("error while Creating Lock information" + error);
    }
  }
  async updateLockerInformation(body: any): Promise<API.LockerInformationAPI[]> {
    try {
      this.LockerInforResult = [];
      const response = await this.InvokeServiceCallAsync(RetailRoutes.UpdateLockersInformation, GlobalConst.Host.retailManagement, HttpMethod.Put, '', '', body);
      this.LockerInforResult = response.result;
      return response.result;
    } catch (error) {
      console.error("error while updating Lock information" + error);
    }
  }
  async deleteLockerInformation(id: number): Promise<API.LockerInformationAPI> {
    try {
      this.LockerInforResult = [];
      const response = await this.InvokeServiceCallAsync(RetailRoutes.DeleteLockersInformation, GlobalConst.Host.retailManagement, HttpMethod.Delete, { id: id });
      this.LockerInforResult = response.result;
      return response.result;
    } catch (error) {
      console.log("error while deleting Lock information" + error);
    }
  }

  async checkLockerInUse(id: number, errorCode): Promise<boolean> {
    try{
      const response = await this.InvokeServiceCallAsync(RetailRoutes.GetCountOfLockerAssigned, GlobalConst.Host.retailPOS, HttpMethod.Get, { lockerId: id });
      console.log(response)
      if(response.result>0){
        this.utils.showError(this.localization.getError(errorCode));
      }
      return response.result;
    }catch(err){
      console.log('error from locker check ',err);
    }
  }

  async getNextListOrder(): Promise<number> {
    try {
      this.LockerInforResult = [];
      const response = await this.InvokeServiceCallAsync(RetailRoutes.GetNextListOrder_LockerInformation, GlobalConst.Host.retailManagement, HttpMethod.Get);
      this.LockerInforResult = response.result;
      return response.result;
    } catch (error) {
      console.log("error while fetching next list order" + error);
    }
  }

  async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParam?: any, queryParams?: any, body?: any): Promise<any> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParam,
        queryString: queryParams
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async create(eve) {
    this.dialog.open(CreateLockersComponent, {
      width: '100%',
      height: '100%',
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {
      if (res.from === DialogCloseEnum.Action) {
        let businessToApi = this.business.mapToLockerInformationAPI(res.form.value);
        let Response =await this.createLockerInformation(businessToApi);
        let resFromcreateLockerInformation= Response.filter(locker => {
          return businessToApi.code == locker.code;
        }); 
        if(res.base64result !=null && res.base64Thumbnail){
          this.saveImage(resFromcreateLockerInformation[0].id,res.base64result, res.base64Thumbnail);
        }
      }
      this.lockerAllInfo = await this.getAllLockerInformation(true);
      const filteredLockerInfo = this.lockerAllInfo.filter(locker => {
        return this.showInactiveTog || locker.isActive;
      });
      this.tableContent = this.business.getTableContent(filteredLockerInfo,this.isViewOnly);
    });
  }

  saveImage(id, base64textString:any[], thumbnailImg:any[]) {
    const base64result = base64textString;
    const base64Thumbnail = thumbnailImg;
    const imageDataObj: PropImage = {
      referenceId: id,
      referenceType: HostEnum.ImgRefType.locker,
      data: base64result[1],
      id: 0,
      thumbnailData: base64Thumbnail ? base64Thumbnail[1] : '',
      contentType: base64result[0],
      sequenceNo: 0
    };
    this.imgService.saveImage(imageDataObj, this.successCallback.bind(this),  this.errorCallback.bind(this), []);
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    switch (callDesc) {
      case "getImagesByReference": {
        const retailItemImageDetails = result.result;
        if (retailItemImageDetails[0]) {
          this.Imageurl = `${retailItemImageDetails[0].contentType},${retailItemImageDetails[0].data}`;
          this.editImageId = retailItemImageDetails[0].id;
          this.sequenceNo = retailItemImageDetails[0].sequenceNo;
        } else {
          this.Imageurl = '';
          this.editImageId = '';
        }
      }
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  async intialize() {
    let isAuthorised = this.BP.CheckForAccess([myGlobals.RetailBreakPoint.LockerType]);
    this.isViewOnly = this.BP.IsViewOnly(myGlobals.RetailBreakPoint.LockerType);
    this.lockerAllInfo = await this.getAllLockerInformation(true);
    const filteredLockerInfo = this.lockerAllInfo.filter(locker => {
      return this.showInactiveTog || locker.isActive;
    });
    this.tableContent = this.business.getTableContent(filteredLockerInfo,this.isViewOnly);
    this.headerOptions = this.business.getHeaderOptions();
    this.tableOptions = this.business.getTableOptions(this.isViewOnly);
    this.searchHeaderOption = {
      createBtnLabel: this.captions.lbl_createLockerType,
      searchPlaceHolder: this.captions.lbl_searchByLockerName,
      advancedSearch: this.captions.lbl_advancedSearch,
      showInactive: true,
      toggleLabel: this.captions.lbl_showInactive,
      createBtnDisabled: this.isViewOnly
    };
  }

  async edit(editData) {
    try {
      let getLockerInfoById = await this.getLockerInformationById(editData.id);
      getLockerInfoById.listOrder = editData.listOrder;
      const dialogRef = this.dialog.open(CreateLockersComponent, {
        width: '100%',
        height: '100%',
        data: { editData: getLockerInfoById }
      });
      dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {
        this.IsEditModeEnabledFlag = false;
        if (res.from === DialogCloseEnum.Action) {
          let businessToApi = this.business.mapToLockerInformationAPI(res.form.value);
          this.lockerAllInfo = await this.updateLockerInformation(businessToApi);
        };
        const filteredLockerInfo = this.lockerAllInfo.filter(locker => {
          return this.showInactiveTog || locker.isActive;
        });
        this.tableContent = this.business.getTableContent(filteredLockerInfo,this.isViewOnly);
      });
    } catch (error) {
      console.error('Error in edit method:', error);
    }
  }
  
  async delete(e) {
    try{
      this.checkLockerUsed = await this.checkLockerInUse(e.id, errorCode.LockerInUseErrorCodeDelete);
      if (this.checkLockerUsed == 0) {
        await this.deleteLockerInformation(e.id);
      }
      this.lockerAllInfo = await this.getAllLockerInformation(true);
      const filteredLockerInfo = this.lockerAllInfo.filter(locker => {
        return this.showInactiveTog || locker.isActive;
      });
      this.tableContent = this.business.getTableContent(filteredLockerInfo, this.isViewOnly);
    }catch(err){
      console.error('Error in delete method:', err);
    }
  }

  async isActiveUpdate(e, value) {
    try {
      this.checkLockerUsed = await this.checkLockerInUse(e.id, errorCode.LockerInUseErrorCodeInActive);
      if (this.checkLockerUsed == 0) {
        let getLockerInfoById = await this.getLockerInformationById(e.id);
        let mapToLockerInformationAPI = this.business.mapToLockerInformationAPIForUpdate(getLockerInfoById);
        this.IsEditModeEnabledFlag = false;
        mapToLockerInformationAPI.isActive = value;
        this.lockerAllInfo = await this.updateLockerInformation(mapToLockerInformationAPI);
      }
      const filteredLockerInfo = this.lockerAllInfo.filter(locker => {
        return this.showInactiveTog || locker.isActive;
      });
      this.tableContent = this.business.getTableContent(filteredLockerInfo, this.isViewOnly);
    }catch(err){
      console.error('Error in isActive method:', err);
    }
  }

  async dragListOrder(e, value) {
    let getLockerInfoById = await this.getLockerInformationById(e.id);
    let mapToLockerInformationAPI = this.business.mapToLockerInformationAPIForUpdate(getLockerInfoById);
    this.IsEditModeEnabledFlag = false;
    mapToLockerInformationAPI.listOrder = value;
    this.lockerAllInfo = await this.updateLockerInformation(mapToLockerInformationAPI);
    const filteredLockerInfo = this.lockerAllInfo.filter(locker => {
      return this.showInactiveTog || locker.isActive;
    });
    this.tableContent = this.business.getTableContent(filteredLockerInfo,this.isViewOnly);
  }
  tableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.edit:
        this.IsEditModeEnabledFlag = true;
        this.edit(eve.Obj);
        break;
      case FromTypeEnum.delete:
        this.delete(eve.Obj);
        break;
      case FromTypeEnum.dragdrop:
        this.IsEditModeEnabledFlag = true;
        this.dragListOrder(eve.Obj.dragdata, eve.Obj.dropdata.listOrder);
        break;
      case FromTypeEnum.switch:
        this.IsEditModeEnabledFlag = true;
        this.isActiveUpdate(eve.Obj, eve.value);
        break;
    }

  }

  async searchChange(e) {
    this.searchText = e;
  }
  async showInactive(e) {
    this.showInactiveTog = e.checked;
    const filteredLockerInfo = this.lockerAllInfo.filter(locker => {
      return this.showInactiveTog || locker.isActive;
    });
    this.tableContent = this.business.getTableContent(filteredLockerInfo, this.isViewOnly);
  }

}
