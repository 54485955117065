import { ReportAPIOptions, ReportParams, ReportUIConfig, DropDownData } from "../report.modals";
import { IBaseReport } from "./IBaseReport";
import { RetailLocalization } from "../../../common/localization/retail-localization";
import { UntypedFormGroup } from "@angular/forms";
import { ServiceLocator } from 'src/app/common/service.locator';
import { Localization } from "src/app/common/localization/localization";

/**
 * Base report - Handles start date, end date,
 * drop-down filter, page-break toggle, inactive toggle
 * @implements {IBaseReport}
 */
export abstract class BaseReport implements IBaseReport {

    constructor(public formGrp: UntypedFormGroup) {
        const config = sessionStorage.getItem('propConfig');
        let propertyConfig = config && JSON.parse(config);
		if(propertyConfig!=null && propertyConfig['EnableJasperReports']!=null)
		{
            this.isJasperReportEnabled = sessionStorage.propConfig && JSON.parse(sessionStorage.propConfig).EnableJasperReports?.toString().trim().toLowerCase() === "true" ? true : false;
		}
     }

    /**
     * Report - UI Controls configuration (dates, dropdown, toggle etc.)
     */
    abstract getReportUIConfig(): ReportUIConfig;

    /**
    * Generates API options and parameters
    */
    abstract generateReportAPIOptions(): ReportAPIOptions;

    /**
    * Params to be used in reports directly without DB interaction. eg. property Name
    */
    protected abstract formReportParams(): ReportParams[];

    /**
     * Route Params needed for ReportData
     */
    protected abstract formURIParams(): ReportParams[];

    /**
     * Request body can be used for quering in Microservices. eg. List of Therapist IDs.
     */
    protected abstract formFilters(): any;

    protected captions: any = this.localization.captions.reports;
    protected propertyCaptions:any = this.localization.propertyCaptions.reports;

    protected get localization(): RetailLocalization {
        if (this._localization == undefined) {
            this._localization = ServiceLocator.injector.get(RetailLocalization);
            this._localization.SetLocaleBasedProperties();
            return this._localization;
        }
        return this._localization;
    }

    protected getMultiSelectValuesForJasper(controlName: string): any {
        let values: DropDownData[] = this.getReportControlValues(controlName);
        return values.map(o => o.id+"|"+o.description);
    }

    protected getValuesForJasper(values): any {
        return values.map(o => o.id+"|"+o.description);
    }

    protected getCategoryMultiSelectValuesForJasper(values): any {
        return values.map(o => o.id+"|"+o.name);
    }

    protected getMultiSelectValuesAsString(controlName: string): string[] {
        let values: DropDownData[] = this.getReportControlValues(controlName);
        return values.map(o => o.id.toString());
    }

    protected get commonLocalization(): Localization {
        if (this._commonLocalization == undefined) {   
            this._commonLocalization = ServiceLocator.injector.get(Localization);
        }
        return this._commonLocalization;
    }

    protected get propertyName(): string {
        return this.localization.GetPropertyInfo("PropertyName");
    }

    protected get printedDate(): string {
        return this.localization.ConvertDateToISODateTime(new Date());
    }

    protected get startTime(): string {
        return this.getReportControlValues("startTime");
    }

    protected get startDate(): Date {
        return this.getReportControlValues("startDate");
    }

    protected get endTime(): string {
        return this.getReportControlValues("endTime");
    }

    protected get endDate(): Date {
        return this.getReportControlValues("endDate");
    }

    protected get pageBreakToggle(): string {
        return this.getReportControlValues("pageBreakToggle");
    }

    protected get includeInactiveToggle(): boolean {
        return this.getReportControlValues("includeInactiveusers");
    }

    protected get includeInhouseGratuitySC(): boolean {
        return this.getReportControlValues("includeInhouseGratuitySC");
    }

    protected get selectedFilterOptions(): DropDownData[] {
        return this.getReportControlValues('reportNameChild');
    }

    protected get getSelectedFilterIds(): number[] {
        let filters: DropDownData[] = this.getReportControlValues('reportNameChild');
        return filters.map(f => f.id);
    }

    // For getting default outlet
    protected get getDefaultOutletId(): number[] {
        const data = this.getReportControlValues('outletOptions');
        return data && data.length ? [data[0].id] : [];
    }

    protected get getDefaultOutletIdForJasper(): string[] {
        const data = this.getReportControlValues('outletOptions');
        return data && data.length ? [data[0].id + "|" + data[0].description ] : [];
    }

    protected get getDefaultOutletCaption(): string {
        const data = this.getReportControlValues('outletOptions');
        return this.propertyCaptions.All;
    }

    /**
     *pFilterCaptions - 'ALL' if everything in dropdown is selected or return selected.
     * applies only for base report filter dropdown.
     */
    protected get getFilterCaption(): string {
        if (this.filterDataSource.length == this.selectedFilterOptions.length) {
            return this.propertyCaptions.All;
        }
        return this.selectedFilterOptions.map(filter => filter.description).join();
    }

    /**
     *Filter dropdown initial data source.
     */
    public filterDataSource: DropDownData[] = [];

    private _localization: RetailLocalization;
   

    private _commonLocalization: Localization;
    protected isJasperReportEnabled : boolean = false;


    private getReportControlValues(ctl: string) {
        return this.formGrp.controls[ctl].value;
    }

}

