import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { COMPRESSION_LIMIT, IMAGE_MAXHEIGHT, IMAGE_MAXWIDTH } from '../../constants';
import { Localization } from '../../localization/localization';

@Component({
  selector: 'ag-image-upload',
  templateUrl: './ag-image-upload.component.html',
  styleUrls: ['./ag-image-upload.component.scss']
})

export class AgImageUploadComponent implements OnInit {

  constructor(private imageCompress: NgxImageCompressService, private _Localization: Localization) { }
  file: any;
  uploadedfileName: string;
  imgResultAfterCompress: string;
  sizeOFCompressedImage: number;
  ImageUploaded: boolean = false;
  FileSizeExceeded: boolean = false;
  ImageSizeExceeded: boolean = false;
  captions: any;
  imageData: string = null;
  @ViewChild('inputFile') inputFile: ElementRef;

  @Input('resetImage')
  set resetImage(value) {
    if (value && value[0] && value[1] != '') {
      this.imageData = value[1];
      this.uploadedfileName = value[1];
      this.ImageUploaded = true;
    }
    if (value && value[1] == '') {
      this.imageData = null;
      this.ImageUploaded = false;
      this.FileSizeExceeded = false;
      this.ImageSizeExceeded = false;
      if (this.inputFile)
        this.inputFile.nativeElement.value = null;
    }
  }
  @Input('imageInput')
  set imageInput(value) {
    if (value && value != '') {
      this.imageData = value;
      this.uploadedfileName = value;
      this.ImageUploaded = true;
    }
    if (value && value == '') {
      this.imageData = null;
      this.ImageUploaded = false;
      if (this.inputFile)
        this.inputFile.nativeElement.value = null;
    }
  }

  @Input() checkImagePxSize;
  @Input() maxImagePx: string | number[] = [IMAGE_MAXWIDTH, IMAGE_MAXHEIGHT]; //[width,height]
  @Input() disableControl;
  @Output() compressedImage = new EventEmitter();
  @Output() imageDeleted = new EventEmitter();
  @Output() fileSizeExceeded = new EventEmitter();
  @Output() ImagePxSizeExceeded = new EventEmitter();

  selectFile(event: any) {
    this.file = event.target.files.length != 0 && event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (arg: any) => {
        let img = new Image();
        img.src = arg.target.result;
        img.onload = () => {
          const fileSize = this.imageCompress.byteCount(arg.target.result) / (1024);
          if (fileSize >= COMPRESSION_LIMIT) {
            this.ImageUploaded = false;
            this.FileSizeExceeded = true;
            if (this.inputFile) {
              this.inputFile.nativeElement.value = null;
            }
            this.fileSizeExceeded.emit();
          } else {
            if (this.checkImagePxSize) {
              if (img.naturalWidth > this.maxImagePx[0] || img.naturalHeight > this.maxImagePx[1]) {
                this.ImageUploaded = false;
                this.FileSizeExceeded = false;
                this.ImageSizeExceeded = true;
                this.ImagePxSizeExceeded.emit();
              } else {
                this.compressFile(arg.target.result, this.file['name']);
              }
            } else {
              this.compressFile(arg.target.result, this.file['name']);
            }
          }
        };
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  compressFile(image, fileName) {
    var orientation = -1;
    this.imageCompress.compressFile(image, orientation).then(
      result => {
        this.imgResultAfterCompress = result;
        this.ImageUploaded = true;
        this.FileSizeExceeded = false;
        this.ImageSizeExceeded = false;
        this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024);
        const imageFile = new File([result], fileName, { type: 'image/jpeg' });
        this.uploadedfileName = imageFile['name'];
        this.compressedImage.emit([this.imgResultAfterCompress, imageFile]);
      });
  }

  deleteUpload() {
    this.ImageUploaded = false;
    this.FileSizeExceeded = false;
    this.ImageSizeExceeded = false;
    this.imageData = null;
    if (this.inputFile)
      this.inputFile.nativeElement.value = null;
    this.imageDeleted.emit();
  }

  ngOnInit() {
    this.captions = this._Localization.captions;
  }
}