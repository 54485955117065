import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service'; 
import { BaseResponse } from '../../../../shared/business/shared.modals';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { ReplaySubject } from 'rxjs';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { Localization } from 'src/app/common/localization/localization';
import * as GlobalConst from '../../../../shared/globalsContant';

@Component({
  selector: 'app-client-blocked-information',
  templateUrl: './client-blocked-information.component.html',
  styleUrls: ['./client-blocked-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientBlockedInformationComponent implements OnInit,OnDestroy {
  showClose: boolean = true;
  allCaptions: any;

  @Input("ClientBlockInformation")
  set ClientBlockInformation(value: any) {
    if (value) {
      this.isclientBlockInformationsLoaded = true;
      this.initializeEditData();
    }
  }
  @Input("IsTherapistServiceLoaded")
  set serviceTherapistPreference(value: boolean){
    if(value){
      this.LoadServices();
      this.LoadTherapists();
    }
  }

  comments: string = "";
  captions: any;
  clientCaptions: any;
  services: any = [];
  therapists: any = [];
  showAddServiceOptions: boolean = false;
  showAddTherapistOptions: boolean = false;
  tempServices: any;
  tempTherapists: any[];
  serviceDetail: boolean;
  therapistDetail: boolean;
  clientBlockFormGroup: UntypedFormGroup;
  servicedataRecord: any;
  therapistRecord: any;
  checkallBool: boolean = false;
  serviceallCheck: boolean = false;
  serviceallTherapistCheck: boolean = false;
  checkallTherapist: boolean = false;
  ClientEditData: any;
  clientDetail: any;
  serviceDetails: any = [];
  therapistDetails: any = [];
  clientDetails: any = [];
  clientId: number;
  common: any;
  isFirstTime: boolean = true;
  isClientBlocked: boolean = false;
  isEditDataLoaded = false;
  isclientBlockInformationsLoaded = false;
  isServicesLoaded = false;
  isTherapistsLoaded = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  overrideClientBlock: boolean = false;
  isInitialLoad = false;
  floatLabel:string;
  isGuestRecordPurged: boolean = false;
  @Input('changedClientData')
  set changeClientData(value){
    if(value){
      this.initializeFormData();
    }
  }

  constructor(public appointmentService: AppointmentpopupService, private Form: UntypedFormBuilder, public localization: SpaLocalization,
    private http: HttpServiceCall, public dialog: MatDialog, public dialogRef: MatDialogRef<ClientBlockedInformationComponent>,
    private utils: SpaUtilities, private breakPoint: BreakPointAccess,
    private PropertyInfo: SpaPropertyInformation,
    private _localization:Localization) {
    this.captions = this.localization.captions.bookAppointment;
    this.floatLabel = this.localization.setFloatLabel;
    this.common = this.localization.captions.common;
    this.allCaptions = this._localization.captions;
    this.clientBlockFormGroup = this.Form.group({
      isClientBlocked: false,
      serviceDetail: false,
      therapistDetail: false,
      clientDetailTherapist: [],
      clientDetailService: [],
      comments: ""
    });
    this.initializeEditData();
    this.appointmentService.clientBlockIformationFormGroup = this.clientBlockFormGroup;
  }

  initializeEditData() {
    if (!this.isEditDataLoaded && this.isclientBlockInformationsLoaded && this.isServicesLoaded && this.isTherapistsLoaded) {
      this.isInitialLoad = true;
      this.appointmentService.getRecentAppointments(this.appointmentService.ClientBlockInformation.clientId)
      this.isEditDataLoaded = true;
      const blockedTherapists = [];
      const blockedServices = [];
      this.isClientBlocked = false;
      if (this.appointmentService.ClientBlockInformation) {
        if (this.appointmentService.ClientBlockInformation.blockClient) {
          this.isClientBlocked = true;
        }
        else {
          if (this.appointmentService.ClientBlockInformation.serviceBlock && this.appointmentService.ClientBlockInformation.serviceBlock.length > 0) {
            this.appointmentService.ClientBlockInformation.serviceBlock.map(x => {
              this.services.map(y => {
                if (x === y.id) {
                  y.selected = true;
                  blockedServices.push(y);
                }
              });
            });
          }
          if (this.appointmentService.ClientBlockInformation.therapistBlock && this.appointmentService.ClientBlockInformation.therapistBlock.length > 0) {
            this.appointmentService.ClientBlockInformation.therapistBlock.map(x => {
              this.therapists.map(y => {
                if (x === y.id) {
                  y.selected = true;
                  blockedTherapists.push(y);
                }
              });
            });
          }
        }

        this.clientBlockFormGroup = this.Form.group({
          isClientBlocked: this.isClientBlocked,
          serviceDetail: blockedServices.length > 0,
          therapistDetail: blockedTherapists.length > 0,
          clientDetailTherapist: blockedTherapists,
          clientDetailService: blockedServices,
          comments: this.appointmentService.ClientBlockInformation.comments
        });
        this.comments = this.appointmentService.ClientBlockInformation.comments;

        this.serviceDetail = blockedServices.length > 0;
        this.therapistDetail = blockedTherapists.length > 0;
        if (this.serviceDetail) {
          this.clientBlockFormGroup.controls.clientDetailService.setValue(blockedServices);
          this.updateServiceSelection(true);
        }
        if (this.therapistDetail) {
          this.clientBlockFormGroup.controls.clientDetailTherapist.setValue(blockedTherapists);
          this.updateTherapistSelection(true);
        }
        this.updateForm();
      }
      this.isInitialLoad = false;
    }
  }

  closeWarning(){
    this.showClose = false;
  }
  ngOnInit() {
    this.initializeFormData();
    this.isGuestRecordPurged = this.appointmentService?.clientEditData?.clientDetail?.isPurged;
    this.isGuestRecordPurged ? this.clientBlockFormGroup.disable() : '';
  }
  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  toggleBlockClient(blockClient) {
    this.isClientBlocked = blockClient;

    if (this.isClientBlocked) {
      this.ValidateClientBlocksAndProceed();
      this.services.map(x => x.selected = false);
      this.therapists.map(x => x.selected = false);
      this.updateServiceSelection();
      this.updateTherapistSelection();
    }
    this.clientBlockFormGroup.controls.isClientBlocked.setValue(blockClient);
    this.updateForm();
  }
  updateForm() {
    this.appointmentService.IsClientBlockInfoDirty = true;
    this.appointmentService.clientBlockIformationFormGroup = this.clientBlockFormGroup;
  }

  initializeFormData() {
    if (this.appointmentService.clientEditData && this.appointmentService.clientWidowActionType == "EDIT") {
      this.appointmentService.hasAccessForClientBlockInfo = this.breakPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.EditClientBlockInfo], false);
      this.clientId = this.appointmentService.clientEditData.clientDetail.id;

      if (this.appointmentService.isClientViewOnly || this.appointmentService.isClientBlockViewOnly) {
        this.utils.disableControls(this.clientBlockFormGroup);
      }
    }
    else {
      this.appointmentService.hasAccessForClientBlockInfo = this.breakPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.AddClientBlockInfo], false);

    }

    this.LoadServices();
    this.LoadTherapists();

  }

  LoadServices() {
    this.services = _.cloneDeep(this.appointmentService.allServices.result);
    if(this.services != undefined) {
      for (let servicesItem of this.services) {
        servicesItem.selected = false;
        for (let serviceDetailsItem of this.serviceDetails) {
          if (serviceDetailsItem.typeId == servicesItem.id) {
            servicesItem.selected = true;
          }
        }
      }
      this.servicedataRecord = this.checkisData(this.services);
      this.isServicesLoaded = true;
      this.initializeEditData();
  }
}

  LoadTherapists() {
    this.therapists = _.cloneDeep(this.appointmentService.allTherapists.result);
    for (let i = 0; i < this.therapists?.length; i++) {
      this.therapists[i].selected = false;
      this.therapists[i].name = this.therapists[i].firstName + " " + this.therapists[i].lastName;
      for (let j = 0; j < this.therapistDetails.length; j++) {
        if (this.therapistDetails[j].typeId == this.therapists[i].id) {
          this.therapists[i].selected = true;
        }
      }
    }
    this.therapistRecord = this.checkisData(this.therapists);
    this.isTherapistsLoaded = true;
    this.initializeEditData();
  }


  toggleDetail(info, v) {

    if (!this.serviceDetail && !this.therapistDetail)
      return;
    let dataArray = (v == "service") ? this.services : this.therapists;
    info.selected = !info.selected
    if (v == "service") {
      this.showAddServiceOptions = true;
      this.checkallBool = dataArray.every(x => x.selected);
      this.serviceallCheck = dataArray.every(x => x.selected);
      this.servicedataRecord = this.checkisData(dataArray);
    } else {
      this.showAddTherapistOptions = true;
      this.checkallTherapist = dataArray.every(x => x.selected);
      this.serviceallTherapistCheck = dataArray.every(x => x.selected);
      this.therapistRecord = this.checkisData(dataArray);
    }

  }

  AddServiceOptions() {
    this.showAddServiceOptions = true;
    this.tempServices = _.cloneDeep(this.services);
  }

  AddTherapistOptions() {
    this.showAddTherapistOptions = true;
    this.tempTherapists = _.cloneDeep(this.therapists);
  }

  updateServiceSelection(isInitialLoad?: boolean, from?: string) {

    let isValid = isInitialLoad;
    if (!isInitialLoad)
      isValid = this.ValidateServiceBlocksAndProceed();

    if (isValid) {
      this.showAddServiceOptions = false;
      this.tempServices = [];
      this.servicedataRecord = this.checkisData(this.services);
      let tempData: any = [];
      this.services.forEach(s => {
        if (s.selected)
          tempData.push({
            Id: 0,
            ClientId: this.clientId,
            typeName: 'SERVICE',
            typeId: s.id
          });
      });

      for (let i = 0; i < tempData.length; i++) {
        for (let j = 0; j < this.serviceDetails.length; j++) {
          if (tempData[i].typeId == this.serviceDetails[j].typeId) {
            tempData[i].id = this.serviceDetails[j].id;
          }
        }
      }
      this.serviceDetails = [];
      this.serviceDetails = tempData;
      this.clientBlockFormGroup.controls.clientDetailService.setValue(this.serviceDetails);            
      this.updateForm();
      if (from === 'Done') {
        this.appointmentService.clientBlockIformationFormGroup.markAsDirty();
      }
    }

  }

  

  checkChange() {
    if (!this.therapistDetail && this.showAddTherapistOptions) {
      this.cancelTherapistSelection();
    }
    if (!this.serviceDetail && this.showAddServiceOptions) {
      this.cancelServiceSelection();
    }
  }


  ValidateClientBlocksAndProceed() {
    if (!this.isInitialLoad && !this.overrideClientBlock && this.appointmentService.clientBlockIformationFormGroup && this.appointmentService.clientBlockIformationFormGroup.value.isClientBlocked
      && this.appointmentService.add_client
      && this.appointmentService.RecentAppsResponse
      && this.appointmentService.RecentAppsResponse.filter(x => this.utils.ValidateDatesGreaterThanOrEqual(x.date, this.PropertyInfo.CurrentDate)).length > 0) {

      this.utils.showAlert(this.captions.ClientHasFutureAppointments, GlobalConst.AlertType.Warning, GlobalConst.ButtonType.YesNo, async (res) => {
        if (res === GlobalConst.AlertAction.YES) {
          this.overrideClientBlock = true;
        }
        else {
          this.toggleBlockClient(false);
        }
      });
    }
  }



  ValidateTherapistBlocksAndProceed() {
    let isSuccess = true;
    if (!this.overrideClientBlock && this.appointmentService.clientBlockIformationFormGroup
      && this.appointmentService.add_client
      && this.appointmentService.RecentAppsResponse
      && this.appointmentService.RecentAppsResponse.filter(x => this.utils.ValidateDatesGreaterThanOrEqual(x.date, this.PropertyInfo.CurrentDate)).length > 0
      && this.therapists && this.therapists.filter(x => x.selected).length > 0) {

      const selectedTherapists = this.therapists.filter(x => x.selected).map(x => x.id);
      let invalidTherapists = this.therapists.filter(x => selectedTherapists.find(y => x.id === y));
      if (invalidTherapists && invalidTherapists.length > 0) {
        const therapistAppointments = this.appointmentService.RecentAppsResponse.filter(x => this.utils.ValidateDatesGreaterThanOrEqual(x.date, this.PropertyInfo.CurrentDate)
          && invalidTherapists.find(y => x.therapistNames.indexOf(y.name)));
        if (therapistAppointments && therapistAppointments.length > 0) {
          isSuccess = false;
          this.utils.showAlert(this.captions.ClientHasFutureAppointments, GlobalConst.AlertType.Warning, GlobalConst.ButtonType.YesNo, async (res) => {
            if (res === GlobalConst.AlertAction.YES) {
              this.overrideClientBlock = true;
              this.updateTherapistSelection();
            }
          });
        }
      }
    }
    return isSuccess;
  }


  ValidateServiceBlocksAndProceed() {
    let isSuccess = true;
    if (!this.overrideClientBlock && this.appointmentService.clientBlockIformationFormGroup
      && this.appointmentService.add_client
      && this.appointmentService.RecentAppsResponse
      && this.appointmentService.RecentAppsResponse.filter(x => this.utils.ValidateDatesGreaterThanOrEqual(x.date, this.PropertyInfo.CurrentDate)).length > 0
      && this.services && this.services.filter(x => x.selected).length > 0) {

      const selectedServics = this.services.filter(x => x.selected).map(x => x.id);
      let invalidServices = this.services.filter(x => selectedServics.find(y => x.id === y));
      if (invalidServices && invalidServices.length > 0) {
        const serviceAppointments = this.appointmentService.RecentAppsResponse.filter(x =>
          this.utils.ValidateDatesGreaterThanOrEqual(x.date, this.PropertyInfo.CurrentDate)
          && invalidServices.find(y => y.description === x.serviceName));
        if (serviceAppointments && serviceAppointments.length > 0) {
          isSuccess = false;
          this.utils.showAlert(this.captions.ClientHasFutureAppointments, GlobalConst.AlertType.Warning, GlobalConst.ButtonType.YesNo, async (res) => {
            if (res === GlobalConst.AlertAction.YES) {
              this.overrideClientBlock = true;
              this.updateServiceSelection();
            }
          });
        }
      }
    }
    return isSuccess;
  }


  updateTherapistSelection(isInitialLoad?: boolean, from?: string) {
    let isValid = isInitialLoad;
    if (!isInitialLoad)
      isValid = this.ValidateTherapistBlocksAndProceed();

    if (isValid) {
      this.showAddTherapistOptions = false;
      this.tempTherapists = [];
      this.therapistRecord = this.checkisData(this.therapists);
      let tempData: any = [];
      this.therapists.forEach(s => {
        if (s.selected)
          tempData.push({
            Id: 0,
            ClientId: this.clientId,
            typeName: 'THERAPIST',
            typeId: s.id
          });
      });

      for (let tempDataItem of tempData) {
        for (let therapistDetailsItem of this.therapistDetails) {
          if (tempDataItem.typeId == therapistDetailsItem.typeId) {
            tempDataItem.id = therapistDetailsItem.id;
          }
        }
      }
      this.therapistDetails = [];
      this.therapistDetails = tempData;
      this.clientBlockFormGroup.controls.clientDetailTherapist.setValue(this.therapistDetails);
      this.updateForm();
      if (from === 'Done') {
        this.appointmentService.clientBlockIformationFormGroup.markAsDirty();
      }
    }
  }

  cancelServiceSelection() {
    this.showAddServiceOptions = false;
    this.services = this.tempServices;
    this.checkallBool = this.services.every(x => x.selected);
    this.serviceallCheck = this.services.every(x => x.selected);
    this.servicedataRecord = this.checkisData(this.services);
  }

  cancelTherapistSelection() {
    this.showAddTherapistOptions = false;
    this.therapists = this.tempTherapists;
    this.checkallTherapist = this.therapists.every(x => x.selected);
    this.serviceallTherapistCheck = this.therapists.every(x => x.selected);
    this.therapistRecord = this.checkisData(this.therapists);
  }
  /**
   * @function checkisData
   * @param array
   * @description Find the array if any checked data
   */

  checkisData(e) {
    let result = e?.find(x => x.selected);
    return (typeof result == "undefined") ? true : false;
  }

  /**
   * @function checkallData
   * @param array
   * @description Find the array if any checked data
   */
  checkallData() {
    if (!this.serviceallCheck) {
      this.services.map(x => x.selected = true);
      this.checkallBool = this.services.every(x => x.selected);
      this.serviceallCheck = true;
      this.servicedataRecord = this.checkisData(this.services);
    } else {
      this.services.map(x => x.selected = false);
      this.checkallBool = this.services.every(x => x.selected);
      this.serviceallCheck = false;
      this.servicedataRecord = this.checkisData(this.services);
    }
  }

  checkallTherapistData() {
    if (!this.serviceallTherapistCheck) {
      this.therapists.map(x => x.selected = true);
      this.checkallTherapist = this.therapists.every(x => x.selected);
      this.serviceallTherapistCheck = true;
      this.therapistRecord = this.checkisData(this.therapists);
    } else {
      this.therapists.map(x => x.selected = false);
      this.checkallTherapist = this.therapists.every(x => x.selected);
      this.serviceallTherapistCheck = false;
      this.therapistRecord = this.checkisData(this.therapists);
    }
  }
  
  // successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
  //   if (callDesc == "GetAllSpaService") {
  //     this.services = <any>result.result;
  //     for (let servicesItem of this.services) {
  //       servicesItem.selected = false;
  //       for (let serviceDetailsItem of this.serviceDetails) {
  //         if (serviceDetailsItem.typeId == servicesItem.id) {
  //           servicesItem.selected = true;
  //         }
  //       }
  //     }
  //     this.servicedataRecord = this.checkisData(this.services);
  //     this.isServicesLoaded = true;
  //     this.initializeEditData();

  //   }
  //   else if (callDesc == "GetAllTherapist") {
  //     this.therapists = <any>result.result;
  //     for (let i = 0; i < this.therapists.length; i++) {
  //       this.therapists[i].selected = false;
  //       this.therapists[i].name = this.therapists[i].firstName + " " + this.therapists[i].lastName;
  //       for (let j = 0; j < this.therapistDetails.length; j++) {
  //         if (this.therapistDetails[j].typeId == this.therapists[i].id) {
  //           this.therapists[i].selected = true;
  //         }
  //       }
  //     }
  //     this.therapistRecord = this.checkisData(this.therapists);
  //     this.isTherapistsLoaded = true;
  //     this.initializeEditData();
  //   }
  // }
}


