    import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AgilysysButtonComponent } from './agilysys-buttons.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule],
    declarations: [AgilysysButtonComponent],
    exports: [AgilysysButtonComponent]
})
export class AgilysysButtonsModule {}