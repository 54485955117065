import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'getPrimary',
    pure: true
})
export class GetPrimary implements PipeTransform {
    transform(value: any) {
        let primaryVal = value.filter(x => x.isPrimary);
        return primaryVal && primaryVal.length > 0 ? primaryVal : value;
    }
}