import { GridFilterAction } from "src/app/eatecui/source/transaction/shared/interface/transaction.interface";
import { RadioOptions } from "src/app/eatecui/dist/agilysys-dynamicform";
import { GridInterface } from "src/app/eatecui/source/setup-master/shared/interface/grid-interface";
import { FormBuilderGroupKey, GridFilterValue } from "src/app/eatecui/source/setup-master/shared/interface/master.interface";
import { FilterDataMapping } from "../models/data-mapping.interface";

export class BaseDataMapping {
    /**
      * @method MappingPostPayload
      * @input IntialArgument
      * @output MappingPayloadEngine
      * @description Mapping Payload
      */
    static BaseDataMapping(resposeData: Array<any>): Array<any> {
        try {
            return resposeData;
        } catch (error) {
            console.error(error);
        }
    }
    static OptionListDataMapping(resposeData: Array<any>, FieldGrouping: FormBuilderGroupKey): Array<RadioOptions> {
        try {
            const radioOptionlist: Array<RadioOptions> = []
            if (resposeData?.length) {
                resposeData.forEach((x: any )=> {
                    const optionListValue = {} as RadioOptions;
                    optionListValue.key = this.DataIdMapping(x, FieldGrouping);
                    optionListValue.label = this.DataLabelMapping(x, FieldGrouping);
                    radioOptionlist.push(optionListValue);
                });
            }
            return radioOptionlist;
        } catch (error) {
            console.error(error);
        }
    }
    static DataIdMapping(responseObject: any, FieldGrouping: FormBuilderGroupKey): number | string {
        try {
            return responseObject['Id'];
        } catch (error) {
            console.error(error);
        }
    }

    static DataLabelMapping(responseObject: any, FieldGrouping: FormBuilderGroupKey): number | string {
        try {
            const { Name, Code } = responseObject;
            return ( Name ) ? Name : Code;
        } catch (error) {
            console.error(error);
        }
    }

    static AutoCompleteDataMapping(radioOptions: RadioOptions, FieldGrouping: FormBuilderGroupKey): any {
        try {
            const autoCompleteValues = {} as any;
            const {key, label} = radioOptions;
            autoCompleteValues.Id = key;
            autoCompleteValues.Name = label;
            return autoCompleteValues;
        } catch (error) {
            console.error(error);
        }
    }

    static FilterDataMapping(resposeData: Array<FilterDataMapping>, FilterDetail: GridFilterAction): Array<any> {
        try {
            let filterKeyData = [];
            if( resposeData && resposeData.length > 0 ){
                 const GetFilterData: Array<FilterDataMapping> =  resposeData.filter((x: FilterDataMapping)=> x.Key === FilterDetail.FilterOdataKey);
                 if( GetFilterData && GetFilterData.length > 0 && GetFilterData[0].Value && GetFilterData[0].Value.length > 0 ){
                    filterKeyData = GetFilterData[0].Value.map((data: any) => {
                        const filterKey = {} as GridFilterValue;
                        filterKey.FilterId = GridInterface.GridFilterKeyMapping(FilterDetail, data);
                        filterKey.FilterName = GridInterface.GridFilterLabelMapping(FilterDetail, data);
                        filterKey.Checked = false;
                        return filterKey;
                      });
                 }
            }
            return filterKeyData;
        } catch (error) {
            console.error(error);
        }
    }
}