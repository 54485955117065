import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './app/core/extend-prototype/Date.prototype.extend';
import './app/core/extend-prototype/String.prototype.extend';
import 'src/app/core/extensions/register-routes';
if (environment.production) {
  enableProdMode();
}

(async () => {
  
  let pv = localStorage.getItem('userProductVersion');
  const response = await fetch('./assets/json/config.json?v='+pv);
  const json = await response.json();
  Object.entries(json).forEach(([key, value]) => {
    environment[key] = value;
  });

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

})();