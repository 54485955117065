import { Component, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';

export interface MultiSelectAutoCompleteData {
  Id: number;
  Value: string;
  Checked?: boolean;
}

@Component({
  selector: 'lib-multi-select-auto-complete',
  templateUrl: './multi-select-auto-complete.component.html',
  styleUrls: ['./multi-select-auto-complete.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MultiSelectAutoCompleteComponent implements OnInit, OnChanges {

  textValue: string;
  autoCompleteParams: any;
  value: any;
  defaultValue: any;
  enabledCancel: boolean;
  autoCompleteData: Array<MultiSelectAutoCompleteData> = [];
  displayValue: string;
  selectedList: Array<any> = [];
  previousSelectedId = [];
  previousName: string;
  displayKeyName: string;
  constructor() {
    this.enabledCancel = false;
  }

  cancelautocomplete(): void {
    try {
    //  this.autoCompleteParams.selectData = [];
     this.autoCompleteParams.stopEditing();
    } catch (error) {
        console.error(error);
    }
  }

  agInit(params: any) {
    this.autoCompleteParams = params;
    this.autoCompleteParams.selectData = [];
    this.displayValue = this.autoCompleteParams.value;
    // this.textValue = this.displayValue;
    this.previousName = this.autoCompleteParams.value;
    this.previousSelectedId = this.autoCompleteParams.data[this.autoCompleteParams.UpdatedProperty];
    if (this.previousSelectedId && this.previousSelectedId.length > 0) {
      this.previousSelectedId.forEach(data => {
        data.Checked = true;
      });
    }
    this.displayKeyName = this.autoCompleteParams.AutoCompleteDisplayKey;
  }

  getValue(): any {
    const SelectedData = this.autoCompleteParams.selectData.filter(x => x.Checked === true).map(y => {
      return y.Name;
    });
    let NewDisplayName;
    // if (this.previousName) {
    //   const NamesArray = this.previousName.split(', ');
    //   NewDisplayName = SelectedData.concat(NamesArray);
    //   NewDisplayName = NewDisplayName.filter((c, index) => {
    //     return NewDisplayName.indexOf(c) === index;
    //   });
    // } else {
    //   NewDisplayName = SelectedData;
    // }
    const SelectedId = this.autoCompleteParams.selectData.filter(x => x.Checked === true).map(y => {
      return y;
    });
    let NewSelectedList;
    if (this.previousSelectedId && this.previousSelectedId.length > 0) {
      NewSelectedList = this.previousSelectedId.concat(SelectedId);
      NewSelectedList = NewSelectedList.filter((c, index) => {
        return NewSelectedList.findIndex(x => x.Id === c.Id) === index;
      });
    } else {
      NewSelectedList = SelectedId;
    }
    this.autoCompleteParams.value = NewSelectedList;
    NewDisplayName = NewSelectedList.map(d => {
      return d.Name;
    });
    this.autoCompleteParams.data[this.autoCompleteParams.UpdatedProperty] = NewSelectedList;
    this.displayValue = NewDisplayName.join(', ');
    return this.displayValue;
  }

  addData(DataList: MultiSelectAutoCompleteData): void {
    try {
      if (this.selectedList.findIndex(x => x.Id === DataList.Id) === -1 && DataList.Checked) {
        this.selectedList.push(DataList);
      } else {
        if (this.selectedList.findIndex(x => x.Id === DataList.Id) !== -1 && !DataList.Checked) {
          const Index = this.selectedList.findIndex(x => x.Id === DataList.Id);
          if (Index !== -1) {
            this.selectedList.splice(Index, 1);
          }
        }
        if (this.previousSelectedId && this.previousSelectedId.length > 0 &&
            this.previousSelectedId.findIndex(x => x.Id === DataList.Id) !== -1 && !DataList.Checked) {
              const Index = this.previousSelectedId.findIndex(x => x.Id === DataList.Id);
              if (Index !== -1) {
                this.previousSelectedId.splice(Index, 1);
              }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  textChanged() {
    console.log('Text Changed', this.autoCompleteParams);
    if (this.textValue && this.textValue !== '' && this.textValue.length > 0) {
        this.enabledCancel = true;
        this.autoCompleteParams['searchParams'] = this.textValue;
    }
    if (this.autoCompleteParams.hasOwnProperty('colDef')) {
      this.autoCompleteParams.colDef.valueFormatter(this.autoCompleteParams, true);
    }
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  ngOnChanges() {
    this.autoCompleteData = this.autoCompleteParams.colDef.cellEditorParams.selectData;
  }

  ngOnInit(): void {
  }

  preventDefault(event) {
    event.stopPropagation();
  }

}
