import { Component, Input, OnChanges, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UicontrolInterface } from 'src/app/eatecui/source/shared/models/ui-controls';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { BreadCrumbConfiguration, HeaderConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LargeTextCellEditor } from 'ag-grid-community';

@Component({
  selector: 'app-transaction-header',
  templateUrl: './transcation-header.component.html',
  styleUrls: ['./transcation-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TransactionHeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() headerConfiguration: HeaderConfiguration;
  breadCrumbConfig: BreadCrumbConfiguration[];
  actionButtonConfig: AgilysysButtonModel[];
  bradcrumbDropdownDefaultValue = 1;
  selectedTransaction: any;
  selectedTransactionFrom: any;
  constructor(private routeNavigateService: RouteNavigateService, private location: Location,
    private route: ActivatedRoute, private translateService: TranslateService) {
    const decParams = ( this.route && this.route.snapshot && this.route.snapshot.queryParams && 
      this.route.snapshot.queryParams.data &&
      Object.keys(this.route.snapshot.queryParams.data).length > 0 ) ? 
      JSON.parse(decodeURIComponent(this.route.snapshot.queryParams.data)) : undefined;
    // const decParams = JSON.parse(decodeURIComponent(this.route.snapshot.queryParams.data));
    // this.selectedTransaction = decParams['transaction'];
    // this.selectedTransactionFrom = decParams['transactionFrom'];
      this.selectedTransaction = (decParams && decParams !== undefined) ? (decParams['sales'] ? decParams['sales'] :
          decParams['transaction'] ? decParams['transaction'] : decParams['eventmgmt'] ? decParams['eventmgmt'] : undefined) : undefined;
    this.selectedTransactionFrom = (decParams && decParams !== undefined) ? decParams['transactionFrom'] : undefined;
      const routerPath = ( this.route.snapshot.hasOwnProperty('routeConfig') && this.route.snapshot.routeConfig !== null && 
      this.route.snapshot.routeConfig.hasOwnProperty('path') ) ? this.route.snapshot.routeConfig.path : undefined;
      if ( this.selectedTransaction === undefined && routerPath !== undefined) {
         this.selectedTransaction = UicontrolInterface.CheckParams(routerPath);
      }
  }
  dropdownSeclection(data): void {
    this.bradcrumbDropdownDefaultValue = data.value;
    this.headerConfiguration?.BreadCrumbConfig?.forEach(dataoptions => {
      if (dataoptions.Type === 'dropdown') {
        dataoptions.SelectedTypeId = this.bradcrumbDropdownDefaultValue;
      }
    });
    const dataoption = this.headerConfiguration.BreadCrumbConfig[1].DataOption.filter(x => x.Id === data.value)[0];
    if (this.headerConfiguration.BreadCrumbConfig[1].hasOwnProperty('DropdownCallBack')) {
      this.headerConfiguration.BreadCrumbConfig[1].DropdownCallBack(data, dataoption);
    }
  }
  breadcrumbIconClick(event, iconName): void {
    this.headerConfiguration.BreadCrumbConfig.forEach((x: any) => {
      if (x.IsIconOnly) {
        const callBackData = {
          event: event,
          iconName: iconName
        };
        x.CallBackEvent(callBackData);
      }
    });
  }

  backIconClick(breadCrumbConfig: BreadCrumbConfiguration) {
    this.routeNavigateService.navigateRoute(breadCrumbConfig.RoutingUrl);
  }
  navigateAction(breadCrumbConfig: BreadCrumbConfiguration) {
    try {
      if (breadCrumbConfig.RoutingAction) {
        let transactionParams = {};
        const transCollection: Array<string> = ['piadjusment', 'pientercounts', 'pientercyclecounts', 'piprint'];
        const selectedIndex = transCollection.findIndex((x: string ) => x === this.selectedTransaction);
        const selectedTransaction = ( selectedIndex > -1 ) ?
        'physicalinventory' : ( this.selectedTransaction === 'savedreceiving' && this.selectedTransactionFrom !== 'SavedReceiving' ) ? 'receiving' : 
        this.selectedTransaction;
        const salesCollection: Array<string> = ['sales', 'salesforecasting', 'sales-analysis', 'cycleplanning'];
        const salesIndex = salesCollection.findIndex((e: string) => e === this.selectedTransaction);
        if ( salesIndex > -1 ) {
          const salesKey = this.selectedTransaction === 'sales-analysis'  ? 'salesforecasting' : this.selectedTransaction;
          transactionParams = { sales: salesKey };
        } else if (this.selectedTransaction === 'events') {
          transactionParams = { eventmgmt: selectedTransaction };
        } else if (this.selectedTransaction === 'requisition') {
          transactionParams = { transaction: selectedTransaction, fromReqCategory: this.selectedTransactionFrom};
        } else {
          transactionParams = { transaction: selectedTransaction };
        }
        if (breadCrumbConfig.hasOwnProperty('RoutingQueryParams')) {
          transactionParams = Object.assign({}, transactionParams, breadCrumbConfig.RoutingQueryParams);
        }
        const masterParams = { queryParams: transactionParams };
        if ( breadCrumbConfig.hasOwnProperty('EnableLocationBack') && breadCrumbConfig.EnableLocationBack ) {
          this.location.back();
        } else {
          this.routeNavigateService.navigateRoute(breadCrumbConfig.RoutingUrl, masterParams);
        }
        
      }
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method reassignHeaderWith scroll
   * @input Params None
   * @output Params Void None
   * @Description resize the header container width 
   */
  reassignHeaderWith(): void {
    try {
      const buttonElement = document.getElementsByClassName('transaction-actions');
      const breadCrumbElement = document.getElementsByClassName('transcation-breadcrumbs');
      const headerElement = document.getElementsByClassName('transcation-header');
      if ( buttonElement && breadCrumbElement ) {
        if (buttonElement[0] && buttonElement[0].clientWidth > 0 ) {
          const headerClinetWidth = headerElement[0].clientWidth;
          const buttonClinetWidth = buttonElement[0].clientWidth;
         const breacrumbWidth = ( headerClinetWidth - buttonClinetWidth );
          breadCrumbElement[0]['style'].width = `${breacrumbWidth - 60}px`;
        }
        if ( buttonElement[0] && buttonElement[0].clientWidth === 0 ) {
          const headerClinetWidth = headerElement[0].clientWidth;
          breadCrumbElement[0]['style'].width = `${headerClinetWidth - 60}px`;
        }
      }
    } catch ( error ) {
      console.error(error);
    }
  }
  ngAfterViewInit(): void {
     this.reassignHeaderWith();
  }
  ngOnChanges(): void {
    const _that = this;
    console.log('Transaction Header', this.headerConfiguration);
    const breadCrumbElement = document.getElementsByClassName('transcation-breadcrumbs');
    if ( breadCrumbElement ) {
      breadCrumbElement[0]['style'].width = '30%';
    }
    this.breadCrumbConfig = this.headerConfiguration?.BreadCrumbConfig;
    this.breadCrumbConfig?.forEach(breadcrumb => {
      if (breadcrumb.Type === 'dropdown') {
        breadcrumb.DataOption = breadcrumb.DataOption.filter(x => x.Enable === true);
        this.bradcrumbDropdownDefaultValue = breadcrumb.SelectedTypeId;
      }
    });
    this.actionButtonConfig = this.headerConfiguration?.ActionButtonConfig;
    // setTimeout(function() {
    //   const buttonElement = document.getElementsByClassName('transaction-actions');
    //   let buttonSectionWidth = 0;
    //   if (buttonElement[0].children && buttonElement[0].children.length > 0 
    //     && buttonElement[0].children[0].children && buttonElement[0].children[0].children.length > 0) {
    //     for (let i = 0; i < buttonElement[0].children[0].children.length; i++) {
    //       buttonSectionWidth += buttonElement[0].children[0].children[i].clientWidth + 32;
    //     }
    //   }
    //   const breadCrumbElement = document.getElementsByClassName('transcation-breadcrumbs');
    //   const headerElement = document.getElementsByClassName('transcation-header');
    //   if ( buttonElement && breadCrumbElement ) {
    //     if (  buttonElement[0].clientWidth > 0 ) {
    //       const headerClinetWidth = headerElement[0].clientWidth;
    //       // const buttonClinetWidth = buttonElement[0].clientWidth;
    //      const breacrumbWidth = ( headerClinetWidth - buttonSectionWidth );
    //       breadCrumbElement[0]['style'].width = `${breacrumbWidth - 30}px`;
    //     }          
    //   }
    // }, 0); 
    setTimeout(() => {
      this.reassignHeaderWith();
    }, 100);  
     
  }
  ngOnInit(): void {
    this.headerConfiguration.BreadCrumbConfig?.forEach(data => {
      if (data.Type === 'dropdown') {
        this.bradcrumbDropdownDefaultValue = data.SelectedTypeId;
      }
    });
    if (this.actionButtonConfig && this.actionButtonConfig.length > 0) {
      this.actionButtonConfig.forEach(button => {
      button.displayName = this.translateService.instant('Common.' + button.displayName);
      button.displayName = button.displayName.includes('Common.') ? button.displayName.replace('Common.', '') :
        button.displayName;
    });
  }
  }
}