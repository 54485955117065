import { Pipe, PipeTransform } from '@angular/core';
import { AgilysysButtonModel } from '../interface/button-interface';
@Pipe({name: 'agilysysicononly'})
export class AgilysysButtonIconOnlyPipe implements PipeTransform {
    ifIconOnly: boolean;
    transform(value: AgilysysButtonModel): boolean {
        this.ifIconOnly = true;
        if (value.hasOwnProperty('icon')) {
            if (value.icon.hasOwnProperty('iconOnly') && value.icon.iconOnly){
                this.ifIconOnly = false;
            }
        }
        return this.ifIconOnly;
      }
}