<section *ngIf="fieldType === 'groupingDataMultiple'">
    <h4 *ngIf="formHeader" class="product-header-3">{{formHeader}} <span *ngIf="showCount && form.get(formFieldName).controls.length > 0"> ({{form.get(formFieldName).controls.length}})</span></h4>
    <div [formGroup]="form">
        <div [formArrayName]="formFieldName">
            <div *ngFor="let formObj of form.get(formFieldName).controls; let j=index; let l= last;" class="testtt">
                  <div class="grouped-form" [formGroupName]="j">
                    <ng-template #dynamicComponentGroupedMultiple></ng-template>
                    <!-- [fieldArray]="field.groupedFields[j]"  -->
                      <app-form-icon 
                      *ngIf="field.fieldType.groupingDataMultiple.addMore && (j !== 0 || showRemoveForAll)" 
                      [objectIndex]="j" 
                      [formIconType]="formRemoveBuilderIcons" 
                      [formArray]="form.get(formFieldName)" 
                      [formObject]="formObj"  
                      (iconEmitter)="iconCallback($event)">
                    </app-form-icon>
                      
                      <app-form-icon class="golf--add-more" 
                      *ngIf="field.fieldType.groupingDataMultiple.addMore && j == (form.get(formFieldName).controls.length-1) && field.fieldType.groupingDataMultiple.maxCount >= form.get(formFieldName).controls.length" 
                      [objectIndex]="j" [formIconType]="formAddBuilderIcons" 
                      [formArray]="form.get(formFieldName)" 
                      [formObject]="formObj" 
                      (fielArrayEmiter)="fieldArrayCallback($event, formObj)" 
                      (iconEmitter)="iconCallback($event)"></app-form-icon>
                  </div>
         </div>
      </div>
    </div>
    </section>
  
    <section *ngIf="fieldType === 'groupingDataSingle'">
      <!-- <h4>{{field.labelheader}}</h4> -->
      <div [formGroup]="form">
          <div [formGroupName]="formFieldName">
              <ng-template #dynamicComponentGrouped>
              </ng-template>
        </div>
      </div>
      </section>
  