import { Component, Input, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SplitButtonComponent implements AfterViewInit {
  @ViewChild('buttonGroup') buttonGroup: ElementRef;
  @ViewChild('buttonList') buttonList: ElementRef;
  hideList = true;
  input: any;
  @Input('input')
  set setInputs(value) {
    this.input = value;
  }
  @Output() actionClick = new EventEmitter();
  
  ngAfterViewInit() {
    const buttonWidth = this.buttonGroup && this.buttonGroup.nativeElement.offsetWidth;
    this.buttonList && (this.buttonList.nativeElement.style.width = buttonWidth + 'px');
  }
  showOtherActions() {
    this.hideList = !this.hideList;
  }

  actionClicked(action) {
    this.actionClick.emit(action);
  }
}
