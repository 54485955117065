import { ElementRef, Pipe, PipeTransform } from '@angular/core';

@Pipe(
  {
    name: 'listPipe'
  })

export class ArrayToStringCoversionPipe implements PipeTransform {

  constructor() { }
  
  transform(value: any, elem?: ElementRef): string {
    let string = ''
    value.forEach(locations => {
        string += locations.LocationName + ',';
    });
    return string.substring(0, string.length -1);
  }
}