import { PubSub } from "pubsub-ts"
import { retailEventName } from "../event.model";
import { Injectable, OnDestroy } from "@angular/core";

@Injectable()
export abstract class BaseEventSubscriber extends PubSub.Subscriber implements OnDestroy {

    constructor() {
        super();
        this.registerMe();
    }

    addSubscriber(callback: Function): void {
        super.on(retailEventName, callback);
    }

    abstract registerMe(): void;
    abstract unregisterMe(): void;

    ngOnDestroy(): void {
        this.unregisterMe();
    } 

}