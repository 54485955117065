import { MasterInventoryManagement } from '../master.interface';
import { GridModelConfiguration } from './grid-config.interface';

export const GridShowAdd: GridModelConfiguration = (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement): boolean => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection ) {
            return MasterDataCollection.GridConfig.ShowAddNew;
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};
