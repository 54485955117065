import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ReportOptions, ReportTab, } from '../business/report.modals';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { SPAConfig } from '../../core/config/SPA-config';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { ReportBreakPoint } from '../../shared/globalsContant';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { PropertyFeaturesConfigurationService } from 'src/app/retail/sytem-config/payment-features-config/property-feature-config.service';

@Component({
  selector: 'view-reports',
  templateUrl: './view-reports.component.html',
  styleUrls: ['./view-reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewReportsComponent implements OnInit {
  UseRetailInterface: boolean;
  UseGiftCardInterface: boolean;
  constructor(public spaConfig: SPAConfig, private breakpoint: BreakPointAccess, private PropertyInfo: SpaPropertyInformation, private RetailFeatureConfigService: PropertyFeaturesConfigurationService) {
    this.UseRetailInterface = this.PropertyInfo.UseRetailInterface;
    this.RetailFeatureConfigService.GetGiftCardConfiguration().then((resp) => {
      this.UseGiftCardInterface = resp.activateGiftCardInterface;
    });
  }
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  htmlContent: any;
  selectedTabIndex: number = 0;
  selectedIndex: number = 0;
  reportOption: ReportOptions;
  get tabs() {
    return ReportTab;
  }

  ngOnInit() {
    this.tabGroup._handleClick = this.handleTabChange.bind(this);
  }

  handleTabChange(tab?: MatTab, tabHeader?: MatTabHeader, idx?: number) {
    if (idx == this.tabs.TransactionLog && !this.checkForTransLogTabAccess()) {
      return;
    }
    this.selectedTabIndex = idx;
    this.selectedIndex = idx;
    return true;
  }

  checkForTransLogTabAccess(): boolean {
    return this.breakpoint.CheckForAccess([ReportBreakPoint.TransactionLog]);
  }
}
