import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading = new BehaviorSubject(false);
  public request = new BehaviorSubject(null);
  public completedRequest = new BehaviorSubject(null);
  constructor() {}

}

