import { Injectable } from "@angular/core";
import { UserAccessDataService } from "src/app/common/dataservices/authentication/useraccess.data.service";

@Injectable()
export class QuickLoginBusiness {
    constructor(private _userAccessDataService: UserAccessDataService) {

    }

    public async validateBreakPointAccess(breakPointNumber: number, showPopup: boolean = false): Promise<boolean> {
        let roleid =  sessionStorage.getItem('quickIdRoleId');
        let breakPointResult = await this._userAccessDataService.getUserAccess(breakPointNumber, roleid);
        return breakPointResult && breakPointResult.isAllow;
    }
}