import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, HeaderConfiguration, TilesConfiguration, TitleType, TransactionConfiguration } from '../transaction.interface';
import { QueryConfiguration } from './query-configuration.interface';
export const QueryDisplayName: QueryConfiguration = (
    QueryHeaderEntity: any, 
    QueryDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService,
    responseData ?: any
    ): string => {
        try {
            if ( QueryHeaderEntity && QueryDetailsEntity && TransactionCollection) {
                console.log(responseData);
                return responseData['Packages']['Name']; 
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };