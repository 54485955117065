import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LayoutComponent } from '../layout/layout.component';
import { AuditRouterGuard } from './services/Route-Guards/audit-route.guard.service';
import { PmsLoginResolver } from '../common/external-request/route-resolvers/pms-login.resolver';
import { SetPropertyComponent } from './login/set-property/set-property.component';
import { RedirectRouteGuard } from '../common/services/redirect-route.guard.service';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'supportlogin',
    component: LoginComponent
  },
  {
    path: 'setProperty',
    component: SetPropertyComponent
  },
  {
    path: 'Pms',
    component: LoginComponent,
    resolve:{
      loginData: PmsLoginResolver
    }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'home',
        loadChildren: () => import('../home/home.module').then(m => m.AdminModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'appointment',
        loadChildren: () => import('../appointment/appointment.module').then(m => m.AppointmentModule)
      },
      {
        path: 'client',
        loadChildren: () => import('../client/client.module').then(m => m.ClientModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'exception',
        loadChildren: () => import('../exception/exception.module').then(m => m.ExceptionModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'shop',
        loadChildren: () => import('../retail/shop/shop.module').then(m => m.ShopModule),
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'reports',
        loadChildren: () => import('../report/reports.module').then(m => m.ReportModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/setting.module').then(m => m.SettingModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'audit',
        canActivate: [AuditRouterGuard],
        loadChildren: () => import('../audit/audit.module').then(m => m.AuditModule),
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'logviewer',
        loadChildren: () => import('../common/logviewer/logviewer.module').then(m => m.LogviewerModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'accounting',
        loadChildren: () => import('../spa-accounting/spa-accounting.module').then(m => m.SpaAccountingModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'folio',
        loadChildren: () => import('../folio/resort-finance/resort-finance.module').then(m => m.ResortFinanceModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'exportimport',
        loadChildren: () => import('../common/export-import/export-import.module').then(m=>m.ExportImportModule),
        canActivate:[RedirectRouteGuard],
        data:{disableRouteonSingleTherapist: true}
      },
      {
        path: 'all',
        loadChildren: () => import('src/app/common/all/all.module').then(m => m.AllModule)
      },
      {
        path: 'allReport',
        loadChildren: () => import('src/app/common/report-menu/report-menu.module').then(m => m.reportMenuModule)
      },
      {
        path: 'class',
        loadChildren: () => import('../class/class.module').then(m=>m.ClassModule),
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
