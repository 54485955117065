
import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';

@Component({
  selector: 'lib-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SwitchComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  yes: string;
  no: any;
  config: any;
  formFieldName: any;
  fieldType: any;
  formFieldlabel: any;
  formArrayName: any;
  value: boolean;
  formFieldToolTip: string;
  get isValid(): any { return this.form.controls[this.field.name].valid; }
  get isDirty(): any { return this.form.controls[this.field.name].dirty; }
  constructor(public agyDynamicFormServices: AgyDynamicFormServices) { }

  ngOnInit(): void {
    this.yes = 'Yes';
    this.no = 'No';
    this.fieldType = Object.keys(this.field.fieldType).toString();
    const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
    this.formFieldName = formField.fieldName;
    this.formFieldlabel = formField.fieldLabel;
    this.formArrayName = formField.fieldName;
    this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
    ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
    this.value = this.form.controls[this.formFieldName].value;
    this.form.controls[this.formFieldName].valueChanges.subscribe(val => {
      this.value = val;
      //this.calculateWidth();
    });

  }

  calculateWidth(): void {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px LatoRegular';
    const metrics = context.measureText(this.yes);
    if (canvas) {
      canvas.remove();
    }
    const switchEle = document.getElementsByClassName(this.formFieldName)[0];
    // tslint:disable-next-line: no-string-literal
    switchEle['offsetParent']['style']['width'] = (Math.ceil(metrics.width) + 30) + 'px';
    if (this.value) {
      // tslint:disable-next-line: no-string-literal
      switchEle['nextSibling']['style']['left'] = (Math.ceil(metrics.width) + 10) + 'px';
    } else {
      // tslint:disable-next-line: no-string-literal
      switchEle['nextSibling']['style']['left'] = 6 + 'px';
    }
  }
  toggleChange(event: any): void {
    this.agyDynamicFormServices.formEventCallback(this.field, 0, this.form );
  }
}
