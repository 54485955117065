import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { POPopupDataList, POPopupList, POPopupListHeader, PostPOSupplierConfig } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { PostPOPopUp } from '../model/pop-up.interface';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { IntegrationCollection } from 'src/app/eatecui/source/shared/integration/integration-interface';
@Component({
  selector: 'app-agilysys-post-po',
  templateUrl: './agilysys-post-po.component.html',
  styleUrls: ['./agilysys-post-po.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysPostPoComponent implements OnInit, AfterViewInit {
  agilysysPostPOButton: AgilysysButtonModule[];
  homeGroup: FormGroup;
  totalSupplierList = 0;
  selectedSupplierList = 0;
  supplierjson: POPopupList;
  popupGridHeader: POPopupListHeader[];
  POPopupDataList: POPopupDataList[] = [];
  supplierData: any;
  Intermediate = true;
  searchText = new FormControl();
  searchCopyList: POPopupDataList[];
  PORequiresApproval: number;
  IsApprovalRequired: any;
  disableRadioOptions = false;
  constructor(
    public dialogRef: MatDialogRef<AgilysysPostPoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PostPOPopUp,
    public formBuilder: FormBuilder,
    private systemPreferenceService: SysytemPreferenceService,
    private httpService: HttpService,  private integerationService: IntegrationService) {
    this.homeGroup = this.formBuilder.group({});
    this.totalSupplierList = data.PostPoFormConfig.PostPoFormConfig.AllSupplierCount;
    this.selectedSupplierList = data.PostPoFormConfig.PostPoFormConfig.SelectedSupplierCount;
    this.supplierData = data.PostPoFormConfig;
    this.PORequiresApproval = this.systemPreferenceService.globalSystemPreference.
      filter(x => x.LookupName === 'Porequiresseparateapproval')[0].LookupValue;
  }

  postpo(callBackData: CallBackData) {
    try {
      const SupplierIds = [];
      if (this.homeGroup.controls['post'].value === 'all') {
        this.searchCopyList.map(x => {
          SupplierIds.push(x.Id);
        });
      } else {
        this.searchCopyList.forEach(x => {
          if (x.ListChecked) {
            SupplierIds.push(x.Id);
          }
        });
      }
      const ConfirmClick: PostPOSupplierConfig = {
        EnableMultiplePost: this.homeGroup.controls['post'].value === 'all' ? true : false,
        EnableSelectSupplierPost: this.homeGroup.controls['post'].value === 'select' ? true : false,
        SupllierIdList: SupplierIds,
        SupplierProcessingList: this.searchCopyList
      };
      this.dialogRef.close([callBackData.buttonValue.buttonKey, ConfirmClick]);
    } catch (error) {
      console.error('Error occurred in postpo', error);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.homeGroup.controls['post'].valueChanges.subscribe(res => {
        this.searchText.setValue('');
        this.POPopupDataList = this.searchCopyList;
        if (res === 'all') {
          this.POPopupDataList.forEach(element => {
            element.ListChecked = true;
            element.Email = element.EnableEmail;
            element.NoExport = true;
            element.EnableExport = true;
            const CloneIndex = this.searchCopyList.findIndex(x => x.Id === element.Id);
            if (CloneIndex !== -1) {
              this.searchCopyList[CloneIndex] = element;
            }
          });
          this.totalSupplierList = this.searchCopyList.length;
          this.agilysysPostPOButton.forEach(( butonModule: AgilysysButtonModule) => {
            if ( butonModule['buttonKey'] === 'Confirm' ) {
                  butonModule['displayProperity'] = false;
            }
          });
        } else {
          this.selectedSupplierList = this.POPopupDataList.filter(x => x.ListChecked === true).length;
          this.agilysysPostPOButton.forEach(( butonModule: AgilysysButtonModule) => {
              if ( butonModule['buttonKey'] === 'Confirm' ) {
                    butonModule['displayProperity'] = (this.selectedSupplierList === 0 ) ? true : false;
              }
          });
        }
        this.Intermediate = !this.Intermediate;
      });
    }, 300);
    
  }

  async ngOnInit(): Promise<void> {
    if (this.PORequiresApproval === 2) {
      this.IsApprovalRequired = await this.httpService.
      GethttpMethod(`/inventory/api/IsApprovalRequired/${this.data.TransactionType}`).toPromise();
    } else {
      this.IsApprovalRequired = false;
    }
    this.agilysysPostPOButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.postpo(callBackData);
        }
      }
    ];

    this.popupGridHeader = [
      {
        Id: 1,
        HeaderDisplayKey: 'ListChecked',
        HeaderDisplayName: ''
      },
      {
        Id: 2,
        HeaderDisplayKey: 'ListName',
        HeaderDisplayName: 'Suppliers'
      },
      // {
      //   Id: 5,
      //   HeaderDisplayKey: 'Approval',
      //   HeaderDisplayName: 'Submit Approval'
      // },
      {
        Id: 3,
        HeaderDisplayKey: 'Email',
        HeaderDisplayName: 'E-Mail'
      }
      // {
      //   Id: 4,
      //   HeaderDisplayKey: 'NoExport',
      //   HeaderDisplayName: 'No Export'
      // }
    ];
    const docCollection:Array<IntegrationCollection>  = this.integerationService.IntegrationConfig.IntegrationCollection.filter(x=> x.IntegratonName === 'DocApprovals');
    if( docCollection && docCollection.length > 0 ){
        if( !docCollection[0].IsEnabled ){
           const approvalIndex: number =  this.popupGridHeader.findIndex(e=> e.HeaderDisplayKey === 'Approval');
           if ( approvalIndex > -1 ){
              this.popupGridHeader.splice(approvalIndex, 1);
              this.popupGridHeader = [...this.popupGridHeader]
           }
        }
    }
    this.POPopupDataList = [];
   this.disableRadioOptions = this.supplierData.ProcessPoConfig.SupplierList.some(sup => sup['Warning']);
    this.supplierData.ProcessPoConfig.SupplierList.forEach(supplier => {
      const gridDataHasApproval = this.PORequiresApproval === 2 ? 
        this.data.PostPoFormConfig.ItemList.ItemGridConfig.GridData.filter(rowData => rowData.SupplierId
             === supplier.SuplierId && (rowData.IsDeleted === false || rowData.IsDeleted === undefined)).
             some(x => x.ApprovalLevel ===  true) : false;
      const supplierObject = {
        Id: supplier.SuplierId,
        ListKey: 'Suppliers',
        ListChecked: supplier['Warning'] ? false : true,
        ListName: supplier.SuplierName,
        Email: supplier.SendVia === 1,
        NoExport: true,
        EnableExport: true,
        EnableEmail: supplier.SendVia === 1,
        EnableApproval: this.PORequiresApproval === 2 && this.IsApprovalRequired && gridDataHasApproval ? true : false,
        Approval: this.PORequiresApproval === 2 && this.IsApprovalRequired && gridDataHasApproval ? true : false,
        PORequiresApproval: this.PORequiresApproval === 2 ? true : false,
        IsApprovalRequired: this.IsApprovalRequired,
        DisableRow: supplier['Warning'] ? true : false
      };
      this.POPopupDataList.push(supplierObject);
    });
    this.selectedSupplierList = this.POPopupDataList.filter(fd => fd.ListChecked).length;
    this.agilysysPostPOButton.forEach(( butonModule: AgilysysButtonModule) => {
      if ( butonModule['buttonKey'] === 'Confirm' ) {
            butonModule['displayProperity'] = (this.selectedSupplierList === 0 ) ? true : false;
      }
    });
    this.searchCopyList = _.cloneDeep(this.POPopupDataList);
    console.log(this.POPopupDataList);

    this.searchText.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).
      subscribe(searchValue => {
        if (searchValue !== undefined && searchValue !== null) {
          this.SearchChanged(searchValue);
        }
      });
  }

  SearchChanged(searchValue) {
    try {
      this.POPopupDataList = this.searchCopyList.filter(x => x.ListName.toLowerCase().includes(searchValue));
    } catch (error) {
      console.error('Error occurred in SearchChanged', error);
    }
  }

  getReturnFormValue(value) {
    console.log('Home group', this.homeGroup);
  }

  rowCheckboxChange(key, index) {
    if (key === 'ListChecked') {
      this.POPopupDataList[index].EnableExport = this.POPopupDataList[index].ListChecked;
      this.POPopupDataList[index].Email = this.POPopupDataList[index].EnableEmail ? this.POPopupDataList[index].ListChecked : false;
      this.POPopupDataList[index].NoExport = this.POPopupDataList[index].ListChecked;
      this.POPopupDataList[index].Approval = this.POPopupDataList[index].ListChecked;
      const CloneIndex = this.searchCopyList.findIndex(x => x.Id === this.POPopupDataList[index].Id);
      if (CloneIndex !== -1) {
        this.searchCopyList[CloneIndex] = this.POPopupDataList[index];
      }
      this.selectedSupplierList = this.POPopupDataList.filter(x => x.ListChecked === true).length;
      this.agilysysPostPOButton.forEach(( butonModule: AgilysysButtonModule) => {
        if ( butonModule['buttonKey'] === 'Confirm' ) {
              butonModule['displayProperity'] = (this.selectedSupplierList === 0 ) ? true : false;
        }
      });
    } else {
      const CloneIndex = this.searchCopyList.findIndex(x => x.Id === this.POPopupDataList[index].Id);
      if (CloneIndex !== -1) {
        this.searchCopyList[CloneIndex] = this.POPopupDataList[index];
      }
    }
    this.Intermediate = !this.Intermediate;
    console.log('Search copy', this.searchCopyList);
  }

  columnCheckboxChange(key, event) {
    if (key === 'ListChecked') {
      this.POPopupDataList.forEach(element => {
        element.ListChecked = element['DisableRow'] ? false : event.checked;
        element.Email = element.EnableEmail ? event.checked : false;
        element.NoExport = event.checked;
        element.EnableExport = event.checked;
        element.Approval = !element.EnableApproval ? false : event.checked;
        const CloneIndex = this.searchCopyList.findIndex(x => x.Id === element.Id);
        if (CloneIndex !== -1) {
          this.searchCopyList[CloneIndex] = element;
        }
      });
      this.selectedSupplierList = this.POPopupDataList.filter(x => x.ListChecked === true).length;
      this.agilysysPostPOButton.forEach(( butonModule: AgilysysButtonModule) => {
        if ( butonModule['buttonKey'] === 'Confirm' ) {
              butonModule['displayProperity'] = (this.selectedSupplierList === 0 ) ? true : false;
        }
      });
    } else {
      this.POPopupDataList.forEach(element => {
        if (key === 'Email') {
          element[key] = !element.EnableEmail ? false : event.checked;
        } else if (key === 'Approval') {
          element[key] = !element.EnableApproval ? false : event.checked;
        }
        const CloneIndex = this.searchCopyList.findIndex(x => x.Id === element.Id);
        if (CloneIndex !== -1) {
          this.searchCopyList[CloneIndex] = element;
        }
      });
    }
  }

  disableHeader(key) {
    if (key === 'ListChecked' && this.homeGroup.value.post === 'all') {
      return true;
    } else if (key === 'Email') {
      return this.POPopupDataList.length === this.POPopupDataList.filter(x => x.EnableEmail === false).length;
      // let disableEmailHeader = (this.POPopupDataList.length === this.POPopupDataList.filter(x => x.EnableEmail === false).length);
      // if (!disableEmailHeader) {
      //   const nonDisabledEmailRowLength = this.POPopupDataList.filter(x => x.EnableEmail === true && !x['DisableRow']).length;
      //   if (nonDisabledEmailRowLength === 0) {
      //     disableEmailHeader = true;
      //   }
      // }
      // return disableEmailHeader;
    } else if (key === 'Approval') {
      return this.POPopupDataList.length === this.POPopupDataList.filter(x => x.EnableApproval === false).length;
    } else if (key === 'ListChecked' && this.homeGroup.value.post === 'select' && this.POPopupDataList.length === 1 && this.POPopupDataList[0]['DisableRow']) {
      return true;
    } else {
      return false;
    }
  }
}
