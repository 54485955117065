import { NativeDateAdapter } from "@angular/material/core";
import { SpaPropertyInformation } from "../services/spa-property-information.service";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Platform } from "@angular/cdk/platform";
import { Localization } from "src/app/common/localization/localization";
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(public localization: Localization, private PropertyInfo: SpaPropertyInformation, _platformId: Object) {
        super(localization.localeCode, new Platform(_platformId))

    }

    format(date: Date, displayFormat: Object): string {
        if (displayFormat === "input") {
            return moment(date).format(this.localization.inputDateFormat);
          } else {
            return moment(date).format(this.localization.inputDateFormat);
          }
      }
    getFirstDayOfWeek(): number {
        let firstDayOfWeek;
        if(this.localization.startOfWeekValue > -1){
            firstDayOfWeek =  this.localization.startOfWeekValue;
        } else {
            firstDayOfWeek =  this.localization.getFirstDayOfWeek();
        }
        return firstDayOfWeek;
    }
    
    parse(value: any): Date | null {
        if(value){
            const date = moment(value, this.localization.inputDateFormat);
            return date? date.toDate(): null;
        }
        return null;
    }
}
export const MY_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
  const dateFormat = "DD MMM YYYY";
  const calender_dateFormat = "MMM YYYY";
