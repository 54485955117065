import { Pipe, PipeTransform } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';

@Pipe({
  name: 'totalcountPipe'
})
export class CommontableCountPipe implements PipeTransform {
    captions;
    totalMsg: string;
    constructor(public localization: Localization, )
    {
     this.captions=this.localization.captions;
    }

  transform(inputArray: any[], Value: string, headerKey: any[]): string {
    if (!inputArray) {
      return this.totalMsg = this.localization.replacePlaceholders(
        this.captions.lbl_totalRecordsFoundMsg, ['count'], [0]
      );
    }
    if (!Value) {
      return this.totalMsg = this.localization.replacePlaceholders(
        this.captions.lbl_totalRecordsFoundMsg, ['count'], [inputArray.length]
      );
    }
  
    const valueLower = Value.toLowerCase();
    const filterArray = inputArray.filter(result => {
      return headerKey.some(key => {
        const fieldValue = result[key];
        if (fieldValue != null) { // Check if key exists in the object
          if (typeof fieldValue === 'string') {
            return fieldValue.toLowerCase().includes(valueLower);
          } else if (typeof fieldValue === 'number') {
            return fieldValue.toString().includes(valueLower);
          } else if (Array.isArray(fieldValue)) {
            // Check if any string in the array matches the Value
            return fieldValue.some(item =>
              typeof item === 'string' && item.toLowerCase().includes(valueLower)
            );
          }
        }
        return false;
      });
    });
  
    // Return the message with the count of matching records
    return this.totalMsg = this.localization.replacePlaceholders(
      this.captions.lbl_totalRecordsFoundMsg, ['count'], [filterArray.length]
    );
  }
  
}


