import { Device } from '../../service/payment/payment-model';
import { PaymentMethods } from '../shared.modals';
import { CardData } from './rGuestPayRequestModel';

export interface IGiftcardActions {

    GetDevices(): Promise<Device[]>;

    CaptureGiftCard(selectedDevice: Device, isIDTech: boolean, encryptedData?: string, callBackfn?): Promise<GiftCardCaptureResponse>;

    IssueGiftCard(cardNumber: string, retailTicketNumber: string, amount: number, guestDetails: GiftCardGuestDetails, handle?: string, tenderId?: PaymentMethods);

    PerformCashback(giftcardNumber: string, retailTicketNumber: string, amount: number, handle?:string, tenderId?: PaymentMethods);

    RechargeGiftCard(giftcardNumber: string, retailTicketNumber: string, amount: number, handle?:string, tenderId?: PaymentMethods);

    ReverseRecharge?(giftcardNumber: string, retailTicketNumber: string, amount: number, transId?: number, handle?: string, tenderId?: PaymentMethods);

    ReturnGiftCard(giftcardNumber: string, amount: number, handle?:string, tenderId?: PaymentMethods);
}


//TO-DO Common Models need to be Added Here

export interface GiftCardCaptureResponse {
    cardNumber: string;
    handle: string;
    status: CaptureStatus;
    errorMsg: string;
    cardData: CardData;
}

export enum CaptureStatus {
    Success = 1,
    Failure
}

export interface GiftCardGuestDetails{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}