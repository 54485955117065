import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './ag-progress-bar.component.html',
  styleUrls: ['./ag-progress-bar.component.scss']
})
export class AgProgressBarComponent{
  @Input() progress = 0;
  @Input() uploadMsg; 

}
