import { Injectable } from '@angular/core';
import { WizardTimeSlots } from 'src/app/appointment/spa-wizard/spa-wizard.modal';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SchedulingAssistantView, Service } from '../business/shared.modals';
import { Host } from '../globalsContant';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';
import { BaseResponse } from '../shared.modal';

@Injectable()
export class SchedulingAssistantService {
  availableSlots : WizardTimeSlots[];
  selectedView: SchedulingAssistantView = SchedulingAssistantView.Therapist;
  serviceAggregates : Service[] = [];
  captions: any;

  constructor(private http: HttpServiceCall, private localization: SpaLocalization) {
    this.captions = this.localization.captions;
  }

  getViewbyType(): DropdownOptions[] {
    return [
      {
        id: SchedulingAssistantView.Therapist,
        viewValue: this.captions.lbl_Therapist
      },
      {
        id: SchedulingAssistantView.Location,
        viewValue: this.captions.lbl_Location
      }
    ];
  }

  getdisplayOptionFilterData(): any[] {
    return [
      {
        id: 1,
        viewValue: this.captions.lbl_AvailableLocation
      },
      {
        id: 2,
        viewValue: this.captions.lbl_AvailableTherapist
      }
    ];
  }

  getTimeIntervalType(): DropdownOptions[] {
    return [
      {
        id: 1,
        viewValue: '1 hour'
      },
      {
        id: 2,
        viewValue: '2 hour'
      }
    ];
  }

  async getServiceAggregateById(servieId): Promise<Service> {
    const result : BaseResponse<Service> = await this.http.CallApiAsync({
      host: Host.spaManagement,
      callDesc: 'GetSpecificService',
      method: HttpMethod.Get,
      uriParams: { id: servieId },
    });
    return result && result.result;
  }

  getFormattedDropdownData(data, objectKey): DropdownOptions[] {
    const formattedDate = [];
    if (data) {
      data.forEach(result => {
        formattedDate.push({
          id: result.id,
          viewValue: result[objectKey] || result.description
        });
      });
    }
    return formattedDate;
  }

  async getAppointmentFilters(uriParams : any) : Promise<any>{   
    let result = await this.http.CallApiAsync<any>({
        host: Host.schedule,
        callDesc: "getAppointmentFilters",
        uriParams: uriParams,
        method: HttpMethod.Get,
        showError: true
    });
    return result.result;
  }
}
