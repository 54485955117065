<section class="dropdown {{field.class}}" *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'">
    <label class="golf--group--header" *ngIf="formFieldHeader">{{formFieldHeader}}</label>
      <div [formGroup]="form" title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
        selectedLabel : formFieldToolTip}}">
        <mat-form-field appearance="fill"
          [ngClass]="field?.fieldType[fieldType]?.disbaledProperty === true ? 'textbox-disabled':  ''">
            <mat-label>{{formFieldlabel}}</mat-label>
          <mat-select [required]="isRequired" 
            [disableOptionCentering]="true" [formControlName]="formFieldName" multiple="false" (selectionChange)="eventCallback(j, $event)" 
            [disabled]="field.fieldType[fieldType].disbaledProperty"
            (openedChange)="openedChange($event)">
            <mat-option  *ngIf="enableDropDownSearch" [value]="null" class="agilysys-dropdown-search-field" (click)="$event.stopPropagation();$event.preventDefault();">
              <mat-form-field  appearance="outline"  (click)="$event.stopPropagation();">
                <input id="{{formFieldName}}" matInput type="text" placeholder="Search" [formControl]="dropDownsearchControl" (keydown)="$event.stopPropagation()">
              </mat-form-field>
              <!-- <input *ngIf="enableDropDownSearch" class="agilysys-dropdown-search" type="text" placeholder="Search" [formControl]="dropDownsearchControl" (keydown)="$event.stopPropagation()"/> -->
              <!-- <span class="icon-px_close" *ngIf="dropDownsearchControl.value" (click)="dropDownsearchControl.reset();"></span> -->
            </mat-option>
            <mat-option *ngFor="let data of field.fieldType[fieldType].options" [value]="data.key" [disabled]="data.disableProperty" [title]="data.label">
              {{data.label}}
            </mat-option>
            <mat-option *ngIf="enableClearOption && field.fieldType[fieldType].options?.length > 0" [value]="null" title="Clear" class="clear-option" (click)="clearOption(j, $event)">CLEAR</mat-option>
            <span class="mat-option" *ngIf="field.fieldType[fieldType].options?.length === 0 && dropDownsearchControl.value"
              (click)="$event.stopPropagation();">
              No Data Found
            </span>
          </mat-select>
          <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
          <mat-hint *ngIf="formHintLabel">{{formHintLabel}}</mat-hint>
        </mat-form-field> 
        <i  *ngIf="field.EnableSuffixicon" matSuffix class="agilysys-suffix-icons icon-{{field.iconName}}" (click)="suffixIconClick()"></i>
      </div>
  </section>
  <section class="dropdown" *ngIf="fieldType == 'MultipleData'">
      <div [formGroup]="form">
          <div formArrayName="{{formArrayName}}">
            <div *ngFor="let formObj of form.get(formArrayName).controls; let j=index; let l= last;">
              <div>
                <mat-form-field class="dropdown__field" >
                    <mat-label>{{formFieldlabel}}</mat-label> 
                    <mat-select formControlName="{{j}}" multiple="false" appearance="fill">
                      <mat-option *ngFor="let data of field.fieldType[fieldType].data[0].options" [value]="data.key">
                        {{data.label}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!isValid && isDirty">{{errorMessage}}</mat-error>
                    <mat-hint *ngIf="formHintLabel">{{formHintLabel}}</mat-hint>
                </mat-form-field>
                <app-form-icon *ngIf="field.fieldType.MultipleData.addMore && j !== 0" [objectIndex]="j" [formIconType]="formRemoveBuilderIcons"
                  [formArray]="form.get(formArrayName)" [formObject]="formArrayName" (iconEmitter)="iconCallback($event, form)">
                </app-form-icon>
                <app-form-icon class="golf--add-more"
                  *ngIf="field.fieldType.MultipleData.addMore && j == (form.get(formArrayName).controls.length-1) && field.fieldType.MultipleData.maxCount >= form.get(formArrayName).controls.length"
                  [formIconType]="formAddBuilderIcons" [formArray]="form.get(formArrayName)" [formObject]="field.fieldType.MultipleData.data[j]"
                  (iconEmitter)="iconCallback($event)" (fielArrayEmiter)="fieldArrayCallback($event)">
                </app-form-icon>
              </div>
            </div>
          </div>
        </div>
  </section>