import { Directive, ElementRef, HostListener, ViewChild, AfterViewInit } from '@angular/core';
@Directive({
    selector: '[widthcalculate]'
  })
  export class CustomWidthCalculateDirective implements  AfterViewInit  {
    @ViewChild('category') category : ElementRef;
    constructor(public el: ElementRef) {}
    @HostListener('window:resize', ['$event'])
   onResize(event) {
    const containerwidth =  this.el.nativeElement.offsetWidth;
    const setWidth = containerwidth - 50;
    this.el.nativeElement.querySelector('.mat-radio-label-content').setAttribute('style' , 'width:'+ setWidth +'px');
}
    ngAfterViewInit(){
       const containerwidth =  this.el.nativeElement.offsetWidth;
       const setWidth = containerwidth - 50;
       this.el.nativeElement.querySelector('.mat-radio-label-content').setAttribute('style' , 'width:'+ setWidth +'px');
    }
  }
