import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextEditorComponent implements OnInit, ICellEditorAngularComp {
  textValue: string;
  params: ICellEditorParams;
  value: any;
  defaultValue: any;
  textEditor: HTMLElement;
  constructor() { }

  ngOnInit(): void {
    console.log('NgOnInit');
  }

  agInit(params: ICellEditorParams) {
    // console.log('Text Editor', params);
    this.params = params;
    this.textValue = params.value;
    this.textEditor = document.getElementById('text-' + this.params.colDef.field);
  }

  init(params) {
  }

  setFocus() {
    const classList = document.getElementsByClassName('input-text-editor');
    if (classList.length > 0) {
      for (let index = 0; index < classList.length; index++) {
        const textBox: HTMLElement = classList[index] as HTMLElement;
        if (textBox.id.includes('text-'+this.params.colDef.field)) {
          textBox.focus();
        }
      }
    }
  }

  focusIn() {
    if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
      this.setFocus();
    }
  }

  afterGuiAttached() {
    try {
      if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
            this.setFocus();
      }
    } catch (error) {
      console.error('Error occurred in number cell editor component of afterGuiAttached function', error);
    }
  }

  getValue(): any {
    // console.log('Changed data', this.textValue);
    if (this.params.context.parentComponent.selectedColumn === this.params.colDef.field) {
      this.params.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field);
    }
    return this.textValue;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  textChanged() {
    this.params.context.parentComponent.selectedColumn = this.params.colDef.field;
    // console.log('Text Changed', this.textValue);
  }

  keyDown(event, params: ICellEditorParams) {
    // event.key === 'Enter'  || 
    if (event.key === KEY_ENTER) {
      this.keyEnterMethod(params, event.key);
      this.params.context.parentComponent.selectedColumn = params.colDef.field;
    }
    if (event.key === KEY_ESCAPE) {
      this.params.api.stopEditing();
    }
  }


  keyEnterMethod(key: any, keycode: string) {
    const rowIndex = keycode === KEY_UP ? key.rowIndex - 1 : key.rowIndex + 1;
    const firstCol = key.columnApi.getAllDisplayedColumns()[0];
    let Bool = true;
    key.api.forEachNode(function(rowNode: any, index: any) {
      if (index >= rowIndex && Bool) {
        key.api.ensureColumnVisible(firstCol);
        key.api.stopEditing();
        key.api.setFocusedCell(rowIndex, key.colDef.field);
        key.api.startEditingCell({
          rowIndex: rowIndex,
          colKey: key.colDef.field
        });
        Bool = false;
      }
    });
  }
  
}

const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';
const KEY_ENTER = 'Enter';
const KEY_ESCAPE = 'Escape';