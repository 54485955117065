import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import * as GlobalConst from 'src/app/shared/globalsContant';
import { TransactionDetails } from "../../Models/logViewer.model";

@Injectable({
  providedIn: "root"
})

export class PerfomanceBussines{
    captions: any;
    constructor(private localization:Localization, public http: HttpServiceCall){
        this.captions = this.localization.captions;
    }



    async callAPIGetAllPerformance(filterData, seconds) {
      let uriParams = {
        startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
        endDate:   this.localization.ConvertDateToISODateTime(filterData.todateValue),
        seconds: seconds
      };
      let response = await this.http.CallApiAsync<TransactionDetails>({
        host: GlobalConst.Host.spaManagement,
        callDesc: "GetTransactionsByPerformance",
        uriParams: uriParams,
        method: HttpMethod.Get
      });
      return response.result;
    }
}