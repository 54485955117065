import { HttpServiceCall, HttpMethod } from "./service/http-call.service";
import { Host } from "./globalsContant"; 
import { BaseResponse } from "./business/shared.modals";

export abstract class SpaFormAgent {
    constructor(public http: HttpServiceCall) { }

    makeGetCall<T>(routeURL: string,host:Host, extraParams?: any, uriParams?) {
        this.http.CallApiWithCallback<T[]>({
            host: host,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: routeURL,
            method: HttpMethod.Get,
            showError: true,
            uriParams: uriParams,
            extraParams: [extraParams]
        });
    }

    makePutCall<T>(routeURL: string,host:Host, body: T, extraParams?: any) {
        this.http.CallApiWithCallback<boolean>({
            host: host,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: routeURL,
            method: HttpMethod.Put,
            body: body,
            showError: true,
            extraParams: []
        });
    }

    makeDeleteCall<T>(routeURL: string, extraParams?: any) {
        this.http.CallApiWithCallback({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: routeURL,
            method: HttpMethod.Delete,
            showError: true,
            extraParams: []
        });
    }

    makePostCall<T>(routeURL: string, body: T, extraParams?: any) {
        this.http.CallApiWithCallback<any>({
            host: Host.schedule,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: routeURL,
            body: body,
            method: HttpMethod.Post,
            showError: false,
            extraParams: []
        });
    }

    abstract successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void;
    abstract errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void;
}