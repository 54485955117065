import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';

@Component({
  selector: 'app-appointment-listing',
  templateUrl: './appointment-listing.component.html',
  styleUrls: ['./appointment-listing.component.scss']
})
export class AppointmentListingComponent implements OnInit {
  appointmentListingFrmgrp: UntypedFormGroup;
  captions: any;
  @Output() formReady = new EventEmitter();
  radioFilter = [];
  constructor(private fb: UntypedFormBuilder
    , private PropertyInfo: SpaPropertyInformation
    , public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
  }

  ngOnInit() {
    let config = this.PropertyInfo.AppointmentConfigurations();
    this.appointmentListingFrmgrp = this.fb.group({
      ExcludeAppointmentComments: (config["EXCLUDE_APPOINTMENT_COMMENTS"]=='true' || config["EXCLUDE_APPOINTMENT_COMMENTS"]==true) ?true:false
    });

    this.formReady.emit(this.appointmentListingFrmgrp);
  }
}
