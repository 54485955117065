import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CardInfo } from '../business/shared.modals';

@Component({
  selector: 'app-carddetails-popup',
  templateUrl: './carddetails-popup.component.html',
  styleUrls: ['./carddetails-popup.component.scss']
})
export class CarddetailsPopupComponent implements OnInit {
  caption: any;
  commonCaption:any;
  CurrentActiveCard: CardInfo;
  IsExpireDateVisible: boolean = false;
  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _retailLocalization: RetailLocalization,
    private _commonLocalization: Localization) { }

  ngOnInit(): void {
    this.caption = this._retailLocalization.captions;
    this.commonCaption = this._commonLocalization.captions;
    this.CurrentActiveCard = this.data?.CurrentActiveCard;
    this.IsExpireDateVisible = this.data?.IsExpireDateVisible;
    this.CurrentActiveCard.cardExpiration = this.IsExpireDateVisible ? this.formatCreditCardExpiryDate(this.CurrentActiveCard.cardExpiration) : '';
  }

  formatCreditCardExpiryDate(date: string): string {
    if (date && date.length === 6) {
        return date.substring(4, 6) + '/' + date.substring(2, 4); // MM/YY
    }
    return date;
}
  validateSave() {
    return true;
  }

  onCancelClick() {
    this.dialogRef.close({ action: 'no' });
  }
  save() {
    this.dialogRef.close({ action: 'yes' });
  }

}
