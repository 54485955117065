export interface NotificationActions {
  id: number;
  name: string;
  description: string;
  type: number;
}

export interface NotificationKeyword {
  id: number;
  name: string;
  description: string;
  groupId: number;
  groupNumber: string;
  isExpanded: boolean;
}
export interface NotificationTemplate {
  id: number;
  eventIds: number[];
  templateId: number;
  isDefault: boolean;
  isActive: boolean;
  listOrder: number;
  templateCode: string;
  templateName: string;
  typeId: number;
  eventName: string;
  checked?: boolean;
  notificationTemplateContents? :NotificationTemplateContents[]
}

export interface NotificationAPITemplate {
  id: number;
  eventIds: number[];
  templateId: number;
  isdefault: boolean;
  isactive: boolean;
  listorder: number;
  templateCode: string;
  templateName: string;
  typeId: number;
}

export interface NotificationTemplateAPI {
  Id: number;
  EventId: number;
  TemplateId: number;
  IsDefault: boolean;
  IsActive: boolean;
  ListOrder: number;
  TemplateCode: string;
  TemplateName: string;
  TypeId: number;
}

export interface TemplateData {
  templateName: string;
  templateCode: string;
  templateType: number;
  isDefault: boolean;
  isActive: boolean;
  templateAsset: TemplateAsset[];
  htmlContent: string;
  templateId: number;
  listOrder: number;
  notificationTemplateId: number;
  isTemplateInUse: boolean;
  notificationTemplateContents? :NotificationTemplateContents[];
}
export interface NotificationTemplateContents{
  notificationTemplateId :number;
  languageId :number;
  templateId :string
}

export interface TemplateAsset {
  contentType: string;
  fileName: string;
  keyName: string;
  imageData: string;
}

export interface TemplateDomainData {
  eventId: number[];
  templateData: TemplateData;
}

export enum NotificationType {
  EMAIL = 1,
  SMS,
  WHATSAPP
}

export enum NotificationEvents {
  IntakeForm = 17,
  Eform = 75,
  EFormGolf = 76
}

export interface Language {
  id: number;
  value: string;
  viewValue: string;
  isDisabled:boolean;
}
