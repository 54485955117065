/*
  NOTES TO DEVELOPER
  *****This page for ui developement only, In case any change in this page without authurization will affect UI*****
 */
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormIcons } from '../../form-type.entity';
@Component({
  selector: 'app-form-icon',
  templateUrl: './form-icons.component.html',
  styleUrls: ['./form-icons.component.scss']
})
export class FormIconsComponent implements OnInit, OnChanges {
  @Input() formIconType: FormIcons;
  @Input() formObject: any;
  @Input() fieldArray: any;
  @Input() formArray: any;
  @Input() objectIndex: any;
  @Output() iconEmitter = new EventEmitter();
  @Output() fielArrayEmiter = new EventEmitter();
  tooltipValue: string;
  constructor() { }

  eventClick(event: any): void {
    this.iconEmitter.emit({
        formArray: this.formArray,
        formObject: this.formObject,
        objectIndex: this.objectIndex,
        iconType: this.formIconType
      });
    this.fielArrayEmiter.emit(this.formObject);
  }
  ngOnChanges(): void {
    console.log('e');
  }

  ngOnInit(): void{
    this.tooltipValue = this.formIconType.type.charAt(0).toUpperCase() + this.formIconType.type.slice(1);
  }
}