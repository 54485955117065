import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'lib-dynamic-dropdown',
  templateUrl: './dynamic-drodown.html',
  styleUrls: ['./dynamic-dropdown.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicDropDownComponent implements OnInit {
  params: any;
  value: any;
  defaultValue: number;
  dropdown: FormControl = new FormControl('');
  dropdownOptions: any;
  prevUnitTypeId: any;
  logicalProperty: Array<any> = [];
  constructor() { }

  ngOnInit(): void {
  }
  setDefaultProperty(ArrayCollection: Array<any>): number {
      try {
       let returnValue = null;
       if ( ArrayCollection && ArrayCollection.length > 0 ) {
            const GetFillterId = ArrayCollection[0].Id;
            if ( GetFillterId) {
                returnValue = GetFillterId;
            }
       }
       return returnValue;
      } catch (error) {
         console.error(error);
      }
  }

  agInit(params: any) {
    this.params = params;
    const dropdownField = params['DropdownValueProperty'];
    this.dropdownOptions = this.params.data[dropdownField];
    const defaultValueProperty = (params.hasOwnProperty('DefaultValueProperty')) ? params.DefaultValueProperty : 'Noprop';
    this.defaultValue =  ( params.data.hasOwnProperty(defaultValueProperty) && params.data[defaultValueProperty] !== null) ?
    parseInt(params.data[defaultValueProperty], 10)  : (defaultValueProperty === 'Noprop' ? 
    parseInt(params.data[this.params.ApiAssignValue], 10) : this.setDefaultProperty(this.dropdownOptions));
    this.logicalProperty = params.hasOwnProperty('LogicalProperty') ? params.LogicalProperty : [];
    if ( this.params.hasOwnProperty('DefaultValueProperty')) {
      const DefaultValueSet =  this.params.DefaultValueProperty;
      this.params.data[DefaultValueSet] = this.defaultValue;
    }
    if ( this.params.hasOwnProperty('ApiAssignValue') && this.params.hasOwnProperty('DefaultValueProperty')) {
      const apiAssignValue =  this.params.ApiAssignValue;
      this.params.data[apiAssignValue] = this.defaultValue;
    }
    // if (this.params.hasOwnProperty('DefaultValueProperty')) {
      this.dropdown.setValue(this.defaultValue);
    // }
  }

  init(params: any) {
    this.dropdown.valueChanges.subscribe(res => {
      this.defaultValue = parseInt(res, 10);
      const rowNode = this.params.api.getRowNode(this.params.rowIndex);
      if ( this.logicalProperty &&  this.logicalProperty.length > 0) {
        this.logicalProperty.forEach((element: any) => {
               if ( element.key === 'BatValueUpdate' ) {
                const  updatedCellKey =  element.UpdatedCellKey;
                const value = this.defaultValue * 1;
                rowNode.setDataValue(updatedCellKey, value);
               }
            });
      }
    });
  }

  dropdownChangeEvent = (event: any) => {
     const GetTaragetValue = event.target.value;
     const oldValue = this.params.data[this.params.ApiAssignValue];
      const rowNode = this.params.api.getRowNode(this.params.rowIndex);
      this.defaultValue = parseInt(GetTaragetValue, 10);
      this.logicalProperty.forEach((element: any) => {
        if ( element.key === 'BatValueUpdate' ) {
         const  updatedCellKey =  element.UpdatedCellKey;
         const GetBlanketValue = this.dropdownOptions.filter((e: any) => e.Id ===  this.defaultValue);
         if ( GetBlanketValue && GetBlanketValue.length > 0 ) {
              const GetBlaQty = GetBlanketValue[0].BatchSizeQuantity;
              const updateValue = GetBlaQty * this.params.data.Qty;
              rowNode.setDataValue(updatedCellKey, updateValue);
         }
        }
     });
     if ( this.params.hasOwnProperty('DefaultValueProperty')) {
           const DefaultValueSet =  this.params.DefaultValueProperty;
           this.params.data[DefaultValueSet] = GetTaragetValue;
      }
      if ( this.params.hasOwnProperty('ApiAssignValue')) {
        const apiAssignValue =  this.params.ApiAssignValue;
        this.params.data[apiAssignValue] = GetTaragetValue;
   }
   if (this.params.hasOwnProperty('enableFormatter')) {
    this.params.colDef.valueFormatter(this.params, oldValue, this.params.data[this.params.ApiAssignValue]);
   }
  }

  focusIn() {
    const selectEditor = document.getElementById('dynamic-' + this.params.colDef.field);
    if (selectEditor) {
      selectEditor.focus();
    }
  }

  getValue(): any {
   const GetDisplayCollection = this.dropdownOptions ? this.dropdownOptions.filter(x => x.Id === this.defaultValue) : '';
   if ( GetDisplayCollection && GetDisplayCollection.length > 0 ) {
        const GetDisplayName = GetDisplayCollection[0].Name;
        return ( GetDisplayName ) ? GetDisplayName : '';
   }
  }

  isCancelAfterEnd(): boolean {
    return false;
  }
}
