import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridFilterData, TransactionConfiguration } from '../transaction.interface';
import { GridFilterConfiguarion, ViewGridConfiguration } from './grid-transaction-config.interface';
import _ from 'lodash';
import moment from 'moment';
export const GridClientFilter: GridFilterConfiguarion = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, 
    TilesHeaderTrascationEntity ?: any, 
    responseData ?: Array<any>, 
    GridFilterDatas ?: Array<any> ): Array<any> => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection && responseData) {
                const responseList = [];
                const FilterList = [];
                let filterId = [];
                let filterKey = '';
                GridFilterDatas[0].forEach((x: GridFilterData ) => {
                    const getFiltervalue = x.FilterValue.filter( f => f.Checked).map( e => e.FilterId);
                    filterId = getFiltervalue;
                    filterKey = (x.FilterKey === 'Type' || x.FilterKey === 'Ingredient Types' || x.FilterKey === 'Recipe Types' ?
                         'TypeId' : (x.FilterKey === 'Class' ? 'ClassId' : (x.FilterKey === 'Status') ? 'IsActive' : ''));
                    const object = {
                        FilterId : filterId,
                        FilterKey: filterKey,
                        AdditionalFilterCheck: x.AdditionalFilterCheck,
                        FilterType: x.Type,
                        FilterValue: x.FilterValue
                    };
                    if (filterId.length > 0) {
                        FilterList.push(object);
                    }
                });
                if (FilterList.length > 0) {
                    FilterList.forEach(x => {
                        if (x.hasOwnProperty('AdditionalFilterCheck') && x.AdditionalFilterCheck) {
                            // responseData = responseData.filter(function (e) {
                            //     return (x.FilterId.includes(e[x.FilterKey] && e[x.AdditionalFilterCheck.AdditionalFilterKey]
                            //         === x.AdditionalFilterCheck.AdditionalFilterValue));
                            // });
                            responseData = responseData.filter(aa =>  x.FilterId.includes(aa[x.FilterKey]) && 
                                aa[x.AdditionalFilterCheck.AdditionalFilterKey] === x.AdditionalFilterCheck.AdditionalFilterValue);
                        } else {
                            if (x.FilterType === 'daterange') {
                                // For Scheduled Report date filter
                                let startDate = '';
                                let endDate = '';
                                let dateFilteredArray = [];
                                x.FilterValue.forEach((dateValue: any) => {
                                    if (dateValue.FilterName === 'LastRunTime') {
                                        startDate = moment(dateValue.Value).format('MM-DD-YYYY');
                                    }
                                    if (dateValue.FilterName === 'NextRunTime') {
                                        endDate = moment(dateValue.Value).format('MM-DD-YYYY');
                                    }
                                });
                                responseData.forEach((datecheck: any) => {
                                    const lastrunTime = moment(datecheck['LastRunTime']).format('MM-DD-YYYY');
                                    const nextrunTime = moment(datecheck['NextRunTime']).format('MM-DD-YYYY');
                                    if (moment(lastrunTime).isSameOrAfter(startDate) && 
                                    moment(nextrunTime).isSameOrBefore(endDate)) {
                                        dateFilteredArray.push(datecheck);
                                    }
                                });
                                responseData = [...dateFilteredArray];
                                return responseData;
                            } else {
                                responseData = responseData.filter(function (e) {
                                    return x.FilterId.includes(e[x.FilterKey]);
                                });
                            }
                        }
                    });
                    responseList.push(...responseData);
                } else {
                    return responseData;
                }
                
                return responseList;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };