import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ButtonValue, DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization'
import { FailurePopupComponent } from './failure-popup/failure-popup.component';
import { ImportBusiness } from './import.business';
import { UI, API } from 'src/app/common/Models/datautilitydetails-model';
import * as XLSX from 'xlsx';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { DownloadTemplateComponent } from './download-template/download-template.component';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
  providers: [ImportBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ImportComponent implements OnInit {
  @ViewChild('excelFile', { static: false })
  inputFile: ElementRef;

  importform: UntypedFormGroup;
  captions;
  moduleOptions: DropdownOptions[];
  importButton: ButtonValue;
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent: UI.DataUtilityDetails[];
  tableContentByScreen: UI.DataImportDetails[];
  originalData: Observable<UI.DataUtilityDetails[]>;
  moduleName: string;
  isFileUpload = false;
  type = '.xls, .xlsx';
  isdisabled = true;
  excelImport = import('xlsx');
  isViewOnly: boolean = false;
  isAllow : boolean = false;
  downloadButton: ButtonValue;
  data : any;

  constructor(private fb: UntypedFormBuilder, private localization: Localization, private business: ImportBusiness,
    @Optional() private dialogRef: MatDialogRef<any>, @Optional() @Inject(MAT_DIALOG_DATA) private dialogData,
    private dialog: MatDialog, private utils: CommonUtilities) {
      this.data = this.dialogData;
    }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.buildForm();
    this.initializeInputs();
    if(this.data?.from == 'room'){      
      this.isAllow = true;
    }
    else{
      this.validateBreakPoints();
    }
  }
  close(){
    this.dialogRef.close()
  }
  buildForm() {
    this.importform = this.fb.group({
      module: ''
    })
  }

  async downloadExcel(e, element, key, idx) {
    await this.business.download(element.id,element.fileName);
  }

  async initializeInputs() {
    this.downloadButton = {
      label: this.captions.btn_download_template,
      type: 'primary',
      disabledproperty: false
    };
    this.moduleOptions = await this.business.getModuleOptions();
    this.importform.get('module').setValue(this.moduleOptions[0]?.id);
    this.moduleName = this.moduleOptions.find(x => x?.id == this.moduleOptions[0]?.id)?.value;
    this.isdisabled = false;
    this.generateTable();
  }

  generateTable() {
    if(this.data?.from == 'room')
    {
      this.headerOptions = this.business.getHeaderOptionsByrScreenImport();
      this.tableOptions = this.business.getTableOptionsByScreenImport(); 
      this.utils.ToggleLoader(true);
      this.business.getAllImportByScreenEntries(this.moduleName,'Room Definition',true).then(x => {
        this.tableContentByScreen = x;
        this.utils.ToggleLoader(false);
      })
    }
    else
    {
    this.headerOptions = this.business.getHeaderOptions();
    this.tableOptions = this.business.getTableOptions();
    this.utils.ToggleLoader(true);
    this.business.getAllEntries(this.moduleName, API.DataUtilityType.Import).then(x => {
      this.tableContent = x;
      this.utils.ToggleLoader(false);
    })
  }
  }

  moduleChange(e) {
    this.validateBreakPoints();
    this.moduleName = this.moduleOptions.find(x => x.id == e?.value)?.value;
    this.isdisabled = false;
    this.importform.markAsPristine();
    this.generateTable();
  }

  onSyncClick() {
    this.generateTable();
  }

  onTableAction(e) {
  }

  async openPopup(e, element, key, idx) {
    let errors = await this.business.getDataUtilityErrors(element.id);
    const dialogRef = this.dialog.open(FailurePopupComponent, {
      width: '80%',
      height: '88%',
      panelClass: 'view-account-dialog',
      data:{
              errors: errors,
              elementId: element.id.toString(),
              elementFileName: element.fileName.toString(),
              isShow: true
            }
    });
    dialogRef.afterClosed().subscribe(res => {

    });
  }

  private async validateBreakPoints()
  {
    await this.business.ValidateBreakPoints();
    this.disableControls();
  }

   private disableControls() {
    this.isViewOnly = this.business.isViewOnly;
    this.isAllow =this.business.isAllow;
    if(this.isAllow)
    {
    this.isdisabled = false;
    }
    else{
    this.isdisabled = true;
    }

  }

  fileHandler(arg) {
    switch (this.type) {
      case '.xls, .xlsx':
        this.parseExcel(arg[0]);
        break;
    }
  }

  reset() {
    this.inputFile.nativeElement.value = "";
  }

  parseExcel(file) {

    const reader = new FileReader();
    const Excelreader = new FileReader();
    var fileByteArray = [];
    Excelreader.onload = (fileevent) => {
          var arrayBuffer = fileevent.target.result;
          fileByteArray.push(arrayBuffer);
    };
    Excelreader.readAsArrayBuffer(file);

    reader.onload = (event) => {
      const data = reader.result;

      let workBook = XLSX.read(data, {
        // type: "binary", cellDates: true, dateNF: 'mm/dd/yyyy hh:mm;@'
        type: "binary", cellDates: false, cellNF: false
      });

      let dataUtilityImportData: API.DataUtilityImportData[] = [];
      let totalRows = 0;
      workBook.SheetNames.forEach(function(sheetName)
      {
        let jsonData = {};
        const sheet = workBook.Sheets[sheetName];
        // var range = XLSX.utils.decode_range(sheet['!ref']);
        // let totalRowsPerSheet = range.e.r;
        // totalRows += totalRowsPerSheet;
        jsonData[sheetName] = XLSX.utils.sheet_to_json(sheet, { raw: false});
        let dataString = JSON.stringify(jsonData);
        let importData: API.DataUtilityImportData = {
          importData : dataString,
          //totalRows : totalRowsPerSheet,
          totalRows : 0,
          excelSheetName : sheetName
        };
        dataUtilityImportData.push(importData);
      });
       var base64String =btoa(new Uint8Array(fileByteArray[0]).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
    }, ''));
      let dataUtilityDetails: API.DataUtilityDetails = {
        id: 0,
        fileName: file.name,
        moduleName: this.moduleName,
        dataUtilityImportData: dataUtilityImportData,
        status: API.Status.Queued,
        utilityType: API.DataUtilityType.Import,
        // totalRows: totalRows,
        totalRows: 0,
        completedRows: 0,
        dataUtilityExportData: [],
        base64ExportFileData : base64String,
      };

      this.business.postDataUtilityDetails(dataUtilityDetails, API.DataUtilityType.Import).then(x => {
        this.tableContent = x;
      })
      this.reset();

    };
    reader.readAsBinaryString(file);
  }

  async downloadTemplate() {
    const dialogRef = this.dialog.open(DownloadTemplateComponent, {
      width: '80%',
      height: '88%',
      data: ''
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res && res.dataUtilityDetails != null && res.dataUtilityDetails != undefined)
      {
        this.tableContent = res.dataUtilityDetails;
      }
    });
  }
}
