import { FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { OrderSetting, OrderSummary, PostPOSupplierConfig, SalesOthersSummary, TransactionConfiguration } from '../transaction.interface';
import { PostPo, SalesMediaAssMent } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopModel, SuggestQuantityPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { CustomValidator } from 'src/app/eatecui/source/shared/models/form-models/form-validation';
import { FormType, SingleField } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';
import { ConversionAlgorithm } from 'src/app/eatecui/source/shared/algorithms';
import { FormFieldType } from 'src/app/eatecui/source/setup-master/shared/interface/form-interface/fieldType.enum';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { RequestDataModel } from 'src/app/eatecui/source/shared/nonodatacore/models/requestdata.interface';

export const QOOrderSetting: SalesMediaAssMent = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    homeGroup: FormGroup,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    serviceCharges: Array<any>,
    salesService?: any,
    commonService?: any
) => {
    try {
        
        let customerId = homeGroup.controls['CustomerId'].value;
        customerId = customerId && customerId.Id ? customerId.Id : customerId;
        const UserId = sessionStorage.getItem('UserId') ? 
        parseInt(JSON.parse(sessionStorage.getItem('UserId')), 10) : 1;
        const LoggedInSiteId = sessionStorage.getItem('LoggedInSiteId') ? 
        parseInt(JSON.parse(sessionStorage.getItem('LoggedInSiteId')), 10) : 1;
        const LocationIds = JSON.parse(sessionStorage.getItem('FromLocId')) ?
        JSON.parse(sessionStorage.getItem('FromLocId')) : [];
        const payload = {} as RequestDataModel;
        payload.FetchAll = true;
        payload.LocationIds = LocationIds;
        payload.userId = UserId;
        payload.siteId = LoggedInSiteId;
        payload.GetTotalRecCount = true;
        payload.IsActiveOnly = true;
        payload.LookupField = '';
        payload.SearchText = '';
        payload.CustomerIds = [];
        payload.totalCount = 0;
        payload.orderByColumn = "Name";
        payload.orderByDirection = 1;
        payload.Skip = 0;
        payload.Take = 100;
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ItemOrderSetting')) {
            const payTermsApiUrl = `/inventory/api/GetPayTerms`;
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields[0].fieldType.SingleData.options = [];
            httpService.PosthttpMethod(payTermsApiUrl, payload).subscribe(res => {
                const Response = res['ResultData'];
                Response.forEach((element: any) => {
                    const options = {
                        key : element.Id,
                        label: element.Name
                    };
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields[0].fieldType.SingleData.options.push(options);
                });
            });
            payload.CustomerIds = customerId && customerId > 0 ?[customerId]: [];
            payload.TransTypeId = TransactionCollection.TransactionType;
            const ServiceChargesUrl = `/inventory/api/GetServiceCharges`;
            httpService.PosthttpMethod(ServiceChargesUrl, payload).subscribe(res => {
                const Response = res['ResultData'];
                serviceCharges = Response;
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields[1].fieldType.SingleData.options = [];
                Response.forEach((element: any) => {
                    const options = {
                        key : element.Id,
                        label: element.Name
                    };
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields[1].fieldType.SingleData.options.push(options);
                });
                const defaultValue = res['ResultData'].filter(item => item.IsDefault).length ? res['ResultData'].filter(item => item.IsDefault)[0].Id : '';
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields[1].fieldType.SingleData.value = homeGroup.value['QuickOrderSettings'].ServiceChargeId ?
                homeGroup.value['QuickOrderSettings'].ServiceChargeId : defaultValue ? defaultValue : '';
            });
            const GetCustomersUrl = `/inventory/api/GetCustomers`;
            
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields.forEach(field => {
                if (field.type === 'number') {
                    field.fieldFormaters = {
                        EnableDecimalFormatter: true,
                        WholeNumberLength: field.columnType === 'decimal' ? commonService.GetWholeNumberLength() : commonService.GetWholeNumberLength(true),
                        VisibleDecimal: CommonService.GetCurrencyVisible(),
                        FocusDecimal: field.columnType === 'decimal' ? commonService.GetFocusNumberLength() : commonService.GetFocusNumberLength(true)
                    };
                    field.fieldType.SingleData.FieldValidation = [
                        { validation: CustomValidator.onlyCheckNumber , validationMessage: 'Enter only number', key: 'onlyCheckNumber'},
                        { validation: null, validationMessage: 'Invalid discount %', key: 'invaliddiscountrange'}
                    ];
                }
            });
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields.forEach(field => {
                field.fieldType.SingleData.onCellClick  = ( e: any, field: any, form: any ) => {
                    const DiscountPercentage = form.value['Discount%'];
                    if (DiscountPercentage > 100 || DiscountPercentage < 0) {
                        form.controls['Discount%'].setErrors({ invaliddiscountrange: true, incorrect: true, message: 'Invalid discount percentage.'});
                    } else if (DiscountPercentage < 101 || DiscountPercentage > 0 ) {
                        const currencyDecimallength = commonService.GetWholeNumberLength(true);
                        const grandTotal = transactionService.calculateValues([],
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData, null, false);
                        let subTotalValue = 0;
                        let totalTaxValue = 0
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach((gridData: any) => {
                            subTotalValue += parseFloat(gridData.Valued);
                            if (!gridData.TaxValue) {
                                gridData.TaxAmount1 = gridData.TaxAmount1 ? gridData.TaxAmount1 : 0;
                                gridData.TaxAmount2 = gridData.TaxAmount2 ? gridData.TaxAmount2 : 0;
                                const rowtotaltax = parseFloat(gridData.TaxAmount1) + parseFloat(gridData.TaxAmount2);
                                totalTaxValue += rowtotaltax;
                                gridData.TaxValue = parseFloat((rowtotaltax !== null ? rowtotaltax : 0).toString());
                            } else {
                                totalTaxValue += (gridData.TaxValue ? parseFloat(gridData.TaxValue) : 0);
                            }
                        });
                        const serviceChargeId = form['controls']['ServiceCharge'].value;
                        let serviceChargesamount = 0;
                        let serviceTax = 0;
                        if (serviceCharges && serviceCharges.length > 0) {
                            const FileterServiceCharge: Array<any> = serviceCharges.filter(value => value.Id === serviceChargeId);
                            if (FileterServiceCharge && FileterServiceCharge.length > 0) {
                                serviceChargesamount = FileterServiceCharge[0].Rate * (parseFloat(subTotalValue.toString())+ parseFloat(totalTaxValue.toString())) / 100;
                                serviceTax = serviceChargesamount * FileterServiceCharge[0].TaxRates.Rate / 100;
                            }
                        }
                        const DiscountAmount = (subTotalValue + serviceChargesamount + totalTaxValue + serviceTax) * DiscountPercentage / 100;
                        form['controls']['Discount$'].setValue(parseFloat(DiscountAmount.toString()).toFixed(currencyDecimallength));
                        CommonService.triggerBlurEvent('Discount$');
                        form.updateValueAndValidity();
                    }
                }
            })
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields.forEach(fields => {
                switch(fields.name) {
                    case 'Terms': 
                        fields.fieldType.SingleData.value = homeGroup.value['QuickOrderSettings'].PayTermId ? 
                        homeGroup.value['QuickOrderSettings'].PayTermId : fields.fieldType.SingleData.value ? fields.fieldType.SingleData.value : '';
                        break;
                    case 'ServiceCharge': 
                        fields.fieldType.SingleData.value = homeGroup.value['QuickOrderSettings'].ServiceChargeId ?
                        homeGroup.value['QuickOrderSettings'].ServiceChargeId : '';
                        break;
                    case 'Discount%': 
                        fields.fieldType.SingleData.value = homeGroup.value['QuickOrderSettings'].Discount ?
                        homeGroup.value['QuickOrderSettings'].Discount : 0;
                        break;
                    case 'Discount$':                 
                        const DiscountAmount = homeGroup.value['QuickOrderSettings'].DiscountValue
                        fields.fieldType.SingleData.value = DiscountAmount ? DiscountAmount : 0;
                        break;
                    default: 
                        fields.fieldType.SingleData.value = homeGroup.value['QuickOrderSettings'].IsTaxChecked === true? 
                        homeGroup.value['QuickOrderSettings'].IsTaxChecked : false;
                        break;
                }
            })
            httpService.PosthttpMethod(GetCustomersUrl, payload).subscribe(res => {
                const Response = res['ResultData'];
                if(Response && Response.length === 1) {
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.FormFields[0].fieldType.SingleData.value = homeGroup.value['QuickOrderSettings'].PayTermId ? homeGroup.value['QuickOrderSettings'].PayTermId : Response[0].TermId
                }
                const DataInfo = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting;
                const SuggestQtyDialog: SuggestQuantityPopUpModel = {
                    PopupHeaderContent: DataInfo.PopupHeaderName,
                    FormFields: DataInfo.FormFields,
                    FormGroupData: DataInfo.FormGroupData,
                    Classname: 'Order-Setting',
                    Hide: transactionService.FlowMode === 'Copy' ? true : false
                };
                const dialogRef = dialog.open(AgilysysSuggestqtyPopupComponent, {
                    width: '60%',
                    height: 'auto',
                    data: SuggestQtyDialog,
                    maxWidth: '100vw',
                    panelClass: 'Order-settings',
                    hasBackdrop: true,
                    autoFocus: false,
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result[0] === 'Confirm') {
                        const FormData = result[1] as FormGroup;
                        let ReturnData = aasignPostObject(FormData);
                        homeGroup.controls[DataInfo.Name].setValue(ReturnData);
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemOrderSetting.PostData = ReturnData;
                        if (TransactionCollection.Name === 'quickorder') {
                            let Result = buildQOOrderSetting(FormData, serviceCharges, TransactionCollection, homeGroup.value['Id']);
                            homeGroup.controls['QuickOrderSummary'].setValue(Result);
                            transactionService.quickOrderSummaryData = homeGroup;
                        }
                        const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
                        const VisibleDigit = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
                        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength;
                        if (!FormData.value['Tax']){
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach((gridData: any) => {
                                gridData.TaxValue = 0;
                            });
                            TransactionCollection.CreateTranscationConfig = {...TransactionCollection.CreateTranscationConfig};
                            TransactionCollection = {...TransactionCollection};
                        }
                        const grandTotal = transactionService.calculateValues([],
                        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData);
                        TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailFormHeader[1].Value =
                        grandTotal.toFixed(VisibleDigit);
                        homeGroup.controls['Valued'].setValue(grandTotal.toFixed(VisibleDigit));
                        TransactionCollection.CreateTranscationConfig = {...TransactionCollection.CreateTranscationConfig};
                        TransactionCollection = {...TransactionCollection};
                    }
                });
            });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred Order Setting', Error);
    }
};
function aasignPostObject(FormData: FormGroup) {
    try {
        const QOOrderSettingDetails = {} as OrderSetting;
        if (FormData.value) {
            QOOrderSettingDetails.Discount = FormData.value['Discount%'] !== ''? FormData.value['Discount%'] : 0;
            QOOrderSettingDetails.Id = 0;
            QOOrderSettingDetails.IsInvoiceChecked = false;
            QOOrderSettingDetails.IsOrderChecked = false;
            QOOrderSettingDetails.IsTaxChecked = FormData.value['Tax'];
            QOOrderSettingDetails.DiscountValue = FormData.value['Discount$'] !== ''? FormData.value['Discount$'] : 0;;
            QOOrderSettingDetails.Email = '';
            QOOrderSettingDetails.PayTermId = FormData.value['Terms'] !== '' ? FormData.value['Terms'] : null;
            QOOrderSettingDetails.SendToDeviceId = null;
            QOOrderSettingDetails.ServiceChargeId = FormData.value['ServiceCharge'] !== '' ? FormData.value['ServiceCharge'] : null;
            QOOrderSettingDetails.OrderFormName = null;
            QOOrderSettingDetails.QuickOrderHeadId = 0;
            QOOrderSettingDetails.InvoiceFormName = null;
        }
        return QOOrderSettingDetails;
    } catch (error) {
        console.error('Error occurred in aasignPostObject', error);
    }
}
function buildQOOrderSetting(FormData: FormGroup, serviceCharges: Array<any>, TransactionCollection: any, Id: any) {
    try{
        let quickOrderSummary = TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData ? 
        TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData['QuickOrderSummary'] : [];
        let subTotalValue = 0;
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach((gridData: any) => {
            subTotalValue += parseFloat(gridData.Valued.toString());
        });
        const serviceChargeId = FormData.value['ServiceCharge'];
        let serviceChargesamount = 0;
         let accountId = null;
        if( serviceCharges && serviceCharges.length > 0 ){
            const FileterServiceCharge: Array<any> = serviceCharges.filter( value => value.Id === serviceChargeId);
            if(FileterServiceCharge && FileterServiceCharge.length > 0 ) {
                serviceChargesamount = FileterServiceCharge[0].Rate * parseFloat(subTotalValue.toString()) / 100;
                accountId = FileterServiceCharge[0].SalesCreditAccId;
                CommonService.ServiceChargeRate = FileterServiceCharge[0].Rate;
            }
        }
        
        if (quickOrderSummary && quickOrderSummary.length > 0) {
            let serviceChargeIndex = -1;
            let discountIndex = -1;
            quickOrderSummary.forEach((x: any, index: number) => {
               if (x.QOSummaryLinkTypeId === 3){
                serviceChargeIndex = index;
               } else if (x.QOSummaryLinkTypeId === 6) {
                discountIndex = index;
               }
            });
            if (serviceChargeIndex > -1) {
                quickOrderSummary[serviceChargeIndex].Valued = serviceChargesamount;
                quickOrderSummary[serviceChargeIndex].LinkItemId = FormData.value['ServiceCharge'];
            } 
            if (discountIndex > -1) {
                quickOrderSummary[discountIndex].Valued = FormData.value['Discount$'];
            } 
            if ( serviceChargeIndex === -1 &&  discountIndex === -1 ) {
                const entities = ['Discount$', 'ServiceCharge'];
                entities.forEach((x: any) => {
                    const obj = {} as OrderSummary;
                    obj.Id = 0;
                    obj.QuickOrderHeadId = Id;
                    obj.TrnType = x === 'ServiceCharge' ? 2 : 1;
                    obj.LinkItemId = x === 'ServiceCharge' && !Number.isNaN(parseFloat(FormData.value[x])) ? FormData.value[x]: 0;
                    obj.QOSummaryLinkTypeId = x === 'ServiceCharge' ? 3 : 6;
                    obj.AccId = x === 'ServiceCharge' ? accountId : null;
                    obj.Valued = x === 'ServiceCharge' ? parseFloat(serviceChargesamount ? serviceChargesamount.toString() : '0').toFixed(4) : 
                    parseFloat(FormData.value[x] ? FormData.value[x].toString() : '0').toFixed(4);
                    quickOrderSummary.push(obj);
                });
            } else if ( serviceChargeIndex === -1) {
                const entities = ['ServiceCharge'];
                entities.forEach((x: any) => {
                    const obj = {} as OrderSummary;
                    obj.Id = 0;
                    obj.QuickOrderHeadId = Id;
                    obj.TrnType = x === 'ServiceCharge' ? 2 : 1;
                    obj.LinkItemId = x === 'ServiceCharge' && !Number.isNaN(parseFloat(FormData.value[x])) ? FormData.value[x]: 0;
                    obj.QOSummaryLinkTypeId = x === 'ServiceCharge' ? 3 : 6;
                    obj.AccId = x === 'ServiceCharge' ? accountId : null;
                    obj.Valued = x === 'ServiceCharge' ? parseFloat(serviceChargesamount ? serviceChargesamount.toString() : '0').toFixed(4) : 
                    parseFloat(FormData.value[x] ? FormData.value[x].toString() : '0').toFixed(4);
                    quickOrderSummary.push(obj);
                });
            } else if ( discountIndex === -1 ) {
                const entities = ['Discount$'];
                entities.forEach((x: any) => {
                    const obj = {} as OrderSummary;
                    obj.Id = 0;
                    obj.QuickOrderHeadId = Id;
                    obj.TrnType = x === 'ServiceCharge' ? 2 : 1;
                    obj.LinkItemId = x === 'ServiceCharge' && !Number.isNaN(parseFloat(FormData.value[x])) ? FormData.value[x]: 0;
                    obj.QOSummaryLinkTypeId = x === 'ServiceCharge' ? 3 : 6;
                    obj.AccId = x === 'ServiceCharge' ? accountId : null;
                    obj.Valued = x === 'ServiceCharge' ? parseFloat(serviceChargesamount ? serviceChargesamount.toString() : '0').toFixed(4) : 
                    parseFloat(FormData.value[x] ? FormData.value[x].toString() : '0').toFixed(4);
                    quickOrderSummary.push(obj);
                });
            }
        } else {
            const entities = ["Discount$", "ServiceCharge"];
            entities.forEach((x: any) => {
              const obj = {} as OrderSummary;
              obj.Id = 0;
              obj.QuickOrderHeadId = Id;
              obj.TrnType = x === "ServiceCharge" ? 2 : 1;
              obj.LinkItemId =
                x === "ServiceCharge" &&
                !Number.isNaN(parseFloat(FormData.value[x]))
                  ? FormData.value[x]
                  : 0;
              obj.QOSummaryLinkTypeId = x === "ServiceCharge" ? 3 : 6;
              obj.AccId = x === "ServiceCharge" ? accountId : null;
              obj.Valued =
                x === "ServiceCharge"
                  ? parseFloat(serviceChargesamount ? serviceChargesamount.toString() : '0').toFixed(4) : 
                  parseFloat(FormData.value[x] ? FormData.value[x].toString() : '0').toFixed(4);
              quickOrderSummary.push(obj);
            });
        }
        quickOrderSummary = buildCreateQuickOrderSummary(TransactionCollection, serviceCharges, FormData)
        return quickOrderSummary;
    } catch (error) {
        console.error('Error occurred in aasignPostObject', error);
    }
}

function buildCreateQuickOrderSummary(TransactionCollection: any, serviceCharges: any, FormData: any) {
    try {
        const quickOrderSummary = TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData ? 
        TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData['QuickOrderSummary'] : [];
        let serviceChargesamount = 0;
        let subTotalValue = 0;
        let totalTaxValue = 0;
        let ServiceChargesTaxId = 0;
        let serviceChargesTax = 0;
        const serviceChargeId = FormData.value['ServiceCharge'];
        TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach((gridData: any) => {
            subTotalValue += parseFloat(gridData.Valued.toString());
            if (!gridData.TaxValue) {
                gridData.TaxAmount1 = gridData.TaxAmount1 ? gridData.TaxAmount1 : 0;
                gridData.TaxAmount2 = gridData.TaxAmount2 ? gridData.TaxAmount2 : 0;
                const rowtotaltax = parseFloat(gridData.TaxAmount1) + parseFloat(gridData.TaxAmount2);
                totalTaxValue += rowtotaltax;
                gridData.TaxValue = parseFloat((rowtotaltax !== null ? rowtotaltax : 0).toString());
            } else {
                totalTaxValue += (gridData.TaxValue ? parseFloat(gridData.TaxValue) : 0);
            }
        });
        if( serviceCharges && serviceCharges.length > 0 ){
            const FileterServiceCharge: Array<any> = serviceCharges.filter( value => value.Id === serviceChargeId);
            if(FileterServiceCharge && FileterServiceCharge.length > 0 ) {
                serviceChargesamount = FileterServiceCharge[0].Rate * (parseFloat(subTotalValue.toString())+ parseFloat(totalTaxValue.toString())) / 100;
                if (FileterServiceCharge[0].TaxId1 > 0) {
                    serviceChargesTax = serviceChargesamount * FileterServiceCharge[0].TaxRates.Rate / 100;
                    ServiceChargesTaxId = FileterServiceCharge[0].TaxId1;
                }
            }
        }
        let TaxIndex = -1;
        if (quickOrderSummary) {
        quickOrderSummary.forEach((quickOrderSum: any, index: number) => {
            if (quickOrderSum.QOSummaryLinkTypeId === 2) {
            TaxIndex = index;
            } 
        });
        }
        let TaxValue = 0;
        const GridData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
        if (GridData && GridData.length) {
            GridData.forEach((x: any, index: any) => {
                if (x.hasOwnProperty('TaxValue')) {
                  TaxValue = TaxValue + (x.TaxValue ? parseFloat(x.TaxValue.toString()) : 0);
                } 
              });
        }
        if (TaxIndex > -1) {
            quickOrderSummary[TaxIndex].Valued = parseFloat(TaxValue.toString()) + serviceChargesTax;
            quickOrderSummary[TaxIndex].LinkItemId = ServiceChargesTaxId;
        } else if (TaxIndex === -1) {
            const object = {} as OrderSummary;
            object.Id = 0;
            object.QuickOrderHeadId = 0;
            object.TrnType = 2;
            object.LinkItemId = ServiceChargesTaxId;
            object.QOSummaryLinkTypeId = 2;
            object.AccId = null;
            object.Valued = parseFloat(serviceChargesTax.toString()).toFixed(4);
            quickOrderSummary.push(object);
        }
        return quickOrderSummary;
    } catch (error) {
        console.error(error);
    }
}
