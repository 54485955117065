import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class InventoryReport extends BaseReport {
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }
    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.InventoryReport,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: false,
            endDatePicker: false,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: "PORTRAIT"
        }; 
    }

    protected formReportParams(): ReportParams[] {
        let inventoryReportCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let categoryFilter: string[] = this.business.getSelectedCategoryCaptions(inventoryReportCustomFilterData);
        let toApi = this.localization.convertDateObjToAPIdate;
        return [
        { "pStartDate": toApi(inventoryReportCustomFilterData.startDate) },
        { "pEndDate": toApi(inventoryReportCustomFilterData.endDate) },
        { "pSubCategory1": categoryFilter[1] },
        { "pSubCategory2": categoryFilter[2] },
        { "pSubCategory3": categoryFilter[3] },
        { "pSubCategory4": categoryFilter[4] },
        { "pSubCategory5": categoryFilter[5] },
        { "pCategoryFilter": categoryFilter[0] },
        { "pPropertyName": this.propertyName },
        { "pDate" : this.printedDate }];
    }

    protected formURIParams(): ReportParams[] {
        return [];
    }

    protected formFilters() {

        let inventoryDetailCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let radioFilterId: number = this.reportFormGrp.controls['customFilterFormGrp'].value.itemFilter;
        let toApi = this.localization.convertDateObjToAPIdate;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = inventoryDetailCustomFilterData.timelineFrom;
            const toWildCard = inventoryDetailCustomFilterData.timelineTo;
            const fromIncrement = inventoryDetailCustomFilterData.timelineCountFrom;
            const toIncrement = inventoryDetailCustomFilterData.timelineCountTo;
            return {
                startDate: toApi(inventoryDetailCustomFilterData.startDate),
                endDate: toApi(inventoryDetailCustomFilterData.endDate),
                categoryIds: this.fillFilter(inventoryDetailCustomFilterData.category[0]),
                subCategory1Ids: this.fillFilter(inventoryDetailCustomFilterData.category[1]),
                subCategory2Ids: this.fillFilter(inventoryDetailCustomFilterData.category[2]),
                subCategory3Ids: this.fillFilter(inventoryDetailCustomFilterData.category[3]),
                subCategory4Ids: this.fillFilter(inventoryDetailCustomFilterData.category[4]),
                subCategory5Ids: this.fillFilter(inventoryDetailCustomFilterData.category[5]),
                reporttype: radioFilterId,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            };
        }
        return {
            startDate: toApi(inventoryDetailCustomFilterData.startDate),
            endDate: toApi(inventoryDetailCustomFilterData.endDate),
            categoryIds: this.fillFilter(inventoryDetailCustomFilterData.category[0]),
            subCategory1Ids: this.fillFilter(inventoryDetailCustomFilterData.category[1]),
            subCategory2Ids: this.fillFilter(inventoryDetailCustomFilterData.category[2]),
            subCategory3Ids: this.fillFilter(inventoryDetailCustomFilterData.category[3]),
            subCategory4Ids: this.fillFilter(inventoryDetailCustomFilterData.category[4]),
            subCategory5Ids: this.fillFilter(inventoryDetailCustomFilterData.category[5]),
            reporttype: radioFilterId,
        };
    }

    fillFilter(customFilterData: any): number[] {
        let ids: number[] = customFilterData.selectedCat.length === 0 ? [] : customFilterData.selectedCat.map(x => x.id);
        if(customFilterData.selectedCat.length === customFilterData.catArr.length && customFilterData.catArr.length > 0 && !this.retailLocalization.isFromJobScheduler){
            ids =[];
        }
        return ids;
    }
}


