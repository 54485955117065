import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const checkFormValue: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: string
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            if (FormGroupData.controls['Undistributed'] !== undefined) {
                const value = MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData[0];
                const creditTotal = (FormGroupData.value['CreditTotal'] !== null && FormGroupData.value['CreditTotal'] !== undefined) 
                ? FormGroupData.value['CreditTotal'] : '';
                const Balance = (value !== null && value !== undefined && value.Balance !== null && value.Balance !== undefined)
                    ? value.Balance : '';
                const allocamount = (value !== null && value !== undefined &&
                   value.AllocatedAmount  !== null && value.AllocatedAmount !== undefined) 
                    ? value.AllocatedAmount : '';
            
                const remaningUndistributed = creditTotal !== '' ? 
                              (creditTotal !== ''  ? parseFloat(creditTotal) : 0) 
                              - (allocamount !== ''  ? parseFloat(allocamount) : 0) : '';
                FormGroupData.controls['Undistributed'].setValue(remaningUndistributed);
                CommonService.triggerBlurEvent('Undistributed');
                // if (allocamount !== ''  && creditTotal !== ''  && Balance === parseFloat(allocamount) 
                //   && Balance === parseFloat(creditTotal)) {
                //     MasterDataCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
                //     if (x.buttonKey === 'confirm') {
                //       x.displayProperity = !FormGroupData.valid; 
                //     }    
                //   });
                // } else {
                //     MasterDataCollection.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach(x => {
                //     if (x.buttonKey === 'confirm') {
                //       x.displayProperity = true; 
                //     }    
                //   });
                // }
              }
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};