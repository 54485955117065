import { Injectable } from '@angular/core';
import { ComponentsLinkingService } from '../../dataservices/componentsLinking/components-linking.service';
import { PlayerCategory, TransactionType } from '../../enums/shared-enums';
import { Localization } from '../../localization/localization';
import { DropdownOptions } from '../../Models/ag-models';
import { PaymentMethod } from '../../shared/retail.modals';
import { PaymentMethods } from '../../shared/shared/business/shared.modals';
import { PaymentMethodValue } from '../../shared/shared/globalsContant';
import { CategoryArray, CompLinkform, ComponentAccessTypeLink, dropDownOptions, EmitCompLinkData } from '../component-linking-base/component-linking-base.model';

@Injectable()
export class ComponentLinkingBusiness {
  captions: any;
  dropDowndata: DropdownOptions[] = [];
  paymentMethods: PaymentMethod[]=[];

  constructor(private componentsLinkingService: ComponentsLinkingService
    , private localization: Localization) { }

  getDropDownValues(): Promise<DropdownOptions[]> {
    let data: DropdownOptions[] = [
      { id: 1, value: '1', viewValue: dropDownOptions.TRANSACTION_TYPE, disabled: false },
      { id: 2, value: '2', viewValue: dropDownOptions.PLAYER_CATEGORY, disabled: false },
      { id: 3, value: '3', viewValue: dropDownOptions.PAYMENT_METHODS, disabled: false },     
    ];
    this.dropDowndata = data;
    return Promise.resolve(data);
  }

  getBindData(): Promise<CompLinkform[]> {
    let data: CompLinkform[] = [
      { type: 1 },
      { type: 2 },
      { type: 3}
    ];
    return Promise.resolve(data);
  }
  FilterIdTechAndInactiveMethods(paymentMethods: PaymentMethod[]) {
    const IdTechMethodsandPendingSettlement = [PaymentMethods.IDTECH, PaymentMethods.V1GiftCardIdTech, PaymentMethods.ExternalGiftCardIdTech, PaymentMethods.AgilysysGiftCardIdTech, PaymentMethods.PendingSettlement];
    return paymentMethods.filter(x => !IdTechMethodsandPendingSettlement.includes(x.paymentTypeId)).filter(x => x.isActive);
  }

  async getInitialArrayData(transactionType:number = TransactionType.Sale, playerType: number = PlayerCategory.Player): Promise<CategoryArray[][]> {
    //please dont provide reference values
   
    let paymentMethodsArray: CategoryArray[] = [];
    if(this.paymentMethods.length === 0){
      this.paymentMethods = await this.componentsLinkingService.getPaymentMethods();
      this.paymentMethods = this.FilterIdTechAndInactiveMethods(this.paymentMethods);
      this.paymentMethods.forEach((method) => {
        let paymentMethodName = PaymentMethodValue[method.paymentTypeId];
        if (method.isDefault && method.paymentMethod == paymentMethodName) {
          method.paymentMethod = this.localization.captions.shop.paymentMethods[method.paymentTypeId];
        } else {
          method.paymentMethod = method.paymentMethod;
        }
      });
      
    }
    
    paymentMethodsArray = this.paymentMethods.map(x => {return {id: x.id, name: x.paymentMethod, checked: false}})
    let data: CategoryArray[][] = [
      [
        { id: 1, name: 'Sale', checked: false },
        { id: 2, name: 'Refund', checked: false }   
      ],
      [
        { id: 1, name: 'Player', checked: false },
        { id: 2, name: 'Hotel Reservation', checked: false },  
        { id: 3, name: 'Member', checked: false },   
        { id: 4, name: 'Existing Player', checked: false }
      ],
      paymentMethodsArray,
    ];
    data[0].forEach(x => {
      if(x.id === transactionType) 
        x.checked= true});
    data[1].forEach(x => {
          if(x.id === playerType) 
            x.checked= true});
    
    return Promise.resolve(data);
  }

  async getComponentAccessTypeLink(): Promise<ComponentAccessTypeLink[]>{
    return this.componentsLinkingService.getComponentAccessTypeLink();

  }
  async saveComponentAccessTypeLink(emitCompLinkData: EmitCompLinkData): Promise<boolean>{
    let APIData : ComponentAccessTypeLink[] = [];
    let selectedPaymentMethodIds : number[] = [] ; 
    let selectedTransactionTypeId: number =  emitCompLinkData.singleCategoryData[0].find(x => x.checked) ?emitCompLinkData.singleCategoryData[0].find(x => x.checked).id : 0 ;   
    let selectedPlayerCategoryId: number = emitCompLinkData.singleCategoryData[1].find(x => x.checked) ? emitCompLinkData.singleCategoryData[1].find(x => x.checked).id : 0  ;
    let masterTransactionTypeId: number = this.dropDowndata.find(x => x.viewValue === dropDownOptions.TRANSACTION_TYPE) ?  
                                      Number(this.dropDowndata.find(x => x.viewValue === dropDownOptions.TRANSACTION_TYPE).id) : 0; 
    let masterPlayerCategoryTypeId: number = this.dropDowndata.find(x => x.viewValue === dropDownOptions.PLAYER_CATEGORY) ?  
                                      Number(this.dropDowndata.find(x => x.viewValue === dropDownOptions.PLAYER_CATEGORY).id) : 0; 
    let masterPaymentMethodTypeId: number = this.dropDowndata.find(x => x.viewValue === dropDownOptions.PAYMENT_METHODS) ?  
                                      Number(this.dropDowndata.find(x => x.viewValue === dropDownOptions.PAYMENT_METHODS).id) : 0; 
    if(selectedTransactionTypeId !== 0 && selectedPlayerCategoryId !==0 && masterTransactionTypeId !== 0 && masterPlayerCategoryTypeId !== 0)
    {
      APIData.push(
        {id: 0 , 
        parentId : selectedTransactionTypeId , 
        childId : selectedPlayerCategoryId, 
        masterParentTypeId: masterTransactionTypeId, 
        masterChildTypeId: masterPlayerCategoryTypeId, 
        recursiveLinkId: 0,
        isDefault: false });   
        
        selectedPaymentMethodIds = emitCompLinkData.multipleCategoryData[2].filter(x => x.checked).map(x => x.id);
        if(selectedPaymentMethodIds.length !== 0 )
        {
          selectedPaymentMethodIds.forEach(x => {
           APIData.push(
        {id: 0 , 
        parentId : selectedPlayerCategoryId , 
        childId : x, 
        masterParentTypeId: masterPlayerCategoryTypeId, 
        masterChildTypeId: masterPaymentMethodTypeId, 
        recursiveLinkId: 1,
        isDefault: false });  
          })
        }

    }
    return this.componentsLinkingService.createComponentAccessTypeLink(APIData);

  }


  getTypeChangeData(value,index): Promise<CategoryArray[]> {
    //please dont provide reference values
    let data: CategoryArray[] = [
      { id: 11, name: 'Option 11'+index , checked: false },
      { id: 12, name: 'Option 12'+index , checked: false },
      { id: 13, name: 'Option 13'+index , checked: false },
      { id: 14, name: 'Option 14'+index , checked: false },
      { id: 15, name: 'Option 15'+index , checked: false },
      { id: 16, name: 'Option 16'+index , checked: false },
      { id: 17, name: 'Option 17'+index , checked: false },
      { id: 18, name: 'Option 18'+index , checked: false },
      { id: 19, name: 'Option 19'+index , checked: false }
    ];
    return Promise.resolve(data);
  }
}


