import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';
import _ from 'lodash';
import { HttpMethods } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopModel, SuggestQuantityPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';

export const PoProcessingOptions: PostPo = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    Result: PostPOSupplierConfig,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId: number): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig.ItemsConfig.hasOwnProperty('ProcessOptions')) {
                
                const DataInfo = TransactionCollection.CreateTranscationConfig.ItemsConfig.ProcessOptions;
                if (JSON.parse(sessionStorage.getItem('ProcessingOptions'))) {
                    DataInfo.FormGroupData = JSON.parse(sessionStorage.getItem('ProcessingOptions'));
                }
                const SuggestQtyDialog: SuggestQuantityPopUpModel = {
                    PopupHeaderContent: DataInfo.PopupHeaderName,
                    FormFields: DataInfo.FormFields,
                    FormGroupData: DataInfo.FormGroupData,
                    Classname: 'processing-options'
                };
                const dialogRef = dialog.open(AgilysysSuggestqtyPopupComponent, {
                    width: '50%',
                    height: '25%',
                    data: SuggestQtyDialog,
                    autoFocus: false,
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result[0] === 'Confirm') {
                        console.log('Result', result);
                        sessionStorage.setItem('ProcessingOptions', JSON.stringify(result[1].value));
                    }
                });
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred PoMiscellanuousCharge', Error);
    }
};

