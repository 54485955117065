import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { SnackBarType } from 'src/app/common/shared/shared/enums/enums';
import { StaffReaderBoardBusiness } from '../../staff-reader-board/staff-reader-board.business';
import { StaffReaderBoardDataService } from '../../dataservices/staff-reader-board-data.service';
import { UserRoleDataService } from '../../dataservices/user-roles-data.service';
import { API, UI, NoteSendToSelect } from '../../Models/staff-reader-board-model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-alert-new-popup',
  templateUrl: './alert-new-popup.component.html',
  styleUrls: ['./alert-new-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StaffReaderBoardBusiness, StaffReaderBoardDataService, UserRoleDataService]
})
export class AlertNewPopupComponent implements OnInit {

  captions: any;
  headername: any;
  AcknowledgeButton: ButtonValue;
  cancelButton: ButtonValue;
  notificationData: UI.ViewAllNotes[] =[];
  snoozerData: any;
  showLess: string = "less";
  notifyFrom: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedmillisecs: number;
  priorityList: any;
  isShowNoteAsPopup: boolean = false;
  timer: any;
  lbl_noDataFound: string = "";
  notifyCount: number;

  constructor(private localization: Localization, private dialog: MatDialog, private dialogRef: MatDialogRef<AlertNewPopupComponent>,
    private staffReaderBoardBusiness: StaffReaderBoardBusiness,
    private utilities: CommonUtilities, @Inject(MAT_DIALOG_DATA) data: any) {
    this.captions = this.localization.captions;
    this.headername = this.captions.lbl_notesforyou;
    this.priorityList = this.staffReaderBoardBusiness.getPriorityList();
    this.snoozerData = this.staffReaderBoardBusiness.getSnoozeTimer();

    if (data) {
      this.notifyFrom = data.from
      if (data.from == 'notify') {
        this.headername = this.captions.lbl_notifications
        this.isShowNoteAsPopup = false;
      }
      else
      {
        this.isShowNoteAsPopup = true;
      }
      this.GetRecentNotes();
    }
  }

  ngOnInit(): void {
    this.AcknowledgeButton = {
      type: 'primary',
      label: this.captions.lbl_ackbutton,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.toggleinitialText(this.notificationData)
    }, 500)
  }

  async GetRecentNotes() {
    this.notificationData = await this.staffReaderBoardBusiness.GetRecentNotes(this.isShowNoteAsPopup);
    this.notifyCount= this.notificationData.length;
    let sNo = 0;
    this.notificationData.forEach(x => {
      x.sentOn  = this.transformDateTimeFormat(x.sentOn,'');
      x.priorityName = this.priorityList.filter(y => y.id == x.priority)[0].viewValue;
      x.snoozeTime =   this.snoozerData.filter(y => y.timeInminutes == x.intervalTime).length >0 ? this.snoozerData.filter(y => y.timeInminutes == x.intervalTime)[0].value : '';
      x.selected = false;
      x.isMore = false;
      x.sNo = sNo++;
    });

    if(this.notificationData.length == 0)
    {
      this.lbl_noDataFound = this.captions.lbl_noDataFound;

      if(this.isShowNoteAsPopup)
        this.close(null);
    }
  }

  individualAcknowledge(data) {
    let note = data;
    this.staffReaderBoardBusiness.UpdateStaffReaderBoardDetailsIsAcknowledged(note.id).then(x => {
      note.isAcknowledged = true;
    });
  }

  toggleinitialText(text){
    var classNode = document.getElementsByClassName('panel');
    var moreTextNode = document.getElementsByClassName('moreText');
    for(var i = 0;i <classNode.length;i++){
    
      if(text[i].notes.length < 200){
        // classNode[i].innerHTML = text[i].notes;
        this.localization.decodeHTMLEntityByClass(text[i].notes,'panel',i);

      } else {
        // classNode[i].innerHTML =`${ text[i].notes.substring(0, 200)}...`;
        let data = `${ text[i].notes.substring(0, 200)}...`;
        this.localization.decodeHTMLEntityByClass(data,'panel',i);


      }
        text[i].isMore = true

      }

      for(var i =0 ;i<moreTextNode.length;i++){
        if(text[i].notes.length > 200) {
        moreTextNode[i].getElementsByTagName('a')[0].innerText = 'Show More'
        }
      }
    
  }

  functionCheck(data)
  {
    data.selected = !data.selected;

    this.AcknowledgeButton.disabledproperty = this.notificationData.filter(x=>x.selected).length == 0;

    this.AcknowledgeButton = {...this.AcknowledgeButton};
  }

  close(eve) {
    this.dialogRef.close()
  }


  toggleText(text) {
 
    var classNode = document.getElementsByClassName('panel');
    var moreTextNode = document.getElementsByClassName('moreText');
    for(var i = 0;i <classNode.length;i++){
      if (text.sNo == i){
        if(text.notes.length > 200 && !text.isMore){
          // classNode[i].innerHTML =`${ text.notes.substring(0, 200)}...`;
          let data =`${ text.notes.substring(0, 200)}...`;
        this.localization.decodeHTMLEntityByClass(data,'panel',i);

          moreTextNode[i].getElementsByTagName('a')[0].innerText = 'Show More'

        } else {
          // classNode[i].innerHTML = text.notes;
        this.localization.decodeHTMLEntityByClass(text.notes,'panel',i);
          moreTextNode[i].getElementsByTagName('a')[0].innerText = ' Show Less'
        }
        text.isMore = !text.isMore

      } else {
      }
     
    }
}


  onAckAction(eve) {
    let noteIds = this.notificationData.filter(x=>x.selected).map(x=>x.id);
    this.staffReaderBoardBusiness.UpdateMultipleStaffReaderBoardDetailsIsAcknowledged(noteIds).then(x => {
      this.GetRecentNotes();
      this.AcknowledgeButton.disabledproperty = false;
      this.AcknowledgeButton = {...this.AcknowledgeButton};
    });
  }

  onCancel(eve) {
    this.dialogRef.close()
  }

  setSnoozeTimer(category, snoozeValue) {
    category.snoozeTime = snoozeValue.value
    if (category.snoozeTime) {
      category.isSnoozed = true
    }

    this.staffReaderBoardBusiness.UpdateStaffReaderBoardDetailsIsSnoozed(category.id, category.isSnoozed, snoozeValue.timeInminutes).then(x => {
      this.utilities.showToastMessage(this.captions.lbl_note_snoozed_for.replace('{snoozeTime}', category.snoozeTime), SnackBarType.Success, 3000);
      this.selectedmillisecs = (snoozeValue.timeInminutes) * 60 * 1000;
      this.timer = setInterval(() => this.time(), this.selectedmillisecs);
    });
  }

  removeSnoozeTimer(category) {
    category.isSnoozed = false
    category.snoozeTime = ''

    this.staffReaderBoardBusiness.UpdateStaffReaderBoardDetailsIsSnoozed(category.id, category.isSnoozed, 0).then(x => {
      this.utilities.showToastMessage(this.captions.lbl_note_snoozed_removed, SnackBarType.Success, 3000);
      clearInterval(this.timer);
    });
  }
  transformDateTimeFormat(value: any, formdata: any) {

    if (typeof value == 'string') { value = this.localization.getDate(value); }

    let str = this.localization.localizeDisplayDate(value) + ' ';

    let time ='';

    if (this.localization.getTimeFormat() == 12)

      time = this.localization.formatAMPM(value, true);

  else

    time = this.localization.format24HrTime(value);

    return str + time;

  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  time() {
    const dialogRef = this.dialog.open(AlertNewPopupComponent, {
      width: '50%',
      height: '50%',
      maxWidth: '50%',
      data:{
        from:'login'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {

    });
  }
}