import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from '../shared/shared/service/http-call.service';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { Localization } from '../localization/localization';
import { Host } from '../shared/shared/globalsContant';
import { BaseResponse } from '../shared/shared/business/shared.modals';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonAlertMessagePopupComponent } from '../shared/shared/alert-message-popup/alert-message-popup.component';

@Injectable({ providedIn: 'root' })

export class TemplateServiceCommunication {

  loaderEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  commonCaptions: any;
  templateCaptions: any;
  constructor(private http: HttpServiceCall, private utils: CommonUtilities, private localization: Localization, private dialog: MatDialog) {
    this.commonCaptions = this.localization.captions.common;
    this.templateCaptions = this.localization.captions.settings.utilities;
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<any> {
    let response: BaseResponse<any> = await this.http.CallApiAsync({
      host: domain,
      callDesc: route,
      method: callType,
      body: body,
      uriParams: uriParams,
    });
    if (response && !response.successStatus) {
      this.showError(response.errorCode);
    } else if (response) {
      return response.result;
    }
  }

  private showError(errorCode: number) {
    let errMsg = this.localization.getError(errorCode);
    this.utils.ShowError(this.localization.captions.common.Error, errMsg);
  }

  showConfirmationPopup(successCallback, errorCallback) {
    let dialogRef = this.dialog.open(CommonAlertMessagePopupComponent, {
      width: '400px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.commonCaptions.Warning, headerIcon: 'icon-alert', headerMessage: this.templateCaptions.OverrideDefaultTemplate, buttonName: this.commonCaptions.Yes, noButton: true, noButtonName: this.commonCaptions.No, type: 'message'
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.toLowerCase() == this.commonCaptions.Yes.toLowerCase()) {
        successCallback(result);
      } else {
        errorCallback();
      }
    });
  }
}
