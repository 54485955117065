import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { Localization } from 'src/app/common/localization/localization';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { ContactLog } from '../contact-log-model';
// import { AppointmentpopupService } from '../../../../service/appointmentpopup.service';
import { ContactLogService } from '../contact-log.service';
// import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';


@Component({
  selector: 'app-create-contact-log',
  templateUrl: './create-contact-log.component.html',
  styleUrls: ['./create-contact-log.component.scss'],
  providers:[ContactLogService]
})
export class CreateContactLogComponent implements OnInit {

  captions: any = this.localization.captions;
  FormGrp: UntypedFormGroup;
  floatLabel: string;
  clickbutton: any;
  patchVlue: any;
  logTypeArray: any[];
  currentDate;
  minEndDate;
  placeholderFormat:string;
  automationId:string = "ContactLog";

  // constructor(@Inject(MAT_DIALOG_DATA) public data: any, public localization: SpaLocalization,
  //   public dialog: MatDialogRef<CreateContactLogComponent>, private Form: UntypedFormBuilder, private http: HttpServiceCall, public appoinmentPopupService: AppointmentpopupService, public contactLogService: ContactLogService, private PropertyInfo: SpaPropertyInformation)
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public localization: Localization,
  public dialog: MatDialogRef<CreateContactLogComponent>, private Form: UntypedFormBuilder, private http: HttpServiceCall, public contactLogService: ContactLogService, private PropertyInfo: CommonPropertyInformation)
     {
    this.floatLabel = this.localization.setFloatLabel;
    this.placeholderFormat = this.localization.inputDateFormat;
    this.patchVlue = this.data;
    this.currentDate = this.PropertyInfo.CurrentDate;
    this.minEndDate = this.PropertyInfo.CurrentDate;
  }

  ngOnInit(): void {
    this.FormGrp = this.Form.group({
      logType: ['', Validators.required],
      logNotes: ['', Validators.required],
      //logDate: [''],
      dueDate: '',
      expireDate: '',
      isAlert: '',
      isPrivate: '',
      isGroup: '',
    });

    this.FormGrp.valueChanges.subscribe(x => {
      this.CustomValidator();
    })
    // this.appoinmentPopupService.contactLogsFormGrp = this.appoinmentPopupService.contactLogsFormGrp.dirty ? this.appoinmentPopupService.contactLogsFormGrp : this.FormGrp;
    this.logTypeArray = this.data.logTypeList;
    if (this.contactLogService.logTypeList) {
      // this.contactLogService.getAllLogType(false).then(x=> {
      //   this.logTypeArray = x;
      // });
      
      if (this.data.isEdit) {
        this.PatchValueForm();
        //this.FormGrp.patchValue(this.patchVlue.currentdata);
        this.clickbutton = this.captions.setting.update;
      }
      else {
        this.clickbutton = this.captions.setting.save;
      }
    }
  }

  PatchValueForm() {
    let editData = this.patchVlue.currentdata;
    let dueDate = editData.dueDate ? new Date(editData.dueDate) : '';
    let expiryDate = editData.expiryDate ? new Date(editData.expiryDate) : '';
    //this.FormGrp.controls.logType.setValue((this.contactLogService.logTypeList.filter(x => x.logTypeInstance == editData.logType)[0]).id);
    this.FormGrp.controls.logType.setValue(editData.logTypeId);
    this.FormGrp.controls.logNotes.setValue(editData.logNotes);
    this.FormGrp.controls.dueDate.setValue(dueDate);
    this.FormGrp.controls.expireDate.setValue(expiryDate);
    this.FormGrp.controls.isAlert.setValue(editData.isAlert);
    this.FormGrp.controls.isPrivate.setValue(editData.isPrivate);
    this.FormGrp.controls.isGroup.setValue(editData.isGroup);
  }

  DialogClose = (result) => {
    this.FormGrp.reset()
    this.dialog.close(result);
  }
  saveForm() {
    let contactLogForm: ContactLog = {
      id: this.data.isEdit ? this.patchVlue.currentdata.id : 0,
      logTypeId: this.FormGrp.controls['logType'].value,
      //logTypeId: this.logTypeList.filter(x => x.id == this.FormGrp.controls.logType.value)[0].logTypeInstance,
      logNotes: this.FormGrp.controls['logNotes'].value,
      logDate: this.patchVlue.currentdata ? this.patchVlue.currentdata.logDate :  this.currentDate,
      dueDate: this.FormGrp.controls['dueDate'].value ? this.FormGrp.controls['dueDate'].value : null,
      expiryDate: this.FormGrp.controls['expireDate'].value ? this.FormGrp.controls['expireDate'].value : null,
      isAlert: this.FormGrp.controls['isAlert'].value ? true : false,
      isPrivate: this.FormGrp.controls['isPrivate'].value ? true : false,
      isGroup: this.FormGrp.controls['isGroup'].value ? true : false,
    }
    this.dialog.close(contactLogForm);
  }
  OnlogTypeChange(eve) {

  }
  onGroupToggleChange(e) { }
  onPrivateToggleChange(e) {

  }
  onAlertToggleChange(e) {

  }

  getOnlyDate(input: any): Date {
    let dt = new Date(input);
    return new Date(dt.getFullYear() + '/' + (((dt.getMonth() + 1) < 10) ? '0' : '') + (dt.getMonth() + 1) + '/' + ((dt.getDate() < 10) ? '0' : '') + dt.getDate());
  }


  CustomValidator() {
    if (this.FormGrp.controls.dueDate.hasError('matDatepickerParse') && this.FormGrp.controls.dueDate.errors.matDatepickerParse.text == '') {
        this.FormGrp.controls.dueDate.setErrors(null);
      this.FormGrp.controls.dueDate.setValue(null);
    }
    if(this.FormGrp.controls.expireDate.hasError('matDatepickerParse') && this.FormGrp.controls.expireDate.errors.matDatepickerParse.text == ''){
      this.FormGrp.controls.expireDate.setErrors(null);
      this.FormGrp.controls.expireDate.setValue(null);
    }
  }
}
