export interface AzureAdModel {
    clientID: string;
    authority: string;
    redirectUri: string;
    cacheLocation: string;
    unprotectedResources: Array<any>;
    protectedResourceMap: Array<any>;
    extraQueryParameters: string;
    applicationId?: string;
    tenant?: string;
    signUpSignInPolicy?: string;
    tenantIdentifier?: string;
}

export interface OAuthSettingsConfig {
    appId: string;
    scopes: Array<any>;
    authority ?: string;
}

export interface TenantInformation {
    Id: number;
    TenantName: string;
    TenantIdentifier: string;
    IsActive: boolean;
    TenantID: string;
    ClientID: string;
    ClientSecret?: string;
    IsAdEnabled: boolean;
    AdData: any;
    AzureADB2CAuthority: string;
    AzureAdB2CUserflow: string;
    AzureAdType: any;
    EnableProductAuthentication: boolean;
    ProductAuthority: string;
    OAuthSettings: OAuthSettingsConfig;
    ProductUserFlow ?: string;

}

export interface AdDataModel {
    TenantId: string;
    ClientSecret: string;
    ClientId: string;
    AzureAdType: any;
    AzureADB2CAuthority: string;
    AzureAdB2CUserflow: string;
    AzureAdB2CTenant: string;
    AzureAdB2CSigninOidc: string;
}

export const intialAdDataModel: AdDataModel = {
    TenantId: null,
    ClientSecret: null,
    ClientId: null,
    AzureAdType: null,
    AzureADB2CAuthority: null,
    AzureAdB2CUserflow: null,
    AzureAdB2CTenant: null,
    AzureAdB2CSigninOidc: null
};

export interface TenantModel {
    adData: string;
    customSQLConfiguration: string;
    id: any;
    isActive: boolean;
    isAdEnabled: boolean;
    isMFAEnabled: boolean;
    isProductAuthentication: boolean;
    maxLogInAttempt: number;
    mfaContextId: string;
    mfaTenantId: number;
    otpValidityPeriod: number;
    rGuestProductId: any;
    rGuestSSOAuthURL: any;
    rGuestTenantCode: any;
    rGuestTenantId: any;
    storageDataEnabled: boolean;
    tenantINIConfig: Array<TenantINIConfig>;
    tenantIdentifier: string;
    tenantName: string;
    integrationCofig: any;
    enableDataImport: boolean;
    dataImportConfig: any;
}

export interface TenantINIConfig {
    configGroup: string;
    configKey: string;
    configValue: string;
    id: number;
    tenantID: number;
}