import { DateTimeFormat } from "src/app/common/localization/localization";

export interface FolioDetails extends BaseModel {
    sourceType: string;
    sourceTypeId: string;
    folioNumber: string;
    folioName: string;
    isDefault: boolean;
    paymentType?: string;
    paymentValue?: string;
    terminalRefId?: number;
    paymentTransactionRefId?: number;
    listOrder?: number;
    isThirdPartyPayment?: boolean;
    discloseTransactionToGuest?: boolean;
    folioDefinition?: FolioDefinition[];
    additionalInformation?: string;
    creditLimit?: number;
    folioInvoiceNumber?: string
}

export interface FolioDefinition extends BaseModel {
    folioId: number;
    folioNumber: string;
    postTypeId: number;
    postTypeName: string;
    splitValue: number;
    isSplitByPercentage: boolean;
    departmentId: number;
}

export interface BaseModel {
    id: number;
}

export interface CreateFolioRequest {
    sourceType: string;
    sourceTypeId: string;
    folioName: string;
    paymentMethodId?: number;
    paymentTransactionId?: number;
    creditLimit?: number;
}

export interface CreateDefaultFolioRequest {
    sourceType: string;
    sourceTypeId: string;
    paymentTransactionId: number;
}

export interface FolioLookup {
    sourceType: string;
    sourceTypeId: string[];
    folioInvoiceNumber: string[];
    status: number[];
    includeMemberFolio?: boolean;
}

export enum GuestSearchConstants {
    FULLNAME = "NAME",
    FIRSTNAME = "FIRSTNAME",
    LASTNAME = "LASTNAME",
    PHONE = "PHONE",
    EMAIL = "EMAIL"
}
export enum SearchType {
    GUESTINFO = "GUESTINFO",
    FOLIOINVOICENUMBER = "FOLIOINVOICENUMBER"
}

export enum ErrorConstants {
    NOFOLIODATA = 501001
}

export interface GuestSearchInfo {
    sourceType: string;
    searchkey: string;
    searchField: string;
}

export interface GuestInfo {
    id: number;
    guestId: string;
    firstName: string;
    lastName: string;
    folios: []
}

export enum SourceType {
    PMS = 0,
    SPA = 1,
    GOLF = 2,
    SNC = 3,
    PMS_BOOKING = 4,
    AddOn = 5,
    AddOnMember = 6
}

export interface PaymentPostRequestForResortFinance {
    transactionId: number;
    amount: number
}

export interface PostingRecords {
    postTypeNumber: string;
    amount: number;
    comment: string;
    reference: string;
    recordType?: string;
    groupingId?: number;
}

export interface UnMappedPostTypeDetails {
    unmappedCategoriesForSettlement: string[];
    unmappedCategoriesForServiceCharge: string[];
    unmappedCategoriesForGratuity: string[];
    unmappedCategoriesDiscount: string[];
    unmappedTaxes: string[];
    unmappedItems: string[];
}

export interface PostChargeRequest extends PostingRecords {
    sourceType: string;
    sourceTypeId: string;
    folioId: number;
    guestId: string;
    outletId:number;
    FolioInvoiceNumber:string;   
}

export interface PostingRequest {
    postChargeRequests: PostChargeRequest[];
    retailTicketNumber: number;
    retailTransactionId: number;
    transactionAmount: number; 
    transactionDate: DateTimeFormat;
    transactionGuestId: string;
    paymentMethodId: number;       
}

export interface PostingResponse {
    folioPostRecords: any[];
    folioPostingLogId: number;
    isPostingSuccess: boolean;
}

export interface FolioLookupResponse {
    sourceType: string;
    sourceTypeId: string;
    folioInvoiceNumber: string;
    overAllBalance: number;
    guestDetailInfo: GuestInfo;
    folioLookupData: FolioLookupData[];
}

export interface FolioLookupData {
    folioId: number;
    folioNumber: string;
    folioName: string;
    folioStatus: number;
    creditLimit: number;
    outstandingBalance: number;
    isDefault : boolean;
}

export interface GuestInfo {
    id: number;
    firstName: string;
    lastName: string;
    phoneNumber: string[];
    email: string[];
    interfaceGuestId?: string;
}

export interface RedemptionBucketMapping extends BaseModel {
    code: string;
    name: string;
    listOrderLinkId: number;
    isActive: boolean;
    paymentMethodId: number;
}

export enum LengthConstants {
    guestLen = 3,
    FolioLen = 5
}

export enum FolioSearchConstants
{
    Folio = 'folio',
    Guest = 'guest',
    Member = 'member'
}

export enum SettingModule {
    FolioSetup = "FOLIOSETUP"
}

export enum SettingScreen {
    FolioDefaults = "MISC",
    EngageFeature = "FEATURECONFIGURATION_ENGAGE",
    ConfigurationSwitches = "MISC_CONFIGURATION_SWITCHES"
}

export interface Setting<T>  {
    id: number;
    moduleName: string;
    screenName: string;
    configValue: T;
    defaultValue: T;
  }

  export interface ConfigurationsSwitches {
    configurationKey: string;
    configurationValue: string;
}

export enum MiscConfigurationSwitches{
    validateCreditLimit = 'validateCreditLimit',
    agilysysEngage = 'agilysysEngage'    
  }

  export interface BulkCreateResortFinanceFoliosRequest {
    sourceType: string;
    sourceTypeIds: string[];
    guestCardTokenReferences?: CustomCardTokenDictionary[];
    resortFinanceGuestCardTokenReferences?: CustomCardTokenDictionary[];
    folioCreationDate?: Date;
}

export interface CustomCardTokenDictionary {
    sourceTypeId: string;
    cardToken: number;
    isDefaultToken: boolean;
}