import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AgDateConfig, AgTimeConfig, ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { ImageProcessorService } from 'src/app/retail/shared/service/image-processor-service';
import { LockerAppointmentDetails, LockerControlDetails, LockerControlRequest, OutOfOrderLockerModel } from 'src/app/retail/shared/shared.modal';
import { LockerControlBusiness } from './locker-control.business';
import { HttpServiceCall,HttpMethod} from 'src/app/retail/shared/service/http-call.service';
import { BaseResponse} from 'src/app/retail/shared/shared.modal';
import * as GlobalConst from '../../../shared/globalsContant';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { LockerBookingSourceType } from '../../shop.modals';
@Component({
  selector: 'app-locker-control',
  templateUrl: './locker-control.component.html',
  styleUrls: ['./locker-control.component.scss'],
  providers: [LockerControlBusiness],
  encapsulation : ViewEncapsulation.None
})
export class LockerControlComponent implements OnInit {

  captions: any;
  dropdownoptions = [];
  dateInput: AgDateConfig;
  lockerForm: UntypedFormGroup;
  timeInput: AgTimeConfig;
  todayButton: ButtonValue;
  searchButton: ButtonValue;
  isInUse: boolean = false;
  isAvailable: boolean = false;
  isOutOfOrder: boolean = false;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent= [];
  availableItem = [];
  isDisplayImagesInShop: boolean = true;
  searchPlaceHolder: any;
  dateTimePlaceholder: any;
  outOfOrderTableData: OutOfOrderLockerModel[];
  inUseAvailableRequestBody: LockerControlRequest;
  LockerControlDetails: LockerControlDetails[];
  lockerimages = [];
  searchText: any;
  LockerAppointmentDetails: LockerAppointmentDetails[];
  originalTableContent= [];
  originalAvailableItem = [];
  emptyBaseResponse: BaseResponse<any> = {
    result: null,
    errorCode: 0,
    errorDescription: '',
    successStatus: true,
    propertyId: 0,
    outletId: 0
  }
  constructor(public localization: RetailLocalization,private fb: UntypedFormBuilder,
    private business:LockerControlBusiness, public propertyInfo: RetailPropertyInformation,
    private utils: CommonUtilities,public imgService: ImageProcessorService,private http: HttpServiceCall,public _commonVarService: CommonVariablesService,public _utilities: RetailUtilities) {
    this.captions = this.localization.captions;
   }

  async ngOnInit(){
    this.isInUse = true;
    if(this.isInUse){
      this.searchPlaceHolder = this.captions.Lockers.lbl_searchByClientLockerNameNumber;
    }
    else{
      this.searchPlaceHolder = this.captions.Lockers.lbl_searchByLockerNameCode;
    }
    
    this.formGenerator();
    await this._commonVarService.GetMiscConfig();
    this.headerOptions = this.business.getInuseHeaderOptions();
    this.tableOptions = this.business.getTableOptions();
    this.tableContent = await this.getInUseTableContent();
    this.originalTableContent = this.tableContent;
  }

  formGenerator(){
    this.lockerForm = this.fb.group({
      date: this.propertyInfo.CurrentDate,
      time: this.localization.getTime(this.localization.getDate(this.utils.getPropertyTime()), this.localization.getTimeFormat()),
      dropdown: '',
    });
    this.dateInput = {
      form: this.lockerForm,
      formControlName: 'date',
      automationId:"'Txt_lockerItemControl_date'"
    };
    this.timeInput = {
      form: this.lockerForm,
      formControlName: 'time',
    }
    this.todayButton = {
      type: 'secondary',
      label: this.captions.btn_today,
      disabledproperty : false
    }
    this.searchButton = {
      type: 'primary',
      label: this.captions.btn_search,
      disabledproperty : false
    }


  }

  async search() {
    if(this.isInUse == true){
    this.tableContent = await this.getInUseTableContent();
    }
    else if(this.isAvailable == true){
      this.availableItem = await this.getAvailableItemList();
    }
    else if(this.isOutOfOrder == true){
    this.tableContent = await this.getOutOfOrderTableContent();
    }
    this.originalTableContent = this.tableContent;
    this.originalAvailableItem = this.availableItem;
  }

  async inUseClick(){
    this.isInUse = true;
    this.isAvailable = false;
    this.isOutOfOrder = false;
    this.searchPlaceHolder = this.captions.Lockers.lbl_searchByClientLockerNameNumber;
    this.dateTimePlaceholder = this.captions.lbl_dateTime;
    this.headerOptions = this.business.getInuseHeaderOptions();
    this.tableContent = await this.getInUseTableContent();
    this.tableOptions = this.business.getTableOptions();
    this.originalTableContent = this.tableContent;
    this.searchValueChange('');
  }
  async onAvailable(){
    this.isAvailable = true;
    this.isInUse = false;
    this.isOutOfOrder = false;
    this.searchPlaceHolder = this.captions.Lockers.lbl_searchByLockerNameCode;
    this.dateTimePlaceholder = this.captions.lbl_dateTime;
    this.availableItem = await this.getAvailableItemList();
    this.originalAvailableItem = this.availableItem;
    this.searchValueChange('')
  }
  async getAvailableItemList(){
    let apiDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.date.value);
   let apiTime = this.localization.DeLocalizeTime(this.lockerForm.controls.time.value);
   this.localization.convertDateObjToAPIdate
    this.inUseAvailableRequestBody = {
       isInUseLockerDetail : false,
      requestStartDateTime :apiDate+'T'+apiTime
    };
    const response = await this.InvokeServiceCallAsync(RetailRoutes.GetInUseAvailableLockers, GlobalConst.Host.retailPOS, HttpMethod.Post,'',this.inUseAvailableRequestBody);
    this.LockerControlDetails = response.result;
    let lockerIds = this.LockerControlDetails?.map(x=>x.lockerId);
    if(lockerIds.length>0){
      this.loadRetailItemImages(lockerIds);
    }
    return this.LockerControlDetails;
  }
  loadRetailItemImages = (lockerIds) => {
    this.imgService.GetAllImagesByReference(lockerIds, GlobalConst.ImgRefType.locker, false, this.successCallback.bind(this), this.errorCallback.bind(this), []);
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === 'getAllImagesByReference') {
      this.lockerimages = result.result as any;
      const value = [...this.mapImg(this.availableItem, this.lockerimages)];
      this.availableItem = value;
    }
  }
  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this._utilities.ToggleLoaderWithMessage(false);
  }
  mapImg(availableItem: any[], images: any[]) {
    images.forEach(element => {
      availableItem.forEach(itemArr => {
        if (itemArr.lockerId== element.referenceId) {
          itemArr.image = element;
        }
      });
    });

    return availableItem;
  }
  async onOutOfOrder(){
    this.isOutOfOrder = true;
    this.isInUse = false;
    this.isAvailable = false;
    this.searchPlaceHolder = this.captions.Lockers.lbl_searchByLockerNameCode;
    this.dateTimePlaceholder = this.captions.tbl_hdr_extractDate;
    this.headerOptions = this.business.getOutOfOrderHeaderOptions();
    this.tableContent =  await this.getOutOfOrderTableContent();
    this.tableOptions = this.business.getOutOfOrderTableOptions();
    this.originalTableContent = this.tableContent;
    this.searchValueChange('');
  }
  async getInUseTableContent() {
    let apiDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.date.value);
    let apiTime = this.localization.DeLocalizeTime(this.lockerForm.controls.time.value);
    this.localization.convertDateObjToAPIdate
    this.inUseAvailableRequestBody = {
      isInUseLockerDetail: true,
      requestStartDateTime: apiDate + 'T' + apiTime
    };
    const targetBookingTypes = [
      LockerBookingSourceType.SpaAppointment,
      LockerBookingSourceType.SpaClasses
    ];
    const response = await this.InvokeServiceCallAsync(RetailRoutes.GetInUseAvailableLockers, GlobalConst.Host.retailPOS, HttpMethod.Post, '', this.inUseAvailableRequestBody);
    this.LockerControlDetails = response.result;
    let appointmentIds = this.LockerControlDetails.filter(x => x.bookingType == LockerBookingSourceType.SpaAppointment || x.bookingType == LockerBookingSourceType.SpaClasses).map(item => ({
      bookingTypeId: item.bookingTypeId,
      bookingType: item.bookingType
    }));
    let guestIds = this.LockerControlDetails.filter(x => x.bookingType == LockerBookingSourceType.Client).map(x => x.clientId);
    this.utils.ToggleLoaderWithMessage(true, this.captions.lbl_processing);
    let appointmentBookingTypeIds : number[] = [];
    let classBookingTypeIds : number[] = [];
    if (appointmentIds?.length > 0) {
      for (const item of appointmentIds) {
        switch (item.bookingType) {
          case LockerBookingSourceType.SpaAppointment:
            appointmentBookingTypeIds.push(item.bookingTypeId);
            break;
          case LockerBookingSourceType.SpaClasses:
            classBookingTypeIds.push(item.bookingTypeId);
            break;
        }
      }
    }
    let serviceNameFilter = await Promise.all([this.GetAppointmentInfo(appointmentBookingTypeIds), this.GetClassInfo(classBookingTypeIds),this.GetClientInfo(guestIds)]);
    const appointmentResponse = serviceNameFilter[0];
    const classResponse = serviceNameFilter[1];
    const guestResponse = serviceNameFilter[2];

    if (guestIds.length > 0) {
      let guestDetails = (await guestResponse).result;
      this.LockerControlDetails.filter(x=>x.bookingType == LockerBookingSourceType.Client).forEach(lcd => {
        let guest = guestDetails.find(x => x.clientDetail.guestId == lcd.clientId);
        if (guest) {
          lcd.clientName = guest.clientDetail.firstName + " " + guest.clientDetail.lastName;
        }
        lcd.lockerBookingStartTime = this.localization.LocalizeLongDateTime(new Date(lcd.lockerBookingStartTime));
          lcd.lockerBookingEndTime = this.localization.LocalizeLongDateTime(new Date(lcd.lockerBookingEndTime));
      });
    }
    if (appointmentIds.length > 0) {
      const res: BaseResponse<any> = await appointmentResponse;
      const resFromClass : BaseResponse<any> = await classResponse;
      if (res?.result || resFromClass?.result) {
        this.LockerAppointmentDetails = res?.result;
        let lockerClassDetail = resFromClass?.result;
        this.LockerControlDetails.filter(item => targetBookingTypes.includes(item.bookingType)).forEach(lcd => {
          if (lcd.bookingType == LockerBookingSourceType.SpaAppointment) {
            const relevantAppointments = this.LockerAppointmentDetails?.filter(lad => lcd.bookingTypeId === lad.appointmentId);
            if (relevantAppointments.length > 0) {
              const lad = relevantAppointments[0]; 
              let startTime = this.localization.getTime(lad.appointmentStartTime.APIStringToDate(), this.localization.getTimeFormat());
              let endTime = this.localization.getTime(lad.appointmentEndTime.APIStringToDate(), this.localization.getTimeFormat());
              lcd.appointmentTime = startTime + "-" + endTime;
              lcd.service = lad.serviceName;
              lcd.clientName = lad.clientFirstName + " " + lad.clientLastName;
            }
          }
          if (lcd.bookingType == LockerBookingSourceType.SpaClasses) {
            const relevantClass = lockerClassDetail?.filter(lad => lcd.bookingTypeId === lad.classClientId);
            if (relevantClass.length > 0) {
              const lad = relevantClass[0]; 
              let startTime = this.localization.getTime(lad.classDateTime.APIStringToDate(), this.localization.getTimeFormat());
                let endTime = this.localization.getTime(this.localization.AddMinutes(lad.classDateTime.APIStringToDate(), lad.classDuration), this.localization.getTimeFormat());
                lcd.appointmentTime = startTime + "-" + endTime;
                lcd.service = lad.className;
                lcd.clientName = lad.clientFirstName + " " + lad.clientLastName
            }
          }
          lcd.lockerBookingStartTime = this.localization.LocalizeLongDateTime(new Date(lcd.lockerBookingStartTime));
          lcd.lockerBookingEndTime = this.localization.LocalizeLongDateTime(new Date(lcd.lockerBookingEndTime));
        });
      }
    }
    this.utils.ToggleLoaderWithMessage(false);
    return this.LockerControlDetails;
  }
  GetAppointmentInfo(appointmentBookingTypeIds){
    return appointmentBookingTypeIds.length > 0 ?  this.InvokeServiceCallAsync('GetAppointmentInfoByAppointmentIds', GlobalConst.Host.schedule, HttpMethod.Put, '', appointmentBookingTypeIds) : Promise.resolve(this.emptyBaseResponse);
  }
  async GetClassInfo(classBookingTypeIds){ 
    return classBookingTypeIds.length > 0 ? (await this.getClassClientDetails(classBookingTypeIds)) : Promise.resolve(this.emptyBaseResponse);
  }
  GetClientInfo(guestIds){ 
    return guestIds.length > 0 ? this.InvokeServiceCallAsync('GetClientsByGuestIds', GlobalConst.Host.spaManagement, HttpMethod.Put, '', guestIds) : Promise.resolve(this.emptyBaseResponse);
  }
  async getOutOfOrderTableContent(){
    const response = await this.InvokeServiceCallAsync(RetailRoutes.GetOutOfOrderLockers, GlobalConst.Host.retailManagement, HttpMethod.Get, '', '');
    this.outOfOrderTableData = response.result;
    this.outOfOrderTableData.forEach(x => {
    x.totalQuantity = x.outofOrderQuantity+ x.remainingQuantity
    })
    return this.outOfOrderTableData;
  }
  todayClick(){
    this.lockerForm.patchValue({
      date: this.propertyInfo.CurrentDate,
      time: this.localization.getTime(this.localization.getDate(this.utils.getPropertyTime()), this.localization.getTimeFormat()),
    })
  }
  async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async getClassClientDetails(
    classClientIds: number[]
  ): Promise<BaseResponse<any>> {
    return await this.InvokeServiceCallAsync(
      "getClassClientDetails",
      GlobalConst.Host.schedule,
      HttpMethod.Put,
      null,
      classClientIds
    );
  };

  searchValueChange(e){
    this.searchText=e?e.trim().toLowerCase():''
    if(this.searchText && this.searchText.length > 0)
    {
      if(this.isInUse){
        this.tableContent=this.originalTableContent.filter(x=>x.lockerName.toLowerCase().includes(this.searchText)|| x.lockerNumber?.toLowerCase().includes(this.searchText)
        || x.clientName.toLowerCase().includes(this.searchText) || x.service.toLowerCase().includes(this.searchText) || x.comments.toLowerCase().includes(this.searchText));
      }
      else if(this.isAvailable){
        this.availableItem=this.originalAvailableItem.filter(x=>x.lockerCode.toLowerCase().includes(this.searchText) || x.lockerName.toLowerCase().includes(this.searchText));
      }
      else if(this.isOutOfOrder){
        this.tableContent=this.originalTableContent.filter(x=>x.lockerName.toLowerCase().includes(this.searchText)|| x.lockerCode?.toLowerCase().includes(this.searchText));
      }
    }
    else{
      this.tableContent = this.originalTableContent;
      this.availableItem=this.originalAvailableItem
    }
  }
  dateChange(e){

  }
  timeChange(e){

  }
  filterChange(){

  }

}
