import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { SecondaryHeader, SubFormConfigs } from 'src/app/eatecui/source/shared/models/secondary-header.interface';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { MasterBreadCrumbConfig } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

@Component({
  selector: 'app-secondary-header',
  templateUrl: './secondary-header.component.html',
  styleUrls: ['./secondary-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecondaryHeaderComponent implements OnChanges, AfterViewInit {

  @Input() HeaderInput: SecondaryHeader;
  @Input() MasterBreadCrumb?: MasterBreadCrumbConfig;
  changeBreadCrumbControl: boolean;

  constructor() {
    console.log(this.MasterBreadCrumb);
    this.changeBreadCrumbControl = false;
  }
  backArrowClick(breadcrumb = null) {
    if (this.HeaderInput.hasOwnProperty('BackArrowClick') && breadcrumb === null) {
      this.HeaderInput.BackArrowClick();
    } else if (this.HeaderInput.hasOwnProperty('BackArrowClick') && breadcrumb !== null) {
      if (breadcrumb.RoutingAction) {
        this.HeaderInput.BackArrowClick();
      }
    }
  }

  subFormClick(data: SubFormConfigs) {
    try {
      if (this.HeaderInput.hasOwnProperty('SubFormOptionClick')) {
        this.HeaderInput.SubFormOptionClick(data);
      }
    } catch (error) {
      console.error('Error occurred in subFormClick', error);
    }
  }

  ngAfterViewInit() {
    console.log('Page');
  }

  ngOnChanges() {
    if (this.MasterBreadCrumb && Object.keys(this.MasterBreadCrumb).length > 0) {
      this.changeBreadCrumbControl = true;
      const enableIframe = StorageService.GetSessionStorage('enableIframe');
      if (enableIframe && enableIframe !== null && enableIframe !== 'null') {
        this.MasterBreadCrumb.BreadCrumbConfiguration = [];
        if (this.HeaderInput.hasOwnProperty('ButtonArray') &&
        this.HeaderInput.ButtonArray.length > 0) {
          this.HeaderInput.ButtonArray.forEach(x => {
          if ( x.DisplayName.toLowerCase() === 'cancel') {
                x.Class += ' display-none';
          }
          });
        }
      }
    }
  }
}
