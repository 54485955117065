import { AuthorizedRoutingConfig } from './authorized.routing.interface';
import { CheckStateUrl } from './check.stateurl.interface';
import { CheckRouterParams } from './checkrouterparams.interface';
import { MasterManagementAuthorization } from './master-management-authorized.interface';
import { NavigationAuthorization } from './navigation-authorized.interface';
import { SetupAuthorization } from './setup-authorized.interface';
import { UserManagementAuthorization } from './usermanagement-authorized.interface';
import { TransactionAuthorization } from './transaction-authorized.interface';
import { RouteAuthGuardAuthorization } from './routeguard-authorization.interface';
import { TransactionTabCheckDropdown } from './transaction-tab-dropdown.interface';
import { CrossReferenceTabCheckDropdown } from './crossreference-tab-dropdown.interface';

export const UicontrolInterface = {
   StateUrlCheck: CheckStateUrl,
   AuthorizedRoute: AuthorizedRoutingConfig,
   CheckParams: CheckRouterParams,
   NavigationAuth: NavigationAuthorization,
   SetupAuthorizationAuth: SetupAuthorization,
   UserManagementAuthorizationAuth: UserManagementAuthorization,
   MasterManagementAuthorizationAuth: MasterManagementAuthorization,
   TransactionAuthorizationAuth: TransactionAuthorization,
   RoutingGuardAuthorization: RouteAuthGuardAuthorization,
   TransactionTabCheck: TransactionTabCheckDropdown,
   CrossReferenceTabCheck: CrossReferenceTabCheckDropdown
};