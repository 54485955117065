import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
@Injectable()
export class ADB2CAuthConfiguration
{
    constructor() {
    }
    public ADB2CAuthFeatureEnabled: boolean = false;
    public DiscoveryDocumentConfigUrl: string;
    public authConfig: AuthConfig;
}
