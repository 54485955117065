import { TransactionMasterData } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

export const TransactionTabCheckDropdown = (TransactionMasterJson: TransactionMasterData[]): any => {
    try {
        let rguestTenantId = true;
        const checkTenant = sessionStorage.getItem('RGuestTenantId');
        const isEatecUser = sessionStorage.getItem('isEatecUser');
        if (checkTenant && JSON.parse(checkTenant)) {
            rguestTenantId = true;
        }
        if (rguestTenantId || (isEatecUser && !JSON.parse(isEatecUser))) {
            const ModuleName = ['Requisition'];
            ModuleName.forEach(moduleNames => {
                TransactionMasterJson = TransactionMasterJson.filter(x => x.GroupName !== moduleNames);
            });
            const ModuleId = [1];        
            TransactionMasterJson.forEach(jsonData => {
                if (jsonData.GridConfiguration.GridTitleType.Type === 'dropdown' && 
                    jsonData.GridConfiguration.GridTitleType.TypeConfig &&
                    jsonData.GridConfiguration.GridTitleType.TypeConfig.length > 0) {
                    ModuleId.forEach(moduleName => {
                        jsonData.GridConfiguration.GridTitleType.TypeConfig = 
                        jsonData.GridConfiguration.GridTitleType.TypeConfig.filter(x =>
                            x.Id !== moduleName);
                    });
                }
            });
        }
        return TransactionMasterJson;
    } catch (error) {
        console.error('Error occurred in TransactionTabCheckDropdown', error);
    }
};