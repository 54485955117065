import { Injectable } from '@angular/core';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { DataMagineIntegrationDataService } from 'src/app/common/dataservices/data-magine-integration.data.service';
import { DmConfig, Product, SNCDataMagineModel } from 'src/app/common/Models/common.models';
import { UI, LetterType } from './data-magine-config.model';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Injectable()
export class DatamagineConfigBusiness {
  captions;
  constructor(private _dmConfigDataService: DMConfigDataService, private _dataMagineIntegrationDataService: DataMagineIntegrationDataService,private utils: CommonUtilities,
    private _localization: Localization) {
    this.captions = this._localization.captions;
  }

  public async GetDMConfig() {
    let apiModel: DmConfig = await this._dmConfigDataService.getDataMagineConfig();
    const result = (apiModel != null) ? this.uiMapper(apiModel) : null;
    return result;
  }

  public async GetDMConfigSession() {
    let apiModel: DmConfig = await this._dmConfigDataService.getDataMagineConfigSession();
    const result = (apiModel != null) ? this.uiMapper(apiModel) : null;
    return result;
  }
  public async createDMConfig(DataMagineConfig: UI.DmConfig): Promise<UI.DmConfig> {
    let apiModel: DmConfig = await this._dmConfigDataService.createDataMagineConfig(this.APIMapper(DataMagineConfig));
    return this.uiMapper(apiModel);
  }

  public async updateDMConfig(DataMagineConfig: UI.DmConfig): Promise<UI.DmConfig> {
    let apiModel: DmConfig = await this._dmConfigDataService.updateDataMagineConfig(this.APIMapper(DataMagineConfig));
    return this.uiMapper(apiModel);
  }

  public async testConnection(dataMagineConfig: UI.DmConfig, isDMOverride: boolean, isSnc) {
    if(isDMOverride)
    {
      return await this._dataMagineIntegrationDataService.getDMTokenInfo(dataMagineConfig.serverurl, dataMagineConfig.username, dataMagineConfig.password);
    }
    else if (isSnc) {
      return await this._dataMagineIntegrationDataService.getDmTokenInfoSNC(dataMagineConfig.serverurl, dataMagineConfig.username, dataMagineConfig.password);
    }
    else
    return await this._dataMagineIntegrationDataService.getDmTokenInfo(dataMagineConfig.serverurl, dataMagineConfig.username, dataMagineConfig.password);
  }
  public GetViewForSNC() {
    return [{
      id: LetterType.Account,
      viewValue: this.captions.lbl_account,
      value: LetterType.Account,
    },
    {
      id: LetterType.Booking,
      viewValue: this.captions.drp_taskType_booking,
      value: LetterType.Booking,
    },
    {
      id: LetterType.Proforma,
      viewValue: this.captions.mail_proformaInvoice,
      value: LetterType.Proforma,
    },
    {
      id: LetterType.Beo,
      viewValue: this.captions.lbl_beo,
      value: LetterType.Beo,

    },
    {
      id: LetterType.Check,
      viewValue: this.captions.lbl_banquetCheck,
      value: LetterType.Check,
    }
    ]
  }

  private APIMapper(dataMagineConfig: UI.DmConfig): DmConfig{
    let productId = parseInt(this._localization.GetPropertyInfo('ProductId'));
    let eformConfig = dataMagineConfig?.eFormConfigurations;
    let isFromSnc = productId == Product.SNC;
    return {
      id: dataMagineConfig.id ? dataMagineConfig.id : 0,
      captureSignature: dataMagineConfig.signature,
      enableDataMagine: dataMagineConfig.enableDatamagine,
      enableDmLink: dataMagineConfig.enablelink,
      enableIDScan: dataMagineConfig.enableScanner,
      enableSigCap: dataMagineConfig.enableSignatureCapture,
      showPreview: dataMagineConfig.showPreview,
      password: dataMagineConfig.password,
      product: productId,
      scannerEndpoint: dataMagineConfig.scannerEndPoint,
      serverURL: dataMagineConfig.serverurl,
      signatureCaptureEndpoint: dataMagineConfig.signCapEndPoint,
      userName: dataMagineConfig.username,
      viewName:dataMagineConfig.viewName,
      postCheckReceiptToDM: dataMagineConfig.postCheckReceiptToDM,
      sncDataMagineAdditonalModel: isFromSnc ? this.getSncAPIDataMagineUIMapper(dataMagineConfig) : null,
      dmEformsConfig: {
        id: dataMagineConfig.dmEformConfigId ? dataMagineConfig.dmEformConfigId : 0,
        authURL: dataMagineConfig.authurl,
        eformsLinkURL: dataMagineConfig.eformurl,
        eformsListURL: dataMagineConfig.eformlisturl,
        eformsPropertyId: dataMagineConfig.propertyID,
        eformsTenantId: dataMagineConfig.tenantID,
        enableEforms: dataMagineConfig.enableEforms,
        showDuringCheckIn: dataMagineConfig.showDuringCheckIn,
        clientId: dataMagineConfig.clientId,
        clientSecret: dataMagineConfig.clientSecret,
        enableEformSigCap: eformConfig?.useEformSignCapture,
        showSigCapDuringCheckIn: eformConfig?.showOnCheckin,
        showPreview: eformConfig?.showOnPreview,
        enableSigCapDefaultTemplate: eformConfig?.defaultTemplate,
        sigCapUrl: eformConfig?.signEndPoint,
        sigCapDefaultTemplate: eformConfig?.searchTemplate
      }
    } as DmConfig;
  }
  private getSncAPIDataMagineUIMapper(dataMagineConfig) {
    return {
      supportedLetter: dataMagineConfig.supportedLetter.map(o => o.id),
      beoViewText: dataMagineConfig.beoViewText,
      accountViewText: dataMagineConfig.accountViewText,
      bookingViewText: dataMagineConfig.bookingViewText,
      proformaViewText: dataMagineConfig.proformaViewText,
      beoCheckViewText: dataMagineConfig.beoCheckViewText,
      accountDocSignExpiaryDate: Number(dataMagineConfig.accountDocSignExpiaryDate ?? 0),
      bookingDocSignExpiaryDate: Number(dataMagineConfig.bookingDocSignExpiaryDate ?? 0),
      proformaDocSignExpiaryDate: Number(dataMagineConfig.proformaDocSignExpiaryDate ?? 0),
      beoDocSignExpiaryDate: Number(dataMagineConfig.beoDocSignExpiaryDate ?? 0),
      beoCheckDocSignExpiaryDate: Number(dataMagineConfig.beoCheckDocSignExpiaryDate ?? 0),
    } as SNCDataMagineModel
  }
  private uiMapper(dataMagineConfig: DmConfig): UI.DmConfig {
    let eforms = dataMagineConfig?.dmEformsConfig;
    return {
      id: dataMagineConfig.id,
      enableDatamagine: dataMagineConfig.enableDataMagine,
      password: dataMagineConfig.password,
      product: dataMagineConfig.product,
      scannerEndPoint: dataMagineConfig.scannerEndpoint,
      serverurl: dataMagineConfig.serverURL,
      signCapEndPoint: dataMagineConfig.signatureCaptureEndpoint,
      signature: dataMagineConfig.captureSignature,
      username: dataMagineConfig.userName,
      enableEforms: eforms?.enableEforms,
      enableScanner: dataMagineConfig.enableIDScan,
      enableSignatureCapture: dataMagineConfig.enableSigCap,
      viewName:dataMagineConfig.viewName,
      postCheckReceiptToDM:dataMagineConfig.postCheckReceiptToDM,
      enablelink: dataMagineConfig.enableDmLink,
      authurl: eforms?.authURL,
      eformlisturl: eforms?.eformsListURL,
      eformurl: eforms?.eformsLinkURL,
      propertyID: eforms?.eformsPropertyId,
      showDuringCheckIn: eforms?.showDuringCheckIn,
      tenantID: eforms?.eformsTenantId,
      clientId: eforms?.clientId,
      clientSecret: eforms?.clientSecret,
      showPreview: dataMagineConfig.showPreview,
      dmEformConfigId: dataMagineConfig.dmEformsConfig?.id ?? 0,
      supportedLetter: dataMagineConfig.sncDataMagineAdditonalModel?.supportedLetter ?? [],
      accountViewText: dataMagineConfig.sncDataMagineAdditonalModel?.accountViewText ?? '',
      bookingViewText: dataMagineConfig.sncDataMagineAdditonalModel?.bookingViewText ?? '',
      proformaViewText: dataMagineConfig.sncDataMagineAdditonalModel?.proformaViewText ?? '',
      beoViewText: dataMagineConfig.sncDataMagineAdditonalModel?.beoViewText ?? '',
      beoCheckViewText: dataMagineConfig.sncDataMagineAdditonalModel?.beoCheckViewText ?? '',
      accountDocSignExpiaryDate: dataMagineConfig.sncDataMagineAdditonalModel?.accountDocSignExpiaryDate ?? 0,
      bookingDocSignExpiaryDate: dataMagineConfig.sncDataMagineAdditonalModel?.bookingDocSignExpiaryDate ?? 0,
      proformaDocSignExpiaryDate: dataMagineConfig.sncDataMagineAdditonalModel?.proformaDocSignExpiaryDate ?? 0,
      beoDocSignExpiaryDate: dataMagineConfig.sncDataMagineAdditonalModel?.beoDocSignExpiaryDate ?? 0,
      beoCheckDocSignExpiaryDate: dataMagineConfig.sncDataMagineAdditonalModel?.beoCheckDocSignExpiaryDate ?? 0,
      eFormConfigurations: {
        useEformSignCapture: eforms?.enableEformSigCap,
        showOnCheckin: eforms?.showSigCapDuringCheckIn,
        showOnPreview: eforms?.showPreview,
        searchTemplate: eforms?.sigCapDefaultTemplate,
        signEndPoint: eforms?.sigCapUrl,
        defaultTemplate: eforms?.enableSigCapDefaultTemplate,
      }
    } as UI.DmConfig;
  }

}


