import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormType } from './form-type.entity';
import { AgyDynamicFormServices } from './agilysys-dynamicform-business';
import * as _ from 'lodash';

@Component({
  selector: 'lib-agilysys-dynamicform',
  templateUrl: './agilysys-dynamicform.component.html',
  styleUrls: ['./agilysys-dynamicform.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysDynamicformComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('dynamicform', {  read: false, static: false }) dynamicform: ElementRef;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSubmit = new EventEmitter();
  @Output() formReturnValues = new EventEmitter();
  @Input() fields: FormType[];
  @Input() formValue: FormGroup;
  @Input() formGroupName: string;
  form: FormGroup;
  formUnsubscribe: Subscription;
  groupedClassName: string;
  groupingSet: Array<any> = [];
  constructor(public _FB: FormBuilder, public _AGY: AgyDynamicFormServices, public renderer: Renderer2) { }

  ngAfterViewInit(): void {
    this.formUnsubscribe = this.form.valueChanges.subscribe(x => {
      this.formReturnValues.emit(x);
    });
  }

  ngOnChanges(): void {
   this.fields.filter(x => x.groupedset).map(e => this.groupingSet.push(e) );
   this.form = this._AGY.createFormGroup(this.fields, this.formValue);
   this._AGY.primaryFormField = this.fields;
  }

  ngOnInit(): void {
  this.form = this._AGY.createFormGroup(this.fields, this.formValue);
  this._AGY.primaryFormField = this.fields;
  }


  ngDestroy(): void {
    this.formUnsubscribe.unsubscribe();
  }
}

