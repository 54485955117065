import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SpaLocalization } from "src/app/core/localization/spa-localization";

@Component({
  selector: "app-deposit-info-dialog",
  templateUrl: "./deposit-info-dialog.component.html",
  styleUrls: ["./deposit-info-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DepositInfoDialogComponent {
  okText = this._Localization.captions.alertPopup.okay;
  KindInfoHeader = this._Localization.captions.alertPopup.KindInfo;
  captions: any;

  constructor(
    public dialogRef: MatDialogRef<DepositInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Localization: SpaLocalization
  ) {
    this.captions = this._Localization.captions.bookAppointment;
  }

  /**
   * close dialog on ok click
   * @param result -- flag
   */
  dialogClose(result?: any): void {
    this.dialogRef.close(result);
  }

  /**
   * return positive deposit amount
   * @returns -- number
   */
  getRemainingAmount(amount: number) {
    if (amount) {
      let formattedAmount = this._Localization.localizeCurrency(amount, true);
      if (formattedAmount.includes("-")) {
        return `(${formattedAmount.replace("-", "")})`;
      }
      return formattedAmount;
    }
    return this._Localization.localizeCurrency(0, true);
  }
}
