import { Injectable } from '@angular/core';
import { CancellationNoShowMapping, CancellationNoShowMappingBaseDetails, CancellationNoShowMappingTableData, CancellationNoShowPolicy, CancellationNoShowPolicyBaseDetails, 
    MainGroup, ServiceBaseDetails, ServiceGroupsWithServices, SubGroup } from 'src/app/common/shared/shared/cancellation-no-show-policy/cancellation-no-show-policy.model';
import { Host } from 'src/app/shared/globalsContant';
import { HttpServiceCall, HttpMethod } from 'src/app/shared/service/http-call.service';
import { Localization } from 'src/app/common/localization/localization';
import { Guid } from 'guid-typescript';
import { DropdownOptions } from 'src/app/common/Models/ag-models';

@Injectable()
export class SpaCancellationNoShowFeeService {

    captions: any;
    getGuestTypeValue: { [Key: number]: string };
    constructor(private http: HttpServiceCall, private localization: Localization) {
        this.captions = this.localization.captions;
        this.getGuestTypeValue = {
            0: this.captions.lbl_existingClient,
            2: this.captions.lbl_member,
            3: this.captions.lbl_hotelReservation
        };
    }

    getGuestTypes() {
        return [
            {
                id: 0,
                name: this.captions.lbl_existingClient,
                selected: false
            },
            {
                id: 3,
                name: this.captions.lbl_hotelReservation,
                selected: false
            },
            {
                id: 2,
                name: this.captions.lbl_member,
                selected: false
            }
        ]
    }

    updateGuestTypes(applyToOptions: any[], selectedOptions: number[]): any[] {
        return applyToOptions.map(o => ({
            ...o,
            selected: selectedOptions.includes(o.id)
        }));
    }

    async getAllCancellationNoShowPolicies(includeInActive: boolean = true): Promise<CancellationNoShowPolicyBaseDetails[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllCancellationNoShowPolicies",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { includeInactive: includeInActive }
        });
        return response.result;
    }

    async getCancellationNoShowPolicyDetailsById(id: number): Promise<any> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetCancellationNoShowPolicyById",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { id: id }
        });
        return response.result;
    }

    async createOrUpdateCancellationNoShowPolicy(PolicyDetails: CancellationNoShowPolicy, isUpdate: boolean): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "CreateOrUpdateCancellationNoShowPolicy",
                host: Host.schedule,
                method: HttpMethod.Put,
                showError: true,
                body: PolicyDetails,
                uriParams: { isUpdate: isUpdate }
            });
            return response;
        }
        catch (error) {
            return error.error
        }
    }

    async deleteCancellationNoShowPolicy(id: number): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "DeleteCancellationNoShowPolicy",
                host: Host.schedule,
                method: HttpMethod.Delete,
                showError: true,
                uriParams: { id: id }
            });
            return response;
        }
        catch (error) {
            return error.error;
        }
    }

    async getAllCancellationNoShowMappings(includeInActive: boolean = true): Promise<CancellationNoShowMappingBaseDetails[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllCancellationNoShowMappings",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { includeInactive: includeInActive }
        });
        return response.result;
    }

    async getAllServicesGroupsWithServices(isClass: boolean): Promise<MainGroup[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllServicesGroupsWithServices",
            host: Host.spaManagement,
            method: HttpMethod.Get,
            showError: true,
            queryString: { key: "isClass", value: [isClass] }
        });
        return this.mapIsSelected(response.result);
    }

    mapIsSelected(data: ServiceGroupsWithServices[]): MainGroup[] {
        if (data && data.length > 0) {
            return data.map(serviceGroup => {
                return{
                    id: serviceGroup.id,
                    name: serviceGroup.name,
                    selected: false,
                    subGroup: this.mapServices(serviceGroup.services)
                } as MainGroup
            });
        }
        return [];
    }

    mapServices(services: ServiceBaseDetails[]) {
        if (services && services.length > 0) {
            return services.map(service => {
                return {
                    id: service.id,
                    name: service.name,
                    selected: false,
                } as SubGroup;
            });
        }
        return [];
    }

    async formObjectForPolicyMapping(mappingData: any): Promise<CancellationNoShowMapping[]> {
        let mappingObj: CancellationNoShowMapping[] = [];
        mappingData?.guestTypes?.forEach(async m => {
            mappingObj.push({
                id: 0,
                startDate: mappingData.startDate,
                endDate: mappingData.endDate,
                policyId: mappingData.policyId,
                guestType: m,
                mappingGroupId: mappingData.mappingGroupId,
                policyMapping: await this.mapPolicyMapping(mappingData)

            });
        });
        return mappingObj;
    }
    
    async mapPolicyMapping(mappingData: any) {
        var serviceGroup: number[] = [];
        var service: number[] = [];
        mappingData.policyMapping.forEach(a => {
            if (a.selected) {
                serviceGroup.push(a.id);
                a.subGroup.forEach(s => {
                    if (s.selected)
                        service.push(s.id);
                });
            }
        });
        const jsonObject = {
            "ServiceGroup": serviceGroup,
            "Service": service
        };
        return JSON.stringify(jsonObject);
    }
    
    async mapServiceAndServiceGroup(serviceData: MainGroup[], mappingData: string) {
        const jsonString = mappingData.replace(/(\w+):/g, '"$1":');
        const parsedObject = JSON.parse(jsonString);
        serviceData?.forEach(serviceGroup => {
            if (parsedObject?.ServiceGroup?.includes(serviceGroup.id)) {
                serviceGroup.selected = true;
            }
            serviceGroup?.subGroup?.forEach(service => {
                if (parsedObject?.Service?.includes(service.id)) {
                    service.selected = true;
                }
            });
        });
    }

    async searchCancellationNoShowPolicy(searchText: string): Promise<CancellationNoShowPolicyBaseDetails[]> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "SearchCancellationNoShowPolicy",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { searchText: searchText }
        });
        return response.result;
    }

    async getCancellationNoShowMapping(groupId: Guid): Promise<CancellationNoShowMappingBaseDetails> {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetCancellationNoShowMappingByGroupId",
            host: Host.schedule,
            method: HttpMethod.Get,
            showError: true,
            uriParams: { groupId: groupId }
        });
        return response.result;
    }

    async deleteCancellationNoShowMapping(groupId: Guid): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "DeleteCancellationNoShowMapping",
                host: Host.schedule,
                method: HttpMethod.Delete,
                showError: true,
                uriParams: { groupId: groupId }
            });
            return response;
        }
        catch (error) {
            return error.error
        }
    }

    async createOrUpdateCancellationNoShowMapping(mappingData: CancellationNoShowMapping[], isUpdate: boolean = false): Promise<any> {
        try {
            let response: any = await this.http.CallApiAsync({
                callDesc: "CreateOrUpdateCancellationNoShowMapping",
                host: Host.schedule,
                method: HttpMethod.Put,
                showError: true,
                body: mappingData,
                uriParams: { isUpdate: isUpdate }
            });
            return response;
        }
        catch (error) {
            return error.error
        }
    }

    async updatePolicyActive(policyId: number) {
        let response: any = await this.http.CallApiAsync({
            callDesc: "UpdatePolicyActive",
            host: Host.schedule,
            method: HttpMethod.Put,
            showError: true,
            uriParams: { policyId: policyId }
        });
        return response.result;
    }

    mapMappingData(mappingData: CancellationNoShowMappingBaseDetails[]): CancellationNoShowMappingTableData[] {
        var result: CancellationNoShowMappingTableData[] = [];
        mappingData.forEach(data => {
            result.push({
                id: data.policyId,
                name: data.name,
                code: data.code,
                applyTo: data.guestType.map(type => this.getGuestTypeValue[type]).join(', '),
                dateRange: this.localization.localizedDate(this.localization.ToDate(data.startDate, "YYYY-MM-DD")) + ' - ' + this.localization.localizedDate(this.localization.ToDate(data.endDate, "YYYY-MM-DD")),
                mappingGroupId: data.mappingGroupId,
                isInActive: data.isActive,
                dateSort: new Date(data.startDate).getTime()
            })
        })
        return result;
    }

    getchargeOptions(){
        return [
          {
            id: 1,
            value: this.captions.gratuity,
            viewValue: this.captions.gratuity
          },
          {
            id: 2,
            value: this.captions.serviceCharge,
            viewValue: this.captions.serviceCharge
          },
          {
            id: 3,
            value: this.captions.tax,
            viewValue: this.captions.tax
          },
          {
            id: 4,
            value: this.captions.bookAppointment.CustomFee,
            viewValue: this.captions.bookAppointment.CustomFee
          }
        ]
      }

    policyMapper(data) {
        return data.map(x => this.uiMapper(x));
    }

    uiMapper(option): DropdownOptions {
        return {
            id: option.id,
            value: option.id,
            viewValue: option.name,
            checked: false
        }
    }
}