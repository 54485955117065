import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SchedulingAssistantComponent } from 'src/app/shared/scheduling-assistant/scheduling-assistant.component';
import { AppointmentpopupService } from 'src/app/shared/service/appointmentpopup.service';

@Injectable()
export class AppointmentdetailsService implements OnDestroy {

  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  captions: any;
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef, private apptService: AppointmentpopupService, private localization: SpaLocalization) {
    this.captions = this.localization.captions;
  }
  openDialog(serviceTypeArr, selectedServiceType, services, selectedServices, therapists, locations, appointmentDate, tempHoldArr,
    propertyStartTime, propertyEndTime, appointmentId: number, callback: any, operatingHours?) {
    const panelClass = 'action-dialog-overlay';
    const width = '95%';
    const height = '90%';
    this.cdr.detach();

    let isTherapistOverBook = false;
    let isLocationOverBook = false;
    if (this.apptService.isOverbook) {
      isTherapistOverBook = this.apptService.isTherapistView;
      isLocationOverBook = !isTherapistOverBook;
      if (this.apptService.isFromAppointmentSearch) {
        isTherapistOverBook = true;
        isLocationOverBook = true;
      }
    } else if (this.apptService.resultExistingAppointment) {
      isLocationOverBook = this.apptService.resultExistingAppointment.appointmentDetail.isLocationOverbook;
      isTherapistOverBook = this.apptService.resultExistingAppointment.appointmentDetail.isTherapistOverbook;
    }

    const dialogRef = this.dialog.open(SchedulingAssistantComponent, {
      width: width,
      height: height,
      hasBackdrop: true,
      data: {
        title: this.captions.lbl_SchedulingAssistant,
        closebool: true,
        data: {
          serviceType: serviceTypeArr,
          selectedServiceType: selectedServiceType && selectedServiceType.length ? serviceTypeArr.filter(x => selectedServiceType.includes(x.id)) : serviceTypeArr,
          services: services,
          selectedServices: selectedServices,
          therapists: therapists,
          locations: locations,
          appointmentDate: appointmentDate,
          tempHoldIds: tempHoldArr,
          propertyStartTime: propertyStartTime,
          propertyEndTime: propertyEndTime,
          appointmentIds: appointmentId,
          isTherapistOverbook: isTherapistOverBook,
          isLocationOverbook: isLocationOverBook,
          operatingHours : operatingHours
        }
      },
      panelClass: panelClass
    });
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((result) => {
      this.cdr.reattach();
      if (result && result.action === 'continue' && result.selectedData && result.filterResponse && callback) {
        callback(result.filterResponse, result.selectedData, result.occupiedLocation);
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
}
