import { UiControlService } from 'src/app/eatecui/source/shared/services/ui-controls.service';
import { UiConfiguration } from './ui-control-config.interface';
import { UiControlConfiguration, UiControls } from './ui-controls.interface';

export const CheckStateUrl: UiConfiguration = (
    controlConfiguration: Array<UiControlConfiguration>, 
    RouterUrl: any,
    EatecUiControls: Array<UiControls>,
    uiControlService: UiControlService,
    RouterNavigateService ?: any,
    CustomData ?: any,
    ): any => {
        try {
            if ( controlConfiguration && RouterUrl ) {
                let authorizedRoute = false;
               const authorizedControl =  controlConfiguration.filter( x => x.AuthorizedKey === RouterUrl && x.IsAllowed);
               if (authorizedControl.length > 0) {
                  authorizedRoute = true;
                  authorizedControl[0].IsActiveRoute = true;
               }
                return authorizedRoute;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };