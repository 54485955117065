import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { PostedPOPopUp } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-posted-po',
  templateUrl: './agilysys-posted-po.component.html',
  styleUrls: ['./agilysys-posted-po.component.scss']
})
export class AgilysysPostedPoComponent implements OnInit {

  agilysysPostPOButton: AgilysysButtonModule[];
  jsonKeys: any;

  constructor(
    public dialogRef: MatDialogRef<AgilysysPostedPoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PostedPOPopUp) {
  }

  ngOnInit(): void {
    this.agilysysPostPOButton = [
      {
        displayProperity: false,
        displayName: 'Ok',
        buttonKey: 'Ok',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      }
    ];
    this.jsonKeys = Object.keys(this.data.RowData[0]);
  }

}
