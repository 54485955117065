import { Pipe, PipeTransform } from '@angular/core';
// import * as GlobalFormaters from '../../../assets/json/global-formater-config.json';
import moment from 'moment';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { DefaultIniDateFormat } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
export const GlobalFormater = [
  {
      SHORTDATE: 'MM-DD-YYYY',
      CREDITCARD: 4,
      CURRENCYCODE: '$'
  }
];
@Pipe({
  name: 'GlobalFormater'
})
export class GlobalFormaterPipe implements PipeTransform {

  transform(str: any, args: any): any {
    let returnValue = '-';
    if (str !== null && str !== undefined && str !== '' && args) {
      if (args.hasOwnProperty('Formatter')) {
        let FormaterType = null;
        Object.keys(GlobalFormater[0]).forEach(function (formater) {
          if (formater === args.Formatter) {
            FormaterType = formater;
          }
        });
        let returFormatValue: string;
        switch (FormaterType) {
          case 'SHORTDATE':
            str = str === '-' ? '' : str;
            const sessionDateFormat = StorageService.GetSessionStorage("IniDateFieldFormat") ? StorageService.GetSessionStorage("IniDateFieldFormat") : DefaultIniDateFormat;
            if (str) {
              if (typeof str === 'object') { // For Edit and New Scenario
                returFormatValue = moment(str).format(sessionDateFormat);
              } else { // For data coming edit and copy as new 
                // returFormatValue = moment.utc(str).local().format(sessionDateFormat);
                returFormatValue = moment(str).format(sessionDateFormat);
              }
            } else {
              returFormatValue = ( str && str !== '' && str !== 'Invalid date') ?  
              moment(str.toString()).format(sessionDateFormat) : '-';
            }
            break;
          case 'CREDITCARD':
            // const formaterValue = GlobalFormaters['default'][0][FormaterType];
            returFormatValue = str.replace(/\s+/g, '').replace(/(\d{formaterValue})/g, '$1 ').trim();
            break;
          case 'CURRENCYCODE':
            const BaseCurrencyId = parseInt(sessionStorage.getItem('BaseCurrency'), 10);
            const Currencies = constants.currencySymbol;
            const CurrencyCode = CommonService.getCurrencyCode(BaseCurrencyId);
            const symbol = Currencies.Symbols.filter(curr => curr.Code === CurrencyCode)[0].Symbol;
            returFormatValue = str ? `${symbol} ${str}` : '-';
            break;
          default:
            returFormatValue = (str) ? str : '-';
        }
        returnValue = returFormatValue;
      } else {
        returnValue = str;
      }
      
    }
    return returnValue;
  }
}


