import { BaseReport } from '../common/base-report';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import { ReportBusinessService } from '../report-business.service';
import { ReportAPIOptions, ReportParams } from 'src/app/retail/retail.modals';
import { ReportUIConfig, OutletAndCategoryFilter, AllReports } from '../report.modals';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';


export class TaxExemptSales extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private productId: number, private func: RetailFunctionalityBusiness, private propertyInfo: RetailPropertyInformation
        ,public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.TaxExemptSales,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'LANDSCAPE',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        if (Array.isArray(customFilterData.reportcategory) && !customFilterData.reportcategory.length)
            customFilterData.reportcategory = this.business.FilterDataSource["allCategoryGroups"];
        let reportcategoryCaption: string = this.business.getFilterCaption(this.business.FilterDataSource["allCategoryGroups"], customFilterData.reportcategory.map(x => x.id));
        let categoryCaption: string = this.business.getFilterCaption(this.business.FilterDataSource["categories"].filter(x => customFilterData.reportcategory.map(y => y.id).includes(x.categoryGroup)), customFilterData.category.filter(x => customFilterData.reportcategory.map(y => y.id).includes(x.categoryGroup)).map(x => x.id));
        let machineCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allMachineNames"],customFilterData.machineName.map(x=>x.id));
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) }, { "pPageBreak": this.pageBreakToggle },
         { "pOutletCaption":  this.getFilterCaption }
            , { "pReportCategoryCaption": reportcategoryCaption }, { "pCategoryCaption": categoryCaption }, { "pPropertyName": this.propertyName }
            , { 'pDate': this.printedDate }
        , {'pMachineName': machineCaption},
        {'pPrintMachineName': this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false},
        {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInTaxExemptSalesReport != undefined ? true : false },
        {'pIsIncludePaymentTaxExempt': customFilterData?.includePaymentTaxExempt ?? false}];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }




    protected formFilters() {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let toApi = this.localization.convertDateObjToAPIdate;
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        let categoryids = customFilterData.category.map(x => x.id);
        if (customFilterData.category.length == 0) {
            let category = [];
            if (customFilterData.reportcategory.length != 0)
                category = this.business.FilterDataSource["categories"].filter(x => customFilterData.reportcategory.map(y => y.id).includes(x.categoryGroup));
            else
                category = this.business.FilterDataSource["categories"].filter(x => this.business.FilterDataSource["allCategoryGroups"].map(y => y.id).includes(x.categoryGroup));
            categoryids = category.map(x => x.id);
        }
        let showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
        let isAllMachineSelected: boolean = this.business.checkAllSelected(this.business.FilterDataSource["allMachineNames"],customFilterData.machineName.map(x=>x.id));      
        const tempOutletId = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        const tempMachineNameIds = isAllMachineSelected ? [] : customFilterData.machineName.map(x => x.id);
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            OutletIds:  this.selectedFilterOptions.map(x => x.id),
            MachineNameIds: showMachineDropDown   ? tempMachineNameIds : [],
            CategoryIds: categoryids,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            };
        }
        let filter: Partial<OutletAndCategoryFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            OutletIds:  this.selectedFilterOptions.map(x => x.id),
            MachineNameIds: showMachineDropDown   ? tempMachineNameIds : [],
            CategoryIds: categoryids
        };
 
        return JSON.stringify(filter);
    }

}


