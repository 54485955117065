import { Injectable, OnInit } from '@angular/core';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import * as xml2js from 'xml2js';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import * as OdataConfig from 'src/app/eatecui/source/store/actions/odata-configuration.action';
import {
  CustomFilterNavigationUrl, GridFilterAction, MasterInventoryManagement, PrintFormConfig,
  PrinterModuleConfig,
  SubFormConfigs, TransactionMasterData
} from '../interface/master.interface';
import { LoaderService } from 'src/app/eatecui/source/core/services/loader.service';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
  AutoCompleteData, GridActionsConfig, GridColumnReorder, GridConfig, GridFilter,
  GridFilterData, GridFilterValue, GridInfiniteScrollConfig, GridPaginationConfig, GridResizeRows, GridSearchConfig, TitleType
} from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { TranslateService } from '@ngx-translate/core';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { GridInterface } from '../interface/grid-interface';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { GlobalSystemPreference } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { MasterKeys, MasterName } from '../enum/master-enum';
import { HttpClient } from '@angular/common/http';
import { MasterZoomInInterface } from '../interface/master-zoom-in';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { CacheType } from 'src/app/eatecui/source/shared/enum/global.enum';
import { RequestCache } from 'src/app/eatecui/source/core/interceptors/services/request-cache.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { ConfirmPopModel, ErrorPopModel, PrintPopModel, PrintPopUpCallBack } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { MatTabGroup } from '@angular/material/tabs';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { BehaviorSubject } from 'rxjs';
import { PrintService } from 'src/app/eatecui/source/shared/services/print-form/print.service';
import { GenerateReportFilterModel, GenerateReportModel } from 'src/app/eatecui/source/reports/interface/reports.interface';
import { AgilysysPrintPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-print-popup/agilysys-print-popup.component';
import { AgilysysSessionExpiredComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-session-expired/agilysys-session-expired.component';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})


export class MasterManagementService implements OnInit {
  cloneGridData: any = [];
  TransactionCloneGridData: any;
  CreateCloneGridData: any;
  RowData: any;
  matTabGroup: MatTabGroup;
  IframeTrigerEvent = new BehaviorSubject<any>(null);
  retailProduct: Array<MasterInventoryManagement>;
  retailJson: Array<MasterInventoryManagement> = [];
  fbProduct: Array<MasterInventoryManagement> = [];
  masterJosnData: Array<MasterInventoryManagement> = [];
  fbInventoryJson: Array<MasterInventoryManagement> = [];
  ReceipeJson: Array<MasterInventoryManagement> = [];
  MasterFormGroup: any;
  FlowMode: any;
  LinkBidsEditCheck: any;
  PostDeactivate: any;
  getPictureDetails = new BehaviorSubject<boolean>(false);
  PreviousMasterFormGroup: any;
  SupplierExchangeRate: number;
  constructor(
    private envService: EnvService,
    private store: Store<AppState>,
    private loaderService: LoaderService, private httpService: HttpService,
    private translateService: TranslateService, private http: HttpClient,
    private dialog: MatDialog, private requestCache: RequestCache,
    private routerNavigateService: RouteNavigateService,
    private printService: PrintService,
    private commonService: CommonService, private toasterService: ToastrService) {
    this.IframeTrigerEvent.next(null);
  }

  getPopupSource() {
    return this.IframeTrigerEvent.asObservable();
  }
  getIngredientsJson(selectedMaster: string, selectedInventoryType: number) {
    try {
      // const FBJson: MasterInventoryManagement = (FBInventoryJson as any).default;
      // const MasterJson: MasterInventoryManagement[] = (MasterJsonObject as any).default;
      const FBJson: Array<MasterInventoryManagement> = this.fbInventoryJson;
      const RetailJson: MasterInventoryManagement = this.masterJosnData.filter(x => x.Key === selectedMaster)[0];
      if (selectedInventoryType === 1) {
        return FBJson;
      } else {
        return [RetailJson];
      }
    } catch (error) {
      console.error('Error occurred in getIngredientsJson', error);
    }
  }

  getCustomerJson(selectedMaster: string, selectedInventoryType: number) {
    try {
      const MasterJson: MasterInventoryManagement[] = this.masterJosnData;
      const CustomerJson: MasterInventoryManagement = MasterJson.filter(x => x.Key === selectedMaster)[0];
      return [CustomerJson];
    } catch (error) {
      console.error('Error occurred in getIngredientsJson', error);
    }
  }

  getSupplierJson(selectedMaster: string, selectedInventoryType: number) {
    try {
      const MasterJson: MasterInventoryManagement[] = this.masterJosnData;
      const SupplierJson: MasterInventoryManagement = MasterJson.filter(x => x.Key === selectedMaster)[0];
      return [SupplierJson];
    } catch (error) {
      console.error('Error occurred in getIngredientsJson', error);
    }
  }

  getPackagesJson(selectedMaster: string, selectedInventoryType: number) {
    try {
      const MasterJson: MasterInventoryManagement[] = this.masterJosnData;
      const SupplierJson: MasterInventoryManagement = MasterJson.filter(x => x.Key === selectedMaster)[0];
      return [SupplierJson];
    } catch (error) {
      console.error('Error occurred in getIngredientsJson', error);
    }
  }
  getReceipeJson(selectedMaster: string, selectedInventoryType: number) {
    try {
      // const MasterReceipe: MasterInventoryManagement[] = (ReceipeJson as any).default;
      const MasterReceipe: MasterInventoryManagement[] = this.ReceipeJson;
      return MasterReceipe;
    } catch (error) {
      console.error('Error occurred in getReceipeJson', error);
    }
  }

  getGroupJson(selectedMaster: string, selectedInventoryType: number) {
    try {
      const MasterJson: MasterInventoryManagement[] = this.masterJosnData;
      const SupplierJson: MasterInventoryManagement = MasterJson.filter(x => x.Key === selectedMaster)[0];
      return [SupplierJson];
    } catch (error) {
      console.error('Error occurred in getIngredientsJson', error);
    }
  }

  getProductJson(selectedMaster: string, selectedInventoryType: number) {
    try {
      // const FBProductJson: MasterInventoryManagement[] = (FBProduct as any).default;
      // const RetailProductJson: MasterInventoryManagement[] = (RetailProduct as any).default;
      const FBProductJson: MasterInventoryManagement[] = this.fbProduct;
      const RetailProductJson: MasterInventoryManagement[] = this.retailProduct;
      if (selectedInventoryType === 1) {
        return FBProductJson;
      } else {
        return RetailProductJson;
      }
    } catch (error) {
      console.error('Error occurred in getIngredientsJson', error);
    }
  }

  /**
   *@method GetMasterJsonData()
   *@params jsonData and odatakey
   *@description getjsonresponseform the data
   * */
  GetMasterJsonData(selectedMaster: string, selectedInventoryType: number = null): any {
    try {
      let masterJsonData: Array<MasterInventoryManagement> = [];
      // eslint-disable-next-line no-debugger
      switch (selectedMaster) {
        case MasterName.MasterIngredients:
          masterJsonData = this.getIngredientsJson(selectedMaster, selectedInventoryType);
          break;
        case MasterName.MasterCustomers:
          masterJsonData = this.getCustomerJson(selectedMaster, selectedInventoryType);
          break;
        case MasterName.MasterSuppliers:
          masterJsonData = this.getSupplierJson(selectedMaster, selectedInventoryType);
          break;
        case MasterName.MasterProducts:
          masterJsonData = this.getProductJson(selectedMaster, selectedInventoryType);
          break;
        case MasterName.MasterPackages:
          masterJsonData = this.getPackagesJson(selectedMaster, selectedInventoryType);
          break;
        case MasterName.MasterGroup:
          masterJsonData = this.getGroupJson(selectedMaster, selectedInventoryType);
          break;
        case MasterName.MasterReceipe:
          masterJsonData = this.getReceipeJson(selectedMaster, selectedInventoryType);
          break;
      }
      return masterJsonData;
    } catch (error) {
      console.error(error);
    }
  }

  // async GetMasterData(MasterJsonKey: string) {
  //    try {
  //     const _that = this;
  //     return new Promise<any>( async function(resolve, reject) {
  //       const getMaster = '/assets/json/master/' + MasterJsonKey + '.json';
  //       const tt = await _that.http.get<MasterInventoryManagement[]>(getMaster).toPromise()
  //       tt.map(x=>{
  //         resolve(x);
  //       })

  //      });
  //    } catch(error) {
  //      console.error(error);
  //    }
  // }

  /**
   * GetViewModelData(ViewModelEntity, EntityKey)
   * @params ViewModelEntity
   * @params EntityKey
   * @description filter the view Model Entity
   */

  GetViewModelData(ViewModelEntity: Array<any>, EntityKey: string) {
    try {
      const viewModelEntity = ViewModelEntity.filter(x => {
        for (const prop in x) {
          if (prop) {
            if (prop === '$') {
              const entityName = x[prop].Name;
              if (entityName === EntityKey) {
                return true;
              }
            }
          }
        }
      });
      return viewModelEntity;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * GetViewModelData(ViewModelEntity, EntityKey)
   * @params ViewModelEntity
   * @params EntityKey
   * @description filter the view Model Entity
   */

  GetPostModelData(PostModelData: Array<any>, EntityKey: string) {
    try {
      const viewModelEntity = PostModelData.filter(x => {
        for (const prop in x) {
          if (prop) {
            if (prop === '$') {
              const entityName = x[prop].Name;
              if (entityName === EntityKey) {
                return true;
              }
            }
          }
        }
      });
      return viewModelEntity;
    } catch (error) {
      console.error(error);
    }
  }

  /**
 * @method getEntity(entitySchema: Array<any>, entityName: string)
 * @input params Entity Name
 * @description split the entity name
 */
  getEntity(EntitySchema: Array<any>, EntityName: string): Array<any> {
    const entityArray = EntitySchema.filter(x => x['$']['Namespace'] === EntityName)[0].EntityType;
    return entityArray;
  }

  /**
   * @methoda GetOdataGlobalCollection()
   * @params NOne
   * @description Get Odata Global Collection
   */
  GetOdataGlobalCollection(): void {
    try {
      const HTTP = new XMLHttpRequest();
      const url = this.envService.apiUrl + CONSTANT.ENDPOINTURL['OdataInventoryManagementApi']['GetMetaData'];
      // const url = 'https://localhost:44353/api/$metadata';
      const _that = this;
      let userSessionId = sessionStorage.getItem('userSession'); 
      let token = sessionStorage.getItem("_jwt");
      HTTP.open('GET', url);
      HTTP.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      HTTP.setRequestHeader("SessionId", userSessionId ? userSessionId : "");
      HTTP.setRequestHeader("Authorization", token ? 'Bearer ' + token : "");
      HTTP.send();
      HTTP.onreadystatechange = function (e) {
        _that.loaderService.isLoading.next(true);
        if (this.status === 200) {
          _that.loaderService.isLoading.next(true);
          if (e.target as XMLHttpRequest) {
            _that.loaderService.isLoading.next(true);
            xml2js.parseString(this.response, function (err: any, result: any) {
              _that.loaderService.isLoading.next(true);
              if (result && result !== null && result !== 'null') {
                _that.loaderService.isLoading.next(false);
                const OdataGlobal = {} as OdataGlobalEntity;
                const entitySchema = result['edmx:Edmx']['edmx:DataServices'][0].Schema;
                const viewModelEntity = _that.getEntity(entitySchema, 'Agilysys.VisualOne.Retail.Management.Infrastructure.Entities.Eatec');
                const postModelEntity = _that.getEntity(entitySchema, 'Agilysys.VisualOne.Retail.Management.Domain.Model.Eatec');
                const transactionViewModelEntity = _that.getEntity(entitySchema, 'Agilysys.VisualOne.Retail.Management.Domain.Model.Eatec');
                const transactionPostModelEntity = _that.getEntity(entitySchema, 'Inventory.Entities.TransactionEntities');
                OdataGlobal.OdataPostModelEntity = [...postModelEntity, ...transactionPostModelEntity];
                OdataGlobal.OdataViewModelEntity = [...viewModelEntity, ...transactionViewModelEntity];
                _that.store.dispatch(new OdataConfig.GetInventoryOdataSucess(OdataGlobal));
              }
            });
          }
        } else {
          _that.loaderService.isLoading.next(false);
        }
      };
    } catch (error) {
      this.loaderService.isLoading.next(false);
      console.error(error);
    }
  }

  /***cell Value change */
  cellValueChanged(cellParams: any): void {
    try {
      if (
        cellParams && cellParams.colDef &&
        cellParams.colDef.hasOwnProperty('cellEditorParams') &&
        cellParams.colDef.cellEditorParams && cellParams.colDef.cellEditorParams.hasOwnProperty('type') &&
        cellParams.colDef.cellEditorParams.type === 'Divide') {
        const rowNode = cellParams.api.getRowNode(cellParams.rowIndex);
        const dividePrimaryValue = cellParams.colDef.cellEditorParams.operandaPramPrimary;
        const divideSecondaryValue = cellParams.colDef.cellEditorParams.operandaPramSecondary;
        const primaryValue = cellParams.data[dividePrimaryValue];
        const secondaryValue = cellParams.data[divideSecondaryValue];
        if (primaryValue !== '' && secondaryValue !== '') {
          const dividedValue = parseFloat(primaryValue) / parseFloat(secondaryValue);
          cellParams.data[cellParams.colDef.cellEditorParams.setTo] = dividedValue;
          rowNode.setDataValue(cellParams.colDef.cellEditorParams.setTo, Formater('FixedFormat', dividedValue, null, null));
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  AssignGridData(dataObject, SubForm: SubFormConfigs, ChildNavigationProperty: any, MasterDataInfo): any {
    try {
      const gridData = {} as GridConfig;
      const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
      // if (SubForm.Name === 'Transactions') {
      //   SubForm.NestedGridData.GridHeaderDefination = this.rearrangeGridHeader(SubForm, dataObject, MasterDataInfo);
      // }
      if (dataObject!= null && dataObject[0]) {
        if (SubForm.Name === 'Transactions' || SubForm.Name === 'CrossReference') {
          gridData.GridData = this.assignRowData(dataObject, ChildNavigationProperty);
        } else {
          gridData.GridData = this.assignRowData(dataObject[0][SubForm.OdataKey], ChildNavigationProperty);
          gridData.GridData = gridData.GridData.filter(x => x.IsActive === true);
        }
      } else {
        gridData.GridData = [];
      }
      let headerDefs = [];
      if (SubForm.NestedGridData) {
        headerDefs = SubForm.NestedGridData.GridHeaderDefination.map(values => {
          const text = MasterDataInfo.GridConfiguration.GridOdataKey + '.' + values.field;
          this.translateService.get(text).subscribe(res => {
            values.headerName = res;
          });
          if (values.type === 'dateColumn') {
            values.valueFormatter = Formaters.dateformater;
          } else if (values.columnType === 'decimal') {
            values.valueFormatter = Formaters.decimalFormater;
          } else if (values.columnType === 'currency') {
            values.valueFormatter = Formaters.currencyFormater;
          }
          if (values.cellRenderer !== 'actionRenderer') {
            values.resizable = true;
            values.minWidth = '75';
            values.tooltipField = values.field;
          }
          return values;
        });
      }
      gridData.GridHeaderDefination = headerDefs;
      gridData.TotalRecords = gridData.GridData ? gridData.GridData.length : 0;
      gridData.GridActions = {} as GridActionsConfig;
      gridData.GridActions.GridTitle = SubForm.DisplayName;
      if (SubForm.NestedGridData.GridTitleType) {
        gridData.GridActions.GridTitleType = {} as TitleType;
        gridData.GridActions.GridTitleType.Type = SubForm.NestedGridData.GridTitleType.Type;
        gridData.GridActions.GridTitleType.TypeConfig = SubForm.NestedGridData.GridTitleType.TypeConfig;
      }
      gridData.GridActions.TotalItems = gridData.GridData ? gridData.GridData.length : 0;
      gridData.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
      gridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
      gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
      gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
      gridData.GridActions.FilterConfig = {} as GridFilter;
      gridData.GridActions.FilterConfig.EnableFilter = false;
      gridData.GridActions.FilterConfig.FilterOptions = [];
      if (SubForm.NestedGridData.GridSearch.length === 1) {
        gridData.GridActions.SearchConfig = {} as GridSearchConfig;
        gridData.GridActions.SearchConfig.EnabledSearch = SubForm.NestedGridData.GridSearch &&
          SubForm.NestedGridData.GridSearch.length > 0 ? true : false;
        if (gridData.GridActions.SearchConfig.EnabledSearch) {
          gridData.GridActions.SearchConfig.SearchPlaceholder = SubForm.NestedGridData.GridSearch[0].SearchPlaceholderName;
          gridData.GridActions.SearchConfig.SearchPlaceholder = ProductDataRemap.FieldDisplayNameEngine(
            productremapJson, SubForm.OdataKey, `Search ${gridData.GridActions.SearchConfig.SearchPlaceholder}`,
            `Search ${gridData.GridActions.SearchConfig.SearchPlaceholder}`, this.translateService);
          gridData.GridActions.SearchConfig.SearchKey = SubForm.NestedGridData.GridSearch &&
            SubForm.NestedGridData.GridSearch.length > 0 ?
            SubForm.NestedGridData.GridSearch[0].SearchKey : SubForm.NestedGridData.GridActions.SearchConfig.SearchKey;
        }
      }
      gridData.GridActions.FilterConfig = {} as GridFilter;
      gridData.GridActions.FilterConfig.EnableFilter = SubForm.NestedGridData.GridFilter.length > 0 ? true : false;
      if (gridData.GridActions.FilterConfig.EnableFilter) {
        this.getFilterOptions(SubForm.NestedGridData, ChildNavigationProperty,
          this.httpService).then(x => {
            gridData.GridActions.FilterConfig.FilterOptions = x;
            gridData.GridActions.FilterConfig.EnableFilter = x.length > 0 ? true : false;
          });
      } else {
        gridData.GridActions.FilterConfig.FilterOptions = [];
      }
      gridData.GridActions.PaginationConfig = {} as GridPaginationConfig;
      gridData.GridActions.PaginationConfig.EnabledPaginationation = false;
      gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
      gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
      gridData.dropdownChange = SubForm.NestedGridData.dropdownChange;
      return gridData;
    } catch (error) {
      console.error('Error occurred in AssignGridData', error);
    }
  }

  assignRowData(ResponseData, ViewModelEntity) {
    try {
      if (ResponseData) {
        ResponseData.forEach(dataobject => {
          for (const prop in dataobject) {
            if (prop) {
              // dataobject[prop] = Formater(prop, dataobject[prop], null, ViewModelEntity);
              if (dataobject[prop] && dataobject[prop] !== null) {
                const propType = typeof dataobject[prop];
                if (propType === 'object') {
                  // const objProperity = dataobject[prop];
                  this.assignGridNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                }
              }
            }
          }
        });
        return ResponseData;
      } else {
        return [];
      }
    } catch (error) {
      console.log('error occured assignRowData', error);
    }
  }
  assignGridNavigationProperity(ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
      ViewModelEntityColl &&
      ViewModelEntityColl.hasOwnProperty('NavigationProperty') &&
      ViewModelEntityColl['NavigationProperty'].length > 0) {
      ViewModelEntityColl['NavigationProperty'].forEach((element: any) => {
        const navProperiperityName = element['$']['Name'].toLowerCase().toString();
        if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
          //  if (element['ReferentialConstraint']) {
          //     const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toString();
          entityObject[ProperityName] = ProperirtyObject['Name'];
          //  }
        }
      });
    }
    return entityObject;
  }

  getFilterOptions(MasterDataCollection, ViewModelEntity, httpService): any {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    return new Promise<any>((resolve, reject) => {
      const GridFilterRowData = [];
      MasterDataCollection.GridFilter.forEach((GridFilterDataObj: GridFilterAction) => {
        const navigationProp = {} as CustomFilterNavigationUrl;
        let originalkeyname = '';
        const Navigation = ViewModelEntity['NavigationProperty']
          .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] === GridFilterDataObj.FilterOdataKey);
        if (Navigation.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          ViewModelEntity['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
            const NavigationProperityName = NavigationProperty['$']['Name'];
            if (NavigationProperityName === GridFilterDataObj.FilterOdataKey) {
              let NavigationReferentialConstraint = '';
              if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                originalkeyname = NavigationProperty['ReferentialConstraint'][0]['$']['Property'];
              }
              const EntityNameList = NavigationProperty['$']['Type'].split('.');
              let EntityName = '';
              if (EntityNameList.length > 0) {
                EntityName = EntityNameList[EntityNameList.length - 1];
                navigationProp.RequestUrl = `/inventory/api/${EntityName}?$filter=IsActive eq true`;
                if (EntityName === 'Suppliers') {
                  navigationProp.RequestUrl = navigationProp.RequestUrl + `&$select=Id,Name`;
                }
                navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                navigationProp.EnabledCustomField = false;
              }
            }
          });
        } else {
          if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('CustomFilters') &&
            GridFilterDataObj.CustomFilters.EnableCustomFilter) {
            navigationProp.RequestUrl = GridFilterDataObj.CustomFilters.CustomNavigationUrl;
            navigationProp.EnabledCustomField = true;
            navigationProp.NavigationContraintName = GridFilterDataObj.FilterOdataKey;
          }
        }
        if (navigationProp && navigationProp.RequestUrl !== null) {
          httpService.GethttpMethod(navigationProp.RequestUrl).subscribe((response: any) => {
            if (response && response.value && response.value.length > 0) {
              const filterKeyData = response.value.map((data: any) => {
                const filterKey = {} as GridFilterValue;
                filterKey.FilterId = GridInterface.GridFilterKeyMapping(GridFilterDataObj, data);
                filterKey.FilterName = GridInterface.GridFilterLabelMapping(GridFilterDataObj, data);
                filterKey.Checked = false;
                return filterKey;
              });
              const filterDisplay = {} as GridFilterData;
              filterDisplay.FilterKey = GridFilterDataObj.FilterDisplayName;
              filterDisplay.FilterValue = filterKeyData;
              filterDisplay.FilterOriginalKey = originalkeyname;
              filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
              filterDisplay.Type = 'multiselect';
              GridFilterRowData.push(filterDisplay);
            }
            resolve(GridFilterRowData);
          },
            (responseError) => {
              console.error('error caught in component', responseError);
            });
        }
      });
    });
  }

  masterDecodeUri(DataQueryParams: any, paramsKey: string, NumberEncode: any = null): any {
    try {
      if (DataQueryParams && DataQueryParams.hasOwnProperty('data')) {
        const decodeParams = this.isValidJsonData(decodeURIComponent(DataQueryParams.data)) ?
          JSON.parse(decodeURIComponent(DataQueryParams.data)) : '';
        let decMasterParams = decodeParams ? decodeParams[paramsKey] : '';
        if (NumberEncode !== null) {
          decMasterParams = decodeParams ? Number(decodeURI(decodeParams[NumberEncode])) : '';
        }
        return decMasterParams;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getTranslation(EntityName: any, FieldName: any) {
    try {
      return this.translateService.instant(EntityName + '.' + FieldName);
    } catch (error) {
      console.error('Error occurred in getTranslation', error);
    }
  }

  getPrintForm(InventoryId, PrintInfo: PrintFormConfig) {
    PrintInfo.DynamicId = InventoryId;
    this.printService.getPrintForm(PrintInfo);
  }

  async getPrintTemplates(InventoryId, PrintInfo: PrintFormConfig) {
    // const Printenpoint = '/reportdata/api/ReportModule?$select=Id,Name&$expand=Reports($filter=IsActive eq true and IsPrint eq true;$select=Id,Name,FileName,Description)&$filter=IsActive eq true And Reports/any(c: c/IsActive eq true and c/IsPrint eq true)';
    // const getPrintList = await this.httpService.GethttpMethod(Printenpoint).toPromise();
    const PrintId = await this.commonService.getPrintId('InventoryLabelPrint');
    const getPrintList = await this.commonService.getPrintListById(PrintId);
    const EnablePopupResult: PrintPopUpCallBack = await this.OpenPrintList(this.dialog, getPrintList, 'InventoryLabelPrint', 'Inventory Label', PrintId, true);
    if (EnablePopupResult && EnablePopupResult.ButtonKey === 'Confirm' && EnablePopupResult.SelectedId !== null) {
      const generateReportModel = {} as GenerateReportModel;
      generateReportModel.reportFormat = 0;
      generateReportModel.fileName = null;
      generateReportModel.inLine = false;
      generateReportModel.sortBy = 'string';
      generateReportModel.reportid = EnablePopupResult.SelectedId;
      generateReportModel.isAsc = true;
      generateReportModel.isRetail = true;
      const generateReportFilterModel = {} as GenerateReportFilterModel;
      generateReportFilterModel.property = 'id';
      generateReportFilterModel.value = InventoryId.toString();
      generateReportFilterModel.isExclude = false;
      const generateReportFilterModel2 = {} as GenerateReportFilterModel;
      generateReportFilterModel2.property = 'Count';
      generateReportFilterModel2.value = EnablePopupResult.printCount.toString();
      generateReportFilterModel2.isExclude = false;
      generateReportModel.filters = [generateReportFilterModel, generateReportFilterModel2];
      const postUrl = `/ReportData/api/GenerateReport`;
      const reportData = await this.httpService.PosthttpMethod(postUrl,
        generateReportModel, null, { observe: 'response', responseType: 'blob' }).toPromise();
      const file = new Blob([reportData.body], { type: reportData.body.type });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }
  /**
   * @methodg etTemplates
   * @param InventoryId 
   * @param PrintInfo 
   */
  async getTemplates(selectedId: any, PrintInfo: PrintFormConfig, GetPrinterList: Array<any>, printerModuleConfig: PrinterModuleConfig) {
    const port = parseInt(localStorage.getItem('PrinterPort')?.toString());
    if (port && port > -1) {
      const responseData = [{ locationid: null }]
      this.printService.getPrinter(null, null, PrintInfo, selectedId, responseData, GetPrinterList, printerModuleConfig);
    } else {
      const toastMessage = `${this.translateService.instant('Common.configurePort')}`
      this.toasterService.error(toastMessage,
        '', { timeOut: 3000, closeButton: true });
    }
  }
  /**
   * changeInventoryTypeName
   */
  changeInventoryTypeName(globalPreference: Array<GlobalSystemPreference>, defaultDisplayName: string): string {
    try {
      let inventoryName = defaultDisplayName;
      const getLookupId = globalPreference[0].LookupValue;
      if (getLookupId === 1) {
        inventoryName = `${defaultDisplayName} - F&B`;
      } else if (getLookupId === 2) {
        inventoryName = `${defaultDisplayName} - Retail`;
      } else {
        inventoryName = `${defaultDisplayName}`;
      }
      return inventoryName;
    } catch (error) {
      console.error(error);
    }
  }


  rowNameIconClicked(value, OdataGlobalEntityModel, JsonData) {
    try {
      const LinkItemType = value.data.LinkItemType ? value.data.LinkItemType : value.data.LinkTypeId;
      const EntityName = LinkItemType === 1 ? 'Ingredients' : LinkItemType === 2 ? 'Recipes' : 'Recipes';
      const ViewModelObject = OdataGlobalEntityModel.OdataViewModelEntity.filter(x => x['$'].Name === EntityName);
      const MasterDetailEntity = OdataGlobalEntityModel.OdataViewModelEntity.filter(x => x['$'].Name === EntityName);
      const MasterHeaderEntity = null;
      MasterZoomInInterface.MasterDetails(
        ViewModelObject, MasterDetailEntity, JsonData, this.httpService, this.dialog, this.translateService, MasterHeaderEntity, value
      );
    } catch (error) {
      console.error('Error occured in rowNameIconClicked', error);
    }
  }

  contractNutrientsPostModel(HomeGroup: FormGroup, NutrientsData: MasterInventoryManagement) {
    try {
      if (HomeGroup && NutrientsData && NutrientsData.NestedFormConfig.EditFormConfig) {
        const getNutrients = NutrientsData.NestedFormConfig.EditFormConfig.filter(x => x.Name === 'Nutrients');
        const getOdataKey = (getNutrients && getNutrients.length > 0) ? getNutrients[0].OdataKey : 'IngredientsNutritions';
        const nutrientData = HomeGroup.controls[getOdataKey];
        const createNutrientsObject = {};
        if (nutrientData) {
          const nutirntsObject = HomeGroup.controls[getOdataKey]['controls'];
          for (const nutrientsKey in nutirntsObject) {
            if (nutrientsKey) {
              createNutrientsObject[nutrientsKey] = nutirntsObject[nutrientsKey].value;
            }
          }
          const getNutrientGridColl = NutrientsData.NestedFormConfig.EditFormConfig.filter(x => x.OdataKey === getOdataKey);
          if (getNutrientGridColl && getNutrientGridColl.length > 0) {
            if (getNutrientGridColl[0].hasOwnProperty('caloriesCalculation') &&
              getNutrientGridColl[0].caloriesCalculation.CaloriesGrid.CaloriesData.length > 0) {
              getNutrientGridColl[0].caloriesCalculation.CaloriesGrid.CaloriesData.forEach((x, Index: number) => {
                createNutrientsObject[`Nu${x['USDA_No']}`] = x['NutrientValue2'];
              });
            }
          }
          createNutrientsObject['Source'] = 1;
          createNutrientsObject['IsActive'] = true;
        }
        return Object.assign({}, createNutrientsObject);
      }
    } catch (error) {
      console.error(error);
    }
  }

  defineProduct(masterDataCollection: MasterInventoryManagement, data, nestedId,
    selectedPreferenceInventoryId, SelectedTypeId, moduleId = 0, dataFrom) {
    try {
      if ((data.Name === 'DefineProducts'
        && masterDataCollection.DetailsConfig.AlreadyDefinedMaster)) {
        const popupContent = this.getTranslation('Common', 'ProductDefine');
        const ConfirmPopUpDialog: ConfirmPopModel = {
          PopupHeaderContent: this.getTranslation('Common', 'Confirmation'),
          PopUpContent: popupContent,
        };
        const DialogRef = this.dialog.open(AgilysysConfirmationPopupComponent, {
          width: '400px',
          height: '222px',
          data: ConfirmPopUpDialog,
          disableClose: true
        });
        DialogRef.afterClosed().subscribe(result => {
          if (result === 'Confirm') {

            if (data.Name === 'DefineProducts'
              && masterDataCollection.DetailsConfig.FinishedGoodMaster) {
              let popupContents = this.getTranslation('Common', 'RecipeProductDefine');
              popupContents = popupContents.replace('{RecipeName}', masterDataCollection.DetailsConfig.DetailsObservation);
              const ConfirmPopUpDialogs: ConfirmPopModel = {
                PopupHeaderContent: this.getTranslation('Common', 'Confirmation'),
                PopUpContent: popupContents,
              };
              const DialogRefs = this.dialog.open(AgilysysConfirmationPopupComponent, {
                width: '400px',
                height: '222px',
                data: ConfirmPopUpDialogs,
                disableClose: true
              });
              DialogRefs.afterClosed().subscribe(results => {
                if (results === 'Confirm') {
                  this.navigateToProduct(data, nestedId, SelectedTypeId, selectedPreferenceInventoryId, moduleId, dataFrom);
                }
              });
            } else {
              this.navigateToProduct(data, nestedId, SelectedTypeId, selectedPreferenceInventoryId, moduleId, dataFrom);
            }
          }
        });
      } else {
        const recipeObj = masterDataCollection.GridConfig.GridData.filter(g => g.id === nestedId)[0];
        if (recipeObj && recipeObj.isbutcheryrecipe) {
          const inventoryItemsLinked = masterDataCollection.NestedFormConfig.ViewFormConfig.filter(y => y.Name === 'InventoryItems')[0];
          if (inventoryItemsLinked && inventoryItemsLinked.NestedGridData?.GridData && inventoryItemsLinked.NestedGridData.GridData.length > 0) {
            this.navigateToProduct(data, nestedId, SelectedTypeId, selectedPreferenceInventoryId, moduleId, dataFrom);
          } else if (inventoryItemsLinked && inventoryItemsLinked.NestedGridData?.GridData && inventoryItemsLinked.NestedGridData.GridData.length === 0) {
            const ConfirmPopUpDialog: ConfirmPopModel = {
              PopupHeaderContent: 'Info',
              PopUpContent: `Cannot define FG. Mandatory to add any one raw inventory item as Ingredient to Recipe ${recipeObj.name} to define as FG`
            };
            this.dialog.closeAll();
            const dialogRef = this.dialog.open(AgilysysSessionExpiredComponent, {
              width: '510px',
              height: '222px',
              data: ConfirmPopUpDialog,
              disableClose: true
            });
          }
        } else {
          this.navigateToProduct(data, nestedId, SelectedTypeId, selectedPreferenceInventoryId, moduleId, dataFrom);
        }
      }
    } catch (error) {
      console.error('Error occurred in defineProduct', error);
    }
  }

  navigateToProduct(data, nestedId, SelectedTypeId, selectedPreferenceInventoryId, moduleId, dataFrom) {
    const masterParams = {
      queryParams: {
        master: data.OdataKey, masterId: nestedId,
        inventorytype: SelectedTypeId,
        systemPreference: selectedPreferenceInventoryId,
        moduleId: moduleId,
        dataFrom: dataFrom
      }
    };
    this.requestCache.manageCacheEngine(CacheType.CLEARALL);
    this.routerNavigateService.navigateRoute(data.RoutingLinkUrl, masterParams);
  }
  rearrangeGridHeader(SubForm: SubFormConfigs, dataObject, MasterDataInfo: TransactionMasterData) {
    try {
      if (MasterDataInfo.DetailsName === 'ReqDetails' || MasterDataInfo.DetailsName === 'TransferDetails' ||
        MasterDataInfo.DetailsName === 'ReceivingDetails') {
        SubForm.NestedGridData.GridHeaderDefination.filter(x => x.field === 'QtyBU')[0]['valueGetter'] = params => {
          params.data.QtyBU =
            params.data.Qty && params.data.Qty !== 0 && params.data.Conversion && params.data.Conversion !== 0
              ? parseFloat(params.data.Conversion) * parseFloat(params.data.Qty) : 0;
          return params.data.QtyBU;
        };
      }
      if (MasterDataInfo.DetailsName === 'ReceivingDetails') {
        SubForm.NestedGridData.GridHeaderDefination.filter(x => x.field === 'QtyBU')[0]['valueGetter'] = params => {
          const conversion = params.data.UnitTypeId === 4 ? (1 / params.data.Conversion) : params.data.Conversion;
          params.data.QtyBU =
            params.data.Qty && params.data.Qty !== 0 && conversion && conversion !== 0
              ? parseFloat(conversion) * parseFloat(params.data.Qty) : 0;
          return params.data.QtyBU;
        };
        SubForm.NestedGridData.GridHeaderDefination.filter(x => x.field === 'ValuedBU')[0]['valueGetter'] = params => {
          params.data.ValuedBU = params.data.Amount;
          return params.data.ValuedBU;
        };
        SubForm.NestedGridData.GridHeaderDefination.filter(x => x.field === 'UnitCost')[0]['valueGetter'] = params => {
          params.data.UnitCost = params.data.Qty && params.data.Qty !== 0
            ? parseFloat(params.data.Amount) / parseFloat(params.data.Qty) : 0;
          params.data.UnitCost = params.data.UnitCost ? params.data.UnitCost :
            (params.data.CurrentCost ? params.data.CurrentCost : 0);
          return params.data.UnitCost;
        };
        SubForm.NestedGridData.GridHeaderDefination.filter(x => x.field === 'CostBU')[0]['valueGetter'] = params => {
          params.data.CostBU = params.data.Qty && params.data.Qty !== 0 && params.data.Conversion &&
            params.data.Conversion !== 0 && params.data.Amount && parseFloat(params.data.Amount) !== 0 ?
            (parseFloat(params.data.Amount) / parseFloat(params.data.Qty)) / parseFloat(params.data.Conversion) : 0;
          // Bug - 181127 fix
          params.data.CostBU = params.data.CostBU ? params.data.CostBU : (params.data.CurrentCost ? params.data.CurrentCost : 0);
          return params.data.CostBU;
        };
      }
      return SubForm.NestedGridData.GridHeaderDefination;
    } catch (error) {
      console.error('error occurred in rearrangeGridHeader', error);
    }
  }

  validateNutrientData(HomeGroup: FormGroup, NutrientsData: MasterInventoryManagement) {
    try {
      let validData = true;
      if (HomeGroup && NutrientsData && NutrientsData.NestedFormConfig.EditFormConfig) {
        const getNutrients = NutrientsData.NestedFormConfig.EditFormConfig.filter(x => x.Name === 'Nutrients');
        const getOdataKey = (getNutrients && getNutrients.length > 0) ? getNutrients[0].OdataKey : 'IngredientsNutritions';
        const nutrientData = HomeGroup.controls[getOdataKey];
        const getNutrientGridColl = NutrientsData.NestedFormConfig.EditFormConfig.filter(x => x.OdataKey === getOdataKey);
        const GridDataPresent = (getNutrientGridColl[0] && getNutrientGridColl[0].hasOwnProperty('caloriesCalculation') &&
          getNutrientGridColl[0].caloriesCalculation.CaloriesGrid.CaloriesData.length > 0) ? true : false;
        if (GridDataPresent) {
          console.log('Calories Grid Data', getNutrientGridColl[0].caloriesCalculation.CaloriesGrid.CaloriesData);
        }
        const GridValuesPresent = GridDataPresent && getNutrientGridColl[0].caloriesCalculation.CaloriesGrid.CaloriesData.some(x =>
          x.NutrientValue2 !== undefined && x.NutrientValue2 !== null && x.NutrientValue2 !== '');
        if (GridValuesPresent && (nutrientData.value.ServingSize === null || nutrientData.value.ServingSize === '' ||
          nutrientData.value.ServingSize === undefined)) {
          const ErrorPopUpDialog: ErrorPopModel = {
            PopupHeaderContent: 'Error',
            PopUpContent: 'Cannot save. Please enter a value for Grams Per Serving.',
          };
          this.dialog.open(AgilsysErrorPopComponent, {
            width: '400px',
            data: ErrorPopUpDialog,
            disableClose: true
          });
          validData = false;
        }
      }
      return validData;
    } catch (error) {
      console.error('Error occurred in validateNutrientData', error);
    }
  }

  OpenPrintList(dialog: MatDialog, ResultData: Array<any>, selectedtransaction: string, Module: string, PrintId: number, EnableCount: boolean = false) {
    try {
      return new Promise<PrintPopUpCallBack>(resolve => {
        const printPopModel = {} as PrintPopModel;
        printPopModel.PopUpContent = `${Module} Print`;
        printPopModel.PopupHeaderContent = `${Module} Print`;
        printPopModel.PrintList = ResultData;
        printPopModel.PrintKey = selectedtransaction;
        printPopModel.EnableCount = EnableCount;
        printPopModel.PrintId = PrintId;
        const dialogRef = dialog.open(AgilysysPrintPopupComponent, {
          width: '40%',
          height: '30%',
          data: printPopModel,
          disableClose: true
        });
        dialogRef.afterClosed().subscribe((result: PrintPopUpCallBack) => {
          resolve(result);
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * @method openZplPopup
   * @input daialo Resultdata
   * @description 
   * */

  openZplPopup(dialog: MatDialog, ResultData: Array<any>, selectedtransaction: string, Module: string, PrintId: number, EnableCount: boolean = false) {
    try {
      return new Promise<PrintPopUpCallBack>(resolve => {
        const printPopModel = {} as PrintPopModel;
        printPopModel.PopUpContent = `${Module} Print`;
        printPopModel.PopupHeaderContent = `${Module} Print`;
        printPopModel.PrintList = ResultData;
        printPopModel.PrintKey = selectedtransaction;
        printPopModel.EnableCount = EnableCount;
        printPopModel.PrintId = PrintId;
        const dialogRef = dialog.open(AgilysysPrintPopupComponent, {
          width: '40%',
          height: '30%',
          data: printPopModel,
          disableClose: true
        });
        dialogRef.afterClosed().subscribe((result: PrintPopUpCallBack) => {
          resolve(result);
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * LoadMasterData
   */
  async LoadMasterData(selectedMaster: string = null, inventoryType: any = null): Promise<void> {
    try {
      return new Promise(async (resolve, reject) => {
        switch (selectedMaster) {
          case MasterKeys.MasterIngredients:
            const MasterJsonObjectUrl = 'app/eatecui/assets/json/master/retail-inventory.json';
            this.masterJosnData = await this.http.get<Array<MasterInventoryManagement>>(MasterJsonObjectUrl).toPromise();
            break;
        }
        resolve();
      });
    } catch (error) {
      console.error(error);
    }
  }

  /** 
   * isValidJsonData
  */
  isValidJsonData(jsonData: any): boolean {
    try {
      return /^[\],:{}\s]*$/.test(jsonData.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit(): void {
    this.LoadMasterData();
  }
}
