import { Pipe, PipeTransform } from '@angular/core';
import { Localization } from './localization';
import { LocalizeDateTimePipe } from './localize-dateTime.pipe';
 
@Pipe({
    name: 'localizeDateTime'
})
export class CommonLocalizeDateTimePipe extends LocalizeDateTimePipe {
 
    constructor(localization: Localization)
    {
        super(localization);
    }
}