import { DropdownOptions } from "src/app/common/Models/ag-models";


export namespace UI {
    export interface GridTime {
        startTime: string;
        endTime: string;
    }

    export interface GridHeader {
        key: string;
        dateString: string;
        value: Date | String;
        localizedValue: string;
        subText: string;
    }

    export interface YieldingSkeleton {
        id: number;
        time: string;
        yielding?: PricingDetails[];
    }
    
    export interface YieldingDetails {
        startDate: string;
        endDate: string;
        strategyName:string;
        pricingDetails: PricingDetails[];
        isDateExpanded?: boolean;
        overrideDetails?: YieldingDetails[];
    }

    export interface PricingDetails{
        ruleId: number;
        ruleCategoryId: PricingRuleCategory;
        periodType: PricingRulesBy;
        serviceAvailableTimeRange: string;
        startTime: string;
        endTime: string;
        serviceGroup: number[];
        services: number[];
        serviceNames:string;
        serviceRate: number;
        maxOccupancy: number;
        isOverride: boolean;
        applicableDays: string;
        daysOfWeek:number[];
        rules:Rules[];
        stopAvailability: boolean;
        totalHours?: number;
        hoursLag?: number;
        rulesUI?: Rules[];
        isRuleExpanded?: boolean;
        startDate: string;
        endDate: string;
    }

    export interface Rules{
        id:number;
        ruleCategoryId: PricingRuleCategory;
        pricingCondition: PricingCondition;
        pricingConditionValue: string;
        isIncrement: boolean;
        isPercent: boolean;
        pricingStrategyValue: number;
        stopAvailability: boolean;
    }

    
    
    export enum PricingRuleCategory {
        Utilization = 1,
        DaysInAdvance,
        Override
    }

    export enum PricingCondition {
        GreaterThanEqualTo = 1,
        LesserThanEqualTo,
        NoCondition
    }

    export enum PricingRulesBy {
        DayPart = 1,
        Day
    }

    export enum GridType{
        Day = 1,
        Week
    }

    export enum ViewType{
        Grid = 1,
        List
    }

    // For View All Slider
    export interface ViewPricingData{
        startDate: string;
        endDate: string;
        pricingDetails:ViewPicingDetails[];

    }
    export interface ViewPicingDetails{
        timeRange: string;
        startTime: string;
        endTime: string;
        rules: Rules[];
        services: Services[];
    }
    export interface Services{
        id: number;
        name:string;
        baseRate: string;
        priceTypes:PriceTypes[];
    }
    export interface PriceTypes{
        id: number;
        name: string;
        baseRate: string;
        updatedRate: string;
    } 

    export interface StrategyList {
        strategyName: string;
        groupId: string;
        serviceGroupList : {id: number, code: string, services: Service[] }[];        
    }

    export interface Service {
        id: number,
        code: string
    }

    export interface IListViewServiceFilter
    {
        selectedOptions: DropdownOptions[],
        isFilterRequired:boolean 
    }

    export interface MenuFilter{
        startTime:string,
        endTime:string,
        daysOfWeek:number[],
        availability:number[],
        rangeStart:number,
        rangeEnd:number
    }
}
export enum RuleType{
    dayPart,
    day
}
export enum DaysOfWeek {
    Sunday = 1,
    Monday = 2,
    Tuesday = 4,
    Wednesday = 8,
    Thursday = 16,
    Friday = 32,
    Saturday = 64
}

export namespace API {
    
    export interface DynamicPricingRule {
        id: number;
        startDateTime?: string;
        endDateTime?: string;
        name: string;
        serviceGroupId: number[];
        serviceId: number[];
        ruleCategoryId: DynamicPricingRuleCategory;
        ruleType: DynamicPricingRulesBy;
        groupId: string;
        overrideGroupId: string;
        ruleGroupId: string;
        limits: number;
        daysOfWeek: DaysOfWeek;
        dynamicAvailability: boolean;
        isDeleted: boolean;
        isOverride: boolean;     
        isActive: boolean;   
        dynamicPricingStrategy: DynamicPricingStrategy[];
        hasOverride?:boolean
    }

    export interface DynamicPricingStrategy {
        id: number;
        dynamicPricingRuleId: number;
        dynamicPricingCondition: DynamicPricingCondition;
        conditionValue: any;
        dynamicAvailability: boolean;
        isIncrement: boolean;
        isPercent: boolean;
        dynamicPricingStrategyValue: number;      
    }

    export interface DynamicPricingService {
        id: number;
        startTime?: string;
        endTime?: string;
        name: string;
        serviceGroupId: number[];
        serviceIds: number[];
        ruleCategoryId: DynamicPricingRuleCategory;
        ruleType: DynamicPricingRulesBy;
        groupId: string;
        overrideGroupId: string;
        ruleGroupId: string;
        limits: number;
        daysOfWeek: DaysOfWeek;
        dynamicAvailability: boolean;
        isDeleted: boolean;
        isOverride: boolean; 
        dynamicPricingStrategy: DynamicPricingStrategy[];
        isActive: boolean;
        hasOverride?:boolean;
    }

    export interface YieldingDetails {
        startDate: string;
        endDate: string;
        pricingDetails: PricingDetails[];
        isDateExpanded?: boolean;
        name:string;
        groupId: string;
        isActive: boolean;
        overrideDetails?: YieldingDetails[];
    }
 
    export interface PricingDetails{
        ruleId: number;
        ruleCategoryId: DynamicPricingRuleCategory;
        periodType: DynamicPricingRulesBy;
        serviceAvailableTimeRange: string;
        startTime: string;
        endTime: string;
        serviceGroup: number[];
        services: number[];
        serviceRate: number;
        maxOccupancy: number;
        isOverride: boolean;
        applicableDays: string;
        daysOfWeek:number[];
        rules:Rules[];
        stopAvailability: boolean;
        totalHours?: number;
        hoursLag?: number;
        rulesUI?: Rules[];
        isRuleExpanded?: boolean;
        ruleGroupId: string;
        overrideGroupId: string;
        name:string;
        groupId: string;
        rule:Rules[];
    }
 
    export interface Rules{
        id:number;
        ruleCategoryId: DynamicPricingRuleCategory;        
        pricingCondition: DynamicPricingCondition;
        pricingConditionValue: string;
        isIncrement: boolean;
        isPercent: boolean;
        pricingStrategyValue: number;
        stopAvailability: boolean;
        dynamicAvailability:boolean
    }

    export interface DynamicPricingServiceGroup {
        startDate: string;
        endDate: string;
        id: number;
        name: string;
        groupId: string;
        serviceGroupId: number;
        serviceGroupCode: string;
        serviceId: number;
        serviceCode: string;
    }

    // For View All Slider
    export interface DynamicPricingViewPricingData{
        startDate: string;
        endDate: string;
        pricingDetails:DynamicPricingViewPricingDetails[];

    }
    export interface DynamicPricingViewPricingDetails{
        timeRange: string;
        startTime: string;
        endTime: string;
        ruleTypeId: DynamicPricingRulesBy,
        rules: Rules[];
        services: DynamicPricingServices[];
    }

    export interface DynamicPricingServices{
        id: number;
        name:string;
        baseRate: string;
        priceTypes:DynamicPricingPriceTypes[];
    }
    export interface DynamicPricingPriceTypes{
        id: number;
        name: string;
        baseRate: string;
        updatedRate: string;
    } 
}

export enum DynamicPricingRuleCategory {
    Utilization = 1,
    DaysInAdvance,
    Override
}

export enum DynamicPricingRulesBy {
    DayPart = 1,
    Day
}

export enum DynamicPricingCondition {
    GreaterThanEqualTo = 1,
    LesserThanEqualTo,
    NoCondition
}

export enum DynamicPricingStatus {
    Inserted = 1,
    Updated,
    Deleted
}

export enum ActionMode {
    Create = 1,
    Edit,
    EditOverride,
    Delete,
    Cancel,
    SingleDateOverride,
    MultiDateOverride,
    Copy
}

export namespace MapUI {
    export interface DynamicPricingRules {
        id: number;
        startDateTime?: string;
        endDateTime?: string;
        name: string;
        isActive: boolean;
        serviceId: number[];
        serviceGroupId: number[];
        ruleCategoryId: DynamicPricingRuleCategory;
        ruleType: DynamicPricingRulesBy;
        groupId: string;
        overrideGroupId: string;
        ruleGroupId: string;
        limits: number;
        daysOfWeek: DaysOfWeek;
        dynamicAvailability: boolean;
        isDeleted: boolean;
        isOverride: boolean;        
        dynamicPricingStrategy: DynamicPricingStrategy[];
        hasOverride?:boolean
    }
    export interface DynamicPricingStrategy {
        id: number;
        dynamicPricingRuleId: number;
        dynamicPricingCondition: DynamicPricingCondition;
        conditionValue: any;
        dynamicAvailability: boolean;
        isIncrement: boolean;
        isPercent: boolean;
        dynamicPricingStrategyValue: number;    
    }
}