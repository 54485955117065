
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/common/app-service';
import { PrintManagerCommunication } from 'src/app/common/communication/services/printmanager-communication.service';
import { environment } from 'src/environments/environment';
import { Localization } from '../localization/localization';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { APIPrinterDetails, PrintDocumentInput } from '../Models/common.models';
import { CommonApiRoutes } from '../common-route';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
@Injectable({
  providedIn: 'root'
})
export class PrintManagerDataService {
private _printmanagerService:PrintManagerCommunication;
  constructor(private localization: Localization,
    private httpclient: HttpClient,private utilities: CommonUtilities, private PropertyInfo: CommonPropertyInformation,
    private appService: AppService) {

}
public async GetAllPrinters(url : string,handleErr : boolean = false): Promise<APIPrinterDetails[]> {
  this._printmanagerService = new PrintManagerCommunication(url, this.httpclient, this.localization, this.utilities, this.PropertyInfo, this.appService);
  return await this._printmanagerService.getPromise<APIPrinterDetails[]>({
      route: CommonApiRoutes.GetAllInstalledPrinters
  },handleErr);
}
public async PrinterManagerHeartBeat(url : string,handleErr : boolean = false):Promise<string> {
  this._printmanagerService = new PrintManagerCommunication(url, this.httpclient, this.localization, this.utilities, this.PropertyInfo, this.appService);
  return await this._printmanagerService.getPromise<string>({
      route: CommonApiRoutes.PrinterManagerHeartBeat
  },handleErr);
}
public async GetPrinterManagerVersion(url : string,handleErr : boolean = false):Promise<string> {
  this._printmanagerService = new PrintManagerCommunication(url, this.httpclient, this.localization, this.utilities, this.PropertyInfo, this.appService);
  return await this._printmanagerService.getPromise<string>({
      route: CommonApiRoutes.GetPrinterManagerVersion
  },handleErr);
}
public async IsImageSupportAvailable(url : string,handleErr : boolean = false):Promise<boolean> {
  this._printmanagerService = new PrintManagerCommunication(url, this.httpclient, this.localization, this.utilities, this.PropertyInfo, this.appService);
  return await this._printmanagerService.getPromise<boolean>({
      route: CommonApiRoutes.IsImageSupportAvailable
  },handleErr);
}
public async Print(url : string,printDocumentInput : PrintDocumentInput,handleErr : boolean = false): Promise<boolean> {
  this._printmanagerService = new PrintManagerCommunication(url, this.httpclient, this.localization, this.utilities, this.PropertyInfo, this.appService);
  return await this._printmanagerService.putPromise<boolean>({
      route: CommonApiRoutes.Print,
      body: printDocumentInput
  },handleErr);
}
}
