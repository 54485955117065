import { Injectable, OnDestroy } from '@angular/core';
import { PromptType, ButtonType } from '../shared/globalsContant';
import { DialogOverviewExampleDialog } from '../shared/dialog-popup/dialogPopup-componenet';
import { MatDialog } from '@angular/material/dialog';
import { Localization } from '../../localization/localization';
import { UserAlertsModal } from '../shared/business/shared.modals';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Injectable()
export class UserAlerts implements OnDestroy {

    public userPromptConfigs: any;
    public IsUserPromptConfigsLoaded:boolean = false;
    subscriptions: ISubscription[] = [];

  constructor(public localization:Localization, private dialog: MatDialog) {

    }

    ngOnDestroy(): void {
        if (this.subscriptions){
            this.subscriptions.forEach(sub => {
              if (sub) {
                sub.unsubscribe();
              }
            });
          }
    }

    showCustomPrompt(alertMsgId:number,callback: (result: string|boolean, extraParamsCB?:any) => void, buttonType:ButtonType , extraParams?:any){
      let alertObj:UserAlertsModal = this.localization.getUserAlertObj(alertMsgId);
      let message = alertObj.message;
      let toBeShown = this.IsEnabled(alertObj);
      if (toBeShown) {
        let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            height: 'auto',
            width: '40%',
            data: { headername: this.localization.captions.common.Confirmation, closebool: true, datarecord: message, buttonType: buttonType },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true
        });
        const subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback) {
                callback(res,extraParams);
            }
        });
        this.subscriptions.push(subscription);
      } else {
        if (callback)
            callback(false, extraParams);
      }
    }


    /**
     * //User Prompts for User actions.
     * @param type PromptType eg. DeleteUnsaved changes.
     * @param callback callback Method for the option clicked by the user.
     * @param extraParams Any extra parameter
     */
    showPrompt(type: PromptType, callback?: (result: string, extraParams?: any) => void, extraParams?: any, isUserConfirmationNeeded:boolean = false) {
        let toBeShown: boolean;
        switch (type) {
            case PromptType.Delete:
                let alertObjectForDelete :UserAlertsModal = this.localization.getUserAlertObj(1001);
                let commonDeleteMsg:string = alertObjectForDelete.message;
                toBeShown = this.IsEnabled(alertObjectForDelete);
                if (toBeShown) {
                    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
                        height: 'auto',
                        width: '40%',
                        data: { headername: this.localization.captions.common.Confirmation, closebool: true, datarecord: commonDeleteMsg, buttonType: ButtonType.OkCancel },
                        panelClass: 'small-popup',
                        disableClose: true,
                        hasBackdrop: true
                    });
                    const subscription = dialogRef.afterClosed().subscribe(res => {
                        if (callback) {
                            callback(res, extraParams);
                        }
                    });
                    this.subscriptions.push(subscription);
                } else {
                    if (callback)
                        callback("ok", extraParams)
                }
                break;
            case PromptType.UnsavedChanges:
                let alertObjectForUnsaved :UserAlertsModal = this.localization.getUserAlertObj(1002);
                let commonUnsavedMsg: string = alertObjectForUnsaved.message;
                toBeShown = isUserConfirmationNeeded;
                if (toBeShown) {
                    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
                        height: 'auto',
                        width: '40%',
                        data: { headername: this.localization.captions.common.Confirmation, closebool: true, datarecord: commonUnsavedMsg, buttonType: ButtonType.YesNo },
                        panelClass: 'small-popup',
                        disableClose: true,
                        hasBackdrop: true
                    });
                    const subscription = dialogRef.afterClosed().subscribe(res => {
                        if (callback) {
                            callback(res, extraParams);
                        }
                    });
                    this.subscriptions.push(subscription);
                } else {
                    if (callback)
                        callback("yes", extraParams)
                }

                break;

            default:

                break;
        }
    }

    IsEnabled(userAlert:UserAlertsModal){
        if (this.userPromptConfigs && Object.keys(this.userPromptConfigs[0]).length != 0 ) {
            if (userAlert.priority == 1) {
                return  this.userPromptConfigs[0].SYSTEM_MESSAGES_PRIORITY1;
            }else if (userAlert.priority == 2){
              return  this.userPromptConfigs[0].SYSTEM_MESSAGES_PRIORITY2;
            }else if(userAlert.priority == 3){
              return  this.userPromptConfigs[0].SYSTEM_MESSAGES_PRIORITY3;
            }
        }else{
            return false;
        }

    }

    ShowError(title: string, message: string, popButtonType: ButtonType = ButtonType.Ok, callback?: (result: string, extraParams?: any[]) => void, extraParams?: any[], closebool = true) {
        if (this.dialog) {
            let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
                height: 'auto',
                width: '40%',
                data: { headername: title, closebool: closebool, datarecord: message, buttonType: popButtonType },
                panelClass: 'small-popup',
                disableClose: true,
                hasBackdrop: true
            });
            const subscription = dialogRef.afterClosed().subscribe(res => {
                if (callback)
                    callback(res, extraParams);
            });
            this.subscriptions.push(subscription);
        }
    }

}
