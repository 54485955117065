import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, uniq } from 'lodash';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { EventDataServices } from '../../dataservices/notification/event.data.service';
import { TemplateDataServices } from '../../dataservices/notification/template-data-service';
import { Localization } from '../../localization/localization';
import { AlertAction, AlertType, ButtonTypes } from '../../Models/common.models';
import { GridType } from '../../shared/shared/globalsContant';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { TemplateServiceCommunication } from '../templates.service';
import { CrudReportTemplatesBusiness } from './crud-report-template/crud-report-template.business';
import { Events, ReportAPITemplate, ReportTemplate } from './crud-report-template/crud-report-templates.model';

@Component({
  selector: 'app-template-report',
  templateUrl: './template-report.component.html',
  styleUrls: ['./template-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CrudReportTemplatesBusiness, TemplateDataServices, TemplateServiceCommunication,EventDataServices]
})
export class TemplateReportComponent implements OnInit {

  @Output() emittedReportTempldata: EventEmitter<any> = new EventEmitter();
  @Output() copyReportEventEmitter: EventEmitter<any> = new EventEmitter();

  @Input() showTableRecord: boolean;
  @Input() isCopyEnabled;
  @Input() istoggleDirectEmit:boolean;
  
  reporttemplateCaptions: any;
  tableoptions: any;
  tabledata: ReportTemplate[];
  events: Events[];
  hideCrudWindow: boolean = true;
  IsViewOnly: boolean = false;
  onEditDisableFunction: boolean = true;
  showDefaultToggle = false;
  reporttemplateSettingsCaptions: any;
  doneEventObj: any;
  productId: number;
  selectedFilterData: any[] = [];
  defaultData: any = {
    id: 0,
    code: 'ALL',
    description: this.localization.captions.common.all
  };
  originalTemplateData: ReportTemplate[];
  filterEventsData: Events[];
  uniqueFilterData: ReportTemplate[]; //john
  modifiedEvents: Events[]=[];
  ReportFilterselectionData: any[]=[];
  isToggleActive: Boolean = false;
  isViewOnly = true;
  constructor(public localization: Localization, private _CrudEmailTemplatesBusiness: CrudReportTemplatesBusiness, private _templateServiceCommunication: TemplateServiceCommunication,
    private _eventBusiness: EventDataServices, private commonUtils: CommonUtilities,private _userAccessBusiness: UserAccessBusiness) {
    this.reporttemplateCaptions = this.localization.captions.settings.utilities;
    this.reporttemplateSettingsCaptions = this.localization.captions.settings;
  }

  ngOnInit() {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.getReportData();
  }
  async getReportData() {
    await this.ValidateBreakPoint();
    await this.GetEvents();
    const templatedata = await this._CrudEmailTemplatesBusiness.getReportTemplateData();
    this.tabledata = this.MaptoUIMpdel(templatedata);
    this.originalTemplateData = cloneDeep(this.tabledata);
    let originalTemplateDataTemp:any = this.originalTemplateData.map(x=>x.eventIds)
    let singlearrayobj= originalTemplateDataTemp.flat();
    let uniqueFilterData:any=  uniq(singlearrayobj);
    this.modifiedEvents = this.events.filter(x=> {
      return (uniqueFilterData.indexOf(x.id)!=-1) 
    })

    this.BindReportTableData(this.tabledata);
  }

  async GetEvents() {
    this.events = await this._CrudEmailTemplatesBusiness.GetAllEvents();
    this.modifiedEvents = cloneDeep(this.events)

  }
  async ValidateBreakPoint() {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.ReportTemplate, true);
    this.IsViewOnly = result.isViewOnly;
  }

  MaptoUIMpdel(tabledata): ReportTemplate[] {
    let NotificationTemplates:ReportTemplate[]=[];
    tabledata.forEach(element => {
      let notificationTemplate: any = {
        eventIds: element.eventIds,
        id: element.id,
        isActive: element.isActive,
        isDefault: element.isDefault,
        listOrder: element.listOrder,
        templateCode: element.templateCode,
        templateName: element.templateName,
        templateId: element.templateId,        
        eventName: this.GetEventNames(element.eventIds)
      };
      NotificationTemplates.push(notificationTemplate);
    });
    return NotificationTemplates;
  }

  GetEventNames(eventIds:number[]) {
    const arr =  this.events
    .filter(c => eventIds.includes(c.id))
    .map(dlc => dlc.eventName)
    .join(',');
    return arr.split(',');
  }


  BindReportTableData(tabledata) {
    this.tableoptions = [{
      TableHdrData: this.GetReportHeader(),
      TablebodyData: tabledata,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.reporttemplateCaptions.reportSrchTxt,
      EnableActions: true,
      SelectRows: false,
     
      Searchable: GridType.customfield,
      EditMoreOption: true,     
    
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: false,
      TableDraggable: true,
      showTableRecords: this.showTableRecord,
      isCopyEnabled: this.isCopyEnabled,
      istoggleDirectEmit: this.istoggleDirectEmit,
      disableCopy: this.IsViewOnly
    }];

    
  }
  private GetReportHeader() {
    return [
      { "title": this.reporttemplateCaptions.event, "jsonkey": "eventName", "sortcolumndatatype": "string", "searchable": true, "sortable": true },
      { "title": this.reporttemplateCaptions.reportTempCode, "jsonkey": "templateCode", "sortcolumndatatype": "string", "searchable": true, "sortable": true },
      { "title": this.reporttemplateCaptions.reportTempName, "jsonkey": "templateName", "searchable": true, "sortable": true },     
      { "title": this.reporttemplateCaptions.default, "jsonkey": "isDefault", "searchable": false, "sortable": true, "type": "toggle"  }
    ];     
  }
  crudreportTemplate() {
    this.emittedReportTempldata.emit({ editEmailObj: null, closeCrudWindow: false, isViewOnly: this.IsViewOnly });
  }

  
  async DeleteRecords(eve) {
    if(eve[0].isDefault){
      const deleteWarningMessage = `${this.reporttemplateCaptions.deleteReportWarning} ${eve[0].eventName}`;
      this.commonUtils.showCommonAlert(deleteWarningMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.delete(eve);
        }
        else {
          return;
        }
      });
    }
    else {
      this.delete(eve);
    }
  }

  async delete(eve) {    
      this.tabledata = await this._CrudEmailTemplatesBusiness.DeleteTemplate(eve[0].templateId);
      await this.getReportData();
  }

  EditRecords(eve) {
    this.emittedReportTempldata.emit({ editReportObj: eve[0], closeCrudWindow: false, isViewOnly: this.IsViewOnly });
  }

  async showDefaultToggleEvent(event) {
    this.showDefaultToggle = event[0];
    let filteredtabledata = [];    
    if(this.showDefaultToggle)
    {        
      this.originalTemplateData.forEach(x => {
        if (x.isDefault) 
        {
          filteredtabledata.push(x);
        }})     
    } 
    else 
    {
      filteredtabledata = this.originalTemplateData;
    }

    this.BindReportTableData(filteredtabledata);
    
  }


  async DoneEvent(event) {
    this.isToggleActive = false;
    this.doneEventObj = event;
    if (event.isDefault) {
      let result = await this._CrudEmailTemplatesBusiness.DefaultTemplateExists(event.eventIds, event.templateId);
      if (result) {
        this._templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this), this.showConfirmationErrorCallback.bind(this));
      }else{
        await this.SaveTemplate();
      }
    }else{
      await this.SaveTemplate();
    }
  }

  copyEvent(event) {
    this.copyReportEventEmitter.emit({ editEmailObj: event, closeCrudWindow: false });
  }

  toggleEvtEmit(event){
    this.isToggleActive = true;
  }

  CancelEvent(event){
    this.isToggleActive = event;
  }

  async showConfirmationSuccessCallback(res) {
    await this.SaveTemplate();
  }
  async showConfirmationErrorCallback(res) {
    this.doneEventObj.isDefault = false;
    await this.SaveTemplate();
  }


  async DragDropEvent(event) {
    let result = await this._CrudEmailTemplatesBusiness.UpdateListOrder(event[0], event[1]);
    if (result) {
      await this.getReportData();
    }
  }

  async SaveTemplate() {
    let reportTemplate = this.MaptoAPIMpdel(this.doneEventObj);
    let result = await this._CrudEmailTemplatesBusiness.UpdateTemplate(this.doneEventObj.templateId, reportTemplate);
    if (result) {
      await this.getReportData();
    }
  }

  MaptoAPIMpdel(event): ReportAPITemplate {
    return {
      eventIds: event.eventIds,
      id: event.id,     
      isdefault: event.isDefault,
      listorder: event.listOrder,
      templateCode: event.templateCode,
      templateName: event.templateName,
      templateId: event.templateId     
    }    
  }

  
  filterTableData(event, selectedData) {
    if (event.checked) {
      if (selectedData.id === 0) {
        this.ReportFilterselectionData = [];
        this.modifiedEvents.forEach(x => {
          this.ReportFilterselectionData.push(x);
        })
      } else {
        this.ReportFilterselectionData.push(selectedData);
      }
    } else {
      if (selectedData.id === 0) {
        this.ReportFilterselectionData = [];
      } else {
        let index = this.ReportFilterselectionData.indexOf(selectedData);
        this.ReportFilterselectionData.splice(index, 1)
      }
    }
    this.filterReportDisplaytabledata();
  }
  filterReportDisplaytabledata() { 
    let eventidArr = this.ReportFilterselectionData.map(x => x.id);
    let filteredtabledata = [];
    if (eventidArr.length > 0) {
      this.originalTemplateData.forEach(x => {
        if (eventidArr.some(r=> x.eventIds.includes(r))) {
          filteredtabledata.push(x);
        }
      })
    } else {
      filteredtabledata = this.originalTemplateData;
    } 
    this.BindReportTableData(filteredtabledata);
  }

}
