import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AgDateRangeConfig } from '../../Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { Localization } from '../../localization/localization';

@Component({
  selector: 'app-ag-date-range-picker',
  templateUrl: './ag-date-range-picker.component.html',
  styleUrls: ['./ag-date-range-picker.component.scss']
})
export class AgDateRangePickerComponent implements OnInit {

  className: string;
  errorMessage: string;
  minDateErrorMessage : string;
  form: UntypedFormGroup;
  startDateControlName: string;
  endDateControlName: string;
  hidden = false;
  maxDate: string | Date;
  minDate: string | Date;
  placeHolder: string;
  value: string | Date;
  placeHolderId: string;
  errorMessageId: string;
  automationId: string;
  isDateRequired: boolean;
  isDisabled: boolean;
  placeHolderFormat: string;
  captionsLocalization:any;
  floatLabel: string;
  captions = {
    invalid: 'Invalid'
  };
  @Output() startDatePickerChange = new EventEmitter();
  @Output() endDatePickerChange = new EventEmitter();
  @Output() pickerOpenChange = new EventEmitter();
  @Input() customPlaceholderLabel;
  @Input() readonly;
  @Input('inputs')
  set inputOptions(value: AgDateRangeConfig) {
    if(value){
      this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: '';
      this.className = value.className;
      this.errorMessage = value.errorMessage;
      this.form = value.form;
      this.startDateControlName = value.startDateControlName;
      this.endDateControlName = value.endDateControlName;
      this.maxDate = value.maxDate;
      this.minDate = value.minDate;
      this.placeHolder = value.placeHolder;
      this.value = value.value;
      this.isDateRequired = value.isDateRequired ? value.isDateRequired : false;
      this.isDisabled = value.isDisabled ? value.isDisabled : false;
      this.minDateErrorMessage = value.minDateErrorMessage ? value.minDateErrorMessage : `${this.captions.invalid} ${this.placeHolder}`;
      this.automationId = value.automationId ? value.automationId : '';
    }
  }
    constructor(public dialog: MatDialog, private localization:Localization) {
      this.captionsLocalization = this.localization.captions.common;
    }

  ngOnInit() {
    this.placeHolderFormat = this.customPlaceholderLabel ? this.customPlaceholderLabel : this.localization.inputDateFormat;
  }

  startDateChange(event) {
    this.startDatePickerChange.emit([this.form, this.startDateControlName]);
  }

  endDateChange(event){
    this.endDatePickerChange.emit([this.form, this.endDateControlName]);
  }

  pickerOpen(event) {
    this.pickerOpenChange.emit(this.form)
  }

}
