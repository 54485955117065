import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridTableCard, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridTableCardsConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): GridTableCard[] => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                const gridFilterAction = {} as GridTableCard;
                let  gridFilterActionArray;
                const GridFilterArray = [];
                GridFilterArray.push(gridFilterAction);
                gridFilterActionArray = GridFilterArray;
                return gridFilterActionArray;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };