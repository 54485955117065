import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    CalcultionField,
    CustomFilterNavigationUrl,
    CustomToolTipConfig,
    GridCustomColExpand,
    GridFilterAction,
    GridFilterData,
    GridHeaderConfig,
    GridTableCard, MasterInventoryManagement, NestedBuilderData, NestedDataBuilder, SubFormConfigs, TitleType, TransactionMasterData
} from '../master.interface';
import { MasterDetailConfiguration } from './master-detail-config.interface';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import {
    GridActionsConfig, GridColumnReorder, GridConfig, GridFilter, GridFilterValue, GridInfiniteScrollConfig,
    GridPaginationConfig, GridResizeRows, GridSearchConfig
} from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { TranslateService } from '@ngx-translate/core';
import { GridInterface } from '../grid-interface';
import { DetailInterface } from '.';
import { Formaters } from '../../formaters';
import _ from 'lodash';
// import * as TransactionMaster from '../../../../../assets/json/master/transaction-master.json';
// import * as CrossReference from '../../../../../assets/json/master/cross-reference-master.json';
import { MasterManagementService } from '../../service/master.service';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { GlobalCustomFormAction } from 'src/app/eatecui/source/shared/models/custom-action/global-custom-action.interface';
import { ActionTypes } from 'src/app/eatecui/source/shared/models/custom-action/custom-enum.interface';
import { DecimalRoundOff, UnitTypeName } from 'src/app/eatecui/source/shared/enum/global.enum';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';
import { DecimalFormater } from 'src/app/eatecui/source/shared/formaters/decimal-formater.interface';

export const MasterNestedDetail: MasterDetailConfiguration =
    (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
        httpService: HttpService,
        GridTableCardDataObject: GridTableCard, ResponseData: any, translateService: TranslateService, AllViewModelEntity: any,
        masterManagementService: MasterManagementService, dynamicToolTipConfig: any): any => {
        try {
            // const TransactionMasterJson: TransactionMasterData[] = (TransactionMaster as any).default;
            // const TransactionMasterJson = transaactionMasterData;
            if (ViewModelEntity.length > 0 && MasterDataCollection) {
                const Url = ViewModelEntity[0]['$']['Name'];
                const ParentEnitityName = ViewModelEntity[0]['$']['Name'];
                const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                const x = ResponseData;
                let dataResponse = x['value'];
                if (typeof dataResponse === 'string') {
                    const resposeConvertArray = [];
                    const dataResponseObject = JSON.parse(x['value']);
                    resposeConvertArray.push(dataResponseObject);
                    dataResponse = resposeConvertArray;
                }
                if (dataResponse?.length > 0) {
                    masterManagementService.RowData = dataResponse[0];
                    const responseObject = dataResponse;
                    const queryParams = {} as QueryParams;
                    const dataObject = GridInterface.GridData(
                        ViewModelEntity, MasterDataCollection, httpService, queryParams, responseObject);
                    // if (Url === 'Suppliers' && dataResponse.hasOwnProperty('VendorPunchoutConfiguration') && dataResponse.VendorPunchoutConfiguration) {
                    //     let punchoutConfigFields: Array<string> = ['AuthenticationURL', 'PurchaseProcessMapping', 'OrderPlacementURL', 'AuthenticationTemplate', 'PurchaseProcessXSLTemplate', 'OrderPlacementTemplate'];
                    //     punchoutConfigFields.forEach(field => {
                    //         dataResponse[0][field] = (field === 'AuthenticationTemplate' || field === 'OrderPlacementTemplate') ? 
                    //         decodeURIComponent(dataResponse[0].VendorPunchoutConfiguration[field]) : dataResponse[0].VendorPunchoutConfiguration[field];
                    //     });
                    // }
                    if (Url === 'Ingredients' && dataObject[0].hasOwnProperty('IngredientsOtherDetails')
                        && dataResponse[0].IngredientsOtherDetails) {
                        const ingredientsOthersColl: Array<string> = ['DepleteFlag', 'ControlPrice', 'PostPhyOnZeroCost', 'DefaultLable', 'AlternateLable', 'InventoryOverrideAccId', 'CostOfSaleOverrideAccId',
                            'CatchWeight', 'MinTemp', 'MaxTemp', 'TolerancePercentage', 'DepletionLocId', 'ProdRecipeId',
                            'AltProdrecipeId', 'TrackingFlag', 'Yield', 'ShelfLife', 'PrimarySupplierId'];
                        ingredientsOthersColl.forEach((element: string) => {
                            if (dataObject[0].IngredientsOtherDetails.hasOwnProperty(element)) {
                                dataObject[0][element] = dataObject[0].IngredientsOtherDetails[element];
                            }
                        });
                    } else if (Url === 'Products' && dataResponse[0].hasOwnProperty('ProductOtherDetails')
                        && dataResponse[0].ProductOtherDetails) {
                        const ProdcutOtherDetails: Array<string> = ['Price2', 'CalNnut', 'BulkItem', 'OverrideSalesAccId', 'TaxFlag', 'POS15', 'POS20']; 
                        ProdcutOtherDetails.forEach((element: string) => {
                            if (dataObject[0].ProductOtherDetails.hasOwnProperty(element)) {
                                dataObject[0][element] = dataObject[0].ProductOtherDetails[element];
                            }
                        });
                    } else if (Url === 'Recipes' && dataResponse[0].hasOwnProperty('RecipeOtherDetails')
                        && dataResponse[0].RecipeOtherDetails) {
                        dataResponse[0].PrepTime = dataResponse[0].RecipeOtherDetails.PrepTime;
                        dataResponse[0].ShelfLife = dataResponse[0].RecipeOtherDetails.ShelfLife;
                    }
                    if (Url === 'Recipes' && dataResponse[0].hasOwnProperty('RecipeUnits')
                        && dataResponse[0].RecipeUnits) {
                        dataResponse[0].RecipeUnits.forEach(element => {
                            const recipeUnits: Array<string> = ['UnitName', 'Quantity', 'Price', 'CostPerUnit', 'CostPercentage'];
                            const getElementIndex: number = recipeUnits.findIndex(e => e === element.UnitName);
                            recipeUnits.forEach((r: string ) => {
                                dataResponse[0][element.UnitTypeName.split(' ')[0] + r] = element[r];
                            });
                        });
                    }
                    if (Url === 'Ingredients' && dataResponse[0].hasOwnProperty('SiteIngredientsConfigs')
                        && dataResponse[0].SiteIngredientsConfigs) {
                        dataResponse[0].ProdOptionId = dataResponse[0].SiteIngredientsConfigs.ProdOptionId;
                        dataResponse[0].TransferOptionId = dataResponse[0].SiteIngredientsConfigs.TransferOptionId;
                        dataResponse[0].SiteIngredientsConfigsPurchaseUnit = dataResponse[0].SiteIngredientsConfigs.
                        PurchaseUnitName;
                        dataResponse[0].SiteIngredientsConfigsTransactionUnit = dataResponse[0].SiteIngredientsConfigs.
                        TransactionUnitName;
                        dataResponse[0].SitePurchaseConversion = dataResponse[0].SiteIngredientsConfigs.PurchaseConversion;
                        dataResponse[0].SiteTransactionConversion = dataResponse[0].SiteIngredientsConfigs.TransactionConversion;
                    }
                    if (Url === 'Ingredients') {                        
                        const decimalPoint = sessionStorage.getItem('appsetting') ? 
                        JSON.parse(sessionStorage.getItem('appsetting')).DecimalPointRoundOff : DecimalRoundOff.SIX;
                        const GetCurrentCostValue = dataResponse[0].Units?.filter(x => x.UnitTypeId === 1)[0]?.UnitCost;
                        const GetProductPrice = dataResponse[0].ProductPrice;
                        const markupValue = GetProductPrice && GetCurrentCostValue && GetCurrentCostValue > 0 ?
                        (GetProductPrice - GetCurrentCostValue ) / (GetCurrentCostValue / 100) : 0;
                        dataResponse[0].Markup = (markupValue ? markupValue.toFixed(decimalPoint) : 0);
                    }
                    if ( MasterDataCollection?.NestedFormConfig?.ViewFormConfig) {
                        MasterDataCollection.NestedFormConfig.ViewFormConfig.forEach((SubForm: SubFormConfigs) => {
                            if (SubForm.Type === 'grid') {
                                SubForm.NestedGridData.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img class="overlay-loader-image" style="height: 50px" src="app/eatecui/assets/images/eatec_loader.gif"><div class="overlay-text product-caption">Please be patient</div></span>';
                            }
                            if ((SubForm.Type === 'listing' || SubForm.OdataKey === 'ProductItemDetails')
                                && SubForm.OdataKey !== 'IngredientsNutritions' && SubForm.OdataKey !== 'ProductNutritions'
                                && SubForm.OdataKey !== 'RecipeNutritions') {
                                SubForm.data.forEach((nestedBuilder: NestedDataBuilder) => {
                                    const dynmicNestedDataData = [];
                                    if (nestedBuilder.NestedDataDynamic) {
                                        const innerArray = responseObject[0][nestedBuilder.NestedDynamicColumns[0].DynamicParentKey];
                                        innerArray?.sort(function (a, b) {
                                            return a.UnitTypeId - b.UnitTypeId;
                                        });
                                        if (innerArray && innerArray.length > 0) {
                                            let GlobalCode = null;
                                            innerArray.forEach((element, arrayIndex: number) => {
                                                nestedBuilder.NestedDynamicColumns
                                                    .forEach((NestedData: NestedBuilderData, index: number) => {
                                                        const dynamicNestedobject: NestedBuilderData =
                                                            JSON.parse(JSON.stringify(NestedData));
                                                        if (element) {
                                                            const data = element;
                                                            if (dynamicNestedobject.hasOwnProperty('DynamicGlobalFilterCode') &&
                                                                dynamicNestedobject.DynamicGlobalFilterCode && arrayIndex === 0) {
                                                                GlobalCode = (data[dynamicNestedobject.DynamicGlobalFilterCode]) ?
                                                                    data[dynamicNestedobject.DynamicGlobalFilterCode] : null;
                                                            }
                                                            if (GlobalCode !== null &&
                                                                element && element.hasOwnProperty('UnitTypeName') &&
                                                                element.UnitTypeName === 'Recipe Unit' &&
                                                                dynamicNestedobject.hasOwnProperty('DynamicFilterCode')) {
                                                                GlobalCode = `/${GlobalCode}`;
                                                            }
                                                            dynamicNestedobject.OdataKey = data.UnitTypeName
                                                                ? data.UnitTypeName.split(' ')[0] + dynamicNestedobject.OdataKey
                                                                : dynamicNestedobject.OdataKey;
                                                            const selectedInventoryType = dynamicToolTipConfig?.selectedInventoryType;
                                                            const selectedInventoryKey = SubForm.DisplayName + (selectedInventoryType &&
                                                                !isNaN(selectedInventoryType) ? selectedInventoryType : '');
                                                            if (dynamicToolTipConfig && dynamicToolTipConfig
                                                                .dynamicToolTipData?.hasOwnProperty(selectedInventoryKey)
                                                                // eslint-disable-next-line max-len
                                                                && dynamicToolTipConfig?.dynamicToolTipData[selectedInventoryKey].hasOwnProperty
                                                                    (dynamicNestedobject.OdataKey + dynamicNestedobject.DynamicValueKey)) {
                                                                dynamicNestedobject.CustomToolTip = dynamicToolTipConfig
                                                                    .dynamicToolTipData[selectedInventoryKey]
                                                                [dynamicNestedobject.OdataKey + dynamicNestedobject.DynamicValueKey];
                                                                if (translateService) {
                                                                    dynamicNestedobject.CustomToolTip.ToolTipText = translateService
                                                                        .instant(ParentEnitityName + nestedBuilder.NestedName + '.' +
                                                                            dynamicNestedobject.CustomToolTip.ToolTipKey);
                                                                }
                                                            }
                                                            if (dynamicNestedobject.hasOwnProperty('DynamicDisplayKey')) {
                                                                if (dynamicNestedobject.DynamicDisplayAppendKey) {
                                                                    dynamicNestedobject.DisplayName =
                                                                        dynamicNestedobject.DynamicDisplayKey
                                                                            .split(' ')[0]
                                                                            ? data[dynamicNestedobject.DynamicDisplayKey].split(' ')[0]
                                                                            + ' ' + dynamicNestedobject.DynamicDisplayAppendKey
                                                                            : dynamicNestedobject.DisplayName;
                                                                } else {
                                                                    dynamicNestedobject.DisplayName =
                                                                        dynamicNestedobject.DynamicDisplayKey
                                                                            ? data[dynamicNestedobject.DynamicDisplayKey]
                                                                            : dynamicNestedobject.DisplayName;
                                                                }
                                                            }
                                                            dynamicNestedobject.Value = Formater(
                                                                dynamicNestedobject.DynamicValueKey,
                                                                data[dynamicNestedobject.DynamicValueKey], null, null);
                                                            if (dynamicNestedobject.hasOwnProperty('DynamicFilterCode')) {
                                                                dynamicNestedobject.Value = dynamicNestedobject.Value ?
                                                                     dynamicNestedobject.Value.toString() : '-';
                                                                const code = !isNaN(parseFloat(dynamicNestedobject.Value.toString())) &&
                                                                    parseFloat(dynamicNestedobject.Value.toString()) > 1 && element?.UnitTypeName !== 'Recipe Unit'
                                                                    ? `${ (GlobalCode !== null) ? GlobalCode :
                                                                         (data[dynamicNestedobject.DynamicFilterCode] ? data[dynamicNestedobject.DynamicFilterCode] : '-')}s`
                                                                    : `${ (GlobalCode !== null) ? GlobalCode :
                                                                         (data[dynamicNestedobject.DynamicFilterCode] ? data[dynamicNestedobject.DynamicFilterCode] : '-')}`;
                                                                dynamicNestedobject.Value =
                                                                    !isNaN(parseFloat(dynamicNestedobject.Value.toString())) ?
                                                                        Formater('Conversion', dynamicNestedobject.Value, null, null)
                                                                        + ` ${code}`
                                                                        : dynamicNestedobject.Value.toString()
                                                                        + ` (${code})`;
                                                            }

                                                        }
                                                        dynamicNestedobject.Value = Formater(dynamicNestedobject.OdataKey
                                                            , dynamicNestedobject.Value, null, null);
                                                        if (!dynamicNestedobject.Value) {
                                                            dynamicNestedobject.Value = '-';
                                                        }
                                                        dynamicNestedobject.DisplayName = ProductDataRemap.
                                                            FieldDisplayNameEngine(productremapJson,
                                                                ParentEnitityName.toString() + nestedBuilder.NestedName,
                                                                dynamicNestedobject.DisplayName);
                                                     
                                                        dynamicNestedobject.Visible = ProductDataRemap.
                                                            FieldVisibleRemapEngine(productremapJson,
                                                                ParentEnitityName.toString() + nestedBuilder.NestedName,
                                                                dynamicNestedobject.DisplayName);
                                                        dynmicNestedDataData.push(dynamicNestedobject);
                                                    });

                                            });
                                            nestedBuilder.NestedData = [];
                                            nestedBuilder.NestedData = dynmicNestedDataData;

                                        }

                                    } else {
                                        nestedBuilder.NestedData.forEach((NestedData: NestedBuilderData, index: number) => {
                                            if (NestedData.hasOwnProperty('CustomOdataUrl')) {
                                                if (NestedData.OdataKey === 'POSDivisionProducts') {
                                                    dataObject[0][NestedData.OdataKey] = getDivisionsIds(dataObject[0][NestedData.OdataKey]);
                                                }
                                                NestedData.Value = DetailInterface.MasterCustomNavigation(
                                                    dataObject[0][NestedData.OdataKey],
                                                    NestedData.CustomOdataUrl, httpService, NestedData,
                                                    ViewModelEntity, dataResponse);
                                            } else if (NestedData.hasOwnProperty('NestedBuilderDynamic')) {
                                                const dynamicParentArray = responseObject[0]
                                                [NestedData.NestedBuilderDynamic.DynamicParentKey];
                                                if (dynamicParentArray && dynamicParentArray.length > 0) {
                                                    nestedBuilder.NestedData.splice(index, 1);
                                                    dynamicParentArray.forEach((dynmicDataValue: any) => {
                                                        const nestedDynamicData = {} as NestedBuilderData;
                                                        nestedDynamicData.DisplayName =
                                                            dynmicDataValue[NestedData.NestedBuilderDynamic.DynamicDisplayKey];
                                                        nestedDynamicData.Value =
                                                            dynmicDataValue[NestedData.NestedBuilderDynamic.DynamicValueKey];
                                                        nestedDynamicData.DynamicParentKey =
                                                            NestedData.NestedBuilderDynamic.DynamicParentKey;
                                                        nestedDynamicData.DynamicDisplayKey =
                                                            NestedData.NestedBuilderDynamic.DynamicDisplayKey;
                                                        nestedDynamicData.DynamicValueKey =
                                                            NestedData.NestedBuilderDynamic.DynamicValueKey;
                                                        nestedDynamicData.ParentClass = (NestedData.hasOwnProperty('ParentClass')) ?
                                                            NestedData.ParentClass : null;
                                                        nestedDynamicData.Visible = true;
                                                        nestedBuilder.NestedData.push(nestedDynamicData);
                                                    });

                                                }
                                            } else {
                                                NestedData.Value = assignNavigationProperity(dataObject[0],
                                                    NestedData, ViewModelEntity);
                                            }                                            
                                            NestedData.Value = Formater(NestedData.OdataKey, NestedData.Value, null, null);
                                            if (!NestedData.Value) {
                                                NestedData.Value = '-';
                                            }
                                            if (NestedData.EnableIconConfig && NestedData.EnableIconConfig.MappingProperty
                                                && responseObject[0][NestedData.EnableIconConfig.MappingProperty] !== '' &&
                                                responseObject[0][NestedData.EnableIconConfig.MappingProperty] !== null) {
                                                if (responseObject[0][NestedData.EnableIconConfig.MappingProperty]?.length !== 0 &&
                                                    responseObject[0][NestedData.EnableIconConfig.MappingProperty].filter(rd => rd.IsActive).length > 0) {
                                                        NestedData.EnableIconConfig.EnableIcon = true;
                                                        NestedData.EnableIconConfig.Value = responseObject[0][NestedData.EnableIconConfig.MappingProperty].filter(rdata => rdata.IsActive);
                                                        NestedData.Value = NestedData.EnableIconConfig.IconText;
                                                } else {
                                                    NestedData.EnableIconConfig.EnableIcon = false;
                                                    NestedData.Value = '-';
                                                }                                                    
                                            }
                                            NestedData.DisplayName = ProductDataRemap.FieldDisplayNameEngine(productremapJson,
                                                ParentEnitityName.toString() + nestedBuilder.NestedName, NestedData.DisplayName);
                                            NestedData.Visible = ProductDataRemap.FieldVisibleRemapEngine(productremapJson,
                                                ParentEnitityName.toString() + nestedBuilder.NestedName, NestedData.DisplayName);
                                        });
                                    }
                                });
                                if (SubForm.OdataKey === 'ProductItemDetails') {
                                    const ViewModelName = SubForm.hasOwnProperty('CustomOdataKey')
                                        && SubForm.CustomOdataKey ? SubForm.CustomOdataKey : SubForm.OdataKey;
                                    const ChildNavigationProperty = AllViewModelEntity
                                        .filter(view => view['$']['Name'] === ViewModelName)[0];
                                    SubForm.NestedGridData = AssignGridData(dataResponse,
                                        SubForm, ViewModelEntity, translateService, ChildNavigationProperty);
                                }
                            } else if (SubForm.Type === 'grid' && SubForm.Name !== 'Transactions' &&
                                SubForm.Name !== 'CrossReference' && SubForm.Name !== 'History'
                                && SubForm.Name !== 'ItemSales' && SubForm.Name !== 'Activity') {
                                let ViewModelName = SubForm.hasOwnProperty('CustomOdataKey')
                                    && SubForm.CustomOdataKey ? SubForm.CustomOdataKey : SubForm.OdataKey;
                                ViewModelName = ViewModelName === 'IngredientsBarCodes' ? 'IngredientBarCode' : ViewModelName;    
                                const ChildNavigationProperty = AllViewModelEntity
                                    .filter(view => view['$']['Name'] === ViewModelName)[0];
                                SubForm.NestedGridData = AssignGridData(dataResponse,
                                    SubForm, ViewModelEntity, translateService, ChildNavigationProperty, httpService);
                            }
                            if (Url === 'Recipes' && (SubForm.Name === 'DefineProducts' ||
                                SubForm.Name === 'DefineFinishedGoods' || SubForm.Name === 'Packages')) {
                                SubForm.ellipsesDisabled = !dataResponse[0].IsApproved;
                            }
                            if (Url === 'Recipes' && (SubForm.Name === 'Analysis')) {
                                SubForm.ellipsesDisabled = dataResponse[0].RecipeIngredients.length > 0 ? false : true;
                            }
                            if (Url === 'Products' && SubForm.Name === 'Analysis') {
                                SubForm.ellipsesDisabled = dataResponse[0].ProductItemDetails.length > 0 ? false : true;
                            }
                            if (SubForm.hasOwnProperty('AddNewListConfig')) {
                                SubForm.AddNewListConfig = SubForm.AddNewListConfig;
                            }
                        });
                    }
                }
                // });
                // console.log(MasterDataCollection.NestedFormConfig.ViewFormConfig);
                return MasterDataCollection.NestedFormConfig;
            }
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };
/**
 * @method mappingPuchoutData
 * @param UniqueId 
 * @param customUrl 
 * @param httpService 
 * @returns 
 */
function mappingPuchoutData(nestedBuilder: NestedDataBuilder, responaseData: any): any {
    try{
        if( nestedBuilder && responaseData.PunchoutMapping){
            responaseData.PunchoutMapping.forEach((punchout: any )=>{
               if( punchout.ConfigGroup === nestedBuilder.NestedName ){
                  const nestedBuilderData = {} as NestedBuilderData;
                  nestedBuilderData.OdataKey = punchout.ConfigMappingKey;
                  nestedBuilderData.Name = punchout.ConfigMappingKey;
                  nestedBuilderData.DisplayName = punchout.ConfigMappingKey;
                  nestedBuilderData.EdmType = "Edm.String"
                  nestedBuilderData.Visible = true;
                  nestedBuilderData.Value = '-'
                  const toolTip = {} as CustomToolTipConfig;
                  toolTip.EnableToolTip = true;
                  toolTip.ToolTipText = '';
                  toolTip.ToolTipKey = `@${punchout.ConfigMappingKey}`;
                  nestedBuilderData.CustomToolTip = toolTip;
                  nestedBuilder.NestedData.push(nestedBuilderData);
               }
            });
        }
    } catch(error){
        console.error(error);
    }
}

function getCustomNavigationData(UniqueId: any, customUrl: string, httpService: HttpService) {
    let customValue = UniqueId;
    // eslint-disable-next-line radix
    const id = parseInt(UniqueId);
    const seleId = `id eq ${id}`;
    const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
    const params: QueryParams = {
        count: true,
        top: InfiniteScrollItemCount,
        skip: 0,
        expand: '',
        select: '',
        filter: '',
        orderby: '',
        isactive: false,
        search: '',
        filterQuery: GetDataFilter,
        searchtext: ''
    };
    const getQueryParams = formQueryParams(params);
    const GetDataByIdUrl = `${customUrl + getQueryParams}`;
    httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
        if (x['value'].length > 0) {
            customValue = x['value'][0]['Name'];
        }
    });
    return customValue;

}

function assignNavigationProperity(DataObject: object, OdataKeyFieldKey: NestedBuilderData, ViewModelEntityColl: any) {
    let entityObject = '';
    const key = OdataKeyFieldKey.OdataKey;
    if (OdataKeyFieldKey.Formatter) {
        entityObject = DataObject[OdataKeyFieldKey.OdataKey];
    } else if (OdataKeyFieldKey.hasOwnProperty('DynamicParentKey') && OdataKeyFieldKey.hasOwnProperty('DynamicDisplayKey')) {
        if (DataObject.hasOwnProperty(OdataKeyFieldKey.DynamicParentKey) && 
           DataObject[OdataKeyFieldKey.DynamicParentKey] && DataObject[OdataKeyFieldKey.DynamicParentKey].length > 0) {
            const getParentArray = DataObject[OdataKeyFieldKey.DynamicParentKey].filter(
                (x: any) => x[OdataKeyFieldKey.DynamicDisplayKey] === OdataKeyFieldKey.DisplayName)[0];
            if (getParentArray) {
                entityObject = getParentArray[OdataKeyFieldKey.DynamicValueKey];
            }
        } else {
            entityObject = '-';
        }

    } else {
        const ModelType = ViewModelEntityColl[0].Property.filter(x => x['$']['Name'] === OdataKeyFieldKey.OdataKey)[0];
        const Type = ModelType && ModelType['$'].Type === 'Edm.Boolean' ? 'Boolean' : 'Others';
        entityObject = DataObject[OdataKeyFieldKey.OdataKey];
        const BooleanObject = entityObject !== null && entityObject !== undefined && entityObject !== ''
            && typeof (entityObject) === 'boolean' ? true : false;
        if (Type === 'Boolean' || BooleanObject) {
            entityObject = DataObject[OdataKeyFieldKey.OdataKey] ? 'Yes' : 'No';
        }
    }
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl[0].hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl[0]['NavigationProperty'].length > 0) {
        ViewModelEntityColl[0]['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'];
            if (element.hasOwnProperty('ReferentialConstraint')) {
                const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toLowerCase().toString();
                if (key && referelcontraintName === key.toLowerCase().toString()) {
                    const checkNull = DataObject[navProperiperityName];
                    if (checkNull) {
                        entityObject = DataObject[navProperiperityName]['Name'] ? DataObject[navProperiperityName]['Name'] :
                            DataObject[navProperiperityName]['Code'];

                        const OdataKey = ViewModelEntityColl[0]['$']['Name'].toLowerCase().toString();
                        const mergedValue = GlobalCustomFormAction(
                            referelcontraintName, OdataKey, referelcontraintName, 'GridAction',
                            ActionTypes.CUSTOMMERGE, null, null, null, null, null, null, DataObject,
                            null, null, null);
                        if (mergedValue && mergedValue !== undefined && mergedValue !== null) {
                            entityObject = mergedValue;
                        }
                    }

                }
            }
        });
    }
    return entityObject;
}

/**
    *@method GetMasterJsonData()
    *@params jsonData and odatakey MasterData['default']
    *@description getjsonresponseform the data
    * */
function GetMasterJsonData(MasterJson: MasterInventoryManagement[], key: string): MasterInventoryManagement {
    try {
        let masterJsonData = [];
        const ReturnMasterData = MasterJson.filter((MasterDatav: MasterInventoryManagement) => MasterDatav.Key === key);
        if (ReturnMasterData && ReturnMasterData.length > 0) {
            masterJsonData = ReturnMasterData;
        }
        return masterJsonData[0];
    } catch (error) {
        console.error(error);
    }
}


function NavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
    AllViewModelEntity: any, ParentEnitityName: any) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                    const navigationName = element['$'].Name;
                    const referentialRelationId = element['ReferentialConstraint'] && element['ReferentialConstraint'][0]['$'].Property
                        ? element['ReferentialConstraint'][0]['$'].Property : '';
                    if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                        const properityIndex = ViewModelEntity[0]['Property']
                            .findIndex(x => x['$'].Name === referentialRelationId);
                        if (properityIndex > -1) {
                            let expandQuery = '($select=id,name)';
                            if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey: GridCustomColExpand) => {
                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand && GridCustomExpandKey.IsCustomExpand
                                        && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                        expandQuery = `($select=id,${expandCode})`;
                                    }
                                });
                            }
                            const properityString = `${navigationName}` + `${expandQuery}`;
                            navigationPrperity.push(properityString);
                        } else {
                            // Added for supplier supplier bids name change
                            const properityString = navigationName === 'SupplierBids' ? 'IngredientsBids' : navigationName;
                            if (MasterDataCollection.NestedFormConfig.EditFormConfig &&
                                MasterDataCollection.NestedFormConfig.EditFormConfig.findIndex(x => x.OdataKey === navigationName) > -1) {
                                const ChildNavigation = [];
                                if (AllViewModelEntity.filter(view => view['$']['Name'] === properityString).length > 0) {
                                    const ChildNavigationProperty = AllViewModelEntity.
                                        filter(view => view['$']['Name'] === properityString)[0];
                                    ChildNavigationProperty['NavigationProperty'].forEach(elements => {
                                        if (elements['$'].Name !== ParentEnitityName) {
                                            let ChildNavigationPropertys = `${elements['$'].Name}($select=id,name)`;
                                            if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                                MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey:
                                                    GridCustomColExpand) => {
                                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand &&
                                                        GridCustomExpandKey.IsCustomExpand
                                                        && GridCustomExpandKey.PrimaryExpand === elements['$'].Name) {
                                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                                        ChildNavigationPropertys = `${elements['$'].Name}($select=id,${expandCode})`;
                                                    }
                                                });
                                            }
                                            ChildNavigation.push(ChildNavigationPropertys);
                                        }
                                    });
                                }
                                const data = navigationName + '($expand=' + ChildNavigation.join() + ')';
                                // properityString = properityString + '($expand=' + ChildNavigation.join() + ')';
                                navigationPrperity.push(data);
                            }
                        }
                    }
                });
                return navigationPrperity;
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function AssignGridData(dataObject, SubForm: SubFormConfigs, ViewModelEntity,
    translateService: TranslateService, ChildNavigationProperty: any, httpService?: HttpService): any {
    try {
        if (ViewModelEntity[0]['$']['Name'] === 'Suppliers' &&
            SubForm.OdataKey === 'SupplierBids') {
            dataObject.forEach((el: any) => {
                if (el.hasOwnProperty('SupplierBids') && el.SupplierBids.length > 0) {
                    el.SupplierBids.forEach((sup: any) => {
                        if (sup.hasOwnProperty('Ingredients') && sup.Ingredients) {
                            sup.LinkTypeId = sup.Ingredients.LinkTypeId;
                            sup.DivisionId = sup.Ingredients.DivisionId;
                        }
                    });
                }
            });
        }
        const EnitityName = ViewModelEntity[0]['$']['Name'].toString();
        const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
        const gridData = {} as GridConfig;
        gridData['GridFilter'] = SubForm.NestedGridData.GridFilter;
        gridData['GridSearch'] = SubForm.NestedGridData.GridSearch;
        gridData['GridSort'] = SubForm.NestedGridData.GridSort;
        if (dataObject[0]) {
            if (SubForm.Name === 'Transactions') {
                gridData.GridData = assignRowData(dataObject, ChildNavigationProperty);
            } else if (SubForm.Name === 'CrossReference') {
                gridData.GridData = assignRowData(dataObject, ChildNavigationProperty);
            } else if (SubForm.Name === 'History') {
                gridData.GridData = dataObject;
            } else if (SubForm.Name === 'ItemSales') {
                gridData.GridData = dataObject;
            } else if (SubForm.Name === 'Activity') {
                dataObject.forEach(element => {
                    if (element.LocationId && element.LocationId > 0) {
                        element.Locations = element.Locations ? element.Locations.Name : '';
                    } else {
                        element.Locations = '';
                    }
                    if (element.UnitId && element.UnitId > 0) {
                        element.UnitDetail = element.UnitDetail ? element.UnitDetail.Name : '';
                    } else {
                        element.UnitDetail = '';
                    }
                }); gridData.GridData = dataObject;
            } else if (SubForm.OdataKey === 'ProductItemDetails') {
                gridData.GridData = assignProductItemDetails(dataObject[0]['ProductItemDetails']);
            } else {
                if (SubForm.OdataKey === 'IngredientsBids') {
                    if (dataObject[0][SubForm.OdataKey] && dataObject[0][SubForm.OdataKey].length > 0) {
                        dataObject[0][SubForm.OdataKey].forEach(rowInfo => {
                            rowInfo.IngredientBidRegions = dataObject[0].IngredientBidRegions ?
                            dataObject[0].IngredientBidRegions.filter(x => x.IngredientBidId === rowInfo.Id) : [];
                        });
                    }
                }
                gridData.GridData = assignRowData(dataObject[0][SubForm.OdataKey], ChildNavigationProperty,
                    SubForm.NestedGridData.GridHeaderDefination);
                if (SubForm.Name !== 'SupplierBids' && SubForm.Name !== 'Barcodes') {
                    gridData.GridData = gridData.GridData.filter(x => x.IsActive === true);
                }
            }
        } else {
            gridData.GridData = [];
        }
        if (SubForm.data) {
            SubForm.data.forEach(data => {
                data.NestedData.forEach(formdata => {
                    if (formdata.Calculation) {
                        gridData.GridData.forEach(rowData => {
                            const returnValue = calculationOperation(formdata.Calculation, rowData);
                            rowData[formdata.OdataKey] = returnValue;
                            // rowData[formdata.OdataKey] = Formater(formdata.OdataKey, returnValue.toString(), null, ViewModelEntity);
                        });
                    }
                });
            });
        }
        if (SubForm.OdataKey === 'IngredientsBids' || SubForm.OdataKey === 'SupplierBids') {
            gridData.GridData.forEach(rowInfo => {
                let TypeName: string = UnitTypeName.PurchaseUnit;
                let UnitType = rowInfo.IngredientBidsUnits;
                if (rowInfo.UnitTypeId !== null) {
                    switch (rowInfo.UnitTypeId) {
                        case 1: TypeName = UnitTypeName.BaseUnit; break;
                        case 2: TypeName = UnitTypeName.PurchaseUnit; break;
                        case 3: TypeName = UnitTypeName.TransactionUnit; break;
                        case 4: TypeName = UnitTypeName.RecipeUnit; break;
                    }
                    UnitType = rowInfo.IngredientBidsUnits + ` (${TypeName})`;
                }
                // const UnitType = rowInfo.IngredientBidsUnits + ` (${TypeName})`;
                rowInfo.IngredientBidsUnits = UnitType;
            });
        }
        let headerDefs = [];
        if (SubForm.NestedGridData) {
            const SubFormName = SubForm.Name === 'Transactions' ? 'ReqDetails' : SubForm.Name;
            headerDefs = SubForm.NestedGridData.GridHeaderDefination.map(values => {
                values.sortDirection = '';
                if (SubForm.NestedGridData.GridSort && SubForm.NestedGridData.GridSort.length > 0) {
                    if (SubForm.NestedGridData.GridSort[0].SortingOdataKey === values.field &&
                        SubForm.NestedGridData.GridSort[0].DefaultSorting) {
                        values.sortDirection = SubForm.NestedGridData.GridSort[0].SortingType.toLowerCase();
                    }
                }
                const text = SubFormName + '.' + values.field;
                translateService.get(text).subscribe(res => {
                    values.headerName = res;
                });
                values.headerName = ProductDataRemap.FieldDisplayNameEngine(
                    productremapJson, SubForm.OdataKey, values.headerName);

                if (values.type === 'dateColumn') {
                    values.valueFormatter = Formaters.dateformater;
                } else if (values.columnType === 'decimal') {
                    values.valueFormatter = DecimalFormater;
                } else if (values.columnType === 'currency') {
                    values.valueFormatter = Formaters.currencyFormater;
                }
                if (values.cellRenderer !== 'actionRenderer') {
                    values.resizable = true;
                    values.minWidth = '75';
                    values.tooltipField = values.field;
                }
                if (values.field === 'icon' &&
                    values.hasOwnProperty('categoryIcon') && values.categoryIcon.EnableCategoryIcon) {
                    values.minWidth = '20';
                    values.cellRenderer = function (params: any) {
                        let a = `<div class='grid-icon'>`;
                        const IconObject = CategoryIcons.categoryIconName(params.data);
                        if (IconObject !== null && IconObject !== undefined &&
                            IconObject) {
                            a += `<i class="${IconObject.IconName}" title="${IconObject.IconToolTip}"></i>`;
                        }
                        a += `</div>`;
                        return a;
                    };
                }
                const columnHide = ProductDataRemap.FieldVisibleRemapEngine(
                    productremapJson, SubForm.OdataKey, values.field, values.hide);
                values.hide = !columnHide;
                return values;
            });
        }
        gridData.GridHeaderDefination = headerDefs;
        gridData.TotalRecords = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions = {} as GridActionsConfig;
        const GridTittleName = SubForm.DisplayName;
        gridData.GridActions.GridTitle = ProductDataRemap.FieldDisplayNameEngine(
            productremapJson, SubForm.OdataKey, SubForm.DisplayName, `GridTittle-${GridTittleName}`, translateService);
        if (SubForm.NestedGridData && SubForm.NestedGridData.GridTitleType) {
            gridData.GridActions.GridTitleType = {} as TitleType;
            gridData.GridActions.GridTitleType.Type = SubForm.NestedGridData.GridTitleType.Type;
            gridData.GridActions.GridTitleType.TypeConfig = SubForm.NestedGridData.GridTitleType.TypeConfig;
            gridData.GridActions.GridTitleType.SelectedTypeId = 1;
        } else if (SubForm.NestedGridData.GridActions.GridTitleType) {
            gridData.GridActions.GridTitleType = SubForm.NestedGridData.GridActions.GridTitleType;
            gridData.GridActions.GridTitleType.SelectedTypeId = 1;
        }
        gridData.GridActions.TotalItems = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions.ShowTextWithDropdown = SubForm.NestedGridData.ShowTextWithDropdown !== undefined ?
            SubForm.NestedGridData.ShowTextWithDropdown : (SubForm.NestedGridData.GridActions && SubForm.NestedGridData.
                GridActions.ShowTextWithDropdown ? SubForm.NestedGridData.GridActions.ShowTextWithDropdown : false);
        gridData.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
        gridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
        gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
        gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
        gridData.GridActions.SearchConfig = {} as GridSearchConfig;
        gridData.GridActions.SearchConfig.EnabledSearch = SubForm.NestedGridData.GridSearch &&
            SubForm.NestedGridData.GridSearch.length > 0 ? true : (SubForm.NestedGridData.GridActions && SubForm.NestedGridData.
                GridActions.SearchConfig ? SubForm.NestedGridData.GridActions.SearchConfig.EnabledSearch : false);
        if (SubForm.Name === 'Wizard' || SubForm.Name === 'Pricing') {
            gridData.GridActions.SearchConfig.EnabledSearch = false;
        }
        if (gridData.GridActions.SearchConfig.EnabledSearch) {
            gridData.GridActions.SearchConfig.SearchPlaceholder = SubForm.NestedGridData.GridSearch &&
                SubForm.NestedGridData.GridSearch.length > 0 ?
                SubForm.NestedGridData.GridSearch[0].SearchPlaceholderName : SubForm.NestedGridData.GridActions.SearchConfig.SearchKey;
            gridData.GridActions.SearchConfig.SearchKey = SubForm.NestedGridData.GridSearch &&
                SubForm.NestedGridData.GridSearch.length > 0 ?
                SubForm.NestedGridData.GridSearch[0].SearchKey : SubForm.NestedGridData.GridActions.SearchConfig.SearchKey;
            gridData.GridActions.SearchConfig.SearchPlaceholder = ProductDataRemap.FieldDisplayNameEngine(
                productremapJson, SubForm.OdataKey, `Search ${gridData.GridActions.SearchConfig.SearchPlaceholder}`,
                `Search ${gridData.GridActions.SearchConfig.SearchPlaceholder}`, translateService);
        }
        gridData.GridActions.FilterConfig = {} as GridFilter;
        gridData.GridActions.FilterConfig.EnableFilter = SubForm.NestedGridData.GridFilter &&
            SubForm.NestedGridData.GridFilter.length > 0 ? true : (SubForm.NestedGridData.GridActions && SubForm.NestedGridData.
                GridActions.FilterConfig ? SubForm.NestedGridData.GridActions.FilterConfig.EnableFilter : false);
        if (gridData.GridActions.FilterConfig.EnableFilter) {
            getFilterOptions(SubForm.NestedGridData, ChildNavigationProperty,
                httpService).then(x => {
                    gridData.GridActions.FilterConfig.FilterOptions = x;
                    gridData.GridActions.FilterConfig.EnableFilter = x && x.length > 0 ? true : false;
                });
        } else {
            gridData.GridActions.FilterConfig.FilterOptions = [];
        }
        gridData.GridActions.PaginationConfig = {} as GridPaginationConfig;
        gridData.GridActions.PaginationConfig.EnabledPaginationation = false;
        gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
        gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
        gridData.dropdownChange = SubForm.NestedGridData.dropdownChange;
        gridData.searchTextChanged = SubForm.NestedGridData.searchTextChanged;
        gridData.filterApplied = SubForm.NestedGridData.filterApplied;
        gridData.rowClicked = SubForm.NestedGridData.rowClicked;
        gridData.rowNameInfoClicked = SubForm.NestedGridData.rowNameInfoClicked;
        gridData.sortChanged = SubForm.NestedGridData.sortChanged;
        gridData.fetchFilterDatas = SubForm.NestedGridData.fetchFilterDatas;
        return gridData;
    } catch (error) {
        console.error('Error occurred in AssignGridData', error);
    }
}

function assignRowData(ResponseData, ViewModelEntity, GridHeaderDefinition?: Array<GridHeaderConfig>) {
    try {
        if (ResponseData) {
            ResponseData.forEach(dataobject => {
                for (const prop in dataobject) {
                    if (prop) {
                        // dataobject[prop] = Formater(prop, dataobject[prop], null, ViewModelEntity);
                        if (dataobject[prop] && dataobject[prop] !== null) {
                            const propType = typeof dataobject[prop];
                            const isArrayprop = Array.isArray(dataobject[prop]);
                            if (propType === 'object' && !isArrayprop) {
                                assignGridNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                            } else if (isArrayprop) {
                                assignGridNavigationArrayProperty(prop, dataobject[prop], dataobject, ViewModelEntity,
                                    GridHeaderDefinition);
                            }
                        }
                    }
                }
            });
            return ResponseData;
        } else {
            return [];
        }
    } catch (error) {
        console.log('error occured assignRowData', error);
    }
}

function assignGridNavigationArrayProperty(ProperityName: any, ProperirtyObject: Array<any>,
    PrimaryProperity: object, ViewModelEntityColl: any, GridHeaderDefinition?: Array<GridHeaderConfig>) {
    try {
        const entityObject = PrimaryProperity;
        const MultiArrayGridHeader = GridHeaderDefinition.filter(x => x['cellEditorParams'] &&
            x['cellEditorParams'].UpdatedProperty === ProperityName);
        if (MultiArrayGridHeader.length > 0) {
            const GridViewData = [];
            const GridPropertyName = MultiArrayGridHeader[0].field;
            ProperirtyObject.forEach(data => {
                let GridValue: any;
                if (MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue) {
                    MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue.forEach((element, index) => {
                        data = data[element];
                        if (index === (MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue.length - 1)) {
                            GridValue = data;
                        }
                    });
                    GridViewData.push(GridValue);
                } else {
                    GridViewData.push(data);
                }
            });
            entityObject[GridPropertyName] = GridViewData.join(', ');
        } else {
            entityObject[ProperityName] = ProperirtyObject.join(', ');
        }
        return entityObject;
    } catch (error) {
        console.error('Error occurred in assignGridNavigationArrayProperty', error);
    }
}

function assignGridNavigationProperity(ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl.hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl['NavigationProperty'].length > 0) {
        ViewModelEntityColl['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'].toLowerCase().toString();
            if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
                //  if (element['ReferentialConstraint']) {
                //     const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toString();
                if (navProperiperityName.toLowerCase() !== 'ingredientsbarcodes') {
                    entityObject[ProperityName] = ProperirtyObject['Name'];
                } else {
                    entityObject[ProperityName] = ProperirtyObject && ProperirtyObject[0] ? ProperirtyObject[0].Barcode : '-';
                }
                //  }
            }
        });
    }
    return entityObject;
}


function calculationOperation(calcField: CalcultionField, FormData: any) {
    try {
        let returnValue = 0;
        let FirstOperand = isNaN(parseFloat(FormData[calcField.OperandFields[0].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[0].OdataKey]);
        let SecondOperand = isNaN(parseFloat(FormData[calcField.OperandFields[1].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[1].OdataKey]);
        if (isNaN(FirstOperand)) {
            FirstOperand = 0;
        }
        if (isNaN(SecondOperand)) {
            SecondOperand = 0;
        }
        switch (calcField.OperationType) {
            case 'Add':
                returnValue = FirstOperand + SecondOperand;
                break;
            case 'Multiply':
                returnValue = FirstOperand * SecondOperand;
                break;
            case 'Divide':
                if (FirstOperand !== 0 && SecondOperand !== 0) {
                    returnValue = FirstOperand / SecondOperand;
                    if (isNaN(returnValue)) {
                        returnValue = 0;
                    }
                }
                break;
        }
        return returnValue;
    } catch (error) {
        console.error('Error occurred in calculationOperation', error);
    }
}

function getFilterOptions(MasterDataCollection, ViewModelEntity, httpService): any {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    return new Promise<any>((resolve, reject) => {
        const GridFilterRowData = [];
        MasterDataCollection.GridFilter.forEach((GridFilterDataObj: GridFilterAction) => {
            const navigationProp = {} as CustomFilterNavigationUrl;
            let originalkeyname = '';
            const Navigation = ViewModelEntity['NavigationProperty']
                .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] === GridFilterDataObj.FilterOdataKey);
            if (Navigation.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                ViewModelEntity['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
                    const NavigationProperityName = NavigationProperty['$']['Name'];
                    if (NavigationProperityName === GridFilterDataObj.FilterOdataKey) {
                        let NavigationReferentialConstraint = '';
                        if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                            NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                            originalkeyname = NavigationProperty['ReferentialConstraint'][0]['$']['Property'];
                        }
                        const EntityNameList = NavigationProperty['$']['Type'].split('.');
                        let EntityName = '';
                        if (EntityNameList.length > 0) {
                            EntityName = EntityNameList[EntityNameList.length - 1];
                            navigationProp.RequestUrl = `/inventory/api/${EntityName}`;
                            navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                            navigationProp.EnabledCustomField = false;
                        }
                    }
                });
            } else {
                if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('CustomFilters') &&
                    GridFilterDataObj.CustomFilters.EnableCustomFilter) {
                    navigationProp.RequestUrl = GridFilterDataObj.CustomFilters.CustomNavigationUrl;
                    navigationProp.EnabledCustomField = true;
                    navigationProp.NavigationContraintName = GridFilterDataObj.FilterOdataKey;
                }
            }
            const filterDisplay = {} as GridFilterData;
            filterDisplay.FilterKey = GridFilterDataObj.FilterDisplayName;
            filterDisplay.FilterValue = [];
            filterDisplay.FilterOriginalKey = originalkeyname;
            filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
            filterDisplay.Type = 'multiselect';
            filterDisplay.FilterUrl = navigationProp.RequestUrl;
            filterDisplay.CustomMapping = GridFilterDataObj.CustomMapping;
            filterDisplay.SortOrder = GridFilterDataObj.SortOrder;
            filterDisplay.MappingKeyForNonOdataCore = GridFilterDataObj.MappingKeyForNonOdataCore;
            filterDisplay.LoadOnClick = GridFilterDataObj.hasOwnProperty('LoadOnClick') ?
            GridFilterDataObj.LoadOnClick : false;
            filterDisplay.EnableLoader = GridFilterDataObj.hasOwnProperty('EnableLoader') ?
            GridFilterDataObj.EnableLoader : false;
            GridFilterRowData.push(filterDisplay);
            // if (navigationProp && navigationProp.RequestUrl !== null) {
            //     httpService.GethttpMethod(navigationProp.RequestUrl).subscribe((response: any) => {
            //         if (response && response.value && response.value.length > 0) {
            //             const filterKeyData = response.value.map((data: any) => {
            //                 const filterKey = {} as GridFilterValue;
            //                 filterKey.FilterId = GridInterface.GridFilterKeyMapping(GridFilterDataObj, data);
            //                 filterKey.FilterName = GridInterface.GridFilterLabelMapping(GridFilterDataObj, data);
            //                 filterKey.Checked = false;
            //                 return filterKey;
            //             });
            //             const filterDisplay = {} as GridFilterData;
            //             filterDisplay.FilterKey = GridFilterDataObj.FilterDisplayName;
            //             filterDisplay.FilterValue = filterKeyData;
            //             filterDisplay.FilterOriginalKey = originalkeyname;
            //             filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
            //             filterDisplay.Type = 'multiselect';
            //             GridFilterRowData.push(filterDisplay);
            //         }
            //         resolve(GridFilterRowData);
            //     },
            //         (responseError) => {
            //             console.error('error caught in component', responseError);
            //         });
            // }
        });
        resolve(GridFilterRowData);
    });
}


function assignProductItemDetails(responseData) {
    try {
        responseData.forEach(element => {
            element.UnitCode = element.ProductUnits ? element.ProductUnits.Name + ` (${element.ProductUnits.Code})` : '';
            element.UnitCost = (element.Price && element.Price > 0 && element.Qty && element.Qty > 0) ? element.Price / element.Qty : 0;
            element.Name = element.ItemName;
            const baseUnitCost: Array<any> = element['LinkedItemUnits'] && element['LinkedItemUnits'].length > 0 ?
                element['LinkedItemUnits'].filter((e: any) => (e.UnitTypeId === 1 || e.UnitTypeId === 5)) : [];
            element['BaseUnitCost'] = (baseUnitCost.length > 0) ? (baseUnitCost[0].hasOwnProperty('CostPerUnit')) ?
                baseUnitCost[0].CostPerUnit : baseUnitCost[0].UnitCost : 0;
            // element.UnitCost = Formater('UnitCost', element.UnitCost, null, null);
        });
        return responseData;
    } catch (error) {
        console.error('error occured in assignProductItemDetails', error);
    }
}

function getDivisionsIds(ProductPOSDivisions: Array<any>): any {
    try {
        const ids = ProductPOSDivisions.map(x => {return x.PosDivisionId;}).join(',');
        return ids;
    } catch (error) {
        console.error('Error occurred in getDivisionIds', error);
    }
}