import { Injectable } from '@angular/core';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { MasterInventoryManagement, NestedBuilderData, NestedFormConfiguration, SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

@Injectable({
    providedIn: 'root'
})
export class AgilysysGroupingPopupService {
    changedIds = [];

    constructor() { }

    SearchChanged(searchValue: any, MasterInventoryManagements) {
        try {
            let currentFilter = '';
            const SearchText = searchValue.replaceAll('\'', '\'\'');
            searchValue = encodeURIComponent(SearchText).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            MasterInventoryManagements.GridConfig.GridSearch.forEach(element => {
                if (element) {
                    const query = 'contains(' + element.SearchKey + ',\'' + searchValue + '\')';
                    currentFilter = this.addFilter(currentFilter, query);
                }
            });
            return currentFilter;
        } catch (error) {
            console.error(error);
        }
    }

    addFilter(currentFilter, filter) {
        if (currentFilter.length === 0) {
            currentFilter = filter;
        } else {
            currentFilter += ' or ' + filter;
        }
        return currentFilter;
    }

    groupingNestedFormConstraction(
        MasterDataCollection: MasterInventoryManagement,
        ResponseObject: any): NestedFormConfiguration {
        try {
            if (
                MasterDataCollection.NestedFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig &&
                MasterDataCollection.NestedFormConfig.ViewFormConfig.length > 0) {
                MasterDataCollection.NestedFormConfig.ViewFormConfig.forEach((x: SubFormConfigs, i) => {
                    if (x.Type === 'listing') {
                        if (ResponseObject['value'].length > 0) {
                            x.data[0].NestedData = [];
                            ResponseObject['value'].forEach((element: any) => {
                                const NestedDatabuilder = {} as NestedBuilderData;
                                const Count = element.hasOwnProperty('PackLists') && element.PackLists.length > 0 ?
                                 element['PackLists'].filter(pack => pack.IsActive === true).length : 0;
                                NestedDatabuilder.DisplayName = `${element['Name']}(` + `${Count})`;
                                NestedDatabuilder.Value = element['Description'] ? element['Description'] : '-';
                                x.data[0].NestedData.push(NestedDatabuilder);
                            });
                        } else {
                            x.data[0].NestedData = [];
                        }
                    }
                });
                return MasterDataCollection.NestedFormConfig;
            }
        } catch (error) {
            console.error(error);
        }
    }

    formQueryParams(params: QueryParams) {
        params.filter = '';
        const keys = Object.keys(params);
        let query = '';
        keys.forEach((data, index) => {
            if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
                if (params[data] !== '' && data !== 'filter') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        if (query.length > 0) {
                            query = query + '&$';
                        } else {
                            query = query + '?$';
                        }
                    }
                    query = query + data + '=' + params[data];
                } else if (data === 'filter') {
                    if (params.isactive) {
                        params[data] = 'IsActive eq true';
                    }
                    if (params.search && params.search.length > 0) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.search;
                        } else {
                            params[data] = params.search;
                        }
                    }
                    if (params.filterQuery && params.filterQuery.length > 0) {
                        if (params[data].length > 0) {
                            params[data] = params[data] + ' and ' + params.filterQuery;
                        } else {
                            params[data] = params.filterQuery;
                        }
                    }

                    if (params[data] !== '') {
                        if (index === 0) {
                            query = query + '?$';
                        } else {
                            query = query + '&$';
                        }
                        query = query + data + '=' + params[data];
                    }
                }
            }
        });
        return query;
    }

    formPostModelData(MasterData: MasterInventoryManagement, datalist: Array<NestedBuilderData>, PostModelEntity: any) {
        try {
            this.changedIds = [];
            const PostObject = [];
            datalist.forEach(elements => {
                const postData = {};
                const dataName = elements.DisplayName.substring(0, elements.DisplayName.lastIndexOf('('));
                const dataObject = MasterData.GridConfig.GridData.filter(x => x.Name === dataName)[0];
                if (dataObject['PackLists'] && dataObject['PackLists'].length > 0) {
                    const index = dataObject['PackLists'].findIndex(x => x.Item.toString() ===
                        MasterData.DetailsConfig.DetailsData[0].Id.toString());
                    if (index > -1 && !elements.Selected) {
                        const ObjectData = dataObject['PackLists'][index];
                        postData['Id'] = dataObject.Id;
                        postData['Name'] = dataObject.Name;
                        postData['TypeId'] = dataObject.TypeId;
                        postData['Description'] = dataObject.Description;
                        postData['IsActive'] = dataObject.IsActive;
                        postData['Number'] = dataObject.Number;
                        postData['PackLists'] = [{
                            Item: MasterData.DetailsConfig.DetailsData[0].Id,
                            IsActive: true,
                            Id: ObjectData.Id,
                            IsDeleted: true
                        }];
                        PostObject.push(postData);
                    } else if (index > -1 && elements.Selected) {
                        const ObjectData = dataObject['PackLists'][index];
                        postData['Id'] = dataObject.Id;
                        postData['Name'] = dataObject.Name;
                        postData['TypeId'] = dataObject.TypeId;
                        postData['Description'] = dataObject.Description;
                        postData['IsActive'] = dataObject.IsActive;
                        postData['Number'] = dataObject.Number;
                        postData['PackLists'] = [ {
                            Item: MasterData.DetailsConfig.DetailsData[0].Id,
                            IsActive: true,
                            Id: ObjectData.Id,
                            IsDeleted: false
                        }];
                        PostObject.push(postData);
                    } else if (index === -1 && elements.Selected) {
                        postData['Id'] = dataObject.Id;
                        postData['Name'] = dataObject.Name;
                        postData['TypeId'] = dataObject.TypeId;
                        postData['Description'] = dataObject.Description;
                        postData['IsActive'] = dataObject.IsActive;
                        postData['Number'] = dataObject.Number;
                        postData['PackLists'] = [ {
                            Item: MasterData.DetailsConfig.DetailsData[0].Id,
                            IsActive: true,
                            Id: 0,
                            IsDeleted: false,
                            Position: dataObject['PackLists'].length + 1 
                        }];
                        PostObject.push(postData);
                    }
                } else {
                    if (elements.Selected) {
                        postData['Id'] = dataObject.Id;
                        postData['Name'] = dataObject.Name;
                        postData['TypeId'] = dataObject.TypeId;
                        postData['Description'] = dataObject.Description;
                        postData['IsActive'] = dataObject.IsActive;
                        postData['Number'] = dataObject.Number;
                        postData['PackLists'] = [ {
                            Item: MasterData.DetailsConfig.DetailsData[0].Id,
                            IsActive: true,
                            Id: 0,
                            IsDeleted: false,                            
                            Position: 1 
                        }];
                        PostObject.push(postData);
                    }
                }
                // PostModelEntity.Property.forEach(element => {
                //     // && element['$']['Name'] !== 'ItemCount'
                //     if (element['$']['Name'] !== 'PackLists' ) {
                //         postData[element['$']['Name']] = dataObject[element['$']['Name']];
                //     } else if (element['$']['Name'] === 'PackLists') {
                //         if (dataObject['PackLists']) {
                //             const arrayList = dataObject['PackLists'];
                // eslint-disable-next-line max-len
                // eslint-disable-next-line max-len
                //             if (arrayList.findIndex(x => x['Item'].toString() === MasterData.DetailsConfig.DetailsData[0].Id.toString()) === -1
                //             && elements.Selected) {
                // eslint-disable-next-line max-len
                //                 postData['ItemList'] = dataObject['ItemList'] + ',' + MasterData.DetailsConfig.DetailsData[0].Id.toString();
                //             } else if (arrayList.findIndex(x => x === MasterData.DetailsConfig.DetailsData[0].Id.toString()) >   -1
                //             && !elements.Selected) {
                //                 const arrayLists  = dataObject['ItemList'].split(',');
                //                 const index = arrayLists.findIndex(x =>
                //                     x.toString() === MasterData.DetailsConfig.DetailsData[0].Id.toString());
                //                 if (index > -1) {
                //                     arrayLists.splice(index, 1);
                //                     postData['ItemList'] = arrayLists.join(',');
                //                 } else {
                //                     postData['ItemList'] = dataObject['ItemList'];
                //                 }
                //             } else {
                //                 postData['ItemList'] = dataObject['ItemList'];
                //             }
                //         } else {
                //             postData['ItemList'] = elements.Selected ? MasterData.DetailsConfig.DetailsData[0]['Id'].toString() : null;
                //         }
                //     }
                //     //  else if (element['$']['Name'] === 'ItemCount') {
                //     //     if (dataObject['ItemList']) {
                //     //         if (postData['ItemList']) {
                //     //             const Count = postData['ItemList'].split(',').length;
                //     //             postData['ItemCount'] = Count;
                //     //         } else {
                //     //             postData['ItemCount'] = 0;
                //     //         }
                //     //     } else {
                //     //         postData['ItemCount'] = 1;
                //     //     }
                //     // }
                //     //  else if (element['$']['Name'] === 'Id') {
                //     //     this.changedIds.push(dataObject[element['$']['Name']]);
                //     // }
                // });
                // if (dataObject['ItemList'] !== postData['ItemList']) {
                //     this.changedIds.push(dataObject['Id']);
                //     PostObject.push(postData);
                // }
            });
            return PostObject;
        } catch (error) {
            console.error(error);
        }
    }

}
