import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { SpaLocalization } from './spa-localization'

@Pipe({ name: "Currency" })
@Injectable()
export class CustomCurrencyPipe implements PipeTransform {
    private DECIMAL_SEPARATOR: string;
    private THOUSANDS_SEPARATOR: string;
    constructor(private language: SpaLocalization) {
    }

    transform(value: any, currencySymbolRequired: boolean = true): string {       
        return this.language.localizeCurrency(value, currencySymbolRequired);        
    }
}