import { Injectable, Directive, Output, EventEmitter } from '@angular/core';
import { HttpServiceCall, HttpMethod } from './shared/service/http-call.service';
import { BaseResponse, CommissionTemplate, ClientLabel, ServicePriceType, clientRecentAppsResponse, RecentAppointment, ServicePriceTypeWithCode, ClientScreenProperties, ClientSOAPNote, ClientCreditCardInfo } from './retail.modals';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from './shared/dialog-popup/dialogPopup-componenet';
import { multiClientInfo, ClientInfo, Address, TempHoldApp } from './shared/business/shared.modals';
import { RetailUtilities } from './shared/utilities/retail-utilities';
import { RetailLocalization } from './common/localization/retail-localization';
import { cloneDeep } from 'lodash';
import { UntypedFormBuilder, NgForm, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { BreakPointAccess } from './shared/service/breakpoint.service';
import { filter } from 'rxjs/operators';
import * as GlobalConst from './shared/globalsContant';
import { Router, NavigationEnd } from '@angular/router';
import { UpdatedPlayerCustomFee, PlayerTransaction, PlayerTransactionDetail, CancelPlayers, CancellationNoShowLedger } from './shared/shared.modal';
export enum PackageBook {
    Express = 'Express',
    Standard = 'Standard'
}

export enum SelectedSession {
    ClientAppointment = 0
}


@Directive()
@Injectable()
export class RetailService {
    commissionTemplate: CommissionTemplate[] = [];
    public activeFormGroup: UntypedFormGroup;
    private _useRetailInterface: boolean;
    isClientCompleted: boolean = false;
    clientsearchArray: any;
    guestInfo: any;
    labelRecords: ClientLabel[] = [];
    multipleRecords: boolean = false;
    recordsArray: any = [];
    multiClientInfo: multiClientInfo[] = [];
    isAptPriceReadOnly: boolean = false;
    selectedServicePriceTypes: ServicePriceType[] = [];
    appoinmentdetailArray: any = [];
    RecentAppsUIArr: RecentAppointment[];
    captions: any = this.localization.captions.bookAppointment;
    activeBorderId: number = 0;
    showDetails: boolean;
    clientId: any;
    depositArray: any = [];
    packageDeepdata = [];
    PropertyInfo: any;
    clientSelect: any = 1;
    payeedId: number = 0
    productId: number;
    retailMethodInvoker = new EventEmitter<[string, any]>();
    memberRemove:boolean=false;
    memberGuestId :any;

    constructor(
        private dialog: MatDialog,
        private httpService: HttpServiceCall, private utils: RetailUtilities,
        public localization: RetailLocalization, public fb: UntypedFormBuilder, private breakPoint: BreakPointAccess, private httpserviceCall: HttpServiceCall,
        private route: Router
    ) {
        this.popupTitle = this.captions.NewAppointment;
        this.appointmentTabName = this.captions.AppointmentDetails;
        this.clientTabName = this.captions.ClientInformation;
        this.personalTabName = this.captions.PersonalInformation;
        this.additionalInformationTabName = this.captions.AdditionalInformation;
        this.medicalTabName = this.captions.MedicalHistory;
        this.editClientFromPackage = false;
        this.clientScreenProperties = {
            helperText: '',
            minGuest: 0,
            maxGuest: 5 //default allowable clients can be search : 5

        }
        this.historyTabName = this.captions.History;
        this.preferencesTabName = this.captions.Preferences;
        this.soapNotesTabName = this.captions.SoapNotes;
        this.itineraryTabName = this.captions.Itinerary;

        this.currentUserSessionId = this.utils.GetCurrentUserSessionId();

        // APP MODULE SERVICE
        route.events.pipe(filter((event: any) => event instanceof NavigationEnd))
            .subscribe(event => {
                this.showHeaderBar = event.url === '/shop/viewshop/order' ? true : false;
            });

        this.productId = Number(this.utils.GetPropertyInfo('ProductId'));
        if (this.productId === GlobalConst.Product.RETAIL) {
            this.clientSelect = 2;
        } else {
            this.clientSelect = 1
        }
    }

    BuildItemOutletMappingForTransfer(transferItemData): any {
        let result: any[] = [];
        transferItemData.forEach(item => {
            item.outlets.forEach(outlet => {
                result.push({ itemId: item.itemId, outletId: outlet.toOutletId })
            });
        });
        return result;
    }


    public get UseRetailInterface() {
        return cloneDeep(this._useRetailInterface);
    }

    openErrorDialog(error, Type, header): void {
        this.dialog.open(DialogOverviewExampleDialog, {
            height: 'auto',
            width: '600px',
            data: { headername: header, closebool: true, templatename: 'In the Given Data', datarecord: error, Type: Type },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true,
            backdropClass: 'Overlay-Alerts'
        });
    }



    @Output() change = new EventEmitter();
    activeSelectedClient: number;
    clientWidowActionType: string;
    clientEditData: ClientInfo;
    localisation: any = {};
    popupTitle: any;
    totalData: any;
    isOverbook: any;
    appointmentTabName: any;
    clientTabName: any;
    personalTabName: any;
    additionalInformationTabName: any;
    medicalTabName: any;
    historyTabName: any;
    otherdetails = { linkcode: 0, vip: false, NoMove: false, gender: 0, customField1: 0, customField2: 0, customField3: 0, customField4: '', customField5: '', comments: '', policy: '', checkout: '', valueChange: false, client_scheduling: false };
    clinetInfo: any = { clientcomments: '', selectedtherapistId: 1 };
    REQUIREGENEDERPREFRENCE: boolean = false;
    cancelBool: boolean;
    moreviewTag: boolean = false;
    therapistArray: any = [];
    selectedtherapistId: any;
    appoinmentDetailFormArray: UntypedFormGroup;
    clientInfoPriceType: ServicePriceTypeWithCode[] = [];
    therapistidArray: any = [];   //Get Therapist Array Id
    selectedIndex: any;
    ClientSelectedTab: number;
    selectedClientIndex: any;
    therapistNotSelected: boolean = false;
    clientScreenProperties: ClientScreenProperties;
    attachClient: boolean;
    therapistAssociationError: boolean;
    isTherapistView: boolean;
    checkVar: string;
    isEditAppointment: boolean = false;
    clientImageUrl: any;
    ImgTempHolder: any;
    VIP: any;
    saveText: any;
    GenderPreferences: any;
    EnforceGender: any;
    NoPreference: any;
    ServiceComments: any;
    CheckOutComments: any;
    checkwhetherfromClient: boolean = false;//this value to used to set the value of the save and update button under variuos scenarios
    public isfromAppoinment: boolean = false;
    private _clientImageObj = new BehaviorSubject<number>(0);
    clientImageObj: any = this._clientImageObj.asObservable();
    imageArray: any = [];
    allPriceTypes: any = [];
    clientInfopriceTypeModel: number;
    selectedClientPriceValue: number;
    aptPriceTypeGlobalService: any;
    isMultiPackAppointment: boolean = false;

    private _clientInfoObj1 = new BehaviorSubject<any>(0);
    clientInfoObj1: any = this._clientInfoObj1.asObservable();
    clientMultipacks: any;
    isCancel: boolean; //Fix For No changes in client-info
    isTestbool: boolean = false;
    serviceChanged: boolean = false;
    fromClientModule: boolean = false;
    addFromClientModule: boolean = false;
    private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
    clientAddedFromClientInformation: boolean = false;
    clientComments: string;
    clientTherapist: any;
    commentPlaceHolder: string;
    clientInfoChange(info: any) {
        this._clientInfoObj1.next(info);
    }

    medicalhistoryArray: any = [];

    activateClientSelected: any;

    addDepositarray: any = [{ "clientId": "", "linkClientId": [] }];
    priceTypes: any = [];
    preferencesTabName: string;
    soapNotesTabName: string;
    clientPreferences: any = {};
    clientSoapNotes: ClientSOAPNote[] = [];
    clientCreditCardInfo: ClientCreditCardInfo[] = [];
    clientPreferedGender: any;
    clientPreferedService: any[] = [];
    clientPreferedTherapist: any[] = [];
    itineraryTabName: any;
    allCaptions: any = this.localization.captions;
    serviceArray: any;
    intialClinetArray: any = [];
    recordobjId: any = [];
    depositStartDate: any;
    depositEndDate: any;
    linkId: any;
    objidArray: any;
    clientIds: any;
    showProcess: boolean = false;
    isFromAppointmentSearch: boolean = false;
    clientHasPic: any;
    isClientImgRemoved: boolean;
    disablePreference: boolean = false;
    addwaitlist: boolean = false;
    IsAppointmentDisabled: boolean;
    //Express package stepper
    isPackageCompleted: boolean = false;
    isExpressServiceCompleted: boolean = false;
    isStandardServiceCompleted: boolean = false;
    isOtherInfoComplted: boolean = false;

    CheckForUpdate: boolean = false;
    appointmentBooking: boolean = false;
    editselectedClient: boolean = false;
    currentUserSessionId: number = 0;


    ngOnDestroy(): void {
        this.$destroyed.next(true);
        this.$destroyed.complete();
        this.isAptPriceReadOnly = false;
        this.commentPlaceHolder = '';
        this.clientAddedFromClientInformation = false;
        this.isMultiPackAppointment = false;
        this.payeedId = 0;
    }



    therapistBreak = { therapistId: 0, therapistName: '', startDate: '', startTime: '', breakDuration: 0, breakType: '', comments: '' };
    editClientFromPackage: boolean = false;
    add_client: boolean = false;
    exsisting_client: boolean = false;
    getServiceDetails: string = "assets/service_details.json";
    getPersonalDetails: string = "assets/personal_details.json";
    value_holder: any = [];
    personal_details_holder: any = [];
    resultNewAppointment: any = {};
    existingAppointmentsRef: any;
    resultExistingAppointment: any;
    resultExistingClients: any;
    resultNewClient: any = {};
    phoneLineLength: number = 5;
    addressLineLength: number = 3;
    SelectedClientId: any;
    RecentAppsResponse: clientRecentAppsResponse[]
    //Client Screens FormGroup - Begins
    personalDetailsFormGroup: UntypedFormGroup;
    additionalDetailsFormGroup: UntypedFormGroup;
    otherDetailsFormGroup: UntypedFormGroup;
    medicalHistoryFormGrp: UntypedFormGroup;
    soapNotesFormGrp: UntypedFormGroup;
    //Client Screens FormGroup - End
    clientInformationFormInfo: multiClientInfo[];
    personalDetailAddress: Address;
    personalIsValid: boolean;
    additionalIsValid: boolean;
    IsEmailAvailable: boolean;
    IsPhoneAvailable: boolean;
    IsCopyFromClient: any = [];
    IsRequiredOnAppointment: any = [];
    preferenceFormGroup: UntypedFormGroup;
    clientInformationForm: NgForm;
    IsAddClientFromSearch: boolean = false;
    IsAddClientFromSPA: boolean = false;
    appoinementConversion: boolean = false;
    // hasAccessForMedicalHistory: boolean = true;
    // hasAccessForSoapNotes: boolean = true;
    // hasAccessForEditSoapNotes: boolean = true;
    // hasAccessForClientPreference: boolean;
    appointmentBreakPoints: any;
    TempHoldArr: TempHoldApp[] = [];
    //default temp hold values
    tempHoldValues: TempHoldApp = {
        service: 0,
        location: 0,
        startTime: '',
        endTime: '',
        serviceGroupId: 0,
        comments: "",
        status: "TEMP",
        breakDownTime: 0,
        duration: 0,
        setUpTime: 0,
        price: 0,
        doNotMove: false,
        requestStaff: false,
        isVip: false,
        genderPreference: 0,
        checkOutComments: "",
        noShow: false,
        packageId: 0,
        clientId: 0,
        therapist: [],
        appointmentId: 0,
        cancelId: "",
        appointmentDate: '',
        TempHoldId: '',
        TempHoldLinkId: 0,
        originalClientId: 0,
        multiGroupId: 0,
        ServiceCharge: 0,
        Gratuity: 0,
        Tax: 0,
        TotalAmount: 0
    };
    clientGuestMap: any = [];
    ClientActivities: any = [];
    isPreviousDayAppt: boolean;
    clientScreenBreakPoints: any = [];
    isClientViewOnly: boolean;
    isPreferenceViewOnly: boolean;
    isSoapNotesViewOnly: boolean;
    IsOrderSummary: boolean = false;

    /**
     * @description Updates the given list of players as 'Paid' in Golf Schedule Db
     * @param playerIds
     */
    public async UpdateTransactionsWithPlayerStatus(playerTransactions: PlayerTransaction[], payeeId: number = 0): Promise<any> {
        try {

            return await this.httpserviceCall.CallApiAsync<any>({
                host: GlobalConst.Host.golfSchedule,
                callDesc: "UpdateTransactionsWithPlayerStatus",
                method: HttpMethod.Put,
                body: playerTransactions,
                uriParams: { "payeeId": payeeId }
            });

        } catch (e) {
            this.httpService.exceptionHandle(e);
        }
    }

    /**
     * @description 
     * Updates Scheduled tee time custom fees for players that have
     * been changed on shop screen / return with ticket
     */
    public async UpdatePlayerCustomFees(updatedPlayerCustomFees: UpdatedPlayerCustomFee[])
    {
        try
        {
            return await this.httpserviceCall.CallApiAsync<any>
            (
                {
                host: GlobalConst.Host.golfSchedule,
                callDesc: "UpdatePlayerCustomFees",
                method: HttpMethod.Put,
                body: updatedPlayerCustomFees
                }
            );
        }
        catch (e) 
        {
            this.httpService.exceptionHandle(e);
        }
    }

    public async CancelPlayerTeeTime(cancelPlayers: CancelPlayers)
    {
        try
        {
            return await this.httpserviceCall.CallApiAsync<any>
            (
                {
                host: GlobalConst.Host.golfSchedule,
                callDesc: "BulkCancelTeeTimePlayers",
                method: HttpMethod.Put,
                body: cancelPlayers
                }
            );
        }
        catch (e) 
        {
            this.httpService.exceptionHandle(e);
        }
    }

    public async CancellationNoShowLedger(cancellationNoShowLedger: CancellationNoShowLedger[])
    {
        try
        {
            return await this.httpserviceCall.CallApiAsync<any>
            (
                {
                host: GlobalConst.Host.golfSchedule,
                callDesc: "CreateCancellationNoShowLedger",
                method: HttpMethod.Post,
                body: cancellationNoShowLedger
                }
            );
        }
        catch (e) 
        {
            this.httpService.exceptionHandle(e);
        }
    }

    public async NoShowPlayers(cancelPlayers)
    {
        try
        {
            return await this.httpserviceCall.CallApiAsync<any>
            (
                {
                host: GlobalConst.Host.golfSchedule,
                callDesc: "NoShowTeeTimePlayerForMultiple",
                method: HttpMethod.Put,
                body: cancelPlayers
                }
            );
        }
        catch (e) 
        {
            this.httpService.exceptionHandle(e);
        }
    }
    public async UpdateCancellationNoShowLedger(playerId,status,ticketNumber)
    {
        try
        {
            return await this.httpserviceCall.CallApiAsync<any>
            (
                {
                host: GlobalConst.Host.golfSchedule,
                callDesc: "UpdateCancellationNoShowLedgerStatus",
                method: HttpMethod.Put,
                uriParams: { "playerId": playerId,"status": status ,"ticketNumber": ticketNumber}
                }
            );
        }
        catch (e) 
        {
            this.httpService.exceptionHandle(e);
        }
    }
    

    public async InsertPlayerTransactionRecord(playerTransactions: PlayerTransaction[]): Promise<any> {
        try {

            return await this.httpserviceCall.CallApiAsync<any>({
                host: GlobalConst.Host.golfSchedule,
                callDesc: "TeeTimes/InsertPlayerTransactionRecord",
                method: HttpMethod.Put,
                body: playerTransactions
            });

        } catch (e) {
            this.httpService.exceptionHandle(e);
        }
    }

    public async CreateTransLog(transactionId, logType,reqObject): Promise<any> {
        return await this.httpserviceCall.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: 'CreateTransLog',
            method: HttpMethod.Post,
            uriParams: { transactionId, logType},
            body: reqObject,
        });
    }

    public async MapTransactionDetailWithPlayer(playerTransactionDetails: PlayerTransactionDetail[]): Promise<any> {
        try {
            return await this.httpserviceCall.CallApiAsync<any>({
                host: GlobalConst.Host.golfSchedule,
                callDesc: "MapTransactionDetailWithPlayer",
                method: HttpMethod.Put,
                body: playerTransactionDetails,
            });
        } catch (e) {
            this.httpService.exceptionHandle(e);
        }
    }

    async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
            return await this.httpService.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
            });
        } catch (e) {
            this.httpService.exceptionHandle(e);
        }
    }


    ///APP MODULE SERVICE

    isglobalSearch: boolean;
    selectedShopObj: any;
    therapistObj: any;
    globalsearchTherapist: any = false;
    selectedClient: any;
    public arraysliceValue: number;
    OnTherapistOpen: EventEmitter<any> = new EventEmitter();
    paymentProcessing: boolean = false;
    showHeaderBar: boolean = false;
    paymentCompleted: boolean = true; // to disable actions till payment is completed
    loaderEnable: BehaviorSubject<string> = new BehaviorSubject('');



    //Inventory Related Service
    tabLoaderEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /**
     * @function updateLoaderState
     * @param isCallBack
     * @param isInitialLoad
     * @param callCounter
     */
    updateInitalLoads(isCallBack, initialLoads, callCounter) {
        if (initialLoads) {
            if (isCallBack) {
                callCounter--;
            }
            else {
                callCounter++;
            }
            if (callCounter === 0) {
                this.tabLoaderEnable.next(false)
                initialLoads = false;
            }
        }
        return [initialLoads, callCounter];
    }

}

function stringFormat(input: string, appendBy?: string) {
    if (appendBy) {
        const tempdata = (input == "" ? '' : input + appendBy);
        return input ? tempdata : ''
    }
    else {
        const tempDataFalse = (input == "" ? '' : input);
        return input ? tempDataFalse : ''
    }
}