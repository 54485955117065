import { BaseReport } from "./base-report";
import { AllReports } from "../report.modals";
import { AppointmentByLocation } from "../spa-reports/appointmentByLocation.report";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { Injectable } from "@angular/core";
import { AppointmentListing } from "../spa-reports/appointmentListing.report";
import { AppointmentByStaffMember } from "../spa-reports/appointmentByStaffMember.report";
import { ReportBusinessService } from '../report-business.service';
import { CustomFieldByDropDown } from "../spa-reports/customFieldByDropDown.report";
import { CustomFieldByTextBox } from "../spa-reports/customFieldByTextBox.report";
import { CancellationReport } from "../spa-reports/cancellation.report";
import { NoShowReport } from "../spa-reports/noshow.report";
import { AppointmentByColorLinkCode } from "../spa-reports/appointmentByColorLinkCode.report";
import { SpaUtilities } from "../../../shared/utilities/spa-utilities";
import { clientItinerary } from "../client/clientItinerary.report";
import { StaffSchedule } from '../spa-reports/staffSchedule.report';
import { ProjectedRevenue } from '../spa-reports/projectedRevenue.report';
import { Utilization } from '../spa-reports/utilization.report';
import { clientListing } from '../client/clientListing.report';
import { AppointmentByUser } from "../spa-reports/appointmentByUser.report";
import { BatchClientItinerary } from "../client/batchClientItinerary.report";
import { OnlineAppointment } from "../spa-reports/OnlineAppointment.report";
import { UserAccessReport } from "../security-reports/useraccess.report";
import { MemberServiceAvailed } from "../member/memberserviceavailed.report";
import { AppointmentDeposit} from "../spa-reports/AppointmentDeposit.report";
import { LostDeniedBusinessReport } from "../spa-reports/LostDeniedBusiness.report";
import { ClassClientListing } from "../spa-reports/classClientListing.report";
import { ClassClientNoShow } from "../spa-reports/classClientNoShow.report";
import { ClassClientCancellation } from "../spa-reports/classClientCancellation.report";
import { DayPassReport } from "../spa-reports/DayPass.report";
import { LockerUtilizationreport } from "../spa-reports/LockerUtilization.report";
import { PackageSoldReport } from "../spa-reports/packagesold.report";


@Injectable()
export class ReportFactory {
    private readonly _reportFormGrp: UntypedFormGroup;
    private readonly _dataService: ReportDataService;
    private readonly _business: ReportBusinessService;
    private readonly _utils: SpaUtilities;

    constructor(reportFormGrp: UntypedFormGroup, dataService: ReportDataService,
        business: ReportBusinessService, private utils: SpaUtilities) {
        this._reportFormGrp = reportFormGrp;
        this._dataService = dataService;
        this._business = business;
        this._utils = utils;
    }

    public Get(code: string, editableValue: any): BaseReport {
        switch (code) {
            case AllReports.AppointmentListingByLocation:
                return new AppointmentByLocation(this._reportFormGrp, this._dataService, editableValue);
            case AllReports.AppointmentListing:
                return new AppointmentListing(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.PackageSoldReport:
                return new PackageSoldReport(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.AppointmentByStaffMember:
                return new AppointmentByStaffMember(this._reportFormGrp, this._dataService, editableValue);
            case AllReports.AppointmentByLinkCode:
                return new AppointmentByColorLinkCode(this._reportFormGrp, this._dataService, editableValue);
            case AllReports.CustomField1:
                return new CustomFieldByDropDown(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.CustomField2:
                return new CustomFieldByDropDown(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.CustomField3:
                return new CustomFieldByDropDown(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.CustomField4:
                return new CustomFieldByTextBox(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.CustomField5:
                return new CustomFieldByTextBox(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.Cancellation:
                return new CancellationReport(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.NoShowAppointment:
                return new NoShowReport(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.Itinerary:
                return new clientItinerary(this._reportFormGrp, this._dataService, editableValue);
             case AllReports.MemberServiceAvailed:
                return new MemberServiceAvailed(this._reportFormGrp, this._dataService, editableValue);
            case AllReports.StaffSchedule:
                return new StaffSchedule(this._reportFormGrp, this._business, this._dataService, editableValue);
            case AllReports.ProjectedRevenue:    
                return new ProjectedRevenue(this._reportFormGrp, this._dataService, this._business, editableValue);
            case AllReports.Utilization:
                return new Utilization(this._reportFormGrp, this._dataService,editableValue);
            case AllReports.ClientListing:
                return new clientListing(this._reportFormGrp, this._dataService,this._business);
            case AllReports.AppointmentByUser:
                return new AppointmentByUser(this._reportFormGrp, this._dataService, editableValue);    
            case AllReports.BatchClientItinerary:
                return new BatchClientItinerary(this._reportFormGrp, this._dataService, editableValue);
            case AllReports.OnlineAppointment:
                return new OnlineAppointment(this._reportFormGrp, this._dataService, code, editableValue);
            case AllReports.UserAccessReport:
                return new UserAccessReport(this._reportFormGrp,this._dataService);
            case AllReports.AppointmentDeposit:
                return new AppointmentDeposit(this._reportFormGrp,this._dataService, editableValue);
            case AllReports.LostDeniedBusinessReport:
                return new LostDeniedBusinessReport(this._reportFormGrp,this._dataService, editableValue);
            case AllReports.DayPassReport:
                return new DayPassReport(this._reportFormGrp,this._dataService,code, editableValue);
            case AllReports.ClassClientListing:
                    return new ClassClientListing(this._reportFormGrp,this._dataService,code, editableValue);
            case AllReports.ClassClientNoShow:
                    return new ClassClientNoShow(this._reportFormGrp,this._dataService,code, editableValue);
            case AllReports.ClassClientCancellation:
                return new ClassClientCancellation(this._reportFormGrp,this._dataService,code, editableValue);
            case AllReports.LockerUtilization:
                return new LockerUtilizationreport(this._reportFormGrp,this._dataService,code, this._business);
                       
            default:
                break;
        }
    }
}