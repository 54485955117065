import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonAlertMessagePopupComponent } from '../common/shared/shared/alert-message-popup/alert-message-popup.component';
import { RetailFeatureFlagInformationService } from '../retail/shared/service/retail.feature.flag.information.service';

@Injectable({ providedIn: 'root' })
export class ClientCommonService {
  captions: any;
  commonCaptions: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private dialog: MatDialog,
    public localization: SpaLocalization
    , private _featureInfo: RetailFeatureFlagInformationService
  ) {
    this.captions = this.localization.captions.bookAppointment;
    this.commonCaptions = this.localization.captions.common;
  }

  openDialogPopup(data) {
    const isCMSRequirePin = this._featureInfo?.CMSRequirePin;
    let dialogRef = this.dialog.open(CommonAlertMessagePopupComponent, {
      width: '350px',
      maxWidth: '1000px',
      height: '272px',
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: {
        headername: this.captions.PlayerWorthDetails,
        closebool: true,
        type: 'PW',
        datarecord: { patronId: data, isCMSRequirePin: isCMSRequirePin, isPlayerPointsView: true, isPlayerCompsView: true},
        buttonName: ''
      },
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
