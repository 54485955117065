import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AgFieldConfig } from '../../Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { MatDialog } from '@angular/material/dialog';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';

@Component({
  selector: 'app-ag-incremental',
  templateUrl: './ag-incremental.component.html',
  styleUrls: ['./ag-incremental.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgIncrementalComponent  {
  customClass: string;
  errorMessage: string;
  form: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  placeHolderId: string;
  customErrorMessageId: string;
  errorMessageId: string;
  value: string | number;
  captions: any;
  isRequired: boolean;
  disabled: boolean;
  maxValue: number;
  increment: number;
  @Output() inputChange = new EventEmitter();
  @Output() outputValue = new EventEmitter();
  minValue: number;
  customErrorMessage: string;
  minError: string;
  maxError: string;
  floatLabel: string;
  automationId: string;
  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    if (!value) {
      return;
    }
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.maxValue = value.maxValue;
    this.isRequired = value.isRequired;
    this.minValue = value.minValue ? value.minValue : 0;
    this.increment = value.incrementBy ? value.incrementBy : 1;
    this.customErrorMessage = value.customErrorMessage;
    this.placeHolderId = value.placeHolderId;
    this.customErrorMessageId = value.customErrorMessageId;
    this.errorMessageId = value.errorMessageId;
    this.maxError = value.customMaxErrorMsg ? value.customMaxErrorMsg : this.captions?.err_max;
    this.minError = value.customMinErrorMsg ? value.customMinErrorMsg : this.captions?.err_min;
    this.automationId = value.automationId ? value.automationId: '';
    if (this.value != undefined) {
      this.form.controls[value.formControlName].setValue(this.value);
    }
    if (value.disabled) {
      this.form.controls[value.formControlName].disable();
    } else {
      this.form.controls[value.formControlName].enable();
    }
    const validators = this.isRequired ? [Validators.required] : []
    if (this.maxValue) {
      validators.push(Validators.max(this.maxValue))
      this.form.controls[value.formControlName].setValidators(validators);
    } 
      validators.push(Validators.min(this.minValue))
      this.form.controls[value.formControlName].setValidators(validators);
  
    if (this.isRequired) {
// isRequired
    }
    this.form.controls[value.formControlName].updateValueAndValidity();
  }
  constructor(private _localization: Localization, public dialog: MatDialog) { 
    this.captions = this._localization.captions;
    this.floatLabel = this._localization.setFloatLabel;
  }

  inputChanged(event) {
    this.inputChange.emit(event);
  }

  valueEmit(eve) {
    this.outputValue.emit(this.form.controls[this.controlName].value);
  }

  onclick() {
    if (document.designMode == 'on') {
     this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: this.placeHolder,
          oldErrorMessage: this.errorMessage,
          oldCustomErrorMessage: this.customErrorMessage,
          oldMinErrorValue: this.minError,
          oldMaxErrorValue: this.maxError
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result != undefined) {
          this.placeHolder = result.newplaceholder;
          this.errorMessage = result.newErrorMessage;
          this.customErrorMessage = result.newCustomErrorMessage;
          this.minError = result.newMinErrorValue;
          this.maxError = result.newMaxErrorValue
        }
      });
    }
  }

  selectonfocus(eve){
    let inpTxt = eve.target as HTMLInputElement;
    inpTxt.select(); // OK
  }
}
