import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-header-label',
  templateUrl: './header-label.component.html',
  styleUrls: ['./header-label.component.scss']
})
export class HeaderLabelComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  labelText = '';
  labelClass: any;
  constructor() { }

  ngOnInit(): void {
    this.labelText = this.field.fieldType.SingleData.label;
    this.labelClass = this.field.class;
  }

}
