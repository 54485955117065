import { FilterTypes } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { CustomFilterQuery } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { FieldEdmType } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface/fieldEdmType.enum';
import { QueryParams } from '../api-query-params.interface';
import { QueryConfig, QueryCustomFilterConfig } from './query-config.interface';
import moment from 'moment';
import * as constants from '@shared/constant/GlobalConstants';

export const FormFilterQuery: QueryCustomFilterConfig = ( CustomFilterQuerys: Array<CustomFilterQuery> ): any => {
    try {
        if (CustomFilterQuerys.length > 0) {
            const queryList = [];
            const LoginSiteId = JSON.parse(sessionStorage.getItem('LoggedInSiteId'));
            CustomFilterQuerys.forEach((querys: CustomFilterQuery) => {
                let querystring = '';
                switch (querys.Type) {
                    case FilterTypes.Multiple: {
                        let value = '';
                        if (querys.CustomOdataUrlName) {
                            value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                            if (value && querys.Name) {
                                querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                            } else if (value) {
                                querystring = querys.OdataKey + ' in (' + value + ')';
                            }
                            if ( LoginSiteId === 1 && querys.hasOwnProperty('CheckInterco') && querys.CheckInterco ) {
                                querystring = `((${querystring}`;
                            }
                        } else {
                            if (querys.Name === querys.OdataKey) {
                                value = querys.DefaultValue.join();
                                querystring = querys.OdataKey + ' in (' + value + ')';
                            } else {
                                value = querys.DefaultValue.join();
                                querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                                if ( LoginSiteId === 1 && querys.hasOwnProperty('CheckInterco') && querys.CheckInterco ) {
                                    querystring = `${querystring}) or LocationTypes/Id in (8))`;
                                }
                            }
                        } 
                        break;
                    }
                    case FilterTypes.GreaterThanEqual: {
                        if (querys.FieldType === FieldEdmType.EDMDATEOFFSET) {
                            // const date = new Date();
                            //   Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
                            //   date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                            // const utcdate = moment.utc().format();
                            // const utcdate = date;
                            // querystring = querys.OdataKey + ' ge ' + utcdate;
                            querystring = querys.OdataKey + ' ge ' + moment().startOf('day').format(constants.PostDateTimeFormat);
                        } else {
                            querystring = querys.OdataKey + ' ge ' + querys.DefaultValue;
                        }
                        break;
                    }
                    case FilterTypes.Equal: {
                        if (querys.CustomOdataUrlName) {
                            const value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                            if (value) {
                                querystring = querys.OdataKey + ' eq ' + value;
                            }
                        } else if (querys.ExpandName) {
                            querystring = querys.ExpandName + '/' + querys.OdataKey + ' eq ' + querys.DefaultValue ;
                        } else {
                            querystring = querys.OdataKey + ' eq ' + querys.DefaultValue;
                        }                    
                        break;
                    }
                    case FilterTypes.NotEqual: {
                        // querystring = 'not(' + query.OdataKey + ' eq ' + query.DefaultValue + ')';
                        querystring = querys.OdataKey + ' ne ' + querys.DefaultValue;
                        break;
                    }
                }
                if (querystring) {
                    queryList.push(querystring);
                }
            });
            const returnData = queryList.join(' and ');
            return returnData;
        }
    } catch (error) {
        console.error(error);
    }
};