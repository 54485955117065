import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { TableHeaderOptions, TableOptions, DropdownOptions } from 'src/app/common/Models/ag-models';
import { Days } from "./custom-fee.model";


@Injectable()
export class CustomFeeBusiness {
    captions: any;
    constructor(private localization: Localization) {
      this.captions = this.localization.captions;
    }
    getFeeOptions():DropdownOptions[] {
        return [{
            id: 1,
            value: 1,
            viewValue: "name1"
          },
          {
            id: 2,
            value: 2,
            viewValue: "name2"
          },
          {
            id: 3,
            value: 3,
            viewValue: "name3"
          }]
    }
    GetTableHeader(): TableHeaderOptions[] {
        return [
          {
            key: 'fee',
            displayName: this.captions.lbl_fee,
            sorting: true,
            sortingKey: 'fee',
            searchable: true
          },
          {
            key: 'dateRange',
            displayName: this.captions.lbl_dateRange,
            sorting: true,
            sortingKey: 'startDate',
            searchable: false
          },
          {
            key: 'time',
            displayName: this.captions.tbl_hdr_time,
            sorting: false,
            sortingKey: 'time',
            searchable: false
          },
          {
            key: 'price',
            displayName: this.captions.tbl_hdr_price,
            sorting: true,
            sortingKey: 'sortingPrice',
            searchable: true
          },
          {
            key: ActionTypeEnum.action,
            templateName: ActionTypeEnum.action,
            displayName: this.captions.tbl_hdr_actions,
            searchable: false,
          }
        ]
      }
    
      GetTableOptions(isViewOnly): TableOptions {
        return {
          defaultSortOrder: SorTypeEnum.desc,
          defaultsortingColoumnKey: 'dateRange',
          columnFreeze: {
            firstColumn: true,
            lastColumn: true
          },
          showDeleteMsg: false,
          showTotalRecords: true,
          enablePagination: true,
        //   isHeaderCheckboxAllowed: !isViewOnly,
          checkboxKey: 'checked',
          deleteMsgKey: 'fee',
          actions: [
            {
              type: TableActions.edit,
              disabled: isViewOnly
            },
            {
              type: TableActions.delete,
              disabled: isViewOnly,
            }
          ]
        }
      }

      getWeekList() {
        return [
            {
                id: Days.Monday,
                value: "M",
                viewVal: "Mon",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 1
            },
            {
                id: Days.Tuesday,
                value: "T",
                viewVal: "Tue",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 2
            },
            {
                id: Days.Wednesday,
                value: "W",
                viewVal: "Wed",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 3
            },
            {
                id: Days.Thursday,
                value: "T",
                viewVal: "Thu",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 4
            },
            {
                id: Days.Friday,
                value: "F",
                viewVal: "Fri",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 5
            },
            {
                id: Days.Saturday,
                value: "S",
                viewVal: "Sat",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 6
            },
            {
                id: Days.Sunday,
                value: "S",
                viewVal: "Sun",
                isClicked: false,
                isDisabled: true,
                isSeasonDay: false,
                weekId: 0
            },
        ]
    }
    
}

