import { Injectable } from '@angular/core';
import { ServiceBaseDetails, ServiceGroupsWithServices } from 'src/app/common/shared/shared/cancellation-no-show-policy/cancellation-no-show-policy.model';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { appointmentService } from 'src/app/shared/service/appointment.service';
import { DynamicPricingDataService } from 'src/app/shared/service/dynamicPricing.data.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { API, DaysOfWeek } from '../dynamic-yielding.model';

@Injectable()
export class CreateYieldSetupBusiness {
    captions: any;
    constructor(private _appointmentService: appointmentService, private localization: SpaLocalization,private _Utilities: SpaUtilities,
        private _dynamicPricingDataService: DynamicPricingDataService) {
        this.captions = this.localization.captions;
    }

    async getServiceGroupsWithService() {
        const response = await this._appointmentService.getAllServicesGroupsWithServices(false);
        return response.map(x => this.UIMapper(x));
    }

    UIMapper(data: ServiceGroupsWithServices) {
        return {
            id: data.id,
            value: data.name,
            viewValue: data.name,
            services: data.services.map(x => this.servicesUIMapper(x))
        }
    }

    servicesUIMapper(data: ServiceBaseDetails) {
       return {
            id: data.id,
            value: data.code,
            viewValue: data.name
           }
    }

    getWeekList() {
        return [
            {
                id: DaysOfWeek.Monday,
                value: "M",
                viewVal: "Mon",
                isClicked: false,
                isDisabled: false,
                isAvailable: false,
                weekId: 1
            },
            {
                id: DaysOfWeek.Tuesday,
                value: "T",
                viewVal: "Tue",
                isClicked: false,
                isDisabled: false,
                isAvailable: false,
                weekId: 2
            },
            {
                id: DaysOfWeek.Wednesday,
                value: "W",
                viewVal: "Wed",
                isClicked: false,
                isDisabled: false,
                isAvailable: false,
                weekId: 3
            },
            {
                id: DaysOfWeek.Thursday,
                value: "T",
                viewVal: "Thu",
                isClicked: false,
                isDisabled: false,
                isAvailable: false,
                weekId: 4
            },
            {
                id: DaysOfWeek.Friday,
                value: "F",
                viewVal: "Fri",
                isClicked: false,
                isDisabled: false,
                isAvailable: false,
                weekId: 5
            },
            {
                id: DaysOfWeek.Saturday,
                value: "S",
                viewVal: "Sat",
                isClicked: false,
                isDisabled: false,
                isAvailable: false,
                weekId: 6
            },
            {
                id: DaysOfWeek.Sunday,
                value: "S",
                viewVal: "Sun",
                isClicked: false,
                isDisabled: false,
                isAvailable: false,
                weekId: 0
            },
        ]
    }
    getConditionValues() {
        return [
            {
                id: 1,
                value: 1,
                viewValue: this.captions.lbl_greaterThanEqualTo,
                isDisabled: false
            },
            {
                id: 2,
                value: 2,
                viewValue: this.captions.lbl_lesserThanEqualTo,
                isDisabled: false
            },
        ]
    }

      async save(data: API.DynamicPricingRule[],successCallback?, errorCallback?) {
       const response = await this._dynamicPricingDataService.CreateDynamicPricing(data,successCallback, errorCallback);
       return response;
      }

      async update(data: API.DynamicPricingRule[],successCallback?, errorCallback?) {
        const response = await this._dynamicPricingDataService.UpdateDynamicPricing(data,successCallback, errorCallback);
        return response;
       }

}