import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button/public-api';
import { ApprovalStatusPopModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-approval-status-popup',
  templateUrl: './agilysys-approval-status-popup.component.html',
  styleUrls: ['./agilysys-approval-status-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysApprovalStatusPopupComponent implements OnInit {
  remarksFormControl = new FormControl();
  approvalStatusButton: AgilysysButtonModel[];

  constructor(public dialogRef: MatDialogRef<AgilysysApprovalStatusPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ApprovalStatusPopModel) { }

  ngOnInit(): void {
    this.approvalStatusButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          const callbackData = {
            ButtonKey: callBackData.buttonValue.buttonKey,
            remarkValue: this.remarksFormControl.value
          };
          this.dialogRef.close(callbackData);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          const callbackData = {
            ButtonKey: callBackData.buttonValue.buttonKey,
            remarkValue: this.remarksFormControl.value
          };
          this.dialogRef.close(callbackData);
        }
      }
    ];
  }

}
