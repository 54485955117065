import { Directive, Input, HostListener, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[spaAppDisableDoubleClick]'
})
export class DisableDoubleClickDirective implements OnInit, OnDestroy {

  throttleTime = 5;   
  @Input('spaAppDisableDoubleClick')
  set setThrottleTime(value: number) {
    this.throttleTime = value ? value : this.throttleTime;
  }

  @Output() throttleClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

    ngOnInit() {
    this.subscription = this.clicks.pipe(
      throttleTime(this.throttleTime) 
    ).subscribe(e =>
      this.throttleClick.emit(e)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event']) clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
