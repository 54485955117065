import { Component, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ReportDataService } from '../../../data/report-data.services';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ValidationMessage } from '../../../basereport/base-report.component';
import { DropDownData } from '../../../business/report.modals';

@Component({
  selector: 'app-inventory-value-and-activity',
  templateUrl: './inventory-value-and-activity.component.html',
  styleUrls: ['./inventory-value-and-activity.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class InventoryValueandActivityComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  InventoryValueandActivityFormGrp: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization,
    public business: ReportBusinessService, private dataService: ReportDataService) { }

  ngOnInit(): void {
    this.InventoryValueandActivityFormGrp = this.fb.group({
      category: new UntypedFormGroup({})
    });
    this.formReady.emit(this.InventoryValueandActivityFormGrp);
  }

  formInitialized(form: UntypedFormGroup) {
    this.InventoryValueandActivityFormGrp.setControl('category', form);
  }
  
  IsSelectionValid(validationObject: ValidationMessage) {
    this.IsValidSelection.emit({ dropDownName: validationObject.dropDownName, validity: validationObject.validity });
  }

}