import { Component, OnInit,Input,Output,EventEmitter,ViewEncapsulation } from '@angular/core';
import { TableHeaderOptions, TableSearchHeader } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { AsideDateFilter, AsideFilterConfig } from 'src/app/common/Models/ag-models';
import { AgToggleConfig, ButtonValue, FilterGroup } from 'src/app/common/Models/ag-models';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { Location } from '@angular/common';
import { ActionMode, AlertType } from 'src/app/common/shared/shared/enums/enums';
import { AlertAction, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UI, API } from 'src/app/common/Models/staff-reader-board-model';
import { StaffReaderBoardBusiness } from './staff-reader-board.business';
import { StaffReaderBoardDataService } from '../dataservices/staff-reader-board-data.service';
import { UserRoleDataService } from '../dataservices/user-roles-data.service';
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { SnackBarType } from '../shared/shared/enums/enums';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-staff-reader-board',
  templateUrl: './staff-reader-board.component.html',
  styleUrls: ['./staff-reader-board.component.scss'],
  encapsulation:ViewEncapsulation.None,
  providers:[StaffReaderBoardBusiness, StaffReaderBoardDataService, UserRoleDataService]
})
export class StaffReaderBoardComponent implements OnInit {

  searchHeaderOption: TableSearchHeader;
  notes: UI.ViewAllStaffReaderBoard[] = [];
  filteredNotes: UI.ViewAllStaffReaderBoard[] = [];
  captions: any;
  dateConfig: AsideDateFilter;
  isSlideOpen: boolean = false;
  filterData: any;
  showNoteAckToggleInput: AgToggleConfig;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  filterConfig: AsideFilterConfig;
  selectedNote: any;
  createModalData: createModal;
  isViewOnly : boolean = false;
  header: any;
  showSlide :boolean = false;
  creationToggler: boolean = false;
  priorityList: any;
  modeOptions: any;
  allUserData = [];
  allRolesData = [];
  staffreaderForm: UntypedFormGroup
  propertyDate: Date;
  totalnotes: number;
  selectedid: any = 0;
  noteId: number = 0;
  @Input() automationId : string = '';
  constructor(private localization:Localization,private location: Location,private fb:UntypedFormBuilder, private staffReaderBoardBusiness: StaffReaderBoardBusiness,
    private PropertyInfo: PropertyInformation, private utilities: CommonUtilities) { 
    this.propertyDate = this.PropertyInfo.CurrentDTTM;
    this.captions = this.localization.captions;
    this.header = this.captions.hdr_view_note;
    this.priorityList = this.staffReaderBoardBusiness.getPriorityList();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.validateBreakPoints();
    this.initializeButtons();
    this.initializesearchHeaderOption();
    this.GetAllStaffReaderBoardList();
  }

  initializeForm(){
    this.staffreaderForm = this.fb.group({
      priority: 0,
      showNoteAck: false,
      startDate: this.propertyDate,
      endDate: this.propertyDate
    })
  }

  initializeButtons() {
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_apply,
      disabledproperty: false
    },
      this.cancelButton = {
        type: 'tertiary',
        label: this.captions.btn_cancel,
        disabledproperty: false
      };

    this.filterConfig = {
      filterText: this.captions.lbl_filter,
      isCloseRequired: true,
      displayCount: 4,
      isMultiSelect: true,
      viewMoreConfig: {
        apply: this.captions.btn_apply,
        cancel: this.captions.btn_cancel,
        searchByPlaceHolder: this.captions.lbl_searchBy
      },
    };

    this.showNoteAckToggleInput = {
      group: this.staffreaderForm,
      formControlName: 'showNoteAck',
      label: '',
      automationId: this.automationId ? this.automationId : ''
    };
  }

  private async validateBreakPoints()
  {
    await this.staffReaderBoardBusiness.ValidateBreakPoints();
    this.disableControls();
  }

  private disableControls() {
    this.isViewOnly = this.staffReaderBoardBusiness.isViewOnly;

    this.searchHeaderOption.createBtnDisabled = this.isViewOnly;
    this.searchHeaderOption = {...this.searchHeaderOption};
  }

  initializesearchHeaderOption() {
    this.searchHeaderOption = {
      createBtnLabel: this.captions.btn_create,
      searchPlaceHolder: this.captions.lbl_SearchByDescription,
      showInactive: false,
      showPrint: false,
      hideAdvanceSearch: false,
      showFilter : true,
      showTextCount : true,
      textLabel : this.captions.totalnotes,
      advancedSearch: this.captions.lnk_advancedSearch,
      automationId : this.automationId ? this.automationId : ''
    };

  }

  async GetAllStaffReaderBoardList() {
    this.notes = await this.staffReaderBoardBusiness.GetAllStaffReaderBoardList();

    this.notes.forEach(x=> {
      x.priorityName = this.priorityList.filter(y=> y.id == x.priority)[0].viewValue;
    });

    this.filteredNotes = this.notes;

    this.totalnotes = this.filteredNotes.length;
  }

  dateChanged(e) {
    this.staffreaderForm.get('startDate').setValue(e.value.startDate);
    this.staffreaderForm.get('endDate').setValue(e.value.endDate);
    this.setDateConfig();
  }

  CategoryFilter(e){

  }

  collapsePanel(e){
    this.isSlideOpen = false;
  }

  openFilter(e) {
    this.isSlideOpen = !this.isSlideOpen;

    if (this.isSlideOpen) {
      this.setDateConfig();
    }
  }

  setDateConfig()
  {
    this.dateConfig = {
      name: 'Date Filter',
      isDateRange: true,
      filters: [{
        name: 'startDate',
        placeholder: this.captions.lbl_startDate,
        defaultValue: this.staffreaderForm.value.startDate,
        maxDate: this.staffreaderForm.value.endDate
      },
      {
        name: 'endDate',
        placeholder: this.captions.lbl_endDate,
        defaultValue: this.staffreaderForm.value.endDate,
        minDate: this.staffreaderForm.value.startDate
      }
      ]
    }
  }

  openSlide(note){
    this.selectedNote = {...note};
    this.showSlide = true;
  }

  closeSlide(){
    this.showSlide = false;
  }

  createEvent(e){
    this.creationToggler = true;
    this.noteId = 0;
    this.createModalData = {
      title: this.captions.pg_title_createnote
    };
  }

  onBack(e?) {
    this.creationToggler = false;
  }
  handleClick(eve) {
    switch (eve.mode) {
      case ActionMode.create:
       this.onSave(eve.form);
       this.onBack()
       break;
      case ActionMode.update:
       this.onBack()
       this.utilities.showToastMessage(this.captions.lbl_note_sent_successfully, SnackBarType.Success,3000)
       break;
      case ActionMode.cancel:
        this.onCancelAction();
        break;
    }
  }

  onCancelAction(){
    this.onBack()
  }

  searchChange(eve){
    var enteredSearchId = eve;
    var filtered = this.notes.filter(function (str) { return str.notes.toUpperCase().includes(enteredSearchId.toUpperCase()) || str.priorityName.toUpperCase().includes(enteredSearchId.toUpperCase()) });
    this.filteredNotes = filtered;
    this.totalnotes = this.filteredNotes.length;
  }

  async onSave(formdata: any) {
    let data = formdata.value;

    if (this.noteId == 0) {
      await this.staffReaderBoardBusiness.CreateStaffReaderBoard(data).then(x => {
        this.utilities.showToastMessage(this.captions.lbl_note_sent_successfully, SnackBarType.Success, 3000);
        this.GetAllStaffReaderBoardList();
      });
    }
    else
    {
      await this.staffReaderBoardBusiness.UpdateStaffReaderBoard(data).then(x => {
        this.utilities.showToastMessage(this.captions.lbl_note_sent_successfully, SnackBarType.Success, 3000);
        this.GetAllStaffReaderBoardList();
      });
    }
  }

  async onFilter(eve) {
    let staffReaderBoardFilterRequest: API.StaffReaderBoardFilterRequest = {
      fromDate: this.localization.convertDateTimeToAPIDateTime(this.staffreaderForm.value.startDate),
      toDate: this.localization.convertDateTimeToAPIDateTime(this.staffreaderForm.value.endDate),
      priority: this.staffreaderForm.value.priority,
      isNotAcknowledged: this.staffreaderForm.value.showNoteAck
    } as API.StaffReaderBoardFilterRequest;

    this.notes = await this.staffReaderBoardBusiness.FilterAllStaffReaderBoard(staffReaderBoardFilterRequest);

    this.notes.forEach(x=> {
      x.priorityName = this.priorityList.filter(y=> y.id == x.priority)[0].viewValue;
    });

    this.filteredNotes = this.notes;

    this.totalnotes = this.filteredNotes.length;
  }

  selectedPriority(id){
    this.staffreaderForm.get('priority').setValue(id);
    this.selectedid = id;
  }

  onCancel(e)
  {
    this.isSlideOpen =!this.isSlideOpen;
  }

  funtionCheck(id){
    if(this.selectedid == id){
      return 'priority_'+id
    }
  }

  editEvent(id){
    this.creationToggler = true;
    this.noteId = id;
    this.createModalData = {
      title: this.captions.pg_title_editnote
    };
  }

  deleteNote(id) {
    this.utilities.showCommonAlert(this.captions.lbl_note_delete_warning, AlertType.Confirmation, ButtonTypes.YesNo, async (res) => {
      if (res === AlertAction.YES) {
        this.staffReaderBoardBusiness.DeleteStaffReaderBoard(id).then(x => {
          this.utilities.showToastMessage(this.captions.lbl_note_deleted_successfully, SnackBarType.Success, 3000);
          this.GetAllStaffReaderBoardList();
        });
      }
    });
  }
}
