import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { Localization } from 'src/app/common/localization/localization';
import { ButtonValue, AgFieldConfig, AgToggleConfig, DropdownOptions, AgInputFieldConfig, AgDropdownConfig, AgDateConfig } from 'src/app/common/Models/ag-models';
import { CacheService } from 'src/app/common/services/cache-service';
import { CommonUtilities } from '../utilities/common-utilities';
import { takeUntil } from 'rxjs/operators';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { AlertAction, ButtonType } from 'src/app/common/enums/shared-enums';
import { SftpSetupBusiness } from './sftp-setup.business';

@Component({
  selector: 'app-sftp-setup',
  templateUrl: './sftp-setup.component.html',
  styleUrls: ['./sftp-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SftpSetupBusiness]
})
export class SftpSetupComponent implements OnInit {
  isViewOnly: boolean;
  isHidden: boolean = false;
  isReadonly: boolean = false;
  form: UntypedFormGroup;
  captions: any;
  actionButton: ButtonValue;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  floatLabel: string;
  hostNameTextInputConfig: AgInputFieldConfig;
  userNameTextInputConfig: AgInputFieldConfig;
  passwordTextInputConfig: AgInputFieldConfig;
  remotePathTextInputConfig: AgInputFieldConfig;
  portTextInputConfig: AgInputFieldConfig;
  isCopy = false;

  constructor(private fb: UntypedFormBuilder, private utilities: CommonUtilities, private _sftpBusiness: SftpSetupBusiness,
    private localization: Localization, private cacheService: CacheService) {
    this.captions = this.localization.captions.common;
  }

  ngOnInit(): void {
    this.validateUserAccess();
    this.createSftpForm();
    this.pageInitialization();
  }

  ngAfterViewInit() {
  }

  async validateUserAccess() {
    // const userAccess = await this._FacadeService.getUserAccess(UserAccessBreakPoints.SFTP, true);
    // this.isViewOnly = userAccess?.isViewOnly;
    // if (this.isViewOnly) {
    //   this.actionButton.disabledproperty = true;
    //   this.actionButton = { ...this.actionButton };
    // }
  }

  createSftpForm() {
    this.form = this.fb.group({
      id: 0,
      isEnabled: false,
      hostName: '',
      userName: '',
      password: '',
      remotePath: '',
      port: '0',
    });
    this.form.statusChanges.subscribe(x => {
      this.actionButton.disabledproperty = !(this.form.dirty);
      this.actionButton = { ...this.actionButton }
    })
  }


  async onAction(e) {
    this.actionButton.disabledproperty = true;
    this.actionButton = { ...this.actionButton };
    const formValues = this.form.value;
    if (formValues) {

      if (formValues.isEnabled) {

        if (formValues.hostName == "" || formValues.hostName == undefined) {
          this.utilities.showAlert("Host Name is Mandatory!!!", AlertType.Error, ButtonType.Ok);
          return false;
        }
        if (formValues.userName == "" || formValues.userName == undefined) {
          this.utilities.showAlert("User Name is Mandatory!!!", AlertType.Error, ButtonType.Ok);
          return false;
        }

        if (formValues.password == "" || formValues.password == undefined) {
          this.utilities.showAlert("Password is Mandatory!!!", AlertType.Error, ButtonType.Ok);
          return false;
        }

        if (formValues.port == "" || formValues.port == undefined) {
          this.utilities.showAlert("Port is Mandatory!!!", AlertType.Error, ButtonType.Ok);
          return false;
        }

        const checksftpConnResponse = await this._sftpBusiness.checkSFTPConnection(formValues);
        if (!checksftpConnResponse) {
          this.utilities.showAlert("SFTP Connection is Invalid!!!", AlertType.Error, ButtonType.Ok);
          return false;
        }


      }

      let apiResponse: any;
      if (formValues.id > 0)
        apiResponse = await this._sftpBusiness.updateSftpConfig(formValues);
      else
        apiResponse = await this._sftpBusiness.createSftpConfig(formValues);
      if (apiResponse) {
        this.utilities.showAlert(this.captions.successfullysaved, AlertType.WellDone, ButtonType.Ok, (res) => {
          if (res === AlertAction.CONTINUE) {
            this.pageInitialization();
            this.actionButton.disabledproperty = true;
            this.actionButton = { ...this.actionButton };
            this.form.markAsPristine();
          }
        });
      } else {
        this.utilities.showAlert("Error Occur", AlertType.Error, ButtonType.Ok);
      }


    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  async pageInitialization() {

    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.hostNameTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'hostName',
      placeHolderId: 'Sftplbl_HostName',
      placeHolder: this.captions.Sftplbl_HostName,
      showRequired: false,
      errorMessageId: 'Sftp_missing_HostName',
      errorMessage: this.captions.Sftp_missing_HostName,
      changeLabelOnEdit: true,
      readonly: false,
      //inputType:this._SettingsSharedService.codeInputType
      automationId: "sftpSetup"
    };
    this.userNameTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'userName',
      placeHolderId: 'Sftplbl_userName',
      placeHolder: this.captions.Sftplbl_userName,
      showRequired: false,
      errorMessageId: 'Sftperr_missing_UserName',
      errorMessage: this.captions.Sftperr_missing_UserName,
      readonly: false,
      //inputType:this._SettingsSharedService.nameInputType
      automationId: "sftpSetup"
    };

    this.passwordTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'password',
      placeHolderId: 'Sftplbl_Password',
      placeHolder: this.captions.Sftplbl_Password,
      showRequired: false,
      errorMessageId: 'Sftperr_missing_Password',
      errorMessage: this.captions.Sftperr_missing_Password,
      readonly: false,
      //inputType:this._SettingsSharedService.nameInputType
      automationId: "sftpSetup"
    };

    this.remotePathTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'remotePath',
      placeHolderId: 'Sftplbl_remotePath',
      placeHolder: this.captions.Sftplbl_remotePath,
      showRequired: false,
      errorMessageId: 'Sftperr_missing_remotePath',
      errorMessage: this.captions.Sftperr_missing_remotePath,
      readonly: false,
      //inputType:this._SettingsSharedService.nameInputType
      automationId: "sftpSetup"
    };

    this.portTextInputConfig = {
      className: 'ag_form-control--lg',
      form: this.form,
      formControlName: 'port',
      placeHolderId: 'Sftplbl_Port',
      placeHolder: this.captions.Sftplbl_Port,
      showRequired: false,
      errorMessageId: 'Sftperr_missing_Port',
      errorMessage: this.captions.Sftperr_missing_Port,
      readonly: false,
      //inputType:this._SettingsSharedService.nameInputType
      automationId: "sftpSetup"
    };

    var apiResponse = await this._sftpBusiness.getSftpConfig();


    // if (apiResponse) {

    //   if (!apiResponse.isEnabled) {
    //     this.FeildsReadOnly(true, apiResponse.id);
    //   } else {
    //     this.FeildsReadOnly(false, apiResponse.id);
    //   }

    //   this.form.patchValue({
    //     isEnabled: apiResponse.isEnabled,
    //     hostName: apiResponse.hostName,
    //     userName: apiResponse.userName,
    //     password: apiResponse.password,
    //     remotePath: apiResponse.remotePath,
    //     port: apiResponse.port,
    //     id: apiResponse.id
    //   });
    // }


    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.actionButton.disabledproperty = !(this.form.valid && this.form.dirty);
      this.actionButton = { ...this.actionButton };
    });


  }

  onChangeConfig(event) {
    const Id = this.form?.value?.id;
    if (event.checked) {
      this.FeildsReadOnly(false, Id);
    }
    else {
      this.FeildsReadOnly(true, Id);
    }
  }


  onCancel(e) {
    this.pageInitialization();
    this.actionButton.disabledproperty = true;
    this.actionButton = { ...this.actionButton };
    this.form.markAsPristine();
  }

  FeildsReadOnly(result: boolean, SftpId: number) {
    this.hostNameTextInputConfig.readonly = result;
    this.userNameTextInputConfig.readonly = result;
    this.passwordTextInputConfig.readonly = result;
    this.remotePathTextInputConfig.readonly = result;
    this.portTextInputConfig.readonly = result;

    if (result) {
      this.form.patchValue({
        isEnabled: false,
        hostName: '',
        userName: '',
        password: '',
        remotePath: '',
        port: '0',
        id: SftpId
      });
    }

  }
}
