import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { KeyBoardGrouping } from "../../Models/ag-models";
@Component({
  selector: "app-ag-shortcut-key-guide",
  templateUrl: "./ag-shortcut-key-guide.component.html",
  styleUrls: ["./ag-shortcut-key-guide.component.scss"],
})
export class AgShortcutKeyGuideComponent implements OnInit {
  guideData: KeyBoardGrouping[];
  constructor(
    public dialogRef: MatDialogRef<AgShortcutKeyGuideComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { guideJson: KeyBoardGrouping[] }
  ) {}

  ngOnInit(): void {
    this.guideData = this.data.guideJson;
  }
  close() {
    this.dialogRef.close();
  }
}
