import { CodeSetups } from './../../../shared/enums/shared-enums';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ButtonValue, HeaderInput, AgToggleConfig, InputActionPlaceHolder } from '../../models/ag-models';
import { ReplaySubject } from 'rxjs';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { takeUntil } from 'rxjs/operators';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { SettingsSharedService } from 'src/app/settings/settings-shared.service';
import { AlertAction, AlertType, ButtonType } from 'src/app/shared/globalsContant';
@Component({
  selector: 'app-spa-input-header-action',
  templateUrl: './input-header-action.component.html',
  styleUrls: ['./input-header-action.component.scss'],
  providers: [SettingsSharedService],
  encapsulation: ViewEncapsulation.None
})
export class InputHeaderActionComponent implements OnInit, OnDestroy {
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  ActionForm: UntypedFormGroup;
  captions: any;
  isUpdate: boolean;
  updateCaption: any;
  toggleInput: AgToggleConfig;
  code: string;
  name: string;
  active: string;
  errMessageCode: string;
  errMessageName: string;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() formReady = new EventEmitter<UntypedFormGroup>();

  @Input() validationType: string;
  @Input() inputPlaceholder: InputActionPlaceHolder;
  @Input() minValue: number;
  @Input() maxValue = 50;
  @Input() inputFor: CodeSetups;
  @Input() cancelWarningMessage: string;
  @Input('clearInput')
  set clearFormInputs(value) {
    if (value) {
      this.clearForm();
    }
  }
  nameMinLength: number;
  codeMinLength: number;
  codeMaxLength: number;
  nameMaxLength: number;
  showRequired: boolean;
  @Input('disabled')
  set setDisabled(value: boolean) {
    if (value) {
      this.ActionForm.controls.code.disable();
      this.ActionForm.controls.name.disable();
      this.cancelButton.disabledproperty = value;
    } else {
      this.ActionForm.controls.code.enable();
      this.ActionForm.controls.name.enable();
      this.cancelButton.disabledproperty = value;
    }
  }


  @Input('editActionObj')
  set ActionObject(value: HeaderInput) {
    this.isUpdate = false;
    if (value) {
      this.isUpdate = value.toggleShow;
      this.saveButton.label = this.captions.btn_update;
      this.ActionForm.patchValue(value);
    }
    if (this.isUpdate) {
      this.toggleInput = {
        group: this.ActionForm,
        horizontal: false,
        label: this.captions.tog_lbl_active,
        formControlName: 'isActive',
        disabled: false
      };
    }
  }


  @Input('inputPlaceholder')
  set setPlaceHolder(value: InputActionPlaceHolder) {
    this.code = value.code;
    this.name = value.name;
    this.errMessageCode = value.errorMessageCode;
    this.errMessageName = value.errorMessageName;
  }

  constructor(private formBuilder: UntypedFormBuilder, private _Localization: SpaLocalization, private _utilities: SpaUtilities, private _SettingsSharedService: SettingsSharedService) {
    this.captions = this._Localization.captions;

    this.ActionForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.maxLength(this.codeMaxLength), Validators.minLength(this.codeMinLength)]],
      name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength), Validators.minLength(this.nameMinLength)]],
      isActive: true,
      id: 0,
      listOrder: 0
    });

    this.toggleInput = {
      group: this.ActionForm,
      horizontal: false,
      label: this.captions.tog_lbl_active,
      formControlName: 'isActive',
      disabled: false
    };

    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_add,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }

  ngOnInit() {
    this.ActionForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.saveButton.disabledproperty = !(this.ActionForm.dirty && this.ActionForm.valid);
    });
    this.getValidators();
    this.formReady.emit(this.ActionForm);
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  getValidators() {
    const lengthValidators = this._SettingsSharedService.getSettingsSetupValidators(this.inputFor);
    this.codeMinLength = lengthValidators.codeMinLength;
    this.codeMaxLength = lengthValidators.codeMaxLength;
    this.nameMinLength = lengthValidators.nameMinLength;
    this.nameMaxLength = lengthValidators.nameMaxLength;
    this.showRequired = this._SettingsSharedService.showRequired;
  }

  onSave(_event) {
    this.saveButton.disabledproperty = true;
    this.save.emit(this.ActionForm.value);
  }

  onCancel(_arg) {

    if (this.ActionForm.dirty) {
      const _cancelWarningMessage = this.cancelWarningMessage ? this.cancelWarningMessage : this.captions.lbl_unsavedData;
      this._utilities.showAlert(_cancelWarningMessage, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.leaveChanges();
        }
      });
    } else {
      this.leaveChanges();
    }


  }

  leaveChanges() {
    this.clearForm();
    this.cancel.emit(this.ActionForm.value);
  }

  private clearForm() {
    this.isUpdate = false;
    this.saveButton.label = this.captions.btn_add;
    this.ActionForm.reset({
      isActive: true,
      id: 0,
      listOrder: 0
    });
  }

}
