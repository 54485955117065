import { TranslateService } from '@ngx-translate/core';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { HeaderConfiguration, TilesConfiguration, TilesSummaryData, TransactionConfiguration } from '../transaction.interface';
import { ViewTransactionConfiguration } from './view-configuration.interface';

export const ViewTilesHeaderConfiguration: ViewTransactionConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity?: any): TilesConfiguration => {
    try {
        if (ViewHeaderEntity && ViewDetailsEntity && TransactionCollection &&
            TransactionCollection.ViewTranscationConfig.hasOwnProperty('TilesConfiguration')
        ) {
            const Id = TransactionCollection.ViewTranscationConfig.TilesConfiguration.Id;
            const routingUrl = TransactionCollection.ViewTranscationConfig.TilesConfiguration.RoutingUrl;
            const OdataKey = TransactionCollection.ViewTranscationConfig.TilesConfiguration.OdataKey;
            let tilesConfiguration = {} as TilesConfiguration;
            if (Id > 0) {
                if( TransactionCollection?.ViewTranscationConfig?.TilesConfiguration?.EnabledStaticData ) {
                    tilesConfiguration = {...TransactionCollection?.ViewTranscationConfig?.TilesConfiguration}
                }
                const endPointUrl = `/inventory/api/TransactionHeader/${Id}`;
                httpService.GethttpMethod(endPointUrl).subscribe(x => {
                    const responseData = JSON.parse(x['value']?.toString());
                    if (responseData) {
                        TransactionCollection.ViewTranscationConfig.TilesConfiguration.TypeId = responseData['TypeId'];
                        tilesConfiguration.TypeId = responseData['TypeId'];
                        tilesConfiguration.Id = Id;
                        tilesConfiguration.RoutingUrl = routingUrl;
                        tilesConfiguration.OdataKey = OdataKey;
                        tilesConfiguration.TilesSummaryData = TileDetails(responseData, ViewHeaderEntity, translateService);
                        TransactionCollection.ViewTranscationConfig.TilesConfiguration.TilesSummaryData =
                        tilesConfiguration.TilesSummaryData;
                        // tilesConfiguration = tilesConfiguration;
                    }
                });
            } else {
                tilesConfiguration = TransactionCollection.ViewTranscationConfig.TilesConfiguration;
            }
            return tilesConfiguration;

        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function TileDetails(responseData: object, viewHeaderEntity: any, translateService?: TranslateService): TilesSummaryData[] {
    const SummaryData = [];
    for (const key in responseData['TileDetails']) {
        if (key) {
            const entityName = viewHeaderEntity[0]['$']['Name'].toString();
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            const TilesSummary = {} as TilesSummaryData;
            TilesSummary.Id = 1;
            TilesSummary.OdataKey = key;
            TilesSummary.IconName = key;
            TilesSummary.DisplayName = ProductDataRemap.FieldTranslateRemapEngine(
            productremapJson, entityName, key, translateService);
            TilesSummary.Value = responseData['TileDetails'][key];
            SummaryData.push(TilesSummary);
        }
    }
    return SummaryData;
}

function dynamicGridDataHeight(): void {
    try {
        const getHeaderContainer = document.getElementsByClassName('trascation-header-container')[0];
        const getTileContainer = document.getElementsByClassName('transaction-tiles')[0];
        if ( getHeaderContainer && getHeaderContainer['length'] > 0  && getTileContainer && getTileContainer['length'] > 0 ) {
             const headerHeight = getHeaderContainer['offsetHeight'];
             const tileHeight = getTileContainer['offsetHeight'];
             if ( headerHeight.length > 0 && tileHeight.length > 0 ) {
                 console.log(headerHeight, tileHeight);
             } 
        }
    } catch (error) {
        console.error(error);
    }
}