import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { GuaranteeMethod, GuaranteeMethodDetail } from "src/app/retail/shared/business/shared.modals";
import { AppointmentBillingDetail } from "src/app/shared/business/shared.modals";
import { AlertType, Host } from "src/app/shared/globalsContant";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import { SpaUtilities } from "src/app/shared/utilities/spa-utilities";

@Injectable()
export class GuaranteeMethodBusiness {
    constructor(private _http: HttpServiceCall
        , private _utils: SpaUtilities
        , private _localization: Localization) { }

    async SearchGuestRoom(searchParams): Promise<any> {
        try {
            return await this._http.CallApiAsync<any>({
                callDesc: "SearchInHouseGuests",
                host: Host.spaManagement,
                method: HttpMethod.Put,
                body: searchParams,
                showError: false
            });
        } catch (e) {
            this._utils.exceptionHandle(e);
        }
    }

    IsGuaranteeMethodAvailable(guaranteeMethod: GuaranteeMethodDetail, isMandatory: boolean): boolean {
        let isValidForSave = !isMandatory;
        if (isMandatory && guaranteeMethod) {
            isValidForSave = guaranteeMethod.GuaranteeMethod > 0 &&
                (guaranteeMethod.ARAccountDetail || guaranteeMethod.CardDetail?.PaymentReferenceId > 0 || guaranteeMethod.RoomDetail) ? true : false;
        }
        return isValidForSave;
    }

    async UpdateCardInfoToClientProfile(clientCardInfo: ClientCardInfo[]) {
        await this._http.CallApiAsync<any>({
            callDesc: "UpdateCardDetailToClientProfile",
            host: Host.spaManagement,
            method: HttpMethod.Put,
            body: clientCardInfo,
            showError: false
        });
    }

    IsCardAvailableForClientProfileUpdate(guaranteeMethod: GuaranteeMethodJSONModel) {
        let isAvailableForUpdate = false;
        if (guaranteeMethod && guaranteeMethod.updateCardDetailsToClientProfile &&
            guaranteeMethod.guaranteePaymentMethod && guaranteeMethod.guaranteePaymentMethod.CardDetail &&
            guaranteeMethod.guaranteePaymentMethod.CardDetail.PaymentReferenceId > 0) {
            isAvailableForUpdate = true;
        }
        return isAvailableForUpdate;
    }

    GetAPIStringJSON(guaranteeMethod: GuaranteeMethodJSONModel): string {
        let stringJson = '';
        if (guaranteeMethod && guaranteeMethod.guaranteePaymentMethod) {
            let apiModel: GuaranteeMethodAPIModel = {
                guaranteePaymentMethod: guaranteeMethod.guaranteePaymentMethod,
                updateCardDetailsToClientProfile: guaranteeMethod.updateCardDetailsToClientProfile
            };
            stringJson = JSON.stringify(apiModel);
        }
        return stringJson;
    }

    ParseToAPIModel(billingDetail: string): GuaranteeMethodAPIModel {
        let guaranteeMethod: GuaranteeMethodAPIModel;
        if (billingDetail && billingDetail.length > 0) {
            guaranteeMethod = JSON.parse(billingDetail);
        }
        return guaranteeMethod;
    }

    ValidateStayDates(appointmentDate: Date, selectedGuaranteeMethod: GuaranteeMethodDetail) {
        let isDateValid = true;
        const selectedStay = selectedGuaranteeMethod?.RoomDetail;
        if (selectedGuaranteeMethod && selectedGuaranteeMethod?.GuaranteeMethod == GuaranteeMethod.RoomCharge
            && appointmentDate && selectedStay?.stayStartDate && selectedStay?.stayEndDate) {
            isDateValid = (appointmentDate >= selectedStay.stayStartDate && appointmentDate <= selectedStay.stayEndDate);
        }
        if (!isDateValid) {
            this._utils.showAlert(this._localization.captions.bookAppointment.GuaranteeMethod.RoomChargeNotApplicableMsg, AlertType.Info);
        }
        return isDateValid;
    }

}

export enum GuestSearchCategory {
    All = 1,
    ConfirmationNumber,
    GuestName
}

export interface GuaranteeMethodAPIModel {
    guaranteePaymentMethod: GuaranteeMethodDetail;
    updateCardDetailsToClientProfile: boolean;
}
export interface GuaranteeMethodJSONModel extends GuaranteeMethodAPIModel {
    id: number;
    isFormValid: boolean;
    isFormDirty: boolean;
}

export interface GuestSearchFilter {
    searchKey: string;
    activityStartDate: string;
    activityEndDate: string;
    guestSearchCategory: GuestSearchCategory;
    product: string;
}

export interface GuaranteeMethodConfig {
    billingDetail?: AppointmentBillingDetail
    isMandatoryOnAppointment: boolean;
    bookingStartDate: Date | string;
    bookingEndDate: Date | string;
}

export interface ClientCardInfo {
    clientId: number;
    tokenTransId: number;
}