import { Injectable } from '@angular/core';
import { AuthByTokenRequest, AuthByTokenResponse, AuthRequest, AuthResponse, GatewayConfigurationType, PaymentMethods, SaleByTokenRequest, SaleByTokenResponse, SaleRequest, SaleResponse, ValidateAuthRequest, ValidatePayRequest } from 'src/app/common/shared/shared/business/shared.modals';
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { CardInfo, CMSHandleRequest,  
    CreateTokenRequest, HandleRequest, HandleResponse, PaymentBaseResponse, PaymentErrorCodes, StoreTokenRequest, TokentransactionInfo } from 'src/app/common/shared/shared/business/shared.modals';
// import { PaymentMethods } from 'src/app/retail/shared/business/shared.modals';
import { CommonApiRoutes } from '../common-route';
import { Localization } from '../localization/localization';
import { CreditCardConfiguration, FolioUserSessionConfiguration, SettingModule, SettingScreen } from '../Models/payment.model';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { PayAgentCommunication } from './payagent.communication';
import { RetailCommunication } from '../communication/services/retailcommunication.service';
import { CommonPaymentDataService } from './payment-data.service';

@Injectable()
export class CommonPaymentBusinessService {
    private readonly payAgentURI: string = 'PAYAGENTURI';
    NonPayAgentMethods: PaymentMethods[] = [
        PaymentMethods.Cash,
        PaymentMethods.RainCheck,
        PaymentMethods.ARPost,
        PaymentMethods.ARAcctPost,
        PaymentMethods.RedeemPoint,
        PaymentMethods.CreditBook,
        PaymentMethods.OfferRedemption
    ];
    V1GiftCardMethods: PaymentMethods[] = [
        PaymentMethods.V1GiftCard,
        PaymentMethods.V1GiftCardIdTech
    ];
    constructor(
        private _localization: Localization,
        private utils: CommonUtilities,
        private _payAgentHttp: PayAgentCommunication,
        private _paymentDataService: CommonPaymentDataService,
        private _retailhttp: RetailCommunication,
        private propertyInfo: CommonPropertyInformation,
        private propertySettingService: PropertySettingDataService
    ) { }

    private CEDS_Store = new Map<string, string>();

    private async TryGetCEDSValueAsync(terminalId: string, outletId = '0'): Promise<string> {
        try {

            if(outletId !== '0')
            {
                let userId = this._localization.GetPropertyInfo('TenantId');
                const CEDS_Value: string = this._localization.GetPropertyInfo('TenantId') + '_' + 
                this._localization.GetPropertyInfo('PropertyId') + '_' + outletId + '_' + outletId;
                this.CEDS_Store.set(terminalId, CEDS_Value);
                return CEDS_Value;
            }

            let defaultOutletId = this.propertyInfo.GetDefaultOutlet();
            if (defaultOutletId === 0) {
                defaultOutletId = await this.propertySettingService.getDefaultOutlet();
                if (defaultOutletId > 0 ) {
                    this.propertyInfo.SetDefaultOutlet(defaultOutletId);
                }
            }
            if (this.CEDS_Store.has(terminalId)) {
                return this.CEDS_Store.get(terminalId);
            } 
            else if (terminalId === '0' && defaultOutletId !== 0) {
                const response = await this._retailhttp.getPromise<any>({
                    route: CommonApiRoutes.GetCEDSByOutletId,
                    uriParams: { outletId: defaultOutletId }
                });
                const CEDS_Value: string = response;
                this.CEDS_Store.set(terminalId, CEDS_Value);
                return CEDS_Value;
            } 
            else {
                let response = await this._retailhttp.getPromise<any>({
                    route: CommonApiRoutes.GetCEDSByTerminalId,
                    uriParams: { terminalId: terminalId }
                });
                const CEDS_Value: string = response;
                this.CEDS_Store.set(terminalId, CEDS_Value);
                return CEDS_Value;
            }
        } catch (error) {
            console.log(`Error occurred while retrieving CEDS value err:${error}`);
        }
    }

    async FormTenderId(tender: string, terminalId: string, outletId = '0') {
        //return "1001_1001_10_10.2";TODO Remove 
        const CEDS = await this.TryGetCEDSValueAsync(terminalId, outletId);
        return (`${CEDS}.${tender}`);
    }

    /**
     * @function GetHandles async
     * @returns HandleResponse
     * @param handleRequestBody HandleRequest
     * @param terminalId string
     * @description Returns the list of available devices
     */
    async GetHandles(handleRequestBody: HandleRequest, terminalId = '0', outletId = '0', handleErr: boolean = false): Promise<HandleResponse> {
        handleRequestBody.tenderId = await this.FormTenderId(handleRequestBody.tenderId, terminalId, outletId);
        let uri = this.BuildPMAgentURI(APIAction.GetHandles);
        let resp$ = this._payAgentHttp.postPromise<HandleResponse>({
            route: uri,
            body: handleRequestBody,
            uriParams: { terminalId: terminalId }
        },
            handleErr = handleErr);
        return resp$;
    }

    /**
     * @function GetHandles async
     * @returns HandleResponse
     * @param handleRequestBody HandleRequest
     * @param terminalId string
     * @description Returns the list of available devices
     */
    async GetHandlesForCMS(handleRequestBody: CMSHandleRequest, terminalId = '0', handleErr: boolean = true): Promise<HandleResponse> {
        handleRequestBody.tenderId = await this.FormTenderId(handleRequestBody.tenderId, terminalId);
        let uri = this.BuildPMAgentURI(APIAction.GetHandles);
        let resp$ = this._payAgentHttp.postPromise<HandleResponse>({
            route: uri,
            body: handleRequestBody,
            uriParams: { terminalId: terminalId }
        },
            handleErr = handleErr);
        return resp$;
    }

    /**
     * @function CreateToken async
     * @returns TokentransactionInfo
     * @param handle string
     * @param tenderID number
     * @param terminalId string
     * @description Communicates with PMAgent to lights up the device for capturing the card
     */
    async CreateToken(handle: string, tenderID: number, terminalId = '0', isPartialTenderAllowed = false, ismanualCardEntry= false,outletId = '0'): Promise<TokentransactionInfo> {
        let requestBody: CreateTokenRequest;
        requestBody = { handle: handle, inquirerInfo: { TenderId: tenderID.toString(), isPartialTenderAllowed: isPartialTenderAllowed } };
        requestBody.inquirerInfo.TenderId = await this.FormTenderId(requestBody.inquirerInfo.TenderId, terminalId);
        requestBody.inquirerInfo.manualCardEntry = ismanualCardEntry;
        let uri = this.BuildPMAgentURI(APIAction.CreateToken);
        let resp$ = this._payAgentHttp.postPromise<any>({
            route: uri,
            body: requestBody,
            uriParams: { terminalId: terminalId }
        }, false);
        return resp$;
    }

    /**
     * @function StoreToken async
     * @returns PaymentBaseResponse
     * @param storeTokenRequest StoreTokenRequest     
     * @description Communicates with Payment microservice for storing the token retrieved from PMAgent/Device
     */
    async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
        let routeURL = this.propertyInfo.isResortFinanceEnabled ? CommonApiRoutes.StoreTokenRF : CommonApiRoutes.StoreToken;
        const response: any = await this._retailhttp.postPromise<any>({
            route: routeURL,
            body: storeTokenRequest
        });
        if (response) {
            return response;
        }
        return null;
    }

    /**
     * @function GetCardInfo async
     * @returns CardInfo
     * @param tokenRefId number     
     * @description Communicates with Payment microservice to get the token based on the reference ID
     */
    async GetCardInfo(tokenRefId: number): Promise<CardInfo> {
        const response: any = await this._retailhttp.getPromise<any>({
            route: CommonApiRoutes.GetCardInfo,
            uriParams: { tokenTransId: tokenRefId }
        });
        return response ? response : null;
    }

    /**
     * @function RequestSale async
     * @returns SaleResponse
     * @param payRequest SaleRequest 
     * @param terminalId number
     * @description Communicates with PMAgent to light up the device for sale
     */
    async RequestSale(payRequest: SaleRequest, terminalId = '0', handlerError = false): Promise<SaleResponse> {
        payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, terminalId);
        let uri = this.BuildPMAgentURI(APIAction.RequestSale);
        let resp$: Promise<SaleResponse> = this._payAgentHttp.postPromise<any>({
            route: uri,
            body: payRequest,
            uriParams: { terminalId: terminalId }
        }, handlerError);
        return resp$;
    }

    FormSaleRequestBody(saleData, handle, tenderId, saleAmount?): SaleRequest {
        let userId = this._localization.GetPropertyInfo('UserId');
        let RequestBody: SaleRequest = {
          handle: handle,
          inquirerInfo: {
            terminalId: saleData.terminalId,
            orderNumber: saleData.checkNumber,
            profitCenter: saleData.profitCenter,
            profitCenterName: saleData.profitCenterName,
            financialBins: [],
            zipcode: saleData.zipcode ? saleData.zipcode : '',
            isPartialTenderAllowed: saleData.isPartialPayAllowed ?  saleData.isPartialPayAllowed : false,
            tenderId: tenderId,
            employeeId: userId ? userId : '0',
            customer: userId ? userId : '0'
          },
          amount: {
            requestAmount: saleAmount
          }
        };
        return RequestBody;
      }
 
    /**
     * @function validatepay async
     * @returns ValidatePayResponse
     * @param ValidatePayRequest validatePayRequest 
     * @param OutletId number
     * @description Communicates with Payment Manger to validate the sale response
     */
    async ValidatePay(validatePayRequest: ValidatePayRequest, OutletId: number, handlerError = true): Promise<any> {

        let resp$: Promise<any> = this._retailhttp.postPromise<any>({
            route: CommonApiRoutes.ValidatePay,
            body: validatePayRequest,
            uriParams: { outletId: OutletId },
        }, handlerError);
        return resp$;
    }

    /**
     * @function VoidSale async
     * @returns VoidSale Response
     * @param transactionIds transactionIds 
     * @description Communicates with Payment Manger to void the sale 
     */
    async VoidSale(transactionIds: number[], handlerError = true): Promise<any> {

        let resp$: Promise<any> = this._retailhttp.postPromise<any>({
            route: CommonApiRoutes.VoidSale,
            body: transactionIds
        }, handlerError);
        return resp$;
    }
    /**
       * @function ValidateAuth async
       * @returns ValidatePayResponse
       * @param ValidateAuthRequest validateAuthRequest 
       * @param OutletId number
       * @description Communicates with Payment Manger to validate the Auth response
       */
    async ValidateAuth(validateAuthRequest: ValidateAuthRequest, OutletId: number): Promise<any> {

        let resp$: Promise<any> = this._retailhttp.postPromise<any>({
            route: CommonApiRoutes.ValidateAuth,
            body: validateAuthRequest,
            uriParams: { outletId: OutletId },
        });
        return resp$;
    }

    /**
     * @function MaskCreditCardNumber
     * @returns string
     * @param cardNo string 
     * @description Formats the card number for display purpose
     */
    MaskCreditCardNumber(cardNo: string): string {
        cardNo = 'XXXX-XXXX-XXXX-' + cardNo.substring(cardNo.length - 4, cardNo.length);
        return cardNo;
    }

    /**
     * @function ValidateCreditCard
     * @returns boolean
     * @param cardInfo CardInfo 
     * @description Validates the given card details for its validity, based on the expiry date
     */
    ValidateCreditCard(cardInfo: CardInfo): boolean {
        if (cardInfo.cardExpiration && cardInfo.cardExpiration.length === 6) { // Check the returned date is of format YYYYMM
            // Check Expiry date
            const cardMonth = Number(cardInfo.cardExpiration.substring(4, 6)) - 1;
            const cardYear = Number(cardInfo.cardExpiration.substring(0, 4));
            const currentDate = this.utils.getCurrentDate();
            if (new Date(cardYear, cardMonth, 0).getTime() < new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getTime()) {
                let errMsg = this._localization.getError(10720); //BUG - 27519 - Changes
                this.utils.showError(errMsg);
                return false;
            }
        }
        return true;
    }

    /**
    * @function formatCreditCardExpiryDate
    * @returns string
    * @param date string
    * @description formats the given expiry date for display purpose
    */
    formatCreditCardExpiryDate(date: string): string {
        if (date && date.length === 6) {
            return date.substring(4, 6) + '/' + date.substring(2, 4); // MM/YY
        }
        return date;
    }

    /**
    * @function SaleByToken
    * @description Perform the sale using the credit card token
    */
    async SaleByToken(outletId, amount: number, tenderId, inquirerInfo, tokenTransactiond): Promise<SaleByTokenResponse> {
        const RequestBody = this.FormSaleByTokenRequestBody(tenderId, amount, inquirerInfo, tokenTransactiond);
        const SaleResponse = await this._paymentDataService.SaleByToken(RequestBody, outletId);
        return SaleResponse;
    }


    /**
     * @function CreditByToken
     * @description Perform the credit using the credit card token
     */
    async CreditByToken(outletId, amount: number, tenderId, inquirerInfo, tokenTransactiond): Promise<SaleByTokenResponse> {
        const RequestBody = this.FormSaleByTokenRequestBody(tenderId, amount, inquirerInfo, tokenTransactiond);
        let CreditResponse = await this._paymentDataService.CreditByToken(RequestBody, outletId);
        return CreditResponse;
    }

    /**
     * @function FormSaleByTokenRequestBody
     * @description Perform the sale using the credit card token
     */
    FormSaleByTokenRequestBody(tenderId, saleAmount: number, inquirerInfo, tokenTransactiond): SaleByTokenRequest {
        let userId = this._localization.GetPropertyInfo('UserId');
        let RequestObj: SaleByTokenRequest = {
            inquirerInfo: {
                terminalId: inquirerInfo.terminalId,
                orderNumber: inquirerInfo.checkNumber,
                profitCenter: inquirerInfo.ProfitCenter,
                zipcode: inquirerInfo.zipcode ? inquirerInfo.zipcode : '',
                isPartialTenderAllowed: inquirerInfo.isPartialPayAllowed ? inquirerInfo.isPartialPayAllowed : false,
                tenderId: tenderId,
                employeeId: userId ? userId : '0',
                customer: userId ? userId : '0',
				enterprise: String(this.propertyInfo.PropertyId),
            },
            transactionId: tokenTransactiond,
            amount: saleAmount,
            sourceType: inquirerInfo.sourceType,
            sourceTypeId: inquirerInfo.sourceTypeId,
            folioNumber: inquirerInfo.folioNumber,
            roomNumber: inquirerInfo.roomNumber
        };
        return RequestObj;
    }

    /**
     * @function BuildPMAgentURI
     * @returns CommonApiRoutes
     * @param action APIAction
     * @param outletId number
     * @description constructs the URI based on the API Action specified
     */
    private BuildPMAgentURI(action: APIAction): CommonApiRoutes {
        let completeURI = '';
        switch (action) {
            case APIAction.RequestSale:
                completeURI = CommonApiRoutes.Sale;
                break;
            case APIAction.RequestAuth:
                completeURI = CommonApiRoutes.Auth;
                break;
            case APIAction.CreateToken:
                completeURI = CommonApiRoutes.CreateToken;
                break;
            case APIAction.GetHandles:
                completeURI = CommonApiRoutes.GetHandles;
                break;
            case APIAction.Credit:
                completeURI = CommonApiRoutes.Credit;
                break;
        }
        return completeURI as CommonApiRoutes;
    }

    /**
     * @function PaymentErrorPrompt
     * @param errcodes PaymentErrorCodes
     * @description Displays the error popup based on the error code
     */
    PaymentErrorPrompt(errcodes, errorMsg = "") {
        if (!(errcodes in PaymentErrorCodes) && errorMsg) {
            return this.utils.ShowError(this._localization.captions.common.Error, errorMsg);
        }
        switch (parseInt(errcodes)) //TODO
        {
            case PaymentErrorCodes.ClientInvalidRequest:
                return this.utils.showError(this._localization.captions.shop.PMInvalidRequest);
            case PaymentErrorCodes.DeviceNotAvailable:
                return this.utils.showError(this._localization.captions.shop.PMDeviceNotAvailable);
            case PaymentErrorCodes.DeviceNotReady:
                return this.utils.showError(this._localization.captions.shop.PMDeviceBusy);
            case PaymentErrorCodes.DeviceUserPressedCancel:
                return this.utils.showError(this._localization.captions.shop.PMDeviceCancel);
            case PaymentErrorCodes.TransactionTimeOut:
                return this.utils.showError(this._localization.captions.shop.PMTransactionTimeOut);
            case PaymentErrorCodes.DeviceError:
            case PaymentErrorCodes.CardErrorInvalidAccountNumber:
            case PaymentErrorCodes.CardErrorInvalidExpirationDate:
            case PaymentErrorCodes.CardError:
            case PaymentErrorCodes.CardErrorUnsupportedCardType:
            case PaymentErrorCodes.CardErrorUnsupportedCardIssuer:
                return this.utils.showError(this._localization.getError(10721));
            case PaymentErrorCodes.DeviceOperationAborted:
                return this.utils.showError(this._localization.captions.shop.PMDeviceTimeOut);
            case PaymentErrorCodes.CardDeclinedLimit:
                return this.utils.showError(this._localization.captions.shop.InsufficientFunds);
            case PaymentErrorCodes.GatewayAuthenticationFailed:
                return this.utils.showError(this._localization.captions.shop.PMAuthFailed);
            case PaymentErrorCodes.CardDeclinedChipDecline:
                return this.utils.showError(this._localization.captions.shop.PMcardDeclinedChipDecline);
            case PaymentErrorCodes.PaymentManagerTimedOut:
                return this.utils.showError(this._localization.captions.shop.PMAgentUnavailable);
            case PaymentErrorCodes.DeviceInvalidData:
            default:
                return this.utils.showError(this._localization.captions.shop.PMUnexpectedError);
        }
    }

    /**
     * @function RequestCredit async
     * @returns SaleResponse
     * @param payRequest SaleRequest 
     * @param terminalId string
     * @description Communicates with PMAgent to light up the device for credit
     */
    async RequestCredit(payRequest: SaleRequest, terminalId = '0'): Promise<any> {
        payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, terminalId);
        let uri = this.BuildPMAgentURI(APIAction.Credit);
        let resp$ = this._payAgentHttp.postPromise<any>({
            route: uri,
            body: payRequest,
            uriParams: { terminalId: terminalId }
        });

        return resp$;
    }


    /**
  * @function RequestAuth async
  * @returns AuthResponse
  * @param payRequest AuthRequest 
  * @param terminalId number
  * @description Communicates with PMAgent to light up the device for sale
  */
    async RequestAuth(payRequest: AuthRequest, terminalId = '0'): Promise<AuthResponse> {
        payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, terminalId);
        let uri = this.BuildPMAgentURI(APIAction.RequestAuth);
        let resp$: Promise<AuthResponse> = this._payAgentHttp.postPromise<any>({
            route: uri,
            body: payRequest,
            uriParams: { terminalId: terminalId }
        });
        return resp$;
    }


    /**
  * @function AuthByToken
  * @description Perform the auth using the credit card token
  */
    async AuthByToken(outletId, amount: number, tenderId, inquirerInfo, tokenTransactiond): Promise<AuthByTokenResponse> {
        const RequestBody = this.FormAuthByTokenRequestBody(tenderId, amount, inquirerInfo, tokenTransactiond);
        const AuthResponse = await this._paymentDataService.AuthByToken(RequestBody, outletId);
        return AuthResponse;
    }

    /**
   * @function FormAuthByTokenRequestBody
   * @description Perform the auth using the credit card token
   */
    FormAuthByTokenRequestBody(tenderId, authAmount, authInfo, tokenTransactiond): AuthByTokenRequest {
        let userId = this._localization.GetPropertyInfo('UserId');
        let RequestObj: AuthByTokenRequest = {
            inquirerInfo: {
                terminalId: authInfo.terminalId,
                orderNumber: authInfo.checkNumber,
                profitCenter: authInfo.ProfitCenter,
                zipcode: authInfo.zipcode ? authInfo.zipcode : '',
                isPartialTenderAllowed: authInfo.isPartialPayAllowed ? authInfo.isPartialPayAllowed : false,
                tenderId: tenderId,
                employeeId: userId ? userId : '0',
                customer: userId ? userId : '0',
				enterprise: String(this.propertyInfo.PropertyId),
            },
            transactionId: tokenTransactiond,
            amount: authAmount,
            sourceType: authInfo.sourceType,
            sourceTypeId: authInfo.sourceTypeId,
            folioNumber: authInfo.folioNumber,
            roomNumber: authInfo.roomNumber
        };
        return RequestObj;
    }


    async getUserSessionConfiguration(userId: number): Promise<FolioUserSessionConfiguration> {
        let result = await this._retailhttp.getPromise<FolioUserSessionConfiguration>({
            route: CommonApiRoutes.GetUserSessionConfiguration,
            uriParams: { "userId": userId }
        });
        if (result === null) {
            result = {
                id: 0,
                userId: userId,
                defaultOutletId: 0,
                defaultCourseId: 0,
                defaultPaymentDevice: '',
                defaultDeviceName: '',
                isIdtechSred: false,
                smallStickersPrinter: '',
                hangingTicketsPrinter: '',
                subPropertyId: 0,
                propertyId: 0,
                defaultTerminalId: 0
            }
        }
        return result;
    }
    /**
* @function getCreditCardConfiguration
* @description To get setting for credit cards
*/
    async getCreditCardConfiguration(): Promise<CreditCardConfiguration> {
        const settings = await this._paymentDataService.getCreditCardConfiguration(SettingModule.SystemSetup, SettingScreen.CreditCardConfiguration);
        return this.UIMapper(settings.configValue);
    }

    UIMapper(creditCardConfiguration): CreditCardConfiguration {
        const creditCardConfig = creditCardConfiguration.configurations.find(t => t.type == GatewayConfigurationType.CreditCard);
        const config: CreditCardConfiguration = {
            configurations: creditCardConfig,
            enableRequestCardSwipe: creditCardConfiguration.enableRequestCardSwipe,
            enableCCTracking: creditCardConfiguration.enableCCTracking,
            skipCCFauxNoPurgeDuringZOut: creditCardConfiguration.skipCCFauxNoPurgeDuringZOut,
            purgeArchivedCCDaysAfterCO: creditCardConfiguration.purgeArchivedCCDaysAfterCO ? creditCardConfiguration.purgeArchivedCCDaysAfterCO : '',
            purgeBookingCCDaysAfterCO: creditCardConfiguration.purgeBookingCCDaysAfterCO ? creditCardConfiguration.purgeBookingCCDaysAfterCO : '',
            purgeGuestCCDaysAfterCO: creditCardConfiguration.purgeGuestCCDaysAfterCO ? creditCardConfiguration.purgeGuestCCDaysAfterCO : '',
            purgePostingCCDaysAfterCO: creditCardConfiguration.purgePostingCCDaysAfterCO ? creditCardConfiguration.purgePostingCCDaysAfterCO : '',
            purgeStayCCDaysAfterCO: creditCardConfiguration.purgeStayCCDaysAfterCO ? creditCardConfiguration.purgeStayCCDaysAfterCO : '',
            activateAllPABPRecommendation: creditCardConfiguration.activateAllPABPRecommendation,
            noOfSvcReceipts: creditCardConfiguration.noOfSvcReceipts ? creditCardConfiguration.noOfSvcReceipts : '',
            pbfeNetpfDelay: creditCardConfiguration.pbfeNetpfDelay ? creditCardConfiguration.pbfeNetpfDelay : '',
            batchWaitTimeSec: creditCardConfiguration.batchWaitTimeSec,
            incrementalAuthWhenNotSupported: creditCardConfiguration.incrementalAuthWhenNotSupported,
            skipDecreaseAuth: creditCardConfiguration.skipDecreaseAuth,
            enableChipAndPin: creditCardConfiguration.enableChipAndPin,
            enableChipPinTest: creditCardConfiguration.enableChipPinTest,
            enableHouseNumber: creditCardConfiguration.enableHouseNumber,
            alwaysRequestSwipeAtCheckIn: creditCardConfiguration.alwaysRequestSwipeAtCheckIn,
            automaticallyDeferCCAdvanceDepositPost: creditCardConfiguration.automaticallyDeferCCAdvanceDepositPost,
            enablePartialPayment: creditCardConfig ? creditCardConfig.isPartialPayAllowed : false,
            enablePrintCCAuthorization: creditCardConfiguration.enablePrintCCAuthorization,
            enableActivateAVS: creditCardConfig ? creditCardConfig.isAVSActive : false,
            enableActivateInterface: creditCardConfig.isInterfaceActive,
            setCCOverAuthAdjustThreshold: creditCardConfiguration.setCCOverAuthAdjustThreshold
        }
        return config;
    }

  

}

enum APIAction {
    RequestSale = 1,
    GetHandles,
    CreateToken,
    Credit,
    RequestAuth
}
