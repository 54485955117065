import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { TableHeaderOptions, TableOptions, AgDateConfig } from 'src/app/common/Models/ag-models';
import { dataMigrationexpBussiness } from './data-migration-business';
import { datamigform, UImodal, DataMigrationStatistics, StatisticalDetails, DataMigrationStatisticsRequest } from './data-migration-exp.modal';
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities'
@Component({
  selector: 'app-data-migration-exp',
  templateUrl: './data-migration-exp.component.html',
  styleUrls: ['./data-migration-exp.component.scss'],
  providers:[dataMigrationexpBussiness],
  encapsulation:ViewEncapsulation.None
})


export class DataMigrationExpComponent implements OnInit {
  captions:any;
  datamigform: FormGroup<datamigform>;
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent: Promise<UImodal[]>;
  processButton: { type: string; label: any; disabledproperty: boolean; };
  filterList: { id: number; value: string; checked: boolean; }[];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchText: any;
  responseModel: Promise<UImodal[]>;
  fromDate: AgDateConfig;
  toDate: AgDateConfig;
  selectedFromDate: string;
  selectedToDate: string;
  selectedScreenType: string;
  failedCodesFileName: string;
  failedItemsFileName:string;
  maxStartDate: Date;
  minEndDate: Date;

  constructor(private Localization:Localization,private bussiness: dataMigrationexpBussiness,private fb: FormBuilder,private _propertyInformation: PropertyInformation, private utilities: CommonUtilities) { 
    this.captions=this.Localization.captions;
  }

  ngOnInit(): void {
    this.failedCodesFileName = ' Failed Codes';
    this.failedItemsFileName = ' Failed Items';
    this.selectedScreenType = 'Settings';  
    this.formGenerator();
    this.headerOptions = this.bussiness.getHeaderOptions();
    this.tableOptions = this.bussiness.getTableOptions(); 
    this.processButton = {
      type: 'primary',
      label: this.captions.btn_process,
      disabledproperty: true,
    };
    this.filterReset();    
    this.datamigform.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.processButton.disabledproperty=!(this.datamigform.valid && this.datamigform.dirty);
    });
     
  }

  filterReset()
  {
    this.filterList=[
      {
        id:0,
        value:"All",
        checked:true,
      },
      {
        id:1,
        value:"Failed Imports",
        checked:false,
      },
      {
        id:2,
        value:"Passed Imports",
        checked:false,
      }
    ]
  }

  formGenerator()
  {
    this.datamigform=this.fb.group({
      screenType: [this.selectedScreenType],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      fiterData:[]
    });

    this.fromDate = {
      className: 'ag_w--33 ag_pr--7 ag_display--inblock',
      form: this.datamigform,
      formControlName: 'fromDate',
      placeHolderId: 'lbl_fromDate',
      minDate: this._propertyInformation.CurrentDate,
      placeHolder: this.captions.lbl_fromDate,
      value: this.Localization.LocalizeDate(this._propertyInformation.CurrentDate),
      isDateRequired: true,
      errorMessage: this.captions.lbl_fromDate
    };

    this.toDate = {
      className: 'ag_w--33 ag_pr--7 ag_display--inblock',
      form: this.datamigform,
      formControlName: 'toDate',
      placeHolderId: 'lbl_toDate',
      minDate: this._propertyInformation.CurrentDate,
      placeHolder: this.captions.lbl_toDate,
      value: this.Localization.LocalizeDate(this._propertyInformation.CurrentDate),
      isDateRequired: true,
      errorMessage: this.captions.lbl_toDate
    };

    this.datamigform.controls.fromDate.setValue(this._propertyInformation.CurrentDate.toString());
    this.datamigform.controls.toDate.setValue(this._propertyInformation.CurrentDate.toString());
    this.maxStartDate = this._propertyInformation.CurrentDate;
    this.minEndDate = this._propertyInformation.CurrentDate;
  }

  async downloadExcel(event,ele,key,idx)
  {
    this.utilities.ToggleLoader(true);
    await this.bussiness.download(ele.id,'FAILEDCODE',(ele.screen + this.failedCodesFileName)).then().finally(()=>{
      this.utilities.ToggleLoader(false);
    });
  }

  onfilter(obj)
  {
    this.datamigform.markAsDirty();
    this.filterList.forEach(x=> {
      x.checked = false;
    })
    obj.checked=true;
    this.datamigform.get('fiterData').setValue(this.filterList);
    switch(obj.id)
    {
      case 0:
        this.tableContent = this.responseModel;
        break;
      case 1:
        this.tableContent = this.responseModel.then(res =>{
          let filteredData = res.filter(x => x.failedCount > 0);
          return filteredData;
        });
        break;        
      case 2:
        this.tableContent = this.responseModel;
        this.tableContent = this.responseModel.then(res =>{
          let filteredData = res.filter(x => x.failedCount <= 0);
          return filteredData;
        });
        break;
    }
  }

  onTableAction(e)
  {

  }

  searchByscreen(e)
  {
  this.searchText=e;
  }

  screenTypeChange(e)
  {
    this.selectedScreenType = e.value.toString();
  }
  onAction()
  {
    this.utilities.ToggleLoader(true);
    let requestModel: DataMigrationStatisticsRequest = {
      moduleName: this.selectedScreenType.toString(),
      startDate: this.Localization.LocalizeDate(this.fromDate.value).toString(),
      endDate: this.Localization.LocalizeDate(this.toDate.value).toString()
    };
    
    this.responseModel = this.bussiness.getAPIData(requestModel).then().finally(()=>{
      this.utilities.ToggleLoader(false);
    });
    this.tableContent = this.responseModel;
    this.callResetFilter();
  }
  callResetFilter()
  {
    this.datamigform.markAsDirty();
    this.filterReset();
    this.datamigform.get('fiterData').setValue(this.filterList);
  }

  fromDateChange(e)
  {
    this.selectedFromDate = this.Localization.LocalizeDate(e.date);
    this.fromDate.value = this.Localization.LocalizeDate(e.date);
    this.minEndDate = e.date;
  }

  toDateChange(e)
  {
    this.selectedToDate = this.Localization.LocalizeDate(e.date);
    this.toDate.value = this.Localization.LocalizeDate(e.date);
    this.maxStartDate = e.date;
  }
  
  async failedDownloadsExcel(event,ele,key,idx)
  {
    this.utilities.ToggleLoader(true);
    await this.bussiness.download(ele.id,'FAILEDITEM', (ele.screen + this.failedItemsFileName)).then().finally(()=>{
      this.utilities.ToggleLoader(false);
    });
  }

  onSyncClick()
  {
    
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

}
