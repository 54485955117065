import { FormaterConfig, FormaterConfiguration } from './formater-config';
// import * as FormterJson from '../../../../assets/json/formater.json';
import { Formaters } from '.';
import { Converters } from '../converters';
export const FromaterArray: Array<FormaterConfiguration> = [
  {
      Id: 1,
      FieldKey: 'Amount',
      FormaterType: 'CurrencyFormater'
  },
  {
    Id: 1,
    FieldKey: 'Currency',
    FormaterType: 'CurrencyFormater'
   },
  {
      Id: 2,
      FieldKey: 'Qty',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'Quantity',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'QuantityBU',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 3,
      FieldKey: 'Valued',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 3,
      FieldKey: 'Value',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'UnitCost',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'currentcost',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'purchasecurrentcost',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'transactioncurrentcost',
      FormaterType: 'CurrencyFormater'
  },
  {
    Id: 4,
    FieldKey: 'recipecurrentcost',
    FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'Valuation',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'QtyOnHand',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'QtyOnOrder',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'QtyOnRequistion',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'AverageWtCost',
      FormaterType: 'CurrencyFormater'
  },
//   {
//       Id: 4,
//       FieldKey: 'Conversion',
//       FormaterType: 'CurrencyFormater'
//   },
  {
      Id: 4,
      FieldKey: 'PackSize',
      FormaterType: 'ConversionFormater'
  },
  {
      Id: 4,
      FieldKey: 'Price',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'ControlPrice',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'QOH',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'Valued',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'ProductPrice',
      FormaterType: 'CurrencyFormater'
  },
  {
    Id: 4,
    FieldKey: 'Markup',
    FormaterType: 'QuantityFormater'
    },
  {
      Id: 4,
      FieldKey: 'WAC',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'MinPar',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'MaxPar',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'LastPhyQty',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'TotalAdjustQty',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'TotalAdjustValue',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'Normalized',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'PoTotal',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'PoItemTotal',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'MinOrder',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'MaxOrder',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'OutFunc',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'InFunc',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'Expiration',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'CurrentCost',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 4,
      FieldKey: 'CostBU',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'CostRU',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'Price2',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 4,
      FieldKey: 'CostPercentage',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'NutrientValue2',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'ServingSize',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'Refuse',
      FormaterType: 'QuantityFormater'    
  },
  {
      Id: 2,
      FieldKey: 'Serving',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'NutrientValue1',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'NutrientPercentageValue',
      FormaterType: 'FixedFormater'
  },
  {
      Id: 2,
      FieldKey: 'BatchCostPerUnit',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 2,
      FieldKey: 'PortionCostPerUnit',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 2,
      FieldKey: 'BatchCPUPercentage',
      FormaterType: 'FixedFormater'
  },
  {
      Id: 2,
      FieldKey: 'PortionCPUPercentage',
      FormaterType: 'FixedFormater'
  },
  {
      Id: 2,
      FieldKey: 'Conversion',
      FormaterType: 'ConversionFormater'
  },
  {
      Id: 2,
      FieldKey: 'TotalQty',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'TotalSales',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 2,
      FieldKey: 'TotalCost',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 2,
      FieldKey: 'Costpercent',
      FormaterType: 'FixedFormater'
  },
  {
      Id: 2,
      FieldKey: 'Margin',
      FormaterType: 'CurrencyFormater'
  },
  {
      Id: 2,
      FieldKey: 'Profitpercent',
      FormaterType: 'FixedFormater'
  },
  {
      Id: 2,
      FieldKey: 'Price',
      FormaterType: 'QuantityFormater'
  },
  {
      Id: 2,
      FieldKey: 'Yield',
      FormaterType: 'FixedFormater'
  },
  {
      Id: 2,
      FieldKey: 'FixedFormat',
      FormaterType: 'FixedFormater'
  },
  {
    Id: 2,
    FieldKey: 'Profitpercentage',
    FormaterType: 'FixedFormater'
    },
    {
        Id: 2,
        FieldKey: 'TransactionConversion',
        FormaterType: 'ConversionFormater'
    },
    {
        Id: 2,
        FieldKey: 'PurchaseConversion',
        FormaterType: 'ConversionFormater'
    },
    {
        Id: 2,
      FieldKey: 'Conversion',
      FormaterType: 'ConversionFormater'
    },
    {
        Id: 2,
        FieldKey: 'Yield',
        FormaterType: 'ConversionFormater'
    },
    {
        Id: 3,
        FieldKey: 'Tolerance',
        FormaterType: 'QuantityFormater'
    },
    {
        Id: 3,
        FieldKey: 'TolerancePercentage',
        FormaterType: 'QuantityFormater'
    },
    {
        Id: 3,
        FieldKey: 'CostPerUnit',
        FormaterType: 'CurrencyFormater'
    },
    {
        Id: 3,
        FieldKey: 'CPUPercentage',
        FormaterType: 'QuantityFormater'
    }
];
export const Formater: FormaterConfig = ( FieldKey: string, FieldValue: any, HeaderEntity: any, DetailEntity: any,
     isEdit?: boolean): string => {
    try {
        // const FormaterJson: Array<FormaterConfiguration> =  FormterJson['default'];
        const FormaterJson: Array<FormaterConfiguration> = FromaterArray;
         const FormaterField =  FormaterJson.filter(x => x.FieldKey.toString().toLowerCase() === 
          (FieldKey ? FieldKey.toString().toLowerCase() : ''));
         if ( FormaterField && FormaterField.length > 0) {
            switch (FormaterField[0].FormaterType) {
                case 'CurrencyFormater':
                    FieldValue = Formaters.CurrencyFormater(FieldKey, FieldValue?.toString(), HeaderEntity, DetailEntity, isEdit);
                  break;
                case 'QuantityFormater':
                    FieldValue = Formaters.QuantityFormeter(FieldKey, FieldValue?.toString(), HeaderEntity, DetailEntity, isEdit);
                  break;
                case 'ConversionFormater':
                    FieldValue = Formaters.ConversionFormater(FieldKey, FieldValue?.toString(), HeaderEntity, DetailEntity, isEdit);
                    break;
                case 'FixedFormater':
                    FieldValue = Converters.FixedConverter(FieldValue?.toString(), null);
                    break;
                default:
                  // code block
              }
         }
      
        return FieldValue;
    } catch (error) {
        console.error(error);
    }
};


