import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppService } from 'src/app/common/app-service';
import { HttpCallService } from '../communication/common/http-call.service';
import { Localization } from '../localization/localization';
import { ServiceParams } from '../Models/http.model';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';

@Injectable({providedIn:'root'})
/**
 * Communication layer for pay agent
 * HttpCalls can be overridden here
*/
export class PayAgentCommunication extends HttpCallService {

    constructor(localization: Localization,
         utils: CommonUtilities,
         httpclient: HttpClient,
         _sanitizer: DomSanitizer,
        _propertyInfo: CommonPropertyInformation,
        appService: AppService
    ) {        
        super(_propertyInfo.GetPayAgentConfigURIValue(), httpclient, localization, utils, _propertyInfo,appService);
    }


    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        let response$: Promise<T> = super.putPromise<T>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: T = await response$;
        return response;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<T> = super.postPromise<T>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: T = await response$;
        return response;
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        }
        else {
            throw err;
        }
    }

}
