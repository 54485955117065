import { Component, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ToastrService } from 'ngx-toastr';
import { AgilysysButtonModel } from '../../agilysys-buttons/models/agilysys-buttons.interface';
import { ActionListPopUpModel, ErrorPopModel, ProgressPopupModel } from '../model/pop-up.interface';
import _ from 'lodash';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import moment from 'moment';
import { DynamicGridService, GridTableComponent } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
@Component({
    selector: 'app-agilysys-action',
    templateUrl: './agilysys-action.component.html',
    styleUrls: ['./agilysys-action.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgilsysActionPopComponent implements OnDestroy {  
    @ViewChild('gridTable', { static: false }) gridTable: GridTableComponent;
    actionGridTransactionData: GridConfiguration;
    originalactionGridTransactionData: GridConfiguration;
  constructor(private toastrService: ToastrService, private dynamicGridService: DynamicGridService,
    private commonservice: CommonService,
    public dialogRef: MatDialogRef<AgilsysActionPopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActionListPopUpModel) {
           this.actionGridTransactionData = data.ActionListGridData;
           this.originalactionGridTransactionData = _.cloneDeep(this.actionGridTransactionData);
           this.gridActionEvent();
           if (this.actionGridTransactionData.GridSort.length > 0) {
            const value = [
              {
                colId: this.actionGridTransactionData.GridSort[0].SortingOdataKey.toLowerCase(),
                sort: this.actionGridTransactionData.GridSort[0].SortingType.toLowerCase()
              }
            ];
            this.actionGridTransactionData.sortChanged(value);
          }
        }

        gridActionEvent() {
            this.actionGridTransactionData.sortChanged = (value: any) => {
              // this.params.orderby = value[0].colId + ' ' + value[0].sort;
              const orderBy = value[0].sort;
              const tempKey = value[0].colId;
              const key = this.actionGridTransactionData.GridHeaderDefination.filter(x => x.field.toLowerCase() === value[0].colId.toLowerCase())[0].fieldType;
              const data = this.actionGridTransactionData.GridData;
              this.actionGridTransactionData.GridSort[0].SortingOdataKey = tempKey;
              this.actionGridTransactionData.GridSort[0].SortingType = orderBy;
              const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
              const DateTimeFormat = (sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null) ?
                sessionDateFormat :  constants.DefaultIniDateFormat;
              if (key.toString() === 'date') {
                data.forEach(x => {
                  if (x[tempKey] && x[tempKey].indexOf('T') !== -1) {
                    x[tempKey] = moment(new Date(x[tempKey])).format(DateTimeFormat);
                  }
                });
                data.sort((a, b) => {
                  const data1 = a[tempKey] ? Number(moment(a[tempKey], DateTimeFormat).format('X')) : 0;
                  const data2 = b[tempKey] ? Number(moment(b[tempKey], DateTimeFormat).format('X')) : 0;
                  if (orderBy.toString() === 'desc') {
                    return data2 - data1;
                  } else {
                    return data1 - data2;
                  }
        
                });
              } else if (key.toString() === 'number') {
                data.sort((a, b) => {
                  const data1 = Number(a[tempKey]);
                  const data2 = Number(b[tempKey]);
                  if (orderBy.toString() === 'desc') {
                    return data2 - data1;
                  } else {
                    return data1 - data2;
                  }
                });
              } else if (key.toString() === 'string') {
                data.sort((a, b) => {
                  const data1 = a[tempKey] ? a[tempKey]?.toString()?.replace(/\s/g, '').toLowerCase() : '';
                  const data2 = b[tempKey] ? b[tempKey]?.toString()?.replace(/\s/g, '').toLowerCase() : '';
                  if (orderBy.toString() === 'desc') {
                    // if (data2 < data1) {
                    //   return -1;
                    // }
                    // if (data2 > data1) {
                    //   return 1;
                    // }
                    // return 0;
                    return data2.localeCompare(data1, undefined, {numeric: true});
                  } else {
                    // if (data1 < data2) {
                    //   return -1;
                    // }
                    // if (data1 > data2) {
                    //   return 1;
                    // }
                    // return 0;
                    return data1.localeCompare(data2, undefined, {numeric: true});
                  }
        
                });
              }
              this.actionGridTransactionData.GridData = data;
              this.actionGridTransactionData.GridData = [...this.actionGridTransactionData.GridData]; 
              this.actionGridTransactionData = { ...this.actionGridTransactionData };
            };
            this.actionGridTransactionData.searchTextChanged = (searchText) => {
              this.searchTextChange(searchText);
            };
            this.actionGridTransactionData.columnChanged = (value: any) => {
              const columnJson = this.dynamicGridService.assignColumns(value[1], value[0]);
              this.actionGridTransactionData.GridActions.ShuffleColumnConfig.ColumnsList = columnJson;
              this.actionGridTransactionData.GridHeaderDefination = columnJson;
              this.actionGridTransactionData = { ...this.actionGridTransactionData };
            };
        }
        searchTextChange(searchkey) {
            const rowData = this.originalactionGridTransactionData.GridData;
            const key = this.actionGridTransactionData.GridSearch[0].SearchKey.toLowerCase();
            let returnData = [];
            if (searchkey.length > 0) {
              returnData = rowData.filter(x => x[key].toString().toLowerCase().includes(searchkey.toLowerCase()));
            } else {
              returnData = rowData;
            }
            this.actionGridTransactionData.GridData = [];
            this.actionGridTransactionData.GridData = returnData;
            if (this.actionGridTransactionData.GridSort.length > 0) {
              const value = [
                {
                  colId: this.actionGridTransactionData.GridSort[0].SortingOdataKey.toLowerCase(),
                  sort: this.actionGridTransactionData.GridSort[0].SortingType.toLowerCase()
                }
              ];
              this.actionGridTransactionData.sortChanged(value);
            }
            this.actionGridTransactionData.GridActions.TotalItems = returnData.length;
            this.actionGridTransactionData = { ...this.actionGridTransactionData };
          }
        ngOnDestroy() {
          // localStorage.removeItem('rowHeightData');
          this.commonservice.resizeRows(this.gridTable);
        }     

     

}