import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { NotificationConfigurationBusiness } from './notification-configuration.business';
import { NotificationConfigurationService } from './notification-configuration.service';
import { EventUI, Distribution, NotificationEventTemplate, EventConfigurationUI, TemplateType, DistributionType, statusFlag, VIPType, GuestCategory, DmEFormInformation, EFormData } from './notification-configuration.model';
import _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Product } from '../../shared/shared/globalsContant';
import { BaseResponse, SystemConfig } from '../../shared/shared/business/shared.modals';
import { ActivatedRoute } from '@angular/router';
import { AlertAction, AlertType, ButtonTypes, DmConfig } from '../../Models/common.models';
import { UserAccessBusiness } from '../../dataservices/authentication/useraccess.business';
import { DMConfigDataService } from '../../dataservices/datamagine-config.data.service';
import { MatDialog } from '@angular/material/dialog';
import { DmEformsSpaComponent } from '../../components/dm-eforms-spa/dm-eforms-spa.component';
import { EformsBusiness } from '../../data-magine/dm-eforms/dm-eforms.business';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { EventNotification } from '../notification-configuration/notification-configuration.model';

@Component({
  selector: 'app-notification-configuration',
  templateUrl: './notification-configuration.component.html',
  styleUrls: ['./notification-configuration.component.scss'],
  providers: [NotificationConfigurationBusiness, NotificationConfigurationService, UserAccessBusiness, DMConfigDataService, EformsBusiness],
  encapsulation: ViewEncapsulation.None
})
export class NotificationConfigurationComponent implements OnInit {
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  configuredEvents: any = [];
  notificationFormGroup: UntypedFormGroup;
  notifycaptions: any;
  Notify_events: EventUI[] = [];
  Notify_eventsCollection: any = [];
  //guest
  G_email: boolean = false;
  G_sms: boolean = false;
  G_emailDistLst: Distribution[] = [];
  G_smsDistLst: Distribution[] = [];
  G_whatsappDistLst: Distribution[] = [];
  G_emailTemp: NotificationEventTemplate[] = [];
  G_emailTemp_Collection: any = [];
  G_smsTemp: NotificationEventTemplate[] = [];
  G_smsTemp_Collection: any[] = [];
  G_whatsappTemp: NotificationEventTemplate[] = [];
  G_whatsappTemp_Collection: any[] = [];
  //User
  U_email: boolean = false;
  U_sms: boolean = false;
  U_emailDistLst: Distribution[] = [];
  U_smsDistLst: Distribution[] = [];
  U_whatsappDistLst: Distribution[] = [];
  U_emailTemp: NotificationEventTemplate[] = [];
  U_emailTemp_Collection: any[] = [];
  U_smsTemp: NotificationEventTemplate[] = [];
  U_smsTemp_Collection: any[] = [];
  U_whatsappTemp: NotificationEventTemplate[] = [];
  U_whatsappTemp_Collection: any[] = [];
  selectedEvents: any[] = [];
  EventConfigurationUI: EventConfigurationUI[] = [];
  isEdit: boolean = false;
  saveBtnEnabled: boolean;
  defaultNotificationFormGroup: UntypedFormGroup;
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  finalArray: any = [];
  isReminder: boolean;
  productId: number;
  hideIntakeForm: boolean;
  propertyIntakeForm: boolean = false;
  hideVipCode: boolean;
  vipcodes: VIPType[];
  guestTypes: VIPType[];
  buildings: VIPType[];
  eventCaptions: any;
  showCancelPopup: boolean = false;
  isPms = false;
  isDisabled : boolean = false;
  captions:any;
  previousEvent;
  selectedIndexVal;
  previousVal;
  isFromEdit : boolean = false;
  guestCategory: GuestCategory[] = [];
  dmConfig: DmConfig;
  dmResponse: any;
  dmEFormInfo: DmEFormInformation;
  eformData : EFormData[] = [];
  floatLabel: string;
  isGdprEnabled:boolean = false;
  GolfGuestDataRequestEventId:any;
  SPAGuestDataRequestEventId:any;

  isEformsEnabled = false;
  isAuthorizeEnabled = false;
  GeneralEventId: number;
  sncId = Product.SNC;
  pmsId = Product.PMS;

  constructor(private fb: UntypedFormBuilder, private _Localization: Localization, public _NCB: NotificationConfigurationBusiness,
    private cdref: ChangeDetectorRef, private Utilities: CommonUtilities, private router: ActivatedRoute, 
     private dmConfigDataService: DMConfigDataService, public dialog: MatDialog, public propertyInfo: CommonPropertyInformation) {
    this.notifycaptions = this._Localization.captions.settings.notificationsConfiguration;
    this.productId = Number(this._Localization.GetPropertyInfo('ProductId'));
    this.hideIntakeForm = this.productId == Product.SPA ? false : true;
    this.hideVipCode = this.productId == Product.PMS ? false : true;
    this.eventCaptions = this._Localization.captions.settings.events;
    this.floatLabel = this._Localization.setFloatLabel;
    this.captions = this._Localization.captions
  }

  async ngOnInit() {
    this.router.data.pipe(takeUntil(this.$destroyed)).subscribe((data) => {
      this.showCancelPopup = data.ShowCancelPopup ? data.ShowCancelPopup : false;
    });
    this.isGdprEnabled = JSON.parse(sessionStorage.getItem('isGdprEnabled'));
    this.GolfGuestDataRequestEventId = EventNotification.GolfGuestDataRequest;
    this.SPAGuestDataRequestEventId = EventNotification.SPAGuestDataRequest;
    this.GeneralEventId = EventNotification.General;
    this.generateNotificationForm();  
    await this.GetSystemSetting();
    if (this.productId == Product.PMS) {
      this.isPms = true;
      let result = await this._NCB.getAllVIPCode();
      this.vipcodes = result[0];
      this.guestTypes = result[1];
      this.buildings = result[2];
    }
    this._NCB.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = this.captions.lbl_processing;
        }
        else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
    this._NCB.loaderEnable.next(true);    
    await this.getDDDatas();
    await this.validateUserAccess();
    this._NCB.loaderEnable.next(false);
    this.getDmconfig();
    this.getAuthorizeConfig();
  }

  async getDmconfig()
  {
    this.dmConfig = await this.dmConfigDataService.getDataMagineConfigSession();
    if (this.dmConfig && this.dmConfig?.enableDataMagine) {
      this.isEformsEnabled = this.dmConfig?.dmEformsConfig?.enableEforms ?? false;
    }
  }

  async getAuthorizeConfig()
  {
      this.isAuthorizeEnabled = this.propertyInfo.IsAuthorizeEnabled();
  }

  public async validateUserAccess() {
    await this._NCB.validateBreakPoints();
    this.disableControls();
    this.notificationFormGroup.updateValueAndValidity();
  }

  disableControls() {
    if (this._NCB.isViewOnly) {
      for(let element of this.notificationFormGroup.get('notificationEvents')['controls']) {
        this.Utilities.disableControls(element);        
      }
      this.saveBtnEnabled = false;
      this.isDisabled = true;
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  generateNotificationForm() {
    this.notificationFormGroup = this.fb.group({
      notificationEvents: this.fb.array([])
    });
    this.notificationFormGroup.statusChanges.subscribe(x => {
      this.saveBtnEnabled = this.notificationFormGroup.valid && this.notificationFormGroup.dirty;
    });
    this.defaultNotificationFormGroup = _.cloneDeep(this.notificationFormGroup);
  }

  async getDDDatas() {
    this.Notify_events = await this._NCB.getAllEvents();
    const DistributionList = await this._NCB.getAllDistributionByProductId();
    const GuestCategory = await this._NCB.getGuestCategoryByProductId();
    if (!this.isGdprEnabled) this.Notify_events = this.Notify_events.filter(x => x.id != EventNotification.GolfGuestDataRequest && x.id != EventNotification.SPAGuestDataRequest);

    if (Number(this._Localization.GetPropertyInfo('ProductId')) == Product.PMS) {
      this.Notify_events = this.Notify_events.filter(event => event.id != EventNotification.Anniversary
        && event.id != EventNotification.Birthday
        && event.id != EventNotification.Consents
        && event.id != EventNotification.EventsGroups
        && event.id != EventNotification.EventsReminder
        && event.id != EventNotification.GDPROptInGuest
        && event.id != EventNotification.Groups
        && event.id != EventNotification.GuestStayFeedback
        && event.id != EventNotification.Invoices
        && event.id != EventNotification.NoShow
        && event.id != EventNotification.PreCheckIn
        && event.id != EventNotification.Reactivation
        && event.id != EventNotification.RMSEvent
        && event.id != EventNotification.VIPArrivals
        && event.id != EventNotification.WeddingsGroups
        && event.id != EventNotification.WelcomeMessage
        && event.id != EventNotification.AccountCreated
        && event.id != EventNotification.BookingCreated
        && event.id != EventNotification.OTABookingConfirmation
        && event.id != EventNotification.OTABookingModification
        && event.id != EventNotification.OTABookingCancellation);
    }

    this.isReminder = this.Notify_events.filter(x => x.reminder).length > 0;
    this.guestCategory = GuestCategory;

    if (DistributionList && DistributionList.length > 0) {
      const emailDistribution = DistributionList.filter(x => (x.distributionType & DistributionType.EMAIL)  == DistributionType.EMAIL);
      const smsDistribution = DistributionList.filter(x => (x.distributionType & DistributionType.SMS)  == DistributionType.SMS);
      const whatsappDistribution = DistributionList.filter(x => (x.distributionType & DistributionType.WHATSAPP)  == DistributionType.WHATSAPP);
      this.G_emailDistLst = this.U_emailDistLst = emailDistribution;
      this.G_smsDistLst = this.U_smsDistLst = smsDistribution;
      this.G_whatsappDistLst = this.U_whatsappDistLst = whatsappDistribution;
    }
    this.EventConfigurationUI = await this._NCB.getAllEventConfigurationByProductId(this.Notify_events);
    if (this.EventConfigurationUI && this.EventConfigurationUI.length > 0) {
      this.isEdit = true;
      this.pushEventList();
      this.defaultNotificationFormGroup = _.cloneDeep(this.notificationFormGroup);
    }
  }

  pushEventList() {
    let arr = this.notificationFormGroup.get('notificationEvents') as UntypedFormArray;
    if(!this.isGdprEnabled) this.EventConfigurationUI = this.EventConfigurationUI.filter(x=> x.events != EventNotification.GolfGuestDataRequest && x.events != EventNotification.SPAGuestDataRequest);

    if(Number(this._Localization.GetPropertyInfo('ProductId')) ==Product.PMS)
    {
      this.EventConfigurationUI = this.EventConfigurationUI.filter(event => event.events != EventNotification.Anniversary
        && event.events != EventNotification.Birthday
        && event.events != EventNotification.Consents
        && event.events != EventNotification.EventsGroups
        && event.events != EventNotification.EventsReminder
        && event.events != EventNotification.GDPROptInGuest
        && event.events != EventNotification.Groups
        && event.events != EventNotification.GuestStayFeedback
        && event.events != EventNotification.Invoices
        && event.events != EventNotification.NoShow
        && event.events != EventNotification.PreCheckIn
        && event.events != EventNotification.Reactivation
        && event.events != EventNotification.RMSEvent
        && event.events != EventNotification.VIPArrivals
        && event.events != EventNotification.WeddingsGroups
        && event.events != EventNotification.WelcomeMessage
        && event.events !=EventNotification.AccountCreated
        && event.events !=EventNotification.BookingCreated        
        && event.events != EventNotification.OTABookingConfirmation
        && event.events != EventNotification.OTABookingModification
        && event.events != EventNotification.OTABookingCancellation);
    }

    this.EventConfigurationUI.forEach(x => {
      this.CreateEmptyCollection();
    });
    this.EventConfigurationUI.forEach((x, index) => {
      this.setNotify_events();
      this.pushTemplateCollecion(x.events, index);
      this.Notify_eventsCollection[index].SelectedList = x.events;
      this.Notify_eventsCollection[index].SelectedListObj = this.Notify_events.find(y => y.id == x.events);
      arr.push(this.createnotificationFormControls(x));
      this.resetNotify_eventsCollection();
    });
  }

  createnotificationFormControls(x) {
    let form = this.fb.group({
      guestType: '',
      building: '',
      guestId: x.guestId,
      userId: x.userId,
      events: x.events,
      emailSubject : (x.emailSubject != null && x.emailSubject != undefined) ? x.emailSubject : "",
      authorize: x.authorize,
      guestEmail: x.guestEmail,
      guestSms: x.guestSms,
      guestWhatsapp: x.guestWhatsapp,
      guestEmailDistLst: x.guestEmailDistLst,
      guestSMSDistLst: x.guestSMSDistLst,
      guestWhatsappDistLst: x.guestWhatsappDistLst,
      guestEmailTemp: x.guestEmailTemp,
      guestSmsTemp: x.guestSmsTemp,
      guestWhatsappTemp: x.guestWhatsappTemp,
      userEmail: x.userEmail,
      userSms: x.userSms,
      userWhatsapp: x.userWhatsapp,
      userEmailDistLst: x.userEmailDistLst,
      userSMSDistLst: x.userSMSDistLst,
      userWhatsappDistLst: x.userWhatsappDistLst,
      userEmailTemp: x.userEmailTemp,
      userSmsTemp: x.userSmsTemp,
      userWhatsappTemp: x.userWhatsappTemp,
      days: [x.days, Validators.max(999)],
      hrs: [x.hrs, Validators.max(23)],
      min: [x.min, Validators.max(59)],
      sec: [x.sec, Validators.max(59)],
      ISdbdata: x.ISdbdata,
      eventSchedulerId: x.eventSchedulerId,
      guestEventScheduler: x.guestEventScheduler,
      userEventScheduler: x.userEventScheduler,
      intakeForm: x.intakeForm,
      vipCodeId: x.vipCodeId,
      guestEmailCategory:[],
      enableEForm:x.enableEForm,
      guestSmsCategory:[],
      eFormData:[]
    });
    form.controls.guestType.setValue(x?.guestType ?? '');
    form.controls.building.setValue(x?.building ?? '');
    form.controls.guestEmailCategory.setValue(x?.guestEmailCategory?.length > 0 ? x.guestEmailCategory:this.getDefaultCategory());
    form.controls.guestSmsCategory.setValue(x?.guestSmsCategory?.length > 0 ? x.guestSmsCategory:this.getDefaultCategory());
   form.controls.eFormData.setValue(x?.eFormData ?? []);
  
    return form;
  }

  setNotify_events() {
    let obj = {
      'orginalList': this.Notify_events,
      'filteredList': this.Notify_events,
      'SelectedList': '',
      'SelectedListObj': ''
    };
    this.Notify_eventsCollection.push(obj);
  }

  togglechangeeform(e,eventId,f)
  {
    if(e[0])
    {
      if (this.dmConfig && this.dmConfig?.enableDataMagine) {
        this.isEformsEnabled = this.dmConfig?.dmEformsConfig?.enableEforms ?? false;     
      } 
    }     
  }

  togglechangeAuthorize(e,eventId,f)
  {
    if(e[0])
    {
      this.getAuthorizeConfig();
    }     
  }

  openDMEforms(eventId) {
    let selectedData: [] = [];
    const eFormdataArray = this.notificationFormGroup.get('notificationEvents') as UntypedFormArray;
    const formControl = eFormdataArray.controls.find(element => element.value.events== eventId  && element.value.enableEForm);
    if(formControl)
    {
      selectedData = formControl['controls']['eFormData'].value;
    }  
    let res = this.dialog.open(DmEformsSpaComponent, {
      width: '50%',
      height: '570px',
      hasBackdrop:true,
      data: {
        screenId: 'DMSTAYREC',
        id: "",
        confNo: "",
        guestId: "",
        isFromNotiicationConfig: true,
        data: selectedData

      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(s => {
      if(s){
        this.dmResponse = s;
        this.saveDmEmailLink(this.dmResponse, eventId);
      }
      
    });
  }

  saveDmEmailLink(dmResponse: any, eventId)
  {
    
    this.eformData  = dmResponse.map(x=>  {
       let data : EFormData = {
        eFormID : x.eFormId,
        //link : x.link,
        linkName : x.eFormName
       }       
       return data;
    });
    this.dmEFormInfo = {EFormData: this.eformData, EForm: true};
    const eFormdataArray = this.notificationFormGroup.get('notificationEvents') as UntypedFormArray;
    const formControl = eFormdataArray.controls.find(element => element.value.events== eventId  && element.value.enableEForm);
    if(formControl)
    {
      formControl.markAsDirty();
      formControl['controls']['eFormData'].setValue(this.dmEFormInfo.EFormData);
      
    }  

  }

  getNotificationName(id: number[]): string {
    let notifications = id.map(x=>{
      const category = this.guestCategory.find(y=>y.categoryId === x);
      return category.categoryName;
    }).join(', ');
    return notifications;
  }

  async pushTemplateCollecion(eventId, index) {
    const templateValues = await this._NCB.getTemplateDetailsByEventId(eventId);

    if (templateValues && templateValues.length > 0) {
      let EmailTemplates = templateValues.filter(x => x.typeId == TemplateType.Email);
      let SMSTemplates = templateValues.filter(x => x.typeId == TemplateType.SMS);
      let WhatsappTemplates = templateValues.filter(x => x.typeId == TemplateType.WHATSAPP);
      this.G_emailTemp_Collection[index] = (EmailTemplates ? EmailTemplates : []);
      this.U_emailTemp_Collection[index] = (EmailTemplates ? EmailTemplates : []);
      this.G_smsTemp_Collection[index] = (SMSTemplates ? SMSTemplates : []);
      this.U_smsTemp_Collection[index] = (SMSTemplates ? SMSTemplates : []);
      this.G_whatsappTemp_Collection[index] = (WhatsappTemplates ? WhatsappTemplates : []);
      this.U_whatsappTemp_Collection[index] = (WhatsappTemplates ? WhatsappTemplates : []);
    }
  }

  async setTemplateCollection(eventId, index) {
    const templateValues = await this._NCB.getTemplateDetailsByEventId(eventId);

    if (templateValues && templateValues.length > 0) {
      const EmailTemplates = templateValues.filter(x => x.typeId == TemplateType.Email);
      const SMSTemplates = templateValues.filter(x => x.typeId == TemplateType.SMS);
      const WhatsappTemplates = templateValues.filter(x => x.typeId == TemplateType.WHATSAPP);
      this.G_emailTemp_Collection[index] = (EmailTemplates ? EmailTemplates : []);
      this.U_emailTemp_Collection[index] = (EmailTemplates ? EmailTemplates : []);
      this.G_smsTemp_Collection[index] = (SMSTemplates ? SMSTemplates : []);
      this.U_smsTemp_Collection[index] = (SMSTemplates ? SMSTemplates : []);
      this.G_whatsappTemp_Collection[index] = (WhatsappTemplates ? WhatsappTemplates : []);
      this.U_whatsappTemp_Collection[index] = (WhatsappTemplates ? WhatsappTemplates : []);
      const defaultEmailTemplate = EmailTemplates.find(x => x.isDefault);
      const defaultSMSTemplate = SMSTemplates.find(x => x.isDefault);
      const defaultWhatsappTemplate = WhatsappTemplates.find(x => x.isDefault);
      this.SetDefaultTemplate(index, defaultEmailTemplate, defaultSMSTemplate, defaultWhatsappTemplate);
    }
  }

  SetDefaultTemplate(index, defaultEmailTemplate, defaultSMSTemplate, defaultWhatsappTemplate) {
    if (defaultEmailTemplate) {
      this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.guestEmailTemp.setValue(defaultEmailTemplate.id);
      this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.userEmailTemp.setValue(defaultEmailTemplate.id);
    }
    if (defaultSMSTemplate) {
      this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.guestSmsTemp.setValue(defaultSMSTemplate.id);
      this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.userSmsTemp.setValue(defaultSMSTemplate.id);
    }
    if(defaultWhatsappTemplate){
      this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.guestWhatsappTemp.setValue(defaultWhatsappTemplate.id);
      this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.userWhatsappTemp.setValue(defaultWhatsappTemplate.id);
    }
    this.notificationFormGroup.updateValueAndValidity();
  }

  async GetSystemSetting() {
    if (this.productId == Product.SPA) {
      this.Utilities.getConfiguration(this.successCallback.bind(this), this.errorCallback.bind(this), "Appointment", "INTAKE_FORM");
    }
  }

  async AddEvents(index) {
    if(!this._NCB.isViewOnly){
      const indexvalue = index + 1;
      this.setNotify_events();
      const eventId = this.getfirstId(this.Notify_eventsCollection, indexvalue);
      this.Notify_eventsCollection[indexvalue].SelectedListObj = this.Notify_events.find(x => x.id == eventId);
      let emptyData = {
        guestId: 0,
        userId: 0,
        events: eventId,
        guestEmail: false,
        guestSms: false,
        guestWhatsapp: false,
        guestEmailDistLst: null,
        guestSMSDistLst: null,
        guestWhatsappDistLst: null,
        guestEmailTemp: null,
        guestSmsTemp: null,
        guestWhatsappTemp: null,
        guestEmailCategory: null,
        guestSmsCategory: null,
        intakeForm: this.propertyIntakeForm,
        userEmail: false,
        userSms: false,
        userWhatsapp: false,
        userEmailDistLst: null,
        userSMSDistLst: null,
        userWhatsappDistLst: null,
        userEmailTemp: null,
        userSmsTemp: null,
        userWhatsappTemp: null,
        days: 0,
        hrs: 0,
        min: 0,
        sec: 0,
        ISdbdata: false,
        eventSchedulerId: 0,
        guestEventScheduler: null,
        userEventScheduler: null,
        vipCodeId: 0,
        guestTypeId: 0,
        buildingTypeId: 0
      };
      let arr = this.notificationFormGroup.get('notificationEvents') as UntypedFormArray;
      arr.push(this.createnotificationFormControls(emptyData));
      let eve = { 'value': eventId };
      await this.actionChanged(eve, 'events', indexvalue);
      await this.resetNotify_eventsCollection();
      this.isFromEdit = false;
    }    
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetConfiguration") {
      let res: SystemConfig = <any>result.result;
      this.propertyIntakeForm = res.value == "true" ? true : false;
    }
  }


  errorCallback<T>(): void {

  }

  getfirstId(collection, index) {
    let id;
    let notifyeventlist = this.notificationFormGroup.controls.notificationEvents.value.map(x => x.events);

    if (notifyeventlist.length > 0) {
      id = this.Notify_events.filter(x => {
        return (notifyeventlist.indexOf(x.id) == -1);
      });
      if (id && id.length > 0) {
        id = id[0].id;
      }
      else {
        let reminder = this.Notify_events.find(x => x.reminder);
        id = reminder && reminder.id;
      }
    } else {
      id = this.Notify_events[0].id;
    }
    return id;
  }

  removeEvent(index) {
    if (!this._NCB.isViewOnly) {
      this.Notify_eventsCollection.splice(index, 1);
      this.G_emailTemp_Collection.splice(index, 1);
      this.U_emailTemp_Collection.splice(index, 1);
      this.G_smsTemp_Collection.splice(index, 1);
      this.U_smsTemp_Collection.splice(index, 1);
      this.G_whatsappTemp_Collection.splice(index, 1);
      this.U_whatsappTemp_Collection.splice(index, 1);
      let arr = this.notificationFormGroup.get('notificationEvents') as UntypedFormArray;
      let removeddata = arr.getRawValue()[index];
      if (removeddata.ISdbdata) {
        removeddata.status = statusFlag.Delete;
        this.finalArray.push(removeddata)
      }
      arr.removeAt(index);

      this.resetNotify_eventsCollection();
      this.notificationFormGroup.markAsDirty();
      this.notificationFormGroup.updateValueAndValidity();
    }
  }

  selectAll(e,obj,index,type) {
   if(e.checked) {
   let selectedIds;
   if(type == 'guestType'){
     selectedIds=this.guestTypes.map(x=>x.id);
   } else {
     selectedIds=this.buildings.map(x=>x.id);
   }
   this.notificationFormGroup.controls.notificationEvents['controls'][index].controls[type].setValue(selectedIds);
  }else
   {
    this.notificationFormGroup.controls.notificationEvents['controls'][index].controls[type].setValue("");
   }
   this.notificationFormGroup.controls.notificationEvents['controls'][index].markAsDirty()
   this.notificationFormGroup.controls.notificationEvents['controls'][index].updateValueAndValidity()
  }

  togglechange(eve) {

  }
  async actionChanged(eve, ctrl, index) {
    if (ctrl == "events") {
      this.RemoveCollectionByIndex(index);
      this.CreateEmptyCollection();
      this.Notify_eventsCollection[index].SelectedList = eve.value;
      this.Notify_eventsCollection[index].SelectedListObj = this.Notify_events.find(x => x.id == eve.value);
      this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.emailSubject.setValue(this.Notify_eventsCollection[index].SelectedListObj.defaultEmailSubject);
      this.setTemplateCollection(eve.value, index);
    }
    this.resetNotify_eventsCollection();
  }
  CreateEmptyCollection() {
    this.G_emailTemp_Collection.push([]);
    this.U_emailTemp_Collection.push([]);
    this.G_smsTemp_Collection.push([]);
    this.U_smsTemp_Collection.push([]);
    this.G_whatsappTemp_Collection.push([]);
    this.U_whatsappTemp_Collection.push([]);
  }
  RemoveCollectionByIndex(index) {
    this.G_emailTemp_Collection.splice(index, 1);
    this.U_emailTemp_Collection.splice(index, 1);
    this.G_smsTemp_Collection.splice(index, 1);
    this.U_smsTemp_Collection.splice(index, 1);
    this.G_whatsappTemp_Collection.splice(index, 1);
    this.U_whatsappTemp_Collection.splice(index, 1);
    this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.guestEmailTemp.setValue(null);
    this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.userEmailTemp.setValue(null);
    this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.guestSmsTemp.setValue(null);
    this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.userSmsTemp.setValue(null);
    this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.guestWhatsappTemp.setValue(null);
    this.notificationFormGroup.controls.notificationEvents['controls'][index].controls.userWhatsappTemp.setValue(null);
  }
  expColl(eve, iscollapsed, i) {
    this.selectedIndexVal = i;
    if (iscollapsed) {
      if(this.previousEvent){
        this.previousEvent.target.parentNode.parentNode.classList.remove('isExpanded');
      }
      this.previousEvent = eve;
      eve.target.parentNode.parentNode.classList.add('isExpanded');
    } else {
      eve.target.parentNode.parentNode.classList.remove('isExpanded');

    }
    this.isFromEdit = true;
    let arr = this.notificationFormGroup.get('notificationEvents') as UntypedFormArray;
    let arrayValues = arr.controls.map(x => x.value);
    arrayValues = arrayValues.filter(x => x.events != 41);
    this.previousVal = arrayValues[i];
  }
  async onSave(eve) {
    this._NCB.loaderEnable.next(true);
    let arr = this.notificationFormGroup.get('notificationEvents') as UntypedFormArray;
    if (this.isPms) {
      let arrayValues = arr.controls.map(x => x.value);
      arrayValues = arrayValues.filter(x => x.events != 41);
      let GroupByEventIdAndTime = _.chain(arrayValues).groupBy((item) => item.events + '#' + parseInt(item.hrs) + '#' + parseInt(item.min) + '#' + parseInt(item.sec)).map((value, key) => ({ eventId: key, notificationGroups: value })).value();
      let isDuplicateReminder = GroupByEventIdAndTime.some(x => x.notificationGroups.length > 1);
      if (isDuplicateReminder) {
        let repeatingEvents = GroupByEventIdAndTime.filter(x => x.notificationGroups.length > 1)
        var selectedGuestTypesandBuilding;
        if(this.selectedIndexVal == undefined) {
          GroupByEventIdAndTime.forEach(guestData => {
            if(guestData.notificationGroups.filter(x=>x.ISdbdata == false)){
              selectedGuestTypesandBuilding =  guestData.notificationGroups.filter(x=>x.ISdbdata == false);
            }
          });
        }
        else {
          selectedGuestTypesandBuilding = arrayValues[this.selectedIndexVal];
        }
        var selectedGuestTypes;
        var selectedBuildings;
        var eventId;
        if(this.selectedIndexVal == undefined && selectedGuestTypesandBuilding.length != 0){
          selectedGuestTypes = selectedGuestTypesandBuilding[0].guestType;
          selectedBuildings = selectedGuestTypesandBuilding[0].building;
          eventId = selectedGuestTypesandBuilding[0].events;
        }
        else {
          selectedGuestTypes = selectedGuestTypesandBuilding.guestType;
          selectedBuildings = selectedGuestTypesandBuilding.building;
          eventId = selectedGuestTypesandBuilding.events;
        }      
        if(selectedGuestTypesandBuilding.length != 0) {
          if(this.isFromEdit == false){
            for(let i=0;i < arrayValues.length;i++) {
              if(arrayValues[i].guestType.length == selectedGuestTypes.length && arrayValues[i].building.length == selectedBuildings.length && arrayValues[i].ISdbdata  && arrayValues[i].events == eventId){
                if(JSON.stringify(arrayValues[i].guestType)==JSON.stringify(selectedGuestTypes) && JSON.stringify(arrayValues[i].building)==JSON.stringify(selectedBuildings)){
                let message = this.notifycaptions.DuplicateCombination;
                this.Utilities.showError(message);
                this._NCB.loaderEnable.next(false);
                return;
                }
              }
            }
          }
          else {
            for(let i=0;i < this.previousVal.length;i++){
              if(this.previousVal[i].guestType.length == selectedGuestTypes.length && this.previousVal[i].building.length == selectedBuildings.length && this.previousVal[i].ISdbdata  && this.previousVal[i].events == eventId){
                if(JSON.stringify(this.previousVal[i].guestType)==JSON.stringify(selectedGuestTypes) && JSON.stringify(this.previousVal[i].building)==JSON.stringify(selectedBuildings)){
                let message = this.notifycaptions.DuplicateCombination;
                this.Utilities.showError(message);
                this._NCB.loaderEnable.next(false);
                return;
                }
              }
            }
          }
        }
      }
    }
    else {
      let arrValues = arr.controls.map(x => x.value);
      arrValues = arrValues.filter(x => x.events != 41);
      let GroupByEventIdAndTime = _.chain(arrValues).groupBy((item) => item.events + '#' + parseInt(item.hrs) + '#' + parseInt(item.min) + '#' + parseInt(item.sec)).map((value, key) => ({ eventId: key, notificationGroups: value })).value();
      let isDuplicateReminder = GroupByEventIdAndTime.some(x => x.notificationGroups.length > 1);
      if (isDuplicateReminder) {
        let message = this.notifycaptions.DuplicateReminder;
        this.Utilities.showError(message);
        this._NCB.loaderEnable.next(false);
        return;
      }
    }
    let arrayValues = arr.controls.map(x => x.value);
    arrayValues = arrayValues.filter(x => x.events == 41);
    let GroupByEventIdByVIPCode = _.chain(arrayValues).groupBy((item) => item.events + '#' + item.vipCodeId).map((value, key) => ({ eventId: key, notificationGroups: value })).value();
    let isDuplicateVIPCode = GroupByEventIdByVIPCode.some(x => x.notificationGroups.length > 1);
    if (isDuplicateVIPCode) {
      let message = this.notifycaptions.DuplicateVIPCode;
      this.Utilities.showError(message);
      this._NCB.loaderEnable.next(false);
      return;
    }
    arr.controls.forEach((ele, index) => {
      if (ele.dirty) {
        if (ele.value.ISdbdata) {
          ele.value.status = statusFlag.Update;
          this.finalArray.push(ele.value);
        } else {
          ele.value.status = statusFlag.Insert;
          this.finalArray.push(ele.value);
        }
      }
    });
    this.saveBtnEnabled = false;
    await this._NCB.SaveEventConfiguration(this.finalArray, this.Notify_events);
    this.resetValues();
    this.generateNotificationForm();
    await this.getDDDatas();
    this._NCB.loaderEnable.next(false);
  }

  resetValues() {
    this.finalArray = [];
    this.Notify_events = [];
    this.Notify_eventsCollection = [];
    this.G_emailDistLst = [];
    this.G_smsDistLst = [];
    this.G_whatsappDistLst = [];
    this.G_emailTemp = [];
    this.G_emailTemp_Collection = [];
    this.G_smsTemp = [];
    this.G_smsTemp_Collection = [];
    this.G_whatsappTemp = [];
    this.G_whatsappTemp_Collection = [];
    this.U_emailDistLst = [];
    this.U_smsDistLst = [];
    this.U_whatsappDistLst = [];
    this.U_emailTemp = [];
    this.U_emailTemp_Collection = [];
    this.U_smsTemp = [];
    this.U_smsTemp_Collection = [];
    this.U_whatsappTemp = [];
    this.U_whatsappTemp_Collection = [];
    this.selectedEvents = [];
  }
  async onCancel(eve) {
    if (this.showCancelPopup) {
      if (this.notificationFormGroup.dirty) {
        this.Utilities.showCommonAlert(this.notifycaptions.warn_datalost, AlertType.Warning, ButtonTypes.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this.cancel(eve);
          }
          else {
            return;
          }
        });
      }
      else {
        this.cancel(eve);
      }
    }
    else {
      this.cancel(eve);
    }
  }

  async cancel(eve) {
    this._NCB.loaderEnable.next(true);
    this.resetValues();
    this.generateNotificationForm();
    await this.getDDDatas();
    this._NCB.loaderEnable.next(false);
  }

  async resetNotify_eventsCollection() {
    let notifyeventlist = this.notificationFormGroup.controls.notificationEvents.value.map(x => x.events);
    if (!this.isPms) {
      this.Notify_eventsCollection.forEach((x, index) => {
        let newcollction = [];
        let removeobjList = notifyeventlist.filter(y => y != x.SelectedList);
        newcollction = x.orginalList.filter(x => {
          return (removeobjList.indexOf(x.id) == -1);
        });
        const reminderEvents = this.Notify_events.filter(x => x.reminder && x.description != this.eventCaptions.TeeTimeCompletion);
        if (reminderEvents && reminderEvents.length > 0) {
          reminderEvents.forEach(y => {
            let collection = newcollction.find(x => x.id == y.id);
            if (collection == undefined) {
              newcollction.push(y);
            }
          });
        }
        const viparrivals = this.Notify_events.filter(x => !x.reminder && x.description == this.eventCaptions.VIPArrivals);
        if (viparrivals && viparrivals.length > 0) {
          viparrivals.forEach(y => {
            let collection = newcollction.find(x => x.id == y.id);
            if (collection == undefined) {
              newcollction.push(y);
            }
          });
        }
        x.filteredList = newcollction;
      });
    }
  }

  private getDefaultCategory(): number[] {
    return this.guestCategory.length > 0 ?
      this.guestCategory.map(x=>x.categoryId):[];
  }
}
