import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomAction } from './actions';
import { AddCustomFilterAction, FormCustomActionConfig, MergedFields } from './custom-action.interface';
import { ActionTypes } from './custom-enum.interface';
import { GlobalCustomConfig } from './global-custom-config.interface';
// import * as PreCustomAction from '../../../../assets/json/ui-control/pre-custom-action.json';
import * as _ from 'lodash';

export const GlobalCustomFormAction: GlobalCustomConfig = (
    Name: string, 
    Odatakey: string, 
    FieldKey: string, 
    ActionType: string,
    TypeName: string,
    IsCustomUrl ?: boolean,
    CustomUrl ?: string,
    CustomFilter ?: Array<AddCustomFilterAction>,
    IsCustomMerge ?: boolean,
    MergerFields ?: Array<MergedFields>,
    MergeSymbol ?: any,
    ResponseObject ?: any,
    TransaactionCollection ?: TransactionConfiguration,
    MasterCollection ?: MasterInventoryManagement,
    SetupCollection ?: any
    ): any => {
        try {
            let ReturnCustomAction = null;
            let getCustionAction = JSON.parse(localStorage.getItem('PreCustomAction'));
            if ( getCustionAction && getCustionAction === undefined ) {
                getCustionAction  =  getData('assets/json/ui-control/pre-custom-action.json');
                localStorage.setItem('PreCustomAction', JSON.stringify(getCustionAction) );
            }
           
           if ( getCustionAction !== null ) {
            const actionType = (ActionType === 'FormAction') ? 'FormCustomAction' : 'GridCustomAction';
            const customActions = getCustionAction[actionType].filter((e: FormCustomActionConfig) => 
            ( e.Global && FieldKey === e.Fieldkey ) || ( !e.Global && FieldKey === e.Fieldkey && Odatakey === e.Odatakey ));
            if ( customActions && customActions.length > 0 ) {
                if ( customActions[0].hasOwnProperty('ActionType') && customActions[0].ActionType.length > 0 ) {
                   const getUniqueType =  customActions[0].ActionType.filter(a => a.TypeName === TypeName );
                   if ( getUniqueType && getUniqueType.length > 0 ) {
                    switch ( getUniqueType[0].TypeName ) {
                        case ActionTypes.ADDCUSTOMURL:
                            ReturnCustomAction = CustomAction.CustomUrlAction(
                                Name, Odatakey, FieldKey, ActionType,
                                getUniqueType[0].TypeName, getUniqueType[0].IsCustomUrl, getUniqueType[0].CustomUrl, CustomFilter);
                            break;
                        case ActionTypes.CUSTOMMERGE:
                            const MergeAgainstField = customActions[0].hasOwnProperty('MergeAgainstValue') ? 
                            customActions[0].MergeAgainstValue : null;
                            ReturnCustomAction = CustomAction.CustomFieldMerge(
                                Name, Odatakey, FieldKey, ActionType,
                                getUniqueType[0].TypeName, null, null, null, null, getUniqueType[0].MergerFields,
                                getUniqueType[0].MergeSymbol, ResponseObject, null, null, null, null, null, null, MergeAgainstField);
                            break;
                        case ActionTypes.CHANGEFORMTYPE:
                            ReturnCustomAction = CustomAction.ChangeType(
                                Name, Odatakey, FieldKey, ActionType,
                                getUniqueType[0].TypeName, null, getUniqueType[0].FormType, null, null, null,
                                null, null);
                            break;
                        case ActionTypes.CUSTOMICONMERGE:
                            ReturnCustomAction = CustomAction.CustomIconMerge(
                                Name, Odatakey, FieldKey, ActionType,
                                getUniqueType[0].TypeName, null, null, null, null, null,
                                null, null, null, null, null, getUniqueType[0].IsAddCustomIcon, getUniqueType[0].CustomIcons,
                                getUniqueType[0].IconList);
                            break;
                        default:
                          // code block
                      }
                   }
                }
               
            }
        }
           
            return ReturnCustomAction;
         
        } catch ( error ) {
            console.error(error);
        }
};



function getData(theUrl: string) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open( 'GET', theUrl, false ); // false for synchronous request
    xmlHttp.send( null );
    return JSON.parse(xmlHttp.responseText);
}
  
 

