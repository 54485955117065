import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GlobalSystemPreference } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CustomFormBuild, CustomFormBuildingGrouping, FormBuilderGroupKey, MasterInventoryManagement, } from '../master.interface';
import { FormModelConfiguration } from './form-config.interface';

export const FormBuilding: FormModelConfiguration = (
  ViewModelEntity: any, PostModelEntity: any, MasterDataCollection: MasterInventoryManagement,
  httpService: HttpService, postModelEntityObject: any, translateService?: TranslateService,
  sysytemPreferenceService?: SysytemPreferenceService): any => {
  try {
    if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0) {
      const keyName = postModelEntityObject['$']['Name'];
      const PostModelProperity = postModelEntityObject['$'];
      let MaxLengthPreference: Array<GlobalSystemPreference> = [];
      if (sysytemPreferenceService && sysytemPreferenceService.globalSystemPreference.length > 0) {
        MaxLengthPreference = sysytemPreferenceService.globalSystemPreference
          .filter((x: GlobalSystemPreference) => (x.GroupName === 'System Options' && x.LookupName === 'NoOfSerialsDigit'));
      }
      MasterDataCollection.FormConfig.CustomFormBuilder.forEach((x: CustomFormBuild) => {
        const customBuilderKey = x.CustomBuilderKey.findIndex(builderKey => builderKey.OdataKey === keyName);
        if (customBuilderKey > -1) {
          if (x.CustomBuilderType === 'accordiangrouping') {
            x.CustomBuilderGrouping.forEach((BuilderGrouping: CustomFormBuildingGrouping) => {
              const groupKeyIndex = BuilderGrouping.GroupKey.findIndex(groupkey => groupkey.OdataKey === keyName);
              if (groupKeyIndex > -1) {
                const getGroupObject = BuilderGrouping.GroupKey[groupKeyIndex];
                if (PostModelProperity.hasOwnProperty('Nullable')) {
                  getGroupObject['Nullable'] = postModelEntityObject['$']['Nullable'];
                }
                getGroupObject['EdmType'] = postModelEntityObject['$']['Type'];
              }
            });
          } else if (x.CustomBuilderType === 'accordian') {
            const accordianBuilderKeyIndex = x.CustomBuilderKey.findIndex(builderKey => builderKey.OdataKey === keyName);
            if (accordianBuilderKeyIndex > -1) {
              const accordianObject = x.CustomBuilderKey[accordianBuilderKeyIndex];
              if (PostModelProperity.hasOwnProperty('Nullable')) {
                accordianObject['Nullable'] = postModelEntityObject['$']['Nullable'];
              }
              accordianObject['EdmType'] = postModelEntityObject['$']['Type'];
            }
          } else if (x.CustomBuilderType === 'primary') {
            const primaryBuilderKeyIndex = x.CustomBuilderKey.findIndex(builderKey => builderKey.OdataKey === keyName);
            if (primaryBuilderKeyIndex > -1) {
              const accordianObject = x.CustomBuilderKey[primaryBuilderKeyIndex];
              if (PostModelProperity.hasOwnProperty('Nullable')) {
                accordianObject['Nullable'] = postModelEntityObject['$']['Nullable'];
              }
              if (PostModelProperity.hasOwnProperty('MaxLength')) {
                accordianObject['MaxLength'] = postModelEntityObject['$']['MaxLength'];
              }
              // check systemprference recheck the value 
              if (MaxLengthPreference && MaxLengthPreference.length > 0) {
                const SysytemNullableCollection: Array<string> = ['Number', 'Barcode'];
                const systemPreIndex = SysytemNullableCollection.findIndex((e: string) => keyName === e);
                if (systemPreIndex > -1) {
                  accordianObject['MaxLength'] = MaxLengthPreference[0].LookupValue;
                }
              }
              accordianObject['EdmType'] = (accordianObject.hasOwnProperty('EdmType')) ? accordianObject.EdmType :
                postModelEntityObject['$']['Type'];
            }
          }
        }
      });
    }
    return MasterDataCollection;
  } catch (error) {
    console.log('error occured EnableGridFilter', error);
  }
};