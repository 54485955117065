import { Injectable } from '@angular/core';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { HttpServiceCall } from '../../../shared/service/http-call.service';
import { HttpMethod } from 'src/app/retail/shared/service/http-call.service';
import { Host, PaymentMethodValue } from 'src/app/retail/shared/globalsContant';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { BatchProcessInfo, FailedTransactions, TransactionsLogSearchRequest, TransactionType } from './retail-transactions-log';
import { TransactionDataService } from 'src/app/retail/sytem-config/data-service/transactions-log.service';
import { UserInfo } from '../retail-revenue-posting-logs/revenue-posting';
import { BaseResponse } from 'src/app/retail/shared/business/shared.modals';

@Injectable()
export class TranscationLogBusiness {
  captions: any;
  currencySymbol: string;

  constructor(private localization: RetailLocalization, private utils: RetailUtilities, private dataService: TransactionDataService,
    private http: HttpServiceCall) {
    this.captions = this.localization.captions.shop;
    this.currencySymbol = this.localization.currencySymbol;
  }

  public getTableOptions() {
    return {
      actions: [
      ],
      defaultsortingColoumnKey: 'ticketNumber',
      showTotalRecords: false,
      defaultSortOrder: SorTypeEnum.asc,
      columnFreeze: {
        firstColumn: false,
        lastColumn: true
      },
      isDragDisabled: false,
      isHeaderCheckboxAllowed: true,
      checkboxKey: 'checked',
      ignoreSort: true
    };
  }

  public getHeaderOptions() {
    return [
      {
        key: 'checked',
        displayNameId: '',
        displayName: '',
        hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
        templateName: ActionTypeEnum.checkbox
      },
      {
        key: 'retailTicketNumber',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_ticketNumber,
        sortingKey: 'retailTicketNumber',
        sorting: true
      },
      {
        key: 'tDate',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_transactionDate,
        sortingKey: 'tDate',
        sorting: true
      },
      {
        key: 'tTime',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_transactiontime,
        sortingKey: 'tTime',
        sorting: true
      },
      {
        key: 'amountFormatted',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_failedAmount + ' ' + '(' + this.currencySymbol + ')',
        sortingKey: 'amount',
        sorting: true

      },
      {
        key: 'user',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_user,
        sortingKey: 'user',
        sorting: true
      },
      {
        key: 'errorMessage',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_errorMessage,
        sortingKey: 'errorMessage',
        sorting: true,
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'paymentMethod',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_payment_type,
        sortingKey: 'paymentMethod',
        sorting: true
      },
      {
        key: 'transactionType',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_transaction_type,
        sortingKey: 'transactionType',
        sorting: true
      },
      {
        key: 'Action',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_Action,
        sortingKey: 'Action',
        templateName: ActionTypeEnum.custom
      },

    ];
  }

  async BatchProcessFailedTransactions(isAllSelected: boolean, transactionIds: number[]) {
    return await this.dataService.BatchProcessFailedTransactions(isAllSelected, transactionIds);
  }

  private async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any): Promise<T> {
    const response: BaseResponse<T> = await this.http.CallApiAsync<T>({
        callDesc,
        host,
        method: callType,
        body,
        uriParams: uRIParams
    });
    return response.result;
}

  async getTabledata(model:TransactionsLogSearchRequest , userNameList: UserInfo[]): Promise<FailedTransactions[]> {
    const result = await this.InvokeServiceCall<FailedTransactions[]>('GetFailedTransactions', HttpMethod.Put, Host.payment,model);
    const response: any = result;
    return Promise.resolve(response.map((element) => {
      return {
        id: element.id,
        outletId: element.outletId,
        paymentTransactionId: element.paymentTransactionId,
        amount: element.amount,
        amountFormatted: this.localization.localizeCurrency(element.amount),
        retailTicketNumber: element.retailTicketNumber,
        paymentMethod: PaymentMethodValue[element.paymentMethod],
        transactionType: TransactionType[element.transactionType],
        transactionTime: element.transactionTime,
        tDate: this.localization.LocalizeShortDate(element.transactionTime),
        tTime: this.localization.LocalizeTime(element.transactionTime, true),
        status: element.status,
        errorMessage: element.errorMessage,
        detailedMessage: element.detailedMessage,
        user: userNameList && userNameList.some(u => u.id === element.userId) ? userNameList.
          find(u => u.id === element.userId).userName : ''
      };
    }));
  }

  async getClerkInfo(): Promise<UserInfo[]> {
    const result = await this.http.CallApiAsync({
      callDesc: 'GetUserInfoByPropertyId',
      method: HttpMethod.Get,
      host: Host.authentication,
      uriParams: {
        propertyId: Number(this.utils.GetPropertyInfo('PropertyId')),
        productId: Number(this.utils.GetPropertyInfo('ProductId'))
      }
    });
    const response: any = result && result.result ? result.result : [];
    return response.map((element) => {
      return {
        id: element.userId,
        firstName: element.firstName,
        lastName: element.lastName,
        userName: element.userName
      };
    });
  }

  async getActiveBatchStatus(): Promise<BatchProcessInfo> {
    let result = await this.http.CallApiAsync({
      callDesc: "GetActiveBatchStatus",
      method: HttpMethod.Get,
      host: Host.payment
    });
    let response: any = result && result.result ? result.result : [];
    return response;
  }

}
