import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Localization } from 'src/app/common/localization/localization';
import { COMPRESSION_LIMIT, ALLOWED_IMAGE_SIZE } from '../../constants';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { AlertType, ButtonTypes, AlertAction } from '../../Models/common.models';
import { ButtonType } from '../../enums/shared-enums';



@Component({
  selector: 'ag-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class AgImageUploaderComponent implements OnInit {

  @Input() readOnly: boolean;
  @Input() emptyImgCaption: string;
  @Input('imgData')
  set ImageData(value) {
    if (value) {
      this.url = value;
      this.ImageUploaded = true;
    } else {
      this.ImageUploaded = false;
      this.url = '';
    }
  }
  @Input() requiredWarningPopup:boolean;
  requiredWarningMsg:string;
  @Output() fileDeleted = new EventEmitter();
  @Output() fileUploaded = new EventEmitter();
  @Output() fileSizeExceeded = new EventEmitter();
  url: any;
  ImageUploaded: boolean;
  captions: any;
  isViewOnly = false; // was not declared
  constructor(private imageCompress: NgxImageCompressService, public _ls: Localization,private utilities: CommonUtilities) { }

  ngOnInit() {

    this.captions = this._ls.captions.common;
  }

 
  compressFile() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.url = image;
      // convert to MB
      const supportedFileFormat = ['jpg','png','jpeg','svg']
      const uploadFileFormat = image?.split(";")[0]?.split(":")[1]?.split("/")[1]
      if(supportedFileFormat.some(v => uploadFileFormat?.includes(v))){
        const fileSize = this.imageCompress.byteCount(image) / (1024);
        if (fileSize > COMPRESSION_LIMIT) {
          this.imageCompress.compressFile(image, orientation).then(
            result => {
              const compressedfileSize = this.imageCompress.byteCount(result) / (1024 * 1024);
              if (compressedfileSize <= ALLOWED_IMAGE_SIZE) {
                this.url = result;
                this.ImageUploaded = true;             
                this.compressThumbnail(result);
              } else {
                this.fileSizeExceeded.emit();
              }
            }
          );
        } else {
          this.ImageUploaded = true;
          this.compressThumbnail(image);
        }      
      } else {
        this.utilities.showAlert(this.captions.lbl_Image_validation, AlertType.Error, ButtonType.Ok);
      }
     
    });
  }

  compressThumbnail(image){
    const imgData = {orgImg: image};
    this.imageCompress.compressFile(image, 1, 30, 30).then(result => {
      const compressedfileSize = this.imageCompress.byteCount(result) / (1024);
      imgData['tmbImg'] = result;
      this.fileUploaded.emit(imgData);
    });
  }

  onFileDelete() {
    let warningnotRequired:boolean=true; 
    if(this.requiredWarningPopup)
    {
      this.captions.removepopup?(this.requiredWarningMsg=this.captions.removepopup):(this.requiredWarningMsg='');
      this.utilities.showCommonAlert(this.requiredWarningMsg, AlertType.Confirmation, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES)
        {
          this.ImageUploaded = false;
          this.fileDeleted.emit();
        }
       
      });
    }   
    else{
    this.ImageUploaded = false;
    this.fileDeleted.emit();
  }
}
  }
