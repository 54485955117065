<h4 class="golf--group--header" *ngIf="formFieldHeader">{{formFieldHeader}}</h4>
<section *ngIf="fieldType == 'MultipleData'" class="textarea agilysys-lib-text-area">
    <div [formGroup]="form" #formTextArea>
      <div formArrayName="{{formArrayName}}">
        <div *ngFor="let formObj of form.get(formArrayName).controls; let j= index;let l= last;">
          <div>
            <mat-form-field>
              <textarea matInput [placeholder]="field.fieldType.MultipleData.data[j].label" 
                [name]="j" [id]="j" [formControlName]="j" (blur)="eventCallback(j)"></textarea>
                <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
            </mat-form-field>
            <app-form-icon *ngIf="field.fieldType.MultipleData.addMore && j !== 0" [objectIndex]="j" [formIconType]="formRemoveBuilderIcons"
            [formArray]="form.get(formArrayName)" [formObject]="formArrayName" (iconEmitter)="iconCallback($event, form)">
          </app-form-icon>
          <app-form-icon class="golf--add-more"
            *ngIf="field.fieldType.MultipleData.addMore && j == (form.get(formArrayName).controls.length-1) && field.fieldType.MultipleData.maxCount >= form.get(formArrayName).controls.length"
            [formIconType]="formAddBuilderIcons" [formArray]="form.get(formArrayName)" [formObject]="field.fieldType.MultipleData.data[j]"
            (iconEmitter)="iconCallback($event)" (fielArrayEmiter)="fieldArrayCallback($event)">
          </app-form-icon>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <section class="textarea agilysys-lib-text-area" *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'">
    <div [formGroup]="form" #formTextArea>
      <mat-form-field title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
        form.controls[this.formFieldName]?.value : formFieldToolTip}}" class="textarea__input" appearance="fill" [ngClass]="field.fieldType[fieldType].disbaledProperty ? 'disable-texarea' : ''">
        <mat-label>{{formFieldlabel}}</mat-label>
        <textarea matInput [required]="errorLength" [placeholder]="formFieldlabel" [name]="formFieldName" [id]="formFieldName" 
        [formControlName]="formFieldName" (blur)="eventCallback()" [maxlength]="field.maxCount > 0 ? field.maxCount : ''"></textarea>
        <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
        <mat-hint *ngIf="field.maxCount > 0"><span>Max {{field.maxCount}} Characters</span><span>{{form.controls[formFieldName].value?.length || 0}}/{{field.maxCount}}</span></mat-hint>
        <mat-hint *ngIf="field.maxCount == 0"><span>Max Characters</span></mat-hint>
      </mat-form-field>
      <i  *ngIf="field.EnableSuffixicon" matSuffix class="agilysys-suffix-icons icon-{{field.iconName}}" (click)="suffixIconClick()"></i>
    </div>
  </section>