import { FormType, SingleField } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { CustomDefaultSelectConfig, FormBuilderGroupKey, MasterInventoryManagement, RestDataCollection, RestDataConfiguration } from '../master.interface';
import { FormModelFieldBuilder } from './form-config.interface';
import { FilterTypes } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { CustomFilterQuery } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import moment from 'moment';
import { GlobalCustomFormAction } from 'src/app/eatecui/source/shared/models/custom-action/global-custom-action.interface';
import { ActionTypes } from 'src/app/eatecui/source/shared/models/custom-action/custom-enum.interface';
import { RadioOptions } from 'src/app/eatecui/source/shared/models/form-models/form-type.entity';
import { TranslateService } from '@ngx-translate/core';
import { CustomClientFilter } from 'src/app/eatecui/source/shared/models/custom-action/actions/custom-clientFilter.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { OptionListMapping } from 'src/app/eatecui/source/shared/nonodatacore/data-mapping/option-list-mapping';
import { OptionListConfiguration } from 'src/app/eatecui/source/shared/nonodatacore/models/data-mapping.interface';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { BaseDataMapping } from 'src/app/eatecui/source/shared/nonodatacore/data-mapping/base-data-mapping';
import * as constants from '@shared/constant/GlobalConstants';

export const FormFieldOptions: FormModelFieldBuilder = (
    ViewModelEntity: any,
    PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    translateService: TranslateService,
    CustomData?: any,
    SystemPreferenceService?: SysytemPreferenceService,
    masterService?: any,
    customBuilderData?: any,
    toasterService?: ToastrService,
    selectedValue?: any,
    MasterHomeGroup?: FormGroup,
    FormGroupSingleData?: SingleField,
    FormBuilder?: any
): any => {
    try {
        let radioOptionlist: RadioOptions[] = [];
        const autocompleteOptionsList = [];
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0) {
            let requestUrl = null;
            if (ViewModelEntity[0]['NavigationProperty']) {
                const postModelData = ViewModelEntity[0]['NavigationProperty']
                    .filter(x => x['ReferentialConstraint'] && FieldGrouping.OdataKey === x['ReferentialConstraint'][0]['$'].Property);
                if (postModelData.length > 0) {
                    const keyName = postModelData[0]['$']['Type'].split('.')[7];
                    if (keyName) {
                        requestUrl = `/inventory/api/${keyName}`;
                    }
                }
            }
            if (FieldGrouping.hasOwnProperty('CustomOdataUrl')) {
                requestUrl = FieldGrouping.CustomOdataUrl;
            }
            if (FieldGrouping.hasOwnProperty('CustomFilterQuery') && requestUrl) {
                const query = getFilterQuery(FieldGrouping.CustomFilterQuery);
                if (query) {
                    requestUrl = requestUrl + `?$filter=${query}`;
                }
            }
            // Added this for passing select fields in $select odata query
            if (FieldGrouping.hasOwnProperty('CustomSelectQuery') && requestUrl) {
                if (FieldGrouping.hasOwnProperty('CustomFilterQuery') && FieldGrouping.CustomFilterQuery) {
                    requestUrl = requestUrl + '&' + FieldGrouping.CustomSelectQuery;
                } else {
                    requestUrl = requestUrl + '?' + FieldGrouping.CustomSelectQuery;
                }
            }
            if (FieldGrouping.hasOwnProperty('CustomFilterSort') && requestUrl) {
                if (!FieldGrouping.hasOwnProperty('CustomFilterQuery') && !FieldGrouping.hasOwnProperty('CustomSelectQuery')) {
                    requestUrl = requestUrl + `?$orderby=${FieldGrouping.CustomFilterSort.SortKey}` + ` ${FieldGrouping.CustomFilterSort.SortBy}`;
                } else {
                    requestUrl = requestUrl + `&$orderby=${FieldGrouping.CustomFilterSort.SortKey}` + ` ${FieldGrouping.CustomFilterSort.SortBy}`;
                }
            }
            if (!FieldGrouping.hasOwnProperty('CustomOdataUrl')) {
                const getReturnValue = GlobalCustomFormAction(FieldGrouping.Name, FieldGrouping.OdataKey,
                    FieldGrouping.OdataKey, 'FormAction', ActionTypes.ADDCUSTOMURL);
                if (getReturnValue && getReturnValue !== null) {
                    requestUrl = getReturnValue;
                }
            }
            if (MasterDataCollection && MasterDataCollection.hasOwnProperty('MasterRestDataCollection')) {
                const restFilterData: Array<RestDataConfiguration> = MasterDataCollection.MasterRestDataCollection.RestDataConfig
                    .filter((rest: RestDataConfiguration) => rest.DataKeyName.findIndex(x => x === FieldGrouping.OdataKey) > -1);
                if (restFilterData && restFilterData.length > 0) {
                    if (MasterDataCollection.MasterRestDataCollection.RestDataCollection.length > 0) {
                        MasterDataCollection.MasterRestDataCollection.RestDataCollection.forEach((collection: RestDataCollection) => {
                            const getCollectionName = restFilterData[0].Name;
                            if (collection.hasOwnProperty(getCollectionName)) {
                                requestUrl = null;
                                const customFilter = CustomClientFilter(collection, FieldGrouping, getCollectionName);
                                radioOptionlist = [...customFilter];
                                if (restFilterData[0].hasOwnProperty('RestSetFirstValue') && restFilterData[0].RestSetFirstValue) {
                                    const FirstKey = radioOptionlist[0].key;
                                    FormGroupSingleData.value = FirstKey;
                                }
                            }
                        });
                    }
                }
            }
            if (FieldGrouping.FieldType === 'multipleautocomplete') {
                requestUrl = requestUrl + `&$top=${InfiniteScrollItemCount}` + `&$count=true`;
            }
            if (FieldGrouping.hasOwnProperty('DynamicParamsKey') && FieldGrouping.hasOwnProperty('CustomOdataUrl')) {
                let IngredientId: any;
                const IngId = selectedValue[0][FieldGrouping.DynamicParamsKey] !== undefined &&
                    selectedValue[0][FieldGrouping.DynamicParamsKey] !== '' ? selectedValue[0][FieldGrouping.DynamicParamsKey] :
                    selectedValue[0]['IngredientId'] ? selectedValue[0]['IngredientId'] : selectedValue[0]['Id'];
                IngredientId = '/' + IngId;
                requestUrl = FieldGrouping.CustomOdataUrl + IngredientId;
            }
            let methodType = 'GethttpMethod';
            let authPayload = {} as any;
            authPayload.ClientUrl = requestUrl;
            authPayload.Payload = {};
            if (FieldGrouping.hasOwnProperty('DisableRequest')) {
                if (FieldGrouping.DisableRequest) {
                    authPayload.ClientUrl = null;
                }
            }
            if (FieldGrouping.StoreProcedureConFig && FieldGrouping.StoreProcedureConFig.EnableStoreProcedure) {
                authPayload.ClientUrl = null;
            }
            if( FieldGrouping && FieldGrouping.FieldDataCollection && FieldGrouping.FieldDataCollection.EnabledFieldCollection){
                requestUrl = null;
                authPayload.ClientUrl = null;
            }
            if (authPayload.ClientUrl !== null) {
                httpService[methodType](authPayload.ClientUrl, authPayload.Payload).subscribe((response: any) => {
                    const optionListResponse = (response.hasOwnProperty('value') && response.value !== 'null') ?
                    response.value : (response.hasOwnProperty('ResultData') && response.ResultData !== 'null') ? response.ResultData : response;
                    const mappedlist = optionListResponse.map((data: any) => {
                        const checkActiveBool = (data.hasOwnProperty('IsActive')) ? (data.IsActive) ? true : false : true;
                        if (checkActiveBool) {
                            const valueAssign = {} as RadioOptions;
                            const autoCompleteValues = {} as any;
                            valueAssign.label = data.hasOwnProperty('Name') ? data['Name'] : data['Code'];
                            const returnValue = GlobalCustomFormAction(valueAssign.label, FieldGrouping.OdataKey, 'Name', 'FormAction',
                                ActionTypes.CUSTOMMERGE, null, null, null, null, null, null, data);
                            if (returnValue && returnValue !== null) {
                                valueAssign.label = returnValue;
                            }
                            if (FieldGrouping.EdmType === 'Edm.String') {
                                valueAssign.key = data['Id'].toString();
                            } else {
                                valueAssign.key = data['Id'];
                            }
                            if (FieldGrouping.FieldType === 'multipleautocomplete') {
                                autoCompleteValues.Id = data['Id'];
                                autoCompleteValues.Name = data['Name'];
                            }
                            if (FieldGrouping.FieldType === 'multipleautocomplete') {
                                autocompleteOptionsList.push(autoCompleteValues);
                            } else {
                                radioOptionlist.push(valueAssign);
                            }
                            if (FieldGrouping.FieldType === 'multipleautocomplete') {
                                FormGroupSingleData.autoCompleteData = [];
                                FormGroupSingleData.autoCompleteData = [...autocompleteOptionsList];
                                if (FormBuilder && FormBuilder.hasOwnProperty('autoCompleteConfig')) {
                                    FormBuilder['autoCompleteConfig'].EnableLoadMore =
                                        ((response['count'] === response['value']?.length) || (response['count'] === response['ResultData']?.length))
                                            ? false : true;
                                } else if (FieldGrouping && FieldGrouping.hasOwnProperty('autoCompleteConfig')) {
                                    FieldGrouping.autoCompleteConfig.EnableLoadMore =
                                        (response['count'] ===  response['value'].length)
                                            ? false : true;
                                }

                            }
                            if (FieldGrouping && FieldGrouping.OdataKey === 'MealPeriodsId' && data.IsDefault) {
                                if (MasterDataCollection) {
                                    if (MasterDataCollection['CreateTranscationConfig']['TFromGroupControl']) {
                                        MasterDataCollection['CreateTranscationConfig']['TFromGroupControl']['controls']['MealPeriodsId'].setValue(data.Id)
                                    }
                                }
                            }
                        }
                    });
                    if (FieldGrouping && FieldGrouping.hasOwnProperty('CustomDefaultSelect') &&
                        FieldGrouping.CustomDefaultSelect.length > 0) {
                        FieldGrouping.CustomDefaultSelect.forEach((customselect: CustomDefaultSelectConfig) => {
                            if (optionListResponse && optionListResponse !== null &&
                                optionListResponse.length > 0 && optionListResponse !== 'null') {
                                const getCustomValue: Array<any> = optionListResponse.filter((responseOption: any) =>
                                    responseOption[customselect.FilteredKey] === customselect.FilteredValue);
                                if (getCustomValue && getCustomValue.length > 0) {
                                    const defaultValue = getCustomValue[0][customselect.FilteredGetKey];
                                    FieldGrouping.Value = defaultValue;
                                    customselect.FileteredSetValue = defaultValue;
                                    customselect.IsDefaultValue = true;
                                    customselect.FilterValueCode = `${getCustomValue[0]['Name']}(` + `${getCustomValue[0]['Code']})`;
                                    MasterHomeGroup.controls[FieldGrouping.OdataKey].setValue(defaultValue);
                                } else {
                                    const defaultValue = optionListResponse[0][customselect.FilteredGetKey];
                                    FieldGrouping.Value = defaultValue;
                                    customselect.FileteredSetValue = defaultValue;
                                    customselect.IsDefaultValue = false;
                                    customselect.FilterValueCode = `${optionListResponse[0]['Name']}(` + `${optionListResponse[0]['Code']})`;
                                }
                            }

                        });
                    }
                    return radioOptionlist;
                },
                    (responseError) => {
                        console.error('error caught in  component', responseError);
                    });
            } else if (FieldGrouping.StoreProcedureConFig && FieldGrouping.StoreProcedureConFig.EnableStoreProcedure) {
                const EnpointUrl = CONSTANT.ENDPOINTURL['InvertoryManagement'][FieldGrouping.StoreProcedureConFig.ReguestUrlKey];
                const optionConfiguration = {} as OptionListConfiguration;
                optionConfiguration.FormGroupSingleData = null;
                optionConfiguration.HttpType = FieldGrouping.StoreProcedureConFig.HttpType;
                optionConfiguration.httpService = httpService;
                optionConfiguration.RequestEndPointUrl = EnpointUrl;
                optionConfiguration.TransactionType = FieldGrouping.StoreProcedureConFig.TransactionType;
                optionConfiguration.FromLocation = FieldGrouping.StoreProcedureConFig.IsFromLocation;
                optionConfiguration.LoggedInLocationId = JSON.parse(sessionStorage.getItem('FromLocId'));
                optionConfiguration.CustomPayload = FieldGrouping.StoreProcedureConFig?.CustomPayload;
                optionConfiguration.IsDateIncluded = FieldGrouping.StoreProcedureConFig?.IsDateIncluded;           
                if (MasterDataCollection['CreateTranscationConfig'] && MasterDataCollection['CreateTranscationConfig']['flowFrom'] && 
                MasterDataCollection['CreateTranscationConfig']['flowFrom'] === 'edit') {
                    if (MasterDataCollection['Name'] === 'Events') {
                        optionConfiguration.CustomPayload = {
                            statusIds: [1,2,3]
                        };
                    }
                }
                optionConfiguration.FetchAll = true;
                optionConfiguration.Responsecallback = (responseData: Array<any>) => {
                    const resultData: Array<RadioOptions> = BaseDataMapping.OptionListDataMapping(responseData, FieldGrouping);
                    resultData.forEach((e: RadioOptions) => {
                        radioOptionlist.push(e);
                    })
                }
                const optionListMapping = new OptionListMapping(optionConfiguration);
                optionListMapping.DataMapping();
            } else {
                if (FieldGrouping.CustomOptions) {
                    FieldGrouping.CustomOptions.forEach(res => {
                        if (FieldGrouping.EdmType === 'Edm.String' && FieldGrouping.FieldType !== 'radio') {
                            res.key = res['key'].toString();
                        }
                        if (FieldGrouping.FieldType === 'radio') {
                            res.label = translateService.instant('Common.' + res.label);
                        }
                        radioOptionlist.push(res);
                    });
                }
            }
        }
        return radioOptionlist;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function getFilterQuery(CustomFilterQuerys: Array<CustomFilterQuery>) {
    if (CustomFilterQuerys.length > 0) {
        const queryList = [];
        const LoginSiteId = JSON.parse(sessionStorage.getItem('LoggedInSiteId'));
        CustomFilterQuerys.forEach((querys: CustomFilterQuery) => {
            let querystring = '';
            switch (querys.Type) {
                case FilterTypes.Multiple: {
                    let value = '';
                    if (querys.CustomOdataUrlName) {
                        value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                        if (value && querys.Name) {
                            querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                        } else if (value) {
                            querystring = querys.OdataKey + ' in (' + value + ')';
                        }
                        if (LoginSiteId === 1 && querys.hasOwnProperty('CheckInterco') && querys.CheckInterco) {
                            querystring = `((${querystring}`;
                        }
                    } else {
                        if (querys.Name === querys.OdataKey) {
                            value = querys.DefaultValue.join();
                            querystring = querys.OdataKey + ' in (' + value + ')';
                        } else {
                            value = querys.DefaultValue.join();
                            querystring = querys.Name + '/' + querys.OdataKey + ' in (' + value + ')';
                            if (LoginSiteId === 1 && querys.hasOwnProperty('CheckInterco') && querys.CheckInterco) {
                                querystring = `${querystring}) or LocationTypes/Id in (8))`;
                            }
                        }
                    }
                    break;
                }
                case FilterTypes.GreaterThanEqual: {
                    if (querys.FieldType === FieldEdmType.EDMDATEOFFSET) {
                        // const date = new Date();
                        // Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
                        //     date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
                        // const utcdate = moment.utc().format();
                        querystring = querys.OdataKey + ' ge ' + moment().startOf('day').format(constants.PostDateTimeFormat);
                    } else {
                        querystring = querys.OdataKey + ' ge ' + querys.DefaultValue;
                    }
                    break;
                }
                case FilterTypes.Equal: {
                    if (querys.CustomOdataUrlName) {
                        const value = JSON.parse(sessionStorage.getItem(querys.CustomOdataUrlName));
                        if (value) {
                            querystring = querys.OdataKey + ' eq ' + value;
                        }
                    } else if (querys.ExpandName) {
                        querystring = querys.ExpandName + '/' + querys.OdataKey + ' eq ' + querys.DefaultValue;
                    } else {
                        querystring = querys.OdataKey + ' eq ' + querys.DefaultValue;
                    }
                    break;
                }
                case FilterTypes.NotEqual: {
                    // querystring = 'not(' + query.OdataKey + ' eq ' + query.DefaultValue + ')';
                    querystring = querys.OdataKey + ' ne ' + querys.DefaultValue;
                    break;
                }
            }
            if (querystring) {
                queryList.push(querystring);
            }
        });
        const returnData = queryList.join(' and ');
        return returnData;
    }
}