import {  Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputActionPlaceHolder, ButtonValue, AgToggleConfig, HeaderInput } from '../../Models/ag-models';

import { AlertType, CodeSetups } from '../../shared/shared/enums/enums';
import {  AlertAction } from '../../shared/shared.modal';
import { Localization } from '../../localization/localization';
import { CommonUtilities,InputTypeText } from '../../shared/shared/utilities/common-utilities';
import { ButtonTypes } from '../../Models/common.models';
import { Product } from 'src/app/common/shared/shared/globalsContant';

@Component({
  selector: 'app-single-input-header-action',
  templateUrl: './single-input-header-action.component.html',
  styleUrls: ['./single-input-header-action.component.scss']
   
})
export class SingleInputHeaderActionComponent implements OnInit, OnDestroy {
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  ActionForm: UntypedFormGroup;
  captions: any;
  isUpdate: boolean;
  updateCaption: any;
  toggleInput: AgToggleConfig;
  name: string;
  active: string;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);    
  nameMinLength: number = 1; 
  nameMaxLength: number = 50;
  nameInputType:string;
  propertyId:number;
  floatLabel: string;
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Input() validationType: string;
  @Input() inputPlaceholder: InputActionPlaceHolder;
  @Input('minLength')
  set minimumFieldLength(value:number){
    this.nameMinLength = value;
  }
  @Input('maxLength')
  set maximumFieldLength(value:number){
    this.nameMaxLength = value;
  }
  @Input() inputFor: CodeSetups;
  @Input() cancelWarningMessage: string;
  @Input('clearInput')
  set clearFormInputs(value) {
    if (value) {
      this.clearForm();
    }
  } 
 

  @Input('disabled')
  set setDisabled(value: boolean) {
    if (value) {
      this.ActionForm.controls.name.disable();
      this.cancelButton.disabledproperty = value;
    } else {
      this.ActionForm.controls.name.enable();
      this.cancelButton.disabledproperty = value;
    }
  }


  @Input('editActionObj')
  set ActionObject(value: HeaderInput) {
   
    this.isUpdate = false;
    if (value) {
      this.isUpdate = value.toggleShow;
      this.saveButton.label = this.captions.btn_update;
      this.ActionForm.patchValue(value);
    }
    if (this.isUpdate) {
      this.toggleInput = {
        group: this.ActionForm,
        horizontal: false,
        label: 'Active',
        formControlName: 'isActive'
      }
    }
  }


  @Input('inputPlaceholder')
  set setPlaceHolder(value: InputActionPlaceHolder) {
    this.name = value.name;
  }


  constructor(private formBuilder: UntypedFormBuilder, private _Localization: Localization, 

    private _utilities: CommonUtilities) {
    this.captions = this._Localization.captions;
    this.floatLabel = this._Localization.setFloatLabel;
    this.propertyId = parseInt(this._Localization.GetPropertyInfo('ProductId'));
    let regexPattern = this.propertyId == Product.PMS ? "" : "[a-zA-Z0-9 ]{" + 1 + "," + 100 + "}$";
    this.nameInputType = this.propertyId == Product.PMS ? InputTypeText.NAMEINPUTTYPE : 'nospecailchar';
    this.ActionForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(regexPattern), Validators.maxLength(this.nameMaxLength), Validators.minLength(this.nameMinLength)]],
      isActive: true,
      id: 0
    });

    this.toggleInput = {
      group: this.ActionForm,
      horizontal: false,
      label: 'Active',
      formControlName: 'isActive'
    }

    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_add,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }

  ngOnInit() {    
    this.ActionForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.saveButton.disabledproperty = !(this.ActionForm.dirty && this.ActionForm.valid);
    });    
    this.formReady.emit(this.ActionForm);
  }
  

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  } 

  onSave(event) {    
    this.save.emit(this.ActionForm.value);
    this.clearForm();
  }

  onCancel(arg) {
    if (this.ActionForm.dirty) {
      const _cancelWarningMessage = this.cancelWarningMessage?this.cancelWarningMessage:this.captions.lbl_unsavedData;
      this._utilities.showCommonAlert(_cancelWarningMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.leaveChanges();
        }
      });
    } else {
      this.leaveChanges();
    }
  }

  leaveChanges() {
    this.clearForm();
    this.cancel.emit(this.ActionForm.value);
  }

  private clearForm() {
    this.isUpdate = false;
    this.saveButton.label = this.captions.btn_add;
    this.ActionForm.reset({
      isActive: true,
      id: 0,
      listOrder: 0
    });
  }
}
