import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import {  HttpServiceCall } from '../../../../shared/service/http-call.service';
import { ReportDataService } from '../../../data/report-data.services';
@Component({
  selector: 'app-sales-by-subcategory',
  templateUrl: './sales-by-subcategory.component.html',
  styleUrls: ['./sales-by-subcategory.component.scss']
})
export class SalesBySubcategoryComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  SubCategoryFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  public outlets:any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any;
  categoryGroupSelected : any[] = [];
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, public business: ReportBusinessService, private http: HttpServiceCall, private dataService: ReportDataService) { }

  ngOnInit() {
    this.radioFilter = [{ id: 0, name: 'Detail By Register' }, { id: 1, name: 'Summary By Register' }, { id: 2, name: 'Detail By Outlet' }, { id: 3, name: 'Summary By Outlet' }];
    this.SubCategoryFormGrp = this.fb.group({
      allreportcategory: '',
      reportcategory: new UntypedFormControl(),
      category: new UntypedFormGroup({}),
      itemFilter: 0
    });
    this.formReady.emit(this.SubCategoryFormGrp);
    this.onLoad();
  }

  private async onLoad() {
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.business.FilterDataSource['allCategoryGroups'] = this.allCategoryGroups;
    
  } 

  formInitialized(form: UntypedFormGroup) {
    this.SubCategoryFormGrp.setControl('category', form);
  }


  getFormControlValue(event) {
    if (event[0] === 'reportcategory') {
    this.SubCategoryFormGrp.setControl('reportcategory', event[1]);
    this.SubCategoryFormGrp.setControl('allreportcategory', event[1]);
    }
  }


  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }
}
