import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';


@Component({
    selector: 'lib-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class ChipListComponent implements OnInit, AfterViewInit, OnDestroy {
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [13, 188];
    chipListCtrl = new FormControl('');
    chipLists: string[] = [];
    filteredChips: Observable<string[]>;
    allChipList: string[] = [];
    typeField = 'text';
    formFieldName: any;
    fieldType: any;
    formFieldlabel: any;
    formArrayName: any;
    formFieldHeader: string;
    formFieldToolTip: string;
    chipListPopup = false;
    addOnBlur = true; // For focus out, the text should be considered as chip
    @Input() field: any = {};
    @Input() form: FormGroup;
    @ViewChild('chipListInput') chipListInputs: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    constructor(
        public agyDynamicFormServices: AgyDynamicFormServices,
        private dialog: MatDialog,
    ) {
        this.chipListCtrl.valueChanges.pipe(
            startWith(null),
            map((chip: string | null) =>
              chip ? this._filter(chip) : this.allChipList.slice()
            )
          );
    }
    focusOut(): void {
        if (this.form) {
            this.form.controls[this.formFieldName].setValue(this.chipLists, { emitEvent: true });
            this.form.updateValueAndValidity();
        }
    }
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
          this.chipLists.push(value.trim());
        }
        if (input) {
          input.value = '';
        }
        this.chipListCtrl.setValue(null);
      }
      remove(fruit: string): void {
        const index = this.chipLists.indexOf(fruit);
        if (index >= 0) {
          this.chipLists.splice(index, 1);
        }
      }
      selected(event: MatAutocompleteSelectedEvent): void {
        this.chipLists.push(event.option.viewValue);
        this.chipListInputs.nativeElement.value = '';
        this.chipListCtrl.setValue(null);
      }
      private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.allChipList.filter(chip =>
             chip.toLowerCase().indexOf(filterValue) === 0);
      }
    ngOnInit(): void {
        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.agyDynamicFormServices.generateFormFieldName(
        this.field
        );
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formFieldHeader = formField.fieldHeader;
        this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip
        ?.EnableToolTip
        ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText
        : null;
        this.chipLists = this.field.fieldType[this.fieldType].value ?
         this.field.fieldType[this.fieldType].value : [];
    }
    ngAfterViewInit(): void {
        this.form.controls[this.formFieldName].valueChanges.subscribe(x => {
            if (this.chipLists.length === 0) {
                this.chipLists = (this.form.value[this.formFieldName] && this.form.value[this.formFieldName].length > 0) ?
                this.form.value[this.formFieldName] : x;
            }
        });
    }
    ngOnDestroy(): void {
        console.log('On Destroy');
    }
    openPopup(templateRef: any): void {
        this.chipListPopup = true;
        const dialogRef = this.dialog.open(templateRef, {
          width: '500px',
          height: '500px',
        });
      }
    }