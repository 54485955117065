import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { RetailService } from '../retail.service';
import { AlertMessagePopupComponent } from '../shared/alert-message-popup/alert-message-popup.component';
import { BaseResponse, OutletSubProperty } from '../shared/business/shared.modals';
import { ButtonType, Host, Modules } from '../shared/globalsContant';
import { HttpMethod } from '../shared/service/http-call.service';
import { Inventory } from './inventory.modals';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { RetailLocalization } from '../common/localization/retail-localization';
import { RetailFeatureFlagInformationService, ConfigKeys, FeatureName } from '../shared/service/retail.feature.flag.information.service';
import { AlertType } from '../shared/shared.modal';
import { InventoryActionType, InventoryCountStatus, InventoryCountType, InventoryCountUpdateType, InventoryOutletStatus, InventoryService } from './inventory.service';
import { OutletInventoryService } from './outlet-inventory.service';
import { PropertyFeature } from '../shared/business/property-features.model';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-retail-inventory-setting',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [OutletInventoryService , RetailFunctionalityBusiness]
})
export class RetailInventorySettingComponent implements OnInit {
  outlets: any;
  defaultOutlet: any;
  tableOptions: any = [];
  searchText = '';
  inventoryCaptions: any;
  commonCaptions: any;
  inventoryStatus: any;
  isActive = true;
  isManualAvailable = false;
  isSaveAvailable = false;
  isScannedQtyEnabled = false;
  isScannedInputAvailable = false;
  outletItemsList: any = [];
  selectedOutlet: number;
  categories: any[] = [];
  hasUnsavedQty = false;
  countId = 0;
  showOnHandQty = true;
  hasItem = false;
  initialLoads = true;
  callCounter = 0;
  isInventorySyncEnabled = false;
  isSyncing = false;
  showInactive = false;
  InventorySetupFormGrp: UntypedFormGroup;
  isInActive = false;
  selectedCategory = 0;
  selectedSubCategory1 = 0;
  selectedSubCategory2 = 0;
  selectedSubCategory3 = 0;
  selectedSubCategory4 = 0;
  selectedSubCategory5 = 0;
  enableAdvSearch: boolean;
  subCategoryOne: Array<any> = [];
  subCategoryTwo: Array<any> = [];
  subCategoryThree: Array<any> = [];
  subCategoryFour: Array<any> = [];
  subCategoryFive: Array<any> = [];
  categoriesClone: Array<any> = [];
  subCategoryOneClone: Array<any> = [];
  subCategoryTwoClone: Array<any> = [];
  subCategoryThreeClone: Array<any> = [];
  subCategoryFourClone: Array<any> = [];
  subCategoryFiveClone: Array<any> = [];
  selectedCategoryArray: Array<any> = [];
  showProgress: boolean;
  captions: any;
  floatLabel: string;
  floatLabelNever: string;

  pageStart = 0;
  pageLength = 20;
  sortBy = 0;
  isSortByAscending = true;
  isLoading = false;

  sortables = ['itemNumber', 'itemDescription'];
  notifier: Subject<void> = new Subject<void>();
  doneAdvSearch: boolean;
  isScrollPositionReset = false;
  functionalities: { [key: string]: boolean} = {};
  ShowSelectOutletOption = true;
  defaultPMSOutlet: number;
  itemData: Inventory.InventoryCountUpdateQuantity[] = [];
  isSaveClicked = false;

  constructor( public dialog: MatDialog, private utils: RetailUtilities,
    private inventoryService: InventoryService, public localization: RetailLocalization,
    private rs: RetailService, private _featureFlagService: RetailFeatureFlagInformationService,
    private func: RetailFunctionalityBusiness, public propertyInfo: RetailPropertyInformation, private fb: UntypedFormBuilder
  ) {
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.inventoryCaptions = this.localization.captions.retailInventory;
    this.commonCaptions = this.localization.captions.common;
    this.captions = this.localization.captions.retailsetup;
    this.rs.tabLoaderEnable.next(true);
    this.defaultPMSOutlet = this.propertyInfo.GetDefaultOutlet();
    this.InventorySetupFormGrp = new UntypedFormGroup({
      shownIactiveRecords: new UntypedFormControl(false)
    });
  }

  ngOnInit() {
    this.SetEatecFeatureConfigurationFlags();
    this.func.getRetailFunctionality().then(res => {
    this.functionalities = res;
    this.ShowSelectOutletOption = this.functionalities.ShowInventorySelectOutletOption;
    [this.initialLoads, this.callCounter] = this.rs.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this.GetAllOutlets();
    [this.initialLoads, this.callCounter] = this.rs.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this.GetAllCategories();
    });
  }

  async GetAllOutlets() {
    if (!this.ShowSelectOutletOption && this.defaultPMSOutlet === 0) {
      this.defaultPMSOutlet = await this.func.getDefaultOutlet();
      if (this.defaultPMSOutlet > 0 ) {
        this.propertyInfo.SetDefaultOutlet(this.defaultPMSOutlet);
      }
    }
    this.outlets = [];
    let userId: number = Number(this.utils.GetPropertyInfo("UserId"));
    userId = userId ? userId : 1;
    const apiResponse: BaseResponse<OutletSubProperty[]> = await this.inventoryService.InvokeServiceCallAsync('GetSubPropertyAccessByUser', Host.retailManagement, HttpMethod.Get, { userId: userId });
    [this.initialLoads, this.callCounter] = this.rs.updateInitalLoads(true, this.initialLoads, this.callCounter);
    if (apiResponse && apiResponse.successStatus && apiResponse.result && apiResponse.result.length > 0) {
      apiResponse.result.forEach(e => {
        if (e.isActive) {
          this.outlets.push({ id: e.subPropertyID, outletName: e.subPropertyName });
        }
      });
      this.defaultOutlet = !this.ShowSelectOutletOption && this.defaultPMSOutlet > 0  ? this.defaultPMSOutlet : this.outlets[0].id ;
      this.ResetFlags();
      this.GetItemsForSelectedOutlet();
    }
  }

  async GetAllCategories() {
    const apiResponse: BaseResponse<any> = await this.inventoryService.InvokeServiceCallAsync('GetCategoryAndSubCategoryLink', Host.retailManagement, HttpMethod.Get);
    [this.initialLoads, this.callCounter] = this.rs.updateInitalLoads(true, this.initialLoads, this.callCounter);
    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      this.BuildCategories(apiResponse.result);
    }
  }

  shownIactiveRecords(event: any) {
    this.isInActive =event[0];
    this.InventorySetupFormGrp.controls.shownIactiveRecords.setValue(event[0]);
    this.GetItemsForSelectedOutlet();
  }

  async SetEatecFeatureConfigurationFlags() {
    const featureNames = [({ featureName: FeatureName.EnhancedInventory, moduleId: Modules.PAYMENT } as PropertyFeature)];
    const featureconfigurations = await this._featureFlagService.GetFeatureConfigurations(featureNames);
   
    if (featureconfigurations != null) {
      const eatecUser = featureconfigurations.find(f => f && f.configurationKey === ConfigKeys.Eatec.EatecTenantUser);
      const uri = featureconfigurations.find(f => f && f.configurationKey === ConfigKeys.Eatec.EatecInventoryServiceURI);
      if (eatecUser && eatecUser.configurationValue && uri && uri.configurationValue) {
        this.isInventorySyncEnabled = true;
      }
    } else {
      this.isInventorySyncEnabled = false;
    }
  }

  BuildCategories(response: any) {
    this.categories = [];
    this.categories = response.category ? this.utils.sortObj(response.category, 'listOrder') : [];
    this.subCategoryOne = response.subCategoryOne ? this.utils.sortObj(response.subCategoryOne, 'listOrder') : [];
    this.subCategoryTwo = response.subCategoryTwo ? this.utils.sortObj(response.subCategoryTwo, 'listOrder') : [];
    this.subCategoryThree = response.subCategoryThree ? this.utils.sortObj(response.subCategoryThree, 'listOrder') : [];
    this.subCategoryFour = response.subCategoryFour ? this.utils.sortObj(response.subCategoryFour, 'listOrder') : [];
    this.subCategoryFive = response.subCategoryFive ? this.utils.sortObj(response.subCategoryFive, 'listOrder') : [];
    this.categoriesClone = _.cloneDeep(this.categories);
    this.subCategoryOneClone = _.cloneDeep(this.subCategoryOne);
    this.subCategoryTwoClone = _.cloneDeep(this.subCategoryTwo);
    this.subCategoryThreeClone = _.cloneDeep(this.subCategoryThree);
    this.subCategoryFourClone = _.cloneDeep(this.subCategoryFour);
    this.subCategoryFiveClone = _.cloneDeep(this.subCategoryFive);
  }

  OnCategoryChange($event: any) {
    // clear category data before binding
    this.selectedCategory = 0;
    this.selectedSubCategory1 = 0;
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedCategory = $event.value;
    this.selectedCategoryArray = this.categories.filter(a => a.id === this.selectedCategory);
    this.subCategoryOne = this.subCategoryOneClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  OnSubCategory1Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory1 = 0;
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory1 = $event.value;
    this.selectedCategoryArray = this.subCategoryOneClone.filter(a => a.id === this.selectedSubCategory1);
    this.subCategoryTwo = this.subCategoryTwoClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  OnSubCategory2Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory2 = $event.value;
    this.selectedCategoryArray = this.subCategoryTwoClone.filter(a => a.id === this.selectedSubCategory2);
    this.subCategoryThree = this.subCategoryThreeClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  OnSubCategory3Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory3 = $event.value;
    this.selectedCategoryArray = this.subCategoryThreeClone.filter(a => a.id === this.selectedSubCategory3);
    this.subCategoryFour = this.subCategoryFourClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  OnSubCategory4Change($event: any) {
    // clear category data before binding
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    this.selectedSubCategory4 = $event.value;
    this.selectedCategoryArray = this.subCategoryFourClone.filter(a => a.id === this.selectedSubCategory4);
    this.subCategoryFive = this.subCategoryFiveClone.filter(a => {
      return this.selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id === a.id);
    });
  }

  async OuletChange(event) {
    this.utils.ToggleLoader(true);
    try {
      console.log(event.value);
      this.defaultOutlet = event.value;
      this.ResetFlags();
      this.categories.forEach(r => r.filtered = []);
      this.ResetFlags();
      await this.GetItemsForSelectedOutlet();
    } catch{}
    finally {
      this.utils.ToggleLoader(false);
    }
  }

  ResetFlags() {
    this.isActive = true;
    this.isManualAvailable = false;
    this.isSaveAvailable = false;
    this.isScannedQtyEnabled = false;
    this.isScannedInputAvailable = false;
    this.hasUnsavedQty = false;
    this.showOnHandQty = true;
  }

  toggleAdvancedSearch() {
    this.enableAdvSearch = true;
  }

  closeAdvanceSearch() {
    this.enableAdvSearch = false;

    this.selectedCategory = 0;
    this.selectedSubCategory1 = 0;
    this.selectedSubCategory2 = 0;
    this.selectedSubCategory3 = 0;
    this.selectedSubCategory4 = 0;
    this.selectedSubCategory5 = 0;

    if (this.doneAdvSearch) {
      this.doneAdvSearch = false;
      const timeOut = setTimeout(async () => {
        await this.searchByDetails();
        clearTimeout(timeOut);
      }, 100);
    }
  }

  clearFilter(){
    // to do
  }
  async clearSearchData()
  {
    this.searchText=''
    await this.GetItemsForSelectedOutlet();
  }
  async searchByDetails() {
    if(this.isSaveClicked == false && this.isScannedInputAvailable)
    {
      const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
        width: '400px',
        height: '300px',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data: {
          headername: this.commonCaptions.Warning,
          headerIcon: 'icon-warning-icon',
          headerMessage: this.commonCaptions.saveChangesMessage,
          buttonName: this.commonCaptions.Yes,
          noButton: true,
          noButtonName: this.commonCaptions.No,
          type: 'message'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result.toLowerCase() === this.commonCaptions.Yes.toLowerCase()) {
           await this.GetItemsForSelectedOutlet();
        }
      });
    }
    else{
      this.isSaveClicked = false;
    await this.GetItemsForSelectedOutlet();
    }
  }

  async SearchByCategories() {
    if(this.isSaveClicked == false && this.isScannedInputAvailable)
    {
      const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
        width: '400px',
        height: '300px',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data: {
          headername: this.commonCaptions.Warning,
          headerIcon: 'icon-warning-icon',
          headerMessage: this.commonCaptions.saveChangesMessage,
          buttonName: this.commonCaptions.Yes,
          noButton: true,
          noButtonName: this.commonCaptions.No,
          type: 'message'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(async result => {
        if (result.toLowerCase() === this.commonCaptions.Yes.toLowerCase()) {
          if (this.selectedCategory === 0 && this.selectedSubCategory1 === 0
            && this.selectedSubCategory2 === 0 && this.selectedSubCategory3 === 0
            && this.selectedSubCategory4 === 0 && this.selectedSubCategory5 === 0) {
            return;
          }
      
          this.doneAdvSearch = true;
           await this.GetItemsForSelectedOutlet();
       }
      });
    }
else{
    if (this.selectedCategory === 0 && this.selectedSubCategory1 === 0
      && this.selectedSubCategory2 === 0 && this.selectedSubCategory3 === 0
      && this.selectedSubCategory4 === 0 && this.selectedSubCategory5 === 0) {
      return;
    }
    this.isSaveClicked = false;
    this.doneAdvSearch = true;

    await this.GetItemsForSelectedOutlet();
  }
  }

  async GetItemsForSelectedOutlet() {
    this.utils.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);;
    this.pageStart = 0;

    this.isScrollPositionReset = true;
    this.cancelOngoingCall();

    // just load the inventory system status alone nothing more
    await this.loadInventoryStatus();

    // take the only retail items that are enabled with `UseInventory`
    const tableData = await this.fetchInventoryItems();
    this.hasItem = tableData && tableData.length > 0;

    this.tableOptions = [{
      headerData: this.inventoryService.GetInventoryHeader(this.showOnHandQty),
      bodyData: tableData,
      sortable: true,
      Sortable: 'Item',
      bufferCount: 10
    }];
    this.isScrollPositionReset = false;
    this.utils.ToggleLoader(false);
  }

  cancelOngoingCall() {
    this.notifier.next();
    this.notifier.complete();
  }

  private async loadInventoryStatus() {
    const apiResponse: BaseResponse<Inventory.InventoryItemModel> = await this.inventoryService.InvokeServiceCallAsync('GetInventoryOutletStatus',
      Host.retailManagement,
      HttpMethod.Get,
      { outletId: this.defaultOutlet });

    if (apiResponse && apiResponse.successStatus && apiResponse.result) {
      const status = apiResponse.result.outletStatus;
      const count = apiResponse.result.outletCount;
      this.countId = count ? count.id : 0;

      if (status && status.status === InventoryOutletStatus.FROZEN) {
        this.isActive = false;
        this.isManualAvailable = true;
        this.hasUnsavedQty = true;

        if (count) {
          if (count.countType === InventoryCountType.MANUAL) {
            this.isManualAvailable = false;
            this.isScannedInputAvailable = true;
            this.isScannedQtyEnabled = true;
            this.isSaveAvailable = true;
            this.showOnHandQty = false;
          } else {
            this.isSaveAvailable = false;
          }

          if (count.countStatus === InventoryCountStatus.COMPLETE) {
            this.isSaveAvailable = false;
            this.isScannedInputAvailable = false;
            this.isManualAvailable = false;
            this.hasUnsavedQty = false;
          }
        }
      }
    }
  }

  private async fetchInventoryItems() {

    this.notifier = new Subject<void>();
    const retailItems = await this.inventoryService.cancellableObservalble(
      this.notifier,
      'GetRetailInventoryItemByPagination',
      Host.retailManagement,
      HttpMethod.Get,
      undefined,
      {
        outletId: this.defaultOutlet,
        includeInactive: this.isInActive,
        pageStart: this.pageStart,
        pageLength: this.pageLength,
        sortBy: this.sortBy,
        isSortByAscending: this.isSortByAscending,
        searchText: this.searchText,
        category: this.selectedCategory,
        subcategory1: this.selectedSubCategory1,
        subcategory2: this.selectedSubCategory2,
        subcategory3: this.selectedSubCategory3,
        subcategory4: this.selectedSubCategory4,
        subcategory5: this.selectedSubCategory5,
        useInventory: true
      }).toPromise();

    this.pageStart += this.pageLength;
    return retailItems?.result || [];
  }
 
  async FreezeInventory() {
    if (!this.inventoryService.IsUserAuthorized(InventoryActionType.Freeze)) {
      return;
    }

    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '458px',
      height: '364px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.commonCaptions.Warning,
        headerIcon: 'icon-warning-icon',
        headerMessage: this.localization.getError(100300),
        buttonName: this.commonCaptions.Yes,
        noButton: true,
        noButtonName: this.commonCaptions.No,
        type: 'message'
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.FreezeCallBack(result);
    });

  }

  async FreezeCallBack(result: string) {
    if (result.toLowerCase() === this.commonCaptions.Yes.toLowerCase()) {
      const apiResponse = await this.inventoryService.InvokeServiceCallAsync('StartNewInventoryCount', Host.retailManagement, HttpMethod.Post, { outletId: this.defaultOutlet });
      if (apiResponse && apiResponse.successStatus && apiResponse.result) {
        this.isActive = false;
        this.isManualAvailable = true;
        this.countId = apiResponse.result.id;
        this.hasUnsavedQty = true;
      }
    }
  }

  async ReleaseFreeze() {
    if (!this.inventoryService.IsUserAuthorized(InventoryActionType.Release)) {
      return;
    }

    if (this.hasUnsavedQty) {
      const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
        width: '400px',
        height: '300px',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data: {
          headername: this.commonCaptions.Warning,
          headerIcon: 'icon-warning-icon',
          headerMessage: this.commonCaptions.saveChangesMessage,
          buttonName: this.commonCaptions.Yes,
          noButton: true,
          noButtonName: this.commonCaptions.No,
          type: 'message'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.toLowerCase() === this.commonCaptions.Yes.toLowerCase()) {
          this.ReleaseOutletFreeze();
        }
      });
    } else {
      this.ReleaseOutletFreeze();
    }
  }

  async ReleaseOutletFreeze() {
    const apiResponse = await this.inventoryService.InvokeServiceCallAsync('UpdateInventoryStatus',
      Host.retailManagement,
      HttpMethod.Patch,
      { outletId: this.defaultOutlet },
      InventoryOutletStatus.ACTIVE);

    if (apiResponse && apiResponse.successStatus) {
      this.categories.forEach(r => r.filtered = []);
      this.ResetFlags();
      this.GetItemsForSelectedOutlet();
    }
  }

  async openManualInventory(e) {
    if (!this.inventoryService.IsUserAuthorized(InventoryActionType.Manual)) {
      return;
    }

    const apiResponse: BaseResponse<any> = await this.inventoryService.InvokeServiceCallAsync('UpdateInventoryCountType',
      Host.retailManagement,
      HttpMethod.Put,
      { countId: this.countId },
      InventoryCountType.MANUAL);

    if (apiResponse && apiResponse.successStatus) {
      this.GetItemsForSelectedOutlet();
    }
  }

  openScanInventory(event) {
    console.log('Scan Inventory');
  }

  UpdateInventory(action: InventoryActionType) {

    if (action === InventoryActionType.FullUpdate || action === InventoryActionType.PartialUpdate) {
     
      if (!this.inventoryService.IsUserAuthorized(action)) {
        return;
      }
      const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
        width: '377px',
        height: '365px',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data: {
          headername: this.commonCaptions.Warning,
          headerIcon: 'icon-warning-icon',
          headerMessage: this.localization.getError(100301),
          buttonName: this.commonCaptions.Yes,
          noButton: true,
          noButtonName: this.commonCaptions.No,
          type: 'message'
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.UpdateInventoryCallBack(result, action);
      });
    } else {
      this.isSaveClicked = true;
      this.UpdateItemQty(action);
    }    
  }

  async UpdateInventoryCallBack(result: string, extraPrams: InventoryActionType) {
    if (result.toLowerCase() === this.commonCaptions.Yes.toLowerCase()) {
      this.UpdateItemQty(extraPrams);
    }
  }

  async UpdateItemQty(action: InventoryActionType) {
    let updateType = '';
    switch (action) {
      case InventoryActionType.FullUpdate:
        updateType = InventoryCountUpdateType.FULL;
        break;
      case InventoryActionType.PartialUpdate:
        updateType = InventoryCountUpdateType.PARTIAL;
        break;
      case InventoryActionType.TempUpdate:
        updateType = InventoryCountUpdateType.TEMP;
        break;
    }

    const updateInventoryData = this.tableOptions[0].bodyData;
   // const itemData: Inventory.InventoryCountUpdateQuantity[] = []; 
    if (updateInventoryData && updateInventoryData.length > 0) {
      updateInventoryData.forEach(e => {

        if (e.countedQuantity !== 0) {
         this.itemData.push(
            {
              itemId: e.id,
              updatedQuantity: e.countedQuantity
            }
          );
        }

      });

      const body: Inventory.InventoryCountUpdate = {
        updateType: updateType,
        itemQuantity: this.itemData
      };
      const apiResponse: BaseResponse<boolean> = await this.inventoryService.InvokeServiceCallAsync('UpdateInventoryCount',
        Host.retailManagement,
        HttpMethod.Put,
        { countId: this.countId },
        body);
      if (apiResponse && apiResponse.successStatus && apiResponse.result) {
        if (action === InventoryActionType.FullUpdate || action === InventoryActionType.PartialUpdate) {
          this.itemData = [];
          this.categories.forEach(r => r.filtered = []);
        }
        this.ResetFlags();
        this.GetItemsForSelectedOutlet();
      }
    }

    this.tableOptions = [...this.tableOptions];
  }

  async SyncInventory() {
    this.isSyncing = true;
    this.utils.ToggleLoader(true);

    const apiResponse = await this.inventoryService.InvokeServiceCallAsync('SyncInventory',
      Host.retailManagement,
      HttpMethod.Get,
      { outletId: this.defaultOutlet })
      .finally(() => {
        this.utils.ToggleLoader(false);
      });

    if (apiResponse) {
      this.utils.showAlert(this.captions.processComplete, AlertType.Success, ButtonType.Ok);
      this.isSyncing = false;
    }
  }

  async virtualScrollHandler(arg: { viewPortItems: string | any[]; }) {
    if (arg.viewPortItems && arg.viewPortItems.length && !this.isLoading) {

      if (!this.isLoading) {
        this.isLoading = true;
        const tableData = await this.fetchInventoryItems();
        this.tableOptions[0].bodyData.push(...tableData);
        this.isLoading = false;
      }

    }
  }

  async sortHandler(arg) {
    this.sortBy = this.sortables.indexOf(arg.header);
    this.isSortByAscending = arg.direction === 'asc';

    await this.GetItemsForSelectedOutlet();
  }
}
