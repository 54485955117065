import { Component,  Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pms-floating-navigation',
  templateUrl: './floating-navigation.component.html',
  styleUrls: ['./floating-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FloatingNavigationComponent {
  list;
  show = false;
  @Output() emitEvent = new EventEmitter();
  @Input('inputs')
  set inputOptions(value) {
    this.list = value;
  }
  
  clickIcon() {
    this.show = true;
  }

  navigate(eve, domId) {
    const elmnt = document.getElementById(domId);
    elmnt.scrollIntoView();
  }

}
