import { RouteAuthGuardConfig } from './ui-control-config.interface';
import { AuthorizationSubModule, AuthorizedDataModel, PermissionResource, RouteDataConfig, RoutingAuthConfig } from './ui-controls.interface';

export const RouteAuthGuardAuthorization: RouteAuthGuardConfig = ( RouteData: RoutingAuthConfig): boolean => {
        try {
          let authorizationBool = true;  
          const AuthorizationData: Array<AuthorizedDataModel> = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
          if ( AuthorizationData && AuthorizationData.length > 0 ) {
            const authDataModel: Array<AuthorizedDataModel> = AuthorizationData
            .filter((x: AuthorizedDataModel) => x.Name === RouteData.AuthModule);
            if ( authDataModel && authDataModel.length > 0 ) {
                const FilterSubModule: Array<AuthorizationSubModule> = authDataModel[0].SubModules
                .filter((e: AuthorizationSubModule) => e.Name === RouteData.AuthSubModule);
                if ( FilterSubModule && FilterSubModule.length > 0 ) {
                    authorizationBool = FilterSubModule[0].PermissionResource
                    .filter((f: PermissionResource) => RouteData.AuthKey.findIndex(a => a === f.Name) > -1 ).every(e => e.IsActive);
                }
            }
          }
          return authorizationBool; 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};


