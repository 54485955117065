import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysPicturePopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-picture-popup/agilysys-picture-popup.component';
import {
    DetailPictureConfig,
    FormBuilderGroupKey, MasterInventoryManagement
} from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { MatTabGroup } from '@angular/material/tabs';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';


export const BarcodeFormEdit: CustomFormConfiguration = (ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: MatDialog, systemPreference?: any,
    masterService?: MasterManagementService): any => {
    try {

        if (FieldGrouping.CustomFormAction && MasterDataCollection.FormConfig.FlowMode.toLowerCase() === 'edit') {
            const Index =
                MasterDataCollection.NestedFormConfig.EditFormConfig.findIndex(x => x.Name === 'Barcodes');
            if (Index !== -1 && FormGroupData.value.Barcode) {
                masterService.matTabGroup.selectedIndex = Index;
                setTimeout(() => {
                    const elementdata = MasterDataCollection.NestedFormConfig.EditFormConfig[Index];
                    const RowIndex = elementdata.NestedGridData.GridData.findIndex(x => x.Barcode ===
                        FormGroupData.value.Barcode);
                    const RowObject = {
                        data: elementdata.NestedGridData.GridData[RowIndex]
                    };
                    MasterDataCollection.NestedFormConfig.EditFormConfig[Index].NestedGridData.
                        rowClicked(RowObject);
                }, 100);
            }
        }
    } catch (error) {
        console.log('error occured openBarcodeEdit', error);
    }
};
