import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { ConfirmPopModel, PostSculptureDataModel, PrintPopModel, PrintPopUpCallBack, SculptureData, TransactionHeader } from '../model/pop-up.interface';
import * as constants from '../../shared/constant/GlobalConstants';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button/public-api';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AgilysysSessionExpiredComponent } from '../agilysys-session-expired/agilysys-session-expired.component';
import { GridInterface } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface';
import { GridTableComponent } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { SculptureIntegeration } from 'src/app/eatecui/source/shared/integration/sculpture-integration';
import { TranslateService } from '@ngx-translate/core';
import { AgilysysConfirmationPopupComponent } from '../agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import * as _ from 'lodash';
import { GenerateReportFilterModel, GenerateReportModel } from 'src/app/eatecui/source/reports/interface/reports.interface';
import { AgilysysPrintPopupComponent } from '../agilysys-print-popup/agilysys-print-popup.component';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

@Component({
  selector: 'app-agilysys-import-pi-popup',
  templateUrl: './agilysys-import-pi-popup.component.html',
  styleUrls: ['./agilysys-import-pi-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysImportPiPopupComponent implements OnInit {
  @ViewChild('gridTable', { static: false }) gridTable: GridTableComponent;
  postButton: AgilysysButtonModel[];
  getDataButton: AgilysysButtonModel[];
  homeGroup: FormGroup;
  piOptions: Array<any>;
  showValidation = false;
  validationMessage: string;
  importData: any;
  enableGridSec: boolean;
  clonedData: Array<any>;
  clonedHeader: any;
  exportCSVButton: AgilysysButtonModel[];
  postResultData: any;
  enableDownload = false;
  maxDate = new Date();
  filterApplied = false;
  searchKey: string;
  searchApplied = false;
  FilterQuery: any;
  sculptureDateFormat: any;
  IniDateFieldFormat: string;
  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<AgilysysImportPiPopupComponent>, public formBuilder: FormBuilder, 
    @Inject(MAT_DIALOG_DATA) public data, public httpService: HttpService, public httpClient: HttpClient, 
    private toasterService: ToastrService, public translateService: TranslateService, private commonService: CommonService) { 
    this.homeGroup = this.formBuilder.group({
      periodId: [null, [Validators.required]],
      dateValue: [null, [Validators.required]],
      piOptions: [null],
      IsCumulativeCount: false
    });
    this.sculptureDateFormat = JSON.parse(sessionStorage.getItem('appsetting')).Sculpture.DateFormat;
    this.enableGridSec = false;
    this.getJsonData();
    this.IniDateFieldFormat = sessionStorage.getItem('IniDateFieldFormat');
  }
  async getJsonData() {
    try {      
      this.importData = await this.httpClient.get<Array<any>>('assets/json/transaction/sculpture.json').toPromise();
      this.clonedData = _.cloneDeep(this.importData.SculptureGridData.GridData);
      this.importData.SculptureGridData.searchTextChanged = (value: any) => {
        this.searchData(value);
      };
      this.importData.SculptureGridData.sortChanged = (value: any) => {
        this.sortData(value);
      };
      this.importData.SculptureGridData.rowChecked = (value: any) => {
        const length = this.importData.SculptureGridData.GridData.filter(x => x.Checked).length;
        this.postButton[3].displayName = '';
        if (length > 0) {
          this.postButton[3].displayName = ('Post (' + length + ')');
        } else {
          this.postButton[3].displayName = 'Post';
        }
         
      };
      this.importData.SculptureGridData.filterApplied = (value: any) => {
        this.filterData(value);
      };
    } catch (error) {
      console.log(error);
    }
  }

  splitFormDate(datevalue, splitValue) {
    try {
      const dateValues = datevalue.split(splitValue);
      const indexvalue = dateValues.findIndex(x => x > 12); // to check whether first given is date or month
      let date, month, year;
      if (indexvalue !== -1) {
        date = dateValues[indexvalue];
        month = indexvalue === 0 ? dateValues[1] : dateValues[0];
        year = dateValues[2] ? dateValues[2] : new Date().getFullYear();
      } else {
        date = dateValues[0];
        month = dateValues[1];
        year = dateValues[2] ? dateValues[2] : new Date().getFullYear();
      }
      const formedDate = moment([year, (month - 1), date]).format();
      return formedDate;
    } catch (error) {
      console.error('Error occurred in spliFormDate', error);
    }
  }
  closeDialog(): any {
    try {
      if (this.homeGroup.dirty || this.homeGroup.touched) {
        const ConfirmPopUpDialog: ConfirmPopModel = {
          PopupHeaderContent: 'Warning',
          PopUpContent: `You are about to exit the module, any changes made to the screen will be lost. Are you sure you want to exit?`,
          ExitPopup: true
        };
          const dialogRefe = this.dialog.open(AgilysysConfirmationPopupComponent, {
          width: '400px',
          height: '222px',
          data: ConfirmPopUpDialog,
          disableClose: true
          });
        return dialogRefe.afterClosed().toPromise().then((result: any) => {
          if (result === 'Confirm') {
            this.dialogRef.close();
          }
        });
      } else {
        this.dialogRef.close();
      }
    } catch (error) {
      console.log(error);
    }
  }
  valueChange(value: any) {
    const datevalue = value.target.value;
    if (datevalue !== '') {
      const checkType = moment(datevalue, 'M/D/YYYY', true).isValid() || moment(datevalue, 'D/M/YYYY', true).isValid()
        || moment(datevalue, 'YYYY/M/D', true).isValid() || moment(datevalue, 'M-D-YYYY', true).isValid() ||
        moment(datevalue, 'D-M-YYYY', true).isValid() || moment(datevalue, 'YYYY-M-D', true).isValid();
      // this.checkType = value.target.value.toString() instanceof Date;
      if (!checkType) {
        if (datevalue.indexOf('/') !== -1 && datevalue.indexOf('//') === -1) {
          const returnDate = this.splitFormDate(datevalue, '/');
          this.homeGroup.controls['dateValue'].setValue(new Date(returnDate));
        } else if (datevalue.indexOf('-') !== -1 && datevalue.indexOf('--') === -1) {
          const returnDate = this.splitFormDate(datevalue, '-');
          this.homeGroup.controls['dateValue'].setValue(new Date(returnDate));
        } else {
          this.homeGroup.controls['dateValue'].setValue(new Date());
        }
      } else {
        let dateFormatted: any;
        const formattedDate = moment(datevalue, 'M/D/YYYY');
        const formattedDate1 = moment(datevalue, 'D/M/YYYY');
        const formattedDate2 = moment(datevalue, 'YYYY/M/D');
        if (formattedDate.isValid()) {
          dateFormatted = formattedDate.format();
        } else if (formattedDate1.isValid()) {
          dateFormatted = formattedDate1.format();
        } else if (formattedDate2.isValid()) {
          dateFormatted = formattedDate2.format();
        } else {
          dateFormatted = moment(new Date()).format();
        }
        this.homeGroup.controls['dateValue'].setValue(new Date(dateFormatted));
      }
    }
  }

  buttonClick() {
    try {
      if (this.homeGroup.value.dateValue) {
        const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
        const DateTimeFormat = (sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null) ?
          sessionDateFormat : constants.DefaultIniDateFormat;
        const periodId = parseInt(this.homeGroup.value.periodId, 10);
         const cxDate =  moment(this.homeGroup.value.dateValue).format(this.sculptureDateFormat);
         this.showValidation = false;
         let reqURL = CONSTANT.ENDPOINTURL['PhysicalHead']['getPIImportedData'];
          // reqURL = reqURL + periodId + '&moduleKey=Sculpture';
         reqURL = reqURL + cxDate + '&moduleKey=Sculpture';
         this.httpService.GethttpMethod(reqURL).subscribe((res: any) => {
          res.ValidationError = null;
          if (res.ValidationError && (res.ValidationError === '' || res.ValidationError === null) 
            && res.TransactionHeader === null) {
            this.enableGridSec = false;
            const ConfirmPopUpDialog: ConfirmPopModel = {
              PopupHeaderContent: 'Alert',
              PopUpContent: 'Unable to fetch items for given date - ' + this.homeGroup.value.dateValue
            };
            const dialog = this.dialog.open(AgilysysSessionExpiredComponent, {
              width: '440px',
                height: '220px',
                data: ConfirmPopUpDialog,
                autoFocus: false,
                disableClose: true
            });
            dialog.afterClosed().subscribe((r: any) => { 
              console.log(r);
            });
          } else {
            this.enableGridSec = true;
            this.importData.HeaderData.forEach(data => {
               data.Value = res.TransactionHeader[data.Name];
               if (data.Name === 'PostDate') {
                 data.Value = moment(data.Value).format(DateTimeFormat);
               }
            });
            this.importData.SculptureGridData.GridActions.TotalItems = res.TransactionHeader.Items.length;
            this.importData.SculptureGridData.GridData = res.TransactionHeader.Items;     
            this.importData.SculptureGridData.GridData = [...this.importData.SculptureGridData.GridData];               
            this.importData.SculptureGridData.GridActions.FilterConfig.EnableFilter = false;
            this.sortData(this.importData.SculptureGridData.
              GridHeaderDefination.filter(x => x.sortDirection !== '' && x.sortDirection !== undefined)[0]);
            this.importData.SculptureGridData = {...this.importData.SculptureGridData};   
            this.clonedData = _.cloneDeep(this.importData.SculptureGridData.GridData);
            this.postButton[2].displayProperity = true;
            this.postButton[3].displayProperity = true;
            if (this.importData.SculptureGridData.GridData.length > 0) {
              // this.postButton[2].displayProperity =  false;
              this.postButton[1].displayProperity =  false;
           } else {
            // this.postButton[2].displayProperity = true;
            this.postButton[1].displayProperity =  true;
           }         
            if (this.importData?.SculptureGridData && this.importData?.SculptureGridData?.GridHeaderDefination) {
              this.importData.SculptureGridData.GridHeaderDefination.forEach((header: any) => {
                if (header.hasOwnProperty('columnType') && header.columnType === 'decimal') {
                  header.valueFormatter = Formaters.decimalFormater;
                }
              });
            }
            const index = this.importData.SculptureGridData.GridHeaderDefination.findIndex(x => x.field === 'ValidationMessage');
            this.importData.SculptureGridData.GridHeaderDefination[index].hide = true;
            this.importData.SculptureGridData.GridHeaderDefination[0].hide = true;
            this.gridTable?.gridApi?.setColumnDefs([]);
            this.gridTable?.gridApi?.setColumnDefs(this.importData.SculptureGridData.GridHeaderDefination);
            this.importData.SculptureGridData.GridHeaderDefination = [...this.importData.SculptureGridData.GridHeaderDefination];  

          }
        }, (error) => {
          this.enableGridSec = false;
          // this.dialog.closeAll();
            // const ConfirmPopUpDialog: ConfirmPopModel = {
            //   PopupHeaderContent: 'Error',
            //   PopUpContent: error.ValidationError ? error.ValidationError : error.Message

            // };
            // const dialog = this.dialog.open(AgilysysSessionExpiredComponent, {
            //   width: '440px',
            //     height: '220px',
            //     data: ConfirmPopUpDialog,
            //     autoFocus: false,
            //     disableClose: true
            // });
            // dialog.afterClosed().subscribe((r: any) => { 
            //   console.log(r);
            // });
          this.toasterService.error(error.ValidationError, '', {
            timeOut: 3000,
            closeButton: true
          });
        }       
        );
         
      } else {
        this.showValidation = true;
        this.validationMessage = 'Please enter date';
      }
    } catch (error) {
      console.log(error);
    }
  }
  searchData(value: any, fromFilter = false): void {
    try {
      let returnData;
      let returnValue;
      this.searchKey = value;
      if (this.filterApplied && !fromFilter) {
        returnValue = this.filterData(this.FilterQuery, true);
      }
      returnValue = (returnValue && returnValue.length > 0) ? returnValue : this.clonedData;
      if (value.length > 0) {
        // this.importData.SculptureGridData.GridData = [...this.clonedData];
        this.searchApplied = true;
        returnData = returnValue.filter(x => x.Barcode.includes(value));
        this.importData.SculptureGridData.GridData = [...returnData];
        this.importData.SculptureGridData.TotalRecords = returnData.length;
        this.importData.SculptureGridData.GridActions.TotalItems = returnData.length;
        this.importData.SculptureGridData = {...this.importData.SculptureGridData};
      } else {
        this.searchApplied = false;
        returnData = returnValue;
        this.importData.SculptureGridData.GridData = returnValue;
        this.importData.SculptureGridData.TotalRecords = this.clonedData.length;
        this.importData.SculptureGridData.GridActions.TotalItems = this.clonedData.length;
        this.importData.SculptureGridData = {...this.importData.SculptureGridData};
      }
      this.importData.SculptureGridData.GridActions.TotalItems = returnData.length;
      this.enableButtons();
      return returnData;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method sortData
   */
  sortData(value: any): void {
    try {
      const action = {
        NestedGridData: this.importData.SculptureGridData
      };
      let sortConfig: any = [];
      if (value && value[0] === undefined) {
        sortConfig.push({'colId': value.field , 'sort': value.sortDirection});
      }
      sortConfig = sortConfig.length > 0 ? sortConfig : value;
      if (sortConfig) {
      const RowData = GridInterface.GridSortConfig(action, this.importData,
        this.importData.SculptureGridData.GridData, sortConfig);
      this.importData.SculptureGridData.GridData = [...RowData];
      this.importData.SculptureGridData = {
        ...this.importData.SculptureGridData
      };
    }
    } catch (error) {
      console.log(error);
    }    
  }
  /**
   * @method filterData
   */
  filterData(filterValue: any, fromSearch = false): void {
    try {
      console.log(filterValue);
      let filterData: any;
      let responseData = [];
      const FilterList = [];
      let filterId = [];
      let filterKey = '';
      this.FilterQuery = filterValue;
      if (this.searchApplied && !fromSearch) {
        filterData = this.searchData(this.searchKey, true);
      }
      filterData = (filterData && filterData.length > 0) ? filterData : this.clonedData;
      filterValue[0].forEach(element => {
        const getFiltervalue = element.FilterValue.filter(f => f.Checked).map(e => e.FilterId);
        filterId = getFiltervalue;
        filterKey = 'StatusId';
        const object = {
          FilterId: filterId,
          FilterKey: filterKey
        };
        if (filterId.length > 0) {
          FilterList.push(object);
          this.filterApplied = true;
        }
      });
      if (FilterList.length > 0) {
        FilterList.forEach(x => {
            if (x.hasOwnProperty('AdditionalFilterCheck') && x.AdditionalFilterCheck) {
                responseData = filterData.filter(aa =>  x.FilterId.includes(aa[x.FilterKey]) && 
                    aa[x.AdditionalFilterCheck.AdditionalFilterKey] === x.AdditionalFilterCheck.AdditionalFilterValue);
            } else {
                responseData = filterData.filter(function (e) {
                    return x.FilterId.includes(e[x.FilterKey]);
                });
            }
        });
        filterData = responseData;
        this.importData.SculptureGridData.GridData = responseData;
        this.importData.SculptureGridData.GridData = [...this.importData.SculptureGridData.GridData];
        this.importData.SculptureGridData = {...this.importData.SculptureGridData};
    } else {
      this.filterApplied = false;
      this.importData.SculptureGridData.GridData = filterData;
      this.importData.SculptureGridData.GridData = [...this.importData.SculptureGridData.GridData];
      this.importData.SculptureGridData = {...this.importData.SculptureGridData};
    }
    this.importData.SculptureGridData.GridActions.TotalItems = filterData.length;
    this.enableButtons();
    return filterData;
    } catch (error) {
      console.log(error);
    }
  }
  postData(callBackData: any): void {
    try {
      const obj = new SculptureIntegeration(this.toasterService, null);
      const validationModel = obj.SculptureValidation(this.importData.SculptureGridData.GridData);
        if (this.homeGroup.value.piOptions && this.homeGroup.value.dateValue) {
          const isCheckedData = this.importData.SculptureGridData.GridData.some(x => x.Checked);          
          if (isCheckedData) {
            this.postCallFunc(validationModel, callBackData);
          } else {
            const ConfirmPopupModel = {} as ConfirmPopModel;
                    ConfirmPopupModel.PopupHeaderContent = 'Confirmation';
                    ConfirmPopupModel.PopUpContent = 'Are you sure to post physical counts for ' + 
                    `${validationModel.SculptureData.length}` + ' valid items out of ' + `${this.clonedData.length}` + ' items';
                    const dialog = this.dialog.open(AgilysysConfirmationPopupComponent, {
                        width: '600px',
                        height: '250px',
                        data: ConfirmPopupModel,
                        disableClose: true
                    });
                    dialog.afterClosed().subscribe((r: any) => { 
                        if (r === 'Confirm') {
                          this.postCallFunc(validationModel, callBackData);
                        } else {
                            dialog.close();
                        }
                    });
          }      
        } else {
          const ConfirmPopUpDialog: ConfirmPopModel = {
            PopupHeaderContent: 'Alert',
            PopUpContent: 'Please select any one of the Physical counts and Date'
          };
          const dialog = this.dialog.open(AgilysysSessionExpiredComponent, {
            width: '440px',
              height: '220px',
              data: ConfirmPopUpDialog,
              autoFocus: false,
              disableClose: true
          });
          dialog.afterClosed().subscribe((r: any) => { 
            console.log(r);
          });
        }
             
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method postCallFunc()
   */
  async postCallFunc(validationModel: any, callBackData: any): Promise<void> {
    try {
      const postModel = this.constructData();
      postModel.isValidate = false;
      postModel.transactionHeader.items = validationModel.SculptureData;
      const reqURL = CONSTANT.ENDPOINTURL['PhysicalHead']['postPIImportedData'];
      this.httpService.PosthttpMethod(reqURL, postModel).subscribe(async res => {
        if (res.Message) {
          this.toasterService.success(`Physical inventory ${res.Data.response.PhysicalHeadId} created successfully`, '', {
            timeOut: 3000,
            closeButton: true,
          });
        }
        if (res?.Data?.response?.PhysicalHeadId && callBackData.buttonValue.EnablePrintPost) {
          const Printenpoint = '/reportdata/api/ReportModule?$select=Id,Name&$expand=Reports($filter=IsActive eq true and IsPrint eq true;$select=Id,Name,FileName,Description)&$filter=IsActive eq true And Reports/any(c: c/IsActive eq true and c/IsPrint eq true)';
            // const getPrintList = await this.httpService.GethttpMethod(Printenpoint).toPromise();
            let selectedTransactionName = 'Physical Inventory';  
            const PrintId = await this.commonService.getPrintId(selectedTransactionName);
            const getPrintList = await this.commonService.getPrintListById(PrintId);
            const EnablePopupResult: PrintPopUpCallBack = await this.OpenPrintList(this.dialog, getPrintList, selectedTransactionName, PrintId);     
            if (EnablePopupResult && EnablePopupResult.ButtonKey === 'Confirm' && EnablePopupResult.SelectedId !== null) {     
              const generateReportModel = {} as GenerateReportModel;
              generateReportModel.reportFormat = 0;
              generateReportModel.fileName = null;
              generateReportModel.inLine = false;
              generateReportModel.sortBy = 'string';
              generateReportModel.reportid = EnablePopupResult.SelectedId;
              generateReportModel.isAsc = true;
              generateReportModel.isRetail = true;
              const generateReportFilterModel = {} as GenerateReportFilterModel;
              generateReportFilterModel.property = 'id';
              generateReportFilterModel.value = ` ${res.Data.response.PhysicalHeadId?.toString()}`;
              generateReportFilterModel.isExclude = false;
              generateReportModel.filters = [generateReportFilterModel];
              generateReportModel.IanaTimeZoneId = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
              generateReportModel.UserTimeZoneId = new Date()?.toString()?.split("(")[1]?.split(")")[0];
              
              const postUrl = `/ReportData/api/GenerateReport`;
              const reportData = await this.httpService.PosthttpMethod(postUrl,
                  generateReportModel, null, { observe: 'response', responseType: 'blob' }).toPromise();
              const file = new Blob([reportData.body], { type: reportData.body.type });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);   
            }       
        }
        this.postButton[3].displayName = 'Post';                 
        const resData = [];
        this.importData.SculptureGridData.GridData.forEach(data => {
          let exist = false;
          validationModel.SculptureData.forEach(validData => {
            if (data.Barcode === validData.Barcode) {
              exist = true;
            }
          });
          if (!exist) {
            resData.push(data);
          }
        });
        this.importData.SculptureGridData.GridActions.TotalItems = resData.length;
        const checkHasValid = resData.some(z => z.StatusId === 1);
        const checkboxIndex = this.importData.SculptureGridData.GridHeaderDefination.findIndex(x => x.field === 'check');            
        this.importData.SculptureGridData.GridHeaderDefination[checkboxIndex].hide = !checkHasValid;
        this.gridTable.gridApi.setColumnDefs([]);
        this.gridTable.gridApi.setColumnDefs(this.importData.SculptureGridData.GridHeaderDefination);
        this.importData.SculptureGridData.GridHeaderDefination = [...this.importData.SculptureGridData.GridHeaderDefination];
        this.importData.SculptureGridData.GridData = resData;
        this.importData.SculptureGridData.GridData = [...this.importData.SculptureGridData.GridData];   
        this.importData.SculptureGridData.ClearSearchFilter = true;
        setTimeout(() => {
          this.importData.SculptureGridData.ClearSearchFilter = false;
        }, 100);         
        this.importData.SculptureGridData = {...this.importData.SculptureGridData}; 
        // this.clonedData =  this.importData.SculptureGridData.GridData; 
        const isValidateData = this.importData.SculptureGridData.GridData.some((data: any) => data.StatusId === 1 );
        if (!isValidateData) {
          this.postButton[2].displayProperity =  true;
          this.postButton[3].displayProperity =  true;
          this.postButton[1].displayProperity =  true;
        } 
        const clonedCollec = [];   
        this.clonedData.forEach(data => {
          let exist = false;
          validationModel.SculptureData.forEach(validData => {
            if (data.Barcode === validData.Barcode) {
              exist = true;
            }
          });
          if (!exist) {
            clonedCollec.push(data);
          }
        });  
        this.clonedData = clonedCollec;     
    }); 
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method validateData
   */
  validateData(): void {
    try {
      const obj = new SculptureIntegeration(this.toasterService, null);
      const validationModel = obj.SculptureValidation(this.importData.SculptureGridData.GridData, 'Validate');
      const ValidateCallModel = this.constructData();
      if (validationModel.isValidate) {
        ValidateCallModel.isValidate = validationModel.isValidate;
        ValidateCallModel.transactionHeader.items = validationModel.SculptureData;        
        const reqURL = CONSTANT.ENDPOINTURL['PhysicalHead']['postPIImportedData'];
        this.httpService.PosthttpMethod(reqURL, ValidateCallModel).subscribe(res => {          
            const index = this.importData.SculptureGridData.GridHeaderDefination.findIndex(x => x.field === 'ValidationMessage');
            const checkboxIndex = this.importData.SculptureGridData.GridHeaderDefination.findIndex(x => x.field === 'check');
            this.importData.SculptureGridData.GridHeaderDefination[checkboxIndex].hide = false;
            this.importData.SculptureGridData.GridHeaderDefination[index].hide = false;
            this.gridTable.gridApi.setColumnDefs([]);
            this.gridTable.gridApi.setColumnDefs(this.importData.SculptureGridData.GridHeaderDefination);
            this.importData.SculptureGridData.GridHeaderDefination = [...this.importData.SculptureGridData.GridHeaderDefination];
            this.importData.SculptureGridData.GridData = res.Data.response.Items;
            this.importData.SculptureGridData.GridData = [...this.importData.SculptureGridData.GridData];
            this.sortData(this.importData.SculptureGridData.
              GridHeaderDefination.filter(x => x.sortDirection !== '' && x.sortDirection !== undefined)[0]);
            this.importData.SculptureGridData.GridActions.FilterConfig.EnableFilter = true;
            this.importData.SculptureGridData = {...this.importData.SculptureGridData};
            this.clonedData = _.cloneDeep(this.importData.SculptureGridData.GridData);
            // this.enableDownload = true;
            this.postResultData = res.Data.response;   
            const isValidateData = this.importData.SculptureGridData.GridData.some((data: any) => data.StatusId === 1 );
            if (isValidateData) {
              this.postButton[2].displayProperity =  false;
              this.postButton[3].displayProperity =  false;
            } else {
              this.postButton[2].displayProperity =  true;
              this.postButton[3].displayProperity =  true;
              this.postButton[1].displayProperity =  true;
            }
          }, (error) => {
            this.sortData(this.importData.SculptureGridData.
              GridHeaderDefination.filter(x => x.sortDirection !== '' && x.sortDirection !== undefined)[0]);
            this.postButton[2].displayProperity =  true;
            this.postButton[3].displayProperity =  true;
            const ConfirmPopUpDialog: ConfirmPopModel = {
              PopupHeaderContent: 'Error',
              PopUpContent: error?.Data?.err
            };
            if (ConfirmPopUpDialog.PopUpContent) {
              const dialogRef = this.dialog.open(AgilysysSessionExpiredComponent, {
                  width: '480px',
                  height: '222px',
                  data: ConfirmPopUpDialog,
                  disableClose: true
                });
                dialogRef.afterClosed().subscribe((r: any) => { 
                  console.log(r);
                });
            }
          });
      }
    } catch (error) {

    }
  }
/**
 * @method downloadCSV
 */
downloadCSV(): void {
    try {
      const csvData = this.ConvertToCSV(this.clonedHeader, this.postResultData);      
      const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      const dwldLink = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
      if (isSafariBrowser) { 
          dwldLink.setAttribute('target', '_blank');
      }
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', 'ExportedData.csv');
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    } catch (error) {
      console.log(error);
    }
}
/**
 * @method constructData
 */
constructData(): PostSculptureDataModel {
  const sculpturePostModel = {} as PostSculptureDataModel;
  const transactionHeader = {} as TransactionHeader;
  const sculptureDateFormat = JSON.parse(sessionStorage.getItem('appsetting')).Sculpture.DateFormat;
  const cxDate =  moment(this.homeGroup.value.dateValue).format(sculptureDateFormat);
  const postDate = this.importData.HeaderData.filter(x => x.Name === 'PostDate')[0].Value;
  sculpturePostModel.isValidate = false;
  sculpturePostModel.isSaved = false;
  sculpturePostModel.validationError = '';
  transactionHeader.location = this.importData.HeaderData.filter(x => x.Name === 'Location')[0].Value;
  transactionHeader.postDate = Formaters.globalDateFormaters(postDate);
  transactionHeader.isCycleCount = this.homeGroup.value.piOptions === 4;
  transactionHeader.cxdate = cxDate;
  transactionHeader.isCumulativeCount = this.homeGroup.value.IsCumulativeCount;  
  transactionHeader.items = this.importData.SculptureGridData.GridData;                  
  sculpturePostModel.transactionHeader = transactionHeader;
  this.clonedHeader = {
      Location: transactionHeader.location,
      PostDate: transactionHeader.postDate
  };
  return sculpturePostModel;
}
/**
 * @method ConvertToCSV
 */
  ConvertToCSV(headerList, objArray: Array<any> = []) {
    try {
      const array = (objArray && objArray.length > 0) ? typeof objArray !== 'object' ? JSON.parse(objArray) : objArray : [];
      let str = '';
      const headerKeys = [];
      const headerValues = [];
      for (const key in headerList) {
        if (key) {
          headerKeys.push(key);
          headerValues.push(headerList[key]);
        }
      }
      headerKeys.forEach(index1 => {
        str += index1 + ',';                 
        for (const val in headerList) {     
          if (val === index1) {
            const rowData = headerList[val];
            if (rowData !== undefined) {
              str += rowData + ',';
            }
          }                            
        }
        str = str.slice(0, -1);
        str = str + '\r\n';
      });
      str = str.slice(0, -1);
      str = str + '\r\n';
      // if (headerValues !== null && headerValues.length > 0) {        
      //   for (let i = 0; i < headerValues.length; i++) {             
      //     const rowData = headerValues[i];
      //     str += rowData + ',';
      //   }
      // }
      // str = str.slice(0, -1);
      str = str + '\r\n';
      const dataKeys = [];
      const dataValues = [];
      for (const key in objArray[0]) {
        if (key) {
          dataKeys.push(key);
        }
      }
      for (const key in objArray) {     
        if (key) {
          dataValues.push(objArray[key]);
        }         
      }
      dataKeys.forEach(key => {
        str += key + ',';
      });
      str = str.slice(0, -1);
      str = str + '\r\n';
      if (dataValues !== null && dataValues.length > 0) {        
        for (let i = 0; i < dataValues.length; i++) {             
          const rowData = dataValues[i];
          let line = '';
          for (const key in rowData) {
            if (key) {          
              line +=  rowData[key] + ',' ;                   
            }            
          }
          str += line + '\r\n';
        }
      }      

      return str;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method enableButtons()
   */
  enableButtons(): void {
    try {
      const enableButton = this.importData.SculptureGridData.GridData.every(x => x.StatusId === 2);
      this.postButton[1].displayProperity = enableButton;
      this.postButton[2].displayProperity = enableButton;
      this.postButton[3].displayProperity = enableButton;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * @method OpenPrintList
   */
  OpenPrintList(dialog: MatDialog, ResultData: Array<any>, selectedtransaction: string, PrintId: number) {
    try {
        return new Promise<PrintPopUpCallBack>(resolve => {
            const printPopModel = {} as PrintPopModel;
            printPopModel.PopUpContent = `${selectedtransaction} Print`;
            printPopModel.PopupHeaderContent = `${selectedtransaction} Print`;
            printPopModel.PrintList = ResultData;
            printPopModel.PrintKey = selectedtransaction;
            printPopModel.EnableCount = false;
            printPopModel.PrintId = PrintId;
            const dialogRef = dialog.open(AgilysysPrintPopupComponent, {
                width: '40%',
                height: '30%',
                data: printPopModel,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe((result: PrintPopUpCallBack) => {
                resolve(result);
            });
        });
    } catch (error) {
        console.error(error);
    }
}
  ngOnInit(): void {
    this.piOptions = [
      {
        Id: 2,
        Name: 'Enter Counts'
      },
      {
        Id: 4,
        Name: 'Enter Cycle Counts'
      }
    ];
    this.postButton = [
      {
        displayProperity: false,
        displayName: 'Clear',
        buttonKey: 'Clear',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          // this.dialogRef.close();
          // this.importData.SculptureGridData.GridData = [];
          // this.importData.SculptureGridData.GridData = [...this.importData.SculptureGridData.GridData];
          // this.clonedData = this.importData.SculptureGridData.GridData;
          // const index = this.importData.SculptureGridData.GridHeaderDefination.findIndex(x => x.field === 'ValidationMessage');
          // const checkboxIndex = this.importData.SculptureGridData.GridHeaderDefination.findIndex(x => x.field === 'check');
          // this.importData.SculptureGridData.GridHeaderDefination[checkboxIndex].hide = true;
          // this.importData.SculptureGridData.GridHeaderDefination[index].hide = true;
          // this.gridTable.gridApi.setColumnDefs([]);
          // this.gridTable.gridApi.setColumnDefs(this.importData.SculptureGridData.GridHeaderDefination);
          // this.importData.SculptureGridData.GridHeaderDefination = [...this.importData.SculptureGridData.GridHeaderDefination];
          // this.importData.SculptureGridData.GridActions.FilterConfig.EnableFilter = false;
          // this.importData.SculptureGridData = {...this.importData.SculptureGridData};
          this.enableGridSec = false;
          this.postButton[1].displayProperity = true;
          this.postButton[2].displayProperity = true;
          this.postButton[3].displayProperity = true;
        }
      },
      {
        displayProperity: true,
        displayName: 'Validate',
        buttonKey: 'Validate',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.validateData();
        }
      },
      {
        displayProperity: true,
        displayName: 'Print & Post',
        buttonKey: 'PrintPost',
        buttonType: 'agilysys-button-primary',
        EnablePrintPost: true,
        buttonCallBack: (callBackData: CallBackData) => {
          this.postData(callBackData);
        }
      },
      {
        displayProperity: true,
        displayName: 'Post',
        buttonKey: 'Post',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.postData(callBackData);
        }
      }
    ];
    this.getDataButton = [
      {
        displayProperity: false,
        displayName: 'Get Data',
        buttonKey: 'get',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.buttonClick();
        }
      }
    ];
  }

}
