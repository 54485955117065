<!-- <div class="triangle-up"></div> -->
<section class="reorder-panel">
  <div class="reorder-panel-section">
    <div class="reorder-section" cdkScrollable>
      <!-- <div class="reorder-list" cdkDropList (cdkDropListDropped)="onItemDrop($event)">
        <div class="data-row" *ngFor="let value of copyOfColumns" cdkDrag (cdkDragStarted)="dragMoved($event)">
          <div class="icon-div">
            <i *ngIf="value.hide" class="icon-path disable"></i>
            <i class="icon-path draggable" cdkDragHandle></i>
          </div>
          <mat-checkbox class="check-class" [(ngModel)]="!value.hide"
            (change)="value.hide = !value.hide;disableReset = false;disableButtonCheck();"></mat-checkbox>
          <span class="text-div" title="{{value.headerName}}">{{value.headerName}}</span>
        </div>
      </div> -->
      <div cdkDropList class="reorder-list" (cdkDropListDropped)="onItemDrop($event)">
        <div class="reorder-box" *ngFor="let value of copyOfColumns" cdkDrag [ngClass]="value.columnConfiguration === false ? 'display-none' : ''">
          <div class="icon-div">
            <i *ngIf="value.hide" class="icon-path-eatec disable"></i>
            <i *ngIf="!value.hide" class="icon-path-eatec draggable"></i>
          </div>
          <mat-checkbox class="check-class" [(ngModel)]="!value.hide" disableRipple="true"
            (change)="value.hide = !value.hide;disableReset = false;disableButtonCheck();"></mat-checkbox>
          <span class="text-div" title="{{value.headerName}}">{{value.headerName}}</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <button mat-button class="mr-4"
        (click)="cancel()">Set To Default</button>
      <button mat-button class="apply-button mr-4" [disabled]="disableReset"
        (click)="apply()">Apply</button>
    </div>
  </div>  
</section>