import { Pipe, PipeTransform } from '@angular/core';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
@Pipe({
  name: 'StaffSchedulePipe'
})
export class StaffSchedulePipe implements PipeTransform {
  constructor(public localization: SpaLocalization, private utilities: SpaUtilities) { }
  transform(contentArr: any[], time: any, therapistId: any, isActualFilter: boolean, isOnCallFilter: boolean): any[] {
    let schedules: any = null;
    let resultData: any = [];
    this.utilities.getDate(time.row1).toString();
    if (isActualFilter == isOnCallFilter) {
      resultData = contentArr.filter(result => {
        return therapistId == result.therapistId && this.utilities.getDate(time.row1).toString() == this.utilities.getDate(result.scheduleDate).toString()
      });
    } else if (isActualFilter) {
      resultData = contentArr.filter(result => {
        return therapistId == result.therapistId && !result.availableOnCall && this.utilities.getDate(time.row1).toString() == this.utilities.getDate(result.scheduleDate).toString()
      });
    } else if (isOnCallFilter) {
      resultData = contentArr.filter(result => {
        return therapistId == result.therapistId && result.availableOnCall && this.utilities.getDate(time.row1).toString() == this.utilities.getDate(result.scheduleDate).toString()
      });
    }
    if (resultData != null && resultData.length > 0) {
      let timeData, availableTimeString, breakTimeString, onCall, off;
      schedules = [];

      for (let i = 0; i < resultData.length; i++) {
        availableTimeString = breakTimeString = '';
        onCall = resultData[i].availableOnCall;
        off = resultData[i].isOff;
        for (let j = 0; j < resultData[i].availableTime.length; j++) {
          if (j > 0) {
            availableTimeString = availableTimeString + ' | ';
          }
          timeData = this.utilities.getDate(resultData[i].availableTime[j].fromTime);          
          availableTimeString = availableTimeString + this.localization.LocalizeTime(timeData);

          timeData = this.utilities.getDate(resultData[i].availableTime[j].toTime);          
          availableTimeString = availableTimeString + '-' + this.localization.LocalizeTime(timeData);
        }
        for (let j = 0; j < resultData[i].breakTime.length; j++) {
          if (j > 0) {
            breakTimeString = breakTimeString + ' | ';
          }
          timeData = this.utilities.getDate(resultData[i].breakTime[j].fromTime);          
          breakTimeString = breakTimeString + this.localization.LocalizeTime(timeData);

          timeData = this.utilities.getDate(resultData[i].breakTime[j].toTime);          
          breakTimeString = breakTimeString + '-' + this.localization.LocalizeTime(timeData);
        }
        schedules = { 'availableTime': availableTimeString, 'breakTime': breakTimeString, 'onCall': onCall, 'off': off };
      }
    }
    return schedules;
  }
}
