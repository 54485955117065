import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgilysysButtonComponent } from './agilysys-button.component';
import { AgilysysButtonIconPipe } from './pipe/agilysys-button.pipe';
import { AgilysysButtonIconOnlyPipe } from './pipe/agilysys-icon-only.pipe';
import { AgilysysButtonMoreOptionPipe } from './pipe/agilysys-option.pipe';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [AgilysysButtonComponent, AgilysysButtonIconPipe, AgilysysButtonIconOnlyPipe, AgilysysButtonMoreOptionPipe],
  imports: [
    CommonModule,
    MatMenuModule
  ],
  exports: [AgilysysButtonComponent, AgilysysButtonIconPipe, AgilysysButtonIconOnlyPipe, AgilysysButtonMoreOptionPipe]
})
export class AgilysysButtonModule { }
