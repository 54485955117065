import { Injectable } from "@angular/core";
import { DropdownOptions } from "src/app/common/Models/ag-models";
import { SchedulingAssistant, SchedulingAssistantInnerData, SchedulingAssistantView, Service, Therapist, Location, ServiceViewModel, ServiceDetail, SlotResourceFilter } from "src/app/shared/business/shared.modals";
import { SchedulingAssitantInput } from "src/app/shared/scheduling-assistant/scheduling-assistant.modal";
import { SchedulingAssistantService } from "src/app/shared/scheduling-assistant/scheduling-assistant.service";
import { appointmentService } from "src/app/shared/service/appointment.service";
import { SpaUtilities } from "src/app/shared/utilities/spa-utilities";
import { TimeIntervals, WizardTimeSlots } from "../spa-wizard/spa-wizard.modal";
import { WizardSlotBusiness } from "../spa-wizard/spa-wizard.slot.business";

@Injectable()
export class SchedulingAssistantBusiness {

    constructor(
        private wizardSlotBusiness: WizardSlotBusiness,
        private appointmentService: appointmentService,
        private schedulingAssistantService: SchedulingAssistantService,
        private utils: SpaUtilities) {

    }


    async buildSchedulingAssistanceData(serviceId: number, appointmentDate: Date, timeIntervals: TimeIntervals[],
                                        selectedView: SchedulingAssistantView, input: SchedulingAssitantInput): Promise<SchedulingAssistant[]> {
        const selectedService = await this.getServiceAggregateById(serviceId);
        const serviceViewModel = this.mapServiceDetailToServiceViewModel(selectedService.serviceDetail);
        const filterData: SlotResourceFilter = {
            therapistIds : [-1],
            locationIds: [-1],
            ignoreSetupBreakdownTime: false
        };
        this.schedulingAssistantService.availableSlots = await this.wizardSlotBusiness.buildWizardSlots(appointmentDate, serviceViewModel, timeIntervals, input.appointmentId, input.tempHoldIds, filterData);
        return this.buildDatBasedOnSelectedView(selectedService, this.schedulingAssistantService.availableSlots, timeIntervals, selectedView);
    }

    async buildDatBasedOnSelectedView(selectedService: Service, slotData: WizardTimeSlots[], timeIntervals: TimeIntervals[], selectedView: SchedulingAssistantView): Promise<SchedulingAssistant[]> {
        let result: SchedulingAssistant[] = [];
        if (selectedView == SchedulingAssistantView.Therapist) {
            result = await this.buildTherapistData(selectedService, timeIntervals, slotData);
        }
        else if (selectedView == SchedulingAssistantView.Location) {
            result = await this.buildLocationData(selectedService, timeIntervals, slotData);
        }
        return result;
    }

    async buildDataForViewChange(serviceId: number, appointmentDate: Date, timeIntervals: TimeIntervals[], selectedView: SchedulingAssistantView,input : SchedulingAssitantInput) {
        let selectedService = await this.getServiceAggregateById(serviceId);
        if (this.schedulingAssistantService.availableSlots && this.schedulingAssistantService.availableSlots.length > 0) {
            return await this.buildDatBasedOnSelectedView(selectedService, this.schedulingAssistantService.availableSlots, timeIntervals, selectedView);
        }
        else {
            return await this.buildSchedulingAssistanceData(serviceId, appointmentDate, timeIntervals, selectedView, input);
        }
    }

    async buildTherapistData(serviceAgg: Service, timeIntervals: TimeIntervals[], slotData: WizardTimeSlots[]): Promise<SchedulingAssistant[]> {
        let result: SchedulingAssistant[] = [];
        let therapists: Therapist[] = this.appointmentService.managementData['ActiveTherapists'];
        let applicableTherapists = serviceAgg.serviceTherapists.map(r => r.therapistId);
        let data: SchedulingAssistant;
        for (let therapId of applicableTherapists) {
            let therap = therapists.find(r => r.id == therapId);
            if (!therap || !therap.isActive) {
                continue;
            }
            data = {
                id: therapId,
                displayName: `${therap.firstName} ${therap.lastName}`,
                listOrder: therap.listOrder,
                innerData: await this.buildLocationInnerData(serviceAgg, timeIntervals, slotData, therapId),
                slots: []
            };
            for (let interval of timeIntervals) {
                let slot = slotData.find(r => r.dateTime.getTime() == interval.dateTime.getTime());
                let availableTherapists = slot ? slot.availableStaff : [];
                data.slots.push({
                    localizedTime: interval.localizedTime,
                    dateTime: interval.dateTime,
                    isAvailable: availableTherapists && availableTherapists.some(t => t.id == therapId),
                    isServiceAvailable : slot && slot.availableSlots > 0
                });
            }
            result.push(data);
        }
        return result;
    }

    private getAvailableLocationsForSchedule(slotTime: Date, therapistId: number): number {
        let availableScheduleLocation: number = 0;
        if (this.wizardSlotBusiness.therapistAvailability && this.wizardSlotBusiness.therapistAvailability.therapistDetails) {
            let therapistSchedule = this.wizardSlotBusiness.therapistAvailability.therapistDetails.find(r => r.therapist.id == therapistId);
            if (therapistSchedule && therapistSchedule.scheduleTime) {
                let scheduleDuration = therapistSchedule.scheduleTime.find(s => this.utils.getDate(s.startTime).getTime() <= slotTime.getTime() && this.utils.getDate(s.endTime).getTime() >= slotTime.getTime());
                availableScheduleLocation = scheduleDuration ? scheduleDuration.locationId : 0;
            }
        }
        return availableScheduleLocation;
    }


    async buildLocationData(serviceAgg: Service, timeIntervals: TimeIntervals[], slotData: WizardTimeSlots[]): Promise<SchedulingAssistant[]> {
        let result: SchedulingAssistant[] = [];
        let locations: Location[] = this.appointmentService.managementData['UserAccessLocations'];
        let applicableLocations = serviceAgg.serviceLocations.map(r => r.locationId);
        let data: SchedulingAssistant;
        for (let locationId of applicableLocations) {
            let location = locations.find(r => r.id == locationId);
            if (!location) {
                continue;
            }
            data = {
                id: locationId,
                displayName: location.description,
                comments: location.comments,
                listOrder: location.listOrder,
                innerData: await this.buildTherapistInnerData(serviceAgg, timeIntervals, slotData, locationId),
                slots: []
            };
            for (let interval of timeIntervals) {
                let slot = slotData.find(r => r.dateTime.getTime() == interval.dateTime.getTime());
                let availableLocations = slot ? slot.availableLocations : [];
                data.slots.push({
                    localizedTime: interval.localizedTime,
                    dateTime: interval.dateTime,
                    isAvailable: availableLocations && availableLocations.some(t => t.id == locationId),
                    isServiceAvailable : slot && slot.availableSlots > 0
                });
            }
            result.push(data);
        }
        return result;
    }

    private async buildLocationInnerData(serviceAgg: Service, timeIntervals: TimeIntervals[], slotData: WizardTimeSlots[], therapistId: number) {
        let innerData: SchedulingAssistantInnerData[] = [];
        let applicableLocations = serviceAgg.serviceLocations.map(r => r.locationId);
        let locations: Location[] = this.appointmentService.managementData['UserAccessLocations'];
        for (let locationId of applicableLocations) {
            let loc = locations.find(l => l.id == locationId);
            if (!location) {
                continue;
            }
            let data: SchedulingAssistantInnerData = {
                id: locationId,
                displayName: loc.description,
                listOrder: loc.listOrder,
                slots: []
            };
            for (let interval of timeIntervals) {
                let slot = slotData.find(r => r.dateTime.getTime() == interval.dateTime.getTime());
                let availableLocations = slot ? slot.availableLocations : [];
                let therapistAvailableLocation = this.getAvailableLocationsForSchedule(interval.dateTime, therapistId);
                data.slots.push({
                    localizedTime: interval.localizedTime,
                    dateTime: interval.dateTime,
                    isAvailable: availableLocations && availableLocations.some(t => t.id == locationId) && (therapistAvailableLocation == 0 || therapistAvailableLocation == locationId),
                    isServiceAvailable : slot && slot.availableSlots > 0
                });
            }
            innerData.push(data);
        }
        return innerData;
    }

    private async buildTherapistInnerData(serviceAgg: Service, timeIntervals: TimeIntervals[], slotData: WizardTimeSlots[], locationId: number) {
        let innerData: SchedulingAssistantInnerData[] = [];
        let applicableTherapists = serviceAgg.serviceTherapists.map(r => r.therapistId);
        let therapists: Therapist[] = this.appointmentService.managementData['ActiveTherapists'];
        for (let therapistId of applicableTherapists) {
            let therap = therapists.find(l => l.id == therapistId);
            if (!therap || !therap.isActive) {
                continue;
            }
            let data: SchedulingAssistantInnerData = {
                id: therapistId,
                displayName: `${therap.firstName} ${therap.lastName}`,
                listOrder: therap.listOrder,
                slots: []
            };
            for (let interval of timeIntervals) {
                let slot = slotData.find(r => r.dateTime.getTime() == interval.dateTime.getTime());
                let availableTherapists = slot ? slot.availableStaff : [];
                let therapistAvailableLocation = this.getAvailableLocationsForSchedule(interval.dateTime, therapistId);
                data.slots.push({
                    localizedTime: interval.localizedTime,
                    dateTime: interval.dateTime,
                    isAvailable: availableTherapists && availableTherapists.some(t => t.id == therapistId) && (therapistAvailableLocation == 0 || therapistAvailableLocation == locationId),
                    isServiceAvailable : slot && slot.availableSlots > 0
                });
            }
            innerData.push(data);
        }
        return innerData;
    }

    getViewbyType(): DropdownOptions[] {
        return this.schedulingAssistantService.getViewbyType();
    }

    getdisplayOptionFilterData(): any[] {
        return this.schedulingAssistantService.getdisplayOptionFilterData();
    }

    getTimeIntervalType(): DropdownOptions[] {
        return this.schedulingAssistantService.getTimeIntervalType();
    }

    getFormattedDropdownData(data, objectKey?: string): DropdownOptions[] {
        return this.schedulingAssistantService.getFormattedDropdownData(data, objectKey);
    }

    async getServiceAggregateById(id): Promise<Service> {
        let service: Service;
        if (this.schedulingAssistantService.serviceAggregates && this.schedulingAssistantService.serviceAggregates.length > 0) {
            service = this.schedulingAssistantService.serviceAggregates.find(s => s.serviceDetail.id == id);
        }
        if (!service) {
            service = await this.schedulingAssistantService.getServiceAggregateById(id);
        }
        this.schedulingAssistantService.serviceAggregates.push(service);
        return service;
    }

    async buildSlotData(bookingDate: Date, startTime, endTime,serviceDetail: ServiceViewModel,filterData: SlotResourceFilter = null) {
        return this.wizardSlotBusiness.buildSlotData(bookingDate, startTime, endTime,serviceDetail,filterData);
    }

    mapServiceDetailToServiceViewModel(serviceDetail: ServiceDetail): ServiceViewModel {
        let service: any = {
            id: serviceDetail.id,
            isOffsite: serviceDetail.isOffsite,
            minimumGuest: serviceDetail.minimumGuest,
            maximumGuest: serviceDetail.maximumGuest,
            minimumStaff: serviceDetail.minimumStaff,
            maximumStaff: serviceDetail.maximumStaff,
            duration: serviceDetail.time,
            setupTime: serviceDetail.setupTime,
            breakDownTime: serviceDetail.breakDownTime,
            description: serviceDetail.description,
            code: serviceDetail.code
        };
        return service;
    }

    async getAppointmentFilters(uriParams: any): Promise<any> {
        return await this.schedulingAssistantService.getAppointmentFilters(uriParams);
    }

    resetData() {
        this.schedulingAssistantService.availableSlots = null;
        this.schedulingAssistantService.selectedView = SchedulingAssistantView.Therapist;
        this.schedulingAssistantService.serviceAggregates = [];
    }

}