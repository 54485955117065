import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { ExportBusiness } from '../export.business';
import { UI, API } from 'src/app/common/Models/datautilitydetails-model';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss'],
  providers: [ExportBusiness],
})
export class ExportDataComponent implements OnInit {
  exportdataform: UntypedFormGroup;
  header: string;
  captions;
  checkBoxOptions;
  checkBoxOptions$;
  searchText: string;
  isAllSelected = false;
  exportButton: ButtonValue; 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: UntypedFormBuilder, private dialogRef: MatDialogRef<any>,
  private localization: Localization, private business: ExportBusiness) { }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.buildForm();
    this.initializeInputs();
  }

  async initializeInputs() {
    this.header = 'Export';
    this.exportButton = {
      label: this.captions.btn_export,
      type: 'primary',
      disabledproperty: true
    };
    this.checkBoxOptions = await this.business.getCheckBoxList(this.data);
    this.checkBoxOptions$ = this.checkBoxOptions;
  }

  buildForm() {
    this.exportdataform = this.fb.group({
      withData: ''
    })   
  }

  close(e) {
    this.dialogRef.close({
      dataUtilityDetails: null
    });
  }

  searchValueChange(e) {
    this.searchText = e;
    this.checkBoxOptions = Object.assign([], this.checkBoxOptions$).filter(
      item => item.value.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
    )
    // if(this.checkBoxOptions.length == 0)
    //  this.checkBoxOptions = this.checkBoxOptions$;
  }

  enableButton() {
    let flag = this.checkBoxOptions.filter(x => x.checked).length > 0 ? true : false;
    this.exportButton.disabledproperty = !(this.exportdataform.dirty && flag);
  }

  onAllCheck(e: { checked: boolean; }) {
    this.checkBoxOptions.forEach(x => {
      x.checked = e.checked;
    });
    this.checkBoxOptions = [...this.checkBoxOptions];
    this.isAllchecked();
    this.enableButton();
  }
 
  isAllchecked() {
    this.exportdataform.markAsDirty();
    const isEverychecked = this.checkBoxOptions.every(x => x.checked);
    this.isAllSelected = isEverychecked;
    const fiteredStatus = this.checkBoxOptions.filter(x => x.checked);
    this.enableButton();
  }

  statusChange(event, obj, i) {
    this.exportdataform.markAsDirty();
    this.checkBoxOptions[i].checked = event.checked;
    this.checkBoxOptions = [...this.checkBoxOptions];
    this.isAllchecked();
    this.enableButton();
  }

  async export() {
    this.exportButton.disabledproperty = true;
    var dataUtilityDetails = await this.business.export(this.checkBoxOptions$.filter(x => x.checked).map(x => x.value), this.data, API.DataUtilityType.Export);
    this.dialogRef.close({
      dataUtilityDetails: dataUtilityDetails
    });
  }
}
