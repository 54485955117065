import { Injectable } from "@angular/core";
import { SpaLocalization } from "src/app/core/localization/spa-localization";

@Injectable({ providedIn: "root" })
export class ACRBusiness {
  captions: any;
  constructor(private localization: SpaLocalization) {
    this.captions = this.localization.captions.setting;
  }

  /**
   * get cancel policy
   * @returns ACRModel.IOptions[]
   */
  getCancelPolicy(): ACRModel.IOptions[] {
    return [
      { id: 1, name: this.captions.ServiceCancelPolicy },
      { id: 2, name: this.captions.DefaultSystemCancelPolicy },
    ];
  }

  /**
   * get price percentage
   * @returns ACRModel.IOptions[]
   */
  getPricePercentage(): ACRModel.IOptions[] {
    return [
      { id: 1, name: this.captions.FullServicePrice },
      { id: 2, name: this.captions.CustomPercentage },
    ];
  }

  /**
   * get custom percentage
   * @returns ACRModel.ICustomOption[]
   */
  getCustomValue(): ACRModel.ICustomOption[] {
    return [
      { id: 1, value: this.localization.currencySymbol },
      { id: 2, value: "%" },
    ];
  }

  /**
   * get select value
   * @returns ACRModel.ICustomOption[]
   */
  getSelectValue(): ACRModel.ICustomOption {
    return { id: 2, value: "%" };
  }

  /**
   * get personal info form field
   * @returns ACRModel.IFormDetail[]
   */
  getPersonFormInfo(): ACRModel.IFormDetail[] {
    return [
      { id: 1, name: this.captions.Title, controlName: "ACR_TITLE" },
      {
        id: 2,
        name: this.captions.First_Name,
        controlName: "ACR_FIRST_NAME",
      },
      { id: 3, name: this.captions.Last_Name, controlName: "ACR_LAST_NAME" },
      { id: 4, name: this.captions.Birthday, controlName: "ACR_BIRTHDAY" },
      { id: 5, name: this.captions.Gender, controlName: "ACR_GENDER" },
    ];
  }

  /**
   * get contact info form field
   * @returns ACRModel.IFormDetail[]
   */
  getContactFormInfo(): ACRModel.IFormDetail[] {
    return [
      { id: 1, name: this.captions.Address, controlName: "ACR_ADDRESS" },
      { id: 2, name: this.captions.City, controlName: "ACR_CITY" },
      { id: 3, name: this.captions.State, controlName: "ACR_STATE" },
      {
        id: 4,
        name: this.captions.Postal_Code,
        controlName: "ACR_POSTAL_CODE",
      },
      { id: 5, name: this.captions.Country, controlName: "ACR_COUNTRY" },
      { id: 6, name: this.captions.Phone, controlName: "ACR_PHONE" },
      { id: 7, name: this.captions.Email, controlName: "ACR_EMAIL" },
    ];
  }

  /**
   * get payment info form field
   * @returns ACRModel.IFormDetail[]
   */
  getPaymentInfo(): ACRModel.IFormDetail[] {
    return [
      {
        id: 1,
        name: this.captions.Credit_Card,
        controlName: "ACR_CREDIT_CARD",
      },
    ];
  }

  /**
   * get required field form info
   * @returns ACRModel.IFormDetail[]
   */
  getRequiredFieldInfo(): ACRModel.IRequiredField[] {
    return [
      {
        name: this.captions.personalInformation,
        requiredInfo: this.getPersonFormInfo(),
      },
      {
        name: this.captions.contactDetails,
        requiredInfo: this.getContactFormInfo(),
      },
      {
        name: this.captions.paymentDetails,
        requiredInfo: this.getPaymentInfo(),
      },
    ];
  }
}

export namespace ACRModel {
  export interface IOptions {
    id: number;
    name: any;
  }

  export interface ICustomOption {
    id: number;
    value: string;
  }

  export interface IFormDetail {
    id: number;
    name: string;
    controlName: string;
  }

  export interface IRequiredField {
    name: any;
    requiredInfo: ACRModel.IFormDetail[];
  }

  export interface ISwitch {
    [key: string]: any;
  }
}
