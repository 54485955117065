import { GridCustomColExpand } from '../app-grid.interface';
import { CustomExpansion } from '../grid.interface';

export const CustomExpandForEntity: CustomExpansion = (ColumnExpands: GridCustomColExpand[]): any => {
    try {
        let expandString = '';
        ColumnExpands.forEach((element, index) => {
            expandString = expandString + element.PrimaryExpand;
            if (element.SecondaryExpand && element.SelectQuery) {
                expandString += '($expand=' + element.SecondaryExpand + '($select=' + element.SelectQuery + '))';
            } else if (element.SecondaryExpand) {
                expandString += '($expand=' + element.SecondaryExpand + ')';
            } else if (element.SelectQuery) {
                expandString += '($select=' + element.SelectQuery + ')';
            }
            if (expandString.length > 0 && index !== ColumnExpands.length - 1) {
                expandString += ', ';
            }
        });

        return expandString;
    } catch (error) {
        console.log('Error occurred in get column expand');
    }
};