import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActionIconButtons, GridActionsConfig, GridFilterData, GridItemListActions } from '../interface/common';
import { GridHeaderService } from '../grid-header/grid-header.service';
import { HeaderOverlayRef } from '../grid-header/header-overlay-ref';
import * as _ from 'lodash';

@Component({
  selector: 'lib-grid-actions',
  templateUrl: './grid-actions.component.html',
  styleUrls: ['./grid-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridActionsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() gridActions: GridActionsConfig;
  @Input() gridItemListActions: GridItemListActions;
  @Input() rowData: Array<any>;
  @Input() ClearSearchFilter: boolean;
  @Output() searchTextChanged: EventEmitter<any> = new EventEmitter();
  @Output() toggleChanged: EventEmitter<any> = new EventEmitter();
  @Output() dropdownChanged: EventEmitter<any> = new EventEmitter();
  @Output() filterEnabled: EventEmitter<any> = new EventEmitter();
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();
  @Output() fetchFilterData: EventEmitter<GridFilterData> = new EventEmitter();
  @Output() addButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() refreshButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() iconActionClick: EventEmitter<any> = new EventEmitter();
  @Output() globalDeleteClick: EventEmitter<any> = new EventEmitter();
  @Output() deactivateClick: EventEmitter<any> = new EventEmitter();
  searchText: FormControl = new FormControl();
  dropDownsearchControl: FormControl = new FormControl();
  searchSubscription: Subscription;
  @ViewChild('reShuffle') reShuffle: ElementRef;
  @ViewChild('reSizeRows') reSize: ElementRef;
  showFilter = false;
  toggleValue = true;
  selectedData: number;
  EnableFilterSearch: boolean;
  selectedName: string;
  deepCloneFilterArray: Array<any> = [];
  enableDropDownSearch: boolean;
  NoFilterChange = true;
  ClearClicked = false;
  constructor(private gridHeaderService: GridHeaderService) { }

  ngOnInit(): void {
    /**
     * @description search text value changes subscribe>
     */
    this.searchSubscription = this.searchText.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(res => {
        if (res !== undefined && res !== null) {
          this.searchTextChanged.emit(res);
        }
      }, (error) => {
        console.error('Error occurred in subscribe of search', error);
      });
  }

  ngOnDestroy(): void {
    try {
      this.searchSubscription.unsubscribe();
    } catch (error) {
      console.error('Error occurred in ngOnDestroy', error);
    }
  }

  ngOnChanges() {
    if (this.ClearSearchFilter) {
      if (this.searchText.value) {
        this.searchText.setValue('');
      }
      if (this.showFilter) {
        this.gridActions.FilterConfig.FilterOptions.forEach(res => {
          res.FilterValue.forEach(data => {
            data.Checked = false;
          });
        });
        this.applyFilter([this.gridActions.FilterConfig.FilterOptions]);
        this.filterOption();
      }
    }
    if (this.gridActions.GridTitleType && this.gridActions.GridTitleType.TypeConfig &&
        this.gridActions.GridTitleType.TypeConfig.length > 0) {
      this.selectedData = this.gridActions.GridTitleType.SelectedTypeId;
      this.selectedName = this.gridActions.GridTitleType.TypeConfig.filter(x => x.Id === this.selectedData).length > 0 ?
      this.gridActions.GridTitleType.TypeConfig.filter(x => x.Id === this.selectedData)[0].DisplayName : '';
    } else {
      this.selectedData = 0;
      this.selectedName = '';
    }
    const filterApplied = [];
    let filterCheck = false;
    if (this.gridActions.FilterConfig.FilterOptions) {
      this.gridActions.FilterConfig.FilterOptions.forEach(x => {
        filterApplied.push(x.FilterValue.filter(y => y.Checked === true).length > 0 ? true : false);
      });
      filterCheck = filterApplied.filter(x => x === true).length > 0 ? true : false;
    }
    if (this.rowData && this.rowData.length < 2 && !this.searchText.value && !filterCheck) {
      this.EnableFilterSearch = false;
    } else {
      this.EnableFilterSearch = true;
    }
    if (this.deepCloneFilterArray.length === 0) {
      this.deepCloneFilterArray = this.gridItemListActions.DropdownOptions && 
      this.gridItemListActions.DropdownOptions.length > 0 ? _.cloneDeep(this.gridItemListActions.DropdownOptions) : [];
    }
    this.enableDropDownSearch = (this.gridItemListActions.hasOwnProperty('EnableDropDownSearch')
      && this.gridItemListActions.EnableDropDownSearch) ? true : false;
    if (this.enableDropDownSearch) {
      this.dropDownsearchControl.valueChanges.pipe(debounceTime(500)).subscribe(x => {
        const searchKey = x ? x.toString().toLowerCase() : '';
        if (this.deepCloneFilterArray && this.deepCloneFilterArray.length > 0) {
            const filtered = this.deepCloneFilterArray
                .filter(arrayOptions => arrayOptions.Name.toString().toLowerCase().includes(searchKey));
            this.gridItemListActions.DropdownOptions = [...filtered];
        }
      });
    }
  }

  /**
   * @method searchCancel
   * @description cancel in search input clicked
   */
  searchCancel() {
    try {
      this.searchText.setValue('');
      // this.searchTextChanged.emit(this.searchText.value);
    } catch (error) {
      console.error('Error occurred in searchCancel', error);
    }
  }

  openedChange(event): void {    
    this.gridItemListActions.DropdownOptions = this.deepCloneFilterArray;
    if (event === false) {
        this.dropDownsearchControl.reset();
    }
}

  /**
   * @method shuffleColumns
   * @description To open the overlay with columns list
   */
  shuffleColumns() {
    try {
      const dialogRef: HeaderOverlayRef = this.gridHeaderService.open({
        columns: this.gridActions.ShuffleColumnConfig.ColumnsList,
        gridName: this.gridActions.ShuffleColumnConfig.GridName
      }, this.reShuffle);
    } catch (error) {
      console.error('Error occurred in shuffleColumns', error);
    }
  }

  /**
   * @method resizeRows
   * @description To rearrange the height of rows
   */
  resizeRows() {
    try {
      const dialogRef: HeaderOverlayRef = this.gridHeaderService.openResizeRows({
        gridName: this.gridActions.ShuffleColumnConfig.GridName
      }, this.reSize);
    } catch (error) {
      console.error('Error occurred in resizeRows', error);
    }
  }

  /**
   * @method filterOption
   * @description to open the filter section
   */
  filterOption(NoEmit?: any) {
    this.showFilter = !this.showFilter;
    /**
     * Bug Fix - 202783 - Filter icon close and cancel issue
     */
    this.NoFilterChange = this.gridActions.FilterConfig.FilterOptions.every(e =>
      e.FilterValue.every(x => x.Checked === false || x.Value === null && !this.ClearClicked));
    if (!this.showFilter) {
      this.gridActions.FilterConfig.FilterOptions.forEach(res => {
        if (res.Type !== 'daterange' && res.FilterValue && res.FilterValue.length > 0) {
          res.FilterValue.forEach(values => {
            values.Checked = false;
            values.Value = null;
          });
        } else if (res.Type === 'daterange' && res.FilterValue && res.FilterValue.length > 0) {
          res.FilterValue.forEach(values => {
            values.Value = null;
          });
        }
      });
      /**
       * Bug Fix - 202783 - Filter icon close and cancel issue
       */
      if (!this.NoFilterChange || (NoEmit !== undefined && NoEmit === false) || !this.showFilter) {
        this.filterApplied.emit([this.gridActions.FilterConfig.FilterOptions, '']);
      }
    }
    this.filterEnabled.emit(this.showFilter);
  }

  applyFilter(event) {
    this.filterApplied.emit(event);
    this.ClearClicked = (event && event.length > 0) ?
    (event[2] !== undefined) ? event[2] : false : false;
  }

  fetchFilterValues(filterValue: GridFilterData) {
    this.fetchFilterData.emit(filterValue);
  }

  toggleChange(event) {
    try {
      console.log('Toggle Value', event);
      // fixed for this bug 275816
      // this.showFilter = false;
      // this.filterEnabled.emit(this.showFilter);
      this.toggleChanged.emit(event);
    } catch (error) {
      console.error('Error occurred in togglechange', error);
    }
  }

  dropdownChange(event) {
    try {
      this.selectedName = this.gridActions.GridTitleType.TypeConfig.filter(x => x.Id === event.value).length > 0 ?
      this.gridActions.GridTitleType.TypeConfig.filter(x => x.Id === event.value)[0].DisplayName : '';
      this.dropdownChanged.emit(event);
    } catch (error) {
      console.error('Error occurred in dropdownChanged', error);
    }
  }

  addNewButtonClicked(data) {
    try {
      this.addButtonClick.emit(data);
    } catch (error) {
      console.error('Error occurred in addNewButtonClicked', error);
    }
  }

  refreshClick(gridActions) {
    try {
      this.refreshButtonClick.emit(gridActions);
    } catch (error) {
      console.error('Error occurred in refreshClick', error);
    }
  }

  actionIconClick(iconAction: ActionIconButtons) {
    try {
      if (iconAction.Name === 'ColumnConfiguration') {
        this.shuffleColumns();
      } else {
        this.iconActionClick.emit(iconAction);
      }
    } catch (error) {
      console.error('Error occurred in actionIconClick', error);
    }
  }

  globalDelete() {
    try {
      if (this.gridActions.EnableGlobalDelete) {
        this.globalDeleteClick.emit();
      }
    } catch (error) {
      console.error('Error occurred in globalDelete', error);
    }
  }
  deactivateIconClick(event) {
    try {
      if (this.gridActions.ShowDeactivate) {
        this.deactivateClick.emit(event);
      }
    } catch (error) {
      console.error('Error occurred in globalDelete', error);
    }
  }
}
