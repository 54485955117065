import { DisplayNameRemapConfig, FieldDisplayNameRemapConfig, FormDisplayNameRemapConfig } from './data-remap-config.interface';
import { TranslateService } from '@ngx-translate/core';

export const FieldDisplayNameRemap: FieldDisplayNameRemapConfig = 
( remapJson: Array<any>, FieldKey: string, DisplayName: string,  UniqueKey?: string, translateService ?: TranslateService): string => {
    try {
        let displayName = DisplayName;
        if ( translateService && translateService !== undefined && DisplayName !== undefined) {
            const languageKey = DisplayName;
          displayName = translateService.instant(languageKey);
          if(typeof(displayName) === 'object'){
            displayName = translateService.instant(languageKey + '.Name');
          }
        }
        const FieldDisplayName =  ( UniqueKey === undefined ) ? DisplayName : UniqueKey;
        if (remapJson) {
            remapJson.forEach((remap: any) => {
            //  const remapKey = Object.keys(remap)[0].toString();
            const remapKey = remap.Name.toString().toString();
             if ( FieldKey.toLowerCase() === remapKey.toLowerCase()) {
                const getMapAray = remap.UIFieldLookup;
                if ( getMapAray && getMapAray.length > 0 ) {
                    getMapAray.forEach((element: any) => {
                        if ( element.Property_Key.toString().toLowerCase() === FieldDisplayName.toString().toLowerCase()) {
                            const getLangugaeObj = element.UIFieldDisplayNames.filter(( Planguage: any) => Planguage.LanguageId === 1);
                            if ( getLangugaeObj && getLangugaeObj.length > 0 ) {
                                displayName = getLangugaeObj[0].DisplayName;
                            }
                        }  
                     });
                }
             }
            });
        }
        return displayName;
    } catch (error) {
        console.error(error);
    }
};