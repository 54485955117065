import { Pipe, PipeTransform } from '@angular/core';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Pipe({
  name: 'localizeDateTime'
})
export class LocalizeDateTimePipe implements PipeTransform {

  constructor(private localization: SpaLocalization) {

  }
  transform(value: any): any {
    return value ? this.localization.localizeDisplayDate(value) + ' - ' + this.localization.LocalizeTime(value).toUpperCase() : '';
  }

}
