import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { cloneDeep } from 'lodash';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
  encapsulation:ViewEncapsulation.None
})



export class DropdownSearchComponent implements OnInit {
  form: UntypedFormGroup;
  formControlName: any;
  isdisabled: boolean;
  floatLabel: string;
  filteredOptions: {id:number;value:string;viewValue:string}[];
  inputplaceholderName: any;
  unfilteredOptions: any;
  className: any;
  placeholderName: any;
  isoptionsOpened:boolean=false;
  errorMessage: string;
  showRequired: boolean = false;
  @Input('inputs')
  set inputOptions(value) {
    this.form=value?.form;
    this.formControlName=value?.formControlName
    this.isdisabled=value?.isdisabled
    this.inputplaceholderName=value?.inputplaceholderName;
    this.className=value?.className
    this.placeholderName=value?.placeholderName;
    this.errorMessage = value?.errorMessage;
    this.showRequired = value?.showRequired;
  }
  @Output() selectChange = new EventEmitter();
  @Input('filteredOptions')
  set filterOptionsfunc(value) {
    this.filteredOptions=value;
    this.unfilteredOptions=cloneDeep(value);
  }
  constructor(private Localization:Localization,private utils:CommonUtilities) {
    this.floatLabel=this.Localization.setFloatLabel
   }

  

  ngOnInit(): void {
  }

  filterOptionschange(e)
  {
   let searchText=e.target.value?e.target.value.trim():'';

   if(searchText)
   {
    this.filteredOptions=this.unfilteredOptions.filter(x=>x.value.toLowerCase().includes(searchText.toLowerCase()))
   }else{
    this.filteredOptions=this.unfilteredOptions;
   }
   
  }

  setSelected()
  {
  
  }

  optionSelected(e, name: string)
  {
    const selectedValue = this.form.value[name];
    this.selectChange.emit(selectedValue);
  }

  onOpen(e)
  {
   this.utils.addoverLay(true)
  }
  
  onClosed(e)
  {
    this.utils.addoverLay(false)
  }

}
