import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GridConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GridFilterData, GridSortAction, GridTableCard } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { UicontrolInterface } from 'src/app/eatecui/source/shared/models/ui-controls';
import { GridInterface } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { CacheType } from 'src/app/eatecui/source/shared/enum/global.enum';
import { RequestCache } from 'src/app/eatecui/source/core/interceptors/services/request-cache.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-table-cards',
  templateUrl: './table-cards.component.html',
  styleUrls: ['./table-cards.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableCardsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  searchText: FormControl = new FormControl();
  searchSubscription: Subscription;
  @Input() TableData: GridTableCard[];
  @Input() GridData: GridConfig;
  @Output() searchTextChanged: EventEmitter<any> = new EventEmitter();
  @Output() triggeredBottomReached = new EventEmitter<string>();
  @Input() SortOptions: Array<GridSortAction>;
  @Input() SystemPreference: any;
  @Input() selectedInventoryType: any;
  selectedObject: any;
  viewport: HTMLElement;
  @ViewChild('filter') public popover: NgbPopover;
  selectedMaster: any;
  selectedButton = 2;
  productremapJson: Array<any>;
  enableLoader: boolean;
  selectedpanelnumber: number;
  refreshEnabled: boolean;
  selectedFiltersList = [];
  removable = true;
  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService,
    private requestCache: RequestCache,
    private dialog: MatDialog) { 
      const decParams = ( this.route && this.route.snapshot && this.route.snapshot.queryParams.data &&
        Object.keys(this.route.snapshot.queryParams.data).length > 0  && 
        this.isValidJsonData(decodeURIComponent(this.route.snapshot.queryParams.data))) ?
        JSON.parse(decodeURIComponent(this.route.snapshot.queryParams.data)) : undefined;
      this.selectedMaster = (decParams && decParams !== undefined) ? decParams['master'] : undefined;
      if (this.selectedMaster === undefined || this.selectedMaster === '') {
        // const queryParams = this.route.snapshot.queryParams;
        const routerPath = this.route.snapshot.routeConfig.path;
        if (routerPath !== undefined) {
          this.selectedMaster = UicontrolInterface.CheckParams(routerPath);
        }
      }
      // const decParams = JSON.parse(decodeURIComponent(this.route.snapshot.queryParams.data));
      // this.selectedMaster = decParams['master'];
      this.productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
  }
  checkBoxSelected(event) {
    try {
      // console.log('Filter Option clicked', event.checked, this.GridData.GridActions.FilterConfig.FilterOptions);
      if (this.GridData.hasOwnProperty('filterApplied')) {
        this.GridData.filterApplied([this.GridData.GridActions.FilterConfig.FilterOptions]);
      }
    } catch (error) {
      console.error('Error occurred in filterOption', error);
    }
  }

  trackByMethod(index: number, el: any): number {
    return el.Id;
  }

  rowClicked(data: GridTableCard) {
    try {
      if (this.GridData.hasOwnProperty('rowClicked')) {
        this.selectedObject = data.Id;
        this.GridData.rowClicked(data);
      }
    } catch (error) {
      console.error('Error occurred in rowClicked', error);
    }
  }

  toggleChanged() {
    try {
      this.searchText.reset('', {emitEvent: false});
      this.refreshEnabled = false;
      this.selectedpanelnumber = undefined;
      if (this.GridData.hasOwnProperty('toggleChange')) {
        this.GridData.toggleChange(this.selectedButton);
      }
    } catch (error) {
      console.error('Error occurred in toggleChange', error);
    }
  }

  sortChange(data: GridSortAction) {
    try {
      this.SortOptions.forEach(x => {
        x.Checked = x.SortingOdataKey === data.SortingOdataKey && x.SortingType === data.SortingType ? true : false;
      });
      if (this.GridData.hasOwnProperty('sortChanged')) {
        this.GridData.sortChanged(data);
      }
    } catch (error) {
      console.error('Error occurred in sortChanged', error);
    }
  }

  searchCancel() {
    try {
      this.searchText.setValue('');
    } catch (error) {
      console.error('Error occurred in searchCancel', error);
    }
  }

  filterOption() {
    try {
      console.log('Filter Option clicked');
    } catch (error) {
      console.error('Error occurred in filterOption', error);
    }
  }

  scrollEvent = (event) => {
    try {
      if (Math.ceil(event.target.scrollHeight) === Math.ceil(event.target.scrollTop + event.target.offsetHeight) 
        || Math.ceil(event.target.scrollHeight) <= Math.ceil(event.target.scrollTop + event.target.offsetHeight)) {
        if (this.GridData.TotalRecords !== this.GridData.GridData.length) {
          if (this.GridData.hasOwnProperty('bottomReached')) {
            this.GridData.bottomReached(true);
          }
        }
      }
    } catch (error) {
      console.error('Error occurred in scrollEvent', error);
    }
  }

  closePopover() {
    try {
      if (this.popover) {
        this.popover.close();
        this.selectedpanelnumber = undefined;
      }
    } catch (error) {
      console.error('Error occurred in close popover', error);
    }
  }
  /** 
   * isValidJsonData
  */
  isValidJsonData(jsonData: any): boolean {
    try {
      return /^[\],:{}\s]*$/.test(jsonData.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
    } catch (error) {
      console.log(error);
    }
  }
  
  ngAfterViewInit() {
    const _that = this;
    if (this.GridData.GridActions && this.GridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll) {
      this.viewport = document.getElementsByClassName('card-details')[0] as HTMLElement;
      if (this.viewport) {
        this.viewport.addEventListener('scroll', this.scrollEvent);
      }
    }
    const el = document.getElementById('scrollEvent');
    el.addEventListener('scroll', function(e) {
        if (this.scrollHeight - this.scrollTop - this.clientHeight <= 0 ) {
          _that.triggeredBottomReached.emit('reached');
        }
    });
  }

  ngOnChanges() {
    this.selectedButton = this.selectedInventoryType ? this.selectedInventoryType :  2;
    if (this.TableData.length > 0) {
      this.selectedObject = this.TableData[0].Id;
    } else {
      this.selectedObject = 0;
    }
    const FilterEnabled = (this.GridData && this.GridData.GridActions && this.GridData.GridActions.FilterConfig) ? 
    this.GridData.GridActions.FilterConfig.FilterOptions.some(x => x.FilterValue.some(y => y.Checked === true)) : false;
    this.selectedFiltersList = [];
    if (this.GridData && this.GridData.GridActions && this.GridData.GridActions.FilterConfig) {
      this.GridData.GridActions.FilterConfig.FilterOptions.forEach((x: any) => { 
        if (x.FilterValue.length > 0) { 
          x.FilterValue.forEach((y: any) => {
            if (y.Checked) {
              const filterObject = {
                'ParentFilterKey': x.FilterKey,
                'ChildId': y.FilterId,
                'ChildName': y.FilterName
              };
              this.selectedFiltersList.push(filterObject);
            }
          });
        }
      });
    }
    const SortApplied = this.SortOptions.filter(x => x.Checked).length > 0 ? true : false;
    if (FilterEnabled || this.searchText.value || SortApplied) {
      this.selectedObject = null;
    } else {
      this.selectedObject = this.TableData.length > 0 ? this.TableData[0].Id : 0;
    }
  }
  removeFilter(item: any) {
    try {
      this.GridData.GridActions.FilterConfig.FilterOptions.forEach((x: any) => {
        if (x.FilterKey === item.ParentFilterKey) {
          x.FilterValue.forEach((y: any) => {
            if (y.FilterId === item.ChildId && y.FilterName === item.ChildName) {
              y.Checked = false;
            }
          });
        }
      });
      if (this.GridData.hasOwnProperty('filterApplied')) {
        this.GridData.filterApplied([this.GridData.GridActions.FilterConfig.FilterOptions]);
      }
    } catch (error) {
      console.error('Mat Chip Filter Removal Error Occured ', error);
    }
  }
  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.searchSubscription = this.searchText.valueChanges.pipe(debounceTime(500))
      .subscribe(res => {
        if (res !== undefined && res !== null) {
          this.GridData.searchTextChanged(res);
        }
      }, (error) => {
        console.error('Error occurred in subscribe of search', error);
      });
  }

  panelOpened(filter: GridFilterData, index: number) {
    try {
      this.requestCache.manageCacheEngine(CacheType.CLEARALL);
      this.selectedpanelnumber = index;
      if (filter.FilterValue.length === 0 && !this.refreshEnabled) {
        this.enableLoader = true;
        GridInterface.GridSingleFilterFetch(filter, this.httpService).then(res => {
          this.GridData.GridActions.FilterConfig.FilterOptions[index].FilterValue = res;
          this.enableLoader = false;
        });
      }
    } catch (error) {
      console.error('Error occurred in panelOpened', error);
    }
  }

  refreshFilter() {
    try {
      this.refreshEnabled = true;
      this.selectedpanelnumber = undefined;
      this.GridData.GridActions.FilterConfig.FilterOptions.forEach(filter => {
        if (filter['FilterUrl']) {
          filter.FilterValue = [];
        } else {
          filter.FilterValue.forEach(x => x.Checked = false);
        }
      });
      this.enableLoader = true;
      GridInterface.GridFilterRefresh(this.GridData.GridActions.FilterConfig.FilterOptions,
           this.httpService).then(res => {
            this.GridData.GridActions.FilterConfig.FilterOptions = [...res];
            this.enableLoader = false;
            this.refreshEnabled = false;
      });
      if (this.GridData.hasOwnProperty('filterApplied')) {
        this.GridData.filterApplied([this.GridData.GridActions.FilterConfig.FilterOptions]);
      }
    } catch (error) {
      console.error('Error occurred in refreshFilter', error);
    }
  }
}
