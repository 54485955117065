import { RetailItemType } from "../retail.modals";
import { PaymentMethods } from "../shared/business/shared.modals";

export const NonPayAgentMethods: PaymentMethods[] = [
    PaymentMethods.Cash,
    PaymentMethods.RainCheck,
    PaymentMethods.ARPost,
    PaymentMethods.ARAcctPost,
    PaymentMethods.RedeemPoint,
    PaymentMethods.CreditBook,
    PaymentMethods.OfferRedemption,
    PaymentMethods.CompSlipRedemption,
    PaymentMethods.PostToFolio,
];

export const GiftCardMethods: PaymentMethods[] = [
    PaymentMethods.V1GiftCard,
    PaymentMethods.V1GiftCardIdTech,
    PaymentMethods.ExternalGiftCard,
    PaymentMethods.ExternalGiftCardIdTech,
    PaymentMethods.AgilysysGiftCard,
    PaymentMethods.AgilysysGiftCardIdTech
];

export const V1GiftCardMethods: PaymentMethods[] = [
    PaymentMethods.V1GiftCard,
    PaymentMethods.V1GiftCardIdTech
];

export const AgilysysGCMethods: PaymentMethods[] = [
    PaymentMethods.AgilysysGiftCard,
    PaymentMethods.AgilysysGiftCardIdTech
];

export const ExternalGCMethods: PaymentMethods[] = [
    PaymentMethods.ExternalGiftCard,
    PaymentMethods.ExternalGiftCardIdTech
];

export const MethodsWhichHasPaymentConfirmationPopup = [
    PaymentMethods.Cash,
    PaymentMethods.CardOnFile,
    PaymentMethods.V1GiftCard,
    PaymentMethods.V1GiftCardIdTech,
    PaymentMethods.ExternalGiftCard,
    PaymentMethods.ExternalGiftCardIdTech,
    PaymentMethods.ARPost,
    PaymentMethods.RedeemPoint,
    PaymentMethods.CreditBook,
    PaymentMethods.AgilysysGiftCard,
    PaymentMethods.AgilysysGiftCardIdTech
];

export const CardPaymentMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH];

export const SurchargePaymentMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH, PaymentMethods.CardOnFile, PaymentMethods.NonIntegratedCreditCard];

export enum PaymentTransactionState {
	NotStarted,
	InProgress,
	Closed,
	None
}

export enum RetailScreenType {
    Shop = 1,
    FolioDefinition,
    FolioPosting,
    IndividualCheckOut,
    FolioMakePayment
}

export enum RainCheckEventType {
    AddRaincheckItem = 1,
    RemoveRainCheckItem
}

export enum PaymentOptionType {
    FullPayment = 1,
    MultiPayment
}

export enum PaymentAPIFlow {
    CreateTransaction = 1,
    GetHandles,    
    TryPay,
    Sale,
    Credit,
    SaleByToken,
    CreditByToken,
    Load,
    Issue,
    ValidatePay,
    ValidateGiftcard,
    ValidateCashBack,
    Pay
}

export const RoomGrpChargeMethod = [PaymentMethods.RoomCharge, PaymentMethods.GroupCharge]

export const GiftcardIDTechMethodMapper = {
    [PaymentMethods.V1GiftCardIdTech]: PaymentMethods.V1GiftCard,
    [PaymentMethods.ExternalGiftCardIdTech]: PaymentMethods.ExternalGiftCard,
    [PaymentMethods.AgilysysGiftCardIdTech]: PaymentMethods.AgilysysGiftCard,
}

export const GiftcardIDTechMethods = [
    PaymentMethods.ExternalGiftCardIdTech,
    PaymentMethods.AgilysysGiftCardIdTech,
    PaymentMethods.V1GiftCardIdTech
]

export const MemberShipMethods = [
    PaymentMethods.ARPost,
    PaymentMethods.CreditBook,
    PaymentMethods.RedeemPoint
]

export const RWT_TransactionTypes = [
    "RETURN WITHOUT TICKET",
    "RETURN WITH TICKET WITHOUT EXCHANGE",
    "RETURN WITH TICKET WITH EXCHANGE"
];

export const CancellationNoShowItemType = [    
    , RetailItemType.CancellationFee
    , RetailItemType.NoShowFee
];

export const SpaPackageItemType = [    
    , RetailItemType.SpaPackage
];