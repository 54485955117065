import { Injectable } from '@angular/core';
import { API } from 'src/app/common/components/dedupe-guest-records/dedupe-guest-ui-model';
import { API as GuestAPI } from 'src/app/common/components/combine-guest-records/combine-guest-ui-model';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { Host } from 'src/app/common/shared/shared/globalsContant';


@Injectable({
  providedIn: 'root'
})
export class DeDupeGuestDataService {

  constructor(private http: HttpServiceCall) { }

  public async getGuestsBySearchCriteria(guestSearchFields: API.GuestSearchFields): Promise<API.Guest[]> {
    try {
      return await this.http.CallApiAsync({
        callDesc: 'GetDedupeGuestInformation',
        host: Host.spaManagement,
        method: HttpMethod.Post,
        body: guestSearchFields
      }) as any;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }



  public async MergeGuestsRecords(primaryGuest: string, secondaryGuest: string[]): Promise<boolean> {
    try {
      return await this.http.CallApiAsync({
        callDesc: 'CombineGuestInformation',
        host: Host.spaManagement,
        method: HttpMethod.Put,
        uriParams: { primaryGuestId: primaryGuest },
        body: secondaryGuest
      }) as any;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  
  public async getClientDataByGuid(guestId: any): Promise<GuestAPI.Guest> {
    return this.http.CallApiAsync({
      host: Host.spaManagement,
      callDesc: "GetGuestInfoByGuid",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { id: guestId }
    }) as any;
  }

  public async UpdateGuestInformation(primaryGuestId: string, guestInfo: GuestAPI.Guest): Promise<string> {
    try {
      return await this.http.CallApiAsync({
        callDesc: 'UpdateGuestInformation',
        host: Host.spaManagement,
        method: HttpMethod.Put,
        uriParams: { id: primaryGuestId },
        body: guestInfo
      }) as any;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
}