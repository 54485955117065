import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    CalcultionField,
    GridConfiguration, GridCustomColExpand,
    GridFilterData,
    MasterInventoryManagement} from '../master.interface';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import {
    AutoCompleteData,
    DropDownDataModel,
    GridActionRender,
    GridActionsConfig, GridColumnReorder, GridConfig, GridFilter, GridInfiniteScrollConfig,
    GridPaginationConfig, GridResizeRows, GridSearchConfig
} from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { TranslateService } from '@ngx-translate/core';
import { FormGridDataConfiguration } from './form-config.interface';
import { Formaters } from '../../formaters';
import { GridInterface } from '../grid-interface';
import { MasterManagementService } from '../../service/master.service';
import * as _ from 'lodash';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';
export const FormCreateNestedGridDetail: FormGridDataConfiguration =
    (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
        httpService: HttpService, translateService: TranslateService, masterManagementService: MasterManagementService, 
        AllViewModelEntity: any): any => {
        try {
            return new Promise<any>((resolve, reject) => {
                if (ViewModelEntity.length > 0 && MasterDataCollection) {
                    if (MasterDataCollection.NestedFormConfig && MasterDataCollection.NestedFormConfig.EditFormConfig) {
                        MasterDataCollection.NestedFormConfig.EditFormConfig.forEach((SubForm: any) => {
                            if (SubForm.Type === 'Grid' || SubForm.Type === 'ListingGrid') {
                                const ViewModelName = SubForm.hasOwnProperty('CustomOdataKey')
                                && SubForm.CustomOdataKey ? SubForm.CustomOdataKey : SubForm.OdataKey;
                                const ChildNavigationProperty = AllViewModelEntity
                                .filter( view => view['$']['Name'] === ViewModelName)[0];
                                SubForm.NestedGridInfo = AssignGridData(
                                [], SubForm, MasterDataCollection, translateService, masterManagementService,
                                ChildNavigationProperty, httpService, ViewModelEntity
                                );
                            } else if (SubForm.OdataKey === 'ProductItemDetails') {
                                SubForm.LinkedItems.LinkItemGridConfig.GridHeaderDefination.map(values => {
                                    const text = SubForm.Name + '.' + values.field;
                                    translateService.get(text).subscribe(res => {
                                        values.headerName = res;
                                    });
                                    const entityName = ViewModelEntity[0]['$']['Name'].toString();
                                    const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                                    values.headerName = ProductDataRemap.FieldDisplayNameEngine(
                                        productremapJson, entityName, values.headerName, entityName + SubForm.Name + `-${values.field}`);
                                });
                                SubForm.LinkedItems.LinkItemGridConfig.GridHeaderDefination.forEach((header: any) => {
                                        if (header.field === 'icon' &&
                                        header.hasOwnProperty('categoryIcon') && header.categoryIcon.EnableCategoryIcon) {
                                            header.cellRenderer = function (params: any) {
                                            let a = `<div class='grid-icon'>`;
                                            const IconObject = CategoryIcons.categoryIconName(params.data);
                                            console.log(IconObject);
                                            if (IconObject !== null && IconObject !== undefined &&
                                            IconObject) {
                                                    a += `<i class="${IconObject.IconName}" title="${IconObject.IconToolTip}"></i>`;
                                            }
                                            a += `</div>`;
                                            return a;
                                        };
                                        header.minWidth = '10';
                                    }
                                });
                                SubForm.LinkedItems.LinkItemGridConfig.GridData = [];
                            }

                        });
                        resolve(MasterDataCollection.NestedFormConfig);
                    }
                }
            });
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function AssignGridData(
    dataObject: any, 
    SubForm: any, 
    MasterDataCollection: any, 
    translateService: TranslateService, 
    masterManagementService: MasterManagementService,
    ChildNavigationProperty: any, 
    httpService: HttpService, ViewModelEntity: any ): any {
    try {
        const entityName = ViewModelEntity[0]['$']['Name'].toString();
        const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
        const gridData = {} as GridConfig;
        gridData.GridData = assignRowData([], ChildNavigationProperty);
        if ( SubForm.OdataKey !== 'SupplierBids' &&  SubForm.OdataKey !== 'IngredientsBids') {
            gridData.GridData = gridData.GridData.filter( x => x.IsActive === true);
        }
        if (MasterDataCollection.FormConfig.NavigationCollection.filter(x => x.odataKey === SubForm.OdataKey).length > 0) {
            const index = MasterDataCollection.FormConfig.NavigationCollection.findIndex(x => x.odataKey === SubForm.OdataKey);
            MasterDataCollection.FormConfig.NavigationCollection[index].ModelCollection = gridData.GridData;
        }
        if (SubForm.data) {
            SubForm.data.forEach(data => {
                data.NestedData.forEach(formdata => {
                    if (formdata.Calculation) {
                        gridData.GridData.forEach(rowData => {
                            const returnValue = calculationOperation(formdata.Calculation, rowData);
                            rowData[formdata.OdataKey] = returnValue;
                        });
                    }
                });
            });
        }
        const actionRenderer = getActionRendererColumn(SubForm.NestedGridData);
        let headerDefs = [];
        if (SubForm.NestedGridData) {
            headerDefs = SubForm.NestedGridData.GridHeaderDefination.map(values => {
                const text = SubForm.Name + '.' + values.field;
                translateService.get(text).subscribe(res => {
                    values.headerName = res;
                });
                values.headerName = ProductDataRemap.FieldDisplayNameEngine(
                productremapJson, entityName, values.headerName, entityName + SubForm.Name + `-${values.field}`) ;
                if (values.type === 'dateColumn') {
                    values.valueFormatter = Formaters.dateformater;
                } else if (values.columnType === 'decimal') {
                    values.valueFormatter = Formaters.decimalformater;
                } else if (values.columnType === 'currency') {
                    values.valueFormatter = Formaters.currencyFormater;
                }
                if (values.cellRenderer !== 'actionRenderer') {
                    values.resizable = true;
                    values.minWidth = '75';
                    values.tooltipField = values.field;
                  }
                const columnHide = ProductDataRemap.FieldVisibleRemapEngine(
                productremapJson, entityName, entityName + SubForm.Name + `-${values.field}`);
                values.hide = !columnHide;
                if (values.hasOwnProperty('cellEditor') && values.cellEditor === 'autocomplete') {
                    values.valueFormatter = (params: any, fromGridSearch = false) => {
                        if (params.searchParams && fromGridSearch) {
                            const ElementName = 'Name';
                            const filter = 'contains(cast(' + ElementName + ',Edm.String),\'' + params.searchParams + '\')';
                            const GetDataFilter = encodeURIComponent(`${filter}`).replace(/[!'()*]/g, function (c) {
                                return '%' + c.charCodeAt(0).toString(16);
                            });
                            const Supplierparams: QueryParams = {
                                count: true,
                                top: InfiniteScrollItemCount,
                                skip: 0,
                                expand: '',
                                select: '',
                                filter: '',
                                orderby: '',
                                isactive: true,
                                search: '',
                                filterQuery: GetDataFilter,
                                searchtext: ''
                            };
                            const GetDataByIdUrl =  `/inventory/api/${params.colDef.field}${formQueryParams(Supplierparams)}`;
                            httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                                const SupplierData: Array<AutoCompleteData> = [];
                                   const SuppliersValue = x['value'];
                                const supplierList = SuppliersValue.map((e: any) => {
                                      const customSullierData = {} as AutoCompleteData;
                                      customSullierData.Id = e.Id;
                                      customSullierData.Value = e.Name;
                                      return customSullierData; 
                            });
                            params.colDef.cellEditorParams.selectData = [...supplierList];
                            params.selectData = [...supplierList];
                        });
                        }
                   
                    };
                }
                // For create mode there is no need of cell editor dropdown call - Need to confirm
                // if (values.hasOwnProperty('cellEditor') && values.cellEditor === 'gridDropDown') {
                //     const GetDataByIdUrl =  values.cellEditorParams.enpointUrl;
                //     httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                //            const responseValue = x['value'];
                //              const dropDownArray = [];
                //              const mappedList = responseValue.map((e: any) => {
                //               const checkActiveBool  = ( e.hasOwnProperty('IsActive') ) ? ( e.IsActive ) ? true : false : true;
                //               if ( checkActiveBool ) {
                //               const dropdownData = {} as DropDownDataModel;
                //               dropdownData.Id = e[values.cellEditorParams.keyId];
                //               dropdownData.Value = e[values.cellEditorParams.keyName];
                //               dropDownArray.push(dropdownData);
                //               }
                //             });
                //             values.cellEditorParams.dataList = [...dropDownArray];
                // });
                // }
                return values;
            });
            if (SubForm.NestedGridData.GridRowActions.ShowActionColumn) {
                headerDefs.push(actionRenderer);
            }
        }
        gridData.GridHeaderDefination = headerDefs;
        gridData.TotalRecords = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions = {} as GridActionsConfig;
        gridData.GridActions.ShowAddButton = SubForm.NestedGridData.ShowAddNew ? SubForm.NestedGridData.ShowAddNew : false;
        gridData.GridActions.ShowRefresh = SubForm.NestedGridData.ShowRefresh ? SubForm.NestedGridData.ShowRefresh : false;
        gridData.GridActions.ShowActiveIndication = SubForm.NestedGridData.ShowActiveIndication ? 
        SubForm.NestedGridData.ShowActiveIndication : false;
        gridData.GridActions.GridTitle = ProductDataRemap.FieldDisplayNameEngine( 
        productremapJson, entityName, SubForm.DisplayName, entityName + SubForm.Name + `-${SubForm.DisplayName}`);
        gridData.GridActions.TotalItems = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
        gridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
        gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
        gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
        gridData.GridActions.SearchConfig = {} as GridSearchConfig;
        if (SubForm.NestedGridData.hasOwnProperty('GridSearch') && SubForm.NestedGridData.GridSearch.length > 0) {
            gridData.GridActions.SearchConfig.EnabledSearch = true;
            gridData.GridActions.SearchConfig.SearchType = SubForm.NestedGridData.GridSearch[0]['SearchKey'];
            gridData.GridActions.SearchConfig.SearchPlaceholder = SubForm.NestedGridData.GridSearch[0]['SearchPlaceholderName'];
            gridData.GridActions.SearchConfig.SearchKey = SubForm.NestedGridData.GridSearch[0]['SearchKey'];
            gridData.GridActions.SearchConfig.SearchPlaceholder = ProductDataRemap.FieldDisplayNameEngine( 
            productremapJson, entityName, gridData.GridActions.SearchConfig.SearchPlaceholder, entityName + SubForm.Name + `-${gridData.GridActions.SearchConfig.SearchPlaceholder}`);
        }
        if (SubForm.NestedGridData && SubForm.NestedGridData.hasOwnProperty('GridFilter') && SubForm.NestedGridData.GridFilter.length > 0) {
            gridData.GridActions.FilterConfig = {} as GridFilter;
            const gridFilter = {} as GridFilter;
            gridFilter.EnableFilter = true;
            gridFilter.FilterOptions = {} as GridFilterData[];
            gridData.GridActions.FilterConfig = gridFilter;
            gridData.GridActions.FilterConfig.FilterOptions = GridInterface.GridCustomFilter( 
            ViewModelEntity, MasterDataCollection, httpService, SubForm.NestedGridData.GridFilter) as unknown as GridFilterData[];
        }
        gridData.GridActions.PaginationConfig = {} as GridPaginationConfig;
        gridData.GridActions.PaginationConfig.EnabledPaginationation = false;
        gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
        gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
        gridData.rowActionClicked = SubForm.NestedGridData.rowActionClicked;
        gridData.addNewButtonClicked = SubForm.NestedGridData.addNewButtonClicked;
        gridData.refreshButtonClicked = SubForm.NestedGridData.refreshButtonClicked;
        gridData.searchTextChanged = SubForm.NestedGridData.searchTextChanged;
        gridData.filterApplied = SubForm.NestedGridData.filterApplied;
        gridData.onCellValueChanged = SubForm.NestedGridData.onCellValueChanged;
        gridData.rowClicked =  SubForm.NestedGridData.rowClicked;
        gridData.rowValueChanged =  SubForm.NestedGridData.rowValueChanged;
        // if (masterManagementService && masterManagementService.cloneGridData) {
        //     masterManagementService.cloneGridData.forEach(element => {
        //         if (element.Name === SubForm.Name) {
        //             element.NestedGridInfo.GridData = _.clone(gridData.GridData);
        //         }
        //     });
        // }
        return gridData;
    } catch (error) {
        console.error('Error occurred in AssignGridData', error);
    }
}


function getActionRendererColumn(MasterDataCollection: GridConfiguration) {
    if (MasterDataCollection.GridRowActions) {
        const actionRenderer: GridActionRender = {
            headerName: MasterDataCollection.GridRowActions.HeaderName,
            cellRenderer: MasterDataCollection.GridRowActions.CellRenderer,
            hide: false,
            editable: false,
            field: '',
            showIcon: true,
            showButton: false,
            pinned: 'right',
            width: '120',
            Edit: MasterDataCollection.GridRowActions.Edit,
            Delete: MasterDataCollection.GridRowActions.Delete,
            Deactivate: MasterDataCollection.GridRowActions.Deactivate,
            Activate: MasterDataCollection.GridRowActions.Activate,
            Copy: MasterDataCollection.GridRowActions.Copy,
            Bidhistory: MasterDataCollection.GridRowActions.Bidhistory,
            UnlinkBids: MasterDataCollection.GridRowActions.UnlinkBids
        };
        return actionRenderer;
    }
}

function assignRowData(ResponseData, ViewModelEntity) {
    try {
        if (ResponseData) {
            ResponseData.forEach( (dataobject, index) => {​​​​​​​​​
                dataobject['RowDataId'] = index + 1;
                for (const prop in dataobject) {​​​​​​​​​
                    if (prop) {​​​​​​​​​
                        if (dataobject[prop] && dataobject[prop] !== null) {​​​​​​​​​
                            const propType = typeof dataobject[prop];
                            const isArrayprop = Array.isArray(dataobject[prop]);
                            if (propType === 'object' && !isArrayprop) {​​​​​​​​​
                                const objProperity = dataobject[prop];
                                assignNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                            }​​​​​​​​​
                        }​​​​​​​​​
                    }​​​​​​​​​
                }   ​​​​​​​​​
            }​​​​​​​​​);
            return ResponseData;
        } else {
            return [];
        }
    } catch (error) {
        console.log('error occured assignRowData', error);
    }
}

function assignNavigationProperity (ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
      ViewModelEntityColl &&
      ViewModelEntityColl.hasOwnProperty('NavigationProperty') &&
      ViewModelEntityColl['NavigationProperty'].length > 0 ) {
       ViewModelEntityColl['NavigationProperty'].forEach((element: any) => {
         const navProperiperityName = element['$']['Name'].toLowerCase().toString();
         if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
            //  if (element['ReferentialConstraint']) {
            //     const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toString();
                entityObject[ProperityName] = ProperirtyObject['Name'];
            //  }
         }
       });
    }
    return entityObject;
 }

 function calculationOperation(calcField: CalcultionField, FormData: any) {
    try {
      let returnValue = 0;      
      let FirstOperand = isNaN(parseFloat(FormData[calcField.OperandFields[0].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[0].OdataKey]);
        let SecondOperand = isNaN(parseFloat(FormData[calcField.OperandFields[1].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[1].OdataKey]);
      if (isNaN(FirstOperand)) {
        FirstOperand = 0;
      }
      if (isNaN(SecondOperand)) {
        SecondOperand = 0;
      }
      switch (calcField.OperationType) {
        case 'Add':
          returnValue = FirstOperand + SecondOperand;
          break;
        case 'Multiply':
          returnValue = FirstOperand * SecondOperand;
          break;
        case 'Divide':
          if (FirstOperand !== 0 && SecondOperand !== 0) {
              returnValue = FirstOperand / SecondOperand;
              if (isNaN(returnValue)) {
                returnValue = 0;
            }
          }
          break;
      }
      return returnValue;
    } catch (error) {
      console.error('Error occurred in calculationOperation', error);
    }
  }