export enum MasterEnum {
    Customer = 'Customer',
}

export enum NestedType {
  Listing = 'listing',
  Grouping = 'grouping',
  Form = 'form',
  Print = 'print'
}

export enum PackageTypes {
  Inventory = 1,
  Recipe = 2,
  Equipment = 3,
  customer = 5,
  Supplier = 6,
  Product = 7
}

export enum MasterKeys {
  MasterIngredients = 'Ingredients',
  MasterCustomers = 'Customer',
  MasterSuppliers = 'Suppliers',
  MasterPackages = 'Packages',
  MasterRetailProducts = 'retailproduct',
  MasterRecipe = 'Recipes',
  MasterProduct = 'Products'
}


export enum MasterName {
  MasterIngredients = 'Ingredients',
  MasterCustomers = 'Customer',
  MasterSuppliers = 'Suppliers',
  MasterPackages = 'Packages',
  MasterProducts = 'Products',
  MasterGroup = 'Group',
  MasterReceipe = 'Recipes'
}




export enum NutrientsIdentify {
  NutrientValue2 = 'NutrientValue2',
  NutrientValue1 = 'NutrientValue1',
  NutrientPercentageValue = 'NutrientPercentageValue'
}
  
export enum NutrientVitaminUSDA {
  VitaminAIU = '318',
  VitaminARE = '392',
  VitaminAREU = '320'
}


export enum ModeType {
  OnlyEdit = 'OnlyEdit',
  OnlyCreate = 'OnlyCreate',
  Both = 'Both'
}