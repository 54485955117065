import { Pipe, PipeTransform } from '@angular/core';
// import * as TileJson from '../../../assets/json/transaction/tiles.json';
export const TileJson = [
  {
      TileName: 'Approved',
      IconName: 'Requisiton-Approved1'
  },
  {
      TileName: 'Pending',
      IconName: 'Requisiton-Pending1'
  },
  {
      TileName: 'On Hold',
      IconName: 'Requisiton-Onhold1'
  },
  {
      TileName: 'Denied',
      IconName: 'requisition-denied'
  },
  {
      IconName: 'Requisiton-Canceled1',
      TileName: 'Cancelled'
  },
  {
      TileName: 'APPROVED SPOILAGE',
      IconName: 'Requisiton-Approved1'
  },
  {
      TileName: 'TOTAL SPOILAGE',
      IconName: 'Requisiton-Approved1'
  },
  {
      TileName: 'ONHOLD SPOILAGE',
      IconName: 'Requisiton-Onhold1'
  },
  {
      TileName: 'DENIED SPOILAGE',
      IconName: 'requisition-denied'
  },
  {
      IconName: 'Requisiton-Canceled1',
      TileName: 'CANCELLED SPOILAGE'
  },
  {
      TileName: 'TOTAL TRANSFERS',
      IconName: 'total-transfer'
  },
  {
      TileName: 'Transfers In ',
      IconName: 'transfer-in'
  },
  {
      TileName: 'TRANSFER OUT',
      IconName: 'transfer-out'
  },
  {
      TileName: 'PENDING SUBMISSION',
      IconName: 'Requisiton-Pending1'
  },
  {
      TileName: 'PARTIAL',
      IconName: 'Requisition-Partial-Icon1'
  },
  {
      TileName: 'FINALIZED',
      IconName: 'Requisiton-Finalized1'
  },
  {
      TileName: 'PENDING APPROVAL',
      IconName: 'Requisiton-Pending1'
  },
  {
      TileName: 'COMPLETED',
      IconName: 'Requisiton-Approved1'
  },
  {
      TileName: 'DEFINITE',
      IconName: 'Requisiton-Approved1'
  },
  {
      TileName: 'TENTATIVE',
      IconName: 'Events_Icon-01-1'
  },
  {
      TileName: 'DEPLETED',
      IconName: 'Depleted_KPI-01'
  },
  {
      TileName: 'INVOICED',
      IconName: 'Invoiced_KPI-01'
  }
];
@Pipe({
  name: 'tileicon'
})
export class TileIconPipe implements PipeTransform {
  // TileIconJson = (TileJson as any).default;
  TileIconJson = TileJson;
  transform(str: any, args: unknown[]): unknown {
    if (str) {
      const returnValue = this.TileIconJson.filter(x => x.TileName.toLowerCase().trim() === str.toLowerCase().trim())[0];
      if (returnValue) {
        return returnValue.IconName;
      } else {
          return 'requisition-denied';
      }
    } else {
      return str;
    }
  }
}

