import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SettingsService } from '../../settings.service';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';
import { UserOutletAccessDataService } from '../useroutletaccess.data.service';

@Component({
  selector: 'app-user-setup',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [UserOutletAccessDataService]
})
export class UserSetupComponent implements OnInit, OnDestroy {

  captions: any;
  tableoptions: any[];
  searchText: any;
  searchFilter: any = [];
  Categories: any[];
  tableData: any = [];
  tableDataCopy: any = [];
  products: any = [];
  roles: any = [];
  rolelst : any = [];
  usersInfo: any = [];

  IsReadOnly: boolean;
  hasAccess: boolean = true;
  dialogSubscription: ISubscription;

  FormGrp: UntypedFormGroup;
  searchValue: boolean = true;
  floatLabel:string;
  floatLabelNever:string;
  isADB2CConfigEnabled:boolean=false;

  constructor(private Form: UntypedFormBuilder, public localization: SpaLocalization, private dialog: MatDialog,
    private _servicesetting: SettingsService,
    private http: HttpServiceCall,
    private utils: SpaUtilities, private BPoint: BreakPointAccess,
    private _userOutletsService: UserOutletAccessDataService) {
      this.floatLabel = this.localization.setFloatLabel;
      this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this._servicesetting.tabLoaderEnable.next(false);
    this.captions = this.localization.captions.userConfig;
    this.FormGrp = this.Form.group({
      searchtext: '',
    });

    if (!this.BPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.UserSetup])) {
      this.hasAccess = false;
      return;
    }
    this.IsReadOnly = this.BPoint.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.UserSetup);
    this.GetServiceCall('GetProductsByPropertyId', { propertyId: Number(this.utils.GetPropertyInfo('PropertyId'))});
    this.GetServiceCall('GetActiveUserRolesByPropertyId', {  propertyId: Number(this.utils.GetPropertyInfo('PropertyId')) , includeInActive : false});
    this.GetRetailServiceCall('GetOutlets', { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
    this.GetServiceCall('GetAllUsers', { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) });
    this.GetSPAServiceCall('GetAllGroups');
    this.GetSPAServiceCall('getActiveTherapist');
    this.GetServiceCall('GetADB2CEnableConfig',{ tenantId: Number(this.utils.GetPropertyInfo('TenantId')) })
    this.Categories = [
      {
        'id': 1,
        'name': 'application',
        'title': this.captions.Application,
        'filters': [],
        'filtered': [],
         'selectionType': 'multiple'
      },
      {
        'id': 2,
        'name': 'userRole',
        'title': this.captions.UserRole,
        'filters': [],
        'filtered': [],
        'selectionType': 'multiple'
      },
      {
        'id': 3,
        'name': 'outlet',
        'title': this.captions.Outlet,
        'filters': [],
        'filtered': [],
        'selectionType': 'multiple'
      },
      {
        'id': 4,
        'name': 'blockStatus',
        'title': this.captions.BlockStatus,
        'filters': [{ id: 0, name: this.localization.captions.common.all, 'isAll': true },
        { id: 2, name: this.captions.Blocked, value: true },
        { id: 3, name: this.captions.Unblocked, value: false }],
        'filtered': [],
        'selectionType': 'multiple'
      },
      {
        'id': 5,
        'name': 'activeStatus',
        'title': this.captions.ActiveStatus,
        'filters': [{ id: 0, name: this.localization.captions.common.all, 'isAll': true },
        { id: 2, name: this.captions.Active, value: true },
        { id: 3, name: this.captions.Inactive, value: false }],
        'filtered': [],
        'selectionType': 'multiple'
      }
    ]

  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  displayFn(searchVal: any) {
    if (searchVal) { return searchVal.value; }
  }

  bindTable(tableData) {
    let header = [{ 'title': this.captions.UserID, 'jsonkey': 'userId', 'alignType': 'left' },
    { 'title': this.captions.Name, 'jsonkey': 'name', 'alignType': 'left', "showStatus": true },
    { 'title': this.captions.Email, 'jsonkey': 'email', 'alignType': 'left' },
    { 'title': this.captions.ApplicationAllowed, 'jsonkey': 'applicationAllowed', 'alignType': 'left' },
    { 'title': this.captions.Roles, 'jsonkey': 'roles', 'alignType': 'left' },
    { 'title': this.captions.CreatedOn, 'jsonkey': 'createdOn', 'alignType': 'left','sortcolumn':'createdOn_Min' },
    { 'title': this.captions.LastAccessedOn, 'jsonkey': 'lastAccessedOn', 'alignType': 'left','sortcolumn':'lastAccessedOn_Min' }];
    this.tableoptions = [{
      TableHdrData: header,
      TablebodyData: tableData,
      EditMoreOption: false,
      Sortable: 'userId',
      SelectedSettingId: GlobalConst.GridType.userSetup,
      CustomColumn: true,
      TableSearchText: this.FormGrp.value.searchtext,
      EnableActions: true,
      sticky: true,
      userAction: true,
      disableDelete: true,
      TableDraggable: false
    }]
  }

  createUser(type) {
    let Dialogtitle;
    if (type == 'New') {
      this.ResetServiceAttributes();
      Dialogtitle = this.captions.NewUser;
    }
    else {
      Dialogtitle = this.captions.EditUser;
    }

    let popupConfiguration: popupConfig;
    popupConfiguration = {
      operation: type
    }
    let DialogTemplate = 'NU';
    let dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      height: '80%',
      width: '1000px',
      data: { headername: Dialogtitle, closebool: true, templatename: DialogTemplate, datarecord: '', popupConfig: popupConfiguration, isADB2CConfigEnabled: this.isADB2CConfigEnabled },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });


    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ResetServiceAttributes();
        this.GetServiceCall('GetAllUsers', { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) });
      }
    });
  }

  onChange() {
    this.bindTable(this.tableData);
    this.searchValue = this.FormGrp.value.searchtext == '' ? true : false;
  }

  clearSearch() {
    this.FormGrp.controls.searchtext.setValue('');
    this.searchValue = true;
    this.onChange();
  }

  filterChange(arg?: any) {
    let tableData = _.clone(this.tableDataCopy);
    _.forEach(this.Categories, (filter) => {
      const appliedFilter = filter.filters.filter(r => filter.filtered.includes(r.id));
      if (filter.name == 'blockStatus' && appliedFilter.length > 0) {
        tableData = tableData.filter(data => {
          return _.some(appliedFilter, x => x.value === data.isAccountBlocked);
        });
      }
      if (filter.name == 'activeStatus' && appliedFilter.length > 0) {
        tableData = tableData.filter(data => {
          return _.some(appliedFilter, x => x.value === data.isActive);
        });
      }
      if (filter.name == 'application' && appliedFilter.length > 0) {
        tableData = _.filter(tableData, (data) => {
          return _.some(data.allowedAppId, item => {
            return _.some(appliedFilter, dataFilter => dataFilter.id === item);
          });
        });
      }
      if (filter.name == 'outlet' && appliedFilter.length > 0) {
        tableData = _.filter(tableData, (data) => {
          return _.some(data.allowedOutId, item => {
            return _.some(appliedFilter, dataFilter => dataFilter.id === item);
          });
        });
      }
      if (filter.name == 'userRole' && appliedFilter.length > 0) {
        tableData = tableData.filter(data => {
          return _.some(appliedFilter, x => data.roles.includes(x.name));
        });
      }
    });
    this.tableData = tableData ;
    this.bindTable(tableData);
  }

  async EditRecords(event) {
    let clientObj = this.usersInfo.filter(x => x.userId == event[0].id)[0];
    let userSpaConfig: any = await this.GetUserConfigAsync('GetUserSpaConfiguration', GlobalConst.Host.spaManagement, event[0].id);
    let userRetailConfig: any = await this.GetUserConfigAsync('GetUserRetailConfiguration', GlobalConst.Host.retailManagement, event[0].id)
    this._servicesetting.allUserTherapist = <any[]>await this.GetConfigAsync('GetAllUserTherapist', GlobalConst.Host.spaManagement);
    let userTherapist = this._servicesetting.allUserTherapist.find(x=> x.userId == event[0].id);

    let userData: any = {
      activeuser: clientObj.isActive,
      fname: clientObj.firstName,
      lname: clientObj.lastName,
      userid: clientObj.userName,
      quickid: clientObj.quickId,
      email: clientObj.email,
      language: clientObj.languageId,
      newpassword: clientObj.isNewUser,
      pwdexpirationdate: clientObj.passwordExpireDate ? this.utils.getDate(clientObj.passwordExpireDate) : ''
    };

    let spaData: any;
    let retailData: any;
    let retailOutletMap: any;
    clientObj.userPropertyAccesses = clientObj.userPropertyAccesses.filter(x => x.propertyID === Number(this.utils.GetPropertyInfo('PropertyId'))
      && this.products.map(v => v.id).includes(x.productId));
    for (let i = 0; i < clientObj.userPropertyAccesses.length; i++) {
      let prodId = clientObj.userPropertyAccesses[i].productId;
      let prodName = this.products.filter(x => x.id == prodId)[0].productName.replace(/ /g, '');
      if (prodName.toUpperCase() == 'SPA') {
        spaData = {
          rolename: clientObj.userPropertyAccesses[i].roleId,
          accountblocked: clientObj.userPropertyAccesses[i].accountBlocked,
          autologoff: clientObj.userPropertyAccesses[i].autoLogOff,
          logoffafter: clientObj.userPropertyAccesses[i].logOffAfter,
          usertherapist: userTherapist?.therapistId,
          istherapistscheduleview: userTherapist?.therapistId ? true : false
        }
      }
      retailData = {
        rolename: clientObj.userPropertyAccesses[i].roleId,
        allowgratuity: userRetailConfig ? userRetailConfig.allowGratuity : false,
        allowservicecharge: userRetailConfig ? userRetailConfig.allowServiceCharge : false,
        allowcommission: userRetailConfig ? userRetailConfig.allowCommission : false,
        commissionclass: userRetailConfig ? userRetailConfig.commissionClass : false
      }
    }
    this._servicesetting.userSettingsFormGrp.patchValue(userData);
    if (spaData) {
      this._servicesetting.spaSettingsFormGrp.patchValue(spaData);
    }
    if (retailData) {
      this._servicesetting.retailSettingsFormGrp.patchValue(retailData);
    }
    this._servicesetting.editUserInfo = {
      clientInfo: clientObj,
      spaInfo: userSpaConfig ? userSpaConfig : [],
      retainInfo: userRetailConfig,
      retailOutletMap: clientObj.retailOutletMap,
      userTherapistInfo: userTherapist
    };
    this.createUser('Edit');
  }

  async GetUserConfigAsync(callDesc, host, id) {
    let info = await this.http.CallApiAsync({
      host: host,
      uriParams: { id: id },
      callDesc: callDesc,
      method: HttpMethod.Get
    });
    return info ? info.result : null;
  }

  async GetConfigAsync(callDesc, host) {
    let info = await this.http.CallApiAsync({
      host: host,
      callDesc: callDesc,
      method: HttpMethod.Get
    });
    return info ? info.result : null;
  }

  GetServiceCall(Route, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetRetailServiceCall(Route, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }


  GetSPAServiceCall(Route, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): Promise<void> {
    if (callDesc == 'GetProductsByPropertyId') {
      if (result.result) {
        this.products = <any>result.result;
        this.Categories[0].filters = this.products.map(x => { return { id: x.id, name: x.productName, value: false } })
      }
    }
    else if (callDesc == 'GetOutlets') {
      if (result.result) {
        this._servicesetting.propOutlets = <any>result.result;
        this.Categories[2].filters = this._servicesetting.propOutlets.map(x => { return { id: x.subPropertyID, name: x.subPropertyName, value: false } })
      }
    }
    else if (callDesc == 'GetActiveUserRolesByPropertyId') {
      if (result.result) {
        this.roles = this.rolelst = <any>result.result;
        this.Categories[1].filters = this.roles?.map(x=> {return { id: x.id, name: x.description, value: false}});
        this._servicesetting.userRoles = this.roles;
        this.roles = this.roles.filter(x=> x.productId.includes(Number(this.utils.GetPropertyInfo('ProductId'))));
      }
    }
    else if (callDesc == 'GetAllUsers') {
      if (result.result) {
        this.usersInfo = <any>result.result;
        let data = _.cloneDeep(<any>result.result);
        if (data.length > 0) {
          data = await this.FillUserOutletsAccess(this.usersInfo);
          data = data.filter(u => u.userPropertyAccesses && u.userPropertyAccesses.some(a => a.propertyID === Number(this.utils.GetPropertyInfo('PropertyId')) && a.productId === Number(this.utils.GetPropertyInfo("ProductId"))));
          this.tableData = [];
          this._servicesetting.existingUserIds = [];
          this._servicesetting.existingQuickIds = [];
          for (let x = 0; x < data.length; x++) {
            let propertyAccess = data[x].userPropertyAccesses;
            let appAllowedNames, appAllowedIds, roleNames, roleIds;
            let outletAllowedIds = [];
            let userblocked = false;
            if (propertyAccess && propertyAccess.length > 0) {
              appAllowedIds = propertyAccess.filter(j => j.propertyID == Number(this.utils.GetPropertyInfo('PropertyId')) && j.hasAccess).map(y => y.productId);
              roleIds =  propertyAccess.filter(z => z.propertyID == Number(this.utils.GetPropertyInfo('PropertyId'))).map(y => y.roleId);
              appAllowedNames = this.products.filter(y => appAllowedIds.includes(y.id)).map(z => z.productName).toString();
              roleNames = this.rolelst.filter(y => roleIds.includes(y.id)).map(z => z.description).toString();


                let subPropAccess = data[x].retailOutletMap;
                let outIds;
                if (subPropAccess && subPropAccess.length > 0) {
                  outIds = subPropAccess.filter(t=> t.hasAccess).map(z => z.subPropertyID);
                  outletAllowedIds.push(...outIds);
                }

            }
            var spaProd = propertyAccess.find(o => o.propertyID === Number(this.utils.GetPropertyInfo('PropertyId')) && o.productId === Number(this.utils.GetPropertyInfo("ProductId")));
            const tempUserBlock = (spaProd ? spaProd.accountBlocked : userblocked);
            userblocked = data[x].isLocked ? data[x].isLocked : tempUserBlock;
            let userInfo = {
              userId: (data[x].userName ? data[x].userName : '').toUpperCase(),
              name: data[x].firstName + ' ' + data[x].lastName,
              email: data[x].email,
              isActive: data[x].isActive,
              isAccountBlocked: propertyAccess && propertyAccess.length > 0 ? propertyAccess[0].accountBlocked : false,
              createdOn: data[x].createdOnLocalTimeZone ? `${this.localization.LocalizeDate(this.utils.getDate(data[x].createdOnLocalTimeZone))} | ${this.localization.LocalizeTime(this.utils.getDate(data[x].createdOnLocalTimeZone))}` : '',
              createdOn_Min:data[x].createdOnLocalTimeZone ?new Date(this.localization.LocalizeDateTimeFormatDDMMMYYYY(this.utils.getDate(data[x].createdOnLocalTimeZone))).getTime():0,
              lastAccessedOn: data[x].lastAccessDateLocalTimeZone ? `${this.localization.LocalizeDate(this.utils.getDate(data[x].lastAccessDateLocalTimeZone))} | ${this.localization.LocalizeTime(this.utils.getDate(data[x].lastAccessDateLocalTimeZone))}` : '',
              lastAccessedOn_Min :data[x].lastAccessDateLocalTimeZone ?new Date(this.localization.LocalizeDateTimeFormatDDMMMYYYY(this.utils.getDate(data[x].lastAccessDateLocalTimeZone))).getTime():0,
              applicationAllowed: appAllowedNames,
              roles: roleNames,
              allowedAppId: appAllowedIds,
              allowedOutId: outletAllowedIds,
              id: data[x].userId,
              retailOutletMap: data[x].retailOutletMap,
              blockedUser: userblocked,
              isRestrictuserBlock:this.IsReadOnly
            }

            this.tableData.push(userInfo);
            this.tableDataCopy = [...this.tableData];
            this._servicesetting.existingUserIds.push((data[x].userName ? data[x].userName : '').toUpperCase());
            if (data[x].quickId) {
              this._servicesetting.existingQuickIds.push(data[x].quickId);
            }
          }
        }
        this.bindTable(this.tableData);        
      this.filterChange();
      }
    }
    else if (callDesc == 'GetAllGroups') {
      if (result.result) {

        var allgroups = <any>result.result;        

        this._servicesetting.serviceGroups = allgroups.filter(t=>!t.isClassGroup);
        this._servicesetting.classGroups = allgroups.filter(t=>t.isClassGroup);
      }
    }
    else if (callDesc == 'BlockUserProfile') {
      if (result.result) {
        this.GetServiceCall('GetAllUsers', { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) });
      }
    }
    else if (callDesc == 'GetADB2CEnableConfig') {
      if (result.result) {
        this.isADB2CConfigEnabled =(Boolean)(result.result);
      }
    }
    else if(callDesc == "getActiveTherapist"){
      this._servicesetting.allTherapist = <any>result.result;
    }
  }


  private async FillUserOutletsAccess(users: any) {
    var userOutlets = await this._userOutletsService.GetOutletsAccessByPropertyId();
    users.map(u => {
      let userAccessOutlets = userOutlets && userOutlets.length > 0 ? userOutlets.filter(r => r.userID == u.userId) : [];
      u.retailOutletMap = userAccessOutlets;
    });
    return users;
  }

  ResetServiceAttributes() {
    this._servicesetting.userSettingsFormGrp.reset();
    this._servicesetting.retailSettingsFormGrp.reset();
    this._servicesetting.spaSettingsFormGrp.reset();
    this._servicesetting.selectedAccess = [];
    this._servicesetting.selectedServiceGrp = [];
    this._servicesetting.selectedOutlets = [];
  }

  BlockUserEdit(event)
  {
    let clientObj = this.usersInfo.filter(x => x.userId == event[0].id)[0];
    clientObj.userPropertyAccesses = clientObj.userPropertyAccesses.filter(x => x.propertyID === Number(this.utils.GetPropertyInfo('PropertyId'))
      && this.products.map(v => v.id).includes(x.productId));
      if (clientObj.userPropertyAccesses) {
        this.GetServiceCall('BlockUserProfile', { userId: event[0].id, accountBlocked: !event[0].blockedUser });
      }
  }
}
