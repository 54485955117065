import { Component, OnInit, ViewEncapsulation,Input, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ButtonValue,DropdownOptions } from 'src/app/common/Models/ag-models';
import { environment } from 'src/environments/environment';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PropertyService } from 'src/app/common/services/property.service';
import { JasperServerCommonDataService } from 'src/app/common/dataservices/jasperServerCommon.data.service';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';

@Component({
  selector: 'app-jasper-report',
  templateUrl: './jasper-report.component.html',
  styleUrls: ['./jasper-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JasperReportComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder,
    private propertyInfo: CommonPropertyInformation,
    private utils: CommonUtilities,
    private http: HttpClient,
    private propertyservie: PropertyService,
    public storageservice:StorageService,
    private jasperServerCommon: JasperServerCommonDataService) {
    this.sanitizedreportUrl = this.reportURL;
  }

  title = 'JasperReportsApp';
  sanitizedreportUrl: SafeResourceUrl = "";
  jasperReportForm: UntypedFormGroup;
  reportResource : string = "";  
  reportParams = "?_flowId=viewReportFlow&standAlone=true&decorate=no"
  authentication : string = "";
  reportURL : string = "";
  baseURL: string = "";
  
  async ngOnInit() {
    let jasperBaseUri = sessionStorage.getItem("jaspersofturi").toString();
    this.baseURL = jasperBaseUri != "" || jasperBaseUri != undefined ? jasperBaseUri : await this.jasperServerCommon.GetJasperServerBaseURL();
  }

  ngAfterViewInit() { }

  jaspersoftReport?: boolean = false;
  reportUrlPath : string;
  reportParameters:string;
  reportdetails:any;
  @Input('reportDetails') 
	set setAction(reportDetails)
  {
		if(reportDetails?.isJaspersoftReport)
    {
      this.reportdetails = reportDetails;
      this.reportUrlPath = reportDetails.reportUrlPath;
      this.reportParameters = reportDetails.reportUrlParameters ? reportDetails.reportUrlParameters : "";
      //---- auto fill the jasper input controlls from our application UI
      if(reportDetails.filters){
      this.reportParameters = this.reportParameters + '&' +Object.keys(reportDetails.filters).map(key => {
        var data = reportDetails.filters[key]
        if(Array.isArray(data))
        {
          var request = [];
           data.forEach(x=>{
              request.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(x)}`
              );
           })
           return request.join('&');
        }
         else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(reportDetails.filters[key])}`;
         }  
      }).join('&');  
    }
      //this.onAction(this.LoadReport,this.reportdetails,this.reportUrlPath);
      this.onAction();
		}
	}

  LoadReport(reportDetail,reportUrlPath){
    var form;
    form = document.createElement('form');
    form.style.display = 'none';  
    form.method = 'GET';
    form.action =  this.baseURL +'/flow.html';
    form.target = "JasperreportIframe";
    const input1 = document.createElement('input');
      input1.type = 'hidden';
      input1.name = '_flowId';
      input1.value = 'viewReportFlow'; 
    
      const input2 = document.createElement('input');
      input2.type = 'hidden';
      input2.name = 'standAlone';
      input2.value = 'true'; 
      const input3 = document.createElement('input');
      input3.type = 'hidden';
      input3.name = 'decorate';
      input3.value = 'no'; 
      const input4 = document.createElement('input');
      input4.type = 'hidden';
      input4.name = 'reportUnit';
      input4.value = reportUrlPath;
      Object.keys(reportDetail.filters).map(key=>{
        var data = reportDetail.filters[key]
          if(Array.isArray(data)){
            data.forEach(x=>{
              const input5 = document.createElement('input');
              input5.type = 'hidden';
              input5.name = key;
               input5.value = x;
               form.appendChild(input5);
            })
          }
          else{
            const input5 = document.createElement('input');
            input5.type = 'hidden';
            input5.name = key;
             input5.value = data;
             form.appendChild(input5);
          }
      })
      
      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);
      form.appendChild(input4);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
  }

  FormURL() {
    this.reportResource = "&reportUnit=" + this.reportUrlPath;
    var requestURL = this.baseURL + "/flow.html" + this.reportParams + this.reportResource + this.reportParameters;
    return requestURL;
  }

  // async onAction(callback,reportdetails,reportUrlPath)
  // {  

  //   let reportURL = this.FormURL();  
  //   await this.propertyservie.setJasperAttributes();
  //   var xhr = new XMLHttpRequest();
  //    xhr.withCredentials = true;
  //    xhr.addEventListener("readystatechange", function () {
  //       if (this.readyState === 4) 
  //       {
  //           callback(reportdetails,reportUrlPath);
  //       }
  //     });

  //   var data = "";
  //   xhr.open("GET", environment["JasperServerURL"] + "/rest_v2/serverInfo");
  //   xhr.setRequestHeader("Content-Type", "application/json");
  //   xhr.setRequestHeader("pp", sessionStorage.getItem("_jwt"));
  //   xhr.send(data);
  // }

  async onAction() {
    let reportURL = this.FormURL();
    let headers = await this.jasperServerCommon.GetJasperServerHeader();
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        var myIframe = document.querySelector('#jasperiframe');
        myIframe['src'] = reportURL;
      }
    });
    var data = "";
    xhr.open("GET", this.baseURL + "/rest_v2/serverInfo");
    if(headers){
      Object.keys(headers).forEach(key => {
        if (headers[key] !== null) {
            xhr.setRequestHeader(key, headers[key]);
        }
    });
    }
    xhr.send(data);
  }
}
