import { Injectable } from "@angular/core";
import { TableHeaderOptions, TableOptions } from "src/app/common/Models/ag-models";
import * as myGlobals from 'src/app/shared/globalsContant';
import { ActionTypeEnum, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { TableActions } from "src/app/common/enums/shared-enums";
import { Localization } from "src/app/common/localization/localization";
import { HttpServiceCall } from "src/app/common/shared/shared/service/http-call.service";
import { API } from "src/app/settings/Utilities/lockers/lockers.model";


@Injectable()
export class LockersBusiness {
  captions: any;
  selectedFromList: [{ id: number; name: string; }];
  isViewOnly: boolean;
  constructor(private localization: Localization, private http: HttpServiceCall) {
    this.captions = this.localization.captions;
  }

  getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'lockerCode',
        displayName: this.captions.lbl_lockerCode,
        displayNameId: 'lbl_lockerCode',
        sorting: true,
        searchable: true,
      },
      {
        key: 'lockerName',
        displayName: this.captions.lbl_lockerName,
        displayNameId: 'lbl_lockerName',
        sorting: true,
        searchable: true,
      },
      {
        key: 'lockerQty',
        displayName: this.captions.lbl_lockerQty,
        displayNameId: 'lbl_lockerQty',
        sorting: true,
      },
      {
        key: 'listOrder',
        displayName: this.captions.grid_hdr_ListOrder,
        displayNameId: 'grid_hdr_ListOrder',
        sortingKey: 'listOrder',
        sorting: true
      },
      {
        key: 'rateType',
        displayName: this.captions.rateType,
        displayNameId: 'rateType',
        sorting: true,
      },
      {
        key: 'active',
        displayName: this.captions.tbl_hdr_active,
        displayNameId: 'tbl_hdr_active',
        templateName: ActionTypeEnum.toggle,
        sorting: true,
      },
      {
        key: ActionTypeEnum.action,
        displayName: this.captions.tbl_hdr_actions,
        displayNameId: 'tbl_hdr_actions',
        templateName: ActionTypeEnum.action,
      },
    ];
  }
  getTableContent(LockerInfo: any[],isViewOnly) {
    const TableModel: any[] = [];
    LockerInfo.forEach(x => {
      TableModel.push({
        id: x.id,
        lockerCode: x.code,
        lockerName: x.name,
        lockerQty: x.quantity,
        listOrder: x.listOrder,
        rateType: (x.rateType == 0) ? 'Hourly' : 'Fixed',
        active: { value: x.isActive, isDisabled: isViewOnly },
      });
    });
    return TableModel;
  }

  getTableOptions(isViewOnly): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'listOrder',
      showTotalRecords: false,
      actions: [
        {
          type: TableActions.edit,
          disabled: false
        },
        {
          type: TableActions.delete,
          disabled: isViewOnly
        },
        {
          type: TableActions.drag,
          disabled: isViewOnly
        },
      ],
    };
  }

  mapToLockerInformationAPI(source: any): API.LockerInformationAPI {
    return {
      id: Number(source.id) > 0 ? source.id : 0,
      imageRefId: Number(source.imageRefId > 0) ? source.imageRefId : 0,
      name: source.lockerName,
      code: source.lockerCode,
      lockerPrefix: source.prefix,
      quantity: source.lockerQty,
      listOrder: Number(source.listOrder) > 0 ? source.listOrder : 0,
      availableOnWeb: source.availableOnWeb,
      isActive: source.active,
      outofOrderQuantity: Number(source.outofOrder) > 0 ? source.outofOrder : 0,
      rateType: source.rateType,
      retailItemId: source.retailItemId,
      rate:  this.localization.currencyToSQLFormat(source.rate) > 0 ? this.localization.currencyToSQLFormat(source.rate) : 0,
      lockerSlabRates: source?.hourlyRate ? (source?.hourlyRate.map((rate: any) => ({
        id: 0,
        lockerInformationId: 0,
        slabStart: rate.slabStart,
        slabEnd: rate.slabEnd,
        amount:  this.localization.currencyToSQLFormat(rate.amount)
      }))) :
        [
          {
            id: 0,
            lockerInformationId: 0,
            slabStart: 0,
            slabEnd: 0,
            amount: 0
          }
        ]
    };
  }

  mapToLockerInformationAPIForUpdate(source: any): API.LockerInformationAPI {
    return {
      id: Number(source.id) > 0 ? source.id : 0,
      imageRefId: Number(source.imageRefId > 0) ? source.imageRefId : 0,
      name: source.name,
      code: source.code,
      lockerPrefix: source.lockerPrefix,
      quantity: source.quantity,
      listOrder: Number(source.listOrder) > 0 ? source.listOrder : 0,
      availableOnWeb: source.availableOnWeb,
      isActive: source.isActive,
      outofOrderQuantity: Number(source.outofOrderQuantity) > 0 ? source.outofOrderQuantity : 0,
      rateType: source.rateType,
      retailItemId: source.retailItemId,
      rate: source.rate > 0 ? source.rate: 0,
      lockerSlabRates: source?.lockerSlabRates ? (source?.lockerSlabRates.map((rate: any) => ({
        id: 0,
        lockerInformationId: 0,
        slabStart: rate.slabStart,
        slabEnd: rate.slabEnd,
        amount: rate.amount
      }))) :
        [
          {
            id: 0,
            lockerInformationId: 0,
            slabStart: 0,
            slabEnd: 0,
            amount: 0
          }
        ]
    };
  }
}