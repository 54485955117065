import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import {
  GlobalSystemPreference,
  SysytemPreferenceAction,
} from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import _ from 'lodash';
import { ZeroQty } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { Validation } from 'src/app/eatecui/source/shared/utilities/data.validator';
export const ZeroQtyPR: SystemPreferenceConfig = (
  PreferenceData: Array<any>,
  PreferenceAction: Array<SysytemPreferenceAction>,
  GlobalPreference: Array<GlobalSystemPreference>,
  httpService: HttpService,
  sysytemPreferenceService: SysytemPreferenceService,
  PrferenceType?: any,
  ModuleKey?: string,
  dialog?: MatDialog,
  transactionCollection?: TransactionConfiguration,
  homeGroup?: FormGroup
): any => {
  try {
    return new Promise<boolean>((resolve, reject) => {
      if (GlobalPreference && GlobalPreference.length > 0 && homeGroup) {
        let zeroQtyCheck = false;
        const prZeroQtyGlobalPreference = GlobalPreference.filter(
          (x) => x.LookupName === PrferenceType
        );
        if (prZeroQtyGlobalPreference && prZeroQtyGlobalPreference.length > 0) {
          const prZeroQty = prZeroQtyGlobalPreference[0];
          if (prZeroQty.LookupValue === ZeroQty.NoZeroQty) {
            const filteredGridData: Array<any> =
              transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.filter((x =>
                new Validation.NullDataValidator().isCheck(x.Qty)));
            if (filteredGridData.length > 0) {
              transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData =
                [...filteredGridData];
              zeroQtyCheck = true;
            } else {
              const receivingZeroQuantity = {} as ErrorPopModel;
              receivingZeroQuantity.PopupHeaderContent = 'Info';
              receivingZeroQuantity.PopUpContent =
                'Must enter at least one item Qty greater than zero';
              const dialogRef = dialog.open(AgilsysErrorPopComponent, {
                width: '400px',
                data: receivingZeroQuantity,
                disableClose: true,
              });
              dialogRef.afterClosed().subscribe((result: any) => {
                if (result === 'Confirm') {
                  resolve(true);
                } else {
                  reject(false);
                }
              });
              reject(false);
            }
          } else {
            zeroQtyCheck = true;
          }
          if (zeroQtyCheck) {
            zeroPriceCheck(transactionCollection, dialog, homeGroup).then(function (finalresponse) {
              resolve(finalresponse);
            });
          } else {
            reject(false);
          }
        }
      } else { 
        resolve(true);
      }
    });
  } catch (error) {
    console.error(error);
  }
};

function zeroPriceCheck(
  TransactionCollection: TransactionConfiguration,
  dialog: any,
  homeGroup: FormGroup
) {
  return new Promise<boolean>((resolve, reject) => {
    const GridRowData =
      TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList
        .ItemGridConfig.GridData;
    let isZeroPrice = false;
    let isNegativePrice = false;
    let PopUpContent = '';
    const ingredientName = TransactionCollection.CopyAsNewConfig.CopyNewItemListConfig
    .ItemListProperty.filter(x => x.OdataKey === TransactionCollection.OdataKey)[0].UnitsName;
    const normalCosting = GridRowData.filter(x => x.PostPhyOnZeroCost ? x.PostPhyOnZeroCost === 1
      : (x[ingredientName] && x[ingredientName].IngredientsOtherDetails
        ? x[ingredientName].IngredientsOtherDetails.PostPhyOnZeroCost === 1 :
        (x.IngredientsOtherDetails ? x.IngredientsOtherDetails.PostPhyOnZeroCost === 1
          : false)));
    // let zeroCosting = gridData.filter(x => x.PostPhyOnZeroCost && x.PostPhyOnZeroCost === 2);
    if (normalCosting.length > 0) {
      const zeroCostItem = normalCosting.some(x => parseFloat(x.UnitCost) === parseFloat('0')
        && parseFloat(x.Qty) > parseFloat('0'));
      if (zeroCostItem) {
        isZeroPrice = true;
        PopUpContent = 'Transaction contains a zero price. Confirm to proceed?';
      }
    }

    GridRowData.forEach((x) => {
      if ((x.UnitCost ? parseFloat(x.UnitCost) : 0) < 0) {
        isNegativePrice = true;
        PopUpContent =
          'Unit cost cannot be less than zero, Please check and try again';
      }
    });
    if (
      (isZeroPrice && !homeGroup.value['AllowZeroCostItem']) ||
      isNegativePrice
    ) {
      const ConfirmPopUpDialog: ConfirmPopModel = {
        PopupHeaderContent: 'Validations',
        PopUpContent: PopUpContent,
      };
      const dialogRef = dialog.open(AgilsysErrorPopComponent, {
        width: '400px',
        data: ConfirmPopUpDialog,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Ok') {
          if (isZeroPrice) {
            homeGroup.controls['AllowZeroCostItem'].setValue(true);
            resolve(true);
          }
        } else {
          reject(false);
        }
      });
      return true;
    } else {
      resolve(true);
      return false;
    }
  });
}
