import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownSelectorComponent implements OnInit {

  params: any;
  defaultValue: number;
  dropdownOptions: Array<any> = [];
  dropdown: FormControl = new FormControl('');
  DynamicPropertyName: string;
  constructor() { }

  ngOnInit(): void {
    console.log('NgOnInit');
  }

  agInit(params: any) {
    this.params = params;
    this.dropdownOptions = params?.colDef?.dropdownOptionsList;
    this.DynamicPropertyName = params?.DynamicPropertyName;
    this.defaultValue = params?.data[this.DynamicPropertyName];
    this.dropdown.setValue(this.defaultValue);
  }

  dropdownChangeEvent(event) {
    console.log('Return Value', event);
    const GetTaragetValue = event.target.value;
    this.defaultValue = parseInt(GetTaragetValue, 10);
    this.params.data[this.DynamicPropertyName] = this.defaultValue;
  }

  getValue(): any {
    const GetDisplayCollection = this.dropdownOptions ? this.dropdownOptions.filter(x => x.Id === this.defaultValue) : '';
    if ( GetDisplayCollection && GetDisplayCollection.length > 0 ) {
         const GetDisplayName = GetDisplayCollection[0].Name;
         return ( GetDisplayName ) ? GetDisplayName : '';
    } else {
        return this.params.data[this.params.colDef.field];
    }
   }

   focusIn() {
    const selectEditor = document.getElementById('selector-' + this.params.colDef.field);
    if (selectEditor) {
      selectEditor.focus();
    }
  }
}
