<section class="row-actions" (click)="$event.stopPropagation()">
    <div *ngIf="columnDef.showIcon" class="menu-button">
        <i class="icon-more-eatec disable-actions" *ngIf="checkIsSystem || noactions || InvalidQty"></i>
        <i class="ag-row-edit icon-more-eatec" *ngIf="!checkIsSystem && !noactions && !InvalidQty" (click)="moreIcon($event)"
        #clickHoverMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="clickHoverMenu"></i>
    </div>
    <mat-menu #clickHoverMenu="matMenu" xPosition="before" yPosition="below" (closed)="menuCloses($event)">
        <div style="width: 100%;">
            <!-- <button mat-menu-item class="menu-item" (click)="buttonClick('edit')"> <i class="icon-edit1"></i> Edit </button>
            <button mat-menu-item class="menu-item" (click)="buttonClick('delete')"> <i class="icon-delete"></i> Deactivate </button> -->
            <div *ngFor="let data of actions">
                <button mat-menu-item class="menu-item" *ngIf="data.Show" (click)="buttonClick(data.Name)">
                    <i class="{{data.IconName}}"></i> {{data.DisplayName}}
                </button>
            </div>
        </div>
    </mat-menu>
</section>