import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SearchboxService {

  constructor(public http: HttpClient) { }
    WarningMessage: string="*Enter minimum 3 letters to search";
    searchIcon: string = "search";
    closeIcon: string= "close";
    RightShuttleData: Array<Object>;
    searchPath: string = "/assets/list.json";
    noDataMessage:string = "No Match Found";
    searchLength: number = 3;
    PopupInput: any;
    people:any=[];
    searchType:boolean = true;
    showWarning:false;
    placeHolderMessage:string="Enter minimum 3 letters";
    getJson() {
        return this.http.get(this.searchPath);
      }

  getGlobalJson() {
    return this.http.get('assets/global-search.json');
   }

}


