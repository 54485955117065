import { Injectable } from '@angular/core';
import { DropDownData } from '../../retail.modals';

@Injectable()
export class MultiSelectDropdownService {

  toggleClickbtn(data: DropDownData, dataSourceArray: DropDownData[], selectedDataArray: DropDownData[], defaultData?: DropDownData, allselectedCheck?): DropDownData[] {
    let selectedArray = selectedDataArray;
    const currentlySelectedArray = data;
    if (currentlySelectedArray.id == defaultData.id) { /* For all button click */
      if (allselectedCheck) {        
        selectedArray = dataSourceArray.map(x =>
          x
        );        
      } else {
        selectedArray = [];
      }
    } else { /* For other than all button click */
      if (selectedArray.indexOf(currentlySelectedArray) == -1) {
        selectedArray.push(currentlySelectedArray);
        if ((dataSourceArray.length) == selectedArray.length) {
         //dataSourceArray
        }
      } else {
        selectedArray.splice(selectedArray.indexOf(currentlySelectedArray), 1);

        if (selectedArray.indexOf(defaultData) != -1) {
          selectedArray.splice(selectedArray.indexOf(defaultData), 1);
        }
      }
    }    
    return selectedArray;
  }
}
