import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { AddNewPopModel } from '../model/pop-up.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-agilysys-print-worksheet-popup',
    templateUrl: './agilysys-print-worksheet-popup.component.html',
    styleUrls: ['./agilysys-print-worksheet-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

export class AgilysysPrintWorksheetPopupComponent implements OnInit {
    agilysysAddNewButton: AgilysysButtonModel[];
    homeGroup: FormGroup;
    constructor(public dialogRef: MatDialogRef<AgilysysPrintWorksheetPopupComponent>,
      @Inject(MAT_DIALOG_DATA) public data: AddNewPopModel, public formBuilder: FormBuilder,
      private translateService: TranslateService,
      private ref: ChangeDetectorRef) {
        this.homeGroup = this.formBuilder.group({});
      }

      ngOnInit(): void {
        this.agilysysAddNewButton = [
            {
              displayProperity: false,
              displayName: this.translateService.instant('Common.Cancel'),
              buttonKey: 'Cancel',
              buttonType: 'agilysys-button-secondary',
              buttonCallBack: (callBackData: CallBackData) => {
                this.dialogRef.close(callBackData.buttonValue.buttonKey);
              }
            },
            {
              displayProperity: false,
              displayName: this.translateService.instant('Common.' + (displayName(this.data.FormType))),
              buttonKey: 'Print',
              buttonType: 'agilysys-button-primary',
              buttonCallBack: (callBackData: CallBackData) => {
                this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
              }
            }
        ];
      
        function displayName(FormType) {
            switch (FormType) {
              case 'print':
                return 'Print';
              default:
                return 'Confirm';
            }
          }
      }
}