import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements  OnChanges {

  @Input() public ToastMessageInput: ToastMessage;

  constructor() { }

  dismiss(event) {
    event.preventDefault();
    this.ToastMessageInput.Show = false;
    if (this.ToastMessageInput.hasOwnProperty('Dismiss')) {
      this.ToastMessageInput.Dismiss(true);
    }
  }
  ngOnChanges() {
    if (this.ToastMessageInput) {
      this.ToastMessageInput.Show = this.ToastMessageInput.Message ? true : false;
      if (this.ToastMessageInput.Show) {
        if (this.ToastMessageInput.AutoClose) {
          setTimeout(() => {
            if (this.ToastMessageInput.Show) {
              this.ToastMessageInput.Show = false;
              this.ToastMessageInput.AutoDismiss(true);
            }
          }, this.ToastMessageInput.TimerCount);
        }
      }
    }
  }

}
