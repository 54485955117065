import { AutoCompleteSearchInventory } from './auto-complete-inventory-search.interface';
import { AutoCompleteSearchApCredit } from './auto-complete-search-apcredit.interface';
import { AutoCompleteSearch } from './auto-complete-search.interface';
import { AutoCompleteSearchUSDA } from './auto-complete-usda-lookup.interface';
import { CalcAllUnitCosts } from './calc-allunit-cost.interface';
import { CalcPurchaseUnitCost } from './calc-purchase-cost.interface';
import { cascadingDropdown } from './cascading-dropdown.interface';
import { checkFormValidation } from './checkFormvalidation.interface';
import { checkFormValue } from './checkFormValue.interface';
import { CreditCardCalcApCredit } from './creditcardcalc-apcredit.interface';
import { CustomAddPicture } from './custom-add-picture.interface';
import { BarcodeFormEdit } from './custom-barcode-edit.interface';
import { CustomComapareLocation } from './custom-comparelocation.interface';
import { CustomCostPercentCalc } from './custom-cost-percent-calculation.interface';
import { CustomCustomerLocation } from './custom-customer-location.interface';
import { CustomDefaultSelect } from './custom-default-select.interface';
import { CustomDietaryPreference } from './custom-dietary-preference.interface';
import { customEnableSupplier } from './custom-enableSupplier.interface';
import { GetBidRegion } from './custom-get-bidregion';
import { CustomInventoryTypeChange } from './custom-inventorytypechange.interface';
import { CustomFromLocation } from './custom-location.interface';
import { CustomMealPlanStartDate } from './custom-mealplan-startdate.interface';
import { CustomPeriodLengthChange } from './custom-periodlength-change.interface';
import { CustomTypeChange } from './custom-pi-type-change.interface';
import { CustomPOLocationChange } from './custom-po-location-change.interface';
import { CustomPOSelectFillData } from './custom-po-select-fill-form.interface';
import { CustomPrefillInvoice } from './custom-prefill-invoice.interface';
import { CustomPrefillPO } from './custom-prefill-po-data.interface';
import { CustomProductTypeChange } from './custom-producttypechange.interface';
import { CustomReceivingValidation } from './custom-receiving-validation.interface';
import { CustomRecipeUnitChange } from './custom-recipeunit-change.interface';
import { CustomRetailWizard } from './custom-retail-wizard-options.interface';
import { CustomDueDateSet } from './custom-set-due-date.interface';
import { CustomTransferOutToggle } from './custom-transferout-toggle.interface';
import { CustomUnitDropdownChange } from './custom-unitdropdown-change.interface';
import { customEnableReceipeApprove } from './enable-receipe-approved.interface';
import { MultipleAutoCompleteSearch } from './multiple-auto-complete-search-header.interface';
import { CustomPackageTypeChanges } from './package-type-change.interface';
import { Replaceitemreplaceinchange } from './replace -in-change.interface';
import { setCostValue } from './set-Cost-Value.interface';
import { setHaccpType } from './set-Haccp-Type.interface';
import { SetMarkUpPercent } from './set-markup-price.interface';
import { setSubType } from './set-Sub-Type.interface';
import { SetUnitData } from './set-unit-value.interface';
import { CalMarkUpValue } from './calc-markup-value.interface';
import { LocationChangeRestriction } from './location-change-restriction.interface';
import { CustomTemplateFormLocation } from './custom-template-location.interface';
import { TemplateMultiAutoCompleteSearch } from './template-multiple-auto-complete-search.interface';
import { DeliveryRules } from './delivery-rules.interface';
import { POPostDateChangeCutOffCheck } from './po-postdate-change-cutoff-check.interface';
import { CustomExchangeRate } from './custom-exchange-rate.interface';
import { GetCustomCurrencyChange } from './custom-currency-dropdown.interface';
import { GetCustomPriceCalculation } from './custom-price-calculation.interface';
import { IngredientTypeIdChange } from './Ingredient-TypeId-Change.interface';
import { CustomGetCustomer } from './custom-get-customer';
import { CustomSubTypeChange } from './custom-subtype.interface';

export const CustomFormField = {
    CustomFormSelection: CustomDefaultSelect,
    CustomFormLocation: CustomFromLocation,
    CustomGetCustomer: CustomGetCustomer,
    CustomComapareLocation: CustomComapareLocation,
    CustomTransferOut: CustomTransferOutToggle,
    CustomCustomerLocation: CustomCustomerLocation,
    CustomUnitDropdownChange: CustomUnitDropdownChange,
    CustomPictureAdd: CustomAddPicture,
    CustomPITypesChange: CustomTypeChange,
    CustomRetailWizardChange: CustomRetailWizard,
    CustomPOLocation: CustomPOLocationChange,
    CustomPrefillPOData: CustomPrefillPO,
    CustomPODataSelect: CustomPOSelectFillData,
    CustomDueDateSet: CustomDueDateSet,
    AutoCompleteSearch: AutoCompleteSearch,
    AutoCompleteSearchApcredit: AutoCompleteSearchApCredit,
    CustomReceivingLocationValidation: CustomReceivingValidation,
    CreditCardCalcApCredit: CreditCardCalcApCredit,
    CalcPurchaseCost: CalcPurchaseUnitCost,
    CalcAllUnitCOst: CalcAllUnitCosts,
    setHaccpType: setHaccpType,
    setSubType: setSubType,
    setCostValue: setCostValue,
    SetUpMarkUpValue: SetMarkUpPercent,
    CustomPrefillInvoice: CustomPrefillInvoice,
    checkFormValue: checkFormValue,
    checkFormValid: checkFormValidation,
    CustomPackageTypeChange: CustomPackageTypeChanges,
    customInventoryTypeChange: CustomInventoryTypeChange,
    customProductTypeChange: CustomProductTypeChange,
    autoCompleteSearchInventory: AutoCompleteSearchInventory,
    replaceitemreplaceinchange: Replaceitemreplaceinchange,
    setUnitValue: SetUnitData,
    customCostPercentCalc: CustomCostPercentCalc,
    autoCompleteSearchUSDALookUp: AutoCompleteSearchUSDA,
    GetBidRegion: GetBidRegion,
    CustomBarCodeEdit: BarcodeFormEdit,
    DeliveryRulesClick: DeliveryRules,
    customEnableSupplier: customEnableSupplier,
    customRecipeUnitChange: CustomRecipeUnitChange,
    custoreceipeApprove: customEnableReceipeApprove,
    cascadingDropdown: cascadingDropdown,
    multipleAutoCompleteSearch: MultipleAutoCompleteSearch,
    templatemultiAutoCompleteSearch: TemplateMultiAutoCompleteSearch,
    CustomPeriodLengthChange: CustomPeriodLengthChange,
    CustomMealPlanStartDate: CustomMealPlanStartDate,
    CalMarkUpValues: CalMarkUpValue,
    CustomDietaryPreference: CustomDietaryPreference,
    LocationChangeRestriction: LocationChangeRestriction,
    CustomTemplateFormLocation: CustomTemplateFormLocation,
    POPostDateChange: POPostDateChangeCutOffCheck,
    GetCustomExchangeRate: CustomExchangeRate,
    GetCustomCurrencyChange: GetCustomCurrencyChange,
    GetCustomPriceCalculation: GetCustomPriceCalculation,
    IngredientTypeIdChange: IngredientTypeIdChange,
    IngredientTypeChange: CustomSubTypeChange
};
