import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';


@Pipe({
  name: 'remapdisplayname'
})
export class ProductReMapPipe implements PipeTransform {
  constructor(private cd: ChangeDetectorRef, private translateService: TranslateService) {
  }
  transform(value: any, ModuleKey: any, RemapJson: Array<any>): any {
    let returnValue = value;
    if (ModuleKey) {
        returnValue = ProductDataRemap.FormDisplayNameRemapEngine(RemapJson, ModuleKey, value, this.translateService);
    }
    return returnValue;
  }

}