import { Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { Localization } from 'src/app/common/shared/localization/Localization';
import { CGtablecontent } from '../../combine-guest-ui-model';
import { ObjectType } from '../../guest-model';
import { valueToSimpleBusiness } from './value-to-combine.business';

@Component({
  selector: 'ag-value-to-combine',
  templateUrl: './value-to-combine.component.html',
  styleUrls: ['./value-to-combine.component.scss'],
  providers: [valueToSimpleBusiness],
  encapsulation: ViewEncapsulation.None
})
export class AgValueToCombineComponent {
  mergedselecteddata: CGtablecontent[] = [];
  VSC_primaryselecteddata: CGtablecontent;
  VSC_secondaryselecteddata: CGtablecontent[];
  PrimarydefaultSet = true;
  bindvalue;

  @Output() VTCcombineEmitter = new EventEmitter();

  @Input()
  set inputs(value) {
    this.bindvalue = value;
    this.mergedselecteddata = [];
    this.declarationsection();

    if (value.secondarydata.length > 0) {
      this.VSC_primaryselecteddata = value.primarydata;
      this.VSC_secondaryselecteddata = value.secondarydata;
      this.mergedselecteddata.push(this.VSC_primaryselecteddata);
      this.VSC_secondaryselecteddata.forEach(element => {
        this.mergedselecteddata.push(element);
      });
      this.renderingScreenObj.forEach(x => {
        const ddValue = [];
        const derivedkey = x.key;

        {
          if(derivedkey=="phoneNumberArray" ||derivedkey=="emailArray")
          {
            this.mergedselecteddata.forEach(y => {
              y[derivedkey].forEach(z=>{
                const data={
                  id:y.id,
                  value:z.value,
                  viewValue:z.value,
                  isPrimary:z.isPrimary,
                  isPrivate:z.isPrivate
                };
                ddValue.push(data);
              });
              
            });
          }
          else{
          this.mergedselecteddata.forEach(y => {
            const data = {
              id: y.id,
              value: y[derivedkey],
              viewValue: y[derivedkey]
            };
            ddValue.push(data);
          });
        }
        }

        const DDdata = ddValue?.filter(x=>x.value);
        x.seedObj.selectOptions = Promise.resolve(DDdata);
      });
      this.setPrimaryguestvalue(true);
    }
  }
  captions: any;
  platFormExtendedSearchRequired :boolean = false;
  SVCform: UntypedFormGroup;
  renderingScreenObj = [];
  @ViewChild('VCT2', { static: false }) VCT2; // dropdown
  @ViewChild('VCT3', { static: false }) VCT3; // textbox
  formobjCol: any;




  constructor(private _localization: Localization, private fb: UntypedFormBuilder, public _valueToSimpleBusiness: valueToSimpleBusiness) {
    this.captions = this._localization.captions;
    this.platFormExtendedSearchRequired = this._localization.IsPlatformGuestSearchConfigured();
  }


  declarationsection() {
    this.formobjCol = this._valueToSimpleBusiness.getjsonobj();
    this.SVCform = this.createControl(this.formobjCol);
    this.createFormFields(this.formobjCol);
    this.bindonjtoscreen(this.formobjCol);
    this.SVCform.statusChanges.subscribe(x => {
      const formchangeddata = this.SVCform.value;
      this.VTCcombineEmitter.emit({ formchangeddata, primarydata: this.VSC_primaryselecteddata, secondarydata: this.VSC_secondaryselecteddata, primarydefaultSet: this.PrimarydefaultSet });
    });
  }

  bindonjtoscreen(obj) {
    this.renderingScreenObj = obj;
  }
  createControl(formobjCol) {
    const frmgroup = this.fb.group({});
    formobjCol.forEach(field => {
      const control = this.fb.control('', field.isRequired ? Validators.required : null);

      frmgroup.addControl(field.id, control);
    });
    return frmgroup;
  }
  createFormFields(fieldObjColl) {
    fieldObjColl.forEach(field => {
      switch (field.objectType) {
        case ObjectType.Text:
          field.seedObj = this.create_txt_OBJ(field.label, field.id, field.isRequired);
          break;
        case ObjectType.Dropdown:
          const ddData = [];
          field.seedObj = this.create_DD_OBJ(field.label, field.id, ddData, field.isRequired);
          break;
      }
    });
  }

  setPrimaryguestvalue(eve) {
    this.PrimarydefaultSet = eve;
    if (eve) {
      const valobj = this.mapperforPatch();
      this.patchFormValue(valobj);
      const formchangeddata = this.SVCform.value;
      this.VTCcombineEmitter.emit({ formchangeddata, primarydata: this.VSC_primaryselecteddata, secondarydata: this.VSC_secondaryselecteddata, primarydefaultSet: this.PrimarydefaultSet });
    } else {
      this.VTCcombineEmitter.emit({ "formchangeddata": this.SVCform.value, "primarydata": this.VSC_primaryselecteddata, "secondarydata": this.VSC_secondaryselecteddata, "primarydefaultSet": this.PrimarydefaultSet })
    }
  }

  create_DD_OBJ(placeHldr, ctrlname, ddData, isRequired) {
    return {
      form: this.SVCform,
      formControlName: ctrlname,
      placeHolderId: '',
      placeHolder: placeHldr,
      className: 'ag_form-control--lg',
      selectOptions: of(ddData),
      isFirstEmpty: false,
      showRequired: isRequired,
      disabled: this.platFormExtendedSearchRequired?(ctrlname=="lastname" || ctrlname =="firstname")?true: false:false,
      isMultiSelect: (ctrlname == "emailArray" || ctrlname == "phoneNumberArray")
    };
  }

  create_txt_OBJ(placeHldr, ctrlnme, isRequired) {
    return {
      className: 'ag_form-control--lg',
      placeHolderId: '',
      placeHolder: placeHldr,
      form: this.SVCform,
      formControlName: ctrlnme,
      showRequired: isRequired,
      changeLabelOnEdit: true,
    };
  }

  patchFormValue(valobj) {
    const mappedvalue = valobj;

    this.SVCform.patchValue(mappedvalue);
  }
  mapperforPatch() {
    var phone=[];
    var email=[];
    this.VSC_primaryselecteddata.phoneNumberArray.forEach(x=>
      {
        var number= {
          id:this.VSC_primaryselecteddata.id,
          value:x.value,
          viewValue:x.value,
          isPrimary:x.isPrimary,
          isPrivate:x.isPrivate
        };
        phone.push(number);
      });
      this.VSC_primaryselecteddata.emailArray.forEach(x=>
        {
          var number= {
            id:this.VSC_primaryselecteddata.id,
            value:x.value,
            viewValue:x.value,
            isPrimary:x.isPrimary,
            isPrivate:x.isPrivate
          };
          email.push(number);
        });
    return {
      address: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.address, viewValue: this.VSC_primaryselecteddata.address },
      city: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.city, viewValue: this.VSC_primaryselecteddata.city },
      clubnumber: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.clubnumber, viewValue: this.VSC_primaryselecteddata.clubnumber },
      company: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.company, viewValue: this.VSC_primaryselecteddata.company },
      companyid: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.companyid, viewValue: this.VSC_primaryselecteddata.companyid },
      country: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.country, viewValue: this.VSC_primaryselecteddata.country },

      emailArray: email,
      firstname: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.firstname, viewValue: this.VSC_primaryselecteddata.firstname },

      initial: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.initial, viewValue: this.VSC_primaryselecteddata.initial },
      lastname: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.lastname, viewValue: this.VSC_primaryselecteddata.lastname },
      membernumber: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.membernumber, viewValue: this.VSC_primaryselecteddata.membernumber },

      patronid: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.patronid, viewValue: this.VSC_primaryselecteddata.patronid },
      phoneNumberArray: phone,
      postalcode: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.postalcode, viewValue: this.VSC_primaryselecteddata.postalcode },

      state: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.state, viewValue: this.VSC_primaryselecteddata.state },
      title: { id: this.VSC_primaryselecteddata.id, value: this.VSC_primaryselecteddata.title, viewValue: this.VSC_primaryselecteddata.title },
    };
  }
}
