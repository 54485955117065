import { Guid } from "guid-typescript";

export enum PolicyType {
    Cancellation,
    NoShow
}
export enum TeeTimePriceType {
    FullPrice,
    CustomPrice
}
export enum ChargeType {
    Percentage,
    Amount
}
export enum ApplyWithinType{
    Days = 0,
    Hours
}

export interface DataPayload{
    type: SubjectDataType;
    data: any;
    errorMessage?: string;
}

export interface MappingDataPayload{
    type: MappingSubjectDataType;
    data: any;
    errorMessage?: string;
}

export enum SubjectDataType{
    GetPolicy,
    SendPolicy,
    CreatePolicy,
    UpdatePolicy,
    DeletePolicy,
    UpdateActive,
    APIResponse,
    DeleteAPIResponse
}

export enum MappingSubjectDataType{
    SearchMappingRequest,
    SearchMappingResponse,
    GetPolicyMapping,
    SendPolicyMapping,
    CreatePolicyMapping,
    UpdatePolicyMapping,
    DeletePolicyMapping,
    APIResponse,
    DeleteAPIResponse
}


export enum SpaGuestType{
    ExistingClient,
    Member = 2,
    HotelReservation
}
export interface CancellationNoShowPolicyBaseDetails{
    id: number;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
}
export interface CancellationNoShowPolicy{
    id: number;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
    cancellationNoShowDetails: CancellationNoShowDetails[];
}

export interface CancellationNoShowDetails{
    id: number;
    poicyId: number;
    isCancellationPolicy: boolean;
    includeCharges: string;
    isActive: boolean;
    isApplyWithinDays: boolean;
    applyWithinDuration: number;
    isFullPrice: boolean;
    isPercentage: boolean;
    charge: number;
}

export interface CancellationNoShowMapping{
    id: number,
    startDate: string,
    endDate: string,
    policyId: number,
    guestType: number,
    mappingGroupId: string,
    policyMapping: string
}

export interface CancellationNoShowMappingBaseDetails{
    policyId: number,
    name: string,
    code: string,
    guestType: number[],
    startDate: string,
    endDate: string,
    policyMapping: string,
    isActive: boolean,
    mappingGroupId: Guid
}

export interface CancellationNoShowMappingTableData{
    id: number,
    name: string,
    code: string,
    applyTo: string,
    dateRange: string,
    mappingGroupId: Guid,
    isInActive: boolean,
    dateSort?: number
}

export interface ServiceGroupsWithServices{
    id: number,
    name: string,
    selected: boolean,
    services: ServiceBaseDetails[]
}

export interface ServiceBaseDetails{
    id: number,
    groupId: number,
    code: string,
    name: string,
    selected: boolean
}


export interface MainGroup {
    id: number;
    name: string;
    selected: boolean;
    subGroup: SubGroup[];
    isSubGroupSelected?: boolean;
}
export interface SubGroup {
    id: number;
    name: string;
    selected: boolean;
}