export interface OdataConfiguration {
    GetOdata: any;
    GetOdataSuccess: any;
    GetInventoryOdata: any;
    GetInventoryOdataSucess: any;
}

export const intialOdataState: OdataConfiguration = {
    GetOdata: null,
    GetOdataSuccess: null,
    GetInventoryOdata: null,
    GetInventoryOdataSucess: null
};