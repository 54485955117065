import { Injectable } from '@angular/core';
import { CommonControllersRoutes } from '../../communication/common-route';
import { Localization } from 'src/app/common/localization/localization';
import { NotificationCommunication } from '../../communication/services/notification-communication-service';
import{API as HistoryAPI} from 'src/app/common/shared/shared/event-scheduler-history/event-scheduler-history.model';
import {API,UI} from 'src/app/common/templates/KAKaO-Template-Mapping/Kakao-Template-mapping.model'

@Injectable(
    { providedIn: 'root' }
)
export class KakaoTemplateDataService {
    captions: any;
    constructor(private _notificationCommunication: NotificationCommunication, public localization: Localization) {
        this.captions = this.localization.captions;
    }
    
      public async getAllKakaoTemplateMapping(includeInactive: boolean = true): Promise<API.KakaoTemplateMapping[]> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetAllKakaoTemplateMApping,
          uriParams: { 'includeInactive': includeInactive }
        }, false);
      }
       
      public async getKakaoTemplateCodes(): Promise<API.KakaoTemplateCode[]> {
        return this._notificationCommunication.getPromise({
          route: CommonControllersRoutes.GetKakaoTemplateCodes
        });
      }      

      public createKakaoTemplateMapping(data: API.KakaoTemplateMapping): Promise<API.KakaoTemplateMapping[]> {
        const result = this._notificationCommunication.postPromise<API.KakaoTemplateMapping[]>({
          route: CommonControllersRoutes.CreateKakaoTemplateMapping,
          body: data
        });
        return result;
      }
    
      public updateKakaoTemplateMapping(data: API.KakaoTemplateMapping): Promise<API.KakaoTemplateMapping[]> {
        const result = this._notificationCommunication.putPromise<API.KakaoTemplateMapping[]>({
          route: CommonControllersRoutes.UpdateKakaoTemplateMapping,
          body: data
        });
        return result;
      }
    
      public deleteKakaoTemplateMapping(id: number): Promise<boolean> {
        const result = this._notificationCommunication.deletePromise<boolean>({
          route: CommonControllersRoutes.DeleteKakaoTemplateMapping,
          uriParams: {id}
        });
        return result;
      }
}
