import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppointmentpopupService } from '../../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod } from '../../service/http-call.service';
import { SpaUtilities } from '../../utilities/spa-utilities';
import { BaseResponse, ClientInfo, Email, PhoneNumber } from '../../business/shared.modals';
import * as global from '../../globalsContant';
import { appointmentService } from '../../service/appointment.service';
import { NgForm, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppointmentUtilities } from '../../utilities/appointment-utilities';
import * as _ from 'lodash'
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ImageProcessorService } from '../../service/image-processor-service';
import { UserAlerts } from '../../../core/config/alerts-config';
import { BreakPointAccess } from '../../service/breakpoint.service';
import { Subscription, ReplaySubject, Subject } from 'rxjs';
import { AppointmentPopupComponent } from '../../appointment-popup/appointment-popup.component';
import { ClientService } from '../../service/client-service.service';
import { AddwaitlistPopupComponent } from '../../addwaitlist-popup/addwaitlist-popup.component';
import { WaitListService } from '../../addwaitlist-popup/waitlist-popup.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PMSActiviyStatus, StaySearchFilter } from 'src/app/shared/shared.modal';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { ClientCommonService } from 'src/app/client/client.service';
import { CMSBusinessService } from 'src/app/retail/shared/business/CMS-business.service';
import { CMSPlayerInfo } from 'src/app/common/Models/common.models';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { ButtonType, clientSearchType, ClientType, Host, GenderPreference, ButtonOptions, InternalPMS } from '../../globalsContant';
import { AlertType } from 'src/app/shared/globalsContant';
import { LostDeniedBusinessPopupService } from '../../appointment-actions/add-lost-denied-business-popup/lost-denied-business-popup.service';
import { AddLostDeniedBusinessPopupComponent } from '../../appointment-actions/add-lost-denied-business-popup/add-lost-denied-business-popup.component';
import { FindRelationshipComponent } from '../../find-relationship/find-relationship.component';
import { ClientLabel } from '../../business/new-booking.model';
import { AlertMessagePopupComponent } from '../../alert-message-popup/alert-message-popup.component';
import { GuestSearch } from '../../appointment-popup/create-appointment/client-information/GuestSearch-model';
@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ClientInfoComponent implements OnInit, OnDestroy, OnChanges {
  imageContent: any;
  @ViewChild('frm', { static: true }) clientForm: NgForm;
  @Input() isMultipleSearch: boolean;
  @Input() searchLimit = -1;
  @Input() activateClient: any;
  @Input() fromPackage: boolean = false;
  @Output() ClientAddedEvent: EventEmitter<any> = new EventEmitter();


  public activeBorderId = 0;
  public therapistArray: any = [];
  clientInfo: any;
  commentPlaceHolder: string;
  validTherapist = [];
  activeClient: number;
  clientComments: string;
  clientTherapist: any;
  clientactivated = false;
  activeSelectedClient: number;
  defaultvalues: any = [];
  clientswitchvalue: any;
  clientSearchValue: string;
  addclientInfo: ClientInfo;
  hotelReservationTypes: any;
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  callBack = false;
  imgSubscription: Subscription;
  isZero: any = "0";
  requestUid = "";
  PMSActiviyStatus = PMSActiviyStatus;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ItineraryData: any;
  ItinerarySubscriber: Subscription;
  nsBDRclr: any;
  closeBDRclr: any;
  checkinBDRclr: any;
  checkoutBDRclr: any;
  sBDRclr: any;
  poppedClientId = 0;
  clientTypes: any;
  searchTextPlaceHolder: string;
  clearSearchText: boolean = false;
  defaulthotelresvvalue: number = 1;
  defaultClientSearchValue: number = 0;
  floatLabel: string;
  clientSearchTypes: any;
  selectedClientSearchType: number;
  searchText = '';
  existingClientPlaceholder: string;
  selectedTherapist: any;
  currentguestInfo: any;
  actionDialogSubscription: Subscription;
  clientToRemove: any;
  isContactLogShow: boolean = false;
  isClientEditFromPackage: boolean;
  findrelationId: any;
  toAddClient: number;
  platFormExtendedSearchRequired: boolean = false;
  isPlatformGuestSearch: boolean = false;
  clientSearchForm: UntypedFormGroup;
  pmsSystem: boolean = false;
  pmsSystemValue: any;
  platFormsearchDebounce = new Subject<any>();
  findRelationshipDebounce = new Subject<any>();
  showExternalSearch = false;
  clientSearchFilter: GuestSearch[];
  isExternalPMSIntegrated: boolean;
  externalSearch: boolean;
  hotelReservChipPlaceholder: string;
  isFromClientInfo: boolean = true;

  constructor(public dialog: MatDialog, public dialogReference: MatDialogRef<ClientInfoComponent>, private appUtils: AppointmentUtilities,
    public apptService: appointmentService,
    public appointmentPopupservice: AppointmentpopupService, private http: HttpServiceCall,
    public utils: SpaUtilities, public localization: SpaLocalization,
    private imgService: ImageProcessorService, private userAlert: UserAlerts, private breakPoint: BreakPointAccess, private clientService: ClientService,
    private waitListService: WaitListService, private PropertyInfo: SpaPropertyInformation, private clientCommonService: ClientCommonService,
    //Added for add client to Appointment screen (validating with CMS value )
    private _cmsBusiness: CMSBusinessService,
    private _quickLoginUtil: QuickLoginUtilities,
    private lostDeniedBusinessPopupservice: LostDeniedBusinessPopupService,
    private Form: UntypedFormBuilder
  ) {
    this.floatLabel = this.localization.setFloatLabel;
    this.appointmentPopupservice.cancelBool = true;
    this.clientTypes = [{ id: 0, name: this.localization.captions.existingClients, checked: true }, { id: 3, name: this.localization.captions.hotelReservation, checked: false }, { id: 2, name: this.localization.captions.members, checked: false }];
    this.clientSearchTypes = [{ id: 0, name: this.localization.captions.firstName, checked: true }, { id: 1, name: this.localization.captions.lastName, checked: false }, { id: 2, name: this.localization.captions.name, checked: false }, { id: 3, name: this.localization.captions.phone, checked: false }, { id: 4, name: this.localization.captions.email, checked: false }];
    this.PropertyInfo._appointmentConfigurations$.pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result) {
        this.setStatusColors();
      }
    });
    this.isExternalPMSIntegrated = this.localization.GetExternalPMSIntegrated();
    const jsonPropConfig = sessionStorage.getItem('propConfig');
    this.pmsSystem = jsonPropConfig ? JSON.parse(jsonPropConfig)?.PMSSystem : null;
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
    this.pmsSystemValue = propConfig?.PMSSystem ? propConfig?.PMSSystem : null;
    this.selectedClientSearchType = clientSearchType.firstName;
    this.pmsSystem = this.pmsSystemValue ? true : false;
    this.appointmentPopupservice.isInternalPMSIntegrated = this.pmsSystemValue?.toLowerCase() == global.InternalPMS.LMS.toLowerCase() || this.pmsSystemValue?.toLowerCase() == global.InternalPMS.Stay.toLowerCase() ? true : false;
    this.getClientSearchTypes();
  }

  async ngOnInit() {
    this.clientSearchForm = this.Form.group({
      isPlatformGuestSearch: this.Form.control(''),
      isExternalSearch: this.Form.control('')
    });
    this.platFormsearchDebounce
      .pipe(debounceTime(700), distinctUntilChanged())
      .subscribe(($event) => {
        console.log("$event.searchValue", $event.searchValue);
        this.makeSearchCall($event.searchValue, this.selectedClientSearchType, $event.requestUid)

      });
    this.findRelationshipDebounce
      .pipe(debounceTime(700), takeUntil(this.destroyed$))
      .subscribe(() => this.openFindRelationship());

    this.appointmentPopupservice.recordsArray = [];
    this.appointmentPopupservice.labelRecords = [];
    this.platFormExtendedSearchRequired = this.localization.IsPlatformGuestSearchConfigured();
    this.showExternalSearch = this.localization.IsExternalGuestSearchConfigured();
    if (this.showExternalSearch) {
      this.appointmentPopupservice.isExternalSearch = true;
      this.clientSearchForm.controls.isExternalSearch.setValue(true);
      this.clientSearchForm.controls.isExternalSearch.updateValueAndValidity();
    }
    this.externalSearch = this.localization.ShowExternalGuestSearch();
    this.appointmentPopupservice.isExternalIntergrated = (this.externalSearch || this.showExternalSearch) ? true : false;
    await this.getClientSearchTypes();
    let clientSearchswitchvalue = this.clientSearchTypes.find(t => t.checked == true);
    this.setSearchText(clientSearchswitchvalue?.id);
    this.ItinerarySubscriber = this.appointmentPopupservice.PMSClientItineraryData.subscribe(
      x => {
        if (x) {
          x.start = this.localization.LocalizeDate(x.start);
          x.end = this.localization.LocalizeDate(x.end);
        }
        this.ItineraryData = x;
      }
    )
    if (!this.fromPackage) {
      await this.GetClientTypes();
      this.clientTypeChange(this.clientswitchvalue);
    } else {
      this.clientswitchvalue = this.appointmentPopupservice.clientTypes.find(t => t.checked == true);
      this.setSearchText(this.clientswitchvalue?.id);
    }
    this.appointmentPopupservice.multipleRecords = false;
    const _that = this;
    this.imgSubscription = this.appointmentPopupservice.clientImageObj.subscribe(x => {
      const imageObj = x;
      if (imageObj && imageObj.length > 0) {
        _that.imageContent = imageObj[0];
        _that.appointmentPopupservice.clientImageUrl = _that.imageContent.contentType && _that.imageContent.data ?
          `data:${imageObj[0].contentType};base64,${imageObj[0].data}` :
          '';
        let tempItem = _that.appointmentPopupservice.imageArray.find(arr => {
          return arr.imageReferenceId === _that.imageContent.imageReferenceId && arr.referenceType === _that.imageContent.referenceType;
        });
        if (_that.appointmentPopupservice.imageArray.length === 0 || !tempItem) {
          _that.appointmentPopupservice.imageArray.push(imageObj[0]);
        }
      } else {
        _that.imageContent = {};
      }
    });

    if (this.appointmentPopupservice.resultExistingAppointment && this.ClientAddedEvent) {
      this.appointmentPopupservice.multipleRecords = this.appointmentPopupservice.resultExistingClients.length > 1;
      for (let ApptItem of this.appointmentPopupservice.resultExistingClients) {
        this.appointmentPopupservice.ClientAddedEvent(ApptItem);
      }

    } else if (this.appointmentPopupservice.multiClientInfo && this.appointmentPopupservice.multiClientInfo.length > 0) {
      for (let itemAppt of this.appointmentPopupservice.multiClientInfo) {
        this.appointmentPopupservice.ClientAddedEvent(itemAppt.id);
      }
    }
    if (!this.appointmentPopupservice.clientInformationForm) {
      this.appointmentPopupservice.clientInformationForm = this.clientForm;
    }
    if (this.appointmentPopupservice.fromClientModule) {
      let unassignedTherapistId = 0;
      this.appointmentPopupservice.multiClientInfo = [];
      this.appointmentPopupservice.multipleRecords = this.appointmentPopupservice.recordsArray.length > 1;
      for (let tempRecord of this.appointmentPopupservice.recordsArray) {
        let clientDataArray: any = [];
        clientDataArray.push(tempRecord);
        this.clientSelected(clientDataArray);
        this.appointmentPopupservice.multiClientInfo.push({ "id": tempRecord.clientDetail.id, "comments": "", "TherapistId": unassignedTherapistId, "priceTypeId": tempRecord.clientDetail.priceTypeId, "price": 0 });
      }
      if (this.appointmentPopupservice.recordsArray.length == 1) {
        this.appointmentPopupservice.getRecentAppointments(this.appointmentPopupservice.recordsArray[0].clientDetail.id);
      }
      this.appointmentPopupservice.fromClientModule = false;
    }
    else if (this.appointmentPopupservice.editselectedClient) {
      this.openAddClient('EDIT', this.appointmentPopupservice.clientId);
    }
    else if (this.appointmentPopupservice.addFromClientModule) {
      this.openAddClient('NEW', this.appointmentPopupservice.clientId);
    }
    if (this.platFormExtendedSearchRequired) {
      await this.appointmentPopupservice.GetExtendedProfileSearchSetting();
      if (this.appointmentPopupservice.ExtendedSearchConfiguration[0].value == "true") {
        this.clientSearchForm.controls.isPlatformGuestSearch.setValue(true);
        this.isPlatformGuestSearch = true;
        if (this.isPlatformGuestSearch) {
          this.appointmentPopupservice.isExternalSearch = false;
          this.clientSearchForm.controls.isExternalSearch.setValue(false);
          this.clientSearchForm.controls.isExternalSearch.updateValueAndValidity();
        }
      }
    }
  }

  setStatusColors() {
    this.nsBDRclr = this.utils.getBorderColor('NOSHOW');
    this.closeBDRclr = this.utils.getBorderColor('CLOSED');
    this.checkinBDRclr = this.utils.getBorderColor('CKIN');
    this.checkoutBDRclr = this.utils.getBorderColor('CKOUT');
    this.sBDRclr = this.utils.getBorderColor('RESV');
  }


  async clientTypeChange(id) {
    this.appointmentPopupservice.selectedClientType = id;
    this.getClientSearchTypes();
    switch (id) {
      case ClientType.Guest:
        this.clientSearchTypeChange(this.defaultClientSearchValue);
        break;
      case ClientType.Member:
        this.clearSearchText = !this.clearSearchText;
        break;
      case ClientType.HotelReservation:
        var externalSearchSelectedValue = this.appointmentPopupservice.hotelReservationTypes.filter(a => a.checked == true);
        this.hotelReservationTypeChange(this.defaulthotelresvvalue == 1 || this.defaulthotelresvvalue == 2 || this.defaulthotelresvvalue == 0 ? this.defaulthotelresvvalue : externalSearchSelectedValue[0].id);
        break;
      default:
        break;
    }
    this.SetClientTypeValue(id);
  }

  clientSearchTypeChange(id) {
    this.clearSearchTextValue();
    this.clientSearchChange(id);
    this.selectedClientSearchType = id;
  }

  clientSearchChange(clientSearchswitchvalue) {
    this.clientSearchTypes.forEach(c => c.checked == false);
    if (this.clientSearchTypes.find(c => c.id == clientSearchswitchvalue)) {
      this.clientSearchTypes.find(c => c.id == clientSearchswitchvalue).checked = true
    }
    else {
      this.clientSearchTypes[0].checked = true;
      clientSearchswitchvalue = this.clientSearchTypes[0].id
    }
    switch (clientSearchswitchvalue) {
      case clientSearchType.firstName:
        this.selectedClientSearchType = clientSearchType.firstName;
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
      case clientSearchType.lastName:
        this.selectedClientSearchType = clientSearchType.lastName;
        this.existingClientPlaceholder = this.localization.captions.searchByLastName;
        break;
      case clientSearchType.name:
        this.selectedClientSearchType = clientSearchType.name;
        this.existingClientPlaceholder = this.localization.captions.searchByFullName;
        break;
      case clientSearchType.phone:
        this.selectedClientSearchType = clientSearchType.phone;
        this.existingClientPlaceholder = this.localization.captions.searchByPhoneNumber;
        break;
      case clientSearchType.email:
        this.selectedClientSearchType = clientSearchType.email;
        this.existingClientPlaceholder = this.localization.captions.searchByEmail;
        break;
      default:
        this.selectedClientSearchType = clientSearchType.firstName;
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
    }
  }


  clearSearchTextValue() {
    this.searchText = '';
    this.clearSearchText = !this.clearSearchText;
  }


  platFormsearch($event) {
    if (this.validateLocationMaxGuest()) {

      this.platFormsearchDebounce.next($event)

    }
  }

  private async GetClientTypes() {
    this.defaultvalues = await this.appointmentPopupservice.GetDefaultClientCategory();
    let defaultclientcategory = this.defaultvalues.result[0];
    let defaulthotelreservation = this.defaultvalues.result[1];
    let defaultexistingclient = this.defaultvalues.result[2];
    //  if(this.lostDeniedBusinessPopupservice.action == 1){
    //  if(this.appointmentPopupservice.isfromAppoinment){
    //     clientswitchvalue=this.appointmentPopupservice.selectedClientType;
    //  }
    //  else{
    this.clientswitchvalue = defaultclientcategory.value != "" ? (Number)(defaultclientcategory.value) : 0;
    this.defaulthotelresvvalue = defaulthotelreservation.value != "" ? (Number)(defaulthotelreservation.value) : 1;
    this.appointmentPopupservice.defaulthotelresvvalue = this.defaulthotelresvvalue;
    this.defaultClientSearchValue = defaultexistingclient != "" ? (Number)(defaultexistingclient.value) : 0;
    this.appointmentPopupservice.clientTypes = [{ id: 0, name: this.localization.captions.existingClients, checked: true }, { id: 3, name: this.localization.captions.hotelReservation, checked: false }, { id: 2, name: this.localization.captions.members, checked: false }];
    this.SetClientTypeValue(this.clientswitchvalue);
  }


  SetClientTypeValue(clientswitchvalue) {
    switch (clientswitchvalue) {
      case ClientType.Guest:
        this.appointmentPopupservice.clientTypes[0].checked = true;
        this.appointmentPopupservice.clientTypes[1].checked = false;
        this.appointmentPopupservice.clientTypes[2].checked = false;
        this.appointmentPopupservice.selectedClientType = ClientType.Guest;
        this.searchTextPlaceHolder = this.localization.captions.searchByClient;
        this.clientSearchChange(this.defaultClientSearchValue);
        break;
      case ClientType.Member:
        this.appointmentPopupservice.clientTypes[0].checked = false;
        this.appointmentPopupservice.clientTypes[1].checked = false;
        this.appointmentPopupservice.clientTypes[2].checked = true;
        this.appointmentPopupservice.selectedClientType = ClientType.Member;
        this.searchTextPlaceHolder = this.localization.captions.searchByMember;
        break;
      case ClientType.HotelReservation:
        this.appointmentPopupservice.clientTypes[0].checked = false;
        this.appointmentPopupservice.clientTypes[1].checked = true;
        this.appointmentPopupservice.clientTypes[2].checked = false;
        this.appointmentPopupservice.selectedClientType = ClientType.HotelReservation;
        this.hotelReservationTypeChange(this.defaulthotelresvvalue);
        break;
      default:
        this.appointmentPopupservice.selectedClientType = ClientType.Guest;
        this.searchTextPlaceHolder = this.localization.captions.searchByClient;
        break;
    }
  }

  setSearchText(clientSearchswitchvalue) {
    switch (clientSearchswitchvalue) {
      case clientSearchType.firstName:
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
      case clientSearchType.lastName:
        this.existingClientPlaceholder = this.localization.captions.searchByLastName;
        break;
      case clientSearchType.name:
        this.existingClientPlaceholder = this.localization.captions.searchByFullName;
        break;
      case clientSearchType.phone:
        this.existingClientPlaceholder = this.localization.captions.searchByPhoneNumber;
        break;
      case clientSearchType.email:
        this.existingClientPlaceholder = this.localization.captions.searchByEmail;
        break;
      default:
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
    }
  }

  ngOnChanges() {
    if (this.activateClient && !this.clientactivated) {
      this.ItineraryData = null;
      this.activeBorder(0, this.activateClient);
      this.clientactivated = true;
    }
  }

  ngOnDestroy(): void {

    this.commentPlaceHolder = '';
    this.appointmentPopupservice.clientScreenProperties['maxGuest'] = 1;
    this.appointmentPopupservice.clientsearchArray = [];
    this.appointmentPopupservice.isRadioDisable = false;
    this.appointmentPopupservice.currentClient = [];
    this.appointmentPopupservice.guestContact = [];
    this.appointmentPopupservice.relatedClient = [];
    if (!this.apptService.isAppointmentFromPackage) {
      this.appointmentPopupservice.imageArray = [];
    }
    this.imgSubscription.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.ItinerarySubscriber.unsubscribe();
  }

  loadClientContactLogs(clientDetail) {
    let showPopup = (this.appointmentPopupservice.clientEditData && (this.appointmentPopupservice.clientEditData.clientContactLogs && this.appointmentPopupservice.clientEditData.clientContactLogs.length > 0))
    if (showPopup) {
      this.appointmentPopupservice.add_client = false;
      this.openClientContactLogs(clientDetail);
    }
  }
  openClientContactLogs(clientDetail) {
    this.appointmentPopupservice.clientWidowActionType = "EDIT";
    let data;
    data = { headername: this.captions.KindInformation, headerIcon: 'icon-info-icon', buttonName: this.localization.captions.common.OK, type: 'CL', isPurged: this.appointmentPopupservice.clientEditData?.clientDetail?.isPurged, contactLogs: this.appointmentPopupservice.clientEditData.clientContactLogs }

    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '60%',
      height: '80%',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.isContactLogShow = false;
      this.PrioritizedPopupShow(clientDetail);
    })
  }

  //This method wrote beacause of, to show contact log information at first and consecutive popup next next.
  async PrioritizedPopupShow(clientDetail) {
    if (this.appointmentPopupservice.clientEditData.clientDetail.genderPreference == '') {
      this.appointmentPopupservice.otherdetails.gender = GenderPreference.none;
    }
    let medicalConditionExists: boolean = false;
    if (!this.isClientEditFromPackage && !this.appointmentPopupservice.add_client) {
      if (this.appointmentPopupservice != null && this.appointmentPopupservice.resultNewAppointment != null &&
        this.appointmentPopupservice.resultNewAppointment.service != null && this.appointmentPopupservice.resultNewAppointment.service != 0) {
        this.appUtils.CheckMedicalWarning(this.appointmentPopupservice.resultNewAppointment.service, [clientDetail.id],
          this.successCallback.bind(this), this.utils.errorCallback.bind(this));
        medicalConditionExists = true;
      }
    }
    if (!medicalConditionExists && !this.appointmentPopupservice.add_client) {
      await this.SetGuestInfomationsForClient(this.appointmentPopupservice.clientEditData, this.isClientEditFromPackage);
      this.loadClientPreference();
    }
  }

  async isPlatformGuestSearchChanged(e) {
    this.isPlatformGuestSearch = Boolean(e[0]);
    if (this.isPlatformGuestSearch) {
      this.appointmentPopupservice.isExternalSearch = false;
      this.clientSearchForm.controls.isExternalSearch.setValue(false);
      this.clientSearchForm.controls.isExternalSearch.updateValueAndValidity();
    }
    await this.getClientSearchTypes();
    this.setSearchText(this.selectedClientSearchType);
  }


  async SetGuestInfomationsForClient(clientDetail, packageEdit: boolean = false) {
    if (clientDetail.clientDetail.clientType != ClientType.Member) {
      await this.clientService.GetClientMandatoryandCustomFields();
      let isAllMandatoryFieldsFilled = await this.clientService.MandatoryFieldsValidation(clientDetail)
      if (!isAllMandatoryFieldsFilled) {
        let extraParams = { "clientDetail": clientDetail, "packageEdit": packageEdit }
        this.utils.ShowErrorMessage(this.captions.ErrorinCreatingAppointment, this.localization.replacePlaceholders(this.localization.getError(100005), ["clientName",], [clientDetail.clientDetail.firstName + ' ' + clientDetail.clientDetail.lastName]), ButtonType.Ok, this.SetGuestInformationCallback.bind(this), extraParams);
      }
    }
  }

  SetGuestInformationCallback(response: string, extraParams) {
    if (response.toLowerCase() == ButtonOptions.Ok.toLowerCase()) {
      this.appointmentPopupservice.clientEditData = extraParams.clientDetail;
      this.appointmentPopupservice.popupTitle = this.captions.EditClient;
      this.appointmentPopupservice.clientWidowActionType = "EDIT";
      this.appointmentPopupservice.isEditClient = true;
      this.appointmentPopupservice.isExternalClient = this.appointmentPopupservice.clientEditData?.clientDetail?.isExternalGuest;
      this.appointmentPopupservice.isExternalClientLinked = this.appointmentPopupservice.clientEditData?.clientDetail?.isExternalGuestLinked;
      this.appointmentPopupservice.saveText = this.allCaptions.common.Update;
      this.appointmentPopupservice.add_client = true;
      this.appointmentPopupservice.checkwhetherfromClient = true;
      if (this.apptService.isAppointmentFromPackage && !extraParams.packageEdit) {
        this.appointmentPopupservice.editClientFromPackage = true;
        this.openPopupForClientEdit(extraParams.clientDetail);
      }
      else {
        this.appointmentPopupservice.disablePreference = false;
        this.callBack = false;
        this.openAddClient(this.appointmentPopupservice.clientWidowActionType, extraParams.clientDetail.id);
      }
    }
  }

  loadClientPreference() {
    let showPopup = (this.appointmentPopupservice.clientEditData && (this.appointmentPopupservice.clientEditData.clientPreferences && this.appointmentPopupservice.clientEditData.clientPreferences.length > 0) ||
      (this.appointmentPopupservice.clientEditData.clientDetail && this.appointmentPopupservice.clientEditData.clientDetail.genderPreference))
    if (this.appointmentPopupservice.clientEditData.clientDetail.genderPreference == '') {
      this.appointmentPopupservice.otherdetails.gender = GenderPreference.none;
    }
    if (showPopup && this.appointmentPopupservice.hasAccessForClientPreference) {
      this.appointmentPopupservice.add_client = false;
      this.openClientPreference();
    }
  }

  openClientPreference() {
    this.appointmentPopupservice.disablePreference = true;
    this.appointmentPopupservice.clientWidowActionType = "EDIT";
    let data;
    data = { headername: this.captions.KindInformation, headerIcon: 'icon-info-icon', buttonName: this.localization.captions.common.OK, type: 'CP' }

    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '60%',
      height: '80%',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.appointmentPopupservice.disablePreference = false;
    })
  }



  /***
   * @function receiveMessage
   * @input Params <Array>
   * EventEmiter Form the Multi-search Components
   */

  receiveMessage($event) {
    this.clientSearchValue = $event.searchValue;
    if ($event.searchValue.length >= 3) {
      if (this.validateLocationMaxGuest()) {
        this.makeSearchCall($event.searchValue, this.selectedClientSearchType, $event.requestUid)
      }
    }
  }

  validateLocationMaxGuest() {
    if (this.appointmentPopupservice.recordsArray && this.appointmentPopupservice.clientScreenProperties.maxGuestLocation && this.appointmentPopupservice.clientScreenProperties.maxGuestLocation > 0
      && this.appointmentPopupservice.recordsArray.length >= this.appointmentPopupservice.clientScreenProperties.maxGuestLocation) {
      let message = this.captions.LocationMaxGuestClientConflict.interpolate({ location: this.appointmentPopupservice.clientScreenProperties.maxGuestLocation });
      this.utils.showAlert(message, global.AlertType.Warning, global.ButtonType.Ok);
      return false;
    }
    return true;

  }

  makeSearchCall(name: string, searchType: any, requestUid: string) {
    name = name.trim();
    if (name.length > 2) {
      this.appointmentPopupservice.clientsearchArray = [];
      this.appointmentPopupservice.showNodata = false;
      this.appointmentPopupservice.showLoading = true;
      var StartDate = this.appointmentPopupservice.waitListFromDate ? this.utils.formatDate(this.appointmentPopupservice.waitListFromDate) : "";
      var EndDate = this.appointmentPopupservice.waitListToDate ? this.utils.formatDate(this.appointmentPopupservice.waitListToDate) : "";
      if (this.appointmentPopupservice.selectedClientType == 3) {
        this.requestUid = "";
        const searchParams: StaySearchFilter = {
          reservationSearchCategory: this.appointmentPopupservice.hotelReservationType,
          activityStartDate: StartDate,
          activityEndDate: EndDate,
          searchKey: name,
          product: this.pmsSystemValue ? this.pmsSystemValue : ""
        }
        if (!StartDate || !EndDate) {
          // this.utils.showAlert(this.captions.GuaranteeMethod.MissingServiceDate, AlertType.Info);
          // this.clearSearchTextValue();
          this.appointmentPopupservice.showLoading = false;
          this.appointmentPopupservice.clientsearchArray = [];
          this.appointmentPopupservice.showNodata = true;
          return;
        }
        this.http.CallApiWithCallback<any[]>({
          host: global.Host.spaManagement,
          success: this.successCallback.bind(this),
          error: this.utils.errorCallback.bind(this),
          callDesc: "StayClientSearch",
          method: HttpMethod.Put,
          showError: false,
          body: searchParams,
          extraParams: ['dataBelongTo']
        });
      }
      else {
        this.requestUid = requestUid;
        this.http.CallApiWithCallback<any[]>({
          host: global.Host.spaManagement,
          success: this.successCallback.bind(this),
          error: this.utils.errorCallback.bind(this),
          callDesc: "extendedClientSearch",
          method: HttpMethod.Get,
          uriParams: { key: encodeURIComponent(name), searchType: searchType, requestUid: this.requestUid, clientType: this.appointmentPopupservice.selectedClientType, isExternalGuestSearch: this.appointmentPopupservice.isExternalSearch, isPlatformGuestSearch: this.isPlatformGuestSearch },
          showError: false,
          extraParams: ['dataBelongTo']
        });
      }
    }
  }

  /***
   * @function successCallback
   * @input Params <Array>
   * Server Call From the Server
   */

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
    switch (callDesc) {
      case "v2GetImagesByReferenceId":
        this.appointmentPopupservice.clientHasPic = result.result[0] ? true : false;
        this.appointmentPopupservice.clientImageChange(result.result);
        if (this.appointmentPopupservice.clientHasPic) {
          let client = this.appointmentPopupservice.recordsArray.find(x => x.clientDetail.guestId == result.result[0].imageReferenceId);
          if (client) {
            client['imageObj'] = result.result;
          }
          if (!this.appointmentPopupservice.imageArray.find(x => x['imageReferenceId'] === result.result[0].imageReferenceId)) {
            this.appointmentPopupservice.imageArray.push(result.result[0]);
          }
        }
        break;
      case "clientSearch":
      case "StayClientSearch":
      case "extendedClientSearch":
        {
          let searchApiResponse = <any>result.result;
          if (searchApiResponse) {
            this.appointmentPopupservice.showLoading = false;
            var responseUid = "";
            if (searchApiResponse != null && searchApiResponse.length > 0 && this.appointmentPopupservice.selectedClientType != 3)
              responseUid = searchApiResponse[0].requestUid

            if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
              this.appointmentPopupservice.clientsearchArray = this.checkForClientAlreadyAdded(searchApiResponse);
            }
          }
          else {
            this.appointmentPopupservice.showLoading = false;
            this.appointmentPopupservice.clientsearchArray = [];
            this.appointmentPopupservice.showNodata = true;
          }
        }
        break;
      case "getClientInfo": {
        let clientDetail = <any>result.result;
        this.appointmentPopupservice.isExternalClient = (clientDetail.clientDetail && clientDetail.clientDetail.isExternalGuest) ?? false;
        this.appointmentPopupservice.isExternalClientLinked = (clientDetail.clientDetail && clientDetail.clientDetail.isExternalGuestLinked) ?? false;
        if (extraParams && extraParams.length > 0 && extraParams[0] == "FromClientSearch") {
          //Get client info for client preferences
          this.appointmentPopupservice.add_client = false;
        } else {
          //edit client scope
          this.appointmentPopupservice.add_client = true;
          this.appointmentPopupservice.disablePreference = false;
          this.appointmentPopupservice.currentClient = clientDetail.length > 0 ? clientDetail[0] : clientDetail;
          this.callBack = false;
        }
        this.appointmentPopupservice.clientEditData = clientDetail;


        // Set Spa informations for Guestprofile Guest
        let isClientEditFromPackage: boolean = extraParams && extraParams.length > 0 && extraParams[0] == "PackageEdit"
        // Open pop up if the edit call comes from package pop up
        if (isClientEditFromPackage || this.apptService.isaddClientFromClientInfo) {
          this.openPopupForClientEdit(clientDetail);
        }


        if (this.callBack) {
          this.appointmentPopupservice.disablePreference = true;

          let showPopup = (this.appointmentPopupservice.clientEditData && (this.appointmentPopupservice.clientEditData.clientPreferences && this.appointmentPopupservice.clientEditData.clientPreferences.length > 0) ||
            (this.appointmentPopupservice.clientEditData.clientDetail && this.appointmentPopupservice.clientEditData.clientDetail.genderPreference))

          let filteredContactLog: any[] = [];
          this.appointmentPopupservice.clientEditData.clientContactLogs.forEach((contactLog, i) => {
            let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
            let dueDate = contactLog.dueDate ? this.utils.GetDateWithoutTime(this.utils.getDate(contactLog.dueDate)) : null;
            let expiryDate = contactLog.expiryDate ? this.utils.GetDateWithoutTime(this.utils.getDate(contactLog.expiryDate)) : null;
            let propertyDate = this.utils.GetDateWithoutTime(this.PropertyInfo.CurrentDate);
            if (contactLog.isAlert && dueDate && (expiryDate >= propertyDate || expiryDate == null)) {
              if (contactLog.isPrivate && contactLog.productId == productId && dueDate.toDateString() == propertyDate.toDateString()) {//&& contactLog.productId != 1) || !contactLog.isPrivate
                filteredContactLog.push(contactLog);
              }
              else if (!contactLog.isPrivate && dueDate.toDateString() == propertyDate.toDateString()) {
                filteredContactLog.push(contactLog);
              }
            }
            else if (contactLog.isAlert && contactLog.dueDate == null && (expiryDate >= propertyDate || expiryDate == null)) {
              if (contactLog.isPrivate && contactLog.productId == productId) {
                filteredContactLog.push(contactLog);
              }
              else if (!contactLog.isPrivate) {
                filteredContactLog.push(contactLog);
              }
            }
          });
          this.isContactLogShow = filteredContactLog.length > 0;
          this.appointmentPopupservice.clientEditData.clientContactLogs = this.isContactLogShow ? filteredContactLog : [];
          if (this.isContactLogShow) {
            this.loadClientContactLogs(clientDetail);
          }
          if (!this.isContactLogShow) {
            await this.PrioritizedPopupShow(clientDetail);
          }

          if (this.appointmentPopupservice.clientEditData.clientDetail.genderPreference == '') {
            this.appointmentPopupservice.otherdetails.gender = global.GenderPreference.none;
          }
          if (showPopup && this.appointmentPopupservice.hasAccessForClientPreference) {
            this.appointmentPopupservice.add_client = false;
          }
        }
      }
        break;
      case "GetItineraryForAppointment": {
        let clientIteneraryByDate = <any>result.result;
        this.appointmentPopupservice.resultNewAppointment.timelineData = clientIteneraryByDate;
        this.appointmentPopupservice.resultNewAppointment.clientFullItinerary = clientIteneraryByDate;
        this.appointmentPopupservice.subscribeWaitListDetail(clientIteneraryByDate, this.appointmentPopupservice.waitListFromDate, this.appointmentPopupservice.waitListToDate);
      }
        break;
      case "GetClientBlockById": {
        const response = result.result ? <any>result.result : null;
        this.appointmentPopupservice.ClientBlockInformation = response;
        if (!this.appointmentPopupservice.clientBlockInfos)
          this.appointmentPopupservice.clientBlockInfos = [];
        if (response) {
          this.appointmentPopupservice.clientBlockInfos = this.appointmentPopupservice.clientBlockInfos.filter(y => y.id != response.id);
          this.appointmentPopupservice.clientBlockInfos.push(response);
          this.validateClientBlockInformation(response.clientId);
        }
      }
        break;
      case "GetClientblockdetails": {
        let clientBlockInfos = result.result ? <any>result.result : [];
        if (!this.appointmentPopupservice.clientBlockInfos)
          this.appointmentPopupservice.clientBlockInfos = [];
        if (clientBlockInfos && clientBlockInfos.length > 0) {

          clientBlockInfos.map(x => {
            this.appointmentPopupservice.clientBlockInfos = this.appointmentPopupservice.clientBlockInfos.filter(y => y.clientId != x.clientId);
            this.appointmentPopupservice.clientBlockInfos.push(x);
          });
          this.validateClientBlockInformation(clientBlockInfos[0].clientId);
        }

        break;

      }

    }
  }

  async setClientInfo(event: string, clientDetails: any[]) {
    let unassignedTherapistId = 0;   // included to make a validation during create new appointment
    this.appointmentPopupservice.isClientCompleted = false;
    if (this.appointmentPopupservice.clientTypes[2].checked == true) {
      clientDetails = clientDetails.filter(client => client.clientDetail.clientLinkId === event);
    } else {
      clientDetails = clientDetails.filter(client => client.clientDetail.guestId === event);
    }

    let scheduleDt = new Date();
    let memberInfo = clientDetails && clientDetails.length > 0 && clientDetails[0].clientDetail.clientLinkId != '' && clientDetails[0].clientDetail.clientLinkId != null ? await this.appointmentPopupservice.getMemberInfo(clientDetails[0].clientDetail.clientLinkId, scheduleDt.toISOString(), clientDetails[0].clientDetail.corpId) : null;
    if (clientDetails && clientDetails.length > 0) {
      var clientData: ClientLabel = {
        Id: clientDetails[0].clientDetail.id,
        FirstName: clientDetails[0].clientDetail.firstName,
        LastName: clientDetails[0].clientDetail.lastName,
        clientType: clientDetails[0].clientDetail.clientType
      };
      clientData = clientData ? clientData : { Id: 0, FirstName: '', LastName: '', clientType: 0, };
      this.appointmentPopupservice.labelRecords.push(clientData);
    }

    this.appointmentPopupservice.getRecentAppointments(clientDetails[0]?.clientDetail.id);
    this.appointmentPopupservice.recordsArray.push(clientDetails[0] ?? {
      Id: clientDetails[0].clientDetail.id,
      FirstName: clientDetails[0].clientDetail.firstName,
      LastName: clientDetails[0].clientDetail.lastName,
      clientType: clientDetails[0].clientDetail.clientType
    });

    let priceTypeId = clientDetails[0]?.clientDetail.priceTypeId;
    if (this.appointmentPopupservice.clientInfoPriceType && memberInfo && memberInfo.priceType) {
      let selectedPriceTypeObject = this.appointmentPopupservice.clientInfoPriceType.find(x => x.code.toLowerCase() == memberInfo.priceType.toLowerCase())
      if (selectedPriceTypeObject) {
        priceTypeId = selectedPriceTypeObject.priceTypeId;
      }

    }
    const selectedPriceType = this.appointmentPopupservice.selectedServicePriceTypes.find(x => x.priceTypeId === priceTypeId)

    this.appointmentPopupservice.multiClientInfo.push({ "id": clientDetails[0]?.clientDetail.id, "comments": "", "TherapistId": unassignedTherapistId, "priceTypeId": priceTypeId, "price": selectedPriceType ? selectedPriceType.price : 0 });
    this.appointmentPopupservice.isPriceTypeApplicable();
    this.appointmentPopupservice.activeBorderId = this.appointmentPopupservice.recordsArray.length > 1 ? this.appointmentPopupservice.recordsArray.length - 1 : 0;
    if (this.appointmentPopupservice.labelRecords.length > 1) {
      this.appointmentPopupservice.multipleRecords = true;
      this.appointmentPopupservice.showDetails = false;
    } else {
      this.appointmentPopupservice.multipleRecords = false;
      this.appointmentPopupservice.showDetails = true;
      this.appointmentPopupservice.clientId = clientDetails[0]?.clientDetail.id;
      this.appointmentPopupservice.guestId = clientDetails[0]?.clientDetail.guestId;
    }
    this.appointmentPopupservice.clientsearchArray = [];
    if (this.appointmentPopupservice.labelRecords.length > 0) {
      this.appointmentPopupservice.isClientCompleted = true;
    }

  }

  findRelationshipClicked() {
    this.findRelationshipDebounce.next();
  }

  async openFindRelationship() {
    if (this.appointmentPopupservice.labelRecords.length > 1) {
      this.findrelationId = this.appointmentPopupservice.activeSelectedClient ? this.appointmentPopupservice.activeSelectedClient : this.appointmentPopupservice.currentClient.id;
      if (this.findrelationId != this.appointmentPopupservice.clientId) {
        this.findrelationId = this.appointmentPopupservice.clientId;
      }
    }
    else {
      this.findrelationId = this.appointmentPopupservice.labelRecords[0].Id;
    }
    this.findrelationId = this.findrelationId ? this.findrelationId : this.appointmentPopupservice.clientEditData.id;
    let clientDetail = await this.http.CallApiAsync<any>({
      host: Host.spaManagement,
      callDesc: "GetClientRelationships",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { id: this.findrelationId }
    });
    this.currentguestInfo = clientDetail.result;
    this.appointmentPopupservice.externalPMSConfNo = clientDetail.result.confirmationNumber;
    let swapClient = this.appointmentPopupservice.recordsArray.length >= this.appointmentPopupservice.clientScreenProperties.maxGuest;

    let dialogRef = this.dialog.open(FindRelationshipComponent, {
      width: '40%',
      height: '60%',
      hasBackdrop: true,
      disableClose: true,
      data: {
        form: this.currentguestInfo,
        swapClient: swapClient,
        isEdit: true,
        closebool: true
      }
    });
    this.actionDialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result?.data.forEach(e => {
          result.relatedClient.forEach(x => {
            if (e.clientDetail.guestId == x.contactGuestId) {
              if (x.checked) {
                if (this.appointmentPopupservice.recordsArray.map(b => b.id).includes(e.id)) {
                  return;
                }
                else {
                  if (swapClient) {
                    this.appointmentPopupservice.recordsArray.forEach(arr => {
                      if (arr.id === this.findrelationId) {
                        this.removeRelatedClient(arr.id);
                      }
                    })
                  }
                  this.setClientInfo(e.clientDetail.guestId, [e]);
                  this.clientSelected([e]);
                }
              }
              else {
                this.appointmentPopupservice.recordsArray.forEach(arr => {
                  if (arr.id === e.clientDetail.id) {
                    this.removeRelatedClient(arr.id);
                  }
                })
              }
            }

          })
          // this.appointmentPopupservice.recordsArray.push(e ?? {
          //     Id: e.clientDetail.id,
          //     FirstName: e.clientDetail.firstName,
          //     LastName: e.clientDetail.lastName,
          //     clientType: e.clientDetail.clientType
          // });
        });

        //this.ClientAddedEvent.emit(result);
      }
    });
  }

  removeRelatedClient(clientId) {
    this.clientToRemove = this.appointmentPopupservice.recordsArray.find(a => a.id == clientId);
    this.appointmentPopupservice.multiClientInfo = this.appointmentPopupservice.multiClientInfo.filter(r => r.id != this.clientToRemove.id);
    this.appointmentPopupservice.labelRecords = this.appointmentPopupservice.labelRecords.filter(r => r.Id != this.clientToRemove.id);
    this.appointmentPopupservice.recordsArray = this.appointmentPopupservice.recordsArray.filter(r => r.clientDetail.id != this.clientToRemove.id);
  }


  openPopupForClientEdit(clientDetail?) {
    this.appointmentPopupservice.isExternalClient = (clientDetail?.isExternalGuest) ?? false;
    this.appointmentPopupservice.isExternalClientLinked = (clientDetail?.isExternalGuestLinked) ?? false;
    let data = {
      closebool: true,
      action: 'Edit',
      clientInfo: clientDetail,
      fromPackage: true
    };
    let dialogRef = this.dialog.open(AppointmentPopupComponent, {
      width: '85%',
      height: '85%',
      disableClose: true,
      hasBackdrop: true,
      data: data
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (this.ClientAddedEvent)
        this.appointmentPopupservice.ClientAddedEvent(res);
    });
  }



  /***
  * @function getMail
  * @input Params <Array>
  * Return the Mail From the Mail Array
  */
  getMail(e) {
    if (e.length > 0) {
      let emailId
      emailId = e[0].emailId;
      return emailId;
    }
  }

  /***
  * @function getPhone
  * @input Params <Array>
  * Return the Phone From the Phone Array
  */

  getPhone(p) {
    if (p.length > 0) {
      let phonenumber
      phonenumber = p[0].number;
      return phonenumber;
    }
  }

  changeComment(e) {
    this.appointmentPopupservice.multiClientInfo.forEach(element => {
      if (element.id == this.appointmentPopupservice.clientId) {
        element.comments = e.target.value;
      }
    });
  }

  changeTherapist(e) {
    this.appointmentPopupservice.multiClientInfo.forEach(element => {
      if (element.id == this.appointmentPopupservice.clientId) {
        element.TherapistId = e.value;
      }
    });
  }

  activeBorder(i, clientObj) {
    this.appointmentPopupservice.activeBorderId = i
    this.activeClient = clientObj.clientDetail.id;
    this.appointmentPopupservice.activeSelectedClient = this.activeClient;
    this.appointmentPopupservice.currentClient = clientObj;

    let dt: Date =
      typeof this.appointmentPopupservice.resultNewAppointment.dateField == "string"
        ? this.utils.getDate(this.appointmentPopupservice.resultNewAppointment.dateField)
        : this.appointmentPopupservice.resultNewAppointment.dateField;
    if (dt && dt.getTime() && this.appointmentPopupservice.resultNewAppointment.time) {
      this.utils.convertDateFormat(this.utils.getDate(
        dt.getFullYear() +
        "-" +
        this.utils.getFullMonth(dt) +
        "-" +
        this.utils.getFullDate(dt) +
        "T" +
        this.localization.DeLocalizeTime(this.appointmentPopupservice.resultNewAppointment.time)
      )
      );

    }

    this.appointmentPopupservice.clientId = clientObj.clientDetail.id;
    this.appointmentPopupservice.guestId = clientObj.clientDetail.guestId;
    let clientFormatedName: string = clientObj.clientDetail.firstName + " " + clientObj.clientDetail.lastName;
    this.commentPlaceHolder = this.localization.replacePlaceholders(this.captions.Commentsaddedfor, ['clientName'], [clientFormatedName])
    this.refreshClient(clientObj.clientDetail.id);
    this.getFullItenerary(clientObj.clientDetail.guestId);
    let defaultclientcategory = this.defaultvalues?.result[0];
    let defaulthotelreservation = this.defaultvalues?.result[1];
    if (this.lostDeniedBusinessPopupservice.action == 1 || this.waitListService.action == 1) {
      if (this.appointmentPopupservice.isfromAppoinment) {
        this.clientswitchvalue = clientObj.clientDetail.clientType;
      }
      else {
        this.clientswitchvalue = defaultclientcategory.value != "" ? (Number)(defaultclientcategory.value) : 0;
      }
    }
    if (this.lostDeniedBusinessPopupservice.action == 2 || this.lostDeniedBusinessPopupservice.action == 1 || this.waitListService.action == 2 || this.waitListService.action == 1 || this.waitListService.action == 0) {
      this.clientswitchvalue = clientObj.clientDetail.clientType;

    }
    this.SetClientTypeValue(this.clientswitchvalue);
  }

  refreshClient(clientId) {
    for (let itemMultiClient of this.appointmentPopupservice.multiClientInfo) {
      if (itemMultiClient.id == clientId) {
        this.clientComments = itemMultiClient.comments;
        this.clientTherapist = itemMultiClient.TherapistId;
        this.appointmentPopupservice.clientInfopriceTypeModel = itemMultiClient.priceTypeId;
        this.appointmentPopupservice.selectedClientPriceValue = itemMultiClient.price;
      }
    }
  }


  openAddClient(e, id?: number) {
    if (e == 'NEW' && !this.validateLocationMaxGuest()) {
      return;
    }
    this.apptService.isaddClientFromClientInfo = true;
    //this.apptService.isAppointmentFromPackage = true;
    this.toAddClient = this.utils.getRandomDecimal();
    this.appointmentPopupservice.clientWidowActionType = e;
    if (e.toLowerCase() == 'new' && this.breakPoint.CheckForAccess([global.SPAManagementBreakPoint.AddNewClientProfile])) {
      if (this.searchLimit && this.searchLimit > 0 && this.appointmentPopupservice.recordsArray.length >= this.searchLimit) {
        return;
      }
      let firstName = "";
      let lastName = "";
      let email: Email[] = [];
      let phone: PhoneNumber[] = [];
      if (this.clientSearchValue) {

        switch (this.selectedClientSearchType) {
          case clientSearchType.firstName:
            firstName = this.clientSearchValue;
            break;
          case clientSearchType.lastName:
            lastName = this.clientSearchValue;
            break;
          case clientSearchType.name:
            let name = this.clientSearchValue.split(" ");
            if (name.length > 1) {
              lastName = name[name.length - 1];
              name.splice(-1, 1);
              firstName = name.join(' ');
            }
            else {
              firstName = name[0];
            }
            break;
          case clientSearchType.email:
            let emailsearch: Email = {
              id: 0,
              emailId: this.clientSearchValue,
              contactTypeId: 9,
              clientId: 0,
              isPrimary: false,
              isPrivate: false,
              propertyId: 0,
              subPropertyId: 0,
              platformContactUuid: '',
            };
            email.push(emailsearch);
            break;
          case clientSearchType.phone:
            let number = Number(this.clientSearchValue);
            let phoneSearch: any = {
              clientId: 0,
              contactTypeId: 1,
              extension: null,
              id: 0,
              isPrimary: false,
              isPrivate: false,
              number: isNaN(number) ? "" : this.clientSearchValue,
              platformContactUuid: "00000000-0000-0000-0000-000000000000"
            };
            phone.push(phoneSearch);
            break;

        }

      }
      this.appointmentPopupservice.popupTitle = this.captions.NewClient;
      this.appointmentPopupservice.add_client = true;
      this.appointmentPopupservice.ClientSelectedTab = 0;
      this.appointmentPopupservice.saveText = this.allCaptions.common.Save;
      this.appointmentPopupservice.clientEditData = null;
      this.appointmentPopupservice.clientPreferences = null;
      this.appointmentPopupservice.clientCreditCardInfo = null;
      this.appointmentPopupservice.ImgTempHolder = {};
      this.appointmentPopupservice.clientNameInfo.firstName = firstName;
      this.appointmentPopupservice.clientNameInfo.lastName = lastName;
      this.appointmentPopupservice.clientNameInfo.email = email;
      this.appointmentPopupservice.clientNameInfo.phone = phone;
      //this.waitListService.isAddClient = true;
      //this.dialogReference.close();
      let data = {
        closebool: true,
        fromPackage: true
      };
      let dialogRef = this.dialog.open(AppointmentPopupComponent, {
        width: '85%',
        height: '85%',
        disableClose: true,
        hasBackdrop: true,
        data: data
      });
      let dialogObs = dialogRef.afterClosed().subscribe(res => {
        this.appointmentPopupservice.add_client = false;
        //this.waitListService.isAddClient = false;
        if (this.ClientAddedEvent && res) {
          this.appointmentPopupservice.clientInformationForm.control.markAsDirty();
          this.appointmentPopupservice.ClientAddedEvent(res);
          this.appointmentPopupservice.multiClientInfo.push({ id: res, TherapistId: 0, comments: '', priceTypeId: 0, price: 0 });
          this.appointmentPopupservice.multipleRecords = this.appointmentPopupservice.multiClientInfo.length > 1;
          this.GetClientBlockById(res);
        }
        // if (this.appointmentPopupservice.isFromLostDeniedBusiness)
        //   this.addLostDeniedBusiness('New');
        // else if (this.appointmentPopupservice.isFromWaitlist)
        //   this.addWaitlist('New');
        dialogObs.unsubscribe();
      });
    }
    //Added
    else if (e.toLowerCase() == 'edit' && this.breakPoint.CheckForAccess([global.SPAManagementBreakPoint.EditClientProfile])) {
      const tempClientIds = (this.appointmentPopupservice.recordsArray && this.appointmentPopupservice.recordsArray.length > 0 ? this.appointmentPopupservice.recordsArray[0].clientDetail.id : 0);
      let clientId = id ? id : tempClientIds;
      let guestId = (this.appointmentPopupservice.recordsArray && this.appointmentPopupservice.recordsArray.length > 0) ? this.appointmentPopupservice.recordsArray[0].clientDetail.guestId : global.DefaultGUID;
      this.appointmentPopupservice.clientId = _.cloneDeep(clientId);
      this.appointmentPopupservice.guestId = _.cloneDeep(guestId);
      if (clientId) {
        if (this.appointmentPopupservice.imageArray && this.appointmentPopupservice.imageArray.length > 0) {
          this.appointmentPopupservice.clientImageChange(this.appointmentPopupservice.imageArray.filter(x => x && x.imageReferenceId == guestId && x.referenceType == global.ImgRefType.guest));
        }
        else {
          this.imgService.GetImagesByReference(guestId, global.ImgRefType.client, this.successCallback.bind(this), this.utils.errorCallback.bind(this), [], true);
        }
        this.appointmentPopupservice.popupTitle = this.captions.EditClient;
        this.appointmentPopupservice.clientWidowActionType = "EDIT";
        this.appointmentPopupservice.isEditClient = true;
        this.appointmentPopupservice.saveText = this.allCaptions.common.Update;
        this.http.CallApiWithCallback({
          host: global.Host.spaManagement,
          success: this.successCallback.bind(this),
          error: this.utils.errorCallback.bind(this),
          callDesc: "getClientInfo",
          method: HttpMethod.Get,
          showError: true,
          uriParams: { id: clientId },
          extraParams: [this.apptService.isAppointmentFromPackage ? 'PackageEdit' : '']
        });
      }
    }
  }

  async clientSelected(clientObj) {
    if (clientObj == null) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.common.InActiveMember, ButtonType.Ok);
      return;
    }
    this.utils.ToggleLoader(true);
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "getClientInfo",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { id: clientObj[0].clientDetail.id },
      extraParams: ["FromClientSearch"]
    });
    this.utils.ToggleLoader(false);

    this.appointmentPopupservice.currentClient = clientObj.length ? clientObj[0] : clientObj;
    this.appointmentPopupservice.activeBorderId = 0;

    this.appointmentPopupservice.clientCreditCardInfo = this.appointmentPopupservice.currentClient?.clientCreditCardInfo;
    this.appointmentPopupservice.disableRadioButton();
    this.clientForm.control.markAsDirty();
    this.waitListService.isFormDirty = true;
    this.lostDeniedBusinessPopupservice.isFormDirty = true;
    this.appointmentPopupservice.attachClient = false;
    let selectedClientInfo = this.appointmentPopupservice.recordsArray;
    let lastAddedClient = clientObj[0];
    if (clientObj.length >= 1) {
      let clientFormatedName: string = lastAddedClient.clientDetail.firstName + " " + lastAddedClient.clientDetail.lastName;
      this.commentPlaceHolder = this.commentPlaceHolder = this.localization.replacePlaceholders(this.captions.Commentsaddedfor, ['clientName'], [clientFormatedName]);

    } else {
      this.commentPlaceHolder = ""
    }

    this.appointmentPopupservice.recordsArray.forEach(x => this.appointmentPopupservice.getFormattedPhone(x));
    let matchingClientMap = this.appointmentPopupservice.clientGuestMap.filter(x => x.ClientId == clientObj[0].clientDetail.id)
    if (!matchingClientMap || matchingClientMap.length == 0) {
      this.appointmentPopupservice.clientGuestMap.push({ "ClientId": clientObj[0].clientDetail.id, "GuestId": clientObj[0].clientDetail.guestId, "PMSActivityId": clientObj[0].clientDetail.pmsActivityId });
    }
    this.appointmentPopupservice.guestId = clientObj[0].clientDetail.guestId;
    this.imgService.GetImagesByReference(clientObj[0].clientDetail.guestId, global.ImgRefType.client, this.successCallback.bind(this), this.utils.errorCallback.bind(this), [], true);
    this.ItineraryData = null;
    this.callBack = true;
    this.appointmentPopupservice.clientWidowActionType = "EDIT";
    //Open pop up to show the client preferences

    this.GetClientBlockById(clientObj[0].clientDetail.id);


    if (!clientObj[0].clientDetail.bypassClientScheduling) {
      this.appointmentPopupservice.GetConflictingActivities(clientObj[0].id);
    }
    this.getFullItenerary();

    //Added to get CMS Values
    if ((clientObj && clientObj[0].clientDetail && clientObj[0].clientDetail.loyaltyDetail && clientObj[0].clientDetail.loyaltyDetail.length > 0) && (clientObj[0].clientDetail.loyaltyDetail[0].patronId != null)) {
      this.utils.ToggleLoader(true);
      let patronId = clientObj[0].clientDetail.loyaltyDetail[0].patronId;
      let playerInfo: CMSPlayerInfo = await this._cmsBusiness.GetCMSPlayerInfo(patronId);
      if (playerInfo) {
        let cmsHasChange: boolean = false;
        cmsHasChange = this.appointmentPopupservice.isCMSDataChanged(clientObj[0], playerInfo.personalDetails);

        if (cmsHasChange) {
          if ((this.appointmentPopupservice.labelRecords && this.appointmentPopupservice.labelRecords.length > 0) && (clientObj[0].clientDetail.firstName !== playerInfo.personalDetails.firstName ||
            clientObj[0].clientDetail.lastName !== playerInfo.personalDetails.lastName)) {

            let clientName = this.appointmentPopupservice.labelRecords.find(c => c.Id == clientObj[0].clientDetail.id);
            if (clientName) {
              clientName.FirstName = playerInfo.personalDetails.firstName;
              clientName.LastName = playerInfo.personalDetails.lastName;
            }
          }
          await this.appointmentPopupservice.UpdateCMSDetailOnExistingGuest(patronId, clientObj[0]);

          if (clientObj[0].emails && clientObj[0].emails.length > 0) {
            clientObj[0].emails.forEach(element => {
              element.contactTypeId = element.contactTypeId == 0 ? 10 : element.contactTypeId;
            });
          }
          if (clientObj[0].phoneNumbers && clientObj[0].phoneNumbers.length > 0) {
            this.appointmentPopupservice.recordsArray.forEach(x => this.appointmentPopupservice.getFormattedPhone(x));
          }

          let callDesc = 'updateClient';
          let uriParams = { id: clientObj[0].clientDetail.id };
          this.http.CallApiWithCallback({
            host: global.Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: callDesc,
            uriParams: uriParams,
            method: HttpMethod.Put,
            body: clientObj[0],
            showError: true,
            extraParams: []
          });
        }
      }
      else {
        this.openAddClient('edit', clientObj[0].clientDetail.id);
      }
      this.utils.ToggleLoader(false);
    }
  }

  GetClientBlockById(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: global.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetClientBlockById",
      uriParams: { id: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  removeFromSearchBar(id = 0) {
    this.clientSearchValue = "";
    this.clientForm.control.markAsDirty();
    this.waitListService.isFormDirty = true;
    this.lostDeniedBusinessPopupservice.isFormDirty = true;
    if (id == 0) { // All clients has been removed from master cancel icon - from client chip
      this.RemoveAllClients();
      this.appointmentPopupservice.multiClientInfo = [];
      this.appointmentPopupservice.recordsArray = [];
      this.appointmentPopupservice.resultExistingClients = [];
      this.appointmentPopupservice.clientBlockInfos = [];
    }
    else {
      this.appointmentPopupservice.multiClientInfo = this.appointmentPopupservice.multiClientInfo.filter(r => r.id != id);
      this.appointmentPopupservice.recordsArray = this.appointmentPopupservice.recordsArray.filter(r => {
        return r.clientDetail.id != id
      });
      if (this.appointmentPopupservice.resultExistingClients) {
        this.appointmentPopupservice.resultExistingClients = this.appointmentPopupservice.resultExistingClients.filter(r => r != id);
      }
      this.appointmentPopupservice.clientBlockInfos = this.appointmentPopupservice.clientBlockInfos.filter(r => r && r.clientId != id);
    }
    this.appointmentPopupservice.currentClient = this.appointmentPopupservice.recordsArray && this.appointmentPopupservice.recordsArray.length > 0 ? this.appointmentPopupservice.recordsArray[0] : null;
    this.appointmentPopupservice.activeBorderId = 0;
    this.appointmentPopupservice.activeSelectedClient = this.appointmentPopupservice.recordsArray[0]?.clientDetail?.id;
    this.appointmentPopupservice.clientId = this.appointmentPopupservice.recordsArray[0]?.clientDetail?.id;
    this.appointmentPopupservice.activateClientSelected = this.appointmentPopupservice.recordsArray[0];
    this.appointmentPopupservice.disableRadioButton();

  }

  private RemoveAllClients() {
    let clientLength = this.appointmentPopupservice.recordsArray.length;
    if (clientLength > 0) {
      let client = this.appointmentPopupservice.recordsArray[this.appointmentPopupservice.recordsArray.length - 1];
      this.appointmentPopupservice.recordsArray = this.appointmentPopupservice.recordsArray.filter(r => {
        return r.clientDetail.id != client.clientDetail.id
      });
      this.RemoveAllClients();
    }
  }

  loadValidTherapist() {

    if (typeof this.appointmentPopupservice.clientScreenProperties.selectedStaff != "undefined") {
      this.validTherapist = this.appointmentPopupservice.clientScreenProperties.selectedStaff;
    } else {
      this.validTherapist = [];
    }
    if (this.validTherapist.length == 0) {
      this.appointmentPopupservice.selectedIndex = 0;
      this.appointmentPopupservice.therapistNotSelected = true;
    } else {
      this.appointmentPopupservice.selectedIndex = 1
      this.appointmentPopupservice.therapistNotSelected = true;
    }
  }


  checkForClientAlreadyAdded(client: ClientInfo[]): ClientInfo[] {
    let alreadyAdded: ClientInfo[] = this.appointmentPopupservice.recordsArray as ClientInfo[]; //this collection contains already selected client items in client screen.
    let clientSearchResArr: ClientInfo[] = client;
    let indexOfExisting: number;
    alreadyAdded.forEach(alreadyAddedCli => {
      indexOfExisting = clientSearchResArr.findIndex(c => c.clientDetail.id == alreadyAddedCli.clientDetail.id);
      if (indexOfExisting != -1) {
        clientSearchResArr.splice(indexOfExisting, 1);  //remove from the search result collection if the item matches.
      }
    }
    )
    return clientSearchResArr.sort((a, b) => (a.clientDetail.firstName + ' ' + a.clientDetail.lastName) > (b.clientDetail.firstName + ' ' + b.clientDetail.lastName) ? 1 : -1);
  }

  addWaitlist(event): void {
    var titile = this.waitListService.action == global.OperationType.Create ? this.captions.AddWaitList : this.captions.EditWaitList;
    let dataArray = { headername: titile, closebool: true, fromPackage: true };
    this.waitListService.selectedIndex = 1;
    this.dialog.open(AddwaitlistPopupComponent, {
      width: '85%',
      height: '85%',
      disableClose: true,
      data: dataArray,
      hasBackdrop: true,
      panelClass: 'small-popup'
    });
  }

  addLostDeniedBusiness(event): void {
    var titile = this.lostDeniedBusinessPopupservice.action == global.OperationType.Create ? this.captions.pg_title_createLostAndFound : this.captions.pg_title_editLostAndFound;
    let dataArray = { headername: titile, closebool: true, fromPackage: true };
    this.waitListService.selectedIndex = 1;
    this.dialog.open(AddLostDeniedBusinessPopupComponent, {
      width: '95%',
      height: '90%',
      disableClose: true,
      data: dataArray,
      hasBackdrop: true,
      panelClass: 'small-popup'
    });
  }
  getFullItenerary(waitListGuestId?: any) {
    if (waitListGuestId) { this.appointmentPopupservice.guestId = waitListGuestId ? waitListGuestId : global.DefaultGUID }
    let guestId = this.appointmentPopupservice.guestId ?
      this.appointmentPopupservice.guestId : global.DefaultGUID;
    if (guestId && guestId != global.DefaultGUID && this.appointmentPopupservice.waitListFromDate) {
      this.http.CallApiWithCallback({
        host: global.Host.schedule,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "GetItineraryForAppointment",
        method: HttpMethod.Get,
        uriParams: { clientId: guestId, dateTime: this.utils.convertDateFormat(this.appointmentPopupservice.waitListFromDate) },
        showError: true,
        extraParams: null
      });
    }
  }
  playerWorthDetails(patronId: string) {
    this.clientCommonService.openDialogPopup(patronId);
  }




  removeClient(clientId: number) {
    if (this.appointmentPopupservice.recordsArray && this.appointmentPopupservice.recordsArray.length > 0) {
      this.appointmentPopupservice.labelRecords = this.appointmentPopupservice.labelRecords.filter(r => r.Id != clientId);
      this.appointmentPopupservice.recordsArray = this.appointmentPopupservice.recordsArray.filter(r => r.clientDetail.id != clientId);
      this.appointmentPopupservice.clientBlockInfos = this.appointmentPopupservice.clientBlockInfos.filter(r => r.clientId != clientId);
    }
  }
  validateClientBlockInformation(clientId: any) {

    const clientBlock = this.appointmentPopupservice.clientBlockInfos ?
      this.appointmentPopupservice.clientBlockInfos.find(x => x.clientId === clientId && x.blockClient && !x.clientBlockOverrideAccess) : null;

    if (clientBlock) {
      this.poppedClientId = clientBlock.clientId;
      this.utils.showAlert(this.captions.ClientBlockedForAppointment, global.AlertType.Warning,
        this.appointmentPopupservice.clientBlockOverrideAccess ? global.ButtonType.Ok : global.ButtonType.OKOverride, async (res) => {
          if (this.appointmentPopupservice.clientBlockOverrideAccess) {
            this.appointmentPopupservice.clientBlockInfos.map(x => {
              if (x.clientId === clientId) {
                x.clientBlockOverrideAccess = true;
              }
            }); this.poppedClientId = 0;
          }
          else {
            if (res === global.AlertAction.CONTINUE) {
              this.removeClient(this.poppedClientId);
            }
            else {
              const quickLoginDialogRef = this._quickLoginUtil.QuickLogin({ breakPointNumber: global.SPAScheduleBreakPoint.overrideClientBlock });
              quickLoginDialogRef.beforeClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult) => {
                console.log("quickLoginDialogResult");
              });
              quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

                if (quickLoginDialogResult.isLoggedIn) {
                  this.appointmentPopupservice.clientBlockInfos.map(x => {
                    if (x.clientId === clientId) {
                      x.clientBlockOverrideAccess = true;
                    }
                  });
                  this.poppedClientId = 0;
                }

                else {
                  this.removeClient(this.poppedClientId);
                }
              });
            }

          }
        });
    }


  }

  getDate(dateField: any) {
    if (dateField) {
      return this.localization.LocalizeDate(dateField);
    }
    return "-";
  }

  getCurrency(value: any) {
    if (value) {
      return this.localization.localizeCurrency(value);
    }
    return "-";
  }
  hotelReservationTypeChange(id) {
    switch (id) {
      case 1: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByConfirmation;
        break;
      }
      case 2: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByRoomNumber;
        break;
      }
      case 0: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByGuestName;
        break;
      }
      case 3 && !this.appointmentPopupservice.isExternalSearch && !this.appointmentPopupservice.isInternalPMSIntegrated: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByLinkCode;
        break;
      }
      case 4 && !this.appointmentPopupservice.isExternalSearch: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByContactDetail;
        break;
      }
      default:
        this.hotelReservChipPlaceholder = this.localization.captions.searchByConfirmation;
        break;
    }
    this.clearSearchText = !this.clearSearchText;
  }


  async IsExternalChanged(e: boolean) {
    this.appointmentPopupservice.isExternalSearch = e[0];
    this.getClientSearchTypes();
    if (this.appointmentPopupservice.isExternalSearch) {
      this.isPlatformGuestSearch = false;
      this.clientSearchForm.controls.isPlatformGuestSearch.setValue(false);
      this.clientSearchForm.controls.isPlatformGuestSearch.updateValueAndValidity();
      this.clientSearchTypeChange(this.defaultClientSearchValue);
    }
    this.setSearchText(this.selectedClientSearchType);
  }

  getClientSearchTypes(): any {
    this.appointmentPopupservice.getDefaultHotelReservationTypes(this.pmsSystemValue);
    this.getDefaultClientSearchFilter();
  }

  getDefaultClientSearchFilter() {
    this.clientSearchFilter = this.appointmentPopupservice.fetchGuestSearchJson();
    let clientSearchDetail = this.clientSearchFilter?.find(p => p.integratedPms == this.pmsSystemValue);
    if (this.appointmentPopupservice.isExternalSearch && this.pmsSystemValue && clientSearchDetail) {
      this.clientSearchTypes = this.appointmentPopupservice.MapSearchData(clientSearchDetail, ClientType.Guest);
    }
    else {
      this.clientSearchTypes = [{ id: 0, name: this.localization.captions.firstName, checked: false },
      { id: 1, name: this.localization.captions.lastName, checked: false },
      { id: 2, name: this.localization.captions.name, checked: false },
      { id: 3, name: this.localization.captions.phone, checked: false },
      { id: 4, name: this.localization.captions.email, checked: false }];
    }
    switch (this.defaultClientSearchValue) {
      case 0:
        this.selectedClientSearchType = clientSearchType.firstName;
        break;
      case 1:
        this.selectedClientSearchType = clientSearchType.lastName;
        break;
      case 2:
        this.selectedClientSearchType = clientSearchType.name;
        break;
      case 3:
        this.selectedClientSearchType = clientSearchType.phone;
        break;
      case 4:
        this.selectedClientSearchType = clientSearchType.email;
        break;
    }

  }
}


