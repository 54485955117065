import { NavigationPrimaryMenu, SecondaryMenu } from 'src/app/eatecui/source/core/models/navigation-model';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { UiControlService } from 'src/app/eatecui/source/shared/services/ui-controls.service';
import { NavigationAuthorizedConfig, UiConfiguration } from './ui-control-config.interface';
import { AuthorizationSubModule, AuthorizedDataModel, AuthorizedGroupModel, 
ChangeOdataKeyUrl, PermissionResource, UiControlConfiguration, UiControls } from './ui-controls.interface';
// import * as AuthSample from '../../../../assets/json/ui-control/authoriztion-sample.json';
import { AuthorizationModule } from './authorization.enum';
import * as constants from '../../constant/GlobalConstants';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { IntegrationCollection } from 'src/app/eatecui/source/shared/integration/integration-interface';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';

export const NavigationAuthorization: NavigationAuthorizedConfig = (controlConfiguration: Array<UiControlConfiguration>,
  EatecUiControls: Array<UiControls>, uiControlService: UiControlService, NavigationData: NavigationPrimaryMenu[],
  AuthorizedData: Array<AuthorizedDataModel>,
  RouterNavigateService?: RouteNavigateService, intergrationService ?: IntegrationService, configService?: ConfigService): NavigationPrimaryMenu[] => {
  try {
    const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
    const MenuNavigation: NavigationPrimaryMenu[] = NavigationData;
    const authSample = null;
    const AuthorizedDataResult: Array<AuthorizedDataModel> =
      (AuthorizedData && AuthorizedData !== null) ? AuthorizedData : ( AuthorizationData && AuthorizationData !== null ) ? 
      AuthorizationData : authSample;
    if (NavigationData && AuthorizedDataResult && AuthorizedDataResult.length > 0) {
      MenuNavigation.forEach((NavigationMenu: NavigationPrimaryMenu) => {
        const NavigationCategroyName = NavigationMenu.AuthorizationKey;
        // eslint-disable-next-line max-len
        let AuthCategroy: Array<AuthorizedDataModel> = AuthorizedDataResult.filter((AuthDataModel: AuthorizedDataModel) => AuthDataModel.Name === NavigationCategroyName );
        if (AuthCategroy && AuthCategroy.length > 0) {
          if (NavigationMenu && NavigationMenu.hasOwnProperty('SecondaryMenu') && NavigationMenu.SecondaryMenu.length > 0) {
            NavigationMenu.SecondaryMenu.forEach((secodarymenu: SecondaryMenu) => {
              let enableMenu = NavMenuManageEngine(secodarymenu.AuthorizationKey, AuthCategroy, NavigationMenu, intergrationService);
              secodarymenu.IsEnabled = enableMenu;
              secodarymenu.IsVisible = enableMenu;
              secodarymenu.Authorization = enableMenu;
              if ( secodarymenu.Name === 'Transfers' ) {
                const getChangeUrl: ChangeOdataKeyUrl = changeOdataKeyAuth(AuthorizedDataResult);
                if ( getChangeUrl && getChangeUrl !== null ) {
                  secodarymenu.OdataKey = getChangeUrl.Odatakey;
                  secodarymenu.RoutingURL = getChangeUrl.RoutingUrl;
                }
              } else if (secodarymenu.Name === 'Item Maintenance' && enableMenu) {
                const MenuEnableKey = (secodarymenu.hasOwnProperty('AuthSecondaryKey') ) ?  
                secodarymenu.AuthSecondaryKey : secodarymenu.AuthorizationKey;
                // eslint-disable-next-line max-len
                AuthCategroy = AuthorizedDataResult.filter((AuthDataModel: AuthorizedDataModel) => AuthDataModel.Name === AuthorizationModule.MASTERMANAGEMENT );
                enableMenu = NavMenuManageEngine(MenuEnableKey, AuthCategroy, NavigationMenu, intergrationService);
                secodarymenu.IsEnabled = enableMenu;
                secodarymenu.IsVisible = enableMenu;
                secodarymenu.Authorization = enableMenu;
              } else if (secodarymenu.Name === 'Production Plan') {
                const MenuEnableKey = (secodarymenu.hasOwnProperty('AuthSecondaryKey') ) ?  
                secodarymenu.AuthSecondaryKey : secodarymenu.AuthorizationKey;
                // eslint-disable-next-line max-len
                AuthCategroy = AuthorizedDataResult.filter((AuthDataModel: AuthorizedDataModel) => AuthDataModel.Name === AuthorizationModule.TRANSACTION );
                enableMenu = NavMenuManageEngine(MenuEnableKey, AuthCategroy, NavigationMenu, intergrationService);
                secodarymenu.IsEnabled = enableMenu;
                secodarymenu.IsVisible = enableMenu;
                secodarymenu.Authorization = enableMenu;
              } else if (secodarymenu.Name === 'DataImport') {
                secodarymenu.IsEnabled = false;
                secodarymenu.IsVisible = false;
                secodarymenu.Authorization = false;
                /**
                 * Menu should be enabled (In Setup -> Roles -> Any one permission 
                 * is provided => menu will be enabled)
                 */
                const GetAuthorizationModuleName = AuthorizationModule.UTILITYMANAGEMENT;
                const authDataModel: Array<AuthorizedDataModel> = AuthorizationData.
                  filter((x: AuthorizedDataModel) => x.Name === GetAuthorizationModuleName);
                const dataImportIndex = authDataModel[0].SubModules.findIndex(x => x.Name === 'DataImport');
                const PermissionResourceArray = authDataModel[0].SubModules[dataImportIndex].PermissionResource;
                const filterPermissionResource = PermissionResourceArray.some(x => x.IsActive === true);
                const enableTenantBasedDataImport = configService.dataImportConfig ? 
                Object.values(configService.dataImportConfig).some(x => x === 'True') : false;
                // const enableDataImport = sessionStorage.getItem('enableDataImport') === 'false' ? false : true;
                // const isAgysUser = StorageService.GetSessionStorage('isAgilysysEmployee');
                // if (isAgysUser.toLowerCase() === 'false') {
                //   secodarymenu.IsEnabled = false;
                //   secodarymenu.IsVisible = false;
                //   secodarymenu.Authorization = false;
                // } else {
                  if (enableTenantBasedDataImport) {
                    if (filterPermissionResource) {
                      secodarymenu.IsEnabled = true;
                      secodarymenu.IsVisible = true;
                      secodarymenu.Authorization = true;
                    }
                  }
                // }
              }
            });
            NavigationMenu.IsVisible = NavigationMenu.SecondaryMenu.some(x => x.IsVisible === true);
            NavigationMenu.IsEnabled = NavigationMenu.SecondaryMenu.some(x => x.IsEnabled === true);
            NavigationMenu.Authorization = NavigationMenu.SecondaryMenu.some(x => x.Authorization === true);
            const GetSiteName =  sessionStorage.getItem(constants.LoggedInSiteName); 
          } else if ( 
            NavigationCategroyName === AuthorizationModule.REPORTSMANGEMENT || 
            NavigationCategroyName === AuthorizationModule.INTEGERATIONMGNT || 
            NavigationCategroyName === AuthorizationModule.DASHBOARDMGMT ||
            NavigationCategroyName === AuthorizationModule.EVENTMANAGEMENT ||
            (NavigationCategroyName === AuthorizationModule.TRANSACTION && NavigationMenu.Name === 'Approvals' ) ) {
             const MenuEnableKey = (NavigationMenu.hasOwnProperty('AuthSecondaryKey') ) ?  
             NavigationMenu.AuthSecondaryKey : NavigationMenu.AuthorizationKey;
            const enableMenu = NavMenuManageEngine(MenuEnableKey, AuthCategroy, NavigationMenu, intergrationService);
            NavigationMenu.IsEnabled = enableMenu;
            NavigationMenu.IsVisible = enableMenu;
            NavigationMenu.Authorization = enableMenu;
          }
        } else {
          NavigationMenu['IsVisible'] = false;
          NavigationMenu['Authorization'] = false;
        }
      });
    }
    return null;
  } catch (error) {
    console.log('error occured EnableGridFilter', error);
  }
};

function changeOdataKeyAuth( AuthorizedDataResult: Array<AuthorizedDataModel> ): ChangeOdataKeyUrl {
   try {
     let changeOdataKey: ChangeOdataKeyUrl  = null;
    const AuthCategroy: Array<AuthorizedDataModel> = 
    AuthorizedDataResult.filter((AuthDataModel: AuthorizedDataModel) => AuthDataModel.Name === AuthorizationModule.TRANSACTION );
    if ( AuthCategroy && AuthCategroy.length > 0 ) {
      if ( AuthCategroy[0].hasOwnProperty('SubModules') && AuthCategroy[0].SubModules.length > 0 ) {
        const getModules = AuthCategroy[0].SubModules.filter(x => x.Name === 'requisitions' );
        if ( getModules && getModules.length > 0 ) {
           const getPermission = getModules[0].PermissionResource.filter(p => p.Name === 'CreateRequisition' && !p.IsActive );
           if ( getPermission && getPermission.length > 0 ) {
               const changeKey = {} as ChangeOdataKeyUrl;
               changeKey.Odatakey = 'transfer';
               changeKey.RoutingUrl = 'transaction/view-transfer';
               changeOdataKey = changeKey;
           }
        }
      }
    }
    return changeOdataKey;

   } catch ( error ) {
     console.error(error);
   }
}

function NavMenuManageEngine(EnableUniqueKey: any, AuthCategroy: Array<AuthorizedDataModel>, NavigationMenu: NavigationPrimaryMenu, intergrationService ?: IntegrationService): boolean {
  try {
    let enableMenu = false;
    if ( AuthCategroy && AuthCategroy.length > 0 ) {
      AuthCategroy.forEach((authDataModel: AuthorizedDataModel) => {
        if (authDataModel && authDataModel.hasOwnProperty('SubModules') && authDataModel.SubModules.length > 0) {
          authDataModel.SubModules.forEach((subModel: AuthorizationSubModule) => {
                if (subModel && subModel.hasOwnProperty('PermissionResource') &&
                  subModel.PermissionResource.length > 0) {
                  subModel.PermissionResource.forEach((p: PermissionResource) => {
                    const permissionName = p.Name.toString().toLowerCase();
                    if ( typeof EnableUniqueKey === 'string' && !Array.isArray(EnableUniqueKey) ) {
                      const combineSubMenuName = `${EnableUniqueKey}`.toString().toLowerCase();
                      if (permissionName === combineSubMenuName) {
                        enableMenu = tenantPermission(p.IsActive, NavigationMenu, intergrationService);
                      }
                    } else if ( Array.isArray(EnableUniqueKey) ) {
                      enableMenu = multipleAuthNvigationVerify(authDataModel.SubModules, EnableUniqueKey);
                    }
                   
                  });
                }
          });
        }
      });
    }
    return enableMenu;
  } catch (error) {
    console.error(error);
  }
}


function multipleAuthNvigationVerify(AuthSubModel: Array<AuthorizationSubModule>, AuthorizationArrayKey: Array<string> ): boolean {
  try {
   let enableMenu = false;
    AuthSubModel.forEach(( subModel: AuthorizationSubModule) => {
      if ( subModel && subModel.hasOwnProperty('PermissionResource') && subModel.PermissionResource.length > 0 ) {
        subModel.PermissionResource.forEach(( permissionRes: PermissionResource) => {
          const PIndex = AuthorizationArrayKey.findIndex(x => x.toString().toLowerCase() === permissionRes.Name.toString().toLowerCase());
          if ( PIndex > -1 && permissionRes.IsActive) {
             enableMenu = permissionRes.IsActive;
          }
        });   
      }
    });
   return enableMenu;
  } catch ( error ) {
    console.error(error);
  }
}

/**
 * @method tenantPermission
 * @param NavigationMenu 
 * @description Enabled Tenant Permission to the purticular Module 
 * @returns 
 */

function tenantPermission(defaultPermission: boolean, NavigationMenu: NavigationPrimaryMenu, intergrationService ?: IntegrationService): boolean {
  try{
    let enablePermission: boolean =  defaultPermission;
    const docApprovals = StorageService.GetSessionStorage(constants.DocApprovals);
    if( NavigationMenu.TenantPermissionKey ){
      if( intergrationService && intergrationService.IntegrationConfig ){
        const docApprovalCollection: Array<IntegrationCollection>  = intergrationService.IntegrationConfig.IntegrationCollection.filter(x=> x.IntegratonName === NavigationMenu.TenantPermissionKey);
        if ( docApprovalCollection && docApprovalCollection.length > 0 ){
          enablePermission = docApprovalCollection[0].IsEnabled;    
        }
      } else if( docApprovals ){
        enablePermission = ( docApprovals.toLowerCase() === 'true') ? true : false;
      }
      
     
    } 
    return enablePermission;     
  } catch(error){
    console.error(error)
  }
}


