import { Formatter } from 'src/app/eatecui/source/shared/models/formater.interface';
import moment from 'moment';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';

export const DateFormater: Formatter = (gridData: any): any => {
    try {
        const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
        const DateTimeFormat = ( sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null ) ? 
        sessionDateFormat :  constants.DefaultIniDateFormat;
        if (gridData.value) {
            const AlreadyFormatted = moment(gridData.value, DateTimeFormat).format(DateTimeFormat) === gridData.value;
            if (!AlreadyFormatted) {
                // const returnValue = moment.utc(gridData.value).local().format(DateTimeFormat);
                const returnValue = moment(gridData.value).format(DateTimeFormat);
                gridData.data[gridData.colDef.field] = returnValue;
                return returnValue;
            } else {
                return gridData.value;
            }
        } else {
            return '';
        }
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  };
