import { Directive, ElementRef, HostListener, AfterViewInit, Output, EventEmitter} from '@angular/core';
import { CommonService } from '../service/common-service.service';
@Directive({
  selector: '[viewMore]'
})
export class ViewmoreDirective implements AfterViewInit  {
    public intialBool:boolean = true;
    @Output() NoOfElements: EventEmitter<any> = new EventEmitter();
    constructor(public el: ElementRef, public _cs: CommonService) {
      this._cs.arraysliceValue = 0;
    }
    ngAfterViewInit() {
        this.calculateNoOfElements();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateNoOfElements()
  }

  private calculateNoOfElements(){
    let elementWidth = this.getWidth(this.el.nativeElement, false);
    let labelwidth = 200;
    let parentelementWidth =  this.getWidth(this.el.nativeElement.parentElement, true) - labelwidth;
    let calWidth = Math.round(parentelementWidth) / Math.round(elementWidth);
    let ResultView = isNaN(Math.floor(calWidth))?7:Math.floor(calWidth);
    this._cs.arraysliceValue = ResultView;
    this.NoOfElements.emit(ResultView);
  }

  private getWidth(element, isParent){
    let style = getComputedStyle(element);
    let margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    let padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    let width = element.offsetWidth + margin;
    if(isParent){
      width -= padding;
    }
    return width;
  }
}
