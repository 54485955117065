import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { LookupConfiguration } from '../transaction.interface';
import { CustomMasterFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const AutoCompleteSearchUSDA: CustomMasterFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, 
    CustomData?: any
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod && FieldGrouping.hasOwnProperty('autoCompleteHeader') && typeof CustomData === 'string'
        && CustomData.length >= 0 ) {
            const Filter = `contains(tolower(Description), '${CustomData}')`;
            const GetDataFilter = encodeURIComponent(`${Filter}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            const EndPointUrl =  `/inventory/api/${FieldGrouping.CustomFormAction.Url}/'${CustomData}'?transType=4`;
            httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                const responseData = res['value'];
                const NutrientsFields = MasterDataCollection.NestedFormConfig.EditFormConfig.filter(x => x.OdataKey === 'IngredientsNutritions' || x.OdataKey === 'ProductNutritions' || x.OdataKey === 'RecipeNutritions')[0].
                    NestedForm.filter(form => form.FormName === 'USDA Nutrition Link')[0].FormFields;
                const autoCompleteData = NutrientsFields.filter((x) => x.type === 'autocomplete');
                if (autoCompleteData && autoCompleteData.length > 0) {
                    const autoFormFields = autoCompleteData[0];
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [];
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [...responseData];
                }
            }, err => console.log('HTTP Error', err));
        }
        if (CustomData && CustomData.length  === 0) {
            const NutrientsFields = MasterDataCollection.NestedFormConfig.EditFormConfig
                            .filter(x => x.OdataKey === 'IngredientsNutritions' || x.OdataKey === 'ProductNutritions' || x.OdataKey === 'RecipeNutritions')[0].
                    NestedForm.filter(form => form.FormName === 'USDA Nutrition Link')[0].FormFields;
                const autoCompleteData = NutrientsFields.filter((x) => x.type === 'autocomplete');
                if (autoCompleteData && autoCompleteData.length > 0) {
                    const autoFormFields = autoCompleteData[0];
                    autoFormFields['fieldType']['SingleData']['autoCompleteData'] = [...[]];
                }
        }
        if (FieldGrouping.CustomFormAction.EnableCustomMethod &&
            FieldGrouping.hasOwnProperty('autoCompleteHeader') &&
            FieldGrouping.autoCompleteHeader.length > 0 && CustomData && typeof CustomData !== 'string') {
                for (const key in FormGroupData.controls) {
                    // Added Id condition for not updating the existing id of ingredient nutrition / product nutrition
                    if (key !== 'Id' && FormGroupData.controls.hasOwnProperty(key)) {
                        const getValue = (key === 'USDAItem') ? CustomData.USDAItem : ( key === 'ProductId') ? '0'
                         : CustomData[key];
                        FormGroupData.controls[key].setValue(getValue);
                        CommonService.triggerBlurEvent(key);
                        const USDALinkFeild = MasterDataCollection.NestedFormConfig.EditFormConfig
                        .filter(x => x.OdataKey === 'IngredientsNutritions' || x.OdataKey === 'ProductNutritions' || x.OdataKey === 'RecipeNutritions')[0].
                        NestedForm.find(e => e.FormHeaderName === 'USDA Nutrition Link');
                        if (USDALinkFeild) {
                          USDALinkFeild.FormFields[0].fieldType.SingleData.disbaledProperty = true;
                        }
                    }
                }
                // FormGroupData.controls['Ndb_no'].setValue(CustomData.Ndb_no);
                // FormGroupData.controls['USDAItem'].setValue(CustomData.Description);
                // FormGroupData.controls['ServingSize'].setValue(CustomData.ServingSize);
                // FormGroupData.controls['Serving'].setValue(CustomData.Serving);
                // FormGroupData.controls['Allergy'].setValue(CustomData.Allergens);
                // FormGroupData.controls['Ingredients'].setValue(CustomData.Ingredients);
                // FormGroupData.controls['UserIngreds'].setValue(CustomData.UserIngreds);
                // FormGroupData.controls['Refuse'].setValue(CustomData.Refuse);
                if (CustomData.Conversion && FormGroupData.controls['Conversion']) {
                    FormGroupData.controls['Conversion'].setValue(CustomData.Conversion);
                    CommonService.triggerBlurEvent('Conversion');
                }
              
                const NutrientsFields = MasterDataCollection.NestedFormConfig.EditFormConfig.filter(x => x.OdataKey === 'IngredientsNutritions' || x.OdataKey === 'ProductNutritions' || x.OdataKey === 'RecipeNutritions')[0];
                if (NutrientsFields.caloriesCalculation.hasOwnProperty('CaloriesCallBack')) {
                    NutrientsFields.caloriesCalculation.CaloriesCallBack(CustomData.InventoryNutritionDetails);
                }
               
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};