import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ProcessTransactionModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-process-transaction',
  templateUrl: './process-transaction.component.html',
  styleUrls: ['./process-transaction.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessTransactionComponent {
  agilysysCofirmationButton: AgilysysButtonModel[];
  constructor(public dialogRef: MatDialogRef<ProcessTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProcessTransactionModel) {
      
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close('cancel');
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close('confirm');
        }
      }
    ];
     }
}
