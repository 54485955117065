import { FormGroup } from '@angular/forms';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';

export namespace Validation  {

    export interface DataValidator {
        isAcceptable(s: any): boolean;
    } 
    export class FormDataValidator {
        isAcceptable(formData: FormGroup): boolean {
            return formData.valid;
        }
    }

    export class DataLengthValidator {
        isAcceptable(rowData: Array<any>, transactionCollection: TransactionConfiguration): boolean {
            return ((transactionCollection.Name === 'cycleplanning' || transactionCollection.Name === 'PiPrint') ? true : (rowData && rowData.length > 0 ? true : false));
        }
    }

    export class SupplierCheckValidator {
        isAcceptable(rowData: Array<any>, transactionCollection: TransactionConfiguration): boolean {
            const KeyCheck = transactionCollection.CreateTranscationConfig.ItemsConfig.AdditionalkeyToEnablePost;
            const supplierMapped = KeyCheck === 'SupplierId' ? (rowData.every(x => x.SupplierId > 0)) : true;
            return supplierMapped;
        }
    }

    export class QtyCheckForPIValidator {
        isAcceptable(rowData: Array<any>, transactionCollection: TransactionConfiguration): boolean {
            const Name = transactionCollection.Name;
            const key = 'Qty';
            const NoQty = Name === 'Physical Inventory' ? (rowData.some(e => e[key] !== '' && e[key] !== undefined && e[key] !== null &&
            e[key].toString().toLowerCase() !== 'n_c' && e[key].toString().toLowerCase() !== 'l_p')) : true;
            return NoQty;
        }
    }

    export class MealLengthValidator {
        isAcceptable(transactionCollection: TransactionConfiguration): boolean {
            const enablePost = transactionCollection.CreateTranscationConfig?.ItemsConfig?.CyclePlanItemConfig?.
            CyclePlanDetailMealCovers?.length > 0 ? true : false;
            return transactionCollection.Name !== 'cycleplanning' ? true :  enablePost;
        }  
    }

    export class NullDataValidator {
        isCheck(value: any): boolean {
            /** 
             * For Bug Fix - 203677, 203676
             */
            if (value && (value !== 'null' && value !== 'undefined' && (parseFloat(value) !== 0))) {
                return true;
            } else {
                 return false;
            }
        }
    }

    export class UnitCheckValidator {
        isAcceptable(rowData: Array<any>, transactionCollection: TransactionConfiguration): any {
            const NoUnitCheck = [
                'sales',
                'salesforecasting',
                'cycleplanning',
                'Packages',
                'Production',
                'Butchery',
                'quickorder',
                'PackageLimitation'
            ];
            const Name = transactionCollection.Name;
            const key = 'UnitTypeId';
            const key1 = 'UnitId';
            let NoUnit = true;
            if (NoUnitCheck.indexOf(Name) === -1) {                
                rowData.forEach((x: any) => {                
                    NoUnit =  (x.hasOwnProperty('Units') && x['Units'].length > 0)  ?
                    true : false;
                });
                NoUnit = (rowData.every(e => e[key] !== '' && e[key] !== undefined  && e[key] !== null && e[key] !== 0 &&
                e[key1] !== '' && e[key1] !== undefined && e[key1] !== null && e[key1] !== 0));
            }            
            return NoUnit;
        }
    }

    export class GetInActiveDataCount {
        isAcceptable(rowData: Array<any>): any {
            let count = 0;
            rowData.forEach((x: any) => { 
                if (x.hasOwnProperty('UnitTypeId') && x.hasOwnProperty('UnitId')) {
                    if ((x['UnitTypeId'] === null || x['UnitTypeId'] === undefined || 
                    x['UnitTypeId'] === 0 || x['UnitTypeId'] === '') && 
                    (x['UnitId'] === null || x['UnitId'] === undefined || 
                    x['UnitId'] === 0 || x['UnitId'] === '')) {
                        count += 1;
                    }
                }
            });
            return count;
        }
    }
}