
import { MatDialog } from '@angular/material/dialog';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { TranslateService } from '@ngx-translate/core';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { GridModels } from 'src/app/eatecui/source/shared/models/grid-models';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridColumnReorder, GridFilter, GridInfiniteScrollConfig, GridPaginationConfig, GridResizeRows, GridSearchConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { CreateTransaction } from '.';
import { GridActionsConfig, GridCustomColExpand, LookUpConfiguration, TransactionConfiguration } from '../transaction.interface';
import { LookUpGridCOnfig } from './create-transaction-config.interface';

export const GridLookUp: LookUpGridCOnfig = (
    TransactionCollection: TransactionConfiguration, ItemLookUPConfig: LookUpConfiguration[], value: any,
    dialog: MatDialog, OdataViewEntity: OdataGlobalEntity, httpService: HttpService,
    translateService: TranslateService): any => {
    return new Promise(async function (resolve, reject) {
        if (ItemLookUPConfig.length > 0) {
            ItemLookUPConfig.forEach((element, index) => {
                const viewModelEntity = GetViewModelData(OdataViewEntity.OdataViewModelEntity, element.OdataKey);
                const navigationProperity = NavigationProperty(viewModelEntity, element);
                const seleId = element.OdataKey === 'IngredientsBids' ? `IngredientId eq ${value.data.IngredientId}` : '';
                let GetDataFilter = '';
                if (seleId) {
                    GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
                        return '%' + c.charCodeAt(0).toString(16);
                    });
                }
                const params: QueryParams = {
                    count: true,
                    skip: 0,
                    expand: '',
                    select: '',
                    filter: '',
                    orderby: '',
                    isactive: true,
                    search: '',
                    filterQuery: GetDataFilter,
                    searchtext: ''
                };
                params.expand = navigationProperity.join();
                if (element.OdataKey === 'IngredientsBids') {
                    params.expand = params.expand.replace('IngredientBidsUnits($select=id,name)',
                         'IngredientBidsUnits($select=id,name,code)');
                }
                const headerDefs = element.GridConfiguration.GridHeaderDefination.map(values => {
                    const text = element.OdataKey + '.' + values.field;
                    translateService.get(text).subscribe(res => {
                        values.headerName = res;
                    });
                    if (values.type === 'dateColumn') {
                        values.valueFormatter = Formaters.dateformater;
                    }
                    return values;
                });
                element.ViewModelProperty = viewModelEntity;
                element.NavigationProperty = navigationProperity;
                element.GridConfiguration.GridData = [];
                element.GridConfiguration.GridHeaderDefination = headerDefs;
                element.GridConfiguration.GridActions = {} as GridActionsConfig;
                element.GridConfiguration.GridActions.GridTitle = element.GridConfiguration.GridName;
                element.GridConfiguration.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
                element.GridConfiguration.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
                element.GridConfiguration.GridActions.ResizeRowsConfig = {} as GridResizeRows;
                element.GridConfiguration.GridActions.ResizeRowsConfig.EnableResizeRows = false;
                element.GridConfiguration.GridActions.SearchConfig = {} as GridSearchConfig;
                element.GridConfiguration.GridActions.SearchConfig.EnabledSearch =
                    element.GridConfiguration.GridSearch.length > 0 ? true : false;
                element.GridConfiguration.GridActions.SearchConfig.SearchPlaceholder =
                    GridModels.getSearchPlaceHolder(element.GridConfiguration.GridSearch);
                element.GridConfiguration.GridActions.FilterConfig = {} as GridFilter;
                element.GridConfiguration.GridActions.FilterConfig.EnableFilter =
                    element.GridConfiguration.GridFilter.length > 0 ? true : false;
                element.GridConfiguration.GridActions.FilterConfig.FilterOptions =
                    CreateTransaction.FilterOptionsLookUp(element, viewModelEntity, httpService);
                element.GridConfiguration.GridActions.PaginationConfig = {} as GridPaginationConfig;
                element.GridConfiguration.GridActions.PaginationConfig.EnabledPaginationation = false;
                element.GridConfiguration.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
                element.GridConfiguration.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
                const getQueryParams = formQueryParams(params);
                const GetDataByIdUrl = element.OdataKey === 'IngredientsBids' ? `/inventory/api/${element.OdataKey + getQueryParams}`
                : `/inventory/api/v2/${element.OdataKey.toLowerCase()}/0`;
                if (index === 0) {
                    httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
                        console.log('Response', x);
                        // element.GridConfiguration.GridData = [];
                        if (element.OdataKey === 'IngredientsBids') {
                            element.GridConfiguration.GridData = [...assignRowData(x['value'], viewModelEntity[0])];
                        } else {
                            element.GridConfiguration.GridData = [...x];
                        }
                        element.GridConfiguration = { ...element.GridConfiguration };
                        element.GridConfiguration.GridActions.TotalItems = element.GridConfiguration.GridData.length;

                    });
                }
                if (index === ItemLookUPConfig.length - 1) {
                    resolve(ItemLookUPConfig);
                }
            });
        }
    });

};

function NavigationProperty(ViewModelEntity: any, ItemLookUPConfig: LookUpConfiguration) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                    const navigationName = element['$'].Name;
                    const referentialRelationId = element['ReferentialConstraint'] && element['ReferentialConstraint'][0]['$'].Property
                        ? element['ReferentialConstraint'][0]['$'].Property : '';
                    if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                        const properityIndex = ViewModelEntity[0]['Property']
                            .findIndex(x => x['$'].Name === referentialRelationId);
                        if (properityIndex > -1) {
                            let expandQuery = '($select=id,name)';
                            if (ItemLookUPConfig &&
                                ItemLookUPConfig.GridConfiguration.hasOwnProperty('GridCustomExpand') &&
                                ItemLookUPConfig.GridConfiguration.GridCustomExpand.length > 0) {
                                ItemLookUPConfig.GridConfiguration.GridCustomExpand.
                                    forEach((GridCustomExpandKey: GridCustomColExpand) => {
                                        if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand && GridCustomExpandKey.IsCustomExpand
                                            && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                            const expandCode = GridCustomExpandKey.SelectQuery;
                                            if (expandCode !== null) {
                                                expandQuery = `($select=id,${expandCode})`;
                                            } else {
                                                expandQuery = '';
                                            }
                                        }
                                    });
                            }
                            const properityString = `${navigationName}` + `${expandQuery}`;
                            navigationPrperity.push(properityString);
                        } else {
                            if (navigationName !== 'TransactionPictures') {
                                const properityString = `${navigationName}`;
                                navigationPrperity.push(properityString);
                            }
                        }
                    }
                });
                return navigationPrperity;
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}

function GetViewModelData(ViewModelEntity: Array<any>, EntityKey: string) {
    try {
        const viewModelEntity = ViewModelEntity.filter(x => {
            for (const prop in x) {
                if (prop) {
                    if (prop === '$') {
                        const entityName = x[prop].Name;
                        if (entityName === EntityKey) {
                            return true;
                        }
                    }
                }
            }
        });
        return viewModelEntity;
    } catch (error) {
        console.error(error);
    }
}

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function assignRowData(ResponseData, ViewModelEntity) {
    try {
        if (ResponseData) {
            ResponseData.forEach(dataobject => {
                if (ViewModelEntity['$']['Name'] === 'IngredientsBids') {
                    if (dataobject.Suppliers && dataobject.Suppliers.hasOwnProperty('SendVia')) {
                        dataobject.SendVia = dataobject.Suppliers.SendVia;
                    }
                    if (dataobject.IngredientBidsUnits && dataobject.IngredientBidsUnits.hasOwnProperty('Code')) {
                        dataobject.UnitCode = dataobject.IngredientBidsUnits.Code;
                    }
                }
            });

            ResponseData.forEach(dataobject => {
                for (const prop in dataobject) {
                    if (prop) {
                        if (dataobject[prop] && dataobject[prop] !== null) {
                            const propType = typeof dataobject[prop];
                            if (propType === 'object') {
                                // const objProperity = dataobject[prop];
                                assignGridNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                            }
                        }
                    }
                }
            });
            return ResponseData;
        } else {
            return [];
        }
    } catch (error) {
        console.log('error occured assignRowData', error);
    }
}

function assignGridNavigationProperity(ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl.hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl['NavigationProperty'].length > 0) {
        ViewModelEntityColl['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'].toLowerCase().toString();
            if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
                entityObject[ProperityName] = ProperirtyObject['Name'];
            }
        });
    }
    return entityObject;
}