import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer} from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-utilization',
  templateUrl: './utilization.component.html',
  styleUrls: ['./utilization.component.scss']
})
export class UtilizationComponent implements OnInit {
  utilizationFrmGrp: UntypedFormGroup;
  captions: any;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();
  radioFilter = [];
  constructor(private fb: UntypedFormBuilder, private controlContainer: ControlContainer, public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
    this.radioFilter = [
      { id: 0, name: this.captions.Both },
      { id: 1, name: this.captions.LocationOnly },
      { id: 2, name: this.captions.StaffOnly }
    ];
  }

  ngOnInit() {
    this.utilizationFrmGrp = this.fb.group({
      radioFilter: 0,
      includeRetailRevenue: false,
      includeInactivestaff: false,      
      scheduleOnly: true
    });

    this.formReady.emit(this.utilizationFrmGrp);
  }

}