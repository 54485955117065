import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { ReqBackOrder } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { CreateTransaction } from '.';
import { PostPOSupplierConfig, TransactionConfiguration, TransactionTableData } from '../transaction.interface';
import { PostPo } from './create-transaction-config.interface';

export const TransferPostConfiguration = (
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    transactionService: TransactionService,
    postModelHederEntity: any,
    postModelDetailEntity: any,
    homeGroup: FormGroup,
    GlobalOdataModel: any,
    toastrService: ToastrService,
    routerNavigateService: RouteNavigateService,
    selectedTransaction: any,
    dialog: MatDialog,
    selectedId?: any,
    SystemPreferenceService?: SysytemPreferenceService,
    router?: ActivatedRoute, configService?: ConfigService, integrationService?: IntegrationService): any  => {
        try {
            if (SystemPreferenceService && SystemPreferenceService.globalSystemPreference.length > 0) {
                const backorderpreference = TransactionCollection.SystemPreference.
                    filter(x => x.PreferenceType === 'BackOrderRequisitions')[0];
                const globalPreference = SystemPreferenceService.globalSystemPreference.
                    filter(x => x.LookupName === backorderpreference.PreferenceType)[0];

                const MessageName = TransactionCollection.Name.toLowerCase();
                const PostModelEndPoint = TransactionCollection.PostEntityName;
                const requestMethod = CreateTransaction.HttpServiceMethod(
                null,  JSON.parse(decodeURIComponent(router.snapshot.queryParams.data)));
                const requetEntity = (requestMethod === 'PuthttpMethod') ?
                    `${PostModelEndPoint}/` + `${selectedId}` : PostModelEndPoint;
                const EndPointUrl = `/inventory/api/${requetEntity}`;
                const postModelData = transactionService.constractTransactionHeaderPostModel(
                    postModelHederEntity, postModelDetailEntity, TransactionCollection, homeGroup, requestMethod, GlobalOdataModel);
                httpService[requestMethod](EndPointUrl, postModelData).subscribe(x => {
                    if (TransactionCollection.AttachedFilesConfig) {
                        const fileAttachedData: Array<UploadMetaData> = 
                          TransactionCollection.AttachedFilesConfig.AttachMentConfig.FileAttachedData;
                    if (fileAttachedData && fileAttachedData.length > 0) {
                        fileAttachedData.forEach(req => {
                            req.IsTransaction = true;
                            if (requestMethod === 'PuthttpMethod') {
                                req.ItemId = selectedId;
                            } else {
                                req.ItemId = x && x.Data && x.Data.resultEntity &&  x.Data.resultEntity.hasOwnProperty('Id')
                                    ? x.Data.resultEntity.Id : 0;
                            }
                        });
                        const dmIntegCollect = integrationService.IntegrationConfig.IntegrationCollection.filter(y => y.IntegratonName === 'DataMagine')[0];
                        const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
                        'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
                        const IntegrationIndex: number = AllowedIntegration.findIndex(y => y === selectedTransaction);
                        const authorizeData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
                        const setupSubModules = authorizeData.filter(x => x.Name === 'setupmanagement')[0].SubModules;
                        const setupPermissionResources = setupSubModules.filter(m => m.Name === 'setup')[0].PermissionResource;
                        const setupDMMappingsEnabled = setupPermissionResources.filter(r => r.Name === 'DMMappings')[0].IsActive;
                        if (configService.enableIntegration && IntegrationIndex > -1 && dmIntegCollect.IsEnabled && setupDMMappingsEnabled) {
                            const FromGridData: Array<TransactionTableData> = TransactionCollection.CreateTranscationConfig.DetailsConfig
                            .DetailTableCard.DetailsTableCardBuilder.GridTableData;
                            const transferID = {
                                DisplayName: 'Transfer ID#',
                                HeaderName: 'TransfersID',
                                Odatakey: 'TransfersID',
                                Value: x.Id
                            };
                            FromGridData.push(transferID);
                            integrationService.dmIntergerationInstance.GetModuleIndexValue(TransactionCollection.Name, null);
                            integrationService.dmIntergerationInstance.PostDatamagineFileData(fileAttachedData, FromGridData);
                        } else {
                        FileData.FileDataPost(fileAttachedData, httpService, toastrService);
                        }
                    }
                    }
                    const responseId = (x && x.hasOwnProperty('Id')) ? ` #${x.Id}` : ` ${selectedId}`;
                    // const seqNo = selectedTransactionSeq(x);
                    // responseId = (seqNo !== undefined && seqNo != null && parseInt(seqNo, 10) > 0) ? ` #${seqNo}` : ` ${selectedId}`;
                    if (globalPreference.LookupValue === ReqBackOrder.CreateNewReqWithBalance && homeGroup.value['ReqId'] !== '' 
                        && homeGroup.value['IsBackOrder'] === true) {
                        const ErrorPopUpDialog: ErrorPopModel = {
                            PopupHeaderContent: 'Info',
                            PopUpContent: `Transfer filed under ${responseId}`,
                          };
                          const dialogRef =  dialog.open(AgilsysErrorPopComponent, {
                            width: '400px',
                            data: ErrorPopUpDialog,
                            disableClose: true
                          });
                          dialogRef.afterClosed().subscribe(result => {
                            console.log(TransactionCollection);
                            const transActionFrom = { queryParams: { from: 'transferbackorder', transaction : 'requisition' } };
                            const transActionrouterUrl = `/settings/enhancedInventory/transaction/create-requisition`;
                            routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
                          });
                    } else {
                        const toastText = (requestMethod === 'PuthttpMethod') ? ' Updated' : ' Created';
                        const message: ToastMessage = {
                            Message: `${MessageName.charAt(0).toUpperCase() + MessageName.slice(1) + responseId} ${toastText} Successfully`,
                            Show: true,
                            AutoClose: true,
                            TimerCount: 20000
                        };
                        toastrService.success(message.Message, '', {
                            timeOut: 3000,
                            closeButton: true,
                        });
                        const transActionFrom = { queryParams: { transaction: selectedTransaction } };
                        const transActionrouterUrl = `transaction/view-${selectedTransaction.toLowerCase()}`;
                        routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
                    }
                });
            }
        } catch (error) {

        }

        function selectedTransactionSeq(data: any) {
            let selectedTrans = '';
            selectedTrans = data && data.hasOwnProperty('CustomerId') && data.CustomerId != null ?
                data.TransOutSequenceNo : data.TransSequenceNo;
            return selectedTrans;
        }
    };