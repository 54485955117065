import { Injectable } from '@angular/core';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { ReadUploadedFile } from '../shared/utilities/readcsvfile';
import { RetailLocalization } from '../common/localization/retail-localization';
import {
    RetailItemDetail, PackagedItem, OutletItem,
    RetailItemTax, RetailItemBarCode, RetailItem,
    InventoryOutlet, InventoryInfo, RetailItemVendor, ImportItemStatus, RetailItemTemplateConfig, MultiPackDetail
} from '../retail.modals';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { BaseResponse } from '../shared/business/shared.modals';
import { AlertType } from '../shared/shared.modal';
import { ButtonType, Product, SourceSystem, SyncStatus } from '../shared/globalsContant';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { RetailChildSetupCSVValidator } from './retail-child-setup.validator';
import { ImportRetailitemsColumn, ToggleState, RetailErrorCode, RetailItemType } from './retail-child-setup.model';
import * as FileSaver from 'file-saver';
import moment from 'moment';

@Injectable()
export class RetailChildSetupBusiness {

    private _retailItems: Array<any> = [];
    private validator: RetailChildSetupCSVValidator;
    public TeamPlayId: number = 2;

    newCategory: Array<any> = [];
    newsubCategory: Array<any> = [];
    newUnitOfMeasure: Array<any> = [];
    newCategoryLink: Array<any> = [];

    levelValues: any = [{ "value": 1 }, { "value": 2 }, { "value": 3 }, { "value": 4 }, { "value": 5 }];
    captions: any;
    retailCaption: any;
    NotProcessedCount: number = 0;
    ProcessedCount: number = 0;
    FailedCount: number = 0;
    EXPORT_FILE_MIME_TYPE:string='text/csv';
    EXPORT_FILE_NAME:string ='RetailItems';
    EXPORT_FILE_EXTENSION:string ='.csv';


    constructor(private _localization: RetailLocalization
        ,       private PropertyInfo: RetailPropertyInformation
        ,       private _fileReader: ReadUploadedFile
        ,       private _utilities: RetailUtilities
        ,       private _retailservice: RetailSetupService
        ,       public localization: RetailLocalization
        ,       csvValidator: RetailChildSetupCSVValidator
    ) {

        this.captions = this.localization.captions.retailsetup.importFail;
        this.retailCaption = this.localization.captions.retailsetup;
        this.validator = csvValidator;
        this.validator.setValidRetailItemTypesForProduct(this._retailservice.ProductId);
    }

    async readRetailitemsCSV(event: Event, isAuthorized: boolean,uiSelectedOutletId: number): Promise<any> {
        var data = await this._fileReader.ReadCsvFileUpperCase<any>(event);
        return this.BuildRetailItems(data, isAuthorized,uiSelectedOutletId);
    }

    async ValidateImport(): Promise<any> {
        try {
            return await this._retailservice.ValidatePreviousImport();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async ExportRetailItems(body: any): Promise<any> {
        try {
            this._utilities.ToggleLoaderWithMessage(true);
            this._retailservice.ExportRetailItem(this.EXPORT_FILE_MIME_TYPE, body).subscribe((blob: Blob) => {
                FileSaver.saveAs(blob,this.EXPORT_FILE_NAME + '_' + moment().format('MM-DD-YYYY_hh:mm:ss').toString() + this.EXPORT_FILE_EXTENSION);
                this._utilities.ToggleLoaderWithMessage(false); 
            });
        } catch (e) {
            this._utilities.ToggleLoaderWithMessage(false); 
            console.error(e);
            throw e;
        }
    }

    async ImportStatus(): Promise<any> {
        let result = await this._retailservice.GetImportStatus();
        this.NotProcessedCount = this.ProcessedCount = this.FailedCount = 0;
        result.forEach((x) => {
            if (x.status === ImportItemStatus.NotProcessed) {
                this.NotProcessedCount += 1;
            }
            if (x.status === ImportItemStatus.Processed) {
                this.ProcessedCount += 1;
            }
            if (x.status === ImportItemStatus.Failed) {
                this.FailedCount += 1;
            }
        });
        result = result.filter(x => x.status != ImportItemStatus.Processed);
        let importdata: any[] = []
        for (var item of result) {
            let categoryName = '';
            if (item.retailImportItemInfo.retailItem.retailItemDetail.category && item.retailImportItemInfo.retailItem.retailItemDetail.category > 0) {
                categoryName = this._retailservice.categories.find(x => x.id == item.retailImportItemInfo.retailItem.retailItemDetail.category).name;
            }
            let UnitOfMeasureName = '';
            if (item.retailImportItemInfo.retailItem.retailItemDetail.unitOfMeasure && item.retailImportItemInfo.retailItem.retailItemDetail.unitOfMeasure > 0) {
                UnitOfMeasureName = this._retailservice.unitOfMeasure.find(x => x.id == item.retailImportItemInfo.retailItem.retailItemDetail.unitOfMeasure).name;
            }
            let Subcategory1Name = '';
            if (item.retailImportItemInfo.retailItem.retailItemDetail.subCategory1 && item.retailImportItemInfo.retailItem.retailItemDetail.subCategory1 > 0) {
                Subcategory1Name = this._retailservice.subCategoryOne.find(x => x.id == item.retailImportItemInfo.retailItem.retailItemDetail.subCategory1).name;
            }
            let Subcategory2Name = '';
            if (item.retailImportItemInfo.retailItem.retailItemDetail.subCategory2 && item.retailImportItemInfo.retailItem.retailItemDetail.subCategory2 > 0) {
                Subcategory2Name = this._retailservice.subCategoryTwo.find(x => x.id == item.retailImportItemInfo.retailItem.retailItemDetail.subCategory2).name;
            }
            let Subcategory3Name = '';
            if (item.retailImportItemInfo.retailItem.retailItemDetail.subCategory3 && item.retailImportItemInfo.retailItem.retailItemDetail.subCategory3 > 0) {
                Subcategory3Name = this._retailservice.subCategoryThree.find(x => x.id == item.retailImportItemInfo.retailItem.retailItemDetail.subCategory3).name;
            }
            let Subcategory4Name = '';
            if (item.retailImportItemInfo.retailItem.retailItemDetail.subCategory4 && item.retailImportItemInfo.retailItem.retailItemDetail.subCategory4 > 0) {
                Subcategory4Name = this._retailservice.subCategoryFour.find(x => x.id == item.retailImportItemInfo.retailItem.retailItemDetail.subCategory4).name;
            }
            let Subcategory5Name = '';
            if (item.retailImportItemInfo.retailItem.retailItemDetail.subCategory5 && item.retailImportItemInfo.retailItem.retailItemDetail.subCategory5 > 0) {
                Subcategory5Name = this._retailservice.subCategoryFive.find(x => x.id == item.retailImportItemInfo.retailItem.retailItemDetail.subCategory5).name;
            }
            let outletName = '';
            if (item.retailImportItemInfo.retailItem.outletItem.length == 1 && item.retailImportItemInfo.retailItem.outletItem[0].outletId) {
                outletName = this._retailservice.allActiveOutlets.find(x => x.id == item.retailImportItemInfo.retailItem.outletItem[0].outletId).name;
            }
            let tax = ''
            if (item.retailImportItemInfo.retailItem.retailItemTax.length > 0) {
                tax = item.retailImportItemInfo.retailItem.retailItemTax.map(x => x.taxName).join(',');
            }
            let barcode = '';
            if (item.retailImportItemInfo.retailItem.retailItemBarCode.length > 0 && item.retailImportItemInfo.retailItem.retailItemBarCode.filter(x => x.isPrimary).length > 0) {
                barcode = item.retailImportItemInfo.retailItem.retailItemBarCode.filter(x => x.isPrimary)[0].barCode;
            }
            let secondaryBarCode = '';
            if (item.retailImportItemInfo.retailItem.retailItemBarCode.length > 0 && item.retailImportItemInfo.retailItem.retailItemBarCode.filter(x => x.isSecondary).length > 0) {
                secondaryBarCode = item.retailImportItemInfo.retailItem.retailItemBarCode.filter(x => x.isSecondary)[0].barCode;
            }
            let vendorId = 0;
            let supplierItemNumber = '';
            if (item.retailImportItemInfo.retailItem.retailItemVendor.length &&
                this._retailservice.vendors.length &&
                this._retailservice.vendors.some(x => x.id ===
                item.retailImportItemInfo.retailItem.retailItemVendor[0].vendorId && x.isActive)){
              vendorId = item.retailImportItemInfo.retailItem.retailItemVendor[0].vendorId;
              supplierItemNumber =  item.retailImportItemInfo.retailItem.retailItemVendor[0].supplierItemId;
            }
            if (item.retailImportItemInfo.retailItem.retailItemVendor.length &&
                this._retailservice.vendors.length){
              supplierItemNumber =  item.retailImportItemInfo.retailItem.retailItemVendor[0].supplierItemId;
            }

            const row: any = {};
            row[ImportRetailitemsColumn.ItemNumber] = item.retailImportItemInfo.retailItem.retailItemDetail.itemNumber;
            row[ImportRetailitemsColumn.ItemDescription] = item.retailImportItemInfo.retailItem.retailItemDetail.itemDescription;
            row[ImportRetailitemsColumn.Price] = item.retailImportItemInfo.retailItem.retailItemDetail.salesPrice.toString();
            row[ImportRetailitemsColumn.Cost] = item.retailImportItemInfo.retailItem.retailItemDetail.costPrice.toString();
            row[ImportRetailitemsColumn.BarCode] = barcode;
            row[ImportRetailitemsColumn.SecondaryBarcode] = secondaryBarCode;
            row[ImportRetailitemsColumn.UnitOfMeasure] = UnitOfMeasureName;
            row[ImportRetailitemsColumn.Category] = categoryName;
            row[ImportRetailitemsColumn.SubCategory1] = Subcategory1Name;
            row[ImportRetailitemsColumn.SubCategory2] = Subcategory2Name;
            row[ImportRetailitemsColumn.SubCategory3] = Subcategory3Name;
            row[ImportRetailitemsColumn.SubCategory4] = Subcategory4Name;
            row[ImportRetailitemsColumn.SubCategory5] = Subcategory5Name;
            row[ImportRetailitemsColumn.Quantity] = item.retailImportItemInfo.inventoryInfo && item.retailImportItemInfo.inventoryInfo.outlets && item.retailImportItemInfo.inventoryInfo.outlets.length > 0 ? item.retailImportItemInfo.inventoryInfo.outlets[0].quantity.toString() : '0';
            row[ImportRetailitemsColumn.ItemPar] = item.retailImportItemInfo.inventoryInfo && item.retailImportItemInfo.inventoryInfo.outlets && item.retailImportItemInfo.inventoryInfo.outlets.length > 0 ? item.retailImportItemInfo.inventoryInfo.outlets[0].itemPar.toString() : '0';
            row[ImportRetailitemsColumn.OutletCode] = outletName;
            const tempOutletName = outletName == '' ? '0' : '1';
            row[ImportRetailitemsColumn.OutletSwitch] = item.errorCode?.includes(RetailErrorCode.OUTLET_SWITCH_MISSING.toString()) ? '' : tempOutletName;
            row[ImportRetailitemsColumn.Tax] = tax;
            row[ImportRetailitemsColumn.ItemType] = item.retailImportItemInfo.retailItem.retailItemDetail.itemType.toString();
            row[ImportRetailitemsColumn.CommissionableItem] = item.retailImportItemInfo.retailItem.retailItemDetail.isCommissionable ? '1' : '0';
            row[ImportRetailitemsColumn.CommissionRequired] = item.retailImportItemInfo.retailItem.retailItemDetail.isCommissionRequired ? '1' : '0';
            row[ImportRetailitemsColumn.AllowEarn] = item.retailImportItemInfo.retailItem.retailItemDetail.allowEarn ? '1' : '0';
            row[ImportRetailitemsColumn.Vendor] = vendorId > 0 ? this._retailservice.vendors.find(x => x.id === vendorId
                                                   && x.isActive)?.name : '';
            row[ImportRetailitemsColumn.VendorId] =  vendorId ;
            row[ImportRetailitemsColumn.SupplierItemNumber]= supplierItemNumber;
            row[ImportRetailitemsColumn.CommissionPercent] = item.retailImportItemInfo.retailItem.retailItemDetail.commissionPercentage ? item.retailImportItemInfo.retailItem.retailItemDetail.commissionPercentage.toString() : '0';
            row[ImportRetailitemsColumn.UseInventory] = item.retailImportItemInfo.retailItem.retailItemDetail.useInventory ? '1' : '0';
            row[ImportRetailitemsColumn.GroupingKey] = item.retailImportItemInfo.retailItem.retailItemDetail.isGroupingKey ? '1' : '0';
            row[ImportRetailitemsColumn.RequestName] = item.retailImportItemInfo.retailItem.retailItemDetail.isRequestName ? '1' : '0';
            row[ImportRetailitemsColumn.PMSPackageItem] = item.retailImportItemInfo.retailItem.retailItemDetail.isPMSPackageItem ? '1' : '0';
            row[ImportRetailitemsColumn.RequiredComments] = item.retailImportItemInfo.retailItem.retailItemDetail.isRequireComments ? '1' : '0';
            row[ImportRetailitemsColumn.OpenItems] = item.retailImportItemInfo.retailItem.retailItemDetail.isOpenItem ? '1' : '0';
            row[ImportRetailitemsColumn.Multipacks] = item.retailImportItemInfo.retailItem.retailItemDetail.isMultiPack ? '1' : '0';
            row[ImportRetailitemsColumn.Gratuity] = item.retailImportItemInfo.retailItem.retailItemDetail.gratuity.toString();
            row[ImportRetailitemsColumn.ServiceCharge] = item.retailImportItemInfo.retailItem.retailItemDetail.serviceCharge.toString();
            row[ImportRetailitemsColumn.SmallSticker] = item.retailImportItemInfo.retailItem.retailItemDetail.isSmallTicket ? '1' : '0';
            row[ImportRetailitemsColumn.ErrorMessage] = item.status != 0 ? item.errorMessage : "";
            if (item.errorMessage == null && item.errorCode != null && item.errorCode != "") {
                if (item.errorCode?.includes(" ")) {
                    let errorCodes = item.errorCode.split(" ");
                    errorCodes.forEach(x => {
                        row[ImportRetailitemsColumn.ErrorMessage] = this._localization.getError(parseInt(x));
                    })
                }
                else {
                    row[ImportRetailitemsColumn.ErrorMessage] = this._localization.getError(parseInt(item.errorCode));
                }

            }
            importdata.push(row);
        }
        return importdata;
    }

    async BuildRetailItems(data: any, isAuthorized: boolean,uiSelectedOutletId: number): Promise<any> {
        this.newCategory = [];
        this.newsubCategory = [];
        this.newCategoryLink = [];
        this.newUnitOfMeasure = [];
        this._retailItems = [];

        for (var row of data) {
            this.disableToggleForInvalidValue(row);
            this.validateRowData(row, data);
            row[ImportRetailitemsColumn.ItemNumber] = row[ImportRetailitemsColumn.ItemNumber].substring(0, 17);
            row[ImportRetailitemsColumn.ItemDescription] = row[ImportRetailitemsColumn.ItemDescription].substring(0, 50);

            if (row[ImportRetailitemsColumn.Category]) {
                row[ImportRetailitemsColumn.Category] = row[ImportRetailitemsColumn.Category].substring(0, 25);
                let isExist = this._retailservice.categories.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.Category].toLowerCase());
                if (!isExist)
                    this.AddNewCategory(row[ImportRetailitemsColumn.Category], Boolean(Number(row[ImportRetailitemsColumn.AllowEarn])));
            }

            if (row[ImportRetailitemsColumn.UnitOfMeasure]) {
                row[ImportRetailitemsColumn.UnitOfMeasure] = row[ImportRetailitemsColumn.UnitOfMeasure].substring(0, 30);
                let isExist = this._retailservice.unitOfMeasure.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.UnitOfMeasure].toLowerCase());
                if (!isExist)
                    this.AddNewUnitOfMeasure(row[ImportRetailitemsColumn.UnitOfMeasure]);
            }

            let parentCategExist = true;
            if (parentCategExist && row[ImportRetailitemsColumn.SubCategory1]) {
                row[ImportRetailitemsColumn.SubCategory1] = row[ImportRetailitemsColumn.SubCategory1].substring(0, 25);
                let isExist = this._retailservice.subCategoryOne.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory1].toLowerCase());
                this.AddCategoryLink(row[ImportRetailitemsColumn.SubCategory1], this.levelValues[0].value, row[ImportRetailitemsColumn.Category], isExist);
                if (!isExist)
                    this.AddNewSubCategory(row[ImportRetailitemsColumn.SubCategory1], this.levelValues[0].value);
            }
            else
                parentCategExist = false;

            if (parentCategExist && row[ImportRetailitemsColumn.SubCategory2]) {
                row[ImportRetailitemsColumn.SubCategory2] = row[ImportRetailitemsColumn.SubCategory2].substring(0, 25);
                let isExist = this._retailservice.subCategoryTwo.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory2].toLowerCase());
                this.AddCategoryLink(row[ImportRetailitemsColumn.SubCategory2], this.levelValues[1].value, row[ImportRetailitemsColumn.SubCategory1], isExist);
                if (!isExist)
                    this.AddNewSubCategory(row[ImportRetailitemsColumn.SubCategory2], this.levelValues[1].value);
            }
            else
                parentCategExist = false;

            if (parentCategExist && row[ImportRetailitemsColumn.SubCategory3]) {
                row[ImportRetailitemsColumn.SubCategory3] = row[ImportRetailitemsColumn.SubCategory3].substring(0, 25);
                let isExist = this._retailservice.subCategoryThree.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory3].toLowerCase());
                this.AddCategoryLink(row[ImportRetailitemsColumn.SubCategory3], this.levelValues[2].value, row[ImportRetailitemsColumn.SubCategory2], isExist);
                if (!isExist)
                    this.AddNewSubCategory(row[ImportRetailitemsColumn.SubCategory3], this.levelValues[2].value);
            }
            else
                parentCategExist = false;

            if (parentCategExist && row[ImportRetailitemsColumn.SubCategory4]) {
                row[ImportRetailitemsColumn.SubCategory4] = row[ImportRetailitemsColumn.SubCategory4].substring(0, 25);
                let isExist = this._retailservice.subCategoryFour.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory4].toLowerCase());
                this.AddCategoryLink(row[ImportRetailitemsColumn.SubCategory4], this.levelValues[3].value, row[ImportRetailitemsColumn.SubCategory3], isExist);
                if (!isExist)
                    this.AddNewSubCategory(row[ImportRetailitemsColumn.SubCategory4], this.levelValues[3].value);
            }
            else
                parentCategExist = false;

            if (parentCategExist && row[ImportRetailitemsColumn.SubCategory5]) {
                row[ImportRetailitemsColumn.SubCategory5] = row[ImportRetailitemsColumn.SubCategory5].substring(0, 25);
                let isExist = this._retailservice.subCategoryFive.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory5].toLowerCase());
                this.AddCategoryLink(row[ImportRetailitemsColumn.SubCategory5], this.levelValues[4].value, row[ImportRetailitemsColumn.SubCategory4], isExist);
                if (!isExist)
                    this.AddNewSubCategory(row[ImportRetailitemsColumn.SubCategory5], this.levelValues[4].value);
            }
        }

        await this.CreateImportcategory();
        for (var row of data) {

            row.subCategory1Id = 0;
            row.subCategory2Id = 0;
            row.subCategory3Id = 0;
            row.subCategory4Id = 0;
            row.subCategory5Id = 0;
            row.unitOfMeasureId = 0;

            if (row[ImportRetailitemsColumn.Category]) {
                let category = this._retailservice.categories.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.Category].toLowerCase());
                row.categoryId = category ? category.id : 0
            }

            if (row[ImportRetailitemsColumn.SubCategory1]) {
                let category = this._retailservice.subCategoryOne.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory1].toLowerCase());
                row.subCategory1Id = category ? category.id : 0
            }

            if (row[ImportRetailitemsColumn.SubCategory2]) {
                let category = this._retailservice.subCategoryTwo.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory2].toLowerCase());
                row.subCategory2Id = category ? category.id : 0
            }

            if (row[ImportRetailitemsColumn.SubCategory3]) {
                let category = this._retailservice.subCategoryThree.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory3].toLowerCase());
                row.subCategory3Id = category ? category.id : 0
            }
            if (row[ImportRetailitemsColumn.SubCategory4]) {
                let category = this._retailservice.subCategoryFour.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory4].toLowerCase());
                row.subCategory4Id = category ? category.id : 0
            }
            if (row[ImportRetailitemsColumn.SubCategory5]) {
                let category = this._retailservice.subCategoryFive.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.SubCategory5].toLowerCase());
                row.subCategory5Id = category ? category.id : 0
            }
            if (row[ImportRetailitemsColumn.UnitOfMeasure]) {
                let unitOfMeasure = this._retailservice.unitOfMeasure.find(x => x.name.toLowerCase() == row[ImportRetailitemsColumn.UnitOfMeasure].toLowerCase());
                row.unitOfMeasureId = unitOfMeasure ? unitOfMeasure.id : 0
            }
            let bodyData = this.FormBodyData(row, uiSelectedOutletId);
            this._retailItems.push(bodyData);
        }
        let res = false;
        if (this._retailItems && this._retailItems.length > 0) {
            this._utilities.ToggleLoaderWithMessage(true, this.retailCaption.LoaderWaitMsg);
            res = await this._retailservice.Importitems(this._retailItems, isAuthorized);
            this._utilities.ToggleLoaderWithMessage(false);
            return res;
        }
        return res;
    }

    disableToggleForInvalidValue(data) {
        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.GroupingKey]))
            data[ImportRetailitemsColumn.GroupingKey] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.CommissionableItem]))
            data[ImportRetailitemsColumn.CommissionableItem] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.CommissionRequired]))
            data[ImportRetailitemsColumn.CommissionRequired] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.UseInventory]))
            data[ImportRetailitemsColumn.UseInventory] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.RequestName]))
            data[ImportRetailitemsColumn.RequestName] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.PMSPackageItem]))
            data[ImportRetailitemsColumn.PMSPackageItem] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.RequiredComments]))
            data[ImportRetailitemsColumn.RequiredComments] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.OpenItems]))
            data[ImportRetailitemsColumn.OpenItems] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.Multipacks]))
            data[ImportRetailitemsColumn.Multipacks] = ToggleState.Disable;

        if (!Object.values(ToggleState).includes(data[ImportRetailitemsColumn.SmallSticker]))
            data[ImportRetailitemsColumn.SmallSticker] = ToggleState.Disable;
    }

    validateRowData(row: any, data: any) {
        this.validator.setDefaultItemTypeIfEmpty(row);
        if (!this.validator.isValidItemType(row)) {
            this.addRetailError(row, RetailErrorCode.RETAIL_ITEM_TYPE_INVALID);
        }

        if (!row[ImportRetailitemsColumn.ItemNumber])
            this.addRetailError(row, RetailErrorCode.ITEM_NUMBER_MISSING);

        let itemNumbercount = data.filter(x => x[ImportRetailitemsColumn.ItemNumber] != "" && x[ImportRetailitemsColumn.ItemNumber] == row[ImportRetailitemsColumn.ItemNumber])
        if (itemNumbercount && itemNumbercount.length > 1) {
            this.addRetailError(row, RetailErrorCode.DUPLICATE_ITEM_NUMBER);
        }

        if (isNaN(row[ImportRetailitemsColumn.ItemNumber])) {
            this.addRetailError(row, RetailErrorCode.ITEM_NUMBER_SHOULD_BE_NUMERIC);
        }

        if (!isNaN(row[ImportRetailitemsColumn.ItemNumber]) && /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/.test(row[ImportRetailitemsColumn.ItemNumber])) {
            this.addRetailError(row, RetailErrorCode.ITEM_NUMBER_SHOULD_NOT_BE_EXPONENTIAL);
        }

        if (row[ImportRetailitemsColumn.ItemNumber] && (row[ImportRetailitemsColumn.ItemNumber].includes('.') || row[ImportRetailitemsColumn.ItemNumber].includes(','))) {
            this.addRetailError(row, RetailErrorCode.ITEM_NUMBER_CANT_BE_DECIMAL);
        }
        if (!row[ImportRetailitemsColumn.ItemDescription]) {
            this.addRetailError(row, RetailErrorCode.DESCRIPTION_MISSING);
        }

        if (row[ImportRetailitemsColumn.SupplierItemNumber] && row[ImportRetailitemsColumn.SupplierItemNumber] != '') {
            const isValid =/^[a-zA-Z0-9 ]*$/.test(row[ImportRetailitemsColumn.SupplierItemNumber]);
            if(!isValid){
                this.addRetailError(row, RetailErrorCode.SUPPLIERITEM_NUMBER_SHOULD_BE_ALPHANUMERIC);
            }
        }

        const barCode = data.filter(x => x[ImportRetailitemsColumn.BarCode] !== ''
                        && x[ImportRetailitemsColumn.BarCode] === row[ImportRetailitemsColumn.BarCode]);
        const dbarCode = data.filter(x => x[ImportRetailitemsColumn.SecondaryBarcode] !== ''
                        && x[ImportRetailitemsColumn.SecondaryBarcode] === row[ImportRetailitemsColumn.BarCode]);
        if (barCode && barCode.length > 1) {
            this.addRetailError(row, RetailErrorCode.DUPLICATE_BARCODE);
        } else if (dbarCode && dbarCode.length >= 1) {
            this.addRetailError(row, RetailErrorCode.DUPLICATE_BARCODE);
        }

        const secondaryBarCode = data.filter(x => x[ImportRetailitemsColumn.SecondaryBarcode] !== ''
                        && x[ImportRetailitemsColumn.SecondaryBarcode] === row[ImportRetailitemsColumn.SecondaryBarcode]);
        const dsecondaryBarCode = data.filter(x => x[ImportRetailitemsColumn.BarCode] !== ''
                        && x[ImportRetailitemsColumn.BarCode] === row[ImportRetailitemsColumn.SecondaryBarcode]);
        if (secondaryBarCode && secondaryBarCode.length > 1) {
            this.addRetailError(row, RetailErrorCode.DUPLICATE_SECONDARY_BARCODE);
        } else if (dsecondaryBarCode && dsecondaryBarCode.length >= 1) {
             this.addRetailError(row, RetailErrorCode.DUPLICATE_SECONDARY_BARCODE);
        }

        if (!row[ImportRetailitemsColumn.Category]) {
            this.addRetailError(row, RetailErrorCode.CATEGORY_MISSING);
        }

        if (!row[ImportRetailitemsColumn.OutletSwitch]) {
            this.addRetailError(row, RetailErrorCode.OUTLET_SWITCH_MISSING);
        }

        if (row[ImportRetailitemsColumn.OutletSwitch] && row[ImportRetailitemsColumn.OutletSwitch] != "0" && row[ImportRetailitemsColumn.OutletSwitch] != "1") {
            this.addRetailError(row, RetailErrorCode.INVALID_OUTLET_SWITCH);
        }
        if (row[ImportRetailitemsColumn.Quantity] && (row[ImportRetailitemsColumn.Quantity].includes('.') || row[ImportRetailitemsColumn.Quantity].includes(','))) {
            this.addRetailError(row, RetailErrorCode.QTY_CANT_BE_DECIMAL);
        }
        if (row[ImportRetailitemsColumn.ItemPar] && (row[ImportRetailitemsColumn.ItemPar].includes('.') || row[ImportRetailitemsColumn.ItemPar].includes(','))) {
            this.addRetailError(row, RetailErrorCode.ITMPAR_CANT_BE_DECIMAL);
        }
        if (row[ImportRetailitemsColumn.OutletSwitch] === "0") {
            if (!row[ImportRetailitemsColumn.OutletCode] && this._retailservice.ProductId !== Product.PMS) {
                this.addRetailError(row, RetailErrorCode.OUTLET_MISSING);
            }

            if(row[ImportRetailitemsColumn.OutletCode]){
                var outletCodes =row[ImportRetailitemsColumn.OutletCode].replace(/"/g, '').split(',');
                let invalidOutlets = outletCodes.filter(x=> 
                    !this._retailservice.allActiveOutlets.some(selected => selected.name === x.trim()));
            

                  if(invalidOutlets && invalidOutlets.length> 0){
                    let errorMsg = this.localization.replacePlaceholders(this.localization.getError(Number(RetailErrorCode.OUTLET_NOT_EXISTS)), ["productName"], [this._retailservice.loginProd])
                    row[ImportRetailitemsColumn.ErrorMessage] = errorMsg;
                  }                  
            }else
            {
                let errorMsg = this.localization.replacePlaceholders(this.localization.getError(Number(RetailErrorCode.OUTLET_NOT_EXISTS)), ["productName"], [this._retailservice.loginProd])
                row[ImportRetailitemsColumn.ErrorMessage] = errorMsg;
            }
        }
        let vendorId = 0 ;
        if (row[ImportRetailitemsColumn.Vendor] && row[ImportRetailitemsColumn.Vendor] !== '') {
            row[ImportRetailitemsColumn.Vendor] = row[ImportRetailitemsColumn.Vendor].substring(0, 50);
            const selectedVendor = this._retailservice.vendors.filter(x => x.isActive && x.name.toLowerCase()
             === row[ImportRetailitemsColumn.Vendor].toLowerCase());
            if (row[ImportRetailitemsColumn.Vendor] && (selectedVendor && selectedVendor.length > 0)) {
                row[ImportRetailitemsColumn.VendorId] = selectedVendor[0].id;
                vendorId = selectedVendor[0].id;
            }
            else if (row[ImportRetailitemsColumn.Vendor] && (selectedVendor.length === 0)) {
                row[ImportRetailitemsColumn.VendorId] = 0;
                this.addRetailError(row, RetailErrorCode.INVALID_VENDOR);
            }
        }
    
        if(row[ImportRetailitemsColumn.SupplierItemNumber] && row[ImportRetailitemsColumn.SupplierItemNumber] != ''){
            row[ImportRetailitemsColumn.SupplierItemNumber] = row[ImportRetailitemsColumn.SupplierItemNumber].substring(0, 25);
            if(row[ImportRetailitemsColumn.Vendor].trim().length > 0 && vendorId != 0 ){
                row[ImportRetailitemsColumn.SupplierItemNumber] = row[ImportRetailitemsColumn.SupplierItemNumber] ; 
            } else  {
                this.addRetailError(row, RetailErrorCode.INVALID_SUPPLIERITEM_NUMBER);
            }
        }

        if (Boolean(Number(row[ImportRetailitemsColumn.Multipacks])) && (Boolean(Number(row[ImportRetailitemsColumn.GroupingKey])) && (row[ImportRetailitemsColumn.UnitOfMeasure] && row[ImportRetailitemsColumn.UnitOfMeasure].trim().length > 0))) {
            this.addRetailError(row, RetailErrorCode.CANT_ENABLE_BOTH_GROUPING_KEY_SCALED_ITEM_AND_MULTIPACK);
        }

        if (Boolean(Number(row[ImportRetailitemsColumn.Multipacks])) && Boolean(Number(row[ImportRetailitemsColumn.GroupingKey])) && !row[ImportRetailitemsColumn.UnitOfMeasure]) {
            this.addRetailError(row, RetailErrorCode.CANT_ENABLE_BOTH_GROUPING_KEY_AND_MULTIPACK);
        }
        if (Boolean(Number(row[ImportRetailitemsColumn.Multipacks])) && (row[ImportRetailitemsColumn.UnitOfMeasure] && row[ImportRetailitemsColumn.UnitOfMeasure].trim().length > 0) && !Boolean(Number(row[ImportRetailitemsColumn.GroupingKey]))) {
            this.addRetailError(row, RetailErrorCode.CANT_ENABLE_BOTH_SCALED_ITEM_AND_MULTIPACK);
        }
        if (!this.validator.isServiceChargeOrGratuityApplicable(row)) {
            this.addRetailError(row, RetailErrorCode.SERVICE_CHARGE_OR_GRATUITY_NOT_APPLICABLE_FOR_SELECTED_RETAIL_ITEM_TYPE);
        }

        if ((Boolean(Number(row[ImportRetailitemsColumn.Multipacks])) || (this._retailservice.ProductId != Product.GOLF && row[ImportRetailitemsColumn.ItemType] == RetailItemType.SpaPackage)) &&
            (Boolean(Number(row[ImportRetailitemsColumn.CommissionableItem])) || Boolean(Number(row[ImportRetailitemsColumn.CommissionRequired])) ||
                (row[ImportRetailitemsColumn.CommissionPercent] && row[ImportRetailitemsColumn.CommissionPercent] > 0))) {
            this.addRetailError(row, RetailErrorCode.CANT_CONFIG_COMMISSION_FOR_MULTIPACK_OR_DEPOSIT_ITEMTYPE);
        }

        const isCommissionableItem: boolean = Boolean(Number(row[ImportRetailitemsColumn.CommissionableItem]));
        const isCommissionRequired: boolean = Boolean(Number(data[ImportRetailitemsColumn.CommissionRequired]));
        const isHavingCommissionPercentage: boolean = row[ImportRetailitemsColumn.CommissionPercent] && Number(row[ImportRetailitemsColumn.CommissionPercent]) > 0;
        const itemType: string = row[ImportRetailitemsColumn.ItemType];
        const isOpenItem: boolean = Boolean(Number(row[ImportRetailitemsColumn.OpenItems]));
        const isGroupingKey: boolean = Boolean(Number(row[ImportRetailitemsColumn.GroupingKey]));
        const isMultiPack: boolean = Boolean(Number(row[ImportRetailitemsColumn.Multipacks]));
        const isScaledItem: boolean = (row[ImportRetailitemsColumn.UnitOfMeasure] && row[ImportRetailitemsColumn.UnitOfMeasure].trim().length > 0);
        const AllowEarn: boolean = Boolean(Number(row[ImportRetailitemsColumn.AllowEarn]));
        const SmallSticker: boolean = Boolean(Number(row[ImportRetailitemsColumn.SmallSticker]));

        // eg: 'Commissionable Item/Commission Required' = '1' and 'Item Type' = '6'(deposit)
        if ((isCommissionableItem || isCommissionRequired) && (itemType == RetailItemType.Deposit)) {
            this.addRetailError(row, RetailErrorCode.COMMISION_NOT_APPLICABLE_FOR_SELECTED_TYPE);
        }

        // eg: 'Commission Percentage' = '123' and 'Item Type' = '6'(deposit)
        if (isHavingCommissionPercentage && itemType == RetailItemType.Deposit) {
            this.addRetailError(row, RetailErrorCode.COMMISION_PERCENTAGE_NOT_APPLICABLE_FOR_SELECTED_TYPE);
        }

        if (isGroupingKey && isOpenItem) {
            this.addRetailError(row, RetailErrorCode.CANT_ENABLE_GROUPINGKEY_OPENITEM);
        }

        if (isMultiPack && isOpenItem) {
            this.addRetailError(row, RetailErrorCode.CANT_ENABLE_MULTIPACK_OPENITEM);
        }

        if (isScaledItem && isOpenItem) {
            this.addRetailError(row, RetailErrorCode.CANT_ENABLE_SCALEDITEM_OPENITEM);
        }
    }

    addRetailError(row, errorCode) {
        if (row[ImportRetailitemsColumn.ErrorMessage]) {
            row[ImportRetailitemsColumn.ErrorMessage] = row[ImportRetailitemsColumn.ErrorMessage] + ' ' + this.localization.getError(Number(errorCode));

            row[ImportRetailitemsColumn.ErrorCode] = row[ImportRetailitemsColumn.ErrorCode] + ' ' + errorCode.toString();

        }
        else {
            row[ImportRetailitemsColumn.ErrorMessage] = this.localization.getError(Number(errorCode));

            row[ImportRetailitemsColumn.ErrorCode] = errorCode.toString();

        }

    }

    async CreateImportcategory() {
        let val = true;
        if (this.newCategory.length > 0 || this.newsubCategory.length > 0 || this.newCategoryLink.length > 0 || this.newUnitOfMeasure.length > 0) {
            let body = {
                categories: this.newCategory,
                subCategories: this.newsubCategory,
                catSubCatLinkDetails: this.newCategoryLink,
                unitOfMeasures: this.newUnitOfMeasure
            }
            val = await this._retailservice.AddCatSubCatAndLinkAndMeasure(body);
        }
        return val;
    }

    AddCategoryLink(child: any, level: any, parent: any, childData: any) {

        if (this.newCategoryLink.find(x => x.parentName.toLowerCase() == parent.toLowerCase() && x.childName.toLowerCase() == child.toLowerCase() && x.level == level))
            return;
        const categoryLinkObj = {
            parentName: parent,
            childName: child,
            level: level
        }
        let selectedCategoryArray: any[] = [];
        if (!childData) {
            this.newCategoryLink.push(categoryLinkObj)
        }
        else {
            switch (level) {
                case 1:
                    selectedCategoryArray = this._retailservice.categories.filter(x => x.name.toLowerCase() == parent.toLowerCase());
                    break;
                case 2:
                    selectedCategoryArray = this._retailservice.subCategoryOne.filter(x => x.name.toLowerCase() == parent.toLowerCase());
                    break;
                case 3:
                    selectedCategoryArray = this._retailservice.subCategoryTwo.filter(x => x.name.toLowerCase() == parent.toLowerCase());
                    break;
                case 4:
                    selectedCategoryArray = this._retailservice.subCategoryThree.filter(x => x.name.toLowerCase() == parent.toLowerCase());
                    break;
                case 5:
                    selectedCategoryArray = this._retailservice.subCategoryFour.filter(x => x.name.toLowerCase() == parent.toLowerCase());
                    break;
            }
            if (selectedCategoryArray && selectedCategoryArray.length > 0) {
                if ((selectedCategoryArray[0].childSubCategories && selectedCategoryArray[0].childSubCategories.length > 0
                    && !selectedCategoryArray[0].childSubCategories.some(sub1 => sub1.id == childData.id)) ||
                    (!selectedCategoryArray[0].childSubCategories || selectedCategoryArray[0].childSubCategories.length == 0))
                    this.newCategoryLink.push(categoryLinkObj);
            }

            if (!selectedCategoryArray || selectedCategoryArray.length == 0)
                this.newCategoryLink.push(categoryLinkObj);
        }
    }

    AddNewUnitOfMeasure(unitOfMeas: string) {
        let isExistinNew = this.newUnitOfMeasure.find(x => x.Name.toLowerCase() == unitOfMeas.toLowerCase());
        if (isExistinNew) return;
        let listOrderArray = this._retailservice.unitOfMeasure && this._retailservice.unitOfMeasure.map(e => e.listOrder);
        const unitOfMeasureObj = {
            IsActive: true,
            Name: unitOfMeas.trim(),
            ListOrder: 0,
        };
        this.newUnitOfMeasure.push(unitOfMeasureObj)
        let maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;
        unitOfMeasureObj.ListOrder = maxListOrder + this.newUnitOfMeasure.length;
    }

    AddNewSubCategory(subCategoryName: string, level: any) {
        let isExistinNew = this.newsubCategory.find(x => x.name.toLowerCase() == subCategoryName.toLowerCase() && x.level == level);
        if (isExistinNew) return;
        let subCategoryObj = {
            name: subCategoryName.trim(),
            level: level,
            isActive: true,
            listOrder: 0,
            parentCategoryId: 0
        }
        let listOrderArray: any[] = [];
        switch (level) {
            case 1:
                listOrderArray = this._retailservice.subCategoryOne && this._retailservice.subCategoryOne.map(e => e.listOrder);
                break;
            case 2:
                listOrderArray = this._retailservice.subCategoryTwo && this._retailservice.subCategoryTwo.map(e => e.listOrder);
                break;
            case 3:
                listOrderArray = this._retailservice.subCategoryThree && this._retailservice.subCategoryThree.map(e => e.listOrder);
                break;
            case 4:
                listOrderArray = this._retailservice.subCategoryFour && this._retailservice.subCategoryFour.map(e => e.listOrder);
                break;
            case 5:
                listOrderArray = this._retailservice.subCategoryFive && this._retailservice.subCategoryFive.map(e => e.listOrder);
                break;
        }
        let maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;
        subCategoryObj.listOrder = maxListOrder + 1 + this.newsubCategory.filter(x => x.level == level).length;
        this.newsubCategory.push(subCategoryObj);
    }

    AddNewCategory(categoryName: string, allowEarn: boolean) {
        let isExist = this.newCategory.find(x => x.name.toLowerCase() == categoryName.toLowerCase());
        if (isExist) {
            if (!allowEarn) { isExist["allowEarn"] = false; }
            return;
        }
        let listOrderArray = this._retailservice.categories && this._retailservice.categories.map(e => e.listOrder);
        let maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;
        let newCategoryObj = {
            "name": categoryName.trim(),
            "retailCategoryGroupId": 0,
            "isActive": true,
            "listOrder": maxListOrder + 1 + this.newCategory.length,
            "eligibleForDiscount": false,
            "allowEarn": allowEarn
        };
        this.newCategory.push(newCategoryObj);
    }


    CalcMarginPercent(salesPrice: string, costPrice: string) {
        //MP = (((SP-CP)/SP)*100)
        let SalesPrice = salesPrice ? this.localization.currencyToSQLFormat(salesPrice): 0;
        let CostPrice = costPrice ? this.localization.currencyToSQLFormat(costPrice) : 0;
        let result = ((((SalesPrice) - (CostPrice)) / (SalesPrice)) * 100);
        result = !isFinite(result) ? 0 : Number(result.toFixed(2));
        return this.localization.localizePercentage(result.toString());
    }

    BuildRetailTaxInfo(taxes, selectedOutlet): RetailItemTax[] {
        let resultArr: RetailItemTax[] = [];
        if (!taxes || taxes.trim().length == 0) {
            return resultArr;
        }

        let taxConfig = taxes.replace(/"/g, '').split(',');

        if (!this.PropertyInfo.IsVATEnabled) {
            for (let tax of taxConfig) {
                // isvalid tax and tax exist in selected outlet
                let taxDetail = this._retailservice.AllActiveTaxes.find(x => x.taxName == tax &&
                    selectedOutlet.find(({ id }) => x.outletId === id));
                if (taxDetail) {
                    if (!resultArr.find(x => x.taxName == tax)) {
                        resultArr.push({
                            id: 0,
                            retailItemId: 0,
                            taxName: tax,
                            taxId:taxDetail.id
                        });
                    }
                }
            }
        }
        else if (this.PropertyInfo.IsVATEnabled) {
            let uniqueTaxes = [...new Set(this._retailservice.AllActiveTaxes.map(item => item.taxName))];
            taxConfig.forEach(_tax => {
                let isexist = uniqueTaxes.find(x => x == _tax);
                let taxDetail = this._retailservice.AllActiveTaxes.find(x => x.taxName == _tax);
                if (isexist && resultArr.length == 0) {
                    resultArr.push({
                        id: 0,
                        retailItemId: 0,
                        taxName: _tax,
                        taxId:taxDetail.id
                    });
                }
            });
        }
        return resultArr;
    }

    BuildVendorObject(id: number,  supplierNumber: string,retailItemId: number): RetailItemVendor[]{
        const vendors: RetailItemVendor[] = [];
        if (id || supplierNumber) {
            vendors.push({
                id: 0,
                vendorId : id,
                retailItemId: retailItemId?retailItemId:0,
                supplierItemId: supplierNumber
            });
        }
        return vendors;
    }

    BuildBarCodeObject(barcode: string, secondaryBarcode: string,retailItemId: number): RetailItemBarCode[] {
        let barCodeObj: RetailItemBarCode[] = [];
        if (barcode && barcode != '') {
            barcode = barcode.trim().substring(0, 100);
            barCodeObj.push({
                id: 0,
                retailItemId: retailItemId?retailItemId:0,
                barCode: barcode,
                isPrimary: true,
                isSecondary: false
            });
        }
        if (secondaryBarcode && secondaryBarcode != '') {
            secondaryBarcode = secondaryBarcode.trim().substring(0, 100);
            barCodeObj.push({
                id: 0,
                retailItemId: retailItemId?retailItemId:0,
                barCode: secondaryBarcode,
                isPrimary: false,
                isSecondary: true
            });
        }
        return barCodeObj;
    }
    
    getObjectValue(obj, propertyName) {
        const lowerCasePropertyName = propertyName.toLowerCase();
        for (const key in obj) {
          if (key.toLowerCase() === lowerCasePropertyName) {
            return obj[key];
          }
        }
        return undefined;
    }

    FormBodyData(data: any,uiSelectedOutletId: number): any {
        var price = this.getObjectValue(data, ImportRetailitemsColumn.Price);
        var cost = this.getObjectValue(data, ImportRetailitemsColumn.Cost);
        var commissionPercentage = this.getObjectValue(data, ImportRetailitemsColumn.CommissionPercent);
        var gratuity = this.getObjectValue(data, ImportRetailitemsColumn.Gratuity);
        var serviceCharge = this.getObjectValue(data, ImportRetailitemsColumn.ServiceCharge);
        var retailItemId = this.getObjectValue(data, ImportRetailitemsColumn.ID);

        let retailItem: RetailItemDetail =
        {
            id: retailItemId?retailItemId:0,
            externalPOSId: retailItemId?retailItemId:0,
            itemNumber: this.getObjectValue(data, ImportRetailitemsColumn.ItemNumber),
            itemDescription: this.getObjectValue(data, ImportRetailitemsColumn.ItemDescription),
            salesPrice: this.convertToDecimal(price),
            memberPrice: this.convertToDecimal(price),
            suggestedPrice: 0,
            costPrice: this.convertToDecimal(cost),
            marginPercentage: this.localization.currencyToSQLFormat(this.CalcMarginPercent(price, cost)),
            barcode: this.getObjectValue(data, ImportRetailitemsColumn.BarCode),
            secondaryBarcode: this.getObjectValue(data, ImportRetailitemsColumn.SecondaryBarcode),

            category: data.categoryId,
            unitOfMeasure: data.unitOfMeasureId,
            subCategory1: data.subCategory1Id,
            subCategory2: data.subCategory2Id,
            subCategory3: data.subCategory3Id,
            subCategory4: data.subCategory4Id,
            subCategory5: data.subCategory5Id,

            isCommissionRequired: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.CommissionRequired))),
            isCommissionable: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.CommissionableItem))),
            commissionPercentage: !isNaN(commissionPercentage) && commissionPercentage ? commissionPercentage : 0,
            allowEarn: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.AllowEarn))),
            itemType: this.getObjectValue(data, ImportRetailitemsColumn.ItemType),
            isGroupingKey: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.GroupingKey))),
            isScaledItem: data.unitOfMeasureId && data.unitOfMeasureId > 0,
            isTeeTimeTrigger: false,
            isCartTrigger: false,
            isActive: true,
            isRequestName: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.RequestName))),
            isPMSPackageItem: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.PMSPackageItem))),
            isRequireComments: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.RequiredComments))),
            isOpenItem: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.OpenItems))),
            isMultiPack: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.Multipacks))),
            isGratuityAllowed: !isNaN(gratuity) && gratuity > 0,
            gratuity: !isNaN(gratuity) && gratuity ? Number(this.localization.currencyToSQLFormat(gratuity).customToFixed()) : 0,
            isServiceChargeAllowed: !isNaN(serviceCharge) && serviceCharge > 0,
            serviceCharge: !isNaN(serviceCharge) && serviceCharge ? Number(this.localization.currencyToSQLFormat(serviceCharge).customToFixed()) : 0,
            isPrintCartAgreement: false,
            isPrintClubAgreement: false,
            isPrintShoeAgreement: false,
            isPrintToCaddyShack: false,
            isPrintOnlyOnePerTansaction: false,
            isHangingTicket: false,
            isSmallTicket: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.SmallSticker))),
            isPrintRetailSaleChit:false,
            linkedItemId: 0,
            multiStartDate: null,
            multiEndDate: '',
            multiExpDays: null,
            multiSalesPrice: null,
            multiMemberPrice: null,
            multiCostPrice: null,
            multiGratuity: null,
            multiServiceCharge: null,
            multiPerSale: null,
            isMultiUnlimited: false,
            seasonalStartDate: '',
            seasonalEndDate: '',
            seasonalPrice: 0,
            seasonalMemberPrice: 0,
            useInventory: Boolean(Number(this.getObjectValue(data, ImportRetailitemsColumn.UseInventory))),
            isItemNumberAutogenerate: false,
            disposableItem: false,
            perHourRentalRate: 0,
            perDayRentalRate: 0,
            isMultipleLinkedItem : false,
            isExternal : false,
            sourceSystem : SourceSystem.Internal
        }
        let packagedItems: PackagedItem[] = [];
        let selectedOutletMgrArr = this._retailservice.allActiveOutlets;

        var outletCode = this.getObjectValue(data, ImportRetailitemsColumn.OutletCode);
        var outletSwitch = this.getObjectValue(data, ImportRetailitemsColumn.OutletSwitch);
        var outletCodes = outletCode.replace(/"/g, '').split(',');

        if (outletSwitch == "0" && outletCode != null) {
            selectedOutletMgrArr = this._retailservice.allActiveOutlets.filter(activeOutlet =>
                outletCodes.some(selected => selected.trim() === activeOutlet.name)
              );
        }
        else if (outletSwitch == "" && outletCode != "") {
            selectedOutletMgrArr = this._retailservice.allActiveOutlets.filter(activeOutlet =>
                outletCodes.some(selected => selected.trim() === activeOutlet.name)
              )??[];
        }
        else if ((outletSwitch == "" && outletCode == "") ||
            (outletSwitch == "0" && outletCode == "")) {
            selectedOutletMgrArr = [];
        }
        if(this._retailservice.ProductId === Product.PMS) {
            selectedOutletMgrArr = this._retailservice.allActiveOutlets.filter(x => x.id === this.PropertyInfo.GetDefaultOutlet())
        }

        let outletItem: OutletItem[] = [];
        if (selectedOutletMgrArr && selectedOutletMgrArr.length > 0) {
            outletItem = selectedOutletMgrArr.map(x => {
                return {
                    retailItemId: retailItemId?retailItemId:0,
                    outletId: x.id,
                    syncStatus: SyncStatus.SYNCED
                    //syncStatus: SyncStatus.NOTSYNCED
                }
            })
        }
        let vendorInfo: RetailItemVendor[] = this.BuildVendorObject(this.getObjectValue(data, ImportRetailitemsColumn.VendorId),this.getObjectValue(data, ImportRetailitemsColumn.SupplierItemNumber),retailItemId);
        let retailItemTemplateConfig : RetailItemTemplateConfig[]=[];
        let taxesInfo: RetailItemTax[] = this.BuildRetailTaxInfo(this.getObjectValue(data, ImportRetailitemsColumn.Tax), selectedOutletMgrArr);
        let barCodeInfo: RetailItemBarCode[] = this.BuildBarCodeObject(this.getObjectValue(data, ImportRetailitemsColumn.BarCode), this.getObjectValue(data, ImportRetailitemsColumn.SecondaryBarcode),retailItemId);
        let multipackDetail: MultiPackDetail[] = [];
        let retailItemAggregate: RetailItem = {
            retailItemDetail: retailItem, outletItem: outletItem, packagedItem: packagedItems,
            retailItemTax: taxesInfo, retailItemBarCode: barCodeInfo, retailItemVendor: vendorInfo,
            retailItemTemplateConfig:retailItemTemplateConfig,
            multipackDetail: multipackDetail

        }

        var quantity = this.getObjectValue(data, ImportRetailitemsColumn.Quantity);
        var itemPar = this.getObjectValue(data, ImportRetailitemsColumn.ItemPar);
        // Update InventoryItems for Selected Outlet from UI
        let uiSelectedOutlet = selectedOutletMgrArr.filter(x=>x.id == uiSelectedOutletId);
        let inventoryOutlet: InventoryOutlet[] = uiSelectedOutlet && uiSelectedOutlet.length > 0 ? uiSelectedOutlet.map(x => {
            return {
                id: x.outletId ? x.outletId : 0,
                inventoryItemId: x.inventoryItemId ? x.inventoryItemId : 0,
                itemId: retailItemAggregate.retailItemDetail.id ? retailItemAggregate.retailItemDetail.id : 0,
                outletId: x.id,
                quantity: quantity ? quantity : 0,
                updatedQuantity: quantity ? quantity : 0,
                itemPar: !isNaN(itemPar) && itemPar ? itemPar : 0,
                unitCost: cost ? Number(this.localization.currencyToSQLFormat(cost).customToFixed()) : 0,
                weightedAverageCost:  0,
                reason: '',
                mtd: x.mtd ? x.mtd : 0,
                ytd: x.ytd ? x.ytd : 0
            }
        }) : [];
        const tempOutlet = inventoryOutlet.length > 0 ? inventoryOutlet[0].inventoryItemId : 0;
        let inventoryInfo: InventoryInfo = {
            id: inventoryOutlet.length > 0 ? tempOutlet : 0,
            retailItemId: retailItemAggregate.retailItemDetail.id ? retailItemAggregate.retailItemDetail.id : 0,
            outlets: inventoryOutlet
        }
        return {
            retailItem: retailItemAggregate,
            inventoryInfo: inventoryInfo,
            errorMessage: this.getObjectValue(data, ImportRetailitemsColumn.ErrorMessage),
            errorCode: this.getObjectValue(data, ImportRetailitemsColumn.ErrorCode)
        };
    }

    private convertToDecimal(value: string) {
        value = value ? value : "0";
        let res = parseFloat(value.replace(/"/g, '').replace(/,/g, ''));
        return Number(res.customToFixed());
    }

    buildheader(data: any) {
        let headerdata: any[] = [];
        for (var key of Object.keys(data[0])) {
            switch (key) {
                case ImportRetailitemsColumn.Price:
                case ImportRetailitemsColumn.Cost:
                case ImportRetailitemsColumn.Quantity:
                case ImportRetailitemsColumn.CommissionPercent:
                    headerdata.push(
                        {
                            name: key,
                            title: this.captions[key],
                            type: "number",
                            isEditable: true,
                            ishidden: false,
                            required: false
                        }
                    )
                    break;
                case ImportRetailitemsColumn.AllowEarn:
                case ImportRetailitemsColumn.OutletSwitch:
                    headerdata.push(
                        {
                            name: key,
                            title: this.captions[key],
                            type: "boolean",
                            isEditable: true,
                            ishidden: false,
                            required: false
                        }
                    )
                    break;
                case ImportRetailitemsColumn.ErrorMessage:
                    headerdata.push(
                        {
                            name: key,
                            title: this.captions[key],
                            type: "string",
                            isEditable: false,
                            ishidden: false,
                            required: false
                        }
                    )
                    break;
                case 'id':
                case 'categoryId':
                case 'subCategory1Id':
                case 'subCategory2Id':
                case 'subCategory3Id':
                case 'subCategory4Id':
                case 'subCategory5Id':
                case 'unitOfMeasureId':
                case ImportRetailitemsColumn.VendorId:
                    headerdata.push(
                        {
                            name: key,
                            title: this.captions[key],
                            type: "string",
                            isEditable: false,
                            ishidden: true,
                            required: false
                        }
                    )
                    break;
                case ImportRetailitemsColumn.ItemNumber:
                case ImportRetailitemsColumn.ItemDescription:
                case ImportRetailitemsColumn.Category:
                    headerdata.push(
                        {
                            name: key,
                            title: this.captions[key],
                            type: "string",
                            isEditable: true,
                            ishidden: false,
                            required: true
                        }
                    )
                    break;
                default:
                    headerdata.push(
                        {
                            name: key,
                            title: this.captions[key],
                            type: "string",
                            isEditable: true,
                            ishidden: false,
                            required: false
                        }
                    )
                    break;
            }
        }
        return headerdata;
    }

    handleError(errorResponse: BaseResponse<any>, callback): void {
        let errorMessage = this.localization.getError(Number(errorResponse.errorCode));
        if (errorResponse.errorDescription)
            errorMessage = errorMessage + ' - ' + errorResponse.errorDescription;

        this._utilities.showAlert(errorMessage, AlertType.Error, ButtonType.viewFailedCancel, callback);
    }
}
