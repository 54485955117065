import { Pipe, PipeTransform } from '@angular/core'; 
import { RetailLocalization } from './retail-localization';

@Pipe({
  name: 'localizeDateTime'
})
export class LocalizeDateTimePipe implements PipeTransform {

    constructor(private localization: RetailLocalization) {

    }
  transform(value: any): any {
    return value? this.localization.LocalizeDate(value) + " - "  + this.localization.LocalizeTime(value): "";
  }

}
