import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { TranslateService } from '@ngx-translate/core';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { ConfirmPopModel } from "src/app/eatecui/source/agilysys-popup/model/pop-up.interface"
import { MatDialog } from '@angular/material/dialog';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';

export const CustomTypeChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: TransactionConfiguration, 
    sysytemPreferenceService?: SysytemPreferenceService, createTransactionService?: CreateTransactionService, translateService?: TranslateService, matDialog?: MatDialog
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            if (MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData && 
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0) { 
                const clonedObject = JSON.stringify(createTransactionService?.prefiledlistObject);               
                const confirmPopModel = {} as ConfirmPopModel;
                confirmPopModel.PopUpContent = translateService.instant('PhysicalHead.FieldsChangePopupContent');
                confirmPopModel.PopupHeaderContent = translateService.instant('PhysicalHead.Confirmation');
                const dialogRefs = matDialog?.open(AgilysysConfirmationPopupComponent, {
                  width: '440px',
                  height: '220px',
                  data: confirmPopModel,
                  autoFocus: false,
                  disableClose: true
                });
                dialogRefs?.afterClosed().subscribe(res => {
                    if (res === 'Cancel' && clonedObject) {
                       const parsedObject = JSON.parse(clonedObject);
                       FormGroupData.controls[FieldGrouping.OdataKey].setValue(parsedObject[FieldGrouping.OdataKey]);
                       FormGroupData.updateValueAndValidity();
                    } else if (res === 'Confirm') {
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = 
                          [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = [];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody = 
                        [...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderBody];
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard = 
                        {...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemTableCard};
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = 
                          {...MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig};
                        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true); 
                        typeChange(FormGroupData, FormField, FieldGrouping, MasterDataCollection);                         
                    }
                });
            } else {
                typeChange(FormGroupData, FormField, FieldGrouping, MasterDataCollection);
            }
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

function  typeChange(FormGroupData: FormGroup, FormField: any, FieldGrouping: FormBuilderGroupKey, MasterDataCollection: any): void {
    try {
        const TypeId = FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value;
        if (FormField.length !== MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields.length) {
            FormField = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields;
        }
        const TypesIndex = FormField.findIndex(x => x.fieldType.SingleData.name === 'Type');
        const ClassIndex = FormField.findIndex(x => x.fieldType.SingleData.name === 'Class');
        const PackageIndex = FormField.findIndex(x => x.fieldType.SingleData.name === 'Package');
        switch (TypeId) {
            case 1:
                // if (FieldGrouping.CustomFormAction.DisabledField === 'pientercounts' ||
                // FieldGrouping.CustomFormAction.DisabledField === 'pientercyclecounts'
                // || FieldGrouping.CustomFormAction.DisabledField === 'piprint') {
                     FormGroupData.controls['Type'].setValidators([Validators.required]);
                     FormGroupData.controls['Package'].clearValidators();
                     FormGroupData.controls['Class'].clearValidators();
                // }
                FormField[TypesIndex].hide = false;
                FormField[ClassIndex].hide = true;
                FormField[PackageIndex].hide = true;
                break;
            case 2:
                // if (FieldGrouping.CustomFormAction.DisabledField === 'pientercounts' ||
                // FieldGrouping.CustomFormAction.DisabledField === 'pientercyclecounts'
                // || FieldGrouping.CustomFormAction.DisabledField === 'piprint') {
                     FormGroupData.controls['Class'].setValidators([Validators.required]);
                     FormGroupData.controls['Type'].clearValidators();
                     FormGroupData.controls['Package'].clearValidators();
                // }
                FormField[TypesIndex].hide = true;
                FormField[ClassIndex].hide = false;
                FormField[PackageIndex].hide = true;
                break;
            case 3:
                // if (FieldGrouping.CustomFormAction.DisabledField === 'pientercounts' ||
                // FieldGrouping.CustomFormAction.DisabledField === 'pientercyclecounts'
                // || FieldGrouping.CustomFormAction.DisabledField === 'piprint') {
                     FormGroupData.controls['Package'].setValidators([Validators.required]);
                     FormGroupData.controls['Type'].clearValidators();
                     FormGroupData.controls['Class'].clearValidators();
                // }
                FormField[TypesIndex].hide = true;
                FormField[ClassIndex].hide = true;
                FormField[PackageIndex].hide = false;
                break;
            case 4:
                 FormGroupData.controls['Type'].clearValidators();
                 FormGroupData.controls['Package'].clearValidators();
                 FormGroupData.controls['Class'].clearValidators();
                FormField[TypesIndex].hide = true;
                FormField[ClassIndex].hide = true;
                FormField[PackageIndex].hide = true;
                break;
        }
        FormGroupData.controls['Type'].setValue(null);
        FormGroupData.controls['Class'].setValue(null);
        FormGroupData.controls['Package'].setValue(null);
        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true);
    } catch (error) {
        console.log(error);
    }
}