import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FileData, FileUploadConfig, GenerateThumbNail, UploadMetaData } from './upload-configuration.interface';
import { DetailsPayload } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';

export const ThumbNailGenerate: GenerateThumbNail = ( AssetUrl: string ) => {
    try {
        if ( AssetUrl) {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest();       
                xhr.open('GET', AssetUrl, true); 
                xhr.responseType = 'blob';
                xhr.onload = function (e) {
                        console.log(this.response);
                        const reader = new FileReader();
                        reader.onload = function(event) {
                           const res = event.target.result;
                           const base64String = res.toString().split('base64,')[1];
                           resolve(base64String);
                           return base64String;
                        };
                        const file = this.response;
                        reader.readAsDataURL(file);
                };
                xhr.send();
              });
        }
       
    } catch (error) {
        console.error(error);
    }
};



