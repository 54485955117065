import { Injectable } from '@angular/core';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';

@Injectable()
export class SetPropertyBusiness {
    constructor(
        private propertyInfoDataService: PropertyInformationDataService,
        public http: HttpServiceCall) {
    }

    public GetLoginDetailsByToken(token: string) {
        return this.propertyInfoDataService.GetLoginDetailsByToken(token);
    }

    public GetUserTherapist(userId: Number,successCallback?: (callDesc: string, result: any) => void, errorCallback?: (callDesc: string, result: any) => void){
      this.GetServiceCall(GlobalConst.Host.spaManagement, 'GetUserTherapist', [], successCallback, errorCallback, {id : userId})
    }

    private GetServiceCall(host, callDesc, extraParams, successCallback, errorCallback, uri?) {
      this.http.CallApiWithCallback<any>({
        host: host,
        success: successCallback,
        error: errorCallback,
        callDesc: callDesc,
        method: HttpMethod.Get,
        body: '',
        showError: true,
        extraParams: extraParams,
        uriParams: uri
      });
    }

}
