
import { FormGroup, Validators } from '@angular/forms';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CreateTransaction } from '../create-transcation';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomPackageTypeChanges: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: FormType[], fromType: boolean
): any => {
    try {
        if (FieldGrouping.CustomFormAction) {
            const InventoryTypes = [1, 2, 3, 4];
            const CustomerTypes = [7, 11, 9];
            const SupplierTypes = [6, 10, 12, 8];
            const ProductTypes = [13, 14, 15, 16];
            const RecipeTypes = [17, 18, 19, 20];
            const TypeId = FormGroupData.value.TypeId;
            switch (TypeId) {
                case 1:
                    const Filters = [{
                        'FilterDisplayName': 'Type',
                        'FilterOdataKey': 'IngredientTypes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/IngredientTypes',
                            'CustomDomainName': 'IngredientTypes'
                        }
                    },
                    {
                        'FilterDisplayName': 'Class',
                        'FilterOdataKey': 'Classes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/Classes',
                            'CustomDomainName': 'Classes'
                        }
                    }];
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.forEach(res => {
                        if (InventoryTypes.includes(res.TypeId)) {
                            res.Visible = true;
                        } else {
                            res.Visible = false;
                        }
                        res.DefaultSelect = false;
                    });
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[0].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[0].
                    OptionGroup[0].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFilter = Filters;
                    break;
                case 5:
                    const CustomerFilters = [{
                        'FilterDisplayName': 'Type',
                        'FilterOdataKey': 'CustomerTypes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/CustomerTypes',
                            'CustomDomainName': 'CustomerTypes'
                        }
                    }];
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.forEach(res => {
                        if (CustomerTypes.includes(res.TypeId)) {
                            res.Visible = true;
                        } else {
                            res.Visible = false;
                        }
                        res.DefaultSelect = false;
                    });
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[2].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[2].
                    OptionGroup[0].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFilter = CustomerFilters;
                    break;
                case 6:
                    const SuppplierFilters = [{
                        'FilterDisplayName': 'Type',
                        'FilterOdataKey': 'SupplierTypes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/SupplierTypes',
                            'CustomDomainName': 'SupplierTypes'
                        }
                    },
                    {
                        'FilterDisplayName': 'Class',
                        'FilterOdataKey': 'Classes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/Classes',
                            'CustomDomainName': 'Classes'
                        }
                    }];
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.forEach(res => {
                        if (SupplierTypes.includes(res.TypeId)) {
                            res.Visible = true;
                        } else {
                            res.Visible = false;
                        }
                        res.DefaultSelect = false;
                    });
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[1].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[1].
                    OptionGroup[0].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFilter = SuppplierFilters;
                    break;
                case 7:
                    const ProductFilters = [{
                        'FilterDisplayName': 'Type',
                        'FilterOdataKey': 'ProductTypes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/ProductTypes',
                            'CustomDomainName': 'ProductTypes'
                        }
                    },
                    {
                        'FilterDisplayName': 'Class',
                        'FilterOdataKey': 'Classes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/Classes',
                            'CustomDomainName': 'Classes'
                        }
                    }];
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.forEach(res => {
                        if (ProductTypes.includes(res.TypeId)) {
                            res.Visible = true;
                        } else {
                            res.Visible = false;
                        }
                        res.DefaultSelect = false;
                    });
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[3].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[3].
                    OptionGroup[0].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFilter = ProductFilters;
                    break;
                case 2:
                    const RecipeFilters = [{
                        'FilterDisplayName': 'Type',
                        'FilterOdataKey': 'RecipeTypes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/RecipeTypes',
                            'CustomDomainName': 'RecipeTypes'
                        }
                    },
                    {
                        'FilterDisplayName': 'Class',
                        'FilterOdataKey': 'Classes',
                        'CustomMapping': {
                            'CustomKey': 'Id',
                            'CustomLabel': 'Name'
                        },
                        'CustomFilters': {
                            'EnableCustomFilter': true,
                            'CustomNavigationUrl': '/inventory/api/Classes',
                            'CustomDomainName': 'Classes'
                        }
                    }];
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.forEach(res => {
                        if (RecipeTypes.includes(res.TypeId)) {
                            res.Visible = true;
                        } else {
                            res.Visible = false;
                        }
                        res.DefaultSelect = false;
                    });
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[4].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType[4].
                    OptionGroup[0].DefaultSelect = true;
                    MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFilter = RecipeFilters;
                    break;
                
            }
            MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFilter =
                CreateTransaction.ItemListGridFilterOption(
                    ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, null);
            if (fromType !== true) {
                MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true);
            }
        }
    } catch (error) {
        console.log('error occured CustomDefaultSelect', error);
    }
};