import { routeJSON } from "src/app/routeJson";

declare let $: any;
export class MsalConfiguration {
    AppId: string;
    RedirectUri: string;
    Scopes: string[];
  }
declare global {

    export const MsalConfig: MsalConfiguration;
    export const RouteJsonConfig: typeof Object;
    export interface Window {
        MsalConfig: MsalConfiguration;
        RouteJsonConfig : typeof Object;
    }
}

Window.prototype.MsalConfig = loadMsalConfig();

function loadMsalConfig() {
    let msalConfig: MsalConfiguration;
    msalConfig = readMsalConfig();
    return msalConfig;
}

function readMsalConfig() {
    let msalConfig: MsalConfiguration;
    const spaHostUrl = 'assets/json/hosts/spa-hosts.json';
    const hostLoadErrMsg = 'Error in loading spa-hosts.json - ' + spaHostUrl;
    $.ajax({
        url: spaHostUrl,
        async: false,
        success: function (result) {
            try {
                if (typeof result == 'object') {
                    msalConfig = result.MsalConfiguration;
                    msalConfig.RedirectUri = window.location.origin;
                }
                else {
                    console.error(hostLoadErrMsg);
                }
            } catch (e) { console.error(hostLoadErrMsg); }
        },
        error: function (error) {
            console.error(hostLoadErrMsg + ' Exception: ' + error);
        }
    });
    return msalConfig;
}
function loadRouteJson() {
    let routes;    
    let pv = localStorage.getItem('userProductVersion');
    // $.ajax({
    //     url: './assets/route.json?v='+pv,
    //     async: false,
    //     success: function (result) {
    //         routes = result;
    //     },
    //     error: function(result){
    //         console.error(result);
    //     }
    // });
    routes = routeJSON;
    return routes;
}
Window.prototype.RouteJsonConfig = loadRouteJson();