import { Injectable } from '@angular/core';
import { CommonApiRoutes } from '../common-route';
import { TenantManagementCommunication } from '../communication/services/tenantmanagement-communication-service';
import { API, UI } from "../Models/user-roles-model";
import { DropdownOptions } from '../Models/ag-models';
import { Product } from '../enums/shared-enums';
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";

@Injectable()
export class UserRoleDataService {

    constructor(private _tenantManagementCommunication: TenantManagementCommunication,private utils : CommonUtilities) {

    }

    public async getActiveuserRoles(): Promise<API.RoleSetup[]> {
        let propertyId: number = Number(this.utils.GetPropertyInfo('PropertyId'));
        propertyId = propertyId ? propertyId : 1;
        var roles = await this._tenantManagementCommunication.getPromise<API.RoleSetup[]>({
            route: CommonApiRoutes.GetActiveUserRolesByPropertyId,
            uriParams: { propertyId: propertyId  , includeInActive : false }
        });

        return roles;
    }

    public async getAllUserId(): Promise<any> {
        let propertyId: number = Number(this.utils.GetPropertyInfo('PropertyId'));
        propertyId = propertyId ? propertyId : 1;
        let productId = Number(this.utils.GetPropertyInfo("ProductId"));

        var data = await this._tenantManagementCommunication.getPromise<any>({
            route: CommonApiRoutes.GetAllUserbyPropertyId,
            uriParams: { propertyId: propertyId  , productId : productId }
        });

        return data;
    }
}