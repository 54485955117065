import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { searchtitleenum, GlobalSearchModel, GlobalSearchData } from './global-search.model';
import { Router } from '@angular/router';
import { Localization } from '../../localization/localization';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})

export class GlobalSearchComponent implements OnInit, AfterViewInit {

  filterData: any = []; // local filter array
  searchGroupOptions: Promise<GlobalSearchModel[]>;
  globalSearchForm: UntypedFormGroup;
  @Input() open = false;
  @Output() onSearch = new EventEmitter();
  @Output() OnOptionSelected = new EventEmitter();
  titleEnum = searchtitleenum;
  @ViewChild("searchText") searchText: ElementRef;
  captions: any;
  floatLabel: string;
  floatLabelNever: string;

  constructor(private _formBuilder: UntypedFormBuilder
    , private _router: Router, private localization: Localization
  ) {
    this.captions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.globalSearchForm = this._formBuilder.group({
      searchGroup: '',
    });

  }

  ngAfterViewInit() {
    if (this.searchText && this.searchText.nativeElement)
      this.searchText.nativeElement.focus();
  }

  public async filterGroup(value: string) {
    this.filterData = [];
    value = value ? value.toString() : "";
    value = value.trim();
    if (value.length >= 3) {
      this.searchGroupOptions = this.globalSearch(value);

    } else if (value.length <= 2) {
      this.filterData = [];
      this.searchGroupOptions = Promise.resolve([]);
    }
  }

  onInput(e) {
    const inputValue = e.target.value;
    if (inputValue.length > 3) {
      console.log(e.target.value);
    }
    this.onSearch.emit();
  }

  addPlayer(e) {    
    this._router.navigate([`login`]);    
  }

  filter = (opt: any[], value: string): any[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(item => {
      for (let key in item) {
        if (key == searchtitleenum.booking || key == searchtitleenum.settings || key == searchtitleenum.sales) {
          const returnValue = (item[key].toLowerCase().indexOf(filterValue) === 0);
          if (returnValue) {
            return returnValue;
          }
        }
      }
    });
  };

  linkClicked(title: string, data: GlobalSearchData, e) {
    // Random number - To refresh the page everytime
    
    switch (title) {
      case searchtitleenum.booking:
        this._router.navigate([`booking`]);
        break;
      case searchtitleenum.sales:
        this._router.navigate([`sales`]);
        break;
      case searchtitleenum.settings:
        this._router.navigate([`settings`]);
        break;
      default:

        break;
    }
    this.searchGroupOptions = Promise.resolve([]);
    this.OnOptionSelected.emit();
  }

  valueMapper() {
    return '';
  }


  async globalSearch(pattern: string): Promise<any[]> {
    return [
      { "title": "booking", "dataCollection": [{ "id": 1, "value": "booking 1" }, { "id": 2, "value": "booking 2" }] },
      { "title": "settings", "dataCollection": [{ "id": 1, "value": "settings 1" }] },
      { "title": "sales", "dataCollection": [{ "id": 1, "value": "Sales 1" }, { "id": 2, "value": "Sales 2" }] }
    ];    
  }


}










