import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer,UntypedFormControl} from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ReportBusinessService } from 'src/app/report/business/report-business.service';
import { ReportDataService } from 'src/app/report/data/report-data.services';
import { DropDownData,ClientDetailForReport } from '../../../business/report.modals';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { HttpMethod,HttpServiceCall} from 'src/app/retail/shared/service/http-call.service';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { Host,clientSearchType } from 'src/app/shared/globalsContant';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { BaseResponse } from 'src/app/shared/business/shared.modals';
@Component({
  selector: 'app-LockerUtilization',
  templateUrl: './Locker-Utilization.component.html',
  styleUrls: ['./Locker-Utilization.component.scss']
})
export class LockerUtilizationComponent implements OnInit {
  LockerUtilizationFrmGrp: UntypedFormGroup;
  captions: any;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();
  serviceData : DropDownData[] = [];
  service : any[] = [];
  lockerType : any[] = [];
  LockerInforResult: any[];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  lockerData : DropDownData[] = [];
  services: DropDownData[] = [];
  lockerTypes: DropDownData[] = [];
  Clients: any[] = [];
  SearchedClients: any[] = [];
  selectedClient = [''];
  requestUid = '';
  showMissingClientError: boolean;
  constructor(private fb: UntypedFormBuilder, private http: HttpServiceCall, public business: ReportBusinessService,private dataService: ReportDataService,public localization: SpaLocalization,private utils: SpaUtilities) {
    this.captions = this.localization.captions.reports;
  }

  ngOnInit() {
    this.LockerUtilizationFrmGrp = this.fb.group({
      services: new UntypedFormControl([this.defaultData]),
      lockerTypes: new UntypedFormControl([this.defaultData]),
      clientTobeSelected: [''],
    });
    this.onLoad();
    this.formReady.emit(this.LockerUtilizationFrmGrp);
  }
  private async onLoad() {
    this.service = await this.dataService.getServices();
    this.lockerType = await this.getAllLockerInformation(true);
    this.serviceData = this.service.map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    this.lockerData = this.lockerType.map(s => {
      return {
          id: s.id,
          description: s.name,
          showInDropDown: true
      }
    });
    this.services = this.serviceData;
    this.lockerTypes = this.lockerData;
    this.business.FilterDataSource["services"] = this.service;
    this.business.FilterDataSource["lockerTypes"] = this.lockerType;
  }
  getFormControlValue(event) 
  {if (event[0] === 'services') {
       this.LockerUtilizationFrmGrp.setControl('services', event[1]);
       } 
       if (event[0] === 'lockerTypes') {
        this.LockerUtilizationFrmGrp.setControl('lockerTypes', event[1]);
        } 
  }
  async getAllLockerInformation(includeInactive: any): Promise<any[]> {
    try {
      this.LockerInforResult = [];
      let routeForIncludeInactive = RetailRoutes.GetLockersInformation;
      const response = await this.InvokeServiceCallAsync(routeForIncludeInactive, GlobalConst.Host.retailManagement, HttpMethod.Get, { includeInactive: includeInactive });
      this.LockerInforResult = response.result;
      return response.result;
    } catch (error) {
      console.error("error while fetching Lock information" + error);
    }
  }async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParam?: any, queryParams?: any, body?: any): Promise<any> {
    try {
      return await this.http.CallApiAsync({
      host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParam,
        queryString: queryParams
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  searchClient(clientName?: string) {
    let clientSearchName: string = this.LockerUtilizationFrmGrp.controls['clientTobeSelected'].value;
    if (clientSearchName) {
      this.http.CallApiWithCallback<any>({
        host: GlobalConst.Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: 'clientSearch',
        method: HttpMethod.Get,
        uriParams: { name: encodeURIComponent(clientSearchName),searchType:clientSearchType.All, requestUid: this.requestUid },
        showError: false,
        extraParams: []
      });
    }
    else {
      this.Clients = [];
      this.selectedClient =[];
    }
  }
  clientSelected(client:['']) {
    this.showMissingClientError = false;
    this.selectedClient = client;
    this.business.FilterDataSource["selectedClient"] = this.selectedClient;
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    const _result: any[] = <any>result.result;
    var responseUid = "";
    if (callDesc == 'clientSearch') {
      const client: ClientDetailForReport[] = _result as ClientDetailForReport[];
      if (client != null) {
        if (client.length > 0)
          responseUid = client[0].requestUid;

        if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
          this.Clients = client.map(C => {
            return {
              name: `${C.clientDetail.firstName} ${C.clientDetail.lastName}`,
              id: C.id,
              guestId: C.clientDetail.guestId
            };
          })
        }
      }
      if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
        this.Clients.forEach(res => { this.SearchedClients.push(res); });
      }
    }
  }
}