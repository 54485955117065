import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'customDropdownPipe'
})
export class CustomDropdownPipePipe implements PipeTransform {

  transform(value: any, position: number, selectedTherapistId: any, listOfselectedTherapist: any, id: any): any {
    if (!value) { return null; }
    return value.filter(x => {
      return (x[id] == selectedTherapistId || !_.some(listOfselectedTherapist, { 'therapist': x[id].toString() }));
    });
  }

}
