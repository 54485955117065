
import { Injectable } from '@angular/core';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Localization } from 'src/app/common/shared/localization/Localization';

@Injectable()
export class sourceSearchCriteriaBusiness {
    captions: any;

  constructor(private localization: Localization) {
        this.captions = this.localization.captions;
    }


    getTableOptions() {
        return {
            defaultsortingColoumnKey: 'firstName',
            defaultSortOrder: SorTypeEnum.asc,
            showTotalRecords: false,
            columnFreeze: {
                firstColumn: true,
                lastColumn: false
            },
            isHeaderCheckboxAllowed: true,
            checkboxKey: 'checked',
        };
    }
    tabledataGeneration() {
        return []        
    }
    
    headerGenerator() {
        return [
            {
                key: 'checked',
                displayName: '',
                hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
                templateName: ActionTypeEnum.checkbox
            },
            {
      displayNameId: 'tbl_hdr_lastName',
                displayName: this.captions.tbl_hdr_lastName,
                key: 'lastname',
                searchable: false,
                sorting: true,
                sortingKey: 'lastname',
            },
            {
      displayNameId: 'tbl_hdr_firstName',
                displayName: this.captions.tbl_hdr_firstName,
                key: 'firstname',
                searchable: false,
                sorting: true,
                sortingKey: 'firstname',
            },
            {
      displayNameId: 'tbl_hdr_title',
                displayName: this.captions.tbl_hdr_title,
                key: 'title',
                searchable: false,
                sorting: true,
                sortingKey: 'title',
            },            
            {
      displayNameId: 'tbl_hdr_address',
                displayName: this.captions.tbl_hdr_address,
                key: 'address',
                searchable: false,
                sorting: true,
                sortingKey: 'address',
            },
            {
      displayNameId: 'tbl_hdr_postalCode',
                displayName: this.captions.tbl_hdr_postalCode,
                key: 'postalcode',
                searchable: false,
                sorting: true,
                sortingKey: 'postalcode',
            },
            {
      displayNameId: 'tbl_hdr_city',
                displayName: this.captions.lbl_city,
                key: 'city',
                searchable: false,
                sorting: true,
                sortingKey: 'city',
            },           
            {
      displayNameId: 'lbl_phoneNumber',
                displayName: this.captions.lbl_phoneNumber,
                key: 'phonenumber',
                searchable: false,
                sorting: true,
                sortingKey: 'phonenumber',
            },
            {
      displayNameId: 'lbl_emailID',
                displayName: this.captions.lbl_emailID,
                key: 'email',
                searchable: false,
                sorting: true,
                sortingKey: 'email',
            },  
      
            {
      displayNameId: 'tbl_hdr_state',
                displayName: this.captions.tbl_hdr_state,
                key: 'state',
                searchable: false,
                sorting: true,
                sortingKey: 'state',
            },
            {
      displayNameId: 'lbl_country',
                displayName: this.captions.lbl_country,
                key: 'country',
                searchable: false,
                sorting: true,
                sortingKey: 'country',
            },            
            {
      displayNameId: 'lbl_patronId',
                displayName: this.captions.lbl_patronId,
                key: 'patronid',
                searchable: false,
                sorting: true,
                sortingKey: 'patronid',
            },            
            {
      displayNameId: 'tbl_hdr_licensePlateNumber',
                displayName: this.captions.tbl_hdr_licensePlateNumber,
                key: 'licensePlateNumber',
                searchable: false,
                sorting: true,
                sortingKey: 'licensePlateNumber',
            }
        ]
    }
}
