import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';


@Injectable()
export class ReportService {

  searchPath: string = "/assets/transaction-log.json";
  constructor(public http:Http) { }

  getTransactionLog() {
    return this.http.get(this.searchPath).pipe(map(response => response.json()));
  }


}
