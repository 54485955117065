import { Injectable, OnInit } from '@angular/core';
import { MenuTreeView } from 'src/app/eatecui/source/core/models/navigation-model';
import { select, Store } from '@ngrx/store';
import { treTiaryTreeMenuSucess } from 'src/app/eatecui/source/store/selectors/navigation-menu.selector';
import { verifyUserFailure, verifyUserSuccess, GetClientIdSuccess, GetClientIdFailure, GetTokenSuccess, GetTokenFailure,
  GetSSOClientIdSuccess, GetSSOClientIdFailure, GetSSOTokenSuccess, GetSSOTokenFailure } from 'src/app/eatecui/source/store/selectors/login.selector';
import * as navigationMenu from 'src/app/eatecui/source/store/actions/navigation-menu.action';
import * as UserAction from 'src/app/eatecui/source/store/actions/login.action';
import * as TranactionAction from 'src/app/eatecui/source/store/actions/transaction.action';
import * as GlobalStateAction from 'src/app/eatecui/source/store/actions/global-state.action';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { AuthClient, User } from 'src/app/eatecui/source/core/models/user-auth-model';
import {SSOSignIn} from '../../sso/models/SSOSignIn';
import { GetAccessToken, GetOnPrem, GetTenant, IsLoggedIn } from 'src/app/eatecui/source/store/selectors/global-state.selector';
import { enableManagementData, enableTransction, InventoryManagementGetDataSuccess, TranasctionSucess } from 'src/app/eatecui/source/store/selectors/transaction.selector';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

@Injectable({
    providedIn: 'root',
  })

  export class CoreFacadeService {
      constructor(public store: Store<AppState>) {}
/**
 * @method dispatchTretiaryMenu()
 * @input parmas none
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchTretiaryMenu(TretiaryObject: MenuTreeView[]) {
    try {
        this.store.dispatch(new navigationMenu.GetTretiaryTreeView(TretiaryObject));
    } catch (error) {
        console.log(error);
    }
}
/**
 * @method getTretiaryTreemenu()
 * @param None;
 * @Params Output MenuTreeView[];
 * @description get tretiary menu item data from navgation sucess prperity
 */
      getTretiaryTreemenu() {
          try {
            return this.store.pipe(select(treTiaryTreeMenuSucess));
          } catch (error) {
            console.log(error);
          }
      }

      /**
 * @method dispatchUserLogin()
 * @input parmas none
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchUserLogin(UserInfo: User) {
    try {
        this.store.dispatch(new UserAction.GetUserLogin(UserInfo));
    } catch (error) {
        console.log(error);
    }
}

dispatchSSOLogin(UserInfo: SSOSignIn) {
  try {
      this.store.dispatch(new UserAction.GetSSOClientId(UserInfo));
  } catch (error) {
      console.log(error);
  }
}

/**
 * @method getVerifiedSuccess()
 * @param None;
 * @Params Output MenuTreeView[];
 * @description get the username and password verified
 */
getVerifiedSuccess() {
    try {
      return this.store.pipe(select(verifyUserSuccess));
    } catch (error) {
      console.log(error);
    }
}

/**
 * @method getTretiaryTreemenu()
 * @param None;
 * @Params Output MenuTreeView[];
 * @description get tretiary menu item data from navgation sucess prperity
 */
getVerifiedFailure() {
    try {
      return this.store.pipe(select(verifyUserFailure));
    } catch (error) {
      console.log(error);
    }
}

/**
 * @method dispatchUserLogin()
 * @input parmas none
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchVerifiedFailure() {
    try {
        this.store.dispatch(new UserAction.GetUserLoginFailure(null));
    } catch (error) {
        console.log(error);
    }
}
     /**
 * @method dispatchUserLogin()
 * @input parmas none
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchGetClientId(authClient: AuthClient) {
    try {
        this.store.dispatch(new UserAction.GetClientId(authClient));
    } catch (error) {
        console.log(error);
    }
}
/**
 * @method getVerifiedSuccess()
 * @param None;
 * @Params Output MenuTreeView[];
 * @description get the username and password verified
 */
getClientIdSuccess() {
    try {
      return this.store.pipe(select(GetClientIdSuccess));
    } catch (error) {
      console.log(error);
    }
}


getSSOClientIdSuccess() {
  try {
    return this.store.pipe(select(GetSSOClientIdSuccess));
  } catch (error) {
    console.log(error);
  }
}

/**
 * @method getTretiaryTreemenu()
 * @param None;
 * @Params Output MenuTreeView[];
 * @description get tretiary menu item data from navgation sucess prperity
 */
getClientIdFailure() {
    try {
      return this.store.pipe(select(GetClientIdFailure));
    } catch (error) {
      console.log(error);
    }
}

getSSOClientIdFailure() {
  try {
    return this.store.pipe(select(GetSSOClientIdFailure));
  } catch (error) {
    console.log(error);
  }
}
     /**
 * @method dispatchUserLogin()
 * @input parmas none
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchGetToken(ClientToken: string) {
    try {
        const payload = {
            ClientToken: ClientToken
        };
        this.store.dispatch(new UserAction.GetToken(payload));
    } catch (error) {
        console.log(error);
    }
}

dispatchGetSSOToken(tokenRequest: any) {
  try {
      const payload = tokenRequest;
      this.store.dispatch(new UserAction.GetSSOToken(payload));
  } catch (error) {
      console.log(error);
  }
}

/**
 * @method getVerifiedSuccess()
 * @param None;
 * @Params Output MenuTreeView[];
 * @description get the username and password verified
 */
getTokenSuccess() {
    try {
      return this.store.pipe(select(GetTokenSuccess));
    } catch (error) {
      console.log(error);
    }
}

/**
 * @method getTretiaryTreemenu()
 * @param None;
 * @Params Output MenuTreeView[];
 * @description get tretiary menu item data from navgation sucess prperity
 */
getTokenFailure() {
    try {
      return this.store.pipe(select(GetTokenFailure));
    } catch (error) {
      console.log(error);
    }
}

getSSOTokenSuccess() {
  try {
    return this.store.pipe(select(GetSSOTokenSuccess));
  } catch (error) {
    console.log(error);
  }
}

getSSOTokenFailure() {
  try {
    return this.store.pipe(select(GetSSOTokenFailure));
  } catch (error) {
    console.log(error);
  }
}


/**
 * @method dispatchEnableTranction()
 * @input parmas none
 * @output parmas None
 * @description Get dispatch from the data
 */
 dispatchEnableTranction(dispathValue: boolean) {
  try {
      this.store.dispatch(new TranactionAction.EnableTranction(dispathValue));
  } catch (error) {
      console.log(error);
  }
}

/**
 * @method dispatchGetTranction()
 * @input parmas none
 * @output parmas None
 * @description Get dispatch from the data
 */
 dispatchGetTranction(dispathValue: TransactionConfiguration) {
  try {
      this.store.dispatch(new TranactionAction.GetTransaction( dispathValue ));
  } catch (error) {
      console.log(error);
  }
}
getTranctionEnable() {
  try {
    return this.store.pipe(select(enableTransction));
  } catch (error) {
    console.log(error);
  }
}
getTranction() {
  try {
    return this.store.pipe(select(TranasctionSucess));
  } catch (error) {
    console.log(error);
  }
}

/**
 * @method dispatchEnableMaster()
 * @param dispathValue;
 */
  dispatchEnableMaster(dispathValue: boolean) {
    try {
      this.store.dispatch(new TranactionAction.EnableMasterManagement(dispathValue));
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @method dispatchGetInventoryMaster()
   * @input parmas none
   * @output parmas None
   * @description Get dispatch from the data
   */
  dispatchGetInventoryMaster(dispathValue: MasterInventoryManagement) {
    try {
      this.store.dispatch(new TranactionAction.GetMasterManagement(dispathValue));
    } catch (error) {
      console.log(error);
    }
  }

getMasterEnable() {
  try {
    return this.store.pipe(select(enableManagementData));
  } catch (error) {
    console.log(error);
  }
}

getMasterData() {
  try {
    return this.store.pipe(select(InventoryManagementGetDataSuccess));
  } catch (error) {
    console.error('Error occurred in get Master Data', error);
  }
}
/**
 * @method dispatchGetAccessToken()
 * @input AccessToken
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchAccessToken(AccessToken: string) {
  try {
      this.store.dispatch(new GlobalStateAction.GetAccessToken(AccessToken));
  } catch (error) {
      console.log(error);
  }
}

/**
 * @method dispatchLoggedIn()
 * @input AccessToken
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchLoggedIn(IsloggedIn: boolean) {
  try {
      this.store.dispatch(new GlobalStateAction.GetIsLoggedIn(IsloggedIn));
  } catch (error) {
      console.log(error);
  }
}

/**
 * @method GetAccessToken()
 * @output AccessToken
 * @description Get dispatch from the data
 */
GetAccessToken() {
  try {
    return this.store.pipe(select(GetAccessToken));
  } catch (error) {
    console.log(error);
  }
}

/**
 * @method dispatchUserLogin()
 * @output IsLoggedIn
 * @description Get dispatch from the data
 */
GetIsLoggedIn() {
  try {
    return this.store.pipe(select(IsLoggedIn));
  } catch (error) {
    console.log(error);
  }
}

/**
 * @method dispatchTenant()
 * @input Tenant
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchTenant(Tenant: string) {
  try {
      this.store.dispatch(new GlobalStateAction.GetTenant(Tenant));
  } catch (error) {
      console.log(error);
  }
}

/**
 * @method GetTenant()
 * @output Tenant
 * @description Get dispatch from the data
 */
GetTenant() {
  try {
    return this.store.pipe(select(GetTenant));
  } catch (error) {
    console.log(error);
  }
}

/**
 * @method dispatchOnPrem()
 * @input OnPrem
 * @output parmas None
 * @description Get dispatch from the data
 */
dispatchOnPrem(OnPrem: boolean) {
  try {
      this.store.dispatch(new GlobalStateAction.GetOnPrem(OnPrem));
  } catch (error) {
      console.log(error);
  }
}

/**
 * @method GetOnPrem()
 * @output OnPrem
 * @description Get dispatch from the data
 */
GetOnPrem() {
  try {
    return this.store.pipe(select(GetOnPrem));
  } catch (error) {
    console.log(error);
  }
}
}

