import { GridSearchAction } from '../app-grid.interface';
import { SearchPlaceholder } from '../grid.interface';

export const GetSearchPlaceholder: SearchPlaceholder = (SearchKeys: GridSearchAction[]): string => {
    try {
        let searchText = 'Search by ';
        if (SearchKeys.length > 0 ) {
            const searchKeys = SearchKeys.map( x => x.SearchPlaceholderName.toLowerCase());
            searchText = searchText + searchKeys.join(', ');
            return searchText;
        } else {
            return 'Search';
        }
    } catch (error) {
        console.log('error occured GetSearchPlaceholderText', error);
    }
};
