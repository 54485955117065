import { ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { Localization } from '../../localization/localization';
import { AgDropdownConfig, DropdownOptions } from '../../Models/ag-models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-radio-btn-search',
  templateUrl: './radio-btn-search.component.html',
  styleUrls: ['./radio-btn-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RadioBtnSearchComponent implements OnInit {

  captions: any = this.localization.captions;
  radiosearchBy: radioselectionInterface;
  radiosearchText: string = '';

  searchPlaceholder: string = '';
  radiomodeOptions: radioselectionInterface[];
  mincharlength: number;
  history: boolean = false;
  isStatusFiltered: boolean = false;
  floatLabel: string;
  floatLabelNever: string;
  statusInput: AgDropdownConfig;
  form: UntypedFormGroup;
  minimumCharacterSearchLength: number = 3;
  staySearchFilterRoomNumber: number = 16;
  @Output() searchSelectionChange = new EventEmitter();
  searchDisabled = false;
  constructor(public localization: Localization, private fb: UntypedFormBuilder,private utilities: CommonUtilities,) {
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }
  @Input('selectionData')
  set radioSelectionData(value: radioSearchSelectionConfig) {
    if (value) {
      this.radiomodeOptions = value.radioOptionsArr;
      this.minimumCharacterSearchLength = this.utilities.minCharSearch();
      let srchId: number = value?.radioOptionsArr?.find(x => x.selected)?.id ?? 0;
      if (srchId > 0 && srchId == this.staySearchFilterRoomNumber) {
        this.mincharlength = 1;
      }
      else {
        this.mincharlength = this.minimumCharacterSearchLength;
      }
      this.mincharlength = (value.minchar != undefined && value != null && value.minchar > -1) ? value.minchar : this.mincharlength;
      
    }
    let finddefault = this.radiomodeOptions.filter(x => x.selected);
    this.radiosearchBy = finddefault.length > 0 ? finddefault[0] : this.radiomodeOptions[0];
    this.searchPlaceholder = this.captions.common.SearchBy + ' ' + this.radiosearchBy?.viewValue;
  }
  @Input('disabled')
  set setDisabled(value: boolean) {
    if (value !== null) {
      this.searchDisabled = value;
    }
  }

  @Input('includeHistory')
  set setIncludeHistory(value) {
    if (value) {
      this.history = true;
    }
  }

  @Input('filterStatus')
  set setisStatusFiltered(value) {
    if (value) {
      this.isStatusFiltered = true;
    }
  }

  @Input('isClearSearchText')
  set setisClearSearchText(value) {
    if (value) {
      this.radiosearchText = "";
    }
  }
  @Output() searchButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() checkboxemit: EventEmitter<any> = new EventEmitter();


  ngOnInit(): void {
    this.form = this.fb.group({
      status: []
    });
    this.statusInput = {
      form: this.form,
      formControlName: 'status',
      placeHolder: this.captions.lbl_status,
      selectOptions: of(this.getAccountStatus()),
      isMultiSelect: true,
      isAll: true
    };

    this.form.controls.status.patchValue(this.getAccountStatus());
  }


  public getAccountStatus(): DropdownOptions[] {
    return [
      { id: 1, value: ARAccountStatus.Permanent, viewValue: this.captions.lbl_permanant, checked: true },
      { id: 2, value: ARAccountStatus.Temporary, viewValue: this.captions.lbl_temperory, checked: true },
      { id: 3, value: ARAccountStatus.Inactive, viewValue: this.captions.lbl_inActive, checked: true },
      { id: 4, value: ARAccountStatus.Hold, viewValue: this.captions.lbl_hold, checked: true },
      { id: 5, value: ARAccountStatus.Expired, viewValue: this.captions.lbl_expired, checked: true },
      { id: 6, value: ARAccountStatus.Frozen, viewValue: this.captions.lbl_frozen, checked: true },
      { id: 7, value: ARAccountStatus.AccessToFacilities, viewValue: this.captions.lbl_AccessToFacilities, checked: true },
      { id: 8, value: ARAccountStatus.Suspended, viewValue: this.captions.lbl_suspended, checked: true }
    ]
  }

  radioChange(eve) {

    this.mincharlength = eve?.value?.minchar ?? this.utilities.minCharSearch();
    let srchlbl = eve.value.viewValue;
    this.searchPlaceholder = this.captions.common.SearchBy + ' ' + srchlbl;
    this.searchSelectionChange.emit(eve.value);
  }
  searchSelectionChangeEmitter(e) {
    this.searchSelectionChange.emit(e);
  }
  action() {
    this.radiosearchText = "";
  }
  includeHistory(eve) {
    this.checkboxemit.emit(eve);
  }
  searchData($event) {
    if (this.radiosearchText.length >= this.mincharlength) {
      let obj: radioSearchSelectionObject = {
        selectedData: this.radiosearchBy,
        searchText: this.radiosearchText,
        selectedStatus: this.form.value.status
      }
      this.searchButtonClicked.emit(obj);
    }
  }
}

export interface radioselectionInterface { id: number; viewValue: string; selected: boolean; minchar?: number; hideOption?: boolean }
export interface radioSearchSelectionObject { selectedData: radioselectionInterface, searchText: string, selectedStatus: any }
export interface radioSearchSelectionConfig { radioOptionsArr: radioselectionInterface[], minchar: number }

export enum ARAccountStatus {
  Permanent = 0,
  Temporary,
  Inactive,
  Hold,
  Expired,
  Frozen,
  AccessToFacilities,
  Suspended
}
