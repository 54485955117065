import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons, FieldArray, OnEventArgs } from '../../form-type.entity';
import { CustomErrorMesssage } from '../../form-validation';

@Component({
    selector: 'lib-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements AfterViewInit, OnInit {
    [x: string]: any;
    // tslint:disable-next-line: no-inferrable-types
    labelPosition: string = 'afte';
    formBuilderIcons: FormIcons;
    @Input() field: any = {};
    @Input() form: FormGroup;
    constructor( public agyDynamicFormService: AgyDynamicFormServices ) {}
    fieldArrayCallback(e: FieldArray): void {
        this.field.fieldType[this.fieldType].data.push(e);
    }
    iconCallback(e: any): void {
        if (e.iconType.type === 'add') {
        this.agyDynamicFormService.addnewRow(e.formArray, e.formObject);
        } else {
            this.agyDynamicFormService.RemoveRow(e.formArray, e.formObject, e.objectIndex);
        }
    }
    eventCallback(index ?: any, argus?: OnEventArgs): void {
        this.agyDynamicFormService.formEventCallback(this.field, index, this.form );
    }
    ngAfterViewInit(): void {
        this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
            this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field );
        });
    }


    ngOnInit(): void {
        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.agyDynamicFormService.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
       };
        this.formRemoveBuilderIcons = {
        type: 'remove',
        label: 'remove',
        maxcount: this.field.maxcount
    };
    }
}