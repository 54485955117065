import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { CompareKey, ErrorType } from '../../constants';
import { Localization } from '../../localization/localization';
import { AgTimeConfig, AgTimeModalConfig } from '../../Models/ag-models';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-ag-modal-time-picker',
  templateUrl: './ag-modal-time-picker.component.html',
  styleUrls: ['./ag-modal-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgModalTimePickerComponent implements OnInit {

  captions: any;
  className: string;
  errorMessage: string;
  floatLabel: string;
  placeHolder: string;
  value: string;
  isInvalid: any;
  customErrorMessage: string;
  isTimeRequired: boolean;
  automationId: string;
  @Output() timeChangeEvent = new EventEmitter();
  timeformat: number;
  inputTimeformat: number;
  timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s(AM|PM|am|pm)$/i;
  patternFormat: string;
  @ViewChild("modalTimepicker") modalTimepicker: NgxMaterialTimepickerComponent;
  @ViewChildren(NgModel) ngModels: QueryList<NgModel>;
  ngModelValue: any;
  ngModelValueInput:any;
  minTime:string;
  @Input('ngModelVal')
  set modalValue(value){
    this.ngModelValue = value;
    this.ngModelValueInput = value;
  }
  @Input() sameTime: boolean=false;
  @Input() name: string;
  // @Input() minTime: string;
  @Input('minTime')
  set minTimeValue(value) {
      this.minTime = value;
      this.validateTimeInputs();
  }
  @Input() maxTime: string;
  @Input() modelProperty: string;
  @Input() disabled: boolean;
  @Input('inputs')
  set inputOptions(value: AgTimeModalConfig) {
    if (value) {
      this.className = value.className ? value.className : '';
      this.floatLabel = this._Localization.setFloatLabel ? this._Localization.setFloatLabel : value.floatLabel;
      this.isTimeRequired = value.isTimeRequired ? value.isTimeRequired : false;
      this.placeHolder = value.placeHolder;
      this.automationId = value.automationId ? value.automationId : '';
      this.errorMessage = value.errorMessage ? value.errorMessage : '';
    }
  }
  constructor(private _Localization: Localization, private utils: CommonUtilities) { 
    this.timeformat = this._Localization.getTimeFormat();
  }

  ngOnInit() {
    this.captions = this._Localization.captions;
    this.timeRegex = (this.timeformat == 12) ? /^(0?[1-9]|1[0-2]):[0-5][0-9]\s(AM|PM|am|pm)$/i : /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    this.patternFormat = (this.timeformat == 12) ? this.captions.ExpectedFormat12 : this.captions.ExpectedFormat24;
  }

  ngAfterViewInit(){
    this.validateTimeInputs();
  }

  timeChanged() {
    this.timeChangeEvent.emit({
      from: this.placeHolder,
      fromProperty: this.modelProperty,
      name: this.name,
      value: this.ngModelValueInput,
      isValid: this.ngModels.find(ngModel => ngModel.name === this.name)? this.ngModels.find(ngModel => ngModel.name === this.name).valid: true
    });
    this.validateTimeInputs();
  }

  validateTimeInputs(){
    let isMinTimeValidated = false;
    let isMaxTimeValidated = false;
    if((this.ngModelValueInput == '' || !this.ngModelValueInput) && this.isTimeRequired){
      this.validateTime(false, ErrorType.required);
    } else if(!this.isPattenValid(this.ngModelValueInput) && (this.ngModelValueInput != '' || this.ngModelValueInput)){
      this.validateTime(false, ErrorType.pattern);
    } else {
      let minTimeValidity = this.utils.ValidateTime(this.minTime, this.ngModelValueInput);
      let maxTimeValidity = this.utils.ValidateTime(this.ngModelValueInput, this.maxTime);
      if(this.sameTime){
        isMinTimeValidated = (minTimeValidity === CompareKey.lesser);
        isMaxTimeValidated = (maxTimeValidity === CompareKey.lesser);
      }
      else{
        isMinTimeValidated = (minTimeValidity === CompareKey.lesser || minTimeValidity === CompareKey.equal);
        isMaxTimeValidated = (maxTimeValidity === CompareKey.lesser || maxTimeValidity === CompareKey.equal);
      }
      if (this.minTime && !this.maxTime) {
        this.validateTime(isMinTimeValidated, ErrorType.invalid);
      } else if (this.maxTime && !this.minTime) {
        this.validateTime(isMaxTimeValidated, ErrorType.invalid);
      } else if (this.minTime && this.maxTime) {
        this.validateTime(isMinTimeValidated && isMaxTimeValidated, ErrorType.invalid);
      } else {
        this.validateTime(true);
      }
    }
  }
  selectedTimeFreeInputChanged(): void {
    this.validateTimeInputs();
    this.timeChanged();
  }


  validateTime(isValid, errortype?: ErrorType) {
    if (isValid && this.modalTimepicker) {
      this.ngModelValue = this.ngModelValueInput;
      this.modalTimepicker.defaultTime = this.ngModelValueInput;
      this.modalTimepicker.updateTime(this.ngModelValueInput);
      const itemNgModel = this.ngModels.find(ngModel => ngModel.name === this.name);
      itemNgModel.control.setErrors(null);
    } else {
      const itemNgModel = this.ngModels.find(ngModel => ngModel.name === this.name);
        if (itemNgModel) {
          switch(errortype){
            case ErrorType.required:
              itemNgModel.control.setErrors(null);
              itemNgModel.control.setErrors({ required: true });
              break;
            case ErrorType.pattern:
              itemNgModel.control.setErrors(null);
              itemNgModel.control.setErrors({ pattern: true });
              break;
            case ErrorType.invalid:
              itemNgModel.control.setErrors(null);
              itemNgModel.control.setErrors({ invalid: true });
              break;
            default:
              itemNgModel.control.setErrors(null);
              break;
          }
        }
    }
  }

  updateTime(event: string): void {
    this.ngModelValue = event;
    this.ngModelValueInput = event;
    this.timeChanged();
  }

  isPattenValid(value){
    return this.timeRegex.test(value);
  }

}
