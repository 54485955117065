import { Injectable } from "@angular/core";
import {
  HttpMethod,
  HttpServiceCall,
  KeyValuePair,
} from "../shared/service/http-call.service";
import * as GlobalConst from "../shared/globalsContant";
import { SpaLocalization } from "../core/localization/spa-localization";
import { SpaUtilities } from "../shared/utilities/spa-utilities";
import { ClassClientDeposit, ClassClientModel, ClassCommonModel } from "./class.model";
import { BaseResponse } from "../shared/shared.modal";
import { NotifyPopupComponent } from "../shared/appointment-actions/notify-popup/notify-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { AppointmentActionsDialogComponent } from "../shared/appointment-actions/appointment-actions-dialog/appointment-actions-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ClassService {
  classSetup;
  classGroups;
  locations;
  instructors;
  classInstructor;
  captions: any;
  isNoShowTrack: boolean = false;

  constructor(
    public localization: SpaLocalization,
    public utilities: SpaUtilities,
      public http: HttpServiceCall,
      private dialog: MatDialog,
  ) { 
    this.captions = this.localization.captions;
  }

  public async GetAllClass() {
    var querystring: KeyValuePair = { key: "isClass", value: [true] };
    const response = await this.invokeServiceCall(
      GlobalConst.Host.spaManagement,
      HttpMethod.Get,
      null,
      null,
      querystring,
      "GetAllSpaServiceByUserId"
    );
    this.classSetup = response.result;
  }

  public async GetAllLocation() {
    var querystring: KeyValuePair = { key: "isClassLocation", value: [true] };
    const response = await this.invokeServiceCall(
      GlobalConst.Host.spaManagement,
      HttpMethod.Get,
      null,
      null,
      querystring,
      "GetAllLocByUserId"
    );
    this.locations = response.result;
  }

  public async GetAllInstructor() {
    var querystring: KeyValuePair = { key: "isInstructor", value: [true] };
    const response = await this.invokeServiceCall(
      GlobalConst.Host.spaManagement,
      HttpMethod.Get,
      null,
      null,
      querystring,
      "AccesibleTherapist"
    );

    let res = <any>response.result;
    res = res ? res : [];
    res = this.utilities.sortObj(res, "listOrder");
    this.instructors = response.result;
  }

  public async GetAllClassGroup() {
    var querystring: KeyValuePair = { key: "isClassGroup", value: [true] };
    const response = await this.invokeServiceCall(
      GlobalConst.Host.spaManagement,
      HttpMethod.Get,
      null,
      null,
      querystring,
      "GetAllServiceGrpByUserId"
    );
    this.classGroups = response.result;
  }

  public async GetAllClassInstructor() {
    const response = await this.invokeServiceCall(
      GlobalConst.Host.spaManagement,
      HttpMethod.Get,
      null,
      null,
      null,
      "GetServiceTherapists"
    );
    this.classInstructor = response.result;
  }

  public async checkInClassClient(
    classAppointmentId: number,
    classClientIds: number[]
  ): Promise<BaseResponse<boolean>> {
    var querystring: KeyValuePair = { key: "trackNoShow", value: [this.isNoShowTrack] };
    return await this.invokeServiceCall(
      GlobalConst.Host.schedule,
      HttpMethod.Put,
      classClientIds,
      { id: classAppointmentId},
      querystring,
      ClassCommonModel.routeConfig.checkInClassClient
    );
  }

  public async undoCheckInClassClient(
    classAppointmentId: number,
    classClientIds: number[]
  ): Promise<BaseResponse<boolean>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.schedule,
      HttpMethod.Put,
      classClientIds,
      { id: classAppointmentId },
      null,
      ClassCommonModel.routeConfig.undoCheckInClassClient
    );
  }

  public async cancelTransaction(
    transactionId: number
  ): Promise<BaseResponse<number>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.retailPOS,
      HttpMethod.Delete,
      null,
      { transactionId: transactionId },
      null,
      'CancelTransaction'
    );
  }

  public async cancelClassClient(
    classAppointmentId: number,
    cancelRequest: ClassClientModel.ICancelClient[]
  ): Promise<BaseResponse<ClassClientModel.IClassClientCancelResponse[]>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.schedule,
      HttpMethod.Put,
      cancelRequest,
      { id: classAppointmentId },
      null,
      ClassCommonModel.routeConfig.cancelClientRequest
    );
  }

  public async deleteClassClient(
    classAppointmentId: number,
    classClientIds: number[]
  ): Promise<BaseResponse<boolean>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.schedule,
      HttpMethod.Put,
      classClientIds,
      { id: classAppointmentId },
      null,
      ClassCommonModel.routeConfig.deleteClassClient
    );
  }

  public async GetClassDepositDetails(
    classClientIds: number[]
  ): Promise<BaseResponse<ClassClientDeposit.IClassAppointmentDetails[]>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.schedule,
      HttpMethod.Put,
      classClientIds,
      null,
      null,
      ClassCommonModel.routeConfig.getClassClientDetails
    );
  }


  public async getRetailItemsDetailedInfoByIds(retailItemIds: number[]): Promise<BaseResponse<ClassClientDeposit.IRetailItem[]>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.retailManagement,
      HttpMethod.Put,
      retailItemIds,
      null,
      null,
      "GetRetailItemDetailedInfoList"
    );
  }

  async GetGetShopItemByTypeAsync(itemType: number): Promise<BaseResponse<ClassClientDeposit.IRetailItem[]>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.retailManagement,
      HttpMethod.Get,
      null,
      { "type": itemType },
      null,
      "GetRetailItemByItemType",
    );
  }

  /**
   * common service call method
   * @param host -- GlobalConst.Host
   * @param httpMethod -- HttpMethod
   * @param requestBody - body object
   * @param uriParams -- uri params
   * @param queryString -- uri params
   * @param callDesc -- name of the service call
   * @returns -- BaseResponse<T>
   */
  async invokeServiceCall<T>(
    host: GlobalConst.Host,
    httpMethod: HttpMethod,
    requestBody,
    uriParams,
    queryString: any = null,
    callDesc: string,
    requiredLoader: boolean = true
  ): Promise<Awaited<BaseResponse<T>>> {
    this.utilities.ToggleLoader(requiredLoader);
    const result: BaseResponse<T | unknown> = await this.http
      .CallApiAsync({
        host: host,
        callDesc: callDesc,
        method: httpMethod,
        body: requestBody,
        uriParams: uriParams,
        queryString: queryString,
      })
      .catch(() => {
        let response: BaseResponse<T> = {
          errorCode: 0,
          errorDescription: "",
          successStatus: false,
          propertyId: 0,
          outletId: 0,
          result: null as T,
        };
        return response;
      });
    if (requiredLoader) this.utilities.ToggleLoader(false);

    return <BaseResponse<T>>result;
  }

  public async undoCheckOut(
    classAppointmentId: number,
    clientIds: number[]
  ): Promise<BaseResponse<boolean>> {
    return await this.invokeServiceCall(
      GlobalConst.Host.schedule,
      HttpMethod.Put,
      clientIds,
      { id: classAppointmentId },
      null,
      ClassCommonModel.routeConfig.UndoCheckOutClassClient
    );
  }

  /**
   * open Notify dialog
   */
   OpenNotifyPopup(clientDetail: ClassCommonModel.IClientGridView) {

    let dialogRef = this.dialog.open(AppointmentActionsDialogComponent, {
      height : '50%',
      width : '60%',
      maxWidth: "1000px;",
      disableClose: true,
      hasBackdrop: true,
      data: {
        isFromClass: true,
        closebool: true, 
        id: 24,
      clientDetail: clientDetail,
      headername : this.captions.bookAppointment.notify
      },
      panelClass: "action-dialog-overlay",
    });
    let sub = dialogRef.afterClosed().subscribe((result) => {
    });
  }
}
