import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonVariablesService } from '../../../shared/service/common-variables.service';
import { RetailTransactions, ButtonType, Maxlength } from '../../../shared/globalsContant';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { Subscription } from 'rxjs';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { TicketDetailsService } from './ticket-details.service';
import { GivenCommissionAmountType, CommissionableStaff, PostedCommissions, ModifyPostedCommission } from '../../shop.modals';
import { Commission } from '../../../../retail/retail.modals';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
  providers: [TicketDetailsService]
})
export class TicketDetailsComponent implements OnInit {

  tableoptions: any;
  tableData: any;
  selectedStaffId: any = "Sales Leader";
  commissionvalue: any = "50";
  selectedTicketNumber: string = "";
  ticketDetailsForm: UntypedFormGroup;
  enableSave: boolean = false;
  captions: any = this.localization.captions.shop;
  Subscriptions: Subscription = new Subscription();
  public GivenCommissionAmountTypeEnum: typeof GivenCommissionAmountType = GivenCommissionAmountType;
  commissiontype: GivenCommissionAmountType = this.GivenCommissionAmountTypeEnum.Percentage;
  public commissionableStaff: CommissionableStaff[] = []
  CommissionConfig: Commission;
  TicketMaxLength = Maxlength.PERCENTAGE;
  floatLabel: string;

  constructor(public router: Router, public _shopservice: CommonVariablesService, private fb: UntypedFormBuilder,
    private route: ActivatedRoute, public localization: RetailLocalization, public utils: RetailUtilities, public ticketDetailsService: TicketDetailsService) {
    this.ticketDetailsForm = this.fb.group({
      staffName: ['', Validators.required],
      commissiontype: ['', Validators.required],
      commissionvalue: ['', Validators.required]
    });
    this._shopservice.rowdata = null;
    this.route.params.subscribe(params =>
      this.selectedTicketNumber = params.selectedTicketNumber
    );
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.bindTable();
    this.OnFormChanges();
  }

  ngOnDestroy() {
    this.Subscriptions.unsubscribe();
  }

  OnFormChanges(): void {
    let subscription = this.ticketDetailsForm.valueChanges.subscribe(x => {
      this.enableSave = this.ticketDetailsForm.valid;
    });
    this.Subscriptions.add(subscription);
  }

  async bindTable() {
    this.removeTableData();
    this.tableData = await this.ticketDetailsService.getPostedCommissions(this.selectedTicketNumber);
    setTimeout(() => {
      let header = this.ticketDetailsService.getHeader();
      this.tableoptions = [{
        TableHdrData: header,
        TablebodyData: this.tableData,
        EditMoreOption: false,
        Sortable: 'itemNumber', 
        SelectedSettingId: RetailTransactions.modifypostedcommissionticketdetails,
        CustomColumn: true,
        TableSearchText: '',
        EnableActions: false,
        sticky: true,
        userAction: false,
        disableDelete: true,
        RadiobuttonOption: true,
      }];

      let commissionable = this.tableData.find(x => x.isCommissionable || x.commissionAmount > 0 || x.commissionPercentage > 0);
      if (commissionable) {
        this.ticketDetailsForm.markAsPristine();
        this.radiobuttonClicked(commissionable, true);
      }
    }, 200);
    if (this.tableData.find(x => x.commissionType == this.GivenCommissionAmountTypeEnum.Amount)) {
      this.TicketMaxLength = Maxlength.FLATAMOUNT;
    }
    else if (this.tableData.find(x => x.commissionType == this.GivenCommissionAmountTypeEnum.Percentage)) {
      this.TicketMaxLength = Maxlength.PERCENTAGE;
    }
  }

  GotoTransactionPage() {
    this._shopservice.syncOpenTrasactionList = false;
    this._shopservice.rowdata = null;
    this._shopservice.retailTransaction.tabState = 1;
    this._shopservice.retailTransaction.fromModifyCommission = true;
    this._shopservice.retailTransaction.dropdownState = RetailTransactions.modifypostedcommission;
    this._shopservice.GoToRetailTransaction = true;
    this.router.navigate(['/shop/viewshop/retailtransactions/modifypostedcommission']);
  }

  async radiobuttonClicked($event, onload: boolean = false) {
    if (!this._shopservice.rowdata || (this._shopservice.rowdata && this._shopservice.rowdata.lineNumber != $event.lineNumber)) {
      if (this.ticketDetailsForm.dirty && !onload) {
        this.utils.ShowErrorMessage("Warning", this.captions.EditedDataWontBeSaved, ButtonType.YesNo, this.DiscardEditedCallBack.bind(this), [$event]);
        return;
      }
      await this.rowChange($event);
      var staff = this.commissionableStaff.find(x => x.id == this.selectedStaffId);
      if (staff)
        this.CommissionConfig = await this.ticketDetailsService.getCommission(staff.staffId, this._shopservice.rowdata.itemId, staff.staffType, staff.commissionClass, this._shopservice.ProductId, this._shopservice.rowdata.outletId);
      this.enableSave = false;
    }
  }

  async DiscardEditedCallBack(result: string, params: any[]) {
    if (result.toUpperCase() == "YES") {
      await this.rowChange(params[0]);
      this.enableSave = false;
    }
  }

  async rowChange($event: PostedCommissions) {
    if (!$event) return;

    if (!$event.isCommissionable) {
      return;
    }

    this._shopservice.rowdata = $event;

    this.selectedStaffId = `${this._shopservice.rowdata.staffType[0]}${this._shopservice.rowdata.staffId}`;
    this.commissiontype = this._shopservice.rowdata.commissionType;
    if (this.commissiontype == this.GivenCommissionAmountTypeEnum.Percentage) {
      this.commissionvalue = this._shopservice.rowdata.commissionPercentage;
    }
    else {
      this.commissionvalue = this._shopservice.rowdata.commissionAmount;
    }
    this.commissionvalue = Number(this.commissionvalue) ? Number(this.commissionvalue) : 0;
    this.commissionableStaff = await this.ticketDetailsService.getCommissionableStaffByItem(this._shopservice.rowdata.itemId, this._shopservice.ProductId);
    this.ticketDetailsForm.reset();
    this.ticketDetailsForm.controls.staffName.setValue(this.selectedStaffId);
    this.ticketDetailsForm.controls.commissiontype.setValue(this.commissiontype);
    this.ticketDetailsForm.controls.commissionvalue.setValue(this.localization.localizeCurrency(this.commissionvalue, false));
    this.CommissionConfig = null;
  }

  percentClick() {
    this.commissiontype = this.GivenCommissionAmountTypeEnum.Percentage;
    this.ticketDetailsForm.controls.commissionvalue.setValue(0);
    this.TicketMaxLength = Maxlength.PERCENTAGE;
  }

  amountClick() {
    this.commissiontype = this.GivenCommissionAmountTypeEnum.Amount;
    this.ticketDetailsForm.controls.commissionvalue.setValue(0);
    this.TicketMaxLength = Maxlength.FLATAMOUNT;
  }

  onNoClick() {
    this.ticketDetailsForm.controls.commissionvalue.reset();
    this.ticketDetailsForm.controls.staffName.reset();
    this.GotoTransactionPage();
  }

  async SelectStaffForCommission(selectedStaffId: string) {
    var staff = this.commissionableStaff.find(x => x.id == selectedStaffId);
    this.CommissionConfig = await this.ticketDetailsService.getCommission(staff.staffId, this._shopservice.rowdata.itemId, staff.staffType, staff.commissionClass,this._shopservice.rowdata.outletId, this._shopservice.ProductId);
    if (this.CommissionConfig && this.CommissionConfig.percentage > 0) {
      this.commissiontype = this.GivenCommissionAmountTypeEnum.Percentage;
      this.ticketDetailsForm.controls.commissiontype.setValue(this.GivenCommissionAmountTypeEnum.Percentage);
      this.ticketDetailsForm.controls.commissionvalue.setValue(this.localization.localizeCurrency(this.CommissionConfig.percentage, false));
    }
    else {
      this.commissiontype = this.GivenCommissionAmountTypeEnum.Amount;
      this.ticketDetailsForm.controls.commissiontype.setValue(this.GivenCommissionAmountTypeEnum.Amount);
      this.ticketDetailsForm.controls.commissionvalue.setValue(this.localization.localizeCurrency((this.CommissionConfig ? this.CommissionConfig.flatAmount : 0), false));
    }
    this.selectedStaffId = selectedStaffId;
  }

  async SaveCmmission() {
    this.enableSave = false;
    let data = this._shopservice.rowdata;
    let isCreate: boolean = data.eligibilityId && data.eligibilityId > 0 ? false : true;
    let transaction = this.ticketDetailsService.retailTransactionService.ClosedTransactions.find(x => x.ticketNumber == this.selectedTicketNumber);
    let value = this.localization.currencyToSQLFormat(this.ticketDetailsForm.controls.commissionvalue.value);
    let commissiontype = this.ticketDetailsForm.controls.commissiontype.value;
    var staff = this.commissionableStaff.find(x => x.id == this.selectedStaffId);

    var eligibleAmount: number = (isCreate && this.CommissionConfig && this.CommissionConfig.isAfterDiscount) ? (data.eligibleAmount - data.discount) : data.eligibleAmount;

    var postCommission: ModifyPostedCommission = {
      staffId: staff.staffId,
      staffType: staff.staffType,
      itemId: data.itemId,
      commissionAmount: commissiontype == GivenCommissionAmountType.Amount ? value : 0,
      commissionPercentage: commissiontype == GivenCommissionAmountType.Percentage ? value : 0,
      eligibleAmount: eligibleAmount,
      quantity: data.quantity,
      trandetailId: data.transactionDetailId,
      transactionId: transaction.id,
      staffCommissionEligiblityId: data.eligibilityId ? data.eligibilityId : 0,
      productId: this._shopservice.ProductId,
      commissionClass: staff.commissionClass,
      commissionType: data.postedCommissionType,
      outletId: data.outletId,
      isAfterDiscount: !!(this.CommissionConfig && this.CommissionConfig.isAfterDiscount),
      netEligibleAmount: data.netEligibleAmount,
      netEligibleWithoutDiscount: data.netEligibleWithoutDiscount,
      isItemCommissionOnNet: await this._shopservice.IsItemCommissionOnNet(),
      isRevenueCommissionOnNet: await this._shopservice.IsRevenueCommissionOnNet(),
    }
    await this.ticketDetailsService.modifyOrAddCommission(isCreate, postCommission);
    this._shopservice.rowdata = null;
    await this.bindTable();
    this.ticketDetailsForm.markAsPristine();
    this.CommissionConfig = null;
  }

  removeTableData(): void {

    this.tableoptions = [{
      TableHdrData: this.ticketDetailsService.getHeader(),
      TablebodyData: [],
      EditMoreOption: false,
      Sortable: 'itemNumber', 
      SelectedSettingId: RetailTransactions.modifypostedcommissionticketdetails,
      CustomColumn: true,
      TableSearchText: '',
      EnableActions: false,
      sticky: true,
      userAction: false,
      disableDelete: true,
      RadiobuttonOption: true,
    }];
  }

}
