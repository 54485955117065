import { Pipe, PipeTransform } from '@angular/core';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { ShortMonth} from '../globalsContant';

 
@Pipe({
  name: 'LocalizeDateMonthPipes'
})
export class LocalizeDateMonthPipes implements PipeTransform {
  constructor(public localization: SpaLocalization) { }
  transform(inputArray: any) {
    return inputArray.getDate()+' '+ShortMonth[inputArray.getMonth()]
  }
}
