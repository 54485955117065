import { Directive,  HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
@Directive({
  selector: "[textmask]"
})

export class TextMaskDirective {

  constructor(private control: NgControl) {
  }
  @Input() textmask: string;
  @Input() maskDisable: boolean;

@HostListener('input', ['$event']) oninput(event) {
    let e = <any>event;
    if (this.maskDisable) {
        return;
    }
    let mask = this.textmask;
    let fullValue = e.target.value;
    if (e.inputType != "deleteContentBackward") {
        event.currentTarget.value = this.appendFormat(fullValue.replace(/\D/g,''),mask);
        let originalvalue = event.currentTarget.value;
       this.control.control.setValue(originalvalue);
    }
  }

  appendFormat(value:string, format:string){
    if (!value && value == "") {
        return "";
    }
    let userInput = value.toString();
    let returnVal:string ="";
    let indexOfUserValue:number = 0;
    if (format && format == "") {
        return value;
    }
    for (let i = 0; i < format.length; i++) {
        const char = format[i];
        let charCode = char.toString().charCodeAt(0);
        const IsNumber:boolean = ((charCode>=48)&&(charCode<=57));
        if (!IsNumber) {
           returnVal = returnVal+format[i];
        }else{
            if (userInput[indexOfUserValue]) {
                returnVal = returnVal+userInput[indexOfUserValue];
                indexOfUserValue++;
            }else{
                break;
            }
        }
    }

    return returnVal;
  }


}
