import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { Localization } from './localization'

@Pipe({ name: "Currency" })
@Injectable()
export  class CustomCurrencyPipe implements PipeTransform {

    constructor(private language: Localization) {  
    }

    transform(value: any, currencySymbolRequired: boolean = true): string {       
        return this.language.localizeCurrency(value, currencySymbolRequired);        
    }
}