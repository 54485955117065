import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ConfirmPopModel } from '../model/pop-up.interface';
import { SessionTimeoutService } from './session-timeout.service';

@Component({
  selector: 'app-agilysys-session-timeout-popup',
  templateUrl: './agilysys-session-timeout-popup.component.html',
  styleUrls: ['./agilysys-session-timeout-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysSessionTimeoutPopupComponent {
  agilysysCofirmationButton: AgilysysButtonModel[];
  seconds: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPopModel,
    public dialogRef: MatDialogRef<AgilysysSessionTimeoutPopupComponent>,
    private translateService: TranslateService,
    public sessionTimeoutService: SessionTimeoutService
  ) {
    this.seconds = this.translateService.instant('Login.Seconds');
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: this.translateService.instant('Login.Stayin'),
        buttonKey: 'Stayin',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: this.translateService.instant('Login.Signout'),
        buttonKey: 'Signout',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      }
    ];
  }
}
