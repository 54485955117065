import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { UI,API } from 'src/app/common/Models/authorize-Integration-configuration-model';
import { AuthorizeConfigurationDataService } from 'src/app/common/dataservices/authorizeIntegrationConfiguration.data.service';

@Injectable()
export class AuthorizeIntegrationSetupBusiness {
captions: any;
constructor(private _localization: Localization, private _authorizeDataService: AuthorizeConfigurationDataService) {
     this.captions = this._localization.captions;
}



async GetAuthorizeConfiguration() {
 let apiModel: API.AuthorizeConfiguration = await this._authorizeDataService.GetAuthorizeConfiguration();
 return this.UIMapper(apiModel);
}

async GetAuthorizeConfig(): Promise<UI.AuthorizeConfig[]> {

 const data = await this._authorizeDataService.GetAuthorizeConfig();
    if (data && data.length > 0) {
    return data;
    }
 return [];
 }

 public async saveDefaultAuthorizeConfig(defaultAuthorizeConfig: UI.AuthorizeConfigDefaults[], removeItems: number[]) : Promise<UI.AuthorizeConfig[]> {
    const AuthorizeConfigurations: UI.AuthorizeConfig[] = [];
    let newConfig : UI.AuthorizeConfig[] = [];
    if (defaultAuthorizeConfig && defaultAuthorizeConfig.length > 0) 
    {

     defaultAuthorizeConfig.forEach(x => {

        const AuthorizeConfig: UI.AuthorizeConfig = {
          configurationKey: x.ConfigKey,
          configurationValue: x.ConfigValue,
          id: x.id
        };
        AuthorizeConfigurations.push(AuthorizeConfig);
      });

      if (removeItems && removeItems.length > 0) {
        await this._authorizeDataService.DeleteAuthorizeConfig(removeItems);
      }

      if (AuthorizeConfigurations && AuthorizeConfigurations.length > 0) {
        newConfig = await this._authorizeDataService.CreateAuthorizeConfig(AuthorizeConfigurations);
       }
    }
    return newConfig;
  }

  

  async CreateAuthorizeConfiguration(authorizeConfig): Promise<UI.AuthorizeConfiguration> {
   let result = this.APIMapper(authorizeConfig);
   let apiModel: API.AuthorizeConfiguration = await this._authorizeDataService.CreateAuthorizeConfiguration(result);
   return this.UIMapper(apiModel);
  }



  async UpdateAuthorizeConfiguration(authorizeConfig): Promise<UI.AuthorizeConfiguration> {
    let result = this.APIMapper(authorizeConfig);
    let apiModel: API.AuthorizeConfiguration = await this._authorizeDataService.CreateAuthorizeConfiguration(result);
    return this.UIMapper(apiModel);
  }

 UIMapper(apiModel): UI.AuthorizeConfiguration {
  if (apiModel == null)
   return;
  else {
   return {
    isEnabled: apiModel.isEnabled,
    authenticationURL: apiModel.authenticationURL,
    formAPIURL: apiModel.formAPIURL,
    formCode: apiModel.formCode,
    arFormCode : apiModel.arFormCode,
    propertyId: apiModel.propertyId,
    tenantID: apiModel.tenantId,
    noOfAuthorization: apiModel.noOfAuthorization,
    id: apiModel.id
   } as UI.AuthorizeConfiguration;
  }
}

APIMapper(apiModel): API.AuthorizeConfiguration {

  return {
   isEnabled : apiModel.isEnabled,
   authenticationURL: apiModel.authenticationURL,
   formAPIURL: apiModel.formAPIURL,
   formCode: apiModel.formCode,
   arFormCode : apiModel.arFormCode,
   propertyId: apiModel.propertyId,
   tenantID: apiModel.tenantID,
   noOfAuthorization: apiModel.noOfAuthorization,
   id: apiModel.id
  } as API.AuthorizeConfiguration;
}

}