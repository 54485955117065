import { Injectable } from '@angular/core';
import { Localization } from '../../localization/localization';
import { CustomType, DashboardConfigDetails } from './dashboard-jasper-config.model';
import { PropertySettingDataService } from '../../dataservices/authentication/propertysetting.data.service';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { FeatureName } from '../../shared/retail.modals';
import { CustomizableDashboardDataService } from '../../dataservices/authentication/customizable-dashbard.data.service';
import { CustomizableDashboardDetails } from './dashboard-jasper-config.model';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { UntypedFormGroup } from '@angular/forms';
import { UI } from '../../Models/property-information.model';

@Injectable()
export class dashBoardJasperConfigBusiness {
  captions: any;
  productId: number;
  propertyId: number;
  userId: number;
  dashboardConfigDetails: DashboardConfigDetails[] = [];
    constructor(private localization: Localization, private propertySettingDataService: PropertySettingDataService, private utils: CommonUtilities, private customizableDashboardService: CustomizableDashboardDataService,private _userAccessBusiness: UserAccessBusiness) {
        this.captions = this.localization.captions;
        this.setPropertyInfoDetails();
    }

    setPropertyInfoDetails(){
        this.productId = Number(this.utils.GetPropertyInfo('ProductId'));
        this.propertyId = Number(this.utils.GetPropertyInfo('PropertyId'));
        this.userId = Number(this.utils.GetPropertyInfo('UserId'));
    }

    async ValidateBreakPoints(): Promise<UserAccessModel.BreakPointResult> {
        const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.CUSTOMIZABLEHOMEDASHBOARD, false);
        return result;
    }

    async isCustomizableDashboardEnabled(){
        const features = await this.propertySettingDataService.getPropertyFeatures(this.propertyId, this.productId);
        if (features != null && features.length > 0) {
            const prodFeature = features.find(x => x.featureName == FeatureName.CustomizableDashboard);
            return prodFeature != null && prodFeature.isActive;
        }
        return false;
    }

    async getDashBoardViews(): Promise<CustomizableDashboardDetails[]> {
        let customizableDashboardDetails: CustomizableDashboardDetails[] = await this.customizableDashboardService.getCustomizableDashboardDetails(this.userId, this.propertyId, this.productId);
        this.mapDashboardConfigValues(customizableDashboardDetails);
        return (!customizableDashboardDetails || customizableDashboardDetails.length == 0) ? this.getDefaultCustomDashboards() : customizableDashboardDetails.map(x => this.mapCustomDashboard(x));
    }

    async getCustomDashboardConfigByPropertyId(){
        let customizableDashboardDetails: CustomizableDashboardDetails[] = await this.customizableDashboardService.getCustomizableDashboardDetailsByPropertyId(this.propertyId, this.productId);
        this.mapDashboardConfigValues(customizableDashboardDetails);
        return (!customizableDashboardDetails || customizableDashboardDetails.length == 0) ? this.getDefaultCustomDashboards(true) : customizableDashboardDetails.map(x => this.mapCustomDashboard(x));
    }

    mapDashboardConfigValues(customizableDashboardDetails: CustomizableDashboardDetails[]){
        if(!customizableDashboardDetails || customizableDashboardDetails.length == 0)
            this.dashboardConfigDetails = this.getDefaultDashboardValues();
        else{
            this.dashboardConfigDetails = customizableDashboardDetails.map(x => this.mapDashboard(x));
        }
    }

    mapDashboard(dashboardDetails: CustomizableDashboardDetails): DashboardConfigDetails{
        let dashboardName: string = this.getDashboardName(dashboardDetails.customType);
        return {
            id: dashboardDetails.customType,
            value: dashboardDetails.dashboardURL,
            viewValue: dashboardDetails.dashboardName && dashboardDetails.dashboardName != '' ? dashboardDetails.dashboardName : dashboardName,
            isDefaultDashboard: dashboardDetails.dashboardName && dashboardDetails.dashboardName != '' ? false : true
        }
    }

    getDashboardName(customType: CustomType): string {
        switch (customType) {
            case CustomType.Dashboard1:
                return 'Dashboard 1'
            case CustomType.Dashboard2:
                return 'Dashboard 2'
            case CustomType.Dashboard3:
                return 'Dashboard 3'
            default:
                return '';
        }
    }

    getDefaultDashboardValues(): DashboardConfigDetails[]{
        return [
            {
                id: CustomType.Dashboard1,
                value: 'Dashboard1',
                viewValue: 'Dashboard 1',
                isDefaultDashboard: true
            },
            {
                id: CustomType.Dashboard2,
                value: 'Dashboard2',
                viewValue: 'Dashboard 2',
                isDefaultDashboard: true
            },
            {
                id: CustomType.Dashboard3,
                value: 'Dashboard3',
                viewValue: 'Dashboard 3',
                isDefaultDashboard: true
            }
        ]
    }

    getDefaultCustomDashboards(isFromProperty: boolean = false){
        let customDashboardDetails: CustomizableDashboardDetails[] = [];
        this.dashboardConfigDetails.map(x => {
            let customDashboard: CustomizableDashboardDetails = {
                dashboardName: '',
                dashboardURL: '',
                productId: this.productId,
                propertyId: this.propertyId,
                userId: !isFromProperty ? this.userId : 0,
                customType: x.id
            }
            customDashboardDetails.push(customDashboard);
        })
        return customDashboardDetails;
    }

    async saveCustomizableDashboard(fromValue, customizableDashboardDetails: CustomizableDashboardDetails[]): Promise<CustomizableDashboardDetails[]>{
        let customizableDashboard: CustomizableDashboardDetails[] = this.mapCustomizableDashboardAPI(fromValue?.dashboardConfigArray, customizableDashboardDetails);
        let customDashboards = await this.customizableDashboardService.UpdateCustomizableDashboardDetails(customizableDashboard);
       this.mapDashboardConfigValues(customDashboards);
        return customDashboards.map(x => this.mapCustomDashboard(x));
    }

    mapCustomizableDashboardAPI(fromValue, customDashboardDetails: CustomizableDashboardDetails[]): CustomizableDashboardDetails[]{
        if(!fromValue || fromValue == null)
            return customDashboardDetails;

        let customDashboard: CustomizableDashboardDetails[] = [];
        fromValue.forEach((x,i) => {
            let dashboard: CustomizableDashboardDetails = {
                dashboardName: x.dashboardName,
                dashboardURL: x.dashboardUrl,
                productId: this.productId,
                propertyId: this.propertyId,
                userId: this.userId,
                customType: i + 1
            }
            customDashboard.push(dashboard);
        })
        return customDashboard;
    }

    mapCustomDashboard(x){
        return {
            userId: x.userId,
            propertyId: x.propertyId,
            userRoleId: x.userRoleId,
            productId: x.productId,
            dashboardName: x.dashboardName,
            dashboardId: x.dashboardId,
            customType: x.customType,
            dashboardURL: x.dashboardURL
        } as CustomizableDashboardDetails
    }

    public shouldActionButtonsDisable(form: UntypedFormGroup): UI.ActionButtonState {
        return {
            isSaveDisable: !(form.dirty && form.valid),
            isCancelDisable: !(form.dirty)
        } as UI.ActionButtonState;
    }

}


