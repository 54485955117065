import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { MasterInventoryManagement } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FormModelConfiguration } from './form-config.interface';

export const FormFieldTypes: FormModelConfiguration = (
ViewModelEntity: any, PostModelEntity: any, MasterDataCollection: MasterInventoryManagement, httpService: HttpService): any => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 ) {
            return getModelType(PostModelEntity, ViewModelEntity);
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function getModelType(ModelType: object, OdataEntityModel: any): string {
    try {
        let fieldType = '';
        switch (ModelType['Type']) {
            case FieldEdmType.EDMSTRING:
                fieldType = FormFieldType.TEXT;
              break;
            case FieldEdmType.EDMINT32:
                fieldType = FormFieldType.DROPDOWN;
              break;
              case FieldEdmType.EDMINT16:
              fieldType = FormFieldType.TEXT;
              break;
              case FieldEdmType.EDMDATEOFFSET:
                fieldType = FormFieldType.DATEPICKER;
              break;
              case FieldEdmType.EMDBOOLEAN:
                fieldType = FormFieldType.SWITCH;
              break;
              case FieldEdmType.EDMINTDECIMAL:
                fieldType = FormFieldType.NUMBER;
              break;
            default:
                fieldType = FormFieldType.TEXT;
          }
          return fieldType;

    } catch (error) {
        console.log('error occured', error);
    }
}