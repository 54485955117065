import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';


export const setCostValue: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            const Quantity = FormGroupData.controls[FieldGrouping.CustomFormAction.CustomMiddleField].value ? 
                     parseFloat(FormGroupData.controls[FieldGrouping.CustomFormAction.CustomMiddleField].value) : 0;
            const Amount = FormGroupData.controls['IngAmount'] && FormGroupData.controls['IngAmount'].value 
                            ? FormGroupData.controls['IngAmount'].value : 0;
            const calculatedAmount = Quantity !== 0 ? (Amount / Quantity).toFixed(4) : 0;
            FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(calculatedAmount);               
            CommonService.triggerBlurEvent(FieldGrouping.CustomFormAction.CutomFieldTo);
            const PriceValue = FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value ? 
            parseFloat(FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value) : 0;
            const calculatedCostPercent = PriceValue !== 0 ? ((parseFloat(calculatedAmount.toString()) / PriceValue) * 100).toFixed(4) : 0;
            FormGroupData.controls[FieldGrouping.CustomFormAction.EnabledField].setValue(calculatedCostPercent);               
            CommonService.triggerBlurEvent(FieldGrouping.CustomFormAction.EnabledField);
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

