import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { ValidationMessage } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-sale-by-item',
  templateUrl: './sale-by-item.component.html',
  styleUrls: ['./sale-by-item.component.scss']
})
export class SaleByItemComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  ItemFormGrp: UntypedFormGroup;
  ShiftFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  inActiveText = this.captions.IncludeInactiveUsers;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any[];
  clerks: DropDownData[] = [];
  registers: any;
  categoryGroupSelected : any[] = [];
  floatLabel:string;
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, public business: ReportBusinessService, private dataService: ReportDataService) {
    this.floatLabel = this.localization.setFloatLabel;
  }

   ngOnInit() {
    this.radioFilter = [{ id: 0, name: this.captions.DetailByOutlet }, { id: 1, name: this.captions.SummaryByOutlet }];
    this.ItemFormGrp = this.fb.group({
      filterItems: false,
      searchbyitem: '',
      includeInactiveusers: false,
      registers: new UntypedFormControl([this.defaultData]),
      clerks: new UntypedFormControl([this.defaultData]),
      itemFilter: 0
    });
    this.formReady.emit(this.ItemFormGrp);
    this.onLoad();
    this.isValidText(true);
  }

  ngOnDestroy() {
    this.isValidText(true);
  }

  private async onLoad() {
    this.clerks = await this.dataService.GetAllUsers();
    this.allCategoryGroups = await this.dataService.GetAllCategoryGroups();
    this.business.FilterDataSource["allClerks"] = this.clerks;
    this.business.FilterDataSource["allCategoryGroups"] = this.allCategoryGroups;
    this.clerks = this.business.toggleIncludeInactive(false, this.clerks);
  }

  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.clerks = this.business.toggleIncludeInactive(IncludeInactiveToo, this.clerks);
    this.business.FilterDataSource["allClerks"] = this.clerks;
    this.ItemFormGrp.controls['clerks'].setValue([this.clerks]);
  }

  formInitialized(form: UntypedFormGroup) {
    this.ItemFormGrp.setControl('category', form);
  }

  changeToggleEvent(event) {
    if (event[0]) {
      this.ItemFormGrp = this.fb.group({
        filterItems: true,
        searchbyitem: '',
        registers: new UntypedFormControl([this.defaultData]),
        clerks: new UntypedFormControl([this.defaultData]),
        itemFilter: 0
      });
      this.isValidText(false);
    } else {
      this.ItemFormGrp = this.fb.group({
        filterItems: false,
        reportcategory: new UntypedFormControl([this.defaultData]),
        registers: new UntypedFormControl([this.defaultData]),
        clerks: new UntypedFormControl([this.defaultData]),
        category: new UntypedFormGroup({}),
        itemFilter: 0
      });
      this.isValidText(true);
    }
    this.formReady.emit(this.ItemFormGrp);
  }
 
  getFormControlValue(event) {
    if (event[0] === 'registers') {
      this.ItemFormGrp.setControl('registers', event[1]);
    } else if (event[0] === 'clerks') {
      this.ItemFormGrp.setControl('clerks', event[1]);
      } else if (event[0] === 'reportcategory') {
        this.ItemFormGrp.setControl('reportcategory', event[1]);
        }
  }

  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

  getCategoryGroupValue(event) {
    this.categoryGroupSelected = [...event];
  }

}
