import { FormGroup } from '@angular/forms';
import { PeriodLength } from 'src/app/eatecui/source/shared/enum/global.enum';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import moment from 'moment';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';

export const CustomPeriodLengthChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    salesJsonData: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: MatDialog
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            console.log('CustomPeriodLengthChange');
            // if (salesJsonData.CreateTranscationConfig.flowFrom === 'edit') {
            //     const ConfirmPopUpDialog: ConfirmPopModel = {
            //         PopupHeaderContent: 'Alert!',
            //         PopUpContent: `Any change to Sales forecast definition will reset the item list. Click Confirm to Proceed`
            //     };
            //     const dialogRef = CustomData.open(AgilysysConfirmationPopupComponent, {
            //         width: '400px',
            //         height: '222px',
            //         data: ConfirmPopUpDialog,
            //         disableClose: true
            //     });
            //     dialogRef.afterClosed().subscribe(result => {
            //         if (result === 'Confirm') {                        
            //             salesJsonData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.forEach(rows => {
            //                 rows.IsDeleted = true;
            //             });
            //             const deletedList = salesJsonData.CreateTranscationConfig.ItemsConfig.ItemList.
            //             ItemGridConfig.GridData.filter(x => x.IsDeleted === true);
            //             salesJsonData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([], true);
            //             salesJsonData.CreateTranscationConfig.ItemsConfig.DeletedItemList = 
            //             [...deletedList, ...salesJsonData.CreateTranscationConfig.ItemsConfig.DeletedItemList];
            //             periodCalcBasedOnPeriodLength(FormGroupData, FormField, salesJsonData, FieldGrouping);
            //         } else {
            //             FormGroupData.controls['PlanPeriodLengthId'].setValue(salesJsonData.CreateTranscationConfig.previousFormValues['PlanPeriodLengthId']);                        
            //         }
            //     });                   
            // } else {
                periodCalcBasedOnPeriodLength(FormGroupData, FormField, salesJsonData, FieldGrouping);
            // }          
        }

    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function periodCalcBasedOnPeriodLength(FormGroupData: any, FormField: any, salesJsonData: any, FieldGrouping: any) {
    if (FormField.length !== salesJsonData.CreateTranscationConfig.TransactionFormConfig.FormFields.length) {
        FormField = salesJsonData.CreateTranscationConfig.TransactionFormConfig.FormFields;
    }
    const SelectedValue = FormGroupData.value[FieldGrouping.OdataKey];
    const StartDate = FormGroupData.value['StartDate'] ? moment(FormGroupData.value['StartDate']).format('MM/DD/YYYY')
         : moment().add(1, 'days').format('MM/DD/YYYY');
    const PlanPeriodLengthId = FormGroupData.value['PlanPeriodLengthId'];
    const PlanPeriodId = FormGroupData.value['PlanPeriodId'] ? FormGroupData.value['PlanPeriodId'] : 1;
    const PlanMealPeriodsIndex = FormField.findIndex(x => x.fieldType.SingleData.name === 'PlanMealPeriods');
    const PlanPeriodIdIndex = FormField.findIndex(x => x.fieldType.SingleData.name === 'PlanPeriodId');
    switch (SelectedValue) {
        case PeriodLength.Week:
            const weekstart = StartDate;
            const weekend = StartDate ? moment(StartDate).add(PlanPeriodId, 'weeks').subtract(1, 'days').format('MM/DD/YYYY') :
                 moment().add(PlanPeriodId, 'weeks').subtract(1, 'days').format('MM/DD/YYYY');
            FormGroupData.controls['StartDate'].setValue(new Date(weekstart));
            FormGroupData.controls['EndDate'].setValue(new Date(weekend));
            FormGroupData.updateValueAndValidity();
            if (PlanMealPeriodsIndex !== -1) {
                FormField[PlanMealPeriodsIndex].hide = true;
            }
            if (PlanPeriodIdIndex !== -1) {
                FormField[PlanPeriodIdIndex].hide = false;
            }
            break;
        case PeriodLength.Month:
            const monthStart = StartDate;
            const monthEnd = StartDate ? moment(StartDate).add(PlanPeriodId, 'months').subtract(1, 'days').format('MM/DD/YYYY') :
                moment(monthStart).add(PlanPeriodId, 'months').subtract(1, 'days').format('MM/DD/YYYY');
            FormGroupData.controls['StartDate'].setValue(new Date(monthStart));
            FormGroupData.controls['EndDate'].setValue(new Date(monthEnd));
            FormGroupData.updateValueAndValidity();
            if (PlanMealPeriodsIndex !== -1) {
                FormField[PlanMealPeriodsIndex].hide = true;
            }
            if (PlanPeriodIdIndex !== -1) {
                FormField[PlanPeriodIdIndex].hide = false;
            }
            break;
        case PeriodLength.Day:
            const Start = StartDate;
            const EndDate = StartDate ? moment(StartDate).add(PlanPeriodId, 'days').subtract(1, 'days').format('MM/DD/YYYY') :
            moment(monthStart).add(PlanPeriodId, 'days').subtract(1, 'days').format('MM/DD/YYYY');
            FormGroupData.controls['StartDate'].setValue(new Date(Start));
            FormGroupData.controls['EndDate'].setValue(new Date(EndDate));
            FormGroupData.updateValueAndValidity();
            if (PlanMealPeriodsIndex !== -1) {
                FormField[PlanMealPeriodsIndex].hide = true;
            }
            if (PlanPeriodIdIndex !== -1) {
                FormField[PlanPeriodIdIndex].hide = false;
            }
            break;
        case PeriodLength.Meal:
            if (PlanMealPeriodsIndex !== -1) {
                FormField[PlanMealPeriodsIndex].hide = false;
            }
            if (PlanPeriodIdIndex !== -1) {
                FormField[PlanPeriodIdIndex].hide = true;
            }
            break;
    }
}

export interface DropdownOption {
    Key: any;
    Value: any;
}