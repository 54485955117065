import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { MasterInventoryManagement, NestedDataBuilder, SubFormConfigs, } from '../master.interface';
import { FormModelConfiguration } from './form-config.interface';

export const NestedFormBuilding: FormModelConfiguration = (
ViewModelEntity: any, PostModelEntity: any, MasterDataCollection: MasterInventoryManagement, 
httpService: HttpService, postModelEntityObject: any, translateService: TranslateService): any => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 ) {
            const keyName =  postModelEntityObject['$']['Name'];
            const PostModelProperity  = postModelEntityObject['$'];
                MasterDataCollection.NestedFormConfig.ViewFormConfig.forEach((x: SubFormConfigs) => {
                 if ( x.Type === 'Form') {
                     x.data.forEach(( nestedDataBuilder: NestedDataBuilder) => {
                         if (nestedDataBuilder.NestedType === 'accordian') {
                            const NestedBuilderKeyIndex = nestedDataBuilder.NestedData.findIndex(
                            builderKey => builderKey.OdataKey === keyName);
                            if (NestedBuilderKeyIndex > -1) {
                                const accordianObject =  nestedDataBuilder.NestedData[NestedBuilderKeyIndex];
                                if ( PostModelProperity.hasOwnProperty('Nullable') ) {
                                    accordianObject['Nullable'] =  postModelEntityObject['$']['Nullable'];
                                }
                                accordianObject['EdmType'] = postModelEntityObject['$']['Type'];
                            }
                         }
                     });
                   
                 }                 
               }); 
        } 
        return MasterDataCollection;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};