import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FileData, FileUploadConfig, FilevideoView, UploadMetaData } from './upload-configuration.interface';
import { DetailsPayload } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { StateService } from 'src/app/eatecui/source/shared/services/state.service';
import { EnvService } from 'src/app/eatecui/source/config.service';
let assetUrl: string;
let totalSegments: number;
let segmentLength: number;
let bytesFetched: any = 0;
let requestedSegments = [];
let segmentDuration = 0;
let httpclientService: HttpService;
let stateClientService: StateService;
let envClientService: EnvService;
export const FileVideoView: FilevideoView =
    (videoElement: any, httpService: HttpService, stateService: StateService, envService: EnvService, fileDetails: any, matDialog: any) => {
        try {
            totalSegments = Math.ceil(fileDetails.FileDetails.Size / 1048576);
            segmentLength = 0;
            bytesFetched = 0;
            requestedSegments = [];
            segmentDuration = 0;
            httpclientService = httpService;
            stateClientService = stateService;
            envClientService = envService;
            const video = videoElement;
            const filePath = fileDetails.FileDetails.FilePath;
            const downloadUrl = '/inventory/api/File/Download';
            const EndPointUrl = downloadUrl + `?fileName=${filePath}` + `&IsThumbnail=false`;
            assetUrl = EndPointUrl;
            const mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
            const videoSourceURl = `${envService.apiUrl}/inventory/api/File/Stream?fileName=` + `${filePath}&tenant=` + stateService.tenant;
            video.src = videoSourceURl;
            // let mediaSource = null;
            // for (let i = 1; i <= totalSegments; i++) {
            //     requestedSegments[i] = false;
            // }
            // if ('MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
            //     mediaSource = new MediaSource();
            //     video.src = URL.createObjectURL(mediaSource);
            //     mediaSource.addEventListener('sourceopen', () => {
            //         // this.sourceOpen(mediaSource, video);
            //         chunckSourceOpen(mediaSource, video, mimeCodec, fileDetails);
            //     });
            // } else {
            //     console.error('Unsupported MIME type or codec: ', mimeCodec);
            // }
        } catch (error) {
            console.error(error);
        }
    };
function chunckSourceOpen(mediaSource: MediaSource, video: any, mimeCodec: any, fileDetails: any) {
    try {
        const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
        // this.getFileLength(_that.assetUrl, function (fileLength: any) {
        const fileLength = fileDetails.FileDetails.Size;
        segmentLength = Math.round(fileLength / totalSegments);
        segmentLength = 1048576; // segment length is constant 
        fetchRange(assetUrl, 0, segmentLength, (chunk: any) => {
            appendSegment(chunk, sourceBuffer);
        });
        requestedSegments[0] = true;
        video.addEventListener('timeupdate', (e: any) => {
            checkBuffer(mediaSource, video, sourceBuffer);
        });
        video.addEventListener('canplay', function () {
            console.log(video.currentTime);
            segmentDuration = video.duration / totalSegments;
            video.play();
            // video.muted = true;
        });
        video.addEventListener('seeking', (e: any) => {
            seek(e, mediaSource, sourceBuffer);
        });
    } catch (error) {
        console.error(error);
    }
}

function fetchRange(url, start, end, cb) {
    const xhr = new XMLHttpRequest;
    xhr.open('get', envClientService.apiUrl + url);
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader('Range', 'bytes=' + start + '-');
    xhr.setRequestHeader('Authorization', `Bearer ${stateClientService.token}`);
    xhr.setRequestHeader('tenant', stateClientService.tenant);
    xhr.onload = function () {
        console.log('fetched bytes: ', start, end);
        bytesFetched += end - start;
        cb(xhr.response);
    };
    xhr.send();
}
function seek (e, mediaSource: MediaSource, sourceBuffer: SourceBuffer) {
    console.log(e);
    if (mediaSource.readyState === 'open') {
      sourceBuffer.abort();
      console.log(mediaSource.readyState);
    } else {
      console.log('seek but not open?');
      console.log(mediaSource.readyState);
    }
  }
function appendSegment(chunk, sourceBuffer: any) {
    sourceBuffer.appendBuffer(chunk);
}

function checkBuffer(mediaSource: MediaSource, video, sourceBuffer: SourceBuffer) {
    const currentSegment = getCurrentSegment(video);
    const NextSegment = shouldFetchNextSegment(currentSegment, video);
    if (currentSegment === totalSegments && haveAllSegments()) {
        console.log('last segment', mediaSource.readyState);
        mediaSource.endOfStream();
        video.removeEventListener('timeupdate', () => {
            checkBuffer(mediaSource, video, sourceBuffer);
        });
    } else if (NextSegment) {
        requestedSegments[currentSegment] = true;
        console.log('time to fetch next chunk', video.currentTime);
        fetchRange(assetUrl, bytesFetched, bytesFetched + segmentLength, (chunk) => {
            appendSegment(chunk, sourceBuffer);
        });
    }
}
function getCurrentSegment(video: any) {
    const segmentCalc = video.currentTime / segmentDuration;
    // eslint-disable-next-line no-bitwise
    const segmentCal = ((segmentCalc) | 0) + 1;
    // eslint-disable-next-line no-bitwise
    return segmentCal;
  }

function haveAllSegments() {
    return requestedSegments.every(function (val) { return !!val; });
}

function shouldFetchNextSegment(currentSegment, video) {
    const segcurrentDuration = segmentDuration * currentSegment * 0.8;
    const nextSegmentCal = video.currentTime > segcurrentDuration;
    const nextFetchSeg = nextSegmentCal && !requestedSegments[currentSegment];
    return nextFetchSeg;
}


