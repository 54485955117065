import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ReceiveTempCheck } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-temp-check',
  templateUrl: './agilysys-temp-check.component.html',
  styleUrls: ['./agilysys-temp-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysTempCheckComponent {
  agilysysTempCheckButton: AgilysysButtonModule[];
  constructor(public dialogRef: MatDialogRef<AgilysysTempCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReceiveTempCheck) {
    this.agilysysTempCheckButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, data.GridInput]);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, data.GridInput]);
        }
      }
    ];
  }
}
