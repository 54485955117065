import { ElementRef, Pipe, PipeTransform } from '@angular/core';

@Pipe(
  {
    name: 'colorCoversion'
  })

export class ColorCoversionPipe implements PipeTransform {

  constructor() { }
  
  transform(value: any, elem?: ElementRef): string {
    const check = value.replace('$', '');
    if (check < 0 && elem) {
      elem.nativeElement.classList.add('product-negative-value');
      elem.nativeElement.classList.remove('product-positive-value');
    } else if (elem) {
      elem.nativeElement.classList.add('product-positive-value');
      elem.nativeElement.classList.remove('product-negative-value');
    }
    return value;
  }
}