import { Component, EventEmitter, OnInit, Output, ViewEncapsulation,ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { MiscellaneousSwitch } from 'src/app/retail/shared/globalsContant';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';
@Component({
  selector: 'app-staff-member-summary',
  templateUrl: './staff-member-summary.component.html',
  styleUrls: ['./staff-member-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StaffMemberSummaryComponent implements OnInit {
  staffMemberFormGrp: any;
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  captions: any;
  houseServiceChargePercentage: string = "0.00";
  isFromJobScheduler: boolean = false;
  editPatchValue: any;
  constructor(private fb: UntypedFormBuilder, private localization: RetailLocalization, 
     private PropertyInfo: RetailPropertyInformation, private propertySettingsDataService: PropertySettingDataService, private container: ViewContainerRef) { }

  ngOnInit() {
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
    this.captions = this.localization.captions.reports;
    this.staffMemberFormGrp = this.fb.group({
      includeZeroValue: true
    });
    this.formReady.emit(this.staffMemberFormGrp);
    if (this.container) 
    {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;

      if(this.editPatchValue && this.isFromJobScheduler)
      {
        this.staffMemberFormGrp.controls.includeZeroValue.setValue(this.editPatchValue.includeZeroValue);  
      }     
      
      
    }
  }

  async getHouseServiceChargePercentageSettingsValue(){
    let miscSettings = await this.propertySettingsDataService.GetMiscConfigurationSetting();
    let houseServiceChargePercentageSettingsValue = miscSettings.find(f => f.switch == MiscellaneousSwitch.HOUSE_SERVICECHARGE_PERCENTAGE);
    if(houseServiceChargePercentageSettingsValue){
        this.houseServiceChargePercentage = String(houseServiceChargePercentageSettingsValue.value); 
    }
    sessionStorage.setItem('houseServiceChargePercentage', this.houseServiceChargePercentage)
  }

}
