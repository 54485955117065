import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { POPostDateChangeCutOff } from './custom-form-configuration.interface';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { DefaultIniDateFormat } from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const POPostDateChangeCutOffCheck: POPostDateChangeCutOff = (
  MasterDataCollection: any, FormGroupData: FormGroup,
  httpService: HttpService, matDialog: MatDialog, translateService: TranslateService): any => {
  try {
    if (MasterDataCollection.CreateTranscationConfig.ItemsConfig?.ItemList?.ItemGridConfig?.GridData &&
      MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0 &&
      MasterDataCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig) {
      const currentSupplierId = MasterDataCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData[0].SupplierId;
      const processPOSupplierObj = MasterDataCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList?.filter(sl => sl.SuplierId === currentSupplierId)[0];
      const processPOSupplierIndex = MasterDataCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList?.findIndex(sl => sl.SuplierId === currentSupplierId);
      if (processPOSupplierObj && processPOSupplierObj['Message'] && FormGroupData.value
        && moment(FormGroupData.value.PostDate) < moment(processPOSupplierObj['DeliveryDate'])) {
        const DateTimeFormat = StorageService.GetSessionStorage("IniDateFormat") ? StorageService.GetSessionStorage("IniDateFormat") : DefaultIniDateFormat;
        const date = moment(processPOSupplierObj['DeliveryDate']).format(DateTimeFormat);
        const message = processPOSupplierObj['Message'].replace('{deliveryDate}', date);
        const confirmPopModel = {} as ConfirmPopModel;
        confirmPopModel.PopUpContent = '';
        confirmPopModel.InnerHtmlContent = message;
        confirmPopModel.PopupHeaderContent = translateService.instant('DeliveryRules.DeliveryRules');
        const drCheckDialog = matDialog.open(AgilysysConfirmationPopupComponent, {
          width: '525px',
          height: '235px',
          data: confirmPopModel,
          autoFocus: false,
          disableClose: true
        });
        drCheckDialog.afterClosed().subscribe(res => {
          if (res === 'Confirm') {
            FormGroupData.controls['PostDate'].setValue(processPOSupplierObj['DeliveryDate']);
            FormGroupData.updateValueAndValidity();
            MasterDataCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList[processPOSupplierIndex]['Warning'] = false;
            MasterDataCollection.CreateTranscationConfig.ItemsConfig = { ...MasterDataCollection.CreateTranscationConfig.ItemsConfig };
          } else {
            MasterDataCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList[processPOSupplierIndex]['Warning'] = true;
            MasterDataCollection.CreateTranscationConfig.ItemsConfig = { ...MasterDataCollection.CreateTranscationConfig.ItemsConfig };
          }
        });
      } else if (processPOSupplierObj && processPOSupplierObj['Warning'] && moment(FormGroupData.value.PostDate) >= moment(processPOSupplierObj['DeliveryDate'])) {
        MasterDataCollection.CreateTranscationConfig.ItemsConfig.ProcessPoConfig.SupplierList[processPOSupplierIndex]['Warning'] = false;
        MasterDataCollection.CreateTranscationConfig.ItemsConfig = { ...MasterDataCollection.CreateTranscationConfig.ItemsConfig };
      }
    }
  } catch (error) {
    console.log('Error Occurred', error);
  }
};
