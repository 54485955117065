import { Component, OnInit } from '@angular/core';
import { menuTypes } from 'src/app/common/components/menu/menu.constant';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';

@Component({
  selector: 'app-export-import',
  templateUrl: './export-import.component.html',
  styleUrls: ['./export-import.component.scss']
})
export class ExportImportComponent {
  menuList: any;
  menuType = menuTypes;
  constructor(private routeDataService: RouteLoaderService) {
    const value = this.routeDataService.GetChildMenu('/exportimport');
    this.menuList = {
      menu: value.linkedElement,
      menuType: value.linkedElement[0].menuAlignment
    };
  }
}
