import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AgInputFieldConfig, ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { sourceSearchCriteriaBusiness } from './source-search-criteria.business';
import { cloneDeep } from 'lodash';
import { CGtablecontent, UI } from '../../combine-guest-ui-model';
import { combineGuestRecordBusiness } from '../../combine-guest-records.business';
import { Localization } from 'src/app/common/shared/localization/Localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';


@Component({
  selector: 'ag-source-search-criteria',
  templateUrl: './source-search-criteria.component.html',
  styleUrls: ['./source-search-criteria.component.scss'],
  providers: [sourceSearchCriteriaBusiness],
  encapsulation: ViewEncapsulation.None
})
export class AgSourceSearchCriteriaComponent implements OnInit {
  primaryselecteddata: CGtablecontent;
  targetsrchCriteria: any;
  originalTableData: CGtablecontent[];
  primarytabledata: CGtablecontent[];
  selectedSecondarySelectedData: CGtablecontent[] = [];
  MatchFields: string[] = [];
  toggleshowmatch: boolean = false;
  isShowmatchDisabled = false;
  showBadge: boolean=false; 
  SearchCriteria : UI.GuestSearchFields;
  isAllEmpty:boolean=false;
  isFilterApplied:boolean=false;

  @Input()
  set inputs(value) {
    this._configureHeaderOptions = value.morePopupOptions;
    this.primaryselecteddata = value.PrimarySelectedData;
    this.targetsrchCriteria = value.targetSrchCriteriaList;
  }

  @Output() combinemenberOutputs = new EventEmitter();

  captions: any;
  searchButton: ButtonValue;
  SSCform: UntypedFormGroup;
  lastNameInput: AgInputFieldConfig;
  firstNameInput: AgInputFieldConfig;
  companyInput: AgInputFieldConfig;
  postalCodeInput: AgInputFieldConfig;
  patronIdInput: AgInputFieldConfig;
  headerOptions: TableHeaderOptions[];
  options: TableOptions;
  tableContent: any[];  
  _configureHeaderOptions: any;

  constructor(private _localization: Localization, private fb: UntypedFormBuilder, 
    public _sourceSearchCriteriaBusiness: sourceSearchCriteriaBusiness,
    public _combineGuestRecordBusiness:combineGuestRecordBusiness,
    private utils: CommonUtilities
    ) {
    this.captions = this._localization.captions;

  }

   ngOnInit(): void {
    this.createForm();
    this.initialization();
    this.tableGridInitialize().then(()=> this.furtherFilterTableContent());
  }

  async tableGridInitialize() {
    this.utils.ToggleLoader(true);
    this.options = this._sourceSearchCriteriaBusiness.getTableOptions();
    this.headerOptions = this._sourceSearchCriteriaBusiness.headerGenerator();
    this.originalTableData = cloneDeep(this._sourceSearchCriteriaBusiness.tabledataGeneration());
    this.originalTableData = await this._combineGuestRecordBusiness.getGuests(this.targetsrchCriteria).finally(()=>{
      this.utils.ToggleLoader(false);
    });
    this.tableContent = this.originalTableData.filter(x => x.id != this.primaryselecteddata.id);
  }
  createForm() {
    this.SSCform = this.fb.group({
      UTSC: true,
      UOFC: '',
      lastName: '',
      firstName: '',      
      postalCode: '',
      patronId:''
    });
  }
  initialization() {
    this.searchButton = {
      label: this.captions.btn_search,
      type: 'primary',
      disabledproperty: true
    }
    this.lastNameInput = {
      className: 'ag_form-control ag_w--20',
      placeHolderId: 'lbl_lastName',
      placeHolder: this.captions.vendors.lbl_lastName,
      form: this.SSCform,
      formControlName: 'lastName',
      disabled: true,
      automationId : "Txt_SourceSearch_LastName"
    };
    this.firstNameInput = {
      className: 'ag_form-control ag_w--20',
      placeHolderId: 'lbl_firstName',
      placeHolder: this.captions.vendors.lbl_firstName,
      form: this.SSCform,
      formControlName: 'firstName',
      disabled: true,
      automationId : "Txt_SourceSearch_FirstName"
    };    
    this.postalCodeInput = {
      className: 'ag_form-control ag_w--20',
      placeHolderId: 'lbl_postalcode',
      placeHolder: this.captions.vendors.lbl_postalCode,
      form: this.SSCform,
      formControlName: 'postalCode',
      disabled: true,
      automationId : "Txt_SourceSearch_Postal"
    };
    this.patronIdInput = {
      className: 'ag_form-control ag_w--20',
      placeHolderId: 'lbl_patronId',
      placeHolder: this.captions.lbl_patronId,
      form: this.SSCform,
      formControlName: 'patronId',
      disabled: true,
      automationId : "Txt_SourceSearch_Patron"
    };
  }
  

  async criteria(eve, typ) {

   
      this.lastNameInput.disabled = this.SSCform.value.UTSC || this.SSCform.value.UOFC ; this.lastNameInput = { ...this.lastNameInput };
      this.firstNameInput.disabled = this.SSCform.value.UTSC || this.SSCform.value.UOFC; this.firstNameInput = { ...this.firstNameInput };     
      this.postalCodeInput.disabled = this.SSCform.value.UTSC || this.SSCform.value.UOFC; this.postalCodeInput = { ...this.postalCodeInput };
      this.patronIdInput.disabled = this.SSCform.value.UTSC || this.SSCform.value.UOFC; this.patronIdInput = { ...this.patronIdInput };   
      this.SSCform.valueChanges.subscribe(x => {
        let object = this.SSCform.value;
        let checkempty = (this.SSCform.value.UTSC) || (this.SSCform.value.UOFC) ||( object.lastName===""&& object.firstName===""&&object.postalCode===""&& object.patronId==="");
        this.searchButton.disabledproperty = checkempty;     
      });
    if (typ == 'UTSC') {    
          
      if (this.SSCform.value.UTSC) {
        this.SSCform.controls.UOFC.setValue(false);
        this.tableContent = this.originalTableData;
        this.furtherFilterTableContent();
        this.SSCform.controls.firstName.setValue("");
        this.SSCform.controls.lastName.setValue("");
        this.SSCform.controls.postalCode.setValue("");
        this.SSCform.controls.patronId.setValue("");       
      
      } else {  
           
        this.tableContent = [];
        this.selectedSecondarySelectedData = []        
        this.findMatchHeader();
        }
      
    }
    if (typ == 'UOFC') {     
      if (this.SSCform.value.UOFC) {
        this.SSCform.controls.UTSC.setValue(false);
        this.tableContent = [];
        await this.filterData();
        this.SSCform.controls.firstName.setValue("");
        this.SSCform.controls.lastName.setValue("");
        this.SSCform.controls.postalCode.setValue("");
        this.SSCform.controls.patronId.setValue(""); 
      } 
      else {
          //UOFC
      }
    }
    if(!this.SSCform.value.UOFC && !this.SSCform.value.UTSC)
    {
      this.furtherFilterTableContent();
    }
  } 

  
  async srchSSC(eve) {
    
    this.utils.ToggleLoader(true);
    if(!this.SSCform.value.UOFC && !this.searchButton.disabledproperty)
    {
      this.createEmptySearchCriteria();
      this.SearchCriteria.firstName= (this.SSCform.value.firstName != null) ? this.SSCform.value.firstName :"";
      this.SearchCriteria.lastName=(this.SSCform.value.lastName != null) ? this.SSCform.value.lastName : "";
      this.SearchCriteria.postalCode=(this.SSCform.value.postalCode != null) ? this.SSCform.value.postalCode :"";
      this.SearchCriteria.playerId=(this.SSCform.value.patronId != null) ? this.SSCform.value.patronId : "";
    }
    const hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined" && v !== "" );
    if (hasValues(this.SearchCriteria)) 
    {
      this.isAllEmpty = (this.SearchCriteria.firstName==""&&this.SearchCriteria.lastName==""&&this.SearchCriteria.postalCode==""&&
                        this.SearchCriteria.playerId==""&&this.SearchCriteria.address==""&&this.SearchCriteria.title=="" && this.SearchCriteria.cityName==""&&
                        this.SearchCriteria.emailAddress==""&& this.SearchCriteria.phone==""&&this.SearchCriteria.stateName==""&&this.SearchCriteria.countryName=="");
    
      if(this.isAllEmpty)
      {
        if(this._configureHeaderOptions.filter(x => x.checked).length>0)
        {
          let filterName=this._configureHeaderOptions.filter(x => x.checked)[0];
          this.primarytabledata = await this._combineGuestRecordBusiness.getGuestsforEmptyFilter(filterName.key); 
          
        }
      }
      else
      {
        this.primarytabledata = await this._combineGuestRecordBusiness.getGuests(this.SearchCriteria); 
      }   
        this.tableContent = this.primarytabledata.filter(x => x.id != this.primaryselecteddata.id);
        this.furtherFilterTableContent();            
    }   
   
   
    
    this.utils.ToggleLoader(false);
  }

 async filterData()
  {
    let checkedData = this._configureHeaderOptions.filter(x => x.checked);         
        if (checkedData.length > 0)
        {          
          this.createEmptySearchCriteria();
          this.SearchCriteria.isFilterApplied=true;   

          let element=checkedData[0];
            switch(element.key)
            {
              case "firstname":
                    this.SearchCriteria.firstName=this.primaryselecteddata.firstname!=null?this.primaryselecteddata.firstname:"";
                    break;
              case "lastname":
                    this.SearchCriteria.lastName=this.primaryselecteddata.lastname!=null?this.primaryselecteddata.lastname:"";
                    break;
              case "city":
                    this.SearchCriteria.cityName=this.primaryselecteddata.city!=null?this.primaryselecteddata.city:"";
                    break;
              case "state":
                    this.SearchCriteria.stateName=this.primaryselecteddata.state!=null?this.primaryselecteddata.state:"";
                    break;
              case "country":
                    this.SearchCriteria.countryName=this.primaryselecteddata.country!=null?this.primaryselecteddata.country:"";
                    break;
              case "email":
                    this.SearchCriteria.emailAddress=this.primaryselecteddata.email!=null?this.primaryselecteddata.email.replace(/.*:/, ""):"";
                    break;
              case "postalcode":
                    this.SearchCriteria.postalCode=this.primaryselecteddata.postalcode!=null?this.primaryselecteddata.postalcode:"";
                    break;
              case "phonenumber":
                    this.SearchCriteria.phone=this.primaryselecteddata.phonenumber!=null?this.primaryselecteddata.phonenumber.replace(/.*:/, ""):"";
                    break;
              case "patronid":
                    this.SearchCriteria.playerId=this.primaryselecteddata.patronid!=null?this.primaryselecteddata.patronid:"";
                    break;
              case "address":
                      this.SearchCriteria.address=this.primaryselecteddata.address!=null?this.primaryselecteddata.address:"";
                      break; 
              case "title":
                      this.SearchCriteria.title=this.primaryselecteddata.title!=null?this.primaryselecteddata.title:"";
                        break;            
            }           
         
          await this.srchSSC(null);              
        }       
  }

  createEmptySearchCriteria()
  {
    this.SearchCriteria = {
      id: 0,
      firstName: "",
      lastName: "",      
      marketChannelId: 0,
      cityName: "",
      stateCode: "",
      postalCode: "",
      stateName: "",
      county: "",
      countryName: "",
      nationality: "",
      phone: "",
      socialSecurityNumber: "",         
      emailAddress: "",
      driversLicense: "",
      memberTypeId: 0,
      playerId: "",
      title:"",
      address:"",
      isFilterApplied:false,
      includeInactive: false,     
      additionalSearch: {
        clubMember: "",
        companyName:  "",
        createdOn: "",
        guestID: "",
        memberCategory: "",
        memberNumber: "",
        memberType: "",
        ownerARNo: "",
        ownerContractNo: "",
        ownerRoomNo: "",
        updatedOn: ""
      }
    };
  }

  showMatches(eve) {
    this.toggleshowmatch = eve.checked;
  }
  tableAction(eve) {    
    this.selectedSecondarySelectedData = eve.checkedData;    
    this.isShowmatchDisabled =  eve.checkedData===undefined || eve.checkedData.length!==1;    
    this.findMatchHeader();
  }

  async furtherFilterTableContent() {    
  
    if(!this.isFilterApplied && ((!this.SSCform.value.UOFC && !this.SSCform.value.UTSC ) || this.SSCform.value.UOFC) && this.searchButton.disabledproperty && this._configureHeaderOptions.filter(x => x.checked).length>0 )
    {
      this.isFilterApplied=true;      
      this.utils.ToggleLoader(true);
      await this.filterData().finally(()=>{        
        this.utils.ToggleLoader(false);
      });
      
    }
    else
    {      
      if( this.SSCform.value.UTSC)
      {
          this.tableContent =this.originalTableData.filter(x => x.id !== this.primaryselecteddata.id);
      }
      else
      {
        this.tableContent =this.primarytabledata.filter(x => x.id !== this.primaryselecteddata.id);
      }
    let checkeddata = this._configureHeaderOptions.filter(x => x.checked);
    this.showBadge=checkeddata.length>0;
    if (checkeddata.length > 0) {
      this.tableContent = this.tableContent.filter(x => {
        let datapresent: boolean = false;
        for(let element of checkeddata)  {
          datapresent = this.primaryselecteddata[element.key]?.toLowerCase() == x[element.key]?.toLowerCase();
          if(!datapresent)
          {
            break;
          }
        }       
        return datapresent;
      });
    }
    this.tableContent = [...this.tableContent];
    this.selectedSecondarySelectedData = []
    this.isFilterApplied=false;
  }
    this.findMatchHeader();
  }
  configureOptionClick(eve, _configureHeaderOptions, headerOption, i) {
    headerOption.checked = eve.checked;
    this.furtherFilterTableContent();
  }

  findMatchHeader() {
    this.MatchFields = [];
    if (this.selectedSecondarySelectedData?.length > 0 && !this.isShowmatchDisabled) {
      for (const key of Object.keys(this.primaryselecteddata)) {
        let drivenKey = key;
        let drivenValue = this.primaryselecteddata[key];
        let arrayofboolean = [];
        this.selectedSecondarySelectedData.forEach(element => {
          if ((drivenKey=="phoneNumberArray" ||drivenKey=="emailArray") ||(element[drivenKey] === "" && drivenValue === "") || (element[drivenKey] === null && drivenValue === null))
          {
           let result = false;
           arrayofboolean.push(result);
          }
          else{
          let result = (element[drivenKey]!==undefined) && (element[drivenKey]?.toLowerCase() == drivenValue?.toLowerCase());
          arrayofboolean.push(result);
          }
        });
        let test = arrayofboolean.every(v => v === true);
        if (test) {
          let headername = this.headerOptions.filter(x => x.key.toLowerCase() == drivenKey.toLowerCase())[0]?.displayName;
          if(headername!==undefined && headername!=='')
          {
            this.MatchFields.push(headername)
          }
        }
      }
    }
    this.combinemenberOutputs.emit({'primarydata':this.primaryselecteddata,'secondarydata':this.selectedSecondarySelectedData});
  }
}
