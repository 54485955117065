import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DisableMenuModels } from 'src/app/eatecui/source/core/models/navigation-model';
// import * as DisabledMenu from '../../../assets/json/diabledmenu.json';
export const disbaledMenuJson: Array<DisableMenuModels> =  [
  {
      Id: 1,
      SiteId: 1,
      OdataKey: 'receiving'
  },
  {
      Id: 2,
      SiteId: 1,
      OdataKey: 'spoilage'
  },
  {
      Id: 3,
      SiteId: 1,
      OdataKey: 'physicalinventory'
  },
  {
      Id: 4,
      SiteId: 1,
      OdataKey: 'apcredits'
  },
  {
      Id: 5,
      SiteId: 1,
      OdataKey: 'inventoryreset'
  }
  
];
@Pipe({name: 'menudisabled'})
export class MenuDisabedPipe implements PipeTransform {
  constructor() {}
  transform(value: any, transForm: any): boolean {
    let disabledMenuItem = true;
    // const disabledMenu: Array<DisableMenuModels> = DisabledMenu['default'];
    const disabledMenu: Array<DisableMenuModels> = disbaledMenuJson;
    const getSiteId = sessionStorage.getItem('LoggedInSiteId');
    const menuIndex = disabledMenu.findIndex(x => x.OdataKey === transForm && x.SiteId.toString() === getSiteId);
    if ( menuIndex > -1 ) {
        disabledMenuItem = false;
    }
    return disabledMenuItem;
  }
}

