import { PMSRequestInfo } from '../common/external-request/pms-request.model';
import { ClientInfo } from '../shared/business/shared.modals';

export namespace core {
    export interface PropertySetting {
        PropertyId: number;
        PropertyDate: Date;
        ActivateRetailInterface: boolean
    }
}

export class UserSessionConfiguration {
    id?: number;
    userId: number;
    defaultOutletId?: number;
    defaultTerminalId?: number;
    defaultMachineId?: number;
    defaultCourseId?: number;
    defaultPaymentDevice?: string;
    defaultDeviceName?: string;
    isIdtechSred: boolean;
    smallStickersPrinter?: string;
    hangingTicketsPrinter?: string;
    propertyId: number;
    defaultPaymentId?: number;
}

export class PMSSessionData extends PMSRequestInfo {
    clientInfo: ClientInfo;
  }

  export class dayPassModel {
    id?:number;
    packageName:string;
    clientIds:any[];
    bookedDate:Date;
    packageDetailsJson:string;
    serviceIds:any[];
    isActive:boolean;
    status:number;
  }
