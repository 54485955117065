
/*
  NOTES TO DEVELOPER
  *****This page for ui developement only, In case any change in this page without authurization will affect UI*****
 */
import { Injectable } from '@angular/core';
import { FormType, NewDataRow } from './form-type.entity';
import { FormValidation } from './form-validation';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AgyDynamicFormServices {
  agyDynamicForm: FormGroup;
  formArrayname: string;
  formobjName: string;
  fromValueArray: FormArray;
  groupedArrayName: string;
  groupedFormArray: FormArray;
  primaryFormField: FormType[];
  constructor(
      public fB: FormBuilder,
      private formValidation: FormValidation
    ) {}
  createFormGroup(getValues: any, formGroup: any): any {
   try {
    this.agyDynamicForm = formGroup;
    getValues.forEach(Formelement => {
     const FieldType = Object.keys(Formelement.fieldType).toString();
     switch (FieldType) {
        case 'SingleData': {
         this.createSingleDataFormGroup(Formelement);
         break;
        }
        case 'MultipleData': {
         this.createMultipleDataFormGroup(Formelement);
         break;
        }
        case 'groupingDataMultiple': {
          this.createGroupingMultipleDataFormGroup(Formelement);
          break;
         }
         case 'groupingDataSingle': {
          this.createGroupingDataFormGroup(Formelement);
          break;
         }
         case 'CustomRow': {
          this.createCustomRowFormGroup(Formelement);
          break;
         }
      }
    });
    return this.agyDynamicForm;
  } catch (Error) {
    console.log(Error.message);
  }
  }

  createGroupedForm(formElement: FormType): void {
    this.groupedArrayName = formElement.name;
    this.groupedFormArray = this.createFormArray(formElement.name);
    const groupedFormObject = {};
    formElement.groupedFields.forEach(element => {
      // tslint:disable-next-line: no-shadowed-variable
      element.group.forEach( (element: any) => {
        groupedFormObject[element.name] = this.createArrayForm(element);
      });
      const group = this.fB.group(groupedFormObject);
      this.groupedFormArray.push(group);
      this.agyDynamicForm.addControl(this.groupedArrayName,  this.groupedFormArray);
    });
  }

  createArrayForm(formElement: FormType ): FormArray {
    this.formArrayname = formElement.name;
    this.fromValueArray = this.createFormArray(formElement.name);
    formElement.fieldArray.forEach(creategroupObj => {
      if (formElement.type !== 'checkbox') {
        this.fromValueArray.push(this.createGroup(creategroupObj));
      } else {
        this.fromValueArray.push(this.createCheckBoxFormControl(creategroupObj));
      }
      });
    return this.fromValueArray;
  }

  createGroupedArrayForm(formElement: any, GroupedName: string ): FormArray {
    formElement.dataSet.forEach((creategroupObj: FormType) => {
      if (formElement.type !== 'checkbox') {
        this.fromValueArray.push(this.createGroup(creategroupObj));
      } else {
        const formControlGenerate = this.createControl(formElement.fieldType.SingleData);
      }
      });
    return this.fromValueArray;
  }

  createSingleDataFormGroup(formElement: any): void {
    if (formElement.type !== 'checkbox' && formElement.type !== 'daterange') {
      const controlName = formElement.fieldType.SingleData.name;
      const formControlGenerate = this.createControl(formElement.fieldType.SingleData);
      this.agyDynamicForm.addControl(controlName, formControlGenerate);
    } else if (formElement.type === 'daterange') {
      const ControlNames = ['FromRange', 'ToRange'];
      ControlNames.forEach(controlValue => {
        const formControlGenerate = this.createControl(formElement.fieldType.SingleData);
        this.agyDynamicForm.addControl(controlValue, formControlGenerate);
      });
    } else {
      const controlName = formElement.fieldType.SingleData.name;
      const checkBoxFormGenerate = this.createCheckBoxFormControl(formElement.fieldType.SingleData);
      this.agyDynamicForm.addControl(controlName,  checkBoxFormGenerate);
    }
  }

  createMultipleDataFormGroup(multiPleFormelement): void {
    if (multiPleFormelement.type !== 'checkbox') {
      const createFormArray = this.createFormArray();
      const formArrayName = multiPleFormelement.fieldType.MultipleData.formName.toString();
      multiPleFormelement.fieldType.MultipleData.data.forEach(formData => {
        const formControlGenerate = this.createControl(formData);
        createFormArray.push(formControlGenerate);
      });
      this.agyDynamicForm.addControl(formArrayName,  createFormArray);
    }
  }

  createGroupingDataFormGroup(groupingFromElement: FormType): void {
  const groupDataType = Object.keys(groupingFromElement.fieldType).toString();
  this.groupedFormArray = this.createFormArray();
  const formArrayName = groupingFromElement.fieldType[groupDataType].formName.toString();
  const groupedFormObject = {};
  groupingFromElement.fieldType[groupDataType].data.forEach(formData => {
     if ( formData.type !== 'checkbox') {
      const formControlGenerate = this.createControl(formData);
      groupedFormObject[formData.name] = formControlGenerate;
     } else {
      const controlName = formData.name;
      const checkBoxFormGenerate = this.createCheckBoxFormControl(formData);
      groupedFormObject[controlName] = checkBoxFormGenerate;
     }
    });
  const groupingData = this.fB.group(groupedFormObject);
  this.groupedFormArray.push(groupingData);
  const groupingType = (groupDataType === 'groupingDataSingle') ? groupingData : this.groupedFormArray;
  this.agyDynamicForm.addControl(formArrayName, groupingType);
  }

  createGroupingMultipleDataFormGroup(groupingFromElement: FormType): void {
    const groupDataType = Object.keys(groupingFromElement.fieldType).toString();
    this.groupedFormArray = this.createFormArray();
    const formArrayName = groupingFromElement.fieldType[groupDataType].formName.toString();
    groupingFromElement.fieldType[groupDataType].data.forEach(formElement => {
      const groupedFormObject = {};
      formElement.dataSet.forEach(element => {
        if ( element.type !== 'checkbox') {
          const formControlGenerate = this.createControl(element);
          groupedFormObject[element.name] = formControlGenerate;
         }
      });
      const groupingData = this.fB.group(groupedFormObject);
      this.groupedFormArray.push(groupingData);
    });
    this.agyDynamicForm.addControl(formArrayName, this.groupedFormArray);
    }

    createCustomRowFormGroup(formElement: any): void {
      formElement.fieldType.CustomRow.data.forEach(element => {
        if (element.type !== 'checkbox') {
          const controlName = element.name;
          const formControlGenerate = this.createControl(element);
          this.agyDynamicForm.addControl(controlName, formControlGenerate);
        }
      });
    }

  createForm(formElement: FormType ): void {
    this.formArrayname = formElement.name;
    const getFieldType = Object.keys(formElement.fieldType)[0].toString();
    if ( getFieldType === 'MultipleData' ) {
      this.fromValueArray = this.createFormArray(formElement.name);
      formElement.fieldArray.forEach(creategroupObj => {
        if (formElement.type !== 'checkbox') {
          this.fromValueArray.push(this.createGroup(creategroupObj));
        } else {
          this.fromValueArray.push(this.createCheckBoxFormControl(creategroupObj));
        }
        });
      this.agyDynamicForm.addControl(this.formArrayname,  this.fromValueArray);
    } else {
      if (formElement.type !== 'checkbox') {
        const formControlGenerate = this.createControl(formElement.value);
        this.agyDynamicForm.addControl(this.formArrayname, formControlGenerate);
      } else {
        const checkBoxFormGenerate = this.createCheckBoxFormControl(formElement);
        this.agyDynamicForm.addControl(this.formArrayname,  checkBoxFormGenerate);
      }
    }
  }

  createCheckBoxFormControl(creategroupArray: any): FormGroup {
    let group: FormGroup;
    const opts = {};
    creategroupArray.options.forEach(optionkey => {
    opts[optionkey.key] = new FormControl(optionkey.value);
    });
    group = this.fB.group(opts);
    if (typeof(creategroupArray.value) === 'object') {
      group.setValue(creategroupArray.value);
    }
    return group;
  }

  createFormArray(e?: any): FormArray {
    return this.fB.array([]);
  }

  createGroupCheckbox(elementkey, elementobject?: any): FormGroup {
    const group = this.fB.group({});
    group.addControl(elementkey, this.createControl(elementobject));
    return group;
  }

  createGroup(e): FormGroup {
    const group = this.fB.group({});
    group.addControl(this.formArrayname, this.createControl(e));
    return group;
  }

  createControl(e?: any): FormControl {
    const controlValue = ( e ) ? e.value : '';
    const validateArray = ( e.FieldValidation ) ? this.formValidation.createValidation(e.FieldValidation) : null;
    return this.fB.control( controlValue, Validators.compose(validateArray));
  }

  createArrayControl(e: any, d: any): FormGroup {
    const group = this.fB.group({});
    group.addControl(e, d);
    return group;
  }

  addnewRow(formArray: FormArray, formObject: FormGroup ): void {
    formArray.controls.push( this.createControl(formObject) );
  }

  createnewRow(formname): FormGroup {
    const group = this.fB.group({});
    group.addControl(formname, this.createnewRowControl());
    return group;
  }

  createnewRowControl(): FormControl {
    return this.fB.control('', Validators.required);
  }

  RemoveRow(formarray: any, formobject: any, objectindex: any): void {
    formarray.removeAt(objectindex);
  }

  addnewRowGrouped(e: any, groupfields: any): void {
    const DataIndex = e.objectIndex;
    const GroupedFormArray = e.formArray;
    const GroupFieldData = groupfields.fieldType.groupingDataMultiple.data[DataIndex];
    const groupedFormObject = {};
    GroupFieldData.dataSet.forEach((element: FormType) => {
      if ( element.type !== 'checkbox') {
        element.value = element.type === 'switch' ? false : null;
        const formControlGenerate = this.createControl(element);
        groupedFormObject[element.name] = formControlGenerate;
       }
    });
    const groupingData = this.fB.group(groupedFormObject);
    if (GroupedFormArray) {
      if (groupfields.ParentClass.indexOf('SnC') > -1) {
        GroupedFormArray.value.forEach((keyName: any) => {
          if (keyName.hasOwnProperty('SNCTypeId')) {
            GroupFieldData.dataSet.forEach((dataset: any) => {
              if (dataset.name === 'SNCTypeId') {
                const sncTypeObject = dataset.options.filter(x => x.key === keyName.SNCTypeId)[0];
              }
            });
          }
        });
      }
    }
    GroupedFormArray.push(groupingData);
    groupfields.fieldType.groupingDataMultiple.data.push(GroupFieldData);
  }

  getComponentIndex(groupFields: any, itemComponents: any): any {
    let returnComponent;
    itemComponents.forEach(element => {
    if ( element.data.type === groupFields.type) {
      returnComponent = element;
    }
   });
    return returnComponent;
  }

   generateFormFieldName(formField: any): any {
    const fieldType = Object.keys(formField.fieldType).toString();
    const field = {};
    if ( fieldType === 'SingleData' || fieldType === 'groupingDataSingle' || fieldType === 'groupingDataMultiple') {
      field['fieldName'] = formField.fieldType[fieldType].name;
      field['fieldLabel'] = formField.fieldType[fieldType].label;
      field['fieldHeader'] = formField.fieldType[fieldType].fieldHeader;
    } else {
      field['fieldName'] = formField.fieldType[fieldType].formName;
      field['fieldLabel'] = formField.fieldType[fieldType].formName;
      field['fieldHeader'] = formField.fieldType[fieldType].fieldHeader;
    }
    return field;
   }

   formEventCallback(formField: any, index: any, formData: any, showMore?: any): void {
    const fieldType: string = Object.keys(formField.fieldType).toString();
    if ( fieldType === 'SingleData' || fieldType === 'groupingDataSingle' || fieldType === 'groupingDataMultiple') {
      if (formField.fieldType[fieldType].hasOwnProperty('onCellClick')) {
        formField.fieldType[fieldType].onCellClick(index, this.primaryFormField, formData, showMore);
      }
    } else {
      if (formField.fieldType.MultipleData.data[index].hasOwnProperty('onCellClick')) {
        formField.fieldType.MultipleData.data[index].onCellClick(index, this.primaryFormField, formData);
      }
    }
}

  addNewrow(newRow: NewDataRow): void {
    newRow.fieldArray.splice(newRow.IndexAfter, 0, newRow.data);
  }

  createnewRowcontrol(controlObject: any): void {
    const controlName = controlObject.fieldType.SingleData.name;
    const formControlGenerate = this.createControl(controlObject.fieldType.SingleData);
    this.agyDynamicForm.addControl(controlName, formControlGenerate);
  }
  formAddNewClick(formField: any, formData: any): void {
    const fieldType: string = Object.keys(formField.fieldType).toString();
    if (fieldType === 'SingleData') {
      if (formField.fieldType[fieldType].hasOwnProperty('onAddActionClick')) {
        formField.fieldType[fieldType].onAddActionClick(formField, formData);
      }
    }
  }

}


