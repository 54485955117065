import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchBoxComponent  {

  @Input() field: any = {};
  @Input() form: FormGroup;
  get isValid(): any { return this.form.controls[this.field.name].valid; }
  get isDirty(): any { return this.form.controls[this.field.name].dirty; }

  constructor() {
    console.log('serchbox');
  }

}
