import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-instructor-schedule-delete-modal',
  templateUrl: './instructor-schedule-delete-modal.component.html',
  styleUrls: ['./instructor-schedule-delete-modal.component.scss']
})
export class InstructorScheduleDeleteModalComponent implements OnInit {
  @Input() dataInput;
  scheduledData: any;
  therapistId = 0;
  captions: any;
  enableAction = false;
  constructor(public dialogRef: MatDialogRef<InstructorScheduleDeleteModalComponent>, private localization: SpaLocalization) { }

  ngOnInit() {
    this.scheduledData = this.dataInput.data;
    this.captions = this.localization.captions.staffSchedule;
    this.enableAction = this.scheduledData.some(r => r.checked);
  }

  checkEvent() {
    this.enableAction = this.scheduledData.some(r => r.checked);
  }

  deleteSchedule() {
    let schedulesToDelete = this.scheduledData.filter(r => r.checked);
    this.dialogRef.close(schedulesToDelete);
  }

  cancel() {
    this.dialogRef.close();
  }
}


