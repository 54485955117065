import { Injectable } from '@angular/core';
import { SaleByTokenRequest, AuthByTokenRequest, SaleByTokenResponse, AuthByTokenResponse, VoucherRedemptionResponse, Voucher, Setting, CardInfo} from 'src/app/common/shared/shared/business/shared.modals';
// import { CardInfo } from 'src/app/retail/shared/business/shared.modals';
import { CommonApiRoutes } from '../common-route';
import { RetailCommunication } from '../communication/services/retailcommunication.service';

@Injectable()
export class CommonPaymentDataService {

    constructor(private retailCommunication: RetailCommunication) {

    }

    public SaleByToken(saleByTokenRequest: SaleByTokenRequest, outletId: number): Promise<SaleByTokenResponse> {
        const result = this.retailCommunication.postPromise<SaleByTokenResponse>(
            { route: CommonApiRoutes.SaleByToken, body: saleByTokenRequest, uriParams: { outletId } }, false);
        return result;
    }


    public CreditByToken(creditByTokenRequest: SaleByTokenRequest, outletId: number): Promise<SaleByTokenResponse> {
        const result = this.retailCommunication.postPromise<SaleByTokenResponse>(
            { route: CommonApiRoutes.CreditByToken, body: creditByTokenRequest, uriParams: { outletId } }, false);
        return result;
    }


    public AuthByToken(authByTokenRequest: AuthByTokenRequest, outletId: number): Promise<AuthByTokenResponse> {
        const result = this.retailCommunication.postPromise<AuthByTokenResponse>(
            { route: CommonApiRoutes.AuthByToken, body: authByTokenRequest, uriParams: { outletId } }, false);
        return result;
    }

    public getCreditCardConfiguration(module: string, screen: string): Promise<Setting<string>> {
        const result = this.retailCommunication.getPromise<Setting<string>>(
            { route: CommonApiRoutes.GetCCConfigurationByScreen, uriParams: { module, screen } }, false);
        return result;
    }

    public RedeemVoucher(patronId: string, voucher: Voucher, handlerError = true): Promise<VoucherRedemptionResponse> {
        const result = this.retailCommunication.postPromise<VoucherRedemptionResponse>(
            { route: CommonApiRoutes.RedeemVoucher, body: voucher, uriParams: { patronId } }, handlerError);
        return result;
    }

    async GetCardInfo(tokenRefId: number): Promise<CardInfo> {
        const response = await this.retailCommunication.getPromise<CardInfo>({
            route: CommonApiRoutes.GetCardInfo,
            uriParams: { tokenTransId: tokenRefId }
        });
        return response ? response : null;
    }
}