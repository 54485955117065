import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ButtonValue } from "src/app/common/Models/ag-models";
import { Localization } from "src/app/common/localization/localization";
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { DiscountMappingBusiness } from './discount-mapping.business';
import { AlertAction, AlertType, DiscountMappingLevel, DiscountMapping, DiscountMappingSections } from '../shared/shared.modal';
import { ButtonTypes } from '../payment-manager/shared/shared-model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-discount-mapping',
  templateUrl: './discount-mapping.component.html',
  styleUrls: ['./discount-mapping.component.scss'],
  providers: [DiscountMappingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class DiscountMappingComponent implements OnInit {

    constructor(  private localization: Localization,private fb: UntypedFormBuilder, private business: DiscountMappingBusiness, private utilities: CommonUtilities) {
      this.captions = this.localization.captions;
  }

  captions: any;
  discountMappingForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  playerOptions = [];
  discountTypeOptions = [];
  discountTypeOptionsClone = [];
  tierLevelOptions = [];
  tierLevelOptionsClone = [];
  membershipLevelOptions = [];
  membershipLevelOptionsClone = [];
  selectedDiscounts = [];
  tierOptions: any;
  isSelectAll: boolean = false;
  discountTypeLinking = [];
  isSwitchLevelAllowed : boolean = false;
  currentLevelSelected: number = 1;
  isDiscountTypeSelected : boolean = false;

  async ngOnInit() {
    this.initializeForm();
    await this.getFormData();
    this.InitialpatchValue();
    this.discountMappingForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.actionButton.disabledproperty = !(this.discountMappingForm.valid && this.discountMappingForm.dirty);
      this.actionButton = { ...this.actionButton };
      this.cancelButton.disabledproperty = !(this.discountMappingForm.valid && this.discountMappingForm.dirty);
      this.cancelButton = { ...this.cancelButton };
    });
  }

  initializeForm() {
    this.discountMappingForm = this.fb.group({
      discountType: [],
      tierLevel: '',
      membershipType: '',
      tierOption: 1
    });
    this.actionButton = {
      type: "primary",
      label: this.captions.btn_save,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "secondary",
      label: this.captions.btn_cancel,
      disabledproperty: true,
    };
  }

  async getFormData(){
    this.tierLevelOptions = this.business.getTierOptions();
    this.tierLevelOptionsClone = cloneDeep(this.tierLevelOptions);
    this.membershipLevelOptions = await this.business.getMembershipTypes();
    this.membershipLevelOptionsClone = cloneDeep(this.membershipLevelOptions);
    this.discountTypeOptions = await this.business.getDiscountTypes();
    this.discountTypeOptionsClone = cloneDeep(this.discountTypeOptions);
    this.tierOptions = this.getmenuOptions();
    this.discountTypeLinking =  await this.business.getDiscountTypeLinking();
  }

  async InitialpatchValue() {
    let childOptionId: number;
    if (this.discountMappingForm.get('tierOption').value == DiscountMappingLevel.MembershipTypeLevel) {
      this.discountMappingForm.get('membershipType').setValue(this.membershipLevelOptions[0].id)
      this.membershipLevelOptions = cloneDeep(this.membershipLevelOptionsClone);
      this.membershipLevelOptions[0].checked = true;
      childOptionId = this.membershipLevelOptions[0].id;
    }
    else {
      this.discountMappingForm.get('tierLevel').setValue(this.tierLevelOptions[0].id);
      this.tierLevelOptions = cloneDeep(this.tierLevelOptionsClone);
      this.tierLevelOptions[0].checked = true;
      childOptionId = this.membershipLevelOptions[0].id;
    }

    let initialDiscountMapping: DiscountMapping = this.discountTypeLinking.find(x => x.masterParentTypeId === DiscountMappingSections.DiscountLevel
      && x.masterChildTypeId === DiscountMappingSections.DiscountLevelOptions
      && x.parentId === this.discountMappingForm.get('tierOption').value
      && x.childId === childOptionId);
    if (initialDiscountMapping) {
      let existingDiscountMapping = this.discountTypeLinking.filter(y => y.recursiveLinkId === initialDiscountMapping.id).map(z => z.childId);
      this.discountTypeOptions.map(z => z.checked = existingDiscountMapping.includes(z.id));
      if (this.discountMappingForm.get('tierOption').value == DiscountMappingLevel.MembershipTypeLevel)
        this.discountMappingForm.controls.membershipType.setValue(initialDiscountMapping.childId);
      else
        this.discountMappingForm.controls.tierLevel.setValue(initialDiscountMapping.childId);
    }
  }

  async patchValues() {
    let selectedMainLevelId: number = this.tierOptions.find(x => x.checked).id;
    let selectedLevelOptionId: number;

    if (this.discountMappingForm.get('tierOption').value == DiscountMappingLevel.MembershipTypeLevel)
      selectedLevelOptionId = this.membershipLevelOptions.find(x => x.checked).id;
    else
    selectedLevelOptionId = this.tierLevelOptions.find(x => x.checked).id;

    this.discountTypeOptions = cloneDeep(this.discountTypeOptionsClone);
    let initialDiscountMapping: DiscountMapping = this.discountTypeLinking.find(x => x.masterParentTypeId === DiscountMappingSections.DiscountLevel
      && x.masterChildTypeId === DiscountMappingSections.DiscountLevelOptions
      && x.parentId === selectedMainLevelId
      && x.childId === selectedLevelOptionId);
    if (initialDiscountMapping) {
      let existingDiscountMapping = this.discountTypeLinking.filter(y => y.recursiveLinkId === initialDiscountMapping.id).map(z => z.childId);
      this.discountTypeOptions.map(z => z.checked = existingDiscountMapping.includes(z.id));
      if (this.discountMappingForm.get('tierOption').value == DiscountMappingLevel.MembershipTypeLevel)
        this.discountMappingForm.controls.membershipType.setValue(initialDiscountMapping.childId);
      else
        this.discountMappingForm.controls.tierLevel.setValue(initialDiscountMapping.childId);
    }
    this.actionButton.disabledproperty = true;
    this.cancelButton.disabledproperty = true;
  }

  getmenuOptions() {
    return [
      {
        id: DiscountMappingLevel.MembershipTypeLevel,
        viewValue: this.captions.membershipType,
        checked: true,
        disabled: false
      }
      // {
      //   id: DiscountLevel.TierLevel,
      //   viewValue: this.captions.tierLevel,
      //   checked: false,
      //   disabled: true
      // }
    ]
  }

  selectAll() {
    this.isSelectAll = !this.isSelectAll;
    if (this.isSelectAll) {
      this.discountTypeOptions.forEach(x => x.checked = true);
    }
    else {
      this.discountTypeOptions.forEach(x => x.checked = false);
    }
    this.selectedDiscounts = this.discountTypeOptions.filter(x => x.checked);
    this.actionButton.disabledproperty = false;
    this.cancelButton.disabledproperty = false;
    this.isDiscountTypeSelected = true;
  }

  radioButtonChange(e) {

    if (this.isDiscountTypeSelected) {
      this.utilities.showCommonAlert(this.localization.captions.common.saveChangesMessage, AlertType.Warning, ButtonTypes.YesNo,
        (res) => {        
          if (res === AlertAction.YES) {
            this.isDiscountTypeSelected = false;
            this.isSelectAll = !this.isSelectAll;
            this.InitializeInputAfterRadioButtonChange(e);
          }
          else{
            if (this.discountMappingForm.get('tierOption').value == DiscountMappingLevel.MembershipTypeLevel) {
              this.discountMappingForm.get('membershipType').setValue(this.membershipLevelOptions.find(x => x.checked).id);
            }
            else {
              this.discountMappingForm.get('tierLevel').setValue(this.tierLevelOptions.find(x => x.checked).id);
            }
          }
        }
      )
    }
    else {
      this.InitializeInputAfterRadioButtonChange(e);
    }
  }

  InitializeInputAfterRadioButtonChange(e) {
    this.actionButton.disabledproperty = true;
    this.cancelButton.disabledproperty = true;
    if (this.discountMappingForm.get('tierOption').value == DiscountMappingLevel.MembershipTypeLevel) {
      this.membershipLevelOptions.forEach(x => {
        x.checked = x.id === e.value;
      });
      this.discountMappingForm.get('membershipType').setValue(this.membershipLevelOptions.find(x => x.checked).id);
    }
    else {
      this.tierLevelOptions.forEach(x => {
        x.checked = x.id === e.value;
      });
      this.discountMappingForm.get('tierLevel').setValue(this.tierLevelOptions.find(x => x.checked).id);
    }
    this.patchValues();
  }

  discountTypeChange(event, selectedObj){
    selectedObj.checked = event.checked;
    this.selectedDiscounts = this.discountTypeOptions.filter(x=>x.checked);
    this.isSelectAll = this.selectedDiscounts.length == this.discountTypeOptions.length;
    this.actionButton.disabledproperty = false;
    this.cancelButton.disabledproperty = false;
    this.isDiscountTypeSelected = true;
  }

  async onAction(e) {
    let APIData: DiscountMapping[] = [];
    let selectedDiscounttypeIds: number[] = [];
    let selectedMainLevelId: number = this.tierOptions.find(x => x.checked).id;

    let selectedLevelOptionId: number;
    if (this.discountMappingForm.get('tierOption').value == DiscountMappingLevel.MembershipTypeLevel)
      selectedLevelOptionId = this.membershipLevelOptions.find(x => x.checked).id;
    else
      selectedLevelOptionId = this.tierLevelOptions.find(x => x.checked).id;

    let masterMainLevelId: number = DiscountMappingSections.DiscountLevel;
    let masterLevelOptionId : number = DiscountMappingSections.DiscountLevelOptions;
    let masterDiscountTypeId : number = DiscountMappingSections.DiscountTypes;

    APIData.push(
      {id: 0 , 
      parentId : selectedMainLevelId , 
      childId : selectedLevelOptionId, 
      masterParentTypeId: masterMainLevelId, 
      masterChildTypeId: masterLevelOptionId, 
      recursiveLinkId: 0 
      });   
      
      selectedDiscounttypeIds = this.discountTypeOptions.filter(x => x.checked).map(x => x.id);
      if(selectedDiscounttypeIds.length !== 0 )
      {
        selectedDiscounttypeIds.forEach(x => {
         APIData.push(
      {id: 0 , 
      parentId : selectedLevelOptionId , 
      childId : x, 
      masterParentTypeId: masterLevelOptionId, 
      masterChildTypeId: masterDiscountTypeId, 
      recursiveLinkId: 1
      });  
        })
      } 
    const result = await this.business.CreateDiscountTypeLinking(APIData);
    if (result) {
      this.utilities.showCommonAlert(this.captions.common.configSaveSuccessFrom, AlertType.Success, ButtonTypes.Ok);
      this.discountTypeLinking = await this.business.getDiscountTypeLinking();
      this.isDiscountTypeSelected = false;
      this.patchValues();
    }
  }

  onCancel(e) {
    this.utilities.showCommonAlert(this.captions.warn_datalostChanges, AlertType.Warning, ButtonTypes.YesNo,
      (res) => {
        if (res === AlertAction.YES) {          
          this.patchValues();
        }
      });
  }

  onDropDownSelected(e) {
    let levelSelected = this.discountMappingForm.get('tierOption').value;
    this.discountMappingForm.get('tierOption').setValue(this.currentLevelSelected);
    if (!this.actionButton.disabledproperty) {
      this.utilities.showCommonAlert(this.localization.captions.common.saveChangesMessage, AlertType.Warning, ButtonTypes.YesNo,
        (res) => {
          if (res === AlertAction.YES) {
            this.discountMappingForm.get('tierOption').setValue(levelSelected);
            this.currentLevelSelected = levelSelected;
            this.InitializeDataForLevelChange(e)
          }
        }
      )
    }
    else {
      this.InitializeDataForLevelChange(e);
    }
  }

  InitializeDataForLevelChange(event) {
    this.tierOptions.forEach(x => {
      x.checked = x.id === event.value;
    });
    this.membershipLevelOptions.forEach((x, index) => {
      x.checked = index === 0;
    });
    this.discountMappingForm.get('membershipType').setValue(this.membershipLevelOptions[0].id);
    this.InitialpatchValue();
  }
}
