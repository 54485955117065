import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { CompareKey } from 'src/app/common/constants';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { DaysOfWeek, UI } from '../dynamic-yielding.model';

@Injectable()
export class DynamicYieldingGridBusiness {
    headerOptions: UI.GridHeader[];
    pricingSkeleton: any[] = [];
    overridenSlots: any[] = [];
    overridenSlots$: any[] = [];
    weekDays: any;
    constructor(private localization: SpaLocalization, private utils: SpaUtilities) {

    }
    generateDateHeaderOption(gridType, gridStartDate, gridEndDate): UI.GridHeader[] {

        let stdate = this.localization.delocalizeDisplayDate(this.localization.getDate(gridStartDate));
        let enDate = this.localization.delocalizeDisplayDate(this.localization.getDate(gridEndDate));
        if (gridType == UI.GridType.Day) {
            this.headerOptions = [];
            this.headerOptions.push(this.headerOptionCreator(stdate));
        } else {
            this.headerOptions = [];
            let dateRange = this.localization.getDatesForGivenRange(stdate, enDate);
            dateRange.forEach(x => {
                this.headerOptions.push(this.headerOptionCreator(x.dateObj));
            });
            console.log(this.headerOptions);
        }
        return this.headerOptions;
    }

    headerOptionCreator(dateObj) {
        return {
            key: 'ag_' + dateObj.getTime().toString(),
            dateString: dateObj.getTime().toString(),
            value: dateObj,
            localizedValue: this.LocalizeMMMFormatDate(dateObj),
            subText: this.localization.getDayForDate(dateObj)
        };
    }

    LocalizeMMMFormatDate(value: Date | string): string {
        if (typeof value == 'string') {
            value = this.localization.getDate(moment(value).format('YYYY-MM-DDTHH:mm'));
        }
        return moment(value).format('DD MMM');
    }

    generateTimeColumn(gridStartTime, gridEndTime, slotDifference) {
        const startTime = this.localization.TimeToDate(gridStartTime);
        const endTime = this.localization.TimeToDate(gridEndTime);
        let timeArray = this.localization.generateTimeRange(startTime, endTime, slotDifference);
        this.pricingSkeleton = [];
        timeArray.forEach((time, idx) => {
            this.pricingSkeleton.push({
                id: idx,
                time: time,
                yielding: [],
                startTime: time,
                endTime: timeArray[idx + 1]
            });
        });
        return this.pricingSkeleton;
    }

    getTimeDifferenceInMinutes(startTime, endTime, slotDifference) {
        const strt = moment(startTime);
        const end = moment(endTime);
        let timespan = end.diff(strt, 'minutes') / slotDifference;
        return timespan;
    }

    mapToGrid(pricingSkeleton: UI.YieldingSkeleton[], dataFromAPI: UI.YieldingDetails[], slotDifference: number, gridType: UI.GridType, gridStartTime, gridEndTime,isFirstMap:boolean): UI.YieldingSkeleton[] {
        this.overridenSlots = dataFromAPI[0].pricingDetails.filter(x => x.isOverride); 
        if(isFirstMap){
            this.overridenSlots.forEach((overrideSlot)=>{
                let stTime = this.localization.getTime(this.localization.getDate(overrideSlot.startTime), this.localization.getTimeFormat());
                let etTime = this.localization.getTime(this.localization.getDate(overrideSlot.endTime), this.localization.getTimeFormat());   
                overrideSlot.startTime = stTime;
                overrideSlot.endTime = etTime;
                if(this.utils.ValidateTime(stTime, gridStartTime) === CompareKey.lesser){
                    overrideSlot.startTime = gridStartTime;
                } 
                if(this.utils.ValidateTime(etTime, gridEndTime) === CompareKey.greater){
                    overrideSlot.endTime = gridEndTime; 
                };  
            })
        }
        let dpItems = dataFromAPI[0].pricingDetails.filter(x => !x.isOverride); 
            dpItems.forEach((ruleItem) => {
                    if(isFirstMap){
                        let stTime = this.localization.getTime(this.localization.getDate(ruleItem.startTime), this.localization.getTimeFormat());
                        let etTime = this.localization.getTime(this.localization.getDate(ruleItem.endTime), this.localization.getTimeFormat());   
                        ruleItem.startTime = stTime;
                        ruleItem.endTime = etTime;
                        if(this.utils.ValidateTime(stTime, gridStartTime) === CompareKey.lesser){
                            ruleItem.startTime = gridStartTime;
                        } 
                        if(this.utils.ValidateTime(etTime, gridEndTime) === CompareKey.greater){
                            ruleItem.endTime = gridEndTime; 
                        };             
                    }
                    let currentTime = this.localization.TimeToDate(ruleItem.startTime).getTime();
                    let slotIndex = this.getSlotIndex(pricingSkeleton, currentTime);
                    ruleItem['more'] = null;
                   
                    if (slotIndex > -1) {
                        if(ruleItem.applicableDays){
                            this.daysMapper(Number(ruleItem.applicableDays));
                        }
                        let ruleStdate = this.localization.delocalizeDisplayDate(this.localization.localizeDisplayDate(ruleItem.startDate));
                        let ruleEnDate = this.localization.delocalizeDisplayDate(this.localization.localizeDisplayDate(ruleItem.endDate));
                        let dateRange = this.localization.getDatesForGivenRange(ruleStdate, ruleEnDate);
                        if (dateRange.length > 0) {
                            dateRange.forEach(x => {
                                let currentDayAvailability = this.weekDays.find(wd=>wd.weekId == x.dayNumber);
                                if(currentDayAvailability.isAvailable){
                                    if(ruleItem.ruleCategoryId == UI.PricingRuleCategory.Utilization){
                                        this.pushToSkeleton(ruleItem, pricingSkeleton, slotIndex, slotDifference, gridType,x)
                                    }
                                }
                            });
                        }
                    }
                });
            this.applyDayOverride(pricingSkeleton, slotDifference, gridType);
        return pricingSkeleton;
    }

    pushToSkeleton(ruleItem, pricingSkeleton, slotIndex, slotDifference, gridType,currentDate){
        let ruleObj = {};
        ruleItem.totalHours = this.getTimeDifferenceInMinutes(this.localization.TimeToDate(ruleItem.startTime), this.localization.TimeToDate(ruleItem.endTime), slotDifference);
        ruleItem.hoursLag = this.getTimeDifferenceInMinutes(this.localization.TimeToDate(pricingSkeleton[slotIndex]['time']), this.localization.TimeToDate(ruleItem.startTime), slotDifference);
        if(gridType == UI.GridType.Week){
            ruleItem = this.adjustRulesBasedonHeight(ruleItem);
        }
        else if(gridType == UI.GridType.Day){
            ruleItem = this.adjustRulesBasedonWidthAndHeight(ruleItem);
        }           
        ruleObj['ag_' + currentDate.dateObj.getTime().toString()] = ruleItem;
        pricingSkeleton[slotIndex].yielding.push(ruleObj);
    }

    applyDayOverride(pricingSkeleton,slotDifference,gridType){
        this.overridenSlots.forEach((overrideslot)=>{
            let currentTime = this.localization.TimeToDate(overrideslot.startTime).getTime();
            let slotIndex = this.getSlotIndex(pricingSkeleton, currentTime);
            if(slotIndex > -1){
                let ruleStdate = this.localization.delocalizeDisplayDate(this.localization.localizeDisplayDate(overrideslot.startDate));
                let ruleEnDate = this.localization.delocalizeDisplayDate(this.localization.localizeDisplayDate(overrideslot.endDate));
                let dateRange = this.localization.getDatesForGivenRange(ruleStdate, ruleEnDate);
                if (dateRange.length > 0) {
                    dateRange.forEach(x => {
                        let overrideRuleItem = null;
                        let ruleStdate = this.localization.delocalizeDisplayDate(this.localization.getDate(overrideslot.startDate));
                        let ruleEnDate = this.localization.delocalizeDisplayDate(this.localization.getDate(overrideslot.endDate));
                        let overriddenRange = this.localization.getDatesForGivenRange(ruleStdate, ruleEnDate);
                        let isOverrideavailableForDate = overriddenRange.find((x)=> x.dateObj.getTime().toString() === x.dateObj.getTime().toString());
                        if(isOverrideavailableForDate){
                            overrideRuleItem = overrideslot;
                        }
                        if(overrideRuleItem){
                            this.pushToSkeleton(overrideRuleItem, pricingSkeleton, slotIndex, slotDifference, gridType,x)                                        
                        }
                    });
                }
            }
        });
    }


    // Consider this for Hourly Override
    getRulesAfterOverride(currentDate, ruleItemObject){
        let returnObj = [];
        this.overridenSlots.forEach((overrideSlot)=>{
            let ruleStdate = this.localization.delocalizeDisplayDate(overrideSlot.startDate);
            let ruleEnDate = this.localization.delocalizeDisplayDate(overrideSlot.endDate);
            let overriddenRange = this.localization.getDatesForGivenRange(ruleStdate, ruleEnDate);
            let isOverrideavailableForDate = overriddenRange.find((x)=> x.dateObj.getTime().toString() === currentDate.dateObj.getTime().toString());
            if(isOverrideavailableForDate){
            // Continue the logic to perform Hourly override. Replace Object if ruleItem and  overrideSlot time are same. Break the object into two or three and return array of object.
                let currentStartTime = (this.localization.TimeToDate(ruleItemObject.startTime)).getTime();
                let currentEndTime = (this.localization.TimeToDate(ruleItemObject.endTime)).getTime();
                overrideSlot.pricingDetails.forEach((overrideTime)=>{
                    let overSttime = (this.localization.TimeToDate(overrideTime.startTime)).getTime();
                    let overEdtime = (this.localization.TimeToDate(overrideTime.endTime)).getTime();
                    if (currentStartTime >= overSttime && currentEndTime <= overEdtime) {
                        returnObj = [];
                    }
                    else if(currentStartTime < overSttime && currentEndTime > overEdtime){
                        let obj1 = cloneDeep(ruleItemObject);
                        let obj2 = cloneDeep(ruleItemObject);
                        obj1.endTime = overrideTime.startTime;
                        returnObj.push(obj1);
                        obj2.startTime = overrideTime.endTime;
                        returnObj.push(obj2);
                    }
                    else if(currentStartTime >= overSttime && currentStartTime <= overEdtime && currentEndTime > overEdtime){
                        let obj = cloneDeep(ruleItemObject);
                        obj.startTime = overrideTime.endTime;
                        returnObj.push(obj);
                    }
                    else if(currentEndTime >= overSttime && currentEndTime <= overEdtime && currentStartTime < overSttime){
                        let obj = cloneDeep(ruleItemObject);
                        obj.endTime = overrideTime.startTime;
                        returnObj.push(obj);
                    }
                    else{
                        returnObj.push(ruleItemObject);
                    }
                });
            } else {
                return returnObj.push(ruleItemObject);
            }
        }); 
    }

    // getOverridenRuleItem(currentDate,gridStartTime,gridEndTime){
    //     let overrideRuleObj = null;
    //     this.overridenSlots$.forEach((overrideSlot,index)=>{          
    //         let ruleStdate = this.localization.delocalizeDisplayDate(this.localization.getDate(overrideSlot.startDate));
    //         let ruleEnDate = this.localization.delocalizeDisplayDate(this.localization.getDate(overrideSlot.endDate));
    //         let overriddenRange = this.localization.getDatesForGivenRange(ruleStdate, ruleEnDate);
    //         let isOverrideavailableForDate = overriddenRange.find((x)=> x.dateObj.getTime().toString() === currentDate.dateObj.getTime().toString());
    //         if(isOverrideavailableForDate){
    //             let stTime = this.localization.getTime(this.localization.getDate(overrideSlot.startTime), this.localization.getTimeFormat());
    //             let etTime = this.localization.getTime(this.localization.getDate(overrideSlot.endTime), this.localization.getTimeFormat());   
    //             overrideSlot.startTime = stTime;
    //             overrideSlot.endTime = etTime;
    //             if(this.utils.ValidateTime(stTime, gridStartTime) === CompareKey.lesser){
    //                 overrideSlot.startTime = gridStartTime;
    //             } 
    //             if(this.utils.ValidateTime(etTime, gridEndTime) === CompareKey.greater){
    //                 overrideSlot.endTime = gridEndTime; 
    //             };   
    //             overrideRuleObj = overrideSlot;
    //         }
    //     }); 
    //     return overrideRuleObj;
    // }

    adjustRulesBasedonWidthAndHeight(ruleItem){
        var totalHeight = (Number(ruleItem.totalHours) * 100) - 8;
        var headerRowHeight = Number(42);
        var ruleCategoryHeight = Number(26);
        var overallUsableHeight = Number(totalHeight - headerRowHeight);
        var ruleCategoryWidth = Number(250);
        var rulesInRow = Math.floor(Number(window.innerWidth - 170) / ruleCategoryWidth);
        var canAccomodateLengthForHeight = Math.floor(overallUsableHeight / ruleCategoryHeight);
        var canAccomodateLength = rulesInRow * canAccomodateLengthForHeight;
        var utilLength = ruleItem.rule.length;
        
        if (utilLength > canAccomodateLength) {
            ruleItem['rulesUI'] = ruleItem['rule'].slice(0, canAccomodateLength);
          if (ruleItem['rulesUI'].length > 0) {
            ruleItem['rulesUI'][ruleItem['rulesUI'].length - 1]["more"] = Number((utilLength > canAccomodateLength) ? (utilLength - canAccomodateLength) : (canAccomodateLength - utilLength));
          }
        } else {
            ruleItem['rulesUI'] = [...ruleItem['rule']];
        }
        return ruleItem;
    }

    adjustRulesBasedonHeight(ruleItem){
        var totalHeight = (Number(ruleItem.totalHours) * 100) - 8;
        var headerRowHeight = Number(42);
        var ruleCategoryHeight = Number(35);
        var overallUsableHeight = Number(totalHeight - headerRowHeight);
        var canAccomodateLength = Math.floor(overallUsableHeight / ruleCategoryHeight);
        var utilLength = ruleItem.rule.length;
        
        if (utilLength > canAccomodateLength) {
            ruleItem['rulesUI'] = ruleItem['rule'].slice(0, canAccomodateLength);
          if (ruleItem['rulesUI'].length > 0) {
            ruleItem['rulesUI'][ruleItem['rulesUI'].length - 1]["more"] = Number((utilLength > canAccomodateLength) ? (utilLength - canAccomodateLength) : (canAccomodateLength - utilLength));
          }
        } else {
            ruleItem['rulesUI'] = [...ruleItem['rule']];
        }
        return ruleItem;
    }



    getSlotIndex(pricingSkeleton, currentTime) {
        let idx = pricingSkeleton.findIndex((slot) => ((this.localization.TimeToDate(slot.startTime)).getTime() <= currentTime && (this.localization.TimeToDate(slot.endTime)).getTime() > currentTime));
        return idx;
    }

    daysMapper(daysOfWeek: DaysOfWeek) {
        this.weekDays = this.getWeekList();
        this.weekDays.forEach(wd=>wd.isAvailable = ((daysOfWeek & wd.id) == wd.id));
        return this.weekDays;
    }
    getWeekList() {
        return [
            {
                id: DaysOfWeek.Monday,
                value: "M",
                viewVal: "Mon",
                isAvailable: false,
                weekId: 1
            },
            {
                id: DaysOfWeek.Tuesday,
                value: "T",
                viewVal: "Tue",
                isAvailable: false,
                weekId: 2
            },
            {
                id: DaysOfWeek.Wednesday,
                value: "W",
                viewVal: "Wed",
                isAvailable: false,
                weekId: 3
            },
            {
                id: DaysOfWeek.Thursday,
                value: "T",
                viewVal: "Thu",
                isAvailable: false,
                weekId: 4
            },
            {
                id: DaysOfWeek.Friday,
                value: "F",
                viewVal: "Fri",
                isAvailable: false,
                weekId: 5
            },
            {
                id: DaysOfWeek.Saturday,
                value: "S",
                viewVal: "Sat",
                isAvailable: false,
                weekId: 6
            },
            {
                id: DaysOfWeek.Sunday,
                value: "S",
                viewVal: "Sun",
                isAvailable: false,
                weekId: 0
            },
        ]
    }
}