import { Injectable } from "@angular/core";
import { SpaLocalization } from "src/app/core/localization/spa-localization";
import { SpaUtilities } from "src/app/shared/utilities/spa-utilities";
import { DaysOfWeek } from "../dynamic-yielding.model";

@Injectable()
export class DynamicYieldingListBusiness {
  constructor(
    private localization: SpaLocalization,
    private utils: SpaUtilities
  ) {}

  /**
   * get days of week
   * @param dayOfWeek 
   * @returns 
   */
  getDaysOfWeek(dayOfWeek: number): [string, number[]] {
    let dayOfWeekDesc: string[] = [];
    let dayOfWeekIds: number[] = [];
    if (dayOfWeek >= DaysOfWeek.Saturday) {
      dayOfWeekDesc.push("S");
      dayOfWeekIds.push(DaysOfWeek.Saturday);
      dayOfWeek -= DaysOfWeek.Saturday;
    }

    if (dayOfWeek >= DaysOfWeek.Friday) {
      dayOfWeekDesc.push("F");
      dayOfWeekIds.push(DaysOfWeek.Friday);
      dayOfWeek -= DaysOfWeek.Friday;
    }

    if (dayOfWeek >= DaysOfWeek.Thursday) {
      dayOfWeekDesc.push("T");
      dayOfWeekIds.push(DaysOfWeek.Thursday);
      dayOfWeek -= DaysOfWeek.Thursday;
    }

    if (dayOfWeek >= DaysOfWeek.Wednesday) {
      dayOfWeekDesc.push("W");
      dayOfWeekIds.push(DaysOfWeek.Wednesday);
      dayOfWeek -= DaysOfWeek.Wednesday;
    }

    if (dayOfWeek >= DaysOfWeek.Tuesday) {
      dayOfWeekDesc.push("T");
      dayOfWeekIds.push(DaysOfWeek.Tuesday);
      dayOfWeek -= DaysOfWeek.Tuesday;
    }

    if (dayOfWeek >= DaysOfWeek.Monday) {
      dayOfWeekDesc.push("M");
      dayOfWeekIds.push(DaysOfWeek.Monday);
      dayOfWeek -= DaysOfWeek.Monday;
    }

    if (dayOfWeek >= DaysOfWeek.Sunday) {
      dayOfWeekDesc.push("S");
      dayOfWeekIds.push(DaysOfWeek.Sunday);
      dayOfWeek -= DaysOfWeek.Sunday;
    }

    return dayOfWeekDesc.length > 0
      ? [dayOfWeekDesc.reverse().join(","), dayOfWeekIds]
      : ["", []];
  }
}
