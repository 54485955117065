import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ButtonValue } from "src/app/common/Models/ag-models";
import { Localization } from "src/app/common/localization/localization";
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { PlayerDiscountTypeBusiness } from './player-discount-type.business';

@Component({
  selector: 'app-player-discount-type',
  templateUrl: './player-discount-type.component.html',
  styleUrls: ['./player-discount-type.component.scss'],
  providers: [PlayerDiscountTypeBusiness],
  encapsulation: ViewEncapsulation.None
})
export class PlayerDiscountTypeComponent implements OnInit {

  constructor(  private localization: Localization,private fb: UntypedFormBuilder, private business: PlayerDiscountTypeBusiness) {
      this.captions = this.localization.captions;
  }

  captions: any;
  playerDiscountForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  playerOptions = [];
  discountTypeOptions = [];
  tierLevelOptions = [];
  membershipLevelOptions = [];
  selectedDiscounts = [];
  tierOptions: any;
  
  ngOnInit(): void {
    this.initializeForm();
    this.getFormData();
    this.playerDiscountForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.enableSave();
    });
  }

  initializeForm(){
   this.playerDiscountForm = this.fb.group({
      playerType: '',
      discountType: '',
      tierLevel: '',
      membershipType: '',
      tierOption: 0
   });
   this.actionButton = {
    type: "primary",
    label: this.captions.btn_save,
    disabledproperty: true,
  };
  this.cancelButton = {
    type: "secondary",
    label: this.captions.btn_cancel,
  };
  }

  getFormData(){
    this.playerOptions = this.business.getPlayerOptions();
    this.tierLevelOptions = this.business.getTierOptions();
    this.discountTypeOptions = this.business.getDiscountTypes();
    this.membershipLevelOptions = this.business.getMembershipTypes();
    this.tierOptions = this.getmenuOptions();
  }

  getmenuOptions(){
    return [
      {
        id: 0,
        viewValue: this.captions.tierLevel
      },
      {
        id: 1,
        viewValue: this.captions.membershipType
      }
    ]
  }

  selectAll(event){
    this.discountTypeOptions.forEach(x=>x.selected = event.checked)
    this.selectedDiscounts = this.discountTypeOptions.filter(x=>x.selected);
    this.enableSave();
  }

  radioButtonChange(){
    console.log();
  }

  discountTypeChange(event, selectedObj){
    selectedObj.selected = event.checked;
    this.selectedDiscounts = this.discountTypeOptions.filter(x=>x.selected);
    this.enableSave();
  }

  enableSave(){
    this.actionButton.disabledproperty = !(this.playerDiscountForm.get('playerType').value && this.playerDiscountForm.get('tierLevel').value 
    && this.playerDiscountForm.get('membershipType').value && this.selectedDiscounts.length > 0)
  }

  onAction(e){

  }
  onCancel(e){

  }

}
