import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { menuTypes } from 'src/app/shared/shared.modal';
import { SortOrderPipe } from 'src/app/shared/pipes/sort-order.pipe';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SPAConfig } from 'src/app/core/config/SPA-config';
import { HttpServiceCall } from '../service/http-call.service';
import { AlertMessagePopupComponent } from '../alert-message-popup/alert-message-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { MsalIntegrationService } from '../service/MsalIntegration.service';
import { RetailFeatureFlagInformationService, ConfigKeys, FeatureName } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { SpaUtilities } from '../utilities/spa-utilities';
import { PropertyFeaturesConfigurationService } from 'src/app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { IdleTimeoutService } from '../service/session-expired.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { PropertyService } from 'src/app/common/services/property.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { NotificationFailureType } from './menu.model';
import { ChangePropertyComponent } from 'src/app/common/components/change-property/change-property.component';
import { ChangePropertySevice } from 'src/app/common/services/change-property.service';
import {TooltipPosition} from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { JWT_TOKEN } from 'src/app/app-constants';
import { Product } from 'src/app/common/enums/shared-enums';
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { JasperServerCommonDataService } from 'src/app/common/dataservices/jasperServerCommon.data.service';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { UserMachineConfigurationService } from 'src/app/core/services/user-machine-configuration.service';



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {

  menuList: any;
  menuItems: any;
  isMenu: boolean;
  positionOptions: TooltipPosition[] = ['right', 'above', 'left', 'below'];
  position = new UntypedFormControl(this.positionOptions[0]);
  moreTextName = '';
  moreListItem: any = [];
  isSubMenu = false;
  searchOpen = false;
  menusearchOpen = false;
  headerPopOver: any;
  logOutPopOver: any;
  lowerLevelSubMenu: any;
  verticalFlag = false;
  levelMenu: menuTypes;
  menuType = menuTypes;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isChangePropertyEnabled: boolean;
  isTherapistLogin: boolean = false;
  baseRedirection = '/home';
  @ViewChild('userPopOver') userPopUp: ElementRef;
  @ViewChild('logOutPopOver') logPopOver: ElementRef;
  @ViewChild('navBar') navBar: ElementRef;
  @ViewChild('RouterOutlet') outlet: RouterOutlet;
  @ViewChild('notificationPopOver') notificationPopOver;

  selectedItem: any;
  userName: string;
  userText: string;
  userRole: string;
  firstName: string;
  lastName: string;
  sortPipe: SortOrderPipe;
  captions: any;
  msgTxt: string = "";
  private childWindow: Window;
  transactionCountSubscription: Subscription;
  notificationCount: number = 0;
  notificationInfo: {id: number , message: string }[] = [];
  floatLabel:string;
  showJasperSoftServerMenu: boolean = false;
  openJaspersoftServer: string = "";

  @Input('menu')
  set MenuValue(value) {
    this.menuList = value;
    this.levelMenu = value.menuType;
    this.menuList.menu = this.sortPipe.transform(this.menuList.menu, 'order', 'aesc');
  }

  constructor(public router: Router, private dialog: MatDialog
    , private its: IdleTimeoutService
    , private _localization: SpaLocalization
    , private spaConfig: SPAConfig
    , private http: HttpServiceCall
    , private authentication: AuthenticationService
    , public msalService: MsalIntegrationService
    , private propertyService: PropertyService
    , private changePropertySevice: ChangePropertySevice
    , private _featureFlagService: RetailFeatureFlagInformationService
    , private _utils: SpaUtilities
    , private _propertyFeatureService: PropertyFeaturesConfigurationService
    , private _propertyInfo: RetailPropertyInformation
    , private elementRef: ElementRef, public quickLoginUtils: QuickLoginUtilities,
    private proService: PropertyService,
    private retailSharedService: RetailSharedVariableService,
    private manageSessionService: ManageSessionService
    , private PropertySettingService: PropertySettingDataService
    , private jasperServerCommon: JasperServerCommonDataService
    , private _dmConfigDataService: DMConfigDataService
    ,private userSessionConfig: UserMachineConfigurationService
  ) {
    this.msalService.Init();
    this.sortPipe = new SortOrderPipe();
    this.floatLabel = this._localization.setFloatLabel;
  }

  async getPropertyApiConfig() {
    const propertyId = this._localization.GetsessionStorageValue('propertyInfo', 'PropertyId')
    await this.proService.SetPropertyApiConfiguration(Number(propertyId));
  }

  async setOutletTerminalId()
  {
    const DefaultOutletId = this._localization.GetsessionStorageValue('userSessionConfigInfo', 'DefaultOutletId')
    const DefaultTerminalId = this._localization.GetsessionStorageValue('userSessionConfigInfo', 'DefaultTerminalId')
    if(DefaultOutletId && Number(DefaultOutletId) > 0 && DefaultTerminalId && Number(DefaultTerminalId) > 0)
    {
      this.retailSharedService.SelectedOutletId=Number(DefaultOutletId);
      this.retailSharedService.SelectedTerminalId=Number(DefaultTerminalId);
    }

  }
   RefreshConfig(isFromPropertyChangeEvent : boolean = false){
    if (!sessionStorage.getItem("giftCardConfiguration")) {
      this._propertyFeatureService.GetGiftCardConfiguration().then((config) => {
        this._propertyInfo.SetGiftCardConfiguration(config);
      });
    }
    this._featureFlagService.RefreshConfig();
  }
  ngOnInit() {    
    let userTherapist = sessionStorage.getItem('UserTherapist');
    this.isTherapistLogin = userTherapist ? true : false;
    this.baseRedirection = this.isTherapistLogin? '/appointment': '/home';
    this.captions = this._localization.captions;
    this.userName = this._localization.GetUserInfo("userName");
    this.firstName = this._localization.GetUserInfo("firstName");
    this.lastName = this._localization.GetUserInfo("lastName");
    this.userRole = this._localization.GetUserInfo("roleName");
    this.moreTextName = this.captions.lbl_more;
    this.openJaspersoftServer = this.captions.common.lbl_OpenJasperSoft;
    this.handleShowAndHideJasperSoftStudioMenu();
    this.transactionCountSubscription = this.manageSessionService.transactionCount.subscribe(res => {
      const revenueresult = res && res.find(x => x.id === NotificationFailureType.revenuePostingFailure) ;
      const paymentresult = res && res.find(x => x.id === NotificationFailureType.paymentTransactionFailure) ;
      const cgpsLogResult = res && res.find(x => x.id === NotificationFailureType.cgpsLog);
      if(cgpsLogResult){
        if(this.notificationInfo && (!this.notificationInfo.some(x => x.id === NotificationFailureType.cgpsLog))){
          this.notificationInfo.push({
            id: NotificationFailureType.cgpsLog,
            message: cgpsLogResult.message
          });
        }
      }
      const dMPostingResult = res && res.find(x => x.id == NotificationFailureType.dMPostingFailure);
      if (revenueresult && revenueresult.count > 0) {
        if (this.notificationInfo && this.notificationInfo.length > 0 &&
           this.notificationInfo.some(x => x.id === NotificationFailureType.revenuePostingFailure )) {
            this.notificationInfo.find(x => x.id === NotificationFailureType.revenuePostingFailure ).message =
            this._localization.replacePlaceholders(this.captions.RevenuePostingInfo, ['count'], [ revenueresult.count]);
          } else {
          this.notificationInfo.push({
            id :  NotificationFailureType.revenuePostingFailure,
            message : this._localization.replacePlaceholders(this.captions.RevenuePostingInfo, ['count'], [ revenueresult.count])
          });
        }
      }
      if (paymentresult && paymentresult.count > 0) {
        if (this.notificationInfo && this.notificationInfo.length > 0 &&
          this.notificationInfo.some(x => x.id === NotificationFailureType.paymentTransactionFailure )) {
           this.notificationInfo.find(x => x.id === NotificationFailureType.paymentTransactionFailure ).message =
           this._localization.replacePlaceholders(this.captions.FailedTransLogInfo, ['count'], [ paymentresult.count]);
         } else {
         this.notificationInfo.push({
           id :  NotificationFailureType.paymentTransactionFailure,
           message :  this._localization.replacePlaceholders(this.captions.FailedTransLogInfo, ['count'], [ paymentresult.count])
         });
       }
      }
      if (dMPostingResult && dMPostingResult.count > 0) {
        if (this.notificationInfo && this.notificationInfo.length > 0 &&
           this.notificationInfo.some(x => x.id === NotificationFailureType.dMPostingFailure )) {
            this.notificationInfo.find(x => x.id === NotificationFailureType.dMPostingFailure ).message =
            this._localization.replacePlaceholders(this.captions.DMPostingInfo, ['count'], [ dMPostingResult.count]);
          } else {
          this.notificationInfo.push({
            id :  NotificationFailureType.dMPostingFailure,
            message : this._localization.replacePlaceholders(this.captions.DMPostingInfo, ['count'], [ dMPostingResult.count])
          });
        }
      }
      this.notificationCount = this.notificationInfo.length;
    });
    this.quickLoginUtils.resetQuickIdDetails();
    this.getPropertyApiConfig();
    this.setOutletTerminalId();
    if (!this.its.tokenTimerSubscription) {
      this.its.forceLogOff();
    }
    if (!sessionStorage.getItem("QuickIdConfig")) {
      this._propertyFeatureService.SetQuickIdConfigSettingForSpa("QuickIdConfig");
    }
    this.isChangePropertyEnabled = this.propertyService.isChangePropertyEnabled();
    if (this.firstName == "undefined" || this.lastName == "undefined" || this.firstName == undefined || this.lastName == undefined) {
      this.userText = this.userName ? this.userName.charAt(0).toUpperCase() : '';
    }
    else {
      this.userText = this.firstName.charAt(0).toUpperCase() + this.lastName.charAt(0).toUpperCase();
    }
    if (this.levelMenu === menuTypes.tertiary) {
      this.selectedItem = this.menuList.menu.find(x => this.router.url.indexOf(x.routePath) > -1);
      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        this.selectedItem = this.menuList.menu.find(menu => this.router.url.indexOf(menu.routePath) > -1);
        this.selectedItem = { ...this.selectedItem };
      });
    }
    
    this.RefreshConfig();
    this._dmConfigDataService.SetDataMagineConfig();
    if(!sessionStorage.getItem("enableMachineTransaction")) {
      this._propertyFeatureService.GetMiscConfig();
    }
    // On Property Change 
    this.changePropertySevice.propertyChanged$.pipe(takeUntil(this.destroyed$)).subscribe(async propertyChanged => {
      if (propertyChanged) {
        console.log(propertyChanged);
        this.userRole = this._localization.GetUserInfo("roleName");
        this._featureFlagService.reset();
        this.RefreshConfig(propertyChanged);
        //this.userDefaultsService.syncDefaultValues(Number(this._localization.GetUserInfo("userId")));
        // this.userSessionConfig.getAllClientSetting().then(defaultsSetting => {
        //   sessionStorage.setItem('defaultSettings', JSON.stringify(defaultsSetting));
        // });
        if (!this._propertyInfo.UseRetailInterface) {
          this.notificationCount = 0;
          this.notificationInfo = [];
          this.manageSessionService.startTimerForNotification(1);
        }
        this.quickLoginUtils.resetQuickIdDetails(); 
        this._propertyFeatureService.SetQuickIdConfigSettingForRetail("QuickIdConfig"); 
        this._propertyFeatureService.SetQuickIdConfigSettingForSpa("QuickIdConfig"); 
        //this.changePropertySevice.UnSubscribePropertyChangedEvent();
        this._propertyFeatureService.GetMiscConfig();
        // if (!sessionStorage.getItem("acesJwt")) {
        //   this.setAcesToken(); //For getting Member token 
        // }
      }
    });
    this.msalService.currentUser.subscribe(txt => this.msgTxt = txt);
    
    
    this.changePropertySevice.roleName$.pipe(takeUntil(this.destroyed$)).subscribe(roleName => {
      if (roleName) {
        this.userRole = roleName;
      }
    });

    this.propertyService.SetExternalPMSConfigFlag();
  }


  changeProperty(e) {
    const dialogRef = this.dialog.open(ChangePropertyComponent, {
      width: '300px',
      height: '35%',
      disableClose: true,
      hasBackdrop: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(async () => {
    let defaultsSetting = await this.userSessionConfig.getDefaultSettings();
    sessionStorage.setItem('defaultSettings', JSON.stringify(defaultsSetting));
      console.log('dialog closed');
    });
    this.logOutPopOver.hide();
    e.stopPropagation();

  }

  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.text === val2.text;
  }

  ngAfterViewInit() {
    if (this.levelMenu === menuTypes.primary) {
      setTimeout(() => {
        this.bindHeaderData();
      }, 1);
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    if (this.transactionCountSubscription) {
      this.transactionCountSubscription.unsubscribe();
    }
  }

  bindHeaderData() {
    const menuItem = cloneDeep(this.menuList.menu);
    let menuTobeShown = [];
    this.moreListItem = [];
    let headerWidth = 0;
    let moreCalc = false;
    const parentelementWidth = this.navBar.nativeElement.clientWidth;
    for (let i = 0; i < menuItem.length; i++) {
      const elementWidth = this.getTextWidth(menuItem[i].text, '100 14px LatoWeb');
      headerWidth += elementWidth + 66;
      if ((parentelementWidth - headerWidth) >= elementWidth) {
        menuTobeShown.push(menuItem[i]);
      } else {
        moreCalc = true;
        break;
      }
    }
    if (moreCalc) {
      menuTobeShown = [];
      headerWidth = 0;
      const moreWidth = this.getTextWidth(this.moreTextName, '100 14px LatoWeb');
      const LegendsWidth = parentelementWidth - (moreWidth + 66);
      for (let i = 0; i < menuItem.length; i++) {
        const elementWidth = this.getTextWidth(menuItem[i].text, '100 14px LatoWeb');
        headerWidth += elementWidth + 66;
        if ((LegendsWidth - headerWidth) >= elementWidth) {
          menuTobeShown.push(menuItem[i]);
        } else {
          this.moreListItem.push({ imgPath: menuItem[i].imgPath, text: menuItem[i].text, routePath: menuItem[i].routePath, visibility: menuItem[i].visibility });
        }
      }
    }
    this.menuItems = menuTobeShown;
    this.onResize();
  }

  getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text.toUpperCase());
    if (canvas) {
      canvas.remove();
    }
    return Math.ceil(metrics.width);
  }

  userPop(logOutPopOver) {
    this.logOutPopOver = logOutPopOver;
    const logOut = this.logOutPopOver.element.nativeElement;
    logOut.querySelectorAll('div')[0].style.display = 'none';
    setTimeout(() => {
      const popover = logOut.querySelectorAll('div');
      const leftValue = popover[0].style.left.split('px');
      popover[0].style.left = (Number(leftValue[0])) + 'px';
      popover[0].style.top = 60 + 'px';
      popover[0].style.display = 'block';
    }, 100);
  }

  onResize() {
    if (this.headerPopOver) {
      this.headerPopOver.hide();
    }

    if (this.logOutPopOver) {
      this.logOutPopOver.hide();
    }
  }

  openPopUp(popOver) {
    this.headerPopOver = popOver;
  }

  onSearch(e: any) {
    this.searchOpen = !this.searchOpen;
  }

  openGlobalSearch(e, type) {
    this.searchOpen = !this.searchOpen;
    this.menusearchOpen = false;
  }

  openMenuSearch() {
    this.menusearchOpen = !this.menusearchOpen;
    this.searchOpen = false;
  }

  OptionSelected(event) {
    this.searchOpen = false;
  }

  MenuOptionSelected(event) {
    this.menusearchOpen = false;
  }

  navigateTo(option) {
    this.router.navigate([option.value.routePath], { state: { ShowPopup: true, onSubmoduleChange: true } });
  }

  async iconClick() {
    let help_hosturl = this.spaConfig.getUrl('host.documentation') + 'spa/';
    let help_page = 'AgilysysSpa_Home.htm';
    let appver = sessionStorage.getItem('productVersion');
    let dotRegex = /\./gi;
    let productVersion = appver && appver != 'null' ? appver : '12.2';
    let _productVersion = productVersion.replace(dotRegex, '_');

    let url = help_hosturl + _productVersion + '/' + help_page;
    let userSesion = sessionStorage.getItem('_jwt');
    const session = sessionStorage.getItem('userSession');
    let isAuthorized = await this.http.createHelpUserSession();
    if (isAuthorized && userSesion) {
      url = url + '?doc_id=' + session;
      setTimeout(() => { window.open(url, '_blank') }, 1000)
    }
  }

  openAboutDialog() {
    let data = '';
    let dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '300px',
      maxWidth: '80vw',
      height: 'auto',
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      data: { headername: this.captions.header.about, closebool: true, type: 'AL', datarecord: data, buttonName: this._localization.captions.common.OK },
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe();
  }

  logout() {
    window.localStorage.setItem('logout-event', JSON.stringify({jwt:sessionStorage.getItem(JWT_TOKEN),Product:Product.SPA,propertyInfo:this._propertyInfo.GetPropertyInfoByKey('PropertyId')}))
    this.its.onUserLogOutClick();
    this.logoutEatec();
    if (this.msalService.checkAccount()) {
      this._utils.ShowErrorMessage(this.captions.header.LogoutMailBox, this.captions.header.ConfirmOutlookSignOut);
      return false;
    }
    this._featureFlagService.reset();
    this.authentication.logout();
  }

  logoutEatec() {

    let eatecUrl = this._propertyInfo.getEatecURI;
    const url = 'sso/logout';

    const eatecToken = sessionStorage.getItem('eatecJwt');

    if (!eatecUrl || !eatecToken) {
      return '';
    }

    if (!eatecUrl.endsWith('/')) {
      eatecUrl += '/';
    }

    eatecUrl = `${eatecUrl}${url}`;

    const doc = this.elementRef.nativeElement.offsetParent;
    const eatecIframe = doc.querySelector('#eatec-iframe');

    if (eatecIframe) {
      doc.removeChild(eatecIframe);
    }

    doc.insertAdjacentHTML('beforeend', '<iframe id="eatec-iframe" style="display:none" src="' + eatecUrl + '"></iframe>');
  }

  loginListener(): any {
    this.msalService.loginEvent.subscribe(item => this.msalService.loggedIn && this.openOutlook());
  }

  serviceLogout() {
    window.onbeforeunload = null;
    this.msalService.logout();
    if (this.childWindow && this.childWindow.length) {
      this.childWindow.close();
    }
    this.msalService.logoutEvent.next(true);
  }

  openOutlook(): any {
    if (this.childWindow == undefined || (this.childWindow && !this.childWindow.length)) {
      this.childWindow = window.open("https://outlook.office365.com/mail/" + this.msalService.getAccount().userName, "outlookWindow", "_blank");
    }
    else {
      if (this.childWindow && this.childWindow.length) {
        this.childWindow.focus();
      }
    }
  }

  removeRevenuePostInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.revenuePostingFailure);
  }

  removePaymentFailureInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.paymentTransactionFailure);
  }
  removeCgpsFailureInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.cgpsLog);
  }

  removeDMReceiptLogInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.dMPostingFailure);
  }

  routeTransc(id: number) {
    if (id === NotificationFailureType.revenuePostingFailure) {
      this.router.navigate(['/shop/viewshop/retailtransactions/revenuepostingslog']);
      this.removeRevenuePostInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    else if (id === NotificationFailureType.paymentTransactionFailure){
      this.router.navigate(['/shop/viewshop/retailtransactions/transactionslog']);
      this.removePaymentFailureInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    else if (id === NotificationFailureType.dMPostingFailure){
      this.router.navigate(['/shop/viewshop/retailtransactions/datamaginereceiptlog']);
      this.removeDMReceiptLogInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    else if (id === NotificationFailureType.cgpsLog){
      this.router.navigate(['/settings/utilities/cgpsLogging']);
      this.removeCgpsFailureInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    this.notificationPopOver.hide();
  }
  handleShowAndHideJasperSoftStudioMenu() {
    let sessionValueofJasperStudio = sessionStorage.getItem('showJasperSoftServerMenu');
    if (sessionValueofJasperStudio != null) {
      this.showJasperSoftServerMenu = sessionValueofJasperStudio == 'true';
    } else {
      this.showJasperSoftServerMenu = true;
    }
  }
  async openJasperSoftServerLink() {
    const [userattributeupdate,jasperServerURL,headers] = await  Promise.all([
      this.PropertySettingService.UpdateRoleAndAttributeToUser(),
      this.jasperServerCommon.GetJasperServerBaseURL(), 
      this.jasperServerCommon.GetJasperServerHeader()
   ]);
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        var url = jasperServerURL + "/flow.html?_flowId=homeFlow";
        let jaspersoftNavigationUri = url;
        window.open(jaspersoftNavigationUri, '_blank');
      }
    });
    let data = "";
    xhr.open("GET", jasperServerURL + "/rest_v2/serverInfo");
    if(headers){
      Object.keys(headers).forEach(key => {
        if (headers[key] !== null) {
            xhr.setRequestHeader(key, headers[key]);
        }
    });
    }
    xhr.send(data);
  }

  notificationAlert()
  {
    this._localization.notification$.next(true)
   }
}
