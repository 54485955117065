import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DefaultIniEnableGridSort, IniEnableGridSort } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { Formaters } from 'src/app/eatecui/source/transaction/shared/formaters';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';
import { GridColumnReorder, GridInfiniteScrollConfig, GridPaginationConfig, GridResizeRows, GridSearchConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { ConfirmPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { AgilysysSessionExpiredComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-session-expired/agilysys-session-expired.component';
import { CreateTransaction } from '.';
import { GridActionsConfig, GridConfiguration, GridItemListActions, GridTypeFilterConfig, TitleType, TransactionConfiguration } from '../transaction.interface';
import { ItemListGrid } from '../view-transaction/view-configuration.interface';
// import * as remapjson from '../../../../../assets/json/ui-control/remapsample.json';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { GridFilterConfig } from 'src/app/eatecui/source/shared/models/product-grid/grid.interface';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { GridModels } from 'src/app/eatecui/source/shared/models/grid-models';

export const ItemListGridConfiguration: ItemListGrid
    = (ViewHeaderEntity: any,
        ViewDetailsEntity: any,
        TransactionCollection: TransactionConfiguration,
        httpService: HttpService,
        commonService: CommonService,
        translateService?: TranslateService,
        TilesHeaderTrascationEntity?: any,
        responseData?: Array<any>,
        dialog?: MatDialog,
        toastrService?: ToastrService): any => {
        try {
            if (TransactionCollection) {
                const moduleName = TransactionCollection.Name;
                const entityName = (ViewHeaderEntity && ViewHeaderEntity.length > 0) ? ViewHeaderEntity[0]['$']['Name'].toString() : TransactionCollection.Name;
                // const productremapJson = (remapjson as any).default;
                const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                const gridData = {} as GridConfiguration;
                // if (moduleName === 'Physical Inventory') {
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.
                    ItemGridConfig.GridCustomComponents = GridModels.setCustomEditors();
                // }
                if (moduleName === 'Batch Item Maintenance') {
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.RowClassRules = {
                        'ag-row-invalid': function (params) {
                            if (params && params.data && params.data.hasOwnProperty('IsValid') && !params.data.IsValid) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        'ag-row-imported': function (params) {
                            if (params && params.data && (params.data.IsValid && !params.data.IsNew)) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                        'ag-row-newimported': function (params) {
                            if (params && params.data && params.data.IsNew && params.data.IsValid) {
                                return true;
                            } else {
                                return false;
                            }
                        }

                    };
                }
                if (moduleName === 'PlanHead' || moduleName === 'productionplan') {
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.RowClassRules = {
                        'ag-sales-updated-color': function (params) {
                            if (params && params.data && params.data.IsUpdated) {
                                params.data.IsUpdated = false;
                                return true;
                            } else {
                                return false;
                            }
                        }
                    };
                }
                gridData.GridFilter = CreateTransaction.ItemListGridFilterOption(
                    ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
                gridData.GridData = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
                gridData.GridActions = {};
                gridData.GridActions.GridTitle = '';
                gridData.GridActions = {} as GridActionsConfig;
                gridData.GridActions.ShowAddButton = false;
                gridData.GridActions.ShowRefresh = false;
                gridData.GridActions.TotalItems = 0;
                gridData.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
                gridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
                gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
                gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
                gridData.GridActions.SearchConfig = {} as GridSearchConfig;
                gridData.GridActions.SearchConfig.EnabledSearch = true;
                gridData.GridActions.SearchConfig.SearchPlaceholder = 'Search';
                gridData.GridActions.PaginationConfig = {} as GridPaginationConfig;
                gridData.GridActions.PaginationConfig.EnabledPaginationation = false;
                gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
                gridData.GridActions.GridTitleType = {} as TitleType;
                gridData.GridActions.GridTitleType.Type = 'text';
                gridData.GridActions.GridTitleType.TypeConfig = [];
                gridData.GridActions.FilterConfig = {} as GridFilterConfig;
                gridData.GridActions.FilterConfig.EnableFilter = gridData.GridFilter.length > 0 ? true : false;
                gridData.GridActions.FilterConfig.FilterOptions = gridData.GridFilter as any;
                const GridColumnConfiguration = TransactionCollection.CreateTranscationConfig.ItemsConfig.
                    ItemList.ItemGridConfig.GridColumnConfiguration;
                gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = GridColumnConfiguration ?
                    GridColumnConfiguration.EnableColumnShuffle : false;
                gridData.GridActions.ShuffleColumnConfig.ColumnsList = GridColumnConfiguration ?
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                        GridHeaderDefination : [];
                gridData.GridActions.ShuffleColumnConfig.GridName = GridColumnConfiguration ? GridColumnConfiguration.GridName : null;
                const itemListSortEnabled = sessionStorage.getItem(IniEnableGridSort) ?
                    JSON.parse(sessionStorage.getItem(IniEnableGridSort)) : DefaultIniEnableGridSort;
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridHeaderDefination.forEach(header => {
                    header.headerName = ProductDataRemap.FieldDisplayNameEngine(
                        productremapJson, entityName, header.headerName, header.field);
                    if (header.type === 'numericColumn' && header.formatter === 'Number') {
                        header.valueFormatter = Formaters.numberFormatter;
                    } else if (header.type === 'dateColumn') {
                        header.valueFormatter = Formaters.dateformater;
                        if (header.field === 'ExpireDate') {
                            header.valueFormatter = (params: any) => {
                                if (params.data.hasOwnProperty('EnableExpireDate')) {
                                    params.colDef.editable = params.data.EnableExpireDate;
                                }
                                return Formaters.dateformater(params);
                            };
                        }
                    } else if (header.columnType === 'decimal') {
                        header.valueFormatter = (params: any) => {
                            return Formaters.decimalFormatter(params);
                        };
                        header['allowDigits'] = constants.WholeNumberLength;
                        header['focusDigits'] = constants.FocusNumberLength;
                    } else if (header.columnType === 'currency') {
                        header.valueFormatter = Formaters.currencyFormater;
                        header['allowDigits'] = constants.WholeNumberCurrencyLength;
                        header['focusDigits'] = constants.FocusNumberCurrencyLength;
                    }
                    if (header.hasOwnProperty('checkEditFunction') && header.checkEditFunction) {
                        header.editable = checkEditFunction.bind(this);
                    }
                    if (header.type === 'numericColumn') {
                        if (header.hasOwnProperty('cellEditor') && header.hasOwnProperty('cellEditorParams')) {
                            if (header['cellEditorParams'].hasOwnProperty('Calculation') &&
                                header['cellEditorParams']['Calculation']['OperationType'] === 'Divide') {
                                header.valueParser = (params: any) => {
                                    if (params.hasOwnProperty('value')) {
                                        const currentValue = params.value;
                                        if (params.data.hasOwnProperty('FGRecipes') && params.data.FGRecipes
                                            && params.data.FGRecipes.length > 0) {
                                            if (params.data.hasOwnProperty('ProdItemId') && params.data.ProdItemId !== null) {
                                                const selectedFgItem = params.data.FGRecipes
                                                    .filter(x => x.Id === parseInt(params.data.ProdItemId, 10));
                                                if (selectedFgItem && selectedFgItem.length > 0) {
                                                    const BlanketQty = selectedFgItem[0].BatchSizeQuantity;
                                                    if (BlanketQty) {
                                                        params.data.Batches = currentValue / BlanketQty;
                                                    }
                                                }
                                            } else if (params.data.hasOwnProperty('BlanketQty')) {
                                                if (params.data.BlanketQty && params.data.BlanketQty !== null) {
                                                    params.data.Batches = currentValue / params.data.BlanketQty;
                                                }
                                            }

                                        }
                                    }

                                };
                            }
                        }
                    }
                    if (moduleName === 'Butchery') {
                        if (header.cellEditor === 'dynamicDropdown' && header.field === 'ProdItemName') {
                            header.valueFormatter = (params: any, oldVal: number, newVal: any) => {
                                if ((oldVal && newVal && oldVal !== parseInt(newVal, 10)) || !oldVal && newVal) {
                                    const recipeUsed = params.data.FGRecipes.filter(x => x.Id === parseInt(newVal, 10))[0];
                                    oldVal = oldVal ? oldVal : null;
                                    if (!recipeUsed.IsButcheryRecipe) {
                                        const rowNode = params.api.getRowNode(params.rowIndex);
                                        params.api.stopEditing();
                                        rowNode.setDataValue('ProdItemId', oldVal);
                                        const productItemName = oldVal ? params.data.FGRecipes
                                            .filter(x => x.Id === parseInt(oldVal.toString(), 10))[0].Name : '';
                                        rowNode.setDataValue('ProdItemName', productItemName);
                                        params.api.redrawRows({ rowNodes: [rowNode] });
                                        const ConfirmPopUpDialog: ConfirmPopModel = {
                                            PopupHeaderContent: translateService.instant('Butchery.ErrorOccurred'),
                                            PopUpContent: translateService.instant('Butchery.RecipeAlert')
                                        };
                                        dialog.closeAll();
                                        const dialogRef = dialog.open(AgilysysSessionExpiredComponent, {
                                            width: '400px',
                                            height: '222px',
                                            data: ConfirmPopUpDialog,
                                            disableClose: true
                                        });
                                        dialogRef.afterClosed().subscribe(res => {
                                            if (res) {
                                                params.api.startEditingCell({
                                                    rowIndex: params.rowIndex,
                                                    colKey: 'ProdItemName'
                                                });
                                            }
                                        });
                                    } else {
                                        const rowNode = params.api.getRowNode(params.rowIndex);
                                        const FgCollection: Array<any> = FGRecipesValuation(params, parseInt(newVal.toString(), 10));
                                        /**
                                            * Raw Selected
                                            */
                                        const rawItemName: GetCellEditorInstancesParams = {
                                            rowNodes: [rowNode],
                                            columns: ['RawItemName'],
                                        };
                                        const rawItemNameInstance = params.api.getCellEditorInstances(rawItemName);
                                        if (rawItemNameInstance.length > 0 && FgCollection.length > 0) {
                                            rawItemNameInstance[0].params.data['RawItemName'] = FgCollection[0]['RawItemName'];
                                            rawItemNameInstance[0].value = FgCollection[0]['RawItemName'];
                                            rawItemNameInstance[0].params.value = FgCollection[0]['RawItemName'];
                                        } else {
                                            if (FgCollection.length > 0) {
                                                rowNode.setDataValue('RawItemName', FgCollection[0]['RawItemName']);
                                            }
                                        }


                                        if (recipeUsed.hasOwnProperty('QtyUsed')) {
                                            const salesAmount: GetCellEditorInstancesParams = {
                                                rowNodes: [rowNode],
                                                columns: ['QtyUsed'],
                                            };
                                            const salesAmountinstances = params.api.getCellEditorInstances(salesAmount);
                                            if (salesAmountinstances.length > 0 && FgCollection.length > 0 &&
                                                FgCollection[0]['QtyUsed']) {
                                                const value = (params.data.Qty * FgCollection[0]['QtyUsed']);
                                                salesAmountinstances[0].params.data['QtyUsed'] = value;
                                                salesAmountinstances[0].value = value;
                                                salesAmountinstances[0].params.value = value;
                                                salesAmountinstances[0].input.value = value;
                                            } else {
                                                if (FgCollection.length > 0 && FgCollection[0]['QtyUsed']) {
                                                    rowNode.setDataValue('QtyUsed', FgCollection[0]['QtyUsed']);
                                                    if (params.data.Qty) {
                                                        const value = (params.data.Qty * FgCollection[0]['QtyUsed']);
                                                        rowNode.setDataValue('QtyUsed', value);
                                                    }
                                                }

                                            }
                                        }

                                    }
                                }
                            };
                        }
                        if (header.field === 'Qty') {
                            header.valueParser = (params: any, newValue: any) => {
                                if (newValue) {
                                    const FgCollection: Array<any> =
                                        FGRecipesValuation(params, parseInt(params.data?.ProdItemId?.toString(), 10));
                                    const rowNode = params.api.getRowNode(params.node?.rowIndex);
                                    if (newValue && params.data?.QtyUsed) {
                                        const valueQuality = parseInt(params.data?.QtyUsed, 10) * parseInt(newValue, 10);
                                        params.data.QtyUsed = valueQuality;
                                    } else {
                                        if (params.data?.ProdItemId) {
                                            if (FgCollection.length > 0 && FgCollection[0]['QtyUsed']) {
                                                params.data.QtyUsed = FgCollection[0]['QtyUsed'];
                                                if (newValue) {
                                                    const value = (newValue * FgCollection[0]['QtyUsed']);
                                                    params.data.QtyUsed = value;
                                                }
                                            }
                                        } else {
                                            params.data.QtyUsed = '';
                                        }
                                    }
                                    const instParams: GetCellEditorInstancesParams = {
                                        rowNodes: [rowNode],
                                        columns: ['QtyUsed'],
                                    };
                                    const salesAmountinstances = params.api.getCellEditorInstances(instParams);
                                    if (salesAmountinstances.length > 0 && FgCollection.length > 0 &&
                                        FgCollection[0]['QtyUsed']) {
                                        const value = (params.data.Qty * FgCollection[0]['QtyUsed']);
                                        salesAmountinstances[0].params.data['QtyUsed'] = value;
                                        salesAmountinstances[0].value = value;
                                        salesAmountinstances[0].params.value = value;
                                        salesAmountinstances[0].input.value = value;
                                    } else {
                                        if (FgCollection.length > 0 && FgCollection[0]['QtyUsed']) {
                                            rowNode.setDataValue('QtyUsed', FgCollection[0]['QtyUsed']);
                                            if (params.data.Qty) {
                                                const value = (params.data.Qty * FgCollection[0]['QtyUsed']);
                                                rowNode.setDataValue('QtyUsed', value);
                                            }
                                        }

                                    }
                                }
                            };
                        }
                    }
                    if (moduleName === 'Purchase Request' && header.from === 'PR') {
                        header.valueParser = (params: any, newValue: any) => {
                            console.log('PR', params, newValue);
                        };
                    }
                    if (header.hasOwnProperty('checkRowVariance') && header.checkRowVariance) {
                        header.cellStyle = function (params: any) {
                            if (header.checkRowVariance.VarianceType === 'ValidateId') {
                                if (params.data.hasOwnProperty('ProdOptionId')) {
                                    const color = (params.data.ProdOptionId === 1 || params.data.ProdOptionId === 3) ?
                                        header.checkRowVariance.VarianceColor : '';
                                    return { color: color };
                                }
                            }
                            if (header.checkRowVariance.VarianceType === 'neagativevalue') {
                                if (params.data.hasOwnProperty(header.field)) {
                                    const variantColor = (params.data[header.field] < 0) ? header.checkRowVariance.VarianceColor : '';
                                    return { color: variantColor };
                                }
                            }

                            return null;
                        };
                    }
                    if (header.field === 'icon' &&
                        header.hasOwnProperty('categoryIcon') && header.categoryIcon.EnableCategoryIcon) {
                        header.cellRenderer = function (params: any) {
                            let a = `<div class='grid-icon'>`;
                            const IconObject = CategoryIcons.categoryIconName(params.data);
                            if (IconObject !== null && IconObject !== undefined &&
                                IconObject) {
                                a += `<i class="${IconObject.IconName}" title="${IconObject.IconToolTip}"></i>`;
                            }
                            a += `</div>`;
                            return a;
                        };
                        header.minWidth = '10';
                    }
                    if (header.headerName === 'Actions') {
                        header.suppressMovable = true;
                    }
                    header.customSort = !itemListSortEnabled && header.customSort ? false : header.customSort;
                    if (header.hasOwnProperty('sortDirection')) {
                        header.sortDirection = '';
                    }
                    if (header.hasOwnProperty('showCurrencySymbol') && header.showCurrencySymbol) {
                        const BaseCurrencyId = parseInt(sessionStorage.getItem('BaseCurrency'), 10);
                        const Currencies = constants.currencySymbol;
                        const CurrencyCode = CommonService.getCurrencyCode(BaseCurrencyId);
                        const symbol = Currencies.Symbols.filter(curr => curr.Code === CurrencyCode)[0].Symbol;
                        header.headerName = header.headerName + ` (${symbol})`;
                    }
                });
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridActions = gridData.GridActions;
                // if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.hasOwnProperty('ShowDropdown')
                //     && TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.ShowDropdown) {
                //     TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.DropdownOptions =
                //         dropdownOptions(TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.
                //             ItemGridConfig.GridItemListAction, httpService, TransactionCollection as TransactionConfiguration);
                // }
                return TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig;
            }
        } catch (error) {
            console.error('Error occurred in ItemListGridConfiguration', error);
        }
    };

/**
 * @method FGRecipesValuation
 * @params FgArray
 */
function FGRecipesValuation(Params: any, currentValue: number): Array<any> {
    try {
        let fgCollection: Array<any> = [];
        if (Params && Params.data && Params.data.FGRecipes && Params.data.FGRecipes.length > 0) {
            fgCollection = Params.data.FGRecipes.filter(f => f.Id === currentValue);
        }
        return fgCollection;

    } catch (error) {
        console.error(error);
    }
}

function checkEditFunction(event) {

    let val: any;
    if (event.data.Qty === undefined || event.data.Qty?.toString() === '') {
        val = '0.00';
    } else {
        val = event.data.Qty?.toString();
    }
    if (event.data && val && isNaN(Number((val?.split(','))?.join('')))) {
        return false;
    } else {
        return true;
    }

}

function dropdownOptions(DropDown: GridItemListActions, httpService: HttpService, TransactionCollection: TransactionConfiguration) {
    try {
        const locationId = TransactionCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormGroupData?.LocationId;
        const postObject = {
            'Skip': 0,
            'Take': 0,
            'FetchAll': true,
            'GetTotalRecCount': true,
            'TotalCount': 0,
            'LookupField': 'string',
            'OrderByColumn': 'string',
            'OrderByDirection': 0,
            'TransTypeId': 0,
            'LocationId': 0,
            'UserId': 0,
            'SiteId': 0,
            'Ids': 0,
            'IsActiveOnly': true,
            'SearchText': 'string',
            'FromLocationId': locationId
        };
        httpService.PosthttpMethod(DropDown.DropdownUrl, postObject).subscribe(res => {
            let ReturnData = [];
            if (TransactionCollection.PostEntityName.toLowerCase() === 'purchaseorderhead') {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate
                    };
                });
            } else {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate,
                        CurrencyName: result.CurrencyName
                    };
                });
            }
            const data = { 'Id': 0, 'Name': 'None' };
            ReturnData.unshift(data);
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.DropdownOptions
                = ReturnData;
        });
        return [];
    } catch (error) {
        console.error('Error occurred in dropdownOptions', error);
    }
}