import { Injectable } from "@angular/core";
import { DatamagineConfigBusiness } from "src/app/common/data-magine/data-magine-config/data-magine-config.business";
import { SpaLocalization } from "src/app/core/localization/spa-localization";
import { SpaPropertyInformation } from "src/app/core/services/spa-property-information.service";
import { ReportAPIOptions } from "src/app/report/business/report.modals";
import { SubPropertyDataService } from "src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service";
import { UserSessionConfiguration } from "src/app/retail/retail.modals";
import { BaseResponse } from "src/app/retail/shared/business/shared.modals";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { ReceiptConfiguration } from "src/app/retail/shop/shop.modals";
import { Host } from "src/app/shared/globalsContant";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import { PreSettlementReceiptService } from "./pre-settlement-receipt.service";
import { UI } from "src/app/common/data-magine/data-magine-config/data-magine-config.model";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { ReceiptBusinessService } from "src/app/retail/shop/receipt/business/receipt-business.service";
import { ReceiptService } from "src/app/retail/shop/receipt/business/receipt.service";
import { BehaviorSubject } from "rxjs";
import _ from 'lodash';


@Injectable({
    providedIn: 'root'
})

export class PreSettlementReceiptBusinessService {
    private receiptConfiguration: ReceiptConfiguration;
    private selectedOutletName: string;
    dmConfig: UI.DmConfig;


    constructor(
        private readonly reportService: PreSettlementReceiptService,
        public localization: RetailLocalization,
        private subPropService: SubPropertyDataService,
        private utils: RetailUtilities,
        private http: HttpServiceCall,
        private propertyInfo: SpaPropertyInformation,
        private dataMagineConfigBusiness: DatamagineConfigBusiness,
        private receiptBusinessService: ReceiptBusinessService,
        private readonly receiptService: ReceiptService
    ) {
    }

    public async GenerateReceipt(appointmentAddonIds, selectedOutletId: number, selectedTerminalId: number,
        isSigCapCompleted?: BehaviorSubject<[boolean, string]>) {
        await this.GetUserOutletName(selectedOutletId).then((o) => {
            this.selectedOutletName = o;
        });
        this.dmConfig = await this.dataMagineConfigBusiness.GetDMConfigSession();
        await this.getReceiptConfig(selectedOutletId).then(resp => this.receiptConfiguration = resp);
        let options: ReportAPIOptions = this.formReportAPIOptions(appointmentAddonIds, selectedOutletId, selectedTerminalId);
        let sigCapOptions = _.cloneDeep(options);
        if (this.dmConfig && this.dmConfig?.enableDatamagine && this.dmConfig?.enableSignatureCapture && this.dmConfig?.signCapEndPoint
            && this.dmConfig?.signCapEndPoint != "") {
            this.utils.ToggleLoader(true, this.localization.captions.initiate_SignatureCapture);
            let receiptString = await this.receiptService.getReceiptAsStringForCheckZoom(sigCapOptions, true);
            const appointmentId = appointmentAddonIds[0]?.AppointmentId != null ? appointmentAddonIds[0]?.AppointmentId : 0;
            await this.receiptBusinessService.inititateSigCapDevice(this.dmConfig, appointmentId, receiptString, sigCapOptions, 1, null, isSigCapCompleted, true, appointmentId);
            let observable = isSigCapCompleted.subscribe((res) => {
                if (res[0] === true) {
                    options.params.push({ 'pSignature': res[1] });
                    this.reportService.printPreSettlementReceipt("PDF", options);
                    isSigCapCompleted.next([false,'']);
                    observable.unsubscribe();
                }
            });

        }
        else {
            this.reportService.printPreSettlementReceipt("PDF", options);
        }

    }

    public async GetUserOutletName(selectedOutletId: number) {
        var allOutlets: any = await this.subPropService.GetUserOutlets();
        return allOutlets.find(x => x.id == selectedOutletId).outletName;
    }

    private formReportAPIOptions(appointmentAddonIds, selectedOutletId, selectedTerminalId): ReportAPIOptions {
        return {
            code: "PreSettlementReceipt",
            params: this.formReportParams(),
            URIParams: [],
            filters: this.formFilters(appointmentAddonIds, selectedOutletId, selectedTerminalId),
            format: 'PDF',
            pageBreak: true
        };
    }

    private formFilters(appointmentAddonIds, outletId, terminalId) {
        return {
            AppointmentAddonIds: appointmentAddonIds,
            OutletId: outletId,
            TerminalId: terminalId
        };
    }

    private formReportParams() {
        let clerkName = sessionStorage.getItem('quickIdUser') ? sessionStorage.getItem('quickIdUser') : this.utils.GetPropertyInfo('userName');
        return [
            { 'pOutletName': this.selectedOutletName },
            { 'pDate': this.localization.ConvertDateToISODateTime(this.propertyInfo.CurrentDTTM) },
            { 'pPropertyName': this.localization.GetPropertyInfo('PropertyName') },
            { 'pDisplayOnlyPackageItem': this.receiptConfiguration != null ? this.receiptConfiguration.displayOnlyPackageItem : 0 },
            { 'pSuppressClerkId': this.receiptConfiguration.suppressClerkId },
            { 'pDisplayPackageDescription': this.receiptConfiguration.displayPackageDescription },
            { 'pDisplayPackagePrice': this.receiptConfiguration.displayPackagePrice },
            { 'pDisplayPackageAppointmentID': this.receiptConfiguration.displayPackageAppointmentID },
            { 'pDisplayPackageStaffCode': this.receiptConfiguration.displayPackageStaffCode },
            { 'pServiceChargeGratuityDisplay': this.receiptConfiguration.serviceChargeGratuityDisplay },
            { 'pSuppressClerkId': this.receiptConfiguration.suppressClerkId },
            { 'pGratuityLine': this.receiptConfiguration.gratuityLine ? this.receiptConfiguration.gratuityLine : "" },
            { 'pReceiptNote': this.receiptConfiguration.receiptNote ? this.receiptConfiguration.receiptNote : "" },
            { 'pVATEnabled': this.propertyInfo.IsVATEnabled },
            { 'pClerk': clerkName },
            { 'pRetailInterface': this.propertyInfo.UseRetailInterface }
        ]
    }

    private async getReceiptConfig(outletId: number): Promise<ReceiptConfiguration> {
        let response: BaseResponse<ReceiptConfiguration> = await this.http.CallApiAsync<ReceiptConfiguration>({
            callDesc: "GetReceiptConfigurationByOutletId",
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { id: outletId }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    public async GetDefaultUserConfiguration(): Promise<UserSessionConfiguration> {
        return this.utils.getLoggedinUserSessionConfiguration();
    }
    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    }

}