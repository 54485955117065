import { I } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { GuaranteeMethod, GuaranteeMethodDetail, GuestRoomSearchResult, PayeeInfo } from 'src/app/retail/shared/business/shared.modals';
import { ArAccount, LookUpInput } from 'src/app/retail/shared/service/payment/payment-business.model';
import { AlertType } from 'src/app/shared/globalsContant';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';

import {
  GuaranteeMethodAPIModel,
  GuaranteeMethodBusiness,
  GuaranteeMethodConfig, GuaranteeMethodJSONModel,
  GuestSearchCategory, GuestSearchFilter
} from './guarantee-method.business';

@Component({
  selector: 'app-guarantee-method',
  templateUrl: './guarantee-method.component.html',
  styleUrls: ['./guarantee-method.component.scss'],
  providers: [GuaranteeMethodBusiness],
  encapsulation: ViewEncapsulation.None
})
export class GuaranteeMethodComponent implements OnInit {

  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedGuestRoom: GuestRoomSearchResult = null;
  selectedARAccount: ArAccount = null;
  SelectedRoomNo = "";
  accountLookupComponentInput: LookUpInput = null;
  payeeInfo: PayeeInfo = null;
  GuaranteeMethodConst = {
    Creditcard: GuaranteeMethod.Creditcard,
    RoomCharge: GuaranteeMethod.RoomCharge,
    MemberARAccount: GuaranteeMethod.MemberARAccount
  };
  guaranteeTypeForm: UntypedFormGroup;
  captions: any;
  GuestRoomSearchResult: GuestRoomSearchResult[] = [];
  savedAPIReferenceId: number = 0;
  roomSearchTypeList: { id: number; name: any; }[] = [];
  searchBoxPlaceHolder = "";
  placeHolderTxt;
  selectedGuaranteeMethod: GuaranteeMethod;
  selectedGuestRoomList = [];
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  separatorKeysCodes = [];
  isConfigPrefilled: boolean = false;
  isRoomChargeGuaranteeApplicable = true;
  isCardRemovable = true;
  showConfirmationForRemoval = false;
  floatLabelNever: string;
  savedReferenceId: number = 0;
  pmsSystem: boolean=false;
  pmsSystemValue: any;

  @ViewChild('guestRoomSearch') guestRoomSearch: ElementRef;
  @ViewChild('GuestSearchtrigger') trigger: MatAutocompleteTrigger;

  @Input("GuarenteeMethodConfig") guaranteeMethodConfigInput: GuaranteeMethodConfig;
  @Input("ReferenceId") PaymentReferenceID: number = 0;
  
  @Input("isFromWaitListLostDenied") isFromWaitListLostDenied:boolean=false;
  @Input("automationId")automationId : string = '';

  @Output("UpdateGuaranteeMethod") UpdateGuaranteeMethod = new EventEmitter<GuaranteeMethodJSONModel | string>();


  constructor(
    private _localization: Localization
    , private _utils: SpaUtilities
    , private _fb: UntypedFormBuilder
    , private _business: GuaranteeMethodBusiness
  ) {
    this.captions = _localization.captions.bookAppointment.GuaranteeMethod;
    this.floatLabelNever = _localization.setFloatLabelNever;
    this.InitiateFormGroup();
    this.initializeSearchSubscription();
    const jsonPropConfig = sessionStorage.getItem('propConfig');
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig")); 
    this.pmsSystemValue = propConfig?.PMSSystem;
    this.pmsSystem = this.pmsSystemValue  ? true : false;
  }

  ngOnInit(): void {
    this.roomSearchTypeList = [
      {
        id: GuestSearchCategory.ConfirmationNumber,
        name: this.captions.ConfirmationNumber
      },
      {
        id: GuestSearchCategory.GuestName,
        name: this.captions.GuestName
      }
    ];
    this.placeHolderTxt = {
      [GuestSearchCategory.ConfirmationNumber]: this.captions.SearchByConfNum,
      [GuestSearchCategory.GuestName]: this.captions.SearchByGuestName
    };
  }

  initializeSearchSubscription() {
    this.guaranteeTypeForm.controls.guestRoomSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)).subscribe(searchKey => {
        if (searchKey && searchKey.length >= 3)
          this.searchGuestRoom();
      });
  }

  InitiateFormGroup() {
    this.guaranteeTypeForm = this._fb.group({
      guaranteeMethod: GuaranteeMethod.Creditcard,
      searchType: GuestSearchCategory.ConfirmationNumber,
      guestRoomSearch: "",
      guestRoomSelection: "",
      updateCardToClientProfile: true
    });
    this.selectedGuaranteeMethod = GuaranteeMethod.Creditcard;
  }

  ngOnChanges() {
    if (this.guaranteeMethodConfigInput && this.guaranteeMethodConfigInput.billingDetail) {
      this.savedAPIReferenceId = this.guaranteeMethodConfigInput.billingDetail.id;

      const savedDetails: GuaranteeMethodAPIModel = this._business.ParseToAPIModel(this.guaranteeMethodConfigInput.billingDetail?.billingDetail);
      if (savedDetails && savedDetails.guaranteePaymentMethod) {
        this.guaranteeTypeForm.controls.guaranteeMethod.setValue(savedDetails.guaranteePaymentMethod.GuaranteeMethod);
        this.selectedARAccount = savedDetails.guaranteePaymentMethod.ARAccountDetail;
        this.selectedGuaranteeMethod = savedDetails.guaranteePaymentMethod.GuaranteeMethod;
        this.selectedGuestRoom = savedDetails.guaranteePaymentMethod.RoomDetail;
        this.PaymentReferenceID = savedDetails.guaranteePaymentMethod.CardDetail ? savedDetails.guaranteePaymentMethod.CardDetail.PaymentReferenceId : 0;
        this.ValidateBookingDates();
      }

      if (this.selectedARAccount) {
        this.accountLookupComponentInput = {
          arAccountName: this.selectedARAccount.accountName,
          arAccountNumber: this.selectedARAccount.accountNumber,
          isCustomUIRequired: false,
          isCustomCaptureSection: true
        }
      }
      if (this.selectedGuestRoom) {
        this.guaranteeTypeForm.controls.searchType.setValue(GuestSearchCategory.ConfirmationNumber);
        this.selectedGuestRoomList = [];
        this.selectedGuestRoomList.push(`${this.selectedGuestRoom.firstName} ${this.selectedGuestRoom.lastName}`);
      }
      this.guaranteeTypeForm.controls.guaranteeMethod.setValidators(
        this.guaranteeMethodConfigInput?.isMandatoryOnAppointment ? Validators.required : null
      );
      if (savedDetails) {
        this.emitGuaranteePaymentDetails(savedDetails.guaranteePaymentMethod, false);
      }
    }
  }

  ValidateBookingDates() {
    if (this.guaranteeMethodConfigInput && this.guaranteeMethodConfigInput?.bookingStartDate && this.guaranteeMethodConfigInput?.bookingEndDate) {
      if (this.guaranteeMethodConfigInput?.bookingStartDate != this.guaranteeMethodConfigInput?.bookingEndDate && !this.isFromWaitListLostDenied) {
        this.isRoomChargeGuaranteeApplicable = false;
        this.selectedGuestRoom = null;
        this.selectedGuestRoomList = [];
        this.emitGuaranteePaymentDetails(null);
      }
    }
  }

  async searchGuestRoom() {
    const searchtxt = this.guaranteeTypeForm.controls.guestRoomSearch.value;
    const searchType = this.guaranteeTypeForm.controls.searchType.value;
    const appointmentStartDate = this.guaranteeMethodConfigInput ? this.guaranteeMethodConfigInput.bookingStartDate : null;
    const appointmentEndDate = this.guaranteeMethodConfigInput ? this.guaranteeMethodConfigInput.bookingEndDate : null;

    if (!appointmentStartDate || !appointmentEndDate) {
      this._utils.showAlert(this.captions.MissingServiceDate, AlertType.Info);
      this.ResetSearchList();
      return;
    }
    if (searchtxt && searchType && appointmentStartDate) {
      const searchParams: GuestSearchFilter = {
        guestSearchCategory: searchType,
        activityStartDate: this._utils.convertDateObjToAPIdate(appointmentStartDate),
        activityEndDate: appointmentEndDate ? this._utils.convertDateObjToAPIdate(appointmentEndDate) : this._utils.convertDateObjToAPIdate(appointmentStartDate),
        searchKey: searchtxt,
        product: this.pmsSystemValue ? this.pmsSystemValue : ""
      }
      let searchResult = await this._business.SearchGuestRoom(searchParams);
      console.log({ searchResult });
      if (searchResult.successStatus && searchResult.result) {
        if (searchResult.result.activities && searchResult.result.activities.length > 0) {
          this.GuestRoomSearchResult = searchResult.result.activities;
          if (this.trigger)
            this.trigger.openPanel();
        }
        else if (searchResult.result.errorCode == -1) {
          this._utils.showAlert(this.captions.RoomChargeNotApplicableMsg, AlertType.Info);
        }
        else {
          this._utils.showAlert(this._localization.captions.common.NoRecordFound, AlertType.Info);
        }
      }
    }
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.selectedGuestRoomList.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.guaranteeTypeForm.controls.guestRoomSearch.setValue(null);
  }

  remove(obj: any): void {
    const index = this.selectedGuestRoomList.indexOf(obj);
    if (index >= 0) {
      this.selectedGuestRoomList.splice(index, 1);
      this.guaranteeTypeForm.markAsDirty();
      this.emitGuaranteePaymentDetails(null);
      this.ResetSearchList();
    }
  }

  checkboxClick($event) {
    const emitData: GuaranteeMethodDetail = {
      GuaranteeMethod: this.selectedGuaranteeMethod,
      RoomDetail: this.selectedGuestRoom,
      ARAccountDetail: this.selectedARAccount,
      CardDetail: this.getCardInfo()
    };
    this.emitGuaranteePaymentDetails(emitData);
  }

  selectSearchType(type) {
    this.searchBoxPlaceHolder = this.placeHolderTxt[type];
    this.ResetSearchList();
  }

  ResetSearchList() {
    this.UpdateNullAndResetSelectionAndFlags();
    this.guaranteeTypeForm.controls.guestRoomSearch.patchValue("");
  }

  UpdateNullAndResetSelectionAndFlags() {
    this.GuestRoomSearchResult = [];
    this.selectedGuestRoom = null;
    this.selectedGuestRoomList = [];
    this.guaranteeTypeForm.controls.guestRoomSelection.patchValue("");
    if (this.guestRoomSearch && this.guestRoomSearch.nativeElement) {
      this.guestRoomSearch.nativeElement.value = "";
    }
  }

  GuaranteeTypeChange(type) {
    if (this.PaymentReferenceID > 0) {
      this.savedReferenceId = this.PaymentReferenceID;
    }
    this.accountLookupComponentInput = {
      arAccountName: "",
      arAccountNumber: "",
      isCustomUIRequired: false
    }
    this.selectedARAccount = null;
    this.UpdateNullAndResetSelectionAndFlags();
    this.PaymentReferenceID = 0;
    this.selectGuaranteeType(type);
  }

  selectGuaranteeType(guaranteeMethod) {
    this.selectedGuaranteeMethod = guaranteeMethod;
    this.guaranteeTypeForm.controls.guaranteeMethod.setValue(guaranteeMethod);
    if (guaranteeMethod == GuaranteeMethod.RoomCharge) {
      this.selectSearchType({ id: GuestSearchCategory.ConfirmationNumber });
    }
    else if (guaranteeMethod == GuaranteeMethod.Creditcard) {
      if (this.PaymentReferenceID == 0 && this.savedReferenceId > 0) {
        this.PaymentReferenceID = this.savedReferenceId;
      }
    }
  }

  SelectGuestRoom(room) {
    if (room) {
      this.selectGuaranteeType(GuaranteeMethod.RoomCharge);
      this.selectedGuestRoom = room;
      this.selectedGuestRoomList.push(`${this.selectedGuestRoom.firstName} ${this.selectedGuestRoom.lastName}`);
      const emitData: GuaranteeMethodDetail = {
        GuaranteeMethod: this.selectedGuaranteeMethod,
        RoomDetail: room,
        ARAccountDetail: this.selectedARAccount,
        CardDetail: this.getCardInfo()
      };
      console.log({ emitData })
      this.emitGuaranteePaymentDetails(emitData);
    } else {
      this.selectedGuestRoom = null;
    }
  }

  SelectAccount(selectedARAccount: ArAccount) {
    if (selectedARAccount) {
      this.selectGuaranteeType(GuaranteeMethod.MemberARAccount);
      this.selectedARAccount = selectedARAccount;
      const emitData: GuaranteeMethodDetail = {
        GuaranteeMethod: this.selectedGuaranteeMethod,
        RoomDetail: this.selectedGuestRoom,
        ARAccountDetail: selectedARAccount,
        CardDetail: this.getCardInfo()
      };
      this.guaranteeTypeForm.markAsDirty();
      this.emitGuaranteePaymentDetails(emitData);
    } else if (this.selectedARAccount) {
      this.selectedARAccount = null;
      this.guaranteeTypeForm.markAsDirty();
      this.emitGuaranteePaymentDetails(null);
    }
  }

  SaveReferenceId(event: number) {
    if (event) {
      this.selectGuaranteeType(GuaranteeMethod.Creditcard);
      this.PaymentReferenceID = event;
      const emitData: GuaranteeMethodDetail = {
        GuaranteeMethod: this.selectedGuaranteeMethod,
        RoomDetail: this.selectedGuestRoom,
        ARAccountDetail: this.selectedARAccount,
        CardDetail: this.getCardInfo()
      };
      this.guaranteeTypeForm.markAsDirty();
      this.emitGuaranteePaymentDetails(emitData);
    }
  }

  getCardInfo() {
    return { PaymentReferenceId: this.PaymentReferenceID };
  }

  ResetCapturedData(guaranteeMethod: GuaranteeMethod) {
    switch (guaranteeMethod) {
      case GuaranteeMethod.Creditcard:
        this.PaymentReferenceID = 0;
        break;
      case GuaranteeMethod.RoomCharge:
        this.selectedGuestRoom = null;
        break;
      case GuaranteeMethod.MemberARAccount:
        this.selectedARAccount = null;
        break;
      default:
        break;
    }
    const emitData: GuaranteeMethodDetail = {
      GuaranteeMethod: this.selectedGuaranteeMethod,
      RoomDetail: this.selectedGuestRoom,
      ARAccountDetail: this.selectedARAccount,
      CardDetail: this.getCardInfo()
    };
    this.emitGuaranteePaymentDetails(emitData);
  }

  emitGuaranteePaymentDetails(guaranteeMethodDetail: GuaranteeMethodDetail, onFieldChng = true) {
    let emitData: GuaranteeMethodJSONModel = {
      guaranteePaymentMethod: guaranteeMethodDetail,
      id: this.savedAPIReferenceId,
      updateCardDetailsToClientProfile: this.guaranteeTypeForm.controls.updateCardToClientProfile.value,
      isFormValid: this.guaranteeTypeForm.valid,
      isFormDirty: onFieldChng ? this.guaranteeTypeForm.dirty : false
    }
    this.UpdateGuaranteeMethod.emit(emitData);
  }

  removeCardDetails(eve) {
    if (eve) {
      this.PaymentReferenceID = 0;
      this.guaranteeTypeForm.markAsDirty();
      this.emitGuaranteePaymentDetails(null);
    }
  }

}
