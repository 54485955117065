import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
@Component({
  selector: 'app-appointment-by-staff-member',
  templateUrl: './appointment-by-staff-member.component.html',
  styleUrls: ['./appointment-by-staff-member.component.scss']
})
export class AppointmentByStaffMemberComponent implements OnInit {

  appointmentPrevNextFrmgrp: UntypedFormGroup;
  captions: any;
  @Output() formReady = new EventEmitter();
  @Output() toggleChangeEvent: EventEmitter<any> = new EventEmitter();
  radioFilter = [];
  constructor(private fb: UntypedFormBuilder
    , private PropertyInfo: SpaPropertyInformation
    , public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
  }

  ngOnInit() {
    let config = this.PropertyInfo.AppointmentConfigurations();
    this.appointmentPrevNextFrmgrp = this.fb.group({
      ExcludeAppointmentComments: config["EXCLUDE_APPOINTMENT_COMMENTS"],
      IncludePrevAndNextAppointment:true
    });

    this.formReady.emit(this.appointmentPrevNextFrmgrp);
  }

  onTogglechange(event){
    this.toggleChangeEvent.emit(event);
  }

}
