import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SPAConfig } from '../../core/config/SPA-config';
import { ReportSelector, ReportControlConfig, DropDownData, CustomFields, ClientDetailForReport, ReportUIConfig, ReportAPIOptions,JasperReportDetails } from '../business/report.modals';
import { ReportControlBuilder } from '../business/reportControlBuilder';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { Host, ReportBreakPoint,clientSearchType } from '../../shared/globalsContant';
import { BaseResponse, UserBreakPoint } from '../../shared/business/shared.modals';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { BaseReport } from '../business/common/base-report';
import { ReportFactory } from '../business/common/report-factory';
import { ReportDataService } from '../data/report-data.services';
import { ReportBusinessService } from '../business/report-business.service';
import { AllReports } from 'src/app/report/business/report.modals';
import { AgTimeConfig, DropdownOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { routingMenuService } from 'src/app/common/components/menu/menu.service';
@Component({
  selector: 'app-reportcontrols',
  templateUrl: './base-report.component.html',
  styleUrls: ['./base-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ReportDataService, ReportBusinessService]

})
export class ReportControl implements OnInit {
  reportGroup: string;
  @Input() hideSaveBtn?: boolean = true;
  @Input() formData: any;
  editableValue: any;
  private reportBuilder = new ReportControlBuilder(this.localization);
  reportSelector: ReportSelector[] = [];
  reportSelectedUi:ReportSelector;
  dropDownFilterData: DropDownData[] = [];
  showDate = true;
  startDateCaption: string = "";
  endDateCaption: string = "";
  activeReportCode: string;
  activeReportConfigs: ReportControlConfig;
  activeReportLayout: string;
  reportsinput: UntypedFormGroup;
  drowDownFilterName = '';
  captions: any;

  commonCaptions: any;
  reportName: any[];
  reportNameChild: any[] = [];
  locations: any[];
  staffMember: any[];
  colorCode: any[];
  reportNames: any[];
  reportNameData: any[];
  inActive = false;
  inActiveText: string;
  pageBreak = false;
  status = false;
  selectedReportConfig: ReportControlConfig;
  showFilterDropDown: boolean;
  customFieldsValues: CustomFields[];
  Clients: any[] = [];
  SearchedClients: any[] = [];
  selectedClient = '';
  minEndDate: Date = this.utils.getCurrentDate();
  maxDate: Date;
  endDateMax: Date;
  minStartDate: Date;
  preventHistoricDate: boolean;
  infiniteDate: Date = new Date(8640000000000000);
  disableGenerateBtn: boolean;
  showMissingClientError: boolean;
  reportParams: ReportAPIOptions;
  validSelection: boolean = true;
  validSelectionErrMsg: string = '';
  textInputFilterValid: boolean = true;

  //custom report filter region variables
  customReport: string;
  requestUid = '';
  timer = null;
  report: BaseReport;
  @ViewChild('reportControl') asideArrowElement: ElementRef;
  valuesSelected: any[] = [];
  reportSubscription: ISubscription;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType: any;
  showTime = false;
  activeToggle: boolean;
  placeholderFormat: string;
  defaultMinDate = new Date('01/01/1900');
  includePreviousAndNextAppt: boolean = false;
  floatLabel: string;
  isFromJobScheduler: boolean;
  timelineOptions: DropdownOptions[];
  wildCardDefaultStart:number | string;
  wildCardDefaultEnd:number | string;
  initValueStart = 0;
  initValueEnd = 0;
  AllowButton: boolean = true;
  propertyConfig : any;
	isJasperReportEnabled: boolean = false;
  AllowJasperReport : boolean = false;
  jasperReportDetails :JasperReportDetails;
  startTimeInputs: AgTimeConfig;
  endTimeInputs: AgTimeConfig;
  @Output() clickSave: EventEmitter<any> = new EventEmitter<any>();
	@Output() clickEditvalues: EventEmitter<any> = new EventEmitter();
  radioFilter: { id: number; name: any; }[];
  isClass: boolean = false;
  @Input('reportGroup')
  set reportGrp(value: string) {
    if (value && this.localize.isFromJobScheduler) {
      this.reportGroup = value.toUpperCase();
      // this.setOnPageLoadReport();
    } else {
      this.reportGroup = value;
    }
  };
  @Input('editValues')
	set patchVal(value){
		if(value){
			this.editableValue = value.filters;
			this.reportName = value.reportName;
			if(value.jobSchedulerId > 0)	{
				this.AllowButton = false;
			}
		}else{
			this.AllowButton = true;
		}
	}

  constructor(private spaConfig: SPAConfig, private fb: UntypedFormBuilder, private http: HttpServiceCall, public localization: SpaLocalization, private PropertyInfo: SpaPropertyInformation, private breakpoint: BreakPointAccess, private utils: SpaUtilities, private data: ReportDataService
    , public business: ReportBusinessService, private _cdr: ChangeDetectorRef, private localize: Localization, private _routingMenuService : routingMenuService) {
      this.floatLabel = this.localization.setFloatLabel;
      this.propertyConfig = this.PropertyInfo.GetPropertyConfiguration();
      this.captions = this.spaConfig.captions.reports;
      this.commonCaptions = this.spaConfig.captions.common;
      this.radioFilter =[
        { id: 0, name: this.captions.AppointmentReport },
        { id: 1, name: this.captions.Class}
       ];
      if(this.propertyConfig!=null && this.propertyConfig['EnableJasperReports']!=null)
		{
			this.isJasperReportEnabled = String(JSON.parse(sessionStorage.propConfig).EnableJasperReports).trim().toLowerCase()=== "true" ? true : false;
		}
  }

  // ngAfterViewChecked() {
  ////Update change detection
  // this._cdr.detectChanges();
  // }

  ngOnInit() {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.isFromJobScheduler = this.localize.isFromJobScheduler;
    this.reportsinput = this.fb.group({
      reportName: '',
      startTime: '12:00 am',
      endTime: '11:59 pm',
      startDate: [this.PropertyInfo.CurrentDate],
      endDate: [this.PropertyInfo.CurrentDate],
      radioFilter: 0,
      includeInactiveusers: false,
      pageBreakToggle: false,
      reportNameChild: '',
      clientTobeSelected: [''],
      memberSearch:'',
      customFilterFormGrp: new UntypedFormGroup({})
    });
    this.startTimeInputs = {
      className: '',
      form: this.reportsinput,
      formControlName: 'startTime',
      placeHolderId: 'lbl_startTime',
      placeHolder: this.commonCaptions.StartTime,
      isTimeRequired: false
    };
    this.endTimeInputs = {
      className: '',
      form: this.reportsinput,
      formControlName: 'endTime',
      placeHolderId: 'lbl_endTime',
      placeHolder: this.commonCaptions.EndTime,
      isTimeRequired: false,
      minTime: this.reportsinput.controls.startTime.value
    };
    if(this.isFromJobScheduler){
      this.timelineOptions = this.localize.scheduleJobWildCards;
      // this.wildCardDefaultStart = this.localize.scheduleJobWildCards[1].id;
      // this.wildCardDefaultEnd = this.localize.scheduleJobWildCards[1].id;
      this.initValueStart = 0;
      this.initValueEnd = 0;
      this.reportsinput.addControl('timelineFrom', new UntypedFormControl());
      this.reportsinput.addControl('timelineCountFrom', new UntypedFormControl());
      this.reportsinput.addControl('timelineTo', new UntypedFormControl());
      this.reportsinput.addControl('timelineCountTo', new UntypedFormControl());
    }
    this.reportSelector = this.reportBuilder.reportSelector;
    var reportselector = this.reportSelector.filter(rs =>rs.group == this.reportGroup);
    this.reportSelector = reportselector;
    this.spaConfig.load();
    // this.minEndDate = this.PropertyInfo.CurrentDate;
    // this.minStartDate = this.PropertyInfo.CurrentDate;
    this.getAllCustomFieldsData();
    if (this.isFromJobScheduler && this.editableValue) {
      this.reportsinput.controls.reportName.setValue(this.reportName);
      this.updateReportControls({ 'value': this.reportName });
      // this.reportsinput.controls['reportName'].setValue(code);
      // initReport = this.getActiveReport(code);
      // this.accessCheckAndAlert(initReport);
    } else {
      this.setOnPageLoadReport();
    }
  }

  timeLineCountFrom(){
    let timeLineCountFrom = this.reportsinput.controls.timelineCountFrom.value;
    if(!timeLineCountFrom){
      this.reportsinput.controls.timelineCountFrom.setValue(0);
    }
  }

  timeLineCountTo(){
    let timelineCountTo = this.reportsinput.controls.timelineCountTo.value;
    if(!timelineCountTo){
      this.reportsinput.controls.timelineCountTo.setValue(0);
    }
  }

  ngOnDestroy() {
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }



  //event from fast report component for disabling generate button when API call is in progress
  disableGenerateButtonEvent(IsEnable: boolean): void {
    this.disableGenerateBtn = IsEnable;
  }

  applyBreakPoints(): void {
    let _userBreakPoints: UserBreakPoint[] = [];
    _userBreakPoints = this.breakpoint.GetBreakPoint([ReportBreakPoint.ClientItinerary,
    ReportBreakPoint.BatchPrintClientItinerary,
    ReportBreakPoint.AppointmentListingbyLocation,
    ReportBreakPoint.AppointmentListingbyStaffMember,
    ReportBreakPoint.AppointmentListingbyCustomFields,
    ReportBreakPoint.AppointmentListing,
    ReportBreakPoint.CancellationReport,
    ReportBreakPoint.NoShowReport,
    ReportBreakPoint.StaffMemberSummaryReport,
    ReportBreakPoint.CommissionReport,
    ReportBreakPoint.GratuityReport,
    ReportBreakPoint.ServiceChargeReport,
    ReportBreakPoint.TransactionLog,
    ReportBreakPoint.ReturnedItems,
    ReportBreakPoint.InventoryAudit,
    ReportBreakPoint.InventoryTransfer,
    ReportBreakPoint.InventoryDetail,
    ReportBreakPoint.InventorySummary,
    ReportBreakPoint.MultiPackItems,
    ReportBreakPoint.CorrectVoid,
    ReportBreakPoint.SaleByItem,
    ReportBreakPoint.SaleByDiscountType,
    ReportBreakPoint.SaleByCategory,
    ReportBreakPoint.SaleBySubCategory,
    ReportBreakPoint.RevenueSummary,
    ReportBreakPoint.Shift,
    ReportBreakPoint.Transaction,
    ReportBreakPoint.InventoryWash,
    ReportBreakPoint.AppointmentListingbyColorCode,
    ReportBreakPoint.StaffSchedule,
    ReportBreakPoint.Utilization,
    ReportBreakPoint.ProjectedRevenueSummary,
    ReportBreakPoint.ClientListing,
    ReportBreakPoint.AppointmentBookedByUser,
    ReportBreakPoint.OnlineAppointment,
    ReportBreakPoint.MemberServiceAvailed,
    ReportBreakPoint.AppointmentDeposit,
    ReportBreakPoint.LostDeniedBusinessReport,
    ReportBreakPoint.LockerUtilization,
    ReportBreakPoint.ClassClientListing,
    ReportBreakPoint.ClassClientCancellation,
    ReportBreakPoint.ClassClientNoShow,
    ReportBreakPoint.DayPass,
    ReportBreakPoint.UserAccessReport,
    ReportBreakPoint.PackageSoldReport
    ]).result;

    for (let breakpts of _userBreakPoints) {
      const _breakPointResp = breakpts;
      const _IsReportAuthorized = _breakPointResp.allow;
      for (let j = 0; j < this.reportSelector.length; j++) {
        const reportSelection: ReportSelector = this.reportSelector[j];
        if (reportSelection.breakPointNumber == _breakPointResp.breakPointNumber) {
          this.reportSelector[j].IsAuthorized = _IsReportAuthorized;
        }
      }
    }

  }

  accessCheckAndAlert(reportSelected: ReportSelector): void {
    if (reportSelected != undefined) {
      if (!reportSelected.IsAuthorized) {
        this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[reportSelected.breakPointNumber]);
      }
      this.reportSelectedUi=reportSelected;
      if(reportSelected?.enableJasperReports && this.isJasperReportEnabled && !this.localize.isFromJobScheduler)
			this.AllowJasperReport = true;
		  else
			this.AllowJasperReport = false;

      if (reportSelected.code == "AppointmentByStaffMember") {
        setTimeout(() => {
          this.endDateMax = this.reportsinput.controls['startDate'].value;
          this.reportsinput.controls['endDate'].setValue(this.endDateMax);
        }, 2000);
      }
    }
  }

  OnFromDateValueChange(e: any): void {
    let startDate = this.reportsinput.controls.startDate.value;
    let endDate = this.reportsinput.controls.endDate.value;
    if (startDate.getTime() >= endDate.getTime()) {
      this.reportsinput.controls.endDate.setValue(e.value);
    }
    this.minEndDate = this.reportsinput.controls['startDate'].value;
    if (this.includePreviousAndNextAppt) {
      this.endDateMax = this.reportsinput.controls['startDate'].value;
      this.reportsinput.controls['endDate'].setValue(this.endDateMax);
    }
  }


  checkClientSelected() {
    let disableGenerateForClientItinerary: boolean;
    disableGenerateForClientItinerary = this.reportsinput.controls['clientTobeSelected'].value == '' ? true : false;
    if (!disableGenerateForClientItinerary) {
      disableGenerateForClientItinerary = !this.selectedClient || this.selectedClient === "" ? true : false;
    }

    this.disableGenerateButtonEvent(disableGenerateForClientItinerary);
    this.showMissingClientError = disableGenerateForClientItinerary;
  }

  setOnPageLoadReport(): void {
    let code: string;
    let initReport: ReportSelector;   
    var firstRetailReport;
    let selectedMenuTxt = this._routingMenuService.selectedRoutingMenu?.text;
    if (this.reportGroup == 'APPOINTMENT') {    
      firstRetailReport = this.getReportCode();
      code = firstRetailReport != null ? firstRetailReport.code : 'AppointmentListing'; 
    } else if (this.reportGroup == 'APPOINTMENT') {
      code = 'LockerUtilization';
      this.checkClientSelected();
      this.reportSubscription = this.reportsinput.controls['clientTobeSelected'].valueChanges.subscribe(result => {
        if (result.length > 2) {
          this.requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
          if (this.timer) {
            clearTimeout(this.timer); //cancel the previous timer.
            this.timer = null;
          }
          this.timer = setTimeout(this.searchClient.bind(this), 1000, result);
        }
      });
    }  else if (this.reportGroup == 'CLIENT') {
      firstRetailReport =this.getReportCode();
      code = firstRetailReport != null ? firstRetailReport.code : 'Itinerary';      
      this.checkClientSelected();
      this.reportSubscription = this.reportsinput.controls['clientTobeSelected'].valueChanges.subscribe(result => {
        if (result.length > 2) {
          this.requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
          if (this.timer) {
            clearTimeout(this.timer); //cancel the previous timer.
            this.timer = null;
          }
          this.timer = setTimeout(this.searchClient.bind(this), 1000, result);
        }
      });
    } else if (this.reportGroup == 'POS') {
      firstRetailReport = this.getReportCode();
      code = firstRetailReport != null ? firstRetailReport.code : 'CommissionReport';
    } else if (this.reportGroup == 'RETAIL') {
      code = 'MultiPackItems'
    }
    else if (this.reportGroup == 'SECURITY') {
      firstRetailReport = this.getReportCode();
      code = firstRetailReport != null ? firstRetailReport.code : 'UserAccessReport';      
    }else if(this.reportGroup == 'MEMBER'){
      firstRetailReport = this.getReportCode();
      code = firstRetailReport != null ? firstRetailReport.code : 'MemberServiceAvailed';    
    }
    this.updateReportControls({ 'value': code });
    this.reportsinput.controls['reportName'].setValue(code);
    initReport = this.getActiveReport(code);
    this.accessCheckAndAlert(initReport);
  }
  getActiveReport(reportCode: string): ReportSelector {
    return this.reportSelector.filter(x => x.code === this.activeReportCode)[0];
  }

  asideArrow(e: any): void {
    this.status = !this.status;
  }

  getReportCode():any{   
    let routePathValue = this._routingMenuService.selectedRoutingMenu;
    let firstReport;
    if (!this.localize.isFromJobScheduler) {
      if (routePathValue != undefined && routePathValue?.text != '' && routePathValue?.text != 'RETAIL') {
        firstReport = this.reportSelector.find(x => x.value == routePathValue.text);
      } else {
        firstReport = this.reportSelector.find(x => x.group === this.reportGroup);
      }
    }
    else
    {
      firstReport = this.reportSelector[0];
    }
    return firstReport;     
  }

  searchClient(clientName?: string) {
    this.checkClientSelected();
    let clientSearchName: string = this.reportsinput.controls['clientTobeSelected'].value;
    if (clientSearchName) {
      this.http.CallApiWithCallback<any>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: 'clientSearch',
        method: HttpMethod.Get,
        uriParams: { name: encodeURIComponent(clientSearchName),searchType:clientSearchType.All, requestUid: this.requestUid },
        showError: false,
        extraParams: []
      });
    }
    else {
      this.Clients = [];
      this.selectedClient = "";
    }
  }
  generateReport(e: any) {
    if (this.reportsinput.valid) {
      let activeReport: ReportSelector = this.getActiveReport(this.activeReportCode);
      if (activeReport && !activeReport.IsAuthorized) {
        this.accessCheckAndAlert(activeReport);
      }
      else {
        //validates drop down selected value
        if (this.showFilterDropDown && this.dropDownFilterData.length == 0) {
          let emptyDataMsg: string = this.localization.getError(-118);
          this.utils.ShowErrorMessage(this.localization.captions.common.Information, emptyDataMsg);
        } else {
          if (!this.disableGenerateBtn) {
            this.createReportAPIOptions();
          }

        }
      }
    }
  }

  loadReports()
	{
    let activeReport=this.reportSelectedUi;
    let urlParameters: string = (activeReport.code == AllReports.AppointmentListing || activeReport.code == AllReports.CustomField4 || activeReport.code == AllReports.CustomField5 ||activeReport.code == AllReports.NoShowAppointment || activeReport.code == AllReports.OnlineAppointment) ? "&startDate=" + this.localization.convertDateObjToAPIdate(this.utils.PropertyInfo.CurrentDate) + "&endDate=" + this.localization.convertDateObjToAPIdate(this.utils.PropertyInfo.CurrentDate) : "";

		var jasper : JasperReportDetails = {
			reportCode :  this.reportSelectedUi?.code,
			isJaspersoftReport : this.AllowJasperReport ,
			reportUrlPath :  this.reportSelectedUi?.reportUrlpath,
      reportUrlParameters: urlParameters
		};
		this.jasperReportDetails = jasper;
	}

  generateReportUI(code: string) {
    const activeReport: string = code;
    try {
      this.report = new ReportFactory(this.reportsinput, this.data, this.business, this.utils).Get(activeReport, !this.editableValue ? {'fromWildCard': 1, 'toWildCard': 1} : this.editableValue);
      if (this.report) {
        let config: ReportUIConfig = this.report.getReportUIConfig();
        this.setUIControls(config);
      }

    } catch (error) {
      console.error(`Please Initialize ${code} Report`);
      throw error;
    }
  }

  private async setUIControls(config: ReportUIConfig): Promise<void> {
    this.activeReportLayout = config.layout;
    this.inActive = config.inActiveToggle;
    this.pageBreak = config.pageBreakToggle;
    this.showDate = config.startDatePicker;
    this.startDateCaption = config.startDateCaption;
    this.endDateCaption = config.endDateCaption;
    this.showTime = config.timePicker;
    this.inActiveText = config.inActiveToggleName ? config.inActiveToggleName : '';
    this.showFilterDropDown = config.dropDownFilters ? true : false;
    this.drowDownFilterName = config.dropDownFilterName ? config.dropDownFilterName : '';
    this.dropDownFilterData = await config.dropDownFilters;
    this.report.filterDataSource = this.dropDownFilterData;
    this.maxDate = (typeof config.allowFutureDate !== 'undefined' && !config.allowFutureDate) ? this.PropertyInfo.CurrentDate : this.infiniteDate;
    this.endDateMax = (typeof config.allowFutureDate !== 'undefined' && !config.allowFutureDate) ? this.PropertyInfo.CurrentDate : this.infiniteDate;
    // this.minStartDate = this.PropertyInfo.CurrentDate;
    this.preventHistoricDate = (typeof config.preventHistoricDate !== 'undefined' && config.preventHistoricDate) ? true : false;
    this.minStartDate = this.preventHistoricDate ? this.PropertyInfo.CurrentDate : this.defaultMinDate;
    this.disableGenerateBtn = typeof config.disableGenerateButton !== 'undefined' ? config.disableGenerateButton : this.disableGenerateBtn;
  }


  createReportAPIOptions(): void {
    try {
      const reportOptns: ReportAPIOptions = this.report.generateReportAPIOptions();
      this.reportParams = { ...reportOptns };
    } catch {
      console.error(`Please Initialize ${this.activeReportCode} Report`);
    }
  }


  updateReportControls(event: any) {
    this.refreshScreen();
    this.customReport = '';
    this._cdr.detectChanges();
    const _code: string = event.value;
    this.activeReportCode = _code;
    this.customReport = event.value;
    this.generateReportUI(_code);
    let val = this.reportBuilder.reportSelector.filter(rs => rs.id == 17)[0];
    if (this.activeReportCode == val.code) {
      this.reportsinput.get('endDate').setValue(this.localization.AddDays(this.PropertyInfo.CurrentDate, 6));
    }
    let utilization = this.reportBuilder.reportSelector.filter(rs => rs.id == 18)[0];
    let config = this.PropertyInfo.AppointmentConfigurations();
    if (this.activeReportCode == utilization.code) {
      if (config && Object.keys(config).length > 0) {
        let highestAppointmentOperatingHours = this.utils.getDayTimeForTheDate(JSON.parse(config["APPOINTMENT_OPERATING_HOURS"]));
        this.reportsinput.get('startTime').setValue(this.localization.getTime(this.localization.TimeToDate(highestAppointmentOperatingHours['StartTime']), this.localization.getTimeFormat()));
        this.reportsinput.get('endTime').setValue(this.localization.getTime(this.localization.TimeToDate(highestAppointmentOperatingHours['EndTime']), this.localization.getTimeFormat()));
      }
    }
    if (this.inActiveText == this.captions.ExcludeAppointmentComments) {
      this.checkClientSelected();
      if (config && Object.keys(config).length > 0) {
        this.reportsinput.get('includeInactiveusers').setValue(config["EXCLUDE_APPOINTMENT_COMMENTS"]=="true" || config["EXCLUDE_APPOINTMENT_COMMENTS"]==true ?true:false);
      }
    }
   
  }


  private refreshScreen(): void {
    this.dropDownFilterData = [];
    this.reportsinput.controls['includeInactiveusers'].setValue(false);
    this.reportsinput.controls['pageBreakToggle'].setValue(false);
    this.reportsinput.controls['reportNameChild'].setValue('');
    this.reportsinput.setControl('customFilterFormGrp', this.fb.group({}));
    this.validSelection = true;
    this.minEndDate = this.PropertyInfo.CurrentDate;
    this.reportsinput.controls['startDate'].setValue(this.PropertyInfo.CurrentDate);
    this.reportsinput.controls['endDate'].setValue(this.PropertyInfo.CurrentDate);
    this.textInputFilterValid = true;
    this.minStartDate = this.PropertyInfo.CurrentDate;
    if (this.reportParams && this.reportParams.code === AllReports.Itinerary) {
      this.reportsinput.controls['clientTobeSelected'].setValue('');
      this.Clients = [];
      this.checkClientSelected();
    }
     //this.reportParams = undefined;
  }

  displayFn(client?: any): string {
    if (client) { return client.name; }
  }
  clientSelected(name: string) {
    this.showMissingClientError = false;
    this.selectedClient = name;
    this.checkClientSelected();
  }

  private getAllCustomFieldsData() {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: 'GetCustomFieldsWithValues',
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }


  toggleIncludeInactive(IncludeInactiveToo: boolean) {
    this.activeToggle = IncludeInactiveToo;
    this.dropDownFilterData = this.dropDownFilterData.map(data => {
      if (IncludeInactiveToo) {
        return {
          id: data.id, code: data.code, showInDropDown: true, description: data.description, isActive: data.isActive, staffType: data.staffType
        };
      } else {
        return {
          id: data.id, code: data.code, showInDropDown: data.isActive, description: data.description, isActive: data.isActive, staffType: data.staffType
        };
      }
    });
    this.reportsinput.controls['reportNameChild'].setValue([0]);
  }


  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    const _result: any[] = <any>result.result;
    var responseUid = "";
    if (callDesc == 'GetCustomFieldsWithValues') {
      const cf: CustomFields[] = _result as CustomFields[];
      this.customFieldsValues = _result;
      //fill report selector data with custom fields info
      this.reportSelector = this.reportBuilder.reportSelector;
      this.reportSelector.forEach(rs => {
        rs.value = cf.find(c => c.columnName == rs.code) ?
          'Appointment Listing by ' + cf.find(c => c.columnName == rs.code).fieldName : rs.value;
      });
      // If custom fields not set then remove empty values from report selector drop down.
      this.reportSelector = this.reportSelector.filter(rs => rs.value != '');
      this.applyBreakPoints();
      this.accessCheckAndAlert(this.reportSelector.find(rs => rs.code == this.activeReportCode));
    } else if (callDesc == 'clientSearch') {
      const client: ClientDetailForReport[] = _result as ClientDetailForReport[];
      if (client != null) {
        if (client.length > 0)
          responseUid = client[0].requestUid;

        if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
          this.Clients = client.map(C => {
            return {
              name: `${C.clientDetail.firstName} ${C.clientDetail.lastName}`,
              id: C.id,
              guestId: C.clientDetail.guestId
            };
          })
        }
      }
      if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
        this.Clients.forEach(res => { this.SearchedClients.push(res); });
      }
    }
  }

  formInitialized(event) {
    this.reportsinput.setControl('customFilterFormGrp', event);
    this.includePreviousAndNextAppt = false;
  }

  appointmentStaffMemberFormInitialized(event) {
    this.reportsinput.setControl('customFilterFormGrp', event);
    this.includePreviousAndNextAppt = true;
  }

  getFormControlValue(event) {
    this.reportsinput.setControl('reportNameChild', event[1]);
  }

  validateForm(validity: boolean) {
    this.disableGenerateBtn = !validity;
  }

  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    this.showValidationErrorMessage(this.drowDownFilterName);

  }

  private showValidationErrorMessage(dropDownName: string): void {
    if (!this.validSelection) {
      const caption: any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validSelectionErrMsg = this.localization.replacePlaceholders(caption, ["option"], [dropDownName]);
    }
    else if (!this.textInputFilterValid) {
      const caption: any = this.localization.captions.reports.ItemNumberMissing;
      this.validSelectionErrMsg = caption;
    }
  }

  isClosed(event) {
    document.getElementById('time-overlay').classList.remove('report-transform3dnone');
    if (this.utils.getTimeDifference(this.reportsinput.controls.startTime.value, this.reportsinput.controls.endTime.value, 'Min') < 0) {
      this.reportsinput.controls.endTime.setErrors({ invalid: true });
    }
  }

  openPicker(event) {
    this.endTimeInputs.minTime = this.reportsinput.controls.startTime.value;
    this.endTimeInputs = {...this.endTimeInputs}
    document.getElementById('time-overlay').classList.add('report-transform3dnone');
  }

  customFilterBlockValidation(event: ValidationMessage) {
    if (!event.validity) {
      this.validSelection = event.validity;
      this.showValidationErrorMessage(event.dropDownName);
      this.validateForm(event.validity);
    }
    else {
      this.validSelection = true;
      this.validateForm(true);
    }
  }
  enablesaveforclientlisting() {
    this.disableGenerateButtonEvent(false);
  }

  TextInputFilterBlockValidation(event: ValidationMessage) {
    if (!event.validity) {
      this.textInputFilterValid = false;
      this.showValidationErrorMessage(this.validSelection ? event.dropDownName : this.drowDownFilterName);

    }
    else {
      this.textInputFilterValid = true;
    }
  }

  handleclick(arg, reportName) {
   // this.reportParams = undefined;
    this.reportsinput.reset();
    this.updateReportControls({ 'value': reportName });
    this.reportsinput.controls.reportName.setValue(reportName);

  }

  ApptByStaffMemberToggleChange(event: boolean[]) {
    if (event.length > 0) {
      if (event[0] == true) {
        this.includePreviousAndNextAppt = true;
        this.endDateMax = this.reportsinput.controls['startDate'].value;
        this.reportsinput.controls['endDate'].setValue(this.endDateMax);
      } else {
        this.endDateMax = null;
        this.includePreviousAndNextAppt = false;
      }
    } else {
      this.includePreviousAndNextAppt = false;
    }
  }

  saveReportConfig() {
    let activeReport: ReportSelector;
		try {
			const reportAPIoptions = this.report.generateReportAPIOptions();
      activeReport = this.getActiveReport(this.activeReportCode);
			if (!(reportAPIoptions && reportAPIoptions.code)) {
				reportAPIoptions.code = activeReport.code;
			}
      let retailReportConfig =
      {
        retailReportAPIOptions : reportAPIoptions,
        retailReportSelector : this.reportSelector
      };
      this.clickSave.emit(retailReportConfig);
		} catch (error) {
			console.error(`ERROR IN ${this.activeReportCode} Report File. ERROR => formParams/filters ${error}`)
		}
	}

}

export interface ValidationMessage {
  dropDownName: string;
  validity: boolean;
}
