import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { DetailsHeaderData, TransactionConfiguration, TransactionGridTableCard, TransactionTableData } from '../../transaction.interface';
import { CreateTransactionConfiguration } from '../create-transaction-config.interface';
import { CreateTransactionItemList } from './item-transaction-configuration.interface';
import { Formaters } from 'src/app/eatecui/source/shared/formaters';

export const DecimalArrayList = [
    'qty',
    'price',
    'unitcost',
    'totalqty',
    'conversion'
];
export const CurrencyArrayList = [
    'price',
    'unitcost'
];
export const CreateItemListTableCard: CreateTransactionItemList = (
ViewHeaderEntity: any, 
ViewDetailsEntity: any, 
TransactionData: TransactionConfiguration, 
httpService: HttpService,
PostModelHeaderEntity: any,
PostModelDetailEntity: any,
translateService?: TranslateService,
TilesHeaderTrascationEntity ?: any, 
responseData ?: Array<any>, 
homeGroupData?: FormGroup): TransactionTableData[] => {
    try {
        if ( TransactionData ) {
            const itemDetailArray = [];
            switch (TransactionData?.Name) {
                case 'cycleplanning':
                itemDetailArray.push(...constructCyclePlanItemDetail(TransactionData));
                    break;
                default:
                    itemDetailArray.push(...constructDefaultItemDetail(TransactionData));
                    break;
            }
            return itemDetailArray;
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function constructDefaultItemDetail(TransactionData: TransactionConfiguration) {
    try {
        const itemCardArray = [];
        const itemlistGridData = TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
        if ( itemlistGridData && itemlistGridData.length > 0) {
            itemlistGridData.forEach(gridData => {
                const innerarrayList = [];
                let combineObject = {};
                for (const property in gridData) {
                    if (property) {
                        // eslint-disable-next-line max-len
                        const listHeader = TransactionData.CreateTranscationConfig.ItemsConfig.ItemTableCard.ItemTableBuilder.ItemTableBuilderhead;
                        const getIndex = listHeader.findIndex(x => x.Odatakey === property);
                        if (getIndex > -1) {
                        const person = new Object();
                        if (CurrencyArrayList.includes(property.toLowerCase())) {
                            person[listHeader[getIndex]['Order']] = Formater('Currency' ,gridData[property], null, null);
                        } else if (DecimalArrayList.includes(property.toLowerCase())) {
                            person[listHeader[getIndex]['Order']] = Formater('Qty', gridData[property], null, null);
                        } else {
                            person[listHeader[getIndex]['Order']] = gridData[property];
                        }
                            innerarrayList.push(person);
                            combineObject = innerarrayList.reduce(function(acc, val) {
                            return Object.assign(acc, val);
                        }, {});
                        }
                    }
                    }
                    itemCardArray.push(combineObject);  
            });
        }
        return itemCardArray; 
    } catch (error) {
        console.log(error);
    }
}

function constructCyclePlanItemDetail(TransactionData: TransactionConfiguration) {
    try {
        const itemCardArray = [];
        const mealPeriods = TransactionData.CreateTranscationConfig.ItemsConfig.CyclePlanItemConfig.CyclePlanDetailMealCovers;
        if ( mealPeriods?.length ) {
            mealPeriods.forEach(meal => {
                const combineObject = {};
                combineObject['one'] = meal?.CyclePlanPeriodMealCover?.Name;
                combineObject['two'] = 0;
                if (meal?.Remark && typeof meal?.Remark === 'string') {
                    combineObject['two'] = Object.values(JSON.parse(meal?.Remark))?.reduce((acc: number, curr: number) => acc += curr, 0);
                }
                itemCardArray.push(combineObject);  
            });
        }
        return itemCardArray; 
    } catch (error) {

    }
}