import { Pipe, PipeTransform } from '@angular/core';
import { AgilysysButtonModel } from '../interface/button-interface';
@Pipe({name: 'agilysysoption'})
export class AgilysysButtonMoreOptionPipe implements PipeTransform {
    enabledOption: boolean;
    transform(value: AgilysysButtonModel): boolean {
        this.enabledOption = false;
        if (value.hasOwnProperty('moreOption')) {
            if (value.hasOwnProperty('moreOption') && value.moreOption.enabledMoreOption){
                this.enabledOption = true;
            }
        }
        return this.enabledOption;
      }
}