import { ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';
import { ConverterConfig } from './converter.config';

export const FixedConverter: ConverterConfig = ( FieldValue: string, DecimalValue = ConversionValue.ConversionTwo): string => {
    try {
        const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
        const decimalValue = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength;
        // if (DecimalValue !== null ) {
        //     decimalValue = DecimalValue;
        // } 
        if ( FieldValue !== null && FieldValue !== undefined && FieldValue !== '' 
        && !isNaN(parseFloat(FieldValue)) && isFinite(parseFloat(FieldValue))) {
            return parseFloat(FieldValue).toFixed(decimalValue);
        } else {
            return parseFloat('0').toFixed(decimalValue);
        }
    } catch (error) {
        console.error(error);
    }
};