import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysPicturePopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-picture-popup/agilysys-picture-popup.component';
import {
    DetailPictureConfig,
    FormBuilderGroupKey, MasterInventoryManagement
} from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { MatTabGroup } from '@angular/material/tabs';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { TransactionConfiguration } from '../transaction.interface';


export const customEnableSupplier: CustomFormConfiguration = (ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: TransactionConfiguration,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, 
    CustomData?: any): any => {
    try {

        if (FieldGrouping.CustomFormAction ) {
            const supplierDropDownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'SupplierId');
            if (FormGroupData.value['IsloadOrderGuide'] && FormField[supplierDropDownIndex]?.fieldType?.SingleData?.options?.length > 0) {
                FormField[supplierDropDownIndex].fieldType.SingleData.disbaledProperty = false;
                FormGroupData.controls['SupplierId'].reset({ value: '', disabled: false });
                FormGroupData.controls['SupplierId'].setValidators([Validators.required]);
                FormGroupData.controls['SupplierId'].markAsTouched();
            } else if (FormGroupData.value['IsloadOrderGuide'] && FormGroupData.value.LocationId === '') {
                FormGroupData.controls['LocationId'].markAsTouched();
                FormGroupData.controls['LocationId'].updateValueAndValidity();
            } else {
                FormField[supplierDropDownIndex].fieldType.SingleData.disbaledProperty = true;
                FormGroupData.controls['SupplierId'].reset({ value: '', disabled: true });
                FormGroupData.controls['SupplierId'].reset();
                FormGroupData.controls['SupplierId'].clearValidators();
            }
            FormGroupData.controls['SupplierId'].updateValueAndValidity();
        }
    } catch (error) {
        console.log('error occured openBarcodeEdit', error);
    }
};
