import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormPageComponent } from './components/form-page/form-page.component';
import { SecondaryHeaderComponent } from './components/secondary-header/secondary-header.component';
import { AgilysysButtonsModule } from '../agilysys-buttons/agilysys-buttons.module';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { MaterialModule } from '../material-module';
import { TableCardsComponent } from './components/table-cards/table-cards.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { GetIconName } from './pipes/get-Icon-name.pipe';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgilysysDynamicformModule } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { TilesComponent } from './components/tiles/tiles.componenet';
import { FormatterPipe } from './pipes/formatter.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { GlobalFormaterPipe } from './pipes/global-formater.pipe';
import { TransConvertBaseSixtyFour } from './pipes/convert-base64.pipe';
import { TileIconPipe } from './pipes/tile-icon.pipe';
import { MenuHighLightDirective } from './directives/menu-highlight.directive';
import { MenuDisabedPipe } from './pipes/menu-disable.pipe';
import { SplitTransform } from './pipes/split.pipe';
import { TransactionDetailHeaderComponent } from 'src/app/eatecui/source/transaction/shared/components/detail-card/detail-card.component';
import { TransactionItemCardComponent } from 'src/app/eatecui/source/transaction/shared/components/item-card/item-card.component';
import { TransactionItemSearchComponent } from 'src/app/eatecui/source/transaction/shared/components/item-search/item-search.component';
import { AgilysysButtonModule } from 'src/app/eatecui/dist/agilysys-button';
import { ProductReMapPipe } from './pipes/product-ramapdisplayname.pipe';
import { ImageSafePipe } from './pipes/image-safe.pipe';
import { NutrientsComponent } from './components/nutrients/nutrients.component';
import { TransactionHeaderComponent } from 'src/app/eatecui/source/transaction/shared/components/transcation-header/transcation-header.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { StorageService } from './services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { Platform } from '@angular/cdk/platform';
import { ExpandableGridComponent } from './components/expandable-grid/expandable-grid.component';
import { GridExpandPipe } from './pipes/expand-grid.pipe';
import { VjsPlayerComponent } from './components/video-player/vjs-player.component';
import { AgilysysVideoStremingComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-video-streming/agilysys-video-streming.component';

import { ValueConversionPipe } from './pipes/value-conversion.pipe';

import { HighlightPipe } from './pipes/search-highlight.pipe';
import { IconTypePipe } from './pipes/icon-type.pipe';
import { TransactionCopyDetailCardComponent } from 'src/app/eatecui/source/transaction/shared/components/copy-detail-card/copy-detail-card.component';
import { MatTabHideDirective } from './directives/mat-tab-hide.directive';
import { GhostListComponent } from './components/ghost-list/ghost-list.component';
import { GhostTransListComponent } from './components/ghost-list/ghost-list.trans.component';
import { SummaryIconPipe } from './pipes/summary-icon.pipe';
import { ApprovalHistoryComponent } from './components/approval-history/approval-history.component';
import { TransactionActionComponent } from 'src/app/eatecui/source/transaction/shared/components/transaction-action/transaction-action.component';
import { AgilysysDynamicGridModule } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { ColorCoversionPipe } from './pipes/color-conversion.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GetDayName } from './pipes/get-day-name.pipe';
import { TimeFormat } from './pipes/time-format.pipe';
import { DropdownEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/select-editor/select-editor.component';
import { TextEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/text-editor/text-editor.component';
import { NumberEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/number-editor/number-editor.component';
import { TextboxWithIconComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/textbox-with-icon/textbox-with-icon.component';
import { CurrencyDropdownComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/currency-dropdown/currency-dropdown.component';
import { DatePickerComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/date-picker/date-picker.component';
import { GridDropDownEditorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/dropdown/dropdown.component';
import { UnitsDropdownComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/units-dropdown/units-dropdown.component';
import { MultiSelectAutoCompleteComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/multi-select-auto-complete/multi-select-auto-complete.component';
import { DynamicDropDownComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/dynamic-dropdown/dynamic-dropdown';
import { DropdownSelectorComponent } from 'src/app/eatecui/source/shared/components/dynamic-grid/dropdown-selector/dropdown-selector.component';
import { CurrencyConverterPipe } from './pipes/currency-converter.pipe';
import { AppCalendarDirective } from './directives/calendar.directive';
import { ArrayToStringCoversionPipe } from './pipes/ArraytoString-conversion.pipe';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'app/eatecui/assets/i18n/', '.json');
}
const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
const DateTimeFormat = ( sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null ) ? sessionDateFormat :  
constants.DefaultIniDateFormat;
export const MY_FORMATS = {
  parse: {
      dateInput: DateTimeFormat
  },
  display: {
      dateInput: DateTimeFormat,
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};
@NgModule({
  declarations: [
    FormPageComponent, 
    SecondaryHeaderComponent, 
    ToastMessageComponent,
    TableCardsComponent, 
    GetIconName, 
    TilesComponent, 
    TrimPipe, 
    GlobalFormaterPipe, 
    FormatterPipe, 
    TransConvertBaseSixtyFour,
    TileIconPipe, 
    MenuHighLightDirective, 
    MenuDisabedPipe, 
    SplitTransform, 
    TransactionDetailHeaderComponent, 
    TransactionItemCardComponent,
    TransactionItemSearchComponent,
    TransactionHeaderComponent,
    ProductReMapPipe,
    ImageSafePipe,
    NutrientsComponent,
    ExpandableGridComponent,
    GridExpandPipe,
    ValueConversionPipe,
    HighlightPipe,
    VjsPlayerComponent,
    AgilysysVideoStremingComponent,
    MatTabHideDirective,
    GhostListComponent,
    GhostTransListComponent,
    IconTypePipe,
    TransactionCopyDetailCardComponent,
    SummaryIconPipe,
    ApprovalHistoryComponent,
    TransactionActionComponent,
    ColorCoversionPipe,
    ArrayToStringCoversionPipe,
    GetDayName,
    TimeFormat,
    DropdownEditorComponent,
    TextEditorComponent,
    TextboxWithIconComponent,
    NumberEditorComponent,
    CurrencyDropdownComponent,
    DatePickerComponent,
    GridDropDownEditorComponent,
    UnitsDropdownComponent,
    MultiSelectAutoCompleteComponent,
    DynamicDropDownComponent,
    DropdownSelectorComponent,
    CurrencyConverterPipe,
    AppCalendarDirective
  ],
  imports: [
    CommonModule,
    AgilysysButtonsModule,
    MaterialModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    FormsModule,
    AgilysysDynamicformModule,
    AgilysysDynamicGridModule,
    AgilysysButtonModule,
    AngularEditorModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, Platform, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  exports: [
    FormPageComponent,
    SecondaryHeaderComponent,
    ToastMessageComponent,
    TableCardsComponent,
    NutrientsComponent,
    TilesComponent,
    GetIconName,
    TranslateModule,
    FormatterPipe,
    TrimPipe,
    GlobalFormaterPipe,
    TransConvertBaseSixtyFour,
    TileIconPipe,
    MenuHighLightDirective,
    MenuDisabedPipe,
    SplitTransform,
    TransactionDetailHeaderComponent,
    TransactionItemCardComponent,
    TransactionItemSearchComponent,
    TransactionHeaderComponent,
    TransactionCopyDetailCardComponent,
    ProductReMapPipe,
    ImageSafePipe,
    ExpandableGridComponent,
    GridExpandPipe,
    ValueConversionPipe,
    HighlightPipe,
    VjsPlayerComponent,
    AgilysysVideoStremingComponent,
    MatTabHideDirective,
    GhostListComponent,
    GhostTransListComponent,
    IconTypePipe,
    SummaryIconPipe,
    ApprovalHistoryComponent,
    TransactionActionComponent,
    ColorCoversionPipe,
    ArrayToStringCoversionPipe,
    GetDayName,
    TimeFormat,
    CurrencyConverterPipe,
    AppCalendarDirective
  ]
})
export class EatecSharedModule {
  constructor(private translate: TranslateService) {
    const currentLanguage = this.getCurrentLanguage();
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
    this.translate.use(currentLanguage);
    console.log('Translate', translate.currentLang, translate.defaultLang);
  }

  getCurrentLanguage(): string {
    try {
      const Language = sessionStorage.getItem('appsetting') ?
        JSON.parse(sessionStorage.getItem('appsetting')).Language : constants.DefaultLanguage;
      return Language;
    } catch (error) {
      console.error('Error occurred in getCurrentLanguage', error);
    }
  }
 }


