import { BaseReport } from "../common/base-report";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportUIConfig, ReportAPIOptions, ReportParams } from "../report.modals";

/**
 * Appointment By customfield 4, Appointment By CustomField 5
 * @export
 * @class CustomFieldByTextBox
 * @extends {BaseReport}
 */
export class CustomFieldByTextBox extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public code: string, public patcheableValue) {
        super(reportFormGrp);
    }

    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            //this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            inActiveToggle: false,
            inActiveToggleName: "",
            pageBreakToggle: false,
            layout: 'LANDSCAPE'
        };
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: this.code,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }
    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) },
        { "pPropertyName": this.propertyName }, {"pDate": this.printedDate},
        { pShowComment: this.reportFormGrp.value.customFilterFormGrp.ExcludeAppointmentComments },
        { pExcludeComment: this.reportFormGrp.value.customFilterFormGrp.ExcludeAppointmentComments ? this.captions.Yes : this.captions.No }]
    }
    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "startDate": toApi(this.startDate) }, { "endDate": toApi(this.endDate) },{ "customFieldColumn": this.code }];
    }
    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        if (this.isJasperReportEnabled) {
            return {
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
				ExcludeAppointmentComments:this.reportFormGrp.value.customFilterFormGrp.ExcludeAppointmentComments
            };
        }
        if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
            return JSON.stringify({
                fromDate: toApi(this.formGrp.get('startDate').value),
            toDate: toApi(this.formGrp.get('endDate').value),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            });
        }
        return {};
    }
    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }

}
