import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstCharUpperPipe'
})
export class FirstCharUpperPipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value){
      let low_value = value.toLowerCase();
      return low_value.charAt(0).toUpperCase()+low_value.slice(1);

    }
    return value;
  }

}
