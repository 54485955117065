import { Component, OnInit, ViewEncapsulation, Inject, Output, EventEmitter, Input } from '@angular/core';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { TableOptions, TableHeaderOptions } from 'src/app/common/Models/ag-models';
import { ConsentManagementBusiness } from './consent-management.business';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { GuestPolicyDetail, PolicyCategoryType, PolicyType } from '../shared/shared.modal';
import { ApplyPolicy, ConsentPolicyUIModel } from './consent-management.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-consent-management',
  templateUrl: './consent-management.component.html',
  styleUrls: ['./consent-management.component.scss'],
  providers: [ConsentManagementBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ConsentManagementComponent implements OnInit {
  captions: any;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  headerOptions: TableHeaderOptions[];
  options: TableOptions;
  tableContent;
  floatLabel;
  message: string;
  @Output() onSaveEmit = new EventEmitter();
  @Output() onCancelEmit = new EventEmitter();
  @Input("inputData") guestPolicyDetail: GuestPolicyDetail = new GuestPolicyDetail();
  @Input("popupComment") popupComment: string;
  automationId:string="consentManagement";
  showComment = false;
  showConsentComment = false;
  public isFromDialog: boolean = false;
  constructor(private localization: Localization
    , private business: ConsentManagementBusiness
    , private utils: CommonUtilities
    , @Inject(MAT_DIALOG_DATA) public dialogData
    , private dialogRef: MatDialogRef<any>) {
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.initializeInputs();
    this.initalizeTableContent();
  }

  initializeInputs() {
    this.isFromDialog = this.dialogData?.isFromDialog ? true : false;
    this.guestPolicyDetail.policyCategoryType = this.isFromDialog ? this.dialogData?.policyCategoryType : this.guestPolicyDetail.policyCategoryType
    this.options = this.business.getTableOptions();
    this.headerOptions = this.business.getHeaderOptions();
    this.floatLabel = this.localization.setFloatLabel;
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    };
    if ((this.popupComment && this.popupComment != '') || (this.dialogData && this.dialogData.popupComment)) {
      this.showComment = true;
      this.message = this.popupComment && this.popupComment != '' ? this.popupComment : (this.dialogData && this.dialogData.popupComment ? this.dialogData.popupComment : '');
    }

  }

  initalizeTableContent() {
    if (this.guestPolicyDetail) {
      this.business.GetConsentPoliciesUsingCategoryId(this.guestPolicyDetail.policyCategoryType).then(value => {
        let tableContent = value.map(x => {
          var isGuestPolicyDetailExist = this.guestPolicyDetail && this.guestPolicyDetail.consentPolicyId == x.consentPolicyId
          return {
            id: x.consentPolicyId,
            checked: isGuestPolicyDetailExist ? true : false,
            consentPolicy: x.consentPolicyName,
            fromDate: {
              value: isGuestPolicyDetailExist ? this.guestPolicyDetail.consentDate : "",
              minDate: "",
              maxDate: ""
            },
            toDate: {
              value: isGuestPolicyDetailExist && this.guestPolicyDetail.consentExpiryDate ? this.guestPolicyDetail.consentExpiryDate : "",
              minDate: "",
              maxDate: ""
            },
            noOfDays: isGuestPolicyDetailExist && this.guestPolicyDetail.consentExpiryDate ? this.localization.getDaysDifference(this.guestPolicyDetail.consentDate, this.guestPolicyDetail.consentExpiryDate) : 0,
            comments: this.guestPolicyDetail.comments
          }
        });
        this.tableContent = [...tableContent];
        this.showConsentLabel(value);
      })
    }
  }
  showConsentLabel(consentPolicies: ConsentPolicyUIModel[]) {
    var listOfConsentPolicyIds = consentPolicies && consentPolicies.length > 0 ? consentPolicies.map(x => x.consentPolicyId) : [];
    this.showConsentComment = this.guestPolicyDetail
      && this.guestPolicyDetail.consentPolicyId
      && this.guestPolicyDetail.consentPolicyId != 0
      && listOfConsentPolicyIds
      && listOfConsentPolicyIds.length >= 0
      && !listOfConsentPolicyIds.includes(this.guestPolicyDetail.consentPolicyId)
      && this.guestPolicyDetail.policyCategoryType !== PolicyCategoryType.Contact
      ? true : false;
    if (this.showConsentComment) {
      this.business.GetPolicyDetailsUsingPolicyId(this.guestPolicyDetail.consentPolicyId).then((value) => {
        if (value && value.policyType == PolicyType.ConsentPolicy) {
          value.policyName = value.policyName == undefined || value.policyName == null ? "" : value.policyName;
          var consentDate = this.localization.localizeDisplayDate(this.guestPolicyDetail.consentDate);
          var policyNote = this.localization.replacePlaceholders(this.captions.lbl_consentPolicyLabel, ['consentPolicyName', 'consentDate'], [value.policyName.toString(), consentDate])
          this.message = this.message && this.message != '' ? this.message.concat(',', policyNote) : policyNote;
        }
      });
    }
  }
  onSave(e) {
    let policy = this.getSavePolicy();
    if (!this.isFromDialog) {
      this.onSaveEmit.emit(policy);
    }
    else {
      this.dialogData.callback(policy, this.dialogData?.arrGuestId, this.dialogRef);
    }
  }
  onCancel(e) {
    if (!this.isFromDialog)
      this.onCancelEmit.emit();
    else
      this.dialogRef.close();
  }
  hideWarning() {
    this.showComment = false;
    this.showConsentComment = false;
  }
  getSavePolicy(): ApplyPolicy {
    let selectedPolicy = this.tableContent.filter(x => x.checked == true)[0];
    var applyPolicy: ApplyPolicy = { policyId: selectedPolicy.id, guestId: !this.isFromDialog ? this.guestPolicyDetail.id : "", policyType: PolicyType.ConsentPolicy, consentDate: '', consentExpiryDate: '' };
    applyPolicy.consentDate = selectedPolicy.fromDate.value && selectedPolicy.fromDate.value != "" ? this.localization.convertDateObjToAPIdate(selectedPolicy.fromDate.value) : this.localization.convertDateObjToAPIdate(new Date(this.utils.PropertyInfo.CurrentDate));
    applyPolicy.consentExpiryDate = selectedPolicy.toDate.value && selectedPolicy.toDate.value != "" ? this.localization.convertDateObjToAPIdate(selectedPolicy.toDate.value) : null;
    if ((applyPolicy.policyId == 0) && !this.isFromDialog) {
      this.utils.showCommonAlert(this.captions.noConsentPolicySelected, AlertType.Done, ButtonTypes.Ok, async (res) => {
        if (res === AlertAction.YES) {
        }
      })
    }
    return applyPolicy
  }

  tableAction(event) {
    switch (event.fromType) {
      case FromTypeEnum.radioButton: {
        this.actionButton.disabledproperty = false;
        this.tableContent.forEach((x) => {
          if (event.Obj.id != x.id) {
            x.checked = false;
            x.noOfDays = 0;
            x.fromDate.value = "";
            x.toDate.value = "";
          }
          else {
            x.checked = true;
            x.fromDate.value = this.localization.getDate(new Date(this.utils.PropertyInfo.CurrentDate));
          }
        });
        this.tableContent = [...this.tableContent];
      }
    }
  }

  emitTableData(event, obj, data, key) {
    let noOfDays: number = 0;
    obj.fromDate.value = obj.fromDate.value == null || obj.fromDate.value == "" ? new Date(this.utils.PropertyInfo.CurrentDate) : obj.fromDate.value;
    obj.toDate.value = obj.toDate.value && obj.toDate.value != "" && obj.fromDate.value > new Date(obj.toDate.value) ? obj.fromDate.value : obj.toDate.value;
    this.tableContent.forEach((x) => {
      if (obj.id == x.id) {
        noOfDays = obj.toDate.value && obj.toDate.value != "" ? this.localization.getDaysDifference(obj.fromDate.value, obj.toDate.value) : 0;
        x.noOfDays = noOfDays;
        x.fromDate.value = obj.fromDate.value;
        x.toDate.value = obj.toDate.value;
      }
      else {
        x.noOfDays = 0;
      }
    });
    obj.noOfDays = noOfDays;
    this.tableContent = [...this.tableContent];
    this.actionButton.disabledproperty = false;
  }
}
