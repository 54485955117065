import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { JWT_TOKEN, REMEMBER_INFO, USERS_SESSSIONS_INFO } from '../app-constants';
import { UserProperty } from '../common/Models/common.models';
import { SignalrService } from 'src/app/common/communication/signalR/signalr.service';
import { Host } from '../retail/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../retail/shared/service/http-call.service';
import { SpaUtilities } from '../shared/utilities/spa-utilities';
import { NotificationFailureType } from '../shared/menu/menu.model';
import { RetailPropertyInformation } from '../retail/common/services/retail-property-information.service';
import { SpaLocalization } from '../core/localization/spa-localization';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { ADB2CAuthConfiguration } from '../common/shared/auth.config';
import { OAuthService } from 'angular-oauth2-oidc';
import { AlertType } from '../shared/globalsContant';
import { AuthenticationService } from '../common/shared/services/authentication.service';
import { TenantManagementCommunication } from '../common/communication/services/tenantmanagement-communication-service';
import moment from 'moment';

// This class must not include or have any dependecy on the http-call.service.ts
@Injectable({
  providedIn: 'root'
})
export class ManageSessionService {

  token = {
    refresh_token: 'refreshtokencode',
    exp: '',
    access_token: {
      username: 'user',
      roles: ['Admin', 'RegisteredUser', 'Super User']
    }
  };

  scope: string = "Spa"
  state: string = Date.now() + "" + this.getRandomDecimal();
  tokenKey: string = "a5smm_utoken"
  propertyKey: string = "propertyInfo"
  url: string = "";
  tenantId: any = 1;
  locations: any[];
  propertyValues: any[];
  userSessionId: string = "userSession";
  public timerSubscriptionForNotification: Subscription;
  public timerForNotification: Observable<number>;

  public timerSubscriptionForAppointmentLockRelease: Subscription;
  public timerForAppointmentLockRelease: Observable<number>;
  public transactionCount: BehaviorSubject<{ id: number, count: number, message?: string }[]> = new BehaviorSubject([]);
  public resetOnTrigger = false;
  public timeoutExpired: Subject<number> = new Subject<number>();

  private count = 0;
  private logOffAfter: number;
  private timeoutSeconds: number;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  private triggerTimeout: any;
  captions: any;
  logOutWaitingtime: number = 120000; //milliseconds
  tokenTimerSubscription: Subscription;
  private tokenTimer: Observable<number>;

  rememberDetail: any[] = [{ name: '' }];

  constructor(private router: Router, public dialogRef: MatDialog, private util: SpaUtilities,
    public propertyInformation: RetailPropertyInformation,
    public http: HttpServiceCall, private signalR: SignalrService, private localize:SpaLocalization,
    private oauthService: OAuthService,private adb2cAuthConfiguration: ADB2CAuthConfiguration,
    public loginService: TenantManagementCommunication
  ) {
      this.captions = this.localize.captions;
    this.timeoutExpired.subscribe(n => {
      //timeout;
    });
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
    if (this.timerSubscriptionForNotification) {
      this.timerSubscriptionForNotification.unsubscribe();
    }
    if(this.timerSubscriptionForAppointmentLockRelease){
      this.timerSubscriptionForAppointmentLockRelease.unsubscribe();
    }
    this.start();
  }

  ngOnDestroy() {
    this.timeoutExpired.unsubscribe();
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
    this.stopTimerForNotification();
    this.stopTimerForAppointmentLockRelease();
  }

  public GetPropertyInfo(name: string) {
    // Not Implemented.
  }

  StoreUser(user: string) {
    let rememberList = this.GetRememberedUsers();
    if (rememberList.find(x => x.name == user)) { return; }
    rememberList = [{ name: user }];
    sessionStorage.setItem(REMEMBER_INFO, JSON.stringify(rememberList));
    localStorage.setItem(REMEMBER_INFO, JSON.stringify(rememberList));
  }

  RemoveUser(user) {
    const storedUsers = this.GetRememberedUsers();
    const updatedStore = storedUsers.filter((obj) => {
      return obj.name !== user;
    });
    sessionStorage.setItem(REMEMBER_INFO, JSON.stringify(updatedStore));
    localStorage.setItem(REMEMBER_INFO, JSON.stringify(updatedStore));
  }

  GetRememberedUsers(): any[] {
    let rememberList = JSON.parse(sessionStorage.getItem(REMEMBER_INFO));
    rememberList = (rememberList != null) ? rememberList : [];
    return rememberList;
  }

  CheckRememberDetails(): boolean {
    let rememberList = JSON.parse(sessionStorage.getItem(REMEMBER_INFO));
    rememberList = (rememberList != null) ? rememberList : [];
    return rememberList.length > 0;
  }

  serverLogOut() {
    const userName = this.util.GetUserInfo('userName');
    const tenantId = this.util.GetPropertyInfo('TenantId');
    const propertyId: number = Number(this.util.GetPropertyInfo('PropertyId'));

    const uriParams = {
        Username: userName
        , TenantId: tenantId
        , PropertyId: propertyId
    };

    const serviceParams = {
        route: "User/LogOutByUserId/{Username}/{TenantId}/{PropertyId}",
        uriParams,
        header: '',
        body: '',
        showError: false,
        baseResponse: true
    };

    return this.loginService.postPromise(serviceParams);
  }

  async createSession() {
    // Not Implemented.
  }

  async updateSession() {
    const sessionData = {
      isActive: false,
      endTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
  };

  const sessionId: string = sessionStorage.getItem(this.userSessionId);
  if (!sessionId) {
      return;
  }
  const serviceParams = {
      route: "User/session/sessionId/{sessionId}",
      uriParams: { sessionId },
      header: '',
      body: sessionData,
      showError: true,
      baseResponse: true
  };

  await this.loginService.putPromise(serviceParams);
  }

  public startTimer(logOffAfter: any, tokenExpiry: number) {

    if (logOffAfter == 0) {
      if (tokenExpiry > 122) {
        tokenExpiry = tokenExpiry - 122; // buffer time for token expiry
      }
      this.tokenTimer = timer(tokenExpiry * 1000);
      this.timerSubscription = this.tokenTimer.subscribe(n => {
        this.timerComplete(n, true);
      });
    }
    else {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.timeoutSeconds = logOffAfter * 60;
      this.timer = timer(this.timeoutSeconds * 1000);
      this.timerSubscription = this.timer.subscribe(n => {
        this.timerComplete(n, false);
      });
    }
  }
  public forceLogOff() {
    let jwtExpiryTime: any = sessionStorage.getItem('jwtExpiryTime');
    jwtExpiryTime = new Date(jwtExpiryTime);
    let currentTime: any = new Date();
    let expirySeconds = jwtExpiryTime - currentTime;
    if (!sessionStorage.getItem('popupEnabled')) {
      if (this.tokenTimerSubscription) {
        this.tokenTimerSubscription.unsubscribe();
      }
      if (expirySeconds > this.logOutWaitingtime) {
        expirySeconds = expirySeconds - this.logOutWaitingtime;
      }
      this.tokenTimer = timer(expirySeconds);
      this.tokenTimerSubscription = this.tokenTimer.subscribe(n => {
        this.timerComplete(n, true);
      });
    }
    else {
      if (expirySeconds > 0) {
        this.triggerTimeout = setTimeout(() => {
          this.logout();
        }, expirySeconds);
      }
      else {
        this.logout();
      }

    }
  }

  public stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
  }

  public resetTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timer = timer(this.timeoutSeconds * 1000);
    this.timerSubscription = this.timer.subscribe(async n => {
      await this.timerComplete(n, false);
    });
  }

  private async timerComplete(n: number, displayAlert: boolean) {
        this.timeoutExpired.next(++this.count);
        if (!displayAlert) {
            this.logout();
        }
        else {
            this.util.showAlert(this.localize.captions.common.AutologoffWarning, AlertType.Warning);
            localStorage.setItem('popupEnabled', 'true')
            this.triggerTimeout = setTimeout(() => {
                this.logout();
            }, this.logOutWaitingtime);
        }
  }

  public UpdateUserSessionsInfo(loginDetails) {
    let userSessionDetails = this.GetUserSessionsInfo();
    userSessionDetails = this.mapLoginDetailsToLocalModel(loginDetails);
    this.SetUserSessionsInfo(userSessionDetails);
  }

  public SetUserSessionsInfo(userSessionDetails) {
    this.setUserSessionsInfoItem(USERS_SESSSIONS_INFO, JSON.stringify(userSessionDetails));
  }

  public GetUserSessionsInfo() {
    let userSessions: any;
    const sessionDetails = this.getUserSessionsInfoItem(USERS_SESSSIONS_INFO);
    if (sessionDetails) {
      userSessions = JSON.parse(sessionDetails);
    }
    return userSessions;
  }

  public changeTitle() {
    const title = document.getElementsByTagName('title')[0];
    title.innerText = this.getPropertyName() ? this.getPropertyName() + ' - ' + this.captions.app_title :
        this.captions.app_title;
  }

  private getUserSessionsInfoItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  private setUserSessionsInfoItem(key: string, value: string): void {
    localStorage.setItem(key, value);
    return sessionStorage.setItem(key, value);
  }

  private getPropertyName() {
    return this.localize.GetsessionStorageValue('propertyInfo', 'PropertyName');
  }

  private mapLoginDetailsToLocalModel(loginDetails) {
    return {
      token: loginDetails.token,
      expiresOn: new Date(),
      userLoginInfo: {
        firstName: loginDetails.userLoginInfo.firstName,
        lastName: loginDetails.userLoginInfo.lastName,
        isNewUser: loginDetails.userLoginInfo.isNewUser,
        isPasswordExpired: loginDetails.userLoginInfo.isPasswordExpired,
        languageCode: loginDetails.userLoginInfo.languageCode,
        productId: loginDetails.userLoginInfo.productId,
        propertyId: loginDetails.userLoginInfo.propertyId,
        tenantCode: loginDetails.userLoginInfo.tenantCode,
        tenantId: loginDetails.userLoginInfo.tenantId,
        userId: loginDetails.userLoginInfo.userId,
        isPropertyChangeAllow: loginDetails.userLoginInfo.isPropertyChangeAllow,
        userName: loginDetails.userLoginInfo.userName
      },
      userProperties: loginDetails.userProperties!=null? loginDetails.userProperties.map(userProperty => {
        return {
          autoLogOff: userProperty.autoLogOff,
          currencyCode: userProperty.currencyCode,
          isActive: userProperty.isActive,
          languageCode: userProperty.languageCode,
          logOffAfter: userProperty.logOffAfter,
          platformPropertyId: userProperty.platformPropertyId,
          platformTenantId: userProperty.platformTenantId,
          productId: userProperty.productId,
          profitCenter: userProperty.profitCenter,
          propertyCode: userProperty.propertyCode,
          propertyDate: userProperty.propertyDate,
          propertyId: userProperty.propertyId,
          propertyName: userProperty.propertyName,
          roleId: userProperty.roleId,
          roleName: userProperty.roleName,
          subPropertyCode: userProperty.subPropertyCode,
          subPropertyId: userProperty.subPropertyId,
          subPropertyName: userProperty.subPropertyName,
          tenantId: userProperty.tenantId,
          timeZone: userProperty.timeZone,
          userId: userProperty.userId,
          sessionId: null,
          arrivalTime: userProperty.arrivalTime,
          checkOutTime: userProperty.checkOutTime
        } as UserProperty;
      }) : null
    };
  }

  async validateSession() {
    //Not Implemented.
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  logout() {
    this.closeSignalRConnection();
    clearTimeout(this.triggerTimeout);
    this.triggerTimeout = null;
    this.stopTimerForNotification();
    this.stopTimerForAppointmentLockRelease();  
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.classList.remove('new-mat-view');
    this.updateSession();
    this.navigationAfterLogOut();
    this.removeToken();
    this.dialogRef.closeAll();
    this.clearLocalStore();
    this.changeTitle();
    if (this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled) {
      console.log("adb2c logout");
      this.oauthService.logOut(); //ADB2C logout
    }
  }

  navigationAfterLogOut(){
    if(sessionStorage.getItem('supportUserMailId')){
      this.router.navigate(['supportlogin']);
    }
    else{
      this.router.navigate(['login']);
    }
  }
  async closeSignalRConnection(){
    this.signalR.stopConnection();
  }  

  getToken() {
    return JSON.parse(sessionStorage.getItem(this.tokenKey));
  }

  setToken(token = this.token) {
    sessionStorage.setItem(this.tokenKey, JSON.stringify(token));
  }

  getAccessToken() {
    return JSON.parse(sessionStorage.getItem(this.tokenKey))['access_token'];
  }

  isAuthenticated() {
    let token = sessionStorage.getItem(this.tokenKey);

    if (token) {
      return true;
    }
    else {
      return false;
    }
  }


  removeToken() {
    sessionStorage.removeItem(this.tokenKey);
    sessionStorage.removeItem("_jwt");
  }

  clearLocalStore() {
    sessionStorage.clear();
  }

  getRandomDecimal(): number {
    const crypto = window.crypto;
    return crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
  }
  public startTimerForNotification(notifyin: number) {
    if (notifyin && notifyin > 0) {
      this.stopTimerForNotification();
      this.timerForNotification = timer(notifyin * 60 * 1000);
      this.timerSubscriptionForNotification = this.timerForNotification.subscribe(n => {
        this.timerCompleteForNotification(n);
      });
    }
  }

  private async timerCompleteForNotification(n: number) {
    try {
      if (this.propertyInformation.HasRevenuePostingEnabled) {
        const revenuePostingFailures = await this.getRevenuePostingCount();
        this.transactionCount.next([{ id : NotificationFailureType.revenuePostingFailure, count : revenuePostingFailures }]);
      }
      const paymenentFailures = await this.getTransactionLogCount();
      this.transactionCount.next([{ id : NotificationFailureType.paymentTransactionFailure, count : paymenentFailures }]);
      if(this.propertyInformation.IsDMPostingEnabled){
        const dMPostingFailure = await this.getFailedDMPostingCount();
        this.transactionCount.next([{ id : NotificationFailureType.dMPostingFailure, count : dMPostingFailure }]);
      }
      this.startTimerForNotification(10);
    } catch (err) {
      if (err && err.status === 401) {
        this.stopTimerForNotification();
    }
    }
  }


  public startTimerForAppointmentRelease() {
      this.stopTimerForAppointmentLockRelease();
      this.timerForAppointmentLockRelease = timer(60 * 30000);
      this.timerSubscriptionForAppointmentLockRelease = this.timerForAppointmentLockRelease.subscribe(n => {
        this.timerCompleteForAppointmentLockRelease();
      });
  }

  private timerCompleteForAppointmentLockRelease() {
    this.releaseAppointmentLock().then(s => {
     console.log();
    });
    this.startTimerForAppointmentRelease();
  }

  public async getRevenuePostingCount(): Promise<number> {
    const response = await this.http.CallApiAsync<number>({
      callDesc: 'GetFailureRevenuePosting',
      host: Host.retailPOS,
      method: HttpMethod.Get,
      showError: true
    });
    return response.result;
  }
  public async getTransactionLogCount(): Promise<number> {
    const response = await this.http.CallApiAsync<number>({
      callDesc: 'GetFailureDetails',
      host: Host.payment,
      method: HttpMethod.Get,
      showError: true
    });
    return response.result;
  }

  public async releaseAppointmentLock(): Promise<any> {
    const response = await this.http.CallApiAsync<any>({
      callDesc: 'ReleaseAppointmentLocks',
      host: Host.schedule,
      method: HttpMethod.Put,
      uriParams : {isFromScheduler :true},
      body:null,
      showError: true
    });
    return response.result;
  }
  
  public async getFailedDMPostingCount(): Promise<number> {
    const response = await this.http.CallApiAsync<number>({
      callDesc: RetailRoutes.FailedDMPostingCount,
      host: Host.retailPOS,
      method: HttpMethod.Get,
      showError: true
    });
    return response.result;
  }

  public stopTimerForNotification() {
    if (this.timerSubscriptionForNotification) {
      this.timerSubscriptionForNotification.unsubscribe();
    }
  }

  
  public stopTimerForAppointmentLockRelease() {
    if (this.timerSubscriptionForAppointmentLockRelease) {
      this.timerSubscriptionForAppointmentLockRelease.unsubscribe();
    }
  }
  start(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
 storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        const jwt = sessionStorage.getItem(JWT_TOKEN);
        const propertyInfo = this.propertyInformation.GetPropertyInfoByKey('PropertyId');
        const localData = JSON.parse(event.newValue);
        if(jwt == localData['jwt'] && propertyInfo == localData['propertyInfo'] && this.propertyInformation.GetPropertyInfoByKey('ProductId') == localData['Product']){
            this.logout()  
        }
      }
    }
}
}