import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridTableCard, MasterInventoryManagement } from '../master.interface';
import { MasterToggleButtonChange } from './master-detail-config.interface';
import * as RetailInventory from '../../../../../assets/json/master/retail-inventory.json';


export const MasterToggle: MasterToggleButtonChange =
    (MasterDataCollection: MasterInventoryManagement, InventoryType: any): any => {
        const MasterJson: MasterInventoryManagement [] = (RetailInventory as any).default;
        const RetailJson: MasterInventoryManagement = MasterJson.filter(x => x.Key === 'Ingredients')[0];

            MasterDataCollection = RetailJson;
        return MasterDataCollection;
    };