export interface UserListState {
    GetUsersList: any;
    GetUserListSuccess: any;
    GetUserListFailure: any;
    DeactivateUser: any;
    DeactivateUserSuccess: any;
    DeactivateUserFailure: any;
    ActivateUser: any;
    ActivateUserSuccess: any;
    ActivateUserFailure: any;
}

export const initialUsersList: UserListState = {
    GetUsersList: null,
    GetUserListFailure: null,
    GetUserListSuccess: null,
    DeactivateUser: null,
    DeactivateUserSuccess: null,
    DeactivateUserFailure: null,
    ActivateUser: null,
    ActivateUserSuccess: null,
    ActivateUserFailure: null
};
