import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GuestType } from '../../appointment-popup.model';

@Component({
  selector: 'app-confirm-clients-popup',
  templateUrl: './confirm-clients-popup.component.html',
  styleUrls: ['./confirm-clients-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmClientsPopupComponent implements OnInit {

  captions: any;
  guestTypes: { id: GuestType; name: string; description: any; }[];
  cancelButton: ButtonValue;
  applyButton: ButtonValue;
  guestTypeForm: UntypedFormGroup;
  selectedGuestType: number = 1;

  constructor(public dialogRef: MatDialogRef<ConfirmClientsPopupComponent>, private localization: SpaLocalization,
    @Inject(MAT_DIALOG_DATA) public dialogData,private fb: UntypedFormBuilder) {
      this.captions = this.localization.captions;
    }

  ngOnInit(): void {
    this.selectedGuestType = this.dialogData;
    this.guestTypeForm = this.fb.group({
      guestType: this.selectedGuestType
    })
      this.guestTypes = [
        { id: GuestType.SpaGuest, name: 'spa', description: this.captions.spaGuest },
        { id: GuestType.OperaGuest, name: 'opera', description: this.captions.operaGuest },
      ]
    this.applyButton = { type: 'primary', label: this.captions.btn_apply, disabledproperty:false};
    this.cancelButton = { type: 'secondary', label: this.captions.btn_cancel, disabledproperty:false};
  }

  guestTypeChange(e){
    this.selectedGuestType = e.value;
  }
  apply(){
    this.dialogRef.close(this.selectedGuestType);
  }
  cancel(){
    this.dialogRef.close(0);
  }

}
