<section class="golfdatepicker" *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'">
    <div [formGroup]="form">
      <!-- <mat-form-field appearance="fill" [ngClass]="field.fieldType[fieldType]?.disbaledProperty ? 'datepicker-disabled':  ''">
        <mat-label>{{formFieldlabel}}</mat-label>
        <input [min]="minDate" [max]="maxDate" [required]="errorLength > 0" (dateChange)="eventCallback($event)" matInput [matDatepicker]="picker" [value]=""  
          [readonly]="field.fieldType[fieldType]?.disbaledProperty"
          [disabled]="field.fieldType[fieldType]?.disbaledProperty" [formControlName]="formFieldName"
          [id]="formFieldName" (change)="valueChange($event)" [placeholder]="IniDateFieldFormat ? IniDateFieldFormat : ''">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
      </mat-form-field> -->

      
      <mat-form-field appearance="fill" [ngClass]="field.fieldType[fieldType]?.disbaledProperty ? 'datepicker-disabled':  ''">
        <mat-label>{{formFieldlabel}}</mat-label>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate [formControlName]="formFieldNameFromRange" [placeholder]="IniDateFieldFormat ? IniDateFieldFormat : 'Start Date'">
          <input matEndDate [formControlName]="formFieldNameToRange" [placeholder]="IniDateFieldFormat ? IniDateFieldFormat : 'End Date'">
        </mat-date-range-input>
        <mat-date-range-picker #rangePicker>
        </mat-date-range-picker>
        <mat-error *ngIf="!isValid">{{errorMessage}}</mat-error>
      </mat-form-field>
    </div>
  </section>