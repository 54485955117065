import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from '../../localization/localization';
import { DropdownOptions } from '../../Models/ag-models';
import { AlertAction, AlertType, ButtonTypes } from '../../Models/common.models';
import { ButtonValue } from '../../shared/retail.modals';
import { PropertyInformation } from '../../shared/services/property-information.service';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CategoryArray, CompLinkform, ComponentAccessTypeLink, dropDownOptionsId, EmitCompLinkData, SelectionType, TransactionType } from '../component-linking-base/component-linking-base.model';
import { ComponentLinkingBusiness } from './component-linking.business';
import _, { map } from 'lodash';
import { PlayerCategory } from '../../enums/shared-enums';
import { ComponentAccessTypeLinkConfigProvider } from '../../services/component-access-type-linking-config-provider.service';


@Component({
  selector: 'app-component-linking',
  templateUrl: './component-linking.component.html',
  styleUrls: ['./component-linking.component.scss'],
  providers: [ComponentLinkingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ComponentsLinkingComponent implements OnInit {
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  compLinkingForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  isViewOnly: boolean = true;;
  captions: any;
  dropdownOptions: DropdownOptions[];
  bindData: CompLinkform[];
  categoryArray: CategoryArray[];
  bindInitialArrayData: CategoryArray[][];
  existingComponentAccessLink: ComponentAccessTypeLink[] = [];
  EmitCompLinkData: EmitCompLinkData;
  previousEmitCompLinkData: EmitCompLinkData;
  automationId:string="ComponentLinking";
  constructor(
    public Commonutils: CommonUtilities,
    public business: ComponentLinkingBusiness,
    private localization: Localization,
    public propertyInformation: PropertyInformation,
    private _componentAccessConfig: ComponentAccessTypeLinkConfigProvider
  ) { }

  ngOnInit(): void {
    this.Commonutils.ToggleLoader(true)
    this.validateBreakPoint();
    this.initialize();
  }

  initialize() {
    this.captions = this.localization.captions;
    this.actionButton = {
      label: this.captions.setting.save,
      type: 'primary',
      disabledproperty: true
    };
    this.cancelButton = {
      label: this.captions.btn_cancel,
      type: 'tertiary'
    };
    this.initializeInputs(TransactionType.Sale, PlayerCategory.Player);
  }

  async initializeInputs(transactionType, playerType, onSave: boolean = false) {
    this.business.getDropDownValues().then(x => {
      this.dropdownOptions = x;
    });
    this.business.getBindData().then(x => {
      this.bindData = x;
    });
    await this.business.getComponentAccessTypeLink().then(x => {
      this.existingComponentAccessLink = x;
      if (onSave) {
        let existingConfig = this._componentAccessConfig.ComponentAccessTypeConfig;
        existingConfig.ComponentLinkChildConfig = x;
        this._componentAccessConfig.UpdateComponentAccessConfigSessionValue(existingConfig);
      }
    });
    this.getInitialArrayData(transactionType, playerType);

  }
  getInitialArrayData(transactionType, playerType) {
    this.business.getInitialArrayData(transactionType, playerType).then(x => {
      this.SelectPaymentMethods(transactionType, playerType, x);
      this.bindInitialArrayData = x;
    });

  }

  SelectPaymentMethods(transactionType, playerType, initialArray) {
    let componentAccessTypeLink: ComponentAccessTypeLink = this.existingComponentAccessLink.
      find(x => x.masterParentTypeId === dropDownOptionsId.TRANSACTION_TYPE
        && x.masterChildTypeId === dropDownOptionsId.PLAYER_CATEGORY
        && x.parentId === transactionType
        && x.childId === playerType);
    if (componentAccessTypeLink) {
      let existingPaymentsMapping = this.existingComponentAccessLink.filter(y => y.recursiveLinkId === componentAccessTypeLink.id).map(p => p.childId);
      initialArray[2].map(z => z.checked = existingPaymentsMapping.includes(z.id));
    }
  }

  onAction(e) {
    // use below values for API
    let isSaveSuccess: boolean = false;
    this.business.saveComponentAccessTypeLink(this.EmitCompLinkData).then(x => {
      isSaveSuccess = x;
      if (isSaveSuccess) {
        this.Commonutils.showCommonAlert(this.captions.common.configSaveSuccessFrom, AlertType.Success, ButtonTypes.Ok);
        let selectedTransactionTypeId: number = this.EmitCompLinkData.singleCategoryData[0].find(x => x.checked) ? this.EmitCompLinkData.singleCategoryData[0].find(x => x.checked).id : 0;
        let selectedPlayerCategoryId: number = this.EmitCompLinkData.singleCategoryData[1].find(x => x.checked) ? this.EmitCompLinkData.singleCategoryData[1].find(x => x.checked).id : 0;
        this.resetAllForm(selectedTransactionTypeId, selectedPlayerCategoryId, true);
      }
    });
  }

  onCancel(e) {
    if (this.compLinkingForm && this.compLinkingForm.dirty) {
      this.Commonutils.showCommonAlert(this.captions.combineGuest_warning, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.resetAllForm(TransactionType.Sale, PlayerCategory.Player);
        }
      });
    }
  }

  resetAllForm(transactionType, playerType, onSave: boolean = false) {
    if (this.compLinkingForm) {
      this.compLinkingForm.reset();
    }
    this.initializeInputs(transactionType, playerType, onSave);
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    if (this.compLinkingForm) {
      this.compLinkingForm.reset();
    }
  }

  formReady(e) {
    this.compLinkingForm = e;
    this.compLinkingForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.actionButton.disabledproperty = !(this.compLinkingForm.valid && this.compLinkingForm.dirty);
      this.actionButton = { ...this.actionButton };
    });
  }

  validateBreakPoint(): void {
    this.isViewOnly = false;
  }

  typeChange(e) {
    this.business.getTypeChangeData(e.value, e.index).then(x => {
      this.categoryArray = x;
    });
  }

  emittedData(e) {
    const doNotMapExistingValues = [SelectionType.SelectAll, SelectionType.DeSelectAll, SelectionType.BindDataPatchValue];
    if (!doNotMapExistingValues.includes(e.selectionType)) {

      let mappedPaymentIds: number[] = [];
      let existingPaymentsMapping: ComponentAccessTypeLink[] = [];
      let newSelectedTransactionTypeId: number = e.singleCategoryData[0]?.find(x => x.checked) ? e.singleCategoryData[0].find(x => x.checked).id : 0;
      let newSelectedPlayerCategoryId: number = e.singleCategoryData[1]?.find(x => x.checked) ? e.singleCategoryData[1].find(x => x.checked).id : 0;
      let componentAccessTypeLink: ComponentAccessTypeLink = this.existingComponentAccessLink.
        find(x => x.masterParentTypeId === dropDownOptionsId.TRANSACTION_TYPE
          && x.masterChildTypeId === dropDownOptionsId.PLAYER_CATEGORY
          && x.parentId === newSelectedTransactionTypeId
          && x.childId === newSelectedPlayerCategoryId);
      if (componentAccessTypeLink) {
        existingPaymentsMapping = this.existingComponentAccessLink.filter(x => x.recursiveLinkId === componentAccessTypeLink.id);
      }

      if (existingPaymentsMapping.length > 0) {
        mappedPaymentIds = existingPaymentsMapping.map(x => x.childId);
        mappedPaymentIds = _.sortBy(mappedPaymentIds);
      }
      if (e.selectionType !== SelectionType.Multiple) {
        this.bindMappedPaymentMethods(mappedPaymentIds, e);
      }

    }
    this.EmitCompLinkData = e;

  }

  bindMappedPaymentMethods(mappedPaymentIds: number[], emittedData) {
    this.bindInitialArrayData[0] = emittedData.singleCategoryData[0];
    this.bindInitialArrayData[1] = emittedData.singleCategoryData[1];
    if (emittedData.form.get('link').dirty && !emittedData.discardChanges) {
      this.bindInitialArrayData[2] = emittedData.multipleCategoryData[2];

    }
    else {
      this.bindInitialArrayData[2].forEach(x => x.checked = false);
      if (mappedPaymentIds.length > 0) {
        this.bindInitialArrayData[2].forEach(x => {
          if (mappedPaymentIds.some(y => y === x.id)) {
            x.checked = true;
          }
        });
      }

    }
    this.bindInitialArrayData = [...this.bindInitialArrayData];
  }

}
