import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { ConversionType, ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';

@Pipe({
  name: 'valueConversion'
})
export class ValueConversionPipe implements PipeTransform {
  transform(value: any, conversionType = ConversionType.TOFIXED, conversionValue = ConversionValue.ConversionOne): any {
    let returnValue = value;
    switch (conversionType) { // conversionType is the format type
      case ConversionType.TOFIXED:  // Tofixed Format case
        const fixedValue = !isNaN(Number(value)) && value !== '' && value !== undefined && value !== null ? parseFloat(value.toString()).toFixed(conversionValue) : value; // conversionValue is the precision value
        returnValue = fixedValue;
        break;
      case ConversionType.ROUND:  // Math round Format case
        const roundValue = value ? Math.round(value) : value;
        returnValue = roundValue;
        break;
      default:
        break;
    }
    return returnValue;
  }
}
