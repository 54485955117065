import * as saveAs from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/pdf' });
    saveAs(blob, fileName);
};
@Injectable()
export class ExportSendBusiness {
  captions: any;


    public saveFileFunction(data: any , filename: string)
    {
        saveFile(data,filename);
    }
}