import { Injectable } from "@angular/core";
import { ReportDownloadFormat, ReportAPIOptions, ReportAPIModel,TemplateReportAPIModel } from "../business/report.modals";
import { SPAConfig } from "../../core/config/SPA-config";
import { Host } from "../../shared/globalsContant";
import { HttpServiceCall } from "../../shared/service/http-call.service";

import { SpaLocalization } from "../../core/localization/spa-localization";
import * as saveAs from 'file-saver'
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
export const saveFile = (blobContent: Blob, fileName: string) => {
  const blob = new Blob([blobContent], { type: 'application/octet-stream' });
  saveAs(blob, fileName);
};

@Injectable()
export class FastReportBusinessService {

    constructor(private spaConfig: SPAConfig, private http: HttpServiceCall, private localization: SpaLocalization,private utils: SpaUtilities) {

    }
    public downloadReport(format: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean = false): void {
        if(options.code == "ClientListing")
        {
            let downloadURL = this.spaConfig.getUrl("host." + Host.schedule) + this.spaConfig.getUrl("clientListingData");
            this.downloadFile(downloadURL, format, options, showPrintDialog);
        }
        else if(options.code == 'GuestItinerary' || options.code == 'GroupItinerary')
        {
            let downloadURL = this.spaConfig.getUrl("host." + Host.reporting) + this.spaConfig.getUrl("GetTemplateReport");
            this.downloadFile(downloadURL, format, options, showPrintDialog);
        }
        else{
            let downloadURL = this.spaConfig.getUrl("host." + Host.reporting) + this.spaConfig.getUrl("GetReport");
            this.downloadFile(downloadURL, format, options, showPrintDialog);
        }
    }
    private downloadFile(url: string, type: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean): void {
        let fileName: string;
        if (type == "PDF") {
            fileName = `${options.code}.pdf`
        }
        else if (type == "WORD") {
            fileName = `${options.code}.docx`
        } else if (type == "EXCEL" || type == "RAWDATA") {
            fileName = `${options.code}.xlsx`
        } else if (type == "IMAGE") {
            fileName = `${options.code}.jpeg`
        }
        else if(type == "CSV")
        {
            fileName = `${options.code}.csv`
        }
        options.format = type;
        let apiModel: any;
        if(options.code == "ClientListing")
        {
            apiModel = options.filters;
        }
        else if(options.code == 'GuestItinerary' || options.code == 'GroupItinerary')
        {
            apiModel = this.createTemplateAPIOptions(options);
        }
        else
        {
            apiModel = this.createAPIOptions(options);
        }
        this.http.putHTTPBlobData(url, apiModel).subscribe(res => {
            if (showPrintDialog) {
                this.showFile(res);
            } else {
                if(res.size == 0 && fileName == "ClientListing.csv")
                {
                    this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(-118));
                }
                else{
                saveFile(res, fileName);
                }
            }
        },error => {
            if(options.code == "ClientListing")
            {
                this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(-2));
            }
            else  if (options.code == 'GuestItinerary' || options.code == 'GroupItinerary') {
                this.utils.ShowErrorMessage(this.localization.captions.common.Information, 'The Client does not have Appointments in Scheduled Status');
            }
        })
    }
    ShowOrSaveFile(reportblobData:Blob,fileName:string,showPrintDialog){
        this.utils.ToggleLoader(false);
        if (showPrintDialog) {
          this.showFile(reportblobData);
        } else {
          saveFile(reportblobData, fileName);
        }
      }
  private showFile(blob) {
        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        let newTab: Window = window.open(data);
        newTab.focus();
        newTab.print();
        //Commented  this code because if we revoke the Object then download button failed to download the 
        //data  since data is garbage collected
        // setTimeout(function () {
        //     // For Firefox it is necessary to delay revoking the ObjectURL
        //     //window.URL.revokeObjectURL(data);
        // }, 100);
    }


    private createAPIOptions(options: ReportAPIOptions): ReportAPIModel {

        return {
            code: options.code,
            format: options.format ? options.format : "HTML",
            downloadFileName: "Download",
            parameters: this.arrayToObject(options.params),
            uRIParams: this.arrayToObject(options.URIParams),
            filterBody: options.filters,
            dateFormat: this.localization.dateFormat
        };
    }

    private createTemplateAPIOptions(options: ReportAPIOptions): TemplateReportAPIModel {

        return {
            code: options.code,
            format:"PDF",
            downloadFileName: "Download",
            parameters: this.arrayToObject(options.params),
            uRIParams: this.arrayToObject(options.URIParams),
            templateParams:this.arrayToTemplateParms(options.templateParams),
            filterBody: options.filters,
            dateFormat: this.localization.dateFormat
        };
    }

    private arrayToObject(objectArr: any[]): { [key: string]: string } {
        var result = {};
        objectArr.forEach(o => {
            result[Object.keys(o)[0]] = Object.values(o)[0];
        });
        return result;   //Dictionary<string,string>
    }

    private arrayToTemplateParms(objectArr: any[]): { [key: string]: Uint8Array } {
        var result = {};
        objectArr.forEach(o => {
            result[Object.keys(o)[0]] = Object.values(o)[0];
        });
        return result;   //Dictionary<string,Uint8Array>
    }



}
