import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder,UntypedFormGroup} from '@angular/forms';
import {of, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AlertType} from 'src/app/common/shared/shared/enums/enums';
import {AlertAction, ButtonType} from 'src/app/common/enums/shared-enums';
import {SftpBusiness} from './sftp.business';
import {ActionMode} from 'src/app/common/shared/shared/enums/enums';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import {
    ButtonValue,
    DropdownOptions,
    AgInputFieldConfig,
    AgDropdownConfig,
} from 'src/app/common/Models/ag-models';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { FacadeService } from 'src/app/common/services/facade.service';

@Component({
    selector: 'app-sftp',
    templateUrl: './sftp.component.html',
    styleUrls: ['./sftp.component.scss'],
    providers: [
        SftpBusiness,
        FacadeService
    ],
    encapsulation: ViewEncapsulation.None
})
export class SftpComponent  implements OnInit {
    isViewOnly : boolean;
    isHidden : boolean = false;
    isReadonly : boolean = false;
    captions : any;
    saveButton : ButtonValue;
    cancelButton : ButtonValue;
    destroyed$ : ReplaySubject < boolean > = new ReplaySubject(1);
    floatLabel : string;
    hostNameTextInputConfig : AgInputFieldConfig;
    connectionNameTextInputConfig: AgInputFieldConfig;
    userNameTextInputConfig : AgInputFieldConfig;
    passwordTextInputConfig : AgInputFieldConfig;
    remotePathTextInputConfig : AgInputFieldConfig;
    portTextInputConfig : AgInputFieldConfig;
    protocolAgDropdownConfig : AgDropdownConfig;
    protocols : DropdownOptions[];
    actionButton: ButtonValue;
    form: UntypedFormGroup;
    showRequired: boolean;
    hidePassword: boolean=true;
    @Input() crudInput;
    @Output() handleClickEvent = new EventEmitter();
    @Output() formReady = new EventEmitter();
    constructor(private fb : UntypedFormBuilder,
                private utilities : CommonUtilities,
                private _sftpBusiness : SftpBusiness,
                private _FacadeService : FacadeService,
                private localization : Localization) {
              this.captions = this._sftpBusiness.captions;
              this.floatLabel = this.localization.setFloatLabel
              }

    ngOnInit(): void {
        this.validateUserAccess();
        this.isViewOnly = this.crudInput && this.crudInput.isViewOnly;
        this.showRequired = true;
        this.createSftpForm();
        this.pageInitialization();

        if (this.crudInput.form && (this.crudInput.mode === ActionMode.update)) {
          const editFormData = this.crudInput.form;
          this.actionButton.label = this.crudInput.actionButton;
          this.actionButton = {...this.actionButton};
          this.connectionNameTextInputConfig.readonly = true;
          this.connectionNameTextInputConfig.disabled = true;
          this.form.patchValue({
            protocol: this.protocols[editFormData.ftpProtocol],
            isEnabled: editFormData.isEnabled.value,
            hostName: editFormData.hostName,
            userName: editFormData.userName,
            password: editFormData.password,
            connectionName: editFormData?.connectionName,
            remotePath: editFormData.remotePath,
            port: editFormData.port,
            id: editFormData.id
        });
        }
    }

    ngAfterViewInit() {}

    async validateUserAccess() {
        const userAccess = await this._FacadeService.getUserAccess(UserAccessBreakPoints.SFTP, true);
        this.isViewOnly = userAccess ?. isViewOnly;
        if (this.isViewOnly) {
            this.actionButton.disabledproperty = true;
            this.actionButton = {
                ...this.actionButton
            };
        }
    }

    createSftpForm() {
        this.form = this.fb.group({
            id: 0,
            isEnabled: true,
            connectionName:'',
            hostName: '',
            userName: '',
            password: '',
            remotePath: '',
            port: '0',
            protocol: ''
        });
        this.formReady.emit(this.form);
    }


    async onAction(e) {

        this.actionButton.disabledproperty = true;
        this.actionButton = {
            ...this.actionButton
        };
        const formValues = this.form.getRawValue();
        if (formValues) {
            if (formValues.connectionName == "" || formValues.connectionName == undefined) {
                this.utilities.showAlert("Connection Name is Mandatory!!!", AlertType.Error, ButtonType.Ok);
                return;
            }
            if (formValues.hostName == "" || formValues.hostName == undefined) {
                this.utilities.showAlert("Host Name is Mandatory!!!", AlertType.Error, ButtonType.Ok);
                return;
            }
            if (formValues.userName == "" || formValues.userName == undefined) {
                this.utilities.showAlert("User Name is Mandatory!!!", AlertType.Error, ButtonType.Ok);
                return;
            }
            if (formValues.password == "" || formValues.password == undefined) {
                this.utilities.showAlert("Password is Mandatory!!!", AlertType.Error, ButtonType.Ok);
                return;
            }
            if (formValues.port == "" || formValues.port == undefined) {
                this.utilities.showAlert("Port is Mandatory!!!", AlertType.Error, ButtonType.Ok);
                return;
            }
            if (formValues.id == 0)
            {
                const isConnectionNameExists = await this._sftpBusiness.checkConnectionName(formValues.connectionName);
                if (isConnectionNameExists) {
                    this.utilities.showAlert("ConnectionName Already Exists!!!", AlertType.Error, ButtonType.Ok);
                    return;
                }
            }
            const checksftpConnResponse = await this._sftpBusiness.checkSFTPConnection(formValues);
            if (!checksftpConnResponse) {
                this.utilities.showAlert("SFTP Connection is Invalid!!!", AlertType.Error, ButtonType.Ok);
                return;
            }
            let apiResponse: any;
            if (formValues.id > 0)
                apiResponse = await this._sftpBusiness.updateSftpConfig(formValues);
             else
                apiResponse = await this._sftpBusiness.createSftpConfig(formValues);

            if (apiResponse) {
                this.utilities.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok, (res) => {
                    if (res === AlertAction.CONTINUE) {
                        this.handleClickEvent.emit({
                            from: this.crudInput.mode,
                            form: this.form
                          });
                    }
                });
            } else {
                this.utilities.showAlert("Not an  valid connection", AlertType.Error, ButtonType.Ok);
            }
        }
    }

    async validationCheck(formValues : any)
    {

    }

    ngOnDestroy() {
        if (this.destroyed$) {
            this.destroyed$.next(true);
            this.destroyed$.complete();
        }
    }

    async pageInitialization() {
       this.protocols = this._sftpBusiness.getFtpProtocols();
        this.actionButton = {
            type: 'primary',
            label: this.crudInput.actionButton,
            disabledproperty: true
        };
        this.cancelButton = {
            type: 'tertiary',
            label: this.captions.btn_cancel
        };
        this.protocolAgDropdownConfig = {
            className: 'ag_form-control--lg',
            form: this.form,
            formControlName: 'protocol',
            isFirstEmpty: false,
            placeHolderId: 'lbl_protocol',
            placeHolder: this.captions.lbl_config_type,
            selectOptions: of(this.protocols),
            showRequired: true,
            errorMessage: this.captions.lbl_missingProtocols
        };
        this.connectionNameTextInputConfig = {
          className: 'ag_form-control--lg',
          form: this.form,
          formControlName: 'connectionName',
          placeHolderId: 'Sftplbl_tbl_hdr_connectionName',
          placeHolder: this.captions.tbl_hdr_connectionName,
          showRequired: true,
          errorMessageId: 'Sftp_missing_tbl_hdr_connectionName',
          errorMessage: this.captions.tbl_hdr_connectionName,
          changeLabelOnEdit: true,
          readonly: false,
          inputType: 'capitalise,notallowspace',
      };
        this.hostNameTextInputConfig = {
            className: 'ag_form-control--lg',
            form: this.form,
            formControlName: 'hostName',
            placeHolderId: 'Sftplbl_HostName',
            placeHolder: this.captions.Sftplbl_HostName,
            showRequired: false,
            errorMessageId: 'Sftp_missing_HostName',
            errorMessage: this.captions.Sftp_missing_HostName,
            changeLabelOnEdit: true,
            readonly: false
        };
        this.userNameTextInputConfig = {
            className: 'ag_form-control--lg',
            form: this.form,
            formControlName: 'userName',
            placeHolderId: 'Sftplbl_userName',
            placeHolder: this.captions.Sftplbl_userName,
            showRequired: false,
            errorMessageId: 'Sftperr_missing_UserName',
            errorMessage: this.captions.Sftperr_missing_UserName,
            readonly: false
        };
        this.passwordTextInputConfig = {
            className: 'ag_form-control--lg',
            form: this.form,
            formControlName: 'password',
            placeHolderId: 'Sftplbl_Password',
            placeHolder: this.captions.Sftplbl_Password,
            showRequired: false,
            errorMessageId: 'Sftperr_missing_Password',
            errorMessage: this.captions.Sftperr_missing_Password,
            readonly: false,
            inputType: 'password',
        };
        this.remotePathTextInputConfig = {
            className: 'ag_form-control--lg',
            form: this.form,
            formControlName: 'remotePath',
            placeHolderId: 'Sftplbl_remotePath',
            placeHolder: this.captions.Sftplbl_remotePath,
            showRequired: false,
            errorMessageId: 'Sftperr_missing_remotePath',
            errorMessage: this.captions.Sftperr_missing_remotePath,
            readonly: false
        };
        this.portTextInputConfig = {
            className: 'ag_form-control--lg',
            form: this.form,
            formControlName: 'port',
            placeHolderId: 'Sftplbl_Port',
            placeHolder: this.captions.Sftplbl_Port,
            showRequired: false,
            errorMessageId: 'Sftperr_missing_Port',
            errorMessage: this.captions.Sftperr_missing_Port,
            readonly: false
        };
        await this.patchConfiguration();
        this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            this.actionButton.disabledproperty = !(this.form.valid && this.form.dirty);
            this.actionButton = {
                ...this.actionButton
            };
        });
    }

    private async patchConfiguration(protocol : number = 0) {
        // const apiResponse = await this._sftpBusiness.getSftpConfigByProtocol(protocol);
        // // if (apiResponse) {
        // //     if (! apiResponse.isEnabled) {
        // //         this.FeildsReadOnly(true, apiResponse.id);
        // //     } else {
        // //         this.FeildsReadOnly(false, apiResponse.id);
        // //     }
        // // }
        // if (apiResponse){
        //   this.form.patchValue({
        //     protocol: apiResponse ? this.protocols.find(p => p.id == apiResponse.ftpProtocol) : this.protocols.find(p => p.id == protocol),
        //     isEnabled: apiResponse ? apiResponse.isEnabled : false,
        //     connectionName: apiResponse ? apiResponse?.connectionName : false,
        //     hostName: apiResponse ? apiResponse.hostName : '',
        //     userName: apiResponse ? apiResponse.userName : '',
        //     password: apiResponse ? apiResponse.password : '',
        //     remotePath: apiResponse ? apiResponse.remotePath : '',
        //     port: apiResponse ? apiResponse.port : '',
        //     id: apiResponse ? apiResponse.id : 0
        // });
        // }

    }

    // onChangeConfig(event) {
    //     const Id = this.form ?. value ?. id;
    //     if (event.checked) {
    //         this.FeildsReadOnly(false, Id);
    //     } else {
    //         this.FeildsReadOnly(true, Id);
    //     }
    // }


    FeildsReadOnly(result : boolean, SftpId : number) {
        this.hostNameTextInputConfig.readonly = result;
        this.connectionNameTextInputConfig.readonly = result;
        this.userNameTextInputConfig.readonly = result;
        this.passwordTextInputConfig.readonly = result;
        this.remotePathTextInputConfig.readonly = result;
        this.portTextInputConfig.readonly = result;

        if (result) {
            this.form.patchValue({
                isEnabled: true,
                connectionName: '',
                hostName: '',
                userName: '',
                password: '',
                remotePath: '',
                port: '0',
                id: SftpId
            });
        }

    }

    changeConfiguration(e) {
        this.patchConfiguration(Number(e.id));
    }


    onCancel(e) {
    this.handleClickEvent.emit({
      from: ActionMode.cancel,
      form: this.form
    });
    this.form.reset();
  }

 }
