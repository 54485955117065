import { Component, AfterViewInit, ViewChild, ViewEncapsulation, ElementRef, OnInit, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';



@Component({
    selector: 'lib-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements OnInit,  OnChanges {
    datePickerParams: any;
    dateValue = new FormControl('');

    @ViewChild('picker') datePicker: MatDatepicker<Date>;
    constructor() {}
    ngOnInit(): void {
        console.log('autoCompleteParams');
    }
    changeDatePicker(event: any) {
      try {
        this.datePickerParams.value = new Date(this.dateValue.value);
      } catch (error) {
          console.error(error);
      }
    }
    agInit(params: any) {
        this.datePickerParams = params;
        if (params.value) {
          this.dateValue.setValue(params.value);
        }
        console.log('Date Picker');
    }
    init(params: any) {
      this.datePickerParams = params;
    }
    getValue(): any {
      return this.datePickerParams.value;
    }
    checkDateFormat(DateDefaultValue: any ): any {
      try {
        let DateFormatedValue = DateDefaultValue;
        const DateTimeFormat = 'DD-MM-YYYY';
        if ( DateDefaultValue ) {
          const AlreadyFormatted = moment(DateDefaultValue, DateTimeFormat).format(DateTimeFormat) === DateDefaultValue;
          if (!AlreadyFormatted) {
              const returnValue = moment.utc(DateDefaultValue).local().format(DateTimeFormat);
              DateFormatedValue = returnValue;
          }
        }
        return DateFormatedValue;
      } catch (error) {
        console.error(error);
      }
    }
    ngOnChanges(): void {
        console.log('Tess');
    }
}
