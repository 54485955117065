import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalRestrictionPipe'
})
export class DecimalRestrictionPipePipe implements PipeTransform {

  transform(value: any, args:number): any {
    let custlength = args ? args : 2;
    return value.toFixed(custlength);
  }

}
