import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { LetterSetupBusiness } from '../letter-setup.business';
import { CreateLetterInputs, LetterLandingInputs, LetterTableContent, LetterTemplateType } from '../letter.model';

@Component({
  selector: 'app-cart-agreement',
  templateUrl: './cart-agreement.component.html',
  styleUrls: ['./cart-agreement.component.scss'],
  providers: [LetterSetupBusiness]
})
export class CartAgreementComponent implements OnInit {
  childForm: UntypedFormGroup;
  captions: any;
  createInputs: CreateLetterInputs;
  isViewOnly: boolean;
  landingPageInputs: LetterLandingInputs;
  originalData: LetterTableContent[];
  showInactiveToggle: boolean;
  constructor(private localization: Localization, private _letterBusiness : LetterSetupBusiness) { }
  ngOnInit(): void {
    this.refreshGrid();
    this.captions = this.localization.captions.retailsetup;
    this.landingPageInputs = {
      searchPlaceholder: this.captions.lbl_searchByLetterCodeLetterName,
      createBtnLabel: this.captions.btn_createletter,
      tableOptions: this.getTableOptions(this.isViewOnly)
    };
    this.createInputs = {
      letterType: LetterTemplateType.CartAgreement,
      codePlaceholder: this.captions.lbl_LetterCode,
      namePlaceholder: this.captions.lbl_LetterName,
      createTitle: this.captions.pg_title_createCartLetter,
      editTitle: this.captions.pg_title_editCartLetter,
      editorLabel: this.captions.lbl_Letter
    };

  }

  async refreshGrid() {
    this._letterBusiness.getTemplates(LetterTemplateType.CartAgreement, this.showInactiveToggle).then(res=>
      {
        this.originalData=res;
        this.landingPageInputs = {
          searchPlaceholder: this.captions.lbl_searchByLetterCodeLetterName,
          createBtnLabel: this.captions.btn_createletter,
          tableOptions: this.getTableOptions(this.isViewOnly)
        };
      });

  }
  onToggleChange(event) {
    this.showInactiveToggle = event;
    this.refreshGrid();
  }
  getTableOptions(isViewOnly: boolean) {
    return [
      {
        TableHdrData: this.GetTableHeader(),
        TablebodyData: this.originalData,
        EnableActions: !isViewOnly,
        TableDraggable: true,
        IsViewOnly: isViewOnly,
        Sortable: "listOrder",
      }
    ];
   } 

   GetTableHeader(): any {
    return [{ "title":this.captions.tbl_hdr_letterCode, "jsonkey": "code", "sortable":true ,"searchable": true},
    { "title": this.captions.tbl_hdr_letterName, "jsonkey": "name", "sortable":false,"searchable": true },
    { "title":this.captions.tbl_hdr_listOrder, "jsonkey": "listOrder", "alignType": "right", "sortable":true },
    { "title": this.captions.active, "jsonkey": "active", "sortable": true, "type": "toggle" },
    { "title": this.captions.default, "jsonkey": "isInActive", "sortable": true ,"type": "toggle"}];
  }
}
