import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { Localization } from "../../localization/localization";
import { CommonUtilities } from "../../shared/shared/utilities/common-utilities";
import { AppService } from "src/app/common/app-service";
import { HttpCallService } from 'src/app/common/communication/common/http-call.service';
import { BaseResponse, ServiceParams } from 'src/app/common/Models/http.model';
import { CommonPropertyInformation } from "../../shared/services/common-property-information.service";


@Injectable({
    providedIn: 'root'
})
export class RetailPaymentCommunication extends HttpCallService {

    constructor(httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, commonPropertyInfo: CommonPropertyInformation, appService: AppService) {
        super(environment["payment"], httpclient, localization, utilities, commonPropertyInfo,appService);
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        let response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        let response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }

    public Post<T>(params: ServiceParams) {
        return super.post<BaseResponse<T>>(params);
    }

    public Put<T>(params: ServiceParams) {
        return super.put<BaseResponse<T>>(params);
    }

    public async postPromiseDirect<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<T> = super.postPromise<T>(params);
        //on error =>
        response$.catch(err => this.error(err, handleErr));
        //on success =>
        let response = await response$;
        return response;
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        }
        else {
            throw err;
        }
    }


}
