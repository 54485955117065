import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicGridService } from '../agilysys-grid.service';
import { HeaderOverlayRef } from '../grid-header/header-overlay-ref';
import { GRID_NAME, HEADER_COLUMN_DATA } from '../grid-header/header-token';
import _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as constants from '../shared/globalConstants';
import { GridHeaderConfig } from '../interface/common';

@Component({
  selector: 'lib-reorder-panel',
  templateUrl: './reorder-panel.component.html',
  styleUrls: ['./reorder-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReorderPanelComponent implements OnInit, OnDestroy {

  checkBoxColumn: any;
  iconColumn: GridHeaderConfig;
  actionColumn: GridHeaderConfig;
  linkColumn: GridHeaderConfig;
  allColumns: GridHeaderConfig[];
  copyOfColumns: GridHeaderConfig[];
  disableReset = true;
  jsonName: any;
  defaultColumns: any;
  setToDefault: any;

  constructor(
    public dialogRef: HeaderOverlayRef,
    @Inject(HEADER_COLUMN_DATA) public columns: GridHeaderConfig[],
    @Inject(GRID_NAME) public gridName: any,
    private dynamicGridService: DynamicGridService
    ) {
    this.checkBoxColumn = this.columns.filter(x => x.headerCheckboxSelection === true)[0];
    this.actionColumn = this.columns.filter(x => x.cellRenderer === 'actionRenderer' || x.cellRenderer === 'actioniconRenderer')[0];
    this.iconColumn = this.columns.filter(x => x.cellRenderer === 'iconRenderer')[0];
    this.linkColumn = this.columns.filter(x => x.cellRenderer === 'linkActions')[0];
    // To remove non header columns
    this.allColumns = _.cloneDeep(this.columns.filter(x => x.headerName !== '' && x.headerName !== undefined));
    this.copyOfColumns = _.cloneDeep(this.columns.filter(x => x.headerName !== '' && x.headerName !== undefined ));
    // To remove non field columns
    this.allColumns = _.cloneDeep(this.allColumns.filter(x => x.field !== '' && x.field !== undefined));
    this.copyOfColumns = _.cloneDeep(this.copyOfColumns.filter(x => x.field !== '' && x.field !== undefined ));
    this.jsonName = this.gridName;
    this.defaultColumns = JSON.parse(localStorage.getItem(constants.columnInfoInitial));
    let changedColumns = JSON.parse(localStorage.getItem(constants.columnInfo));
    this.defaultColumns = !this.defaultColumns ? {} : this.defaultColumns;
    changedColumns = !changedColumns ? {} : changedColumns;
    if (!this.defaultColumns[this.jsonName]) {
      const jsonData = [];
      this.copyOfColumns.forEach(element => {
        const info = {};
        if (element.field !== '') {
          info[element.field] = !element.hide;
          jsonData.push(info);
        } else {
          info[element.field] = true;
          jsonData.push(info);
        }
      });

      this.defaultColumns[this.jsonName] = jsonData;
      changedColumns[this.jsonName] = jsonData;
      localStorage.setItem(constants.columnInfo, JSON.stringify(changedColumns));
      localStorage.setItem(constants.columnInfoInitial, JSON.stringify(this.defaultColumns));
    }

    this.setToDefault = [{
      Id: 1,
      Name: 'settodefault',
      DisplayName: 'Set To Default',
      Class: 'set-to-default',
      DisableProperity: false,
      onCellClick: (e: any) => {
        this.cancel();
      }
    }];
  }

  ngOnInit() {
  }

  agInit(params) {
    console.log(params);
  }

  /**
   * @method cancel
   * @description Set to default function for reorder
   */
  cancel() {
    try {
      this.copyOfColumns = this.assignColumnsValues(this.jsonName, this.columns);
      this.rearrangeData();
      this.dynamicGridService.setColumnDefs.next([this.copyOfColumns, this.jsonName]);
      this.dialogRef.close();
    } catch (error) {
      console.error('Error occurred in cancel', error);
    }
  }

  /**
   * @method apply
   * @description Apply function after reoreder and checkbox selection
   */
  apply() {
    try {
      if (this.columns.length !== this.copyOfColumns.length) {
        if (this.iconColumn) {
          this.copyOfColumns.unshift(this.iconColumn);
        }
        if (this.checkBoxColumn) {
          this.copyOfColumns.unshift(this.checkBoxColumn);
        }        
        if (this.actionColumn) {
          this.copyOfColumns.push(this.actionColumn);
        }
        if (this.linkColumn) {
          this.copyOfColumns.push(this.linkColumn);
        }
      }
      this.rearrangeData();
      this.dynamicGridService.setColumnDefs.next([this.copyOfColumns, this.jsonName]);
      this.dialogRef.close();
    } catch (error) {
      console.error('Error occurred in apply', error);
    }
  }

  /**
   * @method drop
   * @param event: CdkDragDrop<string[]>
   * @description After items are dropped
   */
  drop(event: CdkDragDrop<string[]>) {
    try {
      this.disableReset = false;
      moveItemInArray(this.copyOfColumns, event.previousIndex, event.currentIndex);
    } catch (error) {
      console.error('Error occurred in drop', error);
    }
  }

  /**
   * @method ngOnDestroy
   */
  ngOnDestroy() {
    this.dynamicGridService.setColumnDefs.next(null);
  }

  /**
   * @method rearrangeData
   * @description Format function to store in localstorage
   */
  rearrangeData() {
    try {
      const jsonData = [];
      this.copyOfColumns.forEach(element => {
        const info = {};
        if (element.field !== '') {
          info[element.field] = !element.hide;
          jsonData.push(info);
        } else {
          info[element.field] = true;
          jsonData.push(info);
        }
      });
      const json = JSON.parse(localStorage.getItem(constants.columnInfo));
      json[this.jsonName] = jsonData;
      localStorage.setItem(constants.columnInfo, JSON.stringify(json));
    } catch (error) {
      console.error('Error occurred in rearrangeData', error);
    }
  }

  /**
   * @method assignColumnsValues
   * @params jsonName, columnName
   * @description Format to json
   */
  assignColumnsValues(jsonName, columnName) {
    try {
      const modifiedColumns = [];
      const data = this.defaultColumns;
      const columnInfo = data[jsonName];
      const columnValues = Object.values(columnInfo);
      columnValues.forEach(value => {
        const columnKeys = Object.keys(value);
        for (const elements of columnName) {
          if (elements.field !== '' && elements.headerName !== '') {
            if (columnKeys[0] === elements.field) {
              elements.hide = !value[elements.field];
              if (!modifiedColumns.includes(elements)) {
                modifiedColumns.push(elements);
                break;
              }
            }
          } else {
            if (!modifiedColumns.includes(elements)) {
              elements.hide = false;
              modifiedColumns.push(elements);
              if (modifiedColumns.filter(x => x.field === columnKeys[0]).length !== 0) {
                break;
              }
            }
          }
        }
      });
      if (modifiedColumns.length !== columnName.length) {
        columnName.forEach(element => {
          if (!modifiedColumns.includes(element)) {
            element.hide = false;
            modifiedColumns.push(element);
          }
        });
      }
      return modifiedColumns;
    } catch (error) {
      console.error('Error occurred in assignColumnsValues', error);
    }
  }

  /**
   * @method disableButtonCheck
   * @description Disable button function
   */
  disableButtonCheck() {
    try {
      this.disableReset = this.copyOfColumns.filter(x => x.hide === true).length === this.copyOfColumns.length ? true : false;
    } catch (error) {
      console.error('Error occurred in disableButtonCheck', error);
    }
  }

  /**
   * @param event: CdkDragDrop<string
   * @method onItemDrop
   * @description After item is dropped
   */
  onItemDrop(event: CdkDragDrop<string[]>) {
    try {
      this.disableReset = false;
      moveItemInArray(this.copyOfColumns, event.previousIndex, event.currentIndex);
    } catch (error) {
      console.error('Error occurred in onItemDrop', error);
    }
  }
}
