import { UntypedFormGroup } from "@angular/forms";
import { DialogOverviewExampleDialog } from "../dialog-popup/dialogPopup-componenet";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Injectable, OnDestroy } from "@angular/core";
import { AlertAction, AlertType, ButtonType, LAST_VISITED_APPT_SLOT } from "../globalsContant";
import { MoreFilterOptions, BaseResponse } from "../business/shared.modals";
import { appointmentService } from "../service/appointment.service";
import { Router } from "@angular/router";
import { MoreSectionServiceService } from '../more-section/more-section-service.service';
import * as moment from "moment";
import { SubscriptionLike as ISubscription } from "rxjs";
import { AlertMessagePopupComponent } from "../alert-message-popup/alert-message-popup.component";
import { PMSSessionData } from 'src/app/core/core.model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

import { HttpServiceCall, HttpMethod } from 'src/app/common/shared/shared/service/http-call.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { FormatText } from 'src/app/common/shared/shared/pipes/formatText-pipe.pipe';
import * as GlobalConst from 'src/app/common/shared/shared/globalsContant';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { contactType, SplitValue } from '../shared.modal';
import { camelCase } from "lodash";
import { SpaServiceSesonalDate } from "../business/view-settings.modals";
import { AlertPopupComponent } from "src/app/ag-common/components/alert-popup/alert-popup.component";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
  } from "@angular/material/snack-bar";
import { SubPropertyModel } from "src/app/retail/retail.modals";
export enum RedirectToModules {
    retail = 1,
    order,
    appointment,
    settings,
    Utilities,
    exchange,
    Dayend,
    home,
    RevenuePostingsLog
}

export interface PatchJson {
    op: PatchOperation;
    path: string;
    value: any;
}

export interface AppointmentColors {
    BackGround: string;
    BackGroundLight: string;
    Color: string;
    ColorLight: string;
    Border: string;
}

export enum PatchOperation {
    add = "add",
    remove = "remove",
    replace = "replace",
    copy = "copy",
    move = "move",
    test = "test"
}

export enum RecurringType {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    Yearly = 3
}

export enum AppointmentColorConfiguration {
    "CKIN" = "APPOINMENT_CHECKEDIN_COLOR",
    "RESV" = "APPOINMENT_SCHEDULED_COLOR",
    "NOSHOW" = "APPOINMENT_NOSHOW_COLOR",
    "CKOUT" = "APPOINMENT_CHECKEDOUT_COLOR",
    "CANC" = "APPOINMENT_CANCEL_COLOR",
    "WAIT" = "APPOINMENT_WAIT_COLOR",
    "OPEN" = "APPOINMENT_OPEN_COLOR",
    "CLOSED" = "APPOINMENT_CLOSED_COLOR",
    "TEMP" = "APPOINMENT_TEMP_COLOR",
    "BREAK" = "APPOINMENT_BREAK_COLOR",
    "SETUP" = "APPOINMENT_SETUP_COLOR",
    "BREAKDOWN" = "APPOINMENT_BREAKDOWN_COLOR",
    "ONCALL" = "APPOINMENT_ONCALL_COLOR",
    'BLOCK' = 'LOCATION_BLOCK_COLOR'
}

export enum InputTypeNumbers {
    NUMBERS = "onlynumber",
    ONLYPOSITIVE = "nonnegative",
    ONLYNEGATIVE = "onlynegative",
    NODECIMAL = "nodecimal",
    DECIMAL = "decimal",
    ROUNDOFF = "roundoff2",
    PERCENT = "validPercentage",
    POSITIVEDECIMAL = "onlyPositiveDecimal",
    POSITIVEDECIMALORNUMERIC = 'PositiveDecimalOrNumeric',
    NUMBERWITHSEPARATOR = "numberWithSeparator",
    PREMAXDECIMAL = 'preMaxdecimal'
}

export enum InputTypeText {
    CAP = "capitalise",
    TEXT = "textonly",
    NOSPL = "nospecailchar",
    NOSPACE = "notallowspace",
    EMAIL = "email",
    FIRST_CHAR_CAP = 'firstcharcapitalise',
    NOPRESPACE = 'noprespace',
    WEBSITE = 'website',
    ALLOWPASTE = 'allowpaste',
    RESTRICTSCRIPT = "restrictscript"
}


export interface CssProp {
    property: string;
    value: string;
}


export function stringFormat(input: string, appendBy?: string) {
    if (appendBy) {
        const temptrue = (input == "" ? "" : input + appendBy);
        return input ? temptrue : "";
    } else {
        const tempFalse = (input == "" ? "" : input);
        return input ? tempFalse : "";
    }
}



export function convertPhoneNoToUiNo(num: string): string {
    if (num != null || num == "") {
        return (
            num.substring(3, 0) +
            " - " +
            num.substring(6, 3) +
            " - " +
            num.substring(num.length, 6)
        );
    } else {
        return "";
    }
}

export function tConvert(tt) {
    var time = tt.substring(tt.indexOf("T") + 1, tt.length);
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time
    ];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}

export function addMinutesToGivenTime(time, minTobeAdded) {
    let dummyDate: Date = this.getDate("2019-01-01T" + time);
    let dateTimeValue = dummyDate.setMinutes(
        dummyDate.getMinutes() + minTobeAdded
    );
    let dateTimeWithAddedMinutes = this.getDate(dateTimeValue);

    let hours = dateTimeWithAddedMinutes.getHours();
    let min = dateTimeWithAddedMinutes.getMinutes();

    return (hours < 9 ? "0" + hours.toString() : hours.toString()) + ":" + (min < 9 ? "0" + min.toString() : min.toString());
}

@Injectable(
    { providedIn: 'root' }
)
export class SpaUtilities extends CommonUtilities implements OnDestroy {

    subscription: ISubscription;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    countryDetails = [];
    constructor(public localization: SpaLocalization, public dialog: MatDialog, public httpServiceCall: HttpServiceCall, public route: Router,
        public _MoreSectionServiceService: MoreSectionServiceService, public PropertyInfo: SpaPropertyInformation, public CommonPropertyInfo: CommonPropertyInformation, public formatphno: FormatText,
        private httpClient: HttpClient, public snackBar: MatSnackBar) {
        super(localization, dialog, httpServiceCall, route,
            _MoreSectionServiceService, CommonPropertyInfo, formatphno,snackBar);
    }

    exceptionHandle(Ex: any) {
        console.error(Ex);
        throw Ex;
    }

    errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        if (callDesc == "CheckinAppointment" && result.result) 
        {
           this.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getErrorText([953]),ButtonType.Ok);
        }
        else{
        console.error(result.result);
        }
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
    /*
         * The method returns the only edited data in PatchJson format.
         * In addition to that, The @param defaultKeys -The values of the controls having the name will be added by default.
         * @param formGroup - FormGroup to get the edited data
         */

    getEditedData(formGroup: UntypedFormGroup, idKey?: string, defaultKeys?: string[], dateKeys?: string[]): any {
        let patchJson = [];
        let keyValue: string;
        let keys: string[] = Object.keys(formGroup.controls);

        defaultKeys = defaultKeys ? defaultKeys : [];
        let fnconvertDateObjToAPIdate = this.localization.convertDateObjToAPIdate;
        keys.forEach(function (key) {
            if (idKey == key) keyValue = formGroup.controls[key].value;

            if (formGroup.controls[key].dirty || defaultKeys.includes(key)) {
                var val = formGroup.controls[key].value;
                if (dateKeys && dateKeys.includes(key)) {
                    val = typeof val != "string" ? fnconvertDateObjToAPIdate(val) : val;
                }
                patchJson.push({
                    op: PatchOperation.replace,
                    path: "/" + key,
                    value: val
                });
            }
        });
        return {
            key: keyValue,
            patchJson: patchJson
        };
    }

    ShowErrorMessage(title: string, message: any, popButtonType: ButtonType = ButtonType.Ok, callback?: (result: string, extraParams?: any[]) => void, extraParams?: any[] | any, closebool = true) {
        this.hideOverlays();
        let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            height: 'auto',
            width: '40%',
            data: { headername: title, closebool: closebool, templatename: 'In the Given Data', datarecord: message, buttonType: popButtonType },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback)
                callback(res, extraParams);
        });
        return dialogRef;
    }

    
    convertHoursToMinutes(hours: string): number {
        const meridians = ["am", "AM", "pm", "PM"];
        if (meridians.filter(x => hours.includes(x)).length > 0) {
          let index = -1;
          let i = 0;
          meridians.forEach(c => {
            if (hours.indexOf(c) != -1) {
              index = i;
            }
            i++;
          });
    
          let hourSplit = hours.substring(0, hours.indexOf(" ")).split(':');
          let hour = Number(hourSplit[0]);
          let minute = Number(hourSplit[1]);
          if (index >= 2) {
            hour = hour + 12;
          }
          return hour * 60 + minute;
        } else {
          let hourSplit = hours.split(':');
          return Number(hourSplit[0]) * 60 + Number(hourSplit[1]);
        }
      }
      
    IsTherapistHaveSchedule(dateTime, TherapistObj, gridtimeinterval) {
        if (!dateTime || !TherapistObj) return false;
        let therapistScheduled = false;
        if (TherapistObj && TherapistObj.length) {
            for (let i = 0; i < TherapistObj.length; i++) {
                const therap = TherapistObj[i];
                const timeArr = therap ? therap.availableTime : null;
                if (!timeArr) break;
                for (let k = 0; k < timeArr.length; k++) {
                    const timeList = timeArr[k];
                    if (timeList.fromTime && timeList.toTime && dateTime >= timeList.fromTime && dateTime < timeList.toTime) {
                        therapistScheduled = true;
                    }
                }

                const breakTimeArr = therap ? therap.breakTime : null;
                if (!breakTimeArr) break;
                for (let k = 0; k < breakTimeArr.length; k++) {
                    const breakTimeList = breakTimeArr[k];
                    if (breakTimeList.fromTime && breakTimeList.toTime) {
                        let fromBreakTime = breakTimeList.fromTime;
                        let toBreakTime = breakTimeList.toTime;
                        let nearest15MinutesFromBreakTime = this.AddMinutes(fromBreakTime, -1 * fromBreakTime.getMinutes() % gridtimeinterval);
                        let nearest15MinutesToBreakTime = this.AddMinutes(toBreakTime, toBreakTime.getMinutes() % gridtimeinterval == 0 ? 0 : (gridtimeinterval - toBreakTime.getMinutes() % gridtimeinterval));
                        if (dateTime >= nearest15MinutesFromBreakTime && dateTime < nearest15MinutesToBreakTime) {
                            therapistScheduled = false;
                        }
                    }

                }

            }
        }
        return therapistScheduled;
    }

    IsTherapistHaveOnCallSchedule(dateTime, TherapistObj) {
        if (!dateTime || !TherapistObj) return false;
        let therapistScheduled = false;
        if (TherapistObj && TherapistObj.length) {
            for (let i = 0; i < TherapistObj.length; i++) {
                const therap = TherapistObj[i];
                const timeArr = therap ? therap.availableTime : null;
                if (!timeArr) break;
                for (let k = 0; k < timeArr.length; k++) {
                    const timeList = timeArr[k];
                    if (timeList.fromTime && timeList.toTime && dateTime >= timeList.fromTime && dateTime < timeList.toTime) {
                        if (timeList.availableOnCall)
                            therapistScheduled = true;
                    }
                }
            }
        }
        return therapistScheduled;
    }

    IsTherapistHaveOnCallScheduleCopyAndMove(dateTime, TherapistObj) {
        if (!dateTime || !TherapistObj) return false;
        let therapistScheduled = false;
        let therapistScheduleData = TherapistObj[0].scheduleTime;
        if (therapistScheduleData.length) {
            for (let k = 0; k < therapistScheduleData.length; k++) {
                const timeList = therapistScheduleData[k];
                if (timeList.startTime && timeList.endTime && dateTime >= this.getDate(timeList.startTime) && dateTime < this.getDate(timeList.endTime)) {
                    if (timeList.availableOnCall)
                        therapistScheduled = true;
                }
            }
        }
        return therapistScheduled;
    }

    /**Localization verified - Do not remove this comment  - language param needs to be removed*/
    FormatAppointmentDateTime(startTime: any, endTime: any): string {
        let StartApptTime: Date = this.getDate(startTime);
        let EndApptTime: Date = this.getDate(endTime);
        return this.localization.LocalizeShortDate(StartApptTime) + " " + this.FormatAppointmentTime(StartApptTime, EndApptTime);
    }

    FormatAppointmentTime(startTime: any, endTime: any, isCapital = false): string {
        let StartApptTime: Date = this.getDate(startTime);
        let EndApptTime: Date = this.getDate(endTime);
        return this.localization.LocalizeTime(StartApptTime, isCapital) + " - " + this.localization.LocalizeTime(EndApptTime, isCapital);
    }

    FormatDateTime(startTime: any): string {
        let StartApptTime: Date = this.getDate(startTime);
        return this.localization.LocalizeShortDate(StartApptTime) + " " + this.FormatTime(StartApptTime);
    }

    FormatTime(startTime: any, isCapital = false): string {
        let StartApptTime: Date = this.getDate(startTime);
        return this.localization.LocalizeTime(StartApptTime, isCapital);
    }

    getGuid(callDesc = "GetGuid", successCallback, errorCallback, extraParams) {
        this.httpServiceCall.CallApiWithCallback<any>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc: callDesc,
            method: HttpMethod.Get,
            showError: true,
            extraParams: extraParams
        });
    }

    getclassNames(startTime, endTime, className, parentClass, time, ShowLapoption, dataLength, ObjectIndex) {
        let currentselectedDivParent = document.getElementsByClassName(parentClass)[0];
        let currentselectedDiv = document.getElementsByClassName(className)[0];
        let differenceFA = this.getTimeDifference(this.localization.LocalizeTime(time), this.localization.LocalizeTime(startTime), 'Min');
        let differenceFT = this.getTimeDifference(this.localization.LocalizeTime(startTime), this.localization.LocalizeTime(endTime), 'Min');
        let topPosition = (differenceFA / GlobalConst.GridTimeInterval) * 100;
        let cellheight = (differenceFT / GlobalConst.GridTimeInterval) * 100;

        if (currentselectedDiv) {
            currentselectedDiv["style"].top = topPosition + "%";
            currentselectedDiv["style"].height = cellheight + "%";
        }
        if (currentselectedDiv) {
            currentselectedDivParent["style"].width = "0px";
            currentselectedDiv["style"].top = topPosition + "%";
            currentselectedDiv["style"].height = cellheight + "%";
            if (ShowLapoption) {
                let widthSize = 200;
                if (dataLength > 1) {
                    currentselectedDivParent["style"].width = (widthSize * dataLength) + "px";
                    for (let l = 0; l <= dataLength; l++) {
                        if (l == ObjectIndex) {
                            currentselectedDiv["style"].width = widthSize + "px";
                            currentselectedDiv["style"].left = (widthSize * l) + "px";
                        }
                    }
                }
            }

        }
        return true;
    }
    getclassNames_New(startTime, endTime, className, parentClass, setupClassname, breakdownClassname, time, ShowLapoption, data, i, j, poi, ObjectIndex, currData, timinterval, selecteddateIDwithYr, appointmentConfiguration) {

        let dataLength = data.length;
        let widthSize = 200;
        let timeInterval = timinterval;

        let setupTime = currData.appointmentDetail.setUpTime;
        let breakdownTime = currData.appointmentDetail.breakDownTime;


        let currentselectedSetupTimeDiv = document.getElementsByClassName(setupClassname)[0];
        let currentselectedBreakDownDiv = document.getElementsByClassName(breakdownClassname)[0];
        let currentselectedDivParent = document.getElementsByClassName(parentClass)[0];
        let currentselectedDiv = document.getElementsByClassName(className)[0];
        let differenceFA = this.getTimeDifference(this.localization.LocalizeTime(time), this.localization.LocalizeTime(startTime), 'Min');

        let differenceFT = this.getTimeDifference(this.getDate(currData.appointmentDetail.startTime), this.getDate(currData.appointmentDetail.endTime), 'Min');
        let setuptopPosition = (differenceFA / timeInterval) * 100;
        let topPosition = ((differenceFA) / timeInterval) * 100;
        let cellheight = (differenceFT / timeInterval) * 100;
        let cellSUheight = (setupTime / timeInterval) * 100;
        let cellBDheight = (breakdownTime / timeInterval) * 100;

        let otherOverlapCount = data[0].overlapped;
        let otheOverlapflag = data[0].overlappedFlag;


        if (currentselectedDiv && currentselectedSetupTimeDiv && currentselectedBreakDownDiv) {
            currentselectedDiv["style"].left = "0px";
            currentselectedDiv["style"].width = "100%";
            currentselectedSetupTimeDiv["style"].left = "0px";
            currentselectedSetupTimeDiv["style"].width = "100%";
            currentselectedBreakDownDiv["style"].left = "0px";
            currentselectedBreakDownDiv["style"].width = "100%";

            currentselectedDiv["style"].top = topPosition + "%";
            let PropertyOperateTime = {
                startTime: "00:00",
                endTime: "23:59"
            }
            PropertyOperateTime.startTime = this.getDayTimeForTheDate(JSON.parse(appointmentConfiguration[0]["APPOINTMENT_OPERATING_HOURS"]))['StartTime'];
            PropertyOperateTime.endTime = this.getDayTimeForTheDate(JSON.parse(appointmentConfiguration[0]["APPOINTMENT_OPERATING_HOURS"]))['EndTime'];

            let beginningTime = moment(PropertyOperateTime.endTime, 'h:mm');
            let endTimeData = moment(this.getTime(currData.appointmentDetail.endTime, 24), 'h:mm');
            let brkendTime = moment(this.getTime(currData.appointmentDetail.endTimewithbreakdowntime, 24), 'h:mm');

            if (endTimeData.isAfter(beginningTime) && (cellheight > 99)) {
                let tempdifference = this.getTimeDifference(this.getDate(currData.appointmentDetail.startTime), this.localization.AddTimeToDate(selecteddateIDwithYr, moment(PropertyOperateTime.endTime, "HH:mm").toDate()), 'Min');
                currentselectedDiv["style"].height = (tempdifference / timeInterval) * 100 + "%";
            } else {
                currentselectedDiv["style"].height = cellheight + "%";
            }

            currentselectedSetupTimeDiv["style"].top = setuptopPosition - cellSUheight + "%";
            currentselectedSetupTimeDiv["style"].height = cellSUheight + "%"; 
            if (brkendTime.isAfter(beginningTime) && (cellheight > 99)) {
                currentselectedBreakDownDiv["style"].top = "0%";
                currentselectedBreakDownDiv["style"].height = "0%";
            } else {
                currentselectedBreakDownDiv["style"].top = (setuptopPosition + cellheight) + "%";
                currentselectedBreakDownDiv["style"].height = cellBDheight + "%";
            }

            currentselectedDivParent["style"].width = "0px";
            currentselectedDiv["style"].top = topPosition + "%";

            if (ShowLapoption) {

                if (dataLength > 1 || otherOverlapCount > 0 || otheOverlapflag) {
                    currentselectedDiv["style"].width = widthSize + "px";
                    currentselectedDiv["style"].left = (widthSize * (ObjectIndex + otherOverlapCount)) + "px";
                    currentselectedSetupTimeDiv["style"].width = widthSize + "px";
                    currentselectedSetupTimeDiv["style"].left = (widthSize * (ObjectIndex + otherOverlapCount)) + "px";
                    currentselectedBreakDownDiv["style"].width = widthSize + "px";
                    currentselectedBreakDownDiv["style"].left = (widthSize * (ObjectIndex + otherOverlapCount)) + "px";
                    let newDlen = dataLength + otherOverlapCount;
                    currentselectedDivParent["style"].minWidth = (widthSize * newDlen) + "px";
                    currentselectedDivParent["style"].width = (widthSize * newDlen) + "px";
                }
            }

            currData.setcss = false;
        }
        return true;
    }

    public changeExceptionalApptTime(data, selecteddateIDwithYr, appointmentConfiguration) {

        let PropertyOperateTime = {
            startTime: "00:00",
            endTime: "23:59"
        }
        PropertyOperateTime.startTime = this.getDayTimeForTheDate(JSON.parse(appointmentConfiguration[0]["APPOINTMENT_OPERATING_HOURS"]))['StartTime'];
        PropertyOperateTime.endTime = this.getDayTimeForTheDate(JSON.parse(appointmentConfiguration[0]["APPOINTMENT_OPERATING_HOURS"]))['EndTime'];

        let GridFromTime = this.localization.AddTimeToDate(selecteddateIDwithYr, moment(PropertyOperateTime.startTime, "HH:mm").toDate());
        let GridToTime = this.localization.AddTimeToDate(selecteddateIDwithYr, moment(PropertyOperateTime.endTime, "HH:mm").toDate());

        if (data.appointmentDetail.status == "BREAK" && data.appointmentDetail.locationId > 0) {
            let StartApptTime: Date = this.getDate(data.appointmentDetail.startTime);
            let EndApptTime: Date = this.getDate(data.appointmentDetail.endTime);
            if (moment(StartApptTime).isSameOrBefore(GridFromTime) && moment(EndApptTime).isSameOrAfter(GridToTime)) {
                data.appointmentDetail.startTime = this.localization.ConvertDateToISODateTime(GridFromTime);
                data.appointmentDetail.endTime = this.localization.ConvertDateToISODateTime(GridToTime);
            }
            else if (moment(StartApptTime).isSameOrBefore(GridFromTime) && moment(EndApptTime).isSameOrBefore(GridToTime)) {
                data.appointmentDetail.startTime = this.localization.ConvertDateToISODateTime(GridFromTime);
            }
            else if (moment(StartApptTime).isSameOrAfter(GridFromTime) && moment(EndApptTime).isSameOrAfter(GridToTime)) {
                data.appointmentDetail.endTime = this.localization.ConvertDateToISODateTime(GridToTime);
            }
        }
    }

    GetAppointmentColors(status: string): AppointmentColors {
        let AppointmentColors: AppointmentColors = {
            BackGround: "",
            BackGroundLight: "",
            Border: "",
            Color: "",
            ColorLight: ""
        };
        if (status == "CKIN") {
            AppointmentColors.BackGround = "checkinBGclr";
            AppointmentColors.BackGroundLight = "checkinBGclrLight";
            AppointmentColors.Color = "checkedinTextclr";
            AppointmentColors.ColorLight = "checkedinTextclrLight";
            AppointmentColors.Border = "checkinBDRclr";
        }
        else if (status == "CKOUT") {
            AppointmentColors.BackGround = "checkoutBGclr";
            AppointmentColors.BackGroundLight = "checkoutBGclrLight";
            AppointmentColors.Color = "checkedoutTextclr";
            AppointmentColors.ColorLight = "checkedoutTextclrLight";
            AppointmentColors.Border = "checkoutBDRclr";
        }
        else if (status == "CLOSED") {
            AppointmentColors.BackGround = "closeBGclr";
            AppointmentColors.BackGroundLight = "closeBGclrLight";
            AppointmentColors.Color = "closeTXTclrLight";
            AppointmentColors.ColorLight = "closeTXTclrLight";
            AppointmentColors.Border = "closeBDRclr";
        }
        else if (status == "NOSHOW") {
            AppointmentColors.BackGround = "nsBGclr";
            AppointmentColors.BackGroundLight = "nsBGclrLight";
            AppointmentColors.Color = "noshowTextclr";
            AppointmentColors.ColorLight = "noshowTextclrLight";
            AppointmentColors.Border = "nsBDRclr";

        }
        else if (status == "RESV") {
            AppointmentColors.BackGround = "sBGclr";
            AppointmentColors.BackGroundLight = "sBGclrLight";
            AppointmentColors.Color = "scheduledTextclr";
            AppointmentColors.ColorLight = "scheduledTextclrLight";
            AppointmentColors.Border = "sBDRclr";
        }
        else if (status == "TEMP") {
            AppointmentColors.BackGround = "closeBGclr";
            AppointmentColors.BackGroundLight = "closeBGclrLight";
            AppointmentColors.Color = "closeTXTclrLight";
            AppointmentColors.ColorLight = "closeTXTclrLight";
            AppointmentColors.Border = "closeBDRclr";
        }
        else if (status == "BREAK") {
            AppointmentColors.BackGround = "closeBGclr";
            AppointmentColors.BackGroundLight = "closeBGclrLight";
            AppointmentColors.Color = "closeTXTclrLight";
            AppointmentColors.ColorLight = "closeTXTclrLight";
            AppointmentColors.Border = "closeBDRclr";
        }
        return AppointmentColors;
    }
    /**
    *
    * @param Properties This method creates a css class with given styles and returns the class name
    * @param uniqueString unique name to append in class name
    * @param Type belong to
    */
    CreateCssClass(Properties: CssProp[], uniqueString: string, Type: string, deleteClass: boolean = false): string {
        if (!Properties || Properties.length == 0) return "";

        let className = `css-class-${Type}-${uniqueString.replace('#', '-')}`;

        if (document.getElementsByClassName(className).length > 0 && !deleteClass) {
            return className;
        }
        let styleDivElem = document.getElementsByClassName("Style-Div");
        if (!styleDivElem || styleDivElem.length == 0) {
            var styleDiv = document.createElement("div");
            styleDiv.classList.add("Style-Div");
            document.body.appendChild(styleDiv);
            styleDivElem = document.getElementsByClassName("Style-Div");
        }

        var style = document.createElement('style');
        style.classList.add(className);
        style.type = 'text/css';
        var styleStr = `.${className} { `;

        for (let item of Properties) {
            styleStr += ` ${item.property}:${item.value}; `
        }

        styleStr += ` }`

        // style.innerHTML = styleStr;
        styleDivElem[0].appendChild(style);
        this.localization.decodeHTMLEntityByTagName(styleStr,style);
        return className;
    }

    RgbaToRgb(RGBA) {
        let alpha = 1 - RGBA.alpha;
        let bg = { red: 255, green: 255, blue: 255 };
        let RGB = { red: 0, green: 0, blue: 0 };
        RGB.red = Math.round((RGBA.alpha * (RGBA.red / 255) + (alpha * (bg.red / 255))) * 255);
        RGB.green = Math.round((RGBA.alpha * (RGBA.green / 255) + (alpha * (bg.green / 255))) * 255);
        RGB.blue = Math.round((RGBA.alpha * (RGBA.blue / 255) + (alpha * (bg.blue / 255))) * 255);
        return `rgb(${RGB.red},${RGB.green},${RGB.blue})`;
    }

    hexToRgbA(hex: string, resetOpacity?: number): string {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            resetOpacity = resetOpacity ? resetOpacity : 1;
            return this.RgbaToRgb({ red: (c >> 16) & 255, green: (c >> 8) & 255, blue: c & 255, alpha: resetOpacity });
        }
        return '';
    }

    public appendLocalizedFormat(value: string | number, format: string) {
        if (!value && value == "") {
            return "";
        }
        let userInput = value.toString();
        let returnVal: string = "";
        let indexOfUserValue: number = 0;
        if (format == "") {
            return value;
        }
        userInput = (userInput.length > 1 && userInput[0] === '0' && format[1] === '0' ? userInput.replace(/[0]/, '') : userInput);
        for (let i = 0; i < format.length; i++) {
            const char = format[i];
            let charCode = char.toString().charCodeAt(0);
            const IsFormatNumber: boolean = (charCode === 57);
            if (!IsFormatNumber) {
                returnVal = returnVal + format[i];
            } else {
                if (userInput[indexOfUserValue]) {
                    returnVal = returnVal + userInput[indexOfUserValue];
                    indexOfUserValue++;
                } else {
                    break;
                }
            }
        }
        return returnVal;
    }

    getConfiguration(successCallback, errorCallBack, moduleName: string, switchName: string) {
        this.httpServiceCall.CallApiWithCallback<any>({
            host: GlobalConst.Host.spaManagement,
            success: successCallback,
            error: errorCallBack,
            callDesc: "GetConfiguration",
            method: HttpMethod.Get,
            uriParams: { module: moduleName, Switch: switchName },
            showError: true,
            extraParams: []
        });
    }

    GetAllSettingByModule(successCallback, errorCallBack, moduleName: string) {
        this.httpServiceCall.CallApiWithCallback<any>({
            host: GlobalConst.Host.spaManagement,
            success: successCallback,
            error: errorCallBack,
            callDesc: "GetAllSettingByModule",
            method: HttpMethod.Get,
            uriParams: { module: moduleName },
            showError: true,
            extraParams: []
        });
    }



    getstatusColour(_appointmentService: appointmentService, appointmentDetail: any, breakTypeDetail: any = null): string {

        status = appointmentDetail.status;
        if (status == "BREAK" && appointmentDetail.locationId !== 0) {
            status = 'CLOSED';
        }
        if (status == "BREAK" && breakTypeDetail != null) {
            var colorBreak = _appointmentService.managementData["BreakType"].filter(x => { return x.id == breakTypeDetail.id });
            if (colorBreak && colorBreak.length > 0 && colorBreak[0].color != null) {
                let props: CssProp[] = [];
                let bgcolorRgba = this.hexToRgbA(colorBreak[0].color, 0.1);
                let bordercolorRgba = this.hexToRgbA(colorBreak[0].color, 1);
                props = [{
                    property: "background",
                    value: bgcolorRgba + " !important"
                },
                {
                    property: "border",
                    value: " 2px solid " + bordercolorRgba + " !important"
                }];

                return this.CreateCssClass(props, colorBreak[0].color, 'Status');
            }
        }
        else if (appointmentDetail.linkCodeId > 0 && _appointmentService.managementData) {
            var colorLink = _appointmentService.managementData["LinkCode"].filter(x => { return x.id == appointmentDetail.linkCodeId });
            if (colorLink && colorLink.length > 0) {
                let props: CssProp[] = [];
                let bgcolorRgba = this.hexToRgbA(colorLink[0].color, 0.1);
                let bordercolorRgba = this.hexToRgbA(colorLink[0].color, 1);
                props = [{
                    property: "background",
                    value: bgcolorRgba + " !important"
                },
                {
                    property: "border",
                    value: " 2px solid " + bordercolorRgba + " !important"
                }];
                return this.CreateCssClass(props, colorLink[0].color, 'Status');
            }
        }


        if (appointmentDetail.linkCodeId == 0 && _appointmentService.managementData && status == 'RESV') {
            var colorResv = _appointmentService.managementData["Service"]?.filter(x => { return x.id == appointmentDetail.serviceId });
            if (colorResv && colorResv.length > 0 && colorResv[0].colorCode != null && colorResv[0].colorCode != '') {
                let props: CssProp[] = [];
                let bgcolorRgba = this.hexToRgbA(colorResv[0].colorCode, 0.1);
                let bordercolorRgba = this.hexToRgbA(colorResv[0].colorCode, 1);
                props = [{
                    property: "background",
                    value: bgcolorRgba + " !important"
                },
                {
                    property: "border",
                    value: " 2px solid " + bordercolorRgba + " !important"
                }];
                return this.CreateCssClass(props, colorResv[0].colorCode, 'Status');
            }
        }
        let config = _appointmentService.managementData["AppointmentConfigurations"]
        if (config && Object.keys(config).length > 0) {
            let colorConfig = config[AppointmentColorConfiguration[status]];
            let props: CssProp[] = [];
            let bgcolorRgba = this.hexToRgbA(colorConfig, 0.1);
            let bordercolorRgba = this.hexToRgbA(colorConfig, 1);
            props = [{
                property: "background",
                value: bgcolorRgba + " !important"
            },
            {
                property: "border",
                value: " 2px solid " + bordercolorRgba + " !important"
            }];
            return this.CreateCssClass(props, colorConfig, 'Status');

        }

        let colornameBDR;
        let colornameBACKGRNDLight;
        if (status == "CKIN") {
            colornameBDR = "checkinBDRclr";
            colornameBACKGRNDLight = "checkinBGclrLight";
        }
        else if (status == "CKOUT") {
            colornameBDR = "checkoutBDRclr";
            colornameBACKGRNDLight = "checkoutBGclrLight";
        }
        else if (status == "CLOSED") {
            colornameBDR = "closeBDRclr";
            colornameBACKGRNDLight = "closeBGclrLight";
        }
        else if (status == "NOSHOW") {
            colornameBDR = "nsBDRclr";
            colornameBACKGRNDLight = "nsBGclrLight";
        }
        else if (status == "RESV") {
            colornameBDR = "sBDRclr";
            colornameBACKGRNDLight = "sBGclrLight";
        }
        else if (status == "TEMP") {
            colornameBDR = "sBDRclr";
            colornameBACKGRNDLight = "sBGclrLight";
        }
        else if (status == "BREAK") {
            colornameBDR = "closeBDRclr";
            colornameBACKGRNDLight = "closeBGclrLight";
        }
        else if (status == "ONCALL") {
            colornameBDR = "closeBDRclr";
            colornameBACKGRNDLight = "closeBGclrLight";
        }
        return colornameBDR + ' ' + colornameBACKGRNDLight;
    }
    getLegendColor(_appointmentService: appointmentService, status: string): string {

        
        let config = _appointmentService.managementData["AppointmentConfigurations"]

        if (config && Object.keys(config).length > 0) {
            let color = config[AppointmentColorConfiguration[status]];

            let props: CssProp[] = [];

            let bgcolorRgba = this.hexToRgbA(color, 1);
            props = [{
                property: "background",
                value: bgcolorRgba + " !important"
            }];
            return this.CreateCssClass(props, color, 'Legend');

        }
    }

    getLegendColorCode(_appointmentService: appointmentService, status: string): string {
        let config = _appointmentService.managementData["AppointmentConfigurations"]
        if (config && Object.keys(config).length > 0) {
            let color = config[AppointmentColorConfiguration[status]];
            return this.hexToRgbA(color, 1);
        }
    }

    getBackgroundColor(_appointmentService: appointmentService, appointmentDetail: any, breakTypeDetail: any = null): string {
        let status = appointmentDetail.status;
        let config = _appointmentService.managementData["AppointmentConfigurations"]

        if (status == "BREAK" && appointmentDetail.locationId !== 0) {
            let color = _appointmentService.managementData["BreakType"].filter(x => { return x.id == breakTypeDetail.id });
            if (color && color.length > 0 && color[0].color != null) {


                let props: CssProp[] = [];

                let bgcolorRgba = this.hexToRgbA(color[0].color, 0.1);
                props = [{
                    property: "background",
                    value: bgcolorRgba + " !important"
                }];

                return this.CreateCssClass(props, color[0].color, 'Background');
            }
        }
        else if (status == "BREAK" && breakTypeDetail != null) {
            let color = _appointmentService.managementData["BreakType"].filter(x => { return x.id == breakTypeDetail.id });
            if (color && color.length > 0 && color[0].color != null) {


                let props: CssProp[] = [];

                let bgcolorRgba = this.hexToRgbA(color[0].color, 0.1);
                props = [{
                    property: "background",
                    value: bgcolorRgba + " !important"
                }];

                return this.CreateCssClass(props, color[0].color, 'Background');
            }
        }

        if (config && Object.keys(config).length > 0) {
            let color = config[AppointmentColorConfiguration[status]];

            let props: CssProp[] = [];

            let bgcolorRgba = this.hexToRgbA(color, 1);
            props = [{
                property: "background",
                value: bgcolorRgba + " !important"
            }];
            return this.CreateCssClass(props, color, 'Background');

        }
    }

    getTextColor(_appointmentService: appointmentService, status: string): string {

        let config = _appointmentService.managementData["AppointmentConfigurations"]

        if (config && Object.keys(config).length > 0) {
            let color = config[AppointmentColorConfiguration[status]];
            return this.hexToRgbA(color, 1);

            // let props: CssProp[] = [];

            // let bgcolorRgba = this.hexToRgbA(color, 1);
            // props = [{
            //     property: "color",
            //     value: bgcolorRgba + " !important"
            // }];
            // return this.CreateCssClass(props, color, 'Text');

        }
    }

    getBorderColor(status: string): string {

        let config = this.PropertyInfo.AppointmentConfigurations();

        if (config && Object.keys(config).length > 0) {

            let color = config[AppointmentColorConfiguration[status]];

            let props: CssProp[] = [];

            let bgcolorRgba = this.hexToRgbA(color, 1);
            props = [{
                property: "border",
                value: "2px solid " + bgcolorRgba + " !important"
            }];
            return this.CreateCssClass(props, color, 'Border');

        }
    }

    getOnlyBackgoundColor(status: string): string {

        let config = this.PropertyInfo.AppointmentConfigurations();

        if (config && Object.keys(config).length > 0) {

            let color = config[AppointmentColorConfiguration[status]];

            let props: CssProp[] = [];

            let bgcolorRgba = this.hexToRgbA(color, 1);
            props = [{
                property: "background",
                value: bgcolorRgba + " !important"
            }];
            return this.CreateCssClass(props, color, 'Background');

        }
    }

    getOnlyLightBackgoundColor(status: string): string {

        let config = this.PropertyInfo.AppointmentConfigurations();

        if (config && Object.keys(config).length > 0) {

            let color = config[AppointmentColorConfiguration[status]];

            let props: CssProp[] = [];

            let bgcolorRgba = this.hexToRgbA(color, 0.1);
            props = [{
                property: "background",
                value: bgcolorRgba + " !important"
            }];
            return this.CreateCssClass(props, color, 'LightBackground');

        }
    }

    getBorderColorClass(color: string): string {
        let props: CssProp[] = [];
        let bgcolorRgba = this.hexToRgbA(color, 1);
        props = [{
            property: "border",
            value: "2px solid " + bgcolorRgba + " !important"
        }];
        return this.CreateCssClass(props, color, 'Border');

    }

    getOnlyBackgoundColorClass(color: string): string {
        let props: CssProp[] = [];
        let bgcolorRgba = this.hexToRgbA(color, 1);
        props = [{
            property: "background",
            value: bgcolorRgba + " !important"
        }];
        return this.CreateCssClass(props, color, 'Background');
    }

    getOnlyLightBackgoundColorClass(color: string): string {
        let props: CssProp[] = [];
        let bgcolorRgba = this.hexToRgbA(color, 0.1);
        props = [{
            property: "background",
            value: bgcolorRgba + " !important"
        }];
        return this.CreateCssClass(props, color, 'LightBackground');
    }

    setFilterPopUp(ShowMoreFilters: boolean, moreData: any, selectedMoreData: any, topPos: number, leftPos: number, belongto: string, fromPage: string) {
        this._MoreSectionServiceService.filterOptions = {
            'ShowMoreFilters': ShowMoreFilters,
            'moreData': moreData,
            'selectedMoreData': selectedMoreData,
            'top': topPos,
            'left': leftPos,
            'belongto': belongto,
            'fromPage': fromPage
        }
    }

    getFilterPopUpOption(ShowMoreFilters: boolean, moreData: any, selectedMoreData: any, topPos: number, leftPos: number, belongto: string, fromPage: string): MoreFilterOptions {
        return {
            'ShowMoreFilters': ShowMoreFilters,
            'moreData': moreData,
            'selectedMoreData': selectedMoreData,
            'top': topPos,
            'left': leftPos,
            'belongto': belongto,
            'fromPage': fromPage
        }
    }

    getPMSSessionData(): PMSSessionData {
        let pmsData: PMSSessionData;
        var session = sessionStorage.getItem(GlobalConst.PMSDATA);
        if (session) {
            pmsData = JSON.parse(session);
        }
        return pmsData;
    }

    roomOpenDialog(popupData: any, callback?: (x: any) => void): MatDialogRef<any, any> {
        const data = {
            headername: popupData.headertext,
            headerIcon: popupData.icon,
            headerMessage: popupData.text,
            buttonName: popupData.buttonname,
            type: 'message',
            isloaderenable: popupData.isloaderenable,
            isHiddenFieldRequired: popupData.isHiddenFieldRequired,
            cardpayment: popupData.cardpayment,
            isManualEntry: popupData.isManualEntry
        }
        const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
            width: '350px',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data: data,
            disableClose: true
        });
        const subscription = dialogRef.afterClosed().subscribe((x) => {
            if (callback) {
                callback(x);
            }
            if (subscription) {
                subscription.unsubscribe();
            }
        });
        return dialogRef;
    }

    splitPhoneNo(phNo) {
        let phoneNumber = phNo;
        if (phNo.indexOf('|') != -1) {
            let splitNo = phNo.split('|');
            phoneNumber = splitNo[1];
        }
        return phoneNumber;
    }
    splitCountryCode(phNo) {
        let phoneNumber: string = '';
        if (phNo.indexOf('|') != -1) {
            let splitNo = phNo.split('|');
            phoneNumber = splitNo[0] ? splitNo[0] + ' - ' : '';
        }
        return phoneNumber;
    }

    GetPercentageId(percent: number): number {
        let percentageId = 0;
        if (percent == 10) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.TenPercent;
        }
        else if (percent == 15) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.FifteenPercent;
        }
        else if (percent == 20) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.TwentyPercent;
        }
        else if (percent == 25) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.TwentyFivePercent;
        }
        return percentageId;
    }

    RoundOff2DecimalPlaces(value: number): number {
        return Number(value.customToFixed());
    }

    // Can be used for percentage
    // percentageRoundOff2DecimalPlaces(value: number): number {
    //     return Math.round(value * 100) / 100;
    // }

    GetGuestIdbyTitle(title: string): number {
        let id = 0;
        if (!title) {
            return id;
        }
        title = title.replace('.', '').toLowerCase();
        switch (title) {
            case 'dr': id = 1; break;
            case 'fr': id = 2; break;
            case 'miss': id = 3; break;
            case 'mr': id = 4; break;
            case 'mrs': id = 5; break;
            case 'ms': id = 6; break;
            case 'prof': id = 7; break;
            case 'rev': id = 8; break;
        }
        return id;
    }

    GetSPAGuestTitlebyId(id: number): string {
        let title = '';
        switch (id) {
            case 1: title = 'Dr.'; break;
            case 2: title = 'Fr.'; break;
            case 3: title = 'Miss'; break;
            case 4: title = 'Mr.'; break;
            case 5: title = 'Mrs.'; break;
            case 6: title = 'Ms.'; break;
            case 7: title = 'Prof.'; break;
            case 8: title = 'Rev.'; break;
        }
        return title;
    }

    GetNetAmountFromGross(grossPrice: number, vatPercentage): number {
        return Number((grossPrice / 1 + (vatPercentage / 100)).customToFixed());
    }

    GetVATAmountFromGross(grossPrice: number, vatPercentage): number {
        return Number((grossPrice - this.GetNetAmountFromGross(grossPrice, vatPercentage)).customToFixed());
    }

    GetPercentageFromPercentageAmount(actualPrice: number, percentagePrice: number): number {
        return Number((percentagePrice / actualPrice * 100).customToFixed());
    }

    getSessionValue<T>(key: string): T {
        let sessionData: T;
        let session = sessionStorage.getItem(key);
        if (session) {
            sessionData = JSON.parse(session);
        }
        return sessionData;
    }

    generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = this.getRandomDecimal() * 16 | 0;
            let v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    getPhoneNumberFromValue(phoneType: number, phoneNumber: string): string {
        if (phoneNumber) {
            let result: any = '';
            if (phoneNumber.indexOf('|') == -1) {
                result = ((phoneType === contactType.office && phoneNumber.split(':')[1]) ? this.appendLocalizedFormat(phoneNumber.split(':')[1], this.localization.captions.common.PhoneFormat)
                    : this.appendLocalizedFormat(phoneNumber, this.localization.captions.common.PhoneFormat));
            } else {
                result = ((phoneType === contactType.office && phoneNumber.split(':')[1]) ? this.appendLocalizedFormat(phoneNumber.split(':')[1].split('|')[1], this.localization.captions.common.PhoneFormat)
                    : this.appendLocalizedFormat(phoneNumber.split('|')[1], this.localization.captions.common.PhoneFormat));
            }
            return result;
        }
        return ''
    }

    getCountryCodeFromValue(phoneType: number, phoneNumber: string): string {
        if (phoneNumber) {
            let result: any = '';
            if (phoneNumber.indexOf('|') == -1) {
                result = "00";
            } else {
                result = ((phoneType === contactType.office && phoneNumber.split(':')[1]) ? this.appendLocalizedFormat(phoneNumber.split(':')[1].split('|')[0], '')
                    : this.appendLocalizedFormat(phoneNumber.split('|')[0], ''));
            }
            return result;
        }
        return "";
    }


    GetUniqueId(len = 32) {
        var id = [],
            chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
            charlen = chars.length;
        for (var i = 0; i < len; i++) {
            id[i] = chars.charAt(Math.floor(this.getRandomDecimal() * charlen));
        }
        return id.join('');
    }

    getTextWidth(text, font) {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        context.font = font;
        var metrics = context.measureText(text);
        if (canvas) {
            canvas.remove();
        }
        return Math.ceil(metrics.width);
    }

    camelizeKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map(v => this.camelizeKeys(v));
        } else if (obj !== null && obj !== undefined && obj.constructor === Object) {
            return Object.keys(obj).reduce(
                (result, key) => ({
                    ...result,
                    [camelCase(key)]: this.camelizeKeys(obj[key]),
                }),
                {},
            );
        }
        return obj;
    };

    GetSplitup(actualValue: number, numberOfItems: number): SplitValue {
        const RoundOffTwo = (decimalNumber) => { return Number(decimalNumber.customToFixed()) };
        let result: SplitValue = { equalSplit: 0, upScaleValue: 0 };
        if (actualValue > 0 && numberOfItems > 0) {
            result.equalSplit = RoundOffTwo((actualValue / numberOfItems));
            if (RoundOffTwo((result.equalSplit * numberOfItems)) < actualValue) {
                result.upScaleValue = RoundOffTwo((result.equalSplit + RoundOffTwo(actualValue - RoundOffTwo((result.equalSplit * numberOfItems)))));
            }
            else {
                result.upScaleValue = result.equalSplit;
            }
        }
        return result;
    }

    GetServiceBasePrice(serviceDetail: any, serviceSeasonalDates: SpaServiceSesonalDate[], requestDate: Date): number {
        let finalPrice = serviceDetail.price;
        var bookingDate = this.GetDateWithoutTime(requestDate);
        let filteredSeasonalDate: SpaServiceSesonalDate;
        if (serviceDetail != null && serviceSeasonalDates != null && serviceSeasonalDates.length > 0) {
            filteredSeasonalDate = serviceSeasonalDates.find(r => this.GetDateWithoutTime(this.getDate(r.fromDate)).getTime() <= bookingDate.getTime() &&
                this.GetDateWithoutTime(this.getDate(r.toDate)).getTime() >= bookingDate.getTime())
        }
        if (filteredSeasonalDate) {
            let dayIndex = bookingDate.getDay();
            let seasonalPrice = null;
            switch (dayIndex) {
                case 0: seasonalPrice = filteredSeasonalDate.priceSun;
                    break;
                case 1: seasonalPrice = filteredSeasonalDate.priceMon;
                    break;
                case 2: seasonalPrice = filteredSeasonalDate.priceTue;
                    break;
                case 3: seasonalPrice = filteredSeasonalDate.priceWed;
                    break;
                case 4: seasonalPrice = filteredSeasonalDate.priceThu;
                    break;
                case 5: seasonalPrice = filteredSeasonalDate.priceFri;
                    break;
                case 6: seasonalPrice = filteredSeasonalDate.priceSat;
                    break;
            }
            if (seasonalPrice !== null && seasonalPrice !== undefined) {
                finalPrice = seasonalPrice;
            }
        }
        return finalPrice;
    }


    /**
     * Shows Error Popup 
     * @param {string} message content 
     * @memberof Utilities
     */
    public showError(message: string): void {
        this.showAlert(message, AlertType.Error, ButtonType.Ok);
    }

    /**
   * Alert popup to show 'Warning' , 'Error' , 'Success'
   * @param {string} message  - Content
   * @param {AlertType} type  - Type of Popup  
   * @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
   * @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional ) 
   * @returns - Dialog Reference of the modal with Result of enum AlertAction
   * @memberof Utilities
   */
    public showAlert(message: string, type: AlertType, btnType: ButtonType = ButtonType.Ok,
        callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string,
        additionalInfo?: { message: string, class: string }) {

        const dialogRef = this.dialog.open(AlertPopupComponent, {
            height: 'auto',
            width: '300px',
            data: { type: type, message: message, buttontype: btnType, header: headerText, additionalInfo },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback) {
                callback(res, extraParams);
            }
        });
        return dialogRef;
    }

    getstatusDisplayName(status: any | string): any {
        switch (status) {
            case 'CKIN':
                return this.localization.captions.appointmentSearch.CheckedIn;
            case 'CKOUT':
                return this.localization.captions.appointmentSearch.CheckedOut;
            case 'RESV':
                return this.localization.captions.appointmentSearch.Scheduled;
            case 'CANC':
                return this.localization.captions.appointmentSearch.Cancelled;
            case 'NOSHOW':
                return this.localization.captions.appointmentSearch.NoShow;
            default:
                return status;
        }
    }

    // Validates only date without time - Returns true if date is greater that other date
    ValidateDatesGreaterThanOrEqual(date1: Date | string, date2: Date | string) {
        return this.GetDateWithoutTime(this.localization.getDate(date1)).valueOf() >= this.GetDateWithoutTime(this.localization.getDate(date2)).valueOf();
    }

    async geCountriesJSON() {
        try {
            let countryDetails = this.httpClient.get(`assets/i18n/Countries/${this.localization.localeCode}.Countries.json`);
            let response = await countryDetails.toPromise();
            this.countryDetails = response["Countries"];
        }
        catch (e) {
            let countryDetails = this.httpClient.get(`assets/i18n/Countries/en-US.Countries.json`);
            let response = await countryDetails.toPromise();
            this.countryDetails = response["Countries"];
        }
        return Promise.resolve(this.countryDetails);
    }

    FilterCountryValueFromData(country) {
        let countryValue = '';
        if (country && this.FilterCountryByName(country, this.countryDetails).length) {
            countryValue = country;
        } else {
            countryValue = '';
        }
        return countryValue;
    }

    generateUUIDUsingMathRandom() {         
        var d = new Date().getTime();//Timestamp
        var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    getDayTimeForTheDate(allDayTime: Object, propertyDate?: Date): Object {
        if(propertyDate == undefined){
            let highestTime: Object = {};
            highestTime['StartTime'] = Object.entries(allDayTime).sort((a,b)=> { return a[1]['StartTime']<b[1]['StartTime']? -1 : a[1]['StartTime']>b[1]['StartTime'] ? 1: 0})[0][1]['StartTime'];
            highestTime['EndTime'] = Object.entries(allDayTime).sort((a,b)=> {return a[1]['EndTime']>b[1]['EndTime']?-1 : a[1]['EndTime']>b[1]['EndTime'] ? 1 : 0})[0][1]['EndTime'];
            return highestTime;
        }
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const day = propertyDate.getDay();
        return allDayTime[dayNames[day]];
    }

    getLastVisitedApptSlot() {
        let detail = sessionStorage.getItem(LAST_VISITED_APPT_SLOT);
        if (detail) {
            return detail;
        }
        return null;
    }

    setLastVisitedApptSlot(detail) {
        sessionStorage.setItem(LAST_VISITED_APPT_SLOT, detail);
    }
    async GetOutletsByPropertyId(): Promise<SubPropertyModel[]> {
        let result = await this.httpServiceCall.CallApiAsync<SubPropertyModel[]>({
          host: GlobalConst.Host.retailManagement,
          callDesc: "GetOutletsByPropertyAndProduct",
          method: HttpMethod.Get,
          uriParams: { propertyId: Number(this.localization.GetPropertyInfo('PropertyId')), productId: Number(this.localization.GetPropertyInfo('ProductId')) }
        });
        let outlets: SubPropertyModel[] = result.result ? result.result : [];
        return outlets;
      }
}

