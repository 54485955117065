export enum menuTypes {
  initial = 'Initial',
  horizontal = 'Horizontal',
  combo = 'Combo',
  vertical = 'Vertical' 
}

export enum jasperConfig {
  PROPJSCONFIG_JASPERREPORTSAPPLIANCEURL = "JasperReportsApplianceUrl"
}
