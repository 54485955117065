import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ConfirmPopModel, ValidationPopupModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-master-validation-popup',
  templateUrl: './agilysys-master-validation-popup.component.html',
  styleUrls: ['./agilysys-master-validation-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysMasterValidationPopupComponent {
  agilysysCofirmationButton: AgilysysButtonModel[];
  constructor(public dialogRef: MatDialogRef<AgilysysMasterValidationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ValidationPopupModel, private translateService: TranslateService) {
      this.agilysysCofirmationButton = [
        {
          displayProperity: false,
          displayName: this.translateService.instant('Common.Ok'),
          buttonKey: 'Confirm',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
              this.dialogRef.close(callBackData.buttonValue.buttonKey);
          }
        }
      ];
     }

}
