import { Component, OnInit } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef } from '@angular/material/dialog';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { UseInventoryPopupBusiness } from './use-inventory-popup.business';
import { InventoryPopup } from './use-inventory-popup.model';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { CancelInventorySync } from '../../retail.modals';
import { AlertType } from '../../shared/shared.modal';
import { ButtonType } from '../../shared/globalsContant';
@Component({
  selector: 'app-use-inventory-popup',
  templateUrl: './use-inventory-popup.component.html',
  styleUrls: ['./use-inventory-popup.component.scss'],
  providers: [UseInventoryPopupBusiness]
})
export class UseInventoryPopupComponent implements OnInit {

  public captions: any = this.localization.captions.shop;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent: InventoryPopup[];
  IsEditModeEnabled:boolean = true
  data : any;
  utilsCaption: any =this.localization.captions.utilities;

  constructor(public localization: RetailLocalization,
              private dialogRef:MatDialogRef<UseInventoryPopupComponent>,
              private business: UseInventoryPopupBusiness,public utils: RetailUtilities ) { }

  ngOnInit(): void {
    this.utils.ToggleLoader(true);
    this.initialize();
    this.utils.ToggleLoader(false);
  }

  async initialize() {
    this.headerOptions = this.business.getHeaderOptions();
    this.tableOptions = this.business.getTableOptions();
    this.tableContent = await this.business.getTableContent();
  }

  close() {
    this.dialogRef.close()
  }
  async onRefresh(e){
    this.utils.ToggleLoader(true);
    this.tableContent = await this.business.getTableContent();
    this.utils.ToggleLoader(false);
  }

  onTableAction(eve) {

  }

  public get IsSyncNotProcessed() : boolean {
		return (this.business?.InventorySyncDetailInfo && (this.business.InventorySyncDetailInfo?.status == InventorySyncStatus.NotProcessed
      || this.business.InventorySyncDetailInfo?.status == InventorySyncStatus.Initiated));;
	}

  cancelSync(eve){
    this.utils.ToggleLoader(true);
    const model: CancelInventorySync = {
      isSummaryCancelEvent : false,
      summaryId :0,
      detailId : eve.id
    }
   this.business.cancelSync(model).then(x =>{ 
    this.utils.ToggleLoader(false);
    if(!x) {
      this.utils.showAlert(this.utilsCaption.syncWarning,  AlertType.Warning, ButtonType.Ok,(res) => {
        this.onRefresh(eve);
        return;
      });
    }
    else {
      this.onRefresh(eve);
    }
   });
  }

 cancelSyncSummary(eve){
   this.utils.ToggleLoader(true);
    const model: CancelInventorySync = {
      isSummaryCancelEvent : true,
      summaryId : this.business.InventorySyncDetailInfo.id,
      detailId : 0
    }
   this.business.cancelSync(model).then(x =>{
    this.utils.ToggleLoader(false);
    if(!x) {
      this.utils.showAlert(this.utilsCaption.syncWarning,  AlertType.Warning, ButtonType.Ok,(res) => {
        this.onRefresh(eve);
        return;
      });
    }
    else {
      this.onRefresh(eve);
    }
      
   });

  }

}


export enum InventorySyncStatus
{
  NotProcessed = 'NotProcessed',
  Processed = 'Processed',
  Failed = 'Failed',
  Cancelled ='Cancelled',
  Initiated ='Initiated'
}
