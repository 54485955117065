import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { MatStepper } from '@angular/material/stepper';
import { API, CGcombinememInput } from '../combine-guest-ui-model';
import { combineGuestRecordBusiness } from '../combine-guest-records.business';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { AgCombineGuestRecordsComponent } from '../combine-guest-records.component';
import { MatDialog } from '@angular/material/dialog';
import { Localization } from 'src/app/common/shared/localization/Localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';


@Component({
  selector: 'ag-combine-member',
  templateUrl: './combine-member.component.html',
  styleUrls: ['./combine-member.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }],
  encapsulation: ViewEncapsulation.None
})
export class AgCombineMemberComponent implements OnInit {
  captions: any;
  createModalData: createModal;
  cancelBTN: ButtonValue;
  previoustBTN: ButtonValue;
  nextBTN: ButtonValue;
  combineBTN: ButtonValue;
  member: string;
  combinemenberInputs: CGcombinememInput;
  finalData: API.Guest;
  enableVTC = false;
  setvaluetocombineInputs: { primarydata: any; secondarydata: any; };
  finalcombinedata: CGcombinememInput;
  primarydefaultSet: boolean;
  @Input()
  set inputs(value) {
    this.combinemenberInputs = value;
    this.member = value.PrimarySelectedData.firstname + ' ' + value.PrimarySelectedData.lastname;
  }
  @Output() handleClickEvent = new EventEmitter();

  constructor(private _localization: Localization,
    public dialog: MatDialog, private utils: CommonUtilities,
    public _combineGuestRecordBusiness: combineGuestRecordBusiness,
    public _combineGuestRecordsComponent: AgCombineGuestRecordsComponent
  ) {
    this.captions = this._localization.captions;
  }

  ngOnInit(): void {
    this.createModalData = {
      title: this.captions.pg_title_combineMembers
    };
    this.initializedata();
  }

  onCancel(eve) {
    this.handleClickEvent.emit('close');
  }
  initializedata() {

    this.cancelBTN = {
      label: this.captions.common.Cancel,
      type: 'tertiary',
      disabledproperty: false
    };
    this.previoustBTN = {
      label: this.captions.common.PREVIOUS,
      type: 'secondary',
      disabledproperty: false
    };
    this.nextBTN = {
      label: this.captions.common.NEXT,
      type: 'primary',
      disabledproperty: true
    };
    this.combineBTN = {
      label: this.captions.btn_combine,
      type: 'primary',
      disabledproperty: false
    };
  }
  steppercancel(eve) {
    this._combineGuestRecordBusiness.showAlert(this.captions.combineGuest_Cancel_Msg,
      AlertType.Warning, ButtonType.YesNo, async (res) => {
        if (res === AlertAction.YES) {
          this.handleClickEvent.emit('cancel');
        }
      });
  }
  steppernext(eve, stepper: MatStepper) {
    this.enableVTC = true;
    stepper.next();
  }
  stepperprevious(eve, stepper: MatStepper) {
    this.enableVTC = false;
    stepper.previous();
  }
  async steppercombine(eve) {
    const warnMerge = this._localization.replacePlaceholders(this.captions.combineGuestWarn_Merge,
      ['primaryName'], [this.setvaluetocombineInputs.primarydata.firstname + ' ' + this.setvaluetocombineInputs.primarydata.lastname]);
    this._combineGuestRecordBusiness.showAlert(warnMerge, AlertType.Info, ButtonType.YesNo, async (res) => {
      if (res === AlertAction.YES) {
        // Update MergedGuestInfo Tbl and SoftDelete
        this.utils.ToggleLoader(true);
        const response = await this._combineGuestRecordBusiness.combineGuestRecords(this.setvaluetocombineInputs.primarydata.id, this.setvaluetocombineInputs.secondarydata.map(x => x.id));
        if (response) {
          // If primary Chkbox unchecked -- Required to update the field values
          if (!this.primarydefaultSet) {
            const primaryGuestData = await this._combineGuestRecordBusiness.getGuestInformationByGuid(this.setvaluetocombineInputs.primarydata.id);
            primaryGuestData.lastName = this.finalcombinedata.formchangeddata.lastname.value ? this.finalcombinedata.formchangeddata.lastname.value : primaryGuestData.lastName;
            primaryGuestData.firstName = this.finalcombinedata.formchangeddata.firstname.value ? this.finalcombinedata.formchangeddata.firstname.value : primaryGuestData.lastName;
            primaryGuestData.title = this.finalcombinedata.formchangeddata.title.value ? this.finalcombinedata.formchangeddata.title.value : primaryGuestData.title;
            primaryGuestData.guestProfileAddress.forEach(x => {
              x.addressLine1 = this.finalcombinedata.formchangeddata.address.value ? this.finalcombinedata.formchangeddata.address.value : x.addressLine1;
              x.zipCode = this.finalcombinedata.formchangeddata.postalcode.value ? this.finalcombinedata.formchangeddata.postalcode.value : x.zipCode;
              x.city = this.finalcombinedata.formchangeddata.city.value ? this.finalcombinedata.formchangeddata.city.value : x.city;
              x.state = this.finalcombinedata.formchangeddata.state.value ? this.finalcombinedata.formchangeddata.state.value : x.state;
              x.country = this.finalcombinedata.formchangeddata.country.value ? this.finalcombinedata.formchangeddata.country.value : x.country;
            })


            primaryGuestData.patronId = this.finalcombinedata.formchangeddata.patronid.value ? this.finalcombinedata.formchangeddata.patronid.value : primaryGuestData.patronId;
            if (primaryGuestData.guestProfileAddress.length < 1) {
              primaryGuestData.guestProfileAddress.push({
                addressLine1: this.finalcombinedata.formchangeddata.address.value ? this.finalcombinedata.formchangeddata.address.value : '',
                zipCode: this.finalcombinedata.formchangeddata.postalcode.value ? this.finalcombinedata.formchangeddata.postalcode.value : '',
                city: this.finalcombinedata.formchangeddata.city.value ? this.finalcombinedata.formchangeddata.city.value : '',
                state: this.finalcombinedata.formchangeddata.state.value ? this.finalcombinedata.formchangeddata.state.value : '',
                country: this.finalcombinedata.formchangeddata.country.value ? this.finalcombinedata.formchangeddata.country.value : '',
                county: '',
                isPrivate: true,
                addressLine2: '',
                addressLine3: ''
              });
            }
            if ((this.finalcombinedata.formchangeddata.phoneNumberArray && this.finalcombinedata.formchangeddata.phoneNumberArray.length > 0) ||
              (this.finalcombinedata.formchangeddata.emailArray && this.finalcombinedata.formchangeddata.emailArray.length > 0)) {
                primaryGuestData.guestProfileContact=[];
            }

            if (this.finalcombinedata.formchangeddata.emailArray && this.finalcombinedata.formchangeddata.emailArray.length > 0) {
                this.finalcombinedata.formchangeddata.emailArray.forEach(emailValue => {
                    var emailfieldValue = emailValue.value.split(":");
                    let emailType = this.MapEmailContactType(emailfieldValue[0]);
                    primaryGuestData.guestProfileContact.push({
                      type: emailType,
                      name: emailfieldValue[0],
                      description: '',
                      value: emailfieldValue[1],
                      isPrivate: emailValue.isPrivate,
                      isPrimary: (emailValue.id==primaryGuestData.guestId?emailValue.isPrimary:false)
                    });
              });
            }

            if (this.finalcombinedata.formchangeddata.phoneNumberArray && this.finalcombinedata.formchangeddata.phoneNumberArray.length > 0) {
              
              this.finalcombinedata.formchangeddata.phoneNumberArray.forEach(phone => {
                    var phoneValue = phone.value.split(":");
                    let phoneType = this.MapPhoneContactType(phoneValue[0]);
                    primaryGuestData.guestProfileContact.push({
                      type: phoneType,
                      name: phoneValue[0],
                      description: '',
                      value: phoneValue.length>2?phoneValue[1]+":"+phoneValue[2]:phoneValue[1],
                      isPrivate: phone.isPrivate,
                      isPrimary: (phone.id==primaryGuestData.guestId?phone.isPrimary:false)
                    });
              });
            }
            await this._combineGuestRecordBusiness.combineGuestInfo(primaryGuestData);
          }

          this.utils.ToggleLoader(false);
          const SuccessCGMerge = this._localization.replacePlaceholders(this.captions.combineGuestSuccess_Merge,
            ['primaryGuestFirstName', 'primaryGuestLastName'], [this.setvaluetocombineInputs.primarydata.firstname, this.setvaluetocombineInputs.primarydata.lastname]);
          this._combineGuestRecordBusiness.showAlert(SuccessCGMerge, AlertType.Done, ButtonType.Ok, callback => {
            this.handleClickEvent.emit('cancel');
          });
        }
      }
    });
  }

  MapPhoneContactType(phone: string): number {
    let phoneType: number = 1;
    switch (phone) {
      case "Home":
        phoneType = 2;
        break;
      case "Work":
        phoneType = 3;
        break;
    }
    return phoneType;
  }

  MapEmailContactType(phone: string): number {
    let emailType: number = 9;
    switch (phone) {
      case "Office":
        emailType = 10;
        break;
      case "Email":
        emailType = 5;
        break;
    }
    return emailType;
  }

  async output(eve) {
    this.setvaluetocombineInputs = {
      primarydata: eve.primarydata,
      secondarydata: eve.secondarydata,
    };
    this.nextBTN.disabledproperty = ((eve.secondarydata === undefined) || (eve.secondarydata && eve.secondarydata.length === 0));
  }
  AVTC_output(eve) {
    this.finalcombinedata = eve;
    this.primarydefaultSet = eve.primarydefaultSet;
  }
}
