import { Injectable } from "@angular/core";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { RetailPropertyInformation } from "../../common/services/retail-property-information.service";
import { RetailRoutes } from "../../retail-route";
import { BaseResponse } from "../../retail.modals";
import { RetailCommunication } from "../../retailcommunication.service";
import { PaymentMethods, SurchargeCalculationRequest, SurchargeDetailsResponse} from "../../shared/business/shared.modals";
import { Host, MiscellaneousSwitch } from "../../shared/globalsContant";
import { HttpMethod, HttpServiceCall } from "../../shared/service/http-call.service";
import { SurchargeConfiguration, SurchargeConfigurationAPI} from "../../shared/service/payment/payment-business.model";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";


@Injectable({ providedIn: 'root' })
export class SurchargeConfigurationService {

    private _isSurchargesEnabled: boolean = false;

    constructor(
        private _http: HttpServiceCall
        , private _utils: RetailUtilities
        , private retailCommunication: RetailCommunication
        , private localization: RetailLocalization
        , private _propertyInfo: RetailPropertyInformation
    ) { }

    public get IsSurchargesEnabled(): boolean {
        return this._isSurchargesEnabled;
    }

    async GetSurchargeSwitchConfig() {
        if (this._propertyInfo.UseRetailInterface) return;
        let miscRes = this._http.CallApiAsync({
            callDesc: "GetAllSettingByModule",
            host: Host.retailManagement,
            method: HttpMethod.Get,
            uriParams: { module: 3 }
        });
        miscRes.then((res) => {
            console.log(res.result);
            const miscConfig = <any[]>res.result && res.result[0];
            this._isSurchargesEnabled = miscConfig[MiscellaneousSwitch.ALLOW_PAYMENT_SURCHARGE];
        }).catch((err) => {
            console.log(err);
        })
    }

    async GetAllSurchargeConfiguration() {
        if (this._propertyInfo.UseRetailInterface) return;
        return this.retailCommunication.getPromise<SurchargeConfigurationAPI[]>({
            route: RetailRoutes.GetAllSurchargeConfiguration
        });
    }

    async GetSurchargeConfiguration(paymentTypeId: PaymentMethods) {
        if (this._propertyInfo.UseRetailInterface) return;
        const res = await this.retailCommunication.getPromise<SurchargeConfigurationAPI[]>({
            route: RetailRoutes.GetSurchargeConfigurationByPaymentTypeId,
            uriParams: { "paymentTypeId": paymentTypeId }
        });
        return res.map(c => this.MapAPItoUI(c));
    }    
    async GetSurchargeConfigurationWithCreditCardIssuer(paymentTypeId: PaymentMethods) {
        if (this._propertyInfo.UseRetailInterface) return;
        const res = await this.retailCommunication.getPromise<SurchargeConfigurationAPI[]>({
            route: RetailRoutes.GetSurchargeConfigurationByPaymentTypeId,
            uriParams: { "paymentTypeId": paymentTypeId }
        },false);
        return res;
    }
    async SaveSurchargeConfigurationsWithCCIssuerType(config: SurchargeConfiguration[], defaultSurchargeConfig: SurchargeConfigurationAPI[]) {
        if (config) {
            config = config.filter(x => x.id > 0 || x.isActive || x.isDefault); //Filter out invalid and dummy records
            config.map(x => {
                var OldSurchargeConfig =  defaultSurchargeConfig.find(y=>y.id == x.id);
                x.value = x.value ? this.localization.currencyToSQLFormat(String(x.value)) : 0;
                x.creditCardIssuerType = {
                    displayName : x.isDefault ? x.creditCardIssuerType.displayName : x.issuerName,
                    issuerType : x.isDefault ?  x.creditCardIssuerType.issuerType : x.issuerName.replace(' ','').toLowerCase(),
                    id : x?.creditCardIssuerType?.id ?? 0
                };
                x.CreditCardIssuerTypeId = x.creditCardIssuerType.id ?? 0;
                x.paymentTypeId = x.paymentTypeId ?? 2
            });
        }
        const res = await this.retailCommunication.postPromise<SurchargeConfigurationAPI[]>({
            route: RetailRoutes.SaveSurchargeConfigurations
            , body: config
        },false);
        return res;
    }
    async SaveSurchargeConfigurations(config: SurchargeConfigurationAPI[]) {
        if (config) {
            config.map(x => x.value = x.value ? this.localization.currencyToSQLFormat(String(x.value)) : 0);
        }
        const res = await this.retailCommunication.postPromise<SurchargeConfigurationAPI[]>({
            route: RetailRoutes.SaveSurchargeConfigurations
            , body: config
        });
        return res.map(c => this.MapAPItoUI(c));
    }

    UpdateFieldProperties(config: SurchargeConfiguration) {
        let sessionValue = this._utils.GetPropertyInfo("MaxDecimalPlaces");
        let propertyDecimalConfig = 2;
        if (sessionValue && Number(sessionValue)) {
            propertyDecimalConfig = Number(sessionValue);
        }
        if (!config.isPercentage) {
            config.placeHolder = `${this.localization.captions.shop.Value} (${this.localization.currencySymbol})`;
            config.maxLength = 12;
            config.decimalMaxLength = 2;
        }
        else {
            config.placeHolder = `${this.localization.captions.shop.Value} (%)`;
            config.maxLength = 3;
            config.decimalMaxLength = propertyDecimalConfig;
        }
    }
    async GetSurchargeByTokenTransactionId(surchargeCalculationRequest: SurchargeCalculationRequest): Promise<SurchargeDetailsResponse[]> {
        const res = await this.retailCommunication.putPromise<SurchargeDetailsResponse[]>({
            route: RetailRoutes.GetSurchargeByTokenTransactionId,
            body: surchargeCalculationRequest 
        },false);
        return res;
    }
    mapAPIDataToUIElements(configs: SurchargeConfigurationAPI[]) {
        const surchargeConfigCaption = this.localization.captions.setting.SurchargeConfig;
        var uiModel: SurchargeConfiguration[] = [];
        configs.map(config=>{  
            let issueType = config?.creditCardIssuerType?.issuerType;
            var currentModel = <SurchargeConfiguration>{
                id: config.id,
                isActive: config.isActive,
                isPercentage: config.isPercentage,
                paymentMethodId: config.paymentMethodId,
                paymentTypeId: config.paymentTypeId,
                typeId: config.typeId,
                value: config.value,    
                issuerName: issueType,
                isValid: !uiModel.some(x=>(x.issuerName.replace(/ /g, "").toLowerCase() == issueType.replace(/ /g, "").toLowerCase())),
                creditCardIssuerType: config.creditCardIssuerType,
                CreditCardIssuerTypeId: config.creditCardIssuerType?.id,
                maxLength: config.isPercentage ? 3 : 12
            }
            this.UpdateFieldProperties(currentModel);
            uiModel.push(currentModel)                        
        });
        return uiModel;
    }
    MapAPItoUI(config: SurchargeConfigurationAPI) {
        const surchargeConfigCaption = this.localization.captions.setting.SurchargeConfig;
        let uiModel = <SurchargeConfiguration>{
            id: config.id,
            isActive: config.isActive,
            isPercentage: config.isPercentage,
            paymentMethodId: config.paymentMethodId,
            paymentTypeId: config.paymentTypeId,
            typeId: config.typeId,
            value: config.value,
            issuerName: surchargeConfigCaption?.IssuerType[config.typeId] ? surchargeConfigCaption?.IssuerType[config.typeId] : "",
        }
        this.UpdateFieldProperties(uiModel);
        return uiModel;
    }
}