import { Component, OnInit,Inject } from '@angular/core';
import { Localization } from '../../localization/localization';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SaveButtonObj } from '../more/more-model';
import { ResetDashboardConfiguration } from './dashboard-jasper-common-popup.model';

@Component({
  selector: 'app-dashboard-jasper-common-popup',
  templateUrl: './dashboard-jasper-common-popup.component.html',
  styleUrls: ['./dashboard-jasper-common-popup.component.scss']
})
export class DashboardJasperCommonPopupComponent implements OnInit {

  captions: any;
  buttonObj: SaveButtonObj;
  resetTypeSelect = 1;
  dashboardJasperType: any;
  constructor(private localization: Localization,public dialogRef: MatDialogRef<DashboardJasperCommonPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.buttonObj = {
      customSaveText: this.captions.btn_apply,
      disabled: false,
    }
    this.dashboardJasperType = [
      {
        id: ResetDashboardConfiguration.ClearDashboardConfiguration,
        value: ResetDashboardConfiguration.ClearDashboardConfiguration,
        viewValue: this.captions.lbl_Clear_DashboardConfig
      },
      {
        id: ResetDashboardConfiguration.SetPropertyDefaultConfiguration,
        value: ResetDashboardConfiguration.SetPropertyDefaultConfiguration,
        viewValue: this.captions.lbl_set_Propdefault_dashboard
      }
    ]
  }

  close(){
    this.dialogRef.close()
  }

  save(eve){
    this.dialogRef.close(this.resetTypeSelect)
  }

}
