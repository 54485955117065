import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ReqBackOrder } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { ConfirmPopModel, ConfirmPostPopUpModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import _ from 'lodash';
import { AgilysysPartialTransferComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-partial-transfer/agilysys-partial-transfer.component';

export const BackOrderRequisitions: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType ?: any,
    ModuleKey ?: string,
    dialog ?: MatDialog,
    transactionCollection ?: TransactionConfiguration,
    homeGroup ?: FormGroup
): any => {
        return new Promise<boolean>((resolve, reject) => {
            const reqBackOrder = GlobalPreference.filter(x => x.LookupName === PrferenceType)[0].LookupValue;
            if (homeGroup && homeGroup.value['ReqId'] && reqBackOrder !== ReqBackOrder.NoBackOrder) {
                let reqHead;
                const itemDetail = [];
                const reqExistingData = _.cloneDeep(transactionCollection.CreateTranscationConfig.
                        ItemsConfig.ItemList.ItemListExistingData);
                const reqCurrentData = transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
                reqCurrentData.forEach(element => {
                    const originalData = reqExistingData['ReqDetails'].filter(x => x.Id === element.Id)[0];
                    if (originalData) {
                        const currentQty = element.Qty;
                        const currentQtyCheck = (element.Qty > 0 && element.Conversion > 0) ?
                           ( parseFloat(element.Qty) * parseFloat(element.Conversion)) : 0;
                        const originalQtyCheck = (originalData.Qty > 0 && originalData.Conversion > 0) ?
                           ( parseFloat(originalData.Qty) * parseFloat(originalData.Conversion)) : 0 ;
                        if (currentQtyCheck < originalQtyCheck) {
                            originalData.Qty = (originalQtyCheck - currentQtyCheck) / originalData.Conversion;
                            originalData.Valued = originalData.Qty * element.UnitCost;
                            itemDetail.push(originalData);
                        }
                    }
                });
                const removedItems = reqExistingData['ReqDetails'].filter(x => reqCurrentData.every(y => y.Id !== x.Id));
                itemDetail.push(...removedItems);
                reqHead = reqExistingData;
                reqHead['ReqDetails'] = itemDetail;
                sysytemPreferenceService.backOrderReqData = reqHead;
                if (itemDetail.length > 0) {
                    
                    const ConfirmPopUpDialog: ConfirmPopModel = {
                        PopupHeaderContent: 'Partial Transfer',
                        PopUpContent: 'Item(s) transferred partially. Do you wish to back order?',
                      };
        
                      const dialogRef = dialog.open(AgilysysPartialTransferComponent, {
                        width: '400px',
                        height: '222px',
                        data: ConfirmPopUpDialog,
                        disableClose: true
                      });
                      dialogRef.afterClosed().subscribe(result => {
                        if (result === 'Confirm') {
                            homeGroup.controls['IsBackOrder'].setValue(true);
                            resolve(true);
                        } else if (result === 'Cancel') {
                            homeGroup.controls['IsBackOrder'].setValue(false);
                            resolve(true);
                        }
                      });
                } else {
                    resolve(true);
                }
            } else {
                if (homeGroup) {
                    homeGroup.value['IsBackOrder'] = false;
                }
                resolve(true);
            }
        });

};