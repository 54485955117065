import { Injectable } from '@angular/core';
import { LoaderService } from 'src/app/eatecui/source/core/services/loader.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ErrorPopModel, PrintPopModel, PrintPopUpCallBack } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { PrintFormConfig, PrinterModuleConfig } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from '../http.service';
import { GenerateReportFilterModel, GenerateReportModel } from "src/app/eatecui/source/reports/interface/reports.interface";
import { AgilysysPrintPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-print-popup/agilysys-print-popup.component';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { CommonService } from '../common.service';
import { FormType, RadioOptions, SingleField } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { TemplateInterface } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { HttpClient } from '@angular/common/http';
import { PrinterInterface } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { ConversionAlgorithm } from 'src/app/eatecui/source/shared/algorithms';
import * as APIURLConstants from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { ToastrService } from 'ngx-toastr';
import { Validators } from '@angular/forms';
import { StorageService } from '../storage.service';
@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(
    private httpService: HttpService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private commonService: CommonService,
    private httpClientModel: HttpClient,
    private toastrService: ToastrService
  ) { }

  getPrintForm(PrintDetails: PrintFormConfig) {
    let printPostObject: any;
    if (PrintDetails.EnableApiPrint) {
      if (PrintDetails.DOMName === 'expandgrid') {
        printPostObject = PrintDetails;
      } else {
        printPostObject = {
          'reportFormat': 0,
          'inLine': false,
          'fileName': '',
          'reportid': PrintDetails.ReportId,
          'sortBy': 'string',
          'isAsc': true,
          'isRetail': true,
          'filters': [
            {
              'property': PrintDetails.ReportId === 178 || PrintDetails.ReportId === 179 ? 'ids' : 'id',
              'value': PrintDetails.DynamicId.toString(),
              'isExclude': false
            }
          ]
        };
       
      }
      printPostObject['IanaTimeZoneId'] = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      printPostObject['UserTimeZoneId'] = new Date()?.toString()?.split("(")[1]?.split(")")[0];
      const putUrl = `/report`;
      let object = {
        code: '',
        filterBody : printPostObject,
        format: "HTML",
        downloadFileName: "Download",
        parameters: '',
        uRIParams: '',
        dateFormat:  StorageService.GetSessionStorage('IniDateFormat')
      }
      switch(PrintDetails.ReportId)
      {
        case(24) : object['code'] = ReportCode.PHYS09;
        break;
        case(44) : object['code'] = ReportCode.INGFRM;
        break;
        case(71) : object['code'] = ReportCode.PPOX90;
        break;
        case(77) : object['code'] = ReportCode.PPOX90;
        break;
        case(82) : object['code'] = ReportCode.MAFRM;
        break;
        case(115) : object['code'] = ReportCode.MAFRMWITHOUTGL;
        break;
        case(147) : object['code'] = ReportCode.PHYS09P;
        break;
        case(148) : object['code'] = ReportCode.PHYS04UNITP;
        break;
        case(180) : object['code'] = ReportCode.PHYS09QOH;
        break;
        case(282) : object['code'] = ReportCode.PHYS10;
        break;
        case(113) : object['code'] = ReportCode.RTLFRM;
        break;
        case(161) : object['code'] = ReportCode.PHYS09P_PRINT;
        break;
        case(162) : object['code'] = ReportCode.PHYS04UNITP_PRINT;
        break;
      }
      console.log(object);
      this.httpService.PutthttpMethod(putUrl, object, null, { observe: 'response', responseType: 'blob' }).subscribe(
        (responseData: any) => {
          const windowNavigator: any = window.navigator;
          const b: any = new Blob([responseData.body], { type: responseData.body.type });
          // Added for microsoft browsers
          if (responseData.body.type !== 'application/json'
            && responseData.body.type !== 'application/json; charset=utf-8') {
            // Added for Edge support
            if (window.navigator && windowNavigator.msSaveOrOpenBlob) {
              windowNavigator.msSaveOrOpenBlob(b, 'Print.pdf');
            } else {
              const url = window.URL.createObjectURL(b);
              const winFeature = 'location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes';
              const win = window.open(url, 'Print Preview', winFeature);
            }
          } else if (responseData.body.type === 'application/json'
          || responseData.body.type === 'application/json; charset=utf-8') {
            const ErrorModel: ErrorPopModel = {
              'PopupHeaderContent': 'Print',
              'PopUpContent': 'No values found to generate reports'
            };
            this.dialog.open(AgilsysErrorPopComponent, {
              width: '400px',
              data: ErrorModel,
              disableClose: true
            });
          } else {
            const reader = new FileReader();
            reader.addEventListener('loadend', (e) => {
              // const text: any = e.target;
            });
            reader.readAsText(b);
          }
        });
    } else if (PrintDetails.EnableDOMPrint) {
      console.log('DOM Print');
      this.loaderService.isLoading.next(true);
      setTimeout(() => {
        const element = document.getElementById(PrintDetails.DOMName);
        element.style.padding = '15px';
        setTimeout(() => {
          element.style.padding = '0px';          
        }, 1);
        html2canvas(element).then((canvas) => {
          // element.style.padding = '0px';
          console.log('canvas', canvas);
          canvas.setAttribute('style', 'padding: 15px');
          const doc = new jsPDF('p', 'mm', 'a4');
          const width = doc.internal.pageSize.getWidth();
          const height = doc.internal.pageSize.getHeight();
          const imagedata = canvas.toDataURL('image/jpeg', 1.0);
          const imageheight = canvas.height * 208 / canvas.width;
          doc.addImage(imagedata, 'JPEG', 0, 0, width, height);
          doc.save('Nutrition.pdf');
          this.loaderService.isLoading.next(false);
          // doc.output('dataurlnewwindow');
          const file = doc.output('blob');
          const url = window.URL.createObjectURL(file);
          const winFeature = 'location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes';
          const win = window.open(url, 'Nutrition', winFeature);
          // const a = document.createElement('a');
          // document.body.appendChild(a);
          // a.setAttribute('style', 'display: none');
          // a.href = url;
          // a.download = 'Nutrition';
          // a.click();
          // window.URL.revokeObjectURL(url);
        });        
      }, 100);
    }
  }
  getanalysisForm(PrintDetails: PrintFormConfig) {
    if (PrintDetails.EnableDOMPrint) {
      console.log('DOM Print');
      this.loaderService.isLoading.next(true);
      setTimeout(() => {
        const element = document.getElementById(PrintDetails.DOMName);
        element.style.padding = '15px';
        setTimeout(() => {
          element.style.padding = '0px';          
        }, 1);
        html2canvas(element).then((canvas) => {
          // element.style.padding = '0px';
          console.log('canvas', canvas);
          canvas.setAttribute('style', 'padding: 15px');
          const doc = new jsPDF();
          const width = doc.internal.pageSize.getWidth();
          // const height = 50;
          const height = element.offsetHeight / 5;
          // const imagedata = canvas.toDataURL('image/jpeg', 1.0);
          const imagedata = canvas.toDataURL('image/PNG');
          const imageheight = canvas.height * 208 / canvas.width;
          // doc.addImage(imagedata, 'JPEG', 0, 0, width, height);
          doc.addImage(imagedata, 'PNG', 0, 0, width, height);   
          doc.save('Analysis.pdf');
          this.loaderService.isLoading.next(false);
          // doc.output('dataurlnewwindow');
          const file = doc.output('blob');
          const url = window.URL.createObjectURL(file);
          const winFeature = 'location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes';
          const win = window.open(url, 'Analysis', winFeature);
        });        
      }, 100);
    }
  }
  async getPrintTemplates(PrintInfo: PrintFormConfig, ModuleName: string) {
    // const Printenpoint = '/reportdata/api/ReportModule?$select=Id,Name&$expand=Reports($filter=IsActive eq true and IsPrint eq true;$select=Id,Name,FileName,Description)&$filter=IsActive eq true And Reports/any(c: c/IsActive eq true and c/IsPrint eq true)';
    // const getPrintList = await this.httpService.GethttpMethod(Printenpoint).toPromise();
    let selectedPrintName: string;
    switch(ModuleName) {
      case 'Production Label': selectedPrintName = 'ProductionLabelPrint'; break;
      case 'Receiving Label': selectedPrintName = 'ReceivingLabelPrint'; break;
      case 'Production Batch': selectedPrintName = 'ProductionBatchPrint'; break;
      default: selectedPrintName = ModuleName; break;
    }
    const PrintId = await this.commonService.getPrintId(selectedPrintName);
    const getPrintList = await this.commonService.getPrintListById(PrintId);
    const EnablePopupResult: PrintPopUpCallBack = await this.OpenPrintList(this.dialog, 
                              getPrintList, PrintInfo.DOMName + 'Print', selectedPrintName, PrintId);
    if ( EnablePopupResult && EnablePopupResult.ButtonKey === 'Confirm' && EnablePopupResult.SelectedId !== null) {
       const generateReportModel = {} as GenerateReportModel;
       generateReportModel.reportFormat = 0;
       generateReportModel.fileName = null;
       generateReportModel.inLine = false;
       generateReportModel.sortBy = 'string';
       generateReportModel.reportid = EnablePopupResult.SelectedId;
       generateReportModel.isAsc = true;
       generateReportModel.isRetail = true;
       const generateReportFilterModel = {} as GenerateReportFilterModel;
       generateReportFilterModel.property = 'id';
       generateReportFilterModel.value  = PrintInfo.DynamicId.toString();
       generateReportFilterModel.isExclude = false;
       generateReportModel.filters = [generateReportFilterModel];
       generateReportModel.IanaTimeZoneId = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
       generateReportModel.UserTimeZoneId = new Date()?.toString()?.split("(")[1]?.split(")")[0];
       const postUrl = `/ReportData/api/GenerateReport`;
       const reportData = await this.httpService.PosthttpMethod(postUrl, 
       generateReportModel, null, { observe: 'response', responseType: 'blob'}).toPromise();
       const file = new Blob([reportData.body], {type: reportData.body.type});
       const fileURL = URL.createObjectURL(file);
       window.open(fileURL);
    }
  }

  /**
   * 
   * @param dialog 
   * @param ResultData 
   * @param selectedtransaction 
   * @param Module 
   * @param PrintId 
   * @param EnableCount 
   * @returns 
   */
  async getPrinter(PrintInfo: PrintFormConfig, ModuleName: string, PrinterInfo:  any, selectedId ?: number, responseData ?: Array<any>, getPrintList?: any, printerModuleConfig ?: PrinterModuleConfig ){
    if( getPrintList && getPrintList.length > 0 ){
      const {locationid } = responseData[0];
      const formType = {} as FormType;
      formType.name = 'printlabel';
      formType.label = 'Print Label';
      formType.type = 'dropdown'
      const singleData = {} as SingleField;
      singleData.name = 'printlabel';
      singleData.label = 'Select Label Template';
      singleData.options = [];
      singleData.value = getPrintList[0].Id;
      getPrintList.forEach((x: TemplateInterface)=>{
        const radioOPtion = {} as RadioOptions;
        radioOPtion.key = x.Id;
        radioOPtion.label = x.Name
        singleData.options.push(radioOPtion);
      })
      formType.fieldType = {
        SingleData: singleData
      };
      /**
       * printformlabel
       */
      const labelFormType = {} as FormType;
      labelFormType.name = 'printlabelcount';
      labelFormType.label = 'Label Count';
      labelFormType.type = 'text';
      const singleLabelData = {} as SingleField;
      singleLabelData.name = 'printlabelcount';
      singleLabelData.label = 'Label Count';
      singleLabelData.options = [];
      singleLabelData.value = 1;
      singleLabelData.FieldValidation = ( printerModuleConfig?.EnableCountText ) ? [{
        validation: Validators.required,
        validationMessage: 'This Field is required', 
        key: 'required'  
       }] : []
      labelFormType.fieldType = {
        SingleData: singleLabelData
      };
      /**
       * configure add New Printer Ip Control
       */

      const newPrinterControl = {} as FormType;
      newPrinterControl.name = 'ipaddress';
      newPrinterControl.label = 'IP Addreess';
      newPrinterControl.type = 'text'
      const singleFormData = {} as SingleField;
      singleFormData.name = 'ipaddress';
      singleFormData.label = 'Enter Printer Name / IP Address';
      singleFormData.options = [];
      singleFormData.value = '';
      
      newPrinterControl.fieldType= {
        SingleData: singleFormData
      };
      const port = parseInt(localStorage.getItem('PrinterPort').toString());
      const PrinterParams = {} as PrinterInterface;
      PrinterParams.DialogConfig = this.dialog;
      PrinterParams.PrinterType = "ZPL";
      PrinterParams.PrinterUrl = `http://localhost:${port}/EatecUtility/print`;
      PrinterParams.LocalPrinterUrl = `http://localhost:${port}/EatecUtility/GetPrinterDetails`;
      PrinterParams.NetworkPrinterUrl = APIURLConstants.ENDPOINTURL['Report']['GetNetWorkPrinter'];;
      PrinterParams.httpClient = this.httpClientModel;
      PrinterParams.httpService = this.httpService;
      PrinterParams.PrinterTypes = PrinterInfo.PrinterConfig['PrinterTypes'];
      PrinterParams.PrinterModel = PrinterInfo.PrinterConfig['PrinterModel'];
      PrinterParams.PrintFormType = [formType];
      PrinterParams.TemplateCollecation = getPrintList;
      PrinterParams.selectedId = selectedId;
      PrinterParams.PrintLabelCount = [labelFormType]
      PrinterParams.locationId = locationid;
      PrinterParams.isretail = ( printerModuleConfig ) ? printerModuleConfig.IsRetail : false;
      PrinterParams.EnableCountText = ( printerModuleConfig ) ? printerModuleConfig.EnableCountText : false;
      PrinterParams.NewPrinterFormType = [newPrinterControl];
      PrinterParams.PrinterCallback = ( PrinterParams: any ) =>{
      console.log(PrinterParams);
      }
      ConversionAlgorithm.Printer(PrinterParams)
    } else {
      this.toastrService.info('Printer label not configured.', '', {
        timeOut: 3000,
        closeButton: true,
      });
    }
   
    
  }

  OpenPrintList(dialog: MatDialog, ResultData: Array<any>, selectedtransaction: string, Module:  string,PrintId: number, EnableCount: boolean = false,
    ) {
    try {
     return new Promise<PrintPopUpCallBack>(resolve => {
         const printPopModel = {} as PrintPopModel;
         printPopModel.PopUpContent = `${Module} Print`;
         printPopModel.PopupHeaderContent = `${Module} Print`;
         printPopModel.PrintList = ResultData;
         printPopModel.PrintKey = selectedtransaction;
         printPopModel.EnableCount = EnableCount;
         printPopModel.PrintId = PrintId;
         const dialogRef = dialog.open(AgilysysPrintPopupComponent, {
             width: '40%',
             height: '30%',
             data: printPopModel,
             disableClose: true
         });
         dialogRef.afterClosed().subscribe((result: PrintPopUpCallBack) => {
             resolve(result);
         });
       });
    } catch ( error ) {
        console.error(error);
    }
 }
}


export enum ReportCode
{
  PHYS09 = 'PHYS09',
  INGFRM = 'INGFRM',
  PPOX90 = 'PPOX90',
  PHYFRM = 'PHYFRM',
  MAFRM = 'MAFRM',
  MAFRMWITHOUTGL = 'MAFRMWITHOUTGL',
  PHYS09P = 'PHYS09P',
  PHYS04UNITP = 'PHYS04UNITP',
  PHYS09QOH = 'PHYS09QOH',
  PHYS10 = 'PHYS10',
  RTLFRM = 'RTLFRM',
  PHYS09P_PRINT = 'PHYS09P_PRINT',
  PHYS04UNITP_PRINT = 'PHYS04UNITP_PRINT'
}