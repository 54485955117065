import { MatDialog } from "@angular/material/dialog";
import { CustomDeliveryRulesConfig, DeliveryRulesDataCollection, DeliveryRulesPostModel } from "./custom-form-configuration.interface";
import { HttpClient } from "@angular/common/http";
import { DeliveryRulesPopupComponent } from "src/app/eatecui/source/setup-master/shared/components/delivery-rules-popup/delivery-rules-popup.component";
import { FormGroup } from "@angular/forms";
import moment from "moment";
import { NestedDataBuilder } from "src/app/eatecui/source/setup-master/shared/interface/master.interface";

export const DeliveryRules: CustomDeliveryRulesConfig = async (formGroupData: FormGroup, HeaderPropName: string, dialog: MatDialog, http: HttpClient, NestedDataBuild: NestedDataBuilder, DataCollection: any): Promise<any> => {
    try {
       const deliveryRulesDataCollec = await http.get<DeliveryRulesDataCollection>('assets/json/delivery-rules.json').toPromise();
       const formGroupPropName = NestedDataBuild?.NestedName === 'SupplierSitesAccConfig' ? 'DeliveryRulesSupplierSitesAccConfigs' : 
       'DeliveryRulesSuppliers';
       const deliveryTypeId = NestedDataBuild?.NestedName === 'SupplierSitesAccConfig' ? 3 : 2;
       const formGroupDataCollec = formGroupData?.value[formGroupPropName].filter(fg => fg.DeliveryTypeId === deliveryTypeId);
       const dataModel = {} as any;
       dataModel.popupHeader = HeaderPropName;
       dataModel.data = getDataCollec(deliveryRulesDataCollec, formGroupDataCollec);
       const dialogRef = dialog.open(DeliveryRulesPopupComponent, {
        width: '807px',
        height: '805px',
        data: dataModel,
        disableClose: true,
        autoFocus: false
       });
       dialogRef.afterClosed().subscribe(returnValue => {
        if (returnValue && returnValue !== 'Cancel') {
            let deliveryRulesPostCollection = [];
          returnValue.DeliveryRulesCollection.forEach(collec => {
            let deliveryRulesPostObject = {} as DeliveryRulesPostModel;
            const fcollection = formGroupData?.value[formGroupPropName]?.length > 0 ? 
              formGroupData?.value[formGroupPropName]?.filter(f => f.DeliveryDay === collec.DeliveryDay)[0] : null;
            deliveryRulesPostObject.Id = fcollection?.Id ? fcollection.Id : 0;
            deliveryRulesPostObject.DeliveryTypeId = NestedDataBuild?.NestedName === 'SupplierSitesAccConfig' ? 3 : 2;
            deliveryRulesPostObject.LocationId = null;
            deliveryRulesPostObject.SupplierId = (NestedDataBuild?.NestedName !== 'SupplierSitesAccConfig') ?
            fcollection?.SupplierId ? fcollection.SupplierId : null : null;
            deliveryRulesPostObject.SupplierSitesAccConfigId = NestedDataBuild?.NestedName === 'SupplierSitesAccConfig' ?
            fcollection?.SupplierSitesAccConfigId ? fcollection.SupplierSitesAccConfigId : null : null;
            deliveryRulesPostObject.DeliveryDay = collec.DeliveryDay;
            deliveryRulesPostObject.Description = null;
            deliveryRulesPostObject.OrderTime = null;
            const odayIndex = collec.OrderDay.findIndex(od => od.IsChecked);
            if (odayIndex > -1) {
              deliveryRulesPostObject.OrderDay = collec.OrderDay[odayIndex].Code;
              deliveryRulesPostObject.OrderDayUTC = getOrderDay(collec, deliveryRulesDataCollec);
              deliveryRulesPostObject.DeliveryOrderTime =  getUTCTime(collec.OrderTime);              
              deliveryRulesPostObject.IsActive = true;
            } else {
              deliveryRulesPostObject.OrderDay = null;
              deliveryRulesPostObject.DeliveryOrderTime = getUTCTime('12:00 am');
              deliveryRulesPostObject.IsActive = false;
            }
            deliveryRulesPostCollection.push(deliveryRulesPostObject);
          });
            formGroupData?.controls[formGroupPropName]?.setValue(deliveryRulesPostCollection);   
            console.log(DataCollection);   
            const otherFieldsTabIndex = DataCollection?.NestedFormConfig?.EditFormConfig?.findIndex(fc => fc.Name === 'Other Fields');
            const deliveryRulesIndex = DataCollection?.NestedFormConfig?.EditFormConfig[otherFieldsTabIndex]?.NestedForm[0]?.FormFields?.findIndex(of => of.fieldType.SingleData.name === 'DeliveryRulesSuppliers');
            if (deliveryRulesPostCollection?.filter(dc => dc.IsActive === true).length > 0 && deliveryRulesIndex > -1) {
              DataCollection.NestedFormConfig.EditFormConfig[otherFieldsTabIndex].NestedForm[0].FormFields[deliveryRulesIndex].fieldType.SingleData.LabelWithIconConfig.AdditionalIconConfig.IconEnable = true;
            } else if (deliveryRulesIndex > -1) {
              DataCollection.NestedFormConfig.EditFormConfig[otherFieldsTabIndex].NestedForm[0].FormFields[deliveryRulesIndex].fieldType.SingleData.LabelWithIconConfig.AdditionalIconConfig.IconEnable = false;
            }  
        }         
       });
    } catch (error) {
        console.log('error occured openBarcodeEdit', error);
    }
};
function getDataCollec(dataCollec: any, formGroupDataCollec: any): Array<DeliveryRulesDataCollection>  {
    try {
     let returnDataCollec = dataCollec;
     if (formGroupDataCollec?.length > 0) {
        formGroupDataCollec.forEach(fgroupData => {
          const deliveryRulesIndex = returnDataCollec?.DeliveryRulesCollection?.findIndex(d => d.DeliveryDay === fgroupData.DeliveryDay);
          const deliveryRulesOrderDayIndex = 
            returnDataCollec.DeliveryRulesCollection[deliveryRulesIndex]?.OrderDay?.findIndex(o => o.Code === fgroupData.OrderDay);
          const selectedOrderDayName = returnDataCollec.DeliveryRulesCollection[deliveryRulesIndex]?.OrderDay[deliveryRulesOrderDayIndex]?.Name ?
          returnDataCollec.DeliveryRulesCollection[deliveryRulesIndex].OrderDay[deliveryRulesOrderDayIndex].Name : '';
          const getTime = fgroupData.DeliveryOrderTime ? fgroupData.DeliveryOrderTime : fgroupData.OrderTime ? fgroupData.OrderTime : '';
          const time = getPatchTime(getTime);
          returnDataCollec.DeliveryRulesCollection[deliveryRulesIndex].OrderTime =  time ? time : '12:00 am';
          returnDataCollec.DeliveryRulesCollection[deliveryRulesIndex].SelectedOrderDay = selectedOrderDayName;
          if (deliveryRulesOrderDayIndex > -1) {
            returnDataCollec.DeliveryRulesCollection[deliveryRulesIndex].OrderDay[deliveryRulesOrderDayIndex].IsChecked = true; 
          }                  
        });
     }
     return returnDataCollec;
    } catch (error) {
        console.log(error);
    }
}
function getUTCTime(time: string): string {
  try {
    const currentTimeDate = new Date();
    const TimeFormats = moment(time, ['hh:mm a']).format('HH:mm');
    const Hours = parseInt(TimeFormats.split(':')[0], 10);
    const Minutes = parseInt(TimeFormats.split(':')[1], 10);
    const datetime = new Date(currentTimeDate.getFullYear(), currentTimeDate.getMonth(), currentTimeDate.getDate(), Hours, Minutes, 0);
    const ScheduleTime = moment.utc(datetime).format('hh:mm a');
    console.log(ScheduleTime);
    return ScheduleTime;
  } catch (error) {
    console.log(error);
  }
}
function getPatchTime(time): string {
  try {
    if (time) {
      const currentTimeDate = moment().format('MM/DD/YYYY');
      const dateTime = new Date(`${currentTimeDate} ${time} UTC`);
      const momentDateTime = moment(dateTime).format('hh:mm a');
       return momentDateTime;
    }    
  } catch (error) {
     console.log(error);
  }
}
function getOrderDay(collec: any, deliveryRulesDataCollec: any): any {
  try {
    const now = new Date();
    let startDate = moment(now, 'YYYY/MM/DD').add(1, 'weeks');
    const startDay = startDate.day() + 1;
    const selectedDayCollec = deliveryRulesDataCollec.DeliveryRulesCollection[0].OrderDay.filter(o => o.Name === collec.SelectedOrderDay)[0];
    const selectedDayInd = selectedDayCollec.Code;
    const diffDay = selectedDayInd - startDay;
    const selectedDate = moment(startDate, 'YYYY/MM/DD').add(diffDay, 'days');
    const year = selectedDate.year();
    const month = selectedDate.month();
    const date = selectedDate.date();
    const timeFormats = moment(collec.OrderTime, ['hh:mm a']).format('HH:mm');
    const hours = parseInt(timeFormats.split(':')[0], 10);
    const minutes = parseInt(timeFormats.split(':')[1], 10);
    const strtDate = new Date(year, month, date, hours, minutes, 0);
    const utcDay = moment.utc(strtDate).day() + 1;
    return utcDay;
  } catch (error) {
    console.log(error);
  }
}