import { UserManagementAuthorizedConfig } from './ui-control-config.interface';
import { AuthorizationSubModule, AuthorizedDataModel, AuthorizedGroupModel, PermissionResource, UiControlConfiguration, UiControls } from './ui-controls.interface';
// import * as AuthSample from '../../../../assets/json/ui-control/authoriztion-sample.json';
import { AuthorizationModule, AuthoriztionKey } from './authorization.enum';
import { SetUpGroup, SetUpGrouping, SubGroupListing } from 'src/app/eatecui/source/setup/shared/interface/setup-links.interface';
import { GridAppConfig } from '../app-grid.interface';
import { SecondaryHeader } from '../secondary-header.interface';
import { GridConfig, GridHeaderConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';

export const UserManagementAuthorization: UserManagementAuthorizedConfig = (
setUpGroup: Array<SetUpGroup>, AuthorizedData: Array<AuthorizedDataModel>, SetUpAppGridConfig ?: Array<GridAppConfig>, 
UserManagementHeader ?: SecondaryHeader, GridData ?: GridConfig): any => {
        try {
            const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
            const authorizationName: any = 'usermanagement';
            const authSample = null;
            // eslint-disable-next-line max-len
            const AuthorizedDataResult: Array<AuthorizedDataModel> = ( AuthorizedData && AuthorizedData !== null ) ? AuthorizedData : 
            ( AuthorizationData && AuthorizationData !== null ) ? AuthorizationData : authSample;
            if ( setUpGroup && AuthorizedDataResult && AuthorizedDataResult.length > 0 ) {
               // eslint-disable-next-line max-len
               const authDataModel: Array<AuthorizedDataModel> = AuthorizedDataResult.filter((x: AuthorizedDataModel) => x.Name ===  AuthorizationModule.USERMANAGEMENT);
               if ( authDataModel && authDataModel.length > 0 ) {
                authDataModel.forEach((authModel: AuthorizedDataModel) => {
                    if ( authModel.hasOwnProperty('SubModules') && authModel.SubModules.length > 0 ) {
                       authModel.SubModules.forEach((e: AuthorizationSubModule) => {
                          if ( e.hasOwnProperty('PermissionResource') && e.PermissionResource.length > 0 ) {
                           e.PermissionResource.forEach((p: PermissionResource) => {
                               if ( ( p.Name.replace(/ +/g, '')  === AuthoriztionKey.VIEWUSERPROFILE || 
                                      p.Name.replace(/ +/g, '')  === AuthoriztionKey.VIEWROLE ) ) {
                                  userManagementMenuDisabled(p, setUpGroup, e.Name);
                               }
                               if ( p.Name.replace(/ +/g, '')  === AuthoriztionKey.CREATEUSERPROFILE || 
                                    p.Name.replace(/ +/g, '')  === AuthoriztionKey.CREATEROLE ) {
                                    userManagementCreatePermission(p, SetUpAppGridConfig, e.Name, UserManagementHeader, GridData);
                               }
                               if ( SetUpAppGridConfig !== null && 
                                ( p.Name.replace(/ +/g, '')  === AuthoriztionKey.RDUSERPROFILE || 
                                  p.Name.replace(/ +/g, '')  === AuthoriztionKey.RDROLEPROFILE ) ) {
                                    userManagemsentDeactivatePermission(p, SetUpAppGridConfig, e.Name, UserManagementHeader, GridData);
                             }
                               
                           });
                          }
                       });
                    }
               });
               }
            } 
            return null;
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

    function userManagementMenuDisabled(permisssionResource: PermissionResource, setUpGroup: Array<SetUpGroup>, 
    SubModuleName: string ): void {
       try {
        if ( setUpGroup && setUpGroup.length > 0 ) {
            setUpGroup.forEach((x: SetUpGroup ) => {
               if ( x.hasOwnProperty('SetUpSubGroup') && x.SetUpSubGroup.length > 0 ) {
                    x.SetUpSubGroup.forEach((subGroup: SetUpGrouping) => {
                        const groupName = ( subGroup.hasOwnProperty('SubGroupName') && subGroup.SubGroupName !== '') ? 
                        subGroup.SubGroupName : x.GroupName;
                        if ( groupName.toString().toLowerCase() === SubModuleName.toString().toLowerCase() &&  
                            subGroup.hasOwnProperty('SubGroupList') && subGroup.SubGroupList.length > 0 ) {
                            subGroup.SubGroupList.forEach((groupList: SubGroupListing) => {
                                groupList['Authorization'] = permisssionResource.IsActive;
                            });
                            subGroup.Authorization = subGroup.SubGroupList.some(subGrp => subGrp.Authorization === true);
                        }
                    });
                    x.Authorization = x.SetUpSubGroup.some(grp => grp.Authorization === true);
               }
            });   
         }
       } catch ( error ) {
           console.error(error);
       }
    }

    function userManagementCreatePermission(permisssionResource: PermissionResource, 
    SetUpAppGridConfig: Array<GridAppConfig>, SubModuleName: string, UserManagementHeader: SecondaryHeader, GridData ?: GridConfig): void {
      try {
          const uniqueName = ( UserManagementHeader && UserManagementHeader.hasOwnProperty('PageTitle') && 
          UserManagementHeader.PageTitle === 'Users') ? 'users' : 'roles';
         if ( SubModuleName === uniqueName && UserManagementHeader && UserManagementHeader.ButtonArray.length > 0 ) {
            UserManagementHeader.ButtonArray.forEach(x => {
                x.Class = ( permisssionResource.IsActive ) ? 'agilysys-button-primary' :  'agilysys-button-primary d-none';
            });
            if ( uniqueName.toString().toLowerCase() === SubModuleName.toString().toLowerCase()) {
                if ( GridData.hasOwnProperty('GridHeaderDefination') && GridData.GridHeaderDefination &&  
                     GridData.GridHeaderDefination.length > 0 ) {
                        GridData.GridHeaderDefination.forEach((HeaderConfig: GridHeaderConfig) => {
                            if ( HeaderConfig.headerName === 'Actions') {
                                if ( HeaderConfig.hasOwnProperty('Copy')) {
                                    HeaderConfig['Copy'] = permisssionResource.IsActive;
                                } 
                                if ( HeaderConfig.hasOwnProperty('Edit')) {
                                    HeaderConfig['Edit'] = permisssionResource.IsActive;
                                }  
                            }
                        });
                }
                
                if (GridData.hasOwnProperty('rowClicked') && GridData.rowClicked && !permisssionResource.IsActive) {
                    delete GridData['rowClicked'];
                }
            }
         }
      } catch ( error ) {
          console.error(error);
      }
    }

    function userManagemsentDeactivatePermission(permisssionResource: PermissionResource, SetUpAppGridConfig: Array<GridAppConfig>, 
    SubModuleName: string, UserManagementHeader: SecondaryHeader, GridData ?: GridConfig): void {
          try {
             if ( SetUpAppGridConfig && SetUpAppGridConfig.length > 0 ) {
                const uniqueName = ( UserManagementHeader && UserManagementHeader.hasOwnProperty('PageTitle') && 
                UserManagementHeader.PageTitle === 'Users') ? 'users' : 'roles';
                if ( uniqueName.toString().toLowerCase() === SubModuleName.toString().toLowerCase()) {
                    if ( GridData.hasOwnProperty('GridHeaderDefination') && GridData.GridHeaderDefination &&  
                         GridData.GridHeaderDefination.length > 0 ) {
                            GridData.GridHeaderDefination.forEach((HeaderConfig: GridHeaderConfig) => {
                                if ( HeaderConfig.headerName === 'Actions') {
                                    if ( HeaderConfig.hasOwnProperty('Activate')) {
                                        HeaderConfig['Activate'] = permisssionResource.IsActive;
                                    }
                                    if ( HeaderConfig.hasOwnProperty('Deactivate') ) {
                                        HeaderConfig['Deactivate'] = permisssionResource.IsActive;
                                    }
                                }
                            });
                    }
                }
             }
          } catch ( error ) {
              console.error(error);
          }
        }

   