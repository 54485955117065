
import { FormType } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { CustomFormField } from '../custom-form';
import { TransactionConfiguration } from '../transaction.interface';
import { EnableCustomFormFieldsConfig } from './create-transaction-config.interface';

export const EnableCustomFormFields: EnableCustomFormFieldsConfig = (
        TransactionCollection: TransactionConfiguration,  FormFields: FormType[], RouterQueryParams: any): any => {
        try {
            const FormDetails = TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder;
            const Modetype = RouterQueryParams['rowId'] ? 'edit' : 'create';
            // const Transaction = RouterQueryParams['transaction'];
            if (FormDetails.length > 0) {
                FormDetails.forEach(element => {
                    if (element.hasOwnProperty('CustomEnableField') && element.CustomEnableField) {
                        const CustomField = FormFields.findIndex(x => x.fieldType.SingleData.name === element.OdataKey);
                        if (CustomField > -1) {
                            if (Modetype === 'create') {
                                FormFields[CustomField].fieldType.SingleData.disbaledProperty = element.CustomEnableField.Enable;
                            }
                        }
                    }
                });
                return FormFields;
            }
        } catch (Error) {
            console.error('Error occurred FetchGridProperties', Error);
        }
    };