import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { CustomErrorMesssage } from '../../form-validation';
@Component({
  selector: 'lib-multi-select-action',
  templateUrl: './multi-select-action.component.html',
  styleUrls: ['./multi-select-action.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectActionComponent implements OnInit, AfterViewInit {

  @Input() field: any = {};
  @Input() form: FormGroup;
  fieldType: string;
  formFieldName: any;
  formFieldlabel: any;
  formArrayName: any;
  formFieldHeader: string;
  errorMessage: any;
  showOptions = false;
  selectedList: any = [];
  displayControl: FormControl = new FormControl();
  @ViewChild('listOption') listOption: ElementRef;
  get isValid(): any { return this.displayControl.valid; }
  get isDirty(): any { return this.displayControl.dirty; }
  formFieldToolTip: string;
  isRequired: boolean;
  constructor(public AgyDynamicFormService: AgyDynamicFormServices) {
  }

  ngOnInit(): void {
    this.fieldType = Object.keys(this.field.fieldType).toString();
    const formField = this.AgyDynamicFormService.generateFormFieldName(this.field);
    this.formFieldName = formField.fieldName;
    this.formFieldlabel = formField.fieldLabel;
    this.formArrayName = formField.fieldName;
    this.formFieldHeader = formField.fieldHeader;
    const selectedId = [];
    this.selectedList = [];
    this.field.fieldType.SingleData.options.forEach(element => {
      if (this.field.fieldType.SingleData.value && this.field.fieldType.SingleData.value.includes(element.key)) {
        element.checked = true;
        selectedId.push(element.label);
        this.selectedList.push(element);
      } else {
        element.checked = false;
      }
    });
    if (selectedId.length > 0) {
      this.displayControl.setValue(selectedId.join(', '));
      this.displayControl.updateValueAndValidity();
    }
    if (this.field.fieldType.SingleData.FieldValidation) {
      this.field.fieldType.SingleData.FieldValidation.forEach(element => {
        this.displayControl.setValidators(element.validation);
      });
      this.displayControl.updateValueAndValidity();
    }
    this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
    ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
  }

  ngAfterViewInit(): void {
    this.isRequired = this.form.controls[this.formFieldName]?.validator ?
    !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
    this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
      this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field);
    });
  }
  addNew(): void {
    this.AgyDynamicFormService.formAddNewClick(this.field, this.form);
    this.showOptions = false;
  }
  displayFn(data): string {
    const _THAT = this;
    const datas = [];
    if (data && Array.isArray(data)) {
        _THAT.field.fieldType.SingleData.options.forEach((element, index) => {
        if (data.includes(element.key)) {
          datas.push(element.label);
        }
      });
    }
    return datas && datas.length > 0 ? datas.join(',') : '';
  }
  prevent(event): void {
    event.stopPropagation();
  }

  selected(data: any): void {
    data.checked = !data.checked;
    if (data.checked && !this.selectedList.includes(data)) {
      this.selectedList.push(data);
    } else {
      const index = this.selectedList.findIndex(x => x.key === data.key);
      this.selectedList.splice(index, 1);
    }
    let selectedItems = '';
    const selectedId = [];
    this.selectedList.forEach((element, index) => {
      if (element.checked) {
        selectedItems = selectedItems + element.label;
        if (index !== this.selectedList.length - 1) {
          selectedItems = selectedItems + ', ';
        }
        selectedId.push(element.key);
      }
    });
    this.displayControl.setValue(selectedItems);
    this.form.controls[this.formFieldName].setValue(selectedId);
  }

  inputChange(event: any): void {
    event.preventDefault();
  }
}
