import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GlobalSystemPreference, SystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceAction } from './preference-action';
import { SystemPreferenceConfig } from './system-preference-configuration';
// import * as PreferenceJson from '../../../../assets/json/systempreference/preferenceaction.json';
import { MatDialog } from '@angular/material/dialog';
import { TransactionConfiguration, TransSystemPreferenceConfig } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { FormGroup } from '@angular/forms';
export const PreferenceJson: Array<any> = [
    {
        Id: 1,
        PreferenceId: 121,
        PreferenceName: 'MaximumAllowedQuantityToPost',
        Action: 'enabledPopup'
    },
    {
        Id: 2,
        PreferenceId: 14,
        PreferenceName: 'POBackOrderOptions',
        Action: 'enabledPopup',
        ModuleName: 'ReceivingHead',
        ModuleKey: 'ReceivingHead',
        EnablePopUp: true
    },
    {
        Id: 3,
        PreferenceId: 101,
        PreferenceName: 'Preventreceivingexpiredlots',
        Action: 'enabledPopup'
    },
    {
        Id: 4,
        PreferenceId: 37,
        PreferenceName: 'BackOrderRequisitions',
        Action: 'enabledPopup',
        ModuleName: 'ReqHead',
        ModuleKey: 'ReqHead',
        EnablePopUp: true
    },
    {
        Id: 5,
        PreferenceId: 15,
        PreferenceType: 'AllowPOModification',
        ModuleKey: 'PurchaseOrderHead',
        Action: 'edit',
        PrefernceValid: false
    },
    {
        Id: 6,
        PreferenceId: 46,
        PreferenceType: 'LockpricecolumnwhenreceivingPO',
        ModuleKey: 'ReceivingHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['Valued', 'UnitCost']
    },
    {
        Id: 7,
        PreferenceId: 47,
        PreferenceType: 'Defaultnumberoftrackinglabelsforreceiving',
        ModuleKey: 'ReceivingHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['ItemLotLabelQty']
    },
    {
        Id: 8,
        PreferenceId: 48,
        PreferenceType: 'Defaultnumberoftrackinglabelsforproduction',
        ModuleKey: 'ProductionHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['ItemLotLabelQty']
    },
    {
        Id: 9,
        PreferenceId: 49,
        PreferenceType: 'ZeroquantityOKinPurchaseRequests',
        ModuleKey: 'PurchaseReqHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['Qty', 'UnitCost']
    },
    {
        Id: 10,
        PreferenceId: 50,
        PreferenceType: 'ZeroquantityOKintransactions',
        ModuleKey: 'ReceivingHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['Qty', 'UnitCost']
    },
    {
        Id: 11,
        PreferenceId: 51,
        PreferenceType: 'ZeroquantityOKintransactions',
        ModuleKey: 'ReqHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['Qty']
    },
    {
        Id: 12,
        PreferenceId: 52,
        PreferenceType: 'ZeroquantityOKintransactions',
        ModuleKey: 'ReqHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['Qty']
    },
    {
        Id: 13,
        PreferenceId: 53,
        PreferenceType: 'ZeroquantityOKintransactions',
        ModuleKey: 'SpoilageHead',
        Action: 'prepost',
        PrefernceValid: false,
        LookUpColumn: ['Qty']
    },
    {
        Id: 14,
        PreferenceId: 54,
        PreferenceType: 'AllowPOCancelling',
        ModuleKey: 'PurchaseOrderHead',
        Action: 'cancel',
        PrefernceValid: false
    }
    
];
export const PreferenceTypeConfig: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType ?: any,
    ModuleKey ?: string,
    dialog ?: MatDialog,
    transactionCollection ?: TransactionConfiguration,
    homeGroup ?: FormGroup
): any => {
        return new Promise<boolean>((resolve, reject) => {
            if (PreferenceData.length === 0) {
                resolve(true);
            } else {
                PreferenceData.forEach((preference: TransSystemPreferenceConfig, index, array) => {
                    ValidateSystemPreference(sysytemPreferenceService, preference.PreferenceType, 
                        preference, httpService, preference.ModuleKey, dialog, transactionCollection, 
                        homeGroup, PreferenceAction, GlobalPreference).then( function(response) {
                            preference.PrefernceValid = response;
                            // index === array.length - 1
                            if (array.every(x => x.PrefernceValid === true)) {
                                resolve(response);
                            }
                        });
                  });
            }
        });                

};

function ValidateSystemPreference(sysytemPreferenceService, PreferenceType, PreferenceData, httpService, ModuleKey, 
    dialog, transactionCollection, homeGroup, PreferenceAction, GlobalPreference) {
    const actionReturn = false;
    return new Promise<boolean>((resolve, reject) => {
        if ( sysytemPreferenceService && 
            sysytemPreferenceService.globalSystemPreference && 
            sysytemPreferenceService.globalSystemPreference.length > 0 ) {
                // const preferenceConfigData = PreferenceJson['default'];
                const preferenceConfigData = PreferenceJson;
                const PrefrenceConfigAction = [...preferenceConfigData];
                const GlobalPreferenceReAssign = [...sysytemPreferenceService.globalSystemPreference];
                switch ( PreferenceType ) {
                    case 'POBackOrderOptions':
                        SystemPreferenceAction.PoBackOrder( PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                         transactionCollection, homeGroup).then( function(response) {
                            resolve(response);
                         });
                      break;
                    case 'BackOrderRequisitions':
                        SystemPreferenceAction.BackOrderRequisitions( PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then( function(response) {
                                resolve(response);
                            });
                        break;
                    case 'AllowPOModification':
                        transactionCollection.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.
                        find(x => x.Id === 5).Enable = GlobalPreferenceReAssign.filter(x => x.LookupName ===
                            PreferenceType)[0].LookupValue === 1 ? true : false;
                        resolve(true);
                        break;
                    case 'AllowPOCancelling':
                        transactionCollection.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.
                        find(x => x.Id === 4).Enable = GlobalPreferenceReAssign.filter(x => x.LookupName ===
                            PreferenceType)[0].LookupValue === 1 ? true : false;
                        resolve(true);
                        break;
                    case 'Defaultnumberoftrackinglabelsforreceiving':
                        printLabelPreference(PreferenceData, transactionCollection, PreferenceType, 
                            GlobalPreferenceReAssign, PrefrenceConfigAction);
                        resolve(true);
                        break;
                    case 'Defaultnumberoftrackinglabelsforproduction':
                        printLabelPreference(PreferenceData, transactionCollection, PreferenceType, 
                            GlobalPreferenceReAssign, PrefrenceConfigAction);
                        resolve(true);
                        break;
                    case 'zeroCosting':
                        SystemPreferenceAction.ZeroCosting( PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then( function(response) {
                                resolve(response);
                            });
                        break;
                    case 'LockpricecolumnwhenreceivingPO':
                        if (GlobalPreferenceReAssign.filter(x => x.LookupName === PreferenceType)[0].LookupValue === 1) {
                            const lookupPreference = PrefrenceConfigAction.filter(x => x.PreferenceType === PreferenceType)[0];
                            const fieldConfigs = transactionCollection.CreateTranscationConfig.ItemsConfig.
                                                ItemList.ItemGridConfig.GridHeaderDefination;
    
                            if (lookupPreference && fieldConfigs) {
                                const lookUpColumnNames = lookupPreference.LookUpColumn;
                                lookUpColumnNames.filter(x => {
                                    if (transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                                        GridHeaderDefination.filter(y => y.field === x).length > 0) {
                                        transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                                            GridHeaderDefination.filter(y => y.field === x)[0].editable = false;
                                    }
                                });
                                transactionCollection = {...transactionCollection};
                            }
                        }
                        resolve(true);
                        break;
                    case 'ZeroquantityOKinPurchaseRequests':
                        SystemPreferenceAction.ZeroQtyPR( PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then( function(response) {
                                resolve(response);
                            });
                        break;
                    case 'ZeroquantityOKintransactions':
                        SystemPreferenceAction.ZeroQtyTransaction( PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then( function(response) {
                                resolve(response);
                            });
                        break;
                    case 'ZeroButchery':
                        SystemPreferenceAction.ZeroButchery(PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then(function (response) {
                                resolve(response);
                            });
                        break;
                    case 'NoZeroQuantityTransctions':
                        SystemPreferenceAction.NoZeroQuantityTransctions(PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then(function (response) {
                                resolve(response);
                            });
                        break;
                    case 'NoZeroQuantityCyclePlanning':
                        SystemPreferenceAction.NoZeroQuantityCyclePlanning(PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then(function (response) {
                                resolve(response);
                            });
                        break;
                    case 'NegativeQtyWithRemarks':
                        SystemPreferenceAction.NegativeQtyWithRemarks(PreferenceData, PrefrenceConfigAction, GlobalPreferenceReAssign,
                            httpService, sysytemPreferenceService, PreferenceType, ModuleKey, dialog,
                            transactionCollection, homeGroup).then(function (response) {
                                resolve(response);
                            });
                        break;
                    default:
                      // code block
                  }
        } else {
            resolve(true);
        }
    });
}

function printLabelPreference(PreferenceData, transactionCollection, PreferenceType, GlobalPreferenceReAssign, PrefrenceConfigAction) {
    if (PreferenceData.Action === 'edit') {
        // transactionCollection.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.
        // find(x => x.Id === 3).Enable = GlobalPreferenceReAssign.filter(x => x.LookupName ===
        //     PreferenceType)[0].LookupValue === 2 ? true : false;
    } else if (PreferenceData.Action === 'post' && GlobalPreferenceReAssign.filter
        (x => x.LookupName === PreferenceType)[0].LookupValue === 1) {
        const lookupPreference = PrefrenceConfigAction.filter(x => x.PreferenceType === PreferenceType)[0];
        const fieldConfigs = transactionCollection.CreateTranscationConfig.ItemsConfig.
                            ItemList.ItemGridConfig.GridHeaderDefination;

        if (lookupPreference && fieldConfigs) {
            const lookUpColumnNames = lookupPreference.LookUpColumn;
            lookUpColumnNames.filter(x => {
                if (transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                    GridHeaderDefination.filter(y => y.field === x).length > 0) {
                    transactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                        GridHeaderDefination.filter(y => y.field === x)[0].editable = true;
                }
            });
            transactionCollection = {...transactionCollection};
        }
    }
}

