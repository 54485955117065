import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { SpaUtilities } from '../../../../shared/utilities/spa-utilities';
import { SettingsService } from '../../../settings.service';
import { HttpServiceCall, HttpMethod } from '../../../../shared/service/http-call.service';
import * as GlobalConst from '../../../../shared/globalsContant';
import { BaseResponse } from '../../../../shared/business/shared.modals';

@Component({
  selector: 'app-spa-settings',
  templateUrl: './spa-settings.component.html',
  styleUrls: ['./spa-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpaSettingsComponent implements OnInit {
  spaSettingsFormGrp: UntypedFormGroup;
  roles: any[] = [];
  serviceGroups: any[] = [];
  classGroups: any[] = [];
  caption: any;
  ActionButton: string;
  IsReadOnly: boolean;
  activeTherapist: any;
  @Input() IsRoleSelected;
  @Input('filteredActiveTherapist')
  set filteredActiveTherapistValue(value){
    if(value)
    this.activeTherapist = value;
  }
  floatLabel:string;
  floatLabelNever:string;
  constructor(public localization: SpaLocalization, private _utilities: SpaUtilities,
    public _servicesetting: SettingsService, private http: HttpServiceCall) {
      this.floatLabel = this.localization.setFloatLabel;
      this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit() {
    this.caption = this.localization.captions;
    this.ActionButton = this.caption.setting.save;
    this.spaSettingsFormGrp = this._servicesetting.spaSettingsFormGrp;
    this.IsReadOnly = this._servicesetting.breakpoints.find(bp => bp.breakPointNumber == GlobalConst.SPAScheduleBreakPoint.UserSetup).view;
    if (this.IsReadOnly) {
      this._utilities.disableControls(this.spaSettingsFormGrp);
    }


    if (this._servicesetting.serviceGroups.length > 0) {
      if (this._servicesetting.serviceGroups) {
        this.serviceGroups.push({ id: 0, name: 'ALL' })
      }
      let serGrp = this._servicesetting.serviceGroups.map(x => { return { id: x.id, name: x.description } });
      this.serviceGroups.push(...serGrp);
      this._utilities.setUserAccessSettings(this.serviceGroups, this._servicesetting.selectedServiceGrp);
    }


    if (this._servicesetting.classGroups.length > 0) {
      if (this._servicesetting.classGroups) {
        this.classGroups.push({ id: 0, name: 'ALL' })
      }
      let serGrp = this._servicesetting.classGroups.map(x => { return { id: x.id, name: x.description } });
      this.classGroups.push(...serGrp);
      this._utilities.setUserAccessSettings(this.classGroups, this._servicesetting.selectedClassGrp);
    }



    this.roles = this._servicesetting.userRoles && this._servicesetting.userRoles.length > 0 ? this._servicesetting.userRoles.filter(x => x.active && x.productId.find(r => r === Number(this._utilities.GetPropertyInfo('ProductId')))).map(x => { return { id: x.id, name: x.description } }) : this.GetServiceCall('GetActiveUserRolesByPropertyId', GlobalConst.Host.authentication, { propertyId: Number(this._utilities.GetPropertyInfo('PropertyId')), includeInActive: false });
    let serviceSettingControl = this._servicesetting.spaSettingsFormGrp.controls;
    serviceSettingControl.autologoff.value ? serviceSettingControl.logoffafter.enable() : serviceSettingControl.logoffafter.disable();
  }

  ButtonToggle(ga, gv) {
    if (gv.id === 0) {
      this._servicesetting.selectedServiceGrp = this._utilities.getToggleAllFilter(this.serviceGroups, ga);
    } else {
      this._servicesetting.selectedServiceGrp = this._utilities.getToggleFilter(this.serviceGroups, ga, gv);
    }
    this._utilities.setUserAccessSettings(this.serviceGroups, this._servicesetting.selectedServiceGrp);
    this._servicesetting.isRadioButtonsChange = true;
  }


  ButtonClassToggle(ga, gv) {
    if (gv.id === 0) {
      this._servicesetting.selectedClassGrp = this._utilities.getToggleAllFilter(this.classGroups, ga);
    } else {
      this._servicesetting.selectedClassGrp = this._utilities.getToggleFilter(this.classGroups, ga, gv);
    }
    this._utilities.setUserAccessSettings(this.classGroups, this._servicesetting.selectedClassGrp);
    this._servicesetting.isRadioButtonsChange = true;
  }


  OnRoleChange(event) {
    this.IsRoleSelected = true;
    this._servicesetting.spaSettingsFormGrp.markAsDirty();
  }

  sliderChange(event, type?: string) {
    let serviceSettingControl = this._servicesetting.spaSettingsFormGrp.controls;
    if (type == 'ALO') {
      serviceSettingControl.autologoff.setValue(event[0]);
    }
    serviceSettingControl.autologoff.value ? serviceSettingControl.logoffafter.enable() : serviceSettingControl.logoffafter.disable();
  }

  TherapistScheduleViewToggle(event){
    console.log(event);
    if(event[0])
      this._servicesetting.spaSettingsFormGrp.get('usertherapist').addValidators(Validators.required);
    else
      this._servicesetting.spaSettingsFormGrp.get('usertherapist').clearValidators();

    this._servicesetting.spaSettingsFormGrp.get('usertherapist').updateValueAndValidity();
  }

  GetServiceCall(Route, Host, Uri?) {
    this.http.CallApiWithCallback<any>({
      host: Host,
      success: this.successCallback.bind(this),
      error: this._utilities.errorCallback.bind(this),
      callDesc: Route,
      uriParams: Uri,
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == 'GetActiveUserRolesByPropertyId') {
      if (result.result) {
        let data = <any>result.result;
        data = data.filter(x => x.productId.includes(Number(this._utilities.GetPropertyInfo('ProductId'))));
        this.roles = data.map(x => { return { id: x.id, name: x.description } });
      }
    }
    else if (callDesc == 'GetAllServiceGrp') {
      if (result.result) {
        var allgroups = <any>result.result;
        this._servicesetting.serviceGroups = allgroups.filter(t=>!t.isClassGroup);
        
    
        if (this._servicesetting.serviceGroups.length > 0) {
          this.serviceGroups.push({ id: 0, name: 'ALL' })
          let serGrp = this._servicesetting.serviceGroups.map(x => { return { id: x.id, name: x.description } });
          this.serviceGroups.push(...serGrp);
        }

      this._servicesetting.classGroups = allgroups.filter(t=>t.isClassGroup);

      if (this._servicesetting.classGroups.length > 0) {
        this.classGroups.push({ id: 0, name: 'ALL' })
        let classGrp = this._servicesetting.classGroups.map(x => { return { id: x.id, name: x.description } });
        this.classGroups.push(...classGrp);
      }

      }
    }

  }
}
