import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Localization } from './localization';

@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(private localization:Localization){

  }
  transform(value: any): any {
    return moment(value).format(this.localization.inputDateFormat);
  }

}
