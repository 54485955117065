import { Component, OnInit, Input } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { StaffReaderBoardBusiness } from '../staff-reader-board.business';
import { StaffReaderBoardDataService } from '../../dataservices/staff-reader-board-data.service';
import { API, UI, NoteSendToSelect } from '../../Models/staff-reader-board-model';

@Component({
  selector: 'app-view-note',
  templateUrl: './view-note.component.html',
  styleUrls: ['./view-note.component.scss'],
  providers:[StaffReaderBoardBusiness, StaffReaderBoardDataService]

})
export class ViewNoteComponent implements OnInit {

  captions: any;
  data: any;
  note: UI.ViewAllStaffReaderBoardDetails;
  priorityList: any;

  @Input('inputs')
  set fromType(value) {
    if (value) {
      this.data = value;
      this.GetAllStaffReaderBoardDetails();
    }
  }

  constructor(private localization: Localization, private staffReaderBoardBusiness: StaffReaderBoardBusiness) {
    this.captions = this.localization.captions;
    this.priorityList = this.staffReaderBoardBusiness.getPriorityList();
  }

  ngOnInit(): void {
  }

  async GetAllStaffReaderBoardDetails() {
    this.note = await this.staffReaderBoardBusiness.GetAllStaffReaderBoardDetails(this.data.id);
    this.note.validToDate = this.localization.LocalizeDate(this.note.validToDate) + ' ' + this.localization.LocalizeTime(this.note.validToDate);
    this.note.priorityName = this.priorityList.filter(y=> y.id == this.note.priority)[0].viewValue;
  }
}
