import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from '../../common/localization/retail-localization';

@Component({
  selector: 'app-gift-card-pinpopup',
  templateUrl: './gift-card-pinpopup.component.html',
  styleUrls: ['./gift-card-pinpopup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GiftCardPINPopupComponent implements AfterViewInit {
  captions:any;
  PinNumber: string = "";
  STD_PIN_LENGTH = 4;
  @ViewChild("pinNumber") pinNumber: ElementRef;
  floatLabel: string;

  constructor(
    public localization: RetailLocalization
    , public dialogRef: MatDialogRef<GiftCardPINPopupComponent>
  ) { 
    this.captions = localization.captions.shop.GiftCard;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngAfterViewInit() {
    if (this.pinNumber && this.pinNumber.nativeElement)
      this.pinNumber.nativeElement.focus();
  }

  closeDialog(){
    if (this.PinNumber && this.PinNumber.length == this.STD_PIN_LENGTH) {
      this.dialogRef.close(this.PinNumber);  
    }
  }

}
