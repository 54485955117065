import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { CreateTransaction } from 'src/app/eatecui/source/transaction/shared/interface/create-transcation';
import { GridTransaction } from 'src/app/eatecui/source/transaction/shared/interface/grid-transaction';
import { DetailsHeaderData, LookUpConfiguration, LookupConfiguration, TransactionConfiguration, TransactionTableData } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel, ItemFbProductModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import moment from 'moment';
import * as _ from 'lodash';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';
import { MasterGridManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master-grid.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysFbProductComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-fbproduct/agilysys-fbproduct.component';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';
import { FormGroup } from '@angular/forms';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { MiscWithTaxAmount } from 'src/app/eatecui/source/transaction/shared/interface/custom-form/auto-complete-search.interface';
import * as constants from '@shared/constant/GlobalConstants';
@Injectable({
  providedIn: 'root'
})
export class CreateTransactionService {

  public GridRowData = [];
  prefiledlistObject: object;
  params = {} as QueryParams;
  typeId = 1;
  previousSelectedLocation: any;
  // For Bug Fix - 232710
  RequisitionPrevLocationId = null;
  MiscChargesList: Array<any> = [];
  constructor(
    private translateService: TranslateService,
    private httpService: HttpService, private masterManagementService: MasterManagementService,
    private transactionViewService: ViewTransactionService,
    private dialog: MatDialog,
    private masterGridService: MasterGridManagementService,
    private transactionService: TransactionService,
    private systemPreference: SysytemPreferenceService
  ) { }

  dropdownOptions(DropDown: any, TransactionCollection: TransactionConfiguration,
     reArrangaeResponseData: any, selectedTransaction: string) {
    try {
        let locationId: any;
        switch (selectedTransaction) {
          case 'poopenrequisition': locationId = reArrangaeResponseData.FromLocId; break;
          case 'poopenpr':
          case 'allpurchaserequest':
          case 'openpurchaserequest':
            locationId = reArrangaeResponseData.RequestLocId; break;
          case 'purchaseorder': locationId = reArrangaeResponseData.LocationId; break;
        }
        const postObject = {
            'Skip': 0,
            'Take': 0,
            'FetchAll': true,
            'GetTotalRecCount': true,
            'TotalCount': 0,
            'LookupField': '',
            'OrderByColumn': '',
            'OrderByDirection': 0,
            'TransTypeId': 0,
            'LocationId': 0,
            'UserId': 0,
            'SiteId': 0,
            'Ids': 0,
            'IsActiveOnly': true,
            'SearchText': '',
            'FromLocationId': locationId
        };
        this.httpService.PosthttpMethod(DropDown['DropdownUrl'], postObject).subscribe(res => {
            let ReturnData = [];
            if (TransactionCollection.PostEntityName.toLowerCase() === 'purchaseorderhead') {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate
                    };
                });
            } else {
                ReturnData = res.map(result => {
                    return {
                        Id: result.Id,
                        Name: result.Name,
                        SendVia: result.SendVia,
                        CurrencyId: result.CurrencyId,
                        ExchangeRate: result.ExchangeRate,
                        CurrencyName: result.CurrencyName
                    };
                });
            }
            const data = { 'Id': 0, 'Name': 'None' };
            ReturnData.unshift(data);
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridItemListAction.DropdownOptions
                = ReturnData;
        });
        return [];
    } catch (error) {
        console.error('Error occurred in dropdownOptions', error);
    }
}

  searchTextChange(searchText: string, GridRowData: Array<any>) {
    if (searchText.length > 0) {
      const returnData = GridRowData.filter(x => x.Name.toLowerCase().includes(searchText.toLowerCase()));
      return returnData;
    } else {
      return this.GridRowData;
    }
  }

  filterApplied(value, GridRowData: Array<any>) {
    console.log('Filter Applied', value);
  }

  itemListHeaderDataRemap(
    entityName: string, productremapJson: Array<any>, detailsHeaderData: DetailsHeaderData[]): Array<DetailsHeaderData> {
    try {
      if (detailsHeaderData.length > 0) {
        detailsHeaderData.forEach((x: DetailsHeaderData) => {
          x.DisplayName = ProductDataRemap.FieldTranslateRemapEngine(productremapJson, entityName, x.DisplayName);
        });
        return detailsHeaderData;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async lookUpPopUpOpen(transactionJsonData: TransactionConfiguration, ItemLookUpConfig: LookUpConfiguration[], value, dialog,
    OdataGlobalEntityModel) {
    return await CreateTransaction.LookUpPopupCOnfig
      (transactionJsonData, ItemLookUpConfig, value, dialog,
        OdataGlobalEntityModel, this.httpService, this.translateService);
  }

  AssignBidsOptions(transactionData: TransactionConfiguration) {
    if (transactionData.CreateTranscationConfig.ItemsConfig.ItemAssignBids.FormGroupData) {
      const ExpiredBids = transactionData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ExpiredBids'] ? '1' : '0';
      const Priority = transactionData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['Priority'] ? '1' : '0';
      const SkipZero = transactionData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['SkipZero'] ? '1' : '0';
      const ClearPrices = transactionData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ClearPrices'] ? '1' : '0';
      const ClearSupplier = transactionData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['ClearSupplier'] ? '1' : '0';
      const BidRegion = transactionData.CreateTranscationConfig.ItemsConfig
        .ItemAssignBids.FormGroupData['BidRegion'] ? '1' : '0';
      const options = ExpiredBids + Priority + SkipZero + ClearPrices + ClearSupplier + BidRegion;
      return options;
    } else {
      return '011000';
    }
  }

  constructItemDataFromCart(transactionData: TransactionConfiguration) {
    try {
      const lookUpData = {} as LookupConfiguration;
      let LocId, options;
      const GridData = transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
      if (GridData.length > 0) {
        lookUpData.TransType = (transactionData && transactionData.TransactionType) ? transactionData.TransactionType : 1;
        if (transactionData && transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData) {
          const ModuleName = (transactionData && transactionData.Name) ? transactionData.Name : undefined;
          switch (ModuleName) {
            case 'Requisition':
              LocId = transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
              break;
            case 'Purchase Request':
              LocId = transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['RequestLocId'];
              options = this.AssignBidsOptions(transactionData);
              break;
            case 'Purchase Order':
              LocId = transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
              options = this.AssignBidsOptions(transactionData);
              break;
            case 'Production':
            case 'Receiving':
              LocId = transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['LocationId'];
              break;
          }
        }
        lookUpData.Id = 0;
        lookUpData.SupplierId = 0;
        lookUpData.PostDate = (transactionData && transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate']) ? 
        transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['PostDate'] : null;
        lookUpData.FromLocationId = LocId;
        lookUpData.ToLocationId =
          (transactionData && transactionData.CreateTranscationConfig
            && transactionData.CreateTranscationConfig.TransactionFormConfig
            && transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData
            && transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId']) ?
            transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'] : undefined;
        lookUpData.TypeIds = [];
        lookUpData.PackageIds = [];
        lookUpData.ClassIds = [];
        lookUpData.options = options;
        lookUpData.flgSearch = false;
        lookUpData.selectedIngredients = GridData.map(x => { return x.IngredientId });
        const GetDataByIdUrl = `/inventory/api/IngredientLookup`;
        this.httpService.PosthttpMethod(GetDataByIdUrl, lookUpData).subscribe(res => {
          if (res) {
            const Data = res['Data'] ? res['Data']['ent']['ResultData'] : res['ResultData'];
            let itemListData = GridTransaction.GridItemListConfiguration(transactionData, Data,
              this.transactionService.currencyList, null, null, this.systemPreference);
            itemListData.forEach(rowData => {
              const currentItem = GridData.filter(x => x.IngredientId === rowData.IngredientId)[0];
              rowData.Qty = currentItem ? 
              (parseFloat(rowData.Conversion) && currentItem.Qty ? (currentItem.Qty / rowData.Conversion) : 0) : null;
              rowData.Valued = rowData.Qty && rowData.UnitCost && parseFloat(rowData.Qty) > 0 &&
              parseFloat(rowData.UnitCost) > 0 ? rowData.Qty * rowData.UnitCost : 0;
              if (transactionData.Name === 'Production') {
                if (rowData.hasOwnProperty('FGRecipes') && rowData.FGRecipes
                  && rowData.FGRecipes.length > 0) {
                  if (rowData.hasOwnProperty('ProdItemId') && rowData.ProdItemId !== null) {
                    const selectedFgItem = rowData.FGRecipes
                      .filter(x => x.Id === parseInt(rowData.ProdItemId, 10));
                    if (selectedFgItem && selectedFgItem.length > 0) {
                      const BlanketQty = selectedFgItem[0].BatchSizeQuantity;
                      if (BlanketQty) {
                        rowData.Batches = (rowData.Qty) / BlanketQty;
                      }
                    }
                  } else if (rowData.hasOwnProperty('BlanketQty')) {
                    if (rowData.BlanketQty && rowData.BlanketQty !== null) {
                      rowData.Batches = (rowData.Qty) / rowData.BlanketQty;
                    }
                  }
                }
              }
            });
            transactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded(itemListData, true);
            transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [
              ...transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
            transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = {
              ...transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig};
          }
        });
      }
    } catch (error) {
      console.log('Error occurred in constructItemDataFromCart', error);
    }
  }

  async constractItemDataPrefiled(transactionData: TransactionConfiguration, InventoryIds: Array<any> = null, IsEditMode = false) {
    try {
      const filedDataObject = transactionData.CreateTranscationConfig.TransactionFormConfig.FormGroupData;      
      if (transactionData.CreateTranscationConfig.ItemsConfig.hasOwnProperty('PreFiledItemList') &&
        transactionData.CreateTranscationConfig.ItemsConfig.PreFiledItemList &&
        transactionData.CreateTranscationConfig.ItemsConfig.PreFiledItemList.EnabledPreFiled &&  
        ((JSON.stringify(this.prefiledlistObject) !== JSON.stringify(filedDataObject) && filedDataObject['TypeId'] !== 4)
          || InventoryIds !== null)) {
        const preFiledOdataKey = transactionData.CreateTranscationConfig.ItemsConfig.PreFiledItemList.OdataKey;
        // const endPointUrl = GetEndPointUrl(PreFiledViewModelEntity,
        // PreFiledViewModelEntity, transactionData, this.httpService, this.params, this.typeId);
        const endPointUrl = `/inventory/api/${preFiledOdataKey}`;
        // const date = new Date(filedDataObject['PostDate']);
        // const newdate = new Date();
        // let postDate: any;
        // eslint-disable-next-line max-len
        // const utcstartdate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
        
        // const currentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), newdate.getHours(), newdate.getMinutes(), newdate.getSeconds());
        // if (isNaN(currentDate.getTime())) {
        //   postDate = null;
        // } else {
        //   const utcstartdate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).format();
        //   postDate = (date) ? utcstartdate : null;
        // }
        const postDate = moment(filedDataObject['PostDate']).isValid() ? moment(filedDataObject['PostDate']).format(constants.PostDateTimeFormat) : null;
        const sampleObject = {
          LocationId: filedDataObject['LocationId'],
          Packages: filedDataObject['Package'] ? [filedDataObject['Package']] : [],
          Types: filedDataObject['Type'] ? filedDataObject['Type'] : [],
          Classes: filedDataObject['Class'] ? filedDataObject['Class'] : [],
          Forms: 'aaa',
          FunctionId: filedDataObject['FunctionId'],
          ActionId: filedDataObject['ActionId'],
          IsLocationActiveOnly: IsEditMode ? false : filedDataObject['IsLocationActiveOnly'],
          IsNonZeroOnly: IsEditMode ? false : filedDataObject['IsNonZeroOnly'],
          PostDate: postDate,
          IsPostAdjustment: filedDataObject['IsPostAdjustment'],
          NonReportedItems: IsEditMode ? false : filedDataObject['NonReportedItems'],
        };
        if (InventoryIds !== null && InventoryIds.length > 0) {
          sampleObject['InventoryId'] = InventoryIds;
        }
        if (InventoryIds === null || (InventoryIds && InventoryIds.length === 0)) {
          if (transactionData.ActionConfig.length > 0 && transactionData.ActionConfig.filter(
            x => x.ActionKey === 'No Math')[0].ActionPopupConfig.PopupInputData['NomathDetails']) {
            transactionData.ActionConfig[0].ActionPopupConfig.PopupInputData['NomathDetails'] = [];
          }
        }
        // if (filedDataObject['TypeId'] !== 4) {
        this.httpService.PosthttpMethod(endPointUrl, sampleObject).subscribe(async x => {
          console.log(x);
          this.prefiledlistObject = _.cloneDeep(filedDataObject);
          const Data = x['Data']['resultEntity'];
          let itemListData = GridTransaction.GridItemListConfiguration(transactionData, Data.ItemList,
            null, null, null, this.systemPreference);
          if (itemListData.length === 0 && filedDataObject['IsPostAdjustment']) {
            transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [];
            transactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded([]);
            const ConfirmPopUpDialog: ErrorPopModel = {
              PopupHeaderContent: 'Message',
              PopUpContent: 'No items found needing adjustments',
            };
            const dialogRef = this.dialog.open(AgilsysErrorPopComponent, {
              width: '400px',
              height: '222px',
              data: ConfirmPopUpDialog,
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result === 'Confirm') {
                console.log('Confirm');
              }
            });
          } else {
            if (InventoryIds !== null && InventoryIds.length > 0) {
              const concatlistItem = itemListData;
              itemListData = concatlistItem;
            }
            const IsEmpty = InventoryIds === null || (InventoryIds && InventoryIds.length === 0) ? true : false;
            const FbItemlistData = itemListData;
            const GetFbProdcutItem =  FbItemlistData.filter( y => y.hasOwnProperty('IsFGItem') && y.IsFGItem );
            if ( GetFbProdcutItem && GetFbProdcutItem.length > 0 ) {
              GetFbProdcutItem.map(n => n['Checked'] = true);
                const fgDataItem = await this.checkFbProductItem(GetFbProdcutItem);
                if ( fgDataItem && fgDataItem.length > 0 ) {
                    const GetFgItemId: Array<any> = fgDataItem.map( z => z.Id ? z.Id : z.IngredientId);
                    const GetFilteredList = itemListData
                    .filter(e => !(GetFgItemId.findIndex(f => f === (e.Id ? e.Id : e.IngredientId)) > -1) );
                    GetFilteredList.map(n => n['Checked'] = false);
                    itemListData = [...GetFilteredList];
                } else {
                  itemListData.map(e => e['Checked'] = false);
                }

            }
            if (!IsEditMode) {
              transactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded(itemListData, IsEmpty);
            } else {
              const dataList = transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
              dataList.forEach(rowData => {
                const Index = itemListData.findIndex(ing => ing.IngredientId === rowData.IngredientId);
                if (Index !== -1) {
                  rowData.QtyOnHand = Formater('QtyOnHand', itemListData[Index].QtyOnHand, null, null);
                }
              });
              transactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.ItemAdded(dataList, true);
              transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData = [
                ...transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData];
              transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig = {
                  ...transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig};
            }
          }
        });
        // }
      } else if ((transactionData.OdataKey === 'PurchaseOrderHead' || transactionData.OdataKey === 'ReceivingHead' ||
        transactionData.OdataKey === 'ReqHead' || transactionData.OdataKey === 'PurchaseReqHead')
        && (this.allowLoadOrderGuide(this.prefiledlistObject, filedDataObject, transactionData))) {
        CreateTransaction.loadOrderGuide(transactionData, this.httpService, this.transactionService, this.dialog, this.systemPreference);
        this.prefiledlistObject = filedDataObject;
      }
    } catch (error) {
      console.error(error);
    }
  }
  checkFbProductItem(selectedItemList: Array<any>) {
    try {
      return new Promise<any>((resolve, reject) => {
          if (selectedItemList && selectedItemList.length > 0 ) {
            const ConfirmPopUpDialog: ItemFbProductModel = {
                PopupHeaderContent: 'Add FG Products',
                SelectedItem: selectedItemList
              };
            const dialogRef = this.dialog.open(AgilysysFbProductComponent, {
                width: '600px',
                height: '400px',
                data: ConfirmPopUpDialog,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
               const buttonKey = result[0];
                if (buttonKey === 'remove') {
                    resolve(result[1]);
                } else {
                  resolve(result[1]);
                }
            });                
        } else {
            resolve(true);
        }
      });
      
     
  } catch (error) {
      console.error(error);
  }
  }
  allowLoadOrderGuide(prefiledlistObject: any, filedDataObject: any, transactionData: TransactionConfiguration = null ) {
    try {
      let IsAllowed = true;
      if ( prefiledlistObject && filedDataObject && (prefiledlistObject['SupplierId'] === filedDataObject['SupplierId'])) {
        IsAllowed = false;
      }
      if ( transactionData.hasOwnProperty('LoadOrderGuide') ) {
        IsAllowed = transactionData.LoadOrderGuide;
      }
      if (transactionData.OdataKey === 'ReceivingHead' &&
       transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length > 0 &&
       (prefiledlistObject['SupplierId'] === filedDataObject['SupplierId']))  {
        IsAllowed = false;
      }
      // Bug Id: 197747
      if (transactionData.OdataKey === 'PurchaseOrderHead' &&
       transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData.length === 0 &&
       prefiledlistObject && filedDataObject &&
       (prefiledlistObject['SupplierId'] === filedDataObject['SupplierId']))  {
        IsAllowed = true;
      }
      return IsAllowed;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  accessCurrencyList(selectedtransaction: string): boolean {
    try {
      const currecncyListTransactions = [
        'allpurchaserequest', 
        'openpurchaserequest', 
        'purchaseorder', 
        'poopenrequisition', 
        'poopenpr'];
        const GetTransactionIndex = currecncyListTransactions.findIndex(x => x === selectedtransaction );
        return ( GetTransactionIndex > -1 ) ? true : false;
    } catch ( error ) {
      console.error(error);
    }
  }
  accessUnitList(selectedtransaction: string): boolean {
    try {
      const unitListTransactions = [
        'allpurchaserequest', 
        'openpurchaserequest', 
        'purchaseorder', 
        'poopenrequisition', 
        'poopenpr', 'receiving', 'savedreceiving', 'apvoucherrni'];
        const GetTransactionIndex = unitListTransactions.findIndex(x => x === selectedtransaction );
        return ( GetTransactionIndex > -1 ) ? true : false;
    } catch ( error ) {
      console.error(error);
    }
  }

  accessMiscCharges(selectedtransaction: string): boolean {
    try {
      const unitListTransactions = [
        'purchaseorder', 
        'poopenrequisition', 
        'poopenpr', 'receiving', 'savedreceiving'];
        const GetTransactionIndex = unitListTransactions.findIndex(x => x === selectedtransaction );
        return ( GetTransactionIndex > -1 ) ? true : false;
    } catch ( error ) {
      console.error(error);
    }
  }

  unitConversionUpdate(value) {
    const fromUnit = value.fromUnit;
    const toUnit = value.toUnit;
    const filter = `FromUnit eq ${fromUnit} and ToUnit eq ${toUnit}`;
    const GetDataFilter = encodeURIComponent(`${filter}`).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
    const UnitConversionparams: QueryParams = {
      count: true,
      top: InfiniteScrollItemCount,
      skip: 0,
      expand: '',
      select: '',
      filter: '',
      orderby: '',
      isactive: true,
      search: '',
      filterQuery: GetDataFilter,
      searchtext: ''
    };
    if (fromUnit !== undefined && toUnit !== undefined) {
      const GetDataByIdUrl = `/inventory/api/UnitConversions${this.masterGridService.formQueryParams(UnitConversionparams)}`;
      this.httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
        console.log('Unit Conversion', x, value);
        const rowNode = value.api.getRowNode(value.rowIndex);
        let quantity = value.data.Qty;
        const NewConversionValue = x['value'][0] ? x['value'][0].Conversion : 1;
        let NewConversion = NewConversionValue * parseFloat(value.data.Conversion.toString());
        const OldConversion = value.data.Conversion ? parseFloat(value.data.Conversion.toString()) : 0;
        quantity = (OldConversion * quantity) / NewConversion;
        rowNode.setDataValue('Conversion', NewConversion);
        const Conversiondata: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['Conversion'],
        };
        const Conversioninstances = value.api.getCellEditorInstances(Conversiondata);
        if (Conversioninstances.length > 0) {
          Conversioninstances[0].params.data.Valued = NewConversion;
          Conversioninstances[0].value = NewConversion;
          Conversioninstances[0].params.value = NewConversion;
          Conversioninstances[0].input.value = NewConversion;
        }
        let calCost: any = (NewConversion * value.data.CurrentCost);
        let calAmount = value.data.Valued;
        calAmount = calAmount.toString().split(',').join('');
        calCost = calCost.toString().split(',').join('');
        quantity = quantity ? quantity.toString().split(',').join('') : '';
        if (isNaN(calAmount)) {
          calAmount = 0;
        }
        if (isNaN(calCost)) {
          calCost = 0;
        }
        if (isNaN(quantity)) {
          calAmount = 0;
        }
        if (isNaN(NewConversion)) {
          NewConversion = 0;
        }
        if (NewConversion !== 0) {
          const prevVal = calCost / NewConversion;
          calCost = prevVal * NewConversion;
          calCost = calCost.toString().split(',').join('');
          calAmount = +calCost * +quantity;
          calAmount = Formater('Currency', calAmount, null, null);
        }
        rowNode.setDataValue('Price', calAmount);
        rowNode.setDataValue('Valued', calAmount);
        rowNode.setDataValue('UnitCost', calCost);

        const Amtdata: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['Valued'],
        };
        const Amtinstances = value.api.getCellEditorInstances(Amtdata);
        if (Amtinstances.length > 0) {
          Amtinstances[0].params.data.Valued = calAmount;
          Amtinstances[0].value = calAmount;
          Amtinstances[0].params.value = calAmount;
          Amtinstances[0].input.value = calAmount;
        }

        const Costdata: GetCellEditorInstancesParams = {
          rowNodes: [rowNode],
          columns: ['UnitCost'],
        };
        const Costinstances = value.api.getCellEditorInstances(Costdata);
        if (Costinstances.length > 0) {
          Costinstances[0].params.data.UnitCost = calCost;
          Costinstances[0].value = calCost;
          Costinstances[0].params.value = calCost;
          Costinstances[0].input.value = calCost;
        }

        const data: GetCellEditorInstancesParams = {
          rowNodes: [rowNode], // specify rows, or all rows by default
          columns: ['Qty'], // specify columns, or all columns by default
        };
        const instances = value.api.getCellEditorInstances(data);
        if (instances.length > 0) {
          instances[0].params.data.Qty = parseFloat((quantity).toString()).toString();
          instances[0].value = (quantity).toString();
          instances[0].params.value = (quantity).toString();
          instances[0].input.value = (quantity).toString();
        }
      });
    }
  }

  printLabelQuantityUpdate(value) {
    const rowNode = value.api.getRowNode(value.rowIndex);
    const qtyValue = value.data.Qty;
    rowNode.setDataValue('ItemLotLabelQty', qtyValue);
    const itemLot: GetCellEditorInstancesParams = {
      rowNodes: [rowNode],
      columns: ['ItemLotLabelQty'],
    };
    const itemLotInstances = value.api.getCellEditorInstances(itemLot);
    if (itemLotInstances.length > 0) {
      itemLotInstances[0].value = qtyValue;
      itemLotInstances[0].params.value = qtyValue;
      itemLotInstances[0].input.value = qtyValue;
    }
  }
  /***
   * @method allowTransactionFromAction()
   * @input transactionFrom
   * @output integer
   */
   allowTransactionFromAction(TransactionFrom: string): number {
      try {
         const transactionFromArray = ['query', 'attachedfiles', 'transactionitemaction', 'processpo', 'savepi', 'transferbackorder', 'shoppingPaste'];
         const transActionArrayIndex = transactionFromArray.findIndex(x => x === TransactionFrom );
         return transActionArrayIndex;
      } catch ( error ) {
        console.error(error);
      }
   }
     /***
   * @method allowTransactionFromAction()
   * @input transactionFrom
   * @output integer
   */
      allowSelectedTransaction(SelectedTranasction: string): number {
        try {
           const selectedTransactionArray = ['allpurchaserequest', 'openpurchaserequest', 'purchaseorder', 'poopenrequisition', 'poopenpr', 'receiving', 'savedreceiving', 'apvoucherrni'];
           const transctionArrayIndex = selectedTransactionArray.findIndex(x => x === SelectedTranasction );
           return transctionArrayIndex;
        } catch ( error ) {
          console.error(error);
        }
     }


     cascading(field: Array<FormType>, MasterDataCollection: any, httpService: HttpService, FieldGrouping: FormBuilderGroupKey, form: FormGroup ): void {
      try{
          const customerId = form.controls['CustomerId'].value;
          const conntactApiUrl = `/inventory/api/CustomerContacts?$filter=CustomerId eq ${customerId.Id} and IsActive eq true and FirstName ne null`;
          httpService.GethttpMethod(conntactApiUrl).subscribe(e=> {
              if( MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields?.length > 0 && e && e['value']) {
                  MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                     if( x.fieldType.SingleData.name === 'CustomerContactId' ) {
                      if( e['value'] && e['value'].length > 0 ){
                          const contactValue: Array<any> = e['value'];
                          const radioOPrion: Array<RadioOptions> = [];
                          contactValue.forEach(x=> {
                              const radiooption = {} as RadioOptions;
                              radiooption.key = x['Id'];
                              radiooption.label = x['LastName'] ? x['FirstName'] + ' ' + x['LastName'] : x['FirstName'];
                              radioOPrion.push(radiooption);
                          })
                          x.fieldType.SingleData.options = [...radioOPrion]
                          x.fieldType.SingleData.value = null;
                      } else {
                          x.fieldType.SingleData.options = [];
                      }
                     }
                  })
                  MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach(x => x.fieldType.SingleData.name);
              } else {
                MasterDataCollection?.CreateTranscationConfig?.TransactionFormConfig?.FormFields.forEach((x: FormType)=> {
                  if( x.fieldType.SingleData.name === 'CustomerContactId' ) {
                        x.fieldType.SingleData.options = [];
                    }
                });
            }
             console.log(MasterDataCollection)
          });
      } catch(error){
  
      }
  }


     expandIcon(expand: boolean) {
      try {
        const productHammer = document.getElementById('productHammer');
        const asideElement = document.getElementById('eatec-auth-aside');
        const layoutContainer = document.getElementById('layout-container');
        const detailsContainer = document.getElementById('details-container');
        const itemListContainer = document.getElementById('item-list-container');
        if (expand) {
          productHammer?.classList?.add('width-height-0-novisible');
          asideElement?.classList?.add('width-height-0-novisible');
          detailsContainer?.classList?.add('width-height-0-novisible');
          layoutContainer?.classList?.add('expanded-block');
          itemListContainer.classList.add('expanded-grid-block');
        } else {
          itemListContainer?.classList?.remove('expanded-grid-block');
          layoutContainer?.classList?.remove('expanded-block');
          detailsContainer?.classList?.remove('width-height-0-novisible');
          asideElement?.classList?.remove('width-height-0-novisible');
        productHammer?.classList?.remove('width-height-0-novisible');
        }
      } catch (error) {
        console.error('Error occurred in expand icon', error);
      }
     }

    
 async getMiscChargesList() {
  const MiscListResponse = await this.httpService.GethttpMethod('/inventory/api/MiscCharges?$filter=IsActive eq true').toPromise();
  this.MiscChargesList = MiscListResponse['value'];
 }


  /**
   * @method recalculateMiscValue
   * @param GridData 
   * @description Recalculate Misc Based on Tax Amount 1 & 2
   */
  recalculateMiscValue(GridData: Array<any>, homeGroup: FormGroup, TransactionCollection) {
    try {
      const TaxAmountWithMisc: Array<MiscWithTaxAmount> = [];
      let Response: Array<any> = this.MiscChargesList;
      const ExchangeRate = GridData[0]?.ExchangeRate;
      Response.forEach(element => {
        const TaxList1 = GridData.filter(x => x.TaxName1 === element.Name);
        const TaxAmount1 = TaxList1.reduce(function (prev, element) {
            return prev + (element.TaxAmount1 ? parseFloat(element.TaxAmount1) : 0);
        }, 0);
        const TaxList2 = GridData.filter(x => x.TaxName2 === element.Name);
        const TaxAmount2 = TaxList2.reduce(function (prev, element) {
            return prev + (element.TaxAmount2 ? parseFloat(element.TaxAmount2) : 0);
        }, 0);
        if (TaxList1.length > 0 || TaxList2.length > 0) {
            TaxAmountWithMisc.push({
                MiscId: element.Id,
                Amount: parseFloat((TaxAmount1 + TaxAmount2).toFixed(4)),
                AmountInFC: parseFloat((ExchangeRate * (TaxAmount1 + TaxAmount2)).toFixed(4))
            });
        }
    });
    const MiscValues = homeGroup.controls['ReceivingHeadOtherTotals'].value ?
       homeGroup.controls['ReceivingHeadOtherTotals'].value : [];
    TaxAmountWithMisc.forEach(misc => {
      const indexValue = MiscValues.findIndex(x => x.CategoryDetailId === misc.MiscId);
      if (indexValue !== -1) {
        MiscValues[indexValue].Amount = misc.Amount;
        MiscValues[indexValue].AmountInFC = misc.AmountInFC;
      } else {
        const MiscCharge = {
          Id: 0,
          CategoryId: 2,
          CategoryDetailId: misc.MiscId,
          AmountInFC:  misc.AmountInFC,
          Amount: misc.Amount                                 
        };
        MiscValues.push(MiscCharge);
      }
    });
    if (GridData.length === 0) {
      MiscValues.forEach(element => {
        element.Amount = 0;
        element.AmountInFC = 0;
      });
    }
    homeGroup.controls['ReceivingHeadOtherTotals'].setValue(MiscValues);
    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemMiscCharges.PostData = MiscValues;
    } catch (Error) {
      console.log('Error occurred in recalculateMiscValue', Error);
    }
  } 
  
  /**
   * @method MischargeCalculation
   * @input method None
   * @description misc charge caulate if value assign data
   */
  MischargeCalculation(transactionGridData: any ): any {
    try {
      const TaxAmountWithMisc: Array<MiscWithTaxAmount> = [];
      this.MiscChargesList.forEach(Miscelement => {
        let MiscTaxListCollection: Array<any> = []
        let TotalTaxAmount: number = 0;
        MiscTaxListCollection = transactionGridData.filter(x => x.TaxName1?.toLowerCase() === Miscelement?.Name.toLowerCase() || x.TaxName2?.toLowerCase() === Miscelement?.Name.toLowerCase());
        TotalTaxAmount = MiscTaxListCollection.reduce(function (prev, element) {
                 let MiscTaxValue = 0
                if( Miscelement.Name.toLowerCase() === element.TaxName1.toLowerCase() ) {
                    MiscTaxValue = ( element.TaxAmount1 ) ? parseFloat(element.TaxAmount1) : 0;
                } else if( Miscelement.Name.toLowerCase() === element.TaxName2.toLowerCase() ) {
                    MiscTaxValue = ( element.TaxAmount2 ) ? parseFloat(element.TaxAmount2) : 0
                } 
                return prev + MiscTaxValue;
        }, 0);
        if ( TotalTaxAmount ) {
            TaxAmountWithMisc.push({
                MiscId: Miscelement.Id,
                Amount: parseFloat(TotalTaxAmount.toFixed(4)),
                AmountInFC: parseFloat((1 * TotalTaxAmount ).toFixed(4)) // Exchange rate 1 
            });
        }
    });
    return TaxAmountWithMisc
    } catch(error){
      console.error(error);
    }
  }
}
