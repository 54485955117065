import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { AddCustomFilterAction, CustomIconList, MergeAgainstValue, MergedFields } from '../custom-action.interface';
import { GlobalCustomConfig } from '../global-custom-config.interface';

export const CustomMergeField: GlobalCustomConfig = (
  Name: string,
  Odatakey: string,
  FieldKey: string,
  ActionType: string,
  TypeName?: string,
  IsCustomUrl?: boolean,
  CustomUrl?: string,
  CustomFilter?: Array<AddCustomFilterAction>,
  IsCustomMerge?: boolean,
  MergerFields?: Array<MergedFields>,
  MergeSymbol?: any,
  ResponseObject?: Array<any>,
  TransaactionCollection?: TransactionConfiguration,
  MasterCollection?: MasterInventoryManagement,
  SetupCollection?: any,
  IsCustomIconMerge?: boolean,
  CustomIconsList?: Array<CustomIconList>,
  CustomIcons?: Array<any>,
  mergeAgainstValue ?: MergeAgainstValue
): string => {
  try {
    if (ResponseObject && Object.keys(ResponseObject).length > 0
      && ((ResponseObject.hasOwnProperty('Id') && ResponseObject['Id'] !== -1)
        || (ResponseObject.hasOwnProperty('id') && ResponseObject['id'] !== -1))) {
      let customValue = ResponseObject[FieldKey];
      if ( mergeAgainstValue && mergeAgainstValue !== null ) {
        if ( mergeAgainstValue.MergeAgainstDefaultValue === customValue) {
         customValue = mergeAgainstValue.MergeAgainstSetValue;
        }
     }
      if (MergerFields && MergerFields.length > 0) {
        const fieldValueArray = [];
        MergerFields.forEach((Fields: MergedFields) => {
          const fieldValue = ResponseObject[Fields.FieldKey];
          if (fieldValue && fieldValue !== undefined && fieldValue !== null) {
            const isArrayprop = Array.isArray(fieldValue);
            const propType = typeof fieldValue;
            if (propType === 'object' && !isArrayprop) {
              let getobjectValye = fieldValue[Fields.SecondaryKey];
              if (Fields.hasOwnProperty('Tertiary') && Fields.Tertiary !== undefined && Fields.Tertiary !== null) {
                if (fieldValue[Fields.Tertiary] && fieldValue[Fields.Tertiary] !== undefined &&
                  fieldValue[Fields.Tertiary] !== null) {
                  getobjectValye = fieldValue[Fields.Tertiary][Fields.TertiaryValue];
                  if (fieldValue[Fields.SecondaryKey] && fieldValue[Fields.SecondaryKey] !== null &&
                    fieldValue[Fields.SecondaryKey]) {
                    getobjectValye = getobjectValye + ` - ${fieldValue[Fields.SecondaryKey]}`;
                  }
                }
              }
              fieldValueArray.push(getobjectValye);
            } else {
              fieldValueArray.push(fieldValue);
            }

          }
        });
        if (fieldValueArray && fieldValueArray.length > 0) {
          customValue = fieldValueArray.toString();
          if (MergeSymbol && MergeSymbol !== null) {
            customValue = fieldValueArray.join(MergeSymbol).toString();
          }
          if (MergeSymbol && MergeSymbol !== null && MergeSymbol === '(') {
            customValue = customValue + ')';
          }
        }
      }
      return customValue;
    }

  } catch (error) {
    console.error(error);
  }
};