import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Localization } from '../../localization/localization';
import { AgFieldConfig } from '../../Models/ag-models';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';

@Component({
  selector: 'app-ag-currency',
  templateUrl: './ag-currency.component.html',
  styleUrls: ['./ag-currency.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AgCurrencyComponent  {

  @Output() inputChange = new EventEmitter();
  @Output() onInputBlur = new EventEmitter();
  customClass: string;
  errorMessage: string;
  groupName: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  maxDecimalLength: string | number;
  maxValue: number | string;
  minValue: number | string;
  showRequired: boolean;
  maxValueErrorMessage: string;
  minValueErrorMessage: string;
  allowNegative: boolean;
  automationId: string;
  placeHolderId: string;
  errorMessageId: string;
  readOnly: boolean;
  floatLabel: string;
  fieldUpdate: any;

  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    if(value){
      this.customClass = value.className? value.className: '';
    this.errorMessage = value.errorMessage;
    this.groupName = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.maxDecimalLength = value.maxlength;
    this.showRequired = value.isRequired ? value.isRequired : false;
    this.maxValue = value.maxValue;
    this.minValue = value.minValue;
    this.maxValueErrorMessage = value.maximumValueErrorMsg ? value.maximumValueErrorMsg : '';
    this.minValueErrorMessage = value.minimumValueErrorMsg ? value.minimumValueErrorMsg : '';
    this.allowNegative = value.allowNegative;
    this.automationId = value.automationId ? value.automationId: '';
    this.errorMessageId = value.errorMessageId;
    this.placeHolderId = value.placeHolderId;
    this.fieldUpdate=value.isfieldUpdate;

    if (value.disabled) {
      value.form.controls[value.formControlName].disable();
    } else {
      value.form.controls[value.formControlName].enable();
    }
    this.readOnly = value.readOnly ? value.readOnly : false;
    }
  }

  constructor(public dialog: MatDialog,public localization: Localization) { 
    this.floatLabel = this.localization.setFloatLabel;
  }

  inputChanged(event) {
    this.inputChange.emit(event);
  }
  onInputBlurChange(event){
    this.onInputBlur.emit(event);
  }
  onControlClick() {
    if (document.designMode == 'on') {
     this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: this.placeHolder,
          oldErrorMessage: this.errorMessage,
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result) {
          this.placeHolder = result.newplaceholder;
          this.errorMessage = result.newErrorMessage;
        }
      });
    }
  }
}
