import { Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Localization } from '../../localization/localization';
import { ConfigRouteCommon } from './config-route-common';
@Injectable()
export class SPAConfig extends ConfigRouteCommon {   
    constructor(public http: HttpClient, public locale: Localization) {
        super(http, locale);
    }
}
