import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { GridActionsService } from 'src/app/eatecui/source/shared/services/grid-actions.service';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { LookUpConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { LookUpPopupModel } from '../model/pop-up.interface';
import { AgilysysLookupPopupService } from './agilysys-lookup-popup.service';
import * as _ from 'lodash';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { UnitTypes } from 'src/app/eatecui/source/shared/enum/global.enum';

@Component({
  selector: 'app-agilysys-lookup-popup-model',
  templateUrl: './agilysys-lookup-popup-model.component.html',
  styleUrls: ['./agilysys-lookup-popup-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysLookupPopupModelComponent implements OnInit {

  agilysysLookUpButton: AgilysysButtonModule[];
  TabIndex = 0;
  supplierSelected: any;
  params = {} as QueryParams;
  cloneData: Array<any> = [];
  constructor(
    public dialogRef: MatDialogRef<AgilysysLookupPopupModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LookUpPopupModel,
    private agilysysLookupPopupService: AgilysysLookupPopupService,
    private gridActionService: GridActionsService,
    private httpService: HttpService) { 
      this.params = {
        count: true,
        skip: 0,
        expand: '',
        select: '',
        filter: '',
        orderby: '',
        isactive: true,
        search: '',
        filterQuery: '',
        searchtext: ''
    };
    this.cloneData = this.data.Content[1].GridConfiguration.GridData;
    }

  confirm(callBackData: CallBackData) {
    console.log('Confirm Clicked');
    const RowData = _.cloneDeep(this.data.RowData);
    const ReturnObject = {
      SupplierId: this.TabIndex === 0 ? this.supplierSelected.SupplierId : this.supplierSelected.Id,
      SupplierName: this.TabIndex === 0 ? this.supplierSelected.Suppliers : this.supplierSelected.Name,
      BidNumber: this.TabIndex === 0 ? this.supplierSelected.Number : this.supplierSelected.Number,
      MinOrder: this.TabIndex === 0 ? this.supplierSelected.Qty : this.data.RowData.MinOrder,
      BidId: this.TabIndex === 0 ? this.supplierSelected.Id : this.data.RowData.BidId,
      SendVia: this.TabIndex === 0 ? this.supplierSelected.SendVia : this.supplierSelected.SendVia,
      Price: this.TabIndex === 0 ? this.supplierSelected.Price : this.data.RowData.UnitCost,
      Conversion: this.TabIndex === 0 ? this.supplierSelected.Conversion : this.data.RowData.Conversion,
      UnitTypeId: this.TabIndex === 0 ? this.supplierSelected.UnitTypeId : this.data.RowData.UnitTypeId,
      UnitId: this.TabIndex === 0 ? this.supplierSelected.UnitId : this.data.RowData.UnitId,
      UnitCode: this.TabIndex === 0 ? this.supplierSelected.UnitCode : this.data.RowData.UnitCode,
      UnitName: this.TabIndex === 0 ? this.supplierSelected.IngredientBidsUnits : this.data.RowData.UnitName,
      CurrencyId: this.TabIndex === 0 ? this.supplierSelected.CurrencyId : this.supplierSelected.CurrencyId,
      ExchangeRate: this.supplierSelected.ExchangeRate
    };
    RowData.SupplierId = ReturnObject.SupplierId;
    RowData.SupplierName = ReturnObject.SupplierName;
    RowData.BidNumber = ReturnObject.BidNumber;
    RowData.MinOrder = ReturnObject.MinOrder;
    RowData.SendVia = ReturnObject.SendVia;
    RowData.UnitCost = ReturnObject.Price;
    RowData.Valued = RowData.UnitCost * RowData.Qty;
    RowData.UnitId =  ReturnObject.UnitId;
    RowData.UnitCode = ReturnObject.UnitCode;
    RowData.UnitName = ReturnObject.UnitName;
    RowData.UnitTypeId = ReturnObject.UnitTypeId ? ReturnObject.UnitTypeId : UnitTypes.PurchaseUnit;
    RowData.Conversion = ReturnObject.Conversion;
    RowData.Units = this.alterUnitArray(RowData);
    RowData.ForeignCurrencyId = ReturnObject.CurrencyId;
    const Currency = RowData.Currency.filter(x => x.Id === RowData.ForeignCurrencyId)[0];
    RowData.CurrencyCode = Currency ? Currency.Name : '';
    RowData.ExchangeRate = ReturnObject.ExchangeRate;
    RowData.UnitCostInForigenCurrency = RowData.UnitCost * ReturnObject.ExchangeRate;
    RowData.ValueInForeignCurrency = RowData.Valued * ReturnObject.ExchangeRate;
    this.dialogRef.close([callBackData.buttonValue.buttonKey, RowData]);
  }

  alterUnitArray(RowData) {
    try {
      const UnitArray = RowData.Units;
      let UnitAlreadyExsist = UnitArray.findIndex(x => x.UnitId === RowData.UnitId && x.UnitTypeId === RowData.UnitTypeId);
      UnitAlreadyExsist = UnitAlreadyExsist === -1 ?
        UnitArray.findIndex(x => x.UnitTypeId === UnitTypes.PurchaseUnit) : UnitAlreadyExsist;
      UnitArray[UnitAlreadyExsist].UnitCost = RowData.UnitCost;
      UnitArray[UnitAlreadyExsist].Conversion = RowData.Conversion;
      UnitArray[UnitAlreadyExsist].UnitCode = RowData.UnitCode;
      UnitArray[UnitAlreadyExsist].UnitName = RowData.UnitName;
      return UnitArray;
    } catch (error) {
      console.error('Error occurred in alterUnitArray', error);
    }
  }

  selectedTabValue(event) {
    console.log('Tab Change Event', event);
      this.data.Content[this.TabIndex].GridConfiguration.ClearSearchFilter = true;    
    setTimeout(() => {
      this.data.Content[this.TabIndex].GridConfiguration.ClearSearchFilter = false;    
    }, 100);
    this.params = {
      count: true,
      skip: 0,
      expand: '',
      select: '',
      filter: '',
      orderby: '',
      isactive: true,
      search: '',
      filterQuery: '',
      searchtext: ''
    };
    if (this.TabIndex === 1) {
        const postObject = {
            'Skip': 0,
            'Take': 0,
            'FetchAll': true,
            'GetTotalRecCount': true,
            'TotalCount': 0,
            'LookupField': '',
            'OrderByColumn': '',
            'OrderByDirection': 0,
            'TransTypeId': 0,
            'LocationId': 0,
            'UserId': 0,
            'SiteId': 0,
            'Ids': 0,
            'IsActiveOnly': true,
            'SearchText': '',
            'FromLocationId': this.data.LocationId
        };
      const EndPointUrl = `/inventory/api/v2/Getsuppliers`;
      this.httpService.PosthttpMethod(EndPointUrl, postObject).subscribe(res => {
        this.data.Content[this.TabIndex].GridConfiguration.GridData = [...res];
        this.data.Content[this.TabIndex].GridConfiguration = { ...this.data.Content[this.TabIndex].GridConfiguration };
        this.data.Content[this.TabIndex].GridConfiguration.GridActions.TotalItems =
         this.data.Content[this.TabIndex].GridConfiguration.GridData.length;
        this.cloneData = this.data.Content[1].GridConfiguration.GridData;
      });
    }
  }

  ngOnInit(): void {
    this.agilysysLookUpButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.confirm(callBackData);
        }
      }
    ];

    this.data.Content.forEach(data => {
      data.GridConfiguration.rowClicked = (value: any) => {
        console.log('Row clicked', value);
        this.supplierSelected = value.data;
      };
      data.GridConfiguration.searchTextChanged = (value: any) => {
        this.params.filter = '';
        this.searchTextChanged(data, value);
        console.log('searchTextChanged', value);
      };
      data.GridConfiguration.filterApplied = (value: any) => {
        console.log('filterApplied', value);
        this.params.filter = '';
        this.FilterApplied(data, value);
      };
    });
  }

  searchTextChanged (data: LookUpConfiguration, value: any) {
    let currentFilter = '';
    const IngredientId = this.data.IngredientId ? this.data.IngredientId :
    ( this.data.RowData.hasOwnProperty('IngredientId')) ? this.data.RowData.IngredientId :
    this.data.RowData.Id; 
    const seleId = data.OdataKey === 'IngredientsBids' ? `IngredientId eq ${IngredientId}` : '';
    let GetDataFilter = '';
    if (seleId) {
      GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
          return '%' + c.charCodeAt(0).toString(16);
      });
    }
    const searchFields = data.GridConfiguration.GridSearch.map(x => {
      return {
        OdataKey: x.ColumnName,
        columnName: x.SearchKey
      };
    });
    if (data.OdataKey === 'IngredientsBids') {
      currentFilter = this.gridActionService.formSearchQuery(searchFields, currentFilter, value, data.NavigationProperty);
      this.params.filterQuery = this.params.filterQuery.indexOf(GetDataFilter) > -1 ? this.params.filterQuery : GetDataFilter;
      this.params.expand = data.NavigationProperty.join();
      this.params.searchtext = value;
      this.params.search = currentFilter;
      const QuerysParams = this.gridActionService.formQueryParams(this.params);
      const endPointUrl = `/inventory/api/${data.OdataKey}${QuerysParams}`;
      this.httpService.GethttpMethod(endPointUrl).subscribe(res => {
        console.log('result', res);
        const resultData = this.agilysysLookupPopupService.assignRowData(res['value'], data.ViewModelProperty[0]);
        data.GridConfiguration.GridData = resultData;
        data.GridConfiguration.GridData = [...data.GridConfiguration.GridData];
        data.GridConfiguration.GridActions.TotalItems = data.GridConfiguration.GridData.length;
        data.GridConfiguration = {...data.GridConfiguration};
      });
    } else {
        const resultData = this.cloneData.filter(x => x.Name.toString().toLowerCase().includes(value.toLowerCase()));
        data.GridConfiguration.GridData = resultData;
        data.GridConfiguration.GridData = [...data.GridConfiguration.GridData];
        data.GridConfiguration.GridActions.TotalItems = data.GridConfiguration.GridData.length;
        data.GridConfiguration = {...data.GridConfiguration};
    }
  }

  FilterApplied (data: LookUpConfiguration, value: any) {
    let currentFilter = '';
    const seleId = data.OdataKey === 'IngredientsBids' ? `IngredientId eq ${this.data.RowData.Id}` : '';
    let GetDataFilter = '';
    if (seleId) {
      GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
          return '%' + c.charCodeAt(0).toString(16);
      });
    }
    data.GridConfiguration.GridSearch.map(x => {
      return {
        OdataKey: x.ColumnName,
        columnName: x.SearchKey
      };
    });
    currentFilter = this.gridActionService.formLookUpFilterQuery(value[0], currentFilter);
    this.params.filterQuery = GetDataFilter && currentFilter ? (GetDataFilter + ' and ' + currentFilter) :
    (currentFilter ? currentFilter : GetDataFilter);
    this.params.expand = data.NavigationProperty.join();
    this.params.searchtext = value;
    const QuerysParams = this.gridActionService.formQueryParams(this.params);
    const endPointUrl = `/inventory/api/${data.OdataKey}${QuerysParams}`;
    this.httpService.GethttpMethod(endPointUrl).subscribe(res => {
      console.log('result', res);
      const resultData = this.agilysysLookupPopupService.assignRowData(res['value'], data.ViewModelProperty[0]);
      data.GridConfiguration.GridData = resultData;
      data.GridConfiguration.GridData = [...data.GridConfiguration.GridData];
      data.GridConfiguration.GridActions.TotalItems = data.GridConfiguration.GridData.length;
      data.GridConfiguration = {...data.GridConfiguration};
    });
  }
}
