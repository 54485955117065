import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gridexpand'
})
export class GridExpandPipe implements PipeTransform {

  transform(value: any, args: any, cellIndex: number, levelArray ?: any, nodeLevel ?: any): unknown {
     const largestLevel = Math.max.apply(Math, levelArray);
    let returnValue = largestLevel * 40;
    if ( cellIndex === 0) {
        returnValue =  (largestLevel * 40) - args.level * 40  + nodeLevel;
    } else {
        returnValue =  0;
    }
    return returnValue;
  }

}
