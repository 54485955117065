
export interface ReportKeyword {
  id: number;
  name: string;
  description: string;
  groupId: number;
  groupNumber: string;
  isExpanded: boolean;
}

export interface ReportKeywordAPI {
  id: number;
  name: string;
  description: string;
}

export interface ReportTemplate {
  id: number;
  eventIds: number[];
  templateId: number;
  isDefault: boolean;
  listOrder: number;
  templateCode: string;
  templateName: string;
  eventName: string;
  checked?: boolean;
}

export interface ReportAPITemplate {
  id: number;
  eventIds: number[];
  templateId: number;
  isdefault: boolean;
  listorder: number;
  templateCode: string;
  templateName: string;
}

export interface ReportTemplateAPI {
  Id: number;
  EventId: number;
  TemplateId: number;
  IsDefault: boolean;
  IsActive: boolean;
  ListOrder: number;
  TemplateCode: string;
  TemplateName: string;
  TypeId: number;
}

export interface TemplateData {
templateName: string;
templateCode: string;
isDefault: boolean;
htmlContent: string;
templateId: number;
listOrder: number;
}


export interface TemplateDomainData {
eventIds: number[];
templateData: TemplateData;
}

export interface Events{
  id : number;
  eventName : string;
  productId : number;
  disabled?: boolean;
}

export enum EventReport
{
  GuestItenary=1,
  GroupItenary=2 
}


