import { Injectable } from '@angular/core';
import { InternalIntegrationSettingData } from 'src/app/common/constants';
import { SamsotechDataService } from 'src/app/common/dataservices/samsotech.data.service';
import { SamsotechIntegrationConfig, IntegrationFormSetting } from 'src/app/common/Models/common.models';

@Injectable()
export class SamsotechIntegrationBusinessService 
{

  constructor(private settingsDataService: SamsotechDataService) {
    
   }
  
  async GetSamsotechConfiguration():Promise<IntegrationFormSetting>
  {
     return this.settingsDataService.getSettingByModuleScreen(InternalIntegrationSettingData.InterfaceModuleName
      ,InternalIntegrationSettingData.ScreenNameSamsotechIntegration);
  }

  async SaveSamsotechConfiguration(formData : any ,isCreate:boolean, settingsId : number):Promise<IntegrationFormSetting[]>
  {
    var samsotechConfig : SamsotechIntegrationConfig = {
      enableSamsotech : formData.enableSamsotech.value,
      enableFlipCard: formData.enableFlipCard.value,
      scannerEndpointUrl : formData.samsotechScannerEndpoint.value,
      readChipEndpointUrl : formData.samsotechReadChipEndpoint.value,
    };

    let setting = [{
      id:  settingsId,
      moduleName: InternalIntegrationSettingData.InterfaceModuleName,
      screenName: InternalIntegrationSettingData.ScreenNameSamsotechIntegration,
      configValue: samsotechConfig,
    }];
    
    if(isCreate)
    {
      return this.settingsDataService.createSettings(setting);
    }
    else
    {
      return this.settingsDataService.updateSettings(setting);
    }    
  }
}


