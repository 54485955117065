import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AddNewListConfiguration, AddNewTypeConfig, TransactionConfiguration } from '../../transaction.interface';
import { CreateTransactionConfiguration } from '../create-transaction-config.interface';
import { AddNewListConfig, CreateTransactionItemList } from './item-transaction-configuration.interface';

export const    ItemAddNewList: AddNewListConfig = (
ViewHeaderEntity: any, 
ViewDetailsEntity: any, 
TransactionData: TransactionConfiguration, 
PostModelHeaderEntity: any,
PostModelDetailEntity: any,
translateService?: TranslateService,
): AddNewListConfiguration => {
    try {
        if ( TransactionData ) {
            const entityName = ( ViewHeaderEntity &&  ViewHeaderEntity.length > 0 ) ? ViewHeaderEntity[0]['$']['Name'].toString() : TransactionData.Name;
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            let addItemListConfig = TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig;
            if (TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.length > 0) {
                TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.forEach(
                (addNewType: AddNewTypeConfig) => {
                    ProductDataRemap.FormDisplayNameRemapEngine(
                    productremapJson, entityName, addNewType.DisplayName, translateService)
                   .then((data: any) => addNewType.DisplayName = data);
                    addNewType.Visible = ProductDataRemap.FieldVisibleRemapEngine(productremapJson, entityName, addNewType.Name,
                        !addNewType.Visible);  
                });
                addItemListConfig = TransactionData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig;
            }
            
            return addItemListConfig;
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};