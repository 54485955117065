import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons } from '../../form-type.entity';

@Component({
  selector: 'lib-file',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class DragDropComponent implements OnInit, AfterViewInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  formAddBuilderIcons: FormIcons;
  formRemoveBuilderIcons: FormIcons;
  formRemoveIcons: FormIcons;
  hidePassword: boolean;
  typeField: string;
  formFieldName: any;
  fieldType: any;
  formFieldlabel: any;
  formArrayName: any;
  formFieldHeader: string;
  errorMessage: string;
  rowIndex: number;
  validationMesage: string;
  files: any = [];
  fileArray: any = [];
  validFormats: Array<any> = [];
  fileFormats = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'video/mp4']; // , 'image/tiff' commented tiff format
  fileSizeLimit = 20971520;
  fileSizeExceeded = [];
  moreThanTwoVideos = [];
  get isValid(): any { return this.form.controls[this.formFieldName].valid; }
  get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }

  constructor(private agyDynamicFormServices: AgyDynamicFormServices, private domSanitizer: DomSanitizer,
    private toasterService: ToastrService) {
    this.hidePassword = false;
    this.typeField = 'text';
    this.rowIndex = 0;
  }

  ngAfterViewInit(): void {
    this.form.get(this.formFieldName).valueChanges.subscribe(
      (selectedValue) => {
        if (selectedValue && selectedValue !== null) {
        this.files = [];
        selectedValue.map((x: any) => x.Picture =
        (x.Picture.hasOwnProperty('changingThisBreaksApplicationSecurity')) ? x.Picture : this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${x.Picture}`));
        this.files = selectedValue;
      }
    });
  }

  ngOnChange(): void {
    console.log(this.field.value);
  }
  readFileBinary(UploadedFile: any): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(UploadedFile);
        fileReader.onload = (fileLoaded) => {
          return resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    } catch (error) {
      console.error(error);
    }
  }
  // tslint:disable-next-line:typedef
  async uploadFile(event: any) {
    let eventList = [];
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if (this.fileFormats.includes(event[index].type)) {
        const fileExtension = this.fileExtension(event[index].name);
        if (fileExtension) {
        const fileExceed = this.fileSize(event[index].type, event[index].size);
        if (!fileExceed) {
          const filedata = await this.readFileBinary(element);
          // const imageBaseConvert = this.domSanitizer.sanitize(SecurityContext.HTML, this.domSanitizer.bypassSecurityTrustHtml(filedata));
          const pictureObj = {};
          // tslint:disable-next-line:no-string-literal
          pictureObj['Type'] = event[index].type;
          pictureObj['Picture'] = ( event[index].type.split('/')[0] !== 'video') ?
          filedata.split(';base64,')[1] : await this.thumnailGenaerate('assets/images/video.png');
          if (this.files && this.files.length > 0) {
            const TwoVideos = this.files.filter(x => x.Type === 'video/mp4').length === 1 ? true : false;
            if (TwoVideos  && event[index].type === 'video/mp4') {
              this.moreThanTwoVideos.push(true);
            } else {
              eventList.push(event[index]);
              this.files.push(pictureObj);
            }
           } else {
              eventList.push(event[index]);
              this.files.push(pictureObj);
           }
        } else {
          this.fileSizeExceeded.push(true);
        }
      } else {
        this.validFormats.push(false);
      }
      } else {
        this.validFormats.push(false);
      }
    }
    this.form.controls[this.formFieldName].setValue(this.files);
    if (this.validFormats.filter(x => x === false).length > 0) {
      alert('Invalid File Formats Uploaded');
      this.validFormats = [];
    }
    if (this.fileSizeExceeded.filter(x => x === true).length > 0) {
      alert('One or more file(s) has exceeded allowed upload file size.');
      this.fileSizeExceeded = [];
    }
    if (this.moreThanTwoVideos.filter(x => x === true).length > 0) {
      this.toasterService.error('Video atatchment already exist.', '', 
      {
        closeButton: true,
        timeOut: 3000
      });
      this.moreThanTwoVideos = [];
    }
    const eventAction = {
      actionType: 'upload',
      FileEvent: eventList
    };
    this.agyDynamicFormServices.formEventCallback(this.field, eventAction, this.form);
    this.fileInput.nativeElement.value = '';
  }
  viewMoreImage(formField: any): void {
    this.agyDynamicFormServices.formEventCallback(this.field, 1, this.form);
  }

  deleteAttachment(index): void {
    this.files.splice(index, 1);
    this.form.controls[this.formFieldName].setValue(this.files);
  }
  thumnailGenaerate( assetUrl: string ): any {
    try {
        if ( assetUrl) {
            return new Promise(resolve => {
                const that = this;
                const xhr = new XMLHttpRequest();
                xhr.open('GET', assetUrl, true);
                xhr.responseType = 'blob';
                xhr.onload = (e) => {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                           const res = event.target.result;
                           const base64String = res.toString().split('base64,')[1];
                           resolve(base64String);
                           return base64String;
                        };
                        const file = xhr.response;
                        reader.readAsDataURL(file);
                };
                xhr.send();
              });
        }
    } catch (error) {
        console.error(error);
    }
}
 fileSize = (FileFormat: string, FileSize: number): boolean => {
  const imageSizeLimit = 2097152;
  const videoSizeLimit = 20971520;
  try {
      let FileExceed = false;
      const GetType = FileFormat.split('/')[0];
      if ( GetType === 'video' ) {
          FileExceed = ( FileSize > videoSizeLimit ) ? true : false;
      } else if ( GetType === 'image' ) {
          FileExceed = ( FileSize > imageSizeLimit ) ? true : false;
      } else {
          FileExceed = false;
      }
      return FileExceed;
  } catch ( error ) {
      console.error(error);
  }
}
fileExtension = (FileName: string): boolean => {
  try {
    let fileExtensionAllowed = true;
    let notAllowedExtension = ['.jfif'];
    if (FileName.length > 0) {
      for (let j = 0; j < notAllowedExtension.length; j++) {
        let sCurExtension = notAllowedExtension[j];
        if (FileName.substring(FileName.length - sCurExtension.length, FileName.length).toLowerCase() === sCurExtension.toLowerCase()) {
          fileExtensionAllowed = false
          break;
        }
      }
    }
    return fileExtensionAllowed;
  } catch (error) {
    console.error(error);
  }
}
  ngOnInit(): void {
    this.fieldType = Object.keys(this.field.fieldType).toString();
    const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
    this.formFieldName = formField.fieldName;
    this.formFieldlabel = formField.fieldLabel;
    this.formArrayName = formField.fieldName;
    this.formFieldHeader = formField.fieldHeader;
    if (
      this.form &&
      this.form.controls &&
      this.form.controls[this.formFieldName].value &&
      this.form.controls[this.formFieldName].value.length > 0) {
      this.files = [];
      this.form.controls[this.formFieldName].value
        .map((x: any) => x.Picture = ( x.Picture.hasOwnProperty('changingThisBreaksApplicationSecurity')) ?
        x.Picture : this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${x.Picture}`));
      this.files = [...this.form.controls[this.formFieldName].value];
    }
  }
}
