import { DefineDataObjectConfig } from "../construct-config.interface";
import { CommonService } from "src/app/eatecui/source/shared/services/common.service";
import { ViewTransactionService } from "src/app/eatecui/source/transaction/view-transaction/view-transaction.service";
import { SysytemPreferenceService } from "src/app/eatecui/source/setup/system-preference/system-preference.service";
import { RequestEngine } from "src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine";
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { RequestPagination } from "src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination";
import { TransactionConfiguration } from "src/app/eatecui/source/transaction/shared/interface/transaction.interface";
import { ModuleNames } from "src/app/eatecui/source/shared/enum/global.enum";
import { GetPackageLimitation } from "src/app/eatecui/source/shared/nonodatacore/models/requestdata.interface";
import { SetupConfiguration } from "src/app/eatecui/source/setup/shared/interface/setup.interface";
import moment from "moment";

export const GetPayloadConstract: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration | SetupConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, Value?: any): any => {
        try {
            const ScrollItemCount = sessionStorage.getItem('appsetting') ? 
            JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid : constants.InfiniteScrollItemCount;   
            const orderByColumn = transactionService.setSortColumn(JsonData?.ViewTranscationConfig?.GridConfiguration?.GridSort);
            const sortDirection = transactionService.
            setSortDirection(JsonData?.ViewTranscationConfig?.GridConfiguration?.GridSort);
            const sortingData = new RequestPagination(orderByColumn, sortDirection, requestEngine.FetchAll,
            requestEngine.LocationIds );
            let requestPayload = sortingData.getPaginationPayload(0, ScrollItemCount);
            if (OdataKey !== ModuleNames.PackageLimitation) {
                const LoadFromTransactionFilter = sysytemPreferenceService?.globalSystemPreference?.
                filter(x => x.LookupName === 'LoadFromTransactionsFilter')[0].LookupValue;
                const allowFilter = true;
                if (LoadFromTransactionFilter > 1 && allowFilter) {
                    const selectedDays = (LoadFromTransactionFilter - 1) * 14;
                    requestPayload.fromDate = formatDateFilter(moment().subtract((selectedDays - 1), 'days'), 'FromDate');
                    requestPayload.toDate = formatDateFilter(moment(), 'ToDate');
                }
            }
            if( OdataKey === ModuleNames.SalesHead){
                requestPayload['SalesHeadId'] = 0;
            } else if (OdataKey === ModuleNames.PackageLimitation) {                
                delete requestPayload.siteId;
                delete requestPayload.userId;
                const pklimination = {} as GetPackageLimitation;
                const packageLimitation = Object.assign(requestPayload, pklimination)
                requestPayload = loggingIdentity(packageLimitation);
                
            }
            return requestPayload;
        } catch (error) {
            console.error('Error Occured in Create Event Post Data Construction -', error);
        }
    }

    /**
     * @method packageLimitationPayload
     * @input Base Payload
     * outPut Payload
     */
    function loggingIdentity<Type extends GetPackageLimitation>(arg: Type): Type {
        arg.IsFromMasterLimit = true;
        arg.SiteIds = [];
        arg.TypeIds = [];
        arg.IsActive = true;
        return arg;
      }

      function formatUTCDateFilter(DateValue, DateType: string) {
        try {
          let currentDate;
          if (DateType === 'ToDate') {
            currentDate = moment(DateValue).endOf('day').format();
          } else {
            currentDate =  moment(DateValue).startOf('day').format();
          }
          return currentDate;
        } catch (error) {
          console.error('Error occurred in formatDateFilter', error);    
        }
      }

      // function formatDateFilter(DateValue, DateType: string) {
      //   try {
      //     let currentDate;
      //     if (DateType === 'FromDate') {
      //       const FromDate = new Date(DateValue);
      //       currentDate = moment.utc([FromDate.getFullYear(), FromDate.getMonth(), FromDate.getDate(), 0, 0, 0]).format();
      //     } else {
      //       const ToDate = new Date(DateValue);
      //       currentDate =  moment.utc([ToDate.getFullYear(), ToDate.getMonth(), ToDate.getDate(), 23, 59, 59]).format();
      //     }
      //     return currentDate;
      //   } catch (error) {
      //     console.error('Error occurred in formatDateFilter', error);
    
      //   }
      // }

      function formatDateFilter(DateValue, DateType: string) {
        try {
          let currentDate;
          if (DateType === 'ToDate') {
            currentDate = moment(DateValue).endOf('day').format(constants.PostDateTimeFormat);
          } else {
            currentDate =  moment(DateValue).startOf('day').format(constants.PostDateTimeFormat);
          }
          return currentDate;
        } catch (error) {
          console.error('Error occurred in formatDateFilter', error);    
        }
      }

