import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicGridService } from '../agilysys-grid.service';
import { HeaderOverlayRef } from '../grid-header/header-overlay-ref';
import { ResizeRows } from '../interface/common';
import * as constants from '../shared/globalConstants';

@Component({
  selector: 'lib-resize-rows',
  templateUrl: './resize-rows.component.html',
  styleUrls: ['./resize-rows.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResizeRowsComponent implements OnInit {

  resizeRowsList: ResizeRows[] = constants.resizeRows;
  SelectedSize: ResizeRows;
  constructor(private dynamicGridService: DynamicGridService,
    public dialogRef: HeaderOverlayRef,
  ) { }

  ngOnInit(): void {
    const selectedSize = JSON.parse(localStorage.getItem(constants.rowHeightData));
    if (selectedSize) {
      this.SelectedSize = selectedSize;
    }
  }

  agInit(params) {
    console.log('Params', params);
  }

  /**
   * @method apply
   * @description After selecting the row height
   */
  apply() {
    try {
      this.dynamicGridService.resizeRows.next(this.SelectedSize);
      localStorage.setItem(constants.rowHeightData, JSON.stringify(this.SelectedSize));
      this.dialogRef.close();
    } catch (error) {
      console.error('Error occured in apply', error);
    }
  }

  /**
   * @method cancel
   * @description Set to default function
   */
  cancel() {
    try {
      this.SelectedSize = JSON.parse(localStorage.getItem(constants.defaultRowHeightData));
      this.dynamicGridService.resizeRows.next(this.SelectedSize);
      localStorage.setItem(constants.rowHeightData, JSON.stringify(this.SelectedSize));
      this.dialogRef.close();
    } catch (error) {
      console.error('Error occurred in cancel', console.error());
    }
  }

  /**
   * @method selectedSize
   * @params data
   * @description Click on icon to get the selected value
   */
  selectedSize(data) {
    try {
      this.SelectedSize = data;
    } catch (error) {
      console.error('Error occurred in selectedSize', data);
    }
  }
}
