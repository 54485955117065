<!-- <mat-form-field class="basic-select-dropdown" appearance="outline"> -->
    <!-- <mat-select [(value)]="defaultValue" (selectionChange)="dropdownChangeEvent($event)">
        <mat-option value="A">A</mat-option>
        <mat-option value="B">B</mat-option>   [(value)]="defaultValue"
    </mat-select> -->
    <select id="dropdowm-editor" class="select-style" [formControl]="dropdown" (keydown)="keyDown($event, params)" (change)="dropdownChangeEvent($event)">
        <!-- <option value="1">Volvo</option>
        <option value="2">Saab</option>
        <option value="3">Mercedes</option>
        <option value="4">Audi</option>
     ({{data.UnitTypeName}}) -->
        <option *ngFor="let data of dropdownOptions" [value]="data.UnitTypeId">
            {{data.UnitCode}} <span *ngIf="data.UnitTypeName">({{data.UnitTypeName}})</span>
        </option>
      </select>
<!-- </mat-form-field> -->