import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { select, Store } from '@ngrx/store';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button/public-api';
import { BatchExportPosModel, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { BatchExportGridData } from '../model/pop-up.interface';
import { odataInventorySuccess } from 'src/app/eatecui/source/store/selectors/odata-configuration.selector';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/eatecui/source/store/states/app-state';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridInterface } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface';
import { GridTableComponent } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { MasterGridManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master-grid.service';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import _ from 'lodash';
@Component({
  selector: 'app-agilysys-grid-popup',
  templateUrl: './agilysys-grid-popup.component.html',
  styleUrls: ['./agilysys-grid-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysGridPopupComponent implements OnInit, AfterViewInit {
  agilysysCofirmationButton: AgilysysButtonModel[];
  OdataGlobalEntityModel: OdataGlobalEntity;  
  MasterDataSuccess: Subscription;
  selectedIds: any[] = [];  
  skip = 0;
  lastIndex = 0;
  loadDataCount = 1000;
  @ViewChild('agGridTable', { static: false }) agGrid: GridTableComponent;
  params = {} as QueryParams;
  constructor(
    public dialogRef: MatDialogRef<AgilysysGridPopupComponent>,    
    @Inject(MAT_DIALOG_DATA) public batchExportGridData: BatchExportGridData,
    private store: Store<AppState>,
    private masterManagementService: MasterManagementService,
    private httpService: HttpService,
    private masterGridManagementService: MasterGridManagementService) { 
      this.agilysysCofirmationButton = [
        {
          displayProperity: false,
          displayName: 'Cancel',
          buttonKey: 'Cancel',
          buttonType: 'agilysys-button-secondary',
          buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
          },
        },
        {
          displayProperity: false,
          displayName: 'Export',
          buttonKey: 'Confirm',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
            
            const postObject: BatchExportPosModel = {
              ProductIds: this.selectedIds.map(function(x) { return x.Id; }),
              IsExport: true
            };
            this.dialogRef.close([callBackData.buttonValue.buttonKey, postObject]);
            
          },         
        }        
      ];
    }

  ngOnInit(): void {
    this.params.count = true;
    this.params.isactive = true;
    this.params.skip = 0;
    this.params.top = this.loadDataCount;
    this.params.expand = 'ProductsTypeId($select=Id,Name),ProductsClassId($select=Id,Name)';
    this.params.select = 'Id,Name,Number,TypeId,ClassId,Description,Price,IsExport';
    this.params.filterQuery = 'IsExport eq false';
    this.batchExportGridData.GridData['rowChecked'] = (value) => {
      console.log(value);
      this.selectedIds = value;
    }; 
    this.batchExportGridData.GridData['bottomReached'] = (value) => {
      this.skip = this.skip + this.loadDataCount;
      this.params.skip = this.params.skip + this.loadDataCount;
      const productexportUrl = `/inventory/api/Products` + '?$filter=IsActive eq true and IsExport eq false&$expand=ProductsTypeId($select=Id,Name),ProductsClassId($select=Id,Name)&$select=Id,Name,Number,TypeId,ClassId,Description,Price&$top=1000' + `&$skip=${this.skip}`;
      this.getRowDataList();
      // this.httpService.GethttpMethod(productexportUrl).subscribe(res => {
      //   const GridData = GridInterface.GridData(null, null,
      //     this.httpService, null, res['value']);
      //     if (this.skip && this.skip > 0) {
      //       this.batchExportGridData.GridData.GridData = [...this.batchExportGridData.GridData.GridData, ...GridData];
      //     } else {
      //       this.batchExportGridData.GridData.GridData = GridData;
      //     }
      //     this.batchExportGridData.GridData = {...this.batchExportGridData.GridData};
      // });
    };    
    this.batchExportGridData.GridData['searchTextChanged'] = (value) => {
      this.lastIndex = 0;
      let currentFilter = '';
      const searchFields = this.batchExportGridData.GridData.GridSearch.map(x => {
        return {
          OdateKey: x.SearchKey,
          columnName: x.SearchKey,
          ChildSearchKey: x.ChildSearchKey
        };
      });
      currentFilter = this.masterGridManagementService.formSearchQuery(searchFields, currentFilter, value);
      this.params.skip = 0;
      this.params.searchtext = value ? value + ' and IsExport eq false' : value;
      this.params.search = currentFilter;
      this.getRowDataList();
    };
    this.batchExportGridData.GridData['filterApplied'] = value => {
      this.lastIndex = 0;
      let currentFilter = '';
      this.batchExportGridData.GridData['GridConfig'] = {};
      this.batchExportGridData.GridData['GridConfig']['GridFilter'] = this.batchExportGridData.GridData.GridFilter;
      currentFilter = this.masterGridManagementService.formFilterQuery(value[0], currentFilter,
           this.batchExportGridData.GridData);
      this.params.filterQuery = currentFilter ? currentFilter + ' and IsExport eq false' : currentFilter;
      this.params.skip = 0;
      this.getRowDataList();
    };
  }

  getRowDataList() {
    const ViewModelEntityData = this.masterManagementService.
                GetViewModelData(this.masterGridManagementService.OdataGlobalEntity.OdataViewModelEntity, 'Products');
    const endPointUrl = this.masterGridManagementService.getEndPointUrl(ViewModelEntityData,
       this.batchExportGridData.MasterManagementData, this.params);
    this.httpService.GethttpMethod(`/inventory/api/${endPointUrl}`).subscribe((response: any) => {
      const responseData = [];
      response.value.forEach((element: any) => {
        // const dataObject = _.mapKeys(element, function (v, k) { return k.toLowerCase(); });
        responseData.push(element);
      });
      response.value = responseData.filter(x => x.IsExport === false);
      const GridData = this.masterGridManagementService.
        getRowData(ViewModelEntityData, this.batchExportGridData.MasterManagementData, response);
      if (this.params.skip && this.params.skip > 0) {
        this.batchExportGridData.GridData.GridData = [...this.batchExportGridData.GridData.GridData, ...GridData];
      } else {
        this.batchExportGridData.GridData.GridData = GridData;
      }
      this.batchExportGridData.GridData.GridData.TotalRecords = response['totalCount'];
      this.batchExportGridData.GridData.GridActions.TotalItems = response['totalCount'];
      this.batchExportGridData.GridData = { ...this.batchExportGridData.GridData };
      if (this.params.skip && this.params.skip > 0) {
        setTimeout(() => {
          const itemcount = this.loadDataCount;
          this.lastIndex = this.lastIndex + itemcount;
          this.agGrid.gridApi.ensureIndexVisible(this.lastIndex - 1, 'bottom');            
        }, 100);
      }
    });
  }

  ngAfterViewInit() {
    if (this.batchExportGridData.GridData.GridActions &&
      this.batchExportGridData.GridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll) {
      const gridPopup = document.getElementsByClassName('gridPopUp')[0];
      const viewport = gridPopup.getElementsByClassName('ag-body-viewport')[0] as HTMLElement;
      if (viewport) {
        viewport.addEventListener('scroll', this.scrollEvent);
      }
    }
  }

  scrollEvent = (event) => {
    try {
      if (Math.ceil(event.target.scrollHeight) === Math.ceil(event.target.scrollTop + event.target.offsetHeight)
      || Math.ceil(event.target.scrollHeight) <= Math.ceil(event.target.scrollTop + event.target.offsetHeight)) {
        if (this.batchExportGridData.GridData.TotalRecords !== this.batchExportGridData.GridData.GridData.length) {
          if (this.batchExportGridData.GridData.hasOwnProperty('bottomReached')) {
            this.batchExportGridData.GridData.bottomReached(true);
          }
        }
      }
    } catch (error) {
      console.error('Error occurred in scrollEvent', error);
    }
  }

}
