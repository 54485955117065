import { Injectable } from "@angular/core";
import { RetailCommunication } from "src/app/common/communication/services/retailcommunication.service";
import { DiscountType } from "./discount-types.model";
import { RetailRoutes } from "../../retail-route";

@Injectable({
    providedIn: 'root'
})

export class DiscountDataService {

    constructor(private _V1RetailCommunication: RetailCommunication){

    }

    public createDiscountType(model: DiscountType): Promise<DiscountType[]> {
        return this._V1RetailCommunication.postPromise<DiscountType[]>(
            { route: RetailRoutes.CreateDiscountType, body: model }, true);
    }

    public updateDiscountType(model: DiscountType):Promise<DiscountType[]> {
        return this._V1RetailCommunication.putPromise<DiscountType[]>(
            { route: RetailRoutes.UpdateDiscountType, uriParams : {id :  model.id } , body: model }, true);
    }

}