import { ButtonValue, AgFieldConfig, AgToggleConfig, AgInputFieldConfig } from 'src/app/common/Models/ag-models';
import { Input, Output, Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { ActionMode, AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonTypes } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { VIPTypeBusiness } from '../vip-type.business';
import { InputTypeText } from 'src/app/common/shared/shared/utilities/common-utilities';
import { InputTypeNumbers } from 'src/app/common/shared/shared/utilities/common-utilities';;

@Component({
  selector: 'app-create-vip-type',
  templateUrl: './create-vip-type.component.html',
  styleUrls: ['./create-vip-type.component.scss']
})
export class CreateVipTypeComponent implements OnInit {
  vipTypeForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  codeMinLength: number;
  codeMaxLength: number = 10;
  codeTextInputConfig: AgInputFieldConfig;
  nameMinLength: number;
  nameMaxLength: number = 50;
  nameTextInputConfig: AgInputFieldConfig;
  listOrderInput: AgInputFieldConfig;
  listOrderMinLength = 1;
  listOrderMaxLength = 5;
  listOrderMaxValue = 99999;
  toggleisActiveInput: AgToggleConfig;
  toggleincludeOnDailyReportInput: AgToggleConfig;
  toggleAllowMobileCICO: AgToggleConfig;
  isEdit = false;
  isCopy = false;
  maxListOrder: number;
  message: string;
  showClose : boolean = true;
  constructor(private localization: Localization,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<CreateVipTypeComponent>,
    private utils: CommonUtilities,
    private business: VIPTypeBusiness) {
  }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.isEdit = this.dialogData.mode == ActionMode.update;
    this.isCopy = this.dialogData.mode == ActionMode.copy;
    this.formGenerator();
    this.vipTypeForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.actionButton.disabledproperty = !(this.vipTypeForm.dirty && this.vipTypeForm.valid)
    });
  }

  async getNextListOrder() {
    await this.business.getNextListOrder().then(x =>
      this.maxListOrder = x
    );
    this.vipTypeForm.controls.listOrder.setValue(this.maxListOrder);
  }

  formGenerator() {
    this.vipTypeForm = this.fb.group({
      id: 0,
      code: ['', Validators.required],
      name: ['', Validators.required],
      listOrder: this.maxListOrder,
      isActive: true,
      //includeOnDailyReport: false,
    })
    this.actionButton = {
      type: "primary",
      label: (this.dialogData.mode == ActionMode.create) || (this.dialogData.mode == ActionMode.copy) ?
        this.captions.btn_createVIPType : this.captions.btn_updateVIPType,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.codeTextInputConfig = {
      className: 'ag_form-control--2',
      form: this.vipTypeForm,
      formControlName: 'code',
      placeHolderId: 'VIPTypelbl_Code',
      placeHolder: this.captions.VIPType_grid_hdr_Code,
      maxlength: this.codeMaxLength,
      showRequired: true,
      errorMessageId: 'VIPTypeerr_missing_Code',
      errorMessage: this.captions.VIPTypeerr_missing_Code,
      changeLabelOnEdit: true,
      disabled: this.isEdit,
      inputType : InputTypeText.CODEINPUTTYPE.concat(',',InputTypeText.NOSPL),
      hideplaceholder : true
    };
    this.nameTextInputConfig = {
      className: 'ag_form-control--2',
      form: this.vipTypeForm,
      formControlName: 'name',
      placeHolderId: 'VIPTypelbl_Name',
      placeHolder: this.captions.VIPType_grid_hdr_Name,
      maxlength: this.nameMaxLength,
      showRequired: true,
      errorMessageId: 'VIPTypeerr_missing_Name',
      errorMessage: this.captions.VIPTypeerr_missing_Name,
      disabled : this.dialogData.isViewOnly,
      inputType : InputTypeText.NOPRESPACE,
      hideplaceholder : true
    };
    this.listOrderInput = {
      className: 'ag_form-control--2',
      form: this.vipTypeForm,
      formControlName: 'listOrder',
      placeHolderId: 'VIPTypelbl_ListOrder',
      placeHolder: this.captions.VIPType_grid_hdr_ListOrder,
      maxlength: this.listOrderMaxLength,
      showRequired: false,
      isRequired:false,
      disabled: this.dialogData.isViewOnly,
      maxValue: this.listOrderMaxValue,
      minValue: this.listOrderMinLength,
      customErrorMessageId: 'VIPTypeerr_missing_ListOrder',
      customErrorMessage: this.captions.VIPTypeerr_missing_ListOrder,
      inputType: InputTypeNumbers.ONLYPOSITIVE.concat(',',InputTypeNumbers.NODECIMAL),
      hideplaceholder : true
    };
    this.toggleisActiveInput = {
      group: this.vipTypeForm,
      formControlName: 'isActive',
      label: this.captions.VIPType_grid_hdr_IsActive,
      className: 'ag_form-control--2',
      disabled: false
    };

    this.toggleincludeOnDailyReportInput = {
      group: this.vipTypeForm,
      formControlName: 'includeOnDailyReport',
      label: this.captions.VIPTypelbl_IncludeOnDailyReport,
      className: 'ag_form-control--2',
      disabled: false
    };
    if (!this.isEdit)
      this.getNextListOrder();

    if (this.isEdit) {
      this.patchValueFormEdit();
    } else if (this.isCopy) {
      this.message = this.localization.replacePlaceholders(this.captions.lbl_copyText, ['code'], [this.dialogData.data.name] )
      this.patchValueFormCopy();
    }
  }

  patchValueFormEdit() {
    var editData = this.dialogData.data;
    this.vipTypeForm.controls.id.setValue(editData.id);
    this.vipTypeForm.controls.code.setValue(editData.code);
    this.vipTypeForm.controls.name.setValue(editData.name);
    this.vipTypeForm.controls.isActive.setValue(editData.isActive);
    this.vipTypeForm.controls.listOrder.setValue(editData.listOrder);
    if(this.dialogData.isViewOnly) {
      this.utils.disableControls(this.vipTypeForm);
    }
  }

  patchValueFormCopy() {
    var copyData = this.dialogData.data;
    this.vipTypeForm.controls.id.setValue(0);
    this.vipTypeForm.controls.name.setValue(copyData.name);
    this.vipTypeForm.controls.isActive.setValue(copyData.isActive);
    this.vipTypeForm.controls.listOrder.setValue(this.maxListOrder);
  }

  switchActive() {

  }

  /**
   * return list order for save and edit
   * @returns - number
   */
  getListOrder() {
    var editData = this.dialogData.data;
    return this.vipTypeForm.controls.listOrder.value
      ? this.vipTypeForm.controls.listOrder.value
      : this.isEdit
      ? editData.listOrder
        ? editData.listOrder
        : this.maxListOrder
      : this.maxListOrder;
  }

  async onAction(e) {
    if(this.vipTypeForm.controls.name.value.trim() === '') {
      this.vipTypeForm.get('name').setValue('');
      return;
    }
    const vipType: any = {
      id: this.isEdit ? this.vipTypeForm.controls.id.value : 0,
      code: this.vipTypeForm.controls.code.value,
      name: this.vipTypeForm.controls.name.value,
      isActive: this.vipTypeForm.controls.isActive.value,
      listOrder: this.getListOrder()
    };
    !this.isEdit ? await this.business.createVipType(vipType).then(
      x => {
        if (x.successStatus){
          this.dialogRef.close();
        }
      }
    ).catch(
      x => this.catchError(x.error.errorCode)
    ) : 
    await this.business.updateVipType(vipType, vipType.id).then(
      x => {
        if (x.successStatus){
          this.dialogRef.close();
        }
      }
    ).catch(
      x => this.catchError(x.error.errorCode)
    );
    //this.dialogRef.close();
  }


  catchError(errorCode){
    this.utils.showError(this.localization.getError(errorCode));
    this.actionButton.disabledproperty = true;
    this.actionButton = {...this.actionButton};
  }

  close(e) {
    this.dialogRef.close();
  }

  onCancel(eve) {
    this.dialogRef.close();
  }

  hideWarning(){
    this.showClose = false;
  }

}
