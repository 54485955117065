import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ValidationMessage } from '../../basereport/base-report.component';
import { ReportBusinessService } from '../../business/report-business.service';
import { DropDownData } from '../../business/report.modals';
import { ReportDataService } from '../../data/report-data.services';
@Component({
  selector: 'app-inventory-purchase-orders',
  templateUrl: './inventory-purchase-orders.component.html',
  styleUrls: ['./inventory-purchase-orders.component.scss']
})
export class InventoryPurchaseOrdersComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  ItemFormGrp: UntypedFormGroup;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  clerks: DropDownData[] = [];
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization,
    public business: ReportBusinessService, private dataService: ReportDataService) { }

  ngOnInit(): void {
    this.ItemFormGrp = this.fb.group({
      filterItems: false,
      searchbyitem: '',
      clerks: new UntypedFormControl([this.defaultData])
    });
   this.formReady.emit(this.ItemFormGrp);
    this.onLoad();
   this.isValidText(true);
  }
  private async onLoad() {
    this.clerks = await this.dataService.getAllVendor();
    this.business.FilterDataSource["allVendors"] = this.clerks;
  }
  
  getFormControlValue(event) {
     if (event[0] === 'clerks') {
        this.ItemFormGrp.setControl('clerks', event[1]);
    }
  }
  
  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

}
