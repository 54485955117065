import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, OnDestroy, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { SearchboxService } from './searchbox.service';


import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';

import { AppointmentPopupComponent } from '../appointment-popup/appointment-popup.component';
import { AppointmentpopupService } from '../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod, ServiceParamsAsync } from '../service/http-call.service';
import { BaseResponse, AppointmentGlobalSearch, popupConfig } from '../../shared/business/shared.modals';
import { Host,  SPAManagementBreakPoint, SPAScheduleBreakPoint, GridType, GridOperationType } from '../globalsContant';
import { SpaUtilities } from '../utilities/spa-utilities';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { BreakPointAccess } from '../service/breakpoint.service';
import { Router } from '@angular/router';
import { AppModuleService } from '../../app.service';
import { CommonFunctionalities } from '../common-functionalities/common.service';
import { appointmentEditService } from '../service/edit-appointment.service';
import { SettingDialogPopupComponent } from 'src/app/settings/setting-dialog-popup/setting-dialog-popup.component';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchboxComponent implements OnInit, OnDestroy {
  @ViewChild(SearchboxComponent) scrollRef: SearchboxComponent;

  @Output() onSearch = new EventEmitter();

  data_not_present: boolean = true;
  myControl: UntypedFormControl = new UntypedFormControl();
  search_icon: boolean = true;
  clear_icon: boolean = false;
  min_input_warning: boolean = false;
  json_value: any;
  input_value = '';
  value: any = [];
  value_holder: any = [];
  no_item: boolean = false;
  content_available: boolean = true;
  content_available_single: boolean = false;
  data_present: boolean = true;
  filteredOptions: Observable<string[]>;
  data: boolean = this.searchBoxService.searchType;
  dullopac: any = false;
  public clinentData: any = [];
  setBorderRadius: boolean = false;
  //MultipleSearch AutoComplete
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new UntypedFormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  allClientDetails = [];
  ifintegerBool: boolean = true;
  isUserAuthorized = true;
  addClientText: string = "";
  disabledFlag: boolean = false;
  color = 'primary';
  mode = 'indeterminate';
  values = 10;
  radius = 25;
  contactTypeEmail: any = [];
  contactTypePhone: any = [];
  timer = null;

  @ViewChild('fruitInput') fruitInput: ElementRef;
  @ViewChild('autoInput1') autoInput1: ElementRef;
  @ViewChild('highttext') highttext: ElementRef;
  @ViewChild('progressoverlay') progressoverlay: ElementRef;

  GlobalSearchResult: any;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  updateViewFlag = true;
  commonCaptions: any;
  ngOnInit() {
    this.value = [];
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  clickChange(event: any) {
    const nameInput = document.getElementById('name') as HTMLInputElement;
    fromEvent(nameInput, 'keydown').pipe(takeUntil(this.$destroyed))
      .subscribe((e: KeyboardEvent) => {
        if (e.keyCode == 40) {
          nameInput.style.backgroundColor = "red";
          nameInput.value = '';
        }
      });
  }

  removeDull(v: any) {
    if (v == 'r') {
      this.dullopac = true;
    } else {
      this.dullopac = false;
    }
    if (this.value.length > 1 && !this.dullopac) {
      this.value = [];
    }
  }

  /**
   * @method checklength
   * @function Check the input value
   * @description check the input value and parrams pass to the https service get the data bind the dom
   * @description Inner settime out function hight the search text
   * @input search value
   * @output Array from the HTTP service
   */

  checklength(e: any) {
    this.input_value = this.input_value ? this.input_value : "";
    this.ifintegerBool = this.checkifInteger(e);
    if (this.input_value && this.input_value.length) {
      this.search_icon = false;
      this.clear_icon = true;
    } else {
      this.search_icon = true;
      this.clear_icon = false;
    }
    if (this.input_value && this.input_value.length >= this.searchBoxService.searchLength) {
      this.min_input_warning = true;
      this.progressoverlay.nativeElement.style.display = 'block';
      this.disabledFlag = true;
      this.GlobalSearchResult = null;
      this.InvokeGlobalSearch();
    }
    else if (this.input_value.length < this.searchBoxService.searchLength && this.checkifInteger(this.input_value)) {
      this.min_input_warning = true;
      this.progressoverlay.nativeElement.style.display = 'block';
      this.disabledFlag = true;
      this.GlobalSearchResult = null;
      this.InvokeAppointmentSearch();
    }
    else {
      this.min_input_warning = false;
      this.value = [];
      this.GlobalSearchResult = [];
    }
    if (this.input_value && this.input_value.length == 0) {
      this.value = [];
    }
    if (this.input_value.length <= 1) {
      this.updateViewFlag = true;
    }
  }

  InvokeGlobalSearch() {
    if (this.timer) {
      clearTimeout(this.timer); //cancel the previous timer.
      this.timer = null;
    }
    this.timer = setTimeout(this.PerformGlobalSearch.bind(this), 1000);
  }

  InvokeAppointmentSearch() {
    if (this.timer) {
      clearTimeout(this.timer); //cancel the previous timer.
      this.timer = null;
    }
    this.timer = setTimeout(this.PerformAppointmentSearch.bind(this), 1000);
  }


  PerformGlobalSearch() {
    let appointmentsearchModel: AppointmentGlobalSearch = {
      searchText: this.input_value,
      searchDate: false,
      searchDateValue: this.localization.convertDateObjToAPIdate(this.utils.getCurrentDate())
    };
    if (this.utils.checkDateWithLocaleFormat(this.input_value)) {
      appointmentsearchModel.searchDate = true;
      appointmentsearchModel.searchDateValue = this.localization.convertDateObjToAPIdate(this.utils.convertLocalizedDateToAPIDateInput(this.input_value));
    }
    let searchText = this.input_value;
    let calls: ServiceParamsAsync[] = [{
      callDesc: "GlobalSearchClients",
      method: HttpMethod.Put,
      host: Host.spaManagement,
      body: this.input_value
    }, {
      callDesc: "GlobalSearchTherapist",
      method: HttpMethod.Put,
      host: Host.spaManagement,
      body: this.input_value
    },
    {
      callDesc: "GlobalSearchRetailItems",
      method: HttpMethod.Put,
      host: Host.retailManagement,
      body: this.input_value
    }, {
      callDesc: "GlobalSearchAppointments",
      method: HttpMethod.Put,
      host: Host.spaManagement,
      body: appointmentsearchModel
    }]
    // Making 4 http calls
    let result = this.http.WaitForHttpCalls(calls);

    let subs: Subscription = result.subscribe(x => {
      console.log(x);

      this.FormGlobalSearchResults(x, searchText);
      // Unsubscribing the subscription
      if (subs) {
        subs.unsubscribe();
      }
    },
      x => { console.log(x) });
  }

  PerformAppointmentSearch() {
    let searchText = this.input_value;
    let calls: ServiceParamsAsync[] = [{
      callDesc: "GlobalSearchAppointmentsIds",
      method: HttpMethod.Put,
      host: Host.spaManagement,
      body: this.input_value
    }]
    // Making 4 http calls
    let result = this.http.WaitForHttpCalls(calls);

    let subs: Subscription = result.subscribe(x => { 
      console.log(x);

      this.FormGlobalSearchResults(x, searchText); 
      if (subs) {
        subs.unsubscribe();
      }
    },
      x => { console.log(x) });
  }


  FormGlobalSearchResults(result: any[], searchText: string) {
    if (searchText == this.input_value) {
      if (result != null && this.input_value && ((this.input_value.length >= this.searchBoxService.searchLength) ||
        (this.input_value.length < this.searchBoxService.searchLength && this.checkifInteger(this.input_value)))) {
        this.GlobalSearchResult = result.map(x => {
          let showResults: boolean;
          switch (x.result.groupName) {
            case "Clients":
              showResults = this.CheckMatchingCriteriaForClient(x.result.clientDetails);
              break;
            case "Therapists":
              showResults = (x.result.therapistDetails && x.result.therapistDetails.length > 0);
              break;
            case "Items":
              showResults = (x.result.itemDetails && x.result.itemDetails.length > 0);
              break;
            case "Appointments":
              showResults = (x.result.appointmentDetails && x.result.appointmentDetails.length > 0);
              break;
          }
          if (showResults) {
            return x.result;
          }
          else {
            return false;
          }
        }).filter(x => x != false); //Bind the records
        this.progressoverlay.nativeElement.style.display = 'none';
        this.disabledFlag = false;
        let _that: any = this;
        setTimeout(()=> {
          _that.highttext.panel.nativeElement.querySelectorAll('.rendering-option span').forEach(element => {
            let inputSearchText = _that.input_value.trim().toLowerCase();
            let text = element.textContent;
            let regex = new RegExp('(' + inputSearchText + ')', 'ig');
            text = text.replace(regex, '<b>$1</b>');
            // element.innerHTML = text;
            this.localization.decodeHTMLEntityByTagName(text,element);
          });
        }, 10);
      }
      else {
        this.progressoverlay.nativeElement.style.display = 'none';
      }
    }

  }

  CheckMatchingCriteriaForClient(clientDetails: any): boolean {
    let isRecordsAvailable = false;
    if (clientDetails && clientDetails.length > 0) {
      isRecordsAvailable = true;
      let searchTextLower = this.input_value.toLowerCase();
      this.contactTypePhone = this.localization.ContactTypes.Phone;
      this.contactTypeEmail = this.localization.ContactTypes.Email;
      clientDetails.forEach(element => {
        let showAddress = false;
        let showContactEmail = false;
        let showContactPhone = false;
        let showPatronId = false;
        if (element.address && element.address.length > 0) {
          let address = element.address[0];
          showAddress = (
            (address.addressLine1 || '').toLowerCase().indexOf(searchTextLower) >= 0 || 
            (address.addressLine2 || '').toLowerCase().indexOf(searchTextLower) >= 0 ||
            (address.addressLine3 || '').toLowerCase().indexOf(searchTextLower) >= 0 ||
            (address.city || '').toLowerCase().indexOf(searchTextLower) >= 0 ||
            (address.state || '').toLowerCase().indexOf(searchTextLower) >= 0 ||
            (address.country || '').toLowerCase().indexOf(searchTextLower) >= 0 ||
            (address.county || '').toLowerCase().indexOf(searchTextLower) >= 0 ||
            (address.zipCode || '').toLowerCase().indexOf(searchTextLower) >= 0)
        }
        if (element.contactDetail && element.contactDetail.length > 0) {
          let contactDetail = element.contactDetail;
          let matchingContactDetails = contactDetail.filter(x => (x.value || '').toLowerCase().indexOf(searchTextLower) >= 0)
          if (matchingContactDetails.length == 0) {
            showContactPhone = false;
            showContactEmail = false;
          }
          else {
            let phoneNumbersArray: string[] = ["Home", "Cell", "Work"];
            let phoneRecords = matchingContactDetails.filter(x => phoneNumbersArray.indexOf(x.name) >= 0);
            if (phoneRecords.length >= 1) {
              showContactPhone = true;
            }
            else {
              let eMailTypesArray: string[] = ["Personal", "Office"];
              let eMailRecords = matchingContactDetails.filter(x => eMailTypesArray.indexOf(x.name) >= 0);
              if (eMailRecords.length >= 1) {
                showContactEmail = true;
              }
            }
          }
        }

        if (element.loyaltyDetail && element.loyaltyDetail.length > 0) {
          let matcingLoyalty = element.loyaltyDetail.filter(x => x.patronId.toLowerCase().indexOf(searchTextLower) >= 0)
          if (matcingLoyalty.length > 0) {
            showPatronId = true
          }
        }

        if ((showContactEmail || showContactPhone || showAddress || showPatronId) && element.appointmentDetails && element.appointmentDetails.length > 1) {
          element.appointmentDetails.length = 1;
        }
      });
    }
    return isRecordsAvailable;

  }

  /**
   * @method check if integer
   * @function checkifInteger(e: any): boolean
   * @description Search value if full of integer  Add client hide
   * @input string (search params)
   * @output boolean
   */

  checkifInteger(e: any): boolean {
    const isnumber = /^\d+$/.test(e);
    let ifnumberBool: boolean = false;
    if (isnumber) {
      ifnumberBool = true;
    }
    return ifnumberBool;
  }

  filter(val: any): string[] {
    let realval = val && typeof val === 'object' ? val.name : val;
    let result = [];
    this.value = [];
    let lastOption = null;
    if (!this.data) {
      for (let i = 0; i < this.value_holder.length; i++) {
        if (!realval || this.value_holder[i].name.toLowerCase().startsWith(realval.toLowerCase())) {
          if (this.value_holder[i].name !== lastOption) {
            lastOption = this.value_holder[i].name;
            result.push(this.value_holder[i]);
          }
        }
      }
    }
    if (this.data) {
      for (let i = 0; i < this.value_holder.length; i++) {
        if (!realval || (this.value_holder[i].name.toLowerCase().indexOf(realval.toLowerCase()) !== -1) || (this.value_holder[i].address.toLowerCase().indexOf(realval.toLowerCase()) !== -1)) {
          if (this.value_holder[i].name !== lastOption) {
            lastOption = this.value_holder[i].name;
            result.push(this.value_holder[i]);
          }
        }
      }
    }

    return (result);


  }

  public search(searchString: string) {
    if (searchString.length > 1) {
      this.search_icon = false;
      this.clear_icon = true;
    }
    else {
      this.search_icon = true;
      this.clear_icon = false;
    }

  }
  clearText() {
    this.input_value = "";
    this.min_input_warning = false;
    this.value = [];
    this.search_icon = true;
    this.clear_icon = false;
    this.appointmentservice.showDetails = false;
    this.appointmentservice.recordsArray = [];
    this.fruits = [];
    this.updateViewFlag = true;
  } 
  captions: any = {};
  globalSearchCaptions: any = {};
  noRecordsText: string = '';
  floatLabel:string;
  constructor(public searchBoxService: SearchboxService, public appointmentservice: AppointmentpopupService, public dialog: MatDialog, private http: HttpServiceCall,
    public localization: SpaLocalization,
    private breakPoint: BreakPointAccess, private router: Router, public _as: AppModuleService, public commonfunc: CommonFunctionalities,
    private _appointmentEditService: appointmentEditService, private utils: SpaUtilities) {
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions.bookAppointment;
    this.globalSearchCaptions = this.localization.captions.globalSearch;
    this.noRecordsText = this.globalSearchCaptions.NoRecordsFound;
    this.commonCaptions = this.localization.captions.common;
    this.clinentData.push(this.appointmentservice.personal_details_holder)
    searchBoxService.getJson().pipe(takeUntil(this.$destroyed)).subscribe(data => this.value_holder = data);

    this.addClientText = this.captions.AddClient;
    //Input Mutiple Search
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));

  }
  //Input Mutiple Search Function
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }


  clearInput() {
    if ((this.input_value || this.input_value == "") && this.input_value.length <= 2 && !this.checkifInteger(this.input_value)) {
      this.GlobalSearchResult = [];
    }
  }
  public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };

  public valueMapper = (id) => {
    let selection = this.value.filter(e => e.clientId === id);
    if (selection.length > 0)
      return selection[0].name;
  };

  openAddClient() {
    if (this.breakPoint.CheckForAccess([SPAManagementBreakPoint.AddNewClientProfile])) {
      this.GlobalSearchResult = [];
      this.autoInput1.nativeElement.value = '';
      this.input_value = '';
      this.appointmentservice.add_client = true;
      this.appointmentservice.IsAddClientFromSearch = true;
      this.appointmentservice.ImgTempHolder = {};
      this.appointmentservice.popupTitle = this.localization.captions.bookAppointment.NewClient;
      let data = {};
      this.dialog.open(AppointmentPopupComponent, {
        width: '85%',
        height: '85%',
        disableClose: true,
        hasBackdrop: true,
        data: { data: data, closebool: true },
        panelClass: 'small-popup'
      });
    }
    this.onSearch.emit();
  }

  /**
   * @function openPopup(e: any, f: any)
   * @method Open the popup
   * @description If click global search option popup open that particular method
   * @input params <obj>, <obj>
   * @output open the popup
   */

  async renderPopup(e: any, f: any) {
    const groupName = f.groupName.toString().trim();
    switch (groupName) {
      case "Clients": {
        this._as.isglobalSearch = true;
        this._as.selectedClient = e;
        this.router.navigate(['/client/allclients/', e.guestProfileId + this.utils.getRandomDecimal() * 10]);
        break;
      }
      case "Appointments": {
        if (this.breakPoint.CheckForAccess([SPAScheduleBreakPoint.EditAppointment])) {
          await this._appointmentEditService.EditAppointment(e.appointmentId, null);
        }
        break;
      }
      case "Items": {
        this._as.isglobalSearch = true;
        this._as.selectedShopObj = e;
        this.router.navigate([`/shop/viewshop/`], { queryParams: { description: e.name, id: e.itemId, query: this.utils.getRandomDecimal() * 10 } });
        break;
      }
      case "Therapists": {
        console.log('selected Therapist', e)
        if (this.breakPoint.CheckForAccess([SPAScheduleBreakPoint.SettingTherapistSetup])) {
          this.GetDetailsForId(e.therapistId, 'GetTherapistAggregate');
        }
        break;
      }
      default: {
        break;
      }
    }

  }

  FormGroupNameTitle(groupName: string) {
    let groupTitle = "";
    switch (groupName) {
      case "Clients":
        groupTitle = this.globalSearchCaptions.Clients;
        break;
      case "Therapists":
        groupTitle = this.globalSearchCaptions.Therapists;
        break;
      case "Items":
        groupTitle = this.globalSearchCaptions.Items;
        break;
      case "Appointments":
        groupTitle = this.globalSearchCaptions.Appointments;
        break;
    }
    return groupTitle;
  }

  ngAfterViewChecked() {
    if (document.getElementById('search-actions') && document.getElementById('search-actions').offsetWidth > 0 && this.updateViewFlag) {
      this.updateViewFlag = false;
      let actionsWidth = document.getElementById('search-actions').offsetWidth - 30;
      document.getElementById('input-box').style.width = 'calc(100% - ' + actionsWidth + 'px)';
    }
  }
  ngAfterViewInit() {
    document.getElementById('input-box').focus();
  }

  change(event, inputvalue) {
    this.onSearch.emit();
  }

  GetDetailsForId(id: string, callDesc: string): any {
    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback,
      callDesc: callDesc,
      method: HttpMethod.Get,
      uriParams: { id: id },
      showError: true,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetTherapistAggregate") {
        this.openDialog(result.result, GridType.therapist, 'Edit');
    }
  }

  openDialog(e: any, belonTo: any, preTit: any, afterClosed = true): any {
    let dialogRef: any;
    let popupConfiguration: popupConfig;

    if (preTit == "Edit") {
      popupConfiguration = {
        operation: "edit",
        GridOperationType: GridOperationType.Edit
      };
    } else {
      popupConfiguration = {
        operation: "create",
        GridOperationType: GridOperationType.Create
      };
    }
    dialogRef = this.triggerPopup(e, belonTo, preTit, popupConfiguration, afterClosed);

    return dialogRef;
  }

  triggerPopup(e: any, belonTo: any, preTit: any, popupConfiguration: any, afterClosed = true): any {
    let Dialogtitle = 'Therapist'
    const panelClass = ['small-popup'];

    let popupWidth = "90%";
    let popupHeight = "85%";
    let popupMinWidth = "900px";
    const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      height: popupHeight,
      minWidth: popupMinWidth,
      width: popupWidth,
      maxWidth: popupWidth,
      data: { headername: preTit + '  ' + Dialogtitle, closebool: true, templatename: 'TS', datarecord: e, popupConfig: popupConfiguration, type: belonTo },
      panelClass: panelClass,
      disableClose: true,
      hasBackdrop: true
    });
    if (afterClosed) {
      dialogRef.afterClosed().subscribe();
    }
    return dialogRef;
  }

}










