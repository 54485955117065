import { Component, OnInit,Output,EventEmitter, Input} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {KakaoTemplateMappingBusiness} from 'src/app/common/templates/KAKaO-Template-Mapping/Kakao-Template-mapping.business';
import { AgDropdownConfig, AgInputFieldConfig, ButtonValue, DropdownOptions } from 'src/app/common/Models/ag-models';
import { from, ReplaySubject } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { ActionMode } from 'src/app/common/enums/shared-enums';
import { takeUntil } from 'rxjs/operators';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector : 'app-Create-KakaoMapping',
    templateUrl : './Create-KaKaoTemplate-mapping.component.html',
    styleUrls : ['./Create-KaKaoTemplate-mapping.component.scss'],
    // providers : [KakaoTemplateMappingBusiness]
})

export class CreateKaKaoMappingComponent  implements OnInit {
  captions : any;
  smsTemplates : Promise<DropdownOptions[]>;
  CreateKakoTemplateMappingForm: UntypedFormGroup;
  smsTemplatesInput: AgDropdownConfig;
  buttonTypeInput : AgDropdownConfig;
  kakaoTemplatesInput: AgDropdownConfig;
  fallBackInput: AgDropdownConfig;
  buttonDisplayInput : AgInputFieldConfig;  
  buttonUrlInput : AgInputFieldConfig;  
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  FormArray: UntypedFormArray;
  buttonTypeselectedList : any;
  @Input() crudInput;
  @Output() handleClickEvent = new EventEmitter();
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  changeInputValues: any;

  constructor(private fb: UntypedFormBuilder,localization: Localization,
    private KakaoTemplateMappingBusiness : KakaoTemplateMappingBusiness)
    {
      
    }

   ngOnInit()
    {
        this.captions = this.KakaoTemplateMappingBusiness.Captions
        this.actionButton = {
          type: 'primary',
          label: this.crudInput.actionButton,
          disabledproperty: true
        }
        this.cancelButton = {
          type: 'tertiary',
          label: this.captions.btn_cancel
    
        }
        this.pageInitialization(); 
        this.createKakaoTemplateMappingForm();
    }

    ngOnDestroy() {
      if (this.destroyed$) {
        this.destroyed$.next(true);
        this.destroyed$.complete();
      }
    }

    createKakaoTemplateMappingForm()
    {
        
          this.formReady.emit(this.CreateKakoTemplateMappingForm);
          this.CreateKakoTemplateMappingForm.valueChanges.subscribe(x=>{
            console.log(x);
          })
          this.buttonTypeselectedList = this.KakaoTemplateMappingBusiness.getButtonType();
          this.CreateKakoTemplateMappingForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
            this.actionButton.disabledproperty = !(this.CreateKakoTemplateMappingForm.dirty && this.CreateKakoTemplateMappingForm.valid)
          });
          
          if(this.crudInput && this.crudInput.mode == ActionMode.update)          
            this.PatchEditForm(this.crudInput.form,this.crudInput.mode);          

          if(this.crudInput && this.crudInput.mode == ActionMode.copy)
          {
            this.PatchEditForm(this.crudInput.form,this.crudInput.mode);
            this.CreateKakoTemplateMappingForm.controls.smsTemplates.setValue([]);
            this.CreateKakoTemplateMappingForm.controls.id.setValue(0);
          }
    }

    PatchEditForm(data : any, mode : any)
    {
      this.CreateKakoTemplateMappingForm.patchValue(data);
      this.CreateKakoTemplateMappingForm.controls.smsTemplates.setValue(data.smsTemplates);
      this.CreateKakoTemplateMappingForm.controls.kakaoTemplate.setValue(data.kakaoTemplate);
      this.CreateKakoTemplateMappingForm.controls.fallBackMessageType.setValue(data.fallBackMessageType);
      this.PatchKakaoButtonConfiguration(data?.items,mode);
    }

    PatchKakaoButtonConfiguration(kakaoTemplates :  any, mode : any)
    {
      if(kakaoTemplates.length > 0)
      {
      this.FormArray = this.CreateKakoTemplateMappingForm.get('items') as UntypedFormArray;

      if (this.FormArray.length > 0) {
        this.FormArray.removeAt(0);
      }
     
      for (let i = 0; i < kakaoTemplates.length; i++) {
        let data: any = {
          buttonType: kakaoTemplates[i].buttonType,
          buttonDisplayName: kakaoTemplates[i].buttonDisplayName,
          buttonUrlForPc: kakaoTemplates[i].buttonUrlForPc,
          buttonURLForMobile: kakaoTemplates[i].buttonURLForMobile,
        };
      
        if (mode === ActionMode.update) {
          data.id = kakaoTemplates[i].id;
        }
      
        let formGroup = this.fb.group(data);
        this.FormArray.push(formGroup);
        }
      }
    }

    async pageInitialization()
    {
      this.CreateKakoTemplateMappingForm = this.fb.group({
        id: 0,
        smsTemplates: [],
        kakaoTemplate: '',
        fallBackMessageType: '',
        isActive :  {value: true, isDisabled: false},
        items: this.fb.array([this.fb.group({
          buttonType: '',
          buttonDisplayName: '',
          buttonUrlForPc: '',
          buttonURLForMobile : '',
          id : 0
        })
      ])      
      });
      //this.FormArray = this.CreateKakoTemplateMappingForm.get('items') as UntypedFormArray;
      this.buttonTypeselectedList = this.KakaoTemplateMappingBusiness.getButtonType();
        this.kakaoTemplatesInput = {
            className: 'ag_form-control--lg',
            form: this.CreateKakoTemplateMappingForm,
            formControlName: 'kakaoTemplate',
            placeHolderId: 'kakaoTemplate',
            placeHolder: this.captions.Kakaolbl_KakaoTemplateId,
            selectOptions : from(this.KakaoTemplateMappingBusiness.getKakoTalkTemplateCodes()),
            showRequired: true,
            isFirstEmpty: true,
          };
          this.fallBackInput = {
            className: 'ag_form-control--lg',
            form: this.CreateKakoTemplateMappingForm,
            formControlName: 'fallBackMessageType',
            placeHolderId: 'fallBackMessageType',
            placeHolder: this.captions.Kakaolbl_FallBackMessageTypeId,
            selectOptions : from(this.KakaoTemplateMappingBusiness.getFallbackMessageType()),
            showRequired: true,
            isFirstEmpty: true,
          };
          this.smsTemplatesInput = {
            className: 'ag_form-control--lg',
            form: this.CreateKakoTemplateMappingForm,
            formControlName: 'smsTemplates',
            placeHolderId: 'SMSTemplates',
            placeHolder: this.captions.SMSTemplates,
            selectOptions: from(this.KakaoTemplateMappingBusiness.getSMSTemplateData(this.crudInput.mode)),
            showRequired: true,
            isFirstEmpty: true,
          };
    }

    onAction(event) {
      this.handleClickEvent.emit({
        mode: this.crudInput.mode,
        data: this.CreateKakoTemplateMappingForm.getRawValue()
      })
    }
  
    onCancel(event) {
      this.handleClickEvent.emit({
        mode: ActionMode.cancel,
        data: this.CreateKakoTemplateMappingForm.getRawValue()
      })
    }

    removeItem(e, i) {
      this.CreateKakoTemplateMappingForm.markAsDirty();
      this.FormArray = this.CreateKakoTemplateMappingForm.get('items') as UntypedFormArray;
      this.FormArray.removeAt(i); 
    }

    add(e, i) {
      this.CreateKakoTemplateMappingForm.markAsDirty();
      this.FormArray = this.CreateKakoTemplateMappingForm.get('items') as UntypedFormArray; 
      let data = this.fb.group({
          buttonType: '',
          buttonDisplayName: '',
          buttonUrlForPc: '',
          buttonURLForMobile : '',
          id : 0
      })
      this.FormArray.push(data); 
    }

    drop(event: CdkDragDrop<string[]>) {
      this.CreateKakoTemplateMappingForm.markAsDirty();
      this.FormArray = this.CreateKakoTemplateMappingForm.get('items') as UntypedFormArray;
      moveItemInFormArray(this.FormArray, event.previousIndex, event.currentIndex);
    }
}

export function moveItemInFormArray(
  formArray: UntypedFormArray,
  fromIndex: number,
  toIndex: number
): void {
  const dir = toIndex > fromIndex ? 1 : -1;

  const item = formArray.at(fromIndex);
  for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
    const current = formArray.at(i + dir);
    formArray.setControl(i, current);
  }
  formArray.setControl(toIndex, item);
}