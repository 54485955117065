import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import { ConsentManagementBusiness } from 'src/app/common/consent-management/consent-management.business';
import { Host, SPAManagementBreakPoint, Module } from '../../../globalsContant';
import { BaseResponse, Device,   ClientCreditCardInfo, CardInfo } from '../../../../shared/business/shared.modals';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { SubscriptionLike as ISubscription, Subscription, ReplaySubject } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserSessionConfiguration } from '../../../../core/core.model';
import { UserMachineConfigurationService } from '../../../../core/services/user-machine-configuration.service';
import { PayAgentService } from 'src/app/retail/shared/service/payagent.service';
import { CardInputMethod } from 'src/app/retail/shared/service/payment/payment-business.model';
import { GatewayConfigurationType } from 'src/app/retail/shared/globalsContant';
import { ExportSendComponent } from 'src/app/common/export-send/export-send.component';
import { ConsentManagementComponent } from 'src/app/common/consent-management/consent-management.component';
import { DataRetentionComponent} from 'src/app/common/data-retention/data-retention.component'
import { takeUntil } from 'rxjs/operators';
import { GuestPolicyWrapperComponent } from './guest-policy-wrapper/guest-policy-wrapper.component';
import { Email, GuestPolicyDetail, Phone, PolicyCategoryType, PolicyType } from 'src/app/common/shared/shared.modal';
import { Localization } from 'src/app/common/localization/localization';
import { GatewayConfiguration } from 'src/app/retail/shared/service/payment/payment-model';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
  providers: [ConsentManagementBusiness]
})
export class AdditionalInformationComponent implements OnInit, OnDestroy {
  cardConnectDialog: MatDialogRef<any, any>;
  guestData: any;
  additionalDetails: any = [];
  FormGrp: UntypedFormGroup;
  minFromDate: any;
  client_scheduling: boolean;
  customFieldInfo: any = [];
  isTitleHidden: boolean = true;
  clientConfiguration: any = [];
  customFieldHidden: any = [true, true, true, true, true];
  customFieldDesc: any = ['', '', '', '', ''];
  customFieldValues: any = [[], [], []];
  captions: any;
  showToggle: boolean;
  cardInfo: ClientCreditCardInfo[] = [];
  displayCardInfo: CardInfo;
  formSubscription: ISubscription;
  selectedDevice: string;
  priceTypes = [];
  availableDevices: Device[] = [];
  cardType = 'visa';
  EncryptedCardData: string = '';
  userSessionConfiguration: UserSessionConfiguration;
  IDTechCardSwipePopupClosed: boolean = false;
  clientWindowConvertion: Subscription;
  isFirstTime: boolean = true;
  GatewayConfiguration: GatewayConfiguration;
  defaultCardInputMethod: CardInputMethod;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  PaymentReferenceID = 0;
  additionalInfo :any;
  isClientViewOnly = false;
  cardRequired: any;
  isGuestRecordPurged: boolean = false;
  floatLabel:string;
  @Input() IsGDPREnabled : boolean = false;
  IsConsentPolicy : boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showClose: boolean = true;
  allCaptions: any;
  noftificationConfig : any;
  checkBoxnNotiPref: any;
  vipTypes: any
  get defaultOutletId() {
    return (this.userSessionConfiguration && this.userSessionConfiguration.defaultOutletId > 0) ? this.userSessionConfiguration.defaultOutletId : 0;
  }

  @Input('changedClientData')
  set changeClientData(value){
    if(value){
      this.initializeFormData();
    }
  }

  constructor(public appointmentService: AppointmentpopupService, private _consentManagementBusiness :ConsentManagementBusiness, private Form: UntypedFormBuilder, private http: HttpServiceCall,
    public localization: SpaLocalization, private breakPoint: BreakPointAccess, public dialog: MatDialog, private utils: SpaUtilities, private PropertyInfo: SpaPropertyInformation,
    private payAgentService: PayAgentService, private userMachineConfigurationService: UserMachineConfigurationService
    ,private _localization: Localization) {
    this.captions = this.localization.captions.bookAppointment;
    this.floatLabel = this.localization.setFloatLabel;
    this.allCaptions = this._localization.captions;
    this.FormGrp = this.Form.group({
      viptype: '',
      pricetype: 0,
      device: 0,
      card_details: '',
      card_name: '',
      expiry_date: this.PropertyInfo.CurrentDate,
      card_cvv: '',
      client_scheduling: false,
      customField1: '',
      customField2: '',
      customField3: '',
      customField4: '',
      customField5: '',
      comments: '',
      socialMedia: '',
      alergy: '' ,
      consentDate :'',
      consentExpiryDate: '',
      consentPolicyId : '',
      isPurged: false,
      // notification : 0,
      // profileForMarketing: '',
      // shareProfile :''
    });
    this.appointmentService.additionalDetailsFormGroup = this.FormGrp;
    this.appointmentService.additionalDetailsFormGroup["CreateClientValid"] = false;
    this.appointmentService.additionalIsValid = this.FormGrp.valid;
    this.client_scheduling = this.FormGrp.controls.client_scheduling.value;
  }

  sliderChange() {
    this.client_scheduling = !this.client_scheduling;
  }

  ngOnInit() {
    this.initializeFormData();
    this.vipTypes = this.appointmentService.allVipTypes;
    this.noftificationConfig= this.getNotificationData();
    this.checkBoxnNotiPref = this.getcheckBoxnNotiPref();
    if(this.appointmentService && this.appointmentService.clientEditData && this.appointmentService.clientEditData.clientDetail && this.appointmentService.clientEditData.clientDetail.consentPolicyId != 0)
    {
      this.getPolicyTypebyPolicyId(this.appointmentService.clientEditData.clientDetail.consentPolicyId);
      if(this.appointmentService?.clientEditData?.clientDetail?.isPurged)
        this.isGuestRecordPurged = true;
    }
  }

  initializeFormData() {    
    this.selectedDevice = this.FormGrp.controls.device.value;
    this.makeGetCall("GetClientConfiguration");
    this.getAllConfigList();
    this.additionalDetails = this.appointmentService.resultNewClient;    
    this.clientWindowConvertion = this.appointmentService.convertToEdit.subscribe(x => {
      if (x && x.id > 0 && this.isFirstTime) {
        this.isFirstTime = false;
        this.initializeFormData();
      }
    });
    if(this.isGuestRecordPurged)
      this.FormGrp.disable();
  }  
  getNotificationData(){
    return [
      {
          id: 0,
          value: this.captions.dontsend,
          viewValue: this.captions.dontsend,
      },
      {
          id: 1,
          value: this.captions.viaCell,
          viewValue: this.captions.viaCell
      },
      {
          id: 2,
          value: this.captions.viaEmail,
          viewValue: this.captions.viaEmail,
      },
      {
          id: 3,
          value: this.captions.sendToall,
          viewValue: this.captions.sendToall
      },
  ]
  }
  getcheckBoxnNotiPref(){
    return [
      {
          name: this.captions.profileForMarketing,
          control : 'profileForMarketing',
          isSelected: false,
      },
      {
          name: this.captions.shareProfile,
          control : 'shareProfile',
          isSelected: false,
      }
  ]
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.clientWindowConvertion) {
      this.clientWindowConvertion.unsubscribe();
    }
    this.isFirstTime = true;
  }

  getAllConfigList() {
    //Price type list
    this.getAllPriceTypes();
    this.getAllVipTypes();
  }
  getPolicyTypebyPolicyId(consentPolicyId : number)
  {
    this._consentManagementBusiness.GetPolicyTypeUsingPolicyId(consentPolicyId).then(res=>
      {
          this.IsConsentPolicy = res == PolicyType.ConsentPolicy;
      });
  }
  async SetEditValues() {
    if (this.appointmentService.clientEditData && this.appointmentService.clientEditData.clientDetail) {
      this.FormGrp.controls.customField1.setValue(this.appointmentService.clientEditData.clientDetail.customField1);
      this.FormGrp.controls.customField2.setValue(this.appointmentService.clientEditData.clientDetail.customField2);
      this.FormGrp.controls.customField3.setValue(this.appointmentService.clientEditData.clientDetail.customField3);
      this.FormGrp.controls.customField4.setValue(this.appointmentService.clientEditData.clientDetail.customField4);
      this.FormGrp.controls.customField5.setValue(this.appointmentService.clientEditData.clientDetail.customField5);
      this.FormGrp.controls.comments.setValue(this.appointmentService.clientEditData.clientDetail.comments);
      this.showToggle = true;
      this.FormGrp.controls.client_scheduling.setValue(this.appointmentService.clientEditData.clientDetail.bypassClientScheduling);
      this.FormGrp.controls.pricetype.setValue(this.appointmentService.clientEditData.clientDetail.priceTypeId);
      if(!this.appointmentService.copySelectedClient){
        this.FormGrp.controls.viptype.setValue(this.appointmentService.clientEditData.clientDetail.vip);
      }
      this.cardInfo = this.appointmentService.clientCreditCardInfo = this.appointmentService.copySelectedClient ? [] : this.appointmentService.clientEditData.clientCreditCardInfo;
      if (this.cardInfo && this.cardInfo.length > 0) {
        const activeCard = this.cardInfo.filter(x => x.isActive);
        if (activeCard.length > 0) {
          this.PaymentReferenceID = activeCard[0].tokenTransId;          
        }
      }
    }
    if (this.appointmentService.clientWidowActionType == "EDIT") {
      if (this.appointmentService.isClientViewOnly) {
        this.utils.disableControls(this.FormGrp);
      } else {
        let customFieldBPs = this.breakPoint.GetBreakPoint([SPAManagementBreakPoint.EditCustomField1, SPAManagementBreakPoint.EditCustomField2, SPAManagementBreakPoint.EditCustomField3, SPAManagementBreakPoint.EditCustomField4, SPAManagementBreakPoint.EditCustomField5]).result;
        if (customFieldBPs && customFieldBPs.length > 0) {
          if (!(customFieldBPs.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditCustomField1)[0].allow)) {
            this.FormGrp.controls['customField1'].disable();
          }
          if (!(customFieldBPs.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditCustomField2)[0].allow)) {
            this.FormGrp.controls['customField2'].disable();
          }
          if (!(customFieldBPs.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditCustomField3)[0].allow)) {
            this.FormGrp.controls['customField3'].disable();
          } 
          if (!(customFieldBPs.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditCustomField4)[0].allow)) {
            this.FormGrp.controls['customField4'].disable();
          } 
          if (!(customFieldBPs.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditCustomField5)[0].allow)) {
            this.FormGrp.controls['customField5'].disable();
          } 
        }
      }
    }
  }

  fetchCustomFieldInfo() {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetCustomFieldsWithValues",
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  getAllPriceTypes() {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetAllPriceTypes",
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  async getAllVipTypes() {
    await this.http.CallApiAsync<any>({
      host: Host.spaManagement,
      callDesc: "GetAllVipType",
      method: HttpMethod.Get,
      showError: false,
      uriParams: { isIncludeInactive : false},
    }).then( x => 
      this.vipTypes = x.result
    );
  }

  validateForm() {
    this.appointmentService.additionalDetailsFormGroup["CreateClientValid"] = this.FormGrp.valid;
  }
  
  OnChangeEvents() {
    this.formSubscription = this.FormGrp.valueChanges.subscribe(res => {
      this.validateForm();
    });
  }

  closeWarning(){
    this.showClose = false;
  }

  makeGetCall(routeURL: string) {
    this.http.CallApiWithCallback<any[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: routeURL,
      uriParams: { module: Module.client },
      method: HttpMethod.Get,
      showError: false,
      extraParams: ["dataBelongTo"]
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetCustomFieldsWithValues") {

      this.customFieldInfo = result.result ? result.result : [];
      if (this.customFieldInfo && this.customFieldInfo.length > 0) {
        for (let i = 0; i < this.customFieldInfo.length; i++) {
          //Display On 0 - Not displayed
          //Display On 1 - On client only
          //Display On 2 - On appointment only
          //Display On 3 - Both on client and on appointment
          if (this.customFieldInfo[i] && (this.customFieldInfo[i].displayOn == 1 || this.customFieldInfo[i].displayOn == 3)) {
            this.customFieldHidden[i] = false;
            this.customFieldDesc[i] = this.customFieldInfo[i].fieldName ? this.customFieldInfo[i].fieldName : '';
            if (i < 3) {
              this.customFieldValues[i] = this.customFieldInfo[i].customFieldValues && this.customFieldInfo[i].customFieldValues.length > 0 ? this.customFieldInfo[i].customFieldValues : [];
            }
          }
          if (this.isTitleHidden && !this.customFieldHidden[i]) {
            this.isTitleHidden = false;
          }
        }
      }
      this.Validation(this.clientConfiguration);
      this.SetEditValues();
    }
    if (callDesc == "GetClientConfiguration") {
      this.clientConfiguration = <any>result.result;
      this.fetchCustomFieldInfo();
      this.OnChangeEvents();
    }
    if (callDesc == "GetAllPriceTypes") {
      this.priceTypes = <any>result.result;
      this.checkPriceTypeUserAccess()
    }
    if (callDesc == 'GetPaymentConfiguration') {
      let response: any = result.result;
      this.GatewayConfiguration = response.gatewayConfiguration.find(t => t.type == GatewayConfigurationType.CreditCard);
    }
  }

  checkPriceTypeUserAccess() {
    if (this.appointmentService.clientWidowActionType != "EDIT") {
      let priceTypeBps = this.breakPoint.GetBreakPoint([SPAManagementBreakPoint.AddPriceType]).result;
      if (priceTypeBps && priceTypeBps.length > 0) {
        priceTypeBps.filter(x => x.breakPointNumber == SPAManagementBreakPoint.AddPriceType)[0].allow ? '' : this.FormGrp.controls['pricetype'].disable();
      }
    }
  }

  Validation(clientConfiguration: any) {
    this.FormGrp.controls['customField1'].clearValidators()
    this.FormGrp.controls['customField1'].setValidators(clientConfiguration[0]["CUSTOM_FIELD_1"] && !this.customFieldHidden[0] ? [Validators.required] : []);
    this.FormGrp.controls['customField1'].updateValueAndValidity();
    this.FormGrp.controls['customField2'].clearValidators()
    this.FormGrp.controls['customField2'].setValidators(clientConfiguration[0]["CUSTOM_FIELD_2"] && !this.customFieldHidden[1] ? [Validators.required] : []);
    this.FormGrp.controls['customField2'].updateValueAndValidity();
    this.FormGrp.controls['customField3'].clearValidators()
    this.FormGrp.controls['customField3'].setValidators(clientConfiguration[0]["CUSTOM_FIELD_3"] && !this.customFieldHidden[2] ? [Validators.required] : []);
    this.FormGrp.controls['customField3'].updateValueAndValidity();
    this.FormGrp.controls['customField4'].clearValidators()
    this.FormGrp.controls['customField4'].setValidators(clientConfiguration[0]["CUSTOM_FIELD_4"] && !this.customFieldHidden[3] ? [Validators.required] : []);
    this.FormGrp.controls['customField4'].updateValueAndValidity();
    this.FormGrp.controls['customField5'].clearValidators()
    this.FormGrp.controls['customField5'].setValidators(clientConfiguration[0]["CUSTOM_FIELD_5"] && !this.customFieldHidden[4] ? [Validators.required] : []);
    this.FormGrp.controls['customField5'].updateValueAndValidity();
    this.cardRequired = clientConfiguration[0]["CLIENT_CREDIT_CARD"];


    this.appointmentService.additionalDetailsFormGroup = this.FormGrp;
    this.appointmentService.additionalDetailsFormGroup["CreateClientValid"] = this.FormGrp.valid;
    this.appointmentService.additionalIsValid = this.FormGrp.valid;
    this.client_scheduling = this.FormGrp.controls.client_scheduling.value;

  }

  SaveReferenceId(event: number) {
    this.FormGrp.controls.device.markAsDirty();
    this.FormGrp.controls.device.markAsTouched();
    this.PaymentReferenceID = event;
    const swipedcardInfo = {
      id: 0,
      tokenTransId: event,
      isActive: true,
      clientId: 0,
      createdTime: this.PropertyInfo.CurrentDTTM
    };
    if (this.cardInfo) {
      this.cardInfo.forEach(x => { x.isActive = false; }); // deactive previously stored card info
      this.cardInfo = this.cardInfo.filter(c => c.id !== 0); // Remove previously swiped card info
    }
    
    this.cardInfo.push(swipedcardInfo);    
    this.appointmentService.clientCreditCardInfo = this.cardInfo;
  }

  removeCardDetails(){
    this.FormGrp.controls.device.markAsDirty();
    this.FormGrp.controls.device.markAsTouched();
    this.PaymentReferenceID = 0;
    if(this.cardInfo){
      this.cardInfo.forEach(x => {x.isActive = false;});
      this.appointmentService.clientCreditCardInfo = this.cardInfo;
    }
  }

  mapEmailInfo(isFromPersonalInformation : boolean)
  {
    if(!isFromPersonalInformation)
    {
      const emailInfo : Email[] =  this.appointmentService.clientEditData.emails.map(model => {
        const account: Email = {
          contactType: "",
          emailType: 0,
          emailLabel: "",
          emailId: model.emailId,
          primaryEmail: model.isPrimary,
          privateInformtionEmail: model.isPrivate,
          platformContactUuid: model.platformContactUuid
        }
        return account;
      });
      return emailInfo;
    }
    else{
      const emailInfo : Email[] =  this.appointmentService.personalDetailsFormGroup.value.Email.map(model => {
        const account: Email = {
          contactType: model.contactType,
          emailType: 0,
          emailLabel: model.EmailLabel,
          emailId: model.EmailId,
          primaryEmail: model.EmailPrimary,
          privateInformtionEmail: model.EmailPrivate,
          platformContactUuid: model.PlatformContactUuid
        }
        return account;
      });
      return emailInfo;
    }  
  }

  mapPhoneNumber(isFromPersonalInformation : boolean)
  {
    if(!isFromPersonalInformation)
    {
      const phoneInfo : Phone[] =  this.appointmentService.clientEditData.phoneNumbers.map(model => {
        const account: Phone = {
          contactType: "",
          phoneType: 0,
          phoneLabel: "",
          countryCode:model.countryCode,
          phoneNumber: model.number,
          extension: model.extension,
          privateInformtionPhone: model.isPrivate,
          primaryPhone: model.isPrimary,
          platformContactUuid: model.platformContactUuid
        }
        return account;
      });
      return phoneInfo;
    }
    else{
      const phoneInfo : Phone[] = this.appointmentService.personalDetailsFormGroup.value.Phone.map(model => {
        const account: Phone = {
          contactType: "",
          phoneType: 0,
          phoneLabel: "",
          countryCode:model.countryCode,
          phoneNumber: model.number,
          extension: model.extension,
          privateInformtionPhone: model.isPrivate,
          primaryPhone: model.isPrimary,
          platformContactUuid: model.platformContactUuid
        }
        return account;
      });
      return phoneInfo;
    }
    
  }

  openGuestPolicyDialog(popupType) {
    let guestPolicyDetail : GuestPolicyDetail;
    if( this.appointmentService && this.appointmentService.clientEditData)
    {
      guestPolicyDetail = {
        id: this.appointmentService.clientEditData.clientDetail.guestId,
        consentDate : this.appointmentService.clientEditData.clientDetail.consent,
        consentExpiryDate: this.appointmentService.clientEditData.clientDetail.consentExpiryDate,
        consentPolicyId : this.appointmentService.clientEditData.clientDetail.consentPolicyId,
        comments:"",
        isPurged: this.appointmentService.clientEditData.clientDetail.isPurged,
        policyCategoryType : PolicyCategoryType.Guest,
        lastName: this.appointmentService.clientEditData.clientDetail.lastName,
        email: this.mapEmailInfo(false),
        phone : this.mapPhoneNumber(false)
      } 
    }
    else{
      guestPolicyDetail = {
        id: '',
        consentDate : this.FormGrp.value.consentDate,
        consentExpiryDate: this.FormGrp.value.consentExpiryDate,
        consentPolicyId : this.FormGrp.value.consentPolicyId,
        comments:"",
        isPurged:  this.FormGrp.value.isPurged,
        policyCategoryType : PolicyCategoryType.Guest,
        email :  this.mapEmailInfo(true),
        lastName : this.appointmentService.personalDetailsFormGroup.value.lastName,
        phone : this.mapPhoneNumber(true)
      } 
    }
    this.dialog.open(GuestPolicyWrapperComponent, {
      width: popupType== 3 ?'40%' : '80%',
      height: popupType== 3 ?'35%' : '80%',
      disableClose: true,
      hasBackdrop:true,
      data: {
        guestPolicyDetail : guestPolicyDetail,
        popupType: popupType
      },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if(res != undefined && popupType == 1)
      {
        let guestPolicyDetail : ApplyPolicy = res as ApplyPolicy;
        this.IsConsentPolicy = true;
        this.FormGrp.controls.device.markAsDirty();
        this.FormGrp.controls.device.markAsTouched();   
        this.FormGrp.controls.consentDate.setValue(guestPolicyDetail.consentDate != '' && guestPolicyDetail.consentDate ? this.localization.getDate(guestPolicyDetail.consentDate):null);
        this.FormGrp.controls.consentExpiryDate.setValue(guestPolicyDetail.consentExpiryDate != '' && guestPolicyDetail.consentExpiryDate ?  this.localization.getDate(guestPolicyDetail.consentExpiryDate as string) : null);
        this.FormGrp.controls.consentPolicyId.setValue(guestPolicyDetail.policyId);
        if(this.appointmentService && this.appointmentService.clientEditData && this.appointmentService.clientEditData.clientDetail)
        {
        this.appointmentService.clientEditData.clientDetail.consent = this.localization.getDate(guestPolicyDetail.consentDate as string);
        this.appointmentService.clientEditData.clientDetail.consentExpiryDate =guestPolicyDetail.consentExpiryDate && guestPolicyDetail.consentExpiryDate != '' ? this.localization.getDate(guestPolicyDetail.consentExpiryDate as string) : null;
        this.appointmentService.clientEditData.clientDetail.consentPolicyId = guestPolicyDetail.policyId;
        return;  
        }     
      }
      else if(res != undefined && popupType == 2 )
      {
        this.appointmentService.clientEditData.clientDetail.isPurged = res ?  true : false;
      }
    });
  }
}

