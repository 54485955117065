import { Injectable } from '@angular/core';
import { Host,Product } from 'src/app/common/shared/shared/globalsContant';
import { HttpMethod,  HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { ReportAPITemplate, ReportKeywordAPI,ReportTemplate,  TemplateDomainData,Events,EventReport } from './crud-report-templates.model';
import { Localization } from 'src/app/common/localization/localization';

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateService {
  eventCaptions:any;
  productId:number;
  host:Host;
  constructor(private http:HttpServiceCall, public localization: Localization) {   
    this.eventCaptions = this.localization.captions.settings.events;
    this.productId=Number(this.localization.GetPropertyInfo('ProductId'));
    this.host= this.productId==Number(Product.SPA) ? Host.schedule:Host.golfSchedule;
  }

  public async GetTemplateDataById(templateId: any): Promise<TemplateDomainData> {
    let response:any=await this.http.CallApiAsync({
         host: this.host,       
         callDesc: "GetTemplateById",
         method: HttpMethod.Get,
         showError: true,
         uriParams: { templateId: templateId }        
     });
 
     return response.result;
 }

 public async GetAllTemplates(): Promise<ReportTemplate[]> {
  let response:any=await this.http.CallApiAsync({
       host: this.host,       
       callDesc: "ReportTemplate",
       method: HttpMethod.Get,
       showError: true,          
   });

   return response.result;
}

public async SaveTemplateData(templateData:TemplateDomainData ): Promise<number> {
  let response:any=await this.http.CallApiAsync({
       host: this.host,       
       callDesc: "ReportTemplate",
       method: HttpMethod.Post,
       showError: true,  
       body:templateData        
   });

   return response.result;
}

public async DeleteTemplate(templateId:number ): Promise<ReportTemplate[]> {
  let response:any=await this.http.CallApiAsync({
       host: this.host,       
       callDesc: "DeleteTemplate",
       method: HttpMethod.Delete,
       showError: true,  
       uriParams:{ id: templateId }        
   });

   return response.result;
}

public async UpdateTemplate(templateId:number,reportTemplate:ReportAPITemplate ): Promise<boolean> {
  let response:any=await this.http.CallApiAsync({
       host: this.host,       
       callDesc: "UpdateTemplate",
       method: HttpMethod.Put,
       showError: true,  
       uriParams:{ id: templateId },
       body:reportTemplate        
   });

   return response.result;
}

public async UpdateListOrder(fromorder:number,toOrder:number): Promise<boolean> {
      let response:any=await this.http.CallApiAsync({
        host: this.host,       
        callDesc: "UpdateListOrder",
        method: HttpMethod.Patch,
        showError: true,  
        uriParams :{fromorder:fromorder,toOrder:toOrder}        
    });

return response.result;
}

public async DefaultTemplateExists(eventId:number[],id:number): Promise<boolean> {
      let response:any=await this.http.CallApiAsync({
        host: this.host,       
        callDesc: "DefaultTemplateExist",
        method: HttpMethod.Put,
        showError: true,  
        uriParams :{id:id},
        body:eventId
    });

    return response.result;
}

public async GetMaxListOrder(): Promise<number> {
  let response:any=await this.http.CallApiAsync({
       host: this.host,       
       callDesc: "GetMaxListOrder",
       method: HttpMethod.Get,
       showError: true,       
   });

   return response.result;
}

public async GetReportKeyWords(): Promise<ReportKeywordAPI[]> {
  let response:any=await this.http.CallApiAsync({
       host: this.host,       
       callDesc: "GetTemplateKeyWords",
       method: HttpMethod.Get,
       showError: true,       
   });

   return response.result;
}


public async GetAllEvents(): Promise<Events[]> {
  let response:any=await this.http.CallApiAsync({
       host: this.host,       
       callDesc: "GetAllEvents",
       method: HttpMethod.Get,
       showError: true            
   });
   response.result.forEach(element => {
    let eventname = EventReport[element.id];
    element.eventName = this.eventCaptions[eventname];
   });

   return response.result;
}

}
