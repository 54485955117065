import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomRowData, FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { CustomFormField } from 'src/app/eatecui/source/transaction/shared/interface/custom-form';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ToastrService } from 'ngx-toastr';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { CustomFormBuild, CustomFormBuildingGrouping, FormBuilderGroupKey, MasterInventoryManagement, NestedDataBuilder } from '../master.interface';
import { CustomFormBuilder, FormModelFieldBuilder } from './form-config.interface';
import { CreateTransactionService } from 'src/app/eatecui/source/transaction/create-transaction/create-transaction.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

export const CustomFormActions: CustomFormBuilder = (
    ViewModelEntity: any,
    PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey, FormGroupData: FormGroup, FormField: any, customData?: any,
    sysytemPreferenceService?: SysytemPreferenceService,
    masterService?: any, customBuilderData?: any, FileEvent?: any, showMoreEvent?: any, toasterService?: ToastrService,
    FormBuilder?: any, createTransactionService?: CreateTransactionService, matDialog?: MatDialog, translateService?: TranslateService, http?: HttpClient, 
    NestedDataBuild?: NestedDataBuilder, MasterParentFormGroup?: FormGroup): any => {
    try {
        if (FieldGrouping.hasOwnProperty('CustomFormAction') && FieldGrouping.CustomFormAction.EnableCustomMethod) {
            switch (FieldGrouping.CustomFormAction.CustomTypeName) {
                case 'CustomDefaultSelect':
                    CustomFormField.CustomFormSelection(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, FieldGrouping, FormGroupData, FormField);
                    break;
                case 'CustomFormLocation':
                    CustomFormField.CustomFormLocation(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, sysytemPreferenceService, 
                        null, customBuilderData, FileEvent, null, toasterService, createTransactionService,
                        translateService, matDialog, null);
                    break;
                case 'CustomTemplateFormLocation':
                    CustomFormField.CustomTemplateFormLocation(ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CompareFormLocation':
                    CustomFormField.CustomComapareLocation(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomTransferOut':
                    CustomFormField.CustomTransferOut(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomCustomerLocation':
                    CustomFormField.CustomCustomerLocation(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'addPicture':
                    CustomFormField.CustomPictureAdd(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, null, null, null, FileEvent, null, toasterService);
                    break;
                case 'UnitDropdownChange':
                    CustomFormField.CustomUnitDropdownChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, sysytemPreferenceService);
                    break;
                case 'CustomPITypesChange':
                    CustomFormField.CustomPITypesChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, null, createTransactionService, translateService, matDialog);
                    break;
                case 'RetailWizardOptions':
                    CustomFormField.CustomRetailWizardChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomPOFormLocation':
                    CustomFormField.CustomPOLocation(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, sysytemPreferenceService, createTransactionService, translateService);
                    break;
                case 'CustomPrefillPO':
                    CustomFormField.CustomPrefillPOData(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomPOSelect':
                    CustomFormField.CustomPODataSelect(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomDueDateSet':
                    CustomFormField.CustomDueDateSet(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'autoCompleteHeader':
                    CustomFormField.AutoCompleteSearch(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, sysytemPreferenceService, createTransactionService);
                    break;
                case 'autoCompleteHeaderApcredit':
                    CustomFormField.AutoCompleteSearchApcredit(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomReceivingLocationValidation':
                    CustomFormField.CustomReceivingLocationValidation(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, sysytemPreferenceService, createTransactionService, translateService);
                    break;
                case 'credittoatalcalculation':
                    CustomFormField.CreditCardCalcApCredit(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'calcpurchasecost':
                    CustomFormField.CalcPurchaseCost(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'calAllUnitCost':
                    CustomFormField.CalcAllUnitCOst(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'setHaccpType':
                    CustomFormField.setHaccpType(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'setSubType':
                    CustomFormField.setSubType(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'setCostValue':
                    CustomFormField.setCostValue(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'setUnitValue':
                    CustomFormField.setUnitValue(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'setmarkupvalue':
                    CustomFormField.SetUpMarkUpValue(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomPrefillInvoice':
                    CustomFormField.CustomPrefillInvoice(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'checkFormValue':
                    CustomFormField.checkFormValue(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'checkFormValidation':
                    CustomFormField.checkFormValid(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomPackageTypeChange':
                    CustomFormField.CustomPackageTypeChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'InventoryTypeChange':
                    CustomFormField.customInventoryTypeChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'ProductTypeChange':
                    CustomFormField.customProductTypeChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'autoCompleteInventoryLookUp':
                    CustomFormField.autoCompleteSearchInventory(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'autoCompleteUSDALookUp':
                    CustomFormField.autoCompleteSearchUSDALookUp(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'replaceitemreplaceinchange':
                    CustomFormField.replaceitemreplaceinchange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CostingPercentCalc':
                    CustomFormField.customCostPercentCalc(ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'GetBidRegion':
                    CustomFormField.GetBidRegion(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomGetCustomers':
                    CustomFormField.CustomGetCustomer(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'BarcodeEditClick':
                    CustomFormField.CustomBarCodeEdit(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, null, masterService);
                    break;
                case 'DeliveryRulesClick':
                    const dialog = customData ? customData : matDialog;
                    const HeaderPropName = NestedDataBuild?.NestedName !== 'SupplierSitesAccConfig' ? FormGroupData.value?.Name : MasterParentFormGroup?.value?.Name;
                    CustomFormField.DeliveryRulesClick(FormGroupData, HeaderPropName, dialog, http, NestedDataBuild, MasterDataCollection);
                    break;
                case 'enableSupplier':
                    CustomFormField.customEnableSupplier(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'enableReceipeApprove':
                    CustomFormField.custoreceipeApprove(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'recipeunitchange':
                    CustomFormField.customRecipeUnitChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, sysytemPreferenceService);
                    break;
                case 'cascadingdropdown':
                    CustomFormField.cascadingDropdown(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, customBuilderData);
                    break;
                case 'multipleautoCompleteHeader':
                    CustomFormField.multipleAutoCompleteSearch(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, showMoreEvent);
                    break;
                case 'templateAutoCompleteHeader':
                    CustomFormField.templatemultiAutoCompleteSearch(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData, showMoreEvent);
                    break;
                case 'CustomPeriodLengthChange':
                    CustomFormField.CustomPeriodLengthChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomMealPlanStartDate':
                    CustomFormField.CustomMealPlanStartDate(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'calMarkUpValue':
                    CustomFormField.CalMarkUpValues(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'addDietaryPreference':
                    CustomFormField.CustomDietaryPreference(ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'LocationChangeRestriction':
                    CustomFormField.LocationChangeRestriction(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, createTransactionService, matDialog, translateService);
                    break;
                case 'IngredientTypeIdChange':
                    CustomFormField.IngredientTypeIdChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'IngredientTypeChange':
                    CustomFormField.IngredientTypeChange(ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                case 'POPostDateChange':
                    CustomFormField.POPostDateChange(MasterDataCollection, FormGroupData, httpService, matDialog, translateService);
                    break;
                case 'CustomExchangeRate':
                    CustomFormField.GetCustomExchangeRate(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomCurrencyChange':
                    CustomFormField.GetCustomCurrencyChange(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                case 'CustomPriceCalculation':
                    CustomFormField.GetCustomPriceCalculation(
                        ViewModelEntity, PostModelEntity, MasterDataCollection, httpService,
                        FieldGrouping, FormGroupData, FormField, customData);
                    break;
                default:
                /* implement the statement(s) to be executed if expression
                doesn't match any of the above cases */
            }
        }
        const customGroupingData = {} as CustomRowData;

        return customGroupingData;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};
