import { Injectable } from '@angular/core';
import { HttpServiceCall, HttpMethod } from '../../service/http-call.service';
import { SpaUtilities } from '../../utilities/spa-utilities';
import { BreakPointAccess } from '../../service/breakpoint.service';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { LostDeniedReasonBusinessInfo, AppointmentLostDeniedBusinessClient, AppointmentFilterOutput, LocationViewModel, TherapistViewModel, ServiceViewModel, AppointmentWaitList, WaitListAppointmentInfo, multiClientInfo, BaseResponse, KeyValuePair, WaitListPriority, appointmentDetail, appointment, appointmentTherapist, ClientDetail, AppointmentBillingDetail, ManagementData, LostDeniedReasonBusiness } from '../../business/shared.modals';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Host, OperationType, AppointmentStatus, ButtonType, ActionType } from '../../globalsContant';
import { AppointmentUtilities } from '../../utilities/appointment-utilities';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentActionsDialogComponent } from '../../appointment-actions/appointment-actions-dialog/appointment-actions-dialog.component';
import { GuaranteeMethodConfig } from '../../appointment-popup/create-appointment/guarantee-method/guarantee-method.business';
import { appointmentService } from '../../service/appointment.service';

@Injectable()
export class LostDeniedBusinessPopupService {

  constructor(public dialog: MatDialog, public http: HttpServiceCall, public utils: SpaUtilities,
    public localization: SpaLocalization, public _appService: appointmentService, public breakPoint: BreakPointAccess, public appointmentUtility: AppointmentUtilities) {
  }

  selectedIndex: number = 0;
  appointmentDetailFormGroup: any;
  isAppointmentDetailFormValid: boolean = false;
  isFormDirty: boolean = false;
  appointmentDetail: LostDeniedReasonBusinessInfo;
  waitListId: number = 0;
  isAddClient: boolean = false;
  filterData: AppointmentFilterOutput;
  action: OperationType = 0;
  priority: KeyValuePair[] = [];
  confirmationDate: Date;
  viewWaitlistRef: any;
  guaranteeMethodConfig: GuaranteeMethodConfig;
  isGuaranteeMethodRequired = false;
  isFromAppointment:boolean = false;
  dataFromAppointmentScreen : any;

  async BuildPriority() {
      this.priority = [{ key: WaitListPriority.High, value: this.localization.captions.bookAppointment.WaitListPriorityHigh },
      { key: WaitListPriority.Medium, value: this.localization.captions.bookAppointment.WaitListPriorityMedium },
      { key: WaitListPriority.Low, value: this.localization.captions.bookAppointment.WaitListPriorityLow }];
  }

  getWaitListGridHeader() {
      let arrDataHeader: any;
      arrDataHeader = [
          { 'title': this.localization.captions.bookAppointment.DateRange, 'jsonkey': 'dateRange', 'searchable': false, 'sortable': false },
          { 'title': this.localization.captions.bookAppointment.Time, 'jsonkey': 'time', 'searchable': false, 'sortable': false },
          { 'title': this.localization.captions.bookAppointment.Client, 'jsonkey': 'client', 'searchable': true, 'sortable': false },
          { 'title': this.localization.captions.bookAppointment.Service, 'jsonkey': 'service', 'searchable': true, 'sortable': false },
          { 'title': this.localization.captions.bookAppointment.Location, 'jsonkey': 'location', 'searchable': true, 'sortable': false },
          { 'title': this.localization.captions.bookAppointment.Therapist, 'jsonkey': 'therapist', 'searchable': true, 'sortable': false },
          { 'title': this.localization.captions.bookAppointment.Priority, 'jsonkey': 'priority', 'searchable': false, 'sortable': false },
          { 'title': this.localization.captions.bookAppointment.GeneralComments, 'jsonkey': 'comments', 'searchable': false, 'sortable': false }];
      return arrDataHeader;
  }

  FilterLocation(filterData: AppointmentFilterOutput, FormGrp: any, selectedServiceGrp: number[]) {
      let location: LocationViewModel[] = [];
      let serviceGroup: number[] = selectedServiceGrp.length > 0 ? selectedServiceGrp : [];
      let service: number[] = FormGrp.get("service").value ? [Number(FormGrp.get("service").value)] : [];
      let therapistArray: number[] = [];
      if (FormGrp.controls['StaffDropDownFormArr'].value != null && FormGrp.controls['StaffDropDownFormArr'].value.length > 0) {
          for (let index = 0; index < FormGrp.controls['StaffDropDownFormArr'].value.length; index++) {
              const element = FormGrp.controls['StaffDropDownFormArr'].value[index];
              if (element.therapist > 0) {
                  therapistArray.push(Number(element.therapist));
              }
          }
      }

      let allFilters = _.cloneDeep(filterData);
      let allLocations = this.GetAllLocations(filterData.locationViewModels);
      if (allLocations.length == 0) {
          return location;
      }
      location = _.cloneDeep(allLocations).filter(res => {
          let isMatch = false;
          /* Selected Service Group */
          let availableService = allFilters.serviceViewModels.filter(a => {
              return serviceGroup.length == 0 || serviceGroup.includes(a.serviceGroupId);
          });
          /* Selected Service  */
          let filteredService = availableService.filter(a => {
              return service.length == 0 || service.includes(a.id);
          });

          //MatchingLocationForTherapist
          let filteredLocation = [];
          if (allFilters.therapistLocationModels) {
              filteredLocation = allFilters.therapistLocationModels.filter(x => {
                  return therapistArray.length == 0 || therapistArray.includes(x.therapistId);
              }).map(y => y.locationId);
          }

          /* Location Match? */
          filteredService.some(a => {
              allFilters.locationViewModels.some(b => {
                  if (a.id == b.serviceId && b.locationId == res.locationId && (filteredLocation == null || filteredLocation.length == 0 || filteredLocation[0] == 0 || therapistArray.length == 0 || (therapistArray.length > 1 ? this.GetLocationMapped(res.locationId, therapistArray, allFilters.therapistLocationModels) : filteredLocation.includes(res.locationId)))) {
                      isMatch = true;
                  }
                  return isMatch;
              });
              return isMatch;
          });
          return isMatch;
      });
      return location;
  }

  FilterTherapist(filterData: AppointmentFilterOutput, FormGrp: any, selectedServiceGrp: number[]) {
      let therapist: TherapistViewModel[] = [];
      let serviceGroup: number[] = selectedServiceGrp.length > 0 ? selectedServiceGrp : [];
      let service: number[] = FormGrp.get("service").value ? [Number(FormGrp.get("service").value)] : [];
      //let location: number[] = FormGrp.controls["location"] ? [Number(FormGrp.get("location").value)] : [];
      let allFilters = _.cloneDeep(filterData);
      let allTherapists = this.GetAllTherapists(filterData.therapistViewModels);
      if (allTherapists.length == 0)
          return therapist;
      therapist = _.cloneDeep(allTherapists).filter(res => {
          let isMatch = false;
          /* Selected Service Group */
          let availableService = allFilters.serviceViewModels.filter(a => {
              return serviceGroup.length == 0 || serviceGroup.includes(a.serviceGroupId);
          });
          /* Selected Service  */
          let filteredService = availableService.filter(a => {
              return service.length == 0 || service.includes(a.id);
          });

          let filteredTherapist = [];
        //   if (allFilters.therapistLocationModels) {
        //       filteredTherapist = allFilters.therapistLocationModels.filter(x => {
        //           return location.length == 0 || location[0] == 0 || location.includes(x.locationId) || x.locationId == 0;
        //       }).map(y => y.therapistId);
        //   }

          /* Therapist Match? */
          filteredService.some(function (a) {
              allFilters.therapistViewModels.some(function (b) {
                  if (a.id == b.serviceId && b.therapistId == res.therapistId && (filteredTherapist == null || filteredTherapist.length == 0 || filteredTherapist.includes(res.therapistId))) {
                      isMatch = true;
                  }
                  return isMatch;
              });
              return isMatch;
          });
          return isMatch;
      });
      return therapist;
  }
  async GetManagementData(): Promise<any> {
      const apiResponse = await this.InvokeServiceCallAsync('GetManagementData', Host.spaManagement, HttpMethod.Get);
      if (apiResponse && apiResponse.successStatus && apiResponse.result) {
        const response: ManagementData = apiResponse.result;
        //let managementData: any[] = [];
        this._appService.managementData ['Service'] = response.service;
        this._appService.managementData ['Therapist'] = response.therapist;
        this._appService.managementData ['Location'] = response.location;
        this._appService.managementData ['LostDeniedReasons']=response.lostDeniedReasons;
        this._appService.managementData ['ServiceGroups']=response.serviceGroups;
        //this._appService.managementData = managementData;
        this._appService.appointmentConfiguration = response.appointmentConfigurations;
      }
    }

  FilterService(filterData: AppointmentFilterOutput, FormGrp: any, selectedServiceGrp: number[]) {
      let service: ServiceViewModel[] = [];
      let serviceGroup: number[] = selectedServiceGrp.length > 0 ? selectedServiceGrp : [];
      //let location: number[] = FormGrp.get("location").value && FormGrp.get("location").value != "0" ? [Number(FormGrp.get("location").value)] : [];
      let therapist: number[] = [];
      if (FormGrp.controls['StaffDropDownFormArr'].value != null && FormGrp.controls['StaffDropDownFormArr'].value.length > 0) {
          for (let index = 0; index < FormGrp.controls['StaffDropDownFormArr'].value.length; index++) {
              const element = FormGrp.controls['StaffDropDownFormArr'].value[index];
              if (element.therapist > 0) {
                  therapist.push(Number(element.therapist));
              }
          }
      }
      let allFilters = _.cloneDeep(filterData);
      if (!allFilters)
          return;

      /* Group filtered */
      let tempService = _.cloneDeep(allFilters.serviceViewModels).filter(res => {
          return serviceGroup.length == 0 || serviceGroup.includes(res.serviceGroupId);
      });

      /* Location filtered */
    //   tempService = tempService.filter(res => {
    //       let isMatch = false;
    //     //   allFilters.locationViewModels.some(a => {
    //     //       isMatch = res.id == a.serviceId && (location.length == 0 || location.includes(a.locationId)) ? true : false;
    //     //       return isMatch;
    //     //   });
    //       return isMatch;
    //   });
      /* Therpist filtered */
      tempService = tempService.filter(res => {
          let isMatch = false;
          allFilters.therapistViewModels.some(a => {
              isMatch = res.id == a.serviceId && (therapist.length == 0 || therapist.includes(a.therapistId)) ? true : false;
              return isMatch;
          });
          return isMatch;
      });
      service = tempService;
      return service;
  }

  GetLocationMapped(locationId: any, therapistArray: any, filters: any): boolean {
      let isMatch = true;
      therapistArray.forEach(x => {
          let matchingLocation = filters.filter(y => y.therapistId == x);
          if (matchingLocation && matchingLocation.length > 0 && Number(matchingLocation[0].locationId) != 0 && Number(matchingLocation[0].locationId) != Number(locationId)) {
              isMatch = false;
          }
      });
      return isMatch;
  }

  GetAllTherapists(therapists: TherapistViewModel[]): TherapistViewModel[] {
      let result: TherapistViewModel[] = [];
      for (let i = 0; i < therapists.length; i++) {
          if (!result.find(r => r.therapistId == therapists[i].therapistId)) {
              result.push(therapists[i]);
          }
      }
      return result;
  }

  GetAllLocations(locations: LocationViewModel[]): LocationViewModel[] {
      let result: LocationViewModel[] = [];
      for (let i = 0; i < locations.length; i++) {
          if (!result.find(r => r.locationId == locations[i].locationId)) {
              result.push(locations[i]);
          }
      }
      return result;
  }

  BuildWaitListData(multipleClientInfo: multiClientInfo[], therapistId: number[], billingDetail: string): LostDeniedReasonBusiness {
       let data: LostDeniedReasonBusiness;
       let fromTime,toTime;
      if (this.appointmentDetail) {
        if(this.localization.time24formatFlag == 24){
            fromTime = this.appointmentDetail.startTime,
            toTime = this.appointmentDetail.endTime
        } else{
            fromTime = moment(this.appointmentDetail.startTime, this.localization.timeFormat).format("hh:mm a");
            toTime = moment(this.appointmentDetail.endTime, this.localization.timeFormat).format("hh:mm a");

       }
          let fromdateTime: any = this.localization.ConvertDateToISODate(<any>this.appointmentDetail.startDate) + "T" + fromTime;
          let toDateTime: any = this.localization.ConvertDateToISODate(<any>this.appointmentDetail.endDate) + "T" + toTime;

          data = {
              id: this.appointmentDetail.id,
              startTime: this.utils.getDate(fromdateTime),
              endTime: this.utils.getDate(toDateTime),
              comments: this.appointmentDetail.comments,
              serviceId: Number(this.appointmentDetail.serviceId),
              client: [],
              serviceGroupId: Number(this.appointmentDetail.serviceGroupId),
              price :this.appointmentDetail.price, //? this.localization.currencyToSQLFormat(this.appointmentDetail.price) : 0,
              waitListId : this.appointmentDetail.waitListId,
              lostDeniedReasonId : this.appointmentDetail.lostDeniedReasonId,
              therapistId : 0
          };

          if (multipleClientInfo && multipleClientInfo.length > 1) {
              for (let i = 0; i < multipleClientInfo.length; i++) {
                  data.client.push(
                      {
                          id: 0,
                          LostDeniedBusinessId : this.appointmentDetail.id,
                          therapistId: multipleClientInfo[i].TherapistId,
                          clientId: multipleClientInfo[i].id,
                          comments: multipleClientInfo[i].comments,
                      });
              }
          }
          else {
              for (let i = 0; i < therapistId.length; i++) {
                  data.client.push(
                      {
                          id: 0,
                          LostDeniedBusinessId : this.appointmentDetail.id,
                          therapistId: therapistId[i],
                          clientId: multipleClientInfo[0].id,
                          comments: multipleClientInfo[0].comments,
                      });
              }
          }
          console.log(data);
      }
      if (this.appointmentDetail.guaranteePaymentMethod && billingDetail) {
          data.billingDetail = {
              id: this.appointmentDetail.guaranteePaymentMethod?.id || 0,
              appointmentLostDeniedBusinessId: this.appointmentDetail.id,
              billingDetail: billingDetail
          };
      }
      return data;
  }

  InitializeData() {
      this.appointmentDetail = {
          id: 0,
          startTime: '',
          endTime: '',
          startDate : '',
          endDate : '',
          serviceId: 0,
          serviceGroupId: 0,
          lostDeniedReasonId:0,
          therapistId : 0,
          price :0,
          comments: '',
          client: [],
          waitListId : 0
      };
  }

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
      try {
          return await this.http.CallApiAsync({
              host: domain,
              callDesc: route,
              method: callType,
              body: body,
              uriParams: uriParams,
          });
      } catch (e) {
          this.http.exceptionHandle(e);
      }
  }

  async GetFilters() {
      let apiResponse: BaseResponse<AppointmentFilterOutput> = await this.InvokeServiceCallAsync("WaitListFilter", Host.schedule, HttpMethod.Get, { date: null });
      if (apiResponse && apiResponse.result) {
          await this.SetFilterObject(apiResponse.result);
      }
  }

  async SetFilterObject(result: AppointmentFilterOutput) {
      this.filterData = result;
  }

//   async BuildTableData(lostDeniedBusinessData): Promise<any> {
//       let tableDataArray: any = [];
//       for (let i = 0; i < lostDeniedBusinessData.length; i++) {
//           const lostDeniedBusinessResult = lostDeniedBusinessData[i];
//           let tableData = {
//               client: this.GetClientName(lostDeniedBusinessResult.client),
//               clientTherapist: lostDeniedBusinessResult.client,
//               billingDetail: lostDeniedBusinessResult.billingDetail,
//               therapist: this.GetTherapistName(lostDeniedBusinessResult.client),
//           }
//           tableDataArray.push(tableData);
//       }

//       return tableDataArray;
//   }




  private GetClientName(client: AppointmentLostDeniedBusinessClient[]): string {
      let clientName: string = '';
      let clientArray: KeyValuePair[] = [];
      for (let item of client) {
          if (!clientArray.some(r => r.key == item.clientId)) {
              clientArray.push({ key: item.clientId, value: `${item.firstName} ${item.lastName}` });
          }
      }
      if (clientArray.length > 0) {
          clientName = clientArray.map(r => r.value).join(',');
      }
      return clientName;
  }

  private GetTherapistName(therap: AppointmentLostDeniedBusinessClient[]): string {
      let therapistName: string = '';
      let therapistArray: KeyValuePair[] = [];
      for (let i = 0; i < therap.length; i++) {
          if (!therapistArray.some(r => r.key == therap[i].therapistId)) {
              therapistArray.push({ key: therap[i].therapistId, value: `${this.appointmentUtility.GetTherapistNameById(therap[i].therapistId)}` });
          }
      }
      if (therapistArray.length > 0) {
          therapistName = therapistArray.map(r => r.value).join(',');
      }
      return therapistName;
  }

  private GetPriority(priority: WaitListPriority): string {
      return this.priority.find(r => r.key == priority).value;
  }

  // Returns availabe waitlist for the given date
  public FilterWaitList(filterDate: Date, waitList: AppointmentWaitList[]): AppointmentWaitList[] {
      let result: AppointmentWaitList[] = [];
      let input: AppointmentWaitList[] = _.cloneDeep(waitList);
      // Removing Time part since we need to compare only date
      filterDate.setHours(0, 0, 0, 0);
      input.forEach(r => {
          r.startTime.setHours(0, 0, 0, 0);
          r.endTime.setHours(0, 0, 0, 0);
      });
      result = input.filter(r => {
          return (r.startTime.getTime() <= filterDate.getTime() && r.endTime.getTime() >= filterDate.getTime())
      });

      if (result && result.length > 0) {
          let filteredWaitlistId = result.map(w => w.id);
          result = waitList.filter(w => filteredWaitlistId.includes(w.id));
      }

      return result;
  }

  public async GetAllFilters(serviceId: number = 0, date: any = null): Promise<AppointmentFilterOutput> {
      var result: AppointmentFilterOutput;
      let apiResponse: BaseResponse<AppointmentFilterOutput> = await this.InvokeServiceCallAsync("WaitListFilter", Host.schedule, HttpMethod.Get, { date: date });
      if (apiResponse && apiResponse.successStatus && apiResponse.result) {
          if (serviceId > 0) {
              result = {
                  serviceViewModels: apiResponse.result.serviceViewModels.filter(r => r.id == serviceId),
                  therapistViewModels: apiResponse.result.therapistViewModels.filter(r => r.serviceId == serviceId),
                  locationViewModels: apiResponse.result.locationViewModels.filter(r => r.serviceId == serviceId),
                  therapistLocationModels: apiResponse.result.therapistLocationModels
              };
          }
          else {
              result = apiResponse.result;
          }
      }
      this.filterData = _.cloneDeep(result);
      return result;
  }

  public GetTherapistInfo(therapistId: number[]): string {
      let therapistName: string[] = [];
      if (!this.filterData || !this.filterData.therapistViewModels || this.filterData.therapistViewModels.length == 0) {
          return '';
      }
      therapistId.forEach(r => {
          let therapist: TherapistViewModel = this.filterData.therapistViewModels.find(t => t.therapistId == r);
          if (therapist) {
              therapistName.push(therapist.therapistName);
          }
      });
      return therapistName.join(',');
  }

  public async BuildAppointmentInfo(clientTherapist: ClientsTherapists[], startTime: Date, service: ServiceViewModel, locationId: number, tableData: any): Promise<appointment[]> {
      let appObjArr: appointment[] = [];
      let appDetail: appointmentDetail;
      let apptTherapist: appointmentTherapist[] = [];
      let totalClients: number[] = clientTherapist.map(t => t.id);
      let clientInfo: ClientDetail[] = [];
      let [priceInfo, clientResponse] = await Promise.all([this.appointmentUtility.GetAppointmentPrice(service, startTime),
      this.InvokeServiceCallAsync("GetClientByIds", Host.spaManagement, HttpMethod.Put, '', totalClients)]);

      if (clientResponse && clientResponse.successStatus && clientResponse.result) {
          clientInfo = clientResponse.result;
      }
      console.log(clientInfo);
      for (const client of clientTherapist) {
          apptTherapist = [];
          var guestId = clientInfo.find(c => c.id == client.id).guestId;
          appDetail = {
              startTime: this.localization.ConvertDateToISODateTime(startTime),
              endTime: this.localization.ConvertDateToISODateTime(startTime),
              originalStartTime: this.localization.ConvertDateToISODateTime(startTime),
              originalEndTime: this.localization.ConvertDateToISODateTime(startTime),
              serviceGroupId: tableData.serviceGroupId,
              serviceId: service.id,
              locationId: locationId || 0,
              clientId: client.id,
              comments: tableData.comments,
              status: AppointmentStatus.Scheduled,
              cancelId: '',
              duration: service.duration && !isNaN(service.duration) ? service.duration : 0,
              setUpTime: service.setupTime && !isNaN(service.setupTime) ? service.setupTime : 0,
              breakDownTime: service.breakDownTime && !isNaN(service.breakDownTime) ? service.breakDownTime : 0,
              price: priceInfo ? priceInfo.price : 0,
              doNotMove: false,
              requestStaff: false,
              isBookingLocked: false,
              isVip: false,
              genderPreference: 0,
              checkOutComments: '',
              noShowComments:'',
              noShow: false,
              packageId: 0,
              appointmentType: '',
              cancelReason: 0,
              cancelFee: 0,
              cancelComments: '',
              customField1: null,
              customField2: null,
              customField3: null,
              customField4: '',
              customField5: '',
              tempHoldId: '',
              tempHoldLinkId: 0,
              isTherapistOverbook: false,
              isLocationOverbook: false,
              linkCodeId: 0,
              guestId: guestId ? guestId : null,
              clientComments: tableData.clientTherapist.find(r => r.clientId == client.id).comments,
              ServiceCharge: priceInfo ? priceInfo.serviceCharge : 0,
              Gratuity: priceInfo ? priceInfo.gratuity : 0,
              Tax: 0,
              TotalAmount: priceInfo ? priceInfo.totalAmount : 0,
              yieldPrice: priceInfo && priceInfo.yieldPrice ? priceInfo.yieldPrice : 0,
              serviceChargePercent: service.serviceChargePercent,
              gratuityPercent: service.gratuityPercent,
              intakeForm: false
          }
          client.therapists.forEach(t => {
              apptTherapist.push(
                  {
                      appointmentId: 0,
                      therapistId: t,
                      id: 0
                  }
              )
          });
          let waitlistBillingInfo: AppointmentBillingDetail = null;
          if (tableData.billingDetail) {
              waitlistBillingInfo = {
                  id: 0,
                  appointmentId: 0,
                  billingDetail: tableData.billingDetail.billingDetail
              }
          }
          appObjArr.push(
              {
                  appointmentAddOn: [],
                  appointmentDetail: appDetail,
                  appointmentTherapists: apptTherapist,
                  appointmentBillingDetail: waitlistBillingInfo
              }
          );
      }
      return appObjArr;
  }

  // Returns table data to show available waitlist on given date range
//   async GetNearningWaitlist(canceledDate: Date[], availableWaitLists: AppointmentWaitList[]): Promise<any> {
//       let isMatchFound = true;
//       let tableData: any = await this.BuildTableData(availableWaitLists);
//       this.confirmationDate = canceledDate[0];
//       // If Multiple appointments are canceled with diff date,
//       // consider to take first least date(if waitList available) of cancellation as waitlist confirm date
//       if (canceledDate.length > 1) {
//           isMatchFound = false;
//           availableWaitLists.forEach(r => {
//               r.startTime = this.utils.getDate(r.startTime);
//               r.endTime = this.utils.getDate(r.endTime);
//           });
//           for (let i = 0; i < canceledDate.length; i++) {
//               var filteredResult: AppointmentWaitList[] = this.FilterWaitList(this.utils.getDate(canceledDate[i]), availableWaitLists);
//               if (filteredResult && filteredResult.length > 0) {
//                   isMatchFound = true;
//                   availableWaitLists = filteredResult;
//                   tableData = await this.BuildTableData(filteredResult);
//                   this.confirmationDate = canceledDate[i];
//                   break;
//               }
//           }
//       }
//       tableData = isMatchFound ? tableData : null;
//       return tableData;
//   }

  public ValidateMaximumTherapist(maximumTherapist: number, clientTherapist: ClientsTherapists[], showError: boolean = false): boolean {
      let isValid: boolean = false;
      let therapistArray: number[][] = clientTherapist.map(t => t.therapists);
      let totalSelectedTherapist: number[] = [].concat.apply([], therapistArray);
      // total count of selected therapist for service
      totalSelectedTherapist = Array.from(new Set(totalSelectedTherapist)); // remove duplicate therapists
      isValid = totalSelectedTherapist.length <= maximumTherapist;
      if (!isValid && showError) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(100010), ButtonType.Ok);
      }
      return isValid;
  }

  public ValidateMinimumTherapist(minimumTherapist: number, clientTherapist: ClientsTherapists[], showError: boolean = false): boolean {
      let isValid: boolean = false;
      let therapistArray: number[][] = clientTherapist.map(t => t.therapists);
      let totalSelectedTherapist: number[] = [].concat.apply([], therapistArray);
      // total count of selected therapist for service
      totalSelectedTherapist = Array.from(new Set(totalSelectedTherapist)); // remove duplicate therapists
      isValid = totalSelectedTherapist.length >= minimumTherapist;
      if (!isValid && showError) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, `${this.localization.getError(100011)} ${minimumTherapist}`, ButtonType.Ok);
      }
      return isValid;
  }
  public ValidateAllTherapistAssigned(clientTherapist: ClientsTherapists[], showError: boolean = false): boolean {
      let isValid = !clientTherapist.some(r => r.therapists.length == 0);
      if (!isValid && showError) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(100014), ButtonType.Ok);
      }
      return isValid;
  }
  public async IsShowAutomaticRecap(): Promise<boolean> {
      let showRecap = false;
      let result: BaseResponse<any> = await this.http.CallApiAsync({
          host: Host.spaManagement,
          callDesc: "GetConfiguration",
          method: HttpMethod.Get,
          body: null,
          uriParams: { module: 'Appointment', Switch: 'APPOINTMENT_SHOW_RECAP' },
      });
      if (result && result.result) {
          showRecap = result.result.value;
      }
      return showRecap;
  }

  public async ShowRecap(AppointmentId: number, clientId: number, AppointmentDate: Date, Appointments: any) {
      let from = "NewAptSummary";

      let clientArray = [];
      let therapistArray = [];
      Appointments.forEach(x => {
          if (clientArray.indexOf(x.appointmentDetail.clientId) == -1) {
              clientArray.push(x.appointmentDetail.clientId)
          }
          x.appointmentTherapists.forEach(y => {
              if (therapistArray.indexOf(y.toString()) == -1) {
                  therapistArray.push(y);
              }
          })
      });
      therapistArray = therapistArray.map(r => { return { 'TherapistId': r.therapistId } });
      let appinfo = { AppointmentId, clientId, AppointmentDate, from, clientArray, therapistArray };
      this.dialog.open(AppointmentActionsDialogComponent, {
          width: '90%',
          maxWidth: "1000px;",
          height: '80%',
          hasBackdrop: true,
          data: { id: ActionType.recap, headername: this.localization.captions.bookAppointment.AppointmentRecap, closebool: true, appointmentSummaryInfomation: appinfo },
          panelClass: 'action-dialog-overlay'

      });
  }

  assignGuaranteeInput() {
      let waitlistId = this.appointmentDetail.billingDetail?.appointmentLostDeniedBusinessId || 0;
      let id = this.appointmentDetail.billingDetail?.id || 0;
      this.guaranteeMethodConfig = {
          billingDetail: { id: id, appointmentId: waitlistId, billingDetail: this.appointmentDetail.billingDetail?.billingDetail },
          isMandatoryOnAppointment: this.isGuaranteeMethodRequired,
          bookingStartDate: this.appointmentDetail.startDate,
          bookingEndDate: this.appointmentDetail.endDate,
      }
  }



  public async LoadServices(ismanual: boolean = false) {
      let services = await this.http.CallApiAsync<any>({
          host: Host.spaManagement,
          callDesc: "GetAllSpaServiceByUserId",
          method: HttpMethod.Get,
          showError: true
      });
      return services;
  }


  public async LoadLocations(ismanual: boolean = false) {
      let locations = await this.http.CallApiAsync<any>({
          host: Host.spaManagement,
          callDesc: "GetAllLocByUserId",
          method: HttpMethod.Get,
          showError: true
      });
      return locations;
  }

}

export interface Therapists {
  id: number;
  name: string;
}

export interface ClientsTherapists {
  id: number;
  name: string;
  therapists: number[];
}
