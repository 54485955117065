import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridTransaction } from '../grid-transaction';
import { GridConfiguration, HeaderConfiguration, TilesConfiguration, TransactionConfiguration } from '../transaction.interface';
import { ViewTransactionConfiguration } from './view-configuration.interface';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';

export const ViewGridConfiguration: ViewTransactionConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity?: any, responseData ?: Array<any>,
    httpClient?: HttpClient, integrationService ?: IntegrationService, precolumnOrderJson?: any): GridConfiguration => {
    try {
        if (ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
            const gridConfiguration = {} as GridConfiguration;
            gridConfiguration.ClearSearchFilter = false;
            gridConfiguration.GridName = GridTransaction.GridNameConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, translateService, TilesHeaderTrascationEntity);
            gridConfiguration.GridOdataKey = GridTransaction.GridOdataKeyConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.ShowActiveToggle = GridTransaction.GridShowToggleConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.ShowAddNew = GridTransaction.GridShowAddnNewConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridTitleType = GridTransaction.GridTitleConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridFilter = GridTransaction.GridFilterConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridSort = GridTransaction.GridSortConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridSearch = GridTransaction.GridSearchConfig(
                ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, translateService, TilesHeaderTrascationEntity);
            gridConfiguration.GridColumnHide = GridTransaction.GridColumnHide(
            ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridHeaderDefination = GridTransaction.GridHeaderDefination( ViewHeaderEntity, ViewDetailsEntity, 
            TransactionCollection, httpService, translateService, TilesHeaderTrascationEntity, responseData, httpClient, integrationService, precolumnOrderJson);       
            gridConfiguration.GridData = GridTransaction.GridData( ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, 
            httpService, translateService, TilesHeaderTrascationEntity, responseData);     
            gridConfiguration.GridCustomExpand = GridTransaction.GridCustomExpand(
            ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridActions = GridTransaction.GridRowActions( ViewHeaderEntity, ViewDetailsEntity, 
                TransactionCollection, httpService, translateService, TilesHeaderTrascationEntity, responseData);
            gridConfiguration.GridRowActions = GridTransaction.GridActionsConfig(
            ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridTableCards = GridTransaction.GridTableCardsConfig(
            ViewHeaderEntity, ViewDetailsEntity, TransactionCollection, httpService, TilesHeaderTrascationEntity);
            gridConfiguration.GridRowActions =  TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowActions;
            gridConfiguration.GridRowSelectable =  TransactionCollection.ViewTranscationConfig.GridConfiguration.GridRowSelectable;
            gridConfiguration.EnableCombinedFilter = TransactionCollection.ViewTranscationConfig.GridConfiguration?.EnableCombinedFilter ? 
            TransactionCollection.ViewTranscationConfig.GridConfiguration.EnableCombinedFilter : false;
            return gridConfiguration;
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};