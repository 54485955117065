import { JobType } from "src/app/common/Models/notification.model"

export namespace DefaultUrlConfigAPI
{
  export interface DefaultConfig{
    id : number
    productId: number
    jobType: JobType
    jobName: string,
    route : string,
    moduleType : number,
  }
//   export enum JobType{
//     GenericExtract = 1,
//     Reports,
//     ExecuteQuery,
//     API,
//     AdhocReport
//   }
}

export namespace DefaultUrlConfigUI
{
    export interface DefaultConfig
    {
      id : number
      JobType: JobType
      JobName: string,
      Route : string,
      moduleType : number,
      productId : number
    }

    export enum JobType{
      GenericExtract = 1,
      Reports,
      ExecuteQuery,
      API,
      AdhocReport
    }
}

export namespace customUrlUI{
  export interface CustomConfig{
    id : number
    JobCustomConfigurationId  : number,
    JobDefaultConfigurationId  : number,
    JobType: JobType
    JobName: string,
    Route : string,
    ModuleType : number,
    ProductId : number
  }

  export enum JobType{
    GenericExtract = 1,
    Reports,
    ExecuteQuery,
    API,
    AdhocReport
  }
}

export namespace customUrlApi{
  export interface CustomConfig{
    id : number,
    jobCustomConfigurationId  : number,
    jobDefaultConfigurationId  : number,
    jobType: JobType
    jobName: string,
    route : string,
    moduleType : number,
    productId : number
  }

  export enum JobType{
    GenericExtract = 1,
    Reports,
    ExecuteQuery,
    API,
    AdhocReport
  }
}

export namespace UI{
  export interface jobScheduleConfig{
    id : number
    JobDefaultSaveOption : string,
    FtpSetupId : string,
    IsTracingEnabled : boolean,
    JobCustomUrlConfiguration :customUrlUI.CustomConfig[]
  }

  export enum JobDefaultSaveOption{
    Database,
    Ftp
  }

}

export namespace API{
  export interface jobScheduleConfig{
    id : number
    jobDefaultSaveOption : JobDefaultSaveOption,
    ftpSetupId : string,
    isTracingEnabled : boolean,
    jobCustomUrlConfiguration :customUrlApi.CustomConfig[]
  }

  export enum JobDefaultSaveOption{
    Database,
    Ftp
  }
}

