<section class="toggle {{field.class}}" title="{{field?.fieldType?.SingleData?.CustomToolTip?.ShowValue ?
    form.controls[this.formFieldName]?.value : formFieldToolTip}}"
    *ngIf="!field.hide && fieldType == 'SingleData' || fieldType == 'groupingDataSingle' || fieldType == 'groupingDataMultiple'">
    <label class="toggle__label">{{formFieldlabel}}</label>
    <div class="toggle__button" [formGroup]="form">
        <label class="toggle__switch" [ngClass]="{'disable' : field.fieldType[fieldType].disbaledProperty}">
            <input class="toggle__switch-input" type="checkbox" [formControlName]="formFieldName"
                (change)="toggleChange($event)" />
            <span class="toggle__switch-label {{formFieldName}}"></span>
            <span class="toggle__switch-handle"></span>
        </label>
        <span class="switch-yes" *ngIf="value"> {{yes}}</span>
        <span class="switch-no" *ngIf="!value">{{no}}</span>
    </div>
</section>