import { Component, OnInit, ViewEncapsulation, Input, OnChanges, OnDestroy, ViewChild, HostListener, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { appointmentService } from '../../../service/appointment.service';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { SubscriptionLike as ISubscription, Subscription, ReplaySubject } from 'rxjs';
import { GuestPolicyDetail,PolicyCategoryType } from 'src/app/common/shared/shared.modal';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { takeUntil } from 'rxjs/operators';
import { ConsentManagementBusiness } from 'src/app/common/consent-management/consent-management.business';

import { GuestPolicyWrapperComponent } from 'src/app/shared/appointment-popup/create-client/additional-information/guest-policy-wrapper/guest-policy-wrapper.component'



@Component({
  selector: 'app-service-and-client-details',
  templateUrl: './service-and-client-details.component.html',
  styleUrls: ['./service-and-client-details.component.scss'],
  providers: [ConsentManagementBusiness],
  encapsulation: ViewEncapsulation.None
})
export class ServiceClientDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() sections: any[];
  @Input() checkIn: boolean = false;
  @Input() automationId: string;
  clientInfo: any = {};
  serviceInfo: any = {};
  comments: any = {};
  otherInfo: any;
  caption: any = this.localization.captions.bookAppointment;
  imageContent: any = {};
  showService: boolean;
  IsGDPREnabled: boolean = false;
  IsConsentPolicy: boolean = false;
  showPackage: boolean;
  showComments: boolean;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showClient: boolean;
  imgSubscription: ISubscription;
  topValue: any;
  leftValue: any;
  containerWidth: any;
  noShowIcon: boolean;
  clientGender = '';
  @ViewChild('navView') el: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.testText();
  }

  constructor(public apptService: appointmentService, private _consentManagementBusiness: ConsentManagementBusiness, public dialog: MatDialog, public localization: SpaLocalization) {
    this.serviceInfo = apptService.serviceInfo;
    this.clientInfo = apptService.clientInfo;
    this.comments = apptService.comments;
    this.otherInfo = apptService.otherinfo;
  }

  ngOnInit() {
    this.imgSubscription = this.apptService.clientImageObj.subscribe(x => {
      this.imageContent = x && x[0] ? x[0] : {};
    });
    this._consentManagementBusiness.GetIsGdprConfiguredFlag().then(res => {
      this.IsGDPREnabled = !!res;
    });
    this.check();
    this.setGenderCaption();
  }

  setGenderCaption(){
    if(this.apptService.clientInfo.gender)
    this.clientGender = this.apptService.clientInfo.gender.toLowerCase == 'male' ? this.localization.captions.common.Male : 
                        this.apptService.clientInfo.gender.toLowerCase == 'female' ? this.localization.captions.common.Female  : '';
  }

  ngOnDestroy() {
    if (this.imgSubscription) {
      this.imgSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.showService = this.sections.some(x => x === 'service');
    this.showPackage = this.sections.some(x => x === 'package');
    this.showClient = this.sections.some(x => x === 'client');
    this.showComments = this.sections.some(x => x === 'comments');
  }

  ngAfterViewInit() {

    this.containerWidth = document.getElementsByClassName('details-container')[0]['clientWidth'];
    setTimeout(() =>
      this.testText(), 1000);
    this.check();
  }

  check() {
    if(this.apptService.clientInfo.consentPolicyId)
    {
      this._consentManagementBusiness.GetPolicyTypeUsingPolicyId(this.clientInfo.consentPolicyId).then(res => {
        if (res == 2) {
          this.IsConsentPolicy = true;
        }
      });
    } 
  }

  testText() {
    this.topValue = document.getElementsByClassName('details-container')[0]['offsetTop'] + 5;
    this.leftValue = document.getElementsByClassName('mat-dialog-container')[0]['clientWidth'];
    let scrollWidth = document.getElementsByClassName('section-container ')[0]['scrollWidth'];
    let clientWidth = document.getElementsByClassName('details-container')[0]['clientWidth'];
    if (scrollWidth <= clientWidth) {
      this.noShowIcon = true;
    }
    else {
      this.noShowIcon = false;
    }
  }

  scrolltoRight() {

    this.leftArrowDisabled = false;
    this.rightArrowDisabled = true;
    let element = this.el.nativeElement;
    let scrollValue = element.scrollLeft + this.containerWidth;
    let scrollWidth = element.scrollWidth;
    element.scrollLeft = scrollValue;

    if ((scrollWidth - (scrollValue)) > 0) {
      this.rightArrowDisabled = false;
    }
  }

  leftArrowDisabled = false;
  rightArrowDisabled = false;
  scrolltoLeft() {
    this.rightArrowDisabled = false;
    let element = this.el.nativeElement;
    let scrollValue = element.scrollLeft - this.containerWidth;
    element.scrollLeft = scrollValue;

    if (scrollValue <= 0) {
      this.leftArrowDisabled = true;
    }
  }

  openGuestPolicyDialog() {
    let guestPolicyDetail: GuestPolicyDetail = {
      id: this.apptService.clientInfo.guestId,
      consentDate: this.apptService.clientInfo.consent,
      consentExpiryDate: this.apptService.clientInfo.consentExpiryDate,
      consentPolicyId: this.apptService.clientInfo.consentPolicyId,
      comments: "",
      isPurged: this.apptService.clientInfo.isPurged,
      policyCategoryType : PolicyCategoryType.Guest
    }
    this.dialog.open(GuestPolicyWrapperComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: {
        guestPolicyDetail: guestPolicyDetail,
        popupType: 1
      },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res != undefined) {
        let guestPolicyDetail: ApplyPolicy = res as ApplyPolicy
        this.clientInfo.consent = new Date(guestPolicyDetail.consentDate);
        this.clientInfo.consentExpiryDate = new Date(guestPolicyDetail.consentExpiryDate);
        this.clientInfo.consentPolicyId = guestPolicyDetail.policyId;
        this.apptService.clientInfo = this.clientInfo;
      }
      this.check();
    });
  }

}
