import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ifboolean'
})
export class IfbooleanPipe implements PipeTransform {

  transform(value: any, IfBoolean:any): boolean {
    let ReturnBool:boolean;
    if (typeof IfBoolean === "boolean") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  }
}
