import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormInterface } from '.';
import { FieldEdmType } from '../grid-interface/fieldEdmType.enum';
import { CustomFormBuild, MasterInventoryManagement } from '../master.interface';
import { FormFieldType } from './fieldType.enum';
import { FieldBuilderType } from './form-builder-type.enum';
import { FormModelConfiguration } from './form-config.interface';

export const FormRendererType: FormModelConfiguration = (
ViewModelEntity: any, PostModelEntity: any, MasterDataCollection: MasterInventoryManagement, 
httpService: HttpService, postModelEntityObject: any): any => {
    try {
        let FieldBuilder = FieldBuilderType.Primary.toString();
        if (ViewModelEntity.length > 0 && MasterDataCollection && PostModelEntity.length > 0 ) {
             if (MasterDataCollection.FormConfig && MasterDataCollection.FormConfig.hasOwnProperty('CustomFormBuilder')) {
                const keyName =  postModelEntityObject['$']['Name'];
                const BuilderName = MasterDataCollection.FormConfig.CustomFormBuilder
                .filter((x: CustomFormBuild) => (x.CustomBuilderKey.findIndex(builderKey => builderKey === keyName) > -1));
                if (BuilderName.length > 0) {
                   FieldBuilder = BuilderName[0].CustomBuilderName;
                }

             }
        }
        return FieldBuilder;
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};