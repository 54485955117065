import { AgDateConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { Input, Output, Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionMode, ButtonType, Product } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CancallationNoShowSharedService } from '../../cancellation-no-show-policy/cancellation-no-show-shared.service';
import { MainGroup } from '../../cancellation-no-show-policy/cancellation-no-show-policy.model';
import { PropertyInformation } from '../../../services/property-information.service';

@Component({
  selector: 'app-create-cancellation-no-show-mapping',
  templateUrl: './create-cancellation-no-show-mapping.component.html',
  styleUrls: ['./create-cancellation-no-show-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCancellationNoShowMappingComponent implements OnInit {

  policyMappingForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchText: string;
  selectedMainGroupList = [];
  subHeader: any;
  mainHeader: any;
  originalData = [];
  searchGuestMaxLength = 50;
  @Input() isViewOnly = true;
  @Input() applyToOptions;
  @Input() mainGroupList : MainGroup [];
  @Input() productId;
  @Input() searchPlaceholder;
  @Input() inputs;
  @Input() policyOptions;
  @Output() formReady = new EventEmitter();
  @Output() handleClickEvent = new EventEmitter();
  startDateInput: AgDateConfig;
  endDateInput:  AgDateConfig;
  isApplyToSelected: boolean = false;
  isMappingSelected: boolean = false;
  currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
  public defaultDate: Date;
  allPlayerTypeSelected: boolean = false;
  constructor(private localization: Localization, private propertyInformation: PropertyInformation,
    private fb: UntypedFormBuilder, private utils: CommonUtilities,private cancellationNoShowService: CancallationNoShowSharedService) {
      this.captions = this.localization.captions;
    }

  ngOnInit(): void {
    this.defaultDate = this.propertyInformation.CurrentDTTM;
    this.cancellationNoShowService.mappingData$.pipe(takeUntil(this.destroyed$)).subscribe(payload => {
      if(payload != null){
        
      }
    });
    this.formGenerator();
    this.originalData = this.mainGroupList;
    this.mainHeader = this.productId ? this.productId == Product.GOLF ? this.captions.playerType : this.captions.serviceGroup : ''
    this.subHeader = this.productId ? this.productId == Product.GOLF ? this.captions.rateType : this.captions.service : ''
    this.policyMappingForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.actionButton.disabledproperty = this.isViewOnly || !(this.policyMappingForm.dirty && this.policyMappingForm.valid && this.isApplyToSelected && this.isMappingSelected)
    });
    if(this.isViewOnly)
    {
      this.disableControls();
    }
  }

  private disableControls() {
    this.actionButton.disabledproperty = true;
    this.actionButton = {...this.actionButton };
    this.utils.disableControls(this.policyMappingForm);
  }

  formGenerator(){
    this.policyMappingForm = this.fb.group({
      policyId: [ null, [Validators.required]],
      startDate: [this.defaultDate, Validators.required],
      endDate: [this.defaultDate, Validators.required],
    })



    this.actionButton = {
      type: "primary",
      label: this.inputs.mode === ActionMode.update ? this.captions.btn_update : this.captions.btn_save,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.startDateInput = {
      form: this.policyMappingForm,
      formControlName: 'startDate',
      placeHolderId: 'lbl_startDate',
      placeHolder: this.captions.lbl_startDate,
      automationId:"'Txt_policyMapping_startDate'",
      isDateRequired: true,
      value: this.defaultDate,
      minDate : this.defaultDate,
      errorMessage: this.captions.err_MissingStartDate
    };
    this.endDateInput = {
      form: this.policyMappingForm,
      formControlName: 'endDate',
      placeHolderId: 'lbl_endDate',
      placeHolder: this.captions.lbl_endDate,
      automationId:"'Txt_policyMapping_endDate'",
      isDateRequired: true,
      value: this.defaultDate,
      minDate : this.policyMappingForm.controls.startDate.value,
      errorMessage: this.captions.err_MissingEndDate
    };
    if(this.inputs != null && this.inputs.mode == ActionMode.update){
      this.patchFormValues(this.inputs.form);
    }
  }

  patchFormValues(data: any){
    this.policyMappingForm.patchValue({
      policyId: data.policyId,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate)
    });
    let isValid = this.localization.compareDates(this.defaultDate,new Date(data.startDate));
    if(isValid){
      this.startDateInput.minDate = this.defaultDate;
    }
    else{
      this.startDateInput.minDate = this.localization.getDate(data.startDate);
    }
    this.startDateInput = {...this.startDateInput}
    this.allPlayerTypeSelected = this.mainGroupList?.every(x=> x.selected) ? true : false;
    this.mainGroupList?.forEach(mainGroup => {
      if (mainGroup.selected) {
        mainGroup.isSubGroupSelected = mainGroup.subGroup?.every(x=> x.selected) ? true : false;
        this.selectedMainGroupList.push(mainGroup);
      }
    });
    this.cancellationNoShowService.policyGroupId = data.mappingGroupId;
    this.isApplyToSelected = this.isMappingSelected =true;
  }

  startDateChange(){
    this.endDateInput.minDate = this.policyMappingForm.get('startDate').value;
    this.endDateInput = { ...this.endDateInput };
    this.policyMappingForm.controls.endDate.markAsTouched();
  }

  applyTo(event,option){
    option.selected = event.checked;
    this.isApplyToSelected = this.applyToOptions.some(x => x.selected);
    this.policyMappingForm.markAsDirty();
    this.saveButtonValidation();
  }

  groupTypeSelection(event,selectedGroup){
    selectedGroup.selected = event.checked;
      if(event.checked){
        this.selectedMainGroupList = this.mainGroupList.filter(x=> x.selected);
      }
      else{
        this.selectedMainGroupList = this.selectedMainGroupList.filter(x=> x.id != selectedGroup.id);
        selectedGroup.subGroup?.map(x=> x.selected = false);
        selectedGroup.isSubGroupSelected = event.checked;
      }
      this.policyMappingForm.markAsDirty();
      this.CheckIfMappingSelected();
      this.allPlayerTypeSelected = this.mainGroupList?.every(x => x.selected) ? true : false;
  }

  subGroupTypeSelection(event, selectedObj, mainGroup){
    selectedObj.selected = event.checked;
    this.policyMappingForm.markAsDirty();
    this.CheckIfMappingSelected();
    mainGroup.isSubGroupSelected = mainGroup?.subGroup?.every(x=> x.selected) ? true : false;
  }

  CheckIfMappingSelected(){
    this.isMappingSelected = false;
    if(this.mainGroupList.length > 0 && this.selectedMainGroupList.length > 0){
      this.isMappingSelected = this.selectedMainGroupList.every(x=> x.subGroup.some(m => m.selected));
    }
    this.saveButtonValidation();
  }

  searchValueChange(eve) {
    if(eve){
      this.searchText = eve;
      this.mainGroupList = this.originalData.filter(x=> x.name.toLowerCase().includes(this.searchText.toLowerCase()));
    }
    else{
      this.mainGroupList = this.originalData
    }
  
  }

  policyChange(){
    console.log();
  }

  onAction(e){
    const clickReturnValue = {
      from: this.inputs.mode,
      formValues: this.policyMappingForm.getRawValue(),
      form: this.policyMappingForm
    };
    this.handleClickEvent.emit(clickReturnValue);
  }

  onCancel(eve) {
    const clickReturnValue = {
      from: ActionMode.cancel,
      formValues: this.policyMappingForm.getRawValue(),
      form: this.policyMappingForm
    };
    if (this.policyMappingForm.dirty) {
      this.utils.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res == AlertAction.YES) {
          this.handleClickEvent.emit(clickReturnValue);
        }
      })
    }
    else{
      this.handleClickEvent.emit(clickReturnValue);
    }
  }


  saveButtonValidation(){
    this.actionButton.disabledproperty = !(this.policyMappingForm.dirty && this.policyMappingForm.valid && this.isApplyToSelected && this.isMappingSelected)
  }


  ngOnDestroy(){
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  endDateChange(e){

  }

  playerTypeAll(e) {
    this.mainGroupList.forEach(x=> {
      this.groupTypeSelection(e,x)
    })
  }
  rateTypeAll(e, mainGroup) {
    if(mainGroup) {
      mainGroup.subGroup.forEach(y=>{
        this.subGroupTypeSelection(e, y, mainGroup)
      })
    }
  }
}
