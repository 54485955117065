export const paths = {
    auth: 'auth',
    cache: 'cache',
    fake: 'fake',
    error: 'error',
    profiler: 'profiler',
    notify: 'notify',
    header: 'headers',
    convert: 'convert',
    loader: 'loader',
    https: 'https'
  };
  export enum ERRORCODE {
    CODE_400 = 400,
    CODE_500 = 500,
    CODE_401 = 401,
    CODE_403 = 403,
    CODE_404 = 404,
    CODE_406 = 406,
    CODE_408 = 408,
    CODE_405 = 405
  }
  export enum SUCCESSCODE {
    CODE_200 = 200,
  }
  export enum ERRORMESSAGE {
  ERROR_MESSAGE_400 = 'Bad Request',
  ERROR_MESSAGE_401 = 'Unauthorized',
  ERROR_MESSAGE_403 = 'Forbidden',
  ERROR_MESSAGE_500 = 'Internal Server Error',
  ERROR_MESSAGE_404 = 'Not Found',
  ERROR_MESSAGE_405 = 'Method Not Allowed',
  ERROR_MESSAGE_406 = 'Not Acceptable',
  ERROR_MESSAGE_408 = 'Request Timeout'
  }