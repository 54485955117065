import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";

@Injectable()
export class PlayerDiscountTypeBusiness {
    captions: any;

    constructor(private localization: Localization) {
        this.captions = this.localization.captions;
    }

    getPlayerOptions(){
        return[
            {
                id: 0,
                value: "Public",
                viewValue: "Public" 
            },
            {
                id: 1,
                value: "Member",
                viewValue: "Member" 
            },
            {
                id: 3,
                value: "Guest",
                viewValue: "Guest" 
            }
        ]
    }

    getTierOptions(){
        return[
            {
                id: 0,
                value: "Gold",
                viewValue: "Gold" 
            },
            {
                id: 1,
                value: "Silver",
                viewValue: "Silver" 
            },
            {
                id: 3,
                value: "Diamond",
                viewValue: "Diamond" 
            }
        ]
    }

    getDiscountTypes(){
        return[
            {
                id: 0,
                name: "Winter sale",
                selected: false
            },
            {
                id: 1,
                name: "Shipping discount",
                selected: false
            },
            {
                id: 3,
                name: "Flat 50%",
                selected: false
            }
        ]
    }
    getMembershipTypes(){
        return[
            {
                id: 0,
                value: "Basic",
                viewValue: "Basic" 
            },
            {
                id: 1,
                value: "Silver",
                viewValue: "Silver" 
            },
            {
                id: 3,
                value: "Platinum",
                viewValue: "Platinum" 
            }
        ]
    }
}