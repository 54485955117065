import { Injectable } from "@angular/core";
import { Localization } from '../localization/localization';
import { API, UI, NoteSendToSelect } from '../Models/staff-reader-board-model';
import { StaffReaderBoardDataService } from '../dataservices/staff-reader-board-data.service';
import { UserRoleDataService } from '../dataservices/user-roles-data.service';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { DropdownOptions } from '../Models/ag-models';
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";

@Injectable()
export class StaffReaderBoardBusiness {
    captions: any;
    allUserData = [];
    allRolesData = [];
    public isViewOnly: boolean = false;
    public isAllow: boolean = false;

    timeformat: any;

    constructor(private localization: Localization, private staffReaderBoardDataService: StaffReaderBoardDataService, private userRoleDataService: UserRoleDataService, private utils: CommonUtilities,private _userAccessBusiness: UserAccessBusiness) {
        this.captions = this.localization.captions;
        this.timeformat = this.localization.getTimeFormat();
    }

    public async CreateStaffReaderBoard(staffReaderBoard: UI.StaffReaderBoard): Promise<API.StaffReaderBoard> {
        const result = await this.staffReaderBoardDataService.CreateStaffReaderBoard(this.APIMapper(staffReaderBoard));

        return result;
    }

    public async UpdateStaffReaderBoard(staffReaderBoard: UI.StaffReaderBoard): Promise<boolean> {
        const result = await this.staffReaderBoardDataService.UpdateStaffReaderBoard(this.APIMapper(staffReaderBoard));
        return result;
    }

    public async DeleteStaffReaderBoard(id: number): Promise<boolean> {
        const result = await this.staffReaderBoardDataService.DeleteStaffReaderBoard(id);

        return result;
    }

    async ValidateBreakPoints(): Promise<boolean> {
        const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.STAFFREADERBOARD, true);
        this.isViewOnly = result.isViewOnly;
        this.isAllow = result.isAllow;
        return result.isAllow;
      }
      
    async ValidateBreakPointsfromNotes(){
        const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.STAFFREADERBOARD, true);
        this.isViewOnly = result.isViewOnly;
        this.isAllow = result.isAllow;
        return result;
      }
      

    public async GetAllStaffReaderBoardList(): Promise<UI.ViewAllStaffReaderBoard[]> {
        let userId: number = Number(this.utils.GetPropertyInfo('UserId'));
        
        const result = await this.staffReaderBoardDataService.GetAllStaffReaderBoardList(userId);

        return result;
    }

    public async FilterAllStaffReaderBoard(staffReaderBoardFilterRequest: API.StaffReaderBoardFilterRequest): Promise<UI.ViewAllStaffReaderBoard[]> {
        staffReaderBoardFilterRequest.userId =  Number(this.utils.GetPropertyInfo('UserId'));
        
        const result = await this.staffReaderBoardDataService.FilterAllStaffReaderBoard(staffReaderBoardFilterRequest);

        return result;
    }

    public async GetAllStaffReaderBoardDetails(id: number): Promise<UI.ViewAllStaffReaderBoardDetails> {
        const result = await this.staffReaderBoardDataService.GetAllStaffReaderBoardDetails(id);

        result.userNameAndRoles.forEach(x => {
            x.users.forEach(y => {
                y.acknowledgedOn = y.acknowledgedOn ? this.localization.LocalizeDate(y.acknowledgedOn) + ' ' + this.localization.LocalizeTime(y.acknowledgedOn) : null;
            })
        });

        return result;
    }

    public async GetStaffReaderBoardById(id: number): Promise<UI.StaffReaderBoard> {
        const result = await this.staffReaderBoardDataService.GetStaffReaderBoardById(id);

        return this.UIMapper(result);
    }

    public async UpdateStaffReaderBoardDetailsIsViewed(id: number): Promise<boolean> {
        const result = await this.staffReaderBoardDataService.UpdateStaffReaderBoardDetailsIsViewed(id);

        return result;
    }

    public async UpdateStaffReaderBoardDetailsIsAcknowledged(id: number): Promise<boolean> {
        const result = await this.staffReaderBoardDataService.UpdateStaffReaderBoardDetailsIsAcknowledged(id);

        return result;
    }

    public async UpdateMultipleStaffReaderBoardDetailsIsAcknowledged(ids: number[]): Promise<boolean> {
        const result = await this.staffReaderBoardDataService.UpdateMultipleStaffReaderBoardDetailsIsAcknowledged(ids);

        return result;
    }

    public async UpdateStaffReaderBoardDetailsIsSnoozed(id: number, isSnoozed: boolean, interval: number): Promise<boolean> {
        const result = await this.staffReaderBoardDataService.UpdateStaffReaderBoardDetailsIsSnoozed(id, isSnoozed, interval);

        return result;
    }

    public async GetAllNotes(): Promise<UI.ViewAllNotes[]> {
        let userId: number = Number(this.utils.GetPropertyInfo('UserId'));

        const result = await this.staffReaderBoardDataService.GetAllNotes(userId);

        return result;
    }

    public async FilterAllNotes(staffReaderBoardFilterRequest: API.StaffReaderBoardFilterRequest): Promise<UI.ViewAllNotes[]> {
        staffReaderBoardFilterRequest.userId =  Number(this.utils.GetPropertyInfo('UserId'));
        const result = await this.staffReaderBoardDataService.FilterAllNotes(staffReaderBoardFilterRequest);

        return result;
    }

    public async GetRecentNotes(isShowNoteAsPopup: boolean): Promise<UI.ViewAllNotes[]> {
        let userId: number = Number(this.utils.GetPropertyInfo('UserId'));

        const result = await this.staffReaderBoardDataService.GetRecentNotes(userId,isShowNoteAsPopup);

        return result;
    }

    public async GetRecentNotesCount(isShowNoteAsPopup: boolean): Promise<number> {
        let userId: number = Number(this.utils.GetPropertyInfo('UserId'));

        const result = await this.staffReaderBoardDataService.GetRecentNotesCount(userId,isShowNoteAsPopup);

        return result;
    }

    public async getActiveuserRoles(): Promise<DropdownOptions[]> {
        const result = await this.userRoleDataService.getActiveuserRoles();
        let productId = Number(this.utils.GetPropertyInfo("ProductId"));
        
        this.allRolesData = result.filter(x=> x.productId.includes(productId));

        return this.allRolesData.map(o => ({ id: o.id, value: o.id, viewValue: o.description }) as DropdownOptions);
    }

    public async getAllUserId(): Promise<DropdownOptions[]> {
        const result = await this.userRoleDataService.getAllUserId();

        this.allUserData = result;

        return result.map(o => ({ id: o.userId, value: o.userId, viewValue: o.firstName + " " + o.lastName }) as DropdownOptions);
    }

    UIMapper(staffReaderBoard: API.StaffReaderBoard): UI.StaffReaderBoard {
        return {
            id: staffReaderBoard.id,
            notedetails: staffReaderBoard.notes,
            priority: staffReaderBoard.priority,
            shownote: staffReaderBoard.isShowNoteAsPopup,
            popupValidFromDate: this.localization.getDate(staffReaderBoard.validFromDate),
            popupValidToDate: this.localization.getDate(staffReaderBoard.validToDate),
            popupValidFromTime: this.localization.getTime(new Date(staffReaderBoard.validFromDate), this.timeformat),
            popupValidToTime: this.localization.getTime(new Date(staffReaderBoard.validToDate), this.timeformat),
            selectedData: staffReaderBoard.staffReaderBoardDetails
        } as UI.StaffReaderBoard;
    }

    APIMapper(staffReaderBoard: UI.StaffReaderBoard): API.StaffReaderBoard {
        return {
            id: staffReaderBoard.id,
            notes: staffReaderBoard.notedetails,
            priority: staffReaderBoard.priority,
            isShowNoteAsPopup: staffReaderBoard.shownote,
            validFromDate: this.localization.convertDateTimeToAPIDateTimeSec(this.localization.AddTimeToDate(staffReaderBoard.popupValidFromDate, this.localization.TimeToDate(staffReaderBoard.popupValidFromTime.toString()))),
            validToDate: this.localization.convertDateTimeToAPIDateTimeSec(this.localization.AddTimeToDate(staffReaderBoard.popupValidToDate, this.localization.TimeToDate(staffReaderBoard.popupValidToTime.toString()))),
            staffReaderBoardDetails: staffReaderBoard.selectedData.map(x => {
                return {
                    id: 0,
                    receivedByUserId: x.receivedByUserId
                } as API.StaffReaderBoardDetails
            })
        } as API.StaffReaderBoard;
    }

    getPriorityList() {
        return [
            {
                id: 1,
                value: 1,
                viewValue: this.captions.lbl_high
            },
            {
                id: 2,
                value: 2,
                viewValue: this.captions.lbl_medium
            },
            {
                id: 3,
                value: 3,
                viewValue: this.captions.lbl_low
            }
        ]
    }

  GetModeTypes() {
    return [
        {
            id: NoteSendToSelect.roles,
            value: NoteSendToSelect.roles,
            viewValue: this.captions.lbl_roles
        },
        {
            id: NoteSendToSelect.user,
            value: NoteSendToSelect.user,
            viewValue: this.captions.lbl_Users
        },
       
    ]
}
getSnoozeTimer() {
    return [
      {
        id: 1,
        value: "15 mins",
        timeInminutes: 15
      },
      {
        id: 2,
        value: "30 mins",
        timeInminutes: 30
      },
      {
        id: 3,
        value: "45 mins",
        timeInminutes: 45
      }
    ]
  }
}