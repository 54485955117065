import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from '../../localization/localization';
import { AgInputFieldConfig, ButtonValue } from '../../Models/ag-models';
import { UI } from 'src/app/common/data-magine/data-magine-config/data-magine-config.model';

@Component({
  selector: 'app-eform-sign-capture-config',
  templateUrl: './eform-sign-capture-config.component.html',
  styleUrls: ['./eform-sign-capture-config.component.scss']
})
export class EformSignCaptureConfigComponent implements OnInit {

  captions: any;
  signCaptureConfigForm: UntypedFormGroup;
  signCaptureEndPointConfig: AgInputFieldConfig;
  DataMagineConfigData: UI.DmConfig;
  selectedForm: string;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  enableSearchEform: boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() eformSignCapture = new EventEmitter();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private localization: Localization, private dialogRef: MatDialogRef<EformSignCaptureConfigComponent>,
    private fb: UntypedFormBuilder) { 
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.formGenerator();
    this.signCaptureConfigForm.patchValue({
      useEformSignCapture: this.data.eformConfiguration.useEformSignCapture,
      showOnCheckin: this.data.eformConfiguration.showOnCheckin,
      showOnPreview: this.data.eformConfiguration.showOnPreview,
      signEndPoint: this.data.eformConfiguration.signEndPoint,
      defaultTemplate: this.data.eformConfiguration.defaultTemplate,
      searchTemplate: this.data.eformConfiguration.searchTemplate
    });
    this.enableSearchEform = this.data.eformConfiguration.defaultTemplate;
    this.DataMagineConfigData = this.data.dmConfig;
    this.selectedForm = this.data.eformConfiguration.searchTemplate;
    
    this.signCaptureConfigForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if(this.signCaptureConfigForm.controls.defaultTemplate.value && !this.signCaptureConfigForm.controls.searchTemplate.value)
      {
        this.saveButton.disabledproperty = true;
      }
      else
      {
        this.saveButton.disabledproperty = !(this.signCaptureConfigForm.valid && this.signCaptureConfigForm.dirty);
      }
      
      this.saveButton = { ...this.saveButton };
    });
  }
  formGenerator() {
    this.signCaptureConfigForm = this.fb.group({
      useEformSignCapture: '',
      showOnCheckin: '',
      showOnPreview: '',
      signEndPoint: '',
      defaultTemplate: '',
      searchTemplate: ''
    });
    this.signCaptureEndPointConfig = {
      className: 'ag_form-control--2',
      form: this.signCaptureConfigForm,
      formControlName: 'signEndPoint',
      placeHolderId: 'lbl_signature_endpoint',
      placeHolder: this.captions.lbl_signature_endpoint,
    }
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: false
    }

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    }
  }
  close() {
    this.dialogRef.close();
  }
  onSave(){
    this.dialogRef.close({
      data: this.signCaptureConfigForm
    });
  }
  onCancel(){
    this.dialogRef.close();
  }
  defaultTemplateChange(eve) {
    this.enableSearchEform = eve.checked;
  }
  searchTextValue(eve) {
    this.signCaptureConfigForm.controls.searchTemplate.markAsDirty();
    this.signCaptureConfigForm.controls.searchTemplate.setValue(eve);
  }

}
