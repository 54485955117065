import { PubSub } from "pubsub-ts";
import { retailEventName, RetailEventParameters } from "../event.model";
import { BaseEventSubscriber } from "./base.event.subscriber";

export abstract class BaseEventPublisher extends PubSub.Publisher {

    constructor() {
        super();
    }

    abstract addSubscriber(subsriber: BaseEventSubscriber): void;

    /**     
    *
    * @template T type of data sent in the event 
    * @param {T} data event data 
    * @memberof BaseEventPublisher
    */
    public publishEvent<T>(eventData: RetailEventParameters<T>): void {
        super.notifyUrgent(retailEventName, eventData);
    }
}
