import { Pipe, PipeTransform } from '@angular/core';
import { Localization } from '../../localization/Localization';

@Pipe({
  name: 'sumColumn'
})
export class SumColumnPipe implements PipeTransform {
  constructor(private localization: Localization) {

  }
  transform(tableContent: any[], field: string): string | undefined {
    if (tableContent?.length && field) {
      let allValue = tableContent.map(data => this.localization.currencyToSQLFormat((data[field])));
      allValue = allValue.filter(value => value && value != NaN);
      const total = allValue.reduce((a, b) => a + b, 0)
      return this.localization.localizeCurrency(total, false)
    }
  }

}



