import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CMSPlayerInfo, Voucher } from 'src/app/common/Models/common.models';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailService } from '../../retail.service';
import { CMSBusinessService } from '../business/CMS-business.service';
import { RetailUtilities } from '../utilities/retail-utilities';

@Component({
  selector: 'app-comp-slip-lookup',
  templateUrl: './comp-slip-lookup.component.html',
  styleUrls: ['./comp-slip-lookup.component.scss']
})
export class CompSlipLookupComponent implements OnInit {
  width;
  searchPlaceholder: string;
  ShopCaptions: any;
  CompLookupForm: UntypedFormGroup;
  Vouchers: Voucher[] = [];
  selectedVoucher: Voucher;
  selectedPlayerInfo: CMSPlayerInfo;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() VoucherSelected = new EventEmitter();
  @Input("patronId") patronId = "";
  floatLabelNever: string;

  constructor(
    private _localization: RetailLocalization
    , private _utilities: RetailUtilities
    , private _fb: UntypedFormBuilder
    , public _ams: RetailService
    , private _cmsBusiness: CMSBusinessService
  ) {
    this.floatLabelNever = this._localization.setFloatLabelNever;
    this.CompLookupForm = this._fb.group({
      patronSearch: ['', Validators.required],
      compSlipSearch: ['', Validators.required],
      voucherSelection: []
    });
  }

  ngOnInit() {
    this.ShopCaptions = this._localization.captions.shop;
    this.StartCustomLoaderSubscription();
  }

  ngOnChanges() {
    if (this.patronId != "") {
      this.CompLookupForm.controls.patronSearch.setValue(this.patronId);
    }
  }

  StartCustomLoaderSubscription() {
    this._ams.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe((loader) => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
          loadingContainerMessage.innerText = loader;
        } else {
          loadingContainer.style.display = 'none';
          loadingContainerMessage.innerText = '';
        }
      }
    });
  }

  async searchCompSlip() {
    if (this.CompLookupForm.valid && this.CompLookupForm.controls.patronSearch.value
      && this.CompLookupForm.controls.compSlipSearch.value) {
      this.Vouchers = [];
      this.selectedVoucher = null;
      this.selectedPlayerInfo = null;
      this.VoucherSelected.emit([null, null]);
      const patronId = this.CompLookupForm.controls.patronSearch.value;
      const compSlipNumber = this.CompLookupForm.controls.compSlipSearch.value;
      const MGTvendorType = "MGT";
      this._ams.loaderEnable.next(this.ShopCaptions.RetrievePatronDetails);
      let playerInfo: CMSPlayerInfo = await this._cmsBusiness.GetCMSPlayerInfo(patronId);
      this._ams.loaderEnable.next("");
      if (playerInfo && playerInfo.cmsBalanceDetails && playerInfo.cmsBalanceDetails.offerRedemption) {
        this.selectedPlayerInfo = playerInfo;
        let searchedCompSlip = [];
        const availableVouchers = playerInfo.cmsBalanceDetails.offerRedemption.vouchers;
        if (availableVouchers && availableVouchers.length > 0 && availableVouchers.some(x => x.vendorType.toUpperCase() == MGTvendorType)) {
          searchedCompSlip = availableVouchers.filter(x => x.vendorType == MGTvendorType && x.voucherId == compSlipNumber);
          this.Vouchers = searchedCompSlip;
          console.log({ searchedCompSlip });
        }
        if (!availableVouchers || availableVouchers.length == 0 || !searchedCompSlip || searchedCompSlip.length == 0) {
          this._utilities.ShowErrorMessage(
            this._localization.captions.common.Error,
            this._localization.captions.shop.CMS.CompSlipNotFound
          );
        }
      } else {
        this._utilities.ShowErrorMessage(
          this._localization.captions.common.Error,
          this._localization.captions.shop.NoPlayersFound
        );
      }
    }
  }

  selectVoucher(voucher) {
    this.VoucherSelected.emit([this.selectedPlayerInfo, voucher]);
  }
}
