import { MenuTreeView, NavigationPrimaryMenu } from 'src/app/eatecui/source/core/models/navigation-model';
import { Action } from '@ngrx/store';

export enum ENavigationMenu {
    GetNavigationMenu = '[GetNavigationMenu] Get GetNavigationMenu',
    GetNavigationMenuSucess = '[GetNavigationMenuSucess] Get GetNavigationMenuSucess',
    GetTretiaryTreeView = '[GetTretiaryTreeView] Get GetTretiaryTreeView'
}

export class GetNavigationMenu implements Action {
    readonly type = ENavigationMenu.GetNavigationMenu;
    constructor(public payload: any) { }
}
export class GetNavigationMenuSucess implements Action {
    readonly type = ENavigationMenu.GetNavigationMenuSucess;
    constructor(public payload: NavigationPrimaryMenu[]) { }
}

export class GetTretiaryTreeView implements Action {
    readonly type = ENavigationMenu.GetTretiaryTreeView;
    constructor(public payload: MenuTreeView[]) { }
}


export type NavigationMenuAction = GetNavigationMenu | GetNavigationMenuSucess | GetTretiaryTreeView;
