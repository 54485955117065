import { ViewGridConfiguration } from './view-gridconfiguration';
import { ViewHeaderBreabcrumbConfiguration } from './view-header/view-breadcrumb-config';
import { ViewHeaderConfiguration } from './view-headerconfig.interface';
import { ViewTilesHeaderConfiguration } from './view-tilesheader.interface';

export const ViewTransaction = {
    HeaderConfiguration: ViewHeaderConfiguration,
    HeaderTilesConfiguration: ViewTilesHeaderConfiguration,
    HeaderGridConfiguration: ViewGridConfiguration,
    
};