import { GridAppConfig } from '../app-grid.interface';
import { GridColumnSortDirection } from '../grid.interface';

export const GridSortDirection: GridColumnSortDirection = (headerName: string,
    GridActionConfig: GridAppConfig): string => {
    let defaultSortingString = '';
    if (GridActionConfig && GridActionConfig.GridSort.length > 0) {
        const sortingObject = GridActionConfig.GridSort.filter(x => x.SortingKey === headerName);
        if (sortingObject.length > 0) {
            if (sortingObject[0].DefaultSorting) {
                defaultSortingString = sortingObject[0].SortingType;
            }
        }
    }
    return defaultSortingString.toLowerCase();
};