export enum QuickIdConfigSetting {
    all = "QUICKID_ALL",
    appointmentBook= "QUICKID_APPOINTMENTBOOK",
    appointmentEdit= "QUICKID_APPOINTMENTEDIT",
    appointmentMove= "QUICKID_APPOINTMENTMOVE",
    appointmentCancel= "QUICKID_APPOINTMENTCANCEL",
    appointmentCopy = "QUICKID_APPOINTMENTCOPY",
    appointmentReinstate = "QUICKID_APPOINTMENTREINSTATE",
    therapistBreakCreate= "QUICKID_THERAPISTBREAKCREATE",
    therapistBreakEdit= "QUICKID_THERAPISTBREAKEDIT",
    therapistBreakDelete= "QUICKID_THERAPISTBREAKDELETE",
    therapistBreakMove= "QUICKID_THERAPISTBREAKMOVE",
    retailtransactions= "QUICKID_RETAILTRANSACTIONS"
}

export interface ConfigData {
    id: number;
    isActive?: boolean;
    moduleId: number;
    switch: string;
    switchType?: string;
    value:  any;
}