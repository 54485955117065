export namespace API{
    export interface KakaoTemplateMapping
    {
        id : number,
        smsTemplates : any,
        smsTemplateId : number,
        kakaoTemplateCode : string,
        fallbackMessageType : any
        isActive : boolean,
        kakaoTemplateButtonConfiguration : KakaoTemplateButtonConfiguration[]
    }

    export interface KakaoTemplateButtonConfiguration 
    {
        id : number
        buttonDisplayName : string,
        buttonType : Enum.ButtonType,
        buttonUrlForPc : string,
        buttonUrlForMobile : string,
        isActive : boolean,
    }

    export interface KakaoTemplateCode
    {
        templateCode : string,
        templateName : string,
        securityFlag : boolean,
        templateStatus : string,
        kepStatus : string,
        categoryCode : string,
        templateDormant : boolean,
        templateBlock : boolean,
    }
}

export namespace UI{
    export interface KakaoTemplateMapping
    {
        id : number,        
        smsTemplates : any,
        smsTemplateId : number,
        kakaoTemplateCode : string,
        kakaoTemplate : {
            id : number,
            value : string,
            viewValue : string,
        };
        smsTemplateName : string,
        fallBackMessageType : {
            id : number,
            value : string,
            viewValue : string,
        };       
        isActive: {
            value: boolean,
            isDisabled: boolean;
        };
        items : KakaoTemplateButtonConfiguration[]
    }
    export interface KakaoTemplateButtonConfiguration 
    {
        id : number
        buttonDisplayName : string,
        buttonType : Enum.ButtonType,
        buttonUrlForPc : string,
        buttonURLForMobile : string,
        isActive : boolean,
    }
}

export namespace Enum{
    export enum ButtonType
    {
      WebLink = 1,
      AddChannel,
      AppLink,
      DeliveryTracking,
      BotKeyWord,
      MessageDelivery,
      ConsultaionTalkConversion,
      BotConversion,
      ImageSecureTransferPlugin,
      PersonalInformationUsePlugin,
      OneClickPaymentPlugin
    }

    export enum FallBackMessageType
    {
        ShortMessage = 1,
        LongMessage
    }
}
