import { DisplayNameRemapConfig } from './data-remap-config.interface';

export const DisplayNameRemap: DisplayNameRemapConfig = ( languageJson: any, remapJson: Array<any>): string => {
    try {
        const language = languageJson;
        remapJson.forEach((remap: any) => {
         const remapKey = remap.Name.toString();
         const getLanguage = language[remapKey];
         if ( getLanguage && getLanguage !== null && getLanguage !== undefined ) {
            const getMapAray = remap.UIFieldLookup; 
            if ( getMapAray && getMapAray.length > 0 ) {
                getMapAray.forEach((element: any) => {
                   const getElement = getLanguage[element.Property_Key];
                   if ( getElement && getElement !== undefined) {
                    const getLangugaeObj = element.UIFieldDisplayNames.filter(( Planguage: any) => Planguage.LanguageId === 1);
                    if ( getLangugaeObj && getLangugaeObj.length > 0 ) {
                        getLanguage[element.Property_Key] = getLangugaeObj[0].DisplayName;
                    }    
                   
                   }  
                });
            }
         }
        });
        return language;
    } catch (error) {
        console.error(error);
    }
};