import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'OrderSummaryPipe',
    pure: false
})
export class OrderSummaryPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        return items.filter(ordersummary => !ordersummary.isGroupingKey || ordersummary.isPackagedItem || (ordersummary.packageItemId | ordersummary.PackageItemId)  > 0);
    }
}