import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';

/**
 * classClientCancellation Report
 * @export
 * @class classClientCancellation
 * @extends {BaseReport}
 */
export class ClassClientCancellation extends BaseReport {
	constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public code: string, public patcheableValue) {
		super(reportFormGrp);
	}

	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: this.code,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		};
	}

	getReportUIConfig(): ReportUIConfig {
		if (this.commonLocalization.isFromJobScheduler) {
            //this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
		return {
			startDatePicker: true,
			endDatePicker: true,
			inActiveToggle: false,
			inActiveToggleName: '',
			pageBreakToggle: false,
			layout: 'PORTRAIT',
			dropDownFilters: this.data.getAllCancelReasons(),
			dropDownFilterName: this.captions.CancelReason,
		};
	}
	protected formReportParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [
			{ pStartDate: toApi(this.startDate) },
			{ pEndDate: toApi(this.endDate) },
			{ pPropertyName: this.propertyName },
            { pFilterCaption: this.getFilterCaption },
			{ pDate: this.printedDate }
		];
	}
	protected formURIParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [ { startDate: toApi(this.startDate) }, { endDate: toApi(this.endDate) } ];
	}
	protected formFilters(): any {
		if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
			let obj = {
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement,
                StartDate:(this.formGrp.get('startDate').value),
                EndDate: (this.formGrp.get('endDate').value),
                Data: this.selectedFilterOptions.map(s=>s.id)
            };
            return obj;
        }
		return this.selectedFilterOptions.map(s=>s.id);

	}

	patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }
}
