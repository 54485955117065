import { Pipe, PipeTransform } from '@angular/core';
import { UI } from './dynamic-yielding.model';

@Pipe({
  name: 'pricingCondition'
})
export class PricingConditionPipe implements PipeTransform {

  transform(condition: any): string {
    let returnValue = '';
    if (condition == UI.PricingCondition.GreaterThanEqualTo) {
      returnValue = '>=';
    } else if(condition == UI.PricingCondition.LesserThanEqualTo){
      returnValue = '<=';
    } else if(condition == UI.PricingCondition.NoCondition){
      returnValue = '-NA-';
    }

    return returnValue;
  }

}