import { GridModelHeaderDefination } from './grid-config.interface';
import { ReplaceTextWithIcon } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { MasterInventoryManagement } from '../master.interface';

export const GetCellRendererColumn: GridModelHeaderDefination = (
ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement, EntityProperty: any): any => {
    try {
        const ReplacableObject = ReplaceTextWithIcon.filter(x => x.OdataKey.toLowerCase() === EntityProperty['$']['Name'].toLowerCase())[0];
        if (ReplacableObject) {
            return ReplacableObject.RendererName;
        }
    } catch (error) {
        console.log('Error occurred in GetCellRendererColumn');
    }
};
