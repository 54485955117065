import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AgilysysButtonModel } from '../../agilysys-buttons/models/agilysys-buttons.interface';
import { ErrorPopModel, ProgressPopupModel } from '../model/pop-up.interface';
@Component({
    selector: 'app-agilysys-progress',
    templateUrl: './agilysys-progress-popup.component.html',
    styleUrls: ['./agilysys-progress-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgilsysProgressPopComponent {
    agilysyserrorlogButton: AgilysysButtonModel[];
    VisibleInfoMessage: boolean;
    constructor(public dialogRef: MatDialogRef<AgilsysProgressPopComponent>, private toastrService: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: ProgressPopupModel) {
            this.agilysyserrorlogButton = [
                {
                  Id: 1,
                  Name: 'Ok',
                  DisplayName: 'OK',
                  Class: 'agilysys-button-primary',
                  DisableProperity: false,
                  onCellClick: (returnValue: AgilysysButtonModel) => {
                     this.dialogRef.close(returnValue.Name);
                  }
                }
              ];
        }

     

}