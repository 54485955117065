import { Injectable } from "@angular/core";
import { HandleRequest, HttpResponseStatus, PaymentMethods, TokentransactionInfo } from "src/app/common/shared/shared/business/shared.modals";
import { Router } from "@angular/router";
import { IntegrationOperationsTransactionType, PMAgentActionType, RGuestPayRequestBuildParams, TokenResponse } from "src/app/common/shared/shared/business/rGuestPayRequestModel";

@Injectable({ providedIn: 'root' })
export class PMAgentToRGuestPayRequestAdapter {
    constructor(private _router: Router) { }

    MapHandleRequest(request: HandleRequest, outletId: number): RGuestPayRequestBuildParams {
        return {
            actionType: PMAgentActionType.GetHandles,
            outletId: outletId,
            tenderId: Number(request.tenderId),
            manualCardEntry: request?.inquiryInfo?.manualCardEntry,
            encryptedCardData: request?.inquiryInfo?.cardData?.encryptedData,
            integrationOperationType: IntegrationOperationsTransactionType.CreditCardGetHandlesForRetailTransaction,
            params: { "route": this._router.url, "originalTenderId": request.originalTenderId ? request.originalTenderId.toString() : '' }
        } as RGuestPayRequestBuildParams;
    }

    MapTokenRequest(handle: string, tenderID: number, outletId: number, manualCardEntry: boolean): RGuestPayRequestBuildParams {
        return {
            actionType: PMAgentActionType.CreateToken,
            outletId: outletId,
            tenderId: tenderID,
            manualCardEntry: manualCardEntry,
            integrationOperationType: IntegrationOperationsTransactionType.CreditCardGetHandlesForRetailTransaction,
            deviceGuid: tenderID == PaymentMethods.IDTECH ? "" : handle,
            encryptedCardData: tenderID == PaymentMethods.IDTECH ? JSON.parse(handle)?.inquiryInfo?.cardData?.encryptedData : "",
            amount: 0,
            sourceTypeId: "",
            params: { "route": this._router.url }
        } as RGuestPayRequestBuildParams;
    }

    MapTokenResponse(tokenRes: TokenResponse): TokentransactionInfo {
        let response: any = {
            status: HttpResponseStatus.Success,
            account: {
                id: tokenRes.cardInfo?.accountNumberMasked,
                name: tokenRes.cardInfo?.cardHolderName,
                type: ""
            }
        };
        if (tokenRes.cardInfo != null) {
            response.cardInfo = {
                entryMode: tokenRes?.cardInfo?.entryMode,
                issuer: tokenRes?.cardInfo?.cardIssuer,
                cardExpiration: tokenRes?.cardInfo?.expirationYearMonth,
                cardType: tokenRes?.cardInfo?.cardType
            };
        }
        return response as TokentransactionInfo;
    }
}