import { TranslogType } from "./report.modals";

 export const translog :TranslogType[]= [
    {
        "id": 1,
        "code": "AppointmentCreate",
        "description": "",
        "logtype": "Appointment Create"
    },
    {
        "id": 2,
        "code": "AppointmentEdit",
        "description": "",
        "logtype": "Appointment Edit"
    },
    {
        "id": 3,
        "code": "AppointmentCheckIn",
        "description": "",
        "logtype": "Appointment Check In"
    },
    {
        "id": 4,
        "code": "AppointmentCheckOut",
        "description": "",
        "logtype": "Appointment Check Out"
    },
    {
        "id": 5,
        "code": "AppointmentMove",
        "description": "",
        "logtype": "Appointment Move"
    },
    {
        "id": 6,
        "code": "AppointmentCopy",
        "description": "",
        "logtype": "Appointment Copy"
    },
    {
        "id": 7,
        "code": "AppointmentCancel",
        "description": "",
        "logtype": "Appointment Cancel"
    },
    {
        "id": 8,
        "code": "AppointmentOverbook",
        "description": "",
        "logtype": "Appointment Overbook"
    },
    {
        "id": 9,
        "code": "StaffScheduleCreate",
        "description": "",
        "logtype": "Staff Schedule Create"
    },
    {
        "id": 10,
        "code": "StaffScheduleEdit",
        "description": "",
        "logtype": "Staff Schedule Edit"
    },
    {
        "id": 11,
        "code": "StaffScheduleDelete",
        "description": "",
        "logtype": "Staff Schedule Delete"
    },
    {
        "id": 12,
        "code": "TherapistBreakCreate",
        "description": "",
        "logtype": "Therapist Break Create"
    },
    {
        "id": 13,
        "code": "TherapistBreakEdit",
        "description": "",
        "logtype": "Therapist Break Edit"
    },
    {
        "id": 14,
        "code": "TherapistBreakDelete",
        "description": "",
        "logtype": "Therapist Break Delete"
    },
    {
        "id": 15,
        "code": "ServiceCreate",
        "description": "",
        "logtype": "Service Create"
    },
    {
        "id": 16,
        "code": "ServiceEdit",
        "description": "",
        "logtype": "Service Edit"
    },
    {
        "id": 17,
        "code": "ServiceDelete",
        "description": "",
        "logtype": "Service Delete"
    },
    {
        "id": 18,
        "code": "SpaCodeCreate",
        "description": "",
        "logtype": "Spa Code Create"
    },
    {
        "id": 19,
        "code": "SpaCodeEdit",
        "description": "",
        "logtype": "Spa Code Edit"
    },
    {
        "id": 20,
        "code": "SpaCodeDelete",
        "description": "",
        "logtype": "Spa Code Delete"
    },
    {
        "id": 21,
        "code": "ClientCreate",
        "description": "",
        "logtype": "Client Create"
    },
    {
        "id": 22,
        "code": "ClientEdit",
        "description": "",
        "logtype": "Client Edit"
    },
    {
        "id": 23,
        "code": "RetailCodeCreate",
        "description": "",
        "logtype": "Retail Code Create"
    },
    {
        "id": 24,
        "code": "RetailCodeEdit",
        "description": "",
        "logtype": "Retail Code Edit"
    },
    {
        "id": 25,
        "code": "RetailCodeDelete",
        "description": "",
        "logtype": "Retail Code Delete"
    },
    {
        "id": 26,
        "code": "RetailTransactionCreate",
        "description": "",
        "logtype": "Retail Transaction Create"
    },
    {
        "id": 27,
        "code": "RetailTransactionSettle",
        "description": "",
        "logtype": "Retail Transaction Settle"
    },
    {
        "id": 28,
        "code": "RetailTransactionVoid",
        "description": "",
        "logtype": "Retail Transaction Void"
    },
    {
        "id": 29,
        "code": "RetailTransactionReturn",
        "description": "",
        "logtype": "Retail Transaction Return"
    },
    {
        "id": 30,
        "code": "SignIn",
        "description": "",
        "logtype": "Sign In"
    },
    {
        "id": 31,
        "code": "SignOut",
        "description": "",
        "logtype": "Sign Out"
    },
    {
        "id": 32,
        "code": "UserCreate",
        "description": "",
        "logtype": "User Create"
    },
    {
        "id": 33,
        "code": "UserEdit",
        "description": "",
        "logtype": "User Edit"
    },
    {
        "id": 34,
        "code": "UserDelete",
        "description": "",
        "logtype": "User Delete"
    },
    {
        "id": 35,
        "code": "DayEnd",
        "description": "",
        "logtype": "Day End"
    },
    {
        "id": 36,
        "code": "GenderPreferenceOverride",
        "description":"",
        "logtype": "Gender Preference Override"
    }
    ,
    {
        "id": 37,
        "code": "ClientBlockCreate",
        "description":"",
        "logtype": "Client Block Create"
    },
    {
        "id": 38,
        "code": "ClientBlockUpdate",
        "description":"",
        "logtype": "Client Block Update"
    },
    {
        "id": 39,
        "code": "AuditReport",
        "description":"",
        "logtype": "Auto Night Audit"
    },
    {
        "id": 40,
        "code": "ClassCreate",
        "description":"",
        "logtype": "Class Create"
    },
    {
        "id": 41,
        "code": "ClassEdit",
        "description":"",
        "logtype": "Class Edit"
    },
    {
        "id": 42,
        "code": "ClassCopy",
        "description":"",
        "logtype": "Class Copy"
    },
    {
        "id": 43,
        "code": "ClassMove",
        "description":"",
        "logtype": "Class Move"
    },
    {
        "id": 44,
        "code": "ClassClientCheckIn",
        "description":"",
        "logtype": "Class Client Check In"
    },
    {
        "id": 45,
        "code": "ClassClientCheckOut",
        "description":"",
        "logtype": "Class Client Check Out"
    }
   
]



