import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, GridCustomColExpand, GridHeaderConfig, GridTableCard, MasterInventoryManagement, TableData } from '../master.interface';
import { GridModelData, GridTableCardConfig } from './grid-config.interface';
import _ from 'lodash';
import { Key } from 'protractor';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { TranslateService } from '@ngx-translate/core';

export const GridTableCards: GridTableCardConfig = (
    ViewModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement,
    GridData: Array<any>, 
    GridHeaderDefination: GridHeaderConfig[],
    translateService: TranslateService
 ): GridTableCard[] => {
    try {
        if (GridData.length > 0) {
            const entityName = ViewModelEntity[0]['$']['Name'].toString();
            const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            const TableGridArray = [];
            GridData.forEach((dataObject) => {
                const TableGrid = {} as GridTableCard;
                TableGrid.Id = dataObject['id'];
                const GridTableCardArray = [];
                for (const keys in dataObject) {
                    if (keys) {
                    const HeaderDefinationCon = GridHeaderDefination.filter(( headerDef: GridHeaderConfig) => headerDef.field === keys);
                    if (HeaderDefinationCon.length > 0) {
                        const TableGridData = {} as TableData;
                        TableGridData.DisplayName = ProductDataRemap.FieldDisplayNameEngine(
                            productremapJson, entityName, HeaderDefinationCon[0].headerName, entityName + `Card-${HeaderDefinationCon[0].headerName}`, translateService);
                        TableGridData.Value = dataObject[keys];
                        if (keys === 'ingredientsbarcodes') {
                            TableGridData.Value = dataObject[keys] && dataObject[keys][0] ? dataObject[keys][0].Barcode : '-';
                        }
                        TableGridData.HeaderName = HeaderDefinationCon[0].field;
                        TableGridData.sortOrder = HeaderDefinationCon[0].tableCardSortOrder;
                        GridTableCardArray.push(TableGridData);
                    } else if (keys === 'barcode') {                        
                        const TableGridData = {} as TableData;
                        TableGridData.DisplayName = ProductDataRemap.FieldDisplayNameEngine(
                            productremapJson, entityName, 'Barcode', entityName + `Card-Barcode`);
                        TableGridData.Value = dataObject[keys];
                        TableGridData.HeaderName = keys;
                        const customHeader = GridHeaderDefination.filter(( headerDef: GridHeaderConfig) => headerDef.field === 'ingredientsbarcodes');
                        TableGridData.sortOrder = customHeader[0].tableCardSortOrder;
                        GridTableCardArray.push(TableGridData);
                    }
                }
                }
                TableGrid.GridTableData = GridTableCardArray.sort((a, b) => a.sortOrder - b.sortOrder);
                TableGridArray.push(TableGrid);
            });
            return TableGridArray;
        } else {
            return [];
        }
    } catch (error) {
        console.log('error occured GridTableCards', error);
    }
};
