import { TransactionConfiguration } from '../transaction.interface';
import { ConfirmPopUpDataModel } from './button-config.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import * as _ from 'lodash';

export const confirmPopUpData: ConfirmPopUpDataModel = (
  TransactionCollection: TransactionConfiguration
): any => {
  try {
    if (TransactionCollection) {
      const ModuleName = TransactionCollection.Name;
      const GridTableData = _.cloneDeep(
        TransactionCollection.CreateTranscationConfig.DetailsConfig
          .DetailTableCard.DetailsTableCardBuilder.GridTableData);
      const GridRowData =  TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
      let ReturnGridTableData = [];
      let rguestTenantId = false;
      const checkTenant = sessionStorage.getItem('RGuestTenantId');
      if (checkTenant && JSON.parse(checkTenant)) {
        rguestTenantId = true;
      }
      switch (ModuleName) {
        case 'Physical Inventory':
          ReturnGridTableData = GridTableData;
          break;
        case 'Inventory Reset':
          const RequireFields = ['LocationId', 'PostDate'];
          ReturnGridTableData = [];
          RequireFields.forEach((res) => {
            if (GridTableData.filter((x) => x.Odatakey === res).length > 0) {
              ReturnGridTableData.push(
                GridTableData.filter((x) => x.Odatakey === res)[0]
              );
            }
          });
          break;
        case 'Receiving':
          const RequiredFieldsReceiving = [
            'SupplierId',
            'InvoiceNumber',
            'PONumber',
            'PostDate',
            'InvoiceDueDate',
            'InvoiceDate',
            'ItemTotal'
          ];
          if (!rguestTenantId) {
            const TotalInFC = GridRowData.reduce(function (prev, element) {
              return prev + ((element.AmountInFC) ? (parseFloat(element.AmountInFC.toString())) : 0);
            }, 0);
            const suppliercurrency = TransactionCollection.CreateTranscationConfig.TransactionFormConfig.FormGroupData.TransactionCurrencyId;
            const supplierCode = CommonService.getCurrencyCode(suppliercurrency);
            const RawValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: supplierCode }).format(null);
            const ReturnRawValue = CommonService.SetCurrencySymbolInUtility(supplierCode, RawValue, true)
            GridTableData.push({
                DisplayName: `Item Total (${ReturnRawValue})`,
                HeaderName: `Item Total (${ReturnRawValue})`,
                Value: parseFloat(TotalInFC ? TotalInFC.toString() : '0').toFixed(4),
                Odatakey: 'ItemTotal'
            });
          }
          ReturnGridTableData = [];
          RequiredFieldsReceiving.forEach((res) => {
            if (GridTableData.filter((x) => x.Odatakey === res).length > 0) {
              ReturnGridTableData.push(
                GridTableData.filter((x) => x.Odatakey === res)[0]
              );
            }
          });
          break;
        case 'AP RNI':
          const RequiredFields = [
            'SupplierId',
            'InvoiceNumber',
            'PONumber',
            'PostDate',
            'InvoiceDueDate',
            'InvoiceDate',
            'ItemTotal'
          ];
          if (!rguestTenantId) {
            const VocuherTotalInFC = GridRowData.reduce(function (prev, element) {
              return prev + ((element.AmountInFC) ? (parseFloat(element.AmountInFC.toString())) : 0);
            }, 0);
            const vouchersuppliercurrency = TransactionCollection.CreateTranscationConfig.TransactionFormConfig.FormGroupData.TransactionCurrencyId;
            const vouchersupplierCode = CommonService.getCurrencyCode(vouchersuppliercurrency);
            const voucherValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: vouchersupplierCode }).format(null);
            const ReturnValue = CommonService.SetCurrencySymbolInUtility(vouchersupplierCode, voucherValue, true)
            GridTableData.push({
              DisplayName: `Item Total (${ReturnValue})`,
              HeaderName: `Item Total (${ReturnValue})`,
              Value: parseFloat(VocuherTotalInFC ? VocuherTotalInFC.toString() : '0').toFixed(4),
              Odatakey: 'ItemTotal'
            });
          }
          ReturnGridTableData = [];
          RequiredFields.forEach((res) => {
            if (GridTableData.filter((x) => x.Odatakey === res).length > 0) {
              ReturnGridTableData.push(
                GridTableData.filter((x) => x.Odatakey === res)[0]
              );
            }
          });
          break;
        case 'sales':
          const requiredFields = [
            'SalesLocationId',
            'MealPeriodsId',
            'PostDate',
            'Balance',
            'Valued',
          ];
          const DetailInfo = [];
          const DetailData = TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder;
          DetailData.forEach((detail: any) => {
            if (detail.OdataKey === 'Balance' || detail.OdataKey === 'Valued') {
              DetailInfo.push(detail);
            }
          });
          const GridData =
            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList
              .ItemGridConfig.GridData;
          ReturnGridTableData = [];
          let Valued = '0';
          requiredFields.forEach((res) => {
            if (DetailInfo.filter((x) => x.OdataKey === res).length > 0) {
              DetailInfo.forEach((x: any) => {
                if (x.OdataKey === res) {
                  if (res === 'Balance') {
                    x.Value =
                      GridData.length > 1
                        ? GridData.length.toString() + ' Item(s)'
                        : GridData.length.toString() + ' Item';
                  } else {
                    if (res === 'Valued') {
                      TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.
                      DetailFormHeader.forEach((detail: any) => {
                        if (detail.hasOwnProperty('HeaderInfo') && detail.DisplayName === 'Grand Total') {
                          Valued = detail.Value;
                        }
                      });
                      x.Value = Valued === '' ? '0.00' : Valued;
                    }
                }
              }
            });
              ReturnGridTableData.push(
                DetailInfo.filter((x) => x.OdataKey === res )[0]
              );
            }
            if (ReturnGridTableData.length !== requiredFields.length) {
              GridTableData.forEach((gridTableData: any) => {
                if (gridTableData.Odatakey === res && res !== 'Balance' && res !== 'Valued') {
                  ReturnGridTableData.push(
                    GridTableData.filter((x) => x.Odatakey === res)[0]
                  );
                }
              });
            }
          });
          break;
      }
      return ReturnGridTableData;
    }
  } catch (error) {
    console.log('error occured EnableGridFilter', error);
  }
};
