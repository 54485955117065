import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'src/app/common/app-service';
import { HttpCallService } from 'src/app/common/communication/common/http-call.service';
import { Localization } from 'src/app/common/localization/localization';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { samsotechScanResponse, IntegrationFormSetting,IntegrationAPISettings } from 'src/app/common/Models/common.models';
import { InternalIntegrationCommunication } from '../communication/services/internal-intergration-service';
import { CommonApiRoutes } from '../common-route';
@Injectable({
    providedIn: 'root'
})

@Injectable({ providedIn: 'root' })
export class SamsotechDataService extends HttpCallService {

    constructor(
        private httpclient: HttpClient,
        localization: Localization,
        utilities: CommonUtilities,
        propertyinfo: CommonPropertyInformation,
        appservice: AppService,
        private v1InternalIntegration: InternalIntegrationCommunication) {
        super(appservice.hosts.PMSManagerService, httpclient, localization, utilities, propertyinfo, appservice);
    }

    public async InitProcessSmartCard(url: string): Promise<string> {
        return await this.httpclient.get<string>(url).toPromise<string>();
    }
    
    public async InitScanDocument(url: string): Promise<string> {
        return await this.httpclient.get<string>(url).toPromise<string>();
    }

    public getSettingByModuleScreen<T>(module: string, screen: string): Promise<IntegrationFormSetting> {
        let result = this.v1InternalIntegration.getPromise<IntegrationFormSetting>(
            { route: CommonApiRoutes.GetSettingByModuleScreenInternalIntegration, uriParams: { module, screen } });
        return result;
    } 

    public async updateSettings<T>(data: IntegrationAPISettings<T>[]): Promise<IntegrationFormSetting[]> {
        const setting: IntegrationAPISettings<string>[] = [];
        for (let index = 0; index < data.length; index++) {
            setting.push(this.FormAPISetting(data[index]));
        }

        const result =await this.v1InternalIntegration.putPromise<IntegrationFormSetting[]>(
            { route: CommonApiRoutes.UpdateSettingsInternalIntegration, body: setting });

        return result;
    }

    public createSettings<T>(data: IntegrationAPISettings<T>[]): Promise<IntegrationFormSetting[]> {
        const setting: IntegrationAPISettings<string>[] = [];
        for (let index = 0; index < data.length; index++) {
            setting.push(this.FormAPISetting(data[index]));
        }

        const result = this.v1InternalIntegration.postPromise<IntegrationFormSetting[]>(
            { route: CommonApiRoutes.UpdateSettingsInternalIntegration, body: setting });

        return result;
    }

    FormAPISetting(data) {
        return {
            id: data.id,
            moduleName: data.moduleName,
            screenName: data.screenName,
            configValue: JSON.stringify(data.configValue)
    } as IntegrationAPISettings<string>
    }
}

