import { EventActorsDataProvider, IEventActorDataProvider, EventActorCollection, Therapist,Users } from './common/Models/notification.model';
import { SpaUtilities } from './shared/utilities/spa-utilities';
import { Product } from './shared/globalsContant';
import { SPAConfig } from './core/config/SPA-config';


export class SpaEventActorDataProvider implements EventActorsDataProvider {
    propertyId: string;
    spaRouteJson: any;

    constructor(private utils: SpaUtilities, private caption: any, private spaConfig:SPAConfig) {
        this.propertyId = this.utils.GetPropertyInfo('PropertyId');
        this.spaRouteJson = this.spaConfig.getRouteJson();
    }


    get providers(): IEventActorDataProvider[] {
        const caption = this.caption.settings.utilities.distributionlist;
        return [
            {
                actor: 'AppointmentGuest',
                actorDescription: caption.AppointmentGuest
            },
            {
                actor: 'AppointmentTherapist',
                actorDescription: caption.AppointmentTherapist
            },
            {
                actor: 'Therapist',
                actorDescription: caption.Therapist,
                host: this.spaRouteJson.host.spaManagement,
                route: this.spaRouteJson.getActiveTherapist,
                uiMapper: (apiValue: Therapist[]) => {
                    return apiValue.map(a => {
                        return <EventActorCollection>{
                            id: a.id,
                            desc: `${a.firstName} ${a.lastName}`
                        }
                    });
                }
            },
            {
                actor: 'UserRoles',
                actorDescription: caption.UserRoles,
                host: this.spaRouteJson.host.authentication,
                route: `UserRole/GetActiveUserRolesByPropertyId/${this.utils.GetPropertyInfo('PropertyId')}/false`,
                uiMapper: (apiValue: any[]) => {
                    return apiValue.filter(o=>o.productId.includes(Product.SPA)).map(a => {
                        return <EventActorCollection>{
                            id: a.id,
                            desc: a.description
                        }
                    });
                }
            },
            {
                actor: 'Users',
                actorDescription: caption.Users,
                host: this.spaRouteJson.host.authentication,
                route: `User/GetAllUserbyTenantId/${this.utils.GetPropertyInfo('TenantId')}?inActive=false`,
                uiMapper: (apiValue: Users[]) => {
                    return apiValue.map(a => {
                        return <EventActorCollection>{
                            id: a.userId,
                            desc: `${a.firstName} ${a.lastName}`
                        }
                    });
                }
            }


        ]
    }



}

