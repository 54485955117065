import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { ConfirmPopModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-session-expired',
  templateUrl: './agilysys-session-expired.component.html',
  styleUrls: ['./agilysys-session-expired.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysSessionExpiredComponent implements OnInit {
  agilysysCofirmationButton: AgilysysButtonModel[];
  constructor(public dialogRef: MatDialogRef<AgilysysSessionExpiredComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmPopModel, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.agilysysCofirmationButton = [
      {
        displayProperity: false,
        displayName: this.translateService.instant('Common.Ok'),
        buttonKey: 'Ok',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
            this.dialogRef.close(callBackData.buttonValue.buttonKey);
        }
      }
    ];
  }

}
