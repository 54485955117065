import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';

/**
 *Appointment By StaffMember Report
 * @export
 * @class AppointmentByStaffMember
 * @extends {BaseReport}
 */
export class AppointmentByStaffMember extends BaseReport {
	constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,  public patcheableValue) {
		super(reportFormGrp);
	}

	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: this.reportFormGrp.value.customFilterFormGrp.IncludePrevAndNextAppointment?AllReports.AppointmentByStaffPrevNext:AllReports.AppointmentByStaffMember,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		};
	}

	getReportUIConfig(): ReportUIConfig {
		if (this.commonLocalization.isFromJobScheduler) {
            //this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
		return {
			startDatePicker: true,
			endDatePicker: true,
			dropDownFilters: this.data.getAllTherapist('AppointmentByStaffMember', false),
			dropDownFilterName: this.captions.Therapists,
			inActiveToggle: true,
			inActiveToggleName: this.captions.IncludeInactiveTherapist,
			pageBreakToggle: true,
			layout: 'LANDSCAPE'
		};
	}

	protected formReportParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [
			{ pStartDate: toApi(this.startDate) },
			{ pEndDate: toApi(this.endDate) },
			{ pPageBreak: this.pageBreakToggle },
			{ pPropertyName: this.propertyName },
			{ pFilterCaption: this.getFilterCaption },
			{ pDate: this.printedDate },
			{ pOffsite: this.localization.captions.reports.Offsite },
			{ pShowComment: this.reportFormGrp.value.customFilterFormGrp.ExcludeAppointmentComments },
			{ pExcludeComment: this.reportFormGrp.value.customFilterFormGrp.ExcludeAppointmentComments ? this.captions.Yes : this.captions.No },
			{ pPrevNext: this.reportFormGrp.value.customFilterFormGrp.IncludePrevAndNextAppointment ? this.captions.Yes : this.captions.No},
			{ pInactiveTherapist : this.includeInactiveToggle ?  this.captions.Yes : this.captions.No }
		];
	}

	protected formURIParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [ { StartDate: toApi(this.startDate) }, { EndDate: toApi(this.endDate) } ];
	}

	protected formFilters(): any {
		let toApi = this.localization.convertDateObjToAPIdate;
		if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
			let obj = {
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement,
                StartDate:(this.formGrp.get('startDate').value),
                EndDate: (this.formGrp.get('endDate').value),
                Data: this.selectedFilterOptions.filter(x=>x.showInDropDown == true).map((s) => s.id)
            };
            return obj;
        }
		return this.selectedFilterOptions.filter(x=>x.showInDropDown == true).map((s) => s.id);
	}

	patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }
}
