import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { SpaLocalization } from './spa-localization';

@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(private localization: SpaLocalization){

  }

  transform(value: any): any {
    return moment(value).format(this.localization.inputDateFormat);
  }

}
