import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { VCartBusiness } from "src/app/common/components/menu/vcart/vcart.business";
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertType } from '../../../Models/common.models';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-cart-transfer',
  templateUrl: './cart-transfer.component.html',
  styleUrls: ['./cart-transfer.component.scss']
})
export class CartTransferComponent implements OnInit {
  captions: any;
  buttonObj: { customSaveText: any; disabled: boolean; isEdit: boolean; };
  cartItineraryId: any;
  title: string;
  tableData: { userId: number; firstname: string; lastname: string; userName: string; selected: boolean }[];
  unfilteredtableData: { userId: number; firstname: string; lastname: string; userName: string; selected: boolean }[];
;
  selectedObj: any; constructor(public dialog: MatDialog, private localization: Localization,
    private dialogRef: MatDialogRef<CartTransferComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private _VCartBusiness: VCartBusiness, private utils: CommonUtilities) {
    this.captions = this.localization.captions;
    this.cartItineraryId = data?.cartItineraryId;
    this.tableData = data?.tableData;
    this.unfilteredtableData = cloneDeep(data?.tableData)
    this.title = data?.title?.toUpperCase()
  } ngOnInit(): void {
    this.buttonObj = {
      customSaveText: "Transfer",
      disabled: true,
      isEdit: false
    };
  }

  close() {
    this.dialogRef.close(false)
  }

  async save(e) {
    let result = null;
    try {
      this.utils.ToggleLoader(true);
      result = await this._VCartBusiness.transferCart(this.selectedObj.userId, Number(this.utils.GetUserInfo('userId')), this.localization.GetUserInfo('userName'), this.cartItineraryId);
    }
    catch (error) {
      this.utils.showAlert(this.captions.lbl_errorTransferCart, AlertType.Error);
    }
    finally {
      this.utils.ToggleLoader(false);
    }
    this.dialogRef.close(result);
  } onSelect(obj) {
    this.buttonObj.disabled = false;
    this.buttonObj = { ...this.buttonObj }
    this.tableData.forEach(x => {
      x.selected = false
    });
    obj.selected = true;
    this.selectedObj = obj
  }

  searchValueChange(e)
  {
    let searchText=e?e.trim().toLowerCase():''
    if(searchText && searchText.length > 0)
    {
     this.tableData=this.unfilteredtableData.filter(x=>x.userName.toLowerCase().includes(searchText)||x.firstname.toLowerCase().includes(searchText)||x.lastname.toLowerCase().includes(searchText))
    }else{
      this.tableData = this.unfilteredtableData;
    }
  }
}