import { Injectable } from '@angular/core';
import { EmailTemplatesService } from './email-templates.service';
import { NotificationActions,  TemplateData,  TemplateForm, TemplateDetail, TemplateDetailUI, TemplateDomainData, DefaultTemplateUpdate } from './email-templates.model';
import { Localization } from '../../localization/localization';

@Injectable()
export class EmailTemplatesBusiness {
  captions: any;
  emailTypes: any = [  ];
  notificationActions: NotificationActions[] =[];

  constructor(private localization: Localization, private _EmailTemplatesService : EmailTemplatesService) {
    this.captions = this.localization.captions.settings.utilities;
  }

  async getTemplateDetails(topicId: number): Promise<TemplateDetailUI> {
    const templateDetail: TemplateDetail = await this._EmailTemplatesService.GetTemplateDetailsByTopicId(topicId);
    return this.mapToUI(topicId, templateDetail);
  }

  async getActions(type: number): Promise<NotificationActions[]>{
    this.notificationActions = await this._EmailTemplatesService.getActionsByType(type);
    return this.notificationActions;
  }

  async CreateorUpdateTemplate(topicId: number, templateData: TemplateData[]): Promise<boolean> {
    try {
      const templateDomainData: TemplateDomainData = { topicId: topicId, templateData: templateData };
      return await this._EmailTemplatesService.CreateorUpdateTemplate(templateDomainData);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async UpdateDefaultTemplate(topicId: number, templateId: string, templateType: number): Promise<boolean> {
    try {
      const defaultTemplateUpdate: DefaultTemplateUpdate = {
        notificationTopicId: topicId,
        templateId: templateId,
        templateType: templateType
      }
      return await this._EmailTemplatesService.UpdateDefaultTemplate(defaultTemplateUpdate);
    } catch (e) {
      console.error(e);
      throw e;
   }
  }

  mapToUI(requestedTopicId: number, templateDetail: TemplateDetail): TemplateDetailUI {
    let email1templateData: any; 
    let email2templateData: any;
    let email3templateData: any; 
    let email4templateData: any; 
    let email5templateData: any; 
    let defaultEmailTemplateType: any;

    const topicId = templateDetail.templateDomainData.topicId ? templateDetail.templateDomainData.topicId : requestedTopicId;
    const topicName = this.notificationActions.find(x => x.id == topicId).name;

    if (templateDetail.templateDomainData.templateData) {
      email1templateData = templateDetail.templateDomainData.templateData.find(x => x.templateName ? x.templateName.includes('1') : undefined);
      email2templateData = templateDetail.templateDomainData.templateData.find(x => x.templateName ? x.templateName.includes('2') : undefined);
      email3templateData = templateDetail.templateDomainData.templateData.find(x => x.templateName ? x.templateName.includes('3') : undefined);
      email4templateData = templateDetail.templateDomainData.templateData.find(x => x.templateName ? x.templateName.includes('4') : undefined);
      email5templateData = templateDetail.templateDomainData.templateData.find(x => x.templateName ? x.templateName.includes('5') : undefined);

      const defaultTemplate = templateDetail.templateDomainData.templateData.find(x => x.isDefault);
      const defaultTemplateName = defaultTemplate ? defaultTemplate.templateName.split('_')[1] : ''; 
      defaultEmailTemplateType = this.emailTypes.find(x => x.templateName == defaultTemplateName);
    }

    const formData: TemplateForm = {
      action: topicId,
      name: topicName,
      emailTypes: defaultEmailTemplateType ? defaultEmailTemplateType.id : 1,
      emailType1: email1templateData ? email1templateData.htmlContent : '',
      emailType2: email2templateData ? email2templateData.htmlContent : '',
      emailType3: email3templateData ? email3templateData.htmlContent : '',
      emailType4: email4templateData ? email4templateData.htmlContent : '',
      emailType5: email5templateData ? email5templateData.htmlContent : '',
      templateId1: email1templateData ? email1templateData.templateId : '',
      templateId2: email2templateData ? email2templateData.templateId : '',
      templateId3: email3templateData ? email3templateData.templateId : '',
      templateId4: email4templateData ? email4templateData.templateId : '',
      templateId5: email5templateData ? email5templateData.templateId : '',
      templateName1: email1templateData ? email1templateData.templateName : '',
      templateName2: email2templateData ? email2templateData.templateName : '',
      templateName3: email3templateData ? email3templateData.templateName : '',
      templateName4: email4templateData ? email4templateData.templateName : '',
      templateName5: email5templateData ? email5templateData.templateName : '',
    };
    const keywords = templateDetail.keywords;

    return  {
      keywords: keywords,
      templateForm: formData
    };    
   
  }

  getEmailTypes() {
  this.emailTypes = [
      {
        id: 1,
        name: this.captions.Email1,
        templateName: 'Email1'
      },
      {
        id: 2,
        name: this.captions.Email2,
        templateName: 'Email2'
      },
      {
        id: 3,
        name: this.captions.Email3,
        templateName: 'Email3'
      },
      {
        id: 4,
        name: this.captions.Email4,
        templateName: 'Email4'
      },
      {
        id: 5,
        name: this.captions.Email5,
        templateName: 'Email5'
      }
    ];
    return this.emailTypes;
  }
}
