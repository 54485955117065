import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { AgContactConfig, ButtonValue } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-email-receipt',
  templateUrl: './email-receipt.component.html',
  styleUrls: ['./email-receipt.component.scss']
})
export class EmailReceiptComponent implements OnInit {

  mailInputs: AgContactConfig;
  form: UntypedFormGroup;
  captions: any;
  floatLabel: string;
  isEmailDisabled:boolean = false;
  proceedButton : ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  okButton: string;
  constructor(private fb: UntypedFormBuilder,  private localization: Localization, private dialogRef: MatDialogRef<EmailReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { 
    this.captions = this.localization.captions.shop;
    this.floatLabel = this.localization.setFloatLabel;
    this.okButton = this.captions.OK;
  }

  ngOnInit(): void {
    this.proceedButton ={
      label : this.okButton.toUpperCase(),
      type : "primary",
      disabledproperty : true
    }
    this.cancelButton = {
      label : this.captions.Cancel,
      type : "secondary"
    }
    this.initialize();
  }

  
  initialize(){ 
    if(this.data && this.data.length == 0)
    {
      this.form = this.fb.group({
        mail: this.fb.array([
          this.fb.group({
            emailValue: ['',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
          })
        ]),
      });
    }
    else    
    {
      this.data.forEach((element, i) => {
        if (i == 0) {
          this.form = this.fb.group({
            mail: this.fb.array([
              this.fb.group({
                emailValue: [element, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
              })
            ]),
          });
          this.proceedButton.disabledproperty = false;
      }
      else
      {
        const email = this.form.get('mail') as UntypedFormArray;
        let data = this.fb.group({
          emailValue: [ element ,[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        });
        email.push(data);
      }
      });
    }

    this.form.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.proceedButton.disabledproperty = !(this.form.valid && this.form.dirty);
      this.proceedButton = { ...this.proceedButton };
    });
  }
  addEmailItem(){
    const email = this.form.get('mail') as UntypedFormArray;
    if(email.value.length <3){
      email.push(new UntypedFormGroup({
        emailValue:new UntypedFormControl('',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      }))
    } else {
      this.isEmailDisabled = true;
    }
  }
  removeEmailItem(index: number): void {
      const email = this.form.get('mail') as UntypedFormArray;
      this.form.markAsDirty();
      email.removeAt(index);
  }
  onAction(){
    this.dialogRef.close(this.form);
  }
  onCancel(){
    this.dialogRef.close();
  }
}
