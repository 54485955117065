import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { Localization } from "src/app/common/localization/localization";
@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleSearchComponent implements AfterViewInit {

  searchText = '';
  width: number;
  searchPlaceholder: string;
  searchDisabled = false;
  noDataCaption = '';
  hideSearch = false;
  reduceHeightAutoComplete = '';
  inputdirectiveParam;
  searchAutomationId: string = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchOptionChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchIconClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() options;
  @Input() inputMaxLength: number;
  @Input() readonly:boolean;
  floatLabel: string;
  floatLabelNever: string;
  @Input('value')
  set setSearchField(value: string) {
    this.searchText = value;
  }
  @Input() focusField: boolean;
  @Input('disabled')
  set setDisabled(value: boolean) {
    if (value !== null) {
      this.searchDisabled = value;
      // this.clearText();
    }
  }

  @Input('inputdirectiveParam')
  set directiveParams(value) {
    this.inputdirectiveParam = value;
  }

  @Input('hideSearch')
  set searchHide(value: boolean) {
    this.hideSearch = value;
  }

  @Input('reduceHeightAutoComplete')
  set heightOfAutoCompleteBox(value: string) {
    this.reduceHeightAutoComplete = value;
  }

  @Input('noDataCaption')
  set noDataCaptionValue(value: string) {
    this.noDataCaption = value;
  }

  @Input('placeholder')
  set setPlaceHolder(value: string) {
    if (value) {
      this.searchPlaceholder = value;
      this.width = value.length + 5; // added 5 character length because of the icon space and padding
      this.calculateSearchBoxWidth();
    }
  }
  @Input('automationId')
  set automationId(value: string) {
    if(value) {
      this.searchAutomationId = "Srch_"+value+"_Search"
      // Srch_{{searchAutomationId}}_search
    }
  }

  resize: boolean;
  @Input('noResize')  // on create model popup opens search resize is restricted
  set callResizeSearchBox(value: boolean) {
    if (value) {
      this.resize = value;
    }
    if (!value && this.resize) {
      this.calculateSearchBoxWidth();
      this.resize = false;
    }
  }

  @Input('clearValue')
  set clearField(value){
    this.searchText = '';
  }

  constructor(private localization: Localization ){
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.resize) {
        this.calculateSearchBoxWidth();
      }
    }, 100);
    setTimeout(() => {
      if (this.focusField) {
        this.searchInput.nativeElement.focus();
      }
    },100)
    
  }
  calculateSearchBoxWidth() {
    const headerWrapper = document.getElementsByClassName('header-wrapper')[0];
    let pageHeader = headerWrapper ?
      headerWrapper['offsetWidth'] : 0;
    const searchInput = document.getElementsByClassName('searchbox')[0];
    let matFormWidth = this.setMatformWidth();
    if (!matFormWidth) {
      matFormWidth = this.setMatformWidth();
    }
    if (pageHeader > 0) {
      pageHeader = pageHeader - matFormWidth - 60;
    }
    const tempSearch = this.searchInput ? (this.searchInput.nativeElement.attributes['data-placeholder']?.value?.length || 0) : 1;
    const inputLength = this.searchPlaceholder ? this.searchPlaceholder.length : tempSearch;
    const inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 10 + 20;
    if (searchInput && pageHeader > 0) {
      const calculatedWidth = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      searchInput['style'].width = calculatedWidth;
      if (headerWrapper.classList.contains('--actionFirst') && document.getElementsByClassName('right-action-section')[0]) {
        document.getElementsByClassName('right-action-section')[0]['style'].width = calculatedWidth;
      }
    }

  }

  setMatformWidth() {
    if (document.getElementsByClassName('header-wrapper')[0]) {
      let minWidthElement;
      if (document.getElementsByClassName('header-wrapper')[0].classList.contains('--actionFirst')) {
        minWidthElement = 'left-action-section';
      } else {
        minWidthElement = 'right-action-section';
      }
      return document.getElementsByClassName(minWidthElement)[0] ?
        document.getElementsByClassName(minWidthElement)[0]['offsetWidth'] : 300; //min-300 max-470
    }
  }

  // Event Handlers
  searchValueChange() {
    this.searchChange.emit(this.searchText.trim());
  }

  //Commented for Bug 97003
  // bugId: 47874 - System not allowing the user to perform copy paste action for Room Number field (Valid Input)
  // @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
  //   const valuePasted = e.clipboardData.getData('text/plain');
  //   this.searchChange.emit(valuePasted.trim());
  // }

  clearText() {
    this.searchText = '';
    this.searchValueChange();
  }
  optionSelected(arg) {
   //this.searchText = arg.option.value.viewValue;
    this.searchOptionChange.emit(arg.option.value);
  }

  action(e) {
    if (this.searchText) {
      e.stopPropagation()
      this.clearText()
    } else {
      this.searchIconClick.emit();
    }
  }

  getOptionText(options){
    if (options) {
      this.searchText =
        options && options?.viewValue? options?.viewValue: options.length > 0 ? options: this.searchText;
      return options && options?.viewValue ? options?.viewValue : options.length > 0 ? options : "";
    }
  }
}
