import { Injectable } from "@angular/core";
import { ReportDownloadFormat, ReportAPIOptions } from 'src/app/report/business/report.modals';
import * as saveAs from 'file-saver'
import { FastReportBusinessService } from 'src/app/report/fast-report/fastreport.business.service';
export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
};
@Injectable()
export class PreSettlementReceiptService {
    constructor( private _fastReportBusinessService: FastReportBusinessService) {

    }

    public printPreSettlementReceipt(format: ReportDownloadFormat, options: ReportAPIOptions, callBack?: any): void {
        this._fastReportBusinessService.downloadReport(format, options, true);
    }


}
