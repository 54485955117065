import { appointment } from "../../business/shared.modals";
import { DropEvent } from "ng-drag-drop";


export interface PropertyOperatingTime {
    StartTime: string;
    EndTime: string;
}

export enum GridViewType {
    TherapistView = 'TV',
    AvailableTherapistView = 'ATV',
    LocationView = 'LV'
}

export interface GridCellClickEventParam {
    event: MouseEvent;
    timing: string;
    hdr: any;
    viewTypeSelected: GridViewType;
    selectedDateWithYear: Date;
    alldaycheckbox: boolean;
    obj: any;
    rowIndex: number;
    columnIndex: number;
    cellObjectIndex: number | any;
    gridtimeinterval: number;
}

export interface GridItemDropEventParam {
    event: DropEvent;
    time: string;
    FromhdrColoumn: CalendarGridHeader<any>;
    TohdrColoumn: CalendarGridHeader<any>;
    celldata: any;
    rowIndex: number;
    columnIndex: number;
    GridViewType: GridViewType;
    CalendarGridType: CalendarGridType;
    isPastDate: boolean;
    selectedDateforWeekView?: Date;
    isWeekViewForAllTherapist?: boolean;
}


export interface CalendarGridBodyProps extends appointment {

    Icons: any[];
    clientDetails: any;
    appointmentIconDetail: any;
    breakTypeDetail: BreakTypeDetail;
    deposit: any[];
    retailTransactionStatus: string;
    ticketNumber: string;


    setCss: boolean;
    overLapped: number;
    multiGroupOverlap: number;
    overlappedFlag: boolean;
    statusClass: string;
    setupColorClass: string;
    breakdownColorClass: string;
    cellClass: string;
    cellBDClass: string;
    cellSUClass: string;
    cellPTDClass: string;
    startTimewithsetuptime: Date;
    endTimewithbreakdowntime: Date;
    serviceName: string | number;
    locationName: string | number;
    therapistName: string;
    appointmentAddOns: string;
    bookingName?: string;
}

export interface BreakTypeDetail {
    id: number;
    code: string;
    description: string;
    color: string;

}

/**
 * Calendar Grid Header
 */
export interface CalendarGridHeader<T> {
    /** ID of Header */
    id: number;
    /** Image string in Base64 format or full URL */
    image: string;
    /** Header Title text, every title will be shown in one line. if two properties to be shown in single line,
     * Use | symbol like firstName|lastName
     */
    titleKey: string[];
    /** Header Object.(like Therapist/ Location) */
    headerObject: T;
    /** Mouse hover text to be displayed */
    titleDesc: string;
}

export interface Timing {
    slotTime: Date,
    localizedSlotTime: string
}

export interface CalendarGridBody {
    timing: string;
    timingObj?: Date;
    data: CalendarGridBodyData[]
}


export interface CalendarGridBodyData {
    data: CalendarGridBodyProps[];
    tempdata: Array<CalendarGridBodyProps[]>;
    overbookcell: string;
    BlockingSlot: boolean;
    noschedule: string;
    isOnCall: string;
    spaDayout: string;
    hdr: any;
}

export interface CalendarGridProperty<Theader> {
    GridType: CalendarGridType;
    EnableToolTip: boolean;
    AllDayCheckBox: boolean;
    EnableShowOverlapFlag: boolean;
    GridTimeInterval: number;
    HeaderArray: CalendarGridHeader<Theader>[];
    BodyArray: CalendarGridBody[];
    SelectedDate: Date;
    StartTime: string;
    EndTime: string;
    viewTypeSelected?: GridViewType;
    allowSingleHeader?: boolean;
    isViewOnly?: boolean;
    isTodayDateChange?: boolean;
    isWeekEnabled?: boolean;
    isWeekEnabledforAllTherapist?: boolean;
}

export enum CalendarGridType {
    Appointment = 'appointment',
    Standard = 'standard',
    Copy = 'copy',
    Move = 'move',
    ParkingLot = 'parkinglot'
}

export interface CalendarAllDayHeader {
    row1: string;
    row1a: string;
    row2: string;
    row2a: string;
    row3: string;
    year: string;
}