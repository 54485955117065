import { MenuTreeView, NavigationPrimaryMenu } from 'src/app/eatecui/source/core/models/navigation-model';

export interface NavigationMenu {
    GetNavigationMenu: any;
    GetNavigationMenuSucess: NavigationPrimaryMenu[];
    GetTretiaryTreeView: MenuTreeView[];
}

export const intialNavigationState: NavigationMenu = {
    GetNavigationMenu: null,
    GetNavigationMenuSucess: null,
    GetTretiaryTreeView: null
};