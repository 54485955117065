import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import { MsalIntegrationService } from './MsalIntegration.service';
import { SpaUtilities } from '../utilities/spa-utilities';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  private graphClient: Client;
  private appointmentWindows: Object = {};
  constructor(public authService: MsalIntegrationService, private utils: SpaUtilities,
    private localization: SpaLocalization, private propertyInfo: SpaPropertyInformation) {
    try {
      this.graphClient = Client.init(this.GetIntializer());
      this.authService.logoutEvent.subscribe((val) => {
        let prop = Object.keys(this.appointmentWindows);
        if (val && prop.length) {
          prop.forEach(element => {
            if (this.appointmentWindows[element] && this.appointmentWindows[element].window)
              this.appointmentWindows[element].window.close();
          });
        }
      })
    }
    catch (err) {
      console.log("Graph Initialization Error : ", err);
    }
  }

  GetPropConfig() {
    return this.propertyInfo.GetPropertyConfiguration() || {};
  }

  GetIntializer() {
    return {
      authProvider: async (done) => {
        // Get the token from the auth service
        let token = await this.authService.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token.accessToken);
        } else {
          done("Could not get an access token", null);
        }
      }
    };
  }

  async searchMailByAttachmentName(searchText: string) {
    try {
      await this.authService.checkAndLogin();
      if (this.authService.getAccount()) {
        let result = await this.graphClient.api('/me/messages').search(`"subject:${searchText} OR attachment:${searchText}"`).get();
        return result.value;
      }
    }
    catch (err) {
      console.log("Graph Error : ", err);
    }
  }

  async openInTakeFormMail(appointmentId: string) {
    const appointmentCaption = this.localization.captions.bookAppointment;
    let config: any = this.GetPropConfig();
    try {
      if (config && config.clientId) {
        let resultVal = await this.searchMailByAttachmentName(appointmentId);
        if (Array.isArray(resultVal)) {
          if (resultVal.length) {
            if (this.appointmentWindows && this.appointmentWindows[appointmentId.toString()] && this.appointmentWindows[appointmentId.toString()].window)
              this.appointmentWindows[appointmentId.toString()].window.close();
            this.appointmentWindows[appointmentId.toString()] = window.open(resultVal[0].webLink.replace('&exvsurl=1', ''), `newWindow_${appointmentId}`, "_blank");
          }
          else {
            this.utils.ShowErrorMessage(appointmentCaption.ViewIntakeForm, appointmentCaption.NoInTakeFormReceived);
          }
        }
        console.log('Mail Search Result : ', resultVal);
      }
      else {
        console.log('ClientId not configured to search mail');
      }
    }
    catch (err) {
      console.log('On Mail Search ', err);
    }
  }
}
