import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall } from "src/app/shared/service/http-call.service";
import * as GlobalConst from '../../../shared/globalsContant';
import { ConfigData } from "./quickid-config.model";

@Injectable()
export class QuickIdConfigService {
    constructor(private http: HttpServiceCall) {        
    }

    public async GetQuickIdConfigSettings(): Promise<ConfigData[]> {
      const response = await this.http.CallApiAsync<ConfigData[]>(
        {
          callDesc: 'GetAllSetting',
          method: HttpMethod.Get,
          host: GlobalConst.Host.spaManagement
        }
      );
      return response.result;
    }

    public async UpdateQuickIdConfigSettings(data): Promise<ConfigData[]> {
      const response = await this.http.CallApiAsync<ConfigData[]>(
        {
          callDesc: 'UpdateSetting',
          method: HttpMethod.Put,
          host: GlobalConst.Host.spaManagement,
          body: data
        }
      );
      return response.result;
    }

    public async GetAllSettingbyModule(data): Promise<ConfigData[]> {
      let response: any = await this.http.CallApiAsync<any>({
        host: GlobalConst.Host.spaManagement,
        callDesc: "GetAllSettingByModule",
        method: HttpMethod.Get,
        uriParams: {  module:data },
       
    });        
      return response.result;
    }

    
}