import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { SPAManagementBreakPoint } from '../../../../shared/globalsContant';
import * as _ from 'lodash';
import { ClientSOAPNote } from '../../../business/shared.modals';
import { HttpServiceCall } from '../../../service/http-call.service';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-soapnotes',
  templateUrl: './soapnotes.component.html',
  styleUrls: ['./soapnotes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SoapnotesComponent implements OnInit {
  public soapNote: UntypedFormGroup;
  captions: any;
  clientId: number;
  clientSoapNote: ClientSOAPNote;
  clientSoapNotes: ClientSOAPNote[];
  hasAccessToAddSOAPNotes: boolean;
  isSoapNotesViewOnly: boolean;
  originalClientSoapNotes: ClientSOAPNote[];
  clientWindowConvertion : Subscription;
  isFirstTime: boolean = true;
  messageForWarning: string;
  floatLabel:string;
  showClose: boolean = true;
  isGuestRecordPurged: boolean  = false;
  @Input('changedClientData')
  set changeClientData(value){
    if(value){
      this.initializeFormData();
    }
  }

  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, private utilities: SpaUtilities, public appoinmentPopupService: AppointmentpopupService, private breakPoint: BreakPointAccess,
    private http: HttpServiceCall, private PropertyInfo: SpaPropertyInformation) {
    this.captions = this.localization.captions.bookAppointment;
    this.floatLabel = this.localization.setFloatLabel;
  }

  soapNotes() {
    this.clientSoapNote = {
      clientId: this.clientId,
      loggedTime: this.localization.LocalizeDateTimeFormatDDMMMMYYYY(this.PropertyInfo.CurrentDTTM),
      note: this.soapNote.get('soapNotesName').value,
      userId: parseInt(this.http.GetPropertyInfo("UserId")),
      userName: this.http.GetPropertyInfo("userName"),
      id: 0
    };
  }

  ValidateSOAPNote(): void {
    this.clientSoapNote.note = this.soapNote.get('soapNotesName').value;
    this.clientSoapNote.loggedTime = '';
    if (this.clientSoapNote.note.trim() === "" && this.originalClientSoapNotes.length != this.clientSoapNotes.length) {
      this.clientSoapNotes.pop();
    } else if (this.originalClientSoapNotes.length == this.clientSoapNotes.length) {
      this.clientSoapNotes.push(this.clientSoapNote);
    }

    this.appoinmentPopupService.clientSoapNotes = this.clientSoapNotes;
  }

  ngOnInit() {
    this.initializeFormData();
  }

  initializeFormData(){
    this.isSoapNotesViewOnly = this.appoinmentPopupService.isSoapNotesViewOnly;
    if (this.appoinmentPopupService.clientWidowActionType !== 'EDIT') {
      this.appoinmentPopupService.hasAccessForSoapNotes = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.AddSOAPNotes], false);
    }
    else {
      this.appoinmentPopupService.hasAccessForEditSoapNotes = this.breakPoint.CheckForAccess([SPAManagementBreakPoint.EditSOAPNotes], false);
    }
    this.isGuestRecordPurged = this.appoinmentPopupService?.clientEditData?.clientDetail?.isPurged;
    this.messageForWarning = this.appoinmentPopupService?.allCaptions?.lbl_guestRecordPurged;
    this.soapNote = this.fb.group({
      soapNotesName: [''],
    });
    this.isGuestRecordPurged ? this.soapNote.disable() : ''; 
    this.appoinmentPopupService.soapNotesFormGrp = this.soapNote;
    this.prepareSOAPNoteData();
    this.clientWindowConvertion = this.appoinmentPopupService.convertToEdit.subscribe(x =>{
      if(x && x.id > 0 && this.isFirstTime){
        this.isFirstTime = false;
        this.initializeFormData();  
      }
    });
  }
  closeWarning(){
    this.showClose = false;
  }

  prepareSOAPNoteData() {
    if (this.appoinmentPopupService.clientEditData && this.appoinmentPopupService.clientWidowActionType == "EDIT") {
      this.clientSoapNotes = this.appoinmentPopupService.clientEditData.clientSOAPNotes ? this.appoinmentPopupService.clientEditData.clientSOAPNotes : [];
      this.originalClientSoapNotes = _.cloneDeep(this.clientSoapNotes);
      this.clientId = this.appoinmentPopupService.clientEditData.clientDetail.id;
    } else {
      this.appoinmentPopupService.clientSoapNotes = [];
      this.originalClientSoapNotes = [];
      this.clientSoapNotes = [];
    }
    this.soapNotes();
    this.clientSoapNotes.push(this.clientSoapNote);

  }

  ngOnDestroy() : void {
    if(this.clientWindowConvertion){
      this.clientWindowConvertion.unsubscribe()
    }
    this.isFirstTime = true;
  }


}
