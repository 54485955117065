import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceAddOns, ItemSearchBaseResponse, ItemSearchRequest } from '../../../shared/business/view-settings.modals';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { Host, SPAScheduleBreakPoint } from '../../../shared/globalsContant';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { BaseResponse } from '../../../shared/business/shared.modals';
@Component({
  selector: 'app-sevice-add-ons',
  templateUrl: './sevice-add-ons.component.html',
  styleUrls: ['./sevice-add-ons.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SeviceAddOnsComponent implements OnInit {
  clickbutton: any;
  @Input() dataInput;
  @Input() popupConfigs;
  isUserAuthorized = true;
  isViewOnly = true;
  sgDataInput: any = [];
  flagStatus: any;
  FormGrp: UntypedFormGroup;
  locObj: any = {};
  retailItemDirty = false;
  blnReadOnly: boolean;
  captions: any = this.localization.captions;
  selectedRetailItemId: number;
  retailItemMiss = true;
  Category: any = [];
  retailItemType = 5;
  searchFilter: ItemSearchBaseResponse[] = [];
  guid: any;
  floatLabel:string;
  throttleTime: number = 1000;

  constructor(private http: HttpServiceCall, public dialogRef: MatDialogRef<SeviceAddOnsComponent>, private Form: UntypedFormBuilder,
    public localization: SpaLocalization, private utils: SpaUtilities,
    private vsBusiness: ViewSettingClientBusiness, private BP: BreakPointAccess) {
      this.floatLabel = this.localization.setFloatLabel;
  }
  onNoClick(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }
  Done(selectedRetailItemId: any): void {
    if (selectedRetailItemId > 0) {
      this.selectedRetailItemId = selectedRetailItemId;
      this.retailItemMiss = false;
      this.retailItemDirty = true && this.FormGrp.valid;
    } else {
      this.retailItemMiss = true;
      this.retailItemDirty = false;
    }
  }
  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingServiceAddOn]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingServiceAddOn);
    if (this.isViewOnly) {
      this.utils.disableControls(this.FormGrp);
    }
  }
  ngOnInit() {
    this.locObj = this.localization.captions.setting;
    this.GetAllCategory();
    this.FormGrp = this.Form.group({
      id: '',
      addOnName: ['', [Validators.required, EmptyValueValidator]],
      commissionable: false,
      isAvailableOnWeb: false,
      retailCategoryId: ['', [Validators.required, EmptyValueValidator]],
      price: ['', Validators.required],
      linkedItem: ['', Validators.required]
    });
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.ValidateBreakPoint();

    if (this.popupConfigs.operation == "create") {
      this.clickbutton = this.captions.setting.save;
      this.blnReadOnly = false;
      this.FormGrp.setValue({
        id: '',
        addOnName: this.dataInput ? this.dataInput.addOnName : '',
        commissionable: this.dataInput ? this.dataInput.commissionable : false,
        isAvailableOnWeb: this.dataInput ? this.dataInput.isAvailableOnWeb : false,
        retailCategoryId: this.dataInput ? this.dataInput.retailCategoryId : '',
        price: this.dataInput ? this.dataInput.price : '',
        linkedItem: this.dataInput ? this.dataInput.retailItemId : ''
      });
    } else if (this.popupConfigs.operation == "edit") {
      // Allow Edit now

      this.blnReadOnly = true;
      this.clickbutton = this.captions.setting.update;
      this.FormGrp.patchValue(this.dataInput);
      this.getLinkedItemDesc(this.dataInput.retailItemId);
      this.retailItemMiss = this.dataInput.retailItemId > 0 ? false : true;
    }
  }

  GetAllCategory() {
    this.http.CallApiWithCallback<any>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetCategoryAndSubCategoryLink",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      extraParams: []
    });

  }

  saveClick() {
    if (this.popupConfigs.operation == "create") {
      this.createServiceAddons()
    } else if (this.popupConfigs.operation == "edit") {
      this.updateServiceAddOns()
    }
  }

  /*
  * Create Add ons
  */
  createServiceAddons() {
    const bodyObj: ServiceAddOns = this.formBody(0);

    this.http.CallApiWithCallback<ServiceAddOns[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "CreateAddOn",
      method: HttpMethod.Post,
      body: bodyObj,
      showError: true,
      extraParams: [bodyObj]
    });

  }

  /*
   * Edit Add Ons
   */
  updateServiceAddOns() {
    const id = this.FormGrp.controls['id'].value;
    const bodyObj: ServiceAddOns = this.formBody(id);

    let patchJson = [];
    const patchData = this.utils.getEditedData(this.FormGrp, "code");
    if (this.selectedRetailItemId) {
      patchData.patchJson.push({ op: "replace", path: "/retailItemId", value: this.selectedRetailItemId });
    }
    patchJson = patchData.patchJson;

    if (patchJson.some(x => x.path == "/linkedItem")) {
      patchJson = patchJson.filter(x => x.path != "/linkedItem")//Since linked item added with retailItemID,filtered linked item reference
    }

    this.http.CallApiWithCallback<ServiceAddOns[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateAddOn",
      method: HttpMethod.Patch,
      uriParams: { id: id },
      body: patchJson,
      showError: true,
      extraParams: [bodyObj]
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateAddOn" || callDesc == "UpdateAddOn") {
      result["NewData"] = extraParams[0];
      this.dialogRef.close(result);
    }
    if (callDesc == "GetCategoryAndSubCategoryLink") {
      let response = <any>result.result;
      this.Category = response ? this.utils.sortObj(response.category, "listOrder") : [];
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "CreateAddOn" || callDesc == "UpdateAddOn") {
      if (error) {
        error["NewData"] = extraParams[0];
      }
    }
  }

  validateSearchControl(isSearchControlinValid: boolean) {
    this.retailItemMiss = !isSearchControlinValid;
  }

  formBody(id): ServiceAddOns {
    const addOnName = this.FormGrp.controls['addOnName'].value;
    const price = this.FormGrp.controls['price'].value;
    const retailCategory = this.FormGrp.controls['retailCategoryId'].value;
    const isCommissionable = this.FormGrp.controls['commissionable'].value;
    const isAvailableOnWeb = this.FormGrp.controls['isAvailableOnWeb'].value;
    return {
      id: id,
      addOnName: addOnName,
      price: this.localization.currencyToSQLFormat(price),
      retailCategoryId: retailCategory,
      commissionable: isCommissionable,
      retailItemId: this.selectedRetailItemId,
      isAvailableOnWeb: isAvailableOnWeb
    };
  }

  async getLinkedItemDesc(retailItemId: number) {
    var itemText = await this.vsBusiness.GetLinkedRetailItemTextByItemId(retailItemId);
    this.FormGrp.controls['linkedItem'].setValue(itemText);
  }

  async searchTextEmit(searchText) {
    this.retailItemMiss = true;
    this.retailItemDirty = false;
    this.searchFilter = [];
    this.guid = this.utils.generateGUID();
    let body: ItemSearchRequest = {
      itemType: this.retailItemType,
      searchKey: searchText,
      requestId: this.guid
    };
    var response = await this.vsBusiness.SearchRetailItem(body);
    if (response && response.requestId === this.guid) {
      this.searchFilter = response.retailItems;
    }
  }
}

