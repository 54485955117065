import { Component, Inject, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { SaveButtonObj } from 'src/app/common/Models/ag-models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ActionMode, AlertAction, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { MasterComponentLinkingBusiness } from '../master-component-linking.business';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-create-master-component-linking',
  templateUrl: './create-master-component-linking.component.html',
  styleUrls: ['./create-master-component-linking.component.scss'],
  providers: [MasterComponentLinkingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class CreateMasterComponentLinkingComponent implements OnInit {
  data: any;
  captions: any;
  title: string;
  buttonObj: SaveButtonObj;
  createForm: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CreateMasterComponentLinkingComponent>,
    private utils: CommonUtilities,
    private fb: UntypedFormBuilder,
    private localization: Localization,
    private business: MasterComponentLinkingBusiness
  ) {
    this.captions = this.localization.captions;
    this.data = data;
  }

  ngOnInit(): void {
    this.title = this.data.mode == ActionMode.create ? this.captions.pg_title_createComponentLinking : this.captions.pg_title_editComponentLinking;
    this.buttonObj = {
      customSaveText: this.data.mode == ActionMode.create ? this.captions.btn_create : this.captions.btn_update,
      customCancelText: this.captions.btn_cancel,
      disabled: true,
      isEdit: this.data.mode == ActionMode.create
    };
    this.formBuilder();
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  formBuilder() {
    this.createForm = this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
      typeId: ['']
    });
    this.createForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.buttonObj.disabled = !(this.createForm.valid && this.createForm.dirty);
      this.buttonObj = { ...this.buttonObj };
    });
    this.createForm.patchValue(this.data.data);
  }


  close() {
    if (this.createForm.dirty) {
      this.utils.showCommonAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.dialogRef.close({ from: 'cancel' });
        }
      })
    } else {
      this.dialogRef.close({ from: 'cancel' });
    }

  }

  async save(arg) {
    let performTypeIdValidation: boolean = true;
    if(this.data.data.typeId && this.data.data.typeId === this.createForm.value.typeId ){
      performTypeIdValidation = false
    }
    this.business.getComponentsLinkingConfigByTypeId(this.createForm.value.typeId, performTypeIdValidation).then(IsExist => 
    {
      if (IsExist) {
        let errorMsg = this.localization.replacePlaceholders(this.captions.lbl_typeIdExists, ['typeId'], [this.createForm.value.typeId])
        this.utils.showCommonAlert(errorMsg, AlertType.Error, ButtonTypes.OkCancel);
      } 
      else 
      {
        this.dialogRef.close({
          from: 'save',
          data: this.createForm.value,
          id: this.data.data.id ? this.data.data.id : 0
        });

      }


    });

  }
}
