import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserAccessDataService } from 'src/app/common/dataservices/authentication/useraccess.data.service';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Localization } from 'src/app/common/localization/localization';

@Injectable()
export class UserAccessService {

  captions: any;
  constructor(private utilities: CommonUtilities, private useraccessdataservice: UserAccessDataService, public localization: Localization) { 
    this.captions = this.localization.captions;
  }

  public async getUserAccess(
    breakPointNumber: number,
    showUserMessage: boolean = true,
    callBack?: any): Promise<UserAccessModel.BreakPointResult> {
    const result = await this.useraccessdataservice.getUserAccess(breakPointNumber);
    const bpMessage = this.localization.captions[breakPointNumber];
    if (!result.isAllow && !result.isViewOnly && showUserMessage) {
      this.showBreakPointPopup(bpMessage, callBack);
    }


    return result;
  }

  public async getUserAccessDetails(
    breakPointNumber: number,
    showUserMessage: boolean = true,
    callBack?: any): Promise<UserAccessModel.BreakPointResult> {
    try {
      return await this.useraccessdataservice.getUserAccess(breakPointNumber);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public showBreakPointPopup(functionName?: string, callBack?: any) {
    let message: string;
    message = `${this.captions.err_breakPointAccessDeniedMsg}
         <br><br>${this.localization.captions.common.lbl_breakpoint}: ${functionName}`;
    this.utilities.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
  }

  public showBreakPointPopupMessage(functionName?: string, callBack?: any) {
    let message: string;
    message = `${'The user does not have permissions to perform this action'}
         <br><br>${'BreakPoint'}: ${functionName}`;
    this.utilities.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
  }

  public async getUserAccesses(
    breakPointNumber: number[],
    showUserMessage: boolean = true,
    callBack?: any): Promise<UserAccessModel.BreakPointResult[]> {
   return this.useraccessdataservice.getUserAccesses(breakPointNumber);
 
  }

  public disableControls(FormGroups: UntypedFormGroup, excludedControls?: string[]) {
    this.utilities.disableControls(FormGroups, excludedControls);
  }
  public enableControls(FormGroups: UntypedFormGroup, excludedControls?: string[]) {
   //enableControls
  }
}
