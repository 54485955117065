import { Injectable } from '@angular/core';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { NotificationTemplate, NotificationKeyword,TemplateDomainData, NotificationAPITemplate } from '../../template-email/crud-email-template/crud-email-template.model';

@Injectable()
export class CrudWhatsappTemplatesBusiness  {

  constructor(private _templateDataServices: TemplateDataServices, private dmConfigDataService: DMConfigDataService) {
  }

  exceptionHandle(ex: any) {
    console.error(ex);
    throw ex;
  }

  async getDmconfig() {
    return await this.dmConfigDataService.getDataMagineConfig();
  }

  async getDmconfigSession() {
    return await this.dmConfigDataService.getDataMagineConfigSession();
  }

  async getWhatsAppTemplateData(typeId: number, showInActive: boolean = false): Promise<NotificationTemplate[]> {
    try {
      return await this._templateDataServices.GetAllTemplates(typeId, showInActive);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async getNotificationKeywords(eventId: number[], productId: number): Promise<NotificationKeyword[]> {
    try {
      return await this._templateDataServices.GetTemplateKeywords(eventId, productId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async getTemplateDetailsById(notificationTemplateId: number): Promise<TemplateDomainData> {
    try {
      return await this._templateDataServices.GetTemplatByNotificationTemplateId(notificationTemplateId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async CreateorUpdateTemplate(templatedomaindata: TemplateDomainData): Promise<number> {
    try {
      return await this._templateDataServices.CreateorUpdateTemplate(templatedomaindata);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async DeleteTemplate(notificationTemplateId: number): Promise<NotificationTemplate[]> {
    try {
      return await this._templateDataServices.DeleteTemplate(notificationTemplateId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async UpdateTemplate(id: number, notificationTemplate: NotificationAPITemplate): Promise<boolean> {
    try {
      return await this._templateDataServices.UpdateTemplate(id, notificationTemplate);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async getMaxListOrder(typeId:number): Promise<number> {
    try {
      return await this._templateDataServices.GetMaxListOrder(typeId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async UpdateListOrder(fromorder: number, toOrder: number, typeId: number): Promise<boolean> {
    try {
      return await this._templateDataServices.UpdateListOrder(fromorder, toOrder, typeId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async DefaultTemplateExists(eventIds: number[], id: number,typeId:number): Promise<boolean> {
    try {
      return await this._templateDataServices.DefaultTemplateExists(eventIds, id, typeId);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }
}