import { Component, Inject, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromptType, ButtonOptions } from '../../shared/globalsContant';
import { UserAlerts } from '../../core/config/alerts-config';
import { popupConfig } from '../../shared/business/shared.modals';
import { ViewSettingClientBusiness } from '../../shared/common-functionalities/business/view-settings.business';

@Component({
  selector: 'app-setting-dialog-popup',
  templateUrl: './setting-dialog-popup.component.html',
  styleUrls: ['./setting-dialog-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingDialogPopupComponent implements OnInit {
  public test = true;
  datRec: any;
  popupConfigs: popupConfig;
  CloseClick: boolean;
  tableData:any;
  isADB2CConfigEnabled:boolean=false;

  constructor(private userAlerts: UserAlerts,
    public dialogRef: MatDialogRef<SettingDialogPopupComponent>,
    private vsBusiness: ViewSettingClientBusiness,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public viewContainerRef: ViewContainerRef) {
      console.log("DATA",this.data);
     }

  onNoClick(): void {
    if (this.vsBusiness.activeFormGroup && this.vsBusiness.activeFormGroup.dirty) {
      this.promptUser();
    } else {
      this.dialogRef.close();
    }
  }

  promptUser() {
    if (this.vsBusiness.activeFormGroup.dirty) {
      this.userAlerts.showPrompt(PromptType.UnsavedChanges, this.PopupCallback.bind(this));
    } else {
      this.dialogRef.close();
    }
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == ButtonOptions.Yes) {
      this.dialogRef.close();
    }
  }

  ngOnInit() {
    this.datRec = this.data.datarecord;
    this.popupConfigs = this.data.popupConfig;
    this.tableData=this.data.tabledata;
    this.isADB2CConfigEnabled=this.data.isADB2CConfigEnabled;
  }
}
