import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization'
import * as XLSX from 'xlsx';
import { FailurePopupBusiness } from './failure-popup.business';

@Component({
  selector: 'app-failure-popup',
  templateUrl: './failure-popup.component.html',
  styleUrls: ['./failure-popup.component.scss'],
  providers: [FailurePopupBusiness],
  encapsulation: ViewEncapsulation.None
})
export class FailurePopupComponent implements OnInit {
  header: string;
  captions;
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent;
  isShow: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>,
  private localization: Localization,  private business: FailurePopupBusiness) { }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.initializeInputs();
    this.generateTable();
    this.isShow = this.data.isShow;
  }

  initializeInputs() {
    this.header = 'Failure Details';   
  }

  generateTable() {
    this.headerOptions = this.business.getHeaderOptions();
    this.tableOptions = this.business.getTableOptions();
    this.tableContent = this.data.errors;
  }

  downloadFailureExcels(e){
    let utilityId = this.data.elementId;
    let temp =  this.data.elementFileName.split(".");
    let filename = 'Failures_'+ temp[0] + '_' + utilityId + '.xlsx';
    this.exportFailureExcel(this.data.errors, filename, ('Failures'));
  }

   //Exporting Import Failures datas to excel file 
   public exportFailureExcel(jsonData: any[], fileName: string, screenName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, screenName.toString());

    XLSX.writeFile(wb, fileName.toString());  
  }
  async downloadFailedExcelRows(e) { 
    await this.business.download(this.data.elementId);
  }

  close(e) {
    this.dialogRef.close();
  }

  onTableAction(e) {
  }

}
