import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { SpaLocalization } from '../localization/spa-localization';
import { ConfigRouteCommon } from 'src/app/common/shared/config/config-route-common';
@Injectable()
export class SPAConfig extends ConfigRouteCommon {
   
    constructor(public http: HttpClient, public locale: SpaLocalization) {
        super(http, locale);
    }
    public getRouteJson(): Object {
        return this._config;
    }
}
