
import { Injectable } from '@angular/core';
import { API } from 'src/app/settings/dynamic-yielding/dynamic-yielding.model';
import { Host } from '../globalsContant';
import { BaseResponse } from '../models/http.model';
import { HttpMethod, HttpServiceCall } from './http-call.service';
import { SpaUtilities } from '../utilities/spa-utilities';


@Injectable({
  providedIn: 'root'
})
export class DynamicPricingDataService {

  constructor(private http: HttpServiceCall,private util:SpaUtilities) {
  }


  public async GetDynamicPricingByGroupId(groupId: string, overrideGroupId: string): Promise<API.DynamicPricingRule[]> {
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'GetDynamicPricingByGroupId',
        host: Host.schedule,
        method: HttpMethod.Get,
        uriParams: { groupId: groupId, overrideGroupId: overrideGroupId },
        showError: true
      }) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }



  public async GetDynamicPricingByServiceAndDate(startDate: string, endDate: string, groupId: string): Promise<API.YieldingDetails[]> {
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'GetDynamicPricingByServiceAndDate',
        host: Host.schedule,
        method: HttpMethod.Get,
        uriParams: { "startDate": startDate, "endDate": endDate, "groupId": groupId },
        showError: true
      }) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async GetDynamicPricingServices(startDate: Date, endDate: Date) {
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'GetDynamicPricingServices',
        host: Host.schedule,
        method: HttpMethod.Get,
        uriParams: { "startDate": startDate, "endDate": endDate },
        showError: true
      }) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async CreateDynamicPricing(dynamicPricingObj: API.DynamicPricingRule[], successCallback, errorCallback) {
    try {
      const response = await this.http.CallApiWithCallback({
        host: Host.schedule,
        success: successCallback,
        error: errorCallback,
        callDesc: "CreateDynamicPricing",
        method: HttpMethod.Post,
        body: dynamicPricingObj,
        showError: true,
        extraParams: []
      });
      return response;
    } catch (e) {
      this.http.exceptionHandle(e);
    }

  }

  public async UpdateDynamicPricing(dynamicPricingObj: API.DynamicPricingRule[], successCallback, errorCallback) {
    try {
      const response = await this.http.CallApiWithCallback({
        host: Host.schedule,
        success: successCallback,
        error: errorCallback,
        callDesc: 'UpdateDynamicPricing',
        method: HttpMethod.Put,
        body: dynamicPricingObj,
        showError: true,
        extraParams: []
      });
      return response;
    } catch (e) {
      this.http.exceptionHandle(e);
    }

  }

  public async PreviewDynamicPricing(stDate, serviceIds: Number[]): Promise<API.DynamicPricingServices[]> {
    this.util.ToggleLoader(true)
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'PreviewPricing',        
        host: Host.schedule,
        method: HttpMethod.Put,
        uriParams: { "date": stDate },
        body: serviceIds,
        showError: true
      }) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    } finally {
      this.util.ToggleLoader(false)
    }
  }



  public async ViewDynamicPricing(groupId: Number): Promise<API.DynamicPricingViewPricingData> {
    this.util.ToggleLoader(true)
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'ViewPricing',
        host: Host.schedule,
        method: HttpMethod.Get,
        uriParams: { groupId: groupId },
        showError: true
      }) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    } finally {
      this.util.ToggleLoader(false)
    }
  }

  public async DeleteDynamicPricing(groupId: string) {
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'DeleteDynamicPricingByGroupId',
        host: Host.schedule,
        method: HttpMethod.Delete,
        uriParams: { "groupId": groupId },
        showError: true
      }) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async DeleteDynamicPricingById(id: number) {
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'DeleteDynamicPricingById',
        host: Host.schedule,
        method: HttpMethod.Delete,
        uriParams: { "dynamicPricingId": id },
        showError: true
      }) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async DeleteOverrideDynamicPricingByGroupId(overrideGroupId,dynamicPricingGroupId,deleteUsedPricing = false ) {
    try {
      const response = await this.http.CallApiAsync({
        callDesc: 'DeleteOverrideDynamicPricingByGroupId',
        host: Host.schedule,
        method: HttpMethod.Delete,
        uriParams: { overrideGroupId: overrideGroupId ,dynamicPricingGroupId : dynamicPricingGroupId ,deleteUsedPricing:deleteUsedPricing  },
        showError: true
      }) as any;
      return response.result;
    }
    catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async getPricingDetailsListView(
    startDate: string,
    endDate: string
  ): Promise<any> {
    try {
      const response = (await this.http.CallApiAsync({
        callDesc: "GetDynamicPricingForListView",
        host: Host.schedule,
        method: HttpMethod.Get,
        uriParams: { startDate: startDate, endDate: endDate },
        showError: true,
      })) as any;
      return response.result;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  

}
