import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import * as DecoupledEditor from '../../../ckeditor5-build-decoupled-document-master/build/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { cloneDeep } from 'lodash'
import { TemplateDataServices } from 'src/app/common/dataservices/notification/template-data-service';
import { EventDataServices } from 'src/app/common/dataservices/notification/event.data.service';
import { NotificationKeyword, TemplateData, TemplateDomainData, NotificationType, NotificationTemplateContents } from '../../template-email/crud-email-template/crud-email-template.model';
import { Events, EventNotification } from '../../notification-configuration/notification-configuration.model';
import { TemplateServiceCommunication } from '../../templates.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType, ButtonTypes, DmConfig } from 'src/app/common/Models/common.models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Product } from 'src/app/common/enums/shared-enums';
import { SorTypeEnum } from 'src/app/common/shared/shared/enums/enums';
import { MatDialog } from '@angular/material/dialog';
import { CkeditorPopupComponent } from 'src/app/common/shared/shared/ckeditor-popup/ckeditor-popup.component';
import { CommonDataAwaiters } from 'src/app/common/shared/events/awaiters/common.data.awaiters';
import juice from 'juice';
import { CrudWhatsappTemplatesBusiness } from './crud-whatsapp-template.component.business';


@Component({
  selector: 'app-crud-whatsapp-template',
  templateUrl: './crud-whatsapp-template.component.html',
  styleUrls: ['./crud-whatsapp-template.component.scss'],
  providers: [CrudWhatsappTemplatesBusiness, TemplateDataServices, EventDataServices, TemplateServiceCommunication],
  encapsulation: ViewEncapsulation.None
})

export class CrudWhatsappTemplateComponent implements OnInit {

  CrudWhatsAppTempForm: UntypedFormGroup;
  crudWhatsapptemplateCaptions: any;
  productId: number;
  dmConfig: DmConfig;
  events: Events[];
  captions: any;
  HeaderLabel: string;
  floatLabel: string;
  Entire_keywords: NotificationKeyword[];
  filteredkeywords: NotificationKeyword[];
  isShowCopy: boolean = false;
  templateCodeMaxlength: number = 12;
  isTemplateInUse: boolean = false;
  Editor = DecoupledEditor;
  currentEditor: any;
  isGroupingenabled = true;
  EnablesaveUpdBtn: boolean = false;
  editableData: TemplateDomainData;
  templatedomaindata: TemplateDomainData;
  SelectedLanguagueID: number = 1;
  saveUpdLabel: string;
  floatLabelNever: string;
  pmsTemplateCodeMaxlength: number = 6;
  isIntakeForm: boolean;
  message: string;
  searchText: string = '';
  ordertype = SorTypeEnum.asc;
  groupBy = 'groupId';
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);

  @Output() emittedWhatsAppdata: EventEmitter<any> = new EventEmitter();
  @ViewChild('ComposeWhatsappTemp') ComposeWhatsappTemp: CKEditorComponent;
  @Input() showTableRecord;
  @Input() editableObj;
  @Input() isViewOnly;
  @Input() isCopyInput;

  Config = {
    toolbar: [
      'bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize',
      'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList',
      'bulletedList', 'todoList', 'outdent', 'indent', 'link', 'insertTable', 'imageUpload', 'undo', 'redo', 'htmlEmbed'
    ],
    fontColor: {
      colors: this.commonUtils.getColors()
    },
    fontBackgroundColor: {
      colors: this.commonUtils.getColors()
    },
    table: {
      tableProperties: {
        borderColors: this.commonUtils.getColors(),
        backgroundColors: this.commonUtils.getColors()
      },
      tableCellProperties: {
        borderColors: this.commonUtils.getColors(),
        backgroundColors: this.commonUtils.getColors()
      }
    },
    fontSize: {
      options: this.commonUtils.getFontSize(),
      supportAllValues: true
    },
    extraPlugins: [], //Base64UploaderPlugin
    placeholder: "Type here",
    toolbarLocation: ['bottom'],
  };

  constructor(private fb: UntypedFormBuilder, public localization: Localization, private _CrudWhatsappTemplatesBusiness: CrudWhatsappTemplatesBusiness,
    private commonUtils: CommonUtilities, public dialog: MatDialog, private templateServiceCommunication: TemplateServiceCommunication,
    private _eventBusiness: EventDataServices) {
    this.crudWhatsapptemplateCaptions = this.localization.captions.settings.utilities;
    this.Config.placeholder = this.crudWhatsapptemplateCaptions.typehere;
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  async ngOnInit() {
    this.templateServiceCommunication.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
        }
        else {
          loadingContainer.style.display = 'none';

        }
      }
    });
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.templateCodeMaxlength = this.productId == Product.PMS ? this.pmsTemplateCodeMaxlength : this.templateCodeMaxlength;
    this.templateServiceCommunication.loaderEnable.next(true);
    this.generateFrom();
    this.dmConfig = await this._CrudWhatsappTemplatesBusiness.getDmconfigSession();
    this.isIntakeForm = await CommonDataAwaiters.GetIntakeDetails();
    await this.GetEvents();
    this.isShowCopy = this.isCopyInput?.isCopy;
    if (this.editableObj) {
      this.HeaderLabel = !this.isShowCopy ? this.crudWhatsapptemplateCaptions.editWhatsappTemplate : this.crudWhatsapptemplateCaptions.newWhatsappTemplate;
      this.saveUpdLabel = !this.isShowCopy ? this.crudWhatsapptemplateCaptions.update : this.crudWhatsapptemplateCaptions.save;
      await this.GetTemplateData(this.editableObj.id);
    }
    else {
      this.HeaderLabel = this.crudWhatsapptemplateCaptions.newWhatsappTemplate;
      this.saveUpdLabel = this.crudWhatsapptemplateCaptions.save;
      await this.GetMasterData();
    }
    if (this.isShowCopy) {
      this.message = this.localization.replacePlaceholders(this.crudWhatsapptemplateCaptions.lbl_templatecopyAlertMessage, ['className'],
        [this.isCopyInput.eventName]);
    }
    this.CrudWhatsAppTempForm.statusChanges.subscribe(x => {
      this.EnablesaveUpdBtn = this.CrudWhatsAppTempForm.dirty && this.CrudWhatsAppTempForm.valid;
    });
    if (this.isViewOnly) {
      this.CrudWhatsAppTempForm.disable();
      this.CrudWhatsAppTempForm.controls.isActive.disable();
      this.CrudWhatsAppTempForm.controls.isDefault.disable();
    }    
  }

  async GetEvents() {
    let filterEvents: Events[] = await this._eventBusiness.getAllEventsByProductId(this.productId);
    this.events = filterEvents.filter(o => o.id != EventNotification.GolfNightAuditReminder
      && o.id != EventNotification.SpaNightAuditReminder
      && o.id != EventNotification.RetailNightAuditReminder
      && o.id != EventNotification.GolfNightAuditCompletion
      && o.id != EventNotification.SpaNightAuditCompletion
      && o.id != EventNotification.RetailNightAuditCompletion
      && o.id != EventNotification.GolfNightAuditFailure
      && o.id != EventNotification.SpaNightAuditFailure
      && o.id != EventNotification.RetailNightAuditFailure
      && o.id != EventNotification.GolfGuestDataRequest
      && o.id != EventNotification.SPAGuestDataRequest
      && o.id != EventNotification.GuestLetter
      && o.id != EventNotification.StayLetter
      && o.id != EventNotification.FolioStatement
      && o.id != EventNotification.GuestStayItinerary
      && o.id != EventNotification.JobScheduler);
    this.events = !this.isIntakeForm ? this.events.filter(event => event.id != EventNotification.IntakeForm) : this.events;
  }

  async GetMasterData() {
    let listOrder = await this._CrudWhatsappTemplatesBusiness.getMaxListOrder(NotificationType.WHATSAPP);
    if (this.events && this.events.length > 0) {
      let eventarray: number[] = [];
      if (this.editableData != null) {
        eventarray.push(this.events[0].id);
      }
      await this.getTemplateKeywords(eventarray);
      this.CrudWhatsAppTempForm.controls.eventId.setValue(eventarray);
      this.CrudWhatsAppTempForm.controls.listOrder.setValue(listOrder + 1);
    }
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  async GetTemplateData(id: number) {
    this.editableData = await this._CrudWhatsappTemplatesBusiness.getTemplateDetailsById(id);
    await this.getTemplateKeywords(this.editableData.eventId);
    await this.patchValues();
    this.templateServiceCommunication.loaderEnable.next(false);
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    this.currentEditor = this.ComposeWhatsappTemp;
  }

  elementClick(arg) {
    const appendData = "{{{" + arg + "}}}";
    const selection = this.currentEditor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    this.currentEditor.editorInstance.model.change(writer => {
      writer.insert(appendData, range.start);
    });
  }

  onChange(currentEditorInstance) {
    this.currentEditor = currentEditorInstance;
  }

  generateFrom() {
    this.CrudWhatsAppTempForm = this.fb.group({
      eventId: ['', Validators.required],
      templateCode: ['', Validators.required],
      templateName: ['', Validators.required],
      listOrder: ['', Validators.required],
      htmlContent: '',
      isActive: true,
      isDefault: false,
      templateId: null,
      notificationtemplateId: 0
    });
  }

  async patchValues() {
    let templatedata = this.editableData.templateData;
    let data = {
      eventId: this.editableData.eventId,
      templateCode: templatedata.templateCode,
      templateName: templatedata.templateName,
      listOrder: templatedata.listOrder,
      htmlContent: templatedata.htmlContent,
      isActive: templatedata.isActive,
      isDefault: templatedata.isDefault,
      templateId: templatedata.templateId,
      notificationtemplateId: templatedata.notificationTemplateId
    }
    this.isTemplateInUse = templatedata.isTemplateInUse;
    if (this.isShowCopy) {
      let nextlistOrder = await this._CrudWhatsappTemplatesBusiness.getMaxListOrder(NotificationType.WHATSAPP);
      data.listOrder = nextlistOrder + 1;
      data.templateCode = '';
      data.notificationtemplateId = 0;
    }
    this.CrudWhatsAppTempForm.patchValue(data);
  }

  enteredSrchText() {
    let val = this.searchText.toLowerCase();
    this.filteredkeywords = this.Entire_keywords.filter(x => {
      return (x.name.toLowerCase().indexOf(val) != -1);
    })
  }

  actionChanged(eve) {
    let actionSelec = eve.value;
    this.getTemplateKeywords(actionSelec);
  }

  async showConfirmationSuccessCallback(eve) {
    await this.SaveTemplate();
  }

  async showConfirmationErrorCallback(eve) {
    this.CrudWhatsAppTempForm.value["isDefault"] = false;
    await this.SaveTemplate();
  }

  async getTemplateKeywords(selecAct) {
    this.Entire_keywords = cloneDeep(await this._CrudWhatsappTemplatesBusiness.getNotificationKeywords(selecAct, this.productId));
    this.filteredkeywords = this.Entire_keywords;
    if (!this.dmConfig?.dmEformsConfig?.enableEforms) {
      this.events = this.events.filter(event => event.id != 75);
      this.filteredkeywords = this.Entire_keywords.filter(keyword => keyword.groupId != 15);
      this.Entire_keywords = this.filteredkeywords;
    }
  }

  promptForUnSavedChanges() {
    if (this.showTableRecord) {
      if (this.CrudWhatsAppTempForm.dirty) {
        this.commonUtils.showCommonAlert(this.crudWhatsapptemplateCaptions.warn_datalost, AlertType.Warning, ButtonTypes.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this.emittedWhatsAppdata.emit({ closeCrudWindow: true });
          }
          else {
            return;
          }
        });
      }
      else {
        this.emittedWhatsAppdata.emit({ closeCrudWindow: true });
      }
    }
    else {
      this.emittedWhatsAppdata.emit({ closeCrudWindow: true });
    }
  }

  async save() {
    const cleanData = this.commonUtils.sanitizeHTMLTemplate(this.CrudWhatsAppTempForm.get('htmlContent')?.value);
    this.CrudWhatsAppTempForm.get('htmlContent').setValue(cleanData);
    let isDefault = this.CrudWhatsAppTempForm.value["isDefault"];
    if (isDefault) {
      this.templateServiceCommunication.loaderEnable.next(true);
      let result = await this._CrudWhatsappTemplatesBusiness.DefaultTemplateExists(this.CrudWhatsAppTempForm.value["eventId"], this.CrudWhatsAppTempForm.value["notificationtemplateId"], NotificationType.WHATSAPP);
      if (result) {
        this.templateServiceCommunication.loaderEnable.next(false);
        this.templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this), this.showConfirmationErrorCallback.bind(this));
      } else {
        this.templateServiceCommunication.loaderEnable.next(false);
        await this.SaveTemplate();
      }
    }
    else {
      await this.SaveTemplate();
    }
  }

  async SaveTemplate() {
    try {
      this.templateServiceCommunication.loaderEnable.next(true);
      this.EnablesaveUpdBtn = false;
      this.templatedomaindata = undefined;
      this.UpdateTemplateDataObject(this.CrudWhatsAppTempForm.value);
      this.CrudWhatsAppTempForm.markAsPristine();
      if (this.templatedomaindata) {
        let id = await this._CrudWhatsappTemplatesBusiness.CreateorUpdateTemplate(this.templatedomaindata);
        this.GetTemplateData(id);
      }
      this.templateServiceCommunication.loaderEnable.next(false);
      this.promptForUnSavedChanges();
    } catch (error) {
      this.templateServiceCommunication.loaderEnable.next(false);
    }
  }

  UpdateTemplateDataObject(formData: UntypedFormGroup) {
    this.AddTemplateDataIfDirty(formData);
  }

  AddTemplateDataIfDirty(formData: UntypedFormGroup) {
    if (this.CrudWhatsAppTempForm.dirty) {
      const templateCode = formData["templateCode"];
      const templateNameValue = formData["templateName"];
      const templateContentValue = formData["htmlContent"];
      let templateid = ""
      if (this.editableData?.templateData?.notificationTemplateContents != null && this.editableData?.templateData?.notificationTemplateContents.length > 0) {
        let notificationcontent = this.editableData.templateData.notificationTemplateContents.find(x => x.languageId == this.SelectedLanguagueID);
        if (notificationcontent) templateid = notificationcontent.templateId.toString();
      }
      const templatecontent: NotificationTemplateContents = {
        languageId: this.SelectedLanguagueID,
        notificationTemplateId: formData["notificationtemplateId"],
        templateId: templateid ? templateid : formData["templateId"],
      }
      const notificationdata: NotificationTemplateContents[] = [];
      notificationdata.push(templatecontent);
      let modifiedHTMLData = juice.inlineContent(this.commonUtils.getCkeditorImgWidth(templateContentValue), this.commonUtils.getCssStringForCkEditor());
      const templateData: TemplateData = {
        templateName: templateNameValue,
        templateCode: templateCode,
        templateType: NotificationType.WHATSAPP,
        isDefault: formData["isDefault"],
        templateAsset: [],
        htmlContent: modifiedHTMLData, //.replace(/<[^>]+>/g, '').replaceAll('&nbsp;', ' '), // trim extra spaces
        templateId: formData["templateId"],
        isActive: formData["isActive"],
        listOrder: formData["listOrder"],
        notificationTemplateId: formData["notificationtemplateId"],
        isTemplateInUse: false,
        notificationTemplateContents: notificationdata
      };
      let domainData: TemplateDomainData = {
        eventId: formData["eventId"],
        templateData: templateData
      }
      this.templatedomaindata = domainData;
    }
  }

  openClose(e, obj) {
    this.filteredkeywords.forEach((x, i) => {
      if (obj.groupId == x.groupId) {
        x.isExpanded = !x.isExpanded
      }
    })
  }

  enteredgrpSrchText() {
    let searchValue = this.searchText.toLowerCase();
    let tempArray = [];
    this.Entire_keywords.forEach(searchKey => {
      const result = searchKey.name.toLowerCase().indexOf(searchValue) > -1
      if (result) {
        if (searchValue == "") {
          searchKey.isExpanded = false;
        } else {
          searchKey.isExpanded = true;
        }
        tempArray.push(searchKey);
      }
    })
    this.filteredkeywords = tempArray;
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    if (!this.showTableRecord) {
      this.promptForUnSavedChanges();
    }
  }

  showHtmlContent() {
    const cleanData = this.commonUtils.sanitizeHTMLTemplate(this.CrudWhatsAppTempForm.get('htmlContent')?.value);
    this.CrudWhatsAppTempForm.get('htmlContent').setValue(cleanData);
    let modifyHTML = juice.inlineContent(this.commonUtils.getCkeditorImgWidth(this.CrudWhatsAppTempForm.get('htmlContent')?.value), this.commonUtils.getCssStringForCkEditor())
    const dialogRef = this.dialog.open(CkeditorPopupComponent, {
      height: '90%',
      width: '90%',
      data: { headername: this.captions.composed, datarecord: modifyHTML, Type: "HTML" },
      panelClass: 'ag_dialog--lg',
      disableClose: true,
      hasBackdrop: true
    });
  }

}
