import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { StateService } from 'src/app/eatecui/source/shared/services/state.service';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';

export declare type FileUploadConfig = (
FileEvent: Array<any>, httpService: HttpService, FileDetails: DetailsPayload, FileDataCollection: Array<UploadMetaData>, 
toasterService?: ToastrService, DMEnabled?: boolean,
 MaximumSize ?: number, BlockLength ?: number, masterPictureDetails?: any[]) => Promise<any>;
export declare type FilePostConfig = ( UploadMetaData: Array<UploadMetaData>, httpService: HttpService, 
  toastrService ?: ToastrService, masterService?: MasterManagementService) => void;
export declare type FilevideoView = ( videoElement: any, httpService: HttpService, stateService: StateService, envService: EnvService,
  fileDetails: any, matDialog: any) => void;
export declare type FileViewConfig = (ViewResponse: Array<FileViewData>, FileDetails: DetailsPayload, httpService: HttpService,
  sanitizer: DomSanitizer) => any;
export declare type GenerateThumbNail = (AssetUrl: string) => any;
export interface UploadMetaData {
    FileName: string;
    BlocksCount: number;
    FileSize: number;
    ContentType: string;
    GroupId: number;
    SortOrder: number;
    IsFavourite: boolean;
    IsFavorite?: boolean;
    ItemId: number;
    FileData: any;
    FileDetails: any;
    CloudFileName: string;
    IsMetaCreated: boolean;
    IsTransaction?: boolean;
    Picture?: any;
}
/***
 * InterFace Added Seperate Interface for metaupload
 */
export  interface UploadFileMetaData {
    fileName: string;
    blocksCount: number;
    fileSize: number;
    contentType: string;
    groupId: number;
    itemId: number;
    sortOrder: number;
    isFavourite: boolean;
    cloudFileName: string;
    isMetaCreated: boolean;
    isTransaction: boolean;
    fileDetails:string;
}
export interface FileViewData {
  Id: number;
  InventoryGroupId: number;
  InventoryId: number;
  Picture: any;
  FilePath: string;
  FileName: string;
  SortOrder: number;
  IsActive: boolean;
  URL: string;
  IsFavorite: boolean;
  InventoryGroups: any;
  CreatedBy: string;
  CreatedDate: string;
  UpdatedBy: string;
  UpdatedDate: string;
}

export interface DetailsPayload {
  ItemId: number;
  GroupId: number;
  EndpointUrl?: any;
  EndPoint?: any;
  IsTransaction?: boolean;
  IsFromMaster?: boolean;
  IsFromDataImport?: boolean;
}

export interface FileUploadData {
    fileName: string;
    CloudFileName: string;
    IsMetaCreated: boolean;
    blockId: any;
}

export interface ThumbViewModel {
  FilePath: string;
  Name: string;
  Picture?: any;
  FileDetails: any;
  TotalCount?: any;
}

export interface FileDataPicture {
  Picture: any;
  Name: string;
  Url: string;
  IsFavorite: any;
  SortOrder: any;
  Type: string;
  FileData?: any;
  FileDetails?: any;
}

export enum FileData {
    BlockLength = 1048576
}

export interface FileDetails {
  FileName: string;
  FilePath: string;
  InventoryGroupId: number;
  InventoryId: number;
  Picture: any;
  Size: number;
  ContentType: string;
  SortOrder: number;
  IsFavorite: boolean;
  Id: number;
  LocalUrl?: any;
}
