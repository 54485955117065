import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GetCellEditorInstancesParams } from 'ag-grid-community/dist/lib/gridApi';

@Component({
  selector: 'lib-units-dropdown',
  templateUrl: './units-dropdown.component.html',
  styleUrls: ['./units-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnitsDropdownComponent implements OnInit {

  params: any;
  value: any;
  defaultValue: number;
  dropdown: FormControl = new FormControl('');
  dropdownOptions: any;
  previousUnit: number;
  prevUnitTypeId: number;

  constructor() { }

  ngOnInit(): void {
  }
  agInit(params) {
    console.log('Dropdown Editor', params);
    this.defaultValue = params.data['UnitTypeId'];
    const dropdownField = 'Units';
    this.params = params;
    this.dropdown.setValue(this.defaultValue);
    this.dropdownOptions = this.params.data[dropdownField];
  }

  init(params) {
    this.dropdown.valueChanges.subscribe(res => {
      this.previousUnit = this.dropdown.value;
      this.defaultValue = parseInt(res, 10);
      const rowNode = this.params.api.getRowNode(this.params.rowIndex);
      rowNode.setDataValue('UnitTypeId', this.defaultValue);
    });
  }

  dropdownChangeEvent = (event) => {
    console.log('Dropdown Change Event', event);
    this.previousUnit = this.params.data.UnitId;
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    this.prevUnitTypeId = this.defaultValue;
    this.defaultValue = parseInt(event.target.value, 10);
    let quantity = parseFloat(this.params.data.Qty.toString() === '' ? 0 : this.params.data.Qty.toString().split(',').join(''));
    if (this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue).length > 0) {
      const Data = this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue)[0];
      this.params.data.UnitCode = Data.UnitName + ` (${Data.UnitCode})`;
      this.params.data.UnitId = Data.UnitId;
      this.params.data.UnitTypeId = this.defaultValue;
      this.params.data.UnitCost = Data.UnitTypeId > 4 ? Data.CostPerUnit : Data.UnitCost;
      let prevUnit, nextUnit;
      prevUnit = this.dropdownOptions.filter(x => x.UnitTypeId === this.prevUnitTypeId);
      nextUnit = this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue);
      const prevPackSize = prevUnit[0].UnitTypeId === 4 ? (1 / prevUnit[0].Conversion) :
            (prevUnit[0].Conversion ? prevUnit[0].Conversion : parseFloat(this.params.data.Conversion.toString()));
      // const nextPackSize = nextUnit[0].UnitTypeId === 4 ? (1 / nextUnit[0].Conversion) : nextUnit[0].Conversion;
      const nextPackSize = nextUnit[0].Conversion;
      // quantity = (prevPackSize > 0 && quantity > 0) ? ((prevPackSize * quantity) / nextPackSize) : 0;
      const NewConversionValue = parseFloat(nextPackSize);
      // const NewConversion = NewConversionValue * parseFloat(this.params.data.Conversion.toString());
      const Conversiondata: GetCellEditorInstancesParams = {
        rowNodes: [rowNode],
        columns: ['Conversion'],
      };
      const Conversioninstances = this.params.api.getCellEditorInstances(Conversiondata);
      if (Conversioninstances.length > 0) {
        Conversioninstances[0].params.data.Valued = NewConversionValue;
        Conversioninstances[0].value = NewConversionValue;
        Conversioninstances[0].params.value = NewConversionValue;
        Conversioninstances[0].input.value = NewConversionValue;
      }
      this.params.data.Conversion = NewConversionValue;

      // const data: GetCellEditorInstancesParams = {
      //   rowNodes: [rowNode], // specify rows, or all rows by default
      //   columns: ['Qty'], // specify columns, or all columns by default
      // };
      // const instances = this.params.api.getCellEditorInstances(data);
      // if (instances.length > 0) {
      //   instances[0].params.data.Qty = parseFloat((quantity).toString()).toString();
      //   instances[0].value = (quantity).toString();
      //   instances[0].params.value = (quantity).toString();
      //   instances[0].input.value = (quantity).toString();
      // }
    }
    rowNode.setDataValue('Price', parseFloat((quantity * this.params.data.UnitCost).toString()));
    rowNode.setDataValue('UnitTypeId', this.defaultValue);
    rowNode.setDataValue('UnitCode', this.params.data.UnitCode);
    rowNode.setDataValue('UnitId', this.params.data.UnitId);
    rowNode.setDataValue('Conversion', this.params.data.Conversion);
    // if (this.params.hasOwnProperty('colDef') && this.params.colDef.valueFormatter) {
    //   this.params.colDef.valueFormatter(this.params, this.previousUnit, this.params.data.UnitId);
    // } else {
    //   const ColumnInfo = this.params.api.columnController.columnDefs.filter(x => x.field === this.params.colDef.field)[0];
    //   ColumnInfo.valueFormatter(this.params, this.previousUnit, this.params.data.UnitId);
    // }
  }

  getValue(): any {
    if (this.dropdownOptions && this.dropdownOptions.length > 0 &&
      this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue).length > 0) {
      const returnValue = this.dropdownOptions.filter(x => x.UnitTypeId === this.defaultValue)[0];
      return returnValue.UnitName + ` (${returnValue.UnitCode})`;
    }
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

}
