import { Component, OnInit } from '@angular/core';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertMessagePopupComponent } from '../../../shared/alert-message-popup/alert-message-popup.component';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import * as _ from 'lodash';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { Host } from '../../../shared/globalsContant';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';

@Component({
  selector: 'app-copy-roles',
  templateUrl: './copy-roles.component.html',
  styleUrls: ['./copy-roles.component.scss']
})
export class CopyRolesComponent implements OnInit {
  captions: any = this.localization.captions.userConfig;
  sampleOptionsTemp: any;
  secondOptionsSet: any;
  sampleOptions: any = [{ "id": 1, "name": "System Administrator" }, { "id": 2, "name": "Advanced User" }];
  roleGrp: UntypedFormGroup;
  floatLabel:string;

  constructor(private http: HttpServiceCall, public localization: SpaLocalization,
    private fb: UntypedFormBuilder, private _viewSetting: ViewSettingClientBusiness,
    private dialogRef: MatDialogRef<SettingDialogPopupComponent>, private dialog: MatDialog, private utils: SpaUtilities) {
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.roleGrp = this.fb.group({
      copyFrom: ['', Validators.required],
      copyTo: ['', Validators.required]
    });
    this._viewSetting.activeFormGroup = this.roleGrp;
    this.roleGrp.controls.copyTo.disable();
    this.getUserRoles();
  }

  Cancel() {
    this.dialogRef.close();
  }

  getUserRoles() {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetActiveUserRole",
      method: HttpMethod.Get,
      uriParams: { tenantId: Number(this.utils.GetPropertyInfo('TenantId')) , includeInActive : false },
      showError: true,
      extraParams: [false]
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetActiveUserRole") {
      this.sampleOptions = result.result ? result.result : [];
      this.sampleOptions = this.sampleOptions.filter(x=> x.propertyId  === Number(this.utils.GetPropertyInfo('PropertyId'))
      && x.productId.find(t=> t === Number(this.utils.GetPropertyInfo('ProductId'))));
      this.sampleOptionsTemp = _.cloneDeep(this.sampleOptions);
    }
    else if (callDesc == "CopyUserRoles") {
      this.dialogRef.close();
      this.dialog.open(AlertMessagePopupComponent, {
        width: '305px',
        height: '300px',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data: { headername: this.captions.wellDone, headerIcon: 'icon-success-icon', headerMessage: this.captions.configSuccessFrom + " " + this.roleGrp.controls.copyFrom.value + " " + this.captions.configSuccessTo + " " + this.roleGrp.controls.copyTo.value, buttonName: this.captions.okay, type: 'message' },
        disableClose: true
      });
    }
  }

  CopyRoles(from: number, to: number) {
    this.http.CallApiWithCallback<any>({
      host: Host.authentication,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "CopyUserRoles",
      method: HttpMethod.Post,
      uriParams: { from: from, to: to },
      showError: true,
      extraParams: [false]
    });
  }

  SaveCopyRoles() {
    let from: number = this.sampleOptions.find(x => x.description == this.roleGrp.value.copyFrom).id;
    let to: number = this.sampleOptions.find(x => x.description == this.roleGrp.value.copyTo).id;
    this.CopyRoles(from, to);
  }

  configChanged(event) {
    this.secondOptionsSet = _.reject(this.sampleOptionsTemp, ['description', event.value]);
    this.roleGrp.controls.copyTo.enable();
  }
}
