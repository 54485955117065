import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'ImageSafe'})
export class ImageSafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    public transform(url: any) {
        const imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return imageUrl['changingThisBreaksApplicationSecurity'].changingThisBreaksApplicationSecurity;
    }
}