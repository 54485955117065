import { Host, Product } from '../../shared/globalsContant';



// TO DO Need to remove( dependency in translog report )
export interface ReportOptions {
  code: string;
  params: ReportParams[];
  URIParams: ReportParams[];
  Filter: ReportFilterParams[];
  pageBreak: boolean;
  layout: string;
  language: string;
}

export class ReportAPIOptions {
  code: string;
  format?: ReportDownloadFormat = 'HTML';
  params: ReportParams[];
  URIParams: ReportParams[];
  filters: any;
  pageBreak: boolean;
  downloadFileName? : string;
}

/**
 * API Model for Report microservice request PUT call.
 */
export interface ReportAPIModel {
  code: string;
  format: ReportDownloadFormat;
  downloadFileName: string;
  parameters: { [key: string]: string };  //Dictionary<string,string>
  uRIParams: { [key: string]: string };   //Dictionary<string,string>
  filterBody: any;
  dateFormat: string;
}

export type ReportDownloadFormat = 'PDF' | 'WORD' | 'EXCEL' | 'IMAGE' | 'HTML' | 'TEXT' | 'RAWDATA';


/**
 * Report UI Control Configuration Model
 */
export interface ReportUIConfig {
  dropDownFilterName?: string;
  startDatePicker: boolean;
  endDatePicker: boolean;
  dropDownFilters?: Promise<DropDownData[]>;  
  inActiveToggle: boolean;
  inActiveToggleName?: '';
  pageBreakToggle: boolean;
  timePicker?: boolean;
  layout: 'PORTRAIT' | 'LANDSCAPE';
  allowFutureDate?: boolean;
  restrictPastDate?:boolean;
  showHelperText?:boolean;
  includeHourlyRate?: boolean;
  hideCorrectedValues?: boolean;
  includeInhouseGratuitySC?: Promise<boolean>;
  includeInhouseGratuity?: Promise<boolean>;
  includeInhouseSC?: Promise<boolean>;
}

export const enum SPAReports {
  report1 = 'AppointmentListingByLocation',
  report2 = 2,
  report3 = 5
}

export enum ReportTab {
  Appointment = 0,
  Retail = 1,
  Client = 2,
  CommissionGratuityServiceCharge = 3,
  TransactionLog = 4
}
export enum ItemType {
  RetailItemRetailPOSOnly = "Retail Item",
  RentalItem = "Rental Item"
}

export enum AllReports {
  AppointmentListingByLocation = 'AppointmentByLocation',
  AppointmentListing = 'AppointmentListing',
  AppointmentByStaffMember = 'AppointmentByStaffMember',
  AppointmentByLinkCode = 'AppointmentByLinkCode',
  Itinerary = 'Itinerary',
  CommissionReport = 'CommissionReport',
  Gratuity = 'Gratuity',
  CustomField1 = 'CustomField1',
  CustomField2 = 'CustomField2',
  CustomField3 = 'CustomField3',
  CustomField4 = 'CustomField4',
  CustomField5 = 'CustomField5',
  Cancellation = 'Cancellation',
  NoShowAppointment = 'NoShowAppointment',
  ServiceCharge = 'ServiceCharge',
  StaffMemberSummary = 'StaffMemberSummary',
  Surcharges = 'Surcharges',
  MultiPackItems = 'MultiPackItems',
  ReturnedItems = 'ReturnedItems',
  InventorySummary = 'InventorySummary',
  InventoryOnHand= 'InventoryOnHand',
  InventoryDetails = 'InventoryDetails',
  InventoryTransfer = 'InventoryTransfer',
  InventoryWash = 'InventoryWash',
  InventoryAudit = 'InventoryAudit',
  SalesByDiscountTypes = 'SalesByDiscountTypes',
  SalesBySubCategory = 'SalesBySubCategory',
  SalesByCategory = 'SalesByCategory',
  SaleByItem = 'SaleByItem',
  SalesByVendor= 'SalesByVendor',
  CorrectVoid = 'CorrectVoid',
  Shift = 'Shift',
  RevenueSummary = 'RevenueSummary',
  TopSpenders = 'TopSpenders',
  Transaction = 'Transaction',
  TransactionSNC = 'TransactionSNC',
  Receipt = 'Receipt',
  GiftCardRedeem = 'GiftCardsRedeem',
  GiftCardIssued = 'GiftCardsIssued',
  TransactionDetail = "TransactionDetail",
  TaxExemptSales = "TaxExemptSales",
  CaddyShack = "CaddyShack",
  Accrual= "Accrual",
  SalesByDiscountTypesSummary = 'SalesByDiscountTypesSummary',
  SalesMix= "SalesMix",
  InventoryReport = 'InventoryReport',
  InventoryPickupDelivery = 'InventoryPickupDelivery',
  ItemsExtract = 'ItemsExtract',
  InventoryDetailVendor ='InventoryDetailVendor',
  InventorySummaryByVendor = 'InventorySummaryVendor',
  TransactionExtract = 'TransactionExtract',
  PurchaseOrders = 'PurchaseOrders',
  Receiving = 'Receiving',
  InventoryVariance='InventoryVariance',
  InventoryValuation = 'InventoryValuation',
  InventoryValueAndActivity = 'InventoryValueAndActivity',
  InventoryAvailability = 'InventoryAvailabilitySummary'
}

export interface ReportParams {
  [parameter: string]: string | number | boolean;
}

export interface ReportFilterParams {
  [Filter: string]: number[];
}

export interface ReportDropDown {
  id: number | string;
  viewValue: string;
  callDesc: string;
  IsAuthorized: boolean;
  breakPointNumber?: number;
}

export interface HTMLDomElement extends Element {
  name?: string;
}

export interface ReportControls {
  code: string;
  reportConfig: ReportControlConfig;
  params?: ReportOptions;
}


export interface ReportControlConfig {
  startDatePicker: boolean;
  endDatePicker: boolean; 
  dropDownFilters?: ReportFilter[];
  inActiveToggle: boolean;
  inActiveToggleName?: string;
  pageBreakToggle: boolean;
  layout: 'Portrait' | 'Landscape';
}

export interface ReportFilter {
  callDesc: string;
  name: string;
  host: Host;
}

export interface MultiPackItemFilterData {
  StartDate: string;
  EndDate: string;
  MultiPackIds: number[];
  IsAllMultiPacks: boolean;
  IsExpirationDateRange: boolean;
  IsSoldDate: boolean;
  IsAllByGuests: boolean;
  IsOutstandingOnly: boolean;
}

export interface CorrectVoidFilterData {
  StartDate: string;
  EndDate: string;
  OutletIds: number[];
  UserIds: number[];  
}

export interface TransactionFilterData {
  StartDate: string;
  EndDate: string;
  OutletIds: number[];
  UserIds: number[];
  MachineNameId: number[];
}

export interface TransactionDetailFilterData {
  StartDate: string;
  EndDate: string;
  PaymentMethodIds: number[];
  MachineNameId: number[];
  IncludeVoidedTransaction: boolean;
}

export interface AccrualFilterData {
  StartDate: string;
  EndDate: string;
  OutletIds: number[];
  UserIds: number[];
  PatronId: number;   
}

export interface GiftCardRedeemFilterData {
  StartDate: string;
  EndDate: string;
  OutletIds: number[];
  UserIds: number[];
  CardNumber: string;
}

export interface RevenueSummaryFilterData {
  StartDate: string;
  EndDate: string;
  OutletIds: number[];
  UserIds: number[];
}

export interface DropDownData {
  id: number;
  code?: string;
  description: string;
  isActive?: boolean;
  showInDropDown?: boolean; 
  staffType?: string
}

export interface StaffFilter {
  id: number;
  code?: string;
  description: string;
  isActive?: boolean;
  showInDropDown?: boolean;
  staffType?: string
}

export interface StaffReportRequest{
  fromWildCard? :number;
  fromIncrement?:number;
  toWildCard?:number;
  toIncrement?:number;
  staffFilter: StaffFilterData[];
} 

export interface StaffSummaryFilter {
  includeZeroValue: boolean;
  staffs: StaffFilterData[];
}

export interface StaffFilterData {
  staffId: number;
  staffType?: string
}

export interface ReportSelector {
  id?: number;
  group: string;
  code: string;
  value: string;
  breakPointNumber: number;
  IsAuthorized: boolean;
  allowedProducts?: Product[];
  enableJasperReports?:boolean;
  reportUrlpath?:string;
}
export class JasperReportDetails{
  reportCode : string;
  isJaspersoftReport: boolean;
  reportUrlPath: string;
  reportUrlParameters? : string;
}

export interface AllCustomFields {
  id: number;
  columnName: string;
  fieldName: string;
  moveRecordFromClient: boolean;
  requiredOnAppointment: boolean;
  displayOn: number;
  customFieldValues: any[];
  propertyId: number;
  subPropertyId: number;
}

export interface TherapistData {
  code: string;
  firstName: string;
  lastName: string;
  id: number;
  isActive: boolean;
  allowCommission: boolean;
  allowServiceCharge: boolean;
  allowGratuity: boolean;

}



export interface CustomFields {
  id: number;
  columnName: string;
  fieldName: string;
  moveRecordFromClient: boolean;
  requiredOnAppointment: boolean;
  displayOn: number;
  customFieldValues: CustomFieldValue[];
  propertyId: number;
  subPropertyId: number;

}

export interface CustomFieldValue {
  id: number;
  customFieldId: number;
  code: string;
  description: string;
  isUtilized: boolean;
  propertyId: number;
  subPropertyId: number;
  showInDropDown: boolean;
}

export interface ClientDetailForReport {
  clientDetail: ClientDetail;
  id: number;
  requestUid: string;
}

export interface ClientDetail {
  id: number;
  guestId: string;
  title: string;
  firstName: string;
  lastName: string;
  loyaltyDetail: Array<LoyaltyDetail>;
}

export interface LoyaltyDetail{
  patronId:string;
  rank:string;
}

export interface clientAutoComplete {
  name: string;
  id: number;
}



export interface LinkCodes {
  id: number;
  code: string;
  description: string;
  listOrder: number;
  color: string;
  startDate: string;
  endDate: string;
  status: string;
  propertyId: number;
  subPropertyId: number;
}

export interface Users {
  tenantId: 1;
  userId: 1;
  userName: string;
  firstName: string;
  lastName: string;
  password: null;
  isActive: boolean;
}

export interface TranslogType {
  id: number;
  code: string;
  description: string;
  logtype: string;
}

export interface InventoryDetailFilterData {
  categoryIds: number[];
  subCategory1Ids: number[];
  subCategory2Ids: number[];
  subCategory3Ids: number[];
  subCategory4Ids: number[];
  subCategory5Ids: number[];
}
export interface InventoryReportFilterData {
  categoryIds: number[];
  subCategory1Ids: number[];
  subCategory2Ids: number[];
  subCategory3Ids: number[];
  subCategory4Ids: number[];
  subCategory5Ids: number[];
  reporttype: number;
  startDate: string;
  endDate: string;
}

export interface InventoryPickupDeliveryFilterData {
  CategoryIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  subCategory4Ids: number[];
  SubCategory5Ids: number[];
  reporttype: number; 
}

export interface InventoryTransferFilterData {
  CategoryIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  subCategory4Ids: number[];
  SubCategory5Ids: number[];
  OutletIds: number[]
  Type: number[]
}


export interface MultiPackTypes {
  id: number;
  itemDescription: string;
}

export interface InventoryAuditFilter {
  startDate?: string;
  endDate?: string;
  outlets: number[];
  category: number[];
  subCategory1: number[];
  subCategory2: number[];
  subCategory3: number[];
  subCategory4: number[];
  subCategory5: number[];
  includeinactiveitems: boolean;
  includenoninventoryitems: boolean;
  searchbyitem: string;
  searchbydesc: string;
  searchbysupplier: string;
  searchbybarcode: string;
  searchbyotherscannercode: string;
  searchbysupplieritem: number;
  purchaseorder: number;
  showOutlet: boolean;
}

export interface InventoryAvailabilityFilter{
  startDate: string;
  endDate: string;
  categoryIds: number[];
  itemType: number[];
  isDetailedReport: boolean
}
export interface OutletAndCategoryFilter {
  startDate?: string;
  endDate?: string;
  OutletIds: number[];
  CategoryIds: number[];
  MachineNameIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  SubCategory4Ids: number[];
  SubCategory5Ids: number[];
  DiscountType: number[];
  SearchbyItem: number[];
  ClerkIds: number[];
  SelectedItemIds: number[];
  FilterItems: boolean;
  ReportCode: string;
  CategoryGroupIds: number[];
  Spenders: number;
  Itemfilter: number;
  ReportGroupId?: number[];
  VendorIds?: number[];
   isVendor?: boolean;
  Location?: string[];
  AsofDate?:string;
  Supplier?:string[];
  PONumber?:string[];
}

export interface ItemsExtractFilter {
  startDate?: string;
  endDate?: string;
  OutletIds: number[];
  CategoryIds: number[];
  MachineNameIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  SubCategory4Ids: number[];
  SubCategory5Ids: number[];
  ClerkIds: number[];
  ReportCode: string;
  CategoryGroupIds: number[];
  PaymentMethodIds: number[];
}

export interface InventoryWashFilterData {
  CategoryIds: number[];
  SubCategory1Ids: number[];
  SubCategory2Ids: number[];
  SubCategory3Ids: number[];
  subCategory4Ids: number[];
  SubCategory5Ids: number[];
  OutletIds: number[]
}

export interface PaymentMethodDetail {
  id: number;
  paymentTypeId: number;
  paymentMethod: string;
  isActive: boolean;
  isDefault: boolean;
}
export interface TransactionExtractFilter{
  StartDate: string;
  EndDate: string;
  IsConsolidatedData?: boolean;
}
export interface InventoryValue {
  startDate: string;
  endDate: string;
  ReportCode: string;
  locations: string[];
  AsofDate:string;
  site:string;
}
export interface InventoryValuesandActivity {
  startDate: string;
  endDate: string;
  types:string[];
  subtypes?:string[];
  ReportCode: string;
  locations: string[];
  AsofDate:string;
  site:string;
}
