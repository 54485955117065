import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CreditCardConfigurationService } from './credit-card.service';
import { CardInfo, ClientCreditCardInfo, Device, HandleRequest, HandleResponse, IDTech, IDTechHandle, PaymentBaseResponse, PaymentMethods, StoreTokenRequest, TokentransactionInfo } from '../../shared/shared/business/shared.modals';
import { UserSessionConfiguration } from '../../shared/core.model';
import { CardEntryModeDialogAction, CardEntryModeDialogResult, CardInputMethod, GatewayConfiguration, SaleRequest, SaleResponse, ValidateAuthRequest, ValidateAuthResponse, ValidatePayResponse } from '../../Models/payment.model';
import { GatewayConfigurationType } from '../../shared/shared/globalsContant';
import { CommonPaymentBusinessService } from '../../dataservices/payment-business.service';
import { PayAgentCommunication } from '../../dataservices/payagent.communication';
import { AuthInformation, AuthRequest, AuthResponse, HttpResponseStatus, ValidatePayRequest } from 'src/app/common/Models/payment.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonPaymentDataService } from '../../dataservices/payment-data.service';
import { takeUntil } from 'rxjs/operators';
import { CardEntryModeComponent } from './card-entry-mode/card-entry-mode.component';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AgentRequestHandler } from '../capture-card/agent-handler/agent-request-handler';

@Component({
  selector: 'ag-capture-card',
  templateUrl: './capture-card.component.html',
  styleUrls: ['./capture-card.component.scss'],
  providers: [CreditCardConfigurationService, CommonPaymentBusinessService, PayAgentCommunication, CommonPaymentDataService, AgentRequestHandler]
})
export class AgCaptureCardComponent implements OnInit {
  @Input() HideCardHeaderflag: boolean;
  @Input() PaymentReferenceID: number;
  @Input() disableCardCapture = false;
  @Input() terminalId = '0';
  @Input() outletId = '0';
  @Input() isView;
  @Input() displayHeader;
  @Input() saleData: any;
  @Input() authData: any;
  @Input() isShowPMAgentValidationMessage = true;
  @Input() loadGetHandlesWhenInitialize : boolean = true;
  availableDevices: Device[] = [];
  selectedDevice: Device = {
    name: '',
    handle: ''
  };
  cardConnectDialog: MatDialogRef<any, any>;
  IDTechCardSwipePopupClosed: boolean;
  EncryptedCardData: any;
  cardInfo: ClientCreditCardInfo[] = [];
  displayCardInfo: CardInfo;
  CurrentDevice: string;
  authAmount: 0;
  floatLabel: string;
  isManualEntry: boolean = false;
  @Output() NewPaymentReferenceID = new EventEmitter<number>();
  @Output() TransactionID = new EventEmitter<ValidatePayResponse>();
  @Output() CardInfo = new EventEmitter<any>();
  @Output() AuthAmount = new EventEmitter<any>();
  captions: any;
  userSessionConfiguration: UserSessionConfiguration;
  GatewayConfiguration: GatewayConfiguration;
  IsExpireDateVisible: boolean = false; 

  @Output() AuthTransactionID = new EventEmitter<ValidatePayResponse>();
  @Output() removeDetails = new EventEmitter<any>();

  UseRetailInterface = false;
  defaultCardInputMethod: CardInputMethod;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  get DefaultOutletId() {
    let _outletId: string = '0';
    if(!isNaN(+this.outletId) && +this.outletId > 0){
      return _outletId = this.outletId.toString();
    }
    else{
      _outletId = (this.userSessionConfiguration && this.userSessionConfiguration.defaultOutletId > 0) ? this.userSessionConfiguration.defaultOutletId.toString() : '0';
    }
    if(_outletId == '0'){
      _outletId = sessionStorage.getItem('defaultOutlet');
    }
    return _outletId;
  }

  preferredInputSelectedBanner: string = "";
  get showPreferredCardEntryBanner() {
    return (this.GatewayConfiguration
      && this.GatewayConfiguration.cardInputMethod == CardInputMethod.All
      && this.GatewayConfiguration.preferredCardEntryMode > 0
      && this.selectedDevice && this.selectedDevice.name);
  }

  constructor(
    private _paymentService: CommonPaymentBusinessService,
    private utils: CommonUtilities,
    private localization: Localization,
    private cd: ChangeDetectorRef
    , private creditCardConfigurationService: CreditCardConfigurationService,
    public dialog: MatDialog,
    public PropertyInfo: CommonPropertyInformation,
    public agentRequestHandler : AgentRequestHandler
  ) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.GetUserDefaultDeviceConfiguration().then(() => {
      // this.GetDevices(false, true);
    });
    if(!this.GatewayConfiguration)
      this.GetCreditCardConfiguration();

  }
  SetPreferredCardLabel() {
    if (this.GatewayConfiguration && this.GatewayConfiguration.preferredCardEntryMode) {
      const preferredInputMap = {
        [CardInputMethod.KeyIn]: this.localization.captions.setting.keyIn,
        [CardInputMethod.SwipeInsertTap]: this.localization.captions.setting.swipeInsertTap
      }
      this.preferredInputSelectedBanner = this.localization.replacePlaceholders(
        this.localization.captions?.shop.preferredInputModeSelectedBanner ?? '', ['preferredMethod'],
        [preferredInputMap[this.GatewayConfiguration.preferredCardEntryMode]]
      );
    }
  }

  async GetCreditCardConfiguration() {
    const result = await this.creditCardConfigurationService.getCreditCardConfiguration({ type: GatewayConfigurationType.CreditCard });
    this.GatewayConfiguration = result.find(t => t.type == GatewayConfigurationType.CreditCard);
    this.IsExpireDateVisible= this.GatewayConfiguration.showCreditCardExpDate;        
    this.SetPreferredCardLabel();
  }

  async ngOnChanges() {
    if (this.PaymentReferenceID > 0) {
      await this.getCardInfo(this.PaymentReferenceID);
      //this.disableCardCapture = true; // Commented for multiple card payments
    } else {
      //  this.disableCardCapture = false;
      this.displayCardInfo = null;
      this.SelectDevice({
        name: '',
        handle: ''
      });
      this.CurrentDevice = '';
    }
    if (this.terminalId !== '0') {
      this.availableDevices.length = 0;
      if(this.userSessionConfiguration != null) this.GetDevices();
    }
    else if (this.outletId !== '0') {
      this.availableDevices.length = 0;
      if(this.userSessionConfiguration != null) this.GetDevices();
    }
    this.cd.detectChanges();
  }

  async GetDevices(isFromIdTech: boolean = false, isOnLoadCall: boolean = false, isShowPMAgentValidationMessage: boolean = true) {
    if (!this.disableCardCapture) {
      if ((this.availableDevices.length == 0 || isFromIdTech)) {
        const result = [];
        let RequestBody: HandleRequest;
        if (this.selectedDevice.name == IDTechHandle.name) {
          RequestBody = {
            tenderId: PaymentMethods.IDTECH.toString(),
            inquiryInfo: {
              id: '',
              cardData: {
                encryptedData: JSON.stringify({
                  deviceType: 'idtechkb',
                  encryptedData: this.EncryptedCardData
                })
              }
            }
          };
        } else {
          RequestBody = {
            tenderId: PaymentMethods.CreditCard.toString()
          };
        }
        if ((this.userSessionConfiguration.isIdtechSred || this.userSessionConfiguration.defaultPaymentDevice == IDTech.id)
          && !this.availableDevices.includes(IDTechHandle)) {
          this.availableDevices.push(IDTechHandle);
          this.SelectDefaultDevice();
          if (this.userSessionConfiguration.defaultPaymentDevice == IDTech.id && !this.EncryptedCardData)
            return;
        }

        const Handles: Promise<HandleResponse> = this.agentRequestHandler.PaymentProcessor.GetHandles(RequestBody, this.terminalId, this.DefaultOutletId, isShowPMAgentValidationMessage);
        Handles.then(response => {
          if (response && response.status.toLocaleLowerCase() === 'success' && response.paymentHandle.length > 0) {
            if (isFromIdTech && this.selectedDevice.name == IDTechHandle.name && this.EncryptedCardData) {
              this.CreateToken(response.paymentHandle[0].handle, PaymentMethods.IDTECH);
            } else {
              const availableIngenicoDevices = response.paymentHandle;
              availableIngenicoDevices.forEach(device => {
                if (this.availableDevices.filter(x => x.name == device.name).length == 0) {
                  this.availableDevices.push(device);
                }
              });
            }
          }
          if (!isFromIdTech) {
            this.SelectDefaultDevice();
          }
          if (this.availableDevices.length <= 0) {
            this.utils.ShowError(this.localization.captions.common.Error, response.errorMessage);
          }
        }).catch(err => {
          // Show Payment Error Prompt
          if (!isOnLoadCall) {
            if (err.error && (err.error[0] !== null && err.error[0].Code !== null && err.error[0].Code !== '')) {
              this._paymentService.PaymentErrorPrompt(err.error[0].Code);
            } else {
              //return this.utils.showError(this.captions.PayAgentUnavailable);
            }
          } else {
            console.error('Ignoring Capture Card error during initial load', err);
          }
        });
      }
    }

  }

  async GetUserDefaultDeviceConfiguration() {
    this.userSessionConfiguration = await this._paymentService
      .getUserSessionConfiguration(Number(this.localization.GetPropertyInfo('UserId')));
    if(this.loadGetHandlesWhenInitialize) this.GetDevices(false, true, this.isShowPMAgentValidationMessage);
  }

  SelectDefaultDevice() {
    if (this.userSessionConfiguration.defaultPaymentDevice && this.userSessionConfiguration.defaultPaymentDevice.toLowerCase() === IDTech.id) {
      this.SelectDevice(IDTechHandle);
    } else if (this.userSessionConfiguration.defaultDeviceName) {
      const defaultDevice = this.availableDevices.find(x => x.name === this.userSessionConfiguration.defaultDeviceName);
      if (defaultDevice) {
        this.SelectDevice(defaultDevice);
      }
    } else {  // to do Remove once userConfiguration is set
      const defaultDevice = this.availableDevices && this.availableDevices.length > 0 ? this.availableDevices[0] : '';
      if (defaultDevice) {
        this.SelectDevice(defaultDevice);
      }
    }
  }
  SelectDevice(deviceObj: Device) {

    this.selectedDevice = deviceObj;
    this.CurrentDevice = deviceObj?.name;
  }

  formatCreditCardExpiryDate(date: string): string {
    return this._paymentService.formatCreditCardExpiryDate(date);
  }

  async ConnectDevice() {
    if (this.isAdyenWithAllEntryMode()) {
      this.proceedForAdyenGatewayPayment();
    } else {
      this.proceedToCaptureCard();
    }
  }

  async proceedForAdyenGatewayPayment(promptForPreferenceOverride = false) {
    if (this.GatewayConfiguration.cardInputMethod === CardInputMethod.All && this.GatewayConfiguration.preferredCardEntryMode == 0 || promptForPreferenceOverride) {
      const dialogRef = this.openCardEntryModeSelectionPopup();
      dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((result: CardEntryModeDialogResult) => {
        if (result.action === CardEntryModeDialogAction.Proceed) {
          this.defaultCardInputMethod = result.cardInputMethod;
          this.proceedToCaptureCard();
        } else {
          // Card Entry Mode dialog action cancelled
        }
      });
    } else {
      this.defaultCardInputMethod = this.GatewayConfiguration.preferredCardEntryMode;
      this.proceedToCaptureCard();
    }
  }

  openCardEntryModeSelectionPopup() {
    return this.dialog.open(CardEntryModeComponent, {
      width: '550px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.localization.captions.common.Warning,
        headerIcon: 'icon-warning-icon',
        headerMessage: this.localization.captions.shop.CorrectionTransactionMsg,
        buttonName: this.localization.captions.common.Yes,
        noButton: true,
        noButtonName: this.localization.captions.common.No,
        type: 'message'
      },
      disableClose: true
    });
  }

  isAdyenWithAllEntryMode() {
    return this.GatewayConfiguration &&
      this.GatewayConfiguration.payGatewayID &&
      this.GatewayConfiguration.payGatewayID.trim().toLowerCase() === 'adyen' &&
      this.GatewayConfiguration.cardInputMethod === CardInputMethod.All;
  }

  proceedToCaptureCard() {
    let handle = '';
    this.authAmount = this.authData ? this.authData.authAmount : 0;
    let ismanualCardEntry = false;
    if (this.selectedDevice.name !== '') {
      if (this.selectedDevice.name !== IDTechHandle.name && !this.saleData && this.authAmount == 0) {
        this.OpenDialog();
        handle = this.selectedDevice.handle;
        ismanualCardEntry = this.isManualKeyInCardEntryMode()
        this.CreateToken(handle, PaymentMethods.CreditCard, ismanualCardEntry);
      } else if (this.selectedDevice.name !== IDTechHandle.name && this.saleData && this.saleData.saleAmount != 0) {
        this.OpenDialog();
        if (this.saleData.isCreditPayment) {
          this.RequestForCredit(this.saleData.saleAmount);
        } else {
          this.RequestForSale(this.saleData.saleAmount);
        }
      } else if (this.selectedDevice.name !== IDTechHandle.name && this.authData && this.authData.authAmount != 0) {
        this.OpenDialog();
        this.RequestForAuth(this.authData.authAmount);
      }
      else {
        this.CaptureCardWithIDTechDevice();
      }
    }
  }
  isManualKeyInCardEntryMode(): boolean {
    let isManualEntry = false;
    if (this.GatewayConfiguration &&
      this.GatewayConfiguration.payGatewayID &&
      this.GatewayConfiguration.payGatewayID.trim().toLowerCase() === 'adyen') {
      if (this.GatewayConfiguration.cardInputMethod !== CardInputMethod.All) {
        this.defaultCardInputMethod = this.GatewayConfiguration.cardInputMethod;
      }
      isManualEntry = this.defaultCardInputMethod === CardInputMethod.KeyIn;
    }
    this.isManualEntry = isManualEntry;
    return isManualEntry;
  }

  async getCardInfo(tokenRefId: number) {
    const cardInfo = await this._paymentService.GetCardInfo(tokenRefId);
    if (cardInfo) {
      cardInfo.cardNumber = this._paymentService.MaskCreditCardNumber(cardInfo.cardNumber);
      cardInfo.cardExpiration = this.formatCreditCardExpiryDate(cardInfo.cardExpiration);
      this.displayCardInfo = cardInfo;
    }
  }

  CaptureCardWithIDTechDevice() {
    this.IDTechCardSwipePopupClosed = false;
    this.OpenDialog();
    setTimeout(() => {
      if (!this.EncryptedCardData && !this.IDTechCardSwipePopupClosed) {
        this.cardConnectDialog.close();
        this.utils.showError(this.localization.getError(10725));
      }
    }, 45000);
  }

  OpenDialog() {
    const popupMessage = this.isManualKeyInCardEntryMode() ?  this.localization.captions.shop.KeyInCardMessage : this.localization.captions.shop.SwipeCardMessage;
    const isIDTech: boolean = (this.selectedDevice.name === IDTechHandle.name);
    const dataObj = {
      text: popupMessage,
      buttonname: isIDTech ? this.localization.captions.common.Close : '',
      headertext: '',
      isloaderenable: true,
      cardpayment: true,
      isHiddenFieldRequired: isIDTech,
      isManualEntry: this.isManualKeyInCardEntryMode()
    };
    this.cardConnectDialog = this.utils.OpenCardSwipeDialog(dataObj, this.CloseDialog.bind(this));
  }

  CloseDialog(data: any): void {
    this.IDTechCardSwipePopupClosed = true;
    if (data && data.toLowerCase() !== this.localization.captions.common.close.toLowerCase()
      && this.selectedDevice.name === IDTechHandle.name) {
      this.EncryptedCardData = data;
      this.GetDevices(true);
    }
  }

  CreateToken(handle, tenderID, ismanualCardEntry = false) {
    // Lights up the connected Device and asks for the card swipe
    const tokentransactionInfo: Promise<TokentransactionInfo> = this.agentRequestHandler.PaymentProcessor.CreateToken(handle,tenderID,this.terminalId,this.GatewayConfiguration?.isPartialPayAllowed,ismanualCardEntry,this.DefaultOutletId);
    tokentransactionInfo.then(async (response) => {
      if (response.status.toLocaleLowerCase() === 'success') {
        const cardTransactionInfo = await this.StoreAndGetSwippedCardInfo(response, tenderID);
        if (cardTransactionInfo) {
          this.displayCardInfo = cardTransactionInfo[0];
          this.CardInfo.emit(cardTransactionInfo[0]);
          this.NewPaymentReferenceID.emit(cardTransactionInfo[1]);
        }
      }
    }).catch((err) => {

      if (err.error[0] !== null && err.error[0].Code !== null) {
        this._paymentService.PaymentErrorPrompt(err.error[0].Code, err?.error[0]?.Message);
      } else {
        return this.utils.showError(this.localization.captions.shop.PMUnexpectedError);
      }
      this.cardConnectDialog.close();
    });
  }

  async StoreAndGetSwippedCardInfo(tokentransactionInfo: TokentransactionInfo, tenderID: number): Promise<[CardInfo, number]> {
    let swipedcardInfo: ClientCreditCardInfo;
    let newCardInfo: CardInfo;
    newCardInfo = {
      cardNumber: tokentransactionInfo.account.id,
      cardHolderName: tokentransactionInfo.account.name,
      entryMode: tokentransactionInfo.cardInfo.entryMode,
      issuerType: tokentransactionInfo.cardInfo.issuer.toLowerCase(),
      cardExpiration: tokentransactionInfo.cardInfo.cardExpiration,
      cardType: tokentransactionInfo.cardInfo.cardType
    };

    if (!this._paymentService.ValidateCreditCard(newCardInfo)) {
      this.cardConnectDialog.close();
      return;
    }

    const storeTokenReq: StoreTokenRequest = {
      cardInfo: newCardInfo,
      payAgentResponse: {
        account: tokentransactionInfo.account,
        payAgentId: tokentransactionInfo.payAgentId,
        status: tokentransactionInfo.status,
        transactionDetails: tokentransactionInfo.transactionDetails,
        transactionKey: tokentransactionInfo.transactionKey,
      },
      tenderId: tenderID,
      sourceType: this.authData ? this.authData.sourceType : "",
      sourceTypeId: this.authData ? this.authData.sourceTypeId : "",
      folioNumber: this.authData ? this.authData.folioNumber : "0",
      roomNumber: this.authData ? this.authData.roomNumber : ""
    };

    if (this.agentRequestHandler.checkIsSkipPMAgent()) {
      storeTokenReq.gatewayResponse = JSON.stringify(tokentransactionInfo?.gatewayResponse);
      storeTokenReq.requestId = tokentransactionInfo?.requestId;
      storeTokenReq.outletId = +this.DefaultOutletId;
    }

    const baseResponse: PaymentBaseResponse = await this.StoreToken(storeTokenReq);
    if (baseResponse && baseResponse !== null) {
      swipedcardInfo = {
        id: 0,
        tokenTransId: baseResponse.transactionId,
        isActive: true,
        clientId: 0,
        createdTime: null
      };
      this.cardConnectDialog.close();
    } else {
      // throw error
      this.cardConnectDialog.close();
      return;
    }

    newCardInfo.cardNumber = this._paymentService.MaskCreditCardNumber(newCardInfo.cardNumber);
    newCardInfo.cardExpiration = this.formatCreditCardExpiryDate(newCardInfo.cardExpiration);
    return [newCardInfo, baseResponse.transactionId];
  }

  async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
    const baseResponse: PaymentBaseResponse = await this.agentRequestHandler.PaymentProcessor.StoreToken(storeTokenRequest);
    return baseResponse;
  }

  async RequestForCredit(CreditAmount?) {
    let RequestBody: SaleRequest = this.FormSaleRequestBody(this.saleData, CreditAmount);
    let CreditResponse: Promise<any> = this._paymentService.RequestCredit(
      RequestBody,
      this.terminalId
    );
    CreditResponse.then((response) => {

      let CreditResult: SaleResponse = response;
      if (CreditResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        this.cardConnectDialog.close();
        this.utils.ToggleLoader(true);
        this.ValidatePayRequest(CreditResult);
        this.utils.ToggleLoader(false);
      } else {
        this.cardConnectDialog.close();
        this.HandleFailure(response);
      }
    });
    CreditResponse.catch((errorResponse) => {
      this.cardConnectDialog.close();
      this.HandleFailure(errorResponse);
    });
  }

  RequestForSale(saleAmount?) {
    let RequestBody: SaleRequest = this.FormSaleRequestBody(this.saleData, saleAmount);
    let saleResponse: Promise<any> = this._paymentService.RequestSale(
      RequestBody,
      this.terminalId
    );
    saleResponse.then((response) => {
      let SaleResult: SaleResponse = response;
      if (SaleResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        this.cardConnectDialog.close();
        this.utils.ToggleLoader(true);
        this.ValidatePayRequest(SaleResult);
        this.utils.ToggleLoader(false);
      } else {
        this.cardConnectDialog.close();
        this.HandleFailure(response);
      }
    }).catch((errorResponse) => {
      this.cardConnectDialog.close();
      this.HandleFailure(errorResponse);
    });
  }


  async RequestForAuth(authAmount?) {
    let RequestBody: AuthRequest = this.FormAuthRequestBody(this.authData, authAmount);
    let authResponse: Promise<any> = this._paymentService.RequestAuth(
      RequestBody,
      this.terminalId
    );
    authResponse.then((response) => {

      let AuthResult: AuthResponse = response;
      if (AuthResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        this.cardConnectDialog.close();
        this.utils.ToggleLoader(true);
        this.ValidateAuthRequest(AuthResult);
        this.utils.ToggleLoader(false);
      } else {
        this.cardConnectDialog.close();
        this.HandleFailure(response);
      }
    }).catch((errorResponse) => {
      this.cardConnectDialog.close();
      this.HandleFailure(errorResponse);
    });
  }


  FormSaleRequestBody(saleData: AuthInformation, saleAmount?): SaleRequest {
    let userId = this.localization.GetPropertyInfo('UserId');
    let RequestBody: SaleRequest = {
      handle: this.selectedDevice.handle,
      inquirerInfo: {
        terminalId: saleData.terminalId,
        orderNumber: saleData.checkNumber,
        profitCenter: saleData.ProfitCenter,
        financialBins: [],
        zipcode: saleData.zipcode ? saleData.zipcode : '',
        isPartialTenderAllowed: saleData.isPartialPayAllowed ? saleData.isPartialPayAllowed : false,
        tenderId: PaymentMethods.CreditCard.toString(),
        employeeId: userId ? userId : '0',
        customer: userId ? userId : '0'
      },
      amount: {
        requestAmount: saleAmount
      }
    };
    return RequestBody;
  }

  FormAuthRequestBody(saleData: AuthInformation, saleAmount?): SaleRequest {
    let userId = this.localization.GetPropertyInfo('UserId');
    let RequestBody: SaleRequest = {
      handle: this.selectedDevice.handle,
      inquirerInfo: {
        terminalId: saleData.terminalId,
        orderNumber: saleData.checkNumber,
        profitCenter: saleData.ProfitCenter,
        financialBins: [],
        zipcode: saleData.zipcode ? saleData.zipcode : '',
        isPartialTenderAllowed: saleData.isPartialPayAllowed ? saleData.isPartialPayAllowed : false,
        tenderId: PaymentMethods.CreditCard.toString(),
        employeeId: userId ? userId : '0',
        customer: userId ? userId : '0',
				enterprise: String(this.PropertyInfo.PropertyId),
       sourceTypeId : this.saleData?.sourceTypeId ? this.saleData.sourceTypeId.toString() : '0'
      },
      amount: {
        requestAmount: saleAmount
      }
    };
    return RequestBody;
  }

  HandleFailure(errorResponse) {

    // Show Payment Error Prompt
    if (
      errorResponse.error &&
      errorResponse.error[0] != null &&
      errorResponse.error[0].Code != null &&
      errorResponse.error[0].Code != ''
    ) {
      this._paymentService.PaymentErrorPrompt(errorResponse.error[0].Code);
    }
    else {
      return this.utils.showError(
        this.localization.captions.shop.PMUnexpectedError
      );
    }
  }

  async ValidatePayRequest(SaleResult?): Promise<boolean> {
    const currentSaleAmt = SaleResult ? SaleResult.amount : '';
    if (SaleResult != null) {
      let validatePayReq: ValidatePayRequest = {
        tenderId: PaymentMethods.CreditCard,
        payAgentResponse: !SaleResult ? null : {
          payAgentID: SaleResult.payAgentId,
          status: SaleResult.status,
          transactionDetails: SaleResult.transactionDetails,
          transactionKey: SaleResult.transactionKey,
          errorMessage: SaleResult.errorMessage
        },
        cardInfo: {
          cardNumber: SaleResult.account.id,
          cardHolderName: SaleResult.account.name,
          entryMode: SaleResult.cardInfo.entryMode,
          issuerType: SaleResult.cardInfo.issuer,
          cardExpiration: SaleResult.cardInfo.cardExpiration,
          cardType: SaleResult.cardInfo.cardType
        },
        amount: currentSaleAmt.authorisedAmount,
        aCESPaymentRecord: null,
        cMSPaymentRecord: null,
        giftcardPaymentRecord: null,
        aRPostingPaymentRecord: null,
        hostName: SaleResult?.hostName,
        agentVersion: SaleResult?.agentVersion,
        ipAddress: SaleResult?.ipAddress
      };

      let validatePayResponse: Promise<any> = this._paymentService.ValidatePay(
        validatePayReq,
        this.saleData.outletId
      );


      validatePayResponse
        .then((response) => {
          let validatePayResult: ValidatePayResponse = response;
          if (validatePayResult) {


            const cardInfo = validatePayReq.cardInfo;
            cardInfo.cardNumber = this._paymentService.MaskCreditCardNumber(cardInfo.cardNumber);
            this.CardInfo.emit(cardInfo);
            this.TransactionID.emit(validatePayResult);
            return true;
          }
        })
        .catch((error) => {

          this.TransactionID.emit(null);
          return this.utils.showError(
            this.localization.captions.shop.PMUnexpectedError
          );
        });
    }
    return false;
  }


  async ValidateAuthRequest(AuthResult?): Promise<boolean> {
    const currentSaleAmt = AuthResult ? AuthResult.amount : '';
    if (AuthResult != null) {
      let validateAuthReq: ValidateAuthRequest = {
        tenderId: PaymentMethods.CreditCard,
        payAgentResponse: !AuthResult ? null : {
          payAgentID: AuthResult.payAgentId,
          status: AuthResult.status,
          transactionDetails: AuthResult.transactionDetails,
          transactionKey: AuthResult.transactionKey,
          errorMessage: AuthResult.errorMessage
        },
        cardInfo: {
          cardNumber: AuthResult.account.id,
          cardHolderName: AuthResult.account.name,
          entryMode: AuthResult.cardInfo.entryMode,
          issuerType: AuthResult.cardInfo.issuer,
          cardExpiration: AuthResult.cardInfo.cardExpiration,
          cardType: AuthResult.cardInfo.cardType
        },
        amount: currentSaleAmt.authorisedAmount,
        sourceType: this.authData.sourceType,
        sourceTypeId: this.authData.sourceTypeId,
        folioNumber: this.authData.folioNumber,
        roomNumber: this.authData.roomNumber,
        hostName: AuthResult?.hostName,
        ipAddress: AuthResult?.ipAddress,
        agentVersion: AuthResult?.agentVersion

      };

      let validateAuthResponse: Promise<any> = this._paymentService.ValidateAuth(
        validateAuthReq,
        this.authData.outletId
      );


      validateAuthResponse
        .then((response) => {
          let validateAuthResult: ValidateAuthResponse = response;
          if (validateAuthResult) {


            const cardInfo = validateAuthReq.cardInfo;
            cardInfo.cardNumber = this._paymentService.MaskCreditCardNumber(cardInfo.cardNumber);
            this.CardInfo.emit(cardInfo);
            this.TransactionID.emit(validateAuthResult);
            this.AuthAmount.emit(validateAuthResult.paymentManagerResponse.totalAuthorizedAmount);
            return true;
          }
        })
        .catch((error) => {

          this.TransactionID.emit(null);
          return this.utils.showError(
            this.localization.captions.shop.PMUnexpectedError
          );
        });
    }
    return false;
  }


  removeCardDetails() {
    this.removeDetails.emit(true);
  }

}
