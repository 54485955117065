import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TilesConfiguration, TilesSummaryData } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';

@Component({
    selector: 'app-tiles',
    templateUrl: './tiles.componenet.html',
    styleUrls: ['./tiles.componenet.scss']
})

  export class TilesComponent implements OnInit, OnChanges {
    @Input() TilesModel: TilesSummaryData[];
    tilesSummaryData: TilesSummaryData[] = [];
    constructor() {
    }
    ngOnChanges(): void {
      if ( this.TilesModel && this.TilesModel !== undefined) {
        this.tilesSummaryData = [...this.TilesModel];
      }
    }
    ngOnInit(): void {
       console.log('Transaction Module loaded');
    }
  }