import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { NotificationActions, TemplateDetail, TemplateDomainData, DefaultTemplateUpdate } from './email-templates.model';
import { TemplateServiceCommunication } from '../templates.service';
import { Host, Product } from '../../shared/shared/globalsContant';
import { HttpMethod } from '../../shared/shared/service/http-call.service';

@Injectable()
export class EmailTemplatesService {
  captions: any;
  productId: string;

  constructor(private localization: Localization, private _TemplateServiceCommunication: TemplateServiceCommunication) {
    this.captions = this.localization.captions.settings.utilities;
    this.productId = this.localization.GetPropertyInfo('ProductId');
  }
  exceptionHandle(ex: any) {
    console.error(ex);
    throw ex;
  }

  async getActionsByType(type: number): Promise<NotificationActions[]> {
    try {
      const calldesc: string = "GetTopicsByType";
      return await this._TemplateServiceCommunication.InvokeServiceCallAsync(calldesc, this.getHost(), HttpMethod.Get, { NotificationTypeId: type });
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async GetTemplateDetailsByTopicId(topicId: number): Promise<TemplateDetail> {
    try {
      const calldesc: string = "GetTemplateDetailsByTopicId";
      return await this._TemplateServiceCommunication.InvokeServiceCallAsync(calldesc, this.getHost(), HttpMethod.Get, { TopicId: topicId });
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async CreateorUpdateTemplate(templatedata: TemplateDomainData): Promise<boolean> {
    try {
      const calldesc: string = "CreateorUpdateTemplate";
      return await this._TemplateServiceCommunication.InvokeServiceCallAsync(calldesc, this.getHost(), HttpMethod.Post, '', templatedata);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }

  async UpdateDefaultTemplate(defaultTemplateUpdate: DefaultTemplateUpdate): Promise<boolean> {
    try {
      const calldesc: string = "UpdateDefaultTemplate";
      return await this._TemplateServiceCommunication.InvokeServiceCallAsync(calldesc, this.getHost(), HttpMethod.Put, '', defaultTemplateUpdate);
    } catch (e) {
      this.exceptionHandle(e);
    }
  }


  private getHost(): Host {
    let host: Host;
    switch (this.productId) {
      case Product.GOLF.toString(): {
        host = Host.golfManagement;
        break;
      }
      case Product.SPA.toString(): {
        host = Host.spaManagement;
        break;
      }
      default: {

        break;
      }
    }
    return host;
  }
}
