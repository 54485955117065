import { TranslateService } from '@ngx-translate/core';
import { FormDisplayNameRemapConfig } from './data-remap-config.interface';

export const FromDisplayNameRemap: FormDisplayNameRemapConfig = async ( 
    remapJson: Array<any>, FieldKey: string, DisplayName: string, translateService ?: TranslateService ) => {
    try {
        let displayName = DisplayName;
        if ( translateService && translateService !== undefined ) {
            const languageKey = FieldKey + '.' + DisplayName;
            displayName = translateService.instant(languageKey);
        }
        remapJson?.forEach((remap: any) => {
        const remapKey = remap.Name.toString().toString();
         if ( FieldKey.toLowerCase() === remapKey.toLowerCase()) {
            const getMapAray = remap.UIFieldLookup;
            if ( getMapAray && getMapAray.length > 0 ) {
                getMapAray.forEach((element: any) => {
                    if ( element.Property_Key.toString().toLowerCase() === DisplayName.toString().toLowerCase()) {
                        const getLangugaeObj = element.UIFieldDisplayNames.filter(( Planguage: any) => Planguage.LanguageId === 1);
                        if ( getLangugaeObj && getLangugaeObj.length > 0 ) {
                            displayName = getLangugaeObj[0].DisplayName;
                        }
                    }  
                 });
            }
         }
        });
        return displayName;
    } catch (error) {
        console.error(error);
    }
};
