import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { PropertySettingDataService } from "../../../sytem-config/property-setting.data.service";
import { MiscellaneousSwitch } from "../../../shared/globalsContant";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { AlertAction, AlertType } from "src/app/common/shared/shared.modal";
import { ButtonType } from "src/app/retail/shared/globalsContant";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";

@Component({
  selector: "app-general-ledger-mapping-crud",
  templateUrl: "./general-ledger-mapping-crud.component.html",
  styleUrls: ["./general-ledger-mapping-crud.component.scss"],
})
export class GeneralLedgerMappingCrudComponent implements OnInit {
  commonCaptions: any;
  captions: any;
  generalLedgerForm: UntypedFormGroup;
  mappingData: any;
  isValid: boolean = false;
  businessUnitFromMisc: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() dataInput: any;
  @Input() LinkType: number;

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private localization: RetailLocalization,
    private dialogRef: MatDialogRef<GeneralLedgerMappingCrudComponent>,
    private utilities: RetailUtilities,
    private setting: PropertySettingDataService
  ) {}

  async ngOnInit() {
    await this.getBusinessUnitFromMisc();
    this.initializeForm();
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.generalLedgerForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        this.isValid = this.generalLedgerForm.dirty;
      });
    if (this.dataInput[0][this.dataInput[1]] != "—") {
      this.patchValue(this.dataInput);
    }
  }

  initializeForm() {
    this.generalLedgerForm = this.fb.group({
      glAccountId: [""],
      departmentId: [""],
      businessUnit: this.businessUnitFromMisc,
      isActive: true,
    });
  }

  patchValue(data) {
    console.log(data);
    let propName = data[1];
    this.generalLedgerForm.controls.glAccountId.setValue(
      data[0][propName + " GlAccountId"]
    );
    this.generalLedgerForm.controls.departmentId.setValue(
      data[0][propName + " DepartmentId"]
    );
    this.generalLedgerForm.controls.businessUnit.setValue(
      data[0][propName + " BusinessUnit"]
    );
    this.generalLedgerForm.controls.isActive.setValue(
      data[0][propName + " IsActive"]
    );
  }

  saveMappingType() {
    this.mappingData = {
      glAccountId: this.generalLedgerForm.controls.glAccountId.value,
      departmentId: this.generalLedgerForm.controls.departmentId.value,
      businessUnit: this.generalLedgerForm.controls.businessUnit.value,
      isActive: this.generalLedgerForm.controls.isActive.value,
    };
    if (
      (this.generalLedgerForm.controls.glAccountId.value.length != 0 &&
        this.generalLedgerForm.controls.departmentId.value.length != 0 &&
        this.generalLedgerForm.controls.businessUnit.value.length != 0)
    ) {
      this.dialogRef.close(this.mappingData);
    } else {
      this.utilities.showAlert(
        this.captions.showInputValidateMessage,
        AlertType.Error,
        ButtonType.Ok
      );
    }
  }

  onClick() {
    this.dialogRef.close();
  }

  onNoClick() {
    if (this.isValid) {
      this.utilities.showAlert(
        this.commonCaptions.saveChangesMessage,
        AlertType.Warning,
        ButtonType.YesNo,
        (res) => {
          if (res === AlertAction.YES) {
            this.dialogRef.close();
          }
        }
      );
    } else {
      this.isValid = false;
      this.dialogRef.close();
    }
  }

  isActivechange(event) {
    this.generalLedgerForm.controls.isActive.setValue(event);
  }

  private async getBusinessUnitFromMisc() {
    const miscSettings = await this.setting.GetMiscConfigurationSetting();
    let businessUnit = miscSettings.find(
      (x) => x.switch === MiscellaneousSwitch.BUSINESS_UNIT_VALUE
    );
    this.businessUnitFromMisc = businessUnit.value.toString();
  }
}
