import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysPicturePopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-picture-popup/agilysys-picture-popup.component';
import { DetailPictureConfig, 
FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';

export const CustomRetailWizard: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: FormType[], CustomData: MatDialog
    ): any => {
        try {
            console.log('Change triggerred');
            if (FieldGrouping.CustomFormAction) {
                FormField = MasterDataCollection.NestedFormConfig.EditFormConfig[5].NestedForm[0].FormFields;
                let OriginValue = '';
                const RetailWizard = FormGroupData.value[FieldGrouping.OdataKey];
                const EnableFieldIndex =
                FormField.findIndex(x => x.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo);
                if (RetailWizard === 4) {
                    FormField[EnableFieldIndex].fieldType.SingleData.disbaledProperty = false;
                } else {
                    if (RetailWizard === 2) {
                        OriginValue = FormGroupData.value['Number'];
                    } else if (RetailWizard === 3) {
                        OriginValue = FormGroupData.value['Barcode'];
                    }
                    FormField[EnableFieldIndex].fieldType.SingleData.disbaledProperty = true;
                }
                if (RetailWizard === 1) {
                    const ingredientsendPointUrl = `/inventory/api/ingredients?$orderby=Id desc&$top=1&$select=Id`;
                    httpService.GethttpMethod(ingredientsendPointUrl).subscribe(res => {
                        if (res && res['value'].length > 0) {
                            FormGroupData.controls['Origin'].setValue(res['value'][0].Id);
                            FormGroupData.controls['Origin'].updateValueAndValidity();
                        }
                    });
                } else {
                    FormGroupData.controls['Origin'].setValue(OriginValue);
                }
            }
        } catch (error) {
            console.log('error occured CustomDefaultSelect', error);
        }
    };