import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';

@Injectable()
export class SlideInformationService {
  CurrentTherapistImage: any;


  FullScreen = false;
  slideInfo = false;
  hideCheckInCheckOut =false;
  fieldData: any = [];
  events: {
    CreateNewAppointmentClick: () => string,
    EditAppointmentClick: (id: any) => string,
    CreateLocationBlockClick: ()=> string
  };
  appointmentpopupTitle: any;
  cellTooltipdata: any = [];
  private _clientImageObj = new BehaviorSubject<number>(0);
  clientImageObj: Observable<any> = this._clientImageObj.asObservable();

  ImageChange(img: any) {
    this._clientImageObj.next(img);
  }

}
