import { rGuestBaseService } from "./service/base.service";
import { EventEmitter } from "@angular/core";
import { ItemsExtract } from "../retail-reports/business/retail-reports/itemsExtract.report";
import { PaymentMethods } from "./business/shared.modals";
import { C } from "@angular/cdk/keycodes";


export const registerEvent: EventEmitter<rGuestBaseService> = new EventEmitter();
export const unregisterEvent: EventEmitter<rGuestBaseService> = new EventEmitter();

export const DashBoardRegisterEvent: EventEmitter<any> = new EventEmitter();
export const THERAPIST = 'THERAPIST';
export const USER = 'USER';
export const SERVICECHARGE = 'SERVICECHARGE';
export const GRATUITY = 'GRATUITY';

export const VISUAL_ONE = 'visual one';
export const VISUALONE = 'visualone';
export const V1 = 'V1';
export const VERSA = 'VERSA';

export const DefaultGUID = "00000000-0000-0000-0000-000000000000";
export const DaysWithNumbers = [
  { id: 1, name: "Mon" },
  { id: 2, name: "Tue" },
  { id: 3, name: "Wed" },
  { id: 4, name: "Thu" },
  { id: 5, name: "Fri" },
  { id: 6, name: "Sat" },
  { id: 7, name: "Sun" },
]

export const enum PromptLevel {
  One = 1,
  Two,
  Three
}

export const enum PromptType {
  Delete = 1,
  UnsavedChanges,
  Prompt
}

export const SyncStatus =
{
  SYNCED: "SYNCED",
  NOTSYNCED: "NOTSYNCED"
}

export const taxValue = 10;

export const GridTimeInterval = 15;

export const version: string = "1.0.1";

export const FILENAME: string = "spa"

export const url: string = "api/data";

// CompressionLimit is the saturation point to start the compression
// Size in KB
export const COMPRESSION_LIMIT = 500;

// ALLOWED_IMAGE_SIZE is the max file size allowed.
// Size in MB
export const ALLOWED_IMAGE_SIZE = 2;
export const IDTechCardSwipeTimeoutInSeconds = 45;
export const NUMBER_VALUES: any = {
  "one": 1,
  "two": 2,
  "three": 3,
  "four": 4,
  "five": 5,
  "six": 6,
  "seven": 7,

}
export declare const setting: any;

export const enum ButtonType {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  Continue = 6,
  AddCancel = 7,
  ContinueCancel = 8,
  viewFailedCancel = 9
}

export const enum ButtonOptions {
  Yes = 'yes',
  No = 'no',
  Cancel = 'cancel',
  Ok = 'ok',
  Save = 'save',
  Add = 'Add'
}
export const DepositRetailItemType = 6;

export enum GridOperationType {
  Create = 1,
  Edit,
  Delete,
  ListOrderDrag,
  Copy
}

export enum GridType {
  group = 1,
  location,
  therapist,
  medical,
  addons,
  equipment,
  spaServices,
  commission,
  spaPackage,
  appointmentsearch,
  spaPackageService,
  deletepackage,
  packageClasses,
  colorLink,
  lunchsetup,
  breakType,
  priceType,
  cancelReasons,
  password,
  locationMaintenance,
  equipmentMaintenance,
  therapistUnavailability,
  customfield,
  multipleAppointments,
  outlet,
  retail,
  quickSale,
  quickSaleCategories,
  creditcardterminal,
  categorygroup,
  commissionTemplate,
  retailtransactions,
  waitlist,
  roleSetup,
  userSetup,
  userRoleConfiguration,
  packageYielding,
  taxes,
  retailCategories,
  subCategory,
  retailSuppliers,
  retailOutlet,
  supplierDetails,
  unitOfMeasure,
  discountTypes,
  machineNames,
  userMachineConfiguration,
  recieptConfiguration,
  outletTerminal,
  inputEditTable,
  vendor,
  vendorType,
  discountReason,
  posttypemapping,
  memberaccrualmapping,
  revenuepostcode,
  generalLedgerMapping,
  tierLevel
}

export enum RetailTransactions {
  mailorder = 1,
  reprintticket,
  opentransactions,
  correctvoid,
  pettycash,
  returnwithticket,
  returnwithoutticket,
  modifypostedcommission,
  modifypostedcommissionticketdetails,
  RevenuePostingsLog,
  OrphanPayments,
  DataMagineReceiptLog,
  RefundLog,
  NonIntegratedPaymentLog,
  PrintGiftReceipt
}
export enum PhoneType {
  Home = 1,
  Office,
  Mobile
}

export enum ActionType {
  recurring = 1,
  edit,
  cancel,
  copy,
  move,
  swap,
  print,
  recap,
  overbook,
  serviceaddon,
  linkmultipack,
  booklunch,
  changeservice,
  createtherapistbreak,
  modifytherapistbreak,
  undocheckin,
  checkin,
  checkout,
  adddeposit,
  delete,
  checkin_checkout,
  undocheckout,
  reinstate

}
export enum ReceiptType {
  sales = 1,
  reprint = 2,
  void = 3,
  returnExchange = 4,
  reprintVoid = 5,
  preSettlement = 6,
  giftReceipt=7
}

export const enum Product {
  SPA = 1,
  RETAIL = 2,
  GOLF = 3,
  COMMON = 4,
  SNC = 5,
  PMS = 6
}

export const enum ClientType {
  Guest,
  Member = 2,
  HotelReservation
}
export enum NamePrintConfig {
  userName = 0,
  FullName,
  firstName,
  lastName

}

export const enum Host {
  image = "image",
  spaManagement = "spaManagement",
  schedule = "schedule",
  retailManagement = "retailManagement",
  retailPOS = "retailPOS",
  authentication = "authentication",
  reporting = "report",
  commission = "commission",
  payment = "payment",
  golfSchedule = "golfSchedule",
  retailPOS_Local = "retailPOS_Local",
  documentation = "documentation",
  golfManagement = "golfManagement",
  folio = "folio",
  account = "Account",
  SnCService = "SalesCateringService",
  internalIntegration = "InternalIntegration",
  reservation = "V1Reservation",
  CommonGateway = "CommonGateway"
}
export const enum Module {
  client = "Client",
  appointment = "Appointment",
  home = "home"
}
export const enum ImgRefType {
  client = "CLIENT",
  therapist = "THERAPIST",
  retailItem = "RETAILITEM",
  locker = "LOCKER"
}

export enum GenderPreference {
  none,
  enforceGender,
  preferMale,
  preferFemale,
  noPreference
}


export class Config {
  value: any = 1;
}

export enum UtilityMoveOptions {
  therapistAppointments = 1,
  therapistLocation = 2,
  appointmentLocation = 3
}
export const enum AppointmentStatus {
  Scheduled = "RESV",
  Canceled = "CANC",
  Break = "BREAK",
  CheckIn = "CKIN",
  CheckOut = "CKOUT",
  NoShow = "NOSHOW",
  Temp = "TEMP"
}

export const enum ContactMode {
  Phone = 'Phone',
  Email = 'Email'
}

export const enum ContactType {
  Home = 'Home',
  Mobile = 'Mobile',
  Office = 'Office'
}

export const enum ReportBreakPoint {
  ClientItinerary = 1200,
  BatchPrintClientItinerary = 1205,
  AppointmentListingbyLocation = 1210,
  AppointmentListingbyStaffMember = 1215,
  AppointmentListingbyCustomFields = 1220,
  AppointmentListing = 1225,
  CancellationReport = 1230,
  NoShowReport = 1235,
  StaffMemberSummaryReport = 1240,
  CommissionReport = 1245,
  GratuityReport = 1250,
  ServiceChargeReport = 1255,
  SurchargesReport = 8195,
  TransactionLog = 8090,
  Accrual = 8020,
  ReturnedItems = 8060,
  InventoryAudit = 8085,
  InventoryTransfer = 8080,
  InventoryDetail = 8070,
  InventorySummary = 8065,
  InventoryOnHand = 8736,
  MultiPackItems = 8055,
  CorrectVoid = 8015,
  SaleByDiscountType = 8050,
  InventoryWash = 8075,
  SaleByItem = 8035,
  SaleByCategory = 8040,
  SaleBySubCategory = 8045,
  SalesByVendor = 8170,
  RevenueSummary = 8010,
  TopSpenders = 8105,
  Shift = 8000,
  Transaction = 8005,
  GiftCardRedeem = 8006,
  GiftCardIssued = 8007,
  TransactionDetail = 8100,
  TaxExemptSales = 8095,
  SalesMix = 8140,
  InventoryReport = 100805,
  InventoryPickupDelivery = 100820,
  ItemsExtract = 8150,
  InventorySummaryByVendor = 8160,
  VendorInventoryDetail = 8165,
  TransactionExtract = 8175,
  PurchaseOrders = 8180,
  Receiving = 8185,
  InventoryVariance = 8190,
  InventoryValuation = 8205,
  InventoryValueandActivity = 8200,
  InventoryAvailability = 8210
}


export const enum SPAManagementBreakPoint {
  AddNewClientProfile = 600,
  EditClientProfile = 605,
  AccessClientMedicalHistory = 610,
  ViewPrivateMedicalCondition = 615,
  AddSOAPNotes = 620,
  EditSOAPNotes = 625,
  EditClientPreferences = 630,
  EditCustomField1 = 635,
  EditCustomField2 = 640,
  EditCustomField3 = 645,
  EditCustomField4 = 650,
  EditCustomField5 = 655,
  AddPriceType = 660,
  AddEditLocker = 680,
  EquipmentMaintenance = 2400,
  LocationMaintenance = 2405,
  TherapistUnavailability = 2410,
  MoveMultipleApptTherapistAppointments = 2415,
  MoveMultipleApptTherapistLocation = 2420,
  MoveMultipleApptAppointmentLocation = 2425,
  DayEnd = 2430,
  CombineGuestRecords = 2435,
  RemoveTempHold = 2440,
  CommissionClassSetup = 800,
  StaffCommissionSetup = 805,
  AddTherapistBreak = 2200,
  EditTherapistBreak = 2205,
  DeleteTherapistBreak = 2210,
  StaffSchedule = 2215,
  ServiceGroup = 2000,
  ServiceLocation = 2005,
  TherapistSetup = 2010,
  MedicationCondition = 2015,
  ServiceAddOn = 2020,
  ServiceEquipment = 2025,
  SpaService = 2030,
  SpaPackage = 2035,
  CancelReason = 2040,
  PackageClasses = 2045,
  LunchSetup = 2050,
  ColorLinking = 2055,
  BreakType = 2060,
  PriceType = 2065,
  CustomFields = 2070,
  PackageYielding = 2075,
  ServiceYielding = 2080,
  SystemSettings = 2085,
  MoveMultipleAppointments = 241524202425
}



export const enum SPAScheduleBreakPoint {
  BookAppointment = 100,
  BookUnassignedTherapist = 105,
  BookSpaPackage = 110,
  BookExpressSpaPackage = 115,
  BookLunch = 120,
  ServiceAddOn = 125,
  EditAppointment = 130,
  CancelAppointment = 135,
  CheckInAppointment = 140,
  UndoCheckIn = 145,
  CheckOutAppointment = 150,
  CheckIn_CheckOutAppointment = 155,
  CopyAppointment = 160,
  MoveAppointment = 165,
  BookRecurringAppointments = 170,
  ChangeService = 175,
  ChangeService_PackageAppointment = 180,
  ChangeAppointmentStatus = 185,
  SwapAppointment = 190,
  OverbookTherapist = 195,
  OverbookLocation = 200,
  OverbookLunch = 205,
  AddDeposit = 210,
  LinkMultipack = 215,
  UnlinkMultipack = 220,
  ConvertAppointmentfromWaitlisttoScheduled = 225,
  RemoveDoNotMoveFlag = 230,
  EditCustomField1 = 235,
  EditCustomField2 = 240,
  EditCustomField3 = 245,
  EditCustomField4 = 250,
  EditCustomField5 = 255,
  EditAppointmentComments = 260,
  EditAppointmentServicePrice = 265,
  EditSetupTime = 270,
  EditBreakdownTime = 275,
  EditAppointmentDuration = 280,
  RecapAppointment = 285,
  PersonalInfoVisibleOnRecap = 290,
  AppointmentSearch = 400,
  ParkingLot = 405,
  AddWaitList = 410,
  ViewWaitList = 415,
  AppointmentConfirmation = 420,
  SettingServiceGroup = 2000,
  SettingServiceLocation = 2005,
  SettingTherapistSetup = 2010,
  SettingMedicationCondition = 2015,
  SettingServiceAddOn = 2020,
  SettingServiceEquipment = 2025,
  SettingSpaService = 2030,
  SettingSpaPackage = 2035,
  SettingCancelReason = 2040,
  SettingPackageClasses = 2045,
  SettingLunchSetup = 2050,
  SettingColorLinking = 2055,
  SettingBreakType = 2060,
  SettingPriceType = 2065,
  SettingCustomFields = 2070,
  SettingPackageYielding = 2075,
  SettingServiceYielding = 2080,
  SettingSystemSettings = 2085,
  UserSetup = 2300,
  UserRoleSetUp = 2305,
  UserRoleConfiguration = 2310

}

export const enum RetailBreakPoint {
  CreateTransaction = 3000,
  ApplyGratuity = 3005,
  ApplyServiceCharge = 3010,
  ApplyCommission = 3015,
  EditGratuity = 3020,
  EditServiceCharge = 3025,
  EditCommission = 3030,
  ApplyDiscount = 3035,
  ApplyCustomDiscount = 3070,
  ApplyTicketDiscount = 3155,
  OutletSetup = 7000,
  ItemSetup = 7005,
  QuickSaleKeys = 7010,
  UnitofMeasure = 7015,
  SplitCommissionTemplateSetup = 7020,
  ReOpenTransaction = 5000,
  VoidTransaction = 5005,
  CorrectTransaction = 5007,
  ReturnWithTicket = 5010,
  ReturnWithoutTicket = 5015,
  ModifyPostedCommission = 5020,
  ReprintTicket = 5025,
  RevenuePostingsLog = 5030,
  OrphanPayments = 5035,
  DataMagineLog = 5040,
  RefundFailure = 5045,
  NonIntegratedPaymentLog = 5050,
  DiscountType = 7060,
  DiscountConfiguration = 7061,
  PaymentMethods = 7070,
  CategoryLinking = 7041,
  CategoryGroup = 7030,
  RetailCategories = 7035,
  RetailSubCategories = 7040,
  creditcardterminal = 7025,
  Taxconfiguration = 7050,
  Inventory = 8700,
  ItemTransfer = 8725,
  ReceiptConfiguration = 7080,
  TaxExempt = 3040,
  SettleToCreditCard = 3050,
  SettleToPostToFolio = 3095,
  SettleToRoomCharge = 3055,
  SettleToCash = 3045,
  SettleToOthers = 3065,
  UserSessionConfiguration = 7085,
  RainCheckRedeem = 14500,
  RedeemPoint = 14501,
  StoreTerminal = 7090,
  VendorType = 7062,
  VATConfiguration = 7095,
  RetailFeatureConfiguration = 7100,
  CreditCards = 7105,
  GiftCards = 7110,
  PostTypeMapping = 7115,
  MachineNames = 7120,
  CommissionTemplate = 7125,
  QuickSaleCategories = 7130,
  Outlets = 7140,
  PayAgents = 7145,
  MemberAccrualMapping = 7145,
  Settlers = 7150,
  ResortFinancePostTypeMapping = 7155,
  GeneralLedgerMapping = 7165,
  DiscountReason = 7055,
  VendorSetup = 7063,
  PACKAGEDISCOUNT = 50430,
  TeeTimePay = 10130,
  EatecInventory = 8731,
  EatecTransfer = 8732,
  EatecPhysicalInventory = 8733,
  EatecReceiving = 8734,
  EatecPurchaseOrder = 8735,
  PostTypeCompOrder = 150045,
  RankCodeManagement = 150010,
  OfferCodeManagement = 150005,
  CompTemplateSetup = 150030,
  CompDepartmentSetup = 150015,
  StaffDirectorySetup = 150020,
  StaffBillingProfile = 150025,
  CompAccountingSetup = 150040,
  AllowOffersUpload = 150070,

  MealPeriodSetUp = 65120,
  DiscountSetup = 50545,
  PackageClasses = 50400,
  PackageComponents = 50395,
  PackageComponentCategorySetup = 50405,
  PackagePlans = 50390,
  PackageYeilding = 65125,
  PackageSurcharge = 65130,
  PackagePostOptions = 65135,
  PropertySettingPackages = 85155,
  AuxiliaryPackages = 55200,

  CLUBAGREEMENT = 15045,
  CARTAGREEMENT = 15050,
  SHOEAGREEMENT = 15055,
  RETAILSALECHIT = 15060,
  ISSUEV1GIFTCARD = 3075,
  ISSUEEXTERNALGIFTCARD = 3080,
  SETTLETOGROUPCHARGE = 3085,
  COMPONENT_LINKING = 11525,
  OVERRIDE_COMPONENT_LINKING = 11530,
  ALLOWARPAYMENTS = 3090,
  REMOVE_CARD_ON_FILE = 3115,
  ALLOW_GIFTCARD_SEARCH_BY_GUESTNAME = 3125,
  CustomFee = 7160,
  SETTLETOGHOTELCOMP = 3130,
  ItemMapping = 7170,
  SaleDepletionLog = 7175,
  VipType = 2110,
  NonIntegratedCreditCards = 7180,
  SettleToNonIntegratedCreditCards = 3135,
  AddOrModifyCustomFee = 3140,
  RENTALITEMCONTROL = 3145,
  REDEEMRETAILMULTIPACK = 3150,
  LOCKERCONTROL = 3160,
  CostPlusDiscount = 3110
}


export const enum InventoryBreakPoint {
  UpdateQTYonHand = 8700,
  FreezeInventory = 8705,
  ReleaseInventory = 8710,
  FullInventoryUpdate = 8715,
  PartialInventoryUpdate = 8720,
  ManualInventory = 8721,
  InventoryManagement = 8722,
  ItemTransfer = 8725,
  PrintBarcodes = 8730,
}

export const enum ErrorConstants {
  TherpistAlreadyBlocked = 923,
  TherapistNotScheduled = 925,
  LocationNotAvailable = 931,
  LocationAlreadyBlocked = 933,
  LocationUnderMaintenance = 934,
  ClientCanBeOverBooked = 944,
  ClientNotAvailable = 942,
  ClientCannotBeOverBooked = 943,
  TherapistNotCertifiedForAddOn = 928
}


export const enum TherapistScheduleView {
 ClientInformation = 2000005,
 OtherDetails = 2000010,
 TherapistEarnings = 2000015,
 AppointmentPrice = 2000020,
 ClientDetails = 2000025,
 CheckOutComments = 2000030,
 ViewEarnings = 2000035,
 CurrentDayView = 2000040,
 ClientComments = 2000055,
 AppointmentComments = 2000060,
 ServiceAddOn = 2000065,
 SoapNotes = 2000070,
 TherapistView = 2000100,
 TherapistEForm = 2000075,
 AddRetailItem = 2000130,
 ApplyGratuiy = 2000135,
 ApplyServiceCharge = 2000140,
 ApplyDiscount = 2000145,
 ApplyCommission = 2000150
}

export const enum OperationType {
  None = 0,
  Create = 1,
  Edit = 2,
  Delete = 3
}

export type Partial<T> = {
  [P in keyof T]?: T[P];
}

export const allowedUrl: Array<any> = [false, 'report']
export const zipcodeformat: any = {
  'usFormat': '9999 9999 9999 9999',
  'uKFormat': '9999-9999-9999'
}

export enum noRecordsType {
  noRecords,
  noRecordsAfterSearch,
  noAppointmentsBooked
}

export enum ServiceChargeGratuityValueType {
  PreDefinedPercent = 0,
  CustomPercent,
  CustomAmount
}

export enum ServiceChargeGratuityPercentId {
  TenPercent = 1,
  FifteenPercent = 2,
  TwentyPercent = 3,
  TwentyFivePercent = 4
}

export enum PercentageAmount {
  Percentage = 1,
  Amount = 2,
  CustomPercentage = 3
}

export const IDTechCardSwipeTimeOut: number = 30 //In Seconds

export enum SelectType {
  single = 1,
  multiple = 2
}

export const enum RetailCodeTranslogType {
  RETAILCODECREATE = "RetailCodeCreate",
  RETAILCODEEDIT = "RetailCodeEdit",
  RETAILCODEDELETE = "RetailCodeDelete"
}

export const enum RetailCodeTranslogDescription {
  OUTLETCREATED = "Outlet Created",
  OUTLETMODIFIED = "Outlet Modified",
  OUTLETDELETED = "Outlet Deleted"
}

export const enum Maxlength {
  FLATAMOUNT = 12,
  PERCENTAGE = 3
}

export const enum GatewayConfigurationType {
  None = 0,
  CreditCard,
  GiftCard
}

export const enum GiftCardType {
  V1GiftCard = 2,
  ExternalGiftCard = 4,
  Both = 6
}

export enum GiftCardStatus {
  Active = "active",
  InActive = "inactive"
}

export const enum GiftCardTransactionType {
  Issue = 1,
  Load,
  UnLoad,
  CashBack,
  Redemption
}

export enum GiftCardVendorType {
  None = 0,
  V1,
  Givex,
  Vantiv,
  Shift4,
  Agilysys,
  SVS
}

export const enum Modules {
  PMS = 1,
  SPAMGT,
  SPASCH,
  GOLFMGT,
  GOLFSCH,
  SPAIMG,
  POS,
  RETAILMGT,
  INVENTORY,
  REPORTING,
  COMMISSION,
  ACCT,
  SNC,
  PAYMENT
}

export enum ReservationStatus {
  None = 0,
  Active = 2,
  Cancelled = 4,
  Closed = 8,
  Lost = 16
}

export const enum FeatureValue {
  OfferManagementSystem = "OfferManagementSystem",
  Property = "Property"
}

export const enum RetailFunctionalities {
  ShowCreditCardDefaults = "ShowCreditCardDefaults",
  ShowNoTaxExempt = "ShowNoTaxExempt",
  ShowCreatePaymentMethod = "ShowCreatePaymentMethod",
  IsFolioPaymentMethod = "IsFolioPaymentMethod",
  ShowCreditCardTerminalIncludeInactiveToggle = "ShowCreditCardTerminalIncludeInactiveToggle",
  ShowCreditCardTerminalMerchantNumber = "ShowCreditCardTerminalMerchantNumber",
  ShowCreditCardTerminalOtherInfo = "ShowCreditCardTerminalOtherInfo",
  GenerateRandomExternalPosId = "GenerateRandomExternalPosId",
  ShowOutletSelectionFieldInShopScreen = "ShowOutletSelectionFieldInShopScreen",
  ShowRetailSetupSelectOutletField = "ShowRetailSetupSelectOutletField",
  ShowOpenRetailTransactionSelectOutletField = "ShowOpenRetailTransactionSelectOutletField",
  ShowCorrectOrVoidRetailTransactionSelectOutletField = "ShowCorrectOrVoidRetailTransactionSelectOutletField",
  ShowReturnWithTicketRetailTransactionSelectOutletField = "ShowReturnWithTicketRetailTransactionSelectOutletField",
  ShowReprintRetailTransactionSelectOutletField = "ShowReprintRetailTransactionSelectOutletField",
  ShowModifyCommissionRetailTransactionSelectOutletField = "ShowModifyCommissionRetailTransactionSelectOutletField",
  ShowOutletLabelOnRetailItemCreationGeneralTab = "ShowOutletLabelOnRetailItemCreationGeneralTab",
  ShowOutletLabelOnRetailItemCreationInventoryTab = "ShowOutletLabelOnRetailItemCreationInventoryTab",
  ShowOutletFieldInReturnWithTicketTransactionGrid = 'ShowOutletFieldInReturnWithTicketTransactionGrid',
  ShowOutletFieldInReturnWithOutTicketTransactionGrid = 'ShowOutletFielInReturnWithOutTicketTransactionGrid',
  ShowOutletFieldInOpenTransactionGrid = 'ShowOutletFieldInOpenTransactionGrid',
  ShowOutletFieldInCorrectVoidTransactionGrid = 'ShowOutletFieldInCorrectVoidTransactionGrid',
  ShowOutletSelectionFieldInCommisionSetupScreen = 'ShowOutletSelectionFieldInCommisionSetupScreen',
  ShowOutletSelectionFieldInCommisionTabOnRetailItemCreationScreen = 'ShowOutletSelectionFieldInCommisionTabOnRetailItemCreationScreen',
  ShowOutletFieldInCommisionSetupGrid = 'ShowOutletFieldInCommisionSetupGrid',
  ShowOutletFieldInCommisionGridOnRetailItemCreationScreen = 'ShowOutletFieldInCommisionGridOnRetailItemCreationScreen',
  ShowRetailSetupRoomTypesAssignmentTab = 'ShowRetailSetupRoomTypesAssignmentTab',
  ShowOutletSelectionFieldInSalesMixReport = "ShowOutletSelectionFieldInSalesMixReport",
  ShowOutletSelectionFieldInventoryReport = "ShowOutletSelectionFieldInventoryReport",
  ShowOutletFieldInQuickSaleSetupScreen = 'ShowOutletFieldInQuickSaleSetupScreen',
  PrintCaddyShackReceipt = "PrintCaddyShackReceipt",
  ShowMultipleLinkedRetailItemForMultipack = "ShowMultipleLinkedRetailItemForMultipack",
  ApplyRevisedMultiPackLogic = "ApplyRevisedMultiPackLogic",
  ShowNoOfRecordInGrid="ShowNoOfRecordInGrid",
  ShowDefaultToggleInOutletCreation = "ShowDefaultToggleInOutletCreation",
  ShowDefaultToggleInCreditCardTerminalCreation = "ShowDefaultToggleInCreditCardTerminalCreation",
  ShowTherapistForAddingGratSvcFromFolio = "ShowTherapistForAddingGratSvcFromFolio",
  ShowAgilysysEngageIntegrationToggle = "ShowAgilysysEngageIntegrationToggle",
  ShowEnableExtendedProfileSearchByDefault = "ShowEnableExtendedProfileSearchByDefault",
  ShowRentalsRetailItemType = "ShowRentalsRetailItemType",
  ShowMultiPackRedeemButtonInShopScreen = "ShowMultiPackRedeemButtonInShopScreen",
  ShowOutletAddressToggleInOutletCreation = "ShowOutletAddressToggleInOutletCreation",
  ShowOutletFieldInNonIntegratedPaymentLog = "ShowOutletFieldInNonIntegratedPaymentLog",
  ShowMorePhoneTypes = "ShowMorePhoneTypes"
}

export const MiscellaneousSwitch = {
  EXCLUDE_DISCOUNT_ON_SERVICE_CHARGE: "EXCLUDE_DISCOUNT_ON_SERVICE_CHARGE",
  EXCLUDE_DISCOUNT_ON_GRATUITY: "EXCLUDE_DISCOUNT_ON_GRATUITY",
  DISCOUNT_REASON_REQUIRED: "DISCOUNT_REASON_REQUIRED",
  DISCOUNT_COMMENTS_REQUIRED: "DISCOUNT_COMMENTS_REQUIRED",
  WEBCOMMUNICATION_PROXY_ENDPOINT: "WEBCOMMUNICATION_PROXY_ENDPOINT",
  SHOP_SCREEN_ITEM_LOAD_COUNT: "SHOP_SCREEN_ITEM_LOAD_COUNT",
  RETURN_ORIGINAL_PAYMENT_ONLY: "RETURN_ORIGINAL_PAYMENT_ONLY",
  ENABLE_CONFIRMATION_ON_DELETE: "ENABLE_CONFIRMATION_ON_DELETE",
  TRANSACTION_BY_MACHINENAME: "TRANSACTION_BY_MACHINENAME",
  SELECTION_ON_LOGIN: "SELECTION_ON_LOGIN",
  ENABLE_ROOM_KEY_SWIPE_WITH_MSR: "ENABLE_ROOM_KEY_SWIPE_WITH_MSR",
  ROOM_KEY_CARD_DATA_SOURCE_TYPE: "ROOM_KEY_CARD_DATA_SOURCE_TYPE",
  ROOM_KEY_CARD_DATA_START_POSITION: "ROOM_KEY_CARD_DATA_START_POSITION",
  ROOM_KEY_CARD_DATA_END_POSITION: "ROOM_KEY_CARD_DATA_END_POSITION",
  ENABLE_CHECKZOOM: "ENABLE_CHECKZOOM",
  RESTRICT_DAYENDPROCESSWITHFAILEDREVENUEPOSTINGS: "RESTRICT_DAYENDPROCESSWITHFAILEDREVENUEPOSTINGS",
  HOUSE_SERVICECHARGE_PERCENTAGE: "HOUSE_SERVICECHARGE_PERCENTAGE",
  HOUSE_GRATUITY_PERCENTAGE: "HOUSE_GRATUITY_PERCENTAGE",
  ALLOW_DATE_FOR_FUTURE: "ALLOW_DATE_FOR_FUTURE",
  EXPAND_PAYMENT_METHODS: "EXPAND_PAYMENT_METHODS",
  CASH_POPUP_ZERO_DOLLAR_POPULATE: "CASH_POPUP_ZERO_DOLLAR_POPULATE",
  ITEM_NAME_DELETE_OPENITEM_POPULATE: "ITEM_NAME_DELETE_OPENITEM_POPULATE",
  REQUEST_PRESETTLEMENT_RECEIPT_ON_PENDING_SETTLEMENT: "REQUEST_PRESETTLEMENT_RECEIPT_ON_PENDING_SETTLEMENT",
  DISPLAY_IMAGES_IN_SHOP: "DISPLAY_IMAGES_IN_SHOP",
  POST_REVENUE_ON_PROPERTY_DATE: "POST_REVENUE_ON_PROPERTY_DATE",
  ALLOW_TAX_ON_SERVICECHARGE: "ALLOW_TAX_ON_SERVICECHARGE",
  ALLOW_TAX_ON_GRATUITY: "ALLOW_TAX_ON_GRATUITY",
  ENABLE_AUTO_RETAIL_TRANSACTION_LOG: "ENABLE_AUTO_RETAIL_TRANSACTION_LOG",
  ALLOW_PAYMENT_SURCHARGE: "ALLOW_PAYMENT_SURCHARGE",
  ENABLE_AUTO_CLOSE_TRANSACTION: "ENABLE_AUTO_CLOSE_TRANSACTION",
  VALIDATE_PAYAGENT_AND_SETTLER_VERSION_MISMATCH_ON_REFUND: "VALIDATE_PAYAGENT_AND_SETTLER_VERSION_MISMATCH_ON_REFUND",
  DEFAULT_REFUND_TO_ORIGINAL_SETTLEMENTS_ONLY: "DEFAULT_RETURN_TO_ORIGINAL_SETTLEMENTS_ONLY",
  BUSINESS_UNIT_VALUE: "GL_MAPPING_BUSINESS_UNIT",
  OPERATING_UNIT_VALUE: "GL_MAPPING_OPERATING_UNIT",
  REQUIRE_RETURN_REASON: "REQUIRE_RETURN_REASON",
  REQUIRE_CUSTOM_FEE: "REQUIRE_CUSTOM_FEE",
  EXCLUDE_DISCOUNT_ON_TAX: "EXCLUDE_DISCOUNT_ON_TAX",
  ENABLE_EXTENDED_PROFILE_SEARCH_BY_DEFAULT: "ENABLE_EXTENDED_PROFILE_SEARCH_BY_DEFAULT",
  RENTAL_ITEM_QUANTITY_AT_OUTLETLEVEL:"RENTAL_ITEM_QUANTITY_AT_OUTLETLEVEL"
}

export enum OpenTransactionAction {
  Settle,
  Reopen,
  Cancel
}

export const PaymentMethodValue = {
  1: "Cash",
  2: "Card",
  3: "Room Charge",
  4: "Pending Settlement",
  5: "IDTech SRED Key",
  6: "Rain Check",
  7: "AR Account",
  8: "Redeem Point",
  9: "Credit Book",
  10: "Comp Redemption",
  11: "Offer Redemption",
  12: "V1 GiftCard",
  13: "3rd Party GiftCard",
  14: "V1 GiftCard IdTech",
  15: "3rd Party GiftCard IdTech",
  16: "City Ledger",
  17: "Agilysys GiftCard",
  18: "Agilysys GiftCard IdTech",
  19: "Comp Slip Redemption",
  20: "Group Charge",
  21: "Post To Folio",
  24: "Wallet",
  25: "Hotel Comp",
  26: "Non Integrated Credit Card"
}

export enum V1GiftcardErrorCodes {
  'Success', 'InactiveCard', 'InvalidCardNumber', 'InvalidTransaction', 'InsufficientFunds', 'NoPreviousTransaction', 'DuplicateTransaction', 'NoCardFound', 'InsufficientFundsDueToOutstandingAuthorization', 'DenialNoPreviousAuthorization', 'MaximumSingleRechargeExceeded', 'MaximumBalanceExceeded', 'VendorNetworkUnavailable', 'CardAlreadyActivated', 'UnknownProfitCenter', 'MaxiumumNumberOfRechargesExceeded', 'InvalidCardVerificationValue', 'InvalidPin', 'CardAlreadyIssued', 'CardNotIssued', 'CardAlreadyUsed', 'ManualTransactionNotAllowed', 'CardSwipeNotAllowed', 'PinLocked', 'MaximumNumberOfRedemptionsExceeded', 'InvalidCurrencyCode', 'FrozenCard', 'InvalidActivationAmount', 'CardAlreadyInactive', 'CardAlreadyActive', 'AuthorizationAlreadyPending', 'CardAlreadySold', 'CardNotSold', 'Unsupported', 'AdapterFailure', 'VendorFailure', 'UnknownFailure'
}

export interface DefaultSettings {
  id: number;
  switch: string;
  switchType: string;
  value: string;
}

export const DefaultBucketName = {
  memberBucketName: "clubdues",
  creditBookBucketName: "creditbook"
}

export enum CreditCardIssuerType {
  MaestroCard = 1,
  VisaCard,
  MasterCard,
  AmericanExpressCard,
  DiscoverCard
}

export const CardIssuerIconMap = {
  [CreditCardIssuerType.MaestroCard]: "maestro",
  [CreditCardIssuerType.VisaCard]: "visa",
  [CreditCardIssuerType.MasterCard]: "mastercard",
  [CreditCardIssuerType.AmericanExpressCard]: "americanexpress",
  [CreditCardIssuerType.DiscoverCard]: "discover",
}
export const Menu = {
  unPaidPlayerElementId: 2022
}
export interface CreditCardIssuerConfig {
  name: string,
  type: string,
  isPercentage: boolean,
  surchargeValue: number,
  isActive: boolean,
  checked: boolean;
  paymentMethodId:number;
}

export enum clientSearchType {
  firstName,
  lastName,
  name,
  phone,
  email,
  patronId,
  All
}
export enum JasperReportUrlPath {
  SalesByCategory = '/public/Retail/Retail/SalesByCategory/SaleByCategoryReport',
  CorrectVoid = '/public/Retail/Retail/CorrectVoid/CorrectVoid_Report',
  InventoryOnHand = '/public/Retail/Retail/InventoryOnHand/Inventory_On_Hand',
  StaffSummaryReport = '/public/Retail/POS/StaffMemberSummary/Staff_Member_Summary_Report'
}

export enum ResortFinancePaymentMethods{
  Cash = 1,
  CreditCard = 2,
  RoomCharge = 3,
  ARPost = 7,
  CreditBook = 9,
  ARAcctPost = 16,
  AgilysysGiftCard = 17,
  AgilysysGiftCardIdTech = 18,
  ExternalGiftCard = 13,
  ExternalGiftCardIdTech = 15
}

export const ResortFinanceDefaultPostId = -1;
export const  GatewayDeviceConfiguration = 'GatewayDeviceConfiguration';
export const InvokeAgilysysPayViaWebProxy = "InvokeAgilysysPayViaWebProxy";
export const CancellationTimeInSeconds = "CancellationTimeInSeconds";
export const CardDataSourceType = {
  track1: "track1",
  track2: "track2",
  accountnumber: "accountnumber"
}

export enum RetailInterfaceSourceTypes {
  RETAIL = 0,
  SPA = 1,
  GOLF = 2,
  SNC = 4,
  PMS = 5,
  ACCOUNTING = 6,
  EXTERNALSYSTEM = 7
}

export enum AutoRetailTransactionType {
  SPAAppoinmentCheckout = 1,
  GolfTeeTimeCheckout = 2,
  RetailShopSettleOpenTransaction = 3,
  RetailShopCreateTransaction = 4,
  RetailShopReturnWithTicketTransaction = 5,
  RetailShopReturnWithOutTicketTransaction = 6,
  RetailShopVoidTransaction = 7,
  DepositRefund = 8,
  GolfMarkAsPaidCreateTransaction = 9,
  GolfMarkAsPaidSettleTransaction = 10,
  GolfMarkAsRefundTransaction = 11
}

export enum SourceSystem {
  Internal = 0,
  Eatec = 1
}

export const issettleratpropertylevel = 'issettleratpropertylevel';
export const ReceiptFormat = {
  text: "TEXT",
  pdf: "PDF"
}
export enum CustomFeeSourceType{
  None = 0,
  Appointment = 1,
  TeeTime = 2,
  RetailItem = 3, // for appt add ons and retail items. Source Type Id would be the lineNumber of source item
  Package = 4,
  Classes = 5
}

export const NonIntegratedCreditCardPaymentStatus = {
  Completed: "Completed",
  Realized: "Realized",
  Reversed: "Reversed",
  Failed: "Failed",
}

export const NonIntegratedCreditCardState = {
  Completed: 1,
  Realized: 2,
  Failed: 3,
  Success:4,
  Reversed:5
}
export enum  DayPassStatus{
  Open=1,
  Success=2
}

export const MAXLENGTH = {
  PERCENTAGE: 3,
  AMOUNT: 12
}

export enum DiscountType {
  Cost = -1,
  CostPlus = -2
}

export const DiscountName = {
  Cost: "COST DISC",
  CostPlus: "COST + {value} DISC"
}

export const ProcessDigitalInvoiceRealTime = "ProcessDigitalInvoiceRealTime";
export const DefaultSigCapPollingTimeInSeconds = 2;
export const DefaultSigCapCancellationTimeInSeconds = 90;