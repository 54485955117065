import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { DataImportWorkFlow, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';

export const DataImportWexFlowsModuleConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine): any => {
    try {   
        const dataImportModel = {} as DataImportWorkFlow;
        dataImportModel.wexflowAuth = sessionStorage.getItem('WexFlowLogin');
        dataImportModel.identityAuth = sessionStorage.getItem('AccessToken');
        dataImportModel.categoryfilter = true;
        const PostData = Object.assign(dataImportModel);
        return PostData;
    } catch (error) {
        console.error('Error occurred in Data Import Upload Construction', error);
    }
};
