import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intermediateCheck'
})
export class IntermediateCheckPipe implements PipeTransform {

  transform(value: unknown, key: string, ListObject: Array<any>, type: string): unknown {
    if (type === 'intermediate') {
      const returnValue = ListObject.some(x => x[key] === true) && !ListObject.every(x => x[key] ===  true);
      return returnValue;
    } else {
      const returnValue = ListObject.every(x => x[key] ===  true);
      return returnValue;
    }
  }

}
