import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AddNewTypeConfig, ItemQueryListConfiguaration, LookupConfiguration, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { QueryParams } from '../api-query-params.interface';
import { QueryConfig, QueryLookupConfig } from './query-list-config.interface';
import _ from 'lodash';
import { QueryTransaction } from 'src/app/eatecui/source/transaction/shared/interface/query-details';

export const QueryListLookup: QueryLookupConfig = async ( addNewTypeConfig: AddNewTypeConfig,  
transactionConfigurationData: TransactionConfiguration, httpService: HttpService, translateService: TranslateService, 
headerEntity: any, detailEntity: any): Promise<any> => {
    try {
        const params = {} as QueryParams;
        const jsondata = transactionConfigurationData;
        const searchByKey = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds;
        const searchListArray = jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList;
        let endPointUrl = `/inventory/api/${addNewTypeConfig.EndPointUrl}`;
        const lookUpData = {} as LookupConfiguration;
        lookUpData.Id = 0;
        lookUpData.TransType = jsondata.TransactionType;
        if (jsondata.CreateTranscationConfig.TransactionFormConfig) {
            lookUpData.FromLocationId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
            lookUpData.ToLocationId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'];
        }
        if (addNewTypeConfig.Name === 'Class') {
            lookUpData.ClassIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Type') {
            lookUpData.TypeIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Packages') {
            if (jsondata.Name !== 'Recipes') {
                lookUpData.PackageIds = searchByKey;
            } else {
                if (searchByKey && searchByKey.length > 0) {
                    const packageIdList = [];
                    searchByKey.forEach(x => {
                        packageIdList.push(x.Id);
                    });
                    lookUpData.PackageIds = [...packageIdList];
                }
            }
        }
        if (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup') {
            lookUpData.itemType = addNewTypeConfig.DefaultTypeId ? addNewTypeConfig.DefaultTypeId : addNewTypeConfig.TypeId;
            lookUpData.ModuleId = 7; // Added for product module
        }
        const ListType = ( addNewTypeConfig.hasOwnProperty('DefaultTypeId') && addNewTypeConfig.DefaultTypeId !== '') ? 
        addNewTypeConfig.DefaultTypeId : addNewTypeConfig.TypeId;
        let ListName = translateService.instant('Ingredients.Ingredients');
         switch (ListType) {
                case 1: 
                ListName = translateService.instant('Ingredients.Ingredients'); 
                break;
                case 2: 
                ListName = translateService.instant('Ingredients.Receipe'); 
                break;
                case 5: 
                ListName = 
                translateService.instant('Customer.Customer'); 
                break;
                case 6: ListName = 
                translateService.instant('Suppliers.Supplier'); 
                break;
                case 7: 
                ListName = translateService.instant('Products.Products'); 
                break;
            }
            if ( ( jsondata.Name === 'Packages' && jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 1 &&
            jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 7) || 
                 jsondata.Name === 'Ingredients') {
                    if (addNewTypeConfig.Name === 'Packages') {
                        const Data = [];
                        searchByKey.forEach(e => {
                          e.PackLists.forEach(element => {
                            Data.push(element.Item);
                          });
                        });
            
                        const seleId1 = `${addNewTypeConfig.QueryString} (` + `${Data.join()})`;
                        const GetDataFilter1 = encodeURIComponent(`${seleId1}`).replace(/[!'()*]/g, function (c) {
                          return '%' + c.charCodeAt(0).toString(16);
                        });
                        params.filterQuery = GetDataFilter1;
                        if (addNewTypeConfig.QueryExpand) {
                            endPointUrl =  endPointUrl + `?$filter=${params.filterQuery}&$expand=${addNewTypeConfig.QueryExpand}`;
                        } else {
                            endPointUrl =  endPointUrl + `?$filter=${params.filterQuery}`;
                        }
                      } else {
                        const seleId = `${addNewTypeConfig.QueryString} (` + `${searchByKey.join()})`;
                        endPointUrl =  endPointUrl + `?$filter=${seleId}`;
                        if (addNewTypeConfig.QueryExpand) {
                          endPointUrl = endPointUrl + `&$expand=${addNewTypeConfig.QueryExpand}`;
                        }
                      }
                      const x = await httpService.GethttpMethod(endPointUrl).toPromise();
                      const responseData = x['value'];
                        let UrlName = addNewTypeConfig.Url === 'CustomerTypes' ? 'CustomerTypeId' : addNewTypeConfig.Url;
                        if (addNewTypeConfig.Url === 'Classes') {
                            UrlName = jsondata.CreateTranscationConfig.TransactionFormConfig &&
                            jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] === 7 ? 'ProductsClassId'
                             : 'SupplierClass';
                        } else if (addNewTypeConfig.Url === 'ProductTypes') {
                            UrlName = 'ProductsTypeId';
                        }
    
                        if (addNewTypeConfig.EndPointUrl === 'Recipes' && addNewTypeConfig.Url === 'Classes') {
                            UrlName = 'RcpClassId';
                        }
                        if (addNewTypeConfig.EndPointUrl === 'Recipes' && addNewTypeConfig.Url === 'RecipeTypes') {
                            UrlName = 'RcpTypeId';
                        }
                        if (jsondata.CreateTranscationConfig.TransactionFormConfig) {
                            responseData.forEach(element => {
                                if (jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] === 5) {
                                    element['IngredientTypes'] = element['CustomerTypeId'] ? element['CustomerTypeId'].Name : '';
                                }
                                if (jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] === 6) {
                                    element['Classes'] = element['SupplierClass'] ? element['SupplierClass'].Name : '';
                                    element['IngredientTypes'] = element['SupplierTypes'] ? element['SupplierTypes'].Name : '';
                                }
                            });
                        }
                        let groupedData = [];
                        if (addNewTypeConfig.Name === 'Packages') {
                          searchByKey.forEach(key => {
                            const PackageId = key.PackLists.map(y => {
                              return y.Item;
                            });
                            const ItemList = responseData.filter(value => PackageId.includes(value.Id));
                            groupedData.push({
                              Id: key.Id,
                              Name: key.Name,
                              ItemList: ItemList
                            });
                          });
                        } else {
                          groupedData = _(responseData)
                          .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                          .map((ItemList: any, Id: any) => ({
                              Id: parseInt(Id, 10),
                              ItemList,
                              Name: ItemList[0][UrlName]['Name']
                          }))
                          .value();
                        }
                        if (groupedData.length < searchByKey.length) {
                            searchListArray.forEach(key => {
                                if (groupedData.findIndex(z => z.Id === key.Id) === -1) {
                                    groupedData.push({
                                        Id: key.Id,
                                        ItemList: [],
                                        Name: key.Name
                                    });
                                }
                            });
                        }
                        if (groupedData) {
                            const itemHeaderList = [];
                            const values = Object.keys(groupedData);
                            groupedData.forEach((data: any, Index: number) => {
                                const itemQueryListConfiguaration = {} as ItemQueryListConfiguaration;
                                itemQueryListConfiguaration.DisplayName = data.Name;
                                itemQueryListConfiguaration.Enable = true;
                                itemQueryListConfiguaration.IconName = 'Na';
                                itemQueryListConfiguaration.Id = searchByKey[Index];
                                itemQueryListConfiguaration.ItemSelected = [];
                                itemQueryListConfiguaration.Name = data.Name;
                                itemQueryListConfiguaration.RoutingLinkUrl = addNewTypeConfig.Url;
                                itemQueryListConfiguaration.ShowInEllipsis = false;
                                itemQueryListConfiguaration.Type = 'grid';
                                itemQueryListConfiguaration.listAuthorized = true;
                                const GridDataRows = QueryTransaction.QueryIngredientsGridData(detailEntity, 
                                detailEntity, jsondata, httpService, translateService, '', data.ItemList);
                                GridDataRows.forEach(element => {
                                element['GridId'] = Index + '_' + (element.Id ? element.Id : element.IngredientId);
                                });
                                itemQueryListConfiguaration.listGridData = {
                                    GridName: 'Item List',
                                    GridOdataKey: 'Requisition',
                                    ShowActiveToggle: false,
                                    ShowAddNew: false,
                                    GridTitleType: {
                                        Type: 'text',
                                        TypeConfig: [
                                            {
                                                Id: 1,
                                                Name: 'Item List',
                                                DisplayName: 'Item List'
                                            }
                                        ]
    
                                    },
                                    GridFilter: [],
                                    GridSort: [],
                                    GridSearch: [],
                                    GridColumnHide: [],
                                    GridData: GridDataRows,
                                    GridHeaderDefination: QueryTransaction.QueryGridHeaders( detailEntity, detailEntity, jsondata, 
                                    httpService, translateService, data.ItemList),
                                    GridActions: {
                                        GridTitle: `${ListName} List`,
                                        TotalItems: data.ItemList.length,
                                        InfiniteScrollConfig: {
                                            EnableInfiniteScroll: false,
                                            TotalRecords: data.ItemList.length
                                        },
                                        ResizeRowsConfig: {
                                            EnableResizeRows: false
                                        },
                                        ShuffleColumnConfig: {
                                            EnableColumnShuffle: false
                                        }
                                    },
                                    // rowChecked: (value: Array<any>) => {
                                    //     // this.selectedItemList[this.selectedIndex] = value;
                                    // },
                                    searchTextChanged: (searchText) => {
                                        // this.searchTextChange(searchText);
                                    },
                                    sortChanged: (value) => {
                                        // this.sortChange(value);
                                    }
                                },
                                    itemHeaderList.push(itemQueryListConfiguaration);
                            });
                            jsondata.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.
                                ItemQueryListConfig = [...itemHeaderList];
                            const originalQueryTransaction = _.cloneDeep(jsondata);
                            const sortvalues = [
                              {​
                                  colId: 'Name',
                                  sort: 'asc'
                              }​
                          ];
                        //   this.sortChange(sortvalues);
                        }

            } else {
                const x = await httpService.PosthttpMethod(endPointUrl, lookUpData).toPromise();
                    const resultSet = addNewTypeConfig.Name === 'Packages' ? x['Data']['ent']['PackageIds']
                    : x['Data']['ent']['ResultData'];
                    // if (resultSet.length > 0) {
                        const responseDatas = [];
                        const itemHeaderList = [];
                        let resultData = [];
                        let values;
                        if (addNewTypeConfig.Name !== 'Packages') {
                            resultSet.forEach((element: any) => {
                                const dataObject = _.mapKeys(element, function (v, k) {
                                    return k.charAt(0).toUpperCase() + k.slice(1);
                                });
                                responseDatas.push(dataObject);
                            });
                            if (addNewTypeConfig.EndPointUrl === 'IngredientLookup') {
                                const PropertyName = addNewTypeConfig.Url === 'Classes' ? 'ClassName' : 'TypeName';
                                values = _(responseDatas)
                                    .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                                    .map((ItemList: any, Id: any) => ({
                                        Id: parseInt(Id, 10),
                                        ItemList,
                                        Name: ItemList[0][PropertyName]
                                    }))
                                    .value();
                            } else {
                            values = _(responseDatas)
                                .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                                .map((ItemList: any, Id: any) => ({
                                    Id: parseInt(Id, 10),
                                    ItemList,
                                    Name: ItemList[0][addNewTypeConfig.Url]['Name']
                                }))
                                .value();
                            }
                        } else {
                            resultData = x['Data']['ent']['PackageResults'];
                            values = Object.keys(resultData);
                        }
                        if (values.length < searchByKey.length) {
                            if (addNewTypeConfig.Name !== 'Packages') {
                                searchListArray.forEach(key => {
                                    if (values.findIndex(z => z.Id === key.Id) === -1) {
                                        values.push({
                                            Id: key.Id,
                                            ItemList: [],
                                            Name: key.Name
                                        });
                                    }
                                });
                            } else {
                                searchListArray.forEach(key => {
                                    if (values.findIndex(z => z === key.Name) === -1) {
                                        values.push(key.Name);
                                    }
                                });
                            }
                        }
                        values.forEach((data: any, Index: number) => {
                            const itemQueryListConfiguaration = {} as ItemQueryListConfiguaration;
                            itemQueryListConfiguaration.DisplayName = addNewTypeConfig.Name === 'Packages' ? data : data.Name;
                            itemQueryListConfiguaration.Enable = true;
                            itemQueryListConfiguaration.IconName = 'Na';
                            itemQueryListConfiguaration.Id = addNewTypeConfig.Name === 'Packages' ? data : data['id'];
                            itemQueryListConfiguaration.ItemSelected = [];
                            itemQueryListConfiguaration.Name = addNewTypeConfig.Name === 'Packages' ? data : data.Name;
                            itemQueryListConfiguaration.RoutingLinkUrl = addNewTypeConfig.Url;
                            itemQueryListConfiguaration.ShowInEllipsis = false;
                            itemQueryListConfiguaration.Type = 'grid';
                            itemQueryListConfiguaration.listAuthorized = true;
                            const ItemList = addNewTypeConfig.Name === 'Packages' ? (resultData[data] ? resultData[data] : []) : 
                            data.ItemList;
                            const GridDataRows = QueryTransaction.QueryIngredientsGridData(detailEntity, detailEntity, jsondata, 
                            httpService, translateService, '', ItemList);
                            GridDataRows.forEach(element => {
                                    element['GridId'] = Index + '_' + (element.Id ? element.Id : element.IngredientId);
                                });
                            itemQueryListConfiguaration.listGridData = {
                                GridName: 'Item List',
                                GridOdataKey: 'Requisition',
                                ShowActiveToggle: false,
                                ShowAddNew: false,
                                GridTitleType: {
                                    Type: 'text',
                                    TypeConfig: [
                                        {
                                            Id: 1,
                                            Name: 'Item List',
                                            DisplayName: 'Item List'
                                        }
                                    ]

                                },
                                GridFilter: [],
                                GridSort: [],
                                GridSearch: [],
                                GridColumnHide: [],
                                GridData: GridDataRows,
                                GridHeaderDefination: QueryTransaction.QueryGridHeaders( detailEntity, detailEntity, jsondata, 
                                httpService, translateService, data),
                                GridActions: {
                                    GridTitle: `${ListName} List`,
                                    TotalItems: ItemList ? ItemList.length : 0,
                                    InfiniteScrollConfig: {
                                        EnableInfiniteScroll: false,
                                        TotalRecords: ItemList ? ItemList.length : 0
                                    },
                                    ResizeRowsConfig: {
                                        EnableResizeRows: false
                                    },
                                    ShuffleColumnConfig: {
                                        EnableColumnShuffle: false
                                    },
                                    FilterConfig: {
                                        EnableFilter: false
                                    }
                                },
                                rowChecked: (value: Array<any>) => {
                                    // this.selectedItemList[this.selectedIndex] = value;
                                },
                                searchTextChanged: (searchText) => {
                                    // this.searchTextChange(searchText);
                                },
                                sortChanged: (value) => {
                                    // this.sortChange(value);
                                },
                            };
                            itemHeaderList.push(itemQueryListConfiguaration);
                        });
                        jsondata.CreateTranscationConfig.ItemsConfig.ItemQueryConfig
                            .ItemQueryListConfig = [...itemHeaderList];
                        const originalQueryTransaction = _.cloneDeep(jsondata);
                        const sortvalues = [
                            {​
                                colId: 'Name',
                                sort: 'asc'
                            }​
                        ];
                        // this.sortChange(sortvalues);
                    // }
            }
            const ItemQueryListConfig = jsondata.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig;
       
        return jsondata;
    } catch (error) {
        console.error(error);
    }
};