import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AgilysysConfirmationPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-confirmation-popup/agilysys-confirmation-popup.component';
import { AgilysysSuggestqtyPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-suggestqty-popup/agilysys-suggestqty-popup.component';
import { ConfirmPopModel, ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import { PoBackOrderOptions } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import _ from 'lodash';
import { AgilysysPartialTransferComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-partial-transfer/agilysys-partial-transfer.component';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';


export const ZeroCosting: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType ?: any,
    ModuleKey ?: string,
    dialog ?: MatDialog,
    transactionCollection ?: TransactionConfiguration,
    homeGroup ?: FormGroup
): any => {
    try {
        return new Promise<boolean>((resolve, reject) => {
            const POBackOrderOptions = false;
            const gridData = transactionCollection.CreateTranscationConfig.
                ItemsConfig.ItemList.ItemGridConfig.GridData;
            const ingredientName = transactionCollection.CopyAsNewConfig.CopyNewItemListConfig
                                        .ItemListProperty.filter(x => x.OdataKey === transactionCollection.OdataKey)[0].UnitsName;
            if ( GlobalPreference && GlobalPreference.length > 0) {
               let shopopup = false;
               const normalCosting = gridData.filter(x => x.PostPhyOnZeroCost ? x.PostPhyOnZeroCost === 1 
                                                    : (x[ingredientName] && x[ingredientName].IngredientsOtherDetails
                                                        ? x[ingredientName].IngredientsOtherDetails.PostPhyOnZeroCost === 1 : 
                                                        (x.IngredientsOtherDetails ? x.IngredientsOtherDetails.PostPhyOnZeroCost === 1 
                                                            : false)));
               // let zeroCosting = gridData.filter(x => x.PostPhyOnZeroCost && x.PostPhyOnZeroCost === 2);
               if (normalCosting.length > 0) {
                    const zeroCostItem = normalCosting.some(x => parseFloat(x.UnitCost) === parseFloat('0') 
                                                    && parseFloat(x.Qty) > parseFloat('0'));
                    if (zeroCostItem) {
                        shopopup = true;
                    }
                }
    
                if (shopopup) {
                    const ConfirmPopUpDialog: ConfirmPopModel = {
                        PopupHeaderContent: 'Validation',
                        PopUpContent: 'Transaction failed to post as there are item(s) with value calculated to \'0\'. Please review the item list',
                      };
                    const dialogRef = dialog.open(AgilsysErrorPopComponent, {
                        width: '400px',
                        height: '222px',
                        data: ConfirmPopUpDialog,
                        disableClose: true
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result === 'Ok') {
                            resolve(false);
                        }
                    });                
                } else {
                    resolve(true);
                }
               }
        });
        
       
    } catch (error) {
        console.error(error);
    }
};