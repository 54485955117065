import { Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgTimeConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { AddLockersService } from './add-lockers.service';
import { LockerAssignmentDetails, LockerAssignmentDetailsTranslog, LockerAvailability, LockerAvailabilityRequest, LockerBookingSourceType } from './lockers.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { appointmentService } from '../service/appointment.service';
import { AddCommentsComponent } from '../add-comments/add-comments.component';
import { AppointmentFilterRequest } from '../shared.modal';
import * as HostEnum from 'src/app/retail/shared/globalsContant';
import { ImageProcessorService } from 'src/app/shared/service/image-processor-service';
import { BaseResponse } from '../shared.modal';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { LogEntry } from '../business/shared.modals';
import { AppointmentUtilities } from '../utilities/appointment-utilities';
import { LockerAppointmentConstant } from '../globalsContant';
import {cloneDeep} from "lodash";
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { SelectedProducts,AutoRetailTransactionRequestModel } from 'src/app/retail/shared/shared.modal';
import { RedirectToModules } from '../utilities/spa-utilities';
import { SpaUtilities } from '../utilities/spa-utilities';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { SelectOutletModalComponent } from '../select-outlet-modal/select-outlet-modal.component';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { AutoRetailTransactionBusiness } from 'src/app/retail/shared/business/auto-retailtransaction.business';
import { AutoRetailTransactionType, CustomFeeSourceType, RetailBreakPoint, RetailInterfaceSourceTypes } from "src/app/retail/shared/globalsContant";
import { PaymentMethods } from "src/app/retail/shared/business/shared.modals";
import { FolioInfo } from "src/app/retail/shared/service/common-variables.service";
import { SourceType } from "src/app/retail/Folio/Model/folioDetails-model";
import { FolioBusiness } from 'src/app/retail/Folio/folio-business';
import { BulkCreateResortFinanceFoliosRequest } from 'src/app/retail/Folio/Model/folioDetails-model';
import { ClientService } from '../service/client-service.service';

@Component({
  selector: 'app-add-lockers',
  templateUrl: './add-lockers.component.html',
  styleUrls: ['./add-lockers.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddLockersComponent implements OnInit {

  @Input() popupData: any;
  lockerForm: UntypedFormGroup;
  searchPlaceHolder: any;
  captions: any;
  startTimeInput: AgTimeConfig;
  endTimeInput: AgTimeConfig;
  availableItem: any = null;
  isDisplayImagesInShop: boolean = true;
  currencySymbol: string;
  selectedItem = null;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  removeButton: ButtonValue;
  payButton: ButtonValue;
  postToFolioButton: ButtonValue;
  appointmentInformation: any
  @Input() from;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  headerdata: string;
  isFromClient: boolean;
  isFromClass : boolean;
  minDate;
  maxDate;
  floatLabel: string;
  minStartTime: any;
  maxEndTime: any;
  allAvailableItem: any;
  availableLocker: any;
  showLockerSection: boolean;
  appointmentDate: string;
  lockerComment: string;
  timeFormat: any;
  restrictLockerAvailabiltyCall: boolean = false;
  lockerAssignmentDetailsId: number = 0;
  isEdit: boolean = false;
  includeLockerAssignment: Boolean;
  assignLocker: boolean;
  maxDecimalPlaces: any;
  allowOnlyRemove: boolean = false;
  showAssignedLocker:boolean = true;
  oldValueTranslogForLockerAssignmentDetail : any;
  userMachineConfig: any;
  bookingTypeId: number;
  lockerAssignmentaDetail: any;
  clientInfo:any;
  classClientInfo:any;
  classDate:any;
  assignedLockerStartTime:any;
  assignedLockerEndTime:any;
  postToFolio: boolean = false;
  isTimeChange : boolean = false;
  commentCaption: any;
  
  constructor(public localization: Localization, public dialog: MatDialog, private fb: UntypedFormBuilder, private lockerService: AddLockersService,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AddLockersComponent>,
    private apptService: appointmentService,
    private commonUtils: CommonUtilities, private imgService: ImageProcessorService
    , public _featureFlagInfo: RetailFeatureFlagInformationService, private appointmentUtils: AppointmentUtilities
    , public propertyInfo: CommonPropertyInformation
    , private retailSharedService: RetailSharedVariableService
    , private utils: SpaUtilities
    , private retailUtilities: RetailUtilities
    ,public retailPropertyInfo: RetailPropertyInformation
    ,private autoRetailTransactionBusiness:AutoRetailTransactionBusiness
    ,private _folioBussiness:FolioBusiness
    ,private _clientService: ClientService) {
    this.captions = this.localization.captions;
    this.currencySymbol = this.localization.currencySymbol;
    this.appointmentInformation = this.data?.appointmentInfomation;
    this.floatLabel = this.localization.setFloatLabel;
    this.timeFormat = this.localization.getTimeFormat();
    this.allowOnlyRemove = this.data.headername == this.captions.lbl_removeLockers ? true : false;

  }

  async ngOnInit() {
    this.isFromClient = this.data?.from == LockerBookingSourceType.Client ? true : false;
    this.isFromClass = this.data?.from == LockerBookingSourceType.SpaClasses ? true : false;
    this.initializeForm();
    this.includeLockerAssignment = this._featureFlagInfo.includeLockerAssignment;
    if (!this.from) {
      this.from = this.data?.from
    }
    this.showLockerSection = [LockerBookingSourceType.Client, LockerBookingSourceType.SpaAppointment, LockerBookingSourceType.SpaClasses].includes(this.from);
    let lockerAvailabilityRequest: LockerAvailabilityRequest;

    if (this.from === LockerBookingSourceType.SpaAppointment) {
      let { minStartTime, maxEndTime } = await this.getAppointmentByDate();
      this.appointmentDate = this.localization.convertDateToAPIdate(this.popupData.startDTTM);
      this.minStartTime = this.localization.getTime(minStartTime, this.timeFormat);
      this.maxEndTime = this.localization.getTime(maxEndTime, this.timeFormat);
      this.bookingTypeId = this.appointmentInformation.appointmentId;
      lockerAvailabilityRequest = {
        StartTime: this.appointmentDate + 'T' + this.localization.DeLocalizeTime(this.minStartTime),
        EndTime: this.appointmentDate + 'T' + this.localization.DeLocalizeTime(this.maxEndTime),
        BookingType: this.from,
        BookingTypeId: this.bookingTypeId
      };
    }

    if (this.from === LockerBookingSourceType.Client) {
      this.clientInfo = this.data.client;
      if (this.data.client.id == 0) {
        const clientInfoRequest = await this._clientService.getClientInfoByGuid(this.clientInfo.guestId);
        if (clientInfoRequest && clientInfoRequest?.id == 0) {
          let clientDetails = await this._clientService.createClientByGuestId(clientInfoRequest, this.clientInfo.guestId);
          this.data.client.id = clientDetails.id;
        }
      }
      this.minStartTime = this.localization.getTime(this.propertyInfo.CurrentDate, this.timeFormat);
      this.maxEndTime = this.localization.getTime(this.localization.AddMins(this.propertyInfo.CurrentDate, 30), this.timeFormat);
      this.minDate = this.localization.getDate(this.propertyInfo.CurrentDate);
      this.bookingTypeId = this.clientInfo.id;
      lockerAvailabilityRequest = {
        StartTime: this.localization.convertDateToAPIdate(this.propertyInfo.CurrentDate) + 'T' + this.localization.DeLocalizeTime(this.minStartTime),
        EndTime: this.localization.convertDateToAPIdate(this.propertyInfo.CurrentDate) + 'T' + this.localization.DeLocalizeTime(this.maxEndTime),
        BookingType: this.from,
        BookingTypeId: this.bookingTypeId
      };

    }

    if (this.from === LockerBookingSourceType.SpaClasses) {
      this.minStartTime = this.localization.getTime(this.propertyInfo.CurrentDate, this.timeFormat);
      this.maxEndTime = this.localization.getTime(this.localization.AddMins(this.propertyInfo.CurrentDate, 30), this.timeFormat);
      this.classClientInfo = this.data.clientInfo;
      this.classDate = this.localization.convertDateToAPIdate(this.data.classDetails?.classAppointmentDate)
      let classStartTime = this.localization.DeLocalizeTime(this.data.classDetails.classAvailableTime.startTime);
      let classEndTime = this.localization.DeLocalizeTime(this.data.classDetails.classAvailableTime.endTime);
      this.bookingTypeId = this.data.clientInfo.classClientId;
      lockerAvailabilityRequest = {
        StartTime: this.classDate + 'T' + classStartTime,
        EndTime: this.classDate + 'T' + classEndTime,
        BookingType: this.from,
        BookingTypeId: this.bookingTypeId,
        GuestId:this.classClientInfo.guestId
      };
    }

    this.availableLocker = await this.lockerService.GetLockerAvailability(lockerAvailabilityRequest);
    this.oldValueTranslogForLockerAssignmentDetail = this.availableLocker;
    this.lockerAssignmentDetailsId = this.availableLocker[0]?.lockerAssignmentId;
    this.lockerComment = this.availableLocker[0]?.comments;
    this.isEdit = this.lockerAssignmentDetailsId > 0;
    this.headerdata = this.isEdit ? this.captions.hdr_editLocker : this.captions.hdr_addLockers;
    if (this.isEdit) {
      this.commentCaption = this.captions.Lockers.editComments;
    } else {
      this.commentCaption = this.captions.Lockers.addComments;
    }
    this.maxDecimalPlaces = this.commonUtils.GetPropertyInfo("MaxDecimalPlaces");
    await this.formGenerator();
    this.userMachineConfig = this.getLoggedinUserSessionConfiguration();
    this.lockerForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.saveButton.disabledproperty = !(this.lockerForm.valid && this.lockerForm.dirty &&  !this.isTimeChange);
      this.saveButton = { ...this.saveButton };
      this.payButton.disabledproperty = !(this.lockerForm.valid && this.lockerForm.dirty &&  !this.isTimeChange);
      this.payButton = { ...this.payButton };
      this.postToFolioButton.disabledproperty = !(this.lockerForm.valid && this.lockerForm.dirty);
      this.postToFolioButton = { ...this.postToFolioButton };
    });
  }

  initializeForm() {
    this.headerdata = this.captions.hdr_addLockers;
    this.lockerForm = this.fb.group({
      startTime: this.minStartTime,
      endTime: this.maxEndTime,
      startDate: this.minDate,
      endDate: this.minDate
    });
    this.startTimeInput = {
      form: this.lockerForm,
      formControlName: 'startTime',
      placeHolder: this.isFromClient ? this.captions.lbl_startTime : ''
    };
    this.endTimeInput = {
      form: this.lockerForm,
      formControlName: 'endTime',
      placeHolder: this.isFromClient ? this.captions.lbl_endTime : ''
    };
    this.saveButton = {
      type: 'primary',
      label: this.isEdit ? this.captions.btn_update :this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
    this.removeButton = {
      type: 'primary',
      label: this.captions.btn_remove,
      disabledproperty: false
    };
    this.postToFolioButton = {
      type: 'secondary',
      label: this.captions.btn_postToFolio,
      disabledproperty: true
    };
    this.payButton = {
      type: 'primary',
      label: this.lockerAssignmentDetailsId > 0 ?this.captions.btn_save : this.captions.btn_pay,
      disabledproperty: true
    };
  }

  async formGenerator() {
    this.lockerForm.controls.startTime.setValue(this.minStartTime);
    this.lockerForm.controls.endTime.setValue(this.maxEndTime);
    this.endTimeInput.minTime = this.utils.addMinsInTime(this.lockerForm.controls.startTime.value, 1);
    this.endTimeInput = { ...this.endTimeInput }
    this.assignedLockerStartTime = this.availableLocker[0]?.startTime;
    this.assignedLockerEndTime = this.availableLocker[0]?.endTime;
    let apiResponseStartDate = this.localization.getDate(this.assignedLockerStartTime);
    let apiResponseEndDate = this.localization.getDate(this.assignedLockerEndTime);
    let apiResponseStartTime = this.localization.getTime(this.assignedLockerStartTime, this.timeFormat);
    let apiResponseEndTime = this.localization.getTime(this.assignedLockerEndTime, this.timeFormat);
    
    if(this.isFromClient){
      this.lockerForm.controls.startDate.setValue(apiResponseStartDate);
      this.lockerForm.controls.endDate.setValue(apiResponseEndDate);
    }
    this.availableItem = await this.mapLockerAvailability(this.availableLocker);
    this.selectedItem = this.availableItem.find(item => item.isSelected);
    if (this.selectedItem) {
      this.assignLocker = !!this.selectedItem.lockers.find(item => item.islockerSelected);
      if(this.isFromClient){
        this.availableItem = [this.selectedItem];
      }
    }
    this.allAvailableItem = this.availableItem;
    if (apiResponseStartTime != this.minStartTime
      || apiResponseEndTime != this.maxEndTime) {
      this.lockerForm.controls['startTime'].setValue(apiResponseStartTime);
      this.lockerForm.controls['endTime'].setValue(apiResponseEndTime);
      this.restrictLockerAvailabiltyCall = true;
    }



    this.searchPlaceHolder = this.captions.lbl_searchByLockerNameCode;
    this.saveButton.label = this.isEdit ? this.captions.btn_update :this.captions.btn_save;
    this.payButton.label = this.lockerAssignmentDetailsId > 0 ?this.captions.btn_save : this.captions.btn_pay;
  }

  filterChange() {

  }

  searchValueChange(eve) {
    if (eve.trim().length === 0) {
      this.availableItem = this.allAvailableItem
      this.showAssignedLocker = true;
    }
    else {
      this.availableItem = [...this.allAvailableItem.filter(x =>
        x.lockerName.toLowerCase().includes(eve.toLowerCase()) ||
        x.lockerCode.toLowerCase().includes(eve.toLowerCase()))]
        this.showAssignedLocker = false;
    }
    
  }
  startDateChange(eve) {
    this.availableItem = [];
    this.allAvailableItem = [];
    this.selectedItem = null;
    this.payButton.disabledproperty = true;
    this.postToFolioButton.disabledproperty = true;
    this.startTimeChange();
    this.endTimeChange();
  }
  endDateChange(eve) {
    this.availableItem = [];
    this.allAvailableItem = [];
    this.selectedItem = null;
    this.payButton.disabledproperty = true;
    this.postToFolioButton.disabledproperty = true;
    this.startTimeChange();
    this.endTimeChange();
  }

  async startTimeChange() {

    if (this.from === LockerBookingSourceType.SpaAppointment) {
      this.endTimeInput.minTime = this.utils.addMinsInTime(this.lockerForm.controls.startTime.value, 1);
      this.endTimeInput = { ...this.endTimeInput }
      if (this.lockerForm.controls.endTime.value > this.lockerForm.controls.startTime.value && !this.restrictLockerAvailabiltyCall) {
        let apiDate = this.localization.convertDateToAPIdate(this.appointmentDate);
        let apiStartTime = this.localization.DeLocalizeTime(this.lockerForm.controls.startTime.value);
        let apiEndTime = this.localization.DeLocalizeTime(this.lockerForm.controls.endTime.value);
        const lockerAvailabilityRequest: LockerAvailabilityRequest = {
          StartTime: apiDate + 'T' + apiStartTime,
          EndTime: apiDate + 'T' + apiEndTime,
          BookingType: LockerBookingSourceType.SpaAppointment,
          BookingTypeId : this.bookingTypeId
        };
        this.availableLocker = await this.lockerService.GetLockerAvailability(lockerAvailabilityRequest);
        this.availableItem = await this.mapLockerAvailability(this.availableLocker);
        this.availableItem = [...this.availableItem];
        this.selectedItem = this.availableItem.find(item => item.isSelected);
        this.allAvailableItem = this.availableItem;
      }
      this.restrictLockerAvailabiltyCall = false;
    }

    if (this.isFromClient) {
      let startDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.startDate.value);
      let endDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.endDate.value);
      if (startDate === endDate) {
        this.endTimeInput.minTime = this.utils.addMinsInTime(this.lockerForm.controls.startTime.value, 1);
        this.endTimeInput = { ...this.endTimeInput }
      } else {
        if(this.lockerForm.controls.endTime.value)
        this.lockerForm.controls.endTime.setErrors(null);
      }
      this.availableItem = [];
      this.allAvailableItem = [];
      this.selectedItem = null;
      this.isTimeChange = true;
      this.payButton.disabledproperty = true;
      this.postToFolioButton.disabledproperty = true;
    }

    if (this.from === LockerBookingSourceType.SpaClasses) {
      this.endTimeInput.minTime = this.utils.addMinsInTime(this.lockerForm.controls.startTime.value, 1);
      this.endTimeInput = { ...this.endTimeInput }
      if (this.lockerForm.controls.endTime.value > this.lockerForm.controls.startTime.value && !this.restrictLockerAvailabiltyCall) {
        this.saveButton.disabledproperty = true;
        let apiStartTime = this.localization.DeLocalizeTime(this.lockerForm.controls.startTime.value);
        let apiEndTime = this.localization.DeLocalizeTime(this.lockerForm.controls.endTime.value);
        const lockerAvailabilityRequest: LockerAvailabilityRequest = {
          StartTime: this.classDate + 'T' + apiStartTime,
          EndTime: this.classDate + 'T' + apiEndTime,
          BookingType: LockerBookingSourceType.SpaClasses,
          BookingTypeId : this.bookingTypeId,
          GuestId:this.classClientInfo.guestId
        };
        this.availableLocker = await this.lockerService.GetLockerAvailability(lockerAvailabilityRequest);
        this.availableItem = await this.mapLockerAvailability(this.availableLocker);
        this.availableItem = [...this.availableItem];
        this.selectedItem = this.availableItem.find(item => item.isSelected);
        this.allAvailableItem = this.availableItem;
      }
      this.restrictLockerAvailabiltyCall = false;
      this.saveButton.disabledproperty = true;
      this.isTimeChange = true;
    }

  }

  async endTimeChange() {
    if (this.from === LockerBookingSourceType.SpaAppointment) {
      this.endTimeInput.minTime = this.utils.addMinsInTime(this.lockerForm.controls.startTime.value, 1);;
      this.endTimeInput = { ...this.endTimeInput }
      if (this.lockerForm.controls.endTime.value > this.lockerForm.controls.startTime.value && !this.restrictLockerAvailabiltyCall) {
        let apiDate = this.localization.convertDateToAPIdate(this.appointmentDate);
        let apiStartTime = this.localization.DeLocalizeTime(this.lockerForm.controls.startTime.value);
        let apiEndTime = this.localization.DeLocalizeTime(this.lockerForm.controls.endTime.value);
        const lockerAvailabilityRequest: LockerAvailabilityRequest = {
          StartTime: apiDate + 'T' + apiStartTime,
          EndTime: apiDate + 'T' + apiEndTime,
          BookingType: LockerBookingSourceType.SpaAppointment,
          BookingTypeId : this.bookingTypeId
        };
        this.availableLocker = await this.lockerService.GetLockerAvailability(lockerAvailabilityRequest);
        this.availableItem = await this.mapLockerAvailability(this.availableLocker);
        this.availableItem = [...this.availableItem];
        this.selectedItem = this.availableItem.find(item => item.isSelected);
        this.allAvailableItem = this.availableItem;
      }
      this.restrictLockerAvailabiltyCall = false;
    }

    if (this.isFromClient) {
      let startDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.startDate.value);
      let endDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.endDate.value);
      if (startDate === endDate) {
        this.endTimeInput.minTime = this.utils.addMinsInTime(this.lockerForm.controls.startTime.value, 1);
        this.endTimeInput = { ...this.endTimeInput }
      }
      this.availableItem = [];
      this.allAvailableItem = [];
      this.selectedItem = null;
      this.isTimeChange = true;
      this.payButton.disabledproperty = true;
      this.postToFolioButton.disabledproperty = true;
    }

    if (this.from === LockerBookingSourceType.SpaClasses) {
      this.endTimeInput.minTime = this.utils.addMinsInTime(this.lockerForm.controls.startTime.value, 1);
      this.endTimeInput = { ...this.endTimeInput }
      if (this.lockerForm.controls.endTime.value > this.lockerForm.controls.startTime.value && !this.restrictLockerAvailabiltyCall) {
        let apiStartTime = this.localization.DeLocalizeTime(this.lockerForm.controls.startTime.value);
        let apiEndTime = this.localization.DeLocalizeTime(this.lockerForm.controls.endTime.value);
        const lockerAvailabilityRequest: LockerAvailabilityRequest = {
          StartTime: this.classDate + 'T' + apiStartTime,
          EndTime: this.classDate + 'T' + apiEndTime,
          BookingType: LockerBookingSourceType.SpaClasses,
          BookingTypeId: this.bookingTypeId,
          GuestId: this.classClientInfo.guestId
        };
        this.availableLocker = await this.lockerService.GetLockerAvailability(lockerAvailabilityRequest);
        this.availableItem = await this.mapLockerAvailability(this.availableLocker);
        this.availableItem = [...this.availableItem];
        this.selectedItem = this.availableItem.find(item => item.isSelected);
        this.allAvailableItem = this.availableItem;
      }
      this.restrictLockerAvailabiltyCall = false;
      this.saveButton.disabledproperty = true;
      this.isTimeChange = true;
    }
  }


  selectLockerType(eve, assignLocker: boolean = false) {
    this.showAssignedLocker = true;
    this.assignLocker = assignLocker;
    this.availableItem.forEach(x => {
      if (x.id == eve.id) {
        x.isSelected = true;
      } else {
        x.isSelected = false;
      }
    })
    this.allAvailableItem.forEach(x => {
      if (x.id == eve.id) {
        x.isSelected = true;
      } else {
        x.isSelected = false;
      }
    })
    this.selectedItem = this.availableItem.find(item => item.isSelected);
    this.availableItem = [...this.availableItem];
    if (!assignLocker) {
      this.selectedItem.lockers?.forEach(x => {
        x.islockerSelected = false;
      })
    }
    this.saveButton.disabledproperty = assignLocker;
    this.payButton.disabledproperty = assignLocker;
    this.postToFolioButton.disabledproperty = assignLocker;
    
  }

  selectLocker(eve) {
    if (this.selectedItem) {
      this.selectedItem.lockers?.forEach(x => {
        if (x.lockerNumber == eve.lockerNumber) {
          x.islockerSelected = true;
        } else {
          x.islockerSelected = false;
        }
      })
    }
    this.saveButton.disabledproperty = false;
    this.payButton.disabledproperty = false;
    this.postToFolioButton.disabledproperty = false;
    
  }

  async save(isActive: boolean = true) {
    let lockerStartTime;
    let lockerEndTime;
    if (this.from === LockerBookingSourceType.SpaAppointment) {
      lockerStartTime = lockerStartTime = this.localization.AddTimeToDate(this.popupData.startDTTM, this.localization.TimeToDate(this.lockerForm.get('startTime')?.value));
      lockerEndTime = lockerEndTime = this.localization.AddTimeToDate(this.popupData.startDTTM, this.localization.TimeToDate(this.lockerForm.get('endTime')?.value));
    }

    const assignedLocker = this.selectedItem.lockers.find(x => x.islockerSelected);
    if (this.isFromClient && !this.isEdit) {
      lockerStartTime = this.localization.AddTimeToDate(this.localization.getDate(this.lockerForm.get('startDate').value), this.localization.TimeToDate(this.lockerForm.get('startTime')?.value));
      lockerEndTime = this.localization.AddTimeToDate(this.localization.getDate(this.lockerForm.get('endDate').value), this.localization.TimeToDate(this.lockerForm.get('endTime')?.value));
    }
    //Locker can only be assigned in edit flow for client
    if (this.isFromClient && this.isEdit) {
      lockerStartTime = this.assignedLockerStartTime;
      lockerEndTime = this.assignedLockerEndTime;
    }

    if (this.from === LockerBookingSourceType.SpaClasses) {
      lockerStartTime = this.localization.AddTimeToDate(this.classDate, this.localization.TimeToDate(this.lockerForm.get('startTime')?.value));
      lockerEndTime = this.localization.AddTimeToDate(this.classDate, this.localization.TimeToDate(this.lockerForm.get('endTime')?.value));
    }

    
    //Validate if locker assigned to client
    let clientId = this.isFromClient ? this.clientInfo.guestId : this.from == LockerBookingSourceType.SpaAppointment ? this.appointmentInformation.guestId : this.classClientInfo.guestId;
    let asssignedLockerResponse = await this.lockerService.CheckLockerAssingedToClient(this.localization.ConvertDateToISODateTime(lockerStartTime),this.localization.ConvertDateToISODateTime(lockerEndTime),clientId);
    if (asssignedLockerResponse.length > 0 && !this.isEdit) {
      let clientName = this.isFromClient ? this.clientInfo.name : this.from == LockerBookingSourceType.SpaAppointment ? this.appointmentInformation.clientName : `${this.classClientInfo.firstName} ${this.classClientInfo.lastName} `;
      let lockerInfo = (await this.lockerService.GetLockerInformationByLockerIds([asssignedLockerResponse[0].lockerId])).result;
      let errorMsg = `<div> ${this.captions.lockerassignedValidationErrorMessage} </div>`
      errorMsg = errorMsg.replace("{lockerName}", `<b>${lockerInfo.find(x => x.lockerId == asssignedLockerResponse[0].lockerId).lockerName}</b>`)
        .replace("{clientName}", `<b>${clientName}</b>`)
        .replace("{startTime}", `<b>${this.localization.getTime(asssignedLockerResponse[0].lockerBookingStartTime, this.timeFormat)}</b>`)
        .replace("{endTime}", `<b>${this.localization.getTime(asssignedLockerResponse[0].lockerBookingEndTime, this.timeFormat)}</b>`)
        .replace("{duration}", `<b>${asssignedLockerResponse[0].duration}</b>`);

      this.utils.ShowErrorMessage(
        this.localization.captions.common.Error,
        errorMsg
      );
      return
    }
    let LockerAssignmentDetails: LockerAssignmentDetails = {
      amount: this.localization.currencyToSQLFormat(this.selectedItem.price.toString()),
      bookingType: this.isFromClient ? LockerBookingSourceType.Client : this.from, // Need to assign this based on which screen this is added
      bookingTypeId: this.bookingTypeId,
      clientId: this.isFromClient ? this.clientInfo.guestId : this.from == LockerBookingSourceType.SpaAppointment ? this.appointmentInformation.guestId : this.classClientInfo.guestId,
      comments: this.lockerComment,
      duration: this.localization.getTimeDifference(this.lockerForm.get('startTime')?.value, this.lockerForm.get('endTime')?.value, 'Min'),
      id: this.lockerAssignmentDetailsId,
      isActive: isActive,
      lockerBookingStartTime: this.localization.ConvertDateToISODateTime(lockerStartTime),
      lockerBookingEndTime: this.localization.ConvertDateToISODateTime(lockerEndTime),
      lockerId: this.selectedItem.id,
      lockerNumber: assignedLocker?.lockerNumber ?? '',
      lockerPrefix: this.selectedItem.lockerPrefix,
      lockerSequence: assignedLocker?.lockerSequence ?? 0,
      uId: this.commonUtils.generateGUID()

    };
    this.lockerAssignmentaDetail = LockerAssignmentDetails;
    if (this.from == LockerBookingSourceType.SpaAppointment || this.from == LockerBookingSourceType.SpaClasses || this.isEdit) {
      this.lockerService.SaveLocker(LockerAssignmentDetails, this.isEdit);
      if (this.from == LockerBookingSourceType.SpaAppointment) {
        let lockerAssignmentDetailsForTranslog : LockerAssignmentDetailsTranslog= {
          lockerBookingStartTime: LockerAssignmentDetails.lockerBookingStartTime,
          lockerBookingEndTime: LockerAssignmentDetails.lockerBookingEndTime,
          duration: LockerAssignmentDetails.duration,
          lockerName : this.selectedItem.lockerName,
          comments : LockerAssignmentDetails.comments
        };
        if(this.includeLockerAssignment){
          lockerAssignmentDetailsForTranslog['lockerNumber'] = LockerAssignmentDetails.lockerNumber;
        }
        let lockerAssignmentOldValue = cloneDeep(LockerAssignmentDetails);
        const oldValueForTranslog = this.oldValueTranslogForLockerAssignmentDetail.filter(obj => obj.isSelected);
        const selectedLockerDetail = oldValueForTranslog[0]?.lockerDetails.find(x => x.isSelected);
        lockerAssignmentOldValue.lockerNumber = selectedLockerDetail?.name ?? '';
        lockerAssignmentOldValue.lockerSequence = selectedLockerDetail?.lockerSequence ?? '';
        lockerAssignmentOldValue.lockerBookingStartTime = oldValueForTranslog[0]?.startTime ?? '';
        lockerAssignmentOldValue.lockerBookingEndTime = oldValueForTranslog[0]?.endTime ?? '';
        lockerAssignmentOldValue.comments = oldValueForTranslog[0]?.comments ?? '';
        let lockerAssignmentOldValueForTranslog: LockerAssignmentDetailsTranslog = {
          lockerBookingStartTime: lockerAssignmentOldValue.lockerBookingStartTime,
          lockerBookingEndTime: lockerAssignmentOldValue.lockerBookingEndTime,
          duration: lockerAssignmentOldValue.duration,
          lockerName : oldValueForTranslog[0]?.lockerName ?? '',
          comments : lockerAssignmentOldValue.comments
        };
        if(this.includeLockerAssignment){
          lockerAssignmentOldValueForTranslog['lockerNumber'] = lockerAssignmentOldValue.lockerNumber;
        }
        let action = LockerAppointmentConstant.AppointmentEdit;
        let actionDesc = isActive ? this.isEdit ? LockerAppointmentConstant.editLocker : LockerAppointmentConstant.addLocker : LockerAppointmentConstant.removeLocker;
        let TranslogModel: LogEntry = {
          oldValue: isActive ? this.isEdit ? lockerAssignmentOldValueForTranslog : null : lockerAssignmentDetailsForTranslog,
          newValue: isActive ? this.isEdit ? lockerAssignmentDetailsForTranslog : lockerAssignmentDetailsForTranslog : null,
          clientId: this.isFromClient ? this.bookingTypeId : this.appointmentInformation.appointmentId,
          logType: action,
          description: actionDesc,
        };
        this.appointmentUtils.CreateTransLogForAppointment(this.bookingTypeId, action, TranslogModel);
      }

    }

    if (this.isFromClient && !this.isEdit) {
      await this.SetLockerToOrderSummaryScreen();
    }
    else {
      this.dialogRef.close({
        from: this.captions.bookAppointment.lbl_lockers
      });
    }


  }

  cancel() {
    this.dialogRef.close();
  }

  close(eve) {
    this.dialogRef.close();
  }

  async showAvailability() {
    let apiStartTime = this.localization.DeLocalizeTime(this.lockerForm.controls.startTime.value);
    let apiEndTime = this.localization.DeLocalizeTime(this.lockerForm.controls.endTime.value);
    let startDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.startDate.value);
    let endDate = this.localization.convertDateToAPIdate(this.lockerForm.controls.endDate.value);
    let lockerAvailabilityRequest = {
      StartTime: startDate + 'T' + apiStartTime,
      EndTime: endDate + 'T' + apiEndTime,
      BookingType: this.data.from,
      BookingTypeId: this.bookingTypeId
    };
    this.showAssignedLocker = false;
    this.utils.ToggleLoader(true);
    this.availableLocker = await this.lockerService.GetLockerAvailability(lockerAvailabilityRequest);
    this.utils.ToggleLoader(false);
    this.availableItem = await this.mapLockerAvailability(this.availableLocker);
    this.availableItem = [...this.availableItem];
    this.selectedItem = this.availableItem.find(item => item.isSelected);
    this.assignedLockerStartTime = this.availableLocker[0]?.startTime;
    this.assignedLockerEndTime = this.availableLocker[0]?.endTime;
    this.lockerAssignmentDetailsId = this.availableLocker[0]?.lockerAssignmentId;
    this.isEdit = this.lockerAssignmentDetailsId > 0;
    this.headerdata = this.isEdit ? this.captions.hdr_editLocker : this.captions.hdr_addLockers;
    if (this.isEdit) {
      this.commentCaption = this.captions.Lockers.editComments;
    } else {
      this.commentCaption = this.captions.Lockers.addComments;
    }
    this.payButton.label = this.isEdit ? this.captions.btn_save : this.captions.btn_pay;
    this.lockerForm.controls.startTime.setValue(this.localization.getTime(this.assignedLockerStartTime, this.timeFormat));
    this.lockerForm.controls.endTime.setValue(this.localization.getTime(this.assignedLockerEndTime, this.timeFormat));
    this.lockerForm.controls.startDate.setValue(this.localization.getDate(this.assignedLockerStartTime));
    this.lockerForm.controls.endDate.setValue(this.localization.getDate(this.assignedLockerEndTime));
    if (this.isEdit) {
      this.availableItem = [this.selectedItem];
      if (this.selectedItem) {
        this.showAssignedLocker = true;
        this.assignLocker = !!this.selectedItem.lockers.find(item => item.islockerSelected);
      }
    }
    
    this.payButton.disabledproperty = true;
    this.postToFolioButton.disabledproperty = true;
    
    this.allAvailableItem = this.availableItem;
    this.lockerComment = this.availableLocker[0]?.comments;
  }

  openComments() {
    this.dialog.open(AddCommentsComponent, {
      width: '300px',
      height: '300px',
      disableClose: true,
      data: {
        from: 'client',
        lockerComment: this.lockerComment,
        isEdit:this.isEdit
      },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        this.lockerComment = res;
        if (this.selectedItem.id > 0) {
          this.saveButton.disabledproperty = false;
          this.payButton.disabledproperty = false;
          this.postToFolioButton.disabledproperty = false;
        }

      }

    });
  }

  async getAppointmentByDate(): Promise<{ minStartTime: Date, maxEndTime: Date }> {
    const requestInput: AppointmentFilterRequest = {
      FirstName: '',
      LastName: '',
      ConfirmationNumber: '',
      ClientIds: [this.popupData.clientId],
      ScheduleDateTime: this.popupData.startDTTM

    }
    let appointments = await this.apptService.getAppointmentByClient(requestInput);

    const { minStartTime, maxEndTime } = appointments.reduce(
      (acc, appointment) => {
        const startTime = new Date(appointment.startTime);
        const endTime = new Date(appointment.endTime);

        return {
          minStartTime: startTime < acc.minStartTime ? startTime : acc.minStartTime,
          maxEndTime: endTime > acc.maxEndTime ? endTime : acc.maxEndTime,
        };
      },
      {
        minStartTime: new Date(appointments[0].startTime), // Initialize with first startTime
        maxEndTime: new Date(appointments[0].endTime) // Initialize with first endTime
      }
    );
    return { minStartTime, maxEndTime };
  }

  loadLockerImages = (lockerIds) => {
    this.imgService.GetAllImagesByReference(lockerIds, HostEnum.ImgRefType.locker, false, this.successCallback.bind(this), this.errorCallback.bind(this), []);
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === 'getAllImagesByReference') {

      let image = result.result as any;
      const value = [...this.mapImg(this.availableItem, image)];
      this.availableItem = value;
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  mapImg(availableItem: any[], images: any[]) {
    images.forEach(element => {
      availableItem.forEach(itemArr => {
        if (itemArr.id == element.referenceId) {
          itemArr.image = `${element.contentType};base64,${element.data}`;
        }
      });
    });

    return availableItem;
  }

  async mapLockerAvailability(LockerAvailabilityResponse: any) {
    let availableLocker = [];
    let lockerIds = LockerAvailabilityResponse.map(x => x.id);
    this.loadLockerImages(lockerIds);
    availableLocker = await Promise.all(LockerAvailabilityResponse.map(async (x) => {
      let lockerList = [];
      x.lockerDetails.map(y => {
        lockerList.push({
          lockerNumber: y.name,
          lockerSequence: y.lockerSequence,
          islockerSelected: y.isSelected
        });
      });

      let imageUrl = x.image ? `${x.image.contentType};base64,${x.image.thumbnailData}` : 'assets/images/shop/emptyshop.jpg';

      return {
        id: x.id,
        lockerName: x.lockerName,
        lockerCode: x.lockerCode,
        availableQty: x.availableCount,
        totalQty: x.totalCount,
        lockerPrefix: x.lockerPrefix,
        image: imageUrl,
        price: this.localization.localizeCurrency(x.lockerAmount.toFixed(this.maxDecimalPlaces)),
        isSelected: x.isSelected,
        lockers: lockerList,
        lockerAssignmentId: x.lockerAssignmentId,
        retailItemId: x.retailItemId
      };
    }));

    return availableLocker;
  }
  getLoggedinUserSessionConfiguration() {
    return this.retailUtilities.getLoggedinUserSessionConfiguration();
  }

  async OpenDefaultOutletModal() {
    let dialogRef = this.dialog.open(SelectOutletModalComponent, {
      width: '30%',
      height: 'auto',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'action-dialog-overlay'
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (result) => {
      if (result && result?.isSave) {
        this.retailSharedService.SelectedOutletId = result.outlet;
        if (this.postToFolio) {
          this.postToFolio = false;
          let selctedProduct = await this.setSelectedProduct();
          this.autoRetailTransactionBusiness.CheckForRequireComments(selctedProduct, this.InitiateAutoRetailTransaction.bind(this))
        }
        else {
          await this.setLockerItem();
        }

      }
    });
  }
  async SetLockerToOrderSummaryScreen() {
    if (!this.userMachineConfig.defaultOutletId) {
      await this.OpenDefaultOutletModal();
    }
    else {
      await this.setLockerItem();
    }

  }

  async setLockerItem(){
    const selectedProducts: SelectedProducts[] = await this.setSelectedProduct();
    
    this.retailSharedService.selectedProducts = selectedProducts;
    this.retailSharedService.isReturnWithoutTicket = false;
    this.retailSharedService.payeeId = this.clientInfo.id
    this.retailSharedService.SelectedOutletId = this.userMachineConfig.defaultOutletId
    this.retailSharedService.lockerAssignmentDetails = this.lockerAssignmentaDetail
    this.utils.RedirectTo(RedirectToModules.order)
    this.dialogRef.close({
      from: this.captions.bookAppointment.lbl_lockers
    });
  }

  async payWithPostToFolio() {
    this.postToFolio = true;
    if (!this.userMachineConfig.defaultOutletId) {
      await this.OpenDefaultOutletModal();
    }
    else{
      this.retailSharedService.SelectedOutletId = this.userMachineConfig.defaultOutletId;
      let selctedProduct = await this.setSelectedProduct();
      this.autoRetailTransactionBusiness.CheckForRequireComments(selctedProduct, this.InitiateAutoRetailTransaction.bind(this))
    }
    
  }

  async setSelectedProduct(){
    const selectedProducts: SelectedProducts[] = [];
    let shopItems = await this.lockerService.getRetailItemsDetailedInfoByIds([this.selectedItem.retailItemId]);
    let lockerInfo = this.selectedItem;
    let retailItem = shopItems.find(a => a.id == lockerInfo.retailItemId);
    let retailItemId = lockerInfo.retailItemId;
    let selectedProduct: SelectedProducts = {
      ItemDescription: lockerInfo.lockerName,
      ItemType: ((retailItem && retailItem.retailItemDetail && retailItem.retailItemDetail.itemType) || 0),
      ProductName: lockerInfo.lockerName,
      ServiceId: 0,
      ProductPrice: this.localization.currencyToSQLFormat(this.selectedItem.price.toString()),
      SalesPrice: this.localization.currencyToSQLFormat(this.selectedItem.price.toString()),
      ExternalPOSItemId: ((retailItem && retailItem.retailItemDetail && retailItem.retailItemDetail.externalPOSId) || 1),
      ItemId: retailItemId,
      Noofitems: 1,
      Discount: 0,
      DiscountPercentage: 0,
      DiscountTypeId: 0,
      isEditDisabled: true,
      isModificationRestricted: true,
      Commission: [],
      isCommissionable: ((retailItem && retailItem.retailItemDetail && retailItem.retailItemDetail.isCommissionable) || 1),
      Gratuity: [],
      ServiceCharge: [],
      isGroupingKey: false,
      isPackagedItem: false,
      PackageItemId: 0,
      MultiPack: false,
      ClientMultiPackId: 0,
      PackageGroupId: 0,
      isOpenPricedItem: true,
      StaffTransactionDetail: [],
      MaxTherapistForCommission: 0,
      IsAppointmentNotCheckedOut: false,
      addOnId: 0,
      category: retailItem ? retailItem.retailItemDetail.category : 0,
      LineNumber: 1,
      costPrice: retailItem.retailItemDetail.costPrice,
      marginPercentage: retailItem.retailItemDetail.marginPercentage,
      isRequireComments: retailItem.retailItemDetail.isRequireComments,
      itemComments: '',
      clientName: this.clientInfo.name,
      clientId: this.clientInfo.id,
      payeeId: this.clientInfo.id,
      clientType: this.clientInfo.clienttype,
      clientLinkId: this.clientInfo.clientLinkId,
      allowEarn: ((retailItem && retailItem.retailItemDetail && retailItem.retailItemDetail.allowEarn) || false),
      discountComments: '',
      discountReason: 0
    };
    selectedProducts.push(selectedProduct);
    return selectedProducts;
  }

  async InitiateAutoRetailTransaction(selectedProducts: SelectedProducts[]) {
    this.utils.ToggleLoader(true);
    const currentGuestId = this.clientInfo.guestId;
    const isMemberAttached = this.clientInfo.clienttype && this.clientInfo.clienttype == 2;

    let folioDetailRequest:BulkCreateResortFinanceFoliosRequest ={
      sourceType: isMemberAttached ? SourceType.AddOnMember.toString() : SourceType.AddOn.toString(),
      sourceTypeIds:[this.clientInfo.guestId],
      guestCardTokenReferences: [
        {
          "sourceTypeId": "",
          "cardToken": 0,
          "isDefaultToken": true
        }
      ],
      resortFinanceGuestCardTokenReferences: [
        {
          "sourceTypeId": "",
          "cardToken": 0,
          "isDefaultToken": true
        }
      ]
    }
    let folioDetails = await this._folioBussiness.BulkCreateResortFinanceFolios(folioDetailRequest);
    let request: AutoRetailTransactionRequestModel[] = [];
    const emailIds = this.clientInfo.Email && this.clientInfo.Email.emailId ? [this.clientInfo.Email.emailId] : [];
    const data = this.autoRetailTransactionBusiness.MapRetailItems(selectedProducts, {
      guestId: currentGuestId,
      clientId: this.clientInfo.id
    });
    
    let autoRetailRequest: AutoRetailTransactionRequestModel = {
      outletId: this.retailSharedService.SelectedOutletId,
      sourceId: this.selectedItem.id,
      sourceType: RetailInterfaceSourceTypes.SPA,
      autoRetailTransactionComments: 'Post To Folio for Lockers ' + `${this.selectedItem.id}`,
      autoRetailTransactionType: AutoRetailTransactionType.SPAAppoinmentCheckout,
      isTraceRequired: false,
      emailId: emailIds,
      autoRetailTransactionRequest: {
        memberId: isMemberAttached ? this.clientInfo.clientLinkId : 0,
        clerkId: Number(this.localization.GetUserInfo('userId')),
        autoRetailItems: data[0],
        userId: Number(this.localization.GetUserInfo('userId')),
        saleType: "",
        guestGuid: currentGuestId
      },
      autoRetailTransactionReason: 'Post To Folio for Lockers' + `${this.selectedItem.id}`,
      autoRetailPaymentDetails: {
        autoRetailPaymentMethodIDs: [PaymentMethods.PostToFolio],
        folioPostingDetail: {
          folioInvoiceNumber: folioDetails[0].folioInvoiceNumber,
          resortFinanceSourceType: isMemberAttached ? SourceType.AddOnMember.toString() : SourceType.AddOn.toString(),
          resortFinanceSourceTypeId: currentGuestId,
          guestId: currentGuestId,
          paymentMethodId: PaymentMethods.PostToFolio
        }
      }
    }
    request.push(autoRetailRequest);
    this.utils.ToggleLoader(true);
    await this.autoRetailTransactionBusiness.InitiateAutoRetailTransaction(request).then(async result => {
      if (result && result.length && result[0].isAutoRetailTransactionSuccess) {
        let lockerStartTime;
        let lockerEndTime;
        const assignedLocker = this.selectedItem.lockers.find(x => x.islockerSelected);
        if (this.isFromClient && !this.isEdit) {
          lockerStartTime = this.localization.AddTimeToDate(this.localization.getDate(this.lockerForm.get('startDate').value), this.localization.TimeToDate(this.lockerForm.get('startTime')?.value));
          lockerEndTime = this.localization.AddTimeToDate(this.localization.getDate(this.lockerForm.get('endDate').value), this.localization.TimeToDate(this.lockerForm.get('endTime')?.value));
        }
        //Locker can only be assigned in edit flow for client
        if (this.isFromClient && this.isEdit) {
          lockerStartTime = this.assignedLockerStartTime;
          lockerEndTime = this.assignedLockerEndTime;
        }

        let LockerAssignmentDetails: LockerAssignmentDetails = {
          amount: this.localization.currencyToSQLFormat(this.selectedItem.price.toString()),
          bookingType: this.isFromClient ? LockerBookingSourceType.Client : this.from, // Need to assign this based on which screen this is added
          bookingTypeId: this.bookingTypeId,
          clientId: this.isFromClient ? this.clientInfo.guestId : this.appointmentInformation.guestId,
          comments: this.lockerComment,
          duration: this.localization.getTimeDifference(this.lockerForm.get('startTime')?.value, this.lockerForm.get('endTime')?.value, 'Min'),
          id: this.lockerAssignmentDetailsId,
          isActive: true,
          lockerBookingStartTime: this.localization.ConvertDateToISODateTime(lockerStartTime),
          lockerBookingEndTime: this.localization.ConvertDateToISODateTime(lockerEndTime),
          lockerId: this.selectedItem.id,
          lockerNumber: assignedLocker?.lockerNumber ?? '',
          lockerPrefix: this.selectedItem.lockerPrefix,
          lockerSequence: assignedLocker?.lockerSequence ?? 0,
          uId: this.commonUtils.generateGUID()

        };
        await this.lockerService.SaveLocker(LockerAssignmentDetails, this.isEdit);
        const folioInfo: FolioInfo = {
          sourceType: folioDetails[0].sourceType,
          sourceTypeId: folioDetails[0].sourceTypeId,
          folioInvoiceNumber: folioDetails[0].folioInvoiceNumber
        }
        this.autoRetailTransactionBusiness.PostResortFinanceFolioPostDetails(selectedProducts, result[0].autoPaymentInfos[0].postIds, Number(result[0].autoPaymentInfos[0].paymentAmount), folioInfo);
        this.autoRetailTransactionBusiness.printReceiptAndSendNotification(result[0], this.clientInfo.id, emailIds, this.clientInfo.name, isMemberAttached ? this.clientInfo.clientLinkId : '');
        this.utils.ToggleLoader(false);
        this.dialogRef.close();
      }
      else {
        this.utils.ToggleLoader(false);
        this.utils.ShowErrorMessage(
          this.localization.captions.common.Error,
          result[0].errorMessage
        );
      }

    }).catch(async error => {
      this.utils.ToggleLoader(false);
      this.utils.ShowErrorMessage(
        this.localization.captions.common.Error,
        this.localization.captions.shop.postingFailed
      );

    });
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

}


