import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { OnEventArgs } from '../../form-type.entity';
import moment from 'moment';

@Component({
  selector: 'lib-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss']
})
export class DaterangePickerComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  formFieldName: any;
  fieldType: any;
  formFieldlabel: any;
  formArrayName: any;
  errorMessage: string;
  isInputDisabled: boolean;
  errorLength: number;
  minDate: any;
  maxDate: any;
  checkType: any;
  formFieldToolTip: string;
  AllowedPickerDate: string;
  IniDateFieldFormat: string;
  formFieldNameFromRange: string;
  formFieldNameToRange: string;
  // get isValid(): any { return this.form.controls[this.formFieldName].valid; }
  // get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }

  constructor(private agyDynamicFormServices: AgyDynamicFormServices) { }

  eventCallback(index?: any, argus?: OnEventArgs): void {
    this.agyDynamicFormServices.formEventCallback(this.field, index, this.form);
  }

  ngOnInit(): void {
    this.fieldType = Object.keys(this.field.fieldType).toString();
    this.IniDateFieldFormat = sessionStorage.getItem('IniDateFieldFormat');
    const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
    this.formFieldName = formField.fieldName;
    this.formFieldNameFromRange = 'FromRange';
    this.formFieldNameToRange = 'ToRange';
    this.formFieldlabel = formField.fieldLabel;
    this.formArrayName = formField.fieldName;
    this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
      ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
    this.isInputDisabled = this.field.fieldType[this.fieldType].disbaledProperty ?
      this.field.fieldType[this.fieldType].disbaledProperty : false;
    this.AllowedPickerDate = this.field.fieldType?.SingleData?.AllowedPickerDate;
    if (this.field.hasOwnProperty('minDate')) {
      this.minDate = this.field.minDate;
    }
    if (this.field.hasOwnProperty('maxDate')) {
      this.maxDate = this.field.maxDate;
    }
    if (this.field.fieldType?.SingleData?.hasOwnProperty('AllowedPickerDate')) {
      if (this.AllowedPickerDate === 'PastDateOnly') {
        this.minDate = new Date();
      } else if (this.AllowedPickerDate === 'FutureDateOnly') {
        this.maxDate = new Date();
      } else if (this.AllowedPickerDate === 'OneMonthOnly') {
        const currentDate = moment();
        this.minDate = currentDate.subtract(1, 'months');
        this.maxDate = new Date();
      }
    } else {
      this.minDate = null;
      this.maxDate = null;
    }
  }

}
