import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Localization } from '../localization/localization';
import { CommonPropertyInformation } from '../shared/services/common-property-information.service';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { HttpServiceCall } from '../shared/shared/service/http-call.service';
import { Host } from '../shared/shared/globalsContant';
import { HttpMethod } from '../Models/http.model';
import { CardInfo, CreateTokenRequest, HandleRequest, HandleResponse, PaymentBaseResponse, PaymentErrorCodes, StoreTokenRequest, TokentransactionInfo } from '../shared/shared/business/shared.modals';
import { GiftCardPMRequest, SaleRequest, SaleResponse, ValidateAuthRequest } from '../Models/payment.model';
import { PropertySettingDataService } from '../dataservices/authentication/propertysetting.data.service';
import { CommonApiRoutes } from '../common-route';

@Injectable({ providedIn: 'root' })
export class PayAgentService {

    private readonly payAgentURI: string = 'PAYAGENTURI';
    constructor(
        public localization: Localization,
        private http: HttpServiceCall,
        private PropertyInfo: CommonPropertyInformation,
        private utils: CommonUtilities,
        private _sanitizer: DomSanitizer,
        private propertySettingService: PropertySettingDataService
    ) {
       
    }

    private CEDS_StoreTerminal = new Map<string, string>();

    private async TryGetCEDSValueAsync(terminalId: string): Promise<string> {
        try {
            let defaultOutletId = this.PropertyInfo.GetDefaultOutlet();
            if (defaultOutletId === 0) {
                defaultOutletId = await this.propertySettingService.getDefaultOutlet();
                if (defaultOutletId > 0 ) {
                    this.PropertyInfo.SetDefaultOutlet(defaultOutletId);
                }
            }
            if (this.CEDS_StoreTerminal.has(terminalId)) {
                return this.CEDS_StoreTerminal.get(terminalId);
            } 
            else if (terminalId === '0' && defaultOutletId !== 0) {
                const response = await this.http.CallApiAsync<any>({
                    host: Host.retailManagement,
                    callDesc: 'GetCEDSByOutletId',
                    method: HttpMethod.Get,
                    uriParams: { outletId: defaultOutletId }
                });
                const CEDS_Value: string = response.result;
                this.CEDS_StoreTerminal.set(terminalId, CEDS_Value);
                return CEDS_Value;
            } 
            else {
                const response = await this.http.CallApiAsync<any>({
                    host: Host.retailManagement,
                    callDesc: CommonApiRoutes.GetCEDSByTerminalId,
                    method: HttpMethod.Get,
                    uriParams: { outletId: terminalId }
                });
                const CEDS_Value: string = response.result;
                this.CEDS_StoreTerminal.set(terminalId, CEDS_Value);
                return CEDS_Value;
            }
        } catch (error) {
            console.log(`Error occurred while retrieving CEDS value err:${error}`);
        }
    }
    async FormGetHandlesTenderId(tender: string, terminalId: string) {
        const CEDS = await this.TryGetCEDSValueAsync(terminalId);
        return (`${CEDS}.${tender}`);
    }

    async FormTenderId(tender: string, outletId: number, ceds: string) {
        const tenderWithCEDS = ceds ? ceds : await this.TryGetCEDSValue(outletId);
        return (`${tenderWithCEDS}.${tender}`);
    }

    private CEDS_Store = new Map<number, string>();

    private async TryGetCEDSValue(defaultOutletId: number): Promise<string> {
        try {
            if (this.CEDS_Store.has(defaultOutletId)) {
                return this.CEDS_Store.get(defaultOutletId);
            } else {
                let response = await this.http.CallApiAsync<any>({
                    host: Host.retailManagement,
                    callDesc: 'GetCEDSByOutletId',
                    method: HttpMethod.Get,
                    uriParams: { outletId: defaultOutletId }
                });
                const CEDS_Value: string = response.result;
                this.CEDS_Store.set(defaultOutletId, CEDS_Value);
                return CEDS_Value;
            }
        } catch (error) {
            console.log(`Error occurred while retrieving CEDS value err:${error}`);
        }
    }

     async GetHandles(handleRequestBody: HandleRequest, terminalId = '0', handleErr: boolean = false, isShowValidatePayAgentMsg: boolean = true): Promise<HandleResponse> {
        const isPMAgentValid = await this.checkPayAgentVersion(isShowValidatePayAgentMsg);   
        if(isPMAgentValid){
            handleRequestBody.tenderId = await this.FormGetHandlesTenderId(handleRequestBody.tenderId, terminalId);
            let uri = this.BuildPMAgentURI(APIAction.GetHandles);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, handleRequestBody);
        } 
        else{
            let handleResponse: HandleResponse = {} as HandleResponse;
            return handleResponse;
        }
     }

    async GetBalance(handleRequestBody: GiftCardPMRequest, outletId = 0, ceds: string = ""): Promise<HandleResponse> {
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            handleRequestBody.InquirerInfo.TenderId = await this.FormTenderId(handleRequestBody.InquirerInfo.TenderId, outletId, ceds);
            let uri = this.BuildPMAgentURI(APIAction.GetBalance, outletId);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, handleRequestBody);   
        } 
        else{
            let handleResponse: HandleResponse = {} as HandleResponse;
            return handleResponse;
        }    
    }

    async LoadCard(handleRequestBody: GiftCardPMRequest, outletId = 0, ceds: string = ""): Promise<any> {
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            handleRequestBody.InquirerInfo.TenderId = await this.FormTenderId(handleRequestBody.InquirerInfo.TenderId, outletId, ceds);
            let uri = this.BuildPMAgentURI(APIAction.Load, outletId);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, handleRequestBody);  
        } 
        else{
            let handleResponse: any = {};
            return handleResponse;
        }       
    }

    async IssueCard(handleRequestBody: GiftCardPMRequest, outletId = 0, ceds: string = ""): Promise<any> {
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            handleRequestBody.InquirerInfo.TenderId = await this.FormTenderId(handleRequestBody.InquirerInfo.TenderId, outletId, ceds);
            let uri = this.BuildPMAgentURI(APIAction.Issue, outletId);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, handleRequestBody);  
        } 
        else{
            let handleResponse: any = {};
            return handleResponse;
        }    
    }

    async GetHandlesWithTimeout(handleRequestBody: HandleRequest, outletId = 0, timeout = 10000, ceds = ""): Promise<HandleResponse> {
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            handleRequestBody.tenderId = await this.FormTenderId(handleRequestBody.tenderId, outletId, ceds);
            let uri = this.BuildPMAgentURI(APIAction.GetHandles, outletId);
            return this.http.InvokeApiAsyncWithTimeout<any>(uri, HttpMethod.Post, handleRequestBody, timeout); 
        } 
        else{
            let handleResponse: HandleResponse = {} as HandleResponse;
            return handleResponse;
        }          
    }

    async CreateToken(handle: string, tenderID: number, outletId = 0, manualCardEntry = false, isPartialTenderAllowed = false, ceds?: string): Promise<TokentransactionInfo> {
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            let requestBody: CreateTokenRequest;
            requestBody = { handle: handle, inquirerInfo: { TenderId: tenderID.toString(), manualCardEntry: manualCardEntry, isPartialTenderAllowed: isPartialTenderAllowed } };        
            requestBody.inquirerInfo.TenderId = await this.FormTenderId(requestBody.inquirerInfo.TenderId, outletId, ceds);                
            let uri = this.BuildPMAgentURI(APIAction.CreateToken, outletId);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, requestBody);  
        } 
        else{
            let response: TokentransactionInfo = {} as TokentransactionInfo;
            return response;
        }        
    }

    async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
        const response: any = await this.http.CallApiAsync<any>({
            host: Host.payment,
            callDesc: 'StoreToken',
            method: HttpMethod.Post,
            body: storeTokenRequest
        });
        if (response && response.result) {
            return response.result;
        }
        return null;
    }

    async GetCardInfo(tokenRefId: number): Promise<CardInfo> {
        const response: any = await this.http.CallApiAsync<any>({
            host: Host.payment,
            callDesc: 'GetCardInfoByTransaction',
            method: HttpMethod.Get,
            uriParams: {tokenTransId: tokenRefId}
        });

        if (response && response.result) {
            return response.result;
        }

        return null;
    }

    async RequestSale(payRequest: SaleRequest, outletId = 0, ceds: string = ""): Promise<any> {
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, outletId, ceds);
            let uri = this.BuildPMAgentURI(APIAction.RequestSale, outletId);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, payRequest);  
        } 
        else{
            let response: any = {};
            return response;
        }       
    }

    /**
  * @function RequestAuth async
  * @returns AuthResponse
  * @param payRequest AuthRequest 
  * @param terminalId number
  * @description Communicates with PMAgent to light up the device for sale
  */
    async RequestAuth(payRequest: SaleRequest, outletId = 0, ceds = ""): Promise<SaleResponse> {        
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, outletId, ceds);
            let uri = this.BuildPMAgentURI(APIAction.RequestAuth);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, payRequest); 
        } 
        else{
            let response: SaleResponse = {} as SaleResponse;
            return response;
        }      
    }

    /**
       * @function ValidateAuth async
       * @returns ValidatePayResponse
       * @param ValidateAuthRequest validateAuthRequest 
       * @param OutletId number
       * @description Communicates with Payment Manger to validate the Auth response
       */
    async ValidateAuth(validateAuthRequest: ValidateAuthRequest, OutletId: number, ceds: string = ""): Promise<any> {        
        OutletId = ceds ? this.extractOutletFromCEDS(ceds) : OutletId;
        return this.http.CallApiAsync<any>(
            {
                callDesc: "ValidateAuth",
                host: Host.payment,
                method: HttpMethod.Post,
                body: validateAuthRequest,
                uriParams: { outletId: OutletId }
            }
        );       
    }

    extractOutletFromCEDS = (ceds) => { 
        let ceds_split = ceds.split("_");
        return ceds_split && ceds_split.length == 4 ? ceds_split[3] : 0
    };

    MaskCreditCardNumber(cardNo: string): string {
       
        cardNo = 'XXXX-XXXX-XXXX-' + cardNo.substring(cardNo.length - 4, cardNo.length);
        return cardNo;
    }

    ValidateCreditCard(cardInfo: CardInfo): boolean {
        if (cardInfo.cardExpiration && cardInfo.cardExpiration.length === 6) { // Check the returned date is of format YYYYMM
            // Check Expiry date
            const cardMonth = Number(cardInfo.cardExpiration.substring(4, 6)) - 1;
            const cardYear = Number(cardInfo.cardExpiration.substring(0, 4));
            const currentDate = this.utils.getCurrentDate();
            if (new Date(cardYear, cardMonth, 0).getTime() < new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getTime()) {
                this.utils.ShowError(this.localization.captions.common.Error, this.localization.getError(10720));
                return false;
            }
        }
        return true;
    }

    formatCreditCardExpiryDate(date: string): string {
        if (date && date.length === 6) {
            return date.substring(4, 6) + '/' + date.substring(2, 4); // MM/YY
        }
        return date;
    }

    private BuildPMAgentURI(action: APIAction, outletId = 0): string {
        let pmAgentURI =  this.PropertyInfo.GetPayAgentConfigURIValue();
        let completeURI = '';
        switch (action) {
            case APIAction.RequestSale:
                completeURI = `${pmAgentURI}/sale`;
                break;
            case APIAction.CreateToken:
                completeURI = `${pmAgentURI}/CreateToken`;
                break;
            case APIAction.GetHandles:
                completeURI = `${pmAgentURI}/GetHandles`;
                break;
            case APIAction.Credit:
                completeURI = `${pmAgentURI}/Credit`;
                break;
            case APIAction.GetBalance:
                completeURI = `${pmAgentURI}/GetBalance`;
                break;
            case APIAction.Load:
                completeURI = `${pmAgentURI}/Load`;
                break;
            case APIAction.Issue:
                completeURI = `${pmAgentURI}/Issue`;
                break;
            case APIAction.RequestAuth:
                completeURI = `${pmAgentURI}/Auth`;
                break;
            case APIAction.GetPMAgentVersion:
                completeURI = `${pmAgentURI}/admin/configuration/getPMAgentVersion`;
                break; 
            case APIAction.RefreshPMAgentConfiguration:
                completeURI = `${pmAgentURI}RefreshConfig`;
                break; 
        }
        return completeURI;
    }

    PaymentErrorPrompt(errcodes) {
        switch (parseInt(errcodes)) 
        {
            case PaymentErrorCodes.ClientInvalidRequest:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMInvalidRequest);
            case PaymentErrorCodes.DeviceNotAvailable:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceNotAvailable);
            case PaymentErrorCodes.DeviceNotReady:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceBusy);
            case PaymentErrorCodes.DeviceUserPressedCancel:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceCancel);
            case PaymentErrorCodes.TransactionTimeOut:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMTransactionTimeOut);
            case PaymentErrorCodes.DeviceError:
            case PaymentErrorCodes.CardErrorInvalidAccountNumber:
            case PaymentErrorCodes.CardErrorInvalidExpirationDate:
            case PaymentErrorCodes.CardError:
            case PaymentErrorCodes.CardErrorUnsupportedCardType:
            case PaymentErrorCodes.CardErrorUnsupportedCardIssuer:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.getError(10721));
            case PaymentErrorCodes.DeviceOperationAborted:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMDeviceTimeOut);
            case PaymentErrorCodes.CardDeclinedLimit:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.InsufficientFunds);
            case PaymentErrorCodes.GatewayAuthenticationFailed:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMAuthFailed);
            case PaymentErrorCodes.CardDeclinedChipDecline:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMcardDeclinedChipDecline);
            case PaymentErrorCodes.PaymentManagerTimedOut:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMAgentUnavailable);
            case PaymentErrorCodes.CardNumberNotValid:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.getError(70609));
            case PaymentErrorCodes.CardAlreadyIssued:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.CardAlreadyIssued);
           
            case PaymentErrorCodes.PayAgentDissabledInPaymentManager:
                 return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.PayAgentDissabledInPaymentManager);
            case PaymentErrorCodes.PayAgentNotFoundInPaymentManager:
                  return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.PayAgentNotFoundInPaymentManager);
            case PaymentErrorCodes.PayAgentNotActivatedInPaymentManager:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.GiftCard.PayAgentNotActivatedInPaymentManager);

            case PaymentErrorCodes.CardNumberNotActive:
            case PaymentErrorCodes.CardPINInvalid:
            case PaymentErrorCodes.CardAlreadyActive:
            case PaymentErrorCodes.CardAlreadyInActive:        
            case PaymentErrorCodes.CardExpired:
                const errorMsgMap = {
                    [PaymentErrorCodes.CardNumberNotActive]: 70611,
                    [PaymentErrorCodes.CardPINInvalid]: 70612,
                    [PaymentErrorCodes.CardAlreadyActive]: 70613,
                    [PaymentErrorCodes.CardAlreadyInActive]: 70614,
                    [PaymentErrorCodes.CardExpired]: 70615
                }
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.getError(errorMsgMap[parseInt(errcodes)]));
            case PaymentErrorCodes.DeviceInvalidData:
            default:
                return this.utils.ShowError(this.localization.captions.common.Error, this.localization.captions.shop.PMUnexpectedError);
        }
    }

    async RequestCredit(payRequest: SaleRequest, outletId = 0, ceds: string = ""): Promise<any> {
        const isPMAgentValid = await this.checkPayAgentVersion();   
        if(isPMAgentValid){
            payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, outletId, ceds);
            let uri = this.BuildPMAgentURI(APIAction.Credit, outletId);
            return this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, payRequest);
        } 
        else{
            let response: any = {};
            return response;
        }  
    }
    

    GetSanitizedCardInfoHTML(CurrentActiveCard: CardInfo) {
        let CardOnFileTemplate = this.localization.captions.shop.ProceedWithCardOnFile + `</br>
                                <div class="saved-card">
                                    <div class="saved-card-details">
                                        <span>${CurrentActiveCard.cardNumber}</span>
                                    </div>
                                </div>`
        // return this._sanitizer.sanitize(SecurityContext.HTML,this._sanitizer.bypassSecurityTrustHtml(CardOnFileTemplate));
        return CardOnFileTemplate;
    }

    
    async ValidatePayAgentVersion() {       
        const pmVersionConfig = JSON.parse(this.PropertyInfo.GetPMAgentVersion());
        const paymentConfig = JSON.parse(this.PropertyInfo.GetPMAgentVersion());
        const version = pmVersionConfig.supportedPMAgentVersionNumber;
        const masterPMAgentVersion = paymentConfig.pmAgentVersion;
        const displayPopVersion = this.getHigherVersion(version,masterPMAgentVersion);
        let isPMAgentValid = false;
        try{
            if (version) {
                const result = await this.getPMAgentVersion();
                if(result){
                    this.refreshPMAgentConfiguration();
                }
                if (this.checkVersionValidity(version,result.pmAgentVersion)) {   
                    isPMAgentValid = true;             
                    this.setValidPayAgentInstalled('true', result.pmAgentVersion);
                }
                else{
                    isPMAgentValid = false;    
                    this.setValidPayAgentInstalled('false', result.pmAgentVersion);
                    this.utils.ShowError(this.localization.captions.common.Warning , this.localization.captions.shop.InstalledPayAgentFetchError.replace("{{version}}", displayPopVersion));    
                }
            }  
            else{
                isPMAgentValid = true;    
                this.setValidPayAgentInstalled('true', '');
            }  
        }
        catch(error) {
            if(Number(error.status) == PMAgentApiError.PMAgentVersionFetchError){
                this.utils.ShowError(this.localization.captions.common.Warning , this.localization.captions.shop.InstalledPayAgentFetchError.replace("{{version}}", displayPopVersion));                
            }
            this.setValidPayAgentInstalled(true, 'VERSION NOT DETECTED');
        }
    }

    async checkPayAgentVersion(isShowValidatePayAgentMsg: boolean = true): Promise<boolean> {      
        const isPMAgentValid_Session = sessionStorage.getItem("isValidPayAgentInstalled");
        let isPMAgentValid = isPMAgentValid_Session? Boolean(JSON.parse(isPMAgentValid_Session)) : false;
        const pmVersionConfig = JSON.parse(this.PropertyInfo.GetPMAgentVersion());
        const version = pmVersionConfig.supportedPMAgentVersionNumber;
        try{
            if (version) {
                const result = await this.getPMAgentVersion();
                if (this.checkVersionValidity(version,result.pmAgentVersion) || isPMAgentValid) {
                    isPMAgentValid = true;
                    this.setValidPayAgentInstalled(isPMAgentValid, result.pmAgentVersion);
                }
                else{
                    if(isShowValidatePayAgentMsg && pmVersionConfig.isForceStopTransaction){
                        this.utils.ShowError(this.localization.captions.common.Error , this.localization.captions.shop.UnSupportedPayAgentInstalledError.replace("{{version}}", version));
                    }
                    else{
                        //this.utils.showToastMessage(this.localization.captions.shop.UnSupportedPayAgentInstalledError.replace("{{version}}", version),SnackBarType.Warning);
                    }
                    isPMAgentValid = false;
                    this.setValidPayAgentInstalled(isPMAgentValid, result.pmAgentVersion);
                }
            }  
            else{
                isPMAgentValid = false;
                this.setValidPayAgentInstalled(isPMAgentValid, '');
            }
            isPMAgentValid = isPMAgentValid || !pmVersionConfig.isForceStopTransaction;
        }  
        catch(error) {
            if(Number(error.status) == PMAgentApiError.PMAgentVersionFetchError){
                if(isShowValidatePayAgentMsg){
                    isPMAgentValid = true;
                    this.setValidPayAgentInstalled(isPMAgentValid, 'VERSION NOT DETECTED');
                    //this.utils.ShowErrorMessage(this.localization.captions.common.Error , this.localization.captions.shop.InstalledPayAgentFetchError.replace("{{version}}", version));
                }
            }
            if(Number(error.status) == PMAgentApiError.PMAgentServiceNotUp){
                if(isShowValidatePayAgentMsg){
                    this.utils.ShowError(this.localization.captions.common.Error , this.localization.captions.shop.PayAgentNotInstalledError.replace("{{version}}", version));
                }
            }
        }
        return isPMAgentValid;
    }

    async getPMAgentVersion(): Promise<any>  {  
        const uri = this.BuildPMAgentURI(APIAction.GetPMAgentVersion);
        const result = await this.http.InvokeApiAsync<any>(uri, HttpMethod.Get);
        sessionStorage.setItem('installedPMAgentVersion', result);
        return result;
    }

    async getPMAgentProperties(): Promise<any>  {   
        let pmAgentProperties = {pmAgentVersion : '', pmAgentHostName : '', pmAgentIpAddress : ''};
        try{
            let uri = this.BuildPMAgentURI(APIAction.GetHandles);
            const result = await this.http.InvokeApiAsync<any>(uri, HttpMethod.Post, {tenderId : '0_0_0_0.0'});
                        
            pmAgentProperties.pmAgentVersion   = result?.agentVersion;
            pmAgentProperties.pmAgentHostName  = result?.hostName;
            pmAgentProperties.pmAgentIpAddress = result?.ipAddress;
        }
        catch(e){
            pmAgentProperties.pmAgentVersion   = e?.error[0]?.agentVersion;
            pmAgentProperties.pmAgentHostName  = e?.error[0]?.hostName;
            pmAgentProperties.pmAgentIpAddress = e?.error[0]?.ipAddress;
        }  

        sessionStorage.setItem('installedPMAgentProperties', JSON.stringify(pmAgentProperties));
        return pmAgentProperties;
    }

    async refreshPMAgentConfiguration()
    {  
        try
        {
            const pmAgentVersion = sessionStorage.getItem('installedPMAgentVersion');
            if(pmAgentVersion)
            {
                const pmAgentProperties = await this.getPMAgentProperties();
                if(pmAgentProperties && pmAgentProperties?.pmAgentHostName)
                {
                    const isPMAgentSynced = await this.GetPMAgentSyncStatus(pmAgentProperties.pmAgentHostName);
                    if(!isPMAgentSynced)
                    {                    
                        const uri = this.BuildPMAgentURI(APIAction.RefreshPMAgentConfiguration);
                        const response = await this.http.InvokeApiAsync<any>(uri, HttpMethod.Post);
                        if(response && response?.toUpperCase() == 'SUCCESS')
                        {
                            sessionStorage.setItem('isPMAgentConfigurationRefreshed', 'true');
                        }
                        else
                        {
                            sessionStorage.setItem('isPMAgentConfigurationRefreshed', 'false');
                        }
                    }
                    else
                    {
                        sessionStorage.setItem('isPMAgentConfigurationRefreshed', 'true');
                    }
                }
            }
        }                    
        catch (error) 
        {
            sessionStorage.setItem('isPMAgentConfigurationRefreshed', 'false');
            console.log(`Error Occurred While Refreshing PMAgent Configuration On Application Login, Error : ${error}`);
        } 
    }         

    async GetPMAgentSyncStatus(hostName: String): Promise<Boolean> {
        const response: any = await this.http.CallApiAsync<any>({
            host: Host.payment,
            callDesc: 'PaymentService/PMAgent/GetPayAgentSyncStatus/hostName/{hostName}',
            method: HttpMethod.Get,
            uriParams: { hostName: hostName }
        });
        if (response && response.result) {
            return response.result;
        }
        return false;
    }
    
    public setValidPayAgentInstalled(isValidPayAgentInstalled, installedPMAgentVersion) {
        sessionStorage.setItem('isValidPayAgentInstalled', isValidPayAgentInstalled);
        sessionStorage.setItem('installedPMAgentVersion', installedPMAgentVersion);
    } 
    
    public checkVersionValidity(supportedVersion:string, installedVersion:string):boolean{
        let isVersionValid = false;
        if(supportedVersion && installedVersion){            
            const supportedVersionArray = supportedVersion.trim().split(".");
            const installedVersionArray = installedVersion.trim().split(".");
            if(installedVersionArray && supportedVersionArray && installedVersionArray.length >= 3 && supportedVersionArray.length >= 3){
                if(Number(installedVersionArray[0]) == Number(supportedVersionArray[0])){
                    if(Number(installedVersionArray[1]) == Number(supportedVersionArray[1])){
                        if(Number(installedVersionArray[2]) == Number(supportedVersionArray[2])){
                            if(installedVersionArray.length == 4 && supportedVersionArray.length == 4){
                                if(Number(installedVersionArray[3]) >= Number(supportedVersionArray[3])){
                                    isVersionValid = true;
                                }
                            }
                            else{
                                isVersionValid = true;
                            }
                        }
                        else if(Number(installedVersionArray[2]) > Number(supportedVersionArray[2])){
                            isVersionValid = true;
                        }
                    }
                    else if(Number(installedVersionArray[1]) > Number(supportedVersionArray[1])){
                        isVersionValid = true;
                    }
                }
                else if(Number(installedVersionArray[0]) > Number(supportedVersionArray[0])){
                    isVersionValid = true;
                }
            }
        }
        return isVersionValid;
    }

    public getHigherVersion(propertyPMAgentVersion:string, masterPMAgentVersion:string):string{
        let higherVersion = propertyPMAgentVersion;
        if(propertyPMAgentVersion && masterPMAgentVersion){            
            const propertyPMAgentVersionArray = propertyPMAgentVersion.trim().split(".");
            const masterPMAgentVersionArray = masterPMAgentVersion.trim().split(".");
            if(masterPMAgentVersionArray && propertyPMAgentVersionArray && masterPMAgentVersionArray.length >= 3 && propertyPMAgentVersionArray.length >= 3){
                if(Number(masterPMAgentVersionArray[0]) == Number(propertyPMAgentVersionArray[0])){
                    if(Number(masterPMAgentVersionArray[1]) == Number(propertyPMAgentVersionArray[1])){
                        if(Number(masterPMAgentVersionArray[2]) == Number(propertyPMAgentVersionArray[2])){
                            if(masterPMAgentVersionArray.length == 4 && propertyPMAgentVersionArray.length == 4){
                                if(Number(masterPMAgentVersionArray[3]) >= Number(propertyPMAgentVersionArray[3])){
                                    higherVersion = masterPMAgentVersion;
                                }
                            }
                            else{
                                if(masterPMAgentVersionArray.length == 4 && propertyPMAgentVersionArray.length == 3){
                                    higherVersion = masterPMAgentVersion;
                                }
                            }
                        }
                        else if(Number(masterPMAgentVersionArray[2]) > Number(propertyPMAgentVersionArray[2])){
                            higherVersion = masterPMAgentVersion;
                        }
                    }
                    else if(Number(masterPMAgentVersionArray[1]) > Number(propertyPMAgentVersionArray[1])){
                        higherVersion = masterPMAgentVersion;
                    }
                }
                else if(Number(masterPMAgentVersion[0]) > Number(propertyPMAgentVersionArray[0])){
                    higherVersion = masterPMAgentVersion;
                }
            }
        }
        return higherVersion;
    }
    
    async InvokeCheckPMSCommunicationAPI(): Promise<Boolean>{
        let response : Boolean;    
        this.http.CallApiAsync<any>({
          host: Host.retailManagement,
          callDesc: CommonApiRoutes.CheckPMSCommunication,
          method: HttpMethod.Get,
          showError: true
        }).then(t =>{
          response = t.result;
          sessionStorage.setItem('IsPMSPostingViaCommunicator', String(response));
        }); 
        return response;
    }
}

enum APIAction {
    RequestSale = 1,
    GetHandles,
    CreateToken,
    Credit,
    GetBalance,
    Load,
    Issue,
    RequestAuth,    
    GetPMAgentVersion,
    RefreshPMAgentConfiguration
}

enum PMAgentApiError{
    PMAgentServiceNotUp = 0,
    PMAgentVersionFetchError = 404
}

