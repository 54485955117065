import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder,  UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../../shop-dialog-popup/shop-dialog-popup.component';
import { RedemptionTypeToHandle } from 'src/app/retail/shared/service/payment/payment-business.model';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WalletBusinessService } from 'src/app/retail/shared/business/Wallet-business.service';


@Component({
  selector: 'app-walletcard-selection-dialog',
  templateUrl: './walletcard-selection-dialog.component.html',
  styleUrls: ['./walletcard-selection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WalletCardSelectionDialogComponent implements OnInit {
  ShopCaptions: any;
  walletCardsForm: UntypedFormGroup;
  @Input() datainput: any;
  walletCards: any[] = [];
  selectedWallet: any[] = [];
  

  constructor(private fb: UntypedFormBuilder, 
    public localization: RetailLocalization, 
    public dialogRef: MatDialogRef<ShopDialogPopUp>,
    private _walletBusiness: WalletBusinessService) {
    this.walletCardsForm = this.fb.group({
      walletCardSelection: ''
    })
    this.ShopCaptions = this.localization.captions.shop;    
  }

  ngOnInit() {
    this.walletCards = this.datainput;
    this.walletCards.forEach(item => {
      item.additionalInformation.maskedCardNumber = this._walletBusiness.MaskWalletNumber(item.additionalInformation.WalletNumber)
   });
  }

  onChange(ob: MatCheckboxChange, walletInfo) {
    if(ob.checked == true){
      this.selectedWallet.push(walletInfo);
    }
    else{
      this.selectedWallet.splice(this.selectedWallet.findIndex(v => v.transactionPayment.id == walletInfo.transactionPayment.id), 1);
    }
 } 

  closeDialog(){
    if (this.selectedWallet) {
      this.dialogRef.close(this.selectedWallet);  
    }    
  }

}
