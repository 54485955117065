import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, UtilizationBody } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";

/**
 * Utilization Report
 * @export
 * @class UtilizationReport
 * @extends {BaseReport}
 */
export class Utilization extends BaseReport {
    shiftStartTime: any;
    shiftEndTime: any;
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,public patcheableValue) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {

        return {
            code: AllReports.Utilization,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            //this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            timePicker: true,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'LANDSCAPE'
        };
    }


    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        let startTime = this.localization.TimeToDate(this.startTime);
        let endTime = this.localization.TimeToDate(this.endTime);
        this.shiftStartTime = this.localization.AddTimeToDate(this.startDate, startTime);
        this.shiftEndTime = this.localization.AddTimeToDate(this.endDate, endTime);
        const customControl: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) },
        { "pStartTime": this.startTime }, { "pEndTime": this.endTime },
        { "pPageBreak": this.pageBreakToggle },
        { "pPropertyName": this.propertyName }, { "pDate": this.printedDate },
        { pReportType : this.GetReportType(customControl.radioFilter)},
        { pIncludeRetailRev : customControl.includeRetailRevenue ? this.captions.Yes : this.captions.No},
        { pInactiveStaff : customControl.includeInactivestaff ? this.captions.Yes : this.captions.No},
        { pScheduleOnly : customControl.scheduleOnly ? this.captions.Yes : this.captions.No}];
    }

    protected GetReportType(id){
        switch (id) {
            case 0 : return this.captions.LocationStaff;
            case 1 : return this.captions.LocationOnly;
            case 2 : return this.captions.StaffOnly;
        }
    }

    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }];
    }


    protected formFilters(): UtilizationBody {
        let customControl: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        if (this.commonLocalization.isFromJobScheduler) {
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;

            let obj = {
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement,
                ReportType: this.formGrp.value.customFilterFormGrp.radioFilter,
                IncludeInactiveLocation: false,
                IncludeInactivestaff: this.formGrp.value.customFilterFormGrp.radioFilter.includeInactivestaff,
                IncludeRetailRevenue: this.formGrp.value.customFilterFormGrp.radioFilter.includeRetailRevenue,
                ScheduleOnly: this.formGrp.value.customFilterFormGrp.radioFilter.scheduleOnly,
                StartDate:(this.formGrp.get('startDate').value),
                EndDate: (this.formGrp.get('endDate').value),
            };

            return obj;
        }
        return {
            ReportType: customControl.radioFilter,
            IncludeInactiveLocation: customControl.includeInactiveLocation,
            IncludeInactivestaff: customControl.includeInactivestaff,
            IncludeRetailRevenue: customControl.includeRetailRevenue,
            ScheduleOnly: customControl.scheduleOnly,
            StartDate: this.localization.convertDateTimeToAPIDateTime(this.shiftStartTime),
            EndDate: this.localization.convertDateTimeToAPIDateTime(this.shiftEndTime),
        };
    }
    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }


}
