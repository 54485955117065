import { AddCustomFilterAction, MergedFields } from '../custom-action.interface';
import { GlobalCustomConfig } from '../global-custom-config.interface';

export const CustomActionUrl: GlobalCustomConfig = ( 
    Name: string, 
    Odatakey: string, 
    FieldKey: string, 
    ActionType: string,
    TypeName ?: string,
    IsCustomUrl ?: boolean,
    CustomUrl ?: string,
    CustomFilter ?: Array<AddCustomFilterAction>,
    IsCustomMerge ?: boolean,
    MergerFields ?: Array<MergedFields>,
    MergeSymbol ?: any ): string => {
    try {
        if ( IsCustomUrl ) {
            return CustomUrl;
        }
       
    } catch (error) {
        console.error(error);
    }
};