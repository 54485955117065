import { ViewHeaderConfiguration } from '../view-transaction/view-headerconfig.interface';
import { CustomFormActions } from '../../../../setup-master/shared/interface/form-interface/custom-formaction.interface';
import { ItemListGridFilterConfig } from './item-list-grid-filter.interface';
import { ItemListGridConfiguration } from './item-list-grid.interface';
import { ItemSearch } from './item-list-search.interface';
import { FetchGridProperties } from './item-list-grid-data-extra-fields.interface';
import { ChangeBreadCrumbNameConfig } from './change-breadcrumb-names.interface';
import { FormPatchValueReassign } from './patchvalue-rearrange.interface';
import { HttpMethodSelection } from './http-method.interface';
import { FormValueReset } from './patchvalue-reset.interface';
import { EnableCustomFormFields } from './enable-custom-formfields.interface';
import { GridLookUp } from './grid-lookup-configuration.interface';
import { GridLookUpFilter } from './grid-lookup-filter-config.interface';
import { NomathCalculation } from './no-math.interface';
import { ProcessPoConfig } from './process-po.interface';
import { AddItemList } from './add-item-list.interface';
import { PostPoDialogConfig } from './post-po-popup-config.interface';
import { PostPOSupplierConfiguration } from './post-po-confirmation.interface';
import { PoMiscellanuousCharge } from './po-misc-charges.interface';
import { PrintPIService } from './print-pi.interface';
import { EditPOSupplierConfiguration } from './editpo-config.interface';
import { NonEditableGridColumn } from './hide-grid-header.interface';
import { SetPOReference } from './set-po-reference.interface';
import { PoProcessingOptions } from './process-options.interface';
import { AllocateChargesInfo } from './allocate-charges.interface';
import { ReceivePost } from './post-receive.interface';
import { ReceivingMiscellanuousCharge } from './receiving-misc-charges.interface';
import { TransferPostConfiguration } from './transferPost.interface';
import { InventoryResetPost } from './inventory-reset-post.interface';
import { loadOrderGuide } from './load-order-guide.interface';
import { InventoryResetCancel } from './inventory-reset-cancel.interface';
import { LoadAsRequisition } from './load-as-requisition-save.interface';
import { SalesMediaAssesMent } from './media-assesment.interface';
import { ImportPI } from './import-pi.interface';
import { SpecialInstruction } from './SpecialInstruction.interface';
import { QOOrderSetting } from './OrderSetting.interface';
import { DeliveryDateCutOffCheck } from '../check-delivery-cutoff.interface';
import { PostPOSupplier } from './post-po-suppliers.interface';
import { PODeliveryCutOff } from './po-delivery-cutoff.interface';
import { ItemListSPSearch } from './item-list-sp-search.interface';
import { OpenCurrencyFieldsForPO } from './open-currency-field.interface';
import { HideCurrencyFieldsForRguest } from './hide-currency-fields-rguest.interface';

export const CreateTransaction = {
    createHeaderConfig : ViewHeaderConfiguration,
    itemListSearch: ItemSearch,
    ItemListGrid: ItemListGridConfiguration,
    ItemListGridFilterOption: ItemListGridFilterConfig,
    FetchExtraFields: FetchGridProperties,
    ChangeBreadCrumbName: ChangeBreadCrumbNameConfig,    
    FormPatchValueReassign: FormPatchValueReassign,
    HttpServiceMethod: HttpMethodSelection,
    resetFormValue: FormValueReset,
    enableCustomFields: EnableCustomFormFields,
    LookUpPopupCOnfig: GridLookUp,
    FilterOptionsLookUp: GridLookUpFilter,
    NomathCalculation: NomathCalculation,
    ProcessPo: ProcessPoConfig,
    PostPOSuppliers: PostPOSupplier,
    itemListAdded: AddItemList,
    PostPOPopUpConfig: PostPoDialogConfig,
    PostPOSupplierConfig: PostPOSupplierConfiguration,
    POMiscCharges: PoMiscellanuousCharge,
    PrintPi: PrintPIService,
    EditPO: EditPOSupplierConfiguration,
    NonEditableGridHeader: NonEditableGridColumn,
    SetReferencePo: SetPOReference,
    ProcessOptions: PoProcessingOptions,
    AllocateCharges: AllocateChargesInfo,
    ReceivePost: ReceivePost,
    ReceivingMiscCharges: ReceivingMiscellanuousCharge,
    TransferPost: TransferPostConfiguration,
    InventoryReset: InventoryResetPost,
    loadOrderGuide: loadOrderGuide,
    InventoryResetCancel: InventoryResetCancel,
    LoadAsRequisition: LoadAsRequisition,
    CheckDeliveryDateCutOff: DeliveryDateCutOffCheck,
    MediaAssesment: SalesMediaAssesMent,
    ImportPI: ImportPI,
    OrderSetting: QOOrderSetting,
    SpecialInstruction: SpecialInstruction,
    PODeliveryCutOffCheck: PODeliveryCutOff,
    ItemListSPSearch: ItemListSPSearch,
    OpenCurrencyFields: OpenCurrencyFieldsForPO,
    HideCurrencyFieldsForRguest: HideCurrencyFieldsForRguest
};
