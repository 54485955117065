import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { GlobalSystemPreference, SysytemPreferenceAction } from 'src/app/eatecui/source/setup/shared/interface/system-preference.interface';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { SystemPreferenceConfig } from '../system-preference-configuration';
import _ from 'lodash';
import { ZeroQty } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { Validation } from 'src/app/eatecui/source/shared/utilities/data.validator';
export const NegativeQtyWithRemarks: SystemPreferenceConfig = (
    PreferenceData: Array<any>,
    PreferenceAction: Array<SysytemPreferenceAction>,
    GlobalPreference: Array<GlobalSystemPreference>,
    httpService: HttpService,
    sysytemPreferenceService: SysytemPreferenceService,
    PrferenceType?: any,
    ModuleKey?: string,
    dialog?: MatDialog,
    transactionCollection?: TransactionConfiguration,
    homeGroup?: FormGroup
): any => {
    try {
        return new Promise<boolean>((resolve, reject) => {
            const enablePost = transactionCollection.CreateTranscationConfig?.ItemsConfig?.ItemList?.
                ItemGridConfig.GridData.every(x => !x.Qty || (parseFloat(x.Qty ? x.Qty : '0') >= 0) || ((parseFloat(x.Qty ? x.Qty : '0') < 0) && x.Remark && x.Remark.length > 0));
            if (enablePost) {
                resolve(true);  
            } else {
                const NegativeQuantity = {} as ErrorPopModel;
                NegativeQuantity.PopupHeaderContent = 'Validation';
                NegativeQuantity.PopUpContent = 'Negative entry requires remark.';
                dialog?.open(AgilsysErrorPopComponent, {
                    width: '400px',
                    data: NegativeQuantity,
                    disableClose: true
                });
                reject(false);
            }
        });
    } catch (error) {
        console.error(error);
    }
};