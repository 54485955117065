import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class AuthGuardService {

  constructor(private authentication: AuthenticationService, private router: Router,private oauthService: OAuthService) { }

  canActivate(): boolean | Promise<boolean> {


	if (sessionStorage.getItem('a5smm_utoken') != null && sessionStorage.getItem('propertyDate') != null)
    return true;
      if(sessionStorage.getItem('supportUserMailId')){
        this.router.navigate(["supportlogin"]);
      } else {
        this.router.navigate(['login']);
      }
      return false;
  }

  redirectToLoginPage() {
    if(sessionStorage.getItem('supportUserMailId')){
      this.router.navigate(["supportlogin"]);
    } else {
      this.router.navigate(['login']);
    }
}

}
