import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { ExitMasterModule } from 'src/app/eatecui/source/shared/models/module-exit-popup.interface';

export const MasterModuleExitPopup: ExitMasterModule = (Old_Details?: any,  New_Details ?: any, CloneGridData ?: Array<any>): any => {
  try {
    const OldDetailsKeys = Object.keys(Old_Details);
    const EditedDetails = New_Details.value;
    const NewDetailsKeys = Object.keys(EditedDetails);
    const MatchKeys = ['MinOrder', 'ProductPrice', 'Qty', 'CostRU', 'Price', 'CostPercentage', 'CostBU', 'PurchaseConversion', 'TransactionConversion'];
    const NonMatchKeys = ['ProductsType', 'ProductPictures', 'InventoryPictures', 'IngredientsNutritions', 'Yield', 'RecipePictures', 'RecipeNutritions'];
    let Old_GridData = [];
    let New_GridData: any;
    const DetailKeys = NewDetailsKeys.filter(value => OldDetailsKeys.includes(value));
    const DetailsMatch = [];
    if (Object.keys(Old_Details).length > 0 && Object.keys(EditedDetails).length > 0) {
    DetailKeys.forEach((keys: any) => {
      if (MatchKeys.indexOf(keys) > -1) {
        console.log(Old_Details[keys]);
        console.log(Formater('FixedFormat', Math.round(Number(Old_Details[keys])), null, null));
        const a = Formater('FixedFormat', Math.round(Number(Old_Details[keys])), null, null);
        const b = Formater('FixedFormat', Math.round(Number(EditedDetails[keys])), null, null);
        if (a === b) {
          Old_Details[keys] = a;
          EditedDetails[keys] = b;
        }
      }
      if (NonMatchKeys.indexOf(keys) > -1) {
        Old_Details[keys] = ' ';
        EditedDetails[keys] = ' ';
      }
      const DetailsData = JSON.stringify(Old_Details[keys]) === JSON.stringify(EditedDetails[keys]);
      DetailsMatch.push(DetailsData);

    });
    } else {
      DetailsMatch.push(true);
    }
    const ComparedDetails = DetailsMatch.includes(false); // If false is present in the DetailsMatch, it will return true
    CloneGridData.forEach((x: any) => {
      OldDetailsKeys.forEach((keysComp: any) => {
        if (x.Name === keysComp) {
          Old_GridData = Old_Details[x.Name];
          New_GridData = [...x.NestedGridData.GridData];
        } else {
          if (x.OdataKey === keysComp) {
            Old_GridData = Old_Details[x.OdataKey];
            if (x.NestedGridData) {
              New_GridData = [...x.NestedGridData.GridData];
            } else {
              New_GridData = [];
            }
          }
        }
      });
    });
    console.log(Old_GridData);
    const GridMatch = [];
    const Old_Data = [];
    const GridMatchKeys = ['CreatedDate'];
    if (Old_GridData !== null && Old_GridData !== undefined && Array.isArray(Old_GridData)) {
      Old_GridData.forEach((x: any) => {
        if (x.IsActive === true) {
          Old_Data.push(x);
        }
      });
    }
    if (Old_Data.length > 0 && New_GridData.length > 0) {
    if (Old_Data.length === New_GridData.length) {
      const OldGridKeys = Object.keys(Old_Data[0]);
      const NewGridKeys = Object.keys(New_GridData[0]);
      const GridKeys = NewGridKeys.filter(value => OldGridKeys.includes(value));
      for (let i = 0; i < Old_Data.length; i++) {
          GridKeys.forEach((keys: any) => {
            GridMatchKeys.forEach((matchKeys: any) => {
              if (matchKeys === keys) {
                GridMatch.push(true);
              } else {
                const ItemGridData = JSON.stringify(Old_Data[i][keys]) === JSON.stringify(New_GridData[i][keys]);
                GridMatch.push(ItemGridData);
              }
            });
          });
      }
    } else {
      GridMatch.push(false);
    } } else {
      if (Old_Data.length === New_GridData.length) {
        GridMatch.push(true);
      } else {
        GridMatch.push(false);
      }
    }
    const ComparedItemList = GridMatch.includes(false); // If false is present in the GridMatch, it will return true
    if (ComparedDetails === true || ComparedItemList === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
