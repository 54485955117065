import { Injectable } from '@angular/core';
import { RguestCommunication } from '../common/rguest-communication';
import { HttpClient } from '@angular/common/http';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AppService } from '../../app-service';
import { environment } from 'src/environments/environment';

@Injectable(
    { providedIn: 'root' }
)
export class InternalIntegrationCommunication extends RguestCommunication {

    constructor(httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService) {
        super(environment["InternalIntegration"], httpclient, localization, utilities, PropertyInfo, appService);
    }

}