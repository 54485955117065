import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethods } from '../business/shared.modals';
@Pipe({
    name: 'paymentbuttonvalidate',
})
export class PaymentButtonPipe implements PipeTransform {

    transform(items: any, isReturnWithoutTicket:any, arg1: any, arg2: any, arg3: any, arg4: any, arg5: any, arg6: any, arg7: any, arg8: any, selectedDevice: any,selectedpayment:any ): any {
      if (isReturnWithoutTicket) {
        return true;
      }
      if ( arg6 ) {
         return (arg1 && arg2 > 0 && arg3 !== 0 && !arg7);
      } else {
         if (arg3.value.align === 1) { // multiple
          if ((selectedpayment == PaymentMethods.CreditCard && selectedDevice != '')) {
            return true;
          }
           return ( (arg3.controls.amounttendered.valid && arg4 && !arg7 ) || (arg8 === 0 && !arg7 ))
         } else { // full payment
          if ((selectedpayment == PaymentMethods.CreditCard && selectedDevice != '')) {
            return true;
          }
           return ( (arg3.invalid && arg5 && !arg7) || ( arg8 === 0 && !arg7 ) || (selectedpayment == PaymentMethods.CreditCard && selectedDevice != ''))
         }
      }
    }
}
 