import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridActionsConfig, GridFilter } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { GridInterface } from 'src/app/eatecui/source/setup-master/shared/interface/grid-interface';
import { CustomFilterNavigationUrl } from '../../../../setup-master/shared/interface/master.interface';
import { ViewGridConfiguration } from '../grid-transaction/grid-transaction-config.interface';
import { GridFilterAction, GridFilterData, GridFilterValue, TransactionConfiguration } from '../transaction.interface';

export const ItemListGridFilterConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): GridFilterAction[] => {
        const GridFilterRowData = [];
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFilter.length > 0) {
                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                    GridFilter.forEach((filter: GridFilterAction) => {
                        const navigationProp = {} as CustomFilterNavigationUrl;
                        const Navigation = ViewHeaderEntity[0]['NavigationProperty']
                        .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] ===  filter.FilterOdataKey);
                        if (Navigation.length > 0) {
                            ViewHeaderEntity[0]['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
                                const NavigationProperityName = NavigationProperty['$']['Name'];
                                if (NavigationProperityName === filter.FilterOdataKey) {
                                    let NavigationReferentialConstraint  = '';
                                    if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                                        NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                                    }
                                    const EntityNameList = NavigationProperty['$']['Type'].split('.');
                                    let EntityName = '';
                                    if (EntityNameList.length > 0) {
                                        EntityName = EntityNameList[EntityNameList.length - 1];
                                        navigationProp.RequestUrl = `/inventory/api/${EntityName}`;
                                        navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                                        navigationProp.EnabledCustomField = false;
                                    }
                                }                     
                            });
                        } else {
                            if (filter && filter.hasOwnProperty('CustomFilters') &&
                                filter.CustomFilters.EnableCustomFilter) {
                                navigationProp.RequestUrl = filter.CustomFilters.CustomNavigationUrl;
                                navigationProp.EnabledCustomField = true;
                                navigationProp.NavigationContraintName = filter.FilterOdataKey;
                            } else if (filter && filter.hasOwnProperty('CustomOptions')) {
                                const filterDisplay = {} as GridFilterData;
                                filterDisplay.FilterKey = filter.FilterDisplayName;
                                filterDisplay.FilterValue = filter.CustomOptions;
                                filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                filterDisplay.Type = 'multiselect';
                                navigationProp.RequestUrl = null;
                                GridFilterRowData.push(filterDisplay);
                            }else if (filter && filter.hasOwnProperty('DynamicFilters')&&
                            filter.DynamicFilters.EnableDynamicFilter) {
                                const filterDisplay = {} as GridFilterData;
                                filterDisplay.FilterKey = filter.FilterDisplayName;;
                                filterDisplay.FilterValue = [];
                                filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                                filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                                filterDisplay.Type = 'multiselect';
                                navigationProp.RequestUrl = null;
                                GridFilterRowData.push(filterDisplay);
                                console.log('ItemListGridFilterConfig called!!');
                            }
                        }
                        if ( navigationProp && navigationProp.RequestUrl !== null) {
                            httpService.GethttpMethod(navigationProp.RequestUrl).subscribe((response: any) => {
                            if (response && response.value && response.value.length > 0) {
                            const filterKeyData = response.value.map((data: any) => {
                            const filterKey = {} as GridFilterValue;
                            filterKey.FilterId = GridInterface.GridFilterKeyMapping(filter, data);
                            filterKey.FilterName = GridInterface.GridFilterLabelMapping(filter, data);
                            filterKey.Checked = false;
                            return filterKey;
                            });
                            const filterDisplay = {} as GridFilterData;
                            filterDisplay.FilterKey = filter.FilterDisplayName;
                            filterDisplay.FilterValue = filterKeyData;
                            filterDisplay.FilterOriginalKey = filter.FilterOdataKey;
                            filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                            filterDisplay.Type = 'multiselect';
                            filterDisplay.AdditionalFilterCheck = filter.AdditionalFilterCheck;
                            GridFilterRowData.push(filterDisplay);
                            }
                            if (!TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                                GridActions) {
                                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                                GridActions = {} as GridActionsConfig;
                            }
                            if (!TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                                GridActions.FilterConfig) {
                                    TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                                    GridActions.FilterConfig = {} as GridFilter;
                            }
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                            GridActions.FilterConfig.EnableFilter
                            = GridFilterRowData.length > 0 ? true : false;                   
                            TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.
                            GridActions.FilterConfig.FilterOptions =  GridFilterRowData;
                            },
                            ( responseError ) => {
                            console.error('error caught in component', responseError);
                            });
                        }
                    });
                    
                }
            }
            return GridFilterRowData;
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };