import {
  ScheduledTeeTimeUnPaidPlayer,
  UserCourseConfiguration,
  RainCheckIssue,
  TransactionDetailAggregate,
  ACESPayment,
  ACESPaymentRecord,
  TeeTimeConfig,
  SettingModule,
  SettingScreen,
  RateType,
  PostTypeCompOrder,
  Department,
  PostType,
  TariffCode,
  Departments,
  PostTypeSearchParam,
  ClientInfo,
  TeeTimeTempHoldResult,
  BreakPointResult
} from '../../shared.modal';
import { PayeeInfo } from '../../business/shared.modals';
import { GuestStayDetail } from '../../service/payment/payment-business.model';
import { ClientMultipack, MultiPackReturn, PlayerPaymentstatus, PostError, RoomType, RoomTypeAssignmentRetailItem, RoomTypeAssignmentRetailItemMapping } from 'src/app/retail/retail.modals';
import { CustomFeeSourceType } from '../../globalsContant';
import { LockerAssignmentDetails } from 'src/app/retail/shop/shop.modals';

export class RetailDataAwaiters {

  private static defaultFunc = async function () {
    return null;
  };



  public static getPayeeInfo: (id: number) => Promise<PayeeInfo> = RetailDataAwaiters.defaultFunc;
  public static GetClientMultiPack: (transactionId: number) => Promise<ClientMultipack[]> = RetailDataAwaiters.defaultFunc;

  public static searchPayee: (
    name: string,
    type: number,
    requestUid: string,
    isPlatformGuestSearch?: boolean,
  ) => Promise<PayeeInfo[]> = RetailDataAwaiters.defaultFunc;

  public static searchTransactionGuest: (name: string,
    type: number,
    requestUid: string
  ) => Promise<PayeeInfo[]> = RetailDataAwaiters.defaultFunc;


  public static getMemberInfo: (cardNo: string, date: string, corpId? : number) => Promise<PayeeInfo> = RetailDataAwaiters.defaultFunc;

  public static getPayeeDetails: (id: number[]) => Promise<PayeeInfo[]> = RetailDataAwaiters.defaultFunc;

  public static getGuestInfos: (guestIds: string[]) => Promise<PayeeInfo[]> = RetailDataAwaiters.defaultFunc;

  public static getGuestStayDetails: (guestId: string) => Promise<GuestStayDetail[]> = RetailDataAwaiters.defaultFunc;
  public static GenerateGuestData: (guestId: string) => Promise<any> = RetailDataAwaiters.defaultFunc;
  
  public static openAddPayeePopup: (
    action: string,
    callback: Function,
    id?,
    guestId?,
    modifyLineItemCallback?: Function,
    platformGuestUuid?
  ) => Promise<void> = RetailDataAwaiters.defaultFunc;

  public static openAddPlayerPopup: (
    callback: Function,
    popUpTitle: string,
    data?
  ) => Promise<void> = RetailDataAwaiters.defaultFunc;

  public static openGuestPatronPopup: (
    action: string,
    callback: Function,
    id?,
    guestId?
  ) => Promise<void> = RetailDataAwaiters.defaultFunc;

  public static GetPlayerNames: (transactionId: number) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static GetSelectedProducts: (
    selectedPlayers: ScheduledTeeTimeUnPaidPlayer[],
    customFeeLinkedItemIds?
  ) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static SetP1PlayerId: () => Promise<number> = RetailDataAwaiters.defaultFunc;

  public static GetDefaultCourses: () => Promise<{ id: number; type: string }> = RetailDataAwaiters.defaultFunc;

  public static GetUserCourse: (
    userId: number
  ) => Promise<UserCourseConfiguration[]> = RetailDataAwaiters.defaultFunc;

  public static GetAllCourses: () => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static GetUnpaidPlayers: (
    courseId: number,
    date: string
  ) => Promise<ScheduledTeeTimeUnPaidPlayer[]> = RetailDataAwaiters.defaultFunc;
  public static GetUnpaidPlayersByPlayerStatus: (
    courseId: number,
    date: string,
    playerStatus: PlayerPaymentstatus
  ) => Promise<ScheduledTeeTimeUnPaidPlayer[]> = RetailDataAwaiters.defaultFunc;

  public static PrintAgreement: (transactionId: number, playerId?: number[]) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static PrintCaddyShack: (transactionId: number) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static getActiveTherapists: () => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static getAllTherapists: () => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static getTherapistImage: (therapistId: number[]) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static ReleaseAppointmentLock: (appointmentId: number[]) => Promise<any> = RetailDataAwaiters.defaultFunc;
  
  public static CheckAppointmentStatusByDayPassId: (dayPassId) => Promise<any> = RetailDataAwaiters.defaultFunc;
  
  public static UpdateIsActiveForDayPass: (dayPassId) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static updateRainCheck: (
    settledRainCheck: RainCheckIssue
  ) => Promise<boolean> = RetailDataAwaiters.defaultFunc;

  public static getRainChecks: (
    issueddate: Date,
    pattern: string
  ) => Promise<RainCheckIssue[]> = RetailDataAwaiters.defaultFunc;

  public static GetRainChecksByTeeTime: (
    teeTimeId: number
  ) => Promise<RainCheckIssue[]> = RetailDataAwaiters.defaultFunc;

  public static GetPlayerNamesByTransactionId: (
    transactionId: number
  ) => Promise<TransactionDetailAggregate> = RetailDataAwaiters.defaultFunc;

  public static PrintTeeTicket: (
    courseIds: number[],
    scheduleTeeTimeIds: number[],
    playerIds: number[],
    dateTime: string,
    paidPlayers: any
  ) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static GetDefaultOutlet: () => any = RetailDataAwaiters.defaultFunc;

  public static CheckForUserOutletAccessAndDefaultOutlet: () => any = RetailDataAwaiters.defaultFunc;

  public static ValidateSelectedTerminalExistsInSelectedOutlet: () => any = RetailDataAwaiters.defaultFunc;

  public static CheckItemsAreFromSelectedOutlet: (shopItems) => any = RetailDataAwaiters.defaultFunc;

  public static GetRetailItemsFromPlayers: (
    selectedPlayers: ScheduledTeeTimeUnPaidPlayer[]
  ) => any = RetailDataAwaiters.defaultFunc;

  public static CheckPlayersHaveAnyPendingSettlements: (
    selectedPlayers: ScheduledTeeTimeUnPaidPlayer[]
  ) => any = RetailDataAwaiters.defaultFunc;

  public static ShowConfirmation: (msg: string, callback: Function) => any = RetailDataAwaiters.defaultFunc;

  public static CreatePlayer: (playerobj: any, callback: Function) => any = RetailDataAwaiters.defaultFunc;

  public static GetGuestByPlatformGuestGuid: (platformGuestGuid: string) => Promise<PayeeInfo> = RetailDataAwaiters.defaultFunc;
  //Added
  public static GetExistingPlayer: (patronId: any) => any = RetailDataAwaiters.defaultFunc;

  public static getRainCheckRedeemIds: (
    outletId: number
  ) => Promise<{ greenFeeItem: number; cartFeeItem: number }> = RetailDataAwaiters.defaultFunc;

  public static ARPost: (acesPayment: ACESPayment) => any = RetailDataAwaiters.defaultFunc;

  public static RedeemPoint: (redeemPoint: any) => any = RetailDataAwaiters.defaultFunc;

  public static CreatePaymentRecord: (
    paymentRecord: ACESPaymentRecord
  ) => Promise<ACESPaymentRecord> = RetailDataAwaiters.defaultFunc;

  public static GetPaymentRecordByTransactionId: (
    transactionId: string
  ) => Promise<ACESPaymentRecord[]> = RetailDataAwaiters.defaultFunc;

  public static GetAllPaymentRecords: () => Promise<ACESPaymentRecord[]> = RetailDataAwaiters.defaultFunc;

  public static MemberOrderSummary: (acesPayment: ACESPayment) => any = RetailDataAwaiters.defaultFunc;

  public static ACESRefund: (transactionId: string, pin: string) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static updateGuestProfile: (
    guestId: string,
    playerId: number
  ) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static GetPlayers: (
    courseId: number,
    date: string
  ) => Promise<ScheduledTeeTimeUnPaidPlayer[]> = RetailDataAwaiters.defaultFunc;

  public static GetCateringBooking: (arg, filter) => any = RetailDataAwaiters.defaultFunc;

  public static UpdateBeoCheck: (checkNumber, ticketNumber, tranactionId, isopentran) => any = RetailDataAwaiters.defaultFunc;

  public static UpdateBeoCheckStatus: (tranactionId) => any = RetailDataAwaiters.defaultFunc;

  public static GetSetBeoChecks: (transactionId) => any = RetailDataAwaiters.defaultFunc;

  public static UpdateBeoCheckTransactionId: (transactionId, newtransactionId) => any = RetailDataAwaiters.defaultFunc;

  public static CreateBookingPost: (bookingId, transactionId) => any = RetailDataAwaiters.defaultFunc;
  public static GetChildMenu: (url: string, menutype?) => any = RetailDataAwaiters.defaultFunc;

  public static OpenManualNotifyPopup: (transactionId: number, guestId: number) => any = RetailDataAwaiters.defaultFunc;

  public static SendNotification: (clientInfo: ClientInfo, emailId :string[], isDistributionListRequired:boolean) => any = RetailDataAwaiters.defaultFunc;

  public static GetSettings: (moduleValue: SettingModule, screenValue: SettingScreen) => Promise<TeeTimeConfig> = RetailDataAwaiters.defaultFunc;

  public static getDefaultsSetting: () => any = RetailDataAwaiters.defaultFunc;

  public static UnRedeemRainChecks: (rainCheckIds: number[]) => any = RetailDataAwaiters.defaultFunc;

  public static getTeeTimeSetting: () => any = RetailDataAwaiters.defaultFunc;

  public static GetCourse: (id: number) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static GetAppointmentClientDetails: (transactionId: number) => Promise<any> = RetailDataAwaiters.defaultFunc;

  public static GetAllRateTypes: () => Promise<RateType[]> = RetailDataAwaiters.defaultFunc;

  public static getAllPostTypes: (includeInactive: boolean, includeRelatedData: boolean) => Promise<PostType[]> = RetailDataAwaiters.defaultFunc;

  public static updatePostTypeCompOrder: (postTypes: PostTypeCompOrder[]) => any = RetailDataAwaiters.defaultFunc;

  public static getAllDepartments: () => Promise<Department[]> = RetailDataAwaiters.defaultFunc;

  public static getAllTariffCodes: (includeInactive: boolean) => Promise<TariffCode[]> = RetailDataAwaiters.defaultFunc;

  public static getDepartmentAndPostTypes: () => Promise<{ departments: Departments[], postTypes: PostType[] }> = RetailDataAwaiters.defaultFunc;

  public static getPostTypesByFilter: (body: PostTypeSearchParam) => Promise<PostType[]> = RetailDataAwaiters.defaultFunc;
  public static GetPostTypeForComp: (includeInactive: boolean, includeRelatedData: boolean) => Promise<PostType[]> = RetailDataAwaiters.defaultFunc;

  public static getRoomTypeAssignmentByRetailItem: (retailItemId: number) => Promise<RoomTypeAssignmentRetailItemMapping[]> = RetailDataAwaiters.defaultFunc;
  public static createOrUpdateRoomTypeAssignment: (body: RoomTypeAssignmentRetailItemMapping[], retailItemId: number) => Promise<RoomTypeAssignmentRetailItemMapping[]> = RetailDataAwaiters.defaultFunc;

  public static getNormalizedPostTypes: (checkBodyData) => any = RetailDataAwaiters.defaultFunc;

  public static getAllRoomTypes: () => Promise<RoomType[]> = RetailDataAwaiters.defaultFunc;
  public static getRoomTypeAssignmentAggregate: (retailItemId: number) => Promise<RoomTypeAssignmentRetailItem[]> = RetailDataAwaiters.defaultFunc;
  public static validateOfferCode: (casinoOffer: string) => Promise<boolean> = RetailDataAwaiters.defaultFunc;
  public static CreateInterfacePostingError: (postError: PostError) => any = RetailDataAwaiters.defaultFunc;
  public static UpdateMultiPack: (multipackreturn: MultiPackReturn) => Promise<MultiPackReturn> = RetailDataAwaiters.defaultFunc;
  public static UpdateAppointmentMultiPackId: (clientMultiPack: ClientMultipack[]) => Promise<boolean> = RetailDataAwaiters.defaultFunc;
  public static ReleasePlayerTempHold: (
    playerIds: number[]
  ) => Promise<any> = RetailDataAwaiters.defaultFunc;
  public static RefundAppointmentDeposit: (transactionId: number, returnTransactionId: number, refundAmount: number,sourceType?:CustomFeeSourceType | null) => any = RetailDataAwaiters.defaultFunc;
  public static GetExtendedProfileSearchConfig: () => any = RetailDataAwaiters.defaultFunc;
  public static ReleaseTempHoldPlayers: () => any = RetailDataAwaiters.defaultFunc;
  public static TempHoldPlayers: (playerIds: number[]) => Promise<boolean> = RetailDataAwaiters.defaultFunc;
  public static GetTempHoldPlayers: (playerIds: number[]) => Promise<TeeTimeTempHoldResult[]> = RetailDataAwaiters.defaultFunc;
  public static ValidateBreakPoint: (breakPointNumber: number, showPopup: boolean)=> Promise<BreakPointResult> = RetailDataAwaiters.defaultFunc;
  public static ReleasePlayersInHold: (playerIds: number[]) => Promise<boolean> = RetailDataAwaiters.defaultFunc;
  public static ValidateOutletReference:(outletId:number) => any = RetailDataAwaiters.defaultFunc;
  public static SaveLocker: (lockerAssignmentDetails: LockerAssignmentDetails, isUpdated?: boolean) => Promise<any> = RetailDataAwaiters.defaultFunc;
  public static IsCancelationNoShowEnabled: () => Promise<any> = RetailDataAwaiters.defaultFunc;
  public static ReleaseTempHoldTeeTime: (
    teeTimeTempHold
  ) => Promise<any> = RetailDataAwaiters.defaultFunc;

}
