import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormGroup } from '@angular/forms';
import { ButtonValue, AgDateConfig, AgInputFieldConfig, AgDropdownConfig } from 'src/app/common/Models/ag-models';
import { ActionMode, AlertAction, ButtonType } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AlertType, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { from, of } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-fiscal-period-slider-action',
  templateUrl: './fiscal-period-slider-action.component.html',
  styleUrls: ['./fiscal-period-slider-action.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiscalPeriodSliderActionComponent implements OnInit {
  fiscalPeriodSlideForm: UntypedFormGroup;
  captions: any;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  startingInputConfig: AgInputFieldConfig;
  yearsToAddInput: AgDropdownConfig;
  yearsToAdd: number;
  autoPeriodButton: ButtonValue;
  showAutoPeriod: boolean;
  periodDateInputs:{startDate: AgDateConfig; endDate: AgDateConfig}[];
  sliderInputs: any;
  changeInputValues: any = ''; // form values
  isCreate: boolean;
  fromProcess = false;
  NextSequencialYear: number;
  NextSequencialDate: Date;
  IsPropertyDate = false;
  EditMin: Date;
  EditMax: Date;
  dayend = 0;
  totalFiscals: number = 0;
  defaultTotalFiscals: number = 12;
  periodNumberList: {id:number;value:number;viewValue:string}[];
  yearsList;
  fiscalContent;
  editContent;
  floatLabel;
  periodList: Array<any>;
  updatedPeriodList: { periodStart: Date | null; periodEnd: Date | null; }[];
  currentPeriod: number;
  @Input('sliderInputMode')
  set setsliderInputMode(value: any) {
    if (value) {
      this.isCreate = (value.mode === ActionMode.create);
      this.sliderInputs = value;
      this.showAutoPeriod = false;
      if(value.mode == ActionMode.create){
        if(value.fromProcess){
          this.fromProcess = true;
        }else{
          this.showAutoPeriod = true;
        }
      }
    }
  }

  @Input('periodNumberList')
  set setPeriodList(value){
    this.periodNumberList = value;
  }

  @Input('yearsList')
  set setYearList(value){
    this.yearsList = value;
  }

  @Input('fiscalContent')
  set setfiscalContent(value){
    this.fiscalContent = value
  }

  @Input('editContent')
  set setEditContent(value){
    this.editContent = value;
  }


  @Output() handlePeriodEvent = new EventEmitter();
  @Output() formReady = new EventEmitter();

  constructor(
    private localization: Localization,
    private formbuilder: UntypedFormBuilder,
    private propertyInformation: PropertyInformation,
    private utilities: CommonUtilities) { 
      this.captions = this.localization.captions;
      this.floatLabel = this.localization.setFloatLabel

    }

  ngOnInit() {
    this.initialize();
    this.totalFiscalsCalculation();
    this.enableSave();
  }

  totalFiscalsCalculation() {
    if (this.isCreate === true) {
      if(this.fiscalPeriodSlideForm){
        this.totalFiscals = this.fiscalPeriodSlideForm.controls['noOfPeriods'].value;
      }
      else{
        this.totalFiscals =this.defaultTotalFiscals;
      }
      this.initializeWithData();
    } else {
      this.totalFiscals = this.sliderInputs.formValues.period.length;
      this.fiscalPeriodSlideForm.controls['noOfPeriods'].patchValue(this.totalFiscals);
      this.initializeWithData();
    }
  }

  initialize() {
    this.fiscalPeriodSlideForm = this.formbuilder.group({
      noOfPeriods:this.periodNumberList[0].value,
      yearEnd: '',
      startingYear: this.sliderInputs.startingYear,
      yearToAdd: { id: 1, value: 1, viewValue: '1' },
      periodList: new UntypedFormArray([])
    });
    this.actionButton = {
      type: 'primary',
      label: this.isCreate ? this.captions.btn_add : this.captions.btn_update,
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel
    };
    this.autoPeriodButton = {
      disabledproperty: false,
      label: this.captions.btn_autoPeriod,
      type: 'secondary',
      customclass: 'ag_link'
    };
    this.startingInputConfig = {
      className: 'ag_form-control customInput',
      errorMessageId: 'err_startingYear',
      errorMessage: this.captions.err_startingYear,
      form: this.fiscalPeriodSlideForm,
      formControlName: 'startingYear',
      placeHolderId: 'lbl_startingYear',
      placeHolder: this.isCreate ? this.captions.lbl_startingYear : this.captions.lbl_year,
      showRequired: false,
      disabled: true
    };
    if (this.isCreate) {
      //const yearsList = this.fiscalPeriodSliderActionBusiness.NoOfYears();
      this.yearsToAddInput = {
        // className: 'ag_form-control',
        form: this.fiscalPeriodSlideForm,
        formControlName: 'yearToAdd',
        isFirstEmpty: false,
        placeHolderId: 'lbl_numberOfYearsToAdd',
        placeHolder: this.captions.lbl_numberOfYearsToAdd,
        selectOptions: of(this.yearsList),
        showRequired: false,
      };
    }
    else{
      this.fiscalPeriodSlideForm.controls['noOfPeriods'].patchValue(this.sliderInputs.formValues.period.length);
    }
    this.totalFiscals = this.fiscalPeriodSlideForm.controls.noOfPeriods.value;
    this.addGroupToArray(this.totalFiscals);
    this.periodDateInputs = this.getFormArrayBuilder(this.totalFiscals);  
  }

  addGroupToArray(noOfperiod) {
    if(!this.isCreate){
      noOfperiod = this.sliderInputs.formValues.period.length;
      this.fiscalPeriodSlideForm.controls['noOfPeriods'].patchValue(this.sliderInputs.formValues.period.length);
    }
    const formArr = this.fiscalPeriodSlideForm.get('periodList') as UntypedFormArray;
    formArr.clear();
    for (let i = 0; i < noOfperiod; i++) {
      formArr.push(this.formbuilder.group({
        periodStart: this.isCreate ? '' : new Date(this.sliderInputs.formValues.period[i].periodStart),
        periodEnd: this.isCreate ? '' : new Date(this.sliderInputs.formValues.period[i].periodEnd),
        displayName: this.isCreate ?'Period ' + (i +1): this.sliderInputs.formValues.period[i].displayName
        // push with control value
      }));
    }
  }

  getFormArrayBuilder(noOfperiod): {startDate:AgDateConfig; endDate:AgDateConfig}[] {
    const formPeriodGroup = this.fiscalPeriodSlideForm.get('periodList') as UntypedFormArray;
    const input = [];
    for (let i = 1; i <= noOfperiod; i++) {
      const formGroupName = formPeriodGroup.controls[i - 1] as UntypedFormGroup;
      input.push({
        startDate:{
        errorMessageId: 'err_periodNumberStart',
        errorMessage: this.localization.replacePlaceholders(this.captions.err_periodNumberStart, ['number'], [i]),
        form: formGroupName,
        formControlName: 'periodStart',
        placeHolderId: '',
        placeHolder: this.localization.replacePlaceholders(this.captions.lbl_periodNumberStart, ['number'], [i]),
        className: 'default-width',
        isDateRequired: true
      },
       endDate:{errorMessageId: 'err_periodNumberEnd',
       errorMessage: this.localization.replacePlaceholders(this.captions.err_periodNumberEnd, ['number'], [i]),
       form: formGroupName,
       formControlName: 'periodEnd',
       placeHolderId: '',
       placeHolder: this.localization.replacePlaceholders(this.captions.lbl_periodNumberEnd, ['number'], [i]),
       className: 'default-width',
       isDateRequired: true
      },
      displayName:{
          form: formGroupName,
        formControlName: 'displayName',
        placeHolderId: '',
        placeHolder: this.localization.replacePlaceholders(this.captions.lbl_displayName, ['number'], [i]),
        className: 'default-width',
        required: true
      }
      });
    }
    return input;
  }

  formStatusChangeEvent(eve){

  }

  initializeWithData(){
    this.getNextsequenceyear();
    if (this.sliderInputs) {
      if (!this.isCreate) {
        this.NextSequencialYear = this.sliderInputs.formValues.startingYear;
        //this.localization.getDate(this.sliderInputs.formValues.period[Math.floor((this.totalFiscals - 1) / 2)].periodStart).getFullYear();
      }
      if(this.sliderInputs.fromProcess){
        this.NextSequencialYear = this.sliderInputs.startingYear
      }
      this.buildPeriods();
      this.fiscalPeriodSlideForm.controls.startingYear.setValue(this.NextSequencialYear);
    }
  }

  buildPeriods() {
    this.totalFiscals = this.fiscalPeriodSlideForm.controls.noOfPeriods.value;
    //this.getNextsequenceyear()
    if (this.IsPropertyDate) {
      this.bindAutoPeriodValues(this.totalFiscals, this.NextSequencialYear, 0, 1);
      this.periodDateInputs[0].startDate.minDate = this.EditMin;
      this.periodDateInputs[0] = { ...this.periodDateInputs[0] };
    }
    else {
      const NextDate: Date = this.localization.AddDays(this.NextSequencialDate, 1);
      this.bindAutoPeriodValues(this.totalFiscals, NextDate.getFullYear(), NextDate.getMonth(), NextDate.getDate());
      if (this.isCreate) {
        this.periodDateInputs[0].startDate.minDate = this.localization.AddDays(this.NextSequencialDate, 1);
        this.periodDateInputs[0].startDate = { ...this.periodDateInputs[0].startDate };
      }
    }

  }

  getNextsequenceyear(){
    const fiscalperiods = !this.isCreate ? this.editContent : this.fiscalContent.filter(x => x.startingYear == this.sliderInputs.startingYear);
    if (fiscalperiods.length !== 0) {
      this.NextSequencialDate = this.localization.getDate(fiscalperiods[fiscalperiods.length - 1].periodDates.slice(-1)[0]);
      if (!this.isCreate) {
        const index = fiscalperiods.findIndex(x => x.year === Number(this.sliderInputs.startingYear));
        const propertydate: Date = index === 0 ? this.localization.setCustomYYYYMMDD(this.sliderInputs.startingYear, 0, 1) : null;
        this.EditMin = fiscalperiods[index - 1] ? this.localization.AddDays(this.localization.getDate(fiscalperiods[index - 1].periodDates.slice(-1)[0]), 1) : propertydate;
        //this.EditMax = fiscalperiods[index + 1] ? this.localization.AddDays(this.localization.getDate(fiscalperiods[index + 1].periodDates.slice(0)[0]), -1) : null;
      }
      else {
        const NextYear: Date = this.localization.dateAdd.AddMonths(this.NextSequencialDate, 1);
        this.NextSequencialYear = this.sliderInputs.startingYear + 1;
      }
    }
    else {
      const propertydate = this.propertyInformation.CurrentDate.getFullYear();
      this.IsPropertyDate = true;
      this.NextSequencialYear = propertydate;
      this.EditMin = this.localization.setCustomYYYYMMDD(propertydate, 0, 1);
    }
    //this.sliderInputs.startingYear = this.isCreate ? this.NextSequencialYear : this.sliderInputs.startingYear;
    if(this.isCreate && !this.fromProcess){
      this.sliderInputs.startingYear = this.NextSequencialYear
    }
    else if(this.fromProcess && this.isCreate ){
      this.NextSequencialYear = this.sliderInputs.startingYear
    }
  }

  bindAutoPeriodValues(noOfperiod, years, month, day: number = 0) {
    for (let i = 0; i < noOfperiod; i++) {
      const key = 'periodStart';
      let value = this.isCreate ? this.SetMonthDayYear(years, month, day) : this.localization.getDate(this.periodDateInputs[i].startDate.form.value.periodStart);
      const arr = this.fiscalPeriodSlideForm.controls['periodList'] as UntypedFormArray;
      const formCtrl = arr.controls[i] as UntypedFormGroup;
      value = i > 0 && this.isCreate ? null: value;
      formCtrl.controls[key].setValue(value);
      const lastDay = this.isCreate ? this.SetMonthDayYear(years, month + 1, day - 1) : this.periodDateInputs[i] ? this.localization.getDate(this.periodDateInputs[i].endDate.form.value.periodEnd) : '';
      this.periodDateInputs[i].startDate.maxDate = this.isCreate ? null : lastDay;
      formCtrl.controls['periodEnd'].setValue(this.isCreate ? null : lastDay);
      if (this.periodDateInputs[i - 1] && this.isCreate)
        this.periodDateInputs[i].startDate.minDate = this.localization.AddDays(this.periodDateInputs[0].startDate.form.value.periodStart, 1);
      else
        this.periodDateInputs[i].startDate.minDate = null;
      this.periodDateInputs[i].startDate = { ...this.periodDateInputs[i].startDate };
      if (this.periodDateInputs[i - 1] && this.isCreate)
        this.periodDateInputs[i].endDate.minDate = this.localization.AddDays(this.periodDateInputs[0].startDate.form.value.periodStart, 1);
      else
        this.periodDateInputs[i].endDate.minDate = value;
      this.periodDateInputs[i].endDate = { ...this.periodDateInputs[i].endDate };
      // this.bindYearEnd(i, formCtrl, key);
      month = month + 1;
    }
  }

  SetMonthDayYear(yyyy: number, mm: number, dd: number) {
    if (this.dayend !== 0) {
      return this.localization.setCustomYYYYMMDD(yyyy, mm, this.dayend);
    }
    const monthend: number = this.localization.setCustomYYYYMMDD(yyyy, mm + 1, 0).getDate();
    const day = dd <= monthend ? dd : monthend;
    if (!(dd <= monthend)) {
      this.dayend = monthend;
    }
    return this.localization.setCustomYYYYMMDD(yyyy, mm, day)
  }


  onPeriodDateChange(e, index) {
    this.dayend = 0;
    if (index != undefined) {
      if(this.periodDateInputs[index - 1] != undefined)
      {
        this.periodDateInputs[index - 1].startDate.maxDate = this.localization.AddDays(e[0].value.periodStart, -1);
        this.periodDateInputs[index - 1].startDate = { ...this.periodDateInputs[index - 1].startDate };
        this.periodDateInputs[index - 1].endDate.maxDate = this.localization.AddDays(e[0].value.periodStart, -1);
        this.periodDateInputs[index - 1].endDate.form.controls['periodEnd'].setValue(this.localization.AddDays(e[0].value.periodStart, -1))
        this.periodDateInputs[index - 1].endDate = { ...this.periodDateInputs[index - 1].endDate };
      }
      this.periodDateInputs[index].endDate.minDate = this.localization.AddDays(e[0].value.periodStart, 0);
      this.periodDateInputs[index].endDate = { ...this.periodDateInputs[index].endDate };
      this.enableSave();
    }
  }
  
  onPeriodEndDateChange(e, index){
    if (index<this.totalFiscals) {
      
      const formArr = this.fiscalPeriodSlideForm.get('periodList') as UntypedFormArray;
      const grp = formArr.controls[index+1] as UntypedFormGroup;
      if(grp){
      grp.get('periodStart').setValue(this.localization.AddDays(e[0].value.periodEnd, 1));
      this.periodDateInputs[index + 1].startDate.minDate = this.localization.AddDays(e[0].value.periodEnd, 1);
      this.periodDateInputs[index + 1].startDate = { ...this.periodDateInputs[index + 1].startDate };
      this.periodDateInputs[index + 1].endDate.minDate = this.localization.AddDays(e[0].value.periodEnd, 1);
      this.periodDateInputs[index + 1].endDate = { ...this.periodDateInputs[index + 1].endDate };  
      }
      this.enableSave();
    }
  }

  periodChange(e){
    let previousPeriod = this.currentPeriod == undefined ? 12 : this.currentPeriod;
    this.currentPeriod = e.value;
    if (this.isCreate === true) {
      if(this.fiscalPeriodSlideForm){
        this.totalFiscals = this.fiscalPeriodSlideForm.controls['noOfPeriods'].value;
      }
      else{
        this.totalFiscals =this.defaultTotalFiscals;
      }
    } else {
      this.totalFiscals = this.sliderInputs.formValues.totalFiscals;
    }
    this.totalFiscals = this.fiscalPeriodSlideForm.controls.noOfPeriods.value;
    this.periodList = this.fiscalPeriodSlideForm.value.periodList;
  
    this.updatedPeriodList = [...this.periodList];
    const hasNonNullPeriodStart = this.periodList.some(period => period.periodEnd !== null);
  
    if (hasNonNullPeriodStart) {
      const fromPeriod = "period " + previousPeriod;
      const toPeriod = "period " + this.currentPeriod;
      const fiscalPeriodWarn = this.localization.replacePlaceholders(
        this.captions.lbl_fiscalPeriodAlert,
        ['fromPeriod', 'toPeriod'],
        [fromPeriod, toPeriod]
      );
  
      this.utilities.showAlert(fiscalPeriodWarn, AlertType.Warning, ButtonType.YesNoCancel, (result) => {
        if (result === AlertAction.YES) {
          this.handleAlertYesAction(previousPeriod);
        } else if (result === AlertAction.CANCEL) {
          this.handleAlertCancelAction(previousPeriod);
          this.currentPeriod = previousPeriod;
        } else if (result === AlertAction.NO) {
          this.handleAlertNoAction();
        }
      });
    } else {
      this.handleNoNonNullPeriodStart();
    }
  
    if (previousPeriod < this.currentPeriod) {
      this.actionButton.disabledproperty = true;
    } else if (previousPeriod > this.currentPeriod && this.periodList.slice(0, -(previousPeriod - this.currentPeriod)).every(period => period.periodStart !== null && period.periodEnd !== null)) {
      this.actionButton.disabledproperty = false;
    } else {
      this.actionButton.disabledproperty = true;
    }
  }

  private handleAlertYesAction(previousPeriod: number): void {
    this.updatePeriods(previousPeriod);
  }
  
  private handleAlertCancelAction(previousPeriod: number): void {
    this.fiscalPeriodSlideForm.controls.noOfPeriods.setValue(previousPeriod);
    this.totalFiscals = previousPeriod;
    this.updatePeriods(previousPeriod);
  
    //If all dates are selected
    const allDatesSelected = this.updatedPeriodList.every(period => period.periodStart !== null && period.periodEnd !== null);
    if (allDatesSelected) {
      this.enableSave();
    }
    else{
      this.disableSave();
    }
  }
  
  private handleAlertNoAction(): void {
    this.addGroupToArray(this.totalFiscals)
    this.periodDateInputs = this.getFormArrayBuilder(this.totalFiscals);
    this.buildPeriods();
    this.actionButton = {...this.actionButton};
  }
  
  private updatePeriods(period: number): void {
    this.addGroupToArray(this.totalFiscals);
    this.periodDateInputs = this.getFormArrayBuilder(this.totalFiscals);
    this.buildPeriods();
  
    this.periodList.forEach((period, index) => {
      let formGroup = this.fiscalPeriodSlideForm.get('periodList')['controls'][index] as FormGroup;
      formGroup.get('periodStart').setValue(period.periodStart);
      formGroup.get('periodEnd').setValue(period.periodEnd);
      formGroup.get('displayName').setValue(period.displayName);
    });
  }
  
  private handleNoNonNullPeriodStart(): void {
    this.addGroupToArray(this.totalFiscals);
    this.periodDateInputs = this.getFormArrayBuilder(this.totalFiscals);
    this.buildPeriods();
  }

  onAction(eve){
    let validFrom: boolean = true;
    const periodNumber = [];
    for(let i=0;i<this.fiscalPeriodSlideForm.value.noOfPeriods;i++)
    {
      const val=this.fiscalPeriodSlideForm.value.periodList[i];
      if(val.displayName==''||val.displayName==null)
      {
        this.utilities.showCommonAlert(this.captions.warn_period_displayName, AlertType.Warning, ButtonTypes.Ok);
        validFrom = false;
        break;
      }
      
      if(val.periodStart==null||val.periodEnd==null)
      {
        this.utilities.showCommonAlert(this.captions.warn_period_validation, AlertType.Warning, ButtonTypes.Ok); 
        validFrom = false;
        break;
      }
      else{
        periodNumber.push(i + 1);
      }
    }
    const clickReturnValue = {
      from: this.sliderInputs.mode,
      formValues: this.fiscalPeriodSlideForm.value,
      startingYear: this.sliderInputs.startingYear,
      periodNumber: periodNumber,
      validfrom: validFrom
    };
    
    this.handlePeriodEvent.emit(clickReturnValue);
  }

  onCancel(eve){
    const clickReturnValue = {
      from: ActionMode.cancel,
      formValues: this.fiscalPeriodSlideForm.value,
      startingYear: this.sliderInputs.startingYear,
      isDirty: this.fiscalPeriodSlideForm.dirty,
      validfrom: true
    };
    
    this.handlePeriodEvent.emit(clickReturnValue);
  }

  enableSave(){
    this.actionButton.disabledproperty = !(this.fiscalPeriodSlideForm.dirty && this.fiscalPeriodSlideForm.valid);
    this.actionButton = {...this.actionButton};
  }

  disableSave() {
    this.actionButton.disabledproperty = true;
    this.actionButton = { ...this.actionButton };
  }
}