import { Component, Inject, OnChanges, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModel } from '../../agilysys-buttons/models/agilysys-buttons.interface';
import { ErrorPopModel, IframePopupModel } from '../model/pop-up.interface';
import { MasterManagementService } from '../../setup-master/shared/service/master.service';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import { AgilysysPopupService } from '../services/agilysys.popup.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-agilysys-iframe',
    templateUrl: './agilysys-iframe-popup.component.html',
    styleUrls: ['./agilysys-iframe-popup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AgilsysIframePopComponent {
    agilysyserrorlogButton: AgilysysButtonModel[];
    LoaderEnable = false;
    constructor(
      public dialogRef: MatDialogRef<AgilsysIframePopComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IframePopupModel, private agilysysPopupService: AgilysysPopupService, 
        private toastrService: ToastrService) {
            this.LoaderEnable = true;
            this.agilysyserrorlogButton = [
                {
                  Id: 1,
                  Name: 'confirm',
                  DisplayName: data.ButtonName === 'Supplier' ? 'Set ' + data.ButtonName : 'Set Item',
                  Class: 'agilysys-button-primary',
                  DisableProperity: false,
                  onCellClick: (returnValue: AgilysysButtonModel) => {
                    const enableIframeButton = StorageService.GetSessionStorage('EnableIframeButton');
                   if ( enableIframeButton && enableIframeButton !== null && enableIframeButton !== 'null' ) {
                     this.dialogRef.close(returnValue.Name);
                   } else {
                    let Msg: string;
                    switch (data.ButtonName) {
                      case 'Supplier': 
                            Msg = 'Save supplier initially, Then try to set the supplier';
                            break;
                      case 'Inventory':
                            Msg = 'Save inventory initially, Then try to set the item';
                            break;
                      case 'Product':
                            Msg = 'Save product initially, Then try to set the product';
                            break;
                      case 'Customer':
                            Msg = 'Save customer initially, Then try to set the customer';
                            break;
                      case 'Recipe':
                            Msg = 'Save recipe initially, Then try to set the recipe';
                            break;
                    }
                    const message = Msg;
                    this.toastrService.error(message, '', {
                      timeOut: 3000,
                      closeButton: true,
                    });
                   }
                  }
                }
              ];
        }

    onLoad() {
      try {
        this.LoaderEnable = false;
        const iframe: any = document.getElementById('eatec-inventoryadd-frame');
        const style = document.createElement('style');
        style.textContent = '.layout-container { height: 100% !important; }';
        iframe?.contentDocument?.head?.appendChild(style);
      } catch (error) {
        console.error('Error occurred in onLoad', error);
      }
    }
}

