import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApprovalHistoryModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';

@Component({
  selector: 'app-approval-history',
  templateUrl: './approval-history.component.html',
  styleUrls: ['./approval-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApprovalHistoryComponent {

  constructor(
    public dialogRef: MatDialogRef<ApprovalHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ApprovalHistoryModel,
  ) { }

}
