import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Output, Input, OnDestroy, AfterViewChecked } from '@angular/core';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormGroup, FormArray } from '@angular/forms';
import {
  HttpServiceCall,
  HttpMethod
} from '../../../service/http-call.service';
import { Module, Host, ImgRefType, SPAManagementBreakPoint, PatronInfoSearchResultType, DefaultSettings, clientSearchType, noRecordsType, DefaultExistingClientCategory } from '../../../globalsContant';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { Addresscomponent, BaseResponse, Email, ImageData, PhoneNumber, NoDataModel } from '../../../business/shared.modals';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { ReplaySubject } from 'rxjs';
import { EmptyValueValidator } from '../../../Validators/EmptyValueValidator';
import { ClientService } from '../../../service/client-service.service';
import { takeUntil } from 'rxjs/operators';
import { ClientCommonService } from 'src/app/client/client.service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { AgContactFieldConfig, SystemConfigKeys } from 'src/app/shared/shared.modal';
import { ContactType, PhoneTypes } from 'src/app/retail/shared/shared.modal';
import { GOOGLE_MAP_API } from 'src/app/app-constants';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SelectEFormsComponent } from 'src/app/common/shared/shared/select-e-forms/select-e-forms.component';
import { DmConfig } from 'src/app/common/Models/common.models';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { Localization } from 'src/app/common/localization/localization';
import { ConfirmClientsPopupComponent } from '../confirm-clients-popup/confirm-clients-popup.component';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { GuestSearchData } from '../../create-appointment/client-information/GuestSearch-model';
import { GuestType } from '../../appointment-popup.model';
@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PersonalInformationComponent implements OnInit, OnDestroy, AfterViewChecked {
  thumbnailImg: any;
  phoneTypes = PhoneTypes;
  contactTypes = ContactType;
  placeHolderFormat: any;
  captionsCommon: any;
  isGuestRecordPurged: boolean = false;
  allCaptions: any;
  noDataOptions: NoDataModel;
  ngAfterViewChecked(): void {
    if (this.appointmentService.clientEditData && this.appointmentService.clientWidowActionType == 'EDIT') {
      this.clientservice.validateClientSave()
    } else {
      this.clientservice.validatePhoneAndEmail();
    }
  }

  phoneCountArray: any = [{ id: 0, removeLine: false, addLine: true }];
  addressLineArray: any = [{ id: 0, addLine: true, removeLine: false }];
  personalDetails: any = [];
  personalDetailsForm: UntypedFormGroup;
  maxDate = this.PropertyInfo.CurrentDate;
  FormGrp: UntypedFormGroup;
  contactTypeEmail: any = [];
  contactTypePhone: any = [];
  clientConfiguration: any = [];
  currentaddIndex: any = 0;
  currentIndexemail: any = 0;
  currentIndexPhone: any = 0;
  selectedEmail: any;
  validateEmailType: string;
  validatePhoneType: string;
  titledropdownInput: { form: UntypedFormGroup; formControlName: string; isdisabled: boolean; placeholderName: string; className: string; showRequired:boolean; errorMessage:string };
  titles = [{ id: 1, value: 'Dr',viewValue: 'Dr' }, { id: 2, value: 'Fr',viewValue: 'Fr' }, { id: 3, value: 'Miss', viewValue: 'Miss' },
  { id: 4, value: 'Mr', viewValue: 'Mr' }, { id: 5, value: 'Mrs',viewValue: 'Mrs' }, { id: 6, value: 'Ms', viewValue: 'Ms' },
  { id: 7, value: 'Prof',viewValue: 'Prof' }, { id: 8, value: 'Rev',viewValue: 'Rev' },{ id: 9, value: 'Mx',viewValue: 'Mx' }];

  options = {
    types: ['geocode']
  };
  defaultSettings: DefaultSettings[] = [];
  dmConfig: DmConfig;
  showViewEform: boolean = false;
  dmResponse: any;
  genderList: any[] = [];
  IsEdit: boolean;
  captions: any;
  selectedFile: any;
  url: any;
  ImageUploaded: boolean;
  editImageId: any;
  imageObj: any;
  textmaskFormat: string;
  emailRequired: boolean;
  phoneRequired: boolean;
  AddressRequired: boolean;
  isPatronIdAvailable = false;
  showLoader: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isCMSConfigured: boolean = false;
  phoneInputs: AgContactFieldConfig;
  automationId: string;
  mailInputs: AgContactFieldConfig;
  phoneInfo: PhoneNumber[];
  mailInfo: Email[];
  filteredCountries: Observable<any>;
  floatLabel: string;
  floatLabelNever: string;
  showClose: boolean = true;
  similarProfiles = [];
  searchProfiles = [];
  profileSearchBy: { id: clientSearchType; name: string; description: any; }[];
  searchPlaceholder;
  inputType;
  linkProfile: UntypedFormGroup;
  actionButton: ButtonValue;
  isExpand: boolean = false;
  searchValue: string = '';
  spaGuestForLinking: any;
  initialGuestData: any;
  isSearchCallCompleted: boolean = false;
  clearSimilarSearchValue: any;
  defaultExistingClientCategory: number = 0;

  @Input() inputData;
  @Input() clientEditData;
  @Input("isExpand")
  set matchingGuests(value: boolean) {
    this.isExpand = value;
    if (value && this.similarProfiles.length == 0) {
      this.getMatchingPofiles();
    }
  }
  @Input('changedClientData')
  set changeClientData(value) {
    if (value) {
      this.initializeFormData();
    }
  }
  @Output() updateGuestDetails = new EventEmitter();
  @Output() personalInfoParams: EventEmitter<any> = new EventEmitter();

  constructor(
    public appointmentService: AppointmentpopupService,
    private Form: UntypedFormBuilder,
    private http: HttpServiceCall,
    public localization: SpaLocalization,
    private domSanitizer: DomSanitizer,
    private utils: SpaUtilities,
    private BP: BreakPointAccess,
    private PropertyInfo: SpaPropertyInformation,
    private clientservice: ClientService,
    private clientCommonService: ClientCommonService,
    private _featureSwitch: RetailFeatureFlagInformationService,
    public dialog: MatDialog,
    private dmConfigDataService: DMConfigDataService,
    private _localization: Localization
  ) {
    let defaultSettingData = sessionStorage.getItem('defaultSettings');
    if (defaultSettingData) {
      var settingValue = JSON.parse(defaultSettingData)?.find(d => d.switch == DefaultExistingClientCategory && d.isActive).value;
      this.defaultExistingClientCategory = settingValue == null ? 0 : Number(settingValue);
    }
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.defaultSettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
    this.captions = this.localization.captions.bookAppointment;
    this.allCaptions = this._localization.captions;
    this.captionsCommon = this.localization.captions.common;
    this.genderList = [{ text: this.captions['Male'], value: 'Male' }, { text: this.captions['Female'], value: 'Female' }];
    this.placeHolderFormat = this.localization.inputDateFormat;
    this.FormGrp = this.Form.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      pronounced: '',
      dob: '',
      pincode: '',
      title: '',
      gender: '',
      Email: this.Form.array([this.createEmailItem(0, '', '', false, false, '')]),
      Phone: this.Form.array([this.createPhoneItem(0, '', '', '', false, false, '')]),
      Address: this.Form.array([this.createAddressItem('', false)]),
      emailPrimary: false,
      phonePrimary: false,
      state: '',
      city: '',
      country: '',
      platformAddressUuid: '',
      platformAddressList: [],
      postal_code: '',
      patronid: '',
      rank: '',
      platformBussinessCardRevUuid: '',
      platformBussinessCardUuid: '',
      platformGuestUuid: '',
      platformRevUuid: ''
    });
    this.linkProfile = this.Form.group({
      profiles: 1,
      searchBy: 0
    })

    this.setDataForPhoneEmailTitle();
    this.appointmentService.personalDetailsFormGroup = this.FormGrp;
    this.appointmentService.personalDetailsFormGroup['CreateClientValid'] = false;
    this.appointmentService.personalIsValid = this.FormGrp.valid;
    this.isCMSConfigured = this._featureSwitch.IsCMSConfigured;
    this.profileSearchBy = this.getProfileSearch();
    this.linkProfile.get('searchBy').setValue(this.defaultExistingClientCategory);
    this.profileSearchChange(this.defaultExistingClientCategory);
    this.actionButton = { type: 'primary', label: this.allCaptions.btn_search, disabledproperty: true };
  }

  getProfileSearch() {
    return [
      { id: clientSearchType.firstName, name: 'firstName', description: this.allCaptions.firstName },
      { id: clientSearchType.lastName, name: 'lastName', description: this.allCaptions.lastName },
      { id: clientSearchType.name, name: 'fullName', description: this.allCaptions.name },
      { id: clientSearchType.phone, name: 'phoneNumber', description: this.allCaptions.phoneNo },
      { id: clientSearchType.email, name: 'email', description: this.allCaptions.email }
    ];
  }

  profileSearchChange(searchType: number) {
    this.clearSimilarSearchValue = '';
    this.clearSimilarSearchValue = { ...this.clearSimilarSearchValue };
    switch (searchType) {
      case clientSearchType.firstName:
        this.searchPlaceholder = this.allCaptions.searchByFirstName;
        this.inputType = ''
        break;
      case clientSearchType.lastName:
        this.searchPlaceholder = this.allCaptions.searchByLastName;
        this.inputType = ''
        break;
      case clientSearchType.name:
        this.searchPlaceholder = this.allCaptions.searchByFullName;
        this.inputType = ''
        break;
      case clientSearchType.email:
        this.searchPlaceholder = this.allCaptions.searchByEmail;
        this.inputType = ''
        break;
      case clientSearchType.phone:
        this.searchPlaceholder = this.allCaptions.searchByPhoneNumber;
        this.inputType = 'onlynumber'
        break;
      default:
        break;
    }
  }

  async onProfileSelection(profile, array) {
    var newProfileSelected = !this.spaGuestForLinking || profile.guestId != this.spaGuestForLinking?.clientDetail.guestId;
    array.forEach(element => {
      element.isSelected = false
      if (newProfileSelected)
        element['selectedGuestType'] = GuestType.SpaGuest;
    });
    profile.isSelected = !profile.isSelected;
    if (profile.isSelected) {
      this.dialog.open(ConfirmClientsPopupComponent, {
        width: '20%',
        height: '27%',
        disableClose: true,
        data: profile.selectedGuestType
      })
        .afterClosed().subscribe(async res => {
          if (res > 0) {
            this.utils.ToggleLoader(true);
            profile.selectedGuestType = res;
            if (res == GuestType.SpaGuest) {
              if (newProfileSelected) {
                this.spaGuestForLinking = await this.clientservice.getClientInfoByGuid(profile.guestId);
                this.spaGuestForLinking.clientDetail.clientLinkId = this.initialGuestData.clientDetail.clientLinkId;
              }
              this.updateGuestDetails.emit(this.spaGuestForLinking);
              newProfileSelected = false;
            }
            else if (res == GuestType.OperaGuest) {
              this.initialGuestData.clientDetail.guestId = profile.guestId;
              this.initialGuestData.clientDetail.id = profile.id;
              this.updateGuestDetails.emit(this.initialGuestData);
            }
            this.clientservice.isExistingGuestSelectedForLinking = true;
            this.utils.ToggleLoader(false);
          }
        })
    }
  }

  searchValueChange(e) {
    this.searchValue = e;
    if (e.length >= 3) {
      this.actionButton.disabledproperty = false;
    }
    else {
      this.actionButton.disabledproperty = true;
    }
  }

  async searchProfile() {
    if (!this.actionButton.disabledproperty) {
      this.utils.ToggleLoader(true);
      this.searchProfiles = await this.clientservice.SearchClientInformation(this.searchValue, false, 0, this.linkProfile.get('searchBy').value);
      this.isSearchCallCompleted = true;
      this.utils.ToggleLoader(false);
    }
  }

  async getMatchingPofiles() {
    this.utils.ToggleLoader(true);
    this.similarProfiles = await this.clientservice.getMatchingGuests(this.buildGuestSearchData());
    this.utils.ToggleLoader(false);
  }

  buildGuestSearchData(): GuestSearchData {
    return {
      firstName: this.appointmentService.clientEditData.clientDetail.firstName ?? '',
      lastName: this.utils.capitalizeFirstLetter(this.appointmentService.clientEditData.clientDetail.lastName) ?? '',
      phoneNumber: this.phoneInfo?.map(p => p.number) ?? [],
      emailAddress: this.mailInfo?.map(m => m.emailId) ?? []
    }
  }

  setDataForPhoneEmailTitle() {
    this.phoneInputs = {
      form: this.FormGrp,
      formArrayName: 'Phone',
      showSwitches: true,
      formControlName: 'PhoneNumber',
      typeControlName: 'PhoneNumberLabel',
      isPrimaryName: 'PhonePrimary',
      isPrivateName: 'PhonePrivate',
      disabled: false,
      automationId: 'personalInformation'
    };
    this.mailInputs = {
      form: this.FormGrp,
      formArrayName: 'Email',
      showSwitches: true,
      formControlName: 'EmailId',
      typeControlName: 'EmailLabel',
      isPrimaryName: 'EmailPrimary',
      isPrivateName: 'EmailPrivate',
      disabled: false,
      automationId: 'personalInformation'
    };
    let isTitleRequired : boolean = false;
    if(this.clientConfiguration?.length > 0)
    {
      isTitleRequired = this.clientConfiguration[0]['CLIENT_TITLE']
    }
    this.titledropdownInput={
      form: this.FormGrp,
      formControlName: 'title',
      isdisabled:false,
      placeholderName:this.captions.CTitle,
      className:'ag_w--100 ag_ml--1',
      showRequired: isTitleRequired,
      errorMessage : isTitleRequired ? this.captions.MissingTitle : ''
    }
  }

  createAddressItem(_address?: any, _addressPrivate?: any): UntypedFormGroup {
    return this.Form.group({
      addressLine: [_address !== '' ? _address : '', this.AddressRequired ? [Validators.required, EmptyValueValidator] : ''],
      privateAddress: _addressPrivate,
      disabled: false
    });
  }
  Address: any = [];

  addAddressItem(idx: any, _addressLine?: any, _addressPrivate?: any): void {

    this.currentaddIndex = idx + 1;
    this.Address = this.FormGrp.get('Address') as UntypedFormArray;
    if (this.Address.controls.length > 2) {
      return;
    }
    this.Address.push(this.createAddressItem(_addressLine, _addressPrivate));
  }

  removeAddressItem(i) {
    this.Address.removeAt(i);
    this.currentaddIndex = i - 1;

  }


  createEmailItem(arr: number, _EmailLabel?: any, _EmailId?: any, _EmailIsPrivate?: any, _EmailIsPrimary?: any, _PlatformContactUuid?: any): UntypedFormGroup {

    const emailLabel = this.defaultSettings?.find(x => x.switch == 'DEFAULT_EMAIL_TYPE');
    if (emailLabel && emailLabel.value != "0" && (_EmailLabel == 0 || _EmailLabel == "")) {
      _EmailLabel = emailLabel && emailLabel.value ? Number(emailLabel.value) : '';
    }
    else if ((!emailLabel || emailLabel.value == '0') && this.appointmentService.clientNameInfo?.phone?.email > 0) {
      _EmailLabel = emailLabel && emailLabel.value != "0" ? Number(emailLabel.value) : _EmailLabel;
    }



    return this.Form.group({
      EmailLabel: [_EmailLabel, this.emailRequired || _EmailId ? [Validators.required, EmptyValueValidator] : ''],
      EmailId: [_EmailId, this.emailRequired ? [Validators.required, Validators.email, EmptyValueValidator] : ''],
      EmailPrimary: _EmailIsPrimary,
      EmailPrivate: _EmailIsPrivate,
      PlatformContactUuid: _PlatformContactUuid
    });
  }
  Email: any = [];

  addEmailItem(i, _EmailLabel?: any, _EmailId?: any, _EmailIsPrivate?: any, _EmailIsPrimary?: any, _PlatformContactUuid?: any): void {
    this.currentIndexemail = i + 1;
    this.Email = this.FormGrp.get('Email') as UntypedFormArray;
    this.Email.push(this.createEmailItem(i, _EmailLabel, _EmailId, _EmailIsPrivate, _EmailIsPrimary, _PlatformContactUuid));
  }

  removeEmailItem(i: any, d?: any, f?: any) {
    this.Email.removeAt(i);
    this.currentIndexemail = i - 1;
  }

  removePhoneItem(i: any, e?: any, d?: any) {
    this.Phone.removeAt(i);
    this.currentIndexPhone = i - 1;
  }

  Phone: any = [];

  createPhoneItem(arr: number, _phoneNoLabel: any, _countryCode: any, _phoneNoDetails: any, _phoneIsPrivate: any, _phoneIsPrimary: any, extension?: any, _platformContactUuid?: any): UntypedFormGroup {

    if (_countryCode == '') {
      let countryCode = this.defaultSettings?.find(x => x.switch == 'DEFAULT_COUNTRY_CODE');
      _countryCode = countryCode?.value && Number(countryCode.value) > 0 ? countryCode.value : _countryCode;
    }
    let phoneNoLabel = this.defaultSettings?.find(x => x.switch == 'DEFAULT_PHONE_TYPE');
    if (phoneNoLabel && phoneNoLabel.value != "0" && (_phoneNoLabel == 0 || _phoneNoLabel == "")) {
      _phoneNoLabel = phoneNoLabel && phoneNoLabel.value ? Number(phoneNoLabel.value) : '';
    }
    else if ((!phoneNoLabel || phoneNoLabel.value == '0') && this.appointmentService.clientNameInfo?.phone?.length > 0) {
      _phoneNoLabel = phoneNoLabel && phoneNoLabel.value != "0" ? Number(phoneNoLabel.value) : _phoneNoLabel;
    }


    return this.Form.group({
      PhoneNumberLabel: [_phoneNoLabel, this.phoneRequired || _phoneNoDetails ? [Validators.required, EmptyValueValidator] : ''],
      countryCode: [_countryCode, this.setCountryCodeValidator(this.phoneRequired, _phoneNoLabel)],
      PhoneNumber: [_phoneNoDetails, this.phoneRequired ? [Validators.required, EmptyValueValidator] : ''],
      PhonePrivate: _phoneIsPrivate,
      PhonePrimary: _phoneIsPrimary,
      Extension: extension,
      PlatformContactUuid: _platformContactUuid
    });
  }

  setCountryCodeValidator(phoneRequired, _phoneNoLabel) {
    if (phoneRequired && _phoneNoLabel && _phoneNoLabel === 1) {
      return [Validators.required, EmptyValueValidator];
    } else {
      return '';
    }
  }

  makeFormDirty() {
    this.appointmentService.personalDetailsFormGroup.markAsDirty();
  }

  addPhoneItem(i, _phoneNoLabel: any, _countryCode: any, _phoneNoDetails: any, _phoneIsPrivate: any, _phoneIsPrimary: any, extension: any = '', _platformContactUuid: any): void {
    this.Phone = this.FormGrp.get('Phone') as UntypedFormArray;
    this.Phone.push(this.createPhoneItem(i, _phoneNoLabel, _countryCode, _phoneNoDetails, _phoneIsPrivate, _phoneIsPrimary, extension, _platformContactUuid));
    this.currentIndexPhone = i + 1;
  }

  togglePrimaryContact(formArrayName: string, formGroupName: any, formControlName: any) {
    let arr = this.FormGrp.get(formArrayName) as UntypedFormArray;
    let ctrls = arr.controls.filter((x, idx) => idx != formGroupName);
    ctrls.forEach(x => {
      let grp = x as UntypedFormGroup;
      grp.controls[formControlName].setValue(false);
    });
  }


  //enabling the extension only if the selected value is 'Office'
  enableExtension(index: number): boolean {
    let phoneControlsArr: any = this.FormGrp.get('Phone') as UntypedFormArray;
    let phoneNoSelectedValue: any = phoneControlsArr.at(index).controls['PhoneNumberLabel'].value
    let officeDesc: string = '';
    if (phoneNoSelectedValue) {
      let officeNo = this.contactTypePhone.find(d => d.id == phoneNoSelectedValue);
      officeDesc = officeNo ? officeNo.description : '';
    }
    return (this.localization.captions.common.Work.toLowerCase() == officeDesc.toLowerCase())
  }
  ngOnInit() {
    this.clientservice.isExistingGuestSelectedForLinking = false;
    this.noDataOptions = { inputText: this.localization.captions.common.NoDataFound, noDataId: noRecordsType.noRecords, noDataFontSize: 10 };
    this.initialGuestData = this.appointmentService.clientEditData;
    this.initializeFormData();
    this.getDmconfig();
    if (this.appointmentService.orderSummaryPatronId) {
      let patronId = this.appointmentService.orderSummaryPatronId;
      this.FormGrp.controls.patronid.setValue(patronId);
      this.appointmentService.searchClientByPatron(patronId, this.searchPatronCallBack.bind(this));
    }
    this.utils.geCountriesJSON().then(res => {
      this.filteredCountries = this.FormGrp.controls.country.valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        map(country => country ? this.utils.FilterCountry(country, this.utils.countryDetails) : [])
      );
      this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (this.FormGrp.controls['city'].value) {
          this.FormGrp.controls.country.setErrors({ required: true });
          this.FormGrp.controls['country'].markAsTouched();
        }
        if (this.FormGrp.controls['country'].value &&
          !this.utils.FilterCountryValueFromData(this.FormGrp.controls['country'].value)) {
          this.FormGrp.controls.country.setErrors({ invalid: true });
          this.FormGrp.controls['country'].markAsTouched();
        } else if ((this.FormGrp.controls['city'].value &&
          this.utils.FilterCountryValueFromData(this.FormGrp.controls['country'].value) &&
          this.FormGrp.controls['country'].value)) {
          this.FormGrp.controls.country.setErrors(null);
        }
      });
    });
    this.linkProfile.get('profiles')?.valueChanges.subscribe(() => {
      this.linkProfile.get('searchBy').setValue(this.defaultExistingClientCategory);
      this.profileSearchChange(this.defaultExistingClientCategory);
      this.isSearchCallCompleted = false;
      this.searchProfiles = [];
    })
  }

  initializeFormData() {
    this.url = this.appointmentService.memberImageUrl;
    this.textmaskFormat = this.localization.captions.common.PhoneFormat != '' ? this.localization.captions.common.PhoneFormat : '999999999999999999';
    this.personalDetails = this.appointmentService.resultNewClient;
    this.makeGetCall('GetClientConfiguration');
    this.contactTypePhone = this.localization.ContactTypes.Phone;
    this.contactTypeEmail = this.localization.ContactTypes.Email;
    this.validateEmailType = this.localization.getError(-87);
    //this.validatePhoneType = this.localization.getError(-88);
    this.appointmentService.isClientViewOnly = false;
    this.appointmentService.isPreferenceViewOnly = false;
    this.appointmentService.isSoapNotesViewOnly = false;
    this.appointmentService.isClientImgRemoved = false;

    if (this.appointmentService.clientEditData && ['EDIT', 'TEMPEDIT'].includes(this.appointmentService.clientWidowActionType)) {
      if (!this.appointmentService.copySelectedClient) {
        this.appointmentService.clientImageObj.pipe(takeUntil(this.destroyed$)).subscribe(x => {
          this.imageObj = x;
          if (this.imageObj && this.imageObj.length > 0 && this.appointmentService.guestId == this.imageObj[0].imageReferenceId) {
            if (this.imageObj[0].contentType.includes('base64')) {
              this.url = `${this.imageObj[0].contentType},${this.imageObj[0].data}`;
            } else {
              this.url = `data:${this.imageObj[0].contentType};base64,${this.imageObj[0].data}`;
            }
            this.editImageId = this.imageObj[0].id;
          }
        });
      }
      this.appointmentService.clientScreenBreakPoints = this.BP.GetBreakPoint([SPAManagementBreakPoint.EditClientProfile, SPAManagementBreakPoint.EditClientPreferences, SPAManagementBreakPoint.EditSOAPNotes, SPAManagementBreakPoint.EditClientBlockInfo]).result;
      const editClientProfile = this.appointmentService.clientScreenBreakPoints.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditClientProfile)[0];
      this.appointmentService.isClientViewOnly = this.appointmentService.clientScreenBreakPoints ? (editClientProfile.view || (editClientProfile.view == false && editClientProfile.allow == false)) ? true : false : false;
      this.appointmentService.isPreferenceViewOnly = this.appointmentService.clientScreenBreakPoints ? this.appointmentService.clientScreenBreakPoints.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditClientPreferences)[0].view : false;
      this.appointmentService.isSoapNotesViewOnly = this.appointmentService.clientScreenBreakPoints ? this.appointmentService.clientScreenBreakPoints.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditSOAPNotes)[0].view : false;
      this.appointmentService.isClientBlockViewOnly = this.appointmentService.clientScreenBreakPoints ? this.appointmentService.clientScreenBreakPoints.filter(x => x.breakPointNumber == SPAManagementBreakPoint.EditClientBlockInfo)[0].view : false;

      this.IsEdit = true;
      this.SetEditValues();
      if (this.appointmentService.isClientViewOnly) {
        this.utils.disableControls(this.FormGrp);
      }
      if (this.appointmentService?.clientEditData?.clientDetail?.isPurged) {
        this.isGuestRecordPurged = true;
        this.FormGrp.disable();
      }
    }
    if (!this.IsEdit) {
      this.FormGrp.controls['firstName'].setValue(this.appointmentService.clientNameInfo.firstName);
      this.FormGrp.controls['lastName'].setValue(this.appointmentService.clientNameInfo.lastName);
      // Phone number mapping
      if (this.appointmentService.clientNameInfo.phone && this.appointmentService.clientNameInfo.phone.length > 0) {
        this.appointmentService.clientNameInfo.phone.forEach((element, i) => {
          let _extension = element.extension ? element.extension : ''
          let _countryCode = element.countryCode ? element.countryCode : ''
          let _platformContactUuid = element.platformContactUuid ? element.platformContactUuid : ''
          if (element.number != '') {
            if (element.contactTypeId === 3) { //Added For Extension when contact type is work
              if (element.number.indexOf(':') !== -1) {
                const arr = element.number.split(':');
                element.number = arr.length > 1 ? arr[1] : element.number;
                _extension = arr[0] ? arr[0] : '';
              } else {
                _extension = '';
              }
            }

            if (element.number.indexOf('|') !== -1) {
              const phonenum = element.number.split('|');
              element.number = phonenum[1] && phonenum[1] !== "undefined" ? phonenum[1] : '';
              _countryCode = phonenum[0] && phonenum[0] !== "undefined" ? phonenum[0] : '';
            }
          }
          this.addPhoneItem(i, element.contactTypeId, _countryCode, this.utils.appendLocalizedFormat(element.number, this.localization.captions.common.PhoneFormat), element.isPrivate, element.isPrimary, _extension, _platformContactUuid);
        });
        this.Phone.removeAt(0);
        this.phoneInfo = this.appointmentService.clientNameInfo.phone;
      }
      if (this.appointmentService.clientNameInfo.email && this.appointmentService.clientNameInfo.email.length > 0) {
        this.appointmentService.clientNameInfo.email.forEach((element, i) => {
          this.addEmailItem(i, element.contactTypeId, element.emailId, element.isPrivate, element.isPrimary, element.platformContactUuid);
        });
        this.Email.removeAt(0);
        this.mailInfo = this.appointmentService.clientNameInfo.email;
      }
    }
  }

  closeWarning() {
    this.showClose = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.appointmentService.convertToEditClient(null);
  }

  async getDmconfig() {
    this.dmConfig = await this.dmConfigDataService.getDataMagineConfigSession();
    if (this.dmConfig && this.dmConfig.enableDataMagine) {
      let isEformsEnabled = this.dmConfig?.dmEformsConfig?.enableEforms ?? false;
      if (isEformsEnabled && this.appointmentService.popupTitle == "EDIT CLIENT") {
        this.showViewEform = true;
      }
    }
  }

  setConditionalRequiredFields(clientConfiguration) {
    // make phone number mandatory if Booking contact
    if (this.appointmentService.isWizardGroupContactView) {
      clientConfiguration[SystemConfigKeys.CLIENT_PHONE] = true;
    }
  }

  Validation(clientConfiguration: any) {
    clientConfiguration = clientConfiguration ? clientConfiguration : [];
    if (clientConfiguration && clientConfiguration.length == 0) return;

    this.setConditionalRequiredFields(clientConfiguration[0]);

    this.FormGrp.controls['firstName'].clearValidators()
    this.FormGrp.controls['firstName'].setValidators(clientConfiguration[0]['CLIENT_FIRST_NAME'] ? [Validators.required, EmptyValueValidator] : []);
    this.FormGrp.controls['firstName'].updateValueAndValidity();

    this.FormGrp.controls['lastName'].clearValidators()
    this.FormGrp.controls['lastName'].setValidators(clientConfiguration[0]['CLIENT_LAST_NAME'] ? [Validators.required, EmptyValueValidator] : []);
    this.FormGrp.controls['lastName'].updateValueAndValidity();

    this.FormGrp.controls['title'].clearValidators();
    this.FormGrp.controls['title'].setValidators(clientConfiguration[0]['CLIENT_TITLE'] ? [Validators.required] : []);
    this.FormGrp.controls['title'].updateValueAndValidity();

    this.FormGrp.controls['gender'].clearValidators();
    this.FormGrp.controls['gender'].setValidators(clientConfiguration[0]['CLIENT_GENDER'] ? [Validators.required] : []);
    this.FormGrp.controls['gender'].updateValueAndValidity();

    this.FormGrp.controls['state'].clearValidators();
    this.FormGrp.controls['state'].setValidators(clientConfiguration[0]['CLIENT_STATE'] ? [Validators.required, EmptyValueValidator] : []);
    this.FormGrp.controls['state'].updateValueAndValidity();

    this.FormGrp.controls['city'].clearValidators();
    this.FormGrp.controls['city'].setValidators(clientConfiguration[0]['CLIENT_CITY'] ? [Validators.required, EmptyValueValidator] : []);
    this.FormGrp.controls['city'].updateValueAndValidity();

    this.FormGrp.controls['country'].clearValidators();
    this.FormGrp.controls['country'].setValidators(clientConfiguration[0]['CLIENT_COUNTRY'] ? [Validators.required, EmptyValueValidator] : []);
    this.FormGrp.controls['country'].updateValueAndValidity();

    this.FormGrp.controls['postal_code'].clearValidators();
    this.FormGrp.controls['postal_code'].setValidators(clientConfiguration[0]['CLIENT_POSTAL_CODE'] ? [Validators.required, EmptyValueValidator] : []);
    this.FormGrp.controls['postal_code'].updateValueAndValidity();

    this.FormGrp.controls['dob'].clearValidators();
    this.FormGrp.controls['dob'].setValidators(clientConfiguration[0]['CLIENT_BIRTHDAY'] ? [Validators.required, EmptyValueValidator] : []);
    this.FormGrp.controls['dob'].updateValueAndValidity();


    this.emailRequired = clientConfiguration[0]['CLIENT_EMAIL'];
    this.phoneRequired = clientConfiguration[0]['CLIENT_PHONE'];
    this.clientservice.emailRequired = clientConfiguration[0]['CLIENT_EMAIL'];
    this.clientservice.phoneRequired = clientConfiguration[0]['CLIENT_PHONE'];
    this.AddressRequired = clientConfiguration[0]['CLIENT_ADDRESS_LINE_1'];


    let EmailArray = this.FormGrp.get('Email') as UntypedFormArray;
    EmailArray.controls.forEach(function (control, index) {
      let EmailGroup: UntypedFormGroup;
      EmailGroup = <UntypedFormGroup>control;

      EmailGroup.controls['EmailLabel'].clearValidators();
      EmailGroup.controls['EmailLabel'].setValidators(clientConfiguration[0]['CLIENT_EMAIL'] ? [Validators.required] : []);
      EmailGroup.controls['EmailLabel'].updateValueAndValidity();

      EmailGroup.controls['EmailId'].clearValidators();
      EmailGroup.controls['EmailId'].setValidators(clientConfiguration[0]['CLIENT_EMAIL'] ? [Validators.required, EmptyValueValidator] : []);
      if (EmailGroup.controls['EmailLabel'].value) {
        EmailGroup.controls['EmailId'].setValidators(Validators.required);
      }
      EmailGroup.controls['EmailId'].updateValueAndValidity();

    });
    let PhoneArray = this.FormGrp.get('Phone') as UntypedFormArray;
    let that = this;
    PhoneArray.controls.forEach(function (control, index) {
      let PhoneGroup: UntypedFormGroup;
      PhoneGroup = <UntypedFormGroup>control;

      PhoneGroup.controls['PhoneNumberLabel'].clearValidators();
      PhoneGroup.controls['PhoneNumberLabel'].setValidators(clientConfiguration[0]['CLIENT_PHONE'] ? [Validators.required] : []);
      PhoneGroup.controls['PhoneNumberLabel'].updateValueAndValidity();

      PhoneGroup.controls['countryCode'].clearValidators();
      PhoneGroup.controls['countryCode'].setValidators(clientConfiguration[0]['CLIENT_PHONE'] ? [Validators.required, EmptyValueValidator] : []);
      that.setmandatory('event', 'PhoneNumber', 'countryCode', 'PhoneNumberLabel', index);
      PhoneGroup.controls['countryCode'].updateValueAndValidity();

      PhoneGroup.controls['PhoneNumber'].clearValidators();
      PhoneGroup.controls['PhoneNumber'].setValidators(clientConfiguration[0]['CLIENT_PHONE'] ? [Validators.required, EmptyValueValidator] : []);
      PhoneGroup.controls['PhoneNumber'].updateValueAndValidity();
    });
    let AddresArray = this.FormGrp.get('Address') as UntypedFormArray;
    AddresArray.controls.forEach(function (control) {
      let AddressGroup: UntypedFormGroup;
      AddressGroup = <UntypedFormGroup>control;
      AddressGroup.controls['addressLine'].clearValidators();
      AddressGroup.controls['addressLine'].setValidators(clientConfiguration[0]['CLIENT_ADDRESS_LINE_1'] ? [Validators.required, EmptyValueValidator] : []);
      AddressGroup.controls['addressLine'].updateValueAndValidity();
    });

    this.FormGrp.updateValueAndValidity();
    this.appointmentService.personalDetailsFormGroup = this.FormGrp;
    this.appointmentService.personalDetailsFormGroup['CreateClientValid'] = this.FormGrp.valid;
    this.appointmentService.personalIsValid = this.FormGrp.valid;
    this.setDataForPhoneEmailTitle();
    let email = this.FormGrp.get('Email') as UntypedFormArray;
    if (email.value[0].EmailLabel) {
      email.markAsTouched();
    }
    if (this.appointmentService?.clientEditData?.clientDetail?.clientType == 2) {
      let allControls = ['firstName', 'lastName', 'Phone', 'Email', 'pronounced', 'dob', 'city', 'state', 'country', 'postal_code', 'Address', 'title', 'gender']
      this.utils.disableFormControls(this.FormGrp, allControls);
      this.phoneInputs.disabled = true;
      this.mailInputs.disabled = true;
      let AddresArray = this.FormGrp.get('Address') as UntypedFormArray;
      AddresArray.controls.forEach(function (control) {
        let AddressGroup: UntypedFormGroup;
        AddressGroup = <UntypedFormGroup>control;
        AddressGroup.controls['disabled'].setValue(true)
        AddressGroup.controls['disabled'].updateValueAndValidity();
      });
    }
    this.phoneInputs = { ...this.phoneInputs }
    this.mailInputs = { ...this.mailInputs }
  }


  PostalCodeChanged(e, IsAutoComplete) {

    let inputPin = this.FormGrp.controls['postal_code'].value;
    this.http
      .getHTTPData(GOOGLE_MAP_API + inputPin)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(result => this.bindAddressFromGoogle(result, IsAutoComplete));
  }

  bindAddressFromGoogle(res: any, IsAutoComplete?, index?: number) {
    this.placeNotfound = false;
    if (IsAutoComplete) {
      this.updateAddressField(res.address_components, true, index)
      if (!res.status) {
        this.googleAutoCompleteAddressLineBinding(index);
      }
      return;
    }
    if (res.status == 'OK') {
      if (IsAutoComplete) {
        this.updateAddressField(res.address_components, true, index)
      } else {
        this.updateAddressField(res.results[0].address_components, false, index);
      }
    }
    if (res.status == 'ZERO_RESULTS') {
      this.placeNotfound = true;
      this.clearAddressFields();
    }
    if (!res.status) {
      this.googleAutoCompleteAddressLineBinding(index);
    }
  }

  googleAutoCompleteAddressLineBinding(index) {
    //google auto complete from search
    let addressFormArr = this.FormGrp.get('Address') as UntypedFormArray;
    //populating auto completed value from text box(work around for angular issue)
    let addressCtl: any = document.getElementById('AddressInput' + index);
    let addressAutoPopulated: string = addressCtl.value;
    addressFormArr.at(index).patchValue({ 'addressLine': addressAutoPopulated });
    let ctrl: any = addressFormArr.at(index)
    ctrl.controls.addressLine.setErrors(null);
  }

  updateAddressField(add: Addresscomponent[], IsAutoComplete: boolean, index?: number) {
    let country: string = '';
    let state: string = '';
    let city: string = '';
    let googlePostalCode: string;
    if (add) {
      let addressFormArr = this.FormGrp.get('Address') as UntypedFormArray;
      addressFormArr.at(0).patchValue({ 'addressLine': ((add[0] && add[0].long_name) ? add[0].long_name.toString() : '') + ' ' + ((add[1] && add[1].long_name) ? add[1].long_name.toString() : '') });
      let ctrl: any = addressFormArr.at(index)
      ctrl.controls.addressLine.setErrors(null);
    }
    for (let i = 0; i < add.length; i++) {
      const element = add[i];
      if (element.types[0] == 'administrative_area_level_1') {
        state = element.long_name;
      }
      if (element.types[0] == 'locality') {
        city = element.long_name;
      }
      if (element.types[0] == 'country') {
        country = element.long_name;
      }
      if (element.types[0] == 'postal_code') {
        googlePostalCode = element.long_name;
      }
    }

    if (googlePostalCode == this.FormGrp.controls['postal_code'].value || IsAutoComplete) {
      this.FormGrp.controls['city'].setValue(city);
      this.FormGrp.controls['country'].setValue(country);
      this.FormGrp.controls['state'].setValue(state);
      this.FormGrp.controls['postal_code'].setValue(googlePostalCode);
    } else {
      this.placeNotfound = true;
      this.clearAddressFields();
    }
  }
  clearAddressFields() {
    this.FormGrp.controls['city'].setValue('');
    this.FormGrp.controls['country'].setValue('');
    this.FormGrp.controls['state'].setValue('');
  }


  placeNotfound: boolean;
  makeGetCall(routeURL: string) {
    this.http.CallApiWithCallback<any[]>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: routeURL,
      uriParams: { module: Module.client },
      method: HttpMethod.Get,
      showError: false,
      extraParams: ['dataBelongTo']
    });
  }

  successCallback<T>(
    result: BaseResponse<T>,
    callDesc: string,
    extraParams?: any[]
  ) {
    if (callDesc == "GetClientConfiguration") {
      this.clientConfiguration = <any>result.result;
      this.Validation(this.clientConfiguration);
      this.OnChangeEvents();
    }
    else if (callDesc == "getImagesByReference") {
      let imageDetails = result.result;
      this.appointmentService.clientImageChange(result.result);
      this.appointmentService.clientHasPic = false;
      if (imageDetails[0]) {
        this.appointmentService.clientHasPic = true;
        this.url = `${imageDetails[0].contentType},${imageDetails[0].data}`;
        this.editImageId = imageDetails[0].id;
      }
    }
  }
  countFunctionAdd(index, e) {
    e.preventDefault();
    if (this.phoneCountArray.length < this.appointmentService.phoneLineLength) {
      this.phoneCountArray.push({
        id: index + 1,
        removeLine: true,
        addLine: true
      });
      this.phoneCountArray[index].removeLine = true;
      this.phoneCountArray[index].addLine = false;
    }
  }

  countFunctionRemove(index, e) {
    e.preventDefault();
    if (index == this.phoneCountArray.length - 1) {
      if (this.phoneCountArray.length == 2) {
        this.phoneCountArray.splice(index, 1);
        this.phoneCountArray[index - 1].addLine = true;
        this.phoneCountArray[index - 1].removeLine = false;
      } else if (this.phoneCountArray.length > 1) {
        this.phoneCountArray.splice(index, 1);
        this.phoneCountArray[index - 1].addLine = true;
        this.phoneCountArray[index - 1].removeLine = true;
      }
    } else if (this.phoneCountArray.length == 2) {
      this.phoneCountArray.splice(index, 1);
      this.phoneCountArray[0].addLine = true;
      this.phoneCountArray[0].removeLine = false;
    } else {
      this.phoneCountArray.splice(index, 1);
    }
  }
  addAddressLine(index, e) {
    e.preventDefault();
    if (
      this.addressLineArray.length < this.appointmentService.addressLineLength
    ) {
      this.addressLineArray.push({
        id: index + 1,
        addLine: true,
        removeLine: false
      });
      this.addressLineArray[index].addLine = false;
      this.addressLineArray[index].removeLine = true;
    }
  }
  removeAddressLine(index, e) {
    e.preventDefault();
    this.addressLineArray.splice(index, 1);
  }
  autoCompleteCallback1(e: Address) {
    let addressArr: AddressComponent[] = [];
    let _line1: string = '';
    let _line2: string = '';
    let _line3: string = '';
    let _state: string = '';
    let _country: string = '';
    let _zip: string = '';

    for (let i = 0; i < e.address_components.length; i++) {
      const element = e.address_components[i];
      if (element.types[0] == 'locality') {
        _line1 = element.long_name;
      }
      if (element.types[0] == 'administrative_area_level_1') {
        _line2 = element.long_name;
      }
      if (element.types[0] == 'administrative_area_level_2') {
        _line3 = element.long_name;
      }
      if (element.types[0] == 'country') {
        _country = element.long_name;
      }
      if (element.types[0] == 'postal_code') {
        _zip = element.long_name;
      }
    }

    this.appointmentService.personalDetailAddress = {
      ContactTypeId: 1,
      ClientId: 0,
      Line1: _line1,
      Line2: _line2,
      Line3: _line3,
      State: _state,
      City: _country,
      Country: _country,
      Zip: _zip,
      IsPrivate: false
    };
    this.FormGrp.controls['pincode'].setValue(_zip);

  }

  validateForm() {
    this.appointmentService.personalDetailsFormGroup['CreateClientValid'] = this.FormGrp.valid;
  }
  OnChangeEvents() {
    this.FormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.validateForm();
    });
  }

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  public handleAddressChange(address: Address) {
    // Do some stuff
  }

  async SetEditValues() {
    let reuseTempVar = null;
    let loyalty = (reuseTempVar = this.appointmentService.clientEditData.clientDetail.loyaltyDetail, reuseTempVar && reuseTempVar.length ? reuseTempVar[0] : null);
    let isCMSDataChanged: boolean = false;
    if (loyalty && loyalty.patronId && this.isCMSConfigured) {
      isCMSDataChanged = await this.appointmentService.UpdateCMSDetailOnExistingGuest(loyalty.patronId, this.appointmentService.clientEditData, this.searchPatronCallBack.bind(this));
      loyalty = (reuseTempVar = this.appointmentService.clientEditData.clientDetail.loyaltyDetail, reuseTempVar && reuseTempVar.length ? reuseTempVar[0] : null);
      if (!loyalty) {
        this.isPatronIdAvailable = false;
        this.FormGrp.controls.patronid.markAsDirty();
      }
      else {
        this.isPatronIdAvailable = true;
        this.FormGrp.reset();
      }
      if (isCMSDataChanged) {
        this.FormGrp.markAsDirty();
      }
    }
    else {
      this.isPatronIdAvailable = false;
    }
    this.personalDetails = this.appointmentService.clientEditData.clientDetail;
    this.FormGrp.controls.lastName.setValue(this.appointmentService.clientEditData.clientDetail.lastName);
    if (!this.appointmentService.copySelectedClient) {
      this.FormGrp.controls.title.setValue(this.appointmentService.clientEditData.clientDetail.title);
      this.FormGrp.controls.firstName.setValue(this.appointmentService.clientEditData.clientDetail.firstName);
      this.FormGrp.controls.patronid.setValue(loyalty ? loyalty.patronId : '');
      this.FormGrp.controls.pronounced.setValue(this.appointmentService.clientEditData.clientDetail.pronounce);
      this.FormGrp.controls.gender.setValue(this.appointmentService.clientEditData.clientDetail.gender);
      this.FormGrp.controls.dob.setValue(
        this.appointmentService.clientEditData.clientDetail.dateOfBirth ?
          this.utils.getDate(this.appointmentService.clientEditData.clientDetail.dateOfBirth)
          : ""
      );
      this.FormGrp.controls.rank.setValue(loyalty ? loyalty.rank : '');
      this.FormGrp.controls.platformBussinessCardRevUuid.setValue(this.appointmentService.clientEditData.clientDetail.platformBussinessCardRevUuid);
      this.FormGrp.controls.platformBussinessCardUuid.setValue(this.appointmentService.clientEditData.clientDetail.platformBussinessCardUuid);
      this.FormGrp.controls.platformGuestUuid.setValue(this.appointmentService.clientEditData.clientDetail.platformGuestUuid);
      this.FormGrp.controls.platformRevUuid.setValue(this.appointmentService.clientEditData.clientDetail.platformRevUuid);
    }
    if (this.appointmentService.clientEditData.addresses && this.appointmentService.clientEditData.addresses.length > 0) {
      this.FormGrp.controls.postal_code.setValue(this.appointmentService.clientEditData.addresses[0].zip);
      this.FormGrp.controls.state.setValue(this.appointmentService.clientEditData.addresses[0].state);
      this.FormGrp.controls.city.setValue(this.appointmentService.clientEditData.addresses[0].city);
      this.FormGrp.controls.country.setValue(this.appointmentService.clientEditData.addresses[0].country);
      this.FormGrp.controls.platformAddressUuid.setValue(this.appointmentService.copySelectedClient ? '' : this.appointmentService.clientEditData.addresses[0].platformAddressUuid);
      this.FormGrp.controls.platformAddressList.setValue(this.appointmentService.copySelectedClient ? [] : this.appointmentService.clientEditData.addresses);
    }
    let phoneData = this.FormGrp.get('Phone') as FormArray;      
    if (this.appointmentService.clientEditData.phoneNumbers && this.appointmentService.clientEditData.phoneNumbers.length > 0) {
      phoneData.clear();
      this.appointmentService.clientEditData.phoneNumbers.forEach((element, i) => {
        let _extension = element.extension ? element.extension : ''
        let _countryCode = element.countryCode ? element.countryCode : ''
        let _platformContactUuid = (element.platformContactUuid && !this.appointmentService.copySelectedClient) ? element.platformContactUuid : ''
        if (element.number != '') {
          if (element.contactTypeId === 3) { //Added For Extension when contact type is work
            if (element.number.indexOf(':') !== -1) {
              const arr = element.number.split(':');
              element.number = arr.length > 1 ? arr[1] : element.number;
              _extension = arr[0] ? arr[0] : '';
            } else {
              _extension = '';
            }
          }

          if (element.number.indexOf('|') !== -1) {
            const phonenum = element.number.split('|');
            element.number = phonenum[1] && phonenum[1] !== "undefined" ? phonenum[1] : '';
            _countryCode = phonenum[0] && phonenum[0] !== "undefined" ? phonenum[0] : '';
          }
        }
        this.addPhoneItem(i, element.contactTypeId, _countryCode, this.utils.appendLocalizedFormat(element.number, this.localization.captions.common.PhoneFormat), element.isPrivate, element.isPrimary, _extension, _platformContactUuid);
      });
      // this.Phone.removeAt(0);
    }
    this.phoneInfo = this.appointmentService.clientEditData.phoneNumbers;
    this.mailInfo = this.appointmentService.clientEditData.emails;
    let mailData = this.FormGrp.get('Email') as FormArray;      
    if (this.appointmentService.clientEditData.emails && this.appointmentService.clientEditData.emails.length > 0) {
      mailData.clear();
      this.appointmentService.clientEditData.emails.forEach((element, i) => {
        let _platformContactUuid = this.appointmentService.copySelectedClient ? '' : element.platformContactUuid
        this.addEmailItem(i, element.contactTypeId, element.emailId, element.isPrivate, element.isPrimary, _platformContactUuid);
      });
      // this.Email.removeAt(0);
    }

    if (this.appointmentService.clientEditData.addresses && this.appointmentService.clientEditData.addresses.length > 0) {
      let addressItem = this.appointmentService.clientEditData.addresses[0];
      if (addressItem.line1) {
        this.addAddressItem(0, addressItem.line1, addressItem.isPrivate)
        this.Address = this.FormGrp.get('Address') as UntypedFormArray;
        this.Address.removeAt(0);
      }
      if (addressItem.line2) {
        this.addAddressItem(1, addressItem.line2, addressItem.isPrivate)
      }
      if (addressItem.line3) {
        this.addAddressItem(2, addressItem.line3, addressItem.isPrivate)
      }

    }
  }

  onFileDelete(event) {
    this.appointmentService.isClientImgRemoved = this.IsEdit;
    this.ImageUploaded = false;
    this.makeFormDirty();
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    const file = event.target.files[0];
    // convert to MB
    const fileSize = file.size / (1024 * 1024);
    // Allow upload if size is lesser than or equal to 2 MB
    if (fileSize <= 2) {
      this.ImageUploaded = true;
      this.appointmentService.isClientImgRemoved = false;
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
      this.makeFormDirty();
    } else {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.common.FileSizeExceeded);
    }

  }

  base64textString: any;
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    let imageObj: ImageData;
    if (this.imageObj && this.imageObj.length > 0) {
      imageObj = _.clone(this.imageObj[0]);
      imageObj.data = this.base64textString;
      imageObj.thumbnailData = this.base64textString;
      imageObj.contentType = this.selectedFile.type;
    }
    else {
      imageObj = {
        referenceId: this.appointmentService.clientId,
        referenceType: ImgRefType.client,
        data: this.base64textString,
        id: 0,
        thumbnailData: this.base64textString,
        contentType: this.selectedFile.type,
        sequenceNo: 0
      }
    }
    this.appointmentService.ImgTempHolder = imageObj;
    this.bindImage(this.selectedFile.type, this.base64textString)
  }

  private bindImage(fileContentType, fileContent) {
    if (fileContentType && fileContent) {
      this.appointmentService.clientImageUrl = `data:${fileContentType};base64,${fileContent}`;
      // this.url = this.domSanitizer.bypassSecurityTrustUrl(this.appointmentService.clientImageUrl);
      this.url = this.appointmentService.clientImageUrl;
      this.ImageUploaded = true;
    }
  }

  fileDeleted() {
    this.appointmentService.isClientImgRemoved = this.IsEdit;
    this.ImageUploaded = false;
    this.FormGrp.markAsDirty();
  }
  fileUploaded(data) {
    this.base64textString = data['orgImg'];
    this.thumbnailImg = data['tmbImg'];
    const base64result = this.base64textString.split(',');
    const base64Thumbnail = this.thumbnailImg.split(',');
    let imageObj: ImageData;
    if (this.imageObj && this.imageObj.length > 0) {
      imageObj = _.clone(this.imageObj[0]);
      imageObj.data = base64result[1];
      imageObj.thumbnailData = base64Thumbnail[1];
      imageObj.contentType = base64result[0];
    } else {
      imageObj = {
        referenceId: this.appointmentService.clientId,
        referenceType: ImgRefType.client,
        data: base64result[1],
        id: 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: 0
      };
    }
    this.appointmentService.ImgTempHolder = imageObj;
    this.FormGrp.markAsDirty();
  }
  fileSizeExceeded() {
    this.utils.ShowErrorMessage(this.captionsCommon.FileSizeExceeded, this.captionsCommon.Error, this.captionsCommon.Error);
  }

  emailChange(emailid, emailLabel, index) {

    if (this.FormGrp.controls['Email']['controls'][index].controls[emailid].value && !this.FormGrp.controls['Email']['controls'][index].controls[emailLabel].value) {

      this.FormGrp.controls['Email']['controls'][index].controls[emailLabel].setValidators(Validators.required);
      this.FormGrp.controls['Email']['controls'][index].controls[emailLabel].markAsTouched();
    } else {
      if (!this.emailRequired) {
        this.FormGrp.controls['Email']['controls'][index].controls[emailLabel].clearValidators();
      }
    }
    this.FormGrp.controls['Email']['controls'][index].controls[emailLabel].updateValueAndValidity();
  }

  setmandatory(eve, phoneNumber, altfield, phoneType, index) {
    if (eve && eve.target && eve.target.value) {
      this.FormGrp.controls['Phone']['controls'][index].controls[altfield].setValidators([Validators.required]);
    }
    if (phoneNumber == 'PhoneNumber') {
      if (this.FormGrp.controls['Phone']['controls'][index].controls[phoneType].value && this.FormGrp.controls['Phone']['controls'][index].controls[phoneType].value === 1) {
        this.FormGrp.controls['Phone']['controls'][index].controls[altfield].setValidators(Validators.required);
      } else {
        this.FormGrp.controls['Phone']['controls'][index].controls[altfield].clearValidators();
      }
    }

    this.FormGrp.controls['Phone']['controls'][index].controls[altfield].markAsTouched();
    this.FormGrp.controls['Phone']['controls'][index].controls[altfield].updateValueAndValidity();
  }

  phoneChange(eve, phoneNumber, altfield, phoneNumberLabel, index) {
    this.setmandatory(eve, phoneNumber, altfield, phoneNumberLabel, index);
    if (this.FormGrp.controls['Phone']['controls'][index].controls[phoneNumber].value && !this.FormGrp.controls['Phone']['controls'][index].controls[phoneNumberLabel].value) {

      this.FormGrp.controls['Phone']['controls'][index].controls[phoneNumberLabel].setValidators(Validators.required);
      this.FormGrp.controls['Phone']['controls'][index].controls[phoneNumberLabel].markAsTouched();
    } else {
      if (!this.phoneRequired) {
        this.FormGrp.controls['Phone']['controls'][index].controls[phoneNumberLabel].clearValidators();
      }
    }
    this.FormGrp.controls['Phone']['controls'][index].controls[phoneNumberLabel].updateValueAndValidity();
  }

  playerWorthDetails(event) {
    this.clientCommonService.openDialogPopup(this.FormGrp.controls.patronid.value);
  }


  searchPatron() {
    let patronId = this.FormGrp.controls.patronid.value;
    if (patronId && patronId != '' && this.isCMSConfigured) {
      this.appointmentService.searchClientByPatron(patronId, this.searchPatronCallBack.bind(this));
    }
  }

  searchPatronCallBack(result: number, extraParams?: any) {
    if (result == PatronInfoSearchResultType.EDITEXISTINGPATRON) {
      this.isPatronIdAvailable = true;
      this.initializeFormData();
    }
    else if (result == PatronInfoSearchResultType.PATRONNOTFOUND) {
      this.isPatronIdAvailable = false;
      this.FormGrp.controls.patronid.setValue('');
      this.FormGrp.controls.patronid.markAsDirty();
    }
    else if (result == PatronInfoSearchResultType.PATRONFOUND) {
      this.isPatronIdAvailable = true;
    }
    else if (result == PatronInfoSearchResultType.UPDATECMSDATAONEXISTING) {
      this.FormGrp.controls.firstName.setValue(extraParams[0].firstName);
      this.FormGrp.controls.lastName.setValue(extraParams[0].lastName);
      this.FormGrp.controls.pronounced.setValue(extraParams[0].pronounced);
      this.FormGrp.controls.rank.setValue(extraParams[0].playerRank);
      this.FormGrp.controls.dob.setValue(this.utils.getDate(extraParams[0].dateOfBirth));
      const tempFemale = extraParams[0].gender == 'F' || extraParams[0].gender.toLowerCase() == 'female' ? 'Female' : '';
      this.FormGrp.controls.gender.setValue(extraParams[0].gender == 'M' || extraParams[0].gender.toLowerCase() == 'male' ? 'Male' : tempFemale)
      if (extraParams[0].address) {
        this.addAddressItem(0, extraParams[0].address.addressLine1, false)
        this.FormGrp.controls.postal_code.setValue(extraParams[0].address.postalCode);
        this.FormGrp.controls.state.setValue(extraParams[0].address.state);
        this.FormGrp.controls.city.setValue(extraParams[0].address.city);
        this.FormGrp.controls.country.setValue(extraParams[0].address.country);
        this.Address.removeAt(0);
      }
      if (extraParams[0].phone && extraParams[0].phone.length > 0) {
        extraParams[0].phone.forEach((element, i) => {
          this.addPhoneItem(i, element.phoneTypeId, element.countryCode, this.utils.appendLocalizedFormat(element.phoneNumber, this.localization.captions.common.PhoneFormat), false, element.isPrimary, element.extension, element.platformContactUuid);
        });
        this.Phone.removeAt(0);
      }
      if (extraParams[0].email && extraParams[0].email.length > 0) {
        extraParams[0].email.forEach((element, i) => {
          this.addEmailItem(i, element.emailTypeId, element.emailAddress, false, false);
        });
        this.Email.removeAt(0)
      }
      this.phoneInfo = this.mapPhone(extraParams[0].phone)
      this.mailInfo = this.mapEmail(extraParams[0].email)
      this.isPatronIdAvailable = true;
    }
    this.clearPatronValidationError()
  }

  mapEmail(cmsEmail): any {
    let emailArray: any[] = [];
    var mailTypes = this.getEmailType();
    cmsEmail.forEach(e => {
      const mail = mailTypes.filter(x => x.id == e.emailTypeId)[0];
      emailArray.push({
        contactType: mail && mail.description,
        emailType: e.emailTypeId,
        emailLabel: String(e.emailTypeId),
        emailId: e.emailAddress,
        privateInformtionEmail: false,
        primaryEmail: false,
        // TO_DO : How we will get platformcontact Id When doing CMS Update.
        platformContactUuid: e.platformContactUuid ? e.platformContactUuid : ''
      })
    });

    return emailArray;
  }

  mapPhone(cmsPhone): any {
    let phoneArray: any[] = [];

    var phoneTypes = this.getPhoneType();
    cmsPhone.forEach(e => {
      let number = (e.countryCode ? e.countryCode : '000') + '|' + e.extension + e.phoneNumber
      phoneArray.push({
        contactType: phoneTypes.filter(x => x.id == e.phoneTypeId)[0]?.description,
        phoneType: e.phoneTypeId,
        phoneLabel: String(e.phoneTypeId),
        countryCode: this.utils.getCountryCodeFromValue(e.phoneTypeId, number).toString(),
        phoneNumber: this.utils.getPhoneNumberFromValue(e.phoneTypeId, number).toString(),
        extension: e.extension,
        privateInformtionPhone: false,
        primaryPhone: e.isPrimary,
        // TO_DO : How we will get platformcontact Id When doing CMS Update.
        platformContactUuid: e.platformContactUuid ? e.platformContactUuid : ''
      })
    });
    return phoneArray;

  }

  checkPatronValidation() {
    let patronValue = this.FormGrp.controls.patronid.value;
    if (patronValue) {
      this.FormGrp.controls.patronid.setValidators(Validators.required);
      if (!this.isPatronIdAvailable) {
        this.FormGrp.controls.patronid.setErrors({ invalid: true });
        this.validateForm();
      }
    } else {
      this.FormGrp.controls.patronid.clearValidators();
      this.FormGrp.controls.patronid.updateValueAndValidity();
    }
  }

  clearPatronValidationError() {
    this.FormGrp.controls.patronid.clearValidators();
    this.FormGrp.controls.patronid.updateValueAndValidity();
  }
  getPhoneType() {
    return [
      { id: this.phoneTypes.mobile, description: this.localization.captions.contactTypesOptions.Phone[0].description, type: ContactType.phone },
      { id: this.phoneTypes.home, description: this.localization.captions.contactTypesOptions.Phone[1].description, type: ContactType.phone },
      { id: this.phoneTypes.office, description: this.localization.captions.contactTypesOptions.Phone[2].description, type: ContactType.phone }
    ];
  }
  getEmailType() {
    return [
      { id: 9, description: this.localization.captions.contactTypesOptions.Email[0].description, type: ContactType.email },
      { id: 10, description: this.localization.captions.contactTypesOptions.Email[1].description, type: ContactType.email }
    ];
  }
  openEForms() {
    let res = this.dialog.open(SelectEFormsComponent, {
      width: '85%',
      height: '85%',
      hasBackdrop: true,
      data: {
        guestId: this.appointmentService.guestId,
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(s => {

    });
  }

}


