import { CustomFeeSourceType } from "../shared/globalsContant";
import { ReturnWithTicketType, ServiceChargeGratuityTaxDetail, Ticket, TransactionCustomFee } from "../shared/service/common-variables.service";

export interface TransactionContract{
    lineItems: LineItem[];
    checkData: CheckData;
    calculationOrder: { [key: number]: string; };
    Discounts : EngineDiscountModel[];
}

export interface CheckData {
    checkNumber: string;
    checkHandleGuid: string;
    totalTax: number;
    totalAmount: number;
    gratuity: number;
    serviceCharge: number;
    discount: number;
    withheldValue: number;
    taxDetails: TaxDetails[];
    discountModels: DiscountModel[];
    gratuities: Gratuity[];
    serviceCharges: ServiceCharge[];
    calculationOrder: { [key: number]: string; };
}

export interface LineItem {
    itemId: number;
    quantity: number;
    unitPrice: number;
    externalPOSItemId: number;
    tax: number;
    amount: number;
    discount: number;
    isMultiPackRedeem: boolean;
    index: number;
    isOpenPricedItem: boolean;
    isTaxExempt: boolean;
    taxDetails: TaxDetails[];
    discountModel: DiscountModel;
    netUnitPrice: number;
    vAT: number;
    netPrice: number;
    netUnitPriceWithoutDiscount: number;
    serviceCharge: number;
    gratuity: number;
    discountModels: DiscountModel[];
    gratuities: Gratuity[];
    serviceCharges: ServiceCharge[];
}

export interface TaxDetails {
    id: number;
    value: number;
    isPercentage: boolean;
    parentId: number;
    linkedTaxDetails: TaxDetails[]
}

export interface DiscountModel {
    index: number;
    isPercentage: boolean;
    value: number;
}

export interface Gratuity extends DetailInfo {
    id: number;
    value: number;
    isPercentage: boolean;
    gratuityValue: number;
    taxDetails: TaxDetails[];
}

export interface ServiceCharge extends DetailInfo {
    id: number;
    value: number;
    isPercentage: boolean;
    serviceChargeValue: number;
    taxDetails: TaxDetails[];
}

export interface DetailInfo{
    therapistId: number;
    transactionDetailId: number;
    staffType: string;
}

export interface TransactionData {
    id: number;
    transactionType: string;
    ticketNumber: string;
    transactionDate: string;
    clerkId: number;
    totalPrice: number;
    totalTax: number;
    totalAmount: number;
    guestId: number;
    guestGuid: string | null;
    memberId: number;
    comment: string;
    stayId: number;
    isVoided: boolean;
    isCorrected?: boolean;
    shopItems: ShopItem[];
    outletId: number;
    propertyId?: number;
    subPropertyId?: number;
    gratuity?: number;
    serviceCharge?: number;
    discount?: number;
    transactionLinkId?: number;
    voidReason?: string;
    productId: number;
    terminalId?: number;
    receiptComment?: string;
    correlationId?: string | null;
    retailTransactionType?: string;
    patronId: string;
    machineNameId: number;
    voidedByClerkId?: number;
    taxExemptIds?: number[];
    transactionAdditionalComment?: string;
    transactionAdditionalReference?: string;
    discounts?:EngineDiscountModel[];
}

export interface ShopItem {
    id: number;
    itemId: number;
    externalPOSItemId: number;
    itemDescription: string;
    itemType: number;
    unitPrice: number;
    memberUnitPrice: number;
    lineNumber: number;
    staffId?: number;
    staffType?: string;
    staffTransactionDetail: StaffTransactionDetail[];
    quantitySold: number;
    discount: number;
    discountTypeId: number;
    discountPercentage: number;
    commission?: Commission[];
    serviceChargeGratuity?: ServiceChargeGratuity[];
    taxExemptIds?: number[];
    tax: number;
    totalAmount: number;
    outletId: number;
    isGroupingKey: boolean;
    isPackagedItem: boolean;
    packageItemId: number;
    isMultiPack: boolean;
    isMultiPackRedeem: boolean;
    clientMultiPackId: number;
    packageGroupId: number;
    isOpenPricedItem: boolean;
    transactionDetailLinkId?: number;
    isReturn?: boolean;
    scaledUnit?: number | null;
    unitOfMeasureId?: number | null;
    isTaxExempt: boolean;
    discountModel: DiscountModel;
    isModificationRestricted: boolean;
    netUnitPrice?: number;
    netUnitPriceWithoutDiscount?: number;
    giftCardTransactionItem?: GiftCardTransactionItem;
    itemComments: string;
    costPrice: number;
    marginPercentage: number;
    allowEarn: boolean;
    discountReason: number;
    discountComments: string;
    returnLinkId?: number;
    returnType?: ReturnWithTicketType;
    netPrice?: number;
    originalQuantity?: number;
    groupingParentId?: number;
    groupingItemDescription?: string;
    groupingUniqueIdentifier?: string | null;
    multiPackTransactionDetailId?: number;
    isCustomFeeAdded?: boolean;
    customFee?: TransactionCustomFee;
    linkedCustomFeeItemLineNumber?: number;
    sourceTypeId? : number;
    sourceType?: CustomFeeSourceType;
    weightedDiscount?:number;
}

export interface Commission {
    id: number;
    propertyId?: number;
    subPropertyId?: number;
    transactionId?: number;
    itemId: number;
    amount?: number;
    commissionPercent?: number;
    commissionFlatAmount?: number;
    commissionAmount?: number;
    quantity: number;
    date?: string;
    templateId: number;
    staffId: number;
    staffType: string;
    splitPercentage: number;
}

export interface ServiceChargeGratuity {
    id: number;
    propertyId?: number;
    subPropertyId?: number;
    transactionDetailId: number;
    therapistId: number;
    itemId: number;
    gratuity: number;
    gratuityPercent: number;
    serviceCharge: number;
    serviceChargePercent: number;
    date: string;
    totalServiceCharge: number;
    totalGratuity: number;
    staffType: string;
    serviceChargeGratuityTaxDetails? : ServiceChargeGratuityTaxDetail[];
    additionalGratuity?: number;
    additionalGratuityPercent?: number;
    additionalServiceCharge?: number;
    additionalServiceChargePercent?: number;
}

export interface StaffTransactionDetail {
    staffId: number;
    staffType: string;
}

export interface EngineDiscountModel {
    index?: number;
    isPercentage?: boolean;
    value: number;
    discountAmount?: number;
    discountLevel: DiscountLevel;
    isExclusive?: boolean;
    tenderReducesDiscount?: boolean;
    allowAdditionalFlatDiscount?: boolean;
    discountTypeId: number;
    discountReason: number;
    discountComments:string;
    minimumAmountInCart?:number;
    appliedDiscount?:number;
}

export enum DiscountLevel {
    Ticket = 1,
    Item
}

export interface DiscountModel {
    index: number;
    isPercentage: boolean;
    value: number;
}

export interface GiftCardTransactionItem {
    id: number;
    transactionDetailId: number;
    paymentReferenceId: number;
    amount: number;
    firstName: string;
    lastName: string;
    cardType: GiftCardType;
    cardNumber: string;
    expiryDate: string | null;
    handleInfo: string;
    isVoided: boolean;
    status: number;
    unLoadPaymentReferenceId: number;
    isIssue: boolean;
}

export enum GiftCardType {
    V1Gifcard = 2,
    ExternalGiftcard = 4
}

export interface TenderInfoTransactionContract {
    contract: Ticket;
    tenderReducesDiscount: boolean;
    tenderReducesTax: boolean;
    paymentAmount?: number;
    netTenderReducesDiscountSettledAmount?:number;
    netTenderReducesTaxSettledAmount?:number;
    isReversed?:boolean;
    returnedItemTotal ?: number;
    returnedItemTotalWithOutTax ?: number;
    IsTaxExempt?:boolean;
    isReturn?:boolean
}