import { Injectable } from "@angular/core";
import { BaseResponse } from "src/app/common/Models/http.model";
import { DefaultGUID, Host } from "../../globalsContant";
import { HttpMethod, HttpServiceCall, ServiceParamsAsync } from "../../service/http-call.service";

@Injectable()
export class CommonItineraryDataService{
    private  serviceParams : ServiceParamsAsync;
    successCallback: any;
    constructor(private _http: HttpServiceCall){
    }

    async GetGuestItineraryData(reqBody : GetGuestItineraryByDate){
        if(reqBody.guestId && reqBody.guestId != DefaultGUID){
            this.serviceParams = {
                callDesc: "GetGuestItinerary",
                host: Host.commonGateway,
                method: HttpMethod.Put,            
                uriParams: {},
                body: reqBody
            };
            var result = await this._http.CallApiAsync(this.serviceParams);
            if(result){
                return result;
            }
        } 
        return null;
    }

    async GetActivitiesByGuestIds(requestBody : GetActivitiesByGuestIds){
        if(requestBody && requestBody.guestIds && requestBody.guestIds.length > 0){
            this.serviceParams = {
                callDesc: "Activity/GetActivitiesByGuestIds?tenantId="+
                requestBody.tenantId+"&startDate="+requestBody.startDate,
                host: Host.commonGateway,
                method: HttpMethod.Post,
                body: requestBody.guestIds
            }
            var result = await this._http.CallApiAsync(this.serviceParams);
            if(result){
                return result;
            }
            return null;
        }
    }

    async GetAppointmentByConfirmationNumber(confNo : string){
        if(confNo && confNo != ""){
            this.serviceParams = {
                callDesc: "GetAppointmentsByConfirmationNumber",
                host: Host.schedule,
                method: HttpMethod.Get,            
                uriParams: {confirmationNumber : confNo},
               // body: reqBody
            };
            var result = await this._http.CallApiAsync(this.serviceParams);
            if(result){
                return result.result;
            }
        } 
        return null;
    }

    async GetGuestItineraryDataForTeeTime(reqBody : any){
        if(reqBody.guestId ){
            this.serviceParams = {
                callDesc: "GetActivitiesForTeeTimeItinerary",
                host: Host.commonGateway,
                method: HttpMethod.Put,            
                uriParams: {},
                body: reqBody
            };
            var result = await this._http.CallApiAsync(this.serviceParams);
            if(result){
                return result;
            }
        } 
        return null;
    }

    async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
        try {
            return await this._http.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
            });
        } catch (e) {
            this._http.exceptionHandle(e);
        }
  }
}

export interface GetGuestItineraryByDate{
    guestId: string;
    tenantId: string;
    startDate: string;
    endDate: string;
    propertyTimeZone: string; 
    includeDeparture:boolean;  
    includeCancelledActivities: boolean; 
  }

  export interface GetActivitiesByGuestIds{
      guestIds: string[];
      tenantId: string;
      startDate: string;
  }
