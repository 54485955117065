import { Formaters } from 'src/app/eatecui/source/shared/formaters';
import { GridInterface } from '.';
import { GridHeaderConfig, GridSortAction, MasterInventoryManagement } from '../master.interface';
import { ColumnFieldType } from './columnType.enum';
import { GridModelConfiguration } from './grid-config.interface';
import { ReplaceTextWithIcon } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import * as constant from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { GridActionRender } from 'src/app/eatecui/source/shared/models/grid-data.interface';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
export const GridHeaderColumn: GridModelConfiguration = async (
    ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement, translateService ?: TranslateService,
    httpClient?: HttpClient):
    Promise<GridHeaderConfig[]> => {
    try {
        const GridDataHeaderColumn: GridHeaderConfig[] = [];
        const deviceName = getDeviceName();
        if (ViewModelEntity && ViewModelEntity[0].Property && ViewModelEntity[0].Property.length > 0) {
            ViewModelEntity[0].Property.forEach((columnObject: object) => {
                const HeaderColumnObject = {} as GridHeaderConfig;
                //  console.log('Header-column', columnObject['$']['Name']);
                if (MasterDataCollection.GridConfig.GridColumnHide[0][deviceName].
                    filter(x => x.ColumnName.toLowerCase() === columnObject['$']['Name'].toString().toLowerCase()).length === 0) {
                    HeaderColumnObject.headerName = GridInterface.GridHeaderColumnName(
                    ViewModelEntity, MasterDataCollection, columnObject, translateService);
                    HeaderColumnObject.field = columnObject['$']['Name'].toString().toLowerCase();
                    HeaderColumnObject.hide = false;
                    HeaderColumnObject.sortOrder = ['Asc'];
                    HeaderColumnObject.editable = false;
                    HeaderColumnObject.sortable = false;
                    HeaderColumnObject.customSort = true;
                    HeaderColumnObject.sortDirection =
                        GridInterface.GridSetDefaultSort(ViewModelEntity, MasterDataCollection, columnObject);
                    HeaderColumnObject.type = GridInterface.GridHeaderColumnType;
                    HeaderColumnObject.valueFormatter = HeaderColumnObject.type === ColumnFieldType.DATECOLUMN ? Formaters.dateformater :
                        Formaters.columnValueFormater;
                    HeaderColumnObject.suppressMovable = true;
                    HeaderColumnObject.cellRenderer = GridInterface.GridHeaderRenderer(ViewModelEntity, MasterDataCollection, columnObject);
                    HeaderColumnObject.resizable = GridInterface.GridColumnResize(ViewModelEntity, MasterDataCollection, columnObject);
                    const ReplacableObject = ReplaceTextWithIcon.filter(x => x.OdataKey.toLowerCase() ===
                        columnObject['$']['Name'].toLowerCase())[0];
                    if (ReplacableObject) {
                        HeaderColumnObject.width = ReplacableObject.Width;
                    }
                    GridDataHeaderColumn.push(HeaderColumnObject);
                }
            });

            ViewModelEntity[0].NavigationProperty.forEach((columnObject: object) => {
                const HeaderColumnObject = {} as GridHeaderConfig;
                //  console.log('Header-column', columnObject['$']['Name']);
                if (columnObject['$']['Name'].toString().toLowerCase() === 'ingredientsbarcodes') {
                    HeaderColumnObject.headerName = GridInterface.GridHeaderColumnName(ViewModelEntity, 
                        MasterDataCollection, columnObject, translateService);
                    HeaderColumnObject.field = columnObject['$']['Name'].toString().toLowerCase();
                    HeaderColumnObject.hide = false;
                    HeaderColumnObject.sortOrder = ['Asc'];
                    HeaderColumnObject.editable = false;
                    HeaderColumnObject.sortable = false;
                    HeaderColumnObject.customSort = true;
                    GridDataHeaderColumn.push(HeaderColumnObject);
                }
            });

        }
        if (MasterDataCollection.GridConfig.GridRowActions.ShowActionColumn) {
            const cellRenderer = getActionRendererColumn(MasterDataCollection);
            GridDataHeaderColumn.push(cellRenderer);
        }
        const RearrangedHeaderDefination = await
            rearrangeHeaderColumn(GridDataHeaderColumn, httpClient, ViewModelEntity[0]['$']['Name']);
        return RearrangedHeaderDefination;

    } catch (error) {
        console.log('error occured SetSortOrder', error);
    }
};

/**
 * @description To get the name of the device
 */
function getDeviceName() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let deviceName = '';
    if (width > constant.desktopminwidth && constant.desktopminheight > 768) {
        deviceName = constant.DeviceName.Desktop;
    } else if ((width === constant.tablandscapewidth && height === constant.tablandscapeheight) ||
        (width >= constant.tabpotraitminwidth && width <= constant.tabpotraitmaxwidth &&
            height >= constant.tabpotraitminheight && height <= constant.tabpotraitmaxheight)) {
        deviceName = constant.DeviceName.Tablet;
    } else if (width >= constant.mobileminwidth && width <= constant.mobilemaxwidth &&
        height >= constant.mobileminheight && height <= constant.mobilemaxheight) {
        deviceName = constant.DeviceName.Mobile;
    }
    return deviceName === '' ? constant.DeviceName.Desktop : deviceName;
}

function getActionRendererColumn(MasterDataCollection: MasterInventoryManagement) {
    const actionRenderer: GridActionRender = {
        headerName: MasterDataCollection.GridConfig.GridRowActions.HeaderName,
        cellRenderer: MasterDataCollection.GridConfig.GridRowActions.CellRenderer,
        hide: false,
        editable: false,
        field: '',
        showIcon: true,
        showButton: false,
        pinned: 'right',
        width: '120',
        Edit: MasterDataCollection.GridConfig.GridRowActions.Edit,
        Delete: MasterDataCollection.GridConfig.GridRowActions.Delete,
        Deactivate: MasterDataCollection.GridConfig.GridRowActions.Deactivate,
        Activate: MasterDataCollection.GridConfig.GridRowActions.Activate,
        Copy: MasterDataCollection.GridConfig.GridRowActions.Copy,
    };
    return actionRenderer;
}

async function rearrangeHeaderColumn(HeaderDefination, httpClient: HttpClient, odataKey) {
    const IsActiveIndex = HeaderDefination.findIndex(x => x.field.toLowerCase() === 'isactive');
    const IsActiveObject = HeaderDefination.filter(x => x.field.toLowerCase() === 'isactive')[0];
    if (IsActiveIndex > -1) {
        HeaderDefination.splice(IsActiveIndex, 1);
        HeaderDefination.splice(0, 0, IsActiveObject);
    }

    const RetailProductJson = await httpClient.get<any>('app/eatecui/assets/json/ui-control/pre-order-gridcolumn.json').toPromise();
    let reorderHeaderDefination = HeaderDefination;    
    const columnOrder = RetailProductJson;
    columnOrder.forEach((columnorder: any, orderIndex: number) => {
        const headerINdex = HeaderDefination.findIndex(x => x.field === columnorder.Key.toLowerCase()
         && columnorder.Odatakey === odataKey);
        if ( headerINdex > -1 ) {
            HeaderDefination[headerINdex]['tableCardSortOrder'] = columnorder.Order;
            reorderHeaderDefination = array_move(HeaderDefination, headerINdex, columnorder.Order);
        }
    });
    return HeaderDefination;
}
function array_move(arr: any, old_index: number, new_index: number) {
    try {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
    } catch (error) {
      console.log(error);
    }
    
  }