import { FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomTemplateFormLocation: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: any, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData ?: TransactionConfiguration
    ): any => {
        try {
            if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
                const getCurrentLocationId = FormGroupData.controls[FieldGrouping.OdataKey].value;
                const isFromLocId = FieldGrouping.Name === 'FromLocId' ? true : false;
                const CustomUrl = `/inventory/api/GetLocations/24/${isFromLocId}`;
                httpService.GethttpMethod(CustomUrl).subscribe(async locationdata => {
                   if (locationdata && locationdata['ResultData'] && locationdata['ResultData'].length > 0) {
                    const getSupplierId = locationdata['ResultData'].filter(z => z.Id === FormGroupData.value.FromLocId)[0]['SupplyLocId'];
                      FormField.forEach(element => {
                          if (element.fieldType.SingleData 
                            && element.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo
                            && element.fieldType.SingleData.options 
                            && element.fieldType.SingleData.options.length > 0) {
                            if (element.fieldType.SingleData.options.findIndex(x => x.key === getSupplierId) >= 0) {
                                if (getSupplierId && getSupplierId !== null && !FormGroupData.value['IsTransferOut']) {
                                    FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(getSupplierId);
                                }
                            }
                          }
                      });
                        if ( FieldGrouping.CustomFormAction.hasOwnProperty('DisabledField') && 
                           FieldGrouping.CustomFormAction.DisabledField) {
                          const locationTypeId = locationdata['ResultData'].filter(z => z.Id === FormGroupData.value.FromLocId)[0].LocationTypeId;
                          if (locationTypeId === 8 && locationTypeId !== null ) {
                              let authPayload = {} as any;
                              authPayload.ClientUrl = '/inventory/api/GetCustomers';
                              const customerPostPayload = {} as any;
                              customerPostPayload.transTypeId = MasterDataCollection.TransactionType;
                              customerPostPayload.locationId = FormGroupData.value.FromLocId;
                              customerPostPayload.searchText = '';
                              customerPostPayload.skip = 0;
                              customerPostPayload.take = 100;
                              customerPostPayload.getTotalRecCount = true;
                              customerPostPayload.fetchAll = false;
                              customerPostPayload.totalCount = 0;
                              customerPostPayload.lookupField = '';
                              customerPostPayload.orderByColumn = 'Name';
                              customerPostPayload.orderByDirection = 1;
                              authPayload.Payload = customerPostPayload;
                              const customerResultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
                              FormField.forEach((x: any) => {
                                if (x.hasOwnProperty('fieldType') && x.fieldType.hasOwnProperty('SingleData') &&
                                   x.fieldType.SingleData.name === 'CustomerId') {
                                    x.fieldType.SingleData.disbaledProperty = false;
                                }
                              });
                              const customerDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'CustomerId');
                              FormField[customerDropdownIndex].fieldType.SingleData.autoCompleteData = customerResultData.ResultData;
                              FormField[customerDropdownIndex].autoCompleteConfig.EnableLoadMore = 
                              customerResultData.ResultData?.length === customerResultData.TotalCount ? false : true;
                              FormGroupData.controls['CustomerId'].setValidators([Validators.required]);
                              FormGroupData.controls['CustomerId'].markAsTouched();
                              FormGroupData.controls['CustomerId'].updateValueAndValidity();
                          } else {
                            FormGroupData.controls['CustomerId'].clearValidators();
                            FormGroupData.controls['CustomerId'].setValue(null);
                            FormField.forEach((x: any) => {
                                if (x.type === 'multipleautocomplete') {
                                if (x.hasOwnProperty('fieldType') && x.fieldType.hasOwnProperty('SingleData') && 
                                x.fieldType.SingleData.name === 'CustomerId') {
                                    x.fieldType.SingleData.disbaledProperty = true;
                                    x.fieldType.SingleData.autoCompleteData = [];
                                }
                            }
                            });
                          }                        
                          FormGroupData.controls['CustomerId'].updateValueAndValidity();
                          
                      }  
                   }
                   if (!FormGroupData.value['IsTransferOut']) {
                    CustomFormField.CustomComapareLocation(
                     ViewModelEntity, PostModelEntity, MasterDataCollection, httpService, 
                     FieldGrouping, FormGroupData, FormField, CustomData  );
                   }
                });               
            }
         
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };