import { DefineDataObjectConfig } from "../construct-config.interface";
import { CommonService } from "src/app/eatecui/source/shared/services/common.service";
import { ViewTransactionService } from "src/app/eatecui/source/transaction/view-transaction/view-transaction.service";
import { SysytemPreferenceService } from "src/app/eatecui/source/setup/system-preference/system-preference.service";
import { RequestEngine } from "src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine";
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ExchangeRateModel, Currencies } from '../post-model-per-module/setup-post-model.interface';

export const CreateSetUpData: DefineDataObjectConfig = (ModuleKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine, Value?: any): any => {
        try {
            const homeGroupValue = Value; 
            let PostObject: any;    
            switch(JsonData.Name) {
                case 'Currencies': 
                const newCurrency: Currencies = {
                    Id: homeGroupValue.id,
                    Name: homeGroupValue.name,
                    Code: homeGroupValue.number,
                    NumericCode: homeGroupValue.numericCode,
                    IsActive: homeGroupValue.isActive,
                    SortOrder: homeGroupValue.SortOrder ? parseFloat(homeGroupValue.SortOrder.toString()) : 0,
                    Decimals: homeGroupValue.decimals ? parseFloat(homeGroupValue.decimals.toString()) : 0,
                    Description: homeGroupValue.description,
                    IsSystem: homeGroupValue.isSystem
                };
                PostObject = newCurrency;
                break;
                default:
                    PostObject = homeGroupValue;
                break;
            }
            return PostObject;
        } catch (error) {
            console.error('Error Occured in Create Event Post Data Construction -', error);
        }
    }
