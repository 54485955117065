import { Injectable } from '@angular/core';
import { CommonCommunication } from '../../communication/services/common-communication-services';
import { CommonControllersRoutes } from '../../communication/common-route';
import { DistributionListApi } from '../../Models/notification.model';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { BaseResponse } from '../../Models/http.model';

@Injectable()
export class DistributionListDataService {

    constructor(private _commonCommunication: CommonCommunication, private http: HttpServiceCall) {
    }


    public getDistributionList(productId: number): Promise<DistributionListApi[]> {
        return this._commonCommunication.getPromise<DistributionListApi[]>({
            route: CommonControllersRoutes.GetDistributionList,
            uriParams: { productId: productId }
        },true);
    }

    public createDistributionList(body: DistributionListApi): Promise<boolean> {
        return this._commonCommunication.postPromise<boolean>({
            route: CommonControllersRoutes.CreateDistributionList,
            body: body
        },true);
    }

    public updateDistributionList(apiValue: DistributionListApi, id: number): Promise<boolean> {
        return this._commonCommunication.putPromise<boolean>({
            route: CommonControllersRoutes.UpdateDistributionList,
            uriParams: { id: id },
            body: apiValue
        },true);
    }

    public deleteDistributionList(id: number): Promise<boolean> {
        return this._commonCommunication.deletePromise<boolean>({
            route: CommonControllersRoutes.DeleteDistributionList,
            uriParams: { id: id }
        },true);
    }

    public async GetSNCDistListIds(): Promise<number[]>{
        const response: BaseResponse<number[]> = await this.http.CallApiAsync<number[]>(
            {
              callDesc: 'Services/Schedule/distlist',
              host: Host.SalesCateringService,
              method: HttpMethod.Get,
            }
          );
          return response.result;
    }
}