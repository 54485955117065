import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appCharacterCount]'
})
export class CharacterCountDirective implements AfterViewInit {
  @Input() maxlength = 0;
  private valueChangesSubscription: Subscription;

  constructor(private element: ElementRef, @Optional() @Self() private ngControl: NgControl) {
    
   } 


   ngAfterViewInit() { 
    if(this.maxlength>0){
    let currentfocus = this.element.nativeElement.parentNode.lastElementChild.lastElementChild;
      currentfocus.classList.add('w-100');
      currentfocus.appendChild(document.createElement('span')).classList.add('float-right');
      currentfocus.lastElementChild.innerHTML='/'+this.maxlength;
      currentfocus.appendChild(document.createElement('span')).classList.add('float-right');
      currentfocus.lastElementChild.innerHTML=0;
       // Update the character count initially
       this.updateCharacterCount(this.element.nativeElement.value);

       // If using reactive forms, subscribe to valueChanges
       if (this.ngControl && this.ngControl.control) {
         this.valueChangesSubscription = this.ngControl.control.valueChanges.subscribe(value => {
           this.updateCharacterCount(value);
         });
       }
    }
   }  
   @HostListener('input', ['$event']) oninput(event) {
    if(this.maxlength>0){  
    this.element.nativeElement.parentNode.lastElementChild.lastElementChild.lastElementChild.innerHTML = event.target.value.length;
    }
   }

   private updateCharacterCount(value: string) {
    const currentFocus = this.element.nativeElement.parentNode.lastElementChild.lastElementChild;
    currentFocus.lastElementChild.innerHTML = value.length;
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
