import { RequestPagination } from 'src/app/eatecui/source/shared/nonodatacore/pagination/requestpagination';
import { RequestEngine } from 'src/app/eatecui/source/shared/nonodatacore/request-engine/requestengine';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { PlanHeadButchery, PlanHeadGet, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { ViewTransactionService } from 'src/app/eatecui/source/transaction/view-transaction/view-transaction.service';
import moment from 'moment';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';
import { DefineDataObjectConfig } from '../construct-config.interface';
import * as constants from '@shared/constant/GlobalConstants';

export const PlanHeadButcheryConstruction: DefineDataObjectConfig = (OdataKey: string, commonService: CommonService,
    JsonData: TransactionConfiguration, LocationIds: any, transactionService: ViewTransactionService, IsActive: boolean,
    sysytemPreferenceService: SysytemPreferenceService, requestEngine ?: RequestEngine): any => {
    try {        
        const orderByColumn = transactionService.
        setSortColumn(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortDirection = transactionService.
        setSortDirection(JsonData.ViewTranscationConfig.GridConfiguration.GridSort);
        const sortingData = new RequestPagination(orderByColumn, sortDirection, requestEngine.FetchAll,
        requestEngine.LocationIds );
        const requestPayload = sortingData.getSortPayload(orderByColumn, sortDirection);
        const planButcheryModel = {} as PlanHeadButchery;        
        if (sysytemPreferenceService && sysytemPreferenceService.globalSystemPreference) {            
          const LoadFromTransactionFilter = sysytemPreferenceService.globalSystemPreference.
            filter(x => x.LookupName === 'LoadFromTransactionsFilter')[0].LookupValue;
          const allowFilter = true;
          if (LoadFromTransactionFilter > 1 && allowFilter) {
              const selectedDays = (LoadFromTransactionFilter - 1) * 14;
              planButcheryModel.FromDate = formatDateFilter(moment().subtract((selectedDays - 1), 'days'), 'FromDate');
              planButcheryModel.ToDate = formatDateFilter(moment(), 'ToDate');
          }
      }
        planButcheryModel.PlanTypeId = 2;
        planButcheryModel.PlanPeriodLengthIds = [];
        const PostData = Object.assign(requestPayload, planButcheryModel);
        return PostData;
    } catch (error) {
        console.error('Error occurred in PlanHeadConstruction', error);
    }
};

function formatDateFilter(DateValue, DateType: string) {
  try {
    let currentDate;
    if (DateType === 'ToDate') {
      currentDate = moment(DateValue).endOf('day').format(constants.PostDateTimeFormat);
    } else {
      currentDate =  moment(DateValue).startOf('day').format(constants.PostDateTimeFormat);
    }
    return currentDate;
  } catch (error) {
    console.error('Error occurred in formatDateFilter', error);

  }
}