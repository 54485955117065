import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { SpaUtilities, PatchOperation, PatchJson } from "../../shared/utilities/spa-utilities";
import { SpaLocalization } from "../../core/localization/spa-localization";
import { PropertySetting, PaymentConfiguration, PropertyConfiguration } from "../../shared/business/view-settings.modals";
import { BaseResponse } from "../../shared/business/shared.modals";
import { Host } from "../../shared/globalsContant";

// Data Service only for property setting context in Authentication DB
@Injectable()
export class PropertySettingDataService {

    constructor(private http: HttpServiceCall, private utils: SpaUtilities, private localization: SpaLocalization) { }

    public async getAllPropertySetting(): Promise<PropertySetting> {
        try {
            return await this.invokePropertySettingCalls<PropertySetting>("getAllPropertySettings", HttpMethod.Get, undefined,
                { "propertyId": Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetTenantbyEnvironmentConfig(): Promise<string> {
        try {
            return await this.invokePropertySettingCalls<string>("GetTenantbyConfig", HttpMethod.Get, undefined,
                {});
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetADB2CConfig(uriParams): Promise<string> {
        try {
            return await this.getADB2CConfig<string>("GetADB2CAuthConfig", HttpMethod.Get, undefined,
            uriParams);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetADB2CLogin(body): Promise<any> {
        try {
            return await this.getADB2CLogin<any>("ADB2CLogin", HttpMethod.Post, body,
            undefined);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async patchPropertySetting(body: Partial<PropertySetting>): Promise<boolean> {
        try {
            let patchJson: PatchJson = {
                op: PatchOperation.replace,
                path: "/ActivateRetailInterface",
                value: body.activateRetailInterface
            };
            return await this.invokePropertySettingCalls<boolean>("updatePropertySetting", HttpMethod.Patch, [patchJson],
                { "propertyId": Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetPaymentConfigurationByProperty(propertyId: number): Promise<PaymentConfiguration[]> {
        try {
            return await this.InvokeServiceCall<PaymentConfiguration[]>("GetPaymentConfigurationByProperty", HttpMethod.Get, Host.payment, undefined,
                { "propertyId": propertyId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

    private async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: host,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }


    private async invokePropertySettingCalls<T>(callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.authentication,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private async getADB2CConfig<T>(callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.authentication,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private async getADB2CLogin<T>(callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.authentication,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        const response1: any =response;
        return response1;
    }

    public async getPropConfig(): Promise<PropertyConfiguration<string>> {
        try {
            return await this.invokePropertySettingCalls<PropertyConfiguration<string>>("GetPropertyConfiguration", HttpMethod.Get, undefined,
                {
                    "propertyId": Number(this.utils.GetPropertyInfo('PropertyId')),
                    "productId": 0,
                    "configName": "PROPERTYCONFIGURATION"
                });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetSupportedPMAgentVersionByPropertyID(propertyId: number): Promise<any> {
        try {
            return await this.InvokeServiceCall<any>("GetSupportedPMAgentVersionByProperty", HttpMethod.Get, Host.payment, undefined,
                { "propertyId": propertyId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
}