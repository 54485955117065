import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridCustomColExpand, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridCustomExpandConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): GridCustomColExpand[] => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                const gridFilterAction = {} as GridCustomColExpand;
                let  gridFilterActionArray: any;
                const GridFilterArray = [];
                GridFilterArray.push(gridFilterAction);
                gridFilterActionArray = GridFilterArray;
                return TransactionCollection.ViewTranscationConfig.GridConfiguration.GridCustomExpand;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };