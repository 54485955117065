import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionManagerService } from '../shared/services/session-manager.service';
import { BreakPointAccess } from '../shared/shared/service/breakpoint.service';
import { UserAlerts } from '../shared/config/alerts-config';
import { PayAgentService } from '../shared/shared/service/payagent.service';
import { ImageProcessorService } from '../shared/shared/service/image-processor-service';
import { MoreSectionServiceService } from '../shared/shared/more-section/more-section-service.service';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { SPAConfig } from '../shared/config/SPA-config';
import { HttpServiceCall } from '../shared/shared/service/http-call.service';
import { FormatText } from '../shared/shared/pipes/formatText-pipe.pipe';
import { TemplatesComponent } from './templates.component';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { CommonSharedModule } from '../shared/shared/shared.module';
import { DocumentEditorComponent } from './document-editor/document-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ScrollbarModule } from 'ngx-scrollbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SmsTemplatesComponent } from './sms-templates/sms-templates.component';
import { TemplateEmailComponent } from './template-email/template-email.component';
import { TemplateSmsComponent } from './template-sms/template-sms.component';
import { CrudEmailTemplateComponent } from './template-email/crud-email-template/crud-email-template.component';
import { CrudSmsTemplateComponent } from './template-sms/crud-sms-template/crud-sms-template.component';
import { commonToggleSwitchModule } from '../shared/shared/common-toggle-switch/common-toggle-switch.module';
import { DistributionListComponent } from './distribution-list/distribution-list.component';
import { CrudDistributionListComponent } from './distribution-list/crud-distribution-list/crud-distribution-list.component';
import { NotificationConfigurationComponent } from './notification-configuration/notification-configuration.component';
import { PopoverModule } from 'ngx-smart-popover';
import { TemplateReportComponent } from './template-report/template-report.component';
import { CrudReportTemplateComponent } from './template-report/crud-report-template/crud-report-template.component';
import { KakaoTemplateMappingComponent } from './KAKaO-Template-Mapping/Kakao-Template-mapping.component';
import { CreateKaKaoMappingComponent } from './KAKaO-Template-Mapping/Create-KaKaoTemplate-Mapping/Create-KaKaoTemplate-mapping.component';
import { TemplateWhatsappComponent } from './template-whatsapp/template-whatsapp.component';
import { CrudWhatsappTemplateComponent } from './template-whatsapp/crud-whatsapp-template/crud-whatsapp-template.component';


@NgModule({
    declarations: [
        TemplatesComponent,
        EmailTemplatesComponent,
        DocumentEditorComponent,
        SmsTemplatesComponent,
        TemplateEmailComponent,
        TemplateSmsComponent,
        CrudEmailTemplateComponent,
        CrudSmsTemplateComponent,
        DistributionListComponent,
        CrudDistributionListComponent,
        NotificationConfigurationComponent,
        TemplateReportComponent,
        CrudReportTemplateComponent,
        KakaoTemplateMappingComponent,
        CreateKaKaoMappingComponent,
        TemplateWhatsappComponent,
        CrudWhatsappTemplateComponent
    ],
    imports: [
        CommonModule,
        CKEditorModule,
        ScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SwiperModule,
        UiSwitchModule.forRoot({}),
        CommonSharedModule,
        commonToggleSwitchModule,
        PopoverModule
    ],
    providers: [
        FormatText,
        SessionManagerService,
        BreakPointAccess,
        UserAlerts,
        PayAgentService,
        ImageProcessorService,
        MoreSectionServiceService,
        AuthGuardService,
        SPAConfig,
        HttpServiceCall
    ],
    exports: [
        TemplatesComponent,
        CrudEmailTemplateComponent,
        EmailTemplatesComponent,
        DocumentEditorComponent,
        SmsTemplatesComponent,
        TemplateEmailComponent,
        TemplateSmsComponent,
        DistributionListComponent,
        NotificationConfigurationComponent,
        KakaoTemplateMappingComponent,
        CreateKaKaoMappingComponent
    ]
})
export class TemplatesModule { }
