import { Injectable } from '@angular/core';
import { EformConfigDataService } from 'src/app/common/dataservices/eform-config.data.service';
import { EformConfig, Product } from 'src/app/common/Models/common.models';
import { UI } from './eform-config.model';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Injectable()
export class EformConfigBusiness {

  constructor(private _agilysysEformConfigDataService: EformConfigDataService, private utils: CommonUtilities,
    private _localization: Localization) {

  }

  public async GetAgilysysEformConfig() {
    let apiModel: EformConfig = await this._agilysysEformConfigDataService.getAgilysysEformConfig();
    const result = (apiModel != null) ? this.uiMapper(apiModel) : null;
    return result;
  }

  public async createAgilysysEformConfig(AgilysysEformConfig: UI.EformConfig): Promise<UI.EformConfig> {
    let apiModel: EformConfig = await this._agilysysEformConfigDataService.createAgilysysEformConfig(this.APIMapper(AgilysysEformConfig));
    return this.uiMapper(apiModel);
  }

  public async updateAgilysysEformConfig(AgilysysEformConfig: UI.EformConfig): Promise<UI.EformConfig> {
    let apiModel: EformConfig = await this._agilysysEformConfigDataService.updateAgilysysEformConfig(this.APIMapper(AgilysysEformConfig));
    return this.uiMapper(apiModel);
  }

  public async testConnection(agilysysEformConfig: UI.EformConfig) {
    return await this._agilysysEformConfigDataService.getEformTokenInfo(agilysysEformConfig.authenticationURL, agilysysEformConfig.userName, agilysysEformConfig.password);
  }

  private APIMapper(agilysysEformConfig: UI.EformConfig): EformConfig{
    return {
      id: agilysysEformConfig?.id ? agilysysEformConfig?.id : 0,
      enableEform : agilysysEformConfig?.enableEform,
      userName : agilysysEformConfig?.userName,
      password : agilysysEformConfig?.password ? this.utils.encodePassword(agilysysEformConfig?.password): "",
      authenticationURL : agilysysEformConfig?.authenticationURL,
      systemURL : agilysysEformConfig?.systemURL,
      documentURL : agilysysEformConfig?.documentURL,
      eformsTenantId: agilysysEformConfig?.eformsTenantId,
      eformsPropertyId: agilysysEformConfig?.eformsPropertyId
    } as EformConfig;
  }

  private uiMapper(agilysysEforms: EformConfig): UI.EformConfig {
    return {
      id: agilysysEforms?.id,
      enableEform : agilysysEforms?.enableEform,
      userName : agilysysEforms?.userName,
      password : agilysysEforms?.password ? this.utils.decodePassword(agilysysEforms?.password): "",
      authenticationURL : agilysysEforms?.authenticationURL,
      systemURL : agilysysEforms?.systemURL,
      documentURL : agilysysEforms?.documentURL,
      eformsTenantId: agilysysEforms?.eformsTenantId,
      eformsPropertyId : agilysysEforms?.eformsPropertyId
    } as UI.EformConfig;
  }
}


