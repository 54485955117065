import { Component, OnInit } from '@angular/core';
import { SpaLocalization } from '../localization/spa-localization';

@Component({
  selector: 'app-restrict-portrait',
  templateUrl: './restrict-portrait.component.html',
  styleUrls: ['./restrict-portrait.component.scss']
})
export class RestrictPortraitComponent implements OnInit {

  captions: any;

  constructor(private localization: SpaLocalization) { 
    this.captions= this.localization.captions;
  }

  ngOnInit(): void {
  }

}
