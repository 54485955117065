import { GridAppConfig, GridCustomColExpand } from '../app-grid.interface';
import { GetGridRowData } from '../grid.interface';

export const GetRowData: GetGridRowData = (Response: Array<any>, GridActionConfig: GridAppConfig): any => {
    try {
        const CustomExpand = GridActionConfig.GridCustomExpand;
        if (Response) {
            const RowDataList = [];
            Response.forEach((dataObject: any) => {
                for (const prop in dataObject) {
                    if (prop) {
                        if (dataObject[prop] && dataObject[prop] !== null) {
                            const propType = typeof dataObject[prop];
                            if (propType === 'object') {
                                if (Array.isArray(dataObject[prop])) {
                                    const returnValue = assignValuesFromArray(prop, dataObject[prop], dataObject, CustomExpand);
                                    dataObject = returnValue;
                                } else {
                                    const returnValue = assignNavigationProperity(prop, dataObject[prop], dataObject, CustomExpand);
                                    dataObject = returnValue;
                                }
                            }
                        }
                    }
                }
                RowDataList.push(dataObject);
            });
            return RowDataList;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error occurred in Get Row Data', error);
    }
};

function assignValuesFromArray(PropertyName: string, expandData: Array<any>, DataObject: any, CustomExpand: GridCustomColExpand[]): any {
    try {
        const entityObject = JSON.parse(JSON.stringify(DataObject));
        const currentexpand = CustomExpand.filter(x => x.PrimaryExpand.toLowerCase() === PropertyName.toLowerCase())[0];
        const value = [];
        expandData.forEach((rowData, index) => {
            if (rowData.hasOwnProperty('IsActive')) {
                if (rowData.IsActive) {
                    value.push(rowData[currentexpand.SecondaryExpand][currentexpand.SelectQuery]);
                }
            } else {
                value.push(rowData[currentexpand.SecondaryExpand][currentexpand.SelectQuery]);
            }
        });
        entityObject[PropertyName] = value.join(', ');
        return entityObject;
    } catch (error) {
        console.error('Error occurred in assignValuesFromArray', error);
    }
}

function assignNavigationProperity (PropertyName: any, expandData: object, DataObject: object,
    CustomExpand: GridCustomColExpand[]) {
    const entityObject = JSON.parse(JSON.stringify(DataObject));
    const currentexpand = CustomExpand.filter(x => x.PrimaryExpand.toLowerCase() === PropertyName.toLowerCase())[0];
    const referelcontraintName = currentexpand.PrimaryExpand;
    entityObject[referelcontraintName] = expandData[currentexpand.SelectQuery];
    return entityObject;
 }