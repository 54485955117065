import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridInterface } from '.';
import { GridFilterData, GridFilterValue } from '../master.interface';
import { GridFilterRefreshConfig } from './grid-config.interface';

export const GridFilterRefresh: GridFilterRefreshConfig = async (FilterData: Array<any>,
    httpService: HttpService): Promise<any> => {
    try {   
        if (FilterData && FilterData.length > 0) {
            return new Promise((resolve) => {
                const returnFilterResponse = [];
                FilterData.forEach((dataConfig, index) => {
                    if (dataConfig.FilterUrl) {
                        httpService.GethttpMethod(dataConfig.FilterUrl).subscribe(response => {
                            if (response && response['value'] && response['value'].length > 0) {
                                const filterKeyData = response['value'].map((data: any) => {
                                const filterKey = {} as GridFilterValue;
                                filterKey.FilterId = GridInterface.GridFilterKeyMapping(dataConfig, data);
                                filterKey.FilterName = GridInterface.GridFilterLabelMapping(dataConfig, data);
                                filterKey.Checked = false;
                                return filterKey;
                                });
                                dataConfig.FilterValue = filterKeyData;
                                returnFilterResponse.push(dataConfig);
                            } else {
                                dataConfig.FilterValue = [];
                                returnFilterResponse.push(dataConfig);
                            }
                            if (returnFilterResponse.length === FilterData.length) {
                                returnFilterResponse.sort((a, b) => a.SortOrder - b.SortOrder);
                                resolve(returnFilterResponse);
                            }     
                        });    
                    } else {
                        returnFilterResponse.push(dataConfig);
                    }          
                });
            });
        }
    } catch (error) {  
        console.error('Error occurred in GridSingleFilterOptionFetch', error);
    }
};