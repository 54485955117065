import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { GridFetchFields, TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety, PatchValueReassign } from './create-transaction-config.interface';

export const FormPatchValueReassign: PatchValueReassign = (
        TransactionCollection: TransactionConfiguration, responseData: any,
        ViewHeaderEntity: any,
        ViewDetailsEntity: any, 
        FormGroupData: FormGroup, 
        TilesHeaderTrascationEntity?: any,
        httpService?: HttpService, 
        translateService?: TranslateService): any => {
        try {
            if (TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.hasOwnProperty('GridFetchFields') &&
                TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFetchFields.length > 0) {
                const FetchFields = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridFetchFields;
                FetchFields.forEach((x: GridFetchFields) => {
                   const homeGroupData =  FormGroupData;
                   if (responseData[x.FieldFrom]) { 
                    homeGroupData.controls[x.FieldTo].setValue(responseData[x.FieldFrom]);
                    responseData[x.FieldTo] = responseData[x.FieldFrom];
                   }
                });
                return null;
            }
        } catch (Error) {
            console.error('Error occurred FetchGridProperties', Error);
        }
    };