import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ERRORCODE, ERRORMESSAGE, paths } from './const';
import { MatDialog } from '@angular/material/dialog';
import { AgilsysErrorPopComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-error-popup/agilysys-error-popup.component';
import { ErrorPopModel } from '../../agilysys-popup/model/pop-up.interface';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { Router } from '@angular/router';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog,
    private routeNavigateService: RouteNavigateService,
    private commonService: CommonService, private route: Router) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!!req[paths.error]) {
      return next.handle(req);
    }
    console.warn('ErrorInterceptor');

    return next.handle(req).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {

        let errorMsg = '';
        const returnError = this.validateError(error);
        if (returnError) {
          return throwError(error);
        }
        if (this.route.url && this.route.url.indexOf('settings/enhancedInventory') > -1) {
          if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            errorMsg = `Error: ${error.error.message}`;
            const errorClientModel = {} as ErrorPopModel;
            errorClientModel.PopupHeaderContent = 'Error Message Occurred(client)';
            errorClientModel.PopUpContent = error.error.message;
            this.openErrorPopup(errorClientModel, error);
          } else if (error.error instanceof Blob) {
            this.handleBlobError(error);
          } else {
            if (error.error && error.error.hasOwnProperty('response_Code') && error.error.response_Code === 401) {
              return next.handle(req);
            } else {
              const errroMessage = this.constrctErrorEngine(error);
              console.log('this is server side error');
              if (errroMessage) {
                this.openErrorPopup(errroMessage, error);
              }
            }
            // errorMsg = error.message;
            // if ( error && error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && 
            // error.error.error.hasOwnProperty('message') ) {
            //   errorMsg = error.error.error.message;
            // }
            // const ErrorPopUpDialog: ErrorPopModel = {
            //   PopupHeaderContent: 'Error Occurred',
            //   PopUpContent: errorMsg,
            // };
            // this.dialog.open(AgilsysErrorPopComponent, {
            //   width: '400px',
            //   data: errroMessage,
            // });

          }
          console.log(errorMsg);
        }
        return throwError(error);

      })
    );


  }

  validateError(error: HttpErrorResponse) {
    try {
      let returnValidateError = false;
      if (error && error.error && error.error.message && error.error.message.includes('invalid/Inactive tenant')) {
        if (error.url.includes('Login/sso')) {
          returnValidateError = true;
        } else if (!error.url.includes('Login')) {
          this.commonService.errorMessage = error.error.message;
          this.routeNavigateService.navigateRoute('tambering');
          returnValidateError = true;
        }
      } else if (error && error.url &&
        error.url.includes('/exchange/api/dataimport/workflow/history')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('/inventory/api/Integration/PhysicalInventory')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('/api/Integration/RetrieveAllDocument')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('api/Integration/CreateDocument')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.endsWith('dmsserver/dmsdoc/api/dms')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('dmsserver/dmsdoc/api/dms/indexingv2')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('/api/Integration/SavePhysicalInventory')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('/api/Integration/AuthenticateVendorPunchout')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('/inventory/api/Integration/GetIntegrationConfigToken')) {
        returnValidateError = true;
      } else if (error && error.url && error.url.includes('healthcheck')) {
        returnValidateError = true;
      } else if (error && error.url && error.url.includes('/inventory/api/BannerNotificationMessage')) {
        returnValidateError = true;
      } else if (error && error.url &&
        error.url.includes('/EatecUtility/ping')) {
        returnValidateError = true;
      } else if (error && error.url && error.url.includes('/inventory/api/DMIntegrationConfiguration')) {
        returnValidateError = true;
      }
      return returnValidateError;
    } catch (error) {
      console.error('Error occurred in validateError', error);
    }
  }
  handleBlobError(err: HttpErrorResponse): any {
    const _that = this;
    if ('application/problem+json' === err.headers.get('Content-Type')) {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        const responseType = JSON.parse(e.currentTarget['result']);
        if (responseType) {
          if (responseType.hasOwnProperty('IsShowMessage') && responseType.IsShowMessage) {
            const errorClientModel = {} as ErrorPopModel;
            errorClientModel.PopupHeaderContent = responseType.Title;
            errorClientModel.PopUpContent = responseType.message;
            _that.openErrorPopup(errorClientModel, err);

          }
        }
      });
      reader.readAsText(err.error);
    }
  }

  openErrorPopup(ErrorModel: ErrorPopModel, Error: HttpErrorResponse): void {
    try {
      if (Error.hasOwnProperty('error') && Error.error !== null &&
        Error.error.hasOwnProperty('IsShowMessage') && Error.error.IsShowMessage === false) {
        console.log('No Popup Error');
      } else {
        this.dialog.open(AgilsysErrorPopComponent, {
          width: '400px',
          data: ErrorModel,
          disableClose: true,
          panelClass: 'global-error-popup',
          backdropClass: 'AgilsysErrorPopComponent-base-class'
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  constrctErrorEngine(errorResponse: any): ErrorPopModel {
    try {
      const errorClientModel = {} as ErrorPopModel;
      errorClientModel.EnableInfo = false;
      errorClientModel.PopupHeaderContent = 'Error Occurred';
      errorClientModel.PopUpContent = 'Unhandled exception';
      errorClientModel.InfoBtnState = 'Less';
      if (errorResponse.hasOwnProperty('error') && errorResponse.error !== null && errorResponse.error.hasOwnProperty('data') &&
        errorResponse.error.data?.hasOwnProperty('err')) {
        errorClientModel.PopupHeaderContent = errorResponse.error.message;
        errorClientModel.PopUpContent = errorResponse.error.data.err.toString();
        if (errorResponse.error.hasOwnProperty('response_Code')) {
          errorClientModel.PopUpContent = this.errorCodeHander(errorResponse.error.data.err, errorResponse.error.response_Code);
          errorClientModel.EnableInfo = true;
          errorClientModel.InfoMessage = errorResponse.error.data.err;
        }
      } else if (errorResponse.hasOwnProperty('error') && errorResponse.error !== null && errorResponse.error.hasOwnProperty('value')) {
        errorClientModel.PopupHeaderContent = 'Error Occurred';
        errorClientModel.PopUpContent = errorResponse.error.value.toString();
        if (errorResponse.error.hasOwnProperty('response_Code')) {
          errorClientModel.PopUpContent = this.errorCodeHander(errorResponse.error.value, errorResponse.error.response_Code);
          errorClientModel.EnableInfo = true;
          errorClientModel.InfoMessage = errorResponse.error.data.err;
        }
      } else if (errorResponse.hasOwnProperty('error') && errorResponse.error !== null && errorResponse.error.hasOwnProperty('errors')) {
        const errorValue = [];
        for (const property in errorResponse.error.errors) {
          if (errorResponse.error.errors.hasOwnProperty(property)) {
            console.log(errorResponse.error.errors[property]);
            errorValue.push(errorResponse.error.errors[property].toString());
          }
        }
        errorClientModel.PopupHeaderContent = 'Error Occurred';
        errorClientModel.PopUpContent = errorValue.toString();
      } else if (errorResponse.hasOwnProperty('IsShowMessage') && errorResponse.IsShowMessage) {
        errorClientModel.PopupHeaderContent = errorResponse.Title;
        errorClientModel.PopUpContent = errorResponse.message;
      } else if (errorResponse.hasOwnProperty('error') && errorResponse.error === null) {
        errorClientModel.PopupHeaderContent = 'Error Occurred';
        errorClientModel.PopUpContent = 'Error Null';
        if (errorResponse.hasOwnProperty('status') && errorResponse.status === 401) {
          errorClientModel.PopUpContent = this.errorCodeHander('Unauthorized', errorResponse.status);
        }
      } else if (errorResponse.hasOwnProperty('error') && errorResponse.error !== null && (errorResponse.error.hasOwnProperty('message') || errorResponse.error.hasOwnProperty('message'))) {
        const responseCode = (errorResponse.error.hasOwnProperty('response_Code')) ? errorResponse.error.response_Code :
          errorResponse.status;
        const ErrorMessage = (errorResponse.error.message) ? errorResponse.error.message : errorResponse.error.message
        errorClientModel.PopupHeaderContent = (errorResponse.error.headerText) ? errorResponse.error.headerText : 'Error Occurred';
        errorClientModel.PopUpContent = this.errorCodeHander(ErrorMessage, responseCode);
        errorClientModel.EnableInfo = true;
        errorClientModel.InfoMessage = ErrorMessage;

      } else if (errorResponse.hasOwnProperty('error') && errorResponse.error !== null &&
        errorResponse.error.hasOwnProperty('IsShowMessage')) {
        const responseCode = (errorResponse.error.hasOwnProperty('response_Code')) ? errorResponse.error.response_Code :
          (errorResponse.error.StatusCode) ? errorResponse.error.StatusCode : errorResponse.status;
        errorClientModel.PopupHeaderContent = errorResponse.error.Title;
        errorClientModel.PopUpContent = this.errorCodeHander(errorResponse.error.message, responseCode);
        errorClientModel.EnableInfo = true;
        errorClientModel.InfoMessage = errorResponse.error.message;
      } else if (errorResponse.hasOwnProperty('error') && this.isJsonString(errorResponse.error)
        && JSON.parse(errorResponse.error).hasOwnProperty('success')) {
        const parsedError = JSON.parse(errorResponse.error);
        if (!parsedError.success) {
          errorClientModel.PopupHeaderContent = parsedError.message;
          errorClientModel.PopUpContent = this.errorCodeHander(parsedError.message, parsedError.response_Code);
          errorClientModel.EnableInfo = true;
          errorClientModel.InfoMessage = parsedError.data.err;
        }
      } else if (errorResponse.hasOwnProperty('error') && errorResponse.error.length > 0) {
        errorClientModel.PopupHeaderContent = 'Error Occurred';
        errorClientModel.PopUpContent = errorResponse.error[0];
      } else {
        const responseCode = (errorResponse && errorResponse.hasOwnProperty('error')
          && errorResponse.error.hasOwnProperty('response_Code')) ? errorResponse.error.response_Code :
          errorResponse.status;
        errorClientModel.PopupHeaderContent = 'Error Occurred';
        errorClientModel.PopUpContent = this.errorCodeHander(errorResponse.message, responseCode);
        errorClientModel.EnableInfo = true;
        errorClientModel.InfoMessage = errorResponse.message;

      }
      errorClientModel.InfoBtnState = (errorClientModel?.PopupHeaderContent?.toLowerCase() === 'validation failed') ? 'More' : 'Less';
      return errorClientModel;
    } catch (error) {
      console.error(error);
    }
  }

  errorCodeHander(DefaultMessage: string, ErrorCode: number): string {
    try {
      let errorMessage = DefaultMessage.toString();
      switch (ErrorCode) {
        case ERRORCODE.CODE_400:
          errorMessage = ERRORMESSAGE.ERROR_MESSAGE_400;
          break;
        case ERRORCODE.CODE_500:
          errorMessage = ERRORMESSAGE.ERROR_MESSAGE_500;
          break;
        case ERRORCODE.CODE_401:
          errorMessage = ERRORMESSAGE.ERROR_MESSAGE_401;
          break;
        default:
          errorMessage = DefaultMessage;
      }
      return errorMessage;
    } catch (error) {
      console.error(error);
    }
  }

  isJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}
