import { MasterInventoryManagement } from '../master.interface';
import { GridModelHeaderDefination } from './grid-config.interface';

export const SetDefaultSort: GridModelHeaderDefination =
    (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement, EntityProperty: any): any => {
        try {
            let defaultSortingString = '';
            if (MasterDataCollection && MasterDataCollection.GridConfig.GridSort.length > 0) {
                const sortingObject = MasterDataCollection.GridConfig.GridSort.filter(x => x.SortingOdataKey.toLocaleLowerCase()
                    === EntityProperty['$']['Name'].toLowerCase());
                if (sortingObject.length > 0) {
                    if (sortingObject[0].DefaultSorting) {
                        defaultSortingString = sortingObject[0].SortingType;
                    }
                }
            }
            return defaultSortingString.toLowerCase();
        } catch (error) {
            console.error('Error occurred in Setdefaultsort', error);
        }
    };
