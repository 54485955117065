import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomDietaryPreference: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
    ): any => {
        try {
            if (FieldGrouping.CustomFormAction) {
                MasterDataCollection.FormConfig.NavigationCollection[5].ModelCollection.push(FormGroupData.value.DietaryPreference);
            }
        } catch (error) {
            console.log('error occured CustomDietaryPreference', error);
        }
    };


