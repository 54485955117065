import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { PostPOSupplierConfig, TransactionConfiguration } from '../transaction.interface';
import { LoadAsRequisitions } from './create-transaction-config.interface';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { MatDialog } from '@angular/material/dialog';
import { AgilysysCreateReqPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-create-req-popup/agilysys-create-req-popup.component';
import { AddNewPopModel, PrintPopModel, PrintPopUpCallBack, TransactionTableData } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';
import { ToastMessage } from 'src/app/eatecui/source/shared/models/toast-message.interface';
import * as constants from 'src/app/eatecui/source/shared/enum/global.enum';
import { TranslateService } from '@ngx-translate/core';
import { CreateTransaction } from '.';
import { AgilysysPrintPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-print-popup/agilysys-print-popup.component';
import { GenerateReportFilterModel, GenerateReportModel } from "src/app/eatecui/source/reports/interface/reports.interface";
import { UploadMetaData } from 'src/app/eatecui/source/shared/models/file-data/upload-configuration.interface';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { ConfigService } from 'src/app/eatecui/source/msal-module/config.service';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const LoadAsRequisition: LoadAsRequisitions = (
  TransactionCollection: TransactionConfiguration,
  httpService: HttpService,
  transactionService: TransactionService,
  router: any,
  postModelHederEntity: any,
  postModelDetailEntity: any,
  homeGroup: FormGroup,
  GlobalOdataModel: any,
  toastrService: ToastrService,
  routerNavigateService: RouteNavigateService,
  selectedTransaction: any,
  dialog: MatDialog,
  buttonCallBackValue: any,
  selectedId?: any,
  selectedSeq?: any,
  analysisRedirect?: any,
  translateService?: TranslateService,
  configService?: ConfigService,
  integrationService?: IntegrationService,
  commonService?: CommonService): any => {
  if (TransactionCollection.CreateTranscationConfig) {
    httpService.GethttpMethod(`/inventory/api/IsApprovalRequired/${TransactionCollection.TransactionType}`).subscribe(res => {
      const requestMethod = CreateTransaction.HttpServiceMethod(null, JSON.parse(decodeURIComponent(router.snapshot.queryParams.data)));
      const MessageName = translateService.instant(`${TransactionCollection.PostEntityName}.${TransactionCollection.Name}`);
      const PostModelEndPoint = TransactionCollection.PostEntityName;
      const postObject = transactionService.constractTransactionHeaderPostModel(
        postModelHederEntity, postModelDetailEntity, TransactionCollection, homeGroup, requestMethod, GlobalOdataModel);
      if (requestMethod === 'PosthttpMethod') {
        postObject['Id'] = 0;
      }
      const EndPointUrl = requestMethod === 'PuthttpMethod' ? `/inventory/api/${PostModelEndPoint}/${selectedId}` :
        `/inventory/api/${PostModelEndPoint}`;
      const GridDataHasApprovalLevel = TransactionCollection.CreateTranscationConfig.ItemsConfig.ItemList.
        ItemGridConfig.GridData.filter(y => y.IsDeleted === false || y.IsDeleted === undefined).some(x => x.ApprovalLevel === true);
      if (GridDataHasApprovalLevel && res) {
        if (requestMethod === 'PosthttpMethod' || requestMethod === 'PuthttpMethod') {
          const labelText = requestMethod === 'PosthttpMethod' ? 'create new' : 'edit';
          const ConfirmPopUpDialog: AddNewPopModel = {
            PopupHeaderContent: requestMethod === 'PosthttpMethod' ? `Create ${MessageName}` : `Edit ${MessageName}`,
            PopUpContent: `Are you sure you want to ${labelText} ${MessageName.toLowerCase()}?`,
            ClassName: '',
            FormType: '',
            FormFields: [{
              name: 'createtemplate',
              type: 'checkboxsingle',
              hide: true,
              disbaledproperity: false,
              fieldType: {
                SingleData: {
                  hide: true,
                  name: 'createtemplate',
                  value: false,
                  disbaledProperty: true,
                  label: 'Save this requisition as a new template'
                }
              }
            },
            {
              name: 'submitapproval',
              type: 'checkboxsingle',
              disbaledproperity: false,
              hide: res ? false : true,
              fieldType: {
                SingleData: {
                  hide: res ? false : true,
                  name: 'submitapproval',
                  value: true,
                  disbaledProperty: false,
                  label: 'Submit request for approval'
                }
              }
            }]
          };
          const dialogRef = dialog.open(AgilysysCreateReqPopupComponent, {
            width: '500px',
            height: '275px',
            data: ConfirmPopUpDialog,
            disableClose: true
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result[0] === 'Confirm') {
              const homeGroup1: FormGroup = result[1];
              if (homeGroup1.controls['submitapproval'].value) {
                postObject['IsApprovalRequired'] = homeGroup1.controls['submitapproval'].value;
              }
              postcall(requestMethod, EndPointUrl, postObject, homeGroup1, MessageName, PostModelEndPoint, buttonCallBackValue, commonService);
            } else {
              console.log('Cancel');
            }
          });  
        } else {
          httpService[requestMethod](EndPointUrl, postObject).subscribe(async (x: any) => {
            let responseId;
            responseId = (x && x.hasOwnProperty('Id')) ? ` #${x.Id}` : ` #${selectedId}`;
            const toastText = (requestMethod === 'PuthttpMethod') ? 'Updated' : ' Created';
            const toastMessage = `${MessageName.charAt(0).toUpperCase() + MessageName.slice(1) + responseId} ${toastText} Successfully`;
            const message: ToastMessage = {
              Message: toastMessage,
              Show: true,
              AutoClose: true,
              TimerCount: 20000
            };
            toastrService.success(message.Message, '', {
              timeOut: 3000,
              closeButton: true,
            });
            const transActionFrom = { queryParams: { transaction: 'requisition' } };
            const transActionrouterUrl = `transaction/view-requisition`;
            routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
          });
        }
      } else {        
        postObject['IsApprovalRequired'] = true;
        postcall(requestMethod, EndPointUrl, postObject, null, MessageName, PostModelEndPoint, buttonCallBackValue, commonService);
      }
    });
  }

  function postcall(requestMethod, EndPointUrl, postObject, homeGroup1, MessageName, PostModelEndPoint, buttonValues, commonService) {    
    httpService[requestMethod](EndPointUrl, postObject).subscribe(async (x: any) => {
      let responseId, toastText, toastMessage;
      if (homeGroup1 && homeGroup1.controls &&  homeGroup1.controls['submitapproval'].value) {
        responseId = (x && x.Data && x.Data.resultEntity &&  x.Data.resultEntity.hasOwnProperty('Id')) ?
          ` #${x.Data.resultEntity.Id}` : ` #${selectedId}`;
        toastText = (requestMethod === 'PuthttpMethod') ? 'Updated' : ' Created';
        toastMessage = `${MessageName.charAt(0).toUpperCase() + MessageName.slice(1) + responseId} ${toastText} Successfully.`;
        toastMessage = x.Message;
      } else {
        responseId =  x && x.Data && x.Data.resultEntity &&  x.Data.resultEntity.hasOwnProperty('Id') ? ` #${x.Data.resultEntity.Id}`
         : (x && x.hasOwnProperty('Id')) ? ` #${x.Id}` : ` #${selectedId}`;
        toastText = (requestMethod === 'PuthttpMethod') ? 'Updated' : ' Created';
        toastMessage = x.Message;
      }
      if (TransactionCollection.AttachedFilesConfig) {
        const fileAttachedData: Array<UploadMetaData> = TransactionCollection.AttachedFilesConfig.AttachMentConfig.FileAttachedData;
        if (fileAttachedData && fileAttachedData.length > 0) {
            fileAttachedData.forEach(req => {
                req.IsTransaction = true;
                if (requestMethod === 'PuthttpMethod') {
                    req.ItemId = selectedId;
                } else {
                    req.ItemId = x && x.Data && x.Data.resultEntity &&  x.Data.resultEntity.hasOwnProperty('Id')
                     ? x.Data.resultEntity.Id : 0;
                }
            });
            const AllowedIntegration: Array<string> = ['receiving', 'openpurchaserequest', 'allpurchaserequest', 
            'savedreceiving', 'requisition', 'spoilage', 'opentransaction', 'transfer', 'production', 'sales'];
            const IntegrationIndex: number = AllowedIntegration.findIndex(y => y === selectedTransaction);
            const dmIntegCollect = 
            integrationService.IntegrationConfig.IntegrationCollection.filter(y => y.IntegratonName === 'DataMagine')[0];
            const authorizeData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
            const setupSubModules = authorizeData.filter(x => x.Name === 'setupmanagement')[0].SubModules;
            const setupPermissionResources = setupSubModules.filter(m => m.Name === 'setup')[0].PermissionResource;
            const setupDMMappingsEnabled = setupPermissionResources.filter(r => r.Name === 'DMMappings')[0].IsActive;
            if (configService.enableIntegration && IntegrationIndex > -1 && dmIntegCollect.IsEnabled && setupDMMappingsEnabled) {
              const FromGridData: Array<TransactionTableData> = TransactionCollection.CreateTranscationConfig.DetailsConfig
              .DetailTableCard.DetailsTableCardBuilder.GridTableData;
              const refObj = {
                  DisplayName: selectedTransaction === 'requisition' ? 'Requisition ID#' : 'Spoilage ID#',
                  HeaderName: selectedTransaction === 'requisition' ? 'RequisitionsID' : 'SpoilageID',
                  Odatakey: selectedTransaction === 'requisition' ? 'RequisitionsID' : 'SpoilageID',
                  Value: requestMethod === 'PuthttpMethod' ? selectedId : x.Data.resultEntity.Id
              };
              FromGridData.push(refObj);        
              if (selectedTransaction === 'openpurchaserequest' || selectedTransaction === 'allpurchaserequest') {
                const IndexNo = FromGridData.findIndex(x => x.Odatakey.toLowerCase() === 'transsequenceno');
                  if (IndexNo !== -1) {
                    FromGridData[IndexNo].Value = requestMethod === 'PuthttpMethod' ? selectedId : x.Data.resultEntity.Id;
                }
              }      
              integrationService.dmIntergerationInstance.GetModuleIndexValue(TransactionCollection.Name, null);
              integrationService.dmIntergerationInstance.PostDatamagineFileData(fileAttachedData, FromGridData);
          } else {
            FileData.FileDataPost(fileAttachedData, httpService, toastrService);
          }
      }
      }
      if (buttonValues &&
        buttonValues.buttonValue.hasOwnProperty('EnablePrintPost') &&
        buttonValues.buttonValue.EnablePrintPost
    ) {
        // const Printenpoint = '/reportdata/api/ReportModule?$select=Id,Name&$expand=Reports($filter=IsActive eq true and IsPrint eq true;$select=Id,Name,FileName,Description)&$filter=IsActive eq true And Reports/any(c: c/IsActive eq true and c/IsPrint eq true)';
        // const getPrintList = await httpService.GethttpMethod(Printenpoint).toPromise();
        let selectedTransactionName = null;
        switch (selectedTransaction) {
            case 'pientercounts':
            case 'pientercyclecounts':
                selectedTransactionName = 'Physical Inventory';
                break;
            case 'opentransaction':
                selectedTransactionName = 'Transfer';
                break;
            case 'apcredits':
                selectedTransactionName = 'AP Credits';
                break;
            case 'openpurchaserequest':
            case 'allpurchaserequest':
            case 'vendorpunchout':
                selectedTransactionName = 'Purchase Request';
                break;
            case 'apvoucherrni':
                selectedTransactionName = 'AP Vouchers';
                break;
            case 'savedreceiving':
                selectedTransactionName = 'Receiving';
                break;
            default:
                selectedTransactionName = selectedTransaction;
                break;
        }
        const PrintId = await commonService.getPrintId(selectedTransactionName);
        const getPrintList = await commonService.getPrintListById(PrintId);
        const EnablePopupResult: PrintPopUpCallBack = await OpenPrintList(dialog, getPrintList, selectedTransactionName, PrintId);
        if (EnablePopupResult && EnablePopupResult.ButtonKey === 'Confirm' && EnablePopupResult.SelectedId !== null) {
            const generateReportModel = {} as GenerateReportModel;
            generateReportModel.reportFormat = 0;
            generateReportModel.fileName = null;
            generateReportModel.inLine = false;
            generateReportModel.sortBy = 'string';
            generateReportModel.reportid = EnablePopupResult.SelectedId;
            generateReportModel.isAsc = true;
            generateReportModel.isRetail = true;
            const generateReportFilterModel = {} as GenerateReportFilterModel;
            generateReportFilterModel.property = 'id';
            if (selectedTransaction.toLowerCase() === 'spoilage') {
              generateReportFilterModel.value = x.Data && x.Data.resultEntity && x.Data.resultEntity.Id ?
              x.Data.resultEntity.Id?.toString() : ` ${selectedId}`;
          }
            generateReportFilterModel.value = (x && x.hasOwnProperty('Id')) ? ` ${x.Id}` :
                (x.Data && x.Data.resultEntity && x.Data.resultEntity.Id ?
                  ` ${x.Data.resultEntity.Id}` : ` ${selectedId}`);
            generateReportFilterModel.isExclude = false;
            generateReportModel.filters = [generateReportFilterModel];
            generateReportModel.IanaTimeZoneId = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
            generateReportModel.UserTimeZoneId = new Date()?.toString()?.split("(")[1]?.split(")")[0];
            const postUrl = `/ReportData/api/GenerateReport`;
            const reportData = await httpService.PosthttpMethod(postUrl,
                generateReportModel, null, { observe: 'response', responseType: 'blob' }).toPromise();
            const file = new Blob([reportData.body], { type: reportData.body.type });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }
      const message: ToastMessage = {
        Message: toastMessage,
        Show: true,
        AutoClose: true,
        TimerCount: 20000
      };
      toastrService.success(message.Message, '', {
        timeOut: 3000,
        closeButton: true,
      });
      let transActionFrom = { queryParams: { transaction: selectedTransaction } };
      let transActionrouterUrl = `transaction/view-${selectedTransaction}`;
      
      if ( analysisRedirect && analysisRedirect !== undefined ) {
        transActionFrom = { queryParams: analysisRedirect };
        if (analysisRedirect?.isFromPlan) {
            transActionrouterUrl = `sales-management/copynew-${analysisRedirect?.isFromPlan}`;
        } else {
            transActionrouterUrl = 'inventory-management/analysis';
        }
      }
      routerNavigateService.navigateRoute(transActionrouterUrl, transActionFrom);
      if (homeGroup1 && homeGroup1.controls['createtemplate'] && homeGroup1.controls['createtemplate'].value) {
        postObject['TransTypeId'] = constants.TransTypeId.Template;
        postObject['ReqDetails'].forEach(element => {
          if (element.ReqTemplateId) {
            element.ReqTemplateId = null;
          }
        });
        const TemplateUrl = `/inventory/api/${PostModelEndPoint}`;
        httpService.PosthttpMethod(TemplateUrl, postObject).subscribe(resp => {
          let responseId1;
          responseId1 = (resp && resp.hasOwnProperty('Id')) ? ` #${resp.Id}` : ` #${selectedId}`;
          const toastMessage1 = `Template ${responseId1} Created Successfully`;
          toastrService.success(toastMessage1, '', {
            timeOut: 3000,
            closeButton: true,
          });
        });
      }
    });
  }

  function OpenPrintList(dialogs: MatDialog, ResultData: Array<any>, selectedtransaction: string, PrintId: number) {
    try {
        return new Promise<PrintPopUpCallBack>(resolve => {
            const printPopModel = {} as PrintPopModel;
            printPopModel.PopUpContent = `${selectedtransaction} Print`;
            printPopModel.PopupHeaderContent = `${selectedtransaction} Print`;
            printPopModel.PrintList = ResultData;
            printPopModel.PrintKey = selectedtransaction;
            printPopModel.EnableCount = false;
            printPopModel.PrintId = PrintId;
            const dialogRef = dialogs.open(AgilysysPrintPopupComponent, {
                width: '40%',
                height: '30%',
                data: printPopModel,
                disableClose: true
            });
            dialogRef.afterClosed().subscribe((result: PrintPopUpCallBack) => {
                resolve(result);
            });
        });
    } catch (error) {
        console.error(error);
    }
  }
};
