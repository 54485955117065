import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RetailComponent } from './retail.component';
import { CodeSetupComponent } from './retail-code-setup/retail-code-setup.component';
import { RetailChildSetupComponent } from './retail-child-setup/retail-child-setup.component';
import { RetailQuickSaleComponent } from './retail-quick-sale/retail-quick-sale.component';
import { RetailCommissionTemplateComponent } from './retail-commission-setup/retail-commission-setup.component';
import { CategoryLinkingComponent } from './category-linking/category-linking.component';
import { RetailDiscountConfigurationComponent } from './retail-discount-configuration/retail-discount-configuration.component';
import { RetailCreditCardTerminalComponent } from './retail-code-setup/retail-credit-card-terminal/retail-credit-card-terminal.component';
import { RetailOutletsComponent } from './retail-code-setup/retail-outlets/retail-outlets.component';
import { RetailCategoryGroupComponent } from './retail-code-setup/retail-category-group/retail-category-group.component';
import { RetailCategoriesComponent } from './retail-code-setup/retail-categories/retail-categories.component';
import { RetailSubCategoriesComponent } from './retail-code-setup/retail-sub-categories/retail-sub-categories.component';
import { RetailUnitOfMeasureComponent } from './retail-code-setup/retail-unit-of-measure/retail-unit-of-measure.component';
import { RetailTaxesComponent } from './retail-code-setup/retail-taxes/retail-taxes.component';
import { RetailDiscountTypesComponent } from './retail-code-setup/retail-discount-types/retail-discount-types.component';
import { RetailPaymentMethodComponent } from './retail-payment-method/retail-payment-method.component';
import { RetailQuickSaleCategoriesComponent } from './retail-code-setup/retail-quick-sale-categories/retail-quick-sale-categories.component';
import { VatComponent } from './sytem-config/vat-configuration/vat-configuration.component';
import { PaymentFeaturesConfigComponent } from './sytem-config/payment-features-config/payment-features-config.component';
import { CreditCardsComponent } from './sytem-config/credit-cards/credit-cards.component';
import { GiftCardsComponent } from './sytem-config/gift-cards/gift-cards.component';
import { RetailBreakPoint } from './shared/globalsContant';
import { RouteGuardService } from './shared/service/route-gaurd.service';
import { RetailOutletTerminalComponent } from './retail-code-setup/retail-outlet-terminal/retail-outlet-terminal.component';
import { RetailVendorSetupComponent } from './retail-vendor-setup/retail-vendor-setup.component';
import { RetailVendorTypesComponent } from './retail-code-setup/retail-vendor-types/retail-vendor-types.component';
import { PostTypeMappingComponent } from './retail-code-setup/post-type-mapping/post-type-mapping.component';
//import { DeactivateGuard } from '../core/services/Route-Guards/deactivate.guard.service';
import { TaxTypeComponent } from './retail-code-setup/retail-taxes/tax-type/tax-type.component';
import { RetailDiscountReasonComponent } from './retail-code-setup/retail-discount-reason/retail-discount-reason.component';
import { TaxExemptCategoryComponent } from './retail-code-setup/tax-exempt-category/tax-exempt-category.component';
import { RetailMachineNameComponent } from './retail-code-setup/machine-name/machine-name.component';
import { LetterSetupComponent } from './letter-setup/letter-setup.component';
import { ClubAgreementComponent } from './letter-setup/club-agreement/club-agreement.component';
import { CartAgreementComponent } from './letter-setup/cart-agreement/cart-agreement.component';
import { ShoeAgreementComponent } from './letter-setup/shoe-agreement/shoe-agreement.component';
import { retailEventName } from './shared/events/event.model';
import { SaleChitComponent } from './letter-setup/sale-chit/sale-chit.component';
import { MemberAccrualMappingComponent } from './retail-code-setup/member-accrual-mapping/member-accrual-mapping.component';
import { CanDeactivateGuard } from './shared/service/can-deactivate.guard.service';
import { ResortFinancePostTypeMappingComponent } from './retail-code-setup/resort-finance-post-type-mapping/resort-finance-post-type-mapping.component';
import { GeneralLedgerMappingComponent } from './retail-code-setup/general-ledger-mapping/general-ledger-mapping.component';
import { CustomFeeComponent } from '../common/shared/shared/custom-fee/custom-fee.component';
import { ExternalPostTypeMappingComponent } from './external-post-type-mapping/external-post-type-mapping.component';
import { RetailTierLevelComponent } from './retail-code-setup/retail-tier-level/retail-tier-level.component';
import { NonIntegratedCreditCardComponent } from './sytem-config/non-integrated-credit-card/non-integrated-credit-card.component';
import { VipTypeComponent } from '../common/components/vip-type/vip-type.component';
import {Product} from 'src/app/common/enums/shared-enums'

let productId = 0;
let propertyInfo = sessionStorage.getItem('propertyInfo');
if(propertyInfo) productId = Number(propertyInfo?.split(';').map(x => ({ key: x.split('=')[0].trim(), value: x.split('=')[1] }))?.find(x => x.key === 'ProductId')?.value);
let hookpoint = (productId == Product.ACCOUNTING) ? '/accounting':'/settings';
const routeSetup = (productId == Product.ACCOUNTING) ? 'codesetup' : 'retailsetup';
const routes: Routes = [{
  path: '',
  component: RetailComponent,
  children: [
    { path: '', redirectTo: routeSetup, pathMatch: 'full' },
    {
      path: 'codesetup',
      component: CodeSetupComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: 0, redirectTo: hookpoint+'/inventory', hasChild: true },
      children: [
        { path: '', redirectTo: 'creditcardterminals', pathMatch: 'full' },
        {
          path: 'creditcardterminals',
          component: RetailCreditCardTerminalComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.creditcardterminal, redirectTo: hookpoint+'/retailsetup/codesetup/outlets' }
        },
        {
          path: 'outlets',
          component: RetailOutletsComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.OutletSetup, redirectTo: hookpoint+'/retailsetup/codesetup/storeterminals' }
        },
        {
          path: 'storeterminals',
          component: RetailOutletTerminalComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.StoreTerminal, redirectTo: hookpoint+'/retailsetup/codesetup/categorygroups' }
        },
        {
          path: 'categorygroups',
          component: RetailCategoryGroupComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.CategoryGroup, redirectTo: hookpoint+'/retailsetup/codesetup/retailcategories' }
        },
        {
          path: 'retailcategories',
          component: RetailCategoriesComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.RetailCategories, redirectTo: hookpoint+'/retailsetup/codesetup/retailsubcategories' }
        },
        {
          path: 'retailsubcategories',
          component: RetailSubCategoriesComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.RetailSubCategories, redirectTo: hookpoint+'/retailsetup/codesetup/unitofmeasure' }
        },
        {
          path: 'unitofmeasure',
          component: RetailUnitOfMeasureComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.UnitofMeasure, redirectTo: hookpoint+'/retailsetup/codesetup/taxes' }
        },
        {
          path: 'taxes',
          component: RetailTaxesComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.Taxconfiguration, redirectTo: hookpoint+'/retailsetup/codesetup/taxtype' }
        },
        {
          path: 'taxtype',
          component: TaxTypeComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.Taxconfiguration, redirectTo: hookpoint+'/retailsetup/codesetup/discounttypes' }
        },
        {
          path: 'discounttypes',
          component: RetailDiscountTypesComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.DiscountType, redirectTo: hookpoint+'/retailsetup/codesetup/paymentmethods' }
        },
        {
          path: 'paymentmethods',
          component: RetailPaymentMethodComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.PaymentMethods, redirectTo: hookpoint+'/retailsetup/codesetup/quicksalecategories' }
        },
        {
          path: 'quicksalecategories',
          component: RetailQuickSaleCategoriesComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.QuickSaleCategories, redirectTo: hookpoint+'/retailsetup/codesetup/vatconfiguration' }
        },
        {
          path: 'vatconfiguration',
          component: VatComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.VATConfiguration, redirectTo: hookpoint+'/retailsetup/codesetup/retailfeatureconfiguration' }
        },
        {
          path: 'retailfeatureconfiguration',
          component: PaymentFeaturesConfigComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.RetailFeatureConfiguration, redirectTo: hookpoint+'/retailsetup/codesetup/creditcards' }
        },
        {
          path: 'creditcards',
          component: CreditCardsComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.CreditCards, redirectTo: hookpoint+'/retailsetup/codesetup/giftcards' }
        },
        {
          path: 'giftcards',
          component: GiftCardsComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.GiftCards, redirectTo: hookpoint+'/retailsetup/codesetup/vendortypes' }
        },
        {
          path: 'taxexemptcategory',
          component: TaxExemptCategoryComponent,
          canActivate: [RouteGuardService],
          data: { redirectTo: '' }
        },
        {
          path: 'vendortypes',
          component: RetailVendorTypesComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.VendorType, redirectTo: hookpoint+'/retailsetup/codesetup/posttypemapping' }
        },
        {
          path: 'posttypemapping',
          component: PostTypeMappingComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [CanDeactivateGuard],
          data: { breakPointNumber: RetailBreakPoint.PostTypeMapping, redirectTo: hookpoint+'/retailsetup/codesetup/generalLedgermapping' }
        },
        {
          path: 'generalledgermapping',
          component: GeneralLedgerMappingComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [CanDeactivateGuard],
          data: { breakPointNumber: RetailBreakPoint.GeneralLedgerMapping, redirectTo: hookpoint+'/retailsetup/codesetup/discountreason' }
        },
        {
          path: 'externalposttypemapping',
          component: ExternalPostTypeMappingComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [CanDeactivateGuard],
          data: { redirectTo: hookpoint+'/interfaces/pmsIntegration/pmsIntegrationSetup' }
        },
        {
          path: 'discountreason',
          component: RetailDiscountReasonComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.DiscountReason, redirectTo: hookpoint+'/retailsetup/quicksalesetup' }
        },
        {
          path: 'machinename',
          component: RetailMachineNameComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.MachineNames }
        },
        {
          path: 'memberAccrualMapping',
          component: MemberAccrualMappingComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.MemberAccrualMapping, redirectTo: hookpoint+'/retailsetup/codesetup/resortfinanceposttypemapping' }
        },
        {
          path: 'resortfinanceposttypemapping',
          component: ResortFinancePostTypeMappingComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [CanDeactivateGuard],
          data: { breakPointNumber: RetailBreakPoint.ResortFinancePostTypeMapping, redirectTo: hookpoint+'/retailsetup/codesetup/customfee' }
        },
        {
          path: 'customfee',
          component: CustomFeeComponent,
          canActivate: [RouteGuardService],
          canDeactivate: [CanDeactivateGuard],
          data: { breakPointNumber: RetailBreakPoint.CustomFee, redirectTo: hookpoint+'/retailsetup/retailsetup' }
        },
        {
          path: 'tierLevel',
          component: RetailTierLevelComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.DiscountType, redirectTo: hookpoint+'/retailsetup/retailsetup' }
        },
        {
          path: 'nonintegratedcreditcards',
          component: NonIntegratedCreditCardComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.NonIntegratedCreditCards, redirectTo: hookpoint+'/retailsetup/codesetup/retailsetup' }
        },
        {
          path: 'viptype',
          component: VipTypeComponent,
          canActivate: [RouteGuardService],
          data: { breakPointNumber: RetailBreakPoint.VipType, redirectTo: hookpoint+'/retailsetup/retailsetup' }
        }
      ]
    },
    {
      path: 'retailsetup',
      component: RetailChildSetupComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: RetailBreakPoint.ItemSetup, redirectTo: hookpoint + '/retailsetup/codesetup',pathMatch: 'full' },
      
    },
    {
      path: 'quicksalesetup',
      component: RetailQuickSaleComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: RetailBreakPoint.QuickSaleKeys, redirectTo: hookpoint+'/retailsetup/commissionsetup' }
    },
    {
      path: 'commissionsetup',
      component: RetailCommissionTemplateComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: RetailBreakPoint.SplitCommissionTemplateSetup, redirectTo: hookpoint+'/retailsetup/discountconfiguration' }
    },
    {
      path: 'discountconfiguration',
      component: RetailDiscountConfigurationComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: RetailBreakPoint.DiscountConfiguration, redirectTo: hookpoint+'/retailsetup/categorylinking' }
    },
    {
      path: 'categorylinking',
      component: CategoryLinkingComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: RetailBreakPoint.CategoryLinking, redirectTo: hookpoint+'/retailsetup/vendorsetup' }
    },
    {
      path: 'vendorsetup',
      component: RetailVendorSetupComponent,
      canActivate: [RouteGuardService],
      data: { breakPointNumber: RetailBreakPoint.VendorSetup, redirectTo: hookpoint+'/retailsetup/paymentmanager' }
    },
    {
      path:'paymentmanager',
      loadChildren: () => import('src/app/retail/payment-manager/payment-manager.module').then(m => m.PaymentManagerModule) ,
      data: { redirectTo: '', hasChild: true }
    },
    {
      path:'eatecintegration',
      loadChildren: () => import('src/app/retail/eatec-integration/eatec-integration.module').then(m => m.EatecItegrationModule) ,
      data: { redirectTo: '', hasChild: true }
    },
    {
      path: 'lettersetup',
      component: LetterSetupComponent,
      // canActivate: [RouteGuardService],
      // data: { breakPointNumber: 0, redirectTo: hookpoint+'/lettersetup', hasChild: true },
      children: [
        { path: '', redirectTo: 'retailsalechit', pathMatch: 'full' },
        {
          path: 'retailsalechit',
          component: SaleChitComponent,
          // canActivate: [RouteGuardService],
         //  data: { breakPointNumber: RetailBreakPoint.RETAILSALECHIT, redirectTo: hookpoint+'/lettersetupclubagreement' }

        },
        {
          path: 'clubagreement',
          component: ClubAgreementComponent,
         //  canActivate: [RouteGuardService],
         //  data: { breakPointNumber: RetailBreakPoint.CLUBAGREEMENT, redirectTo: hookpoint+'/lettersetupcartagreement' }
        },
        { path: 'cartagreement',
          component: CartAgreementComponent ,
         //  canActivate: [RouteGuardService],
         //  data: { breakPointNumber: RetailBreakPoint.CARTAGREEMENT, redirectTo: hookpoint+'/lettersetupshoeagreement' }

        },
        { path: 'shoeagreement',
          component: ShoeAgreementComponent ,
          // canActivate: [RouteGuardService],
          // data: { breakPointNumber: RetailBreakPoint.SHOEAGREEMENT, redirectTo: '' }

        }
      ]
    }
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RetailroutingModule { }
