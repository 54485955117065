import { GetTotalCount } from '../grid.interface';

export const getTotalCount: GetTotalCount = (Response: any): number => {
    try {
        if (Response && Response.hasOwnProperty('@odata.count')) {
            return Response['@odata.count'];
        } else if (Response && Response.hasOwnProperty('count')) {
            return Response['count'];
        } else {
            return 0;
        }
    } catch (error) {
        console.error('Error occurred in getTotalCount');
    }
};