import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { UI } from 'src/app/common/Models/property-information.model';
import { CustomizableDashboardDataService } from 'src/app/common/dataservices/authentication/customizable-dashbard.data.service';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Injectable()
export class HomeDashboardConfigurationBusiness {
    captions: any
    constructor(private golfLocalization: SpaLocalization, private customizableDashboardService: CustomizableDashboardDataService) {
        this.captions = this.golfLocalization.captions;
    }


    public shouldActionButtonsDisable(form: UntypedFormGroup): UI.ActionButtonState {
        return {
            isSaveDisable: !(form.dirty && form.valid),
            isCancelDisable: !(form.dirty)
        } as UI.ActionButtonState;
    }
}