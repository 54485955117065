import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { UserMachineConfigurationService } from '../../../core/services/user-machine-configuration.service';
import * as myGlobals from '../../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { SubPropertyModel, ZebraPrinters, NextId } from '../../../retail/retail.modals';
import { HttpMethod, HttpServiceCall } from '../../../shared/service/http-call.service';
import { UserSessionConfiguration } from "../../../core/core.model";
import { HttpResponseStatus } from '../../../retail/shared/service/payment/payment-business.model';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { HandleResponse, PaymentMethods, HandleRequest } from '../../../shared/business/shared.modals';
import { ZebraPrintService } from '../../../retail/retail-print/zebra-print.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { StoreTerminal } from 'src/app/retail/shared/business/shared.modals';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { DefaultUserConfigurationTenant, DefaultUserConfigurationTenantModel, MachineName } from 'src/app/common/shared/shared.modal';
import { MachineNameDataService } from 'src/app/common/dataservices/machinename.data.service';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { cloneDeep } from 'lodash';
import { DEFAULTCONFIGURATION } from 'src/app/common/constants';
import { RetailSharedVariableService } from '../../../retail/shared/retail.shared.variable.service';
import { PMAgentServiceProvider } from 'src/app/retail/payment/PMAgentServiceProvider';

@Component({
  selector: 'app-user-machine-configuration',
  templateUrl: './user-machine-configuration.component.html',
  styleUrls: ['./user-machine-configuration.component.scss'],
  providers: [PMAgentServiceProvider],
  encapsulation: ViewEncapsulation.None
})
export class UserMachineConfigurationComponent implements OnInit {

  userSessionConfigForm: UntypedFormGroup;
  userSessionConfiguration = new UserSessionConfiguration();

  captions: any = this.localization.captions.utilities;
  outlets: Array<any>;
  machines: Array<any>;
  outletTerminals: StoreTerminal[] = [{ terminalId: "", terminalName: '' } as StoreTerminal];
  courses: Array<any>;
  paymentDevices: Array<any>;
  deviceNames: Array<any>;
  printers: Array<any>;
  selectedOutlet: number;
  selectedCourse: number;
  selectedDeviceName: string;
  selectedPaymentDevice: string;
  isIdtechSred = false;
  ZebraPrinters: ZebraPrinters;
  smallStickersPrinter: string;
  hangingTicketsPrinter: string;

  enableSave: boolean;
  enablePaymentDeviceSelect: boolean = false;
  enablePrinterSelect: boolean = false;
  userMachineConfigSubscription: ISubscription;
  IsViewOnly: boolean;
  userOperationType: myGlobals.OperationType = myGlobals.OperationType.None;
  useRetailInterface: boolean;
  testMode: boolean = false;
  showPaymentDevice: boolean;
  defaultMachineOptions: DropdownOptions[] = [];
  enableMachineTransaction: boolean = false;
  TenantDefaultUserConfiguration: DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>
  floatLabel: string;

  constructor(
    private fb: UntypedFormBuilder,
    public machineNameDataService: MachineNameDataService,
    public localization: SpaLocalization,
    public PropertyInfo: SpaPropertyInformation,
    private userMachineConfigurationService: UserMachineConfigurationService,
    private featureFlagInfo: RetailFeatureFlagInformationService,
    private http: HttpServiceCall,
    private utils: SpaUtilities,
    private retailUtils: RetailUtilities,
    private payAgentService: PMAgentServiceProvider,
    private zebra: ZebraPrintService,
    private retailSharedService: RetailSharedVariableService) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() {
    this.userSessionConfigForm = this.fb.group({
      defaultOutletId: '',
      defaultTerminalId: 0,
      defaultMachineId: 0,
      defaultPaymentId: 0,
      defaultCourseId: '',
      defaultPaymentDevice: '',
      defaultDeviceName: '',
      isIdtechSred: false,
      smallStickersPrinter: '',
      hangingTicketsPrinter: '',
      userMachine: ''
    });
    this.useRetailInterface = this.PropertyInfo.UseRetailInterface;
    this.userMachineConfigSubscription = this.userSessionConfigForm.valueChanges.subscribe(() => {
      if (this.userSessionConfigForm.dirty) {
        this.enableSave = true;
      }
    });

    await this.onPageLoad();
    if (this.retailUtils.GetEnablemachineTransaction() == 'true') {
      this.enableMachineTransaction = true;
    }
    this.GetMachineNames();
  }

  async onPageLoad() {
    this.getUserSessionConfiguration(this.localization.GetPropertyInfo('UserId'))
      .catch(err => console.error(err));

    this.GetPropertyOutletsAsync()
      .catch(err => console.error(err));

  }

  // User Session Configuration
  private async getUserSessionConfiguration(userId) {
    let userSessionConfiguration: UserSessionConfiguration = await this.userMachineConfigurationService.getUserSessionConfiguration(userId);
    if (userSessionConfiguration.defaultOutletId) {
      await this.GetStoreTerminals(userSessionConfiguration.defaultOutletId);
    }
    if (userSessionConfiguration.id === 0) {
      this.userOperationType = myGlobals.OperationType.Create;
    } else {
      this.userOperationType = myGlobals.OperationType.Edit;
    }
    let defaultMachineId = await this.GetTenantDefaultUserConfiguration(userId);
    userSessionConfiguration.defaultMachineId = defaultMachineId.configValue ? defaultMachineId.configValue.defaultMachineId : defaultMachineId.defaultValue.defaultMachineId;
    this.displayUserSessionConfiguration(userSessionConfiguration);
  }

  displayUserSessionConfiguration(userSessionConfiguration: UserSessionConfiguration) {
    if (this.userSessionConfigForm) {
      this.userSessionConfigForm.reset();
    }
    this.userSessionConfiguration = userSessionConfiguration;

    if (!this.useRetailInterface) {
      this.getDeviceNamesAsync();
      this.GetPaymentDevicesAsync();
      this.getPrinterNamesAsync();
      this.GetPaymentMethodsAsync();
    }

    // Update the data on the form
    this.userSessionConfigForm.patchValue({
      defaultOutletId: this.userSessionConfiguration.defaultOutletId,
      defaultTerminalId: this.userSessionConfiguration.defaultTerminalId,
      defaultMachineId: this.userSessionConfiguration.defaultMachineId,
      defaultCourseId: this.userSessionConfiguration.defaultCourseId,
      defaultPaymentDevice: this.userSessionConfiguration.defaultPaymentDevice,
      defaultDeviceName: this.userSessionConfiguration.defaultDeviceName,
      isIdtechSred: this.userSessionConfiguration.isIdtechSred,
      smallStickersPrinter: this.userSessionConfiguration.smallStickersPrinter,
      hangingTicketsPrinter: this.userSessionConfiguration.hangingTicketsPrinter,
      defaultPaymentId: this.userSessionConfiguration.defaultPaymentId
    });

  }

  private async createUserSessionConfiguration(body: UserSessionConfiguration): Promise<NextId> {
    try {
      this.UpdateTenantDefaultUserConfiguration(body);
      return await this.userMachineConfigurationService.createUserSessionConfiguration(body);
    } catch (e) {
      this.utils.exceptionHandle(e);
    }
  }

  private async updateUserSessionConfiguration(body: UserSessionConfiguration): Promise<UserSessionConfiguration> {
    try {
      this.UpdateTenantDefaultUserConfiguration(body);
      return await this.userMachineConfigurationService.updateUserSessionConfiguration(body);
    } catch (e) {
      this.utils.exceptionHandle(e);
    }
  }

  // Outlets
  private async GetPropertyOutletsAsync() {
    await this.GetOutletsByPropertyId();
  }

  async GetOutletsByPropertyId() {
    let result = await this.http.CallApiAsync<SubPropertyModel[]>({
      host: myGlobals.Host.retailManagement,
      callDesc: "GetOutletsByPropertyAndProduct",
      method: HttpMethod.Get,
      uriParams: { propertyId: Number(this.localization.GetPropertyInfo('PropertyId')), productId: Number(this.localization.GetPropertyInfo('ProductId')) }
    });
    let outlets: SubPropertyModel[] = result.result ? result.result : [];
    outlets = outlets.filter(x => x.isActive);
    this.outlets = outlets.map(x => { return { id: x.subPropertyID, description: x.subPropertyName } });
    this.outlets.unshift({ id: 0, description: '' });
  }

  async GetStoreTerminals(selectedOutletId: number) {
    this.outletTerminals = [];
    if (selectedOutletId) {
      let terminals = await this.http.CallApiAsync<StoreTerminal[]>({
        host: myGlobals.Host.retailManagement,
        callDesc: "GetStoreTerminal",
        method: HttpMethod.Get,
        uriParams: { outletId: selectedOutletId }
      });
      this.outletTerminals = terminals.result && terminals.result.length > 0 ? terminals.result : [];
      this.outletTerminals.forEach(o => o.terminalId = Number(o.terminalId));
      this.outletTerminals.unshift({ terminalId: 0, terminalName: '' } as StoreTerminal);
    }
  }

  async outletChange(arg) {
    this.userSessionConfigForm.controls['defaultTerminalId'].setValue(0);
    if (this.PropertyInfo.UseRetailInterface) {
      await this.GetStoreTerminals(arg.value);
    }
  }

  // Courses
  private async getCoursesAsync() {
    await this.getCourses();
  }

  // Test method for use until Golf is ready
  async getCourses(): Promise<any> {
    return new Promise((resolve, reject) => {
      let courses = [
        { id: 0, description: '' },
        { id: 1, description: 'course A' },
        { id: 2, description: 'course B' },
        { id: 3, description: 'course C' }];
      if (courses) {
        this.courses = courses;
        resolve(this.courses);
      }
      else {
        reject('error');
      }
    })
  }

  // Payment Devices
  private async GetPaymentDevicesAsync() {
    await this.getPaymentDevices();
  }
  paymentMethods: any[] = [];
  private async GetPaymentMethodsAsync() {
    var _paymentMethods = await this.userMachineConfigurationService.GetPaymentMethods();
    if (Array.isArray(_paymentMethods) && _paymentMethods.length) {
      this.paymentMethods = _paymentMethods;
      const PaymentsToBeSkipped = [PaymentMethods.IDTECH, PaymentMethods.V1GiftCardIdTech, PaymentMethods.ExternalGiftCardIdTech, PaymentMethods.AgilysysGiftCardIdTech, PaymentMethods.PendingSettlement];
      this.paymentMethods = this.paymentMethods.filter(x => !PaymentsToBeSkipped.includes(x.paymentTypeId) && x.isActive);

      this.paymentMethods.forEach((method) => {
        if (this.localization.captions.shop.paymentMethods[method.paymentTypeId]) {
          method.paymentMethod = this.localization.captions.shop.paymentMethods[method.paymentTypeId];
        } else {
          method.paymentMethod = method.paymentMethod;
        }
      });

      let giftcardMethod = this.paymentMethods.find(x => x.paymentTypeId == PaymentMethods.ExternalGiftCard);
      if (giftcardMethod && this.featureFlagInfo.GatewayType) {
        const paymentMethod = this.localization.replacePlaceholders(this.localization.captions.shop.paymentMethods[giftcardMethod.paymentTypeId], ["Third Party"], [this.featureFlagInfo.GatewayType]);
        giftcardMethod.paymentMethod = paymentMethod;
      }
    }
  }

  async getPaymentDevices(): Promise<any> {
    return new Promise((resolve, reject) => {
      let paymentDevices = [
        { id: '', description: '' },
        { id: 'rguestpay', description: this.localization.captions.utilities.RGuestPay },
        { id: 'idtech', description: this.localization.captions.utilities.Idtech }];
      if (paymentDevices) {
        this.paymentDevices = paymentDevices;
        resolve(this.paymentDevices);
      }
      else {
        reject('error');
      }
    })
  }

  // Device Names
  private async getDeviceNamesAsync(): Promise<void> {
    await this.GetHandles();
  }

  private async GetHandles() {
    let body: HandleRequest = {
      tenderId: PaymentMethods.CreditCard.toString()
    };

    //Use default outlet or First oulet
    let outletId: number = (this.userSessionConfiguration && this.userSessionConfiguration.defaultOutletId > 0) ? this.userSessionConfiguration.defaultOutletId : 0;
    let handleResponse: Promise<HandleResponse> = this.payAgentService.PaymentProcessor.GetHandles(body, outletId);

    handleResponse.then(response => {
      if (response.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
        this.deviceNames = response.paymentHandle.map(x => x.name);
        this.deviceNames = this.deviceNames.map(x => { return { id: x, description: x } });
        this.deviceNames.unshift({ id: '', description: '' });
        this.enablePaymentDeviceSelect = true;
      } else {
        this.deviceNames = [];
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.NoPaymentDevicesFound);
        this.enablePaymentDeviceSelect = false;
        if (this.testMode) {
          this.getDeviceNames(); // call test method
          this.enablePaymentDeviceSelect = true;
        }
      }
      this.deviceChanged({ value: this.userSessionConfiguration.defaultPaymentDevice });
    }).catch(error => {
      this.deviceNames = [];
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.shop.NoPaymentDevicesFound);
      this.enablePaymentDeviceSelect = false;
      if (this.testMode) {
        this.getDeviceNames(); // call test method
        this.enablePaymentDeviceSelect = true;
      }
      this.deviceChanged({ value: this.userSessionConfiguration.defaultPaymentDevice });
    });
  }

  // Test method for use when no Pay Agent available
  async getDeviceNames(): Promise<any> {
    return new Promise((resolve, reject) => {
      let deviceNames = [
        { id: '', description: '' },
        { id: 'device1', description: 'Device 1' },
        { id: 'device2', description: 'Device 2' },
        { id: 'device3', description: 'Device 3' }];
      if (deviceNames) {
        this.deviceNames = deviceNames;
        resolve(this.deviceNames);
      }
      else {
        reject('error');
      }
    })
  }

  // Printer Names
  private async getPrinterNamesAsync(): Promise<void> {
    this.printers = [];
    this.ZebraPrinters = await this.zebra.getLocalDevices();
    if (this.ZebraPrinters !== undefined && this.ZebraPrinters.printer.length > 0) {
      for (var p of this.ZebraPrinters.printer) {
        let description: string = p.name;
        if (p.name.substring(0, 3).toLowerCase() == "18j") {
          description = `Zebra ZT410: ${p.name}`;
        }
        if (p.name.substring(0, 3).toLowerCase() == "28j") {
          description = `Zebra GK420: ${p.name}`;
        }
        this.printers.push(
          {
            id: p.name,
            description: description
          }
        )
      }
      this.printers.unshift({ id: '', description: '' });
      console.dir(this.printers);
      this.enablePrinterSelect = true;

    } else {
      this.enablePrinterSelect = false;
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.utilities.NoPrintersFound);
      if (this.testMode) {
        await this.getPrinterNames(); // call test method
        this.enablePrinterSelect = true;
      }
    }
  }

  // Test method to use when no Zebra printers connected
  async getPrinterNames(): Promise<any> {
    return new Promise((resolve, reject) => {
      let printers = [
        { id: '', description: '' },
        { id: 'printer1', description: 'Printer 1' },
        { id: 'printer2', description: 'Printer 2' }
      ];
      if (printers) {
        this.printers = printers;
        resolve(this.printers);
      }
      else {
        reject('error');
      }
    })
  }

  async save() {
    const body = { ...this.userSessionConfiguration, ...this.userSessionConfigForm.value };
    switch (this.userOperationType) {
      case myGlobals.OperationType.Create:
        await this.createUserSessionConfiguration(body);
        this.updatesessionStorage(body);
        break;
      case myGlobals.OperationType.Edit:
        await this.updateUserSessionConfiguration(body);
        this.updatesessionStorage(body);
        break;
      default:
    }
    this.retailSharedService.SelectedOutletId = body?.defaultOutletId;
    this.afterSaveOrUpdate();
  }

  private updatesessionStorage(values: any): void {
    let userSessionConfigKey: string = 'userSessionConfigInfo';
    let userSessionConfigValues: string =
      ` Id=${values.id};
      UserId=${values.userId};
      DefaultOutletId=${values.defaultOutletId};
      DefaultTerminalId=${values.defaultTerminalId};
      DefaultMachineId=${values.defaultMachineId};
      DefaultCourseId=${values.defaultCourseId};
      DefaultPaymentDevice=${values.defaultPaymentDevice};
      DefaultDeviceName=${values.defaultDeviceName};
      IsIdtechSred=${values.isIdtechSred};
      HangingTicketsPrinter=${values.hangingTicketsPrinter};
      SmallStickersPrinter=${values.smallStickersPrinter};
      DefaultPaymentId=${values.defaultPaymentId};
`;
    sessionStorage.setItem(userSessionConfigKey, userSessionConfigValues);
  }

  afterSaveOrUpdate() {
    this.userOperationType = myGlobals.OperationType.Edit;
    this.enableSave = false;
  }

  cancel() {
    this.userSessionConfigForm.reset();
    this.getUserSessionConfiguration(this.localization.GetPropertyInfo('UserId'))
      .catch(err => console.error(err));
    this.enableSave = false;
  }

  ngOnDestroy() {
    if (this.userMachineConfigSubscription) {
      this.userMachineConfigSubscription.unsubscribe();
    }
  }

  deviceChanged(event) {
    if (event && event.value) {
      if (event.value === 'idtech') {
        this.userSessionConfigForm.controls.defaultDeviceName.setValue('');
        this.userSessionConfigForm.controls.isIdtechSred.setValue(false);
        this.showPaymentDevice = false;
      } else {
        this.showPaymentDevice = true;
      }
    }
  }

  async GetMachineNames() {
    this.defaultMachineOptions = [];
    const propertyId: number = Number(this.localization.GetPropertyInfo("PropertyId"));
    if (propertyId) {
      const machineNames = await this.machineNameDataService.GetMachineNames(propertyId);
      this.defaultMachineOptions = this.mapMachineOptions(machineNames);
    }
  }

  private mapMachineOptions(machineNames: MachineName[]): DropdownOptions[] {
    let userMachineNames = [] as DropdownOptions[];
    userMachineNames = machineNames.map(machineName => {
      return {
        id: machineName.id,
        value: machineName.id,
        viewValue: machineName.name
      } as DropdownOptions
    });
    userMachineNames.unshift({ id: 0, value: 0, viewValue: '' });
    return userMachineNames;
  }

  public UpdateTenantDefaultUserConfiguration(formvalue: UserSessionConfiguration) {
    let defaultUservalue: DefaultUserConfigurationTenantModel = { defaultMachineId: formvalue.defaultMachineId ? formvalue.defaultMachineId : 0 };
    let tenantDefaultUserConfiguration: any = cloneDeep(this.TenantDefaultUserConfiguration);
    tenantDefaultUserConfiguration.configValue = JSON.stringify(defaultUservalue);
    tenantDefaultUserConfiguration.defaultValue = JSON.stringify(tenantDefaultUserConfiguration.defaultValue);
    tenantDefaultUserConfiguration.userId = this.localization.GetPropertyInfo("UserId");
    return this.userMachineConfigurationService.UpdateTenantDefaultUserConfiguration(tenantDefaultUserConfiguration);
  }
  public async GetTenantDefaultUserConfiguration(userId): Promise<DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>> {
    let configurationName = DEFAULTCONFIGURATION;
    let propertyId = Number(this.localization.GetPropertyInfo("PropertyId"));
    let productId = Number(this.localization.GetPropertyInfo("ProductId"));
    let defaultvalue: DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel> = await this.userMachineConfigurationService.GetTenantDefaultUserConfiguration(configurationName, propertyId, productId, userId);
    this.TenantDefaultUserConfiguration = defaultvalue;
    return defaultvalue;
  }
}
