import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectindexof'
})
export class ObjectindexofPipe implements PipeTransform {
  transform(value: any, arrayData: any, data: any): any {
    if (!arrayData) { return -1; }
    if (arrayData && arrayData.length) {
      const numArray = arrayData.map(res => res.id);
      return numArray.indexOf(data.id);
    } else if (arrayData && arrayData.id === data.id) {
      return 1;
    } else  {
      return -1;
    }
  }
}
