import { Pipe, PipeTransform } from '@angular/core';
import { RetailLocalization } from '../common/localization/retail-localization';


@Pipe({
    name: 'showPaymentAmount'
})
export class ShowPaymentAmount implements PipeTransform {
    constructor(public localization: RetailLocalization) {

    }
    transform(value: any, totalAmount, exempttax, taxValue, allownegative = false) {
        let price: string = '';
        let totalValue = Number(totalAmount) + (exempttax ? 0 : taxValue);
        if (!allownegative) {
            if (totalValue < 0) {
                totalValue *= -1;
            }
        }
        price = this.localization.localizeCurrency(totalValue, true);
        return price;
    }
}

