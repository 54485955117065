import { Injectable } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SettingsService } from '../../settings.service';
import { HttpMethod } from 'src/app/shared/service/http-call.service';
import { Host } from 'src/app/shared/globalsContant';
import { SystemConfig } from 'src/app/shared/business/shared.modals';
import { SystemConfiguration } from 'src/app/shared/business/view-settings.modals';

@Injectable()
export class EmailConfiguarationSetupService {

  settingInfo: SystemConfiguration[] = [];
  constructor(private _Localization: SpaLocalization, public ss: SettingsService) { 


  }

  async GetEmailConfigurationSetting() {
    const apiResponse: SystemConfiguration[] = await this.ss.InvokeServiceCallAsync<SystemConfiguration[]>(
      "GetSettingByModule",
      Host.spaManagement,
      HttpMethod.Get,
      { module:  "EmailConfiguration" }
    );

    this.settingInfo = apiResponse;

    return this.getPatchJSON();
  }

  async UpdateEmailConfigurationSetting(formValues: any) {
    const body = this.GetSystemConfig(formValues);

   const apiResponse: SystemConfiguration[] = await this.ss.InvokeServiceCallAsync<SystemConfiguration[]>(
      "UpdateSetting",
      Host.spaManagement,
      HttpMethod.Put,
      undefined,
      body
    );

    this.settingInfo = apiResponse.filter(x => x.moduleId === 8);

    return this.getPatchJSON();
  }

  private getPatchJSON() {
      const _appJSON: any = {};
      const senderMailValue: any = [{ "toEmailId": "", "toEmailLabel": "" }];
      this.settingInfo.forEach(sc => {
        let value: any;
        let key: any;

        if (sc.switchType === "Boolean") {
          key = sc.switch;
          value = this.convertStringToBoolean(sc.value as string);
        } else if (sc.switch === "SENDER_MAIL_ADDRESS_TYPE") {
          key = "toEmail";
          senderMailValue[0].toEmailLabel = Number(sc.value);
          value = senderMailValue;
        } else if (sc.switch === "SENDER_MAIL_ADDRESS") {
          key = "toEmail";
          senderMailValue[0].toEmailId = sc.value;
          value = senderMailValue;
        } else if (sc.switch === "BCC_EMAILADDRESS") {
          key = sc.switch;
          value = this.separateBCCMailAddressToFormArray(sc.value);
        } else {
          key = sc.switch;
          value = sc.value;
        }

        _appJSON[key] = value;
      });
      return _appJSON;
  }

  private GetSystemConfig(formValues: any): SystemConfig[] {
    const SettingsData: SystemConfig[] = [];
    const allControls: string[] = Object.keys(formValues.controls);

    for (let i = 0; i < allControls.length; i++) {
      const _control: string = allControls[i];
      let value: any;

      if(_control === "toEmail") {
        const toEmailValue = formValues.controls[_control].value[0];
        const toEmailLabelValue = toEmailValue["toEmailLabel"];
        const toEmailLabelSettingInfo: SystemConfiguration = this.settingInfo.find(s => s.switch === "SENDER_MAIL_ADDRESS_TYPE");
        SettingsData.push(this.formSystemConfigData(toEmailLabelSettingInfo, "SENDER_MAIL_ADDRESS_TYPE", toEmailLabelValue));

        const toEmailIdValue = toEmailValue["toEmailId"].toString();
        const toEmailIdsettingInfo: SystemConfiguration = this.settingInfo.find(s => s.switch === "SENDER_MAIL_ADDRESS");
        SettingsData.push(this.formSystemConfigData(toEmailIdsettingInfo, "SENDER_MAIL_ADDRESS", toEmailIdValue));
      } else {
        const settingInfo: SystemConfiguration = this.settingInfo.find(s => s.switch === _control);
        value = formValues.controls[_control].value;
        if (_control ===  "BCC_EMAILADDRESS") {
          value = this.joinBCCMailAddres(value);
        }
        SettingsData.push(this.formSystemConfigData(settingInfo, _control, value));
      }
    }
    return SettingsData;
  }

  private convertStringToBoolean(value: string): boolean {
    return value === "true" ? true : false;
  }

  private separateBCCMailAddressToFormArray(value: any) {
    return value.split(",");
  }

  private formSystemConfigData(settingInfo: SystemConfiguration, control: string, value: any): SystemConfig {
    return {
      id: settingInfo.id,
      moduleId: settingInfo.moduleId,
      switch: control,
      value: value
    }; 
  }

  private joinBCCMailAddres(value: any): string {
    return value.filter(x => x.bccEmailId !== "").map(x=> x.bccEmailId).join(',');
  }
}
