import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AddNewTypeConfig, GridConfiguration, ItemQueryListConfiguaration, LookupConfiguration, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { QueryParams } from '../api-query-params.interface';
import { QueryConfig, QueryLookupConfig, TransQueryLookupConfig } from './query-list-config.interface';
import _ from 'lodash';
import { QueryTransaction } from 'src/app/eatecui/source/transaction/shared/interface/query-details';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { OdataGlobalEntity } from 'src/app/eatecui/source/core/models/odata-model';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { MasterInventoryManagement, SubFormConfigs } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { QueryDatas } from './master-query-tab-click.interface';

export const TransMasterQueryListLookup: TransQueryLookupConfig = async (addNewTypeConfigs: AddNewTypeConfig,
    transactionConfigurationData: TransactionConfiguration, httpService: HttpService, translateService: TranslateService,
    headerEntity: any, detailEntity: any, transactionService: TransactionService, selectedTransaction: string,
    OdataGlobalEntityModel: OdataGlobalEntity, masterManagementService: MasterManagementService,
    selectedId?: Array<any>, selectedQueryList?: Array<any>): Promise<any> => {
    try {
        const MasterModuleName: Array<any> = ['Recipes', 'Ingredients', 'Suppliers', 'Products'];
        const ModuleIndex = MasterModuleName.findIndex(x => x === selectedTransaction);
        let transactionJsonData = {} as TransactionConfiguration;
        if (ModuleIndex === -1) {
            // transactionJsonData = await transactionService.GetTransactionJsonData(selectedTransaction);
            transactionJsonData = { ...transactionConfigurationData };
        } else {
            const MasterJsonData: Array<MasterInventoryManagement> = masterManagementService.GetMasterJsonData(selectedTransaction, 2);
            transactionJsonData.Name = selectedTransaction;
            transactionJsonData.ViewTranscationConfig = {
                ViewHeaderOdataKey: 'Suppliers',
                HeaderConfiguration: null,
                ViewDetailOdataKey: null
            };
            let AddNewConfig: SubFormConfigs = MasterJsonData[0].NestedFormConfig.EditFormConfig.filter(x => x.hasOwnProperty('AddNewListConfig'))[0];
            if (selectedTransaction === 'Products') {
                AddNewConfig = {} as SubFormConfigs;
                AddNewConfig.QueryListMasterConfig = MasterJsonData[0].NestedFormConfig.EditFormConfig[0].
                    LinkedItems.QueryListMasterConfig;
                AddNewConfig.AddNewListConfig = MasterJsonData[0].NestedFormConfig.EditFormConfig[0].
                    LinkedItems.AddNewListConfig;
            }
            if (selectedTransaction === 'Recipes') {
                AddNewConfig = {} as SubFormConfigs;
                AddNewConfig.QueryListMasterConfig = MasterJsonData[0].AddItemList.QueryListMasterConfig;
                AddNewConfig.AddNewListConfig = MasterJsonData[0].AddItemList.AddNewListConfig;
            }
            let TypeId = 1;
            switch (AddNewConfig.QueryListMasterConfig.EntityName) {
                case 'IngredientBids':
                    TypeId = 6;
                    break;
                case 'SupplierBids':
                    TypeId = 1;
                    break;
                case 'ProductItemDetails':
                    TypeId = 1;
                    break;
                case 'RecipeIngredients':
                    TypeId = 2;
                    break;
            }
            transactionJsonData.QueryListTransactionConfig = AddNewConfig.QueryListMasterConfig;
            transactionJsonData.CreateTranscationConfig = {
                HeaderConfig: null,
                DetailsConfig: null,
                ItemsConfig: {
                    ItemTableCard: null,
                    KeyToEnablePost: null,
                    ItemList: {
                        AddNewListConfig: AddNewConfig.AddNewListConfig,
                        EnableAddNewItem: true,
                        EnableItemList: false,
                        ExpandItemList: false,
                        ItemGridConfig: null,
                    },
                    ItemQueryConfig: {
                        ItemQueryGridHeaderList: AddNewConfig.QueryListMasterConfig.ItemQueryGridHeaderList,
                        ItemQueryListConfig: []
                    }
                },
                TransactionFormConfig: {
                    FormFields: [],
                    DisplayName: '',
                    Key: '',
                    Name: '',
                    FormGroupData: {
                        'TypeId': TypeId
                    }
                }
            };
            transactionService.transactionConfigurationData = { ...transactionJsonData };
        }
        const getDetailOdataKey = transactionService.getOdataKey(transactionJsonData, 'ViewDetailOdataKey');
        const queryTransactionDetailEntity = masterManagementService.GetViewModelData(
            OdataGlobalEntityModel.OdataViewModelEntity, getDetailOdataKey);
        // eslint-disable-next-line max-len
        const transGroup = transactionService.transactionConfigurationData.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.AddNewType.filter((TypeConfig: AddNewTypeConfig) => TypeConfig.DefaultSelect)[0];
        // eslint-disable-next-line max-len
        let addNewTypeConfig: AddNewTypeConfig = (transGroup.hasOwnProperty('OptionGroup') && transGroup.OptionGroup && transGroup.OptionGroup.length > 0) ?
            transGroup.OptionGroup.filter(x => x.DefaultSelect === true)[0] : transGroup;
        addNewTypeConfig = (addNewTypeConfig.Name === addNewTypeConfigs.Name) ? addNewTypeConfig : addNewTypeConfigs;
        const jsondata = transactionService.transactionConfigurationData;
        const searchByKey = (jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds &&
            jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds.length > 0) ?
            jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryIds : selectedId;
        const searchListArray = (jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList &&
            jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList.length > 0) ?
            jsondata.CreateTranscationConfig.ItemsConfig.ItemList.AddNewListConfig.SelectedQueryList : selectedQueryList;
        let endPointUrl = '';
        endPointUrl = getEnpointUrl(addNewTypeConfig.EndPointUrl);
        const IngredientsHeadEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity, 'Ingredients');
        const IndredientsViewEntity = masterManagementService.GetViewModelData(OdataGlobalEntityModel.OdataViewModelEntity, 'Ingredients');
        const lookUpData = {} as LookupConfiguration;
        lookUpData.Id = 0;
        lookUpData.TransType = transactionJsonData.TransactionType;
        if (jsondata.CreateTranscationConfig.TransactionFormConfig) {
            lookUpData.FromLocationId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['FromLocId'];
            lookUpData.ToLocationId = jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['ToLocationId'];
        }
        if (addNewTypeConfig.Name === 'Class') {
            lookUpData.ClassIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Type') {
            lookUpData.TypeIds = searchByKey;
        } else if (addNewTypeConfig.Name === 'Packages') {
            lookUpData.LoadItemsfromPackages = true
            if (jsondata.Name !== 'Recipes') {
                lookUpData.PackageIds = searchByKey;
            } else {
                // if (searchByKey && searchByKey.length > 0) {
                //     const val = [];
                //     searchByKey.forEach(x => {
                //         val.push(x.Id);
                //     });
                //     lookUpData.PackageIds = val;
                // }
                lookUpData.PackageIds = searchByKey;
            }
        }
        if (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup') {
            lookUpData.itemType = addNewTypeConfig.DefaultTypeId ? addNewTypeConfig.DefaultTypeId : addNewTypeConfig.TypeId;
        }
        const ListType = (addNewTypeConfig.hasOwnProperty('DefaultTypeId') && addNewTypeConfig.DefaultTypeId !== '') ?
            addNewTypeConfig.DefaultTypeId : addNewTypeConfig.TypeId;
        let ListName = translateService.instant('Ingredients.Ingredients');
        if ( selectedTransaction === 'Recipes' && ListType === 1) {
            lookUpData.DivisionIds = [1];
        }
        if (selectedTransaction === 'Packages' || selectedTransaction === 'packagelimitation') {
            lookUpData.FetchAll = false;
            lookUpData.Skip = 0;
            lookUpData.IsQuery = true;
            lookUpData.Take = 100;
            lookUpData.flgSearch = true;
        }
        lookUpData.searchText = '';
        switch (ListType) {
            case 1: ListName = translateService.instant('Ingredients.Ingredients'); break;
            case 2: ListName = translateService.instant('Ingredients.Receipe'); break;
            case 5: ListName = translateService.instant('Customer.Customer'); break;
            case 6: ListName = translateService.instant('Suppliers.Supplier'); break;
            case 7: ListName = translateService.instant('Products.Products'); break;
        }
        lookUpData.IsFromMasterLimit = (transactionJsonData && transactionJsonData.Name === 'PackageLimitation') ?
        true : false;
        // ((transactionJsonData.Name === 'Packages' || transactionJsonData.Name === 'PackageLimitation') &&
        // jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 1 &&
        // jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] !== 7)
        // || 
        if ((transactionJsonData.Name === 'Ingredients' || transactionJsonData.Name === 'PlanHead') && addNewTypeConfig.EndPointUrl !== 'GetSuppliers') {
            if (addNewTypeConfig.Name === 'Packages') {
                const Data = [];
                searchByKey.forEach(key => {
                    if (key.PackLists && key.PackLists.length > 0) {
                        key.PackLists.forEach(element => {
                            Data.push(element.Item);
                        });
                    } else if (transactionJsonData.Name === 'PlanHead') {
                        Data.push(key);
                    }
                });

                const seleId1 = `${addNewTypeConfig.QueryString} (` + `${Data.join()})`;
                const GetDataFilter1 = encodeURIComponent(`${seleId1}`).replace(/[!'()*]/g, function (c) {
                    return '%' + c.charCodeAt(0).toString(16);
                });
                const params = {} as QueryParams;
                params.filterQuery = GetDataFilter1;
                if (addNewTypeConfig.QueryExpand) {
                    endPointUrl = endPointUrl + `?$filter=${params.filterQuery}&$expand=${addNewTypeConfig.QueryExpand}`;
                } else {
                    endPointUrl = endPointUrl + `?$filter=${params.filterQuery}`;
                }
            } else {
                const seleId = `${addNewTypeConfig.QueryString} (` + `${searchByKey.join()})`;
                endPointUrl = endPointUrl + `?$filter=${seleId}`;
                if (addNewTypeConfig.QueryExpand) {
                    endPointUrl = endPointUrl + `&$expand=${addNewTypeConfig.QueryExpand}`;
                }
            }
            const x = await httpService.GethttpMethod(endPointUrl).toPromise();
            const responseData = x['value'];
            if (addNewTypeConfig.EndPointUrl === 'Recipes' || addNewTypeConfig.EndPointUrl === 'GetRecipes') {
                responseData.forEach(data => {
                   data['TypeName'] = (data.RcpTypeId !== undefined && data.RcpTypeId !== null)  ? data.RcpTypeId.Name : null;
                   data['ClassName'] = (data.RcpClassId !== undefined && data.RcpClassId !== null) ? data.RcpClassId.Name : null;
                });
            } else if (addNewTypeConfig.EndPointUrl === 'Customer' || addNewTypeConfig.EndPointUrl === 'GetCustomers') {
                responseData.forEach(data => {
                   data['TypeName'] = (data.CustomerTypeId !== undefined && data.CustomerTypeId !== null) ? data.CustomerTypeId.Name : null;
                   data['ClassName'] = (data.CustomerClassId !== undefined && data.CustomerClassId !== null) ? 
                   data.CustomerClassId.Name : null;
                });
            } else if (addNewTypeConfig.EndPointUrl === 'Suppliers' || addNewTypeConfig.EndPointUrl === 'GetSuppliers') {
                responseData.forEach(data => {
                   data['TypeName'] = (data.SupplierTypes !== undefined && data.SupplierTypes !== null) ? data.SupplierTypes.Name : null;
                   data['ClassName'] = (data.SupplierClass !== undefined && data.SupplierClass !== null) ? data.SupplierClass.Name : null;
                });
            } else if (addNewTypeConfig.EndPointUrl === 'Products') {
                responseData.forEach(data => {
                   data['TypeName'] = (data.ProductsTypeId !== undefined && data.ProductsTypeId !== null) ? data.ProductsTypeId.Name : null;
                   data['ClassName'] = (data.ProductsClassId !== undefined && data.ProductsClassId !== null) ? 
                   data.ProductsClassId.Name : null;
                });
            }
            let UrlName = addNewTypeConfig.Url === 'CustomerTypes' ? 'CustomerTypeId' : addNewTypeConfig.Url;
            if (addNewTypeConfig.Url === 'Classes') {
                UrlName = (jsondata.CreateTranscationConfig.TransactionFormConfig &&
                    jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] === 7)
                    || jsondata.OdataKey === 'PlanHead' ? 'ProductsClassId'
                    : 'SupplierClass';
            } else if (addNewTypeConfig.Url === 'ProductTypes') {
                UrlName = 'ProductsTypeId';
            }

            if ((addNewTypeConfig.EndPointUrl === 'Recipes' || addNewTypeConfig.EndPointUrl === 'GetRecipes') && addNewTypeConfig.Url === 'Classes') {
                UrlName = 'RcpClassId';
            }
            if ((addNewTypeConfig.EndPointUrl === 'Recipes' || addNewTypeConfig.EndPointUrl === 'GetRecipes') && addNewTypeConfig.Url === 'RecipeTypes') {
                UrlName = 'RcpTypeId';
            }
            if (jsondata.CreateTranscationConfig.TransactionFormConfig) {
                responseData.forEach(element => {
                    if (jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] === 5) {
                        element['IngredientTypes'] = element['CustomerTypeId'] ? element['CustomerTypeId'].Name : '';
                    }
                    if (jsondata.CreateTranscationConfig.TransactionFormConfig.FormGroupData['TypeId'] === 6) {
                        element['Classes'] = element['SupplierClass'] ? element['SupplierClass'].Name : '';
                        element['IngredientTypes'] = element['SupplierTypes'] ? element['SupplierTypes'].Name : '';
                    }
                });
            }
            let groupedData = [];
            if (addNewTypeConfig.Name === 'Packages') {
                searchByKey.forEach(key => {
                    const PackageId = key.PackLists.map(y => {
                        return y.Item;
                    });
                    const ItemList = responseData.filter(value => PackageId.includes(value.Id));
                    groupedData.push({
                        Id: key.Id,
                        Name: key.Name,
                        ItemList: ItemList
                    });
                });
            } else {
                groupedData = _(responseData)
                    .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                    .map((ItemList: any, Id: any) => ({
                        Id: parseInt(Id, 10),
                        ItemList,
                        Name: ItemList[0][UrlName]['Name']
                    }))
                    .value();
            }
            if (groupedData.length < searchByKey.length) {
                searchListArray.forEach(key => {
                    if (groupedData.findIndex(z => z.Id === key.Id) === -1) {
                        groupedData.push({
                            Id: key.Id,
                            ItemList: [],
                            Name: key.Name
                        });
                    }
                });
            }
            if (groupedData) {
                const itemHeaderList = [];
                const values = Object.keys(groupedData);
                groupedData.forEach((data: any, Index: number) => {
                    const itemQueryListConfiguaration = {} as ItemQueryListConfiguaration;
                    itemQueryListConfiguaration.DisplayName = data.Name;
                    itemQueryListConfiguaration.Enable = true;
                    itemQueryListConfiguaration.IconName = 'Na';
                    itemQueryListConfiguaration.Id = searchByKey[Index];
                    itemQueryListConfiguaration.ItemSelected = [];
                    itemQueryListConfiguaration.Name = data.Name;
                    itemQueryListConfiguaration.RoutingLinkUrl = addNewTypeConfig.Url;
                    itemQueryListConfiguaration.ShowInEllipsis = false;
                    itemQueryListConfiguaration.Type = 'grid';
                    itemQueryListConfiguaration.listAuthorized = true;
                    const GridDataRows = QueryTransaction.QueryIngredientsGridData(IngredientsHeadEntity,
                        IndredientsViewEntity, transactionJsonData, httpService, translateService, '', data.ItemList);
                    GridDataRows.forEach(element => {
                        element['GridId'] = Index + '_' + (element.Id ? element.Id : element.IngredientId);
                    });
                    itemQueryListConfiguaration.listGridData = {
                        GridName: 'Item List',
                        GridOdataKey: 'Requisition',
                        ShowActiveToggle: false,
                        ShowAddNew: false,
                        GridTitleType: {
                            Type: 'text',
                            TypeConfig: [
                                {
                                    Id: 1,
                                    Name: 'Item List',
                                    DisplayName: 'Item List'
                                }
                            ]

                        },
                        GridFilter: [],
                        GridSort: [],
                        GridSearch: [],
                        GridColumnHide: [],
                        GridData: GridDataRows,
                        GridHeaderDefination: QueryTransaction.QueryGridHeaders(queryTransactionDetailEntity,
                            queryTransactionDetailEntity, transactionJsonData, httpService, translateService, data.ItemList
                        ),
                        GridActions: {
                            GridTitle: `${ListName} List`,
                            TotalItems: x['Data'] !== undefined && addNewTypeConfig.EndPointUrl !== 'GetRecipeIngredientLookup' ?
                            x['Data']['ent']['TotalCount'] : x['TotalCount'] ? x['TotalCount'] : data.ItemList.length,
                            InfiniteScrollConfig: {
                                EnableInfiniteScroll: false,
                                TotalRecords: x['Data'] !== undefined && addNewTypeConfig.EndPointUrl !== 'GetRecipeIngredientLookup' ?
                                x['Data']['ent']['TotalCount'] : x['TotalCount'] ? x['TotalCount'] : data.ItemList.length
                            },
                            ResizeRowsConfig: {
                                EnableResizeRows: false
                            },
                            ShuffleColumnConfig: {
                                EnableColumnShuffle: false
                            }
                        },
                    },
                        itemHeaderList.push(itemQueryListConfiguaration);
                });
                transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig = [...itemHeaderList];
            }
        } else {
            const x = await httpService.PosthttpMethod(endPointUrl, lookUpData).toPromise();
            let resultSet: any;
            switch (addNewTypeConfig.Name) {
                case 'Packages':
                    if (addNewTypeConfig.EndPointUrl === 'ProductLookup') {
                        resultSet = x['PackageResults'];
                    } else {
                        resultSet = x['Data'] ?  x['Data']['ent']['PackageIds'] : x['ResultData'];
                    }
                    break;
                case 'Type':
                case 'Class':
                    if (addNewTypeConfig.EndPointUrl === 'ProductLookup') {
                        resultSet = x['PackageResults'];
                    } else {
                        resultSet = x['Data'] ? x['Data']['ent']['ResultData'] : x['ResultData'];
                    }
                    break;
                default:
                    resultSet = x['Data']['ent']['ResultData'];
                    break;
            }
            // let resultSet = addNewTypeConfig.Name === 'Packages' ? x['Data']['ent']['PackageIds']
            //     : x['Data']['ent']['ResultData'];
            //     if (
            //       addNewTypeConfig.EndPointUrl === "ProductLookup" &&
            //       (addNewTypeConfig.Name === "Class" ||
            //         addNewTypeConfig.Name === "Type")
            //     ) {
            //         resultSet = x["PackageResults"];
            //     }
            // if (resultSet.length > 0) {
            const itemHeaderList = [];
            let resultData = [];
            let values: any;
            const responseDatas = [];
            if (addNewTypeConfig.Name === 'Inventory' || (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup' &&
            addNewTypeConfig.Name !== 'Packages')) {
                resultSet.forEach((element: any) => {
                    const dataObject = _.mapKeys(element, function (v, k) {
                        return k.charAt(0).toUpperCase() + k.slice(1);
                    });
                    responseDatas.push(dataObject);
                });
                if (addNewTypeConfig.EndPointUrl === 'IngredientLookup') {
                    const PropertyName = addNewTypeConfig.Url === 'Classes' ? 'ClassName' : 'TypeName';
                    values = _(responseDatas)
                        .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                        .map((ItemList: any, Id: any) => ({
                            Id: parseInt(Id, 10),
                            ItemList,
                            Name: ItemList[0][PropertyName]
                        }))
                        .value();
                } else {
                    values = _(responseDatas)
                        .groupBy(o => o[addNewTypeConfig.PrimaryKey])
                        .map((ItemList: any, Id: any) => ({
                            Id: parseInt(Id, 10),
                            ItemList,
                            Name: ItemList[0][addNewTypeConfig.Url]['Name']
                        }))
                        .value();
                }
            } else {
                // if (addNewTypeConfig.EndPointUrl === 'ProductLookup') {
                //     resultData = x['PackageResults'];
                //     values = Object.keys(resultData);
                // } else {
                //     resultData = x['Data']['ent']['PackageResults'];
                //     values = Object.keys(resultData);
                // }
                let QueryName: string;
            switch (addNewTypeConfig.Name) {
                case 'Class': 
                    QueryName = 'ClassId';
                    break;
                case 'Type': 
                    QueryName = 'TypeId';
                    break;
                case 'Packages':
                    QueryName = 'PackageId';
                    break;
            }
            values = [];
            if (addNewTypeConfig.EndPointUrl === 'GetSuppliers' || addNewTypeConfig.EndPointUrl === 'GetRecipes' || addNewTypeConfig.EndPointUrl === 'GetCustomers') {
                searchListArray.forEach(searchInfo => {
                    let ItemList = [];
                    if(x['ResultData'] && x['ResultData'].length > 0) {
                        switch (addNewTypeConfig.Name) {                        
                            case 'Class':
                                ItemList = x['ResultData']?.filter(x => x.ClassId === searchInfo.Id);
                                break;
                            case 'Type':
                               ItemList = x['ResultData']?.filter(x => x.TypeId === searchInfo.Id);
                                break;
                            case 'Packages':
                                ItemList = x['ResultData']?.filter(x => x.Packages?.findIndex(y => y.Id === searchInfo.Id) !== -1);
                                break;
                        }
                    }                                        
                    const data = {} as QueryDatas;
                    data.Id = searchInfo.Id;
                    data.ItemList = ItemList;
                    data.Name = searchInfo.Name;
                    values.push(data);
                });
            } else {
                resultData = x['PackageResults'] ?
                x['PackageResults'] : x['Data'] !== undefined ? x['Data']['ent']['PackageResults'] :
                 (x['Data'] && x['Data']['ent']['ResultData'] ? x['Data']['ent']['ResultData'] : x['ResultData']);
                let loopvalues: any;
                if (resultData !== null && resultData !== undefined) {
                    loopvalues = Object.keys(resultData);
                } else {
                    resultData = [];
                    loopvalues = Object.keys(resultData);
                }
                values = [];
                loopvalues.forEach(pack => {
                    const data = {} as QueryDatas;
                    data.Id = resultData[pack] && resultData[pack].length > 0 ? resultData[pack][0][QueryName]
                    : searchListArray.filter(res => res.Name === pack)[0].Id;
                    data.ItemList = resultData[pack];
                    data.Name = pack;
                    values.push(data);
                });
            }
            }
            if (values.length < searchByKey.length) {
                if (addNewTypeConfig.Name !== 'Packages') {
                    searchListArray.forEach(key => {
                        if (values.findIndex(z => z.Id === key.Id) === -1) {
                            values.push({
                                Id: key.Id,
                                ItemList: [],
                                Name: key.Name
                            });
                        }
                    });
                } else {
                    searchListArray.forEach(key => {
                        if (values.findIndex(z => z === key.Name) === -1) {
                            values.push(key.Name);
                        }
                    });
                }
            }
            console.log('Grouped Data', values);
            values.forEach((data: any, Index: number) => {
                const itemQueryListConfiguaration = {} as ItemQueryListConfiguaration;
                itemQueryListConfiguaration.DisplayName = data.Name;
                itemQueryListConfiguaration.Enable = true;
                itemQueryListConfiguaration.IconName = 'Na';
                itemQueryListConfiguaration.Id = data['id'];
                itemQueryListConfiguaration.ItemSelected = [];
                itemQueryListConfiguaration.Name = data.Name;
                itemQueryListConfiguaration.RoutingLinkUrl = addNewTypeConfig.Url;
                itemQueryListConfiguaration.ShowInEllipsis = false;
                itemQueryListConfiguaration.Type = 'grid';
                itemQueryListConfiguaration.listAuthorized = true;
                const ItemList = data.ItemList;
                const GridDataRows = QueryTransaction.QueryIngredientsGridData(IngredientsHeadEntity, IndredientsViewEntity,
                    transactionJsonData, httpService, translateService, '', ItemList);
                const checkKey = addNewTypeConfig.EndPointUrl === 'ProductLookup' ? 'ProductId' : 'IngredientId';
                GridDataRows.forEach(element => {
                    element['GridId'] = Index + '_' + (element.Id ? element.Id : element[checkKey]);
                    if (addNewTypeConfig.EndPointUrl === 'GetRecipeIngredientLookup' && addNewTypeConfig.DefaultTypeId === 2) {
                        element['TypeName'] = element.RecipeTypes.Name;
                        element['ClassName'] = element.Classes;
                    }
                });
                itemQueryListConfiguaration.listGridData = {
                    GridName: 'Item List',
                    GridOdataKey: 'Requisition',
                    ShowActiveToggle: false,
                    ShowAddNew: false,
                    GridTitleType: {
                        Type: 'text',
                        TypeConfig: [
                            {
                                Id: 1,
                                Name: 'Item List',
                                DisplayName: 'Item List'
                            }
                        ]

                    },
                    GridFilter: [],
                    GridSort: [],
                    GridSearch: [],
                    GridColumnHide: [],
                    GridData: GridDataRows,
                    GridHeaderDefination: QueryTransaction.QueryGridHeaders(
                        queryTransactionDetailEntity, queryTransactionDetailEntity,
                        transactionJsonData, httpService, translateService, data
                    ),
                    GridActions: {
                        GridTitle: `${ListName} List`,
                        TotalItems: x['Data'] !== undefined && addNewTypeConfig.EndPointUrl !== 'GetRecipeIngredientLookup' ?
                        x['Data']['ent']['TotalCount'] : x['TotalCount'] ? x['TotalCount'] : ItemList.length,
                        InfiniteScrollConfig: {
                            EnableInfiniteScroll: false,
                            TotalRecords: x['Data'] !== undefined && addNewTypeConfig.EndPointUrl !== 'GetRecipeIngredientLookup' ?
                            x['Data']['ent']['TotalCount'] : x['TotalCount'] ? x['TotalCount'] : ItemList.length
                        },
                        ResizeRowsConfig: {
                            EnableResizeRows: false
                        },
                        ShuffleColumnConfig: {
                            EnableColumnShuffle: false
                        },
                        FilterConfig: {
                            EnableFilter: false
                        }
                    },

                };
                itemHeaderList.push(itemQueryListConfiguaration);
            });
            transactionJsonData.CreateTranscationConfig.ItemsConfig.ItemQueryConfig.ItemQueryListConfig = [...itemHeaderList];

        }
        return transactionJsonData;
    } catch (error) {
        console.error(error);
    }
};
function getEnpointUrl(endPoint: string): string {
    try {
    //   let urlEndPoint = endPoint;
    //   switch (endPoint) {
    //     case 'GetSuppliers':
    //         urlEndPoint = 'Suppliers';
    //         break;
    //     case 'GetCustomers':
    //         urlEndPoint = 'Customer';
    //         break;
    //     case 'GetRecipes':
    //         urlEndPoint = 'Recipes';
    //         break;
    //   }
      const returnUrl = `/inventory/api/${endPoint}`;
      return returnUrl;
    } catch (error) {
        console.log(error);
    }
}