import { Component, OnInit, Input, } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { localizationJSON } from 'src/app/common/localization/localization.model';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ContactType, PhoneTypes } from 'src/app/retail/shared/shared.modal';
import { AgContactFieldConfig, PhoneDetail } from '../shared.modal';
import { uniquephoneValidator } from './ag-duplicate-contact-directive';

@Component({
  selector: 'app-phone-number',
  templateUrl: './ag-phone-number.component.html',
  styleUrls: ['./ag-phone-number.component.scss']
})
export class AgPhoneNumberComponent implements OnInit {
  customClass: string;
  customClass1: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  typeControlName: string;
  automationId: string;
  typeClass: string;
  options: any[];
  arrayName: string;
  textMaskPhone: string;
  phoneTypes = PhoneTypes;
  captions: any;
  textMaskExtension: string;
  showSwitches: boolean;
  primarySwitchName: string;
  privateSwitchName: string;
  extensionClass: string;
  extensionPlaceHolder: string;
  duplicateError: string;
  extensionLength: number;
  countryCodeLength: number;
  countryCodePlaceHolder: string;
  allCaptions: localizationJSON;
  validatePhoneType: string;
  isEmpty: boolean;
  floatLabel: string;
  isDisabled: boolean;

  @Input('isEmtyreq')
  set emptyFunc(value) {
    this.isEmpty = value ? value : true;
  }

  @Input('inputs')
  set inputOptions(value: AgContactFieldConfig) {
    this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.err_invalidPhone;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.lbl_phone;
    this.extensionPlaceHolder = value.extnPlaceHolder ? value.extnPlaceHolder : this.captions.lbl_extension;
    this.countryCodePlaceHolder = this.captions.countryCode;
    this.controlName = value.formControlName ? value.formControlName : 'phoneNumber';
    this.typeControlName = value.typeControlName ? value.typeControlName : 'phoneType';
    this.customClass = '';
    this.customClass1 = 'w-20 mr-1';
    this.typeClass = value.typeClass ? value.typeClass : 'golf-form-control--xs';
    this.extensionClass = value.extensionClass ? value.extensionClass : 'golf-form-control--md';
    this.isDisabled = value.disabled ? value.disabled : false;
    this.showSwitches = value.showSwitches;
    if (this.showSwitches) {
      this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryPhone';
      this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionPhone';
    }

    this.contactForm = value.form;
    this.arrayName = value.formArrayName;
    this.automationId = value.automationId ? value.automationId : '';

    if (!this.arrayName) {
      if (!this.contactForm.get('countryCode')) {
        this.contactForm.addControl('countryCode', new UntypedFormControl());
      }
      this.contactForm.get('countryCode').disable();
      this.contactForm.get(this.controlName).disable();
    } else {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.controls.forEach((obj: UntypedFormGroup) => {
        if (!obj.get('countryCode')) {
          obj.addControl('countryCode', new UntypedFormControl());
        }
        if (!obj.value[this.typeControlName]) {
          obj.get('countryCode').disable();
          obj.get(this.controlName).disable();
        }
      });
    }
  }

  @Input('valuePatched')
  set ControlState(value: boolean) {
    if (!this.contactForm.get('countryCode')) {
      this.contactForm.addControl('countryCode', new UntypedFormControl());
    }
    const countrycodeControl = this.contactForm.get('countryCode');
    const contactControl = this.contactForm.get(this.controlName);
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.typeControlName).value) {
      contactControl.enable();
      countrycodeControl.enable();
      this.contactForm.controls['countryCode'].setValidators([Validators.required]);
      this.contactForm.controls[this.controlName].setValidators([Validators.required]);
    } else {
      contactControl.disable();
      countrycodeControl.disable();
    }
  }

  @Input('values')
  set SetFormArray(value: PhoneDetail[]) {

    console.log("values", value);
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.phoneFieldDisabler(this.isDisabled);
      this.ChangePrimaryToggle();
    }
  }

  @Input() isViewOnly: boolean = false;
  @Input() phoneRequired = false;

  constructor(private fb: UntypedFormBuilder, private localization: SpaLocalization) {
    this.captions = this.localization.captions.common;
    this.allCaptions = this.localization.captions;
    this.duplicateError = this.captions.duplicatePhone;
    this.validatePhoneType = this.localization.getError(-88);
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.textMaskPhone = this.maskPhoneNo();
    this.textMaskExtension = this.maskExtension();
    this.extensionLength = 5;
    this.countryCodeLength = 3;
    this.options = this.getPhoneOptions();
    this.isEmpty = true;
    this.ChangePrimaryToggle();
  }
  ngOnChanges() {
    if (this.arrayName) {
      this.phoneFieldDisabler(this.contactForm.get(this.arrayName).disabled);
    }
  }
  phoneFieldDisabler(isDisable) {
    let mailField = this.contactForm.get(this.arrayName);
    if (isDisable) {
      this.isDisabled = true;
      mailField['controls'].forEach(element => {
        element.disable();
      });
    }
  }
  setmandatoryField(curr, altcontrol, formGroup: UntypedFormGroup, i?) {
    if (i || i >= 0) {
      const arr = formGroup.get(this.arrayName) as UntypedFormArray;
      const group = arr.controls[i] as UntypedFormGroup;
      let curvalue = group.get(curr).value;
      if (curvalue != '') {
        if (curr == 'countryCode') {
          group.controls[altcontrol].clearValidators();
          group.controls[altcontrol].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, i), Validators.minLength(3)]);
          group.controls[altcontrol].markAsTouched();
        } else {
          group.controls[altcontrol].clearValidators();
          group.controls[curr].setValidators([Validators.minLength(3), uniquephoneValidator('countryCode', this.controlName, i)]);
          if (group.controls[this.typeControlName].value === PhoneTypes.mobile) { group.controls[altcontrol].setValidators([Validators.required]) }
          group.controls[altcontrol].markAsTouched();
          group.controls[curr].markAsTouched();
        }
      } else {
        group.controls[altcontrol].clearValidators();
      }
      group.controls[altcontrol].updateValueAndValidity();
      group.controls[curr].updateValueAndValidity();
    } else {
      let curvalue = formGroup.get(curr).value;
      if (curvalue != '') {
        formGroup.controls[altcontrol].setValidators([Validators.required]);
        formGroup.controls[altcontrol].markAsTouched();
      } else {
        formGroup.controls[altcontrol].clearValidators();
      }
      formGroup.controls[altcontrol].updateValueAndValidity();
    }
    this.countryCodeRequiredCheck(i);
  }
  // Dynamic Phone field addition Logic
  addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, isPrimary?, isPrivate?, platformContactUuid?): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.phone,
      extension: extn,
      id: index
    });
    currentForm.addControl(this.typeControlName, new UntypedFormControl(phoneLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: phoneNumber, disabled: !phoneLabel }));
    currentForm.addControl('countryCode', new UntypedFormControl({ value: countryCode, disabled: !phoneLabel }));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isPrimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    currentForm.addControl('platformContactUuid', new UntypedFormControl(platformContactUuid));
    return currentForm;
  }
  addPhoneItem(index, phoneLabel: string, countryCode: string | number, phoneNumber: string | number, extn?: string | number, primaryPhone?: string | boolean, privateInformtionPhone?: string | boolean, platformContactUuid?: string, idx?: number | string): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.push(this.addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, primaryPhone, privateInformtionPhone, platformContactUuid));
      if (idx) {
        const group = phone.controls[idx] as UntypedFormGroup;
        group.controls['countryCode'].setValidators([Validators.required]);
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);
      }
      this.ChangePrimaryToggle();
    }
  }

  removePhoneItem(index: number): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.removeAt(index);
      this.ChangePrimaryToggle();
      this.contactForm.markAsDirty();
    }
  }

  ChangePrimaryToggle() {
    let contactArray = this.contactForm.get(this.arrayName) as UntypedFormArray;
    if (contactArray?.value?.length > 0) {
      let isPrimaryAvailable = false;
      contactArray.controls.forEach(x => {
        const grp = x as UntypedFormGroup;
        if (grp.controls[this.primarySwitchName].value) {
          isPrimaryAvailable = true;
          grp.controls[this.primarySwitchName].disable();
        }
      });
      if (!isPrimaryAvailable) {
        const ctrl = contactArray.controls[0] as UntypedFormGroup;
        ctrl.controls[this.primarySwitchName].setValue(true);
        ctrl.controls[this.primarySwitchName].disable();
      }
    }
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const selectedctrl = arr.controls.filter((x, idx) => idx == formGroupName)?.[0];
    if (selectedctrl) {
      (selectedctrl as UntypedFormGroup).controls[this.primarySwitchName].disable();
    }
    const ctrls = arr.controls.filter((x, idx) => idx !== formGroupName);
    ctrls.forEach(x => {
      const grp = x as UntypedFormGroup;
      grp.controls[this.primarySwitchName].setValue(false);
      grp.controls[this.primarySwitchName].enable();
    });
  }

  typeChange(arg, formGroup: UntypedFormGroup, i) {
    formGroup.get('countryCode').enable();
    formGroup.get(this.controlName).enable();
    formGroup.get(this.controlName).setValidators([Validators.minLength(3)]);

    if (arg.value === this.phoneTypes.office && !formGroup.get('Extension')) {
      formGroup.addControl('Extension', new UntypedFormControl());
    }
    this.countryCodeRequiredCheck(i);


    if (!arg.value) {

      formGroup.reset();
      formGroup.clearValidators();
      formGroup.markAsDirty();
      formGroup.get('countryCode').disable();
      formGroup.get(this.controlName).disable();
      formGroup.controls[this.primarySwitchName].setValue(true);
    }
  }

  countryCodeRequiredCheck(index) {
    if (this.contactForm.get(this.typeControlName)) {
      if (this.contactForm.get(this.typeControlName).value === PhoneTypes.mobile && this.contactForm.get(this.controlName).value) {
        this.contactForm.controls['countryCode'].setValidators([Validators.required]);
      } else {
        this.contactForm.controls['countryCode'].clearValidators();
      }
      this.contactForm.controls['countryCode'].updateValueAndValidity();
    } else if (this.contactForm.get('Phone')) {
      const phoneControl = this.contactForm.controls.Phone['controls'][index]['controls'];
      if (phoneControl[this.typeControlName].value) {
        phoneControl['countryCode'].setValidators([Validators.required]);
      } else {
        phoneControl['countryCode'].clearValidators();
      }
      phoneControl['countryCode'].updateValueAndValidity();
    }
  }

  private patchOrAdd(value) {
    console.log("value", value);
    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
    value.forEach((obj, idx) => {

      console.log("value", obj);
      const group = phone.controls[idx] as UntypedFormGroup;
      if (group) {

        console.log("group", group);
        group.controls['countryCode'].setValidators([Validators.required]);
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);

        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.phone));
        }
        if (!group.get('countryCode')) {
          group.addControl('countryCode', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] === this.phoneTypes.office) {
          group.addControl('Extension', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get('countryCode').enable();
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
      } else {
        const id = obj ? obj.id : '';
        const phoneType = obj ? obj[this.typeControlName] : '';
        const countryCode = obj ? obj.countryCode : '';
        const phoneNumber = obj ? obj[this.controlName] : '';
        const extension = obj ? (obj.extension || obj.Extension) : '';
        const primaryPhone = obj ? obj.primaryPhone : '';
        const privateInformtionPhone = obj ? obj.privateInformtionPhone : '';
        const platformContactUuid = obj ? obj.platformContactUuid : '';
        this.addPhoneItem(id, phoneType, countryCode, phoneNumber, extension, primaryPhone, privateInformtionPhone, platformContactUuid, idx);
      }
    });
  }

  private maskPhoneNo(): string {
    return this.captions.PhoneFormat ?
      this.captions.PhoneFormat : this.captions.PhoneFormat;
  }

  private maskExtension(): string {
    return this.captions.ExtensionFormat ?
      this.captions.ExtensionFormat : '9999999';
  }

  private getPhoneOptions() {
    return [
      { id: this.phoneTypes.mobile, description: this.localization.captions.contactTypesOptions.Phone[0].description, type: ContactType.phone },
      { id: this.phoneTypes.home, description: this.localization.captions.contactTypesOptions.Phone[1].description, type: ContactType.phone },
      { id: this.phoneTypes.office, description: this.localization.captions.contactTypesOptions.Phone[2].description, type: ContactType.phone },
      { id: this.phoneTypes.business, description: this.localization.captions.contactTypesOptions.Phone[3].description, type: ContactType.phone },
      { id: this.phoneTypes.work, description: this.localization.captions.contactTypesOptions.Phone[4].description, type: ContactType.phone }
    ];
  }
}
