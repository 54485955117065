<section class="actions-section">
    <div class="no-filter-action">
        <div class="action-landing" *ngIf="!gridItemListActions.EnableCustomGridAction">
            <div class="title" *ngIf="gridActions.GridTitleType.Type === 'text'">
                <span class="label-content subheading-medium">{{gridActions.GridTitle}}</span>
                <span class="count subheading-medium" *ngIf="gridActions.TotalItems > 0"> ({{gridActions.TotalItems}})</span>
                <span class="add-new-action" *ngIf="gridActions.ShowAddButton" (click)="addNewButtonClicked(gridActions)">Add New</span>
            </div>
            <div class="title-dropdown" *ngIf="gridActions.GridTitleType.Type === 'dropdown'" [ngClass]="gridActions.ShowTextWithDropdown? 'dropdown-withtext' : 'dropdown-withouttext'">
                <span class="label-content subheading-medium" *ngIf="gridActions.ShowTextWithDropdown" title="{{gridActions.GridTitle}}">{{gridActions.GridTitle}}</span>
                <mat-form-field appearance="outline" class="grid-dropdown">
                    <mat-select [(ngModel)]="selectedData" panelClass="text-with-dropdown" (selectionChange)="dropdownChange($event)" disableOptionCentering="true"> 
                        <mat-select-trigger>
                            <span title="{{selectedName}}" >{{selectedName}} <span *ngIf="gridActions.TotalItems > 0"> ({{gridActions.TotalItems}})</span></span>
                          </mat-select-trigger>
                        <mat-option *ngFor="let opt of gridActions.GridTitleType.TypeConfig" [value]="opt.Id">
                        {{opt.DisplayName}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
            <div class="action-buttons">
                <div class="max-count-hint" *ngIf="gridActions.EnableHint">
                    <label class="subheading-medium">{{gridActions.HintText}}</label>
                </div>
                <div class="toggle__button" *ngIf="gridActions.EnableToggle">
                    <label class="toggle__switch">
                        <input class="toggle__switch-input" type="checkbox" [(ngModel)]="toggleValue" (change)="toggleChange($event.target.checked)"/>
                        <span class="toggle__switch-label"></span>
                        <span class="toggle__switch-handle"></span>
                    </label>
                </div>
                <label class="toggle-label" *ngIf="gridActions.EnableToggle && gridActions.ToggleText">{{gridActions.ToggleText}}</label>
                <label class="toggle-label" *ngIf="gridActions.EnableToggle && !gridActions.ToggleText">Show Active Only</label>
                <div class="toggle-indication" *ngIf="gridActions.ShowActiveIndication">
                    <i class="icon-tick-eatec"></i>
                    <i class="icon-not-active-eatec"></i>
                </div>
                <div class="search-bar" *ngIf="gridActions.SearchConfig.EnabledSearch">
                    <mat-form-field appearance="outline" class="grid-search">
                        <input matInput placeholder="{{gridActions.SearchConfig.SearchPlaceholder}}" id="searchBox" autocomplete="off" [formControl]="searchText" />
                        <i matSuffix *ngIf="!searchText.value" class="icon-search-eatec"></i>
                        <i matSuffix *ngIf="searchText.value" class="icon-requisition-denied-eatec" (click)="searchCancel()"></i>
                    </mat-form-field>
                </div>
                <div class="refresh-icon" *ngIf="gridActions.EnableRefresh" (click)="refreshClick(gridActions)">
                    <span class="icon-Refresh-eatec"></span>
                </div>
                <div class="filter-icon" *ngIf="gridActions.FilterConfig.EnableFilter" (click)="filterOption()" [ngClass]="showFilter? 'hightlight-filter' :''">
                    <span class="icon-px_Filter-eatec"></span>
                </div>
                <div class="reshuffle-icon" *ngIf="gridActions.ShuffleColumnConfig.EnableColumnShuffle" #reShuffle (click)="shuffleColumns()">
                    <span class="icon-px_Manage_Column-eatec"></span>
                </div>
                <div class="resizerow-icon" *ngIf="gridActions.ResizeRowsConfig.EnableResizeRows" #reSizeRows (click)="resizeRows()"> 
                    <span class="icon-px_Manage_Row-eatec"></span>
                </div>
                <div class="refresh-text" *ngIf="gridActions.ShowRefresh" (click)="refreshClick(gridActions)">
                    <span>REFRESH</span>
                </div>
                <div class="filter-icon" *ngIf="gridActions.GlobalDelete" (click)="globalDelete()" [ngClass]="gridActions.EnableGlobalDelete? 'enable-class' :'disable-class'">
                    <span class="icon-delete-eatec"></span>
                </div>
            </div>
        </div>
        <div class="action-item-list" *ngIf="gridItemListActions.EnableCustomGridAction">
            <div class="action-buttons">
                <div class="search-bar" *ngIf="gridItemListActions.EnableSearch && gridActions.SearchConfig.EnabledSearch" [ngClass]="EnableFilterSearch ? '' : 'disable-search-filter'">
                    <mat-form-field appearance="outline" class="grid-search">
                        <input matInput placeholder="{{gridActions.SearchConfig.SearchPlaceholder}}" id="searchBox" autocomplete="off" [formControl]="searchText" />
                        <i matSuffix *ngIf="!searchText.value" class="icon-search-eatec"></i>
                        <i matSuffix *ngIf="searchText.value" class="icon-requisition-denied-eatec" (click)="searchCancel()"></i>
                    </mat-form-field>
                </div>
                <div class="filter-icon" *ngIf="gridItemListActions.EnableFilter && gridActions.FilterConfig.EnableFilter" (click)="filterOption()"
                [ngClass]="EnableFilterSearch ? '' : 'disable-search-filter'">
                    <span class="icon-filter-eatec"></span>
                </div>
            </div>
            <div class="action-icon-buttons">
                <div *ngIf="gridItemListActions.ShowDropdown" class="dropdown-section">
                    <mat-form-field appearance="outline" class="grid-dropdown">                        
                        <mat-select [(ngModel)]="gridItemListActions.SelectedSupplier" disableOptionCentering="true"
                            placeholder="{{gridItemListActions.SelectPlaceholder}}" (selectionChange)="dropdownChange($event)" (openedChange)="openedChange($event)">
                            <input *ngIf="enableDropDownSearch" class="agilysys-dropdown-search" type="text" placeholder="Search"
                                [formControl]="dropDownsearchControl" (keydown)="$event.stopPropagation()"/>
                            <span class="icon-px_close-eatec" *ngIf="dropDownsearchControl.value" (click)="dropDownsearchControl.reset();"></span>
                            <mat-option *ngFor="let opt of gridItemListActions.DropdownOptions" [value]="opt.Id">
                                {{opt.Name}}
                            </mat-option>
                            <span class="mat-option" *ngIf="gridItemListActions.DropdownOptions.length === 0 && dropDownsearchControl.value"
                                (click)="$event.stopPropagation();">
                                No Data Found
                            </span>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="deactivate-section" *ngIf="gridItemListActions.IsActiveCount > 0 && gridItemListActions.ShowDeactivate">
                    <span (click)="deactivateIconClick($event)">
                        <i class="icon-cancel-eatec" title="Deactivate"></i>
                        <span class="deactivate-text">Deactivate&nbsp;({{gridItemListActions.IsActiveCount}})</span>
                    </span>
                </div>
                <div class="count-section">
                    <span *ngIf="gridItemListActions.ShowSelectedCount">{{gridItemListActions.SelectedListCount}}
                        <span *ngIf="gridItemListActions.SelectedListCount >= 0">  Item(s)</span>
                        <!-- <span *ngIf="gridItemListActions.SelectedListCount === 1">  Item(s)</span> -->
                    </span>
                </div>
                <div class="icon-actions-eatec" #reShuffle *ngIf="gridItemListActions.ActionIcons.length > 0" >
                    <span class="action-buttons" [ngClass]="gridItemListActions.SelectedListCount > 0 || icon.Enable ? 'enable-icon' : 'disable-icon'"
                    *ngFor="let icon of gridItemListActions.ActionIcons" (click)="actionIconClick(icon)">
                        <i class="{{icon.IconName}}" title="{{icon.Tooltip}}"></i>
                    </span>
                    
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showFilter" class="filter-action">
        <lib-grid-filter [filterData]="gridActions.FilterConfig.FilterOptions" (closeFilter)="filterOption($event)"
        (filterApplied)="applyFilter($event)" (fetchFilterData)="fetchFilterValues($event)"></lib-grid-filter>
    </div>
</section>
