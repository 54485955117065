import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
    private static SECRET_KEY = 'secret_key';
  public static SetSessionStorage(Key: string, Value: Object): void {
    try {
      if (Key === null || Key === '' || Value === null) {
        return;
      }
      window.sessionStorage.setItem(Key, this.encryptData(Value).toString());
    } catch (error) {
      console.error(error);
    }
  }

  public static GetSessionStorage(Key: string): string {
    try {
      if (Key === null || Key === '') {
        return;
      }
      return this.decryptData(window.sessionStorage.getItem(Key));
    } catch (error) {
      console.error(error);
    }
  }

  public static RemoveSessionStorage(Key: string): void {
    try {
      if (Key === null || Key === '') {
        return;
      }
      window.sessionStorage.removeItem(Key);
    } catch (error) {
      console.error(error);
    }
  }

  public static ClearSessionStorage(): void {
       window.sessionStorage.clear();
  }

  private static encryptData(data) {
    try {
        data = CryptoJS.AES.encrypt(data, this.SECRET_KEY);
        data = data.toString();
        return data;
    } catch (error) {
      console.log(error);
    }
  }

  private static decryptData(data) {
    try {
      if (data) {
        data = CryptoJS.AES.decrypt(data, this.SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
