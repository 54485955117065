import { FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { AgilysysButtonModel } from 'src/app/eatecui/source/agilysys-buttons/models/agilysys-buttons.interface';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';

export const CustomComapareLocation: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: any, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData ?: TransactionConfiguration
    ): any => {
        try {
            const fromLocationId = FormGroupData.value[FieldGrouping.CustomFormAction.CutomFieldFrom];
            const toLocationId = FormGroupData.value[FieldGrouping.CustomFormAction.CutomFieldTo];
            if (fromLocationId && toLocationId) {
                if (fromLocationId === toLocationId) { 
                    FormGroupData.setErrors({ 'valid': false });
                    if (CustomData && CustomData.hasOwnProperty('CreateTranscationConfig')) {
                        CustomData.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach((button: any) => {
                            if (button.buttonKey === 'confirm') {
                                button.displayProperity = true;
                            }
                        });
                    }
                } else if ( fromLocationId !== toLocationId && checkQuantity(CustomData)) {
                    FormGroupData.setErrors({ 'valid': true });
                    if (CustomData && CustomData.hasOwnProperty('CreateTranscationConfig')) {
                        CustomData.CreateTranscationConfig.HeaderConfig.ActionButtonConfig.forEach((button: any) => {
                            if (button.buttonKey === 'confirm') {
                                button.displayProperity = false;
                            }
                        });
                    }
                }
            }
            
           console.log(FormGroupData, FormField, FieldGrouping);
        } catch (error) {
            console.log('error occured CustomDefaultSelect', error);
        }
    };

    function checkQuantity(transactionData: TransactionConfiguration) {
        let enableRequest = false;
        if (transactionData && transactionData.CreateTranscationConfig && transactionData.CreateTranscationConfig.ItemsConfig.ItemList) {
            const itemListData = transactionData.CreateTranscationConfig.ItemsConfig.ItemList.ItemGridConfig.GridData;
            if (itemListData.length > 0) {
              const quantityDataGreatter = itemListData.findIndex(x => x['qty'] > 0 || x['Qty'] > 0);
              if (quantityDataGreatter > -1) {
                enableRequest = true;
              }
            }
        }
        return enableRequest;
}