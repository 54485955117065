import { FormGroup } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { LookupConfiguration, SearchShowMoreConfig } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { filter } from 'rxjs/operators';
import { AddNewTypeConfig } from '../master.interface';
import { MasterSearchList } from './master-detail-config.interface';

export const MasterLinkedItemSearchList: MasterSearchList = (
searchByType: AddNewTypeConfig,
httpService: HttpService,
searchByKey: any, FormGroupData: FormGroup, UnitsList?: any, searchShowMoreConfigs?: SearchShowMoreConfig): any => {
        return new Promise(function (resolve, reject) {
        if ( searchByKey && searchByType && typeof(searchByKey) === 'string') {
            const ProductType = FormGroupData.value.ProductsType;
            const endPointUrl = ( searchByType.Name === 'Inventory') ? searchByType.EndPointUrl : searchByType.Url;
            const searchText = `(contains(tolower(Name),'${searchByKey.toLowerCase()}'))`;
            // let filterText; TypeId
            // switch (endPointUrl) {
            //     case 'Ingredients' :
            //         filterText = `IngredientTypes/DivisionId eq ${ProductType}`;
            //         searchText = searchText + ' and ' + filterText;
            //         break;
            // }
            const typeId = ( searchByType.hasOwnProperty('DefaultTypeId') && searchByType.DefaultTypeId !== '' ) ? 
            searchByType.DefaultTypeId : ( searchByType.hasOwnProperty('LinkedTypeId') && searchByType.LinkedTypeId !== '' ) ?
            searchByType.LinkedTypeId : searchByType.TypeId;
            const GetDataFilter = encodeURIComponent(`${searchText}`).replace(/[!'()*]/g, function (c) {
                return '%' + c.charCodeAt(0).toString(16);
            });
            const FilterParams = ( searchByType.OdataKey === 'Packages' ) ? 
            GetDataFilter + `and TypeId eq ${typeId}` : GetDataFilter;
            const params: QueryParams = {
                count: true,
                top: InfiniteScrollItemCount,
                skip: 0,
                expand: searchByType.Name === 'Packages' ? 'PackLists($select=Item)' : '',
                select: '',
                filter: '',
                orderby: '',
                isactive: true,
                search: '',
                filterQuery: FilterParams,
                searchtext: ''
            };
            if (searchShowMoreConfigs) {
                params.top = searchShowMoreConfigs.Take;
                params.skip = searchShowMoreConfigs.Skip;
            }
            const getQueryParams = formQueryParams(params);
            const GetDataByIdUrl = `/inventory/api/${endPointUrl + getQueryParams}`;
            if (endPointUrl !== 'IngredientLookup' && endPointUrl !== 'GetRecipeIngredientLookup'
            && endPointUrl !== 'ProductLookup') {
                httpService.GethttpMethod(GetDataByIdUrl).subscribe(res => {
                    const responseData = res;
                    resolve(responseData);
                });
            } else {
                const GetUrl = `/inventory/api/${endPointUrl}`;
                const lookUpData = {} as LookupConfiguration;
                lookUpData.Id = 0;
                lookUpData.TransType = 4;
                if (searchByType.EndPointUrl === 'GetRecipeIngredientLookup') {
                    lookUpData.ModuleId = 7; // To differentiate Module
                    lookUpData.itemType = searchByType.DefaultTypeId ? searchByType.DefaultTypeId : searchByType.TypeId;
                  }
                lookUpData.searchText = searchByKey;
                lookUpData.FromLocationId = undefined;
                lookUpData.ToLocationId = undefined;
                lookUpData.TypeIds = [];
                lookUpData.PackageIds = [];
                lookUpData.ClassIds = [];
                if (searchShowMoreConfigs) {
                    lookUpData.FetchAll = searchShowMoreConfigs.FetchAll;
                    lookUpData.Take = searchShowMoreConfigs.Take;
                    lookUpData.Skip = searchShowMoreConfigs.Skip;
                }
                httpService.PosthttpMethod(GetUrl, lookUpData).subscribe(x => {
                    const responseData = x;
                    resolve(responseData);
                });
            }
        } 
    });

};

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}