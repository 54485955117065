import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Pipe({name: 'timeFormat'})
export class TimeFormat implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(utcTime: string): any {
     if (utcTime) {
      const currentTimeDate = moment().format('MM/DD/YYYY');
      const dateTime = new Date(`${currentTimeDate} ${utcTime} UTC`);
      const momentDateTime = moment(dateTime).format('hh:mm a');
      return momentDateTime;
    }
  }
}
