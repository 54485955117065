import { Directive, ElementRef, HostListener, Input, OnChanges} from '@angular/core';
@Directive({
  selector: '[inputvalidate]'
})
export class CheckInputValidate implements OnChanges {
    constructor(public el: ElementRef) {}
  @Input() public changeValue: string;
    @HostListener('change') ngOnChanges() {
      if(this.changeValue == "   "){
        return;
      }
    }
}