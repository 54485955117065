import { BooleanFormater } from 'src/app/eatecui/source/setup-master/shared/formaters/boolean-formatter.interface';
import { CommonDateFormater } from './common-date-formatter';
import { DateFormater } from './date-formater.interface';
import { GlobalDateTimeFormater } from './date-time-formater.interface';
import { GlobalDateFormaters } from './global-date-formater';
import { GlobalNumberFormater } from './global-number-formater.interface';
import {  cellValueFormater } from './value-formater.interface';
import { DecimalFormater } from './decimal-formater.interface';
import { CurrencyFormater } from './currency-formaters.interface';
import { DateTimeFormater} from 'src/app/eatecui/source/shared/models/formaters/datetime-formater.interface';
export const Formaters = {
  columnValueFormater: cellValueFormater,
  dateformater: DateFormater,
  dateTimeFormatter: DateTimeFormater,
  globalDateFormaters: GlobalDateFormaters,
  globalNumberFormater : GlobalNumberFormater,
  commonDateFormater: CommonDateFormater,
  booleanFormater: BooleanFormater,
  decimalFormater: DecimalFormater,
  currencyFormater: CurrencyFormater,
  dateTimeFormater: GlobalDateTimeFormater
};

