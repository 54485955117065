import { Component,  OnInit,  ViewEncapsulation } from '@angular/core';
import { TemplateTypes } from '../Models/common.models';
import { Localization } from '../localization/localization';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilities } from '../shared/shared/utilities/common-utilities';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplatesComponent implements OnInit {
  captions: any;
  selectedCategory: any;
  templateTypes: { id: TemplateTypes; name: any; IsAuthorized: boolean; }[];
  hideCrudWindow: boolean = true;
  hideCrudSmsWindow: boolean = true;
  hideCrudWhatsappWindow: boolean = true;
  hideCrudReportWindow: boolean = true;
  editEmailObj: any;
  editSmsObj: any;
  editWhatsappObj: any;
  editReportObj:any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showTableRecords: boolean = false;
  isCopyEnabled: boolean = false;
  isCopy: boolean = false;
  isEmailCopyInputs: {
    isCopy: boolean;
    eventName: string;
  };
  isSmsCopyInputs: {
    isCopy: boolean;
    eventName: string;
  };
  isReportCopyInputs: {
    isCopy: boolean;
    eventName: string;
  };
  isWhatsappCopyInputs: {
    isCopy: boolean;
    eventName: string;
  };
  istoggleDirectEmit: boolean;
  isViewOnly = false;
  templateForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder,private localization: Localization, private route: ActivatedRoute, private commonUtils: CommonUtilities) {
    this.captions = this.localization.captions.settings.utilities;
    this.templateTypes = [
      {
        id: TemplateTypes.Email,
        name: this.captions.EmailTemplates,
        IsAuthorized: true
      },
      {
        id: TemplateTypes.SMS,
        name: this.captions.SMSTemplates,
        IsAuthorized: true
      },
      {
        id: TemplateTypes.WhatsApp,
        name: this.captions.WhatsappTemplates,
        IsAuthorized: true
      }
    ];
  }

  ngOnInit() {
    this.templateForm = this.fb.group({

    })
    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.selectedCategory = data.templateID;
      this.showTableRecords = data.showtableRecords ? data.showtableRecords : false;
      this.isCopyEnabled = data.activateCopy ? data.activateCopy : false;
      this.istoggleDirectEmit = data.toggleDirectEmit ? data.toggleDirectEmit : false;
      
    });
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
  }
  }

  categoryChange(event, selectedMenu) {
    if (selectedMenu.IsAuthorized) {
      this.selectedCategory = Number(selectedMenu.id);
    } 
  }

  // email template
  crudemailTemplateEmitter(eve){
    this.hideCrudWindow = eve.closeCrudWindow;
    this.editEmailObj = eve.editEmailObj;
    this.isViewOnly = eve.isViewOnly;
    this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
    this.manipulateClass('router-container', 0, 'add', 'wid-100');
    this.manipulateClass('router-container', 0, 'add', 'set-ht');
   
  }

  
  getEmitedEmailData(eve){
  
      this.hideCrudWindow = eve.closeCrudWindow;
      this.isViewOnly = eve.isViewOnly
      this.isEmailCopyInputs = {
        isCopy: false,
        eventName: ''
      };
      this.manipulateClass('mat-tab-header', 0, 'remove', 'd-none');
      this.manipulateClass('menuSecondary-container', 0, 'remove', 'd-none');
      this.manipulateClass('subnav-wrapper', 0, 'remove', 'd-none');
      this.manipulateClass('menuLowerlevel-container', 0, 'remove', 'd-none');
      this.manipulateClass('secondary-router-container', 0, 'remove', 'h-100');
      this.manipulateClass('tertiary-container', 0, 'remove', 'h-100');
      this.manipulateClass('router-container', 0, 'remove', 'wid-100');
      this.manipulateClass('router-container', 0, 'remove', 'set-ht');
  }

  getEmitedReportData(eve){
  
    this.hideCrudReportWindow = eve.closeCrudWindow;
    this.isReportCopyInputs = {
      isCopy: false,
      eventName: ''
    };
    this.manipulateClass('mat-tab-header', 0, 'remove', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'remove', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'remove', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'remove', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'remove', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'remove', 'h-100');
    this.manipulateClass('router-container', 0, 'remove', 'wid-100');
    this.manipulateClass('router-container', 0, 'remove', 'set-ht');
}

  // Sms Template
  crudsmsTemplateEmitter(eve){
    this.hideCrudSmsWindow = eve.closeCrudWindow;
    this.editSmsObj = eve.editSmsObj;
    this.isViewOnly = eve.isViewOnly;
    this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
    this.manipulateClass('router-container', 0, 'add', 'wid-100');
    this.manipulateClass('router-container', 0, 'add', 'set-ht');
    
  }

// Report Template
  crudreportTemplateEmitter(eve){
    this.hideCrudReportWindow = eve.closeCrudWindow;
    this.editReportObj = eve.editReportObj;
    this.isViewOnly = eve.isViewOnly;
    this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
    this.manipulateClass('router-container', 0, 'add', 'wid-100');
    this.manipulateClass('router-container', 0, 'add', 'set-ht');    
  }

    // Whatsapp Template
    crudWhatsappTemplateEmitter(eve){
      this.hideCrudWhatsappWindow = eve.closeCrudWindow;
      this.editWhatsappObj = eve.editWhatsappObj;
      this.isViewOnly = eve.isViewOnly;
      this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
      this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
      this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
      this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
      this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
      this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
      this.manipulateClass('router-container', 0, 'add', 'wid-100');
      this.manipulateClass('router-container', 0, 'add', 'set-ht');
      
    }

  copyEmailfunc(eve) {
    this.isEmailCopyInputs = {
      isCopy: true,
      eventName: eve.editEmailObj.templateName
    };
    this.hideCrudWindow = eve.closeCrudWindow;
    this.editEmailObj = eve.editEmailObj;
    this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
    this.manipulateClass('router-container', 0, 'add', 'wid-100');
    this.manipulateClass('router-container', 0, 'add', 'set-ht');
   
  }

  copyReportfunc(eve) {
    this.isReportCopyInputs = {
      isCopy: true,
      eventName: eve.editReportObj.templateName
    };
    this.hideCrudReportWindow = eve.closeCrudWindow;
    this.editReportObj = eve.editReportObj;
    this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
    this.manipulateClass('router-container', 0, 'add', 'wid-100');
    this.manipulateClass('router-container', 0, 'add', 'set-ht');
   
  }

  copySmsfunc(eve) {
    this.isSmsCopyInputs = {
      isCopy: true,
      eventName: eve.editSmsObj.templateName
    };
    this.hideCrudSmsWindow = eve.closeCrudWindow;
    this.editSmsObj = eve.editSmsObj;
    this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
    this.manipulateClass('router-container', 0, 'add', 'wid-100');
    this.manipulateClass('router-container', 0, 'add', 'set-ht');   
  }
  
  getEmitedSmsData(eve){
    this.hideCrudSmsWindow = eve.closeCrudWindow;
    this.isSmsCopyInputs = {
      isCopy: false,
      eventName: ''
    };
    this.manipulateClass('mat-tab-header', 0, 'remove', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'remove', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'remove', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'remove', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'remove', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'remove', 'h-100');
    this.manipulateClass('router-container', 0, 'remove', 'wid-100');
    this.manipulateClass('router-container', 0, 'remove', 'set-ht');  
  }

  copyWhatsappfunc(eve) {
    this.isWhatsappCopyInputs = {
      isCopy: true,
      eventName: eve.editWhatsappObj.templateName
    };
    this.hideCrudWhatsappWindow = eve.closeCrudWindow;
    this.editWhatsappObj = eve.editWhatsappObj;
    this.manipulateClass('mat-tab-header', 0, 'add', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'add', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'add', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'add', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'add', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'add', 'h-100');
    this.manipulateClass('router-container', 0, 'add', 'wid-100');
    this.manipulateClass('router-container', 0, 'add', 'set-ht');   
  }

  getEmitedWhatsappData(eve){
    this.hideCrudWhatsappWindow = eve.closeCrudWindow;
    this.isWhatsappCopyInputs = {
      isCopy: false,
      eventName: ''
    };
    this.manipulateClass('mat-tab-header', 0, 'remove', 'd-none');
    this.manipulateClass('menuSecondary-container', 0, 'remove', 'd-none');
    this.manipulateClass('subnav-wrapper', 0, 'remove', 'd-none');
    this.manipulateClass('menuLowerlevel-container', 0, 'remove', 'd-none');
    this.manipulateClass('secondary-router-container', 0, 'remove', 'h-100');
    this.manipulateClass('tertiary-container', 0, 'remove', 'h-100');
    this.manipulateClass('router-container', 0, 'remove', 'wid-100');
    this.manipulateClass('router-container', 0, 'remove', 'set-ht');
  }

  manipulateClass(containerName, index, operation, className){
    if (operation === 'add'){
      if (document.getElementsByClassName(containerName) && document.getElementsByClassName(containerName).length > 0){
        document.getElementsByClassName(containerName)[index].classList.add(className);
      }
    }
    if (operation === 'remove'){
      if (document.getElementsByClassName(containerName) && document.getElementsByClassName(containerName).length > 0){
        document.getElementsByClassName(containerName)[index].classList.remove(className);
      }
    }
  }

  toggleEvent(event){
    if(event){
      this.templateForm.markAsDirty();
    } else {
      this.templateForm.markAsPristine();
    }
  }
}
