<section class="grid-filter">
    <!-- <div class="filterBar">
        <i class="icon-filter"></i>
        <input placeholder="Filter" autofocus="true" [(ngModel)]="searchText" (ngModelChange)="searchTextChanged()"/>
    </div> -->
    <div class="filter-data">
        <div *ngFor="let data of filterData;let i = index;" class="filter-data-keys" (click)="openFilterOptions(data, $event);selectedFilterMenu = i;" [matMenuTriggerFor]="appMenu"
        (menuClosed)="menuClosed()">
            <div class="filter-info-key" [ngClass]="selectedFilterMenu === i ? 'highlight-filter-menu' : ''">{{data.FilterKey}} <span class="icon-back-eatec"></span></div>
            <mat-menu #appMenu="matMenu" xPosition="after" yPosition="below" class="filter-options">
                <div *ngIf="data.Type === filterType.SelectType"class="filter-options-list">
                    <div class="filter-vallues-menu"  (click)="$event.stopPropagation();" >
                        <div *ngIf="data.FilterValue.length > 0 && !data.EnableLoader">
                            <div *ngFor="let value of data.FilterValue" class="filter-options-value"  >
                                <mat-checkbox [checked]="value.Checked" (change)="checkBoxSelected(value, data.FilterKey)" disableRipple="true"
                                title="{{value.FilterName}}">{{value.FilterName}}</mat-checkbox>
                                <!-- <div class ="custom-checkbox"
                                     (click)="checkBoxSelected(value, data.FilterKey)">
                                    <span class="icon-span" [ngClass]="value.Checked? 'icon-checkbox' : 'icon-blank-check-box'"></span>
                                    <span class="checkbox-label" title="{{value.FilterName}}">{{value.FilterName}}</span>
                                </div> -->
                            </div>
                        </div>
                        <div class="no-results-found" *ngIf="data.FilterValue.length == 0 && !data.EnableLoader">
                            No data found
                        </div>
                        <div class="loader-section" *ngIf="data.EnableLoader">
                            <mat-progress-spinner diameter=20 mode="indeterminate" ></mat-progress-spinner>
                        </div>
                    </div>
                    <div class="filter-footer" [ngClass]="data.FilterValue.length === 0 ? 'disable-clear' :''">
                        <button mat-button style="border:1px solid #2653e0;color: #2653e0;margin-right: 10px;height:36px" (click)="clearSelectedValues(data.FilterKey)">CLEAR</button>
                        <button mat-button style="background-color: #2653e0;color: #fff;" (click)="applyFilterValues(data.FilterKey)">APPLY</button>
                    </div>
                </div>
                <div *ngIf="data.Type === filterType.DateRangeType" class="date-filter-list">
                    <div class="date-section" (click)="$event.stopPropagation();">
                        <mat-form-field appearance="fill">
                            <mat-label>Enter a date range</mat-label>
                            <mat-date-range-input [rangePicker]="rangePicker">
                                <input [(ngModel)]="data.FilterValue[0].Value"  matStartDate [placeholder]="IniDateFieldFormat ? IniDateFieldFormat : 'Sart Date'" (dateChange)="dateChanged('change', $event, 'start', data)">
                                <input [(ngModel)]="data.FilterValue[1].Value"  matEndDate [placeholder]="IniDateFieldFormat ? IniDateFieldFormat : 'End Date'" (dateChange)="dateChanged('change', $event, 'end', data)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker>
                                <!-- <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                </mat-date-range-picker-actions> -->
                            </mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="filter-footer">
                        <button mat-button style="border:1px solid #2653e0;color: #2653e0;margin-right: 10px;height:36px" (click)="clearSelectedValues(data.FilterKey)">CLEAR</button>
                        <button mat-button style="background-color: #2653e0;color: #fff;" (click)="applyFilterValues(data.FilterKey)">APPLY</button>
                    </div>
                </div>
                <div *ngIf="data.Type === filterType.RadioType" class="radio-filter-list">
                    <div class="single-select-section" (click)="$event.stopPropagation();" *ngFor="let filterdata of filterData">
                        <mat-radio-group
                        aria-labelledby="radio-group-label"
                        class="single-select-radio-group"
                        [(ngModel)]="radioSelected">
                        <mat-radio-button class="single-select-radio-button" (change)="radioSelectChange(filterVal, filterdata)"
                         *ngFor="let filterVal of filterdata.FilterValue" [value]="filterVal">
                            {{filterVal.FilterName}}
                        </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="filter-footer">
                        <button mat-button style="border:1px solid #2653e0;color: #2653e0;margin-right: 10px;height:36px" (click)="clearSelectedValues(data.FilterKey)">CLEAR</button>
                        <button mat-button style="background-color: #2653e0;color: #fff;" (click)="applyFilterValues(data.FilterKey)">APPLY</button>
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>
    <div class="cancel-filter">
        <i class="icon-requisition-denied-eatec" (click)="disableFilter()"></i>
    </div>
</section>