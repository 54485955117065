import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer, UntypedFormControl } from '@angular/forms';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-inventory-pickup-delivery-report',
  templateUrl: './inventory-pickup-delivery-report.component.html',
  styleUrls: ['./inventory-pickup-delivery-report.component.scss']
})
export class InventoryPickupDeliveryReportComponent implements OnInit {

  InventoryPickupDeliveryReportFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  currentDate: Date;
  startDate: Date;
  endDate: Date;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  floatLabel: string;
  editPatchValue: any;
  isFromEdit = false;
  isFromJobScheduler: boolean = false;
  categoryComponentFilter: any;
  timelineOptions: DropdownOptions[];
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private controlContainer: ControlContainer,
    private propertyInformation: RetailPropertyInformation, private container: ViewContainerRef) {
      this.floatLabel = this.localization.setFloatLabel;
     }

  ngOnInit() { 
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
    this.radioFilter = [{id: 0, name: this.captions.InhouseInventory}, {id: 1, name: this.captions.OnArrival}];
    this.currentDate = this.propertyInformation.CurrentDate;
    this.minStartDate = this.propertyInformation.CurrentDate;
    this.minEndDate = this.propertyInformation.CurrentDate;
    this.InventoryPickupDeliveryReportFormGrp = this.fb.group({
      lostCostMethod: false,
      itemFilter: 0,
      categoryView: false,
      category: new UntypedFormGroup({}),
      startDate: this.currentDate,
      endDate: this.currentDate
    });
    if (this.isFromJobScheduler) {
      this.InventoryPickupDeliveryReportFormGrp.addControl('timelineFrom', new UntypedFormControl());
      this.InventoryPickupDeliveryReportFormGrp.addControl('timelineCountFrom', new UntypedFormControl());
      this.InventoryPickupDeliveryReportFormGrp.addControl('timelineTo', new UntypedFormControl());
      this.InventoryPickupDeliveryReportFormGrp.addControl('timelineCountTo', new UntypedFormControl());
      this.setSchedulercontrols();
    }
    this.formReady.emit(this.InventoryPickupDeliveryReportFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if (this.localization.isFromJobScheduler && _parent.edit){
        this.categoryComponentFilter =
        {
          category: _parent.editableValue.CategoryIds,
          subCategory1: _parent.editableValue.SubCategory1Ids,
          subCategory2: _parent.editableValue.SubCategory2Ids,
          subCategory3: _parent.editableValue.SubCategory3Ids,
          subCategory4: _parent.editableValue.subCategory4Ids,
          subCategory5: _parent.editableValue.SubCategory5Ids
        };
        this.PatchSchedulercontrols(_parent.edit);
      }
    }
  }

  private PatchSchedulercontrols(isEdit) {
    if (this.localization.isFromJobScheduler && isEdit) {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      setTimeout(() => {
        if (patchItem.fromWildCard != undefined)
          this.InventoryPickupDeliveryReportFormGrp.controls.timelineFrom.patchValue(this.localization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

        if (patchItem.fromIncrement != undefined)
          this.InventoryPickupDeliveryReportFormGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

        if (patchItem.toWildCard != undefined)
          this.InventoryPickupDeliveryReportFormGrp.controls.timelineTo.patchValue(this.localization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

        if (patchItem.toIncrement != undefined)
          this.InventoryPickupDeliveryReportFormGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
      }, 1);
    }
  }

  setSchedulercontrols() {
    this.timelineOptions = this.localization.scheduleJobWildCards;
    this.InventoryPickupDeliveryReportFormGrp.controls['timelineFrom'].setValue(this.localization.scheduleJobWildCards[0].id);
    this.InventoryPickupDeliveryReportFormGrp.controls['timelineTo'].setValue(this.localization.scheduleJobWildCards[0].id);
    this.InventoryPickupDeliveryReportFormGrp.controls['timelineCountFrom'].setValue(0);
    this.InventoryPickupDeliveryReportFormGrp.controls['timelineCountTo'].setValue(0);
  }

   /**
   * After a form is initialized, we link it to our main form
   */
  formInitialized(form: UntypedFormGroup) {
    this.InventoryPickupDeliveryReportFormGrp.setControl('category', form);
  }


  IsSelectionValid(validationObject: ValidationMessage) {
    this.IsValidSelection.emit({ dropDownName: validationObject.dropDownName, validity: validationObject.validity });
  }

  startDateChanged(arg, startDate, endDate) {
    if (this.isStartDateExceedsEndDate(startDate, endDate)) {
      this.InventoryPickupDeliveryReportFormGrp.controls[endDate].setValue(
        this.InventoryPickupDeliveryReportFormGrp.controls[startDate].value
      );
    }
    this.minEndDate = this.InventoryPickupDeliveryReportFormGrp.controls[
      startDate
    ].value;
  }

  isStartDateExceedsEndDate(stDate, edDate): boolean {
    const startDate = this.InventoryPickupDeliveryReportFormGrp.controls[stDate]
      .value;
    const endDate = this.InventoryPickupDeliveryReportFormGrp.controls[edDate]
      .value;
    return this.resetTime(startDate) > this.resetTime(endDate);
  }
  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }
}
