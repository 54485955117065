import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpMethod, HttpServiceCall } from '../../../shared/service/http-call.service';
import { Host, PaidOptions, SPAManagementBreakPoint } from '../../../shared/globalsContant';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { takeUntil } from 'rxjs/operators';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { ReplaySubject } from 'rxjs';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { AgTimeConfig } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'add-break',
  templateUrl: './add-break.component.html',
  styleUrls: ['./add-break.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddbreakComponent implements OnInit {

  FormGrp: UntypedFormGroup;
  public breakDuration: any = [];
  public breakType: any = [];
  showTime: any;
  vDurationErr: string;
  vTherapistErr: string;
  staff: string;
  vBreakTypeErr: string;
  vStartTimeErr: string;
  captions: any;
  therapists: any = [];
  breaks = [];
  currentTime: any;
  vEndTimeErr: any;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  floatLabel:string;
  paidOptions: any;
  selectedPaidOptions: number;
  paid: boolean = false;
  unpaid: boolean=false;
  breakPointNumber:any;
  paidUnpaidToggle:boolean;
  startTimeInputs: AgTimeConfig;
  endTimeInputs: AgTimeConfig;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AddbreakComponent>, private vsBusiness: ViewSettingClientBusiness,
    public fb: UntypedFormBuilder,
    private http: HttpServiceCall,
    private BP: BreakPointAccess,
    private utils: SpaUtilities,
    public localization: SpaLocalization,
    private PropertyInfo: SpaPropertyInformation,
    private commonUtils: QuickLoginUtilities,
    private _retailPropertyInfo: RetailPropertyInformation) {
    this.captions = this.localization.captions.staffSchedule;
    this.vDurationErr = this.captions.MissingDuration;
    this.vTherapistErr = this.data?.datarecord?this.captions.MissingInstructor:this.captions.MissingTherapist;
    this.staff = this.data?.datarecord?this.captions.Instructor:this.captions.Therapist;
    this.vBreakTypeErr = this.captions.MissingBreakType;
    this.vStartTimeErr = this.captions.MissingStartTime;
    this.vEndTimeErr = this.captions.MissingEndTime;
    this.FormGrp = this.fb.group({
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      breakType: '',
      therapistName: ['', Validators.required],
      comments: [''],
      paidOptions: ''
    });
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.floatLabel = this.localization.setFloatLabel;
    this.paidOptions = [{ id: 0, name: this.localization.captions.paid, checked: false }, { id: 1, name: this.localization.captions.unpaid, checked: false }];
  }

  ngOnInit() {
    this.startTimeInputs = {
      className: '',
      form: this.FormGrp,
      formControlName: 'startTime',
      placeHolder: this.captions.BreakStartTime,
      isTimeRequired: true,
      errorMessage: this.localization.captions.bookAppointment.err_missingBreakStartTime
    };
    this.endTimeInputs = {
      className: '',
      form: this.FormGrp,
      formControlName: 'endTime',
      placeHolder: this.captions.BreakEndTime,
      isTimeRequired: true,
      minTime: this.FormGrp.controls.startTime.value,
      errorMessage: this.localization.captions.bookAppointment.err_missingBreakEndTime
    };
    this.breakPointNumber=this.BP.GetBreakPoint([SPAManagementBreakPoint.paidUnpaidProfile]); 
    this.paidUnpaidToggle = this.breakPointNumber.result[0].allow;
    this.GetActivetherapists("getActiveTherapist");
    this.GetBreakTypes();
    this.breakDuration =
      [{ "minutes": 15, "value": `15 ${this.captions.Minutes}` },
      { "minutes": 30, "value": `30 ${this.captions.Minutes}` },
      { "minutes": 45, "value": `45 ${this.captions.Minutes}` },
      { "minutes": 60, "value": `60 ${this.captions.Minutes}` }];
    this.currentTime = this.localization.LocalizeTime(this.PropertyInfo.CurrentDate);
    this.FormGrp.controls.startTime.setValue(this.currentTime);
    this.endTimeInputs.minTime = this.FormGrp.controls.startTime.value;
    this.endTimeInputs = {...this.endTimeInputs}
    this.showTime = this.localization.getTime(this.utils.getDate(this.localization.ConvertDateToISODate(this.PropertyInfo.CurrentDate) + " " + this.localization.DeLocalizeTime(this.currentTime)), 24);
  }

  openPicker(e){
    this.endTimeInputs.minTime = this.FormGrp.controls.startTime.value;
    this.endTimeInputs = {...this.endTimeInputs}
  }

  onStartTimeSet(e) {
    this.FormGrp.controls.startTime.markAsDirty();
    this.FormGrp.controls.startTime.setValue(this.localization.LocalizeTime(this.utils.TimeToPropertyDateTime(e)));
  }

  onEndTimeSet(e) {
    this.FormGrp.controls.endTime.markAsDirty();
    this.FormGrp.controls.endTime.setValue(this.localization.LocalizeTime(this.utils.TimeToPropertyDateTime(e)));
  }
  isClosed(event) {
    
    if (!this.IsValidTimeForBreak(this.FormGrp.controls.startTime.value,this.FormGrp.controls.endTime.value)) {
      this.FormGrp.controls.endTime.setErrors({ incorrect: true });
      this.FormGrp.controls.endTime.markAsDirty();
      this.FormGrp.controls.endTime.markAsTouched();
      return;
    } else {
      this.FormGrp.controls.endTime.setErrors(null);
    }
   
    

  }
  IsValidTimeForBreak(fromTime, toTime): boolean {
    
      
    if (this.localization.TimeToDate(fromTime).getTime() < this.localization.TimeToDate(toTime).getTime()) {
        return true;
    }
    return false;
}

  async CreateTherapistBreak() {
    const therapistId = this.FormGrp.controls.therapistName.value;
    const tempBreak = this.FormGrp.controls['breakType'].value == '' ? 0 : this.FormGrp.controls['breakType'].value;
    const breakType = this.FormGrp.controls['breakType'].value ? tempBreak : 0;
    const comments = this.FormGrp.controls['comments'].value.length > 0 ? this.FormGrp.controls['comments'].value : null;
    const fromTime = this.localization.ConvertDateToISODateTime(this.utils.getDate(this.utils.formatDate(this.PropertyInfo.CurrentDate) + "T" + this.FormGrp.controls['startTime'].value));
    const toTime = this.localization.ConvertDateToISODateTime(this.utils.getDate(this.utils.formatDate(this.PropertyInfo.CurrentDate) + "T" + this.FormGrp.controls['endTime'].value));
    const paidOptions=  this.FormGrp.controls['paidOptions'].value == '' ? 0 : this.FormGrp.controls['paidOptions'].value;
    const isInstructorSchedule = this.data?.datarecord?true:false;
    if(paidOptions==PaidOptions.paid)
    {
      this.paid=true;
      this.unpaid=false;
    }
    else{
      this.paid=false;
      this.unpaid=true;
    }
    const bodyObj: any = {
      breakType: breakType, comments: comments, fromTime: fromTime, toTime: toTime,paid: this.paid, unpaid: this.unpaid,isInstructorSchedule:isInstructorSchedule
    };
    var callDesc=isInstructorSchedule ?"CreateInstructorBreak":"CreateTherapistBreak";
    var uriParam=isInstructorSchedule ?{instructorId: therapistId}:{therapistId: therapistId};
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      method: HttpMethod.Post,
      body: bodyObj,
      uriParams: uriParam,
      showError: false,
      extraParams: []
    });

  }

  async saveAddBreak() {
    const quickIdConfig = this._retailPropertyInfo.getQuickIdConfig;
    if (quickIdConfig && quickIdConfig.QUICKID_THERAPISTBREAKCREATE) {
      const quickLoginDialogRef = this.commonUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          await this.CreateTherapistBreak();
        }
      });
    } else {
      await this.CreateTherapistBreak();
    }
  }

  GetActivetherapists(routeURL: string) {
    var keyValue = { key: "isInstructor", value:  [this.data?.datarecord || false]};
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: routeURL,
      method: HttpMethod.Get,
      showError: true,
      extraParams: [],
      queryString: keyValue
    });
  }
  GetBreakTypes() {
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetBreakTypes",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }
  paidOptionsChange(id) {
    
    this.SetPaidOptionValue(id);
    this.selectedPaidOptions = id;
    this.FormGrp.controls.paidOptions.markAsTouched();
  }
  
  SetPaidOptionValue(paidswitchvalue) {
    switch (paidswitchvalue) {
      case PaidOptions.paid:
        this.paidOptions[0].checked = true;
        this.paidOptions[1].checked = false;      
        this.selectedPaidOptions = PaidOptions.paid;       
        break;
      case PaidOptions.unpaid:
        this.paidOptions[0].checked = false;
        this.paidOptions[1].checked = true;       
        this.selectedPaidOptions = PaidOptions.unpaid;      
        break;    
      default:      
        break;
    }
  }
  Close() {
    this.dialogRef.close();
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string): void {
    if (callDesc == "GetBreakTypes") {
      this.breakType = <any>result.result;
    }
    if (callDesc == "CreateTherapistBreak" || callDesc =="CreateInstructorBreak") {
      this.commonUtils.resetQuickIdDetails();
      this.dialogRef.close(result);
    }
    if (callDesc == "getActiveTherapist") {
      this.therapists = <any>result.result;
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string): void {
    if (callDesc == "CreateTherapistBreak" || callDesc =="CreateInstructorBreak") {
      this.commonUtils.resetQuickIdDetails();
      var errorMsg = "Unkown Error";
      var errorList: any = error.result;
      var errors = Object.keys(errorList);
      if (errors && errors.length > 0) {
        errorMsg = "";
        for (let i = 0; i < errors.length; i++) {
          const element = errorList[errors[i]];
          errorMsg += this.localization.getError(element) + "<br><br>"
        }
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorMsg);
      }
    }
  }

}
