import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginState } from 'src/app/eatecui/source/store/states/login-state';
import { AppState } from '../states/app-state';

const loginSelector = (state: AppState) => state.LoginState;
export const verifyUserSuccess = createSelector(loginSelector, (state: LoginState) => state.GetUserLoginSuccess);
export const verifyUserFailure = createSelector(loginSelector, (state: LoginState) => state.GetUserLoginFailure);
export const GetClientIdSuccess = createSelector(loginSelector, (state: LoginState) => state.GetClientIdSuccess);
export const GetClientIdFailure = createSelector(loginSelector, (state: LoginState) => state.GetClientIdFailure);
export const GetTokenSuccess = createSelector(loginSelector, (state: LoginState) => state.GetTokenSuccess);
export const GetTokenFailure = createSelector(loginSelector, (state: LoginState) => state.GetTokenFailure);
export const GetSSOClientIdSuccess = createSelector(loginSelector, (state: LoginState) => state.GetSSOClientIdSuccess);
export const GetSSOClientIdFailure = createSelector(loginSelector, (state: LoginState) => state.GetSSOClientIdFailure);
export const GetSSOTokenSuccess = createSelector(loginSelector, (state: LoginState) => state.GetSSOTokenSuccess);
export const GetSSOTokenFailure = createSelector(loginSelector, (state: LoginState) => state.GetSSOTokenFailure);
