import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Localization } from 'src/app/common/localization/localization';
import { COMPRESSION_LIMIT } from '../../constants';
import { remove } from "lodash";
import { MultiImageUpload } from '../../Models/ag-models';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'ag-image-multi-upload',
  templateUrl: './ag-image-multi-upload.component.html',
  styleUrls: ['./ag-image-multi-upload.component.scss'],
  providers: [NgxImageCompressService],
  encapsulation: ViewEncapsulation.None
})

export class AgImageMultiUploadComponent implements OnInit {
  infoText: string;

  constructor(private imageCompress: NgxImageCompressService, private _Localization: Localization,private utilities: CommonUtilities) { }
  file: any;
  uploadedfileName: string;
  imgResultAfterCompress: string;
  sizeOFCompressedImage: number;
  sizeOFCompressedthumbNail: number;
  maxImageUploaded: boolean = false;
  FileSizeExceeded: boolean = false;
  captions: any;
  imageData: string = null;
  thumbNailData: string = null;
  imageArray: MultiImageUpload[] = [];
  checkDuplicateFile: boolean;
  dupErrorList: string[] = [];
  fileSizeErrorList: string[] = [];
  @ViewChild('inputFile') inputFile: ElementRef;

  @Input() maxImage: number;
  @Input() disableControl;
  @Input() showDropArea = false;
  @Input() maxFileSize = COMPRESSION_LIMIT;
  @Output() compressedImage = new EventEmitter();
  @Output() imageDeleted = new EventEmitter();
  @Output() fileSizeExceeded = new EventEmitter();

  @Input('imageInput')
  set imageInput(value) {
    if (value) {
      if (value.length > 0 && value.length >= this.maxImage) {
        this.imageArray = value.slice(0, this.maxImage);
      } else {
        this.imageArray = value;
      }

      this.maxImageUploaded = this.imageArray.length >= this.maxImage ? true : false;
    }
  }

  selectFile(arg: any) {
    this.maxImageUploaded = false;
    this.file = arg ;
    this.dupErrorList = [];
    this.fileSizeErrorList = [];
    const files = arg;
    let completedCount = 0;
    if (files) {      
      this.maxImageUploaded = (this.imageArray.length + files.length) > this.maxImage;
      if(this.maxImageUploaded) this.utilities.showCommonAlert(this.captions.MaximumPicturesUploaded+" "+this.maxImage.toString()+this.captions.lbl_fileCaption, AlertType.Info);
      else {
        for (let i = 0; i < files.length; i++) { 
          let reader = new FileReader();
          reader.onload = (eve: any) => {
            completedCount++;       
            const fileSize = files[i]['size'] / (1024);
            let checkDuplicate = this.imageArray.length > 0 && this.imageArray.find(x => x.filename == files[i]['name']);
            this.FileSizeExceeded = false;
            //this.maxImageUploaded = (this.imageArray.length + files.length) > this.maxImage;
            if (!checkDuplicate) {
              this.checkDuplicateFile = false;
              if (fileSize >= this.maxFileSize) {
                //this.maxImageUploaded = this.imageArray.length >= this.maxImage ? true : false;
                this.FileSizeExceeded = true;
                this.fileSizeErrorList.push(`${this.captions.lbl_fileSizeExceeded}: ${files[i]['name']}`)
              
                this.fileSizeExceeded.emit();
              }
             
               else {           
                if(!this.maxImageUploaded){
                let fullImageCompress = this.compressFile(eve.target.result, files[i]['name']);
                let thumbNailImageCompress = this.compressThumbnail(eve.target.result);
                Promise.all([fullImageCompress, thumbNailImageCompress]).then(res => {
                  this.imageArray.push({
                    image: this.imgResultAfterCompress,
                    imageSize: this.sizeOFCompressedImage,
                    thumbnail: this.thumbNailData,
                    thumbnailSize: this.sizeOFCompressedthumbNail,
                    filename: this.uploadedfileName
                  });
                  this.maxImageUploaded = this.imageArray.length >= this.maxImage ? true : false;
                  
                  this.compressedImage.emit(this.imageArray);
                });
              }
              else
              {
                this.utilities.showCommonAlert(this.captions.MaximumPicturesUploaded+" "+this.maxImage.toString()+this.captions.lbl_fileCaption, AlertType.Info);
                
              }
            }
            } else {
              this.checkDuplicateFile = true;
              this.dupErrorList.push(`${this.captions.lbl_duplicateFile}: ${files[i]['name']}`);
             
            }
          } 
          reader.onloadend=(event)=>{
                if (this.inputFile && completedCount === files.length) {
                this.inputFile.nativeElement.value = null;
                }
          }      
          if(files[i].type.indexOf("image") !== -1 && files[i].type.search("^image\/(png|jpg|jpeg|gif)$") !== -1){
            reader.readAsDataURL(files[i]);
          }
          else{
            this.utilities.showCommonAlert(this.captions.lbl_validPhotoFormat, AlertType.Info);
          }
          }
      }
      
    }
  }

  compressFile(image, fileName) {
    let orientation = -1;
    return this.imageCompress.compressFile(image, orientation,100, 100).then(result => {
      this.imgResultAfterCompress = result;
      this.FileSizeExceeded = false;
      this.sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024);
      const imageFile = new File([result], fileName, { type: 'image/jpeg' });
      this.uploadedfileName = imageFile['name'];
    });

  }

  compressThumbnail(image) {
    return this.imageCompress.compressFile(image, 1, 100, 100).then(result => {
      this.sizeOFCompressedthumbNail = this.imageCompress.byteCount(result) / (1024);
      this.thumbNailData = result;
    });

  }

  deleteUpload(index) {
    remove(this.imageArray, this.imageArray[index]);
    this.maxImageUploaded = this.imageArray.length >= this.maxImage ? true : false;
    this.FileSizeExceeded = false;
    this.imageData = null;
    this.checkDuplicateFile = false;
    if (this.inputFile)
      this.inputFile.nativeElement.value = null;
    this.imageDeleted.emit(this.imageArray);
  }

  ngOnInit() {
    this.captions = this._Localization.captions;
    this.infoText = this._Localization.replacePlaceholders(this.captions.lbl_imageUploadInfo, ['type', 'sizeinMB', 'count'], ['JPEG / PNG', (this.maxFileSize/1024).toString(), this.maxImage.toString()]);
  }
}