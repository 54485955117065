import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Localization } from "src/app/common/localization/localization";
import { ButtonValue, AgToggleConfig } from "src/app/common/Models/ag-models";
import { DialogCloseEnum } from "src/app/common/enums/shared-enums";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaffReaderBoardBusiness } from '../../staff-reader-board/staff-reader-board.business';
import { StaffReaderBoardDataService } from '../../dataservices/staff-reader-board-data.service';
import { API, UI, NoteSendToSelect } from '../../Models/staff-reader-board-model';
import { UserRoleDataService } from '../../dataservices/user-roles-data.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { SnackBarType } from '../../shared/shared/enums/enums';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from '../../Models/common.models';
@Component({
  selector: 'app-list-of-notes',
  templateUrl: './list-of-notes.component.html',
  styleUrls: ['./list-of-notes.component.scss'],
  providers:[StaffReaderBoardBusiness, StaffReaderBoardDataService, UserRoleDataService],
  encapsulation: ViewEncapsulation.None
})
export class ListOfNotesComponent implements OnInit {

  captions: any;
  title: string;
  DialogCloseEnum = DialogCloseEnum;
  acknowledgeButton: ButtonValue;
  cancelButton: ButtonValue;
  filterButton: ButtonValue;
  readerBoard: ButtonValue;
  listOfNotes: UI.ViewAllNotes[] =[];
  note:UI.ViewAllNotes;
  priorityList: any;
  staffreaderForm: UntypedFormGroup;
  propertyDate: Date;
  maxStartDate: Date;
  minStartDate: Date;
  maxEndDate: Date;
  minEndDate: Date;
  selectedid: any = 0;
  showNoteAckToggleInput: AgToggleConfig;
  snoozerData: any;
  currentProductId: number;

  constructor(
    private router: Router,
    private localization: Localization,
    private dialogRef: MatDialogRef<ListOfNotesComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private staffReaderBoardBusiness: StaffReaderBoardBusiness,
    private fb:UntypedFormBuilder,
    private PropertyInfo: PropertyInformation,
    private utilities: CommonUtilities,
  ) {
    this.captions = this.localization.captions;
    this.title = this.captions.list_of_notes;
    this.priorityList = this.staffReaderBoardBusiness.getPriorityList();
    this.propertyDate = this.maxStartDate = this.minStartDate = this.maxEndDate = this.minEndDate = this.PropertyInfo.CurrentDTTM;
    this.snoozerData = this.staffReaderBoardBusiness.getSnoozeTimer();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.GetAllNotes();
  }

  initializeForm(){
    this.staffreaderForm = this.fb.group({
      priority: 0,
      showNoteAck: false,
      startDate: this.propertyDate,
      endDate: this.propertyDate
    })

    this.acknowledgeButton = {
      type: "primary",
      label: this.captions.acknowledge,
      disabledproperty: false,
    };
    this.cancelButton = {
      type: "secondary",
      label: this.captions.btn_cancel,
    };

    this.filterButton = {
      type: "primary",
      label: this.captions.grp_hdr_filter,
      disabledproperty: false,
    };

    this.readerBoard = {
      type: "primary",
      label: this.captions.grp_hdr_staffReader,
      disabledproperty: false,
    };

    this.showNoteAckToggleInput = {
      group: this.staffreaderForm,
      formControlName: 'showNoteAck',
      label: this.captions.lbl_show_unacknowledged,
      className:'ag_pl--2'
    };
    this.staffreaderForm.valueChanges.subscribe(x => {
      if ((this.staffreaderForm.value.startDate == "" || this.staffreaderForm.value.startDate == null) ||
      (this.staffreaderForm.value.endDate == "" || this.staffreaderForm.value.endDate == null) ){
        this.filterButton.disabledproperty = true;
        this.filterButton = {...this.filterButton};
      }
      else
      {
        this.filterButton.disabledproperty = false;
        this.filterButton = {...this.filterButton};
      }
    });
  }
  
  async GetAllNotes() {
    this.listOfNotes = await this.staffReaderBoardBusiness.GetAllNotes();

    this.listOfNotes.forEach(x=> {
      x.sentOn = this.localization.LocalizeDate(x.sentOn) + ' ' + this.localization.LocalizeTime(x.sentOn);
      x.acknowledgedOn = this.validateAcknowledgedOn(x.acknowledgedOn);
      x.priorityName = this.priorityList.filter(y=> y.id == x.priority)[0].viewValue
      x.snoozeTime =   this.snoozerData.filter(y => y.timeInminutes == x.intervalTime).length >0 ? this.snoozerData.filter(y => y.timeInminutes == x.intervalTime)[0].value : '';
    });

     if(this.listOfNotes){
      this.onViewNotes(this.listOfNotes[0]);
    }
  }

  startdateChange(eve){
    this.minEndDate = this.localization.getDate(this.staffreaderForm.controls['startDate'].value);
  }
  enddateChange(eve){
    this.maxStartDate = this.localization.getDate(this.staffreaderForm.controls['endDate'].value);
  }

  inputDateChange(eve,formControl) {
    //this.staffreaderForm.controls[formControl].setValue(eve.target.value);
    if(formControl === 'startDate')
    {
      if (((this.staffreaderForm.value.endDate == "" || this.staffreaderForm.value.endDate == null) ||
        eve.target.value == "") && this.staffreaderForm.valid){
          this.setFilterDisabledProperty(true);
      }
      else
      {
        this.setFilterDisabledProperty(false);
      }
    }
    else{
      if (((this.staffreaderForm.value.startDate == "" || this.staffreaderForm.value.startDate == null) ||
        eve.target.value == "")&& this.staffreaderForm.valid){
          this.setFilterDisabledProperty(true);
      }
      else
      {
        this.setFilterDisabledProperty(false);
      }
    }
  }

  setFilterDisabledProperty(value)
  {
    this.filterButton.disabledproperty = value;
    this.filterButton = {...this.filterButton};
  }

  onViewNotes(note) {
    this.note = note;
    this.listOfNotes.forEach((notes) => {
      notes.isClicked = (notes.id === note.id);
    });

    if (this.note != undefined && !this.note.isViewed) {
      let noteId = note.id;
      this.staffReaderBoardBusiness.UpdateStaffReaderBoardDetailsIsViewed(noteId).then(x => {
        this.note.isViewed = true;
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onAcknowledge(eve) {
    let noteId = eve.id;
    this.staffReaderBoardBusiness.UpdateStaffReaderBoardDetailsIsAcknowledged(noteId).then(x => {
      this.note.isAcknowledged = true;
      eve.isAcknowledged = true;
      eve.acknowledgedOn = this.localization.LocalizeDate(this.propertyDate) + ' ' + this.localization.LocalizeTime(this.propertyDate);
      this.GetAllNotes();
    });
  }

  selectedPriority(id){
    this.staffreaderForm.get('priority').setValue(id);
    this.selectedid = id;
  }

  funtionCheck(id){
    if(this.selectedid == id){
      return 'priority_'+id
    }
  }

 async onStaffReader(){
  this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
  if(this.currentProductId == Product.SPA || this.currentProductId == Product.GOLF){
    let result = await this.staffReaderBoardBusiness.ValidateBreakPointsfromNotes();
    if(result.isAllow || result.isViewOnly)
    {
      this.dialogRef.close();
      this.router.navigate(['/settings/utilities/staffreaderboard']); 
    }
    else{
      return;
    }
  }
  else{
    this.dialogRef.close();
    this.router.navigate(['/settings/utilities/staffreaderboard']); 
  }
 }

 async onFilter()
  {
    let staffReaderBoardFilterRequest: API.StaffReaderBoardFilterRequest = {
      fromDate: this.localization.convertDateTimeToAPIDateTime(this.staffreaderForm.value.startDate),
      toDate: this.localization.convertDateTimeToAPIDateTime(this.staffreaderForm.value.endDate),
      priority: this.staffreaderForm.value.priority,
      isNotAcknowledged: this.staffreaderForm.value.showNoteAck
    } as API.StaffReaderBoardFilterRequest;

    this.listOfNotes = await this.staffReaderBoardBusiness.FilterAllNotes(staffReaderBoardFilterRequest);

    this.listOfNotes.forEach(x=> {
      x.sentOn = this.localization.LocalizeDate(x.sentOn) + ' ' + this.localization.LocalizeTime(x.sentOn);
      x.acknowledgedOn = this.validateAcknowledgedOn(x.acknowledgedOn);
      x.priorityName = this.priorityList.filter(y=> y.id == x.priority)[0].viewValue;
      x.snoozeTime =   this.snoozerData.filter(y => y.timeInminutes == x.intervalTime).length >0 ? this.snoozerData.filter(y => y.timeInminutes == x.intervalTime)[0].value : '';
     });
     if(this.listOfNotes){
      this.onViewNotes(this.listOfNotes[0]);
    }
  }

  setSnoozeTimer(note, snoozeValue) {
    note.snoozeTime = snoozeValue.value
    if (note.snoozeTime) {
      note.isSnoozed = true
    }

    this.staffReaderBoardBusiness.UpdateStaffReaderBoardDetailsIsSnoozed(note.id, note.isSnoozed, snoozeValue.timeInminutes).then(x => {
      this.utilities.showToastMessage(this.captions.lbl_note_snoozed_for.replace('{snoozeTime}', note.snoozeTime), SnackBarType.Success, 3000);
    });
  }

  removeSnoozeTimer(category) {
    category.isSnoozed = false
    category.snoozeTime = ''

    this.staffReaderBoardBusiness.UpdateStaffReaderBoardDetailsIsSnoozed(category.id, category.isSnoozed, 0).then(x => {
      this.utilities.showToastMessage(this.captions.lbl_note_snoozed_removed, SnackBarType.Success, 3000);
    });
  }

  validateAcknowledgedOn(acknowledgedOn): string | Date {
    if (acknowledgedOn != null && acknowledgedOn != undefined && acknowledgedOn != '') {
      acknowledgedOn = this.localization.LocalizeDate(acknowledgedOn) + ' ' + this.localization.LocalizeTime(acknowledgedOn);
      return acknowledgedOn;
    }
    else {
      return "";
    }
  }
}
