import { GridHeaderConfig } from '../app-grid.interface';
import { CellRenderer } from '../grid.interface';

export const GridColumnCellRenderer: CellRenderer = (ColumnHeaders: GridHeaderConfig, ReplaceTextWithIcon): string => {
    try {
        const ReplacableObject = ReplaceTextWithIcon.filter(x => x.OdataKey.toLowerCase() === ColumnHeaders.field.toLowerCase())[0];
        if (ReplacableObject) {
            return ReplacableObject.RendererName;
        } else {
            return '';
        }
    } catch (error) {
        console.error('Error occurred in GridColumnCellRenderer', error);
    }
};