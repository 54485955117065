import { Component, Input, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-ghost-list',
  templateUrl: './ghost-list.component.html',
  styleUrls: [
    './ghost-list.scss',
    './ghost.scss'
  ]
})
export class GhostListComponent {
   ghosts: Array<any>;
    constructor() {
        this.ghosts = [1, 3, 5, 4, 3, 0, 0, 1, 1];
    }
}
