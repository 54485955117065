import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { MasterManagementService } from 'src/app/eatecui/source/setup-master/shared/service/master.service';
import { FileData, FilePostConfig, FileUploadData, UploadFileMetaData, UploadMetaData } from './upload-configuration.interface';
import { stubFalse } from 'lodash';

export const FileDataPost: FilePostConfig = ( UploadData: Array<UploadMetaData>, httpService: HttpService, 
 toastrService ?: ToastrService, masterService?: MasterManagementService): void => {
    try {
       // changes for large metadata payload to API
        const uploadFileMetaData: Array<UploadFileMetaData> = [];
        if ( UploadData && UploadData.length > 0 ) {
          UploadData.forEach(( uploadMeata: UploadMetaData) => {
            const UploadMetaPayload = {} as UploadFileMetaData;
            UploadMetaPayload.fileName = uploadMeata.FileName;
            UploadMetaPayload.blocksCount = uploadMeata.BlocksCount;
            UploadMetaPayload.fileSize = uploadMeata.FileSize;
            UploadMetaPayload.contentType = uploadMeata.ContentType;
            UploadMetaPayload.groupId = uploadMeata.GroupId;
            UploadMetaPayload.itemId = uploadMeata.ItemId;
            UploadMetaPayload.sortOrder = uploadMeata.SortOrder;
            UploadMetaPayload.isFavourite = uploadMeata.IsFavourite;
            UploadMetaPayload.cloudFileName = uploadMeata.CloudFileName;
            UploadMetaPayload.isMetaCreated = uploadMeata.IsMetaCreated;
            UploadMetaPayload.isTransaction = uploadMeata.IsTransaction;
            UploadMetaPayload.fileDetails= uploadMeata?.FileData?.Base64string;
            uploadFileMetaData.push(UploadMetaPayload);
          });
        }
        const blockLength = FileData.BlockLength;
        if ( UploadData && UploadData.length > 0 ) {
          const FileMetaData = '/inventory/api/File/MetaData';
          const contentType = 'multipart/form-data';
          const form_data: FormData = new FormData();
          httpService.PostFilehttpMethod(FileMetaData, uploadFileMetaData, null, false, contentType).subscribe(responseData => {
            if ( responseData && responseData.length > 0 ) {
                // UploadData.forEach((uploadData: UploadMetaData) => {
                  responseData.forEach((upload: UploadMetaData, index) => {
                    const progressionToast = toastrService.info('Inprogress', upload.FileName, {
                      progressAnimation: 'increasing',
                      progressBar: true,
                      positionClass: 'toast-bottom-right'
                   });
                    const blockId = 1;
                    // eslint-disable-next-line max-len
                    const uploadData: UploadMetaData = UploadData.filter((FilUploadData: UploadMetaData) => FilUploadData.FileName === upload.FileName)[0];
                    if (upload.IsMetaCreated === true) {
                      const startChunk = 0;
                      const endChunk = Math.min(FileData.BlockLength, uploadData.FileSize);
                      sendFileNew(uploadData, upload, blockId, httpService, startChunk, endChunk, index, responseData.length,
                         masterService, progressionToast);
                    } else {
                      alert('Upload Failed');
                    }
                  });
                // });
              }
            });
        }
    } catch (error) {
        console.error(error);
    }
};

const sendFile = async (uploadData: UploadMetaData, chuckblock: any, blockId: any, httpService: HttpService, chunkStart = 0,
endChunk = 0, fileIndex: number, totalFileLength: number, masterService: MasterManagementService, progressionToast) => {
    const fileData = uploadData.FileData;
    const fileUploadData = {} as FileUploadData;
    fileUploadData.blockId = blockId;
    fileUploadData.fileName = chuckblock.CloudFileName; // File Name is Block Id
    let start = chunkStart;
    // let end = Math.min(FileData.BlockLength, uploadData.FileSize);
    let end = endChunk;
    const retryCount = 0;
    const sendNextChunk = '';
    let fileChunk: any;
    fileChunk = new FormData();
    
    if (fileData.slice) {
      fileChunk.append('Slice', uploadData.FileData.slice(start, end));
    console.log(fileChunk);
    } else if (fileData.webkitSlice) {
      
     fileChunk.append('Slice', fileData.webkitSlice(start, end));
    } else if (fileData.mozSlice) {
     
     fileChunk.append('Slice', fileData.mozSlice(start, end));
    }
    
    // Needed for Post File Meta Data
    // const MetaDataFileKey = Object.keys(chuckblock);
    // MetaDataFileKey.forEach(keys => {
    //   fileChunk.append(keys, chuckblock[keys]);
    // });
    // console.log('FileChunks', fileChunk);
    const UploadFileUrl = `/inventory/api/File/Upload?fileName=${fileUploadData.fileName}&blockId=` + blockId;
    const contentType = 'multipart/form-data';
    const payload = {
      "fileDetails":uploadData?.FileData?.Base64string
    }
    
    const ss = await httpService.PostFilehttpMethod(UploadFileUrl,fileChunk, null, true, contentType).toPromise();
      ++blockId;
        start = (blockId - 1) * FileData.BlockLength;
        end = Math.min(blockId * FileData.BlockLength, uploadData.FileSize);
        (<any>progressionToast).toastRef.componentInstance.updateProgress = () => {
          (<any>progressionToast).toastRef.componentInstance.width = ( blockId / uploadData.BlocksCount ) * 100;
        };
        if (blockId <= uploadData.BlocksCount && uploadData.BlocksCount === chuckblock.BlocksCount) {
            sendFile(uploadData, chuckblock, blockId, httpService, start, end, fileIndex,
               totalFileLength, masterService, progressionToast);
        } else {
          if (fileIndex === (totalFileLength - 1)) {
            masterService.getPictureDetails.next(true);
          }
        }

};


//new sendfile
const sendFileNew = async (uploadData: UploadMetaData, chuckblock: any, blockId: any, httpService: HttpService, chunkStart = 0,
  endChunk = 0, fileIndex: number, totalFileLength: number, masterService: MasterManagementService, progressionToast) => {
      const fileData = uploadData.FileData;
      const fileUploadData = {} as FileUploadData;
      fileUploadData.blockId = blockId;
      fileUploadData.fileName = chuckblock.CloudFileName; // File Name is Block Id
      let start = chunkStart;
      // let end = Math.min(FileData.BlockLength, uploadData.FileSize);
      let end = endChunk;
      const retryCount = 0;
      const sendNextChunk = '';
      let fileChunk: any;
      fileChunk = new FormData();
      console.log('uploadData.FileData',uploadData.FileData)
      if (fileData.slice) {
        fileChunk.append('Slice', uploadData.FileData.slice(start, end));
      } else if (fileData.webkitSlice) {
       fileChunk.append('Slice', fileData.webkitSlice(start, end));
      } else if (fileData.mozSlice) {
       fileChunk.append('Slice', fileData.mozSlice(start, end));
      }
      
      // Needed for Post File Meta Data
      // const MetaDataFileKey = Object.keys(chuckblock);
      // MetaDataFileKey.forEach(keys => {
      //   fileChunk.append(keys, chuckblock[keys]);
      // });
      // console.log('FileChunks', fileChunk);
      const UploadFileUrl = `/inventory/api/File/new-upload?fileName=${fileUploadData.fileName}&blockId=` + blockId;
      const contentType = '';
      const payload = {
        "fileDetails":uploadData?.FileData?.Base64string
      }
      
      const ss = await httpService.PostFilehttpMethod(UploadFileUrl,payload, null, false, contentType).toPromise();
        ++blockId;
          start = (blockId - 1) * FileData.BlockLength;
          end = Math.min(blockId * FileData.BlockLength, uploadData.FileSize);
          (<any>progressionToast).toastRef.componentInstance.updateProgress = () => {
            (<any>progressionToast).toastRef.componentInstance.width = ( blockId / uploadData.BlocksCount ) * 100;
          };
          if (blockId <= uploadData.BlocksCount && uploadData.BlocksCount === chuckblock.BlocksCount) {
            sendFileNew(uploadData, chuckblock, blockId, httpService, start, end, fileIndex,
                 totalFileLength, masterService, progressionToast);
          } else {
            if (fileIndex === (totalFileLength - 1)) {
              masterService.getPictureDetails.next(true);
            }
          }
  
  };
//
function x( e: any, arg1: (any: any) => void) {
  throw new Error(e);
}

