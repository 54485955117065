import { Component, forwardRef, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SpaLocalization } from '../../core/localization/spa-localization';


@Component({
  selector: 'app-spa-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpaToggleSwitchComponent),
      multi: true
    }
  ]
})
export class SpaToggleSwitchComponent implements ControlValueAccessor {
  @Output() changeToggleEvent: EventEmitter<any> = new EventEmitter();
  @Input() disableToggle: boolean = false;
  @Input() automationId: string = '';
  @Input('value') _value = false;
  onChange = (value: any) => {
    //the  onChange
  };
  onTouched = () => {
    //the onTouched
  };
  localisation: any;

  constructor(private ls: SpaLocalization) {
    this.localisation = ls;
    this.disableToggle = this.disableToggle != undefined ? this.disableToggle : false
  }

  get name() {
    return this._value;
  }

  set name(val) {
    this._value = val;

  }


  writeValue(value): void {
    if (value || value == "true") {
      this.name = true;
    }
    else {
      this.name = false;
    }

  }

  setDisabledState(isDisabled: boolean): void {


    this.disableToggle = isDisabled;
  }

  toggleChange($event) {
    this.name = !this.name;
    this.changeToggleEvent.emit([$event]);
    this.onChange($event);
    this.onTouched();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


}
