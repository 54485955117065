import { Component, OnInit, Input, OnChanges, AfterViewChecked, ViewEncapsulation, Output, EventEmitter, ViewChild } from '@angular/core';
import { SPAConfig } from '../../common/config/SPA-config';
@Component({
  selector: 'app-retail-inventory-table',
  templateUrl: './inventory-table.component.html',
  styleUrls: ['./inventory-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailInventoryTableComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() tableOptions;
  @Input() isScannedAvailable: boolean;
  @Input() isScannedInput: boolean;
  @Input() searchInput;
  @Input() scrollPositionReset;
  @Output() scrollEmitter: EventEmitter<any> = new EventEmitter();
  @Output() sortEmitter: EventEmitter<any> = new EventEmitter();
  tableHeaderData: any = [];
  tableBodyData: any = [];
  searchableData: any = [];
  viewCheckedFlag: boolean = false;
  searchText: string;
  commonCaptions: any;
  inventoryCaptions: any;
  selectedDefaultHeader: any;
  orderType: any = 'asc';
  defaulSortColumn: any;
  isDataLoading: boolean;
  hideScroll: boolean;
  bufferCount: any;
  @ViewChild('scroll') scrollvalue;
  constructor(private spaConfig: SPAConfig) { }

  ngOnInit() {
    this.commonCaptions = this.spaConfig.captions.common;
    this.inventoryCaptions = this.spaConfig.captions.retailInventory;
    if (this.tableOptions.length > 0) {
      this.bufferCount = this.tableOptions[0].bufferCount;
      this.defaulSortColumn = this.tableOptions[0].Sortable;
      this.selectedDefaultHeader = this.tableOptions[0].Sortable;
    }
  }

  ngOnChanges() {
    this.searchableData = [];
    this.viewCheckedFlag = false;

    if (this.tableOptions && this.tableOptions.length > 0) {
      this.bufferCount = this.tableOptions[0].bufferCount;
      this.defaulSortColumn = this.tableOptions[0].Sortable;
      this.tableHeaderData = this.tableOptions[0].headerData.filter(title => this.isScannedAvailable ? title : title.jsonKey !== 'countedQuantity');
      this.tableBodyData = this.tableOptions[0].bodyData;
      if (this.tableHeaderData) {
        for (let head of this.tableHeaderData) {
          if (head.searchable != undefined ? head.searchable : true)
            this.searchableData.push(head.jsonKey);
        }
      }
      this.selectedDefaultHeader = this.selectedDefaultHeader ? this.selectedDefaultHeader : this.tableOptions[0].Sortable;
      this.isDataLoading = false;
    }
    else {
      this.isDataLoading = true;
    }
    this.searchText = this.searchInput;
    setTimeout(() => {
      if (this.scrollPositionReset) this.scrollvalue.scrollToIndex(-1);
    }, 1);
  }

  ngAfterViewChecked() {
    if (!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
    }
  }

  copyData(event) {
    console.log(event);
    this.tableBodyData = this.tableBodyData.map(result => {
      result.ScannedQuantity = 0;  
      result.countedQuantity = result.frozenQuantity;
      return result;
    });
  }

  sortingFn(selectedKey, index, defaultHeader, sortable) {
    if (sortable) {
      if (defaultHeader == selectedKey) {
        this.orderType = this.orderType == 'asc' ? 'desc' : 'asc';
      }
      else {
        this.orderType = 'asc'
      }
      this.selectedDefaultHeader = selectedKey;
      this.sortEmitter.emit({
        direction: this.orderType,
        header: selectedKey
      });
    }
  }

  ValidateQuantity(bodyData, jsonKey) {
    if (bodyData[jsonKey] == null || bodyData[jsonKey] == ' ') {
      bodyData[jsonKey] = 0;
    }
  }

  scrollEndHandler(arg) {
    if (!this.tableBodyData || !this.tableBodyData.length || arg.endIndex < this.tableBodyData.length - (this.bufferCount + 1)) { return; }
    this.scrollEmitter.emit({
      scrollArg: arg,
      viewPortItems: this.scrollvalue.viewPortItems
    });
  }
}
