import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, UntypedFormControl } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant';
import { NonIntegratedCreditCardService } from './non-integrated-credit-card.service';
import { NonIntegratedCreditCardConfiguration, NonIntegratedCreditCardConstants } from '../../shared/service/payment/payment-model';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { CreditCardIssuerType } from '../../shared/globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CardInputMethod, SurchargeConfiguration, SurchargeConfigurationAPI } from '../../shared/service/payment/payment-business.model';
import { SurchargeConfigurationService } from '../data-service/surcharge-configuration.data.service';
import { PaymentMethods } from '../../shared/business/shared.modals';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-non-integrated-credit-card',
  templateUrl: './non-integrated-credit-card.component.html',
  styleUrls: ['./non-integrated-credit-card.component.scss']
})
export class NonIntegratedCreditCardComponent implements OnInit {
  captions: any = {};
  creditcardInfo: UntypedFormGroup;
  GatewayPairs: UntypedFormGroup;
  config: NonIntegratedCreditCardConfiguration;
  isUserAuthorized: boolean = true;
  isViewOnly: boolean = false;
  isUpdateEnabled: boolean = false;
  featureEnabled: boolean = true;
  functionalities: { [key: string]: boolean } = {};
  showOtherCreditCardToggles = false;
  displayCardInputMethod = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  SurchargeLast: number = 0;
  maxPercentage: number = 100;
  get IsInvalidSurchargeConfig(): boolean {
    return this.surchargeConfig.filter(x => x.isActive && x.isValid && (x.issuerName.length == 0 || (x.isPercentage ? (x.value > 100 || x.value == 0) : x.value == 0))).length > 0;
  }
  get IsBothCardInputsSelected() {
    return (this.creditcardInfo && this.creditcardInfo.controls
      && this.creditcardInfo.controls.cardInputMethod.value == CardInputMethod.All)
  }
  get nonIntegratedCreditCardConfigs(): UntypedFormArray {
    return this.creditcardInfo.get('GatewayConfigPairs.NonIntegratedCreditCardConfigs') as UntypedFormArray;
  }

  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  surchargeConfigCaption: any;
  surchargeConfig: SurchargeConfiguration[] = [];
  decimalMaxLength: number = 2;
  selectAll = false;
  surchargeConfigUpdated: boolean = false;
  floatLabel: string;
  SurchargeConfiguration: SurchargeConfigurationAPI[] = [];

  get showSurchargeConfig() {
    return this.surchargeConfigService?.IsSurchargesEnabled;
  }
  get overAllCBTooltipMsg() {
    return this.surchargeConfig?.every(x => x.isActive) ? this.surchargeConfigCaption?.DeSelectAll : this.surchargeConfigCaption?.SelectAll
  }

  constructor(private localization: RetailLocalization
    , private fb: UntypedFormBuilder
    , private nonIntegratedCreditCardService: NonIntegratedCreditCardService
    , private breakpoint: BreakPointAccess
    , private utilities: RetailUtilities
    , private func: RetailFunctionalityBusiness
    , private surchargeConfigService: SurchargeConfigurationService) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  async ngOnInit() {
    this.captions = this.localization.captions.setting;
    this.surchargeConfigCaption = this.captions.SurchargeConfig;
    await this.surchargeConfigService.GetSurchargeSwitchConfig();
    this.creditcardInfo = this.fb.group({
      enableActivateInterface: false,
      enableIdTech: false,
      GatewayConfigPairs: this.fb.group({
        NonIntegratedCreditCardConfigs: this.fb.array([
          this.fb.group({
            Key: '',
            Value: ''
          })
        ])
      })
    });



    this.validateBreakPoint();
    this.utilities.ToggleLoader(true, this.captions.lbl_processing);

    this.setValidators();
    await this.getCreditCardConfiguration().finally(() => {
      this.utilities.ToggleLoader(false);
    });

    this.isUpdateEnabled = false;

    this.creditcardInfo.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      if (this.creditcardInfo.controls.setCCOverAuthAdjustThreshold && this.localization.currencyToSQLFormat(this.creditcardInfo.controls.setCCOverAuthAdjustThreshold.value) > 100) {
        this.isUpdateEnabled = false;
      }
      else {
        this.isUpdateEnabled = (this.creditcardInfo.valid && this.creditcardInfo.dirty);
      }
    });
    await this.getSurchargeConfig();
  }


  validateBreakPoint(): void {
    this.isUserAuthorized = this.breakpoint.CheckForAccess([myGlobals.RetailBreakPoint.NonIntegratedCreditCards]);
    this.isViewOnly = this.breakpoint.IsViewOnly(myGlobals.RetailBreakPoint.NonIntegratedCreditCards);
  }

  async getCreditCardConfiguration() {
    this.config = await this.nonIntegratedCreditCardService.getNonIntegratedCreditCardConfiguration();
    if (this.config) {
      if (this.config.gatewayValue && this.config.gatewayValue.length > 0) {
        this.nonIntegratedCreditCardConfigs.clear();
      }

      this.patchAdditionalConfigValue(JSON.parse(this.config.gatewayValue));
      this.patchValue();

    }
  }


  patchValue() {

    this.creditcardInfo.patchValue({
      enableActivateInterface: this.config.activateInterface,
      enableIdTech: this.config.enableIdTech

    });
  }
  setValidators() {
    this.creditcardInfo.statusChanges.subscribe(() => {

      if (this.creditcardInfo.valid && this.creditcardInfo.dirty) {
        this.isUpdateEnabled = true;
      }
      else {
        this.isUpdateEnabled = false;
      }
    });
  }

  clearValidators(control: UntypedFormControl) {
    control.setValidators(null);
    control.clearValidators();
    control.updateValueAndValidity();
  }


  getErrorMessage(control: string, index: number) {
    if (control == "GatewayPairs") {
      if (index == 0 && this.creditcardInfo.get('GatewayPairs')['controls'].length == 1) {
        return this.creditcardInfo.get('enableActivateInterface').value ? this.captions.MissingKeyPairValue : '';
      }
    }
  }

  patchAdditionalConfigValue(data: any) {
    if (data && data?.length > 0) {
      const control = this.nonIntegratedCreditCardConfigs as UntypedFormArray;
      let index = control.value.findIndex(x => x.Key === '' && x.Value === '');
      if (index >= 0) {
        control.removeAt(index);
      }
      data.forEach(x => {
        const form = this.fb.group(x);
        control.push(form);
      });
    }
  }

  addGatewayArray(e) {
    const form = this.fb.group({
      Key: '',
      Value: ''
    });
    const control = this.nonIntegratedCreditCardConfigs as UntypedFormArray;
    control.push(form);
  }

  removeGateayArray(e) {
    const control = this.nonIntegratedCreditCardConfigs as UntypedFormArray;
    control.removeAt(e)
    this.isUpdateEnabled = true;
  }

  public isControlValid(controlname: string) {
    const invalid = [];
    const controls = this.creditcardInfo.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid.indexOf(controlname) == -1;
  }




  onActiveChange() {
    this.creditcardInfo.markAsTouched();
    this.creditcardInfo.markAsDirty();
  }
  onCardInputMethodsChange($event) {
    this.creditcardInfo.controls.preferredCardEntryMode.setValue(CardInputMethod.None);
  }
  async save() {
    this.utilities.ToggleLoader(true, this.captions.lbl_processing);
    this.isUpdateEnabled = false;

    const NonIntegratedCreditCardConfig: NonIntegratedCreditCardConfiguration = {
      activateInterface: this.creditcardInfo.get('enableActivateInterface').value,
      enableIdTech: this.creditcardInfo.get('enableIdTech').value,
      gatewayValue: JSON.stringify(this.nonIntegratedCreditCardConfigs.value)
    };

    await this.nonIntegratedCreditCardService.createOrUpdateNonIntegratedCreditCardConfiguration(NonIntegratedCreditCardConfig);
    this.utilities.ToggleLoader(false);
    if (this.surchargeConfigService.IsSurchargesEnabled && this.validateSurchargeChanges()) {
      this.SurchargeConfiguration = [];
      this.SurchargeConfiguration = await this.surchargeConfigService.SaveSurchargeConfigurationsWithCCIssuerType(this.surchargeConfig, this.SurchargeConfiguration);
      this.utilities.ToggleLoader(false);
      this.FormSurchargeConfigGrid(this.surchargeConfigService.mapAPIDataToUIElements(this.SurchargeConfiguration));
      this.surchargeConfigUpdated = false;
    }
    await this.getCreditCardConfiguration();
    this.utilities.ToggleLoader(false);
    this.creditcardInfo.markAsUntouched();
    this.creditcardInfo.updateValueAndValidity();
    this.isUpdateEnabled = false;
    this.updateSurchargeGridControls();
  }

  async cancel() {
    this.utilities.ToggleLoader(true, this.captions.lbl_processing);
    await this.getCreditCardConfiguration();
    await this.getSurchargeConfig();
    this.isUpdateEnabled = false;
    this.utilities.ToggleLoader(false);
  }

  keyPress(event: any) {
    if (event.target.value.length >= 200) {
      event.preventDefault();
    }
  }




  validateSurchargeChanges() {
    return ((this.surchargeConfig.every(x => x.id == 0) && this.surchargeConfig.some(x => x.isActive))
      || this.surchargeConfigUpdated)
  }

  async getSurchargeConfig() {
    if (!this.surchargeConfigService.IsSurchargesEnabled) return;
    try {
      this.SurchargeConfiguration = [];
      this.SurchargeConfiguration = await this.surchargeConfigService.GetSurchargeConfigurationWithCreditCardIssuer(PaymentMethods.NonIntegratedCreditCard);
      this.FormSurchargeConfigGrid(this.surchargeConfigService.mapAPIDataToUIElements(this.SurchargeConfiguration));
      this.updateSurchargeGridControls();
    } catch (error) {
      console.log(error);
    }
  }

  FormSurchargeConfigGrid(config: SurchargeConfiguration[]) {
    try {
      let CC_IssuerTypes = [
        CreditCardIssuerType.MaestroCard,
        CreditCardIssuerType.VisaCard,
        CreditCardIssuerType.MasterCard,
        CreditCardIssuerType.AmericanExpressCard,
        CreditCardIssuerType.DiscoverCard
      ];
      const issuerTypeMap = {
        1: 'maestro',
        2: 'visa',
        3: 'masterCard',
        4: 'americanExpress',
        5: 'discover',
      };
      let defaultCCType: { displayName: string, issuerType: number }[] = [];
      CC_IssuerTypes.map(x => defaultCCType.push({ displayName: this.surchargeConfigCaption?.IssuerType[x], issuerType: x }));
      const initDefaultCardTypeConfig = (issuerName: string, issuerTypeId: number) => {
        const displayName = issuerName;
        let issuerType = issuerTypeMap[issuerTypeId]
        return <SurchargeConfiguration>{
          id: 0,
          isActive: false,
          isPercentage: true,
          paymentMethodId: 0,
          creditCardIssuerType: { id: 0, issuerType: issuerType, displayName: displayName },
          paymentTypeId: PaymentMethods.NonIntegratedCreditCard,
          value: 0,
          issuerName: displayName,
          isValid: true,
          isDefault: true,
          maxLength: 3
        }
      }
      this.surchargeConfig = [];
      if (config?.length == 0) {
        defaultCCType.forEach(x => this.surchargeConfig.push(initDefaultCardTypeConfig(x.displayName, x.issuerType)));
        return;
      }
      this.surchargeConfig = this.surchargeConfig.concat(config);
      this.surchargeConfig.forEach(config => {
        this.surchargeConfigService.UpdateFieldProperties(config);
        if (defaultCCType.some(x => x.displayName == config?.creditCardIssuerType?.displayName)) {
          config.isDefault = true;
          config.issuerName = config?.creditCardIssuerType?.displayName;
        }
      });

    } catch (error) {
      console.log(error);
    }
  }

  chooseType(isPercentage, config: SurchargeConfiguration) {
    if (config.isPercentage != isPercentage) {
      this.surchargeConfig.find(x => x.uniqueId == config.uniqueId).value = 0;
    }
    config.isPercentage = isPercentage;
    this.EnableSave();
    this.surchargeConfigService.UpdateFieldProperties(config);
  }

  EnableSave() {
    this.selectAll = this.surchargeConfig.every(x => x.isActive);
    this.isUpdateEnabled = false;
    if (this.hasDuplicateIssuerTypes) return;
    if (this.IsInvalidSurchargeConfig) return;
    this.isUpdateEnabled = this.surchargeConfigUpdated = !this.surchargeConfig.some(x => x.isActive && String(x.value) == '');
  }

  enableAllSurcharge(event) {
    this.EnableSave();
    this.selectAll = event?.checked;
    this.surchargeConfig.forEach(x => x.isActive = event?.checked);
  }

  changeStatus(event, config: SurchargeConfiguration) {
    this.EnableSave();
    if (!event?.checked) {
      this.selectAll = false;
    }
  }

  updateSCVal(event) {
    this.EnableSave();
  }
  get hasDuplicateIssuerTypes(): boolean {
    const mySet = new Set(this.surchargeConfig.filter(x => x.isActive).map(x => x.isDefault ? x.creditCardIssuerType.issuerType.replace(/ /g, "").toLowerCase() : x.issuerName.replace(/ /g, "").toLowerCase()));
    if (mySet.size !== this.surchargeConfig.filter(x => x.isActive).length || mySet.has('')) {
      return true;
    } else {
      this.surchargeConfig.forEach(x => x.isValid = true);
      return false;
    }
  }
  updateIssuerType(event, config: SurchargeConfiguration) {
    this.surchargeConfig.find(x => x.uniqueId == config.uniqueId).isValid = true;
    let issuerType = [];
    this.surchargeConfig.map(x => issuerType.push(x.isDefault ? x.creditCardIssuerType.issuerType : x.issuerName));
    const isDuplicate = issuerType.find(x => x == config.issuerName);
    if (isDuplicate) {
      this.surchargeConfig.find(x => x.uniqueId == config.uniqueId).isValid = false;
    }
    this.EnableSave();
  }
  addArray(e = null) {
    const newvalue = <SurchargeConfiguration>{
      id: 0,
      isActive: false,
      isPercentage: true,
      paymentMethodId: 0,
      creditCardIssuerType: { id: 0, issuerType: '', displayName: '' },
      paymentTypeId: PaymentMethods.NonIntegratedCreditCard,
      value: 0,
      issuerName: '',
      isValid: true
    };
    this.surchargeConfig.push(newvalue);
    this.surchargeConfigService.UpdateFieldProperties(newvalue);
    this.updateSurchargeGridControls();
    this.EnableSave();
  }

  removeArray(e) {
    this.surchargeConfig = this.surchargeConfig.filter(x => x.uniqueId.toString() != e.uniqueId.toString());
    if (this.surchargeConfig.length == 0) {
      this.FormSurchargeConfigGrid([] as SurchargeConfiguration[]);
    }
    this.updateSurchargeGridControls();
    this.EnableSave();
  };
  updateSurchargeGridControls() {
    this.surchargeConfig.forEach(x => { x.lastIndex = false; x.uniqueId = Guid.create() });
    this.surchargeConfig[this.surchargeConfig.length - 1].lastIndex = true;
    this.selectAll = this.surchargeConfig.every(x => x.isActive);
  }
  getMaxSurchargeValue(isPercentage: boolean): number {
    return isPercentage ? this.maxPercentage : Number.MAX_SAFE_INTEGER;
  }

  isSensitiveField(key: string): boolean {
    const sensitiveKeywords = [NonIntegratedCreditCardConstants.APIUSERNAME, NonIntegratedCreditCardConstants.CLIENTID];
    return key && sensitiveKeywords.some(keyword => key.toLowerCase().includes(keyword.toLowerCase()));
  }
}
