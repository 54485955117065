import { Component, OnInit, Input, ViewEncapsulation,Pipe, PipeTransform, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms'; 
import { PhoneTypes, ContactType, AgContactConfig, AgPhoneDetail } from '../shared.modal';
import { uniquephoneValidator } from './agys-duplicate-contact-directive';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import countrylist from './country-code.json';
import { cloneDeep } from 'lodash';
import { countryCodeValue } from './agys-phone-number.model';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Product } from '../globalsContant';

@Component({
  selector: 'app-agys-phone-number',
  templateUrl: './agys-phone-number.component.html',
  styleUrls: ['./agys-phone-number.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgysPhoneNumberComponent implements OnInit {
  customClass: string;
  customClass1: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  typeControlName: string;
  typeClass: string;
  options: any[];
  arrayName: string;
  textMaskPhone: string;
  phoneTypes = PhoneTypes;
  captions: any;
  textMaskExtension: string;
  showSwitches: boolean;
  primarySwitchName: string;
  privateSwitchName: string;
  extensionClass: string;
  extensionPlaceHolder: string;
  duplicateError: string;
  extensionLength: number;
  primaryLabel: string;
  privateLabel: string;
  phoneLength: number;
  isPhoneDisabled: boolean;
  countryCodePlaceHolder: string;
  countryCodeLength: number;
  searchText = '';
  countrylist: countryCodeValue[];
  unfilteredcountrylist:  countryCodeValue[];
  textMaskPhoneArray: any = [];
  currentProductId: number;
  defaultCountryCode: any;
  defaultPhoneType: any;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  floatLabel: string;
  

  @Input('inputs')
  set inputOptions(value: AgContactConfig) {
    this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.err_invalidPhone;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.lbl_phone;
    this.extensionPlaceHolder = value.extnPlaceHolder ? value.extnPlaceHolder : this.captions.lbl_extension;
    this.countryCodePlaceHolder = this.localization.captions.common.countryCode;
    this.controlName = value.formControlName ? value.formControlName : 'phoneNumber';
    this.typeControlName = value.typeControlName ? value.typeControlName : 'phoneType'; 
    this.customClass = 'w-50';
    this.customClass1 = 'w-23 mr-1';
    this.typeClass = value.typeClass ? value.typeClass : 'ag_form-control--xs';
    this.extensionClass = value.extensionClass ? value.extensionClass : 'ag_form-control--md';
    this.primaryLabel = value.primarySwitchLabel ? value.primarySwitchLabel : this.captions.lbl_PrimaryPhone;
    this.privateLabel = value.privateSwitchLabel ? value.privateSwitchLabel : this.captions.lbl_PrivateInformation;
    this.isPhoneDisabled = value.disabled ? value.disabled : false;
    this.showSwitches = value.showSwitches;
    if (this.showSwitches) {
      this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryPhone';
      this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionPhone';
    }

    this.contactForm = value.form;
    this.arrayName = value.formArrayName;

    if (!this.arrayName) {
      if (!this.contactForm.get('countryCode')) {
        this.contactForm.addControl('countryCode', new UntypedFormControl());
      }
      this.contactForm.get('countryCode').disable();
      this.contactForm.get(this.controlName).disable();
    } else {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.controls.forEach((obj: UntypedFormGroup) => {
        if (!obj.get('countryCode')) {
          obj.addControl('countryCode', new UntypedFormControl());
        }
        if (!obj.value[this.typeControlName]) {
          obj.get('countryCode').disable();
          obj.get(this.controlName).disable();
        }
      });
    }
    this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
    if(this.currentProductId == Product.GOLF){
      let defaultsettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
      this.defaultCountryCode = defaultsettings?.defaultCountryCode;
      this.defaultPhoneType = defaultsettings?.defaultPhoneTypeOption
     this.setDefaultCountryCode(this.defaultCountryCode,this.defaultPhoneType)
    } else if(this.currentProductId == Product.SPA){
      let defaultsettings : any[];
      defaultsettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
      let defaultCountryCodeSetting = defaultsettings.find(x=> x.switch === 'DEFAULT_COUNTRY_CODE');
      this.defaultCountryCode = defaultCountryCodeSetting? Number(defaultCountryCodeSetting.value): '';
      let defaultPhoneTypeSetting = defaultsettings.find(x => x.switch === 'DEFAULT_PHONE_TYPE');
      this.defaultPhoneType  = defaultPhoneTypeSetting? Number(defaultPhoneTypeSetting.value): ''
      this.setDefaultCountryCode(this.defaultCountryCode,this.defaultPhoneType)
    }
  }

  @Input('valuePatched')
  set ControlState(value: boolean) {
    if (!this.contactForm.get('countryCode')) {
      this.contactForm.addControl('countryCode', new UntypedFormControl());
    }
    const countrycodeControl = this.contactForm.get('countryCode');
    const contactControl = this.contactForm.get(this.controlName);
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.typeControlName).value) {
      contactControl.enable();
      countrycodeControl.enable();
      this.contactForm.controls['countryCode'].setValidators([Validators.required]);
      this.contactForm.controls[this.controlName].setValidators([Validators.required]);
    } else {
      contactControl.disable();
      countrycodeControl.disable();
    }
  }

  @Input('values')
  set SetFormArray(value: AgPhoneDetail[]) {
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.phoneFieldDisabler(this.isPhoneDisabled);
    }
  }

  @Input() isViewOnly = false;

  constructor(private fb: UntypedFormBuilder,private utilities: CommonUtilities, private localization: RetailLocalization,private cmnutilities:CommonUtilities) {
    this.captions = this.localization.captions.common;
    this.duplicateError = this.captions.duplicatePhone;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.textMaskPhone = this.maskPhoneNo();
    this.textMaskExtension = this.maskExtension();
    this.extensionLength = 5;
    this.options = this.getPhoneOptions();
    this.phoneLength = 12;
    this.countryCodeLength = 5;
    this.countrylist = countrylist;
    this.unfilteredcountrylist = cloneDeep(this.countrylist)
  }

  filterdata(eve){
    this.searchText = eve?eve.target.value.trim().toLowerCase():'';
    this.countrylist = this.searchText ? this.unfilteredcountrylist?.filter(x=>x.dialCode.toLowerCase().includes(eve.target.value) || x.name.toLowerCase().includes(eve.target.value)) : this.unfilteredcountrylist;  
  }

  ngOnChanges(){
    if(this.arrayName){
      this.phoneFieldDisabler(this.contactForm.get(this.arrayName).disabled);
    }
  }

  phoneFieldDisabler(isDisable){
    let phoneField = this.contactForm.get(this.arrayName);
    if(isDisable){
      this.isPhoneDisabled = true;
      phoneField['controls'].forEach(element => {
        element.disable();
      });
    }
  }

  checkValidity(e, i) {
    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const contactControl = phone.controls[i].get(this.controlName);
    this.checkPhoneControlValidity(contactControl);
  }

  checkValiditySinglePhone(e) {
    const contactControl = this.contactForm.get(this.controlName);
    this.checkPhoneControlValidity(contactControl);
  }

  checkPhoneControlValidity(contactControl) {
    const val = contactControl.value;
    const res = val.replace(/\D/g, '');
    if (contactControl.dirty && (res.length < this.phoneLength) && (res.length !== 0)) {
      contactControl.setErrors({ incorrect: true });
    }
  }

  // Dynamic Phone field addition Logic
  addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, isPrimary?, isPrivate?, isSynced?): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.phone,
      extension: extn,
      id: index,
      isSynced: isSynced
    });
    currentForm.addControl(this.typeControlName, new UntypedFormControl(phoneLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: phoneNumber, disabled: !phoneLabel }));
    currentForm.addControl('countryCode', new UntypedFormControl({ value: countryCode, disabled: !phoneLabel }));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isPrimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    return currentForm;
  }

  addPhoneItem(index, phoneLabel: string, countryCode: string | number, phoneNumber: string | number, extn: string | number, primaryPhone: string | boolean, privateInformtionPhone: string | boolean, idx: number | string, isSynced: string| boolean): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.push(this.addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, primaryPhone, privateInformtionPhone, isSynced));
      if (idx) {
        const group = phone.controls[idx] as UntypedFormGroup;
        group.controls['countryCode'].setValidators([Validators.required]);
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);
      }

    }
  }

  removePhoneItem(index: number): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      this.contactForm.markAsDirty();
      phone.removeAt(index);
    }
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const ctrls = arr.controls.filter((x, idx) => idx !== formGroupName);
    ctrls.forEach(x => {
      const grp = x as UntypedFormGroup;
      grp.controls[this.primarySwitchName].setValue(false);
    });
  }

  typeChange(arg, formGroup: UntypedFormGroup, i) {
    formGroup.get('countryCode').enable();
    formGroup.get(this.controlName).enable();

    if (arg.value === this.phoneTypes.office && !formGroup.get('extension')) {
      formGroup.addControl('extension', new UntypedFormControl());
    }
    this.countryCodeRequiredCheck(i);
  }


  private patchOrAdd(value) {
    console.log("value", value);
    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
    value.forEach((obj, idx) => {

      console.log("value", obj);
      const group = phone.controls[idx] as UntypedFormGroup;
      if (group) {

        console.log("group", group);
        group.controls['countryCode'].setValidators([Validators.required]);
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);

        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.phone));
        }
        if (!group.get('countryCode')) {
          group.addControl('countryCode', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] === this.phoneTypes.office) {
          group.addControl('extension', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get('countryCode').enable();
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
      } else {
        const id = obj ? obj.id : '';
        const phoneType = obj ? obj[this.typeControlName] : '';
        const countryCode = obj ? obj.countryCode : '';
        const phoneNumber = obj ? obj[this.controlName] : '';
        const extension = obj ? obj.extension : '';
        const primaryPhone = obj ? obj.primaryPhone : '';
        const privateInformtionPhone = obj ? obj.privateInformtionPhone : '';
        const isSynced = obj? obj.isSynced:false;
        this.addPhoneItem(id, phoneType, countryCode, phoneNumber, extension, primaryPhone, privateInformtionPhone, idx, isSynced);
      }
      let filteredMaskCode = countrylist.filter(x=>x.dialCode === obj.countryCode);
      this.textMaskPhoneArray[idx] = countrylist.find(x=>x.dialCode === obj.countryCode)? countrylist.find(x=>x.dialCode === obj.countryCode).placeHolder:'';
      this.getphoneformatmask1(filteredMaskCode, idx);
    });
  }
  private maskPhoneNo(): string {
    return this.cmnutilities.getPhoneFormat();
  }

  private maskExtension(): string {
    return this.cmnutilities.getExtPhoneFormat();
  }

  private getPhoneOptions() {
    return [
      { id: this.phoneTypes.home, description: this.captions.drp_txt_home, type: ContactType.phone },
      { id: this.phoneTypes.office, description: this.captions.drp_txt_office, type: ContactType.phone },
      { id: this.phoneTypes.mobile, description: this.captions.drp_txt_mobile, type: ContactType.phone }
    ];
  }

  setmandatoryField(curr, altcontrol, formGroup: UntypedFormGroup, i?) {
    if (i || i >= 0) {
      const arr = formGroup.get(this.arrayName) as UntypedFormArray;
      const group = arr.controls[i] as UntypedFormGroup;
      let curvalue = group.get(curr).value;
      
      if(curr === "countryCode") {
        let getPlaceholder = countrylist.filter(city => city.dialCode == curvalue);
        this.getphoneformatmask1(getPlaceholder,i);
      }
      if (curvalue != '') {
        if (curr == 'countryCode') {
          group.controls[altcontrol].clearValidators();
          group.controls[altcontrol].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, i)]);
          group.controls[altcontrol].markAsTouched();
        } else {
          group.controls[altcontrol].clearValidators();
          if (group.controls[this.typeControlName].value === PhoneTypes.mobile) {group.controls[altcontrol].setValidators([Validators.required])}
          group.controls[altcontrol].markAsTouched();
        }
      } else {
        group.controls[altcontrol].clearValidators();
      }
      group.controls[altcontrol].updateValueAndValidity();
    } else {
      let curvalue = formGroup.get(curr).value;
      if (curvalue != '') {
        formGroup.controls[altcontrol].setValidators([Validators.required]);
        formGroup.controls[altcontrol].markAsTouched();
      } else {
        formGroup.controls[altcontrol].clearValidators();
      }
      formGroup.controls[altcontrol].updateValueAndValidity();
    }
    this.countryCodeRequiredCheck(i);
  }

  clearOutCountryCode(curvalue, formGroup, i){
    const arr = formGroup.get(this.arrayName) as UntypedFormArray;
    const group = arr.controls[i] as UntypedFormGroup;
    let getPlaceholder = this.countrylist.filter(city => city.dialCode == curvalue);
    if(getPlaceholder.length === 0){
      group.controls['countryCode'].setValue('');
    }
  }  

  countryCodeRequiredCheck(index) {
    if (this.contactForm.get(this.typeControlName)) {
      if (this.contactForm.get(this.typeControlName).value === PhoneTypes.mobile && this.contactForm.get(this.controlName).value) {
        this.contactForm.controls['countryCode'].setValidators([Validators.required]);
      } else {
        this.contactForm.controls['countryCode'].clearValidators();
      }
      this.contactForm.controls['countryCode'].updateValueAndValidity();
    } else if (this.contactForm.get('phone')) {
      const phoneControl = this.contactForm.controls.phone['controls'][index]['controls'];
      if (phoneControl[this.typeControlName].value === PhoneTypes.mobile &&
        phoneControl[this.controlName].value) {
        phoneControl['countryCode'].setValidators([Validators.required]);
      } else {
        phoneControl['countryCode'].clearValidators();
      }
      phoneControl['countryCode'].updateValueAndValidity();
    }
  }

  getphoneformatmask1(eve,i){
    if (eve.length > 0) {
      this.textMaskPhoneArray[i] = eve[0].placeHolder;
      this.contactForm.controls.phone['controls'][i]['controls']['countryCode'].setValue(eve[0].dialCode);
      let newformatvalue = this.appendphoneFormat(this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.value,this.textMaskPhoneArray[i] ); 
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.setValue(newformatvalue);
    }
  }
  getphoneformatmask(eve,i){
    if (eve.option.value.placeHolder) {
      this.textMaskPhoneArray[i] = eve.option.value.placeHolder;
      this.contactForm.controls.phone['controls'][i]['controls']['countryCode'].setValue(eve.option.value.dialCode);
      let newformatvalue = this.appendphoneFormat(this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.value,this.textMaskPhoneArray[i] ); 
      this.contactForm.controls.phone['controls'][i]['controls'].phoneNumber.setValue(newformatvalue);
    }
  }
  appendphoneFormat(value:string, format:string){
    if (!value && value == "") {
        return "";
    }
    let userInput = value.toString();
    userInput = userInput.replace(/[^0-9]/g,'')
    let returnVal:string ="";
    let indexOfUserValue:number = 0;
    if (format && format == "") {
        return value;
    }
    userInput = (userInput.length > 1 && userInput[0] === '0' && format[1] === '0' ? userInput.replace(/[0]/, '') : userInput);
    for (let type of format) {
        const char = type;
        let charCode = char.toString().charCodeAt(0);
        const IsFormatNumber:boolean = (charCode === 57);
        if (!IsFormatNumber) {
           returnVal = returnVal+type;
        }else{
            if (userInput[indexOfUserValue]) {
                returnVal = returnVal+userInput[indexOfUserValue];
                indexOfUserValue++;
            }else{
                break;
            }
        }
    }
    return returnVal;
  }

  updateMask(index) {
    let cCode = this.contactForm.controls.phone['controls'][index].controls.countryCode.value;
    if (cCode) {
      let cObj = countrylist.find(x => x.dialCode === cCode);
      if (cObj) {
        this.textMaskPhoneArray[index] = cObj.placeHolder;
      }
    }
  }
  setDefaultCountryCode(defaultCountryCode,defaultPhoneType){
    if(this.contactForm.get('phone')) {
      this.contactForm.get('phone')['controls']?.forEach((element,index) =>{
        if(element.get('countryCode').value === ""){
           element.get('countryCode').setValue(defaultCountryCode);
        }   
        if(element.get(this.typeControlName).value === ""){
           element.get(this.typeControlName).setValue(defaultPhoneType);
        } 
        if(element.get('countryCode').value && element.get(this.typeControlName).value){
          element.get('countryCode').enable();
          element.get(this.controlName).enable();
        }
      })
    } 
  }
}

@Pipe({
  name: "flagSelectorRetail"
})
export class FlagSelectorRetailPipe implements PipeTransform {
  transform(dialCode: any): string {
    let isTextString = parseInt(dialCode);
    
    if (dialCode) {
      let flagObj;
      if(isNaN(isTextString)) {
        flagObj = countrylist.find((x) => {
          let name = x.name.toLowerCase();
          let dialText = dialCode.toLowerCase();
          return name.includes(dialText) 
        } ); 
      }
      else {
        flagObj = countrylist.find(x => x.dialCode === dialCode); 
      }
     
      if (flagObj) {
        return flagObj.flagClass;
      }
    }
    return '';
  }
}

