import { ServiceParams } from 'src/app/common/Models/http.model';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { RguestCommunication } from '../common/rguest-communication';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { AppService } from '../../app-service';
import { JSONReaderService } from '../load-json.service';
import { PropertySettingDataService } from '../../dataservices/authentication/propertysetting.data.service';
@Injectable({ providedIn: 'root' })
export class JasperReportCommunication extends RguestCommunication {

    constructor(httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService, jsonReader: JSONReaderService,private propertyserveice: PropertySettingDataService) {
        super(environment["JasperServerURL"], httpclient, localization, utilities, PropertyInfo, appService);
    }

    jasperReportGetWithCustomHeaders(params: ServiceParams, responseType : string): Observable<any> {
        return super.jasperReportGetWithCustomHeaders(params, responseType);
    }
    async jasperReportPostWithCustomHeaders(params: ServiceParams, responseType : string,isLoaderRequired:boolean = false): Promise<any> {
            await this.propertyserveice.UpdateRoleAndAttributeToUser(isLoaderRequired);
            return super.jasperReportPostWithCustomHeaders(params, responseType);         
    }
}

