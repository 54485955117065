import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { GridFilterData, GridFilterValue } from '../interface/common';
import moment from 'moment';
import * as constants from '../shared/globalConstants';
import * as _ from 'lodash';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'lib-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridFilterComponent implements OnInit, OnChanges {
  @Input() filterData: GridFilterData[];
  @Output() closeFilter: EventEmitter<any> = new EventEmitter();
  @Output() filterApplied: EventEmitter<any> = new EventEmitter();
  @Output() fetchFilterData: EventEmitter<GridFilterData> = new EventEmitter();
  daysSelected: any = [];
  filterType = constants.FilterType;
  searchText = '';
  copyOfFilterOptions: GridFilterData[] = [];
  applyClicked: boolean;
  selectedFilterMenu: any;
  @ViewChild('rangePicker') datePicker: MatDatepicker<Date>;
  @ViewChild('appMenu', {read: MatMenuTrigger}) filterOptionMenu: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  startDate: Date;
  endDate: Date;
  radioSelected: any;
  IniDateFieldFormat: string;
  NoFilterChange = false;
  constructor() {
    this.IniDateFieldFormat = sessionStorage.getItem('IniDateFieldFormat');
  }
  /**
   * @method openFilterOptions
   * @params data, event
   * @description Open filter option overlay
   */
  openFilterOptions(data: GridFilterData, event: any) {
    try {
      this.applyClicked = false;
      // console.log(data);
      // if (data.Type === this.filterType.DateRangeType) {
      //   setTimeout(() => {
      //     // this.trigger.closeMenu();
      //     this.datePicker.open();
      //   }, 10);
      // }
      event.stopPropagation();
      if (data.hasOwnProperty('LoadOnClick') && data.LoadOnClick && (data.FilterValue && data.FilterValue.length === 0)) {
        data.EnableLoader = data.hasOwnProperty('EnableLoader') ? !!data.EnableLoader : true;
        this.fetchFilterData.emit(data);
      }
      this.copyOfFilterOptions = _.cloneDeep(this.filterData);
    } catch (error) {

    }
  }

  dateChanged(type: string, event, dateType: string, data: GridFilterData) {
    try {
      if (dateType === 'start') {
        data.FilterValue[0].Value = event.value;
      } else {
        data.FilterValue[1].Value = event.value;
      }
    } catch (error) {
      console.error('Error occurred in dateChanged', error);
    }
  }
  radioSelectChange(data: any, filteroptions: any) {
    try {
      if (filteroptions && filteroptions.hasOwnProperty('FilterValue')) {
        filteroptions.FilterValue.forEach((x: any) => {
          if (x.FilterId === data.FilterId) {
            x.Checked = true;
          } else {
            x.Checked = false;
          }
        });
      }
    } catch (error) {
      console.error('Error occured in Radio Button Selection', error);
    }
  }
  ngOnChanges() {
    // console.log(this.filterData);
  }
  ngOnInit() {
    // console.log('onit');
  }

  /**
 * @method checkBoxSelected
 * @params data
 * @params FilterValue
 * @description Emit the filter checked values
 */
  checkBoxSelected(data: GridFilterValue, FilterValue: string) {
    data.Checked = !data.Checked;
  }

  /**
   * @method clearSelectedValues
   * @params data
   * @description clear selected filter options
   */
  clearSelectedValues(data: string) {
    this.applyClicked = true;
    this.filterData.forEach(element => {
      if (element.FilterKey === data) {
        element.FilterValue.forEach(values => {
          values.Checked = false;
          values.Value = null;
        });
        if (element.Type === 'daterange') {
          this.startDate = null;
          this.endDate = null;
        } else if (element.Type === 'radio') {
          this.radioSelected = null;
        }
      }
    });
    this.filterApplied.emit([this.filterData, this.searchText, true]);
  }

  /**
   * @description apply filter
   * @param data ;
   */
  applyFilterValues(data) {
    this.applyClicked = true;
    this.filterApplied.emit([this.filterData, this.searchText]);
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  menuClosed() {
    this.selectedFilterMenu = null;
    if (!this.applyClicked) {
      this.copyOfFilterOptions.forEach(filter => {
        const filterIndex = this.filterData.findIndex(x => x.FilterKey === filter.FilterKey);
        if (filterIndex !== -1 && this.filterData[filterIndex].FilterValue.length > 0 && filter.FilterValue.length === 0) {
          const filterOptions = this.filterData[filterIndex].FilterValue.map(result => {
            result.Checked = false;
            return result;
          });
          filter.FilterValue = filterOptions;
          filter.EnableLoader = false;
        }
      });
      this.filterData = this.copyOfFilterOptions;
    }
    // console.log('Menu closed');
  }

  disableFilter() {
    const reloadFilter = [];
    this.startDate = null;
    this.endDate = null;
    this.radioSelected = null;
    /**
       * Bug Fix - 202783 - Filter icon close and cancel issue
       */
    this.NoFilterChange = this.filterData.every(e =>
      e.FilterValue.every(x => x.Checked === false));
    this.filterData.forEach(element => {
      if (element.Type === 'daterange') {
        reloadFilter.push(element.FilterValue.filter(x => x.Value !== null && x.Value !== undefined).length > 0);
      } else {
        reloadFilter.push(element.FilterValue.filter(x => x.Checked === true).length > 0);
      }
    element.FilterValue.forEach(value => {
        value.Value = null;
        value.Checked = false;
      });
    });
    this.filterData = [...this.filterData];
    // if (reloadFilter.filter(x => x === true).length > 0) {
      // this.filterApplied.emit([this.filterData, this.searchText]);
    // }
    /**
       * Bug Fix - 202783 - Filter icon close and cancel issue
       */
    this.closeFilter.emit(this.NoFilterChange);
  }

  select(event: any, calendar: any) {
    this.daysSelected.push(moment(event).format('L'));
    calendar.updateTodaysDate();
  }

  isSelected = (event: any) => {
    const formattedValue = moment(event).format('L');
    return this.daysSelected ? this.daysSelected.find(x => x === formattedValue) ? 'selected' : null : null;
  }

  searchTextChanged() {
    this.filterApplied.emit([this.filterData, this.searchText]);
  }

}
