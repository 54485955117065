import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { AgDropdownConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AlertAction, AlertType, ButtonType, DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { dedupeGuestRecordBusiness } from '../dedupe-guest-records.business';
import { DDcombine, DDcombineInput, UI } from '../dedupe-guest-ui-model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-combine-guest',
  templateUrl: './combine-guest.component.html',
  styleUrls: ['./combine-guest.component.scss']
})
export class CombineGuestComponent implements OnInit {

  captions: any;
  combineGuestForm: UntypedFormGroup;
  lastNameInput: AgDropdownConfig;
  DialogCloseEnum = DialogCloseEnum;
  hdrData: any[];
  proceedButton: ButtonValue;
  cancelButton: ButtonValue;
  floatLabel: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  setvaluetocombineInputs: { primarydata: any; secondarydata: any; };
  finalcombinedata: DDcombineInput;
  primarydefaultSet: boolean = false;

  @Output() handleClickEvent = new EventEmitter();

  constructor(private _localization: Localization, private formbuilder: UntypedFormBuilder, public _dedupeGuestRecordBusiness: dedupeGuestRecordBusiness, private dialogRef: MatDialogRef<CombineGuestComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dialog: MatDialog, private utils: CommonUtilities) {
    this.captions = this._localization.captions;
    this.hdrData = this.headerDataInitialize();
    this.floatLabel = this._localization.setFloatLabel;
  }

  ngOnInit(): void {
    this.initialize();
    this.combineGuestForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(_res => {
      this.proceedButton.disabledproperty = !(this.combineGuestForm.valid && this.combineGuestForm.dirty);
      this.proceedButton = { ...this.proceedButton };
    });
    this.loadGuestIds();
  }
  closeDialog() {
    const primary = this.data.groupIds.find(x => x.isprimary).guestRecords
    const groupNumber = primary.groupNumber;
    // Send data to the parent component
    this.dialogRef.close(groupNumber);
  }
  initialize() {
    this.proceedButton = {
      type: 'primary',
      label: this.captions.btn_proceed,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
    let group = {
      'defaultToPrimaryValue': new FormControl('')
    }
    this.hdrData.forEach(x => {
      group[x.formControlName] = new FormControl(x.selectOptions[0].id);
    })
    this.combineGuestForm = new FormGroup(group);
  }
  loadGuestIds() {
    const primary = this.data.groupIds.find(x => x.isprimary).guestRecords;
    const secondary = this.data.groupIds.filter(x => x.isSecondary).map(option => option.guestRecords);
    this.setvaluetocombineInputs = { primarydata: primary, secondarydata: secondary };
  }
  
  initializeFinalCombineData() {
    this.finalcombinedata = {} as DDcombineInput;
    this.finalcombinedata.formchangeddata = {} as DDcombine;
    const formValue = this.combineGuestForm.value;
    this.hdrData.forEach(x => {
      const controlValue = formValue[x.formControlName];
      const selectedOption = x.selectOptions.find(option => option.id === controlValue);

      if (selectedOption) {
        switch (x.formControlName) {
          case 'title':
            this.finalcombinedata.formchangeddata.title = selectedOption.viewValue;
            break;
          case 'firstName':
            this.finalcombinedata.formchangeddata.firstName = selectedOption.viewValue;
            break;
          case 'lastName':
            this.finalcombinedata.formchangeddata.lastName = selectedOption.viewValue;
            break;
          case 'phoneNumber':
            this.finalcombinedata.formchangeddata.phoneNumber = selectedOption.viewValue;
            break;
          case 'emailAddress':
            this.finalcombinedata.formchangeddata.emailAddress = selectedOption.viewValue;
            break;
          case 'address':
            this.finalcombinedata.formchangeddata.address = selectedOption.viewValue;
            break;
          case 'city':
            this.finalcombinedata.formchangeddata.city = selectedOption.viewValue;
            break;
          case 'postalCode':
            this.finalcombinedata.formchangeddata.postalCode = selectedOption.viewValue;
            break;
        }
      }
    });
  }
  steppercombine(eve) {
    this.initializeFinalCombineData();
    const warnMerge = this._localization.replacePlaceholders(this.captions.combineGuestWarn_Merge,
      ['primaryName'], [this.setvaluetocombineInputs.primarydata.firstName + ' ' + this.setvaluetocombineInputs.primarydata.lastName]);
    this._dedupeGuestRecordBusiness.showAlert(warnMerge, AlertType.Info, ButtonType.YesNo, (res) => {
      if (res === AlertAction.YES) {
        // Update MergedGuestInfo Tbl and SoftDelete
        this.utils.ToggleLoader(true);
        this._dedupeGuestRecordBusiness.combineGuestRecords(this.setvaluetocombineInputs.primarydata.guestId, this.setvaluetocombineInputs.secondarydata.map(x => x.guestId)).then(response => {

          this.utils.ToggleLoader(false);

          if (response) {

            // If primary Chkbox unchecked -- Required to update the field values
            if (!this.primarydefaultSet) {
              this.utils.ToggleLoader(true);
              this._dedupeGuestRecordBusiness.getGuestInformationByGuid(this.setvaluetocombineInputs.primarydata.guestId).then(primaryGuestData => {

                primaryGuestData.lastName = this.finalcombinedata.formchangeddata.lastName ? this.finalcombinedata.formchangeddata.lastName : primaryGuestData.lastName;
                primaryGuestData.firstName = this.finalcombinedata.formchangeddata.firstName ? this.finalcombinedata.formchangeddata.firstName : primaryGuestData.lastName;
                primaryGuestData.title = this.finalcombinedata.formchangeddata.title ? this.finalcombinedata.formchangeddata.title : primaryGuestData.title;
                primaryGuestData.guestProfileAddress.forEach(x => {
                  x.addressLine1 = this.finalcombinedata.formchangeddata.address ? this.finalcombinedata.formchangeddata.address : x.addressLine1;
                  x.zipCode = this.finalcombinedata.formchangeddata.postalCode ? this.finalcombinedata.formchangeddata.postalCode : x.zipCode;
                  x.city = this.finalcombinedata.formchangeddata.city ? this.finalcombinedata.formchangeddata.city : x.city;
                })

                if (primaryGuestData.guestProfileAddress.length < 1) {
                  primaryGuestData.guestProfileAddress.push({
                    addressLine1: this.finalcombinedata.formchangeddata.address ? this.finalcombinedata.formchangeddata.address : '',
                    zipCode: this.finalcombinedata.formchangeddata.postalCode ? this.finalcombinedata.formchangeddata.postalCode : '',
                    city: this.finalcombinedata.formchangeddata.city ? this.finalcombinedata.formchangeddata.city : '',
                    state: '',
                    country: '',
                    county: '',
                    isPrivate: true,
                    addressLine2: '',
                    addressLine3: ''
                  });
                }
                if (this.finalcombinedata.formchangeddata.emailAddress) {
                  const contactEmailType = [9, 10];
                  let email = primaryGuestData.guestProfileContact.find(a => contactEmailType.includes(a.type));
                  if (email) {
                    email.value = this.finalcombinedata.formchangeddata.emailAddress;
                  }
                  else {
                    primaryGuestData.guestProfileContact.push({
                      playerId: 0,
                      id: 0,
                      type: 9,
                      name: '',
                      description: '',
                      value: this.finalcombinedata.formchangeddata.emailAddress,
                      isPrivate: false,
                      isPrimary: true,
                      platformContactUuid: null

                    });
                  }
                }

                if (this.finalcombinedata.formchangeddata.phoneNumber) {
                  const contactPhoneType = [1, 2, 3];
                  let phonenumber = primaryGuestData.guestProfileContact.find(a => contactPhoneType.includes(a.type));
                  if (phonenumber) {
                    phonenumber.value = this.finalcombinedata.formchangeddata.phoneNumber;
                  }
                  else {
                    primaryGuestData.guestProfileContact.push({
                      playerId: 0,
                      id: 0,
                      type: 3,
                      name: '',
                      description: '',
                      value: this.finalcombinedata.formchangeddata.phoneNumber,
                      isPrivate: false,
                      isPrimary: true,
                      platformContactUuid: null

                    });
                  }
                }
                this._dedupeGuestRecordBusiness.combineGuestInfo(primaryGuestData);
                this.utils.ToggleLoader(false);
              });
            }
            const SuccessCGMerge = this._localization.replacePlaceholders(this.captions.combineGuestSuccess_Merge,
              ['primaryGuestFirstName', 'primaryGuestLastName'], [this.setvaluetocombineInputs.primarydata.firstName, this.setvaluetocombineInputs.primarydata.lastName]);
            this._dedupeGuestRecordBusiness.showAlert(SuccessCGMerge, AlertType.Done, ButtonType.Ok, callback => {
              this.handleClickEvent.emit('cancel');
            });
            this.closeDialog();
          }
        });
      }
    });
  }

  selectedField(field, eve) {

  }
  closePopUp(from) {
    this.dialogRef.close({
      from: from
    })
  }
  primaryValueChange(eve) {
    if (this.primarydefaultSet) this.primarydefaultSet = false;
    else this.primarydefaultSet = true;
  }
  onAction(eve) {

  }

  headerDataInitialize() {
    return this.data.headerData.filter(x => x.selectOptions).map(data => {
      return {
        displayName: data.displayName,

        formControlName: data.formControlName,
        selectOptions: data.selectOptions.map(c => {

          return {
            id: c.id,
            value: c.value,
            viewValue: c.viewValue,
            isPrimary: c.isPrimary
          }
        }).sort((a, b) => { return b.isPrimary - a.isPrimary })
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

}
