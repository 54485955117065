import { TransControlAuthorizedConfig } from './authorized-configuration.interface';
import { PermissionResource } from '../ui-controls.interface';
import { ItemListHeaderConfiguration, TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { AuthoriztionKey } from '../authorization.enum';
import { SysytemPreferenceService } from 'src/app/eatecui/source/setup/system-preference/system-preference.service';

export const TransCancellationAuth: TransControlAuthorizedConfig = ( TransactionData: TransactionConfiguration,
    permissionResource: PermissionResource, systemPreference?: SysytemPreferenceService): TransactionConfiguration => {
        try {
            const transactionData: TransactionConfiguration = TransactionData;
            transactionData.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.forEach((x: ItemListHeaderConfiguration) => {
                // const cancellationName = x.Name.trim().replace(' ', '');
                const searchRegExp = /\s/g;
                const replaceWith = '';
                const cancellationName = x.Name.trim().replace(searchRegExp, replaceWith);
                const newCancellationName = cancellationName.slice(0, -1);
                if (cancellationName === permissionResource.Name || newCancellationName === permissionResource.Name) {                    
                    if (permissionResource.IsActive) {
                        if (x.hasOwnProperty('SystemPreferenceKey') && x.SystemPreferenceKey) {
                            if (systemPreference?.globalSystemPreference) {
                                x['Enable'] = systemPreference.globalSystemPreference.
                                filter(y => y.LookupName === x.SystemPreferenceKey)[0].LookupValue === 1 ? true : false;
                            }
                        }
                    } else {
                        x['Enable'] = permissionResource.IsActive;
                    }
                } else if (cancellationName === 'Deactivate' && permissionResource.Name === 'ReactivateDeactivateMasterLimitation') {
                    x['Enable'] = permissionResource.IsActive;
                }
            });
            if (transactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.length > 0 ) {
                transactionData.ViewTranscationConfig.GridConfiguration.GridHeaderDefination.forEach(header => {
                    if (header.hasOwnProperty('headerCheckboxSelection') && header.hasOwnProperty('checkboxSelection') &&
                     header.headerCheckboxSelection && header.checkboxSelection) {
                        header.hide = !permissionResource.IsActive;
                    } 
                    if (header.headerName === 'Actions') {
                        if (permissionResource.Name === 'ReactivateDeactivateMasterLimitation') {
                            header['Deactivate'] = permissionResource.IsActive;
                            header['Activate'] = permissionResource.IsActive;
                        } else {
                            header['Delete'] = permissionResource.IsActive;
                        }
                        
                    }
                });
            }
            transactionData.CopyAsNewConfig.CopyNewItemListConfig.ItemListHeaderConfig.forEach(headerConfig => {
              if (headerConfig.Name === 'Delete Template') {
                headerConfig.Enable = permissionResource.IsActive;
              }
            });
            return null;
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

   