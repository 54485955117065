import { Component, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, ControlContainer, UntypedFormControl } from '@angular/forms';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from '../../../../common/localization/retail-localization';
import { RetailReportControl, ValidationMessage } from '../../../basereport/base-report.component';

@Component({
  selector: 'app-retail-inventory-report',
  templateUrl: './inventory-report.component.html',
  styleUrls: ['./inventory-report.component.scss']
})
export class RetailInventoryReportComponent implements OnInit {
  InventoryReportFormGrp: UntypedFormGroup;
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  currentDate: Date;
  startDate: Date;
  endDate: Date;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  floatLabel: string;
  isFromJobScheduler: boolean = false;
  timelineOptions: DropdownOptions[];
  editPatchValue: any;
  isFromEdit = false;
  categoryComponentFilter: any;
  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, private controlContainer: ControlContainer,
    private propertyInformation: RetailPropertyInformation, private container: ViewContainerRef) {
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.isFromJobScheduler = this.localization.isFromJobScheduler;
    this.radioFilter = [{ id: 0, name: this.captions.InhouseInventory }, { id: 1, name: this.captions.OnArrival }];
    this.currentDate = this.propertyInformation.CurrentDate;
    this.minStartDate = this.propertyInformation.CurrentDate;
    this.minEndDate = this.propertyInformation.CurrentDate;
    this.InventoryReportFormGrp = this.fb.group({
      lostCostMethod: false,
      categoryView: false,
      itemFilter: 0,
      category: new UntypedFormGroup({}),
      startDate: this.currentDate,
      endDate: this.currentDate
    });
    if (this.isFromJobScheduler) {
      this.InventoryReportFormGrp.addControl('timelineFrom', new UntypedFormControl());
      this.InventoryReportFormGrp.addControl('timelineCountFrom', new UntypedFormControl());
      this.InventoryReportFormGrp.addControl('timelineTo', new UntypedFormControl());
      this.InventoryReportFormGrp.addControl('timelineCountTo', new UntypedFormControl());
      this.setSchedulercontrols();
    }
    this.formReady.emit(this.InventoryReportFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      this.categoryComponentFilter =
      {
        category: _parent.editableValue.categoryIds,
        subCategory1: _parent.editableValue.subCategory1Ids,
        subCategory2: _parent.editableValue.subCategory2Ids,
        subCategory3: _parent.editableValue.subCategory3Ids,
        subCategory4: _parent.editableValue.subCategory4Ids,
        subCategory5: _parent.editableValue.subCategory5Ids
      };
      this.PatchSchedulercontrols(_parent.edit);
    }
  }
  private PatchSchedulercontrols(isEdit) {
    if (this.localization.isFromJobScheduler && isEdit) {
      this.isFromEdit = true;
      let patchItem = this.editPatchValue;
      setTimeout(() => {
        if (patchItem.fromWildCard != undefined)
          this.InventoryReportFormGrp.controls.timelineFrom.patchValue(this.localization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

        if (patchItem.fromIncrement != undefined)
          this.InventoryReportFormGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

        if (patchItem.toWildCard != undefined)
          this.InventoryReportFormGrp.controls.timelineTo.patchValue(this.localization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

        if (patchItem.toIncrement != undefined)
          this.InventoryReportFormGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        if (patchItem.reporttype != undefined) {
          this.InventoryReportFormGrp.controls['itemFilter'].patchValue(patchItem.reporttype);
        }
      }, 1);
    }
  }
  setSchedulercontrols() {
    this.timelineOptions = this.localization.scheduleJobWildCards;
    this.InventoryReportFormGrp.controls['timelineFrom'].setValue(this.localization.scheduleJobWildCards[0].id);
    this.InventoryReportFormGrp.controls['timelineTo'].setValue(this.localization.scheduleJobWildCards[0].id);
    this.InventoryReportFormGrp.controls['timelineCountFrom'].setValue(0);
    this.InventoryReportFormGrp.controls['timelineCountTo'].setValue(0);
  }
  formInitialized(form: UntypedFormGroup) {
    this.InventoryReportFormGrp.setControl('category', form);
  }


  IsSelectionValid(validationObject: ValidationMessage) {
    this.IsValidSelection.emit({ dropDownName: validationObject.dropDownName, validity: validationObject.validity });
  }

  startDateChanged(arg, startDate, endDate) {
    if (this.isStartDateExceedsEndDate(startDate, endDate)) {
      this.InventoryReportFormGrp.controls[endDate].setValue(
        this.InventoryReportFormGrp.controls[startDate].value
      );
    }
    this.minEndDate = this.InventoryReportFormGrp.controls[
      startDate
    ].value;
  }

  isStartDateExceedsEndDate(stDate, edDate): boolean {
    const startDate = this.InventoryReportFormGrp.controls[stDate]
      .value;
    const endDate = this.InventoryReportFormGrp.controls[edDate]
      .value;
    return this.resetTime(startDate) > this.resetTime(endDate);
  }
  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }
}
