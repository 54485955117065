import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addwaitlistValidation'
})
export class AddwaitlistValidationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log("Saved");
    return value ? true : false;
  }

}
