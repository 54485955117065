import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CartDetail, CartDetailUI, CartItinerary, CartItineraryUI, CartPayload } from '../components/menu/vcart/vcart.modal';

@Injectable({
    providedIn: 'root'
})

export class IcartDataService {
    CartItemAdded = new Subject<CartPayload>();
    CartItemUpdated = new Subject<{ cartDetailId: number, cartItineraryId: number, cartPayload: CartPayload }>();
    DataReloadRequired = new Subject<boolean>();
    CartItinerarySelected = new Subject<CartItineraryUI>();
    CartItemEdited = new Subject<{ cartItinerary: CartItineraryUI, cartDetail: CartDetailUI }>();
    CartItemRemoved = new Subject<{ cartItinerary: CartItineraryUI, cartDetail: CartDetailUI }>();
    CartItineraryRemoved = new Subject<CartItineraryUI>();
    CartItineraryTransfered = new Subject<CartItineraryUI>();
    CartResult = new Subject<{ cartItinerary: CartItinerary, cartDetail: CartDetail, result: boolean }>();
    NewGuestCreateClicked = new Subject();
    NewGuestCreated = new Subject<{guestId: String, packageCode: String, arrivalDate: String, nights: String}>();
    RequestNewCard = new Subject();


    constructor() {

    }
}
