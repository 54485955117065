import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../../business/report-business.service';
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class TopSpenders extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    itemFilter : number;
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness
        ,public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.TopSpenders,

            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        this.itemFilter = customFilterData.itemFilter;
        const defaultOutlet =   customFilterData.unmodifiedOutlets[0].id;
        const defaultOutletDescription =  this.business.getFilterCaption(this.business.FilterDataSource["outlets"],  [defaultOutlet] );
        let outlets:string =  this.business.getFilterCaption(this.business.FilterDataSource["outlets"], 
        customFilterData.outlets.length > 0 ? customFilterData.outlets.filter(u => u.showInDropDown).map(x=>x.id) : customFilterData.defaultOutlets.map(x=>x.id));
        let reportcategoryCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allCategoryGroups"],
        customFilterData.reportcategory.length > 0  ? customFilterData.reportcategory.filter(u => u.showInDropDown).map(x=>x.id) : customFilterData.defaultcategorygroup.map(x=>x.id));
        let categoryCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["categories"],
        customFilterData.category.length > 0  ? customFilterData.category.filter(u => u.showInDropDown).map(x=>x.id) : customFilterData.defaultcategory.map(x=>x.id));
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) },
        {"pOutlets":  this.functionalities.ShowOutletSelectionFieldInTopSpendersReport ? outlets : defaultOutletDescription }
        ,{"pReportCategoryCaption":reportcategoryCaption},{"pCategoryCaption":categoryCaption},{"pSpenders": Number(customFilterData.topSpenders)},{ "pPageBreak": this.pageBreakToggle}
        ,{"pPropertyName":this.propertyName}
        ,{'pDate': this.printedDate},{"pItemfilter":Number(customFilterData.itemFilter)}
        ,{"pIncludeInactiveOutlets":customFilterData.includeInactiveOutlets}
        ,{"pIncludeInactiveCategoryGroups":customFilterData.includeInactiveCategoryGroups}
        ,{"pIncludeInactiveRetailCategories":customFilterData.includeInactiveRetailCategories},
        {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInTopSpendersReport ? true : false }];

    }

   
    protected formURIParams(): ReportParams[] {
        return [];
    }

    protected formFilters() {
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet =  [customFilterData.unmodifiedOutlets[0].id] ;
        let toApi = this.localization.convertDateObjToAPIdate;
        const tempOutlets = customFilterData.outlets.length === 0 ? customFilterData.defaultOutlets.map(x => x.id) :
            customFilterData.outlets.filter(u => u.showInDropDown).map(x => x.id);
            let filter : any;
        if(this.retailLocalization.isFromJobScheduler){
                const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
                const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
                const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
                const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
                filter = {
                    startDate: toApi(this.startDate),
                    endDate: toApi(this.endDate),
                    OutletIds :  this.functionalities.ShowOutletSelectionFieldInTopSpendersReport ? tempOutlets : defaultOutlet,
                    CategoryIds:  customFilterData.category.length === 0   ? customFilterData.defaultcategory.map(x=>x.id) : customFilterData.category.filter(u => u.showInDropDown).map(x=>x.id) ,
                    CategoryGroupIds: customFilterData.reportcategory === 0 ? customFilterData.defaultcategorygroup.map(x=>x.id) :customFilterData.reportcategory.filter(u => u.showInDropDown).map(x=>x.id),
                    Spenders :Number(customFilterData.topSpenders) ,
                    Itemfilter: Number(customFilterData.itemFilter),
                    fromWildCard,
                    toWildCard,
                    fromIncrement,
                    toIncrement 
    
                };
            }
            else
            {
        filter = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            OutletIds :  this.functionalities.ShowOutletSelectionFieldInTopSpendersReport ? tempOutlets : defaultOutlet,
            CategoryIds:  customFilterData.category.length === 0   ? customFilterData.defaultcategory.map(x=>x.id) : customFilterData.category.filter(u => u.showInDropDown).map(x=>x.id) ,
            CategoryGroupIds: customFilterData.reportcategory === 0 ? customFilterData.defaultcategorygroup.map(x=>x.id) :customFilterData.reportcategory.filter(u => u.showInDropDown).map(x=>x.id),
            Spenders :Number(customFilterData.topSpenders) ,
            Itemfilter: Number(customFilterData.itemFilter)

        };
    }
        filter.CategoryGroupIds = filter.CategoryGroupIds.length ===0 ?customFilterData.defaultcategorygroup.map(x=>x.id) :customFilterData.reportcategory.filter(u => u.showInDropDown).map(x=>x.id);
       
        return JSON.stringify(filter);
    }
}


