import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from 'src/app/eatecui/source/shared/services/formatters/utility.service';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

@Pipe({
  name: 'currencyConverter'
})
export class CurrencyConverterPipe implements PipeTransform {

  constructor(
    private utilityService: UtilityService) {

  }

  transform(value: any, extras?: any): unknown {
    if (extras) {
      const INIDecimalValue = sessionStorage.getItem('INICurrencyValue');
      const decimalValue = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength;
      const currencyCode = CommonService.getCurrencyCode(extras);
      const currenyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode, minimumFractionDigits: decimalValue, maximumFractionDigits: decimalValue }).format(value);
      const returnValue = CommonService.SetCurrencySymbolInUtility(currencyCode, currenyFormat);
      return returnValue;
    } else {
      if (isNaN(parseFloat(value.toString()))) {
        return value;
      } else {
        const CurrencyId = JSON.parse(sessionStorage.getItem('BaseCurrency'));
        const currencyCode = CommonService.getCurrencyCode(CurrencyId);
        const currenyFormat = this.utilityService.CurrencyFormatter.format(parseFloat(value.toString()));
        const returnValue = CommonService.SetCurrencySymbolInUtility(currencyCode, currenyFormat);
        return returnValue;
      }
    }
  }

}
