import { TranslateService } from '@ngx-translate/core';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { BreadCrumbConfiguration, TransactionConfiguration } from '../../transaction.interface';
import { ViewTransactionConfiguration } from '../view-configuration.interface';

export const ViewHeaderBreabcrumbConfiguration: ViewTransactionConfiguration = (
ViewHeaderEntity: any, 
ViewDetailsEntity: any, 
TransactionCollection: TransactionConfiguration, 
httpService: HttpService,
translateService?: TranslateService): BreadCrumbConfiguration[] => {
    try {
        if (TransactionCollection) {
            const breadcrumbArray = [];
            if ( TransactionCollection?.ViewTranscationConfig?.HeaderConfiguration?.BreadCrumbConfig.length > 0) {
                TransactionCollection.ViewTranscationConfig.HeaderConfiguration.BreadCrumbConfig.forEach((x: BreadCrumbConfiguration) => {
                  const entityName = (ViewHeaderEntity && ViewHeaderEntity.length > 0 && ViewHeaderEntity[0]['$']['Name']) ?
                    ViewHeaderEntity[0]['$']['Name'].toString() : TransactionCollection.OdataKey;
                  const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                  const breadCrumbConfiguration = {} as BreadCrumbConfiguration;
                  if (x.Type === 'text') {
                    breadCrumbConfiguration.Id = x.Id;
                    ProductDataRemap.FormDisplayNameRemapEngine(
                    productremapJson, entityName, x.DisplayName, translateService).then((data: any) => breadCrumbConfiguration.Name = data);
                    breadCrumbConfiguration.Type = x.Type;
                    breadCrumbConfiguration.EndPointUrl = x.EndPointUrl;
                    ProductDataRemap.FormDisplayNameRemapEngine(
                    productremapJson, entityName, x.DisplayName, translateService).then((data: any) =>
                     breadCrumbConfiguration.DisplayName = data);
                    breadCrumbConfiguration.DisableProperity = x.DisableProperity;
                    breadCrumbConfiguration.DataOption = [];
                    breadCrumbConfiguration.Authorization = x.Authorization;
                    if (x.hasOwnProperty('RoutingAction')) {
                      breadCrumbConfiguration.RoutingAction = x.RoutingAction;
                      breadCrumbConfiguration.RoutingUrl = x.RoutingUrl;
                    }
                    breadCrumbConfiguration.EnableBackArrow = x.EnableBackArrow;
                  } else {
                    breadCrumbConfiguration.Id = x.Id;
                    breadCrumbConfiguration.Name = x.Name;
                    breadCrumbConfiguration.Type = x.Type;
                    breadCrumbConfiguration.EndPointUrl = x.EndPointUrl;
                    breadCrumbConfiguration.DisplayName = x.DisplayName;
                    breadCrumbConfiguration.DisableProperity = x.DisableProperity;
                    breadCrumbConfiguration.DataOption = (x.DataOption.length > 0) ? x.DataOption : 
                    breadcrumbDataOption(x.EndPointUrl, httpService);
                    breadCrumbConfiguration.Authorization = x.Authorization;
                    breadCrumbConfiguration.SelectedTypeId = x.SelectedTypeId;
                    breadCrumbConfiguration.SelectedTypeUrl = x.SelectedTypeUrl;
                    breadCrumbConfiguration.EnableBackArrow = x.EnableBackArrow;
                  }
                  breadcrumbArray.push(breadCrumbConfiguration);
                });  
            }
            return breadcrumbArray; 
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function breadcrumbDataOption(EndPointUrl: string, httpService: HttpService) {
    try {
        httpService.GethttpMethod(EndPointUrl).subscribe(x => {

        });
      return [];
    } catch (erorr) {
        console.log(erorr);
    }

}