import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const CustomCostPercentCalc: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
    ): any => {
        try {
            if (FieldGrouping.CustomFormAction) {
                const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
                const VisibleDigit = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
                JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength;
                const Price = FormGroupData.value.Price;
                // Price = Number(Price) !== NaN && !isNaN(Number(Price)) && Number(Price) !== 0 ? Price : 1;
                if (Price.toString() === '0' || Price.toString() === '' ||
                    Number(Price) < 0 || isNaN(Number(Price))) {
                    const value = 100;
                    FormGroupData.controls['CostPercentage'].setValue(value.toFixed(6)); 
                    
                } else {
                    const CostRU = FormGroupData.value.CostRU;
                    if (CostRU && Price) {
                        const CostPercentage = CostRU !== 0 && Price !== 0 ? (CostRU / Price) * 100 : 0;
                        FormGroupData.controls['CostPercentage'].setValue(CostPercentage.toFixed(6));                    
                    } else {
                        const CostBU = FormGroupData.value.CostBU;
                        if (CostBU && CostRU) {
                            const CostPercentage = CostRU !== 0 && Price !== 0 ? (CostRU / CostBU) * 100 : 0;
                            FormGroupData.controls['CostPercentage'].setValue(CostPercentage.toFixed(6));         
                        }
                    }
                }
                CommonService.triggerBlurEvent('CostPercentage');
            }
        } catch (error) {
            console.log('error occured CustomDefaultSelect', error);
        }
    };