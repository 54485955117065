import _ from 'lodash';
import { GridSortConfig } from './grid-config.interface';
import { MasterInventoryManagement, SubFormConfigs } from '../master.interface';
import moment from 'moment';
import { StorageService } from 'src/app/eatecui/source/shared/services/storage.service';
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';

export const GridClientSort: GridSortConfig = (
    action: SubFormConfigs,
    MasterDataCollection: any,
    elementData: any,
    value: any
): Array<any> => {
    try {
        if (action && MasterDataCollection && elementData) {
            const orderBy = value[0].sort;
            const tempKey = value[0].colId;
            const gridItemList = action.NestedGridData;
            const key = gridItemList.GridHeaderDefination.filter(x => x.field === value[0].colId)[0].fieldType;
            const data = gridItemList.GridData;
            const sessionDateFormat = StorageService.GetSessionStorage(constants.IniDateFormat);
            const DateTimeFormat = (sessionDateFormat && sessionDateFormat !== '' && sessionDateFormat !== null) ?
        sessionDateFormat :  constants.DefaultIniDateFormat;
            if (key.toString() === 'date') {
                elementData.sort((a, b) => {
                    const data1 = a[tempKey] ? Number(moment(a[tempKey], DateTimeFormat).format('X')) : 0;
                    const data2 = b[tempKey] ? Number(moment(b[tempKey], DateTimeFormat).format('X')) : 0;
                    if (orderBy.toString() === 'desc') {
                        return data2 - data1;
                    } else {
                        return data1 - data2;
                    }

                });
            } else if (key.toString() === 'number') {
                elementData.sort((a, b) => {
                    const data1 = Number(a[tempKey]);
                    const data2 = Number(b[tempKey]);
                    if (orderBy.toString() === 'desc') {
                        return data2 - data1;
                    } else {
                        return data1 - data2;
                    }
                });
            } else if (key.toString() === 'string') {
                elementData.sort((a, b) => {
                    const data1 = a[tempKey] ? a[tempKey].replace(/\s/g, '').toLowerCase() : '';
                    const data2 = b[tempKey] ? b[tempKey].replace(/\s/g, '').toLowerCase() : '';
                    if (orderBy.toString() === 'desc') {
                        if (data2 < data1) {
                            return -1;
                        }
                        if (data2 > data1) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (data1 < data2) {
                            return -1;
                        }
                        if (data1 > data2) {
                            return 1;
                        }
                        return 0;
                    }

                });
            } else if (key.toString() === 'alphanumeric') {
                elementData.sort((a, b) => {
                    const data1 = a[tempKey] ? a[tempKey].replace(/\s/g, '').toLowerCase() : '';
                    const data2 = b[tempKey] ? b[tempKey].replace(/\s/g, '').toLowerCase() : '';
                    if (orderBy.toString() === 'desc') {
                        return data2.localeCompare(data1, undefined, {numeric: true});
                    } else {
                        return data1.localeCompare(data2, undefined, {numeric: true});
                    }
                });
            }
            return elementData;
        } else {
            return elementData;
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};