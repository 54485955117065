import { Component,  Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AgFieldConfig } from '../../models/ag-models';

@Component({
  selector: 'app-spa-ag-percentage',
  templateUrl: './ag-percentage.component.html',
  styleUrls: ['./ag-percentage.component.scss']
})
export class AgPercentageComponent {
  customClass: string;
  errorMessage: string;
  groupName: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string|number;
  disabled?:boolean;
  maxValue:number|string;
  minValue:number|string;
  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.groupName = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.disabled=value.disabled;
    this.maxValue = value.maxValue;
    this.minValue = value.minValue
  }
}
