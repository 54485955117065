import { Component, OnInit } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { menuTypes } from 'src/app/shared/shared.modal';

@Component({
  selector: 'app-interfaces',
  templateUrl: './interfaces.component.html',
  styleUrls: ['./interfaces.component.scss']
})
export class InterfacesComponent implements OnInit {

  relativePath: string;
  emailCaptions: any;
  menuList: any;
  menuType = menuTypes;
  captions;
  constructor(private _localization: SpaLocalization,private routeDataService: RouteLoaderService) {
    this.captions = this._localization.captions.settings;
    this.emailCaptions = this._localization.captions.settings.emailConfigurationsetup;

    const value = this.routeDataService.GetChildMenu('/settings/interfaces');
    this.menuList = {
      menu: value.linkedElement,
      menuType : menuTypes.tertiary
    };

  }

  ngOnInit() {
    
  }
}
