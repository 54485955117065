import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { PackagePopupComponent } from '../package-yielding/package-popup/package-popup.component';
import { PackageYield } from '../../shared/business/view-settings.modals';
import { MatDialog } from '@angular/material/dialog';
import { ViewSettingClientBusiness } from '../../shared/common-functionalities/business/view-settings.business';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Host, GridType, PromptType, SPAScheduleBreakPoint } from '../../shared/globalsContant';
import { SpaUtilities } from '../../shared/utilities/spa-utilities';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { BaseResponse, MoreFilterOptions } from '../../shared/business/shared.modals';
import { UserAlerts } from '../../core/config/alerts-config';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { SettingsService } from '../settings.service';

@Component({
    selector: 'app-package-yielding',
    templateUrl: './package-yielding.component.html',
    styleUrls: ['./package-yielding.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PackageYieldingComponent implements OnInit, OnDestroy {
    yieldAvailableArr: any = [];
    FrmGrp: UntypedFormGroup;
    minToDate: Date;
    editAvailable: any = true;
    public packagesList = [];
    selectedPackageArray: any = [];
    selectedPackageNamesArray: any = [];
    btnText: string;
    packageYieldArr: any = [];
    IsExits: boolean;
    captions: any = {};
    defaultFromDt: Date;
    defaultToDt: Date;
    public packageClass: any = [];
    public weekArray: any = [];
    tableoptions: any;
    @ViewChild('searchInput') searchInput: ElementRef;
    isUserAuthorized = true;
    isViewOnly = true;
    TableSearchText = '';
    checkedYieldArr = []
    isAllSelected = false;
    dialogSubscription: ISubscription;
    customAvailableValue: any = "";
    filterOptions: MoreFilterOptions;
    selectedWeekArray = [];
    packageYieldArrTemp = [];
    initialLoads = true;
    callCounter = 0;
    placeholderFormat: string;
    commonCaptions: any;
    floatLabel:string;

    constructor(public localization: SpaLocalization, private BP: BreakPointAccess,
        private http: HttpServiceCall,
        public dialog: MatDialog, private Form: UntypedFormBuilder,
        public business: ViewSettingClientBusiness,
        private utils: SpaUtilities, private userAlert: UserAlerts, private PropertyInfo: SpaPropertyInformation, private ss: SettingsService) {
        this.captions = this.localization.captions;
        this.placeholderFormat = this.localization.inputDateFormat;
        this.commonCaptions = this.localization.captions.common;
        this.defaultFromDt = this.PropertyInfo.CurrentDate;
        this.defaultToDt = new Date(this.defaultFromDt.getFullYear(), this.defaultFromDt.getMonth() + 1, 0);
        this.minToDate = this.defaultFromDt;
        this.FrmGrp = this.Form.group({
            startDate: this.defaultFromDt,
            endDate: this.defaultToDt
        });
        this.btnText = this.captions.setting.Apply;
        this.ss.tabLoaderEnable.next(true);
        this.floatLabel = this.localization.setFloatLabel;
    }

    ValidateBreakPoint(): void {
        this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingPackageYielding]);
        this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingPackageYielding);
    }

    calculateWidth() {
        if (this.searchInput) {
            const actionWidth = document.getElementById('actions').offsetWidth;
            const searchBoxWidth = document.getElementById('search-box').offsetWidth;
            const searchFilterWidth = document.getElementById('search-filter');
            const inputLength = this.searchInput.nativeElement.placeholder.length * 8 + 20;
            const requiredWidth = searchBoxWidth - actionWidth - 30;
            searchFilterWidth.style.width = inputLength > requiredWidth ? requiredWidth + 'px' : inputLength + 'px';
        }
    }
    resetFilter() {
        this.packageYieldArrTemp = this.packageYieldArr;
        this.selectedWeekArray = [];
        this.selectedPackageArray = [];
        this.selectedPackageNamesArray = [];
        this.FrmGrp.controls["startDate"].setValue(this.defaultFromDt);
        this.FrmGrp.controls["endDate"].setValue(this.defaultToDt);
        this.minToDate = this.defaultFromDt;
        this.makeGetCallwithParams("GetPackageYield", this.defaultFromDt, this.defaultToDt);
    }

    ngAfterViewInit() {
        this.calculateWidth();
    }
    RowSelect(event?: any, item?: any, Frm?: any) {
        if (event.From == 'All') {
            this.checkedYieldArr = [];
            if (event.event.checked) {
                for (let l = 0; l < this.packageYieldArrTemp.length; l++) {
                    this.checkedYieldArr.push(this.packageYieldArrTemp[l]);
                }
                this.btnText = this.captions.setting.ApplyAll;
            } else {
                this.checkedYieldArr = [];
                this.btnText = this.captions.setting.Apply;
            }
        } else {
            if (this.checkedYieldArr.indexOf(event.SelectedRow) == -1) {
                this.checkedYieldArr.push(event.SelectedRow);
                if (this.packageYieldArrTemp.length == this.checkedYieldArr.length) {
                    this.isAllSelected = true;
                }
            } else {
                this.checkedYieldArr.splice(this.checkedYieldArr.indexOf(event.SelectedRow), 1);
                if (this.packageYieldArrTemp.length > this.checkedYieldArr.length) {
                    this.isAllSelected = false;
                }
            }
            if (this.checkedYieldArr.length == 0) {
                this.isAllSelected = false;
                this.btnText = this.captions.setting.Apply;
            }
        }
    }


    OnFromDateValueChange($event) {
        let todate: Date;
        todate = this.FrmGrp.controls["endDate"].value;
        if (this.utils.getDate(todate) < this.utils.getDate($event.value)) {
            this.FrmGrp.controls["endDate"].setValue($event.value);
        }
        this.minToDate = this.utils.getDate($event.value);
        this.makeGetCallwithParams("GetPackageYield", this.utils.getDate($event.value), this.FrmGrp.controls["endDate"].value);
    }
    OnToDateValueChange($event) {
        this.makeGetCallwithParams("GetPackageYield", this.FrmGrp.controls["startDate"].value, this.utils.getDate($event.value));
    }
    applyall() {
        if (this.checkedYieldArr.length == 0) {
            this.business.openErrorDialog(this.captions.setting.PleaseSelectAtleastOneYieldInGrid, this.captions.common.Error, this.captions.setting.NoYieldSelected);
            return;
        } else if (this.customAvailableValue == "") {
            this.business.openErrorDialog(this.captions.setting.PleaseEnterAvailableValue, this.captions.common.Error, this.captions.setting.NoValueEntered);
            return;
        } else {
            const currentdate = this.utils.GetFormattedDate(this.PropertyInfo.CurrentDate);
            for (let i = 0; i < this.checkedYieldArr.length; i++) {
                const yielddate = this.utils.GetFormattedDate(this.checkedYieldArr[i].yieldDate);

                if (yielddate >= currentdate) {
                    this.checkedYieldArr[i].available = this.customAvailableValue;
                    if (this.customAvailableValue > 0 && this.customAvailableValue >= this.checkedYieldArr[i].sold) {
                        this.checkedYieldArr[i].remaining = this.customAvailableValue - this.checkedYieldArr[i].sold;
                    } else {
                        this.checkedYieldArr[i].remaining = 0;
                    }
                }
            }
            this.UpdateYieldAvailable(this.checkedYieldArr.map(x => {
                return {
                    id: x.id, yieldDate: x.yieldDate,
                    packageId: x.packageId,
                    available: x.available,
                    sold: x.sold,
                    remaining: x.remaining,
                    packageClass: x.packageClassId
                };
            }));
            this.isAllSelected = false;
        }
        this.checkedYieldArr = [];
    }

    editRow(event?: any, item?: any, action?: any) {
        const yielddate = this.utils.GetFormattedDate(event[0].yieldDate);
        const currentdate = this.utils.GetFormattedDate(this.PropertyInfo.CurrentDate);
        if (this.utils.getDate(yielddate) < this.utils.getDate(currentdate)) {
            return;
        } else {
            this.openPackageYield(event[2], event[0]);
        }
    }

    openPackageYield = (action?: any, item?: any) => {
        let title;
        let packageYieldJson;
        let defaultFromDt;
        let defaultToDt;
        if (action.toLowerCase() == 'new') {
            defaultFromDt = this.FrmGrp.controls['startDate'].value;
            defaultToDt = this.FrmGrp.controls['endDate'].value;
            title = this.captions.setting.NewPackageYielding;
            packageYieldJson = [];
        } else if (action.toLowerCase() == 'edit') {
            defaultFromDt = this.utils.getDate(item.yieldDate);
            defaultToDt = this.utils.getDate(item.yieldDate);
            title = this.captions.setting.EditPackageYielding;
            packageYieldJson = this.packageYieldArrTemp.filter(packageYield => packageYield.id == item.id);
        }
        const dataArray = {
            packageYieldArray: packageYieldJson,
            title: title,
            closebool: true,
            defaultDate: { fromDate: defaultFromDt, toDate: defaultToDt },
            action: action
        };
        const dialogRef = this.dialog.open(PackagePopupComponent, {
            width: '750px',
            height: '500px',
            disableClose: true,
            hasBackdrop: true,
            data: dataArray,
            panelClass: 'small-popup',
        });

        this.dialogSubscription = dialogRef.afterClosed().subscribe(() => {
            this.makeGetCallwithParams("GetPackageYield", this.FrmGrp.controls["startDate"].value, this.FrmGrp.controls["endDate"].value);
        });
    }

    toggleButtonClick = ($event, frm, Driven: any) => {
        if (Driven.id == 0) {
            if (frm == 'DOW') {
                let filteredWeekArray = this.utils.getToggleAllFilter(this.weekArray, this.selectedWeekArray);
                this.selectedWeekArray = this.weekArray.filter((element) => filteredWeekArray.includes(element.id));
            } else if (frm == 'PC') {
                let filteredPackageArray = this.utils.getToggleAllFilter(this.packageClass, this.selectedPackageArray);
                this.selectedPackageArray = this.packageClass.filter((element) => filteredPackageArray.includes(element.id));
            } else if (frm == 'PN') {
                let filteredPackageNamesArray  = this.utils.getToggleAllFilter(this.packagesList, this.selectedPackageNamesArray);
                this.selectedPackageNamesArray = this.packagesList.filter((element) => filteredPackageNamesArray.includes(element.id));
            }
            this.selectedFilter();
            this.BindGridData();
            return;
        }
        if (frm == 'DOW') {
            const selectedArraydeep = this.selectedWeekArray.map(r=>r.id);
            this.selectedWeekArray = [];
            let filteredWeekArray = this.utils.getToggleFilter(this.weekArray, selectedArraydeep, Driven);
            this.selectedWeekArray = this.weekArray.filter((element) => filteredWeekArray.includes(element.id));
            this.selectedFilter();
        } else if (frm == 'PC') {
            const selectedpackageArraydeep = this.selectedPackageArray.map(r=>r.id);
            this.selectedPackageArray = [];
            let filteredPackageArray = this.utils.getToggleFilter(this.packageClass, selectedpackageArraydeep, Driven);
            this.selectedPackageArray = this.packageClass.filter((element) => filteredPackageArray.includes(element.id));
            this.selectedFilter();
        } else if (frm == 'PN') {
            const selectedpackagenameArraydeep = this.selectedPackageNamesArray.map(r=>r.id);
            this.selectedPackageNamesArray = [];
            let filteredPackageNamesArray = this.utils.getToggleFilter(this.packagesList, selectedpackagenameArraydeep, Driven);
            this.selectedPackageNamesArray = this.packagesList.filter((element) => filteredPackageNamesArray.includes(element.id));
            this.selectedFilter();
        }
        this.BindGridData();
    }
    expand(name) {
        const ele = document.getElementById(name);
        const topPos = ele.offsetTop + 49,
            leftPos = ele.offsetLeft + 13;
        let selectedArr, fullArr;
        if (name == 'week') {
            selectedArr = this.selectedWeekArray;
            fullArr = this.weekArray;
        } else if (name == 'package') {
            selectedArr = this.selectedPackageArray;
            fullArr = this.packageClass;
        } else if (name == 'packName') {
            selectedArr = this.selectedPackageNamesArray;
            fullArr = this.packagesList;
        }
        fullArr.forEach(element => {
            element['description'] = element.name;
        });
        selectedArr = selectedArr.map(x => x.id);
        this.filterOptions = this.utils.getFilterPopUpOption(true, fullArr, selectedArr, topPos, leftPos, name, "PY");
    }
    filteredDataResponse(event) {
        let result = event[0];
        const ArrType = event[1];
        if (!result) { return; }
        result = result ? result : [];
        this.pushToSelectedArray(ArrType, result);
    }

    pushToSelectedArray(name, result) {
        if (name === 'package') {
            this.selectedPackageArray = this.packageClass.filter((element) => result.includes(element.id));
        } else if (name === 'packName') {
            this.selectedPackageNamesArray = this.packagesList.filter((element) => result.includes(element.id));
        } else {
            this.selectedWeekArray = this.weekArray.filter((element) => result.includes(element.id));
        }
        this.selectedFilter();
    }

    getMappedArray(sourceArray, selectedArray, prop) {
        let resultArray = [];
        if (selectedArray.length === 0) {
            resultArray = sourceArray.map(result => result[prop]);
        } else if (selectedArray.length > 0) {
            resultArray = selectedArray.map(result => result[prop]);
        }
        return resultArray;
    }

    selectedFilter() {
        let WeekArr = [];
        let PackArr = [];
        let PackageNameArr = [];
        const newWeekArr: any = [];
        WeekArr = this.getMappedArray(this.weekArray, this.selectedWeekArray, 'short');
        PackArr = this.getMappedArray(this.packageClass, this.selectedPackageArray, 'id');
        PackageNameArr = this.getMappedArray(this.packagesList, this.selectedPackageNamesArray, 'id');

        for (let i = 0; i < this.packageYieldArr.length; i++) {
            const element = this.packageYieldArr[i];
            const date = this.utils.getDate(element.yieldDate);
            const day = this.localization.getDayForDate(date);
            if(WeekArr.includes(day) && (this.selectedPackageArray.length == 0 || PackArr.includes(element.packageClassId)) && PackageNameArr.includes(element.packageId)){
                newWeekArr.push(element);
            }
        }
        this.packageYieldArrTemp = newWeekArr;
        this.BindGridData();
    }
    ngOnInit() {
       
        this.ValidateBreakPoint();
        this.makeGetCallsByDesc('GetAllSpaPackage');
        [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
        this.makeGetCallsByDesc('GetSpaPackageClass');
        [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
        this.weekArray = this.localization.daysArray.map(x => ({ id: x.id, name: x.long, short: x.short }))
    }

    ngOnDestroy() {
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
    }

    makeGetCallsByDesc(callDesc: string) {
        this.http.CallApiWithCallback<any>({
            host: Host.spaManagement,
            callDesc: callDesc,
            method: HttpMethod.Get,
            success: this.successCallback.bind(this),
            error: this.errorCallback,
            showError: true,
            extraParams: []
        });
    }

    makeGetCallwithParams(routeURL: string, fromDt: Date, toDt: Date) {
        const fromDtConverted = this.utils.convertDateFormat(fromDt);
        const toDtConverted = this.utils.convertDateFormat(toDt);
        this.http.CallApiWithCallback<any[]>({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: routeURL,
            uriParams: { fromDate: fromDtConverted, toDate: toDtConverted },
            method: HttpMethod.Get,
            showError: false,
            extraParams: ['dataBelongTo']
        });
    }

    OnAvailableYieldChange(evt, item) {
        this.yieldAvailableArr = [];
        const available = evt.currentTarget.value;
        const sold = item.sold;
        let remaining = 0;
        if (available > 0 && available >= sold) {
            remaining = available - sold;
        }
        item.remaining = remaining;

        this.yieldAvailableArr.push(item);
        this.UpdateYieldAvailable(this.yieldAvailableArr);

    }
    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
        const results = <any>result.result;
        switch (callDesc) {
            case "GetSpaPackageClass": {
                this.packageClass = results.map(x => ({ id: x.id, name: x.className }));
                [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
                [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(false, this.initialLoads, this.callCounter);
                this.makeGetCallwithParams("GetPackageYield", this.defaultFromDt, this.defaultToDt);
            } break;
            case "GetAllSpaPackage": {
                [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
                this.packagesList = results.map(x => ({ id: x.id, name: x.description }));
            } break;
            case "GetPackageYield": {
                [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
                const yieldInfo = this.formTableData(results);
                this.packageYieldArr = yieldInfo;
                this.packageYieldArrTemp = yieldInfo;
                this.BindGridData();
                this.selectedFilter();
            } break;
            case "UpdatePackageYield": {
                if (results) {
                    this.customAvailableValue = "";
                    const startDate = this.FrmGrp.controls["startDate"].value;
                    const endDate = this.FrmGrp.controls["endDate"].value
                    this.makeGetCallwithParams("GetPackageYield", startDate, endDate);
                }
            } break;
            case "DeletePackageYield": {
                if (result) {
                    const startDate = this.FrmGrp.controls["startDate"].value;
                    const endDate = this.FrmGrp.controls["endDate"].value
                    this.makeGetCallwithParams("GetPackageYield", startDate, endDate);
                }
            } break;
        }

    }

    formTableData(results: PackageYield[]) {
        results.forEach(element => {
            element.IsAvailableReadOnly = true;
        });
        return results;
    }

    errorCallback(callDesc): void {
        if (!this.initialLoads) {
            return;
        }
        switch (callDesc){
            case "GetPackageYield":
            case "GetSpaPackageClass":
            case "GetAllSpaPackage":
                    [this.initialLoads, this.callCounter] = this.ss.updateInitalLoads(true, this.initialLoads, this.callCounter);
                    break;
        }
    }

    promptUserForDelete(event) {
        this.userAlert.showPrompt(PromptType.Delete, this.deleteRecords.bind(this), event[0])
    }

    // Delete packages
    deleteRecords = (result: string, item) => {
        if (result.toLocaleLowerCase() != "ok") {
            return;
        }
        this.http.CallApiWithCallback<any[]>({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "DeletePackageYield",
            uriParams: { id: item.id },
            method: HttpMethod.Delete,
            showError: true,
            extraParams: []
        });

    }

    UpdateYieldAvailable(updatedYieldArr: any[]) {
        this.http.CallApiWithCallback<any[]>({
            host: Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.errorCallback.bind(this),
            callDesc: "UpdatePackageYield",
            uriParams: {},
            method: HttpMethod.Put,
            body: updatedYieldArr,
            showError: true,
            extraParams: []
        });
    }


    BindGridData() {
        /*2027 removed Percentage placeholder */
        this.tableoptions = [{
            TableHdrData: [{ "title": this.captions.common.Date, "jsonkey": "yieldDate", "datatype": "date", "sortable": true, "searchable": false },
            { "title": this.captions.setting.PackageCode, "jsonkey": "code", "sortable": true, "searchable": true },
            { "title": this.captions.setting.PackageDescription, "jsonkey": "description", "sortable": true, "searchable": true },
            { "title": this.captions.setting.PackageClass, "jsonkey": "packageClass", "sortable": true, "searchable": true },
            { "title": this.captions.setting.Available, "jsonkey": "available", "alignType": "right", "sortable": true, "searchable": false },
            { "title": this.captions.setting.Sold, "jsonkey": "sold", "displayType": "icon", "alignType": "right", "sortable": true, "searchable": false },
            { "title": this.captions.setting.Remaining, "jsonkey": "remaining", "alignType": "right", "sortable": true, "searchable": false }
            ],
            TablebodyData: this.packageYieldArrTemp,
            pagination: false,
            sortable: true,
            CustomColumn: true,
            PlaceHoldertext: this.captions.setting.PackageYieldSearchBy,
            EnableActions: false,
            EditMoreOption: true,
            SelectRows: false,
            IsCommission: true,
            SelectedSettingId: GridType.packageYielding,
            Searchable: true,
            TableSearchText: this.TableSearchText,
            isEditOptionRemove: true,
            Sortable: 'description',
            TableDraggable: false,
            IsReadOnly: this.isViewOnly
        }];
    }
}
