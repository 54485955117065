export interface FailedTransactions {
    id: number;
    paymentTransactionId: number;
    outletId: number;
    amount: number;
    retailTicketNumber: string;
    paymentMethod: string,
    transactionType: TransactionType,
    transactionTime: Date;
    status: number;
    errorMessage: string;
    detailedMessage: string;
    user: string | number;
    tDate: Date;
    tTime: Date;
    isCheckBoxDisabled?: boolean;
}
export enum TransactionType {
    Tokenize = 1,
    Sale = 2,
    SaleByToken = 3,
    VoidSale = 4,
    RefundSale = 5,
    Credit = 6,
    CreditByToken = 7,
    ACESPayment = 8,
    GiftCardLoad = 9,
    GiftCardIssue = 10,
    Others = 11,
    AuthorizationOnly = 12,
    AuthorizationOnlyByToken = 13,
    IncrementalAuthorization = 14,
    DecreaseAuthorization = 15,
    PriorAuthorizedSale = 16,
    ReAuth = 17
}

export interface BatchProcessInfo {
    Id: number;
    UserId: number;
    NumberOfRecords: number;
    Status: BatchProcessStatus;
    StartDateTime: string;
}

export enum BatchProcessStatus {
    Started,
    Completed,
    CompletedWithFailures
}

export interface TransactionsLogSearchRequest {
    ticketNumber: string;
    startDate: string;
    endDate: string;
    clerkIdList: number[];
    amount: number;
    pageStart: number;
}

export interface RefundFailureSearchRequest {
    ticketNumber: string;
    fromDate: string;
    toDate: string;
    clerkIdList: number[];
    amount: number;
    pageStart: number;
}

export interface TransactionSearchRequest {
    ticketNumber: string;
    startDate: string;
    endDate: string;
    clerkIdList: number[];
    amount: number;
    pageStart: number;
    outletId?: number;
    status?: string;
}

export interface TransactionSearchQuery {
    outletId: number;
    fromDate: string;
    toDate: string;
    status: string;
    amount: number;
    itemId?: number;
    ticketNumber: string;
    clerkIdList: number[];
    memberIdList: number[];
    guestIdList: number[];
    voidedByClerkIdList?: number[];
    retailTransactionType?: string;
    cardNumber: string;
    cardType: string;
    transactionAdditionalReference?: string;
}
