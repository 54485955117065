import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';


export const setHaccpType: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any, 
    MasterDataCollection: MasterInventoryManagement, 
    httpService: HttpService, 
    FieldGrouping: FormBuilderGroupKey, 
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if ( FieldGrouping.CustomFormAction.EnableCustomMethod ) {
            const selectedHaccpProcessCategoryId = FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value ? 
                     parseFloat(FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldFrom].value) : 0;
            if (FieldGrouping.CustomFormAction.Url === 'HaccpFoodTypes') {
                let catergoryName = '';         
                MasterDataCollection.NestedFormConfig.EditFormConfig.map(function(Resultset) {  
                   Resultset.NestedForm.filter(x => x.FormFields.filter(y => {
                      if (y.fieldType && y.fieldType.SingleData) {
                          if (y.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldFrom) {
                            catergoryName = y.fieldType.SingleData.options
                            .filter(opt => opt.key === selectedHaccpProcessCategoryId)[0]?.label;
                          }
                       }
                     }));
                }); 
                // Bug Fix - #205468
                const EndPointUrl =  `/inventory/api/${FieldGrouping.CustomFormAction.Url}?$filter= Id ne -1 and Category eq ` 
                + selectedHaccpProcessCategoryId;
                httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                    const responseData = res['value'];
                    const listData = responseData.map(person => ({ key: person.Id, label: person.Name + ' - ' + catergoryName }));
                    MasterDataCollection.NestedFormConfig.EditFormConfig.map(function(Resultset) {  
                    Resultset.NestedForm.filter(x => x.FormFields.filter(y => {
                       if (y.fieldType && y.fieldType.SingleData) {
                           if (y.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo) {
                            y.fieldType.SingleData.options = listData;
                            y.fieldType.SingleData.disbaledProperty = false;
                           }
                        }
                      }));
                   }); 
                });
            } else if (FieldGrouping.CustomFormAction.Url === 'HaccpTypeDescription') {
                const EndPointUrl =  `/inventory/api/${FieldGrouping.CustomFormAction.Url}?$filter=HACCPTypeId eq `
                 + selectedHaccpProcessCategoryId;
                httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                    const responseData = res['value'];
                    let haccptext = '';
                    responseData.forEach(element => {
                        haccptext = haccptext + element.Caption + '\n\n' ;
                        const descrArray = element.Description.split('.  ');
                        descrArray.forEach(descval => {
                            haccptext = haccptext + descval + '\n';
                        });
                        
                    });

                    MasterDataCollection.NestedFormConfig.EditFormConfig.map(function(Resultset) {  
                    Resultset.NestedForm.filter(x => x.FormFields.filter(y => {
                       if (y.fieldType && y.fieldType.SingleData) {
                           if (y.fieldType.SingleData.name === FieldGrouping.CustomFormAction.CutomFieldTo) {
                             y.fieldType.SingleData.value = haccptext;
                             FormGroupData.controls[FieldGrouping.CustomFormAction.CutomFieldTo].setValue(haccptext);
                           }
                        }
                      }));
                   }); 
                });
            }
        }        
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

