import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { RetailUtilities, InputTypeNumbers, InputTypeText } from "./retail-utilities";
import { RetailService } from "../../retail.service";
import { InputTypeValidator } from "./inputtypeValidator";


/*
  INPUTTYPE DIRECTIVES: InputTypeNumbers

  onlynumber      : Allows Negative & Positive Numbers.
  nonnegative     : Allows only Positive Numbers.
  onlynegative    : Allows only Negative Numbers.
  nodecimal       : Allows No Decimal Values.
  decimal         : Allows only Decimal Values.
  roundoff2       : Roundoff the values with 2 decimal palces.
  validPercentage : valid Percentage.
  onlyPositiveDecimal : Allows only Decimal Values with 2 decimal palces in positive digits. (e.g. 155.55)
  PositiveDecimalOrNumeric : Allows either Decimal Values with 2 digits or +ve Numbers. (e.g. 5.55 or 45)
  numberWithCommaSeparator: Allows only number with comma separated, (e.g. 22, 453, 532)

*/

@Directive({
  selector: "[inputtype]"
})

export class inputtypeDirective {

  private listOfNumberTypes: string[] = [InputTypeNumbers.NUMBERS,
                                         InputTypeNumbers.ONLYPOSITIVE,
                                          InputTypeNumbers.ONLYNEGATIVE,
                                           InputTypeNumbers.NODECIMAL,
                                            InputTypeNumbers.DECIMAL,
                                             InputTypeNumbers.ROUNDOFF,
                                              InputTypeNumbers.PERCENT,
                                               InputTypeNumbers.POSITIVEDECIMAL,
                                                InputTypeNumbers.POSITIVEDECIMALORNUMERIC,
                                                 InputTypeNumbers.NUMBERWITHSEPARATOR];
  private listOfTextType: string[] = [InputTypeText.CAP, InputTypeText.TEXT, InputTypeText.NOSPL, InputTypeText.NOSPACE, InputTypeText.EMAIL, InputTypeText.NOPRESPACE, InputTypeText.RESTRICTSCRIPT];

  constructor(private element: ElementRef, private control: NgControl, public localization: RetailLocalization, private utils: RetailUtilities, private business: RetailService) {

  }
  @Input() inputtype: string;
  @Input() skipInputValidation?: boolean = false;
  @Input() showErrPopup?: boolean = false;
  private givenListType: string[];
  private negativeSign: string = "-";
  private DecimalSeperator: string = this.localization.decimalSeparator;
  private CommaSeperator: string = ",";

  includesArrayValue(baseArray: string[], inputArray: string[]): boolean {

    return inputArray.every(function (value) {
      return (baseArray.indexOf(value) >= 0);
    });

  }

  @HostListener('blur', ['$event']) onblur(event) {
    if (this.skipInputValidation) {
      return;
    }
    let e = <KeyboardEvent>event;
    this.givenListType = this.inputtype.replace(/ /g, "").split(",");
    if (this.includesArrayValue(this.listOfNumberTypes, this.givenListType))
      this.OnBlurFunction(e);
    if (this.includesArrayValue(this.listOfTextType, this.givenListType))
      this.OnBlurFunction(e);

  }


  @HostListener('paste', ['$event']) 
  @HostListener('drop', ['$event']) 
  blockPaste(e: ClipboardEvent) {
    this.givenListType = this.inputtype.replace(/ /g, "").split(",");
    let valueEntered = e.clipboardData.getData('text/plain');
    let valueTobePasted: string = new InputTypeValidator(this.utils,this.localization, valueEntered, this.givenListType).Validate();
    if(this.givenListType.includes(InputTypeText.RESTRICTSCRIPT)){
      valueEntered = valueEntered.replace('&lt','');
      valueEntered = valueEntered.replace('&gt','');
      const forbiddenKeys = ['<', '>'];
      let containsForbiddenChars = forbiddenKeys.some(char => valueEntered.includes(char));
      if(containsForbiddenChars) {
        valueTobePasted = '';
        e.preventDefault();
      }
    }

    const slicedDataLength = this.control.valueAccessor['_elementRef'].nativeElement.maxLength;
    if (slicedDataLength > 0 && valueTobePasted.length > slicedDataLength) {
      let errorMsg = this.localization.captions.common.MaximumCharactersOnlyAllowed;
      errorMsg = errorMsg.replace('{num}', slicedDataLength);
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning, errorMsg);
      valueTobePasted = '';
    }


    this.control.control.setValue(valueTobePasted);
    e.preventDefault();
  }

  @HostListener('keyup', ['$event']) onkeyup(event) {
    if (this.skipInputValidation) {
      return;
    }
    let e = <KeyboardEvent>event;
    if (!this.inputtype) {
      this.inputtype = "";
    }
    this.givenListType = this.inputtype.replace(/ /g, "").split(",");
    if (this.includesArrayValue(this.listOfTextType, this.givenListType))
      this.InputTextType(event)
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.skipInputValidation) {
      return;
    }
    let e = <KeyboardEvent>event;
    if (!this.inputtype) {
      this.inputtype = "";
    }
    this.givenListType = this.inputtype.replace(/ /g, "").split(",");
    if (this.includesArrayValue(this.listOfNumberTypes, this.givenListType))
      this.InputTypeNumberValidation(e);
    if (this.includesArrayValue(this.listOfTextType, this.givenListType))
      this.InputTextType(e);

    if (this.givenListType.includes(InputTypeNumbers.ROUNDOFF)) {
      this.RestrictRoundoff2(e);
    }
  }

  OnBlurFunction(e: KeyboardEvent) {
    let input = (<HTMLInputElement>e.target);
    let valueEntered: string = input.value ? input.value : "";


    if (this.givenListType.includes(InputTypeNumbers.DECIMAL) || this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL)) {
      if (!valueEntered.includes(this.DecimalSeperator)) {
        this.control.control.setValue(valueEntered.trim() == "" ? "" : valueEntered.toString() + this.DecimalSeperator + "00");
        valueEntered = this.control.control.value;
      }
      else {
        let splitVal = valueEntered.split(this.DecimalSeperator);
        if (splitVal[1].length > 2) {
          let value: any = this.localization.currencyToSQLFormat(valueEntered);
          value = value.customToFixed();
          let localizedCurrency = this.localization.localizeCurrency(value, false);
          this.control.control.setValue(localizedCurrency);
          valueEntered = this.control.control.value;
        }
        else if (splitVal[1].length < 2) { 
          let value = splitVal[1].length == 0 ? "00" : splitVal[1] + "0";
          let roundedValue = splitVal[0] + this.DecimalSeperator + value;
          this.control.control.setValue(roundedValue);
          valueEntered = this.control.control.value;
        }
      }
    }

    if (this.givenListType.includes(InputTypeNumbers.PERCENT)) {
      if (valueEntered == '') {
        this.control.control.setValue("0.00");
      }
      if (parseFloat(valueEntered) > 100) {
        let errorMessage = this.localization.replacePlaceholders(this.localization.captions.retailsetup.MaximumDiscountValue, ['MaxNumber'], [this.localization.localizeCurrency('100.00', false)]);
        this.business.openErrorDialog(errorMessage, this.localization.captions.common.Error, this.localization.captions.common.Error);
        this.control.control.setValue("0.00");
      }
    }

    if (this.givenListType.includes(InputTypeText.EMAIL)) {
      if (!this.validateEmail(valueEntered)) {
        if (valueEntered.trim() != "") {
          this.control.control.setErrors({ 'incorrect': true })
        }
      }
    } 
    this.regexValidator(valueEntered)

  }

  regexValidator(valueEntered) {

    if (valueEntered.trim() != "") {
 
      if (this.givenListType.includes(InputTypeNumbers.NUMBERS) && !this.givenListType.includes(InputTypeNumbers.DECIMAL)) {
        if (!this.ValidateNumbers(valueEntered)) {
          this.control.control.setValue("");
          this.control.control.setErrors({ 'incorrect': true })
        }
      }
      else if (this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE) && !this.givenListType.includes(InputTypeNumbers.DECIMAL)) {
        if (!this.ValidateNonNegative(valueEntered)) {
          this.control.control.setValue("");
          this.control.control.setErrors({ 'incorrect': true })
        }
      }
      else if (this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMAL)) {
        if (!this.ValidatePositiveDecimal(valueEntered)) {
          this.control.control.setValue("");
          this.control.control.setErrors({ 'incorrect': true })
        }
      }
      else if (this.givenListType.includes(InputTypeNumbers.POSITIVEDECIMALORNUMERIC)) {
        valueEntered = valueEntered.indexOf(this.DecimalSeperator) !== -1 ? parseFloat(valueEntered).customToFixed() : valueEntered;
        this.control.control.setValue(valueEntered);
        if (!this.ValidatePositiveDecimalNumbers(valueEntered)) {
          this.control.control.setValue("");
          this.control.control.setErrors({ 'incorrect': true })
        }
      } else if (this.givenListType.includes(InputTypeNumbers.NUMBERWITHSEPARATOR)){
        const lastChar = valueEntered[valueEntered.length -1];
        const firstChar = valueEntered[0];
        if (lastChar === this.CommaSeperator){
          valueEntered = valueEntered.slice(0, -1);
          this.control.control.setValue(valueEntered);
        }
        if (firstChar === this.CommaSeperator){
          valueEntered = valueEntered.substr(1);
          this.control.control.setValue(valueEntered);
        }
        if (!this.validateCommaSeparatedPattern(valueEntered)) {
          this.control.control.setValue("");
          this.control.control.setErrors({ 'incorrect': true });
        }
      }
    }
  }

  ValidatePositiveDecimalNumbers(text) {
    if (this.ValidatePositiveDecimal(text))
      return true
    else
      return this.ValidateNonNegative(text);
  }


  ValidatePositiveDecimal(text) { 
    let regx = new RegExp("^\\d+(" + this.DecimalSeperator + "\\d{2})$");
    return regx.test(String(text).toLowerCase())
  }

  ValidateNonNegative(text) {
    let regx = /^\d+$/;
    return regx.test(String(text).toLowerCase())
  }

  ValidateNumbers(text) {
    let reg = /^-?[\d]+$/;
    return reg.test(String(text).toLowerCase())
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateCommaSeparatedPattern(text) {
    const reg = /^[\d]+(,[\d]+)*$/;
    return reg.test(String(text).toLowerCase());
  }
 

  InputTypeNumberValidation(e: KeyboardEvent): void {
    let input = (<HTMLInputElement>e.target);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : "";
    let lastChar = valueEntered[valueEntered.length -1];
    if (
      // Allow function keys
      (e.keyCode >= 112 && e.keyCode <= 123) ||
      //allow minus - onlynumber,only negative
      (input.selectionStart == 0 && (this.givenListType.includes(InputTypeNumbers.NUMBERS) || this.givenListType.includes(InputTypeNumbers.ONLYNEGATIVE)) &&
        e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length)) ||
      // Decimal Seperator - Region
      (!this.givenListType.includes(InputTypeNumbers.NODECIMAL) && valueEntered.indexOf(this.DecimalSeperator) == -1 && this.DecimalSeperator == e.key) ||
      (this.givenListType.includes(InputTypeNumbers.NUMBERWITHSEPARATOR) && this.CommaSeperator == e.key && lastChar != this.CommaSeperator) ||
      //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) ||
      //non negative , includes zero
      (this.givenListType.includes(InputTypeNumbers.ONLYPOSITIVE) && e.key == this.negativeSign)
    ) {
      e.preventDefault();
    }

  }

  omit_special_char(e) {
    // Allow function keys
    return (e.keyCode >= 112 && e.keyCode <= 123) ||
      //Allow default options like delete..
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      //Allow: Space
      (e.keyCode === 32) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39) ||
      !(/[^a-zA-Z0-9]/.test(e.key)); 
  }

  RestrictRoundoff2(e: KeyboardEvent) {
    let input = (<HTMLInputElement>e.target);
    let valueEntered: string = input.value;
    valueEntered = valueEntered ? valueEntered : "";
    if (valueEntered.indexOf(this.DecimalSeperator) >= 0) {
      let arr = valueEntered.split(this.DecimalSeperator);
      if (
        // Allow function keys
        (e.keyCode >= 112 && e.keyCode <= 123) ||
        //allow minus - onlynumber,only negative
        (input.selectionStart < 1 && (this.givenListType.includes(InputTypeNumbers.NUMBERS) || this.givenListType.includes(InputTypeNumbers.ONLYNEGATIVE)) &&
          e.key == this.negativeSign && (valueEntered.indexOf(this.negativeSign) == -1 || input.selectionEnd == valueEntered.length)) ||
        // Decimal Seperator - Region
        (!this.givenListType.includes(InputTypeNumbers.NODECIMAL) && valueEntered.indexOf(this.DecimalSeperator) == -1 && this.DecimalSeperator == e.key) ||
        //Allow default options like delete..
        [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39) || (input.selectionEnd > input.selectionStart) ||
        //allow input before decimal seperator
        (valueEntered.indexOf(this.DecimalSeperator) >= input.selectionStart)) {
        // let it happen, don't do anything
        return;
      }
      let noOfDigits=2
      if(sessionStorage.getItem('noOfDecimalDigits')){
        let propDigits = parseInt(sessionStorage.getItem('noOfDecimalDigits'));
        if(!isNaN(noOfDigits)){
          noOfDigits = propDigits;
        }
      }
      if (arr[1].length > (noOfDigits-1)) {
        e.preventDefault();
      }
    }
  }

  InputTextType(e: KeyboardEvent): void {

    if (this.givenListType.includes(InputTypeText.NOSPL)) {
      if (!this.omit_special_char(e)) {
        if (this.showErrPopup) {
          this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.common.Specialcharactersarenotallowed);
        }
        e.preventDefault();
        return;
      }
    }

    if (this.givenListType.includes(InputTypeText.TEXT)) {
      if (Number(e.key) > 0) {
        e.preventDefault();
        return;
      }
    }
    if (this.givenListType.includes(InputTypeText.CAP)) {
      let value = this.element.nativeElement.value;
      value = value ? value.toUpperCase() : "";
      this.control.control.setValue(value);
    }
    if (this.givenListType.includes(InputTypeText.NOSPACE)) {
      if (e.which === 32) {
        e.preventDefault();
      }
    }
    if (this.givenListType.includes(InputTypeText.NOPRESPACE)) {
      let value = this.element.nativeElement.value;
      value = value ? value : "";
      if (e.which === 32 && value.trim().length === 0) {
        this.control.control.setValue(value.trim());
        e.preventDefault();
      }
    }  

    if(this.givenListType.includes(InputTypeText.RESTRICTSCRIPT)){
      let value = this.element.nativeElement.value;
      value = value ? value : "";
      value = value.replace('&lt','');
      value = value.replace('&gt','');
      this.control.control.setValue(value);
      const forbiddenKeys = ['<', '>'];
      if (forbiddenKeys.includes(e.key)) {
        e.preventDefault();
      }
    }

  }
}
