import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DropDownData } from '../../../business/report.modals';
import { HttpServiceCall } from '../../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../../shared/utilities/spa-utilities';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
@Component({
  selector: 'app-revenue-summary',
  templateUrl: './revenue-summary.component.html',
  styleUrls: ['./revenue-summary.component.scss']
})
export class RevenueSummaryComponent implements OnInit {
  RevenueSummaryFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  selectedDropdownType: string = '';
  valuesSelected: any[];
  users: any[];
  constructor(private fb: UntypedFormBuilder, public localization: SpaLocalization, private http: HttpServiceCall, private utils: SpaUtilities, public business: ReportBusinessService, private dataService: ReportDataService) { }

  ngOnInit() {
    this.RevenueSummaryFormGrp = this.fb.group({
      users: new UntypedFormControl([this.defaultData])
    });
    this.formReady.emit(this.RevenueSummaryFormGrp);
    this.onLoad();
  }

  private async onLoad() {
    this.users = await this.dataService.GetAllUsers();
    this.business.FilterDataSource["allUsers"] = this.users.filter(u=>u.showInDropDown );
  }

  getFormControlValue(event) {
    if (event[0] === 'users') {
    this.RevenueSummaryFormGrp.setControl('users', event[1]);
    }
  }
}
