import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, ViewEncapsulation, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { DataType } from '../../models/ag-models';
@Component({
  selector: 'app-spa-chip-search',
  templateUrl: './chip-search.component.html',
  styleUrls: ['./chip-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChipSearchComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new UntypedFormControl();
  filteredData: DataType[];
  @ViewChild('chipInput', { static: false }) chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  captions: any;
  originalPlaceholder: string;

  @Input('placeholderText')
  set _placeHolder(value: string) {
    this.placeholder = value;
    this.originalPlaceholder = value;
  }
  @Input() searchKey: string[];
  @Input() selectedChipKey: string[];
  @Input() selectedChipKeySeperator = ',';
  @Input() autoCompleteKeys: string[];
  @Input() autoCompleteKeysSeperator = '|';
  @Input() maxChips = 1;
  @Input() disabled: boolean;
  @Input() searchMaxCharLength: number;

  chipData: any[] = [];
  searchedText: string;
  @Input('allData')
  set _allData(data: any[]) {
    this.chipData = ((this.searchedText && this.searchedText.length > 0) && (data && data.length > 0)) ? data : [];
    this.filteredData = ((this.searchedText && this.searchedText.length > 0) && (data && data.length > 0)) ? data : [];
  }

  @Input() selectedData: any[] = [];
  @Output() selectedChipDataEmit: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedChipDataEmit2: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() searchTextEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChipRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOnBlur: EventEmitter<any> = new EventEmitter<any>();
  @Input() fromComponentNumber: number;
  placeholder: string;
  constructor(private localization: SpaLocalization) {
    this.captions = this.localization.captions.common;
  }

  public searchText(value: string): void {
    const txt = value.trim();
    this.searchedText = txt;
    this.searchTextEmit.emit(txt);
  }

  remove(selectedElement: DataType, fromComponent?:number): void {
    if (this.disabled) {
      return;
    }
    const index = this.selectedData.indexOf(selectedElement);

    if (index >= 0) {
      this.selectedData.splice(index, 1);
    }
    this.disabled = false;
    this.filterData();
    this.checkPlaceHolder();
    this.chipDataEmitter(fromComponent);
  }

  selected(event: MatAutocompleteSelectedEvent, fromComponent?:number): void {
    if (this.selectedData.length < this.maxChips) {
      this.selectedData.push(event.option.value);
      this.chipInput.nativeElement.value = '';
      this.chipCtrl.setValue(null);
      this.checkPlaceHolder();
      this.filterData();
      this.chipDataEmitter(fromComponent);
    }
  }

  chipDataEmitter(fromComponent?){
    if(fromComponent === 0){
      this.selectedChipDataEmit.emit(this.selectedData);
    }
    else if(fromComponent === 1){
      this.selectedChipDataEmit2.emit(this.selectedData);
    }
    else{
      this.selectedChipDataEmit.emit(this.selectedData);
    }
  }

  private filterData() {
    this.filteredData = this.chipData.filter(data => (!this.selectedData.find(existingData => existingData.id === data.id)));
  }

  checkPlaceHolder() {
    if (this.selectedData.length > 0) {
      this.placeholder = '';
    } else {
      this.placeholder = this.originalPlaceholder;
    }
  }

  ngOnInit() {
    this.checkPlaceHolder();
  }

  ngOnChanges(){
    this.checkPlaceHolder();
  }

  onRemove(event) {
    if(!this.disabled)
    {
      this.onChipRemove.emit(event);
    }    
  }

  onBlur(event){
    this.emitOnBlur.emit(event);
    this.chipInput.nativeElement.value = '';
    this.chipCtrl.setValue(null); 
  }
}
