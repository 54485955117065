import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridSortAction, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridSortConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): any => {
        try {
            // let sortOrder = '';
            // if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
            //     if ( ViewHeaderEntity.GridConfig && ViewHeaderEntity.GridConfig.GridSort ) {
                    // const sort = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSort
                    // .filter( ( sortorder: GridSortAction) => 
                    // sortorder.DefaultSorting === true);
                    // sortOrder = sort[0].SortingOdataKey + ' ' + sort[0].SortingType.toLowerCase();
            //     }
            // } 
            return TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSort;
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };