import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { AgFieldConfig } from '../../payment-manager/shared/shared-model';

@Component({
  selector: 'app-edit-custom-fee',
  templateUrl: './edit-custom-fee.component.html',
  styleUrls: ['./edit-custom-fee.component.scss']
})
export class EditCustomFeeComponent implements OnInit {

  editCustomFeeForm: UntypedFormGroup;
  priority = [];
  captions:any;
  inputData: any;
  percentageConfig: AgFieldConfig;

  constructor(private fb: UntypedFormBuilder,		
    public dialog: MatDialog,
		public utils: RetailUtilities,
		public localization: RetailLocalization,
    private dialogRef:MatDialogRef<EditCustomFeeComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.initializeForm();
    this.captions = this.localization.captions;
    this.priority = this.getPrioirty();
    this.bindData();
  }

  bindData() {
    this.editCustomFeeForm.controls.isPercentage.setValue(this.data.isPercentage ? 1 : 2);
    if (this.data.isPercentage) {
      this.editCustomFeeForm.controls.percentValue.setValue(this.data.value);
    }
    else {
      this.editCustomFeeForm.controls.flatAmount.setValue(this.data.value);
    }
    this.editCustomFeeForm.markAsDirty();
    this.percentageConfig = {
      maxValue: 100
    };
  }

  initializeForm(): void {
    this.editCustomFeeForm = this.fb.group({
      isPercentage: 1,
      percentValue: [0, Validators.maxLength(100)],
      flatAmount: 0
    });
  }

  getPrioirty() {
    return [
      { 'id': 1, 'name': this.captions.lbl_percentage_customfee },
      { 'id': 2, 'name': this.captions.lbl_flatAmount_customfee },
    ]
  }

  apply() {
    let result = {
      isPercentage: this.editCustomFeeForm.controls.isPercentage.value,
      value: this.editCustomFeeForm.controls.isPercentage.value == 1 ? this.editCustomFeeForm.controls.percentValue.value : this.editCustomFeeForm.controls.flatAmount.value,
    }
    this.dialogRef.close(result);
  }
  isFormValid() {
    return (this.editCustomFeeForm.dirty && this.editCustomFeeForm.valid 
      && (this.editCustomFeeForm.controls.isPercentage.value == 1 ? this.editCustomFeeForm.controls.percentValue.value > 0 : this.editCustomFeeForm.controls.flatAmount.value > 0));
  }
  close() {
    this.dialogRef.close()
  }

}
