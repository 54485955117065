import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType, DialogCloseEnum, AlertAction } from 'src/app/common/enums/shared-enums';
import { AgFieldConfig, AgToggleConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { Localization } from '../../../localization/Localization';
import { CommonUtilities, Product } from '../../utilities/common-utilities';
import { DMBusiness } from './data-migration-configuration.business';
import { MatDialogRef } from '@angular/material/dialog';
import { API,UI } from 'src/app/common/Models/datautilitydetails-model'
@Component({
  selector: 'app-Data-Migration-configuration',
  templateUrl: './data-migration-configuration.component.html',
  styleUrls: ['./data-migration-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DMBusiness]
})
export class DataMigrationConfigComponent implements OnInit {
   @Output() handleClickEvent = new EventEmitter();
  datamigrationConfiguration: string;
  captions: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  backButton: ButtonValue;
  dialogCloseEnum = DialogCloseEnum;
  DMDefaults: UntypedFormGroup;
  versaData: API.VersaSettings;
  commonData: API.CommonSettings;
  id = 0;
  isSaveDisabled: boolean = true;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  floatLabel: string;
  settingType: number;
  skipAdvanceSearchInput: AgToggleConfig;
  allowGuestIdOverrideInput: AgToggleConfig;
  importCounter: AgFieldConfig;
  defaultImportLimit: number = 20000;
  currentProduct: number;
  isDisplayConfig : boolean = false;
  versaSettingsId: number = 0;
  commonSettingsId: number = 0;

  constructor(
    private localization: Localization,
    private fb: UntypedFormBuilder,
    private DMBusiness: DMBusiness,
    private dialogRef: MatDialogRef<DataMigrationConfigComponent>,
    private utilities: CommonUtilities) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.currentProduct = Number(this.utilities.GetPropertyInfo('ProductId'));
  }

  ngOnInit() {   
    this.datamigrationConfiguration = this.captions.datamigrationConfiguration;
    this.isDisplayConfig = this.currentProduct === Product.PMS ? true : false; 
    this.initializeinputs(); 
    this.loadDMDefaults();
  }  

  async loadDMDefaults() {
    if(this.currentProduct == Product.PMS) {      
      this.settingType = API.ProductSetting.CommonSettings | API.ProductSetting.VersaSettings;
    }
    else {  
      this.settingType = API.ProductSetting.CommonSettings;        
    }
    this.utilities.ToggleLoader(true);
    await this.DMBusiness.getAllConfiguration(this.settingType).then(response => {
      if(response != undefined && response.length > 0 && response != null)
      {
        let responseSettings = response;
        let versaSettings:API.Setting = responseSettings.find(x => x.settingType == API.SettingKeys.VersaSettings);
        let commonSettings: API.Setting = responseSettings.find(x => x.settingType ==API.SettingKeys.CommonSettings);
        this.versaData = versaSettings != undefined ? JSON.parse(versaSettings?.value) : undefined;
        this.commonData = commonSettings != undefined ? JSON.parse(commonSettings?.value) : undefined;  
        this.versaSettingsId = versaSettings != undefined ? versaSettings.id : 0;
        this.commonSettingsId = commonSettings != undefined ? commonSettings.id : 0;     
        let maxCountOfImport = this.commonData != undefined ? this.commonData.maximumImportCount : this.defaultImportLimit; 
        let isSkipGuestSearch = this.versaData != undefined ? this.versaData.skipGuestAdvanceSearch : false;
        let isGuestIdOverride = this.versaData != undefined ? this.versaData.allowOverrideGuestGuid : false;
        this.DMDefaults.get('maximumImportCount').patchValue(maxCountOfImport);
        this.DMDefaults.get('skipAdvanceSearch').patchValue(isSkipGuestSearch);
        this.DMDefaults.get('allowGuestIdOverride').patchValue(isGuestIdOverride);
      } else {
        this.DMDefaults.get('maximumImportCount').patchValue(this.defaultImportLimit);
        this.DMDefaults.get('skipAdvanceSearch').patchValue(false);
        this.DMDefaults.get('allowGuestIdOverride').patchValue(false);
      }
      this.utilities.ToggleLoader(false);
    });
    this.DMDefaults.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() =>{
    this.saveButton.disabledproperty = !(this.DMDefaults.valid && this.DMDefaults.dirty);
    this.isSaveDisabled = this.saveButton.disabledproperty;
    this.saveButton = { ... this.saveButton };
    }); 
  }

  ngDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  formGenerator() {
    this.DMDefaults = this.fb.group({      
      skipAdvanceSearch: false,
      allowGuestIdOverride: false,
      maximumImportCount: ''
    })
  }

  initializeinputs() {
    this.formGenerator(); 
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.bookAppointment.Reset,
      disabledproperty: false
    };

    this.skipAdvanceSearchInput = {
      group: this.DMDefaults,
      horizontal: true,
      formControlName: 'skipAdvanceSearch',
      disabled: false
    };
    this.allowGuestIdOverrideInput = {
      group: this.DMDefaults,
      horizontal: true,
      formControlName: 'allowGuestIdOverride',
      disabled: false
    };
    this.importCounter = {
      className: 'mt-0',      
      form: this.DMDefaults,
      formControlName: 'maximumImportCount',
      isRequired: true,
      disabled: false,
      minValue: 1,
      maxValue: 30000,
      placeHolder: this.captions.lbl_maxmimumImportRecordCount
    };
  }

  public onChange(event) {
    if(!this.DMDefaults.hasError){
      this.enableSave();
    }            
  }

  close(e) {
    if(!this.isSaveDisabled)
    {
      this.utilities.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.dialogRef.close(); 
        }
      });
    }
    else
    {
      this.dialogRef.close(); 
    }
  }

  onSave(e) {
    this.disableSave();   
    const dataToSave = {...this.DMDefaults.getRawValue()};
    const settingIds = {
      commonSettingsId : this.commonSettingsId,
      versaSettingsId : this.versaSettingsId
    }
    this.DMBusiness.saveDMDefaults(dataToSave, settingIds).then(() => {
      this.utilities.showAlert(this.captions.success_msg, AlertType.Success, ButtonType.Ok,(res) => {
        if(res){
          this.dialogRef.close();
        }
      })});  
  }

  OnInputChange(e) { 
    if(!this.DMDefaults.hasError){
      this.enableSave();
    } 
  }

  private disableSave() {
    this.saveButton.disabledproperty = true;
    this.isSaveDisabled = this.saveButton.disabledproperty;
    this.saveButton = { ...this.saveButton };
  }

  private enableSave() {
    this.saveButton.disabledproperty = false;
    this.isSaveDisabled = this.saveButton.disabledproperty;
    this.saveButton = { ...this.saveButton };
  }

  onCancel(e) {
    if(!this.isSaveDisabled)
    {
      this.utilities.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.formGenerator();
          this.disableSave();
          this.initializeinputs();
        }
      });
    }
    else
    {
      this.formGenerator();
      this.disableSave();
      this.initializeinputs();
    }
  }
}
