import { ViewGridHeaderFieldType } from './grid-header-fieldtype.interface';
import { ViewGridHeaderNameConfig } from './grid-header-name.interface';
import { ViewGridHeaderType } from './grid-header-type.interface';
import { ViewGridSortDirection } from './grid-sort-direction.interface';

export const GridHeaderTransaction = {
  GridHeaderNameConfig: ViewGridHeaderNameConfig,
  GridHeaderSortDirection: ViewGridSortDirection,
  GridHeaderTypeConfig: ViewGridHeaderType,
  GridHeaderFieldTypeCOnfig: ViewGridHeaderFieldType
};