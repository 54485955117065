import { AgDateAttributes, AgDateType } from '../../Models/ag-models';
import { Localization } from '../../shared/localization/Localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
export class AgDateBehaviour {

    private default_max_date: Date = new Date(8640000000000000);
    private default_min_date = new Date(-8640000000000000);

    inputTypes: AgDateAttributes[] = [
        {
            type: AgDateType.DEFAULT,
            minDate: this.default_min_date,
            maxDate: this.default_max_date,
            toDomMapper: (value: string | Date) => this.convertDateToDomCompatible(value),
            toFormMapper: (value: Date) => this.convertDateToForm(value),
            customClass: ''
        }
    ];

    constructor(public localization: Localization, public utils: CommonUtilities) {
    }

    private convertDateToForm(value: Date): string {
        if (!value) {
            // In Case of Intializing Date Picker with Empty Value ( No Date Selected Scenario's)
            return '';
        }
        return this.localization.ConvertDateToISODateTime(value);
    }

    private convertDateToDomCompatible(value: string | Date): Date {
        let domValue: Date = undefined;

        if (value && typeof value === 'string' && Date.parse(value)) {
            domValue = this.localization.getDate(value);
        } else if (typeof value === 'object' && (value instanceof Date)) {
            domValue = value;
        }

        this.validateDate(value, domValue);
        return domValue;
    }


    validateDate(value: string | Date, domValue: Date | undefined) {
        if (domValue == undefined) {
            if (typeof value === 'string') {
                this.utils.safeAlert("Given Date string is not valid");
            } else {
                this.utils.safeAlert("Invalid Date");
            }
        }
    }

}