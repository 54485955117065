import { Component, OnInit, ViewEncapsulation,Input } from '@angular/core';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { Host,HISTORY_DETAILS_DAYS_RANGE,ActivityType,ActivityStatus } from '../../../globalsContant';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import { BaseResponse, TransactionWithItemNumber } from '../../../../shared/business/shared.modals';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { GuestRetailTransactionHistory,Transaction } from '../../../business/shared.modals';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { MultipackAPIModel, MultipackUIModel,MultpackHistoryRequest } from '../../../business/multipack.model';
import { Guid } from 'guid-typescript';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { HistoryBusiness } from './transaction-history.business';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { LockerBookingSourceType } from 'src/app/shared/add-lockers/lockers.model';
import { ClassService } from 'src/app/class/class.services';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HistoryBusiness]
})
export class TransactionHistoryComponent implements OnInit {

  historyTypes: any = [];
  selectedRowItem: any;
  historyType: any;
  tableHeaderArray: any = [];
  tableDataArray: any = [];
  itemArray: any = []
  allTherapists: any = [];
  allServices: any = [];
  frequentlyPurchased = [];
  salesHistory = []; 
  appointmentDetails = [];
  cancellations = [];
  teeTimes = [];
  activities = [];
  transactionDetails = []
   
  captions: any;
  clientAppointments: any[];
  clientWindowConvertion : Subscription;
  isFirstTime: boolean = true;
  guestMultipackAPIData: Array<MultipackAPIModel>;
  guestMultipacks: Array<MultipackUIModel>;
  showMultiPackExpiredFlag:boolean = false;
  floatLabel:string;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent= [];
  lockerClientHistory = [];
  serviceMap  = [] ;
  searchText: string;
  timeformat: number;
  includeLockerAssignment: boolean;
  @Input('changedClientData')
  set changeClientData(value){
    if(value){
      this.initializeFormData();
    }
  }

  constructor(public localization: SpaLocalization, private http: HttpServiceCall, private appointmentPopupService: AppointmentpopupService, private utilities: SpaUtilities,
    public PropertyInfo: RetailPropertyInformation, private business:HistoryBusiness, public _featureFlagInfo: RetailFeatureFlagInformationService, public classService: ClassService) {
    this.captions = this.localization.captions.bookAppointment;
    this.floatLabel = this.localization.setFloatLabel;
    this.includeLockerAssignment = this._featureFlagInfo.includeLockerAssignment ;
    this.historyTypes = [{ "id": "1", "name": this.captions.FrequentlyPurchasedItems },
    { "id": "2", "name": this.captions.SalesHistory },
    { "id": "3", "name": this.captions.AppointmentDetails },
    { "id": "4", "name": this.captions.CancellationsandNoShows },
    { "id": "5", "name" : this.captions.TeeTimes},
    { "id": "7", "name" : this.captions.multipack_details}
  ]
  if(this._featureFlagInfo.IsLockersEnabled){
    this.historyTypes.push( { "id": "8", "name" : this.captions.lbl_lockers });
  }
  }

  changeType() {
    this.tableDataArray = [];
    this.tableHeaderArray = [];

    switch (this.historyType) {
      case "1":
        this.tableHeaderArray = [{ tableHeader: this.captions.Quantity, keyValue: "quantity", alignType: "right" },
        { tableHeader: this.captions.ItemNumber, keyValue: "itemNumber", alignType: "right" },
        { tableHeader: this.captions.Description, keyValue: "description", alignType: "left" }];
        this.tableDataArray = this.frequentlyPurchased;
        break;

      case "2":
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: "date", alignType: "left" },
        { tableHeader: this.captions.TransactionNo, keyValue: "transaction", alignType: "right" },
        { tableHeader: this.captions.GrandTotal + ` (${this.localization.currencySymbol})`, keyValue: "grandTotal", alignType: "left" },
        { tableHeader: this.captions.SubTotal + ` (${this.localization.currencySymbol})`, keyValue: "subTotal", alignType: "left" },
        { tableHeader: this.captions.Tax + ` (${this.localization.currencySymbol})`, keyValue: "tax", alignType: "left" },
        { tableHeader: this.captions.Gratuity + ` (${this.localization.currencySymbol})`, keyValue: "gratuity", alignType: "left" },
        { tableHeader: this.captions.Discount + ` (${this.localization.currencySymbol})`, keyValue: "discount", alignType: "left" }];

        this.tableDataArray = this.salesHistory;
        this.selectedRow(this.salesHistory[0]);
        break;

      case "3":
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: "appointmentTime", alignType: "left" },
        { tableHeader: this.captions.TransactionNo, keyValue: "transaction", alignType: "right" },
        { tableHeader: this.captions.Service, keyValue: "service", alignType: "left" },
        { tableHeader: this.captions.TherapistName, keyValue: "therapist", alignType: "left" }];
        this.tableDataArray = this.clientAppointments.filter(x => x.status == 'RESV' || x.status == 'CKIN' || x.status == 'CKOUT');
        _.sortBy(this.tableDataArray, [function(data) {
          return data.appointmentTime;
        }]);
        this.tableDataArray.reverse();
        break;

      case "4":
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: "appointmentTime", alignType: "left" },
        { tableHeader: this.captions.TransactionNo, keyValue: "transaction", alignType: "right" },
        { tableHeader: this.captions.Service, keyValue: "service", alignType: "left" },
        { tableHeader: this.captions.TherapistName, keyValue: "therapist", alignType: "left" },
        { tableHeader: this.captions.CancellationNumber, keyValue: "cancelId", alignType: "left" },
        { tableHeader: this.captions.CancellationComments, keyValue: "cancelComments", alignType: "left" }];
        this.tableDataArray = this.clientAppointments.filter(x => x.status == 'CANC' || x.status == 'NOSHOW' || x.isTrackNoshow);
        _.sortBy(this.tableDataArray, [function(data) {
          return data.appointmentTime;
        }]);
        this.tableDataArray.reverse();
        break;

      case "5":
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: "date", alignType: "left" },
        { tableHeader: this.captions.TransactionNo, keyValue: "transaction", alignType: "right" },
        { tableHeader: this.captions.Course, keyValue: "course", alignType: "left" },
        { tableHeader: this.captions.Status, keyValue: "status", alignType: "left" }];
        this.tableDataArray = this.teeTimes.filter(s=> s.activityType.toLowerCase()==ActivityType.GOLF.toLowerCase())
        .map(o=> {o.status = this.mapActivityStatusToGolfStatus(o.status); return o});;
        break;

      case "6":
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: "date", alignType: "left" },
        { tableHeader: this.captions.TransactionNo, keyValue: "transaction", alignType: "right" },
        { tableHeader: this.captions.Activity, keyValue: "activity", alignType: "left" },
        { tableHeader: this.captions.Status, keyValue: "status", alignType: "left" }];
        this.tableDataArray = this.activities;
        break;
      case "7":
        this.tableHeaderArray = [{ tableHeader: this.captions.dateandTime, keyValue: "DateRedeemed", alignType: "left" },
        { tableHeader: this.captions.multipack, keyValue: "MultipackDescription", alignType: "left" },
        { tableHeader: this.captions.dateofsale, keyValue: "DateOfSale", alignType: "left" },
        { tableHeader: this.captions.redeemSession, keyValue: "RedeemedCount", alignType: "left" },
        { tableHeader: this.captions.remainingsession, keyValue: "RemainingCount", alignType: "left" },
        { tableHeader: this.captions.dateofexpiry, keyValue: "DateOfExpiry", alignType: "left" },
        { tableHeader: this.captions.product, keyValue: "Product", alignType: "left" }];
        if(this.guestMultipackAPIData){
          this.tableDataArray =  this.mapMultipackToUI(this.guestMultipackAPIData); 
        }       
        break;
      case "8":
        const TableModel: any[] = [];
        this.lockerClientHistory.forEach(x => {
          TableModel.push({
            beginsAt : this.localization.LocalizeShortDateTime(new Date(x.lockerBookingStartTime)),
            endsAt: this.localization.LocalizeShortDateTime(new Date(x.lockerBookingEndTime)),
            lockerName: x.lockerName,
            lockerNumber : x.lockerNumber,
            rate: x.amount,
            service: this.serviceMap.find(y => y.bookingType == x.bookingType && y.bookingTypeId == x.bookingTypeId)?.serviceName || '' ,
            Comments: x.comments
          });
        });
        this.headerOptions = this.business.getTableHeaderOptions(this.includeLockerAssignment);
        this.tableOptions = this.business.getTableOptions();
        this.tableContent = this.business.getTableContent(TableModel);
    }
  }
  selectedRow(item) {
    if (this.historyType == 2) {
      this.itemArray = [];

      this.itemArray = item.transactionDetails.items;
    }
    this.selectedRowItem = item
  }

  

  get sortdataArray() {
    return this.tableDataArray.sort((a, b) => {
      if(a.hasOwnProperty('date')){
        return <any>this.utilities.getDate(b.date) - <any>this.utilities.getDate(a.date);
      }else if(a.hasOwnProperty('DateRedeemed')){        
        return <any>new Date(b.DateRedeemed).getTime() - <any>new Date(a.DateRedeemed).getTime();       
      }
      else{
        return true;
      }
    });
  }
  
  ngOnInit() {
    this.initializeFormData();
  }

  async initializeFormData(){
    this.allTherapists = this.appointmentPopupService.allTherapists.result;
    this.allServices = this.appointmentPopupService.allServices.result;
    this.historyType = this.historyTypes[0].id;
    this.changeType();
    if (this.appointmentPopupService && this.appointmentPopupService.isEditClient && this.appointmentPopupService.guestId && this.appointmentPopupService.guestId != Guid.EMPTY) {
      let guestIds:any[]=[];
      guestIds.push(this.appointmentPopupService.guestId);
      let clientIds=await this.GetAllClientsbyguid(guestIds);
      //this.GetSalesHistory(clientIds);
      if(!this.appointmentPopupService.copySelectedClient){
      this.GetSalesHistoryByGuids(guestIds);
      this.GetAllAppointments(clientIds);
      this.GetTeeTimes();
      this.getMultipackDetails(guestIds[0]);   
      }   
    }

    this.clientWindowConvertion = this.appointmentPopupService.convertToEdit.subscribe(x =>{
      if(x && x.id > 0 && this.isFirstTime){
        this.isFirstTime = false;
        this.initializeFormData();  
      }
    });
    this.timeformat = this.localization.getTimeFormat();
    if(this.appointmentPopupService.guestId){
      await this.FetchLockerDetails(this.appointmentPopupService.guestId)
    }
  }

  async FetchLockerDetails(guestId) {
    this.lockerClientHistory = await this.GetLockerClientHistory(guestId);
    const targetBookingTypes = [
      LockerBookingSourceType.SpaAppointment,
      LockerBookingSourceType.SpaClasses
    ];
    let bookingTypeInfo = this.lockerClientHistory?.filter(item => targetBookingTypes.includes(item.bookingType))
    .map(item => ({
      bookingTypeId: item.bookingTypeId,
      bookingType: item.bookingType
    }));
    let appointmentBookingTypeIds : number[] = [];
    let classBookingTypeIds : number[] = [];
    if (bookingTypeInfo?.length > 0) {
      for (const item of bookingTypeInfo) {
        switch (item.bookingType) {
          case LockerBookingSourceType.SpaAppointment:
            appointmentBookingTypeIds.push(item.bookingTypeId);
            break;
          case LockerBookingSourceType.SpaClasses:
            classBookingTypeIds.push(item.bookingTypeId);
            break;
        }
      }
    }
    let serviceNameFilter = await Promise.all([this.GetAppointmentInfo(appointmentBookingTypeIds), this.GetClassInfo(classBookingTypeIds)]);
    if(serviceNameFilter[0]){
      serviceNameFilter[0].forEach(app => {
        this.serviceMap.push({
          bookingType: LockerBookingSourceType.SpaAppointment,
          bookingTypeId : app.appointmentId,
          serviceName: app.serviceName
        })
      });
    }
    if(serviceNameFilter[1]){
      serviceNameFilter[1].forEach(classInfo => {
        this.serviceMap.push({
          bookingType: LockerBookingSourceType.SpaClasses,
          bookingTypeId: classInfo.classClientId,
          serviceName: classInfo.className
        })
      });
    }
  }

  GetAppointmentInfo(appointmentBookingTypeIds){
    return appointmentBookingTypeIds.length > 0 ? this.GetAppointmentInfoByAppointmentIds(appointmentBookingTypeIds) : [];
  }
  async GetClassInfo(classBookingTypeIds){ 
    return classBookingTypeIds.length > 0 ? (await this.classService.GetClassDepositDetails(classBookingTypeIds)).result : [];
  }
  GetSalesHistoryByGuids(Guids: number[]) {
    this.http.CallApiWithCallback<any>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: "GetSalesHistoryTransactionByGuestGuids",
      body: Guids,
      method: HttpMethod.Put,
      showError: true,
      extraParams: []
    });
  }

  GetAllAppointments(clientIds:number[]) {
    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: "AppointmentbyClientsandStatus",
      uriParams: { clientIds: clientIds.toString(), status: 'CANC,RESV,NOSHOW,CKIN,CKOUT', date: null },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetTeeTimes()
  {
    const propertyStartDate :Date = this.PropertyInfo.CurrentDTTM.addDays(-HISTORY_DETAILS_DAYS_RANGE);
    const propertyEndDate :Date = this.PropertyInfo.CurrentDTTM.addDays(HISTORY_DETAILS_DAYS_RANGE);
    var startDate = propertyStartDate ? this.localization.convertDateObjToAPIdate(propertyStartDate) : '';
    var endDate = propertyEndDate ? this.localization.convertDateObjToAPIdate(propertyEndDate) : '';

    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: "GetIteneraryByDateRange",
      uriParams: { clientId: this.appointmentPopupService.guestId,fromDate:startDate,toDate:endDate },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  GetSalesHistory(clientIds:number[])
  {
    this.http.CallApiWithCallback<any>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: "GetSalesHistoryTransactionByGuestIds",
      body: clientIds,
      method: HttpMethod.Put,
      showError: true,
      extraParams: []
    });
  }

  async GetLockerClientHistory(clientId:any)
  {
    let result = await this.InvokeServiceCallAsync(RetailRoutes.GetLockerClientHistory, Host.retailPOS, HttpMethod.Get, clientId);
    return result.result;
  }

  async GetAppointmentInfoByAppointmentIds(appointmentId : Number[]) {
    let result = await this.InvokeServiceCallAsync('GetAppointmentInfoByAppointmentIds', Host.schedule, HttpMethod.Put, '', appointmentId);
    return result.result;
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
    let appointments = [];
    if (callDesc == "AppointmentbyClientsandStatus") {
      let responseResult: any = result.result;
      for (let item of  responseResult) {
        let detail = item.appointmentDetail;
        let therapist = item.appointmentTherapists;
        let therapistNames: string[] = [];
        therapist.forEach(therap => {
          therapistNames.push(this.getTherapistName(therap.therapistId))
        });
        let appTime: string = this.localization.LocalizeShortDateTime(detail.startTime);
        let appointment = {
          "id": detail.id,
          "appointmentTime": appTime,
          "service": this.getServiceName(detail.serviceId),
          "therapist": therapistNames.join(','),
          "cancelComments": detail.cancelComments,
          "status": detail.status,
          "cancelId": detail.cancelId,
          "transaction": detail.transactionId,
          "isTrackNoshow": detail.noShow
        }
        appointments.push(appointment);
      }
      this.clientAppointments = appointments;
    }
    else if (callDesc == "GetSalesHistoryTransactionByGuestIds")
    {
      let res:any = result.result;
      let responseResult: GuestRetailTransactionHistory = res;
      let transactionDetail:any;
      let items :any[]=[];
      for (let index1 = 0; index1 < responseResult.transaction.length; index1++) {
        let transactionNumber = this.PropertyInfo.UseRetailInterface ?responseResult.transaction[index1].transactionData.ticketNumber:responseResult.transaction[index1].transactionData.retailTicketNumber ;
        let TransactionDate = responseResult.transaction[index1].transactionData.transactionDate ;
        let TotalPrice = (responseResult.transaction[index1].transactionData.totalPrice).customToFixed();
        let TotalAmount = (responseResult.transaction[index1].transactionData.totalAmount).customToFixed();
        let totalGratuity = (responseResult.transaction[index1].transactionData.gratuity).customToFixed();
        let TotalTax = (responseResult.transaction[index1].transactionData.totalTax).customToFixed();
        let totalDiscount:number = 0;
         let itemDescription;

        if(responseResult.transaction[index1].transactionDetails.length <= 0){
          transactionDetail = {
            items: []
          }
        }

        for(let index2 = 0; index2 < responseResult.transaction[index1].transactionDetails.length;index2++)
          {
           let QuantitySold = responseResult.transaction[index1].transactionDetails[index2].quantitySold;
           let unitPrice = (responseResult.transaction[index1].transactionDetails[index2].unitPrice).customToFixed();
           let itemId = responseResult.transaction[index1].transactionDetails[index2].itemId;
           itemDescription = responseResult.itemDescription[itemId];
           var indexOfItem = this.frequentlyPurchased.findIndex(i=> i.itemNumber == itemId);
           if(indexOfItem > -1)
           {
            this.frequentlyPurchased[indexOfItem].quantity = Number(this.frequentlyPurchased[indexOfItem].quantity) + Number(QuantitySold);
           }
           else
           {
            this.frequentlyPurchased.push({ "description": itemDescription, "quantity": QuantitySold, "itemNumber": itemId})
           }
  
           let Discount :number= responseResult.transaction[index1].transactionDetails[index2].discount;
           totalDiscount += Discount;
           items.push({ "name": itemDescription, "quantity": QuantitySold, "price": unitPrice});
           transactionDetail = { 
           items: items
          }
        }
       let guestHistory = {
        "date": this.localization.LocalizeDate(TransactionDate),
        "transaction": transactionNumber,
        "grandTotal": this.localization.localizeCurrency(TotalPrice,false),
        "subTotal": this.localization.localizeCurrency(TotalAmount,false),
        "tax": this.localization.localizeCurrency(TotalTax,false),
        "gratuity":this.localization.localizeCurrency(totalGratuity,false),
        "discount":  this.localization.localizeCurrency(totalDiscount.customToFixed(),false),
        "transactionDetails":transactionDetail
       }

       appointments.push(guestHistory);
       items = [];
      }
      this.frequentlyPurchased = this.frequentlyPurchased.sort(this.compareItem).reverse();
      this.salesHistory = appointments;
    }
    else if (callDesc == "GetSalesHistoryTransactionByGuestGuids") {
      let res: any = result.result;
      let responseResult: TransactionWithItemNumber[] = res;
      let transactionDetail: any;
      let items: any[] = [];
      for (let result of responseResult) {
        let transactionNumber = this.PropertyInfo.UseRetailInterface ? result.transactionData.ticketNumber : result.transactionData.retailTicketNumber;
        let TransactionDate = result.transactionData.transactionDate;
        let TotalPrice = (result.transactionData.totalPrice).customToFixed();
        let TotalAmount = (result.transactionData.totalAmount).customToFixed();
        let totalGratuity = (result.transactionData.gratuity).customToFixed();
        let TotalTax = (result.transactionData.totalTax).customToFixed();
        let totalDiscount: number = 0;
        let itemDescription;

        if (result.transactionDetails.length <= 0) {
          transactionDetail = {
            items: []
          }
        }

        for (let TransDetails of result.transactionDetails) {
          let QuantitySold = TransDetails.quantitySold;
          let unitPrice = (TransDetails.unitPrice).customToFixed();
          let itemNumber = TransDetails.itemNumber;
          itemDescription = TransDetails.itemDescription;
          var indexOfItem = this.frequentlyPurchased.findIndex(i => i.itemNumber == itemNumber);
          if (indexOfItem > -1) {
            this.frequentlyPurchased[indexOfItem].quantity = Number(this.frequentlyPurchased[indexOfItem].quantity) + Number(QuantitySold);
          }
          else {
            this.frequentlyPurchased.push({ "description": itemDescription, "quantity": QuantitySold, "itemNumber": itemNumber })
          }

          let Discount: number = TransDetails.discount;
          totalDiscount += Discount;
          items.push({ "name": itemDescription, "quantity": QuantitySold, "price": unitPrice });
          transactionDetail = {
            items: items
          }
        }
        let guestHistory = {
          "date": this.localization.LocalizeDate(TransactionDate),
          "transaction": transactionNumber,
          "grandTotal": this.localization.localizeCurrency(TotalPrice, false),
          "subTotal": this.localization.localizeCurrency(TotalAmount, false),
          "tax": this.localization.localizeCurrency(TotalTax, false),
          "gratuity": this.localization.localizeCurrency(totalGratuity, false),
          "discount": this.localization.localizeCurrency(totalDiscount.customToFixed(), false),
          "transactionDetails": transactionDetail
        }

        appointments.push(guestHistory);
        items = [];
      }
      this.frequentlyPurchased = this.frequentlyPurchased.sort(this.compareItem).reverse();
      this.salesHistory = appointments;
    }
    else if(callDesc=="GetIteneraryByDateRange")    
    {
      let clientteeTimes = [];
      let responseResult: any = result.result;
      for (let item of  responseResult) {         
        let bookTime: string = this.localization.LocalizeShortDateTime(item.start);
        let teeTime = {
          "id": item.id,
          "date": bookTime,             
          "cancelComments": item.activityDetail.cancelComments,
          "status": item.status,
          "transaction": item.activityDetail.transactionId,
          "activityType": item.interfaces[0].activityType,
          "course": item.activityDetail.activityDescription
        }
        clientteeTimes.push(teeTime);
      }
      this.teeTimes = clientteeTimes;
    }else if(callDesc == "GetMultiPackRedeemHistoryDetails")
    {
      this.guestMultipackAPIData = <any>result.result;    
    }
  }

  private mapActivityStatusToGolfStatus(value: number): string {    
    switch (value) {
      case ActivityStatus.Reserved:
        return this.localization.captions.commonItinerary.Booked;       
      case ActivityStatus.Cancelled:
        return this.localization.captions.commonItinerary.Cancelled;      
      case ActivityStatus.NoShow:
        return this.localization.captions.commonItinerary.NoShow;      
      default:
        return this.localization.captions.commonItinerary.Unknown;       
    }
  }

  compareItem(a, b) {
    const item1 = a.quantity;
    const item2 = b.quantity;
  
    let comparison = 0;
    if (item1 > item2) {
      comparison = 1;
    } else if (item1 < item2) {
      comparison = -1;
    }
    return comparison;
  }

  getServiceName(serviceId: any) {
    const tempService = this.appointmentPopupService.serviceArray ? this.appointmentPopupService.serviceArray : [];
    let services = this.allServices && this.allServices.length > 0 ? this.allServices : tempService;
    let service = services.find(x => x.id == serviceId);
    return service ? service.description : ''
  }
  getTherapistName(therapistId: any) {
    const tempApptTherapist = this.appointmentPopupService.therapistArray ? this.appointmentPopupService.therapistArray : [];
    let therapists = this.allTherapists && this.allTherapists.length > 0 ?this.allTherapists : tempApptTherapist;
    let therapist = therapists.find(x => x.id == therapistId);
    return therapist ? `${therapist.firstName} ${therapist.lastName}` : ''
  }

  async GetAllClientsbyguid(guestIds: any[]):Promise<number[]>
  {
    let response=await this.http.CallApiAsync<any>({
      host: Host.spaManagement,
      callDesc: "GetClientsByGuestIds",
      method:HttpMethod.Put,
      body: guestIds
    });
    return response.result.map(a=>a.id);
  }

  async InvokeServiceCallAsync(route: string, domain: Host, method : HttpMethod , uriParams?: any, body ?: any): Promise<BaseResponse<any>>  {
    let result: BaseResponse<any> = await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: method,
        uriParams: uriParams,
        body : body
    });
    return result;
}
  ngOnDestroy(): void {
    if (this.clientWindowConvertion) {
      this.clientWindowConvertion.unsubscribe()
    }
    this.isFirstTime = true;
  }

  showExpiredMultipacks(event){
    this.showMultiPackExpiredFlag = event;
    if(this.guestMultipackAPIData){
      this.tableDataArray =  this.mapMultipackToUI(this.guestMultipackAPIData); 
    }  
  }

  public getMultipackDetails(guestId: string){
    const request =<MultpackHistoryRequest>{
      "GuestGuid": guestId,
      "IsIncludeExpiredMultipacks":true
    }

    this.http.CallApiWithCallback<any>({
      host: Host.retailPOS,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: "GetMultiPackRedeemHistoryDetails",
      body: request,
      method: HttpMethod.Put,
      showError: true,
      extraParams: []
    });
  }
  
  public mapMultipackToUI(multipacks: MultipackAPIModel[]): MultipackUIModel[]{
    if(multipacks && multipacks.length > 0){
      let result = multipacks.map(x=> {
        return <MultipackUIModel>{
          DateRedeemed: x.redeemedSessions == 0?this.localization.LocalizeShortDateTime(x.clientMultiPackSaleDateTime):
           this.localization.LocalizeShortDateTime(x.clientMultiPackRedeemDateTime),
          DateOfSale: this.localization.LocalizeShortDateTime(x.clientMultiPackSaleDateTime),
          DateOfExpiry: this.localization.LocalizeShortDate(x.multipackExpirytDate),
          MultipackDescription: x.multiPackName,
          RedeemedCount: x.redeemedSessions.toString(),
          RemainingCount: x.isUnlimitedMultipack == true?this.captions.NotAvailable:x.remainingSessions.toString(),
          IsMultipackExpired: x.isMultiPackExpired,
          Product: x.productName,
        }
      });
   
      if(!this.showMultiPackExpiredFlag){           
        result = result.filter(x=>x.IsMultipackExpired == false);
      }

      return result;
    }else
    {
      return new Array<MultipackUIModel>();
    }
  }
  searchChange(e) {
    this.searchText = e;
  }
}
