import { Injectable } from '@angular/core';
import _ from 'lodash';
import { cloneDeep, uniqBy } from 'lodash';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { AlertType, ButtonType } from 'src/app/shared/globalsContant';
import { DynamicPricingDataService } from 'src/app/shared/service/dynamicPricing.data.service';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { UI, API, MapUI } from './dynamic-yielding.model';

@Injectable()
export class DynamicYieldingBusiness {
    editData: API.DynamicPricingRule[];
    constructor(private _dynamicPricingDataService: DynamicPricingDataService, private localization: SpaLocalization,
        private utils: SpaUtilities) {

    }

    async GetPricingDetails(startDate, endDate, groupId): Promise<API.YieldingDetails[]>{
        const apiModel: API.YieldingDetails[] = await this._dynamicPricingDataService.GetDynamicPricingByServiceAndDate(startDate, endDate, groupId);
        return apiModel;
    }

    async GetDynamicPricingServices(startDate,endDate): Promise<API.DynamicPricingServiceGroup[]> {
        const apiModel: API.DynamicPricingServiceGroup[] = await this._dynamicPricingDataService.GetDynamicPricingServices(startDate, endDate);
        return apiModel;
    }


    async PreviewDynamicPricing(startDate,serviceIds:Number[]): Promise<API.DynamicPricingServices[]> {
        const apiModel: API.DynamicPricingServices[] = await this._dynamicPricingDataService.PreviewDynamicPricing(startDate,serviceIds);
        return apiModel;
    }

    async ViewDynamicPricing(groupId: Number): Promise<API.DynamicPricingViewPricingData> {
        const apiModel: API.DynamicPricingViewPricingData = await this._dynamicPricingDataService.ViewDynamicPricing(groupId);
        return apiModel;
    }

    async DeleteDynamicPricing(groupId): Promise<boolean> {
        const result : boolean = await this._dynamicPricingDataService.DeleteDynamicPricing(groupId);
        return result;
    }
    async DeleteDynamicPricingById(id): Promise<boolean> {
        const result : boolean = await this._dynamicPricingDataService.DeleteDynamicPricingById(id);
        return result;
    }

    public async DeleteOverrideDynamicPricingByGroupId(overrideGroupId: string, dynamicPricingGroupId: string, deleteUsedPricing: boolean = false): Promise<Boolean> {
        return await this._dynamicPricingDataService.DeleteOverrideDynamicPricingByGroupId(overrideGroupId, dynamicPricingGroupId, deleteUsedPricing);
    }

    async getPricingDetailsListView(
        startDate: string,
        endDate: string
      ): Promise<any> {
        const apiModel: any[] =
          await this._dynamicPricingDataService.getPricingDetailsListView(
            startDate,
            endDate
          );
        return apiModel;
      }
      

    async GetServiceByServiceIdAndDate(groupId: string, overridegroupId: string) {
        const apiModel: API.DynamicPricingRule[] = await this._dynamicPricingDataService.GetDynamicPricingByGroupId(groupId, overridegroupId);
        this.editData = cloneDeep(apiModel);
        return this.UIMapper(apiModel);
    }
    
    UIMapper(apiData: API.DynamicPricingRule[]): MapUI.DynamicPricingRules[] {
        console.log(apiData);
        let response = [];
        let mappedData: MapUI.DynamicPricingRules;
        let editDataarr: API.DynamicPricingService[] = [];
        let editDataObj;      
        let rulegroupIdList = _.uniq(apiData.map(x => x.ruleGroupId));
        for(var ruleGroup of rulegroupIdList) {
            let serviceIds = [];
            let serviceGroupIds = [];
            for(var rule of apiData) {
                if (ruleGroup === rule.ruleGroupId) {
                    let serviceId = rule.serviceId;
                    var serviceGroupId = rule.serviceGroupId;
                    editDataObj = {
                        id: rule.id,
                        startTime: rule.startDateTime,
                        endTime: rule.endDateTime,
                        name: rule.name,
                        ruleCategoryId: rule.ruleCategoryId,
                        ruleType: rule.ruleType,
                        groupId: rule.groupId,
                        overrideGroupId: rule.overrideGroupId,
                        ruleGroupId: rule.ruleGroupId,
                        limits: rule.limits,
                        daysOfWeek: rule.daysOfWeek,
                        dynamicAvailability: rule.dynamicAvailability,
                        isDeleted: rule.isDeleted,
                        isOverride: rule.isOverride, 
                        dynamicPricingStrategy: rule.dynamicPricingStrategy,
                        isActive: rule.isActive,
                        hasOverride: rule.hasOverride                       
                    }
                    serviceIds.push(serviceId);
                    serviceGroupIds.push(serviceGroupId);
                }               
            }
            editDataObj.serviceIds = serviceIds;
            editDataObj.serviceGroupId = serviceGroupIds;
            editDataarr.push(editDataObj);
        }
        console.log(editDataarr);
        if (editDataarr?.length > 0) {
            if(editDataarr && editDataarr.length > 0) {
                editDataarr.forEach(res => {
                    mappedData = {
                        id: res.id,
                        startDateTime: res.startTime,
                        endDateTime: res.endTime,
                        name: res.name,
                        serviceGroupId: res.serviceGroupId,
                        serviceId: res.serviceIds,
                        ruleCategoryId: res.ruleCategoryId,
                        ruleType: res.ruleType,
                        groupId : res.groupId,
                        overrideGroupId: res.overrideGroupId,
                        ruleGroupId: res.ruleGroupId,
                        isOverride : res.isOverride,
                        limits: res.limits,
                        daysOfWeek: res.daysOfWeek,
                        dynamicAvailability: res.dynamicAvailability,
                        isDeleted: res.isDeleted,
                        dynamicPricingStrategy: res.dynamicPricingStrategy,
                        isActive: res.isActive,
                        hasOverride: res.hasOverride                      
                    }     
                    response.push(mappedData);        
             });
            }
        }           
        return response;
      }  

      public showBusinessError(err: any, callback?: (res: string) => void): void {
        if (err.error && err.error.errorCode) {
            let code: number = parseInt(err.error.errorCode);
            let message: string = this.localization.getError(code);
            // special case on this error code
            if (code == 29910) {
                this.utils.showAlert(message, AlertType.Warning, ButtonType.YesNo, callback);
                return;
            }
            if(code === 29911){
                let courseNames = JSON.parse(err.error.errorDescription);
                message = message.interpolate({Course : courseNames});
              }
            this.utils.showError(message);
        } else {
            this.utils.showError(err.message);
        }
    }

}