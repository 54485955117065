import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "src/app/retail/shared/service/http-call.service";
import { RetailUtilities, PatchOperation, PatchJson } from "src/app/retail/shared/utilities/retail-utilities";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { BaseResponse } from "src/app/retail/shared/business/shared.modals";
import { Host, MiscellaneousSwitch } from "src/app/retail/shared/globalsContant";
import { PropertySetting, PaymentConfiguration, SystemConfiguration} from "../retail.modals";
import { DropDownData } from "src/app/common/shared/retail.modals";
import { UserMachineInfo } from "src/app/common/shared/shared.modal";
import * as _ from "lodash";
import { RetailRoutes } from "../retail-route";

// Data Service only for property setting context in Authentication DB
@Injectable({ providedIn: 'root' })
export class PropertySettingDataService {

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization) { }

    public async getAllPropertySetting(): Promise<PropertySetting> {
        try {
            return await this.invokePropertySettingCalls<PropertySetting>("getAllPropertySettings", HttpMethod.Get, undefined,
                { "propertyId": Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }


    public async patchPropertySetting(body: Partial<PropertySetting>): Promise<boolean> {
        try {
            let patchJson: PatchJson = {
                op: PatchOperation.replace,
                path: "/ActivateRetailInterface",
                value: body.activateRetailInterface
            };
            return await this.invokePropertySettingCalls<boolean>("updatePropertySetting", HttpMethod.Patch, [patchJson],
                { "propertyId": Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    public async GetPaymentConfigurationByProperty(propertyId: number): Promise<PaymentConfiguration[]> {
        try {
            return await this.InvokeServiceCall<PaymentConfiguration[]>("GetPaymentConfigurationByProperty", HttpMethod.Get, Host.payment, undefined,
                { "propertyId": propertyId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }


    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

    private async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any, isSessionCheckNotRequired?:boolean): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: host,
            method: callType,
            body: body,
            uriParams: uRIParams
        }, false, isSessionCheckNotRequired);

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }


    private async invokePropertySettingCalls<T>(callDesc: string, callType: HttpMethod, body?: any, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.authentication,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }


    public async UpdatePropertySetting(body: Partial<PropertySetting>, propertyName: string): Promise<boolean> {
        try {
            let patchJson: PatchJson = {
                op: PatchOperation.replace,
                path: `/${propertyName}`,
                value: body.vatEnabled
            };
            return await this.invokePropertySettingCalls<boolean>("updatePropertySetting", HttpMethod.Patch, [patchJson],
                { "propertyId": Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    public async UpdatePropertyTaxId(body: Partial<PropertySetting>, propertyName: string): Promise<boolean> {
        try {
            let patchJson: PatchJson = {
                op: PatchOperation.replace,
                path: `/${propertyName}`,
                value: body.taxId
            };
            return await this.invokePropertySettingCalls<boolean>("updatePropertySetting", HttpMethod.Patch, [patchJson],
                { "propertyId": Number(this.utils.GetPropertyInfo('PropertyId')) });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetPMSEndpointConfigurationSetting() {
        try {
            return await this.InvokeServiceCall("GetConfiguration", HttpMethod.Get, Host.retailManagement, undefined,
                { module: 3, Switch: 'WEBCOMMUNICATION_PROXY_ENDPOINT' });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }


    public async GetShopItemListCountConfigurationSetting() {
        try {
            return await this.InvokeServiceCall("GetConfiguration", HttpMethod.Get, Host.retailManagement, undefined,
                { module: 3, Switch: 'SHOP_SCREEN_ITEM_LOAD_COUNT' });
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    public async GetAutoCloseTransactionSetting()  {
        try {
            const cachedValue = sessionStorage.getItem( MiscellaneousSwitch.ENABLE_AUTO_CLOSE_TRANSACTION );
            if (!cachedValue) {
                const response: SystemConfiguration  = await this.InvokeServiceCall('GetConfiguration', HttpMethod.Get,
                Host.retailManagement, undefined,
                { module: 3, Switch: MiscellaneousSwitch.ENABLE_AUTO_CLOSE_TRANSACTION });
                const enableAutoClose = true ; //  response ? response.value  === 'true'  : false ;
                sessionStorage.setItem(MiscellaneousSwitch.ENABLE_AUTO_CLOSE_TRANSACTION, enableAutoClose.toString());
                return enableAutoClose ;
            }
            return  cachedValue === 'true' ? true : false;
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    public async GetReturnOriginalPaymentConfigurationSetting(): Promise<SystemConfiguration> {
        try {
            return await this.InvokeServiceCall("GetConfiguration", HttpMethod.Get, Host.retailManagement, undefined,
                { module: 3, Switch: 'RETURN_ORIGINAL_PAYMENT_ONLY' });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async UpdateSettings(body: any[]) {
        try {
            return await this.InvokeServiceCall("UpdateSetting", HttpMethod.Put, Host.retailManagement, body, {});
        } catch (e) {
            this.http.exceptionHandle(e);
        }

    }

    public async GetMiscConfigurationSetting(): Promise<SystemConfiguration[]> {
        try {
            return await this.InvokeServiceCall("GetMiscConfiguration", HttpMethod.Get, Host.retailManagement, undefined);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetMiscConfigurationSettingByPropertyId(propertyId: number): Promise<SystemConfiguration[]> {
        try {
            return await this.InvokeServiceCall("GetMiscConfigurationByPropertyId", HttpMethod.Get, Host.retailManagement, undefined, { propertyId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetMachineNamesAndConfigurationSetting(userId: number, productId:number,propertyIds: number[]): Promise<UserMachineInfo> {
        let response = await this.InvokeServiceCall<UserMachineInfo>("GetMachineNamesConfigurationInfo", HttpMethod.Put, Host.authentication, propertyIds, {userId,productId}, true);
        response.userPropertiesMachineInfo.forEach(res => {
            res.machineNames = _.orderBy(res.machineNames, 'listOrder', 'asc')
        });
        return response;
    }

    
    public async GetCardIssuers(): Promise<DropDownData[]>{
        let response = await this.InvokeServiceCall<any[]>('GetAllCreditCardIssuerType', HttpMethod.Get, Host.payment, null);
        return response.map(element => {
            return {
                id: element.id,
                code: element.issuerType,
                description: element.displayName,
                showInDropDown: true
            }
        })
    }

    public async GetWebCommunicationProxyVersion(): Promise<any> {
        try {
            return await this.InvokeServiceCall<any>("GetWebCommunicationProxyVersion", HttpMethod.Get, Host.payment, undefined);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetSiteIdForIC(): Promise<number> {
        try {
            return await this.InvokeServiceCall<number>(RetailRoutes.GetSiteId, HttpMethod.Get, Host.retailManagement, undefined);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
    public async GetEnableExtendedProfileSearchByDefaultSetting(): Promise<SystemConfiguration> {
        try {
            return await this.InvokeServiceCall("GetConfiguration", HttpMethod.Get, Host.retailManagement, undefined,
                { module: 3, Switch: MiscellaneousSwitch.ENABLE_EXTENDED_PROFILE_SEARCH_BY_DEFAULT });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
}
