import { RetailPropertyInformation } from "../common/services/retail-property-information.service";
import { RetailUtilities } from "../shared/utilities/retail-utilities";
import * as _ from "lodash";
import { RetailLocalization } from "../common/localization/retail-localization";
import { Injectable } from "@angular/core";
import { FolioService } from "./folio-service";
import { CreateDefaultFolioRequest, CreateFolioRequest, FolioDetails, FolioLookup, FolioLookupResponse, GuestSearchInfo, PaymentPostRequestForResortFinance, PostChargeRequest, PostingRequest, PostingResponse, SettingScreen, SourceType } from "./Model/folioDetails-model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ShopDialogPopUp } from "../shop/shop-dialog-popup/shop-dialog-popup.component";
import { PaymentComponent } from "../payment/payment.component";
import { AlertType, BaseResponse, RetailErrorConstants, SelectedProducts } from "../shared/shared.modal";
import { ResortFinanceFolioPostingPaymentRecord } from "../shared/service/payment/payment-business.model";
import { PostTypeDetails } from "../retail.modals";
import { Host, Product, THERAPIST, USER } from "../shared/globalsContant";
import { HttpMethod, HttpServiceCall } from "../shared/service/http-call.service";
import { FolioRoutes, RetailRoutes } from "../retail-route";
import { PaymentBusinessLogicHandler } from "../payment/PaymentBusinessLogicHandler";
import { ShopServiceitemSummaryComponent } from "../shop/shop-common-components/shop-serviceitem-summary/shop-serviceitem-summary.component";
import { ButtonType } from "src/app/common/enums/shared-enums";
import { CommonGatewayCommunication } from "src/app/common/communication/services/commongateway-communication.service";
import { FolioInfo, PostChargeUserDetail, ResortFinanceFolioPostDetail, ResortFinanceOriginType } from "../shared/service/common-variables.service";
import { StaffType } from "../shop/shop.modals";
import { Guid } from "guid-typescript";
import { BulkCreateResortFinanceFoliosRequest } from 'src/app/retail/Folio/Model/folioDetails-model';
@Injectable({
  providedIn: "root",
})
export class FolioBusiness {
  dialogRef: MatDialogRef<any>;
  ShopCaptions: any = this.localization.captions.shop;
  constructor(
    private folioService: FolioService,
    private utils: RetailUtilities,
    private propertyInfo: RetailPropertyInformation,
    private localization: RetailLocalization,
    public dialog: MatDialog,
    private httpserviceCall: HttpServiceCall
	,private commonGatewayCommunication: CommonGatewayCommunication
  ) {}


  openFolioDialog(paymentCompScope: PaymentComponent,shopSummaryScope:ShopServiceitemSummaryComponent,fromScreen:string,  callback?: (x: any, paymentCompScope) => void){
	   const isGuestAttahced = paymentCompScope.payeeInfo?.guestProfileId;
	   if(!isGuestAttahced){
		this.openDialag(paymentCompScope, false, [] , '' ,callback);
	   }
	   else {
		this.openDialag(paymentCompScope,true ,[], '', callback);
	    }	
	}

	openDialag(paymentCompScope: PaymentComponent, isGuestAttached , folios: any[], overAllBalance ,  callback?: (x: any, paymentCompScope) => void){
		const dialogRef = this.dialogRef = this.dialog.open(ShopDialogPopUp, {
			width: '700px',
			height: '600px',
			maxHeight: '700px',
			disableClose: true,
			hasBackdrop: true,
			data: {
				isEdit: false,
				headername: this.ShopCaptions.hdr_post_to_folio,
				closebool: true,
				templatename: 'folio',
				data: {
					payeeInfo: paymentCompScope?.payeeInfo,
					isGuest : isGuestAttached,
					folios : folios,
					overAllBalance : overAllBalance,
					memberGuestId : paymentCompScope?._ams?.memberGuestId
				}
			}, //, templatename: templateName
			panelClass: 'shop-payment'
		});
		dialogRef.afterClosed().subscribe((result) => {
			if(result && result.from == 'save') {
				paymentCompScope.folioSection = true;
				paymentCompScope.postfolioData = result.selectedFolio;
				paymentCompScope.postToFolioInfo = result;
				paymentCompScope.ConfirmationPopupShown = true;
            if (callback) {					
					callback(result, paymentCompScope);
				}
			}
		});
	}

	getSourceType(data: any = null) {
		if (data?.memberGuestId)
			return SourceType.AddOnMember.toString();
		else
			return SourceType.AddOn.toString();
	}  

  public async PostFolio(paymentCompScope: PaymentComponent, saleAmount, transactionId: number,  pbs:PaymentBusinessLogicHandler, callback?: (x: any) => void){
	const model: PaymentPostRequestForResortFinance = {
		transactionId : transactionId,
		amount : saleAmount
	}	
	  this.getPostRecordsForResortFinance(model).then(postingRecords => {
		  const posts: PostChargeRequest[] = [];
		  if (paymentCompScope !=null && paymentCompScope.postToFolioInfo != null) {
			  postingRecords.forEach(post => {
				  posts.push({
					  folioId: paymentCompScope.postfolioData.id,
					  guestId: paymentCompScope.postToFolioInfo?.selectedGuest?.guestId,
					  sourceType: paymentCompScope.postToFolioInfo?.selectedFolio?.sourceType,
					  sourceTypeId: paymentCompScope.postToFolioInfo?.selectedGuest?.guestId,
					  reference: post.reference,
					  comment: post.comments,
					  amount: post.amount,
					  postTypeNumber: post.postTypeNumber,
					  outletId: paymentCompScope.CreateRetailItemResponse?.transactionData?.outletId,
					  FolioInvoiceNumber: paymentCompScope.postToFolioInfo?.selectedFolio?.folioInvoiceNumber					 
				  });

			  });
		  }

			const request : PostingRequest = {
				retailTicketNumber : paymentCompScope.CreateRetailItemResponse?.transactionData?.retailTicketNumber,
				postChargeRequests :posts,
				retailTransactionId : transactionId,
				transactionAmount : saleAmount,
				transactionDate : paymentCompScope.CreateRetailItemResponse?.transactionData?.transactionDate,
				transactionGuestId: paymentCompScope.payeeInfo?.guestId,
				paymentMethodId: paymentCompScope.CurrentTryPayResponse?.paymentMethodId							
			}
			this.Postings(request).then(r => {
				if (callback) {	
					const record : ResortFinanceFolioPostingPaymentRecord = {
						amount :  saleAmount,
						isVoided : false,
						folioInvoiceNumber : paymentCompScope.postfolioData.folioInvoiceNumber,
						guestProfileId : paymentCompScope.postToFolioInfo.selectedGuest.guestId,
						clientId : paymentCompScope.postToFolioInfo.selectedGuest.id,
						folioNumber : paymentCompScope.postfolioData.number,
						folioId : paymentCompScope.postfolioData.id,
						product : Number(this.utils.GetPropertyInfo("ProductId")),
						logId : r.folioPostingLogId
					}
					const info : FolioInfo = {
						sourceType: paymentCompScope.postToFolioInfo?.selectedFolio?.sourceType,
						sourceTypeId: paymentCompScope.postToFolioInfo?.selectedGuest?.guestId,
						folioInvoiceNumber: paymentCompScope.postfolioData.folioInvoiceNumber
					}
				  this.PostResortFinanceFolioPostDetails(paymentCompScope._ss.selectedProducts ,r.folioPostRecords.map(x => x.id),saleAmount,info);
				  callback(record);
				}
			})
			
		}).catch(err => {
			pbs.dialog.closeAll();		
			pbs.$scope.EnableCloseTranBtn = false;
			pbs.$scope.isAPICallInProgress = false;
			pbs.$scope.ConfirmationPopupShown = false;
			if(err.error.errorCode == RetailErrorConstants.POSTTYPES_UNMAPPED && err.error.errorDescription){
				let unmappedCategoriesForSettlement= (err.error.result.unmappedCategoriesForSettlement.length>0) ? this.ShopCaptions.unmappedSettlement+": "+ _.uniq(err.error.result.unmappedCategoriesForSettlement).join(',')  : '';
				let unmappedCategoriesForServiceCharge=(err.error.result.unmappedCategoriesForServiceCharge.length>0) ? this.ShopCaptions.ServiceCharge+": "+ _.uniq(err.error.result.unmappedCategoriesForServiceCharge).join(',') : '';
				let unmappedCategoriesForGratuity=(err.error.result.unmappedCategoriesForGratuity.length>0) ? this.ShopCaptions.Gratuity+": "+ _.uniq(err.error.result.unmappedCategoriesForGratuity).join(',') : '';
                let unmappedCategoriesDiscount=(err.error.result.unmappedCategoriesDiscount.length>0) ?this.ShopCaptions.discount+": "+ _.uniq(err.error.result.unmappedCategoriesDiscount).join(',') : '';
                let unmappedTaxes=(err.error.result.unmappedTaxes.length>0) ?this.ShopCaptions.unmapped_taxes+": "+ _.uniq(err.error.result.unmappedTaxes).join(',') : '';
				this.utils.showAlert(this.ShopCaptions.postTypesUnmappedText+"<br><br>"+unmappedCategoriesForSettlement+"<br>"+unmappedCategoriesForServiceCharge+"<br>"+unmappedCategoriesForGratuity+"<br>"+unmappedCategoriesDiscount+"<br>"+unmappedTaxes,AlertType.Error, ButtonType.Ok, (res) => {});
			}
		})		
	}
	
	
  async PostResortFinanceFolioPostDetails(selectedProducts: SelectedProducts[],  posts: number[], saleAmount: number,folioInfo: FolioInfo) {
	const groupingId = Guid.create().toString();
	const details : ResortFinanceFolioPostDetail[] =[];
	const currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
	const userInfo : PostChargeUserDetail = {userId : Number(this.localization.GetUserInfo('userId')), userType : StaffType.User};
	let  retailItems = [];
	if(currentProductId == Product.GOLF) {
		const golfRetailItems = selectedProducts.filter(x => x.payeeId > 0);
		if(golfRetailItems.length) {
			details.push(this.FormResortFinanceFolioPostDetail(groupingId, folioInfo,golfRetailItems ,saleAmount, posts, [userInfo], ResortFinanceOriginType.TEETIME));
		}
		retailItems = selectedProducts.filter(x => !golfRetailItems.includes(x));
	}
	else if(currentProductId == Product.SPA) {
		const serviceItems = selectedProducts.filter(x => x.CheckOutAppointmentId > 0);
		if(serviceItems.length ) {
			const appRetailItems = _.chain(serviceItems).groupBy('CheckOutAppointmentId')
			.map((value, key) => ({CheckOutAppointmentId : key , value})).value();
			appRetailItems.forEach(app => {
				const products = app.value;
				const staffDetails = _.flatten(products.map(x => x.StaffTransactionDetail));
			    const userDetailMap : PostChargeUserDetail[] =[userInfo];
			    const therapistIds = staffDetails?.filter(x => x.StaffType == THERAPIST)?.map(x => x.StaffId);
			    const userIds = staffDetails?.filter(x => x.StaffType == USER)?.map(x => x.StaffId);
			    if(therapistIds?.length) {
			    	therapistIds.forEach(x => {
			    		userDetailMap.push({userId : x, userType : StaffType.Therapist})
			    	})
			    }
			    if(userIds?.length) {
			    	userIds.forEach(x => {
			    		userDetailMap.push({userId : x, userType : StaffType.User})
			    	})
			    }
				details.push(this.FormResortFinanceFolioPostDetail(groupingId, folioInfo, products ,saleAmount,posts,userDetailMap, ResortFinanceOriginType.APPOINTMENT));
			})
		}
	    retailItems = selectedProducts.filter(x => !serviceItems.includes(x));
	}
	else  {
		retailItems = selectedProducts;
	}
	if(retailItems.length) {
		details.push(this.FormResortFinanceFolioPostDetail(groupingId, folioInfo,retailItems,saleAmount,posts,[userInfo], ResortFinanceOriginType.RETAILITEM));
	}
	
	if(details.length) {
		await this.saveResortFinanceFolioPostDetail(details);
	}
  }

  FormResortFinanceFolioPostDetail(groupingId: string, folioInfo: FolioInfo, selectedProducts: SelectedProducts[], saleAmount: number ,posts:number[], postChargeUserDetails :PostChargeUserDetail[], orginType : string){
	return  {
		sourceType: folioInfo.sourceType,
		sourceTypeId: folioInfo.sourceTypeId,
		invoiceNumber: folioInfo.folioInvoiceNumber,
		originTotal:  _.sum(selectedProducts.map(x => x.ProductPrice * x.Noofitems)),
		originType: orginType,
		postedAmount :saleAmount,
		postIds: JSON.stringify(posts) ,
		postChargeUserDetails: JSON.stringify(postChargeUserDetails),
		groupingId: groupingId
	} as ResortFinanceFolioPostDetail;
  }

  public async Postings(model: PostingRequest): Promise<PostingResponse> {
    const resp: BaseResponse<PostingResponse> = await this.httpserviceCall.CallApiAsync({
		callDesc: RetailRoutes.PostCharge,
		host: Host.payment,
		method: HttpMethod.Post,
		body : model,
		showError:false
	});
	return resp.result;
  }

  public async getFolioDetails(sourceTypeId: string): Promise<FolioDetails[]> {
    const result = await this.folioService.getFolioDetails(this.getSourceType(), sourceTypeId);
    return result;
  }

  public async createFolioDetails(body: FolioDetails): Promise<FolioDetails> {
    const result = await this.folioService.createFolioDetails(body);
    return result;
  }

  public async saveResortFinanceFolioPostDetail(body: ResortFinanceFolioPostDetail[]): Promise<ResortFinanceFolioPostDetail[]> {
    return await this.folioService.saveResortFinanceFolioPostDetail(body);
  }

  public async createDefaultFolio(body: CreateDefaultFolioRequest): Promise<FolioDetails> {
    const result = await this.folioService.createDefaultFolio(body);
    return result;
  }

  public async GetResortFinanceCardTokenReference(guestCardTokenReference: number): Promise<number>{
	return await this.folioService.GetResortFinanceCardTokenReference(guestCardTokenReference);
  }

  public async folioLookup(body: FolioLookup): Promise<FolioLookupResponse[]> {
    const result = await this.folioService.FolioLookup(body);
    return result;
  }

  public async folioLookupByGuestInfo(body: GuestSearchInfo): Promise<FolioLookupResponse[]> {
    const result = await this.folioService.FolioLookupByGuestDetails(body);
    return result;
  }

  public async getPostTypes(): Promise<PostTypeDetails[]> {
    const result = await this.folioService.getPostTypes();
    return result;
  }

	public async BulkCreateResortFinanceFolios(body: BulkCreateResortFinanceFoliosRequest) {
		return await this.folioService.BulkCreateResortFinanceFolios(body);
	}

  public async getPostRecordsForResortFinance(model: PaymentPostRequestForResortFinance): Promise<any> {
	const resp: BaseResponse<any> = await this.httpserviceCall.CallApiAsync({
		callDesc: RetailRoutes.GetPostRecordsForResortFinance,
		host: Host.retailPOS,
		method: HttpMethod.Post,
		body : model,
		showError : false
	});
	return resp.result;
  }

  public async  fetchFolioDefaultsSettings(module : string ,screen : string){
	const response = await this.commonGatewayCommunication.getPromise<any>({
		route: FolioRoutes.GetFolioSettingByScreen,
		uriParams: { module, screen }
	});
	if(response  && response.screenName == SettingScreen.ConfigurationSwitches){
	return response;
	}
	return null;
}
}
