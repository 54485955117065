import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { SpaLocalization } from "src/app/core/localization/spa-localization";
import { SpaPropertyInformation } from "src/app/core/services/spa-property-information.service";
import { HttpServiceCall } from "src/app/shared/service/http-call.service";
import { SpaUtilities } from "src/app/shared/utilities/spa-utilities";
import {StaffScheduleService} from "../staff-schedule.service";
import { ImageProcessorService } from "src/app/shared/service/image-processor-service";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { QuickLoginUtilities } from "src/app/common/shared/shared/utilities/quick-login-utilities";
import { BreakPointAccess } from "src/app/shared/service/breakpoint.service";
import { UserAlerts } from "src/app/core/config/alerts-config";
import { SettingsService } from "../../settings.service";
import { StaffScheduleComponent } from "../staff-schedule.component"; // STORAGE THE BACK ARRAY
import moment from "moment";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppointmentpopupService } from "src/app/shared/service/appointmentpopup.service";
import { SPAManagementBreakPoint } from "src/app/shared/globalsContant";
import { SnackBarType } from "src/app/common/shared/shared/enums/enums";

@Component({
  selector: "app-staff-schedule-popup",
  templateUrl: "./staff-schedule-popup.component.html",
  styleUrls: ["./staff-schedule-popup.component.scss"],
  providers: [StaffScheduleService],
  encapsulation: ViewEncapsulation.None
})
export class StaffSchedulePopupComponent
  extends StaffScheduleComponent
  implements OnInit {
  private staffdestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  enableSave = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  public captions: any = this.localization.captions;
  floatLabel:string;
  isEditenabled:boolean = false;
  comments: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StaffSchedulePopupComponent>,
    public fb: UntypedFormBuilder,
    public localization: SpaLocalization,
    public propertyInfo: SpaPropertyInformation,
    public utils: SpaUtilities,
    public http: HttpServiceCall,
    public ss: SettingsService,
    public Form: UntypedFormBuilder,
    public _su: StaffScheduleService,
    public dialog: MatDialog,
    public imgService: ImageProcessorService,
    public userAlerts: UserAlerts,
    public breakPoint: BreakPointAccess,
    public commonUtils: QuickLoginUtilities,
    public _retailPropertyInfo: RetailPropertyInformation,
    public aps: AppointmentpopupService
  ) {
    super(
      http,
      fb,
      utils,
      fb,
      ss,
      _su,
      dialog,
      localization,
      imgService,
      userAlerts,
      breakPoint,
      propertyInfo,
      commonUtils,
      _retailPropertyInfo
    );
    this.floatLabel = this.localization.setFloatLabel;
    }

  formGroupValues: UntypedFormGroup;
  editSchedule = true;
  initialLoads: boolean = true;
  callCounter: number = 0;
  locationArr: any = [];
  therapistAppointments: any[];
  locationId: number;
  deletedIndex: number;

  ngOnInit() {
    this.formGroupValues = this.fb.group({
      typeArray: this.fb.array([
        this.fb.group({
          WorkStartTime:[''],
          WorkEndTime:[''],
          location:[''],
          isEditenabled:false,
          therapistId:[''],
          isExpanded:true,
        })
      ])
    });
    this.LoadLocations();
    this.patchValue(false);
    this.formGroupValues.valueChanges
      .pipe(takeUntil(this.$destroyed))
      .subscribe((res) => {
        this.enableSave = true;
      });
    this._su.staffScheduleUpdateNotifier$
      .pipe(takeUntil(this.staffdestroyed$))
      .subscribe((result) => {
        if(result.msg == 'Deleted')
        {
          const control = this.formGroupValues.get('typeArray') as UntypedFormArray;
          control.removeAt(this.deletedIndex);
          this.Formgrp.updateValueAndValidity();
        }
        this.formRefresh(result.therapistId);
      });
  }

  async formRefresh(therapistId){
    let date = this.localization.convertDateTimeToAPIDateTime(this.data['value'][0].availableTime.fromTime);
    let schedules;
    await this._su.GetTherapistSchedule(date, therapistId).then( x =>
      schedules = x,
    );
    if(schedules && schedules.length > 0) {
    let element = schedules[0].availableTime;
    let value = element.map(y => this.bindData(schedules[0],y));
    this.data.value = value;
    }
    else {
      this.data.value = [];
    }
    this.patchValue(true);
  }

  bindData(selectedData: any, filterData: any): any {
    return {
      availableTime: {
        availableOnCall: filterData.availableOnCall, createdAt: filterData.createdAt,
        fromTime: filterData.fromTime, id: filterData.id,
        locationId: filterData.locationId, lunchId: filterData.lunchId,
        therapistScheduleId: filterData.therapistScheduleId, toTime: filterData.toTime,
      },
      breakTime: selectedData.breakTime, createdAt: selectedData.createdAt,
      id: selectedData.id, isOff: selectedData.isOff, scheduleDate: selectedData.scheduleDate, therapistId: selectedData.therapistId,
    };
  }

  ngAfterViewInit() {
    this.subject.subscribe((x) => {
      if (x) {
        this.clearAppointment();
        this.therapistAppointments.forEach(x=>{
          this.seperateAppointmentsBasedonSchedule(x);
        })
        this.locationArr.forEach(y => {
          this.data.value.forEach((val,index) => {
            if(y.id == val.availableTime.locationId) {
              this.data.value[index]['locationDescription'] = y.description
            }
          })
        })
      }
    });
  }
  clearAppointment() {
    this.data.value.forEach(x=> {
      if(x.appointments) {
        x.appointments = [];
      }
      else{
        x['appointments'] = [];
      }
    })
  }
  seperateAppointmentsBasedonSchedule(appt) {
    this.data.value.forEach(x=> {
      let scheduleSTime = x.availableTime.fromTime.getTime();
      let scheduleETime = x.availableTime.toTime.getTime();
      let apptStime = new Date(appt.startTime).getTime();
      let apptEtime = new Date(appt.endTime).getTime();
      if(apptStime >= scheduleSTime && apptEtime <= scheduleETime) {
        x.appointments.push(appt)
      }
    })
  }
  changeLocation(arg: any) {
    this.locationId = arg.value;
  }

  async patchValue(isAfterEdit: boolean) {
    const control = this.formGroupValues.get('typeArray') as UntypedFormArray;
    for(let i = 0 ; i < this.data.value.length; i++) {
      if(i>0 && !isAfterEdit) {
        control.push(new UntypedFormGroup({
          WorkStartTime: new UntypedFormControl(''),
          WorkEndTime: new UntypedFormControl(''),
          location: new UntypedFormControl(''),
          isEditenabled: new UntypedFormControl(false),
          therapistId:new UntypedFormControl(''),
          isExpanded:new UntypedFormControl(true),
        }))
      }
     control.controls[i]['controls']["WorkStartTime"].patchValue(moment(this.data['value'][i].availableTime.fromTime).format("HH:mm a"));
     control.controls[i]['controls']["WorkEndTime"].patchValue(moment(this.data['value'][i].availableTime.toTime).format("HH:mm a"));
     control.controls[i]['controls']["location"].patchValue(this.data['value'][i].availableTime.locationId);
    }
    this.formGroupValues.updateValueAndValidity();
    if (this.data.value.length > 0) { 
      await this.GetTherapistAppointmentsByStaffId(
      this.data['value'][0].availableTime.fromTime,
      this.data['value'][0].availableTime.toTime,
      this.data.TherapistId);
    } else {
      this.subject.next(true);
    }
    this.comments = this.data['value'][0].comments
  }

  close(result){
    this.dialogRef.close(result);
    this.formGroupValues.reset();
  }

  onNoClick(idx) {
    const control = this.formGroupValues.get('typeArray') as UntypedFormArray;
    if(!control.controls[idx].value.isEditenabled) {
      control.controls[idx]['controls']['isEditenabled'].patchValue(true);
    } else {
      control.controls[idx]['controls']['isEditenabled'].patchValue(false);
    }
    this.formGroupValues.updateValueAndValidity();
  }

  openPicker() {
    document
      .getElementById("staff-creation-section")
      .classList.add("transform3dnone");
  }

  Save(idx) {
    const control = this.formGroupValues.get('typeArray') as UntypedFormArray;
    if(!control.controls[idx].value.isEditenabled) {
      control.controls[idx]['controls']['isEditenabled'].patchValue(true);
    } else {
      control.controls[idx]['controls']['isEditenabled'].patchValue(false);
    }
    this.formGroupValues.updateValueAndValidity();
    let value = {
      therapaistId: this.data.TherapistId,
      startDate: this.data['value'][0].availableTime.fromTime,
      endDate: this.data['value'][0].availableTime.toTime,
      startTime: control.controls[idx]['controls']['WorkStartTime'].value,
      endTime: control.controls[idx]['controls']['WorkEndTime'].value,
      locationId: this.locationId,
    };

    this._su.CreateTherapistSchedule(value).then((result: boolean) => {
      if(result){
    let notificationMsg = this.localization.replacePlaceholders(this.captions.scheduleSuccessMessage, ['index'], [idx+1]);
    this.utils.showToastMessage(notificationMsg, SnackBarType.Success, 5000, 'center', 'top', 'icon-requisition-finalized-eatec',345);
  }
});
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    this.staffdestroyed$.next(true);
    this.staffdestroyed$.complete();
  }

  editClick(idx) {
    const breakpointNumber = [SPAManagementBreakPoint.EditStaffSchedule];
    const isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
    if (!isUserAuthorized) {
      return;
    }
    const control = this.formGroupValues.get('typeArray') as UntypedFormArray;
    if(!control.controls[idx].value.isEditenabled) {
      control.controls[idx]['controls']['isEditenabled'].patchValue(true);
      control.controls[idx]['controls']['isExpanded'].patchValue(true);
    } else {
      control.controls[idx]['controls']['isEditenabled'].patchValue(false);
    }
    this.formGroupValues.updateValueAndValidity();
  }

  deleteClick(idx) {
    const breakpointNumber = [SPAManagementBreakPoint.DeleteStaffSchedule];
    const isUserAuthorized = this.breakPoint.CheckForAccess(breakpointNumber);
    if (!isUserAuthorized) {
      return;
    }
    this.deletedIndex = idx;
    const selectData =  this.data['value'][idx].availableTime;
    let body = [];
    body.push({
      fromTime: this.localization.convertDateTimeToAPIDateTime(selectData.fromTime),
      toTime: this.localization.convertDateTimeToAPIDateTime(selectData.toTime),
    });
    let request : any = {
      schedules : body,
      isBulkDelete : false
    }
    this._su.deleteTherapistSchedule(this.data.TherapistId, request);
  }

  expandPanel(i) {
    const control = this.formGroupValues.get('typeArray') as UntypedFormArray;
    control.controls[i].value.isExpanded = true;
    this.Formgrp.updateValueAndValidity();
  }
}
