import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { GatewayConfiguration, GatewayValues, UI } from "../../shared/service/payment/payment-model";
import { GatewayConfigurationType, Host } from "../../shared/globalsContant";
import { BaseResponse } from "../../retail.modals";
@Injectable({ providedIn: 'root' })
export class CreditCardConfigurationService {

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private _localization: RetailLocalization) { }

    public async getCreditCardConfiguration(params: object): Promise<GatewayConfiguration[]> {
        try {
            return await this.InvokeCreditCardConfigurationCalls<GatewayConfiguration[]>(Host.payment, "GetCCConfiguration", HttpMethod.Get, null, params);
        } catch (e) {
            this.http.exceptionHandle(e);
      } 
    }

    public async createOrUpdateCreditCardConfiguration(body: GatewayConfiguration[]): Promise<GatewayConfiguration> {
        try {
            return await this.InvokeCreditCardConfigurationCalls<GatewayConfiguration>(Host.payment, "UpdateCCConfiguration", HttpMethod.Put, body);
        } catch (e) {
            this.http.exceptionHandle(e);
      } 
    }

    private async InvokeCreditCardConfigurationCalls<T>(host: Host, callDesc: string, callType: HttpMethod, body?: GatewayConfiguration[], uRIParams?: any): Promise<T> {

        try {
            let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
                callDesc: callDesc,
                host: host,
                method: callType,
                body: body,
                uriParams: uRIParams
            });

            return response.result;
        }
        catch (ex) {
            console.error(ex);
            return null;
        }
    }

    public async getCreditCardConfigurationByScreen(): Promise<UI.CreditCardConfiguration> {
        const result = await this.InvokeCreditCardConfigurationCalls(Host.payment, "GetPaymentSettingByModuleAndScreen", HttpMethod.Get, undefined, { module: 'SYSTEMSETUP', screen: 'CCCONFIGURATION' });
        return this.UIMapper(result);
    }

    public async updateCreditCardConfiguration(creditCardConfiguration: UI.CreditCardConfiguration): Promise<any> {
        try {
            return await this.InvokeCreditCardConfigurationCalls(Host.payment, "PaymentSetting", HttpMethod.Put, this.APIMapper(creditCardConfiguration));
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    APIMapper(creditCardConfiguration: UI.CreditCardConfiguration): any {
        const CCconfigValue = {
            configurations: [this.MapGatewayConfigurations(creditCardConfiguration)],
            enableCCTracking: creditCardConfiguration.enableCCTracking,
            enableRequestCardSwipe: creditCardConfiguration.enableRequestCardSwipe,
            enablePrintCCAuthorization: creditCardConfiguration.enablePrintCCAuthorization,
            automaticallyDeferCCAdvanceDepositPost: creditCardConfiguration.automaticallyDeferCCAdvanceDepositPost,
            alwaysRequestSwipeAtCheckIn: creditCardConfiguration.alwaysRequestSwipeAtCheckIn,
            enableHouseNumber: creditCardConfiguration.enableHouseNumber,
            enableChipAndPin: creditCardConfiguration.enableChipAndPin,
            enableChipPinTest: creditCardConfiguration.enableChipPinTest,
            skipDecreaseAuth: creditCardConfiguration.skipDecreaseAuth,
            incrementalAuthWhenNotSupported: creditCardConfiguration.incrementalAuthWhenNotSupported,
            noOfSvcReceipts: creditCardConfiguration.noOfSvcReceipts,
            batchWaitTimeSec: creditCardConfiguration.batchWaitTimeSec,
            pbfeNetpfDelay: creditCardConfiguration.pbfeNetpfDelay,
            setCCOverAuthAdjustThreshold: creditCardConfiguration.setCCOverAuthAdjustThreshold ? this._localization.currencyToSQLFormat(creditCardConfiguration.setCCOverAuthAdjustThreshold.toString()) : "",
            activateAllPABPRecommendation: creditCardConfiguration.activateAllPABPRecommendation,
            purgeStayCCDaysAfterCO: creditCardConfiguration.purgeStayCCDaysAfterCO,
            purgeArchivedCCDaysAfterCO: creditCardConfiguration.purgeArchivedCCDaysAfterCO,
            purgeBookingCCDaysAfterCO: creditCardConfiguration.purgeBookingCCDaysAfterCO,
            purgePostingCCDaysAfterCO: creditCardConfiguration.purgePostingCCDaysAfterCO,
            skipCCFauxNoPurgeDuringZOut: creditCardConfiguration.skipCCFauxNoPurgeDuringZOut,
            purgeGuestCCDaysAfterCO: creditCardConfiguration.purgeGuestCCDaysAfterCO,
            enableCCAuthLimit: creditCardConfiguration.enableCCAuthLimit || false,
            voidAuthAfterCO : true,
            showCreditCardExpDate: creditCardConfiguration.showCreditCardExpDate ?? false
            
        };

        return {
            id: creditCardConfiguration.id,
            moduleName: 'SYSTEMSETUP',
            screenName: 'CCCONFIGURATION',
            configValue: JSON.stringify(CCconfigValue),
            defaultValue: creditCardConfiguration.defaultConfigValue == "" ? "" : JSON.stringify(creditCardConfiguration.defaultConfigValue),
        };
    }

    MapGatewayConfigurations(configurations: UI.CreditCardConfiguration): GatewayConfiguration {
        let gatewayValues: GatewayValues[] = configurations.GatewayPairs.map(x => {
            return {
                configValue: x.gateway_value.trim(),
                configKey: x.gateway_key.trim(),
                configurationId: ((configurations.gatewayId) || 0)
            }; 
        });
        return {
            id: ((configurations.gatewayId) || 0),
            payAgentURL: configurations.rGuestPayAgentURL.trim(),
            payGatewayID: configurations.rGuestPayGatewayID.trim(),
            isInterfaceActive: configurations.enableActivateInterface,
            isPartialPayAllowed: configurations.enablePartialPayment,
            isAVSActive: configurations.enableActivateAVS,
            restrictCreditToGuest: configurations.restrictCreditToGuest,
            restrictPartialRefund: configurations.restrictPartialRefund,
            voidAuthAfterCO: configurations.voidAuthAfterCO,
            gatewayValues: gatewayValues,
            type: GatewayConfigurationType.CreditCard
        };
    }

    UIMapper(configuration): UI.CreditCardConfiguration {        
        const creditCardConfiguration = configuration.configValue;
        const creditCardConfig = creditCardConfiguration.configurations.find(t => t.type == GatewayConfigurationType.CreditCard);
        return {
            id: configuration.id,
            activateAllPABPRecommendation: creditCardConfiguration.activateAllPABPRecommendation,
            alwaysRequestSwipeAtCheckIn: creditCardConfiguration.alwaysRequestSwipeAtCheckIn,
            automaticallyDeferCCAdvanceDepositPost: creditCardConfiguration.automaticallyDeferCCAdvanceDepositPost,
            batchWaitTimeSec: creditCardConfiguration.batchWaitTimeSec,
            enableActivateAVS: creditCardConfig ? creditCardConfig.isAVSActive : false,
            restrictCreditToGuest: creditCardConfig ? creditCardConfig.restrictCreditToGuest : false,
            restrictPartialRefund: creditCardConfig ? creditCardConfig.restrictPartialRefund : false,
            enableActivateInterface: creditCardConfig ? creditCardConfig.isInterfaceActive : false,
            enableCCTracking: creditCardConfiguration.enableCCTracking,
            enableChipAndPin: creditCardConfiguration.enableChipAndPin,
            enableChipPinTest: creditCardConfiguration.enableChipPinTest,
            enableHouseNumber: creditCardConfiguration.enableHouseNumber,
            enablePartialPayment: creditCardConfig ? creditCardConfig.isPartialPayAllowed : false,
            enablePrintCCAuthorization: creditCardConfiguration.enablePrintCCAuthorization,
            enableRequestCardSwipe: creditCardConfiguration.enableRequestCardSwipe,
            incrementalAuthWhenNotSupported: creditCardConfiguration.incrementalAuthWhenNotSupported,
            noOfSvcReceipts: creditCardConfiguration.noOfSvcReceipts ? creditCardConfiguration.noOfSvcReceipts : '',
            pbfeNetpfDelay: creditCardConfiguration.pbfeNetpfDelay ? creditCardConfiguration.pbfeNetpfDelay : '',
            purgeArchivedCCDaysAfterCO: creditCardConfiguration.purgeArchivedCCDaysAfterCO ? creditCardConfiguration.purgeArchivedCCDaysAfterCO : '',
            purgeBookingCCDaysAfterCO: creditCardConfiguration.purgeBookingCCDaysAfterCO ? creditCardConfiguration.purgeBookingCCDaysAfterCO : '',
            purgeGuestCCDaysAfterCO: creditCardConfiguration.purgeGuestCCDaysAfterCO ? creditCardConfiguration.purgeGuestCCDaysAfterCO : '',
            purgePostingCCDaysAfterCO: creditCardConfiguration.purgePostingCCDaysAfterCO ? creditCardConfiguration.purgePostingCCDaysAfterCO : '',
            purgeStayCCDaysAfterCO: creditCardConfiguration.purgeStayCCDaysAfterCO ? creditCardConfiguration.purgeStayCCDaysAfterCO : '',
            rGuestPayAgentURL: creditCardConfig ? creditCardConfig.payAgentURL : '',
            rGuestPayGatewayID: creditCardConfig ? creditCardConfig.payGatewayID : '',
            setCCOverAuthAdjustThreshold: creditCardConfiguration.setCCOverAuthAdjustThreshold ? this._localization.localizePercentage(creditCardConfiguration.setCCOverAuthAdjustThreshold.toString()) : '',
            skipCCFauxNoPurgeDuringZOut: creditCardConfiguration.skipCCFauxNoPurgeDuringZOut,
            skipDecreaseAuth: creditCardConfiguration.skipDecreaseAuth,
            gatewayId: creditCardConfig ? creditCardConfig.id : 0,
            GatewayPairs: creditCardConfig && creditCardConfig.gatewayValues ? this.MapUIGatewayValues(creditCardConfig.gatewayValues) : [],
            defaultConfigValue: configuration.defaultValue,
            enableCCAuthLimit: creditCardConfiguration.enableCCAuthLimit,
            voidAuthAfterCO :true,
            showCreditCardExpDate: creditCardConfiguration.showCreditCardExpDate ?? false
        };
    }

    MapUIGatewayValues(gateWayValues: GatewayValues[]): UI.GateWayValues[] {
        return gateWayValues.map(x => {
            return {
                gateway_key: x.configKey.trim(),
                gateway_value: x.configValue.trim(),
                gateway_index: x.configurationId
            };
        });
    }
}