import { Injectable } from '@angular/core';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization'
import * as FileSaver from 'file-saver';
import { DataUtilityDataService } from 'src/app/common/dataservices/datautility.data.service';
import moment from 'moment';

@Injectable()
export class FailurePopupBusiness {
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private _localization: Localization,
    private _dataservice: DataUtilityDataService) {
    this.captions = this._localization.captions;
  }
  
  getHeaderOptions(): TableHeaderOptions[] {
    return [     
      {
        key: 'screenName',
        displayName: this.captions.lbl_Screen,
        displayNameId: 'lbl_Screen',
        sorting: true
      },
      {
        key: 'errorRowNo',
        displayName: this.captions.tbl_hdr_rowNumber,
        displayNameId: 'tbl_hdr_rowNumber',
        sorting: false
      },
      {
        key: 'errorMessage',
        displayName: this.captions.tbl_hdr_errorMessage,
        displayNameId: 'tbl_hdr_errorMessage'
      },
    ]
  }

  getTableOptions(): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'screen',
    }
  }

  getTableContent() {
    return [
      {
        id: 1,
        screen: 'ASASAS',
        rowNumber: 12,
        errorMessage: 'asasqw1qw'
      },
      {
        id: 2,
        screen: 'QWQW',
        rowNumber: 14,
        errorMessage: 'qwerty'
      }
    ]
  }
  async download(dataUtilityId : number) {
    let file = await this._dataservice.downloadErrorRowsExcelData(dataUtilityId);    
    FileSaver.saveAs(file, 'Failures'+'_'+ dataUtilityId.toString() + '_' + moment().format('MM-DD-YYYY_hh:mm:ss').toString() + '.xlsx');
    return true;
  }

}
