import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkinnerbool'
})
export class CheckinnerboolPipe implements PipeTransform {
  transform(value: boolean, paramsinner: any):boolean {
    let ReturnBool:boolean;
    if (typeof paramsinner === "object") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  }
}
