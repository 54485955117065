import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { of } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { AgDropdownConfig, ButtonValue, DropdownOptions } from 'src/app/common/Models/ag-models';
import { CommonPropertyInformation } from '../../../services/common-property-information.service';
import { CommonUtilities } from '../../utilities/common-utilities';
import { CommonItineraryDataService, GetActivitiesByGuestIds, GetGuestItineraryByDate } from './common-itinerary.data.service';
import { CompareKey, EXTERNALACTIVITYINTERFACENAME, PMSSSYSTEM } from 'src/app/common/constants';
import { ActivityConstants, Product, PackageName, AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { RetailItemType,appointmentLockerDetails,activityLockerInfo } from '../../../retail.modals';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { HttpMethod,HttpServiceCall} from 'src/app/retail/shared/service/http-call.service';
import { Host } from '../../globalsContant';
@Component({
  selector: 'app-common-itinerary',
  templateUrl: './common-itinerary.component.html',
  styleUrls: ['./common-itinerary.component.scss'],
  providers: [CommonItineraryDataService]
})
export class CommonItineraryComponent implements OnInit {
  primaryArray;
  captions: any;
  @Input() guestId;
  @Input() isCopyClient = false;
  @Input() fromClient;
  fromTeeTime = false;
  teeTimePlayersGuestId: any;
  searchButton: ButtonValue;
  golf = Product.GOLF;
  spa = Product.SPA;
  floatLabel: string;
  showEditIcon: boolean = false;
  @Input('fromTeeTime')
  set setfromTeeTime(val) {
    this.fromTeeTime = val;
  }
  @Input('teeTimePlayersGuestId')
  set setTeeTimePlayersGuestId(val) {
    if (val) {
      this.teeTimePlayersGuestId = val;
    }
  }
  @Input() isHitoricalOnEdit: boolean = false;
  data:any;
  itineraryForm: UntypedFormGroup;
  startDate: Date;
  endDate: Date;
  propertyTenantId: string;
  propertyTimeZone: string;
  placeHolderFormat: string;
  captionsCommon: any;
  playersData: DropdownOptions[];
  productOptions: AgDropdownConfig;
  products: DropdownOptions[];
  isDisable: boolean;
  isResult: boolean;
  productId: number;
  rentalItems = [];
  retailItems = [];
  isRentalRetailLoaded = false;
  lockerAppointmentDetails: appointmentLockerDetails[];
  activityLockerInfo: activityLockerInfo ={
    lockerName :"",
    lockerNumber: 0
  }
  @Input('rentalRetailItems')
  set setRentalRetailItems(val:any[]) {
    if (val) {
      this.retailItems = val?.filter(y => y.retailItemType==RetailItemType.RetailItemRetailPOSOnly)?.map(x => this.mapRetailOrRentalItems(x));
      this.rentalItems = val?.filter(y => y.retailItemType==RetailItemType.PMSAddOnsRentalItem)?.map(x => this.mapRetailOrRentalItems(x));
      this.retailItems = [...this.retailItems]
      this.rentalItems = [... this.rentalItems]
    }
    this.isRentalRetailLoaded = true;
  }
  externalGuestJson; 
  propertyId: number;
  isRentalEnabled: boolean;
  @Input() clientForms;
  @Output() guestEditData = new EventEmitter();
  @Output() onItineraryEdit = new EventEmitter();
  @Output() viewRentalRetail = new EventEmitter();
  constructor(private localization: Localization, private fb: UntypedFormBuilder,
    private propertyInformation: CommonPropertyInformation,
    public _cdr: ChangeDetectorRef,
    private commonItineraryDataService: CommonItineraryDataService,
    public utils: CommonUtilities, 
    private _retailFeatureFlag: RetailFeatureFlagInformationService,private http: HttpServiceCall) {
    this.captions = this.localization.captions.commonItinerary;
    this.propertyInformation.SetDefaultDataOnLoad();
    this.captionsCommon = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
    this.isRentalEnabled = this._retailFeatureFlag.IsRentalEnabled;
  }

  ngOnInit() {
    this.placeHolderFormat = this.localization.inputDateFormat;
    this.startDate = this.propertyInformation.CurrentDate;
    this.productId = Number(this.utils.GetPropertyInfo('ProductId'));
    this.endDate = this.propertyInformation.CurrentDate;
    this.propertyId = this.propertyInformation.PropertyId;
    this.propertyTenantId = this.propertyInformation.GetPropertyInfoByKey("PlatformTenantId");
    this.propertyTimeZone = this.propertyInformation.GetPropertyInfoByKey("TimeZone");
    this.externalGuestJson = this.fetchGolfSearchJson();
    this.formGenerator();
    this.searchButton = {
      type: 'primary',
      label: this.captions.search,
      disabledproperty: this.isDisable
    };
  }

  showmore(e, obj) {
    obj.showmoreDetails = !obj.showmoreDetails;
  }

  async formGenerator() {
    this.itineraryForm = this.fb.group({
      startDate: this.startDate,
      endDate: this.endDate,
      viewCancelledBookings: false,
      players: '',
      products: ''
    })
    if (!this.fromTeeTime && !this.isCopyClient) {
      this.setEndDateForTeeTimeActivitySearch(this.guestId);
    } else {
      this.initializeInputs();
    }
  }

  async initializeInputs() {
    this.playersData = this.teeTimePlayersGuestId.map(result => {
      return {
        id: result.id,
        value: result.playerLinkId,
        viewValue: result.playerName
      };
    });
    this.itineraryForm.controls.players.setValue(this.playersData[0]);
    if (this.playersData.length === 0) {
      this.itineraryForm.controls.players.disable();
    }
    this.isDisable = this.playersData.length === 0;
    let externalActivityInterfaceName = this.localization.GetPropertyConfig(EXTERNALACTIVITYINTERFACENAME);
    let pmsSystem = this.localization.GetPropertyConfig(PMSSSYSTEM);
    this.products = [
      { id: 1, value: ProductType.GolfActivityType, viewValue: 'Agilysys Golf' },
      { id: 2, value: ProductType.SpaActivityType, viewValue: 'Agilysys Spa' },
      { id: 3, value: this.externalGuestJson && this.externalGuestJson.length > 0 && this.externalGuestJson.some(e => e.integratedPms = pmsSystem) ? externalActivityInterfaceName : ProductType.PMSStayActivityType, viewValue: this.externalGuestJson && this.externalGuestJson.length > 0 && this.externalGuestJson.some(e => e.integratedPms = pmsSystem) ? this.externalGuestJson.find(e => e.integratedPms = pmsSystem).pmsName : 'Agilysys PMS' },
      { id: 4, value: ProductType.PMSActivityType, viewValue: 'Agilysys Activities' },
      { id: 5, value: ProductType.SeatActivityType, viewValue: 'Agilysys Seat' }
    ];
    this.productOptions = {
      className: 'ml-5 golf-form-control--lg',
      form: this.itineraryForm,
      formControlName: 'products',
      isFirstEmpty: false,
      placeHolderId: 'products',
      placeHolder: '',
      selectOptions: of(this.products),
      showRequired: false,
      isMultiSelect: true,
      isAll: true,
      isAllSelected: false
    }
    this.itineraryForm.controls.products.setValue(this.products);
    let hotelReservData = this.teeTimePlayersGuestId.find(s => s.id == this.playersData[0].id);
    if (hotelReservData) {
      this.setEndDateForTeeTimeActivitySearch(hotelReservData);
    }
  }

  dateChange(e) {

    this.startDate = this.itineraryForm.controls.startDate.value;
    this.endDate = this.itineraryForm.controls.endDate.value;
    if (this.endDate < this.startDate) {
      this.itineraryForm.controls.endDate.setValue(this.startDate);

      this.endDate = new Date(this.startDate);
    }

    if (!this.fromTeeTime && !this.isCopyClient) {
      this.getGuestItinerary();
    }
  }
  onChange(eve) {
    this.isDisable = this.itineraryForm.value.products.length === 0 || this.playersData.length === 0 ? true : false;
    this.searchButton.disabledproperty = this.isDisable;
  }
  async GetLockerInformation(APIDate: any){
    let appointmentIds: number[] = [];
    if(this._retailFeatureFlag.IsLockersEnabled){
      APIDate.Activities.forEach(x=>{
        x.Interfaces.forEach(element => {
         if(element.ActivityType== 'rG-Spa'){
          let  appointmentId: number = element.ActivityId;
              appointmentIds.push(appointmentId);
         }
        });
       })

       let result= await this.commonItineraryDataService.InvokeServiceCallAsync(RetailRoutes.GetLockerClientHistory,Host.retailPOS, HttpMethod.Put,this.guestId,appointmentIds);
       return result.result;
    }
    else
    {
      return [];
    }
  }

  MapAPIDataToUI(APIDate: any){
    this.data = [];
    let stayData: any[] = [];
    let bookingActivityTypes = ["V1-Stay", "LMS", "STAY"]

    if (APIDate && APIDate.Activities && APIDate.Activities.length > 0) {
      if (!this.itineraryForm.controls.viewCancelledBookings.value && (this.productId == Product.GOLF || this.productId == Product.SPA)) {
        APIDate.Activities = APIDate.Activities.filter(x => x.Status != 4);
      }
      APIDate.Activities.forEach(element => {
        element.Date = this.utils.getDate(_.cloneDeep(element.Start));
        element.BookingType = 'Arrival';
        let stayDate = new Date(element.End);
        if (bookingActivityTypes.includes(element.Interfaces[0].ActivityType) && this.startDate <= stayDate && this.endDate >= stayDate) {
          const stayActivity = Object.assign({}, element);
          stayActivity.Date = this.utils.getDate(_.cloneDeep(stayActivity.End));
          stayActivity.BookingType = 'Departure';
          stayData.push(stayActivity);
        }
      });
      APIDate.Activities.push(...stayData);
      APIDate.Activities = _.orderBy(APIDate.Activities, [act => act.Date], 'asc');
      APIDate.Activities.forEach(element => {
        let startTime = this.utils.getLocalizeTime(element.Start.replace("T", " ").replace("Z", ""));
        let endTime = this.utils.getLocalizeTime(element.End.replace("T", " ").replace("Z", ""));
        let golfItineraryCheck = element.Interfaces.some(x => x.ActivityType == 'rG-Golf');
        let spaItineraryCheck = element.Interfaces.some(x => x.ActivityType == 'rG-Spa');
        let activityId = Number(element.Interfaces[0].ActivityId);
        if(spaItineraryCheck){
          let filteredLockerdets = this.lockerAppointmentDetails?.find(y=> y.bookingTypeId == activityId);
          if(filteredLockerdets){
            this.activityLockerInfo.lockerName = filteredLockerdets?.lockerName;
            this.activityLockerInfo.lockerNumber = filteredLockerdets?.lockerNumber;
          }
        }
        let sameDayItinerary = this.data.filter(x => x.fullDate.valueOf() == this.utils.GetDateWithoutTime(element.Date).valueOf())[0];
        if (sameDayItinerary) {
          const tempStatusValue = golfItineraryCheck ? this.getGolfStatus(element.Status) : this.getSpaStatus(element.Status);
          sameDayItinerary.custDetails.push(
            {
              activityId: element.Id,
              title: element.ActivityDetail.ActivityDescription,
              type: element.Interfaces[0].ActivityType,
              activityTypeId: element.Interfaces[0].ActivityId,
              usercolor: "green",
              fromtime: startTime,
              totime: endTime,
              name: "",
              ellipseIcon: true,
              showmoreDetails: false,
              bookingId: element.PackageName !=  PackageName.Lesson ? element.ConfirmationNumber:"",
              status: element.PlatformActivityStatus ? element.PlatformActivityStatus : tempStatusValue,
              isRoomBooking: bookingActivityTypes.includes(element.Interfaces[0].ActivityType),
              //Kindly add the label values in en-us under "commonItinerary": { } 
              bookingtime: element.BookingType == "Arrival" ? this.captions.lbl_arrivaltime + " - " + startTime : this.captions.lbl_departuretime + " - " + endTime,
              showmoreList: [
                {
                  label: this.captions.Location,
                  value: element.ActivityDetail.ActivityLocation
                }, {
                  label: this.captionsCommon.lockerName,
                  value:this.activityLockerInfo?.lockerName
                },
                {
                  label: this.captionsCommon.lockerNumber,
                  value:this.activityLockerInfo?.lockerNumber
                }
              ],
              activityStatus: element.Status,
              package: element.PMSPackageDetails && element.PMSPackageDetails.length > 0 ? element.PMSPackageDetails[0]?.PackageCode : "",
              showEdit: golfItineraryCheck ? this.canShowEdit(element) : this.canShowSpaEdit(element)
            }
          )
        } else {
          const tempElement = golfItineraryCheck ? this.getGolfStatus(element.Status) : this.getSpaStatus(element.Status);
          let checkDate = new Date(element.Start);
          if (element.BookingType == 'Departure' || (this.startDate <= checkDate && this.endDate >= checkDate)) {
            this.data.push({
              iscurrenDate: this.utils.GetDateWithoutTime(element.Start).valueOf() == this.utils.GetDateWithoutTime(this.propertyInformation.CurrentDate).valueOf(),
              date: this.utils.getLocalizedMonthAndDate(element.BookingType == "Arrival" ? element.Start : element.End),
              fullDate: this.utils.GetDateWithoutTime(element.BookingType == "Arrival" ? element.Start : element.End),
              custDetails: [
                {
                  activityId: element.Id,
                  title: element.ActivityDetail.ActivityDescription,
                  type: element.Interfaces[0].ActivityType,
                  activityTypeId: element.Interfaces[0].ActivityId,
                  usercolor: "green",
                  fromtime: startTime,
                  totime: endTime,
                  name: "",
                  ellipseIcon: true,
                  showmoreDetails: false,
                  bookingId: element.PackageName !=  PackageName.Lesson ? element.ConfirmationNumber:"",
                  status: element.PlatformActivityStatus ? element.PlatformActivityStatus : tempElement,
                  activityStatus: element.Status,
                  isRoomBooking: bookingActivityTypes.includes(element.Interfaces[0].ActivityType),
                  //Kindly add the captions label values in en-us under "commonItinerary": { } 
                  bookingtime: element.BookingType == "Arrival" ? this.captions.lbl_arrivaltime + " - " + startTime : this.captions.lbl_departuretime + " - " + endTime,
                  showmoreList: [
                    {
                      label: this.captions.Location,
                      value: element.ActivityDetail.ActivityLocation
                    },
                    {
                      label: this.captionsCommon.lockerName,
                      value:this.activityLockerInfo?.lockerName
                    },
                    {
                      label: this.captionsCommon.lockerNumber,
                      value:this.activityLockerInfo?.lockerNumber
                    }
                  ],
                  package: element.PMSPackageDetails && element.PMSPackageDetails.length > 0 ? element.PMSPackageDetails[0]?.PackageCode : "",
                  showEdit: golfItineraryCheck ? this.canShowEdit(element) : this.canShowSpaEdit(element)
                }
              ]
            })
          }
        }
      });
    }
  }

  private checkDates(start) {
    let res = this.utils.ValidateDates(start, this.propertyInformation.CurrentDate);
    if ((res === CompareKey.equal) || (res === CompareKey.greater)) {
      return true;
    } else {
      return false;
    }
  }

  private canShowEdit(data) {
    let isNotAHistoricDate = this.checkDates(data.Start);
    let isFromGolfActivity = data.Interfaces.filter(x => x.ActivityType === ProductType.GolfActivityType).length > 0 ? true : false;
    let propertyId: number = Number(this.utils.GetPropertyInfo('PropertyId'));
    if (isNotAHistoricDate && !this.fromTeeTime && (data.Status === 0 || data.Status === 1) && isFromGolfActivity && data.PackageName != PackageName.Lesson && this.productId == Product.GOLF
     && propertyId.toString() === data.PropertyId) {
      return true;
    } else
      return false;
  }
  private canShowSpaEdit(data) {
    let isNotAHistoricDate = this.checkDates(data.Start);
    let isFromSpaActivity = data.Interfaces.filter(x => x.ActivityType === ProductType.SpaActivityType).length > 0 ? true : false;
    if (isNotAHistoricDate && (data.Status === 0 || data.Status === 1) && isFromSpaActivity && data.PropertyId == this.propertyId && this.productId == Product.SPA && this.fromClient) {
      return true;
    } else
      return false;
  }

  private async getGuestItinerary(playerLinkId: string = '') {
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 0);
    let reqBody: GetGuestItineraryByDate = {
      guestId: (this.guestId != 0 && this.guestId.length > 0) ?
        this.guestId : playerLinkId && playerLinkId.length > 0 ? playerLinkId : 0,
      tenantId: this.propertyTenantId,
      startDate: this.utils.convertDateFormat(this.startDate),
      endDate: this.utils.convertDateFormat(this.endDate),
      propertyTimeZone: this.propertyTimeZone,
      includeDeparture: true,
      includeCancelledActivities: (this.productId == Product.GOLF || this.productId == Product.SPA) ? true : false
    }
    var result = await this.commonItineraryDataService.GetGuestItineraryData(reqBody);
    this.showEditIcon = this.ValidateActivity(result);
    this.lockerAppointmentDetails = await this.GetLockerInformation(result);
    this.MapAPIDataToUI(result);
  }

  private async getGuestItineraryForTeeTime() {
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 0);
    let reqBody: any = {
      guestId: this.itineraryForm.value.players.value,
      tenantId: this.propertyTenantId,
      startDate: this.utils.convertDateFormat(this.startDate),
      endDate: this.utils.convertDateFormat(this.endDate),
      productTypes: this.itineraryForm.value.products.map(x => x.value),
      propertyTimeZone: this.propertyTimeZone
    }
    var result = await this.commonItineraryDataService.GetGuestItineraryDataForTeeTime(reqBody);
    this.MapAPIDataToUI(result);
  }

  getSpaStatus(status: ActivityStatus) {
    switch (status) {
      case ActivityStatus.Reserved:
        return this.captions.Reserved;
      case ActivityStatus.CheckedIn:
        return this.captions.CheckedIn;
      case ActivityStatus.CheckedOut:
        return this.captions.CheckedOut;
      case ActivityStatus.NoShow:
        return this.captions.NoShow;
      case ActivityStatus.Cancelled:
        return this.captions.Cancelled;

      default:
        return this.captions.Unknown;
    }
  }

  getGolfStatus(status: ActivityStatus) {
    var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    var isRequireCheckInCheckOutEnabled = teeTimeSetting && teeTimeSetting != null && teeTimeSetting?.requireCheckInCheckOut
    switch (status) {
      case ActivityStatus.Reserved:
        return this.captions.Booked;
      case ActivityStatus.CheckedOut:
        return isRequireCheckInCheckOutEnabled ? this.captions.CheckedOut : this.captions.Paid;
      case ActivityStatus.CheckedIn:
        return isRequireCheckInCheckOutEnabled ? this.captions.CheckedIn : this.captions.Unknown
      case ActivityStatus.NoShow:
        return this.captions.NoShow;
      case ActivityStatus.Cancelled:
        return this.captions.Cancelled;

      default:
        return this.captions.Unknown;
    }
  }

  search() {
    this.getGuestItineraryForTeeTime();
  }
  public fetchGolfSearchJson(): any {
    const golfSearchUrl = './assets/json/GolfSearchJson.json';
    return this.localization.jsonReader.getJSON(golfSearchUrl);
  }

  onPlayerSelectionChange(eve, selectedPlayerDetail) {
    this.setEndDateForTeeTimeActivitySearch(selectedPlayerDetail);
  }

  async setEndDateForTeeTimeActivitySearch(playerDetail: any) {
    if ((this.fromTeeTime && playerDetail?.playerLinkId?.length > 0) || (!this.fromTeeTime && this.guestId?.length > 0)) {
      let reqBody: GetActivitiesByGuestIds = {
        guestIds: playerDetail?.playerLinkId ? [playerDetail.playerLinkId] : [this.guestId],
        tenantId: this.propertyTenantId,
        startDate: this.utils.convertDateFormat(this.startDate)
      }
      let result = await this.commonItineraryDataService.GetActivitiesByGuestIds(reqBody);
      if (result && result[ActivityConstants.Activities].length > 1) {
        let descSortedActivities = result[ActivityConstants.Activities].sort((a, b) => {
          const dateA = new Date(a.End);
          const dateB = new Date(b.End);
          return dateA.getTime() - dateB.getTime();
        });
        this.startDate = new Date(descSortedActivities[0].Start);
        this.endDate = new Date(descSortedActivities[descSortedActivities.length - 1].End);
        this.itineraryForm.controls.startDate.setValue(this.startDate);
        this.itineraryForm.controls.endDate.setValue(this.endDate);
      }
      else if (result && result[ActivityConstants.Activities].length == 1) {
        this.startDate = new Date(result[ActivityConstants.Activities][0].Start);
        this.endDate = new Date(result[ActivityConstants.Activities][0].End);
        this.itineraryForm.controls.startDate.setValue(this.startDate);
        this.itineraryForm.controls.endDate.setValue(this.endDate);
      }
      this.getGuestItinerary(playerDetail?.playerLinkId?.length > 0
        ? playerDetail.playerLinkId : this.guestId?.length > 0 ? this.guestId : 0);
    }
  }

  async openEdit(eve, data) {
    console.log(data);

    if (data) {
      if (this.productId == Product.SPA) {
        this.onItiEdit(data);

      }
      else
        this.guestEditData.emit(data.activityId);
    }
  }

  ValidateActivity(data: any) {

    return true;
  }
  async onItiEdit(data) {
    // API Call to get Appointment Info with the Conf Id in data

    if (this.clientForms) {
      if ((this.clientForms.personalDetailsFormGroup?.dirty && this.clientForms.personalDetailsFormGroup?.touched) ||
        (this.clientForms.additionalDetailsFormGroup?.dirty && this.clientForms.additionalDetailsFormGroup?.touched) ||
        (this.clientForms.otherDetailsFormGroup?.dirty && this.clientForms.otherDetailsFormGroup?.touched) ||
        (this.clientForms.guaranteeFormGroup?.dirty && this.clientForms.guaranteeFormGroup?.touched) ||
        (this.clientForms.medicalHistoryFormGrp?.dirty && this.clientForms.medicalHistoryFormGrp?.touched) ||
        (this.clientForms.soapNotesFormGrp?.dirty && this.clientForms.soapNotesFormGrp?.touched) ||
        (this.clientForms.contactLogsFormGrp?.dirty && this.clientForms.contactLogsFormGrp?.touched) ||
        (this.clientForms.guestContactFormGrp?.dirty && this.clientForms.guestContactFormGrp?.touched)) {
        this.utils.showAlert(this.captionsCommon.WarningMessage, AlertType.Warning, ButtonType.YesNo, async (res) => {
          if (res === AlertAction.YES) {
            let appointmentData: any;
            appointmentData = await this.commonItineraryDataService.GetAppointmentByConfirmationNumber(data.bookingId);
            if (appointmentData && appointmentData?.length > 0) 
            {
              let appId = appointmentData[0].id;
              let emitfromItinerary = {
                appointmentId: appId,
                appointmentDate : this.localization.LocalizeDate(appointmentData[0].appointmentDetail.startTime),
                from : 'itinerary',
                therapistIds: appointmentData[0].appointmentTherapists.map(x=>x.therapistId)
              }
              this.onItineraryEdit.emit(emitfromItinerary);
            }
          }
          else {
            return;
          }
        });
      }
      else
      {
        let appointmentData: any;
        appointmentData = await this.commonItineraryDataService.GetAppointmentByConfirmationNumber(data.bookingId);
        if (appointmentData && appointmentData?.length > 0) 
        {
          let appId = appointmentData[0].id;
          let emitfromItinerary = {
            appointmentId: appId,
            appointmentDate : this.localization.LocalizeDate(appointmentData[0].appointmentDetail.startTime),
            from : 'itinerary',
            therapistIds: appointmentData[0].appointmentTherapists.map(x=>x.therapistId)
          }
          this.onItineraryEdit.emit(emitfromItinerary);
        }
      }

    }
  }
  getRetailOrRentalItems(data){
    this.isRentalRetailLoaded = false;
    this.rentalItems = [];
    this.retailItems = [];
    this.viewRentalRetail.emit(data);
    this._cdr.detectChanges();
  }
  mapRetailOrRentalItems(item)
  {
    return {
      itemDescription : item.itemDescription,
      quantity: `(${item.totalQuantityCount})`
    }
  }
}



export enum ActivityStatus {
  Reserved = 0,
  CheckedIn,
  CheckedOut,
  NoShow,
  Cancelled,
  Unknown
}

export enum ProductType {
  PMSActivityType = "V1-PMS",
  SeatActivityType = "rG-Seat",
  GolfActivityType = "rG-Golf",
  SpaActivityType = "rG-Spa",
  PMSStayActivityType = "V1-Stay"
}
