import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';

@Component({
  selector: 'app-package-sold',
  templateUrl: './package-sold.component.html',
  styleUrls: ['./package-sold.component.scss']
})
export class PackageSoldComponent implements OnInit {
  packageSoldFrmgrp: UntypedFormGroup;
  captions: any;
  @Output() formReady = new EventEmitter();
  radioFilter = [];
  constructor(private fb: UntypedFormBuilder
    , private PropertyInfo: SpaPropertyInformation
    , public localization: SpaLocalization) {
    this.captions = this.localization.captions.reports;
  }

  ngOnInit() {
    let config = this.PropertyInfo.AppointmentConfigurations();
    this.packageSoldFrmgrp = this.fb.group({
      ExcludeCanceledNoShow: (config["EXCLUDE_CANCELED_NOSHOW"]=='true' || config["EXCLUDE_CANCELED_NOSHOW"]==true) ?true:false
    });

    this.formReady.emit(this.packageSoldFrmgrp);
  }
}
