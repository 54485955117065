import { Injectable } from "@angular/core";
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { Localization } from "../../localization/localization";

@Injectable()
export class AutomailBusiness {
  captions: any;
  constructor(private localization: Localization) {
    this.captions = this.localization.captions;

  }
    getTableOptions() {
      return {
        defaultsortingColoumnKey: 'listOrder',
        defaultSortOrder: SorTypeEnum.asc,
        showTotalRecords: false,
        columnFreeze: {
          firstColumn: false,
          lastColumn: false
        },
        isDragDisabled: true,
        deleteMsgKey: 'name' // Change To the coresponding Name value here
      };
    }
    getData(){
        return [
                       {
                        name : this.captions.pg_title_checkIn,
                        data:  [
                            {
                              id : "1",
                              code: "Form1",
                              checked: false
                            },
                            {
                              id : "2",
                              code: "Form2",
                              checked: false
                            },
                            {
                              id : "3",
                              code: "Form3",
                              checked: false
                            },
                        ],
                        header: [
                            {
                                displayName: '',
                                key: 'checked',
                                searchable: true,
                                sorting: false,
                                sortingKey: '',
                                templateName: ActionTypeEnum.radioButton,
                                radioKey: 'checkincode'
                              },
                              {
                                displayName: this.captions.tbl_hdr_code,
                                key: 'checkincode',
                                searchable: true,
                                sorting: false,
                                sortingKey: 'checkincode',
                              },
                              {
                                displayName: this.captions.tbl_hdr_emailTemplate,
                                key: 'name',
                                searchable: true,
                                sorting: false,
                                sortingKey: 'name',
                              }
                        ],
                        content:[
                            {
                                active: true,
                                checked: true,
                                checkincode: "check1",
                                id: 2,
                                listOrder: 15,
                                name: "check1",
                                rcode: "check1",
                                templateData: "",
                                type: 1,
                                value: "check1",
                                default: false,
                                max: 2
                              },
                              {
                                active: true,
                                checked: false,
                                checkincode: "check2",
                                id: 2,
                                listOrder: 15,
                                name: "check2",
                                rcode: "check2",
                                templateData: "",
                                type: 2,
                                value: "check2",
                                default: false,
                                max: 1
                              }
                        ]
                    },
                    {
                        name : this.captions.pg_title_checkOut,
                        data:  [
                            {
                              id : "1",
                              code: "Form1",
                              checked: false
                            },
                            {
                              id : "2",
                              code: "Form2",
                              checked: false
                            },
                            {
                              id : "3",
                              code: "Form3",
                              checked: false
                            },
                            {
                                id : "4",
                                code: "Form4",
                                checked: false
                              },
                        ],
                        header: [
                            {
                                displayName: '',
                                key: 'checked',
                                searchable: true,
                                sorting: false,
                                sortingKey: '',
                                templateName: ActionTypeEnum.radioButton,
                                radioKey: 'checkoutcode'
                              },
                              {
                                displayName: this.captions.tbl_hdr_code,
                                key: 'checkoutcode',
                                searchable: true,
                                sorting: false,
                                sortingKey: 'checkoutcode',
                              },
                              {
                                displayName: this.captions.tbl_hdr_emailTemplate,
                                key: 'name',
                                searchable: true,
                                sorting: false,
                                sortingKey: 'name',
                              }
                        ],
                        content:[
                            {
                                active: true,
                                checked: true,
                                checkoutcode: "check1",
                                id: 2,
                                listOrder: 15,
                                name: "check1",
                                rcode: "check1",
                                templateData: "",
                                type: 1,
                                value: "check1",
                                default: false,
                                max: 3
                              },
                              {
                                active: true,
                                checked: false,
                                checkoutcode: "check2",
                                id: 2,
                                listOrder: 15,
                                name: "check2",
                                rcode: "check2",
                                templateData: "",
                                type: 2,
                                value: "check2",
                                default: false,
                                max: 1
                              }
                        ]
                    }, 
            ]    
    }
}