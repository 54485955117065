import { DisplayNameRemapConfig, FieldVisibleRemapConfig, FormDisplayHideRemapConfig } from './data-remap-config.interface';

export const FieldVisibleRemap: FieldVisibleRemapConfig = 
( remapJson: Array<any>, FieldKey: string, DisplayName: string, DefaultHideValue?: boolean ): boolean => {
    try {
        let displayNameHide = DefaultHideValue === undefined ? true : !DefaultHideValue;
        remapJson?.forEach((remap: any) => {
        //  const remapKey = Object.keys(remap)[0].toString();
        const remapKey = remap.Name.toString().toString();
         if ( FieldKey.toLowerCase() === remapKey.toLowerCase()) {
            const getMapAray = remap.UIFieldLookup;
            if ( getMapAray && getMapAray.length > 0 ) {
                getMapAray.forEach((element: any) => {
                    if ( element.Property_Key.toString().toLowerCase() === DisplayName.toString().toLowerCase()) {
                        displayNameHide = element.Visible;
                    }  
                 });
            }
         }
        });
        return displayNameHide;
    } catch (error) {
        console.error(error);
    }
};