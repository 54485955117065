import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { DropDownData } from '../../../business/report.modals';
import { ReportBusinessService } from '../../../business/report-business.service';
import { ReportDataService } from '../../../data/report-data.services';
import { ValidationMessage } from '../../../basereport/base-report.component';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';

@Component({
  selector: 'app-projected-revenue',
  templateUrl: './projected-revenue.component.html',
  styleUrls: ['./projected-revenue.component.scss']
})
export class ProjectedRevenueComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  @Output() validateUser = new EventEmitter<boolean>();
  projectedRevenueFrmGrp: UntypedFormGroup;
  ShiftFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  radioFilter: { id: number; name: string; }[];
  inActiveText = this.captions.IncludeInactiveUsers;
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  selectedDropdownType = '';
  valuesSelected: any[];
  allCategoryGroups: any[];
  serviceGroups: DropDownData[] = [];
  services: DropDownData[] = [];
  therapists: DropDownData[] = [];
  registers: any;
  serviceData : DropDownData[] = [];
  service : any[] = [];
  categoryGroupSelected : any[] = [];
  validServiceSelection: boolean = true;
  validServiceGroupSelection: boolean = true;
  validServiceGroupErrMsg:string;
  validServiceErrMsg:string;
  isClass: boolean = false;
  validTherapistSelection: boolean;
  validTherapistErrMsg: string;
  therapistData: DropDownData[];
  IncludeInactive: any;
  StaffDropDown: any;
  GroupsDropDown: any;
  SeviceDropDown: any;
  constructor(private fb: UntypedFormBuilder,private cdr: ChangeDetectorRef, public localization: SpaLocalization, public business: ReportBusinessService, private dataService: ReportDataService, public utils: SpaUtilities) {
    this.radioFilter =[
      { id: 0, name: this.captions.AppointmentReport },
      { id: 1, name: this.captions.Class}
     ];
  }

   ngOnInit() {
    this.projectedRevenueFrmGrp = this.fb.group({
      includeInactiveTherapists: false,
      radioFilter: 0,
      therapists: new UntypedFormControl([this.defaultData]),
      serviceGroups: new UntypedFormControl([this.defaultData]),
      services: new UntypedFormControl([this.defaultData])
    });
    this.onLoad();
    this.isValidText(true);
    this.formReady.emit(this.projectedRevenueFrmGrp);
  }

  ngOnDestroy() {
    this.isValidText(true);
  }

  toggleIncludeInactive(e){
    this.therapists = [];
    if(e){
    // this.therapists = [...this.therapistData];
     this.therapists = this.therapistData.map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    } else {
     this.therapists = this.therapistData.filter(t=>t.isActive).map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    }
    this.cdr.detectChanges(); 
    this.formReady.emit(this.projectedRevenueFrmGrp); 
  }

  reportTypeChange(id){
    this.toggleIncludeInactive(false);
    this.projectedRevenueFrmGrp.controls['includeInactiveTherapists'].setValue(false);
    this.projectedRevenueFrmGrp.controls.includeInactiveTherapists.updateValueAndValidity();
    if(id == 1)
    {
      this.isClass = true;
    }
    else
    {
      this.isClass = false;
    }
    this.onLoad();
    this.isValidText(true);
    this.formReady.emit(this.projectedRevenueFrmGrp);
  }

  private async onLoad() {
    this.getCaptions();
    if(!this.isClass){
    this.therapistData = await this.dataService.getAllTherapist("ProjectedRevenue");
    this.therapists = this.therapistData.filter(t=>t.isActive);
    this.serviceGroups = await this.dataService.getServiceGroup();
    this.service = await this.dataService.getServices();
    this.serviceData = this.service.map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    this.services = this.serviceData;
    this.business.FilterDataSource["therapists"] = this.therapists;
    this.business.FilterDataSource["services"] = this.service;
    this.business.FilterDataSource["serviceGroups"] = this.serviceGroups;
  }
  else{
    this.therapistData = await this.dataService.getAllInstructors("ProjectedRevenue");
    this.therapists = this.therapistData.filter(t=>t.isActive);
    this.serviceGroups = await this.dataService.getClassGroup();
    this.service = await this.dataService.getClasses();
    this.serviceData = this.service.map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    this.services = this.serviceData;
    this.business.FilterDataSource["therapists"] = this.therapists;
    this.business.FilterDataSource["services"] = this.service;
    this.business.FilterDataSource["serviceGroups"] = this.serviceGroups;
  }
  }

  getCaptions()
  {
    this.IncludeInactive = this.isClass?this.captions.IncludeInactiveInstructors:this.captions.IncludeInactiveTherapist;
    this.StaffDropDown = this.isClass? this.captions.Instructors : this.captions.Therapists;
    this.GroupsDropDown = this.isClass? this.captions.ClassGroups : this.captions.ServiceGroups;
    this.SeviceDropDown = this.isClass? this.captions.Classes : this.captions.Services
  }

  getFormControlValue(event) 
  {
     if (event[0] === 'serviceGroups') {
       this.projectedRevenueFrmGrp.setControl('serviceGroups', event[1]);
     } else if (event[0] === 'services') {
       this.projectedRevenueFrmGrp.setControl('services', event[1]);
       } 
       else if (event[0] === 'therapists') {
        this.projectedRevenueFrmGrp.setControl('therapists', event[1]);
        } 
  }

  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

  serviceGroupChange(event)
  {
    if(event == undefined)
    {
      this.services = this.business.FilterDataSource["services"].map(s => {
        return {
            id: s.id,
            description: s.description,
            showInDropDown: true
        }
      });
      this.serviceGroups = this.business.FilterDataSource["serviceGroups"];
      this.formReady.emit(this.projectedRevenueFrmGrp);
    }
    else{
      let serviceGroupIds : any[] = event.map(x => x.id);
    let data = this.service.filter(x => serviceGroupIds.includes(x.serviceGroupId));
    let serviceIds : any[] = data.map(x => x.id);
    this.services = this.serviceData.filter(x => serviceIds.includes(x.id));
    this.formReady.emit(this.projectedRevenueFrmGrp); 
    }
  }

  IsServiceGroupValid(validity: boolean) {
    this.validServiceGroupSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption:any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validServiceGroupErrMsg = this.localization.replacePlaceholders(caption,["option"],[this.captions.ServiceGroups]);
    }
  }

  IsServiceValid(validity: boolean) {
    this.validServiceSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption:any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validServiceErrMsg = this.localization.replacePlaceholders(caption,["option"],[this.captions.Services]);
    }
  }
  IsTherapistValid(validity: boolean) {
    this.validTherapistSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption:any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validTherapistErrMsg = this.localization.replacePlaceholders(caption,["option"],[this.captions.Therapists]);
    }
  }

}
