import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { recurringTypeConst, monthlyType, RecurringType } from './recurring.modal';
import { RecurringService } from './recurring.service';

import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-spa-recurring',
  templateUrl: './recurring.component.html',
  styleUrls: ['./recurring.component.scss'],
  providers: [RecurringService]
})
export class RecurringComponent implements OnInit, OnChanges {

  captions: any;
  daily: boolean;
  defaultRecurringData: RecurringType;
  errorCaptions: any;
  isDayMissing: boolean;
  monthSelectionType: any;
  monthly: boolean;
  months: RecurringType[];
  onoccurence: any;
  recurringFormGroup: UntypedFormGroup;
  recurringType: number;
  recurringTypeConst: any;
  startDateInputs: { className: string; form: any; formControlName: string; minDate: Date | string; placeHolder: any; value: Date; };
  weekly: boolean;
  weeklyData: RecurringType[];
  yearly: boolean;
  @Input() recurringData: RecurringType[];
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Output() recurringSelected: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder, private _RecurringService: RecurringService, private localization: SpaLocalization) {
    this.recurringTypeConst = recurringTypeConst;
    this.recurringFormGroup = this.formBuilder.group({
      recurring: [],
      daily: this.formBuilder.group({
        every: ['', Validators.required]
      }),
      weekly: this.formBuilder.group({
        every: '',
        weeklyData: []
      }),
      monthly: this.formBuilder.group({
        every: '',
        monthlyData: this.formBuilder.group({
          monthlytype: 1,
          date: '',
          onoccurence: '',
          weeklyData: ''
        })
      }),
      yearly: this.formBuilder.group({
        every: '',
        onoccurence: '',
        weeklyData: ''
      })
    });

    this.captions = this._RecurringService.generateCaptions();
    this.errorCaptions = this._RecurringService.generateErrorCaptions();
  }

  ngOnChanges(): void {
    this.enableDefaultRecurringType(this.startDate, this.endDate);
  }

  ngOnInit() {
    this.startDateInputs = {
      className: 'ag_form-control--xs',
      form: this.recurringFormGroup.controls.monthly['controls'].monthlyData,
      formControlName: 'date',
      minDate: this.startDate,
      placeHolder: this.captions.Chooseadate,
      value: this.startDate
    };
    this.defaultRecurringData = this._RecurringService.defaultRecurringData;
    this.weeklyData = this._RecurringService.weeklyData;
    this.monthSelectionType = this._RecurringService.monthSelectionType;
    this.onoccurence = this._RecurringService.onoccurence;
    this.months = this._RecurringService.months;
    this.recurringType = recurringTypeConst.daily;
    this.recurringFormGroup.controls.daily['controls'].every.setValidators([Validators.required]);
    this.recurringFormGroup.controls.daily['controls'].every.updateValueAndValidity();
    this.emitData();
  }

  buttonSelectionChange(event) {

    switch (event.value.id) {
      case 1:
        if (this.recurringType !== recurringTypeConst.daily) {
          this.resetValues();
          this.recurringType = recurringTypeConst.daily;
          this.recurringFormGroup.controls.daily['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.daily['controls'].every.updateValueAndValidity();
        }
        break;
      case 2:
        if (this.recurringType !== recurringTypeConst.weekly) {
          this.resetValues();
          this.recurringType = recurringTypeConst.weekly;
          this.recurringFormGroup.controls.weekly['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValidators([Validators.required]);
        }
        break;
      case 3:
        if (this.recurringType !== recurringTypeConst.monthly) {
          this.resetValues();
          this.recurringType = recurringTypeConst.monthly;
          this.recurringFormGroup.controls.monthly['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.monthly['controls'].every.updateValueAndValidity();
          if (this.recurringFormGroup.controls.monthly['controls'].monthlytype == 2) {
            this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators([Validators.required]);
            this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.updateValueAndValidity();
          }

        }
        break;
      case 4:
        if (this.recurringType !== recurringTypeConst.yearly) {
          this.resetValues();
          this.recurringType = recurringTypeConst.yearly;
          this.recurringFormGroup.controls.yearly['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.yearly['controls'].onoccurence.setValidators([Validators.required]);
          this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValidators([Validators.required]);
          this.recurringFormGroup.controls.yearly['controls'].weeklyData.updateValueAndValidity();
        }
        break;
    }
    this.recurringFormGroup.controls.recurring.setValue(event.value);
  }

  clearValidators() {
    this.recurringFormGroup.controls.daily['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.weekly['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValidators(null);
    this.recurringFormGroup.controls.monthly['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators(null);
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators(null);
    this.recurringFormGroup.controls.yearly['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.yearly['controls'].onoccurence.setValidators(null);
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValidators(null);
    this.recurringFormGroup.controls.daily['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.weekly['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.updateValueAndValidity();
    this.recurringFormGroup.controls.yearly['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.yearly['controls'].onoccurence.updateValueAndValidity();
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.updateValueAndValidity();
  }
  resetValues() {
    this.clearValidators();
    this.recurringFormGroup.controls.daily.reset();
    this.recurringFormGroup.controls.weekly.reset();
    this.recurringFormGroup.controls.monthly.reset();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.monthlytype.setValue(1);
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.date.setValue(this.localization.getDate(this.startDate));
    this.recurringFormGroup.controls.yearly.reset();
    this.isDayMissing = false;
  }
  yearlyonoccurence() {
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValidators([Validators.required]);
    if (!this.recurringFormGroup.controls.yearly['controls'].weeklyData.value ||
      (this.recurringFormGroup.controls.yearly['controls'].weeklyData.value &&
        this.recurringFormGroup.controls.yearly['controls'].weeklyData.value.length === 0)) {
      this.isDayMissing = true;
    } else {
      this.isDayMissing = false;
    }
  }

  monthlyonoccurence() {
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators([Validators.required]);
    if (!this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.value ||
      (this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.value &&
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.value.length === 0)) {
      this.isDayMissing = true;
    } else {
      this.isDayMissing = false;
    }
  }

  weeklyonoccurence() {
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValidators([Validators.required]);
    if (!this.recurringFormGroup.controls.weekly['controls'].weeklyData.value ||
      (this.recurringFormGroup.controls.weekly['controls'].weeklyData.value &&
        this.recurringFormGroup.controls.weekly['controls'].weeklyData.value.length === 0)) {
      this.isDayMissing = true;
    } else {
      this.isDayMissing = false;
    }
  }

  dailyInputChange(_event) {
    this.emitData();
  }

  weeklyButtonSelectionChange(event) {
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValue(event.value);
    this.emitData();
    this.weeklyonoccurence();
  }

  monthlyButtonSelectionChange(event) {
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValue(event.value);
    this.emitData();
    this.monthlyonoccurence();
  }

  YearlyButtonSelectionChanged(event) {
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValue(event.value);
    this.emitData();
    this.yearlyonoccurence();
  }

  YearlyButtonSelectionChange(_event) {
    this.emitData();
  }



  radioclick(_eve) {
    if (this.recurringFormGroup.controls['monthly'].get('monthlytype').value.id === monthlyType.date) {
      this.recurringFormGroup.controls.monthly['controls'].monthlytype.addControl('date', new UntypedFormControl());
    } else if (this.recurringFormGroup.controls['monthly'].get('monthlytype').value.id === monthlyType.day) {
      this.recurringFormGroup.controls.monthly['controls'].monthlytype.addControl('onoccurence', new UntypedFormControl());
      this.recurringFormGroup.controls.monthly['controls'].monthlytype.addControl('weekly', new UntypedFormControl());
    }
  }

  emitData() {
    let recurring = this.recurringFormGroup;
    this.recurringSelected.emit(recurring);
  }

  enableDefaultRecurringType(selectedStartDate, selectedEndDate) {
    const dayDiff = this._RecurringService.dateDiff(selectedStartDate, selectedEndDate);
    const monthDiff = this._RecurringService.monthDiff(selectedStartDate, selectedEndDate);
    const yearDiff = this._RecurringService.yearDiff(selectedStartDate, selectedEndDate);
    this.recurringData.forEach(repeatType => {
      switch (repeatType.id) {
        case 1:
          repeatType.disabled = dayDiff < 1;
          break;
        case 2:
          repeatType.disabled = dayDiff < 8;
          break;
        case 3:
          repeatType.disabled = monthDiff < 1;
          break;
        case 4:
          repeatType.disabled = yearDiff < 1;
          break;
      }
    });
    const selectedOccuranceValues = this.recurringData.filter(selectedOccurances =>
      this.recurringFormGroup.controls.recurring.value && selectedOccurances.id === this.recurringFormGroup.controls.recurring.value.id
    );
    if (selectedOccuranceValues && selectedOccuranceValues.length > 0 && selectedOccuranceValues[0].disabled) {
      this.recurringType = recurringTypeConst.daily;
      this.recurringData = [...this.recurringData];
    }
  }

  monthlytypeChange(event) {
    switch (event.value) {
      case 1:
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.patchValue([]);
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators(null);
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators(null);
        break;
      case 2:
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators([Validators.required]);
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators([Validators.required]);
        break;
    }
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.updateValueAndValidity();
    this.emitData();
  }
}
