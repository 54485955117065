import { Component, Inject, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FileData } from 'src/app/eatecui/source/shared/models/file-data';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { StateService } from 'src/app/eatecui/source/shared/services/state.service';
import { VideoFormats } from 'src/app/eatecui/source/transaction/shared/enum/transaction-enum';
import { AgilysysButtonModel, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { ConfirmPopModel, StreamingModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-video-popup',
  templateUrl: './agilysys-video-streming.component.html',
  styleUrls: ['./agilysys-video-streming.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysVideoStremingComponent implements AfterViewInit {
  agilysysCofirmationButton: AgilysysButtonModel[];
  videoFile = true;
  pictureData: any;
  fileName: any;
  constructor(public dialogRef: MatDialogRef<AgilysysVideoStremingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StreamingModel, 
    private httpService: HttpService,
    private stateService: StateService, 
    private envService: EnvService, public domSanitizer: DomSanitizer ) {
      this.agilysysCofirmationButton = [
        {
          displayProperity: false,
          displayName: 'close',
          buttonKey: 'Close',
          buttonType: 'agilysys-button-primary',
          buttonCallBack: (callBackData: CallBackData) => {
              this.dialogRef.close(callBackData.buttonValue.buttonKey);
          }
        }
      ];
     }
     ngAfterViewInit(): void {
      this.fileName = (this.data && this.data.hasOwnProperty('FileData') && this.data.FileData.hasOwnProperty('FileName')) ?
      this.data.FileData.FileName.split('.')[0] : (this.data && this.data.hasOwnProperty('FileData') && this.data.FileData.hasOwnProperty('Name')) ?
       this.data.FileData.Name.split('.')[0] : ''; 
      if (this.data.FileData.Type === VideoFormats.MP4 || this.data.FileData.Type === 'video/mp4') {
        this.videoFile = true;
        const video = document.getElementById('videoStream');
        if (this.data.FileData && this.data.FileData.Id === 0) {
            // const localStream = `data:video/mp4;base64,${this.data.FileData.Picture}`;
            video.setAttribute('src', this.data.FileData.LocalUrl);
        } else {
          FileData.FileVideo(video, this.httpService, this.stateService, this.envService, this.data.FileData, 'Mat');
        }
      } else {
        this.videoFile = false;
        
        const imageUrl = `${this.data.FileData.Picture}`;
        this.pictureData =this.data.FileData.Picture;
       
      }
    }

}
