import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, uniq } from 'lodash';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { Product } from 'src/app/common/enums/shared-enums';
import { EventDataServices } from '../../dataservices/notification/event.data.service';
import { TemplateDataServices } from '../../dataservices/notification/template-data-service';
import { Localization } from '../../localization/localization';
import { AlertAction, AlertType, ButtonTypes, DmConfig } from '../../Models/common.models';
import { CommonDataAwaiters } from '../../shared/events/awaiters/common.data.awaiters';
import { GridType } from '../../shared/shared/globalsContant';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { EventNotification } from '../../templates/notification-configuration/notification-configuration.model';
import { Events } from '../notification-configuration/notification-configuration.model';
import { TemplateServiceCommunication } from '../templates.service';
import { CrudEmailTemplatesBusiness } from './crud-email-template/crud-email-template.business';
import { NotificationAPITemplate, NotificationTemplate, NotificationType } from './crud-email-template/crud-email-template.model';


@Component({
  selector: 'app-template-email',
  templateUrl: './template-email.component.html',
  styleUrls: ['./template-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CrudEmailTemplatesBusiness, TemplateDataServices, TemplateServiceCommunication, EventDataServices]
})
export class TemplateEmailComponent implements OnInit {
  @Output() emittedEmaiTempldata: EventEmitter<any> = new EventEmitter();
  @Output() copyEmailEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleActiveEmit: EventEmitter<any> = new EventEmitter();
  @Input() showTableRecord: boolean;
  @Input() isCopyEnabled;
  @Input() istoggleDirectEmit: boolean;

  emailtemplateCaptions: any;
  tableoptions: any;
  tabledata: NotificationTemplate[];
  events: Events[];
  hideCrudWindow: boolean = true;
  IsViewOnly: boolean = false;
  onEditDisableFunction: boolean = true;
  showInactiveToggle = false;
  emailtemplateSettingsCaptions: any;
  doneEventObj: any;
  productId: number;
  selectedFilterData: any[] = [];
  defaultData: any = {
    id: 0,
    code: 'ALL',
    description: this.localization.captions.common.all
  };
  originalTemplateData: NotificationTemplate[];
  filterEventsData: Events[];
  uniqueFilterData: NotificationTemplate[]; //john
  modifiedEvents: Events[] = [];
  EMAILFilterselectionData: any[] = [];
  dmConfig: DmConfig;
  isIntakeForm: boolean;
  isGdprEnabled: boolean;
  isToggleActive: boolean = false;
  isViewOnly = true;
  productTemplateIds: number[] = [];
  constructor(public localization: Localization, private _CrudEmailTemplatesBusiness: CrudEmailTemplatesBusiness, private _templateServiceCommunication: TemplateServiceCommunication,
    private _eventBusiness: EventDataServices, private commonUtils: CommonUtilities,
    private _userAccessBusiness: UserAccessBusiness) {
    this.emailtemplateCaptions = this.localization.captions.settings.utilities;
    this.emailtemplateSettingsCaptions = this.localization.captions.settings;
  }

  ngOnInit() {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.isGdprEnabled = JSON.parse(sessionStorage.getItem('isGdprEnabled'));
    this.getEmailData(false);
  }
  async getEmailData(event) {
    await this.ValidateBreakPoint();
    if(this.productId != Product.SALESANDCATERING){
    this.dmConfig = await this._CrudEmailTemplatesBusiness.getDmconfigSession();
    }
    this.isIntakeForm = await CommonDataAwaiters.GetIntakeDetails();
    await this.GetEvents();
    const templatedata = await this._CrudEmailTemplatesBusiness.getEmailTemplateData(NotificationType.EMAIL, event);
    for (let index = 0; index < templatedata.length; index++) {
      const data = templatedata[index];
      if (!this.dmConfig?.dmEformsConfig?.enableEforms && data.eventIds.some(id => id == 75)) {
        templatedata.splice(templatedata.indexOf(data), 1);
        index--;
      }
      if (!this.isIntakeForm && data.eventIds.some(id => id == 17)) {
        templatedata.splice(templatedata.indexOf(data), 1);
        index--;
      }
      if (!this.isGdprEnabled && data.eventIds.some(id => (id == EventNotification.GolfGuestDataRequest || id == EventNotification.SPAGuestDataRequest))) {
        templatedata.splice(templatedata.indexOf(data), 1);
        index--;
      }
    }
   
      this.productTemplateIds = await this._CrudEmailTemplatesBusiness.GetProductTemplateIds(this.productId);
    

    this.tabledata = this.MaptoUIMpdel(templatedata);
    this.originalTemplateData = cloneDeep(this.tabledata);
    let originalTemplateDataTemp: any = this.originalTemplateData.map(x => x.eventIds)
    let singlearrayobj = originalTemplateDataTemp.flat();
    let uniqueFilterData: any = uniq(singlearrayobj);
    this.modifiedEvents = this.events.filter(x => {
      return (uniqueFilterData.indexOf(x.id) != -1)
    })

    this.BindemailTableData(this.tabledata);
  }
   async ValidateBreakPoint() {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.EmailTemplate, true);
    this.IsViewOnly = result.isViewOnly;
  }

  
  async GetEvents() {
    this.events = await this._eventBusiness.getAllEventsByProductId(this.productId);
    if(!this.isGdprEnabled) this.events = this.events.filter(x=> x.id != EventNotification.GolfGuestDataRequest && x.id != EventNotification.SPAGuestDataRequest);

    if (this.productId == Product.PMS) {
      this.events = this.events.filter(event => event.id != EventNotification.Anniversary
        && event.id != EventNotification.Birthday
        && event.id != EventNotification.Consents
        && event.id != EventNotification.EventsGroups
        && event.id != EventNotification.EventsReminder
        && event.id != EventNotification.GDPROptInGuest
        && event.id != EventNotification.Groups
        && event.id != EventNotification.GuestStayFeedback
        && event.id != EventNotification.Invoices
        && event.id != EventNotification.NoShow
        && event.id != EventNotification.PreCheckIn
        && event.id != EventNotification.Reactivation
        && event.id != EventNotification.RMSEvent
        && event.id != EventNotification.VIPArrivals
        && event.id != EventNotification.WeddingsGroups
        && event.id != EventNotification.WelcomeMessage
        && event.id !=EventNotification.AccountCreated
        && event.id !=EventNotification.BookingCreated        
        && event.id != EventNotification.OTABookingConfirmation
        && event.id != EventNotification.OTABookingModification
        && event.id != EventNotification.OTABookingCancellation);
    }
    this.modifiedEvents = cloneDeep(this.events)

  }

  MaptoUIMpdel(tabledata): NotificationTemplate[] {
    let NotificationTemplates: NotificationTemplate[] = [];
    tabledata.forEach(element => {
      let notificationTemplate: any = {
        eventIds: element.eventIds,
        id: element.id,
        isActive: element.isActive,
        isDefault: element.isDefault,
        listOrder: element.listOrder,
        templateCode: element.templateCode,
        templateName: element.templateName,
        templateId: element.templateId,
        typeId: NotificationType.EMAIL,
        eventName: this.GetEventNames(element.eventIds)
      };
      NotificationTemplates.push(notificationTemplate);
    });
    return NotificationTemplates;
  }

  GetEventNames(eventIds: number[]) {
    const arr = this.events
      .filter(c => eventIds.includes(c.id))
      .map(dlc => dlc.eventName)
      .join(',');
    return arr.split(',');
  }


  BindemailTableData(tabledata) {
    this.tableoptions = [{
      TableHdrData: this.GetEmailHeader(),
      TablebodyData: tabledata,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.emailtemplateCaptions.emailSrchTxt,
      EnableActions: true,
      SelectRows: false,

      Searchable: GridType.customfield,
      EditMoreOption: true,
      Sortable: "listOrder",

      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: false,
      TableDraggable: true,
      showTableRecords: this.showTableRecord,
      isCopyEnabled: this.isCopyEnabled,
      istoggleDirectEmit: this.istoggleDirectEmit,
      disableCopy: this.IsViewOnly
    }];


  }
  private GetEmailHeader() {
    return [
      { "title": this.emailtemplateCaptions.event, "jsonkey": "eventName", "sortcolumndatatype": "array", "searchable": true, "sortable": true },
      { "title": this.emailtemplateCaptions.emailTempCode, "jsonkey": "templateCode", "sortcolumndatatype": "string", "searchable": true, "sortable": true },
      { "title": this.emailtemplateCaptions.emailTempName, "jsonkey": "templateName", "searchable": true, "sortable": true },
      { "title": this.emailtemplateCaptions.listOrder, "jsonkey": "listOrder", "searchable": false, "sortable": true, "alignType": "right" },
      { "title": this.emailtemplateCaptions.default, "jsonkey": "isDefault", "searchable": false, "sortable": true, "type": "toggle" },
      { "title": this.emailtemplateCaptions.active, "jsonkey": "isActive", "searchable": false, "sortable": true, "type": "toggle" }
    ];
  }
  crudemailTemplate() {
    this.emittedEmaiTempldata.emit({ editEmailObj: null, closeCrudWindow: false, isViewOnly: this.IsViewOnly });
  }
  async DeleteRecords(eve) {
    if (this.showTableRecord) {
      const deleteWarningMessage = `${this.emailtemplateCaptions.lbl_delete} ${eve[0].eventName}`;
      this.commonUtils.showCommonAlert(deleteWarningMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          if (!this.validateProductTemplate(eve[0].id)){
            this.delete(eve);
          }
        }
        else {
          return;
        }
      });
    }
    else {
      this.delete(eve);
    }
  }

  async delete(eve) {
    this.tabledata = await this._CrudEmailTemplatesBusiness.DeleteTemplate(eve[0].id);
    await this.getEmailData(this.showInactiveToggle);
  }

  EditRecords(eve) {
    this.emittedEmaiTempldata.emit({ editEmailObj: eve[0], closeCrudWindow: false, isViewOnly: this.IsViewOnly });
  }

  async showInactiveToggleEvent(event) {
    this.showInactiveToggle = event[0];
    await this.getEmailData(this.showInactiveToggle);
  }


  async DoneEvent(event) {
    this.isToggleActive = false;
    this.toggleActiveEmit.emit( this.isToggleActive)
    this.doneEventObj = event;
    if (event.isDefault) {
      let result = await this._CrudEmailTemplatesBusiness.DefaultTemplateExists(event.eventIds, event.id, NotificationType.EMAIL);
      if (result) {
        this._templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this), this.showConfirmationErrorCallback.bind(this));
      } else {
        await this.SaveTemplate();
      }
    } else {
      await this.SaveTemplate();
    }
  }

  copyEvent(event) {
    event.templateId = "";
    this.copyEmailEventEmitter.emit({ editEmailObj: event, closeCrudWindow: false });
  }

  toggleEvtEmit(event){
    this.isToggleActive = true;
    this.toggleActiveEmit.emit( this.isToggleActive)
  }

  CancelEvent(event){
    this.isToggleActive = event;
    this.toggleActiveEmit.emit( this.isToggleActive)
  }

  async showConfirmationSuccessCallback(res) {
    await this.SaveTemplate();
  }
  async showConfirmationErrorCallback(res) {
    this.doneEventObj.isDefault = false;
    await this.SaveTemplate();
  }


  async DragDropEvent(event) {
    let result = await this._CrudEmailTemplatesBusiness.UpdateListOrder(event[0], event[1], NotificationType.EMAIL);
    if (result) {
      await this.getEmailData(this.showInactiveToggle);
    }
  }

  async SaveTemplate() {
    let notificationTemplate = this.MaptoAPIMpdel(this.doneEventObj);
    try {
      let result = await this._CrudEmailTemplatesBusiness.UpdateTemplate(this.doneEventObj.id, notificationTemplate);
      if (result) {
        await this.getEmailData(this.showInactiveToggle);
      }
    }
    catch (e) {
      if (e && e.error && e.error.errorCode == '11106') {
        this.doneEventObj.isActive = !this.doneEventObj.isActive;
      }
    }
  }

  MaptoAPIMpdel(event): NotificationAPITemplate {
    return {
      eventIds: event.eventIds,
      id: event.id,
      isactive: event.isActive,
      isdefault: event.isDefault,
      listorder: event.listOrder,
      templateCode: event.templateCode,
      templateName: event.templateName,
      templateId: event.templateId,
      typeId: NotificationType.EMAIL
    }
  }


  filterTableData(event, selectedData) {
    if (event.checked) {
      if (selectedData.id === 0) {
        this.EMAILFilterselectionData = [];
        this.modifiedEvents.forEach(x => {
          this.EMAILFilterselectionData.push(x);
        })
      } else {
        this.EMAILFilterselectionData.push(selectedData);
      }
    } else {
      if (selectedData.id === 0) {
        this.EMAILFilterselectionData = [];
      } else {
        let index = this.EMAILFilterselectionData.indexOf(selectedData);
        this.EMAILFilterselectionData.splice(index, 1)
      }
    }
    this.filterSMSDisplaytabledata();
  }
  filterSMSDisplaytabledata() {
    let eventidArr = this.EMAILFilterselectionData.map(x => x.id);
    let filteredtabledata = [];
    if (eventidArr.length > 0) {
      this.originalTemplateData.forEach(x => {
        if (eventidArr.some(r => x.eventIds.includes(r))) {
          filteredtabledata.push(x);
        }
      })
    } else {
      filteredtabledata = this.originalTemplateData;
    }
    this.BindemailTableData(filteredtabledata);
  }
  validateProductTemplate(id){
    if ( this.productId == Product.SALESANDCATERING && this.productTemplateIds.find(x => x == id)){
      this.commonUtils.showCommonAlert(this.emailtemplateCaptions.lbl_EmailTemplateError, AlertType.Error, ButtonTypes.Ok);    
      return true;
    }
    else{
      return false;
    }
  }
}
