import { Component, ViewEncapsulation, ChangeDetectorRef, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { AppointmentpopupService } from '../../shared/service/appointmentpopup.service';
import { WaitListService, Therapists, ClientsTherapists } from '../../shared/addwaitlist-popup/waitlist-popup.service';
import { ServiceDetail } from '../../shared/business/shared.modals';
import { ButtonType } from '../../shared/globalsContant';
import * as _ from 'lodash';
import { SpaUtilities } from '../utilities/spa-utilities';


@Component({
  selector: 'app-addwaitlist-client-therapist-mapping',
  templateUrl: './addwaitlist-client-therapist-mapping.component.html',
  styleUrls: ['./addwaitlist-client-therapist-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddwaitlistClientTherapistMappingComponent {


  @Input() therapists: Therapists[]; // Availabe therapists on selected time
  @Input() clientTherapist: ClientsTherapists[]; // client Therapist mapping
  @Input() serviceDetail: ServiceDetail;
  @Output() enableConvertAppointment: EventEmitter<any> = new EventEmitter();

  clientsTherapistsAssigned: any[] = [];
  captions: any;
  selectedclient: number;
  selectedtherapist: any[] = [];
  isAllTherapistSelected = false;
  selectedClientIndex = 0;
  enableAssign = false;
  minimumTherapist: number = 1;
  maximumTherapist: number = 1;
  viewCheckedFlag = true;

  public get IsMultiClient() {
    return this.clientTherapist.length > 1;
  }

  constructor(public fb: UntypedFormBuilder,
    public localization: SpaLocalization, public appointmentpopservice: AppointmentpopupService,
    private waitListService: WaitListService,
    public utils: SpaUtilities, public changeDetection: ChangeDetectorRef) {
    this.captions = this.localization.captions.bookAppointment;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.serviceDetail) {
      this.minimumTherapist = this.serviceDetail ? this.serviceDetail.minimumStaff : 1;
      this.maximumTherapist = this.serviceDetail ? this.serviceDetail.maximumStaff : 1;
    }
    if (changes.therapists) {
      this.BuildClientTherapistAssigned();
      this.selectedclient = this.clientTherapist && this.clientTherapist.length > 0 ? this.clientTherapist[0].id : 0;

    }
    this.viewCheckedFlag = true;
    this.enableAssign = false;
  }

  BuildClientTherapistAssigned() {
    this.clientsTherapistsAssigned = [];
    for (let i = 0; i < this.clientTherapist.length; i++) {
      let therapistArr: number[] = this.clientTherapist[i].therapists;
      this.clientsTherapistsAssigned.push({
        'id': this.clientTherapist[i].id,
        'name': this.clientTherapist[i].name,
        'therapists': this.GetTherapistDetail(therapistArr)
      });

      // Rebuild original client/Therapist mapping based on availability
      // Removing original Therapist mapping based on availability
      let newTherapist: number[] = [];
      therapistArr.forEach(r => {
        if (this.waitListService.filterData && this.waitListService.filterData.therapistViewModels && this.waitListService.filterData.therapistViewModels.some(t => t.therapistId == r)) {
          newTherapist.push(r);
        }
      });
      this.clientTherapist[i].therapists = newTherapist;
    }
    this.viewCheckedFlag = true;
  }

  ngAfterViewChecked(): void {
    if (this.viewCheckedFlag) {
      this.viewCheckedFlag = false;
      setTimeout(() => { this.calculateHeight(); }, 1);
    }

  }

  calculateHeight() {
    let height = 0;

    let clientTableHeight = document.getElementById('client-table').offsetHeight;
    let therapistTableHeight = document.getElementById('therapist-table').offsetHeight;
    let clientTherapistTableHeight = document.getElementById('client-therapist-table').offsetHeight;

    if (clientTableHeight >= therapistTableHeight && clientTableHeight >= clientTherapistTableHeight) {
      height = clientTableHeight;
    } else if (therapistTableHeight >= clientTableHeight && therapistTableHeight >= clientTherapistTableHeight) {
      height = therapistTableHeight;
    } else {
      height = clientTherapistTableHeight;
    }

    if (height > 280) {
      height = 280;
    }


    let containerList = document.getElementsByClassName('waitlist-fixed-table-container');
    if (containerList) {
      Array.from(containerList).forEach(element => {
        element['style']['height'] = height + 50 + 'px';
      });
    }
  }


  GetTherapistDetail(therapistId: number[]): Therapists[] {
    let result: Therapists[] = [];
    therapistId.forEach(r => {
      result.push({
        id: r,
        name: this.waitListService.GetTherapistInfo([r])
      });
    });
    return result;
  }

  SelectClient(event, clientId, currentIndex) {
    this.selectedclient = clientId;
    this.selectedClientIndex = currentIndex;
  }

  async ValidateRule(event, therapist) {
    let selectedClientTherapist: number[] = _.cloneDeep(this.clientTherapist[this.selectedClientIndex].therapists);
    selectedClientTherapist = this.utils.getToggleFilter(this.therapists, selectedClientTherapist, therapist);
    if (! await this.ValidateTherapistRule(selectedClientTherapist)) {
      event.preventDefault();
    }
  }

  SelectTherapist(event, therapist) {
    let selectedClientTherapist: number[] = _.cloneDeep(this.clientTherapist[this.selectedClientIndex].therapists);
    selectedClientTherapist = this.utils.getToggleFilter(this.therapists, selectedClientTherapist, therapist);
    // updating selected client's therapist based on checkbox selection
    this.clientTherapist[this.selectedClientIndex].therapists = selectedClientTherapist;
    this.enableAssign = true;
    this.enableConvertAppointment.emit(!this.enableAssign);
  }

  datechange(event, date) {
    console.log('date changed', date);
  }

  async assignClientTherapistData() {
    if (! await this.ValidateOnAssign()) {
      return;
    }
    this.clientTherapist.forEach((data, index) => {
      this.clientsTherapistsAssigned[index].id = data.id;
      this.clientsTherapistsAssigned[index].name = data.name;
      this.clientsTherapistsAssigned[index].therapists = this.therapists.filter(x => data.therapists.includes(x.id));
    });
    this.enableAssign = false;
    this.enableConvertAppointment.emit(!this.enableAssign);
  }

  private async ValidateOnAssign() {
    let isValid = true;
    let showError = true;
    isValid = this.waitListService.ValidateMaximumTherapist(this.maximumTherapist, this.clientTherapist, showError);
    if (isValid) {
      isValid = this.waitListService.ValidateMinimumTherapist(this.minimumTherapist, this.clientTherapist, showError);
    }
    if (isValid) {
      isValid = this.waitListService.ValidateAllTherapistAssigned(this.clientTherapist, showError);
    }
    return isValid;
  }

  private async ValidateTherapistRule(selectedTherapist: number[]): Promise<boolean> {
    let isValid: boolean = false;
    if (this.IsMultiClient) {
      isValid = await this.ValidateMultiClientRule(selectedTherapist);
    }
    else {
      isValid = await this.ValidateSingleClientRule(selectedTherapist);
    }

    if (isValid) {
      isValid = await this.ValidateMaximumTherapist(selectedTherapist);
    }
    return isValid;
  }

  private async ValidateMultiClientRule(selectedTherapist: number[]): Promise<boolean> {
    let isValid: boolean = true;
    if (selectedTherapist.length > 1) {// Multi client can be assigned with maximum of 1 ctherapist
      this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(100012), ButtonType.Ok);
      isValid = false;
    }
    return isValid;
  }

  private async ValidateSingleClientRule(selectedTherapist: number[]): Promise<boolean> {
    return true;
  }
  private async ValidateMaximumTherapist(selectedTherapist: number[]): Promise<boolean> {
    let isValid: boolean = false;
    let therapistArray: number[][] = this.clientTherapist.map(t => t.therapists);
    let totalSelectedTherapist: number[] = [].concat.apply([], therapistArray);
    // total count of selected therapist for service
    totalSelectedTherapist = Array.from(new Set([...totalSelectedTherapist, ...selectedTherapist])); // remove duplicate therapists
    isValid = totalSelectedTherapist.length <= this.maximumTherapist;
    if (!isValid) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(100010), ButtonType.Ok);
    }
    return isValid;
  }
}
