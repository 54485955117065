import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridConfiguration, HeaderConfiguration, TilesConfiguration, TransactionConfiguration } from '../../transaction.interface';
import { ViewGridConfiguration } from '../grid-transaction-config.interface';
import { ViewGridHeaderConfiguration } from './grid-header-config.interface';
// import * as LanguageJson from '../../../../../../assets/i18n/en.json';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
export const ViewGridHeaderNameConfig: ViewGridHeaderConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    HeaderObject: any,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any ): any => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                // let langJson = (LanguageJson as any).default;
                // const productremapJson = (remapjson as any).default;
                // let langJson =  translateService.store.translations['en'];
                const  ProperityName = HeaderObject['$']['Name'];
                const EntityName = TransactionCollection.ViewTranscationConfig.ViewCustomHeaderOdataKey ?
                     TransactionCollection.ViewTranscationConfig.ViewCustomHeaderOdataKey : ViewHeaderEntity[0]['$']['Name'];
                const ParenetObj = translateService.instant(EntityName);
                let traslatedName = translateService.instant(EntityName + `.${ProperityName}`);
                if ( TransactionCollection.hasOwnProperty('SpModuleKey') ) {
                    traslatedName = translateService.instant(TransactionCollection.SpModuleKey + `.${ProperityName}`);
                }
                const commonName = translateService.instant(`Common.${ProperityName}`);
               
                // const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                // langJson = ProductDataRemap.DisplayNameRemapEngine(langJson, productremapJson);
                // const ColumnHeaderName = (langJson[ViewHeaderEntity[0]['$']['Name']] && 
                // langJson[ViewHeaderEntity[0]['$']['Name']][ProperityName]) ?
                // langJson[ViewHeaderEntity[0]['$']['Name']][ProperityName] : 
                // (langJson['Common'][ProperityName] ) ? langJson['Common'][ProperityName] : ProperityName;
                // return ColumnHeaderName.toLowerCase() === 'isactive' ? '' : ColumnHeaderName;

                const ColumnHeaderName = ( ParenetObj && traslatedName) ? traslatedName : ( commonName ) ? commonName : ProperityName;
                return ColumnHeaderName.toLowerCase() === 'isactive' ? '' : ColumnHeaderName;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };