import { TranslateService } from '@ngx-translate/core';
import { FormType } from 'src/app/eatecui/source/shared/models/app-form-field.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AgilysysDetailExpandPopupComponent } from 'src/app/eatecui/source/agilysys-popup/agilysys-detail-expand-popup/agilysys-detail-expand-popup.component';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { ExpandConfiguration } from './detail-expand-config.interface';
import { DetailExpandConfiguration, DetailExpandDataConfig, DetailGroupingConfig, GridCustomColExpand, MasterInventoryManagement } from '../master.interface';
import { GridInterface } from '../grid-interface';
import { CategoryIcons } from 'src/app/eatecui/source/shared/models/category-icons';
import { AnalysysDataModel } from 'src/app/eatecui/source/agilysys-popup/model/pop-up.interface';

export const MasterDetailExpand: ExpandConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    MasterCollection: MasterInventoryManagement,
    httpService: HttpService,
    matdialog: MatDialog,
    translateService?: TranslateService,
    TilesHeaderTrascationEntity?: any,
    responseData?: any
): any => {
    try {
        if (ViewHeaderEntity && ViewDetailsEntity && MasterCollection) {
            const LinkItemType = responseData.data.LinkItemType ? responseData.data.LinkItemType : responseData.data.LinkTypeId;
            const columnName = LinkItemType === 1 ? 'IngredientName' : LinkItemType === 2 ? 'ReceipeName' : 'ReceipeName';
            // const columnName = responseData.column.colDef.headerName;
            const getIndex = MasterCollection.DetailExpand.findIndex((x: DetailExpandConfiguration) => x.ColumnKey === columnName);
            const detailExpandConfig = MasterCollection.DetailExpand[getIndex];
            const detailData = responseData['data'];
            let Url = NavigationProperty(ViewHeaderEntity, MasterCollection, detailExpandConfig);
            const EntityName = ViewHeaderEntity[0]['$'].Name;
            let Id;
            const expandName = MasterCollection.DetailExpand[getIndex].ExpandName;
            if (MasterCollection.DetailExpand[getIndex].hasOwnProperty('CustomExpandName')
            && MasterCollection.DetailExpand[getIndex].CustomExpandName) {
                const CustomExpand = MasterCollection.DetailExpand[getIndex].CustomExpandName;
                Id = detailData.hasOwnProperty(CustomExpand) ? detailData[CustomExpand] : detailData[CustomExpand.toLowerCase()] ;
            } else {
                Id = expandName ? (detailData.hasOwnProperty(expandName) && detailData[expandName] ? detailData[expandName]['Id'] :
                (detailData.hasOwnProperty(expandName.toLowerCase()) && detailData[expandName.toLowerCase()]) ?
                detailData[expandName.toLowerCase()]['Id'] : detailData['Id'] ) : '';
            }
            // Url = `/inventory/api/${EntityName}?$expand=${Url}&$filter= Id eq ${Id}`;
            // if (EntityName === 'Ingredients') {
                Url = `/inventory/api/${EntityName}/${Id}`;
            // }
            if (Id) {
                httpService.GethttpMethod(Url).subscribe(response => {
                    let responseDataObject = response['value'][0];
                    if (EntityName === 'Ingredients' || EntityName === 'Recipes') {
                        responseDataObject = JSON.parse(response['value']);
                    }
                    detailExpandConfig.Name = responseDataObject['Name'];
                    detailExpandConfig.Observartion = responseDataObject['Name'];
                    const IconObject = CategoryIcons.categoryIconName(responseDataObject);
                    detailExpandConfig.CategoryIconName = IconObject.IconName;
                    detailExpandConfig.CategoryIconToolTip = IconObject.IconToolTip;
                    const expandedData = [];
                    expandedData.push(responseDataObject);
                    let responseDataArray = [];
                    let expandedDataInfo: any;
                    expandedData.forEach((element: any) => {
                        const dataObject = _.mapKeys(element, function (v, k) { return k.toLowerCase(); });
                        responseDataArray.push(dataObject);
                    });
                    if (EntityName === 'Recipes') {
                        responseDataArray = recipeDataFormation(responseDataArray);
                    } else if (EntityName === 'Ingredients') {
                        responseDataArray = ingredientDataFormation(responseDataArray);
                    }
                    const units = {
                        'currentcost': 0,
                        'purchasecurrentcost': 1,
                        'transactioncurrentcost': 2,
                        'recipecurrentcost': 3,
                        'conversion5': 0,
                        'purchaseconversion': 1,
                        'transactionconversion': 2,
                        'recipeconversion': 3
                    }
                    expandedDataInfo = GridInterface.GridData(ViewHeaderEntity, MasterCollection,
                            httpService, null, responseDataArray);
                    detailExpandConfig.DetailGrouping.forEach((detailGrouping: DetailGroupingConfig) => {
                        detailGrouping.DetailExpandData.forEach((expandData: DetailExpandDataConfig) => {
                            const noData = expandedDataInfo[0].inventorytype === 2 &&
                                 EntityName === 'Ingredients' ? hideValues(expandData.OdataKey) : true;
                            // if (noData) {
                                expandData['Disable'] = !noData;
                                expandData.OdataKey = expandData.OdataKey.toLowerCase();
                                expandedDataInfo[0][expandData.OdataKey.toLowerCase()] = (expandData.OdataKey === 'conversion5' || expandData.OdataKey === 'purchaseconversion' || expandData.OdataKey === 'transactionconversion' ||
                                expandData.OdataKey === 'recipeconversion') ? expandedDataInfo[0]['units'][units[expandData.OdataKey]]?.Conversion: expandedDataInfo[0][expandData.OdataKey.toLowerCase()];
                                expandData.Value = (expandedDataInfo[0][expandData.OdataKey.toLowerCase()] === '' ||
                                expandedDataInfo[0][expandData.OdataKey.toLowerCase()] === null ||
                                expandedDataInfo[0][expandData.OdataKey.toLowerCase()] === undefined)
                                        ? '' :  valueReassign(expandedDataInfo, expandData);
                                expandData.Value = (expandData.OdataKey === 'currentcost' || expandData.OdataKey === 'purchasecurrentcost' || expandData.OdataKey === 'transactioncurrentcost' ||
                                    expandData.OdataKey === 'recipecurrentcost') ? expandedDataInfo[0]['units'][units[expandData.OdataKey]]?.UnitCost: expandData.Value;
                                if (expandData.FieldType === 'boolean') {
                                    expandData.Value = expandData.Value === true ? 'Yes' : (expandData.Value === false ? 'No' : '-');
                                }
                                /** Condition for Not Formatting the Value which is binded with Unit Code */
                                if (expandData.OdataKey !== 'transactionconversion' &&
                                expandData.OdataKey !== 'purchaseconversion' &&
                                expandData.OdataKey !== 'conversion5' ) { 
                                    expandData.Value = Formater(expandData.OdataKey.toLowerCase(), expandData.Value, null, null);
                                }
                                if (expandData.Value === '' || expandData.Value === null || expandData.Value === undefined) {
                                    expandData.Value = '-';
                                }
                            // }
                        });
                    });
                    if (LinkItemType !== 2) {
                        matdialog.open(AgilysysDetailExpandPopupComponent, {
                            width: '30vw',
                            height: '100%',
                            data: MasterCollection.DetailExpand[getIndex],
                            position: {
                                right: '0'
                            },
                            panelClass: 'detail-expand-data',
                            hasBackdrop: true,
                            // disableClose: true,
                            backdropClass: 'detail-expand-backdrop-class',
                            // disableClose: true
                        });
                    } else {
                        const analysysDataModel = {} as AnalysysDataModel;
                        analysysDataModel.selectedAnalysys = (MasterCollection.Key === 'Products' || MasterCollection.Key === 'Recipes') ?
                        'product-analysis' : '';
                        analysysDataModel.selectedRowId = responseData.data.ItemId;
                        analysysDataModel.selectedAnalysisType = LinkItemType;
                        // matdialog.open(AgilsysAnalysysDetailComponent, {
                        //     width: '87%',
                        //     height: '80vh',
                        //     data: analysysDataModel,
                        //     maxWidth: '100vw',
                        //     panelClass: 'product-popup',
                        //     hasBackdrop: true,
                        //     backdropClass: 'detail-expand-backdrop-class',
                        //     position: {
                        //         right: '0'
                        //     }
                        // });
                    }                    
                });
            }
            return MasterCollection;
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function hideValues (key) {
    const HideKeys = ['TransactionUnit', 'TransactionConversion', 'RecipeUnit', 'RecipeConversion', 'TransactionCurrentCost', 'RecipeCurrentCost'];
    const returnValue = HideKeys.findIndex(xy => xy.toLowerCase() === key.toLowerCase()) === -1 ? true : false;
    return returnValue;
}

function recipeDataFormation (dataCollection): any {
    try {
        dataCollection.forEach(element => {
            element.batchqty = Formater('Qty' , element.recipeunits[0]?.Quantity?.toString(), null, null);
            element.portionqty = Formater('Qty' , element.recipeunits[1]?.Quantity?.toString(), null, null);
            element.batchunit = element.recipeunits[0]?.UnitName + `(${element.recipeunits[0]?.UnitCode})`;
            element.portionunit = element.recipeunits[1]?.UnitName + `(${element.recipeunits[1]?.UnitCode})`;
            element.batchprice = Formater('Price' , element.recipeunits[0]?.Price?.toString(), null, null);
            element.portionprice = Formater('Price' , element.recipeunits[1]?.Price?.toString(), null, null);
            element.batchcost = Formater('Price' , element.recipeunits[0]?.CostPerUnit?.toString(), null, null);
            element.portioncost = Formater('Price' , element.recipeunits[1]?.CostPerUnit?.toString(), null, null);
            element.batchpercentage = Formater('Price' , element.recipeunits[0]?.CPUPercentage?.toString(), null, null);
            element.portionpercentage = Formater('Price' , element.recipeunits[1]?.CPUPercentage?.toString(), null, null);
        });
        return dataCollection;
    } catch (error) {
        console.error('Error occurred in recipeDataFormation', error);
    }
}

function ingredientDataFormation (dataCollection): any {
    try {
        const fieldsCollection = [
            {
                Id: 1,
                CheckProperty: 'ingbaseunit',
                AssignProperty: 'baseunit'
            },
            {
                Id: 2,
                CheckProperty: 'ingpurchaseunit',
                AssignProperty: 'purchaseunit'
            },
            {
                Id: 3,
                CheckProperty: 'ingtransactionunit',
                AssignProperty: 'transactionunit'
            },
            {
                Id: 4,
                CheckProperty: 'ingrecipeunit',
                AssignProperty: 'recipeunit'
            }
        ];
        dataCollection.forEach(element => {
            fieldsCollection.forEach(field => {
                if (element.hasOwnProperty('units')) {
                    const index = element['units'].findIndex(unit => unit.UnitTypeId === field.Id);
                    element[field.AssignProperty] = element['units'][index] ? element['units'][index] : null;
                    element[field.CheckProperty] = element['units'][index] ? element['units'][index] : null;
                }
            });
        });
        return dataCollection;
    } catch (error) {
        console.error('Error occurred in ingredientDataFormation');
    }
}

function valueReassign(expandData: Array<any>, expand: DetailExpandDataConfig ): any {
   try {
    let expandValue = Formater(
        expand.OdataKey, expandData[0][expand.OdataKey.toLowerCase()], null, null);
    if (expand.OdataKey.toLowerCase() === 'purchaseconversion' || expand.OdataKey.toLowerCase() === 'transactionconversion' || 
         expand.OdataKey.toLowerCase() === 'recipeconversion' ||  expand.OdataKey.toLowerCase() === 'conversion5'
        ) {
         if (expand.hasOwnProperty('ExpandedKey')) {
            const DataCode = expandData[0][expand.OdataKey.toLowerCase()];
            if (expand.OdataKey.toLowerCase() === 'recipeconversion') {
                expandValue = (Formater('Conversion', DataCode, null, null)) + ' /' + (expandData[0][expand.ExpandedKey.toLowerCase()] ?
                expandData[0][expand.ExpandedKey.toLowerCase()]['UnitCode'] ? expandData[0][expand.ExpandedKey.toLowerCase()]['UnitCode']: '-' : '');
            } else {
                expandValue = (Formater('Conversion', DataCode, null, null)) + ' ' + (expandData[0][expand.ExpandedKey.toLowerCase()] ?
                expandData[0][expand.ExpandedKey.toLowerCase()]['UnitCode'] ? expandData[0][expand.ExpandedKey.toLowerCase()]['UnitCode']: '-' : '');
            }
            if (DataCode > 1 && expand.OdataKey.toLowerCase() !== 'recipeconversion') {
                expandValue += 's';
            }
         } else {
            expandValue = `${expandData[0][expand.OdataKey.toLowerCase()]}`;
         } 
    }
    return expandValue;
   } catch (error) {
      console.error(error);
   }
}

function NavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
    detailExpandConfig: DetailExpandConfiguration) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                    const navigationName = element['$'].Name;
                    // Added condition for inventory landing should not have inventory pictures 
                    if (navigationName !== 'InventoryPictures') {
                        const referentialRelationId = element['ReferentialConstraint'] &&
                            element['ReferentialConstraint'][0]['$'].Property
                            ? element['ReferentialConstraint'][0]['$'].Property : '';
                        if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                            const properityIndex = ViewModelEntity[0]['Property']
                                .findIndex(x => x['$'].Name === referentialRelationId);
                            if (properityIndex > -1) {
                                let expandQuery = '($select=id,name)';
                                if (detailExpandConfig && detailExpandConfig.GridCustomExpand
                                    && detailExpandConfig.GridCustomExpand.length > 0) {
                                    detailExpandConfig.GridCustomExpand.forEach(
                                        (GridCustomExpandKey: GridCustomColExpand) => {
                                            if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand
                                                && GridCustomExpandKey.IsCustomExpand
                                                && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                                const expandCode = GridCustomExpandKey.SelectQuery;
                                                expandQuery = `($select=id,${expandCode})`;
                                            }
                                        });
                                }
                                const properityString = `${navigationName}` + `${expandQuery}`;
                                navigationPrperity.push(properityString);
                            } else {

                                const properityString = `${navigationName}`;
                                navigationPrperity.push(properityString);

                            }
                        }
                    }
                });
                return navigationPrperity.join();
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}
