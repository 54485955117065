import * as _ from 'lodash';
import { Conversion } from 'src/app/eatecui/source/shared/models/algorithm.interface';
import { ConversionAlgorithm } from '.';
import { ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';

export const MiscTotalAlgorithm: Conversion = (value: any, GridData: Array<any>, AllocateCharges: any, transactionType: string,
   MiscChargesData: any, selectedTransactionFrom: string): any => {
  try {
      try {
        let rguestTenantId = false;
        const checkTenant = sessionStorage.getItem('RGuestTenantId');
        if (checkTenant && JSON.parse(checkTenant)) {
          rguestTenantId = true;
        }
        const decimalValue = sessionStorage.getItem('appsetting') ? JSON.parse(sessionStorage.getItem('appsetting')).DecimalValue :
        ConversionValue.ConversionThree;
        let count = 0;
          count = ConversionAlgorithm.estimationTotal(value, GridData, null, transactionType, null, selectedTransactionFrom); // To calculate Estimation Total
          let misctotal = 0;
          if (MiscChargesData && MiscChargesData.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            MiscChargesData.forEach((el: any) => {
              if ((selectedTransactionFrom === 'processpo' || selectedTransactionFrom === 'EditPO') && !rguestTenantId) {
                const miscInFC = el.Amount && GridData[0].ExchangeRate ? el.Amount * GridData[0].ExchangeRate : 0;
                misctotal = misctotal + parseFloat(miscInFC ? miscInFC.toString() : '0');
              } else {
                misctotal = misctotal + parseFloat(el.Amount ? el.Amount : 0);
              }
            });
          } else {
            misctotal = 0;
          }
          count = count + misctotal;
          return count;
        } catch (Error) {
        console.error('Error occurred in Miscellaneous Charges Calculation ', Error);
      }
  } catch (error) {
    console.log('Error occured Conversion Algorithm ', error);
  }
};
