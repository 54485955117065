import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { TableSearchHeader } from 'src/app/common/Models/ag-models';
import { MatDialog } from '@angular/material/dialog';
import { CreateTierLevelComponent } from './create-tier-level/create-tier-level.component';

@Component({
  selector: 'app-retail-tier-level',
  templateUrl: './retail-tier-level.component.html',
  styleUrls: ['./retail-tier-level.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailTierLevelComponent implements OnInit {

  tableBodyData: any;
  tableoptions: any[];
  captions: any;
  IsViewOnly: boolean;
  searchHeaderOption: TableSearchHeader;
  showInactiveTog = false;
  userOperationType: myGlobals.OperationType;
  searchText = '';

  constructor(private Form: UntypedFormBuilder, public localization: RetailLocalization, private utils: RetailUtilities,
    private retailService: RetailSetupService,private dialog: MatDialog) {
      this.captions = this.localization.captions
  }

  ngOnInit() {
    this.userOperationType = myGlobals.OperationType.Create;
      this.setSearchHeaderOption(this.captions.btn_CreditTierLevel, this.captions.lbl_searchTierCode);
      this.tableBodyData = this.getTableData();
      this.BindToGrid();
  }

  setSearchHeaderOption(createBtnLabel, searchPlaceHolder) {
    this.searchHeaderOption = {
      createBtnLabel,
      searchPlaceHolder,
      toggleLabel: this.captions.tog_lbl_showInactive,
      showInactive: true,
      toggleDisabled: false,
      createBtnDisabled: this.IsViewOnly,
      hasHeaderAction: false,
      hideActionBtn: false,
      automationId: 'retailTierLevel'
    };
  }

  private BindToGrid() {
    this.tableoptions = [
      {
        TableHdrData: [{ "title": this.captions.tierCode, "jsonkey": "tierCode", "sortable": true },
        { "title": this.captions.tierName, "jsonkey": "tierName", "sortable": true },
        { "title": this.captions.description, "jsonkey": "description", "sortable": false, "searchable": false },
        { "title": this.captions.discountType, "jsonkey": "discountType", "sortable": true, "searchable": false }],
        TablebodyData: this.tableBodyData,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: '',
        EnableActions: true,
        SelectRows: true,
        IsCommission: true,
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.tierLevel,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.tierLevel,
        disableDelete: false,
        pageTitle: 'tierLevel',
        ServiceId: 'tierLevel',
        IsViewOnly: this.IsViewOnly,
        TableDraggable: false,
        automationId: 'retailTierLevel'
      }
    ];
  }

  getTableData(){
    return [
      {
        id: 0,
        tierCode: "111",
        tierName : "Silver",
        description: "Summer Sale",
        discountType: "Summer Sale"
      },
      {
        id: 1,
        tierCode: "112",
        tierName : "Gold",
        description: "Spring Sale",
        discountType: "Spring Sale"
      }
    ]
  }

  searchChange(e) {
    this.searchText = e;
  }

  showInactiveToggler(e) {
    this.showInactiveTog = e.checked;
    // this.BindToGrid();
  }
  async createEvent(e) {
    this.openDialog();
  }

  openDialog(){
    this.dialog.open(CreateTierLevelComponent, {  
      width: '60%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.hdr_CreateTierLevel,
        data: Number(this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1] ? this.tableoptions[0].TablebodyData[this.tableoptions[0].TablebodyData.length - 1].listOrder : 0) + 1,
        mode: myGlobals.GridOperationType.Create,
      }
    }).afterClosed().subscribe(result => {
    });

  }

  EditRecords(discountData) {
    this.dialog.open(CreateTierLevelComponent, {
      width: '60%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.hdr_EditTierLevel,
        data: [discountData[0]],
        mode: myGlobals.GridOperationType.Edit,
      }
    }).afterClosed().subscribe(result => {
    });
  }

  DeleteRecords(event: any) {
    this.utils.ToggleLoader(true,this.captions.lbl_processing);
  }

}
