import { Injectable } from '@angular/core';
import { CaptureCardRequest, GetDeviceResponse, CapturedCardResponse, GetDeviceInfoResponse } from './rGuestPayRequestModel';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/common/app-service';
import { RguestPayCommunication } from 'src/app/common/communication/common/rGuestPay-communication';
import { timeout } from 'rxjs/operators'
import { RetailFeatureFlagInformationService } from '../../service/retail.feature.flag.information.service';
import { rGuesPayRoutes } from 'src/app/common/communication/common-route';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';

@Injectable()
export class rGuestPayBaseService extends RguestPayCommunication {

    constructor(
        private featureInfo: RetailFeatureFlagInformationService
        , appService: AppService
        , httpclient: HttpClient
        , localization: Localization
        , utilities: CommonUtilities
        , PropertyInfo: CommonPropertyInformation
    ) {
        super(featureInfo.V1GiftCardRGuestPayURL, httpclient, localization, utilities, PropertyInfo, appService);
    }

    private get CheckrGuestPayURLHasValue() { return (this.featureInfo.V1GiftCardRGuestPayURL != "") ? true : false; }

    async GetDeviceList(): Promise<GetDeviceResponse> {
        try {
            return await super.getPromise({
                route: rGuesPayRoutes.GetDeviceList
            });
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    GetDeviceInfo(selectedDeviceGuid): Promise<GetDeviceInfoResponse> {        
        return super.getPromise({
            route: rGuesPayRoutes.GetDeviceInfo,
            uriParams: { deviceGuid: selectedDeviceGuid }
        })
    }

    async LightUpTheDevice(selectedDeviceGuid: string) {
        try {            
            const reqBody: CaptureCardRequest = {
                amount: 0,
                currencyCulture: {
                    currencyCultureName: "en-US",
                    currencySymbol: "$"
                }
            }

            //Lights Up the device
            return super.post({
                route: rGuesPayRoutes.CardCapture,
                uriParams: { deviceGuid: selectedDeviceGuid },
                body: reqBody
            }).pipe(timeout(RGUEST_DEVICE_SWIPE_TIMEOUT)).toPromise(); //Timeouts the req after this timeout

        } catch (error) {
            console.log(`Error occurred while CaptureCardWithIngenicoDevice ex: ${error}`);
        }
    }

    GetSwippedCardInfo(selectedDeviceGuid) {        
        return super.getPromise<CapturedCardResponse>({
            route: rGuesPayRoutes.GetCapturedCardInfo,
            uriParams: { deviceGuid: selectedDeviceGuid }
        }, false);
    }

    SetDeviceToStandByMode(selectedDeviceGuid) {        
        return super.postPromise({
            route: rGuesPayRoutes.StandBy,
            uriParams: { deviceGuid: selectedDeviceGuid }
        })
    }

}

export const RGUEST_DEVICE_SWIPE_TIMEOUT = 90 * 1000;

export const REQUEST_TIMEOUT: string = "Request timed out";

export const RequestTimedOutException = "Timeout has occurred"; //CancellationTimeout Implementation

export const DeviceStatus = {
    Unknow: 'unknown',
    Available: 'available',
    NotAvailable: 'notAvailable',
    Updating: 'updating',
    Failed: 'failed'
}