import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';

/**
 * Class Class Client NoShow Report
 * @export
 * @class ClassClientNoShow
 * @extends {BaseReport}
 */
export class ClassClientNoShow extends BaseReport {
	constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public code: string, public patcheableValue) {
		super(reportFormGrp);
	}

	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: this.code,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		};
	}

	getReportUIConfig(): ReportUIConfig {
		return {
			startDatePicker: true,
			endDatePicker: true,
			inActiveToggle: false,
			inActiveToggleName: '',
			pageBreakToggle: false,
			layout: 'PORTRAIT'
		};
	}
	protected formReportParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [
			{ pStartDate: toApi(this.startDate) },
			{ pEndDate: toApi(this.endDate) },
			{ pPropertyName: this.propertyName },
			{ pDate: this.printedDate }
		];
	}
	protected formURIParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [ { startDate: toApi(this.startDate) }, { endDate: toApi(this.endDate) } ];
	}
	protected formFilters(): any {
		let toApi = this.localization.convertDateObjToAPIdate;
            return {
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate)
            };
	}
}
