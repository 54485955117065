import { DisplayNameRemapConfig, FormDisplayHideRemapConfig } from './data-remap-config.interface';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';

export const FromDisplayHideRemap: FormDisplayHideRemapConfig = 
( remapJson: Array<any>, FieldKey: string, DisplayName: string, FieldGrouping: FormBuilderGroupKey  ): boolean => {
    try {
        let displayNameHide = ( FieldGrouping.hasOwnProperty('Hide') ) ? FieldGrouping.Hide : false;
        remapJson?.forEach((remap: any) => {
        //  const remapKey = Object.keys(remap)[0].toString();
        const remapKey = remap.Name.toString().toString();
         if ( FieldKey.toLowerCase() === remapKey.toLowerCase()) {
            const getMapAray = remap.UIFieldLookup;
            if ( getMapAray && getMapAray.length > 0 ) {
                getMapAray.forEach((element: any) => {
                    if ( element.Property_Key.toString().toLowerCase() === DisplayName.toString().toLowerCase()) {
                        displayNameHide = ( element.Visible ) ? false : true;
                    }  
                 });
            }
         }
        });
        return displayNameHide;
    } catch (error) {
        console.error(error);
    }
};