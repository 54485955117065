import { Injectable } from '@angular/core';
import { CommonCommunication } from '../../communication/services/common-communication-services';
import { CommonControllersRoutes } from '../../communication/common-route';
import { EventActor } from '../../Models/notification.model';
import { EventNotificationGroup, Events, EventNotification } from '../../templates/notification-configuration/notification-configuration.model';
import { Localization } from 'src/app/common/localization/localization';

@Injectable()
export class EventDataServices {
    eventCaptions: any;
    constructor(private _commonCommunication: CommonCommunication, public localization: Localization) {
        this.eventCaptions = this.localization.captions.settings.events;
    }

    public getAllEventActors(productId: number): Promise<EventActor[]> {
        return this._commonCommunication.getPromise<EventActor[]>(
            {
                route: CommonControllersRoutes.GetAllEventActors,
                uriParams: { productId: productId }
            }
        );
    }

    async getAllEventsByProductId(productId: number): Promise<Events[]> {
        let result: Events[] = await this._commonCommunication.getPromise({
            route: CommonControllersRoutes.GetAllEventsByProductId,
            uriParams: { productId: productId }
        });
        result.forEach(element => {
            let eventname = EventNotification[element.id];
            element.eventName = this.eventCaptions[eventname];
            if (!element.eventName && element.id == EventNotification.RetailProductRetailReceipts) {
                element.eventName = this.eventCaptions.RetailReceipts;
            }
        });
        return result;
    }

    public GetEventNotificationGroupByProduct(productId: number): Promise<EventNotificationGroup[]> {
        return this._commonCommunication.getPromise({
            route: CommonControllersRoutes.GetEventNotificationGroupByProduct,
            uriParams: { productId: productId }
        });
    }

    public GetEventNotificationGroupByEventId(eventId: number): Promise<EventNotificationGroup[]> {
        return this._commonCommunication.getPromise({
            route: CommonControllersRoutes.GetEventNotificationGroupByEventId,
            uriParams: { eventId: eventId }
        });
    }
}