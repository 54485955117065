import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { FormIcons, OnEventArgs } from '../../form-type.entity';
import { CustomErrorMesssage } from '../../form-validation';
// text,email,tel,textarea,password,
@Component({
    selector: 'lib-textarea',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TextAreaComponent implements OnInit, AfterViewInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    formAddBuilderIcons: FormIcons;
    formRemoveBuilderIcons: FormIcons;
    formFieldName: any;
    typeField: string;
    fieldType: any;
    formFieldlabel: any;
    formArrayName: any;
    errorMessage: string;
    formFieldHeader: string;
    formFieldToolTip: string;
    get isValid(): any { return this.form.controls[this.formFieldName].valid; }
    get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }
    errorLength: boolean;
    constructor(public agyDynamicFormServices: AgyDynamicFormServices) {
      this.typeField = 'textarea';
    }

    iconCallback(e: any): void {
        if (e.iconType.type === 'remove') {
            this.agyDynamicFormServices.RemoveRow(e.formArray, e.formObject, e.objectIndex);
        } else {
            this.agyDynamicFormServices.addnewRow(e.formArray, e.formObject);
        }
    }

    fieldArrayCallback(e: any): void {
        this.field.fieldType.MultipleData.data.push(e);
    }
    eventCallback(index ?: any, argus?: OnEventArgs): void {
        this.agyDynamicFormServices.formEventCallback(this.field, index, this.form );
    }
    suffixIconClick(): void {
        this.agyDynamicFormServices.formEventCallback(this.field, 'edit', this.form);
    }

    ngAfterViewInit(): void {
        this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
            this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field );
        });
        if (this.form.controls[this.formFieldName] && this.form.controls[this.formFieldName].errors){
            const errorsArray =  this.form.controls[this.formFieldName].errors.required === true ? true : false;
            this.errorLength = errorsArray;
        }
    }
    ngOnInit(): void {
        this.field.maxCount = !this.field.maxCount ? 0 : this.field.maxCount;
        this.formAddBuilderIcons = {
            type: 'add',
            label: 'add',
            maxcount: this.field.maxcount
        };
        this.formRemoveBuilderIcons = {
            type: 'remove',
            label: 'remove',
            maxcount: this.field.maxcount
        };
        this.fieldType = Object.keys(this.field.fieldType).toString();
        const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formFieldHeader = formField.fieldHeader;
        this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
        ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
    }
}
