import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { ConversionType, ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';

@Pipe({
  name: 'iconType'
})
export class IconTypePipe implements PipeTransform {
  transform(value: any, divisionValue?: null): any {
    let returnValue = '';
    switch (value) { 
      case 1:
        returnValue = 'icon-Retail';
        if ( divisionValue && divisionValue === 1) {
          returnValue = 'icon-Food-and-beverage-eatec';
        }        
        break;
      case 2:
        returnValue = 'icon-Recipe-eatec';
        break;      
      case 5:
        returnValue = 'icon-Product-eatec';
        break;
      case 'Dog':
        returnValue = 'icon-Dog-eatec';
        break;
      case 'Star':
        returnValue = 'icon-Star-eatec';
        break;
      case 'Plowhorse':
        returnValue = 'icon-Plowhorse-eatec';
        break;
      case 'Puzzle':
        returnValue = 'icon-Puzzle-eatec';
        break;
      default:
        returnValue = 'icon-Recipe-eatec';
        break;
    }
    return returnValue;
  }
}
