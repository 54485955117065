import { Injectable } from "@angular/core";
import { CartCommunication } from "./cart.service";
import { bookingReponse, CartDetail, CartItinerary, CartItineraryType, CartTempHoldReservationResponse, ItineraryCreateRequest, SearchParameters, TransferCartResult } from "./vcart.modal";
import { CommonApiRoutes, CommonGuestProfileServiceRoutes, V1CartServiceApiRoutes } from "src/app/common/common-route";
import { TenantManagementCommunication } from "src/app/common/communication/services/tenantmanagement-communication-service";
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { UserModel } from "src/app/common/Models/common.models";
import { CommonGatewayCommunication } from "src/app/common/communication/services/commongateway-communication.service";
import { Subject } from "rxjs";
import { Guid } from "guid-typescript";
import { API as guestAPI, UI as guestUI } from 'src/app/common/components/combine-guest-records/guest-model';
import { ReservationCommunication } from "src/app/common/communication/services/reservationcommunication.service";

@Injectable(
  {
    providedIn: 'root'
  })
export class VCartBusiness {

  constructor(private cartCommunication: CartCommunication,private reservationCommunication : ReservationCommunication,
    private tenantCommunication: TenantManagementCommunication,
    private _utils: CommonUtilities, private commonGatewayCommunication: CommonGatewayCommunication) {

  }

  async getUsers(): Promise<UserModel[]> {
    let tenantId: number = Number(this._utils.GetPropertyInfo('TenantId'));
    let inActive = false;
    let users: UserModel[] = await this.tenantCommunication.getPromise({
      route: CommonApiRoutes.GetAllUsers,
      uriParams: { tenantId, inActive }
    });
    return users;
  }

  async createEmptyItinerary(itineraryCreateRequest: ItineraryCreateRequest): Promise<CartItinerary> {
    return await this.cartCommunication.postPromise<CartItinerary>({
      route: V1CartServiceApiRoutes.CreateItinerary,
      body: itineraryCreateRequest
    }, false);
  }

  async updateItinerary(cartItinerary: CartItinerary): Promise<boolean> {
    return await this.cartCommunication.putPromise<boolean>({
      route: V1CartServiceApiRoutes.UpdateItinerary,
      body: cartItinerary ? cartItinerary : {}
    }, false);
  }

  async updateCartDetail(cartDetail: CartDetail): Promise<boolean> {
    return await this.cartCommunication.putPromise<boolean>({
      route: V1CartServiceApiRoutes.UpdateCartDetail,
      body: cartDetail
    }, false);
  }

  async AddCartDetail(cartDetail: CartDetail): Promise<CartItinerary> {
    return await this.cartCommunication.postPromise<CartItinerary>({
      route: V1CartServiceApiRoutes.AddToCartItinerary,
      body: cartDetail
    }, false);
  }

  async GetItineraries(userId: number): Promise<CartItinerary[]> {
    const result = await this.cartCommunication.getPromise<CartItinerary[]>({
      route: V1CartServiceApiRoutes.GetItinerariesByUserId,
      uriParams: { userId: userId }
    }, false);
    return result;
  }

  async completeBooking(cartItineraryId: number): Promise<bookingReponse[]> {
    return await this.cartCommunication.putPromise<any>({
      route: V1CartServiceApiRoutes.CompleteBooking,
      uriParams: { cartItineraryId },
      body: {}
    }, false);
  }

  async getGuestInformationByPlatformGuid(platformGuestId: string): Promise<guestUI.Guest> {
    var platformGuestGuid = Guid.parse(platformGuestId);
    const guestData = await this.reservationCommunication.getPromise<guestAPI.Guest>(
      {
        route: CommonApiRoutes.GetGuestInformationByPlatformGuestId,
        uriParams: { platformGuestGuid }
      }, true);
    var guest: guestUI.Guest = this.uiMapper_GuestInformation(guestData);
    return guest;
  }

  private uiMapper_GuestInformation(GuestAPIModel: guestAPI.Guest): guestUI.Guest {
    const guestItem: guestUI.Guest = {} as guestUI.Guest;
    Object.assign(guestItem, GuestAPIModel);

    return guestItem;
  }

  async transferCart(userId: number, fromUserId: number, fromUserName: string, cartItineraryId: number): Promise<TransferCartResult> {
    let result = await this.cartCommunication.putPromise<TransferCartResult>({
      route: V1CartServiceApiRoutes.TransferCartByUserId,
      uriParams: { userId, fromUserId, fromUserName },
      body: cartItineraryId
    }, false);
    return result;
  }

  async deleteCartDetailById(id: number): Promise<any> {
    return await this.cartCommunication.deletePromise<any>({
      route: V1CartServiceApiRoutes.DeleteCartDetailById,
      uriParams: { id }
    }, false);
  }

  async RemoveItinerary(CartItineraryId: number, releaseItinerary: boolean): Promise<boolean> {
    return await this.cartCommunication.putPromise<boolean>({
      route: V1CartServiceApiRoutes.RemoveCartItineraryById,
      uriParams: { CartItineraryId, releaseItinerary }
    }, false);
  }

  async HoldCartItinerary(holdMinutes: number, cartItineraryId: number): Promise<CartTempHoldReservationResponse[]> {
    return await this.cartCommunication.putPromise<any>({
      route: V1CartServiceApiRoutes.HoldItineraryByById,
      uriParams: { holdMinutes, cartItineraryId }
    }, false);
  }

  async AuthorizeItinerary(cartItineraryId: number, emailId: string): Promise<any> {
    return await this.cartCommunication.putPromise<any>({
      route: V1CartServiceApiRoutes.SendPaymentForAuthorize,
      uriParams: { cartItineraryId, emailId }
    }, false);
  }

  async getCartItineraryById(cartItineraryId: number): Promise<CartItinerary> {
    return await this.cartCommunication.getPromise<any>({
      route: V1CartServiceApiRoutes.GetCartItinerary,
      uriParams: { cartItineraryId }
    });
  }

  async MoveCartDetail(cartDetailId: number, sourceCartItineraryId: number, destinationCartItineraryId: number): Promise<boolean> {
    return await this.cartCommunication.putPromise<any>({
      route: V1CartServiceApiRoutes.MoveCartDetail,
      uriParams: { cartDetailId, sourceCartItineraryId, destinationCartItineraryId }
    }, false);
  }

  async UpdateCartComments(cartItineraryId: number, comments: string) {
    return await this.cartCommunication.putPromise<any>({
      route: V1CartServiceApiRoutes.UpdateCartComments,
      uriParams: { cartItineraryId },
      body: JSON.stringify(comments)
    }, false);
  }
  async SetActiveCardOnFile(cartItineraryId: number, authId: number) {
    return await this.cartCommunication.putPromise<any>({
      route: V1CartServiceApiRoutes.SetActiveCardOnFile,
      uriParams: { cartItineraryId, authId }
    }, false);
  }

  async SearchGuest(nameFromGuest: string, filters: string[], notifier: Subject<void>, isPlatformGuestSearch: boolean): Promise<any> {
    let tenantId = this._utils.GetPropertyInfo('PlatformTenantId');
    let searchParams = { SearchKey: nameFromGuest, SearchCriteria: filters, IsPlatformGuestSearch: isPlatformGuestSearch }
    if (tenantId) {
      let result = await this.commonGatewayCommunication.putPromiseWithoutBaseResponse<any>({
        route: CommonGuestProfileServiceRoutes.SearchGuestBySearchKeywithFilter,
        uriParams: { tenantId },
        body: JSON.stringify(searchParams)
      }, false, notifier);
      return result;
    }
    return [];
  }

  async GetGuestByGuestId(id: String): Promise<any> {
    let tenantId = this._utils.GetPropertyInfo('PlatformTenantId');
    if (tenantId) {
      let result = await this.commonGatewayCommunication.getPromiseWithoutBaseResponse<any>({
        route: CommonGuestProfileServiceRoutes.GetGuestByGuestId,
        uriParams: { tenantId, id },
      }, false);
      return result;
    }
    return null;
  }
  async AddCardInfo( cartItineraryId: number,paymentTransactionId: number, maskedCardNumber: string, expDate: string, cardType: string): Promise<any> {
    return await this.cartCommunication.postPromise<boolean>({
      route: V1CartServiceApiRoutes.AddCardToCart,
      uriParams: {cartItineraryId},
      body: {paymentTransactionId, maskedCardNumber, expDate,cardType}
    }, true);
  }
  async GetAllItineraries(): Promise<CartItinerary[]> {
    const result = await this.cartCommunication.getPromise<CartItinerary[]>({
      route: V1CartServiceApiRoutes.GetAllItineraries
    }, true);
    return result;
  }
}