import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { Outlet } from '../../retail.modals';
import { HttpServiceCall, HttpMethod } from '../../shared/service/http-call.service';
import { BaseResponse, popupConfig } from '../../shared/business/shared.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailSetupService } from '../../retail-setup/retail-setup.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
@Component({
  selector: 'app-retail-category-group',
  templateUrl: './retail-category-group.component.html',
  styleUrls: ['./retail-category-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailCategoryGroupComponent implements OnInit {
  FormGrp: UntypedFormGroup; 
  Outlets: Outlet[] = [];
  selectedPackageComponent: any[] = [];
  tableoptions: any[];
  captions: any;
  currIndex: any;
  IsViewOnly: boolean;
  allCategoryGroups: any;
  maxListOrder: number;
  @Input() popupConfigs: popupConfig;
  @Input() DragEvent;
  onEditDisableFunction:boolean = true;
  constructor(private Form: UntypedFormBuilder, private http: HttpServiceCall, public localization: RetailLocalization, private utils: RetailUtilities,
    private retailService: RetailSetupService) {
  }

  ngOnInit() {
    this.FormGrp = this.Form.group({
      TerminalID: []
    });
    this.IsViewOnly = this.retailService.retailSetupBreakPoints.find(rb => rb.breakPointNumber == myGlobals.RetailBreakPoint.CategoryGroup).view;
    this.captions = this.localization.captions.retailsetup;
    this.getAllCategoryGroups();
  }


 checkForMinValidation(categoryGroup): boolean {
    let valid: boolean = true;
    if ((categoryGroup.value.controls.setupCodeName.value.trim().length < 2)) {
        let errMsg:string = this.localization.getError(10712);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
        valid = false;
    }
    return valid;
}

  dragDrop(e: any) {
    const dragIndex = e[0];
    const dropIndex = e[1];
    const settingBelongsTo = e[2];
    let uriParam: any = dragIndex.toString() + "/" + dropIndex.toString();
    this.http.CallApiWithCallback({
      host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback,
      callDesc: "CategoryGroupDragDrop",
      uriParams: uriParam,
      method: HttpMethod.Patch,
      showError: true,
      extraParams: settingBelongsTo
    });
    console.log('dropped', e);
  }


  Done(event: any) {
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == event.id);
    const categoryGroupObj = {
      IsActive: this.tableoptions[0].TablebodyData[this.currIndex].isActive,
      Name: this.tableoptions[0].TablebodyData[this.currIndex].categoryGroup,
      ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
    };
    this.http.CallApiWithCallback({
      host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateCategoryGroup",
      uriParams: {id : this.tableoptions[0].TablebodyData[this.currIndex].id},
      method: HttpMethod.Put,
      body: categoryGroupObj,
      showError: true,
      extraParams: []
    });
  }

  addCategoryGroup(data: any) {

    let listOrderArray = this.tableoptions && this.tableoptions[0].TablebodyData && this.tableoptions[0].TablebodyData.map(e=> e.listOrder);
    let maxListOrder = listOrderArray && listOrderArray.length > 0 ? Math.max(...listOrderArray) : 0;

    if (data.type.toLowerCase() == this.localization.captions.setting.Add.toLowerCase() && this.checkForMinValidation(data)) {
      const categoryGroupObj = {
        IsActive: true,
        Name: data.value.controls.setupCodeName.value.trim(),
        ListOrder: maxListOrder + 1,
      };

      this.http.CallApiWithCallback({
        host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "CreateCategoryGroup",
        method: HttpMethod.Post,
        body: categoryGroupObj,
        showError: true,
        extraParams: []
      });
 
    } else if (data.type.toLowerCase() == this.localization.captions.setting.update.toLowerCase() && this.checkForMinValidation(data)) {
      const categoryGroupObj = {
        IsActive: data.value.controls.activetoggle.value,
        Name: data.value.controls.setupCodeName.value.trim(),
        ListOrder: this.tableoptions[0].TablebodyData[this.currIndex].listOrder,
      };
      this.http.CallApiWithCallback({
        host: myGlobals.Host.retailManagement,
        success: this.successCallback.bind(this),
        error: this.errorCallback.bind(this),
        callDesc: "UpdateCategoryGroup",
        uriParams: {id : this.tableoptions[0].TablebodyData[this.currIndex].id},
        method: HttpMethod.Put,
        body: categoryGroupObj,
        showError: true,
        extraParams: []
      });
      console.log('updated details', categoryGroupObj);
    }
    return true;
  }

  EditRecords(data?: any, type?: any) {
    this.currIndex = this.tableoptions[0].TablebodyData.findIndex(item => item.id == data[0].id);
  }

  DeleteRecords(event: any) {
    this.http.CallApiWithCallback({
      host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "DeleteCategoryGroup",
      uriParams: {id : event[0].id},
      method: HttpMethod.Delete,
      showError: true,
      extraParams: []
    });
    console.log('Deleted Data', event);
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    this.utils.ToggleLoaderWithMessage(true,this.captions.lbl_processing)
    if (callDesc == "CreateCategoryGroup" || "GetAllCategoryGroups" || "DeleteCategoryGroup" || "UpdateCategoryGroup") {
        let response = <any>result.result;
      this.allCategoryGroups = [];
      response.forEach(element => {
        let CategoryGroup: any = {
          id: element.id,
          categoryGroup: element.name,
          isActive: element.isActive,
          listOrder: element.listOrder
        }
        this.allCategoryGroups.push(CategoryGroup);
      });

      this.BindToGrid();
      this.utils.ToggleLoader(false);
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
  }

  private getAllCategoryGroups(){
     this.http.CallApiWithCallback<any>({
       host: myGlobals.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "GetAllCategoryGroups",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { PropertyId: Number(this.utils.GetPropertyInfo('PropertyId')) },
      extraParams: []
    });
  }


  private async BindToGrid() {

    this.tableoptions = [
      {
        TableHdrData: [{ 'title': this.captions.categoryGroup, 'jsonkey': 'categoryGroup', 'alignType': 'left', 'sortable': true },
        { 'title': this.captions.Active, 'jsonkey': 'isActive', 'alignType': 'left', 'type': 'toggle', 'sortable': true },
        { 'title': this.captions.ListOrder, 'jsonkey': 'listOrder', 'alignType': 'right', 'sortable': true, "searchable": false }],
        TablebodyData: this.allCategoryGroups,
        pagination: false,
        sortable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.Search,
        EnableActions: true,
        SelectRows: true,
        IsCommission: true, 
        Searchable: false,
        EditMoreOption: false,
        SelectedSettingId: myGlobals.GridType.categorygroup,
        Sortable: 'listOrder',
        TableId: myGlobals.GridType.categorygroup,
        disableDelete: false,
        customHeader: true,
        pageTitle: 'categoryGroup',
        ServiceId: 'categoryGroup', 
        IsViewOnly: this.IsViewOnly? this.IsViewOnly : false,
        TableDraggable: true,
        automationId: 'retailCategoryGroup'
      }
    ];
    this.tableoptions = [...this.tableoptions];
  }
}
