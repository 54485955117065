import { Pipe, PipeTransform } from '@angular/core';
import { appointmentService } from '../service/appointment.service';
import { SpaLocalization } from '../../core/localization/spa-localization';

 
@Pipe({
  name: 'getServiceStatusCodePipes'
})
export class GetServiceStatusCodePipesPipe implements PipeTransform {
  constructor(public _appointmentService: appointmentService, public localization: SpaLocalization) { }
  transform(statusCode) {
    let captions: any = this.localization.captions.bookAppointment;
    if (statusCode == "RESV") {
      return captions.Scheduled;
    }
    else if (statusCode == "CKIN") {
      return captions.CheckedIn;
    }
    else if (statusCode == "CKOUT") {
      return captions.CheckedOut;
    }
    else if (statusCode == "CLOSED") {
      return captions.Closed;
    }
    else if (statusCode == "NOSHOW") {
      return captions.NoShow;
    }
    else if (statusCode == "BREAK") {
      return captions.Break;
    }
    else {
      return statusCode;
    }
  }
}

