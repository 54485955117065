import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, OnDestroy, ViewChild, ElementRef, OnChanges, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as _ from "lodash";
import { SpaLocalization } from '../../core/localization/spa-localization';
import { HttpServiceCall } from '../../shared/service/http-call.service';
import { SubscriptionLike as ISubscription, fromEvent } from 'rxjs';
import { BaseResponse } from '../../shared/business/shared.modals';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ItemSearchBaseResponse } from 'src/app/shared/business/view-settings.modals';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';


@Component({
  selector: 'app-search-items',
  templateUrl: './search-items.component.html',
  styleUrls: ['./search-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchItemsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() searchTextEmit: EventEmitter<any> = new EventEmitter();
  @Output() searchControlValidator: EventEmitter<any> = new EventEmitter();
  @Input() retailItemType: number;
  @Input() RetailItemId: number;
  @Input() autocomplete: UntypedFormControl;
  @Input() filteredData: any = [];
  ORG_searchFilter: any;
  @Input() Disabled = false;
  isRetailItemSelected = false;
  private searchFilterResult: any = [];
  searchText: string;
  captions: any = this._Localization.captions.retailsetup;
  settingCaptions: any = this._Localization.captions.setting;
  subscription: ISubscription;
  @ViewChild('autoCompleteInput') autoCompleteSelect: MatAutocompleteTrigger;
  @ViewChild('searchInput', { static: true }) input: ElementRef;
  linkedItemsClicked: boolean;
  searchFilter: ItemSearchBaseResponse[] = [];
  floatLabel:string;

  constructor(public _Localization: SpaLocalization, private http: HttpServiceCall,private util :SpaUtilities) {
    this.floatLabel = this._Localization.setFloatLabel;
  }


  ngOnChanges() {
    this.searchFilter = this.filteredData ? this.filteredData : [];
    setTimeout(() => {
      this.heightCalc();
    }, 10);
  }

  ngAfterViewInit(): void {
    const terms$ = fromEvent<any>(this.input.nativeElement, 'keyup')
      .pipe(
        map(event => event.target.value),
        distinctUntilChanged()
      );
    this.subscription = terms$
      .subscribe(
        criterion => {
          if (this.autocomplete.value && this.autocomplete.value.length >= 3) {
            this.searchTextEmit.emit(this.autocomplete.value);
          }
        }
      );
  }

  ngOnInit() {
    if (this.Disabled) {
      this.autocomplete.disable();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  filterFucntion($event) {

    if (this.autocomplete.value) {
      this.searchText = null;
      this.autocomplete.setValue(undefined);
      this.searchTextEmit.emit(this.autocomplete.value);
    }
  }
  Done(id) {
    this.doneEvtEmitter.emit(id);
  }

  SelectedRecord(data) {
    const id = data.id;
    this.Done(id);
    this.autocomplete.setValue(data.itemNumber + ' - ' + data.itemDescription);
    this.searchFilter = [];
    this.linkedItemsClicked = false;
  }

  autocompleteShow(e) {
    this.linkedItemsClicked = true;
    e.stopPropagation();
  }

  clickedOutside() {
    if (this.linkedItemsClicked) {
      this.linkedItemsClicked = false;
    }
  }
  ToMatch(from: string, to: string): boolean {
    let fromValue: string = from.toString().toUpperCase();
    fromValue = fromValue.replace(/[^a-zA-Z0-9]/g, "")
    to = to.toUpperCase();
    to = to.replace(/[^a-zA-Z0-9]/g, "")
    if (fromValue.match(to)) {
      return true;
    } else {
      return false;
    }
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetAllShopItems") {
      const LinkedList = result.result ? result.result : [];
      this.ORG_searchFilter = _.cloneDeep(LinkedList);
    } else if (callDesc == "GetRetailItemByItemType") {
      this.searchFilterResult = result ? result.result : [];
      this.searchFilter = this.searchFilterResult;
      if (this.searchFilterResult && this.RetailItemId) {
        this.searchFilter = [];
        const retailItem = this.searchFilterResult.find(x => x.id == this.RetailItemId);
        this.autocomplete.setValue(retailItem);
        this.isRetailItemSelected = true;
        this.displayFn(retailItem);
      }
    }
  }
  
  displayFn(searchVal: any) {
    if (searchVal) {
      const dispValue = searchVal.itemNumber + ' - ' + searchVal.itemDescription;
      this.isRetailItemSelected = true;
      if (this.isRetailItemSelected) {
        this.searchText = dispValue;
      }
      return dispValue;
    }
  }
  formFieldValidator() {
    this.searchControlValidator.emit((this.autocomplete.value) ? true : false);
  }

  heightCalc() {
    let searchData = document.getElementById('searchData');
    let searchLength = this.searchFilter.length * 48;
    let searchOuter = document.getElementById('searchOuter');
    if (searchData && searchOuter) {
      if (searchLength < searchOuter['offsetHeight']) {
        searchData['style']['height'] = searchLength + 'px';
      } else {
        searchData['style']['height'] = searchOuter['offsetHeight'] + 2 + 'px';
      }
    } else {
      if (searchOuter)
        searchOuter['style']['height'] = 0 + 'px';
    }
  }
}
