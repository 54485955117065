import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from 'src/app/eatecui/source/transaction/shared/interface/transaction.interface';
import { FormBuilderGroupKey, MasterInventoryManagement } from '../master.interface';
import { CustomFormBuilder, FormModelFieldBuilder } from './form-config.interface';

export const FormCheckFlow: FormModelFieldBuilder = (ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey, translateService: TranslateService, CustomData ?: TransactionConfiguration ): boolean => {
    try {
        let hideFlowBoolean = false;
        if ( CustomData ) {
           if ( FieldGrouping !== undefined && FieldGrouping.Hide !== null ) {
               hideFlowBoolean = FieldGrouping.Hide;
           }
           if ( CustomData.CreateTranscationConfig && CustomData.CreateTranscationConfig.hasOwnProperty('flowFrom')  &&  
                CustomData.CreateTranscationConfig.flowFrom === 'new' &&
                FieldGrouping.hasOwnProperty('checkflowFrom')  && FieldGrouping.checkflowFrom === 'hidenewflow') {
               hideFlowBoolean = true;
           }
        }
       return hideFlowBoolean;
    } catch (error) {
        console.error('Error occurred in FormAutoFillDataForm', error);
    }
};