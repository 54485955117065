import { User } from 'src/app/eatecui/source/core/models/user-auth-model';

export interface LoginState {
  GetUserLogin: User;
  GetUserLoginSuccess: any;
  GetUserLoginFailure: any;
  GetClientId: any;
  GetClientIdSuccess: any;
  GetClientIdFailure: any;
  GetTokenSuccess: any;
  GetTokenFailure: any;
  GetSSOClientId: any;
  GetSSOClientIdSuccess: any;
  GetSSOClientIdFailure: any;
  GetSSOTokenSuccess: any;
  GetSSOTokenFailure: any;
  }

  export const initialLoginState: LoginState = {
    GetUserLogin: null,
    GetUserLoginSuccess: null,
    GetUserLoginFailure: null,
    GetClientId: null,
    GetClientIdSuccess: null,
    GetClientIdFailure: null,
    GetTokenSuccess: null,
    GetTokenFailure: null,
    GetSSOClientId: null,
    GetSSOClientIdSuccess: null,
    GetSSOClientIdFailure: null,
    GetSSOTokenSuccess: null,
    GetSSOTokenFailure: null
  };
