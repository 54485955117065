import { BaseReport } from "../common/base-report";
import { ReportUIConfig, ReportAPIOptions, ReportParams , AllReports, StaffFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services"; 
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { Product } from '../../../shared/globalsContant';
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { cloneDeep } from "lodash";
export class StaffSummaryReport extends BaseReport {   
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, private productId: number, 
        private PropertyInfo: RetailPropertyInformation,public retailLocalization : RetailLocalization,
        public patcheableValue, private propertySettingsDataService: PropertySettingDataService) {
        super(reportFormGrp);
    }

    houseServiceChargePercentage: string = "0.00";
    houseGratuityPercentage: string = "0.00";

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.StaffMemberSummary,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

    getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllStaffs(AllReports.StaffMemberSummary,this.productId),
            dropDownFilterName: this.captions.Users,
            inActiveToggle: true,
            inActiveToggleName: this.captions.IncludeInactiveUsers,
            pageBreakToggle: true,
            layout: 'LANDSCAPE',
            allowFutureDate: false,
            showHelperText : this.productId==Product.SPA?true:false,
            hideCorrectedValues: true,
            includeInhouseGratuitySC: this.getHouseGratuitySCPercentageSettingsValue()
        };
    }

    formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;   
        let houseServiceChargePercentage = this.getHouseServiceChargePercentage();
        houseServiceChargePercentage =  Number(houseServiceChargePercentage)>0? houseServiceChargePercentage : "0.00";
        let houseGratuityPercentage = this.getHouseGratuityPercentage();
        houseGratuityPercentage =  Number(houseGratuityPercentage)>0? houseGratuityPercentage : "0.00";

        if(this.reportFormGrp.controls.includeInhouseGratuitySCToggle.value == false)
        {
            houseServiceChargePercentage = "0.00";
            houseGratuityPercentage = "0.00";
        }

        if (this.commonLocalization.isFromJobScheduler)
        {
            return [
                { "pStartDate": toApi(this.startDate) },
                { "pEndDate": toApi(this.endDate) },
                { "pPageBreak": this.pageBreakToggle }, 
                { "pFilterCaption": this.getFilterCaption },
                { "pPropertyName": this.propertyName }, 
                { "pDate": this.printedDate }, 
                { "pVATEnable": this.PropertyInfo.IsVATEnabled },
                { "pHouseServiceChargePercentage": houseServiceChargePercentage},
                { "pHouseGratuityPercentage": houseGratuityPercentage},
                { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
                { "pIncludeZeroCommSCGrat": this.reportFormGrp.controls['customFilterFormGrp'].value.includeZeroValue},
                { "fromWildCard": this.reportFormGrp.controls.timelineFrom.value},
                { "fromIncrement": this.reportFormGrp.controls.timelineCountFrom.value},
                { "toWildCard": this.reportFormGrp.controls.timelineTo.value},
                { "toIncrement": this.reportFormGrp.controls.timelineCountTo.value},
                { "includeZeroValue": this.reportFormGrp.controls['customFilterFormGrp'].value.includeZeroValue},
                { "PatchEditParam": true},
                { "pInhouseReportType": this.reportFormGrp.controls.reportTypeGSCSelection.value}
            ];
        }

        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle }, { "pFilterCaption": this.getFilterCaption },
        { "pPropertyName": this.propertyName }, { "pDate": this.printedDate }, 
        { "pVATEnable": this.PropertyInfo.IsVATEnabled },
        { "pHouseServiceChargePercentage": houseServiceChargePercentage},
        { "pHouseGratuityPercentage": houseGratuityPercentage},
        { "pIncludeInactiveUsers": this.reportFormGrp.controls.includeInactiveusers.value},
        { "pIncludeZeroCommSCGrat": this.reportFormGrp.controls['customFilterFormGrp'].value.includeZeroValue},
        { "pInhouseReportType": this.reportFormGrp.controls.reportTypeGSCSelection.value}
        ];
    }

    getHouseServiceChargePercentage(): string{
        let houseServiceChargePercentage : string = "0.00";
        let houseServiceChargePercentageSession = sessionStorage.getItem('houseServiceChargePercentage');    
        if(houseServiceChargePercentageSession){
            houseServiceChargePercentage = houseServiceChargePercentageSession;
        }
        return houseServiceChargePercentage;
    }

    getHouseGratuityPercentage(): string{
        let houseGratuityPercentage : string = "0.00";
        let houseGratuityPercentageSession = sessionStorage.getItem('houseGratuityPercentage');    
        if(houseGratuityPercentageSession){
            houseGratuityPercentage = houseGratuityPercentageSession;
        }
        return houseGratuityPercentage;
    }

    async getHouseGratuitySCPercentageSettingsValue(){
        let showHouseGratuitySCToggle = false;
        let miscSettings = await this.propertySettingsDataService.GetMiscConfigurationSetting();
        let houseServiceChargePercentageSettingsValue = miscSettings.find(f => f.switch == GlobalConst.MiscellaneousSwitch.HOUSE_SERVICECHARGE_PERCENTAGE);
        if(houseServiceChargePercentageSettingsValue){
            this.houseServiceChargePercentage = String(houseServiceChargePercentageSettingsValue.value); 
        }
        sessionStorage.setItem('houseServiceChargePercentage', this.houseServiceChargePercentage)
        let houseGratuityPercentageSettingsValue = miscSettings.find(f => f.switch == GlobalConst.MiscellaneousSwitch.HOUSE_GRATUITY_PERCENTAGE);
        if(houseGratuityPercentageSettingsValue){
            this.houseGratuityPercentage = String(houseGratuityPercentageSettingsValue.value); 
        }
        sessionStorage.setItem('houseGratuityPercentage', this.houseGratuityPercentage);

        if(Number(this.houseServiceChargePercentage) > 0 || Number(this.houseGratuityPercentage) > 0)
            return true;
        return showHouseGratuitySCToggle;
    }

    formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) },
        { "EndDate": toApi(this.endDate) },
        { "ProductId": this.productId },
        { "HideCorrectedValues": this.reportFormGrp.controls.hideCorrectedValuesToggle.value }];
    }

    formFilters() {

        let selectedStaffs: StaffFilter[] = this.selectedFilterOptions;
        let data = selectedStaffs.filter(r => r.showInDropDown).map(s => { return { staffId: s.id, staffType: s.staffType } });
        if (this.commonLocalization.isFromJobScheduler) {
            return {
                includeZeroValue: this.reportFormGrp.controls['customFilterFormGrp'].value.includeZeroValue,
                staffs: data,
                fromIncrement: this.reportFormGrp.controls.timelineCountFrom.value,
                fromWildCard: this.reportFormGrp.controls.timelineFrom.value,
                toIncrement: this.reportFormGrp.controls.timelineCountTo.value
                , toWildCard: this.reportFormGrp.controls.timelineTo.value
            };
        }
        if (this.isJasperReportEnabled) {
            const toApi = this.localization.convertDateObjToAPIdate;
            let houseServiceChargePercentage = this.getHouseServiceChargePercentage();
            houseServiceChargePercentage = Number(houseServiceChargePercentage) > 0 ? houseServiceChargePercentage : "0";
            let users = cloneDeep(selectedStaffs.filter(r => r.showInDropDown && r.staffType == GlobalConst.USER))
            users.forEach(element => {
               element.description = element.description.replace(/\s*\((T|U)\)$/, '')
            }); 
            let therapists = cloneDeep(selectedStaffs.filter(r => r.showInDropDown && r.staffType == GlobalConst.THERAPIST))
            therapists.forEach(element => {
                element.description = element.description.replace(/\s*\((T|U)\)$/, '')
             }); 
            return {
                StartDate: toApi(this.startDate),
                EndDate: toApi(this.endDate),
                includeZeroValue: this.reportFormGrp.controls['customFilterFormGrp'].value.includeZeroValue,
                isVATEnabled: this.PropertyInfo.IsVATEnabled,
                UserIds: this.getValuesForJasper(users),
                TherapistIds: this.getValuesForJasper(therapists),
                HouseServiceCharge: houseServiceChargePercentage,
                IncludeInactiveUsers: this.reportFormGrp.controls.includeInactiveusers.value,
                HideCorrectedValues: this.reportFormGrp.controls.hideCorrectedValuesToggle.value
            }
        }
        return {
            includeZeroValue: this.reportFormGrp.controls['customFilterFormGrp'].value.includeZeroValue,
            staffs: selectedStaffs.filter(r => r.showInDropDown).map(s => { return { staffId: s.id, staffType: s.staffType } })
        };
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);            

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);
            
        }, 1);
    }
}
