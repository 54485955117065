import { Formatter, GlobalNumberFormatter } from 'src/app/eatecui/source/shared/models/formater.interface';

export const GlobalNumberFormater: GlobalNumberFormatter = (NumericValue: any): any => {
    try {
        if (NumericValue !== null && NumericValue !== '' ) {
            const FormatedValue: number = (typeof NumericValue === 'string') ? Number( NumericValue ) : NumericValue;
            return FormatedValue.toFixed(2);
        } else {
            return '';
        }
    } catch (error) {
        console.log('GlobalNumberFormater', error);
    }
  };