import { Injectable } from '@angular/core';
import { SpaLocalization } from '../../../../core/localization/spa-localization';

@Injectable({
  providedIn: 'root'
})
export class ManagerUtilitiesService {


  constructor(private _Localization: SpaLocalization) { }

  getCaptions() {
    return this._Localization.captions.settings.utilities;
  }
}
