import { Injectable } from "@angular/core";
import { RetailDataAwaiters } from "../../../retail/shared/events/awaiters/retail.data.awaiters";
import { AppointmentPopupComponent } from "../../appointment-popup/appointment-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { AppointmentpopupService } from "../appointmentpopup.service";
import { SpaLocalization } from "../../../core/localization/spa-localization";
import { BreakPointAccess } from "../breakpoint.service";
import * as GlobalConst from "../../globalsContant";
import { ImageProcessorService } from "../image-processor-service";
import { BaseResponse, LoyaltyDetail,ClientDetail as sharedClientDetail, RetailReceiptNotificationModel, SystemConfig, DepositRefund } from "../../business/shared.modals";
import * as _ from "lodash";
import { ClientDetail, PhoneNumber, Email, Address, ClientInfo } from '../../business/new-booking.model';
import { HttpMethod, HttpServiceCall } from "../http-call.service";
import { PayeeInfo } from "../../../retail/shared/business/shared.modals";
import { GuestStayDetail } from "../../../retail/shared/service/payment/payment-business.model";
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { FormatText } from '../../pipes/formatText-pipe.pipe';
import { CommonDataAwaiters } from "src/app/common/shared/events/awaiters/common.data.awaiters";
import { ExceptionBussines } from "src/app/logviewer/logviewer/exceptions/exceptions.business";
import { LogMessagesBussines } from "src/app/logviewer/logviewer/log-messages/log-messages.business";
import { PerfomanceBussines } from "src/app/logviewer/logviewer/perfomance/perfomance.business";
import { TraceBussines } from "src/app/logviewer/logviewer/trace-modal/trace-modal.business";
// @ts-ignore
import { SpaUtilities } from "../../utilities/spa-utilities";
import { ClientInfo as retailClientInfo } from "src/app/retail/shared/shared.modal";
import { ClientType ,clientSearchType} from "../../globalsContant";
import { LogTypeBussines } from "../log-type.service";
import { GuestExportNotificationModel } from "src/app/common/shared/shared.modal";
import { VipTypBusiness } from "../vip-type.service";
import { CustomFeeSourceType } from "src/app/retail/shared/globalsContant";
import { UserAccessBreakPoints } from "src/app/common/constants/useraccess.constants";
import { LockerAssignmentDetails } from "src/app/retail/shop/shop.modals";
import { AddLockersService } from "../../add-lockers/add-lockers.service";

@Injectable({
    providedIn: "root"
})
export class DataAwaiterService {
    constructor(
        private dialog: MatDialog,
        private appointmentPopupService: AppointmentpopupService,
        private localization: SpaLocalization,
        private BPoint: BreakPointAccess,
        private imageService: ImageProcessorService,
        private routeLoaderService: RouteLoaderService,
        public formatphno: FormatText,
        private exceptionBussines: ExceptionBussines,
        private logMessagesBussines: LogMessagesBussines,
        private perfomanceBussines: PerfomanceBussines,
        private traceBussines: TraceBussines,
        private utils : SpaUtilities,
        public http: HttpServiceCall,
        private logTypeBusiness: LogTypeBussines,
        private vipTypeBusiness: VipTypBusiness,
        private lockerService: AddLockersService
    ) {
        this.setAwaiters();
    }
    private setAwaiters(): void {
        RetailDataAwaiters.getActiveTherapists = this.getActiveTherapists.bind(this);
        RetailDataAwaiters.openAddPayeePopup = this.openAddGuestPopup.bind(this);
        RetailDataAwaiters.searchPayee = this.searchClient.bind(this);
        RetailDataAwaiters.getGuestInfos = this.GetAllClientsbyguid.bind(this);
        RetailDataAwaiters.searchTransactionGuest = this.searchClient.bind(this);
        RetailDataAwaiters.getPayeeDetails = this.getClientDetails.bind(this);
        RetailDataAwaiters.getMemberInfo = this.getMemberInfo.bind(this);
        RetailDataAwaiters.getPayeeInfo = this.getClientInfo.bind(this);
        RetailDataAwaiters.getGuestStayDetails = this.getGuestStayDetails.bind(this);
        RetailDataAwaiters.getTherapistImage = this.getTherapistImage.bind(this);
        RetailDataAwaiters.getAllTherapists = this.getAllTherapists.bind(this);
        RetailDataAwaiters.ReleaseAppointmentLock = this.ReleaseAppointmentLock.bind(this);
        RetailDataAwaiters.CreatePlayer = this.createClient.bind(this);
        RetailDataAwaiters.GetGuestByPlatformGuestGuid = this.GetGuestByPlatformGuestGuid.bind(this);

        RetailDataAwaiters.GetChildMenu = this.getChildMenu.bind(this);
        //Added to load data
        RetailDataAwaiters.GetExistingPlayer = this.getExistingPlayer.bind(this);
        RetailDataAwaiters.openGuestPatronPopup = this.openGuestPatronPopup.bind(this);
        RetailDataAwaiters.GetAppointmentClientDetails = this.getAppointmentClientDetails.bind(this);
        RetailDataAwaiters.SendNotification = this.sendRetailReceiptNotification.bind(this);

        //Refund Deposit Amount
        RetailDataAwaiters.RefundAppointmentDeposit = this.refundDepositAmount.bind(this);

        RetailDataAwaiters.ValidateOutletReference = this.ValidateOutletReference.bind(this);

        RetailDataAwaiters.IsCancelationNoShowEnabled = this.IsCancelationNoShowEnabled.bind(this);


        CommonDataAwaiters.GetAllExceptions = this.getAllExceptions.bind(this);
        CommonDataAwaiters.GetTopUserException = this.getTopUserException.bind(this);
        CommonDataAwaiters.GetTopFrequentException = this.getTopFrequentException.bind(this);
        CommonDataAwaiters.GetAllTransactions = this.getAllTransactions.bind(this);
        CommonDataAwaiters.GetTopUserTransactions = this.getTopUserTransactions.bind(this);
        CommonDataAwaiters.GetTopFrequentTransactions = this.getTopFrequentTransactions.bind(this);
        CommonDataAwaiters.GetAllTransactionsForUser = this.getAllTransactionsForUser.bind(this);
        CommonDataAwaiters.GetAllTransactionsForScreen = this.getAllTransactionsForScreen.bind(this);
        CommonDataAwaiters.GetAllPerformance = this.getAllPerformance.bind(this);
        CommonDataAwaiters.GetTraceDetails = this.getTraceDetails.bind(this);
        CommonDataAwaiters.GetIntakeDetails=this.getIntakeDetails.bind(this);
        RetailDataAwaiters.GetClientMultiPack = this.getClietnMultiPacksBytransactionId.bind(this);
        RetailDataAwaiters.UpdateMultiPack = this.UpdateMultiPack.bind(this);
        RetailDataAwaiters.UpdateAppointmentMultiPackId = this.UpdateAppointmentMultiPackId.bind(this);
        CommonDataAwaiters.GetAllLogType = this.getAllLogType.bind(this);
        CommonDataAwaiters.CreateLogType = this.CreateLogType.bind(this);
        CommonDataAwaiters.EditLogType = this.EditLogType.bind(this);
        CommonDataAwaiters.UpdateLogType = this.UpdateLogType.bind(this);
        CommonDataAwaiters.DeleteLogType = this.DeleteLogType.bind(this);
        CommonDataAwaiters.GetAllContactLogType = this.getAllContactLogType.bind(this);
        CommonDataAwaiters.CheckForAccess = this.CheckLogTypeForAccess.bind(this);
        CommonDataAwaiters.isViewOnly = this.CheckLogTypeIsViewOnly.bind(this);
        CommonDataAwaiters.GetConfirmationSetting = this.GetConfirmationSetting.bind(this);
        CommonDataAwaiters.SendGuestExportEmail = this.SendExportNotification.bind(this);

        //ExtendedProfileSearchConfig
        RetailDataAwaiters.GetExtendedProfileSearchConfig = this.GetExtendedProfileSearchConfig.bind(this);

        //VipType
        CommonDataAwaiters.GetAllVipType = this.getAllVipType.bind(this);
        CommonDataAwaiters.CreateVipType = this.createVipType.bind(this);
        CommonDataAwaiters.UpdateVipType = this.updateVipType.bind(this);
        CommonDataAwaiters.DeleteVipType = this.deleteVipType.bind(this);
        CommonDataAwaiters.GetNextListOrderofVipType = this.getNextListOrderofVipType.bind(this);
        CommonDataAwaiters.DragDropVipType = this.dragDropVipType.bind(this);
        CommonDataAwaiters.GetVipTypeBreakpoint = this.GetVipTypeBreakpoint.bind(this);  


        RetailDataAwaiters.CheckAppointmentStatusByDayPassId=this.CheckAppointmentStatusByDayPassId.bind(this);
        RetailDataAwaiters.UpdateIsActiveForDayPass=this.UpdateIsActiveForDayPass.bind(this);
        RetailDataAwaiters.SaveLocker = this.SaveLockers.bind(this);
      }

    async UpdateMultiPack(multipackreturn) {
        const response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "UpdateMultiPacks",
            GlobalConst.Host.retailPOS,
            HttpMethod.Put,
            null,
            multipackreturn
        );
        return response.result;
    }

    async GetExtendedProfileSearchConfig(){
        const response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "GetConfiguration",
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { module:2 , Switch:"EXTENTED_PROFILE_SEARCH"}
          );
      let res: SystemConfig = <any>response.result;
      let isPlatformSearch = res.value == "true" ? true : false;
      return  isPlatformSearch;
    }

    async UpdateAppointmentMultiPackId(ClientMultipack) {
        const response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "UpdateAppoinmentMultipackId",
            GlobalConst.Host.schedule,
            HttpMethod.Put,
            null,
            ClientMultipack
        );
        return response.result;
    }

    async getClietnMultiPacksBytransactionId(transactionId) {
        const response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "GetClientMultiPacksByTransactionId",
            GlobalConst.Host.retailPOS,
            HttpMethod.Get,
            { transactionId: transactionId }
        );
        return response.result;
    }

    async getAllExceptions(filterData) {
        return this.exceptionBussines.callAPIGetAllExceptions(filterData);
    }
    async getTopUserException(filterData) {
        return this.exceptionBussines.callAPIGetTopUserException(filterData);
    }
    async getTopFrequentException(filterData) {
        return this.exceptionBussines.callAPIGetTopFrequentException(filterData);
    }
    async getAllTransactions(filterData, recordStart) {
        return this.logMessagesBussines.callAPIGetAllTransactions(filterData, recordStart);
    }
    async getTopUserTransactions(filterData) {
        return this.logMessagesBussines.callAPIGetTopUserTransaction(filterData);
    }
    async getTopFrequentTransactions(filterData) {
        return this.logMessagesBussines.callAPIGetTopFrequentTransaction(filterData);
    }
    async getAllTransactionsForUser(filterData, userName) {
        return this.logMessagesBussines.callAPIGetAllTransactionsForUser(filterData, userName);
    }
    async getAllTransactionsForScreen(filterData, screenName) {
        return this.logMessagesBussines.callAPIGetAllTransactionsForScreen(filterData, screenName);
    }
    async getAllPerformance(filterData, seconds) {
        return this.perfomanceBussines.callAPIGetAllPerformance(filterData, seconds);
    }
    async getTraceDetails(traceId) {
        return this.traceBussines.callAPI(traceId);
    }
    private async getIntakeDetails(): Promise<boolean> {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "GetConfiguration",
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { module:2 , Switch: "INTAKE_FORM"}
        );
        let res: SystemConfig = <any>response.result;
        let propertyIntakeForm = res.value == "true" ? true : false;
        return  propertyIntakeForm;

    }

    async getAppointmentClientDetails(transactionId: number) {
        const response = await this.appointmentPopupService.InvokeServiceCallAsync(
            'ReceiptDetailsByTransactionId',
            GlobalConst.Host.schedule,
            HttpMethod.Get,
            { transactionId: transactionId }
        );
        return response.result;
    }

    //Added
    private async getExistingPlayer(patronId) {
        let client = await this.appointmentPopupService.InvokeServiceCallAsync('GetClientByPatronId', GlobalConst.Host.spaManagement, HttpMethod.Get, { patronId: patronId });
        return client.result;
    }

    async openGuestPatronPopup(e, callback: Function, id?, guestId?) {
        if (e.toLowerCase() == "ordersummary" &&
            this.BPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.AddNewClientProfile])) {
            this.appointmentPopupService.add_client = true;
            this.appointmentPopupService.checkVar = e;
            this.appointmentPopupService.exsisting_client = true;
            this.appointmentPopupService.clientWidowActionType = "NEW";
            this.appointmentPopupService.popupTitle = this.localization.captions.bookAppointment.NewClient;
            this.appointmentPopupService.saveText = this.localization.captions.bookAppointment.save;
            this.appointmentPopupService.IsOrderSummary = true;
            this.addGuestDialog(id);
        }
    }

    addGuestDialog(PatronId) {
        let data = {
            closebool: true,
            action: 'NEW',
            oderSummaryPlayerId: PatronId
        };
        this.dialog.open(AppointmentPopupComponent, {
            width: "85%",
            height: "85%",
            disableClose: true,
            hasBackdrop: true,
            data: data,
            panelClass: "small-popup"
        });
    }


    private async searchClient(name: string, type : number, requestUid: string, isPlatformGuestSearch: boolean): Promise<PayeeInfo[]> {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "clientSearch",
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { name: encodeURIComponent(name),searchType:clientSearchType.All, requestUid: requestUid, isPlatformGuestSearch: isPlatformGuestSearch }
        );
        let clientDetails: PayeeInfo[] = [];
        var responseUid = "";
        if (response.successStatus && response.result && response.result.length > 0) {
            responseUid = response.result[0].requestUid

            if ((requestUid != "" && responseUid != "" && requestUid == responseUid) || (requestUid == "" || responseUid == "")) {
                response.result.forEach(client => {
                    clientDetails.push(this.BuildPayeeData(client));
                });
            }
        }
        return [response.result, clientDetails];
    }

    async GetAllClientsbyguid(guestIds: any[]):Promise<PayeeInfo[]>{
       const response= await this.appointmentPopupService.InvokeServiceCallAsync(
        "GetClientsByGuestIds",
         GlobalConst.Host.spaManagement,
         HttpMethod.Put,
         '',
         guestIds
       );
       let clientDetails: PayeeInfo[] = [];
       if (response.successStatus && response.result && response.result.length > 0) {
           response.result.forEach(client => {
               clientDetails.push(this.BuildPayeeData(client));
           });
       }
       return [response.result, clientDetails];
   }

    private async getClientDetails(id: number[]): Promise<PayeeInfo[]> {
        let response: BaseResponse<sharedClientDetail[]> = await this.appointmentPopupService.InvokeServiceCallAsync(
            "GetClientByIds",
            GlobalConst.Host.spaManagement,
            HttpMethod.Put,
            { includeRelatedData: false },
            id
        );
        let clientDetails: PayeeInfo[] = [];
        if (response.successStatus && response.result && response.result.length > 0) {
            response.result.forEach(client => {
                clientDetails.push({
                    id: client.id,
                    name: client.firstName + ' ' + client.lastName,
                    guestProfileId: client.guestId,
                    address: '',
                    country: '',
                    city: '',
                    zip: '',
                    cardInfo: [],
                    guestId: client.id.toString(),
                    emailId: client.emailId,
                    guesttype: client.clientType,
                    playerLinkId: client.clientLinkId
                });
            });
        }
        return clientDetails;
    }

    private async getActiveTherapists(): Promise<any> {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "getActiveTherapist",
            GlobalConst.Host.spaManagement,
            HttpMethod.Get
        );
        return response.result;
    }

    private async getAllTherapists(): Promise<any> {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "GetAllTherapist",
            GlobalConst.Host.spaManagement,
            HttpMethod.Get
        );
        return response.result;
    }

    private async createClient(clientobj, callback): Promise<any> {
        if (clientobj.playerLinkId) {
            let clientData = await this.appointmentPopupService.InvokeServiceCallAsync(
                "GetGuestMemberId",
                GlobalConst.Host.spaManagement,
                HttpMethod.Get,
                { memberId: clientobj.playerLinkId }
            );
            if (clientData.result == null) {
                let scheduleDt = new Date();
                let memberInfo = await this.getMemberInfo(clientobj.playerLinkId, scheduleDt.toISOString());
                let clientinfoObj = await this.MapMemberToClientInfo(clientobj, memberInfo);
                const response = await this.appointmentPopupService.InvokeServiceCallAsync(
                    "createNewClient",
                    GlobalConst.Host.spaManagement,
                    HttpMethod.Post,
                    {},
                    clientinfoObj,
                );
                return callback(response.result.clientDetail.id,response.result.clientDetail.guestId);
            }

            return callback(clientData.result.clientDetail.id,clientData.result.clientDetail.guestId);
        }
        else if(clientobj.guestId!='' && clientobj.id==0){
            const response = await this.appointmentPopupService.InvokeServiceCallAsync(
                "createClientByGuestId",
                GlobalConst.Host.spaManagement,
                HttpMethod.Put,
                { guid: clientobj.clientDetail.guestId },
                clientobj,
            );
            return callback(response.result.clientDetail.id);
        }
        return callback(clientobj.clientDetail.id,clientobj.result.clientDetail.guestId);
    }
    public async GetGuestByPlatformGuestGuid(platformGuestGuid: string)
    {
        var response =  await this.http.CallApiAsync({
            host: GlobalConst.Host.spaManagement,
            callDesc: "GetClientByPlatformGuestUuid",
            method: HttpMethod.Get,
            showError: true,
            uriParams: { platformGuid: platformGuestGuid }
        });
        let clientDetail: PayeeInfo;
        if (response && response.result)
        {
            clientDetail = this.BuildPayeeData(response.result);
        }
        return clientDetail;
    }
    public async getMemberInfo(cardNo: string, scheduleDateTime: string): Promise<any> {
        const response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "GetMemberInfoByCardNo",
            GlobalConst.Host.retailPOS,
            HttpMethod.Get,
            { cardNo: cardNo, scheduleDateTime: scheduleDateTime }
        );

        if(response.result !=null){
           return response.result;
        }
        return null;
	}


    async MapMemberToClientInfo(clientobj,memberInfo) {
        if (clientobj) {
            let loyaltyObj: LoyaltyDetail[] = [];
            let clientinfo: ClientDetail = {
                id: 0,
                GuestId: '00000000-0000-0000-0000-000000000000',
                Title: '',
                FirstName: this.utils.capitalizeFirstLetter(clientobj.firstName),
                LastName: this.utils.capitalizeFirstLetter(clientobj.lastName),
                Pronounce: this.utils.capitalizeFirstLetter(clientobj.pronounced),
                Gender: '',
                DateOfBirth: '',
                Comments: '',
                BypassClientScheduling: false,
                CustomField1: 0,
                CustomField2: 0,
                CustomField3: 0,
                CustomField4: '',
                CustomField5: '',
                GenderPreference: '',
                LastChangeId: '00000000-0000-0000-0000-000000000000',
                InterfaceGuestId: clientobj.playerLinkId,
                PriceTypeId: 0,
                discountType:  memberInfo!=null? memberInfo.discountType : null,
                loyaltyDetail: loyaltyObj,
                tempId: null,
                clientType : ClientType.Member,
                clientLinkId: clientobj.playerLinkId,
                arAccountNumber: memberInfo!=null? memberInfo.arAccountNumber : null,
                isContactRelationUpdateRequired:this.appointmentPopupService.isContactRelationUpdateRequired,
                vip : '',
            };

            let clientInfoObj: ClientInfo = {
                id: clientinfo.id,
                clientDetail: clientinfo,
                emails: [],
                addresses: null,
                phoneNumbers: [], // Need to do
                clientMedicalCondition: [],// Need to do
                clientPreferences: [],
                clientSOAPNotes: [],
                clientCreditCardInfo: [],
                clientBlock: null,
                clientContactLogs :[],
                guestContact:[],
            }

           return clientInfoObj;
        }
    }



    private async getTherapistImage(therapistId: number): Promise<any> {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "getAllImagesByReference",
            GlobalConst.Host.image,
            HttpMethod.Get,
            { referencetype: GlobalConst.ImgRefType.therapist, referenceids: therapistId, isthumbnailonly: false }
        );
        return response.result;
    }

    private async ReleaseAppointmentLock(appointmentId :number[]) {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "ReleaseAppointmentLocks",
            GlobalConst.Host.schedule,
            HttpMethod.Put,
            false,
            appointmentId
        );
        return response.result;
    }

    private async getClientInfo(id: number): Promise<PayeeInfo> {
        let payee: PayeeInfo;
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "getClientInfo",
            GlobalConst.Host.spaManagement,
            HttpMethod.Get,
            { id: id }
        );
        if (response && response.successStatus && response.result) {
            payee = this.BuildPayeeData(response.result);
        }
        return payee;
    }

    private async getGuestStayDetails(guestId: string): Promise<GuestStayDetail[]> {
        var response: BaseResponse<GuestStayDetail[]> = await this.appointmentPopupService.InvokeServiceCallAsync(
            "GetAllRoomsWithGuestID",
            GlobalConst.Host.schedule,
            HttpMethod.Get,
            { clientId: guestId }
        );
        return response.result;
    }

    private BuildPayeeData(client): PayeeInfo {
        return {
            id: client.clientDetail.id,
            name: client.clientDetail.firstName + ' ' + client.clientDetail.lastName,
            address: (client.addresses && client.addresses.length > 0) ? (client.addresses[0].line1 + ' ' + client.addresses[0].state) : '',
            country: (client.addresses && client.addresses.length > 0) ? client.addresses[0].country : '',
            zip: (client.addresses && client.addresses.length > 0) ? client.addresses[0].zip : '',
            city: (client.addresses && client.addresses.length > 0) ? client.addresses[0].city : '',
            guestProfileId: client.clientDetail.clientType == ClientType.Member? client.clientDetail.clientLinkId:client.clientDetail.guestId,
            cardInfo: client.clientCreditCardInfo,
            patronId: client.clientDetail.loyaltyDetail && client.clientDetail.loyaltyDetail[0] ? client.clientDetail.loyaltyDetail[0].patronId : '',
            rank: client.clientDetail.loyaltyDetail && client.clientDetail.loyaltyDetail[0] ? client.clientDetail.loyaltyDetail[0].rank : '',
            phoneNumber: this.GetClientPhoneNumber(client),
            emailId: this.GetClientEmail(client),
            lastName: client.clientDetail.lastName,
            guestId : client.clientDetail.guestId,
            guesttype: client.clientDetail.clientType,
            platformGuestUuid: client.clientDetail.platformGuestUuid,
            vip: client.clientDetail.vip
        };
    }

    private GetClientPhoneNumber(clientInfo) {
        if (clientInfo && clientInfo.phoneNumbers && clientInfo.phoneNumbers.length) {
            let notPrivate = clientInfo.phoneNumbers.filter(x => !x.isPrivate);
            if (notPrivate && notPrivate.length) {
                let primary = notPrivate.find(x => x.isPrimary);
                if (primary == null || primary == undefined) {
                    primary = notPrivate[0];
                }
                return this.getFormattedPhNo(primary);
            }
        }
        return '';
    }

    private GetClientEmail(clientInfo) {
        if (clientInfo && clientInfo.emails && clientInfo.emails.length) {
            //let notPrivate = clientInfo.emails.filter(x => !x.isPrivate);
            let notPrivate = clientInfo.emails;
            if (notPrivate && notPrivate.length) {
                let primary = notPrivate.find(x => x.isPrimary);
                if (primary && primary.emailId) {  
                    return primary.emailId;
                }
                return notPrivate[0].emailId;
            }
        }
        return '';
    }

    private getFormattedPhNo(PhoneNo: any): any {
        //ADDING FOR EXTENSION FIELD
        let _number: any,
            number = PhoneNo.number;
        let countryCode = [];
        countryCode = number.split('|');
        if (countryCode != undefined && countryCode[0] != undefined && countryCode[0] != '' && countryCode[1] != undefined) {
            let extension = [];
            PhoneNo.countryCode = countryCode[0];
            number = countryCode[1];
            extension = countryCode[0].split(':');
            if (extension != undefined && extension[0] != undefined && extension[0] != '' && extension[1] != undefined) {
                countryCode[0] = extension[1];
                number = extension[0] + ":" + number;
            }
        }
        if (number != undefined && number != '') {
            if (PhoneNo.contactTypeId == '3') {
                let PhnoExt = [];
                PhnoExt = number.split(':');
                if (PhnoExt.length > 1) {
                    PhoneNo.extension = PhnoExt[0];
                    number = PhnoExt[1];
                }
            }
            _number = PhoneNo.extension ? this.formatphno.transform(`${PhoneNo.extension}:${number}`, this.localization.captions.common.ExtensionFormat) : this.formatphno.transform(number, this.localization.captions.common.PhoneFormat);
            if (countryCode != undefined && countryCode[0] != undefined && countryCode[0] != '' && countryCode[1] != undefined) {
                _number = countryCode[0] + " - " + _number;
            }
        }
        return _number;
    }

    async openAddGuestPopup(e, callback: Function, id?, guestId?, modifyLineItemsCallback?: Function, platformGuestUuid?) {
        if (e.toLowerCase() == "ordersummary" &&
            this.BPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.AddNewClientProfile])
        ) {
            this.appointmentPopupService.add_client = true;
            this.appointmentPopupService.checkVar = e;
            this.appointmentPopupService.exsisting_client = true;
            this.appointmentPopupService.clientWidowActionType = "NEW";
            this.appointmentPopupService.popupTitle = this.localization.captions.bookAppointment.NewClient;
            this.appointmentPopupService.saveText = this.localization.captions.bookAppointment.save;
            this.appointmentPopupService.IsOrderSummary = true;
            this.addClientDialog(callback, null, modifyLineItemsCallback);
        } else if (e.toLowerCase() == "ordersummaryedit" &&
            this.BPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.EditClientProfile])
        ) {
            const tempClientIds = this.appointmentPopupService.recordsArray &&
                this.appointmentPopupService.recordsArray.length > 0
                ? this.appointmentPopupService.recordsArray[0].clientDetail.id
                : 0;
            var clientId = id ? id : tempClientIds;
            const tempGuestId = this.appointmentPopupService.recordsArray &&
                this.appointmentPopupService.recordsArray.length > 0
                ? this.appointmentPopupService.recordsArray[0].clientDetail.guestId
                : GlobalConst.DefaultGUID;
            let guestid = guestId && guestId != GlobalConst.DefaultGUID ? guestId : tempGuestId;
            this.appointmentPopupService.guestId = _.cloneDeep(guestid);
            this.appointmentPopupService.clientId = _.cloneDeep(clientId);
            if(platformGuestUuid != '' && guestId == ''){
                var clientInfo = await this.appointmentPopupService.InvokeServiceCallAsync("getClientInfo", GlobalConst.Host.spaManagement, HttpMethod.Get, { id: id,platformGuestUuid });
            }
            else{
                var clientInfo = await this.appointmentPopupService.InvokeServiceCallAsync("getClientInfo", GlobalConst.Host.spaManagement, HttpMethod.Get, { id: id });
            }
            if (clientId) {
                this.imageService.GetImagesByReference(
                    guestid,
                    GlobalConst.ImgRefType.client,
                    this.successCallback.bind(this),
                    this.errorCallback,
                    [],
                    true
                );
                this.appointmentPopupService.IsOrderSummary = true;
                this.appointmentPopupService.checkVar = e;
                this.appointmentPopupService.add_client = true;
                this.appointmentPopupService.popupTitle = this.localization.captions.bookAppointment.EditClient;
                this.appointmentPopupService.saveText = this.localization.captions.bookAppointment.update;
                this.appointmentPopupService.isEditAppointment = true;
                this.appointmentPopupService.clientWidowActionType = "EDIT";
                var clientData = clientInfo;
                this.addClientDialog(callback, clientData.result, modifyLineItemsCallback);
            }
        }
    }

    addClientDialog(callback: Function, clientInfo = null, modifyLineItemsCallback?: Function) {
        let data = {
            closebool: true,
            action: 'Edit',
            clientInfo: clientInfo
        };
        let dialogRef = this.dialog.open(AppointmentPopupComponent, {
            width: "85%",
            height: "85%",
            disableClose: true,
            hasBackdrop: true,
            data: data,
            panelClass: "small-popup"
        });
        dialogRef.afterClosed().subscribe(result => {
            this.appointmentPopupService.IsOrderSummary = false;
            if (result && !this.appointmentPopupService.isCancel) {
                this.appointmentPopupService.isCancel = false;
                if (callback) {
                    callback(this.BuildPayeeData(result));
                }
            }
            if (result && modifyLineItemsCallback) {
                modifyLineItemsCallback(this.BuildPayeeData(result));
            }
        });
    }

    private async sendRetailReceiptNotification(clientInfo: retailClientInfo,emailid:any[], isDistributionListRequired: boolean = true) {
        let bodyObj: RetailReceiptNotificationModel;
        bodyObj = {
            appointmentId: clientInfo.appointmentId,
            action: "RetailReceipts",
            reportQuery: clientInfo.reportQuery,
            emailId:emailid,
            isRetailNotification:true,
            transactionId:clientInfo.transactionId,
            isDistributionListRequired: isDistributionListRequired
        }
        var response: BaseResponse<GuestStayDetail[]> = await this.appointmentPopupService.InvokeServiceCallAsync(
            "SendRetailNotification",
            GlobalConst.Host.schedule,
            HttpMethod.Put,
            null,
            bodyObj
        );
        return response.result;

    }

    private async refundDepositAmount(transactionId: number, refundTransactionId: number, refundTransactionDetails: any,sourceType?:CustomFeeSourceType | null) {
        if(sourceType === CustomFeeSourceType.Classes){
            await this.refundClassesDeposit(transactionId,refundTransactionId,refundTransactionDetails);
        }else{
           await this.refundAppointmentDeposit(transactionId,refundTransactionId,refundTransactionDetails);
        }
    }

    private async refundAppointmentDeposit(transactionId: number, refundTransactionId: number, refundTransactionDetails: any){
        let bodyObj: DepositRefund[] = [];
        refundTransactionDetails.forEach(r => {
            bodyObj.push({
                appointmentId: 0,
                transactionId: transactionId,
                transactionDetailId: r.Id,
                refundTransactionId: refundTransactionId,
                depositRefundAmount: r.ProductPrice
            })
        });
        var response: BaseResponse<boolean[]> = await this.appointmentPopupService.InvokeServiceCallAsync(
            "RefundDepositWithAppointment",
            GlobalConst.Host.schedule,
            HttpMethod.Put,
            null,
            bodyObj
        );
        return response.result;
    }

    private async refundClassesDeposit(transactionId: number, refundTransactionId: number, refundTransactionDetails: any){
        let bodyObj: DepositRefund[] = [];
        refundTransactionDetails.forEach(r => {
            bodyObj.push({
                appointmentId: 0,
                transactionId: transactionId,
                transactionDetailId: r.Id,
                refundTransactionId: refundTransactionId,
                depositRefundAmount: r.ProductPrice
            })
        });
        var response: BaseResponse<boolean[]> = await this.appointmentPopupService.InvokeServiceCallAsync(
            "RefundDepositWithClasses",
            GlobalConst.Host.schedule,
            HttpMethod.Put,
            null,
            bodyObj
        );
        return response.result;
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string) {
        if (callDesc == "v2GetImagesByReferenceId") {
            this.appointmentPopupService.clientImageChange(result.result);
        }
    }

    errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        console.error(error.result);
    }

    getChildMenu(url, menutype?) {
        return this.routeLoaderService.GetChildMenu(url, menutype);
    }

    async getAllContactLogType(includeinactive) {
           return await this.http.CallApiAsync<any[]>({
          host: GlobalConst.Host.spaManagement,
          callDesc: "GetAllLogType",
          method: HttpMethod.Get,
          uriParams: { "isIncludeInactive": includeinactive }
        });
      }
    async getAllLogType(includeinactive) {
      return this.logTypeBusiness.callAPIGetAllLog(includeinactive);
    }

    async CreateLogType(logtype) {
    return this.logTypeBusiness.callAPICreateLog(logtype);
    }

    async EditLogType(logtype, id) {
    return this.logTypeBusiness.callAPIEditLog(logtype, id);
    }

    async UpdateLogType(inputdata) {
    return this.logTypeBusiness.callAPIUpdateLog(inputdata);
    }

    async DeleteLogType(id) {
    return this.logTypeBusiness.callAPIDeleteLog(id);
    }
    async CheckLogTypeForAccess(){
        return this.BPoint.CheckForAccess([GlobalConst.SPAManagementBreakPoint.LogType], false);
      }
    async CheckLogTypeIsViewOnly(){
        return this.BPoint.IsViewOnly(GlobalConst.SPAManagementBreakPoint.LogType);
    }
    async GetConfirmationSetting()

    {
  
     return this.logTypeBusiness.setValue();
  
    }

    private async SendExportNotification(notifyModel: GuestExportNotificationModel): Promise<boolean> {
        var response: BaseResponse<boolean> = await this.appointmentPopupService.InvokeServiceCallAsync(
            "SendGuestExportNotification",
            GlobalConst.Host.schedule,
            HttpMethod.Put,
            false,
            notifyModel
        );
        return response.result;
    }

    private async getAllVipType(includeInactive) {
        return this.vipTypeBusiness.getAllVipType(includeInactive);
    }

    private async createVipType(vipType) {
        return this.vipTypeBusiness.createVipType(vipType);
    }

    private async updateVipType(vipType, id) {
        return this.vipTypeBusiness.updateVipType(vipType, id);
    }

    private async deleteVipType(id){
        return this.vipTypeBusiness.deleteVipType(id);
    }

    private async getNextListOrderofVipType(){
        return this.vipTypeBusiness.getNextListOrderofVipType();
    }

    private async dragDropVipType(fromOrder, toOrder, includeInactive){
        return this.vipTypeBusiness.dragDropVipType(fromOrder, toOrder, includeInactive);
    }

    private async GetVipTypeBreakpoint(){
        return UserAccessBreakPoints.VipType;
    }

    private async ValidateOutletReference(outletId:number){
        var response: BaseResponse<boolean> = await this.appointmentPopupService.InvokeServiceCallAsync(
            "IsOutletInUse",
            GlobalConst.Host.schedule,
            HttpMethod.Get,
            { outletId: outletId }
        );
        return response;
    }

    private async CheckAppointmentStatusByDayPassId(dayPassId :number) {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "CheckAppointmentStatusByDayPassId",
            GlobalConst.Host.schedule,
            HttpMethod.Get,
            { id: dayPassId }
        );
        return response.result;
    }
    private async UpdateIsActiveForDayPass(dayPassId :number) {
        var response = await this.appointmentPopupService.InvokeServiceCallAsync(
            "UpdateIsActiveForDayPass",
            GlobalConst.Host.schedule,
            HttpMethod.Put,
            { id: dayPassId }
        );
        return response.result;
    }

    private async SaveLockers(lockerAssignmentDetails: LockerAssignmentDetails, isUpdated?: boolean) {
        this.lockerService.SaveLocker(lockerAssignmentDetails, isUpdated)
    }

    private async IsCancelationNoShowEnabled() {
        const CancellationNoShowFeeSwitch = JSON.parse(sessionStorage.getItem('defaultSettings'))?.find(x => x.switch == GlobalConst.CancellationNoShowFeeSwitch);
        return CancellationNoShowFeeSwitch ? CancellationNoShowFeeSwitch.value.toLowerCase() == 'true' : false;
    }
}
