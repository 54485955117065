import { Injectable } from '@angular/core';
import { RetailFunctionalityService } from '../service/retail-functionality.service';

@Injectable({ providedIn: 'root' })
export class RetailFunctionalityBusiness {

    constructor(
        private retailFunctionalityService: RetailFunctionalityService
    ) {
    }

    public async getRetailFunctionality(): Promise<{}> {

        const productId = this.getProductId();
        const funcs = window.sessionStorage.getItem('_functionalities');
        if (funcs) {
            return JSON.parse(funcs);
        }

        const retailFunctionalities = await this.retailFunctionalityService.getRetailFunctionality(productId);
        const result = retailFunctionalities && retailFunctionalities.result;

        const mapped = {};
        for (const k of result.filter(x => x.isActive).map(x => x.functionality)) {
            mapped[k] = true;
        }

        window.sessionStorage.setItem('_functionalities', JSON.stringify(mapped));
        return mapped;
    }

    public async getDefaultOutlet(): Promise<number> {
        const data = await this.retailFunctionalityService.getDefaultOutlet();
        return data.result;
    }

    public getFunctionality(): {} {
        const funcs = window.sessionStorage.getItem('_functionalities');
        if (funcs) {
            return JSON.parse(funcs);
        }
    }

    private getProductId(): number {
        try {
        const propertyInfo = sessionStorage.getItem('propertyInfo');
        const productId = propertyInfo.split(';').map(x => ({ key: x.split('=')[0].trim(), value: x.split('=')[1] })).find(x => x.key === 'ProductId').value;
        return parseInt(productId, 10);
        } catch (ex) {
        }

        return 0;
    }

}
