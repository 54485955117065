<!-- <b>Agilysys Grid Module</b> -->
<div class="dynamic-grid">
        <div class="grid-actions" *ngIf="gridInput && gridInput.GridActions !== undefined" [ngClass]="filterEnable ? 'grid-actions-with-filter' : ''">
                <lib-grid-actions #gridAction [gridActions]="gridInput.GridActions" [gridItemListActions]="gridInput.GridItemListAction" (searchTextChanged)="searchTextChange($event)"
                (filterEnabled)="filterEnabled($event)" (filterApplied)="applyFilter($event)" (toggleChanged)="toggleChange($event)"
                (dropdownChanged)="dropdownChanged($event)" (addButtonClick)="addButtonClick($event)" (refreshButtonClick)="refreshButtonClick($event)"
                (iconActionClick)="iconActionClick($event)" (deactivateClick)="deactivateClick($event)" [rowData]="rowData" [ClearSearchFilter]="gridInput.ClearSearchFilter"
                (globalDeleteClick)="globaldeleteClick()" (fetchFilterData)="fetchFilterValues($event)"></lib-grid-actions>
        </div>
        <div id="{{gridInput.ParentId}}" *ngIf="gridInput" class="grid-section {{resizeRowClass}}" [ngClass]="{'calc-grid-section' : gridInput.GridActions &&
        gridInput.GridActions.PaginationConfig.EnabledPaginationation, 'grid-with-filter' : filterEnable, 'grid-with-focus' : gridInput.IsGridFocusEnabled}">
                <ag-grid-angular *ngIf="gridInput" class="ag-theme-material ag-grid-size"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [rowDragManaged]="true"
                        [columnDefs]="columnData"
                        [gridOptions]="gridOptions"
                        [overlayLoadingTemplate]="overlayLoadingTemplate"
                        (rowDataChanged)="rowDataChanged($event)"
                        (rowClicked)="rowClicked($event)" 
                        (selectionChanged)="onSelectionChanged($event)"
                        (rowValueChanged)="rowValueChanged($event)" 
                        [isRowSelectable]="RowSelectable"
                        (componentStateChanged)="componentChanged($event)" 
                        (cellValueChanged)="onCellValueChanged($event)"
                        (rowDragEnd)="rowDragEnded($event)"
                        (cellClicked)="onCellClicked($event)"
                        [popupParent]="popupParent"
                        >
                </ag-grid-angular>
        </div>
        <div class="grid-footer" *ngIf="gridInput.GridActions && gridInput.GridActions.PaginationConfig.EnabledPaginationation">
                
        </div>
</div>
<!--[ngClass]="!gridInput.showPagination || totalItems<=10 ? 'grid-size':''"
(rowClicked)="rowClicked($event)" 
(gridSizeChanged)="gridSizeChanged($event)"
(selectionChanged)="onSelectionChanged($event)"
(rowSelected) = "rowSelecteds($event)"
(componentStateChanged)="componentChanged($event)"
(rowValueChanged)="rowValueChanged($event)"
(rowEditingStopped)="rowEditingStopped($event)"
(cellClicked)="editStarted($event)"
[isRowSelectable]="isRowSelectable"
[getRowHeight]="getRowHeight"
[getRowClass]="getRowClass"
(gridColumnsChanged) = "gridColumnsChanged($event)"
(cellEditingStopped) ="cellEditingStopped($event)"
[suppressKeyboardEvent]="suppressKeyboardEvent"
(columnResized) ="columnResizedEvent($event)"
(rowDataChanged) ="rowDataChanged($event)" -->