import { Injectable } from "@angular/core";
import { IcartDataService } from "src/app/common/dataservices/icart.data.service";
import { CartWindowMessage, CartWindowMessageType } from "./vcart.modal";
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';

@Injectable(
  {
    providedIn: 'root'
  })

// This class is to handle all the window message communication between Cart product and embedded Iframes
export class VCartMessageHandler {
  constructor(private cartDataService: IcartDataService,private cartUtils: CartUtilities) {

  }

  processWindowMessage(message: CartWindowMessage) {
    if (message.sender == 'PARENT') {
      this.processParentMessage(message);
    } else {
      this.processChildMessage(message);
    }
  }

  // Message from Parent product who has the V-Cart to Child iframe will be processed here
  processParentMessage(message: CartWindowMessage) {
    switch (message.type) {
      case CartWindowMessageType.CartDataBroadcast:
        this.handleCartChangedEvent(message);
        break;
      case CartWindowMessageType.DataReloadNeeded:
        this.cartDataService.DataReloadRequired.next(true);
        break;
      case CartWindowMessageType.CartItinerarySelected:
        this.cartDataService.CartItinerarySelected.next(message.data);
        break;
      case CartWindowMessageType.CartItemEdited:
        this.cartDataService.CartItemEdited.next(message.data);
        break;
      case CartWindowMessageType.CartItemRemoved:
        this.cartDataService.CartItemRemoved.next(message.data);
        break;
      case CartWindowMessageType.CartItineraryRemoved:
        this.cartDataService.CartItineraryRemoved.next(message.data);
        break;
      case CartWindowMessageType.CartItineraryTransfered:
        this.cartDataService.CartItineraryTransfered.next(message.data);
        break;
      case CartWindowMessageType.CartResult:
        this.cartDataService.CartResult.next(message.data);
        break;
      case CartWindowMessageType.NewGuestCreateSelected:
        this.cartDataService.NewGuestCreateClicked.next(message.data);
        break;
      case CartWindowMessageType.RequestNewCard:
        this.cartDataService.RequestNewCard.next(message.data);
        break;
    }
    
  }

  handleCartChangedEvent(message: CartWindowMessage) {
    // Whenever cart changes, cart item details will be stored in the child product's session storage
    sessionStorage.setItem("CartItineraryList", JSON.stringify(message.data));
  }

  // Message from Child product (embeded in iframe) to Parent will be processed here
  processChildMessage(message: CartWindowMessage) {
    if (message.type == CartWindowMessageType.AddToCart) {
      this.cartDataService.CartItemAdded.next(message.data);
    } else if (message.type == CartWindowMessageType.UpdateCart) {
      this.cartDataService.CartItemUpdated.next(message.data);
    }
    else if(message.type == CartWindowMessageType.IFrameLoaded){
      this.cartUtils.publishBroadcastMessage();
      this.cartUtils.publishCartItinerarySelected();
    } else if(message.type == CartWindowMessageType.NewGuestCreated){      
      this.cartDataService.NewGuestCreated.next(message.data);
    }
  }
}
