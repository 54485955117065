import { ActionType } from 'src/app/shared/globalsContant';
import { AppointmentServiceInformation, GroupAppointment } from '../spa-wizard.modal';
import { AppointmentServiceInformationBusiness } from '../appointment-service-information/appointment-service-information.business';
import { appointmentTherapist } from 'src/app/shared/business/shared.modals';
import { Injectable } from '@angular/core';
import { SpaWizardService } from '../spa-wizard.service';
import { AppointmentUtilities } from 'src/app/shared/utilities/appointment-utilities';
import { SpaUtilities } from 'src/app/shared/utilities/spa-utilities';
import { uniq as _unique } from 'lodash';

@Injectable()
export class WizardClientBusiness {

  constructor(
    public asi: AppointmentServiceInformationBusiness,
    public appointmentUtilities: AppointmentUtilities,
    public spaWizardService: SpaWizardService,
    public utilities: SpaUtilities) {

  }

  public async buildAppointServiceInformationInput(appointments: GroupAppointment[], selectedAppointment: GroupAppointment): Promise<AppointmentServiceInformation> {
    try {
      let hasMultiGroupId = false; // refers if the selected appointment is multiClient
      let multiGroupId: string;
      let multiClientAppointments: GroupAppointment[];
      const singleClientAppointment: GroupAppointment = selectedAppointment;
      let clientIds: number[] = [];
      if (selectedAppointment && selectedAppointment.appointment.appointmentDetail.multiGroupId) {
        hasMultiGroupId = true;
        multiGroupId = selectedAppointment.appointment.appointmentDetail.multiGroupId;
      }

      if (hasMultiGroupId) {
        multiClientAppointments = appointments.filter(apt => apt.appointment.appointmentDetail.multiGroupId === multiGroupId);
        multiClientAppointments.forEach(gp => {
          if (gp.tempClientId && gp.tempClientId !== '' && gp.appointment.appointmentDetail.clientId === 0) {
            // Empty if COndition;
          } else {
            clientIds.push(gp.appointment.appointmentDetail.clientId);
          }
        });

      } else {
        if (singleClientAppointment.tempClientId) {
          clientIds = [];
        } else {
          clientIds.push(singleClientAppointment.appointment.appointmentDetail.clientId);
        }
      }
      const distinctClientIds = _unique(clientIds);
      return await this.asi.build(selectedAppointment, multiClientAppointments, distinctClientIds);
    } catch (ee) {
      console.error(ee);
      throw ee;
    }
  }

  public buildDataFields(selectedAppointment: GroupAppointment) {
    const appointmentDetail = selectedAppointment.appointment.appointmentDetail;
    const therapists = selectedAppointment.appointment.appointmentTherapists;
    return {
      clientId: appointmentDetail.clientId,
      price: appointmentDetail.price,
      clientMultiPackId: appointmentDetail.clientMultiPackId,
      appointmentId: appointmentDetail.id,
      serviceName: this.getServiceName(appointmentDetail.serviceId),
      therapistName: this.getTherapistName(therapists),
      startDTTM: appointmentDetail.startTime,
      EndDTTM: appointmentDetail.endTime,
      managementData: {
        Service: this.spaWizardService.serviceArray,
        Therapist: this.spaWizardService.therapistArray
      }
    };
  }


  public buildDataFieldschangesetup(fieldData: any, appointment: GroupAppointment) {
    fieldData.appointmentId = appointment.appointment.appointmentDetail.id;
    fieldData.setupTime = appointment.appointment.appointmentDetail.setUpTime;
    fieldData.breakdownTime = appointment.appointment.appointmentDetail.breakDownTime;
    fieldData.serviceName = this.getServiceName(appointment.appointment.appointmentDetail.serviceId);
    fieldData.appointmentDate = this.utilities.FormatAppointmentDateTime(appointment.appointment.appointmentDetail.startTime, appointment.appointment.appointmentDetail.endTime);

  }

  public buildDataFieldsCheckIn(selectedAppointment: GroupAppointment) {
    const appointmentDetail = selectedAppointment.appointment.appointmentDetail;
    const therapists = selectedAppointment.appointment.appointmentTherapists;
    return {
      clientId: appointmentDetail.clientId,
      price: appointmentDetail.price,
      clientMultiPackId: appointmentDetail.clientMultiPackId,
      appointmentId: appointmentDetail.id,
      serviceName: this.getServiceName(appointmentDetail.serviceId),
      therapistName: this.getTherapistName(therapists),
      LocationName: this.appointmentUtilities.GetLocationDescById(appointmentDetail.locationId),
      startDTTM: appointmentDetail.startTime,
      EndDTTM: appointmentDetail.endTime,
      locationId: appointmentDetail.locationId,
      managementData: {
        Service: this.spaWizardService.serviceArray,
        Therapist: this.spaWizardService.therapistArray
      },
      status: appointmentDetail.status,
      linkedAppointmentId: appointmentDetail.linkedAppointmentID
    };
  }

  public buildCancelAppointmentInformation() {
    return {
      id: ActionType.cancel,
      headername: 'Cancel Appointment',
      closebool: true,
      appointmentInfomation: { appointmentFrom: 'spaWizard' }
    };
  }

  public buildCheckInAppointmentInformation() {
    return {
      id: ActionType.checkin,
      headername: 'CheckIn Appointment',
      closebool: true,
      appointmentInfomation: { appointmentFrom: 'spaWizard' }
    };
  }

  getServiceName(id: number): string {
    const service = this.spaWizardService.serviceArray.find(s => s.id === id);
    if (service) {
      return service.description;
    }
    return '-';
  }

  getTherapistName(therapists: appointmentTherapist[]): string {
    const assignedTherapistIds = therapists.map(x => {
      return x.therapistId;
    });
    const name: string[] = [];
    const result = this.spaWizardService.therapistArray.filter(r => assignedTherapistIds.includes(r.id));
    if (result && result.length > 0) {
      result.forEach(t => {
        name.push(`${t.firstName} ${t.lastName}`);
      });
    }
    return name.join(',');
  }

  async deleteTempHold(selectedAppointment: GroupAppointment[]): Promise<any> {
    try {
      const tempIds = selectedAppointment.map(x => x.appointment.appointmentDetail.id);
      return await this.asi.deleteTempHold(tempIds);
    } catch (ee) {
      console.error(ee);
      throw ee;
    }
  }
}
