import { Injectable } from '@angular/core';
import { CommonVariablesService } from '../service/common-variables.service';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import * as GlobalConst from '../globalsContant';
import { ShopBussinessService } from '../../shop/shop-business.service';
import { PaymentMethods } from './shared.modals';
import { RetailUtilities } from '../utilities/retail-utilities';
import _ from 'lodash';
import { PaymentHistory, TransactionPayment, TransactionPaymentAggregate } from '../service/payment/payment-business.model';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialog } from '@angular/material/dialog';
import { ShopDialogPopUp } from '../../shop/shop-dialog-popup/shop-dialog-popup.component';
import { WalletRequestBody, WalletResponse } from './wallet.model';
import { RetailRoutes } from '../../retail-route';

@Injectable()
export class WalletBusinessService {

    private selectedWallet: TransactionPaymentAggregate[] = [];

    constructor(
        private _localization: RetailLocalization,
        private http: HttpServiceCall,
        public dialog: MatDialog,
        private utils: RetailUtilities,
        public _ss: CommonVariablesService

    ) { }

    /**
    * @description Performs Wallet Redemption
    * @param cardNumber
    * @param amount
    * @param retailTicketNumber
    * @param tenderId
    * @param paymentMethodId
    * @returns WalletResponse
    */
    async RedeemWallet(cardNumber: any, amount: number, retailTicketNumber: any, tenderId: number, paymentMethodId: number): Promise<WalletResponse> {
        let postRes: WalletResponse;
        const postRequest: WalletRequestBody = {
            cardNumber: cardNumber,
            amount: amount,
            retailTicketNumber: retailTicketNumber,
            tenderId: tenderId,
            paymentMethodId: paymentMethodId,
        };
        const response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.payment,
            callDesc: RetailRoutes.RedeemWallet,
            method: HttpMethod.Post,
            uriParams: { outletId: this._ss.SelectedOutletId },
            body: postRequest
        });
        if (response.successStatus) {
            postRes = response.result;
        }

        return postRes;
    }

    /**
    * @description Performs Wallet Fund
    * @param $scope
    * @param compScope
    * @param formGrpObj
    */
    async RefundExistingWalletPayments($scope: ShopBussinessService, compScope, formGrpObj) {

        let actualRefundAmount = $scope.getSaleAmt(true);

        let walletTransactions = _.cloneDeep($scope.TransactionPaymentsAggregate.filter(x => this.utils.GetOriginalTenderId(Number(x.transactionPayment.paymentMethod), x.transactionPayment.parentTenderId) == PaymentMethods.Wallet && x.additionalInformation.Amount > 0));
        let uniqWalletTransactions = _.uniqWith(walletTransactions,
            (x, y) => x.additionalInformation.WalletNumber == y.additionalInformation.WalletNumber
                && x.transactionPayment.id == y.transactionPayment.id);

        let walletTransactionPayments = _.cloneDeep($scope.TransactionPayments.filter(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.Wallet && x.paymentAmount > 0));
        let uniqwalletTransactionPayment = _.uniqWith(walletTransactionPayments,
            (x, y) => x.id == y.id);

        let prevSettlementWithWallet: number = 0;
        if (uniqwalletTransactionPayment && uniqwalletTransactionPayment.length > 0 && uniqwalletTransactionPayment.some(x => Number(x.paymentMethod) == PaymentMethods.Wallet)) {
            uniqwalletTransactionPayment.filter(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.Wallet).forEach(x => prevSettlementWithWallet += x.paymentAmount);
        }

        let fullWalletReturnFlow = (actualRefundAmount >= prevSettlementWithWallet && $scope.FullPayment); //Proceed to Refund

        const saleNotStarted = (!$scope.ReturnWithTicketResponse && ($scope._ss.finalAmount == $scope.remainingAmount));
        const flowStartedButRemaining = ($scope.ReturnWithTicketResponse && ($scope._ss.finalAmount >= $scope.remainingAmount));
        uniqWalletTransactions = uniqWalletTransactions.filter(x => x.additionalInformation.Amount > 0); //After each refund process amount gets reduced, filtering remaining active transns

        if (uniqWalletTransactions.length > 1 && !fullWalletReturnFlow && (saleNotStarted || flowStartedButRemaining)) {
            this.ShowWalletSelectionPopup(uniqWalletTransactions, $scope, formGrpObj, compScope);
        } else {
            this.selectedWallet = [];
            let transtoRefund = $scope.TransactionPayments.filter(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.Wallet)
            this.ProceedRefund($scope, formGrpObj, compScope, transtoRefund);
        }
    }

    /**
     * @description This method is to perform the Refund and then continue/close the flow
     * @param $scope 
     * @param formGrpObj 
     * @param compScope 
     * @param transInfo 
     */
    async ProceedRefund($scope: ShopBussinessService, formGrpObj, compScope, transInfo: TransactionPayment[]) {

        //If user selects wallet as initial payment method then perform return first
        if (compScope && !$scope.ReturnWithTicketResponse) {
            compScope.ReturnwithTicket();
            return;
        }

        $scope._ams.loaderEnable.next($scope.ShopCaptions.RefundToOriginalSettlementsInProgress);
        let settlementHistory: any[] = [];
        const amountNeedToBeRefunded = Math.abs($scope.getAmountPaid(formGrpObj));

        let response: any[] = await this.VoidWalletPayment($scope, transInfo);
        settlementHistory = settlementHistory.concat(response);
        
        if (settlementHistory && settlementHistory.length > 0) {
            let refundedAmount: number = 0;
            settlementHistory.filter(x => x.refunded).forEach(x => refundedAmount += x.refundAmount);
            settlementHistory.forEach(settlement => {
                if (settlement.refunded) {
                    this.AddSettlementHistory($scope, settlement);
                }
            });

            $scope.ShowSettlemtHist = true;
            let remainingAmount = this.CalculateRemainingAmount($scope);

            //Switch to multi payment if we remaining amt to be refunded and No need for any CMS sale
            if (amountNeedToBeRefunded >= refundedAmount && remainingAmount != 0) {
                //Close the loader
                setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);
                //After adding settlement history update the buttons
                $scope.CheckSettlementStatusAndUpdateButtons(compScope);
                $scope.SwitchToMultiPayment('', formGrpObj, true);
            }

            // If none of the prev cond met then check is settled call TenderRetainData 
            else {
                //Close the loader
                setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);

                compScope.TenderRetainData();
                //After adding settlement history update the buttons
                $scope.CheckSettlementStatusAndUpdateButtons(compScope);
            }

            console.log(settlementHistory);
        }
    }

    /**
     * @description This method is to perform the Wallet Void operation
     * @param $scope 
     * @param transInfo 
     */
    async VoidWalletPayment($scope: ShopBussinessService, transInfo: TransactionPayment[]) {
        let settlementHistory = [];

        let response: any = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.retailPOS,
            callDesc: RetailRoutes.VoidWallet,
            method: HttpMethod.Put,
            uriParams: {
                transactionId: $scope.ReturnWithTicketResponse.transactionData.id,
            },
            body: transInfo
        });
        if (response.successStatus) {
            settlementHistory = response.result;
        }
        return settlementHistory;
    }


    ValidateWalletMethodsActivation($scope: ShopBussinessService) {
        let collectFlow: boolean = ($scope._ss.finalAmount > 0);
        const walletExistInOriginalTrans = $scope.GetActiveTransactionPayments($scope.TransactionPayments).some(t => this.utils.GetOriginalTenderId(Number(t.paymentMethod), t.parentTenderId) == PaymentMethods.Wallet);
        if (!collectFlow && $scope.TransactionPayments.length > 0 && !walletExistInOriginalTrans) {
            this.EnableAllPaymentMethodsAndRemoveWallet($scope, PaymentMethods.Wallet);
        } 
    }

    EnableAllPaymentMethodsAndRemoveWallet($scope: ShopBussinessService, walletMethod: PaymentMethods) {
        $scope.paymentMethods = $scope.paymentMethods.filter(x => this.utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) != walletMethod);
        if ($scope.selectedpayment && $scope.SelectedPaymentMethodEquals(walletMethod)) {
            $scope.ResetSelectedPaymentMethod();
        }
        $scope.paymentMethods.forEach(x => { x.isDisabled = false; });
    }

    CheckIfWalletCanBeAppliedAndPrefill($scope: ShopBussinessService) {
        let allowedToProceed: boolean = true;
        let refundAmt = 0;

        let uniqwalletTransactionPayment = _.uniqWith($scope.TransactionPayments,
            (x, y) => x.id == y.id);

        let prevSettlementWithWallet: number = 0;

        if (uniqwalletTransactionPayment.some(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId)) {
            const remainingAmt = Math.abs($scope.remainingAmount);

            if (uniqwalletTransactionPayment && uniqwalletTransactionPayment.length > 0 && uniqwalletTransactionPayment.some(x => Number(x.paymentMethod) == PaymentMethods.Wallet)) {
                uniqwalletTransactionPayment.filter(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.Wallet).forEach(x => prevSettlementWithWallet += x.paymentAmount);
            }

            refundAmt = remainingAmt < prevSettlementWithWallet ? remainingAmt : prevSettlementWithWallet;
            if (Math.abs(prevSettlementWithWallet) > remainingAmt) {
                allowedToProceed = false;
                const errMsg = $scope.localization.replacePlaceholders(
                    $scope.localization.getError(100028)
                    , ["paymentType"]
                    , ["Wallet"]
                );
                $scope.utils.ShowErrorMessage($scope.localization.captions.common.Error, errMsg);
                refundAmt = prevSettlementWithWallet;
            }
        }

        return [allowedToProceed, refundAmt];
    }

    MaskWalletNumber(input: any) {
        const mask = (cc, num = 4, mask = 'X') =>
            ('' + cc).slice(0, -num).replace(/./g, mask) + ('' + cc).slice(-num);

        return mask(input);
    }


    ShowWalletSelectionPopup(transAdditionalData: TransactionPaymentAggregate[], $scope: ShopBussinessService, formGrpObj, compScope) {
        //Close the loader
        setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);
        const dialogRef = this.dialog.open(ShopDialogPopUp, {
            width: '500px',
            height: 'auto',
            maxHeight: '700px',
            disableClose: true,
            hasBackdrop: true,
            data: {
                isEdit: false,
                headername: this._localization.captions.shop.Wallet.WalletSelectionDialog.header,
                closebool: true,
                templatename: 'Wallet',
                data: transAdditionalData
            },
            panelClass: 'shop-payment'
        });

        dialogRef.afterClosed().subscribe(walletInfo => {
            if (walletInfo) {
                let walletTransactionPayments = _.cloneDeep($scope.TransactionPayments.filter(x => this.utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.Wallet && x.paymentAmount > 0));
                let uniqwalletTransactionPayment = _.uniqWith(walletTransactionPayments,
                    (x, y) => x.id == y.id);

                let selectedTransactionPaymentsToRefund: TransactionPayment[] = [];
                walletInfo.forEach(x => {
                    let selectedTransactionPaymentToRefund = uniqwalletTransactionPayment.find(y => y.id == x.transactionPayment.id)
                    if (selectedTransactionPaymentToRefund) {
                        selectedTransactionPaymentsToRefund.push(selectedTransactionPaymentToRefund);
                    }
                });

                this.ProceedRefund($scope, formGrpObj, compScope, selectedTransactionPaymentsToRefund);
            }
        });
    }

    CalculateRemainingAmount($scope) {
        let remainingAmount = 0;
        let SettledAmt = 0;
        $scope.SettlementHistory.map((settlemt) => SettledAmt += $scope.RoundOffTwo(settlemt.amount));
        if ($scope.IsRefundFlow && $scope.getSaleAmt() < 0)
            remainingAmount = -$scope.RoundOffTwo(($scope.getSaleAmt(true) - (isNaN(SettledAmt) ? 0 : SettledAmt) * -1));
        else
            remainingAmount = $scope.RoundOffTwo($scope.getSaleAmt(true) - (isNaN(SettledAmt) ? 0 : SettledAmt));
        return remainingAmount;
    }

    AddSettlementHistory($scope: ShopBussinessService, settlementData: any) {
        let maskedCardNumber = this.MaskWalletNumber(settlementData.cardNumber);
        let SettleMentObject: PaymentHistory = {
            paymentMethodId: $scope.selectedpayment.paymentTypeId,
            parentTenderId: $scope.selectedpayment?.parentTypeId,
            paymentMethod: $scope.FormatPaymentMethodLabelForSettlementHistory($scope.selectedpayment,
                $scope.paymentMethods, null, null, null, null, null, null, null, null, maskedCardNumber),
            amount: -settlementData.refundAmount,
            paymentMethodInfo: $scope.selectedpayment,
            taxExemptRatio:  this._ss.Ticket.compTaxExemptRatio,
            tenderReducesDiscount: $scope.selectedpayment.tenderReducesDiscount,
            tenderReducesTax:  $scope.selectedpayment.isAutoRemoveTax,
            discountExemptRatio:  this._ss.Ticket.tenderReducesDiscountRatio
        }

        $scope.SettlementHistory.unshift(SettleMentObject);
    }
}
