import { ButtonValue, AgInputFieldConfig, AgDateConfig, AgDropdownConfig } from 'src/app/common/Models/ag-models';
import { Input, Output, Component, OnInit, Inject, ViewEncapsulation, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormGroup } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { ActionMode, AlertType } from 'src/app/common/shared/shared/enums/enums';
import { of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { PolicyType,TeeTimePriceType,ChargeType,ApplyWithinType } from '../cancellation-no-show-policy.model';

@Component({
  selector: 'app-create-cancellation-no-show-policy',
  templateUrl: './create-cancellation-no-show-policy.component.html',
  styleUrls: ['./create-cancellation-no-show-policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateCancellationNoShowPolicyComponent implements OnInit {
  cancellationForm: UntypedFormGroup;
  charges: FormArray;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  policyCodeConfig: AgInputFieldConfig;
  policyNameConfig: AgInputFieldConfig;
  policyDescriptionConfig: AgInputFieldConfig;
  codeMaxLength: number = 10;
  PolicyTypeEnum = PolicyType;
  TeeTimePriceEnum = TeeTimePriceType;
  ChargeTypeEnum = ChargeType;
  floatLabel: string;
  addChargeButton: ButtonValue;
  ApplyWithinEnum = ApplyWithinType;
  isCancellationTabSelected: boolean = true;
  cancellationDropDownInput: AgDropdownConfig
  noShowDropDownInput: AgDropdownConfig
  applyWithinHoursDaysMaxLength: number = 9;
  @Input() inputs;
  @Input() isViewOnly = true;
  @Input() chargeOptions : any[] = [];
  @Output() formReady = new EventEmitter();
  @Output() handleClickEvent = new EventEmitter();
  isDuplicateAvailable:boolean = false;

  constructor(private localization: Localization,
    private fb: UntypedFormBuilder, private utils: CommonUtilities,) { }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.formGenerator();
    this.cancellationForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.updateActionButtonState();
    });
    if (this.isViewOnly) {
      this.disableControls();
    }
  }

  updateActionButtonState() {
    let isPolicyActive = this.cancellationForm.controls.isCancellationActive.value || this.cancellationForm.controls.isNoShowActive.value;
    this.actionButton.disabledproperty = this.isViewOnly || !(this.cancellationForm.dirty && this.cancellationForm.valid && isPolicyActive);
  }

  private disableControls() {
    this.utils.disableControls(this.cancellationForm, ["policyType"]);
      this.policyCodeConfig.disabled = true;
      this.policyCodeConfig = {...this.policyCodeConfig};
      this.policyNameConfig.disabled = true;
      this.policyNameConfig = {...this.policyNameConfig};
      this.policyDescriptionConfig.disabled = true;
      this.policyDescriptionConfig = {...this.policyDescriptionConfig};
      this.actionButton.disabledproperty = true;
      this.actionButton = {...this.actionButton };
      this.addChargeButton.disabledproperty = true;
      this.addChargeButton = {...this.addChargeButton };
  }

  formGenerator(){
    this.cancellationForm = this.fb.group({
      id: 0,
      policyType: this.PolicyTypeEnum.Cancellation,
      policyCode: '',
      policyName: '',
      policyDescription : '',
      isActive: true,
      noShowPriceType: this.TeeTimePriceEnum.FullPrice,
      noShowChargeType: this.ChargeTypeEnum.Percentage,
      noShowCharge: 0,
      isCancellationActive: true,
      cancellationIncludeCharges: '',
      isNoShowActive: false,
      noShowIncludeCharges: '',
      noShowDetailsId: 0, 
      charges: this.fb.array([
        this.fb.group({
          id: 0,
          isCancellationPolicy: true,
          applyWithin: this.ApplyWithinEnum.Days,
          applyWithinHoursDays: [0, Validators.required],
          priceType: this.TeeTimePriceEnum.FullPrice,
          chargeType: this.ChargeTypeEnum.Percentage,
          charge: 0,
        })
      ])
    });

    this.cancellationForm.get('policyType')?.valueChanges.subscribe(() => {
      this.cancellationForm.get('policyType')?.markAsPristine();
    });

    this.policyCodeConfig = {
      form: this.cancellationForm,
      formControlName: 'policyCode',
      placeHolder: this.captions.tbl_hdr_policyCode,
      maxlength: this.codeMaxLength,
      showRequired: true,
      errorMessage: this.captions.missing_policyCode,
      inputType: 'capitalise,notallowspace,nospecailchar',
      hideplaceholder: true,
      disabled: this.inputs.mode == ActionMode.update
    };
    this.policyNameConfig = {
      form: this.cancellationForm,
      formControlName: 'policyName',
      placeHolder: this.captions.tbl_hdr_policyName,
      maxlength: 80,
      showRequired: true,
      inputType: 'noprespace',
      errorMessage: this.captions.missing_policyName,
      hideplaceholder: true
    };
    this.policyDescriptionConfig = {
      form: this.cancellationForm,
      formControlName: 'policyDescription',
      placeHolder: this.captions.policyDescription,
      maxlength: 200,
      showRequired: false,
    };
    this.actionButton = {
      type: "primary",
      label: this.inputs.mode === ActionMode.update ? this.captions.btn_update : this.captions.btn_save,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.addChargeButton = {
      type: "secondary",
      label: this.captions.btn_addCharge,
    }
    this.cancellationDropDownInput = {
      form: this.cancellationForm,
      formControlName: 'cancellationIncludeCharges',
      selectOptions: of(this.chargeOptions),
      isMultiSelect: true,
      placeHolder: this.captions.lbl_includeCharges,
      isAll: true,
      isAllSelected: false,
      dropdownWithSearch: false
    }
    this.noShowDropDownInput = {
      form: this.cancellationForm,
      formControlName: 'noShowIncludeCharges',
      selectOptions: of(this.chargeOptions),
      isMultiSelect: true,
      placeHolder: this.captions.lbl_includeCharges,
      isAll: true,
      isAllSelected: false,
      dropdownWithSearch: false
    }
    if (this.inputs != null) {
      if (this.inputs.mode == ActionMode.update) {
        this.patchFormValues(this.inputs.form);
      }
      if (this.inputs.mode == ActionMode.copy) {
        this.inputs.form.code = '';
        this.patchFormValues(this.inputs.form);
      }
    }
    this.disableCancellationControls(this.cancellationForm.controls.isCancellationActive.value);
    this.disableNoShowControls(this.cancellationForm.controls.isNoShowActive.value);
  }

  patchFormValues(data: any){
    var cancellationRecord = data.cancellationNoShowDetails?.find(c => c.isCancellationPolicy);
    var noShowRecord = data.cancellationNoShowDetails?.find(c => !c.isCancellationPolicy);
    this.isCancellationTabSelected = cancellationRecord != null && cancellationRecord.isActive;
    this.cancellationForm.patchValue({
      id: data.id, 
      policyCode: data.code,
      policyName: data.name,
      policyDescription : data.description,
      policyType : this.isCancellationTabSelected ? this.PolicyTypeEnum.Cancellation : this.PolicyTypeEnum.NoShow,
      isActive: data.isActive,
      noShowPriceType: noShowRecord != null ? (noShowRecord.isFullPrice ? TeeTimePriceType.FullPrice : TeeTimePriceType.CustomPrice): TeeTimePriceType.FullPrice,
      noShowChargeType: noShowRecord != null ? (noShowRecord.isPercentage ? ChargeType.Percentage : ChargeType.Amount): ChargeType.Percentage,
      noShowCharge: this.localization.localizeCurrency(noShowRecord != null ? (noShowRecord.charge): 0, false, 2),
      isCancellationActive: cancellationRecord != null ? (cancellationRecord.isActive): false,
      cancellationIncludeCharges: cancellationRecord != null && cancellationRecord.includeCharges != null ? JSON.parse(cancellationRecord.includeCharges): '',
      isNoShowActive: noShowRecord != null ? noShowRecord.isActive : false,
      noShowIncludeCharges: noShowRecord != null && noShowRecord.includeCharges != null ? JSON.parse(noShowRecord.includeCharges): '',
      noShowDetailsId: noShowRecord != null ? noShowRecord.id : 0, 
    });

    this.charges = this.cancellationForm.get('charges') as FormArray;
    this.charges.clear();
    data.cancellationNoShowDetails.forEach(charge => {
      if(charge.isCancellationPolicy)
      {
      this.charges.push(this.fb.group({
        id: charge.id,
        isCancellationPolicy: charge.isCancellationPolicy,
        isActive: charge.isActive,
        applyWithin: charge.isApplyWithinDays ? ApplyWithinType.Days : ApplyWithinType.Hours,
        applyWithinHoursDays: [charge.applyWithinDuration, Validators.required],
        priceType: charge.isFullPrice ? TeeTimePriceType.FullPrice : TeeTimePriceType.CustomPrice,
        chargeType: charge.isPercentage ? ChargeType.Percentage : ChargeType.Amount,
        charge: this.localization.localizeCurrency(charge.charge, false, 2),
      }));
      }
    });

  }

  addCharge(e){
    const controls=this.fb.group({
      id: 0,
      isCancellationPolicy: true,
      applyWithin: this.ApplyWithinEnum.Days,
      applyWithinHoursDays: [0, Validators.required],
      priceType: this.TeeTimePriceEnum.FullPrice,
      chargeType: this.ChargeTypeEnum.Percentage,
      charge: 0,
    })
    let array = this.cancellationForm.controls.charges as UntypedFormArray;
    array.push(controls);
    this.disableCancellationControls(this.cancellationForm.get('isCancellationActive').value);
    this.cancellationForm.markAsDirty();
    this.cancellationForm.updateValueAndValidity();
  }

  checkValue(){
    //To remove
  }

  onDeleteRow(index){
    let array = this.cancellationForm.controls.charges as UntypedFormArray;
    array.removeAt(index);
    this.cancellationForm.markAsDirty();
    this.cancellationForm.updateValueAndValidity();
  }

  policyTypeChange(e){
    this.isCancellationTabSelected = e.value == PolicyType.Cancellation;
  }

  onActiveChange(e, isCancellationPolicy: boolean) {
    if (isCancellationPolicy) {
      this.disableCancellationControls(e[0]);
    }
    else
    {
      this.disableNoShowControls(e[0]);
    }
  }

  disableNoShowControls(isActive: boolean) {
    if (isActive) {
      this.cancellationForm.controls.noShowIncludeCharges.enable();
      this.cancellationForm.controls.noShowPriceType.enable();
      this.cancellationForm.controls.noShowChargeType.enable();
      this.cancellationForm.controls.noShowCharge.enable();
      this.cancellationForm.updateValueAndValidity();
    }
    else {
      this.cancellationForm.controls.noShowIncludeCharges.disable();
      this.cancellationForm.controls.noShowPriceType.disable();
      this.cancellationForm.controls.noShowChargeType.disable();
      this.cancellationForm.controls.noShowCharge.disable();
      this.cancellationForm.updateValueAndValidity();
    }
  }

  disableCancellationControls(isActive: boolean) {
    const chargesArray = this.cancellationForm.get('charges') as FormArray;
    chargesArray.controls.forEach((control: FormGroup) => {
      const applyWithinHoursDaysControl = control.get('applyWithinHoursDays');
      if (isActive) {
        this.cancellationForm.get('charges').enable();
        //applyWithinHoursDaysControl.setValidators([Validators.required, Validators.min(1)]);
        //applyWithinHoursDaysControl.markAsTouched();
        this.cancellationForm.controls.cancellationIncludeCharges.enable();
        this.cancellationForm.controls.cancellationIncludeCharges.updateValueAndValidity();
        this.addChargeButton.disabledproperty = false;
        this.addChargeButton = {...this.addChargeButton };
      }
      else {
        this.cancellationForm.get('charges').disable();
        //applyWithinHoursDaysControl.clearValidators();
        this.cancellationForm.controls.cancellationIncludeCharges.disable();
        this.cancellationForm.controls.cancellationIncludeCharges.updateValueAndValidity();
        this.addChargeButton.disabledproperty = true;
        this.addChargeButton = {...this.addChargeButton };
      }
      applyWithinHoursDaysControl.updateValueAndValidity();
    });
  }

  onAction(e){
    if(this.validateApplyWithin()){
    const clickReturnValue = {
      from: this.inputs.mode,
      formValues: this.cancellationForm.getRawValue(),
      form: this.cancellationForm
    };
    this.handleClickEvent.emit(clickReturnValue);
  }
  }

  onCancel(eve) {
    const clickReturnValue = {
      from: ActionMode.cancel,
      formValues: this.cancellationForm.getRawValue(),
      form: this.cancellationForm
    };
    if (this.cancellationForm.dirty) {
      this.utils.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res == AlertAction.YES) {
          this.handleClickEvent.emit(clickReturnValue);
        }
      })
    }
    else {
      this.handleClickEvent.emit(clickReturnValue);
    }
  }

  validateApplyWithin(id?) {
    let charges = this.cancellationForm.get('charges') as FormArray;
    let chargeValues = charges.value;
    let applyWithinHours: Set<number> = new Set();
    if (chargeValues?.length > 1) {
      chargeValues.forEach(c => {
        let applyWithin = c.applyWithinHoursDays;
        if (c.applyWithin == ApplyWithinType.Days)
          applyWithin *= 24; // multiply by 24 to convert days to hours
        applyWithinHours.add(applyWithin);
      })
      this.isDuplicateAvailable = applyWithinHours.size < chargeValues.length;
      if (this.isDuplicateAvailable) {
        this.utils.showError(this.captions.err_DuplicateApplyWithin);
      }
    }
    return !this.isDuplicateAvailable;
  }

  getFormControlValue(event) {
    this.cancellationForm.setControl('cancellationIncludeCharges', event[1]);
  }

  cancellationPriceTypeChange(index: number) {
    const chargesGroup = this.getChargesFormGroupForIndex(index);
      if (chargesGroup) {
        const chargeControl = chargesGroup.get('charge');
        chargeControl?.setValue(0);
      }
    }

  noShowPriceTypeChange(){
    const noShowCharge = this.cancellationForm.get('noShowCharge');
    noShowCharge?.setValue(0);
  }

  cancellationApplyWithinChange(index: number){
    const chargesGroup = this.getChargesFormGroupForIndex(index);
    if(chargesGroup){
      const chargeControl = chargesGroup.get('applyWithinHoursDays');
      chargeControl?.setValue(0);
    }
  }

  getChargesFormGroupForIndex(index: number): FormGroup{
    const chargesArray = this.cancellationForm.get('charges') as FormArray;
    if(chargesArray) {
      const chargesGroup = chargesArray.at(index) as FormGroup;
      return chargesGroup;
    }
    return null;
  }

  restrictApplyWithinLength(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    if (input.value.length >= this.applyWithinHoursDaysMaxLength && event.key != 'Backspace' && event.key != 'Delete')
      event.preventDefault();
  }
  
  teeTimeSelectionChange(e,i){
    if(e.value == TeeTimePriceType.FullPrice){
      const chargesGroup = this.getChargesFormGroupForIndex(i);
      chargesGroup.controls.chargeType.setValue(ChargeType.Percentage);
      const chargeControl = chargesGroup.get('charge');
        chargeControl?.setValue(0);
    }
  }
}
