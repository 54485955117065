import { TranslateService } from '@ngx-translate/core';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { GridColumnReorder, GridFilter, GridInfiniteScrollConfig, GridSearchConfig } from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { GridActionsConfig, GridFilterAction, GridRowActionRenderer, GridSearchAction, TitleType, TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridRowActionsConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any, 
    ViewDetailsEntity: any, 
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity ?: any, responseData ?: Array<any> ): GridActionsConfig => {
        try {
            if ( ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
                const entityName = ViewHeaderEntity[0]['$']['Name'].toString();
                const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                const GridActionObject = {} as GridActionsConfig;
                GridActionObject.GridTitle = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridName;
                GridActionObject.TotalItems = responseData.length;
                GridActionObject.EnableToggle = TransactionCollection.ViewTranscationConfig.GridConfiguration.ShowActiveToggle;
                GridActionObject.ShowActiveIndication = TransactionCollection.ViewTranscationConfig.GridConfiguration.ShowActiveIndication;
                GridActionObject.SearchConfig = {} as GridSearchConfig;
                GridActionObject.SearchConfig.EnabledSearch = true;
                GridActionObject.SearchConfig.SearchPlaceholder = GetSearchPlacholderName( entityName, productremapJson, translateService,
                TransactionCollection.ViewTranscationConfig.GridConfiguration.GridSearch);
                GridActionObject.FilterConfig = {} as GridFilter;
                GridActionObject.FilterConfig.EnableFilter = EnableFilterIcon(
                TransactionCollection.ViewTranscationConfig.GridConfiguration.GridFilter, []);
                if (TransactionCollection.ViewTranscationConfig.GridConfiguration.GridFilter.some(x => x.LoadOnClick)) {
                    GridActionObject.FilterConfig = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridActions.FilterConfig;
                }
                GridActionObject.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
                GridActionObject.InfiniteScrollConfig.EnableInfiniteScroll = true;
                GridActionObject.InfiniteScrollConfig.TotalRecords = responseData.length;
                GridActionObject.ShuffleColumnConfig = {} as GridColumnReorder;
                GridActionObject.ShuffleColumnConfig.GridName = ViewHeaderEntity[0]['$']['Name'];
                GridActionObject.ShuffleColumnConfig.EnableColumnShuffle = true;
                if (TransactionCollection.OdataKey === 'VendorPunchout') {
                    GridActionObject.EnableRefresh = true;
                }
                GridActionObject.GridTitleType = {} as TitleType;
                if (TransactionCollection.ViewTranscationConfig.GridConfiguration.GridTitleType) {
                    GridActionObject.GridTitleType.Type = TransactionCollection.ViewTranscationConfig.GridConfiguration.GridTitleType.Type;
                    GridActionObject.GridTitleType.TypeConfig = 
                    TransactionCollection.ViewTranscationConfig.GridConfiguration.GridTitleType.TypeConfig;
                } else {
                    GridActionObject.GridTitleType.Type = 'text';
                    GridActionObject.GridTitleType.TypeConfig = [];
                }
                return GridActionObject;
            } 
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

    function GetSearchPlacholderName(entityName, productremapJson, translateService, GridSearchArray: GridSearchAction[]) {
        try {
            let SearchPlaceholder = 'Search by ';
            const SearchKeys = [];
            GridSearchArray.forEach(search => {
                SearchKeys.push(search.SearchPlaceholderName);
            });
            const SearchKey = ProductDataRemap.FieldTranslateRemapEngine(
                        productremapJson, entityName, SearchKeys.join(','), translateService);
            SearchPlaceholder = SearchPlaceholder + SearchKey;
            return SearchPlaceholder;
        } catch (error) {
            console.error('Error occurred in GetSearchPlacholderName', error);
        }
    }
    
    function EnableFilterIcon(GridFilters: GridFilterAction[], ResponseData: Array<any>) {
        try {
            if (ResponseData.length > 0 && GridFilters.length > 0) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error occurred in GetSearchPlacholderName', error);
        }
    }