import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  transform(str: any, args: unknown[]): unknown {
    if (str) {
      const returnValue = str.replaceAll(' ', '');
      return returnValue;
    } else {
      return str;
    }
  }

}
