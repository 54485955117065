import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall } from "../../shared/service/http-call.service";
import { Outlet, SubPropertyModel, OutletsCRUD, BaseResponse, ReportAPIOptions } from "../../retail.modals";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { Host } from "../../shared/globalsContant";
import { StoreTerminal } from '../../shared/business/shared.modals';
import { ReportAPIModel } from "../../retail-reports/business/report.modals";

@Injectable({ providedIn: 'root' })
export class SubPropertyDataService implements OutletsCRUD {


    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization) { }

    public async getOutlets(): Promise<Outlet[]> {
        let result = await this.invokeOutletCalls<SubPropertyModel[]>("GetOutletsByProperty", HttpMethod.Get, undefined,
            { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')) });
        return this.mapSubpropertyToOutlet(result);
    }

    public async getOutletsByPropertyAndProduct(): Promise<Outlet[]> {
        let result = await this.invokeOutletCalls<SubPropertyModel[]>("GetOutletsByPropertyAndProduct", HttpMethod.Get, undefined,
        { propertyId: Number(this.localization.GetPropertyInfo('PropertyId')) , productId : Number(this.localization.GetPropertyInfo('ProductId'))}
        );
        return this.mapSubpropertyToOutlet(result);
    }

    public async updateOutlet(outlet: SubPropertyModel): Promise<Outlet[]> {
        let result = await this.invokeOutletCalls<SubPropertyModel[]>("UpdateOutletsOfProperty", HttpMethod.Put, outlet,
        { id: outlet.subPropertyID });
        return this.mapSubpropertyToOutlet(result);
    }
    public async deleteOutlet(outlet: SubPropertyModel) {
        let result = await this.invokeOutletCalls<SubPropertyModel[]>("RemoveOutletsOfProperty", HttpMethod.Delete, undefined,
            { Id: outlet.subPropertyID });
        return this.mapSubpropertyToOutlet(result);
    }

    public async createOutlet(body: SubPropertyModel): Promise<Outlet[]> {
        const subPropBody_Auth: SubPropertyModel = { ...body };
        subPropBody_Auth.subPropertyName = subPropBody_Auth.subPropertyName.trim();
        delete subPropBody_Auth.CCTerminalId;
        let result = await this.invokeOutletCalls<SubPropertyModel[]>("AddOutletsToProperty", HttpMethod.Post, subPropBody_Auth);
        return this.mapSubpropertyToOutlet(result);
    }

    async GetUserOutlets(): Promise<Outlet[]> {
        let result = await this.invokeRetailOutletCalls<SubPropertyModel[]>('GetSubPropertyAccessByUser', HttpMethod.Get, undefined,
         { userId: this.utils.GetPropertyInfo("UserId") });
         return this.mapSubpropertyToOutlet(result);
    }

    async getOutletsByProperty(): Promise<Outlet[]> {
        let propertyId: number = Number(this.utils.GetPropertyInfo("PropertyId"));
        propertyId = propertyId ? propertyId : 1;
        let result = await this.invokeRetailOutletCalls<SubPropertyModel[]>('GetOutlets', HttpMethod.Get, undefined,
            { propertyId: propertyId });
        return this.mapSubpropertyToOutlet(result);
    }

    async GetStoreTerminals(outletId: number): Promise<StoreTerminal[]> {
        try {
            return await this.invokeOutletCalls<StoreTerminal[]>('GetStoreTerminal', HttpMethod.Get, undefined, { outletId: outletId });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    async SaveFoiloReceipt(reportData: ReportAPIModel): Promise<boolean> {
        try {
            return await this.invokeFolioSaveCalls<boolean>('SaveFolioReceipt', HttpMethod.Put, reportData);
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }


    public async validateOutletDelete(outletId: number): Promise<boolean> {
        let response: BaseResponse<any> = await this.http.CallApiAsync<any>({
            callDesc: 'GetTransactionCount',
            host: Host.retailPOS,
            method: HttpMethod.Get,
            uriParams: { outletId: outletId }
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        let transactionCount = response.result;
        return (transactionCount === 0);
    }

    private mapSubpropertyToOutlet(subP: SubPropertyModel[]): Outlet[] {
        return subP.map(s => {

            return <Outlet>{
                id: s.subPropertyID,
                isActive: s.isActive,
                outletNumber: s.subPropertyID,
                outletName: s.subPropertyName,
                productId : s.productId ? s.productId : 0,
                autoReceipt: s.autoReceipt || false,
                outletAddresses: s.outletAddresses,
                outletContacts: s.outletContacts,
                includeOutletAddress : s.includeOutletAddress
            }
        });
    }

    private async invokeOutletCalls<T>(callDesc: string, callType: HttpMethod, body?: SubPropertyModel, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.retailManagement,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private async invokeFolioSaveCalls<T>(callDesc: string, callType: HttpMethod, body?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.reporting,
            method: callType,
            body: body,

        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }


    private async invokeRetailOutletCalls<T>(callDesc: string, callType: HttpMethod, body?: SubPropertyModel, uRIParams?: any): Promise<T> {
        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: Host.retailManagement,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage("Error", errMsg);
    }

}
