import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionService } from 'src/app/eatecui/source/transaction/shared/services/transaction.service';
import { AgilysysButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { GridTransaction } from '../grid-transaction';
import { QueryTransaction } from '../query-details';
import { DetailsBuilderData, GridFetchFields, ProcessPoConfiguration, ProcessPoSupplier, TransactionConfiguration } from '../transaction.interface';
import { FetchGridDataPropety, PatchValueReassign, PrintPhyInv, ProcessPo } from './create-transaction-config.interface';
import _ from 'lodash';
import moment from 'moment';
import * as constants from '@shared/constant/GlobalConstants';
import { StorageService } from '@shared/services/storage.service';

export const PrintPIService: PrintPhyInv = (
    TransactionCollection: TransactionConfiguration, 
    httpService: HttpService,
    transactionService: TransactionService,
    homeGroup: FormGroup) => {
        try {
            if (TransactionCollection.CreateTranscationConfig) {
              const date = new Date(homeGroup.value['PostDate']);
            //   const utcstartdate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).format();
            //   const postDate = (date) ? utcstartdate : null;
              const postDate = moment(homeGroup.value['PostDate']).startOf('day').format(constants.PostDateTimeFormat);
                const printPostObject = {
                    'reportFormat': 0,
                    'inLine': false,
                    'fileName': '',
                    'reportid': homeGroup.value.PritTemplate,
                    'sortBy': 'string',
                    'isAsc': true,
                    'isRetail': true,
                    'filters': [
                       {
                          'property': 'types',
                          'value': homeGroup.value.Type && homeGroup.value.Type.length > 0 ? homeGroup.value.Type.join() : null,
                          'isExclude': false
                       },
                       {
                          'property': 'classes',
                          'value': homeGroup.value.Class && homeGroup.value.Class.length > 0 ? homeGroup.value.Class.join() : null,
                          'isExclude': false
                       },
                       {
                          'property': 'locations',
                          'value': homeGroup.value.LocationId?.toString(),
                          'isExclude': false
                       },
                       {
                          'property': 'packages',
                          'value': homeGroup.value.Package  ? homeGroup.value.Package.toString() : null,
                          'isExclude': false
                        },
                        {
                            'property': 'iscumulativecount',
                            'value': 'false',
                            'isExclude': false
                        },
                        {
                            'property': 'islocationactiveonly',
                            'value': homeGroup.value.IsLocationActiveOnly?.toString(),
                            'isExclude': false
                        },
                        {
                            'property': 'isnonzeroonly',
                            'value': homeGroup.value.IsNonZeroOnly?.toString(),
                            'isExclude': false
                        },
                        {
                            'property': 'nonreporteditems',
                            'value': homeGroup.value.NonReportedItems?.toString(),
                            'isExclude': false
                        },
                        {
                            'property': 'postdate',
                            'value': postDate,
                            'isExclude': false
                        }
                    ],
                    'IanaTimeZoneId': Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
                    'UserTimeZoneId': new Date()?.toString()?.split("(")[1]?.split(")")[0]
                 };
                 let object = {
                  code: '',
                  filterBody : printPostObject,
                  format: "HTML",
                  downloadFileName: "Download",
                  parameters: '',
                  uRIParams: '',
                  dateFormat:  StorageService.GetSessionStorage('IniDateFormat')
                }
                switch(homeGroup.value.PritTemplate)
      {
        case(24) : object['code'] = ReportCode.PHYS09;
        break;
        case(44) : object['code'] = ReportCode.INGFRM;
        break;
        case(71) : object['code'] = ReportCode.PPOX90;
        break;
        case(77) : object['code'] = ReportCode.PPOX90;
        break;
        case(82) : object['code'] = ReportCode.MAFRM;
        break;
        case(115) : object['code'] = ReportCode.MAFRMWITHOUTGL;
        break;
        case(147) : object['code'] = ReportCode.PHYS09P;
        break;
        case(148) : object['code'] = ReportCode.PHYS04UNITP;
        break;
        case(180) : object['code'] = ReportCode.PHYS09QOH;
        break;
        case(282) : object['code'] = ReportCode.PHYS10;
        break;
        case(113) : object['code'] = ReportCode.RTLFRM;
        break;
        case(161) : object['code'] = ReportCode.PHYS09P_PRINT;
        break;
        case(162) : object['code'] = ReportCode.PHYS04UNITP_PRINT;
        break;
      }
                const putUrl = `/report`;
                 httpService.PutthttpMethod(putUrl, object, null, {observe: 'response', responseType: 'blob'}).subscribe(
                    (responseData: any) => {
                        console.log(responseData);​​​​​​​
                        const b: any = new Blob([responseData.body], { type: responseData.body.type });
                        // Added for microsoft browsers
                        if (responseData.body.type !== 'application/json' 
                        && responseData.body.type !== 'application/json; charset=utf-8') {
                          // Added for Edge support
                          const windowNavigator: any = window.navigator;
                          if (window.navigator && windowNavigator.msSaveOrOpenBlob) {
                            windowNavigator.msSaveOrOpenBlob(b, 'Print.pdf');
                          } else {
                            const url = window.URL.createObjectURL(b);
                            const winFeature = 'location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes';
                            const win = window.open(url , 'Print Preview', winFeature);
                          }
                        } else {
                          const reader = new FileReader();
                          reader.addEventListener('loadend', (e) => {
                          // const text: any = e.target;
                          });
                          reader.readAsText(b);
                        }
                    });
                console.log('Print PI');
            }
        } catch (Error) {
            console.error('Error occurred PrintPIService', Error);
        }
    };
    export enum ReportCode
{
  PHYS09 = 'PHYS09',
  INGFRM = 'INGFRM',
  PPOX90 = 'PPOX90',
  PHYFRM = 'PHYFRM',
  MAFRM = 'MAFRM',
  MAFRMWITHOUTGL = 'MAFRMWITHOUTGL',
  PHYS09P = 'PHYS09P',
  PHYS04UNITP = 'PHYS04UNITP',
  PHYS09QOH = 'PHYS09QOH',
  PHYS10 = 'PHYS10',
  RTLFRM = 'RTLFRM',
  PHYS09P_PRINT = 'PHYS09P_PRINT',
  PHYS04UNITP_PRINT = 'PHYS04UNITP_PRINT'
}