import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AgDurationConfig } from '../../models/ag-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { durationValidator } from './ag-duration.validator';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-spa-ag-duration',
  templateUrl: './ag-duration.component.html',
  styleUrls: ['./ag-duration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgDurationComponent implements OnInit,OnDestroy {
  captions: any;
  durationForm: UntypedFormGroup;
  label: string;
  required: boolean;
  hourMin = 0;
  minuteMin = 0;
  hourMax = 24;
  minuteMax = 59;
  className: string;
  parentForm: UntypedFormGroup;
  controlName: string;
  durationValue;
  disabled:boolean;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() durationChange: EventEmitter<any> = new EventEmitter();
  @Output() durationEmit: EventEmitter<any> = new EventEmitter();
  @Input('inputs')
  set InputOptions(value: AgDurationConfig) {
    if (value) {
      this.label = value.label;
      this.required = value.required;
      this.className = value.class;
      this.parentForm = value.formGroup;
      this.controlName = value.controlName;
      this.disabled = value.disabled;
      if (!this.durationForm) {
        this.buildForm();
      }
      if (this.disabled) {
        this.durationForm.disable();
      }
    }
  }

  @Input('duration')
  set Duration(value) {
      this.buildForm();
    
    if (value) {
      const minutes = value % 60;
      const hours = Math.floor(value / 60);
      const formattedHr = ('0' + hours).slice(-2);
      const formattedMin = ('0' + minutes).slice(-2);
      this.durationForm.patchValue({ hour: formattedHr, minute: formattedMin }, {emitEvent: false});
    }
  }

  constructor(private localization: SpaLocalization, private fb: UntypedFormBuilder) {
    this.captions = this.localization.captions;
  }

  ngOnInit() {
    if (!this.durationForm) {
      this.buildForm();
    }
  }
  buildForm() {
    this.durationForm = this.fb.group({
      hour: ['00', durationValidator],
      minute: ['00', durationValidator]
    });
    this.durationForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      const duration = (val.hour ? val.hour * 60 : 0) + Number(val.minute);
      this.durationValue = duration;
      if (this.parentForm && this.parentForm.get(this.controlName)) {
        const durationCtrl = this.parentForm.get(this.controlName) as UntypedFormControl;
        if (this.durationForm.invalid) {
          durationCtrl.setErrors({ exceededDuration: true });
        } else {
          if (durationCtrl.hasError('exceededDuration')) {
            delete durationCtrl.errors['exceededDuration'];
          }
          durationCtrl.setValue(duration);
          durationCtrl.markAsTouched();
          durationCtrl.markAsDirty();
          durationCtrl.updateValueAndValidity();
        }
      }
      this.durationChange.emit(duration);
    });
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  durationBlur() {
    const min = this.durationForm.get('minute').value;
    const hour = this.durationForm.get('hour').value;
    const total = Number(hour) * 60 + Number(min);
    const result = total > 1440;
   
    this.durationEmit.emit({
      value: this.durationValue,
      status: !result
    });
  }

}
