import { GlobalDateFormatter } from "src/app/eatecui/source/shared/models/formater.interface";
import * as constants from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { StorageService } from "src/app/eatecui/source/shared/services/storage.service";
import moment from "moment";

export const GlobalDateTimeFormater: GlobalDateFormatter = (gridData: any, utcFormat = false): any => {
    try {
        const IniDateFormat = sessionStorage.getItem('IniDateFieldFormat') ? 
        sessionStorage.getItem('IniDateFieldFormat') : constants.DefaultIniDateFormat;
        let DateTimeFormat = constants.DefaultDateTimeFormat;
        DateTimeFormat = DateTimeFormat.replace('MM/DD/YYYY', IniDateFormat);
        if (gridData.value) {
            return getFormattedDateTime(gridData, DateTimeFormat);
        }
    } catch (error) {
        console.log('error occured GlobalDateTimeFormatter', error);
    }
};

function getFormattedDateTime(gridData: any, DateTimeFormat: any) {
    try {
        const AlreadyFormatted = moment(gridData.value, DateTimeFormat).format(DateTimeFormat) === gridData.value;
            if (!AlreadyFormatted) {
                // Added for showing Invalid Date in Wexflow Grid
                if (moment(gridData.value).isValid()) {
                    // let returnValue = moment.utc(gridData.value).local().format(DateTimeFormat);
                    let returnValue = moment(gridData.value).format(DateTimeFormat);
                    gridData.data[gridData.colDef.field] = returnValue;
                    if( gridData && gridData.data && gridData.data.StatusDate){
                        // const utc = moment.utc(gridData.value);
                        // const newDate = new Date(utc.toString());
                        // returnValue = moment(newDate).format(DateTimeFormat);
                        returnValue = moment(gridData.value).format(DateTimeFormat);
                    }
                    return returnValue;
                } else {
                    return gridData.value;
                }
            } else {
                return gridData.value;
            }
    } catch (error) {
        console.error('Error occured Formatted Date Time ', error);
    }
}