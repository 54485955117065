
import { BaseReport } from "../common/base-report";
import { ReportUIConfig, ReportAPIOptions, ReportParams, AllReports,  CorrectVoidFilterData } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services"; 
import { ReportBusinessService } from '../report-business.service';
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class GiftCardIssuedReport extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private productId: number
        ,private func: RetailFunctionalityBusiness,  public propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }
    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'LANDSCAPE',
            allowFutureDate: false
        };
    }
    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.GiftCardIssued,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }
    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate,
            ReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value,
            userList: any[] = this.business.FilterDataSource['users'],
            userCaption: string = this.business.getFilterCaption(
                ReportFilterData.includeInactiveclerks ? userList : userList.filter((u) => u.isActive ),
                this.fillFilter(ReportFilterData.users.filter((u) => u.showInDropDown ))
            );
        return [{ "pStartDate": toApi(this.startDate) },
        { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle }, 
        { "pFilterCaption": this.functionalities.ShowOutletSelectionFieldInGiftIssuedReport ? this.getFilterCaption : this.getDefaultOutletCaption },
        { "pPropertyName": this.propertyName }, { "pDate": this.printedDate }, { "pUser": userCaption },
        { "pOutlet": this.functionalities.ShowOutletSelectionFieldInGiftIssuedReport ? this.getFilterCaption : this.getDefaultOutletCaption },
        { "pIncludeInactiveClerks": ReportFilterData.includeInactiveclerks}
        ];
    }
    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "staffType": "User" }, { "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) },
        { "ProductId": this.productId }];
    }
    protected formFilters() {
         let toApi = this.localization.convertDateObjToAPIdate;
         let GCIReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
         if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                StartDate: this.localization.convertDateTimeToAPIDateTime(this.startDate),
                EndDate: this.localization.convertDateTimeToAPIDateTime(this.endDate),
                OutletIds: this.fillFilterForOutlet(this.selectedFilterOptions),
                UserIds: this.fillFilter(GCIReportFilterData.users.filter((u) => u.showInDropDown)),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            };  
        }
        return {
         StartDate: this.localization.convertDateTimeToAPIDateTime(this.startDate),
         EndDate: this.localization.convertDateTimeToAPIDateTime(this.endDate),
         OutletIds: this.fillFilterForOutlet(this.selectedFilterOptions),
         UserIds: this.fillFilter(GCIReportFilterData.users.filter((u) => u.showInDropDown))
         };
    }

    fillFilterForOutlet(customFilterData: any) : number[] {
        let ids: number[] = customFilterData.length === 0 ? [] : customFilterData.map((x) => x.id);
        const defaultOutlet = this.propertyInfo.GetDefaultOutlet();
        const tempReportValue = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        ids = this.functionalities.ShowOutletSelectionFieldInGiftIssuedReport ?  ids : tempReportValue ;
        return ids;
    }

    fillFilter(customFilterData: any) : number[] {
        let ids: number[] = customFilterData.length === 0 ? [] : customFilterData.map((x) => x.id);
        return ids;
    }

}
