import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, InventoryAuditFilter } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import * as _ from 'lodash';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { ReportBusinessService } from '../report-business.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
export class InventoryAudit extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, 
         private func: RetailFunctionalityBusiness,  public propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization,private featureFlag: RetailFeatureFlagInformationService) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.InventoryAudit,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }


    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        const toApi = this.localization.convertDateObjToAPIdate;
        const customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const captions: string[] = this.business.getSelectedCategoryCaptions(customFilterData);
        const defaultPMSOutlet = this.propertyInfo.GetDefaultOutlet();
        const defaultOutlet =  defaultPMSOutlet > 0 ? defaultPMSOutlet : customFilterData.defaultOutlets[0].id;
        const defaultOutletDescription =  this.business.getFilterCaption(customFilterData.defaultOutlets,  [defaultOutlet] );
        return [{ 'pStartDate': toApi(this.startDate) }, { 'pEndDate': toApi(this.endDate) }, {'pDate': this.printedDate}, { 'pPageBreak': this.pageBreakToggle},
        {'pPropertyName': this.propertyName}, 
        this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport?
        {'pOutlet': this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport ? 
        customFilterData.outlets.description  : defaultOutletDescription} : {'pOutlet': null },
        {'pshowOutlet': this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport ? true : false },
        {'pSearchByItem': customFilterData.searchbyitem},
        {'psearchByDescription': customFilterData.searchbydesc},
        {'pSearchByBarCode' : customFilterData.searchbybarcode},
        {'pSearchBySupplier': customFilterData.searchbysupplier},
        {'pSearchByOtherScannerCode': customFilterData.searchbyotherscannercode},
        {'pCategory': captions[0]},
        {'pSubCategory1': captions[1]},
        {'pSubCategory2': captions[2]},
        {'pSubCategory3': captions[3]},
        {'pSubCategory4': captions[4]},
        {'pSubCategory5': captions[5]},
        {'pSearchBySupplierItem': customFilterData.searchbysupplieritem},
        {'pPurchaseOrder': customFilterData.purchaseorder},
        {'pincludeInactiveItems':customFilterData.includeinactiveitems ? "true" : "false"},
        {'pincludeNonInventoryItems': customFilterData.includenoninventoryitems ? "true" : "false"},
        {"pIsEatecEnabled":this.featureFlag.IsEatecEnabled==true ? "true" : "false"}
    ];
    }


    protected formURIParams(): ReportParams[] {
        return [];
    }


    protected formFilters() {
        const toApi = this.localization.convertDateObjToAPIdate;
        const customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const outlets: number[] = [];
        if (customFilterData.outlets){
            outlets.push(customFilterData.outlets.id);
        }
        const defaultOutlet =   [customFilterData.defaultOutlets[0].id];
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return JSON.stringify({
                startDate: toApi(this.startDate),
                endDate: toApi(this.endDate),
                outlets: this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport ? outlets : defaultOutlet,
                category: this.getCategoriesAndSubCategories(customFilterData, 0),
                subCategory1: this.getCategoriesAndSubCategories(customFilterData, 1),
                subCategory2: this.getCategoriesAndSubCategories(customFilterData, 2),
                subCategory3: this.getCategoriesAndSubCategories(customFilterData, 3),
                subCategory4: this.getCategoriesAndSubCategories(customFilterData, 4),
                subCategory5: this.getCategoriesAndSubCategories(customFilterData, 5),
                includeinactiveitems: customFilterData.includeinactiveitems,
                includenoninventoryitems: customFilterData.includenoninventoryitems,
                searchbyitem: this.checNullOrEmpty(customFilterData.searchbyitem) ? '' : customFilterData.searchbyitem,
                searchbydesc: this.checNullOrEmpty(customFilterData.searchbydesc) ? '' : customFilterData.searchbydesc,
                searchbysupplier: this.checNullOrEmpty(customFilterData.searchbysupplier) ? '' : customFilterData.searchbysupplier,
                searchbybarcode: this.checNullOrEmpty(customFilterData.searchbybarcode) ? '' : customFilterData.searchbybarcode,
                searchbyotherscannercode: this.checNullOrEmpty(customFilterData.searchbyotherscannercode) ? '' : customFilterData.searchbyotherscannercode,
                searchbysupplieritem: this.checNullOrEmpty(customFilterData.searchbysupplieritem) ? 0 : customFilterData.searchbysupplieritem,
                purchaseorder: this.checNullOrEmpty(customFilterData.purchaseorder) ? 0 : customFilterData.purchaseorder,
                showOutlet: this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport ? true : false,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            });
        }
        const filter: Partial<InventoryAuditFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate),
            outlets: this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport ? outlets : defaultOutlet,
            category: this.getCategoriesAndSubCategories(customFilterData, 0),
            subCategory1: this.getCategoriesAndSubCategories(customFilterData, 1),
            subCategory2: this.getCategoriesAndSubCategories(customFilterData, 2),
            subCategory3: this.getCategoriesAndSubCategories(customFilterData, 3),
            subCategory4: this.getCategoriesAndSubCategories(customFilterData, 4),
            subCategory5: this.getCategoriesAndSubCategories(customFilterData, 5),
            includeinactiveitems: customFilterData.includeinactiveitems,
            includenoninventoryitems: customFilterData.includenoninventoryitems,
            searchbyitem: this.checNullOrEmpty(customFilterData.searchbyitem) ? '' : customFilterData.searchbyitem,
            searchbydesc: this.checNullOrEmpty(customFilterData.searchbydesc) ? '' : customFilterData.searchbydesc,
            searchbysupplier: this.checNullOrEmpty(customFilterData.searchbysupplier) ? '' : customFilterData.searchbysupplier,
            searchbybarcode: this.checNullOrEmpty(customFilterData.searchbybarcode) ? '' : customFilterData.searchbybarcode,
            searchbyotherscannercode: this.checNullOrEmpty(customFilterData.searchbyotherscannercode) ? '' : customFilterData.searchbyotherscannercode,
            searchbysupplieritem: this.checNullOrEmpty(customFilterData.searchbysupplieritem) ? 0 : customFilterData.searchbysupplieritem,
            purchaseorder: this.checNullOrEmpty(customFilterData.purchaseorder) ? 0 : customFilterData.purchaseorder,
            showOutlet: this.functionalities.ShowOutletSelectionFieldInInventoryAuditTrailReport ? true : false
        };
        return JSON.stringify(filter);
    }

    private getCategoriesAndSubCategories(customFilterData: any, index: number): number[] {
        let catArray: number[] = [];
        if (Boolean(_.get(customFilterData, 'category.length'))) {
            console.log(customFilterData.category);
           catArray =  customFilterData.category[index].selectedCat.length === 0 ?
           [] : customFilterData.category[index].selectedCat.map(x => x.id);           
           if((customFilterData.category[index].selectedCat.length === customFilterData.category[index].catArr.length) && customFilterData.category[index].catArr.length > 0 && !this.retailLocalization.isFromJobScheduler){
            catArray.length = 0;
        }
        }
        return catArray;
    }

    private checNullOrEmpty(data: any): boolean {
        if(data == null || data === '') {
            return true;
        } else {
            return false;
        }
    }

}


