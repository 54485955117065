import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionType, PromptType, ButtonOptions } from '../../../shared/globalsContant';
import { appointmentService } from '../../service/appointment.service';
import { AppointmentUtilities } from '../../../shared/utilities/appointment-utilities';
import { UserAlerts } from '../../../core/config/alerts-config';
import { BaseResponse } from '../../business/shared.modals';
@Component({
  selector: 'app-appointment-actions-common-dialog',
  templateUrl: './appointment-actions-common-dialog.component.html',
  styleUrls: ['./appointment-actions-common-dialog.component.scss']
})
export class AppointmentActionsCommonDialogComponent {
  datRec: any;
  @Input() dataInput;
  actionTypes = ActionType;
  constructor(private userAlerts: UserAlerts,
    public dialogRef: MatDialogRef<AppointmentActionsCommonDialogComponent>,
    private apptService: appointmentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ApptUtilities: AppointmentUtilities) {
    this.apptService.actionData = data.id;
  }

  onNoClick(): void {
    if (this.data.id === this.actionTypes.move
      && this.apptService && this.apptService.tempAppointmentId
      && this.apptService.tempAppointmentId !== 0) {
      this.ApptUtilities.deleteTempHold([{ appointmentId: this.apptService.tempAppointmentId }],
        this.successCallback.bind(this), this.errorCallback.bind(this));
    } else {
      this.dialogRef.close();
    }
  }

  promptUser() {
    if (this.apptService.activeFormGroup
      && this.apptService.activeFormGroup.dirty
      && (this.data.id !== this.actionTypes.recap)) {
      this.userAlerts.showPrompt(PromptType.UnsavedChanges, this.PopupCallback.bind(this));
    } else {
      this.dialogRef.close();
    }
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() === ButtonOptions.Yes) {
      this.onNoClick();
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === 'DeleteTempHold') {
      this.apptService.tempAppointmentId = 0;
      this.dialogRef.close();
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc === 'DeleteTempHold') {
      this.apptService.tempAppointmentId = 0;
      this.dialogRef.close();
    }
  }
}
