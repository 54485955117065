import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Localization } from '../../localization/localization';

@Component({
  selector: 'app-snackbar-error',
  templateUrl: './snackbar-error.component.html',
  styleUrls: ['./snackbar-error.component.scss']
})
export class SnackbarErrorComponent implements OnInit {

  snack : string;
  isClosed : boolean;
  @Output() close = new EventEmitter();
  captions: any;

  @Input('snackInput')
  set snackValue(value){
    this.snack = value.slice(0,-2);
    // this.isClosed = this.snack ? false : true;
    this.isClosed = true;
  }
  constructor(public localization: Localization
    ) {
      this.captions = this.localization.captions;
     }

  ngOnInit(): void {
  }
  emptySnack(){
    this.isClosed= true;
    this.close.emit(true);
  }

}
