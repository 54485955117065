/*
  NOTES TO DEVELOPER
  *****This page for ui developement only, In case any change in this page without authurization will affect UI*****
 */
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';
import { OnEventArgs } from '../../form-type.entity';
import { CustomErrorMesssage } from '../../form-validation';
import moment from 'moment';
@Component({
    selector: 'lib-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements AfterViewInit, OnInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    formFieldName: any;
    fieldType: any;
    formFieldlabel: any;
    formArrayName: any;
    errorMessage: string;
    isInputDisabled: boolean;
    errorLength: number;
    minDate: any;
    maxDate: any;
    checkType: any;
    formFieldToolTip: string;
    AllowedPickerDate: string;
    IniDateFieldFormat: string;
    isRequired:boolean;
    get isValid(): any { return this.form.controls[this.formFieldName].valid; }
    get isDirty(): any { return this.form.controls[this.formFieldName].dirty; }

    constructor(private agyDynamicFormServices: AgyDynamicFormServices) {
        this.minDate = null;
        this.maxDate = null;
    }

    eventCallback(index ?: any, argus?: OnEventArgs): void {
        this.agyDynamicFormServices.formEventCallback(this.field, index, this.form );
    }

    ngAfterViewInit(): void {
        this.isRequired = this.form.controls[this.formFieldName]?.validator ?
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
        this.form.controls[this.formFieldName].statusChanges.subscribe(x => {
            this.isRequired = this.form.controls[this.formFieldName]?.validator ?
            !!this.form.controls[this.formFieldName]?.validator({} as AbstractControl)?.required : false;
            if (this.form.controls[this.formFieldName]?.errors?.hasOwnProperty('matDatepickerParse') && 
                this.form.controls[this.formFieldName]?.errors?.hasOwnProperty('required')) {
                delete this.form.controls[this.formFieldName].errors.required;
            }
            this.errorMessage = CustomErrorMesssage.createError(this.fieldType, this.form, this.field);
            if ( this.formFieldName === 'Expiredate' && this.form.controls[this.formFieldName].status === 'INVALID'){
                this.errorMessage = this.form.controls[this.formFieldName].errors.message;
            }
        });
        if (this.form.controls[this.formFieldName] && this.form.controls[this.formFieldName].errors){
            const errorsArray =  Object.keys(this.form.controls[this.formFieldName].errors);
            this.errorLength = errorsArray.length;
        }
    }

    ngOnInit(): void {
        this.fieldType = Object.keys(this.field.fieldType).toString();
        this.IniDateFieldFormat = sessionStorage.getItem('IniDateFieldFormat');
        const formField = this.agyDynamicFormServices.generateFormFieldName(this.field);
        this.formFieldName = formField.fieldName;
        this.formFieldlabel = formField.fieldLabel;
        this.formArrayName = formField.fieldName;
        this.formFieldToolTip = this.field.fieldType?.SingleData?.CustomToolTip?.EnableToolTip
        ? this.field.fieldType.SingleData.CustomToolTip.ToolTipText : null;
        this.isInputDisabled = this.field.fieldType[this.fieldType].disbaledProperty ?
            this.field.fieldType[this.fieldType].disbaledProperty : false;
        this.AllowedPickerDate = this.field.fieldType?.SingleData?.AllowedPickerDate;
        if ( this.field.hasOwnProperty('minDate')){
            this.minDate =  this.field.minDate;
        }
        if ( this.field.hasOwnProperty('maxDate')){
            this.maxDate =  this.field.maxDate;
        }
        if (this.field.fieldType?.SingleData?.hasOwnProperty('AllowedPickerDate')) {
            if (this.AllowedPickerDate === 'PastDateOnly') {
                this.minDate = new Date();
            } else if (this.AllowedPickerDate === 'FutureDateOnly') {
                this.maxDate = new Date();
            } else if (this.AllowedPickerDate === 'OneMonthOnly') {
                const currentDate = moment();
                this.minDate = currentDate.subtract(1, 'months');
                this.maxDate = new Date();
            } else if (this.AllowedPickerDate === 'OneYearOnly') {
                const currentDate = moment();
                this.minDate = currentDate.subtract(1, 'years');
                this.maxDate = new Date();
            }
        } else {
            this.minDate = null;
            this.maxDate = null;
        }
    }

    valueChange(value: any) {
        // const datevalue = value.target.value;
        // if (datevalue !== "") {
        //     this.checkType = moment(datevalue, 'M/D/YYYY', true).isValid() || moment(datevalue, 'D/M/YYYY', true).isValid()
        //     || moment(datevalue, 'YYYY/M/D', true).isValid() || moment(datevalue, 'M-D-YYYY', true).isValid() ||
        //     moment(datevalue, 'D-M-YYYY', true).isValid() || moment(datevalue, 'YYYY-M-D', true).isValid();
        //     // this.checkType = value.target.value.toString() instanceof Date;
        //     if (!this.checkType) {
        //         if (datevalue.indexOf('/') !== -1 && datevalue.indexOf('//') === -1) {
        //             const returnDate = this.splitFormDate(datevalue, '/');
        //             this.form.controls[this.formFieldName].setValue(new Date(returnDate));
        //         } else if (datevalue.indexOf('-') !== -1 && datevalue.indexOf('--') === -1) {
        //             const returnDate = this.splitFormDate(datevalue, '-');
        //             this.form.controls[this.formFieldName].setValue(new Date(returnDate));
        //         } else {
        //             this.form.controls[this.formFieldName].setValue(new Date());
        //         }
        //     }
        //     else {
        //         let dateFormatted: any;
        //         const formattedDate = moment(datevalue, 'M/D/YYYY');
        //         const formattedDate1 = moment(datevalue, 'D/M/YYYY');
        //         const formattedDate2 = moment(datevalue, 'YYYY/M/D');
        //         if (formattedDate.isValid()) {
        //             dateFormatted = formattedDate.format();
        //         } else if (formattedDate1.isValid()) {
        //             dateFormatted = formattedDate1.format();
        //         } else if (formattedDate2.isValid()) {
        //             dateFormatted = formattedDate2.format();
        //         } else {
        //             dateFormatted = moment(new Date()).format();
        //         }
        //         this.form.controls[this.formFieldName].setValue(new Date(dateFormatted));
        //     } 
        // }
    }


    splitFormDate(datevalue, splitValue) {
        try {            
            const dateValues = datevalue.split(splitValue);
            const indexvalue = dateValues.findIndex(x => x > 12); // to check whether first given is date or month
            let date,month, year;
            if (indexvalue !== -1) {
                date = dateValues[indexvalue];
                month = indexvalue === 0 ? dateValues[1] : dateValues[0];
                year = dateValues[2] ? dateValues[2] : new Date().getFullYear();
            } else {
                date = dateValues[0];
                month = dateValues[1];
                year = dateValues[2] ? dateValues[2] : new Date().getFullYear();
            }
            const formedDate = moment([year, (month -1), date]).format();
            return formedDate;
        } catch (error) {
            console.error('Error occurred in spliFormDate', error);
        }
    }
}
