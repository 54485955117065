import { Injectable } from '@angular/core';
import { API } from 'src/app/common/components/combine-guest-records/combine-guest-ui-model';
import { HttpServiceCall, HttpMethod } from './http-call.service';
import { Host } from '../globalsContant';

@Injectable({
  providedIn: 'root'
})
export class CombineGuestDataService {

  constructor(private http: HttpServiceCall) {
  }



  public async getGuestsBySearchCriteria(guestSearchFields: API.GuestSearchFields): Promise<API.Guest[]> {
    try {
      return await this.http.CallApiAsync({
        callDesc: 'GetGuestInformation',
        host: Host.spaManagement,
        method: HttpMethod.Post,
        body: guestSearchFields
      }) as any;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }
  public async getGuestsByEmptySearchCriteria(filterName: string): Promise<API.Guest[]> {
    try {
      return await this.http.CallApiAsync({
        callDesc: 'EmptyFilterSearch',
        host: Host.spaManagement,
        method: HttpMethod.Put,
        uriParams: { filterName: filterName }
      }) as any;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }


  public async MergeGuestsRecords(primaryGuest: string, secondaryGuest: string[]): Promise<boolean> {
    try {
      return await this.http.CallApiAsync({
        callDesc: 'CombineGuestInformation',
        host: Host.spaManagement,
        method: HttpMethod.Put,
        uriParams: { primaryGuestId: primaryGuest },
        body: secondaryGuest
      }) as any;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  public async UpdateGuestInformation(primaryGuestId: string, guestInfo: API.Guest): Promise<string> {
    try {
      return await this.http.CallApiAsync({
        callDesc: 'UpdateGuestInformation',
        host: Host.spaManagement,
        method: HttpMethod.Put,
        uriParams: { id: primaryGuestId },
        body: guestInfo
      }) as any;
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }


  public async getClientDataByGuid(guestId: any): Promise<API.Guest> {
    return this.http.CallApiAsync({
      host: Host.spaManagement,
      callDesc: "GetGuestInfoByGuid",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { id: guestId }
    }) as any; 
  }
}