import { transButtonCallBack } from './button-callback.interface';
import { confirmPopUpFormFields } from './confirm-popup-form-fields.interface';
import { confirmPopUpData } from './confirmation-popup-data.interface';
import { ViewtransButtonCallBack } from './view-button-callback.interface';

export const transactionButton = {
  buttonCallback: transButtonCallBack,
  buttonCallbackView: ViewtransButtonCallBack,
  confirmPopUpInfo: confirmPopUpData,
  ConfirmPopFormFields: confirmPopUpFormFields
};