import { LoadSystemPreference } from './load-system-preference.interface';
import { MaxLengthSystemPreference } from './maxlength-preference.interface';
import { PreferenceTypeConfig } from './preference-type.interface';
import { ResolveLoadSystemPreference } from './resolve-systempreference.interface';

export const SystemPreferenceInterface = {
  LoadPreference: LoadSystemPreference,
  PreferenceTypeConfig: PreferenceTypeConfig,
  ResolveLoadSystemPreferenceEngine: ResolveLoadSystemPreference,
  MaxLengthPreference: MaxLengthSystemPreference
};