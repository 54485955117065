import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgilysysButtonModule, CallBackData } from 'src/app/eatecui/dist/agilysys-button';
import { AddNewPopModel } from '../model/pop-up.interface';

@Component({
  selector: 'app-agilysys-create-req-popup',
  templateUrl: './agilysys-create-req-popup.component.html',
  styleUrls: ['./agilysys-create-req-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgilysysCreateReqPopupComponent implements OnInit {
  agilysysAddNewButton: AgilysysButtonModule[];
  homeGroup: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AgilysysCreateReqPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddNewPopModel,
    public formBuilder: FormBuilder
  ) {
    this.homeGroup = this.formBuilder.group({});
   }

  ngOnInit(): void {
    this.agilysysAddNewButton = [
      {
        displayProperity: false,
        displayName: 'Cancel',
        buttonKey: 'Cancel',
        buttonType: 'agilysys-button-secondary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
        }
      },
      {
        displayProperity: false,
        displayName: 'Confirm',
        buttonKey: 'Confirm',
        buttonType: 'agilysys-button-primary',
        buttonCallBack: (callBackData: CallBackData) => {
          this.dialogRef.close([callBackData.buttonValue.buttonKey, this.homeGroup]);
        }
      }
  ];
  }

}
