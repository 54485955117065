import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AgContactConfig } from '../../Models/ag-models';
import { MailTypes, ContactType, Product, DefaultMailTypes } from 'src/app/common/enums/shared-enums';
import { MatDialog } from '@angular/material/dialog';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';
import { Localization } from '../../localization/localization';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-ag-mail-id',
  templateUrl: './ag-mail-id.component.html',
    styleUrls: ['./ag-mail-id.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AgMailIdComponent implements OnInit {
  customClass: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  emailPlaceHolder: string;
  value: string | number;
  options: { id: number, description: string, type: string }[];
  optionClass: string;
  typeControlName: string;
  arrayName: string;
  showSwitches: boolean;
  mailTypes = MailTypes;
  captions: any;
  primarySwitchName: string;
  privateSwitchName: string;
  duplicateError: string;
  maxLength = 75;
  primaryLabel: string;
  privateLabel: string;
  isEmailRequired: boolean = false;
  requiredErrorMessage: string;
    isEmailDisabled: boolean;
    allowOnlyPrimaryOrPrivate: boolean;
  placeHolderId: string;
  errorMessageId: string;
  currentProductId: number;
  defaultMailTypeValue: string | number;
  floatLabel: string;
  automationId: string;
  showMoreTypes: boolean = false;
  requestFromGuest: boolean = false;
  isOnlyOneContact: boolean;

  @Input('inputs')
  set inputOptions(value: AgContactConfig) {
    this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: '';
    this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.err_invalidEmailFormat;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.lbl_email;
    this.controlName = value.formControlName ? value.formControlName : 'emailId';
    this.typeControlName = value.typeControlName ? value.typeControlName : 'emailType';
    this.allowOnlyPrimaryOrPrivate = value.allowOnlyPrimaryOrPrivate ? value.allowOnlyPrimaryOrPrivate : false;
      this.customClass = value.className ? value.className : 'ag_form-control--md';
    this.optionClass = value.typeClass ? value.typeClass : 'ag_form-control--xs';
    this.showSwitches = value.showSwitches;
    this.primaryLabel = value.primarySwitchLabel ? value.primarySwitchLabel : this.captions.lbl_PrimaryEmail;
    this.privateLabel = value.privateSwitchLabel ? value.privateSwitchLabel : this.captions.lbl_PrivateInformation;
    this.emailPlaceHolder = value.emailPlaceHolder ? value.emailPlaceHolder : '';
    this.isEmailRequired = value.isEmailRequired ? value.isEmailRequired : false;
    this.requiredErrorMessage = value.requiredErrorMessage ? value.requiredErrorMessage : '';
    this.isEmailDisabled = value.disabled ? value.disabled : false;
    this.errorMessageId = value.errorMessageId ?  value.errorMessageId : 'err_invalidEmailFormat';
    this.placeHolderId = value.placeHolderId ? value.placeHolderId : 'lbl_email';
    this.automationId = value.automationId ? value.automationId : ''; 
    if (this.showSwitches) {
      this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryEmail';
      this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionEmail';
    }
    this.contactForm = value.form;
    this.arrayName = value.formArrayName;
    this.showMoreTypes = value.showMoreTypes;
    this.requestFromGuest = value.requestFromGuest;

    if (!this.arrayName) {
      this.contactForm.get(this.controlName).disable();
    } else {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      email.controls.forEach((obj) => {
        if (!obj.value[this.typeControlName] && obj.get(this.controlName)) {
          obj.get(this.controlName).disable();
        }
      });
    }
    this.currentProductId = Number(this.utilities.GetPropertyInfo('ProductId'));
    if(this.currentProductId === Product.PMS){
      const uiDefaultScreenValue = JSON.parse(sessionStorage.getItem('UiDefaultsSettingsValue'));
      let defaultMailType = uiDefaultScreenValue?.mailType ? uiDefaultScreenValue?.mailType : '';
      this.setDefaultMailType(this.getDefaultMailType(defaultMailType));
    }
  }

  @Input('valuePatched')
  set ControlState(value: boolean) {
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.controlName).value) {
      this.contactForm.get(this.controlName).enable();
    } else {
      this.contactForm.get(this.controlName).disable();
    }
  }

  @Input('values')
  set SetFormArray(value) {
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.mailFieldDisabler(this.isEmailDisabled);      
    }
    if(this.requestFromGuest){
      this.ChangePrimaryToggle();
    }
  }

  @Input() isViewOnly: boolean = false;
  @Input() allowMultiple: boolean = true;

  constructor(private fb: UntypedFormBuilder, private localization: Localization, public dialog: MatDialog, private utilities: CommonUtilities) {
      this.captions = this.localization.captions.common;
    this.duplicateError = this.captions.err_duplicateEmail;    
  }

  ngOnInit() {
    this.options = this.getMailOptions();
  }

  ngOnChanges() {
    if (this.arrayName) {
      this.mailFieldDisabler(this.contactForm.get(this.arrayName).disabled);
    }
  }

  getDefaultMailType(defaultMailType){
    switch(defaultMailType){
      case DefaultMailTypes.office:
        this.defaultMailTypeValue = this.options.find(x=> x.id === this.mailTypes.office)?  this.options.find(x=> x.id === this.mailTypes.office).id: '';
        break;
      case DefaultMailTypes.personal:
        this.defaultMailTypeValue = this.options.find(x=> x.id === this.mailTypes.personal)? this.options.find(x=> x.id === this.mailTypes.personal).id: '';
        break;
      case DefaultMailTypes.home:
        this.defaultMailTypeValue = this.options.find(x=> x.id === this.mailTypes.home)?  this.options.find(x=> x.id === this.mailTypes.home).id: '';
        break;
      case DefaultMailTypes.business:
        this.defaultMailTypeValue = this.options.find(x=> x.id === this.mailTypes.business)? this.options.find(x=> x.id === this.mailTypes.business).id: '';
        break;
    }
    return this.defaultMailTypeValue;
  }


  setDefaultMailType(defaultMailType){
    this.contactForm.get(this.arrayName)['controls'].forEach((element,index) =>{
      if(element.get(this.typeControlName).value === ""){
        element.get(this.typeControlName).setValue(defaultMailType);
      } 
      if(element.get(this.typeControlName).value){
        element.get(this.controlName).enable();
      }
    })
  }


  mailFieldDisabler(isDisable) {
    let mailField = this.contactForm.get(this.arrayName);
    if (isDisable) {
      this.isEmailDisabled = true;
      mailField['controls'].forEach(element => {
        element.disable();
      });
    }
  }

  // Dynamic Email field addition Logic
  addEmailArray(index, emailLabel, emailid, isprimary?: boolean, isPrivate?: boolean, isSynced?: boolean): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.email,
      id: index,
      isSynced: isSynced
    });
    currentForm.addControl(this.typeControlName, new UntypedFormControl(emailLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: emailid, disabled: !emailLabel }));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isprimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    return currentForm;
  }
  addEmailItem(index, emailLabel: any, emailid: any, primaryEmail, privateInformtionEmail, isSynced): void {
    if (!this.isViewOnly) {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      email.push(this.addEmailArray(index, emailLabel, emailid, primaryEmail, privateInformtionEmail, isSynced));
    }
    if (this.requestFromGuest) {
      this.ChangePrimaryToggle();
    }
  }

  removeEmailItem(index: number): void {
    if (!this.isViewOnly) {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      this.contactForm.markAsDirty();
      email.removeAt(index);
    }
    if (this.requestFromGuest) {
      this.ChangePrimaryToggle();
    }
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const ctrls = arr.controls.filter((x, idx) => idx !== formGroupName);
    if (!this.requestFromGuest) {
      ctrls.forEach(x => {
        const grp = x as UntypedFormGroup;
        grp.controls[this.primarySwitchName].setValue(false);
      });
      if (this.allowOnlyPrimaryOrPrivate) {
        const currentCtrl = arr.controls.filter((x, idx) => idx == formGroupName);
        currentCtrl.forEach(x => {
          const grp = x as UntypedFormGroup;
          if (grp.value[this.primarySwitchName]) {
            grp.controls[this.privateSwitchName].setValue(false);
          }
        });
      }
    }
    else {
      var validatePrimary = arr.value.filter((e: any) => e.primaryEmail)
      if (validatePrimary?.length > 0) {        
        ctrls.forEach(x => {
          const grp = x as UntypedFormGroup;
          grp.controls[this.primarySwitchName].setValue(false);
        });
      } else {
        const ctrlsTrue = arr.controls.filter((x, idx) => idx === formGroupName);
        ctrlsTrue.forEach(x => {
          const grp = x as UntypedFormGroup;
          grp.controls[this.primarySwitchName].setValue(true);
        });
      }
    }
  }

  togglePrivateContact(formGroupName: number) {
    if (!this.requestFromGuest) {
      const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
      if (this.allowOnlyPrimaryOrPrivate) {
        const currentCtrl = arr.controls.filter((x, idx) => idx == formGroupName);
        currentCtrl.forEach(x => {
          const grp = x as UntypedFormGroup;
          if (grp.controls[this.privateSwitchName]) {
            grp.controls[this.primarySwitchName].setValue(false);
          }
        });
      }
    }
  }

  ChangePrimaryToggle() {
    let contactArray = this.contactForm.get(this.arrayName) as UntypedFormArray;
    if (contactArray?.value?.length == 1) {      
      const ctrls = contactArray.controls.filter((x, idx) => idx === 0);
      ctrls.forEach(x => {
        const grp = x as UntypedFormGroup;
        grp.controls[this.primarySwitchName].setValue(true);
      });
      this.isOnlyOneContact = true;
    } else {
      this.isOnlyOneContact = false;
    }
  }

  typeChange(e, formGroup: UntypedFormGroup) {
    if(!e.value){
      formGroup.get(this.controlName).setValue('');
      formGroup.get(this.controlName).disable();
    }
    else{
      formGroup.get(this.controlName).enable();
    }
  }

  private patchOrAdd(value) {
    const mail = this.contactForm.get(this.arrayName) as UntypedFormArray;
    value.forEach((obj, idx) => {
      const group = mail.controls[idx] as UntypedFormGroup;
      if (group) {
        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.email));
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
      } else {
        const id = obj ? obj.id : '';
        const emailType = obj ? obj[this.typeControlName] : '';
        const emailId = obj ? obj[this.controlName] : '';
        const primaryEmail = obj ? obj.primaryEmail : false;
        const privateInformtionEmail = obj ? obj.privateInformtionEmail : false;
        const isSynced = obj ? obj.isSynced : false;
        this.addEmailItem(id, emailType, emailId, primaryEmail, privateInformtionEmail, isSynced);
      }
    });
  }

  private getMailOptions() {
    let option = [
      { id: this.mailTypes.office, description: this.captions.drp_txt_office, type: ContactType.email },
      { id: this.mailTypes.personal, description: this.captions.drp_txt_personal, type: ContactType.email }];    
      if (this.showMoreTypes) {
      option.push({ id: this.mailTypes.home, description: this.captions.drp_txt_home, type: ContactType.email },
        { id: this.mailTypes.business, description: this.captions.drp_txt_business, type: ContactType.email });
    }
    return option;
  }

  onclick() {
    if (document.designMode == 'on') {
     this.dialog.open(AgMenuEditComponent, {
        width: '700px',
        height: '700px',
        data: {
          oldPlaceHolder: this.placeHolder,
          oldErrorMessage: this.errorMessage
        },
        disableClose: true
      }).afterClosed().subscribe(result => {
        if (result != undefined) {
          this.placeHolder = result.newplaceholder;
          this.errorMessage = result.newErrorMessage;
        }
      });
    }
  }

}
