import { FormGroup } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey, MasterInventoryManagement } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';

export const CustomSubTypeChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: MasterInventoryManagement,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData: MatDialog
) => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            const PrimaryFormFields = MasterDataCollection.FormConfig.FormFields;
            const selectedTypeId = FormGroupData.value.TypeId;
            if (selectedTypeId) {
                const customOdataUrl = `/inventory/api/IngredientSubTypes?$filter=IsActive eq true and InventoryTypeId eq ${selectedTypeId}&$select=Id,Name&$orderby=Name ASC`;
                httpService.GethttpMethod(customOdataUrl).subscribe(res => {
                    const subTypeIndex = PrimaryFormFields.findIndex(f => f.fieldType.SingleData.name === 'SubTypeId');
                    if (subTypeIndex > -1 && res['value']?.length > 0) {
                        let mapDropDownData = [];
                        res['value'].map(e => {
                            const radioOptions = {} as RadioOptions;
                            radioOptions.key = e['Id'];
                            radioOptions.label = e['Name'];
                            mapDropDownData.push(radioOptions);
                        });
                        MasterDataCollection.FormConfig.FormFields[subTypeIndex].fieldType.SingleData.options = mapDropDownData;
                    }                    
                });
            }
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};

