import { FormGroup } from '@angular/forms';
import { PeriodLength } from 'src/app/eatecui/source/shared/enum/global.enum';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import moment from 'moment';

export const CustomMealPlanStartDate: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: TransactionConfiguration
): any => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            console.log('CustomMealPlanStartDate');
            if (FormField.length !== MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields.length) {
                FormField = MasterDataCollection.CreateTranscationConfig.TransactionFormConfig.FormFields;
            }
            const StartDate = FormGroupData.value['StartDate'];
            const PlanPeriodLengthId = FormGroupData.value['PlanPeriodLengthId'];
            const PlanPeriodId = FormGroupData.value['PlanPeriodId'];
            switch (PlanPeriodLengthId) {
                case PeriodLength.Week:
                    const weekend = moment(StartDate).add(PlanPeriodId, 'weeks').subtract(1, 'days').format('MM/DD/YYYY');
                    FormGroupData.controls['EndDate'].setValue(new Date(weekend));
                    FormGroupData.updateValueAndValidity();
                    break;
                case PeriodLength.Month:
                    const monthEnd = moment(StartDate).add(PlanPeriodId, 'months').subtract(1, 'days').format('MM/DD/YYYY');
                    FormGroupData.controls['EndDate'].setValue(new Date(monthEnd));
                    FormGroupData.updateValueAndValidity();
                    break;
                case PeriodLength.Day:
                    const dayEnd = moment(StartDate).add(PlanPeriodId, 'days').subtract(1, 'days').format('MM/DD/YYYY');
                    FormGroupData.controls['EndDate'].setValue(new Date(dayEnd));
                    FormGroupData.updateValueAndValidity();
                    break;
            }
        }

    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

export interface DropdownOption {
    Key: any;
    Value: any;
}