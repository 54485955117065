<section class="golf__formsection" [formGroup]="form">
    <div [formArrayName]="field.name">
      <div *ngFor="let formObj of form.get(field.name)['controls']; let j=index">
        <div [formGroupName]="j">
          <mat-button-toggle-group #group="matButtonToggleGroup" [value]="field.value" [formControlName]="field.name"  aria-labelledby="example-toggle-group-label">
            <mat-button-toggle value="left" aria-label="Text align left" *ngFor="let opt of field.fieldArray[j].options">
              {{opt.label}}
              <span></span>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <app-form-icon *ngIf="field.addMore" [formIconType]="formBuilderIcons" [fieldArray]="field.fieldArray[j]" [formObject]="form.get(field.name)" (iconEmitter)="iconCallback($event)" (fielArrayEmiter)="fieldArrayCallback($event)"></app-form-icon>
      </div>
    </div>
  </section>