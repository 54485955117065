import { Component, Input } from '@angular/core';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';

@Component({
  selector: 'app-appointment-recap-info',
  templateUrl: './appointment-recap-info.component.html',
  styleUrls: ['./appointment-recap-info.component.scss']
})
export class AppointmentRecapInfoComponent {
  @Input() history;
  @Input() recapInfo;
  @Input() showCustomFee: boolean;
  @Input() customFeeTaxAdded: boolean;
  @Input() captions: any;
  @Input() appointmentRetailItems: any;
  @Input() appointmentLockerItems: any;
  constructor(public localization: SpaLocalization){

  }

}
