import { Pipe, PipeTransform } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';

@Pipe({
  name: 'priceround'
})
export class PriceRoundPipe implements PipeTransform {
  constructor(){}
  transform(value: any,configuredDecimalPlaces = 2): any {
    if(sessionStorage.getItem('noOfDecimalDigits')){
      let propDigits = parseInt(sessionStorage.getItem('noOfDecimalDigits'));
      if(!isNaN(configuredDecimalPlaces)){
        configuredDecimalPlaces = propDigits;
      }
    }
    let configuredDecimalPlacesRounding;
    if(configuredDecimalPlaces > 0){
      configuredDecimalPlacesRounding = this.evenRound(value,configuredDecimalPlaces);
    } else {
      configuredDecimalPlacesRounding = Math.round(value);
    }
    return configuredDecimalPlacesRounding.toFixed(configuredDecimalPlaces);
  }
  evenRound(num, decimalPlaces){
    var d = decimalPlaces || 0;
    var m = Math.pow(10, d);
    var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    var i = Math.floor(n), f = n - i;
    var e = 1e-8; // Allow for rounding errors in f
    var r = (f > 0.5 - e && f < 0.5 + e) ?
                ((i % 2 == 0) ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
  }

}