
import { GridHeaderConfig, TransactionConfiguration } from '../transaction.interface';
import { HideColumn } from './create-transaction-config.interface';
import _ from 'lodash';

export const OpenCurrencyFieldsForPO: HideColumn = (
    TransactionCollection: TransactionConfiguration, transactionFrom: string): TransactionConfiguration => {
    try {
        if (TransactionCollection.CreateTranscationConfig) {
            let rguestTenantId = false;
            const checkTenant = sessionStorage.getItem('RGuestTenantId');
            if ( checkTenant && JSON.parse(checkTenant)) {
                rguestTenantId = true;
            }
            if (!rguestTenantId && (transactionFrom === 'processpo' || transactionFrom === 'EditPO')) {
                const EnableFields = ['TransactionCurrencyId', 'ExchangeRate'];
                TransactionCollection.CreateTranscationConfig.DetailsConfig.DetailsFormModel.DetailsBuilder.forEach(fields => {
                    if (EnableFields.findIndex(x => x === fields.Name) !== -1) {
                        fields.DisableProperty = true;
                        fields.Hide = false;
                        fields.DisableRequest = false;
                    }
                });
            }            
            return TransactionCollection;
        }
    } catch (Error) {
        console.error('Error occurred NonEditableGridColumn', Error);
    }
};