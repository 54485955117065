import { Pipe, PipeTransform } from '@angular/core';
// import { first } from 'rxjs/operators';

@Pipe({name: 'getIconName'})
export class GetIconName implements PipeTransform {
  transform(str, args): any {
    if (str && typeof str === 'string') {
        const matches = str.toString().match(/( \b\w)|^\w/g)?.map(ele => ele.replace(' ', '')); // ['J','S','O','N']
        if (matches) {
          const acronym = matches.join('');
        return acronym.substring(0, 2);
        } else {
          return '-';
        }

      }
  }
}
