import { FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const GetCustomCurrencyChange: CustomFormConfiguration = (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: FormGroup, FormField: any, CustomData?: TransactionConfiguration
): any => {
    try {
        const TransactionCurrencyId = FormGroupData.value['CurrencyId'];
        const BaseCurrencyId = parseInt(sessionStorage.getItem('BaseCurrency'), 10);
        if (BaseCurrencyId && TransactionCurrencyId && BaseCurrencyId !== TransactionCurrencyId) {
            const EndPointUrl = `/inventory/api/GetExchangeRate/${BaseCurrencyId}/${TransactionCurrencyId}`;
            httpService.GethttpMethod(EndPointUrl).subscribe(res => {
                if (res) {
                    FormGroupData.controls['ExchangeRate'].setValue(res['ExchangeRate']);
                    CommonService.triggerBlurEvent('ExchangeRate');
                    const PriceValue = FormGroupData.controls['Price'].value ? parseFloat(FormGroupData.controls['Price'].value.toString()) : 0;
                    if (PriceValue && parseFloat(PriceValue.toString()) > 0 && res['ExchangeRate'] > 0) {
                        const PriceInFC = parseFloat((PriceValue * res['ExchangeRate']).toString()).toFixed(4);
                        FormGroupData.controls['PriceInFC'].setValue(PriceInFC);
                        CommonService.triggerBlurEvent('PriceInFC');
                    }
                } else {
                    FormGroupData.controls['ExchangeRate'].setValue(1);
                    CommonService.triggerBlurEvent('ExchangeRate');
                    FormGroupData.controls['PriceInFC'].setValue(1);
                    CommonService.triggerBlurEvent('PriceInFC');
                }
            });
        } else {
            FormGroupData.controls['ExchangeRate'].setValue(1);
            CommonService.triggerBlurEvent('ExchangeRate');
            FormGroupData.controls['PriceInFC'].setValue(1);
            CommonService.triggerBlurEvent('PriceInFC');
        }
    } catch (error) {
        console.log('error occured CustomTypeChange', error);
    }
};