import { Injectable, Inject, OnInit, Injector, ComponentRef, ElementRef, ViewChild } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ReorderPanelComponent } from '../reorder-panel/reorder-panel.component';
import { HeaderOverlayRef } from './header-overlay-ref';
import { GRID_NAME, HEADER_COLUMN_DATA } from './header-token';
import { ResizeRowsComponent } from '../resize-rows/resize-rows.component';

export interface Image {
  name: string;
  url: string;
}

interface HeaderConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  columns?: any;
  gridName?: string;
  filterOptions?: string;
}

const DEFAULT_CONFIG: HeaderConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: '',
  columns: null
  // tm-file-preview-dialog-panel
};

@Injectable({
   providedIn: 'root'
})

export class GridHeaderService {

  constructor(
    private injector: Injector,
    private overlay: Overlay,
    ) { }

  open(config: HeaderConfig = {}, reorder: ElementRef) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig, reorder);

    // Instantiate remote control
    const dialogRef = new HeaderOverlayRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe(_ => {dialogRef.close(); });

    // overlayRef.detach().subscribe( x => // console.log('overlay detached'));
    const bodyData = document.getElementsByTagName('body')[0];
    const cdkOverlayWrappers = document.getElementsByClassName(
          'cdk-global-overlay-wrapper'
        );
    const particulardiv = document.getElementsByClassName('cdk-overlay-connected-position-bounding-box');
    if (cdkOverlayWrappers && cdkOverlayWrappers.length > 0) {
      bodyData.insertBefore(cdkOverlayWrappers[0].parentNode, particulardiv[0].parentNode);​    
    }
    return dialogRef;
  }

  private createOverlay(config: HeaderConfig, reorder: ElementRef) {
    const overlayConfig = this.getOverlayConfig(config, reorder);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: HeaderConfig, dialogRef: HeaderOverlayRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(ReorderPanelComponent, null, injector);
    const containerRef: ComponentRef<ReorderPanelComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: HeaderConfig, dialogRef: HeaderOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(HeaderOverlayRef, dialogRef);
    injectionTokens.set(HEADER_COLUMN_DATA, config.columns);
    injectionTokens.set(GRID_NAME, config.gridName);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: HeaderConfig, reorder: ElementRef): OverlayConfig {
    const info = reorder.nativeElement.getBoundingClientRect();
    const pos: ConnectedPosition [] = [
      {
        // offsetX : (info.right - 300),
        offsetY : info.height,
        overlayX: 'end',
        overlayY: 'top',
        originX: 'end',
        originY: 'top',
      }
  ];
    const positionStrategy = this.overlay.position().flexibleConnectedTo(reorder).
    withPositions(pos);
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      disposeOnNavigation: true
    });

    return overlayConfig;
  }

  openResizeRows(config: HeaderConfig = {}, resize: ElementRef) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig, resize);

    // Instantiate remote control
    const dialogRef = new HeaderOverlayRef(overlayRef);

    const overlayComponent = this.attachReSizeDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe(_ => {dialogRef.close(); });
    return dialogRef;
  }

  private attachReSizeDialogContainer(overlayRef: OverlayRef, config: HeaderConfig, dialogRef: HeaderOverlayRef) {
    const injectionTokens = new WeakMap();
    injectionTokens.set(HeaderOverlayRef, dialogRef);
    // injectionTokens.set(GRID_NAME, config.gridName);
    const injector = new PortalInjector(this.injector, injectionTokens);
    const containerPortal = new ComponentPortal(ResizeRowsComponent, null , injector);
    const containerRef: ComponentRef<ResizeRowsComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }
}
