import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgyDynamicFormServices } from '../../agilysys-dynamicform-business';

@Component({
  selector: 'lib-label-text',
  templateUrl: './label-text.component.html',
  styleUrls: ['./label-text.component.scss']
})
export class LabelTextComponent implements OnInit {
  @Input() field: any = {};
  @Input() form: FormGroup;
  constructor(public agyDynamicFormServices: AgyDynamicFormServices) { }

  ngOnInit(): void {
  }

  IconClick(): void {
    this.agyDynamicFormServices.formEventCallback(this.field, 'edit', this.form);
  }
}
