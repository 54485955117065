import { TranslateService } from '@ngx-translate/core';
import { ActionTypes } from 'src/app/eatecui/source/shared/models/custom-action/custom-enum.interface';
import { GlobalCustomFormAction } from 'src/app/eatecui/source/shared/models/custom-action/global-custom-action.interface';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { TransactionConfiguration } from '../transaction.interface';
import { ViewGridConfiguration } from './grid-transaction-config.interface';
export const ViewGridDataConfig: ViewGridConfiguration = (
    ViewHeaderEntity: any,
    ViewDetailsEntity: any,
    TransactionCollection: TransactionConfiguration,
    httpService: HttpService,
    translateService?: TranslateService, TilesHeaderTrascationEntity?: any, responseData?: Array<any>): Array<any> => {
    try {
        if (ViewHeaderEntity && ViewDetailsEntity && TransactionCollection) {
            responseData.forEach(dataobject => {
                for (const prop in dataobject) {
                    if (prop) {
                        const mergedValue = GlobalCustomFormAction(
                            prop, prop, prop, 'GridAction', ActionTypes.CUSTOMMERGE, null, null, null, null, null, null, dataobject);
                        if (mergedValue && mergedValue !== undefined && mergedValue !== null) {
                            dataobject[prop] = mergedValue;
                        }
                        if (dataobject[prop] && dataobject[prop] !== null) {
                            const propType = typeof dataobject[prop];
                            // dataobject[prop] = Formater(prop, dataobject[prop], ViewHeaderEntity, ViewDetailsEntity);
                            const isArrayprop = Array.isArray(dataobject[prop]);
                            if (propType === 'object' && !isArrayprop) {
                                // const objProperity = dataobject[prop];
                                assignNavigationProperity(prop, dataobject[prop], dataobject, ViewHeaderEntity);
                            }
                        }
                    }
                }
            });
            return responseData;
        }

    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};

function assignNavigationProperity(ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl[0].hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl[0]['NavigationProperty'].length > 0) {
        ViewModelEntityColl[0]['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'].toLowerCase().toString();
            if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
                if (element['ReferentialConstraint']) {
                    const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toLowerCase().toString();
                    const getObjectValue = ProperirtyObject['Name'] ? ProperirtyObject['Name']: ProperirtyObject['name'] ? ProperirtyObject['name'] : ProperirtyObject['UnitName'] ? ProperirtyObject['UnitName']
                        : ProperirtyObject['Code'] ? ProperirtyObject['Code'] : ProperirtyObject['UnitCode'] ? ProperirtyObject['UnitCode'] : null;
                    if (getObjectValue && getObjectValue !== undefined && getObjectValue !== null) {
                        entityObject[referelcontraintName] = getObjectValue;
                    }
                    const mergedValue = GlobalCustomFormAction(
                        referelcontraintName, referelcontraintName, referelcontraintName, 'GridAction', ActionTypes.CUSTOMMERGE,
                        null, null, null, null, null, null, entityObject);
                    if (mergedValue && mergedValue !== undefined && mergedValue !== null) {
                        entityObject[referelcontraintName] = mergedValue;
                    }
                }
            }
        });
    }
    return entityObject;
}