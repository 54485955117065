import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'lib-approval-actions',
  templateUrl: './approval-actions.component.html',
  styleUrls: ['./approval-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApprovalActionsComponent implements OnInit {
  params: any;
  iconLists: Array<ApprovalActionIcons>;
  remarkValue: string;
  @ViewChild('clickRemarkMenuTrigger') clickRemarkMenuTrigger: MatMenuTrigger;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params) {
    this.iconLists = [{
      Id: 1,
      IconName: 'remarks',
      IconClass: 'icon-Remarks_Icon-eatec',
      TooltipValue: 'Remarks',
      Disabled: false
    }, {
      Id: 2,
      IconName: 'approval',
      IconClass: 'icon-Approve_Icon-eatec',
      TooltipValue: 'Approve',
      Disabled: false
    }, {
      Id: 3,
      IconName: 'deny',
      IconClass: 'icon-Deny_Icon-eatec',
      TooltipValue: 'Deny',
      Disabled: false
    }, {
      Id: 4,
      IconName: 'hold',
      IconClass: 'icon-Hold_Icon-eatec',
      TooltipValue: 'Hold',
      Disabled: false
    }];
    this.params = params;
    this.iconLists[0].IconClass = !this.params.data.enableCheck ? 'icon-Remarks_Disabled-eatec' : 'icon-Remarks_Icon-eatec';
    this.iconLists[0].HighlightClass = this.params.data.remark ? 'remarks-highlight' : '';
    this.iconLists[1].IconClass = !this.params.data.enableCheck ? 'icon-Approve_Disabled-eatec' : this.params.data.IsApproved ? 'icon-Approve_Filled-eatec' : 'icon-Approve_Icon-eatec';
    this.iconLists[2].IconClass = !this.params.data.enableCheck ? 'icon-Deny_Disabled-eatec' : this.params.data.IsDenied ? 'icon-Deny_Filled-eatec' : 'icon-Deny_Icon-eatec';
    this.iconLists[3].IconClass = !this.params.data.enableCheck ? 'icon-Hold_Disabled-eatec' : this.params.data.IsHold ? 'icon-Hold_Filled-eatec' : 'icon-Hold_Icon-eatec';
    this.iconLists[0].Disabled = !this.params.data.enableCheck;
    this.iconLists[1].Disabled = !this.params.data.enableCheck;
    this.iconLists[2].Disabled = !this.params.data.enableCheck;
    this.iconLists[3].Disabled = !this.params.data.enableCheck;
  }

  approvalActionClick(icons: ApprovalActionIcons, event) {
    if (icons.Disabled !== true) {
      event.stopPropagation();
      const allColumns = this.params.columnApi.getAllColumns();
      allColumns.forEach(element => {
        element.colDef.editable = false;
      });
      if (icons.IconName !== 'remarks') {
        this.clickRemarkMenuTrigger.closeMenu();
        switch (icons.IconName) {
          case 'approval':
            this.params.data.IsApproved = !this.params.data.IsApproved;
            this.params.data.IsDenied = false;
            this.params.data.IsHold = false;
            break;
          case 'deny':
            this.params.data.IsDenied = !this.params.data.IsDenied;
            this.params.data.IsApproved = false;
            this.params.data.IsHold = false;
            break;
          case 'hold':
            this.params.data.IsHold = !this.params.data.IsHold;
            this.params.data.IsDenied = false;
            this.params.data.IsApproved = false;
            break;
        }
      } else {
        this.remarkValue = this.params.data.remark;
      }
      this.iconLists[1].IconClass = !this.params.data.enableCheck ? 'icon-Approve_Disabled-eatec' : this.params.data.IsApproved ? 'icon-Approve_Filled-eatec' : 'icon-Approve_Icon-eatec';
      this.iconLists[2].IconClass = !this.params.data.enableCheck ? 'icon-Deny_Disabled-eatec' : this.params.data.IsDenied ? 'icon-Deny_Filled-eatec' : 'icon-Deny_Icon-eatec';
      this.iconLists[3].IconClass = !this.params.data.enableCheck ? 'icon-Hold_Disabled-eatec' : this.params.data.IsHold ? 'icon-Hold_Filled-eatec' : 'icon-Hold_Icon-eatec';
      this.params.context.parentComponent.rowActionButtonClick(this.params, this.params.data, icons.IconName.toLowerCase());
    }
  }

  closeMenu() {
    this.remarkValue = null;
    this.clickRemarkMenuTrigger.closeMenu();
  }

  menuCloses(event) {
    this.params.context.parentComponent.moreData(this.params);
  }

  postRemarks() {
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    rowNode.setDataValue('remark', this.remarkValue);
    this.clickRemarkMenuTrigger.closeMenu();
    this.params.api.redrawRows({ rowNodes: [rowNode] });
  }

  clearAllRemarks() {
    this.remarkValue = null;
    const rowNode = this.params.api.getRowNode(this.params.rowIndex);
    rowNode.data.remark = null;
    rowNode.setDataValue('remark', this.remarkValue);
    this.clickRemarkMenuTrigger.closeMenu();
    const refreshRowNode = this.params.api.getRowNode(this.params.rowIndex);
    this.params.api.redrawRows({ rowNodes: [refreshRowNode] });
  }
}

export interface ApprovalActionIcons {
  Id: number;
  IconName: string;
  TooltipValue: string;
  IconClass: string;
  HighlightClass?: string;
  Disabled?: boolean;
}


