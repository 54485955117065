import { FormGroup, Validators } from '@angular/forms';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import { FormBuilderGroupKey } from 'src/app/eatecui/source/setup-master/shared/interface/master.interface';
import { CustomFormField } from '.';
import { TransactionConfiguration } from '../transaction.interface';
import { CustomFormConfiguration } from './custom-form-configuration.interface';
import { RadioOptions } from 'src/app/eatecui/dist/agilysys-dynamicform';

export const CustomGetCustomer: CustomFormConfiguration = async (
    ViewModelEntity: any, PostModelEntity: any,
    MasterDataCollection: any,
    httpService: HttpService,
    FieldGrouping: FormBuilderGroupKey,
    FormGroupData: any, FormField: any, CustomData?: TransactionConfiguration
): Promise<any> => {
    try {
        if (FieldGrouping.CustomFormAction.EnableCustomMethod) {
            const getCurrentLocationId = (FormGroupData?.controls && FormGroupData.controls[FieldGrouping.OdataKey]) ? 
            FormGroupData.controls[FieldGrouping.OdataKey].value : FormGroupData?.FromLocId ? FormGroupData.FromLocId :
            FormGroupData?.RequestLocId;
            const transferOutCheck = FormGroupData?.value && FormGroupData.value['IsTransferOut'] ? FormGroupData.value['IsTransferOut'] : false;
            if (FieldGrouping.CustomFormAction.hasOwnProperty('DisabledField') &&
                FieldGrouping.CustomFormAction.DisabledField && !transferOutCheck) {
                if (getCurrentLocationId) {
                    let authPayload = {} as any;
                    authPayload.ClientUrl = '/inventory/api/GetCustomers';
                    const customerPostPayload = {} as any;
                    customerPostPayload.transTypeId = MasterDataCollection.TransactionType;
                    customerPostPayload.locationId = FormGroupData?.value?.FromLocId ? FormGroupData.value.FromLocId : 
                    FormGroupData?.value?.RequestLocId ? FormGroupData.value.RequestLocId : FormGroupData?.value?.LocationId ? 
                    FormGroupData.value.LocationId : FormGroupData.RequestLocId;
                    customerPostPayload.searchText = '';
                    customerPostPayload.skip = 0;
                    customerPostPayload.take = 100;
                    customerPostPayload.getTotalRecCount = true;
                    customerPostPayload.fetchAll = false;
                    customerPostPayload.totalCount = 0;
                    customerPostPayload.lookupField = '';
                    customerPostPayload.orderByColumn = 'Name';
                    customerPostPayload.orderByDirection = 1;
                    authPayload.Payload = customerPostPayload;
                    const customerResultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
                    FormField.forEach((x: any) => {
                        if (x.hasOwnProperty('fieldType') && x.fieldType.hasOwnProperty('SingleData') &&
                            x.fieldType.SingleData.name === 'CustomerId') {
                            x.fieldType.SingleData.disbaledProperty = false;
                        }
                    });
                    const customerDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'CustomerId');
                    FormField[customerDropdownIndex].fieldType.SingleData.autoCompleteData = customerResultData.ResultData;
                    FormField[customerDropdownIndex].autoCompleteConfig.EnableLoadMore =
                        customerResultData.ResultData?.length === customerResultData.TotalCount ? false : true;
                    // FormGroupData.controls['CustomerId'].setValidators([Validators.required]);
                    // FormGroupData.controls['CustomerId'].markAsTouched();
                    // FormGroupData.controls['CustomerId'].updateValueAndValidity();
                } else {
                    // FormGroupData.controls['CustomerId'].clearValidators();
                    // FormGroupData.controls['CustomerId'].setValue(null);
                    FormField.forEach((x: any) => {
                        if (x.type === 'multipleautocomplete') {
                            if (x.hasOwnProperty('fieldType') && x.fieldType.hasOwnProperty('SingleData') &&
                                x.fieldType.SingleData.name === 'CustomerId') {
                                x.fieldType.SingleData.disbaledProperty = true;
                                x.fieldType.SingleData.autoCompleteData = [];
                            }
                        }
                    });
                }
                // FormGroupData.controls['CustomerId'].updateValueAndValidity();

            }
        }
        if (MasterDataCollection.OdataKey === 'VendorPunchout') {
            const getCurrentLocationId = (FormGroupData?.controls && FormGroupData.controls[FieldGrouping.OdataKey]) ?
                FormGroupData.controls[FieldGrouping.OdataKey].value : null;
            const supplierDropdownIndex = FormField.findIndex(f => f.fieldType.SingleData.name === 'SupplierId');
            if (getCurrentLocationId) {
                let authPayload = {} as any;
                authPayload.ClientUrl = '/inventory/api/GetSuppliers';
                const supplierPostPayload = {} as any;
                supplierPostPayload.skip = 0;
                supplierPostPayload.take = 100;
                supplierPostPayload.fetchAll = true;
                supplierPostPayload.getTotalRecCount = true;
                supplierPostPayload.totalCount = 0;
                supplierPostPayload.searchText = '';
                supplierPostPayload.lookupField = '';
                supplierPostPayload.orderByColumn = 'Name';
                supplierPostPayload.orderByDirection = 1;
                supplierPostPayload.transTypeId = MasterDataCollection.TransactionType;
                supplierPostPayload.locationId = FormGroupData?.value?.LocationId;
                supplierPostPayload.IsActive = true;
                authPayload.Payload = supplierPostPayload;
                const resultData = await httpService.PosthttpMethod(authPayload.ClientUrl, authPayload.Payload).toPromise();
                FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = false;
                FormGroupData.controls['SupplierId'].setValidators([Validators.required]);
                FormGroupData.controls['SupplierId'].markAsTouched();
                let mapDataDropDownArray: RadioOptions[] = []
                resultData.ResultData?.map(e => {
                    const radioOptions = {} as RadioOptions;
                    radioOptions.key = e['Id'];
                    radioOptions.label = e['Name'];
                    mapDataDropDownArray.push(radioOptions);
                });
                FormField[supplierDropdownIndex].fieldType.SingleData.options = mapDataDropDownArray;
                if (FormGroupData.controls['SupplierId'].value !== '' && FormGroupData.controls['SupplierId'].value !== null ||
                    FormGroupData.controls['SupplierId'].value !== undefined) {
                    if (mapDataDropDownArray.length > 0) {
                        const existIndInNewCollec = mapDataDropDownArray.findIndex(f => f.key === FormGroupData.value.SupplierId);
                        if (existIndInNewCollec === -1) {
                            FormGroupData.controls['SupplierId'].setValue(null);
                            FormGroupData.controls['SupplierId'].setValidators([Validators.required]);
                            FormGroupData.controls['SupplierId'].markAsTouched();
                        }
                    } else {
                        FormGroupData.controls['SupplierId'].setValue(null);
                    }
                }
                FormGroupData.controls['SupplierId'].updateValueAndValidity();
            } else {
                FormField[supplierDropdownIndex].fieldType.SingleData.disbaledProperty = true;
                FormGroupData.controls['SupplierId'].reset();
                FormGroupData.controls['SupplierId'].clearValidators();
                FormGroupData.controls['SupplierId'].updateValueAndValidity();
            }
        }
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};