import { Injectable } from '@angular/core';
import { API, UI } from '../../Models/property-information.model';
import { USER_SESSION } from 'src/app/app-constants';
import { UI as PropertyUI } from '../../Models/property-settings.model';
import { Params, Router } from '@angular/router';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { Localization } from '../../localization/localization';
import { Product, UserProperty } from '../../Models/common.models';
import { PropertyService } from '../../services/property.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { PropertyInformationDataService } from '../../dataservices/authentication/property-information.data.service';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';

@Injectable()
export class ChangePropertyBusiness {

    captions;
    private propertyDetails: UI.PropertyDetails[];
    private isChangePropertyEnabled: boolean;

    constructor(
        private router: Router,
        private propertyService: PropertyService,
        private propertyInfoDataService: PropertyInformationDataService,
        private propertyInformationService: CommonPropertyInformation,
        private localization: Localization,
        public storageservice:StorageService,
        private sessionService: ManageSessionService) {
        this.captions = this.localization.captions;
        this.isChangePropertyEnabled = this.propertyService.isChangePropertyEnabled();
    }

    public async GetPropertyInfo(): Promise<UI.PropertyDetails[]> {
        let propertyDetails = await this.propertyInfoDataService.GetPropertiesInformationByUserIdAndProductId(this.getUserId(), this.getProductId());
        propertyDetails = propertyDetails.filter(x => x.id !== Number(this.getPropertyId()));
        return this.mapToUIPropertyDetails(propertyDetails);
    }


    public async GetPropertiesDropDown(): Promise<DropdownOptions[]> {
        let propertyDetails = await this.propertyInfoDataService.GetPropertiesInformationByUserIdAndProductId(this.getUserId(), this.getProductId());
        let response = propertyDetails.map(result => {
            return {
                id: result.id,
                value: result.id,
                viewValue: result.propertyName,
            };            
        });
        return this.orderBY(response);
    }

    private orderBY(array: DropdownOptions[]) {

        if (array && array.length > 0) {

            return array.sort(function (a, b) {
                var nameA = a.viewValue.toLowerCase(), nameB = b.viewValue.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            });
        }
        else
            return array;
    }

    public async GetPropertyConfigurationInfo(): Promise<PropertyUI.PropertyConfiguration[]> {
        return this.propertyService.GetPropertyConfigDetailsByUserId(this.getUserId());
    }

    public async GetTokenForSelectedProperty(selectedProperty: UI.PropertyDetails) {
        return this.propertyInfoDataService.GetLoginToken(this.getUserSessionId(), selectedProperty.id, this.getProductId());

    }

    public async changeProperty(propertyId: number) {
        if (this.isChangePropertyEnabled) {
            let userDetails = this.sessionService.GetUserSessionsInfo();
            const propertyDetails: UserProperty = userDetails.userProperties.find(
                item => item.propertyId === propertyId
            );
            let selectedPropertyDetails = {
                id: propertyDetails.propertyCode,
                name: propertyDetails.propertyName
            };
            this.propertyService.SetUserInfo(userDetails);
            await this.propertyService.UpdateUserSessionInfo(selectedPropertyDetails, userDetails);
            let usersessionId = '';
            if (propertyDetails.sessionId !== null) {
                usersessionId = propertyDetails.sessionId;
            } else {
                usersessionId = String( await this.sessionService.createSession());
            }
            this.propertyService.setUserSession(String(usersessionId));
            this.propertyService.changeTitle();
        }
    }

    private getFormattedPath(pathName: string) {

        if (pathName.includes('pms/')) {
            var splits = pathName.split("pms/")
            pathName = splits[1];
        }

        if (pathName.includes('#/')) {
            var splits = pathName.split('#/');
            pathName = splits[1];
        }
        return pathName;
    }

    public async openNewProperty(hostName: string, token: string, selectedProperty: UI.PropertyDetails, param: Params) {
        this.storageservice.setstoragekey('isPropertychange','true')
        let productId = Number(this.getProductId());
        let pathName = (param.stayId) ? '/pms/reservations/reservationslist' : (param.bookingId) ? 'pms/groups/bookings' : productId==Product.PMS? window.location.hash: window.location.pathname;
        let FormattedPath = this.getFormattedPath(pathName);
        const routeParam = (FormattedPath) ? FormattedPath : '';
        if (this.isChangePropertyEnabled && hostName === window.location.origin) {
            const navigateUrl = this.propertyInformationService.GetChangePropertyUrlForSameTab(token,
                selectedProperty.id, selectedProperty.propertyName, routeParam);
            this.router.navigate([navigateUrl],
                {
                    state: {
                        token,
                        propertyId: selectedProperty.id,
                        propertyName: selectedProperty.propertyName,
                        routeParam
                    }
                }
            );
        } else {
            const changeUrl = this.propertyInformationService.GetChangePropertyUrlForNewTab(hostName, token,
                selectedProperty.id, selectedProperty.propertyName, routeParam);
            window.open(changeUrl);
        }
        return;
    }

    private mapToUIPropertyDetails(propertyDetails: API.PropertyDetails[]): UI.PropertyDetails[] {
        this.propertyDetails = propertyDetails.map(propertyDetail => {
            return {
                contextId: propertyDetail.contextId,
                currencyId: propertyDetail.currencyId,
                id: propertyDetail.id,
                location: propertyDetail.location,
                locationId: propertyDetail.locationId,
                platformContextId: propertyDetail.platformContextId,
                propertyAddress: propertyDetail.propertyAddress,
                propertyCode: propertyDetail.propertyCode,
                propertyContacts: propertyDetail.propertyContacts,
                propertyName: propertyDetail.propertyName,
                propertyProducts: propertyDetail.propertyProducts,
                propertySettings: propertyDetail.propertySettings,
                viewValue: propertyDetail.propertyName
            };
        });
        return this.propertyDetails.sort((a, b) => a.propertyName.toLowerCase() > b.propertyName.toLowerCase() ? 1 : -1);
    }


    private getUserId() {
        return this.localization.GetsessionStorageValue('_userInfo', 'userId');
    }

    private getProductId() {
        return this.localization.GetsessionStorageValue('propertyInfo', 'ProductId');
    }

    public getPropertyId() {
        return this.localization.GetsessionStorageValue('propertyInfo', 'PropertyId');
    }

    private getUserSessionId() {
        return sessionStorage.getItem(USER_SESSION);
    }

    public changePropertyAllowed() {
        return this.isChangePropertyEnabled;
    }
}
