import { MasterInventoryManagement } from '../master.interface';
import { GridModelHeaderDefination } from './grid-config.interface';
// import * as LanguageJson from '@assets/i18n/en.json';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { TranslateService } from '@ngx-translate/core';

export const GridColumnHeaderName: GridModelHeaderDefination = (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement, 
    EntityProperty: any, translateService: TranslateService): 
string => {
    try {
        if (ViewModelEntity.length > 0 && MasterDataCollection ) {
            // let langJson = (LanguageJson as any).default;
            // const productremapJson = (remapjson as any).default;
            // let langJson = translateService.store.translations['en'];
            // const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
            // langJson = ProductDataRemap.DisplayNameRemapEngine(null, productremapJson);
            const  ProperityName = EntityProperty['$']['Name'];
            const EntityName = ViewModelEntity[0]['$']['Name'];
            const translatedName = translateService.instant( EntityName + `.${ProperityName}`);
            const commonTranslatedName = translateService.instant( `Common.${ProperityName}`);
            const ColumnHeaderName = ( translatedName ) ? translatedName : 
            ( commonTranslatedName ) ? commonTranslatedName : ProperityName;
            return ColumnHeaderName.toLowerCase() === 'isactive' ? '' : ColumnHeaderName;
        } 
    } catch (error) {
        console.log('error occured EnableGridFilter', error);
    }
};