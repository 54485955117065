import { Component, OnInit} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { report } from 'process';
import { from, of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { UserAccessDataService } from 'src/app/common/dataservices/authentication/useraccess.data.service';
import { JobType } from 'src/app/common/Models/notification.model';
import { AgDropdownConfig, AgInputFieldConfig, ButtonValue, DropdownOptions } from '../../../Models/ag-models';
import { Localization } from '../../localization/Localization';
import { AlertAction } from '../../shared.modal';
import { AlertType } from '../enums/enums';
import { EventDetailsBusiness } from '../event-scheduler/event-details/event-details.business';
import { ButtonType } from '../globalsContant';
import { SftpSetupBusiness } from '../sftp-setup/sftp-setup.business';
import { CommonUtilities } from '../utilities/common-utilities';
import { EventSchedulerConfigurationBusiness } from './event-scheduler-configuration.business';
import { customUrlUI, DefaultUrlConfigAPI,DefaultUrlConfigUI,UI } from './event-scheduler-configuration.model';

@Component({
    selector: 'app-event-scheduler-configuration',
    templateUrl: './event-scheduler-configuration.component.html',
    styleUrls: ['./event-scheduler-configuration.component.scss'],
    providers: [EventSchedulerConfigurationBusiness,EventDetailsBusiness,SftpSetupBusiness]
  })

  export class EventSchedulerConfigurationComponent implements OnInit
  {
    captions;
    eventSchedulerConfigForm: UntypedFormGroup;
    jobTypeConfig: AgDropdownConfig;
    defaultSFTPConfig: AgDropdownConfig;
    jobTypeList: any;
    apiList: any;
    defaultSFTPOptions: Promise<DropdownOptions[]>;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    url: AgInputFieldConfig;
    cancelButton: ButtonValue;
    saveButton: ButtonValue;
    FormArray: UntypedFormArray;
    enableApi : boolean;
    enableUrl : boolean = true;
    isViewOnly: boolean = false;
    jobDefaultUrlUIConfig :DefaultUrlConfigUI.DefaultConfig[];
    sftpOption : any;

    constructor(private localization: Localization,private fb: UntypedFormBuilder,
        private _eventDetailsBusiness : EventDetailsBusiness, private sftpBusiness : SftpSetupBusiness,
        private _eventSchedulerConfigurationBusiness : EventSchedulerConfigurationBusiness,
        private commonUtils: CommonUtilities, private userAccessDataService : UserAccessDataService)
    {
        this.captions = this.localization.captions.ReportSchedular;
    }

    ngOnInit(): void {

        this.cancelButton = {
            type: 'tertiary',
            label: this.captions.btn_cancel
          }
          this.saveButton = {
            type: 'primary',
            label: this.captions.btn_save,
            disabledproperty: true
          }
        this.buildform();
        this.initializeInputs();
        this.validateUserAccess();
    }

    ngOnDestroy() {
      if (this.destroyed$) {
        this.destroyed$.next(true);
        this.destroyed$.complete();
      }
    }

    async validateUserAccess() {
      const userAccess = await this.userAccessDataService.getUserAccess(UserAccessBreakPoints.JobschedulerConfiguration);
      this.isViewOnly = userAccess?.isViewOnly;
      if (this.isViewOnly) {
        this.isViewOnly = true;
        this.saveButton.disabledproperty = true;
        this.saveButton = { ...this.saveButton };
      }
    }

    saveFileChange(event)
    {
      if(event.value == '1')
      {
        this.eventSchedulerConfigForm.controls['defaultSFTP'].enable();
        this.defaultSFTPConfig.showRequired = true;
        this.defaultSFTPConfig = { ...this.defaultSFTPConfig };
        this.eventSchedulerConfigForm.controls.defaultSFTP.updateValueAndValidity();
      }
      else
      {
        this.eventSchedulerConfigForm.controls['defaultSFTP'].disable();
        this.defaultSFTPConfig.showRequired = false;
        this.defaultSFTPConfig = { ...this.defaultSFTPConfig };
        this.eventSchedulerConfigForm.controls.defaultSFTP.updateValueAndValidity();
        let dropdownOptions: DropdownOptions = {
          id: 0,
          value: '',
          viewValue: '',
        };
        this.eventSchedulerConfigForm.controls['defaultSFTP'].setValue(dropdownOptions);
      }
    }

    buildform()
    {
        this.eventSchedulerConfigForm = this.fb.group({
            id : 0,
            saveFile : '0',
            defaultSFTP : '',
            enableDetailTrace : 0,
            urlArray: this.fb.array([this.fb.group({
                jobType: '',
                api : '',
                url: '',
                isApi: false,
                id : 0
              })
            ])
        });

        this.eventSchedulerConfigForm.statusChanges.subscribe(x => {
          this.saveButton.disabledproperty = !(this.eventSchedulerConfigForm.valid && this.eventSchedulerConfigForm.dirty);
          this.saveButton = { ...this.saveButton }
        })
    }

    bindForm()
    {
      this.eventSchedulerConfigForm.valueChanges.subscribe(x=>{
        console.log(x);
      })
      this._eventSchedulerConfigurationBusiness.GetDefaultConfig().then(res => {
        this.eventSchedulerConfigForm.patchValue({
          id : res.id,
          saveFile : res.JobDefaultSaveOption,
          //defaultSFTP : this.sftpPatch(res.FtpSetupId),
          enableDetailTrace : res.IsTracingEnabled
        });
        this.sftpPatch(res.FtpSetupId);
        this.patchFormArray(res.JobCustomUrlConfiguration);
      });
    }

    sftpPatch(id)
    {
      if(id > 0)
      {
        let sftpData = this.sftpOption.find(x => x.id == id);
        let dropdownOptions: DropdownOptions = {
          id: sftpData.id,
          value: sftpData.hostName,
          viewValue: sftpData.connectionName,
        };

        this.eventSchedulerConfigForm.controls.defaultSFTP.setValue(dropdownOptions);
      }
      else
        this.eventSchedulerConfigForm.controls['defaultSFTP'].disable()
    }

    patchFormArray(data : customUrlUI.CustomConfig[])
    {
      if(data.length > 0)
      {

      this.FormArray = this.eventSchedulerConfigForm.get('urlArray') as UntypedFormArray;

      if (this.FormArray.length > 0) {
        this.FormArray.removeAt(0);
      }
        for(let i = 0; i < data.length; i++)
        {
          const jobMasterData = this.jobDefaultUrlUIConfig.find(x => x.id == data[i].JobCustomConfigurationId);

          let formArray : any = {
            jobType: jobMasterData.JobType,
            api : jobMasterData.JobType == customUrlUI.JobType.API ? jobMasterData.id : jobMasterData.JobName,
            url: data[i].Route,
            isApi: jobMasterData.JobType == customUrlUI.JobType.API ? true : false,
            id : data[i].id
          }

          let formGroup = this.fb.group(formArray);
          this.FormArray.push(formGroup);
        }
      }
    }

   async initializeInputs()
    {

      this.defaultSFTPConfig = {
        form: this.eventSchedulerConfigForm,
        formControlName: 'defaultSFTP',
        selectOptions: this.defaultSFTPOptions,
        placeHolderId: 'lbl_defaultSFTP',
        placeHolder: this.captions.lbl_DefaultSFTP,
        className: 'ag_display--flex',
        isMultiSelect: false,
        showRequired: false
      };

      this.defaultSFTPOptions = this.sftpBusiness.getSftpConfig().then(res => {
        this.sftpOption = res
        return res && res.map(val => {
            return {
              id: val.id,
              value: val.hostName,
              viewValue: val.connectionName,
            };
          });
      });
      this.jobDefaultUrlUIConfig = await this._eventSchedulerConfigurationBusiness.GetDefaultUrlConfig();
        this.jobTypeList = await this.getJobType();
        this.apiList = await this.getApiNames();

          this.defaultSFTPConfig.selectOptions = this.defaultSFTPOptions; this.defaultSFTPConfig = { ...this.defaultSFTPConfig };

          this.eventSchedulerConfigForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            this.saveButton.disabledproperty = !(this.eventSchedulerConfigForm.valid && this.eventSchedulerConfigForm.dirty);
            this.saveButton = { ...this.saveButton };
          });

          this.bindForm();
    }

    async getJobType()
    {
        const dropdownOptions: DropdownOptions[] = await this._eventSchedulerConfigurationBusiness.getAllScheduleJob();
        return dropdownOptions.map(option => ({
            id: option.id,
            value: option.value,
            viewValue: option.viewValue
          }));
    }

    async getApiNames()
    {
         const dropdownOptions : DropdownOptions[] = []

        this.jobDefaultUrlUIConfig.forEach(data => {
            if(data.JobType == JobType.API)
            {
              dropdownOptions.push({
                id : data.id,
                value  : data.JobName,
                viewValue : data.JobName
              })
            }
          });

        return dropdownOptions;
    }

    add(e, i) {
        this.enableApi = false;
        this.eventSchedulerConfigForm.markAsDirty();

        this.FormArray = this.eventSchedulerConfigForm.get('urlArray') as UntypedFormArray;
        let data = this.fb.group({
            jobType: '',
            api : '',
            url: '',
            isApi: false,
            id : 0
        })
        this.FormArray.push(data);
      }

    removeItem(e, i) {
        this.eventSchedulerConfigForm.markAsDirty();
        this.FormArray = this.eventSchedulerConfigForm.get('urlArray') as UntypedFormArray;
        this.FormArray.removeAt(i);
      }

    toggleJobOption(e,item)
    {

      let jobData = this.jobDefaultUrlUIConfig.find(x => x.JobType == e.value);
        item['controls']['url'].setValue(jobData.Route);

        if(e.value == 4)
        {
            item['controls']['isApi'].setValue(true);
        }
        else
        {
            item['controls']['isApi'].setValue(false);
        }
    }

    toggleApiOption(api,item)
    {
      let jobData = this.jobDefaultUrlUIConfig.find(x => x.id == api.value);
        item['controls']['url'].setValue(jobData.Route);
    }

    async onSave(event)
    {
      let apiResponse : any;
      const formValues = this.eventSchedulerConfigForm.value;
      if(formValues)
      {
        let apiModels = this.ApiModelMapper(formValues);

        if(formValues.id > 0)
          await this._eventSchedulerConfigurationBusiness.updateJobSchedulerConfiguration(apiModels).then(res => {
            if(res)
            {
              this.refreshPage();
            }
          });

        else
        {
          this.saveButton.disabledproperty = this.eventSchedulerConfigForm.valid;
          await this._eventSchedulerConfigurationBusiness.createJobSchedulerConfiguration(apiModels).then(res => {
            if(res)
            {
              this.eventSchedulerConfigForm.controls.id.patchValue(res.id);
              this.refreshPage();
            }
          });
        }
      }
    }

    refreshPage()
    {
      this.commonUtils.ToggleLoader(true);
      this.commonUtils.showAlert(this.captions.successfullysaved, AlertType.WellDone, ButtonType.Ok);
      this.eventSchedulerConfigForm.markAsPristine();
      this.commonUtils.ToggleLoader(false);
      this.disableActionButtons(true,false);
    }
    onCancel(e)
    {
      if(this.eventSchedulerConfigForm && this.eventSchedulerConfigForm.valid)
      {
        this.commonUtils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this.saveButton.disabledproperty = true;
            this.saveButton = { ...this.saveButton };
            this.eventSchedulerConfigForm.markAsPristine();
            this.eventSchedulerConfigForm.reset();
            this.FormArray = this.eventSchedulerConfigForm.get('urlArray') as UntypedFormArray;
            this.FormArray.clear();
            this.initializeInputs();
            this.add('',0);
            this.disableActionButtons(true,false);
          }
        });
      }
    }

    private disableActionButtons(isSaveBtnDisable: boolean = true, isCancelBtnDisable: boolean = true) {
      this.saveButton.disabledproperty = isSaveBtnDisable;
      this.cancelButton.disabledproperty = isCancelBtnDisable;
    }

    ApiModelMapper(data)
    {

      let customConfig : customUrlUI.CustomConfig[] = [];
      data.urlArray.forEach(x => {
        customConfig.push({
            id : x.id,
            JobCustomConfigurationId : data.id,
            JobDefaultConfigurationId : x.isApi == true ? this.jobDefaultUrlUIConfig.find(z => z.id == x.api).id : this.jobDefaultUrlUIConfig.find(y => y.JobType == x.jobType).id,
            JobType : x.jobType,
            JobName : x.isApi == true ? this.jobDefaultUrlUIConfig.find(z => z.id == x.api).JobName : this.jobDefaultUrlUIConfig.find(y => y.JobType == x.jobType).JobName,
            Route : x.url,
            ModuleType : this.jobDefaultUrlUIConfig.find(y => y.JobType == x.jobType).moduleType,
            ProductId : this.jobDefaultUrlUIConfig.find(y => y.JobType == x.jobType).productId
        });
      });
      const apiModel =  {
        id : data.id,
        JobDefaultSaveOption : data.saveFile,
        FtpSetupId : data.defaultSFTP ? data.defaultSFTP.id : '0',
        IsTracingEnabled : data.enableDetailTrace,
        JobCustomUrlConfiguration : customConfig
      } as UI.jobScheduleConfig

      return apiModel;
    }
  }
