import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import { EventSchedulerConfigurationDataService } from '../../../dataservices/notification/event-scheduler-configuration.data.service';
import { DropdownOptions } from '../../../Models/ag-models';
import {DefaultUrlConfigAPI, DefaultUrlConfigUI, UI,API, customUrlUI} from '../../../../common/shared/shared/event-scheduler-configuration/event-scheduler-configuration.model'

@Injectable()
export class EventSchedulerConfigurationBusiness {
  captions: any;
  constructor(private _eventSchedulerConfigurationDataService : EventSchedulerConfigurationDataService)
  {

  }
  async createJobSchedulerConfiguration(jobScheduleConfig : UI.jobScheduleConfig)
  {
    let apimodels = await this._eventSchedulerConfigurationDataService.CreateJobSchedulerConfiguration(this.apiMapper(jobScheduleConfig));
    return apimodels;
  }

  async updateJobSchedulerConfiguration(jobScheduleConfig : UI.jobScheduleConfig)
  {
    let apimodels  = await this._eventSchedulerConfigurationDataService.UpdateJobSchedulerConfiguration(this.apiMapper(jobScheduleConfig));
    return apimodels;
  }

  async getAllScheduleJob(): Promise<DropdownOptions[]> {
    const formats = [
      {
        id: 1,
        value: 1,
        viewValue: "GenericExtract"
     },
    {
        id: 2,
        value: 2,
        viewValue: "Reports"
    },
    {
        id: 3,
        value: 3,
        viewValue: "ExecuteQuery"
    },
    {
        id: 4,
        value: 4,
        viewValue: "API"
    },
    {
        id: 5,
        value: 5,
        viewValue: "AdhocReport"
    }
   ];
   return formats;
  }


  public async GetDefaultUrlConfig() : Promise<DefaultUrlConfigUI.DefaultConfig[]>
  {
    let apimodels = await this._eventSchedulerConfigurationDataService.getDefaultUrlConfiguration();
    return apimodels.map(x => this.JobDefaultUrlMapper(x));
  }

  public async GetDefaultConfig() : Promise<UI.jobScheduleConfig>
  {
    const apiModels: API.jobScheduleConfig = await this._eventSchedulerConfigurationDataService.getDefaultConfiguration();
    return this.uimapper(apiModels);
  }

  JobDefaultUrlMapper(apiModels : DefaultUrlConfigAPI.DefaultConfig) : DefaultUrlConfigUI.DefaultConfig
  {
     const uiData = {
       id: apiModels.id,
       JobType: apiModels.jobType,
       Route: apiModels.route,
       JobName: apiModels.jobName,
       moduleType: apiModels.moduleType,
       productId: apiModels.productId
     } as unknown as DefaultUrlConfigUI.DefaultConfig;

     return uiData;
  }

  apiMapper(uiModel : UI.jobScheduleConfig) : API.jobScheduleConfig
  {
    return {
      id: uiModel.id,
      jobDefaultSaveOption: uiModel.JobDefaultSaveOption,
      ftpSetupId: uiModel.FtpSetupId,
      isTracingEnabled: uiModel.IsTracingEnabled,
      jobCustomUrlConfiguration: uiModel.JobCustomUrlConfiguration
    } as unknown as API.jobScheduleConfig
  }

  uimapper(apiModel : API.jobScheduleConfig) : UI.jobScheduleConfig
  {

    let customurlConfig :  customUrlUI.CustomConfig[] = []

    apiModel.jobCustomUrlConfiguration.forEach(x => {
      customurlConfig.push({
        id : x.id,
        JobDefaultConfigurationId : x.id,
        JobCustomConfigurationId : x.jobDefaultConfigurationId,
        JobType : x.jobType,
        JobName : x.jobName,
        Route : x.route,
        ProductId : x.productId,
        ModuleType : x.moduleType
      })
    })

    const uiData = {
      id : apiModel.id,
      JobDefaultSaveOption : apiModel.jobDefaultSaveOption.toString(),
      FtpSetupId : apiModel.ftpSetupId,
      IsTracingEnabled : apiModel.isTracingEnabled,
      JobCustomUrlConfiguration : customurlConfig
    } as UI.jobScheduleConfig
   
    return uiData;
  }

}