import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports, ProjectedRevenueFilter } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from '../report-business.service';
import { DropDownData } from "src/app/reports/report.model";

/**
 *Projected Revenue Summary Report
 * @export
 * @class Projected Revenue Summary
 * @extends {BaseReport}
 */
export class ProjectedRevenue extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,
        public business: ReportBusinessService, public patcheableValue
    ) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.ProjectedRevenue,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

     getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            //this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
         return {
             startDatePicker: true,
             endDatePicker: true,
             dropDownFilterName: this.captions.Therapists,
             inActiveToggle: false,
             inActiveToggleName: "",
             pageBreakToggle: true,
             layout: 'LANDSCAPE',
             preventHistoricDate: true
         };
    }


    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle },
        { "pPropertyName": this.propertyName }, {"pDate": this.printedDate},{"pServiceCaption": this.business.getFilterCaption(this.business.FilterDataSource['services'],this.reportFormGrp.controls['customFilterFormGrp'].value.services.map(s=>s.id))},
        {"pFilterCaption": this.business.getFilterCaption(this.business.FilterDataSource['therapists'],this.reportFormGrp.controls['customFilterFormGrp'].value.therapists.map(s=>s.id))},
        {"pServiceGroupCaption": this.business.getFilterCaption(this.business.FilterDataSource['serviceGroups'],this.reportFormGrp.controls['customFilterFormGrp'].value.serviceGroups.map(s=>s.id))},
        { pInactiveTherapist : this.reportFormGrp.controls['customFilterFormGrp'].value.includeInactiveTherapists ? this.captions.Yes : this.captions.No},
        { pIsClass : this.reportFormGrp.controls['customFilterFormGrp'].value.radioFilter==1}];
    }


    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }];
    }


    protected formFilters(): any {
        let toApi = this.localization.convertDateObjToAPIdate;
        let serviceIds: number[] = this.reportFormGrp.controls['customFilterFormGrp'].value.services.map(s=>s.id);
        let serviceGroupIds: number[] = this.reportFormGrp.controls['customFilterFormGrp'].value.serviceGroups.map(s=>s.id);
        let therapistIds: number[] = this.reportFormGrp.controls['customFilterFormGrp'].value.therapists.map(s=>s.id);
        if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
            return JSON.stringify({
                fromDate: toApi(this.formGrp.get('startDate').value),
            	  toDate: toApi(this.formGrp.get('endDate').value),
                Data: {'therapistIds': therapistIds, 'serviceIds' : serviceIds, 'serviceGroupIds': serviceGroupIds},
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            });
        }
        return {
            serviceIds: serviceIds,
            therapistids: therapistIds,
            serviceGroupIds: serviceGroupIds,
            isClass: this.GetReportType()
        };
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	protected GetReportType(){
        const id: number = this.reportFormGrp.controls['customFilterFormGrp'].value.radioFilter;
		return id == 0 ? false : true;
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }
}
